<template>
    <div class="pa-6 pr-0 pl-0 pb-0 mb-2" v-if="!local_is_view_route" style="margin-left: 32px !important">
        <div class="c-poc-overview-tabs pr-0">
            <!-- <a-tabs class="mb-4" background-color="neutral" slider-color="primary" :show-arrows="false"> -->
            <div class="u-flex align-center">
                <div>
                    <div class="u-flex align-center u-wfull" style="width: 1200px;">
                        <a-tabs background-color="neutral" fixed-tabs hide-slider :show-arrows="false">
                            <a-tab :ripple="false" class="" :class="[local_current_route === 'projects-overview' || local_current_route === 'projects-default-overview' ? 'c-poc-overview-tabs__current_route' : 'c-poc-overview-tabs__border-none']" :to="{name: 'projects-overview'}" v-if="!local_user_is_external">
                                <a-icon size="16" class="mr-2">info</a-icon> <span class="md-body-2 c-nav-item">Overview</span>
                            </a-tab>
                            <a-tab :ripple="false"  v-if="!local_user_is_external && $can('collaborators.index')" :to="{ name: 'projects-collaborators' }" :class="[local_current_route === 'projects-collaborators' ? 'c-poc-overview-tabs__current_route' : 'c-poc-overview-tabs__border-none']">
                                <a-icon size="16" class="mr-2">people</a-icon> <span class="md-body-2 c-nav-item">Collaborator</span>
                            </a-tab>
                            <a-tab :ripple="false" class="" :class="[local_current_route === 'projects-usecases' ? 'c-poc-overview-tabs__current_route' : 'c-poc-overview-tabs__border-none']" :to="{name: 'projects-usecases'}" v-if="$can('usecases.index')">
                                <a-icon size="16" class="mr-2">assignment</a-icon> <span class="md-body-2 c-nav-item">Scope</span>
                            </a-tab>
                            <a-tab :ripple="false" class="" :class="[local_current_route === 'projects-tasks' ? 'c-poc-overview-tabs__current_route' : 'c-poc-overview-tabs__border-none']" :to="{name: 'projects-tasks'}" v-if="$can('tasks.index')">
                                <a-icon size="16" class="mr-2">check_circle</a-icon> <span class="md-body-2 c-nav-item">Tasks</span>
                            </a-tab>
                            <a-tab :ripple="false" class="" :class="[local_current_route === 'projects-links' ? 'c-poc-overview-tabs__current_route' : 'c-poc-overview-tabs__border-none']" :to="{name: 'projects-links'}" v-if="$can('documents.index') || $can('links.index')">
                                <a-icon size="16" class="mr-2">description</a-icon> <span class="md-body-2 c-nav-item">Documents</span>
                            </a-tab>
                            <a-tab :ripple="false" class="" :class="[local_current_route === 'projects-notes' ? 'c-poc-overview-tabs__current_route' : 'c-poc-overview-tabs__border-none']" :to="{name: 'projects-notes'}" v-if="$can('notes.index')">
                                <a-icon size="16" class="mr-2">notes</a-icon> <span class="md-body-2 c-nav-item">Notes</span>
                            </a-tab>
                            <!-- <a-tab :ripple="false" class="mx-2" :class="[local_current_route === 'projects-issues' ? 'c-poc-overview-tabs__current_route' : 'c-poc-overview-tabs__border-none']" :to="{name: 'projects-notes'}" v-if="$can('notes.index')">
                                <a-icon size="16" class="mr-2">notes</a-icon> <span class="md-body-2 c-nav-item">Issues</span>
                            </a-tab> -->
                            <a-tab :ripple="false" class="" :class="[local_current_route === 'projects-inventories' ? 'c-poc-overview-tabs__current_route' : 'c-poc-overview-tabs__border-none']" :to="{name: 'projects-inventories'}" v-if="!local_user_is_external && $can('inventories.index') && user_self.organization && user_self.organization.flag_inventory && user_self.organization.flag_inventory === 1">
                                <a-icon size="16" class="mr-2">memory</a-icon> <span class="md-body-2 c-nav-item">Inventory</span>
                            </a-tab>
                            <a-tab :ripple="false" class="" :class="[local_current_route === 'projects-tickets' ? 'c-poc-overview-tabs__current_route' : 'c-poc-overview-tabs__border-none']" :to="{ name: 'projects-tickets' }" v-if="!local_user_is_external && $can('poc_tickets.index')">
                                <a-icon size="16" class="mr-2">account_tree</a-icon> <span class="md-body-2 c-nav-item">Tickets</span>
                            </a-tab>
                            <!-- <a-tab :ripple="false" class="mx-2" :class="[local_current_route === 'projects-collaborators' ? 'c-poc-overview-tabs__current_route' : 'c-poc-overview-tabs__border-none']" :to="{name: 'projects-collaborators'}" v-if="!local_user_is_external && $can('collaborators.index')">
                                <a-icon size="16" class="mr-2">people</a-icon> <span class="md-body-2 c-nav-item">Collaborators</span>
                            </a-tab> -->
                        </a-tabs>
                        <a-spacer></a-spacer>
                        <a-menu offset-y transition="slide-y-transition" min-width="144">
                            <template v-slot:activator="{ on }">
                                <a-icon size="18" color="grey darken-1" v-on="on">more_vert</a-icon>
                            </template>
                            <a-list class="u-list-condensed">
                                <a-list-item v-if="$can('projects.index')" active-class="grey lighten-5" :to="{ name: 'projects-strategies' }">
                                    <a-list-item-icon class="pa-0"><a-icon size="16" class="mr-2">explore</a-icon></a-list-item-icon>
                                    <a-list-item-content class="md-body-2">Strategy</a-list-item-content>
                                </a-list-item>
                                <a-list-item v-if="$can('document_generator.index')" active-class="grey lighten-5" :to="{ name: 'projects-document-generator' }">
                                    <a-list-item-icon class="pa-0"><a-icon size="16" class="mr-2">source</a-icon></a-list-item-icon>
                                    <a-list-item-content class="md-body-2">Reports</a-list-item-content>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </div>
                    <a-divider class="grey lighten-2 mb-4"></a-divider>
                </div>
                <a-spacer></a-spacer>
                <div class="navigation mb-5">
                    <router-link :to="{name: 'support'}" target="_blank" class="navigation__item">
                        <a-img :src="require('../../assets/images/success-imgs/icon-support.svg')" contain :width="iconSize" :height="iconSize"></a-img>
                    </router-link>
                    <router-link :to="{name: 'teams-enabled'}" v-if="user_self && user_self.is_admin === 1" class="navigation__item">
                        <a-img :src="require('../../assets/images/success-imgs/icon-users.svg')" contain :width="iconSize" :height="iconSize"></a-img>
                    </router-link>
                    <router-link :to="{name: 'settings'}" class="navigation__item">
                        <a-img :src="require('../../assets/images/success-imgs/icon-settings.svg')" contain :width="iconSize" :height="iconSize"></a-img>
                    </router-link>
                    <a-menu bottom center offset-y min-width="160" close-on-content-click transition="slide-y-transition">
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="mr-0 ml-2 u-cursor-pointer d-flex align-center">
                                <g-avatar :item="user_self" :size="32" :right-space="false"></g-avatar>
                                <a-icon size="26" :color="iconColor">arrow_drop_down</a-icon>
                            </div>
                        </template>
                        <a-list one-line class="u-list-condensed py-2">
                            <a-list-item class="px-2" :to="{name: 'settings-profile'}" exact-active-class="c-list-link-active">
                                <a-list-item-avatar class="mr-1 my-0" size="24" style="min-width: 32px;">
                                    <a-icon size="16" color="grey darken-1">person</a-icon>
                                </a-list-item-avatar>
                                <a-list-item-content>
                                    <a-list-item-title class="body-2">Profile</a-list-item-title>
                                </a-list-item-content>
                            </a-list-item>
                            <a-divider class="my-2"></a-divider>
                            <a-list-item class="px-2" @click="local_logout()">
                                <a-list-item-avatar class="mr-1 my-0" size="24" style="min-width: 32px;">
                                    <a-icon size="16" color="grey darken-1">lock</a-icon>
                                </a-list-item-avatar>
                                <a-list-item-content>
                                    <a-list-item-title class="body-2">Logout</a-list-item-title>
                                </a-list-item-content>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { storage } from '@/helpers/helper-local-storage'

export default {
    data () {
        return {
            iconColor: '#444',
            iconSize: '18'
        }
    },

    computed: {
        local_current_route () {
            return this.$route.name
        },

        local_is_view_route () {
            const routeList = ['projects-usecases-view', 'projects-notes-view', 'projects-usecases-template-import', 'projects-usecases-import', 'projects-channels-slack', 'projects-channels-jira', 'projects-tickets-view']
            return routeList.includes(this.local_current_route)
        },

        local_user_is_external () {
            return this.user_self && this.user_self.scope === 'external'
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Auth', {
            auth_response: 'response'
        })
    },

    methods: {
        async local_logout () {
            await this.auth_logout()
            if (this.auth_response.status === 'success') {
                storage.removeOrgKeys()
                window.location.replace('/login')
            }
        },

        ...mapActions('Auth',{
            auth_logout: 'logout',
        }),
    }
}
</script>

<style lang="scss" scoped>
    .c-nav-item {
        font-size: 0.875rem;
        letter-spacing: 0.2px;
        // font-weight: 500;
    }

    .navigation {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
    }
    .navigation__logo {
        padding-right: 0px;
    }
    .navigation__item {
        font-size: 0.8rem;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 500;
        padding: 0 1rem;
        display: block;
        color: rgba(0, 0, 0, 0.6);
        text-decoration: none;
        &--normal {
            font-size: 0.95rem !important;
            text-transform: capitalize !important;
            letter-spacing: auto !important;
        }
    }
    .navigation__item:hover {
        color: #222;
    }
</style>

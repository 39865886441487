var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',_vm._g({staticClass:"d-inline-flex align-center mr-2 u-rounded-corners u-cursor-pointer",class:{'my-1': !_vm.dense},staticStyle:{"padding":"4px 6px"},style:({
        border: `1px solid ${_vm.dataTypeColor}`,
        color: '#fff',
        backgroundColor: _vm.dataTypeColor,
        fontSize: _vm.dataFontSize + 'px',
        height: _vm.height ? _vm.height + 'px' : 'auto'
    }),attrs:{"title":_vm.methodGetLabel(_vm.tag) && _vm.methodGetLabel(_vm.tag).length >= _vm.textRange ? _vm.methodGetLabel(_vm.tag) : ''}},_vm.$listeners),[_vm._v(" "+_vm._s(_vm._f("truncateText")(_vm.methodGetLabel(_vm.tag),_vm.textRange))+" "),(_vm.canUpdate && !_vm.hideClearIcon)?_c('a-icon',{staticClass:"c-icon--dark u-cursor-pointer u-rounded-corners ml-2",staticStyle:{"top":"0px"},attrs:{"size":"16","color":"#fff"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('destroy', _vm.tag, _vm.type)}}},[_vm._v("clear")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
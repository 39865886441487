<template>
    <a-card>
        <div class="u-flex-center-y pa-6 py-5">
            <a-avatar class="primary darken-1" size="40">
                <a-icon dark>folder</a-icon>
            </a-avatar>
            <div class="ml-3">
                <h2 class="md-heading-6 primary--text text--darken-1">Update Project</h2>
            </div>
            <a-spacer></a-spacer>
            <span v-if="titleError" :class="['d-inline-block px-2 py-1 u-rounded-corners-full md-caption font-weight-medium red lighten-5 red--text text--darken-2']">Error</span>
            <template v-else>
                <span v-if="autoSaveLoader && autoSaveLoader === 'Saved'" :class="['d-inline-block px-2 py-1 u-rounded-corners-full md-caption font-weight-medium green lighten-4 green--text text--darken-4']">{{ autoSaveLoader }}</span>
                <span v-if="autoSaveLoader && autoSaveLoader === 'Error'" :class="['d-inline-block px-2 py-1 u-rounded-corners-full md-caption font-weight-medium red lighten-4 red--text text--darken-4']">{{ autoSaveLoader }}</span>
                <span v-if="autoSaveLoader && autoSaveLoader === 'Saving...'" :class="['d-inline-block px-2 py-1 u-rounded-corners-full md-caption font-weight-medium grey lighten-2 grey--text text--darken-2']">{{ autoSaveLoader }}</span>
            </template>
            <a-icon v-test-btn.close size="18" color="grey darken-1" class="ml-3" @click="$emit('close')">clear</a-icon>
        </div>

        <a-divider></a-divider>

        <div class="py-6 pt-5 pb-6">
            <div class="px-6 pb-4 c-textarea-editor c-textarea-editor--no-border">
                <span class="md-body-2 mb-2 d-inline-block body--text text--lighten-1 font-weight-medium">Title <span class="red--text font-weight-bold">*</span></span>
                <a-tooltip bottom :disabled="!projectError.title && !titleError">
                    <template v-slot:activator="{ on }">
                        <a-textarea
                            v-on="on"
                            v-model="projectItem.title"
                            placeholder="Enter Project Title"
                            background-color="transparent"
                            @input="localUpdate('title', projectItem.title)"
                            rows="1"
                            :disabled="!canUpdate"
                            class="c-hover-state"
                            :class="[{ 'c-wiggle-short u-border-error': projectError.title || titleError }]"
                            solo flat hide-details no-resize auto-grow
                            v-test-input.title
                        ></a-textarea>
                    </template>
                    <span>{{ titleError ? titleError : projectError.title }}</span>
                </a-tooltip>
            </div>

            <!-- Start and End dates -->
            <div class="u-flex-center-y px-6">
                <div>
                    <span class="md-body-2 mb-3 d-inline-block body--text text--lighten-1 font-weight-medium">Estimated Start / End Date</span>
                    <div :class="['u-flex align-center u-rounded-corners u-cursor-pointer c-hover-state']">
                        <s-date-picker v-test-input.eststartend :disabled="!(canUpdate && $can('projects-estimated-start-end-dates.destroy'))" :start-date-max-range="localCheckMaxDateRange(projectItem.estimated_end_date)" :due-date-min-range="localCheckMinDateRange(projectItem.estimated_start_date)" startDateTitle="Estimated Start Date" dueDateTitle="Estimated End Date" startDateLabel="estimated_start_date" dueDateLabel="estimated_end_date" :item="projectItem" @update="localUpdateDates">
                            <a-sheet min-height="24" class="u-flex-center-y transparent">
                                <template v-if="projectItem.estimated_start_date && projectItem.estimated_end_date">
                                    <g-moment class="md-body-2 grey--text text--darken-4" :value="projectItem.estimated_start_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(projectItem, 'range')"></g-moment>
                                    <span class="mx-1 grey--text text--darken-2 font-weight-medium"> - </span>
                                    <g-moment class="md-body-2 grey--text text--darken-4" :class="[]" :value="projectItem.estimated_end_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(projectItem, 'range')"></g-moment>
                                </template>
                                <template v-if="projectItem.estimated_start_date && !projectItem.estimated_end_date">
                                    <a-icon size="16" color="body lighten-3" class="mr-2">today</a-icon>
                                    <span class="md-body-2 grey--text text--darken-2 font-weight-medium mr-1">Est Start Date: </span>
                                    <g-moment class="md-body-2 grey--text text--darken-4" :class="[]" :value="projectItem.estimated_start_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format({ start_date: projectItem.estimated_start_date, due_date: projectItem.estimated_end_date })"></g-moment>
                                </template>
                                <template v-if="!projectItem.estimated_start_date && projectItem.estimated_end_date">
                                    <a-icon size="16" color="body lighten-3" class="mr-2">event</a-icon>
                                    <span class="md-body-2 grey--text text--darken-2 font-weight-medium mr-1">Est Due Date: </span>
                                    <g-moment class="md-body-2 grey--text text--darken-4" :class="[]" :value="projectItem.estimated_end_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format({ start_date: projectItem.estimated_start_date, due_date: projectItem.estimated_end_date })"></g-moment>
                                </template>
                                <template v-if="!projectItem.estimated_start_date && !projectItem.estimated_end_date">
                                    <a-icon size="16" color="body lighten-3" class="mr-2">calendar_today</a-icon>
                                    <span class="md-body-2 grey--text text--darken-2 font-weight-medium mr-1">Select Date</span>
                                </template>
                            </a-sheet>
                        </s-date-picker>
                    </div>
                </div>
                <a-spacer></a-spacer>
                <div>
                    <span class="md-body-2 mb-3 d-inline-block body--text text--lighten-1 font-weight-medium">Start / End Date</span>
                    <div :class="['u-flex align-center u-rounded-corners u-cursor-pointer c-hover-state']">
                        <s-date-picker v-test-input.startend :disabled="!(canUpdate && $can('projects-start-end-dates.destroy'))" :start-date-max-range="localCheckMaxDateRange(projectItem.due_date)" :due-date-min-range="localCheckMinDateRange(projectItem.start_date)" :item="projectItem" @update="localUpdateDates">
                            <a-sheet min-height="24" class="u-flex-center-y transparent">
                                <template v-if="projectItem.start_date && projectItem.due_date">
                                    <g-moment class="md-body-2 grey--text text--darken-4" :value="projectItem.start_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(projectItem, 'range')"></g-moment>
                                    <span class="mx-1 grey--text text--darken-2 font-weight-medium"> - </span>
                                    <g-moment class="md-body-2 grey--text text--darken-4" :class="[]" :value="projectItem.due_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(projectItem, 'range')"></g-moment>
                                </template>
                                <template v-if="projectItem.start_date && !projectItem.due_date">
                                    <a-icon size="16" color="body lighten-3" class="mr-2">today</a-icon>
                                    <span class="md-body-2 grey--text text--darken-2 font-weight-medium mr-1">Start Date: </span>
                                    <g-moment class="md-body-2 grey--text text--darken-4" :class="[]" :value="projectItem.start_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(projectItem)"></g-moment>
                                </template>
                                <template v-if="!projectItem.start_date && projectItem.due_date">
                                    <a-icon size="16" color="body lighten-3" class="mr-2">event</a-icon>
                                    <span class="md-body-2 grey--text text--darken-2 font-weight-medium mr-1">Due Date: </span>
                                    <g-moment class="md-body-2 grey--text text--darken-4" :class="[]" :value="projectItem.due_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(projectItem)"></g-moment>
                                </template>
                                <template v-if="!projectItem.start_date && !projectItem.due_date">
                                    <a-icon size="16" color="body lighten-3" class="mr-2">calendar_today</a-icon>
                                    <span class="md-body-2 grey--text text--darken-2 font-weight-medium mr-1">Select Date</span>
                                </template>
                            </a-sheet>
                        </s-date-picker>
                    </div>
                </div>
            </div>
        </div>
    </a-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { diffFormat } from '@/helpers/helper-date-filter'
import { SDatePicker } from '@/config/config-shared-components'

export default {
    name: 'ProjectUpdate',
    components: {
        SDatePicker
    },

    props: {
        project: {
            type: Object,
        },
        isOpen: {
            type: Boolean
        },
        canUpdate: {
            type: Boolean
        },
    },

    data () {
        return {
            titleError: '',
            projectError: {},
            autoSaveLoader: '',
            projectItem: {},
            clonedProject: {},
            updateTimeout: null,
            datepicker_start_date: false,
            datepicker_due_date: false,
            datepicker_estimated_start_date: false,
            datepicker_estimated_end_date: false,
        }
    },

    watch: {
        isOpen: {
            handler (val) {
                this.titleError = ''
                this.projectError = {}
                //this.project_clear_response()
                if (val) this.localIndex()
            },
            immediate: true
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localCurrencyFormat () {
            const currency = this.user_self?.organization?.currency
            if (currency.code === 'EUR') return 'en-EU'
            if (currency.code === 'INR') return 'en-IN'
            if (currency.code === 'GBP') return 'en-GB'
            if (currency.code === 'USD') return 'en-US'
            return 'en-US'
        },

        localCurrencySymbol () {
            return this.user_self && this.user_self.organization && this.user_self.organization.currency && this.user_self.organization.currency.symbol
        },

        ...mapState('User', {
            user_self: 'self'
        }),

        ...mapState('ProjectView', {
            project_response: 'response'
        })
    },

     methods: {
         async localIndex () {
            this.projectItem = _.cloneDeep(this.project)
            this.projectError = {}
         },

         local_date_output_format (date, type = 'range') {
            return diffFormat(date, type)
        },

        localUpdate (field, value) {
            this.clonedProject = _.cloneDeep(this.project)
            this.projectItem.is_date_permission = 0
            this.projectItem.is_estimated_date_permission = 0
            clearTimeout(this.updateTimeout)

            this.titleError = ''
            this.autoSaveLoader = 'Saving...'

            this.updateTimeout = setTimeout(() => {
                if (field === 'title') this.projectItem.title = value
                //if (field === 'deal_amount') this.projectItem.deal_amount = value
                if (field === 'start_date') this.projectItem.start_date = value
                if (field === 'due_date') this.projectItem.due_date = value
                if (field === 'estimated_start_date') this.projectItem.estimated_start_date = value
                if (field === 'estimated_due_date') this.projectItem.estimated_due_date = value
                if(field === 'start_date' || field === 'due_date') this.projectItem.is_date_permission = 1
                if(field === 'estimated_start_date' || field === 'estimated_due_date') this.projectItem.is_estimated_date_permission = 1

                this.$emit('update', this.projectItem, this.localClearLoading, this.localSetErrors)
                clearTimeout(this.updateTimeout)
            }, 600)
        },

        localSetErrors () {
            const titleErr = this.$response(this.project_response, 'title')
            if (titleErr) {
                Object.assign(this.projectError, { title: titleErr })
                Object.assign({}, this.projectItem, { title: this.clonedProject.title })
            }
            this.project_update_item(this.clonedProject)
        },

        localUpdateDates (evt) {
            this.localUpdate(evt.type, evt.value)
        },

        localClearLoading (state = null) {
            this.autoSaveLoader = state === 'error' ? 'Error' : 'Saved'
            if (!state) this.projectError = {}
            setTimeout(() => this.autoSaveLoader = '', 3000)
        },

        localCheckMinDateRange(start_date) {
            if(!start_date) return null
            return moment(start_date).add(0, 'days').format("YYYY-MM-DD")

        },

        localCheckMaxDateRange(due_date) {
            if(!due_date) return null
            return moment(due_date).add(0, 'days').format("YYYY-MM-DD")
        },

        ...mapActions('ProjectView', {
            project_update_item: 'update_item',
            project_clear_response: 'clear_response',
        }),
    }
}
</script>

<style lang="scss" scoped></style>

<template>
    <div
        class="scroll-transparent"
        style="
            position: sticky; left: 0px; top: 0px;
            background-color: #F7F8F9;
            border-right: 1px solid #eee;
            overflow-y: auto;
            height: 100vh;
            z-index: 0
        "
        :style="[
            { maxWidth: visibility ? '280px' : '60px', minWidth: visibility ? '280px' : '60px' }
        ]"
    >
        <template v-if="visibility">
            <div class="u-sticky bg-gray" style="left: 0px; top: 0px; z-index: 2;">
                <shared-workspace-switch />
                <div style="border-bottom: 1px solid #eee;"></div>
            </div>

            <template v-if="navBarLoading">
                <div class="pa-5">
                    <loader-aside-navbar expandState />
                    <a-divider ></a-divider>
                </div>
            </template>

            <template v-else>
                <!-- Project create button -->
                <div v-if="$can('projects.store')" class="px-3 my-6 u-wfull" style="margin-top: 25px !important;">
                    <a-btn
                        v-test-btn.create
                        @click="$router.push({ name: 'projects-add' })"
                        class="c-project-create-btn u-wfull u-rounded-corners"
                        height="34"
                        depressed
                    >
                        <a-icon size="20" class="u-no-select mr-1" color="white">add</a-icon>
                        <span class="u-font-14 u-leading-20 u-tracking white--text font-weight-medium">Create Project</span>
                    </a-btn>
                </div>

                <!-- Menu Items -->
                <div class="mx-3" :class="[{ 'mt-3': !$can('projects.store') }]">
                    <router-link
                        v-for="menu in menuItems"
                        :key="menu.routeName"
                        :to="{ name: menu.routeName }"
                        class="px-2 c-hover-state-light u-rounded-corners u-flex-center-y u-relative u-cursor-pointer"
                        style="column-gap: 8px; height: 36px;"
                    >
                        <shared-menu-active-state v-if="menu.isActive" />
                        <a-icon
                            size="20"
                            :color="menu.isActive ? 'indigo darken-3' : 'grey'"
                            :outlined="menu.outlined"
                        >
                            {{ menu.icon }}
                        </a-icon>
                        <span
                            class="u-font-14 u-tracking font-weight-medium u-relative"
                            :class="[menu.isActive ? 'indigo--text text--darken-3' : 'grey--text text--darken-3']"
                        >
                            {{ menu.name }}
                        </span>
                    </router-link>

                    <a-menu
                        v-if="localMeetingPermission || localInventoryPermission"
                        bottom
                        offset-y
                        min-width="260"
                        transition="slide-x-transition"
                        content-class="u-rounded-corners-lg u-shadow-md"
                    >
                        <template v-slot:activator="{ on, value }">
                            <div
                                v-on="on"
                                class="px-2 u-flex-center-y u-relative u-cursor-pointer"
                                style="column-gap: 8px; height: 36px;"
                            >
                                <shared-menu-active-state v-if="value || localMoreIsActive" />
                                <a-icon size="20" :color="value || localMoreIsActive ? 'indigo darken-3' : 'grey'">
                                    more_horiz
                                </a-icon>
                                <span
                                    class="u-font-14 u-tracking font-weight-medium u-relative"
                                    :class="[value || localMoreIsActive ? 'indigo--text text--darken-3' : 'grey--text text--darken-3']"
                                >
                                    More
                                </span>
                            </div>
                        </template>
                        <a-list class="u-list-condensed u-list-condensed--round-select py-1 px-1">
                            <template v-for="menu in moreMenuItems">
                                <a-list-item
                                    :key="menu.routeName"
                                    :to="{ name: menu.routeName }"
                                    :active-class="menu.isActive ? 'route-active-class' : ''"
                                    class="px-3 py-1 c-hover-state-light u-rounded-corners-lg"
                                >
                                    <a-icon
                                        size="20"
                                        :color="menu.isActive ? 'indigo darken-3' : 'grey'"
                                        :outlined="menu.outlined"
                                    >
                                        {{ menu.icon }}
                                    </a-icon>
                                    <span
                                        class="u-font-14 u-tracking ml-2 font-weight-medium u-relative"
                                        :class="[menu.isActive ? 'indigo--text text--darken-3' : 'grey--text text--darken-3']"
                                    >
                                        {{ menu.name }}
                                    </span>
                                </a-list-item>
                            </template>
                        </a-list>
                    </a-menu>
                </div>

                <div class="mx-3" v-if="reportMenuItems && reportMenuItems.length">
                    <div class="mt-6 mb-3 u-font-12 font-weight-medium u-tracking grey--text">Reports</div>
                    <router-link
                        v-for="menu in reportMenuItems"
                        :key="menu.routeName"
                        :to="{ name: menu.routeName }"
                        class="px-2 c-hover-state-light u-rounded-corners u-flex-center-y u-relative u-cursor-pointer"
                        style="column-gap: 8px; height: 40px;"
                    >
                        <shared-menu-active-state v-if="menu.isActive" />
                        <a-icon
                            size="20"
                            :color="menu.isActive ? 'indigo darken-3' : 'grey'"
                            :outlined="menu.outlined"
                        >
                            {{ menu.icon }}
                        </a-icon>
                        <span
                            class="u-font-14 u-tracking font-weight-medium u-relative"
                            :class="[menu.isActive ? 'indigo--text text--darken-3' : 'grey--text text--darken-3']"
                        >
                            {{ menu.name }}
                        </span>
                    </router-link>
                </div>
            </template>
        </template>

        <template v-if="!visibility">
            <div class="u-sticky bg-gray" style="left: 0px; top: 0px; z-index: 2;">
                <shared-workspace-switch :collapsed="true" />
                <div style="border-bottom: 1px solid #eee;"></div>
            </div>

            <loader-aside-navbar v-if="navBarLoading" />
            <template v-else>
                <!-- Project create button -->
                <div
                    v-test-btn.create
                    v-if="$can('projects.store')"
                    @click="$router.push({ name: 'projects-add' })"
                    class="u-flex mx-3 my-6"
                    style="column-gap: 12px;"
                >
                    <a-sheet min-width="36" height="36" class="c-project-create-btn u-cursor-pointer u-flex-center u-rounded-corners">
                        <a-icon size="20" class="u-no-select" color="white">add</a-icon>
                    </a-sheet>
                </div>

                <!-- Menu Items -->
                <div :class="[{ 'mt-3': !$can('projects.store') }]">
                    <div class="mx-3">
                        <router-link
                            v-for="menu in menuItems"
                            :key="menu.routeName"
                            :to="{ name: menu.routeName }"
                            class="px-2 c-hover-state-light u-rounded-corners u-flex-center-y u-relative u-cursor-pointer"
                            style="column-gap: 8px; height: 36px;"
                        >
                            <shared-menu-active-state v-if="menu.isActive" light />
                            <a-icon
                                size="20"
                                :color="menu.isActive ? 'indigo darken-3' : 'grey'"
                                :outlined="menu.outlined"
                            >
                                {{ menu.icon }}
                            </a-icon>
                        </router-link>

                        <a-menu
                            right
                            offset-x
                            nudge-top="8"
                            min-width="200"
                            transition="slide-x-transition"
                            content-class="u-rounded-corners-lg u-shadow-md"
                            open-on-hover
                        >
                            <template v-slot:activator="{ on, value }">
                                <div
                                    v-on="on"
                                    class="px-2 u-flex-center-y u-relative u-cursor-pointer"
                                    style="column-gap: 8px; height: 36px;"
                                >
                                    <shared-menu-active-state v-if="value" light />
                                    <a-icon size="20" :color="value ? 'indigo darken-3' : 'grey darken-1'">
                                        more_horiz
                                    </a-icon>
                                </div>
                            </template>
                            <a-list class="u-list-condensed u-list-condensed--round-select py-1 px-1">
                                <template v-for="menu in moreMenuItems">
                                    <a-list-item
                                        :key="menu.routeName"
                                        :to="{ name: menu.routeName }"
                                        class="px-3 py-1 c-hover-state-light u-relative u-rounded-corners-lg"
                                    >
                                        <shared-menu-active-state v-if="menu.isActive" />
                                        <a-icon
                                            size="20"
                                            :color="menu.isActive ? 'indigo darken-3' : 'grey'"
                                            :outlined="menu.outlined"
                                        >
                                            {{ menu.icon }}
                                        </a-icon>
                                        <span
                                            class="u-font-14 u-tracking ml-2 font-weight-medium u-relative"
                                            :class="[menu.isActive ? 'indigo--text text--darken-3' : 'grey--text text--darken-3']"
                                        >
                                            {{ menu.name }}
                                        </span>
                                    </a-list-item>
                                </template>
                            </a-list>
                        </a-menu>
                    </div>

                    <a-divider class="grey lighten-3 my-6"></a-divider>
                    <div class="mx-3">
                        <router-link
                            v-for="menu in reportMenuItems"
                            :key="menu.routeName"
                            :to="{ name: menu.routeName }"
                            class="px-2 c-hover-state-light u-rounded-corners u-flex-center-y u-relative u-cursor-pointer"
                            style="column-gap: 8px; height: 36px;"
                        >
                            <div
                                v-if="menu.isActive"
                                class="u-absolute u-rounded-corners u-wfull u-hfull"
                                style="
                                    z-index: 0;
                                    left: 0px; top: 0px;
                                    background: #E8EAF6;
                                "
                            />
                            <a-icon
                                size="20"
                                :color="menu.isActive ? 'indigo darken-3' : 'grey'"
                                :outlined="menu.outlined"
                            >
                                {{ menu.icon }}
                            </a-icon>
                        </router-link>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import mixinGetPreference from '@/mixins/mixin-get-preference'
import mixinGetOrgPlan from '@/mixins/mixin-get-organization-plan'
import SharedWorkspaceSwitch from './SharedWorkspaceSwitch.vue'
import SharedMenuActiveState from './SharedMenuActiveState.vue'
import LoaderAsideNavbar from '../Loader/LoaderAsideNavbar.vue'

export default {
    name: 'Sidebar',
	components: { SharedWorkspaceSwitch, SharedMenuActiveState, LoaderAsideNavbar },

    mixins: [mixinGetPreference, mixinGetOrgPlan],

    props: {
        visibility: {
            type: Boolean,
            default: true
        },
        navBarLoading: {
            type: Boolean,
            default: true
        },
    },

    data () {
        return {
            menuItems: [],
            reportMenuItems: [],
            moreMenuItems: [],
        }
    },

    watch: {
        '$route.name': {
            handler () {
                this.localSetMenuItems()
            },
            immediate: true
        },

        'auth_user_role': {
            handler () {
                this.localSetMenuItems()
            },
        },
    },

    computed: {
        localInventoryPermission () {
            return this.$can('inventories.index') && this.$plan('inventory:full-access') && this.localHasInventoryFlag
        },

        localMeetingPermission () {
            return this.$can('meetings.index') && this.$plan('meetings:full-access') && this.localHasMeetingsFlag
        },

        localHasInventoryFlag () {
            const orgHasInventory = _.get(this.user_self, 'organization.flag_inventory') === 1
            if (this.mixinGetOrgPlan({ field: 'subscription_plan.level' }) === 3) return orgHasInventory

            return orgHasInventory && (this.mixinGetPreference('organization.inventory_view') === 'Yes')
        },

        localHasMeetingsFlag () {
            return !!(this.user_self && this.user_self.organization && this.user_self.organization.flag_meetings && this.user_self.organization.flag_meetings === 1)
        },

        localMoreIsActive () {
            return this.moreMenuItems.some(item => item.isActive)
        },

         ...mapState('Configuration',{
            configuration_main_navbar: 'mainNavbar',
        }),

        ...mapState('User', {
            user_self: 'self'
        }),

        ...mapState('Auth', {
            auth_user_role: 'role',
        }),
    },

    methods: {
        localSetMenuItems () {
            this.localSetMainMenu()
            this.localSetMoreMenu()
            this.localSetReportMenu()
        },

        localSetMainMenu () {
            const menuList = [
                { name: 'Dashboard', routeName: 'dashboard-columns', icon: 'home', outlined: true, isActive: this.$router.route.isDashboardColumns(), show: this.$can('projects.index') },
                { name: 'Projects', routeName: 'projects', icon: 'folder_open', outlined: true, isActive: this.$router.route.isProjects(), show: this.$can('projects.index') },
                { name: 'Tasks', routeName: 'tasks', icon: 'check_circle', outlined: true, isActive: this.$router.route.isTasks(), show: this.$can('tasks.index') },
                { name: 'Notes', routeName: 'notes', icon: 'notes', outlined: true, isActive: this.$router.route.isNotes(), show: this.$can('notes.index') },
                { name: 'Tickets', routeName: 'tickets', icon: 'account_tree', outlined: true, isActive: this.$router.route.isTickets(), show: this.$can('tickets.index') },
                { name: 'Meetings', routeName: 'meetings', icon: 'video_call', outlined: true, isActive: this.$router.route.isMeetings(), show: this.localMeetingPermission },
                { name: 'Inventory', routeName: 'inventory', icon: 'memory', outlined: true, isActive: this.$router.route.isInventory(), show: this.localInventoryPermission },
                { name: 'App Store', routeName: 'appstore', icon: 'apps', outlined: true, isActive: this.$router.route.isAppstore(), show: this.$can('applets.index') },
                { name: 'Accounts', routeName: 'customers', icon: 'account_box', outlined: true, isActive: this.$router.route.isCustomers(), show: this.$can('customers.index') },
                { name: 'Partners', routeName: 'partners', icon: 'contacts', outlined: true, isActive: this.$router.route.isPartners(), show: this.$can('partners.index') },
            ]

            const list = menuList.filter(i => i.show)
            if (!this.localMeetingPermission && !this.localInventoryPermission) {
                this.menuItems = list
                return true
            }

            const moreRouteNames = ['appstore', 'customers', 'partners']
            if (this.localMeetingPermission && this.localInventoryPermission) {
                this.menuItems = list.filter(item => !moreRouteNames.includes(item.routeName))
                return true
            }

            this.menuItems = list.filter(item => !['customers', 'partners'].includes(item.routeName))
        },

        localSetMoreMenu () {
            const list = [
                { name: 'Accounts', routeName: 'customers', icon: 'account_box', outlined: true, isActive: this.$router.route.isCustomers(), show: this.$can('customers.index') },
                { name: 'Partners', routeName: 'partners', icon: 'contacts', outlined: true, isActive: this.$router.route.isPartners(), show: this.$can('partners.index') },
                { name: 'App Store', routeName: 'appstore', icon: 'apps', outlined: true, isActive: this.$router.route.isAppstore(), show: this.$can('applets.index') },
            ]

            this.moreMenuItems = list
                .filter(i => i.show)
                .filter(item => {
                    if (item.routeName !== 'appstore') return item
                    return this.localMeetingPermission && this.localInventoryPermission ? item : null
                })
        },

        localSetReportMenu () {
           this.reportMenuItems = [
                { name: 'Project Reports', routeName: 'reports', icon: 'assignment', outlined: true, isActive: this.$router.route.isReports(), show: this.$can('reports.index') },
                { name: 'Use Case Reports', routeName: 'reports-usecases', icon: 'view_list', outlined: true, isActive: this.$router.route.isReportsUsecases(), show: this.$can('reports.index') && this.$can('usecases.index') },
                { name: 'Resource Planning', routeName: 'resource-planning', icon: 'perm_contact_calendar', outlined: true, isActive: this.$router.route.isResourcePlanning(), show: this.$can('reports.index') },
                { name: 'Insights', routeName: 'insights', icon: 'assessment', outlined: true, isActive: this.$router.route.isInsights(), show: this.$can('reports.index') },
            ]

            this.reportMenuItems = this.reportMenuItems.filter(item => item.show)
        },
    }
}
</script>

<style lang="scss" scoped>
.c-nav-link {
    opacity: 0.5 !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in-out;
}

.active {
    padding: 0px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: 0.2s ease-in-out;
    &--bg {
        opacity: 1 !important;
        padding: 12px;
        background: #fff !important;
        box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.06),
            0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.06) !important;
    }
}

.route-active-class {
    background: linear-gradient(91deg, #E8EAF6 0.77%, rgba(232, 234, 246, 0.00) 99.51%) !important;
}

.c-project-create-btn {
    border: 1px solid #8996A9;
    background: linear-gradient(90deg, #64748B 0%, #1E293B 100%);
    box-shadow: 1px 1px 4px 0px rgba(24, 37, 45, 0.12), 0px 0px 0px 1px #516076, 0px 0px 12px 0px rgba(255, 255, 255, 0.24) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
}
</style>

export const formatTileOptions = (tile_options) => {
	let currentOptions = {}

	for(const option of tile_options) {
		const key = _.camelCase(option.key)

		if (option.key === 'y-axis-value' || option.key === 'primary_slot' || option.key === 'secondary_slot' || option.key === 'value' ) {
			if(option.value.includes('count')) {
				currentOptions[key] = {
					isCurrency: false,
					label: 'Project Count',
					value: option.value
				}
			}
			else if (option.value.includes('min')) {
				currentOptions[key] = {
					isCurrency: true,
					label: 'Minimum Amount',
					value: option.value
				}
			}
			else if (option.value.includes('max')) {
				currentOptions[key] = {
					isCurrency: true,
					label: 'Maximum Amount',
					value: option.value
				}
			}
			else if (option.value.includes('avg')) {
				currentOptions[key] = {
					isCurrency: true,
					label: 'Average Amount',
					value: option.value
				}
			}
			else if (option.value.includes('sum')) {
				currentOptions[key] = {
					isCurrency: true,
					label: 'Total Amount',
					value: option.value
				}
			}
			else if (option.value.includes('none')) {
				currentOptions[key] = {
					isCurrency: false,
					label: '',
					value: option.value
				}
			}
		}
		else if (option.key === 'x-axis') {
			if(option.value.includes('month')) {
				currentOptions[key] = {
					key: 'quarter',
					label: 'Monthly',
					value: option.value
				}
			}
			else if(option.value.includes('quarter')) {
				currentOptions[key] = {
					key: 'quarter',
					label: 'Quarter',
					value: option.value
				}
			}
		}
		else if (option.key === 'y-axis-group' || option.key === 'category') {
			if(option.value.includes('health_color')) {
				currentOptions[key] = {
					label: 'Grouped by Health',
					key: 'health',
					value: option.value,
				}
			}
			else if (option.value.includes('stage_id')) {
				currentOptions[key] = {
					label: 'Grouped by Stage',
					key: 'stage',
					value: option.value,
				}
			}
			else if (option.value.includes('result_id')) {
				currentOptions[key] = {
					label: 'Grouped by Result',
					key: 'result',
					value: option.value,
				}
			}
			else if (option.value.includes('status')) {
				currentOptions[key] = {
					label: 'Grouped by Status',
					key: 'result',
					value: option.value,
				}
			}
			else if (option.value.includes('competitors')) {
				currentOptions[key] = {
					label: 'Grouped by Competitors',
					key: 'result',
					value: option.value,
				}
			}
		}
	}

	return currentOptions;
}
<template>
    <a-card class="u-relative">
        <!-- <div class="px-7 py-3 grey lighten-5"> -->
        <div class="pa-6 grey lighten-5">
            <slot name="root-title">
                <h2 class="md-heading-5 primary--text text--darken-1">
                    <slot name="title"></slot>
                </h2>
            </slot>
        </div>
        <slot name="title-action"><a-divider></a-divider></slot>
        <slot name="prepend"></slot>
        <!-- <a-card-text class="px-7 pb-6 pt-2"> -->
        <a-card-text class="px-6 pb-6 pt-2">
            <slot name="body"></slot>
        </a-card-text>
        <slot name="append"></slot>
        <template v-if="!loading">
            <div v-if="!hideCloseIcon" class="u-absolute u-cursor-pointer" style="top: 15px; right: 20px;">
                <slot name="close-icon">
                    <a-btn small icon class="ma-0" @click="$emit('close', true)">
                        <a-icon size="16">clear</a-icon>
                    </a-btn>
                </slot>
            </div>
        </template>
        <div v-else class="u-absolute" style="top: 15px; right: 20px;">
            <slot name="loading">
                <a-progress-circular width="3" size="24" color="orange darken-1" indeterminate></a-progress-circular>
            </slot>
        </div>
    </a-card>
</template>

<script>
export default {
    props: {
        hideCloseIcon: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
}
</script>

const api_endpoint = '/projects';
import { Base64 } from 'js-base64';
export default {
    namespaced: true,
    state: {
        dash_list: [],
        list: [],
        dash_stats: [],
        item: { collaborators: [], organization: {}, stage_updates: [], customer: {}, sales_engineer: {}, strategies: [], competitors: [], tags: [], labels: [], products: [], platforms: [], account_executive: {}, stakeholders: [], partner: {}, territories: [], organization: {}, stage: {}  },
        slim_item: {},
        stage_history: [],
        related_item: { stage_updates: [], customer: {}, sales_engineer: {}, strategies: [], competitors: [], tags: [], labels: [], products: [], platforms: [], account_executive: {}, stakeholders: [], partner: {}, territories: [], organization: {}, stage: {} },
        links: {},
        extras: {},
        meta: {},
        filters: {
            // 'filter[date_field]': null,
            // 'filter[date_condition]': null,
            // 'filter[date_value_1]': null,
            // 'filter[date_value_2]': null,
            'filter[customer_id]': [],
            'filter[account_executive_id]': [],
            'filter[sales_engineer_id]': [],
            'filter[collaborators]': {},
            'filter[collaborators_group]': {},
            'filter[result_id]': [],
            'filter[workspace_id]': null,
            'filter[stage_id]': [],
            // 'filter[archived]': null,
            'filter[status]': [],
            'filter[competitor]': [],
            'filter[competitor_group]': [],
            'filter[partner_id]': [],
            'reports_status': [],
            // 'filter[involvement]': null,
            'filter[tag]': [],
            'filter[tag_group]': [],
            'filter[territory]': [],
            // 'filter[timeline]': null,
            'filter[health_color]': [],
            'page': 1,
            // 'count': 999,
            'sort': 'title',
            'download': false,
            'download_format': 'csv',
            'fields[projects]': [],
            'attributes': [],
            'include': '',
            'filter[counts]': [],
            'filter[hierarchy_report]': null
        },
        response: {},
        defaults: {
            dash_list: [],
            list: [],
            dash_stats: [],
            item: { collaborators: [], organization: {}, stage_updates: [], customer: {}, sales_engineer: {}, strategies: [], competitors: [], tags: [], labels: [], products: [], platforms: [], account_executive: {}, stakeholders: [], partner: {}, territories: [], organization: {}, stage: {}  },
            related_item: { stage_updates: [], customer: {}, sales_engineer: {}, strategies: [], competitors: [], tags: [], labels: [], products: [], platforms: [], account_executive: {}, stakeholders: [], partner: {}, territories: [], organization: {}, stage: {} },
            links: {},
            extras: {},
            meta: {},
            filters: {
                // 'filter[date_field]': null,
                // 'filter[date_condition]': null,
                // 'filter[date_value_1]': null,
                // 'filter[date_value_2]': null,
                'filter[customer_id]': [],
                'filter[account_executive_id]': [],
                'filter[sales_engineer_id]': [],
                'filter[collaborators]': {},
                'filter[collaborators_group]': {},
                'filter[result_id]': [],
                'filter[workspace_id]': null,
                'filter[stage_id]': [],
                // 'filter[archived]': null,
                'filter[status]': [],
                'filter[competitor]': [],
                'filter[competitor_group]': [],
                'filter[partner_id]': [],
                'reports_status': [],
                // 'filter[involvement]': null,
                'filter[tag]': [],
                'filter[tag_group]': [],
                'filter[territory]': [],
                // 'filter[timeline]': null,
                'filter[health_color]': [],
                'page': 1,
                // 'count': 25,
                'sort': 'title',
                'download': false,
                'download_format': 'csv',
                'fields[projects]': [],
                'attributes': [],
                'include': '',
                'filter[counts]': [],
                'filter[hierarchy_report]': null
            },
            response: {},
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_DASH_LIST(state, items) {
            state.dash_list = items;
        },
        UPDATE_DASH_STATS(state, items) {
            state.dash_stats = items;
        },
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, item) {
            if ((item.state && (item.state === 'archived' || item.state === 'completed')) || (item.mode && item.mode === 'update-item')) {
                state.item = item.response
                return
            }
            state.item = { ...state.item, ...item.response }
        },
        UPDATE_PROJECT_TILE(state, item) {
            state.item = item
        },
        CLEAR_ITEM(state, item) {
            state.item = item
        },
        UPDATE_RELATED_ITEM_PROPERTY(state, payload) {
            state.related_item[payload.property] = payload.data;
        },
        UPDATE_STAGE_HISTORY(state, payload) {
            state.stage_history = payload
        },
        UPDATE_RELATED_ITEM_PROPERTY_CLEAR(state, item) {
            state.related_item = item;
        },
        UPDATE_SLIM_ITEM(state, item) {
            state.slim_item = item;
        },
        UPDATE_ITEM_TAGS(state, tags) {
            state.item.tags = tags;
        },
        UPDATE_ITEM_STAKEHOLDERS(state, stakeholders) {
            state.item.stakeholders = stakeholders;
        },
        UPDATE_ITEM_TERRITORIES(state, territories) {
            state.item.territories = territories;
        },
        UPDATE_ITEM_PROPERTY(state, payload) {
            state.item[payload.property] = payload.data;
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload.mode === 'update-list-item' ? {...state.list[index], ...payload.item} : payload.item
        },
        UPDATE_DASH_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.dash_list, { 'id': payload.id });
            state.dash_list[index] = payload.mode === 'update-list-item' ? {...state.dash_list[index], ...payload.item} : payload.item
        },
        UPDATE_PROJECT_TAGS(state, payload) {
            state.extras = payload
            // let index = _.findIndex(state.list, { 'id': payload.id });
            // state.list[index] = Object.assign(state.list[index], payload.item);
        },
        UPDATE_ITEM_STAGE_RESULT(state, payload) {
            state.item.stage = payload.item.stage
            state.item.stage_id = payload.item.stage_id
            state.item.result = payload.item.result
            state.item.result_id = payload.item.result_id
            state.item.status = payload.item.status
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_DASH_LIST(state, items) {
            // state.list = _.union(state.list, items);
            state.dash_list.push(...items)
        },
        APPEND_LIST(state, items) {
            // state.list = _.union(state.list, items);
            state.list.push(...items)
        },
        ADD_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_LINKS(state, links) {
            state.links = links;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
        FILTER_TIMELINE(state, payload) {
            state.filters.timeline = payload;
        },
    },
    actions: {
        async dash_index({ commit }, payload = {}) {
            this.dispatch('Interface/loader_start')
            try {
                const response = await axios.get(api_endpoint + '/dashboard/optimized', { params: { ...payload } })
                const { refresh_list, stateless } = payload || {}
                if (stateless) {
                    this.dispatch('Interface/loader_stop')
                    commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
                    return { data: response.data.data, status: 'success' }
                }

                commit('UPDATE_META', { links: response.data.links, meta: response.data.meta })

                if (refresh_list || (payload && payload.page === 1) || !payload){
                    commit('UPDATE_DASH_LIST', response.data.data)
                } else {
                    payload && payload.list ?
                        commit('APPEND_DASH_LIST', response.data.data) :
                        commit('UPDATE_DASH_LIST', response.data.data)
                }

                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response?.data?.message, status: 'error' })
                if (payload?.stateless) {
                    return { data: null, status: 'error' }
                }
            }
        },

        async search_index ({ commit }, payload = {}) {
            this.dispatch('Interface/loader_start')
            try {
                const response = await axios.get(api_endpoint + '/search', { params: { ...payload }})
                this.dispatch('Interface/loader_stop')
                return { data: response?.data?.data, _status: 'success' }
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                return { data: null, _status: 'failed' }
            }
        },

        async dash_stats ({ commit }, payload = {}) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data } } = await axios.get(api_endpoint + '/stats/optimized', { params: { ...payload }})
                commit('UPDATE_DASH_STATS', data)

                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response?.data?.message, status: 'error' });
            }
        },

        index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint, { params: { ...payload } })
                .then((response) => {
                    const { refresh_list } = payload || {}
                    if (refresh_list || (payload && payload.page === 1) || !payload){
                        context.commit('UPDATE_LIST', response.data.data);
                    } else {
                        payload && payload.list ?
                        context.commit('APPEND_LIST', response.data.data) :
                        context.commit('UPDATE_LIST', response.data.data)
                    }
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        index_slim(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint, { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        master_template_import(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/master-template/import', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        master_template_export(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/master-template/export', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        download(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/reports/exports', { params: { download_format: payload.format, header_fields: payload.headers, slugs: payload.slugs, ...payload.filters } })
                .then((response) => {
                    context.commit('UPDATE_LINKS', response.data.url);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        export(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id + '/exports')
                .then((response) => {
                    context.commit('UPDATE_LINKS', response.data.url);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        enabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/enabled')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        disabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/disabled')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id, { params: { ...payload.params } })
                .then((response) => {
                    if (payload.mode && payload.mode === 'update-list-item') context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data, mode: 'update-list-item' });
                    if (payload.mode && payload.mode === 'update-item') context.commit('UPDATE_ITEM', { state: null, mode: 'update-item', response: response.data.data });
                    else context.commit('UPDATE_ITEM', { state: payload.state || null, response: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        update_list(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.project_id, { params: { ...payload } })
                .then((response) => {
                    // context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_PROJECT_TILE', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        stage_history(context, { id, params }) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + id + '/stage/history', { params })
                .then((response) => {
                    context.commit('UPDATE_STAGE_HISTORY', response.data.data)
                    // context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'stage_updates', data: response.data.data});
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        tags(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id + '/tags')
                .then((response) => {
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'tags', data: response.data.data});
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        stage(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id + '/stage')
                .then((response) => {
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'stage', data: response.data.data});
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        competitors_index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' +  payload.id + '/competitors', { params: payload })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'competitors', data: response.data.data});
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        sales_engineer(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id + '/sales_engineer')
                .then((response) => {
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'sales_engineer', data: response.data ? response.data.data : null});
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        account_executive(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id + '/account_executive')
                .then((response) => {
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'account_executive', data: response.data ? response.data.data : null});
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        customer(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id + '/customer')
                .then((response) => {
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'customer', data: response.data.data});
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        partner(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id + '/partner')
                .then((response) => {
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'partner', data: response.data ? response.data.data : null});
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        strategies(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id + '/strategies')
                .then((response) => {
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'strategies', data: response.data ? response.data.data : null});
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        stakeholders(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id + '/stakeholders')
                .then((response) => {
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'stakeholders', data: response.data.data});
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        territories(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id + '/territories')
                .then((response) => {
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'territories', data: response.data.data});
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        async store({commit, dispatch}, payload) {
            this.dispatch('Interface/loader_start')
            const descEncoded = await dispatch('encode_content', payload)
            if(descEncoded) payload.description_json = descEncoded
            return axios.post(api_endpoint, payload)
                .then((response) => {
                    commit('ADD_ITEM', payload);
                    // context.commit('UPDATE_ITEM', payload);
                    commit('UPDATE_ITEM', { state: payload.status ?? null, response: payload });
                    // context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'customer', data: response.data.data.customer});
                    // context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'stage_updates', data: response.data.data.stage_updates});
                    // context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'partner', data: response.data.data.partner});
                    // context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'product', data: response.data.data.product});
                    // context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'tags', data: response.data.data.tags});
                    // context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'territories', data: response.data.data.territories});
                    this.dispatch('Interface/loader_stop')
                    commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        invite_team(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + context.state.item.id + '/invite/team', context.state.item)
                .then((response) => {
                    context.commit('ADD_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        invite_client(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + context.state.item.id + '/invite/client', context.state.item)
                .then((response) => {
                    context.commit('ADD_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        competitors_update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + payload.id + '/competitors', context.state.related_item)
                .then((response) => {
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'competitors', data: response.data.data});
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        strategies_update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + context.state.item.id + '/strategies', payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        stage_update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.project_id + '/stages/' + payload.stage_id, {result_id: payload.result_id})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.project_id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        workspace_update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.project_id + '/stage/move/', payload)
            // return axios.patch(api_endpoint + '/' + payload.project_id, payload)
                .then((response) => {
                    // if (!payload.stage_update) {
                    //     context.commit('UPDATE_LIST_ITEM', { id: payload.project_id, item: response.data.data });
                    // }
                    // context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'stage', data: response.data.data.stage});
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id, payload)
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: payload });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' , server: error.response.data });
                });
        },
        complete(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.project_id + '/stage/move', { comment: payload.comment, result_id: payload.result_id, stage_id: payload.stage_id })
                .then((response) => {
                    if (payload && payload.reqType === 'internal-dashboard') context.commit('UPDATE_DASH_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    else context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        archive(context, payload) {
            this.dispatch('Interface/loader_start')
            // return axios.patch(api_endpoint + '/' + payload.project_id + '/stages/archived', { stage_id: payload.stage_id })
            return axios.patch(api_endpoint + '/' + payload.project_id + '/stage/move', { stage: payload.stage_id })
                .then((response) => {
                    if (payload && payload.reqType === 'internal-dashboard') context.commit('UPDATE_DASH_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    else context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_SLIM_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        enable(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/enable', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        disable(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/disable', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        partner_store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + payload.id + '/partners', payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        partner_destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id + '/partners')
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        tag_store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + payload.id + '/tags/' + payload.tag_id, payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'tags', data: response.data.data});
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        tag_destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id + '/tags/' + payload.tag_id)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'tags', data: response.data.data});
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        stakeholder_store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + payload.id + '/stakeholders/' + payload.stakeholder_id, payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'stakeholders', data: response.data.data});
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        stakeholder_destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id + '/stakeholders/' + payload.stakeholder_id)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'stakeholders', data: response.data.data});
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        territory_store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + payload.id + '/territories/' + payload.territory_id, payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'territories', data: response.data.data});
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        territory_destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id + '/territories/' + payload.territory_id)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'territories', data: response.data.data});
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        async clear_download_link(context) {
            context.commit('UPDATE_LINKS', _.cloneDeep(context.state.defaults.links));
        },
        async clear_list(context) {
            context.commit('UPDATE_LIST', []);
        },
        async clear_project_extras(context) {
            context.commit('UPDATE_PROJECT_TAGS', {});
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('CLEAR_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('UPDATE_DASH_LIST', _.cloneDeep(context.state.defaults.dash_list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_LINKS', _.cloneDeep(context.state.defaults.links));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('CLEAR_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_LINKS', _.cloneDeep(context.state.defaults.links));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        response_clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                resolve('Cleared');
            });
        },
        clear_related_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_RELATED_ITEM_PROPERTY_CLEAR', _.cloneDeep(context.state.defaults.related_item));
                resolve('Cleared');
            });
        },
        encode_content ({commit}, payload) {
            if(!payload['description_json']) return
            const content = payload['description_json']
            return Base64.encode(JSON.stringify(content))
        }
    },
}

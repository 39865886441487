<template>
    <a-responsive class="u-rounded-corners-lg mt-2" :class="[items && items.length ? 'grey lighten-2 u-elevation-custom-1 mb-2' : 'grey darken-4']" style="margin-top: 0px;">
        <a-container grid-list-xl container--fluid class="py-3 px-6 pl-4">
            <a-layout align-start>
                <a-flex shrink class="px-0 py-1" v-if="!history">
                    <template v-if="previousRoute">
                        <a-btn class="ma-1" color="white" text :to="{name: previousRoute ? previousRoute !== 'inventory' ? previousRoute : 'inventory' : 'settings'}" exact>
                            <a-icon class="mr-1">arrow_back</a-icon> Back to
                            <template v-if="!routeLabel">{{ previousRoute ? previousRoute !== 'inventory' ? previousRoute : 'Inventory' : 'Settings' }}</template>
                            <template v-else>{{ routeLabel }}</template>
                        </a-btn>
                    </template>
                    <a-btn v-else class="ma-1" color="white" text :to="{name: `${goto}`}" exact>
                        <a-icon class="mr-1">arrow_back</a-icon> Back to
                        <template v-if="!routeLabel">{{ routeName ? routeName : 'settings' }}</template>
                        <template v-else>{{ routeLabel }}</template>
                    </a-btn>
                </a-flex>
                <a-flex shrink class="px-0 py-1" v-else>
                    <a-btn class="ma-1" color="white" text @click="$router.back()" exact>
                        <a-icon class="mr-1">arrow_back</a-icon> Back
                    </a-btn>
                </a-flex>
            </a-layout>
        </a-container>
    </a-responsive>
</template>

<script>
export default {
    props: {
        goto: {
            type: String,
            default: 'settings'
        },
        routeName: {
            type: String,
            default: null
        },
        items: {
            type: Array
        },
        routeLabel: {
            type: String,
            default: ''
        },
        previousRoute: {
            type: String,
            default: null
        },
        history: {
            type: Boolean,
            default: false
        },
    }
}
</script>

<style lang="scss" scoped>
    .router-link-exact-active.router-link-active {
        color: #444;
        pointer-events: none
    }
</style>

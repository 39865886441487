<template>
	<a-main class="mb-10">
        <a-container grid-list-xl container--fluid class="py-6 px-0 mb-16">
            <template v-if="!local_loading">
                <a-layout v-if="stage_list && stage_list.length">
                    <a-flex xs12>
                        <div>
                            <h2 class="body-2 font-weight-bold text-uppercase grey--text text--darken-1 mb-3">Queue</h2>
                            <a-card v-if="queueStageItem" class="u-rounded-corners u-border u-flex-center-y px-6 py-4" flat>
                                <h2 class="u-font-14 u-leading-20 u-tracking font-weight-medium grey--text text--darken-1 mb-0">{{queueStageItem.name}}</h2>
                                <a-spacer></a-spacer>
                                <a-btn color="grey darken-2" v-if="$can('workspaces.update')" @click="localEditStage(queueStageItem.id)" text icon small>
                                    <a-icon small>edit</a-icon>
                                </a-btn>
                            </a-card>
                        </div>
                        <div class="mt-4 pt-2">
                            <h2 class="body-2 font-weight-bold text-uppercase grey--text text--darken-1 mb-3">Active</h2>
                            <a-card class="u-border u-rounded-corners" flat>
                                <draggable v-model="stage_list" :options="{handle: '.js-drag-handle'}">
                                    <div v-for="(stage, index) in stage_list" :key="stage.id">
                                        <a-divider class="grey lighten-3" :key="stage.status + index" v-if="index !== 0"></a-divider>
                                        <div
                                            v-if="stage && stage.status !== 'queue' && stage.status !== 'completed'"
                                            class="u-flex-center-y px-4 py-4"
                                        >
                                            <div
                                                v-if="user_self.role && user_self.role.scope === 'internal' && $can('workspaces.update')"
                                                class="u-cursor-pointer js-drag-handle mr-2"
                                            >
                                                <a-icon color="grey lighten-1">drag_indicator</a-icon>
                                            </div>
                                            <h2 class="u-font-14 u-leading-20 u-tracking font-weight-medium grey--text text--darken-1">
                                                {{ stage.name }}
                                            </h2>
                                            <a-spacer></a-spacer>
                                            <div class="u-flex align-center" v-if="$can('workspaces.update') || $can('workspaces.destroy')">
                                                <a-btn color="grey darken-2" v-if="$can('workspaces.update')" @click="localEditStage(stage.id)" text icon small>
                                                    <a-icon small>edit</a-icon>
                                                </a-btn>
                                                <a-menu v-if="$can('workspaces.destroy')" offset-y left class="u-rounded-corners" :disabled="stage.projects_count > 0" content-class="u-elevation-custom-1 u-border" max-width="250">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <a-tooltip bottom v-if="stage.projects_count > 0">
                                                                <template v-slot:activator="{ on }">
                                                                    <span v-on="on">
                                                                        <a-btn color="grey darken-2" text icon small :disabled="stage.projects_count > 0">
                                                                            <a-icon small>delete</a-icon>
                                                                        </a-btn>
                                                                    </span>
                                                                </template>
                                                                <span class="text-center" v-if="stage.projects_count > 0">You have {{ stage.projects_count }} projects under this stage.<br>Please move them to different stage to delete.</span>
                                                            </a-tooltip>
                                                            <a-btn v-else color="grey darken-2" text icon small>
                                                                <a-icon small>delete</a-icon>
                                                            </a-btn>
                                                        </div>
                                                    </template>
                                                    <a-card class="u-rounded-corners pa-3">
                                                        <h2 class="grey--text text--darken-2 body-2 mb-2">Are you sure to delete the stage? This cannot be undone.</h2>
                                                        <a-btn dark small text @click="localDestroyStage(stage.id)" class="red lighten-1 ml-0 u-rounded-corners">Ok, Delete</a-btn>
                                                    </a-card>
                                                </a-menu>
                                            </div>
                                        </div>
                                    </div>
                                </draggable>
                            </a-card>
                            <a-card v-if="$can('workspaces.update')" class="u-border u-rounded-corners mt-2 u-flex-center px-4" flat>
                                <div class="u-flex-center mr-0" style="flex: 0 0 24px">
                                    <a-icon color="grey" class="" size="24">add</a-icon>
                                </div>
                                <a-form style="flex: 1 1 auto" @submit.prevent="localStoreStage()">
                                    <a-text-field
                                        v-model="local_stage_item.name"
                                        placeholder="Enter Stage Name"
                                        class="u-font-14 u-leading-20 u-tracking py-1 u-rounded-corners"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                </a-form>
                            </a-card>
                            <span class="md-caption red--text text--darken-2 d-block mt-4" style="min-height: 26px">
                                <template v-if="stage_response.server && stage_response.server.errors && stage_response.server.errors.name && stage_form_mode === 'add'">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ stage_response.server.errors.name[0] }}
                                </template>
                            </span>
                        </div>
                        <div>
                            <h2 class="body-2 text-uppercase font-weight-bold grey--text text--darken-1 mb-3">Completed</h2>
                            <a-card v-if="completedStageItem" class="u-border u-rounded-corners" flat>
                                <div class="u-flex-center-y px-6 py-4">
                                    <h2 class="u-font-14 u-leading-20 u-tracking font-weight-medium grey--text text--darken-1 mb-0">{{ completedStageItem.name }}</h2>
                                    <a-spacer></a-spacer>
                                    <a-tooltip bottom :disabled="localWorkspaceIsActive">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">

                                                <g-toggle
                                                    v-model="modelAutoArchive"
                                                    :disabled="!localWorkspaceIsActive"
                                                    :read-only="!$can('workspaces.update')"
                                                    :color="modelAutoArchive ? 'green darken-2' : 'grey darken-4'"
                                                    @change="localEnableAutoArchive"
                                                    class="d-inline-flex align-center mr-6"
                                                    custom-bg dense-xl
                                                >
                                                    <span :class="['grey--text text--darken-2 font-weight-medium u-font-14 u-tracking u-leading-20 ml-2']">
                                                        Auto Archive
                                                    </span>
                                                </g-toggle>
                                            </div>
                                        </template>
                                        <span>Inactive Workspace</span>
                                    </a-tooltip>
                                    <a-btn color="grey darken-2" v-if="$can('workspaces.update')" @click="localEditStage(completedStageItem.id)" text icon small>
                                        <a-icon small>edit</a-icon>
                                    </a-btn>
                                </div>
                                <template v-if="modelAutoArchive">
                                    <a-divider class="grey lighten-3"></a-divider>
                                    <div class="pa-5">
                                        <div class="u-flex-center-y">
                                            <a-icon size="40" color="grey lighten-1">archive</a-icon>
                                            <div class="ml-2">
                                                <h3 class="grey--text text--darken-2 u-font-14 u-tracking u-leading-20 u-font-weight-semibold">
                                                    Move to Archive
                                                </h3>
                                                <h4 class="grey--text text--darken-1 u-font-14 u-tracking u-leading-20 font-weight-medium">
                                                    Choose the frequency for automatically moving the project to archive
                                                </h4>
                                            </div>
                                            <a-spacer></a-spacer>
                                            <a-tooltip bottom :disabled="localWorkspaceIsActive">
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <a-menu
                                                            :disabled="!$can('workspaces.update') || !localWorkspaceIsActive"
                                                            transition="slide-y-transition"
                                                            nudge-bottom="3"
                                                            content-class="u-rounded-corners u-shadow-md"
                                                            bottom offset-y
                                                        >
                                                            <template v-slot:activator="{ on, value }">
                                                                <a-card
                                                                    v-on="on"
                                                                    :class="[
                                                                        { 'u-cursor-pointer': localWorkspaceIsActive && $can('workspaces.update') },
                                                                        'u-flex-center-y u-border u-rounded-corners grey lighten-4 px-3 py-2',
                                                                    ]"
                                                                    width="212"
                                                                    flat
                                                                >
                                                                    <h4 class="grey--text text--darken-2 font-weight-medium u-font-14 u-tracking u-leading-20">
                                                                        {{ currentRange.name }}
                                                                    </h4>
                                                                    <a-spacer></a-spacer>
                                                                    <a-icon size="20" color="primary" v-if="localWorkspaceIsActive && $can('workspaces.update')">
                                                                        arrow_drop_{{ value ? 'up' : 'down' }}
                                                                    </a-icon>
                                                                </a-card>
                                                            </template>

                                                            <div class="white pa-2">
                                                                <div
                                                                    v-for="range in dateRanges"
                                                                    :key="range.value"
                                                                    @click="localUpdateRange(range)"
                                                                    class="pa-2 u-cursor-pointer c-hover-state u-rounded-corners u-relative"
                                                                >
                                                                    <shared-menu-active-state light v-if="currentRange.value === range.value" />
                                                                    <h4
                                                                        :class="[currentRange.value === range.value ? 'indigo--text text--darken-3' : 'grey--text text--darken-2']"
                                                                        class="font-weight-medium u-font-14 u-tracking u-leading-20 u-relative"
                                                                    >
                                                                        {{ range.name }}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </a-menu>
                                                    </div>
                                                </template>
                                                <span>Inactive Workspace</span>
                                            </a-tooltip>
                                        </div>

                                        <div class="blue-grey lighten-5 pa-4 mt-6 u-rounded-corners">
                                            <div class="u-flex-center-y">
                                                <a-icon color="blue-grey darken-1" size="20">info</a-icon>
                                                <h4 class="blue-grey--text text--darken-1 ml-2 u-font-14 u-font-weight-semibold u-tracking u-leading-20">
                                                    How it works?
                                                </h4>
                                            </div>
                                            <ul class="mt-3 ml-4">
                                                <li class="grey--text text--darken-2 u-font-14 font-weight-medium u-leading-20 u-tracking">
                                                    <template v-if="currentRange.value === 'yearly'">
                                                        Runs on the <strong>30th day</strong> of Q1 each {{ currentRange.slug || '' }}.
                                                    </template>
                                                    <template v-else>
                                                        Runs on the <strong>10th day</strong> of each {{ currentRange.slug || '' }}.
                                                    </template>
                                                </li>
                                                <li class="grey--text text--darken-2 my-1 u-font-14 font-weight-medium u-leading-20 u-tracking">
                                                    <strong>Completed projects</strong> with an <strong>End Date</strong> earlier than the previous {{ currentRange.slug || '' }} will be archived.
                                                </li>
                                                <li class="grey--text text--darken-2 u-font-14 font-weight-medium u-leading-20 u-tracking">
                                                    <strong>Completed projects</strong> without an <strong>End Date</strong> will not be archived.
                                                </li>
                                            </ul>
                                            <h4 v-if="currentRange.value === 'monthly'" class="grey--text text--darken-2 mt-4 ml-6 u-font-14 font-weight-medium u-leading-20 u-tracking">
                                                <strong>For Example:</strong> On the <strong>10th day</strong> of March, the system will archive
                                                <strong>Completed projects</strong> that have an <strong>End Date</strong> in January or earlier.
                                            </h4>
                                            <h4 v-if="currentRange.value === 'quarterly'" class="grey--text text--darken-2 mt-4 ml-6 u-font-14 font-weight-medium u-leading-20 u-tracking">
                                                <strong>For Example:</strong> On the <strong>10th day</strong> of Q3, the system will archive
                                                <strong>Completed projects</strong> that have an <strong>End Date</strong> in Q1 or earlier.
                                            </h4>
                                            <h4 v-if="currentRange.value === 'yearly'" class="grey--text text--darken-2 mt-4 ml-6 u-font-14 font-weight-medium u-leading-20 u-tracking">
                                                <strong>For Example:</strong> On the <strong>30th day</strong> of January, the system will archive
                                                <strong>Completed projects</strong> that have an <strong>End Date</strong> in December of the previous year or earlier.
                                            </h4>
                                        </div>
                                    </div>
                                </template>
                            </a-card>
                        </div>
                    </a-flex>
                </a-layout>
            </template>
            <template v-else>
                <a-responsive class="pt-2 my-4 text-center">
                    <h3 class="md-subtitle-1 grey--text text--darken-1">Loading...</h3>
                </a-responsive>
            </template>
        </a-container>
        <a-dialog v-model="dialog_stage_form" max-width="700" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>view_column</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (stage_form_mode == 'add') ? 'Create New Stage' : 'Edit Existing Stage' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (stage_form_mode == 'add') ? 'You can add new stage to the system.' : 'You can edit existing stage.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                    <a-text-field
                                        v-model="stage_item.name"
                                        placeholder="Enter Stage Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="stage_response.server && stage_response.server.errors && stage_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ stage_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the stage.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="localStoreStage(stage_item)" :loading="loading" :disabled="loading">Save Stage</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="localCloseCreateDialog()" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import loadWorkspace from '@/mixins/mixin-workspace'
import SharedMenuActiveState from '../../components/Shared/SharedMenuActiveState.vue'

export default {
    components: {
        SharedMenuActiveState
    },

    mixins: [loadWorkspace],

    data () {
        return {
            modelAutoArchive: false,
            dialog_stage_form: false,
            local_loading: true,
            queueStageItem: null,
            completedStageItem: null,
            local_stage_item: {},
            defaultRange: { name: 'Quarterly', value: 'quarterly', slug: 'quarter' },
            currentRange: { name: '', value: '', slug: '' },
            dateRanges: [
                { name: 'Monthly', value: 'monthly', slug: 'month' },
                { name: 'Quarterly', value: 'quarterly', slug: 'quarter' },
                { name: 'Yearly', value: 'yearly', slug: 'year' },
            ]
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        stage_list: {
            get () {
                return this.$store.state.Stage.list
            },
            set (list) {
                this.localReorderStage(list)
            }
        },

        localIsRangeSet () {
            return !!(_.get(this.completedStageItem?.auto_archive_frequency))
        },

        localWorkspaceIsActive () {
            return _.get(this.settings_workspace_mixin, 'is_active') === 1
        },

        ...mapState('Stage', {
            stage_item: 'item',
            stage_form_mode: 'form_mode',
            stage_response: 'response',
            state_filters: 'filters',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async localIndex () {
            await this.stage_clear()
            this.state_filters.workspace_id = this.settings_workspace_id_mixin
            await this.stage_index({
                'fields[stages]': 'id,name,status,order,is_active,auto_archive_frequency,workspace_id',
                'include': 'projects',
                'filter[workspace_id]': this.$route.params.id
            })

            this.loadUpdateStageStatuses()
            this.local_loading = false
        },

        loadUpdateStageStatuses () {
            const queueStage = this.stage_list.find(list => list.status === 'queue')
            const completedStage = this.stage_list.find(list => list.status === 'completed')
            this.queueStageItem = queueStage
            this.completedStageItem = completedStage

            const currentRangeValue = completedStage?.auto_archive_frequency || this.defaultRange.value
            this.currentRange = this.dateRanges.find(range => range.value === currentRangeValue)

            if (completedStage?.auto_archive_frequency) this.modelAutoArchive = true
        },

        async localEditStage (id) {
            await this.stage_select({ id })
            this.dialog_stage_form = true
        },

        async localStoreStage () {
            if (this.stage_form_mode === 'add') await this.stage_store({ workspace_id: this.settings_workspace_id_mixin, name: this.local_stage_item.name })
            else {
                this.stage_item.workspace_id = this.settings_workspace_id_mixin
                await this.stage_update()
            }

            if (this.stage_response.status !== 'success')  return
            this.dialog_stage_form = false

            if (this.stage_item.status === 'queue' || this.stage_item.status === 'completed' ) {
                this.loadUpdateStageStatuses()
            }

            this.local_stage_item = {}
            this.stage_clear_item()
        },

        localCloseCreateDialog () {
            this.dialog_stage_form = false
            this.stage_clear_item()
        },

        async localDestroyStage (id) {
            await this.stage_destroy({ id })
        },

        async localReorderStage (list) {
            this.stage_reorder({ list })
        },

        localUpdateRange (range) {
            if (_.get(this.completedStageItem, 'auto_archive_frequency') === range.value) return

            this.currentRange = range
            this.completedStageItem.auto_archive_frequency = range.value
            this.stage_update(this.completedStageItem)
        },

        localRemoveRange () {
            this.currentRange = { name: '', value: '', slug: '' }
            this.stage_update({
                ...this.completedStageItem,
                auto_archive_frequency: null,
            })
        },

        localEnableAutoArchive (value) {
            if (value && !this.localIsRangeSet) {
                this.currentRange = this.dateRanges.find(i => i.value === 'quarterly')
                return this.localUpdateRange(this.currentRange)
            }

            this.localRemoveRange()
        },

        ...mapActions('Workspace', {
            workspace_index: 'index',
            workspace_clear: 'clear'
        }),

        ...mapActions('Stage', {
            stage_index: 'index',
            stage_store: 'store',
            stage_update: 'update',
            stage_select: 'select',
            stage_destroy: 'destroy',
            stage_reorder: 'reorder',
            stage_clear: 'clear',
            stage_clear_item: 'clear_item',
        }),
    },
}
</script>

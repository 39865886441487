<template>
    <aside class="sidebar">
        <template v-if="user_self && user_self.scope === 'internal'">
            <div class="md-body-2 brown--text text--darken-1 font-weight-medium orange lighten-3 pa-2 u-flex align-center">
                <a-icon size="18" color="brown darken-2" class="u-icon-nudge" style="top: -1px;">info</a-icon>
                <span class="ml-1">You are viewing as a {{ local_external_view.toLowerCase() }}</span>
                <a-spacer></a-spacer>
                <router-link tag="a" class="md-caption u-cursor-pointer brown--text text--lighten-1 font-weight-medium u-flex align-center" :to="{ name: 'projects-overview' }">
                    <a-icon size="18" color="brown lighten-1">keyboard_arrow_left</a-icon>
                    Go Back
                </router-link>
            </div>
        </template>
        <div class="project">
            <img v-if="user_self && user_self.organization.logo !== null" :src="user_self.organization.logo" :alt="user_self.organization.name" :title="user_self.organization.name" style="max-width: 120px; max-height: 50px">
            <a-chip v-else label small class="ml-0 mb-2">
                {{ user_self.organization.name }}
            </a-chip>
            <h2 class="md-heading-5 break-all grey--text text--darken-4 font-weight-medium">
                {{ (user_self && user_self.scope === 'external') ? project_item_external.title : project_item_internal.title }}
            </h2>
        </div>
    </aside>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    mounted() {
        this.local_index()
    },

    computed: {
        local_external_view () {
            return this.$route.params.view
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('ProjectExternal', {
            project_item_external: 'item',
        }),

        ...mapState('ProjectView', {
            project_item_internal: 'item',
        }),
    },

    methods: {
        async local_index() {
            if(this.user_self && this.user_self.scope === 'external') {
                await this.project_show({
                    id: this.$route.params.id,
                    params: {
                        'include': 'usecasesClosed,usecasesOpenCount,usecasesClosedCount,usecasesCount,tasksPendingCount,tasksCount,linksCount,documentsCount,notesCount'
                    }
                })
            }
        },

        ...mapActions('ProjectExternal', {
            project_show: 'show',
        }),
    },
}
</script>

<style scoped>
    .sidebar {
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
        min-height: 100%;
    }
    .project,
    .duration,
    .team,
    .competitor,
    .loader {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .toolbar {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
    .tags {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .result {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
    .team-member {
        display: flex;
        align-items: center;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        outline: none;
    }
    .member-avatar {
        flex: 0 0 auto;
    }
</style>

<template>
    <div class="u-flex-center" style="height: calc(100vh)">
        <div class="text-center">
            <div class="text-center mb-6">
                <img v-if="orgLogo" :src="orgLogo" style="max-height: 72px; max-width: 250px" class="d-inline-block">
                <img v-if="orgLogo === null" src="../../assets/images/success-imgs/logo-success-full.svg" class="d-inline-block" style="max-width: 280px; max-height: 32px">
            </div>
            <h2 class="md-heading-6 grey--text text--darken-3 font-italic font-weight-medium">
                You are being logged out...
            </h2>
            <a-sheet class="transparent mx-auto mt-6" max-width="200">
                <a-progress-linear class="u-rounded-corners-full" height="8" color="grey darken-3" indeterminate></a-progress-linear>
            </a-sheet>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { storage } from '@/helpers/helper-local-storage'

export default {
    data () {
        return {
            orgLogo: null
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        ...mapState('Auth', {
            auth_response: 'response',
            auth_organization: 'organization',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

     methods: {
        async localIndex () {
            if (!this.user_self || (this.user_self && !this.user_self.id)) {
                return this.$router.replace({ name: 'login' })
            }

            await this.auth_get_organization()
            this.orgLogo = this.auth_organization ? this.auth_organization.logo : null

            await this.auth_logout()
            if (this.auth_response.status === 'success') {
                storage.removeOrgKeys()
                window.location.replace('/login')
            }
        },

        ...mapActions('Auth',{
            auth_logout: 'logout',
            auth_get_organization: 'getOrganization',
        }),
    }
}
</script>

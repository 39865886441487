<template>
    <v-app v-cloak id="app">
        <a-main style="background-color: #F7F8F9">
            <app-layout></app-layout>
        </a-main>
    </v-app>
</template>

<script>
import AppLayout from '@/layouts/AppLayout.vue'
import { VApp } from 'vuetify/lib'
 import intercomScript from '@/config/config-intercom-script'

export default {
    components: { VApp, AppLayout },

    async mounted () {
        this.localIndex()
    },

    data () {
        return {
            zendesk_script_enabled: process.env.VUE_APP_ZENDESK_SCRIPT_ENABLED,
            intercom_script_enabled: process.env.VUE_APP_INTERCOM_SCRIPT_ENABLED,
        }
    },


    methods: {
        localIndex () {
            this.localSetupStripe()
            this.localSetupZendesk()
            this.localSetupIntercom()
            this.localSetupDynatrace()
        },

        localSetupStripe () {
            const stripeScriptTag = document.createElement('script')
            stripeScriptTag.setAttribute('src', 'https://js.stripe.com/v3/')
            document.head.appendChild(stripeScriptTag)
        },

        localSetupZendesk () {
            if (this.zendesk_script_enabled === 'false') return

            const zenDeskScriptTag = document.createElement('script')
            zenDeskScriptTag.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=${process.env.VUE_APP_ZENDESK_KEY}`)
            zenDeskScriptTag.setAttribute('id', 'ze-snippet')
            document.head.appendChild(zenDeskScriptTag)
        },

        localSetupIntercom () {
            if (this.intercom_script_enabled === 'false') return

            const intercomScriptTag = document.createElement('script')
            intercomScriptTag.setAttribute('id', 'intercom-snippet')
            intercomScriptTag.text = intercomScript(process.env.VUE_APP_INTERCOM_SCRIPT_API_KEY)
            document.head.appendChild(intercomScriptTag)
        },

        localSetupDynatrace () {
            if (process.env.VUE_APP_DYNATRACE_SCRIPT_ENABLED !== 'true') return

            const dynatraceSetup = document.createElement('script')
            dynatraceSetup.setAttribute('id', 'dynatrace-snippet')
            dynatraceSetup.setAttribute('type', 'text/javascript')
            dynatraceSetup.setAttribute('crossorigin', 'anonymous')
            dynatraceSetup.setAttribute('src', process.env.VUE_APP_DYNATRACE_SCRIPT_URL)
            document.head.appendChild(dynatraceSetup)
        },

        localCapitalize (string) {
            // if (!string) return ' '

            // return ' - ' + (string.split('-')
            //     .map(s => s.charAt(0).toUpperCase() + s.slice(1))
            //     .join(' ')
            // )
        },
    }
}
</script>

<style lang="scss">
// @import './assets/stylesheets/app.scss';
</style>

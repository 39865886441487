<template>
    <div>
        <template v-if="!can_internal_mixin('links.index')">
            <a-sheet class="transparent text-center mx-auto mt-8" max-width="230">
                <a-icon size="48" color="orange darken-4">warning</a-icon>
                <h2 class="md-subtitle-1 mt-1 grey--text text--darken-1">You do not have sufficient permission to view this page.</h2>
            </a-sheet>
        </template>
        <template v-else>
            <a-responsive class="my-4" v-if="page_loading">
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching {{ local_module_text }}..</h3>
            </a-responsive>

            <template v-if="!page_loading">
                <a-container container--fluid grid-list-xl pa-6>
                <div class="u-flex-center-y mt-4" v-if="link_list && link_list.length > 0">
                    <h4 class="md-subtitle-2 grey--text text--darken-1 text-uppercase">{{ link_list.length }} Link{{ link_list.length > 1 ? 's' : '' }} added</h4>
                </div>
                <div class="mt-4" v-if="permission_link('store')">
                    <v-text-field
                        v-model="local_url"
                        @input="e => !e ? mixinClearError('store') : ''"
                        @keydown.enter="local_store_link"
                        @paste="{}"
                        ref="refLinkCreateInput"
                        placeholder="Add New Link & Hit Enter"
                        class="md-subtitle-1 u-shadow u-rounded-corners-lg"
                        :class="[{ 'c-wiggle-short u-border-error': mixinHasError('store') }]"
                        solo flat hide-details
                    >
                        <template #prepend-inner>
                            <div class="mr-1">
                                <a-icon size="24" color="grey darken-1">add_circle_outline</a-icon>
                            </div>
                        </template>
                        <template #append v-if="mixinHasError('store')">
                            <a-tooltip bottom offset-y>
                                <template v-slot:activator="{ on }">
                                    <a-icon color="red darken-1" size="20" v-on="on">info</a-icon>
                                </template>
                                <span>{{ mixinGetError('store') }}</span>
                            </a-tooltip>
                        </template>
                    </v-text-field>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="link_response.server && link_response.server.errors && link_response.server.errors.url && link_form_mode == 'add'">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ link_response.server.errors.url[0] }}
                    </span>
                </div>
                <a-layout wrap align-start v-if="!link_list.length">
                    <a-flex xs12>
                        <a-responsive class="my-4">
                            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Links Found</h3>
                            <p class="md-body-2 text-center grey--text mb-0 mx-auto" v-if="permission_link('store')" style="max-width: 350px;">Add your project specific links to share within your team or with customer.</p>
                        </a-responsive>
                    </a-flex>
                </a-layout>
                <a-layout wrap align-center v-if="link_list.length > 0 && permission_link('index')">
                    <a-flex xs12 py-0>
                        <draggable v-model="link_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                            <div v-for="link in link_list" :key="link.id" :class="{ 'js-draggable-file-list-item': permission_link('update')}">
                                <a-card class="u-elevation-custom-1 u-cursor-pointer my-3">
                                    <a-card-text class="pa-0">
                                        <a-container container--fluid grid-list-xl class="py-3 px-6">
                                            <a-layout align-center>
                                                <a-flex shrink class="px-0" :class="{'u-opacity-30': !permission_link('update')}">
                                                    <a-icon class="grey--text text--lighten-1 js-drag-handle">drag_indicator</a-icon>
                                                </a-flex>
                                                <a-flex class="text-truncate">
                                                    <h2 class="md-subtitle-1 primary--text text-truncate mb-1">
                                                        {{ link.title }}
                                                    </h2>
                                                    <a :href="link.url" target="_blank" rel="noopener" class="md-body-2 mb-0 blue--text text--darken-2 u-no-underline d-block text-no-wrap text-truncate" style="max-width: 360px;">{{ link.url }}</a>
                                                </a-flex>
                                                <a-spacer></a-spacer>
                                                <a-flex shrink class="u-flex-center-y" style="column-gap: 4px;">
                                                    <a-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <a-btn icon text small color="grey" class="mx-0" v-on="on" :disabled="loading" @click="local_edit(link.id)" v-if="permission_link('update')">
                                                                <a-icon size="16">edit</a-icon>
                                                            </a-btn>
                                                        </template>
                                                        <span>Edit</span>
                                                    </a-tooltip>
                                                    <template v-if="permission_link('destroy')">
                                                        <a-btn icon text small color="grey" v-if="!local_is_selected(link.id)" class="mx-0" @click="local_add_item_to_delete(link.id)">
                                                            <a-icon size="16">delete</a-icon>
                                                        </a-btn>
                                                        <a-tooltip bottom v-else>
                                                            <template v-slot:activator="{ on }">
                                                                <a-btn icon text small color="red" class="mx-0" v-on="on" :disabled="loading" :loading="local_loading_get(link.id, 'destroy')" @click="local_destroy(link.id)">
                                                                    <a-icon size="16">delete</a-icon>
                                                                </a-btn>
                                                            </template>
                                                            <span>Confirm?</span>
                                                        </a-tooltip>
                                                    </template>
                                                </a-flex>
                                            </a-layout>
                                        </a-container>
                                    </a-card-text>
                                </a-card>
                            </div>
                        </draggable>
                    </a-flex>
                </a-layout>
            </a-container>
            </template>

            <a-dialog v-model="dialog_link_form" max-width="600" persistent>
                <a-card>
                    <a-card-text class="pa-0">
                        <a-form @submit.prevent="local_store()">
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-center>
                                    <a-flex shrink class="pr-1">
                                        <a-avatar class="primary darken-1" size="40">
                                            <a-icon dark>link</a-icon>
                                        </a-avatar>
                                    </a-flex>
                                    <a-flex v-if="link_form_mode == 'add'">
                                        <h2 class="md-heading-6 primary--text text--darken-1">Add New Link</h2>
                                        <p class="md-subtitle-2 mb-0">Add new link.</p>
                                    </a-flex>
                                    <a-flex v-if="link_form_mode == 'edit'">
                                        <h2 class="md-heading-6 primary--text text--darken-1">Edit Link Information</h2>
                                        <p class="md-subtitle-2 mb-0">Edit information related to link.</p>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-center>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title (Optional)</label>
                                        <a-text-field
                                            v-model="link_item.title"
                                            placeholder="Enter Link Title"
                                            background-color="neutral"
                                            solo flat hide-details
                                        >
                                        </a-text-field>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="link_response.server && link_response.server.errors && link_response.server.errors.title">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ link_response.server.errors.title[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            This will help you identify the link.
                                        </span>
                                    </a-flex>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">URL</label>
                                        <a-text-field
                                            v-model="link_item.url"
                                            placeholder="Enter URL"
                                            background-color="neutral"
                                            solo flat hide-details
                                        >
                                        </a-text-field>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="link_response.server && link_response.server.errors && link_response.server.errors.url">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ link_response.server.errors.url[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Enter the URL to store.
                                        </span>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-center>
                                    <a-flex shrink>
                                        <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_update_link()" :loading="loading" :disabled="loading">Save Link</a-btn>
                                        <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_link_form_cancel()" :disabled="loading">Cancel</a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-form>
                    </a-card-text>
                </a-card>
            </a-dialog>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ExternalPermissionMixin from '@/mixins/mixin-external-view-permission'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import mixinErrors from '@/mixins/mixin-module-error-setup'

export default {
    mixins: [
        ExternalPermissionMixin,
        mixinLoading,
        mixinErrors
    ],

    data() {
        return {
            export_mode: 'new',
            dialog_link_form: false,
            loading_item: null,
            loading_action: null,
            data_template_group_list: [],
            delete_items_list: [],
            snackbar_status: false,
            snackbar_text: '',
            page_loading: true,
            form_add_mode: 'upload',
            loading_file: false,
            uploadDocsList: [],
            local_url: '',
            local_file_types: '.jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx,.csv,.ppt,.pptx,.txt,.zip',
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        link_list: {
            get() {
                return this.$store.state.LinkExternal.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },
        local_module_text () {
            if (!this.permission_mixin('links.index')) return 'data'
            return 'Links'
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('TemplateGroup', {
            template_group_list: 'list',
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),
        ...mapState('TemplateLink', {
            template_link_item: 'item',
            template_link_response: 'response',
        }),
        ...mapState('LinkExternal', {
            link_item: 'item',
            link_filters: 'filters',
            link_response: 'response',
            link_form_mode: 'form_mode',
        }),
        ...mapState('Project', {
            project_item: 'item',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_index() {
            if (!this.can_internal_mixin('links.index')) return this.page_loading = false
            if (!this.can_external_mixin('links.index')) {
                return this.$router.replace({name: 'errors-unauthorized'})
            }

            if (this.can_external_mixin('links.index') && this.can_internal_mixin('links.index')) {
                await this.link_clear()
                await this.local_fetch_link()
            }

            this.page_loading = false
            if(!this.project_item) await this.project_show({id: this.$route.params.id})
        },
        async local_fetch_link() {
            await this.link_index({ 'filter[project_id]': this.$route.params.id, 'sort' : 'order' })
        },
        async dialog_link_form_open() {
            await this.link_clear_item()
            this.dialog_link_form = true
        },
        async local_edit(id) {
            this.dialog_link_form = true
            await this.link_select({id: id})
        },
        async local_store_link () {
            const params = this.$appendId({ url: this.local_url, project_id: this.$route.params.id, order: -1 })
            this.mixinSetLoading(params.id)
            this.mixinClearError('store')
            await this.link_store(params)
            if (this.link_response && this.link_response.status === 'success') {
                this.local_url = ''
                await this.link_show({ id: params.id })
            }
            this.mixinResetLoading(params.id)
        },
        async local_update_link () {
            await this.link_update({ ...this.link_item, mode: 'show' })
            if (this.link_response && this.link_response.status === 'success') {
                this.dialog_link_form = false
                await this.link_clear_item()
            }
        },
        local_validate_url (url, slug) {
            this.url_error = null
            const regex = new RegExp('^(https?:\\/\\/)?'+'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+'((\\d{1,3}\\.){3}\\d{1,3}))'+'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+'(\\?[;&a-z\\d%_.~+=-]*)?'+'(\\#[-a-z\\d_]*)?$','i');
            const isValid = regex.test(url)
            const maxLengthBreach = url && url.length > 2048

            if (!isValid) this.mixinSetError({ slug, message: 'Not a valid URL' })
            if (maxLengthBreach) this.mixinSetError({ slug, message: 'Maximum of 2048 characters' })
            return isValid && !maxLengthBreach
        },
        async local_link_form_cancel() {
            this.dialog_link_form = false
            await this.link_clear_item()
        },
        async local_destroy(id) {
            await this.local_loading_set(id, 'destroy')
            await this.link_destroy({id: id})
            await this.local_loading_clear()
        },
        local_add_item_to_delete (id) {
            this.delete_items_list.push(id)
            setTimeout(() => this.local_remove_delete_item(id), 3000)
        },
        local_remove_delete_item (id) {
            this.delete_items_list.splice(this.delete_items_list.indexOf(id))
        },
        local_is_selected (id) {
            return this.delete_items_list.includes(id)
        },
        async local_visibility_toggle(id, status) {
            await this.local_loading_set(id, 'visibility')
            await this.link_select({id: id})
            this.link_item.visibility = status === 'external' ? 'internal' : 'external'
            await this.link_visibility(this.link_item)
            await this.local_loading_clear()
            if (this.template_link_response.status === 'success') {
                await this.link_clear_item()
            }
        },
        async local_reorder(list) {
            await this.link_reorder({list: list})
        },
        local_initiate() {
            this.$refs.doc_upload.value = null
            this.$refs.doc_upload.click()
        },
        async local_loading_set(id, action) {
            this.loading_item = id
            this.loading_action = action
        },
        async local_loading_clear() {
            this.loading_item = null
            this.loading_action = null
        },
        local_loading_get(id, action) {
            return this.loading && this.loading_item == id && this.loading_action == action
        },
        permission_link(type) {
            return this.permission_mixin('links.' + type)
        },
        ...mapActions('Project', {
            project_show: 'show',
        }),
        ...mapActions('LinkExternal', {
            link_index: 'index',
            link_show: 'show',
            link_store: 'store',
            link_visibility: 'visibility',
            link_update: 'update',
            link_select: 'select',
            link_destroy: 'destroy',
            link_reorder: 'reorder',
            link_template_import: 'template_import',
            link_clear: 'clear',
            link_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_store: 'store',
            template_group_clear: 'clear',
        }),
        ...mapActions('TemplateLink', {
            template_link_export: 'template_export',
            template_link_clear: 'clear',
        }),
    }
}
</script>

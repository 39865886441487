import generateBaseUrl from '@/config/config-api'
const axios = generateBaseUrl(process.env.VUE_APP_CORE_API_URL, 'system')
const api_endpoint = '/report-blocks'

export default {
    namespaced: true,
    state: {
        list: [],
        filters: { status: 'all', include: 'reportBlockAttributes', page: 1, count: 999, sort_direction: 'asc' },
        response: [],
        defaults: {
            list: [],
            filters: { status: 'all', include: '', page: 1, count: 999, sort_direction: 'asc' },
            response: [],
        },
    },

    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },

    actions: {
        async actionBlockIndex(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data: response } } = await axios.get(api_endpoint)
                context.commit('UPDATE_LIST', response)
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
    },

    getters: {
        getBlocksList: (state) => {
            const list = [...state.list]
            return list
        },
        getDefaultBlockAttr: (state) => (filename, attrName) => {
            const block = state.list.find(block => block.file === filename)
            const attr = { ...block.attributes.find(attribute => attribute.title === attrName) }
            return attr
        },
        getBlockContent: (state) => (id) => {
            return state.list.find(item => item.id === id)
        }
    }
}

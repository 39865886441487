<template>
	<a-main>
        <a-responsive class="grey darken-4" style="margin-top: -4px;">
            <a-container grid-list-xl class="py-2">
                <a-layout align-start>
                    <a-flex shrink class="px-0">
                        <a-btn color="white" text :to="{name: 'appstore'}" exact>
                            <a-icon class="mr-1">arrow_back</a-icon> Back to Apps
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive>
        <a-responsive class="grey darken-3">
            <a-container grid-list-xl class="py-4 my-2" v-if="installation_item.applet">
                <a-layout align-center>
                    <a-flex shrink>
                        <div class="u-elevation-custom-1 white" style="display: flex; align-items: center; justify-content: center; width: 104px; height: 104px; border-radius: 1.618rem;">
                            <img :src="installation_item.applet.icon" :alt="installation_item.applet.title" style="display: block; max-width: 64px; max-height: 64px;">
                        </div>
                    </a-flex>
                    <a-flex>
                        <h2 class="md-heading-4 white--text">{{ installation_item.applet.title }}</h2>
                        <p class="md-subtitle-1 grey--text text--lighten-1 mb-0">{{ installation_item.applet.subtitle }}</p>
                        <p class="md-body-2 grey--text mt-1 mb-0" v-if="installation_item.installer">Installed by {{ installation_item.installer.name }} on <g-moment :value="installation_item.created_at" input-format="YYYY-MM-DD" output-format="MMMM D, YYYY"></g-moment></p>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive>
        <!-- <a-responsive class="white">
            <a-container grid-list-xl class="py-0">
                <a-layout align-center>
                    <a-flex shrink>
                        <tabs></tabs>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive> -->
        <!-- <a :href="`/applets/${installation_item.applet.slug}/oauth/authenticate`" class="d-block orange darken-3 u-cursor-pointer" v-if="installation_item.applet && installation_account_list && installation_account_list.length === 0">
            <a-container grid-list-xl class="py-3">
                <a-layout align-center>
                    <a-flex shrink>
                        <a-icon color="white" size="22">lock</a-icon>
                    </a-flex>
                    <a-flex shrink class="pl-0">
                        <h2 class="md-subtitle-1 white--text">Connect your account to get started with workflows. Click to initiate the authentication.</h2>
                    </a-flex>
                    <a-flex>
                    </a-flex>
                </a-layout>
            </a-container>
        </a> -->
        <a-responsive class="indigo darken-2" v-if="installation_account_list && installation_account_list.length > 0">
            <a-container grid-list-xl class="py-3" v-for="account in installation_account_list" :key="account.uuid">
                <a-layout align-center>
                    <a-flex shrink>
                        <a-icon color="white" size="22">lock</a-icon>
                    </a-flex>
                    <a-flex shrink class="pl-0">
                        <p class="md-subtitle-1 mb-0 white--text">{{ account.label }}</p>
                    </a-flex>
                    <a-flex class="pl-0">
                        <p class="md-caption mb-0 white--text">Connected by {{ (account.authenticator) ? account.authenticator.name : '' }} on <g-moment :value="account.created_at" input-format="YYYY-MM-DD" output-format="MMMM D, YYYY"></g-moment></p>
                    </a-flex>
                    <a-flex shrink>
                        <a-menu bottom left offset-y max-width="176">
                            <a-btn icon text small color="white" class="ma-0" slot="activator">
                                <a-icon size="16">clear</a-icon>
                            </a-btn>
                            <a-card flat>
                                <a-card-text class="pa-0">
                                    <div class="pa-3">
                                        <p class="md-body-2 mb-0 text-center grey--text text--darken-3">All your workflows will be disabled when you disconnect account.</p>
                                    </div>
                                    <a-btn color="red darken-1" class="elevation-0 ma-0" block dark small @click="local_installation_account_destroy(account.uuid)">Confirm Remove</a-btn>
                                </a-card-text>
                            </a-card>
                        </a-menu>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive>
        <a-container grid-list-xl class="my-4" v-if="installation_item.applet && installation_account_list && installation_account_list.length === 0">
            <a-layout align-center justify-center>
                <a-flex xs4>
                    <div class="text-center"><a-icon size="160" color="grey lighten-1">cloud_off</a-icon></div>
                    <h2 class="md-heading-5 mb-1 grey--text text--darken-3 text-center">No Acccount Connected</h2>
                    <p class="md-body-2 mb-2 grey--text text--darken-1 text-center">Connect your Salesforce organization as Admin with Success to create workflows and setup automation.</p>
                    <div class="text-center"><a-btn dark color="blue darken-1" :href="`/applets/${installation_item.applet.slug}/oauth/authenticate`">Connect Salesforce Account</a-btn></div>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container grid-list-xl class="my-2" v-if="installation_account_list && installation_account_list.length > 0">
            <a-layout align-center>
                <a-flex>
                    <h2 class="md-heading-5 font-weight-semibold">Workflows</h2>
                    <p class="md-body-2 mb-0 grey--text text--darken-2">Create automated workflows to run in the background.</p>
                </a-flex>
                <a-flex shrink>
                    <a-btn color="primary" class="mr-0" @click="dialog_worklow_selection = true">
                        <a-icon class="mr-1">add</a-icon> Create Workflow
                    </a-btn>
                </a-flex>
            </a-layout>
            <a-layout align-center>
                <a-flex>
                    <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden mb-3" v-for="workflow in workflow_list" :key="workflow.uuid">
                        <a-card-text class="pa-0">
                            <a-container grid-list-xl class="py-3">
                                <a-layout align-center>
                                    <a-flex shrink>
                                        <div class="d-flex" v-if="workflow.direction === 'Incoming'">
                                            <img src="/images/logo-salesforce.svg" alt="Salesforce" style="display: inline-block; max-width: 32px; max-height: 32px;">
                                            <a-icon class="mx-2">arrow_right_alt</a-icon>
                                            <img src="/images/success-imgs/icon-success.svg" alt="Success" style="display: inline-block; max-width: 28px; max-height: 28px;">
                                        </div>
                                        <div class="d-flex" v-if="workflow.direction === 'Outgoing'">
                                            <img src="/images/success-imgs/icon-success.svg" alt="Success" style="display: inline-block; max-width: 28px; max-height: 28px;">
                                            <a-icon class="mx-2">arrow_right_alt</a-icon>
                                            <img src="/images/logo-salesforce.svg" alt="Salesforce" style="display: inline-block; max-width: 32px; max-height: 32px;">
                                        </div>
                                    </a-flex>
                                    <a-flex class="u-cursor-pointer">
                                        <router-link v-if="installation_item && installation_item.uuid" :to="{name: `appstore-salesforce-workflow-${workflow.type}`, params: { uuid: installation_item.uuid, workflow_uuid: workflow.uuid }}">
                                            <p class="md-body-1 mb-1">{{ workflow.title }}</p>
                                            <p class="md-caption mb-0">{{ local_workflow_type_attribute(workflow.type, 'title') }}</p>
                                        </router-link>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon v-if="workflow.is_active === 0" size="22">radio_button_unchecked</a-icon>
                                        <a-icon v-if="workflow.is_active === 1" size="22" color="green">radio_button_checked</a-icon>
                                        {{ (workflow.is_active === 1) ? 'Active' : 'Inactive' }}
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-menu bottom left offset-y max-width="176">
                                            <a-btn icon text small color="grey darken-1" class="mx-0" slot="activator">
                                                <a-icon size="16">clear</a-icon>
                                            </a-btn>
                                            <a-card flat>
                                                <a-card-text class="pa-0">
                                                    <div class="pa-3">
                                                        <p class="md-body-2 mb-0 text-center grey--text text--darken-3">Workflow will be deleted. This action cannot be reversed.</p>
                                                    </div>
                                                    <a-btn color="red darken-1" class="elevation-0 ma-0" block dark small @click="local_workflow_destroy(workflow.uuid)">Confirm Delete</a-btn>
                                                </a-card-text>
                                            </a-card>
                                        </a-menu>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog :value="dialog_worklow_selection" max-width="800" persistent>
            <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="py-3">
                        <a-layout align-center>
                            <a-flex>
                                <h2 class="md-subtitle-1 font-weight-bold grey--text text--darken-3">Select Workflow Type</h2>
                            </a-flex>
                            <a-flex shrink>
                                <a-btn color="grey" small icon dark @click="dialog_worklow_selection = false">
                                    <a-icon color="white" size="16">clear</a-icon>
                                </a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-list two-line class="py-0">
                        <template v-for="type in workflow_types">
                            <a-divider :key="'divider_'+type.slug"></a-divider>
                            <a-list-item :key="type.slug" @click="local_workflow_store(type.slug, type.direction)">
                                <a-list-item-content class="pa-0">
                                    <a-layout align-center>
                                        <a-flex shrink class="pl-1 pr-3">
                                            <div class="d-flex" v-if="type.direction === 'Incoming'">
                                                <img src="/images/logo-salesforce.svg" alt="Salesforce" style="display: inline-block; max-width: 32px; max-height: 32px;">
                                                <a-icon class="mx-2">arrow_right_alt</a-icon>
                                                <img src="/images/success-imgs/icon-success.svg" alt="Success" style="display: inline-block; max-width: 28px; max-height: 28px;">
                                            </div>
                                            <div class="d-flex" v-if="type.direction === 'Outgoing'">
                                                <img src="/images/success-imgs/icon-success.svg" alt="Success" style="display: inline-block; max-width: 28px; max-height: 28px;">
                                                <a-icon class="mx-2">arrow_right_alt</a-icon>
                                                <img src="/images/logo-salesforce.svg" alt="Salesforce" style="display: inline-block; max-width: 32px; max-height: 32px;">
                                            </div>
                                        </a-flex>
                                        <a-flex>
                                            <a-list-item-title class="md-subtitle-1 font-weight-bold">{{ type.title }}</a-list-item-title>
                                            <a-list-item-sub-title class="md-body-2">{{ type.subtitle }}</a-list-item-sub-title>
                                        </a-flex>
                                    </a-layout>
                                </a-list-item-content>
                                <a-list-item-action>
                                    <a-icon color="grey lighten-1">arrow_right</a-icon>
                                </a-list-item-action>
                            </a-list-item>
                        </template>
                    </a-list>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import Tabs from './tabs'
// import Breadcrumb from './breadcrumb'

export default {
    components: {
        // Tabs,
        // Breadcrumb,
    },
    data() {
        return {
            temp_workflows: [],
            dialog_worklow_selection: false,
            workflow_types: [
                {
                    slug: 'pull-projects',
                    direction: 'Incoming',
                    title: 'Import Projects from Salesforce Opportunity',
                    subtitle: 'Setup workflow for creating Projects in Success from Salesforce opportunity.',
                },
                {
                    slug: 'push-status-updates',
                    direction: 'Outgoing',
                    title: 'Create New Entry in Salesforce on Project Status Updates',
                    subtitle: 'Push data to Salesforce when status update is posted in Success.',
                },
                {
                    slug: 'push-stage-changes',
                    direction: 'Outgoing',
                    title: 'Create New Entry in Salesforce on Project Stage Changes',
                    subtitle: 'Push data to Salesforce when Project stage is changed in Success.',
                },
                {
                    slug: 'push-stage-changes-update',
                    direction: 'Outgoing',
                    title: 'Update Opportunity in Salesforce on Project Stage Changes',
                    subtitle: 'Update Salesforce opportunity when Project stage is changed in Success.',
                }
            ]
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        // breadcrumb_items() {
        //     let breadcrumbs = [];
        //     breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
        //     breadcrumbs.push({text: 'Apps', to: '/appstore', exact: true})
        //     breadcrumbs.push({text: 'Configure Apps', to: '/appstore/installed', exact: true})
        //     return breadcrumbs
        // },
        ...mapState('Installation', {
            installation_item: 'item',
        }),
        ...mapState('InstallationAccount', {
            installation_account_list: 'list',
            installation_account_filters: 'filters',
        }),
        ...mapState('Workflow', {
            workflow_item: 'item',
            workflow_list: 'list',
            workflow_filters: 'filters',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            if (this.user_self.role.scope === 'external') {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.installation_clear_item()
            await this.installation_account_clear()
            await this.workflow_clear()

            await this.installation_show({uuid: this.$route.params.uuid})

            this.installation_account_filters.installation_id = this.installation_item.uuid
            await this.installation_account_index()

            this.workflow_filters.installation_id = this.installation_item.uuid
            await this.workflow_index()
        },
        async local_installation_account_destroy(uuid) {
            await this.installation_account_destroy({uuid: uuid});
            await this.local_index()
        },
        async local_workflow_store(type, direction) {
            await this.workflow_clear_item()
            this.workflow_item.type = type
            this.workflow_item.direction = direction
            this.workflow_item.installation_id = this.installation_item.uuid
            await this.workflow_store()
            this.$router.push({name: `appstore-salesforce-workflow-${this.workflow_item.type}`, params: { uuid: this.installation_item.uuid, workflow_uuid: this.workflow_item.uuid }})
            this.dialog_worklow_selection = false
        },
        async local_workflow_destroy(uuid) {
            await this.workflow_destroy({uuid: uuid})
        },
        local_workflow_type_attribute(slug, property)
        {
            return _.get(_.find(this.workflow_types, {slug: slug}), property)
        },
        ...mapActions('Installation', {
            installation_show: 'show',
            installation_clear: 'clear',
            installation_clear_item: 'clear_item',
        }),
        ...mapActions('InstallationAccount', {
            installation_account_index: 'index',
            installation_account_destroy: 'destroy',
            installation_account_clear: 'clear',
        }),
        ...mapActions('Workflow', {
            workflow_index: 'index',
            workflow_store: 'store',
            workflow_destroy: 'destroy',
            workflow_clear: 'clear',
            workflow_clear_item: 'clear_item',
        }),
    },
}
</script>

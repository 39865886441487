<template>
    <ul class="c-custom-dropdown white u-hfull pa-2">
        <li
            @click="localTrigger({ ...item, visibility: item.visibility === 'external' ? 'internal' : 'external' })"
            class="pa-2 u-rounded-corners u-cursor-pointer md-subtitle-1 c-hover-state-light"
        >
            <div class="u-flex-center-y">
                <div class="u-flex align-start">
                    <a-icon size="20" color="grey darken-1" style="margin-top: 2px;">public</a-icon>
                    <div class="ml-2">
                        <h3 class="md-subtitle-2 grey--text text--darken-3">Public</h3>
                        <p class="md-caption font-italic grey--text text--darken-1 mb-0">Share with External Collaborators</p>
                    </div>
                </div>
                <a-spacer></a-spacer>
                <g-toggle
                    :value="item.visibility === 'external'"
                    @change="localTrigger({ ...item, visibility: item.visibility === 'external' ? 'internal' : 'external' })"
                    :color="item.visibility === 'external' ? 'green' : 'grey darken-4'"
                    custom-bg dense-xl
                />
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        item: {
            type: Object
        },
        action: {
            type: Function
        }
    },

    methods: {
        localTrigger (props) {
            if (this.action) this.action(props)
            this.$emit('change', props)
        }
    }
}
</script>

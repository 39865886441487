<template>
    <v-list-item v-bind="$attrs" v-on="$listeners">
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
    </v-list-item>
</template>

<script>
import { VListItem } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VListItem }
}
</script>

<template>
    <PartialMetaLayout
        title="Test Case Statuses"
        subtitle="Customize labels associated with test cases"
        :loading="loading"
    >
        <div>
            <draggable v-if="meta_list && meta_list.length" v-model="meta_list" :options="{handle: '.js-drag-handle'}" tag="div" class="u-elevation-custom-1 white u-rounded-corners">
                <a-card flat v-for="(meta, index) in meta_list" :key="meta.id">
                    <a-divider v-if="index !== 0" class="grey lighten-4"></a-divider>
                    <a-card-text class="pa-0">
                        <a-container container--fluid grid-list-xl class="pa-4 px-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="px-0" v-if="check_meta_permission('update')">
                                    <a-icon class="grey--text text--lighten-1 u-cursor-pointer js-drag-handle">drag_indicator</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-subtitle-1 font-weight-medium mb-0">{{ meta.value }}</h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-chip color="primary" text-color="white" small>{{ meta.status }}</a-chip>
                                </a-flex>
                                <a-flex shrink pl-1 v-if="check_meta_permission('update')">
                                    <a-btn color="grey darken-2" @click="local_edit(meta.id)" text icon small>
                                        <a-icon small>edit</a-icon>
                                    </a-btn>
                                </a-flex>
                                <!-- <a-flex shrink pl-0 v-if="check_meta_permission('destroy')">
                                    <a-tooltip bottom v-if="meta.projects_count > 0">
                                        <template v-slot:activator="{ on }">
                                            <a-btn color="grey darken-2" @click="local_destroy(meta.id)" v-on="on" text icon small disabled>
                                                <a-icon small>delete</a-icon>
                                            </a-btn>
                                        </template>
                                        <span class="text-center">You have {{ meta.projects_count }} projects under this meta.<br>Please move them to different meta to delete.</span>
                                    </a-tooltip>
                                    <a-btn v-else color="grey darken-2" @click="local_destroy(meta.id)" text icon small>
                                        <a-icon small>delete</a-icon>
                                    </a-btn>
                                </a-flex> -->
                                <a-flex shrink pl-0 v-if="check_meta_permission('destroy')">
                                    <a-tooltip bottom v-if="meta.testcases_count > 0">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <a-btn color="grey darken-2" text icon small disabled>
                                                    <a-icon small>delete</a-icon>
                                                </a-btn>
                                            </div>
                                        </template>
                                        <span>You cannot delete this value because it is associated with a project</span>
                                    </a-tooltip>
                                    <a-btn v-else color="grey darken-2" @click="local_destroy(meta.id)" text icon small>
                                        <a-icon small>delete</a-icon>
                                    </a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-card-text>
                </a-card>
            </draggable>
            <a-card v-if="check_meta_permission('store')" class="u-elevation-custom-1" :class="{ 'mt-3': meta_list && meta_list.length }">
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                    <a-container container--fluid grid-list-xl class="py-2">
                        <a-layout wrap align-center>
                            <a-flex shrink class="pr-0">
                                <a-icon color="grey" size="20">add</a-icon>
                            </a-flex>
                            <a-flex pl-0>
                                <a-text-field
                                    v-model="meta_item_value"
                                    placeholder="Type picklist value and hit enter"
                                    solo flat hide-details
                                    @keyup.enter="local_store()"
                                >
                                </a-text-field>
                                </a-flex>
                            <a-flex shrink>
                                <a-menu bottom offset-y>
                                    <template v-slot:activator="{ on }">
                                        <a-chip class="px-2" color="grey darken-2" text-color="white" small left v-on="on">
                                            {{ meta_item_status }}
                                            <a-icon class="ml-1" color="white" size="18">arrow_drop_down</a-icon>
                                        </a-chip>
                                    </template>
                                    <a-list>
                                        <a-list-item v-for="(status, index) in statuses" :key="'status_'+index" @click="local_meta_status_select(status)">
                                            <a-list-item-title>{{ status }}</a-list-item-title>
                                        </a-list-item>
                                    </a-list>
                                </a-menu>
                            </a-flex>
                            <a-flex shrink class="pr-4">
                                <a-btn color="grey darken-2" text icon small @click="local_store()">
                                    <a-icon small>keyboard_return</a-icon>
                                </a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
            <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="meta_response.server && meta_response.server.errors && meta_response.server.errors.value && meta_form_mode == 'add'">
                <a-icon size="16" color="red darken-2">warning</a-icon>
                {{ meta_response.server.errors.value[0] }}
            </span>
        </div>
        <a-dialog v-model="dialog_meta_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>view_column</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (meta_form_mode == 'add') ? 'Create Picklist Value' : 'Edit Existing Picklist Value' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (meta_form_mode == 'add') ? 'You can add new picklist value to the system.' : 'You can edit existing picklist value.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Label</label>
                                    <a-text-field
                                        v-model="meta_item.value"
                                        placeholder="Enter Label"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="meta_response.server && meta_response.server.errors && meta_response.server.errors.value  && meta_form_mode == 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ meta_response.server.errors.value[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Enter the label to be selected.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Status</label>
                                    <a-select
                                        v-model="meta_item.status"
                                        :items="statuses"
                                        placeholder="Status"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-select>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="meta_response.server && meta_response.server.errors && meta_response.server.errors.status">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ meta_response.server.errors.status[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Select the status for the label.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_update()" :loading="loading" :disabled="loading">Save Value</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_meta_form = !dialog_meta_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
    </PartialMetaLayout>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MetaPermission from '@/mixins/mixin-meta-permission'
import { TabMeta } from '@/config/config-tab-components'
import { PartialMetaLayout } from './Partials'

export default {
    mixins: [MetaPermission],
    components: {
        TabMeta, PartialMetaLayout
    },
    data() {
        return {
            dialog_meta_form: false,
            statuses: ['Open', 'Passed', 'Failed', 'Invalid'],
            meta_item_status: 'Open',
            meta_item_value: '',
        }
    },
    mounted() {
        this.local_index()
    },
    watch:{
        dialog_meta_form(newValue, oldValue) {
            if (newValue === false) {
                this.meta_clear_item()
            }
        },
    },
    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Types & Statuses', to: '/settings/metas', exact: true})
            return breadcrumbs
        },
        meta_list: {
            get() {
                return this.$store.state.Meta.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },
        ...mapState('Meta', {
            meta_item: 'item',
            meta_filters: 'filters',
            meta_form_mode: 'form_mode',
            meta_response: 'response',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            if (this.check_meta_permission('index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.meta_clear()
            await this.meta_index({
                'filter[type]': 'testcase_status',
                'include': 'testcasesCount',
                'sort': 'order'
            })
        },
        async local_edit(id) {
            await this.meta_select({id: id})
            this.dialog_meta_form = true
        },
        async local_create() {
            await this.meta_clear_item()
            this.dialog_meta_form = true
        },
        async local_store() {
            this.meta_item.type = 'testcase_status'
            this.meta_item.value = this.meta_item_value
            this.meta_item.status = this.meta_item_status
            await this.meta_store(this.meta_item)
            if (this.meta_response.status === 'success') {
                this.meta_item_value = ''
                this.meta_item_status = 'Open'
                await this.meta_clear_item()
            }
        },
        async local_update(){
            await this.meta_update(this.meta_item)
            if (this.meta_response.status === 'success') {
                this.meta_item_status = 'Open'
                this.dialog_meta_form = false
                await this.meta_clear_item()
            }
        },
        async local_meta_status_select(status) {
            this.meta_item_status = status
        },
        async local_destroy(id) {
            await this.meta_destroy({id: id})
        },
        async local_reorder(list) {
            this.meta_reorder({list: list})
        },
        ...mapActions('Meta', {
            meta_index: 'index',
            meta_store: 'store',
            meta_update: 'update',
            meta_select: 'select',
            meta_destroy: 'destroy',
            meta_reorder: 'reorder',
            meta_clear: 'clear',
            meta_clear_item: 'clear_item',
        }),
    },
}
</script>

<template>
    <div class="u-flex amber lighten-5 pa-2" style="border-radius: 22px;">
        <div class="align-center justify-center u-flex c-warning-info amber darken-3">
            <a-icon color="white" size="16">info</a-icon>
        </div>
        <p class="c-error-info mb-0 brown--text text--darken-2">{{ errorMessage }}</p>
    </div>
</template>

<script>
export default {
    props: {
        errorMessage: {
            type: String,
            default: null,
        }
    },
}
</script>

<style scoped>
.c-warning-info{
    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
}
.c-error-info {
    margin-bottom: 0px !important;
    margin-top: 5px !important;
    margin-left: 12px !important;
}
</style>
<template>
    <a-card class="u-relative white u-rounded-corners-lg">
        <div class="text-center pa-6">
            <a-avatar size="52" color="deep-orange darken-2">
                <a-avatar size="50" color="white">
                    <a-avatar size="48" color="deep-orange darken-2">
                        <a-icon size="24" color="white">warning</a-icon>
                    </a-avatar>
                </a-avatar>
            </a-avatar>

            <div class="mt-3">
                <h3 class="md-heading-6 grey--text text--darken-3 mb-3">Are you sure you want to <br/> delete {{ localGetCount > 0 ? localGetCount : '' }} {{ localGetModule }}?</h3>
                <span class="md-subtitle-1 grey--text text--darken-1">This action cannot be undone.</span>
            </div>

            <div class="u-flex-center mt-6">
                <a-btn class="deep-orange darken-2 white--text u-rounded-corners mr-3" :disabled="!localGetCount" :loading="loading" depressed @click="$emit('confirm')">Yes, Remove</a-btn>
                <a-btn class="grey lighten-3 grey--text text--darken-1 u-rounded-corners" depressed @click="$emit('close')">Cancel</a-btn>
            </div>
        </div>
    </a-card>
</template>

<script>
export default {
    props: {
        module: {
            type: String
        },
        ucCount: {
            type: Number
        },
        scCount: {
            type: Number
        },
        tcCount: {
            type: Number
        },
        loading: {
            type: Boolean
        },
    },

    computed: {
        localGetModule () {
            switch (this.module) {
                case 'all-uc': return this.localGetCount > 1 ? 'Use Cases' : 'Use Case'
                case 'all-sc': return this.localGetCount > 1 ? 'Success Criteria' : 'Success Criterion'
                case 'all-tc': return this.localGetCount > 1 ? 'Test Cases' : 'Test Case'
            }
        },

        localGetCount () {
            switch (this.module) {
                case 'all-uc': return this.ucCount
                case 'all-sc': return this.scCount
                case 'all-tc': return this.tcCount
            }
        }
    }
}
</script>

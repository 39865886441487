<template>
    <div class="c-summary-tile u-relative u-rounded-corners-xl u-hfull u-flex flex-column">
        <loader-confidence-score-summary v-if="mixLoading" />

        <template v-else>
            <template v-if="localIsEmpty || !mixHasPermission('poc_confidence_score.index')">
                <partial-empty-state-summary
                    :canView="mixHasPermission('poc_confidence_score.index')"
                    title="Confidence Score"
                    icon="scoreboard"
                    class="pb-4"
                    iconSize="26"
                    iconOutlined
                >
                    <template #description>There is no confidence score setup.</template>
                </partial-empty-state-summary>
            </template>

            <template v-else>
                <div class="u-relative pa-5">
                    <h3 class="md-body-2 grey--text text--darken-1 u-font-weight-semibold u-tracking">
                        Accumulated Confidence Score
                    </h3>

                    <div class="mt-3 u-flex" style="column-gap: 16px;">
                        <DoughnutChart
                            :chart-data="chartData"
                            :chart-options="chartOptions"
                            :width="64"
                            :height="64"
                        />
                        <div>
                            <h3 class="u-font-20 u-tracking-tight grey--text text--darken-4 u-font-weight-semibold">
                                {{ localConfScorePercentage }}
                            </h3>
                            <h3 class="md-body-2 grey--text text--darken-2 font-weight-medium mt-1">
                                {{ scoreOverall.current ? scoreOverall.current : '-' }} / {{ scoreOverall.total }}
                            </h3>
                        </div>
                    </div>

                    <div v-if="updatedInfo" class="md-caption mt-4 font-weight-medium grey--text text--darken-1 u-flex-center-y" style="column-gap: 8px;">
                        <span>Updated <g-smart-time :timestamp="updatedInfo.updated_at" showPastFormat /></span>
                        <a-icon size="6" color="grey lighten-1">fiber_manual_record</a-icon>
                        <span>{{ updatedInfo.updated_by ? updatedInfo.updated_by.name : '' | truncateText(14) }}</span>
                    </div>
                </div>

                <a-divider></a-divider>

                <div class="pa-5 u-overflow-hidden">
                    <template v-for="(section, index) in localSectionsList">
                        <div :key="section.id" :class="[{ 'mt-4': index !== 0 }]">
                            <div class="mb-3 u-flex-center-y">
                                <h3 class="md-body-2 grey--text text--darken-2 font-weight-medium" :title="section.name">{{ section.name | truncateText(15) }}</h3>
                                <a-spacer></a-spacer>
                                <span class="grey lighten-3 u-rounded-corners-full py-1 px-2 md-caption font-weight-medium grey--text text--darken-1">
                                    {{ section.sectionCurrent ? section.sectionCurrent : '-' }} / {{ section.sectionTotal }} <span v-if="section.sectionPercent">({{ section.sectionPercent }}%)</span>
                                </span>
                            </div>
                            <a-sheet class="grey lighten-1 u-rounded-corners" height="4">
                                <a-sheet
                                    :width="section.sectionPercent + '%'"
                                    class="blue accent-2 u-hfull u-rounded-corners"
                                    style="transition: width 0.25s ease-in"
                                />
                            </a-sheet>
                        </div>
                    </template>
                </div>
            </template>

            <partial-bottom-gradient
                v-if="scoreSections.length > 3"
                :height="noFetch ? 60 : 38"
                :flat="noFetch"
            />
        </template>

        <partial-project-summary-actions
            v-if="mixHasPermission('poc_confidence_score.index') && !mixLoading"
            :showExpand="!(noFetch || scoreSections.length <= 3)"
            @expand="$emit('expand')"
            @open="mixOpenInNewWindow('projects-confidence-score')"
            :showActions="noFetch"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'
import { Doughnut as DoughnutChart } from 'vue-chartjs/legacy'
import MixinModalStateInjection from '../Mixins/MixinModalStateInjection'
import PartialProjectSummaryActions from './PartialProjectSummaryActions.vue'
import PartialEmptyStateSummary from './PartialEmptyStateSummary.vue'
import PartialBottomGradient from './PartialBottomGradient.vue'
import LoaderConfidenceScoreSummary from '../Loaders/LoaderConfidenceScoreSummary.vue'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
	components: {
        DoughnutChart,
        PartialProjectSummaryActions,
        PartialEmptyStateSummary,
        PartialBottomGradient,
        LoaderConfidenceScoreSummary,
    },

    mixins: [MixinModalStateInjection],

    props: {
        noFetch: {
            type: Boolean,
            default: false
        },
        configMeta: {
            type: Array,
        },
    },

    data () {
        return {
            loading: true,
            loadingChart: false,
            scoreSections: [],
            scoreOverall: { total: 0, current: 0 },
            updatedInfo: {},
            cfIndexParams: {
                'fields[projects]': 'id',
                'filter[confidence_score]': 1,
                'filter[confidence_score_fields]': 1,
                'score_fields[section]': 'id,name',
                'score_fields[fields]': 'id,maximum_value,minimum_value,confidence_score_section_id',
                'score_fields[values]': 'confidence_score_field_id,id,value'
            },
            chartData: {
                labels: [],
                datasets: [{
                    backgroundColor: [],
                    data: [],
                    hoverOffset: 0,
                    cutout: '70%',
                    borderWidth: 1,
                    borderRadius: 4,
                }]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: { legend: false, tooltip: { enabled: false } }
            }
        }
    },

    computed: {
        localSectionsList () {
            return this.noFetch ? this.scoreSections : this.scoreSections.slice(0, 3)
        },

        localIsEmpty () {
            return !_.size(this.scoreSections)
        },

        localGradientShowCondition () {
            return this.localIsEmpty || !this.mixHasPermission('poc_confidence_score.index')
        },

        localConfScorePercentage () {
            return this.scoreOverall.current ? Math.round((this.scoreOverall.current / this.scoreOverall.total) * 100) + '%' + ' ' + 'Confident' : 'No Score'
        },

        localGradientColor () {
            let color = ''
            const { percentage: cfPercentage } = this.mixParentState?.project?.confidence_score || {}
            const configMeta = this.configMeta || this.mixGetSavedSummary?.confidenceScore?.configMeta
            const metaList = _.orderBy(configMeta, 'percentage', 'asc')

            if (cfPercentage > 0) {
                const list = metaList.filter(i => cfPercentage > i.percentage)
                color = metaList[_.size(list)].color
            }

            const softColors = { red: '#FFCDD2', green: '#C8E6C9', yellow: '#FFECB3' }
            return {
                bg: this.$color(color, 'color_bg'),
                bgSoft: softColors[color.split('-')[0]]
            }
        },
    },

    methods: {
        async localIndex () {
            if (!this.mixHasPermission('poc_confidence_score.index')) return

            this.mixSetLoading()
            this.localResetChart()
            if (this.noFetch || this.mixLatestSyncTimeDiff() >= 5) {
                this.localNoFetchSetup()
                setTimeout(() => this.mixClearLoading(), 500)
                return
            }

            await this.localFetchScoreSections()
            this.mixSaveProject({
                confidenceScore: {
                    sections: this.scoreSections,
                    overall: this.scoreOverall,
                    configMeta: this.configMeta,
                    updatedInfo: this.updatedInfo
                }
            })
            this.mixClearLoading()
        },

        localNoFetchSetup () {
            this.scoreSections = this.mixGetSavedSummary.confidenceScore.sections
            this.scoreOverall = this.mixGetSavedSummary.confidenceScore.overall
            this.updatedInfo = this.mixGetSavedSummary.confidenceScore.updatedInfo
            this.localSetupChartData()
        },

        async localFetchScoreSections () {
            const { data: { confidence_score_fields: cfSections, confidence_score_record: cfRecords  }, status } = await this.project_show({
                id: this.mixParentState.projectId,
                stateless: true,
                params: this.cfIndexParams
            })

            if (status !== 'success') return true

            const cfStats = []
            cfSections.forEach(section => {
                const statsObj = { id: section.id, name: section.name }
                statsObj['sectionTotal'] = section.fields.reduce((acc, current) => acc = acc + current.maximum_value, 0)
                statsObj['sectionCurrent'] = section.fields.reduce((acc, { confidence_score_values }) => {
                    acc = acc + (confidence_score_values ? Number(confidence_score_values.value) : 0)
                    return acc
                }, 0)
                statsObj['sectionPercent'] = Math.round((statsObj.sectionCurrent / statsObj.sectionTotal) * 100)
                cfStats.push(statsObj)
            })

            this.loadingChart = true
            this.scoreOverall = { total: cfRecords.total, current: cfRecords.current_score }
            this.updatedInfo = cfRecords.updated_by
            this.scoreSections = cfStats
            this.localSetupChartData()
            this.loadingChart = false
        },

        localSetupChartData () {
            this.chartData.datasets[0].data.push(...[
                this.scoreOverall.current,
                this.scoreOverall.total - this.scoreOverall.current
            ])

            if (this.scoreOverall.current > 0) {
                this.chartData.datasets[0].backgroundColor.push(...[
                    this.localGradientColor.bg,
                    this.localGradientColor.bgSoft
                ])
            }

            if (this.scoreOverall.current === 0) {
                this.chartData.datasets[0].backgroundColor.push('#BDBDBD')
            }

            if (this.scoreOverall.current === this.scoreOverall.total || this.scoreOverall.current === 0) {
                this.chartData.datasets[0].borderWidth = 0
                this.chartData.datasets[0].borderRadius = 0
            }
        },

        localResetChart () {
            this.chartData.datasets[0].data = []
            this.chartData.datasets[0].backgroundColor = []
        },

        ...mapActions('StatusUpdate', {
            status_update_index: 'index'
        }),

        ...mapActions('ProjectView', {
            project_show: 'show',
        }),
    }
}
</script>

<template>
	<div class="c-bar-multiple-vertical-chart u-relative u-elevation-custom-1 u-rounded-corners-lg white">
        <div class="c-bar-multiple-vertical-chart__column">
            <VueApexCharts
                ref="barMultipleVerticalChart"
                :options="chart_options"
                :series="series"
                height="195"
                width="536"
            ></VueApexCharts>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    name: "BarMultipleVerticalChart",

    components: { VueApexCharts },

	data: function() {
		return {
			series: [
				{
					name: "Won",
					data: [44, 55, 41, 50],
				},
				{
					name: "Undecided",
					data: [13, 23, 20, 20],
				},
				{
					name: "Lost",
					data: [11, 17, 15, 11],
				},
			],
            chart_options: {
                chart: {
                    type: "bar",
                    width: 536,
                    height: 195,
                    toolbar: {
                        show: false,
                    }
                },
                colors: ['#9C27B0', '#E91E63', '#FF7043'],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "55%",
                        endingShape: "rounded",
                        borderRadius: 4,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                xaxis: {
                    categories: [
						"Q1",
						"Q2",
						"Q3",
						"Q4",
					],
                    labels: {
                        style: {
                            fontFamily: "Inter, sans-serif",
                            fontWeight: 700,
                        },
                    },
                },
                grid: {
                    padding: {
                        top: -16,
                        bottom: 0,
                        left: -6,
                        right: 0
                    }
                },
                yaxis: {
                    labels: {
                        style: {
                            fontFamily: "Inter, sans-serif",
                        },
                        offsetX: -14
                    },
                },
                legend: {
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 700,
                    markers: {
                        radius: 12,
                    }
                },
                fill: {
                    opacity: 1,
                },
                tooltip: {
                    y: {
                        title: {}
                    },
                    z: {}
                },
            },
		}
	}
}
</script>

<style scoped>
.c-bar-multiple-vertical-chart {
    height: 227px;
    width: 568px;
}

.c-bar-multiple-vertical-chart__column {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.c-bar-multiple-vertical-chart__column > div {
    min-height: auto!important;
}
</style>
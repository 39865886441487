<template>
    <div>
        <s-dashboard-layout>
            <template v-slot:territory>
                <a-flex shrink class="py-0 pl-4">
                    <div class="u-border u-rounded-corners">
                        <a-card
                            @click="local_show_filters_pane()"
                            class="px-2 md-body-2 pa-0 white u-rounded-corners xs-mt-1 xl-mt-0 d-flex align-center grey--text text--darken-1 u-cursor-pointer"
                            min-height="36"
                            flat
                        >
                            <a-progress-circular width="2" size="18" color="orange darken-2" class="mr-1" indeterminate v-if="filter_loading"></a-progress-circular>
                            <a-icon size="18" class="mr-1 blue--text" v-else>filter_alt</a-icon>
                            <span class="md-body-2 font-weight-medium grey--text text--darken-2">Filters</span>
                        </a-card>
                    </div>
                </a-flex>
                <a-flex shrink class="pl-4">
                    <PartialSortDropdown
                        @sort="local_sort_by"
                        :sortField="sort_by"
                        :sortDirection="sort_direction"
                        :disabled="sortLoading || local_loading"
                    />
                </a-flex>
            </template>
            <template v-slot:insights>
                <a-flex shrink v-if="$can('reports.index')">
                    <c-dashboard-insights-mini></c-dashboard-insights-mini>
                </a-flex>
            </template>
            <template v-slot:arrows>
                <a-flex shrink class="pl-4">
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-card v-on="on" class="u-rounded-corners u-border u-cursor-pointer u-flex-center" flat height="36" @click="local_stage_scroll_left()">
                                <a-card-text class="px-2 py-1">
                                    <a-icon size="20" class="ma-0">keyboard_arrow_left</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <span>Scroll Left</span>
                    </a-tooltip>
                </a-flex>
                <a-flex shrink class="pl-2 pr-0">
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-card v-on="on" class="u-rounded-corners u-border u-cursor-pointer u-flex-center" flat height="36" @click="local_stage_scroll_right()">
                                <a-card-text class="px-2 py-1">
                                    <a-icon size="20" class="ma-0">keyboard_arrow_right</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <span>Scroll Right</span>
                    </a-tooltip>
                </a-flex>
            </template>
            <!-- <a-divider class="grey lighten-2"></a-divider> -->
            <div v-if="local_loading">
                <a-responsive class="py-4 my-4">
                    <h3 class="mb-1 text-center md-subtitle-1 font-weight-bold grey--text text--darken-1 text-uppercase">Loading dashboard...</h3>
                </a-responsive>
            </div>
            <div v-if="!local_loading" class="kanban-container pt-0 u-dashboard-scroll">
                <div class="kanban-column" v-for="(stage, index) in stage_sections" :key="stage.id">
                    <div class="px-4 py-3 kanban-column-title bg-gray u-rounded-corners">
                        <h2 :title="stage.name" class="mb-0 md-subtitle-1 grey--text text--darken-4 font-weight-bold">{{ stage.name | truncateText(30) }}</h2>
                        <div class="u-flex-center-y u-rounded-corners">
                            <loader-template class="u-rounded-corners-full" width="120" height="17" style="margin-top: 4px;" v-if="dashStatsLoading"></loader-template>
                            <template v-else>
                                <div class="md-body-2 grey--text text--darken-2 font-weight-medium" small v-if="localGetStageProjects(index, { field: 'length' }) > 0">
                                    {{ localGetStageProjects(index, { field: 'length' }) > 0 ?
                                        localGetStageProjects(index, { field: 'length' }) + ' of ' + localGetProjectStats({ field: 'count', stageId: stage.id }) :
                                        localGetProjectStats({ field: 'count', stageId: stage.id })
                                    }}
                                    {{ localGetProjectStats({ field: 'count', stageId: stage.id }) === 1 ? 'project' : 'projects' }}
                                </div>
                                <div class="md-body-2 grey--text text--darken-2 font-weight-medium" small v-if="!localGetStageProjects(index, { field: 'length' })">
                                    No Projects
                                </div>
                            </template>
                            <a-spacer></a-spacer>
                            <loader-template class="u-rounded-corners-full" width="120" height="17" style="margin-top: 4px;" v-if="dashStatsLoading"></loader-template>
                            <g-currency
                                v-else
                                tag="span"
                                class="md-body-2 font-weight-medium green--text text--darken-1"
                                :value="localGetProjectStats({ stageId: stage.id })"
                            />
                        </div>
                    </div>
                    <div class="pa-2 kanban-column-content u-hfull bg-gray mt-3" style="border-radius: 6px 6px 0px 0px;">
                        <draggable
                            v-model="stage_sections[index].projects"
                            :id="stage_sections[index].id"
                            :options="{group: 'stages'}"
                            handle=".js-drag"
                            :move="(e) => local_check_plan_on_project_move({ ...e, oldStageIndex: index, meta: stage.meta, trigger_type: 'drag' })"
                            @drag-start="dragStart = true"
                            @end="local_project_stage_update_init({ ...$event, oldStageIndex: index, meta: stage.meta, trigger_type: 'drag' })"
                            draggable=".js-draggable-file-list-item"
                            class="kanban-column-target"
                        >
                            <!-- TODO: Remove after confirming new design -->
                            <template v-if="!localHasFlagDashboardNew">
                                <div
                                    v-for="project in stage_sections[index].projects"
                                    :key="project.id"
                                    :id="project.id"
                                    @mouseenter="localFetchProjectMeta(project)"
                                    @mouseleave="localClearProjectMetaFetch"
                                    :class="{ 'js-draggable-file-list-item': local_permission_validate('projects.update-only') }"
                                    class="c-project-card u-shadow"
                                    style="position: relative;"
                                >
                                    <s-project-tile-old
                                        handle="js-drag"
                                        :project="project"
                                        :stage="stage"
                                        :archiveLoading="archive_loading"
                                        :metaLoading="lazyProjectMetaLoading"
                                        :configMeta="configuration_meta"
                                        @archive="local_project_stage_update_init"
                                        @mark-complete="local_project_stage_update_init"
                                        @update-status="local_project_post_create($event, index)"
                                        @create-note="local_project_note_create"
                                        @create-task="local_project_task_create"
                                    ></s-project-tile-old>
                                </div>
                            </template>
                            <template v-else>
                                <div
                                    v-for="(project, project_index) in stage_sections[index].projects"
                                    :key="project.id"
                                    :id="project.id"
                                    :class="[
                                        { 'js-draggable-file-list-item': local_permission_validate('projects.update-only') },
                                        { 'mt-0': project_index === 0 }
                                    ]"
                                    class="c-project-card u-shadow-md"
                                    style="position: relative;"
                                >
                                    <s-project-tile
                                        handle="js-drag"
                                        :project="project"
                                        :stage="stage"
                                        :archiveLoading="archive_loading"
                                        :metaLoading="lazyProjectMetaLoading"
                                        :configMeta="configuration_meta"
                                        @open-summary="local_open_summary_view(project, stage)"
                                        @archive="local_project_stage_update_init"
                                        @mark-complete="local_project_stage_update_init"
                                        @update-status="local_project_post_create($event, index)"
                                        @create-note="local_project_note_create"
                                        @create-task="local_project_task_create"
                                    ></s-project-tile>
                                </div>
                            </template>
                            <a-btn
                                color="primary"
                                class="mt-4 u-wfull"
                                text small
                                v-if="stage_sections[index].meta && (stage_sections[index].links && stage_sections[index].links.next)"
                                @click="local_stage_projects_load(stage.id, stage_sections[index].meta.current_page + 1, stage_sections[index])"
                            >
                                <a-icon size="16" class="mr-2" color="grey darken-3">autorenew</a-icon>
                                Load More
                            </a-btn>
                        </draggable>
                    </div>
                </div>
            </div>
        </s-dashboard-layout>

        <!-- STAGE-COMPLETED-META-FORM -->
        <a-dialog v-model="dialog_stage_completed" max-width="640" persistent>
            <a-card>
                <div class="py-6 px-7 u-flex-center-y">
                    <div class="u-flex-center-y">
                        <a-avatar size="40" color="indigo darken-2">
                            <a-icon size="24" color="white">
                                <slot name="icon-name">domain_verification</slot>
                            </a-icon>
                        </a-avatar>
                        <h3 class="ml-3 text-h6 indigo--text text--darken-3 font-weight-medium">
                            <slot name="title">Update Stage</slot>
                        </h3>
                    </div>
                    <a-spacer></a-spacer>
                    <a-icon size="20" color="grey darken-2" @click="local_project_stage_update_cancel(project_move_parameters)">clear</a-icon>
                </div>
                <a-divider></a-divider>

                <div class="u-flex" :class="[currentProjectResulTab === 'stageUpdate' ? 'c-slide-none' : 'c-slide-full']">
                    <div style="min-width: 640px">
                        <a-sheet class="pt-5 pa-6 px-7 u-overflow-y" max-height="600">
                            <a-sheet>
                                <p class="mb-3 md-subtitle-1 font-weight-medium grey--text text--darken-2">Are you sure you want to update project stage?</p>
                                <div class="u-flex-center-y">
                                    <span class="md-subtitle-1 font-weight-medium grey--text text--darken-3">{{ project_move_parameters && project_move_parameters.stage_from_title | truncateText(32) }}</span>
                                    <a-icon size="32" color="grey darken-1" class="mx-3">trending_flat</a-icon>
                                    <span class="md-subtitle-1 font-weight-medium indigo--text text--darken-2">{{ project_move_parameters && project_move_parameters.stage_to_title | truncateText(32) }}</span>
                                </div>
                            </a-sheet>

                            <div class="mt-5 mb-0">
                                <span class="mb-3 md-md-body-2 grey--text text--darken-2 d-inline-block">Project Result</span>
                                <a-autocomplete
                                    v-model="project_move_parameters.result_id"
                                    :items="result_list"
                                    item-text="value"
                                    item-value="id"
                                    placeholder="Select Result"
                                    background-color="grey lighten-5"
                                    :disabled="mixinIsLoading('fetch-results')"
                                    :menu-props="{ rounded: 'lg', nudgeBottom: '4px' }"
                                    @input="local_select_project_result_detail(project_move_parameters.result_id)"
                                    class="u-border u-rounded-corners-lg"
                                    solo flat hide-details
                                ></a-autocomplete>
                            </div>
                            <SharedValidationFormError
                                identifier="result_id"
                            />
                            <s-reason-comment
                                v-if="project_move_parameters.result_id && currentProjectResulTab === 'stageUpdate'"
                                :projectComment="local_project_comment"
                                :response="project_response"
                                :isModalOpen="!!(project_move_parameters.result_id)"
                                :project-reason-list="project_result_reason_list"
                                :reason-loading="loading_reason"
                                :move-params="project_move_parameters"
                                :result-item="local_result_item"
                                @error="evt => hasStageMoveError = evt"
                                @updateComment="(val) => local_project_comment = val"
                                @updateReason="local_add_project_result_reason_list"
                                class="mt-5"
                            ></s-reason-comment>
                        </a-sheet>
                    </div>

                    <a-sheet class="pt-5 pb-12 pa-6 px-7 u-overflow-y" min-width="640" max-height="600" v-if="currentProjectResulTab === 'competitors'">
                        <div :class="[{ 'mt-4': index !== 0 }]" :key="compType.id" v-for="(compType, index) in competitor_types">
                            <h4 class="mb-1 md-body-2 font-weight-medium grey--text text--darken-1">{{ compType.value }}</h4>
                            <SCombobox
                                :list="localFilteredComps(compType.id)"
                                :selectedList="localGetCompetitorOfType(compType.id, 'list')"
                                :canCreate="$can('projects.update-only') && $can('competitors.store') && $can('projects-competitors.update')"
                                :canUpdate="$can('projects.update-only') &&  $can('projects-competitors.update')"
                                :attachId="`competitor-${compType.id}`"
                                item-text="name"
                                placeholderType="Competitor"
                                :selectionId="compType.id"
                                :selectionType="compType.selection_type && !localHasFlagCompetitor ? compType.selection_type : 'multiple'"
                                :totalCompPage="totalCompPage"
                                :currCompPage="currCompPage"
                                :lazyLoadCompState="lazyLoadCompState"
                                @search="localSearchCompetitors($event, compType.id)"
                                @openDropdown="localShowCompSearchInput(compType.id, $event)"
                                @create="localCreateCompTag({ ...$event, category: compType })"
                                @lazyLoadCompetitors="locallazyLoadCompetitors(compType.id, $event)"
                                @destroy="localDestoryTag($event, compType.id)"
                            >
                                <template #selection="{ item, index, selectionIndex, confirmIndex }">
                                    <SCompetitorTag
                                        @destroy="localRemoveCompetitor($event, compType.id)"
                                        @click="localOpenEditCompDialog(item, compType)"
                                        :canUpdate="$can('projects.update-only') && $can('projects-competitors.update')"
                                        :tag="item"
                                        :hideClearIcon="!$can('projects.update-only')"
                                        class="mb-1"
                                        :class="[
                                            { 'c-ring-blue': selectionIndex === index },
                                            { 'c-ring-red u-opacity-70': confirmIndex === index }
                                        ]"
                                    />
                                </template>
                            </SCombobox>
                            <SharedValidationFormError
                                :identifier="compType.id"
                            />
                        </div>
                    </a-sheet>
                </div>
                <div class="u-flex align-center pa-6 px-7" style="borderTop: 1px solid #f2f2f2;">
                    <a-btn 
                        v-if="localShowCompOnComplete && currentProjectResulTab === 'stageUpdate'" 
                        @click="localValidateResult()" 
                        :disabled="!project_move_parameters.result_id || hasStageMoveError" 
                        :loading="!loading_reason && loading" 
                        class="indigo darken-2 white--text u-rounded-corners" 
                        depressed
                    >
                        <slot name="action-label">Next</slot>
                    </a-btn>
                    <a-btn v-else depressed class="indigo darken-2 white--text u-rounded-corners" @click="local_project_stage_update_confirm(project_move_parameters, 'completed')" :disabled="!project_move_parameters.result_id || mixinIsLoading('stage-update-complete')" :loading="mixinIsLoading('stage-update-complete')">Update Project</a-btn>
                    <a-btn v-if="localShowCompOnComplete && currentProjectResulTab === 'competitors'" depressed class="ml-3 grey--text text--darken-1 u-rounded-corners" @click="currentProjectResulTab = 'stageUpdate'">Back</a-btn>
                    <a-btn depressed class="ml-3 grey--text text--darken-1 u-rounded-corners" @click="local_project_stage_update_cancel(project_move_parameters)">Cancel</a-btn>
                </div>
            </a-card>
        </a-dialog>

         <!-- Competitor Edit Dialog -->
        <a-dialog v-model="dialogCompetitorView" max-width="656">
            <SCompetitorView
                :isOpen="dialogCompetitorView"
                :item="competitorViewItem"
                :isLoading="mixinIsLoading('competitor-show')"
                @delete="localRemoveCompetitor"
                @close="dialogCompetitorView = false"
            />
        </a-dialog>

        <!-- NOTE-FORM -->
        <a-dialog v-model="dialog_project_note_form" scrollable max-width="800" persistent>
            <s-note-form
                :project-title="local_project_title"
                :item="note_item"
                :loading="loading"
                :is-open="dialog_project_note_form"
                @store="local_project_note_store"
                @close="local_project_note_close"
            ></s-note-form>
        </a-dialog>

        <!-- TASK-MILESTONE-FORM -->
        <a-dialog v-model="dialog_project_task_form" scrollable max-width="1000" persistent>
            <s-project-task-form
                :project-title="local_project_title"
                :task-item="task_item"
                :milestone-item="milestone_item"
                :collab-list="collaborator_list"
                :milestone-list="milestone_list"
                :project-id="local_project_id"
                :loading="loading"
                :is-open="dialog_project_task_form"
                @store-task="local_project_task_store"
                @store-milestone="local_project_milestone_store"
                @close="local_project_task_close"
            ></s-project-task-form>
        </a-dialog>

        <!-- STATUS-UPDATE-FORM -->
        <a-dialog max-width="800" v-model="dialog_project_status_form" persistent>
            <s-status-update
                :item="status_update_item"
                :response="status_update_response"
                :project-title="local_project_title"
                :loading="loading"
                @store="local_project_post_store"
                @close="local_project_post_close"
            ></s-status-update>
        </a-dialog>

        <!-- Filter sidebar -->
        <a-responsive class="c-filters-side-pane elevation-12" style="z-index: 200" :class="{'visible': project_pane_filters}">
            <div style="position: relative">
                <div class="font-weight-medium white" style="position: sticky; top: 0;">
                    <a-layout align-center class="pa-6">
                        <a-flex>
                            <div class="d-flex">
                                <a-icon size="24" color="grey darken-4">filter_alt</a-icon>
                                <span class="pl-1" style="fontSize: 20px">Filters</span>
                            </div>
                        </a-flex>
                        <a-spacer></a-spacer>
                        <a-flex shrink>
                            <a-btn small icon @click="local_revert_filter()">
                                <a-icon size="20" color="grey">cancel</a-icon>
                            </a-btn>
                        </a-flex>
                    </a-layout>
                    <a-divider></a-divider>
                </div>
                <div ref="task_pane_filters" class="c-filter-content">
                    <a-layout class="px-6" column>
                        <a-flex xs12 class="mt-5">
                            <div class="d-flex justify-space-between">
                                <label class="md-body-1 font-weight-medium grey--text text--darken-2">Territories</label>
                                <div class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer" v-if="!local_check_selected_territory" @click="local_clear_selected_territory()">Clear</div>
                            </div>
                            <a-autocomplete
                                v-model="selected_territory"
                                :items="filterListTerritories"
                                ref="filter_dropdown"
                                item-text="label"
                                item-value="id"
                                background-color="grey lighten-5"
                                :disabled="loading"
                                class="pa-0 u-border mt-2"
                                :class="{'py-1 c-combo-border': selected_territory && selected_territory.length}"
                                :search-input.sync="search"
                                @input="local_selected_territory(selected_territory)"
                                solo flat hide-selected multiple hide-details small-chips return-object
                            >
                                <template v-slot:selection="{ item, selected }">
                                    <a-chip :input-value="selected" small :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}" class="mx-1 my-1 chip--select-multi">
                                        <div class="d-inline-flex align-center">
                                            <!-- <a-icon size="16" :color="$color(item.color, 'color_text')" class="mr-1">fiber_manual_record</a-icon> -->
                                            <span class="md-body-2" :style="{ color: $color(item.color, 'color_text') }">{{ item.label | truncateText(20) }}</span>
                                            <a-icon size="18" v-if="!(selected_territory.length === 1 && selected_territory[0].value === 'all')" :color="$color(item.color, 'color_text')" class="ml-1 u-cursor-pointer" @click="fetch_territories(item)">cancel</a-icon>
                                        </div>
                                    </a-chip>
                                </template>
                                <template v-slot:item="{ item }">
                                    <div>
                                        <a-chip small :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}" class="mx-1 mb-0 chip--select-multi">
                                            <div class="d-inline-flex align-center">
                                                <!-- <a-icon size="16" :color="$color(item.color, 'color_text')" class="mr-1">fiber_manual_record</a-icon> -->
                                                <span class="md-body-2" :style="{ color: $color(item.color, 'color_text') }">{{ item.label | truncateText(55) }}</span>
                                            </div>
                                        </a-chip>
                                    </div>
                                </template>
                            </a-autocomplete>
                        </a-flex>
                        <a-flex class="mt-5">
                            <div class="d-flex justify-space-between">
                                <label class="md-body-1 font-weight-medium grey--text text--darken-2">Team</label>
                            </div>
                            <div class="mt-2 rounded u-border">
                                <a-menu bottom offset-y max-height="200" class="c-team-menu">
                                    <template v-slot:activator="{ on, value }">
                                        <a-card elevation="0" :ripple="false" height="48" class="py-2 u-rounded-corners u-overflow-hidden c-dashboard-filter-menu lighten-5" v-on="on">
                                            <a-card-text class="px-2 py-1 d-flex justify-space-between">
                                                <div>
                                                    <span class="mx-1 md-body-1 grey--text text--darken-3">{{ local_team_filter.label }}</span>
                                                </div>
                                                <div>
                                                    <a-icon size="24" class="mr-1 ma-0 grey--text text--darken-1">{{ value ? 'arrow_drop_up' : 'arrow_drop_down'}}</a-icon>
                                                </div>
                                            </a-card-text>
                                        </a-card>
                                    </template>
                                    <a-list class="u-list-condensed sadsad">
                                        <a-list-item v-for="item in local_team_filter_list" :key="item.label" @click="local_team_filter_set(item.label, item.value)">
                                            <a-list-item-title class="py-3">
                                                <span class="md-subtitle-1 grey--text text--darken-3">{{ item.label  }}</span>
                                            </a-list-item-title>
                                        </a-list-item>
                                    </a-list>
                                </a-menu>
                            </div>
                        </a-flex>
                        <div class="mt-5">
                            <div class="md-body-1 font-weight-medium grey--text text--darken-2">Health</div>
                            <a-sheet class="mt-1">
                                <a-tooltip bottom :disabled="localHasHealth('green')">
                                    <template v-slot:activator="{ on }">
                                        <a-btn
                                            v-on="on"
                                            height="40"
                                            :class="[local_health_filter('green') ? 'c-radio-btn' : 'u-border']"
                                            depressed outlined class="md-caption mr-4 px-3"
                                            @click="local_toggle_health_filter('green')"
                                        >
                                            <div class="pr-2 pt-1">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="8" cy="8" r="8" fill="#C8E6C9"/>
                                                    <circle cx="7.99999" cy="7.9999" r="5.6" fill="#A5D6A7"/>
                                                    <circle cx="7.99999" cy="8.00005" r="3.2" fill="#388E3C"/>
                                                </svg>
                                            </div>
                                            <span :class="[local_health_filter('green') ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']" style="width: 100%">Green</span>
                                        </a-btn>
                                    </template>
                                    <span>At least one option should be selected</span>
                                </a-tooltip>
                                <a-tooltip bottom :disabled="localHasHealth('yellow')">
                                    <template v-slot:activator="{ on }">
                                        <a-btn
                                            v-on="on"
                                            height="40"
                                            :class="[local_health_filter('yellow') ? 'c-radio-btn' : 'u-border']"
                                            depressed outlined class="md-caption mr-4 px-3"
                                            @click="local_toggle_health_filter('yellow')"
                                        >
                                            <div class="pr-2 pt-1">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="7.99994" cy="8" r="8" fill="#FFECB3"/>
                                                    <circle cx="7.99993" cy="7.9999" r="5.6" fill="#FFE082"/>
                                                    <circle cx="7.99993" cy="8.00005" r="3.2" fill="#FFA000"/>
                                                </svg>
                                            </div>
                                            <span :class="[local_health_filter('yellow') ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']" style="width: 100%">Yellow</span>
                                        </a-btn>
                                    </template>
                                    <span>At least one option should be selected</span>
                                </a-tooltip>
                                <a-tooltip bottom :disabled="localHasHealth('red')">
                                    <template v-slot:activator="{ on }">
                                        <a-btn
                                            v-on="on"
                                            height="40"
                                            :class="[local_health_filter('red') ? 'c-radio-btn' : 'u-border']"
                                            depressed outlined class="md-caption mr-4 px-3"
                                            @click="local_toggle_health_filter('red')"
                                        >
                                            <div class="pr-2 pt-1">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="7.99994" cy="8" r="8" fill="#FFCDD2"/>
                                                    <circle cx="7.99996" cy="7.9999" r="5.6" fill="#EF9A9A"/>
                                                    <circle cx="7.99993" cy="8.00005" r="3.2" fill="#D32F2F"/>
                                                </svg>
                                            </div>
                                            <span :class="[local_health_filter('red') ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']" style="width: 100%">Red</span>
                                        </a-btn>
                                    </template>
                                    <span>At least one option should be selected</span>
                                </a-tooltip>
                            </a-sheet>
                        </div>
                        <div class="mt-5" v-if="localIsConfidenceScoreEnabled && $can('poc_confidence_score.index')">
                            <div class="md-body-1 font-weight-medium grey--text text--darken-2">Confidence Score</div>
                            <a-sheet class="mt-1">
                                <a-tooltip bottom :disabled="localHasConfidenceScore(confidence_score.status)" v-for="confidence_score in local_confidence_score" :key="confidence_score.status">
                                    <template v-slot:activator="{ on }">
                                        <a-btn
                                            v-on="on"
                                            height="40"
                                            :class="[local_confidence_score_filter(confidence_score.status) ? 'c-radio-btn' : 'u-border']"
                                            depressed outlined class="md-caption mr-4 px-3"
                                            @click="local_toggle_confidence_score_filter(confidence_score.status)"
                                        >
                                            <div class="pr-2">
                                                <PartialTileScoreCount
                                                    :value="confidence_score.percentage"
                                                    :color="confidence_score.color"
                                                    :is-zero="!confidence_score.percentage"
                                                />
                                            </div>
                                            <span :class="[local_confidence_score_filter(confidence_score.status) ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']" style="width: 100%">{{ confidence_score.name }}</span>
                                        </a-btn>
                                    </template>
                                    <span>At least one option should be selected</span>
                                </a-tooltip>
                            </a-sheet>
                        </div>
                        <a-flex class="mt-5">
                            <div class="d-flex justify-space-between">
                                <label class="md-body-1 font-weight-medium grey--text text--darken-2">Accounts</label>
                                <div
                                    class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer"
                                    v-if="local_filter_customer_item && local_filter_customer_item.length"
                                    @click="local_filter_customer_item = []"
                                >
                                    Clear
                                </div>
                            </div>
                            <a-autocomplete
                                v-model="local_filter_customer_item"
                                :items="local_customers_list"
                                item-text="name"
                                item-value="id"
                                placeholder="Search Accounts"
                                class="mt-2 pa-0 u-border"
                                :class="{'py-2 c-combo-border': local_filter_customer_item && local_filter_customer_item.length}"
                                background-color="grey lighten-5"
                                :loading="local_loading_customers"
                                @keypress="local_loading_customers = true"
                                :search-input.sync="local_customer_search"
                                @blur="local_customer_search = ''"
                                solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object
                            >
                                <template v-slot:selection="{ parent, item }">
                                    <span class="d-inline-flex align-center u-rounded-corners-full grey lighten-2 pl-3 pr-2 py-2 mx-1 my-1">
                                        <span class="mr-2 md-body-2 grey--text text--darken-4" :title="item.name">{{ item.name | truncateText(20) }}</span>
                                        <a-icon size="16" @click.stop="parent.selectItem(item)" class="u-cursor-pointer">cancel</a-icon>
                                    </span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <a-layout align-center class="py-2">
                                        <span class="md-subtitle-1 grey--text text--darken-3">{{ item.name | truncateText(55) }}</span>
                                    </a-layout>
                                </template>
                                <template #no-data v-if="!mixinIsLoading('customer-search')">
                                    <div style="height: 32px" class="px-4 u-flex-center-y">
                                        <span :class="['md-body-2 body--text text--lighten-1']">{{ local_loading_customers ? 'Loading ...' : 'No Data Found' }}</span>
                                    </div>
                                </template>
                            </a-autocomplete>
                        </a-flex>
                        <a-flex class="mt-5">
                            <div class="d-flex justify-space-between">
                                <label class="md-body-1 font-weight-medium grey--text text--darken-2">Collaborators</label>
                                <div
                                    class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer"
                                    v-if="local_filter_user_item && local_filter_user_item.length"
                                    @click="local_filter_user_item = []"
                                >
                                    Clear
                                </div>
                            </div>
                            <a-autocomplete
                                v-model="local_filter_user_item"
                                :items="local_users_list"
                                item-text="name"
                                item-value="id"
                                placeholder="Search Collaborators"
                                class="mt-2 pa-0 u-border"
                                :class="{'py-2 c-combo-border': local_filter_user_item && local_filter_user_item.length}"
                                background-color="grey lighten-5"
                                :loading="local_loading_users"
                                @keypress="local_loading_users = true"
                                :search-input.sync="local_user_search"
                                @blur="local_user_search = ''"
                                solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object
                            >
                                <template v-slot:selection="{ parent, item }">
                                    <span class="d-inline-flex align-center u-rounded-corners-full grey lighten-2 pl-3 pr-2 py-2 mx-1 my-1">
                                        <span class="mr-2 md-body-2 grey--text text--darken-4" :title="item.name">{{ item.name | truncateText(20) }}</span>
                                        <a-icon size="16" @click.stop="parent.selectItem(item)" class="u-cursor-pointer">cancel</a-icon>
                                    </span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <a-layout align-center class="py-2">
                                        <g-avatar :item="item"></g-avatar>
                                        <g-profile-info :item="item" set-max-width></g-profile-info>
                                    </a-layout>
                                </template>
                                <template #no-data v-if="!mixinIsLoading('collaborator-search')">
                                    <div style="height: 32px" class="px-4 u-flex-center-y">
                                        <span :class="['md-body-2 body--text text--lighten-1']">{{ local_loading_users ? 'Loading ...' : 'No Data Found' }}</span>
                                    </div>
                                </template>
                            </a-autocomplete>
                        </a-flex>
                        <a-flex class="mt-5">
                            <div class="d-flex justify-space-between">
                                <label class="md-body-1 font-weight-medium grey--text text--darken-2">Competitors</label>
                                <div
                                    class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer"
                                    v-if="local_filter_competitor_item && local_filter_competitor_item.length"
                                    @click="local_filter_competitor_item = []"
                                >
                                    Clear
                                </div>
                            </div>

                            <a-autocomplete
                                v-model="local_filter_competitor_item"
                                :items="local_competitors_list"
                                item-text="name"
                                item-value="id"
                                placeholder="Search Competitors"
                                class="mt-2 pa-0 u-border"
                                :class="{'py-2 c-combo-border': local_filter_competitor_item && local_filter_competitor_item.length}"
                                background-color="grey lighten-5"
                                :loading="local_loading_competitors"
                                @keypress="local_loading_competitors = true"
                                :search-input.sync="local_competitor_search"
                                @blur="local_competitor_search = ''"
                                solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object
                            >
                                <template v-slot:selection="{ parent, item }">
                                    <span class="d-inline-flex align-center u-rounded-corners-full grey lighten-2 pl-3 pr-2 py-2 mx-1 my-1">
                                        <span class="mr-2 md-body-2 grey--text text--darken-4" :title="item.name">{{ item.name | truncateText(20) }}</span>
                                        <a-icon size="16" @click.stop="parent.selectItem(item)" class="u-cursor-pointer">cancel</a-icon>
                                    </span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <a-layout align-center class="py-2">
                                        <span class="px-2 py-1 md-body-2 d-inline-block u-rounded-corners blue white--text">{{ item.name | truncateText(55) }}</span>
                                    </a-layout>
                                </template>
                                <template #no-data v-if="!mixinIsLoading('competitor-search')">
                                    <div style="height: 32px" class="px-4 u-flex-center-y">
                                        <span :class="['md-body-2 body--text text--lighten-1']">{{ local_loading_competitors ? 'Loading ...' : 'No Data Found' }}</span>
                                    </div>
                                </template>
                            </a-autocomplete>
                        </a-flex>
                        <a-flex class="mt-5">
                            <div class="d-flex justify-space-between">
                                <label class="md-body-1 font-weight-medium grey--text text--darken-2">Label Tags</label>
                                <div
                                    class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer"
                                    v-if="local_filter_label_tags && local_filter_label_tags.length"
                                    @click="local_filter_label_tags = []"
                                >
                                    Clear
                                </div>
                            </div>
                            <a-autocomplete
                                v-model="local_filter_label_tags"
                                :items="local_label_list"
                                item-text="label"
                                item-value="id"
                                placeholder="Search Label Tags"
                                class="mt-2 pa-0 u-border"
                                :class="{'py-2 c-combo-border': local_filter_label_tags && local_filter_label_tags.length}"
                                background-color="grey lighten-5"
                                :loading="local_loading_tags === 'label'"
                                @keypress="local_tag_search_results('label', 'label-tag-search')"
                                :search-input.sync="local_label_tag_search"
                                @blur="local_label_tag_search = ''"
                                solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object
                            >
                                <template v-slot:selection="{ parent, item }">
                                    <g-tags v-if="item === Object(item) && item" :tag="item" type="Project" @destroy="() => parent.selectItem(item)" :key="item.id"></g-tags>
                                </template>
                                <template v-slot:item="{ item }">
                                    <g-tags :tag="item" type="Project" hide-clear-icon :key="item.id"></g-tags>
                                </template>
                                <template #no-data v-if="!mixinIsLoading('label-tag-search')">
                                    <div style="height: 32px" class="px-4 u-flex-center-y">
                                        <span :class="['md-body-2 body--text text--lighten-1']">{{ local_loading_tags === 'label' ? 'Loading ...' : 'No Data Found' }}</span>
                                    </div>
                                </template>
                            </a-autocomplete>
                        </a-flex>
                        <a-flex class="mt-5">
                            <div class="d-flex justify-space-between">
                                <label class="md-body-1 font-weight-medium grey--text text--darken-2">Product Tags</label>
                                <div
                                    class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer"
                                    v-if="local_filter_product_tags && local_filter_product_tags.length"
                                    @click="local_filter_product_tags = []"
                                >
                                    Clear
                                </div>
                            </div>
                            <a-autocomplete
                                v-model="local_filter_product_tags"
                                :items="local_product_list"
                                item-text="label"
                                item-value="id"
                                placeholder="Search Product Tags"
                                class="mt-2 pa-0 u-border"
                                :class="{'py-2 c-combo-border': local_filter_product_tags && local_filter_product_tags.length}"
                                background-color="grey lighten-5"
                                :loading="local_loading_tags === 'product'"
                                @keypress="local_tag_search_results('product', 'products-tag-search')"
                                :search-input.sync="local_product_tag_search"
                                @blur="local_product_tag_search = ''"
                                solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object
                            >
                                <template v-slot:selection="{ parent, item }">
                                    <g-tags v-if="item === Object(item) && item" :tag="item" type="Project" @destroy="() => parent.selectItem(item)" :key="item.id"></g-tags>
                                </template>
                                <template v-slot:item="{ item }">
                                    <g-tags :tag="item" type="Project" hide-clear-icon :key="item.id"></g-tags>
                                </template>
                                <template #no-data v-if="!mixinIsLoading('products-tag-search')">
                                    <div style="height: 32px" class="px-4 u-flex-center-y">
                                        <span :class="['md-body-2 body--text text--lighten-1']">{{ local_loading_tags === 'product' ? 'Loading ...' : 'No Data Found' }}</span>
                                    </div>
                                </template>
                            </a-autocomplete>
                        </a-flex>
                        <a-flex class="mt-5">
                            <div class="d-flex justify-space-between">
                                <label class="md-body-1 font-weight-medium grey--text text--darken-2">Platform Tags</label>
                                <div
                                    class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer"
                                    v-if="local_filter_platform_tags && local_filter_platform_tags.length"
                                    @click="local_filter_platform_tags = []"
                                >
                                    Clear
                                </div>
                            </div>
                            <a-autocomplete
                                v-model="local_filter_platform_tags"
                                :items="local_platform_list"
                                item-text="label"
                                item-value="id"
                                placeholder="Search Platform Tags"
                                class="mt-2 pa-0 u-border"
                                :class="{'py-2 c-combo-border': local_filter_platform_tags && local_filter_platform_tags.length}"
                                background-color="grey lighten-5"
                                :loading="local_loading_tags === 'platform'"
                                @keypress="local_tag_search_results('platform', 'platform-tag-search')"
                                :search-input.sync="local_platform_tag_search"
                                @blur="local_platform_tag_search = ''"
                                solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object
                            >
                                <template v-slot:selection="{ parent, item }">
                                    <g-tags v-if="item === Object(item) && item" :tag="item" type="Project" @destroy="() => parent.selectItem(item)" :key="item.id"></g-tags>
                                </template>
                                <template v-slot:item="{ item }">
                                    <g-tags :tag="item" type="Project" hide-clear-icon :key="item.id"></g-tags>
                                </template>
                                <template #no-data v-if="!mixinIsLoading('platform-tag-search')">
                                    <div style="height: 32px" class="px-4 u-flex-center-y">
                                        <span :class="['md-body-2 body--text text--lighten-1']">{{ local_loading_tags === 'platform' ? 'Loading ...' : 'No Data Found' }}</span>
                                    </div>
                                </template>
                            </a-autocomplete>
                        </a-flex>
                    </a-layout>
                </div>

                <div class="white" style="position: sticky; bottom: 0;">
                    <a-divider class="mt-16"></a-divider>
                    <a-layout class="px-6 pa-6">
                        <a-flex>
                            <a-btn depressed class="c-btn-filter white--text mr-4" color="indigo darken-1" @click="local_apply_filters()">Filter</a-btn>
                            <a-btn class="grey--text text--darken-3" depressed @click="local_revert_filter()">Cancel</a-btn>
                        </a-flex>
                        <a-spacer></a-spacer>
                        <a-flex shrink>
                            <a-btn class="c-btn-clearAll red--text text--darken-1" depressed @click="local_clear_all_filters()" color="red lighten-5">Clear all</a-btn>
                        </a-flex>
                    </a-layout>
                </div>
            </div>
        </a-responsive>

        <!-- User Upgrade Popup -->
        <a-dialog max-width="400" v-model="dialog_user_upgrade_modal">
            <SUserUpgradeModal @close="dialog_user_upgrade_modal = false" />
        </a-dialog>

        <!-- Admin Upgrade Popup -->
        <a-dialog max-width="600" v-model="dialog_admin_upgrade_modal">
            <SAdminUpgradeModal
                :isOpen="dialog_admin_upgrade_modal"
                @upgrade="{}"
                @close="dialog_admin_upgrade_modal = false"
            >
                <template #plan-info-text>
                    Your current <span class="secondary--text font-weight-bold">Starter</span> plan is limited to {{ $plan('active_projects_limit') }} active projects.
                </template>
            </SAdminUpgradeModal>
        </a-dialog>

        <!-- Project Summary Popup -->
        <a-dialog
            v-model="dialog_project_summary"
            max-width="960"
            content-class="scroll-solid"
        >
            <ModalProjectSummary
                :project="project_summary"
                :isOpen="dialog_project_summary"
                :confScoreMeta="configuration_meta"
                :viewSummaryTags="viewSummaryTags"
                @close="local_close_project_summary()"
                @toggleTagsView="localUpdateSummaryTagPref"
                class="u-wfull grey lighten-5 u-overflow-hidden"
            />
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import DashboardInsightsMiniComponent from './DashboardInsightsMini'
import loadWorkspace from '@/mixins/mixin-workspace'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import mixinErrors from '@/mixins/mixin-module-error-setup'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'
import mixinAutomationHook from '@/mixins/mixin-automation-hook'

import {
    SCombobox,
    SModuleProgress,
    SDashboardLayout,
    SUsersDeck,
    SStatusUpdate,
    SProjectTaskForm,
    SNoteForm,
    SProjectTile,
    SProjectTileOld,
    SReasonComment,
    SCompetitorTypes,
    SCompetitorAddDropdown,
    SCompetitorView,
    SCompetitorTag,
    SUserUpgradeModal,
    SAdminUpgradeModal,
} from '@/config/config-shared-components'
import PartialSortDropdown from './Partials/PartialSortDropdown'
import PartialTileScoreCount from './Partials/PartialTileScoreCount'
import ModalProjectSummary from './Modals/ModalProjectSummary.vue'
import SharedValidationFormError from '../../components/Shared/SharedValidationFormError.vue'
import EventBus from '@/config/config-eventbus'
import { notifier } from '../../helpers/helper-notification'

export default {
    components: {
    'c-dashboard-insights-mini': DashboardInsightsMiniComponent,
    SAdminUpgradeModal,
    SUserUpgradeModal,
    SCombobox,
    SCompetitorTag,
    SModuleProgress,
    SUsersDeck,
    SDashboardLayout,
    SStatusUpdate,
    SProjectTaskForm,
    SNoteForm,
    SProjectTile,
    SProjectTileOld,
    SReasonComment,
    SCompetitorTypes,
    SCompetitorAddDropdown,
    SCompetitorView,
    PartialSortDropdown,
    PartialTileScoreCount,
    ModalProjectSummary,
    SharedValidationFormError
},

    mixins: [
        loadWorkspace,
        mixinLoading,
        mixinErrors,
        mixinAutomationHook,
        mixinGetOrganizationPlan,
    ],

    data() {
        return {
            currentProjectResulTab: 'stageUpdate',
            bulkCompChunkCount: 50,
            tagSearchType: '',
            compBulkAttachTimeout: null,
            dashStatsList: [],
            dashStatsLoading: false,
            createCompetitorItem: { id: 'create', name: 'Create', value: 'create' },
            currentCompList: [],
            compBulkAttachIds: [],
            stage_sections: [],
            result_list: [],
            competitor_types: [],
            competitorList: [],
            competitorSearchTimeout: null,
            lazyProjectTimeout: null,
            lazyProjectMetaLoading: false,
            hoveredProjects: [],
            dialogCompetitorView: false,
            hasStageMoveError: false,
            competitorViewItem: {},
            project_summary: {},
            dragStart: false,

            dialog_project_summary: false,
            dialog_stage_completed: false,
            dialog_project_note_form: false,
            dialog_project_status_form: false,
            dialog_project_task_form: false,

            dialog_user_upgrade_modal: false,
            dialog_admin_upgrade_modal: false,
            project_pane_filters: false,
            viewSummaryTags: false,

            local_project_title: '',
            local_project_id: null,
            local_project_stage_id: null,

            project_move_parameters: {event_type: null, project_id: null, project_title: null, stage_from_id: null, stage_to_id: null, result_id: null},
            local_stage_index: null,
            selected_territory: [],
            user_filters_pref: {},
            search: '',

            project_filters_index: {
                'fields[projects]': 'id,title,deal_amount,health_color,result_id,status,stage_id,start_date,due_date,estimated_start_date,estimated_end_date,customer_name,total_tasks,total_completed_tasks,total_success_criteria,total_completed_success_criteria,active_days,stage_active_days,aggr_territories,aggr_collaborators,created_at',
            },
            stage_load_ids: [],
            project_filters: {},
            local_stage_list: [],
            local_loading: true,
            project_tag_loading: false,
            archive_loading: null,
            local_tags_load: null,
            sort_by: '',
            sort_direction: '',
            sortLoading: false,
            filter: {
                WORKSPACE: 'filter[workspace_id]',
                TERRITORY: 'filter[aggr_territories]',
                STAGE: 'filter[stage_id]',
                INCLUDE: 'include',
                FIELDS: 'fields[projects]',
                COUNT: 'count',
                PAGE: 'page',
                HEALTH:'filter[health_color]',
                CONFIDENCE_SCORE:'filter[confidence_score]',
                CUSTOMER:'filter[customer_id]',
                PROJECT_COLLABORATORS: 'filter[aggr_collaborators]',
                COMPETITOR: 'filter[aggr_competitors]',
                TAG: 'filter[tag]', /* TODO: Remove after confirming new design  */
                LABEL_TAG: 'filter[aggr_label_tags]',
                PRODUCT_TAG: 'filter[aggr_product_tags]',
                PLATFORM_TAG: 'filter[aggr_platform_tags]',
                SORT: 'sort',
                HIERARCHY: 'filter[hierarchy_report]'
            },
            dragPreview: null,
            project_result_reason_list: [],
            loading_reason: false,
            local_project_comment: null,
            local_result_item: {},
            local_confidence_score: [],
            local_noscore_meta: { percentage: null, color: '#E0E0E0', status: 'None', name: 'No Score', type: 'confidence_score', selected: true },
            local_health_colors: [
                { status: 'Green', value: 'green', selected: true },
                { status: 'Yellow', value: 'yellow', selected: true },
                { status: 'Red', value: 'red', selected: true }
            ],
            // customers variables
            local_loading_customers: false,
            search_customer_timeout: null,
            local_customer_search: '',
            customer_filter: {
                FIELDS: 'fields[customers]',
                NAME: 'filter[name]',
                SORT: 'sort',
                COUNT: 'count',
            },
            local_customer_filters: {},
            project_filter_mode: false,
            // Users variables
            local_loading_users: false,
            search_user_timeout: null,
            local_user_search: '',
            user_filter: {
                FIELDS: 'fields[users]',
                NAME: 'filter[user_search]',
                IS_ACTIVE: 'filter[is_active]',
                SORT: 'sort',
                COUNT: 'count',
            },
            local_user_filters: {},
            // competitor variables
            local_loading_competitors: false,
            search_competitor_timeout: null,
            local_competitor_search: '',
            competitor_filter: {
                FIELDS: 'fields[competitors]',
                NAME: 'filter[name]',
                SORT: 'sort',
                COUNT: 'count',
            },
            local_competitor_filters: {},
            // tags variables
            local_loading_tags: false,
            search_tag_timeout: null,
            tag_filter: {
                FIELDS: 'fields[tags]',
                LABEL: 'filter[label]',
                TYPE: 'filter[type]',
                SORT: 'sort',
                COUNT: 'count',
            },
            meta_filters: {
                'filter[type]': 'confidence_score',
                'fields[metas]': 'id,type,status,selection_type,percentage,color',
                'sort': 'order',
            },
            local_tag_filters: {},
            local_filter_customer_item: [],
            local_filter_user_item: [],
            local_filter_competitor_item: [],
            local_filter_label_tags: [],
            local_filter_product_tags: [],
            local_filter_platform_tags: [],
            local_label_tag_search: '',
            local_product_tag_search: '',
            local_platform_tag_search: '',
            local_label_list: [],
            local_product_list: [],
            local_platform_list: [],
            local_filter_health_item: [],
            local_filter_confidence_score_item: [],
            local_loading_clear_all: false,
            filter_loading: false,
            filter_preference: null,

            // Myteam filter
            local_team_filter: {
                label: 'All Projects', value: 'all'
            },
            local_team_filter_list: [],
            filter_list_manager: [
                {'label': 'All Projects', 'value': 'all'},
                {'label': 'Direct Reports', 'value': 'direct_reports'},
                {'label': 'All Reports', 'value': 'all_reports'},
                {'label': 'Assigned to me', 'value': 'self'},
            ],
            filter_list_team: [
                {'label': 'All Projects', 'value': 'all'},
                {'label': 'Assigned to me', 'value': 'self'}
            ],
            configuration_meta: [],
            team_loading: false,
            totalCompPage: 1,
            currCompPage: 1,
            lazyLoadCompState: false,
            isCompetitorUpdate: false,
            compRemoveInd: [],
            totalComp: 0,

            local_users_list: [],
            local_customers_list: [],
            local_competitors_list: [],
            local_set_tag_type: null,
            local_set_tag_type_loading: null,
        }
    },

    computed: {
        filterListTerritories() {
            const result = [];
            result.push({'label': 'All Territories', 'value': 'all', 'color': 'blue-grey-dark'})
            this.territory_list.forEach(element => {
                result.push({'label': element.name, 'value': element.id, 'color': element.color})
            })
            result.push({'label': 'No Territory', 'value': 'none', 'color': 'blue-grey-dark'})
            return result;
        },

        loading: {
            get() {
                return this.$store.state.Interface.loader
            },
            set(value) {
                this.$store.state.Interface.loader = value
            }
        },

        localCompletedStageId() {
            return (this.stage_sections.find(i => i.status === 'completed')).id
        },

        localShowCompOnComplete () {
            const value = this.preference_get_property_by_key('organization.projects.widgets.competitor_stage_complete', 'value')
            return (value === 'Yes' && this.$can('projects-competitors.update'))
        },

        localOrgPreferenceLinkTag () {
            if (this.preference_list && !this.preference_list.length) return
            const index = this.preference_list.findIndex(item => item.key === 'organization.link_competitor_product_tag')
            if (index === -1) return
            return this.preference_list[index].value === 'Yes' ? true : false
        },

        localOrgPreferenceConfidenceScore () {
            const value = this.preference_get_property_by_key('organization.confidence_score', 'value')
            return value === 'Yes'
        },

        // localCheckNoFilters () {
        //     return (!(this.local_health_filter_list().length <= 2) &&
        //     !(this.local_confidence_score_filter_list().length <= 2) &&
        //     !this.local_filter_customer_item.length &&
        //     !this.local_filter_user_item.length &&
        //     !this.local_filter_competitor_item.length &&
        //     !this.local_filter_label_tags.length &&
        //     !this.local_filter_product_tags.length &&
        //     !this.local_filter_platform_tags.length &&
        //     (this.selected_territory.length && this.selected_territory[0].value === 'all') &&
        //     this.local_team_filter.value === 'all')
        // },

        local_check_selected_territory () {
            return this.selected_territory.some(item => item.label === 'All Territories')
        },

        localIsConfidenceScoreEnabled () {
            return this.localOrgPreferenceConfidenceScore && this.localConfidenceScoreFlag
        },

        localConfidenceScoreFlag () {
            const { flag_confidence_score } = this.user_self && this.user_self.organization
            return flag_confidence_score === 1
        },

        localHasFlagCompetitor () {
            return !(this.localHasCheckCompetitorKey() && this.user_self && this.user_self.organization && this.user_self.organization.flag_competitor_preferences === 1)
        },

        localHasFlagDashboardNew () {
            return this.user_self && this.user_self.organization && this.user_self.organization.flag_dashboard_new === 1
        },

        localDefaultSortField () {
            return 'customer_name'
        },

        ...mapState('Stage', {
            stage_list: 'list',
            stage_item: 'item',
        }),

        ...mapState('Project', {
            project_list: 'list',
            project_dash_stats: 'dash_stats',
            project_dash_list: 'dash_list',
            project_item: 'item',
            project_meta: 'meta',
            project_extras: 'extras',
            project_response: 'response',
        }),

        ...mapState('ProjectView', {
            project_saved_items: 'saved_items',
        }),

        ...mapState('Note', {
            note_item: 'item',
            note_response: 'response',
        }),

        ...mapState('Task', {
            task_item: 'item',
            task_response: 'response',
        }),

        ...mapState('Milestone', {
            milestone_item: 'item',
            milestone_list: 'list',
            milestone_response: 'response',
        }),

        ...mapState('Comment', {
            comment_item: 'item',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Territory', {
            territory_list: 'list',
        }),

        ...mapState('Collaborator', {
            collaborator_list: 'list',
        }),

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),

        ...mapState('Preference', {
            preference_list: 'list',
            preference_user_types: 'user_types',
        }),

        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key',
        }),

        ...mapState('StatusUpdate', {
            status_update_item: 'item',
            status_update_response: 'response',
        }),

        ...mapState('User', {
            user_list: 'list',
            user_self: 'self',
        }),

        ...mapState('MetaResultReason', {
            meta_result_reason_list: 'list',
            meta_result_reason_item: 'item',
            meta_result_reason_form_mode: 'form_mode',
            meta_result_reason_response: 'response',
        }),

        ...mapState('Customer', {
            customer_list: 'list',
        }),

        ...mapState('Competitor', {
            competitor_item: 'item',
            competitor_meta: 'meta',
            competitor_list: 'list',
            competitor_response: 'response',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
        })
    },

    mounted() {
        this.local_index()
        EventBus.$on('notification', this.localOnNotification)
    },

    watch: {
        async workspace_current(newValue, oldValue) {
            await this.load_workspace_projects()
            this.local_fetch_stage_stats()
        },
        project_pane_filters (val) {
            if (!val) return
            this.$refs.task_pane_filters.scrollTop = 0
        },
        local_user_search (val) {
            if (!_.trim(val)) return
            this.local_user_search_filter()
        },
        local_customer_search (val) {
            if (!_.trim(val)) return
            this.local_customer_search_filter()
        },
        local_competitor_search (val) {
            if (!_.trim(val)) return
            this.local_competitors_search_filter()
        },
        local_label_tag_search (val) {
            if (!_.trim(val)) return
            this.local_search_filter(val, this.local_set_tag_type, this.local_set_tag_type_loading)
        },
        local_product_tag_search (val) {
            if (!_.trim(val)) return
            this.local_search_filter(val, this.local_set_tag_type, this.local_set_tag_type_loading)
        },
        local_platform_tag_search (val) {
            if (!_.trim(val)) return
            this.local_search_filter(val, this.local_set_tag_type, this.local_set_tag_type_loading)
        },
    },

    methods: {
        async local_index () {
            this.project_clear_saved()
            this.automation_validation_result_status()
            this.meta_result_reason_clear()
            this.territory_clear()
            await this.territory_index({'count': 999, 'fields[territories]': 'id,name,color' })
            await this.meta_index({...this.meta_filters})
            this.local_confidence_score = [this.local_noscore_meta ,...this.meta_list.map(item => ({...item, selected: true, name: item.status}))].reverse()
            await this.local_fetch_preference()
            this.local_set_filter_values()
            this.selected_territory = await this.local_load_territory_filter()
            await this.load_workspace_projects()
            this.local_load_configuration_meta()
            this.local_set_hierarchy_list()
            if (this.localIsConfidenceScoreEnabled) this.confidence_score_aggregate_show()
            this.local_fetch_stage_stats()
            this.local_set_filter_list()

            const pref = this.preference_get_property_by_key('user.config', 'value')
            this.viewSummaryTags = !!(JSON.parse(pref)?.dashboardSummaryTags)
            this.local_loading = false
        },

        async local_set_filter_list () {
            this.local_user_filters[this.user_filter.COUNT] = 5
            await this.user_index(this.local_user_filters)
            this.local_customer_filters[this.customer_filter.COUNT] = 5
            await this.customer_index(this.local_customer_filters)
            this.local_competitor_filters[this.competitor_filter.COUNT] = 5
            await this.competitor_index(this.local_competitor_filters)

            await this.local_set_filter_taglist()

            this.local_users_list = [...this.local_filter_user_item, ...this.user_list]
            this.local_customers_list = [...this.local_filter_customer_item, ...this.customer_list]
            this.local_competitors_list = [...this.local_filter_competitor_item, ...this.competitor_list]
        },

        async local_set_filter_taglist () {
            this.local_tag_filters[this.tag_filter.TYPE] = 'label'
            this.local_tag_filters[this.tag_filter.COUNT] = 5
            await this.tag_index(this.local_tag_filters)
            this.local_label_list = [...this.local_filter_label_tags,...this.tag_list]
            await this.tag_clear()

            this.local_tag_filters[this.tag_filter.TYPE] = 'product'
            this.local_tag_filters[this.tag_filter.COUNT] = 5
            await this.tag_index(this.local_tag_filters)
            this.local_product_list = [...this.local_filter_product_tags,...this.tag_list]
            await this.tag_clear()

            this.local_tag_filters[this.tag_filter.TYPE] = 'platform'
            this.local_tag_filters[this.tag_filter.COUNT] = 5
            await this.tag_index(this.local_tag_filters)
            this.local_platform_list = [...this.local_filter_platform_tags,...this.tag_list]
            await this.tag_clear()
        },

        localOnNotification (args) {
            if (this.$router.isActive()) return

            if (notifier.action(args?.type).isProjectStageUpdate()) {
                const params = this.localConstructNotificationParam(args)
                this.local_project_stage_update_confirm(params, args?.data?.to.status === 'completed')
            }

            if (notifier.action(args?.type).isProjectStatusUpdate()) {
                this.localLoadProjectStage(_.get(args, 'data.module.data.stage_id'))
                this.local_fetch_mini_insights({ onlyHealth: true })
            }
        },

        async local_fetch_stage_stats (args) {
            const { stateless = false, stageId = null } = args || {}
            if (!args) this.dashStatsLoading = true

            const clonedFilters = _.cloneDeep(this.project_filters)
            const removeableFields = ['filter[tag]', 'filter[stage_id]', 'page', 'count']
            removeableFields.forEach(field => delete clonedFilters[field])

            if (stateless) clonedFilters['filter[stage_id]'] = stageId

            await this.project_dash_stats_index(clonedFilters)
            this.dashStatsList = this.project_dash_stats.map(item => {
                const index = this.dashStatsList.findIndex(filter => filter.stage_id === item.stage_id)
                if (index === -1) return item
                return { ...this.project_dash_stats[index], ...item }
            })

            this.dashStatsLoading = false
        },

        localGetProjectStats (args) {
            const { field = 'sum_deal_amount', stageId } = args || {}
            const data = this.dashStatsList.find(i => i.stage_id === stageId)
            if (!data) return ''

            return data[field] || ''
        },

        async local_load_configuration_meta () {
            await this.meta_index({...this.meta_filters})
            this.configuration_meta = _.cloneDeep(this.meta_list)
        },

        async local_fetch_preference() {
            await this.preference_index()
            this.localFetchSummaryTagPref()

            this.filter_preference = this.preference_list.find(item => item.key === 'user.projects.filters')

            if (!this.filter_preference) {
                await this.local_persist_preference()
                return this.sort_by = this.localDefaultSortField
            }

            await this.local_set_filter_pref()
            this.localSetSortProps()

            const parsedValue = JSON.parse(this.filter_preference.value)
            if (parsedValue.sort_by === 'confidence_score' && !(this.localIsConfidenceScoreEnabled && this.$can('poc_confidence_score.index'))) {
                this.sort_by = 'title'
                await this.local_persist_preference()
            }
            if (parsedValue.health_color) this.local_set_health_filter(parsedValue.health_color)
            if (parsedValue.confidence_score) this.local_set_confidence_score_filter(parsedValue.confidence_score)
        },

        async localFetchSummaryTagPref () {
            const dashSummaryTagPref = this.preference_list.find(item => item.key === 'user.config')
            const params = {
                resource_id: this.user_self.id,
                resource_type: 'User',
                label: 'User Preference',
                key: 'user.config',
                is_listable: 0,
                value: JSON.stringify({ dashboardSummaryTags: false }),
            }

            if (!dashSummaryTagPref) {
                await this.preference_filters_store({ ...params })
                this.preference_index()
            }

            if (dashSummaryTagPref && !('dashboardSummaryTags' in JSON.parse(dashSummaryTagPref.value))) {
                await this.preference_filters_update({
                    id: dashSummaryTagPref.id,
                    ...params,
                    value: JSON.stringify({ ...JSON.parse(dashSummaryTagPref.value), dashboardSummaryTags: false })
                })
                this.preference_index()
            }
        },

        localUpdateSummaryTagPref (tagsViewValue) {
            const prefId = this.preference_get_property_by_key('user.config', 'id')
            const value = this.preference_get_property_by_key('user.config', 'value')
            const params = { resource_id: this.user_self.id, resource_type: 'User', label: 'User Preference', key: 'user.config' }

            this.viewSummaryTags = tagsViewValue
            this.preference_filters_update({
                id: prefId,
                ...params,
                value: JSON.stringify({ ...JSON.parse(value), dashboardSummaryTags: tagsViewValue })
            })
        },

        localSetSortProps () {
            const prefValue = JSON.parse(this.filter_preference.value)
            const hasSortProps = prefValue.hasOwnProperty('sort_by')
            if (hasSortProps || (hasSortProps && prefValue.sort_by)) return

            Object.assign(prefValue, { sort_by: this.localDefaultSortField, sort_direction: '' })
            this.sort_by = this.localDefaultSortField
            this.filter_preference.value = JSON.stringify(prefValue)
            this.local_persist_preference()
        },

        async local_set_filter_pref () {
            if (!this.filter_preference) return
            const local_filter_value = JSON.parse(this.filter_preference.value)
            for (let filtervalue in local_filter_value) {
                switch (filtervalue) {
                    case 'health_color':
                        this.local_filter_health_item = this.local_health_filter_list()
                        break;
                    case 'confidence_score':
                        this.local_filter_confidence_score_item = this.local_confidence_score_filter_list()
                        break;
                    case 'accounts':
                        this.local_filter_customer_item = local_filter_value[filtervalue]
                        break;
                    case 'aggr_collaborators':
                        this.local_filter_user_item = local_filter_value[filtervalue]
                        break;
                    case 'aggr_competitors':
                        this.local_filter_competitor_item = local_filter_value[filtervalue]
                        break;
                    case 'aggr_label_tags':
                        this.local_filter_label_tags = local_filter_value[filtervalue]
                        break;
                    case 'aggr_product_tags':
                        this.local_filter_product_tags = local_filter_value[filtervalue]
                        break;
                    case 'aggr_platform_tags':
                        this.local_filter_platform_tags = local_filter_value[filtervalue]
                        break;
                    case 'aggr_territories':
                        this.selected_territory = await this.local_load_territory_filter()
                        break;
                    case 'sort_by':
                        this.sort_by = local_filter_value[filtervalue] || this.localDefaultSortField
                        break;
                    case 'sort_direction':
                        this.sort_direction = local_filter_value[filtervalue] || ''
                        break;
                    case 'hierarchy_report':
                        this.local_team_filter.label = local_filter_value[filtervalue].label
                        this.local_team_filter.value = local_filter_value[filtervalue].value
                        break;
                    default: break;
                }
            }
            await this.local_set_filter_values()
        },

        async local_load_territory_filter(list = this.filterListTerritories, key = 'label') {
            let selectedUserTerritories = []
            if (_.size(this.user_self?.territories)) {
                const userTerritoryIds = _.map(this.user_self.territories, 'id')
                selectedUserTerritories = _.size(userTerritoryIds) ? userTerritoryIds : 'all'
            }

            let prefValues = this.filter_preference ? JSON.parse(this.filter_preference.value) : null
            if (prefValues && _.size(prefValues['aggr_territories'])) {
                let values = prefValues['aggr_territories'].map(item => item.value)
                values = values.includes('all') || values.includes('none') ? values[0] : values
                selectedUserTerritories = values
            }

            if ((!prefValues || !_.size(prefValues['aggr_territories'])) && !_.size(this.user_self?.territories)) {
                selectedUserTerritories = ['all']
            }

            this.project_filters[this.filter.TERRITORY] = selectedUserTerritories

            if (!['all', 'none'].includes(selectedUserTerritories)) {
                this.project_filters[this.filter.TERRITORY] = selectedUserTerritories
                let filtered_territories_label = []
                selectedUserTerritories.filter(item => filtered_territories_label.push(_.find(list, ['value', item])))
                return filtered_territories_label
            }
            return [_.find(list, {value: selectedUserTerritories})]
        },

        // Projects index -----
        async load_workspace_projects () {
            this.stage_sections = []
            this.local_stage_list = []
            await this.stage_clear()
            await this.project_clear()

            await this.stage_index({
                'fields[stages]': 'id,name,status',
                'filter[workspace_id]': this.current_workspace_id_mixin
            })
            this.local_stage_list = this.stage_list
            await this.load_workspace_stages()

            // Load projects under each stage
            this.stage_sections.forEach((stage, index) => {
                this.local_stage_projects_load(stage.id)
            })
            this.local_fetch_mini_insights()
        },

        async local_stage_projects_load(stage_id, page = null, page_info = null, args = {}) {
            if (stage_id === 'archived') return
            const { refresh: refresh_list } = args || {}
            const stage_current = _.find(this.stage_sections, {id: stage_id})
            stage_current && stage_current.loading ? stage_current.loading = true : null
            this.project_filters[this.filter.PAGE] = (page && page >= 1) ? page : 1
            this.project_filters[this.filter.COUNT] = 20
            this.project_filters[this.filter.WORKSPACE] = this.current_workspace_id_mixin
            this.project_filters[this.filter.STAGE] = stage_id

            this.local_set_confidence_score_params()

            let projFilters = {}
            if (stage_current && stage_current.status === 'completed') {
                projFilters['include'] = 'result'
                projFilters['fields[result]'] = 'id,status,value'
            }

            this.loading = true
            /* TODO: Remove after confirming new design  */
            if (!this.localHasFlagDashboardNew) {
                projFilters = Object.assign(projFilters, {
                    'include': 'result,keyCollaborators.user,keyCollaborators.type',
                    'fields[result]': 'id,status,value',
                    'fields[keyCollaborators.user]': 'id,name,color,initial,avatar',
                    'fields[keyCollaborators.type]': 'id,value',
                 })
            }
            if (this.localHasFlagDashboardNew) await this.localFetchOptimizedProjects({ ...this.project_filters, ...projFilters })
            else await this.project_index({ ...this.project_filters, ...this.project_filters_index, ...projFilters, flag_deal_amount: 1, refresh_list })
            this.loading = false
            this.local_stop_filter_loadings()

            if (stage_current) {
                const projectsList = this.localHasFlagDashboardNew ? this.project_dash_list : this.project_list
                stage_current.projects = (page && !refresh_list) ? _.union(stage_current.projects, _.cloneDeep(projectsList)) : _.cloneDeep(projectsList)
                stage_current.meta = _.cloneDeep(this.project_meta.meta)
                stage_current.links = _.cloneDeep(this.project_meta.links)
                stage_current.loading = false
            }
        },

        async localFetchOptimizedProjects (filters) {
            const clonedFilters = _.cloneDeep(filters)
            const removeableFields = ['filter[tag]', 'count']
            removeableFields.forEach(field => delete clonedFilters[field])

            await this.project_dash_index({ ...clonedFilters })
        },

        local_set_confidence_score_params () {
            if (this.project_filters_index['fields[projects]'] && !this.project_filters_index['fields[projects]'].includes('confidence_score')) {
                this.project_filters_index['fields[projects]'] = this.localIsConfidenceScoreEnabled ? this.project_filters_index['fields[projects]']+',confidence_score' : this.project_filters_index['fields[projects]']
            }
        },

        local_stop_filter_loadings () {
            this.local_loading_clear_all = false
            this.filter_loading = false
        },

        local_fetch_mini_insights (args) {
            const { onlyHealth = false } = args || {}

            if (!this.$can('reports.index')) return
            const workspace_id = { 'filter[workspace_id]': this.current_workspace_id_mixin }
            const clonedFilters = { ...this.project_filters, is_archived: 0 }
            delete clonedFilters['filter[stage_id]']

            // Health count => green, red, yellow
            this.insight_mini({ ...workspace_id, status: 'active,queue,completed,archived', ...clonedFilters })
            if (onlyHealth) return

            // Queue stage projects count
            this.insights_stage_poc_count({ ...workspace_id, status: 'queue', ...clonedFilters })

            // Active projects count
            this.insights_projects({ ...workspace_id, ...clonedFilters })

            // Lifetime of all projects
            this.insights_lifetime({ ...workspace_id, ...clonedFilters })
        },

        // Load workspace stages -----
        async load_workspace_stages () {
            this.stage_sections = []
            for (let index = 0; index < this.local_stage_list.length; index++) {
                const stage = this.local_stage_list[index]
                if (stage.status === 'queue') this.load_queued_projects(stage)
                if (stage.status === 'active') this.load_active_projects(stage)
                if ((this.local_stage_list.length - 1) === index) this.load_completed_projects()
            }
            this.local_stage_list = []
        },

        async load_queued_projects (stage) {
            const stage_queue = Object.assign({}, stage, { projects: [], loading: true })
            this.stage_sections.unshift(stage_queue);
        },

        async load_active_projects (stage) {
            const stage_new = Object.assign({}, stage, { projects: [], loading: true })
            this.stage_sections.push(stage_new)
        },

        async load_completed_projects () {
            let stage_completed = this.local_stage_list.splice(_.findIndex(this.local_stage_list, { 'status': 'completed' }), 1)
            stage_completed = Object.assign({}, stage_completed[0], { projects: [], loading: true })
            this.stage_sections.push(stage_completed);
        },
        // Workspace stages load End -----

        // Territory filters -----

         async local_selected_territory (selected_territories) {
            const all_territory = _.find(selected_territories, ['value', 'all'])
            const none_territory = _.find(selected_territories, ['value', 'none'])
            const all_territory_index = this.selected_territory.length > 1 ? this.selected_territory.findIndex(item => item && item.value === 'all') === this.selected_territory.length - 1 : ''
            const none_territory_index = this.selected_territory.length > 1 ? this.selected_territory.findIndex(item => item && item.value === 'none') === this.selected_territory.length - 1 : ''

            // this.$refs.filter_dropdown.ref.blur()
            this.$nextTick(async () => {
                if (all_territory_index) {
                    this.selected_territory = this.selected_territory.filter(item => item && item.value === 'all')
                    this.fetch_territories()
                    return
                }
                if (none_territory_index) {
                    this.selected_territory = this.selected_territory.filter(item => item && item.value === 'none')
                    this.fetch_territories()
                    return
                }
                if (all_territory && this.selected_territory.length === 1) {
                    this.local_filter_value_set(this.filter.TERRITORY, all_territory.value)
                    return
                }
                if (none_territory && this.selected_territory.length === 1) {
                    this.local_filter_value_set(this.filter.TERRITORY, none_territory.value)
                    return
                }
                if (all_territory && this.selected_territory.length > 1 && !all_territory_index) {
                    this.selected_territory.splice(this.selected_territory.findIndex(i => i.value === 'all'), 1)
                }
                if (none_territory && this.selected_territory.length > 1 && !none_territory_index) {
                    this.selected_territory.splice(this.selected_territory.findIndex(i => i.value === 'none'), 1)
                }
                this.fetch_territories()
            })
        },

        async fetch_territories (territory_to_remove = null) {
            this.project_filters[this.filter.TERRITORY] = []
            territory_to_remove ? await this.local_remove_selected_territory(territory_to_remove) : null
            if (this.selected_territory.length > 0) {
                this.local_filter_value_set(this.filter.TERRITORY, this.selected_territory)
            } else {
                this.selected_territory.push({'label': 'All Territories', 'value': 'all', 'color': 'blue-grey-dark'})
                this.local_filter_value_set(this.filter.TERRITORY, 'all')
            }
        },

        async local_remove_selected_territory(territory) {
            this.selected_territory.splice(this.selected_territory.findIndex(i => i.value === territory.value), 1)
        },

        async local_filter_value_set(filter, value) {
            if (value === 'all' || value === 'none' && !Array.isArray(value)) {
                this.project_filters[filter] = value
            } else {
                const finalValue = value.filter(item => item), territoryArr = []
                finalValue.forEach(item => {
                    if (item.value === 'all' || item.value === 'none') {
                        this.project_filters[filter] = item.value
                    } else {
                        territoryArr.push(item.value)
                        this.project_filters[filter] = [...this.project_filters[filter], ...territoryArr]
                    }
                })
            }
            // this.stage_sections.forEach(async (stage, index) => {
            //     this.local_stage_projects_load(stage.id)
            // })
        },

        local_health_filter (color) {
            return Array.isArray(this.local_health_colors) && this.local_health_colors.some(item => item.value === color && item.selected)
        },

        local_toggle_health_filter(color) {
            const index = this.local_health_colors.findIndex(item => item.value === color)
            const selectedItem = this.local_health_colors.find(item => item.value === color)

            if (index === -1) {
                this.local_health_colors.forEach(item => item.selected = !item.selected)
                return
            }
            const selectedLen = this.local_health_colors.filter(item => item.selected).length
            if (this.local_health_colors[index].selected && selectedLen === 1) this.localHasHealth(selectedItem.value)
            else this.local_health_colors[index].selected = !this.local_health_colors[index].selected
        },

        local_health_filter_list () {
            return this.local_health_colors.filter(item => item.selected).map(item => item.value)
        },

        local_set_health_filter (list) {
            this.local_health_colors.forEach(item => item.selected = list.includes(item.value))
        },

        local_clear_selected_territory () {
            this.selected_territory = []
            this.selected_territory.push({'label': 'All Territories', 'value': 'all', 'color': 'blue-grey-dark'})
        },
        // Territory filters End -----

        // Confidence score Filters Start -----
        local_confidence_score_filter (value) {
            return Array.isArray(this.local_confidence_score) && this.local_confidence_score.some(item => item.status === value && item.selected)
        },

        local_toggle_confidence_score_filter(value) {
            const index = this.local_confidence_score.findIndex(item => item.status === value)
            const selectedItem = this.local_confidence_score.find(item => item.status === value)

            if (index === -1) {
                this.local_confidence_score.forEach(item => item.selected = true)
                return
            }
            const selectedLen = this.local_confidence_score.filter(item => item.selected).length
            if (this.local_confidence_score[index].selected && selectedLen === 1) this.localHasConfidenceScore(selectedItem.status)
            else this.local_confidence_score[index].selected = !this.local_confidence_score[index].selected
        },

        local_confidence_score_filter_list () {
            return this.local_confidence_score.filter(item => item.selected).map(item => item.status)
        },

        local_set_confidence_score_filter (list) {
            this.local_confidence_score.forEach(item => item.selected = list.includes(item.status))
        },
        // Confidence score Filters End -----

        // Project stage change -----
        local_check_plan_on_project_move (event) {
            const { from, to } = event
            const { status: fromStageStatus } = this.stage_sections.find(i => i.id === from.id) || {}
            const { status: toStageStatus } = this.stage_sections.find(i => i.id === to.id) || {}
            const { is_admin } = this.user_self || {}
            const { projects_active_count } = this.mixinGetOrgPlan() || {}

            if ((fromStageStatus && ['queue', 'archived', 'completed'].includes(fromStageStatus)) && (toStageStatus && toStageStatus === 'active')) {
                if (this.$plan('active_projects_limit') === -1) return true // "true" allows drag & drop of project tile
                if (projects_active_count < this.$plan('active_projects_limit')) return true // "true" allows drag & drop of project tile

                if (is_admin) this.dialog_admin_upgrade_modal = true
                else this.dialog_user_upgrade_modal = true
                return false // "false" blocks drag & drop of project tile
            }

            return true // "true" allows drag & drop of project tile
        },

        async local_check_stage_change_automation_hook (stage_from_id, stage_to_id, event, project_id) {

            if (event && event.from.id == event.to.id) return true
            const stageItem = this.stage_sections.find(i => i.id === event.to.id)

            const hookItem = {}
            hookItem.id = !stage_from_id ?  event.item.id : project_id
            if (!stage_from_id) hookItem.stage_to_id = event.to.id
            hookItem.type = 'project-stage-changed'

            if (stageItem.status === 'completed') hookItem.type = 'project-completed'
            if (stage_to_id === 'archived') hookItem.type = 'project-archived'

            await this.automation_workflow_hook(hookItem)
            if (!this.$status(this.automation_workflow_hook_response)) {
                this.$automation()
                this.local_stage_projects_load(event.from.id)
                this.local_stage_projects_load(event.to.id)
                return false
            }
        },

        localConstructNotificationParam (args) {
            const { data: { from, to, module, workspace_id } } = args || {}
            return {
                workspace_id,
                result_id: null,
                status: to.status,
                project_id: module.id,
                stage_from_id: from.id,
                stage_to_id: to.id,
                notification: true,
            }
        },

        async local_project_stage_update_init(event, project_id = null, project_title = null, stage_from_id = null, stage_to_id = null, result_id = null, meta) {
            // this.dragStart = true

            const hook = await this.local_check_stage_change_automation_hook(stage_from_id, stage_to_id, event, project_id)
            if (hook === false) return

            stage_to_id = stage_to_id === 'completed' ? this.localCompletedStageId : stage_to_id
            const parameters = {
                event_type: (event) ? 'drag' : 'click',
                project_id: project_id,
                project_title: project_title,
                stage_from_id: stage_from_id,
                stage_to_id: stage_to_id,
                stage_from_title: '',
                stage_to_title: '',
                result_id: result_id,
                old_stage_index: event ? event.oldStageIndex : null,
                old_project_index: event ? event.oldIndex : null,
                new_project_index: event ? event.newIndex : null,
                workspace_id: this.current_workspace_id_mixin,
                meta: event ? event.meta : meta,
                trigger_type: event ? event.trigger_type : 'click',
            }
            if (event && parameters.project_id === null) { parameters.project_id = event.item.id }
            if (event && parameters.project_title === null) { parameters.project_title = event.item.title }
            if (event && parameters.stage_from_id === null) {
                parameters.stage_from_id = event.from.id
            }
            if (event && parameters.stage_to_id === null) {
                parameters.stage_to_id = event.to.id
            }

            const stage_from_item = this.stage_sections.find(item => item.id === parameters.stage_from_id )
            const stage_to_item = this.stage_sections.find(item => item.id === parameters.stage_to_id )

            if(stage_from_item && stage_to_item) {
                parameters.stage_from_title = stage_from_item.name
                parameters.stage_to_title = stage_to_item.name
            }

            if (parameters.stage_from_id === parameters.stage_to_id) return
            this.project_unsave_item({ id: project_id || event?.item?.id })

            if (parameters.stage_to_id === this.localCompletedStageId) await this.local_project_stage_update_result(parameters)
            else await this.local_project_stage_update_confirm(parameters)
        },

        async local_project_stage_update_result(parameters) {
            this.meta_clear()
            this.hasStageMoveError = false;
            this.dialog_stage_completed = true;
            this.local_project_comment = ''
            this.local_result_item = {}
            this.currentProjectResulTab = 'stageUpdate'
            this.project_move_parameters = parameters
            this.local_get_project_results()
            await this.project_show({
                id: parameters.project_id,
                reqType: 'internal-dashboard',
                params: { include: 'competitors', 'fields[projects]': 'id' }
            })
            this.local_get_project_competitors(parameters.project_id)
        },

        async local_get_project_results () {
            this.mixinSetLoading('fetch-results')
            await this.meta_index({
                'filter[type]': 'project_result',
                'fields[metas]': 'id,value,status',
                'sort': 'order'
            })
            this.result_list = _.cloneDeep(this.meta_list);
            this.mixinResetLoading('fetch-results')
        },

        async local_get_project_competitors (project_id = null) {
            await this.meta_index({
                'filter[project_id]': project_id,
                'filter[type]': 'competitor_type',
                'fields[metas]': 'id,value,status,selection_type',
                'filter[is_active]': 1,
                'sort': 'order'
            })
            this.competitor_types = _.cloneDeep(this.meta_list);
        },

        local_open_summary_view (project, stage) {
            const summary = this.project_saved_items.find(i => i.id === project.id)
            const props = { projectSummary: { ...project, stage_name: stage.name } }
            if (!summary) this.project_save_item({ id: project.id, createdAt: new Date(), summary: props })
            else this.project_save_item({ id: project.id, summary: { ...summary.summary, ...props } })

            this.project_summary = _.cloneDeep({ ...project, stage_name: stage.name })
            this.dialog_project_summary = true
        },

        local_close_project_summary () {
            this.dialog_project_summary = false
            this.project_summary = {}
        },

        /* New Comps --- Start */
        async localOpenEditCompDialog (item, category) {
            this.dialogCompetitorView = true
            this.competitorViewItem = _.cloneDeep(item)
            this.mixinSetLoading('competitor-show')

            await this.competitor_show({ id: item.id, params: { 'fields[competitors]': 'description_json,id,name,strengths,weaknesses,domain,link' } })
            this.competitorViewItem = _.cloneDeep({ ...this.competitor_item, category })
            this.mixinResetLoading('competitor-show')
            this.competitor_clear()
        },

        localGetCompetitorOfType (typeId, field) {
            const list = _.size(this.project_item.competitors) > 0 ? this.project_item.competitors.filter(i => i.category_id === typeId) : []
            const result = { list, count: list.length }
            return result[field]
        },

        async localShowCompSearchInput (type, { clearLoading }) {
            this.mixinSetLoading('competitor-search-' + type)

            this.currentCompList = []
            this.compRemoveInd = []
            this.tagSearchType = type
            await this.localOrgPreferenceCompetitorIndex(this.localOrgPreferenceLinkTag, type)
            this.currentCompList = _.cloneDeep(this.competitor_list)
            this.totalCompPage = this.competitor_meta.last_page
            this.currCompPage = this.competitor_meta.current_page
            this.mixinResetLoading('competitor-search-' + type)
            clearLoading()
        },

        async localSearchLazyLoadComp(val, page) {
            let params = {
                count: 20,
                sort: 'order',
                'fields[competitors]': 'id,name',
                'filter[source_id]': `${this.localProjectId}, ${this.tagSearchType}`,
                'filter[project_id]': this.localProjectId,
                'filter[name_filter]': val,
                page: page
            }
            await this.competitor_index(params)
            this.currentCompList.push(...this.competitor_list)
            this.currCompPage = this.competitor_meta.current_page
            // this.localFilterCompTags(this.competitor_list)
            this.lazyLoadCompState = false
            return
        },

        localFilterCompTags(list) {
            const filteredList = list.filter(c => this.currentCompList.findIndex(e => e.id === c.id) === -1)
            this.currentCompList.push(...filteredList)
        },

        async locallazyLoadCompetitors(typeId, {page, searchVal}) {
            this.lazyLoadCompState = true
            if (searchVal.length) return await this.localSearchLazyLoadComp(searchVal, page)

            let params = {
                count: 10,
                sort: 'order',
                'fields[competitors]': 'id,name',
                'filter[source_id]': `${this.project_item.id}, ${typeId}`,
                'filter[project_id]': this.project_item.id,
                page: page
            }
            await this.competitor_index(params)
            this.currentCompList.push(...this.competitor_list)
            this.currCompPage = this.competitor_meta.current_page
            this.lazyLoadCompState = false
        },

        local_filtered_comp_list(list) {
            list.forEach(comp => {
                const compPresent = this.currentCompList.findIndex(c => c.id === comp.id)
                if (compPresent === -1) this.currentCompList.push(comp)
            })
        },

        async localOrgPreferenceCompetitorIndex (preference, typeId) {
            const params = {
                count: 10,
                sort: 'order',
                'fields[competitors]': 'id,name',
                'filter[source_id]': `${this.project_item.id}, ${typeId}`,
            }
            if (preference) params['filter[project_id]'] = this.project_item.id

            await this.competitor_index(params)
        },

        async localSearchCompetitors (props, typeId) {
            const { value, type, clearLoading } = props || {}
            const params = {
                count: 20,
                sort: 'order',
                'filter[name_filter]': value,
                'fields[competitors]': 'id,name',
                'filter[source_id]': `${this.project_item.id}, ${typeId}`,
            }
            if (this.localOrgPreferenceLinkTag) params['filter[project_id]'] = this.project_item.id

            await this.competitor_index(params)
            this.currentCompList = [..._.cloneDeep(this.competitor_list)]
            this.currCompPage = this.competitor_meta.current_page
            this.totalCompPage = this.competitor_meta.last_page
            clearLoading()
        },

        async localCreateCompTag (createProps) {
            const { tag, mode, resolveError, resolveSuccess, searchVal, category = null } = createProps || {}

            if (mode === 'select') return this.localAttachCompsToProject({ tag, resolveSuccess, searchVal })

            const hasPermission = this.$can('projects.update-only') && this.$can('competitors.store')
            if (!hasPermission) return this.mixinResetLoading('competitor-create')

            const compItem = this.$appendId({ name: tag.value, category_id: category.id })
            await this.competitor_store(compItem)
            if (!this.$status(this.competitor_response)) {
                return resolveError(this.$response(this.competitor_response, 'name'))
            }

            resolveSuccess()
            await this.localAddTagToProject(compItem)
            this.localAttachTag(compItem, 'new')
        },

        async localAttachCompsToProject (params) {
            const { tag, resolveSuccess, searchVal, mode = null } = params || {}

            clearTimeout(this.compBulkAttachTimeout)
            await this.localAttachTag(tag, mode)
            const index = this.currentCompList.findIndex(i => i.id === tag.id)
            if (index !== -1) this.currentCompList.splice(index, 1)
            this.compRemoveInd.push(index)
            resolveSuccess()

            this.compBulkAttachTimeout = setTimeout(async () => {
                const ceilCount = Math.ceil(this.compBulkAttachIds.length / this.bulkCompChunkCount)
                for (let index = 0; index < ceilCount; index++) {
                    const chunkIds = this.compBulkAttachIds.splice(0, this.bulkCompChunkCount)
                    await this.association_bulk_store({
                        data: chunkIds,
                        type: 'project_competitor',
                        source_type: 'Project',
                        source_id: this.project_item.id,
                        target_type: 'Competitor',
                        target_id: tag.id,
                        category_id: this.tagSearchType
                    })
                    this.isCompetitorUpdate = true
                }
                clearTimeout(this.compBulkAttachTimeout)
            }, 500)
            if (this.currentCompList.length === 9 && this.currCompPage < this.totalCompPage) await this.locallazyLoadCompetitors(this.tagSearchType, {page: this.currCompPage + 1, searchVal})
        },

        async localAddTagToProject (competitor) {
            const projComps = (this.project_item && this.project_item.competitors) ?? []
            this.$set(this.project_item, 'competitors', [...projComps, competitor])
        },

        async localAttachTag (tag, mode = null) {
            const association = this.$appendId({
                type: 'project_competitor',
                source_type: 'Project',
                source_id: this.project_item.id,
                target_type: 'Competitor',
                target_id: tag.id,
                category_id: this.tagSearchType
            })

            if (mode === 'new') {
                await this.association_store(association)
                this.localAttachTagAssociation(tag.id, association)
                return true
            }
            this.compBulkAttachIds.push({ id: association.id, target_id: tag.id })
            this.localAttachTagToProject({ ...tag, category_id: this.tagSearchType }, association)
        },

        async localAttachTagAssociation (tag_id, association) {
            const index = this.project_item.competitors.findIndex(item => item.id === tag_id)
            if (index === -1) return

            Object.assign(this.project_item.competitors[index], { association })
        },

        localAttachTagToProject (tag, association = {}) {
            const projectComps = (this.project_item && this.project_item.competitors) ?? []
            this.$set(this.project_item, 'competitors', [...projectComps, { ...tag, association }])
        },

        localAddRemovedCompToList(comp, i) {
            const len = this.currentCompList.length
            if (i < len) this.currentCompList.splice(i, 0, comp)
            else this.currentCompList.push(comp)
            // if (i === len - 1) this.currentCompList.splice(i - 1, 0, comp)
        },

        async localDestoryTag (evt, typeId) {
            const { ids: chunkIds } = evt || {}

            chunkIds.forEach(item => {
                // const index = this.project_item.competitors.findIndex(i => i.id === item.id && typeId === i.category_id)
                const index = this.project_item.competitors.findIndex(i => (i.association.id === item.association.id && typeId === i.category_id))
                this.project_item.competitors.splice(index, 1)
                const recentRemovedInd = this.compRemoveInd.pop()
                const removedComp = {id: item.id, name: item.name}
                if (recentRemovedInd) this.localAddRemovedCompToList(removedComp, recentRemovedInd)
                else this.currentCompList.splice(0, 0, removedComp)
            })
            // this.project_replace_item(this.project_item)
            await this.association_bulk_destroy({
                ids: chunkIds.map(i => i.association.id),
                source_id: this.project_item.id,
                source_type: 'Project',
                // category_id: typeId
            })
            this.isCompetitorUpdate = true
            this.$notify('success', 'Competitors removed successfully!')
        },

        async localRemoveCompetitor (tag, typeId) {
            const index = this.project_item.competitors.findIndex(item => (item.id === tag.id && typeId === item.category_id))
            if (index === -1) return

            const comp = _.cloneDeep(this.project_item.competitors[index])
            this.project_item.competitors.splice(index, 1)
            this.association_destroy({ id: comp.association.id })
            this.isCompetitorUpdate = true
            this.$notify('success', 'Competitor removed successfully!')
        },

        async localDeleteCompetitor(evt, typeId) {
            const { ids: chunkIds } = evt || {}
            const item = chunkIds[0]
            await this.localRemoveCompetitor(item, typeId)
            const recentRemovedInd = this.compRemoveInd.pop()
            const removedComp = {id: item.id, name: item.name}
            if (recentRemovedInd) this.localInsertBack(removedComp, recentRemovedInd)
            else this.currentCompList.splice(0, 0, removedComp)
        },

        localInsertBack(comp, i) {
            const len = this.currentCompList.length
            if (i < len) this.currentCompList.splice(i, 0, comp)
            // if (i === len - 1) this.currentCompList.splice(i - 1, 0, comp)
            if (i >= len) this.currentCompList.push(comp)
        },

        localFilteredComps (compTypeId) {
            return this.currentCompList.filter(item => {
                const projectComp = this.project_item.competitors.find(comp => comp.id === item.id)
                if (!projectComp) return item
                return projectComp.category_id !== compTypeId ? item : null
            })
        },
        /* New Comps --- End */

        async localLoadProjectStage (stageId) {
            await this.localToggleStageLoadId(stageId)
            this.localRemoveHoveredProjId(stageId)
            await this.local_stage_projects_load(stageId, null, null, { refresh: true })
            this.localToggleStageLoadId(stageId)
        },

        localProjectResultReasonsClear () {
            this.project_move_parameters = {
                event_type: null, 
                project_id: null, 
                project_title: null, 
                stage_from_id: null, 
                stage_to_id: null, 
                result_id: null
            }
            this.dialog_stage_completed = false
            this.isCompetitorUpdate = false
            this.local_clear_project_reasons()
        },

        async localAutomationWorkflowHookErrorsCheck () {
            const validationErrors = this.automation_workflow_hook_response.data.filter(item => item.is_valid === false)
            const competitorErrors = this.automation_workflow_hook_response.data.filter(item => item.related_type === 'competitors' && item.is_valid === false)
            if (validationErrors.length !== competitorErrors.length) {
                this.$automation()
                this.localProjectResultReasonsClear()
            }
        },

        async local_project_stage_update_confirm(parameters, completed = null) {
            if (completed) {
                if (this.localShowCompOnComplete === false) {
                    await this.localCheckValidateResult()
                    if (!this.$status(this.automation_workflow_hook_response)) return;
                }
                if (completed && this.isCompetitorUpdate === true) {
                    const hookItem = {}
                    hookItem.type = 'project-completed'
                    hookItem.id = parameters.project_id
                    hookItem.stage_to_id = parameters.stage_to_id
                    await this.automation_workflow_hook(hookItem)
                    this.mixinFetchOrgPlanDetails()

                    if (!this.$status(this.automation_workflow_hook_response) && !parameters?.notification) {
                        await this.localAutomationWorkflowHookErrorsCheck()
                        return;
                    } 

                    await this.local_fetch_stage_stats({ stageId: [parameters.stage_to_id, parameters.stage_from_id] })
                }
            }
            this.mixinSetLoading('stage-update-complete')
            const params = { comment : this.local_project_comment, project_id: parameters.project_id, workspace_id: parameters.workspace_id, stage_id: parameters.stage_to_id, result_id: parameters?.result_id }
            if (parameters.stage_to_id === this.localCompletedStageId) {
                parameters.stage_to_id = (this.stage_sections.find(i => i.status === 'completed')).id
                if (!parameters?.notification) await this.project_stage_complete({ ...params, reqType: 'internal-dashboard' })
                this.mixinFetchOrgPlanDetails()
                if (!parameters?.notification) {
                    if (this.project_response.status !== 'success') {
                        this.dialog_stage_completed = true
                        this.mixinResetLoading('stage-update-complete')
                        return
                    }
                    await this.local_project_reasons_association_store(parameters.project_id)
                }
                await this.localLoadProjectStage(parameters.stage_from_id)
                await this.localLoadProjectStage(parameters.stage_to_id)
                await this.local_fetch_stage_stats({ stageId: [parameters.stage_to_id, parameters.stage_from_id] })
                this.mixinResetLoading('stage-update-complete')
            } else if (parameters.stage_to_id === 'archived') {
                this.archive_loading = params.project_id
                if (!parameters?.notification) await this.project_stage_archive({ ...params, reqType: 'internal-dashboard' })
                this.mixinFetchOrgPlanDetails()
                await this.local_remove_proj_form_stage(parameters)
                this.$notify('success', 'Project has been archived!')
                this.archive_loading = null
                await this.localLoadProjectStage(parameters.stage_from_id)
                await this.local_fetch_stage_stats({ stageId: [parameters.stage_from_id] })
            } else {
                if (!parameters?.notification) await this.project_workspace_update(params)
                this.mixinFetchOrgPlanDetails()
                this.local_reset_result(parameters)
                if (this.project_response.status !== 'success' && !parameters?.notification) return
                await this.localLoadProjectStage(parameters.stage_from_id)
                await this.localLoadProjectStage(parameters.stage_to_id)
                await this.local_fetch_stage_stats({ stageId: [parameters.stage_to_id, parameters.stage_from_id] })
            }

            this.localProjectResultReasonsClear()
            setTimeout(() => this.local_fetch_mini_insights(), 500)
            this.mixinResetLoading('stage-update-complete')
            if (this.dragStart) this.dragStart = false
        },

        async local_remove_proj_form_stage ({ stage_from_id, project_id }) {
            const stage = this.stage_sections.find(({ id }) => id === stage_from_id)
            const projIndex = stage.projects.findIndex(item => item.id === project_id)
            stage.projects.splice(projIndex, 1)
        },

        local_reset_result (parameters) {
            const newStage = this.stage_sections.find(({ id }) => id === parameters.stage_to_id)
            const projIndex = newStage.projects.findIndex(item => item.id === parameters.project_id)
            if (newStage.projects[projIndex]?.result && newStage.projects[projIndex]?.result_id) {
                newStage.projects[projIndex].result = null
                newStage.projects[projIndex].result_id = null
            }
        },
        // Project stage change End -----

        // Post Status Update STORE -----
        async local_project_post_create({ id, title }, stage_index) {
            this.status_update_clear_item()
            this.dialog_project_status_form = true
            this.comment_clear_item()
            this.local_project_title = title
            this.local_project_id = id
            this.local_stage_index = stage_index
            this.comment_item.commentable_id = id
        },

        async local_project_post_store(status_update_item) {
            status_update_item.project_id = this.local_project_id
            await this.status_update_store(status_update_item)
            if (this.status_update_response.status == 'success') {
                this.local_project_post_close()
                this.local_update_proj_health()
                this.$notify('success', 'Status update posted successfully!', { path: 'projects-status', path_id: this.local_project_id, button_text: 'View Update' })
                this.local_stage_index = null
                this.local_fetch_mini_insights()
                this.status_update_clear_item()
            }
        },

        local_update_proj_health () {
            const index = _.findIndex(this.stage_sections[this.local_stage_index].projects, { 'id': this.local_project_id });
            this.stage_sections[this.local_stage_index].projects[index].health_color = this.status_update_item.health
        },

        async local_project_post_close() {
            this.dialog_project_status_form = false
            this.local_project_title = ''
            this.comment_clear_item()
            this.meta_clear()
        },
        // Post Status Update STORE -----

        // Note CRUD -----
        async local_project_note_create(id, title) {
            this.note_clear_item()
            this.dialog_project_note_form = true
            this.local_project_id = id
            this.local_project_title = title
            this.note_item.is_client_visible = 0
        },

        async local_project_note_store(note_item) {
            note_item.order = -1
            note_item.parent_type = 'Project'
            note_item.parent_id = this.local_project_id
            await this.note_store({ ...note_item })
            if (this.note_response.status == 'success') {
                this.$notify('success', 'Note created successfully!', { path: 'projects-notes', path_id: this.local_project_id, button_text: 'View Note' })
                this.local_project_note_close()
            }
        },

        async local_project_note_close() {
            this.note_clear_item()
            this.dialog_project_note_form = false
            this.local_project_id = null
            this.meta_clear()
        },
        // Note CRUD End -----

        // Task CRUD -----
        async local_project_task_create(id, title, stage_id) {
            this.task_clear_item()
            this.milestone_clear_item()
            this.local_project_id = id
            this.local_project_stage_id = stage_id
            this.local_project_title = title
            this.task_item.priority = null
            this.task_item.is_client_visible = 0
            this.local_load_milestone_list(id)
            this.dialog_project_task_form = true
        },

        async local_project_task_store(task_item, est_validation_error) {
            if (est_validation_error) return
            task_item.project_id = this.local_project_id
            task_item.params = {'include': 'project.customer,milestone' }
            task_item.page = 'dashboard_task'
            task_item.force_include = true
            if (task_item.hasOwnProperty('description_json')) {
                task_item.description_json = task_item.description_json ? task_item.description_json['encodedData'] : null
            }
            await this.task_store({ ...task_item })
            if (this.task_response.status == 'success') {
                this.local_add_users(task_item, this.task_item.id, 'Task')
                this.dialog_project_task_form = false
                this.$notify('success', 'Task created successfully!', { path: 'projects-tasks', path_id: this.local_project_id, button_text: 'View Task' })
                this.task_clear_item()
                this.local_update_project_tile()
            }
        },

        // Milestonr CRUD -----
        async local_project_milestone_store(milestone_item) {
            milestone_item.project_id = this.local_project_id
            milestone_item.page = 'dashboard_milestone'
            await this.milestone_store(milestone_item)
            if (this.milestone_response.status === 'success') {
                this.local_add_users(milestone_item, this.milestone_item.id, 'Milestone')
                this.dialog_project_task_form = false
                this.$notify('success', 'Task milestone created successfully!', { path: 'projects-tasks', path_id: this.local_project_id, button_text: 'View Milestone' })
                this.milestone_clear_item()
            }
        },

        async local_load_milestone_list(id) {
            this.milestone_index({
                'filter[project_id]': id,
                'fields[milestones]': 'id,title'
            })
        },
        // Milestone CRUD End -----

        async local_add_users(model, model_id, type) {
            model.selected_assignees.forEach(async user_id => {
                this.assignee_store({ resource_type: type, resource_id: model_id, user_id, project_id : model.project_id })
            })
        },

        async local_update_project_tile () {
            const params = this.localHasFlagDashboardNew ? {} : this.project_filters_index
            await this.project_update_list({ project_id: this.local_project_id, reqType: 'internal-dashboard', ...params })
            const newStageIndex = _.findIndex(this.stage_sections, { id: this.local_project_stage_id })
            const newProjIndex = _.findIndex(this.stage_sections[newStageIndex].projects, { id: this.local_project_id })
            this.local_update_stage_project(newStageIndex, newProjIndex)
        },

        local_update_stage_project (newStageIndex, newProjIndex) {
            this.$set(this.stage_sections[newStageIndex].projects, newProjIndex, { ...this.project_item })
            this.project_clear_item()
        },

        async local_project_task_close() {
            await this.task_clear_item()
            this.milestone_clear_item()
            this.dialog_project_task_form = false
            this.local_project_id = null
        },
        // Task CRUD End -----

        async local_project_stage_update_cancel(parameters) {
            this.local_clear_project_reasons()
            this.local_replace_project(parameters)
            this.project_move_parameters = {event_type: null, project_id: null, project_title: null, stage_from_id: null, stage_to_id: null, result_id: null}
            this.dialog_stage_completed = false;
            this.local_fetch_mini_insights()
        },

        local_replace_project({ old_stage_index, new_project_index, old_project_index, stage_to_id }) {
            const newStage = this.stage_sections.find(({ id }) => id === stage_to_id)
            if ((newStage && newStage.projects && newStage.projects.length) && (new_project_index !== null && new_project_index >= 0)) {
                const movedItem = newStage.projects.splice(new_project_index, 1)[0]
                this.stage_sections[old_stage_index].projects.splice(old_project_index, 0, movedItem)
            }
        },

        local_permission_validate(permission) {
            return this.$can(permission)
        },

        local_stage_scroll_right() {
            let content = document.querySelector(".kanban-container");
            content.scrollLeft += 340;
        },

        local_stage_scroll_left() {
            let content = document.querySelector(".kanban-container");
            content.scrollLeft -= 340;
        },

        get_preference(type, field = 'value') {
            if (type === 'progress') {
                return this.preference_get_property_by_key('organization.project.progress_resource', field)
            }
            if (type === 'widgets') {
                return this.preference_get_property_by_key(`organization.projects.widgets.${type}`, field)
            }
        },

        local_add_project_result_reason_list (id) {
            const index = this.project_result_reason_list.findIndex(item => item === id)
            if (index !== -1) {
                this.project_result_reason_list.splice(index, 1)
                return
            }

            this.project_result_reason_list.push(id)
        },

        async local_select_project_result_detail(id) {
            if(!id) return
            this.project_result_reason_list = []
            await this.automation_workflow_hook_clear()
            await this.local_load_meta_result_reason_list(id)
            await this.local_select_result_item(id)
        },

        async local_load_meta_result_reason_list(id) {
            this.loading_reason = true
            await this.meta_result_reason_clear()
            await this.meta_result_reason_index({
                'filter[result_id]': id,
                'fields[project_result_reasons]': 'id,title',
            })
            this.loading_reason = false
        },

        local_select_result_item(id) {
            const result_item = this.result_list.find(item => item.id === id)
            this.local_result_item = {...result_item }
        },

        async local_project_reasons_association_store(source_id) {
            this.project_result_reason_list.forEach(async result_reason_id => {
                const data = { source_type: 'Project', source_id: source_id, target_type: 'ProjectResultReason', target_id: result_reason_id, type: 'project_result_reason' }
                await this.association_store(data)
            })
        },

        async local_clear_project_reasons() {
            this.project_result_reason_list = []
            this.local_project_comment = null
            this.meta_result_reason_clear()
            this.project_response_clear()
        },

        // add project filters

        async local_show_filters_pane() {
            if (this.project_pane_filters || this.local_loading) return
            this.local_clear_filter_data()
            // this.$refs.project_pane_filters.$el.scrollTop = 0
            this.project_pane_filters = true
        },

        local_clear_filter_data() {
            this.customer_clear()
            this.user_clear()
            this.competitor_clear()
            this.tag_clear()
        },

        async local_sort_by (sortByField, direction = this.sort_direction) {
            this.sortLoading = true
            this.sort_by = sortByField
            this.sort_direction = direction
            await this.local_apply_filters()
            this.sortLoading = false
        },

        async local_apply_filters () {
            this.hoveredProjects = []
            this.local_fetch_project()
            await this.local_persist_preference()
            await this.local_fetch_preference()
        },

        async local_persist_preference () {
            this.local_set_user_pref()
            const params = {
                resource_id: this.user_self.id,
                resource_type: 'User',
                value: JSON.stringify(this.user_filters_pref),
                label: 'Dashboard Column',
                key: 'user.projects.filters',
                is_listable: 0,
            }
            if (this.filter_preference) await this.preference_filters_update({ id: this.filter_preference.id, ...params })
            else await this.preference_filters_store({ ...params })
        },

        local_set_user_pref () {
            this.user_filters_pref = {
                health_color: this.local_health_filter_list(),
                confidence_score: this.local_confidence_score_filter_list(),
                accounts: this.local_filter_customer_item,
                aggr_collaborators: this.local_filter_user_item,
                aggr_competitors: this.local_filter_competitor_item,
                aggr_label_tags: this.local_filter_label_tags,
                aggr_product_tags: this.local_filter_product_tags,
                aggr_platform_tags: this.local_filter_platform_tags,
                aggr_territories: this.selected_territory,
                sort_by:  this.sort_by || this.localDefaultSortField,
                sort_direction: this.sort_direction,
                hierarchy_report: this.local_team_filter
            }
        },

        local_clear_all_filters () {
            this.local_reset_filters()
            this.local_apply_filters()
        },

        local_reset_filters () {
            this.local_filter_customer_item = []
            this.local_filter_competitor_item = []
            this.local_filter_label_tags = []
            this.local_filter_product_tags = []
            this.local_filter_platform_tags = []
            this.local_filter_health_item = []
            this.local_filter_confidence_score_item = []
            this.local_health_colors.forEach(item => item.selected = true)
            this.local_confidence_score.forEach(item => item.selected = true)
            this.local_filter_user_item = []
            this.local_filter_value_set(this.filter.TERRITORY, 'all')
            this.selected_territory = []
            this.selected_territory.push({'label': 'All Territories', 'value': 'all', 'color': 'blue-grey-dark'})
            this.local_team_filter = {label: 'All Projects', value: 'all'}
        },

        async local_fetch_project (clear = null) {
            if (!clear) this.filter_loading = true
            this.local_set_filter_values()

            this.stage_sections.forEach(async (stage) => this.local_stage_projects_load(stage.id))
            this.local_fetch_mini_insights()
            this.local_fetch_stage_stats()
            if (!clear) this.project_pane_filters = false
        },

        local_set_filter_values () {
            if (this.user_self && !this.user_self.is_manager && this.local_team_filter && (this.local_team_filter.value !== 'self')) {
                this.local_team_filter['label'] = 'All Projects'
                this.local_team_filter['value'] = 'all'
            }

            /* TODO: Remove after confirming new design  */
            const tagsCount = [...this.local_filter_label_tags, ...this.local_filter_product_tags, ...this.local_filter_platform_tags]
            this.project_filters[this.filter.TAG] = _.size(tagsCount) ? _.map(tagsCount, 'id') : null

            this.project_filters[this.filter.CUSTOMER] = _.size(this.local_filter_customer_item) ? _.map(this.local_filter_customer_item, 'id') : null
            this.project_filters[this.filter.COMPETITOR] = _.size(this.local_filter_competitor_item) ? _.map(this.local_filter_competitor_item, 'id') : null
            this.project_filters[this.filter.LABEL_TAG] = _.size(this.local_filter_label_tags) ? _.map(this.local_filter_label_tags, 'id') : null
            this.project_filters[this.filter.PRODUCT_TAG] = _.size(this.local_filter_product_tags) ? _.map(this.local_filter_product_tags, 'id') : null
            this.project_filters[this.filter.PLATFORM_TAG] = _.size(this.local_filter_platform_tags) ? _.map(this.local_filter_platform_tags, 'id') : null
            this.project_filters[this.filter.HEALTH] = _.size(this.local_health_filter_list()) ? this.local_health_filter_list() : null
            if (this.localIsConfidenceScoreEnabled && this.$can('poc_confidence_score.index')) {
                this.project_filters[this.filter.CONFIDENCE_SCORE] = _.size(this.local_confidence_score_filter_list()) ? this.local_confidence_score_filter_list() : null
            }
            this.project_filters[this.filter.PROJECT_COLLABORATORS] = _.size(this.local_filter_user_item) ? _.map(this.local_filter_user_item, 'id') : null
            this.project_filters[this.filter.TERRITORY] = _.size(this.selected_territory) ? _.map(this.selected_territory, 'value') : null
            this.project_filters[this.filter.SORT] = `${this.sort_direction}${this.sort_by}`
            const territoryValues = this.project_filters[this.filter.TERRITORY]
            this.project_filters[this.filter.TERRITORY] = territoryValues && (territoryValues.includes('all') || territoryValues.includes('none')) ? territoryValues[0] : territoryValues
            this.project_filters[this.filter.HIERARCHY] = this.local_team_filter.value
        },

        async local_customer_search_filter() {
            if(!this.local_customer_search || this.local_customer_search && !this.local_customer_search.trim()) return
            this.mixinSetLoading('customer-search')
            clearTimeout(this.search_customer_timeout)
            this.search_customer_timeout = window.setTimeout(async () => {
                await this.local_load_customer_list()
            }, 500)
            this.mixinResetLoading('customer-search')
        },

        async local_load_customer_list() {
            await this.customer_clear()
            this.local_loading_customers = true
            this.local_customer_filters[this.customer_filter.FIELDS] = 'id,name'
            this.local_customer_filters[this.customer_filter.SORT] = 'name'
            this.local_customer_filters[this.customer_filter.IS_ACTIVE] = 1
            this.local_customer_filters[this.customer_filter.NAME] = this.local_customer_search
            this.local_customer_filters[this.customer_filter.COUNT] = 999
            await this.customer_index(this.local_customer_filters)
            this.local_customers_list = [...this.local_customers_list, ...this.customer_list]
            this.local_loading_customers = false
        },

        async local_user_search_filter() {
            if(!this.local_user_search || this.local_user_search && !this.local_user_search.trim()) return
            this.mixinSetLoading('collaborator-search')
            clearTimeout(this.search_user_timeout)
            this.search_user_timeout = window.setTimeout(async () => {
                await this.local_load_user_list()
            }, 500)
            this.mixinResetLoading('collaborator-search')
        },

        async local_load_user_list() {
            await this.user_clear()
            this.local_loading_users = true
            this.local_user_filters[this.user_filter.FIELDS] = 'id,name,avatar,color,initial,is_active'
            this.local_user_filters[this.user_filter.SORT] = 'name'
            this.local_user_filters[this.user_filter.NAME] = this.local_user_search
            this.local_user_filters[this.user_filter.COUNT] = 999
            await this.user_index(this.local_user_filters)
            this.local_users_list = [...this.local_users_list, ...this.user_list]
            this.local_loading_users = false
        },

        async local_competitors_search_filter() {
            if(!this.local_competitor_search || this.local_competitor_search && !this.local_competitor_search.trim()) return
            this.mixinSetLoading('competitor-search')
            clearTimeout(this.search_competitor_timeout)
            this.search_competitor_timeout = window.setTimeout(async () => {
                await this.local_load_competitor_list()
            }, 500)
            this.mixinResetLoading('competitor-search')
        },

        async local_load_competitor_list() {
            await this.competitor_clear()
            this.local_loading_competitors = true
            this.local_competitor_filters[this.competitor_filter.FIELDS] = 'id,name'
            this.local_competitor_filters[this.competitor_filter.SORT] = 'name'
            this.local_competitor_filters[this.competitor_filter.NAME] = this.local_competitor_search
            this.local_competitor_filters[this.competitor_filter.COUNT] = 999
            await this.competitor_index(this.local_competitor_filters)
            this.local_competitors_list = [...this.local_competitors_list, ...this.competitor_list]
            this.local_loading_competitors = false
        },

        // Tags filter --- Start
        async local_search_filter (search_text, type, loading) {
            this.mixinSetLoading(loading)
            clearTimeout(this.search_tag_timeout)
            this.search_tag_timeout = window.setTimeout(async () => {
                if (!search_text || (search_text && !search_text.trim())) return this.tag_clear()
                if (type === 'label') this.local_label_search_filter()
                if (type === 'product') this.local_product_search_filter()
                if (type === 'platform') this.local_platform_search_filter()
            }, 500)
            this.mixinResetLoading(loading)

        },

        async local_label_search_filter () {
            await this.tag_clear()
            this.local_tag_filters[this.tag_filter.LABEL] = this.local_label_tag_search
            await this.local_load_tag_list('label')
            this.local_label_list = [...this.local_label_list,...this.tag_list]
            this.tag_clear()
        },

        async local_product_search_filter () {
            await this.tag_clear()
            this.local_tag_filters[this.tag_filter.LABEL] = this.local_product_tag_search
            await this.local_load_tag_list('product')
            this.local_product_list = [...this.local_product_list,...this.tag_list]
            this.tag_clear()
        },

        async local_platform_search_filter () {
            await this.tag_clear()
            this.local_tag_filters[this.tag_filter.LABEL] = this.local_platform_tag_search
            await this.local_load_tag_list('platform')
            this.local_platform_list = [...this.local_platform_list,...this.tag_list]
            this.tag_clear()
        },

       async local_load_tag_list (type) {
            this.local_loading_tags = type
            this.local_tag_filters[this.tag_filter.FIELDS] = 'id,label,color'
            this.local_tag_filters[this.tag_filter.SORT] = 'order'
            this.local_tag_filters[this.tag_filter.TYPE] = type
            this.local_tag_filters[this.tag_filter.COUNT] = 999
            await this.tag_index(this.local_tag_filters)
            this.local_loading_tags = null
        },

        local_clear_tags () {
            this.local_product_tag_search = null
            this.local_platform_tag_search = null
            this.local_label_tag_search = null
        },
        // Tags filter --- End

        // Extras --- Start
        localGetStageProjects (index, { field = null }) {
            const stage = (this.stage_sections && this.stage_sections[index]) || null
            if (!stage) return field === 'length' ? 0 : []

            return field === 'length' ? _.size(stage.projects) : stage.projects
        },

        localGetDealAmount (value) {
            const { meta } = value || {}
            return !meta ? 0 : meta.total_deal_amount
        },

        localHasPermission (type) {
            if (!Array.isArray(type)) return this.$can(type)

            let resultPermission = false
            for (let index = 0; index < type.length; index++) {
                const slug = type[index]
                resultPermission = this.$can(slug)
            }
            return resultPermission
        },

        async localToggleStageLoadId (id) {
            const index = this.stage_load_ids.findIndex(i => i === id)
            if (index !== -1) return this.stage_load_ids.splice(index, 1)
            this.stage_load_ids.push(id)
        },

        localIsStageLoading (id) {
            const index = this.stage_load_ids.findIndex(i => i === id)
            return index !== -1
        },

        local_set_hierarchy_list () {
            if (this.user_self && this.user_self.is_manager) return this.local_team_filter_list = this.filter_list_manager
            this.local_team_filter_list = this.filter_list_team
        },

        async local_team_filter_set(label, value) {
            this.local_team_filter.label = label
            this.local_team_filter.value = value
        },

        local_revert_filter () {
            this.local_fetch_preference()
            this.project_pane_filters = false
        },

        /* TODO: Remove after confirming new design  */
        // Lazy fetch project
        localFetchProjectMeta (project) {
            clearTimeout(this.lazyProjectTimeout)

            if (this.localIsProjectHovered(project.id)) return true
            this.lazyProjectMetaLoading = true

            const params = {
                include: 'tags,territories',
                'fields[projects]': 'id',
                'fields[tags]': 'tags.id,tags.label,tags.type,tags.color',
                'fields[territories]': 'territories.id,territories.name,territories.color'
            }

            this.lazyProjectTimeout = setTimeout(async () => {
                await this.project_show({
                    id: project.id,
                    reqType: 'internal-dashboard',
                    params: params
                })
                const stageIndex = this.stage_sections.findIndex(i => i.id === project.stage_id)
                const projectIndex = this.stage_sections[stageIndex].projects.findIndex(i => i.id === project.id)
                const { tags, territories } = this.project_item

                const mergedMeta = {
                    ...this.stage_sections[stageIndex].projects[projectIndex],
                    ...{ tags, territories }
                }
                this.$set(this.stage_sections[stageIndex].projects, projectIndex, mergedMeta)

                this.lazyProjectMetaLoading = false
                this.localUpdateHoveredProjId(project.id)
            }, 1000);
        },

        localClearProjectMetaFetch () {
            this.lazyProjectMetaLoading = false
            clearTimeout(this.lazyProjectTimeout)
        },

        localUpdateHoveredProjId (projectId) {
            const index = this.hoveredProjects.indexOf(projectId)
            if (index !== -1) return true

            this.hoveredProjects.push(projectId)
        },

        localIsProjectHovered (projectId) {
            return this.hoveredProjects.indexOf(projectId) !== -1
        },

        localRemoveHoveredProjId (stageId) {
            const removableProjs = _.get(this.stage_sections.find(i => i.id === stageId), 'projects')
            const removableProjIds = _.size(removableProjs) ? removableProjs.map(i => i.id) : []

            if (!_.size(removableProjIds)) return true

            this.hoveredProjects = this.hoveredProjects.filter(id => {
                return removableProjIds.indexOf(id) !== -1 ? null : id
            })
        },
        // End

        localHasCheckCompetitorKey () {
            const value = this.preference_get_property_by_key('organization.competitor_type_selection', 'value')
            return value === 'Yes' ? true : false
        },

        async localValidateResult () {
            await this.localCheckValidateResult()
            if (!this.$status(this.automation_workflow_hook_response)) return;
            this.currentProjectResulTab = this.localShowCompOnComplete ? 'competitors' : ''
        },

        async localCheckValidateResult () {
            const hookItem = {}
            hookItem.type = 'project-result'
            hookItem.id = this.project_move_parameters.project_id
            hookItem.result_reason = this.project_result_reason_list
            hookItem.result_id = this.project_move_parameters.result_id
            hookItem.comment = this.local_project_comment
            hookItem.stage_to_id = null
            await this.automation_workflow_hook(hookItem)
        },

        localHasHealth (value) {
            return _.size(this.local_health_colors.filter(item => item.selected && item.value !== value)) > 0
        },

        localHasConfidenceScore (value) {
            return _.size(this.local_confidence_score.filter(item => item.selected && item.status !== value)) > 0
        },

        local_user_search_results (type) {
            this[`local_loading_${type}`] = true
        },

        local_tag_search_results (type, loading) {
            this.local_loading_tags = type
            this.local_set_tag_type = type
            this.local_set_tag_type_loading = loading
        },

        ...mapActions('Stage', {
            stage_index: 'index',
            stage_clear: 'clear',
        }),

        ...mapActions('Project', {
            project_index: 'index',
            project_dash_index: 'dash_index',
            project_dash_stats_index: 'dash_stats',
            project_show: 'show',
            project_workspace_update: 'workspace_update',
            project_stage_complete: 'complete',
            project_update_list: 'update_list',
            project_stage_archive: 'archive',
            project_clear: 'clear',
            project_response_clear: 'response_clear',
            project_clear_item: 'clear_item',
        }),

        ...mapActions('ProjectView', {
            project_save_item: 'save_item',
            project_unsave_item: 'unsave_item',
            project_clear_saved: 'clear_saved',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),

        ...mapActions('Note', {
            note_store: 'store',
            note_clear_item: 'clear_item',
        }),

        ...mapActions('Task', {
            task_store: 'store',
            task_clear_item: 'clear_item',
        }),

        ...mapActions('Milestone', {
            milestone_index: 'index',
            milestone_store: 'store',
            milestone_clear_item: 'clear_item',
        }),

        ...mapActions('Collaborator', {
            collaborator_index: 'index',
        }),

        ...mapActions('Comment', {
            comment_clear_item: 'clear_item',
        }),

        ...mapActions('Preference', {
            preference_index : 'index',
            preference_filters_store : 'store',
            preference_filters_update : 'update',
            preference_clear_user_pref : 'clear_user_types'
        }),

        ...mapActions('Territory', {
            territory_index: 'index',
            territory_clear: 'clear',
        }),

        ...mapActions('StatusUpdate', {
            status_update_store: 'store',
            status_update_clear_item: 'clear_item'
        }),

        ...mapActions('Assignee', {
            assignee_store: 'store',
        }),

        ...mapActions('User', {
            user_index: 'index',
            user_clear: 'clear',
            user_show: 'show',
        }),

        ...mapActions('Insight', {
            insight_mini: 'dashboard',
            insights_projects: 'projects',
            insights_lifetime: 'lifetime',
            insights_stage_poc_count: 'stage_poc_count',
        }),

        ...mapActions('Competitor', {
            competitor_store: 'store',
            competitor_show: 'show',
            competitor_clear: 'clear',
        }),

        ...mapActions('Association', {
            association_store: 'store',
            association_bulk_store: 'bulk_store',
            association_destroy: 'destroy',
            association_bulk_destroy: 'bulk_destroy',
        }),

        ...mapActions('MetaResultReason', {
            meta_result_reason_index: 'index',
            meta_result_reason_clear: 'clear',
        }),

        ...mapActions('Customer', {
            customer_index: 'index',
            customer_clear: 'clear',
        }),

        ...mapActions('Competitor', {
            competitor_index: 'index',
            competitor_clear: 'clear',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_clear: 'clear',
        }),

        ...mapActions('ConfidenceScore', {
            confidence_score_aggregate_show: 'aggregate_show'
        }),

        ...mapActions('AutomationValidation', {
            automation_validation_result_status: 'result_status',
        }),
    }
}
</script>

<style lang="scss" scoped>
    .kanban-container {
        display: flex;
        align-items: stretch;
        padding: 0.75rem 0 1.5rem 0;
        width: 100%;
        scroll-behavior: smooth;
        column-gap: 1rem;
    }
    .kanban-column {
        min-height: 48px;
        width: 340px;
        flex-shrink: 0;
    }
    .kanban-column-content {
        display: flex;
        flex-direction: column;
    }
    .kanban-column-title {
        flex: 0 0 auto;
    }
    .kanban-column-target {
        flex: 1 0 auto;
        min-height: 480px;
    }
    // .sortable-ghost {
    //     opacity: 0.8;
    //     box-shadow: 0px 0px 8px 0px #888;
    //     border: 4px dashed rgb(136, 136, 136);
    //     background: #d2f1ff;
    //     // background: #c8ebfb;
    // }
    .sortable-drag {
        opacity: 0.8 !important;
        border: 8px dashed rgb(136, 136, 136);
        box-shadow: 0px 0px 8px 0px #888 !important;
        background: #e0e0e0 !important;
    }
    .sortable-chosen {
        .c-project-card__health,
        .c-tag-loader {
            display: none;
        }
        opacity: 1 !important;
        box-shadow: 0px 6px 6px 4px rgb(0, 0, 0);
        border: none !important;
        box-shadow: 0px 2px 6px 4px rgb(173, 173, 173);
        background: #f5f5f5 !important;
        // opacity: 1 !important;
        // border: 4px dashed rgb(136, 136, 136);
        // box-shadow: 0px 0px 8px 0px #888 !important;
        // background: #e0e0e0 !important;
    }
    .sortable-ghost {
        opacity: 0.8;
        box-shadow: 0px 2px 6px 4px rgb(173, 173, 173);
        border: 1px dashed rgb(116, 116, 116);
        background: #d2f1ff;
    }
    // .sortable-chosen {
    //     .c-expand { display: none; }
    //     opacity: 1 !important;
    //     box-shadow: 0px 6px 6px 4px rgb(0, 0, 0);
    //     border: none !important;
    //     border: 1px dashed rgb(116, 116, 116);
    //     background: #f5f5f5 !important;
    // }
    // .sortable-fallback {
    //     // display: none;
    //     // transition: 2s all;
    //     // background: #000 !important;
    // }

    .c-project-detail-pane.visible {
        transform: translateX(0);
    }

    .c-project-filters-pane {
        transition: 0.3s all;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 4;
        height: 100vh;
        width: 584px;
        overflow-y: auto;
        z-index: 200;
        transform: translateX(-584px);
    }

    .c-project-filters-pane.visible {
        transform: translateX(0);
    }

    .c-border-c7 {
        border: 1px solid #000;
        border-color: #c7c7c7 !important
    }

    .c-slide-none {
        transform: translateX(0);
    }

    .c-slide-full {
        transform: translateX(-100%);
    }
    .c-filters-side-pane.visible {
        transform: translateX(0);
    }
    .c-filters-side-pane {
        transition: 0.3s all;
        position: fixed;
        top: calc((100vh - 96vh)/2);
        left: 24px;
        height: 96vh;
        width: 524px;
        z-index: 10;
        background-color: white;
        transform: translateX(-552px);
        border-radius: 8px;
    }
    .c-filter-content {
        overflow-y: scroll;
        height: calc(96vh - 164px);
    }
    .c-radio-btn {
        border: 1px solid #3949AB;
        background-color: #E8EAF6;
    }
    .c-btn-clearAll.v-btn.v-btn--disabled {
        opacity: 0.5;
        background-color: #FFEBEE !important;
        color: #E53935 !important;
    }
    .c-combo-border {
        border: 1px solid rgba(238, 238, 238, 1) !important;
        background-color: #fafafa !important;
    }
    .c-dashboard-filter-menu {
        background-color: #fafafa !important;
    }

</style>

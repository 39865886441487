<template>
    <a-card>
        <div class="u-flex-center-y pa-6" style="column-gap: 16px;">
            <a-avatar class="primary darken-1" size="40">
                <a-icon size="18" dark>system_update_alt</a-icon>
            </a-avatar>
            <div>
                <h2 class="md-heading-6 primary--text text--darken-1">Import Tasks from Template</h2>
                <p class="md-subtitle-2 mb-0">You can import multiple task into projects.</p>
            </div>
            <a-spacer></a-spacer>
            <a-btn small text icon color="grey" @click="localCloseModal()" class="ma-0 pa-0 px-3 right">
                <a-icon size="26">cancel</a-icon>
            </a-btn>
        </div>
        <a-divider></a-divider>
        <div>
            <a-responsive class="py-4 my-4" v-if="!loading && (template_group_list && template_group_list.length === 0)">
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.<br>Try narrowing your filter or add new entries.</p>
            </a-responsive>
            <a-card flat v-for="(template_group, index) in template_group_list" :key="template_group.id">
                <a-divider v-if="index !== 0"></a-divider>
                <div class="u-flex-center-y py-3 px-6">
                    <h2 class="md-body-2">
                        {{ template_group.name }} <span class="md-body-2 grey--text text--darken-1">{{ template_group.template_task_count }} Tasks</span>
                    </h2>
                    <a-spacer></a-spacer>
                    <a-btn color="primary lighten-1" class="elevation-0" @click="localImportTemplate(template_group.id)" small :disabled="template_group.template_task_count == 0 || loading">Import</a-btn>
                </div>
            </a-card>
        </div>
    </a-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            exportMode: 'new',
        }
    },

    watch: {
        isOpen: {
            handler (val) {
                if (val) this.localIndex()
            },
            immediate: true
        }
    },

    computed: {
        ...mapState('Milestone', {
            milestone_list: 'list',
        }),

        ...mapState('Task', {
            task_list: 'list',
        }),

        ...mapState('TemplateGroup', {
            template_group_list: 'list',
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),

        ...mapState('TemplateMilestone', {
            template_milestone_item: 'item',
            template_milestone_response: 'response',
        }),

        ...mapState('TemplateTask', {
            template_task_item: 'item',
            template_task_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async localIndex () {
            if (this.$can('templates.index') === true) {
                this.template_group_filters.type = 'Task'
                await this.template_group_index({
                    include: 'templateTask,templateTaskCount',
                    'filter[type]': 'tasks'
                })
            }
        },

        async localImportTemplate (id) {
            const import_item = {}
            import_item.project_id = this.$route.params.id
            import_item.template_group_id = id
            await this.task_template_import(import_item)
            this.$emit('refresh', true)
            this.$notify('success', 'Tasks imported successfully!')
        },

        localCloseModal () {
            // this.localExportModeChange()
            this.$emit('close', true)
        },

        ...mapActions('Task', {
            task_template_import: 'template_import',
        }),

        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
        }),
    }
}
</script>

<template>
    <v-icon v-bind="$attrs" v-on="$listeners" :class="{ 'material-icons-outlined': outlined }">
        <slot></slot>
    </v-icon>
</template>

<script>
import { VIcon } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VIcon },
    props: {
        outlined: {
            type: Boolean,
            default: false
        }
    }
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',[_c('div',{staticClass:"u-flex-center-y pa-6 py-5 u-relative",staticStyle:{"column-gap":"8px"}},[_c('a-icon',{attrs:{"size":"24","color":"indigo darken-2","outlined":""}},[_vm._v("format_shapes")]),_c('h2',{staticClass:"indigo--text text--darken-2 md-subtitle-1 font-weight-medium"},[_vm._v("Keywords - "+_vm._s(_vm.meeting.title))]),_c('a-icon',{staticClass:"u-absolute",staticStyle:{"top":"50%","transform":"translateY(-50%)","right":"24px"},attrs:{"size":"18","color":"grey darken-1"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" cancel ")])],1),_c('a-divider'),_c('div',{staticClass:"u-flex align-stretch"},[(_vm.meeting.meeting_trackers && !_vm.meeting.meeting_trackers.length)?[_c('a-sheet',{staticClass:"u-flex-center u-wfull",attrs:{"min-height":"400"}},[_c('div',{staticClass:"pa-6 u-flex-center-y"},[_c('a-icon',{attrs:{"size":"24","color":"grey darken-2"}},[_vm._v("info")]),_c('h3',{staticClass:"md-subtitle-1 ml-2 font-weight-medium grey--text text--darken-3"},[_vm._v("There are no trackers here to display")])],1)])]:[_c('a-sheet',{staticClass:"pb-4",attrs:{"min-width":"340","max-width":"340","max-height":"400","min-height":"400"}},[_c('div',{staticClass:"px-6 py-4"},[_c('h3',{staticClass:"md-body-2 font-weight-medium grey--text text--darken-3"},[_vm._v("Trackers")])]),_c('div',{staticClass:"u-flex flex-column px-4 u-overflow-y c-tiny-scroll",staticStyle:{"max-height":"calc(400px - 53px)"}},[_vm._l((_vm.meeting.meeting_trackers),function(tracker){return [_c('div',{key:tracker.id,ref:'refTrackerMenu-' + tracker.id,refInFor:true,staticClass:"c-sidemenu-navlink u-flex-center-y u-cursor-pointer",class:[{
                                'c-sidemenu-navlink--active': _vm.currentTracker.id === tracker.id,
                                'c-sidemenu-navlink--hover': _vm.currentTracker.id !== tracker.id
                            }],on:{"click":function($event){return _vm.localSetCurrentTracker(tracker)}}},[_c('button',{staticClass:"c-sidemenu-navlink--link md-body-2 font-weight-medium"},[_vm._v(" "+_vm._s(tracker.name)+" ")]),_c('a-spacer'),_c('span',{staticClass:"c-sidemenu-navlink--count md-body-2 font-weight-bold grey--text text--darken-3"},[_vm._v(_vm._s(tracker.total_keywords_count))])],1),_c('a-divider',{key:'divider-' + tracker.id})]})],2)]),_c('a-sheet',{staticClass:"grey lighten-4",attrs:{"width":"1"}}),_c('a-sheet',{attrs:{"max-height":"400","min-height":"400"}},[_c('div',{ref:"refKeywordsList",staticClass:"u-overflow-y c-tiny-scroll pb-6",staticStyle:{"max-height":"400px"}},_vm._l((_vm.meeting.meeting_trackers),function(tracker){return _c('div',{key:tracker.id,ref:'refTracker-' + tracker.id,refInFor:true,attrs:{"data-tracker-observer":tracker.id}},[_c('div',{staticClass:"px-6 py-4 u-sticky white",staticStyle:{"top":"0px","left":"0px"}},[_c('h3',{staticClass:"md-body-2 font-weight-medium grey--text text--darken-3"},[_vm._v(" "+_vm._s(tracker.name)+" ")])]),_c('div',{staticClass:"u-flex flex-wrap px-6",staticStyle:{"column-gap":"8px","row-gap":"8px"}},[(parseInt(tracker.total_keywords_count) === 0)?_c('div',{staticClass:"u-flex-center-y",staticStyle:{"column-gap":"6px"}},[_c('a-icon',{attrs:{"size":"18","color":"grey darken-1"}},[_vm._v("error_outline")]),_c('span',{staticClass:"md-caption grey--text text--darken-3"},[_vm._v("There are no keywords available to this tracker.")])],1):_vm._l((tracker.meeting_keywords),function(keyword){return _c('div',{key:keyword.id,staticClass:"d-inline-flex u-cursor-pointer align-center justify-between md-caption",class:[
                                        'pl-2 pr-1 u-rounded-corners-full',
                                        _vm.currentTracker.id === keyword.meeting_tracker_id ? 'deep-purple darken-2 white--text' : 'u-border-d deep-purple--text text--darken-2'
                                    ],staticStyle:{"column-gap":"6px","padding-top":"3px","padding-bottom":"3px"},style:([_vm.currentTracker.id === keyword.meeting_tracker_id ? { border: '1px solid #B39DDB !important' } : {}]),on:{"click":function($event){return _vm.localSelectTracker(tracker)}}},[_vm._v(" "+_vm._s(keyword.name)+" "),_c('a-sheet',{staticClass:"u-rounded-corners-full u-flex-center font-weight-medium",class:[
                                            'deep-purple--text text--darken-3',
                                            _vm.currentTracker.id === keyword.meeting_tracker_id ? 'white' : 'deep-purple lighten-5'
                                        ],staticStyle:{"font-size":"10px"},attrs:{"width":"18","height":"18","color":"white"}},[_vm._v(" "+_vm._s(keyword.keyword_count)+" ")])],1)})],2)])}),0)])]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<time :datetime="output_value()">{{ output_string() }}</time>
</template>

<script>
    export default {
        props: [
			'inputFormat',
			'outputFormat',
			'value',
			'convertLocal'
		],
		methods: {
			output_string: function () {
				if (this.convertLocal == undefined)
				{
					return moment(this.value, this.inputFormat).format(this.outputFormat);
				}
				else
				{
					return moment.utc(this.value, this.inputFormat).local().format(this.outputFormat);
				}
			},
			output_value: function () {
				if (this.convertLocal == undefined)
				{
					return moment(this.value, this.inputFormat).format();
				}
				else
				{
					return moment.utc(this.value, this.inputFormat).local().format();
				}
			}
		}
    }
</script>

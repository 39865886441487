const api_endpoint = '/tile-filters'
const show_methods = ['show-add-end', 'show-add-start', 'show', 'show-update', 'show-all-add-start', 'show-all-add-end']
const modify_methods = ['only-add-start', 'only-add-end', 'only-update', 'only-add', 'only-all-add-start', 'only-all-add-end']

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        response: [],
        defaults: {
            list: [],
            item: {},
            meta: {},
            response: []
        }
    },

    mutations: {
        UPDATE_LIST (state, list) {
            state.list = list
        },
        UPDATE_LIST_ITEM (state, payload) {
            const index = state.list.findIndex(item => item.id === payload.id)
            state.list[index] = payload
        },
        MODIFY_LIST_ITEM (state, payload) {
            const index = state.list.findIndex(item => item.id === payload.id)
            if (index !== -1) Object.assign(state.list[index], payload)
        },
        APPEND_LIST_ITEM (state, payload) {
            state.list.push(payload)
        },
        PREPEND_LIST_ITEM (state, payload) {
            state.list.unshift(payload)
        },
        REMOVE_LIST_ITEM (state, payload) {
            const index = state.list.findIndex(item => item.id === payload.id)
            state.list.splice(index, 1)
        },
        UPDATE_ITEM (state, item) {
            state.item = item
        },
        UPSERT_ITEM (state, payload) {
            Object.assign(state.item, payload)
        },
        UPDATE_META (state, meta) {
            state.meta = meta
        },
        THROW_RESPONSE (state, response) {
            state.response = response
        },
    },

    actions: {
        async index ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                const { data: { data }, meta } = await axios.get(api_endpoint, { params: payload })
                commit('UPDATE_LIST', data)
                commit('UPDATE_META', meta)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async show ({ dispatch }, payload) {
            dispatch('loader_start')
            try {
                const { data: { data } } = await axios.get(`${api_endpoint}/${payload.id}`, { params: payload.params })
                dispatch('show_ops', { payload, data })
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async store ({ dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.post(api_endpoint, payload)
                if (modify_methods.includes(payload.mode)) dispatch('add_ops', payload)
                if (show_methods.includes(payload.mode)) dispatch('show', payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async update ({ dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.patch(`${api_endpoint}/${payload.id}`, payload)
                if (modify_methods.includes(payload.mode)) dispatch('add_ops', payload)
                if (show_methods.includes(payload.mode)) dispatch('show', payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async destroy ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.delete(`${api_endpoint}/${payload.id}`)
                commit('REMOVE_LIST_ITEM', payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async select ({ commit, state }, payload) {
            const item = state.list.find(item => item.id === payload.id)
            commit('UPDATE_ITEM', { ...item })
        },

        async add_ops ({ commit }, payload) {
            if (payload.mode === 'only-add-start') commit('PREPEND_LIST_ITEM', payload)
            if (payload.mode === 'only-add-end') commit('APPEND_LIST_ITEM', payload)
            if (payload.mode === 'only-update') commit('MODIFY_LIST_ITEM', payload)
            if (payload.mode === 'only-add') commit('UPDATE_ITEM', payload)
            if (payload.mode === 'only-all-add-start') {
                commit('PREPEND_LIST_ITEM', payload)
                commit('UPDATE_ITEM', payload)
            }
            if (payload.mode === 'only-all-add-end') {
                commit('APPEND_LIST_ITEM', payload)
                commit('UPDATE_ITEM', payload)
            }
            if (payload.mode === 'only-all-add-modify') {
                commit('MODIFY_LIST_ITEM', payload)
                commit('UPSERT_ITEM', payload)
            }
            if (payload.mode === 'only-add-modify') {
                commit('MODIFY_LIST_ITEM', payload)
            }
        },

        async show_ops ({ commit }, { payload, data }) {
            if (payload.mode === 'show-update') commit('UPDATE_LIST_ITEM', data)
            if (payload.mode === 'show-add-end') commit('APPEND_LIST_ITEM', data)
            if (payload.mode === 'show-add-start') commit('PREPEND_LIST_ITEM', data)
            if (payload.mode === 'show') commit('UPDATE_ITEM', data)
            if (payload.mode === 'show-all-add-start') {
                commit('PREPEND_LIST_ITEM', data ?? payload)
                commit('UPDATE_ITEM', data ?? payload)
            }
            if (payload.mode === 'show-all-add-end') {
                commit('APPEND_LIST_ITEM', data ?? payload)
                commit('UPDATE_ITEM', data ?? payload)
            }
            if (payload.mode === 'show-modify') {
                commit('MODIFY_LIST_ITEM', data ?? payload)
            }
        },

        async throw_success ({ commit }) {
            commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            this.dispatch('Interface/loader_stop')
        },

        async throw_error ({ commit }, error) {
            this.dispatch('Interface/loader_stop')
            commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
        },

        async loader_start () {
            this.dispatch('Interface/loader_start')
        },

        async clear ({ commit }) {
            commit('UPDATE_LIST', [])
            commit('THROW_RESPONSE', [])
        },

        async clear_item ({ commit }) {
            commit('UPDATE_ITEM', {})
            commit('THROW_RESPONSE', [])
        },

        async clear_response ({ commit }) {
            commit('THROW_RESPONSE', [])
        },
    },

    getters: {
        list: state => state.list,
        item: state => state.item,
        response: state => state.response,
    }
}

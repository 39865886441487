<template>
    <a-card>
        <div class="u-flex-center-y pa-6 py-5 u-relative" style="column-gap: 8px;">
            <a-icon size="24" color="indigo darken-2" outlined>format_shapes</a-icon>
            <h2 class="indigo--text text--darken-2 md-subtitle-1 font-weight-medium">Keywords - {{ meeting.title }}</h2>
            <a-icon
                @click="$emit('close')"
                size="18"
                color="grey darken-1"
                class="u-absolute"
                style="top: 50%; transform: translateY(-50%); right: 24px;"
            >
                cancel
            </a-icon>
        </div>
        <a-divider></a-divider>
        <div class="u-flex align-stretch">
            <template v-if="meeting.meeting_trackers && !meeting.meeting_trackers.length">
                <a-sheet min-height="400" class="u-flex-center u-wfull">
                    <div class="pa-6 u-flex-center-y">
                        <a-icon size="24" color="grey darken-2">info</a-icon>
                        <h3 class="md-subtitle-1 ml-2 font-weight-medium grey--text text--darken-3">There are no trackers here to display</h3>
                    </div>
                </a-sheet>
            </template>
            <template v-else>
                <a-sheet min-width="340" max-width="340" max-height="400" min-height="400" class="pb-4">
                    <div class="px-6 py-4">
                        <h3 class="md-body-2 font-weight-medium grey--text text--darken-3">Trackers</h3>
                    </div>
                    <div class="u-flex flex-column px-4 u-overflow-y c-tiny-scroll" style="max-height: calc(400px - 53px);">
                        <template v-for="(tracker) in meeting.meeting_trackers">
                            <div
                                :key="tracker.id"
                                :class="[{
                                    'c-sidemenu-navlink--active': currentTracker.id === tracker.id,
                                    'c-sidemenu-navlink--hover': currentTracker.id !== tracker.id
                                }]"
                                :ref="'refTrackerMenu-' + tracker.id"
                                class="c-sidemenu-navlink u-flex-center-y u-cursor-pointer"
                                @click="localSetCurrentTracker(tracker)"
                            >
                                <button class="c-sidemenu-navlink--link md-body-2 font-weight-medium">
                                    {{ tracker.name }}
                                </button>
                                <a-spacer></a-spacer>
                                <span class="c-sidemenu-navlink--count md-body-2 font-weight-bold grey--text text--darken-3">{{ tracker.total_keywords_count }}</span>
                            </div>
                            <a-divider :key="'divider-' + tracker.id"></a-divider>
                        </template>
                    </div>
                </a-sheet>
                <a-sheet class="grey lighten-4" width="1"></a-sheet>
                <a-sheet max-height="400" min-height="400">
                    <div ref="refKeywordsList" class="u-overflow-y c-tiny-scroll pb-6" style="max-height: 400px;">
                        <div
                            v-for="(tracker) in meeting.meeting_trackers"
                            :key="tracker.id"
                            :data-tracker-observer="tracker.id"
                            :ref="'refTracker-' + tracker.id"
                        >
                            <!-- Tracker name -->
                            <div class="px-6 py-4 u-sticky white" style="top: 0px; left: 0px;">
                                <h3 class="md-body-2 font-weight-medium grey--text text--darken-3">
                                    {{ tracker.name }}
                                </h3>
                            </div>
                            <!-- Keywords list -->
                            <div class="u-flex flex-wrap px-6" style="column-gap: 8px; row-gap: 8px;">
                                <div v-if="parseInt(tracker.total_keywords_count) === 0" class="u-flex-center-y" style="column-gap: 6px;">
                                    <a-icon size="18" color="grey darken-1">error_outline</a-icon>
                                    <span class="md-caption grey--text text--darken-3">There are no keywords available to this tracker.</span>
                                </div>
                                <template v-else>
                                    <div
                                        v-for="keyword in tracker.meeting_keywords"
                                        :key="keyword.id"
                                        @click="localSelectTracker(tracker)"
                                        :class="[
                                            'pl-2 pr-1 u-rounded-corners-full',
                                            currentTracker.id === keyword.meeting_tracker_id ? 'deep-purple darken-2 white--text' : 'u-border-d deep-purple--text text--darken-2'
                                        ]"
                                        class="d-inline-flex u-cursor-pointer align-center justify-between md-caption"
                                        style="column-gap: 6px; padding-top: 3px; padding-bottom: 3px;"
                                        :style="[currentTracker.id === keyword.meeting_tracker_id ? { border: '1px solid #B39DDB !important' } : {}]"
                                    >
                                        {{ keyword.name }}
                                        <a-sheet
                                            width="18"
                                            height="18"
                                            color="white"
                                            class="u-rounded-corners-full u-flex-center font-weight-medium"
                                            style="font-size: 10px;"
                                            :class="[
                                                'deep-purple--text text--darken-3',
                                                currentTracker.id === keyword.meeting_tracker_id ? 'white' : 'deep-purple lighten-5'
                                            ]"
                                        >
                                            {{ keyword.keyword_count }}
                                        </a-sheet>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </a-sheet>
            </template>
        </div>
    </a-card>
</template>

<script>
export default {
    props: {
        meeting: {
            type: Object,
            required: true
        },
        isOpen: {
            type: Boolean,
        }
    },

    data () {
        return {
            currentTracker: {}
        }
    },


    watch: {
        isOpen: {
            handler (val) {
                if (val) this.localIndex()
            },
            immediate: true
        }
    },

    methods: {
        localIndex () {
            // this.trackers = this.meeting.meeting_trackers
            // this.keywords = [...this.meeting.meeting_trackers.map(tracker => tracker.meeting_keywords)]
            console.log('FIRST: ', this.meeting)
            this.currentTracker = this.meeting.meeting_trackers[0]
            setTimeout(() => this.localSetTrackerObserver(), 200)
        },

        localSetTrackerObserver () {
            // const observer = new IntersectionObserver(list => {
            //     list.forEach(item => {
            //         const elementId = item.target.dataset.trackerObserver
            //         // if (evt.target.scrollTop < 50) {
            //         //     const tracker = this.meeting.meeting_trackers[0]
            //         //     return this.localSetCurrentTracker(tracker)
            //         // }
            //         if (item.intersectionRatio === 1) console.log('Yeee')
            //         console.log(item.isIntersecting)
            //         // if (item.intersectionRatio > 0) {
            //         if (item.isIntersecting) {
            //             const tracker = this.meeting.meeting_trackers.find(i => i.id === elementId)
            //             this.localSetCurrentTracker(tracker)
            //         }
            //     })
            // })

            // document.querySelectorAll('[data-tracker-observer]')
            //     .forEach(tracker => {
            //         observer.observe(tracker)
            //     })
        },

        localSelectTracker (tracker) {
            this.localSetCurrentTracker(tracker)

            setTimeout(() => {
                const menu = this.$refs[`refTrackerMenu-${tracker.id}`]
                menu[0].scrollIntoView({ behavior: 'smooth', block: 'start' })
            }, 50)
        },

        localSetCurrentTracker (tracker) {
            this.currentTracker = tracker
            setTimeout(() => {
                const el = this.$refs[`refTracker-${tracker.id}`]
                el[0].scrollIntoView({ behavior: 'smooth', block: 'start' })
            }, 80)
        }
    }
}
</script>

<style scoped lang="scss">
.c-sidemenu-navlink {
    text-align: left;
    border-radius: 4px;
    padding: 12px;

    &--link { color: #616161 !important; }

    &--hover {
        &:hover {
            background-color: #E8EAF6 !important;
            .c-sidemenu-navlink--link { color: #3949AB !important; }
            .c-sidemenu-navlink--count {
                background-color: #E8EAF6 !important;
                color: #3949AB !important;
            }
        }
    }

    &--active {
        background-color: #1a237e !important;
        .c-sidemenu-navlink--link { color: #fff !important; }
        .c-sidemenu-navlink--count {
            background-color: #1a237e !important;
            color: #fff !important;
        }
    }
}
</style>

<template>
    <a-sheet class="u-flex align-center transparent" min-height="32" v-bind="$attrs">
        <div class="u-flex align-center">
            <template v-if="showEmptyProfile">
                <a-tooltip bottom v-if="!item.assignees || (item.assignees && !item.assignees.length)">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" style="border-radius: 50px;" :class="[can_update ? 'u-cursor-pointer' : '']" @click="can_update ? local_show_add_users_dialog() : {}">
                            <!-- <a-avatar size="32" color="grey lighten-2">
                                <a-icon size="20" dark>person</a-icon>
                            </a-avatar> -->
                            <a-avatar size="40" color="#fff" style="box-shadow: #e8e8e8 0px 2px 6px 1px" left>
                                <a-avatar :size="36" color="#f2f2f2">
                                    <a-icon color="grey darken-1" :size="18">person_add</a-icon>
                                    <!-- <a-img :src="item.avatar || item.user.avatar" :contain="contain">
                                    </a-img> -->
                                </a-avatar>
                            </a-avatar>
                        </div>
                    </template>
                    <span>No users assigned. {{ can_update ? 'Click to assign' : '' }}</span>
                </a-tooltip>
            </template>
            <template v-else>
                <a-btn v-if="can_update && !hideAddButton && showAddButton" icon small class="grey darken-2 white--text ml-0 mr-2 my-0" style="border: 1px solid #333;" @click="local_show_add_users_dialog()"><a-icon size="18">add</a-icon></a-btn>
            </template>
            <div @click="can_update ? local_show_add_users_dialog() : {}" :class="{ 'u-cursor-pointer': can_update }">
                <slot></slot>
            </div>
        </div>
        <div class="c-profiles" :class="[can_update ? 'u-cursor-pointer' : '']" v-if="item.assignees && item.assignees.length" @click="can_update ? local_show_add_users_dialog() : {}" :style="{ 'max-width': item.assignees.length * 32 + 'px', 'flex-direction': 'row-reverse' }">
            <div v-if="item.assignees && item.assignees.length > count" class="c-profiles__img-wrap">
                <a-menu offset-y right nudge-top="8" transition="slide-y-transition" :close-on-content-click="false" open-on-hover content-class="u-rounded-corners-xl elevation-5 u-border">
                    <template v-slot:activator="{ on }">
                        <a-avatar v-on="on" size="30" class="grey lighten-4" style="border: 2px dashed #bdbdbd !important;">
                            <span class="md-caption font-weight-medium grey--text text--darken-1">+{{ item.assignees.length - count }}</span>
                        </a-avatar>
                    </template>
                    <a-sheet class="u-rounded-corners-xl white">
                        <a-list one-line style="max-height: 300px;" class="py-2 transparent overflow-y-auto">
                            <a-list-item :class="[item.assignees.slice(count).length - 1 !== index ? 'pb-1' : '']" v-for="(assignee, index) in item.assignees.slice(count)" :key="assignee.assignee ? assignee.assignee.assignee_id : index">
                                <a-layout align-center class="py-0" v-if="assignee.assignee">
                                    <div class="member-avatar mr-2">
                                        <g-avatar :item="assignee" :size="32" :right-space="false"></g-avatar>
                                    </div>
                                    <div class="member-label">
                                        <template v-if="assignee">
                                            <h5 class="md-subtitle-1 grey--text text--darken-2 font-weight-medium text-truncate" style="max-width: 350px" :title="assignee.name">{{ assignee.name }}</h5>
                                            <template v-if="!hideRole">
                                                <p class="caption grey--text text--darken-2 mb-0" v-if="assignee.collaborator && assignee.collaborator.type">{{ assignee.collaborator.type.status }}</p>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <h5 class="md-subtitle-1 grey--text text--darken-2 font-weight-medium text-truncate" style="max-width: 350px" :title="assignee.name">{{ assignee.name }}</h5>
                                            <template v-if="!hideRole">
                                                <p class="caption grey--text text--darken-2 mb-0" v-if="assignee.resource_type === 'Customer'">Client</p>
                                                <p class="caption grey--text text--darken-2 mb-0" v-if="assignee.resource_type === 'Partner'">Partner</p>
                                            </template>
                                        </template>
                                    </div>
                                </a-layout>
                            </a-list-item>
                        </a-list>
                    </a-sheet>
                </a-menu>
            </div>
            <div v-for="assignee in item.assignees.slice(0, count).reverse()" :key="assignee.assignee.user_id" class="c-profiles__img-wrap c-profiles--ml-n2">
                <a-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <g-avatar :item="assignee" :size="34" :no-border="noBorder" :right-space="false"></g-avatar>
                        </div>
                    </template>
                    <span class="text-center">
                        <template v-if="assignee">
                            <h2 class="md-subtitle-1 font-weight-medium white--text">{{ assignee.name }} </h2>
                            <template v-if="!hideRole">
                                <p class="caption white--text mb-0" v-if="assignee.collaborator && assignee.collaborator.type">{{ assignee.collaborator.type.status }}</p>
                            </template>
                            <!-- <p class="caption white--text mb-0" v-if="assignee.type">{{ assignee.type.status }}</p> -->
                        </template>
                        <template v-else>
                            <h2 class="md-subtitle-1 font-weight-medium white--text">{{ assignee.name }} </h2>
                            <template v-if="!hideRole">
                                <p class="caption white--text mb-0" v-if="assignee.resource_type === 'Customer'">Client</p>
                                <p class="caption white--text mb-0" v-if="assignee.resource_type === 'Partner'">Partner</p>
                            </template>
                        </template>
                    </span>
                </a-tooltip>
            </div>
        </div>
        <template v-else>
            <div v-if="!showEmptyProfile" class="d-inline-flex align-center">
                <template v-if="!local_loading">
                    <slot name="no-users">
                        <span class="grey--text text--lighten-1 caption text-uppercase font-weight-medium">No Users Assigned</span>
                    </slot>
                </template>
                <span v-else class="grey--text text--lighten-1 caption text-uppercase font-weight-medium">Loading...</span>
            </div>
        </template>
    </a-sheet>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        can_update: {
            type: Boolean
        },
        showEmptyProfile: {
            type: Boolean,
            default: true
        },
        count: {
            type: Number,
            default: 2
        },
        local_loading: {
            type: Boolean
        },
        hideRole: {
            type: Boolean,
            default: true
        },
        hideAddButton: {
            type: Boolean,
            default: false
        },
        noBorder: {
            type: Boolean,
            default: false
        },
        showAddButton: {
            type: Boolean,
            default: true
        },
    },

    methods: {
        local_show_add_users_dialog () {
            this.$emit('show_add_users_dialog')
        },
        local_get_initial (name) {
            const [first, last] = _.split(name, ' ', 2)
            return last ? (first[0] ?? '') + (last[0] ?? '') : (first[0] ?? '')
        }
    }
}
</script>

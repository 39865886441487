<template>
    <a-layout align-start>
        <a-flex>
            <a-tabs class="mb-0" background-color="transparent" slider-color="primary">
                <a-tab ripple class="mx-2" :to="{name: 'settings-tags-labels'}">
                    <span class="md-body-2">Label</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-tags-products'}">
                    <span class="md-body-2">Product</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-tags-platforms'}">
                    <span class="md-body-2">Platform</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-tags-usecases'}">
                    <span class="md-body-2">Usecase</span>
                </a-tab>
            </a-tabs>
            <a-divider class="grey lighten-2"></a-divider>
        </a-flex>
    </a-layout>
</template>

<script>
export default {

}
</script>

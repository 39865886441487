<template>
    <span
        v-if="!tile"
        v-bind="$attrs"
        :title="methodGetLabel(tag) && methodGetLabel(tag).length >= textRange ? methodGetLabel(tag) : ''"
        :class="{ 'my-1': !dense, 'mr-2': !noMargin }"
        :style="[
            {
                backgroundColor: $color(tag.color, 'color_bg'),
                color: $color(tag.color, 'color_text'),
                fontSize: dataFontSize + 'px',
                height: height + 'px'
            },
            noPadding ? '' : { padding: '2px 8px' }
        ]"
        class="d-inline-flex align-center u-rounded-corners-full u-cursor-pointer"
    >
        {{ methodGetLabel(tag) | truncateText(textRange) }}
        <a-icon data-tag-type="remove-tag" v-if="canUpdate && !hideClearIcon" @click.prevent="$emit('destroy', tag, type)" class="c-icon u-rounded-corners-full u-icon-nudge ml-1" style="top: 0px;" size="12" :color="$color(tag.color, 'color_text')">clear</a-icon>
    </span>
    <span
        v-else
        v-bind="$attrs"
        :title="methodGetLabel(tag) && methodGetLabel(tag).length >= textRange ? methodGetLabel(tag) : ''"
        :class="[{ 'mr-3 mb-3': !noMargin, 'pa-2': !noPadding }]"
        class="u-rounded-corners md-body-2 d-inline-flex align-center flex-wrap"
        :style="{ backgroundColor: $color(tag.color, 'color_bg'), color: $color(tag.color, 'color_text'), transition: '0.4s box-shadow', boxShadow: '0px 0px 0 1px' }"
    >
        <span>{{ methodGetLabel(tag) | truncateText(textRange) }}</span>
        <a-icon data-tag-type="remove-tag" v-if="canUpdate && !hideClearIcon" @click.prevent="$emit('destroy', tag, type)" :color="$color(tag.color, 'color_text')" size="16" :class="['ml-2 u-rounded-corners u-cursor-pointer', $color(tag.color, 'name') === 'black' ? 'c-icon--dark' : 'c-icon']">clear</a-icon>
    </span>
</template>

<script>
export default {
    props: {
        tag: {
            type: Object | String,
            required: true
        },
        type: {
            type: String,
            default: ''
        },
        tile: {
            type: Boolean,
            default: false
        },
        textRange: {
            type: Number,
            default: 40
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        noMargin: {
            type: Boolean,
            default: false
        },
        noPadding: {
            type: Boolean,
            default: false
        },
        hideClearIcon: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default: 22
        },
        textMd: {
            type: Boolean,
            default: false
        },
        textLg: {
            type: Boolean,
            default: false
        },
        textXl: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        dataFontSize () {
            if (this.textMd) return 12
            if (this.textLg) return 14
            if (this.textXl) return 16
            return 12
        }
    },

    methods: {
        methodGetLabel (tag) {
            if (tag && tag.label) return tag.label
            if (tag && tag.name) return tag.name
            if (tag && tag.value) return tag.value
            return tag
        }
    }
}
</script>

<template>
    <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden" flat>
        <a-progress-linear class="my-0" height="3" color="orange darken-3" :indeterminate="true" v-if="loading"></a-progress-linear>
        <a-card-text class="pa-2">
            <a-container container--fluid grid-list-xl class="pa-4">
                <a-layout align-start>
                    <a-flex shrink>
                        <a-icon size="72" color="grey lighten-1">cloud_download</a-icon>
                    </a-flex>
                    <a-flex>
                        <h2 class="mb-2 md-heading-6">
                            <span v-if="loading">Preparing file...</span>
                            <span v-else>Ready to Download</span>
                        </h2>
                        <p class="mb-0 md-body-2 grey--text text--darken-1">
                            <span v-if="loading">Success is preparing your file for download. It may take a few seconds to get ready.</span>
                            <span v-else>Your file is ready to download. Click on the "Save File" button to download the attachment.</span>
                        </p>
                        <a-btn :href="link.download" @click="$emit('close')" small class="px-3 mt-3 elevation-0 ma-0" color="primary" :disabled="loading" download>
                            <a-icon size="14" class="mr-2">save</a-icon>
                            Save File
                        </a-btn>
                        <a-btn @click="$emit('close')" class="px-3 mt-3 ml-3 elevation-0 ma-0" height="28" color="grey" text :disabled="loading">
                            <span class="md-caption text-uppercase">Cancel</span>
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-card-text>
    </a-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        link: {
            type: Object,
            required: true
        },

        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>

const api_endpoint = '/notifications';

export default {
    namespaced: true,
    state: {
        list: [],
        meta: {},
        meta_links: {},
        unread_count: 0,
        response: {},
        defaults: {
            list: [],
            meta: {},
            meta_links: {},
            response: {},
        },
    },

    mutations: {
        UPDATE_LIST (state, items) {
            state.list = items
        },

        PREPEND_LIST (state, items = []) {
            state.list = [...state.list, ...items]
        },

        ADD_LIST_ITEM (state, payload) {
            state.list.unshift(payload)
        },

        UPDATE_META (state, meta) {
            state.meta = meta;
        },

        UPDATE_META_LINKS (state, meta_links) {
            state.meta_links = meta_links;
        },

        UPDATE_UNREAD_COUNT (state, count) {
            state.unread_count = count;
        },

        THROW_RESPONSE (state, payload) {
            state.response = payload;
        },
    },

    actions: {
        async index(context, payload = {}) {
            this.dispatch('Interface/loader_start')
            try {
                const response = await axios.get(api_endpoint, { params: payload })
                this.dispatch('Interface/loader_stop')

                if (!payload?._type) context.commit('UPDATE_LIST', response.data.data)
                if (payload?._type === 'prepend') context.commit('PREPEND_LIST', response.data.data)

                context.commit('UPDATE_META', response.data.meta)
                context.commit('UPDATE_META_LINKS', response.data.links)
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response?.data?.message, status: 'error' })
            }
        },

        async get_unread_count (context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data } } = await axios.get(`${api_endpoint}/unread-count`)
                this.dispatch('Interface/loader_stop')
                context.commit('UPDATE_UNREAD_COUNT', data.unread_count)
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response?.data?.message, status: 'error' })
            }
        },

        async read (context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.patch(`${api_endpoint}/read/${payload.id}`)
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response?.data?.message, status: 'error' })
            }
        },

        async unread (context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.patch(`${api_endpoint}/unread/${payload.id}`)
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response?.data?.message, status: 'error' })
            }
        },

        async read_all (context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.patch(`${api_endpoint}/read-all`)
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response?.data?.message, status: 'error' })
            }
        },

        async add (context, payload) {
            context.commit('ADD_LIST_ITEM', payload)
        },

        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                resolve('Cleared');
            });
        },
    },
}

<template>
    <div>
        <div :class="['u-relative u-rounded-corners white']">
            <!-- Editor view -->
            <a-sheet class="c-custom-editor c-custom-editor--editor-parent c-custom-editor--comment-field transparent u-overflow-y py-1" :min-height="editorMinHeight" :max-height="editorHeight">
                <div @click="local_get_xy" :class="['primary--text md-body-1 c-transition u-relative px-4 pt-2']" style="min-height: auto; line-height: 24px !important; cursor: text">
                    <editor-content :editor="editor" />
                </div>
            </a-sheet>

            <a-sheet class="u-flex-center-y transparent px-4 py-2">
                <g-toggle custom-bg dense-xl v-model="check_is_public" class="d-inline-flex align-center" :color="check_is_public ? 'orange darken-2' : 'grey darken-4'">
                    <span :class="[check_is_public ? 'orange--text text--darken-2' : 'grey--text text--darken-3', 'font-weight-medium md-caption text-uppercase ml-2']">{{ check_is_public ? 'Public' : 'Private' }}</span>
                </g-toggle>
                <a-spacer></a-spacer>
                <a-menu v-model="emoji_dropdown" offset-y bottom left :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <a-sheet v-on="on" class="u-rounded-corners-full mr-1" :min-height="local_format_height" :class="[local_format_icon_styles, local_units]" v-ripple="canUpdate">
                            <a-icon size="20" color="body lighten-2">insert_emoticon</a-icon>
                        </a-sheet>
                    </template>
                    <Picker :data="emojiIndex" set="facebook" @select="local_add_emoji" />
                </a-menu>
                <a-sheet width="22" height="22" @click="local_update()" v-ripple class="u-rounded-corners-full pa-1 u-cursor-pointer u-flex-center-y">
                    <a-progress-circular v-if="saveLoader" width="3" size="16" indeterminate color="orange darken-1"></a-progress-circular>
                    <a-icon v-else size="20" color="secondary">send</a-icon>
                </a-sheet>
            </a-sheet>

        </div>

        <div class="u-flex-center-y md-caption body--text text--lighten-4 mt-2">
            <span :class="[{ 'red--text text--darken-2': char_count > 5000 } ]">{{ char_count }} / 5000</span>
            <a-spacer></a-spacer>
            <span class="u-flex-center-y">
                <span>Press</span>
                <span class="font-weight-bold mx-1">Ctrl + Enter</span>
                <span>to comment</span>
            </span>
        </div>

        <a-dialog v-model="dialog_link" width="500" transition="slide-y-transition" origin="top center" :overlay-opactiy="0.1">
            <a-card class="pa-6">
                <h2 class="md-subtitle-1 grey--text text--darken-3 mb-3">Insert or Update Link</h2>
                <a-text-field v-model="link_model_value" placeholder="Ex: https://success.app" class="u-border u-rounded-corners" solo flat autofocus hide-details></a-text-field>
                <span class="mt-6 u-flex-center-y">
                    <a-btn small depressed class="primary white--text mr-2" @click="local_run_command('setLink')">Update</a-btn>
                    <a-btn small depressed class="grey--text text--darken-1" @click="dialog_link = false">Cancel</a-btn>
                    <a-spacer></a-spacer>
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-btn v-on="on" small icon dense class="grey--text text--darken-1 grey lighten-5" :disabled="!link_model_value || !(link_model_value && link_model_value.trim())" @click="local_run_command('unsetLink')">
                                <a-icon size="18">link_off</a-icon>
                            </a-btn>
                        </template>
                        <span>Unlink</span>
                    </a-tooltip>
                </span>
            </a-card>
        </a-dialog>

        <a-menu v-model="dialog_popover" :close-on-click="false" nudge-bottom="12" offset-y bottom :position-x="posX" :position-y="posY" absolute transition="slide-y-transition" :close-on-content-click="false" activator="c-linker" v-if="editor && editor.getAttributes('link') && editor.getAttributes('link').href">
            <a-sheet class="d-inline-flex align-center u-elevation-custom-1 u-rounded-corners px-3 py-1" min-height="32">
                <a-icon size="16" color="grey darken-1" class="u-icon-nudge-xs" style="top: 1px">public</a-icon>
                <a
                    target="_blank"
                    class="md-body-2 d-block blue--text text--darken-4 mx-2"
                    :href="editor.getAttributes('link').href"
                    :title="editor.getAttributes('link').href && editor.getAttributes('link').href.length > 25 ? editor.getAttributes('link').href : ''"
                    rel="noopener noreferrer nofollow"
                >{{ editor.getAttributes('link').href | truncateText(25) }}</a>
                <a-spacer></a-spacer>
                <div class="u-flex-center-y">
                    <a-btn v-clipboard:copy="editor.getAttributes('link').href" v-clipboard:success="local_link_cpy_msg" small icon dense>
                        <a-icon size="16" color="grey darken-1">content_copy</a-icon>
                    </a-btn>
                    <a-btn @click="local_open_link_dialog('edit-mode')" small icon dense class="mx-1">
                        <a-icon size="16" color="grey darken-1">edit</a-icon>
                    </a-btn>
                    <a-btn @click="local_run_command('unsetLink')" small icon dense>
                        <a-icon size="16" color="grey darken-1">link_off</a-icon>
                    </a-btn>
                </div>
            </a-sheet>
        </a-menu>
    </div>
</template>

<script>
import { Editor, EditorContent, VueRenderer } from '@tiptap/vue-2'
import { Extension } from '@tiptap/core'
import { generateHTML } from '@tiptap/html'
import { SEditorFormatBar } from '@/config/config-shared-components'
import tippy from 'tippy.js'

// Tiptap extensions
import Link from '@tiptap/extension-link'
import Mention from '@tiptap/extension-mention'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Underline from '@tiptap/extension-underline'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import Typography from '@tiptap/extension-typography'
import Placeholder from '@tiptap/extension-placeholder'
import { SEmojiList } from '@/config/config-shared-components'
import EnterCtrlHandler from '@/helpers/helper-tiptap-ctrl-enter-keydown'

// Emoji config
import data from 'emoji-mart-vue-fast/data/facebook.json'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
const emojiIndex = new EmojiIndex(data)

export default {
    name: 'PartialEditorBox',

    props: {
        dense: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean | Object,
            default: null
        },
        modelIsOpen: {
            type: Boolean,
            default: false
        },
        value: {
            type: String | Object,
            required: true
        },
        returnType: {
            type: String,
            default: 'json'
        },
        saveLoader: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        hideDivider: {
            type: Boolean,
            default: false
        },
        editorHeight: {
            type: Number,
            default: 64
        },
        editorMinHeight: {
            type: Number | String,
            default: 64
        },
        absoluteFormatter: {
            type: Boolean,
            default: false
        },
        iconSize: {
            type: String | Number,
            default: '24'
        },
        iconColor: {
            type: String,
            default: '#444'
        },
        charLimit: {
            type: Number,
            default: 5000
        }
    },

    components: { EditorContent, SEditorFormatBar, Picker },

    data () {
        return {
            emojiIndex: emojiIndex,
            emoji_dropdown: false,
            check_is_public: true,
            editor: null,
            dialog_link: false,
            dialog_popover: false,
            link_model_value: '',
            link_edit_mode: false,
            show_editor: false,
            menu_header: false,
            selected_heading: 'text',
            focus_class: '',
            char_count: 0,
            is_focused: false,
            show_more_desc: false,
            titles: ['text', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
            posX: 0,
            posY: 0
        }
    },

    watch: {
        dialog_popover (val) {
            setTimeout(() => {
                if (!val && this.editor.isActive('link')) this.dialog_popover = true
            }, 0)
        }
    },

    mounted () {
        document.addEventListener('keydown', this.local_click_outside)
        this.local_index()
    },

    beforeDestroy () {
        document.removeEventListener('keydown', this.local_click_outside)
        if (this.editor) this.editor.destroy()
    },

    computed: {
        local_return_data () {
            return this.returnType === 'json' ? this.editor.getJSON() : this.editor.getHTML()
        },

        local_tiptap_packs () {
            return [StarterKit, Document, Underline, ListItem, BulletList, OrderedList, Link, Mention, EnterCtrlHandler]
        },

        local_editor_content () {
            const emptyText = { type: 'doc', content: [] }
            const content = !this.value || (this.value && !this.value.trim()) ? emptyText : JSON.parse(this.value)
            return generateHTML(content, this.local_tiptap_packs)
        },

        local_heading_level () {
            let isLevel = false
            if (this.editor) {
                for (const iterator of [...Array(6).keys()]) {
                    const hasLevel = this.editor.isActive('heading', { level: iterator + 1 })
                    const hasPara = this.editor.isActive('paragraph')
                    if (hasLevel) isLevel = 'h' + (iterator + 1)
                    if (hasPara) isLevel = 'text'
                }
            }
            return isLevel
        },

        local_format_icon_styles () {
            const cursorPointer = this.canUpdate ? 'u-cursor-pointer' : ''
            return 'transparent u-flex-center ' + cursorPointer
        },

        local_units () {
            return 'pa-1'
        },

        local_format_height () {
            return 22
        },

        local_format_bar_focus_class () {
            return {
                height: `${this.local_format_height}px !important`,
                bottom: '0px !important',
                borderTop: '1px solid #efefef !important'
            }
        },

        local_container_focus_class () {
            return {
                marginBottom: '48px !important',
            }
        },

        local_get_desc () {
            const element = document.createElement('div')
            const emptyText = { type: 'doc', content: [] }
            const content = !this.value || (this.value && !this.value.trim()) ? emptyText : JSON.parse(this.value)
            const desc = generateHTML(content, this.local_tiptap_packs)
            element.innerHTML = desc
            return element
        },

        local_is_more () {
            const { innerHTML, innerText } = this.local_get_desc
            const getLength =  innerHTML.split('\n').length ?? null
            return (getLength && getLength > 5) || innerText.length > 966
        },

        local_tippy_start_props () {
            return {
                appendTo: () => document.body,
                showOnCreate: true, interactive: true, trigger: 'manual', placement: 'bottom-start',
            }
        }
    },

    methods: {
        local_index () {
            const limit = this.charLimit
            const vm = this
            this.editor = new Editor({
                editorProps: {
                    handleTextInput(view) {
                        if (view.state.doc.textContent.length >= limit && view.state.selection.empty) return true
                    },
                    handlePaste(view, event, slice) {
                        if (view.state.doc.textContent.length + slice.size > limit) {
                            slice.content = new DocumentFragment()
                        }
                    }
                },
                extensions: [
                    StarterKit, Document, Underline, ListItem, BulletList, OrderedList, Typography,
                    new (class extends Extension {
                        keys () {
                            return {
                                Enter () {
                                    vm.local_update()
                                    return true
                                },
                            }
                        }
                    })(),
                    // EnterCtrlHandler.configure({
                    //     props: {
                    //         handleKeyDown: (evt) => console.log({ evt })
                    //     }
                    // }),
                    Link.configure({
                        HTMLAttributes: { class: 'c-linker' },
                        openOnClick: false
                    }),
                    Placeholder.configure({
                        // placeholder: this.canUpdate ? 'Write something down...' : 'Description not added...',
                        placeholder: 'Write something down...',
                        showOnlyWhenEditable: false
                    }),
                    Mention.configure({
                        HTMLAttributes: { class: 'c-emoji' },
                        suggestion: {
                            char: ':',
                            command: ({ editor, range, props }) => {
                                editor.chain().focus().insertContentAt(range, props.label).run()
                            },
                            items: this.local_search_emojis,
                            render: this.local_emoji_rendering,
                        },
                    })
                ],
                autofocus: this.autofocus ?? false,
                content: this.local_editor_content,
                editable: this.canUpdate,
                parseOptions: { preserveWhitespace: 'full' },
                onUpdate: ({ editor }) => {
                    const textCount = editor.state.doc.textContent.length
                    this.char_count = textCount
                    // this.local_update()
                },
                onFocus: ({ editor }) => this.local_set_focus(false),
                onKeyDown: (hello, world) => {}
            })

            // if (this.dense) this.local_set_focus()
        },

        local_update () {
            this.$emit('input', this.local_return_data)
            this.$emit('update', this.local_return_data)
            this.$emit('update:value', this.local_return_data)
        },

        local_set_focus(set_editor_focus = true) {
            if (set_editor_focus) this.editor.commands.focus()
            this.is_focused = true
            this.$emit('isFocused', this.is_focused)
            this.focus_class = this.dense ? 'u-border' : 'white u-shadow'
            this.char_count = this.editor.state.doc.textContent.length
        },

        local_set_title_format (title) {
            if (!this.canUpdate) return
            title !== 'text' ?
                this.editor.chain().focus().toggleHeading({ level: Number(title.split('h')[1]) }).run() :
                this.editor.chain().focus().setParagraph().run()
        },

        local_get_xy (evt) {
            this.dialog_popover = false
            this.posX = evt.clientX
            this.posY = evt.clientY
            setTimeout(() => {
                if (this.editor.isActive('link')) this.dialog_popover = true
                else this.dialog_popover = false
            }, 400)
        },

        local_open_link_dialog (mode) {
            const previousUrl = this.editor.getAttributes('link').href
            this.link_edit_mode = mode ?? false
            this.link_model_value = previousUrl ?? ''
            this.dialog_link = true
        },

        local_run_command (command) {
            if (!this.canUpdate) return
            const execute = this.editor.chain().focus()
            switch (command) {
                case 'setLink':
                case 'unsetLink': return this.local_link_execute(command)
                case 'toggleBold': return execute.toggleBold().run()
                case 'toggleItalic': return execute.toggleItalic().run()
                case 'toggleUnderline': return execute.toggleUnderline().run()
                case 'toggleStrike': return execute.toggleStrike().run()
                case 'toggleBulletList': return execute.toggleBulletList().run()
                case 'toggleOrderedList': return execute.toggleOrderedList().run()
            }
        },

        local_link_execute (command) {
            const previousUrl = this.editor.getAttributes('link').href
            this.link_model_value = this.link_edit_mode ? this.link_model_value : (previousUrl ?? this.link_model_value)
            this.dialog_link = true
            if (this.link_model_value === null) return
            if (this.link_model_value === '' || command === 'unsetLink') {
                this.link_model_value = ''
                this.link_edit_mode = false
                this.local_link_dialogs_reset()
                return execute.extendMarkRange('link').unsetLink().run()
            }
            execute.extendMarkRange('link').setLink({ href: this.link_model_value, target: '_blank' }).run()
            this.local_link_dialogs_reset()
        },

        local_link_dialogs_reset () {
            this.dialog_link = false
            this.dialog_popover = false
        },

        local_title_active_color (title) {
            return this.local_heading_level === title ? 'indigo--text text--darken-2 grey lighten-3' : 'body--text text--lighten-2'
        },

        local_show_active_color (type) {
            return this.editor.isActive(type) ? 'indigo darken-2' : 'body lighten-2'
        },

        local_link_cpy_msg (evt, link) {
            this.$notify('success', 'Link copied successfully!')
        },

        local_click_outside (evt) {
            // if (this.dense) return
            // if (evt.keyCode === 27 && evt.key === 'Escape' && !this.dense && !this.modelIsOpen) {
            //     this.local_close_editor()
            // }
            // console.log({ evt })
            // if (evt.keyCode === 13 && evt.ctrlKey) {
            //     evt.preventDefault()
            //     evt.stopPropagation()
            //     this.local_update()
            // }
        },

        async local_close_editor () {
            if (this.editor) this.editor.commands.blur()
            setTimeout(() => {
                this.is_focused = false
                this.menu_header = false
                this.focus_class = ''
                this.$emit('isFocused', this.is_focused)
            }, 0)
        },

        // Emoji methods
        local_add_emoji (emoji) {
            this.editor.chain().focus().insertContent(emoji.native).run()
            this.emoji_dropdown = false
        },

        local_search_emojis (query) {
            return Object.keys(this.emojiIndex._emojis)
                        .sort()
                        .map(item => {
                            const emojiValue = this.emojiIndex._emojis
                            const searchText = item.toLowerCase().startsWith(query.toLowerCase())
                            if (searchText) return emojiValue[item]
                        })
                        .filter(i => i !== undefined)
        },

        local_emoji_rendering () {
            let component
            let popup

            return {
                onStart: props => {
                    component = new VueRenderer(SEmojiList, { parent: this, propsData: props })
                    popup = tippy('body', {
                        getReferenceClientRect: props.clientRect,
                        content: component.element,
                        ...this.local_tippy_start_props
                    })
                },

                onUpdate (props) {
                    component.updateProps(props)
                    popup[0].setProps({ getReferenceClientRect: props.clientRect })
                },

                onKeyDown (props) {
                    if (props.event.key === 'Escape') return popup[0].hide()
                    return component.ref?.onKeyDown(props)
                },

                onExit () {
                    popup[0].destroy()
                    component.destroy()
                },
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.is-active {
    transition: 0.4s ease-in-out;
    color: #3949AB !important;
}

.c-transition {
    transition: 0.2s all !important;
}
</style>

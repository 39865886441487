<template>
	<a-main v-if="show_project_view">
        <div class="layout-container">
            <div class="layout-container__item layout-container__item--overview">
                <s-external-project-overview></s-external-project-overview>
            </div>
            <div class="layout-container__item-view">
                <s-external-project-header class="mt-4" />
                <!-- <a-sheet class="transparent" min-width="1200" max-width="1200" style="margin-left: auto; margin-right: auto;"> -->
                <a-sheet class="transparent" :max-width="$route.name === 'external-projects-tickets-view' ? ($vuetify.breakpoint.lgAndDown ? 1440 : 1580) : 1200" style="margin-left: auto; margin-right: auto;">
                    <router-view class="u-hfull"></router-view>
                </a-sheet>
            </div>
            <!-- <div :class="[$route.name === 'projects-usecases-view' || $route.name === 'projects-notes-view' ? 'c-move-right' : 'c-normal']" class="layout-container__item layout-container__item--activities"> -->
            <div :class="[$route.name === 'external-projects-usecases-view' || $route.name === 'external-projects-notes-view' || $route.name === 'external-projects-tickets-view' ? 'c-move-right' : 'c-normal']" class="layout-container__item layout-container__item--activities">
                <s-external-project-activities></s-external-project-activities>
            </div>
        </div>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { SExternalProjectHeader, SExternalProjectOverview, SExternalProjectActivities } from '@/config/config-shared-components'

export default {
    data() {
        return {
            show_project_view: false
        }
    },

    components: {
        SExternalProjectHeader,
        SExternalProjectOverview,
        SExternalProjectActivities,
    },

    mounted() {
        this.local_index()
    },

    computed: {
        localRouteView () {
            const viewName = this.$route.params.view
            return viewName.charAt(0).toUpperCase() + viewName.slice(1)
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Role', {
            role_external_permission: 'external_permission',
        }),
        ...mapState('Project', {
            project_item: 'item',
            project_response: 'response',
        }),
    },

    methods: {
        async local_index() {
            await this.role_external_index({
                include: 'source.permissions',
                'fields[roles]': 'id,name,scope,source_id',
                'fields[source]': 'id,name,scope',
                'filter[name]': this.localRouteView
                // 'filter[name]': this.$route.params.view.charAt(0).toUpperCase() + this.$route.params.view.slice(1)
            })
            await this.project_show({id: this.$route.params.id})
            if (this.project_response && this.project_response.status === 'success') {
                this.show_project_view = true
            }
        },
        ...mapActions('Role', {
            role_external_index: 'external_index',
        }),
        ...mapActions('Project', {
            project_show: 'show',
            project_clear_item: 'clear_item',
        }),
    }
}
</script>

<style scoped>
    .c-normal {
        position: relative !important;
        right: 0px !important;
        display: block !important;
    }
    .c-move-right {
        position: absolute !important;
        right: -1000px !important;
        display: none !important;
    }
    .layout-container {
        display: flex;
        height: 100%;
        overflow-y: auto !important;
    }
    .layout-container__item {
        min-height: calc(100vh - 81px);
        overflow-y: auto !important;
        flex: 1 1 auto;
    }
    .layout-container__item-view {
        height: 100%;
        overflow-y: auto !important;
        flex: 1 1 auto;
    }
    .layout-container__item--overview {
        width: 320px;
        flex: 0 0 auto;
    }
    .layout-container__item--activities {
        width: 320px;
        flex: 0 0 auto;
    }
</style>

<template>
    <a-card>
        <a-sheet class="py-5 px-6">
            <p class="text-h6 indigo--text text--darken-1 font-weight-medium ma-0 pa-0">
                <a-avatar class="indigo darken-1 mr-1" size="40">
                    <a-icon size="20" dark>menu_open</a-icon>
                </a-avatar>
                {{ logType === 'automation' ? 'Automation' : 'Validation' }} Log
            </p>
        </a-sheet>
        <a-divider class="grey lighten-3"></a-divider>
        <template v-if="isLoading">
            <a-divider class="grey lighten-3"></a-divider>
            <a-sheet class="py-5 px-6">
                <div class="u-flex">
                    <loader-template height="20" width="8%" class="u-rounded-corners-lg mr-2"></loader-template>
                    <loader-template height="20" width="80%" class="u-rounded-corners-lg"></loader-template>
                </div>
                <loader-template height="16" width="80%" class="u-rounded-corners-lg mt-4"></loader-template>
                <div class="u-flex mt-4">
                    <loader-template height="20" width="15%" class="u-rounded-corners-lg mr-1"></loader-template>
                    <p class="md-subtitle-2 grey--text text--darken-5 font-weight-medium mb-0 mx-2">
                        <span class="mr-2">&bull;</span>
                    </p>
                    <loader-template height="20" width="30%" class="u-rounded-corners-lg mr-1"></loader-template>
                    <p class="md-subtitle-2 grey--text text--darken-5 font-weight-medium mb-0 mx-2">
                        <span class="mr-2">&bull;</span>
                    </p>
                    <loader-template height="20" width="30%" class="u-rounded-corners-lg mr-1"></loader-template>
                </div>
            </a-sheet>
            <a-divider class="grey lighten-3"></a-divider>
            <a-sheet class="py-5 px-6">
                <div class="u-flex">
                    <loader-template height="35" width="30%" class="u-rounded-corners-lg mr-6"></loader-template>
                    <loader-template height="35" width="30%" class="u-rounded-corners-lg"></loader-template>
                </div>
            </a-sheet>
            <a-divider class="grey lighten-3"></a-divider>
            <a-sheet class="py-5 px-6 grey lighten-5">
                <div class="px-4">
                    <div class="c-log-summary u-flex-center-y u-wfull lighten-5" v-for="(i, index) in 6" :key="index" :class="[index === 0 ? 'mt-0' : 'mt-5']">
                        <loader-template height="20" width="2%" class="u-rounded-corners-lg mr-6"></loader-template>
                        <loader-template height="20" width="40%" class="u-rounded-corners-lg"></loader-template>
                    </div>
                </div>
            </a-sheet>
        </template>
        <a-card-text v-if="!isLoading" class="pa-0 ma-0" style="max-height: 700px;">
            <a-sheet class="py-5 px-6" v-if="item.automation_trigger">
                <p class="md-subtitle-2 grey--text text--darken-1 font-weight-medium mb-0">Trigger</p>
                <p class="body-2 grey--text text--darken-1 mb-0 mt-2" v-if="item && item.automation_trigger.slug">
                    <template v-if="item.automation_trigger.slug === 'project-result'">
                        <span class="body-2 grey--text text--darken-4 font-weight-medium mb-0 mt-2">Result update</span>
                    </template>
                    <template v-else>
                        <span class="grey--text text--darken-3 font-weight-bold" style="font-weight : 600 !important;">{{ localGetTriggerLabel(item.automation_trigger.slug) }}:</span>
                        <span class="mx-1 grey--text text--darken-4">{{ item.stage_from }}</span>
                        <a-icon size="16" color="grey darken-4" class="u-icon-nudge" style="top: 0px;">arrow_forward</a-icon>
                        <span class="mx-1 grey--text text--darken-4" v-if="item.stage_to">{{ item.stage_to }}</span>
                        <span class="mx-1 grey--text text--darken-4" v-else>{{ item.automation_trigger.slug === 'project-archived' ? 'Archive' : 'Completed' }}</span>
                    </template>
                </p>
                <p class="md-subtitle-2 grey--text text--darken-1 font-weight-medium mb-0 mt-4">{{ item.resource_type }}</p>
                <p class="body-2 grey--text text--darken-4 font-weight-medium mb-0 mt-2" style="font-weight : 500 !important;">
                    {{ item.resource_name ? item.resource_name : '-' }}
                </p>
                <p class="md-subtitle-2 grey--text text--darken-1 font-weight-medium mb-0 mt-4">{{ logType === 'automation' ? 'Automation' : 'Validation' }} Details</p>
                <p class="body-2 grey--text text--darken-4 font-weight-medium mb-0 mt-2" style="font-weight : 500 !important;">
                    {{ item.automation_workflow.title }}
                    <template v-if="$route.name === 'settings-automation-validations-logs'">
                        <router-link target="_blank" tag="a" class="u-cursor-pointer ml-1" :to="{name: 'settings-automations-edit', params: {id: item.automation_workflow.id} }" v-if="item.automation_workflow && item.automation_workflow.type === 'action'" :exact="true">
                            <a-icon size="18" color="blue" class="u-icon-nudge">launch</a-icon>
                        </router-link>
                        <router-link target="_blank" tag="a" class="u-cursor-pointer ml-1" :to="{name: 'settings-validations-edit', params: {id: item.automation_workflow.id} }" v-if="item.automation_workflow && item.automation_workflow.type === 'validation'" :exact="true">
                            <a-icon size="18" color="blue" class="u-icon-nudge">launch</a-icon>
                        </router-link>
                    </template>
                </p>
                <div class="u-flex mt-4">
                    <div class="c-log-status c-log-waring" style="width: 146px;" v-if="item.status === 'condition-failed'">Condition Not Met</div>
                    <div class="c-log-status c-log-error" style="width: 134px;" v-if="item.status === 'validation-failed'">Validation Failed</div>
                    <div class="c-log-status c-log-waring" style="width: 134px;" v-if="item.status === 'action-failed'">Action Failed</div>
                    <div v-if="item.status === 'success'" class="c-log-status c-log-success">Success</div>
                    <p class="md-subtitle-2 grey--text text--darken-1 font-weight-medium mb-0 mx-2" style="margin-top: 2px;">
                        <span class="mr-2">&bull;</span> {{ item.created_by.name }}
                    </p>
                    <p class="md-subtitle-2 grey--text text--darken-1 font-weight-medium mb-0" style="margin-top: 2px;">
                        <span class="mr-2">&bull;</span> <g-moment tag="span" :value="item.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                    </p>
                </div>
            </a-sheet>
            <template v-if="localLogInfoType">
                <a-divider class="grey lighten-3"></a-divider>
                <a-sheet class="py-5 px-6">
                    <div class="u-flex">
                        <div v-if="localLogCountCheck('condition')" class="mr-4 u-cursor-pointer px-4 py-2 u-rounded-corners u-border" :class="[localLogInfoType === 'conditions' ? 'c-selected-status-box' : '']" @click="localUpdateLogInfoType('conditions')">
                            <p class="md-subtitle-2 font-weight-bold mb-0 text-uppercase" :class="[localLogInfoType === 'conditions' ? 'grey--text text--darken-3' : 'grey--text text--darken-2']">
                                <a-icon class="u-icon-nudge" :color="!localConditionErrorStatus ? 'yellow darken-3':'green'" size="20">{{ !localConditionErrorStatus ? 'info' : 'check_circle' }}</a-icon>
                                Conditions
                            </p>
                        </div>
                        <div v-if="localLogCountCheck('validation')" class="px-4 py-2 u-cursor-pointer u-rounded-corners u-border" :class="[localLogInfoType === 'validations' ? 'c-selected-status-box' : '']">
                            <p class="md-subtitle-2 grey--text text--darken-2 font-weight-bold mb-0 text-uppercase"  :class="[localLogInfoType === 'validations' ? 'grey--text text--darken-3' : 'grey--text text--darken-2']" @click="localUpdateLogInfoType('validations')">
                                <a-icon class="u-icon-nudge" :color="!localValidationErrorStatus ? 'red': 'green'" size="20">{{ !localValidationErrorStatus ? 'info' : 'check_circle' }}</a-icon>
                                Validations
                            </p>
                        </div>
                        <div v-if="localLogCountCheck('action')" class="px-4 py-2 u-cursor-pointer u-rounded-corners u-border" :class="[localLogInfoType === 'actions' ? 'c-selected-status-box' : '']">
                            <p class="md-subtitle-2 grey--text text--darken-2 font-weight-bold mb-0 text-uppercase"  :class="[localLogInfoType === 'actions' ? 'grey--text text--darken-3' : 'grey--text text--darken-2']" @click="localUpdateLogInfoType('actions')">
                                <a-icon class="u-icon-nudge" :color="localHasActionErrorCount === 0 ? 'blue-grey darken-1' :'green'" size="20">{{ localHasActionErrorCount === 0 ? 'next_plan' : 'check_circle'}}</a-icon>
                                Actions
                            </p>
                        </div>
                    </div>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <a-sheet class="py-5 px-6">
                    <div class="px-0 pb-4">
                        <template v-if="localLogInfoType === 'conditions'">
                            <div v-for="(condition_group, group_index) in localLogConditionList" :key="item.group_id">
                                <a-sheet v-if="group_index !== 0" height="1" class="u-wfull u-relative my-7 c-condition-or-shreet">
                                    <span class="u-absolute md-body-2 font-weight-medium body--text text--lighten-2 d-inline-block c-condition-or-card">OR</span>
                                </a-sheet>
                                <div class="c-condition-card-view">
                                    <div class="u-flex u-cursor-pointer u-relative px-6 py-5" @click="localAccordionLogToggle(condition_group.group_id)">
                                        <template v-if="!localHasConditionCheckPassed(condition_group.group_id)">
                                            <div class="u-relative c-condition-error-view">
                                                <div class="c-condition-error-view-child"></div>
                                            </div>
                                        </template>
                                        <template v-if="localHasConditionCheckPassed(condition_group.group_id)">
                                            <div class="u-relative c-condition-success-view">
                                                <div class="c-condition-success-view-child"></div>
                                            </div>
                                        </template>
                                        <p class="ml-2 grey--text text--darken-3 mb-0 font-weight-bold text-uppercase" style="font-size: 14px;">Condition set {{ group_index +1 }}</p>
                                        <a-spacer></a-spacer>
                                        <div class="u-absolute c-logs-status-count">
                                            <div :class="[localHasConditionCheckPassed(condition_group.group_id) ? 'c-condition-success-count-view' : 'c-condition-error-count-view']" class="py-1 px-2 ml-6">
                                                <p :class="[localHasConditionCheckPassed(condition_group.group_id) ? 'green--text text--darken-1' : 'amber--text text--darken-3']" class="text-center mb-0 text-uppercase" style="font-size: 12px;"> {{ localHasConditionCheckError(condition_group.group_id, 1) }} / {{ localGetTotalCountByGroup(condition_group.group_id, 'condition') }} Conditions Met</p>
                                            </div>
                                        </div>
                                        <a-btn small icon class="grey darken-1" width="24" height="24">
                                            <a-icon color="white" v-if="localAccordionLogIsOpen(condition_group.group_id)" size="18">expand_less</a-icon>
                                            <a-icon v-else color="white" size="18">expand_more</a-icon>
                                        </a-btn>
                                    </div>
                                    <div class="px-3 mb-5" v-if="localAccordionLogIsOpen(condition_group.group_id)">
                                        <div class="grey lighten-5 u-rounded-corners-2xl py-4">
                                            <ul class="c-condition-logs px-3 mt-2">
                                                <li v-for="(item, index) in condition_group.conditions" :key="index">
                                                    <div class="c-condition-log-icon">
                                                        <a-icon :color="item.is_valid ? 'green': 'amber darken-3'" size="20">{{ item.is_valid ? 'check_circle' : 'dangerous' }}</a-icon>
                                                    </div>
                                                    <div class="c-condition-log-text">
                                                        <p class="md-subtitle-2 grey--text text--darken-3 mb-0 font-weight-medium mr-4">
                                                            {{item.field_name}} <span class="font-italic grey--text text--darken-4 mr-1 u-font-weight-semibold"> {{ item.operator }} </span>
                                                            <span v-if="item.value">
                                                                <g-moment v-if="item.field_type === 'date'" tag="span" :value="item.value" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY" convert-local></g-moment>
                                                                <span v-else :title="item.value">{{ item.value | truncateText(60) }}</span>
                                                            </span>
                                                            <span v-if="item.operator === 'Is within' && item.value_alt">
                                                                <g-moment v-if="item.field_type === 'date'" tag="span" :value="item.value_alt" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY" convert-local></g-moment>
                                                                <span v-else :title="item.value_alt">{{ item.value_alt | truncateText(60) }}</span>
                                                            </span>
                                                        </p>
                                                        <span class="md-subtitle-2 mr-1 grey--text text--darken-1">Existing value:</span>
                                                        <template v-if="item.field_type === 'date'">
                                                            <g-moment tag="p" class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium mr-4" :value="item.data[0]" v-if="item.data[0]" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY" convert-local></g-moment>
                                                            <p class="md-subtitle-2 grey--text mb-0 d-inline-block font-weight-medium" v-else>Field is empty</p>
                                                        </template>
                                                        <template v-else-if="item.field_type === 'multi-select'">
                                                            <a-tooltip bottom :disabled="!item.data.length" max-width="660">
                                                                <template v-slot:activator="{ on }">
                                                                    <p v-on="on" class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium" :class="[item.data.length ? 'u-underline' : '']">{{ item.data.length }} {{ item.field_name }}</p>
                                                                </template>
                                                                <div class="pa-1 md-body-2" v-if="item.data.length">
                                                                    <span v-for="(data, index) in item.data" :key="index" class="mb-1 pr-1">{{ data }}
                                                                        <span v-if="item.data[item.data.length - 1] !== data">,</span>
                                                                    </span>
                                                                </div>
                                                            </a-tooltip>
                                                        </template>
                                                        <p v-else-if="item.field_type === 'select'" class="d-inline-block md-subtitle-2 grey--text mb-0 font-weight-medium"> {{ item.data[0] ? item.data[0] : 'Field is empty' }}</p>
                                                        <template v-else>
                                                            <p class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium mr-4" v-if="item.data[0]">{{ item.data[0] }}</p>
                                                            <p class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium" v-else>Field is empty</p>
                                                        </template>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="localLogInfoType === 'validations'">
                            <div v-for="(validation_group, group_index) in localLogValidationList"  :key="group_index" >
                                <a-sheet v-if="group_index !== 0" height="1" class="u-wfull u-relative my-7 c-validation-or-shreet">
                                    <span class="u-absolute md-body-2 font-weight-medium body--text text--lighten-2 d-inline-block c-validation-or-card">OR</span>
                                </a-sheet>
                                <div class="c-rule-card-view" @click="localAccordionLogToggle(validation_group.group_id)">
                                    <div class="u-flex u-cursor-pointer px-6 py-5 u-relative">
                                        <template v-if="!localHasValidationCheckPassed(validation_group.group_id)">
                                            <div class="u-relative c-error-view">
                                                <div class="c-error-view-child"></div>
                                            </div>
                                        </template>
                                        <template v-if="localHasValidationCheckPassed(validation_group.group_id)">
                                            <div class="u-relative c-condition-success-view">
                                                <div class="c-condition-success-view-child"></div>
                                            </div>
                                        </template>
                                        <p class="ml-2 grey--text text--darken-3 mb-0 font-weight-bold text-uppercase" style="font-size: 16px;">Rule set {{ group_index +1 }}</p>
                                        <a-spacer></a-spacer>
                                        <div class="u-flex u-absolute c-logs-status-count">
                                            <div class="c-condition-success-count-view py-1 px-2" v-if="localGetValidationFailedCount(validation_group.group_id, 1)">
                                                <p class="text-center green--text text--darken-1 mb-0 text-uppercase" style="font-size: 12px;"> {{ localGetValidationFailedCount(validation_group.group_id, 1) }} Passed</p>
                                            </div>
                                            <div class="c-error-count-view py-1 px-2 ml-4"  v-if="localGetValidationFailedCount(validation_group.group_id, 0)">
                                                <p class="text-center red--text text--darken-1 mb-0 text-uppercase" style="font-size: 12px;"> {{ localGetValidationFailedCount(validation_group.group_id, 0) }} Failed</p>
                                            </div>
                                        </div>
                                        <a-btn small icon class="grey darken-1" width="24" height="24">
                                            <a-icon color="white" v-if="localAccordionLogIsOpen(validation_group.group_id)" size="18">expand_less</a-icon>
                                            <a-icon v-else color="white" size="18">expand_more</a-icon>
                                        </a-btn>
                                    </div>
                                    <div class="px-3 mb-5" v-if="localAccordionLogIsOpen(validation_group.group_id)">
                                        <div class="grey lighten-5 u-rounded-corners-2xl py-4">
                                            <ul class="c-validation-logs px-3 mt-2">
                                                <li v-for="(item, index) in validation_group.validations" :key="index">
                                                    <div class="c-validation-log-icon">
                                                        <a-icon :color="item.is_valid ? 'green': 'red darken-1'" size="20">{{ item.is_valid ? 'check_circle' : 'dangerous' }}</a-icon>
                                                    </div>
                                                    <div class="c-validation-log-text">
                                                        <p class="md-subtitle-2 grey--text text--darken-3 mb-0 font-weight-medium mr-4">
                                                            {{ item.message }}
                                                        </p>
                                                        <span class="md-subtitle-2 mr-1 grey--text text--darken-1">Current value:</span>
                                                        <template v-if="item.field_type === 'date'">
                                                            <g-moment tag="p" class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium" :value="item.data[0]" v-if="item.data[0]" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY" convert-local></g-moment>
                                                            <p class="md-subtitle-2 grey--text mb-0 d-inline-block font-weight-medium" v-else>Field is empty</p>
                                                        </template>
                                                        <template v-else-if="item.field_type === 'multi-select'">
                                                            <a-tooltip bottom :disabled="!item.data.length" max-width="660">
                                                                <template v-slot:activator="{ on }">
                                                                    <p v-on="on" class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium" :class="[item.data.length ? 'u-underline' : '']">{{ item.data.length }} {{ item.field_name }}</p>
                                                                </template>
                                                                <div class="pa-1 md-body-2" v-if="item.data.length">
                                                                    <span v-for="(data, index) in item.data" :key="index" class="mb-1 pr-1">{{ data }}
                                                                        <span v-if="item.data[item.data.length - 1] !== data">,</span>
                                                                    </span>
                                                                </div>
                                                            </a-tooltip>
                                                        </template>
                                                        <p v-else-if="item.field_type === 'select'" class="d-inline-block md-subtitle-2 grey--text mb-0 font-weight-medium mr-4"> {{ item.data[0] ? item.data[0] : 'Field is empty' }}</p>
                                                        <template v-else>
                                                            <p class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium mr-4" v-if="item.data[0]">{{ item.data[0] }}</p>
                                                            <p class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium" v-else>Field is empty</p>
                                                        </template>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="localLogInfoType === 'actions'">
                            <div class="c-rule-card-view">
                                <div class="u-flex u-relative px-6 py-5">
                                    <div class="u-relative c-condition-success-view">
                                        <div class="c-condition-success-view-child"></div>
                                    </div>
                                    <p class="ml-2 grey--text text--darken-3 mb-0 font-weight-bold text-uppercase" style="font-size: 16px;">Actions</p>
                                    <a-spacer></a-spacer>
                                    <div class="u-flex u-absolute c-logs-action-status-count">
                                        <div class="c-condition-success-count-view py-1 px-2" v-if="localHasActionCheckError(1)">
                                            <p class="text-center green--text text--darken-1 mb-0 text-uppercase" style="font-size: 12px;"> {{ localHasActionCheckError(1) }} Updated</p>
                                        </div>
                                        <div class="c-skipped-count-view py-1 px-2 ml-4" v-if="localHasActionCheckError(0)" >
                                            <p class="text-center mb-0 text-uppercase" style="font-size: 12px; color: #546E7A;"> {{ localHasActionCheckError(0) }} Skipped</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="px-3 mb-5">
                                    <div class="grey lighten-5 u-rounded-corners-2xl py-4">
                                        <ul class="c-action-logs px-3 mt-2">
                                            <li v-for="(item, index) in localLogActionList" :key="index">
                                                <div class="c-action-log-icon">
                                                    <a-icon :color="item.is_valid ? 'green':'blue-grey darken-1'" size="20">{{ item.is_valid ? 'check_circle' : 'next_plan' }}</a-icon>
                                                </div>
                                                <div class="c-action-log-text">
                                                    <p class="md-subtitle-2 grey--text text--darken-3 mb-0 font-weight-medium">
                                                        <span>{{ item.message === 'collaborators' ? 'Update Collaborator' : 'Update Field' }}</span>
                                                        <a-icon size="16" color="grey darken-3" class="mx-1 u-icon-nudge-xs">arrow_forward</a-icon>
                                                        <span>{{ item.field_name }}</span>
                                                    </p>
                                                    <template v-if="item.field_type === 'date' && item.is_valid === 0">
                                                        <p class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium mr-4">{{ item.message  }}</p>
                                                    </template>
                                                    <template v-else>
                                                        <p class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium mr-4" v-if="item.message === 'update_field'">
                                                            The {{ item.field_name }} has been {{ item.action_type === 'remove' ? 'removed' : 'modified to' }}
                                                            <template v-if="item.action_type === 'modify'">
                                                                <span v-if="item.field_type === 'date'">{{ item.value }}</span>
                                                                <span v-else>{{ item.value ? item.value : '-' }}</span>
                                                            </template>
                                                        </p>
                                                        <p class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium mr-4" v-else>
                                                            <template v-if="item.action_type === 'remove' && item.is_action === 1">
                                                                <span>All the {{ item.message === 'collaborators' ? item.message : item.field_name }} have been removed</span>
                                                                <span class="ml-1" v-if="item.message === 'collaborators'">from {{ item.field_name }}</span>
                                                            </template>
                                                            <template v-else>
                                                                <template v-if="['modify', 'add'].includes(item.action_type)">
                                                                    <span v-if="item.message === 'collaborators'">The selected</span>
                                                                    <span v-else>The {{ item.field_name }} has been modified and</span>
                                                                </template>
                                                                <span v-else>The selected {{ item.field_name }}</span>
                                                                <span v-if="localGetLogSkippedCount(false, item.data)">
                                                                    <a-tooltip bottom max-width="660">
                                                                        <template v-slot:activator="{ on }">
                                                                            <span v-on="on" class="u-underline ml-1" style="letter-spacing: 0.2px">{{ localGetLogMessage(false, item) }}</span>
                                                                        </template>
                                                                        <div class="pa-1 md-body-2" v-if="item.data && item.data.length">
                                                                            <span v-for="(data, index) in localGetLogSkippedData(false, item.data)" :key="index" class="mb-1 pr-1">
                                                                                {{ data.value }}
                                                                            </span>
                                                                        </div>
                                                                    </a-tooltip>
                                                                    <span class="ml-1" v-if="['modify', 'add'].includes(item.action_type)">have been added</span>
                                                                    <span v-else class="ml-1">have been removed</span>
                                                                    <span v-if="item.message === 'collaborators'" class="ml-1"> {{ item.action_type === 'add' ? 'to' : 'from'}} {{ item.field_name }}</span>
                                                                    <span v-if="item.message === 'collaborators' && localGetLogSkippedCount(true, item.data)" class="ml-1">and</span>
                                                                </span>
                                                                <span v-if="!item.action_type === 'remove' && localGetLogSkippedCount(false, item.data) && localGetLogSkippedCount(true, item.data)">and</span>
                                                                <span v-if="localGetLogSkippedCount(true, item.data)">
                                                                    <span class="mx-1">skipped</span>
                                                                    <a-tooltip bottom max-width="660">
                                                                        <template v-slot:activator="{ on }">
                                                                            <span v-on="on" class="u-underline ml-1" style="letter-spacing: 0.2px">{{ localGetLogMessage(true, item) }}</span>
                                                                        </template>
                                                                        <div class="pa-1 md-body-2" v-if="item.data && item.data.length">
                                                                            <span v-for="(data, index) in localGetLogSkippedData(true, item.data)" :key="index" class="mb-1 pr-1">
                                                                                {{ data.value }}
                                                                            </span>
                                                                        </div>
                                                                    </a-tooltip>
                                                                    as they already exists
                                                                </span>
                                                            </template>
                                                        </p>
                                                    </template>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </a-sheet>
            </template>
        </a-card-text>
        <a-divider class="grey lighten-3"></a-divider>
        <div class="py-5 px-6">
            <div class="u-flex">
                <a-btn depressed text class="grey lighten-3 grey--text text--darken-1 u-rounded-corners" @click="$emit('close')">
                    Close
                </a-btn>
            </div>
        </div>
    </a-card>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: null,
            required: true
        },
        logType: {
            type: String,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: true
        }
    },

    watch: {
        item (newValue) {
            if(newValue) this.localGetLogInfo()
        }
    },

    data() {
        return {
            localLogInfoType: 'conditions',
            localLogConditionList: [],
            localLogValidationList: [],
            localLogActionList: [],
            localConditionErrorGroupId: null,
            localValidationErrorGroupId: null,
            localConditionErrorStatus: false,
            localValidationErrorStatus: false,
            triggers : [
                { type: "project-created", icon: 'add_box'},
                { type: "project-updated", icon: 'border_color'},
                { type: "project-status-updated", icon: 'donut_large'},
                { type: "project-upsert", icon: 'add_box'},
                { type: "project-stage-changed", icon: 'label_important'},
                { type: "project-completed", icon: 'task_alt'},
                { type: "project-archived", icon: 'archive'},
            ],
            accordionLogs: [],
        }
    },

    computed: {
        localLogType () {
            return this.$route.params.type
        },
        localHasActionErrorCount () {
            if (this.item.automation_log_entries && this.item.automation_log_entries.length) {
                const data = this.item.automation_log_entries.filter(list => list.type === 'action' && list.is_valid === 1)
                return data.length
            }
        },
    },

    methods: {
        localAccordionLogToggle(group_id){
            const index = this.accordionLogs.findIndex(item => item === group_id)

            if (index !== -1) {
                this.accordionLogs.splice(index, 1)
                return
            }

            this.accordionLogs.push(group_id)
        },

        localAccordionLogIsOpen(group_id){
            if (!this.accordionLogs.length) return false

            const index = this.accordionLogs.findIndex(item => item === group_id)
            return index >= 0 ? true : false
        },

        async localGetLogInfo () {
            this.accordionLogs = []
            await this.locaLogInfoClear()
            if (this.item) {
                if (this.item.automation_log_entries && this.item.automation_log_entries.length) {
                    await this.localGetLogConditions()
                    this.localCheckConditionStatus()
                    await this.localGetLogValidation()
                    this.localCheckValidationStatus()
                    await this.localGetLogAction()
                }

                if (!this.item.automation_workflow) return

                if (this.item.status === 'condition-failed') this.localLogInfoType = 'conditions'

                if (this.item.status === 'validation-failed') this.localLogInfoType = 'validations'

                if (this.item.automation_workflow.type === 'action' && this.localLogActionList.length) {
                    this.localLogInfoType = 'actions'
                    return
                }

                if (this.localLogConditionList.length === 0 && this.item.status === 'success') this.localLogInfoType = 'validations'

                if (this.item.automation_log_entries.length === 0) this.localLogInfoType = null
            }
        },

        localCheckConditionStatus () {
            this.localConditionErrorStatus = false
            const data = this.item.automation_log_entries.filter(list => list.type === 'condition')
            data.forEach(item => {
                if (this.localHasConditionCheckPassed(item.group)) {
                    this.localConditionErrorStatus = true
                }
            });
        },

        localLogCountCheck (type) {
            if (this.item.automation_log_entries && this.item.automation_log_entries.length) {
                const data = this.item.automation_log_entries.filter(list => list.type === type)
                return data && data.length ? true : false
            }
        },

        localCheckValidationStatus () {
            if (this.item.automation_log_entries && this.item.automation_log_entries.length) {
                this.localValidationErrorStatus = false
                const data = this.item.automation_log_entries.filter(list => list.type === 'validation')
                data.forEach(item => {
                    if (this.localHasValidationCheckPassed(item.group)) {
                        this.localValidationErrorStatus = true
                    }
                });
            }
        },

        localHasValidationCheckPassed (group_id) {
            if (this.item.automation_log_entries && this.item.automation_log_entries.length) {
                const data = this.item.automation_log_entries.filter(list => list.group === group_id && list.type === 'validation' && list.is_valid === 0)
                return !data.length ? true : false
            }
        },

        localHasConditionCheckError (group_id, status) {
            if (this.item.automation_log_entries && this.item.automation_log_entries.length) {
                const data = this.item.automation_log_entries.filter(list => list.group === group_id && list.type === 'condition' && list.is_valid === status)
                return data ? data.length : 0
            }
        },

        localHasConditionCheckPassed (group_id) {
            if (this.item.automation_log_entries && this.item.automation_log_entries.length) {
                const data = this.item.automation_log_entries.filter(list => list.group === group_id && list.type === 'condition' && list.is_valid === 0)
                return !data.length ? true : false
            }
        },

        localGetTotalCountByGroup (group_id, type) {
            if (this.item.automation_log_entries && this.item.automation_log_entries.length) {
                const data = this.item.automation_log_entries.filter(list => list.group === group_id && list.type === type)
                return data && data.length ? data.length : 0
            }
        },

        localHasActionCheckError (status) {
            if (this.item.automation_log_entries && this.item.automation_log_entries.length) {
                const data = this.item.automation_log_entries.filter(list => list.type === 'action' && list.is_valid === status)
                return data ? data.length : 0
            }
        },

        localGetTriggerLabel (slug) {
            if (slug === 'project-archived') return 'Project Archive'
            if (slug === 'project-completed') return 'Stage Change'
            if (slug === 'project-stage-changed') return 'Stage Change'
        },

        locaLogInfoClear () {
            this.localLogConditionList = []
            this.localLogValidationList = []
        },

        localGetTriggerIcon (type) {
            const trigger = this.triggers.find(item => item.type && item.type === type)
            return trigger ? trigger.icon : null
        },

        localGetLogConditions () {
            const list =  this.item.automation_log_entries.filter(list => list.type === 'condition')
            if (list.length) this.accordionLogs.push(list[0].group)
            // conditions group by
            this.localLogConditionList = list.reduce((prev, current) => {
                const isAvail = prev.find(item => item.group_id === current.group)
                if (isAvail) {
                    current['group_id'] = current.group
                    isAvail.conditions.push(_.cloneDeep(current))
                } else {
                    current['group_id'] = current.group
                    prev.push({
                        group_id: current.group,
                        conditions: [_.cloneDeep(current)]
                    })
                }
                return prev
            }, [])
            this.localConditionErrorGroupId = this.localLogConditionList.length ? this.localLogConditionList[0].group_id : null
        },

        localExpandConditionGroup (group_id) {
            this.localConditionErrorGroupId = group_id
        },

        localExpandValidationGroup (group_id) {
            this.localValidationErrorGroupId = group_id
        },

        localGetValidationFailedCount (group_id, status) {
            if (this.item.automation_log_entries && this.item.automation_log_entries.length) {
                const data = this.item.automation_log_entries.filter(list => list.group === group_id && list.type === 'validation' && list.is_valid === status)
                return data ? data.length : 0
            }
        },

        localGetLogValidation () {
            const list =  this.item.automation_log_entries.filter(list => list.type === 'validation')
            if (list.length) this.accordionLogs.push(list[0].group)
            this.localLogValidationList = list.reduce((prev, current) => {
                const isAvail = prev.find(item => item.group_id === current.group)
                if (isAvail) {
                    current['group_id'] = current.group
                    isAvail.validations.push(_.cloneDeep(current))
                }else {
                    current['group_id'] = current.group
                    prev.push({
                        group_id: current.group,
                        validations: [_.cloneDeep(current)]
                    })
                }
                return prev
            }, [])

            this.localValidationErrorGroupId = this.localLogValidationList.length ? this.localLogValidationList[0].group_id : null
        },

        localGetLogAction () {
            this.localLogActionList = this.item.automation_log_entries.filter(list => list.type === 'action')
        },

        localLogGoTo () {
            if (this.item && this.item.resource_id) {
                let route = this.$router.resolve({name: 'projects-overview', params: {id: this.item.resource_id} });
                window.open(route.href);
            }
        },

        localUpdateLogInfoType (type) {
            this.localLogInfoType = type
        },

        localGetLogSkippedCount (skipped_status = false, data = null) {
            if (!data) return 0

            return data.filter(list => list.skipped === skipped_status).length
        },

        localGetLogSkippedData (skipped_status = false, data = null) {
            if (!data) return []

            return data.filter(list => list.skipped === skipped_status)
        },

        localGetLogMessage (skipped_status, item) {
            if (item === null) return

            const dataCount = this.localGetLogSkippedCount(skipped_status, item.data);

            switch (item.message) {
                case 'territories':
                    if (dataCount === 0) return 'no territories'
                    if (dataCount === 1) return dataCount+' '+'territory'
                    return dataCount+' '+item.message
                case 'collaborators':
                    if (dataCount === 0) return 'no collaborators'
                    if (dataCount === 1) return dataCount+' '+'collaborator'
                    return dataCount+' '+item.message
                case 'tags':
                    if (dataCount === 0) return 'no tags'
                    if (dataCount === 1) return dataCount+' '+'tag'
                    return dataCount+' '+item.message
                case 'competitors':
                    if (dataCount === 0) return 'no competitors'
                    if (dataCount === 1) return dataCount+' '+'competitor'
                    return dataCount+' '+item.message
                default: return item.message
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.c-log-summary {
    &__icon {
        position: relative;
        width: 28px !important;
        &::before, &::after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 2px;
            background-image: linear-gradient(90deg, #EEEEEE, #EEEEEE);
            height: 100%;
            top: -100%;
            margin: 0px 0px;
        }

        &::after {
            top: auto;
            bottom: -100%;
        }
    }

    &:first-child {
        .c-log-summary__info {
            &::before {
                content: none !important;
            }
        }
    }

    &:last-child {
        .c-log-summary__icon {
            &::after {
                content: none;
            }
        }
    }
}
.c-condition-disabled {
    width: 18px;
    height: 18px;
    border-radius: 50%;
}
.c-log-status {
    border-radius: 4px !important;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.26px;
    text-transform: uppercase;
    padding: 6px;
}
.c-log-success {
    background: #C8E6C9 !important;
    color: #43A047;
}
.c-log-in-progress, .c-log-waring {
    background: #FFECB3 !important;
    color: #FF8F00;
}
.c-log-in-progress, .c-log-error {
    background: #FFEBEE !important;
    color: #E53935;
}
.c-action-logs {
  margin: 0px;
  padding: 0;
  list-style: none;
  position: relative;
}
.c-action-logs li {
  list-style: none;
  margin: auto;
  border-left: 2px solid #E0E0E0;
  position: relative;
  left: 9px;
  min-height: 38px;
  padding-bottom: 16px;
}
.c-action-logs li:last-child {
    border: 0 !important;
    top: 0px;
    left: 11px;
    padding: 0px !important;
}

.c-action-logs > li .c-action-log-text {
    top: -4px;
    left: 17px;
    position: relative;
}
.c-action-logs > li .c-action-log-icon {
    top: -5px;
    position: absolute;
    left: -11px;
    margin: 0px;
}
.c-validation-logs {
  margin: 0px;
  padding: 0;
  list-style: none;
  position: relative;
}
.c-validation-logs li:last-child {
    border: 0 !important;
    top: 0px;
    left: 11px;
    padding: 0px !important;
}
.c-validation-logs > li .c-validation-log-text {
    position: absolute;
    top: -4px;
    left: 17px;
}
.c-validation-logs > li .c-validation-log-icon {
    top: -6px;
    position: absolute;
    left: -11px;
    margin: 0px;
}
.c-rule-card-view {
    border-radius: 12px !important;
    border: 1px solid #EEE !important;
}
.c-error-view {
    background-color: #FFEBEE !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    margin-top: 1px;
}
.c-error-view-child {
    position: absolute;
    background: #E53935;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 4px;
    left: 4px;
}
.c-error-count-view {
    border-radius: 8px;
    border: 1px solid #FFCDD2;
    background: #FFEBEE;
    position: relative;
    font-weight: 600;
}
.c-skipped-count-view {
    border-radius: 8px;
    border: 1px solid #CFD8DC;
    background: #ECEFF1;
    position: relative;
    font-weight: 600;
}
.c-validation-or-card {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 4px;
    background: #FFF;
    border: 1px solid #BDBDBD;
    font-size: 12px;
    font-weight: 600;
    color: #616161;
    width: 33px;
    height: 24px;
    padding: 0px 7px;
}
.c-validation-or-shreet {
    border-radius: 1px;
    background: radial-gradient(50% 50.00% at 50% 50.00%, #616161 0%, rgba(97, 97, 97, 0.08) 100%);
}
.c-validation-logs {
  margin: 0px;
  padding: 0;
  list-style: none;
  position: relative;
}
.c-validation-logs li {
  list-style: none;
  margin: auto;
  border-left: 2px solid #E0E0E0;
  position: relative;
  left: 9px;
  min-height: 38px;
  padding-bottom: 16px;
}
.c-validation-logs > li .c-validation-log-text {
    position: relative;
    top: -4px;
    left: 17px;
}
.c-validation-logs > li .c-validation-log-icon {
    top: -6px;
    position: absolute;
    left: -11px;
    margin: 0px;
}
.c-rule-card-view {
    border-radius: 12px !important;
    border: 1px solid #EEE !important;
}

.c-error-view-child {
    position: absolute;
    background: #E53935;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 4px;
    left: 4px;
}
.c-error-count-view {
    border-radius: 8px;
    border: 1px solid #FFCDD2;
    background: #FFEBEE;
    position: relative;
    font-weight: 600;
}
.c-validation-or-shreet {
    border-radius: 1px;
    background: radial-gradient(50% 50.00% at 50% 50.00%, #616161 0%, rgba(97, 97, 97, 0.08) 100%);
}
.c-condition-logs {
  margin: 0px;
  padding: 0;
  list-style: none;
  position: relative;
}
.c-condition-logs li {
  list-style: none;
  margin: auto;
  border-left: 2px solid #E0E0E0;
  position: relative;
  left: 9px;
  min-height: 38px;
  padding-bottom: 16px;
}
.c-condition-logs li:last-child {
    border: 0 !important;
    top: 0px;
    left: 11px;
    padding: 0px !important;
}
.c-condition-logs > li .c-condition-log-text {
    position: relative;
    top: -4px;
    left: 17px;
}
.c-condition-logs > li .c-condition-log-icon {
    top: -6px;
    position: absolute;
    left: -11px;
    margin: 0px;
}
.c-condition-card-view {
    border-radius: 12px !important;
    border: 1px solid #EEE !important;
}
.c-condition-error-view {
    background-color: #FFEBEE !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    margin-top: 1px;
}
.c-condition-error-view-child {
    position: absolute;
    background: #FF8F00;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 4px;
    left: 4px;
}
.c-condition-success-view-child {
    position: absolute;
    background: #43A047;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 4px;
    left: 4px;
}
.c-condition-success-view {
    background-color: #E8F5E9 !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    margin-top: 1px;
}
.c-condition-error-count-view {
    border-radius: 8px;
    border: 1px solid #FFECB3;
    background: #FFF8E1;
    position: relative;
    font-weight: 600;
}
.c-condition-success-count-view {
    border-radius: 8px;
    border: 1px solid #C8E6C9;
    background: #E8F5E9;
    position: relative;
    font-weight: 600;
}
.c-logs-status-count {
    right: 64px;
    top: 17px;
}
.c-logs-action-status-count {
    right: 30px;
    top: 15px;
}
.c-condition-or-card {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 4px;
    background: #FFF;
    border: 1px solid #BDBDBD;
    font-size: 12px;
    font-weight: 600;
    color: #616161;
    width: 33px;
    height: 24px;
    padding: 0px 7px;
}
.c-condition-or-shreet {
    border-radius: 1px;
    background: radial-gradient(50% 50.00% at 50% 50.00%, #616161 0%, rgba(97, 97, 97, 0.08) 100%);
}
.c-selected-status-box {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08) !important;
    background: #F5F5F5 !important;
}
</style>

<template>
    <div class="grey darken-4 u-rounded-corners-lg mt-2">
        <a-container container--fluid grid-list-xl class="py-2 px-5">
            <a-layout align-start>
                <a-flex shrink class="px-0">
                    <a-btn color="white" text :to="{name: page }" exact>
                        <a-icon class="mr-1">arrow_back</a-icon> Back to {{ name }}
                    </a-btn>
                </a-flex>
            </a-layout>
        </a-container>
    </div>
</template>

<script>
export default {
    props: {
        page: {
            type: String,
            required: true,
            default: 'settings-validations'
        },
        name: {
            type: String,
            required: true,
            default: 'validation'
        },
    },
}
</script>
<template>
    <a-main>
        <PValidationWorkflowBreadcrumb
            :page="'settings-validations'"
            :name="'validation'"
        />
        <a-container grid-list-xl container--fluid pa-0>
            <PValidationWorkflowViewHeader
                :item="automation_workflow_item"
                :isLoading="localPageloading"
                :isChanges="localHasWorkflowUpdateChanges"
                :canUpdate="localAutomationWorkflowPermission('update')"
                :isWorkflowUpdate="mixinIsLoading('update-workflow-changes')"
                :validationCount="localValidationCount"
                @update-workflow="localUpdateWorkflowChanges"
                @cancel-changes="localWorkflowHasChanges"
                @toggle-state="localAutomationWorkflowToggleState"
            />
            <PValidationWorkflowStatusBar
                :item="automation_workflow_item"
                :pageLoading="localPageloading"
                :canUpdate="localAutomationWorkflowPermission('update')"
                :isLoading="mixinIsLoading('workflow-validations')"
                :list="localValidationList"
                :type="'validation'"
                @update-config="localValidationConfig"
            />
            <div class="u-flex mt-8">
                <PValidationMenu
                    :isLoading="localPageloading"
                    :page="localPage"
                    :activeMenu="localActiveMenu"
                    @action="localSelectMenu"
                    @page="localPageUpdate"
                />
                <div v-if="localPage === 'configurations'" class="mx-auto c-configuration-card">
                    <div class="white u-wfull" v-if="localActiveMenu === 'workflow-summary'">
                        <PValidationWorkflowSummary
                            :canUpdate="localAutomationWorkflowPermission('update')"
                            type="validation"
                            :item="localCloneAutomationWorkflowItem"
                            :response="automation_workflow_response"
                            :isLoading="localPageloading"
                            @change-value="localGetFieldUpdate"
                            :titleError="localValidationTitleError"
                        />
                    </div>
                    <div v-if="localCheckSelectMenu()">
                        <div class="workflow white u-wfull u-relative" id="workflow-workspace" ref="workflow-workspace">
                            <PValidationWorkspace
                                :item="localCloneAutomationWorkflowItem"
                                :isLoading="localPageloading"
                                :workspaceList="workspace_board_list"
                                :canUpdate="localAutomationWorkflowPermission('update')"
                                :workspaceUpdate="localSelectWorkspace"
                                :isHiddenWorkspace="localCheckHiddenWorkspace"
                                :isDefaultWorkspace="localCheckDefaultWorkspace"
                            />
                        </div>
                        <div style="height: 80px;">
                            <div class="c-ellipse-bottom">
                                <div class="c-ellipse-parent"></div>
                            </div>
                        </div>
                        <div class="c-ellipse-top">
                            <div class="c-ellipse-parent"></div>
                        </div>
                        <div class="workflow white u-wfull u-relative" id="workflow-trigger" ref="workflow-trigger">
                            <PValidationTrigger
                                :triggersList="automation_trigger_list"
                                :selectedTiggerId="localSelectedTiggerId"
                                :item="localCloneAutomationWorkflowItem"
                                :editMode="localIsTriggerEditMode"
                                :isHiddenWorkspace="localCheckHiddenWorkspace"
                                :triggerError="!localCloneAutomationWorkflowItem.automation_trigger_id && localValidateTriggerError"
                                :canUpdate="localAutomationWorkflowPermission('update')"
                                :isLoading="localPageloading"
                                :workflowStageLoading="mixinIsLoading('workflow-stages')"
                                @select-trigger="localSelectTrigger"
                                @stage-update="localTriggerStageUpdate"
                                :triggerIcon="localGetTriggerIcon"
                                :stageFromlist="localTriggerStageFromFilterList"
                                :stageTolist="localTriggerStageToFilterList"
                            />
                            <template v-if="automation_workflow_item.automation_trigger_id && localAutomationWorkflowPermission('update') && !localCheckHiddenWorkspace">
                                <template v-if="localTriggerEditMode">
                                    <a-tooltip bottom v-if="localIsTriggerEditMode">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="c-edit-btn u-cursor-pointer" @click="localTriggerCancel()">
                                                <div class="align-center justify-center u-flex" style="margin: 13px 0px;">
                                                    <a-icon color="grey" size="22">close</a-icon>
                                                </div>
                                            </div>
                                        </template>
                                        <span>Cancel</span>
                                    </a-tooltip>
                                    <a-tooltip bottom v-else>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="c-edit-btn u-cursor-pointer blue-grey lighten-4"  @click="localTriggerEditUpdate()">
                                                <div class="align-center justify-center u-flex" style="margin: 13px 0px;">
                                                    <a-icon color="blue-grey" size="22">drive_file_rename_outline</a-icon>
                                                </div>
                                            </div>
                                        </template>
                                        <span>Confirm</span>
                                    </a-tooltip>
                                </template>
                                <a-tooltip bottom v-else>
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="c-edit-btn u-cursor-pointer" @click="localTriggerEditModeUpdate()">
                                            <div class="align-center justify-center u-flex" style="margin: 13px 0px;">
                                                <a-icon color="grey" size="22">drive_file_rename_outline</a-icon>
                                            </div>
                                        </div>
                                    </template>
                                    <span>Edit trigger</span>
                                </a-tooltip>
                            </template>
                        </div>
                        <template v-if="!localPageloading">
                            <div style="height: 80px;">
                                <div class="c-ellipse-bottom">
                                    <div class="c-ellipse-parent"></div>
                                </div>
                            </div>
                            <div class="c-ellipse-top" v-if="localConditionsList && localConditionsList.length">
                                <div class="c-ellipse-parent"></div>
                            </div>
                            <div class="workflow white u-wfull u-relative" id="workflow-conditions" ref="workflow-conditions" v-if="localConditionsList && localConditionsList.length">
                                <PValidationWorkflowConditions
                                    :conditionList="localConditionsList"
                                    :isError="!localCheckWorkflowConditionsErrors"
                                    :systemTagList="localSystemTagList"
                                    :customTagList="localCustomTagList"
                                    :isLoading="mixinIsLoading('workflow-conditions')"
                                    :canUpdate="localAutomationWorkflowPermission('update')"
                                    :conditionEntityList="automation_condition_entity_list"
                                    @update-source="localUpdateConditionSource"
                                    @remove-condition="localRemoveCondition"
                                    @add-group="localCreateConditionGroup"
                                    @change-value="localGetConditionValueChanges"
                                    @change-operator="localConditionChangeOperator"
                                    :hasCustomFieldTextInfoCheck="localConditionCustomFieldTextInfoCheck"
                                />
                                <a-tooltip bottom v-if="localAutomationWorkflowPermission('update')">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="c-edit-btn u-cursor-pointer" :class="[localConditionsRemoveFlag ? 'blue-grey lighten-4' : '']" @click="!localConditionsRemoveFlag ? localConditionsRemoveFlagUpdate() : localConditionsRemove()">
                                            <div class="align-center justify-center u-flex" style="margin: 13px 0px;">
                                                <a-icon :color="localConditionsRemoveFlag ? 'blue-grey' : 'grey'" size="22">close</a-icon>
                                            </div>
                                        </div>
                                    </template>
                                    <span>{{ localConditionsRemoveFlag ? 'Confirm?' : 'Remove conditions' }}</span>
                                </a-tooltip>
                            </div>
                            <div class="workflow c-add-new-conditions u-relative" id="workflow-conditions" ref="workflow-conditions" v-else>
                                <a-btn @click="localCreateConditionGroup('new')" depressed small rounded class="c-conditions-btn md-subtitle-1 pa-4 u-flex-center white blue--text text--accent-4 font-weight-bold text-uppercase"  :disabled="!localAutomationWorkflowPermission('update')" style="box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);">
                                    <a-icon size="20" color="blue accent-4" class="u-icon-nudge-xs mr-1" v-if="localAutomationWorkflowPermission('update')">add_circle</a-icon>
                                    <span>Add Condition</span>
                                </a-btn>   
                                <a-tooltip right v-if="localAutomationWorkflowPermission('update')">
                                    <template v-slot:activator="{ on }">
                                        <div class="c-add-new-conditions-info" v-on="on">
                                            <a-icon size="22" color="grey darken-1" class="u-icon-nudge-xs mr-1">help</a-icon>
                                        </div>
                                    </template>
                                    <div class="pa-2 md-body-2" style="width: 266px;">Conditions are filters that must be true to execute the action. Conditions are optional.</div>
                                </a-tooltip>
                            </div>
                            <div style="height: 80px;"  v-if="localConditionsList && localConditionsList.length">
                                <div class="c-ellipse-bottom">
                                    <div class="c-ellipse-parent"></div>
                                </div>
                            </div>
                            <div class="c-ellipse-top">
                                <div class="c-ellipse-parent"></div>
                            </div>
                            <div class="workflow white u-wfull" ref="workflow-validations" id="workflow-validations">
                                <PValidationWorkflow
                                    :item="localCloneAutomationWorkflowItem"
                                    :validationMessageType="localValidationMessageType"
                                    :validationList="localValidationList"
                                    :systemTagList="localSystemTagList"
                                    :customTagList="localCustomTagList"
                                    :canUpdate="localAutomationWorkflowPermission('update')"
                                    :isLoading="mixinIsLoading('workflow-validations')"
                                    :isError="!localCheckWorkflowValidationErrors"
                                    :validationEntityList="automation_validation_entity_list"
                                    @add-validation="localCreateValidationGroup"
                                    @update-related-source="localUpdateValidationRelatedSource"
                                    @remove-validation="localRemoveValidation"
                                    @set-default-message="localSetDefaultMessage"
                                    @change-value="localGetValidationValueChanges"
                                    @auto-fill-message="localGetAutoFillRoleValidationMessage"
                                    @update-message-type="localValidationMessageTypeUpdate"
                                    :hasValidationMessageEdit="localHasCheckValidationMessageEdit"
                                    :hasCustomFieldTextInfoCheck="localValidationCustomFieldTextInfoCheck"
                                />
                            </div>
                        </template>
                    </div>
                </div>
                <div v-if="localPage === 'logs'" class="mx-auto c-configuration-card">
                    <PValidationWorkflowLogs
                        :list="automation_log_list"
                        :type="'validation'"
                        :isLogLoading="mixinIsLoading('workflow-logs')"
                        :selectedStatusValues="selectedStatus"
                        :selectedTimeValue="selectedTime"
                        :filterLoadingType="filterLoading"
                        @filter-status="localAutomationLogStatusFilter"
                        @filter-time="localLogAutomationTimefilter"
                    />
                </div>
            </div>
        </a-container>
        <div style="height: 100px;"></div>
        <a-dialog max-width="420" v-model="dialogActiveWorkflow" persistent>
            <a-card>
                <a-sheet class="u-flex py-5 px-6">
                    <h2 class="body-3 font-weight-medium amber--text text--darken-3 u-font-semibold">
                        <a-icon class="mr-1" color="amber darken-3" size="28">flash_on</a-icon>
                        Activate Workflow
                    </h2>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <a-card-text class="pa-0">
                    <a-sheet class="pa-6">
                        <p class="md-subtitle-1 grey--text text--darken-2 mb-0 font-weight-medium">Workflow changes have been saved!</p>
                        <p class="md-subtitle-2 grey--text mb-0 font-weight-medium mt-2">Would you like to enable the workflow?</p>
                    </a-sheet>
                    <a-divider class="grey lighten-3"></a-divider>
                    <a-sheet class="py-5 px-6">
                        <div class="u-flex">
                            <a-btn @click="localUpdateWorkflowState(1)" :loading="mixinIsLoading('update-active')" class="indigo darken-1 white--text u-rounded-corners elevation-0">Yes Enable</a-btn>
                            <a-btn @click="localUpdateWorkflowState(0)" :disabled="mixinIsLoading('update-active')" text class="ml-4 grey lighten-3 grey--text text--darken-1 u-rounded-corners">
                                No Cancel
                            </a-btn>
                        </div>
                    </a-sheet>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog max-width="520" v-model="dialogUpdateWorkflowTrigger" persistent>
            <a-card>
                <a-sheet class="u-flex py-5 px-6">
                    <h2 class="body-3 font-weight-medium indigo--text text--darken-1 u-font-semibold">
                        <a-icon class="mr-1" color="indigo darken-1" size="28">flash_on</a-icon>
                        Trigger Update
                    </h2>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <a-card-text class="pa-0">
                    <a-sheet class="pa-6">
                        <p class="md-subtitle-1 grey--text text--darken-2 mb-0 font-weight-medium">Are you sure you want to update the trigger?</p>
                        <p class="md-subtitle-2 grey--text mb-0 font-weight-medium mt-2">This will remove all the existing validation configured for this workflow (if any), and it cannot be undone.</p>
                    </a-sheet>
                    <a-divider class="grey lighten-3"></a-divider>
                    <a-sheet class="py-5 px-6">
                        <div class="u-flex">
                            <a-btn @click="localUpdateWorkflowTrigger()" class="indigo darken-1 white--text u-rounded-corners elevation-0">Yes Update</a-btn>
                            <a-btn @click="localCloseWorkflowTrigger()" text class="ml-4 grey lighten-3 grey--text text--darken-1 u-rounded-corners">
                                No Cancel
                            </a-btn>
                        </div>
                    </a-sheet>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog max-width="520" v-model="dialogUpdateWorkflowWorkspace" persistent>
            <a-card>
                <a-sheet class="u-flex py-5 px-6">
                    <h2 class="body-3 font-weight-medium indigo--text text--darken-1 u-font-semibold">
                        <a-icon class="mr-1" color="indigo darken-1" size="28">apartment</a-icon>
                        Workspace Update
                    </h2>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <a-card-text class="pa-0">
                    <a-sheet class="pa-6">
                        <p class="md-subtitle-1 grey--text text--darken-2 mb-0 font-weight-medium">Are you sure you want to update the workspace?</p>
                        <p class="md-subtitle-2 grey--text mb-0 font-weight-medium mt-2">This will remove all the existing stages configured for this validation and it cannot be undone.</p>
                    </a-sheet>
                    <a-divider class="grey lighten-3"></a-divider>
                    <a-sheet class="py-5 px-6">
                        <div class="u-flex">
                            <a-btn class="indigo darken-1 white--text u-rounded-corners elevation-0" @click="localUpdateWorkspace()">Yes Update</a-btn>
                            <a-btn text class="ml-4 grey lighten-3 grey--text text--darken-1 u-rounded-corners"  @click="localCancelWorkspace()">
                                No Cancel
                            </a-btn>
                        </div>
                    </a-sheet>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog v-model="dialogCancelPageModal" persistent max-width="400">
            <SCancelChanges
                @success="localCancelWorkflowChanges"
                @close="dialogCancelPageModal = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to cancel?</template>
                <template #successButtonLabel>Yes, cancel</template>
                <template #cancelButtonLabel>No</template>
            </SCancelChanges>
        </a-dialog>
        <a-dialog v-model="dialogLeavePageModal" persistent max-width="400">
            <SCancelChanges
                @success="pageToLeave ? pageToLeave() : {}"
                @close="dialogLeavePageModal = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import loadWorkspace from '@/mixins/mixin-workspace'
import { v4 as uuidv4 } from 'uuid'
import PValidationWorkflowSummary from './Partials/PartialValidationWorkflowSummary'
import PValidationTrigger from './Partials/PartialValidationTrigger'
import PValidationWorkflowConditions from './Partials/PartialValidationWorkflowConditions'
import PValidationWorkflow from './Partials/PartialValidationWorkflow'
import PValidationWorkflowViewHeader from './Partials/PartialValidationWorkflowViewHeader'
import PValidationWorkflowStatusBar from './Partials/PartialValidationWorkflowStatusBar'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import { SCancelChanges } from '@/config/config-shared-components'
import PValidationWorkflowViewTabs from './Partials/PartialValidationWorkflowViewTabs'
import PValidationWorkflowLogs from './Partials/PartialValidationWorkflowLogs'
import PValidationWorkflowBreadcrumb from './Partials/PartialValidationWorkflowBreadcrumb'
import PValidationMenu from './Partials/PartialValidationMenu'
import PValidationWorkspace from './Partials/PartialValidationWorkspace'


export default {
    mixins: [
        loadWorkspace,
        mixinLoading
    ],
    components: {
       SCancelChanges,
       PValidationWorkflowSummary,
       PValidationTrigger,
       PValidationWorkflowConditions,
       PValidationWorkflow,
       PValidationWorkflowViewHeader,
       PValidationWorkflowStatusBar,
       PValidationWorkflowViewTabs,
       PValidationWorkflowLogs,
       PValidationWorkflowBreadcrumb,
       PValidationMenu,
       PValidationWorkspace,
    },
    data() {
        return {
            configurationMenuSelected: [],
            triggers : [
                { type: "project-created", icon: 'add_box'},
                { type: "project-updated", icon: 'border_color'},
                { type: "project-status-updated", icon: 'donut_large'},
                { type: "project-upsert", icon: 'add_box'},
                { type: "project-stage-changed", icon: 'label_important'},
                { type: "project-completed", icon: 'task_alt'},
                { type: "project-archived", icon: 'archive'},
                { type: "project-result", icon: 'thumbs_up_down'},
            ],
            COMPETITOR_FILTERS: {
                FIEDS: 'fields[competitors]',
                TITLE: 'filter[name]',
                SORT: 'sort',
                COUNT: 'count',
            },
            competitor_filters: {},
            TAG_FILTERS: {
                FIEDS: 'fields[tags]',
                LABEL: 'filter[label]',
                TYPE: 'filter[type]',
                SORT: 'sort',
                COUNT: 'count',
            },
            tag_filters: {},
            STAGE_FILTERS: {
                FIEDS: 'fields[stages]',
                WORKSPACE: 'filter[workspace_id]',
                NAME: 'filter[name]',
                SORT: 'sort',
                COUNT: 'count',
            },
            stage_filters: {},
            PARTNER_FILTERS: {
                FIEDS: 'fields[partners]',
                NAME: 'filter[name]',
                SORT: 'sort',
                COUNT: 'count',
            },
            partner_filters: {},
            CUSTOMER_FILTERS: {
                FIEDS: 'fields[customers]',
                NAME: 'filter[name]',
                SORT: 'sort',
                COUNT: 'count',
            },
            customer_filters: {},
            TERRITORY_FILTERS: {
                FIEDS: 'fields[territories]',
                NAME: 'filter[name]',
                SORT: 'sort',
                COUNT: 'count',
            },
            territory_filters: {},

            automation_workflow_filters: {},
            AUTOMATION_TRIGGER_FILTERS: {
                TYPE: 'filter[type]',
                FIEDS: 'fields[automation_triggers]',
                SORT: 'sort',
                COUNT: 'count',
            },
            automation_trigger_filters: {},
            AUTOMATION_WORKFLOW_FILTERS: {
                FIEDS: 'fields[automation_workflows]',
                INCLUDE: 'include',
                TRIGGER_IDS: 'filter[automation_trigger_id]',
                CREATED_BY: 'fields[createdBy]',
                UPDATED_BY: 'fields[updatedBy]',
                TRIGGER: 'fields[automationTrigger]',
            },
            automation_condition_filters: {},
            AUTOMATION_CONDITION_FILTERS: {
                FIEDS: 'fields[automation_conditions]',
                WORKFLOW_ID: 'filter[automation_workflow_id]',
            },
            automation_validation_filters: {},
            AUTOMATION_VALIDATION_FILTERS: {
                FIEDS: 'fields[automation_validations]',
                WORKFLOW_ID: 'filter[automation_workflow_id]',
            },
            automation_action_filters: {},
            AUTOMATION_ACTION_FILTERS: {
                FIEDS: 'fields[automation_actions]',
                WORKFLOW_ID: 'filter[automation_workflow_id]',
            },
            user_filters: {},
            USER_FILTERS: {
                FIEDS: 'fields[users]',
                INCLUDE: 'include',
                ROLE: 'fields[role]',
                COUNT: 'count',
                IS_ACTIVE: 'filter[is_active]',
            },
            localCloneAutomationWorkflowItem: {},

            automation_log_filters: {},
            AUTOMATION_LOG_FILTERS: {
                FIEDS: 'fields[automation_logs]',
                INCLUDE: 'include',
                STATUS: 'filter[status]',
                CREATED_BY: 'fields[createdBy]',
                UPDATED_BY: 'fields[updatedBy]',
                TRIGGER: 'fields[automationTrigger]',
                WORKFLOW_ID: 'filter[automation_workflow_id]',
                WORKFLOW: 'fields[automationWorkflow]',
                TIME: 'filter[created_at]',
                SORT: 'sort',
                STATUS: 'filter[status]',
            },
            selectedTime: { label: 'Last 1 hour', value: 1 },

            // boolean
            localPageloading: false,
            dialogUpdateWorkflowWorkspace: false,
            dialogUpdateWorkflowTrigger: false,
            dialogActiveWorkflow: false,
            dialogLeavePageModal: false,
            dialogCancelPageModal: false,
            localConditionsRemoveFlag: false,
            localTriggerEditMode: false,
            localIsTriggerEditMode: false,
            localValidateTriggerError: false,
            localValidationTitleError: false,

            localActiveMenu: 'workflow-workspace',
            localPage:'configurations',
            localValidationMessageType : 'validation_rules',
            defaultCount: 999,

            // array 
            localConditionsList: [],
            localValidationList: [],
            localTagSectionSlugs: [],
            localSystemTagList: [],
            localCustomTagList: [],
            selectedStatus: [],

            // null
            localConditionStoreItem: null,
            localValidationStoreItem: null,
            localActionStoreItem: null,
            islocalConditionUpdate: null,
            islocalActionUpdate: null,
            islocalValidationUpdate: null,
            islocalFieldUpdateUpdate: null,
            localTitleUpdateDebounce: null,
            localSelectedTriggerId: null,
            localCloneSelectedTriggerId: null,
            pageToLeave: null,
            filterLoading: null,
            localSelectedTiggerId: null,
            localSelectedTiggerSlug: null,
        }
    },

    mounted () {
        this.localIndex()
        window.addEventListener('beforeunload', this.localBeforeUnloadEvent)
        window.addEventListener('scroll', this.localWindowScroll)
    },

    beforeDestroy () {
        window.removeEventListener('beforeunload', this.localBeforeUnloadEvent)
    },

    beforeRouteLeave (toRoute, fromRoute, nextRoute) {
        if (this.localPageloading || this.mixinIsLoading('workflow-conditions') || this.mixinIsLoading('workflow-validations') || this.mixinIsLoading('update-workflow-changes') || this.mixinIsLoading('workflow-toggle-state')) return nextRoute()

        this.localCheckWorkflowChanges()
        if (!this.localHasWorkflowUpdateChanges) return nextRoute()

        nextRoute(false)
        this.dialogLeavePageModal = true
        this.pageToLeave = nextRoute
    },

    computed: {
        localCheckWorkflowConditionsErrors () {
            const conditionError = this.automation_condition_list.filter(item => item.warning)
            return conditionError && conditionError.length === 0
        },

        localCheckWorkflowValidationErrors () {
            const validationError = this.automation_validation_list.filter(item => item.warning)
            return validationError && validationError.length === 0
        },

        localHasWorkflowUpdateChanges () {
            return (this.islocalFieldUpdateUpdate === false ||  this.islocalConditionUpdate === false || 
                   this.islocalValidationUpdate === false)
        },

        localValidationCount () {
            return !(this.localValidationList.length && this.localValidationErrorCheck.length)
        },

        localValidationErrorCheck () {
            return this.automation_validation_list.filter(item => item.is_valid === 1)
        },

        localNumberOperator () {
            return [
                'greater-than',
                'greater-than-or-equal',
                'less-than',
                'less-than-or-equal',
            ]
        },

        localCheckHiddenWorkspace () {
            const workspaceList = this.workspace_board_list.filter(list => list.id === this.localCloneAutomationWorkflowItem.workspace_id)
            return workspaceList.length ? false : true
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_list: 'list',
        }),

        ...mapState('Competitor', {
            competitor_list: 'list',
            competitor_response: 'response',
        }),

        ...mapState('Stage', {
            stage_list: 'list',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Customer', {
            customer_list: 'list',
            customer_response: 'response',
        }),

        ...mapState('Territory', {
            territory_list: 'list',
        }),

        ...mapState('TagSection', {
            tag_section_list: 'list',
        }),

        ...mapState('CustomField', {
            custom_field_list: 'list',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
            tag_response: 'response',
        }),

        ...mapState('AutomationWorkflow', {
            automation_workflow_item: 'item',
            automation_workflow_response: 'response',
        }),
        
        ...mapState('AutomationConditionEntity', {
            automation_condition_entity_list: 'list',
        }),

        ...mapState('AutomationValidationEntity', {
            automation_validation_entity_list: 'list',
        }),

        ...mapState('AutomationTrigger', {
            automation_trigger_list: 'list',
        }),

        ...mapState('AutomationCondition', {
            automation_condition_list: 'list',
        }),

        ...mapState('AutomationValidation', {
            automation_validation_list: 'list',
        }),

        ...mapState('AutomationLog', {
            automation_log_list: 'list',
        }),

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),

        ...mapState('Workspace', {
            workspace_board_list: 'board_list',
        }),
    },

    methods: {
        async localIndex () {
            if (this.user_self && this.user_self.is_admin === 0 || this.user_self.scope === 'external') {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.$vuetify.goTo(0)
            this.localLoadRelatedList()
            this.localLoadAutomationLogList()
            this.localLoadAutomationTriggerList()

            await this.automation_workflow_clear_item()
            this.localPageloading = true
            await this.localShowAutomationWorkflow(this.$route.params.id)
            
            this.localLoadEntityData()
            this.localLoadRelatedWorkflowData()
            this.localCloneAutomationWorkflowItem = _.cloneDeep(this.automation_workflow_item)
            await this.localLoadStageList()
            this.localPageloading = false
        },

        localLoadEntityData () {
            const slug = this.automation_workflow_item && this.automation_workflow_item.automation_trigger ? 
                        this.automation_workflow_item.automation_trigger.slug : 
                        'project-stage-changed'
            this.localLoadWorkflowEntityList(slug)
        },

        localCheckValidationCount () {
            if (this.localCloneAutomationWorkflowItem.automation_all_validation_count === 0) {
                this.localCreateValidationGroup('new', null)
            }
        },

        localWindowScroll () {
            const workflows = document.querySelectorAll('.workflow');
            workflows.forEach(sec => {
                const top = window.scrollY + 320;
                const offset = sec.offsetTop;
                const height = sec.offsetHeight;
                const id = sec.getAttribute('id');
                if (top >= offset && top < offset + height) {
                    const target = document.querySelector("#"+id);
                    this.localActiveMenu = target.id
                }
            })
        },

        async localPageUpdate (page) {
            this.localActiveMenu = null
            if (this.localPage === page) return
            this.localPage = page
            if(this.localPage === 'logs') {
                this.$vuetify.goTo(0)
                this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.STATUS] = null
                this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TIME] = 1
                this.selectedTime.value = 1
                this.selectedTime.label = 'Last 1 hour'
                this.selectedStatus = []
                this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TIME] = 1
                await this.localAutomationLogIndex()
            }
        },

        async localLoadWorkflowEntityList (type = null) {
            this.automation_condition_entity_index({slug : type})
            this.automation_validation_entity_index({slug : type})
        },

        async localLoadRelatedWorkflowData () {
            this.localLoadAutomationConditionList()
            await this.localLoadAutomationValidationList()
            this.localCheckValidationCount()
        },

        localIsAutomationWorkflowRelativeCount () {
            if(this.automation_workflow_item) {
                if (this.automation_workflow_item.automation_trigger_id) this.localIsValidateMenu('workflow-trigger')

                if (this.automation_workflow_item.automation_condition_count !== 0) this.localIsValidateMenu('workflow-conditions')
            
                if (this.automation_workflow_item.automation_validation_count !== 0) this.localIsValidateMenu('workflow-validations')
            }
        },

        localIsValidateMenu (slug) {
            const index = this.configurationMenuSelected.findIndex(item => item === slug)
            if (index !== -1) return
            this.configurationMenuSelected.push(slug)
        },

        async localShowAutomationWorkflow (id) {
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.FIEDS] = 'id,title,description,is_active,order,workspace_id,stage_from,stage_to,automation_trigger_id,updated_by_id,created_by_id,created_at,updated_at'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.INCLUDE] = 'automationTrigger,createdBy,updatedBy,automationAllValidationCount,automationValidationCount'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.CREATED_BY] = 'id,name,avatar,initial,color'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.UPDATED_BY] = 'id,name,avatar,initial,color'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.TRIGGER] = 'id,title,slug,description'
            await this.automation_workflow_show({id: id, params: this.automation_workflow_filters })
        },

        async localLoadAutomationTriggerList () {
            this.automation_trigger_filters[this.AUTOMATION_TRIGGER_FILTERS.TYPE] = 'validation'
            this.automation_trigger_filters[this.AUTOMATION_TRIGGER_FILTERS.FIEDS] = 'id,title,slug,description'
            this.automation_trigger_filters[this.AUTOMATION_TRIGGER_FILTERS.COUNT] =  100
            await this.automation_trigger_index(this.automation_trigger_filters)
        },

        async localSelectMenu (pageSlug) {
            this.localPage = 'configurations'
            
            if (this.localActiveMenu === pageSlug) return

            this.localActiveMenu = pageSlug
            const entitySlug = await this.localGetTriggerSlug()

            if (this.localActiveMenu === 'workflow-summary') this.$vuetify.goTo(0)

            if (this.localActiveMenu === 'workflow-conditions') this.automation_condition_entity_index({slug : entitySlug})

            if (this.localActiveMenu === 'workflow-validations')  this.automation_validation_entity_index({slug : entitySlug})

            setTimeout(async () => {
                this.localSeletedMenuActive(pageSlug)
            }, 100)
        },

        localGetTriggerSlug () {
            const trigger_id = this.localCloneAutomationWorkflowItem.automation_trigger_id ?
                        this.localCloneAutomationWorkflowItem.automation_trigger_id : 
                        this.automation_workflow_item.automation_trigger_id
            
            const triggerItem = this.automation_trigger_list.find(item => item.id === trigger_id)
            return _.get(triggerItem, 'slug') || 'project-stage-changed'
        },

        localCheckSelectMenu () {
            return ['workflow-workspace','workflow-validations','workflow-trigger', 'workflow-conditions'].includes(this.localActiveMenu)
        },

        localSeletedMenuActive (slug) {
            const element = document.getElementById(slug);
            if (element) {
                if (slug === 'workflow-conditions' && this.localConditionsList.length === 0) {
                    window.scrollTo(0, element.offsetTop -170)
                    return
                }
                if (slug === 'workflow-workspace') {
                    window.scrollTo(0, 0)
                    return
                }
                window.scrollTo(0, element.offsetTop - 18)
            }
        },

        async localLoadRelatedList () {
            this.localLoadUserList()
            this.localLoadCompetitorList()
            // this.localLoadStageList()
            this.localLoadPartnerList()
            this.localLoadTerritoryList()
            this.localLoadCustomerList()
            await this.localLoadSystemTagList()
            await this.localLoadCustomTagSectionList()
            await this.localLoadCustomTagList()
            await this.tag_clear()
        },

        async localLoadSystemTagList () {
            await this.tag_clear()
            let system_type = ['label','product','platform']
            this.tag_filters[this.TAG_FILTERS.TYPE] =  system_type
            this.tag_filters[this.TAG_FILTERS.FIEDS] = 'id,type,label,color'
            this.tag_filters[this.TAG_FILTERS.SORT] = 'label'
            this.tag_filters[this.TAG_FILTERS.COUNT] = this.defaultCount
            await this.tag_index(this.tag_filters)
            this.localSystemTagList = _.cloneDeep(this.tag_list)
        },

        async localLoadCustomTagSectionList () {
            await this.tag_section_index({ 'filter[is_active]': 1, sort: 'order', 'fields[tag_sections]': 'id,slug', 'count': 200 })
            this.localTagSectionSlugs = this.localPluckTagSectionSlugs(this.tag_section_list)
        },

        async localLoadCustomTagList () {
            this.tag_filters[this.TAG_FILTERS.TYPE] =  this.localTagSectionSlugs
            this.tag_filters[this.TAG_FILTERS.FIEDS] = 'id,type,label,color'
            this.tag_filters[this.TAG_FILTERS.SORT] = 'label'
            this.tag_filters[this.TAG_FILTERS.COUNT] = this.defaultCount
            await this.tag_index(this.tag_filters)
            this.localCustomTagList = _.cloneDeep(this.tag_list)
        },

        async localLoadCompetitorList () {
            this.competitor_filters[this.COMPETITOR_FILTERS.FIEDS] = 'id,name'
            this.competitor_filters[this.COMPETITOR_FILTERS.SORT] = 'name'
            this.competitor_filters[this.COMPETITOR_FILTERS.COUNT] = this.defaultCount
            await this.competitor_index(this.competitor_filters)
        },

        async localLoadCustomTagSectionList () {
            await this.tag_section_index({ 'filter[is_active]': 1, sort: 'order', 'fields[tag_sections]': 'id,slug', 'count': this.defaultCount })
            this.localTagSectionSlugs = this.localPluckTagSectionSlugs(this.tag_section_list)
        },

        async localLoadCustomTagList () {
            await this.tag_clear()
            this.tag_filters[this.TAG_FILTERS.TYPE] =  this.localTagSectionSlugs
            this.tag_filters[this.TAG_FILTERS.FIEDS] = 'id,type,label,color'
            this.tag_filters[this.TAG_FILTERS.SORT] = 'label'
            this.tag_filters[this.TAG_FILTERS.COUNT] = this.defaultCount
            await this.tag_index(this.tag_filters)
            this.localCustomTagList = _.cloneDeep(this.tag_list)
        },

        async localLoadStageList (workspace_id = null) {
            this.stage_filters[this.STAGE_FILTERS.WORKSPACE] = workspace_id ? workspace_id : this.localCloneAutomationWorkflowItem.workspace_id
            this.stage_filters[this.STAGE_FILTERS.FIEDS] =  'id,name,status,workspace_id'
            this.stage_filters[this.STAGE_FILTERS.SORT] = 'order'
            this.stage_filters[this.STAGE_FILTERS.COUNT] = this.defaultCount
            await this.stage_index(this.stage_filters)
        },

        async localLoadUserList () {
            this.user_filters[this.USER_FILTERS.IS_ACTIVE] = 1
            this.user_filters[this.USER_FILTERS.FIEDS] = 'id,name,initial,scope,avatar,color,role_id,is_active'
            this.user_filters[this.USER_FILTERS.COUNT] = this.defaultCount
            this.user_filters[this.USER_FILTERS.INCLUDE] = 'role'
            this.user_filters[this.USER_FILTERS.ROLE] = 'id,name'
            await this.user_index(this.user_filters)
        },

        async localLoadPartnerList () {
            this.partner_filters[this.PARTNER_FILTERS.FIEDS] =  'id,name'
            this.partner_filters[this.PARTNER_FILTERS.SORT] = 'name'
            this.partner_filters[this.PARTNER_FILTERS.COUNT] = this.defaultCount
            await this.partner_index(this.partner_filters)
        },

        async localLoadCustomerList () {
            this.customer_filters[this.CUSTOMER_FILTERS.FIEDS] =  'id,name'
            this.customer_filters[this.CUSTOMER_FILTERS.SORT] = 'name'
            this.customer_filters[this.CUSTOMER_FILTERS.COUNT] = this.defaultCount
            await this.customer_index(this.customer_filters)
        },

        localPluckTagSectionSlugs (objs, name) {
            var data = [];
            for(var i in objs){
                if(objs[i].hasOwnProperty(name)){
                    data.push(objs[i][name]);
                }
            }
            return data;
        },

        async localLoadTerritoryList () {
            await this.territory_clear()
            this.territory_filters[this.TERRITORY_FILTERS.FIEDS] =  'id,name,color'
            this.territory_filters[this.TERRITORY_FILTERS.SORT] = 'name'
            this.territory_filters[this.TERRITORY_FILTERS.COUNT] = this.defaultCount
            await this.territory_index(this.territory_filters)
        },

        async localAutomationWorkflowToggleState (state) {
            this.mixinSetLoading('workflow-toggle-state')
            await this.automation_workflow_toggle_state({id: this.automation_workflow_item.id, state: state})
            this.mixinResetLoading('workflow-toggle-state')
            if (!this.$status(this.automation_workflow_response)) return
            await this.localShowAutomationWorkflow(this.automation_workflow_item.id)
            this.automation_workflow_item.is_active = state
            this.localCloneAutomationWorkflowItem = _.cloneDeep(this.automation_workflow_item)
            this.$notify('success', state === 1 ? 'Validation Enabled Successfully!' : 'Validation Disabled Successfully!')
        },

        async localAutomationWorkflowUpdate() {
            const update_item = {}
            update_item.id = this.localCloneAutomationWorkflowItem.id
            update_item.title = this.localCloneAutomationWorkflowItem.title
            update_item.description = this.localCloneAutomationWorkflowItem.description
            update_item.stage_from = this.localCloneAutomationWorkflowItem.stage_from
            update_item.stage_to = this.localCloneAutomationWorkflowItem.stage_to
            update_item.automation_trigger_id = this.localCloneAutomationWorkflowItem.automation_trigger_id
            update_item.workspace_id = this.localCloneAutomationWorkflowItem.workspace_id

            await this.automation_workflow_update(update_item)
            if (this.$status(this.automation_workflow_response)) {
                await this.localShowAutomationWorkflow(this.localCloneAutomationWorkflowItem.id)
                this.localCloneAutomationWorkflowItem = _.cloneDeep(this.automation_workflow_item)
                // clear edit mode
                if (this.localTriggerEditMode === true) {
                    this.localIsTriggerEditMode = false
                    this.localTriggerEditMode = false
                    this.localSelectedTiggerId = null
                    this.localSelectedTiggerSlug = null
                }
            }
        },

        // Permission
        localAutomationWorkflowPermission(type) {
            return this.$can(`validation-workflows.${type}`)
        },

        // Validation related function---------start
        async localSetAutomationValidationList () {
            this.localValidationList = this.automation_validation_list.reduce((prev, current) => {
            const isAvail = prev.find(item => item.group_id === current.group)
                if (isAvail) {
                    current['group_id'] = current.group
                    isAvail.validations.push(_.cloneDeep(current))
                } else {
                    current['group_id'] = current.group
                    prev.push({
                        group_id: current.group,
                        validations: [_.cloneDeep(current)]
                    })
                }
                return prev
            }, [])
        },
        
        localValidationConfig () {
            this.localSelectMenu('workflow-validations')
        },

        async localLoadAutomationValidationList() {
            this.mixinSetLoading('workflow-validations')
            this.automation_validation_filters[this.AUTOMATION_VALIDATION_FILTERS.FIEDS] = 'id,group,field_name,field_type,operator,value,value_alt,related_type,related_id,warning,warning_message,is_valid'
            this.automation_validation_filters[this.AUTOMATION_VALIDATION_FILTERS.WORKFLOW_ID] = this.$route.params.id
            await this.automation_validation_index(this.automation_validation_filters)
            this.localSetAutomationValidationList()
            this.mixinResetLoading('workflow-validations')
        },

        localGetValidationValueChanges () {
            this.localGetValidationsUpdate()
        },

        localGetAutoFillRoleValidationMessage (validation) {
            const group_index = this.localValidationList.findIndex(item => item.group_id === validation.group)
            const row_index = this.localValidationList[group_index].validations.findIndex(item => item.id === validation.id)
            const field = this.automation_validation_entity_list.find(item => item.name === validation.field_name)
            if (field && validation.operator) {
                const roules_item = field.rules.find(item => item.key === validation.operator)
                if(roules_item) {
                    this.localValidationList[group_index].validations[row_index].warning_message = roules_item.message
                }
            }
            this.localValidationChangeRule(validation)
        },

        localSetDefaultMessage (validation) {
            const group_index = this.localValidationList.findIndex(item => item.group_id === validation.group)
            const row_index = this.localValidationList[group_index].validations.findIndex(item => item.id === validation.id)

            const field = this.automation_validation_entity_list.find(item => item.name === validation.field_name)
            if (field && validation.operator) {
                const roules_item = field.rules.find(item => item.key === validation.operator)
                if(roules_item) {
                    this.localValidationList[group_index].validations[row_index].warning_message = roules_item.message
                }
            }
            this.localGetValidationValueChanges()
        },

        localHasCheckValidationMessageEdit (validation) {
            const field = this.automation_validation_entity_list.find(item => item.name === validation.field_name)
            if (field && validation.operator) {
                const roules_item = field.rules.find(item => item.key === validation.operator)
                if(roules_item && roules_item.message !== validation.warning_message) {
                    return true
                }
            }
            return false
        },

        localGetValidationsUpdate () {
            this.localValidationStoreItem = null
            const params = { automation_workflow_id: this.$route.params.id, data: [] }
            for (let index = 0; index < this.localValidationList.length; index++) {
                const item = this.localValidationList[index];
                const groupModified = _.cloneDeep(item.validations).map(validation_item => {
                    validation_item.group = _.cloneDeep(validation_item.group)
                    validation_item.group_id
                    return validation_item
                })
                params.data.push(...groupModified)
            }
            this.localValidationStoreItem = params
            this.islocalValidationUpdate = _.isEqual(this.localValidationStoreItem.data, this.automation_validation_list)
        },

        localRemoveValidation (validation, type = 'row') {
            if (type !== 'row') {
                const group_index = this.localValidationList.findIndex(item => item.group_id === validation.group_id)
                this.localValidationList.splice(group_index, 1)
                this.localGetValidationValueChanges()
                return true
            }
            const group_index = this.localValidationList.findIndex(item => item.group_id === validation.group)
            const row_index = this.localValidationList[group_index].validations.findIndex(item => item.id === validation.id)
            this.localValidationList[group_index].validations.splice(row_index, 1)

            if (!this.localValidationList[group_index].validations.length) this.localValidationList.splice(group_index, 1)
            this.localGetValidationValueChanges()
        },

        localUpdateValidationRelatedSource (validation) {
            const group_index = this.localValidationList.findIndex(item => item.group_id === validation.group)
            const row_index = this.localValidationList[group_index].validations.findIndex(item => item.id === validation.id)
            const field_item  = this.automation_validation_entity_list.find(item => item.name === validation.field_name)
            
            if (field_item) {
                this.localValidationList[group_index].validations[row_index].value = null
                this.localValidationList[group_index].validations[row_index].operator = null
                this.localValidationList[group_index].validations[row_index].warning_message = null
                this.localValidationList[group_index].validations[row_index].related_id = field_item.related_id
                this.localValidationList[group_index].validations[row_index].related_type = field_item.related_type
                this.localValidationList[group_index].validations[row_index].field_type = field_item.type

                if (['project_stage_id', 'project_health', 'project_status_id'].includes(field_item.related_type)) {
                    this.localValidationList[group_index].validations[row_index].related_type = null
                    this.localValidationList[group_index].validations[row_index].related_id = null
                }
            }
            this.localGetValidationValueChanges()
        },

        localValidationChangeRule (validation) {
            const group_index = this.localValidationList.findIndex(item => item.group_id === validation.group)
            const row_index = this.localValidationList[group_index].validations.findIndex(item => item.id === validation.id)
            this.localValidationList[group_index].validations[row_index].value = ''
            this.localValidationList[group_index].validations[row_index].value_alt = ''
            this.localValidationList[group_index].validations[row_index].warning = ''
            this.localGetValidationValueChanges()
        },

        async localAddValidation (flag = null) {
            const item = this.$appendId({
                field_name: '',
                field_type: '',
                operator: '',
                value: '',
                value_alt: '',
                related_type: '',
                related_type: '',
                warning_message: '',
            })
            //if (flag) return this.localAddValidationItem(item)

            this.localValidationList.push(item)
            this.localGetValidationValueChanges()
        },

        localCreateValidationGroup (validation_group_id, group_index = null) {
            const groupId = uuidv4()
            const rowId = uuidv4()
            const row_item = {}
            row_item.id = rowId
            row_item.group = groupId
            row_item.field_name = ''
            row_item.field_type = ''
            row_item.operator = ''
            row_item.value = ''
            row_item.value_alt = ''
            row_item.related_type = null
            row_item.related_id = null,
            row_item.warning_message = ''
            row_item.is_valid = 0
            row_item.automation_workflow_id = this.$route.params.id
            const validations_array = [];
            validations_array.push(row_item)
            const newGroupObj = { group_id: groupId, validations: validations_array }
            if (validation_group_id === 'new' && group_index === null) {
                this.localValidationList.push(newGroupObj)
                this.localGetValidationValueChanges()
                return true
            }

            if (validation_group_id === 'new' && group_index >= 0) {
                this.localValidationList.splice(group_index + 1, 0, newGroupObj)
                this.localGetValidationValueChanges()
                return true
            }
            const validation_group_index = this.localValidationList.findIndex(item => item.group_id === validation_group_id)
            row_item.group = validation_group_id
            this.localValidationList[validation_group_index].validations.push(row_item)
            this.localGetValidationValueChanges()
        },

        localGetValidationChanges () {
            this.localValidationStoreItem = null
            const params = { automation_workflow_id: this.$route.params.id, data: [] }
            for (let index = 0; index < this.localValidationList.length; index++) {
                const item = this.localValidationList[index];
                const groupModified = _.cloneDeep(item.validations).map(validation_item => {
                    validation_item.group = _.cloneDeep(validation_item.group)
                    validation_item.group_id
                    return validation_item
                })
                params.data.push(...groupModified)
            }
            this.localValidationStoreItem = params
            this.islocalValidationUpdate = _.isEqual(this.localValidationStoreItem.data, this.automation_validation_list)
        },

        async localAddValidationItem (item) {
            await this.automation_validation_add_item(item)
            this.localValidationList = _.cloneDeep(this.automation_validation_list)
        },

        localValidationMessageTypeUpdate (type) {
            this.localValidationMessageType = type
        },

        //Validation related function-----------end

        //Condition related function-----------start
        localCreateConditionGroup (condition_group_id, group_index = null) {
            const groupId = uuidv4()
            const rowId = uuidv4()
            const row_item = {}
            row_item.id = rowId
            row_item.group = groupId
            row_item.field_name = ''
            row_item.field_type = ''
            row_item.operator = ''
            row_item.value = ''
            row_item.value_alt = ''
            row_item.related_type = null
            row_item.related_id = null,
            row_item.warning = ''
            row_item.is_valid = 1
            row_item.automation_workflow_id = this.$route.params.id
            const conditions_array = [];
            conditions_array.push(row_item)
            const newGroupObj = { group_id: groupId, conditions: conditions_array }
            if (condition_group_id === 'new' && group_index === null) {
                this.localConditionsList.push(newGroupObj)
                this.localGetConditionValueChanges()
                return true
            }

            if (condition_group_id === 'new' && group_index >= 0) {
                this.localConditionsList.splice(group_index + 1, 0, newGroupObj)
                this.localGetConditionValueChanges()
                return true
            }
            const condition_group_index = this.localConditionsList.findIndex(item => item.group_id === condition_group_id)
            row_item.group = condition_group_id
            this.localConditionsList[condition_group_index].conditions.push(row_item)
            this.localGetConditionValueChanges()
        },

        localRemoveCondition (condition, type = 'row') {
            if (type !== 'row') {
                const group_index = this.localConditionsList.findIndex(item => item.group_id === condition.group_id)
                this.localConditionsList.splice(group_index, 1)
                this.localGetConditionValueChanges()
                return true
            }
            const group_index = this.localConditionsList.findIndex(item => item.group_id === condition.group)
            const row_index = this.localConditionsList[group_index].conditions.findIndex(item => item.id === condition.id)
            this.localConditionsList[group_index].conditions.splice(row_index, 1)

            if (!this.localConditionsList[group_index].conditions.length) this.localConditionsList.splice(group_index, 1)
            this.localGetConditionValueChanges()
        },

        localUpdateConditionSource (condition) {
            const group_index = this.localConditionsList.findIndex(item => item.group_id === condition.group)
            const row_index = this.localConditionsList[group_index].conditions.findIndex(item => item.id === condition.id)
            const field_item  = this.automation_condition_entity_list.find(item => item.name === condition.field_name)
            
            if (field_item) {
                this.localConditionsList[group_index].conditions[row_index].value = ''
                this.localConditionsList[group_index].conditions[row_index].operator = ''
                this.localConditionsList[group_index].conditions[row_index].field_type = field_item.type
                this.localConditionsList[group_index].conditions[row_index].related_type = field_item.related_type
                this.localConditionsList[group_index].conditions[row_index].related_id = field_item.related_id
                
                if (['project_stage_id', 'customer_id', 'project_health', 'project_status_id'].includes(field_item.related_type)) {
                    this.localConditionsList[group_index].conditions[row_index].related_type = null
                    this.localConditionsList[group_index].conditions[row_index].related_id = null
                }
            }
            this.localGetConditionValueChanges()
        },

        localGetConditionValueChanges () {
            this.localGetConditionsUpdate()
        },

        localConditionChangeOperator(condition) {
            const group_index = this.localConditionsList.findIndex(item => item.group_id === condition.group)
            const row_index = this.localConditionsList[group_index].conditions.findIndex(item => item.id === condition.id)
            this.localConditionsList[group_index].conditions[row_index].value = ''
            this.localConditionsList[group_index].conditions[row_index].value_alt = ''
            this.localConditionsList[group_index].conditions[row_index].warning = null
            this.localGetConditionValueChanges()
        },

        async localLoadAutomationConditionList () {
            this.mixinSetLoading('workflow-conditions')
            this.automation_condition_filters[this.AUTOMATION_CONDITION_FILTERS.FIEDS] = 'id,group,field_name,field_type,operator,value,value_alt,related_type,related_id,warning,is_valid'
            this.automation_condition_filters[this.AUTOMATION_CONDITION_FILTERS.WORKFLOW_ID] =  this.$route.params.id
            await this.automation_condition_index(this.automation_condition_filters)
            await this.localSetAutomationConditionList()
            this.mixinResetLoading('workflow-conditions')
        },

        localGetConditionsUpdate () {
            this.localConditionStoreItem = null
            const params = { automation_workflow_id: this.$route.params.id, data: [] }
            for (let index = 0; index < this.localConditionsList.length; index++) {
                const item = this.localConditionsList[index];
                const groupModified = _.cloneDeep(item.conditions).map(conditions_item => {
                    conditions_item.group = _.cloneDeep(conditions_item.group)
                    conditions_item.group_id
                    return conditions_item
                })
                params.data.push(...groupModified)
            }
            this.localConditionStoreItem = params
            this.islocalConditionUpdate = _.isEqual(this.localConditionStoreItem.data, this.automation_condition_list)
        },

        async localSetAutomationConditionList () {
            this.localConditionsList = this.automation_condition_list.reduce((prev, current) => {
            const isAvail = prev.find(item => item.group_id === current.group)
                if (isAvail) {
                    current['group_id'] = current.group
                    isAvail.conditions.push(_.cloneDeep(current))
                } else {
                    current['group_id'] = current.group
                    prev.push({
                        group_id: current.group,
                        conditions: [_.cloneDeep(current)]
                    })
                }
                return prev
            }, [])
        },

        //Condition related function-----------end

        // update workflow changes
        async localGetFieldUpdate () {
            this.islocalFieldUpdateUpdate = _.isEqual(this.localCloneAutomationWorkflowItem, this.automation_workflow_item)
        },

        async localWorkflowHasChanges () {
            if (this.localHasWorkflowUpdateChanges === true) this.dialogCancelPageModal = true
        },

        async localCancelWorkflowChanges () {
            this.automation_workflow_clear_response()
            this.localConditionsList =  _.cloneDeep(this.automation_condition_list)
            this.localSetAutomationConditionList()
            this.localCloneAutomationWorkflowItem = _.cloneDeep(this.automation_workflow_item)
            this.localSetAutomationValidationList()
            this.localCheckWorkflowChanges()
            if (this.localIsTriggerEditMode === true) {
                this.localSelectedTiggerId = this.localCloneAutomationWorkflowItem.automation_trigger_id
            }
            this.localLoadWorkspaceStages()
            this.localLoadEntityData()
            this.dialogCancelPageModal = false
        },

        async localUpdateWorkflowChanges () {
            await this.localCheckWorkflowChanges()
            this.mixinSetLoading('update-workflow-changes')
            await this.localUpdateAutomationRelatedDataChanges()
            await this.localAutomationFieldUpdate()
            const response = await this.localGetUpdateStatus()
            if(!response) {
                this.$notify('error', 'Validation Update Failed!')
                this.mixinResetLoading('update-workflow-changes')
                if (this.localValidationCount === true) {
                    this.automation_workflow_item.is_active = 0
                }
                this.localCheckWorkflowChanges()
                return false
            }

            await this.localGetUpdateChanges()
            await this.localCheckWorkflowActiveStatus()
            this.localTriggerModeClear()
            this.mixinResetLoading('update-workflow-changes')
        },

        localGetUpdateStatus () {
            if (this.$response(this.automation_workflow_response, 'automation_trigger_id') || this.localCloneAutomationWorkflowItem.automation_trigger_id === null) {
                this.localActiveMenu = 'workflow-trigger'
                this.localValidateTriggerError = true
                return false
            } else {
                this.localValidateTriggerError = false
            }

            if (this.$response(this.automation_workflow_response, 'title')) {
                this.localActiveMenu = 'workflow-summary'
                return false
            }

            if (this.islocalFieldUpdateUpdate === false && !this.$status(this.automation_workflow_response)) return false
               
            const conditionErrors = this.automation_condition_list.filter(item => item.is_valid === 0)
            const validationErrors = this.automation_validation_list.filter(item => item.is_valid === 0)

            if (validationErrors.length || conditionErrors.length) return false

            if (this.localCloneAutomationWorkflowItem.title === 'Untitled Validation') {
                this.localActiveMenu = 'workflow-summary'
                this.localValidationTitleError = true
                return true
            }
            this.localValidationTitleError = false

            return true
        },

        localCheckWorkflowActiveStatus () {
            if (this.localCloneAutomationWorkflowItem.automation_trigger_id && this.localCloneAutomationWorkflowItem.automation_validation_count !== 0 && this.localCloneAutomationWorkflowItem.is_active === 0) {
                this.dialogActiveWorkflow = true
                return false
            }
            return true
        },

        async localGetUpdateChanges () {
            if (this.localHasWorkflowUpdateChanges === true) {
                this.$notify('success', 'Validation Updated Successfully!')
                this.localStoreAutomationRevisionHistory()
                await this.localShowAutomationWorkflow(this.localCloneAutomationWorkflowItem.id)
                this.localCloneAutomationWorkflowItem = _.cloneDeep(this.automation_workflow_item)
                this.configurationMenuSelected = []
                this.localIsAutomationWorkflowRelativeCount()
            }
            this.localCheckWorkflowChanges()
        },

        async localStoreAutomationRevisionHistory () {
            await this.automation_revision_history_store({ automation_workflow_id : this.localCloneAutomationWorkflowItem.id})
        },

        async localAutomationFieldUpdate () {
            this.automation_workflow_clear_response()
            if (this.islocalFieldUpdateUpdate === false) await this.localAutomationWorkflowUpdate()
        },

        async localUpdateAutomationRelatedDataChanges () {
            if (this.islocalConditionUpdate === false) await this.localConditionUpsert()

            if (this.islocalValidationUpdate === false) await this.localValidationUpsert()
        },

        async localConditionUpsert () {
            await this.automation_condition_upsert(this.localConditionStoreItem)
            await this.localLoadAutomationConditionList()
        },

        async localValidationUpsert () {
            await this.automation_validation_upsert(this.localValidationStoreItem)
            await this.localLoadAutomationValidationList()
        },

        async localUpdateWorkflowState (state) {
            if (state === 0) {
                this.dialogActiveWorkflow = false
                return
            }
            this.mixinSetLoading('update-active')
            await this.localAutomationWorkflowToggleState(state)
            this.mixinResetLoading('update-active')
            this.dialogActiveWorkflow = false
        },

        localCheckWorkflowChanges () {
            this.localGetConditionsUpdate()
            this.localGetValidationsUpdate()
            this.localGetFieldUpdate()
        },

        localBeforeUnloadEvent (e) {
            this.localCheckWorkflowChanges()
            if (this.localHasWorkflowUpdateChanges) {
                return e.returnValue = 'You have unsaved changes. Are you sure you want to leave?'
            }
        },

        // logs related function-----------start 
        async localAutomationLogStatusFilter (value) {
            this.filterLoading = 'status'
            if (!value) {
                this.selectedStatus = []
                this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.STATUS] = null
                await this.localAutomationLogIndex()
                this.triggerDropdown = false
                this.filterLoading = null
                return 
            } 
            if (this.selectedStatus && !this.selectedStatus.length) this.selectedStatus.push(value)
            else {
                const index = this.selectedStatus.findIndex(id => id === value)
                if (index !== -1) this.selectedStatus.splice(index, 1)
                else this.selectedStatus.push(value)
            }
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.STATUS] = this.selectedStatus
            await this.localAutomationLogIndex()
            this.filterLoading = null
        },

        async localLogAutomationTimefilter(time) {
            this.filterLoading = 'time'
            this.selectedTime.label = time.label
            this.selectedTime.value = time.value
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TIME] = time.value
            await this.localAutomationLogIndex()
            this.filterLoading = null
        },

        async localLoadAutomationLogList () {
            this.mixinSetLoading('workflow-logs')
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TIME] = 1
            await this.localAutomationLogIndex()
            this.mixinResetLoading('workflow-logs')
        },

        async localAutomationLogIndex () {
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.WORKFLOW_ID] = this.$route.params.id
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.SORT] = '-created_at'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.FIEDS] = 'id,resource_type,resource_id,status,automation_trigger_id,automation_workflow_id,updated_by_id,created_by_id,created_at,updated_at'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.INCLUDE] = 'automationTrigger,createdBy,updatedBy,automationWorkflow,automationLogEntries,automationActionCount,automationConditionCount,automationValidationCount,automationLogValidationFailedCount,automationLogConditionFailedCount'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.CREATED_BY] = 'id,name'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.UPDATED_BY] = 'id,name'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.WORKFLOW] = 'id,title'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TRIGGER] = 'id,title,slug'
            await this.automation_log_index(this.automation_log_filters)
        },

        // logs related function-----------end 

        localConditionsRemoveFlagUpdate () {
            this.localConditionsRemoveFlag = true
            setTimeout(() => {
                this.localConditionsRemoveFlag = false
            }, 5000);
        },

        localConditionsRemove () {
            this.localConditionsList = []
            this.localGetConditionValueChanges()
            this.localConditionsRemoveFlag = false
        },

        // trigger related function-----------start
        localTriggerEditModeUpdate () {
            this.localTriggerEditMode = true
        },

        localTriggerEditUpdate () {
            this.localIsTriggerEditMode = true
            this.localSelectedTiggerId = this.localCloneAutomationWorkflowItem.automation_trigger_id
        },

        localGetTriggerIcon (type) {
            const trigger = this.triggers.find(item => item.type && item.type === type)
            return trigger ? trigger.icon : null
        },

        localTriggerCancel () {
            this.localIsTriggerEditMode = false
            this.localTriggerEditMode = false
            this.localSelectedTiggerId = null
            this.localSelectedTiggerSlug = null
            this.localCloneAutomationWorkflowItem.automation_trigger_id = this.automation_workflow_item.automation_trigger_id
            this.localCloneAutomationWorkflowItem.stage_from = this.automation_workflow_item.stage_from
            this.localCloneAutomationWorkflowItem.stage_to = this.automation_workflow_item.stage_to
        },

        localSelectTrigger (trigger) {
            if (this.localSelectedTiggerId == trigger.id) return
            this.localSelectedTiggerId = trigger.id
            this.localSelectedTiggerSlug = trigger.slug
            
            if (this.localCloneAutomationWorkflowItem.automation_trigger_id) {
                this.dialogUpdateWorkflowTrigger = true
                return
            }
            this.localUpdateWorkflowTrigger()
        },

        async localUpdateWorkflowTrigger () {
            this.localLoadWorkflowEntityList(this.localSelectedTiggerSlug)
            this.localResultTriggerCheck()
            
            this.localCloneAutomationWorkflowItem.automation_trigger_id = this.localSelectedTiggerId
            this.localIsTriggerEditMode = true
            this.localTriggerEditMode = true
            this.localCloneAutomationWorkflowItem.stage_from = null
            this.localCloneAutomationWorkflowItem.stage_to = null

            if (this.localSelectedTiggerId === this.automation_workflow_item.automation_trigger_id) {
                this.localCloneAutomationWorkflowItem.stage_from = this.automation_workflow_item.stage_from
                this.localCloneAutomationWorkflowItem.stage_to = this.automation_workflow_item.stage_to
            }
            this.localGetFieldUpdate()
            this.dialogUpdateWorkflowTrigger = false
        },

        async localCloseWorkflowTrigger () {
            this.localTriggerCancel()
            this.dialogUpdateWorkflowTrigger = false
        },

        localResultTriggerCheck () {
            this.localConditionsList = []
            this.localValidationList = []
            this.localCloneAutomationWorkflowItem.automation_all_validation_count = 0
            this.localCheckValidationCount()
        },

        localTriggerStageToFilterList (slug) {
            if (slug === "project-stage-changed") {
                const stageList = this.stage_list.filter(list => ['queue','active'].includes(list.status))
                if (this.localCloneAutomationWorkflowItem.stage_from) {
                    return stageList.filter(list => !this.localCloneAutomationWorkflowItem.stage_from.includes(list.id))
                }
                return stageList
            }
            return this.stage_list
        },

        localTriggerStageFromFilterList (slug) {
            if (slug === "project-stage-changed") {
                const stageList = this.stage_list.filter(list => !['completed'].includes(list.status))
                if (this.localCloneAutomationWorkflowItem.stage_to) {
                    return stageList.filter(list => !this.localCloneAutomationWorkflowItem.stage_to.includes(list.id))
                }
                return stageList
            }

            if (slug === 'project-completed') {
                const stageList = this.stage_list.filter(list => !['completed'].includes(list.status))
                if (this.localCloneAutomationWorkflowItem.stage_to) {
                    return stageList.filter(list => !this.localCloneAutomationWorkflowItem.stage_to.includes(list.id))
                }
                return stageList
            }
            return this.stage_list
        },

        localTriggerStageUpdate () {
            this.localGetFieldUpdate()
        },

        localTriggerModeClear () {
            this.localSelectedTiggerId = null
            this.localIsTriggerEditMode = false
            this.localTriggerEditMode = false
        },

        // trigger related function-----------end

        // workspace related  function ----------start
        async localSelectWorkspace () {
            if (this.automation_workflow_item.workspace_id !== this.localCloneAutomationWorkflowItem.workspace_id) {
                this.localCloneAutomationWorkflowItem.stage_from = null
                this.localCloneAutomationWorkflowItem.stage_to = null
                this.dialogUpdateWorkflowWorkspace = true
                return
            }

            await this.localLoadWorkspaceStages()
            this.localCloneAutomationWorkflowItem.stage_from = this.automation_workflow_item.stage_from
            this.localCloneAutomationWorkflowItem.stage_to = this.automation_workflow_item.stage_to 
            this.localGetFieldUpdate()    
            this.dialogUpdateWorkflowWorkspace = false
        },

        localCheckDefaultWorkspace () {
            const defaultWorkspace = this.workspace_board_list.find(item => item.is_default === 1 && item.id === this.localCloneAutomationWorkflowItem.workspace_id)
            return defaultWorkspace ? true : false
        },

        async localLoadWorkspaceStages () {
            this.mixinSetLoading('workflow-stages')
            await this.stage_clear()
            await this.localLoadStageList(this.automation_workflow_item.workspace_id)
            this.mixinResetLoading('workflow-stages')
        },

        async localUpdateWorkspace () { 
            this.localCloneAutomationWorkflowItem.stage_from = null
            this.localCloneAutomationWorkflowItem.stage_to = null
            this.dialogUpdateWorkflowWorkspace = false
            this.mixinSetLoading('workflow-stages')
            await this.stage_clear()
            await this.localLoadStageList(this.localCloneAutomationWorkflowItem.workspace_id)
            this.localGetFieldUpdate()
            this.mixinResetLoading('workflow-stages')
        },

        async localCancelWorkspace () {
            this.dialogUpdateWorkflowWorkspace = false
            this.localCloneAutomationWorkflowItem.workspace_id = this.automation_workflow_item.workspace_id
            this.localCloneAutomationWorkflowItem.stage_from = this.automation_workflow_item.stage_from
            this.localCloneAutomationWorkflowItem.stage_to = this.automation_workflow_item.stage_to 
            this.localGetFieldUpdate()
        },

        localValidationCustomFieldTextInfoCheck (validation) {
            const group_index = this.localValidationList.findIndex(item => item.group_id === validation.group)
            const validationGroupItem = this.localValidationList[group_index]
            if (validationGroupItem) {
                const validationItem = validationGroupItem.validations.find(item => {
                    return this.localNumberOperator.includes(item.operator) && 
                        item.field_type === "string" && 
                        item.related_type === "custom_field" &&
                        item.id === validation.id
                })
                return validationItem ? true : false
            }
            return false
        },

        localConditionCustomFieldTextInfoCheck (condition) {
            const group_index = this.localConditionsList.findIndex(item => item.group_id === condition.group)
            const conditionGroupItem = this.localConditionsList[group_index]
            if (conditionGroupItem) {
                const conditionItem = conditionGroupItem.conditions.find(item => {
                    return this.localNumberOperator.includes(item.operator) && 
                        item.field_type === "string" && 
                        item.related_type === "custom_field" &&
                        item.id === condition.id
                })
                return conditionItem ? true : false
            }
            return false
        },

        // workspace related  function ----------end

        ...mapActions('User', {
            user_index: 'index',
            user_clear: 'clear',
        }),

        ...mapActions('Partner', {
            partner_index: 'index',
            partner_clear: 'clear',
        }),

        ...mapActions('Territory', {
            territory_index: 'index',
            territory_clear: 'clear',
        }),

        ...mapActions('Competitor', {
            competitor_index: 'index',
            competitor_clear: 'clear',
        }),

        ...mapActions('Stage', {
            stage_index: 'index',
            stage_clear: 'clear',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),

        ...mapActions('Customer', {
            customer_index: 'index',
            customer_clear: 'clear',
        }),

        ...mapActions('TagSection', {
            tag_section_index: 'index',
            tag_section_clear: 'clear',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_clear: 'clear',
        }),

        ...mapActions('AutomationWorkflow', {
            automation_workflow_show: 'show',
            automation_workflow_reorder:'reorder',
            automation_workflow_update: 'update',
            automation_workflow_toggle_state: 'toggle_state',
            automation_workflow_clear_item: 'clear_item',
            automation_workflow_clear_response: 'clear_response',
        }),

        ...mapActions('AutomationTrigger', {
            automation_trigger_index: 'index',
            automation_trigger_clear_response: 'clear_response',
            automation_trigger_clear_list: 'clear_list',
        }),

        ...mapActions('AutomationConditionEntity', {
            automation_condition_entity_index: 'index',
        }),

        ...mapActions('AutomationValidationEntity', {
            automation_validation_entity_index: 'index',
        }),

        ...mapActions('AutomationCondition', {
            automation_condition_index: 'index',
            automation_condition_upsert: 'upsert',
            automation_condition_clear_list: 'clear_list',
        }),

        ...mapActions('AutomationValidation', {
            automation_validation_index: 'index',
            automation_validation_add_item: 'add_item',
            automation_validation_upsert: 'upsert',
            automation_validation_clear_list: 'clear_list',
        }),

        ...mapActions('AutomationRevisionHistory', {
            automation_revision_history_store: 'store',
        }),

        ...mapActions('AutomationLog', {
            automation_log_index: 'index',
        }),
    },
}
</script>

<style scoped>
.c-add-new-conditions-info {
    position: absolute;
    top: 2px;
    right: -38px;
}
.c-configuration-card {
    max-width: 87% !important;
    min-width: 87% !important;
    display: block;
}
.c-ellipse-bottom {
    position: relative;
    width: 28px;
    height: 28px;
    left: 50%;
    background: #FFFFFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    bottom: 16px;
    z-index: 1;
}
.c-ellipse-top {
    position: relative;
    width: 28px;
    height: 28px;
    left: 50%;
    background: #FFFFFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    top: 16px;
    z-index: 1;
}
.c-ellipse-parent {
    width: 18px;
    height: 18px;
    border: 4px solid #7C4DFF;
    border-radius: 50%;
    position: relative;
    left: 5px;
    top: 5px;
}
.c-ellipse-top::before {
    position: absolute;
    content: " ";
    min-height: 42px;
    border-left: 2px solid #9E9E9E;
    left: 47%;
    bottom: 28px;
}
.c-ellipse-bottom::after {
    position: absolute;
    content: " ";
    min-height: 42px;
    border-left: 2px solid #9E9E9E;
    left: 47%;
    top: 28px;
}
.c-ellipse-line {
  left: 50%;
  min-height: 120px;
  border-left: 2px solid #9E9E9E;
  position: relative;
}
.c-add-new-conditions {
    position: relative;
    margin: 0px auto;
    width: 166px;
    top: -26px;
}
.c-edit-btn {
    background: #FFFFFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    width: 48px;
    height: 48px;
    position: absolute;
    right: -6%;
    top: 50%;
}
.c-conditions-btn.v-btn.v-btn--disabled {
    opacity: 0.5;
    background-color: #FFFFFF !important;
    color: #2962FF !important;
}
.c-trigger-error {
    border: 1px solid #FFC400 !important;
    box-shadow: 0px 0px 4px 1px rgba(255, 229, 127, 0.48) !important;
}
</style>

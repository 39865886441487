<template>
    <!-- <div :class="['u-rounded-corners', dense ? 'grey lighten-5' : 'white u-shadow']"> -->
    <a-sheet :class="['c-custom-editor u-relative u-rounded-corners c-transition', { 'transparent': !is_focused }, focus_class]" @mouseup="!hideFocus ? local_set_focus() : local_focus_editor()" style="overflow-y: hidden !important;">
        <!-- Editor view -->
        <a-sheet :class="[editorClass, { 'px-4': !readOnly }]" class="c-custom-editor--editor-parent transparent u-overflow-y py-1" :min-height="is_focused ? editorMinHeight : customMinHeight" :max-height="is_focused ? editorHeight : customMaxHeight" :style="[canUpdate ? { cursor: 'text' } : '']">
            <!--<div @click="local_get_xy" :class="['primary--text md-body-1 c-transition u-relative', !is_focused ? 'px-0' : (dense ? 'py-0' : 'py-0')]" style="min-height: auto; line-height: 24px !important; cursor: text">-->
            <div @click="local_get_xy" :class="['md-body-1 c-transition u-relative', textColor]" style="min-height: auto; line-height: 24px !important; cursor: text">
                <editor-content :editor="editor" />
            </div>
        </a-sheet>

        <a-dialog v-model="dialog_link" width="500" transition="slide-y-transition" origin="top center" :overlay-opactiy="0.1">
            <a-card class="pa-6">
                <h2 class="md-subtitle-1 grey--text text--darken-3 mb-3">Insert or Update Link</h2>
                <div class="u-relative">
                    <a-text-field
                        v-model="link_model_value"
                        placeholder="Ex: https://success.app"
                        class="u-border u-rounded-corners"
                        @input="local_validate_url(link_model_value)"
                        solo flat autofocus hide-details
                    ></a-text-field>
                    <span class="u-absolute" style="bottom: -24px; left: 0px;" v-if="url_error">
                        <a-icon size="16" color="red darken-1">info</a-icon>
                        <span class="ml-1 red--text text--darken-1 md-caption">{{ url_error }}</span>
                    </span>
                </div>
                <span class="u-flex-center-y" :class="[url_error ? 'mt-8' : 'mt-6']">
                    <a-btn small depressed class="primary white--text mr-2" @click="local_run_command('setLink')" :disabled="!!url_error">Update</a-btn>
                    <a-btn small depressed class="grey--text text--darken-1" @click="dialog_link = false">Cancel</a-btn>
                    <a-spacer></a-spacer>
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-btn v-on="on" small icon dense class="grey--text text--darken-1 grey lighten-5" :disabled="!link_model_value || !(link_model_value && link_model_value.trim())" @click="local_run_command('unsetLink')">
                                <a-icon size="18">link_off</a-icon>
                            </a-btn>
                        </template>
                        <span>Unlink</span>
                    </a-tooltip>
                </span>
            </a-card>
        </a-dialog>

        <a-menu v-model="dialog_popover" :close-on-click="false" nudge-bottom="12" offset-y bottom :position-x="posX" :position-y="posY" absolute transition="slide-y-transition" :close-on-content-click="false" activator="c-linker" v-if="editor && editor.getAttributes('link') && editor.getAttributes('link').href">
            <a-sheet class="d-inline-flex align-center u-elevation-custom-1 u-rounded-corners px-3 py-1" min-height="32">
                <a-icon size="16" color="grey darken-1" class="u-icon-nudge-xs" style="top: 1px">public</a-icon>
                <a
                    target="_blank"
                    class="md-body-2 d-block blue--text text--darken-4 mx-2"
                    :href="editor.getAttributes('link').href"
                    :title="editor.getAttributes('link').href && editor.getAttributes('link').href.length > 25 ? editor.getAttributes('link').href : ''"
                    rel="noopener noreferrer nofollow"
                >{{ editor.getAttributes('link').href | truncateText(25) }}</a>
                <a-spacer></a-spacer>
                <div class="u-flex-center-y">
                    <a-btn v-clipboard:copy="editor.getAttributes('link').href" v-clipboard:success="local_link_cpy_msg" small icon dense>
                        <a-icon size="16" color="grey darken-1">content_copy</a-icon>
                    </a-btn>
                    <a-btn @click="local_open_link_dialog('edit-mode')" small icon dense class="mx-1">
                        <a-icon size="16" color="grey darken-1">edit</a-icon>
                    </a-btn>
                    <a-btn @click="local_run_command('unsetLink')" small icon dense>
                        <a-icon size="16" color="grey darken-1">link_off</a-icon>
                    </a-btn>
                </div>
            </a-sheet>
        </a-menu>

        <!-- Format bar -->
        <template v-if="!hideFooter">
            <!-- <div v-if="editor" :class="['u-flex-center-y white u-wfull c-transition', absoluteFormatter ? 'u-absolute' : 'u-sticky', dense ? 'pr-4' : 'pr-6']" :style="[ { borderTop: '0px solid transparent' }, { height: '0px', bottom: '0px' }, is_focused ? local_format_bar_focus_class : '']"> -->
            <div v-if="editor && (is_focused || hideFocus)" :class="['u-flex-center-y white u-wfull c-transition', absoluteFormatter ? 'u-absolute' : 'u-sticky', dense ? 'pr-4' : 'pr-6']" :style="[ { borderTop: '0px solid transparent' }, { height: '0px', bottom: '0px' }, local_format_bar_focus_class]">
                <template v-if="canUpdate">
                    <slot name="format-title" v-bind:execute="editor" v-bind:isActive="editor.isActive(local_heading_level)">
                        <div key="format-title" style="border-right: 1px solid #efefef">
                            <a-menu v-model="menu_header" :close-on-content-click="false" :disabled="!canUpdate" top offset-y content-class="editor-menu-popup">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">
                                        <a-sheet :min-height="local_format_height" v-ripple="canUpdate" :class="['u-flex-center u-cursor-pointer transparent', dense ? 'px-4' : 'px-6', { 'is-active': local_heading_level }]" :min-width="50">
                                            <span style="min-width: 34px;" :class="['md-subtitle-1 font-weight-medium text-capitalize', local_heading_level ? 'indigo--text text--darken-2' : 'body--text text--lighten-2']">{{ local_heading_level ? local_heading_level : 'Text' }}</span>
                                            <a-spacer></a-spacer>
                                            <a-icon size="20" color="body lighten-2" class="ml-1">arrow_drop_{{ menu_header ? 'up' : 'down' }}</a-icon>
                                        </a-sheet>
                                    </div>
                                </template>
                                <a-sheet class="u-flex-center-y">
                                    <template v-for="(title, index) in titles">
                                        <span @click="local_set_title_format(title)" v-ripple="canUpdate" :class="['md-subtitle-1 u-cursor-pointer font-weight-medium text-capitalize pa-2 d-inline-block', local_title_active_color(title)]" :key="index">{{ title }}</span>
                                    </template>
                                </a-sheet>
                            </a-menu>
                        </div>
                    </slot>
                    <div key="format-text" class="u-flex-center-y" style="border-right: 1px solid #efefef">
                        <slot name="format-bold" v-bind:execute="editor" v-bind:isActive="editor.isActive('bold')">
                            <a-sheet :min-height="local_format_height" :class="[local_format_icon_styles, { 'is-active': editor.isActive('bold') }, local_units]" v-ripple="canUpdate" @click="local_run_command('toggleBold')">
                                <a-icon :size="iconSize" :color="local_show_active_color('bold')">format_bold</a-icon>
                            </a-sheet>
                        </slot>
                        <slot name="format-italic" v-bind:execute="editor" v-bind:isActive="editor.isActive('italic')">
                            <a-sheet :min-height="local_format_height" :class="[local_format_icon_styles, { 'is-active': editor.isActive('italic') }, local_units]" v-ripple="canUpdate" @click="local_run_command('toggleItalic')">
                                <a-icon :size="iconSize" :color="local_show_active_color('italic')">format_italic</a-icon>
                            </a-sheet>
                        </slot>
                        <slot name="format-underline" v-bind:execute="editor" v-bind:isActive="editor.isActive('underline')">
                            <a-sheet :min-height="local_format_height" :class="[local_format_icon_styles, { 'is-active': editor.isActive('underline') }, local_units]" v-ripple="canUpdate" @click="local_run_command('toggleUnderline')">
                                <a-icon :size="iconSize" :color="local_show_active_color('underline')">format_underline</a-icon>
                            </a-sheet>
                        </slot>
                        <slot name="format-underline" v-bind:execute="editor" v-bind:isActive="editor.isActive('strike')">
                            <a-sheet :min-height="local_format_height" :class="[local_format_icon_styles, { 'is-active': editor.isActive('strike') }, local_units]" v-ripple="canUpdate" @click="local_run_command('toggleStrike')">
                                <a-icon :size="iconSize" :color="local_show_active_color('strike')">strikethrough_s</a-icon>
                            </a-sheet>
                        </slot>
                    </div>
                    <div key="format-list" class="u-flex-center-y">
                        <slot name="format-orderedlist" v-bind:execute="editor" v-bind:isActive="editor.isActive('orderedList')">
                            <a-sheet :min-height="local_format_height" :class="[local_format_icon_styles, { 'is-active': editor.isActive('orderedList') }, local_units]" v-ripple="canUpdate" @click="local_run_command('toggleOrderedList')">
                                <a-icon :size="iconSize" :color="local_show_active_color('orderedList')">format_list_numbered</a-icon>
                            </a-sheet>
                        </slot>
                        <slot name="format-bulletlist" v-bind:execute="editor" v-bind:isActive="editor.isActive('bulletList')">
                            <a-sheet :min-height="local_format_height" :class="[local_format_icon_styles, { 'is-active': editor.isActive('bulletList') }, local_units]" v-ripple="canUpdate" @click="local_run_command('toggleBulletList')">
                                <a-icon :size="iconSize" :color="local_show_active_color('bulletList')">format_list_bulleted</a-icon>
                            </a-sheet>
                        </slot>
                        <slot name="format-link" v-bind:execute="editor" v-bind:isActive="editor.isActive('link')">
                            <a-sheet :min-height="local_format_height" :class="[local_format_icon_styles, { 'is-active': editor.isActive('link') }, local_units]" v-ripple="canUpdate" @click="local_open_link_dialog()">
                                <a-icon :size="iconSize" :color="local_show_active_color('link')">{{ editor.isActive('link') ? 'link_off' : 'link' }}</a-icon>
                            </a-sheet>
                        </slot>
                        <slot name="emoji">
                            <a-menu v-model="emoji_dropdown" offset-y bottom left :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                    <a-sheet v-on="on" :min-height="local_format_height" :class="[local_format_icon_styles, local_units]" v-ripple="canUpdate">
                                        <a-icon :size="iconSize" color="body lighten-2">insert_emoticon</a-icon>
                                    </a-sheet>
                                </template>
                                <Picker :data="emojiIndex" set="facebook" @select="local_add_emoji" />
                            </a-menu>
                        </slot>
                        <slot name="format-codeblock" v-bind:execute="editor" v-bind:isActive="editor.isActive('codeBlock')">
                            <a-sheet :min-height="local_format_height" :class="[local_format_icon_styles, { 'is-active': editor.isActive('codeBlock') }, local_units]" v-ripple="canUpdate" @click="local_run_command('toggleCodeBlock')">
                                <a-icon :size="iconSize" :color="local_show_active_color('codeBlock')">code</a-icon>
                            </a-sheet>
                        </slot>
                    </div>
                    <a-spacer key="spacer"></a-spacer>
                    <slot name="format-charcount" v-bind:count="char_count" v-bind:limit="charLimit">
                        <div key="format-charcount" v-if="(charLimit - char_count) <= -1  " :class="['u-flex-center-y red--text text--darken-1 red lighten-5 u-rounded-corners-full']" :style="[{ padding: dense ? '2px 6px' : '4px 8px' }]" v-test-label.description.error>
                        <!-- <div key="format-charcount" :class="['u-flex-center-y u-rounded-corners-full']" :style="[{ padding: dense ? '2px 6px' : '4px 8px' }]"> -->
                            <a-icon :size="dense ? 16 : 20" color="red darken-1" class="">error</a-icon>
                            <!-- <a-progress-circular size="20" :value="(char_count / charLimit) * 100" :color="char_count > charLimit ? 'red darken-2' : 'deep-orange lighten-2'"></a-progress-circular> -->
                            <!-- <span :class="['u-flex-center', char_count > charLimit ? 'red--text text--darken-1' : 'grey--text text--darken-1']"> -->
                            <span :class="['u-flex-center', 'red--text text--darken-1']">
                                <span class="md-caption text-center text-center" style="min-width: 30px;">{{ char_count }}</span>
                                <span class="md-caption text-center">/</span>
                                <span class="md-caption text-center" style="min-width: 30px;">{{ charLimit }}</span>
                            </span>
                        </div>
                    </slot>
                </template>
                <template v-if="!canUpdate">
                    <span class="md-caption grey--text text--lighten-1" :class="[dense ? 'pl-4' : 'pl-6']">Format options are not available</span>
                    <a-spacer></a-spacer>
                </template>
                <template v-if="!hideCloseBtn">
                    <div v-if="!dense" @click="local_close_editor()" :class="['u-flex-center grey lighten-4 u-cursor-pointer ml-2 u-rounded-corners-full']" :style="[{ padding: '4px 12px' }]">
                        <span :class="['md-caption text-center', 'body--text text--darken-1']">Close</span>
                    </div>
                    <div v-else @click="local_close_editor()" :class="['u-flex-center grey lighten-4 u-cursor-pointer ml-2 u-rounded-corners-full']" :style="[{ padding: '2px 10px' }]">
                        <span :class="['md-caption text-center', 'body--text text--darken-1']">Close</span>
                    </div>
                </template>
            </div>
        </template>
    </a-sheet>
</template>

<script>
import { Editor, EditorContent, BubbleMenu, VueRenderer } from '@tiptap/vue-2'
import { generateHTML } from '@tiptap/html'
import { SEditorFormatBar } from '@/config/config-shared-components'
import tippy from 'tippy.js'

// Tiptap extensions
import Link from '@tiptap/extension-link'
import Mention from '@tiptap/extension-mention'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Underline from '@tiptap/extension-underline'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import Typography from '@tiptap/extension-typography'
import Placeholder from '@tiptap/extension-placeholder'
import HardBreak from '@tiptap/extension-hard-break'
import { SEmojiList } from '@/config/config-shared-components'
import CodeBlock from '@tiptap/extension-code-block'

// Emoji config
import data from 'emoji-mart-vue-fast/data/facebook.json'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
const emojiIndex = new EmojiIndex(data)

export default {
    name: 'PartialEditorBox',

    inheritAttrs: false,

    props: {
        dense: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean | Object,
            default: null
        },
        modelIsOpen: {
            type: Boolean,
            default: false
        },
        value: {
            type: String | Object,
            required: true
        },
        textColor: {
            type: String,
            default: 'primary--text'
        },
        returnType: {
            type: String,
            default: 'json'
        },
        canUpdate: {
            type: Boolean,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        hideDivider: {
            type: Boolean,
            default: false
        },
        hideCloseBtn: {
            type: Boolean,
            default: false
        },
        editorHeight: {
            type: Number,
            default: 300
        },
        customMinHeight: {
            type: Number | String,
            default: 300
        },
        customMaxHeight: {
            type: Number | String,
            default: 300
        },
        editorClass: {
            type: Array,
            default: () => []
        },
        editorMinHeight: {
            type: Number | String,
            default: 120
        },
        absoluteFormatter: {
            type: Boolean,
            default: false
        },
        hardRefresh: {
            type: Boolean,
            default: false
        },
        hideFocus: {
            type: Boolean,
            default: false
        },
        hideBorder: {
            type: Boolean,
            default: false
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        iconSize: {
            type: String | Number,
            default: '20'
        },
        iconColor: {
            type: String,
            default: '#444'
        },
        charLimit: {
            type: Number,
            default: 50000
        },
        refresh: {
            type: Boolean,
            default: false
        },
    },

    components: { EditorContent, SEditorFormatBar, BubbleMenu, Picker },

    data () {
        return {
            emojiIndex: emojiIndex,
            emoji_dropdown: false,
            editor: null,
            dialog_link: false,
            dialog_popover: false,
            link_model_value: '',
            link_edit_mode: false,
            show_editor: false,
            menu_header: false,
            url_error: null,
            url_length: 2000,
            selected_heading: 'text',
            focus_class: 'u-border',
            char_count: 0,
            is_focused: false,
            show_more_desc: false,
            titles: ['text', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
            posX: 0,
            posY: 0
        }
    },

    watch: {
        dialog_popover (val) {
            setTimeout(() => {
                if (!val && this.editor.isActive('link')) this.dialog_popover = true
            }, 0)
        },

        modelIsOpen (val) {
            if (val) this.local_index()
        },

        hideFocus (val) {
            if (val) {
                this.char_count = this.editor.state.doc.textContent.length
            }
        },

        refresh (val) {
            if (val) this.local_index()
        },

        value: {
            handler (val) {
                if (this.hardRefresh) this.local_index()
            },
            deep: true
        }
    },

    mounted () {
        document.addEventListener('keyup', this.local_click_outside)
        this.local_index()
    },

    beforeDestroy () {
        document.removeEventListener('keyup', this.local_click_outside)
        if (this.editor) this.editor.destroy()
    },

    computed: {
        local_return_data () {
            return this.returnType === 'json' ? this.editor.getJSON() : this.editor.getHTML()
        },

        local_tiptap_packs () {
            return [StarterKit, Document, Underline, ListItem, BulletList, OrderedList, Link, Mention, HardBreak, CodeBlock]
        },

        local_editor_content () {
            const emptyText = { type: 'doc', content: [] }
            const value = !this.value ? emptyText : ((typeof this.value) === 'string' ? JSON.parse(this.value) : this.value)
            return generateHTML(value, this.local_tiptap_packs)
        },

        local_heading_level () {
            let isLevel = false
            if (this.editor) {
                for (const iterator of [...Array(6).keys()]) {
                    const hasLevel = this.editor.isActive('heading', { level: iterator + 1 })
                    const hasPara = this.editor.isActive('paragraph')
                    if (hasLevel) isLevel = 'h' + (iterator + 1)
                    if (hasPara) isLevel = 'text'
                }
            }
            return isLevel
        },

        local_format_icon_styles () {
            const cursorPointer = this.canUpdate ? 'u-cursor-pointer' : ''
            return 'transparent u-flex-center ' + cursorPointer
        },

        local_units () {
            let padding = 'px-5'
            if (this.dense) padding = 'px-3'
            return padding
        },

        local_format_height () {
            let height = 48
            if (this.dense) height = 40
            return height
        },

        local_format_bar_focus_class () {
            return {
                height: `${this.local_format_height}px !important`,
                bottom: '0px !important',
                borderTop: '1px solid #efefef !important'
            }
        },

        local_container_focus_class () {
            return {
                marginBottom: '48px !important',
                paddingLeft: '2px',
                paddingRight: '2px',
            }
        },

        local_get_desc () {
            const element = document.createElement('div')
            const emptyText = { type: 'doc', content: [] }
            const content = !this.value || (this.value && !this.value.trim()) ? emptyText : JSON.parse(this.value)
            const desc = generateHTML(content, this.local_tiptap_packs)
            element.innerHTML = desc
            return element
        },

        local_is_more () {
            const { innerHTML, innerText } = this.local_get_desc
            const getLength =  innerHTML.split('\n').length ?? null
            return (getLength && getLength > 5) || innerText.length > 966
        },

        local_tippy_start_props () {
            return {
                appendTo: () => document.body,
                showOnCreate: true, interactive: true, trigger: 'manual', placement: 'bottom-start',
            }
        }
    },

    methods: {
        local_index () {
            if (this.editor) this.editor.destroy()
            this.localEditorSetup()
            this.char_count = 0
            // if (this.dense) this.local_set_focus()
        },

        local_validate_json(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },

        localEditorSetup () {
            const limit = this.charLimit
            this.editor = new Editor({
                editorProps: {
                    handleTextInput(view) {
                        if (view.state.doc.textContent.length >= limit && view.state.selection.empty) return false
                    },
                    handlePaste(view, event, slice) {
                        if (view.state.doc.textContent.length + slice.size > limit) {
                            slice.content = new DocumentFragment()
                        }
                    }
                },
                extensions: [
                    StarterKit, Document, Underline, ListItem, BulletList, OrderedList, Typography, CodeBlock,
                    Link.configure({
                        HTMLAttributes: { class: 'c-linker' },
                        openOnClick: false
                    }),
                    Placeholder.configure({
                        // placeholder: this.canUpdate ? 'Write something down...' : 'Description not added...',
                        placeholder: 'Write something down...',
                        showOnlyWhenEditable: true
                    }),
                    Mention.configure({
                        HTMLAttributes: { class: 'c-emoji' },
                        suggestion: {
                            char: ':',
                            command: ({ editor, range, props }) => {
                                editor.chain().focus().insertContentAt(range, props.label).run()
                            },
                            items: this.local_search_emojis,
                            render: this.local_emoji_rendering,
                        },
                    })
                ],
                autofocus: this.autofocus ?? false,
                content: this.local_editor_content,
                editable: this.canUpdate && !this.readOnly,
                parseOptions: { preserveWhitespace: 'full' },
                onUpdate: ({ editor }) => {
                    const textCount = editor.state.doc.textContent.length
                    this.char_count = textCount
                    this.local_update()
                },
                onFocus: ({ editor }) => !this.hideFocus ? this.local_set_focus(false) : ''
            })
        },

        local_update () {
            this.$emit('input', this.local_return_data)
            this.$emit('update', this.local_return_data)
        },

        local_set_focus(set_editor_focus = true) {
            if (!this.canUpdate) return
            if (set_editor_focus && this.editor) this.editor.commands.focus()
            this.is_focused = true
            this.$emit('isFocused', this.is_focused)
            if (!this.hideBorder) this.focus_class = this.dense ? 'u-border' : 'white u-shadow'
            this.char_count = this.editor.state.doc.textContent.length
        },

        local_focus_editor () {
            if (!this.editor) return
            this.editor.commands.focus()
        },

        local_set_title_format (title) {
            if (!this.canUpdate) return
            title !== 'text' ?
                this.editor.chain().focus().toggleHeading({ level: Number(title.split('h')[1]) }).run() :
                this.editor.chain().focus().setParagraph().run()
        },

        local_get_xy (evt) {
            this.dialog_popover = false
            this.posX = evt.clientX
            this.posY = evt.clientY
            setTimeout(() => {
                if (this.editor.isActive('link')) this.dialog_popover = true
                else this.dialog_popover = false
            }, 400)
        },

        local_open_link_dialog (mode) {
            // if (mode !== 'edit-mode') this.dialog_popover = false
            this.url_error = null
            if (!this.canUpdate) return
            const previousUrl = this.editor.getAttributes('link').href
            this.link_edit_mode = mode ?? false
            this.link_model_value = previousUrl ?? ''
            this.dialog_link = true
        },

        local_run_command (command) {
            if (!this.canUpdate) return
            const execute = this.editor.chain().focus()
            switch (command) {
                case 'setLink':
                case 'unsetLink': return this.local_link_execute(command, execute)
                case 'toggleBold': return execute.toggleBold().run()
                case 'toggleItalic': return execute.toggleItalic().run()
                case 'toggleUnderline': return execute.toggleUnderline().run()
                case 'toggleStrike': return execute.toggleStrike().run()
                case 'toggleBulletList': return execute.toggleBulletList().run()
                case 'toggleOrderedList': return execute.toggleOrderedList().run()
                case 'toggleOrderedList': return execute.toggleOrderedList().run()
                case 'toggleCodeBlock': return execute.toggleCodeBlock().run()
            }
        },

        local_link_execute (command, execute) {
            this.url_error = null
            const previousUrl = this.editor.getAttributes('link').href
            this.link_model_value = this.link_edit_mode ? this.link_model_value : (previousUrl ?? this.link_model_value)
            if (!this.local_validate_url(this.link_model_value)) return
            this.dialog_link = true
            if (this.link_model_value === null) return
            if (this.link_model_value === '' || command === 'unsetLink') {
                this.link_model_value = ''
                this.link_edit_mode = false
                this.local_link_dialogs_reset()
                return execute.extendMarkRange('link').unsetLink().run()
            }
            execute.extendMarkRange('link').setLink({ href: this.link_model_value, target: '_blank' }).run()
            this.local_link_dialogs_reset()
        },

        local_validate_url (url) {
            this.url_error = null
            const regex = new RegExp('(http(s)?:\/\/)(www\.)?([-a-zA-Z0-9@:%._\+~#=]{2,256})(\.[-a-zA-Z0-9@:%_\+~#=]{2,256}){1,5}(\/[-a-zA-Z0-9@:%_\+~#=]{2,256}){0,4}', 'gi')
            // const regex = new RegExp('(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)', 'gi')
            // const regex = new RegExp('(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)', 'i')
            // const regex = new RegExp('(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)', 'gi')
            // const regex = new RegExp('(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)', 'gi')
            const isValid = regex.test(url)
            const maxLengthBreach = url && url.length > this.url_length

            if (!isValid) this.url_error = 'Not a valid URL'
            if (maxLengthBreach) this.url_error = `Maximum of ${ this.url_length } characters`
            return isValid && !maxLengthBreach
        },

        local_link_dialogs_reset () {
            this.dialog_link = false
            this.dialog_popover = false
        },

        local_title_active_color (title) {
            return this.local_heading_level === title ? 'indigo--text text--darken-2 grey lighten-3' : 'body--text text--lighten-2'
        },

        local_show_active_color (type) {
            return this.editor.isActive(type) ? 'indigo darken-2' : 'body lighten-2'
        },

        local_link_cpy_msg (evt, link) {
            this.$notify('success', 'Link Copied!')
        },

        local_click_outside (evt) {
            if (this.dense) return
            evt.preventDefault()
            evt.stopPropagation()
            // if (evt.keyCode === 27 && evt.key === 'Escape' && !this.dense && !this.modelIsOpen) {
            //     this.local_close_editor()
            // }
        },

        async local_close_editor () {
            if (this.editor) this.editor.commands.blur()
            setTimeout(() => {
                this.is_focused = false
                this.menu_header = false
                this.focus_class = ''
                this.$emit('isFocused', this.is_focused)
            }, 0)
        },

        // Emoji methods
        local_add_emoji (emoji) {
            this.editor.chain().focus().insertContent(emoji.native).run()
            this.emoji_dropdown = false
        },

        local_search_emojis (query) {
            return Object.keys(this.emojiIndex._emojis)
                        .map(item => {
                            const emojiValue = this.emojiIndex._emojis
                            const searchText = item.toLowerCase().startsWith(query.toLowerCase())
                            if (searchText) return emojiValue[item]
                        })
                        .filter(i => i !== undefined)
                        .slice(0, 5)
        },

        local_emoji_rendering () {
            let component
            let popup

            return {
                onStart: props => {
                    component = new VueRenderer(SEmojiList, { parent: this, propsData: props })
                    popup = tippy('body', {
                        getReferenceClientRect: props.clientRect,
                        content: component.element,
                        ...this.local_tippy_start_props
                    })
                },

                onUpdate (props) {
                    component.updateProps(props)
                    popup[0].setProps({ getReferenceClientRect: props.clientRect })
                },

                onKeyDown (props) {
                    if (props.event.key === 'Escape') return popup[0].hide()
                    return component.ref?.onKeyDown(props)
                },

                onExit () {
                    popup[0].destroy()
                    component.destroy()
                },
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.is-active {
    transition: 0.4s ease-in-out;
    color: #3949AB !important;
    // @apply t-text-gray-200 t-bg-gray-800 t-transition t-duration-500 t-ease-in-out;
    // .v-icon {
    //     color: #fff !important;
    // }
}

.c-transition {
    transition: 0.2s all !important;
}
</style>

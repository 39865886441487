<template>
    <v-form v-on="$listeners" v-bind="$attrs">
        <slot></slot>
    </v-form>
</template>

<script>
import { VForm } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VForm },

    data () {
        return {
            ref: null
        }
    },

    mounted () {
        this.ref = this.$el
    }
}
</script>

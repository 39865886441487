<template>
    <span v-bind="$attrs" class="md-caption d-block mt-2" :class="[hasError ?  'red--text text--darken-2' : 'grey--text text--darken-2']">
        <slot name="icon">
            <template v-if="showIconOnError">
                <a-icon v-if="showIconOnError && hasError" :size="iconSize" class="u-icon-nudge-xs mr-1" :color="hasError ? 'red darken-2' : 'grey darken-1'">{{ icon }}</a-icon>
            </template>
            <a-icon v-else :size="iconSize" class="u-icon-nudge-xs mr-1" :color="hasError ? 'red darken-2' : 'grey darken-1'">{{ icon }}</a-icon>
        </slot>
        <slot name="error-msg" v-if="hasError">{{ error }}</slot>
        <slot name="info" v-else></slot>
    </span>
</template>

<script>
export default {
    inheritAttrs: false,

    props: {
        icon: {
            type: String,
            default: 'info'
        },
        iconSize: {
            type: String,
            default: '16'
        },
        showIconOnError: {
            type: Boolean,
            default: false
        },
        error: {
            type: Object | Array
        },
        hasError: {
            type: String | Object | Boolean
        }
    }
}
</script>

<template>
    <editor-menu-bubble :editor="content" :keep-in-bounds="false" v-slot="{ commands, isActive, menu }">
        <div class="menububble" v-if="canUpdate" :class="{ 'is-active': menu.isActive }" :style="`left: ${menu.left - 16}px; bottom: ${menu.bottom + 8}px;`">
            <a-btn icon text small class="mx-0" :color="(isActive.bold()) ? 'blue' : 'grey'" @click="commands.bold">
                <a-icon size="16">format_bold</a-icon>
            </a-btn>
            <a-btn icon text small class="mx-0" :color="(isActive.italic()) ? 'blue' : 'grey'" @click="commands.italic">
                <a-icon size="16">format_italic</a-icon>
            </a-btn>
            <a-divider vertical dark></a-divider>
            <a-btn icon text small class="mx-0" :color="(isActive.bullet_list()) ? 'blue' : 'grey'" @click="commands.bullet_list">
                <a-icon size="16">format_list_bulleted</a-icon>
            </a-btn>
            <a-btn icon text small class="mx-0" :color="(isActive.ordered_list()) ? 'blue' : 'grey'" @click="commands.ordered_list">
                <a-icon size="16">format_list_numbered</a-icon>
            </a-btn>
        </div>
    </editor-menu-bubble>
</template>

<script>
import { EditorMenuBubble } from 'tiptap'

export default {
    name: 'SharedEditorMenu',
    components: { EditorMenuBubble },
    props: {
        content: {
            type: Object | String
        },
        canUpdate: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<template>
    <div class="mt-6">
        <g-settings-breadcrumb route-label="Board" previousRoute="dashboard-insights-type"></g-settings-breadcrumb>

        <a-container container--fluid grid-list-xl class="pl-0 pr-4 pt-7" style="margin-bottom: 96px;">
            <!-- Info -->
            <a-sheet class="u-elevation-custom-1 u-rounded-corners">
                <!-- Title -->
                <div class="u-flex-center-y pa-6 px-7 u-cursor-pointer" @click="local_info_toggle_expand()">
                    <v-slide-y-transition hide-on-leave leave-absolute>
                        <a-sheet class="loader-animate u-rounded-corners" height="32" width="300" v-if="local_loading"></a-sheet>
                        <h2 class="text-h5 font-weight-medium body--text text--darken-2" v-else>{{ tile_item.title }}</h2>
                    </v-slide-y-transition>
                    <a-spacer></a-spacer>
                    <a-btn icon small class="ma-0 pa-0 body lighten-2">
                        <a-icon size="20" color="white">expand_{{ is_expanded ? 'less' : 'more' }}</a-icon>
                    </a-btn>
                </div>
                <template v-if="is_expanded">
                    <a-divider class="grey lighten-3"></a-divider>

                    <!-- Options and Filters -->
                    <div class="px-7 pb-9" :class="[{ 'py-6 pt-7': local_loading }, !local_loading && tile_item.description ? 'py-6' : 'py-10']">
                        <v-slide-y-transition hide-on-leave leave-absolute>
                            <a-sheet class="loader-animate u-rounded-corners mb-8" height="24" width="400" v-if="local_loading"></a-sheet>
                            <template v-else>
                                <h4 class="md-subtitle-1 body--text text--lighten-1 mb-8" v-if="tile_item.description">{{ tile_item.description }}</h4>
                            </template>
                        </v-slide-y-transition>
                        <!-- Workspace and timeline -->
                        <div class="u-flex-center-y" :class="{ 'mt-2': !tile_item.description }">
                            <!-- Workspace -->
                            <a-sheet width="50%" class="u-border-light u-rounded-corners-lg pa-4 px-6 mr-3 u-relative">
                                <v-slide-y-transition group hide-on-leave leave-absolute>
                                    <div key="workspace-loader" class="u-absolute u-wfull u-hfull loader-animate" v-if="local_loading" style="top: 0px; left: 0px; z-index: 2"></div>
                                    <template v-else>
                                        <span key="workspace-title" class="u-absolute text-body-2 text-uppercase indigo--text text--darken-2 font-weight-medium d-inline-block white px-2" style="top: -12px; left: 16px; letter-spacing: 0.2px;">Workspace</span>
                                        <div key="workspace-info" class="u-flex-center-y">
                                            <a-icon size="18" color="green darken-2">check_circle</a-icon>
                                            <h4 class="md-subtitle-1 body--text text--darken-1 ml-2">{{ local_get_item_workspace }}</h4>
                                        </div>
                                    </template>
                                </v-slide-y-transition>
                            </a-sheet>
                            <!-- Timeline -->
                            <a-sheet width="50%" class="u-border-light u-rounded-corners-lg pa-4 px-6 ml-3 u-relative">
                                <v-slide-y-transition group hide-on-leave leave-absolute>
                                    <div key="timeline-loader" class="u-absolute u-wfull u-hfull loader-animate" v-if="local_loading" style="top: 0px; left: 0px; z-index: 2"></div>
                                    <template v-else>
                                        <span  key="timeline-title" class="u-absolute text-body-2 text-uppercase indigo--text text--darken-2 font-weight-medium d-inline-block white px-2" style="top: -12px; left: 16px; letter-spacing: 0.2px;">Timeline</span>
                                        <div key="timeline-list" class="u-flex-center-y">
                                            <a-icon size="18" color="green darken-2">check_circle</a-icon>
                                            <h4 class="md-subtitle-1 body--text text--darken-1 ml-2" v-if="local_get_item_timeline">{{ local_get_item_timeline.field }} / {{ local_get_item_timeline.category }}
                                                <span v-if="!start_date && !end_date" class="text-body-2 font-weight-medium body--text text--lighten-2 ml-1">
                                                    ({{ local_get_item_timeline.without_date }} {{ local_get_item_timeline.field }}<span v-if="local_get_item_timeline.without_date_slug === 'not_included'"> only</span>)
                                                </span>
                                                <span v-else class="text-body-2 font-weight-medium body--text text--lighten-2 ml-1">({{ start_date }} - {{ end_date }})</span>
                                            </h4>
                                        </div>
                                    </template>
                                </v-slide-y-transition>
                            </a-sheet>
                        </div>
                        <!-- Filters -->
                        <a-sheet v-if="tile_item.is_filters_deleted === 1 || tile_item && tile_item.tile_filters && tile_item.tile_filters.length || local_loading" class="u-border-light u-rounded-corners-lg pa-4 px-6 mt-8 u-relative">
                            <v-slide-y-transition group hide-on-leave leave-absolute>
                                <div key="filter-loader" class="u-absolute u-wfull u-hfull loader-animate" v-if="local_loading" style="top: 0px; left: 0px; z-index: 2"></div>
                                <template v-else>
                                    <span key="filters-title" class="u-absolute text-body-2 text-uppercase indigo--text text--darken-2 font-weight-medium d-inline-block white px-2" style="top: -12px; left: 16px; letter-spacing: 0.2px;">Filters</span>
                                    <template v-if="tile_item && tile_item.tile_filters">
                                        <div class="u-flex-center-y" :class="[{ 'mt-2': index !== 0}]" v-for="(filter, index) in tile_item.tile_filters" :key="filter.id">
                                            <a-icon v-if="filter.is_value_deleted === 0 && !local_check_disable_item(filter.key) " size="18" color="green darken-2">check_circle</a-icon>
                                            <a-tooltip bottom v-else>
                                                <template v-slot:activator="{ on }">
                                                    <a-icon v-on="on" size="18" color="yellow darken-3">error</a-icon>
                                                </template>
                                                <span v-if="filter.is_value_deleted === 1" class="py-3">Filter condition ignored, as the selected value is deleted.</span>
                                                <span v-else class="py-3">Filter condition ignored, as the selected field is disabled</span>
                                            </a-tooltip>
                                            <div class="u-flex-center-y ml-2">
                                                <h4 class="md-subtitle-1 body--text text--darken-2" :class="{'text-capitalize' : !local_custom_field_and_tag(filter)}">{{ local_get_operator_text(filter.key, filter.operator, 'key') }} <span class="text-capitalize">{{ local_check_disable_item(filter.key)? '(Disabled)' : '' }}</span></h4>
                                                <h4 class="md-subtitle-1 body--text text--lighten-1 text-lowercase" style="margin-left: 4px">{{ local_get_operator_text(filter.key, filter.operator) }}</h4>
                                                <template v-if="!['empty', 'not-empty'].includes(filter.operator)">
                                                    <h4 v-if="tag_type_modules.includes(filter.key)" class="md-subtitle-1 body--text text--darken-2" style="margin-left: 4px">
                                                        <g-tags :text-range="20" :tag="local_filter_value_list(filter.value, filter.key, filter.operator)" hide-clear-icon></g-tags>
                                                    </h4>
                                                    <h4 v-else-if="filter.key === 'competitor_id'" class="md-subtitle-1 body--text text--darken-2" style="margin-left: 4px">
                                                        <span class="md-caption d-inline-block py-1 px-2 mr-2 mt-1 mb-1 u-rounded-corners u-cursor-pointer" :style="{backgroundColor: '#2196f3', color: '#fff'}">
                                                            {{ local_filter_value_list(filter.value, filter.key, filter.operator) | truncateText(14) }}
                                                        </span>
                                                    </h4>
                                                    <h4 v-else-if="local_custom_tag_type_module(filter)" class="md-subtitle-1 body--text text--darken-2" style="margin-left: 4px">
                                                        <div v-if="filter.is_value_deleted === 1">
                                                            <span
                                                                style="padding: 2px 8px; fontSize: 13px; fontWeight: 500"
                                                                class="d-inline-flex align-center mr-2 u-rounded-corners-full u-cursor-pointer blue-grey--text blue-grey lighten-5"
                                                            >
                                                                {{filter.recently_deleted}}
                                                            </span>(Deleted)
                                                        </div>
                                                        <g-tags v-else :text-range="20" :tag="local_filter_value_list(filter.value, filter.key, filter.operator)" hide-clear-icon></g-tags>
                                                    </h4>
                                                    <template v-else>
                                                        <h4 class="md-subtitle-1 body--text text--darken-2" style="margin-left: 4px">{{ local_filter_value_list(filter.value, filter.key, filter.operator) }}</h4>
                                                        <!-- <h4 class="md-subtitle-1 body--text text--darken-2" style="margin-left: 4px">{{  ? `${filter.recently_deleted} (Deleted Value)` : '' }}</h4> -->
                                                        <h4 class="md-subtitle-1 body--text text--darken-2" v-if="filter.value_alt" style="margin-left: 4px"><span class="text-lowercase">and</span> {{ local_filter_value_list(filter.value_alt, filter.key, filter.operator) }}</h4>
                                                        <h4 v-if="filter.is_value_deleted === 1" class="md-subtitle-1 body--text text--darken-2" style="margin-left: 0px">{{ filter.recently_deleted }} (Deleted)</h4>
                                                    </template>
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="tile_item.is_filters_deleted === 1">
                                        <div class="u-flex-center-y mt-1" :class="[{ 'mt-1': index !== 0}]" v-for="(item, index) in deleted_tile_filters" :key="index">
                                            <a-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <a-icon v-on="on" size="18" color="yellow darken-3">error</a-icon>
                                                </template>
                                                <span class="py-3">Filter condition ignored, as the selected field is deleted</span>
                                            </a-tooltip>
                                            <div class="u-flex-center-y ml-2">
                                                <h4 class="md-subtitle-1 body--text text--darken-2 text-capitalize">{{ item.field_name}} (Deleted)</h4>
                                                <h4 class="md-subtitle-1 body--text text--lighten-1 text-lowercase" style="margin-left: 4px">{{ item.operator }}</h4>
                                                <h4 class="md-subtitle-1 body--text text--darken-2 text-lowercase" style="margin-left: 4px">{{ item.field_type === 'dropdown' ? item.field_label : item.field_value }}</h4>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </v-slide-y-transition>
                        </a-sheet>
                    </div>
                </template>
            </a-sheet>

            <a-layout align-center class="mt-6" v-if="!(tile_detailed_list && !tile_detailed_list.length && local_scope_message)">
                <a-flex>
                    <a-btn elevated text color="grey darken-1 u-rounded-corners" class="ma-0 white u-elevation-custom-1" :disabled="local_loading" @click="local_open_presets_popup()">
                        <a-icon left size="16" class="mr-2">tune</a-icon> Customize Fields
                    </a-btn>
                </a-flex>
                <a-flex shrink>
                    <a-menu offset-y left bottom>
                        <template v-slot:activator="{ on }">
                            <a-btn text color="grey darken-1 u-rounded-corners" class="ma-0 white u-elevation-custom-1" v-on="on" :disabled="local_loading">
                                <a-icon left size="16" class="mr-2">save_alt</a-icon> Download <a-icon right size="18" class="ml-1">arrow_drop_down</a-icon>
                            </a-btn>
                        </template>
                        <a-list>
                            <a-list-item @click="local_download_csv()">
                                <a-list-item-title class="md-body-2">Download as CSV</a-list-item-title>
                            </a-list-item>
                            <a-list-item @click="local_download_excel()">
                                <a-list-item-title class="md-body-2">Download as Excel</a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
            </a-layout>

            <a-dialog max-width="520" max-height="300" scrollable persistent v-model="show_field_preference_list">
                <a-card>
                    <a-card-title class="pa-0">
                        <div style="width: 100%;">
                            <a-container container--fluid grid-list-xl class="px-3 py-6">
                                <a-layout wrap align-center class="">
                                        <div class="u-flex">
                                            <a-icon class="primary--text text--darken-1 pl-4" dark>tune</a-icon>
                                            <h2 class="md-heading-6 primary--text text--darken-1 pl-2">Customize Columns</h2>
                                        </div>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <div class="px-4 py-2 u-cursor-pointer" @click="local_toggle_all_fields(true)">
                                <div class="d-flex justify-space-between align-center">
                                    <h4 class="text-body-1 font-weight-medium grey--text text--darken-3">Fields</h4>
                                    <a-sheet class="rounded-circle pr-1">
                                        <a-icon v-if="local_choose" size="20" color="indigo darken-1" >check_circle</a-icon>
                                        <a-icon v-else size="20" color="grey darken-1" >radio_button_unchecked</a-icon>
                                    </a-sheet>
                                </div>
                                <div v-if="fields_count === 0" class="u-flex-center-y amber lighten-5 px-2 py-1 u-rounded-corners-lg mb-2">
                                    <a-icon color="yellow darken-3" size="20">error</a-icon>
                                    <h3 class="md-subtitle-2 font-weight-medium grey--text text--darken-2 ml-2">
                                        Choose at least one field to save the columns.
                                    </h3>
                                </div>
                            </div>
                            <a-divider></a-divider>
                        </div>
                    </a-card-title>
                    <a-card-text class="pa-0">
                        <draggable
                            v-model="table_headers"
                            :options="{handle: '.js-drag-handle'}"
                            draggable=".js-draggable-file-list-item"
                            ghost-class="ghost"
                            v-bind="local_drag_options"
                        >
                            <transition-group type="transition" name="flip-list" >
                                <div v-for="(item, index) in table_headers" :key="item.value" class="js-draggable-file-list-item white">
                                    <a-divider v-if="index !== 0"></a-divider>
                                    <a-container container--fluid grid-list-xl class="py-3 u-cursor-pointer js-drag-handle" @click="local_select_field(item, !item.show)">
                                        <div class="d-flex justify-space-between align-center">
                                            <div class="d-flex align-center">
                                                <div style="paddingBottom: 2px">
                                                    <a-icon class="grey--text text--lighten-1" size="20">drag_indicator</a-icon>
                                                </div>
                                                <!-- <h2 class="md-subtitle-1 grey--text text--darken-1 pl-2">{{ item.text !== ' ' ? item.text : 'Health' }}</h2> -->
                                                <h2 v-if="item.type === 'competitors_type'" class="md-subtitle-1 d-block text-truncate grey--text text--darken-1">{{ local_get_text(item.text, '(Competitor)') | truncateText(item.text_length ? item.text_length : 40) }} <span class="md-subtitle-2 ml-1 grey--text">&lpar;Competitor&rpar;</span></h2>
                                                <h2 v-else class="md-subtitle-1 d-block text-truncate grey--text text--darken-1">{{ item.text | truncateText(item.text_length ? item.text_length : 40) }}</h2>
                                            </div>
                                            <div>
                                                <a-icon v-if="item.show" size="20" color="indigo darken-1">check_circle</a-icon>
                                                <a-icon v-else size="20" color="grey darken-1">radio_button_unchecked</a-icon>
                                            </div>
                                        </div>
                                    </a-container>
                                </div>
                            </transition-group>
                        </draggable>
                    </a-card-text>
                    <a-divider class="grey lighten-3"></a-divider>
                    <a-sheet class="py-4 px-4">
                        <div class="u-flex align-center justify-space-between">
                            <div class="u-flex">
                                <a-btn color="indigo-darken-1" class="indigo darken-1 white--text u-rounded-corners elevation-0 pa-1 py-0 md-subtitle-2 text-uppercase" :disabled="fields_count === 0" @click="local_save_presets()">Save</a-btn>
                                <a-btn depressed  text class="ml-4 grey lighten-3 grey--text text--darken-1 pa-2 u-rounded-corners md-subtitle-2 text-uppercase" @click="local_close_presets()">
                                    Cancel
                                </a-btn>
                            </div>
                            <div class="text-body-2 indigo--text">{{ fields_count }} field(s) selected</div>
                        </div>
                    </a-sheet>
                </a-card>
            </a-dialog>

            <!-- Projects list table -->
            <a-sheet class="mt-6 mb-6 u-overflow-x u-elevation-custom-1 u-rounded-corners" style="maxHeight: 77vh;">
                    <table class="c-review-changes-table u-wfull " style="borderCollapse: separate" >
                        <tr v-if="tile_detailed_list && tile_detailed_list.length && !local_loading_projects" style="position: sticky; top: 0; backgroundColor: white; zIndex: 2;">
                            <template v-for="header in headers">
                                <!-- <template v-if="header.slug === 'custom_tags' && header.show">
                                    <template v-for="tag_section in tag_section_list" >
                                        <template v-if="tag_section.is_active === 1">
                                            <th :key="tag_section.id" class="stickytop py-3 px-6 text-body-2 font-weight-bold indigo--text text--darken-3 u-cursor-pointer" :class="[header.align]"
                                                :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '2px solid #EEEEEE' }]"
                                                @click="localSortTable(header)"
                                            >
                                                <div class="u-flex-center-y">
                                                    <span class="d-block text-truncate">{{ tag_section.name | truncateText(header.text_length ? header.text_length : 40) }}</span>
                                                    <template v-if="header.sortable">
                                                        <a-icon v-if="sortBy === header.value" class="ml-1" size="16" color="grey darken-3">
                                                            arrow_{{ sortDesc ? 'downward' : 'upward' }}
                                                        </a-icon>
                                                        <a-icon v-else class="ml-1" size="16" color="grey lighten-1">
                                                            arrow_upward
                                                        </a-icon>
                                                    </template>
                                                </div>
                                            </th>
                                        </template>
                                    </template>
                                </template> -->
                                <!-- <template v-if="header.slug === 'system_tags' && header.show">
                                    <template >
                                        <th v-for="tag_section in system_tag_sections" :key="tag_section.slug" class="stickytop py-3 px-6 text-body-2 font-weight-bold indigo--text text--darken-3 u-cursor-pointer" :class="[header.align]"
                                            :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '2px solid #EEEEEE' }]"
                                            @click="localSortTable(header)"
                                        >
                                            <div class="u-flex-center-y">
                                                <span class="d-block text-truncate">{{ tag_section.text | truncateText(header.text_length ? header.text_length : 40) }}</span>
                                                <template v-if="header.sortable">
                                                    <a-icon v-if="sortBy === header.value" class="ml-1" size="16" color="grey darken-3">
                                                        arrow_{{ sortDesc ? 'downward' : 'upward' }}
                                                    </a-icon>
                                                    <a-icon v-else class="ml-1" size="16" color="grey lighten-1">
                                                        arrow_upward
                                                    </a-icon>
                                                 </template>
                                            </div>
                                        </th>
                                    </template>
                                </template> -->
                                <th :key="header.slug" class="stickytop py-3 px-6 text-body-2 font-weight-bold indigo--text text--darken-3 u-cursor-pointer" :class="[header.align]"
                                    :style="[{minWidth: header.width !== 'auto' ? header.width !== 'auto' ? header.width + 'px' : header.width : header.width}, { borderBottom: '2px solid #EEEEEE' }]"
                                    v-if="header.show"
                                    @click="localSortTable(header)"
                                >
                                    <div class="u-flex-center-y">
                                        <span v-if="header.type === 'competitors_type'" class="d-block text-truncate">{{ local_get_text(header.text, '(Competitor)') | truncateText(header.text_length ? header.text_length : 40) }} <span class="md-subtitle-2 grey--text ml-1">&lpar;Competitor&rpar;</span></span>
                                        <span v-else class="d-block text-truncate">{{ header.text | truncateText(header.text_length ? header.text_length : 40) }}</span>
                                        <template v-if="header.sortable">
                                            <a-icon v-if="sortBy === header.value" class="ml-1" size="16" color="grey darken-3">
                                                arrow_{{ sortDesc ? 'downward' : 'upward' }}
                                            </a-icon>
                                            <a-icon v-else class="ml-1" size="16" color="grey lighten-1">
                                                arrow_upward
                                            </a-icon>
                                        </template>
                                    </div>
                                </th>
                            </template>
                        </tr>
                        <template v-if="local_loading_projects">
                            <tr v-for="i in 6" :key="i">
                                <td v-for="j in 11" :key="j" class="py-3 px-6">
                                    <a-sheet width="100" height="16" class="loader-animate u-rounded-corners"></a-sheet>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="100" class="text-center py-8" v-if="tile_detailed_list && !tile_detailed_list.length && local_scope_message">
                                    <a-avatar color="yellow darken-3" size="64">
                                        <a-icon size="32" color="white">error</a-icon>
                                    </a-avatar>
                                    <h3 class="md-subtitle-1 font-weight-medium body--text text--lighten-1 mt-3">There are no projects to display <br> Kindly re-check your configuration</h3>
                                </td>
                                <td colspan="100" class="text-center py-8"  v-if="tile_detailed_list && !tile_detailed_list.length && checkscope_message">
                                    <a-avatar color="yellow darken-3" size="64">
                                        <a-icon size="32" color="white">error</a-icon>
                                    </a-avatar>
                                    <h3 class="md-subtitle-1 font-weight-medium body--text text--lighten-1 mt-3">There are no projects available to display. <br> <span class="md-subtitle-1">The view may affected due to role based access control. Please check your configuration or contact your admin.</span> </h3>
                                </td>
                            </tr>
                            <tr v-for="project in tile_detailed_list" :key="project.id">
                                <template v-for="header in headers">
                                    <td :key="header.slug" v-if="header.slug === 'customer' && header.show" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]" >
                                        {{ project.customer ? project.customer.name : '-' | truncateText(20) }}
                                    </td>
                                    <td :key="header.slug" v-if="header.slug === 'title' && header.show" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]" >
                                        <router-link target="_blank" :to="{name: 'projects-overview', params: {id: project.id}}" class="blue--text text--darken-3 u-text-no-underline">{{ project.title | truncateText(40) }}</router-link>
                                    </td>
                                    <td :key="header.slug" v-if="header.slug === 'deal_amount' && header.show" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                        <g-currency tag="span" v-if="project.deal_amount" :value="project.deal_amount"></g-currency>
                                        <template v-else> - </template>
                                    </td>
                                    <td :key="header.slug" v-if="header.slug === 'health_color' && header.show" class="py-3 px-6 body--text text--darken-1 text-body-2 text-capitalize" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                        <div class="u-flex align-center">
                                            <a-icon v-if="!project.health_color || project.health_color === 'none'" class="" color="grey lighten-2" size="16">fiber_manual_record</a-icon>
                                            <a-icon v-if="project.health_color === 'green'" class="" color="green" size="16">fiber_manual_record</a-icon>
                                            <a-icon v-if="project.health_color === 'yellow'" class="" color="orange" size="16">fiber_manual_record</a-icon>
                                            <a-icon v-if="project.health_color === 'red'" class="" color="red" size="16">fiber_manual_record</a-icon>
                                            <span class="ml-2">{{ project.health_color }}</span>
                                        </div>
                                    </td>
                                    <td :key="header.slug" v-if="header.slug === 'stage' && header.show" :title="project.stage ? project.stage.name : ''" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                        {{ project.stage ? project.stage.name : '-' | truncateText(20) }}
                                    </td>
                                    <td :key="header.slug" v-if="header.slug === 'status' && header.show" class="py-3 px-6 body--text text--darken-1 text-body-2 text-capitalize" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                        {{ project.status }}
                                    </td>
                                    <td :key="header.slug" v-if="header.slug === 'result' && header.show" :title="project.result ? project.result.value : ''" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                        {{ project.result ? project.result.value : '-' | truncateText(20) }}
                                    </td>
                                    <td :key="header.slug" v-if="header.slug === 'partner' && header.show" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                        {{ project.partner ? project.partner.name : '-' | truncateText(20) }}
                                    </td>
                                    <!-- <template v-if="header.slug === 'system_tags' && header.show">
                                        <template v-for="tag_section in system_tag_sections">
                                            <td :key="tag_section.slug" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                                <span v-if="!local_get_project_tags(project.tags, tag_section.slug)">–</span>
                                                <template v-else>
                                                    <g-tags :text-range="14" v-for="tag in local_get_project_tags(project.tags, tag_section.slug)" :tag="tag" :key="tag.id" hide-clear-icon></g-tags>
                                                </template>
                                            </td>
                                        </template>
                                    </template> -->
                                    <!-- <template v-if="header.slug === 'custom_tags' && header.show">
                                        <template v-for="tag_section in tag_section_list">
                                            <template v-if="tag_section.is_active === 1">
                                                <td :key="tag_section.id" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                                    <span v-if="!local_get_project_tags(project.tags, tag_section.slug)">–</span>
                                                    <template v-else>
                                                        <g-tags :text-range="14" v-for="tag in local_get_project_tags(project.tags, tag_section.slug)" :tag="tag" :key="tag.id" hide-clear-icon></g-tags>
                                                    </template>
                                                </td>
                                            </template>
                                        </template>
                                    </template> -->
                                    <td :key="header.slug" v-if="header.slug === 'territories' && header.show" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                        <span v-if="project.territories && !project.territories.length">–</span>
                                        <!-- <template v-else>
                                            <g-tags :text-range="14" v-for="tag in project.territories" :tag="tag" :key="tag.id" hide-clear-icon></g-tags>
                                        </template> -->
                                        <partial-tag-list v-else :list="project.territories" rows='2' :reference="project.id + '_' + header.slug"/>
                                    </td>
                                    <td :key="header.slug" v-if="header.type === 'competitors_type' && header.show" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                        <span v-if="!local_get_competitor_types_tags(project.competitors, header.id)">–</span>
                                        <partial-tag-list v-else :competitor="true" :list="local_get_competitor_types_tags(project.competitors, header.id)" rows='2' :reference="project.id + '_' + header.slug"/>
                                    </td>
                                    <!-- <td :key="header.slug" v-if="header.slug === 'competitors' && header.show" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                        <span v-if="project.competitors && !project.competitors.length">–</span>
                                        <template v-else>
                                            <span v-for="(competitor) in project.competitors" class="md-caption d-inline-block py-1 px-2 mr-2 mt-1 mb-1 u-rounded-corners u-cursor-pointer" :key="competitor.id + project.id" :style="{backgroundColor: '#2196f3', color: '#fff'}">
                                                {{ competitor.name | truncateText(14) }}
                                            </span>
                                       </template>
                                        <partial-tag-list v-else :competitor="true" :list="project.competitors" rows='2' :reference="project.id + '_' + header.slug"/>
                                     </td>  -->
                                    <td :key="header.slug" v-if="header.slug === 'start_date' && header.show" class="py-3 px-6 body--text text--darken-1 text-body-2 text-left" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                        <g-moment v-if="project.start_date" tag="span" :value="project.start_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                        <template v-else> - </template>
                                    </td>
                                    <td :key="header.slug" v-if="header.slug === 'due_date' && header.show" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                        <g-moment v-if="project.due_date" tag="span" :value="project.due_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                        <template v-else> - </template>
                                    </td>
                                    <td :key="header.slug" v-if="header.slug === 'estimated_start_date' && header.show" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                        <g-moment v-if="project.estimated_start_date" tag="span" :value="project.estimated_start_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                        <template v-else> - </template>
                                    </td>
                                    <td :key="header.slug" v-if="header.slug === 'estimated_end_date' && header.show" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                        <g-moment v-if="project.estimated_end_date" tag="span" :value="project.estimated_end_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                        <template v-else> - </template>
                                    </td>
                                    <template v-if="header.type === 'collaborators' && header.show">
                                        <td :key="header.slug" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                            <template v-if="project.collaborators && project.collaborators.length">
                                                <s-users-deck :avatar-size="26" font-size="10" v-if="local_get_collab_value(header.id, project.collaborators).length" class="justify-end" :list="local_get_collab_value(header.id, project.collaborators)" :count="4" hide-role hide-list></s-users-deck>
                                                <template v-else><span class="ml-2"> - </span></template>
                                            </template>
                                            <template v-else><span class="ml-2"> - </span></template>
                                        </td>
                                    </template>
                                    <template v-if="header.type === 'custom_tag' && header.show">
                                        <td :key="header.slug" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                            <span v-if="!local_get_project_tags(project.tags, header.slug)">–</span>
                                            <!-- <template v-else>
                                                <g-tags :text-range="14" v-for="tag in local_get_project_tags(project.tags, header.slug)" :tag="tag" :key="tag.id" hide-clear-icon></g-tags>
                                            </template> -->
                                            <partial-tag-list v-else :list="local_get_project_tags(project.tags, header.slug)" rows='2' :reference="project.id + '_' + header.slug"/>
                                        </td>
                                    </template>
                                    <template v-if="header.slug === 'label' && header.show">
                                        <td :key="header.slug" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                            <span v-if="!local_get_project_tags(project.tags, header.slug)">–</span>
                                            <partial-tag-list v-else :list="local_get_project_tags(project.tags, header.slug)" rows='2' :reference="project.id + '_' + header.slug"/>
                                        </td>
                                    </template>
                                    <template v-if="header.slug === 'product' && header.show">
                                        <td :key="header.slug" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                            <span v-if="!local_get_project_tags(project.tags, header.slug)">–</span>
                                            <!-- <template v-else>
                                                <g-tags :text-range="14" v-for="(tag, i) in local_get_project_tags(project.tags, header.slug)" :tag="tag" :key="tag.id + i" hide-clear-icon></g-tags>
                                            </template> -->
                                            <partial-tag-list v-else :list="local_get_project_tags(project.tags, header.slug)" rows='2' :reference="project.id + '_' + header.slug"/>
                                        </td>
                                    </template>
                                    <template v-if="header.slug === 'platform' && header.show">
                                        <td :key="header.slug" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]">
                                            <span v-if="!local_get_project_tags(project.tags, header.slug)">–</span>
                                            <!-- <template v-else>
                                                <g-tags :text-range="14" v-for="tag in local_get_project_tags(project.tags, header.slug)" :tag="tag" :key="tag.id" hide-clear-icon></g-tags>
                                            </template> -->
                                            <partial-tag-list v-else :list="local_get_project_tags(project.tags, header.slug)" rows='2' :reference="project.id + '_' + header.slug"/>
                                        </td>
                                    </template>
                                    <template v-if="header.type === 'custom_field' && header.show">
                                        <td :key="header.slug + '1'" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]" v-if="header.custom_field_type === 'url'">
                                            <a v-if="local_get_custom_field_value(header.id, project.custom_field_values)" class="blue--text text--darken-2" :href="local_get_custom_field_value(header.id, project.custom_field_values)" target="_blank" rel="nofollow noopener" :title="local_get_custom_field_value(header.id, project.custom_field_values)">
                                                {{ local_get_custom_field_value(header.id, project.custom_field_values) | truncateText(32) }}
                                            </a>
                                            <span v-else> - </span>
                                        </td>
                                        <td :key="header.slug + '2'" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE' }]" v-else-if="header.custom_field_type === 'multiline'">
                                            <a-menu v-if="local_get_custom_field_value(header.id, project.custom_field_values)" :close-on-content-click="false" open-on-hover content-class="u-elevation-custom-1 c-border" max-width="400" max-height="400" nudge-left="20" offset-x top>
                                                <template v-slot:activator="{ on }">
                                                    <span class="blue--text text--darken-1 md-caption u-rounded-corners" v-on="on">View {{header.text}}</span>
                                                </template>
                                                <a-card class="px-4 pb-2 u-rounded-corners">
                                                    <div class="pt-4 white" style="position: sticky; top: 0; white-space: pre-wrap;" >
                                                        <div class="u-flex align-center">
                                                            <h2 class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase">{{header.text}}</h2>
                                                            <a-spacer></a-spacer>
                                                            <router-link tag="a" class="blue--text text--darken-2 text-uppercase font-weight-bold u-flex align-center md-caption" v-if="$can('projects.update-only')" :to="{name: 'projects-overview', params: {id: project.id}}">Edit</router-link>
                                                        </div>
                                                        <a-divider class="grey lighten-2 mt-2 mb-3"></a-divider>
                                                        {{ local_get_custom_field_value(header.id, project.custom_field_values) }}
                                                    </div>
                                                </a-card>
                                            </a-menu>
                                            <span v-else> - </span>
                                        </td>
                                        <td :key="header.slug + '3'" class="py-3 px-6 body--text text--darken-1 text-body-2" :class="[header.align]" :title="local_get_custom_field_value(header.id, project.custom_field_values)" :style="[{minWidth: header.width !== 'auto' ? header.width + 'px' : header.width}, { borderBottom: '1px solid #EEEEEE'}]" v-else>
                                            {{ local_get_custom_field_value(header.id, project.custom_field_values) ? local_get_custom_field_value(header.id, project.custom_field_values) : ' - ' | truncateText(24) }}
                                        </td>
                                    </template>
                                </template>
                            </tr>
                        </template>
                    </table>
            </a-sheet>

            <!-- Pagination bar -->
            <a-layout class="mx-0 c-sticky-paginate-parent u-elevation-custom-1 u-rounded-corners" v-if="tile_detailed_list && tile_detailed_list.length && !local_loading_projects" :class="{ 'pa-4': !local_show_paginate_section }">
                <a-flex class="u-flex align-center py-0">
                    <div class="text-body-2 grey--text text--darken-2 font-weight-medium mr-2 d-inline-block" v-if="tile_meta">Showing {{ tile_meta.from }} - {{ tile_meta.to }} out of {{ tile_meta.total }} project{{ tile_meta.total > 1 ? 's' : '' }}</div>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex class="mr-16 pr-16 py-0 u-flex align-center justify-end" v-if="local_show_paginate_section">
                    <a-progress-circular v-if="pagination_loading" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                    <div class="text-right">
                        <a-pagination
                            v-model="tile_meta.current_page"
                            :length="tile_meta.last_page"
                            :total-visible="10"
                            class="c-inventory-pagination py-1"
                            @input="local_paginate()"
                        ></a-pagination>
                    </div>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog max-width="480px" v-model="dialog_download_link" persistent>
            <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                <a-progress-linear class="my-0" height="3" color="orange darken-3" :indeterminate="true" v-if="loading"></a-progress-linear>
                <a-card-text class="pa-2">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout align-start>
                            <a-flex shrink>
                                <a-icon size="72" color="grey lighten-1">cloud_download</a-icon>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 mb-2">
                                    <span v-if="loading">Preparing file...</span>
                                    <span >Ready to Download</span>
                                </h2>
                                <p class="md-body-2 mb-0 grey--text text--darken-1">
                                    <span v-if="loading">Success is preparing your report for download. It may take few seconds to get ready.</span>
                                    <span v-else>Your report is ready to download. Click on the "Save File" button to download the report.</span>
                                </p>
                                <a-btn :href="tile_links" small class="elevation-0 px-3 ma-0 mt-3" color="primary"  @click="local_download_save()">
                                    <a-icon size="14" class="mr-2">save</a-icon>
                                    Save File
                                </a-btn>
                                <a-btn class="elevation-0 px-3 ma-0 mt-3 ml-2" color="grey" small text @click="dialog_download_link = !dialog_download_link" >Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import EntityOperators from '@/helpers/helper-insights-entity-operators'
import loadWorkspace from '@/mixins/mixin-workspace'
import { SUsersDeck } from '@/config/config-shared-components'
import checkScopePermission from '@/mixins/mixin-checkscope-permission'
import PartialTagList from './Partials/PartialTagList.vue'

export default {
    mixins: [loadWorkspace, checkScopePermission],

    components: { SUsersDeck, PartialTagList },

    data () {
        return {
            local_loading: true,
            local_loading_projects: true,
            pagination_loading: false,
            local_page_count: null,
            is_expanded: true,
            projects_count: 25,
            start_date: '',
            end_date: '',
            tile_filters: {},
            collaborator_type_list: [],
            result_meta_list: [],
            tag_type_modules: [ 'label_tag_id', 'product_tag_id', 'platform_tag_id', 'territory_id' ],
            system_tag_sections: [{ text: 'Label', slug: 'label' }, { text: 'Product', slug: 'product' }, { text: 'Platform', slug: 'platform' }],
            headers: [
                { text: 'Account', align: 'text-left', sortable: true, order: 1, value: 'customer.name', slug: 'customer', show: true, width: 220 },
                { text: 'Title', align: 'text-left', sortable: true, order: 2, value: 'title', slug: 'title', show: true, width: 350 },
                { text: 'Deal Amount', align: 'text-left', sortable: true, order: 3, value: 'deal_amount', slug: 'deal_amount', show: true, width: 150 },
                { text: 'Health', align: 'text-left', sortable: true, order: 4, value: 'health_color', slug: 'health_color', show: true, width: 120 },
                { text: 'Stage', align: 'text-left', sortable: true, order: 5, value: 'stage.order', slug: 'stage', show: true, width: 200 },
                { text: 'Status', align: 'text-left', sortable: true, order: 6, value: 'status_value', slug: 'status', show: true, width: 120 },
                { text: 'Result', align: 'text-left', sortable: true, order: 7, value: 'result.order', slug: 'result', show: true, width: 200 },
                { text: 'Partner', align: 'text-left', sortable: true, order: 8, value: 'partner.name', slug: 'partner', show: true, width: 220 },
                { text: 'Label', slug: 'label', align: 'text-left', sortable: false, order: 9, value: 'label', show: true, width: 530 },
                { text: 'Product', slug: 'product', align: 'text-left', sortable: false, order: 10, value: 'product', show: true, width: 530},
                { text: 'Platform', slug: 'platform', align: 'text-left', sortable: false, order: 11, value: 'platform', show: true, width: 530 },
                // { text: 'Custom Tags', align: 'text-left', sortable: false, order: 10, value: 'custom_tags', slug: 'custom_tags', show: true, width: 420 },
                { text: 'Territories', align: 'text-left', sortable: false, order: 12, value: 'territories', slug: 'territories', show: true, width: 530 },
                // { text: 'Competitors', align: 'text-left', sortable: false, order: 13, value: 'competitors', slug: 'competitors', show: true, width: 380 },
                { text: 'Start Date', align: 'text-left', sortable: true, order: 13, value: 'start_date', slug: 'start_date', show: true, width: 200 },
                { text: 'End Date', align: 'text-left', sortable: true, order: 14, value: 'due_date', slug: 'due_date', show: true, width: 200 },
                { text: 'Est. Start Date', align: 'text-left', sortable: true, order: 15, value: 'estimated_start_date', slug: 'estimated_start_date', show: true, width: 200 },
                { text: 'Est. End Date', align: 'text-left', sortable: true, order: 16, value: 'estimated_end_date', slug: 'estimated_end_date', show: true, width: 200 }
            ],
            customize_headers: [
                { text: 'Account', align: 'text-left', sortable: true, order: 1, value: 'customer.name', slug: 'customer', show: true, width: 220 },
                { text: 'Title', align: 'text-left', sortable: true, order: 2, value: 'title', slug: 'title', show: true, width: 350 },
                { text: 'Deal Amount', align: 'text-left', sortable: true, order: 3, value: 'deal_amount', slug: 'deal_amount', show: true, width: 150 },
                { text: 'Health', align: 'text-left', sortable: true, order: 4, value: 'health_color', slug: 'health_color', show: true, width: 120 },
                { text: 'Stage', align: 'text-left', sortable: true, order: 5, value: 'stage.order', slug: 'stage', show: true, width: 200 },
                { text: 'Status', align: 'text-left', sortable: true, order: 6, value: 'status_value', slug: 'status', show: true, width: 120 },
                { text: 'Result', align: 'text-left', sortable: true, order: 7, value: 'result.order', slug: 'result', show: true, width: 200 },
                { text: 'Partner', align: 'text-left', sortable: true, order: 8, value: 'partner.name', slug: 'partner', show: true, width: 220 },
                // { text: 'System Tags', align: 'text-left', sortable: false, order: 9, value: 'system_tags', slug: 'system_tags', show: true, width: 420 },
                // { text: 'Custom Tags', align: 'text-left', sortable: false, order: 10, value: 'custom_tags', slug: 'custom_tags', show: true, width: 420 },
                { text: 'Label', slug: 'label', align: 'text-left', sortable: false, order: 9, value: 'label', show: true, width: 530 },
                { text: 'Product', slug: 'product', align: 'text-left', sortable: false, order: 10, value: 'product', show: true, width: 530 },
                { text: 'Platform', slug: 'platform', align: 'text-left', sortable: false, order: 11, value: 'platform', show: true, width: 530 },
                { text: 'Territories', align: 'text-left', sortable: false, order: 12, value: 'territories', slug: 'territories', show: true, width: 530 },
                // { text: 'Competitors', align: 'text-left', sortable: false, order: 13, value: 'competitors', slug: 'competitors', show: true, width: 380 },
                { text: 'Start Date', align: 'text-left', sortable: true, order: 13, value: 'start_date', slug: 'start_date', show: true, width: 200 },
                { text: 'End Date', align: 'text-left', sortable: true, order: 14, value: 'due_date', slug: 'due_date', show: true, width: 200 },
                { text: 'Est. Start Date', align: 'text-left', sortable: true, order: 15, value: 'estimated_start_date', slug: 'estimated_start_date', show: true, width: 200 },
                { text: 'Est. End Date', align: 'text-left', sortable: true, order: 16, value: 'estimated_end_date', slug: 'estimated_end_date', show: true, width: 200 }
            ],
            TILE_FILTERS: {
                PAGE: 'page',
                SORT: 'sort',
                COUNT: 'count',
                INCLUDE: 'include',
                CUSTOMER_FIELDS: 'fields[customer]',
                PARTNER_FIELDS: 'fields[partner]',
                STAGE_FIELDS: 'fields[stage]',
                RESULT_FIELDS: 'fields[result]',
                PROJECT_FIELDS: 'fields[projects]'
            },
            timeline_field_types: [
                { text: 'Start Date', value: 'start_date' },
                { text: 'End Date', value: 'due_date' },
                { text: 'Estimated Start Date', value: 'estimated_start_date' },
                { text: 'Estimated End Date', value: 'estimated_end_date' },
            ],
            timeline_date_options: [
                { text: 'This Month', value: 'current_month' },
                { text: 'Last Month', value: 'last_month' },
                { text: 'This Quarter', value: 'current_quarter' },
                { text: 'Last Quarter', value: 'last_quarter' },
                { text: 'This Year', value: 'current_year' },
                { text: 'Last Year', value: 'last_year' },
                { text: 'Select a specific date range', value: 'date_range' },
                { text: 'All Time', value: 'all_time' },
            ],
            timeline_without_date: [
                { text: 'Includes Projects without', value: 'included' },
                { text: 'Includes Projects with', value: 'not_included' }
            ],
            fields: {
                project: 'id,title,health_color,deal_amount,next_step_json,status,start_date,due_date,estimated_start_date,estimated_end_date,created_at,updated_at,workspace_id,stage_id,result_id,customer_id,partner_id,archived_at,updated_by_id',
                customer: 'id,name,domain',
                partner: 'id,name,domain',
                stage: 'id,name,status',
                workspace: 'id,title,is_active',
                result: 'id,status,value',
                status_update: 'id,description,type_id,created_by_id,created_at',
                status_update_type: 'id,value',
                status_update_by: 'id,name,initial,color,avatar',
                // collaborators: 'id,project_id,user_id,scope,type_id',
                // collaborators_user: 'id,name,initial,color,email',
                // collaborators_type: 'id,type,value,status',
            },
            project_fields: {
                PROJECT: 'fields[projects]',
                CUSTOMER: 'fields[customer]',
                PARTNER: 'fields[partner]',
                STAGE: 'fields[stage]',
                WORKSPACE: 'fields[workspace]',
                RESULT: 'fields[result]',
                STATUS_UPDATE: 'fields[status_update]',
                STATUS_UPDATE_TYPE: 'fields[latest_status_update.type]',
                STATUS_UPDATE_BY: 'fields[latest_status_update.created_by]',
                // COLLABORATORS: 'fields[keyCollaborators]',
                // COLLABORATOR_USERS: 'fields[keyCollaborators.user]',
                // COLLABORATOR_TYPES: 'fields[keyCollaborators.type]',
            },
            filter: {
                START_DATE: 'filter[start_date]',
                DUE_DATE: 'filter[due_date]',
                EST_START: 'filter[estimated_start_date]',
                EST_END: 'filter[estimated_end_date]',
                CUSTOMER: 'filter[customer_id]',
                ACCNT_EXEC: 'filter[account_executive_id]',
                SALES_ENGG: 'filter[sales_engineer_id]',
                COLLABORATORS: 'filter[collaborators_group]',
                RESULT: 'filter[result_id]',
                WORKSPACE: 'filter[workspace_id]',
                STAGE: 'filter[stage_id]',
                STATUS: 'filter[status]',
                COMPETITORS: 'filter[competitor_group]',
                PARTNERS: 'filter[partner_id]',
                // REPORTS_STATUS: 'reports_status',
                // INVOLVMENT: 'filter[involvement]',
                TAGS: 'filter[tag_group]',
                TERRITORIES: 'filter[territory]',
                TIMELINE: 'filter[timeline]',
                CUSTOM_FIELD: 'filter[custom_field]',
                HEALTH_COLOR: 'filter[health_color]',
                PAGE: 'page',
                COUNT: 'count',
                SORT: 'sort',
                DOWNLOAD: 'download',
                FIELDS: 'fields[project]',
                // ATTRS: 'attributes',
                RELATIONSHIPS: 'include',
                // COUNTS: 'filter[counts]',
                HIERARCHY: 'filter[hierarchy_report]'
            },
            custom_fields: {},
            custom_tags: {},
            sortDesc: true,
            sortBy: 'title',
            show_field_preference_list: false,
            drag : false,
            choose_all_fields: false,
            dialog_download_link: false,
            deleted_tile_filters: [],
            competitor_types_list: [],
            competitor_slug_copy: []
        }
    },

    async mounted () {
        await this.local_index()
    },
    watch: {
        sortBy: {
            handler (newData) {
                const filters = this.tile_filters
                const dataSortOrder = !this.sortDesc ? '-' : ''
                if (this.loading) return
                filters[this.filter.SORT] = newData
                filters[this.filter.SORT] = (filters[this.filter.SORT] && filters[this.filter.SORT].charAt(0) && filters[this.filter.SORT].charAt(0) === '-') ? filters[this.filter.SORT].substring(1) : filters[this.filter.SORT]
                filters[this.filter.SORT] = `${dataSortOrder}${filters[this.filter.SORT]}`
                this.local_sort_project_list()
            },
            deep: true
        },
        sortDesc: {
            handler (data) {
                const filters = this.tile_filters
                const dataSortOrder = !data ? '-' : ''
                if (this.loading) return
                filters[this.filter.SORT] = this.sortBy
                filters[this.filter.SORT] = filters[this.filter.SORT].charAt(0) === '-' ? filters[this.filter.SORT].substring(1) : filters[this.filter.SORT]
                filters[this.filter.SORT] = `${dataSortOrder}${filters[this.filter.SORT]}`
                this.local_sort_project_list()
            },
            deep: true
        },
    },

    computed: {
        local_tile_id () {
            return this.$route.params.tile_id
        },

        local_get_item_workspace () {
            if (this.tile_item && this.tile_item.tile_options) {
                const workspaceOption = this.tile_item.tile_options.find(item => item.key === 'workspace_id')
                const workspace = this.workspace_search_list.find(item => item.id === workspaceOption.value)
                return (workspace?.title) ?? ''
            }
        },

        local_get_item_timeline () {
            if (this.tile_item && this.tile_item.tile_options) {
                const timelineItems = this.tile_item.tile_options.filter(item => item.group === 'timeline')
                const timelineField = this.timeline_field_types.find(item => item.value === _.find(timelineItems, { key: 'field' })['value'])
                const timelineCategory = this.timeline_date_options.find(item => item.value === _.find(timelineItems, { key: 'category' })['value'])
                const timelineWithoutDate = (timelineItems.find(item => item.key === 'without_date')) ? this.timeline_without_date.find(item => item.value === _.find(timelineItems, { key: 'without_date' })['value']) : null
                return { field: (timelineField?.text) ?? '', category: (timelineCategory?.text) ?? '', category_slug: (timelineCategory?.value) ?? '', without_date: (timelineWithoutDate?.text) ?? '', without_date_slug: (timelineWithoutDate?.value) }
            }
        },

        local_show_paginate_section () {
            return this.tile_meta && this.tile_meta.current_page >= 1 && this.tile_meta.last_page !== 1 && this.tile_meta.total > this.projects_count
        },

        local_current_fiscal_month () {
            return this.user_self?.organization?.financial_month
        },

        local_currency_symbol () {
            return this.user_self && this.user_self.organization && this.user_self.organization.currency && this.user_self.organization.currency.symbol
        },

        local_currency_format () {
            const currency = this.user_self?.organization?.currency
            if (currency.code === 'EUR') return 'en-EU'
            if (currency.code === 'INR') return 'en-IN'
            if (currency.code === 'GBP') return 'en-GB'
            if (currency.code === 'USD') return 'en-US'
            return 'en-US'
        },

        table_headers: {
            get () {
                return _.sortBy(this.customize_headers, ['order'])
            },
            set (list) {
                this.local_table_field_reorder(list)
            }
        },

        local_selected_headers() {
            return _.sortBy(_.filter(this.table_headers, 'show'), ['order'])
        },

        project_filters: {
            get () {
                return this.$store.state.Project.filters
            },
            set (data) {
                this.$store.state.Project.filters = data
            }
        },

        local_choose() {
            for (let header of this.customize_headers) {
                if (!header.show) return false
            }
            return true
        },

        fields_count() {
            let count = 0
            for (let header of this.customize_headers) {
                if (header.show) count++
            }
            return count
        },

        loading: {
            get() {
                return this.$store.state.Interface.loader
            },
            set(loading) {
                this.$store.state.Interface.loader = loading
            }
        },

        local_drag_options () {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            }
        },

        local_get_custom_fields_and_tags () {
            let entity_properties = []
            this.custom_field_list.forEach((field) => {
                if (field.type !== 'mask'){
                    const entity_option = {
                        data_type: ['multiline','text'].includes(field.type) ? 'string' : field.type,
                        id: field.id,
                        key: `custom_field.${field.name}`,
                        label: field.label,
                        options: null
                    }
                    entity_properties.push(entity_option)
                }
            })
            this.tag_section_list.forEach((tag) => {
                const entity_option = {
                    data_type: 'dropdown',
                    id: tag.id,
                    key: tag.slug,
                    label: tag.name,
                    options: null
                }
                entity_properties.push(entity_option)
            })
            return entity_properties
        },

        ...mapState('Tile', {
            tile_item: 'item',
            tile_meta: 'meta',
            tile_detailed_list: 'detailed_list',
            tile_links: 'links',
        }),

        ...mapState('BoardEntity', {
            board_entity_list: 'list',
        }),

        ...mapState('Workspace', {
            workspace_search_list: 'search_list',
        }),

        ...mapState('Customer', {
            customer_list: 'list',
        }),

        ...mapState('Partner', {
            partner_list: 'list',
        }),

        ...mapState('Stage', {
            stage_list: 'list',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
        }),

        ...mapState('Competitor', {
            competitor_list: 'list',
        }),

        ...mapState('Territory', {
            territory_list: 'list',
        }),

        ...mapState('TagSection', {
            tag_section_list: 'list',
        }),

        ...mapState('CustomField', {
            custom_field_list: 'list',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('FiscalTimeline', {
            fiscal_timeline_list: 'list',
        }),

        ...mapState('ReportPreset', {
            report_preset_list: 'list',
            report_preset_item: 'item',
            report_preset_response: 'response',
        }),

        ...mapState('Project', {
            project_list: 'list',
            project_meta: 'meta',
            project_links: 'links',
        }),
    },

    methods: {
        async local_index () {
            // this.customize_headers = _.cloneDeep(this.headers)
            this.local_project_checkscope()
            await this.tile_clear_item()
            await this.local_fetch_workspaces()
            await this.tile_show({ id: this.local_tile_id, mode: 'show', params: { include: 'tile_options,tile_filters' } })
            this.tile_filters = _.cloneDeep(this.tile_item.tile_filters)
            this.local_check_deleted_tile_item()
            if (this.local_get_item_timeline) this.local_get_date_range(this.local_get_item_timeline.category_slug)
            await this.local_load_competitor_types()
            await this.local_load_details()
            await this.local_get_entities()
            await this.local_load_value_lists()
            await this.local_load_collaborator_types()
            await this.local_load_custom_fields()
            await this.local_load_custom_tag_sections()
            await this.report_preset_index({ 'filter[type]': 'insight' })
            this.local_page_count = this.tile_meta.current_page
            this.table_headers = this.customize_headers
            const isPresetPresent = this.report_preset_list.find(preset => preset.source_id === this.local_tile_id && preset.type === 'insight')
            if (isPresetPresent && isPresetPresent.type === 'insight') this.local_preset_table_fields(isPresetPresent.fields)
            this.local_loading_projects = false
            this.local_loading = false
        },

        local_preset_table_fields(table_fields) {
            const competitor_slug = table_fields.find(i => i.slug === 'competitors')
            const updatedFields = this.customize_headers.filter(item => {
                const field = table_fields.find(i => i.slug === item.slug)
                if (field) {
                    item.show = field.show
                    item.order = field.order
                    return item
                }
                return item
            })
            const sortedFields = _.sortBy(updatedFields, ['order'])
            if (competitor_slug) this.customize_headers = this.local_competitors_types_state_change(sortedFields, competitor_slug)
            this.customize_headers = _.sortBy(updatedFields, ['order'])
            this.headers = _.cloneDeep(this.customize_headers)
        },

        local_check_deleted_tile_item() {
            if (this.tile_item.is_filters_deleted === 1) {
                const deleted_tile_list = JSON.parse(this.tile_item.recent_deleted_filters)
                this.deleted_tile_filters.push(...deleted_tile_list)
            }
        },

        local_custom_field_and_tag(filter) {
            const key = filter.key
            return key.includes('custom_field') || key.includes('custom_tag_section')

        },

        local_custom_tag_type_module(filter) {
            if (filter.key.includes('custom_tag_section')) return true
            return false
        },

        async local_load_value_lists () {
            await this.customer_index({ 'sort': 'name', 'fields[customers]': 'id,name' })
            await this.partner_index({ 'sort': 'name', 'fields[partners]': 'id,name' })
            await this.stage_index({ 'sort': 'order', 'fields[stages]': 'id,name', 'filter[workspace_id]': this.current_workspace_id_mixin })
            await this.tag_index({ 'sort': 'order', 'fields[tags]': 'id,type,label,color' })
            await this.competitor_index({ 'sort': 'order', 'fields[competitors]': 'id,name' })
            await this.territory_index({ 'sort': 'order', 'fields[territories]': 'id,name,color' })
            await this.meta_index({ 'sort': 'order', 'filter[type]': 'project_result', 'fields[metas]': 'id,value' })
            this.result_meta_list = _.cloneDeep(this.meta_list)
        },

        async local_load_custom_fields () {
            await this.custom_field_index({
                'fields[custom_fields]': 'id,name,label,is_active,order,placeholder,type,options',
                // 'filter[is_active]': 1,
                'filter[resource_type]': 'Project',
            })
            this.local_add_custom_field_header()
        },

        local_add_custom_field_header () {
            let order = this.headers.length
            for (let i = 0; i < this.custom_field_list.length; i++) {
                if (this.custom_field_list[i].is_active === 1 && this.custom_field_list[i].type !== 'mask') {
                    const custom_field = this.custom_field_list[i]
                    const custom_field_item = { text: custom_field.label, align: 'text-left', sortable: false, value: 'custom_fields' + custom_field.id, slug: 'customfields_' + custom_field.id, custom_field_type: custom_field.type, order: order, default_order: order, show: true, id: custom_field.id, type: 'custom_field', width: 220, text_length: 18 }
                    this.headers.push(custom_field_item)
                    this.customize_headers.push(custom_field_item)
                    order = order + 1
                }
            }
        },

        local_add_custom_tag_header () {
            let order = this.headers.length
            for (let i = 0; i < this.tag_section_list.length; i++) {
                if (this.tag_section_list[i].is_active === 1) {
                    const custom_tag = this.tag_section_list[i]
                    const custom_tag_item = { text: custom_tag.name, align: 'text-left', sortable: false, value: 'custom_tag' + custom_tag.id, slug: custom_tag.slug, order: order, default_order: order, show: true, id: custom_tag.id, type: 'custom_tag', width: 530, text_length: 18 }
                    this.headers.push(custom_tag_item)
                    this.customize_headers.push(custom_tag_item)
                    order = order + 1
                }
            }
        },

        async local_load_collaborator_types () {
            await this.meta_index({
                'filter[type]': 'collaborator_type',
                'filter[is_active]': 1,
                'fields[metas]': 'id,type,value,status',
                'sort': 'order'
            })
            this.collaborator_type_list = _.cloneDeep(this.meta_list)
            this.local_add_collabs_header()
        },

        local_add_collabs_header () {
            let order = this.headers.length
            for (let i = 0; i < this.collaborator_type_list.length; i++) {
                const collab = this.collaborator_type_list[i];
                const collab_item = { text: collab.value, align: 'text-left', sortable: false, value: 'collaborators' + collab.id, slug: 'collaborators_' + collab.id, show: true, id: collab.id, type: 'collaborators', width: 200, text_length: 24 }
                this.headers.push(collab_item)
                this.customize_headers.push(collab_item)
                order = order + 1
            }
        },

        async local_load_competitor_types () {
            await this.meta_index({
                'filter[type]': 'competitor_type',
                'fields[metas]': 'id,value,status,selection_type',
                'sort': 'order'
            })
            this.competitor_types_list = _.cloneDeep(this.meta_list)
            this.local_add_competitor_types_header()
        },

        local_add_competitor_types_header () {
            let order = this.headers.length
            for (let i = 0; i < this.competitor_types_list.length; i++) {
                const competitor_type = this.competitor_types_list[i];
                const competitor_item = { text: competitor_type.value + ' (Competitor)', align: 'text-left', sortable: false, value: competitor_type.value + competitor_type.id, slug: 'competitors_' + competitor_type.id, show: true, id: competitor_type.id, type: 'competitors_type', width: 530, text_length: 35, order: order }
                this.headers.push(competitor_item)
                this.customize_headers.push(competitor_item)
                this.competitor_slug_copy.push(competitor_item)
                order = order + 1
            }
        },

        local_competitors_types_state_change (headers, competitor_slug) {
            headers = headers.filter(i => i.type !== "competitors_type")
            let headers1 = headers.slice(0, competitor_slug.order)
            let headers2 = headers.slice(competitor_slug.order)
            let new_order = competitor_slug.order
            this.competitor_slug_copy.forEach(c => {
                c.order = new_order
                c.show = competitor_slug.show
                headers1.push(c)
                // headers2.pop()
                new_order = c.order + 1
            })
            headers2.forEach(i => i.order = i.order + new_order)
            return [...headers1, ...headers2]
        },

        local_get_text(text, val) {
            if (text.includes(val)) {
                const filteredText = text.replaceAll(val, '')
                return filteredText.trim()
            }
            return text
        },

        local_get_competitor_types_tags(compList, compTypeId) {
            const filtered_comp = compList.filter(c => c.category_id === compTypeId)
            return filtered_comp && filtered_comp.length ? filtered_comp : 0
        },

        async localSortTable (header) {
            if (!header.sortable) return
            this.tile_meta.current_page = 1
            await this.local_paginate()
            this.sortDesc = !this.sortDesc
            this.sortBy = header.value
        },

        async local_table_field_reorder(list) {
            this.customize_headers = list.filter((item, index) => {
                item.order = index
                return item
            })
        },

        local_select_field(item, v) {
            item.show = v
        },

        async local_save_presets() {
            const isPresetPresent = this.report_preset_list.find(preset => preset.source_id === this.local_tile_id && preset.type === 'insight')
            this.report_preset_item.name = this.tile_item.title
            this.report_preset_item.filters = { ...this.tile_filters, ...this.project_filters}
            if (isPresetPresent) isPresetPresent.source_id === this.local_tile_id ? await this.local_field_preference_update(isPresetPresent.id) : null
            else await this.local_report_preset_store()
            this.show_field_preference_list = false
            this.headers = _.cloneDeep(this.customize_headers)
        },

        local_close_presets() {
            this.customize_headers = _.cloneDeep(this.headers)
            this.show_field_preference_list = false
        },

        local_open_presets_popup() {
            this.show_field_preference_list = true
            for (let header of this.customize_headers) {
                if (!header.show) {
                    this.choose_all_fields = false
                    return
                }
            }
            this.choose_all_fields = true
        },

        async local_report_preset_store() {
            await this.report_preset_store({
                type: 'insight',
                name: this.tile_item.title ,
                filters: { ...this.tile_filters, ...this.project_filters},
                fields: this.customize_headers,
                source_id: this.local_tile_id,
                source_type: 'tile'
            })
            if (this.report_preset_response.status === 'error') return
        },

        async local_field_preference_update(id) {
            await this.local_set_preset_table_fields()
            this.report_preset_update({id: id})
        },

        async local_set_preset_table_fields() {
            const data = this.customize_headers.map(item => ({ text: item.text, slug: item.slug, order: item.order, show: item.show }))
            this.headers = this.customize_headers
            this.report_preset_item.fields = data
        },

        local_toggle_all_fields(bool) {
            this.choose_all_fields = !this.choose_all_fields
            if (this.choose_all_fields === bool) {
                this.customize_headers = this.customize_headers.map(header => {
                    header.show = true
                    return header
                })
            } else {
                this.customize_headers = this.customize_headers.map(header => {
                    header.show = false
                    return header
                })
            }
        },

        local_field_checked(item){
            return !item.show
        },

        local_set_project_filter_objects() {
            // this.project_filters[this.filter.RELATIONSHIPS] = ['tags', 'territories', 'customer', 'result', 'stage', 'partner', 'workspace', 'competitors', 'keyCollaborators.user', 'keyCollaborators.type'].join();
            this.project_filters[this.filter.RELATIONSHIPS] = ['customFieldValues', 'customFieldValues.customField', 'allTags', 'territories', 'customer', 'result', 'stage', 'partner', 'workspace', 'competitors', 'collaborators.user', 'collaborators.type', 'bridges', 'latestStatusUpdate.type', 'latestStatusUpdate.createdBy'].join();
            this.project_filters[this.filter.COUNT] = 999;
            this.project_filters[this.filter.DOWNLOAD] = false;
            // if (!this.local_workspace_id) this.project_filters[this.filter.WORKSPACE] = this.workspace_current ? this.workspace_current : this.current_workspace_id_mixin
            this.project_filters[this.filter.PAGE] = 1
            this.local_select_response_fields()
        },

        local_select_response_fields() {
            this.project_filters[this.project_fields.PROJECT] = this.fields.project
            this.project_filters[this.project_fields.CUSTOMER] = this.fields.customer
            this.project_filters[this.project_fields.PARTNER] = this.fields.partner
            this.project_filters[this.project_fields.STAGE] = this.fields.stage
            this.project_filters[this.project_fields.WORKSPACE] = this.fields.workspace
            this.project_filters[this.project_fields.RESULT] = this.fields.result
            this.project_filters[this.project_fields.STATUS_UPDATE] = this.fields.status_update
            this.project_filters[this.project_fields.STATUS_UPDATE_TYPE] = this.fields.status_update_type
            this.project_filters[this.project_fields.STATUS_UPDATE_BY] = this.fields.status_update_by
            // this.project_filters[this.project_fields.COLLABORATORS] = this.fields.collaborators
            // this.project_filters[this.project_fields.COLLABORATOR_USERS] = this.fields.collaborators_user
            // this.project_filters[this.project_fields.COLLABORATOR_TYPES] = this.fields.collaborators_type
        },

        async local_sort_project_list() {
            this.loading = true
            await this.tile_show_detailed({
                id: this.local_tile_id,
                mode: 'show-modify-item',
                params: { ...this.tile_filters }
            })
            this.loading = false
            this.local_set_project_filter_objects()
        },

        local_populate_project_list() {
            this.report_projects = this.project_list
        },

        async local_download_csv() {
            await this.local_trigger_download('csv')
            await this.local_set_custom_headers()
            this.tile_filters.download_format = 'csv'
            this.report_preset_item.filters.download_format = 'csv'
            this.tile_download({ ...this.custom_fields, filters: {...this.tile_filters } })
        },

        async local_download_excel() {
            await this.local_trigger_download('xlsx')
            await this.local_set_custom_headers()
            this.tile_filters.download_format = 'xlsx'
            this.report_preset_item.filters.download_format = 'xlsx'
            this.tile_download({ ...this.custom_fields, filters: {...this.tile_filters } })
        },

        async local_set_custom_headers() {
            const headers = [], slugs = []
            this.headers.filter(item => {
                if (item.show) {
                    headers.push(item.text === ' ' ? 'Health' : item.text)
                    slugs.push(item.slug)
                }
            })
            this.custom_fields = { headers, slugs }
        },

        async local_trigger_download(format) {
            this.dialog_download_link = true
            // this.project_filters[this.filter.DOWNLOAD] = true;
            // this.project_filters[this.filter.DOWNLOAD_FORMAT] = format;
            // this.report_preset_item.filters[this.filter.DOWNLOAD] = true
            // this.report_preset_item.filters[this.filter.DOWNLOAD_FORMAT] = format;
        },

        local_download_save() {
            this.dialog_download_link = false
        },

        /*
            TODO: Have to change this section similar to custom
            TODO: fields addition to headers in local_add_custom_field_header()
        */
        async local_load_custom_tag_sections () {
            await this.tag_section_index({ /*'filter[is_active]': 1,*/ sort: 'order', 'fields[tag_sections]': 'id,slug,name,is_active' })
            this.local_add_custom_tag_header()
        },

        local_check_disable_item(key) {
            const custom_tag_item = this.tag_section_list.find(tag => key === tag.slug)
            if (custom_tag_item?.is_active === 0) return true
            const custom_field_item = this.custom_field_list.find(field => key === `custom_field.${field.name}`)
            if (custom_field_item?.is_active === 0) return true
            return false
        },

        async local_fetch_workspaces () {
            this.workspace_clear_search_list()
            const filters = {
                mode: 'only-search',
                params: { 'filters[workspaces]': 'id,name', 'filters[sort]': 'name', 'filters[is_active]': 1 }
            }
            await this.workspace_board_index(filters)
        },

        async local_load_details () {
            this.tile_filters[this.TILE_FILTERS.PAGE] = 1
            this.tile_filters[this.TILE_FILTERS.COUNT] = this.projects_count
            this.tile_filters[this.TILE_FILTERS.INCLUDE] = 'customer,partner,stage,customFieldValues,result,tags,territories,competitors,collaborators.user,collaborators.type'
            this.tile_filters[this.TILE_FILTERS.CUSTOMER_FIELDS] = 'id,name'
            this.tile_filters[this.TILE_FILTERS.PARTNER_FIELDS] = 'id,name'
            this.tile_filters[this.TILE_FILTERS.STAGE_FIELDS] = 'id,name'
            this.tile_filters[this.TILE_FILTERS.RESULT_FIELDS] = 'id,value,status'
            this.tile_filters[this.TILE_FILTERS.PROJECT_FIELDS] = 'id,title,health_color,deal_amount,next_step_json,status,start_date,due_date,estimated_start_date,estimated_end_date,created_at,updated_at,workspace_id,stage_id,result_id,customer_id,partner_id,archived_at,updated_by_id'
            this.tile_filters['filter[hierarchy_report]'] = null
            this.tile_filters['id'] = this.local_tile_id
            await this.local_fetch_projects()
        },

        async local_get_entities () {
            await this.board_entity_index({
                include: 'entityProperties',
                'fields[entities]': 'id',
                'fields[entity_properties]': 'entity_properties.entity_id,entity_properties.id,entity_properties.key,entity_properties.label,entity_properties.data_type,entity_properties.options'
            })
        },

        async local_paginate () {
            if (this.local_page_count === this.tile_meta.current_page) return
            this.pagination_loading = true
            await this.$vuetify.goTo(0)
            this.tile_filters[this.TILE_FILTERS.PAGE] = this.tile_meta.current_page
            await this.local_fetch_projects()
            this.local_page_count = this.tile_meta.current_page
            this.pagination_loading = false
        },

        async local_fetch_projects() {
            await this.tile_show_detailed({
                id: this.local_tile_id,
                mode: 'show-modify-item',
                params: { ...this.tile_filters }
            })
        },

        local_info_toggle_expand () {
            this.is_expanded = !this.is_expanded
        },

        local_get_operator_text (key, operator_value, field_type = 'operator') {
            const entity_properties = this.board_entity_list.reduce((prev, current) => prev = [...prev, ...current.entity_properties], [])
            entity_properties.push(...this.local_get_custom_fields_and_tags)
            if (entity_properties && entity_properties.length) {
                const entity_item = entity_properties.find(item => item.key === key)
                const operator = EntityOperators[entity_item.data_type].find(item => item.key === operator_value)
                return field_type === 'operator' ? (operator?.label ?? '') : (entity_item?.label ?? '')
            }
        },

        local_filter_value_list (value, prop_id) {
            const prop_key = this.local_get_entity_key(prop_id)
            const custom_tag_item = this.tag_section_list.find(tag => tag.slug === prop_key)
            if (custom_tag_item) {
                const tag = this.tag_list.find(item => item.id === value)
                return tag
            }

            const custom_field_name = prop_key.split('.')[1]
            const custom_field = this.custom_field_list.find(field => (field.type === 'dropdown' || field.type === 'date') && field.name === custom_field_name)
            if (custom_field && custom_field.type === 'dropdown') return custom_field.options.find(option => option.value === value)?.label
            if (custom_field && custom_field.type === 'date') return moment(value).format('MMM D, YYYY')

            switch (prop_key) {
                case 'customer_id': {
                    const customer = this.customer_list.find(item => item.id === value)
                    return customer ? customer.name : ''
                }
                case 'partner_id': {
                    const partner = this.partner_list.find(item => item.id === value)
                    return partner ? partner.name : ''
                }
                case 'stage_id': {
                    const stage = this.stage_list.find(item => item.id === value)
                    return stage ? stage.name : ''
                }
                case 'result_id': {
                    const result = this.result_meta_list.find(item => item.id === value)
                    return result ? result.value : ''
                }
                case 'workspace_id': {
                    const workspace = this.workspace_search_list.find(item => item.id === value)
                    return workspace ? workspace.title : ''
                }
                case 'competitor_id': {
                    const competitor = this.competitor_list.find(item => item.id === value)
                    return competitor ? competitor.name : ''
                }
                case 'territory_id': {
                    const territory = this.territory_list.find(item => item.id === value)
                    return territory ?? ''
                }
                case 'deal_amount': return this.local_currency_formatter(value)
                // Tags
                case 'label_tag_id':
                case 'product_tag_id':
                case 'platform_tag_id': {
                    const tag = this.tag_list.find(item => item.id === value)
                    return tag ?? ''
                }
                // Date values
                case 'start_date':
                case 'due_date':
                case 'estimated_start_date':
                case 'estimated_end_date': return moment(value).format('MMM D, YYYY')
                default: return value
            }
        },

        local_get_entity_key (key, field = 'key') {
            if (!key) return ''
            const entity_properties = this.board_entity_list.reduce((prev, current) => prev = [...prev, ...current.entity_properties], [])
            entity_properties.push(...this.local_get_custom_fields_and_tags)
            if (entity_properties && entity_properties.length) {
                const item = entity_properties.find(item => item.key === key)
                return item[field]
            }
        },

        local_currency_formatter (value) {
            value = value.replace(/,/g, '')
            const formatted = Intl.NumberFormat(this.local_currency_format).format(value)
            value = isNaN(value) ? value : ((!formatted || formatted === '0') ? '' : formatted)
            return this.local_currency_symbol + value
        },

        // Extras
        async local_get_date_range (date_option) {
            await this.fiscal_timeline_show()
            this.local_get_selection_range(date_option)
        },

        local_get_selection_range (date_option) {
            const format = 'MMM D, YYYY'
            const { start_date, end_date } = date_option === 'date_range' ? this.local_get_date_values() : this.local_get_dates_for_range(date_option)
            this.start_date = start_date ? moment(start_date).format(format) : ''
            this.end_date = end_date ? moment(end_date).format(format) : ''
        },

        local_get_date_values () {
            if (this.tile_item && !this.tile_item.tile_options) return
            const timelineItems = this.tile_item.tile_options.filter(item => item.group === 'timeline')
            const timelineStart = timelineItems.find(item => item.key === 'start_date')
            const timelineEnd = timelineItems.find(item => item.key === 'due_date')
            return { start_date: timelineStart.value, end_date: timelineEnd.value }
        },

        local_get_dates_for_range (range_slug) {
            if (!range_slug) return { start_date: '', end_date: '' }
            const item = this.fiscal_timeline_list.find(item => item[range_slug])
            return (item && item[range_slug]) ?? { start_date: '', end_date: '' }
        },

        local_get_project_tags (list, slug) {
            const tags = list.filter(item => item.type === slug)
            return tags && tags.length ? tags : []
        },

        local_get_collab_value (type_id, collabs) {
            return collabs.filter(item => item.type_id === type_id)
        },

        local_get_custom_field_value (type_id, custom_field_values){
            const result = custom_field_values.find(item => item.custom_field_id === type_id)
            if (result) {
                const { options, type } = this.custom_field_list.find(item => item.id === type_id)
                switch (type) {
                    case 'dropdown': {
                        const { label } = options.find(item => item.value === result.value) || {}
                        return label || null
                    }
                    case ('number'):
                    case ('text'):
                    case ('url'):
                    case ('multiline'): return result.value
                    case ('date'): return result.value ? moment(result.value).format('MMM D, YYYY') : result.value
                    default: return null
                }
            }
            return null
        },

        ...mapActions('Tile', {
            tile_show: 'show',
            tile_show_detailed: 'show_detailed',
            tile_clear_item: 'clear_item',
            tile_download: 'download'
        }),

        ...mapActions('BoardEntity', {
            board_entity_index: 'index',
        }),

        ...mapActions('Workspace', {
            workspace_board_index: 'board',
            workspace_clear_search_list: 'clear_search_list',
        }),

        ...mapActions('Customer', {
            customer_index: 'index',
            customer_clear: 'clear',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),

        ...mapActions('Partner', {
            partner_index: 'index',
            partner_clear: 'clear',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
        }),

        ...mapActions('Competitor', {
            competitor_index: 'index',
        }),

        ...mapActions('Territory', {
            territory_index: 'index',
        }),

        ...mapActions('Stage', {
            stage_index: 'index',
            stage_clear: 'clear',
        }),

        ...mapActions('TagSection', {
            tag_section_index: 'index',
            tag_section_clear: 'clear',
        }),

        ...mapActions('CustomField', {
            custom_field_index: 'index',
        }),

        ...mapActions('FiscalTimeline', {
            fiscal_timeline_show: 'show'
        }),

        ...mapActions('ReportPreset', {
            report_preset_index: 'index',
            report_preset_store: 'store',
            report_preset_update: 'update',
            report_preset_destroy: 'destroy',
            report_preset_select: 'select',
            report_preset_clear: 'clear',
            report_preset_clear_response: 'clear_response',
            report_preset_clear_item: 'clear_item',
        }),

        ...mapActions('Project', {
            project_index: 'index',
            project_download: 'download',
            project_clear: 'clear',
        }),
    }
}
</script>

<style lang="scss" scoped>
.c-review-changes-table {
    tr {
        td {
            border-bottom: 1px solid #EEEEEE !important;
        }
        &:last-child {
            td {
                border-bottom: 1px solid transparent !important;
            }
        }

    }
}

.ghost {
    background: #c8ebfb;
    opacity: 0.5;
}
</style>

const api_endpoint = '/comments';

export default {
    namespaced: true,
    state: {
        list: [],
        task_comments: [],
        item: { status: '', replies : [] },
        meta: {},
        filters: {
            status: 'all',
            page: 1,
            count: 10,
            sort_field: 'company_name',
            sort_direction: 'desc'
        },
        response: [],
        defaults: {
            list: [],
            task_comments: [],
            item: { status: '', replies : [] },
            meta: {},
            filters: {
                status: 'all',
                page: 1,
                count: 10,
                sort_field: 'company_name',
                sort_direction: 'desc'
            },
            response: [],
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_TASK_COMMENT_LIST(state, items) {
            state.task_comments = items;
        },
        UPDATE_TASK_COMMENT_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.task_comments, { 'id': payload.id });
            state.task_comments[index] = payload.item;
        },
        UPDATE_TASK_VISIBLITY(state, payload) {
            let index = _.findIndex(state.task_comments, { 'id': payload.id });
            state.task_comments[index].visibility = payload.visibility;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_REPLY_ITEM(state, item) {
            state.item.replies = item;
        },
        ADD_REPLY_ITEM(state, item) {
            state.item.replies.push(item);
        },
        REPLY_COUNT_UPDATE(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index].reply_count = payload.item.reply_count;
            state.item.reply_count = payload.item.reply_count
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload.item;
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        ADD_ITEM(state, item) {
            state.list.push(item);
        },
        PREPEND_ITEM(state, item) {
            state.list.unshift(item);
        },
        PREPEND_TASK_COMMENT_ITEM(state, item) {
            state.task_comments.unshift(item);
        },
        REMOVE_ITEM(state, id) {
            const index = _.findIndex(state.list, { 'id': id });
            if (index !== -1) state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            let query_filters = _.pickBy(context.state.filters, function (item) {
                return item != '';
            });
            return ext_axios.get(api_endpoint, { params: payload })
                .then((response) => {
                    if(payload.type && payload.type === 'Task') {
                        context.commit('UPDATE_TASK_COMMENT_LIST', response.data.data);
                    }else {
                        context.commit('UPDATE_LIST', response.data.data);
                    }
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        enabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint + '/enabled')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        disabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint + '/disabled')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint + '/' + payload.id, { params: payload.params })
                .then((response) => {
                    if (payload && payload.mode !== 'add') context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: context.state.item });
                    else context.commit('ADD_ITEM', response.data.data);
                    if(payload && payload.type === 'Task') context.commit('PREPEND_TASK_COMMENT_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show_new (context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint + '/' + payload.id, { params: payload.params })
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.post(api_endpoint, payload)
                .then((response) => {
                    if (!payload.mode || (payload.mode && payload.mode !== 'no-store')) context.commit('ADD_ITEM', payload)
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        // reply_show(context, payload) {
        //     this.dispatch('Interface/loader_start')
        //     return ext_axios.get(api_endpoint + '/' + payload.id + '/reply')
        //         .then((response) => {
        //             context.commit('UPDATE_REPLY_ITEM', response.data.data);
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
        //         })
        //         .catch((error) => {
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
        //         });
        // },
        // reply_store(context, payload) {
        //     this.dispatch('Interface/loader_start')
        //     return ext_axios.post(api_endpoint + '/' + payload.id + '/reply', payload)
        //         .then((response) => {
        //             context.commit('ADD_REPLY_ITEM', response.data.data);
        //             context.commit('REPLY_COUNT_UPDATE', { id: context.state.item.id, item: response.data.data });
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: response.data.data });
        //         })
        //         .catch((error) => {
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
        //         });
        // },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id, payload)
                .then((response) => {
                    // context.commit('UPDATE_LIST_ITEM', { id:payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        enable(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id + '/enable', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        disable(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id + '/disable', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        // client_visible(context, payload) {
        //     this.dispatch('Interface/loader_start')
        //     return ext_axios.patch(api_endpoint + '/' + payload.id + '/client_visible', {})
        //         .then((response) => {
        //             if(response.data.data.commentable_type === 'Task') {
        //                 context.commit('UPDATE_TASK_COMMENT_LIST_ITEM', { id: payload.id, item: response.data.data });
        //             }else {
        //                 context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
        //             }
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('REFRESH_LIST');
        //             context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
        //         })
        //         .catch((error) => {
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
        //         });
        // },
        visible(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id + '/states/visibility', payload)
                .then((response) => {
                    if(payload.type === 'Task') {
                        context.commit('UPDATE_TASK_VISIBLITY', payload);
                    }else {
                        context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        status_list(context) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint + '/status_list')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return ext_axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store_local ({ commit }, payload) {
            commit('PREPEND_ITEM', payload)
        },

        update_local ({ commit }, payload) {
            commit('UPDATE_LIST_ITEM', { id: payload.id, item: payload })
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        task_comment_clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_TASK_COMMENT_LIST', _.cloneDeep(context.state.defaults.task_comments));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },
}

<template>
<a-card text class="u-rounded-corners u-overflow-hidden mb-3 px-3 u-shadow" :class="[disabled ? 'u-opacity-50' : '']" :disabled="disabled">
    <a-container container--fluid grid-list-xl class="pa-0 py-3">
        <a-layout align-center>
            <a-flex shrink>
                <div class="d-flex" v-if="item.direction === null">
                    <a-icon size="24">help</a-icon>
                    <a-icon class="mx-2">arrow_right_alt</a-icon>
                    <a-icon size="24">help</a-icon>
                </div>
                <div class="d-flex" v-if="item.direction === 'incoming'">
                    <img :src="require('../../../assets/images/apps/hubspot/logo.svg')" alt="HubSpot" style="display: inline-block; max-width: 32px; max-height: 32px;">
                    <a-icon class="mx-2">arrow_right_alt</a-icon>
                    <img :src="require('../../../assets/images/success-imgs/icon-success.svg')" class="u-rounded-corners-lg" alt="Success" style="display: inline-block; max-width: 28px; max-height: 28px;">
                </div>
                <div class="d-flex" v-if="item.direction === 'outgoing'">
                    <img :src="require('../../../assets/images/success-imgs/icon-success.svg')" class="u-rounded-corners-lg" alt="Success" style="display: inline-block; max-width: 28px; max-height: 28px;">
                    <a-icon class="mx-2">arrow_right_alt</a-icon>
                    <img :src="require('../../../assets/images/apps/hubspot/logo.svg')" alt="HubSpot" style="display: inline-block; max-width: 32px; max-height: 32px;">
                </div>
            </a-flex>
            <a-flex class="u-cursor-pointer">
                <router-link v-if="installationItem && installationItem.id && item.is_created === 1" :to="{name: `appstore-hubspot-workflows-edit`, params: { id: installationItem.id, workflow_id: item.id }}">
                    <p class="md-subtitle-1 grey--text text--darken-4 mb-0">{{ item.title }}</p>
                </router-link>
                <router-link v-if="installationItem && installationItem.id && item.is_created === 0" :to="{name: `appstore-hubspot-workflows-create`, params: { id: installationItem.id, workflow_id: item.id }}">
                    <p class="md-body-1 mb-0">{{ item.title }}</p>
                </router-link>
            </a-flex>
            <a-flex shrink v-if="!disabled">
                <p class="md-body-2 mb-0 orange--text text--darken-2 text-center" v-if="item.is_complete === 0">
                    <a-icon size="22" class="mr-1 u-icon-nudge" color="orange">warning</a-icon> Invalid
                </p>
                <p class="md-body-2 mb-0 grey--text text--darken-2 text-center" v-if="item.is_complete === 1 && item.is_active === 0">
                    <a-icon size="22" class="mr-1 u-icon-nudge" color="grey">cloud_off</a-icon> Inactive
                </p>
                <p class="md-body-2 mb-0 green--text text--darken-2 text-center" v-if="item.is_complete === 1 && item.is_active === 1">
                    <a-icon size="22" class="mr-1 u-icon-nudge" color="green">check_circle</a-icon> Active
                </p>
            </a-flex>
            <a-flex shrink v-if="$can('applets.update') && !disabled">
                <a-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <a-btn icon text color="grey darken-1" v-on="on" @click="$emit('clone')">
                            <a-icon size="16">content_copy</a-icon>
                        </a-btn>
                    </template>
                    <span>Clone Workflow</span>
                </a-tooltip>

                <a-menu bottom left offset-y max-width="176">
                    <template v-slot:activator="{ on }">
                        <a-btn icon text small color="grey darken-1" class="mx-0" v-on="on">
                            <a-icon size="16">delete</a-icon>
                        </a-btn>
                    </template>
                    <a-card flat>
                        <a-card-text class="pa-0">
                            <div class="pa-3">
                                <p class="md-body-2 mb-0 text-center grey--text text--darken-3">Workflow will be deleted. This action cannot be reversed.</p>
                            </div>
                            <a-btn color="red darken-1" class="elevation-0 ma-0" block dark small @click="$emit('destroy')">Confirm Delete</a-btn>
                        </a-card-text>
                    </a-card>
                </a-menu>
            </a-flex>
        </a-layout>
    </a-container>
</a-card>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: {},
            required: true
        },
        installationItem: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        },
    }
}
</script>

<template>
    <v-avatar v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </v-avatar>
</template>

<script>
import { VAvatar } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VAvatar }
}
</script>

<template>
    <div>
        <InternalProject
            :isPresentationView="project_is_friendly_view"
            v-if="local_user_is_internal"
        />
        <ExternalProject v-else />

        <a-dialog v-model="isPresentationView" max-width="400">
            <ModalExitPresentationMode
                @exit="localExitFriendlyView()"
                @close="isPresentationView = false"
            />
        </a-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import InternalProject from '@/pages/Projects/Projects.vue'
import ExternalProject from '@/pages/External/ExternalProjects/ExternalProjects.vue'
import { ModalExitPresentationMode } from '../Projects/Modals'

export default {
    components: {
        InternalProject,
        ExternalProject,
        ModalExitPresentationMode
    },

    data () {
        return {
            isPresentationView: false,
            intendedPageRoute: null
        }
    },

    mounted () {
        window.addEventListener('beforeunload', this.localHandlePageLeave)
    },

    beforeDestroy () {
        window.removeEventListener('beforeunload', this.localHandlePageLeave)
    },

    beforeRouteLeave (toPage, fromPage, next) {
        this.localCheckRoute(toPage, next)
    },

    beforeRouteUpdate (toPage, fromPage, next) {
        this.localCheckRoute(toPage, next)
    },

    computed: {
        local_user_is_internal() {
            return this.user_self && this.user_self.scope === 'internal'
        },

        ...mapState('User', {
            user_self: 'self'
        }),

        ...mapState('ProjectView', {
            project_is_friendly_view: 'is_friendly_view',
        }),
    },

    methods: {
        localHandlePageLeave (evt) {
            if (this.project_is_friendly_view && this.local_user_is_internal) {
                evt.returnValue = 'Are you sure to refresh? After refresh confidential data will be displayed.'
            }
        },

        localCheckRoute (toPage, nextRoute) {
            const { meta: { parent, view } } = toPage
            if (parent === 'projects' && view !== 'external') return nextRoute()
            if (!this.local_user_is_internal || !this.project_is_friendly_view) return nextRoute()

            this.isPresentationView = this.project_is_friendly_view
            this.intendedPageRoute = nextRoute
            nextRoute(false)
        },

        localExitFriendlyView () {
            this.project_switch_friendly_view(false)
            this.isPresentationView = false
            if (this.intendedPageRoute) this.intendedPageRoute()
        },

        ...mapActions('ProjectView', {
            project_switch_friendly_view: 'switch_friendly_view',
        })
    }
}
</script>

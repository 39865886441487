<template>
    <a-card v-bind="$attrs" class="u-relative u-rounded-corners-lg" :class="{ 'js-draggable-file-list-item': canUpdate }">
        <slot name="divider"></slot>
        <div class="px-4 py-6">
            <router-link tag="div" class="u-cursor-pointer" :class="handle" :to="path">
                <div class="u-flex">
                    <div class="align-center mr-3 mt-1">
                        <slot name="dragIndicator"></slot>
                    </div>
                    <div>
                        <h2 class="md-heading-6 font-weight-medium" :title="usecase.title && usecase.title.length >= (local_is_lgdown ? 185 : 248) ? usecase.title : ''">{{ usecase.title | truncateText(local_is_lgdown ? 185 : 248) }}</h2>
                        <div class="mt-2 u-flex align-center">
                            <div class="mr-4">
                                <slot name="visibility">
                                    <g-confirmation
                                        :loading="loading"
                                        :can-update="canUpdate"
                                        :is-public="usecase && usecase.visibility === 'external'"
                                        @action="canUpdate ? local_visibility_toggle(usecase.visibility) : ''"
                                        type="Use Case"
                                        class="d-inline-block"
                                        dense
                                    ></g-confirmation>
                                </slot>
                            </div>
                            <div class="d-inline-flex align-center mr-4">
                                <slot name="attachment-count"></slot>
                            </div>
                            <div class="d-inline-flex align-center mr-4">
                                <slot name="success-criteria-stats"></slot>
                            </div>
                            <div class="d-inline-flex align-center mr-4">
                                <slot name="testcase-stats"></slot>
                            </div>
                        </div>
                        <slot name="tags">
                            <div class="u-flex-center-y u-wfull flex-wrap mt-2" v-if="!local_is_external && (usecase.tags && usecase.tags.length)">
                                <template v-for="(tag) in usecase.tags">
                                    <g-tags :can-update="canUpdate" :tag="tag" type="Usecase" hide-clear-icon :key="tag.id"></g-tags>
                                </template>
                            </div>
                        </slot>
                    </div>
                    <a-spacer></a-spacer>
                    <slot name="actions"></slot>
                </div>
            </router-link>
        </div>
    </a-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    inheritAttrs: false,
    props: {
        usecase: {
            type: Object,
            required: true
        },
        handle: {
            type: String
        },
        path: {
            type: Object,
            default: () => {
                return { name: 'projects-usecases-view', params: { usecase_id: this.usecase.id } }
            }
        },
        canUpdate: {
            type: Boolean
        },
        expanded: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        local_is_external () {
            return this.user_self && this.user_self.scope === 'external'
        },

        local_is_lgdown () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        ...mapState('User', {
            user_self: 'self'
        }),

        ...mapState('Usecase', {
            usecase_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader'
        }),
    },

    methods: {
        async local_visibility_toggle (state) {
            const usecase_item = {}
            state = state === 'internal' ? 'external' : 'internal'
            usecase_item.id = this.usecase.id
            usecase_item.state = state
            await this.usecase_visibility(usecase_item)
            if (this.usecase_response.status !== 'success') return
            this.$set(this.usecase, 'visibility', state)
            this.$notify('success', 'Updated visibility successfully!')
        },

        ...mapActions('Usecase', {
            usecase_visibility: 'visibility',
        }),
    }
}
</script>

<style lang="scss" scoped>
    .c-dragger {
        width: 28px;
        height: 28px;
        position: absolute;
        top: 0px;
        right: 0px;
        display: block;
        border-radius: 0px 8px 0px 4px !important;
        background: #f5f5f5;
        border-left: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
    }

    .c-bubble {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        border-radius: 6px 0px 24px 0px !important;
        background: #faa730 !important;
        &--private {
            background: #dcdcdc !important;
        }
    }
</style>

<template>
    <a-responsive class="grey darken-3">
        <a-container container--fluid grid-list-xl class="pa-6 my-3">
            <a-layout align-center>
                <a-flex shrink>
                    <div class="u-elevation-custom-1 white" style="display: flex; align-items: center; justify-content: center; width: 104px; height: 104px; border-radius: 1.618rem;">
                        <img :src="require('../../../assets/images/apps/jira/logo.svg')" alt="Jira" style="display: block; max-width: 64px; max-height: 64px;">
                    </div>
                </a-flex>
                <a-flex>
                    <h2 class="md-heading-4 white--text">Jira</h2>
                    <p class="md-subtitle-1 grey--text text--lighten-1 mb-0">Connect to Jira and access Jira ticket data right from Success</p>
                    <p class="md-body-2 grey--text mt-1 mb-0" v-if="installation && installation.installer">Installed by {{ installation.installer.name }} on <g-moment :value="installation.created_at" input-format="YYYY-MM-DD" output-format="MMMM D, YYYY"></g-moment></p>
                </a-flex>
            </a-layout>
        </a-container>
    </a-responsive>
</template>

<script>
export default {
    props: {
        installation: {
            type: Object,
            default: {},
            required: true,
        }
    }
}
</script>

<template>
    <div class="mb-16">
        <h3 class="text-h6 grey--text text--darken-3 font-weight-medium mb-4">Fields & Tags</h3>

        <div>
            <template v-if="loading">
                <a-sheet class="white u-shadow u-wfull u-rounded-corners mb-8">
                    <h4 class="indigo--text text--darken-1 font-weight-medium md-subtitle-1 px-6 py-5">
                        <loader-template width="200" height="14"></loader-template>
                    </h4>
                    <a-divider></a-divider>
                    <div class="u-flex-center-y flex-wrap pa-7">
                        <a-sheet width="50%" v-for="count in 6" :key="count" :class="[count % 2 === 0 ? 'pl-3' : 'pr-3', { 'mt-6': count !== 1 && count !== 2 }]">
                            <loader-template width="100%" height="48" class="u-rounded-corners-lg"></loader-template>
                        </a-sheet>
                    </div>
                </a-sheet>

                <a-sheet class="u-border u-wfull u-rounded-corners mb-8">
                    <h4 class="indigo--text text--darken-1 font-weight-medium md-subtitle-1 px-6 py-5 grey lighten-5">
                        <loader-template width="200" height="14"></loader-template>
                    </h4>
                    <a-divider></a-divider>
                    <div class="pt-4">
                        <a-sheet v-for="count in 2" :key="count" class="u-flex align-start px-6 py-3" :class="{ 'mt-4': count !== 1 }">
                            <a-sheet min-width="184" max-width="184">
                                <loader-template width="100%" height="12" class="u-rounded-corners-lg"></loader-template>
                            </a-sheet>
                            <a-sheet class="u-flex-center-y flex-wrap ml-12">
                                <loader-template v-for="i in 8" :key="i + '-count'" width="140" height="20" class="u-rounded-corners-full mr-2 mb-3"></loader-template>
                            </a-sheet>
                        </a-sheet>
                    </div>
                </a-sheet>
            </template>

            <template v-else>
                <div class="text-center mt-8" v-if="localClonelayoutDesignList && !localClonelayoutDesignList.length">
                    <div class="py-8 px-6 text-center u-flex flex-column align-center">
                        <div class="rounded-circle grey lighten-3 u-flex justify-center mb-4" style="width: 80px; height: 80px">
                            <a-icon size="48" color="grey lighten-1">sell</a-icon>
                        </div>
                        <div class="grey--text text--darken-3 mb-3 text-uppercase" style="font-weight: 600">No fields or tags are added!</div>
                        <p class="grey--text text--darken-1 mb-4 font-weight-medium mx-auto" style="max-width: 600px">
                            <template v-if="$can('layout_designer.index')" >
                                You can configure existing fields and tags using Layout Designer.
                            </template>
                            <template v-else>
                                There are no custom fields or tags attached to this project.
                            </template>
                        </p>
                        <div class="u-flex justify-center font-weight-medium">
                            <a-btn v-if="$can('layout_designer.index')" depressed color="grey darken-4" :to="{ name: 'layout-designer'}">
                                <a-icon size="18" color="white" class="pr-1">login</a-icon>
                                <span class="white--text">Go To Layout Designer</span>
                            </a-btn>
                        </div>
                    </div>
                </div>
                <template v-else>
                    <div class="u-wfull u-rounded-corners mb-8">
                        <!-- <h4 class="indigo--text text--darken-1 font-weight-medium md-subtitle-1 px-6 py-5">Fields & Tags</h4> -->
                        <template>
                            <div v-for="(section, index) in localClonelayoutDesignList" :key="section.id">
                                <a-sheet
                                    class="u-border u-wfull u-rounded-corners mb-8"
                                    v-if="section.is_active"
                                >
                                    <div class="">
                                        <div class="pa-5">
                                            <a-layout align-center>
                                                <a-flex class="u-flex align-center">
                                                    <div class="u-wfull">
                                                        <h2 class="md-subtitle-1 indigo--text text--darken-1 font-weight-medium" :title="section.name">
                                                            {{ section.name | truncateText(local_screen_lg ? 40 : 120) }}
                                                        </h2>
                                                    </div>
                                                </a-flex>
                                                <a-flex shrink v-if="local_has_tag_fields(section)">
                                                    <a-tooltip bottom offset-overflow v-if="$can('projects.update-only')">
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on" class="u-flex-center-y">
                                                                <a-icon size="18" color="grey darken-2" class="mr-2">help</a-icon>
                                                                <span class="md-caption text-uppercase grey--text text--darken-2 font-weight-medium" style="letter-spacing: 1px;">Keyboard Shortcuts</span>
                                                            </div>
                                                        </template>
                                                        <div class="py-3 px-1 u-rounded-corners">
                                                            <div class="u-flex">
                                                                <div class="mr-3 u-flex-center-y" style="flex-shrink: 0; flex-grow: 1; width: 100px;">
                                                                    <div class="transparent u-rounded-corners u-flex-center pa-2 mr-3" style="width: 24px; height: 20px; border: 1px solid #fff !important;">
                                                                        <a-icon size="16" color="white">arrow_upward</a-icon>
                                                                    </div>
                                                                    <div class="transparent u-rounded-corners u-flex-center pa-2" style="width: 24px; height: 20px; border: 1px solid #fff !important;">
                                                                        <a-icon size="16" color="white">arrow_downward</a-icon>
                                                                    </div>
                                                                </div>
                                                                <a-spacer></a-spacer>
                                                                <span class="d-block u-wfull text-left" style="flex-grow: 0;">Moves through the Tags selection dropdown</span>
                                                            </div>
                                                            <div class="u-flex mt-4">
                                                                <div class="mr-3" style="flex-shrink: 0; flex-grow: 1; width: 100px;">
                                                                    <div class="transparent u-rounded-corners u-flex-center white--text md-caption pa-2" style="width: max-content; height: 20px; border: 1px solid #fff !important;">Backspace</div>
                                                                </div>
                                                                <a-spacer></a-spacer>
                                                                <span class="d-block u-wfull text-left" style="flex-grow: 0;">Removes the last Tag item</span>
                                                            </div>
                                                            <div class="u-flex mt-4">
                                                                <div class="mr-3" style="flex-shrink: 0; flex-grow: 1; width: 100px;">
                                                                    <div class="transparent u-rounded-corners u-flex-center white--text md-caption pa-2" style="width: max-content; height: 20px; border: 1px solid #fff !important;">Esc</div>
                                                                </div>
                                                                <a-spacer></a-spacer>
                                                                <span class="d-block u-wfull text-left" style="flex-grow: 0;">Removes focus from the Tags selection dropdown</span>
                                                            </div>
                                                        </div>
                                                    </a-tooltip>
                                                </a-flex>
                                            </a-layout>
                                        </div>
                                        <a-divider v-if="local_show_divider(section)"></a-divider>
                                        <template v-if="section.fields && !section.fields.length">
                                            <div class="py-8 px-6 text-center">
                                                <div class="grey--text text--darken-3 mb-3" style="font-weight: 600">No fields or tags are added to this section!</div>
                                                <p v-if="$can('layout_designer.index')" class="grey--text text--darken-1 mb-4 font-weight-medium mx-auto" style="max-width: 600px">You can configure existing fields and tags using Layout Designer.</p>
                                                <div class="u-flex justify-center font-weight-medium">
                                                    <router-link v-if="$can('layout_designer.index')" class="mx-4 indigo--text text--darken-1" :to="{ name: 'layout-designer'}">
                                                        <span>Go to Layout Designer</span>
                                                        <a-icon size="18" color="indigo darken-1" class="pl-1">arrow_forward</a-icon>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <a-sheet class="u-wfull c-section-container">
                                                <div v-for="(field, fieldIndex) in section.fields" :key="field.id" :class="[field.width === '50%' ? 'c-field-card__half--preview' : 'c-field-card__full']">
                                                    <a-divider v-if="local_show_item_divider(section, field, fieldIndex)"></a-divider>
                                                    <a-sheet
                                                        class="u-wfull mb-5"
                                                    >
                                                        <div class="px-6 pb-2 pt-5 u-wfull">
                                                            <div v-if="field && field.parent_type === 'CustomField'" class="u-flex align-center px-2 mb-3">
                                                                <a-icon
                                                                    size="20"
                                                                    color="grey lighten-1"
                                                                    class="mr-2"
                                                                >
                                                                    {{ local_get_custom_field_type_item(field) }}
                                                                </a-icon>
                                                                <h3 class="md-subtitle-1 grey--text text--darken-3 font-weight-medium" >{{ field.parent.label }}</h3>
                                                                <a-tooltip right v-if="field.parent.description">
                                                                    <template v-slot:activator="{ on }">
                                                                        <a-icon v-on="on" size="16" color="grey darken-1" class="u-cursor-pointer ml-2 u-flex">info</a-icon>
                                                                    </template>
                                                                    <span class="py-3">{{ field.parent.description }}</span>
                                                                </a-tooltip>
                                                            </div>
                                                            <div class="u-wfull">
                                                                <template v-if="field && field.parent.type === 'url'">
                                                                    <template v-if="field.parent.custom_field_value">
                                                                        <a-tooltip bottom offset-y :disabled="!localGetProjectErrorMessage(field.parent.name) && field.parent.user_writable === 1">
                                                                            <template v-slot:activator="{ on }">
                                                                                <div v-on="on">
                                                                                    <a-text-field
                                                                                        v-model="field.parent.custom_field_value.value"
                                                                                        :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                        color="grey darken-1"
                                                                                        background-color="grey lighten-4"
                                                                                        class="u-rounded-corners"
                                                                                        :class="[localGetProjectErrorMessage(field.parent.name) ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                        :disabled="!localCheckProjectPermission || field.parent.user_writable === 0"
                                                                                        @input="localProjectCustomFieldUpdate(field.parent, field.parent.custom_field_value ? field.parent.custom_field_value.value : '')"
                                                                                        :append-icon="!localCheckUrlIcon(field) ? 'open_in_new' : ''"
                                                                                        @click:append="field.parent.custom_field_value.value ? localLinkOpenNewTab(field.parent.custom_field_value.value) : {}"
                                                                                        solo flat hide-details
                                                                                    ></a-text-field>
                                                                                </div>
                                                                            </template>
                                                                            <span class="py-3">{{ localGetProjectErrorMessage(field.parent.name) ? localGetProjectErrorMessage(field.parent.name) : 'This is a read-only field.'}}</span>
                                                                        </a-tooltip>
                                                                    </template>
                                                                </template>
                                                                <template v-if="field && ['text','number'].includes(field.parent.type)">
                                                                    <template v-if="field.parent.custom_field_value">
                                                                        <a-tooltip bottom offset-y :disabled="!localGetProjectErrorMessage(field.parent.name) && field.parent.user_writable === 1">
                                                                            <template v-slot:activator="{ on }">
                                                                                <div v-on="on">
                                                                                    <a-text-field
                                                                                        v-model="field.parent.custom_field_value.value"
                                                                                        :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                        color="grey darken-1"
                                                                                        background-color="grey lighten-4"
                                                                                        class="u-rounded-corners"
                                                                                        :class="[localGetProjectErrorMessage(field.parent.name) ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                        :disabled="!localCheckProjectPermission || field.parent.user_writable === 0"
                                                                                        @input="localProjectCustomFieldUpdate(field.parent, field.parent.custom_field_value ? field.parent.custom_field_value.value : '')"
                                                                                        @keypress="localIsNumber($event, field.parent.type)"
                                                                                        solo flat hide-details
                                                                                    ></a-text-field>
                                                                                </div>
                                                                            </template>
                                                                            <span class="py-3">{{ localGetProjectErrorMessage(field.parent.name) ? localGetProjectErrorMessage(field.parent.name) : 'This is a read-only field.'}}</span>
                                                                        </a-tooltip>
                                                                    </template>
                                                                </template>
                                                                <template v-if="field && field.parent.type === 'multiline'">
                                                                    <template v-if="field.parent.custom_field_value">
                                                                    <a-tooltip bottom offset-y :disabled="!localGetProjectErrorMessage(field.parent.name) && field.parent.user_writable === 1">
                                                                        <template v-slot:activator="{ on }">
                                                                            <div style="overflow-y: auto; max-height: 15rem" class="grey lighten-4 rounded" v-on="on">
                                                                                <a-textarea
                                                                                    v-model="field.parent.custom_field_value.value"
                                                                                    :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                    color="grey darken-1"
                                                                                    background-color="grey lighten-4"
                                                                                    class="u-rounded-corners"
                                                                                    :class="[localGetProjectErrorMessage(field.parent.name)  ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                    :disabled="!localCheckProjectPermission || field.parent.user_writable === 0"
                                                                                    @input="localProjectCustomFieldUpdate(field.parent, field.parent.custom_field_value ? field.parent.custom_field_value.value : '')"
                                                                                    solo flat hide-details no-resize auto-grow
                                                                                    rows="3"
                                                                                >
                                                                                </a-textarea>
                                                                            </div>
                                                                        </template>
                                                                        <span class="py-3">{{ localGetProjectErrorMessage(field.parent.name) ? localGetProjectErrorMessage(field.parent.name) : 'This is a read-only field.'}}</span>
                                                                    </a-tooltip>
                                                                </template>
                                                                </template>
                                                                <template v-if="field && field.parent.type === 'mask'">
                                                                    <template v-if="field.parent.custom_field_value">
                                                                        <a-tooltip bottom offset-y :disabled="!localGetProjectErrorMessage(field.parent.name) && field.parent.user_writable === 1">
                                                                            <template v-slot:activator="{ on }">
                                                                                <div v-on="on">
                                                                                    <a-text-field
                                                                                        v-model="field.parent.custom_field_value.mask_value"
                                                                                        :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                        color="grey darken-1"
                                                                                        background-color="grey lighten-4"
                                                                                        class="u-rounded-corners"
                                                                                        :class="[localGetProjectErrorMessage(field.parent.name) ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                        :disabled="!localCheckProjectPermission || field.parent.user_writable === 0"
                                                                                        solo flat hide-details
                                                                                        :append-icon="$can('tickets.update') && field.parent.user_writable === 1 ? 'drive_file_rename_outline' : ''"
                                                                                        readonly
                                                                                        @click:append="localSelectCustomFieldItem(section.id, field.id, field.parent)"
                                                                                    ></a-text-field>
                                                                                </div>
                                                                            </template>
                                                                            <span class="py-3">{{ localGetProjectErrorMessage(field.parent.name) ? localGetProjectErrorMessage(field.parent.name) : 'This is a read-only field.'}}</span>
                                                                        </a-tooltip>
                                                                    </template>
                                                                </template>
                                                                <template v-if="field && field.parent.type === 'dropdown'">
                                                                    <template v-if="field.parent.custom_field_value">
                                                                        <a-tooltip bottom offset-y :disabled="field.parent.user_writable === 1">
                                                                            <template v-slot:activator="{ on }">
                                                                                <div v-on="on">
                                                                                    <v-autocomplete
                                                                                        v-model="field.parent.custom_field_value.value"
                                                                                        :items="field.parent.options"
                                                                                        item-text="label"
                                                                                        item-value="value"
                                                                                        :placeholder="$can('projects.update-only') ? (field.parent.placeholder ? field.parent.placeholder : 'Select a value') : ''"
                                                                                        class="u-rounded-corners"
                                                                                        :class="[localGetProjectErrorMessage(field.parent.name) ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                        color="grey darken-1"
                                                                                        background-color="grey lighten-4"
                                                                                        :key="field.parent.id"
                                                                                        :append-icon="$can('projects.update-only') ? 'arrow_drop_down' : null"
                                                                                        :disabled="!localCheckProjectPermission || field.parent.user_writable === 0"
                                                                                        @input="localProjectCustomFieldUpdate(field.parent, field.parent.custom_field_value ? field.parent.custom_field_value.value : '')"
                                                                                        solo flat hide-details
                                                                                    >
                                                                                        <template v-slot:no-data>
                                                                                            <div class="px-4 py-2">
                                                                                                <span class="md-body-1 grey--text">No Data Found</span>
                                                                                            </div>
                                                                                        </template>
                                                                                        <template v-slot:selection="{ item }">
                                                                                            <span v-if="item === Object(item)" class="md-body-3 d-block py-1 u-rounded-corners text-truncate" style="max-width: 630px" :title="item.label">{{ item.label }} </span>
                                                                                        </template>
                                                                                        <template v-slot:item="{ item }">
                                                                                            <a-layout align-center class="py-0">
                                                                                                <a-flex style="max-width: 630px">
                                                                                                    <h5 class="md-subtitle-1 text-truncate" :title="item.label">{{ item.label }}</h5>
                                                                                                </a-flex>
                                                                                            </a-layout>
                                                                                        </template>
                                                                                    </v-autocomplete>
                                                                                </div>
                                                                            </template>
                                                                            <span class="py-3">This is a read-only field</span>
                                                                        </a-tooltip>
                                                                    </template>
                                                                </template>
                                                                <template v-if="field && field.parent.type === 'date'">
                                                                    <template v-if="field.parent.custom_field_value">
                                                                        <a-tooltip bottom offset-y :disabled="!localGetProjectErrorMessage(field.parent.name) && field.parent.user_writable === 1">
                                                                            <template v-slot:activator="{ on }">
                                                                                <div v-on="on">
                                                                                    <!-- <a-menu
                                                                                        v-model="datepickerMenu[field.parent.id]"
                                                                                        :close-on-content-click="false"
                                                                                        :nudge-right="60"
                                                                                        transition="scale-transition"
                                                                                        min-width="290px"
                                                                                        offset-y
                                                                                    >   <template v-slot:activator="{ on, attrs }">
                                                                                            <a-text-field
                                                                                                v-model="datepickerDateValue[field.parent.id]"
                                                                                                :value="local_format_date(field.parent.custom_field_value.value)"
                                                                                                placeholder="Select Date"
                                                                                                background-color="neutral"
                                                                                                :class="[localGetProjectErrorMessage(field.parent.name) ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                                :disabled="!localCheckProjectPermission || field.parent.user_writable === 0"
                                                                                                @input="localProjectCustomFieldUpdate(field.parent, field.parent.custom_field_value ? field.parent.custom_field_value.value : '')"
                                                                                                @click:clear="field.parent.custom_field_value.value = null"
                                                                                                solo flat hide-details readonly clearable
                                                                                                v-bind="attrs"
                                                                                                v-on="on"
                                                                                            >
                                                                                            </a-text-field>
                                                                                        </template>
                                                                                        <a-date-picker
                                                                                            v-model="field.parent.custom_field_value.value"
                                                                                            @input="localSelectDatepicker(field.parent)"
                                                                                            no-title scrollable
                                                                                        >
                                                                                        </a-date-picker>
                                                                                    </a-menu> -->
                                                                                    <vc-date-picker
                                                                                        v-model="field.parent.custom_field_value.value"
                                                                                        class="d-block u-wfull"
                                                                                        @input="localSelectDatepicker(field.parent)"
                                                                                        :model-config="modelConfig"
                                                                                    >
                                                                                        <template v-slot="{togglePopover}">
                                                                                            <a-sheet width="100%" @click="(!localCheckProjectPermission || field.parent.user_writable === 0) ? '' : togglePopover({ placement: 'bottom-start' })">
                                                                                                <template>
                                                                                                    <a-text-field
                                                                                                        v-model="datepickerDateValue[field.parent.id]"
                                                                                                        :value="local_format_date(field.parent.custom_field_value.value)"
                                                                                                        placeholder="Select Date"
                                                                                                        background-color="neutral"
                                                                                                        :class="[localGetProjectErrorMessage(field.parent.name) ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                                        :disabled="!localCheckProjectPermission || field.parent.user_writable === 0"
                                                                                                        @input="localProjectCustomFieldUpdate(field.parent, field.parent.custom_field_value ? field.parent.custom_field_value.value : '')"
                                                                                                        @click:clear="localClearDate('Project', project_item.id, field.parent, index)"
                                                                                                        solo flat hide-details readonly clearable
                                                                                                        v-on="on"
                                                                                                    >
                                                                                                    </a-text-field>
                                                                                                </template>
                                                                                            </a-sheet>
                                                                                        </template>
                                                                                    </vc-date-picker>
                                                                                </div>
                                                                            </template>
                                                                            <span class="py-3">{{ localGetProjectErrorMessage(field.parent.name) ? localGetProjectErrorMessage(field.parent.name) : 'This is a read-only field.'}}</span>
                                                                        </a-tooltip>
                                                                    </template>
                                                                </template>
                                                                <template v-if="field && field.parent_type !== 'CustomField'">
                                                                    <a-sheet class="u-rounded-corners pt-3 u-flex align-start" :key="field.parent_id">
                                                                        <a-sheet min-width="184" max-width="184" class="u-flex align-center pt-3">
                                                                            <a-icon size="20" color="grey lighten-1" class="mr-3 ml-2">sell</a-icon>
                                                                            <h2 v-if="field.parent_type === 'SystemTagSection'" class="md-subtitle-1 font-weight-medium primary--text text--lighten-2 mb-0" :title="field.parent.value">
                                                                                {{ field.parent.value | truncateText(12) }}
                                                                            </h2>
                                                                            <h2 v-else class="md-subtitle-1 font-weight-medium primary--text text--lighten-2 mb-0" :title="field.parent.name">
                                                                                {{ field.parent.name | truncateText(12) }}
                                                                            </h2>
                                                                        </a-sheet>
                                                                        <div
                                                                            :id="local_get_tag_slug(field) + '-tags'"
                                                                            class="u-flex align-center flex-wrap ml-4 u-wfull"
                                                                            :class="[
                                                                                tagSearchType === local_get_tag_slug(field) ? 'u-border-d u-rounded-corners-lg': 'u-border-transparent',
                                                                                localGetSelectedTags(local_get_tag_slug(field), null, 'length') ? 'pl-3 pt-3' : 'pa-3',
                                                                                {'u-border-transparent': tagRemoveType === local_get_tag_slug(field)}
                                                                            ]"
                                                                        >
                                                                            <template v-for="(tag, tagIndex) in localGetSelectedTags(local_get_tag_slug(field), 'custom')">
                                                                                <g-tags
                                                                                    :tag="tag"
                                                                                    :class="[
                                                                                        { 'c-ring-blue': currentSelectionIndex === tagIndex && tagSearchType === local_get_tag_slug(field) },
                                                                                        { 'c-ring-red u-opacity-70': tagConfirmRemoveIndex === tagIndex && tagSearchType === local_get_tag_slug(field) }
                                                                                    ]"
                                                                                    :canUpdate="$can('projects.update-only')"
                                                                                    @destroy="localOnTagRemove(tag)"
                                                                                    :key="tag.id"
                                                                                    tile
                                                                                ></g-tags>
                                                                            </template>
                                                                            <template v-if="!$can('projects.update-only') && !localGetSelectedTags(local_get_tag_slug(field), 'custom', 'length')">
                                                                                <span class="grey--text md-body-2">No Tags Added</span>
                                                                            </template>
                                                                            <template v-if="$can('projects.update-only') && !localProjectIsUpdate">
                                                                                <div
                                                                                    v-if="tagSearchType !== local_get_tag_slug(field)"
                                                                                    style="flex-grow: 1; height: 24px;"
                                                                                    :class="['d-inline-flex align-center u-cursor-pointer', { 'mb-3': localGetSelectedTags(local_get_tag_slug(field), 'custom', 'length') }]"
                                                                                    @click.stop="localShowTagSearchInput(local_get_tag_slug(field))"
                                                                                >
                                                                                    <a-icon color="grey" size="16" class="mr-1">add</a-icon>
                                                                                    <a-sheet class="md-body-2 grey--text" min-width="240">
                                                                                        <template v-if="localGetSelectedTags(local_get_tag_slug(field), 'custom', 'length')">
                                                                                            {{ $can('projects.update-only') && $can('tags.store') ? 'Add Tag' : 'Select Existing Tag' }}
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <template v-if="$can('projects.update-only') && $can('tags.store')">Create New or Add Existing Tag</template>
                                                                                            <template v-else>Select Existing Tag</template>
                                                                                        </template>
                                                                                    </a-sheet>
                                                                                </div>
                                                                                <STagsCombobox
                                                                                    v-if="tagSearchType === local_get_tag_slug(field)"
                                                                                    :initialLoading="mixinIsLoading('tag-search-' + local_get_tag_slug(field))"
                                                                                    :tagType="local_get_tag_slug(field)"
                                                                                    :id="local_get_tag_slug(field) + 'TagSearchInput'"
                                                                                    :tagList="localFilteredTags"
                                                                                    :tagSearchType="tagSearchType"
                                                                                    :tagsSelected="localGetSelectedTags(local_get_tag_slug(field), 'custom')"
                                                                                    :tagsTotalCount="tag_types_count[tagSearchType]"
                                                                                    :tagsCountLimit="tagsCountLimit"
                                                                                    :canCreate="$can('projects.update-only') && $can('tags.store')"
                                                                                    @reset="localResetTagIndices"
                                                                                    @backspace="localOnBackspace"
                                                                                    @escape="localOnEscape"
                                                                                    @openDropdown="localShowTagSearchInput(local_get_tag_slug(field))"
                                                                                    @search="localSearchTags($event, 'custom')"
                                                                                    @create="localCreateTagNew({ ...$event, tagModule: 'custom' })"
                                                                                    @select="localAttachTagsToProject({ ...$event, mode: 'custom' })"
                                                                                ></STagsCombobox>
                                                                            </template>
                                                                        </div>
                                                                        <a-spacer></a-spacer>
                                                                        <slot name="visibility">
                                                                            <s-tag-confirmation
                                                                                :loading="localVisibilityLoading"
                                                                                :tag-type="field.parent.status ? field.parent.status : field.parent.slug"
                                                                                :disabled="!$can('projects.update-only') || localProjectIsUpdate"
                                                                                :is-public="localHasVisibility('key', field.parent_type === 'CustomTagSection' ? local_get_tag_slug(field) : `project_${local_get_tag_slug(field)}`) === 'external-ready-only'"
                                                                                @action="(event) => localVisibilityUpdate(event, field.parent)"
                                                                                :org-visibility="localHasOrgVisibility('key', field.parent_type === 'CustomTagSection' ? local_get_tag_slug(field) : `project_${local_get_tag_slug(field)}`)"
                                                                                :type="localHasTagLevel(field.parent_type === 'CustomTagSection' ? local_get_tag_slug(field) : `project_${local_get_tag_slug(field)}`)"
                                                                                @revert="localvisibilityRevert(field.parent_type === 'CustomTagSection' ? local_get_tag_slug(field) : `project_${local_get_tag_slug(field)}`)"
                                                                                class="d-inline-block mr-7"
                                                                                dense
                                                                            ></s-tag-confirmation>
                                                                        </slot>
                                                                    </a-sheet>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </a-sheet>
                                                    <!-- <a-divider v-if="field.width === '100%'"></a-divider> -->
                                                </div>
                                            </a-sheet>
                                        </template>
                                    </div>
                                </a-sheet>
                            </div>
                        </template>
                    </div>
                    <template v-if="this.$can('projects.update-only')">
                        <div class="c-update-container u-border u-rounded-corners py-3 px-5 mt-6">
                            <div class="u-flex">
                                <div class="u-flex" v-if="$can('projects.update-only')">
                                    <a-btn @click="localProjectUpdate()" :loading="localProjectIsUpdate" height="32" color="#3949AB" :disabled="!localCheckProjectUpdate" small class="c-save-btn ma-0 mr-4 px-2 py-3 align-center white--text text--darken-2 u-rounded-corners">
                                        Save
                                    </a-btn>
                                    <a-btn @click="localProjectUpdateCancel()" :disabled="!localCheckProjectUpdate || localProjectIsUpdate" height="32" color="#EEEEEE" depressed tag="a" small class="ma-0 px-2 py-3 align-center text--darken-2 u-rounded-corners">
                                        <span style="color: #9E9E9E">Cancel</span>
                                    </a-btn>
                                    <a-sheet v-if="localProjectUpdateErrors.length" class="px-4 u-rounded-corners d-flex align-center red lighten-5 ml-4">
                                        <a-icon size="16px" color="red">info</a-icon>
                                        <p class="ml-2 font-weight-medium mb-0 red--text text--darken-1">One or more fields are invalid</p>
                                    </a-sheet>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </template>
        </div>
        <a-dialog v-model="dialogMaskUpdateForm" persistent max-width="500">
            <SUpdateMaskedFieldPopup
                :item="selectCustomFieldItem"
                :is-loading="maskCustomFieldLoading"
                :is-modal-open="dialogMaskUpdateForm"
                @action="(mask_value) => localUpdateMaskCustomField(mask_value)"
                @close="localUpdateMaskCustomFieldClose()"
            />
        </a-dialog>

        <a-dialog v-model="dialogCancelPageModal" persistent max-width="400">
            <SCancelChanges
                @success="locaProjectChangesClear"
                @close="dialogCancelPageModal = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to cancel?</template>
                <template #successButtonLabel>Yes, cancel</template>
                <template #cancelButtonLabel>No</template>
            </SCancelChanges>
        </a-dialog>

        <a-dialog v-model="dialogMaskUpdateError" persistent max-width="400">
            <SUpdateMaskedFieldErrorPopup
                :item="selectCustomFieldItem"
                @close="localUpdateMaskCustomFieldBack()"
            />
        </a-dialog>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import mixinErrors from '@/mixins/mixin-module-error-setup'
import { PTagSearchInput } from './Partials'
import { STagsCombobox, STagConfirmation, SUpdateMaskedFieldPopup, SUpdateMaskedFieldErrorPopup, SCancelChanges } from '@/config/config-shared-components'
import { Base64 } from 'js-base64';

let fieldsCount = 0
export default {
    mixins: [
        mixinLoading,
        mixinErrors,
    ],

    components: {
        PTagSearchInput,
        STagConfirmation,
        STagsCombobox,
        PTagSearchInput,
        SUpdateMaskedFieldPopup,
        SUpdateMaskedFieldErrorPopup,
        SCancelChanges,
    },

    data () {
        return {
            /* Boolean */
            loading: true,
            localVisibilityLoading: false,
            showSearchInputDropdown: true,
            dialogMaskUpdateForm: false,
            dialogMaskUpdateError: false,
            maskCustomFieldLoading: false,
            dialogCancelPageModal: false,
            localProjectIsUpdate: false,

            /* Array */
            tagModels: [],
            fieldList: [],
            loadedTagModules: [],

            /* Object */
            visibilityItem: null,
            currentTagList: [],
            removedTags: [],
            tagBulkAttachIds: [],
            systemTagTypes: ['label', 'product', 'platform'],

            /* Number */
            bulkChunkCount: 50,
            tagsCountLimit: 150,
            waitTimeBeforeReset: 50000, // 5mins

            /* Null Types */
            tagRemoveType: null,
            tagSearchType: null,
            tagConfirmRemoveIndex: null,
            currentSelectionIndex: null,
            tagRemoveTimeout: null,
            tagBulkAttachTimeout: null,
            tagDestroyTimeout: null,
            tagSearchTimeout: null,
            project_tag_type: null,
            autosave_custom_field_timeout: null,
            islocalProjectTagsUpdate: null,
            islocalProjectCustomFieldUpdate: null,
            islocalProjectVisibilityUpdate: null,
            field_counter: 0,

            /* Object */
            localProjectItem: {},
            loadedTagsList: {},
            currentTagToRemove: {},
            selectCustomFieldItem: {},
            // selectedTags: { label: [], product: [], platform: [], customTags: [] },
            selectedTags: {},
            createTagItem: { id: 'create', label: 'Create', value: 'create' },
            autosave_custom_field: { id: null, status: 'none' },
            project_filters: {
                'include': 'customFieldValues,tags',
                'fields[projects]': 'id',
                'fields[customFieldValues]': 'id,custom_field_id,resource_id,value,resource_type',
                'fields[tags]': 'tags.id,tags.label,tags.color,tags.type',
                'filter[layout_designer]': 1
            },
            datepickerDateValue: {},
            datepickerMenu: [],
            localLayoutDesignList: [],
            localClonelayoutDesignList: [],
            localProjectCustomFieldValues: [],
            localProjectUpdateErrors: [],
            localProjectSelectedTags: [],
            localProjectRemovedTags: [],
            localVisibilityList: [],
            localVisibilityUpdateList: [],
            localVisibilityRemovedList: [],
            custom_field_types: [
                { type: 'text', icon: 'text_fields' },
                { type: 'multiline', icon: 'subject' },
                { type: 'dropdown', icon: 'arrow_drop_down_circle' },
                { type: 'mask', icon: 'password' },
                { type: 'date', icon: 'calendar_month' },
                { type: 'tags', icon: 'sell' },
                { type: 'url', icon: 'link' },
                { type: 'number', icon: 'pin' },
            ],
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
        }
    },

    mounted () {
        document.addEventListener('keydown', this.localKeyDown)
        this.localIndex()
        window.addEventListener('beforeunload', this.localBeforeUnloadEvent)
        fieldsCount = 0
    },

    beforeDestroy () {
        document.removeEventListener('keydown', this.localKeyDown)
        window.removeEventListener('beforeunload', this.localBeforeUnloadEvent)
    },

    computed: {
        localProjectId () {
            return this.$route.params.id
        },

        localCheckProjectUpdate () {
            return (this.islocalProjectTagsUpdate === false || this.islocalProjectCustomFieldUpdate === false || this.islocalProjectVisibilityUpdate === false)
        },

        localCheckProjectPermission () {
            return (this.$can('projects.update-only') && !this.localProjectIsUpdate)
        },

        localFilteredWidgetTags () {
            const tagWidgets = [
                { name: 'Label', type: 'label', title: 'Labels', subtitle: 'Add/Edit Labels', show: this.localHasPreference('labels') },
                { name: 'Product', type: 'product', title: 'Products', subtitle: 'Add/Edit Product Tags', show: this.localHasPreference('products') },
                { name: 'Platform', type: 'platform', title: 'Platforms', subtitle: 'Add/Edit Platform Tags', show: this.localHasPreference('platforms') }
            ]
            return tagWidgets.filter(item => item.show)
        },

        localFilteredWidgetCustomTags () {
            return this.tag_section_list.filter(item => this.localHasPreference(item.slug))
        },

        localIsTagsVisible () {
            return (this.localFilteredWidgetCustomTags && this.localFilteredWidgetCustomTags.length) ||
                    (this.localFilteredWidgetTags && this.localFilteredWidgetTags.length)
        },

        // localFilteredTags () {
        //     //if (!this.tagSearchType) return []
        //     const isCustomTag = !this.systemTagTypes.includes(this.tagSearchType)
        //     return this.currentTagList.filter(item => {
        //         const index = this.selectedTags[isCustomTag ? 'customTags' : this.tagSearchType].findIndex(i => i.id === item.id)
        //         return index !== -1 ? null : item
        //     })
        // },

        localFilteredTags () {
            return this.currentTagList.filter(item => {
                const index = this.selectedTags[this.tagSearchType].findIndex(i => i.id === item.id)
                return index !== -1 ? null : item
            })
        },

        local_screen_lg() {
            return this.$vuetify.breakpoint.lgAndDown
        },

        ...mapState('ProjectView', {
            project_item: 'item',
        }),

        ...mapState('CustomField', {
            custom_field_list: 'list',
        }),

        ...mapState('CustomFieldValue', {
            custom_field_value_response: 'response'
        }),

        ...mapState('Association', {
            association_response: 'response'
        }),

        ...mapState('TagSection', {
            tag_section_list: 'list',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
            tag_types_count: 'types_count',
            tag_response: 'response'
        }),

        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key',
        }),

        ...mapGetters('Visibility', {
            visibility_get_visibility_item: 'get_list_item',
        }),

        ...mapState('Visibility', {
            visibility_list: 'list',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
            meta_filters: 'filters',
        })
    },

    methods: {
        async localIndex () {
            this.$vuetify.goTo(0)
            this.localFetchPreferences()
            await this.visibility_clear()
            this.localFetchVisibility()
            this.localGetTagTypesCount()
            await this.localProjectShow()
            await this.localLoadTagSections()
            await this.localLoadSystemTagTypes()
            await this.localSetProjectCustomFieldLayoutValues()
            this.loading = false
        },

        async localLoadSystemTagTypes () {
            await this.meta_index({ 'filter[type]': 'system_tag' })
            this.type_list = _.cloneDeep(this.meta_list)
        },

        async localSetProjectCustomFieldLayoutValues () {
            await this.localSetProjectCustomFieldValues()
            await this.localSetProjectTags()
            await this.localSetProjectSoloFields()
            this.localClonelayoutDesignList = _.cloneDeep(this.localLayoutDesignList)
        },

        /* Visibility - Start */
        localHasVisibility (field, value) {
            const data = this.visibility_get_visibility_item(field, value)
            if(!data) return
            return data && data.project_visibility ? data.project_visibility.visibility : data.visibility
        },

        localHasOrgVisibility (field, value) {
            const data = this.visibility_get_visibility_item(field, value)
            if(!data) return
            return data.visibility
        },

        localHasTagLevel(value) {
            const data = this.visibility_get_visibility_item('key', value)
            return data && data.project_visibility ? 'projectTag' : 'organizationTag'
        },

        async localvisibilityRevert(value) {
            const data = this.visibility_get_visibility_item('key', value)
            if(data && !data.project_visibility) return
            this.localVisibilityRemovedGet(data.project_visibility)
            this.visibility_remove_item({id: data.id })
            this.localGetProjectVisibilityUpdate()
        },

        async localVisibilityRemovedGet (data) {
            const index = this.localVisibilityUpdateList.findIndex(item => item.key === data.key)
            if (index !== -1) this.localVisibilityUpdateList.splice(index, 1)

            const removeIndex = this.localVisibilityRemovedList.findIndex(item => item.key === data.key)
            if (removeIndex === -1) this.localVisibilityRemovedList.push({id: data.id, key : data.key })
        },

        async localVisibilityUpdate(event, section) {
            await this.localGetVisibilityItem(event, section)
            await this.visibility_list_update(this.visibilityItem)
            this.localVisibilityUpdateGet(this.visibilityItem)
            this.localGetProjectVisibilityUpdate()
        },

        async localVisibilityUpdateGet (data) {
            const index = this.localVisibilityUpdateList.findIndex(item => item.id === data.id)
            if (index === -1) this.localVisibilityUpdateList.push(_.cloneDeep(data))
            else this.localVisibilityUpdateList[index].visibility = data.visibility

            const removeIndex = this.localVisibilityRemovedList.findIndex(item => item.key === data.key)
            if (removeIndex !== -1) this.localVisibilityRemovedList.splice(removeIndex, 1)
        },

        localGetVisibilityItem(event, section) {
            this.visibilityItem = {}
            this.visibilityItem.resource_type = 'Project'
            this.visibilityItem.resource_id = this.$route.params.id
            if(section.status) {
                this.visibilityItem.key = `project_${section.status}`
                // this.visibilityItem.section_type = 'TagSection'
                // this.visibilityItem.section_id = section.id
            }
            if(section.slug && section.id) {
                this.visibilityItem.key = section.slug
                this.visibilityItem.section_type = 'TagSection'
                this.visibilityItem.section_id = section.id
            }
            const data = this.visibility_get_visibility_item('key', this.visibilityItem.key)
            if(data) this.visibilityItem.id = data.id
            this.visibilityItem.visibility = event
        },

        async localFetchVisibility () {
            await this.visibility_index({
                'include': 'projectVisibility',
                'filter[resource_type]': 'Organization',
                'project_id': this.$route.params.id,
                'fields[visibilities]': 'id,key,visibility',
            })
            this.localVisibilityList = _.cloneDeep(this.visibility_list)
        },

        async localShowVisibility (id) {
            this.visibility_show({
                'id': id,
                'include': 'projectVisibility',
                'filter[resource_type]': 'Organization',
                'project_id': this.project_item.id,
                'fields[visibilities]': 'id,key,visibility',
            })
        },
        /* Visibility - End */

        async localFetchPreferences () {
            this.preference_index()
        },

        async localProjectShow() {
            await this.project_show({
                id: this.$route.params.id,
                params: { ...this.project_filters }
            })
            this.localProjectItem = _.cloneDeep(this.project_item)
            this.localLayoutDesignList = _.cloneDeep(this.project_item.layoutDesigner)
        },

        async localLoadTagSections () {
            await this.tag_section_index({
                'filter[is_active]': 1,
                'fields[tag_sections]': 'id,name,slug,is_active'
            })
        },

        async localGetTagTypesCount () {
            await this.tag_fetch_types_count()
        },


        localSetProjectTags () {
            this.tag_section_list.forEach(section => {
                this.selectedTags[section.slug] = []
            })

            this.type_list.forEach(section => {
                this.selectedTags[section.status] = []
            })

            this.localProjectItem.tags.forEach(tag => {
                this.selectedTags[tag.type].push(tag)
            })
        },

        async local_load_custom_fields () {
            await this.custom_field_value_clear_item()
            await this.custom_field_index({
                'fields[custom_fields]': 'id,name,label,is_active,order,placeholder,type,options,user_writable,description',
                'filter[is_active]': 1,
                'filter[resource_type]': 'Project',
            })
        },

        localSetProjectCustomFieldValues () {
            if (this.project_item) {
                const field_values = _.cloneDeep(this.project_item.custom_field_values)
                this.localLayoutDesignList.forEach(section => {
                    section.fields.forEach(field => {
                        const hasFieldValue = field_values.find(value => value.custom_field_id === field.parent.id)
                        if (hasFieldValue) {
                            hasFieldValue.mask_value = field.parent.type === 'mask' ? hasFieldValue.value : ''
                            field.parent = Object.assign(field.parent, { custom_field_value: hasFieldValue })
                        }
                        else field.parent = Object.assign(field.parent, { custom_field_value: { value: '', label: '', mask_value: ''  } })
                    })
                })

                this.localLayoutDesignList.forEach((section, sectionIndex) => {
                    section.fields.forEach((field, index) => {
                        this.datepickerMenu.push(field.parent.id)
                        if (field.parent.type === 'date') {
                            let date_value = field.parent.custom_field_value.value
                            this.datepickerDateValue[field.parent.id] = date_value ? this.local_format_date(date_value) : ''
                        }
                    })
                })
            }
        },

        async localProjectCustomFieldUpdate (custom_field, value) {
            if (custom_field.type === 'date') {
                let date_value = custom_field.custom_field_value.value
                this.datepickerDateValue[custom_field.id] = this.local_format_date(date_value)
                this.datepickerMenu[custom_field.id] = false
            }
            await this.localCustomFieldUpdate(custom_field, value)
            this.localGetProjectCustomFieldUpdate()
        },

        localProjectValidationCheck () {
            this.localProjectUpdateErrors = []
        },

        localCheckProjectUpdateErros () {
            this.localProjectCustomFieldValues.forEach(item => {
                const length = item.type === 'multiline' ? 50000 : 255
                if (!['date','url','number'].includes(item.type) && item.custom_field_value.value && item.custom_field_value.value.trim().length > length) {
                    const customFieldErroritem = {}
                    customFieldErroritem.name = item.name
                    customFieldErroritem.message = `Only maximum of ${length} characters allowed.`
                    this.localProjectErrorMessageUpdate(customFieldErroritem)
                }
                if (item.type === 'number') this.localNumberValidation(item) 
                if (item.type === 'url') this.localCheckProjectUpdateUrlErrors(item)
            });
            if (this.localProjectUpdateErrors.length) return true
            return false
        },

        localNumberValidation (item) {
            const value = item.custom_field_value ? item.custom_field_value.value : null
            if (!value) return
            if (isNaN(value) === true) {
                const customFieldErroritem = {}
                customFieldErroritem.name = item.name
                customFieldErroritem.message = `The ${item.label} must be number. `
                this.localProjectErrorMessageUpdate(customFieldErroritem)
                return
            }
            const decimal = /^(?:\d*\.\d{1,2}|\d+)$/
            if (decimal.test(value) === false) {
                const customFieldErroritem = {}
                customFieldErroritem.name = item.name
                customFieldErroritem.message = `Only numbers with up to two decimal places are allowed.`
                this.localProjectErrorMessageUpdate(customFieldErroritem)
            }
            const maxNumber = 1000000000.00
            if (value < 0.00 || value > maxNumber) {
                const customFieldErroritem = {}
                customFieldErroritem.name = item.name
                customFieldErroritem.message = `The ${item.label} must be between 0.00 and ${maxNumber}. `
                this.localProjectErrorMessageUpdate(customFieldErroritem)
            }
        },

        localIsNumber (evt, type) {
            if (type !== 'number') return
            const charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            }
        },

        localCheckProjectUpdateUrlErrors (item) {
            const value = item.custom_field_value.value.trim()
            if (!value) return
            const customFieldErroritem = {}
            customFieldErroritem.name = item.name
            const isValid = this.localIsUrlValid(value)
            const urlLength = 2048
            const maxLengthUrl = value.length > urlLength
            if (!isValid) {
                customFieldErroritem.message = `The url format is invalid.`
                this.localProjectErrorMessageUpdate(customFieldErroritem)
                return
            }
            if (maxLengthUrl) {
                customFieldErroritem.message = `The url maximum of ${urlLength} characters.`
                this.localProjectErrorMessageUpdate(customFieldErroritem)
                return
            }
        },

        localIsUrlValid (value) {
            try {
                const newUrl = new URL(value);
                return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
            } catch (err) {
                return false;
            }
        },

        localGetProjectErrorMessage (name) {
            const item = this.localProjectUpdateErrors.find(list => list.name === name)
            if (item) return item.message
            return null
        },

        localProjectErrorMessageUpdate (item) {
            const index = this.localProjectUpdateErrors.findIndex(list => list.id === item.name)
            if (index === -1) this.localProjectUpdateErrors.push(_.cloneDeep(item))
            else this.localProjectUpdateErrors[index].message = item.message
        },

        localCustomFieldUpdate (custom_field, value) {
            const index = this.localProjectCustomFieldValues.findIndex(item => item.name === custom_field.name)
            if (index === -1) {
                this.localProjectCustomFieldValues.push(_.cloneDeep(custom_field))
            }else {
                this.localProjectCustomFieldValues[index].custom_field_value.value = value
            }
        },

        local_format_date(date) {
            return date ? moment(date).format('MMM D, YYYY') : null
        },

        localClearDate(resource_type, resource_id, custom_field, index) {
            custom_field.custom_field_value.value = null
            this.localGetProjectCustomFieldUpdate()
        },

        localSelectDatepicker(custom_field) {
            this.datepickerMenu[custom_field.id] = false
            let value =  custom_field.custom_field_value ? custom_field.custom_field_value : ''
            this.localProjectCustomFieldUpdate(custom_field, value)
        },

        local_can_update(field) {
            return !(this.$can('projects.update-only')) && !(this.autosave_custom_field.id !== field.parent.id && (this.autosave_custom_field.status === 'loading' || this.autosave_custom_field.status === 'error')) || field.parent.user_writable === 0
        },

        async localSelectCustomFieldItem(section_id, field_id, custom_field_item) {
            this.selectCustomFieldItem = {}
            this.selectCustomFieldItem = custom_field_item
            this.selectCustomFieldItem.section_id = section_id
            this.selectCustomFieldItem.field_id = field_id
            this.maskCustomFieldLoading = false
            this.dialogMaskUpdateForm = true
        },

        async localUpdateMaskCustomField (value) {
            const index = this.localClonelayoutDesignList.findIndex(item => item.id === this.selectCustomFieldItem.section_id)
            if (index !== -1) {
                const layoutFields = this.localClonelayoutDesignList[index].fields
                const fieldIndex = layoutFields.findIndex(item => item.id === this.selectCustomFieldItem.field_id)
                if (fieldIndex !== -1) {
                    const fieldparent = this.localClonelayoutDesignList[index].fields[fieldIndex].parent
                    if (fieldparent) {
                        this.localClonelayoutDesignList[index].fields[fieldIndex].parent.custom_field_value.value = value
                        this.localClonelayoutDesignList[index].fields[fieldIndex].parent.custom_field_value.mask_value = this.localCustomFieldMaskedString(value, '*')
                        this.localProjectCustomFieldUpdate(this.localClonelayoutDesignList[index].fields[fieldIndex].parent, value)
                    }
                }
            }
            this.dialogMaskUpdateForm = false
        },


        localCustomFieldMaskedString (value, maskChar) {
            if (value && value.trim()) {
                let maskedString = '';
                for (let i = 0; i < value.length; i++) {
                    maskedString += maskChar;
                }
                return maskedString;
            }
        },

        localUpdateMaskCustomFieldClose () {
            this.dialogMaskUpdateForm = false
        },

        async localCheckUpdateMaskCustomField() {
            this.maskCustomFieldLoading = false
            if (this.custom_field_value_response.status === 'success') {
                await this.localShowUpdateCustomField()
                this.dialogMaskUpdateForm = false
                return
            }
            if(this.custom_field_value_response.server && this.custom_field_value_response.server.errors && this.custom_field_value_response.server.errors.user_writable) {
                this.dialogMaskUpdateForm = false
                this.dialogMaskUpdateError = true
            }
        },

        async localUpdateMaskCustomFieldBack() {
            this.selectCustomFieldItem.user_writable = 0
            this.custom_field_value_update_item(this.selectCustomFieldItem)
            this.dialogMaskUpdateError = false
        },

        async localShowUpdateCustomField () {
            await this.project_show({
                id: this.$route.params.id,
                params: {
                    'include': 'customFieldValues',
                    'fields[projects]': 'id',
                    'fields[customFieldValues]': 'id,custom_field_id,resource_id,value',
                }
            })
            this.localSetProjectCustomFieldValues()
        },

        localGetWidth(index, type) {
            if (type === 'multiline') {
                fieldsCount = 0
                return '100%'
            }
            if (fieldsCount === this.fieldList.length) {
                fieldsCount = 0
            }
            fieldsCount++
            if ((fieldsCount % 2 !== 0) && (index === this.fieldList.length - 1 || this.fieldList[index + 1].type === 'multiline')) {
                return '100%'
            }
            return '50%'
        },
        /* Custom Fields - End  */

        /* Tags - Start */
        async localShowTagSearchInput (type) {
            this.mixinSetLoading('tag-search-' + type)
            this.localResetTagIndices()
            this.tagSearchType = type
            if (this.localIsTagModuleLoaded(type)) {
                this.currentTagList = _.cloneDeep(this.loadedTagsList[type])
                return this.mixinResetLoading('tag-search-' + type)
            }
            this.currentTagList = []
            await this.tag_index({
                count: this.tag_types_count[type] <= this.tagsCountLimit ? this.tag_types_count[type] : this.tagsCountLimit,
                sort: 'order',
                'filter[source_id]': this.localProjectId,
                'filter[type]': this.tagSearchType,
                'fields[tags]': 'id,label,color,type'
            })
            this.currentTagList = this.tag_list
            this.loadedTagsList[type] = _.cloneDeep(this.tag_list)
            this.localSetTagModuleLoad(type)
            this.mixinResetLoading('tag-search-' + type)
        },

        localSetTagModuleLoad (type) {
            const index = this.loadedTagModules.indexOf(type)
            if (index === -1) this.loadedTagModules.push(type)
            setTimeout(() => this.localUnsetTagModuleLoad(type), this.waitTimeBeforeReset)
        },

        localUnsetTagModuleLoad (type) {
            const index = this.loadedTagModules.indexOf(type)
            if (index !== -1) this.loadedTagModules.splice(index, 1)
        },

        localIsTagModuleLoaded (type) {
            const index = this.loadedTagModules.indexOf(type)
            return index !== -1
        },

        localGetSelectedTags (type, mode = null, field = null) {
            let result = []
            result = this.selectedTags[type] || []
            return field === 'length' ? result.length : result
        },

        async localCreateTagNew (createProps) {
            const { value: searchText, tagModule: mode, showError, showSuccess } = createProps || {}
            const hasPermission = this.$can('projects.update-only') && this.$can('tags.store')
            if (!hasPermission) return this.mixinResetLoading('tag-create')

            const tagItem = this.$appendId({ color: this.$randomColor(), label: searchText, type: this.tagSearchType })
            await this.tag_store(tagItem)
            if (!this.$status(this.tag_response)) {
                this.mixinSetError({ slug: 'tag-create', message: this.$response(this.tag_response, 'label') })
                this.mixinResetLoading('tag-create')
                showError(this.$response(this.tag_response, 'label'))
                return true
            }

            showSuccess()
            this.selectedTags[this.tagSearchType].push(tagItem)
            await this.localAttachTag(tagItem, 'new')
            this.localGetProjectSelectedTag(tagItem)
            this.localGetProjectTagsUpdate()
        },

        async localAttachTagsToProject (params) {
            const { tag, showSuccess, mode = null } = params || {}
            clearTimeout(this.tagBulkAttachTimeout)
            this.localResetTagIndices()

            this.selectedTags[this.tagSearchType].push(tag)
            await this.localAttachTag(tag, mode)
            this.localGetProjectSelectedTag(tag)
            this.localGetProjectTagsUpdate()
            showSuccess()
        },

        localAddTagToProject (tag) {
            const project = _.cloneDeep(this.project_item)
            const projTags = (project && project.tags) ?? []

            this.$set(project, 'tags', [...projTags, tag])
            this.project_replace_item(project)
        },

        async localAttachTag (tag, mode = null) {
            const tagType = ['label', 'product', 'platform'].includes(tag.type) ? `project_${tag.type}` : tag.type
            const data = this.$appendId({
                source_type: 'Project',
                source_id: this.localProjectId,
                target_type: 'Tag',
                target_id: tag.id,
                type: tagType
            })

            if (mode === 'new') {
                this.localAttachTagAssociation(tag, data)
            } else this.tagBulkAttachIds.push({ id: data.id, target_id: tag.id })

            this.localAttachAssociationToLocal(data, mode, tag)
        },

        async localAttachTagAssociation (tag, association) {
            const index = this.localProjectItem.tags.findIndex(item => item.id === tag.id)
            if (index === -1) {
                this.localProjectItem.tags.push(_.cloneDeep(tag))
                return
            }

            Object.assign(this.localProjectItem.tags[index], { association })
            this.localAddTagsToLoadedMods(_.cloneDeep(tag))
        },

        localAttachAssociationToLocal (association, mode = null, addedTag) {
            const selectType = this.tagSearchType
            const index = this.selectedTags[selectType].findIndex(item => item.id === association.target_id)
            if (index !== -1) Object.assign(this.selectedTags[selectType][index], { association: { ...association } })
            this.localProjectItem.tags.push(addedTag)
            this.localAddTagsToLoadedMods(addedTag)
        },

        localAddTagsToLoadedMods (tag) {
            if (this.loadedTagsList[tag.type]) {
                const index = this.loadedTagsList[tag.type].findIndex(i => i.id === tag.id)
                if (index === -1) this.loadedTagsList[tag.type].push(_.cloneDeep(tag))
            }
            else this.loadedTagsList[tag.type] = _.cloneDeep([tag])

            this.currentTagList = [...this.loadedTagsList[tag.type]]
        },
        /* Tags - End */

        /* Preference - Start */
        localHasPreference (field, key = 'value') {
            fieldsCount = 0
            const projectPreset = 'organization.projects.widgets'
            const result = this.preference_get_property_by_key(`${projectPreset}.${field}`, key)
            return key === 'value' ? (result === 'Yes') : result
        },
        /* Preference - End */

        /* Custom tag preference - Start */
        localCheckCustomTagPreference (customTagType) {
            const result = this.localHasPreference(customTagType, key)
            //return key === 'value' ? (result === 'Yes') : result
            return tagWidgets.filter(item => item.show)
        },
        /* Custom tag preference - End*/

        /* Extras */
        async localSearchTags ({ type, value, clearLoading, showError }) {
            this.localResetTagIndices()
            if (!this.tag_types_count[type] || (this.tag_types_count[type] <= this.tagsCountLimit)) {
                this.currentTagList = [...this.loadedTagsList[type], ...[this.createTagItem]]
                return clearLoading()
            }

            await this.tag_index({
                count: this.tagsCountLimit,
                sort: 'order',
                'filter[type]': this.tagSearchType,
                'filter[source_id]': this.localProjectId,
                'filter[label_filter]': (value && value.toLowerCase()) || '',
                'fields[tags]': 'id,label,color,type',
            })
            this.currentTagList = [...this.tag_list, ...[this.createTagItem]]
            clearLoading()
        },

        localResetTagIndices () {
            this.currentSelectionIndex = null
            this.tagConfirmRemoveIndex = null
        },

        localKeyDown (evt) {
            //if (!this.tagSearchType) return

            //this.localOnArrowLeft(evt)
            //this.localOnArrowRight(evt)
        },

        localOnArrowLeft (evt) {
            if (evt.code !== 'ArrowLeft' || !evt.shiftKey) return
            if (evt.ctrlKey) return
            this.localTraverseLeft()
        },

        localTraverseLeft () {
            if (!this.$can('projects.update-only')) return
            if (this.tagConfirmRemoveIndex) this.tagConfirmRemoveIndex = null

            const currentTypeTags = this.localGetSelectedTags(this.tagSearchType, null)
            this.currentTagToRemove = currentTypeTags.slice(-1)[0]

            if (this.currentSelectionIndex === null) return this.currentSelectionIndex = currentTypeTags.length - 1
            if (this.currentSelectionIndex > 0) return this.currentSelectionIndex--
            if (this.currentSelectionIndex === 0 && currentTypeTags.length === 1) return this.currentSelectionIndex = currentTypeTags.length - 1
            if (this.currentSelectionIndex === 0) return this.currentSelectionIndex = currentTypeTags.length - 1
        },

        localOnArrowRight (evt) {
            if (evt.code !== 'ArrowRight' || !evt.shiftKey) return
            if (evt.ctrlKey) return

            if (!this.$can('projects.update-only')) return
            if (this.tagConfirmRemoveIndex) this.tagConfirmRemoveIndex = null

            const currentTypeTags = this.localGetSelectedTags(this.tagSearchType, null)
            const listLength = currentTypeTags.length

            if (this.currentSelectionIndex === listLength - 1) return this.currentSelectionIndex = 0
            if (this.currentSelectionIndex === 0 && listLength === 1) return this.currentSelectionIndex = 0
            if (this.currentSelectionIndex >= 0 && listLength > 1) return this.currentSelectionIndex++
        },

        localOnEscape (evt) {
            if (evt.code !== 'Escape') return
            if (evt.shiftKey || evt.ctrlKey) return

            if (this.tagConfirmRemoveIndex) this.tagConfirmRemoveIndex = null
            if (this.currentSelectionIndex !== null) this.currentSelectionIndex = null

            this.tagSearchType = null
        },

        localOnBackspace (evt) {
            if (!['Backspace', 'Delete'].includes(evt.code)) return
            if (evt.shiftKey || evt.ctrlKey) return

            if (this.currentSelectionIndex === null) this.localTraverseLeft()
            if (this.tagConfirmRemoveIndex !== null) {
                this.tagRemoveType = this.tagSearchType
                return this.localTagRemove()
            }
            if (this.currentSelectionIndex !== null) return this.localGetTagRemoveConfirm()
        },

        localOnTagRemove (tag) {
            const tagToRemove = _.cloneDeep(tag)
            this.tagRemoveType = tag.type
            const tagType = tag.type

            const index = this.selectedTags[tagType].findIndex(i => i.id === tag.id)
            if (index === -1) return

            this.localSetRemovedTags(tagType, index)
            this.localResetTagIndices()

            const searchInputEl = document.getElementById(`refTagSearchInput${tagToRemove.type}`)
            if (searchInputEl) searchInputEl.focus()

            this.localAddRemovedTagToList(tagToRemove, true)
            this.localProjectItemTagsRemove(tagToRemove)
            this.localGetProjectTagsUpdate()
        },

        async localRemoveProjectTag (tag) {
            const remove_tag_index = this.localProjectRemovedTags.findIndex(item => item.id === tag.id)
            if (remove_tag_index === -1) {
                this.localProjectRemovedTags.push(_.cloneDeep(tag))
            }
        },

        localRemoveSelectedTag (tag) {
            const index = this.localProjectSelectedTags.findIndex(item => item.id === tag.id)
            if (index !== -1) this.localProjectSelectedTags.splice(index, 1)
        },

        localProjectItemTagsRemove (tag) {
            const index = this.localProjectItem.tags.findIndex(item => item.id === tag.id)
            if (index !== -1) this.localProjectItem.tags.splice(index, 1)
            this.localRemoveSelectedTag(tag)
            this.localRemoveProjectTag(tag)
        },

        localSetRemovedTags (tagType, index) {
            const removedTag = this.selectedTags[tagType].splice(index, 1)
            this.removedTags = [...this.removedTags, ...removedTag]
            this.tagRemoveType = null
        },

        localAddRemovedTagToList (tagToRemove, noFocus = false) {
            this.localAddTagsToLoadedMods(tagToRemove)
        },

        localGetTagRemoveConfirm () {
            if (this.tagRemoveTimeout) clearTimeout(this.tagRemoveTimeout)

            this.tagConfirmRemoveIndex = this.currentSelectionIndex
            this.tagRemoveTimeout = setTimeout(() => this.tagConfirmRemoveIndex = null, 2000)
        },

        async localTagRemove (type) {
            clearTimeout(this.tagDestroyTimeout)
            type = type || this.tagRemoveType
            await this.localModifyRemovedTags(type)
        },

        localDestoryTag () {
            this.tagDestroyTimeout = setTimeout(async () => {
                const ceilCount = Math.ceil(this.removedTags.length / this.bulkChunkCount)
                for (let index = 0; index < ceilCount; index++) {
                    const chunkIds = this.removedTags.splice(0, this.bulkChunkCount)
                    if (!chunkIds || (chunkIds && !chunkIds.length)) continue
                    await this.association_bulk_destroy({ ids: chunkIds.map(i => i.association.id), source_id: this.localProjectId, source_type: 'Project' })
                }
                clearTimeout(this.tagDestroyTimeout)
            }, 1000)
        },

        async localModifyRemovedTags (type, isCustomTag) {
            const selectType = type
            let removedTag = null
            removedTag = this.selectedTags[selectType].splice(this.tagConfirmRemoveIndex, 1)

            const searchInputEl = document.getElementById(`refTagSearchInput${type}`)
            if (searchInputEl) searchInputEl.focus()

            if (this.tag_types_count[type] <= this.tagsCountLimit) {
                this.localAddRemovedTagToList(removedTag[0])
                this.localProjectItemTagsRemove(removedTag[0])
                this.localGetProjectTagsUpdate()
            }

            this.removedTags = [...this.removedTags, ...removedTag]
            this.tagConfirmRemoveIndex = null
            this.currentSelectionIndex = null
            this.currentTagToRemove = {}
        },

        local_get_custom_field_type_item(field) {
            if(field && field.parent_type === 'TagSection' || field.type === 'Tag') return 'sell'
            const type = field && field.parent && field.parent.type ? field.parent.type : field.type
            const custom_field_item = this.custom_field_types.find(item => item.type === type)
            if (custom_field_item) return custom_field_item.icon
        },

        local_get_tag_slug (tagSection) {
            if (tagSection.parent && tagSection.parent.slug) return tagSection.parent.slug
            return tagSection.parent.status
        },

        local_has_tag_fields(section) {
            if (section && section.fields) {
                const tagField = section.fields.filter(field => {
                    return field.parent_type !== 'CustomField'
                })

                return tagField && tagField.length
            }
        },

        localSetProjectSoloFields () {
            this.field_counter = 0
            this.localLayoutDesignList.forEach((section ,index) => {
                section.fields.forEach((item, fieldIndex) => {
                    item.solo_field = false
                    if (item.width === '50%') {
                        ++this.field_counter
                        if(this.field_counter % 2 !== 0 && (fieldIndex+1 < section.fields.length) && (section.fields[fieldIndex+1].width === '100%')) {
                            item.solo_field = true
                            this.field_counter = 0
                        } else if (this.field_counter % 2 === 0) {
                            item.solo_field = false
                            this.field_counter = 0
                        }
                    }
                    if (item.width === '100%') {
                        item.solo_field = false
                        this.field_counter = 0
                    }
                })
            })
        },

        local_show_divider (section) {
            if (section.fields.length === 1 && section.fields[0].width === '50%') return true
            if (section.fields.length > 1 && section.fields[0].width === '50%' && section.fields[1].width !== '50%') return true
        },

        local_show_item_divider (section, field, index) {
            if (index === 0 && section.fields.length === 1 && field.width === '50%') return false
            if (index === 0 && section.fields.length > 1 && field.width === '50%' && section.fields[1].width !== '50%') return false
            return !field.solo_field
        },

        async localGetProjectTagsUpdate () {
            this.islocalProjectTagsUpdate = _.isEqual(this.localProjectItem.tags, this.project_item.tags)
            if (this.islocalProjectTagsUpdate === false) this.$emit('unsaved')
            else this.$emit('saved')
        },

        async localGetProjectCustomFieldUpdate () {
            this.islocalProjectCustomFieldUpdate = _.isEqual(this.localLayoutDesignList, this.localClonelayoutDesignList)
            if (this.islocalProjectCustomFieldUpdate === false) this.$emit('unsaved')
            else this.$emit('saved')
        },

        async localGetProjectVisibilityUpdate () {
            this.islocalProjectVisibilityUpdate = _.isEqual(this.localVisibilityList, this.visibility_list)
            if (this.islocalProjectVisibilityUpdate === false) this.$emit('unsaved')
            else this.$emit('saved')
        },

        async localProjectUpdate () {
            this.localProjectUpdateErrors = []
            this.localProjectIsUpdate = true
            const data = await this.localCheckProjectUpdateErros()
            if (data) {
                this.localProjectNotifyResponse('One or more fields are invalid. Project could not be saved. Please update and try again.', 'error')
                this.localProjectIsUpdate = false
                return
            }
            await this.localProjectCustomFieldValuesUpdate()
            await this.localAddProjectTags()
            await this.localProjectTagsRemoved()
            await this.localProjectVisibilityUpdate()
            await this.localRemoveProjectVisibility()
            if (this.islocalProjectVisibilityUpdate === false) await this.localFetchVisibility()
            this.islocalProjectTagsUpdate = null
            this.islocalProjectCustomFieldUpdate = null
            this.islocalProjectVisibilityUpdate = null
            this.$emit('saved')
            await this.localProjectShow()
            this.localProjectIsUpdate = false
            this.localProjectNotifyResponse('Project Updated Successfully', 'success')
        },

        async localProjectVisibilityUpdate () {
            if (this.localVisibilityUpdateList.length) {
                const visibilityValueItem = {}
                visibilityValueItem.resource_type = 'Project'
                visibilityValueItem.resource_id = this.localProjectId
                visibilityValueItem.data = []

                this.localVisibilityUpdateList.forEach(item => {
                    const valueItem = {}
                    valueItem.id = item.id
                    valueItem.visibility = item.visibility
                    valueItem.key = item.key
                    valueItem.section_type = item.section_type
                    valueItem.section_id = item.section_id
                    visibilityValueItem.data.push(valueItem)
                });
                await this.visibility_bulk_upsert(visibilityValueItem)
                this.localVisibilityUpdateList = []
            }
        },

        async localRemoveProjectVisibility () {
            if (this.localVisibilityRemovedList.length) {
                const visibilityRemoveItem = {}
                visibilityRemoveItem.resource_type = 'Project'
                visibilityRemoveItem.ids = []

                this.localVisibilityRemovedList.forEach(item => {
                    visibilityRemoveItem.ids.push(item.id)
                });
                await this.visibility_bulk_destroy(visibilityRemoveItem)
                this.localVisibilityRemovedList = []
            }
        },

        localProjectNotifyResponse (content, type) {
            this.$notify(type, content)
        },

        async localProjectCustomFieldValuesUpdate () {
            if (this.localProjectCustomFieldValues.length) {
                const customFieldValueItem = {}
                customFieldValueItem.resource_type = 'Project'
                customFieldValueItem.resource_id = this.localProjectId
                customFieldValueItem.data = []

                this.localProjectCustomFieldValues.forEach(item => {
                    if (item.custom_field_value) {
                        const valueItem = item.custom_field_value.id ? item.custom_field_value : this.$appendId(item.custom_field_value)
                        if (item.type === 'multiline') valueItem.value = Base64.encode(valueItem.value)
                        valueItem.custom_field_id = item.id
                        valueItem.custom_field_option_id = item.type === 'dropdown' ? this.localGetCustomFieldOptionId(valueItem.value,  item.options) : null
                        valueItem.type = item.type
                        customFieldValueItem.data.push(valueItem)
                    }
                });
               await this.custom_field_value_bulk_store(customFieldValueItem)
               this.localProjectCustomFieldValues = []
            }
        },

        localGetCustomFieldOptionId (value, options) {
            if (options.length) {
                const customFieldOption = options.find(item => item.value === value)
                return customFieldOption ? customFieldOption.id : null
            }
            return null
        },

        localGetProjectSelectedTag (tag) {
            this.localProjectSelectedTags.push(_.cloneDeep(tag))
        },

        async localAddProjectTags () {
            if (this.localProjectSelectedTags.length) {
                const tagitem = {}
                tagitem.data = []
                this.localProjectSelectedTags.forEach(item => {
                    tagitem.data.push(this.$appendId({target_id : item.id, type : item.association.type  }))
                });
                tagitem.source_id = this.localProjectId
                tagitem.source_type = 'Project'
                tagitem.target_type = 'Tag'
                await this.association_bulk_all_store(tagitem)
                this.localProjectSelectedTags = []
            }
        },

        async localProjectTagsRemoved () {
            if (this.localProjectRemovedTags.length) {
                const removedtagitem = {}
                removedtagitem.ids = []
                this.localProjectRemovedTags.forEach(item => {
                    if (item.association) {
                        removedtagitem.ids.push(item.association.id)
                    }
                });
                removedtagitem.source_id = this.localProjectId
                removedtagitem.source_type = 'Project'
                await this.association_bulk_destroy(removedtagitem)
                this.localProjectRemovedTags = []
            }
        },

        localProjectUpdateCancel () {
            this.dialogCancelPageModal = true
        },

        async locaProjectChangesClear () {
            this.localProjectItem = _.cloneDeep(this.project_item)
            await this.visibility_list_replace(_.cloneDeep(this.localVisibilityList))
            await this.localSetProjectCustomFieldLayoutValues()
            this.localGetProjectTagsUpdate()
            this.localGetProjectCustomFieldUpdate()
            this.localGetProjectVisibilityUpdate()
            this.localProjectCustomFieldValues = []
            this.localProjectSelectedTags = []
            this.localProjectUpdateErrors = []
            this.localProjectRemovedTags = []
            this.localVisibilityUpdateList = []
            this.localVisibilityRemovedList = []
            this.dialogCancelPageModal = false
        },

        async localBeforeUnloadEvent (e) {
            if (this.localCheckProjectUpdate) {
                return e.returnValue = 'You have unsaved changes. Are you sure you want to leave?'
            }
        },

        localCheckUrlIcon (field) {
            return !(
                field.parent.custom_field_value.value &&
                this.localIsUrlValid(field.parent.custom_field_value.value) &&
                !this.localGetProjectErrorMessage(field.parent.name) &&
                this.localCheckProjectPermission &&
                field.parent.user_writable === 1
            )
        },

        localLinkOpenNewTab (link) {
            window.open(link, '_blank');
        },

        ...mapActions('ProjectView', {
            project_show: 'show',
            project_update_item: 'update_item',
            project_replace_item: 'replace_item',
        }),

        ...mapActions('Association', {
            association_store: 'store',
            association_bulk_store: 'bulk_store',
            association_bulk_all_store: 'bulk_all_store',
            association_destroy: 'destroy',
            association_bulk_destroy: 'bulk_destroy',
        }),

        ...mapActions('CustomField', {
            custom_field_index: 'index',
            custom_field_enabled: 'enabled',
        }),

        ...mapActions('CustomFieldValue', {
            custom_field_value_store: 'store',
            custom_field_value_bulk_store : 'bulk_store',
            custom_field_value_update_item: 'update_item',
            custom_field_value_clear_item: 'clear_item',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_fetch_types_count: 'fetch_types_count',
            tag_clear: 'clear',
            tag_clear_item: 'clear_item',
        }),

        ...mapActions('TagSection', {
            tag_section_index: 'index',
        }),

        ...mapActions('Visibility', {
            visibility_index: 'index',
            visibility_show: 'show',
            visibility_bulk_upsert : 'bulk_upsert',
            visibility_bulk_destroy: 'bulk_destroy',
            visibility_upsert_store: 'upsert',
            visibility_list_update: 'list_update',
            visibility_remove_item: 'remove_item',
            visibility_list_replace: 'list_replace',
            visibility_clear: 'clear',
            visibility_destroy: 'destroy',
        }),

        ...mapActions('Preference', {
            preference_index: 'index',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        })
    }
}

</script>

<style scoped>
    .c-section-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .c-field-card__half--preview {
        width: 50% !important;
    }

    .c-field-card__full{
        width: 100% !important;
    }

    .c-update-container {
        position: sticky !important;
        bottom: 0px;
        background: #FFF !important;
        width: 100%;
    }
</style>

<template>
    <a-sheet class="transparent" max-width="656">
        <div v-if="isLoading" class="">
            <loader-template width="100" height="16" class="mb-3 u-rounded-corners"></loader-template>
            <loader-template width="500" height="40" class="u-rounded-corners-xl"></loader-template>
        </div>
        <div v-else class="u-flex-center-y flex-wrap u-wfull mb-6">
            <template v-if="!['Pie', 'Donut', 'Numbers'].includes(selectedTile.chart_type)">
                <a-sheet class="mr-2 transparent" :width="!local_is_barline_charts ? '100%' : '314px'">
                    <div class="md-body-1 body--text text--lighten-1 mb-3 font-weight-medium">X-Axis</div>
                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('datapoints_xaxis')">
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="d-inline-flex" style="width: 314px;">
                                <a-select
                                    v-model="moduleItem.original.xaxis"
                                    :items="xaxis_list"
                                    placeholder="Ex: X-Axis"
                                    class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                    :class="[{ 'c-wiggle-short u-border-error': local_get_error('datapoints_xaxis') }]"
                                    item-color="indigo darken-1"
                                    background-color="white"
                                    :append-icon="!moduleItem.original.xaxis ? 'arrow_drop_down' : ''"
                                    :menu-props="{ rounded: 'lg', nudgeBottom: '4px', offsetY: true, closeOnContentClick: true, closeOnClick: true, }"
                                    @input="local_update('xaxis')"
                                    @click:clear="local_clear_datapoint_fields('xaxis')"
                                    solo flat hide-details clearable
                                ></a-select>
                            </div>
                        </template>
                        <span>{{ local_get_error('datapoints_xaxis') }}</span>
                    </a-tooltip>
                </a-sheet>
                <a-sheet :class="[{ 'mt-6': !local_is_barline_charts }, 'mr-2 transparent']" width="314px" v-if="!local_is_barline_charts">
                    <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">Grouping (Y-Axis)</span>
                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('datapoints_y_axis_group')">
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <a-select
                                    v-model="moduleItem.original.y_axis_group"
                                    :items="dpl_primary_slot"
                                    placeholder="Ex: Project -> Health"
                                    class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                    :class="[{ 'c-wiggle-short u-border-error': local_get_error('datapoints_y_axis_group') }]"
                                    item-color="indigo darken-1"
                                    background-color="white"
                                    :append-icon="!moduleItem.original.y_axis_group ? 'arrow_drop_down' : ''"
                                    :menu-props="{ rounded: 'lg', nudgeBottom: '4px', offsetY: true, closeOnContentClick: true, closeOnClick: true, }"
                                    @input="local_update('y_axis_group')"
                                    @click:clear="local_clear_datapoint_fields('y_axis_group')"
                                    solo flat hide-details clearable
                                ></a-select>
                            </div>
                        </template>
                        <span>{{ local_get_error('datapoints_y_axis_group') }}</span>
                    </a-tooltip>
                </a-sheet>
                <a-sheet :class="[{ 'mt-6': !local_is_barline_charts }, 'ml-2 transparent']" width="314px">
                    <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">Value (Y-Axis)</span>
                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('datapoints_y_axis_value')">
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <a-select
                                    v-model="moduleItem.original.y_axis_value"
                                    :items="aggregate_datapoints_list"
                                    placeholder="Ex: Count of Project"
                                    class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                    :class="[{ 'c-wiggle-short u-border-error': local_get_error('datapoints_y_axis_value') }]"
                                    item-color="indigo darken-1"
                                    background-color="white"
                                    :append-icon="!moduleItem.original.y_axis_value ? 'arrow_drop_down' : ''"
                                    :menu-props="{ rounded: 'lg', nudgeBottom: '4px', offsetY: true, closeOnContentClick: true, closeOnClick: true, }"
                                    @input="local_update('y_axis_value')"
                                    @click:clear="local_clear_datapoint_fields('y_axis_value')"
                                    solo flat hide-details clearable
                                ></a-select>
                            </div>
                        </template>
                        <span>{{ local_get_error('datapoints_y_axis_value') }}</span>
                    </a-tooltip>
                </a-sheet>
            </template>
            <template v-else>
                <a-sheet class="mr-2 transparent" width="314">
                    <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">
                        {{ selectedTile.chart_type === 'Numbers' ? 'Primary Slot' : 'Category' }}
                    </span>
                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('datapoints_primary_slot')">
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <a-select
                                    v-model="moduleItem.original.primary_slot"
                                    :items="local_get_primary_slot_list"
                                    :placeholder="`Ex: ${ selectedTile.chart_type === 'Numbers' ? 'Count of Project' : 'Project -> Health'}`"
                                    class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                    :class="[{ 'c-wiggle-short u-border-error': local_get_error('datapoints_primary_slot') }]"
                                    item-color="indigo darken-1"
                                    background-color="white"
                                    :append-icon="!moduleItem.original.primary_slot ? 'arrow_drop_down' : ''"
                                    :menu-props="{ rounded: 'lg', nudgeBottom: '4px', offsetY: true, closeOnContentClick: true, closeOnClick: true, }"
                                    @input="local_update('primary_slot')"
                                    @click:clear="local_clear_datapoint_fields('primary_slot')"
                                    solo flat hide-details clearable
                                ></a-select>
                            </div>
                        </template>
                        <span>{{ local_get_error('datapoints_primary_slot') }}</span>
                    </a-tooltip>
                </a-sheet>
                <a-sheet class="ml-2 transparent" width="320">
                    <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">
                        {{ selectedTile.chart_type === 'Numbers' ? 'Secondary Slot' : 'Value' }}
                    </span>
                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('datapoints_secondary_slot')">
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                                <a-select
                                    v-model="moduleItem.original.secondary_slot"
                                    :items="local_get_secondary_slot_list"
                                    :placeholder="`Ex: ${ selectedTile.chart_type === 'Numbers' ? 'Sum of project deal amount' : 'Count of Project'}`"
                                    class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                    :class="[{ 'c-wiggle-short u-border-error': local_get_error('datapoints_secondary_slot') }]"
                                    item-color="indigo darken-1"
                                    background-color="white"
                                    :append-icon="!moduleItem.original.secondary_slot ? 'arrow_drop_down' : ''"
                                    :menu-props="{ rounded: 'lg', nudgeBottom: '4px', offsetY: true, closeOnContentClick: true, closeOnClick: true, }"
                                    @input="local_update('secondary_slot')"
                                    @click:clear="local_clear_datapoint_fields('secondary_slot')"
                                    solo flat hide-details clearable
                                ></a-select>
                            </div>
                        </template>
                        <span>{{ local_get_error('datapoints_secondary_slot') }}</span>
                    </a-tooltip>
                </a-sheet>
            </template>
        </div>
    </a-sheet>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        moduleItem: {
            type: Object,
            required: true
        },
        selectedTile: {
            type: Object,
            required: true
        },
        configErrors: {
            type: Array,
        },
        canUpdate: {
            type: Boolean | Number,
            default: true
        },
        isLoading: {
            type: Boolean | Number,
            default: false
        },
    },

    data () {
        return {
            xaxis_list: [
                { text: 'Month over month', value: 'month' },
                { text: 'Quarter over quarter', value: 'quarter' },
            ],
            numbers_primary_slot: [
                { text: 'None', value: 'none' },
                { text: 'Count of Project', value: 'count(projects.id)' },
            ],
            numbers_secondary_slot: [
                { text: 'None', value: 'none' },
                { text: 'Average of Project -> Deal Amount', value: 'avg(projects.deal_amount)' },
                { text: 'Sum of Project -> Deal Amount', value: 'sum(projects.deal_amount)' },
                { text: 'Max of Project -> Deal Amount', value: 'max(projects.deal_amount)' },
                { text: 'Min of Project -> Deal Amount', value: 'min(projects.deal_amount)' }
            ],
            dpl_primary_slot: [
                { text: 'Project -> Health', value: 'projects.health_color' },
                { text: 'Project -> Status', value: 'projects.status' },
                { text: 'Project -> Stage -> Label', value: 'projects.stage_id' },
                { text: 'Project -> Stage -> Status', value: 'projects.stage.status' },
                { text: 'Project -> Result -> Label', value: 'projects.result_id' },
                { text: 'Project -> Result -> Status', value: 'projects.result.status' },
            ],
            aggregate_datapoints_list: [
                { text: 'Count of Project', value: 'count(projects.id)' },
                { text: 'Average of Project -> Deal Amount', value: 'avg(projects.deal_amount)' },
                { text: 'Sum of Project -> Deal Amount', value: 'sum(projects.deal_amount)' },
            ],
        }
    },

    computed: {
        local_current_fiscal_month () {
            return this.user_self?.organization?.financial_month
        },

        local_is_barline_charts () {
            return ['Bar', 'Line'].includes(this.selectedTile.chart_type)
        },

        local_get_primary_slot_list () {
            const projectCompetitor = { text: 'Project -> Competitors', value: 'projects.competitors' }
            switch (this.selectedTile.chart_type) {
                case 'Numbers': return this.numbers_primary_slot
                case 'Donut':
                case 'Pie': {
                    this.dpl_primary_slot.splice(2, 0, projectCompetitor)
                    return this.dpl_primary_slot
                }
                case 'Bar':
                case 'Multiple Line':
                case 'Stacked Bar':
                case 'Multiple Bar':
                case 'Line': return this.dpl_primary_slot
            }
        },

        local_get_secondary_slot_list () {
            switch (this.selectedTile.chart_type) {
                case 'Numbers': return this.numbers_secondary_slot
                case 'Donut':
                case 'Pie':
                case 'Bar':
                case 'Multiple Line':
                case 'Stacked Bar':
                case 'Multiple Bar':
                case 'Line': return this.aggregate_datapoints_list
            }
        },

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        local_update (field) {
            this.$emit('update', 'Datapoints', field, this.moduleItem.original[field])
        },

        local_clear_datapoint_fields (field) {
            this.moduleItem.original[field] = ''
            this.local_update(field)
            this.local_remove_config(field)
        },

        local_remove_config (slug) {
            this.$emit('remove-config', slug)
        },

        local_get_error (slug, field = 'message') {
            if (!slug) return null
            const item = this.configErrors.find(item => item.slug === slug)
            return (item && item[field])
        },
    }
}
</script>

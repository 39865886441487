<template>
    <div class="mb-5 pb-4">
        <a-layout align-center class="mt-4" v-for="(inventories, group_index) in booking_list" :key="inventories + '-'+ group_index">
            <a-flex class="py-0" v-if="local_first_item(inventories.value)">
                <a-card class="elevation-0 pa-0 mb-0 transparent u-rounded-corners u-overflow-x">
                    <div class="u-flex align-center px-6 py-3 pb-4 u-border u-shadow white" style="width: 100vw">
                        <div>
                            <div>
                                <h4 class="title grey--text text--darken-2 font-weight-bold text-truncate" :title="local_first_item(inventories.value).project.title" style="max-width: 400px" v-if="local_first_item(inventories.value) && local_first_item(inventories.value).project">{{ local_first_item(inventories.value).project.title }}</h4>
                                <a-chip class="ml-0 mb-2 md-subtitle-2 grey--text text--darken-1 font-weight-medium mt-1 text-truncate" label small style="max-width: 300px" v-if="local_first_item(inventories.value) && local_first_item(inventories.value).project && local_first_item(inventories.value).project.customer" :title="local_first_item(inventories.value).project.customer.name">{{ local_truncate_text(local_first_item(inventories.value).project.customer.name, 40) }}</a-chip>
                            </div>
                            <div class="u-flex align-center">
                                <span class="md-caption grey--text text--darken-1">Assigned to</span>
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <span class="md-caption grey--text text--darken-3 ml-1 font-weight-medium" v-on="on">{{ local_first_item(inventories.value) && local_first_item(inventories.value).assignee && local_first_item(inventories.value).assignee[0] ? local_first_item(inventories.value).assignee[0].name : '' }}</span>
                                    </template>
                                    <div class="d-flex align-center" v-if="local_first_item(inventories.value) && local_first_item(inventories.value).assignee">
                                        <g-avatar :item="local_first_item(inventories.value).assignee[0]" no-border></g-avatar>
                                        <g-profile-info :item="local_first_item(inventories.value).assignee[0]" type-user dark></g-profile-info>
                                    </div>
                                </a-tooltip>
                                <span class="md-caption grey--text text--darken-1 ml-1">on <span class="md-caption grey--text text--darken-3 font-weight-medium" v-if="local_first_item(inventories.value)">{{ local_format_date(local_first_item(inventories.value).created_at) }}</span></span>
                            </div>
                        </div>
                        <a-spacer></a-spacer>
                        <div>
                            <div class="text-right mb-2" v-if="local_first_item(inventories.value) && $can('inventories.update')">
                                <a-btn small :to="{ name: 'booking-extend', params: { id: local_first_item(inventories.value).batch } }" dark color="blue" class="ma-0 px-3 py-1 elevation-0">Manage</a-btn>
                            </div>
                            <div class="d-flex align-center justify-end" v-if="local_first_item(inventories.value)">
                                <h4 class="md-body-2 font-weight-medium text-right grey--text text--darken-1 text-truncate" :title="local_first_item(inventories.value).location" style="max-width: 400px" v-if="local_first_item(inventories.value) && local_first_item(inventories.value).location">{{ local_first_item(inventories.value).location }}</h4>
                                <h4 class="md-body-2 font-weight-medium text-right grey--text text--darken-1 text-truncate"  style="max-width: 400px" v-else>No Location</h4>
                                <a-icon size="18" class="ml-1">place</a-icon>
                            </div>
                            <div>
                                <span class="md-caption grey--text text--darken-1">Requested by</span>
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <span class="md-caption grey--text text--darken-3 ml-1 font-weight-medium" v-on="on">{{ local_first_item(inventories.value) && local_first_item(inventories.value).requester ? local_first_item(inventories.value).requester.name : '' }}</span>
                                    </template>
                                    <div class="d-flex align-center" v-if="local_first_item(inventories.value)">
                                        <g-avatar :item="local_first_item(inventories.value).requester" no-border></g-avatar>
                                        <g-profile-info :item="local_first_item(inventories.value).requester" type-user dark></g-profile-info>
                                    </div>
                                </a-tooltip>
                                <span class="md-caption grey--text text--darken-1 ml-1">on <span class="md-caption grey--text text--darken-3 font-weight-medium" v-if="local_first_item(inventories.value)">{{ local_format_date(local_first_item(inventories.value).created_at) }}</span></span>
                            </div>
                        </div>
                    </div>
                    <div class="u-rounded-corners u-elevation-custom-1" style="z-index: 10">
                        <a-divider class="grey lighten-3"></a-divider>
                        <div class="px-4 grey lighten-5" style="width: 100vw;">
                            <table class="u-wfull relative" style="position: relative; border-collapse: collapse">
                                <tr class="grey lighten-5 mb-2">
                                    <th class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-3 px-3" :style="{ minWidth: local_cal_width(112, 144) + 'px !important', maxWidth: local_cal_width(112, 144) + 'px !important' }" style="">Category</th>
                                    <th class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-3 px-3" :style="{ minWidth: local_cal_width(160, 360) + 'px !important', maxWidth: local_cal_width(160, 360) + 'px !important' }" style=";">Name</th>
                                    <th class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-3 px-3" :style="{ minWidth: local_cal_width(144, 264) + 'px !important', maxWidth: local_cal_width(144, 264) + 'px !important' }" style=";">Part #</th>
                                    <th class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-3 px-3" :style="{ minWidth: local_cal_width(144, 264) + 'px !important', maxWidth: local_cal_width(144, 264) + 'px !important' }" style=";">Serial #</th>
                                    <th class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-3 px-3" :style="{ minWidth: local_cal_width(144, 272) + 'px !important', maxWidth: local_cal_width(144, 272) + 'px !important' }" style=";">OEM</th>
                                    <th class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-3 px-3" :style="{ minWidth: local_cal_width(184, 232) + 'px !important', maxWidth: local_cal_width(184, 232) + 'px !important' }" style=";">Status</th>
                                    <th class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-right py-3 px-3" :style="{ minWidth: local_cal_width(136, 172) + 'px !important', maxWidth: local_cal_width(136, 172) + 'px !important' }" style=";">Booking Range</th>
                                </tr>
                            </table>
                        </div>
                        <a-divider class="grey lighten-3"></a-divider>
                        <div v-for="(inventory, index) in inventories.value" class="white" style="width: 100vw" :key="inventory + '-'+ index">
                            <a-divider class="u-divider" v-if="index !== 0"></a-divider>
                            <a-card class="py-2 px-4 elevation-0">
                                <table class="u-wfull" style="border-collapse: collapse">
                                    <tr class="u-wfull">
                                        <template v-if="inventory.inventory_trashed">
                                            <td class="md-body-2 py-2 px-3 text-truncate grey--text text--darken-3" :style="{ minWidth: local_cal_width(112, 144) + 'px !important', maxWidth: local_cal_width(112, 144) + 'px !important' }">
                                                <h4 class="body-2 text-left text-truncate" :title="inventory.inventory_trashed.category.value" v-if="inventory.inventory_trashed.category && inventory.inventory_trashed.category.value">{{ inventory.inventory_trashed.category.value }}</h4>
                                                <h4 class="body-2 text-left" v-else>No Category</h4>
                                            </td>
                                            <td class="md-body-2 py-2 px-3 text-truncate grey--text text--darken-3" :style="{ minWidth: local_cal_width(160, 360) + 'px !important', maxWidth: local_cal_width(160, 360) + 'px !important' }">
                                                <div class="u-flex align-center">
                                                    <h4 class="body-2 text-truncate" v-if="inventory.inventory_trashed" :title="inventory.inventory_trashed.name">{{ inventory.inventory_trashed.name }}</h4>
                                                    <span class="caption red--text text--darken-1 d-inline-block px-2 ml-2" style="border-radius: 50px; border: 1px solid #ef5350;">
                                                        Del<span class="hidden-lg-and-down">eted</span>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="md-body-2 py-2 px-3 text-truncate grey--text text--darken-3" :style="{ minWidth: local_cal_width(144, 264) + 'px !important', maxWidth: local_cal_width(144, 264) + 'px !important' }">
                                                <h4 class="body-2 text-left text-truncate" :title="inventory.inventory_trashed.part_no" v-if="inventory.inventory_trashed.part_no">{{ inventory.inventory_trashed.part_no }}</h4>
                                                <h4 class="body-2 text-left" v-else> - </h4>
                                            </td>
                                            <td class="md-body-2 py-2 px-3 text-truncate grey--text text--darken-3" :style="{ minWidth: local_cal_width(144, 264) + 'px !important', maxWidth: local_cal_width(144, 264) + 'px !important' }">
                                                <h4 class="body-2 text-left text-truncate" :title="inventory.inventory_trashed.serial_no" v-if="inventory.inventory_trashed.serial_no">{{ inventory.inventory_trashed.serial_no }}</h4>
                                                <h4 class="body-2 text-left" v-else> - </h4>
                                            </td>
                                            <td class="md-body-2 py-2 px-3 text-truncate grey--text text--darken-3" :style="{ minWidth: local_cal_width(144, 272) + 'px !important', maxWidth: local_cal_width(144, 272) + 'px !important' }">
                                                <h4 class="body-2 text-left text-truncate" :title="inventory.inventory_trashed.oem" v-if="inventory.inventory_trashed.oem">{{ inventory.inventory_trashed.oem }}</h4>
                                                <h4 class="body-2 text-left" v-else> - </h4>
                                            </td>
                                            <td class="md-body-2 py-2 px-3 text-truncate grey--text text--darken-3" :style="{ minWidth: local_cal_width(184, 232) + 'px !important', maxWidth: local_cal_width(184, 232) + 'px !important' }">
                                                <div class="u-flex align-center u-wfull">
                                                    <div class="u-cursor-pointer mr-2">
                                                        <div :style="{ minWidth: inventory.status === 'In Use' ? local_cal_width(64, 88) : local_cal_width(88, 88) + 'px !important' }">
                                                            <a-chip label small class="ma-0 u-rounded-corners orange darken-1 u-wfull d-flex align-center justify-center" style="height: 25px;" v-if="inventory.status === 'In Use'">
                                                                <div class="d-flex align-center">
                                                                    <h4 class="body-2 font-weight-medium white--text">{{ inventory.status }}</h4>
                                                                    <span v-if="inventory.force_extended === 1">
                                                                        <a-tooltip bottom>
                                                                            <template v-slot:activator="{ on }">
                                                                                <a-icon v-on="on" size="14" class="px-2 py-1 pr-0" color="white darken-5">info</a-icon>
                                                                            </template>
                                                                            <span>In Use and Overdue</span>
                                                                        </a-tooltip>
                                                                    </span>
                                                                </div>
                                                            </a-chip>
                                                        </div>
                                                        <div :style="{ minWidth: inventory.status === 'In Use' ? local_cal_width(64, 88) : local_cal_width(88, 88) + 'px !important' }">
                                                            <a-chip label small class="ma-0 u-rounded-corners grey lighten-2 d-flex align-center justify-center" style="height: 28px;" v-if="inventory.status === 'Returned'">
                                                                <div class="d-flex align-center">
                                                                    <h4 class="md-caption font-weight-medium text-uppercase grey--text text--darken-1">{{ inventory.status }}</h4>
                                                                </div>
                                                            </a-chip>
                                                        </div>
                                                        <div :style="{ minWidth: inventory.status === 'In Use' ? local_cal_width(64, 88) : local_cal_width(88, 88) + 'px !important' }">
                                                            <a-chip label small class="ma-0 u-rounded-corners light-green lighten-3 d-flex align-center justify-center" style="height: 28px;" v-if="inventory.status === 'Booked'">
                                                                <div class="d-flex align-center">
                                                                    <h4 class="md-caption font-weight-medium text-uppercase green--text text--darken-2">{{ inventory.status }}</h4>
                                                                </div>
                                                            </a-chip>
                                                        </div>
                                                        <div :style="{ minWidth: inventory.status === 'In Use' ? local_cal_width(64, 88) : local_cal_width(88, 88) + 'px !important' }">
                                                            <a-chip label small class="ma-0 u-rounded-corners red lighten-4 d-flex align-center justify-center" style="height: 28px;" v-if="inventory.status === 'Cancelled'">
                                                                <div class="d-flex align-center">
                                                                    <h4 class="md-caption font-weight-medium text-uppercase red--text text--darken-2">{{ inventory.status }}</h4>
                                                                </div>
                                                            </a-chip>
                                                        </div>
                                                    </div>
                                                    <div class="u-wfull text-left">
                                                        <template v-if="inventory.status === 'In Use'">
                                                            <a-menu offset-y content-class="u-rounded-corners u-elevation-custom-1 u-border white" max-width="350" :disabled="!$can('inventories.update')" class="u-wfull">
                                                                <template v-slot:activator="{ on }">
                                                                    <div v-on="on" class="u-flex align-center u-wfull px-2 u-cursor-pointer u-border u-rounded-corners" style="padding-top: 4px !important; padding-bottom: 4px !important;" :style="{backgroundColor: inventory.inventory_trashed.custom_status && inventory.inventory_trashed.custom_status.color ? $color(inventory.inventory_trashed.custom_status.color, 'color_bg') : '#eee'}">
                                                                        <span class="u-rounded-corners md-caption d-block mx-auto u-wfull" v-if="(inventory && inventory.inventory_trashed && inventory.inventory_trashed.custom_status) && $can('inventories.update')" :title="inventory.inventory_trashed.custom_status.label" :style="{ minWidth: local_cal_width(40, 50) + 'px', backgroundColor: $color(inventory.inventory_trashed.custom_status.color, 'color_bg'), color: $color(inventory.inventory_trashed.custom_status.color, 'color_text')}">{{ local_truncate_text(inventory.inventory_trashed.custom_status.label, $vuetify.breakpoint.lgAndDown ? 8 : 16, '..') }}</span>
                                                                        <span class="u-rounded-corners md-caption d-block mx-auto u-wfull" v-if="(inventory && inventory.inventory_trashed && inventory.inventory_trashed.custom_status) && !$can('inventories.update')" :title="inventory.inventory_trashed.custom_status.label" :style="{ minWidth: local_cal_width(40, 50) + 'px', backgroundColor: $color(inventory.inventory_trashed.custom_status.color, 'color_bg'), color: $color(inventory.inventory_trashed.custom_status.color, 'color_text')}">{{ local_truncate_text(inventory.inventory_trashed.custom_status.label, $vuetify.breakpoint.lgAndDown ? 13 : 18, '..') }}</span>
                                                                        <span v-if="inventory && inventory.inventory_trashed && !inventory.inventory_trashed.custom_status" class="u-rounded-corners md-caption grey--text text--darken-2 d-block mx-auto text-truncate" style="min-width: 50px;">Select Stage</span>
                                                                        <a-spacer></a-spacer>
                                                                        <a-icon size="14" v-if="(inventory && inventory.inventory_trashed && inventory.inventory_trashed.custom_status) && $can('inventories.update')" :style="{ color: $color(inventory.inventory_trashed.custom_status.color, 'color_text') }">keyboard_arrow_down</a-icon>
                                                                    </div>
                                                                </template>
                                                                <template v-if="local_tag_inventory_status_list && local_tag_inventory_status_list.length">
                                                                    <a-list style="max-height: 300px;" class="overflow-y-auto py-1 u-list-condensed">
                                                                        <a-list-item class="md-caption grey--text text--darken-2" @click="local_add_inventory_stage_tag(stage, inventory.inventory_trashed.id, inventories.key)" v-for="stage in local_tag_inventory_status_list" :key="stage.id">
                                                                            {{ stage.label }}
                                                                        </a-list-item>
                                                                    </a-list>
                                                                    <template v-if="local_tag_inventory_status_list.length">
                                                                        <a-divider></a-divider>
                                                                        <a-list class="u-list-condensed py-0">
                                                                            <a-list-item class="md-caption" @click="local_clear_inventory_stage_tag(inventory.inventory_trashed.id, inventories.key)">
                                                                                <a-list-item-content class="md-caption red--text text--darken-1">Remove Stage</a-list-item-content>
                                                                            </a-list-item>
                                                                        </a-list>
                                                                    </template>
                                                                </template>
                                                                <template v-if="local_tag_inventory_status_list && !local_tag_inventory_status_list.length">
                                                                    <a-list class="u-list-condensed">
                                                                        <a-list-item class="md-caption grey--text text--darken-2">
                                                                            No Stage Found
                                                                        </a-list-item>
                                                                    </a-list>
                                                                </template>
                                                            </a-menu>
                                                        </template>
                                                        <template v-else>
                                                            <h4 class="body-2 px-2"> - </h4>
                                                        </template>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="md-body-2 py-2 px-3 text-truncate grey--text text--darken-3" :style="{ minWidth: local_cal_width(136, 172) + 'px !important', maxWidth: local_cal_width(136, 172) + 'px !important' }">
                                                <h4 class="md-body-2 text-right text-truncate" :title="local_format_date(inventory.booking_from, null, true) + ' - ' + local_format_date(inventory.actual_booking_to, null, true)" v-if="inventory.booking_from && inventory.actual_booking_to">{{ local_format_date(inventory.booking_from, null, true) }} - {{ local_format_date(inventory.actual_booking_to, null, true) }}</h4>
                                            </td>
                                        </template>
                                        <template v-else>
                                            <td class="md-body-2 py-2 px-3 text-truncate grey--text text--darken-3" :style="{ minWidth: local_cal_width(112, 144) + 'px !important', maxWidth: local_cal_width(112, 144) + 'px !important' }">
                                                <h4 class="body-2 text-left text-truncate" :title="inventory.inventory.category.value" v-if="inventory.inventory.category && inventory.inventory.category.value">{{ inventory.inventory.category.value }}</h4>
                                                <h4 class="body-2 text-left" v-else>No Category</h4>
                                            </td>
                                            <td class="md-body-2 py-2 px-3 text-truncate grey--text text--darken-3" :style="{ minWidth: local_cal_width(160, 360) + 'px !important', maxWidth: local_cal_width(160, 360) + 'px !important' }">
                                                <template v-if="inventory.inventory_trashed">
                                                    <div class="u-flex align-center">
                                                        <h4 class="body-2 text-truncate" v-if="inventory.inventory_trashed" :title="inventory.inventory_trashed.name">{{ inventory.inventory_trashed.name }}</h4>
                                                        <span class="caption red--text text--darken-1 d-inline-block px-2 ml-2" style="border-radius: 50px; border: 1px solid #ef5350;">
                                                            Del<span class="hidden-lg-and-down">eted</span>
                                                        </span>
                                                    </div>
                                                </template>
                                                <h4 v-else class="body-2 text-truncate" :title="inventory.inventory.name">{{ inventory.inventory ? inventory.inventory.name : '' }}</h4>
                                            </td>
                                            <td class="md-body-2 py-2 px-3 text-truncate grey--text text--darken-3" :style="{ minWidth: local_cal_width(144, 264) + 'px !important', maxWidth: local_cal_width(144, 264) + 'px !important' }">
                                                <h4 class="body-2 text-left text-truncate" :title="inventory.inventory.part_no" v-if="inventory.inventory.part_no">{{ inventory.inventory.part_no }}</h4>
                                                <h4 class="body-2 text-left" v-else> - </h4>
                                            </td>
                                            <td class="md-body-2 py-2 px-3 text-truncate grey--text text--darken-3" :style="{ minWidth: local_cal_width(144, 264) + 'px !important', maxWidth: local_cal_width(144, 264) + 'px !important' }">
                                                <h4 class="body-2 text-left text-truncate" :title="inventory.inventory.serial_no" v-if="inventory.inventory.serial_no">{{ inventory.inventory.serial_no }}</h4>
                                                <h4 class="body-2 text-left" v-else> - </h4>
                                            </td>
                                            <td class="md-body-2 py-2 px-3 text-truncate grey--text text--darken-3" :style="{ minWidth: local_cal_width(144, 272) + 'px !important', maxWidth: local_cal_width(144, 272) + 'px !important' }">
                                                <h4 class="body-2 text-left text-truncate" :title="inventory.inventory.oem" v-if="inventory.inventory.oem">{{ inventory.inventory.oem }}</h4>
                                                <h4 class="body-2 text-left" v-else> - </h4>
                                            </td>
                                            <td class="md-body-2 py-2 px-3 text-truncate grey--text text--darken-3" :style="{ minWidth: local_cal_width(184, 232) + 'px !important', maxWidth: local_cal_width(184, 232) + 'px !important' }">
                                                <div class="u-flex align-center u-wfull">
                                                    <div class="u-cursor-pointer mr-2">
                                                        <div :style="{ minWidth: inventory.status === 'In Use' ? local_cal_width(64, 88) : local_cal_width(88, 88) + 'px !important' }">
                                                            <a-chip label small class="ma-0 u-rounded-corners orange darken-1 u-wfull d-flex align-center justify-center" style="height: 25px;" v-if="inventory.status === 'In Use'">
                                                                <div class="d-flex align-center">
                                                                    <h4 class="body-2 font-weight-medium white--text">{{ inventory.status }}</h4>
                                                                    <span v-if="inventory.force_extended === 1">
                                                                        <a-tooltip bottom>
                                                                            <template v-slot:activator="{ on }">
                                                                                <a-icon v-on="on" size="14" class="px-2 py-1 pr-0" color="white darken-5">info</a-icon>
                                                                            </template>
                                                                            <span>In Use and Overdue</span>
                                                                        </a-tooltip>
                                                                    </span>
                                                                </div>
                                                            </a-chip>
                                                        </div>
                                                        <div :style="{ minWidth: inventory.status === 'In Use' ? local_cal_width(64, 88) : local_cal_width(88, 88) + 'px !important' }">
                                                            <a-chip label small class="ma-0 u-rounded-corners grey lighten-2 d-flex align-center justify-center" style="height: 28px;" v-if="inventory.status === 'Returned'">
                                                                <div class="d-flex align-center">
                                                                    <h4 class="md-caption font-weight-medium text-uppercase grey--text text--darken-1">{{ inventory.status }}</h4>
                                                                </div>
                                                            </a-chip>
                                                        </div>
                                                        <div :style="{ minWidth: inventory.status === 'In Use' ? local_cal_width(64, 88) : local_cal_width(88, 88) + 'px !important' }">
                                                            <a-chip label small class="ma-0 u-rounded-corners light-green lighten-3 d-flex align-center justify-center" style="height: 28px;" v-if="inventory.status === 'Booked'">
                                                                <div class="d-flex align-center">
                                                                    <h4 class="md-caption font-weight-medium text-uppercase green--text text--darken-2">{{ inventory.status }}</h4>
                                                                </div>
                                                            </a-chip>
                                                        </div>
                                                        <div :style="{ minWidth: inventory.status === 'In Use' ? local_cal_width(64, 88) : local_cal_width(88, 88) + 'px !important' }">
                                                            <a-chip label small class="ma-0 u-rounded-corners red lighten-4 d-flex align-center justify-center" style="height: 28px;" v-if="inventory.status === 'Cancelled'">
                                                                <div class="d-flex align-center">
                                                                    <h4 class="md-caption font-weight-medium text-uppercase red--text text--darken-2">{{ inventory.status }}</h4>
                                                                </div>
                                                            </a-chip>
                                                        </div>
                                                    </div>
                                                    <div class="u-wfull text-left">
                                                        <template v-if="inventory.status === 'In Use'">
                                                            <a-menu offset-y content-class="u-rounded-corners u-elevation-custom-1 u-border white" max-width="350" :disabled="!$can('inventories.update')" class="u-wfull">
                                                                <template v-slot:activator="{ on }">
                                                                    <div v-on="on" class="u-flex align-center u-wfull px-2 u-cursor-pointer u-border u-rounded-corners" style="padding-top: 4px !important; padding-bottom: 4px !important;" :style="{backgroundColor: inventory.inventory.custom_status && inventory.inventory.custom_status.color ? $color(inventory.inventory.custom_status.color, 'color_bg') : '#eee'}">
                                                                        <span class="u-rounded-corners md-caption d-block mx-auto u-wfull" v-if="(inventory && inventory.inventory && inventory.inventory.custom_status) && $can('inventories.update')" :title="inventory.inventory.custom_status.label" :style="{ minWidth: local_cal_width(40, 50) + 'px', backgroundColor: $color(inventory.inventory.custom_status.color, 'color_bg'), color: $color(inventory.inventory.custom_status.color, 'color_text')}">{{ local_truncate_text(inventory.inventory.custom_status.label, $vuetify.breakpoint.lgAndDown ? 8 : 16, '..') }}</span>
                                                                        <span class="u-rounded-corners md-caption d-block mx-auto u-wfull" v-if="(inventory && inventory.inventory && inventory.inventory.custom_status) && !$can('inventories.update')" :title="inventory.inventory.custom_status.label" :style="{ minWidth: local_cal_width(40, 50) + 'px', backgroundColor: $color(inventory.inventory.custom_status.color, 'color_bg'), color: $color(inventory.inventory.custom_status.color, 'color_text')}">{{ local_truncate_text(inventory.inventory.custom_status.label, $vuetify.breakpoint.lgAndDown ? 13 : 18, '..') }}</span>
                                                                        <span v-if="inventory && inventory.inventory && !inventory.inventory.custom_status" class="u-rounded-corners md-caption grey--text text--darken-2 d-block mx-auto text-truncate" style="min-width: 50px;">Select Stage</span>
                                                                        <a-spacer></a-spacer>
                                                                        <a-icon size="14" v-if="(inventory && inventory.inventory && inventory.inventory.custom_status) && $can('inventories.update')" :style="{ color: $color(inventory.inventory.custom_status.color, 'color_text') }">keyboard_arrow_down</a-icon>
                                                                    </div>
                                                                </template>
                                                                <template v-if="local_tag_inventory_status_list && local_tag_inventory_status_list.length">
                                                                    <a-list style="max-height: 300px;" class="overflow-y-auto py-1 u-list-condensed">
                                                                        <a-list-item class="md-caption grey--text text--darken-2" @click="local_add_inventory_stage_tag(stage, inventory.inventory.id, inventories.key)" v-for="stage in local_tag_inventory_status_list" :key="stage.id">
                                                                            {{ stage.label }}
                                                                        </a-list-item>
                                                                    </a-list>
                                                                    <template v-if="local_tag_inventory_status_list.length">
                                                                        <a-divider></a-divider>
                                                                        <a-list class="u-list-condensed py-0">
                                                                            <a-list-item class="md-caption" @click="local_clear_inventory_stage_tag(inventory.inventory.id, inventories.key)">
                                                                                <a-list-item-content class="md-caption red--text text--darken-1">Remove Stage</a-list-item-content>
                                                                            </a-list-item>
                                                                        </a-list>
                                                                    </template>
                                                                </template>
                                                                <template v-if="local_tag_inventory_status_list && !local_tag_inventory_status_list.length">
                                                                    <a-list class="u-list-condensed">
                                                                        <a-list-item class="md-caption grey--text text--darken-2">
                                                                            No Stage Found
                                                                        </a-list-item>
                                                                    </a-list>
                                                                </template>
                                                            </a-menu>
                                                        </template>
                                                        <template v-else>
                                                            <h4 class="body-2 px-2"> - </h4>
                                                        </template>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="md-body-2 py-2 px-3 text-truncate grey--text text--darken-3" :style="{ minWidth: local_cal_width(136, 172) + 'px !important', maxWidth: local_cal_width(136, 172) + 'px !important' }">
                                                <h4 class="md-body-2 text-right text-truncate" :title="local_format_date(inventory.booking_from, null, true) + ' - ' + local_format_date(inventory.actual_booking_to, null, true)" v-if="inventory.booking_from && inventory.actual_booking_to">{{ local_format_date(inventory.booking_from, null, true) }} - {{ local_format_date(inventory.actual_booking_to, null, true) }}</h4>
                                            </td>
                                        </template>
                                    </tr>
                                </table>
                            </a-card>
                        </div>
                    </div>
                </a-card>
            </a-flex>
        </a-layout>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    props: {
        booking_list: {
            type: Array
        }
    },

    computed: {
        local_tag_inventory_status_list() {
            return this.tag_list.filter(item => item.type === 'inventory_custom_status')
        },
        ...mapState('Tag', {
            tag_list: 'list',
        })
    },

    methods: {
        local_format_date(date, range = false, simple = false) {
            if (range) return moment(date).format('YYYY-MM-DD')

            if (simple) return moment(date).format('MMM D')

            return date ? moment(date).format('MMM D, YYYY') : null
        },
        local_truncate_text(text, range, separator = '...') {
            if (!text) return

            return text.length > range ? text.slice(0, range) + separator : text
        },
        async local_add_inventory_stage_tag(tag, inventory_id, group_index) {
            this.inventory_attach_custom_status({ tag_id: tag.id, inventory_id })
            // await this.inventory_update_stage_tag({ tag_id: tag.id, inventory_id, type: 'booking' })
            this.inventory_booking_stage_update({ tag, inventory_id, index: group_index })
        },
        local_clear_inventory_stage_tag(inventory_id, group_index) {
            this.inventory_clear_custom_status({ inventory_id, type: 'booking' })
            // this.inventory_clear_stage_tag({ inventory_id, type: 'booking' })
            this.inventory_booking_stage_clear({ inventory_id, index: group_index })
        },
        local_cal_width(low, high) {
            return this.$vuetify.breakpoint.lgAndDown ? low : high
        },
        local_first_item(list) {
            if (list && list[0]) return list[0]
        },
        ...mapActions('InventoryBooking', {
            inventory_booking_stage_update: 'update_stage_tag',
            inventory_booking_stage_clear: 'clear_stage_tag'
        }),
        ...mapActions('Inventory', {
            'inventory_attach_tags': 'attach_tags',
            'inventory_attach_custom_status': 'attach_custom_status',
            inventory_update_stage_tag: 'update_stage_tag',
            // 'inventory_clear_stage_tag': 'clear_stage_tag',
            'inventory_clear_custom_status': 'clear_custom_status',
        }),
    }
}
</script>

<style lang="scss" scoped>
.c-border-c7 {
    border: 1px solid #000;
    border-color: #c7c7c7 !important
}
</style>

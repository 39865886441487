<template>
    <a-container grid-list-xl class="pa-0 white u-rounded-corners u-wfull">
        <div ref="refContainer">
            <div class="pa-6 u-flex white justify-space-between align-center u-rounded-corners">
                <div class="u-flex align-center">
                    <div class="u-rounded-corners-full grey darken-4 u-flex justify-center" style="width: 40px; height: 40px">
                        <a-icon color="white">summarize</a-icon>
                    </div>
                    <div class="ml-4 grey--text text--darken-4">
                        <div class="md-heading-5">Confidence Score Summary</div>
                        <div class="md-body-2 grey--text text--darken-1">View your project score summary here.</div>
                    </div>
                </div>
                <div>
                    <a-btn icon plain @click="$emit('close')">
                        <a-icon size="24" color="grey darken-1">close</a-icon>
                    </a-btn>
                </div>
            </div>
            <a-divider></a-divider>
            <div class="u-overflow-y c-tiny-scroll" ref="fieldsContainer" style="height: 450px">
                <template v-if="local_section_has_fields()">
                    <template v-for="section in summary_list">
                        <div
                            v-if="section && section.fields && section.fields.length"
                            :key="section.field"
                            min-height="160"
                            flat
                            class="mb-6 u-rounded-corners c-section-card"
                        >
                            <div class="px-10 grey lighten-5 u-flex">
                                <div class="my-5 u-wfull u-flex justify-space-between align-center grey--text text--darken-3">
                                    <div style="font-size: 18px; font-weight: 600">
                                        <span :title="section.name">{{ section.name | truncateText(local_is_lgdown ? 60 : 90) }}</span>
                                        <span class="ml-1">- ({{ local_get_section_score_percentage(section) }}%)</span>
                                    </div>
                                    <div class="u-rounded-corners px-4 py-2 blue-grey lighten-5 justify-center u-flex" style="min-width: 130px; letter-spacing: -0.26px">
                                        <div class="u-flex align-end">
                                            <span class="font-weight-bold">{{ section.current_score ? section.current_score : '0' }}</span>
                                            <span>/</span>
                                        </div>
                                        <div class="font-weight-medium">{{ section.total_score ? section.total_score : 100 }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-10">
                                <div v-for="(field, fieldIndex) in section.fields" :key="field.id">
                                    <v-hover v-slot="{ hover }">
                                        <div class="py-6">
                                            <div class="mb-6 u-flex align-center justify-space-between">
                                                <div class="u-flex align-start" style="max-width: 70%">
                                                    <a-icon
                                                        size="20"
                                                        color="grey"
                                                        class="mt-1"
                                                    >
                                                        {{ local_get_field_type_icon(field) }}
                                                    </a-icon>
                                                    <span class="grey--text text--darken-3 md-heading-6 ml-2" style="letter-spacing: -0.26px">{{ field.label }}</span>
                                                </div>
                                                <div v-if="hover && local_has_permission()" class="u-flex align-center u-cursor-pointer" @click="local_edit_item(field)">
                                                    <a-icon size="16" color="grey darken-1" outlined>edit</a-icon>
                                                    <span class="md-subtitle-2 grey--text text--darken-1 ml-2">Edit field</span>
                                                </div>
                                            </div>
                                            <div class="u-flex align-center justify-space-between u-wfull">
                                                <div v-if="field.confidence_score_values && field.confidence_score_values.field_value" style="width: 50%">
                                                    <div class="grey--text text--darken-1 md-body-2 mb-2">Current selection:</div>
                                                    <div class="deep-purple--text text--darken-3 md-heading-6">
                                                        <template v-if="field.type === 'score'">{{ field.confidence_score_values.field_value }}</template>
                                                        <template v-if="field.type === 'dropdown'">
                                                            <div :title="field.confidence_score_values.field_value.label">
                                                                {{ field.confidence_score_values.field_value.label | truncateText(30) }}
                                                            </div>
                                                        </template>
                                                        <template v-if="field.type === 'multi_select'">
                                                            <div class="u-flex">
                                                                <div v-for="(item, valueIndex) in local_get_multiselect_value(field, 2)" :key="item.id">
                                                                    <span v-if="valueIndex < 2" :title="item.label" class="ml-2">
                                                                        <span>{{ item.label | truncateText(10) }}</span>
                                                                        <span class="grey--text text--darken-2"> ({{ item.value }})</span>
                                                                        <span v-if="local_get_multiselect_value(field, 'more', 'length') > 1 && valueIndex !== local_get_multiselect_value(field, 'more', 'length')-1">,</span>
                                                                    </span>
                                                                </div>
                                                                <a-tooltip max-width="600" right v-if="local_get_multiselect_value(field, 'more', 'length') > 2">
                                                                    <template v-slot:activator="{ on }">
                                                                        <div v-on="on" class="ml-2 u-cursor-pointer">+{{ field.confidence_score_values.field_value.length - 2 }} more</div>
                                                                    </template>
                                                                    <div class="pa-2">
                                                                        <div class="md-subtitle-1 font-weight-medium mb-2" v-for="item in local_get_multiselect_value(field, 'more')" :key="item.id" :title="item.label">
                                                                            <span>{{ item.label | truncateText(15) }}</span>
                                                                            <span> ({{ item.value }})</span>
                                                                        </div>
                                                                    </div>
                                                                </a-tooltip>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="" style="width: 35%">
                                                    <div class="u-flex justify-end mb-3 font-weight-medium">
                                                        <div class="blue--text text--darken-2">{{ local_get_field_score_record(field, 'score') }}</div>
                                                        <div class="grey lighten-3 u-rounded-corners-full grey--text text--darken-3 ml-4 px-3">{{ local_get_field_score_record(field, 'percentage') }}%</div>
                                                    </div>
                                                    <a-progress-linear :value="local_get_field_score_record(field, 'percentage')" color="blue darken-1" background-color="grey lighten-3" height="10" rounded></a-progress-linear>
                                                </div>
                                            </div>
                                        </div>
                                    </v-hover>
                                    <a-divider v-if="fieldIndex !== section.fields.length-1"></a-divider>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div class="u-flex flex-column justify-contents-center align-center mt-12">
                        <div>
                            <a-img :src="require('/src/assets/images/confidence-score/empty-summary.png')" contain width="150px" height="160px"></a-img>
                        </div>
                        <div class="grey--text text--darken-2 mt-10 text-center" style="max-width: 340px">
                            To view the summary of submitted fields, please update the relevant fields.
                        </div>
                        <a-btn v-if="local_has_permission() && project_item.confidence_score_fields && project_item.confidence_score_fields.length" height="32" color="indigo darken-1" @click="$emit('close')" small depressed class="c-save-btn mt-8 px-4 py-2 u-flex align-center white--text text--darken-2 u-rounded-corners">
                            <span class="md-body-2 text-uppercase" style="font-weight: 600">Update fields</span>
                        </a-btn>
                    </div>
                </template>
            </div>
            <div v-if="local_section_has_fields()" class="u-shadow-md u-rounded-corners px-10 py-4">
                <div class="u-flex align-center justify-space-between" ref="refBar">
                    <a-progress-linear
                        :value="local_get_score_percentage()"
                        background-color="#E6EAF5"
                        height="16"
                        :class="['c-custom-progress-linear' + `__${local_get_progress_color()}`, 'c-custom-progress-linear u-rounded-corners-full u-wfull']"
                    >
                    </a-progress-linear>
                    <div class="u-flex align-end ml-5" style="fontSize: 20px; letter-spacing: -0.26px">
                        <div class="indigo--text text--darken-1 font-weight-bold">({{ local_get_score_percentage() }}%)</div>
                        <div class="ml-4 grey--text text--darken-3 u-flex align-center" style="fontSize: 20px">
                            <span class="font-weight-bold">{{ score_record.current_score }}</span>
                            <span class="md-subtitle-1">/{{ score_record.total }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        list: {
            type: Array,
            default: []
        },

        isOpen: {
            type: Boolean,
            required: true
        },

        configData: {
            type: Array,
            required: true
        },
    },

    data() {
        return {
            summary_list: [],
            local_field_type_list: [
                { type: 'score', label: 'Score', icon: 'pin' },
                { type: 'dropdown', label: 'Selection (Single)', icon: 'arrow_drop_down_circle' },
                { type: 'multi_select', label: 'Selection (Multiple)', icon: 'check_box' },
            ],
            edit_item: null,
            color_range_1: {},
            color_range_2: {},
            color_range_3: {},
            progress_status_color: null
        }
    },

    watch: {
        isOpen: {
            immediate: true,
            handler (val) {
                if(val) this.local_index()
            }
        }
    },

    computed: {
        local_is_lgdown () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        ...mapState('ConfidenceScoreValues', {
            confidence_form_mode: 'form_mode'
        }),

        ...mapState('ProjectView', {
            project_item: 'item',
        }),
    },

    mounted () {
        this.local_index()
    },

    methods: {
        local_index () {
            this.summary_list = _.cloneDeep(this.list)
            this.score_record = _.cloneDeep(this.project_item.confidence_score)
            this.local_set_all_section_score()
            this.local_set_config_data()
            this.$nextTick(() => this.$refs.fieldsContainer.scrollTop = 0)
        },

        local_set_config_data () {
            this.configData.forEach(item => {
                if (item.color === 'red-dark') this.color_range_1 = item
                if (item.color === 'yellow-dark') this.color_range_2 = item
                if (item.color === 'green-dark') this.color_range_3 = item
            })
        },

        local_get_field_type_icon(field) {
            const type = field.type
            const custom_field_item = this.local_field_type_list.find(item => item.type === type)
            if (custom_field_item) return custom_field_item.icon
        },

        local_edit_item (field) {
            this.edit_item = field
            this.confidence_update_form_mode('field_edit')
            this.confidence_update_edit_item(field)
            this.$emit('close')
        },

        local_get_score_percentage () {
            if (this.score_record && !this.score_record.current_score) return
            const score_ratio = Number(this.score_record.current_score)/Number(this.score_record.total)
            return Math.round(score_ratio*100)
        },

        local_set_all_section_score () {
            const list = _.cloneDeep(this.summary_list)

            list.forEach((section, section_index) => {
                let current_section_score = 0
                section.current_score = 0

                section.fields.forEach(field => {
                    if (field.type === 'multi_select' && field.confidence_score_values.field_value && field.confidence_score_values.field_value.length) {
                        let value_total = 0
                        field.confidence_score_values.field_value.forEach(value => {
                            value_total = parseInt(value_total) + parseInt(value.value)
                        })
                        current_section_score = parseInt(current_section_score) + parseInt(value_total)
                    }
                    const field_value = field.type === 'dropdown' && field.confidence_score_values.field_value && field.confidence_score_values.field_value.value ? parseInt(field.confidence_score_values.field_value.value) : parseInt(field.confidence_score_values.field_value)
                    if (field && field_value > 0) {
                        current_section_score = parseInt(current_section_score) + field_value
                    }
                })

                list[section_index].current_score = current_section_score
            })
            this.summary_list = list
        },

        local_get_multiselect_value (field, count, type = null) {
            const value = _.cloneDeep(field.confidence_score_values.field_value)
            if (type === 'length') return value.length
            if (count === 2) return value.slice(0, count)

            const filtered = value.filter((item, index) => index > 1)
            return filtered
        },

        local_get_field_score_record (item, score_type) {
            if (item.type === 'score') {
                return score_type === 'percentage' ? this.local_get_field_score_percentage(item.confidence_score_values.field_value, item.maximum_value) : item.confidence_score_values.field_value + '/' + item.maximum_value
            }

            if (item.type === 'dropdown') {
                return score_type === 'percentage' ? this.local_get_field_score_percentage(item.confidence_score_values.field_value.value, item.maximum_value) : item.confidence_score_values.field_value.value + '/' + item.maximum_value
            }

            if (item.type === 'multi_select') {
                let field_score = 0
                if (item.confidence_score_values.field_value && item.confidence_score_values.field_value.length) {
                        let value_total = 0
                        item.confidence_score_values.field_value.forEach(value => {
                            value_total = parseInt(value_total) + parseInt(value.value)
                        })
                        field_score = parseInt(field_score) + parseInt(value_total)
                    }
                return score_type === 'percentage' ? this.local_get_field_score_percentage(field_score, item.maximum_value) : field_score + '/' + item.maximum_value
            }
        },

        local_get_field_score_percentage (value, max_value) {
            const score_ratio = value/max_value
            return Math.round(score_ratio*100)
        },

        local_has_permission () {
            return this.$can('projects.update-only') && this.$can('poc_confidence_score.update')
        },

        local_section_has_fields () {
            const sections_with_fields = this.summary_list.filter(section => {
                return !!(section.fields && section.fields.length)
            })
            return !!(sections_with_fields && sections_with_fields.length)
        },

        local_get_section_score_percentage (section) {
            const score_ratio = section.current_score/section.total_score
            return Math.round(score_ratio*100)
        },

        local_get_progress_color () {
            const current_score = this.local_get_score_percentage()
            if (current_score <= this.color_range_1.percentage) return this.color_range_1.color
            if ((current_score <= this.color_range_2.percentage) && (current_score > this.color_range_1.percentage)) return this.color_range_2.color
            if (current_score > this.color_range_2.percentage) return this.color_range_3.color
        },

        ...mapActions('ConfidenceScoreValues', {
            confidence_update_form_mode: 'update_form_mode',
            confidence_update_edit_item: 'update_edit_item'
        }),

        ...mapActions('ProjectView', {
            project_show: 'show',
        }),
    }
}
</script>


<template>
    <div
        style="position: relative"
        :class="{
            'c-external-selected': task.visibility === 'external' && (taskItem && taskItem.id === task.id),
            'yellow lighten-2': task.visibility == 1 && (taskItem && taskItem.id === task.id),
            'white': task.visibility == 0,
            'light-blue lighten-4': taskItem && taskItem.id === task.id,
            'u-item-disabled': task && task.completed_at
        }"
        class="u-cursor-pointer py-2"
        @click="$emit('openEditView', task, task.project_id)"
    >
        <div shrink class="px-0" :class="{'c-external-border': task.visibility === 'external'}" style="position: absolute; height: 100%; top: 0"></div>
        <div class="pa-0">
            <a-container container--fluid grid-list-xl class="py-2 pr-5 pl-8">
                <a-layout align-center>
                    <a-flex shrink class="px-0">
                        <slot name="status-meter" :task="task"></slot>
                    </a-flex>
                    <a-flex md4 lg5 xl6>
                        <h2
                            class="md-subtitle-1 text-truncate"
                            :class="[ taskItem && taskItem.id === task.id ? 'primary--text text--darken-2' : 'grey--text text--darken-3' ]"
                            :title="task.title"
                        >
                            {{ task.title }}
                        </h2>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink v-if="task.start_date || task.due_date" @click="$emit('openEditView', task.id, task.project_id)">
                        <div class="u-flex align-center grey--text text--darken-1">
                            <template v-if="task.start_date">
                                <g-moment class="md-caption" :value="task.start_date" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                <span class="mx-1"> - </span>
                            </template>
                            <span class="mx-1 md-caption" v-if="!task.start_date && task.due_date"> Due on </span>
                            <g-moment v-if="task.due_date" class="md-caption" :class="[localDueDatePassed(task) ? 'red--text text--darken-2': 'grey--text text--darken-1']" :value="task.due_date" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                            <span v-else class="md-caption">No Due Date</span>
                            <a-icon size="4" color="grey lighten-1" class="ml-3">fiber_manual_record</a-icon>
                        </div>
                    </a-flex>
                    <a-flex shrink pl-0 v-if="task.priority && task.priority != 0" @click="$emit('openEditView', task.id, task.project_id)">
                        <span class="md-subtitle-2" :class="{'red--text text--darken-2': task.priority === 'high', 'blue--text text--darken-2': task.priority === 'low', 'amber--text text--darken-2': task.priority === 'medium'}">
                            {{ (task.priority === 'high') ? 'HIGH' : '' }}
                            {{ (task.priority === 'medium') ? 'MEDIUM' : '' }}
                            {{ (task.priority === 'low') ? 'LOW' : '' }}
                        </span>
                        <a-icon size="4" color="grey lighten-1" class="ml-3">fiber_manual_record</a-icon>
                    </a-flex>
                    <a-flex pl-0 shrink v-if="groupBy !== 'project_id'">
                        <router-link
                            tag="span"
                            class="blue--text text--darken-3 u-cursor-pointer md-body-2"
                            v-if="task.project"
                            :title="'Project: ' + task.project.title"
                            :to="{name: 'projects-tasks', params: {id: task.project.id}}"
                            label small
                        >
                            <span>{{ task.project.title | truncateText(10) }}</span>
                            <a-icon size="4" color="grey lighten-1" class="ml-3">fiber_manual_record</a-icon>
                        </router-link>
                    </a-flex>
                    <a-flex shrink pl-0 v-if="(groupBy !== 'project_id') && task.milestone_id && task.milestone">
                        <router-link
                            tag="span"
                            :title="'Milestone: ' + task.milestone.title"
                            class="milestone-text blue-grey--text text--darken-3 u-cursor-pointer md-body-2"
                            small label
                            :to="{name: 'projects-tasks', params: {id: task.project ? task.project.id : '' }}"
                        >
                            <span>{{ task.milestone.title | truncateText(10) }}</span>
                            <a-icon size="4" color="grey lighten-1" class="ml-3">fiber_manual_record</a-icon>
                        </router-link>
                    </a-flex>
                    <a-flex shrink pl-0 v-if="task.milestone_id && task.milestone && groupBy === 'project_id'">
                        <router-link tag="span"
                            class="blue-grey--text text--darken-3 u-cursor-pointer md-body-2 text-truncate"
                            :to="{name: 'projects-tasks', params: {id: task.project ? task.project.id : '' }}"
                            :title="'Milestone: ' + task.milestone.title"
                            small label
                        >
                            <span>{{ task.milestone.title | truncateText(10) }}</span>
                            <a-icon size="4" color="grey lighten-1" class="ml-3">fiber_manual_record</a-icon>
                        </router-link>
                    </a-flex>
                    <a-flex shrink pl-0 class="u-flex align-center">
                        <g-attachment-count-info
                            :count="task.attachments_count"
                            fontSize="14"
                        />
                    </a-flex>
                    <a-flex shrink>
                        <slot name="assignee" :task="task"></slot>
                    </a-flex>
                    <a-flex shrink @click.stop="$emit('openTimeTrack', task)">
                        <a-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a-icon v-on="on" class="u-rounded-corners-full" size="16">hourglass_top</a-icon>
                            </template>
                            <span class="md-body-2 font-weight-medium">
                                {{ task.time_records_sum_duration_minutes ? localGetTimeText(task.time_records_sum_duration_minutes) : '0m' }}
                                <template v-if="task.estimated_duration_text">
                                    / {{ task.estimated_duration_text }}
                                </template>
                            </span>
                        </a-tooltip>
                    </a-flex>
                </a-layout>
            </a-container>
        </div>
    </div>
</template>

<script>
import { calculateByMins } from '@/helpers/helper-time-tracker.js'

export default {
    props: {
        task: {
            type: Object
        },
        taskItem: {
            type: Object
        },
        groupBy: {
            type: String
        },
    },

    methods: {
        localDueDatePassed ({ due_date, status }) {
            return moment(due_date).isBefore() && (status && status.status !== 'Completed')
        },

        localGetTimeText (value) {
            const { durationText } = calculateByMins(value)
            return durationText
        },
    }
}
</script>

<style scoped>
.c-external-border {
    border-left: 4px solid #F57C00;
}
.c-external-selected {
    background-color: #B3E5FC;
}
</style>

<template>
    <a-tooltip top content-class="c-tooltip-pointer c-tooltip-pointer--bottom u-rounded-corners-lg">
        <template v-slot:activator="{ on }">
            <a-sheet
                v-on="on"
                :width="history.daysPercent + '%'"
                :color="localGetHealthColor()"
                height="4"
                style="flex: 1 1 auto"
                class="u-rounded-corners-full"
            />
        </template>
        <div class="py-2 u-rounded-corners-lg">
            <template v-if="localIsPlaceholder">
                <div class="u-flex-center-y mb-1 md-body-2">
                    <a-sheet
                        :color="localGetHealthColor()"
                        :style="[{ border: '2px solid ' + localGetHealthColor('border') + '!important' }]"
                        width="16"
                        height="16"
                        class="u-rounded-corners mr-2"
                    />
                    <span class="u-font-weight-semibold white--text">{{ history.healthColor }}</span>
                    <span class="mx-1">-</span>
                    <span class="u-font-weight-semibold white--text">Initial Status</span>
                </div>
                <div class="u-flex" style="column-gap: 4px">
                    <span class="md-body-2 font-weight-medium grey--text text--lighten-1">
                        <template v-if="history.days > 0">
                            {{ history.days }} {{ history.days > 1 ? 'days' : 'day' }}
                        </template>
                        <template v-if="history.days === 0">Today</template>
                    </span>
                    <span class="grey--text text--lighten-1">|</span>
                    <span class="md-body-2 font-weight-medium grey--text text--lighten-1">
                        {{ localFormatDate(history.createdAt) }}
                    </span>
                </div>
            </template>

            <template v-else>
                <div class="u-flex-center-y mb-1 md-body-2">
                    <a-sheet
                        :color="localGetHealthColor()"
                        :style="[{ border: '2px solid ' + localGetHealthColor('border') + '!important' }]"
                        width="16"
                        height="16"
                        class="u-rounded-corners mr-2"
                    />
                    <span class="u-font-weight-semibold white--text">{{ history.healthColor }}</span>
                    <span class="mx-1">-</span>
                    <span class="u-font-weight-semibold white--text">{{ history.type | truncateText(50) }}</span>
                </div>
                <div class="u-flex mb-1" style="column-gap: 4px">
                    <span class="md-body-2 font-weight-medium grey--text text--lighten-1">
                        {{ history.days }} {{ history.days > 1 ? 'days' : 'day' }}
                    </span>
                    <span class="grey--text text--lighten-1">|</span>
                    <span class="md-body-2 font-weight-medium grey--text text--lighten-1">
                        {{ localFormatDate(history.createdAt) }}
                    </span>
                </div>
                <div class="md-body-2 font-weight-medium grey--text text--lighten-1">
                    Updated by {{ history.updatedBy ? history.updatedBy.name : '' | truncateText(50) }}
                </div>
            </template>
        </div>
    </a-tooltip>
</template>

<script>
import { diffSimple } from '@/helpers/helper-date-filter'

export default {
    inheritAttrs: false,

    props: {
        history: {
            type: Object
        },
    },

    computed: {
        localIsPlaceholder () {
            return this.history?._type === 'placeholder'
        },
    },

    methods: {
        localFormatDate (date) {
            const days = this.history.days
            if (days <= 1) return diffSimple(date)

            const endDate = moment(date).add(days, 'days')
            return diffSimple(date) + ' - ' + diffSimple(endDate)
        },

        localGetHealthColor (field = 'text') {
            let value = {}
            if (this.history.healthColor === 'Yellow') value = { text: 'amber darken-3', hex: '#FF8F00', border: '#FFE082' }
            if (this.history.healthColor === 'Red') value = { text: 'red darken-1', hex: '#E53935', border: '#FFAB91' }
            if (this.history.healthColor === 'Green') value = { text: 'green darken-1', hex: '#43A047', border: '#C8E6C9' }
            if (this.history.healthColor === 'Grey') value = { text: 'grey lighten-1', hex: '#BDBDBD', border: '#fff' }

            return field ? value[field] : value.text
        }
    }
}
</script>

<template>
    <a-main>
        <a-container grid-list-xl container--fluid class="pa-0 mx-0">
            <g-page-header
                :title="title"
                :subtitle="subtitle"
                :loading="loading"
                :icon="icon"
            ></g-page-header>
        </a-container>
        <slot></slot>
    </a-main>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Metas'
        },
        subtitle: {
            type: String,
            default: 'Customize meta labels associated with respective modules.'
        },
        loading: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'list_alt'
        }
    },
}
</script>

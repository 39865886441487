<template>
	<a-main>
        <a-container container--fluid grid-list-xl>
            <a-layout align-center class="mt-2">
                <a-flex>
                    <h2 class="md-heading-6 indigo--text text--darken-1 font-weight-semibold"><a-icon size="24" color="indigo" class="mr-1 u-icon-nudge">device_hub</a-icon>Workflows</h2>
                    <p class="md-body-2 mb-0 grey--text text--darken-2">Workflows to run in the background.</p>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid grid-list-xl class="my-4" v-if="!pageLoading && microservice_workflow_list && microservice_workflow_list.length === 0">
            <a-layout align-center justify-center>
                <a-flex xs4>
                    <div class="text-center"><a-icon size="160" color="grey lighten-1">device_hub</a-icon></div>
                    <h2 class="md-heading-5 mb-1 grey--text text--darken-3 text-center">No Workflows</h2>
                    <p class="md-body-2 mb-2 grey--text text--darken-1 text-center">Setup the workflow to automate data sharing between Salesforce and Success.</p>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid grid-list-xl v-if="pageLoading">
            <a-layout align-center>
                <a-flex>
                    <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden mb-3 px-3"  v-for="i in 7" :key="'layout_1'+i">
                        <a-layout align-center class="ma-0 py-0 px-3" :key="'layout_1'+i">
                            <a-flex shrink xs2>
                                <div class="d-flex align-center">
                                    <loader-template height="20" width="20%" class="u-rounded-corners-lg mr-2"></loader-template>
                                    <loader-template height="20" width="20%" class="u-rounded-corners-lg"></loader-template>
                                </div>
                            </a-flex>
                            <a-flex shrink xs6>
                                <loader-template height="24" width="70%" class="u-rounded-corners-lg"></loader-template>
                            </a-flex>
                            <a-spacer></a-spacer>
                            <a-flex shrink xs2>
                                <loader-template height="24" width="60px" class="float-right"></loader-template>
                            </a-flex>
                        </a-layout>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid grid-list-xl v-if="!pageLoading && microservice_workflow_list && microservice_workflow_list.length">
            <a-layout align-center>
                <a-flex>
                    <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden mb-3 px-3" v-for="workflow in microservice_workflow_list" :key="workflow.id">
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="py-3">
                                <a-layout align-center>
                                    <a-flex shrink>
                                        <div class="d-flex" v-if="workflow.from === 'salesforce' && workflow.to === 'success'">
                                            <img :src="require('../../assets/images/logo-salesforce.svg')" alt="Salesforce" style="display: inline-block; max-width: 32px; max-height: 32px;">
                                            <a-icon class="mx-2">arrow_right_alt</a-icon>
                                            <img :src="require('../../assets/images/success-imgs/icon-success.svg')" class="u-rounded-corners-lg" alt="Success" style="display: inline-block; max-width: 28px; max-height: 28px;">
                                        </div>
                                        <div class="d-flex" v-if="workflow.from === 'success' && workflow.to === 'salesforce'">
                                            <img :src="require('../../assets/images/success-imgs/icon-success.svg')" class="u-rounded-corners-lg" alt="Success" style="display: inline-block; max-width: 28px; max-height: 28px;">
                                            <a-icon class="mx-2">arrow_right_alt</a-icon>
                                            <img :src="require('../../assets/images/logo-salesforce.svg')" alt="Salesforce" style="display: inline-block; max-width: 32px; max-height: 32px;">
                                        </div>
                                    </a-flex>
                                    <a-flex class="u-cursor-pointer">
                                        <router-link :to="{name: `appstore-salesforce-microservices-workflows-edit`, params: { workflow_id: workflow.workflow_id }}">
                                            <p class="md-subtitle-1 grey--text text--darken-4 mb-0">{{ workflow.label }}</p>
                                        </router-link>
                                    </a-flex>
                                    <a-flex shrink>
                                        <p class="md-body-2 mb-0 grey--text text--darken-2 text-center" v-if="workflow.is_active ===  0">
                                            <a-icon size="22" class="mr-1 u-icon-nudge" color="grey">cloud_off</a-icon> Inactive
                                        </p>
                                        <p class="md-body-2 mb-0 green--text text--darken-2 text-center" v-if="workflow.is_active === 1">
                                            <a-icon size="22" class="mr-1 u-icon-nudge" color="green">check_circle</a-icon> Active
                                        </p>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            pageLoading: true,
        }
    },
    mounted() {
        this.localIndex()
    },
    computed: {
        ...mapState('MicroServiceWorkflow', {
            microservice_workflow_list: 'list',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        localSalesforceMicroserviceReportUrl() {
            return this.user_self && this.user_self.organization && this.user_self.organization.salesforce_microservice_report_url
        },

        localSalesforceMicroserviceReportToken() {
            return this.user_self && this.user_self.organization && this.user_self.organization.salesforce_microservice_report_token
        },
    },
    methods: {
        async localIndex() {
            if (this.user_self && this.user_self.organization && this.user_self.organization.flag_salesforce_microservice === 0 || this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.pageLoading = false
                return this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.microservice_workflow_clear()
            await this.microservice_workflow_index({ url: this.localSalesforceMicroserviceReportUrl, token: this.localSalesforceMicroserviceReportToken })
            this.pageLoading = false
        },
        ...mapActions('MicroServiceWorkflow', {
            microservice_workflow_index: 'index',
            microservice_workflow_clear: 'clear',
        }),
    },
}
</script>

let weeks = 0, days = 0, hours = 0, mins = 0
let inputWeeks = 0, inputDays = 0, inputHours = 0, inputMins = 0
let timeLogged = null
let error = null

const finalResult = () => {
    const durationMins = (weeks * 2400) + (days * 480) + (hours * 60) + (mins)
    const durationText = (weeks ? (weeks + 'w ') : '') + (days ? (days + 'd ') : '') + (hours ? (hours + 'h ') : '') + (mins ? (mins + 'm ') : '')
    return { w: weeks, d: days, h: hours, m: mins, durationMins, timeLogged, error, durationText }
}

const resetCounts = () => {
    weeks = 0
    days = 0
    hours = 0
    mins = 0
    inputWeeks = 0
    inputDays = 0
    inputHours = 0
    inputMins = 0,
    error = null
}

export const calculateByText = (loggedTime) => {
    resetCounts()
    if (!loggedTime) return finalResult()
    // timeLogged = loggedTime
    const timeArr = loggedTime.trim().split(' ')

    for (let i = 0; i < timeArr.length; i++) {
        const item = timeArr[i]
        const prev = timeArr[i - 1] ? timeArr[i - 1].slice(-1) : null
        const current = item.slice(-1)
        const calcValue = item.slice(0, -1)
        const count = parseFloat(calcValue)

        if (validateSplChars(calcValue)) {
            error = 'Format is incorrect'
            break
        }

        if (current === 'w' && (!prev || (prev !== 'w' && prev !== 'd' && prev !== 'h' && prev !== 'm'))) {
            if (!validateLogStr(item, 'w')) {
                error = 'Format is incorrect'
                break
            }

            inputWeeks = count
            const typeLength = item.match(/w/ig).length
            const totalCount = (inputWeeks * 2400) + (inputDays * 480) + (inputHours * 60) + (inputMins)
            if (typeLength === 1) {
                calculateByMins(parseFloat(totalCount.toFixed(2)), false)
            } else {
                weeks = 0
                continue
            }
        }
        else if (current === 'd' && (!prev || (prev !== 'd' && prev !== 'h' && prev !== 'm'))) {
            if (!validateLogStr(item, 'd')) {
                error = 'Format is incorrect'
                break
            }
            inputDays = count
            const typeLength = item.match(/d/ig).length
            const totalCount = (inputWeeks * 2400) + (inputDays * 480) + (inputHours * 60) + (inputMins)
            if (typeLength === 1) {
                calculateByMins(parseFloat(totalCount.toFixed(2)), false)
            } else {
                days = 0
                continue
            }
        }
        else if (current === 'h' && (!prev || (prev !== 'h' && prev !== 'm'))) {
            if (!validateLogStr(item, 'h')) {
                error = 'Format is incorrect'
                break
            }
            inputHours = count
            const typeLength = item.match(/h/ig).length
            const totalCount = (inputWeeks * 2400) + (inputDays * 480) + (inputHours* 60) + (inputMins)
            if (typeLength === 1) {
                calculateByMins(parseFloat(totalCount.toFixed(2)), false)
            } else {
                hours = 0
                continue
            }
        }
        else if (current === 'm' && prev !== 'm') {
            if (!validateLogStr(item, 'm')) {
                error = 'Format is incorrect'
                break
            }
            inputMins = count
            const typeLength = item.match(/m/ig).length
            const totalCount = (inputWeeks * 2400) + (inputDays * 480) + (inputHours * 60) + (inputMins)
            if (typeLength === 1) {
                calculateByMins(parseFloat(totalCount.toFixed(2)), false)
            } else {
                mins = 0
                continue
            }
        } else {
            // checkTypeValues()
            error = 'Format is incorrect'
            continue
        }
    }

    return finalResult()
}

const validateLogStr = (value, model) => {
    let result = 0
    if (model === 'w') {
        result = (value.match(/w/gi) || []).length
        if (result > 1 || (value.length && result === 0)) return false
    }
    if (model === 'd') {
        result = (value.match(/d/gi) || []).length
        if (result > 1 || (value.length && result === 0)) return false
    }
    if (model === 'h') {
        result = (value.match(/h/gi) || []).length
        if (result > 1 || (value.length && result === 0)) return false
    }
    if (model === 'm') {
        result = (value.match(/m/gi) || []).length
        if (result > 1 || (value.length && result === 0)) return false
    }
    return true
}

const validateSplChars = (value) => {
    // const pattern = /[^0-9\.]/g
    // const pattern = /[^0-9]([^\.])/g
    // const pattern = /[^0-9](\.).*\1/g
    // const pattern = /[^0-9].*[^0-9]/g
    // const pattern = /[^0-9][\.+]*[^0-9]/g
    // const pattern = /[^0-9A-Za-z][\.+]*[^0-9]/g
    // const pattern2 = /[\.]{2,2}/g
    let result = false
    const pattern1 = /[^0-9\.]/g
    const pattern2 = /^\d*\.?(?:\d{1,})?$/g

    result = pattern1.test(value)
    if (result) return result

    result = pattern2.test(value)
    return !result
}

export const calculateByMins = (count, reset = true) => {
    if (reset) resetCounts()

    if (count >= 2400) {  // check if mins is greater than a week (1week = 40hrs => 40 * 60 => 2400mins)
        const { q: weeksQ, r: minsR } = calculateDivision(count, 2400)
        addWeeks(weeksQ)

        if (minsR >= 480 && minsR < 2400) {  // check if mins is less than (a week) and greater than or equal to (a day)
            const { q: daysQ, r: minsFromDaysR } = calculateDivision(minsR, 480)
            calculateDays(daysQ)

            if (minsFromDaysR >= 60 && minsFromDaysR < 480) {  // check if mins is less than (a day) and greater than (an hour)
                const { q: hoursQ, r: minsFromHoursR } = calculateDivision(minsFromDaysR, 60)
                calculateHours(hoursQ)
                calculateRemainderMins(minsFromHoursR)
                return finalResult()
            } else hours = 0

            calculateRemainderMins(minsFromDaysR)
            return finalResult()
        } else days = 0

        if (minsR >= 60 && minsR < 480) {
            const { q: hoursQ, r: minsFromHoursR } = calculateDivision(minsR, 60)
            calculateHours(hoursQ)
            calculateRemainderMins(minsFromHoursR)
            return finalResult()
        } else hours = 0

        calculateRemainderMins(minsR)
        return finalResult()
    } else weeks = 0

    if (count >= 480 && count < 2400) {
        const { q: daysQ, r: minsFromDaysR } = calculateDivision(count, 480)
        calculateDays(daysQ)

         if (minsFromDaysR >= 60 && minsFromDaysR < 480) {
            const { q: hoursQ, r: minsFromHoursR } = calculateDivision(minsFromDaysR, 60)
            calculateHours(hoursQ)
            calculateRemainderMins(minsFromHoursR)
            return finalResult()
        }

        calculateRemainderMins(minsFromDaysR)
        return finalResult()
    } else days = 0

    if (count >= 60 && count < 480) {
        const { q: hoursQ, r: minsFromHoursR } = calculateDivision(count, 60)
        calculateHours(hoursQ)
        calculateRemainderMins(minsFromHoursR)
        return finalResult()
    } else hours = 0
    calculateRemainderMins(count)

    return finalResult()
}

export const calculateDivision = (count, value) => {
    return {
        q: parseInt(count / value),
        r: parseInt(count % value)
    }
}

export const calculateRemainderMins = (count) => {
    if (count >= 0 && count < 60) mins = parseInt(count)
    else mins = 0
}

export const calculateDays = (count) => {
    if (count >= 5) {
        const { q: weeksQ, r: daysR } = calculateDivision(count, 5)
        addWeeks(weeksQ)
        addDays(daysR)
    } else addDays(count)
}

export const calculateHours = (count) => {
    /*
        > Given: 78 hrs, Find: W, D, H, 1week = 40hrs, 1day = 8hrs
        if count > 40:
            (78 / 40 = 1week) && (78 % 40 = 38hours),
            (38 / 8 = 4days) && (38 % 8 = 6hours)
            RESULT: 1week 4days 6hours
    */
    if (count >= 40) {
        const { q: fromWeekQ, r: fromWeekR } = calculateDivision(count, 40)
        addWeeks(fromWeekQ)

        if (fromWeekR > 0) {
            const { q: daysQ, r: hoursR } = calculateDivision(fromWeekR, 8)
            if ((days + daysQ) >= 5) {
                const { q: fromWeekQDays, r: fromDaysR } = calculateDivision(days + daysQ, 5)
                addWeeks(fromWeekQDays)
                addDays(days + fromDaysR)
            } else addDays(days + daysQ)

            if (hoursR > 0) addHours(hours + hoursR)
        }
    }

    /*
        > Given: 36 hrs, Find: D, H, 1day = 8hrs
        if count >= 8 && count < 40: (here 8 means no.of hours per "day" and 40 refers no.of hours per "week")
            (36 / 8 = 4days) && (36 % 8 = 4hours)
            RESULT: 4days 4hours
    */
    if (count >= 8 && count < 40) {
        const { q: daysQ, r: hoursR } = calculateDivision(count, 8)
        if ((days + daysQ) >= 5) {
            const { q: fromWeekQDays, r: fromDaysR } = calculateDivision(days + daysQ, 5)
            addWeeks(fromWeekQDays)
            addDays(fromDaysR)
        } else addDays(daysQ)

        if (hoursR > 0) addHours(hours + hoursR)
    }

    /* Since the value is less than 8 we will add it directly */
    if (count >= 0 && count < 8) addHours(count)
}

export const calculateMins = (count) => {
    /*
        > Given: 3500 mins, Find: W, D, H, M => 1week = 2400mins, 1day = 480mins, 1hour = 60mins
        if count > 2400:
            (3500 / 2400 = 1week) && (3500 % 2400 = 1100mins),
            (1100 / 480 = 2days) && (1100 % 480 = 140mins)
            (140 / 60 = 2hours) && (140 % 60 = 20mins)
            RESULT: 1week 2days 2hours 20mins
    */
    if (count >= 2400) {
        const { q: fromWeekQ, r: fromWeekR } = calculateDivision(count, 2400)
        addWeeks(fromWeekQ)

        if (fromWeekR >= 480) {
            const { q: daysQ, r: minsR } = calculateDivision(fromWeekR, 480)
            if ((mins + minsR) >= 60) {
                const { q: hoursQ, r: minutesR } = calculateDivision(mins + minsR, 60)
                addDays(daysQ)
                addHours(hoursQ)
                addMinutes(minutesR)
            } else addMinutes(minsR)
        }

        if (fromWeekR >= 60 && fromWeekR < 480) {
            const { q: hoursInQ, r: minsR } = calculateDivision(fromWeekR, 60)
            addHours(hoursInQ)
            addMinutes(minsR)
        }
    }

    /*
        > Given: 1200 mins, Find: D, H, M => 1day = 480mins, 1hour = 60mins
        if count >= 480 &&  count < 2400:
            (1200 / 480 = 2days) && (1200 % 480 = 240mins),
            (240 / 60 = 4hours) && (240 % 60 = 0 hours)
            RESULT: 2days 4hours
    */
    if (count >= 480 && count < 2400) {
        const { q: daysQ, r: minsR } = calculateDivision(fromWeekR, 480)
        if ((mins + minsR) >= 60) {
            const { q: hoursQ, r: minutesR } = calculateDivision(mins + minsR, 60)
            addDays(daysQ)
            addHours(hoursQ)
            addMinutes(minutesR)
        } else {
            addDays(daysQ)
            addMinutes(minsR)
        }
    }

    /*
        > Given: 357 mins, Find: H, M => 1day = 480mins, 1hour = 60mins
        if count >= 60 &&  count < 480:
            (357 / 60 = 5hours) && (357 % 60 = 57mins),
            RESULT: 5hours 57mins
    */
    if (count >= 60 && count < 480) {
        const { q: hoursInQ, r: minsR } = calculateDivision(count, 60)
        addHours(hoursInQ)
        addMinutes(minsR)
    }

    /* Since the value is less than 60 which is less than an hour, we will add it directly */
    if (count >= 0 && count < 60) {
        if (mins + count > 60) calculateMins(mins + count)
        else addMinutes(mins + count)
    }
}

const addWeeks = (weeksCount) => {
    weeks = weeksCount
}

const addDays = (daysCount) => {
    days = daysCount
}

const addHours = (hoursCount) => {
    hours = hoursCount
}

const addMinutes = (minsCount) => {
    mins = minsCount
}

// const checkTypeValues = () => {
//     const timeArr = timeLogged.split(' ')
//     const typeEnums = ['w', 'd', 'h', 'm']
//     const typeEnumValues = { w: weeks, d: days, h: hours, m: mins }
//     for (let i = 0; i < timeArr.length; i++) {
//         const item = timeArr[i]
//         const current = item.slice(-1)
//         if (!typeEnums.includes(current) && typeEnumValues[current] === 0) {
//             if (current === 'w') weeks = 0
//             if (current === 'd') days = 0
//             if (current === 'h') hours = 0
//             if (current === 'm') mins = 0
//         }
//     }
// }

const api_endpoint = '/milestones';

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        query: {
            filter: {},
            sort: "order",
            include: [],
            fields: {},
            append: [],
        },
        query_filters: {
            page: 1,
            count: 10,
        },
        response: {},
        defaults: {
            list: [],
            item: {},
            meta: {},
            query: {
                filter: {},
                sort: "order",
                include: [],
                fields: {},
                append: [],
            },
            query_filters: {
                page: 1,
                count: 10,
            },
            response: {},
        },
        form_mode: "add",
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_MILESTONE(state, payload) {
            let index = _.findIndex(state.list, { id: payload.id });
            Object.assign(state.list[index], payload);
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { id: payload.id });
            // state.list[index] = payload.item;
            Object.assign(state.list[index], payload.item);
        },
        UPDATE_QUERIES(state, query) {
            state.query = query;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        PREPEND_ITEM(state, item) {
            state.list.unshift(item);
        },
        APPEND_ITEM(state, item) {
            state.list.push(item);
        },
        ADD_AT_INDEX(state, item) {
            state.list.splice(item.mIndex, 0, item.milestone);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { id: id });
            if (index === -1) return;
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = "add";
        },
        FORM_EDIT(state) {
            state.form_mode = "edit";
        },
        REORDER_TASKS(state, payload) {
            const list = _.cloneDeep(state.list);
            let index = _.findIndex(list, { id: payload.id });
            list[index].tasks = payload.list;
            state.list = list;
        },
        CLEAR_RESPONSE(state) {
            state.response = {};
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
        PREPEND_TASK_ITEM(state, payload) {
            let milestone_index = _.findIndex(state.list, {
                id: payload.milestone_id,
            });
            state.list[milestone_index].tasks.unshift(payload.data);
            state.list[milestone_index].tasks_count = state.list[milestone_index].tasks_count + 1;
        },
        UPDATE_TASK_LIST_ITEM(state, payload) {
            let milestone_index = _.findIndex(state.list, {
                id: payload.milestone_id,
            });
            if (milestone_index === -1) return;

            let milestone_tasks = state.list[milestone_index].tasks;
            let task_index = _.findIndex(milestone_tasks, { id: payload.id });
            if (task_index === -1) return;

            state.list[milestone_index].tasks[task_index] = payload;
            state.list = [...state.list];
        },
        REMOVE_TASK_LIST_ITEM(state, payload) {
            const list = _.cloneDeep(state.list);
            const milestone_index = _.findIndex(list, { id: payload.milestone_id });
            const milestone_tasks = list[milestone_index].tasks;
            const task_index = _.findIndex(milestone_tasks, { id: payload.id });
            if (task_index === -1) return;
            list[milestone_index].tasks.splice(task_index, 1);
            list[milestone_index].tasks_count--
            state.list = list;
        },
        UPDATE_TASK_UPDATE_VISIBILITY(state, payload) {
            let milestone_index = _.findIndex(state.list, {
                id: payload.milestone_id,
            });
            let milestone_tasks = state.list[milestone_index].tasks;
            let task_index = _.findIndex(milestone_tasks, { id: payload.id });
            state.list[milestone_index].tasks[task_index].visibility =
                payload.state;
        },
        REMOVE_TASK_ITEM(state, payload) {
            let milestone_index = _.findIndex(state.list, {
                id: payload.milestone_id,
            });
            let milestone_tasks = state.list[milestone_index].tasks;

            let task_index = _.findIndex(milestone_tasks, {
                id: payload.task_id,
            });

            if (
                state.list[milestone_index].tasks[task_index].completed_at === 1
            ) {
                state.list[milestone_index].tasks_completed_count =
                    state.list[milestone_index].tasks_completed_count - 1;
            }

            state.list[milestone_index].tasks.splice(task_index, 1);
            state.list[milestone_index].tasks_count =
                state.list[milestone_index].tasks_count - 1;

            if (!state.list[milestone_index].tasks.length) {
                state.list[milestone_index].tasks_count = 0;
                state.list[milestone_index].tasks_completed_count = 0;
            }
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch("Interface/loader_start");
            return axios
                .get(api_endpoint, { params: payload })
                .then((response) => {
                    if (context.state.query_filters.page == 1) {
                        context.commit("UPDATE_LIST", response.data.data);
                    } else {
                        context.commit("APPEND_LIST", response.data.data);
                    }
                    context.commit("UPDATE_META", response.data.meta);
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: "Done",
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: error.response.data.message,
                        status: "error",
                    });
                });
        },
        show(context, payload) {
            this.dispatch("Interface/loader_start");
            return axios
                .get(api_endpoint + "/" + payload.id, {
                    params: payload.params,
                })
                .then((response) => {
                    if (
                        payload &&
                        payload.mode !== "add" &&
                        payload.mode !== "no-update"
                    ) {
                        context.commit("UPDATE_ITEM", response.data.data);
                        context.commit("UPDATE_LIST_ITEM", {
                            id: payload.id,
                            item: response.data.data,
                        });
                    } else {
                        if (payload && payload.mode !== "no-update")
                            context.commit("PREPEND_ITEM", response.data.data);
                        if (payload && payload.mode === "no-update")
                            context.commit("UPDATE_ITEM", response.data.data);
                    }
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: "Done",
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: error.response.data.message,
                        status: "error",
                    });
                });
        },
        store(context, payload) {
            this.dispatch("Interface/loader_start");
            return axios
                .post(api_endpoint, payload)
                .then((response) => {
                    // if (payload.page && payload.page === 'dashboard_milestone') context.commit('UPDATE_ITEM', payload)
                    // else {
                    //     payload.params =  { 'include': 'tasks,tasksCount,tasksCompletedCount,assignees' },
                    //     context.dispatch('show', { ...payload, mode: 'add' })
                    // }
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: "Done",
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: error.response.data.message,
                        status: "error",
                        server: error.response.data,
                    });
                });
        },

        async clone({ commit, dispatch }, payload) {
            this.dispatch("Interface/loader_start");
            try {
                await axios.post(api_endpoint + "/clone", payload);
                this.dispatch("Interface/loader_stop");
                commit("THROW_RESPONSE", { text: "Done", status: "success" });
            } catch (error) {
                this.dispatch("Interface/loader_stop");
                commit("THROW_RESPONSE", {
                    text: error.response.data.message,
                    status: "error",
                    server: error.response.data,
                });
            }
        },

        store_local(context, payload) {
            context.commit("APPEND_ITEM", payload);
        },
        store_index_local(context, payload) {
            context.commit("ADD_AT_INDEX", payload);
        },
        add_list(context, payload) {
            context.commit("APPEND_LIST", payload);
        },
        update_list(context, payload) {
            context.commit("UPDATE_LIST", payload);
        },
        update_local(context, payload) {
            context.commit("UPDATE_MILESTONE", payload);
        },
        remove_local(context, payload) {
            context.commit("REMOVE_ITEM", payload);
        },
        task_store(context, payload) {
            context.commit("PREPEND_TASK_ITEM", payload);
        },
        task_update(context, payload) {
            context.commit("UPDATE_TASK_LIST_ITEM", { ...payload });
        },
        task_remove(context, payload) {
            context.commit("REMOVE_TASK_LIST_ITEM", { ...payload });
        },
        task_visibility(context, payload) {
            context.commit("UPDATE_TASK_UPDATE_VISIBILITY", payload);
        },
        task_destroy(context, payload) {
            context.commit("REMOVE_TASK_ITEM", payload);
        },
        reorder_tasks(context, payload) {
            context.commit("REORDER_TASKS", payload);
        },

        async bulk_update({ commit }, payload) {
            this.dispatch("Interface/loader_start");
            try {
                await axios.patch(api_endpoint + "/bulk-update", payload);
                commit("THROW_RESPONSE", { text: "Done", status: "success" });
                this.dispatch("Interface/loader_stop");
            } catch (error) {
                this.dispatch("Interface/loader_stop");
                commit("THROW_RESPONSE", {
                    text: error?.response?.data?.message,
                    status: "error",
                    server: error?.response?.data,
                });
            }
        },

        async bulk_destroy({ commit }, payload) {
            this.dispatch("Interface/loader_start");
            try {
                await axios.delete(api_endpoint + "/bulk-destroy", {
                    params: payload,
                });
                commit("THROW_RESPONSE", { text: "Done", status: "success" });
                this.dispatch("Interface/loader_stop");
            } catch (error) {
                this.dispatch("Interface/loader_stop");
                commit("THROW_RESPONSE", {
                    text: error?.response?.data?.message,
                    status: "error",
                    server: error?.response?.data,
                });
            }
        },

        update(context, payload) {
            this.dispatch("Interface/loader_start");
            return axios
                .patch(api_endpoint + "/" + payload.id, payload)
                .then((response) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: "Done",
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: error.response.data.message,
                        status: "error",
                        server: error.response.data,
                    });
                });
        },
        expand(context, payload) {
            this.dispatch("Interface/loader_start");
            return axios
                .patch(
                    api_endpoint + "/" + context.state.item.id + "/expand",
                    context.state.item
                )
                .then((response) => {
                    context.commit("UPDATE_ITEM", response.data.data);
                    context.commit("UPDATE_LIST_ITEM", {
                        id: context.state.item.id,
                        item: response.data.data,
                    });
                    this.dispatch("Interface/loader_stop");
                    context.commit("REFRESH_LIST");
                    context.commit("THROW_RESPONSE", {
                        text: "Done",
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: error.response.data.message,
                        status: "error",
                        server: error.response.data,
                    });
                });
        },
        collapse(context, payload) {
            this.dispatch("Interface/loader_start");
            return axios
                .patch(
                    api_endpoint + "/" + context.state.item.id + "/collapse",
                    context.state.item
                )
                .then((response) => {
                    context.commit("UPDATE_ITEM", response.data.data);
                    context.commit("UPDATE_LIST_ITEM", {
                        id: context.state.item.id,
                        item: response.data.data,
                    });
                    this.dispatch("Interface/loader_stop");
                    context.commit("REFRESH_LIST");
                    context.commit("THROW_RESPONSE", {
                        text: "Done",
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: error.response.data.message,
                        status: "error",
                        server: error.response.data,
                    });
                });
        },
        expand_all(context, payload) {
            this.dispatch("Interface/loader_start");
            return axios
                .patch(api_endpoint + "/expand_all", payload.data)
                .then((response) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("REFRESH_LIST");
                    context.commit("THROW_RESPONSE", {
                        text: "Done",
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: error.response.data.message,
                        status: "error",
                        server: error.response.data,
                    });
                });
        },
        collapse_all(context, payload) {
            this.dispatch("Interface/loader_start");
            return axios
                .patch(api_endpoint + "/collapse_all", payload.data)
                .then((response) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("REFRESH_LIST");
                    context.commit("THROW_RESPONSE", {
                        text: "Done",
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: error.response.data.message,
                        status: "error",
                        server: error.response.data,
                    });
                });
        },
        destroy(context, payload) {
            this.dispatch("Interface/loader_start");
            return axios
                .delete(api_endpoint + "/" + payload.id)
                .then((response) => {
                    context.commit("REMOVE_ITEM", payload.id);
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: "Done",
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: error.response.data.message,
                        status: "error",
                    });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch("clear_item");
                let index = _.findIndex(context.state.list, { id: payload.id });
                context.commit(
                    "UPDATE_ITEM",
                    _.cloneDeep(context.state.list[index])
                );
                context.commit("FORM_EDIT");
                resolve("Selected");
            });
        },
        milestone_update(context, payload) {
            context.commit("UPDATE_MILESTONE", payload);
        },
        client_visible(context, payload) {
            this.dispatch("Interface/loader_start");
            return axios
                .patch(api_endpoint + "/" + payload.id + "/client_visible", {})
                .then((response) => {
                    context.commit("UPDATE_LIST_ITEM", {
                        id: payload.id,
                        item: response.data.data,
                    });
                    this.dispatch("Interface/loader_stop");
                    context.commit("REFRESH_LIST");
                    context.commit("THROW_RESPONSE", {
                        text: "Done",
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: error.response.data.message,
                        status: "error",
                    });
                });
        },
        client_invisible(context, payload) {
            this.dispatch("Interface/loader_start");
            return axios
                .patch(
                    api_endpoint + "/" + payload.id + "/client_invisible",
                    {}
                )
                .then((response) => {
                    context.commit("UPDATE_LIST_ITEM", {
                        id: payload.id,
                        item: response.data.data,
                    });
                    this.dispatch("Interface/loader_stop");
                    context.commit("REFRESH_LIST");
                    context.commit("THROW_RESPONSE", {
                        text: "Done",
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: error.response.data.message,
                        status: "error",
                    });
                });
        },
        reorder(context, payload) {
            this.dispatch("Interface/loader_start");
            if (
                !payload.type ||
                (payload.type && payload.type !== "no-local-reorder")
            ) {
                context.commit("UPDATE_LIST", payload.list);
            }
            return axios
                .patch(api_endpoint + "/reorder", { data: payload.list })
                .then((response) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("REFRESH_LIST");
                    context.commit("THROW_RESPONSE", {
                        text: "Done",
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: error.response.data.message,
                        status: "error",
                    });
                });
        },
        async reorder_milestone(context, payload) {
            this.dispatch("Interface/loader_start");
            try {
                await axios.patch(api_endpoint + "/reorder", { data: payload });
                this.dispatch("Interface/loader_stop");
                context.commit("REFRESH_LIST");
                context.commit("THROW_RESPONSE", {
                    text: "Done",
                    status: "success",
                });
            } catch (error) {
                this.dispatch("Interface/loader_stop");
                context.commit("THROW_RESPONSE", {
                    text: error.response.data.message,
                    status: "error",
                });
            }
        },
        tasks_reorder(context, payload) {
            this.dispatch("Interface/loader_start");
            return axios
                .patch(api_endpoint + "/tasks/reorder", { data: payload.list })
                .then((response) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("REFRESH_LIST");
                    context.commit("THROW_RESPONSE", {
                        text: "Done",
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: error.response.data.message,
                        status: "error",
                    });
                });
        },
        template_import(context, payload) {
            this.dispatch("Interface/loader_start");
            return axios
                .post(api_endpoint + "/import/" + payload.id, {
                    project_id: payload.project_id,
                })
                .then((response) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: "Done",
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.dispatch("Interface/loader_stop");
                    context.commit("THROW_RESPONSE", {
                        text: error.response.data.message,
                        status: "error",
                        server: error.response.data,
                    });
                });
        },
        clear_response(context) {
            context.commit(
                "THROW_RESPONSE",
                _.cloneDeep(context.state.defaults.response)
            );
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit(
                    "UPDATE_ITEM",
                    _.cloneDeep(context.state.defaults.item)
                );
                context.commit(
                    "UPDATE_LIST",
                    _.cloneDeep(context.state.defaults.list)
                );
                context.commit(
                    "THROW_RESPONSE",
                    _.cloneDeep(context.state.defaults.response)
                );
                context.commit(
                    "UPDATE_QUERIES",
                    _.cloneDeep(context.state.defaults.query)
                );
                context.commit("FORM_CREATE");
                resolve("Cleared");
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit(
                    "UPDATE_ITEM",
                    _.cloneDeep(context.state.defaults.item)
                );
                context.commit(
                    "THROW_RESPONSE",
                    _.cloneDeep(context.state.defaults.response)
                );
                context.commit("FORM_CREATE");
                resolve("Cleared");
            });
        },
    },
};

<template>
    <div v-if="local_show_confirmation" v-bind="$attrs">
        <a-menu v-bind="$attrs" offset-y min-width="400" max-width="400" :close-on-content-click="false" :disabled="disabled || !canUpdate" content-class="u-rounded-corners">
            <template v-slot:activator="{ on, value }">
                <div v-on.stop="on">
                    <slot>
                        <a-sheet max-width="130" :class="['d-inline-flex align-center u-rounded-corners px-3 u-cursor-pointer', dense ? '' : 'pa-2']" :style="[dense ? { paddingTop: '3px', paddingBottom: '3px' } : '']" :color="check_is_public ? 'orange darken-2' : 'grey darken-4'">
                            <div class="u-flex align-center">
                                <a-icon size="14" color="white">{{ check_is_public ? 'public' : 'lock' }}</a-icon>
                                <span class="md-caption font-weight-medium white--text ml-2">{{ check_is_public ? 'Public' : 'Private' }}</span>
                            </div>
                            <a-spacer></a-spacer>
                            <a-icon v-if="canUpdate" size="20" color="white" class="ml-2">arrow_drop_{{ value ? 'up' : 'down' }}</a-icon>
                        </a-sheet>
                    </slot>
                </div>
            </template>
            <div>
                <a-sheet class="grey lighten-4 pa-6">
                    <div class="u-flex align-center mb-2">
                        <a-icon size="24" color="indigo darken-2" class="mr-2">visibility</a-icon>
                        <h3 class="md-subtitle-1 font-weight-medium indigo--text text--darken-2">Change <span class="text-capitalize">{{ type }}</span> Visibility</h3>
                    </div>
                    <div class="grey--text text--darken-1">You can choose to share this {{ type.toLowerCase() }} with external collaborators or keep it internal only</div>
                </a-sheet>
                <a-sheet>
                    <a-progress-linear height="2" :indeterminate="loading" :color="loading ? 'orange darken-2' : 'body lighten-3'"></a-progress-linear>
                </a-sheet>
                <a-sheet class="pa-5 px-6">
                    <g-toggle custom-bg dense-xl :disabled="disabled" v-model="check_is_public" class="d-inline-flex align-center" :color="check_is_public ? 'orange darken-2' : 'grey darken-4'">
                        <span :class="[(check_is_public && !disabled) ? 'orange--text text--darken-2' : (!disabled ? 'grey--text text--darken-2' : 'grey--text text--lighten-1'), 'font-weight-medium md-body-1 ml-2']">{{ check_is_public ? 'Public' : 'Private' }}</span>
                    </g-toggle>
                </a-sheet>
            </div>
        </a-menu>
    </div>
    <div v-else @click.stop="local_manual_toggle(check_is_public)">
        <slot :isPublic="check_is_public" :toggle="local_manual_toggle">
            <a-sheet max-width="130" :class="['d-inline-flex align-center u-rounded-corners px-3 u-cursor-pointer', dense ? '' : 'pa-2']" :style="[dense ? { paddingTop: '3px', paddingBottom: '3px' } : '']" :color="check_is_public ? 'orange darken-2' : 'grey darken-4'">
                <div class="u-flex align-center">
                    <a-icon size="14" color="white">{{ check_is_public ? 'public' : 'lock' }}</a-icon>
                    <span class="md-caption font-weight-medium white--text ml-2">{{ check_is_public ? 'Public' : 'Private' }}</span>
                </div>
            </a-sheet>
        </slot>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        type: {
            type: String,
            required: true
        },
        dense: {
            type: Boolean,
            default: false
        },
        isPublic: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        check_is_public: {
            get () {
                return this.isPublic
            },
            set (value) {
                this.$emit('action')
            }
        },

        local_show_confirmation() {
            const preference = this.preference_list.find(item => item.key === 'organization.visibility_changeconfirm')
            return preference && preference.value === 'Yes'
        },

        ...mapState('Preference', {
            preference_list: 'list',
        })
    },

    methods: {
        local_manual_toggle (value) {
            if (![0, 1, true, false].includes(value)) {
                throw new Error(`Toggle parameter must be either (1 or 0) of type integer or boolean (true/false). Current parameter: ${value}.`)
            }
            this.check_is_public = !value
            this.$emit('action', !value)
        }
    }
}
</script>

<template>
    <a-tooltip bottom offset-y v-bind="$attrs">
        <template v-slot:activator="{ on }">
            <slot name="trigger" :on="on">
                <div v-on="on" @click="$emit('open', item)" :class="['u-flex-center-y text-truncate', contentClass]" style="column-gap: 8px;" :style="contentStyle">
                    <a-icon size="20" color="grey darken-1" outlined>timer</a-icon>
                    <span class="md-body-2 md-subtitle-1 font-weight-medium u-wfull u-hfull d-block text-truncate">
                        {{ item.time_records_sum_duration_minutes ? localGetTimeEstText(item.time_records_sum_duration_minutes) : '0m' }}
                        <template v-if="estimatedDuration">
                            / {{ estimatedDuration }}
                        </template>
                    </span>
                </div>
            </slot>
        </template>
        <span>
            {{ item.time_records_sum_duration_minutes ? localGetTimeEstText(item.time_records_sum_duration_minutes) : '0m' }}
            <template v-if="estimatedDuration">
                / {{ estimatedDuration }}
            </template>
        </span>
    </a-tooltip>
</template>

<script>
import { calculateByMins } from '@/helpers/helper-time-tracker.js'

export default {
    props: {
        item: {
            type: Object
        },
        estimatedDuration: {
            type: String
        },
        contentClass: {
            type: String | Array
        },
        contentStyle: {
            type: String | Array
        },
    },

    methods: {
        localGetTimeEstText (value) {
            const { durationText } = calculateByMins(value)
            return durationText
        },
    }
}
</script>

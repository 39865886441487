<template>
    <!--<span v-on="$listeners" :title="methodGetLabel(tag) && methodGetLabel(tag).length >= textRange ? methodGetLabel(tag) : ''" style="padding: 8px;" :class="{'my-1': !dense}" class="d-inline-flex align-center mr-2 u-rounded-corners u-cursor-pointer" :style="{border: `1px solid ${dataTypeColor}`, color: dataTypeColor, fontSize: dataFontSize + 'px'}">-->
    <span
        v-on="$listeners"
        style="padding: 4px 6px;"
        class="d-inline-flex align-center mr-2 u-rounded-corners u-cursor-pointer"
        :class="{'my-1': !dense}"
        :style="{
            border: `1px solid ${dataTypeColor}`,
            color: '#fff',
            backgroundColor: dataTypeColor,
            fontSize: dataFontSize + 'px',
            height: height ? height + 'px' : 'auto'
        }"
        :title="methodGetLabel(tag) && methodGetLabel(tag).length >= textRange ? methodGetLabel(tag) : ''"
    >
        {{ methodGetLabel(tag) | truncateText(textRange) }}
        <a-icon v-if="canUpdate && !hideClearIcon" @click.stop="$emit('destroy', tag, type)" class="c-icon--dark u-cursor-pointer u-rounded-corners ml-2" style="top: 0px;" size="16" color="#fff">clear</a-icon>
    </span>
</template>

<script>
export default {
    props: {
        tag: {
            type: Object | String,
            required: true
        },
        type: {
            type: String,
            default: 'normal'
        },
        height: {
            type: String | Number,
            default: ''
        },
        textRange: {
            type: Number,
            default: 40
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        hideClearIcon: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        textMd: {
            type: Boolean,
            default: false
        },
        textLg: {
            type: Boolean,
            default: false
        },
        textXl: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        dataFontSize () {
            if (this.textMd) return 12
            if (this.textLg) return 14
            if (this.textXl) return 16
            return 14
        },

        dataTypeColor () {
            switch (this.type) {
                case 'normal': return '#3949AB'
            }
        },
    },

    methods: {
        methodGetLabel (tag) {
            if (tag && tag.label) return tag.label
            if (tag && tag.name) return tag.name
            return tag
        }
    }
}
</script>

<template>
    <a-autocomplete
        v-model="dataSelectedUser"
        :items="list"
        item-text="user.name"
        item-value="user_id"
        placeholder="Select Users"
        class="u-border"
        :menu-props="{ closeOnContentClick: true, closeOnClick: true }"
        :disabled="loading || isExternal"
        :loading="loading ? 'grey lighten-1' : loading"
        @click:clear="dataSelectedUser = []"
        @change="methodSelectUser(dataSelectedUser)"
        persistent-placeholder solo flat hide-details small-chips hide-selected clearable
    >
        <template v-slot:no-data>
            <div class="px-4 py-2">
                <span class="body-1 grey--text">No Users found</span>
            </div>
        </template>
        <template v-slot:item="{ item }">
            <a-layout align-center class="py-2">
                <g-avatar :item="item"></g-avatar>
                <g-profile-info :item="item" set-max-width></g-profile-info>
            </a-layout>
        </template>
    </a-autocomplete>
</template>

<script>
export default {
    name: 'SAssigneeField',

    props: {
        list: {
            type: Array
        },
        item: {
            type: Object
        },
        showMenu: {
            type: Boolean
        },
        isExternal: {
            type: Boolean
        },
        loading: {
            type: Boolean
        },
    },

    data () {
        return {
            dataSelectedUser: ''
        }
    },

    methods: {
        methodSelectUser (userId) {
            if (!this.dataSelectedUser || !userId) return
            this.dataSelectedUser = []
            this.$emit('assign', userId)
            setTimeout(() => this.dataSelectedUser = [], 500)
        }
    }
}
</script>


const api_endpoint = '/projects';
const show_methods = ['show-add-end', 'show-add-start', 'show', 'show-update', 'show-all-add-start', 'show-all-add-end']
const modify_methods = ['only-add-start', 'only-add-end', 'only-update', 'only-add', 'only-all-add-start', 'only-all-add-end']

import { Base64 } from 'js-base64';
export default {
    namespaced: true,

    state: {
        list: [],
        item: {},
        links: {},
        response: {},
        saved_items: [],
        is_friendly_view: false,
        stage_history_meta: {},
        stage_history: [],
        confidence_score_log: [],
        confidence_score_log_meta: {},
        defaults: {
            list: [],
            item: {},
            links: {},
            is_friendly_view: false,
            response: {},
        },
    },

    mutations: {
        UPDATE_ITEM(state, item) {
            state.item = Object.assign({}, state.item, { ...item })
        },
        REPLACE_ITEM(state, item) {
            state.item = item
        },
        CLEAR_ITEM(state, item) {
            state.item = item
        },
        UPDATE_STAGE_HISTORY(state, payload) {
            state.stage_history = payload
        },
        UPDATE_STAGE_HISTORY_META(state, payload) {
            state.stage_history_meta = payload
        },
        UPSERT_SAVED_ITEM(state, item) {
            const index = state.saved_items.findIndex(i => i.id === item.id)
            if (index === -1) return state.saved_items.push(item)

            Object.assign(state.saved_items[index].summary, {
                ...state.saved_items[index].summary,
                ...item.summary
            })
        },
        REMOVE_SAVED_ITEM(state, item) {
            const index = state.saved_items.findIndex(i => i.id === item.id)
            if (index !== -1) state.saved_items.splice(index, 1)
        },
        CLEAR_SAVED_ITEMS(state) {
            state.saved_items = []
        },
        UPDATE_CONFIDENCE_SCORE_LOG(state, payload) {
            state.confidence_score_log = payload
        },
        UPDATE_CONFIDENCE_SCORE_LOG_LIST_ITEM(state, payload) {
            const index = state.confidence_score_log.findIndex(item => item.id === payload.id)
            if (index !== -1) state.confidence_score_log[index] = _.cloneDeep(payload)
        },
        UPDATE_CONFIDENCE_SCORE_LOG_META(state, payload) {
            state.confidence_score_log_meta = payload
        },
        UPDATE_LINKS(state, links) {
            state.links = links
        },
        UPDATE_FRIENDLY_VIEW(state, payload) {
            state.is_friendly_view = payload
        },
        COMPETITOR_REMOVE(state, payload) {
            const item = _.cloneDeep(state.item)
            const index = item.competitors.findIndex(item => (item.id === payload.id) && (item.category_id === payload.categoryId))
            if (index !== -1) item.competitors.splice(index, 1)
            state.item = item
        },
        TERRITORY_REMOVE(state, territory_id) {
            const item = _.cloneDeep(state.item)
            const index = item.territories.findIndex(item => item.id === territory_id)
            if (index !== -1) item.territories.splice(index, 1)
            state.item = item
        },
        THROW_RESPONSE (state, payload) {
            state.response = payload
        },
    },

    actions: {
        async show ({ commit, dispatch }, payload = {}) {
            dispatch('loader_start')
            try {
                const { data: { data } } = await axios.get(`${api_endpoint}/${payload.id}`, { params: payload.params })
                if (payload.stateless) {
                    dispatch('throw_success')
                    return { data, status: 'success' }
                }
                commit('UPDATE_ITEM', data)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
                if (payload.stateless) {
                    return { data: null, status: 'error' }
                }
            }
        },

        async update ({ dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.patch(`${api_endpoint}/${payload.id}`, payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async update_description ({ dispatch }, payload) {
            dispatch('loader_start')
            const description = await dispatch('encode_content', payload)
            try {
                await axios.patch(`${api_endpoint}/${payload.id}/description/update`, { description_json: description })
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async update_next_step ({ dispatch }, payload) {
            dispatch('loader_start')
            const next_step = await dispatch('encode_content', payload)
            try {
                await axios.patch(`${api_endpoint}/${payload.id}/next-steps/update`, { next_step_json: next_step })
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async destroy ({ dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.delete(`${api_endpoint}/${payload.id}`)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async master_template_import ({ dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.post(`${api_endpoint}/master-template/import`, payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async export ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                const { data: { url } } = await axios.get(`${api_endpoint}/${payload.id}/exports`)
                commit('UPDATE_LINKS', url)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async stage_history ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                const { data: { data, meta } } = await axios.get(`${api_endpoint}/${payload.id}/stage/history`, { params: payload.params })
                commit('UPDATE_STAGE_HISTORY', data)
                commit('UPDATE_STAGE_HISTORY_META', meta)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async confidence_score_log_index ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                const { data: { data, meta } } = await axios.get(`${api_endpoint}/${payload.project_id}/confidence-score-logs`, { params: payload.params })
                commit('UPDATE_CONFIDENCE_SCORE_LOG', data)
                commit('UPDATE_CONFIDENCE_SCORE_LOG_META', meta)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        log_details_update ({ commit }, payload) {
            commit('UPDATE_CONFIDENCE_SCORE_LOG_LIST_ITEM', payload)
        },

        async stage_update ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.patch(`${api_endpoint}/${payload.project_id}/stages/${payload.stage_id}`, { result_id: payload.result_id })
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async workspace_update ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.patch(`${api_endpoint}/${payload.project_id}/stage/move`, payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async archive ({ dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.patch(`${api_endpoint}/${payload.project_id}/stage/move`, { stage: payload.stage_id })
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async update_item ({ commit }, payload) {
            commit('UPDATE_ITEM', payload)
        },

        async replace_item ({ commit }, payload) {
            commit('REPLACE_ITEM', payload)
        },

        async competitor_remove ({ commit }, payload) {
            commit('COMPETITOR_REMOVE', payload)
        },

        async territory_remove ({ commit }, payload) {
            commit('TERRITORY_REMOVE', payload)
        },

        async switch_friendly_view ({ commit }, payload) {
            commit('UPDATE_FRIENDLY_VIEW', payload)
        },

        async save_item ({ commit }, payload) {
            commit('UPSERT_SAVED_ITEM', payload)
        },

        async unsave_item ({ commit }, payload) {
            commit('REMOVE_SAVED_ITEM', payload)
        },

        async clear_saved ({ commit }) {
            commit('CLEAR_SAVED_ITEMS')
        },

        async throw_success ({ commit }) {
            commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            this.dispatch('Interface/loader_stop')
        },

        async throw_error ({ commit }, error) {
            this.dispatch('Interface/loader_stop')
            commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
        },

        async loader_start () {
            this.dispatch('Interface/loader_start')
        },

        async clear_item ({ commit }) {
            commit('CLEAR_ITEM', {})
            commit('THROW_RESPONSE', [])
        },

        async clear_response ({ commit }) {
            commit('THROW_RESPONSE', [])
        },

        encode_content ({commit}, payload) {
            const content = payload['description_json'] || payload['next_step_json']
            return Base64.encode(JSON.stringify(content))
        }
    },
}

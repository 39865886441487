<template>
    <a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="py-8 px-0 pr-1 mb-12">
            <a-layout fill-height>
                <a-flex xs3 xl2>
                    <div style="position: sticky; top: 88px;">
                        <p style="letter-spacing: 0.2px;" class="md-body-2 font-weight-medium text-uppercase grey--text text--darken-1 mb-0 ml-2">Custom Field Types</p>
                        <a-list class="u-list-condensed transparent d-block">
                            <a-list-item v-for="item in custom_field_sections" v-ripple="false" :key="item.id" class="py-1" active-class="grey lighten-2" :to="{name: 'settings-custom-fields-list', params: { id: item.type }}">
                                <a-list-item-icon class="mr-1">
                                    <a-icon size="16" color="grey lighten-1">{{ item.icon }}</a-icon>
                                </a-list-item-icon>
                                <a-list-item-content>
                                    <div class="u-flex align-center">
                                        <span class="md-subtitle-2 text-capitalize grey--text text--darken-4 font-weight-medium">{{ item.label }}</span>
                                    </div>
                                </a-list-item-content>
                            </a-list-item>
                        </a-list>
                    </div>
                </a-flex>
                <a-flex xs9 xl10>
                    <router-view></router-view>
                </a-flex>
            </a-layout>
        </a-container>
    </a-main>
</template>

<script>
export default {
    data () {
        return {
            custom_field_sections: [
                { id: 1, label: 'Projects', type: 'project', icon: 'work' },
                { id: 2, label: 'Tickets', type: 'ticket', icon: 'account_tree' },
            ]
        }
    },
}
</script>

<style>

</style>

<template>
    <div>
        <InternalNotesView @unsaved="unsaved = true" @saved="unsaved = false" v-if="local_user_is_external" />
        <ExternalNotesView @unsaved="unsaved = true" @saved="unsaved = false" v-else />

        <!--Leaving page without save alert -->
        <a-dialog v-model="dialog_leave_page_alert" persistent max-width="400">
            <SCancelChanges
                @success="local_leave_page()"
                @close="dialog_leave_page_alert = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import InternalNotesView from '@/pages/Projects/ProjectsNotesView.vue'
import ExternalNotesView from '@/pages/External/ExternalProjects/ExternalProjectsNotesView.vue'
import SCancelChanges from '/src/components/Shared/SharedCancelChanges'

export default {
    components: {
        ExternalNotesView,
        InternalNotesView,
        SCancelChanges
    },

    beforeRouteLeave (toPage, fromPage, nextRoute) {
        if (!this.unsaved) return nextRoute()
        nextRoute(false)
        this.dialog_leave_page_alert = true
        this.to_page_route = nextRoute
    },

    data () {
        return {
            unsaved: false,
            dialog_leave_page_alert: false,
            to_page_route: null
        }
    },

    computed: {
        local_user_is_external() {
            return this.user_self && this.user_self.scope === 'internal'
        },

        ...mapState('User', {
            user_self: 'self'
        })
    },
    methods: {
        local_leave_page () {
            this.unsaved = false
            this.to_page_route()
        }
    }
}
</script>

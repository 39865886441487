import Vue from 'vue'
const api_endpoint = '/inventory-bookings';

export default {
    namespaced: true,
    state: {
        list: [],
        batch: [],
        item: {},
        meta: {},
        links: {},
        query: {
            filter: {},
            sort: 'order',
            include: [],
            fields: {},
            append: [],
        },
        query_filters: {
            page: 1,
            count: 10,
        },
        filters: {
            'filter[status]': 'ALL',
            // 'filter[requester_id]': 'ALL',
            // 'filter[assignee_id]': 'ALL',
            // 'filter[owner_id]': 'ALL',
            'filter[project_id]': 'ALL',
            'filter[inventory_id]': 'ALL',
            // 'filter[sort_order]': 'asc',
            // 'filter[sort]': 'booking_date',
            // 'filter[booking_from]': null,
            // 'filter[booking_to]': null
        },
        response: {},
        defaults: {
            list: [],
            item: {},
            batch: null,
            meta: {},
            query: {
                filter: {},
                sort: 'order',
                include: [],
                fields: {},
                append: [],
            },
            filters: {
                'filter[status]': 'ALL',
                // 'filter[requester_id]': 'ALL',
                // 'filter[assignee_id]': 'ALL',
                // 'filter[owner_id]': 'ALL',
                'filter[project_id]': 'ALL',
                'filter[inventory_id]': 'ALL',
                // 'filter[sort_order]': 'asc',
                // 'filter[sort]': 'booking_date',
                // 'filter[booking_from]': null,
                // 'filter[booking_to]': null
            },
            query_filters: {
                page: 1,
                count: 10,
            },
            response: {},
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_BOOKING_LIST(state, items) {
            state.list = items
        },
        UPDATE_BATCH(state, item) {
            state.batch = item
        },
        UPDATE_LIST_ITEM(state, payload) {
            const index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload.item;
        },
        ADD_EXTENDED_ITEMS(state, payload) {
            payload.inventory_ids.forEach(id => {
                // const index = state.batch.items.findIndex(booking => booking.id === id)
                const index = state.batch.findIndex(booking => booking.id === id)
                if (index !== -1) {
                    // state.batch.items[index].booking_to = payload.date
                    state.batch[index].actual_booking_to = payload.date
                }
            })
        },
        UPDATE_RETURNED_BOOKINGS(state, payload) {
            payload.inventory_ids.forEach(id => {
                const index = state.batch.items.findIndex(item => item.id === id)
                state.batch.items[index].status = 'Returned';
            })
        },
        UPDATE_STATUS_OF_BOOKINGS(state, payload) {
            payload.inventory_ids.forEach(id => {
                const index = state.batch.findIndex(data => data.id === id)
                if (index !== -1) {
                    state.batch[index].status = payload.status
                    state.batch[index].actual_booking_to = payload.today
                }
            })
        },
        UPDATE_EXTEND_CANCEL(state, payload) {
            const index = state.batch.extension.items.findIndex(item => item.id === payload.id)
            state.batch.extension.items[index].status = 'Cancelled'
        },
        ORDER_EXTENDED_BOOKING(state, payload) {
            const index = state.list.findIndex(item => item.id === payload.data.id)
            state.list[index].extension = payload.data.extension
        },
        UPDATE_INVENTORY_STAGE_TAG(state, payload) {
            const index = _.findIndex(state.list, { key: payload.index });
            const inventoryIndex = state.list[index].value.findIndex(item => item.inventory.id === payload.inventory_id)
            state.list[index].value[inventoryIndex].inventory.custom_status = payload.tag
        },
        CLEAR_INVENTORY_STAGE_TAG(state, payload) {
            const index = _.findIndex(state.list, { key: payload.index });
            const inventoryIndex = state.list[index].value.findIndex(item => item.inventory.id === payload.inventory_id)
            state.list[index].value[inventoryIndex].inventory.custom_status = null
        },
        UPDATE_QUERIES(state, query) {
            state.query = query;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        IMPORT_LIST(state, items) {
            state.import_list = _.union(state.list, items);
        },
        IMPORT_UPDATE_LIST(state, items) {
            state.import_list = items;
        },
        PREPEND_ITEM(state, item) {
            state.list.unshift(item);
        },
        APPEND_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        UPDATE_LINKS(state, links) {
            state.links = links;
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        UPDATE_FILTERS(state, item) {
            state.filters = item;
        },
        CLEAR_FILTERS(state) {
            Vue.set(state, 'filters', state.defaults.filters);
        },
        CLEAR_RESPONSE(state) {
            state.response = {};
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint, { params: { ...payload } })
                .then((response) => {
                    const arr = Object.keys(response.data).map(key => ({ key, value: response.data[key] }));
                    context.commit('UPDATE_BOOKING_LIST', arr);
                    // if (context.state.query_filters.page == 1) {
                    //     context.commit('UPDATE_LIST', response.data.data);
                    // } else {
                    //     context.commit('APPEND_LIST', response.data.data);
                    // }
                    // context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        batch(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/batch/' + payload.id, { params: { ...payload.params } })
                .then((response) => {
                    // console.log(response.data.data)
                    // context.commit('UPDATE_BOOKING_LIST', response.data.data);
                    context.commit('UPDATE_BATCH', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        extend(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/extend', payload)
                .then((response) => {
                    // context.commit('UPDATE_BOOKING_LIST', response.data.data);
                    // context.commit('ADD_EXTENDED_ITEMS', { inventory_ids: payload.inventory_bookings, data: response.data.data });
                    context.commit('ADD_EXTENDED_ITEMS', { inventory_ids: payload.inventory_bookings, date: payload.date });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        extend_patch(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/extend', payload)
                .then((response) => {
                    // context.commit('UPDATE_BOOKING_LIST', response.data.data);
                    context.commit('UPDATE_EXTENDED_ITEM', payload);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, payload.data)
                .then((response) => {
                    // (response.data.data.order > 0)
                    //     ? context.commit('APPEND_ITEM', response.data.data)
                    //     : context.commit('PREPEND_ITEM', response.data.data)
                    // context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: 'Booked Successfully!' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.data.id, payload.data)
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update_stage_tag(context, payload) {
            context.commit('UPDATE_INVENTORY_STAGE_TAG', { tag: payload.tag, inventory_id: payload.inventory_id, index: payload.index })
        },
        clear_stage_tag(context, payload) {
            context.commit('CLEAR_INVENTORY_STAGE_TAG', { inventory_id: payload.inventory_id, index: payload.index })
        },
        async booking_return(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.data.id + '/return')
                .then((response) => {
                    if (payload.data.from_extend) {
                        context.commit('UPDATE_RETURNED_BOOKINGS', { id: payload.data.id, data: response.data.data, inventory_id: payload.data.inventory_ids, from_extend: payload.data.from_extend })
                    } else {
                        context.commit('UPDATE_ITEM', response.data.data)
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        async booking_bulk_return(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/return', { inventory_bookings: payload.inventory_ids })
                .then((response) => {
                    context.commit('UPDATE_STATUS_OF_BOOKINGS', { inventory_ids: payload.inventory_ids, status: 'Returned', today: payload.today })
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        async booking_bulk_cancel(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/cancel', { inventory_bookings: payload.inventory_ids })
                .then((response) => {
                    context.commit('UPDATE_STATUS_OF_BOOKINGS', { inventory_ids: payload.inventory_ids, status: 'Cancelled', today: payload.today })
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        async booking_cancel(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.data.id + '/cancel', payload.data)
                .then((response) => {
                    if (payload.data.from_extend) {
                        context.commit('UPDATE_EXTEND_CANCEL', { id: payload.data.id, data: response.data.data, from_extend: payload.data.from_extend })
                    } else {
                        context.commit('UPDATE_ITEM', response.data.data)
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        async order_extension(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(`${api_endpoint}/batch/${payload.batch_id}/${payload.inventory_id}`)
                .then((response) => {
                    context.commit('ORDER_EXTENDED_BOOKING', { data: response.data.data })
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        import(context, payload) {
            this.dispatch('Interface/loader_start')
            let form_data = new FormData()
            form_data.append('csv_file', payload.csv_file)
            return axios.post(api_endpoint + '/import', form_data, {
                    headers: {
                        'accept': 'application/json',
                        'Accept-Language': 'en-US',
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    let is_valid = response.data.data.filter(list => list.is_valid === 0)
                    if(is_valid.length === 0) {
                        context.commit('APPEND_LIST', response.data.data);
                    }else{
                        context.commit('IMPORT_LIST', response.data.data);
                    }
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        disabled_uploaded(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint +'/disabled_uploaded')
                .then((response) => {
                    context.commit('APPEND_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        import_index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint +'/import_index')
                .then((response) => {
                    context.commit('IMPORT_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        enable_uploaded(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint +'/enable_uploaded')
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear_uploaded(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/clear_uploaded')
                .then((response) => {
                    context.commit('UPDATE_LIST', []);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear_imported(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/clear_imported')
                .then((response) => {
                    context.commit('IMPORT_UPDATE_LIST', []);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        expand(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + context.state.item.id + '/expand', context.state.item)
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        collapse(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + context.state.item.id + '/collapse', context.state.item)
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        expand_all(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/expand_all', payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        collapse_all(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/collapse_all', payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        async update_filters(context, payload) {
            await context.commit('UPDATE_FILTERS', payload.filters)
        },
        clear_filters(context) {
            context.commit('CLEAR_FILTERS')
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        export(context, payload) {
            this.dispatch('Interface/loader_start')
            let query_filters = _.pickBy(context.state.filters, function (item) {
                return item != '';
            });
            return axios.get(api_endpoint+'/export', { params: query_filters })
                .then((response) => {
                    context.commit('UPDATE_LINKS', response.data.links);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_QUERIES', _.cloneDeep(context.state.defaults.query));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_BATCH', _.cloneDeep(context.state.defaults.batch));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },
    getters: {
        list: (state) => {
            const data = [...state.list]
            return data
        },
        batch: (state) => {
            // const data = state.batch ? [ ...state.batch ] : null
            return state.batch
        },
        item: (state) => {
            return { ...state.item }
        },
        meta: (state) => {
            return { ...state.meta }
        },
        links: (state) => {
            return { ...state.links }
        },
        query: (state) => {
            return { ...state.query }
        },
        query_filters: (state) => {
            return { ...state.query_filters }
        },
        filters: (state) => {
            const data = { ...state.filters }
            return data
        },
        response: (state) => {
            return { ...state.response }
        },
        defaults: (state) => {
            const data = { ...state.defaults }
            return data
        },
        form_mode: (state) => state.form_mode,
    }
}

<template>
    <div class="c-project-card__progress caption grey--text text--darken-3" :class="{ 'd-flex': flex, 'u-wfull': full }">
        <template v-if="flex">
            <div :class="{ 'u-wfull': flex }" class="md-caption u-flex-center-y">
                <a-icon size="14" class="mr-1" outlined>{{ task ? 'check_circle_outline' : 'check_box' }}</a-icon>{{ closed || 0 }}/{{ total || 0 }}
                <template v-if="isOld">{{ task ? 'Tasks' : 'Scope' }}</template>
            </div>
        </template>
        <template v-if="full">
            <div class="md-caption u-wfull u-flex-center-y">
                <a-icon size="14" class="mr-1" outlined>{{ task ? 'check_circle_outline' : 'check_box' }}</a-icon>{{ closed || 0 }}/{{ total || 0 }}
                <template v-if="isOld">{{ task ? 'Tasks' : 'Scope' }}</template>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        total: {
            type: Number,
        },
        closed: {
            type: Number,
        },
        isOld: {
            type: Boolean,
            default: false
        },
        flex: {
            type: Boolean,
            default: false
        },
        full: {
            type: Boolean,
            default: false
        },
        task: {
            type: Boolean,
            default: false,
        },
        animate: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

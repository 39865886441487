<template>
    <div class="c-task-edit-view white u-relative">
        <!-- Menu -->
        <div class="u-flex px-8 pt-8 pb-2 white" style="width: 70%; border-right: 1px solid #e0e0e0 !important">
            <template v-if="!hideNavigation">
                <div class="u-flex" style="column-gap: 8px;">
                    <a-sheet v-if="previousTaskId === null" width="32" height="32" class="u-border u-rounded-corners u-flex-center">
                        <a-btn :ripple="false" small depressed min-width="30" height="30" color="grey lighten-4" class="pa-0 ma-0 u-rounded-corners">
                            <a-icon size="24" color="grey darken-2">expand_less</a-icon>
                        </a-btn>
                    </a-sheet>
                    <a-tooltip v-else left content-class="pa-2 c-tooltip-pointer c-tooltip-pointer--top" :disabled="localAttachmentsUpdating">
                        <template v-slot:activator="{ on }">
                            <a-sheet v-on="on" @click="!localAttachmentsUpdating ? $emit('navigate', previousTaskId) : {}" width="32" height="32" class="u-border u-rounded-corners u-flex-center u-shadow-light">
                                <a-btn small depressed min-width="30" height="30" color="white" class="pa-0 ma-0 u-rounded-corners">
                                    <a-icon size="24" color="grey darken-2">expand_less</a-icon>
                                </a-btn>
                            </a-sheet>
                        </template>
                        <div class="u-flex-center-y" style="column-gap: 8px;">
                            <span class="white--text font-weight-medium md-caption">Previous</span>
                            <PartialShortcutKey>J</PartialShortcutKey>
                        </div>
                    </a-tooltip>

                    <a-sheet v-if="nextTaskId === null" width="32" height="32" class="u-border u-rounded-corners u-flex-center">
                        <a-btn :ripple="false" small depressed min-width="30" height="30" color="grey lighten-4" class="pa-0 ma-0 u-rounded-corners">
                            <a-icon size="24" color="grey darken-2">expand_more</a-icon>
                        </a-btn>
                    </a-sheet>
                    <a-tooltip v-else right content-class="pa-2 c-tooltip-pointer c-tooltip-pointer--left" :disabled="localAttachmentsUpdating">
                        <template v-slot:activator="{ on }">
                            <a-sheet v-on="on" @click="!localAttachmentsUpdating ? $emit('navigate', nextTaskId) : {}" width="32" height="32" class="u-border u-rounded-corners u-flex-center u-shadow-light">
                                <a-btn small depressed min-width="30" height="30" color="white" class="pa-0 ma-0 u-rounded-corners">
                                    <a-icon size="24" color="grey darken-2">expand_more</a-icon>
                                </a-btn>
                            </a-sheet>
                        </template>
                        <div class="u-flex-center-y" style="column-gap: 8px;">
                            <span class="white--text font-weight-medium md-caption">Next</span>
                            <PartialShortcutKey>K</PartialShortcutKey>
                        </div>
                    </a-tooltip>
                </div>
                <a-spacer></a-spacer>
            </template>
            <a-menu v-if="canDelete" :disabled="!canDelete || localAttachmentsUpdating" offset-y :close-on-content-click="false" min-width="200">
                <template v-slot:activator="{ on }">
                    <a-sheet v-on="on" width="32" height="32" class="u-border u-rounded-corners u-flex-center u-shadow-light">
                        <a-btn small depressed min-width="30" height="30" color="white" class="pa-0 ma-0 u-rounded-corners">
                            <a-icon size="24" color="grey darken-2">more_horiz</a-icon>
                        </a-btn>
                    </a-sheet>
                </template>
                <a-list class="u-list-std">
                    <a-list-item @click="confirmDeleteTask ? localDeleteTask() : localGetDeleteConfirmation()" :class="[{ 'c-delete-confirm': confirmDeleteTask }]">
                        <a-list-item-icon>
                            <a-icon class="mr-3" size="20" color="grey darken-2">delete</a-icon>
                        </a-list-item-icon>
                        <a-list-item-content>{{ confirmDeleteTask ? 'Confirm Delete?' : 'Delete Record' }}</a-list-item-content>
                    </a-list-item>
                </a-list>
            </a-menu>
        </div>

        <!-- Close popup button -->
        <a-sheet width="32" height="32" style="top: 32px; right: 32px;" class="u-absolute u-border u-rounded-corners u-flex-center u-shadow-light">
            <a-btn
                min-width="30"
                height="30"
                color="white"
                class="pa-0 ma-0 u-rounded-corners"
                style="z-index: 10"
                :disabled="localAttachmentsUpdating"
                @click="localExit()"
                small depressed
            >
                <a-icon size="20" color="grey darken-2">close</a-icon>
            </a-btn>
        </a-sheet>

        <div
            class="u-flex u-relative"
            @dragenter="canUpdate && !localAttachmentsUpdating ? localHandleDragEnter() : ''"
            @drop="localHandleDrop"
        >
            <SDragOverlay
                v-if="filesDraggedOver"
                :pageType="(isExternal ? 'external':'internal') + '--dialog'"
                @leave="localHandleLeave()"
                @enter="hoveredOnInfo=false"
                @enterInfo="hoveredOnInfo=true"
            >
            </SDragOverlay>
            <!-- 70% section -->
            <div class="c-task-edit-view__content" style="border-right: 1px solid #e0e0e0 !important;">
                <!-- Title -->
                <a-sheet
                    min-height="48"
                    style="cursor: text;"
                    tabindex="0"
                    @click="canUpdate ? localFocusTextarea('refTitleTextarea') : ''"
                    class="c-task-edit-view__textarea c-tiny-scroll u-relative pa-0 u-wfull white u-rounded-corners-lg u-overflow-y"
                    :class="[
                        titleLength - currentTitleLength <= 15 ? 'c-task-edit-view__textarea-error' : (canUpdate ? 'c-task-edit-view__textarea-border' : '')
                    ]"
                >
                    <a-textarea
                        v-model="taskTitle"
                        rows="1"
                        ref="refTitleTextarea"
                        placeholder="Add title..."
                        class="md-heading-5 py-3 px-8"
                        :maxlength="titleLength"
                        :disabled="!canUpdate"
                        @input="evt => canUpdate ? localTriggerTitleUpdate(evt) : {}"
                        @blur="canUpdate ? localBlurUpdateTitle(taskTitle) : {}"
                        @paste="evt => canUpdate ? localPasteTitle(evt) : {}"
                        solo flat auto-grow no-resize hide-details
                    />

                    <a-tooltip bottom v-if="titleLength - currentTitleLength <= 15">
                        <template v-slot:activator="{ on }">
                            <span
                                v-on="on"
                                class="c-task-edit-view__textarea--error-chip u-absolute px-2 py-1 text-center font-weight-bold pink lighten-4 pink--text text--darken-3 md-caption"
                                style="top: 8px; right: 18px; border-radius: 0px 0px 0px 16px; min-width: 40px;"
                            >
                                {{ titleLength - currentTitleLength }}
                            </span>
                        </template>
                        <span>Max character limit reached</span>
                    </a-tooltip>
                </a-sheet>

                <!-- Description -->
                <div class="mt-4">
                    <h3 class="md-caption font-weight-medium text-uppercase grey--text text--darken-1 px-8">Description</h3>
                    <!-- v-if="!localIsLoading('index')" -->
                    <div class="c-task-edit-view__edit-desc">
                        <g-rich-text-editor
                            v-model="description"
                            placeholder="Add description..."
                            @update="localUpdateDesc"
                            :canUpdate="canUpdate"
                            :hideFocus="localAttachmentsUpdating"
                            :readOnly="localAttachmentsUpdating"
                            :refresh="refreshDesc"
                            @isFocused="!localAttachmentsUpdating ? evt => isEditorFocused = evt : ''"
                            class="mx-4"
                        ></g-rich-text-editor>
                    </div>
                </div>

                <!-- Attachment section -->
                <div class="mt-4 mx-8">
                    <SAttachmentSection
                        :list="localGetAttachmentData('local_list')"
                        :dropTransferList="localDroppedFilesList"
                        :sourceId="item.id"
                        :updateLoading="localAttachmentsUpdating"
                        :loading="mixinIsLoading('attachment')"
                        :canUpdate="canUpdate"
                        sourceType="Task"
                        @dropTransfer="localUpdateAttachmentTransfer"
                        @loadMore="localLoadMoreAttachments()"
                        @remove="localRemoveAttachmentItem"
                        @delete="localDeleteAttachmentItem"
                        :isExternal="isExternal"
                        flat autoUpdate
                    >
                    </SAttachmentSection>
                </div>

                <!-- Comment box input -->
                <h3 class="c-task-edit-view__comment-title md-caption font-weight-medium text-uppercase px-8 pt-5 grey--text text--darken-1">
                    Comment
                </h3>

                <template v-if="canComment">
                    <!-- Comment create input box -->
                    <div class="c-task-edit-view__comment-parent py-4 px-8 u-sticky white">
                        <div
                            tabindex="0"
                            class="u-rounded-corners-lg u-relative"
                            :class="[
                                (commentLength - modelCommentCreate.length) > 50 ?
                                    (checkIsPublic && !isExternal ? 'c-task-edit-view__comment-public' : 'u-border') :
                                    'c-task-edit-view__comment-error',
                            ]"
                            @click.stop="localFocusCommentTextarea('refCommentTextarea')"
                            @blur="localRemoveFocus('refCommentTextarea', $event)"
                        >
                            <a-sheet
                                min-height="48"
                                style="cursor: text;"
                                class="c-task-edit-view__textarea c-task-edit-view__textarea--light c-task-edit-view__textarea--no-focus c-tiny-scroll grey lighten-5 u-flex-center-y u-relative pa-0 px-3 u-wfull u-rounded-corners-lg u-overflow-y"
                                :style="[!commentCreateFocused ? { overflow: 'hidden !important', maxHeight: '48px !important' } : '']"
                            >
                                <a-textarea
                                    v-model="modelCommentCreate"
                                    :maxlength="commentLength"
                                    @past="localPasteComment"
                                    @blur="localRemoveFocus('refCommentTextarea', $event)"
                                    @keydown.shift.enter.prevent="localCreateComment(modelCommentCreate)"
                                    rows="1"
                                    ref="refCommentTextarea"
                                    placeholder="Add Comment..."
                                    class="md-body-1 c-tiny-scroll"
                                    background-color="transparent"
                                    solo flat auto-grow no-resize hide-details
                                />
                            </a-sheet>

                            <div v-if="commentCreateFocused" class="u-flex-center-y grey lighten-5 u-rounded-corners-lg py-1 pb-3 px-3">
                                <g-toggle
                                    v-if="!isExternal"
                                    v-model="checkIsPublic"
                                    :color="checkIsPublic ? 'orange darken-2' : 'grey darken-4'"
                                    class="d-inline-flex align-center"
                                    custom-bg dense-xl
                                >
                                    <span :class="[checkIsPublic ? 'orange--text text--darken-2' : 'grey--text text--lighten-1', 'font-weight-medium md-caption text-uppercase ml-2']">{{ checkIsPublic ? 'Public' : 'Private' }}</span>
                                </g-toggle>
                                <a-spacer></a-spacer>
                                <div class="u-flex-center-y" style="column-gap: 6px;">
                                    <span class="md-caption grey--text font-weight-medium">Press <strong>Shift + Enter</strong> to</span>
                                    <a-btn @click="localCreateComment(modelCommentCreate)" depressed height="28" class="indigo darken-3 ma-0 py-1 px-3 u-rounded-corners">
                                        <span class="white--text md-caption font-weight-medium text-uppercase">Comment</span>
                                    </a-btn>
                                </div>
                            </div>

                            <a-tooltip bottom v-if="commentLength - modelCommentCreate.length <= 50">
                                <template v-slot:activator="{ on }">
                                    <span
                                        v-on="on"
                                        class="c-task-edit-view__textarea--error-chip u-absolute px-2 py-1 text-center font-weight-bold pink lighten-4 pink--text text--darken-3 md-caption"
                                        style="top: 0px; right: 0px; border-radius: 0px 4px 0px 16px; min-width: 40px;"
                                    >
                                        {{ commentLength - modelCommentCreate.length }}
                                    </span>
                                </template>
                                <span>Max character limit reached</span>
                            </a-tooltip>
                        </div>
                    </div>

                    <!-- Comment create input border bottom/top -->
                    <a-sheet
                        height="1"
                        class="c-task-edit-view__comments-list-parent grey lighten-3 u-sticky mx-auto"
                        style="width: calc(100% - 64px); box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.08)"
                        :style="[commentCreateFocused ? { top: '118px', bottom: '118px' } : { top: '82px', bottom: '82px' }]"
                    />
                </template>

                <template v-if="localIsLoading('comments')">
                    <h4 class="md-body-2 grey--text px-8 mt-4">Loading comments...</h4>
                </template>
                <template v-else>
                    <!-- Comments list -->
                    <div v-if="localGetCommentList && localGetCommentList.length" class="px-8 pb-8">
                        <div class="mt-5 c-task-edit-view__comments-list">
                            <template v-for="(comment, index) in localGetCommentList">
                                <div
                                    :key="comment.id"
                                    :id="'comment-' + comment.id"
                                    :class="[{ 'mt-5': index !== 0 }]"
                                    class="c-task-edit-view__comment-item u-flex u-relative"
                                    style="column-gap: 0.75rem"
                                >
                                    <g-avatar
                                        :item="comment.created_by ? comment.created_by : user_self"
                                        :size="36"
                                        :rightSpace="false"
                                    />
                                    <div class="u-wfull">
                                        <div class="u-flex-center-y" style="column-gap: 0.5rem;">
                                            <h3 class="md-body-2 font-weight-medium grey--text text--darken-4">{{ localCommentGet('created_by.name', comment) }}</h3>
                                            <a-tooltip right content-class="c-tooltip-pointer c-tooltip-pointer--left">
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on" class="grey--text md-caption font-weight-medium d-inline-flex align-items-center" style="column-gap: 6px;">
                                                        <span>{{ localFormatDate(localCommentGet(comment.created_at !== comment.updated_at ? 'updated_at' : 'created_at', comment), 'simple') }}</span>
                                                        <template v-if="!localDateIsDiff(comment)">
                                                            <a-icon size="6" color="grey lighten-1">fiber_manual_record</a-icon>
                                                            <span class="grey--text md-caption font-weight-medium">
                                                                Edited
                                                            </span>
                                                        </template>
                                                    </span>
                                                </template>
                                                <span>{{ localFormatDate(localCommentGet(comment.created_at !== comment.updated_at ? 'updated_at' : 'created_at', comment), 'full') }}</span>
                                            </a-tooltip>
                                            <a-icon size="16" color="amber darken-3" v-if="localCommentGet('visibility', comment) === 'external' && !isExternal">public</a-icon>
                                        </div>
                                        <p v-if="currentCommentEdit !== comment.id" class="mb-0 mt-1 md-body-2 grey--text text--darken-1 font-weight-medium" style="white-space: pre-line">
                                            {{ localCommentGet('content', comment) }}
                                        </p>
                                        <template v-else>
                                            <div
                                                tabindex="0"
                                                class="u-rounded-corners-lg mt-2 u-relative"
                                                :class="[
                                                    (commentLength - comment.content.length) > 50 ?
                                                        (commentPublicModels[comment.id] && !isExternal ? 'c-task-edit-view__comment-public' : 'u-border') :
                                                        'c-task-edit-view__comment-error',
                                                ]"
                                                @click.stop="localFocusEditComment(`refCommentTextarea-${comment.id}`)"
                                            >
                                                <a-sheet
                                                    min-height="48"
                                                    style="cursor: text;"
                                                    class="c-task-edit-view__textarea c-task-edit-view__textarea--light c-task-edit-view__textarea--no-focus c-tiny-scroll grey lighten-5 u-flex-center-y u-relative pa-0 px-3 u-wfull u-rounded-corners-lg u-overflow-y"
                                                >
                                                    <a-textarea
                                                        v-model="commentContentModels[comment.id].value"
                                                        :ref="`refCommentTextarea-${comment.id}`"
                                                        :maxlength="commentLength"
                                                        @paste="localPasteComment($event, comment.id)"
                                                        @keydown.shift.enter.prevent="localUpdateComment(comment)"
                                                        rows="1"
                                                        placeholder="Add Comment..."
                                                        class="md-body-1"
                                                        background-color="transparent"
                                                        solo flat auto-grow no-resize hide-details
                                                    />
                                                </a-sheet>

                                                <div class="u-flex-center-y grey lighten-5 u-rounded-corners-lg py-1 pl-3 pr-1">
                                                    <g-toggle
                                                        v-if="!isExternal"
                                                        v-model="commentPublicModels[comment.id]"
                                                        :color="commentPublicModels[comment.id] ? 'orange darken-2' : 'grey darken-4'"
                                                        @change="localChangeVisibility($event, comment)"
                                                        class="d-inline-flex align-center"
                                                        custom-bg dense-xl
                                                    >
                                                        <span :class="[commentPublicModels[comment.id] ? 'orange--text text--darken-2' : 'grey--text text--lighten-1', 'font-weight-medium md-caption text-uppercase ml-2']">{{ commentPublicModels[comment.id] ? 'Public' : 'Private' }}</span>
                                                    </g-toggle>
                                                    <a-spacer></a-spacer>
                                                    <div class="u-flex-center-y" style="column-gap: 4px;">
                                                        <a-btn @click="localUpdateComment(comment)" depressed height="28" class="indigo darken-3 ma-0 py-1 px-3 u-rounded-corners">
                                                            <span class="white--text md-caption font-weight-medium text-uppercase">Update</span>
                                                        </a-btn>
                                                        <a-btn @click="localCancelCommentEdit(comment.id)" depressed height="28" class="grey lighten-3 ma-0 py-1 px-3 u-rounded-corners">
                                                            <span class="grey--text text--darken-3 md-caption font-weight-medium text-uppercase">Cancel</span>
                                                        </a-btn>
                                                    </div>
                                                </div>

                                                <a-tooltip bottom v-if="commentLength - comment.content.length <= 50">
                                                    <template v-slot:activator="{ on }">
                                                        <span
                                                            v-on="on"
                                                            class="c-task-edit-view__textarea--error-chip u-absolute px-2 py-1 text-center font-weight-bold pink lighten-4 pink--text text--darken-3 md-caption"
                                                            style="top: 0px; right: 0px; border-radius: 0px 4px 0px 16px; min-width: 40px;"
                                                        >
                                                            {{ commentLength - comment.content .length }}
                                                        </span>
                                                    </template>
                                                    <span>Max character limit reached</span>
                                                </a-tooltip>
                                            </div>
                                        </template>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <a-menu
                                        v-if="comment.created_by_id === user_self.id && canComment"
                                        :attach="'#comment-' + comment.id"
                                        :close-on-content-click="commentDeleteId !== comment.id"
                                        :disabled="!canComment"
                                        min-width="150"
                                        content-class="u-z12 u-rounded-corners-lg"
                                        left bottom offset-y
                                    >
                                        <template v-slot:activator="{ on }">
                                            <a-icon v-on="on" class="c-task-edit-view__comment-item-action" size="16" color="grey darken-1">more_vert</a-icon>
                                        </template>
                                        <a-list class="u-list-condensed u-list-condensed--round-select pa-1">
                                            <a-list-item
                                                @click="localCommentOp('edit', comment)"
                                                :disabled="currentCommentEdit === comment.id"
                                                :class="[currentCommentEdit !== comment.id ? 'grey--text text--darken-2' : 'grey--text text--lighten-1']"
                                                class="md-body-2 font-weight-medium u-rounded-corners-lg"
                                            >
                                                Edit
                                            </a-list-item>
                                            <a-list-item
                                                @click="localCommentOp('delete', comment)"
                                                :class="[commentDeleteId === comment.id ? 'c-delete-confirm' : 'grey--text text--darken-2']"
                                                class="md-body-2 font-weight-medium u-rounded-corners-lg"
                                            >
                                                <a-list-item-content>{{ commentDeleteId === comment.id ? 'Confirm?' : 'Delete' }}</a-list-item-content>
                                            </a-list-item>
                                        </a-list>
                                    </a-menu>
                                </div>
                            </template>
                        </div>
                    </div>
                    <template v-else>
                        <h3 class="md-body-2 mt-4 grey--text text--lighten-1 font-weight-medium px-8 mb-4">No Comments</h3>
                    </template>
                </template>
            </div>

            <!-- 30% section -->
            <div class="c-task-edit-view__meta px-8 mt-12 u-relative">
                <!-- Assignees -->
                <a-sheet class="u-flex-center-y">
                    <h3 style="flex: 0 0 104px" class="md-body-2 blue-grey--text text--darken-1 font-weight-medium">Assignees</h3>
                    <div :class="['c-task-edit-view__meta-item u-no-select u-wfull', { 'c-task-edit-view__meta-item--active': activeDropdowns['assignee'] }]" tabindex="0">
                        <PartialSimpleAssigneeDropdown
                            :list="collaborators"
                            :item="item"
                            :projectId="localProjectId"
                            :customZIndex="false"
                            :canUpdate="canUpdate"
                            :disabled="!canUpdate"
                            @select="localUpdateAssignee($event, item)"
                            @active="evt => activeDropdowns['assignee'] = evt"
                            avatarSize="26"
                            fontSize="10"
                            min-width="300"
                            nudge-bottom="8"
                            triggerClass="c-task-edit-view__meta-item--trigger"
                            content-class="u-rounded-corners-lg"
                            placeholderType="Select Assignees"
                            closeOnClick autoMode showEmptyText
                        />
                    </div>
                </a-sheet>

                <!-- Status -->
                <a-sheet class="u-flex-center-y mt-4">
                    <h3 style="flex: 0 0 104px" class="md-body-2 blue-grey--text text--darken-1 font-weight-medium">Status</h3>
                    <div v-if="!canUpdate || (canUpdate && localProject && localProject.status !== 'active')" :class="['c-task-edit-view__meta-item u-wfull u-no-select']" tabindex="0">
                        <GNonActiveInfo
                            :project="localProject"
                            :stage="localProject && localProject.status"
                            :disabled="!canUpdate || (canUpdate && localProject && localProject.status === 'active')"
                            @stage-change="$emit('stage-change', localProjectId )"
                        >
                            <div :class="[{ 'u-cursor-pointer': canUpdate }]" class="c-task-edit-view__meta-item--trigger u-flex-center-y px-3" style="column-gap: 2px; min-height: 36px;">
                                <g-task-meter :value="item.status.percentage" :color="item.status.color" />
                                <p class="ma-0 text-truncate font-weight-medium md-body-2 grey--text text--darken-2 ml-2">{{ localGetTaskStatus }}</p>
                                <a-spacer></a-spacer>
                                <a-icon v-if="canUpdate && activeDropdowns['status_id']" size="16" color="grey darken-2">keyboard_arrow_{{ value ? 'up' : 'down' }}</a-icon>
                            </div>
                        </GNonActiveInfo>
                    </div>
                    <div v-else :class="['c-task-edit-view__meta-item u-wfull', { 'c-task-edit-view__meta-item--active': activeDropdowns['status_id'] }]" tabindex="0">
                        <PartialMenuDropdown
                            :list="taskMetaList"
                            @change="localUpdateStatus"
                            @active="evt => activeDropdowns['status_id'] = evt"
                            :disabled="!canUpdate"
                            :showTitle="false"
                            placeholder="Search statuses..."
                            itemText="value"
                            min-width="300"
                            max-width="300"
                            nudge-bottom="4"
                            conent-class="u-rounded-corners-lg"
                            offset-y bottom
                        >
                            <template #trigger-wrap="{ on, value }">
                                <div v-on="on" :class="[{ 'u-cursor-pointer': canUpdate }]" class="c-task-edit-view__meta-item--trigger u-flex-center-y px-3" style="column-gap: 2px; min-height: 36px;">
                                    <g-task-meter :value="item.status.percentage" :color="item.status.color" />
                                    <p class="ma-0 text-truncate font-weight-medium md-body-2 grey--text text--darken-2 ml-2">{{ localGetTaskStatus }}</p>
                                    <a-spacer></a-spacer>
                                    <a-icon v-if="canUpdate && activeDropdowns['status_id']" size="16" color="grey darken-2">keyboard_arrow_{{ value ? 'up' : 'down' }}</a-icon>
                                </div>
                            </template>
                            <template #list="{ select, list, props: { maxHeight } }">
                                <div class="c-tiny-scroll u-overflow-y pb-2 white" :style="[{ maxHeight: maxHeight + 'px' }]">
                                    <a-list
                                        v-if="list && !list.length"
                                        class="u-list-condensed u-list-condensed--round-select u-overflow-y c-tiny-scroll"
                                        :class="['pa-0']"
                                    >
                                        <a-list-item class="md-body-2 grey--text text--darken-2 text-left px-4 mx-1 py-0">
                                            <a-list-item-title class="text-left md-body-2">
                                                <slot name="noData">No Status Found</slot>
                                            </a-list-item-title>
                                        </a-list-item>
                                    </a-list>

                                    <template v-else>
                                        <template v-for="(groupItems, groupName) in localGroupedStatuses(list)">
                                            <template v-if="groupItems && groupItems.listLength">
                                                <a-list
                                                    :key="groupName + '-title'"
                                                    class="u-list-condensed u-list-condensed--round-select py-0 c-tiny-scroll"
                                                >
                                                    <a-list-item class="px-4 py-1">
                                                        <a-list-item-title>
                                                            <span class="md-caption font-weight-medium grey--text text--darken-1 text-uppercase" style="letter-spacing: 0.2px;">{{ groupName }}</span>
                                                        </a-list-item-title>
                                                    </a-list-item>

                                                    <a-list-item
                                                        v-for="status in groupItems.list"
                                                        :key="status.id"
                                                        :title="status['value']"
                                                        @click="select({ item: status })"
                                                        class="u-rounded-corners-lg px-4 py-1 mx-2 grey--text text--darken-2"
                                                    >
                                                        <div class="u-flex-center-y u-wfull" :class="[{ 'u-opacity-50': item.status_id === status.id }]">
                                                            <a-icon size="20" :color="$color(localGetStatus('color', status.id), 'color_bg')">fiber_manual_record</a-icon>
                                                            <p :class="['ma-0 ml-3 text-truncate md-subtitle-1 grey--text text--darken-2']">{{ localGetStatus('value', status.id) }}</p>
                                                            <a-spacer></a-spacer>
                                                            <span :class="['md-subtitle-2 grey--text text--darken-2']">{{ localGetStatus('percentage', status.id) }}%</span>
                                                        </div>
                                                    </a-list-item>
                                                </a-list>
                                            </template>
                                        </template>
                                    </template>
                                </div>
                            </template>
                        </PartialMenuDropdown>
                    </div>
                </a-sheet>

                <!-- Start date -->
                <a-sheet class="u-flex-center-y mt-4">
                    <h3 style="flex: 0 0 104px" class="md-body-2 blue-grey--text text--darken-1 font-weight-medium">Start Date</h3>
                    <div :class="['c-task-edit-view__meta-item u-no-select u-wfull', { 'c-task-edit-view__meta-item--active': activeDropdowns['start_date'] }]" tabindex="0">
                        <div v-if="!canUpdate" class="c-task-edit-view__meta-item--trigger u-wfull u-flex-center-y px-3" style="column-gap: 8px; min-height: 36px;">
                            <a-icon size="20" color="grey darken-1">today</a-icon>
                            <span class="md-body-2 font-weight-medium u-wfull" :class="[item.start_date ? 'grey--text text--darken-2' : 'grey--text']">
                                {{ item.start_date ? localFormatDate(item.start_date, 'simple') : 'No Start Date' }}
                            </span>
                        </div>
                        <vc-date-picker
                            v-else
                            v-model="dateModels.start_date"
                            :max-date="item.due_date"
                            :model-config="dateModelConfig"
                            class="d-block u-wfull"
                            @input="canUpdate ? localUpdateDate(dateModels.start_date, 'start_date') : {}"
                        >
                            <template v-slot="{ togglePopover }">
                                <div class="c-task-edit-view__meta-item--trigger u-wfull u-flex-center-y px-3 u-cursor-pointer" style="column-gap: 8px; min-height: 36px;" @click.stop="() => togglePopover({ placement: 'bottom-start' })">
                                    <a-icon size="20" color="grey darken-1">today</a-icon>
                                    <span class="md-body-2 font-weight-medium u-wfull" :class="[item.start_date ? 'grey--text text--darken-2' : 'grey--text']">
                                        {{ item.start_date ? localFormatDate(item.start_date, 'simple') : 'No Start Date' }}
                                    </span>
                                </div>
                            </template>
                        </vc-date-picker>
                    </div>
                </a-sheet>

                <!-- Due date -->
                <a-sheet class="u-flex-center-y mt-4">
                    <h3 style="flex: 0 0 104px" class="md-body-2 blue-grey--text text--darken-1 font-weight-medium">Due Date</h3>
                    <div :class="['c-task-edit-view__meta-item u-no-select u-wfull', { 'c-task-edit-view__meta-item--active': activeDropdowns['due_date'] }]" tabindex="0">
                        <div v-if="!canUpdate" class="c-task-edit-view__meta-item--trigger u-wfull u-flex-center-y px-3" style="column-gap: 8px; min-height: 36px;">
                            <a-icon size="20" :color="localDateIsBefore(item.due_date, item.status) ? 'red darken-2' : 'grey darken-1'">event</a-icon>
                            <span class="md-body-2 font-weight-medium u-wfull" :class="[item.due_date ? (localDateIsBefore(item.due_date, item.status) ? 'red--text text--darken-2' : 'grey--text text--darken-2') : 'grey--text']">
                                {{ item.due_date ? localFormatDate(item.due_date, 'simple') : 'No Due Date' }}
                            </span>
                        </div>
                        <vc-date-picker
                            v-else
                            v-model="dateModels.due_date"
                            :min-date="item.start_date"
                            :model-config="dateModelConfig"
                            class="d-block u-wfull"
                            @input="canUpdate ? localUpdateDate(dateModels.due_date, 'due_date') : ''"
                        >
                            <template v-slot="{ togglePopover }">
                                <div class="c-task-edit-view__meta-item--trigger u-wfull u-flex-center-y px-3 u-cursor-pointer" style="column-gap: 8px; min-height: 36px;" @click.stop="() => togglePopover({ placement: 'bottom-start' })">
                                    <a-icon size="20" :color="localDateIsBefore(item.due_date, item.status) ? 'red darken-2' : 'grey darken-1'">event</a-icon>
                                    <span class="md-body-2 font-weight-medium u-wfull" :class="[item.due_date ? (localDateIsBefore(item.due_date, item.status) ? 'red--text text--darken-2' : 'grey--text text--darken-2') : 'grey--text']">
                                        {{ item.due_date ? localFormatDate(item.due_date, 'simple') : 'No Due Date' }}
                                    </span>
                                </div>
                            </template>
                        </vc-date-picker>
                    </div>
                </a-sheet>

                <!-- Visibility -->
                <a-sheet class="u-flex-center-y mt-4" v-if="!isExternal">
                    <h3 style="flex: 0 0 104px" class="md-body-2 blue-grey--text text--darken-1 font-weight-medium">Visibility</h3>
                    <div :class="['c-task-edit-view__meta-item u-wfull u-no-select', { 'c-task-edit-view__meta-item--active': activeDropdowns['visibility'] }]" tabindex="0">
                        <a-menu :disabled="!canUpdate" offset-y min-width="308" width="308" nudge-bottom="4">
                            <template v-slot:activator="{ on }">
                                <PartialVisibilityDisplay
                                    v-on="on"
                                    :visibility="item.visibility"
                                    :class="[{ 'u-cursor-pointer': canUpdate }]"
                                    class="c-task-edit-view__meta-item--trigger u-hfull px-3"
                                    style="min-height: 36px;"
                                />
                            </template>
                            <PartialVisibilityDropdown class="white">
                                <PartialVisibilityToggleSection @change="localUpdateVisibility" :item="item" />
                            </PartialVisibilityDropdown>
                        </a-menu>
                    </div>
                </a-sheet>

                <!-- Priority -->
                <a-sheet class="u-flex-center-y mt-4">
                    <h3 style="flex: 0 0 104px" class="md-body-2 blue-grey--text text--darken-1 font-weight-medium">Priority</h3>
                    <div :class="['c-task-edit-view__meta-item u-wfull u-no-select', { 'c-task-edit-view__meta-item--active': activeDropdowns['priority'] }]" tabindex="0">
                        <a-menu :disabled="!canUpdate" offse-y min-width="200" nudge-bottom="36" conent-class="u-rounded-corners-lg">
                            <template v-slot:activator="{ on }">
                                <div v-on="on" :class="[{ 'u-cursor-pointer': canUpdate }]" class="c-task-edit-view__meta-item--trigger u-flex-center-y px-3 u-wfull" style="column-gap: 8px; min-height: 36px;">
                                    <a-icon v-if="localGetPriority('label') === '-' || !localGetPriority('value')" size="16" color="grey">remove</a-icon>
                                    <template v-else>
                                        <a-icon size="20" :color="localGetPriority('iconColor')">
                                            {{ localGetPriority('icon') }}
                                        </a-icon>
                                        <p :class="['ma-0 text-truncate text-uppercase font-weight-medium md-body-2', localGetPriority('textColor')]">
                                            {{ localGetPriority('label') }}
                                        </p>
                                    </template>
                                </div>
                            </template>
                            <a-list class="u-list-condensed u-list-condensed--round-select pa-2">
                                <a-list-item
                                    v-for="priority in priorityList"
                                    :key="priority.value"
                                    @click="localGetPriority('value') === priority.value ? {} : localUpdatePriority(priority)"
                                    class="u-rounded-corners-lg pl-2"
                                >
                                    <a-list-item-content class="pa-0">
                                        <div class="u-flex-center-y">
                                            <a-icon size="20" :color="priority.iconColor">
                                                {{ priority.icon }}
                                            </a-icon>
                                            <p :class="['ma-0 ml-3 text-truncate text-uppercase font-weight-medium md-body-2', priority.textColor]">
                                                {{ priority.label }}
                                            </p>
                                        </div>
                                    </a-list-item-content>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </div>
                </a-sheet>

                <!-- Milestone -->
                <a-sheet class="u-flex-center-y mt-4">
                    <h3 style="flex: 0 0 104px" class="md-body-2 blue-grey--text text--darken-1 font-weight-medium">Milestone</h3>
                    <div :class="['c-task-edit-view__meta-item u-wfull u-no-select', { 'c-task-edit-view__meta-item--active': activeDropdowns['milestone'] }]" tabindex="0">
                        <PartialMenuDropdown
                            :list="milestones"
                            @change="localUpdateMilestone"
                            @active="evt => activeDropdowns['milestone'] = evt"
                            :disabled="!canUpdate"
                            min-width="300"
                            max-width="300"
                            nudge-bottom="4"
                            content-class="u-rounded-corners-lg"
                            offset-y bottom
                        >
                            <template #trigger-wrap="{ on, value }">
                                <div v-on="on" class="c-task-edit-view__meta-item--trigger u-flex-center-y px-3" style="column-gap: 2px; min-height: 36px;">
                                    <p :class="[{ 'u-cursor-pointer': canUpdate }, 'ma-0 text-truncate font-weight-medium md-body-2 grey--text text--darken-2']">
                                        {{ localGetMilestoneTitle }}
                                    </p>
                                    <a-spacer></a-spacer>
                                    <a-icon v-if="canUpdate && activeDropdowns['milestone']" size="16" color="grey darken-2">keyboard_arrow_{{ value ? 'up' : 'down' }}</a-icon>
                                </div>
                            </template>
                            <template #item="{ listItem, select }">
                                <a-list-item
                                    :key="listItem.id"
                                    :title="listItem['title']"
                                    @click="item.milestone_id && (item.milestone_id === listItem.id) ? '' : select({ item: listItem })"
                                    class="u-rounded-corners-lg px-3 py-1 grey--text text--darken-2"
                                    :class="[item.milestone_id && (item.milestone_id === listItem.id) ? 'u-opacity-50' : 'u-cursor-pointer']"
                                >
                                    <a-list-item-title class="md-body-2 text-truncate">
                                        {{ listItem['title'] }}
                                    </a-list-item-title>
                                </a-list-item>
                            </template>
                        </PartialMenuDropdown>
                    </div>
                </a-sheet>

                <!-- Est. Duration -->
                <a-sheet class="u-flex-center-y mt-4" v-if="!isExternal">
                    <h3 style="flex: 0 0 104px" class="md-body-2 blue-grey--text text--darken-1 font-weight-medium">Est. Duration</h3>
                    <div @click="$emit('openTimetrack', item)" :class="['c-task-edit-view__meta-item u-wfull u-no-select', { 'c-task-edit-view__meta-item--active': activeDropdowns['est_duration'] }]" tabindex="0">
                        <PartialEstDuration
                            :item="item"
                            :estimatedDuration="item.estimated_duration_text"
                            @open="$emit('openTimetrack', $event)"
                            :contentClass="`px-3 py-1 u-cursor-pointer`"
                            contentStyle="min-height: 36px;"
                        />
                    </div>
                </a-sheet>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { diffHuman, diffSimple } from '@/helpers/helper-date-filter'
import SDropdown from '@/components/Shared/SharedDropdown.vue'
import PartialShortcutKey from '../Partials/PartialShortcutKey.vue'
import PartialSimpleAssigneeDropdown from '../Partials/PartialSimpleAssigneeDropdown.vue'
import PartialVisibilityToggleSection from '../Partials/PartialVisibilityToggleSection.vue'
import PartialVisibilityDropdown from '../Partials/PartialVisibilityDropdown.vue'
import PartialVisibilityDisplay from '../Partials/PartialVisibilityDisplay.vue'
import PartialMenuDropdown from '../Partials/PartialMenuDropdown.vue'
import PartialEstDuration from '../Partials/PartialEstDuration.vue'
import SAttachmentSection from '@/components/Shared/SharedAttachmentSection.vue'
import SDragOverlay from '@/components/Attachments/SharedDragOverlay.vue'
import mixinLoader from '@/mixins/mixin-module-loading-setup'
import mixinAttachmentsInternal from '@/mixins/mixin-internal-attachment'
import mixinAttachmentsExternal from '@/mixins/mixin-external-attachment'

export default {
    mixins: [ mixinLoader, mixinAttachmentsInternal, mixinAttachmentsExternal ],

    props: {
        item: {
            type: Object
        },
        project: {
            type: Object
        },
        collaborators: {
            type: Array
        },
        milestones: {
            type: Array
        },
        taskMetaList: {
            type: Array
        },
        previousTaskId: {
            type: String,
            default: ''
        },
        nextTaskId: {
            type: String,
            default: ''
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        hideNavigation: {
            type: Boolean,
            default: false
        },
        canDelete: {
            type: Boolean,
            default: true
        },
        canComment: {
            type: Boolean,
            default: true
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        isExternal: {
            type: Boolean,
            default: false
        },
    },

    components: {
        SDropdown,
        PartialShortcutKey,
        PartialEstDuration,
        PartialMenuDropdown,
        PartialVisibilityDisplay,
        PartialVisibilityDropdown,
        PartialSimpleAssigneeDropdown,
        PartialVisibilityToggleSection,
        SAttachmentSection,
        SDragOverlay
    },

    data () {
        return {
            loadingTypes: [],
            commentPublicModels: {},
            commentContentModels: {},
            titleTimeout: null,
            descriptionTimeout: null,
            modelCommentCreate: '',
            titleLength: 255,
            commentLength: 5000,
            currentTitleLength: 0,
            confirmDeleteTask: false,
            refreshDesc: false,
            commentsTimeout: null,
            activeDropdowns: {
                assignee: false,
                start_date: false,
                due_date: false,
                visibility: false,
                priority: false,
                milestone: false,
                status_id: false,
                est_duration: false,
            },
            commentParams: {
                'sort': '-created_at',
                'include': 'createdBy,updatedBy',
                'filter[commentable_type]': 'Task',
                'fields[created_by]': 'id,name,email,initial,scope,color,avatar,is_active',
                'fields[updated_by]': 'id,name,email,initial,scope,color,avatar,is_active',
                'fields[comments]': 'id,content,visibility,created_by_id,updated_by_id,created_at,updated_at',
            },
            clonedItem: _.cloneDeep(this.item),
            dateModels: { start_date: '', due_date: '' },
            checkIsPublic: false,
            commentCreateFocused: false,
            dateModelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
            commentOpsMenu: [
                { text: 'Edit', value: 'edit' },
                { text: 'Delete', value: 'delete' },
            ],
            priorityList: [
                { id: 'high', label: 'High', value: 'high', textColor: 'deep-orange--text text--darken-2', icon: 'keyboard_double_arrow_up', iconColor: 'deep-orange darken-2' },
                { id: 'medium', label: 'Medium', value: 'medium', textColor: 'amber--text text--darken-2', icon: 'drag_handle', iconColor: 'amber darken-2' },
                { id: 'low', label: 'Low', value: 'low', textColor: 'light-blue--text', icon: 'keyboard_double_arrow_down', iconColor: 'light-blue' },
                { id: 'no-priority', label: 'No Priority', value: null, textColor: 'grey--text text--darken-2', icon: 'close', iconColor: 'grey darken-2', iconSize: 24 },
            ],
            commentText: '',
            taskTitle: null,
            description: null,
            currentCommentEdit: null,
            commentDeleteId: null,
            deleteTimeout: null,

            //Attachments
            filesDraggedOver: false,
            hoveredOnInfo: false,
            FILTER: {
                parent_id: 'filter[parent_id]',
                parent_type: 'filter[parent_type]',
                source_id: 'filter[source_id]',
                source_type: 'filter[source_type]',
                fields: 'fields[attachments]',
                page: 'page'
            },
            attachment_fields: 'id,name,size,extension,order,source_id,source_type,parent_id,parent_type',
            localDroppedFilesList: [],
            localAttachmentFilters: {},
            backupAttachmentList: [],
            localAttachmentTimeout: null,
            localProject: {}
        }
    },

    watch: {
        isOpen: {
            handler (val) {
                if (val) return this.localIndex()
                this.localResetInputs()
                this.localResetCommentAttrs()
                this.localGetAttachmentMixin('ClearAttachmentUpdate')()
            },
            immediate: true
        },

        nextTaskId (newValue, oldValue) {
            if (newValue || (oldValue && !newValue)) this.localIndex({ commentsTimeout: 2000 })
        },

        'item.title': {
            handler (value) {
                this.taskTitle = value
                this.clonedItem = _.cloneDeep(this.item)
            },
            deep: true
        },

        item: {
            handler (value) {
                this.localProject = _.cloneDeep(value.project)
            },
            deep: true,
            immediate: true
        }
    },

    mounted () {
        if (this.canUpdate) this.localAddListeners()
        document.addEventListener('keydown', this.localKeyDown)
        // this.localIndex()
    },

    beforeDestroy () {
        this.localRemoveListeners()
        this.localResetInputs()
    },

    computed: {
        localProjectId () {
            if (this.project && this.project.id) return this.project.id
        },

        localGetMilestoneTitle () {
            if (this.item && !this.item.milestone_id) return 'No Milestone'

            const { title } = this.milestones.find(i => i.id === this.item.milestone_id) || {}
            return title || 'No Milestone'
        },

        localGetTaskStatus () {
            if (!this.item || (this.item && !this.item.status_id)) return '-'

            return this.item.status ? this.item.status.value : '-'
        },

        localGroupedMetaList () {
            const types = [...new Set(this.taskMetaList.map(i => i.status))]
            const result = []
            types.forEach(item => {
                result.push({ type: item, list: this.taskMetaList.filter(({ status }) => status === item) })
            })

            return result
        },

        localGetCommentList () {
            return this.isExternal ? this.comment_external_list : this.comment_list
        },

        localAttachmentsUpdating () {
            return this.mixinIsLoading('attachment-update') || this.mixinIsLoading('attachment-delete')
        },

        ...mapState('Comment', {
            comment_list: 'list'
        }),

        ...mapState('CommentExternal', {
            comment_external_list: 'list'
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Attachment', {
            attachment_list: 'list',
            attachment_local_list: 'local_list',
            attachment_delete_list: 'delete_list',
            attachment_upload_list: 'upload_list',
            attachment_meta: 'meta',
            attachment_response: 'response',
            attachment_delete_response: 'delete_response',
        }),

        ...mapState('AttachmentExternal', {
            attachment_external_list: 'list',
            attachment_external_local_list: 'local_list',
            attachment_external_delete_list: 'delete_list',
            attachment_external_upload_list: 'upload_list',
            attachment_external_meta: 'meta',
            attachment_external_response: 'response',
            attachment_external_delete_response: 'delete_response',
        }),
    },

    methods: {
        localIndex (props) {
            const { commentsTimeout = 0 } = props || {}
            clearTimeout(this.commentsTimeout)
            this.localSetLoading('index')
            if (!this.item) return this.localResetLoading('index')
            this.localLoadTaskAttachments()
            this.taskTitle = this.item.title
            this.description = this.item.description_json
            this.currentTitleLength = this.taskTitle.length
            this.localSetDates()
            this.localRefreshDesc()
            setTimeout(() => this.localResetLoading('index'), 100)

            this.localSetLoading('comments')
            this.commentsTimeout = setTimeout(() => {
                this.localFetchTaskComments()
            }, commentsTimeout);
        },

        localSetDates () {
            this.$set(this.dateModels, 'start_date', this.item.start_date)
            this.$set(this.dateModels, 'due_date', this.item.due_date)
        },

        async localFetchTaskComments () {
            await this.localGetCommentAction('index')({ ...this.commentParams, 'filter[commentable_id]': this.item.id })
            this.localResetLoading('comments')
        },

        localGetCommentAction (slug) {
            switch (slug) {
                case 'index': return this[this.isExternal ? 'comment_external_index' : 'comment_index']
                case 'show': return this[this.isExternal ? 'comment_external_show' : 'comment_show']
                case 'store': return this[this.isExternal ? 'comment_external_store' : 'comment_store']
                case 'store_local': return this[this.isExternal ? 'comment_external_store_local' : 'comment_store_local']
                case 'update': return this[this.isExternal ? 'comment_external_update' : 'comment_update']
                case 'update_local': return this[this.isExternal ? 'comment_external_update_local' : 'comment_update_local']
                case 'destroy': return this[this.isExternal ? 'comment_external_destroy' : 'comment_destroy']
            }
        },

        async localLoadTaskAttachments () {
            this.mixinSetLoading('attachment')
            this.localGetAttachmentAction('clear_response')()
            this.localGetAttachmentAction('clear_upload_list')()
            this.localSetAttachmentFilters()
            await this.localGetAttachmentAction('index')({ params: this.localAttachmentFilters })
            this.mixinResetLoading('attachment')
            this.localGetAttachmentMixin('UpdateLocalAttachmentList')({ list: this.localGetAttachmentData('list') })
            this.backupAttachmentList =  _.cloneDeep(this.localGetAttachmentData('list'))
        },

        localSetAttachmentFilters () {
            this.localAttachmentFilters[this.FILTER.parent_id] = this.item.project_id
            this.localAttachmentFilters[this.FILTER.parent_type] = 'Project'
            this.localAttachmentFilters[this.FILTER.source_id] = this.item.id
            this.localAttachmentFilters[this.FILTER.source_type] = 'Task'
            this.localAttachmentFilters[this.FILTER.fields] = this.attachment_fields
            this.localAttachmentFilters[this.FILTER.page] = 1
            this.localAttachmentFilters.count = 5
        },

        localGetAttachmentData (field) {
            const prefix = this.isExternal ? 'attachment_external_' : 'attachment_'
            return this[prefix + field]
        },

        localGetAttachmentAction (action) {
            const prefix = this.isExternal ? 'attachment_external_' : 'attachment_'
            return this[prefix + action]
        },

        localGetAttachmentMixin (type) {
            const prefix = this.isExternal ? 'mixinExternal' : 'mixin'
            return this[prefix + type]
        },

        // Title update - Start
        localTriggerTitleUpdate (value) {
            clearTimeout(this.titleTimeout)

            if (!_.trim(value)) return
            value = this.localCalTitleLength(value)

            this.localUpdateTitle(value)
        },

        localBlurUpdateTitle (value) {
            clearTimeout(this.titleTimeout)

            if (!_.trim(value)) return this.taskTitle = this.clonedItem.title
            if (value.length > this.titleLength) value = value.slice(0, this.titleLength)

            this.localUpdateTitle(value, 'no-timeout')
        },

        localPasteTitle (evt) {
            let value = evt.clipboardData.getData('text')
            clearTimeout(this.titleTimeout)

            if (!_.trim(value)) return this.taskTitle = this.clonedItem.title
            if (value.length > this.titleLength) this.taskTitle = value.slice(0, this.titleLength)

            this.localUpdateTitle(this.taskTitle, 'no-timeout')
        },

        localUpdateTitle (value, type = null) {
            if (_.trim(value) === _.trim(this.item.title)) return false

            if (type === 'no-timeout') {
                return this.localEmitItemUpdate({ item: null, value }, this.item, 'title')
            }

            this.titleTimeout = setTimeout(() => {
                this.localEmitItemUpdate({ item: null, value }, this.item, 'title')
            }, 500)
        },

        localCalTitleLength (value) {
            this.currentTitleLength = value.length
            if (value.length <= this.titleLength) return value

            const slicedVal = value.slice(0, this.titleLength)
            this.taskTitle = slicedVal

            return slicedVal
        },
        // Title update - End

        localUpdateDesc (evt) {
            // clearTimeout(this.descriptionTimeout)
            // this.descriptionTimeout = setTimeout(() => {
            //     console.log({ evt }, this.item)
            // }, 500)
            this.$emit('descriptionUpdate', this.item, evt)
        },

        localUpdateAssignee (evt) {
            this.localEmitItemUpdate({ item: evt.item, value: null }, this.item, 'assignees')
        },

        localUpdateDate (value, slug) {
            value = value ? moment(value).format('YYYY-MM-DD') : null
            this.localEmitItemUpdate({ item: null, value }, this.item, slug)
        },

        localUpdateVisibility (item) {
            this.localEmitItemUpdate({ item, value: null }, this.item, 'visibility')
        },

        localUpdatePriority (item) {
            this.localEmitItemUpdate({ item, value: null }, this.item, 'priority')
        },

        localUpdateMilestone ({ item }) {
            this.localEmitItemUpdate({ item, value: item.id }, this.item, 'milestone_id')
        },

        localUpdateStatus ({ item }) {
            this.localEmitItemUpdate({ item, value: item.id }, this.item, 'status_id')
        },

        localUpdateAttachmentCount ({ value }) {
            this.localEmitItemUpdate({ item: null, value }, this.item, 'attachments_count')
        },

        localEmitItemUpdate (props, item, slug) {
            this.$emit('select', props, item, slug, (this.item && !this.item.milestone_id))
        },

        localGetPriority (field) {
            const priority = this.priorityList.find(i => i.value === this.item.priority)
            if (!priority) return '-'

            return priority[field]
        },

        localFocusTextarea (ref) {
            if (Array.isArray(this.$refs[ref])) {
                if (_.size(this.$refs[ref])) this.$refs[ref][0].$el.querySelector('textarea').focus()
                return true
            }
            this.$refs[ref].$el.querySelector('textarea').focus()
        },

        // Comment updates - Start
        localFocusCommentTextarea (ref) {
            this.commentCreateFocused = true
            this.localFocusTextarea(ref)
            this.$el.querySelector('.c-task-edit-view__comment-title').scrollIntoView()
        },

        localRemoveFocus (ref, evt) {
            if (this.$el.querySelector('.c-task-edit-view__comment-parent').contains(evt.relatedTarget)) return
            this.commentCreateFocused = false
        },

        localFocusEditComment (ref) {
            this.localFocusTextarea(ref)
        },

        localCancelCommentEdit (id = null) {
            if (id) {
                const index = this.isExternal ? _.findIndex(this.comment_external_list, { id }) : _.findIndex(this.comment_list, { id })
                if (index !== -1) this.$set(this.isExternal ? this.comment_external_list[index] : this.comment_list[index], 'content', this.commentContentModels[id].oldValue)
            }
            this.currentCommentEdit = null
            this.commentPublicModels = {}
            this.commentContentModels = {}
        },

        localCommentOp (type, comment) {
            if (type === 'edit') {
                this.currentCommentEdit = comment.id
                if (this.isExternal || comment.visibility === 'external') this.$set(this.commentPublicModels, comment.id, !!(comment.visibility === 'external'))
                this.$set(this.commentContentModels, comment.id, { value: comment.content, oldValue: comment.content })
            }

            if (type === 'delete') {
                clearInterval(this.deleteTimeout)
                if (this.commentDeleteId === comment.id) return this.localDeleteComment(comment.id)
                this.commentDeleteId = comment.id
                this.deleteTimeout = setTimeout(() => this.commentDeleteId = null, 3000)
            }
        },

        localDeleteComment (id) {
            const index = this.isExternal ? _.findIndex(this.comment_external_list, { id }) : _.findIndex(this.comment_list, { id })
            this.isExternal ? this.comment_external_list.splice(index, 1) : this.comment_list.splice(index, 1)
            this.localGetCommentAction('destroy')({ id })
            this.localTriggerCommentOps('remove')
        },

        localCommentGet (field, comment) {
            const [firstSlug, secondSlug] = field.split('.') || []

            if (!secondSlug) return comment[firstSlug]
            return comment[firstSlug] ? comment[firstSlug][secondSlug] : null
        },

        localPasteComment (value, commentId) {
            value = value.slice(0, this.commentLength)

            if (!commentId) {
                this.modelCommentCreate = value || ''
                return true
            }

            const index = this.isExternal ? _.findIndex(this.comment_external_list, { id: commentId }) : _.findIndex(this.comment_list, { id: commentId })
            this[this.isExternal ? 'comment_external_list' : 'comment_list'][index].content = value

            const comment = this.isExternal ? this.comment_external_list[index] : this.comment_list[index]
            this.localGetCommentAction('update_local')(comment)
        },

        async localCreateComment (value) {
            if (!_.trim(value)) return

            const params = {
                id: uuidv4(),
                content: value,
                commentable_id: this.item.id,
                commentable_type: 'Task',
                visibility: this.checkIsPublic ? 'external' : 'internal',
            }
            this.localGetCommentAction('store_local')({
                ...params,
                created_at: new Date(),
                updated_at: new Date(),
                created_by: this.user_self,
                updated_by: this.user_self,
                created_by_id: this.user_self.id,
                updated_by_id: this.user_self.id,
            })
            this.localTriggerCommentOps()
            this.localResetCommentAttrs()
            await this.localGetCommentAction('store')({ ...params, mode: 'no-store' })
            // this.localGetCommentAction('show')({ id: params.id, params: { ...this.commentParams, 'filter[commentable_id]': this.item.id } })
        },

        localTriggerCommentOps (type = 'add') {
            const comments_count = this.item.comments_count ? (this.item.comments_count + (type === 'add' ? 1 : -1)) : 1
            this.$emit('commentOps', { ...this.item, comments_count }, (this.item && !this.item.milestone_id))
        },

        localResetCommentAttrs () {
            this.modelCommentCreate = ''
            this.currentCommentEdit = null
            this.checkIsPublic = false
        },

        localUpdateComment (comment) {
            let content = this.commentContentModels[comment.id].value
            if (!_.trim(content)) return

            comment.updated_at = new Date()
            comment.updated_by_id = this.user_self.id
            comment.updated_by = this.user_self

            const visibility = this.commentPublicModels[comment.id] ? 'external' : 'internal'
            if (_.trim(content).length < this.commentLength) {
                this.localGetCommentAction('update_local')({ ...comment, content, visibility })
                this.localGetCommentAction('update')({ ...comment, content, visibility })
                return this.localCancelCommentEdit()
            }

            content = content.slice(0, this.commentLength)

            const index = this.isExternal ? _.findIndex(this.comment_external_list, { id: comment.id }) : _.findIndex(this.comment_list, { id: comment.id })
            this[this.isExternal ? 'comment_external_list' : 'comment_list'][index].content = content

            const commentItem = this[this.isExternal ? 'comment_external_list' : 'comment_list'][index]
            this.localGetCommentAction('update_local')({ ...commentItem, content, visibility })

            this.commentPublicModels = {}
            this.commentContentModels = {}
        },
        // Comments update - End

        localDeleteTask () {
            this.confirmDeleteTask = false
            this.$emit('delete', this.item)
            this.localExit()
        },

        localGetDeleteConfirmation () {
            this.confirmDeleteTask = true
            setTimeout(() => this.confirmDeleteTask = false, 3000)
        },

        localExit () {
            this.$emit('close')
        },

        // Extras
        localGroupedStatuses (list) {
            const groups = { Unstarted: { list: [], listLength: 0 }, Started: { list: [], listLength: 0 }, Completed: { list: [], listLength: 0 } }
            list.forEach(item => {
                if (item.status === 'Unstarted') {
                    groups[item.status].list.push(item)
                    groups[item.status].listLength = _.size(groups[item.status].list)
                }
                if (item.status === 'Started') {
                    groups[item.status].list.push(item)
                    groups[item.status].listLength = _.size(groups[item.status].list)
                }
                if (item.status === 'Completed') {
                    groups[item.status].list.push(item)
                    groups[item.status].listLength = _.size(groups[item.status].list)
                }
            })

            return groups
        },

        localGetStatus (field, statusId) {
            const status = this.taskMetaList.find(i => i.id === statusId)
            if (!status) return '-'

            return status[field]
        },

        localKeyDown (evt) {
            const inputFields = ['INPUT', 'TEXTAREA']
            const isEditor = evt && evt.target && (
                !!(evt.target.closest('.ProseMirror'))
                || evt.target.classList.value.includes('ProseMirror')
                || evt.target.classList.value.includes('editor')
            )

            if (
                !isEditor &&
                !inputFields.includes(evt.target.nodeName)
            ) {
                if (evt.code === 'KeyJ') this.$emit('navigate', this.previousTaskId)
                if (evt.code === 'KeyK') this.$emit('navigate', this.nextTaskId)
            }
        },

        // localGetCommentList (field = 'list') {
        //     const list = this.isExternal ? this.comment_external_list : this.comment_list
        //     const result = { list, length: _.size(list) }

        //     return result[field]
        // },

        localFormatDate (date, type = null) {
            if (!date) return null
            if (type === 'simple') return diffSimple(date)
            if (type === 'full') return moment.utc(date).local().format('MMM D, YYYY [at] h:mm A')

            return diffHuman(date, { withYearFormat: 'MMM D, YYYY [at] h:mm A', format: 'MMM D [at] h:mm A' })
        },

        localResetInputs () {
            this.title = null
            this.description = null
        },

        localSetLoading (type) {
            const index = this.loadingTypes.indexOf(type)
            if (index !== -1) return

            this.loadingTypes.push(type)
        },

        localResetLoading (type) {
            const index = this.loadingTypes.indexOf(type)
            if (index === -1) return

            this.loadingTypes.splice(index, 1)
        },

        localIsLoading (type) {
            return this.loadingTypes.indexOf(type) !== -1
        },

        localRefreshDesc () {
            this.refreshDesc = true
            setTimeout(() => {
                this.refreshDesc = false
            }, 100);
        },

        localDateIsBefore (date, status) {
            if (!date) return false
            return moment(date).isBefore() && (status && status.status !== 'Completed')
        },

        localDateIsDiff (item) {
            return moment(item.created_at).isSame(item.updated_at)
        },

        localChangeVisibility (evt, comment) {
            this.$set(this.commentPublicModels, comment.id, evt)
        },

        // Attachment CRUD

        localAddListeners () {
            this.localGetAttachmentMixin('AttachmentListeners')('add')
        },

        localRemoveListeners () {
            document.removeEventListener('keydown', this.localKeyDown)
            this.localGetAttachmentMixin('AttachmentListeners')('remove')
        },

        localHandleDragEnter (e) {
            this.filesDraggedOver = true
        },

        localHandleLeave (e) {
            if (this.hoveredOnInfo) return
            this.filesDraggedOver = false
        },

        localHandleDrop (e) {
            if (this.localAttachmentsUpdating || this.pageLoading) return
            const droppedFiles = Array.from(e.dataTransfer.files)
            if (_.size(droppedFiles) > 5) return this.localValidateAttachmentCount()
            this.localDroppedFilesList = _.cloneDeep(droppedFiles)
            this.filesDraggedOver = false
        },

        localValidateAttachmentCount () {
            this.$notify('warning', 'Sorry, you can only upload up to 5 files at a time')
            this.filesDraggedOver = false
        },

        localRemoveAttachmentItem (id) {
            this.localGetAttachmentMixin('RemoveAttachmentItem')(id)
        },

        localDeleteAttachmentItem (id) {
            this.localGetAttachmentMixin('DeleteAttachmentItem')(id)
            this.localUpdateTaskAttachment()
        },

        async localUpdateTaskAttachment () {
            this.localGetAttachmentAction('clear_response')()
            this.localGetAttachmentMixin('RemoveInvalidFiles')()
            await this.localBulkDeleteAttachment()
            await this.localUploadAttachment()
            this.mixinResetLoading('attachment-update')
            this.description = this.item.description_json
            this.localRefreshDesc()
            this.$emit('attachmentLoading', false)
        },

        async localUploadAttachment () {
            if (!_.size(this.localGetAttachmentData('upload_list'))) return
            
            const formData = new FormData();
            formData.append('parent_id', this.item.project_id)
            formData.append('parent_type', 'Project')
            formData.append('source_id', this.item.id)
            formData.append('source_type', 'Task')
            formData.append('order', -1)
            this.localGetAttachmentData('upload_list').forEach(file => {
                formData.append('files[]', file)
                formData.append('ids[]', file.id)
            })
            await this.localGetAttachmentAction('upload')(formData)

            if (!this.$status(this.localGetAttachmentData('response'))) return this.localGetAttachmentMixin('HandleAttachmentErrors')()
            this.localShowTaskAttachments()
            this.localGetAttachmentAction('clear_upload_list')()
        },

        async localBulkDeleteAttachment () {
            if (!_.size(this.localGetAttachmentData('delete_list'))) return
            this.mixinSetLoading('attachment-delete')
            await this.localGetAttachmentAction('bulk_destroy')({ids: this.localGetAttachmentData('delete_list')})
            if (this.$status(this.localGetAttachmentData('delete_response'))) {
                if (!_.size(this.localGetAttachmentData('upload_list'))) this.localShowTaskAttachments()
                if (!_.size(this.localGetAttachmentData('local_list')) && !_.size(this.localGetAttachmentData('upload_list'))) this.localLoadTaskAttachments()
                this.localGetAttachmentAction('clear_delete_list')()
            }
            this.mixinResetLoading('attachment-delete')
        },

        async localShowTaskAttachments () {
            this.localAttachmentFilters.count = _.size(this.localGetAttachmentData('local_list'))
            this.localAttachmentFilters.page = 1
            await this.localGetAttachmentAction('index')({ mode: 'show', params: this.localAttachmentFilters})
            this.localGetAttachmentMixin('UpdateLocalAttachmentList')({ list: this.localGetAttachmentData('list')})
            this.backupAttachmentList = _.cloneDeep(this.localGetAttachmentData('list'))
            this.localUpdateAttachmentCount({ value: this.localGetAttachmentData('meta').total })
        },

        localUpdateAttachmentTransfer (params) {
            clearTimeout(this.localAttachmentTimeout)
            this.localGetAttachmentAction('clear_response')()
            const { list, transferFiles } = params || {}
            const totalUploadList = !!_.size(this.localGetAttachmentData('upload_list')) ? [...transferFiles, ...this.localGetAttachmentData('upload_list')] : [...transferFiles]
            this.localGetAttachmentMixin('PreserveListChanges')({ list: list, type: 'drop_transfer' })
            this.localGetAttachmentAction('update_upload_list')(totalUploadList)
            this.$emit('attachmentLoading', true)
            this.mixinSetLoading('attachment-update')
            this.description = this.item.description_json
            this.localRefreshDesc()
            this.localAttachmentTimeout = setTimeout(() => this.localUpdateTaskAttachment(), 2000)
        },

        async localLoadMoreAttachments () {
            if (this.localGetAttachmentData('meta').total === _.size(this.localGetAttachmentData('list'))) return

            this.mixinSetLoading('attachment')

            const defaultCount = 5
            const isListShortOfItem = _.size(this.localGetAttachmentData('list'))%5 === 0
            const loadType = isListShortOfItem ? 'load_more' : 'index'
            this.localAttachmentFilters.count = isListShortOfItem ? defaultCount : this.localGetAttachmentData('meta').current_page*defaultCount
            this.localAttachmentFilters[this.FILTER.page] = isListShortOfItem ? this.localGetAttachmentData('meta').current_page+1 : 1
            await this.localGetAttachmentAction('index')({ mode: loadType, params: this.localAttachmentFilters })

            this.mixinResetLoading('attachment')
            this.localGetAttachmentMixin('PreserveListChanges')({ list: this.localGetAttachmentData('list'), type: 'load_more' })
            this.backupAttachmentList =  _.cloneDeep(this.localGetAttachmentData('list'))
        },

        localClearAttachmentUpdate () {
            this.localGetAttachmentMixin('UpdateLocalAttachmentList')({ list: this.backupAttachmentList })
            this.localGetAttachmentMixin('ClearAttachmentUpdate')()
        },

        ...mapActions('Comment', {
            comment_index: 'index',
            comment_show: 'show_new',
            comment_store: 'store',
            comment_store_local: 'store_local',
            comment_update: 'update',
            comment_update_local: 'update_local',
            comment_destroy: 'destroy',
        }),

        ...mapActions('CommentExternal', {
            comment_external_index: 'index',
            comment_external_show: 'show_new',
            comment_external_store: 'store',
            comment_external_store_local: 'store_local',
            comment_external_update: 'update',
            comment_external_update_local: 'update_local',
            comment_external_destroy: 'destroy',
        }),

        ...mapActions('Attachment', {
            attachment_index: 'index',
            attachment_upload: 'upload',
            attachment_update_upload_list: 'update_upload_list',
            attachment_remove_upload_item: 'remove_upload_list_item',
            attachment_bulk_destroy: 'bulk_destroy',
            attachment_clear_upload_list: 'clear_upload_list',
            attachment_clear_delete_list: 'clear_delete_list',
            attachment_clear_response: 'clear_response',
        }),

        ...mapActions('AttachmentExternal', {
            attachment_external_index: 'index',
            attachment_external_upload: 'upload',
            attachment_external_update_upload_list: 'update_upload_list',
            attachment_external_remove_upload_item: 'remove_upload_list_item',
            attachment_external_bulk_destroy: 'bulk_destroy',
            attachment_external_clear_upload_list: 'clear_upload_list',
            attachment_external_clear_delete_list: 'clear_delete_list',
            attachment_external_clear_response: 'clear_response',
        }),
    }
}
</script>

<template>
    <v-img v-bind="$attrs" v-on="$listeners">
        <template #placeholder>
            <div class="u-wfull u-hfull u-flex align-center justify-center">
                <slot name="placeholder">
                    <a-icon :color="holderColor" :size="holderSize">{{ holderIcon }}</a-icon>
                </slot>
            </div>
        </template>
        <slot />
    </v-img>
</template>

<script>
import { VImg } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VImg },

    props: {
        holderSize: {
            type: String | Number,
            default: 16
        },
        holderColor: {
            type: String,
            default: 'grey lighten-1'
        },
        holderIcon: {
            type: String,
            default: 'person'
        }
    }
}
</script>

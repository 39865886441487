export const notifier = {
    action: (actionType) => {
        if (!actionType) throw Error(`Action type not specified. Expected 1 argument, none given. Ex: "project-stage-update"`)

        return {
            isProjectStageUpdate: () => actionType === 'project-stage-update',
            isProjectStatusUpdate: () => actionType === 'project-status-update',
        }
    }
}

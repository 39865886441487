<template>
    <div class="c-profiles" :style="[{ 'flex-direction': direction }]" v-bind="$attrs">
        <div v-if="list && list.length > count" class="c-profiles__img-wrap c-profiles--ml-n2">
            <a-menu
                :open-on-hover="true"
                :close-on-content-click="false"
                :disabled="hideList"
                :content-class="`u-rounded-corners u-elevation-custom-1 u-border ${customZIndex ? 'u-z16' : ''}`"
                nudge-top="8"
                transition="slide-y-transition"
                offset-y right
            >
                <template v-slot:activator="{ on }">
                    <a-avatar v-on="on" :size="avatarSize" color="grey lighten-2">
                        <span :style="{ fontSize: fontSize + 'px' }" class="font-weight-medium grey--text text--darken-1">+{{ list.length - count }}</span>
                    </a-avatar>
                </template>
                <a-sheet class="u-rounded-corners white">
                    <a-list one-line style="max-height: 300px;" class="c-scrollbar py-2 transparent overflow-y-auto">
                        <a-list-item :class="[list.slice(count).length - 1 !== index ? 'pb-1' : '']" v-for="(user, index) in list.slice(count)" :key="index">
                            <a-layout align-center class="py-0">
                                <div class="member-avatar mr-2">
                                    <g-avatar :font-size="fontSize" :size="32" :item="user" :right-space="false"></g-avatar>
                                </div>
                                <div class="member-label">
                                    <!-- <g-profile-info v-if="user.user" :item="user.user"></g-profile-info> -->
                                    <template v-if="directRole">
                                        <p :class="[dark ? 'whit--text' : 'grey--text text--darken-1']" class="md-caption mb-0">
                                            {{ user.type_value }}
                                        </p>
                                    </template>
                                    <g-profile-info v-else :dark="dark" :item="user"></g-profile-info>
                                </div>
                            </a-layout>
                        </a-list-item>
                    </a-list>
                </a-sheet>
            </a-menu>
        </div>
        <div v-for="(user, index) in list.slice(0, count).reverse()" :key="index" class="c-profiles__img-wrap c-profiles--ml-n2">
            <a-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <g-avatar :font-size="fontSize" :size="avatarSize" :item="user" :right-space="false" :darkShadow="darkShadow"></g-avatar>
                    </div>
                </template>
                <span class="text-center">
                    <div class="u-flex align-center justify-center">
                        <p class="text-subtitle-2 mb-0" :class="{ 'font-weight-bold': !hideRole }" v-if="user.user">{{ user.user.name }}</p>
                        <p class="text-subtitle-2 mb-0" :class="{ 'font-weight-bold': !hideRole }" v-if="user.name">{{ user.name }}</p>
                        <a-icon color="red darken-1" size="12" class="ml-1" v-if="(user.user && user.user.is_active === 0) || (user && user.is_active === 0)">block</a-icon>
                    </div>
                    <template v-if="!hideRole">
                        <!-- Users role type -->
                        <div v-if="typeUser" class="text-center">
                            <template v-if="user.scope === 'internal'">
                                <p class="md-caption whit--text mb-0" v-if="user && user.user && user.user.role">{{ user.user.role.name }}</p>
                                <p class="md-caption whit--text mb-0" v-else>{{ user.role ? user.role.name : '' }}</p>
                            </template>
                            <p class="md-caption whit--text text-capitalize mb-0" v-else>{{ user.group }}</p>
                        </div>

                        <!-- Users collaborator type -->
                        <div v-else>
                            <template v-if="directRole">
                                <p class="md-caption whit--text mb-0">
                                    {{ user.type_value }}
                                </p>
                            </template>
                            <template v-else>
                                <template v-if="user && (user.collaborator || user.type)">
                                    <p class="md-caption whit--text mb-0" v-if="user && user.collaborator && user.collaborator.type">{{ user.collaborator.type.value }}</p>
                                    <p class="md-caption whit--text mb-0" v-if="user && user.type">{{ user.type.value }}</p>
                                    <p class="md-caption whit--text text-capitalize mb-0" v-if="user && user.scope === 'external'">{{ user.group }}</p>
                                </template>
                            </template>
                            <!-- <template v-if="user && user.user">
                                <p class="md-caption whit--text mb-0" v-if="user.user.type">{{ user.user.type.status }}</p>
                            </template> -->
                        </div>
                    </template>
                </span>
            </a-tooltip>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            required: true
        },
        count: {
            type: Number,
            default: 4
        },
        avatarSize: {
            type: Number | String,
            default: 32
        },
        fontSize: {
            type: String | Number,
            default: '12'
        },
        directRole: {
            type: Boolean,
            default: false
        },
        hideList: {
            type: Boolean,
            default: false
        },
        hideRole: {
            type: Boolean,
            default: false
        },
        darkShadow: {
            type: Boolean,
            default: false
        },
        typeUser: {
            type: Boolean,
            default: false
        },
        dark: {
            type: Boolean,
            default: false
        },
        customZIndex: {
            type: Boolean,
            default: true
        },
        position: {
            type: String,
            default: null
        },
        direction: {
            type: String,
            default: 'row-reverse'
        }
    }
}
</script>

const pascalToKebab = (vnode) => {
    return vnode.context
                .$options
                .name
                .split('')
                .map((letter, i) => {
                    if (/[A-Z]/.test(letter))
                        return i === 0 ? letter.toLowerCase() : '-' + letter.toLowerCase()
                    return letter
                })
                .join('')
}

const kebabToCamelCase = (value) => {
    return value.split('-')
        .map(a => a.charAt(0).toUpperCase() + a.slice(1))
        .join('')
}

export {
    pascalToKebab,
    kebabToCamelCase
}

<template>
    <g-dialog-box :icon="form_model_type === 'task' ? 'check_circle' : 'assistant_photo'" hide-close-icon>
        <template #title>Create New <span class="text-capitalize">{{ form_model_type }}</span> for {{ projectTitle }}</template>
        <template #subtitle>{{ form_model_type === 'task' ? 'Create new Task to group tasks' : 'Create new Milestone to group milestones' }}</template>
        <template #title-action>
            <a-divider></a-divider>
            <div class="px-6 py-3">
                <v-radio-group v-model="form_model_type" row class="my-0 py-0" hide-details>
                    <v-radio label="Create New Task" value="task"></v-radio>
                    <v-radio label="Create New Milestone" value="milestone"></v-radio>
                </v-radio-group>
            </div>
        </template>
        <template #body>
            <a-container container--fluid grid-list-xl class="pa-6">
                <a-layout>
                    <a-flex xs12 v-if="form_model_type === 'task'">
                        <a-layout wrap align-center>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title</label>
                                <a-text-field
                                    v-model="taskItem.title"
                                    placeholder="Add new task title"
                                    background-color="neutral"
                                    solo flat hide-details
                                ></a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="task_response.server && task_response.server.errors && task_response.server.errors.title">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ task_response.server.errors.title[0] }}
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16">info</a-icon>
                                    Enter the title of the task.
                                </span>
                            </a-flex>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Description (Optional)</label>
                                <div class="c-task-edit-view__edit-desc">
                                    <g-rich-text-editor
                                        v-model="taskItem.description_json"
                                        placeholder="Enter description..."
                                        :class="[{ 'grey lighten-4': !isEditorFocused }]"
                                        @isFocused="evt => isEditorFocused = evt"
                                        :refresh="refreshDesc"
                                        formatterStickyZero
                                    ></g-rich-text-editor>
                                </div>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="task_response.server && task_response.server.errors && task_response.server.errors.description">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ task_response.server.errors.description[0] }}
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16">info</a-icon>
                                    Enter the description of the task.
                                </span>
                            </a-flex>
                            <a-flex xs3>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Start Date (Optional)</label>
                                <!-- <a-menu
                                    v-model="datepicker_task_start_date"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    min-width="290px"
                                    full-width offset-y lazy
                                >
                                    <template v-slot:activator="{ on }">
                                        <a-text-field
                                            :value="local_format_date(taskItem.start_date)"
                                            placeholder="Select Start Date"
                                            background-color="neutral"
                                            v-on="on"
                                            @click:clear="taskItem.start_date = null"
                                            solo flat hide-details readonly clearable
                                        >
                                        </a-text-field>
                                    </template>
                                    <a-date-picker v-model="taskItem.start_date" @input="datepicker_task_start_date= false" :max="taskItem.due_date"  bottom no-title scrollable></a-date-picker>
                                </a-menu> -->
                                <vc-date-picker
                                    v-model="taskItem.start_date"
                                    :max-date="taskItem.due_date"
                                    :model-config="modelConfig"
                                    class="d-block u-wfull"
                                    @input="datepicker_task_start_date= false"
                                >
                                    <template v-slot="{ togglePopover }">
                                        <div @click.stop="() => togglePopover({ placement: 'bottom-start' })">
                                            <a-text-field
                                                :value="local_format_date(taskItem.start_date)"
                                                placeholder="Select Start Date"
                                                background-color="neutral"
                                                @click:clear="taskItem.start_date = null"
                                                solo flat hide-details readonly clearable
                                            >
                                            </a-text-field>
                                        </div>
                                    </template>
                                </vc-date-picker>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                    <a-icon size="16">info</a-icon>
                                    Select the start date of the task.
                                </span>
                            </a-flex>
                            <a-flex xs3>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Due Date (Optional)</label>
                                <!-- <a-menu
                                    v-model="datepicker_task_due_date"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    min-width="290px"
                                    full-width offset-y lazy
                                >
                                    <template v-slot:activator="{ on }">
                                        <a-text-field
                                            :value="local_format_date(taskItem.due_date)"
                                            placeholder="Select Due Date"
                                            background-color="neutral"
                                            v-on="on"
                                            @click:clear="taskItem.due_date = null"
                                            solo flat hide-details readonly clearable
                                        >
                                        </a-text-field>
                                    </template>
                                    <a-date-picker v-model="taskItem.due_date" @input="datepicker_task_due_date= false" :min="taskItem.start_date"  bottom no-title scrollable></a-date-picker>
                                </a-menu> -->
                                <vc-date-picker
                                    v-model="taskItem.due_date"
                                    :min-date="taskItem.start_date"
                                    :max-date="taskItem.due_date"
                                    :model-config="modelConfig"
                                    class="d-block u-wfull"
                                    @input="datepicker_task_due_date= false"
                                >
                                    <template v-slot="{ togglePopover }">
                                        <div @click.stop="() => togglePopover({ placement: 'bottom-start' })">
                                            <a-text-field
                                                :value="local_format_date(taskItem.due_date)"
                                                placeholder="Select Start Date"
                                                background-color="neutral"
                                                @click:clear="taskItem.due_date = null"
                                                solo flat hide-details readonly clearable
                                            >
                                            </a-text-field>
                                        </div>
                                    </template>
                                </vc-date-picker>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                    <a-icon size="16">info</a-icon>
                                    Select the due date of the task.
                                </span>
                            </a-flex>
                            <a-flex xs3>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Priority</label>
                                <a-autocomplete
                                    v-model="taskItem.priority"
                                    :items="[{name: 'No Priority', value: null}, {name: 'High', value: 'high'}, {name: 'Medium', value: 'medium'}, {name: 'Low', value: 'low'}]"
                                    item-text="name"
                                    item-value="value"
                                    placeholder="visibility"
                                    background-color="neutral"
                                    solo flat hide-details
                                >
                                </a-autocomplete>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                    <a-icon size="16">info</a-icon>
                                    Select the Priority of the task.
                                </span>
                            </a-flex>
                            <a-flex xs3>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Estimation</label>
                                <a-text-field
                                    v-model="taskItem.estimated_duration_text"
                                    @input="local_calculate_mins"
                                    placeholder="Ex: 4w 2d 10h 8m"
                                    background-color="neutral"
                                    solo flat hide-details
                                >
                                </a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="validationError && showValidationError">
                                    <a-icon size="16" color="red darken-2">info</a-icon>
                                    {{ validationError }} <span class="grey--text text--darken-2">(Ex: 4w 5d 6h 20m)</span>
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16">info</a-icon>
                                    Duration format 4w 5d 6h 20m
                                </span>
                            </a-flex>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Milestone (Optional)</label>
                                <a-autocomplete
                                    v-model="taskItem.milestone_id"
                                    :items="milestoneList"
                                    item-text="title"
                                    item-value="id"
                                    placeholder="Select Milestone"
                                    background-color="neutral"
                                    solo flat hide-details clearable
                                >
                                </a-autocomplete>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                    <a-icon size="16">info</a-icon>
                                    Select the milestone of the task.
                                </span>
                            </a-flex>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Assignee (Optional)</label>
                                <GUsersSelectDropdown
                                    v-model="selected_assignees"
                                    :list="collabList"
                                    :loading="mixinSearchCollabLoading"
                                    :inputStyle="{ minHeight: '30px', fontSize: '16px' }"
                                    itemText="user.name"
                                    itemValue="user.id"
                                    @search="localSearchCollabs"
                                    placeholder="Select Assignees"
                                    class="pr-5 grey lighten-4"
                                    dropdownClass="c-tiny-scroll py-2"
                                    returnObject multiple removable
                                />
                                <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                    <a-icon size="16">info</a-icon>
                                    Select the assignee of the task.
                                </span>
                            </a-flex>
                        </a-layout>
                    </a-flex>
                    <a-flex xs12 v-if="form_model_type === 'milestone'">
                        <a-layout wrap align-center>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Milestone Title</label>
                                <a-text-field
                                    v-model="milestoneItem.title"
                                    placeholder="Milestone Title"
                                    background-color="neutral"
                                    solo flat hide-details
                                >
                                </a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="milestone_response.server && milestone_response.server.errors && milestone_response.server.errors.title">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ milestone_response.server.errors.title[0] }}
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16">info</a-icon>
                                    Enter the title of the milestone.
                                </span>
                            </a-flex>
                            <a-flex xs6>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Start Date (Optional)</label>
                                <!-- <a-menu
                                    v-model="datepicker_milestone_start_date"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    min-width="290px"
                                    full-width offset-y lazy
                                >   <template v-slot:activator="{ on }">
                                        <a-text-field
                                            :value="local_format_date(milestoneItem.start_date)"
                                            placeholder="Select Start Date"
                                            background-color="neutral"
                                            v-on="on"
                                            @click:clear="milestoneItem.start_date = null"
                                            solo flat hide-details readonly clearable
                                        >
                                        </a-text-field>
                                    </template>
                                    <a-date-picker v-model="milestoneItem.start_date" @input="datepicker_milestone_start_date = false" no-title scrollable></a-date-picker>
                                </a-menu> -->
                                <vc-date-picker
                                    v-model="milestoneItem.start_date"
                                    :max-date="milestoneItem.due_date"
                                    :model-config="modelConfig"
                                    class="d-block u-wfull"
                                    @input="datepicker_milestone_start_date= false"
                                >
                                    <template v-slot="{ togglePopover }">
                                        <div @click.stop="() => togglePopover({ placement: 'bottom-start' })">
                                            <a-text-field
                                                :value="local_format_date(milestoneItem.start_date)"
                                                placeholder="Select Start Date"
                                                background-color="neutral"
                                                @click:clear="milestoneItem.start_date = null"
                                                solo flat hide-details readonly clearable
                                            >
                                            </a-text-field>
                                        </div>
                                    </template>
                                </vc-date-picker>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="milestone_response.server && milestone_response.server.errors && milestone_response.server.errors.start_date">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ milestone_response.server.errors.start_date[0] }}
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16">info</a-icon>
                                    Select the start date of the milestone.
                                </span>
                            </a-flex>
                            <a-flex xs6>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Due Date (Optional)</label>
                                <!-- <a-menu
                                    v-model="datepicker_milestone_due_date"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    min-width="290px"
                                    full-width offset-y lazy
                                >
                                    <template v-slot:activator="{ on }">
                                        <a-text-field
                                            :value="local_format_date(milestoneItem.due_date)"
                                            placeholder="Select Due Date"
                                            background-color="neutral"
                                            v-on="on"
                                            @click:clear="milestoneItem.due_date = null"
                                            solo flat hide-details readonly clearable
                                        >
                                        </a-text-field>
                                    </template>
                                    <a-date-picker v-model="milestoneItem.due_date" @input="datepicker_milestone_due_date = false" no-title scrollable></a-date-picker>
                                </a-menu> -->
                                <vc-date-picker
                                    v-model="milestoneItem.due_date"
                                    :min-date="milestoneItem.start_date"
                                    :max-date="milestoneItem.due_date"
                                    :model-config="modelConfig"
                                    class="d-block u-wfull"
                                    @input="datepicker_milestone_due_date= false"
                                >
                                    <template v-slot="{ togglePopover }">
                                        <div @click.stop="() => togglePopover({ placement: 'bottom-start' })">
                                            <a-text-field
                                                :value="local_format_date(milestoneItem.due_date)"
                                                placeholder="Select Start Date"
                                                background-color="neutral"
                                                @click:clear="milestoneItem.due_date = null"
                                                solo flat hide-details readonly clearable
                                            >
                                            </a-text-field>
                                        </div>
                                    </template>
                                </vc-date-picker>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="milestone_response.server && milestone_response.server.errors && milestone_response.server.errors.due_date">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ milestone_response.server.errors.due_date[0] }}
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16">info</a-icon>
                                    Select the due date of the milestone.
                                </span>
                            </a-flex>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Assignee (Optional)</label>
                                <GUsersSelectDropdown
                                    v-model="selected_assignees"
                                    :list="collabList"
                                    :loading="mixinSearchCollabLoading"
                                    :inputStyle="{ minHeight: '30px', fontSize: '16px' }"
                                    itemText="user.name"
                                    itemValue="user.id"
                                    @search="localSearchCollabs"
                                    placeholder="Select Assignees"
                                    class="pr-5 grey lighten-4"
                                    dropdownClass="c-tiny-scroll py-2"
                                    returnObject multiple removable
                                />
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="milestone_response.server && milestone_response.server.errors && milestone_response.server.errors.assignee">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ milestone_response.server.errors.assignee[0] }}
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16">info</a-icon>
                                    Select the assignee of the milestone.
                                </span>
                            </a-flex>
                        </a-layout>
                    </a-flex>
                </a-layout>
            </a-container>
        </template>
        <template #footer>
            <a-btn color="primary" class="ma-0 elevation-0 px-3"  v-if="form_model_type === 'milestone'" :loading="loading" :disabled="loading" @click="local_store_milestone()">Save Milestone</a-btn>
            <a-btn color="primary" class="ma-0 elevation-0 px-3" v-if="form_model_type === 'task'"  :loading="loading" :disabled="loading" @click="local_store_task">Save Task</a-btn>
            <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="$emit('close', true)" :disabled ="loading">Cancel</a-btn>
        </template>
    </g-dialog-box>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { calculateByText } from '@/helpers/helper-time-tracker.js'
import mixinSearchCollaborators from '@/mixins/mixin-search-collaborators'

export default {
    mixins: [mixinSearchCollaborators],

    props: {
        projectTitle: {
            type: String
        },
        collabList: {
            type: Array
        },
        milestoneList: {
            type: Array
        },
        taskItem: {
            type: Object,
            default: {}
        },
        milestoneItem: {
            type: Object,
            default: {}
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        projectId: {
            type: String,
        },
        loading: {
            type: Boolean
        },
    },

    data () {
        return {
            selected_assignees: [],
            form_model_type: 'task',
            local_assignee_search: null,
            local_user_search: null,
            datepicker_task_start_date: null,
            datepicker_task_due_date: null,
            datepicker_milestone_start_date: null,
            datepicker_milestone_due_date: null,
            validationError: null,
            showValidationError: false,
            refreshDesc: false,
            isEditorFocused: false,
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
        }
    },

    watch: {
        form_model_type (val) {
            this.local_clear_fields()
        },
        isOpen (val) {
            if (val) {
                this.mixinGetCollabs({ project_id: this.projectId })
                this.showValidationError = false
                this.selected_assignees = []
                this.localRefreshDesc()
            }
        }
    },

    computed: {
        ...mapState('Task', {
            task_response: 'response',
        }),

        ...mapState('Milestone', {
            milestone_response: 'response',
        }),
    },

    methods: {
        local_calculate_mins (evt) {
            this.local_reset_validation()
            const { durationMins, error } = calculateByText(evt)
            this.validationError = error
            if (error) return

            this.taskItem.estimated_duration_minutes = durationMins
            if (this.validationError) this.local_reset_validation()
        },

        local_reset_validation () {
            this.showValidationError = false
            this.validationError = null
        },

        local_store_task () {
            if (this.validationError) this.showValidationError = true
            this.$emit('store-task', { selected_assignees: _.map(this.selected_assignees, 'user_id'), ...this.taskItem }, this.showValidationError)
        },

        local_store_milestone () {
            this.$emit('store-milestone', { selected_assignees: _.map(this.selected_assignees, 'user_id'), ...this.milestoneItem, })
        },

        local_format_date(date) {
            if (date != null) return moment(date).format('MMMM DD, YYYY')
            return null;
        },

        local_remove_assignee (item, list) {
            list.splice(_.map(list, 'user_id').indexOf(item.user_id), 1)
        },

        local_clear_fields () {
            this.selected_assignees = []
            this.task_clear_item()
            this.milestone_clear_item()
        },

        localSearchCollabs (val) {
            //const args = { include: 'type,user', 'filter[project_id]': this.projectId, count: 20, page: 1 }
            //this.mixinSearchCollabs(val, args)
        },

        localRefreshDesc () {
            this.refreshDesc = true
            setTimeout(() => {
                this.refreshDesc = false
            }, 100);
        },

        ...mapActions('Task', {
            task_clear_item: 'clear_item',
        }),

        ...mapActions('Milestone', {
            milestone_clear_item: 'clear_item',
        }),
    }
}
</script>

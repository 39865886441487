<template>
    <login-layout @loggedIn="(param) => $emit('logged-in', param)"></login-layout>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout.vue'

export default {
    components: { LoginLayout }
}
</script>

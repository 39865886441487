<template>
    <div class="u-relative">
        <ProjectConfidenceScoreFields
            :leave-page="dialog_leave_page_alert"
            @leavePage="localLeavePage()"
            @cancel="localCancelPageLeave()"
        >
        </ProjectConfidenceScoreFields>
    </div>
</template>

<script>
import ProjectConfidenceScoreFields from './ProjectConfidenceScoreFields.vue'
import ProjectConfidenceScoreSummary from './ProjectConfidenceScoreSummary.vue'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import { mapState, mapActions } from 'vuex'

export default {
    mixins: [mixinLoading],

    components: { ProjectConfidenceScoreFields, ProjectConfidenceScoreSummary },

    data () {
        return {
            dialog_leave_page_alert: false,
            to_page_route: null,
            dialog_score_summary: false
        }
    },

    beforeRouteLeave (to, from, next) {
        if (!this.confidence_save_status) return next()
        next(false)
        this.dialog_leave_page_alert = true
        this.to_page_route = next
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        ...mapState('Preference', {
            preference_list: 'list',
        }),

        ...mapState('ConfidenceScoreValues', {
            confidence_save_status: 'unsaved_changes'
        }),

        ...mapState('ProjectView', {
            project_item: 'item',
        }),
    },

    methods: {
        async localIndex () {
            this.mixinSetLoading('root')
            await this.localFetchPreferences()
            if (!this.localHasPreference() || !this.$can('poc_confidence_score.index')) return this.$router.replace({name: 'errors-unauthorized'})
            this.mixinResetLoading('root')
        },

        localHasPreference () {
            const preference = this.preference_list.find(item => item.key === 'organization.confidence_score')
            return preference && preference.value === 'Yes'
        },

        async localFetchPreferences () {
            await this.preference_index()
        },

        localLeavePage () {
            this.confidence_update_unsaved_status(false)
            this.to_page_route()
        },

        localCancelPageLeave () {
            this.dialog_leave_page_alert = false
        },

        ...mapActions('ConfidenceScoreValues', {
            confidence_update_unsaved_status: 'update_unsaved_status',
        }),

        ...mapActions('Preference', {
            preference_index: 'index',
        }),
    }
}
</script>

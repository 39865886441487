<template>
    <div>
        <PAutomationWorkflowBreadcrumb
            :page="$route.params.type === 'automation'? 'settings-automations' : 'settings-validations'"
            :name="$route.params.type === 'automation' ? 'automation': 'validation'"
        />
        <a-container grid-list-xl container--fluid class="mt-5" pa-0>
            <g-page-header
                :title="`${localLogType === 'automation' ? 'Automation' : 'Validation'} Log`"
                subtitle="Success and error logs from workflow runs."
                icon="menu_open"
            >
            </g-page-header>
        </a-container>
        <template v-if="localPageLoading">
           <PAutomationValidationLogLoader/>
        </template>
        <template v-if="!localPageLoading">
            <a-container container--fluid grid-list-xl pa-0 mt-6>
                <a-layout align-center>
                    <a-flex shrink>
                        <a-menu bottom offset-y v-model="triggerDropdown" :close-on-content-click="false" max-width="264" min-width="264">
                            <template v-slot:activator="{ on }">
                                <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="264" v-on="on">
                                    <div class="md-body-2 grey--text text--darken-2 mx-1 u-flex align-center">
                                        <a-icon size="20" :color="!localIsTriggerFilter ? 'blue' : 'grey darken-1'"  class="mr-1">filter_list</a-icon>
                                        <span>Triggers:</span>
                                        <span class="ml-2 grey--text text--darken-3 font-weight-medium">
                                            <template v-if="selectedTriggers && selectedTriggers.length === 1 && selectedTriggers[0] !== 'All workflows' && selectedTriggers[0] !== 'No workflow'">
                                                {{ localTriggerTitle(selectedTriggers[0]) | truncateText(15) }}
                                            </template>
                                            <template v-else-if="selectedTriggers && selectedTriggers.length > 1">{{ selectedTriggers.length + ' Selected' }}</template>
                                            <template v-else-if="selectedTriggers[0] === 'No trigger'">No Trigger</template>
                                            <template v-else>All Triggers</template>
                                        </span>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <a-progress-circular v-if="filterLoading === 'trigger'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                    <a-icon v-else size="20" class="ma-0">arrow_drop_{{ triggerDropdown ? 'up' : 'down' }}</a-icon>
                                </a-sheet>
                            </template>
                            <a-list class="u-list-condensed u-overflow-y py-2">
                                <a-list-item v-for="trigger in automation_trigger_list" class="pr-5" :key="trigger.id" @click="localAutomationTriggerFilter(trigger.id)">
                                    <a-list-item-icon class="ma-0 pa-0 u-flex justify-center">
                                        <a-checkbox dense hide-details color="blue darken-2" :value="localIsTriggerSelected(trigger.id)" class="ma-0 pa-0"></a-checkbox>
                                    </a-list-item-icon>
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1" :title="trigger.title">{{ trigger.title | truncateText(60) }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                                <a-list-item v-if="(automation_trigger_list && !automation_trigger_list.length)">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">No Trigger Found</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                            <a-divider></a-divider>
                            <a-list class="u-list-condensed u-overflow-y">
                                <a-list-item class="pr-5" @click="localAutomationTriggerFilter(null)">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">All Trigger</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink>
                        <a-menu bottom offset-y v-model="workflowDropdown" :close-on-content-click="false" max-width="264" min-width="264">
                            <template v-slot:activator="{ on }">
                                <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="264" v-on="on">
                                    <div class="md-body-2 grey--text text--darken-2 mx-1 u-flex align-center">
                                        <a-icon size="20" :color="localIsWorkflowFilter ? 'blue' : 'grey darken-1'"  class="mr-1">filter_list</a-icon>
                                        <span>Workflow:</span>
                                        <span class="ml-2 grey--text text--darken-3 font-weight-medium">
                                            <template v-if="selectedWorkflows && selectedWorkflows.length === 1 && selectedWorkflows[0] !== 'All workflows' && selectedWorkflows[0] !== 'No workflow'">
                                                {{ localGetWorkflowTitle(selectedWorkflows[0]) | truncateText(15) }}
                                            </template>
                                            <template v-else-if="selectedWorkflows && selectedWorkflows.length > 1">{{ selectedWorkflows.length + ' Selected' }}</template>
                                            <template v-else-if="selectedWorkflows[0] === 'No workflow'">No Workflow</template>
                                            <template v-else>All Workflows</template>
                                        </span>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <a-progress-circular v-if="filterLoading === 'workflow'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                    <a-icon v-else size="20" class="ma-0">arrow_drop_{{ workflowDropdown ? 'up' : 'down' }}</a-icon>
                                </a-sheet>
                            </template>
                            <a-list class="u-list-condensed u-overflow-y py-2" style="max-height: 264px">
                                <a-list-item v-for="workflow in automation_workflow_list" class="pr-5" :key="workflow.id" @click="localAutomationWorkflowFilter(workflow.id)">
                                    <a-list-item-icon class="ma-0 pa-0 u-flex justify-center">
                                        <a-checkbox dense hide-details color="blue darken-2" :value="localIsWorkflowSelected(workflow.id)" class="ma-0 pa-0"></a-checkbox>
                                    </a-list-item-icon>
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1" :title="workflow.title">{{ workflow.title | truncateText(60) }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                                <a-list-item v-if="(automation_workflow_list && !automation_workflow_list.length)">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">No Workflow Found</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                            <a-divider></a-divider>
                            <a-list class="u-list-condensed u-overflow-y">
                                <a-list-item class="pr-5" @click="localAutomationWorkflowFilter(null)">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">All Workflows</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink pr-2>
                        <a-menu bottom offset-y v-model="statusDropdown" :close-on-content-click="false"  min-width="240" max-width="240">
                            <template v-slot:activator="{ on }">
                                <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="240" v-on="on">
                                    <span class="md-body-2 grey--text text--darken-2 u-flex mx-1">
                                        <a-icon size="20" :color="localIsStatusFilter ? 'blue' : 'grey darken-1'" class="mr-1 u-icon-nudge">filter_list</a-icon>
                                        <span>Status:</span>
                                        <span class="ml-1 grey--text text--darken-3 font-weight-medium">
                                            <template v-if="selectedStatus && selectedStatus.length === 1">
                                                <span class="md-body-2 grey--text text--darken-3 ml-1 text-capitalize">{{ localLogGetStatusValue(selectedStatus[0]) }}</span>
                                            </template>
                                            <template v-else-if="selectedStatus && selectedStatus.length > 1">{{ selectedStatus.length + ' Selected' }}</template>
                                            <template v-else>All Status</template>
                                        </span>
                                    </span>
                                    <a-spacer></a-spacer>
                                    <a-progress-circular v-if="filterLoading === 'status'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                    <a-icon v-else size="20" class="ma-0">arrow_drop_{{ statusDropdown ? 'up' : 'down' }}</a-icon>
                                </a-sheet>
                            </template>
                            <a-list class="u-list-condensed u-overflow-y" style="max-height: 300px;">
                                <a-list-item v-for="(status, index) in logStatus" class="pr-5" :key="'status'+index" @click="localAutomationLogStatusFilter(status.value)">
                                    <a-list-item-icon class="ma-0 pa-0 u-flex justify-center">
                                        <a-checkbox dense hide-details color="blue darken-2" :value="localAutomationLogStatusIsSelected(status.value)" class="ma-0 pa-0"></a-checkbox>
                                    </a-list-item-icon>
                                    <a-list-item-title>
                                        <div class="u-flex">
                                            <span class="md-body-2 grey--text text--darken-3 ml-1">{{ status.label }}</span>
                                            <a-spacer></a-spacer>
                                            <div class="c-filter-status" :class="['c-filter-'+status.value]"></div>
                                        </div>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                            <a-divider></a-divider>
                            <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                                <a-list-item class="pr-5" @click="localAutomationLogStatusFilter(null)">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">All Status</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink pr-2>
                        <a-menu bottom offset-y v-model="timeDropdown"  min-width="197" max-width="197">
                            <template v-slot:activator="{ on }">
                                <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="197" v-on="on">
                                    <span class="md-body-2 grey--text text--darken-2 mx-1">
                                        <a-icon size="20" :color="selectedTime && selectedTime.value !== 1 && localIsTimeFilter ? 'blue' : 'grey darken-1'" class="mr-1 u-icon-nudge">filter_list</a-icon>
                                        <span>Time:</span>
                                        <span class="ml-1 grey--text text--darken-3 font-weight-medium">{{ selectedTime.label }}</span>
                                    </span>
                                    <a-spacer></a-spacer>
                                    <a-progress-circular v-if="filterLoading === 'time'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                    <a-icon v-else size="20" class="ma-0">arrow_drop_{{ timeDropdown ? 'up' : 'down' }}</a-icon>
                                </a-sheet>
                            </template>
                            <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                                <a-list-item v-for="local_time in logTimes" class="pr-5" :class="{ 'grey lighten-4': selectedTime.value === local_time.value }" :key="local_time.value" @click="localLogAutomationTimefilter(local_time)">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">{{ local_time.label }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                </a-layout>
            </a-container>
            <a-container container--fluid grid-list-xl pa-0 mt-6 v-if="automation_log_list && automation_log_list.length">
                <a-layout align-center class="u-rounded-corners">
                    <a-flex pt-0>
                        <a-sheet class="u-overflow-hidden">
                            <a-container container--fluid grid-list-xl py-4 px-5>
                                <a-layout align-center>
                                    <a-flex shrink xs3 xl3>
                                        <h2 class="md-subtitle-1 font-weight-bold indigo--text text--darken-1">Trigger</h2>
                                    </a-flex>
                                    <a-flex shrink xs5 xl5>
                                        <h2 class="md-subtitle-1 font-weight-bold indigo--text text--darken-1">Title</h2>
                                    </a-flex>
                                    <a-flex shrink xs2 xl2>
                                        <h2 class="md-subtitle-1 font-weight-bold indigo--text text--darken-1">Status</h2>
                                    </a-flex>
                                    <a-flex shrink>
                                        <h2 class="md-subtitle-1 font-weight-bold indigo--text text--darken-1">Date & Time</h2>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-sheet>
                        <a-divider></a-divider>
                        <template v-for="(log) in automation_log_list">
                            <a-sheet class="u-overflow-hidden u-cursor-pointer" :key="log.id" @click="localShowLogInfo(log.id)">
                                <a-container container--fluid grid-list-xl py-4 px-5>
                                    <a-layout align-center>
                                        <a-flex shrink xs3 xl3 >
                                            <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-3" v-if="log.automation_trigger" :title="log.automation_trigger.title">{{ log.automation_trigger.title | truncateText(60) }}</h2>
                                            <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-3" v-else>-</h2>
                                            <div class="u-flex mt-1">
                                                <a-icon size="20" color="blue accent-4">tune</a-icon>
                                                <span class="mx-2 md-subtitle-2 grey--text" style="font-weight: 700;">{{ log.automation_condition_count }}
                                                    <a-icon size="16" color="grey" style="font-weight: 700;" class="u-icon-nudge-xs">arrow_forward</a-icon>
                                                </span>
                                                <template v-if="localLogType === 'validation'">
                                                    <a-icon size="20" color="pink">fact_check</a-icon>
                                                    <span class="mx-2 md-subtitle-2 grey--text" style="font-weight: 700;">{{ log.automation_validation_count }}</span>
                                                </template>
                                                <template v-if="localLogType === 'automation'">
                                                    <a-icon size="20" color="purple darken-1">settings_suggest</a-icon>
                                                    <span class="mx-2 md-subtitle-2 grey--text" style="font-weight: 700;">{{ log.automation_action_count }}</span>
                                                </template>
                                            </div>
                                        </a-flex>
                                        <a-flex shrink xs5 xl5>
                                            <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-2" v-if="log.automation_workflow" :title="log.automation_workflow.title">{{ log.automation_workflow.title | truncateText(60) }}</h2>
                                            <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-2" v-else>-</h2>
                                        </a-flex>
                                        <a-flex shrink xs2 xl2>
                                            <div class="c-log-status c-log-warning" style="width: 146px;" v-if="log.status === 'condition-failed'">Condition Not Met</div>
                                            <div class="c-log-status c-log-error" style="width: 134px;" v-if="log.status === 'validation-failed'">Validation Failed</div>
                                            <div class="c-log-status c-log-warning" style="width: 134px;" v-if="log.status === 'action-failed'">Action Failed</div>
                                            <div v-if="log.status === 'success'" class="c-log-status c-log-success" style="width: 80px;">Success</div>
                                        </a-flex>
                                        <a-flex shrink>
                                            <g-moment tag="h2" :value="log.updated_at" class="md-subtitle-1 font-weight-medium grey--text text--darken-2" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-sheet>
                            <a-divider :key="'divider-' + log.id"></a-divider>
                        </template>
                    </a-flex>
                </a-layout>
                <a-layout class="mx-0 c-sticky-paginate-parent u-elevation-custom-1 u-rounded-corners" v-if="automation_log_meta"  :class="{ 'pa-4': !localShowPaginateSection }">
                    <a-flex class="u-flex align-center py-0">
                        <div class="md-body-2 grey--text text--darken-2 font-weight-medium mr-2 d-inline-block">Showing {{ automation_log_meta.from }} - {{ automation_log_meta.to }} out of {{ automation_log_meta.total }} log{{ automation_log_meta.total > 1 ? 's' : '' }}</div>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex class="mr-16 pr-16 py-0 u-flex align-center justify-end" v-if="localShowPaginateSection">
                        <a-progress-circular v-if="pageLoading" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                        <div class="text-right">
                            <a-pagination
                                v-model="automation_log_meta.current_page"
                                :length="automation_log_meta.last_page"
                                :total-visible="10"
                                class="c-inventory-pagination py-1"
                                @input="localLogFetchPaginate()"
                            ></a-pagination>
                        </div>
                    </a-flex>
                </a-layout>
            </a-container>
            <template v-if="automation_log_list && automation_log_list.length === 0">
                <a-container container--fluid grid-list-xl class="mt-12">
                    <a-layout align-center justify-center>
                        <a-flex xs4>
                            <img :src="require('../../assets/images/automation/list-empty.svg')" alt="empty-logs" class="u-wfull" style="width: 70%; margin: 0px auto; display: block;">
                            <h2 class="md-body-1 font-weight-medium mb-0 mt-8 grey--text text--darken-3 text-center text-uppercase">No Logs found!</h2>
                            <p class="md-body-2 mb-0 mt-3 grey--text text--darken-1 text-center">There is no data to fetch right now.Try narrowing your filter or add new {{ localLogType === 'automation' ? 'automation' :  localLogType }}.</p>
                        </a-flex>
                    </a-layout>
                </a-container>
            </template>
        </template>
         <!-- Automation && Validation log Popup -->
         <a-dialog v-model="logsDialog" scrollable max-width="700">
            <SAutomationLogInfo
                :item="automation_log_item"
                :logType="localLogType"
                :isLoading="mixinIsLoading('workflow-log-show')"
                @close="localLogDialogClose"
            />
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PAutomationWorkflowBreadcrumb from './Partials/PartialAutomationValidationBreadcrumb'
import PAutomationValidationLogLoader from './Partials/PartialAutomationValidationLogLoader'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import { SAutomationLogInfo } from '@/config/config-shared-components'

export default {
    mixins: [
        mixinLoading
    ],
    components: {
        PAutomationValidationLogLoader,
        PAutomationWorkflowBreadcrumb,
        SAutomationLogInfo,
    },
    data() {
        return {
            showTitleEditIcon: true,
            localPageLoading: false,
            triggerDropdown: false,
            workflowDropdown: false,
            statusDropdown: false,
            timeDropdown: false,
            pageLoading: false,
            logsDialog: false,

            filterLoading: null,
            pageCount: null,

            logActionStatus: [
                { label: 'Condition Failed', value: 'condition-failed' },
                // { label: 'Action Failed', value: 'action-failed' },
                { label: 'Success', value: 'success' },
            ],
            logValidationStatus: [
                { label: 'Condition Failed', value: 'condition-failed' },
                { label: 'Validation Failed', value: 'validation-failed' },
                { label: 'Success', value: 'success' },
            ],
            logTimes: [
                { label: 'Last 1 hour', value: 1 },
                { label: 'Last 8 hour', value: 8 },
                { label: 'Last 24 hour', value: 24 },
                { label: 'Last 7 days', value: 7},
            ],

            selectedTriggers: [],
            selectedWorkflows: [],
            selectedStatus: [],

            selectedTime: { label: 'Last 1 hour', value: 1 },
            AUTOMATION_WORKFLOW_FILTERS: {
                FIEDS: 'fields[automation_workflows]',
                TYPE: 'filter[type]',
                COUNT: 'count',
            },
            automation_workflow_filters: {},
            automation_trigger_filters: {},
            AUTOMATION_TRIGGER_FILTERS: {
                TYPE: 'filter[type]',
                FIEDS: 'fields[automation_triggers]',
                SORT: 'sort',
                COUNT: 'count',
            },
            AUTOMATION_LOG_FILTERS: {
                FIEDS: 'fields[automation_logs]',
                INCLUDE: 'include',
                TYPE: 'filter[type]',
                TRIGGER_IDS: 'filter[automation_trigger_id]',
                CREATED_BY: 'fields[createdBy]',
                UPDATED_BY: 'fields[updatedBy]',
                TRIGGER: 'fields[automationTrigger]',
                WORKFLOW_IDS: 'filter[automation_workflow_id]',
                WORKFLOW: 'fields[automationWorkflow]',
                SORT: 'sort',
                STATUS: 'filter[status]',
                TIME: 'filter[created_at]',
                PAGE: 'page',
                COUNT: 'count',
            },
            automation_log_filters: {},
        }
    },

    mounted() {
        this.localIndex()
    },

    computed: {
        localIsTimeFilter () {
            return this.selectedTime && this.selectedTime.label !== 'All time'
        },

        localShowPaginateSection () {
            return this.automation_log_meta && this.automation_log_meta.current_page >= 1 && this.automation_log_meta.last_page !== 1 && this.automation_log_meta.total > 100
        },

        localIsWorkflowFilter () {
            return this.selectedWorkflows && this.selectedWorkflows.length
        },

        localIsStatusFilter() {
            return this.selectedStatus && this.selectedStatus.length
        },

        localIsTriggerFilter () {
            return !(this.selectedTriggers && this.selectedTriggers.length)
        },

        logStatus () {
            if (this.$route.params.type === 'automation') return this.logActionStatus

            return this.logValidationStatus
        },

        localLogType () {
            return this.$route.params.type
        },

        ...mapState('AutomationWorkflow', {
            automation_workflow_list: 'list',
        }),

        ...mapState('AutomationTrigger', {
            automation_trigger_list: 'list',
        }),

        ...mapState('AutomationLog', {
            automation_log_list: 'list',
            automation_log_item: 'item',
            automation_log_meta: 'meta',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async localIndex () {
            if (!this.$can('automation-workflows.index') || this.user_self && this.user_self.is_admin === 0 || this.user_self.scope === 'external') {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            this.localLoadAutomationWorkflowList()
            this.localLoadAutomationTriggerList()

            this.localPageLoading = true
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TIME] = 1
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.PAGE] = 1
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.COUNT] = 100
            await this.localGetAutomationLogList()
            this.localPageLoading = false
        },

        async localShowLogInfo (id) {
            await this.automation_log_clear_item()
            this.logsDialog = true
            this.localAutomationLogShow(id)
        },

        localLogDialogClose () {
            this.logsDialog = false
        },

        async localLogFetchPaginate () {
            if(this.pageCount == this.automation_log_meta.current_page) return
            this.pageLoading = true
            await this.$vuetify.goTo(0)
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.PAGE] = this.automation_log_meta.current_page
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.COUNT] = this.automation_log_meta.per_page
            await this.localGetAutomationLogList()
            this.pageCount = this.automation_log_meta.current_page
            this.pageLoading = false
        },

        async localAutomationLogStatusFilter (value) {
            this.filterLoading = 'status'
            if (!value) {
                this.selectedStatus = []
                this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.STATUS] = null
                await this.localGetAutomationLogList()
                this.triggerDropdown = false
                this.filterLoading = null
                return
            }
            if (this.selectedStatus && !this.selectedStatus.length) this.selectedStatus.push(value)
            else {
                const index = this.selectedStatus.findIndex(id => id === value)
                if (index !== -1) this.selectedStatus.splice(index, 1)
                else this.selectedStatus.push(value)
            }
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.STATUS] = this.selectedStatus
            await this.localGetAutomationLogList()
            this.filterLoading = null
        },

        async localLogAutomationTimefilter(time) {
            this.filterLoading = 'time'
            this.selectedTime.label = time.label
            this.selectedTime.value = time.value
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TIME] = time.value
            await this.localGetAutomationLogList()
            this.filterLoading = null
        },

        localLogGetStatusValue (value) {
            const log = this.logStatus.find(item => item.value === value)
            return log ? log.label : ''
        },

        async localAutomationTriggerFilter (trigger_id = null) {
            this.filterLoading = 'triggers'
            if (!trigger_id) {
                this.selectedTriggers = []
                this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TRIGGER_IDS] = null
                await this.localGetAutomationLogList()
                this.filterLoading = null
                return
            }

            if (this.selectedTriggers && !this.selectedTriggers.length) this.selectedTriggers.push(trigger_id)
            else {
                const index = this.selectedTriggers.findIndex(id => id === trigger_id)
                if (index !== -1) this.selectedTriggers.splice(index, 1)
                else this.selectedTriggers.push(trigger_id)
            }
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TRIGGER_IDS] = this.selectedTriggers
            await this.localGetAutomationLogList()
            this.filterLoading = null
        },

        localIsTriggerSelected (id) {
            return this.selectedTriggers.includes(id)
        },

        localTriggerTitle (id) {
            const trigger = this.automation_trigger_list.find(item => item.id === id)
            return trigger ? trigger.title : ''
        },

        localAutomationLogStatusIsSelected (value) {
            return this.selectedStatus.includes(value)
        },

        async localAutomationWorkflowFilter (workflow_id = null) {
            this.filterLoading = 'workflow'
            if (!workflow_id) {
                this.selectedWorkflows = []
                this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.WORKFLOW_IDS] = 'all'
                await this.localGetAutomationLogList()
                this.filterLoading = null
                return
            }

            if (this.selectedWorkflows && !this.selectedWorkflows.length) this.selectedWorkflows.push(workflow_id)
            else {
                const index = this.selectedWorkflows.findIndex(id => id === workflow_id)
                if (index !== -1) this.selectedWorkflows.splice(index, 1)
                else this.selectedWorkflows.push(workflow_id)
            }
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.WORKFLOW_IDS] = this.selectedWorkflows
            await this.localGetAutomationLogList()
            this.filterLoading = null
        },

        localIsWorkflowSelected (id) {
            return this.selectedWorkflows.includes(id)
        },

        localGetWorkflowTitle (id) {
            const workflow = this.automation_workflow_list.find(item => item.id === id)
            return workflow ? workflow.title : ''
        },

        async localGetAutomationLogList () {
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.SORT] = '-created_at'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TYPE] =  this.localLogType === 'automation' ? 'action' : this.localLogType
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.FIEDS] = 'id,resource_type,resource_id,status,automation_trigger_id,automation_workflow_id,updated_by_id,created_by_id,created_at,updated_at'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.INCLUDE] = 'automationTrigger,createdBy,updatedBy,automationWorkflow,automationLogEntries,automationActionCount,automationConditionCount,automationValidationCount,automationLogValidationFailedCount,automationLogConditionFailedCount'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.CREATED_BY] = 'id,name'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.UPDATED_BY] = 'id,name'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.WORKFLOW] = 'id,title'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TRIGGER] = 'id,title,slug'
            await this.automation_log_index(this.automation_log_filters)
        },

        async localAutomationLogShow (id) {
            this.mixinSetLoading('workflow-log-show')
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.SORT] = null
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.FIEDS] = 'id,stage_from,stage_to,resource_name,resource_type,resource_id,status,automation_trigger_id,automation_workflow_id,created_by_id,created_at'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.INCLUDE] = 'automationTrigger,createdBy,automationWorkflow,automationLogEntries'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.CREATED_BY] = 'id,name'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.UPDATED_BY] = 'id,name'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.WORKFLOW] = 'id,title,type'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TRIGGER] = 'id,title,slug'
            await this.automation_log_show({ id: id, params: this.automation_log_filters })
            this.mixinResetLoading('workflow-log-show')
        },

        async localLoadAutomationWorkflowList () {
            await this.automation_workflow_clear_list()
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.FIEDS] = 'id,title'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.TYPE] =  this.localLogType === 'automation' ? 'action' : this.localLogType
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.COUNT] =  99
            await this.automation_workflow_index(this.automation_workflow_filters)
        },

        async localLoadAutomationTriggerList () {
            await this.automation_trigger_clear_list()
            this.automation_trigger_filters[this.AUTOMATION_TRIGGER_FILTERS.FIEDS] = 'id,title,slug'
            this.automation_trigger_filters[this.AUTOMATION_TRIGGER_FILTERS.TYPE] = this.localLogType === 'automation' ? 'action' : this.localLogType
            this.automation_trigger_filters[this.AUTOMATION_TRIGGER_FILTERS.COUNT] =  100
            await this.automation_trigger_index(this.automation_trigger_filters)
        },

        ...mapActions('AutomationWorkflow', {
            automation_workflow_index: 'index',
            automation_workflow_clear_list: 'clear_list',
        }),

        ...mapActions('AutomationTrigger', {
            automation_trigger_index: 'index',
            automation_trigger_clear_list: 'clear_list',
        }),

        ...mapActions('AutomationLog', {
            automation_log_index: 'index',
            automation_log_show:'show',
            automation_log_clear_item : 'clear_item',
            automation_log_clear_list: 'clear_list',
        }),

    },
}
</script>

<style scoped>
.c-filter-status{
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.c-filter-success{
    background: #7aee80 !important;
    position: relative;
    top: 4px;
}
.c-filter-condition-failed,
.c-filter-action-failed {
    background: #fce49e !important;
    position: relative;
    top: 4px;
}
.c-filter-validation-failed {
    background: #E53935 !important;
    position: relative;
    top: 4px;
}
.c-log-error {
    background: #FFEBEE !important;
    color: #E53935;
}
.c-log-status {
    border-radius: 4px !important;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.26px;
    text-transform: uppercase;
    padding: 4px;
}
.c-log-success {
    background: #C8E6C9 !important;
    color: #43A047;
}
.c-log-warning {
    background: #FFECB3 !important;
    color: #FF8F00;
}
</style>

<template>
    <!-- <a-layout wrap align-center v-if="((projectNotesList && projectNotesList.length) && (customerNotesList && customerNotesList.length)) || canStore" :class="{ 'mb-2': canStore }"> -->
    <a-layout wrap align-center v-if="canCreate" :class="{ 'mb-2': canCreate }">
        <a-flex shrink pb-0 v-if="canCreate">
            <a-btn small dark color="blue" class="ma-0 elevation-0" @click="$emit('store')">
                Create New Note
            </a-btn>
        </a-flex>
        <a-spacer></a-spacer>
        <a-flex shrink py-0>
            <a-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <a-btn small icon color="neutral" class="ma-0 elevation-0" @click="$emit('refresh')" v-on="on">
                        <a-icon size="18" color="neutral darken-4">refresh</a-icon>
                    </a-btn>
                </template>
                <span>Reload</span>
            </a-tooltip>
        </a-flex>
        <a-flex shrink py-0 class="pl-0">
            <a-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <a-btn small icon color="neutral" class="ma-0 elevation-0" @click="$emit('toggle-all')" v-if="allExpanded" v-on="on" :disabled="projectNotesList.length === 0 && customerNotesList.length === 0">
                        <a-icon size="18" color="neutral darken-4">unfold_less</a-icon>
                    </a-btn>
                    <a-btn small icon color="neutral" class="ma-0 elevation-0" @click="$emit('toggle-all')" v-else v-on="on" :disabled="projectNotesList.length === 0 && customerNotesList.length === 0">
                        <a-icon size="18" color="neutral darken-4">unfold_more</a-icon>
                    </a-btn>
                </template>
                <span>Expand/Collapse Note</span>
            </a-tooltip>
        </a-flex>
    </a-layout>

</template>

<script>
export default {
    props: {
        projectNotesList: {
            type: Array
        },
        customerNotesList: {
            type: Array
        },
        canCreate: {
            type: Boolean
        },
        allExpanded: {
            type: Boolean
        },
    }
}
</script>

<style>

</style>

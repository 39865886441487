<template>
    <div>
        <template v-if="isLoading">
            <div class="u-overflow-hidden mt-5">
                <div class="u-flex u-wfull mt-6">
                    <div class="u-wfull">
                        <loader-template height="28" width="70%" class="u-rounded-corners-lg"></loader-template>
                    </div>
                    <div class="mr-10">
                        <loader-template height="32" width="140" class="u-rounded-corners-lg mt-2"></loader-template>
                    </div>
                    <loader-template height="28" width="40" class="u-rounded-corners-lg mt-2"></loader-template>
                </div>
            </div>
        </template>
        <template v-if="!isLoading">
            <a-container grid-list-xl container--fluid class="pa-0 mt-5">
                <a-layout align-center>
                    <a-flex>
                        <div class="u-rounded-corners u-overflow-hidden">
                            <a-container container--fluid grid-list-xl>
                                <a-layout align-center>
                                    <a-flex xs10 xl10 pa-0>
                                        <div class="u-flex-center-y">
                                            <p class="md-heading-5 grey--text text--darken-3 mb-0" :title="item.title">{{ !item.title ? 'Untitled Validation' : item.title |  truncateText(80)}}</p>
                                        </div>
                                    </a-flex>
                                    <a-flex>
                                        <div class="u-flex float-right">
                                            <template v-if="canUpdate">
                                                <a-btn height="32" :disabled="!isChanges || isWorkflowUpdate" color="#BDBDBD" small class="u-shadow-none float-right ma-0 mr-2 px-2 py-3 u-flex align-center white--text text--darken-2 u-rounded-corners" @click="$emit('cancel-changes')">
                                                    Cancel
                                                </a-btn>
                                                <a-btn height="32" :loading="isWorkflowUpdate" :disabled="!isChanges || isWorkflowUpdate" color="#222222" small class="c-changes-btn u-shadow-none float-right ma-0 mr-6 px-2 py-3 u-flex align-center white--text text--darken-2 u-rounded-corners" @click="$emit('update-workflow')">
                                                    Save
                                                </a-btn>
                                            </template>
                                            <a-tooltip bottom :disabled="item.automation_trigger_id && !validationCount" max-width="660">
                                                <template v-slot:activator="{ on }">
                                                    <g-toggle
                                                        v-on="on"
                                                        v-model="item.is_active"
                                                        :disabledColor="item.is_active ? '#4CAF50' : '#d0d0d0'"
                                                        @change="$emit('toggle-state', item.is_active ? 1 : 0)"
                                                        :disabled="!canUpdate || !item.automation_trigger_id || validationCount"
                                                        class="mt-1 float-right"
                                                        color="green darken-1"
                                                    ></g-toggle>
                                                </template>
                                                <div class="pa-2 md-body-2" v-if="!item.automation_trigger_id">
                                                    Choose a trigger to proceed with the Validation.
                                                </div>
                                                <div class="pa-2 md-body-2" v-else>
                                                    Configure a validation rules to activate this <br>validation.
                                                </div>
                                            </a-tooltip>
                                        </div>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </div>
                    </a-flex>
                </a-layout>
            </a-container>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        isLoading:{
            type: Boolean,
            default: true
        },
        canUpdate:{
            type: Boolean,
            default: true
        },
        isChanges: {
            type: Boolean,
            default: false
        },
        isWorkflowUpdate: {
            type: Boolean,
            default: true
        },
        validationCount: {
            type: Boolean,
        }
    },
}
</script>

<style scoped>
.c-changes-btn.v-btn.v-btn--disabled {
    opacity: 0.6;
    background-color: #222222 !important;
    color: #fff !important;
}
</style>
<template>
    <div>
        <a-menu
            max-width="292px"
            min-width="292px"
            max-height="552"
            transition="slide-y-transition"
            nudge-bottom="10"
            content-class="u-z22"
            bottom center left offset-y close-on-content-click
        >
            <template v-slot:activator="{ on, value }">
                <div :class="[ { 'profileFocus': value }, 'd-flex align-center justify-center rounded-circle align-center ml-2 mr-0' ]" style="width: 32px; height: 32px">
                    <div v-on="on" :class="[ 'u-cursor-pointer d-flex align-center' ]">
                        <g-avatar :item="user_self" :size="28" :right-space="false" ></g-avatar>
                    </div>
                </div>
            </template>

            <div class="white">
                <div class="pa-5">
                    <div class="u-flex-center-y" style="column-gap: 8px">
                        <g-avatar :item="user_self" :size="36" :right-space="false"></g-avatar>
                        <div>
                            <h4 class="u-font-14 font-weight-medium u-tracking grey--text text--darken-4" style="line-height: 16px;">{{ localGetUser('name') }}</h4>
                            <h4 class="u-font-14 font-weight-medium u-tracking grey--text text--darken-1" style="line-height: 16px;">{{ localGetUser('email') }}</h4>
                        </div>
                    </div>
                    <div class="blue-grey lighten-5 mt-3 text-center u-rounded-corners-full pa-2">
                        <h4 class="u-font-14 u-font-weight-semibold u-tracking blue-grey--text" style="line-height: 16px;">
                            {{ localGetUser('role.name') }}
                        </h4>
                    </div>
                </div>

                <a-divider class="grey lighten-3 mb-3"></a-divider>

                <div class="c-profile-sublinks pb-3">
                    <div v-for="menu in menuList" :key="`${menu.label}`">
                        <router-link
                            v-if="menu.type === 'link' && !menu.externalLink"
                            :to="{ name: menu.name }"
                            :target="menu.target"
                            class="c-hover-state u-relative u-cursor-pointer u-rounded-corners u-flex-center-y px-2 mx-3"
                            style="height: 32px;"
                            v-ripple
                        >
                            <shared-menu-active-state v-if="menu.isActive" />
                            <shared-profile-menu-item :menu="menu" />
                        </router-link>
                        <div
                            v-if="menu.externalLink"
                            class="c-hover-state u-relative u-cursor-pointer u-rounded-corners u-flex-center-y px-2 mx-3"
                            style="height: 32px;"
                            v-ripple
                            @click="localNavigate(menu)"
                        >
                            <shared-menu-active-state v-if="menu.isActive" />
                            <shared-profile-menu-item :menu="menu" />
                        </div>
                        <a-divider v-if="menu.type === 'divider'" class="grey lighten-3 my-3" />
                        <div
                            v-if="menu.type === 'button'"
                            class="c-hover-state u-relative u-cursor-pointer u-rounded-corners u-flex-center-y px-2 mx-3"
                            style="height: 32px;"
                            v-ripple
                            @click="menu.name()"
                        >
                            <shared-menu-active-state v-if="menu.isActive" />
                            <shared-profile-menu-item :menu="menu" />
                        </div>
                    </div>
                </div>
            </div>
        </a-menu>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import SharedMenuActiveState from './SharedMenuActiveState.vue'
import SharedProfileMenuItem from './SharedProfileMenuItem.vue'

export default {
	components: { SharedMenuActiveState, SharedProfileMenuItem },

    data () {
        return {
            menuList: [],
            whatsNewLink: 'https://www.notion.so/What-s-new-42338792f7594ebba97b9da58639a8b1'
        }
    },

    watch: {
        '$route.name': {
            handler () {
                this.localMenuItems()
            },
            immediate: true
        },
    },

    computed: {
        localUserIsExternal () {
            return _.get(this.user_self, 'scope') === 'external'
        },

        localUserIsAdmin () {
            return _.get(this.user_self, 'scope') === 'internal' &&
                _.get(this.user_self, 'is_admin') === 1
        },

        ...mapState('User', {
            user_self: 'self',
        })
    },

    methods: {
        async localLogout () {
            this.$router.replace({ name: 'logout' })
        },

        localNavigate (menu) {
            if (menu.externalLink) {
                window.open(menu.name, menu.target)
                return true
            }

            if (menu.type === 'link' && menu.target === '_blank') {
                const route = this.$router.resolve({ name: menu.name })
                window.open(route.href, menu.target)
                return true
            }

            this.$router.push({ name: menu.name })
        },

        localGetUser (field) {
            return _.get(this.user_self, field)
        },

        localMenuItems () {
            if (this.localUserIsExternal) {
                this.menuList = [
                    { label: 'Profile', name: 'settings-profile', icon: 'person', isActive: this.$router.route.isSettingsProfile(), type: 'link', target: null, outlined: true, externalLink: false },
                    { label: 'Logout', name: this.localLogout, icon: 'logout', isActive: false, type: 'button', target: null, outlined: true, externalLink: false },
                ]

                return true
            }

            const list = [
                { label: 'Profile', name: 'settings-profile', icon: 'person', isActive: this.$router.route.isSettingsProfile(), type: 'link', target: null, outlined: true, externalLink: false, show: true },
                { label: 'Team Members', name: 'teams-enabled', icon: 'people_alt', isActive: this.$router.route.isTeamsEnabled() || this.$router.route.isTeams(), type: 'link', target: null, outlined: true, externalLink: false, show: this.localUserIsAdmin },
                { label: 'External Members', name: 'external-members-enabled', icon: 'account_box', isActive: this.$router.route.isExternalMembersEnabled() || this.$router.route.isExternalMembers(), type: 'link', target: null, outlined: true, externalLink: false, show: this.localUserIsAdmin },
                { label: 'Divider-1', name: 'divider-1', icon: null, isActive: false, type: 'divider', target: null, outlined: true, externalLink: false, show: true },
                { label: 'What\'s new', name: this.whatsNewLink, icon: 'redeem', isActive: false, type: 'link', target: '_blank', outlined: true, externalLink: true, show: true },
                { label: 'Support', name: 'support', icon: 'help_outline', isActive: this.$router.route.isSupport(), type: 'link', target: '_blank', outlined: true, externalLink: false, show: true },
                { label: 'Settings', name: 'settings', icon: 'settings', isActive: this.$router.route.isSettings(), type: 'link', target: null, outlined: true, externalLink: false, show: true },
                { label: 'Divider-2', name: 'divider-2', icon: null, isActive: false, type: 'divider', target: null, outlined: true, externalLink: false, show: true },
                { label: 'Logout', name: this.localLogout, icon: 'logout', isActive: false, type: 'button', target: null, outlined: true, externalLink: false, show: true },
            ]
            this.menuList = list.filter(item => item.show)
        },
    }
}
</script>

<style lang="scss" scoped>
    .profileFocus {
        background-color: rgb(102, 98, 98);
        opacity: 0.8;
    }

    .c-profile-sublinks {
        &__link {
            &:hover {
                background-color: #ccc;
            }
        }
    }
</style>

<template>
    <a-sheet class="transparent">
        <div class="mb-6">
            <span class="md-body-1 body--text text--lighten-1 mb-2 d-inline-block font-weight-medium">Title</span>
            <v-text-field
                v-model="moduleItem.original.title"
                placeholder="Eg: Quarter One"
                background-color="white"
                class="pa-0 mb-2 u-shadow u-border-transparent u-rounded-corners-lg"
                :class="[{ 'c-wiggle-short u-border-error': local_get_error('summary_title') }]"
                :disabled="!canUpdate || isLoading"
                @input="$emit('update', 'Summary', 'title', moduleItem.original.title)"
                solo flat hide-details autofocus
            >
                <template #append>
                    <a-progress-circular v-if="isLoading" width="2" size="14" color="grey darken-1" indeterminate></a-progress-circular>
                </template>
            </v-text-field>
            <g-field-text :has-error="local_get_error('summary_title')">
                <template #error-msg>{{ local_get_error('summary_title', 'message') }}</template>
                <template #info>Enter the title of the tile.</template>
            </g-field-text>
        </div>
        <div>
            <span class="md-body-1 body--text text--lighten-1 mb-2 d-inline-block font-weight-medium">Description (Optional)</span>
            <a-textarea
                v-model="moduleItem.original.description"
                placeholder="More details about the tile goes here..."
                background-color="white"
                class="pa-0 mb-2 pb-2 u-shadow u-rounded-corners-lg"
                :class="[{ 'c-wiggle-short u-border-error': local_get_error('summary_description') }]"
                rows="7"
                :disabled="!canUpdate || isLoading"
                @input="$emit('update', 'Summary', 'description', moduleItem.original.description)"
                solo flat hide-details no-resize
            >
                <template #append>
                    <a-progress-circular v-if="isLoading" width="2" size="14" color="grey darken-1" indeterminate></a-progress-circular>
                </template>
            </a-textarea>
            <g-field-text :has-error="local_get_error('summary_description')">
                <template #error-msg>{{ local_get_error('summary_description', 'message') }}</template>
                <template #info>Enter the name of the tile.</template>
            </g-field-text>
        </div>
    </a-sheet>
</template>

<script>
export default {
    props: {
        moduleItem: {
            type: Object,
            required: true
        },
        configErrors: {
            type: Array,
        },
        canUpdate: {
            type: Boolean | Number,
            default: true
        },
        isLoading: {
            type: Boolean | Number,
            default: false
        },
    },

    methods: {
        local_get_error (slug, field = 'message') {
            if (!slug) return null
            const item = this.configErrors.find(item => item.slug === slug)
            if (!slug.includes('filters')) return (item && item[field]) ?? null

            return (item && item[field] && item[field][field]) ?? null
        },
    }
}
</script>

<style>

</style>

<template>
    <v-list-item-icon v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </v-list-item-icon>
</template>

<script>
import { VListItemIcon } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VListItemIcon }
}
</script>

<template>
	<a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="py-6 pt-2 px-0 mb-10">
            <g-page-header
                title="Notification Preferences"
                subtitle="Manage all notification preferences."
                :loading="loading"
                icon-outlined
                icon="notifications_active"
            ></g-page-header>
            <div class="u-relative c-notification-tab-slider">
                <v-tabs color="indigo darken-1" background-color="transparent">
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab @change="local_show_field_preference('email')">
                        <v-icon class="pr-2">email</v-icon>
                        <span class="text-capitalize">Email</span>
                    </v-tab>
                    <v-tab @change="local_show_field_preference('slack')">
                        <div class="u-flex align-center justify-center">
                            <div style="width: 32px; border-radius: 0.75rem;">
                                <img src="../../assets/images/apps/slack/icon.svg" alt="Slack" style="display: block; max-width: 20px; max-height: 20px;">
                            </div>
                            <span class="text-capitalize">Slack</span>
                        </div>
                    </v-tab>
                    <v-tab @change="local_show_field_preference('microsoft_team')">
                        <div style="width: 32px; border-radius: 0.75rem;">
                            <img :src="require('../../assets/images/apps/ms-teams/microsoft-teams-logo.svg')" alt="MicrosoftTeam" style="display: block; max-width: 20px; max-height: 20px;">
                        </div>
                        <span class="text-capitalize">Microsoft Teams</span>
                    </v-tab>
                </v-tabs>
                <div class="u-absolute u-wfull" style="border-bottom: 2px solid #E0E0E0; bottom: 0px;"></div>
            </div>
            <template v-if="local_preference_permission()">
                <a-card class="u-elevation-custom-1 pa-0 mt-8 mb-8 white u-rounded-corners u-relative" v-for="(notification_preference, header) in local_render" :key="'notification_preference'+header">
                    <a-layout wrap align-center class="ma-0 pa-5 px-6" v-if="notification_preference && notification_preference[0]">
                        <a-flex xs5 xl6 class="pa-0">
                            <h2 class="md-subtitle-1 font-weight-bold indigo--text text--darken-2">{{ notification_preference[0].name }}</h2>
                        </a-flex>
                        <a-flex xs2 class="pa-0">
                            <h2 class="md-subtitle-1 font-weight-bold grey--text text--darken-2 text-right">Actor</h2>
                        </a-flex>
                        <a-flex xs2 class="pa-0">
                            <h2 class="md-subtitle-1 font-weight-bold grey--text text--darken-2 text-right">Recipients</h2>
                        </a-flex>
                        <a-flex xs3 xl2 class="pa-0">
                            <h2 class="md-subtitle-1 font-weight-bold grey--text text--darken-2 text-right">Preference</h2>
                        </a-flex>
                    </a-layout>
                    <a-divider class="grey lighten-3"></a-divider>
                    <template v-for="(preference,index) in notification_preference">
                        <a-layout wrap align-center class="ma-0 pa-3 px-6" :key="preference.id">
                            <a-flex xs5 xl6 class="pa-0">
                                <p class="md-subtitle-2 font-weight-regular mb-0 grey--text text--darken-2 ma-0">{{ preference.description }}</p>
                            </a-flex>
                            <a-flex xs2 class="pa-0">
                                <p class="md-subtitle-2 font-weight-regular mb-0 grey--text text--darken-2 ma-0 text-right text-capitalize">{{ preference.scope_actor }}</p>
                            </a-flex>
                            <a-flex xs2 class="pa-0">
                                <p class="md-subtitle-2 font-weight-regular mb-0 grey--text text--darken-2 ma-0 text-right text-capitalize">{{ preference.recipient }}</p>
                            </a-flex>
                            <a-flex xs3 xl2 class="pa-0">
                                <a-menu bottom offset-y transition="slide-y-transition" v-if="$can('preferences_organization.update')" :disabled="local_loading">
                                    <template v-slot:activator="{ on, value }">
                                        <div v-on="on" class="u-cursor-pointer d-inline-flex align-center grey lighten-4 pl-4 pr-2 py-1 u-rounded-corners" style="min-width: 180px;float: right;">
                                            <span class="md-body-2 grey--text text--darken-4 text-capitalize">{{ preference.effective_value }}</span>
                                            <a-spacer></a-spacer>
                                            <a-progress-circular v-if="local_loading_get(preference.id)" color="grey darken-2" class="ml-1" size="22" width="2" :indeterminate="true"></a-progress-circular>
                                            <a-icon size="22" class="ml-1" color="grey darken-4"  v-else >arrow_drop_{{ value ? 'up' : 'down' }}</a-icon>
                                        </div>
                                    </template>
                                    <a-list class="u-list-condensed">
                                        <template v-for="option in preference.options">
                                            <a-list-item :key="option.value" @click="local_update(preference, option, preference.preferences, preference.effective_value)">
                                                <a-list-item-title class="grey--text text--darken-2">{{ option.label }}</a-list-item-title>
                                            </a-list-item>
                                        </template>
                                    </a-list>
                                </a-menu>
                                <template v-else>
                                    <div class="d-inline-flex align-center grey lighten-4 pl-4 pr-2 py-1 u-rounded-corners" style="float: right;">
                                        <span class="md-body-2 grey--text text--darken-4 text-capitalize">{{ preference.effective_value }}</span>
                                    </div>
                                </template>
                            </a-flex>
                        </a-layout>
                        <a-divider class="grey lighten-3" :key="index"></a-divider>
                    </template>
                </a-card>
            </template>
               <!-- Loading -->
            <a-responsive class="mt-8" v-if="local_loading && local_preference_permission()">
                <a-card class="u-elevation-custom-1 pa-6 mb-8 white u-rounded-corners u-flex flex-column" style="row-gap: 24px">
                    <div class="u-flex" v-for="n in 3" :key="n" style="column-gap: 24px;">
                        <loader-template min-width="900" height="40" class="u-rounded-corners"></loader-template>
                        <loader-template width="100%" height="40" class="u-rounded-corners"></loader-template>
                        <loader-template width="100%" height="40" class="u-rounded-corners"></loader-template>
                        <loader-template width="100%" height="40" class="u-rounded-corners"></loader-template>
                    </div>
                </a-card>
                <a-card class="u-elevation-custom-1 pa-6 mb-8 white u-rounded-corners u-flex flex-column" style="row-gap: 24px">
                    <div class="u-flex" v-for="n in 4" :key="n" style="column-gap: 24px;">
                        <loader-template min-width="900" height="40" class="u-rounded-corners"></loader-template>
                        <loader-template width="100%" height="40" class="u-rounded-corners"></loader-template>
                        <loader-template width="100%" height="40" class="u-rounded-corners"></loader-template>
                        <loader-template width="100%" height="40" class="u-rounded-corners"></loader-template>
                    </div>
                </a-card>
            </a-responsive>

            <div v-if="!local_preference_permission()" class="mt-16">
                <div v-if="local_app_field && !local_app_field['slack'] && selected_integration_preference === 'slack'" class="u-flex flex-column justify-center align-center">
                    <div class="mb-6">
                        <img src="../../assets/images/apps/slack/slack-logo-mono.svg" alt="Slack" style="display: block; max-width: 64px; max-height: 64px;">
                    </div>
                    <p class="md-heading-6 mb-3 grey--text text--darken-3" style="font-weight: 600">SLACK IS NOT INSTALLED!</p>
                    <p class="md-subtitle-1 grey--text text--darken-1 text-center" style="max-width: 460px">You can choose to setup Slack to receive project updates & notifications right away.</p>
                    <a-btn depressed color="grey darken-4" class="white--text md-button" :to="{name: 'appstore'}">
                        <a-icon color="white" class="mr-2">login</a-icon>
                        <span>Go to app store</span>
                    </a-btn>
                </div>
                <div v-if="local_app_field && !local_app_field['microsoft_team'] && selected_integration_preference === 'microsoft_team'" class="u-flex flex-column justify-center align-center">
                    <div class="mb-6">
                        <img src="../../assets/images/apps/ms-teams/microsoft-teams-logo-mono.svg" alt="Microsoft teams" style="display: block; max-width: 64px; max-height: 64px;">
                    </div>
                    <p class="md-heading-6 mb-3 grey--text text--darken-3" style="font-weight: 600">MICROSOFT TEAMS IS NOT INSTALLED!</p>
                    <p class="md-subtitle-1 grey--text text--darken-1 text-center" style="max-width: 460px">You can choose to setup Microsoft Teams to receive project updates & notifications right away.</p>
                    <a-btn depressed color="grey darken-4" class="white--text md-button" :to="{name: 'appstore'}">
                        <a-icon color="white" class="mr-2">login</a-icon>
                        <span>Go to app store</span>
                    </a-btn>
                </div>
            </div>

            <!-- No data found message -->
            <a-responsive v-if="!local_loading && (notification_preference_list && notification_preference_list.length === 0)" class="mt-12">
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
            </a-responsive>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            notification_preference_filter: {},
            filters: {
                NOTIFICATION_FIELDS: 'fields[notification_triggers]',
                SERVICE_FIELDS: 'filter[service]',
            },
            local_loading: false,
            loading_item_id: null,
            default_field: 'email',
            local_app_field: {
                slack: true,
                microsoft_team: true
            },
            selected_integration_preference: null
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        local_render() {
            return _.groupBy(this.notification_preference_list, 'group')
        },

        ...mapState('NotificationPreference', {
            notification_preference_list: 'list',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Slack', {
            slack_status: 'status',
        }),

        ...mapState('MicrosoftTeam', {
            microsoft_teams_status: 'status',
        }),
    },
    methods: {
        async local_index() {
            this.local_show_field_preference(this.default_field)
            this.local_get_integration_status()
        },

        async local_show_field_preference (field) {
            this.selected_integration_preference = field
            this.local_loading = true
            await this.notification_preference_clear()
            this.notification_preference_filter[this.filters.NOTIFICATION_FIELDS] = 'id,key,group,name,description,scope_actor,recipient,options,value_default'
            this.notification_preference_filter[this.filters.SERVICE_FIELDS] = field === 'microsoft_team' ? 'microsoft-teams' : field
            await this.notification_preference_index(this.notification_preference_filter)
            this.local_loading = false
        },

        async local_get_integration_status () {
            await this.slack_check_status()
            await this.microsoft_teams_check_status()
            this.local_app_field['slack'] = this.slack_status['slack_connection_status']
            this.local_app_field['microsoft_team'] = this.microsoft_teams_status['microsoft_team_connection_status']
        },

        local_preference_permission () {
            if (this.selected_integration_preference === 'email') return true
            return this.local_app_field[this.selected_integration_preference]
        },

        async local_update(item, option, preferences, effective_value) {
            if(option.value ==  effective_value) return
            this.loading_item_id = item.id
            const preference_item = {}
            preference_item.key = 'organization.'+item.key
            preference_item.value = option.value
            preference_item.values = option
            preference_item.resource_id = item.id
            preference_item.resource_type = 'Organization'
            if(preferences) {
                preference_item.id = preferences.id
                await this.preference_update(preference_item)
            }else {
                await this.preference_upsert_store(preference_item)
            }
            await this.notification_preference_show({id: item.id })
            this.loading_item_id = null

        },

        local_loading_get(id) {
            return this.loading && this.loading_item_id == id
        },

        ...mapActions('NotificationPreference', {
            notification_preference_index: 'index',
            notification_preference_clear: 'clear',
            notification_preference_show: 'show',
        }),

        ...mapActions('Preference', {
            preference_update: 'update',
            preference_upsert_store: 'upsert_store',
        }),

        ...mapActions('User', {
            user_me: 'me',
        }),

        ...mapActions('Slack', {
            slack_check_status: 'status'
        }),

        ...mapActions('MicrosoftTeam', {
            microsoft_teams_check_status: 'status'
        })
    },
}
</script>

<template>
    <v-sheet v-bind="$attrs" v-on="$listeners" class="c-sheet">
        <slot></slot>
        <slot name="loading-bar" v-if="loadingBar">
            <div :style="[bottomLoading ? { bottom: '0px' } : { top: '0px' }]" class="u-absolute u-wfull" style="left: 0px;">
                <a-progress-linear
                    :color="loadingColor"
                    height="2"
                    indeterminate
                ></a-progress-linear>
            </div>
        </slot>
    </v-sheet>
</template>

<script>
import { VSheet } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    props: {
        loadingBar: {
            type: Boolean,
            default: false
        },
        loadingColor: {
            type: String,
            default: 'deep-purple'
        },
        bottomLoading: {
            type: Boolean,
            default: false
        },
    },
    components: { VSheet },
}
</script>

<style>

</style>

import { render, staticRenderFns } from "./BillingUsage.vue?vue&type=template&id=3bd81951&scoped=true"
import script from "./BillingUsage.vue?vue&type=script&lang=js"
export * from "./BillingUsage.vue?vue&type=script&lang=js"
import style0 from "./BillingUsage.vue?vue&type=style&index=0&id=3bd81951&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bd81951",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VFadeTransition,VScrollYTransition,VSlideXReverseTransition,VSlideXTransition})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})

import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

const EnterHandler = Extension.create({
    name: 'enter_handler',

    addProseMirrorPlugins () {
        return [
            new Plugin({
                key: new PluginKey('enter_handler'),
                props: {
                    handleKeyDown: (view, event) => {
                        if (event.key === 'Enter' && event.ctrlKey) {
                            return event
                        }

                        return false
                    },
                },
            }),
        ]
    }
})

export default EnterHandler

<template>
    <a-tooltip top content-class="c-tooltip-pointer c-tooltip-pointer--bottom u-rounded-corners-lg">
        <template v-slot:activator="{ on }">
            <a-sheet
                v-on="on"
                :width="history.daysPercent + '%'"
                :color="localGetStageColor"
                :style="[!isHighlighted ? { opacity: '0.3 !important' } : {}]"
                @mouseenter="localSimilarHistoryIds(history.stageToId)"
                @mouseleave="$emit('hoveredStage', [])"
                height="24"
                style="flex: 1 1 auto"
                class="u-rounded-corners u-wfull"
            ></a-sheet>
        </template>
        <div class="py-2 u-rounded-corners-lg">
            <template v-if="localIsPlaceholder">
                <p class="md-body-2 mb-2 font-weight-medium white--text">
                    Queue - Initial Stage
                </p>
                <div class="u-flex" style="column-gap: 4px">
                    <span class="md-body-2 font-weight-medium grey--text text--lighten-1">
                        <template v-if="history.days > 0">
                            {{ history.days }} {{ history.days > 1 ? 'Days' : 'Day' }}
                        </template>
                        <template v-if="history.days === 0">Today</template>
                    </span>
                    <span class="grey--text text--lighten-1">|</span>
                    <span class="md-body-2 font-weight-medium grey--text text--lighten-1">
                        {{ localFormatDate(history.days, history.createdAt) }}
                    </span>
                </div>
            </template>

            <template v-else>
                <p class="mb-2 md-body-1 u-font-weight-semibold white--text">
                    <template v-if="history.statusTo === 'completed'">
                        {{ history.result ? ('Completed - ' + history.result.value) : history.stageTo | truncateText(50) }}
                    </template>
                    <span v-if="history.statusTo === 'archived'" class="text-capitalize">
                        {{ history.statusTo | truncateText(50) }} - {{ localGetSimilarStageTotalDays }} {{ localGetSimilarStageTotalDays > 1 ? ' Days' : ' Day' }}
                    </span>
                    <template v-else>
                        {{ history.stageTo | truncateText(50) }} - {{ localGetSimilarStageTotalDays }} {{ localGetSimilarStageTotalDays > 1 ? ' Days' : ' Day' }}
                    </template>
                </p>

                <template v-for="similarHistory in localGetSimilarStages">
                    <div :key="similarHistory.id">
                        <div class="u-flex mb-1" style="column-gap: 4px">
                            <span :class="[localGetHoverTextColor(similarHistory.id)]" class="md-body-2 font-weight-medium">
                                <template v-if="similarHistory.days > 0">
                                    {{ similarHistory.days }} {{ similarHistory.days > 1 ? 'Days' : 'Day' }}
                                </template>
                                <template v-if="similarHistory.days === 0">Today</template>
                            </span>
                            <span :class="[localGetHoverTextColor(similarHistory.id)]">|</span>
                            <span :class="[localGetHoverTextColor(similarHistory.id)]" class="md-body-2 font-weight-medium">
                                {{ localFormatDate(similarHistory.days, similarHistory.createdAt) }}
                            </span>
                            <span :class="[localGetHoverTextColor(similarHistory.id)]">|</span>
                            <span :class="[localGetHoverTextColor(similarHistory.id)]" class="md-body-2 font-weight-medium">
                                {{ similarHistory.createdBy ? similarHistory.createdBy.name : 'System' | truncateText(50) }}
                            </span>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </a-tooltip>
</template>

<script>
import { diffSimple } from '@/helpers/helper-date-filter'

export default {
    inheritAttrs: false,

    props: {
        history: {
            type: Object
        },
        status: {
            type: String | Object,
        },
        historyList: {
            type: Array,
        },
        isHighlighted: {
            type: Boolean,
        },
    },

    computed: {
        localIsPlaceholder () {
            return this.history?._type === 'placeholder'
        },

        localGetStageColor () {
            switch (this.status) {
                case 'completed': return 'green darken-1'
                case 'active': return 'indigo lighten-2'
                case 'queue': return 'indigo lighten-3'
                case 'archived':
                default: return 'grey darken-3'
            }
        },

        localSimilarStagesLength () {
            return _.size(this.localGetSimilarStages)
        },

        localGetSimilarStages () {
            if (this.history.statusTo === 'archived') {
                return this.historyList.filter(history => history.statusTo === 'archived')
            }

            return this.historyList.filter(history => history.stageToId === this.history.stageToId)
        },

        localGetSimilarStageTotalDays () {
            if (this.history.statusTo === 'archived') {
                return this.historyList.filter(history => history.statusTo === 'archived').reduce((acc, c) => acc = acc + c.days, 0)
            }

            return this.historyList.filter(history => history.stageToId === this.history.stageToId).reduce((acc, c) => acc = acc + c.days, 0)
        }
    },

    methods: {
        localFormatDate (days, date) {
            if (days <= 1) return diffSimple(date)

            const endDate = moment(date).add(days, 'days')
            return diffSimple(date) + ' - ' + diffSimple(endDate)
        },

        localSimilarHistoryIds (stageToId) {
            const list = this.historyList.filter(i => i.stageToId === stageToId).map(i => i.stageToId)
            if (_.size(list) > 1) this.$emit('hoveredStage', list)
        },

        localGetHoverTextColor (similarHistoryId) {
            return (similarHistoryId === this.history.id) && (this.localSimilarStagesLength > 1) ?
                'white--text font-weight-medium' :
                'grey--text text--lighten-1'
        },
    }
}
</script>

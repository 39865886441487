const api_endpoint = '/automation-conditions-entities';

export default {
    namespaced: true,

    state: {
        list: [],
        response: {},
        meta: {},
        defaults: {
            list: [],
            response: {},
            meta: {},
        },
    },

    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        THROW_RESPONSE (state, payload) {
            state.response = payload
        },
    },

    actions: {
        async index ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                const response = await axios.get(api_endpoint, { params: payload })
                commit('UPDATE_LIST', response.data)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async throw_success ({ commit }) {
            commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            this.dispatch('Interface/loader_stop')
        },

        async throw_error ({ commit }, error) {
            this.dispatch('Interface/loader_stop')
            commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
        },

        async loader_start () {
            this.dispatch('Interface/loader_start')
        },

        async clear_list ({ commit }) {
            commit('UPDATE_LIST', [])
        },

        async clear_response ({ commit }) {
            commit('THROW_RESPONSE', [])
        },
    },
}

<template>
    <a-card class="u-relative white u-rounded-corners-lg">
        <div class="text-center pa-6">
            <a-avatar size="52" color="amber lighten-1">
                <a-avatar size="50" color="white">
                    <a-avatar size="48" color="amber lighten-1">
                        <a-icon size="24" color="white">info</a-icon>
                    </a-avatar>
                </a-avatar>
            </a-avatar>

            <div class="mt-3">
                <h3 class="md-heading-6 grey--text text--darken-3 mb-3">Are you sure you want to <br/> remove all the collaborators?</h3>
                <span class="md-subtitle-1 grey--text text--darken-1">This action cannot be undone.</span>
            </div>

            <div class="u-flex-center mt-6">
                <a-btn class="amber lighten-1 white--text u-rounded-corners mr-3" :loading="loading" depressed @click="$emit('confirm')">Yes, Remove</a-btn>
                <a-btn class="grey lighten-3 grey--text text--darken-1 u-rounded-corners" depressed @click="$emit('close')">Cancel</a-btn>
            </div>
        </div>
    </a-card>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean
        }
    }
}
</script>

const api_endpoint = '/success-criteria';
import { Base64 } from 'js-base64';
export default {
    namespaced: true,
    state: {
        list: [],
        global_list: [],
        item: {},
        meta: {},
        filters: {
            title: null,
            status: 'all',
            assignee: 'all',
            importance: 'all',
            size: 'all',
            usecase_id: null,
            page: 1,
            count: 25,
            sort_field: 'code',
            sort_direction: 'asc'
        },
        errors: { index: [], store: [], update: [], destroy: [], },
        response: [],
        defaults: {
            list: [],
            global_list: [],
            item: {},
            meta: {},
            filters: {
                title: null,
                status: 'all',
                assignee: 'all',
                importance: 'all',
                size: 'all',
                usecase_id: null,
                page: 1,
                count: 25,
                sort_field: 'code',
                sort_direction: 'asc'
            },
            errors: { index: [], store: [], update: [], destroy: [], },
            response: [],
        },
        form_mode: 'add',
    },
    mutations: {
        GLOBAL_UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = Object.assign(state.item, item);
        },
        UPDATE_SELECTED_LIST_ITEM(state, payload) {
            payload.data.forEach(item => {
                const index = _.findIndex(state.list, { 'id': item.id });
                if (index !== -1) Object.assign(state.list[index], item)
                else {
                    if (payload.mode === 'bulk-mode-list-update') state.list.push(item)
                }
            })
        },
        UPSERT_LIST_ITEM(state, payload) {
            const index = _.findIndex(state.list, { 'id': payload.id });
            const list_item = state.list[index]
            if (index !== -1) Object.assign(list_item, payload)
            else state.list.push(payload)
        },
        UPSERT_BULK_LIST_ITEM(state, payload) {
            if (payload && !payload.bulk_type) return state.list.push(...payload)
            payload.ids.forEach(id => {
                const index = _.findIndex(state.list, { id });
                if (index !== -1) Object.assign( state.list[index], { loading_status: payload.loading_status })
            })
        },
        UPSERT_ITEM(state, payload) {
            if (payload.payload_type) delete payload.payload_type
            Object.assign(state.item, payload)
            // if (state.item && state.item.id) Object.assign(state.item, payload)
            // if (payload.payload_type && payload.payload_type === 'update-item') {
            //     delete payload.payload_type
            //     state.item = payload
            // }
        },
        CLEAR_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_LOADED_ITEM(state, item) {
            let index = _.findIndex(state.list, { 'id': item.id });
            state.list[index] = Object.assign(state.list[index], item);
        },
        UPDATE_ITEM_BUSINESS_DRIVERS(state, payload) {
            state.item.business_drivers = payload
        },
        UPDATE_LIST_ITEM_BUSINESS_DRIVERS(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index].business_drivers = payload.item;
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload.item;
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_LIST(state, items) {
            const newList = items.filter(sc => {
                const index = state.list.findIndex(i => i.id === sc.id)
                return index === -1 ? sc : null
            })

            state.list = _.union(state.list, newList)
        },
        ADD_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REMOVE_SELECTED(state, ids) {
            ids.forEach(id => {
                const index = _.findIndex(state.list, { id })
                if (index !== -1) state.list.splice(index, 1)
            })
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
        UPSERT_ERRORS(state, payload) {
            if (payload.reset) return state.errors = payload.data
            state.errors[payload.type] = payload;
        },
    },
    actions: {
        async global_index ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data, meta } } = await axios.get(api_endpoint, { params: payload.params })
                if (payload && ['update-list-selected-item', 'bulk-mode-list-update'].includes(payload.mode)) {
                    commit('UPDATE_SELECTED_LIST_ITEM', { data, mode: payload.mode })
                } if (payload && payload.mode === 'pagination-index') {
                    commit('APPEND_LIST', data)
                } else {
                    commit('UPDATE_LIST', data)
                    commit('UPDATE_META', meta)
                }
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
                commit('UPSERT_ERRORS', { text: 'Done', status: 'success', type: 'index' })
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
                commit('UPSERT_ERRORS', { text: error.response.data.message, status: 'error', type: 'index' })
            }
        },

        index(context, payload) {
            this.dispatch('Interface/loader_start')
            let query_filters = _.pickBy(context.state.filters, function (item) {
                return item != '';
            });
            return axios.get(api_endpoint, { params: payload.params })
                .then((response) => {
                    if (query_filters.page == 1) {
                        context.commit('UPDATE_LIST', response.data.data);
                    }
                    else {
                        context.commit('APPEND_LIST', response.data.data);
                    }
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                    context.commit('UPSERT_ERRORS', { text: 'Done', status: 'success', type: 'index' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                    context.commit('UPSERT_ERRORS', { text: error.response.data.message, status: 'error', type: 'index' });
                });
        },

        enabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/enabled')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        disabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/disabled')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id, { params: payload.params })
                .then((response) => {
                    if (payload && payload.mode === 'add')  context.commit('ADD_ITEM', response.data.data);
                    else if (payload && ['upsert-list-item'].includes(payload.mode)) context.commit('UPSERT_LIST_ITEM', response.data.data)
                    else if (payload && ['upsert-item'].includes(payload.mode)) context.commit('UPSERT_ITEM', response.data.data)
                    else context.commit('UPDATE_LOADED_ITEM', response.data.data);
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show_item(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id, { params: payload.params })
                .then((response) => {
                    if (!payload.type || (payload.type && payload.type !== 'no-item')) context.commit('UPSERT_ITEM', response.data.data);
                    if (!payload.type || (payload.type && payload.type !== 'no-list')) context.commit('UPSERT_LIST_ITEM', response.data.data);
                    if (payload.type && payload.type === 'update-item') {
                        context.commit('UPSERT_ITEM', { ...response.data.data, payload_type: payload.type });
                        context.commit('UPSERT_LIST_ITEM', response.data.data);
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        activities(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id + '/activities_index')
                .then((response) => {
                    context.commit('UPDATE_LOADED_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, payload)
                .then((response) => {
                    // context.commit('ADD_ITEM', payload);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                    context.commit('UPSERT_ERRORS', { text: 'Done', status: 'success', type: 'store' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                    context.commit('UPSERT_ERRORS', { text: error.response.data.message, status: 'error', server: error.response.data, type: 'store' });
                });
        },

        async bulk_store ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.post(api_endpoint + '/bulk', payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async update({commit, dispatch}, payload) {
            this.dispatch('Interface/loader_start')
            const descJson = payload.description_json ? payload.description_json : null
            const descEncoded = await dispatch('encode_content', payload)
            if(descEncoded) payload.description_json = descEncoded
            return axios.patch(api_endpoint + '/' + payload.id, payload)
                .then((response) => {
                    if(descJson) payload.description_json = descJson
                    commit('UPDATE_LIST_ITEM', { id: payload.id, item: payload });
                    this.dispatch('Interface/loader_stop')
                    commit('REFRESH_LIST');
                    commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                    commit('UPSERT_ERRORS', { text: 'Done', status: 'success', type: 'update' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                    commit('UPSERT_ERRORS', { text: error.response.data.message, status: 'error', server: error.response.data, type: 'update' });
                });
        },

        async bulk_status_update ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.patch(api_endpoint + '/bulk-status-update', payload)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
                commit('UPSERT_ERRORS', { text: 'Done', status: 'success', type: 'bulk_status_update' })
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
                commit('UPSERT_ERRORS', { text: error.response.data.message, status: 'error', type: 'bulk_status_update' })
            }
        },

        async bulk_delete({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.delete(api_endpoint + '/bulk-delete', { params: payload })
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },

        sc_upsert ({ commit }, payload) {
            commit('UPSERT_LIST_ITEM', payload)
        },

        bulk_upsert ({ commit }, payload) {
            commit('UPSERT_BULK_LIST_ITEM', payload)
        },

        async throw_success ({ commit }) {
            commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            this.dispatch('Interface/loader_stop')
        },

        async throw_error ({ commit }, error) {
            this.dispatch('Interface/loader_stop')
            commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
        },

        async loader_start () {
            this.dispatch('Interface/loader_start')
        },

        async delete_selected ({ commit }, payload) {
            await commit('REMOVE_SELECTED', payload)
        },

        assign(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/assign', payload.data)
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Usecase/update_activities', { id: response.data.data.usecase_id, activities: response.data.data.activities })
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        unassign(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/unassign', payload.data)
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Usecase/update_activities', { id: response.data.data.usecase_id, activities: response.data.data.activities })
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        open(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/open', { usecase_id: payload.usecase_id })
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        close(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/close', { usecase_id: payload.usecase_id })
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', type: 'destroy' });
                    context.commit('UPSERT_ERRORS', { text: 'Done', status: 'success', type: 'destroy' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                    context.commit('UPSERT_ERRORS', { text: error.response.data.message, status: 'error', server: error.response.data, type: 'destroy' });
                });
        },
        comment_store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + payload.id + '/comments', { comment_text: payload.comment_text })
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        comment_update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.put(api_endpoint + '/' + payload.id + '/comments', { comment_text: payload.comment_text, comment_id : payload.comment_id })
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        comment_destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id + '/comments/'+ payload.comment_id)
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        enable(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/enable', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        disable(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/disable', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        status(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/states/status', { state : payload.status_id, comment_id: payload.comment_id })
                .then((response) => {
                   // context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' , data: response.data.data  });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        business_driver_store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + payload.id + '/business_drivers/' + payload.business_driver_id, payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_ITEM_BUSINESS_DRIVERS', response.data.data)
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        business_driver_destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id + '/business_drivers/' + payload.business_driver_id)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_LIST_ITEM_BUSINESS_DRIVERS', {
                        item: response.data.data,
                        id: payload.id
                    })
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    // context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        update_list (context, payload) {
            context.commit('UPSERT_LIST_ITEM', payload)
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('CLEAR_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPSERT_ERRORS', { data: _.cloneDeep(context.state.defaults.errors), reset: true });
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('CLEAR_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPSERT_ERRORS', { data: _.cloneDeep(context.state.defaults.errors), reset: true });
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        encode_content ({commit}, payload) {
            if(!payload['description_json']) return
            const content = payload['description_json']
            return Base64.encode(JSON.stringify(content))
        }
    },

    getters: {
        errors: (state) => (type) => {
            return state.errors[type]
        }
    }
}

<template>
    <div class="white u-wfull" style="">
        <div class="pa-6 u-flex white justify-space-between align-center u-sticky" style="top: 0; z-index: 20">
            <div class="u-flex">
                <div class="u-rounded-corners-full grey darken-4 u-flex justify-center" style="width: 40px; height: 40px">
                    <a-icon color="white">ballot</a-icon>
                </div>
                <div class="ml-4">
                    <div class="md-heading-6">Review changes</div>
                    <div class="md-caption grey--text text--darken-1">Find the latest changes on sections and configurations here</div>
                </div>
            </div>
            <div>
                <a-btn class="indigo darken-1 white--text mr-3 c-save-btn" :disabled="(list && !list.length) || loadingStore" :loading="loadingStore" @click="$emit('store')" depressed>Save changes</a-btn>
                <a-btn icon plain :disabled="loadingStore" @click="$emit('close')">
                    <a-icon size="20">close</a-icon>
                </a-btn>
            </div>
        </div>
        <div class="u-wfull c-log-section c-tiny-scroll pb-8">
            <table class="u-wfull">
                <colgroup>
                    <col span="1" style="width: 15%;">
                    <col span="1" style="width: 25%;">
                    <col span="1" style="width: 22.5%;">
                    <col span="1" style="width: 5%;">
                    <col span="1" style="width: 22.5%;">
                    <col span="1" style="width: 10%;">
                </colgroup>

                <tbody class="grey--text text--darken-3">
                    <tr class="md-subtitle-2 u-sticky u-wfull" style="top: 0px; z-index: 10">
                        <th class="pl-6">Action</th>
                        <th>Field/Section</th>
                        <th>From value</th>
                        <th></th>
                        <th>To value</th>
                        <th class="text-center red--text">*</th>
                    </tr>
                    <tr v-for="(log, index) in list" :key="index" class="md-body-2" :class="[{'c-log-row': index !== list.length-1}]">
                        <td class="px-6 py-5">
                            <div class="px-2 py-1 u-rounded-corners text-center text-capitalize grey lighten-4">
                                {{ log.action }}
                            </div>
                        </td>
                        <td class="py-5 pr-4">
                            <template v-if="log && log.arg">
                                <a-icon v-if="log.arg.module === 'field'" size="16" color="grey" class="mr-2">{{ local_get_field_type_icon(log.arg) }}</a-icon>
                                <span :title="log.arg.label" v-if="log.arg.module === 'field'">{{ log.arg.label | truncateText(55) }} </span>
                                <span :title="log.arg.name" v-else>{{ log.arg.name | truncateText(55) }} </span>
                                <span class="grey--text">{{ log.arg.module === 'field' ? '(Field)' : '(Section)' }}</span>
                                <div class="py-2" :title="log.arg.confidence_score_section.name" v-if="log.arg.module === 'field'"><span class="md-subtitle-2">Section:</span> {{ log.arg.confidence_score_section.name | truncateText(55) }}</div>
                            </template>
                        </td>
                        <!-- From value -->
                        <td class="py-3">
                            <template v-if="log.action !== 'modified'">
                                <div class="mt-2">
                                    <a-icon size="16" color="grey">remove</a-icon>
                                </div>
                            </template>
                            <template v-if="log.action === 'modified'">
                                <div class="pa-3 c-modified-value c-modified-value--deleted">
                                    <template v-if="log.arg.module === 'section'">
                                        <div class="grey--text text--darken-4 font-weight-medium">Label:</div>
                                        <div :title="log.arg.name">{{log.arg.name | truncateText(45)}}</div>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <div class="mb-2" v-if="log.toVal.label">
                                                <div class="grey--text text--darken-4 font-weight-medium">Label:</div>
                                                <div :title="log.arg.label" class="u-word-wrap">{{ log.arg.label | truncateText(45)}}</div>
                                            </div>
                                            <div class="mb-2" v-if="log.toVal.name">
                                                <div class="grey--text text--darken-4 font-weight-medium">Identifier:</div>
                                                <div :title="log.arg.name" class="u-word-wrap">{{ log.arg.name| truncateText(45) }}</div>
                                            </div>
                                            <template v-if="log.toVal && log.toVal.options.length">
                                                <div class="mb-2">
                                                    <div class="grey--text text--darken-4 font-weight-medium">Options:</div>
                                                    <div v-for="option in log.arg.options" :key="option.id">
                                                        <span :title="option.label">{{ option.label | truncateText(10) }}: {{option.value}}</span>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="mb-2" v-if="log.toVal.minimum_value">
                                                    <div class="grey--text text--darken-4 font-weight-medium"> Minimum score:</div>
                                                    <div>{{ log.arg.minimum_value }}</div>
                                                </div>
                                                <div class="mb-2" v-if="log.toVal.maximum_value">
                                                    <div class="grey--text text--darken-4 font-weight-medium"> Maximum score:</div>
                                                    <div>{{ log.arg.maximum_value }}</div>
                                                </div>
                                            </template>
                                            <div class="mb-2" v-if="log.toVal.width">
                                                <div class="grey--text text--darken-4 font-weight-medium">Field width:</div>
                                                <div>{{ log.arg.width }}</div>
                                            </div>
                                            <div v-if="local_check_fields_modified('description', log.toVal)">
                                                <div class="grey--text text--darken-4 font-weight-medium">Description:</div>
                                                <div :title="log.arg.description" class="u-word-wrap">{{ log.arg.description | truncateText(125) }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </td>
                        <td class="py-5 text-center">
                            <a-icon size="16" color="grey">east</a-icon>
                        </td>
                        <!-- To value -->
                        <td class="py-3">
                            <!-- Section -->
                            <div class="pa-3 grey--text text--darken-3 c-modified-value" :class="[log.action === 'deleted' ? 'c-modified-value--deleted' : 'c-modified-value--updated']">
                                <template v-if="log.arg.module === 'section'">
                                    <template v-if="log.action === 'created'">
                                        <div>
                                            <div class="grey--text text--darken-4 font-weight-medium">Label:</div>
                                            <div :title="log.arg.name" class="u-word-wrap">{{ log.arg.name | truncateText(45) }}</div>
                                        </div>
                                        <div class="mt-3" v-if="log.arg && log.arg.fields && log.arg.fields.length">
                                            <div class="font-weight-medium mb-3">Fields:</div>
                                            <div v-for="field in log.arg.fields" :key="field.id">
                                                <div class="mb-3">
                                                    <div class="grey--text text--darken-4 pb-3 u-flex align-start justify-space-between">
                                                        <div class="u-flex">
                                                            <div>
                                                                <a-icon size="16" color="grey darken-1" class="mr-2">{{ local_get_field_type_icon(field.arg) }}</a-icon>
                                                            </div>
                                                            <div>
                                                                <span :title="field.arg.label">{{ field.arg.label | truncateText(30)}}</span>
                                                                <span class="grey--text ml-1">(Field)</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a-icon color="grey" class="u-curosr-pointer" size="20" @click="local_is_field_expanded(field.arg.id) ? local_compress_item(field.arg.id) : local_expand_item(field.arg.id)">{{ local_is_field_expanded(field.arg.id) ? 'expand_less' : 'expand_more' }}</a-icon>
                                                        </div>
                                                    </div>
                                                    <div class="md-caption green lighten-5 pa-2 u-rounded-corners" v-if="local_is_field_expanded(field.arg.id)">
                                                        <div class="mb-2">
                                                            <div class="grey--text text--darken-4 font-weight-medium">Label:</div>
                                                            <span :title="field.arg.label" class="u-word-wrap">{{ field.arg.label | truncateText(50) }}</span>
                                                        </div>
                                                        <div class="mb-2">
                                                            <div class="grey--text text--darken-4 font-weight-medium">Identifier:</div>
                                                            <span :title="field.arg.name" class="u-word-wrap">{{ field.arg.name | truncateText(50) }}</span>
                                                        </div>
                                                        <div class="mb-2" v-if="field && field.arg && field.arg.options && field.arg.options.length">
                                                            <div class="grey--text text--darken-4 font-weight-medium">Options:</div>
                                                            <div :title="option.label" v-for="option in field.arg.options" :key="option.id">
                                                                {{ option.label | truncateText(10) }}: {{option.value}}
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <div class="mb-2">
                                                                <div class="grey--text text--darken-4 font-weight-medium">Minimum score:</div>
                                                                <div>{{ field.arg.minimum_value }}</div>

                                                            </div>
                                                            <div class="mb-2">
                                                                <div class="grey--text text--darken-4 font-weight-medium">Maximum score:</div>
                                                                <div>{{ field.arg.maximum_value }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="mb-2">
                                                            <div class="grey--text text--darken-4 font-weight-medium">Field width:</div>
                                                            <div>{{ field.arg.width }}</div>
                                                        </div>
                                                        <div v-if="field.arg.description">
                                                            <div class="grey--text text--darken-4 font-weight-medium">Description:</div>
                                                            <div :title="field.arg.description" class="u-word-wrap">{{ field.arg.description | truncateText(125) }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="log.action === 'modified'">
                                        <div class="pb-3">
                                            <div class="grey--text text--darken-4 font-weight-medium">Label:</div>
                                            <div :title="log.toVal.name" class="u-word-wrap">{{ log.toVal.name | truncateText(45) }}</div>
                                        </div>
                                    </template>
                                    <template v-if="log.action === 'deleted'">
                                        <div>
                                            <div v-if="log.arg.fields && !log.arg.fields.length" class="mb-3">
                                                <div class="grey--text text--darken-4 font-weight-medium">Label:</div>
                                                <div :title="log.arg.name" class="u-word-wrap">{{ log.arg.name | truncateText(45)}}</div>
                                                <span>(No fields)</span>
                                            </div>
                                            <template v-else>
                                                <div class="mb-3">
                                                    <div class="grey--text text--darken-4 font-weight-medium">Label:</div>
                                                    <div :title="log.arg.name">{{ log.arg.name | truncateText(45)}}</div>
                                                </div>
                                                <div class="font-weight-medium mb-3">Fields:</div>
                                                <div v-for="(field, index) in log.arg.fields" :key="field.id">
                                                    <div :class="[{ 'mb-3': index !== log.arg.fields.length -1 }]">
                                                        <div class="grey--text text--darken-4 pb-3 u-flex align-start justify-space-between" >
                                                            <div class="u-flex">
                                                                <div>
                                                                    <a-icon size="16" color="grey darken-1" class="mr-2">{{ local_get_field_type_icon(field) }}</a-icon>
                                                                </div>
                                                                <div>
                                                                    <span :title="field.label" class="u-word-wrap">{{ field.label | truncateText(45)}}</span>
                                                                    <span class="grey--text ml-1">(Field)</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <a-icon color="grey" class="u-curosr-pointer" size="20" @click="local_is_field_expanded(field.id) ? local_compress_item(field.id) : local_expand_item(field.id)">{{ local_is_field_expanded(field.id) ? 'expand_less' : 'expand_more' }}</a-icon>
                                                            </div>
                                                        </div>
                                                        <div class="md-caption red lighten-5 pa-2 u-rounded-corners" v-if="local_is_field_expanded(field.id)">
                                                            <div class="mb-2">
                                                                <div class="grey--text text--darken-4 font-weight-medium">Label:</div>
                                                                <div :title="field.label" class="u-word-wrap">{{ field.label | truncateText(50) }}</div>
                                                            </div>
                                                            <div class="mb-2">
                                                                <div class="grey--text text--darken-4 font-weight-medium">Identifier:</div>
                                                                <div :title="field.name" class="u-word-wrap">{{ field.name | truncateText(50) }}</div>
                                                            </div>
                                                            <div class="mb-2" v-if="field && field && field.options && field.options.length">
                                                                <div class="grey--text text--darken-4 font-weight-medium">Options:</div>
                                                                <div :title="option.label" v-for="option in field.options" :key="option.id">
                                                                    {{ option.label | truncateText(10)}}: {{option.value}}
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                <div class="mb-2">
                                                                    <div class="grey--text text--darken-4 font-weight-medium">Minimum score:</div>
                                                                    <div>{{ field.minimum_value }}</div>

                                                                </div>
                                                                <div class="mb-2">
                                                                    <div class="grey--text text--darken-4 font-weight-medium">Maximum score:</div>
                                                                    <div>{{ field.maximum_value }}</div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="grey--text text--darken-4 font-weight-medium">Field width:</div>
                                                                <div>{{ field.width }}</div>
                                                            </div>
                                                            <div class="mt-2" v-if="field.description">
                                                                <div class="grey--text text--darken-4 font-weight-medium">Description:</div>
                                                                <div :title="field.description" class="u-word-wrap">{{ field.description | truncateText(125) }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </template>

                                <!-- Field -->
                                <template v-else>
                                    <template v-if="log.action !== 'modified'">
                                        <div>
                                            <div class="mb-2">
                                                <div class="grey--text text--darken-4 font-weight-medium">Label:</div>
                                                <div :title="log.arg.label" class="u-word-wrap">{{ log.arg.label | truncateText(45)}}</div>
                                            </div>
                                            <div class="mb-2">
                                                <div class="grey--text text--darken-4 font-weight-medium">Identifier:</div>
                                                <div :title="log.arg.name" class="u-word-wrap">{{ log.arg.name| truncateText(45) }}</div>
                                            </div>
                                            <template v-if="log.arg && log.arg.options.length">
                                                <div class="mb-2">
                                                    <div class="grey--text text--darken-4 font-weight-medium">Options:</div>
                                                    <div :title="option.label" v-for="option in log.arg.options" :key="option.id">
                                                        {{ option.label | truncateText(10) }}: {{option.value}}
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="mb-2">
                                                    <div class="grey--text text--darken-4 font-weight-medium"> Minimum score:</div>
                                                    <div>{{ log.arg.minimum_value }}</div>
                                                </div>
                                                <div class="mb-2">
                                                    <div class="grey--text text--darken-4 font-weight-medium"> Maximum score:</div>
                                                    <div>{{ log.arg.maximum_value }}</div>
                                                </div>
                                            </template>
                                            <div class="mb-2">
                                                <div class="grey--text text--darken-4 font-weight-medium">Field width:</div>
                                                <div>{{ log.arg.width }}</div>
                                            </div>
                                            <div class="" v-if="log.arg.description">
                                                <div class="grey--text text--darken-4 font-weight-medium">Description:</div>
                                                <div :title="log.arg.description" class="u-word-wrap">{{ log.arg.description | truncateText(125) }}</div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <div class="mb-2" v-if="log.toVal.label">
                                                <div class="grey--text text--darken-4 font-weight-medium">Label:</div>
                                                <div :title="log.toVal.label" class="u-word-wrap">{{ log.toVal.label | truncateText(45)}}</div>
                                            </div>
                                            <div class="mb-2" v-if="log.toVal.name">
                                                <div class="grey--text text--darken-4 font-weight-medium">Identifier:</div>
                                                <div :title="log.toVal.name" class="u-word-wrap">{{ log.toVal.name| truncateText(45) }}</div>
                                            </div>
                                            <template v-if="log.toVal && log.toVal.options.length">
                                                <div class="mb-2">
                                                    <div class="grey--text text--darken-4 font-weight-medium">Options:</div>
                                                    <div :title="option.label" v-for="option in log.toVal.options" :key="option.id">
                                                        {{ option.label | truncateText(10) }}: {{option.value}}
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="mb-2" v-if="log.toVal.minimum_value">
                                                    <div class="grey--text text--darken-4 font-weight-medium"> Minimum score:</div>
                                                    <div>{{ log.toVal.minimum_value }}</div>
                                                </div>
                                                <div class="mb-2" v-if="log.toVal.maximum_value">
                                                    <div class="grey--text text--darken-4 font-weight-medium"> Maximum score:</div>
                                                    <div>{{ log.toVal.maximum_value }}</div>
                                                </div>
                                            </template>
                                            <div class="mb-2" v-if="log.toVal.width">
                                                <div class="grey--text text--darken-4 font-weight-medium">Field width:</div>
                                                <div>{{ log.toVal.width }}</div>
                                            </div>
                                            <div class="" v-if="local_check_fields_modified('description', log.toVal)">
                                                <div class="grey--text text--darken-4 font-weight-medium">Description:</div>
                                                <div :title="log.toVal.description" class="u-word-wrap">{{ log.toVal.description | truncateText(125) }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </td>
                        <!--Revert column-->
                        <td class="py-5 text-center" style="vertical-align: middle">
                            <template v-if="log.action !== 'created'">
                                <a-btn icon :disabled="loadingStore" @click="local_revert_action(log, log.arg.module)">
                                    <a-icon size="20" color="red darken-1">settings_backup_restore</a-icon>
                                </a-btn>
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            required: true
        },

        isOpen: {
            type: Boolean,
            required: true
        },

        loadingStore: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            local_field_type_list: [
                { type: 'score', label: 'Score', icon: 'pin' },
                { type: 'dropdown', label: 'Selection (Single)', icon: 'arrow_drop_down_circle' },
                { type: 'multi_select', label: 'Selection (Multiple)', icon: 'check_box' },
            ],
            expanded_fields: []
        }
    },

    methods: {
        local_get_field_type_icon(field) {
            const type = field.type
            const custom_field_item = this.local_field_type_list.find(item => item.type === type)
            if (custom_field_item) return custom_field_item.icon
        },

        local_option_modified (option, logData) {
            const modified_option_ids = logData.options.map(option => option.id)
            return (modified_option_ids.indexOf(option.id) !== -1)
        },

        local_revert_action (item, module) {
            this.$emit('revert', {item, module})
        },

        local_expand_item (id) {
            this.expanded_fields.push(id)
        },

        local_compress_item (id) {
            const index = this.expanded_fields.findIndex(item => item === id)
            if (index !== -1) this.expanded_fields.splice(index, 1)
        },

        local_is_field_expanded (id) {
            return (this.expanded_fields.indexOf(id) !== -1)
        },

        local_check_fields_modified (key, item) {
            return key in item
        }
    }
}
</script>

<style lang="scss" scoped>
    table {
        border-collapse: collapse;
    }
    th {
        background-color: #EEEEEE;
        text-align: left;
        padding: 0.75rem 0;
    }
    td {
        vertical-align: top;
    }
    .c-modified-value {
        border: 1px solid transparent;
        border-radius: 4px;
        padding: 0px 12px;

        &--updated {
            background: rgba(232, 245, 233, 0.6);
        }

        &--deleted {
            background: rgba(255, 235, 238, 0.6);
        }
    }
    .c-log-section {
        overflow-y: scroll !important;
        height: 600px;
    }
    .c-save-btn.v-btn.v-btn--disabled {
        opacity: 0.5;
        background-color: #3949AB !important;
        color: #fff !important;
    }
    .c-log-row {
        border-bottom: 1px solid #EEEEEE;
    }
</style>

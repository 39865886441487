<template>
    <div>
        <template v-if="!localUserIsExternal">
            <!-- External View Navbar -->
            <a-responsive v-if="localViewIsExternal" class="u-shadow" style="position: sticky; top: 0; z-index: 200; background-color: #ffffff">
                <slot name="external-view-nav">
                    <div class="navigation d-flex align-center justify-space-between px-4 py-4 c-padding" v-if="!localUserIsExternal && localViewIsExternal">
                        <div shrink class="d-flex align-center">
                            <router-link :to="{name: 'external-projects-view'}" class="navigation__logo d-block">
                                <a-card flat class="u-rounded-corners grey lighten-3 u-flex align-center justify-center" min-width="40" height="40">
                                    <a-img :src="require('../assets/images/success-imgs/icon-success-dark-txt.svg')" class="d-block" width="24" height="24" contain alt="Success"></a-img>
                                </a-card>
                            </router-link>
                        </div>
                        <a-spacer></a-spacer>
                        <div shrink class="d-flex align-center">
                            <router-link :to="{name: 'support'}" target="_blank" class="navigation__item">
                                <a-icon :color="iconColor">contact_support</a-icon>
                            </router-link>
                            <s-profile-header/>
                        </div>
                    </div>
                </slot>
            </a-responsive>

            <div class="u-flex">
                <!-- Internal Main Sidebar -->
                <slot
                    name="internal-side-nav"
                    :navBarLoading="localLoading"
                    :visible="configuration_main_navbar"
                />

                <a-sheet :width="localMainLayoutWidth">
                    <div
                        v-if="!localUserIsExternal && !localViewIsExternal"
                        class="pr-5 u-sticky u-flex-center-y bg-gray"
                        style="
                            top: 0px;
                            left: 0px;
                            z-index: 22;
                            min-height: 73px;
                            max-height: 73px;
                            border-bottom: 1px solid #eee !important;
                        "
                    >
                        <shared-workspace-switch
                            v-if="localRouteIsProjects"
                            style="border-right: 1px solid #eee;"
                        />

                        <div class="u-flex-center-y pl-5" style="column-gap: 8px;">
                            <div
                                v-if="!localRouteIsProjects"
                                @click="localToggleGlobalAside({ value: !configuration_main_navbar })"
                                class="u-cursor-pointer"
                                style="width: 20px; height: 20px;"
                            >
                                <img
                                    v-if="configuration_main_navbar" src="../assets/images/menu-toggle-close.svg"
                                    alt="Menu Toggle Open"
                                    style="width: 20px; height: 20px;"
                                >
                                <img
                                    v-else
                                    src="../assets/images/menu-toggle-open.svg"
                                    alt="Menu Toggle Close"
                                    style="width: 20px; height: 20px;"
                                >
                            </div>
                            <partial-breadcrumbs class="ml-2"></partial-breadcrumbs>
                        </div>

                        <a-spacer></a-spacer>

                        <div class="navigation__item pa-0 mr-5" v-if="user_self && user_self.organization && user_self.organization.flag_automation === 1">
                            <SAutomationPreHookLoader />
                        </div>

                        <shared-global-search-trigger />

                        <!-- TODO: Implement once websockets is available in backend -->
                        <!-- <shared-user-notifications class="mr-5" :disabled="localLoading">
                            <template #default="{ hasUnreadCount }">
                                <div v-if="hasUnreadCount" class="navigation__item pa-1 u-rounded-corners-lg u-relative">
                                    <div
                                        :style="[{ top: '-3px' }, { right: localNotificationCountPosition } ]"
                                        style="
                                            z-index: 2;
                                            border: 1px solid #FFCDD2;
                                            max-width: 28px; height: 16px;
                                            background: linear-gradient(180deg, #E53935 0%, #C62828 100%);
                                        "
                                        class="u-absolute u-flex-center u-rounded-corners u-font-10 u-leading-16 u-tracking u-font-weight-semibold pa-1 white--text"
                                    >
                                        {{ hasUnreadCount > 99 ? '99+' : hasUnreadCount }}
                                    </div>
                                    <a-icon size="20" color="grey darken-1" outlined>notifications</a-icon>
                                </div>
                                <div v-else class="navigation__item pa-1 u-rounded-corners-lg">
                                    <a-icon size="20" color="grey darken-1" outlined>notifications</a-icon>
                                </div>
                            </template>
                        </shared-user-notifications> -->

                        <s-profile-header/>
                    </div>

                    <!-- Default slot for route pages -->
                    <a-sheet :class="[{ 'pl-5': !localRouteIsProjects }, localSubPageBgColor]" style="height: calc(100% - 73px)">
                        <slot></slot>
                    </a-sheet>
                </a-sheet>
            </div>
        </template>

        <a-responsive
            v-if="localUserIsExternal || (!localUserIsExternal && localViewIsExternal)"
            class="u-shadow"
            style="position: sticky; top: 0; background-color: #ffffff"
        >
            <!-- External Navbar -->
            <slot name="external-nav" v-if="localUserIsExternal">
                <div class="navigation d-flex align-center justify-space-between px-4 py-4 c-padding">
                    <div shrink class="d-flex align-center">
                        <router-link :to="{name: 'dashboard'}" class="navigation__logo d-block">
                            <a-card flat class="u-rounded-corners grey lighten-3 u-flex align-center justify-center" min-width="40" height="40">
                                <a-img :src="require('../assets/images/success-imgs/icon-success-dark-txt.svg')" class="d-block" width="24" height="24" contain alt="Success"></a-img>
                            </a-card>
                        </router-link>
                        <router-link :to="{name: 'dashboard'}" class="navigation__item">
                            Dashboard
                        </router-link>
                    </div>
                    <div shrink class="d-flex align-center">
                        <router-link :to="{name: 'support'}" target="_blank" class="navigation__item">
                            <a-icon :color="iconColor">contact_support</a-icon>
                        </router-link>
                        <s-profile-header/>
                    </div>
                </div>
            </slot>
        </a-responsive>

        <slot v-if="localUserIsExternal"></slot>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

import { SWorkspaceSwitch } from '@/config/config-shared-components'
import loadWorkspace from '@/mixins/mixin-workspace'
import { SAutomationPreHookLoader } from '@/config/config-shared-components'
import GlobalProfileInfo from '../components/Global/GlobalProfileInfo.vue'
import SProfileHeader from '../components/Shared/SharedProfileHeader.vue'
import PartialBreadcrumbs from './Partials/PartialBreadcrumbs.vue'
import SharedWorkspaceSwitch from '../components/Shared/SharedWorkspaceSwitch.vue'
import SharedGlobalSearch from '../components/Shared/SharedGlobalSearch.vue'
import SharedGlobalSearchTrigger from '../components/Shared/SharedGlobalSearchTrigger.vue'

/* TODO: Implement once websockets is available in backend */
// import SharedUserNotifications from '../components/Shared/SharedUserNotifications.vue'

const whiteList = [
    'dashboard-insights-tile-view',
    'tickets-view',
    'inventory-add',
    'inventory-book',
    'booked-inventory',
    'booking-extend',
    'inventory-trash',
    'inventory-import',
    'settings-roles-view',
    'settings-workspace-stages',
    'settings-workspace-members',
    'settings-automations-edit',
    'settings-validations-edit',
    'settings-automation-validations-logs',
    'settings-document-generator-view',
    'master-templates-system-list-usecases-view',
    'master-templates-system-list-task-view',
    'master-templates-system-list-notes-view',
    'settings-metas-result-reasons',
    'customers-notes',
    'customers-notes-view',
    'customers-contacts',
    'partners-contacts',
    // Salesforce microservice
    'appstore-salesforce-microservices-logs',
    'appstore-salesforce-microservices-accounts',
    'appstore-salesforce-microservices-workflows-edit',
    'appstore-salesforce-microservices-workflows',
    // Hubspot
    'appstore-hubspot-workflows',
    'appstore-hubspot-workflows-create',
    'appstore-hubspot-workflows-edit',
    'appstore-hubspot-logs',
    'appstore-hubspot-accounts',
    // Jira
    'appstore-jira-workflows',
    'appstore-jira-workflows-create',
    'appstore-jira-workflows-edit',
    'appstore-jira-logs',
    'appstore-jira-accounts',
    // Gong
    'appstore-gong-settings',
    'appstore-gong-accounts',
    // Saelesforce
    'appstore-salesforce-workflows',
    'appstore-salesforce-workflows-create',
    'appstore-salesforce-workflows-edit',
    'appstore-salesforce-logs',
    'appstore-salesforce-accounts',
    // Webhook
    'appstore-webhook-workflows',
    'appstore-webhook-logs',
    'appstore-webhook-settings',
    'appstore-webhook-workflows-create',
    'appstore-webhook-workflows-edit'
]

export default {
    components: {
        SWorkspaceSwitch,
        GlobalProfileInfo,
        SProfileHeader,
        SAutomationPreHookLoader,
        // SharedUserNotifications, /* TODO: Implement once websockets is available in backend */
        PartialBreadcrumbs,
        SharedWorkspaceSwitch,
        SharedGlobalSearch,
        SharedGlobalSearchTrigger
    },

    mixins: [loadWorkspace],

    props: {
        showTopNav: {
            type: Boolean,
            default: true
        },
        hasWorkspace: {
            type: Boolean,
        },
        showGlobalAside: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            localLoading: true,
            currentUserConfig: null,
            workspace_mode: false,
            iconColor: '#444',
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localMainLayoutWidth () {
            if (this.localLoading || !this.showGlobalAside) return '100%'
            if (!this.configuration_main_navbar) return 'calc(100% - 60px)'
            return (this.localRouteIsProjects || !this.hasWorkspace) ? '100%' : 'calc(100% - 280px)'
        },

        localRouteIsProjects () {
            return this.$route.meta.parent && this.$route.meta.parent === 'projects'
        },

        localUserIsExternal () {
            return this.user_self && this.user_self.scope === 'external'
        },

        localViewIsExternal () {
            return this.$route.meta.view && this.$route.meta.view === 'external'
        },

        localSubPageBgColor () {
            if (this.localUserIsExternal || this.localViewIsExternal) return 'bg-gray'

            const parentName = this.$route?.meta?.parent?.name
            return whiteList.includes(this.$route.name) || parentName === 'settings' ? 'bg-gray' : 'transparent'
        },

        /* TODO: Implement once websockets is available in backend */
        // localNotificationCountPosition () {
        //     if (this.hasUnreadCount > 99) return '-11px'
        //     if (this.hasUnreadCount < 10) return '-1px'
        //     return '-4px'
        // },

        ...mapState('User', {
            user_self: 'self',
            user_list: 'list'
        }),

        ...mapState('Preference', {
            preference_list: 'list',
        }),

        ...mapState('Configuration',{
            configuration_main_navbar: 'globalAsideNavbar',
        }),

        ...mapState('Organization', {
            organization_plan: 'plan',
        }),

        ...mapState('Auth', {
            auth_response: 'response'
        }),

        ...mapState('Interface', {
            interface_loader: 'loader',
        }),

        ...mapGetters('Workspace', {
            workspace_list: 'list',
            workspace_board_list: 'board_list',
            workspace_current: 'current',
        }),
    },

    methods: {
        async localIndex () {
            if (this.user_self && this.user_self.scope === 'internal') {
                await this.localFetchUserConfig()
            }
            this.localLoading = false
        },

        async localFetchUserConfig () {
            let preferences = _.cloneDeep(this.preference_list)
            if (!_.size(preferences)) {
                await this.preference_index()
                preferences = _.cloneDeep(this.preference_list)
            }

            const config = preferences.find(i => i.key === 'user.config.global.aside_navbar')
            if (!config) return this.localToggleGlobalAside({ value: true, type: 'new' })

            this.currentUserConfig = config
            this.localToggleGlobalAside({ value: JSON.parse(config.value) })
        },

        localToggleGlobalAside ({ value, type = null }) {
            this.configuration_toggle_navbar(!!value)
            this.localUpsertUserPref({ value, type })
        },

        localUpsertUserPref ({ value, type = null }) {
            const params = {
                key: 'user.config.global.aside_navbar',
                resource_id: this.user_self.id,
                resource_type: 'User',
                label: 'User Global Preference',
                value
            }
            if (type === 'new') this.currentUserConfig = { ...params, id: uuidv4(), }
            if (type !== 'new') this.currentUserConfig = { ...this.currentUserConfig, value }

            this.preference_upsert(this.currentUserConfig)
        },
        // local_get_user_role (role_id) {
        //     const user = this.user_list.find(item => {
        //         return item.role.id ===
        //     })
        // },
        ...mapActions('User', {
            user_self_switch_workspace: 'switch_workspace',
            users_total_index: 'total_index',
        }),

        ...mapActions('Preference', {
            preference_index: 'index',
            preference_upsert: 'upsert_store',
            preference_workspace_switch: 'switch_workspace',
        }),

        ...mapActions('Configuration',{
            configuration_toggle_navbar: 'toggleGlobalAsideNavbar',
        }),

        ...mapActions('Auth',{
            auth_logout: 'logout',
        }),

        ...mapActions('Workspace', {
            workspace_index: 'index',
            workspace_switch: 'switch_workspace',
            workspace_clear: 'clear',
        })
    },
}
</script>

<style lang="scss" scoped>
    .c-nav-style {
        position: sticky;
        top: 0;
        z-index: 10;
        backdrop-filter: blur(6px);
        -webkit-backdrop-filter: blur(6px);
        --webkit-backdrop-filter: blur(6px);
        transition: 0.4s all;
    }
    .c-workspace {
        &--poc-view { background-color: #2e3482 !important; }
        &--select-poc-view { color: #fff !important; }
    }
    .navigation {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        /* background-color: #212952; */
        // background-color: #ffffff;
    }
    .navigation__logo {
        padding-right: 0px;
    }
    .navigation__item {
        // font-size: 0.95rem;
        font-size: 0.8rem;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 500;
        padding: 12px 1rem;
        display: block;
        /* color: rgba(255, 255, 255, 0.50); */
        color: rgba(0, 0, 0, 0.6);
        text-decoration: none;
        &--normal {
            font-size: 0.95rem !important;
            text-transform: capitalize !important;
            letter-spacing: auto !important;
        }
    }
    .navigation__item:hover {
        /* background-color: rgba(255, 255, 255, 0.05); */
        color: #222;
    }
    .c-padding {
        padding: 0.625rem 0;
        // padding: 0.825rem 0;
    }
</style>

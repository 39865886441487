<template>
    <div>
        <a-container container--fluid grid-list-xl pa-6 pt-0>
            <a-layout wrap align-start>
                <!-- <template v-if="competitor_list && competitor_list.length > 0">
                    <template v-for="competitor in project_item.competitors">
                        <a-flex xs12 :key="competitor.id">
                            <a-card class="mb-4 u-elevation-custom-1" v-if="competitor.projects && competitor.projects.length">
                                <a-card-text class="pa-0">
                                    <a-container container--fluid class="pa-6">
                                        <a-layout wrap align-center>
                                            <a-flex xs12>
                                                <h2 class="md-subtitle-1 primary--text">List of Projects with: {{ competitor.name }}</h2>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                    <template v-if="competitor && competitor.projects && competitor.projects.length">
                                        <template v-for="project in competitor.projects">
                                            <router-link tag="div" :key="project.id" :to="{ name: 'projects-overview', params: {id: project.id} }" class="u-cursor-pointer" v-if="project.id !== $route.params.id">
                                                <a-divider></a-divider>
                                                <a-container container--fluid pa-6>
                                                    <a-layout wrap align-center>
                                                        <a-flex xs12>
                                                            <h2 class="md-subtitle-2 grey--text text--darken-3 font-weight-bold">{{ project.title }}</h2>
                                                            <p class="md-body-2 grey--text text--darken-2 mb-0">
                                                                <span class="mr-2">{{ (project.stage) ? project.stage.name : project.status }}</span>
                                                                <g-moment v-if="project.start_date" :value="project.start_date" input-format="YYYY-MM-DD" output-format="[Started On] MMMM DD, YYYY" class="mr-2"></g-moment>
                                                                <span class="mr-2" v-if="project.sales_engineer && project.sales_engineer.user">{{ preference_get_property_by_key('organization.label_salesengineer', 'value') + ': ' + project.sales_engineer.user.name }}</span>
                                                                <span class="mr-2" v-if="project.usecases_count !== null && project.usecases_count !== undefined && project.usecases_count > 0">{{ project.usecases_closed_count + ' of ' + project.usecases_count }} Usecases Completed</span>
                                                            </p>
                                                        </a-flex>
                                                    </a-layout>
                                                </a-container>
                                            </router-link>
                                        </template>
                                    </template>
                                </a-card-text>
                            </a-card>
                        </a-flex>
                    </template>
                </template> -->
                <a-flex xs12>
                    <a-card class="mb-4 u-elevation-custom-1" v-for="(strategies_type, index) in local_strategies_type_list" :key="index">
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="pa-4">
                                <a-layout wrap>
                                    <a-flex xs12>
                                        <h2 class="md-subtitle-1 font-weight-bold primary--text">{{ strategies_type.value }}</h2>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <a-container container--fluid grid-list-xl class="pa-4">
                                <a-layout wrap>
                                    <a-flex xs12>
                                        <a-chip color="primary lighten-1" class="mr-2 px-2" text-color="white" v-for="strategy in local_strategy_filter(strategies_type.type)" :key="strategy.id">
                                            <a-avatar color="primary darken-1" class="mr-1" dark>
                                                <span class="white--text caption font-weight-bold">{{ local_get_initials(strategy.name) }}</span>
                                            </a-avatar>
                                            {{ strategy.name }}
                                        </a-chip>
                                        <!-- <button class="c-button-dashed grey--text text--lighten-1 md-body-2 my-2" v-if="$can('projects.update-only') && $can('strategies.store')" @click="local_strategy_edit(strategies_type.value, strategies_type.type)"> -->
                                        <button class="c-button-dashed grey--text text--lighten-1 md-body-2 my-2" v-if="$can('projects.update-only')" @click="local_strategy_edit(strategies_type.value, strategies_type.type)">
                                            <a-icon color="grey lighten-1" class="u-icon-nudge" size="16">add</a-icon>
                                            Add/Edit {{ strategies_type.value }}
                                        </button>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog max-width="800" v-model="dialog_strategy_form" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start>
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>people</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ dialog_strategy_value }}s</h2>
                                    <p class="md-subtitle-2 mb-0">Manage {{ dialog_strategy_value }}s in the project.</p>
                                </a-flex>
                            </a-layout>
                            <a-flex shrink>
                                <a-chip class="right" small color="white" text-color="white" v-if="autosave_strategys_status === 'none'"></a-chip>
                                <a-chip class="right" small color="grey" text-color="white" v-if="autosave_strategys_status === 'loading'">Saving...</a-chip>
                                <a-chip class="right" small color="green" text-color="white" v-if="autosave_strategys_status === 'done'">Saved</a-chip>
                            </a-flex>
                            <a-flex shrink>
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a-btn small text icon color="grey"  @click="dialog_strategy_form_close()" class="mx-0 mt-0 mr-0 mb-0 pa-0 px-3 right" v-on="on">
                                            <a-icon size="26">cancel</a-icon>
                                        </a-btn>
                                    </template>
                                    <span>Close</span>
                                </a-tooltip>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start>
                            <a-flex>
                                <span class="md-subtitle-1 d-inline-block px-2 mr-2 my-2 u-rounded-corners u-cursor-pointer" v-if="local_strategy_filter(this.dialog_strategy_type).length === 0">
                                    No data
                                </span>
                                <template v-if="local_strategy_filter(this.dialog_strategy_type) && local_strategy_filter(this.dialog_strategy_type).length">
                                    <a-flex xs12 py-0>
                                        <a-chip color="primary lighten-1" class="mr-2 px-2 my-1" text-color="white" v-for="strategy in local_strategy_filter(this.dialog_strategy_type)" :key="strategy.id">
                                            <a-avatar color="primary darken-1" class="mr-1" dark>
                                                <span class="white--text caption font-weight-bold">{{ local_get_initials(strategy.name) }}</span>
                                            </a-avatar>
                                            {{ strategy.name }}
                                            <a-icon @click="local_project_strategy_destroy(strategy, 'strategies')" size="18" color="white darken-4">clear</a-icon>
                                        </a-chip>
                                    </a-flex>
                                </template>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start>
                            <a-flex xs12>
                                <a-combobox
                                    v-model="project_strategy_item"
                                    :items="local_contact_filter"
                                    item-text="name"
                                    item-value="id"
                                    placeholder="Select Contact"
                                    background-color="neutral"
                                    :search-input.sync="project_strategy_search"
                                    solo flat hide-details
                                    @input="local_project_strategy_store()"
                                >
                                    <template v-slot:no-data>
                                        <div class="px-4 py-2">
                                            <span class="md-body-1" v-if="project_strategy_search != null && project_strategy_search != ''">No Contact</span>
                                            <span class="md-body-1 grey--text" v-else>No Contact found</span>
                                        </div>
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        <span v-if="item && item === Object(item)" class="body-2 d-inline-block py-1 px-2 mx-1 u-rounded-corners" >{{ item.name }}</span>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <a-layout align-center class="py-2">
                                            <g-avatar :item="item"></g-avatar>
                                            <g-profile-info :item="item" set-max-width hide-block></g-profile-info>
                                        </a-layout>
                                    </template>
                                </a-combobox>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                    <a-icon size="16" class="u-icon-nudge">info</a-icon>
                                    Adding {{ dialog_strategy_value }} will not send any emails or provide access to this project.
                                </span>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <!-- <a-dialog max-width="800" v-model="dialog_strategy_form" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-4">
                        <a-layout wrap align-start>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="40">
                                    <a-icon dark>people</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ dialog_strategy_value }}s</h2>
                                <p class="md-subtitle-2 mb-0">Manage {{ dialog_strategy_value }}s in the Project.</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-4">
                        <a-layout wrap align-start>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Contacts</label>
                                <a-autocomplete
                                    :items="contact_list"
                                    item-text="name"
                                    item-value="id"
                                    v-model="strategies"
                                    placeholder="Select Contact"
                                    background-color="neutral"
                                    solo flat hide-details multiple chips small-chips
                                >
                                    <template slot="selection" slot-scope="{ item, selected }">
                                        <a-chip :selected="selected" color="primary" class="white--text">{{ item.name }}</a-chip>
                                    </template>
                                </a-autocomplete>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="project_response.server && project_response.server.errors && project_response.server.errors.strategies">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ project_response.server.errors.strategies[0] }}
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16" class="u-icon-nudge">info</a-icon>
                                    Adding {{ dialog_strategy_value }} will not send any emails or provide access to this Project.
                                </span>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-4">
                        <a-layout wrap align-center>
                            <a-flex shrink>
                                <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_project_strategies_update()" :loading="loading" :disabled="loading">Save</a-btn>
                                <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_strategy_form = !dialog_strategy_form" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog> -->
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import TemplateImportStatus from '@/mixins/mixin-template-import-status'

export default {
    mixins: [TemplateImportStatus],
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            strategies: [],
            dialog_strategy_form: false,
            dialog_strategy_value: null,
            dialog_strategy_type: null,
            dialog_client_form: false,
            autosave_strategys_timeout: null,
            autosave_strategys_status: 'none',
            project_strategy_item: null,
            project_strategy_search: null,
            local_strategies_type_list : [
                { value: 'Sponsor', type: 'strategy_sponsor'},
                { value: 'Champion', type: 'strategy_champion'},
                { value: 'Guide', type: 'strategy_guide'},
                { value: 'Technical Contact', type: 'strategy_technical_contact'},
            ],
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        local_association_payload() {
            return { source_type : 'Project', source_id : this.$route.params.id, target_type: 'Contact', target_id : this.project_strategy_item.id, type: this.dialog_strategy_type }
        },
        local_contact_filter() {
            return _.differenceBy(this.contact_list, this.project_item.strategies.filter(list => list.association && list.association.type === this.dialog_strategy_type), 'id')
        },
        ...mapState('User', {
            user_self: 'self',
            user_list: 'list',
        }),
        ...mapState('Competitor', {
            competitor_list: 'list',
        }),
        ...mapState('Project', {
            project_item: 'item',
            project_response: 'response',
        }),
        ...mapState('Contact', {
            contact_list: 'list',
            contact_filters: 'filters',
        }),
        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('Association', {
            association_response: 'response',
        }),
    },
    methods: {
        async local_index() {

            await this.project_show({id: this.$route.params.id, params: { 'include': 'strategies,customer', 'fields[customer]': 'id,name'} })

            this.local_load_competitors_list()
            this.local_load_contact_list()
        },
        async local_project_competitors_show() {
            await this.project_show({
                    id: this.$route.params.id,
                    params: { 'include': 'competitors.projects' }
                })
        },
        async local_load_competitors_list() {
            setTimeout(async () => await this.local_project_competitors_show(), 100)
        },
        async local_load_contact_list() {
            this.contact_index({
                params: {
                    'filter[parent_type]': 'Customer',
                    'filter[parent_id]': this.project_item.customer_id,
                    'sort': 'name'
                }
            })
        },
        local_get_initials(name) {
            const [first, last] = _.split(name, ' ', 2)
            return last ? (first[0] ?? '') + (last[0] ?? '') : (first[0] ?? '')
        },
        local_strategy_filter(type) {
            return this.project_item.strategies.filter(list => list.association && list.association.type === type )
        },
        // local_contact_filter() {
        //     let filtered_contact_listt = _.differenceBy(this.contact_list, this.project_item.strategies, 'id')
        //     return filtered_contact_listt;
        // },
        local_strategy_edit(value, type) {
            this.dialog_strategy_form = true
            this.dialog_strategy_value = value
            this.dialog_strategy_type = type
        },
        async local_association_store(data, include) {
            await this.association_store(data)
            if (this.association_response.status === 'success') await this.local_show_project(include)
        },
        async local_association_destroy(data, include) {
            await this.association_destroy(data.association)
            if (this.association_response.status === 'success') await this.local_show_project(include)
        },
        async local_show_project(include) {
            await this.project_show({ id: this.$route.params.id, params: { 'include': include } })
        },
        async local_project_strategy_destroy(strategy) {
            clearTimeout(this.autosave_strategys_timeout)
            this.autosave_strategys_status = 'loading'
            await this.local_association_destroy(strategy, 'strategies')
            this.autosave_strategys_status = 'done'
            this.autosave_strategy_timeout = window.setTimeout(() => {
                this.autosave_strategys_status = 'none'
                clearTimeout(this.autosave_strategys_timeout)
            }, 1000)
        },
        async dialog_strategy_form_close() {
            this.project_strategy_item = null
            this.dialog_strategy_value = null
            this.dialog_strategy_type = null
            this.dialog_strategy_form = false
        },
        async local_project_strategy_store() {
            if ((typeof this.project_strategy_item) === 'string') return
            clearTimeout(this.autosave_strategys_timeout)
            this.autosave_strategys_status = 'loading'
            await this.local_association_store(this.local_association_payload, 'strategies')
            this.project_strategy_item = null
            this.autosave_strategys_status = 'done'
            this.autosave_strategys_timeout = window.setTimeout(() => {
                this.autosave_strategys_status = 'none'
                clearTimeout(this.autosave_strategys_timeout)
            }, 1000)
        },
        ...mapActions('User', {
            user_index: 'index',
            user_clear: 'clear'
        }),
        ...mapActions('Project', {
            project_update: 'update',
            project_show: 'show',
        }),
        ...mapActions('Competitor', {
            competitor_index: 'index',
        }),
        ...mapActions('Contact', {
            contact_index: 'index',
            contact_clear: 'clear',
        }),
        ...mapActions('Association', {
            association_store: 'store',
            association_destroy: 'destroy',
        }),
    }
}
</script>

<template>
    <aside class="sidebar">
        <a-container container--fluid grid-list-xl class="pt-0 pl-0">
            <a-layout align-start class="ml-0">
                <a-flex class="pt-4 pl-0">
                    <h4 class="md-subtitle-2 font-weight-bold grey--text mt-3 mb-3" :class="{ 'text-center': local_loading }">{{ local_loading ? 'Loading...' : 'Status Updates' }}</h4>
                    <template v-if="!local_has_any_permission">
                        <a-divider class="grey lighten-2"></a-divider>
                        <div class="u-flex align-center">
                            <a-icon class="u-icon-nudge mr-2" color="yellow darken-4">warning</a-icon>
                            <h4 class="md-subtitle-2 grey--text text--darken-1 mt-3 mb-3">You don't have sufficient permission.</h4>
                        </div>
                    </template>
                    <template v-if="local_has_any_permission">
                        <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden mb-4 u-opacity-50" v-if="!local_loading && status_update_list.length === 0">
                            <a-card-text>
                                <a-layout wrap align-center>
                                    <a-flex xs12>
                                        <div class="md-subtitle-3 text-center" style="white-space: pre-wrap;">No status update posted</div>
                                    </a-flex>
                                </a-layout>
                            </a-card-text>
                        </a-card>
                        <template v-if="!local_loading && status_update_list && status_update_list.length">
                            <a-card class="u-elevation-custom-1 u-overflow-hidden mb-4 c-radius" v-for="activity in status_update_list" :key="activity.id">
                                <a-card-text>
                                    <a-layout wrap align-center>
                                        <a-flex xs12 pb-0 class="u-flex align-center">
                                            <div class="u-flex align-start">
                                                <div>
                                                    <a-chip class="ma-0 grey lighten-2 c-radius--full" small :title="activity.type.value"><span class="md-subtitle-1 mb-1 mr-1">&bull;</span> {{ local_truncate_text(activity.type.value, 20) }}</a-chip>
                                                </div>
                                            </div>
                                        </a-flex>
                                        <a-flex xs12 class="pb-0">
                                            <div class="md-subtitle-3" style="white-space: pre-wrap;">
                                                <span>{{ local_truncate_text(activity.description, 400) }}</span>
                                                <span
                                                    v-if="activity && activity.description && activity.description.length > 400"
                                                    class="md-caption blue--text pl-1 font-italic u-cursor-pointer"
                                                    @click="local_select_status_update_replay(activity.id)">Read more
                                                </span>
                                            </div>
                                        </a-flex>
                                        <a-flex class="u-wfull text-right mt-2 pb-1">
                                            <div v-if="$can('status_updates.comment') || $can('projects.show')" class="d-inline-flex align-center u-cursor-pointer" style="border-radius: 25px" @click="local_select_status_update_replay(activity.id)">
                                                <svg width="16" height="16" fill="currentColor" class="grey--text text--darken-1 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M15 17v-2.99A4 4 0 0 0 11 10H8v5L2 9l6-6v5h3a6 6 0 0 1 6 6v3h-2z"/></svg>
                                                <span v-if="activity.comments_count === 1" class="md-caption d-inline-block grey--text text--darken-1">{{ activity.comments_count }} Comment</span>
                                                <span v-if="activity.comments_count > 1" class="md-caption d-inline-block grey--text text--darken-1">{{ activity.comments_count }} Comments</span>
                                                <span v-if="!activity.comments_count" class="md-caption d-inline-block grey--text text--darken-1">Comment</span>
                                            </div>
                                        </a-flex>
                                        <a-flex class="py-0">
                                            <a-divider class="grey lighten-3"></a-divider>
                                            <div class="u-flex align-center u-wfull">
                                                <div>
                                                    <a-icon size="14" color="grey darken-1">event</a-icon>
                                                    <g-moment tag="span" class="md-caption mb-0 grey--text text--darken-1 ml-1 d-inline-block" :value="activity.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD [at] hh:mma" convert-local></g-moment>
                                                </div>
                                                <a-spacer></a-spacer>
                                                <div class="py-1">
                                                    <div class="text-right">
                                                        <a-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <div v-on="on">
                                                                    <g-avatar :item="activity.created_by" :size="32" :right-space="false"></g-avatar>
                                                                </div>
                                                            </template>
                                                            <div class="u-flex align-center">
                                                                <span class="mr-1">Posted by </span>
                                                                <g-profile-info :item="activity.created_by" dark hide-role></g-profile-info>
                                                            </div>
                                                        </a-tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </a-flex>
                                        <!-- <a-flex class="u-flex align-end u-wfull mt-2">
                                            <div class="u-flex align-start">
                                                <g-avatar v-if="activity && activity.created_by" :item="activity.created_by" :size="32" :right-space="false"></g-avatar>
                                                <div class="ml-2">
                                                    <h3 class="text-body-2 grey--text text--darken-1 font-weight-bold" v-if="activity.created_by" :title="activity.created_by.name" style="line-height: 1">{{ local_truncate_text(activity.created_by.name, 12) }}</h3>
                                                    <g-moment tag="span" class="md-caption mt-1 d-inline-block mb-0 grey--text text--darken-1 d-block" :value="activity.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD [•] hh:mma" convert-local></g-moment>
                                                </div>
                                            </div>
                                            <a-spacer></a-spacer>
                                            <div v-if="$can('projects.index') && $can('status_updates.index')">
                                                <div class="u-flex align-center py-1 px-2 u-cursor-pointer" style="border-radius: 25px" v-ripple="{ color: 'info--text' }" @click="local_select_status_update_replay(activity.id)">
                                                    <svg width="16" height="16" fill="currentColor" class="grey--text text--darken-1 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M15 17v-2.99A4 4 0 0 0 11 10H8v5L2 9l6-6v5h3a6 6 0 0 1 6 6v3h-2z"/></svg>
                                                    <span v-if="activity.comments_count === 1" class="md-caption d-inline-block grey--text text--darken-1">{{ activity.comments_count }} Comment</span>
                                                    <span v-if="activity.comments_count > 1" class="md-caption d-inline-block grey--text text--darken-1">{{ activity.comments_count }} Comments</span>
                                                    <span v-if="!activity.comments_count" class="md-caption d-inline-block grey--text text--darken-1">Comment</span>
                                                </div>
                                            </div>
                                        </a-flex> -->

                                    </a-layout>
                                </a-card-text>
                            </a-card>
                        </template>
                    </template>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog max-width="450" persistent v-model="dialog_delete_status_confirm">
            <a-card flat class="pa-6 c-radius" style="position: relative">
                <a-btn icon text small style="position: absolute; top: 12px; right: 12px" @click="dialog_delete_status_confirm = !dialog_delete_status_confirm">
                    <a-icon size="16" color="grey lighten-1">cancel</a-icon>
                </a-btn>
                <a-card-text class="pa-0">
                    <a-card-text class="pa-0">
                        <h2 class="subtitle-1 font-weight-bold mb-1 grey--text text--darken-1">Are you sure you want to delete the status?</h2>
                        <h2 class="body-2 mt-2 font-weight-normal mb-1 grey--text text--darken-1" style="line-height: 1.6">The previous status update will be taken as last update and it will update the project health color accordingly. This action cannot be undone.</h2>
                        <div class="mt-3">
                            <a-btn v-if="local_delete_activity" @click="local_destroy(local_delete_activity.id)" small text class="red darken-1 u-rounded-corners ma-0 pa-0 px-2" dark>Delete</a-btn>
                        </div>
                    </a-card-text>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog max-width="840" scrollable persistent v-model="dialog_reply">
            <a-card class="pa-0 c-radius--lg" style="position: relative; height: 100%">
                <a-btn icon text small style="position: absolute; top: 12px; right: 12px" @click="dialog_reply = !dialog_reply">
                    <a-icon size="18" color="grey lighten-1">cancel</a-icon>
                </a-btn>
                <a-card-title class="pa-6">
                    <a-container container--fluid grid-list-xl class="pa-0 white">
                        <a-layout wrap align-start>
                            <a-flex shrink xs12 class="px-3 pt-3 pb-0" v-if="status_update_item">
                                <div class="u-flex align-start">
                                    <g-avatar v-if="status_update_item && status_update_item.created_by" :item="status_update_item.created_by" :right-space="false"></g-avatar>
                                    <div>
                                        <div class="ml-3 u-flex align-start">
                                            <div class="mt-1">
                                                <h3 class="body-2 grey--text text--darken-1 font-weight-bold" style="line-height: 1" v-if="status_update_item && status_update_item.created_by">{{ status_update_item.created_by.name }}</h3>
                                                <g-moment tag="span" class="caption mb-0 grey--text text--darken-1 d-block mt-1" :value="status_update_item.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD [ - ] hh:mma" convert-local></g-moment>
                                            </div>
                                            <div class="u-flex align-start ml-3">
                                                <div class="mr-2">
                                                    <a-chip class="ma-0 grey lighten-2 c-radius--full d-block font-weight-medium" small><span class="md-subtitle-1 mb-1 mr-1">&bull;</span> {{ status_update_item.type ? status_update_item.type.value : '' }}</a-chip>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="body-2 grey--text text--darken-3 ml-3 my-2 u-overflow-y" style="line-height: 1.6; white-space: pre-wrap; max-height: 300px">{{ status_update_item.description }}</p>
                                    </div>
                                </div>
                                <div class="d-inline-flex align-center mt-3 px-2 py-1 c-radius--full" v-if="comment_list.length">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="grey--text text--darken-1 mr-2 bi bi-chat" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
                                    </svg>
                                    <!-- <svg width="16" height="16" fill="currentColor" class="grey--text text--darken-1 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M15 17v-2.99A4 4 0 0 0 11 10H8v5L2 9l6-6v5h3a6 6 0 0 1 6 6v3h-2z"/></svg> -->
                                    <span v-if="comment_list.length === 1" class="c-radius--lg caption d-inline-block grey--text text--darken-1">{{ comment_list.length }} Comment</span>
                                    <span v-if="comment_list.length > 1" class="c-radius--lg caption d-inline-block grey--text text--darken-1">{{ comment_list.length }} Comments</span>
                                </div>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-title>
                <a-divider class="grey lighten-2"></a-divider>
                <a-card-text style="max-height: 220px;" ref="refstatus_updateList" class="c-scrollbar grey lighten-4 px-4 pt-4 pb-3">
                    <a-container container--fluid grid-list-xl class="pa-0 c-radius--lg">
                        <template v-if="comment_loding">
                            <div class="u-flex align-center justify-center">
                                <a-progress-circular :width="3" color="primary" indeterminate></a-progress-circular>
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="comment_list.length">
                                <template v-for="(comment, index) in comment_list">
                                    <a-divider class="grey lighten-2 my-3" v-if="index !== 0" :key="comment.id + 'divider'"></a-divider>
                                    <a-layout wrap align-start :key="comment.id">
                                        <a-flex shrink xs12 class="px-3 py-0" v-if="comment">
                                            <div class="u-flex" :class="{ 'my-3': comment_list.length === 1 }">
                                                <g-avatar v-if="comment" :item="comment.created_by" no-border :right-space="false"></g-avatar>
                                                <div class="ml-3 u-flex align-start">
                                                    <div>
                                                        <div class="u-flex align-center">
                                                            <h3 class="body-2 grey--text text--darken-1 font-weight-bold mt-1" style="line-height: 1" v-if="comment.created_by">{{ comment.created_by.name }}</h3>
                                                            <span class="mx-2 grey--text text--lighten-1">•</span>
                                                            <g-moment tag="span" class="caption mb-0 grey--text text--darken-1 d-block mt-1" :value="comment.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD [ - ] hh:mma" convert-local></g-moment>
                                                        </div>
                                                        <p class="body-2 grey--text text--darken-3 mt-2 mb-0" style="line-height: 1.6; white-space: pre-wrap;">{{ comment.content }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a-flex>
                                    </a-layout>
                                </template>
                            </template>
                            <h2 v-else key="no-replies" class="body-2 text-center py-2 pb-3 grey--text text--darken-1">No replies to show.</h2>
                        </template>
                    </a-container>
                </a-card-text>
                <template>
                </template>
                <template v-if="permission_status('comment')">
                    <a-divider class="grey lighten-2"></a-divider>
                    <a-card-actions class="white pa-4 u-flex" style="padding-bottom: 36px !important; position: relative">
                        <!-- <a-tooltip bottom :disabled="!mixinHasError(statusItem.id + '-comment-store')">
                            <template v-slot:activator="{ on }">
                                <a-textarea
                                    v-on="on"
                                    v-model="local_status_update"
                                    placeholder="Reply to this status update"
                                    ref="ref_status_update_input"
                                    class="c-status_update-reply c-scrollbar body-2 ma-0 c-radius--lg u-wfull pa-2"
                                    style="min-height: 30px; max-height: 50px; border: 1px solid #ccc !important; resize: none"
                                    @keydown.enter="evt => local_status_update_reply(evt)"
                                ></a-textarea>
                                </template>
                            <span>{{ mixinGetError(statusItem.id + '-comment-store') }}</span>
                        </a-tooltip> -->
                        <textarea
                            v-model="local_status_update"
                            placeholder="Reply to this status update"
                            ref="ref_status_update_input"
                            class="c-status_update-reply c-scrollbar body-2 ma-0 c-radius--lg u-wfull pa-2"
                            style="min-height: 30px; max-height: 50px; border: 1px solid #ccc !important; resize: none"
                            @keydown.enter="evt => local_status_update_reply(evt)"
                        ></textarea>
                        <a-btn icon text @click="local_status_update_reply()" class="primary white--text ml-3" style="width: 50px; height: 50px">
                            <a-icon color="white" size="24" class="ml-1">send</a-icon>
                        </a-btn>
                        <span class="md-caption red--text text--darken-1" style="position: absolute; bottom: 12px; left: 18px" v-if="local_status_update && comment_response.server && comment_response.server.errors && comment_response.server.errors.content">
                            <a-icon size="16" color="red darken-2" class="u-icon-nudge-small">info</a-icon>
                            {{ comment_response.server.errors.content[0] }}
                        </span>
                        <span class="u-flex align-center grey--text text--darken-1 caption" style="position: absolute; bottom: 12px; left: 18px" v-else>
                            <span class="md-caption"><strong>Ctrl/Cmd + Enter</strong> to post your reply.</span>
                        </span>
                    </a-card-actions>
                </template>
            </a-card>
        </a-dialog>
    </aside>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ExternalPermissionMixin from '@/mixins/mixin-external-view-permission'

export default {
    mixins: [ExternalPermissionMixin],

    data() {
        return {
            activity_type_list: [],
            dialog_status: false,
            dialog_reply: false,
            dialog_delete_status_confirm: false,
            status_update_replies: [],
            local_status_update: null,
            local_status_updateable_id: '',
            local_delete_activity: '',
            comment_loding: true,
            local_loading: true,
        }
    },

    watch: {
        dialog_reply (value) {
            if (value) {
                setTimeout(() => this.$refs.ref_status_update_input.focus(), 300)
            }
        },
        local_status_update (value) {
            if (!value) {
                this.comment_clear_item()
            }
        },
        $route (to, from) {
            if (to.params.id !== from.params.id) this.local_index()
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        local_has_any_permission() {
            const isExternal = this.user_self && this.user_self.scope === 'external'
            const permissions = ['usecases.index', 'tasks.index', 'links.index', 'documents.index', 'notes.index', 'poc_tickets.index']
            if (!isExternal) return permissions.some(item => this.can_external_mixin(item))
            return permissions.some(item => this.$can(item) && this.$can('projects.index'))
        },
        local_status_update_fields() {
            return {'include': 'type,commentsCount,createdBy', 'filter[project_id]' : this.$route.params.id }
        },
        isExternalView () {
            return this.$route.meta.view === 'external'
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('ProjectExternal', {
            project_item: 'item',
        }),
        ...mapState('MetaExternal', {
            meta_list: 'list',
            meta_filters: 'filters',
        }),
        ...mapState('StatusUpdateExternal', {
            status_update_list: 'list',
            status_update_item: 'item',
            status_update_filters: 'filters',
            status_update_response: 'response',
            status_update_form_mode: 'form_mode',
        }),
        ...mapState('CommentExternal', {
            comment_list: 'list',
            comment_item: 'item',
            comment_filters: 'filters',
            comment_response: 'response',
            comment_form_mode: 'form_mode',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_index() {
            this.local_loading = true
            await this.status_update_clear()
            await this.status_update_index(this.local_status_update_fields)
            this.local_loading = false
        },
        async local_store() {
            this.status_update_item.project_id = this.$route.params.id
            if (this.status_update_form_mode === 'add') await this.status_update_store(this.status_update_item)
            else await this.status_update_update(this.status_update_item)

            if (this.status_update_response.status !== 'success') return
            this.local_update_status()
            this.dialog_status = false
        },
        async local_update_status() {
            await this.status_update_show({ id : this.status_update_item.id, mode: this.status_update_form_mode, params : this.local_status_update_fields})
            if (this.status_update_response.status !== 'success') return
            await this.status_update_clear_item()
        },
        async local_post(id) {
            await this.status_update_clear_item()
            this.dialog_status = true
        },
        async local_select(id) {
            await this.status_update_select({id: id})
            this.dialog_status = true
        },
        async local_show_delete_confirm(activity) {
            this.local_delete_activity = activity
            this.dialog_delete_status_confirm = true
        },
        async local_select_status_update_replay(id) {
            await this.comment_clear_item()
            await this.comment_clear()
            this.local_status_update = null
            await this.status_update_select({id: id})
            this.dialog_reply = true
            this.comment_loding = true
            const fields = { 'filter[commentable_id]': id, 'include': 'createdBy', 'filter[commentable_type]': 'StatusUpdate' }
            await this.comment_index(fields)
            this.local_status_updateable_id =  id
            await this.local_adjust_reply_scroll(0)
            this.comment_loding = false
        },
        local_status_update_reply(evt) {
            if(!this.local_status_update || this.local_status_update && !this.local_status_update.trim()) return // Return if value is empty

            if (evt) {
                if (evt.ctrlKey || evt.metaKey) this.local_store_reply()
            }
            else this.local_store_reply()

            this.local_adjust_reply_scroll(300)
        },
        async local_store_reply() {
            this.comment_item.commentable_type = 'StatusUpdate'
            this.comment_item.commentable_id = this.local_status_updateable_id
            this.comment_item.content = this.local_status_update
            this.comment_item.mode = 'no-store'
            await this.comment_store(this.comment_item)
            if (this.comment_response.status !== 'success') return
            this.local_status_update = null
            this.local_comment_show()
            this.local_status_update_show()
        },
        async local_status_update_show() {
            await this.status_update_show({ id : this.status_update_item.id, mode: 'update', params : this.local_status_update_fields})
        },
        async local_comment_show() {
            await this.comment_show({ id: this.comment_item.id , mode: 'add', params : { 'include': 'createdBy' } })
            await this.comment_clear_item()
        },
        async local_adjust_reply_scroll(ms) {
            setTimeout(() => {
                this.$refs.refstatus_updateList.scrollTop = this.$refs.refstatus_updateList.scrollHeight
            }, ms)
        },
        local_truncate_text(text, range) {
            if (!text) return
            return text.length > range ? text.slice(0, range) + '...' : text
        },
        async local_destroy(id) {
            await this.status_update_destroy({id: id})
            if (this.status_update_response.status === 'success') {
                this.dialog_delete_status_confirm = false
                if (this.status_update_list && !this.status_update_list.length) this.project_item.health = 'Green'
            }
        },
        permission_status(type) {
            if (!this.isExternalView) return this.$can('projects.show') && this.$can(`status_updates.${type}`)
            return this.permission_mixin('status_updates.' + type, 'projects.show')
        },
        ...mapActions('ProjectExternal', {
            project_show: 'show',
        }),
        ...mapActions('MetaExternal', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),
        ...mapActions('StatusUpdateExternal', {
            status_update_index: 'index',
            status_update_show: 'show',
            status_update_store: 'store',
            status_update_update: 'update',
            status_update_select: 'select',
            status_update_clear: 'clear',
            status_update_destroy: 'destroy',
            status_update_clear_item: 'clear_item'
        }),
        ...mapActions('CommentExternal', {
            comment_index: 'index',
            comment_show: 'show',
            comment_store: 'store',
            comment_update: 'update',
            comment_select: 'select',
            comment_clear: 'clear',
            comment_destroy: 'destroy',
            comment_clear_item: 'clear_item',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .c-status_update-reply {
        transition: border 0.4s all;
        border: 2px solid transparent !important;
        outline: none !important;
        &:focus {
            transition: border 0.4s all;
            border: 2px solid #888 !important;
        }
    }
</style>

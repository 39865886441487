<template>
    <a-responsive class="neutral task-detail-pane elevation-12" style="margin-top: 58px;" :class="{'visible': isOpen && editItem.id}">
        <a-container container--fluid grid-list-xs class="white px-6 pt-6 pb-4" v-if="editItem && editItem.id">
            <a-layout wrap align-start class="mb-4">
                <a-flex>
                    <a-btn class="ma-0 elevation-0" small :disabled="!permission_task('update')" v-if="!editItem.completed_at" @click="$emit('toggle-complete', editItem.id, editItem.completed_at, editItem.milestone_id)">
                        <a-icon size="20" color="grey darken-3" left class="mr-2">radio_button_unchecked</a-icon> Mark Complete
                    </a-btn>
                    <a-btn class="ma-0 elevation-0" small color="teal" :disabled="!permission_task('update')" :dark="permission_task('update')" v-if="editItem.completed_at" @click="$emit('toggle-complete', editItem.id, editItem.completed_at, editItem.milestone_id)">
                        <a-icon size="20" color="white" left class="mr-2">done</a-icon> Mark Incomplete
                    </a-btn>
                    <a-btn color="red" class="ml-2" text small @click="$emit('destroy', editItem.id, editItem.milestone_id)" :disabled="!permission_task('destroy')" :dark="permission_task('destroy')">
                        Delete
                    </a-btn>
                </a-flex>
                <a-flex shrink>
                    <a-btn color="grey" text small dark @click="$emit('close')">
                        Close
                    </a-btn>
                </a-flex>
            </a-layout>
            <a-layout wrap class="mb-2">
                <a-flex xs12>
                    <a-textarea
                        v-model="editItem.title"
                        class="font-weight-regular content-editable u-border"
                        placeholder="Enter Title"
                        rows="1"
                        :readonly="!permission_task('update')"
                        multi-line auto-grow solo flat hide-details
                        @input="local_touch"
                    ></a-textarea>
                </a-flex>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(task_response, 'title')">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ $response(task_response, 'title') }}
                </span>
            </a-layout>
            <a-layout wrap mt-4>
                <a-flex xs12>
                    <a-textarea
                        v-model="editItem.description"
                        class="font-weight-regular content-editable u-border"
                        :placeholder="(user_self.scope === 'internal') ? 'Enter Description' : 'No Description'"
                        rows="2"
                        row-height="24"
                        :readonly="!permission_task('update')"
                        multi-line auto-grow solo flat hide-details
                        @input="local_touch"
                    ></a-textarea>
                </a-flex>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(task_response, 'description')">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ $response(task_response, 'description') }}
                </span>
            </a-layout>
            <a-layout mt-4>
                <a-flex pb-0>
                    <slot name="assignees"></slot>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex class="text-right" v-if="permission_task('update')">
                    <a-btn class="green lighten-4 green--text text--darken-2 elevation-0" small :disabled="!editItem.edited" @click="local_update()">Update</a-btn>
                </a-flex>
            </a-layout>
        </a-container>
        <a-divider class="u-divider"></a-divider>
        <a-container container--fluid grid-list-xs class="white px-6 pb-3 pt-3" v-if="editItem && editItem.id">
            <a-layout align-center wrap class="mb-3">
                <a-flex shrink align-self-center class="mr-2">
                    <a-icon size="24" color="grey lighten-1">event</a-icon>
                </a-flex>
                <a-flex>
                    <span class="md-caption grey--text mb-0">Start Date</span>
                    <a-menu :close-on-content-click="true" v-model="task_start_date" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" :disabled="!permission_task('update')">
                        <template v-slot:activator="{ on }">
                            <div v-if="editItem.start_date" v-on="on">
                                <g-moment input-format="YYYY-MM-DD" output-format="MMMM D, YYYY" :value="editItem.start_date" class="u-cursor-pointer md-body-2"></g-moment>
                            </div>
                            <div v-else v-on="on">
                                <span class="md-body-2 grey--text u-cursor-pointer">No Start Date</span>
                            </div>
                        </template>
                        <a-date-picker v-model="editItem.start_date" no-title scrollable @input="local_update()"></a-date-picker>
                    </a-menu>
                    <p class="blue--text caption mb-0 u-cursor-pointer" v-if="editItem.start_date !== null && permission_task('update')" @click="local_clear_date('start_date')">Clear Start Date</p>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink align-self-center class="mr-2">
                    <a-icon size="24" color="grey lighten-1">event</a-icon>
                </a-flex>
                <a-flex style="min-width: 175px">
                    <span class="md-caption grey--text mb-0">Due Date</span>
                    <a-menu :close-on-content-click="true" v-model="task_due_date" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" :disabled="!permission_task('update')">
                        <template v-slot:activator="{ on }">
                            <div v-if="editItem.due_date" v-on="on">
                                <g-moment input-format="YYYY-MM-DD" output-format="MMMM D, YYYY" :value="editItem.due_date" class="u-cursor-pointer md-body-2" :class="{'red--text text--darken-2': local_due_date_passed(editItem.due_date, editItem.completed_at)}"></g-moment>
                            </div>
                            <div v-else v-on="on">
                                <span class="md-body-2 grey--text u-cursor-pointer">No Due Date</span>
                            </div>
                        </template>
                        <a-date-picker v-model="editItem.due_date" no-title scrollable @input="local_update()"></a-date-picker>
                    </a-menu>
                    <p class="blue--text caption mb-0 u-cursor-pointer" v-if="editItem.due_date !== null && permission_task('update')" @click="local_clear_date('due_date')">Clear Due Date</p>
                </a-flex>
                <a-flex xs12 text-right pa-0 v-if="$response(task_response, 'due_date')">
                    <span class="md-caption red--text text--darken-1">{{ $response(task_response, 'due_date') }}</span>
                </a-flex>
            </a-layout>
            <a-layout align-center class="mb-3">
                <a-flex shrink align-self-center class="mr-2">
                    <a-icon size="24" color="grey lighten-1">priority_high</a-icon>
                </a-flex>
                <a-flex class="ml-2" style="min-width: 175px">
                    <p class="md-caption grey--text mb-0">Priority</p>
                    <div class="u-flex align-center">
                        <a-menu offset-y :disabled="!permission_task('update')">
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <p class="md-subtitle-1 mb-0 u-cursor-pointer grey--text" v-if="editItem && !editItem.priority">No Priority</p>
                                    <div class="md-subtitle-1 mb-0 u-cursor-pointer" v-else>
                                        <div class="u-rounded-corners px-2 md-body-2 u-flex align-center" style="padding-top: 2px; padding-bottom: 2px;" :class="{
                                            'red lighten-4 red--text text--darken-2 ml-0': editItem.priority === 'high',
                                            'blue lighten-4 blue--text text--darken-2 ml-0': editItem.priority ===  'low'
                                        }">
                                            {{ (editItem.priority === 'high') ? 'High' : '' }}
                                            {{ (editItem.priority ===  'low') ? 'Low' : '' }}
                                            <a-icon small class="ml-1" color="grey darken-4">keyboard_arrow_down</a-icon>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <a-list>
                                <a-list-item @click="$emit('update-priority', 'high')">
                                    <a-list-item-title>
                                        High
                                    </a-list-item-title>
                                </a-list-item>
                                <a-list-item @click="$emit('update-priority', 'low')">
                                    <a-list-item-title>
                                        Low
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                        <a-icon size="16" color="blue" class="pa-1 u-cursor-pointer ml-1" v-if="editItem.priority !== null && permission_task('update')" @click="$emit('update-priority', null)">clear</a-icon>
                    </div>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink align-self-end class="mr-2">
                    <a-icon size="24" color="grey lighten-1">assistant_photo</a-icon>
                </a-flex>
                <a-flex style="min-width: 175px">
                    <p class="md-caption grey--text mb-0">Milestone</p>
                    <a-menu bottom left offset-y min-width="240" :disabled="!permission_task('update')">
                        <template v-slot:activator="{ on }">
                            <p v-if="editItem.milestone_id !== null && editItem.milestone" v-on="on" class="md-subtitle-1 mb-0 u-cursor-pointer">
                                {{ editItem.milestone.title | truncateText(13) }}
                            </p>
                            <p v-else v-on="on" class="md-subtitle-1 mb-0 u-cursor-pointer">No Milestone</p>
                        </template>
                        <a-list class="u-overflow-y" style="max-height: 250px;" v-if="milestone_list && milestone_list.length > 0">
                            <a-list-item v-for="milestone in milestone_list" :key="milestone.id"  :disabled="editItem.milestone && editItem.milestone_id !== null && milestone.id == editItem.milestone.id" @click="$emit('update-milestone', milestone.id)">
                                <a-list-item-content>
                                    <a-list-item-title class="text-truncate" style="max-width: 300px">{{ milestone.title }}</a-list-item-title>
                                </a-list-item-content>
                            </a-list-item>
                            <a-list-item :disabled="editItem.milestone_id == null" @click="$emit('update-milestone', null)">
                                <a-list-item-content>
                                    <a-list-item-title>No Milestone</a-list-item-title>
                                </a-list-item-content>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
            </a-layout>
        </a-container>
        <a-divider class="grey lighten-3"></a-divider>
        <a-container container--fluid grid-list-xs class="pa-6 mb-5" v-if="editItem.id">
            <a-form @submit.prevent="$emit('store-comment')">
                <a-layout wrap align-start v-if="$can('tasks.comment') && $can('projects.comment')">
                    <a-flex xs9 class="mb-4">
                        <a-textarea
                            v-model="comment_item.content"
                            class="elevation-1"
                            placeholder="Add Your Comment"
                            rows="2"
                            multi-line
                            auto-grow solo flat hide-details
                        ></a-textarea>
                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(comment_response, 'comment_text')">
                            <a-icon size="16" color="red darken-2">warning</a-icon>
                            {{ $response(comment_response, 'comment_text') }}
                        </span>
                    </a-flex>
                    <a-flex xs3>
                        <a-btn class="ma-2 elevation-0 primary lighten-1" block @click="$emit('store-comment')">
                            <a-icon>keyboard_return</a-icon>
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-form>
            <a-layout wrap align-center>
                <a-flex xs12>
                    <p class="md-body-2 my-4 pb-3 grey--text text--darken-1 text-center" v-if="comment_list && !comment_list.length"><a-icon size="16" color="grey" class="mr-2">chat_bubble</a-icon> No comments for this task.</p>
                    <div v-else>
                        <p class="md-body-2 mb-2 grey--text text--darken-1"><a-icon size="16" color="grey" class="mr-2">chat_bubble</a-icon> Comments</p>
                        <a-layout class="my-4" v-for="comment  in comment_list" :key="comment.id">
                            <a-tooltip bottom v-if="comment && comment.created_by">
                                <template v-slot:activator="{ on }">
                                    <g-avatar v-on="on" :item="comment.created_by"></g-avatar>
                                </template>
                                <span>{{ comment.created_by.name }}</span>
                            </a-tooltip>
                            <a-flex>
                                <a-card class="u-elevation-custom-1" :class="{ 'yellow lighten-4': comment.visibility === 'external', }">
                                    <a-card-text class="pa-3">
                                        <p class="md-body-2 mb-0 text-wrap-pre">{{ comment.content }}</p>
                                    </a-card-text>
                                </a-card>
                                <a-layout align-center>
                                    <a-flex shrink>
                                        <g-moment class="md-caption grey--text d-block pt-2" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM D, YYYY h:mma" :value="comment.created_at" convert-local></g-moment>
                                    </a-flex>
                                </a-layout>
                            </a-flex>
                        </a-layout>
                    </div>
                </a-flex>
            </a-layout>
        </a-container>
    </a-responsive>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        editItem: {
            type: Object,
            required: true
        },
        isOpen: {
            type: Boolean
        }
    },

    data () {
        return {
            task_start_date: null,
            task_due_date: null,
            localEstText: null,
            validationError: null,
            showEstimateEditForm: false
        }
    },

    computed: {
        ...mapState('CommentExternal', {
            comment_list: 'list',
            comment_item: 'item',
            comment_response: 'response',
        }),

        ...mapState('TaskExternal', {
            task_response: 'response',
        }),

        ...mapState('MilestoneExternal', {
            milestone_list: 'list',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        local_update () {
            this.$emit('update', this.editItem)
        },

        async local_comment_visibility_toggle (comment) {
            comment.visibility = comment.visibility === 'external' ? 'internal' : 'external'
            comment.type = 'Task'
            await this.comment_visible(comment)
        },

        local_set_validation (value) {
            if (value !== undefined) this.validationError = value
            return this.validationError
        },

        local_show_est_edit (durationText) {
            this.localEstText = durationText
            this.showEstimateEditForm = true
        },

        local_update_est () {
            this.$emit('update-est', {value: this.localEstText, validation: this.local_set_validation})
            this.$nextTick(() => {
                if (!this.validationError) this.local_close_est_edit()
            })
        },

        local_close_est_edit () {
            this.local_set_validation(null)
            this.localEstText = null
            this.showEstimateEditForm = false
        },

        local_clear_date(type) {
            this.editItem[type] = null
            this.local_update()
        },

        local_due_date_passed (date, completed_at) {
            return moment(date).isBefore() && !completed_at
        },

        local_touch () {
            this.editItem.edited = true
        },

        permission_task (type) {
            return this.$can(`tasks.${type}`) && this.$can('projects.update-only')
        },

        ...mapActions('CommentExternal', {
            comment_visible: 'visible',
        }),
    }
}
</script>

<style scoped>
.task-detail-pane {
    transition: 0.3s all;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    height: 100vh;
    width: 520px;
    overflow-y: scroll;
    transform: translateX(520px);
}

.task-detail-pane.visible {
    transform: translateX(0);
}
</style>

const Authorizer = {
    install(Vue, { store }) {
        Vue.prototype.$can = (ability, permissions = null, resource = null) => {
            if (ability) {
                const [module, action] = ability.split('.')
                permissions = permissions ?? (store.state.Auth.role && store.state.Auth.role.permissions)
                if (permissions) {
                    const { value } = _.find(permissions, { slug: module })
                    const getAbilityValue = checkAbility(module, action)

                    return value >= getAbilityValue
                }
            }
        }
    }
}

const checkAbility = (module, action) => {
    // TODO: If module exists return the default value based on module name
    switch (action) {
        case 'index': {
            return 1
        }
        case 'show': {
            return 2
        }
        case 'manage': {
            return 2
        }
        case 'comment': {
            return 3
        }
        case 'download': {
            return 4
        }
        case 'update-only': {
            return 4.1
        }
        case 'store': {
            return 5
        }
        case 'update': {
            return 5
        }
        case 'destroy': {
            return 6
        }
    }
}

export default Authorizer

<template>
    <div class="scroll-transparent" style="position: sticky; left: 0px; top: 0px; height: 100vh; overflow-y: auto; z-index: 0; margin-top: -74px">
        <router-link :to="{name: 'dashboard-columns'}" class="c-top-nav-bg d-block px-6 pt-6" style="backdrop-filter: blur(6px);">
            <a-card class="u-shadow u-rounded-corners u-flex align-center justify-center" min-width="48" height="48">
                <a-img :src="require('../../assets/images/success-imgs/icon-success-dark-txt.svg')" class="d-block" width="24" height="24" contain alt="Success"></a-img>
            </a-card>
        </router-link>
        <a-sheet class="transparent u-flex justify-center" style="position: relative; margin-top: 136px;">
            <a-sheet class="d-inline-block transparent">
                <a-menu right offset-x transition="slide-x-reverse-transition" open-on-hover v-if="$can('projects.index') || $can('customers.index') || $can('partners.index')" :nudge-top="dataIsProjectRoutes ? 10 : 10" :content-class="dataProjectClassHover">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" class="c-nav-link mb-8 active u-cursor-pointer" :class="{ 'active--bg': dataIsProjectRoutes }">
                            <a-icon color="grey darken-4" outlined>folder_open</a-icon>
                        </div>
                    </template>
                    <a-sheet width="150" class="u-rounded-corners-lg">
                        <a-list style="max-height: 320px;" class="overflow-y-auto u-rounded-corners-lg u-list-condensed u-list-condensed--round-select py-1 px-1">
                            <template v-for="(report, index) in dataProjectViews">
                                <a-list-item v-if="report.show" :key="report.label" class="white px-3 u-rounded-corners-lg" style="padding-top: 2px; padding-bottom: 2px;" :to="{ name: report.link }" :class="[{ 'grey lighten-5': $route.name === report.link }, { 'mt-1': index !== 0 }]">
                                    <a-list-item-icon class="mx-0 px-0">
                                        <a-icon size="18" color="#999">{{ report.icon }}</a-icon>
                                    </a-list-item-icon>
                                    <a-list-item-title class="md-body-2 font-weight-normal ml-1">
                                        {{ report.name }}
                                    </a-list-item-title>
                                </a-list-item>
                            </template>
                        </a-list>
                    </a-sheet>
                </a-menu>
                <a-tooltip right offset-x content-class="c-tooltip-pointer c-tooltip-pointer--left">
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <router-link v-if="$can('tasks.index')" :to="{name: 'tasks'}" class="c-nav-link mb-8 active" :class="{ 'active--bg': dataRouteName === 'tasks' }">
                                <a-icon color="grey darken-4" outlined>check_circle_outline</a-icon>
                            </router-link>
                        </div>
                    </template>
                    <span>Tasks</span>
                </a-tooltip>
                <a-tooltip right offset-x content-class="c-tooltip-pointer c-tooltip-pointer--left" v-if="$can('meetings.index') && $plan('meetings:full-access') && dataHasMeetings">
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <router-link v-if="$can('tasks.index')" :to="{name: 'meetings'}" class="c-nav-link mb-8 active" :class="{ 'active--bg': dataRouteName === 'meetings' }">
                                <a-icon color="grey darken-4" outlined>video_call</a-icon>
                            </router-link>
                        </div>
                    </template>
                    <span>Meetings</span>
                </a-tooltip>
                <a-tooltip right offset-x content-class="c-tooltip-pointer c-tooltip-pointer--left">
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <router-link v-if="$can('notes.index')" :to="{name: 'notes'}" class="c-nav-link mb-8 active" :class="{ 'active--bg': dataRouteName === 'notes' }">
                                <a-icon color="grey darken-4" outlined>notes</a-icon>
                            </router-link>
                        </div>
                    </template>
                    <span>Notes</span>
                </a-tooltip>
                <a-tooltip right offset-x content-class="c-tooltip-pointer c-tooltip-pointer--left">
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <router-link v-if="$can('tickets.index')" :to="{name: 'tickets'}" class="c-nav-link mb-8 active" :class="{ 'active--bg': dataRouteName === 'tickets' }">
                                <a-icon color="grey darken-4" outlined>account_tree</a-icon>
                            </router-link>
                        </div>
                    </template>
                    <span>Tickets</span>
                </a-tooltip>
                <a-tooltip right offset-x content-class="c-tooltip-pointer c-tooltip-pointer--left" v-if="$can('inventories.index') && $plan('inventory:full-access') && dataHasInventory">
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <router-link :to="{name: 'inventory'}" class="c-nav-link mb-8 active" :class="{ 'active--bg': dataRouteName === 'inventory' }">
                                <a-icon color="grey darken-4" outlined>memory</a-icon>
                            </router-link>
                        </div>
                    </template>
                    <span>Inventory</span>
                </a-tooltip>
                <template v-if="$can('reports.index')">
                    <a-menu right offset-x transition="slide-x-reverse-transition" open-on-hover :nudge-top="dataIsReportRoutes ? 10 : 10" :content-class="dataReportClassHover">
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="c-nav-link mb-8 active u-cursor-pointer" :class="{ 'active--bg': dataIsReportRoutes }">
                                <a-icon color="grey darken-4" outlined>pie_chart_outline</a-icon>
                            </div>
                        </template>
                        <a-sheet width="200" class="u-rounded-corners-lg">
                            <a-list style="max-height: 320px;" class="overflow-y-auto u-rounded-corners-lg u-list-condensed u-list-condensed--round-select py-1 px-1">
                                <template v-for="(report, index) in dataReportViews">
                                    <a-list-item v-if="report.show" :key="report.label" class="white px-3 u-rounded-corners-lg" style="padding-top: 2px; padding-bottom: 2px;" :to="{ name: report.link }" :class="[{ 'grey lighten-5': $route.name === report.link }, { 'mt-1': index !== 0 }]">
                                        <a-list-item-icon class="mx-0 px-0">
                                            <a-icon size="18" color="#999">{{ report.icon }}</a-icon>
                                        </a-list-item-icon>
                                        <a-list-item-title class="md-body-2 font-weight-normal ml-1">
                                            {{ report.name }}
                                        </a-list-item-title>
                                    </a-list-item>
                                </template>
                            </a-list>
                        </a-sheet>
                    </a-menu>
                </template>
                <a-tooltip right offset-x content-class="c-tooltip-pointer c-tooltip-pointer--left">
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <router-link :to="{name: 'appstore'}" class="c-nav-link active" v-if="$can('applets.index')" :class="{ 'active--bg': dataRouteName === 'appstore' }">
                                <a-icon color="grey darken-4" outlined>apps</a-icon>
                            </router-link>
                        </div>
                    </template>
                    <span>App Store</span>
                </a-tooltip>
            </a-sheet>
        </a-sheet>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import mixinGetPreference from '@/mixins/mixin-get-preference'
import mixinGetOrgPlan from '@/mixins/mixin-get-organization-plan'

export default {
    mixins: [mixinGetPreference, mixinGetOrgPlan],

    computed: {
        dataRouteName () {
            const { name } = this.$route
            return name
        },

        dataReportViews () {
            return [
                { name: 'Project Reports', link: 'reports', icon: 'assignment', show: this.$can('projects.index') },
                { name: 'Resource Planning', link: 'resource-planning', icon: 'perm_contact_calendar', show: this.$can('projects.index') && this.$can('reports.index') },
                { name: 'Use Case Reports', link: 'reports-usecases', icon: 'view_list', show: this.$can('usecases.index') },
                { name: 'Insights', link: 'insights', icon: 'assessment', show: this.$can('reports.index') },
            ]
        },

        dataProjectViews () {
            return [
                { name: 'Projects', link: 'projects', icon: 'work', show: this.$can('projects.index') },
                { name: 'Accounts', link: 'customers', icon: 'account_box', show: this.$can('customers.index') },
                { name: 'Partners', link: 'partners', icon: 'contacts', show: this.$can('partners.index') },
            ]
        },

        dataIsProjectRoutes () {
            return this.dataRouteName === 'projects' || this.dataRouteName === 'customers' || this.dataRouteName === 'partners'
        },

        dataIsReportRoutes () {
            return this.dataRouteName === 'reports' || this.dataRouteName === 'insights' || this.dataRouteName === 'resource-planning' || this.dataRouteName === 'reports-usecases'
        },

        dataProjectClassHover () {
            const projectClass = !this.dataIsProjectRoutes ? 'c-menu-pointer--left' : ''
            return `u-rounded-corners-lg ${projectClass}`
        },

        dataReportClassHover () {
            const reportClass = !this.dataIsReportRoutes ? 'c-menu-pointer--left' : ''
            return `u-rounded-corners-lg ${reportClass}`
        },

        dataHasInventory () {
            const orgHasInventory = this.user_self && this.user_self.organization && this.user_self.organization.flag_inventory && this.user_self.organization.flag_inventory === 1
            if (this.mixinGetOrgPlan({ field: 'subscription_plan.level' }) !== 3) return orgHasInventory && (this.mixinGetPreference('organization.inventory_view') === 'Yes')
            return orgHasInventory
        },

        dataHasMeetings () {
            return !!(this.user_self && this.user_self.organization && this.user_self.organization.flag_meetings && this.user_self.organization.flag_meetings === 1)
        },

        ...mapState('User', {
            user_self: 'self'
        })
    }
}
</script>

<style lang="scss" scoped>
.c-nav-link {
    opacity: 0.5 !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in-out;
}

.active {
    padding: 0px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: 0.2s ease-in-out;
    &--bg {
        opacity: 1 !important;
        padding: 12px;
        background: #fff !important;
        box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.06),
            0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.06) !important;
    }
}
</style>

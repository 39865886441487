<template>
    <div>
        <a-layout align-center class="mt-2">
            <a-flex>
                <a-card class="u-elevation-custom-1 u-rounded-corners u-shadow">
                    <template v-for="(log, index) in logList">
                        <a-layout align-center class="ma-0 py-2 px-4 u-cursor-pointer" :key="'log_section_1'+index">
                            <a-flex shrink>
                                <div class="d-flex align-center">
                                    <img :src="require('../../../assets/images/apps/jira/logo.svg')" alt="Jira" style="display: inline-block; max-width: 25px; max-height: 25px;" v-if="log.workflow && log.workflow.trigger && log.workflow.trigger.service && log.workflow.trigger.service.slug === 'jira'">
                                    <img :src="require('../../../assets/images/success-imgs/icon-success.svg')" alt="Success" class="u-rounded-corners-lg" style="display: inline-block; max-width: 25px; max-height: 25px;" v-if="log.workflow && log.workflow.trigger && log.workflow.trigger.service && log.workflow.trigger.service.slug === 'success'">
                                    <a-icon class="mx-2">arrow_right_alt</a-icon>
                                    <img :src="require('../../../assets/images/apps/jira/logo.svg')" alt="Jira" style="display: inline-block; max-width: 25px; max-height: 25px;" v-if="log.workflow && log.workflow.action && log.workflow.action.service && log.workflow.action.service.slug === 'jira'">
                                    <img :src="require('../../../assets/images/success-imgs/icon-success.svg')" alt="Success" class="u-rounded-corners-lg"  style="display: inline-block; max-width: 25px; max-height: 25px;" v-if="log.workflow && log.workflow.action && log.workflow.action.service && log.workflow.action.service.slug === 'success'">
                                </div>
                            </a-flex>
                            <router-link tag="a-flex" class="shrink xl7 xs6" :to="{name: `appstore-jira-workflows-edit`, params: { id: $route.params.id, workflow_id: log.workflow.id }}">
                                <p :title="log.workflow.title" class="u-wfull md-body-2 px-4 mb-0 grey--text text--darken-1 u-cursor-pointer">
                                    {{ log.workflow.title }}
                                </p>
                            </router-link>
                            <a-spacer></a-spacer>
                            <a-flex shrink @click="localAccordionLogToggle(index)">
                                <div class="u-flex u-wfull">
                                    <div class="mr-5">
                                        <s-log-status  :label="log.status" :status="log.status"></s-log-status>
                                    </div>
                                    <div class="mr-5">
                                        <g-moment :value="log.created_at" class="grey--text text--darken-5" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                                    </div>
                                    <a-btn small icon class="ma-0 grey lighten-1" width="24" height="24">
                                        <a-icon v-if="localAccordionLogStatus(index)" size="18" color="white">expand_less</a-icon>
                                        <a-icon v-else size="18" color="white">expand_more</a-icon>
                                    </a-btn>
                                </div>
                            </a-flex>
                        </a-layout>
                        <template v-if="localAccordionLogStatus(index)">
                            <a-divider class="grey lighten-3" :key="'log_source_divider'+index"></a-divider>
                            <a-layout align-center class="grey lighten-4 ma-0 py-1 px-4" :key="'log_section_2'+index">
                                <a-flex shrink xs3>
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Source</h2>
                                    <p class="body-2 mb-0 mt-1 text-capitalize">{{ log.workflow.direction === 'outgoing' ? 'success' : 'jira' }}</p>
                                </a-flex>
                                <a-flex shrink xs3>
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Source Type</h2>
                                    <p class="body-2 mb-0 mt-1">{{ log.trigger_resource_type }}</p>
                                </a-flex>
                                <a-flex shrink xs3>
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Source Object Label</h2>
                                    <template v-if="log.request_payload && log.trigger_resource_id && log.status === 'success'">
                                        <template v-if="log.workflow.trigger && log.workflow.trigger.service && log.workflow.trigger.service.slug === 'jira' && log.trigger_resource_id && log.trigger_resource_type === 'Ticket' && log.request_payload.issue && log.request_payload.issue.fields">
                                            <a :href="log.request_payload.link"  target="_blank" rel="noopener" class="body-2 blue--text mb-0 mt-1" >
                                               {{ log.request_payload.issue.fields.summary | truncateText(35) }}
                                            </a>
                                        </template>
                                        <template v-if="log.workflow.trigger && log.workflow.trigger.service && log.workflow.trigger.service.slug === 'success' && log.trigger_resource_id && log.trigger_resource_type === 'Ticket'">
                                            <router-link target="_blank"  tag="a" class="body-2 blue--text mb-0 mt-1" :to="{name: `tickets-view`, params: { id: log.trigger_resource_id }}">
                                                {{ log.request_payload && log.request_payload.ticket ?  log.request_payload.ticket.title  : log.trigger_resource_id | truncateText(35) }}
                                            </router-link>
                                        </template>
                                    </template>
                                    <p v-else class="body-2 mb-0 mt-1">-</p>
                                </a-flex>
                                <a-flex shrink xs3>
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Request Payload</h2>
                                    <p @click="localSelectLogItem(log, 'request_payload')" class="body-2 u-cursor-pointer blue--text mb-0 mt-1">View Playload</p>                                    
                                </a-flex>
                            </a-layout>
                            <a-divider class="grey lighten-2" :key="'log_target_divider'+index"></a-divider>
                            <a-layout align-center class="grey lighten-4 ma-0 py-1 px-4" :key="'log_section_3'+index">
                                <a-flex shrink xs3>
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Target</h2>
                                    <p class="body-2 mb-0 mt-1 text-capitalize">{{ log.workflow.direction !== 'outgoing' ? 'success' : 'jira' }}</p>
                                </a-flex>
                                <a-flex shrink xs3>
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Target Type</h2>
                                    <p class="body-2 mb-0 mt-1">{{ log.action_resource_type }}</p>       
                                </a-flex>
                                <a-flex shrink xs3>
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Target Object Label</h2>
                                    <template v-if="log.response && log.trigger_resource_id && log.status === 'success'">
                                        <template v-if="log.workflow.action && log.workflow.action.service && log.workflow.action.service.slug === 'jira' && log.action_resource_id && log.action_resource_type === 'Ticket' && log.response.title">
                                            <a :href="log.response.link"  target="_blank" rel="noopener" class="body-2 blue--text mb-0 mt-1" >
                                               {{ log.response.title | truncateText(35) }}
                                            </a>
                                        </template>
                                        <template v-if="log.workflow.action && log.workflow.action.service && log.workflow.action.service.slug === 'success' && log.action_resource_id && log.action_resource_type === 'Ticket'">
                                            <router-link target="_blank"  tag="a" class="body-2 blue--text mb-0 mt-1" :to="{name: `tickets-view`, params: { id: log.action_resource_id }}">
                                                {{ log.response && log.response.title ?  log.response.title  : log.trigger_resource_id | truncateText(35) }}
                                            </router-link>
                                        </template>
                                    </template>
                                    <p v-else class="body-2 mb-0 mt-1">-</p>
                                </a-flex>
                                <a-flex shrink xs3>
                                    <template>
                                        <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Response Payload</h2>
                                        <p @click="localSelectLogItem(log, 'responce_payload')" class="body-2  u-cursor-pointer blue--text mb-0 mt-1">View Playload</p> 
                                    </template>                                   
                                </a-flex>
                            </a-layout>
                        </template>
                        <a-divider class="grey lighten-3" :key="'log_divider_last'+index"></a-divider>
                    </template>
                </a-card>
            </a-flex>
        </a-layout>
        <a-dialog v-model="dialogPayloadInformation" max-width="940">
            <a-card style="max-height: 700px;">
                <a-sheet class="py-5 px-6">
                    <p class="text-h6 indigo--text text--darken-1 font-weight-medium ma-0 pa-0">
                        <a-avatar class="indigo darken-1 mr-1" size="40">
                            <a-icon size="20" dark>dynamic_form</a-icon>
                        </a-avatar>
                        Payload Information
                    </p>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <a-sheet class="py-5 px-6 grey lighten-4" v-if="selectLogitem && selectLogitem.workflow">
                    <p class="md-subtitle-1 grey--text text--darken-4 mb-0 mr-3">{{ selectLogitem.workflow.title }}</p>
                    <div class="d-flex align-center mt-5">
                        <img src="../../../assets/images/success-imgs/icon-success.svg" v-if="selectLogitem.workflow.direction === 'outgoing'" alt="Success" style="display: inline-block; max-width: 25px; max-height: 25px;">
                        <img src="../../../assets/images/apps/jira/logo.svg" v-else alt="Salesforce" style="display: inline-block; max-width: 25px; max-height: 25px;">
                        <a-icon class="mx-2" size="16">arrow_forward</a-icon>
                        <img src="../../../assets/images/apps/jira/logo.svg" v-if="selectLogitem.workflow.direction === 'outgoing'" alt="Salesforce" style="display: inline-block; max-width: 25px; max-height: 25px;">
                        <img src="../../../assets/images/success-imgs/icon-success.svg" v-else alt="Success" style="display: inline-block; max-width: 25px; max-height: 25px;">
                        <span class="px-2 grey--text text--darken-1">&bull;</span>
                        <s-log-status :label="selectLogitem.status" :status="selectLogitem.status"></s-log-status>
                    </div>
                </a-sheet>
                <a-sheet class="py-5 px-6 mt-2" v-if="selectLogitem">
                    <p class="md-body-2 font-weight-bold text-uppercase mb-0 grey--text text--darken-2">{{ selectLogitem.payload_type === 'request_payload' ? 'Request Payload': 'Response Payload' }}:</p>
                    <div class="grey lighten-4 pa-5 mt-2" style="border: 1px solid #EEEEEE !important; border-radius: 4px;">
                        <template v-if="selectLogitem.payload_type === 'request_payload'">
                            <pre style="white-space: pre-wrap !important;" v-if="selectLogitem.status === 'success' && selectLogitem.workflow.trigger && selectLogitem.workflow.trigger.service && selectLogitem.workflow.trigger.service.slug === 'success'">{{ selectLogitem.request_payload && selectLogitem.request_payload.request_payload ? selectLogitem.request_payload.request_payload : '-' }}</pre>
                            <pre style="white-space: pre-wrap !important;" v-else>{{ selectLogitem.request_payload ? selectLogitem.request_payload : '-' }}</pre>
                        </template>
                        <pre style="white-space: pre-wrap !important;" v-if="selectLogitem.payload_type === 'responce_payload'">{{ selectLogitem.response ? selectLogitem.response : '-' }}</pre>
                    </div>
                </a-sheet>
                <!-- <a-sheet class="px-6">
                    <table class="c-table u-wfull">
                        <template v-if="selectLogitem && selectLogitem.conditions && selectLogitem.conditions.fields && selectLogitem.conditions.fields.length">
                            <a-card-text class="pa-0"  style="max-height: 500px; overflow-y: auto">
                                <p class="md-body-2 font-weight-bold text-uppercase mb-0 grey--text text--darken-2">Condition:</p>
                                <table class="c-table-desc u-wfull grey lighten-4 pa-5 mt-2" style="border-collapse: collapse; border: 1px solid #000">
                                    <tr class="" style="text-align: left;">
                                        <th class="c-table-desc__cell">Field</th>
                                        <th class="c-table-desc__cell">Condition Value</th>
                                        <th class="c-table-desc__cell">Operator</th>
                                        <th class="c-table-desc__cell">Source value</th>
                                        <th class="c-table-desc__cell" style="text-align: center;">Valid</th>
                                    </tr>
                                    <template v-for="item in selectLogitem.conditions.fields">
                                        <tr class="" :key="item.id">
                                            <td class="c-table-desc__cell">
                                                <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important">{{ item.name }}</div>
                                            </td>
                                            <td class="c-table-desc__cell">
                                                <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important">{{ item.condition_value }}</div>
                                            </td>
                                            <td class="c-table-desc__cell">
                                                <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important">{{ item.operator }}</div>
                                            </td>
                                            <td class="c-table-desc__cell">
                                                <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important">
                                                    {{ item.source_value && item.condition_value_alt ? item.source_value+' - '+item.condition_value_alt : item.source_value }} 
                                                </div>
                                            </td>
                                            <td class="c-table-desc__cell">
                                                <div class="md-caption" style="text-align: center;" v-if="item.is_valid === true">
                                                    <a-icon color="light-green darken-2" size="16">check_circle</a-icon>
                                                </div>
                                                <div class="md-caption" style="text-align: center;" v-if="item.is_valid === false">
                                                    <a-icon color="red darken-2" size="16">error</a-icon>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                            </a-card-text>
                        </template>
                    </table>
                </a-sheet> -->
            </a-card>
        </a-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { SLogStatus } from '@/config/config-shared-components'

export default {
    components: {
        SLogStatus
    },
    props: {
        logList: {
            type: Array
        },
    },
    data() {
        return {
            accordionLog: [],
            dialogPayloadInformation: false,
            selectLogitem: null,
        }
    },
    computed: {
        localHubspotInstanceUrl() {
            return this.user_self && this.user_self.organization && this.user_self.organization.hubspot_instance_url
        },

        localHubspotPortalId () {
            return this.user_self && this.user_self.organization && this.user_self.organization.hubspot_portal_id
        },

        ...mapState('MicroServiceWorkflow', {
            microservice_workflow_list: 'list',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        localCheckSourece (source_service, target_service = null) {
            return (source_service === 'salesforce' &&  target_service === 'success')
        },

        localGetResponseMessage (item) {
            if (item.status === 'invalid') {
                if (!item.condition && item.response) return JSON.parse(item.response).message
                if (item.conditions && !item.conditions.result) return 'Conditions not satisfied'
            }

            if (item.status === 'success') return 'Successfully connected'
            if (item.status === 'incomplete') return 'Workflow failed, please contact support'
            if (item.status === 'error') return `HTTP request returned status code ${ item.code }`

            return null
        },

        localSelectLogItem (item, payload_type = null) {
            this.selectLogitem = item
            this.selectLogitem.payload_type = payload_type;
            this.dialogPayloadInformation = true
        },

        localShowLogEntriesInformation(item) {
            this.$emit('action', item)
        },

        localAccordionLogStatus (index) {
            if (this.accordionLog[index]) return this.accordionLog[index]
            this.accordionLog[index] = false
            return this.accordionLog[index]
        },

        localAccordionLogToggle (index) {
            if (this.accordionLog[index] === undefined || this.accordionLog[index] === null) {
                this.$set(this.accordionLog, index, true);
            } 
            this.$set(this.accordionLog, index, !this.accordionLog[index]);
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-table {
        display: table !important;
        border-collapse: separate;
        border-spacing: 0 15px;
        &__cell {
            display: table-cell !important;
            white-space: nowrap !important;
            background: #fff !important;
            &:first-child {
                border-radius: 4px 0px 0px 4px;
            }
            &:last-child {
                border-radius: 0px 4px 4px 0px;
            }
        }
    }
    .c-table-desc {
        display: table;
        border-collapse: collapse !important;
        &__cell {
            display: table-cell !important;
            padding: 8px;
            vertical-align: top !important;
            white-space: normal !important;
            border: 1px solid #e4e4e4;
            &:first-child {
                border-radius: 4px 0px 0px 4px;
            }
            &:last-child {
                border-radius: 0px 4px 4px 0px;
            }
        }
    }
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"refParent",staticClass:"u-no-select pb-16 u-relative",staticStyle:{"overflow":"auto","scroll-behaviour":"smooth"},style:([
        _vm.currentResizeEl ? { userSelect: 'none' } : '',
        { height: `calc(100vh - ${_vm.parentHeightControl}px)` }
    ])},[_c('div',{class:['u-relative', 'c-magic-table'],style:([{ minWidth: _vm.getFlexBasis }])},[_c('div',{staticClass:"c-magic-table__header-row white",style:([
            { display: 'flex', position: 'sticky', left: '0px', top: '0px', zIndex: 5 },
            { borderBottom: '1px solid rgba(0,0,0,0.1) !important' },
            { borderTop: '1px solid rgba(0,0,0,0.1) !important' },
        ])},[_c('div',{staticClass:"white py-3 md-body-2 grey--text text--darken-1 c-magic-table__header-cell c-magic-table__header-cell--sticky",style:([
                    { position: 'sticky', left: '0px' },
                    { flexBasis: _vm.localGetFirstCol ? (_vm.localGetFirstCol.minWidth === 'full' ? '100%' : _vm.localGetFirstCol.minWidth + 'px') : '500px', zIndex: 3, flexShrink: 0 },
                    _vm.scrolledLeft ? { boxShadow: '0px 2px 15px 0px rgba(0,0,0,0.2)', clipPath: 'inset(0px -15px 0px -15px)' } : '',
                    _vm.localSelectedColumns.length > 1 ? { borderRight: '1px solid rgba(0,0,0,0.1) !important' } : '',
                    _vm.currentResizeEl ? { userSelect: 'none' } : ''
                ]),attrs:{"data-col-position":"0"}},[_c('div',{staticClass:"u-flex-center-y"},[_c('div',{staticStyle:{"min-width":"40px","height":"100%"}}),_vm._v(" "+_vm._s(_vm.localGetFirstCol ? _vm.localGetFirstCol.label : '')+" ")])]),(_vm.columns.length > 1)?[_c('div',{staticClass:"c-magic-table__header-non-sticky",style:([{ position: 'relative', flexBasis: _vm.getFlexBasis, display: 'flex' }])},_vm._l((_vm.localNonStickCols),function(col,index){return _c('div',{key:col.slug + '-header',staticClass:"white u-relative c-magic-table--header-drag c-magic-table__header-cell",style:([
                            _vm.localGetBorder(col, index),
                            { minWidth: col.minWidth === 'full' ? '100%' : (col.minWidth + 'px' || '200px'), cursor: 'grab' },
                            _vm.currentResizeEl ? { userSelect: 'none' } : ''
                        ]),attrs:{"data-col-position":index + 1}},[(col.slug !== 'add-col')?[_c('div',{staticClass:"px-3 py-3 md-body-2 grey--text text--darken-1"},[_vm._v(_vm._s(col.label))])]:[(_vm.hideAddColumn)?[_c('a-card',{staticClass:"u-wfull text-left u-flex-center-y px-3 py-3 u-wfull",attrs:{"flat":"","tile":"","min-width":"50","max-width":"50","height":"100%"}},[_vm._v("      ")])]:_c('a-menu',{attrs:{"bottom":"","offset-y":"","max-width":"200","content-class":"white"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('a-card',_vm._g({staticClass:"u-wfull text-left u-flex-center-y px-3 py-3 u-wfull",attrs:{"flat":"","tile":"","height":"100%"}},on),[_c('a-icon',{attrs:{"size":"20","color":"grey darken-1"}},[_vm._v("add")])],1)]}}],null,true)},[_c('a-list',{staticClass:"u-list-std c-tiny-scroll",staticStyle:{"max-height":"250px"}},_vm._l((_vm.localColumnsSelectMenu),function(colItem){return _c('a-list-item',{key:colItem.slug,on:{"click":function($event){return _vm.localToggleColumn(colItem)}}},[_c('div',{staticClass:"u-flex-center-y",staticStyle:{"row-gap":"12px"}},[_c('a-checkbox',{staticClass:"ma-0 pa-0",attrs:{"input-value":colItem.selected,"ripple":false,"hide-details":"","dense":""},on:{"click":function($event){$event.stopPropagation();return _vm.localToggleColumn(colItem)}}}),_c('span',{staticClass:"md-subtitle-2 grey--text text--darken-3"},[_vm._v(_vm._s(colItem.label))])],1)])}),1)],1)]],2)}),0)]:_vm._e()],2),_vm._l((_vm.listItems),function(row,rowIndex){return [_c('div',{key:row.id,ref:`refRow-${row._order}`,refInFor:true,staticClass:"u-wfull c-magic-table__row",class:[{ 'js-task-draggable-item': !_vm.bulkMode }],style:([
                    { display: 'flex', borderBottom: '1px solid rgba(0,0,0,0.1) !important' },
                    _vm.currentResizeEl ? { userSelect: 'none' } : ''
                ]),attrs:{"data-row-order":row._order},on:{"dragstart":function($event){return _vm.localListDragStart($event, row, rowIndex)},"dragover":function($event){$event.preventDefault();return _vm.localListDragOver($event, row, rowIndex)},"dragenter":function($event){$event.preventDefault();return _vm.localListDragEnter($event, row, rowIndex)},"dragleave":function($event){return _vm.localListDragLeave($event, row, rowIndex)},"drop":function($event){return _vm.localListDragDrop($event, row, rowIndex)}}},[_c('div',{ref:`refCell-${rowIndex}-0-id-${row.id}`,refInFor:true,staticClass:"c-magic-table__cell c-magic-table__cell-sticky",class:[
                        { 'u-cursor-pointer': _vm.canUpdate },
                        { 'c-cell-row-hovered': _vm.localIsRowHovered(row._order) && !(_vm.localIsRowSelected(row.id) && _vm.selectedRows.length) },
                        { 'c-cell-keyed': (_vm.localIsRowSelected(row.id) && _vm.selectedRows.length) },
                    ],style:([
                        _vm.localIsCellHovered(row._order, 0) ?
                            (_vm.localIsRowSelected(row.id) && _vm.selectedRows.length) ?
                                { backgroundColor: 'rgb(209, 234, 253) !important' } :
                                { backgroundColor: 'rgb(229, 244, 255) !important' }
                            : '',
                        _vm.localIsCellEdited(row._order, 0) ? { backgroundColor: 'rgb(255, 255, 255) !important' } : '',
                        _vm.scrolledLeft ? { boxShadow: '0px 2px 15px 0px rgba(0,0,0,0.2)', clipPath: 'inset(0px -15px 0px -15px)' } : '',
                        { position: 'sticky', left: '0px' },
                        _vm.localSelectedColumns.length > 1 ? { borderRight: '1px solid rgba(0,0,0,0.1) !important' } : '',
                        { flexBasis: _vm.localGetFirstCol ? (_vm.localGetFirstCol.minWidth === 'full' ? '100%' : _vm.localGetFirstCol.minWidth + 'px') : '500px', zIndex: 3, flexShrink: 0 },
                    ]),attrs:{"data-cell-slug":"title"},on:{"click":function($event){_vm.canUpdate ? _vm.localEditCell($event, row, 0) : ''}}},[_c('div',{staticClass:"u-flex-center-y u-no-select",staticStyle:{"height":"56px"},on:{"click":function($event){$event.stopPropagation();!_vm.avoidValueToggle.includes('title') && _vm.canUpdate ? _vm.localEditCell($event, row, 0, _vm.localGetFirstCol) : ''}}},[_c('div',{staticClass:"c-magic-table__row-drag-select u-flex-center",class:[{ 'u-no-select': _vm.bulkMode }],staticStyle:{"min-width":"40px","height":"100%"},on:{"click":function($event){$event.stopPropagation();{}}}},[(!_vm.bulkMode)?_vm._t("itemIcon",null,{"handle":"js-task-drag-handle","canUpdate":_vm.canUpdate,"item":row,"isHovered":row.id === _vm.itemHoverElementId}):_vm._e()],2),(_vm.localIsCellClicked(row, 0))?_vm._t("cellItem",function(){return [_vm._v(" "+_vm._s(row.title || ' - ')+" ")]},{"colSlug":"title","item":row,"column":_vm.localGetFirstCol,"closeCell":_vm.localRemoveCellSelection,"isActive":_vm.localIsCellClicked(row, 0)}):_vm._t("cellDisplay",function(){return [_vm._v(" "+_vm._s(row.title || ' - ')+" ")]},{"colSlug":"title","item":row,"column":_vm.localGetFirstCol,"closeCell":_vm.localRemoveCellSelection,"switchDisplay":($event) => !_vm.avoidValueToggle.includes('title') && _vm.canUpdate ? _vm.localEditCell($event, row, 0, _vm.localGetFirstCol) : ''})],2)]),(_vm.columns.length > 1)?[_c('div',{style:([{ position: 'relative', flexBasis: _vm.getFlexBasis, display: 'flex' }])},_vm._l((_vm.localNonStickCols),function(col,colIndex){return _c('div',{key:col.slug + '-cell',ref:`refCell-${row._order}-${col._order}-id-${row.id}`,refInFor:true,staticClass:"c-magic-table__cell",class:[
                                { 'u-cursor-pointer': _vm.canUpdate },
                                { 'c-cell-row-hovered': _vm.localIsRowHovered(row._order) && !(_vm.localIsRowSelected(row.id) && _vm.selectedRows.length) },
                                { 'c-cell-keyed': (_vm.localIsRowSelected(row.id) && _vm.selectedRows.length) },
                            ],style:([
                                _vm.localIsCellHovered(row._order, colIndex + 1) ?
                                    (_vm.localIsRowSelected(row.id) && _vm.selectedRows.length) ?
                                        { backgroundColor: 'rgb(209, 234, 253) !important' } :
                                        { backgroundColor: 'rgb(229, 244, 255) !important' }
                                    : '',
                                _vm.localIsCellEdited(row._order, colIndex + 1) ? { backgroundColor: 'rgb(255, 255, 255) !important' } : '',
                                _vm.localGetBorder(col, colIndex),
                                { minWidth: col.minWidth + 'px' || '200px' },
                                { maxWidth: col.minWidth + 'px' || '200px' },
                            ]),attrs:{"data-cell-slug":col.slug}},[(col.slug !== 'add-col')?_c('div',{staticClass:"u-no-select u-wfull u-flex align-stretch",style:([{ height: '56px' }])},[(_vm.localIsCellClicked(row, colIndex + 1))?_vm._t("cellItem",function(){return [_vm._v(" "+_vm._s(row[col.slug] || ' - ')+" ")]},{"item":row,"colSlug":col.slug,"column":col,"closeCell":_vm.localRemoveCellSelection,"isActive":_vm.localIsCellClicked(row, colIndex + 1)}):_vm._t("cellDisplay",function(){return [_vm._v(" "+_vm._s(row[col.slug] || ' - ')+" ")]},{"item":row,"colSlug":col.slug,"column":col,"closeCell":_vm.localRemoveCellSelection,"switchDisplay":($event) => col.slug !== 'add-col' && !_vm.avoidValueToggle.includes(col.slug) && _vm.canUpdate ? _vm.localEditCell($event, row, colIndex + 1, col) : ''})],2):[_vm._v("  ")]],2)}),0)]:_vm._e()],2)]})],2),(_vm.canUpdate)?_c('div',{staticClass:"u-sticky u-flex",style:([{
            borderBottom: '1px solid rgba(0,0,0,0.1)',
            height: '50px',
            left: '0px',
            cursor: 'text'
        }]),on:{"click":function($event){return _vm.localEmitCreate()}}},[_c('div',{staticClass:"white u-sticky u-hfull u-wfull u-flex-center-y grey--text px-10",style:([{ left: '0px' }])},[_vm._v(" Add new milestone... ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
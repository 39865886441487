<template>
    <v-select v-model="showModel" v-on="$listeners" v-bind="$attrs">
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
        <template v-for="(_, slotName) in slots" v-slot:[slotName]>
            <slot :name="slotName" />
        </template>
        <!-- <template v-slot:no-data>
            <slot name="no-data">
                <span class="md-body-2 grey--text text--darken-1">No Data Found</span>
            </slot>
        </template> -->
    </v-select>
</template>

<script>
import { VSelect } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VSelect },
    props: ['value'],

    data () {
        return {
            ref: null,
            slots: {}
        }
    },

    mounted () {
        this.local_set_slots()
        this.ref = this.$parent.$children && this.$parent.$children[0] &&
                        this.$parent.$children[0].$children && this.$parent.$children[0].$children[0] ?
                        this.$parent.$children[0].$children[0] : null
    },

    computed: {
        showModel: {
            get () {
                return this.value
            },
            set (val) {
                this.$emit('update:value', val)
            }
        }
    },

    methods: {
        local_set_slots () {
            const scopedSlotsList = ['counter', 'item', 'message', 'selection']
            for (const key in this.$scopedSlots) {
                if (Object.hasOwnProperty.call(this.$scopedSlots, key)) {
                    const slotFunction = this.$scopedSlots[key]
                    if (!scopedSlotsList.includes(key)) this.slots[key] = slotFunction
                }
            }
        }
    },
}
</script>

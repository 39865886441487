const number = (e) => {
    const key = e.key
    const restrictedKeys = ['e', '+', '-', '.', '+', '-', '.']
    const allowedKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete']

    // Disallow dot (.) and (e) input
    if (restrictedKeys.indexOf(key) !== -1) return false

    // Disallow alphabets
    if (!e.key.match(/^[0-9]+$/) && allowedKeys.indexOf(key) === -1) return false

    // If number
    return true
}

export { number }

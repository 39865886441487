<template>
	<a-main>
        <a-responsive class="grey darken-4" style="margin-top: -4px;">
            <a-container grid-list-xl class="py-2">
                <a-layout align-start>
                    <a-flex shrink class="px-0">
                        <a-btn color="white" flat :to="{name: 'appstore-installation-configure'}">
                            <a-icon class="mr-1">arrow_back</a-icon> Back to Configuration
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive>
        <a-divider class="my-0"></a-divider>
        <a-divider class="my-0 mb-3"></a-divider>

        <!-- Title -->
        <a-container grid-list-xl class="py-3">
            <a-layout align-center>
                <a-flex>
                    <h2 class="md-heading-4">Workflow based on <span class="md-heading-4 blue--text text--darken-1">{{ workflow_item.type }}</span></h2>
                    <p class="md-body-1 mb-0">{{ workflow_item.type }}</p>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Workflow Title -->
        <a-container grid-list-xl class="py-3">
            <a-layout align-center>
                <a-flex shrink>
                    <a-avatar size="40" color="blue darken-2">
                        <span class="md-subtitle-1 font-weight-bold white--text">1</span>
                    </a-avatar>
                </a-flex>
                <a-flex>
                    <h3 class="md-heading-5">What would like to call this workflow?</h3>
                    <p class="md-body-2 mb-0 grey--text text--darken-3">You can customize the name of your workflow to help you identify later.</p>
                </a-flex>
            </a-layout>
            <a-layout align-start>
                <a-flex shrink>
                    <a-avatar size="40" color="neutral">
                    </a-avatar>
                </a-flex>
                <a-flex xs8>
                    <a-text-field
                        v-model="workflow_item.title"
                        placeholder="Enter Workflow Title"
                        class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                        flat solo hide-details
                    >
                    </a-text-field>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Source Object -->
        <a-container grid-list-xl class="py-3">
            <a-layout align-center>
                <a-flex shrink>
                    <a-avatar size="40" color="blue darken-2">
                        <span class="md-subtitle-1 font-weight-bold white--text">2</span>
                    </a-avatar>
                </a-flex>
                <a-flex>
                    <h3 class="md-heading-5">What is the primary object to pull from Salesforce?</h3>
                    <p class="md-body-2 mb-0 grey--text text--darken-3">You can select relationships, fields and other related data in next step.</p>
                </a-flex>
            </a-layout>
            <a-layout align-start>
                <a-flex shrink>
                    <a-avatar size="40" color="neutral">
                    </a-avatar>
                </a-flex>
                <a-flex xs8>
                    <a-autocomplete
                        v-model="source_object"
                        :items="source_objects"
                        item-text="label"
                        item-value="name"
                        placeholder="Select Primary Object"
                        class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                        flat solo hide-details
                    >
                    </a-autocomplete>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Mapping Fields & Relationships -->
        <a-container grid-list-xl class="py-3">
            <a-layout align-center>
                <a-flex shrink>
                    <a-avatar size="40" color="blue darken-2">
                        <span class="md-subtitle-1 font-weight-bold white--text">3</span>
                    </a-avatar>
                </a-flex>
                <a-flex>
                    <h3 class="md-heading-5">Which fields and relational data to pull?</h3>
                    <p class="md-body-2 mb-0 grey--text text--darken-3">You can use these information to define condition and add as project value.</p>
                </a-flex>
            </a-layout>
            <a-layout align-start>
                <a-flex shrink>
                    <a-avatar size="40" color="neutral">
                    </a-avatar>
                </a-flex>
                <a-flex xs8>
                    <a-autocomplete
                        v-model="source_object_fields_selected"
                        :items="source_object_fields"
                        item-text="label"
                        item-value="name"
                        placeholder="Select Fields"
                        class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                        flat solo hide-details multiple chips small-chips
                    >
                    </a-autocomplete>
                </a-flex>
            </a-layout>
            <a-layout align-start>
                <a-flex shrink>
                    <a-avatar size="40" color="neutral">
                    </a-avatar>
                </a-flex>
                <a-flex xs8>
                    <a-autocomplete
                        v-model="source_object_relationships_parent_selected"
                        :items="source_object_relationships_parent"
                        item-text="label"
                        item-value="name"
                        placeholder="Select Parent Relationships"
                        class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                        flat solo hide-details multiple chips small-chips
                    >
                    </a-autocomplete>
                </a-flex>
            </a-layout>
            <a-layout align-start>
                <a-flex shrink>
                    <a-avatar size="40" color="neutral">
                    </a-avatar>
                </a-flex>
                <a-flex xs8>
                    <a-autocomplete
                        v-model="source_object_relationships_child_selected"
                        :items="source_object_relationships_child"
                        item-text="label"
                        item-value="name"
                        placeholder="Select Child Relationships"
                        class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                        flat solo hide-details multiple chips small-chips
                    >
                    </a-autocomplete>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Conditions -->
        <a-container grid-list-xl class="py-3">
            <a-layout align-center>
                <a-flex shrink>
                    <a-avatar size="40" color="blue darken-2">
                        <span class="md-subtitle-1 font-weight-bold white--text">4</span>
                    </a-avatar>
                </a-flex>
                <a-flex>
                    <h3 class="md-heading-5">What are the conditions to check?</h3>
                    <p class="md-body-2 mb-0 grey--text text--darken-3">Define conditions and import only matching results.</p>
                </a-flex>
            </a-layout>
            <a-layout align-start>
                <a-flex shrink>
                    <a-avatar size="40" color="neutral">
                    </a-avatar>
                </a-flex>
                <a-flex xs10>
                    <a-card flat class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                        <a-card-text class="pa-0">
                            <a-container grid-list-xl class="py-3">
                                <a-layout align-center>
                                    <a-flex shrink class="pr-0">
                                        <img :src="workflow_item.installation.applet.icon" :alt="workflow_item.installation.applet.title" style="display: block; max-width: 36px; max-height: 36px;">
                                    </a-flex>
                                    <a-flex>
                                        <h2 class="md-subtitle-1 font-weight-bold grey--text text--darken-3">Pull data from {{ workflow_item.configurations.source.name }} if it matches following criteria</h2>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <a-container grid-list-xl class="py-3">
                                <a-layout align-center>
                                    <a-flex shrink class="pr-0">
                                        <a-tooltip bottom>
                                            <a-btn icon dark color="grey darken-2" class="mx-0 elevation-0" slot="activator">
                                                <a-icon size="16">add</a-icon>
                                            </a-btn>
                                            <span>Default Account</span>
                                        </a-tooltip>
                                    </a-flex>
                                    <a-flex>
                                        <p class="md-body-1 mb-1">Add Condition</p>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Result -->
        <a-container grid-list-xl class="py-3 mb-4">
            <a-layout align-center>
                <a-flex shrink>
                    <a-avatar size="40" color="blue darken-2">
                        <span class="md-subtitle-1 font-weight-bold white--text">5</span>
                    </a-avatar>
                </a-flex>
                <a-flex>
                    <h3 class="md-heading-5">How do you want to create project?</h3>
                    <p class="md-body-2 mb-0 grey--text text--darken-3">Define field values to automatically create project.</p>
                </a-flex>
            </a-layout>
            <a-layout align-start>
                <a-flex shrink>
                    <a-avatar size="40" color="neutral">
                    </a-avatar>
                </a-flex>
                <a-flex xs10>
                    <a-card flat class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                        <a-card-text class="pa-0">
                            <a-container grid-list-xl class="py-3">
                                <a-layout align-center>
                                    <a-flex shrink class="pr-0">
                                        <img src="/images/success-imgs/icon-success.svg" alt="Success" style="display: block; max-width: 36px; max-height: 36px;">
                                    </a-flex>
                                    <a-flex>
                                        <h2 class="md-subtitle-1 font-weight-bold grey--text text--darken-3">Create a new project in {{ workflow_item.configurations.destination.name }}</h2>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <div v-for="field in workflow_item.configurations.destination.fields" :key="field.name">
                                <a-divider></a-divider>
                                <a-container grid-list-xl class="py-3">
                                    <a-layout align-center>
                                        <a-flex shrink class="pr-0">
                                            <a-tooltip bottom v-if="field.required">
                                                <a-icon size="16" slot="activator" color="red" class="u-icon-nudge">trip_origin</a-icon>
                                                <span>Mandatory Field</span>
                                            </a-tooltip>
                                            <a-tooltip bottom v-else>
                                                <a-icon size="16" slot="activator" color="grey lighten-2" class="u-icon-nudge">trip_origin</a-icon>
                                                <span>Optional Field</span>
                                            </a-tooltip>
                                        </a-flex>
                                        <a-flex xs3>
                                            <p class="md-subtitle-1 mb-0">{{ field.label }}</p>
                                        </a-flex>
                                        <a-flex>
                                            <a-text-field
                                                :placeholder="field.label"
                                                class="elevation-0 u-rounded-corners u-overflow-hidden"
                                                background-color="neutral"
                                                flat solo hide-details
                                            >
                                            </a-text-field>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </div>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
            <a-layout align-start>
                <a-flex shrink>
                    <a-avatar size="40" color="neutral">
                    </a-avatar>
                </a-flex>
                <a-flex>
                    <a-btn color="primary" class="mx-0">Save Wokflow</a-btn>
                    <a-btn color="grey" flat small>Cancel</a-btn>
                </a-flex>
            </a-layout>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            temp_workflows: [],
            source_objects: [],
            source_object: null,
            source_object_fields: [],
            source_object_fields_selected: [],
            source_object_relationships_parent: [],
            source_object_relationships_parent_selected: [],
            source_object_relationships_child: [],
            source_object_relationships_child_selected: [],
            dialog_worklow_selection: false,
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Apps', to: '/appstore', exact: true})
            breadcrumbs.push({text: 'Configure Workflow', to: '/appstore/installed', exact: true})
            return breadcrumbs
        },
        ...mapState('Workflow', {
            workflow_item: 'item',
        }),
        ...mapState('AppletSalesforce', {
            applet_salesforce_list: 'list',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    watch: {
        async source_object(newValue, oldValue) {
            if (newValue !== null) {
                await this.applet_salesforce_object_describe({object_name: newValue, installation_id: this.workflow_item.installation.uuid})
            } else {
                this.source_object_fields = []
                this.source_object_relationships_parent = []
                this.source_object_relationships_child = []
            }
        }
    },
    methods: {
        async local_index() {
            if (this.user_self.role.scope === 'external') {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.workflow_clear_item()
            await this.workflow_show({uuid: this.$route.params.workflow_uuid})

            await this.applet_salesforce_object_index({installation_id: this.workflow_item.installation.uuid});
            this.source_objects = _.cloneDeep(this.applet_salesforce_list)
        },
        ...mapActions('Workflow', {
            workflow_show: 'show',
            workflow_clear_item: 'clear_item',
        }),
        ...mapActions('AppletSalesforce', {
            applet_salesforce_object_index: 'object_index',
            applet_salesforce_object_describe: 'object_describe',
            applet_salesforce_clear: 'clear',
            applet_salesforce_clear_item: 'clear_item',
        }),
    },
}
</script>

<template>
    <a-main>
        <a-container container--fluid grid-list-xl class="pb-16 pr-6 mx-0 mb-16 pa-0">
            <g-page-header
                title="Tasks"
                subtitle="All tasks from the projects that you are collaborating can be managed."
                :loading="local_loading"
                icon="check_circle"
            >
                <template v-slot:action v-if="permission_task_store()">
                    <a-btn color="primary" class="mx-0 elevation-0"  @click="local_task_create()">
                        <a-icon class="mr-2" color="white" size="18">add</a-icon>
                        Create New Task
                    </a-btn>
                </template>
            </g-page-header>
            <a-container fluid class="mt-4 mb-6 pa-0">
                <a-layout align-center>
                    <a-flex shrink>
                        <a-card
                            @click="local_show_filters_pane()"
                            class="pl-2 pr-3 md-body-2 u-border u-rounded-corners xs-mt-1 xl-mt-0 d-flex align-center u-cursor-pointer"
                            min-height="40"
                            flat
                        >
                            <a-icon size="18" :class="[local_check_no_filters ? 'grey--text text--lighten-1' : 'blue--text']" class="mr-1">filter_alt</a-icon>
                            <span class="md-body-2 font-weight-medium grey--text text--darken-2">Filters</span>
                        </a-card>
                    </a-flex>
                    <a-flex shrink class="pl-1" style="width: 240px">
                        <SharedDropdownWrapper
                            :isActive="local_team_filter.value !== 'all'"
                            :list="local_team_filter_list"
                            :disabled="page_loading"
                            item-value="value"
                            item-text="label"
                            @action="e => local_team_filter_set(e.label, e.value)"
                            flat
                        >
                            <span class="mx-1 md-body-2" v-if="!page_loading">
                                <template v-if="user_self && user_self.is_manager">Team:</template>
                                {{ local_team_filter.label }}
                            </span>
                            <span v-else>
                                <loader-template height="24" width="140px" class="ml-2 u-rounded-corners"></loader-template>
                            </span>
                        </SharedDropdownWrapper>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink style="width: 240px">
                        <SharedDropdownWrapper
                            :isActive="local_filter_value_get(filter_list_group_by, task_group_by, 'value') !== 'project_id'"
                            :list="filter_list_group_by"
                            :disabled="page_loading"
                            item-value="value"
                            item-text="label"
                            @action="e => local_group(e.value)"
                            flat
                        >
                            <span class="mx-1 md-body-2">{{ local_filter_value_get(filter_list_group_by, task_group_by, 'label') }}</span>
                        </SharedDropdownWrapper>
                    </a-flex>
                    <a-flex shrink class="pl-1" style="width: 208px">
                        <SharedDropdownWrapper
                            :isActive="local_filter_value_get(filter_list_sort_field, local_task_filters[task_filter.SORT], 'value') !== 'title'"
                            :list="filter_list_sort_field"
                            :disabled="page_loading"
                            item-value="value"
                            item-text="label"
                            @action="e => local_filter_value_set(task_filter.SORT, e.value)"
                            flat
                        >
                            <span class="mx-1 md-body-2">Sort by: {{ local_filter_value_get(filter_list_sort_field,local_task_filters[task_filter.SORT], 'label') }}</span>
                        </SharedDropdownWrapper>
                    </a-flex>
                    <a-flex shrink class="pl-1">
                        <a-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a-card
                                    v-on="on"
                                    class="px-2 py-2 u-rounded-corners u-overflow-hidden u-border u-cursor-pointer u-flex-center"
                                    height="40"
                                    width="40"
                                    @click="local_filter_value_set('sort_direction', (sort_direction === '-') ? '' : '-')"
                                    flat
                                >
                                    <a-icon size="20" class="ma-0">{{ (sort_direction === '') ? 'arrow_downward' : 'arrow_upward' }}</a-icon>
                                </a-card>
                            </template>
                            <span v-if="sort_direction === ''">Sort Descending</span>
                            <span v-if="sort_direction === '-'">Sort Ascending</span>
                        </a-tooltip>
                    </a-flex>
                    <a-flex shrink class="pl-1">
                        <a-progress-circular color="orange darken-2" size="24" width="2" :indeterminate="loading"></a-progress-circular>
                    </a-flex>
                </a-layout>
            </a-container>
            <a-layout v-if="page_loading">
                <a-flex>
                    <a-responsive class="my-4">
                        <h3 class="mb-1 text-center md-subtitle-1 font-weight-bold grey--text text--darken-1 text-capitalize">Fetching Tasks..</h3>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <a-layout v-if="(!page_loading && task_list && !task_list.length) && (local_scope_message || checkscope_message)">
                <a-flex>
                    <a-responsive class="my-16" v-if="!page_loading && task_list && !task_list.length && local_scope_message">
                        <h3 class="mb-1 text-center md-subtitle-1 font-weight-bold grey--text text--darken-1 text-uppercase">No Data Found</h3>
                        <p class="mb-0 text-center md-body-2 grey--text">There is no data to fetch right now.<br>Try narrowing your filter or add new task.</p>
                    </a-responsive>
                    <a-responsive class="my-16" v-if="!page_loading && !task_list.length && checkscope_message">
                        <h3 class="mb-1 text-center md-subtitle-1 font-weight-bold grey--text text--darken-1 text-uppercase">There are no projects available to display.</h3>
                        <p class="mb-0 text-center md-body-2 grey--text">The view may affected due to role based access control. Please check your configuration or contact your admin.</p>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <a-layout align-start v-if="!page_loading">
                <a-flex>
                    <PartialTaskHeaderList
                        :taskGroup="local_render"
                        :groupBy="task_group_by"
                        :tasksRange="tasksRange"
                    >
                        <template #tasks-list="{ header, tasks, headerIndex }">
                            <PartialTaskList
                                :header="header"
                                :headerIndex="headerIndex"
                                :tasks="tasks"
                                :tasksRange="tasksRange"
                                :groupBy="task_group_by"
                            >
                                <template #item="{ item }">
                                    <PartialTaskItem
                                        :task="item"
                                        :taskItem="local_task_item"
                                        :groupBy="task_group_by"
                                        @openEditView="local_select"
                                        @openTimeTrack="local_open_time_track"
                                    >
                                        <template #status-meter="{ task }">
                                            <GNonActiveInfo
                                                :project="task && task.project"
                                                :stage="task && task.project && task.project.status"
                                                :disabled="!permission_task('update') || (permission_task('update') && (task && task.project && task.project.status === 'active'))"
                                                @stage-change="local_update_project_item"
                                            >
                                                <s-task-status-meter
                                                    :list="meta_list"
                                                    :item="task"
                                                    :status="task.status"
                                                    :can-update="permission_task('update')"
                                                    :isProjectActive="task && task.project && task.project.status === 'active'"
                                                    @update-item="local_status_update"
                                                ></s-task-status-meter>
                                                <template #move-stage="{ moveStage }">
                                                    <span @click="localCheckPlanStageUpdate(moveStage)" class="d-inline-flex align-center u-cursor-pointer">
                                                        <h3 class="blue--text md-body-2 font-weight-medium">Move to active stage</h3>
                                                        <a-icon size="16" color="blue" class="ml-1">trending_flat</a-icon>
                                                    </span>
                                                </template>
                                            </GNonActiveInfo>
                                        </template>
                                        <template #assignee="{ task }">
                                            <SAssigneeDropdown
                                                :item="task"
                                                :users-list="task.assignees"
                                                :project-id="task.project_id"
                                                :can-update="permission_task('update')"
                                                :size="30"
                                                :count="4"
                                                @assignee-store="local_update_list_item(task)"
                                                @change-visibility="local_visibility_toggle(task.id, task.visibility, task.milestone_id)"
                                                @menuOpen="local_get_collabs(task.project_id)"
                                                @click.stop="{}"
                                                module-type="Task"
                                                class="u-flex align-center"
                                                hideExternalConfirmation
                                            ></SAssigneeDropdown>
                                        </template>
                                    </PartialTaskItem>
                                </template>
                            </PartialTaskList>
                        </template>
                    </PartialTaskHeaderList>
                </a-flex>
            </a-layout>
        </a-container>

        <a-dialog v-model="dialogTaskEdit" max-width="1080" :persistent="localAttachmentLoading">
            <ModalTaskEdit
                :item="local_task_item"
                :milestones="localMilestoneMenuList"
                :collaborators="collaborator_list"
                :isOpen="dialogTaskEdit"
                :canUpdate="permission_task('update')"
                :canDelete="permission_task('destroy')"
                :canComment="$can('tasks.comment')"
                :previousTaskId="previousTaskId"
                :nextTaskId="nextTaskId"
                :taskMetaList="taskMetaList"
                :project="local_task_item.project"
                @stage-change="local_update_project_item"
                @attachmentLoading="localSetLoadingStatus"
                @select="localUpdateItem"
                @descriptionUpdate="localUpdateItemDescription"
                @delete="localDeleteTask"
                @openTimetrack="localOpenItemTimeTrack"
                @close="evt => dialogTaskEdit = false"
                hideNavigation
            />
        </a-dialog>

        <a-dialog v-model="dialog_est_list" scrollable persistent max-width="700">
            <template v-if="time_track_task">
                <s-time-tracker
                    :title="time_track_task ? time_track_task.title : ''"
                    @add-entry="local_add_entry"
                    @delete-entry="local_delete_entry"
                    @close="local_close_time_track"
                    :item="time_track_task"
                    :records="time_record_list"
                    :index-loading="record_index_loading"
                    :status="time_track_task.status"
                    :can-update="permission_task('update')"
                >
                    <template v-slot:record-progress>
                        <s-time-progress
                            :limit="700"
                            :item="time_track_task"
                            :sum-mins="time_track_task.time_records_sum_duration_minutes"
                            :est-mins="time_track_task.estimated_duration_minutes"
                            :est-text="time_track_task.estimated_duration_text"
                            class="pb-2"
                            :can-update="permission_task('update')"
                            allow-est-edit
                            @est-update="local_est_update"
                        ></s-time-progress>
                    </template>
                </s-time-tracker>
            </template>
        </a-dialog>

        <a-dialog v-model="dialog_import_form" max-width="800">
            <a-card class="neutral">
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl>
                        <a-layout wrap align-center>
                            <a-flex>
                                <a-responsive class="py-4 my-4" v-if="!loading && template_group_list.length == 0">
                                    <h3 class="mb-1 text-center md-subtitle-1 font-weight-bold grey--text text--darken-1 text-uppercase">No Data Found</h3>
                                    <p class="mb-0 text-center md-body-2 grey--text">There is no data to fetch right now.<br>Try narrowing your filter or add new entries.</p>
                                </a-responsive>
                                <a-card
                                    v-for="template_group in template_group_list"
                                    :key="template_group.id"
                                    class="my-2 u-elevation-custom-1"
                                    flat
                                >
                                    <a-card-text class="pa-0">
                                        <a-container container--fluid grid-list-xl class="px-4 py-3">
                                            <a-layout align-center>
                                                <a-flex>
                                                    <h2 class="md-body-1">
                                                        {{ template_group.name }} <span class="md-body-2 grey--text text--darken-1">{{ template_group.template_tasks_count }} Tasks</span>
                                                    </h2>
                                                </a-flex>
                                                <a-flex shrink>
                                                    <a-btn color="primary lighten-1" class="elevation-0" @click="local_template_import(template_group.id)" small :disabled="template_group.template_tasks_count == 0">Import</a-btn>
                                                </a-flex>
                                            </a-layout>
                                        </a-container>
                                    </a-card-text>
                                </a-card>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-dialog v-model="dialog_export_form" max-width="600">
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="local_user_store()">
                        <a-container container--fluid grid-list-xl class="pa-4">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>insert_drive_file</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Export Tasks as Template</h2>
                                    <p class="mb-0 md-subtitle-2">You can save list of tasks to import them in new projects.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-4">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <v-radio-group v-model="export_mode" row>
                                        <v-radio label="New Template" value="new"></v-radio>
                                        <v-radio label="Existing Template" value="existing"></v-radio>
                                    </v-radio-group>
                                </a-flex>
                                <a-flex xs12 v-if="export_mode == 'new'">
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Group Name</label>
                                    <a-text-field
                                        v-model="template_task_item.name"
                                        placeholder="Template Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="mt-2 md-caption red--text text--darken-2 d-block" v-if="template_task_response.server && template_task_response.server.errors && template_task_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_task_response.server.errors.name[0] }}
                                    </span>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the user.
                                    </span>
                                </a-flex>
                                <a-flex xs12 v-if="export_mode == 'existing'">
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Group</label>
                                    <a-autocomplete
                                        v-model="template_task_item.id"
                                        :items="template_group_list"
                                        item-text="name"
                                        item-value="id"
                                        placeholder="Template Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-autocomplete>
                                    <span class="mt-2 md-caption red--text text--darken-2 d-block" v-if="template_task_response.server && template_task_response.server.errors && template_task_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_task_response.server.errors.name[0] }}
                                    </span>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the user.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-4">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="px-3 ma-0 elevation-0" @click="local_template_export()" :loading="loading" :disabled="loading">Save Template</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-dialog v-model="dialog_task_form" max-width="1000" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container v-if="dialog_task_form_type == 'create_task'" container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="45">
                                    <a-icon dark>check_circle</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1">Create New Task</h2>
                                <p class="mb-0 md-subtitle-2">Create new task for adding to a project</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-container v-if="dialog_task_form_type == 'create_milestone'" container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="45">
                                    <a-icon dark>assistant_photo</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1">Create New Milestone</h2>
                                <p class="mb-0 md-subtitle-2">Create new Milestone for adding to a project</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="px-6 pt-4 pb-6">
                        <a-flex xs12>
                            <v-radio-group v-model="dialog_task_form_type" class="mt-0" row>
                                <v-radio label="Create New Task" value="create_task"></v-radio>
                                <v-radio label="Create New Milestone" value="create_milestone"></v-radio>
                            </v-radio-group>
                        </a-flex>
                        <a-flex xs12 v-if="dialog_task_form_type == 'create_task'">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Title</label>
                                    <a-text-field
                                        v-model="task_item.title"
                                        placeholder="Add new task title"
                                        background-color="neutral"
                                        solo flat hide-details
                                    ></a-text-field>
                                    <span class="mt-2 md-caption red--text text--darken-2 d-block" v-if="task_response.server && task_response.server.errors && task_response.server.errors.title">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ task_response.server.errors.title[0] }}
                                    </span>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Enter the title of the task.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Description (Optional)</label>
                                    <div class="c-task-edit-view__edit-desc">
                                        <g-rich-text-editor
                                            v-model="task_item.description_json"
                                            placeholder="Add description..."
                                            :class="[{ 'grey lighten-4': !isEditorFocused }]"
                                            @isFocused="evt => isEditorFocused = evt"
                                            :refresh="refreshDesc"
                                            formatterStickyZero
                                        ></g-rich-text-editor>
                                    </div>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block">
                                        <a-icon size="16">info</a-icon>
                                        Enter the description of the task.
                                    </span>
                                </a-flex>
                                <a-flex xs3>
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Start Date (Optional)</label>
                                    <!-- <a-menu
                                        v-model="datepicker_task_start_date"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        min-width="290px"
                                        full-width offset-y lazy
                                    >
                                        <template v-slot:activator="{ on }">
                                            <a-text-field
                                                :value="local_format_date(task_item.start_date)"
                                                placeholder="Select Start Date"
                                                background-color="neutral"
                                                v-on="on"
                                                @click:clear="task_item.start_date = null"
                                                solo flat hide-details readonly clearable
                                            >
                                            </a-text-field>
                                        </template>
                                        <a-date-picker v-model="task_item.start_date" @input="datepicker_task_start_date= false" :max="task_item.due_date" bottom no-title scrollable></a-date-picker>
                                    </a-menu>                     -->
                                    <vc-date-picker
                                        v-model="task_item.start_date"
                                        :max-date="task_item.due_date"
                                        :model-config="modelConfig"
                                        class="d-block u-wfull"
                                        @input="datepicker_task_start_date= false"
                                    >
                                        <template v-slot="{ togglePopover }">
                                            <div @click.stop="() => togglePopover({ placement: 'bottom-start' })">
                                                <a-text-field
                                                    :value="local_format_date(task_item.start_date)"
                                                    placeholder="Select Start Date"
                                                    background-color="neutral"
                                                    @click:clear="task_item.start_date = null"
                                                    solo flat hide-details readonly clearable
                                                >
                                                </a-text-field>
                                            </div>
                                        </template>
                                    </vc-date-picker>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block">
                                        <a-icon size="16">info</a-icon>
                                        Select the start date of the task.
                                    </span>
                                </a-flex>
                                <a-flex xs3>
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Due Date (Optional)</label>
                                    <!-- <a-menu
                                        v-model="datepicker_task_due_date"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        min-width="290px"
                                        full-width offset-y lazy
                                    >
                                        <template v-slot:activator="{ on }">
                                            <a-text-field
                                                :value="local_format_date(task_item.due_date)"
                                                placeholder="Select Due Date"
                                                background-color="neutral"
                                                v-on="on"
                                                @click:clear="task_item.due_date = null"
                                                solo flat hide-details readonly clearable
                                            >
                                            </a-text-field>
                                        </template>
                                        <a-date-picker v-model="task_item.due_date" @input="datepicker_task_due_date= false" :min="task_item.start_date" bottom no-title scrollable></a-date-picker>
                                    </a-menu> -->
                                    <vc-date-picker
                                        v-model="task_item.due_date"
                                        :min-date="task_item.start_date"
                                        :max-date="task_item.due_date"
                                        :model-config="modelConfig"
                                        class="d-block u-wfull"
                                        @input="datepicker_task_due_date= false"
                                    >
                                        <template v-slot="{ togglePopover }">
                                            <div @click.stop="() => togglePopover({ placement: 'bottom-start' })">
                                                <a-text-field
                                                    :value="local_format_date(task_item.due_date)"
                                                    placeholder="Select Start Date"
                                                    background-color="neutral"
                                                    @click:clear="task_item.due_date = null"
                                                    solo flat hide-details readonly clearable
                                                >
                                                </a-text-field>
                                            </div>
                                        </template>
                                    </vc-date-picker>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block">
                                        <a-icon size="16">info</a-icon>
                                        Select the due date of the task.
                                    </span>
                                </a-flex>
                                <a-flex xs3>
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Priority</label>
                                    <a-autocomplete
                                        v-model="task_item.priority"
                                        :items="[{name: 'No Priority', value: null}, {name: 'High', value: 'high'}, {name: 'Medium', value: 'medium'}, {name: 'Low', value: 'low'}]"
                                        item-text="name"
                                        item-value="value"
                                        placeholder="visibility"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-autocomplete>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block">
                                        <a-icon size="16">info</a-icon>
                                        Select the Priority.
                                    </span>
                                </a-flex>
                                <a-flex xs3>
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Estimation</label>
                                    <a-text-field
                                        v-model="task_item.estimated_duration_text"
                                        @input="local_calculate_mins"
                                        placeholder="Ex: 4w 2d 10h 8m"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="mt-2 md-caption red--text text--darken-2 d-block" v-if="validationError && showValidationError">
                                        <a-icon size="16" color="red darken-2">info</a-icon>
                                        {{ validationError }} <span class="grey--text text--darken-2">(Ex: 4w 5d 6h 20m)</span>
                                    </span>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Duration format 4w 5d 6h 20m
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Project</label>
                                    <a-autocomplete
                                        v-model="task_item.project_id"
                                        :items="project_list"
                                        item-text="title"
                                        item-value="id"
                                        placeholder="Select Project"
                                        @change="local_project_milestones(task_item.project_id)"
                                        background-color="neutral"
                                        solo flat hide-details clearable
                                    >
                                        <template v-slot:no-data>
                                            <div class="px-4 py-2">
                                                <span class="md-body-2" v-if="project_search != null && project_search != ''"> <strong>{{ project_search }}</strong></span>
                                                <span class="md-body-2 grey--text" v-else>No Projects found</span>
                                            </div>
                                        </template>
                                        <template v-slot:selection="{ item }">
                                            <span v-if="item === Object(item)" class="py-1 md-body-2 d-block u-rounded-corners" style="max-width: 700px" :title="item.title">{{ local_truncate_text(item.title, 90) }} </span>
                                            <span v-else class="px-2 py-1 mx-1 md-body-2 d-block text-truncate u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <a-layout align-center class="py-1 u-flex">
                                                <a-flex shrink style="max-width: 800px">
                                                    <h5 class="md-subtitle-1" :title="item.title">{{ $vuetify.breakpoint.lg ? local_truncate_text(item.title, 70) : local_truncate_text(item.title, 90) }}</h5>
                                                    <p class="mt-1 mb-0 md-caption d-block grey--text text-truncate text--darken-1" v-if="item.customer">{{ item.customer.name }}</p>
                                                </a-flex>
                                                <a-spacer></a-spacer>
                                                <a-flex shrink class="text-right">
                                                    <span class="md-body-2 grey--text" v-if="item.stage">{{ item.stage.name }}</span>
                                                    <span class="md-body-2 grey--text" v-else>Queue</span>
                                                </a-flex>
                                            </a-layout>
                                        </template>
                                    </a-autocomplete>
                                    <span class="mt-2 md-caption red--text text--darken-2 d-block" v-if="task_response.server && task_response.server.errors && task_response.server.errors.project_id">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ task_response.server.errors.project_id[0] }}
                                    </span>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Select the project to add the task.
                                    </span>
                                </a-flex>
                                <a-flex xs12 v-if="task_item.project_id">
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Milestone (Optional)</label>
                                    <a-autocomplete
                                        v-model="task_item.milestone_id"
                                        :items="milestone_list"
                                        item-text="title"
                                        item-value="id"
                                        placeholder="Select Milestone"
                                        background-color="neutral"
                                        solo flat hide-details clearable
                                    >
                                    </a-autocomplete>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block">
                                        <a-icon size="16">info</a-icon>
                                        Select the milestone of the task.
                                    </span>
                                </a-flex>
                                <a-flex xs12 v-if="task_item.project_id">
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Assignee (Optional)</label>
                                    <GUsersSelectDropdown
                                        v-model="selected_assignees"
                                        :list="collaborator_list"
                                        :loading="mixinSearchCollabLoading"
                                        :inputStyle="{ minHeight: '30px' }"
                                        itemText="user.name"
                                        itemValue="user.id"
                                        @search="localSearchCollabs($event, task_item.project_id)"
                                        placeholder="Select Assignees"
                                        class="pr-5 grey lighten-4"
                                        dropdownClass="c-tiny-scroll py-2"
                                        returnObject multiple removable
                                    />
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block">
                                        <a-icon size="16">info</a-icon>
                                        Select the assignee of the task.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-flex>
                        <a-flex xs12 v-if="dialog_task_form_type === 'create_milestone'">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Milestone Title</label>
                                    <a-text-field
                                        v-model="milestone_item.title"
                                        placeholder="Milestone Title"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="mt-2 md-caption red--text text--darken-2 d-block" v-if="milestone_response.server && milestone_response.server.errors && milestone_response.server.errors.title">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ milestone_response.server.errors.title[0] }}
                                    </span>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Enter the title of the milestone.
                                    </span>
                                </a-flex>
                                <a-flex xs6>
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Start Date (Optional)</label>
                                    <!-- <a-menu
                                        v-model="datepicker_milestone_start_date"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        min-width="290px"
                                        full-width offset-y lazy
                                    >   <template v-slot:activator="{ on }">
                                            <a-text-field
                                                :value="local_format_date(milestone_item.start_date)"
                                                placeholder="Select Start Date"
                                                background-color="neutral"
                                                v-on="on"
                                                @click:clear="local_milestone_start_date_clear()"
                                                solo flat hide-details readonly clearable
                                            >
                                            </a-text-field>
                                        </template>
                                        <a-date-picker v-model="milestone_item.start_date" @input="datepicker_milestone_start_date = false" no-title scrollable></a-date-picker>
                                    </a-menu> -->
                                    <vc-date-picker
                                        v-model="milestone_item.start_date"
                                        :max-date="milestone_item.due_date"
                                        :model-config="modelConfig"
                                        class="d-block u-wfull"
                                        @input="datepicker_milestone_start_date= false"
                                    >
                                        <template v-slot="{ togglePopover }">
                                            <div @click.stop="() => togglePopover({ placement: 'bottom-start' })">
                                                <a-text-field
                                                    :value="local_format_date(milestone_item.start_date)"
                                                    placeholder="Select Start Date"
                                                    background-color="neutral"
                                                    @click:clear="milestone_item.start_date = null"
                                                    solo flat hide-details readonly clearable
                                                >
                                                </a-text-field>
                                            </div>
                                        </template>
                                    </vc-date-picker>
                                    <span class="mt-2 md-caption red--text text--darken-2 d-block" v-if="milestone_response.server && milestone_response.server.errors && milestone_response.server.errors.start_date">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ milestone_response.server.errors.start_date[0] }}
                                    </span>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Select the start date of the milestone.
                                    </span>
                                </a-flex>
                                <a-flex xs6>
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Due Date (Optional)</label>
                                    <!-- <a-menu
                                        v-model="datepicker_milestone_due_date"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        min-width="290px"
                                        full-width offset-y lazy
                                    >   <template v-slot:activator="{ on }">
                                            <a-text-field
                                                :value="local_format_date(milestone_item.due_date)"
                                                placeholder="Select Due Date"
                                                background-color="neutral"
                                                v-on="on"
                                                @click:clear="local_milestone_due_date_clear()"
                                                solo flat hide-details readonly clearable
                                            >
                                            </a-text-field>
                                        </template>
                                        <a-date-picker v-model="milestone_item.due_date" @input="datepicker_milestone_due_date = false" no-title scrollable></a-date-picker>
                                    </a-menu> -->
                                    <vc-date-picker
                                        v-model="milestone_item.due_date"
                                        :min-date="milestone_item.start_date"
                                        :max-date="milestone_item.due_date"
                                        :model-config="modelConfig"
                                        class="d-block u-wfull"
                                        @input="datepicker_milestone_due_date= false"
                                    >
                                        <template v-slot="{ togglePopover }">
                                            <div @click.stop="() => togglePopover({ placement: 'bottom-start' })">
                                                <a-text-field
                                                    :value="local_format_date(milestone_item.due_date)"
                                                    placeholder="Select Start Date"
                                                    background-color="neutral"
                                                    @click:clear="milestone_item.due_date = null"
                                                    solo flat hide-details readonly clearable
                                                >
                                                </a-text-field>
                                            </div>
                                        </template>
                                    </vc-date-picker>
                                    <span class="mt-2 md-caption red--text text--darken-2 d-block" v-if="milestone_response.server && milestone_response.server.errors && milestone_response.server.errors.due_date">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ milestone_response.server.errors.due_date[0] }}
                                    </span>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Select the due date of the milestone.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Project</label>
                                    <a-autocomplete
                                        v-model="milestone_item.project_id"
                                        :items="project_list"
                                        item-text="title"
                                        item-value="id"
                                        @change="local_project_milestones(milestone_item.project_id)"
                                        placeholder="Select Project"
                                        background-color="neutral"
                                        solo flat hide-details clearable
                                    >
                                        <template v-slot:no-data>
                                            <div class="px-4 py-2">
                                                <span class="md-body-2" v-if="project_search != null && project_search != ''"> <strong>{{ project_search }}</strong></span>
                                                <span class="md-body-2 grey--text" v-else>No Projects found</span>
                                            </div>
                                        </template>
                                        <template v-slot:selection="{ item }">
                                            <span v-if="item === Object(item)" class="py-1 md-body-2 d-block u-rounded-corners text-truncate" style="max-width: 400px" :title="item.title">{{ item.title }} </span>
                                            <span v-else class="px-2 py-1 mx-1 md-body-2 d-block text-truncate u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <a-layout align-center class="py-1 u-flex">
                                                <a-flex shrink style="max-width: 400px">
                                                    <h5 class="md-subtitle-1 text-truncate" :title="item.title">{{ $vuetify.breakpoint.lg ? local_truncate_text(item.title, 20) : item.title }}</h5>
                                                    <p class="mt-1 mb-0 md-caption d-block grey--text text-truncate text--darken-1">{{ item.customer.name }}</p>
                                                </a-flex>
                                                <a-spacer></a-spacer>
                                                <a-flex shrink class="text-right">
                                                    <span class="md-body-2 grey--text" v-if="item.stage">{{ item.stage.name }}</span>
                                                    <span class="md-body-2 grey--text" v-else>Queue</span>
                                                </a-flex>
                                            </a-layout>
                                        </template>
                                    </a-autocomplete>
                                    <span class="mt-2 md-caption red--text text--darken-2 d-block" v-if="milestone_response.server && milestone_response.server.errors && milestone_response.server.errors.project_id">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ milestone_response.server.errors.project_id[0] }}
                                    </span>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Select the project to add the task.
                                    </span>
                                </a-flex>
                                <a-flex xs12 v-if="milestone_item.project_id && dialog_task_form_type === 'create_milestone'">
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Assignee (Optional)</label>
                                    <GUsersSelectDropdown
                                        v-model="selected_assignees"
                                        :list="collaborator_list"
                                        :loading="mixinSearchCollabLoading"
                                        :inputStyle="{ minHeight: '30px' }"
                                        itemText="user.name"
                                        itemValue="user.id"
                                        @search="localSearchCollabs($event, milestone_item.project_id)"
                                        placeholder="Select Assignees"
                                        class="pr-5 grey lighten-4"
                                        dropdownClass="c-tiny-scroll py-2"
                                        returnObject multiple removable
                                    />
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block">
                                        <a-icon size="16">info</a-icon>
                                        Select the assignee of the task.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-flex>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex xs12>
                                <a-btn color="primary" class="px-3 ma-0 elevation-0"  v-if="dialog_task_form_type == 'create_milestone'" :loading="loading" :disabled="local_project_loading" @click="local_milestone_store()">Save Milestone</a-btn>
                                <a-btn color="primary" class="px-3 ma-0 elevation-0" v-if="dialog_task_form_type == 'create_task'" :loading="loading" :disabled="local_project_loading" @click="local_task_store()">Save Task</a-btn>
                                <a-btn class="mt-0 mb-0 ml-4 mr-0" color="grey" text @click="local_task_close()">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-dialog max-width="800" v-model="dialog_add_users_form" persistent>
            <a-card v-if="local_user_edit_item">
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="40">
                                    <a-icon dark>group</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1"><span class="md-heading-6 text-capitalize">{{ local_user_edit_type }}</span> Assignees</h2>
                                <p class="mb-0 md-subtitle-2">Manage users under <strong class="grey--text text--darken-2">{{ local_truncate_text(local_user_edit_item.title, 55) }}</strong></p>
                            </a-flex>
                            <a-flex shrink v-if="autosave_user_add_status !== 'none'">
                                <a-chip class="right" small color="grey" text-color="white" v-if="autosave_user_add_status === 'loading'">Saving...</a-chip>
                                <a-chip class="right" small color="green" text-color="white" v-if="autosave_user_add_status === 'done'">Saved</a-chip>
                            </a-flex>
                            <a-flex shrink>
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a-btn small text icon color="grey" :disabled="autosave_user_add_status === 'loading'" @click="dialog_add_users_form = false" class="px-3 mx-0 mt-0 mb-0 mr-0 pa-0 right" v-on="on">
                                            <a-icon size="18">cancel</a-icon>
                                        </a-btn>
                                    </template>
                                    <span>Close</span>
                                </a-tooltip>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start style="min-height: 66px;">
                            <a-flex class="u-flex align-center u-wrap">
                                <span class="px-2 py-1 my-1 mr-2 md-subtitle-1 d-inline-block u-rounded-corners u-cursor-pointer" v-if="local_user_edit_item && local_user_edit_item.assignees && local_user_edit_item.assignees.length === 0">
                                    No Users Assigned
                                </span>
                                <template v-else>
                                    <span class="px-2 py-1 my-1 mr-2 md-subtitle-1 u-flex align-center u-rounded-corners u-cursor-pointer grey lighten-2" v-for="assignee in local_user_edit_item.assignees" :key="assignee.id">
                                        <div class="member-avatar">
                                            <g-avatar :item="assignee" :size="30"></g-avatar>
                                        </div>
                                        <div class="member-label u-cursor-pointer">
                                            <template v-if="assignee && assignee.name">
                                                <h2 class="md-subtitle-1 font-weigh-medium grey--text text--darken-3">{{ assignee.name }}</h2>
                                            </template>
                                            <template v-else>
                                                <h2 class="md-subtitle-1 font-weigh-medium grey--text text--darken-3">{{ assignee.assignee.name }}</h2>
                                            </template>
                                            <!-- <h5 class="md-subtitle-1 text-truncate" style="max-width: 500px">{{ assignee.assignee.name }}</h5> -->
                                            <!-- <p class="mb-0 md-caption grey--text text--darken-1">{{ assignee.type }}</p> -->
                                        </div>
                                        <a-icon @click="local_assign(local_user_edit_item.id, assignee.assignee.id, true)" class="ml-2" size="18">clear</a-icon>
                                    </span>
                                </template>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start>
                            <a-flex xs12>
                                <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Select Users</label>
                                <a-autocomplete
                                    v-model="local_selected_user_add_item"
                                    :items="local_user_edit_item.assignees"
                                    item-text="user.name"
                                    item-value="user_id"
                                    label="Select Users"
                                    class="elevation-0"
                                    background-color="neutral"
                                    clear-icon="backspace"
                                    :menu-props="{ closeOnContentClick: autosave_user_add_status === 'loading' }"
                                    :disabled="loading"
                                    @change="local_assign(local_user_edit_item.id, local_selected_user_add_item[0])"
                                    solo flat hide-details multiple small-chips hide-selected clearable deletable-chips autofocus
                                >
                                    <template v-slot:no-data>
                                        <div class="px-4 py-2">
                                            <span class="md-body-1 grey--text">No Users found</span>
                                        </div>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <a-layout align-center class="py-2">
                                            <g-avatar :item="item"></g-avatar>
                                            <g-profile-info :item="item" set-max-width></g-profile-info>
                                        </a-layout>
                                    </template>
                                </a-autocomplete>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!-- Filters side pane -->
        <PartialTaskFilterPane
            :isOpen="filters_pane"
            :preferenceItem="user_filter_item"
            @update="local_apply_filters"
            @revert="local_revert_filter()"
        />

        <!-- User Upgrade Popup -->
        <a-dialog max-width="400" v-model="dialog_user_upgrade_modal">
            <SUserUpgradeModal @close="dialog_user_upgrade_modal = false" />
        </a-dialog>

        <!-- Admin Upgrade Popup -->
        <a-dialog max-width="600" v-model="dialog_admin_upgrade_modal">
            <SAdminUpgradeModal
                :isOpen="dialog_admin_upgrade_modal"
                @upgrade="{}"
                @close="dialog_admin_upgrade_modal = false"
            >
                <template #plan-info-text>
                    Your current <span class="secondary--text font-weight-bold">Starter</span> plan is limited to {{ $plan('active_projects_limit') }} active projects.
                </template>
            </SAdminUpgradeModal>
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import {
    SUserListPopup,
    SAssigneeForm,
    SAssigneeDropdown,
    STaskEditPane,
    STaskStatusMeter,
    SDatePicker,
    SUserUpgradeModal,
    SAdminUpgradeModal
} from '@/config/config-shared-components'
import loadWorkspace from '@/mixins/mixin-workspace'
import { calculateByText } from '@/helpers/helper-time-tracker.js'
import STimeTracker from '@/components/Shared/SharedTimeTracker.vue'
import STimeProgress from '@/components/Shared/SharedTimeProgress.vue'
import mixinSearchCollaborators from '@/mixins/mixin-search-collaborators'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'
import PartialTaskList from './Partials/PartialTaskList.vue'
import PartialTaskHeaderList from './Partials/PartialTaskHeaderList.vue'
import PartialTaskItem from './Partials/PartialTaskItem.vue'
import SharedDropdownWrapper from '@/components/Shared/SharedDropdownWrapper.vue'
import PartialTaskFilterPane from './Partials/PartialTaskFilterPane.vue'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import checkScopePermission from '@/mixins/mixin-checkscope-permission'
import ModalTaskEdit from '../Projects/Modals/ModalTaskEdit.vue'
import { Base64 } from 'js-base64'
import { v4 as uuidv4 } from 'uuid'

export default {
    mixins: [
        mixinLoading,
        loadWorkspace,
        checkScopePermission,
        mixinGetOrganizationPlan,
        mixinSearchCollaborators,
    ],

    components: {
        SDatePicker,
        STimeTracker,
        STimeProgress,
        SAssigneeForm,
        STaskEditPane,
        ModalTaskEdit,
        SUserListPopup,
        PartialTaskList,
        PartialTaskItem,
        STaskStatusMeter,
        SUserUpgradeModal,
        SAssigneeDropdown,
        SAdminUpgradeModal,
        SharedDropdownWrapper,
        PartialTaskHeaderList,
        PartialTaskFilterPane,
    },

    data() {
        return {
            task_title: '',
            task_due_date: null,
            task_priority: null,
            previousTaskId: null,
            nextTaskId: null,
            currentEditItem: {},
            dialogTaskEdit: false,
            dialog_user_upgrade_modal: false,
            dialog_admin_upgrade_modal: false,
            loading_modules: [],
            datepicker_due_date_new: false,
            datepicker_task_start_date: false,
            datepicker_task_due_date: false,
            datepicker_milestone_start_date: false,
            datepicker_milestone_due_date: false,
            export_mode: 'new',
            dialog_user_form: false,
            dialog_import_form: false,
            dialog_export_form: false,
            dialog_task_form: false,
            dialog_task_form_type: 'create_task',
            dialog_est_list: false,
            time_track_task: null,
            from_est: null,
            to_est: null,
            est_date: null,
            est_from_time: new Date(),
            est_to_time: new Date(),
            current_date: null,
            fetch_est_from_time: null,
            fetch_est_to_time: null,
            loading_item: null,
            record_index_loading: false,
            local_collaborator_list: [],
            assignee_mode: false,
            search_collaborator: '',
            project_search: null,
            flag_show_completed: false,
            button_filter_clear: false,
            task_self_filter: null,
            local_project_loading: false,
            task_group_by: 'project_id',
            task_order: 'asc',
            task_sort_field_filter: 'title',
            filter_list_status: [
                {'label': 'Active Projects', 'value': 'active'},
                {'label': 'All Projects', 'value': 'all'},
            ],
            filter_list_manager: [
                {'label': 'All Tasks', 'value': 'all'},
                {'label': 'Direct Reports', 'value': 'direct_reports'},
                {'label': 'All Reports', 'value': 'all_reports'},
                {'label': 'Assigned to me', 'value': 'self'},
            ],
            filter_list_team: [
                {'label': 'All Tasks', 'value': 'all'},
                {'label': 'Assigned to me', 'value': 'self'}
            ],
            filter_list_group_by: [
                {'label': 'Group by Project', 'value': 'project_id'},
                {'label': 'Group by Start Date', 'value': 'start_date'},
                {'label': 'Group by Due Date', 'value': 'due_date'},
                {'label': 'No Grouping', 'value': 'ungrouped'},
            ],
            filter_list_sort_field: [
                {'label': 'Title', 'value': 'title'},
                {'label': 'Start Date', 'value': 'start_date'},
                {'label': 'Due Date', 'value': 'due_date'},
                {'label': 'Default', 'value': 'order'},
            ],
            local_assignee_search: null,
            local_select_project_id: '',
            page_loading: true,
            local_task_filters: {},
            local_project_filters: {},
            local_user_edit_item: {},
            local_selected_user_add_item: [],
            local_user_edit_type: '',
            autosave_user_add_status: 'none',
            dialog_add_users_form: false,
            sort_direction: '',
            selected_assignees: [],
            local_task_item: {},
            local_task_params: {
                'include': 'project.customer,project.stage,milestone,assignees.collaborator.type,status,attachmentsCount',
                'fields[tasks]': 'id,title,priority,visibility,status_id,milestone_id,start_date,due_date,project_id,estimated_duration_text,estimated_duration_minutes,description_json,order',
                'fields[project]': 'id,title,status,customer_id,stage_id,workspace_id',
                'fields[project.customer]': 'id,name',
                'fields[project.stage]': 'id,name',
                'fields[milestone]': 'id,title,order',
                'fields[status]': 'id,status,value,color,percentage',
                'aggregate[time_records.duration_minutes]': 'sum',
                'count': 1000000
            },
            time_record_params: {
                'include': 'createdBy',
                'fields[time_records]': 'id,duration_minutes,duration_text,description,created_at,start_date,resource_type,resource_id,created_by_id',
                'fields[created_by]': 'id,name,avatar,color,initial',
                'aggregate[duration_minutes]': 'count'
            },
            showEstimateEditForm: false,
            validationError: null,
            showValidationError: false,
            task_filter: {
                PROJECT: 'filter[project_id]',
                COUNT: 'filter[count]',
                STATUS: 'filter[status]',
                ASSIGNED:'filter[assigned]',
                COMPLETED_AT:'filter[completed_at]',
                WORKSPACE: 'filter[workspace_id]',
                SORT: 'sort',
                COLLABORATORS: 'filter[collaborator_id]',
                STATUS_TYPE: 'filter[status_id]',
                TERRITORY: 'filter[territory_id]',
                START_DATE: 'filter[start_date]',
                DUE_DATE: 'filter[due_date]',
                VISIBILITY: 'filter[visibility]',
                HIERARCHY: 'filter[hierarchy_report]'
            },
            project_filter: {
                FIEDS: 'fields[type]',
                STATUS: 'filter[status]',
                PROJECT_FIELDS: 'fields[projects]',
                WORKSPACE: 'filter[workspace_id]',
            },
            filters_pane: false,
            filter_loading: false,
            local_filter_projects: 'active',
            local_filter_tasks: 'all',
            selected_status: [],
            selected_collaborators: [],
            selected_territories: [],
            start_date: {
                operator: null,
                operator_value: null,
                value: null,
                value_alt: null
            },
            due_date: {
                operator: null,
                operator_value: null,
                value: null,
                value_alt: null
            },
            user_filter: {
                FIELDS: 'fields[users]',
                NAME: 'filter[user_search]',
                IS_ACTIVE: 'filter[is_active]',
                SORT: 'sort',
                COUNT: 'count',
            },
            territory_filter: {
                NAME: 'filter[name]',
                COUNT: 'count',
            },
            local_user_filters: {},
            filter_preference: null,
            local_filter_visibility: 'all',
            local_team_filter: {
                label: 'All Tasks', value: 'all'
            },
            errors: {
                start_date_from: false,
                start_date_to: false,
                due_date_from: false,
                due_date_to: false,
            },
            reset_errors: {
                start_date_from: false,
                start_date_to: false,
                due_date_from: false,
                due_date_to: false,
            },
            local_team_filter_list: [],
            error_fields: false,
            storedItem: {},
            revert_task_item: {},
            user_filters_pref: {},
            startIndex: 0,
            endIndex: 20,
            tasksRange: {},
            user_filter_item: {},
            local_project_params: {
                'fields[projects]': 'id,title,status,customer_id,stage_id,workspace_id',
                'include': 'customer,stage',
                'fields[customer]': 'id,name',
                'fields[stage]': 'id,name',
            },
            singleTaskEdit: null,
            taskMetaList: [],
            refreshDesc: false,
            isEditorFocused: false,
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
            localAttachmentLoading: false,
            localFiterTaskList: [],
        }
    },

    mounted () {
        this.local_index()
    },

    watch: {
        async dialog_task_form_type (newValue, oldValue) {
            await this.task_clear_item()
            await this.milestone_clear_item()
            this.selected_assignees = []
        },

        showEstimateEditForm (val) {
            if (val) setTimeout(() => this.$refs.refEstEdit.focus(), 100)
        },

        filters_pane (val) {
            if (val) return
            this.loadTaskStatusMeta()
            this.loadFilterModules()
        }
    },

    computed: {
        task_show_additional() {
            return this.task_title != ''
        },

        task_due_date_formatted() {
            if (this.task_due_date != null) {
                return moment(this.task_due_date, 'YYYY-MM-DD').format('MMMM DD, YYYY')
            }
        },

        task_list: {
            get() {
                return this.$store.state.Task.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },

        milestone_list: {
            get() {
                return this.$store.state.Milestone.list
            },
            async set(list) {
                await this.milestone_reorder({list: list})
            },
        },

        localMilestoneMenuList () {
            const list = this.milestone_list.map(i => ({ id: i.id, title: i.title }))
            list.push({ id: 'no-milestone', title: 'No Milestone' })
            return list
        },

        local_render () {
            const groupByKeys = ['project_id', 'ungrouped']
            const result = groupByKeys.includes(this.task_group_by) ?
                                    _.groupBy(this.task_list, this.task_group_by) :
                                    _.groupBy(_.orderBy(this.task_list, this.task_group_by, 'asc'), this.task_group_by)

            Object.keys(result).forEach(item => this.tasksRange[item] = { startIndex: 0, endIndex: this.task_group_by === 'ungrouped' ? 20 : 10 })
            return result
        },

        local_set_project_filters() {
            this.local_project_filters[this.project_filter.STATUS] = 'active'
            this.local_project_filters[this.project_filter.WORKSPACE] = this.current_workspace_id_mixin
        },

        local_check_no_filters() {
            if (this.local_filter_projects === 'active' && !this.selected_status.length && !this.selected_collaborators.length && !this.selected_territories.length && !this.start_date.operator_value && !this.due_date.operator_value && this.local_filter_visibility === 'all') return true
            else return false
        },

        ...mapState('TimeRecord', {
            time_record_list: 'list',
            time_record_response: 'response',
        }),

        ...mapState('Task', {
            task_item: 'item',
            task_filters: 'filters',
            task_response: 'response',
            task_form_mode: 'form_mode',
        }),

        ...mapState('TemplateGroup', {
            template_group_list: 'list',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),

        ...mapState('TemplateTask', {
            template_task_item: 'item',
            template_task_response: 'response',
        }),

        ...mapState('Milestone', {
            milestone_item: 'item',
            milestone_query: 'query',
            milestone_response: 'response',
        }),

        ...mapState('Project', {
            project_item: 'item',
            project_list: 'list',
            project_response: 'response',
            project_filters: 'filters',
        }),

        ...mapState('Comment', {
            comment_item: 'item',
            comment_list: 'task_comments',
            comment_filters: 'filters',
            comment_response: 'response',
        }),

        ...mapState('Collaborator', {
            collaborator_list: 'list',
            collaborator_filters: 'filters',
        }),

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Assignee', {
            assignee_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            users_list: 'list',
            user_self: 'self'
        }),

        ...mapState('Territory', {
            territories_list: 'list',
        }),

        ...mapState('Preference', {
            preference_list: 'list',
        }),
    },

    methods: {
        async local_index() {
            if (this.$can('tasks.index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.meta_index({ 'filter[type]': 'task_status', 'fields[metas]': 'id,status,value,color,percentage', sort: 'order' })
            this.local_user_filters[this.user_filter.COUNT] = 5
            this.user_index(this.local_user_filters)
            this.territory_index()
            this.local_project_checkscope()

            if (this.user_self && this.user_self.id) await this.user_show({id: this.user_self.id, mode: 'self'})
            await this.local_fetch_preference()
            this.local_load_project_list()
            this.local_load_task_list()
            this.loadTaskStatusMeta()
            this.loadFilterModules()
            this.local_set_hierarchy_list()
        },

        async loadTaskStatusMeta () {
            this.localStartLoader('task-status-meta')
            await this.meta_index({ 'filter[type]': 'task_status', 'fields[metas]': 'id,status,value,color,percentage', sort: 'order' })
            const list = []
            const types = ['Unstarted', 'Started', 'Completed']
            const items = _.cloneDeep(this.meta_list)
            types.forEach(type => {
                items.filter(item => {
                    if (item.status === type) list.push(item)
                })
            })
            this.taskMetaList = list.map((item, index) => ({ ...item, _index: index }))
            this.localStopLoader('task-status-meta')
        },

        async loadFilterModules () {
            this.localStartLoader('filter-modules')
            this.localSetUserParams()
            await this.user_index(this.local_user_filters)
            await this.territory_index({ 'fields[territories]': 'id,name,color' })
            this.localStopLoader('filter-modules')
        },

        async local_fetch_preference() {
            await this.preference_index()
            this.filter_preference = this.preference_list.find(item => item.key === 'user.tasks.filters');
            this.local_set_filter_pref()
        },

        async local_set_filter_pref () {
            if (!this.filter_preference) return
            const local_filter_value = JSON.parse(this.filter_preference.value)
            this.user_filters_pref = {...local_filter_value}
            for (let filtervalue in local_filter_value) {
                switch (filtervalue) {
                    case 'status':
                        this.local_filter_projects = local_filter_value[filtervalue]
                        break;
                    case 'collaborators':
                        this.selected_collaborators = local_filter_value[filtervalue]
                        break;
                    case 'territories':
                        this.selected_territories = local_filter_value[filtervalue]
                        break;
                    case 'status_types':
                        this.selected_status = local_filter_value[filtervalue]
                        break;
                    case 'start_date':
                        this.start_date.operator = local_filter_value[filtervalue].operator
                        this.start_date.operator_value = local_filter_value[filtervalue].operator_value
                        this.start_date.value = local_filter_value[filtervalue].value
                        this.start_date.value_alt = local_filter_value[filtervalue].value_alt
                        break;
                    case 'due_date':
                        this.due_date.operator = local_filter_value[filtervalue].operator
                        this.due_date.operator_value = local_filter_value[filtervalue].operator_value
                        this.due_date.value = local_filter_value[filtervalue].value
                        this.due_date.value_alt = local_filter_value[filtervalue].value_alt
                        break;
                    case 'visibility':
                        this.local_filter_visibility = local_filter_value[filtervalue]
                        break;
                    case 'hierarchy_report':
                        this.local_team_filter.value = local_filter_value[filtervalue].value
                        this.local_team_filter.label = local_filter_value[filtervalue].label
                        break;
                    default: break;
                }
            }
            this.local_set_filter_values()
            this.user_filter_item = Object.assign({}, this.filter_preference)
        },

        async local_load_project_list() {
            this.local_project_loading = true
            //await this.project_clear()
            //await this.project_clear_item()
            this.local_project_filters[this.project_filter.WORKSPACE] = this.current_workspace_id_mixin
            await this.project_index({
                'include': 'customer,stage',
                'fields[stage]': 'id,name',
                'fields[projects]': 'id,title,status,stage_id,customer_id,workspace_id',
                'fields[customer]': 'id,name',
                'filter[workspace_id]': this.current_workspace_id_mixin
            })
            this.local_project_loading = false
        },

        async local_load_task_list() {
            //await this.task_clear()
            this.local_set_task_filters()
            await this.task_index({...this.local_task_params, ...this.local_task_filters })
            this.page_loading = false
        },

        local_set_task_filters() {
            this.local_task_filters[this.task_filter.PROJECT] = 'all'
            this.local_task_filters[this.task_filter.COMPLETED_AT] = 'none'
            this.local_task_filters[this.task_filter.WORKSPACE] = this.current_workspace_id_mixin
            this.local_task_filters[this.task_filter.SORT] = 'title'
        },

        local_format_date(date) {
            return (date != null) ? moment(date).format('MMMM DD, YYYY') : null;
        },

        capitalize_first_letter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        local_group(value) {
            this.task_clear_item()
            this.task_group_by = value
            this.button_filter_clear = true
        },

        local_filter_value_get(list, value, key) {
            if (value && typeof value === 'string' && value.charAt(0) === '-') return _.get(_.find(list, {value: value.substring(1)}), key)
            return _.get(_.find(list, {value: value}), key)
        },

        async local_filter_value_set(filter, value) {
            this.localFiterTaskList = []
            if (this.page_loading) return
            this.task_clear_item()
            if (filter === 'sort_direction') {
                this.sort_direction = value
                this.local_task_filters[this.task_filter.SORT] = this.local_task_filters[this.task_filter.SORT].charAt(0) === '-' ? this.local_task_filters[this.task_filter.SORT].substring(1) : this.local_task_filters[this.task_filter.SORT]
                this.local_task_filters[this.task_filter.SORT] = `${value}${this.local_task_filters[this.task_filter.SORT]}`
            } else if (filter === 'sort') {
                this.local_task_filters[filter] = value
                this.local_task_filters[filter] = this.local_task_filters[filter].charAt(0) === '-' ? this.local_task_filters[filter].substring(1) : this.local_task_filters[filter]
                this.local_task_filters[filter] = `${this.sort_direction}${this.local_task_filters[filter]}`
            } else this.local_task_filters[filter] = value
            await this.task_index({...this.local_task_params, ...this.local_task_filters })
            if (filter === 'sort' && value === 'order') await this.local_filter_task_milestones_order_update()
            if(filter === 'filter[status]') this.local_load_project_list(value)
        },

        async local_filter_task_milestones_order_update () {
            const fiterTaskList = this.task_list.filter(item => item.milestone_id)
            const milestones = _.orderBy(fiterTaskList, 'milestone.order')
            milestones.forEach(milestone => {
                this.localFiterTaskList.push(milestone)
            });

            const noMilestones = this.task_list.filter(item => item.milestone_id === null)
            _.orderBy(noMilestones, 'order').forEach(milestone => {
                this.localFiterTaskList.push(milestone)
            });
            await this.task_list_update(_.cloneDeep(this.localFiterTaskList))
        },

        local_task_create() {
            this.local_reset_form_data()
            //this.mixinGetCollabs()
            this.dialog_task_form = true
            this.task_item.priority = null
            this.task_item.visibility = 'internal'
            this.task_item.description_json = null
            this.localRefreshDesc()
            this.dialog_task_form_type = 'create_task'
            this.selected_assignees = []
        },

        localRefreshDesc () {
            this.refreshDesc = true
            setTimeout(() => {
                this.refreshDesc = false
            }, 100);
        },

        async local_reset_form_data() {
            this.task_clear_item()
            this.milestone_clear()
            this.milestone_clear_item()
        },

        async local_task_close() {
            this.dialog_task_form = false
            this.local_reset_form_data()
        },

        async local_task_store() {
            if (this.validationError) return this.showValidationError = true
            this.task_item.force_include = true
            this.task_item.params = { 'include': 'project.customer,milestone,assignees.collaborator.type,status' }
            this.task_item.page = 'gobal_task'
            if (this.task_item.hasOwnProperty('description_json')) {
                this.task_item.description_json = this.task_item.description_json ? this.task_item.description_json['encodedData'] : null
            }
            const { durationText } = calculateByText(this.task_item.estimated_duration_text)
            this.task_item.estimated_duration_text = durationText
            this.task_store({ ...this.task_item }).then(async res => {
                if (this.task_response.status !== 'success') return
                await this.local_add_users(this.task_item ?? null, 'Task')
                this.dialog_task_form = false
                if(this.task_item && this.task_item.milestone_id) this.$notify('success', 'Task added to the milestone successfully')
                else this.$notify('success', 'Task created successfully')
                setTimeout(async () => {
                    await this.local_task_show(this.task_item && this.task_item.id ? this.task_item.id : '', 'show')
                    const taskIndex = this.task_list.findIndex(i => i.id === this.task_item.id)
                    this.$set(this.task_list, taskIndex, { ...this.task_list[taskIndex], ...this.task_item })
                    await this.task_clear_item()
                    this.local_reset_validation()
                }, 300)
                this.selected_assignees = []
            })
        },

        async local_milestone_store() {
            await this.milestone_store(this.milestone_item)
            if (this.milestone_response.status !== 'success') return
            await this.local_add_users(this.milestone_item, 'Milestone')
            this.dialog_task_form = false
            this.$notify('success', 'Milestone created successfully')
            await this.milestone_clear_item()
            this.selected_assignees = []
        },

        async local_add_users(model, type) {
            _.map(this.selected_assignees, 'user_id').forEach(async user_id => {
                await this.assignee_store({ resource_type: type, resource_id: model.id, user_id, project_id : model.project_id })
            })
        },

        async local_project_milestones(projectId) {
            if (projectId !== null) {
                await this.local_load_milestone_list(projectId)
                this.collaborator_filters.project_id = projectId;
                await this.local_get_collabs(projectId, { count: 200, page: 1 })
                this.collaborator_filter_list()
            }
        },

        async local_load_poc_collabs(poc_id) {
            await this.local_get_collabs(poc_id, { count: 200, page: 1 })
            this.collaborator_filter_list()
        },

        local_task_due_date_clear() {
            this.task_item.due_date = null
        },

        local_milestone_due_date_clear() {
            this.milestone_item.due_date = null
        },

        local_milestone_start_date_clear() {
            this.milestone_item.start_date = null
        },

        async local_status_update(task, meta, mode = null) {
            this.loading_item = task.id
            await this.task_state_update({ ids: [task.id], status_id: meta.id, project_id: task.project_id })
            if (task.id === this.local_task_item.id || !this.local_task_item.id) {
                this.local_task_item.id = task.id
                await this.local_task_show(this.local_task_item.id, 'show')
                this.local_task_item = _.cloneDeep(this.task_item)
            } else await this.local_task_show(task.id, 'update-list')

            this.task_refresh()
            this.loading_item = null
        },

        async local_visibility_toggle(id, state) {
            if(this.can_task_update(this.user_self,  this.local_task_item)) {
                const task_item = {}
                task_item.id = id
                task_item.state = state === 'external' ? 'internal' : 'external'
                await this.task_visibility(task_item)
                if(this.task_response.status !== 'success') return
                this.local_task_item.visibility = task_item.state
                await this.local_task_show(id, 'show')
            }
        },

        async local_comment_visibility_toggle(id, visibility) {
            const comment_item = {}
            comment_item.id = id
            comment_item.visibility = visibility === 'external' ? 'internal' : 'external'
            comment_item.type = 'Task'
            await this.comment_visible(comment_item)
        },

        local_remove_assignee(item, list) {
            list.splice(_.map(list, 'user_id').indexOf(item.user_id), 1)
        },

        async local_reorder(list) {
            this.task_reorder({list: list})
        },

        async local_select(task, poc_id) {
            this.localEditTask(task)
            this.local_task_item = {}
            this.comment_clear_item()
            this.showEstimateEditForm = false
            this.localEstText = null
            this.loading_item = task.id
            this.local_task_item.edited = false
            const index = this.task_list.findIndex(i => i.id === task.id)
            this.local_task_item = Object.assign({}, { ...this.local_task_item, ...this.task_list[index] })
            this.local_load_milestone_list(poc_id)
            this.local_load_poc_collabs(poc_id)
            this.loading_item = null
        },

        async local_load_milestone_list(projectId) {
            await this.milestone_index({ 'filter[project_id]': projectId || this.task_item.project_id, 'fields[milestones]': 'id,title' })
        },

        async local_load_task_comments(id) {
            this.mixinSetLoading('task-comments-' + id)
            await this.comment_clear()
            const filters = {
                'include': 'createdBy',
                'fields[comments]': 'id,content,visibility,created_by_id',
                'fields[createdBy]': 'id,name,email,avatar,initial,color,is_active,scope',
                'filter[commentable_id]': this.task_item.id,
                'filter[commentable_type]': 'Task',
                'type': 'Task',
                'sort': '-created_at'
            }
            await this.comment_index(filters)
            this.mixinResetLoading('task-comments-' + id)
        },

        local_subheading_check(name) {
            return _.endsWith(name, ':');
        },

        async local_comment_store() {
            this.comment_item.commentable_type = 'Task'
            this.comment_item.commentable_id = this.task_item.id
            this.comment_item.visibility = 'internal'
            await this.comment_store(this.comment_item)
            if (this.comment_response.status === 'success') {
                await this.comment_show({id : this.comment_item.id, type: 'Task', params: { 'include': 'createdBy'}})
                await this.comment_clear_item()
            }
        },

        async local_task_show(id, mode, params = null) {
            await this.task_show({
                id: id, mode: mode, page: 'gobal_task',
                params: params ? params : {
                    'include': 'project.customer,project.stage,milestone,assignees.collaborator.type,status',
                    'fields[milestone]': 'id,title',
                    'fields[status]': 'id,status,value,color,percentage',
                    'aggregate[time_records.duration_minutes]': 'sum'
                }
            })
        },

        can_task_update(user, project = null, task) {
            return user.role.scope === 'internal' && this.$can('projects.update-only') && this.$can('tasks.update')
        },

        cannot_task_update(user, project, task) {
            return user.role.scope === 'external' || !this.$can('projects.update-only') || !this.$can('tasks.update')
        },

        local_update_input(event) {
            this.$emit('update', event.target.innerText);
        },

        collaborator_filter_list() {
            this.local_collaborator_list = [...this.collaborator_list]
        },

        local_collaborator_item() {
            let search = this.search_collaborator
            return  _.filter(this.local_collaborator_list, function(list) {
                     return list.user.name.toLowerCase().indexOf(search.toLowerCase())>=0;
                });
        },

        local_due_date_clear() {
            this.milestone_item.due_date = null
        },

        local_start_date_clear() {
            this.milestone_item.start_date = null
        },

        async local_destroy(id) {
            await this.task_destroy({ id: id, destroy_force: true})
            await this.task_clear_item();
        },

        async local_template_import_form() {
            if (this.$can('templates.index') == true) {
                await this.template_group_clear()
                this.template_group_filters.type = 'Task'
                await this.template_group_index()
            }
            this.dialog_import_form = !this.dialog_import_form
        },

        async local_template_export_form() {
            if (this.$can('templates.index') == true) {
                await this.template_group_clear()
                this.template_group_filters.type = 'Task'
                await this.template_group_index()
            }
            this.dialog_export_form = !this.dialog_export_form
            this.template_task_item.name = null
            this.template_task_item.id = null
            this.export_mode = 'new'
        },

        async local_template_import(id) {
            await this.task_template_import({project_id: this.$route.params.id, id: id})
            this.dialog_import_form = false;
            await this.task_clear()
            this.task_filters.page = 0;
            this.task_filters.project_id = this.$route.params.id
            await this.task_index(this.local_task_params)
        },

        async local_template_export() {
            let task_ids = _.map(this.task_list, 'id');
            this.template_task_export({data: task_ids, template_group_id: this.template_task_item.id, template_group_name: this.template_task_item.name});
            this.dialog_export_form = false;
        },

        local_loading(id) {
            return this.loading && this.loading_item === id
        },

        permission_task (type) {
            return this.$can('projects.update-only') && this.$can(`tasks.${type}`) && this.user_self.role.scope === 'internal'
        },

        permission_task_store(client_allowed = false) {
            if (this.user_self.role.scope === 'external') {
                return client_allowed
            }
            return this.$can('tasks.store') && this.$can('projects.update-only') && this.user_self.role.scope === 'internal'
        },

        permission_task_update(task, client_allowed = false) {
            if (this.user_self.role.scope === 'external') {
                return client_allowed
            }
            return this.$can('projects.update-only') && this.$can('tasks.update') && this.user_self.role.scope === 'internal'
        },

        permission_task_ticket(type) {
            return this.$can('projects.update-only') && this.$can('tasks.update') && this.user_self.role.scope === 'internal'
        },

        permission_task_destroy(task_item) {
             if (this.user_self.role.scope === 'external') {
                return client_allowed
            }
            return this.$can('projects.update-only') && this.$can('tasks.destroy') && this.user_self.role.scope === 'internal'
        },

        local_get_initial(name) {
            const [first, last] = _.split(name, ' ', 2)
            return last ? (first[0] ?? '') + (last[0] ?? '') : (first[0] ?? '')
        },

        local_truncate_text(text, range, separator = '...') {
            if (!text) return
            return text.length > range ? text.slice(0, range) + separator : text
        },

        // Time tracker
        async local_time_record_index () {
            this.record_index_loading = true
            await this.time_record_index({
                ...this.time_record_params,
                'filter[resource_type]': 'Task',
                'filter[resource_id]': this.time_track_task.id,
                'sort': '-created_at'
            })
            this.record_index_loading = false
        },

        async local_open_time_track (task) {
            this.time_track_task = task
            this.dialog_est_list = true
            this.local_time_record_index()
        },

        local_close_time_track ({ resetFields }) {
            resetFields()
            this.time_record_clear()
            this.time_track_task = null
            this.dialog_est_list = false
        },

        local_calculate_mins (evt) {
            this.local_reset_validation()
            const { durationMins, error } = calculateByText(evt)
            this.validationError = error
            if (error) return
            this.task_item.estimated_duration_minutes = durationMins
            if (this.validationError) this.local_reset_validation()
        },

        async local_add_entry ({ formData: record, resetFields, mode, record_id: id }) {
            const params = { ...record, mode: 'create', resource_type: 'Task', resource_id: this.time_track_task.id }
            if (mode && mode === 'edit') await this.time_record_update({ ...record, mode, id })
            else await this.time_record_store({ ...params })
            this.local_clear_time_record(resetFields)
        },

        async local_delete_entry ({ id, resetFields }) {
            await this.time_record_destroy({ id })
            this.local_clear_time_record(resetFields)
        },

        async local_est_update (evt) {
            delete evt.description_json
            await this.task_update(evt)
            if (this.task_response && this.task_response.status == 'success') {
                this.local_task_item = _.cloneDeep(this.task_item)
            }
        },

        async local_clear_time_record (resetFields) {
            if (this.time_record_response && this.time_record_response.status === 'success') {
                // this.local_time_record_index()
                await this.local_task_show(this.time_track_task.id, 'show')
                this.local_task_item = _.cloneDeep(this.task_item)
                this.time_track_task = this.local_task_item
                resetFields()
            }
        },

        local_reset_validation () {
            this.showValidationError = false
            this.validationError = null
        },

        async local_update_list_item (task) {
            await this.local_task_show(task.id, 'show')
            const taskIndex = this.task_list.findIndex(i => i.id === task.id)
            this.$set(this.task_list, taskIndex, { ...this.task_list[taskIndex], ...this.task_item })
            if (this.local_task_item && this.local_task_item.id) this.local_task_item = _.cloneDeep(this.task_item)
        },

        localSearchCollabs (val, project_id) {
            const args = { include: 'type,user', 'filter[project_id]': project_id, count: 20, page: 1 }
            this.mixinSearchCollabs(val, args)
        },

        local_get_collabs (project_id, args = {}) {
            const params = {
                count: 1000, page: 1,
                include: 'user.role,type,contact',
                'fields[collaborators]': 'id,project_id,user_id,contact_id,group,scope,type_id,user_id',
                'fields[user]': 'id,name,email,scope,color,is_active,avatar,initial',
                'fields[contact]': 'id,name,email,parent_type,parent_id',
                'filter[project_id]': project_id,
                ...args
            }
            this.collaborator_index({ ...params })
        },

        check_est_permission (task) {
            return this.permission_task('update')
        },

        // Project --- Start
        localCheckPlanStageUpdate (stageToMove) {
            const { is_admin } = this.user_self || {}
            const { projects_active_count } = this.mixinGetOrgPlan() || {}

            if (this.$plan('active_projects_limit') === -1) return stageToMove()
            if (projects_active_count < this.$plan('active_projects_limit')) return stageToMove()

            if (is_admin) this.dialog_admin_upgrade_modal = true
            else this.dialog_user_upgrade_modal = true
        },
        // Project --- End

        local_show_filters_pane() {
            if (this.page_loading) return
            this.task_clear_item()
            this.user_filter_item = Object.assign({}, this.filter_preference)
            this.filters_pane = true
        },

        localSetUserParams () {
            this.local_user_filters[this.user_filter.FIELDS] = 'id,name,avatar,color,initial,is_active'
            this.local_user_filters[this.user_filter.SORT] = 'name'
            this.local_user_filters[this.user_filter.COUNT] = 5
            if (this.local_user_search) this.local_user_filters[this.user_filter.NAME] = this.local_user_search
        },

        local_team_filter_set(label, value) {
            this.task_clear_item()
            this.local_team_filter.label = label
            this.local_team_filter.value = value
            this.local_apply_filters('team')
        },

        async local_apply_filters (type, user_pref = null) {
            const filter_team_param = {
                hierarchy_report: {
                    label: this.local_team_filter.label,
                    value: this.local_team_filter.value
                }
            }
            this.user_filters_pref = (type === 'team') ? { ...this.user_filters_pref, ...filter_team_param } : { ...user_pref, ...filter_team_param }
            this.filters_pane = false
            const params = {
                resource_id: this.user_self.id,
                resource_type: 'User',
                value: JSON.stringify(this.user_filters_pref),
                label: 'Task Filter',
                key: 'user.tasks.filters',
                is_listable: 0,
            }
            if (this.filter_preference) {
                await this.preference_filters_update({ id: this.filter_preference.id, ...params })
                await this.local_fetch_preference()
            } else await this.preference_filters_store({ ...params })

            this.local_load_filtered_task_list()
        },

        local_set_filter_values () {
            if (this.user_self && !this.user_self.is_manager && this.local_team_filter && (this.local_team_filter.value !== 'self')) {
                this.local_team_filter['label'] = 'All Tasks'
                this.local_team_filter['value'] = 'all'
            }
            this.local_task_filters[this.task_filter.STATUS] = this.local_filter_projects
            this.local_task_filters[this.task_filter.COLLABORATORS] = _.size(this.selected_collaborators) ? _.map(this.selected_collaborators, 'id') : null
            this.local_task_filters[this.task_filter.STATUS_TYPE] = _.size(this.selected_status) ? _.map(this.selected_status, 'id') : null
            this.local_task_filters[this.task_filter.TERRITORY] = _.size(this.selected_territories) ? _.map(this.selected_territories, 'id') : null
            this.local_task_filters[this.task_filter.START_DATE] = this.start_date.operator ? this.local_set_date_filter_params(this.start_date) : null
            this.local_task_filters[this.task_filter.DUE_DATE] = this.due_date.operator ? this.local_set_date_filter_params(this.due_date) : null
            this.local_task_filters[this.task_filter.VISIBILITY] = this.local_filter_visibility !== 'all' ? this.local_filter_visibility : null
            this.local_task_filters[this.task_filter.HIERARCHY] = this.local_team_filter && this.local_team_filter.value !== 'all' ? this.local_team_filter.value : null
            if (this.local_team_filter && this.local_team_filter.value === 'self') {
                this.local_task_filters[this.task_filter.ASSIGNED] = 'self'
                this.local_task_filters[this.task_filter.HIERARCHY] = null
                return
            }
            this.local_task_filters[this.task_filter.ASSIGNED] = 'all'
        },

        local_set_date_filter_params (field) {
            const params = [field.operator_value, field.value, field.value_alt]
            return (field.operator_value === 'empty' || field.operator_value === 'not-empty') ? field.operator_value : params
        },

        async local_load_filtered_task_list() {
            this.page_loading = true
            // await this.task_clear()
            this.local_set_filter_pref()
            this.local_set_filter_values()
            this.local_load_project_list(this.local_filter_projects)
            await this.task_index({...this.local_task_params, ...this.local_task_filters })
            this.page_loading = false
        },

        local_set_hierarchy_list () {
            if (this.user_self && this.user_self.is_manager) return this.local_team_filter_list = this.filter_list_manager
            return this.local_team_filter_list = this.filter_list_team
        },

        local_error (status) {
            this.error_fields = status
        },

        local_revert_changes (item) {
            this.revert_task_item = {}
            this.revert_task_item = {...item}
        },

        local_revert_filter () {
            this.errors = {...this.reset_errors}
            this.filters_pane = false
            this.user_filter_item = Object.assign({}, this.filter_preference)
        },

        localStartLoader (slug) {
            const index = this.loading_modules.indexOf(slug)
            if (index !== -1) return

            this.loading_modules.push(slug)
        },

        localStopLoader (slug) {
            const index = this.loading_modules.indexOf(slug)
            if (index !== -1) this.loading_modules.splice(index, 1)
        },

        localIsLoading (slug) {
            const index = this.loading_modules.indexOf(slug)
            return index !== -1
        },

        localToggleAccordian (toggleFn, index) {
            if (typeof toggleFn === 'string') return this[toggleFn](index)
            toggleFn()
        },

        async local_update_project_item (project_id) {
            const filteredTasks = _.filter(this.task_list, ['project_id', project_id])
            const taskIds = _.map(filteredTasks, 'id')
            await this.project_show({ id: project_id, params: this.local_project_params })
            this.task_project_update({taskIds: taskIds, projectItem: this.project_item})
            this.local_task_item = { ...this.local_task_item , project: this.project_item }
        },

        // Task edit popup - Start

        localEditTask (task) {
            this.dialogTaskEdit = true
            // this.localNavigateFromDetailView(task.id)
        },

        localNavigateFromDetailView (taskId) {
            // TODO: Implement this later

            // const allTasks = Object.values(this.local_render).flat()
            // const count = _.size(allTasks)
            // const currentIndex = allTasks.findIndex(i => i.id === taskId)
            // if (currentIndex === -1) return
            // this.local_task_item = _.cloneDeep(allTasks[currentIndex])
            // if (currentIndex >= 0 && currentIndex <= count) {
            //     if (currentIndex === 0) this.previousTaskId = null
            //     else this.previousTaskId = allTasks[currentIndex - 1].id

            //     if ((currentIndex + 1) === count) this.nextTaskId = null
            //     else this.nextTaskId = allTasks[currentIndex + 1].id
            // }
        },

        localUpdateItemDescription (task, props) {
            const { encodedData, value, isEmpty } = props || {}
            const item = _.cloneDeep(task)
            item['description_json'] = encodedData

            this.localTaskFieldsUpdate({ ...task, description_json: value })
            this.localTaskUpdate(item, 'description_json', { isEmpty })
        },

        localOpenItemTimeTrack (props) {
            this.local_open_time_track(props)
        },

        localDeleteTask (item) {
            this.task_remove(item.id)
            this.task_destroy(item)
        },

        async localTaskUpdate (task, field = null, additionProps = {}) {
            const { isEmpty = false } = additionProps || {}
            const clonedTask = _.cloneDeep(task)

            if (clonedTask.hasOwnProperty('description_json') && field !== 'description_json') {
                const jsonDesc = (clonedTask.description_json && (typeof clonedTask.description_json !== 'string')) ? JSON.stringify(clonedTask.description_json) : clonedTask.description_json
                clonedTask['description_json'] = isEmpty || !clonedTask.description_json ? null : Base64.encode(jsonDesc)
            }

            this.task_new_update(clonedTask)
        },

        async localTaskFieldsUpdate (item) {
            this.task_update_list(item)
            this.localUpdateModalTaskItem(item)
        },

        localUpdateModalTaskItem (item) {
            if (!this.dialogTaskEdit) return

            // const allTasks = Object.values(this.local_render).flat()
            // const currentIndex = allTasks.findIndex(i => i.id === item.id)
            // this.local_task_item = _.cloneDeep(allTasks[currentIndex])
            this.local_task_item = item
        },

        async localUpdateItem (props, task, colSlug, isNoMilestone) {
            const { item, value } = props || {}
            this.singleTaskEdit = task

            if (colSlug === 'title') {
                this.localUpdateTaskTitle(colSlug, value, isNoMilestone)
            }

            if (colSlug === 'visibility') {
                this.localTaskFieldsUpdate({ ...task, visibility: item.visibility })
                this.task_visibility({ id: this.singleTaskEdit.id, state: item.visibility })
            }

            if (colSlug === 'status_id') {
                this.localTaskFieldsUpdate({ ...task, status: item, status_id: item.id })
                await this.task_state_update({ ids: [this.singleTaskEdit.id], status_id: item.id, project_id: this.singleTaskEdit.project_id })
                this.localAddSystemEntryToTaskTimeEst(item, this.singleTaskEdit)
            }

            if (colSlug === 'priority') {
                this.localTaskFieldsUpdate({ ...task, priority: item.id })
                this.localTaskUpdate({...task, priority: item.id})
            }

            if (colSlug === 'milestone_id') {
                this.localTaskFieldsUpdate({ ...task, milestone_id: item.id === 'no-milestone' ? null : item.id, milestone: item })
                this.localTaskUpdate({...task, milestone_id: item.id === 'no-milestone' ? null : item.id, milestone: item })
            }

            if (colSlug === 'start_date') {
                const startDateValue = value === 'Invalid date' ? null : value
                this.localTaskFieldsUpdate({ ...task, start_date: startDateValue })
                this.localTaskUpdate({...task, start_date: startDateValue })
            }

            if (colSlug === 'due_date') {
                const dueDateValue = value === 'Invalid date' ? null : value
                this.localTaskFieldsUpdate({ ...task, due_date: dueDateValue })
                this.localTaskUpdate({...task, due_date: dueDateValue })
            }

            if (colSlug === 'assignees') {
                this.localUpdateTaskAssignee(colSlug, item, isNoMilestone)
            }

            if (colSlug === 'attachments_count') {
                this.localTaskFieldsUpdate({ ...task, attachments_count: value })
                this.localTaskUpdate({...task, attachments_count: value})
            }
        },

        async localAddSystemEntryToTaskTimeEst (status, task) {
            const { id, estimated_duration_text, time_records_sum_duration_minutes } = task || {}
            if (status.status === 'Completed' && estimated_duration_text && !time_records_sum_duration_minutes) {
                const params = {
                    'include': 'milestone,assignees.collaborator.type,status',
                    'fields[status]': 'id,status,value,color,percentage',
                    'aggregate[time_records.duration_minutes]': 'sum'
                }
                await this.local_task_show(id, 'show', params)
                if (this.dialogTaskEdit) {
                    this.local_task_item = { ...this.local_task_item, ...this.task_item }
                    return this.localTaskFieldsUpdate(this.local_task_item)
                }

                this.localTaskFieldsUpdate({ ...task, ...this.task_item })
            }
        },

        async localUpdateTaskAssignee (colSlug, item, isNoMilestone) {
            if (colSlug !== 'assignees') return

            const isAssigned = this.localIsAssigneeAdded(item, this.singleTaskEdit)
            isAssigned ? this.localRemoveAssignee(isAssigned, this.singleTaskEdit, isNoMilestone) : this.localAddAssignee(item, this.singleTaskEdit, isNoMilestone)
        },

        localAddAssignee (collab, item, isNoMilestone) {
            const { item: taskItem, assigneeProps } = this.localAddAssigneeSetup(collab, item)

            this.localTaskFieldsUpdate(taskItem, isNoMilestone)
            this.assignee_store({ ...assigneeProps, project_id: taskItem.project_id, resource_type: 'Task' })
        },

        localIsAssigneeAdded (collab, item) {
            if (!item.assignees || _.size(item.assignees) === 0) return false

            const hasCollab = item.assignees.find(item => item.assignee.user_id === collab.user_id)
            return hasCollab || false
        },

        localRemoveAssignee (isAssigned, taskItem, isNoMilestone) {
            const index = taskItem.assignees.findIndex(i => i.assignee.id === isAssigned.assignee.id)
            if (index === -1) return true

            taskItem.assignees.splice(index, 1)
            this.localTaskFieldsUpdate(taskItem, isNoMilestone)
            this.assignee_destroy({ id: isAssigned.assignee.id })
        },

        localAddAssigneeSetup (collab, item) {
            const assigneeId = uuidv4()
            const collabItem = _.cloneDeep(collab)
            const assigneeProps = { id: assigneeId, resource_id: item.id, user_id: collab.user_id }
            const assignee = { ...collab.user, assignee: assigneeProps, collaborator: collabItem }

            if (!item.hasOwnProperty('assignees')) item['assignees'] = [assignee]
            else item.assignees.push(assignee)

            return { item, assigneeProps }
        },

        async localFetchMilestone (milestoneId, timeout = 500) {
            setTimeout(async () => {
                await this.milestone_show({
                    id: milestoneId,
                    params: {
                        'include': 'tasksCount,tasksCompletedCount',
                        'filter[project_id]': this.localProjectId,
                        'fields[milestones]': 'id,project_id,visibility',
                        sort: 'order'
                    }
                })
            }, timeout)
        },

        localUpdateTaskTitle (colSlug, value, isNoMilestone) {
            this.singleTaskEdit[colSlug] = _.trim(value)
            this.localTaskFieldsUpdate(this.singleTaskEdit)
            this.localTaskUpdate({ id: this.singleTaskEdit.id, title: this.singleTaskEdit.title, milestone_id: isNoMilestone ? null : this.singleTaskEdit.milestone_id  })
        },

        localSetLoadingStatus (e) {
            this.localAttachmentLoading = e
        },
        // Task edit popup - End

        ...mapActions('TimeRecord', {
            time_record_index: 'index',
            time_record_store: 'store',
            time_record_update: 'update',
            time_record_destroy: 'destroy',
            time_record_clear: 'clear',
        }),

        ...mapActions('Task', {
            task_list_update : 'list_update',
            task_index: 'index',
            task_show: 'show',
            task_store: 'store',
            task_assign: 'assign',
            task_complete: 'complete',
            task_state_update: 'state_update',
            task_visibility: 'visibility',
            task_uncomplete: 'uncomplete',
            task_client_visible: 'client_visible',
            task_client_invisible: 'client_invisible',
            task_update: 'update',
            task_select: 'select',
            task_upgrade: 'upgrade',
            task_downgrade: 'downgrade',
            task_template_import: 'template_import',
            task_remove: 'remove',
            task_destroy: 'destroy',
            task_reorder: 'reorder',
            task_clear: 'clear',
            task_clear_item: 'clear_item',
            task_clear_response: 'clear_response',
            task_refresh: 'refresh_list',
            task_project_update: 'task_project_update',
            task_update_list: 'task_update',
            task_new_update: 'new_update',
            task_remove: 'remove',
            task_bulk_visibility: 'bulk_visibility',
        }),

        ...mapActions('Comment', {
            comment_index: 'index',
            comment_store: 'store',
            comment_show: 'show',
            comment_visible: 'visible',
            comment_clear: 'task_comment_clear',
            comment_clear_item: 'clear_item',
        }),

        ...mapActions('Milestone', {
            milestone_index: 'index',
            milestone_store: 'store',
            milestone_task_update: 'task_update',
            milestone_task_remove: 'task_remove',
            milestone_clear: 'clear',
            milestone_clear_item: 'clear_item',
            milestone_clear_response: 'clear_response',
            milestone_task_remove: 'task_remove',
        }),

        ...mapActions('Project', {
            project_show: 'show',
            project_index: 'index',
            project_clear: 'clear',
            project_clear_item: 'clear_item',
        }),

        ...mapActions('Collaborator', {
            collaborator_index: 'index',
            collaborator_clear: 'clear',
        }),

        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_clear: 'clear',
        }),

        ...mapActions('TemplateTask', {
            template_task_export: 'template_export',
            template_task_clear: 'clear',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear'
        }),

        ...mapActions('Assignee', {
            assignee_store: 'store',
            assignee_destroy: 'destroy',
        }),

        ...mapActions('Territory', {
            territory_index: 'index',
            territory_clear: 'clear'
        }),

        ...mapActions('User', {
            user_index: 'index',
            user_show: 'show',
            user_clear: 'clear'
        }),

        ...mapActions('Preference', {
            preference_index : 'index',
            preference_filters_store : 'store',
            preference_filters_update : 'update',
        }),
    }
}
</script>

<style lang="scss" scoped>
.task-detail-pane {
    transition: 0.3s all;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    height: 100vh;
    width: 480px;
    overflow-y: auto;
    transform: translateX(480px);
}
.task-detail-pane.visible {
    transform: translateX(0);
}
.v-textarea textarea {
    font-weight: 400;
    font-size: 24px;
}
.text-wrap-pre {
    white-space: pre-wrap;
}
.c-project-title {
    a {
        &.router-link-exact-active {
            color: #42b983;
        }
        &:hover {
            color: #2196F3
        }
    }
}
@media screen and (max-width: 1400px) {
  .milestone-text {
    display: none;
  }
}
</style>

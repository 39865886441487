import PieChart from './PieChart.vue'
import NumbersChart from './NumbersChart.vue'
import DonutChart from './DonutChart.vue'
import BarChart from './BarChart.vue'
import LineChart from './LineChart.vue'
import StackedBarChart from './StackedBarChart.vue'
import MultipleBarChart from './MultipleBarChart.vue'
import MultipleLineChart from './MultipleLineChart.vue'
import Progress from './Progress.vue'
import FolderOpen from './FolderOpen.vue'
import RippleLoading from './RippleLoading.vue'
import ScopeBulkAddTag from './ScopeBulkAddTag.vue'
import ScopeBulkRemoveTag from './ScopeBulkRemoveTag.vue'
import ScopeBulkAddTagWhite from './ScopeBulkAddTagWhite.vue'
import ScopeBulkRemoveTagWhite from './ScopeBulkRemoveTagWhite.vue'
import SortAsc from './SortAsc.vue'
import SortDesc from './SortDesc.vue'
import Automation from './Automation.vue'
import EnterKey from './EnterKey.vue'

export default {
    enter_key: { component: EnterKey },
    automation: { component: Automation },
    sort_asc: { component: SortAsc },
    sort_desc: { component: SortDesc },
    pie_chart: { component: PieChart },
    numbers_chart: { component: NumbersChart },
    donut_chart: { component: DonutChart },
    bar_chart: { component: BarChart },
    line_chart: { component: LineChart },
    stacked_bar_chart: { component: StackedBarChart },
    multiple_bar_chart: { component: MultipleBarChart },
    multiple_line_chart: { component: MultipleLineChart },
    progress: { component: Progress },
    open_folder: { component: FolderOpen },
    ripple_loading: { component: RippleLoading },
    scope_bulkadd_tag: { component: ScopeBulkAddTag },
    scope_bulkremove_tag: { component: ScopeBulkRemoveTag },
    scope_bulkadd_tagwhite: { component: ScopeBulkAddTagWhite },
    scope_bulkremove_tagwhite: { component: ScopeBulkRemoveTagWhite },
}

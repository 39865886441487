<template>
    <a-responsive class="c-filters-side-pane elevation-12" :class="{'visible': isOpen}">
        <div style="position: relative">
            <div class="font-weight-medium white" style="position: sticky; top: 0; z-index: 200">
                <a-layout align-center class="pa-6">
                    <a-flex>
                        <div class="d-flex">
                            <a-icon size="24" color="grey darken-4">filter_alt</a-icon>
                            <span class="pl-1" style="fontSize: 20px">Filters</span>
                        </div>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink>
                        <a-btn small icon @click="local_revert_filter()">
                            <a-icon size="20" color="grey">cancel</a-icon>
                        </a-btn>
                    </a-flex>
                </a-layout>
                <a-divider></a-divider>
            </div>
            <div class="c-filter-content" ref="task_pane_filters">
                <div class="px-6 pt-5 pb-3">
                    <div class="md-body-1 font-weight-medium grey--text text--darken-2">Projects</div>
                    <v-radio-group v-model="local_filter_projects" hide-details row class="mt-1">
                        <a-btn height="40" :class="[local_filter_projects === 'all' ? 'c-radio-btn' : 'u-border']" outlined depressed class="md-caption mr-4 pl-3 pr-0" @click="local_filter_projects='all'">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="all"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_filter_projects === 'all' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']" style="width: 100%">All Projects</span>
                                </template>
                            </v-radio>
                        </a-btn>
                        <a-btn height="40" :class="[local_filter_projects === 'active' ? 'c-radio-btn' : 'u-border']" outlined depressed class="md-caption pl-3 pr-0" @click="local_filter_projects='active'">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="active"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_filter_projects === 'active' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']">Active Projects</span>
                                </template>
                            </v-radio>
                        </a-btn>
                    </v-radio-group>
                </div>
                <div class="px-6 mt-2 pb-3">
                    <div class="md-body-1 font-weight-medium grey--text text--darken-2">Visibility</div>
                    <v-radio-group v-model="local_filter_visibility" hide-details row class="mt-1">
                        <a-btn height="40" :class="[local_filter_visibility === 'all' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption mr-4 pl-3 pr-0" @click="local_filter_visibility='all'">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="all"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_filter_visibility === 'all' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']" style="width: 100%">All</span>
                                </template>
                            </v-radio>
                        </a-btn>
                        <a-btn height="40" :class="[local_filter_visibility === 'internal' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption pl-3 pr-0 mr-4" @click="local_filter_visibility='internal'">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="internal"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_filter_visibility === 'internal' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']">Private</span>
                                </template>
                            </v-radio>
                        </a-btn>
                        <a-btn height="40" :class="[local_filter_visibility === 'external' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption pl-3 pr-0" @click="local_filter_visibility='external'">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="external"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_filter_visibility === 'external' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']">Public</span>
                                </template>
                            </v-radio>
                        </a-btn>
                    </v-radio-group>
                </div>
                <a-layout class="px-6" column>
                    <a-flex class="mt-2">
                        <div class="d-flex justify-space-between">
                            <label class="md-body-1 font-weight-medium grey--text text--darken-2">Status</label>
                            <div class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer" v-if="selected_status && selected_status.length" @click="selected_status = []">Clear</div>
                        </div>
                        <a-autocomplete
                            v-model="selected_status"
                            :items="local_meta_list"
                            item-text="value"
                            item-value="id"
                            placeholder="Search Status"
                            class="pa-0 u-border mt-2"
                            :class="{'py-2 c-combo-border': selected_status && selected_status.length}"
                            background-color="grey lighten-5"
                            :loading="local_loading_status"
                            :search-input.sync="local_status_search"
                            @blur="local_status_search = ''"
                            :append-icon="null"
                            solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object
                        >
                            <template v-slot:selection="{ item, selected, parent }">
                                <a-chip :input-value="selected" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}" class="chip--select-multi mx-1 my-1">
                                    <div class="d-inline-flex align-center">
                                        <span class="md-body-2" :style="{ color: $color(item.color, 'color_text') }">{{ item.value | truncateText(20) }}</span>
                                        <a-icon size="18" :color="$color(item.color, 'color_text')" class="u-cursor-pointer ml-1"  @click.stop="parent.selectItem(item)">cancel</a-icon>
                                    </div>
                                </a-chip>
                            </template>
                            <template v-slot:item="{ item }">
                                <div>
                                    <a-chip :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}" class="chip--select-multi mx-1 mb-0">
                                        <div class="d-inline-flex align-center">
                                            <span class="md-body-2" :style="{ color: $color(item.color, 'color_text') }">{{ item.value | truncateText(45) }}</span>
                                        </div>
                                    </a-chip>
                                </div>
                            </template>
                            <template #no-data v-if="!mixinIsLoading('status-search')">
                                <div style="height: 32px" class="px-4 u-flex-center-y">
                                    <span :class="['md-body-2 body--text text--lighten-1']">No Data Found</span>
                                </div>
                            </template>
                        </a-autocomplete>
                    </a-flex>
                    <a-flex class="mt-5">
                        <div class="d-flex justify-space-between">
                            <label class="md-body-1 font-weight-medium grey--text text--darken-2">Start Date</label>
                            <div class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer" v-if="start_date.operator" @click.stop="local_clear_date_fields('start_date')">Clear</div>
                        </div>
                        <div class="mt-2 u-border rounded">
                            <a-menu bottom offset-y max-height="200">
                                <template v-slot:activator="{ on }">
                                    <a-card elevation="0" height="48" class="u-rounded-corners u-overflow-hidden py-2 grey lighten-5" v-on="on">
                                        <a-card-text class="px-2 py-1 d-flex justify-space-between">
                                            <div>
                                                <span v-if="start_date.operator" class="mx-1 md-body-1 grey--text text--darken-3">{{ start_date.operator }}</span>
                                                <span v-else class="mx-1 md-body-1 grey--text">Select operator</span>
                                            </div>
                                            <div>
                                                <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                            </div>
                                        </a-card-text>
                                    </a-card>
                                </template>
                                <a-list class="u-list-condensed">
                                    <a-list-item v-for="item in date_operator_list" :key="item.label" @click="local_set_start_date_operator(item.label, item.value)">
                                        <a-list-item-title class="py-3">
                                            <span class="md-body-1 grey--text text--darken-1">{{ item.label }}</span>
                                        </a-list-item-title>
                                    </a-list-item>
                                </a-list>
                            </a-menu>
                        </div>
                        <div class="mt-2 d-flex u-wfull">
                            <div :class="{'mr-4': start_date.operator === 'is within'}">
                                <s-date-picker noDueDate :startDateTitle="start_date.operator === 'is within' ? 'From Date' : 'Start Date'" v-if="!local_validate_fields('start_date')" v-model="start_date.value" :item="task_start_from" class="mt-2" @update="local_filter_timeline('start_from')">
                                    <v-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_check_date_errors('start_date_from')" z-index="1000">
                                        <template v-slot:activator="{on}">
                                            <div v-on="on">
                                                <a-sheet :min-width="start_date.operator === 'is within' ? 223 : 446" :class="[{ 'red darken-1 c-wiggle-short': errors.start_date_from },'u-border rounded']">
                                                    <template>
                                                        <v-text-field
                                                            v-model="local_readable_date_1"
                                                            :placeholder="start_date.operator === 'is within' ? 'From' : 'Start Date'"
                                                            background-color="grey lighten-5"
                                                            class="c-filter-date-input"
                                                            readonly solo flat hide-details
                                                            @input="local_filter_timeline('start_from')"
                                                        >
                                                            <div slot="append">
                                                                <v-icon v-if="local_readable_date_1" size="20" color="grey lighten-1" @click.stop="start_date.value=null">cancel</v-icon>
                                                            </div>
                                                            <div slot="prepend-inner">
                                                                <v-icon size="20" color="grey lighten-1">today</v-icon>
                                                            </div>
                                                        </v-text-field>
                                                    </template>
                                                </a-sheet>
                                            </div>
                                        </template>
                                        <span>Required Field</span>
                                    </v-tooltip>
                                </s-date-picker>
                            </div>
                            <div v-if="start_date.operator === 'is within'">
                                <s-date-picker noDueDate startDateTitle="To Date" v-model="start_date.value_alt" :item="task_start_to" class="mt-2" @update="local_filter_timeline('start_to')">
                                    <v-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_check_date_errors('start_date_to')" z-index="1000">
                                        <template v-slot:activator="{on}">
                                            <div v-on="on">
                                                <a-sheet min-width=206 :class="[{ 'red darken-1 c-wiggle-short': errors.start_date_to },'u-border rounded']">
                                                    <template>
                                                        <v-text-field
                                                            v-model="local_readable_date_2"
                                                            placeholder="To"
                                                            background-color="grey lighten-5"
                                                            class="c-filter-date-input"
                                                            readonly solo flat hide-details
                                                            @input="local_filter_timeline('start_to')"
                                                        >
                                                            <div slot="append">
                                                                <v-icon v-if="local_readable_date_2" size="20" color="grey lighten-1" @click.stop="start_date.value_alt=null">cancel</v-icon>
                                                            </div>
                                                            <div slot="prepend-inner">
                                                                <v-icon size="20" color="grey lighten-1">event</v-icon>
                                                            </div>
                                                        </v-text-field>
                                                    </template>
                                                </a-sheet>
                                            </div>
                                        </template>
                                        <span>Required Field</span>
                                    </v-tooltip>
                                </s-date-picker>
                            </div>
                        </div>
                    </a-flex>
                    <a-flex class="mt-3">
                        <div class="d-flex justify-space-between">
                            <label class="md-body-1 font-weight-medium grey--text text--darken-2">Due Date</label>
                            <div class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer" v-if="due_date.operator" @click.stop="local_clear_date_fields('due_date')">Clear</div>
                        </div>
                        <div class="mt-2 u-border rounded">
                            <a-menu bottom offset-y max-height="200">
                                <template v-slot:activator="{ on }">
                                    <a-card height="48" elevation="0" class="u-rounded-corners u-overflow-hidden grey lighten-5" v-on="on">
                                        <a-card-text class="px-2 py-3 d-flex justify-space-between">
                                            <div>
                                                <span v-if="due_date.operator" class="mx-1 md-body-1 grey--text text--darken-3">{{ due_date.operator }}</span>
                                                <span v-else class="mx-1 md-body-1 grey--text">Select operator</span>
                                            </div>
                                            <div>
                                                <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                            </div>
                                        </a-card-text>
                                    </a-card>
                                </template>
                                <a-list class="u-list-condensed">
                                    <a-list-item v-for="item in date_operator_list" :key="item.label" @click="local_set_due_date_operator(item.label, item.value)">
                                        <a-list-item-title class="py-3">
                                            <span class="md-body-1 grey--text text--darken-1">{{ item.label }}</span>
                                        </a-list-item-title>
                                    </a-list-item>
                                </a-list>
                            </a-menu>
                        </div>
                        <div class="mt-2 d-flex u-wfull">
                            <div :class="{'mr-4': due_date.operator === 'is within'}">
                                <s-date-picker noDueDate :startDateTitle="due_date.operator === 'is within' ? 'From Date' : 'Due Date'" v-model="due_date.value" v-if="!local_validate_fields('due_date')" :item="task_due_from" class="mt-2" @update="local_filter_timeline('due_from')">
                                    <v-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_check_date_errors('due_date_from')" z-index="1000">
                                        <template v-slot:activator="{on}">
                                            <div v-on="on">
                                                <a-sheet :min-width="due_date.operator === 'is within' ? 223 : 446" :class="[{ 'red darken-1 c-wiggle-short': errors.due_date_from },'u-border rounded']">
                                                    <template>
                                                        <v-text-field
                                                            v-model="local_readable_date_3"
                                                            :placeholder="due_date.operator === 'is within' ? 'From' : 'Due Date'"
                                                            background-color="grey lighten-5"
                                                            class="c-filter-date-input"
                                                            readonly solo flat hide-details
                                                            @input="local_filter_timeline('due_from')"
                                                        >
                                                            <div slot="append">
                                                                <v-icon v-if="local_readable_date_3" size="20" color="grey lighten-1" @click.stop="due_date.value=null">cancel</v-icon>
                                                            </div>
                                                            <div slot="prepend-inner">
                                                                <v-icon size="20" color="grey lighten-1">today</v-icon>
                                                            </div>
                                                        </v-text-field>
                                                    </template>
                                                </a-sheet>
                                            </div>
                                        </template>
                                        <span>Required Field</span>
                                    </v-tooltip>
                                </s-date-picker>
                            </div>
                            <div v-if="due_date.operator === 'is within'">
                                <s-date-picker noDueDate startDateTitle="To Date" v-model="due_date.value_alt" :item="task_due_to" class="mt-2" @update="local_filter_timeline('due_to')">
                                    <v-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_check_date_errors('due_date_to')" z-index="1000">
                                        <template v-slot:activator="{on}">
                                            <div v-on="on">
                                                <a-sheet v-on="on" min-width=206 :class="[{ 'red darken-1 c-wiggle-short': errors.due_date_to },'u-border rounded']">
                                                    <template>
                                                        <v-text-field
                                                            v-model="local_readable_date_4"
                                                            placeholder="To"
                                                            background-color="grey lighten-5"
                                                            class="c-filter-date-input"
                                                            readonly solo flat hide-details
                                                            @input="local_filter_timeline('due_to')"
                                                        >
                                                            <div slot="append">
                                                                <v-icon v-if="local_readable_date_4" size="20" color="grey lighten-1" @click.stop="due_date.value_alt=null">cancel</v-icon>
                                                            </div>
                                                            <div slot="prepend-inner">
                                                                <v-icon size="20" color="grey lighten-1">event</v-icon>
                                                            </div>
                                                        </v-text-field>
                                                    </template>
                                                </a-sheet>
                                            </div>
                                        </template>
                                        <span>Required Field</span>
                                    </v-tooltip>
                                </s-date-picker>
                            </div>
                        </div>
                    </a-flex>
                    <a-flex class="mt-3">
                        <div class="d-flex justify-space-between">
                            <label class="md-body-1 font-weight-medium grey--text text--darken-2">Collaborators</label>
                            <div class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer" v-if="selected_collaborators && selected_collaborators.length" @click="selected_collaborators = []">Clear</div>
                        </div>
                        <a-autocomplete
                            v-model="selected_collaborators"
                            :items="local_users_list"
                            item-text="name"
                            item-value="id"
                            placeholder="Search Collaborators"
                            class="mt-2 pa-0 u-border"
                            :class="{'py-2 c-combo-border': selected_collaborators && selected_collaborators.length}"
                            background-color="grey lighten-5"
                            :loading="local_loading_users"
                            :search-input.sync="local_user_search"
                            @blur="local_user_search = ''"
                            :append-icon="null"
                            solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object
                        >
                            <template v-slot:selection="{ parent, item }">
                                <span class="d-inline-flex align-center u-rounded-corners-full grey lighten-2 pl-3 pr-2 py-2 mx-1 my-1">
                                    <span class="mr-2 md-body-2 grey--text text--darken-4" :title="item.name">{{ item.name | truncateText(20) }}</span>
                                    <a-icon size="16" @click.stop="parent.selectItem(item)" class="u-cursor-pointer">cancel</a-icon>
                                </span>
                            </template>
                            <template v-slot:item="{ item }">
                                <a-layout align-center class="py-2">
                                    <g-avatar :item="item"></g-avatar>
                                    <g-profile-info :item="item" set-max-width></g-profile-info>
                                </a-layout>
                            </template>
                            <template #no-data v-if="!mixinIsLoading('collaborator-search')">
                                <div style="height: 32px" class="px-4 u-flex-center-y">
                                    <span :class="['md-body-2 body--text text--lighten-1']">No Data Found</span>
                                </div>
                            </template>
                        </a-autocomplete>
                    </a-flex>
                    <a-flex class="mt-5 mb-12">
                        <div class="d-flex justify-space-between">
                            <label class="md-body-1 font-weight-medium grey--text text--darken-2">Territories</label>
                            <div class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer" v-if="selected_territories && selected_territories.length" @click="selected_territories = []">Clear</div>
                        </div>
                        <a-autocomplete
                            v-model="selected_territories"
                            :items="local_territories_list"
                            item-value="id"
                            item-text="name"
                            placeholder="Search Territories"
                            class="pa-0 u-border mt-2"
                            :class="{'py-2 c-combo-border': selected_territories && selected_territories.length}"
                            background-color="grey lighten-5"
                            :loading="local_loading_territories"
                            :search-input.sync="local_territories_search"
                            @blur="local_territories_search = ''"
                            :append-icon="null"
                            solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object
                        >
                            <template v-slot:selection="{ item, parent }">
                                <a-chip :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}" class="chip--select-multi mx-1 my-1">
                                    <div class="d-inline-flex align-center">
                                        <span class="md-body-2" :style="{ color: $color(item.color, 'color_text') }">{{ item.name | truncateText(20)}}</span>
                                        <a-icon size="18" :color="$color(item.color, 'color_text')" class="u-cursor-pointer ml-1"  @click.stop="parent.selectItem(item)">cancel</a-icon>
                                    </div>
                                </a-chip>
                            </template>
                            <template v-slot:item="{ item }">
                                <a-chip :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}" class="chip--select-multi mx-1 mb-0">
                                    <div class="d-inline-flex align-center">
                                        <span class="md-body-2" :style="{ color: $color(item.color, 'color_text') }">{{ item.name | truncateText(45)}}</span>
                                    </div>
                                </a-chip>
                            </template>
                            <template #no-data v-if="!mixinIsLoading('territory-search')">
                                <div style="height: 32px" class="px-4 u-flex-center-y">
                                    <span :class="['md-body-2 body--text text--lighten-1']">No Data Found</span>
                                </div>
                            </template>
                        </a-autocomplete>
                    </a-flex>
                </a-layout>
            </div>
            <div class="white" style="position: sticky; bottom: 0; z-index: 200">
                <a-divider class="mt-16"></a-divider>
                <a-layout class="px-6 pa-6">
                    <a-flex>
                        <a-btn depressed class="c-btn-filter white--text mr-4" color="indigo darken-1" @click="local_apply_filters()">Filter</a-btn>
                        <a-btn class="grey--text text--darken-3" depressed @click="local_revert_filter()">Cancel</a-btn>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink>
                        <a-btn class="c-btn-clearAll red--text text--darken-1" depressed @click="local_clear_all_filters()" color="red lighten-5">Clear all</a-btn>
                    </a-flex>
                </a-layout>
            </div>
        </div>
    </a-responsive>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { SDatePicker } from '@/config/config-shared-components'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
export default {
    props: {
        isOpen: {
            type: Boolean
        },
        preferenceItem: {
            type: Object,
            required: true
        }
    },

    components: {
        SDatePicker
    },

    mixins: [mixinLoading],

    data () {
        return {
            local_filter_projects: 'active',
            selected_collaborators: [],
            selected_territories: [],
            selected_status: [],
            task_start_from: {
                start_date: null,
            },
            task_start_to: {
                start_date: null,
            },
            task_due_from: {
                start_date: null,
            },
            task_due_to: {
                start_date: null,
            },
            start_date: {
                operator: null,
                operator_value: null,
                value: null,
                value_alt: null
            },
            due_date: {
                operator: null,
                operator_value: null,
                value: null,
                value_alt: null
            },
            local_status_search: '',
            search_status_timeout: null,
            local_loading_status: false,
            local_meta_filters: {},
            meta_filter: {
                TYPE: 'filter[type]',
                NAME: 'filter[status_search]',
                FIELDS: 'fields[metas]',
                SORT: 'order'
            },
            search_territories_timeout: null,
            local_loading_territories: false,
            local_territories_search: '',
            local_territories_filters: {},
            territory_filter: {
                NAME: 'filter[name]',
                COUNT: 'count',
            },
            search_territories_timeout: null,
            local_filter_visibility: 'all',
            date_operator_list: [
                {label: 'is', value: 'equals'},
                {label: 'is not', value: 'not-equals'},
                {label: 'is within', value: 'between'},
                {label: 'is after', value: 'greater-than'},
                {label: 'is on or after', value: 'greater-than-or-equal'},
                {label: 'is before', value: 'lesser-than'},
                {label: 'is on or before', value: 'lesser-than-or-equal'},
                {label: 'is empty', value: 'empty'},
                {label: 'is not empty', value: 'not-empty'}
            ],
            errors: {
                start_date_from: false,
                start_date_to: false,
                due_date_from: false,
                due_date_to: false,
            },
            reset_errors: {
                start_date_from: false,
                start_date_to: false,
                due_date_from: false,
                due_date_to: false,
            },
            local_loading_users: false,
            local_user_search: '',
            search_user_timeout: null,
            local_user_filters: {},
            user_filter: {
                FIELDS: 'fields[users]',
                NAME: 'filter[user_search]',
                IS_ACTIVE: 'filter[is_active]',
                SORT: 'sort',
                COUNT: 'count',
            },
            user_filters_pref: {},
            filter_preference: null,
            local_territories_list: [],
            local_users_list: [],
            local_meta_list: [],
        }
    },

     watch: {
        isOpen (val) {
            if (!val) return
            this.$refs.task_pane_filters.scrollTop = 0
            this.local_index()
        },

        preferenceItem (val) {
            if (val && !val.id) return
            this.local_set_filter_pref()
        },

        local_territories_search (val) {
            if (!val || (val && !val.trim())) return
            this.local_territories_search_filter()
        },

        local_user_search (val) {
            if (!val || (val && !val.trim())) return
            this.local_user_search_filter()
        },

        local_status_search (val) {
            if (!val || (val && !val.trim())) return
            this.local_status_search_filter()
        }
    },

    computed: {
        local_filter_errors () {
            return this.errors.start_date_from || this.errors.start_date_to || this.errors.due_date_from || this.errors.due_date_to
        },

        local_readable_date_1: {
            get () {
                if (!this.start_date.value) return ''
                return moment(this.start_date.value).format('MMM DD, YYYY')
            },
            set (val) {
                if (!val) this.start_date.value = null
            }
        },

        local_readable_date_2: {
            get () {
                if (!this.start_date.value_alt) return ''
                return moment(this.start_date.value_alt).format('MMM DD, YYYY')
            },
            set (val) {
                if (!val) this.start_date.value_alt = null
            }
        },

        local_readable_date_3: {
            get () {
                if (!this.due_date.value) return ''
                return moment(this.due_date.value).format('MMM DD, YYYY')
            },
            set (val) {
                if (!val) this.due_date.value = null
            }
        },

        local_readable_date_4: {
            get () {
                if (!this.due_date.value_alt) return ''
                return moment(this.due_date.value_alt).format('MMM DD, YYYY')
            },
            set (val) {
                if (!val) this.due_date.value_alt = null
            }
        },

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('User', {
            users_list: 'list',
            user_self: 'self'
        }),

        ...mapState('Territory', {
            territories_list: 'list',
        }),

        ...mapState('Preference', {
            preference_list: 'list',
            preference_user_types: 'user_types',
        }),
    },

    methods: {
        local_index () {
            this.local_set_filter_list()
        },

        local_set_filter_list () {
            this.local_territories_list = [...this.selected_territories, ...this.territories_list]
            this.local_users_list = [...this.selected_collaborators, ...this.users_list]
            this.local_meta_list = [...this.selected_status, ...this.meta_list]
        },

        local_set_filter_pref () {
            if (!this.preferenceItem) return
            const local_filter_value = JSON.parse(this.preferenceItem.value)
            for (let filtervalue in local_filter_value) {
                switch (filtervalue) {
                    case 'status':
                        this.local_filter_projects = local_filter_value[filtervalue]
                        break;
                    case 'collaborators':
                        this.selected_collaborators = local_filter_value[filtervalue]
                        break;
                    case 'territories':
                        this.selected_territories = local_filter_value[filtervalue]
                        break;
                    case 'status_types':
                        this.selected_status = local_filter_value[filtervalue]
                        break;
                    case 'start_date':
                        this.start_date.operator = local_filter_value[filtervalue].operator
                        this.start_date.operator_value = local_filter_value[filtervalue].operator_value
                        this.start_date.value = local_filter_value[filtervalue].value
                        this.start_date.value_alt = local_filter_value[filtervalue].value_alt
                        break;
                    case 'due_date':
                        this.due_date.operator = local_filter_value[filtervalue].operator
                        this.due_date.operator_value = local_filter_value[filtervalue].operator_value
                        this.due_date.value = local_filter_value[filtervalue].value
                        this.due_date.value_alt = local_filter_value[filtervalue].value_alt
                        break;
                    case 'visibility':
                        this.local_filter_visibility = local_filter_value[filtervalue]
                        break;
                    default: break;
                }
            }
        },

        local_validate_fields(type) {
            if (type === 'start_date') return !this.start_date.operator || this.start_date.operator === 'is empty' || this.start_date.operator === 'is not empty'
            return !this.due_date.operator || this.due_date.operator === 'is empty' || this.due_date.operator === 'is not empty'
        },

        local_status_search_filter() {
            if(!this.local_status_search || this.local_status_search && !this.local_status_search.trim()) return
            this.mixinSetLoading('status-search')
            clearTimeout(this.search_status_timeout)
            this.search_status_timeout = window.setTimeout(async () => {
                await this.local_load_status_list()
            }, 500)
            this.mixinResetLoading('status-search')
        },

        async local_load_status_list () {
            this.local_loading_status = true
            this.local_meta_filters[this.meta_filter.NAME] = this.local_status_search
            this.local_meta_filters[this.meta_filter.TYPE] = 'task_status'
            this.local_meta_filters[this.meta_filter.FIELDS] = 'id,status,value,color,percentage'
            this.local_meta_filters[this.meta_filter.SORT] = 'order'
            await this.meta_index(this.local_meta_filters)
            this.local_meta_list = [...this.local_meta_list, ...this.meta_list]
            this.local_loading_status = false
        },

        local_clear_date_fields(type) {
            if (type === 'start_date') {
                this.start_date.operator = null
                this.start_date.operator_value = null
                this.start_date.value = null
                this.start_date.value_alt = null
                this.errors.start_date_from = false
                this.errors.start_date_to = false
                return
            }
            this.due_date.operator = null
            this.due_date.operator_value = null
            this.due_date.value = null
            this.due_date.value_alt = null
            this.errors.due_date_from = false
            this.errors.due_date_to = false
        },

        local_set_start_date_operator(type, value) {
            this.errors.start_date_from = false
            this.errors.start_date_to = false
            if (type !== 'is within') {
                this.start_date.value_alt = null
                this.errors.start_date_to = false
            }
            if (type === 'is empty' || type === 'is not empty') {
                this.start_date.value = null
                this.start_date.value_alt = null
            }
            this.start_date.operator = type
            this.start_date.operator_value = value
        },

        local_set_due_date_operator(type, value) {
            this.errors.due_date_from = false
            this.errors.due_date_to = false
            if (type !== 'is within') {
                this.due_date.value_alt = null
                this.errors.start_date_to = false
            }
            if (type === 'is empty' || type === 'is not empty') {
                this.due_date.value = null
                this.due_date.value_alt = null
            }
            this.due_date.operator = type
            this.due_date.operator_value = value
        },

        local_check_date_errors(field) {
            if (this.errors[field]) return this.errors[field]
        },

        local_filter_timeline(type) {
            if (type === 'start_from') return this.start_date.value = this.task_start_from.start_date
            if (type === 'start_to') return this.start_date.value_alt = this.task_start_to.start_date
            if (type === 'due_from') return this.due_date.value = this.task_due_from.start_date
            if (type === 'due_to') return this.due_date.value_alt = this.task_due_to.start_date
        },

        async local_user_search_filter() {
            if(!this.local_user_search || this.local_user_search && !this.local_user_search.trim()) return
            this.mixinSetLoading('collaborator-search')
            clearTimeout(this.search_user_timeout)
            this.search_user_timeout = window.setTimeout(async () => {
                await this.local_load_user_list()
            }, 500)
            this.mixinResetLoading('collaborator-search')
        },

        async local_load_user_list() {
            this.local_loading_users = true
            this.localSetUserParams()
            await this.user_index(this.local_user_filters)
            this.local_users_list = [...this.local_users_list, ...this.users_list]
            this.local_loading_users = false
        },

        localSetUserParams () {
            this.local_user_filters[this.user_filter.FIELDS] = 'id,name,avatar,color,initial,is_active'
            this.local_user_filters[this.user_filter.SORT] = 'name'
            this.local_user_filters[this.user_filter.COUNT] = 5
            this.local_user_filters[this.user_filter.NAME] = this.local_user_search
        },

        local_territories_search_filter(e, list) {
            if(!this.local_territories_search || this.local_territories_search && !this.local_territories_search.trim()) return
            this.mixinSetLoading('territory-search')
            clearTimeout(this.search_territories_timeout)
            this.search_territories_timeout = window.setTimeout(async () => {
                this.local_load_territories_list()
            }, 500)
            this.mixinResetLoading('territory-search')
        },

        async local_load_territories_list () {
            await this.meta_clear()
            this.local_loading_territories = true
            this.local_territories_filters[this.territory_filter.NAME] = this.local_territories_search
            this.local_territories_filters[this.territory_filter.COUNT] = 5
            await this.territory_index(this.local_territories_filters)
            this.local_territories_list = [...this.territories_list, ...this.local_territories_list]
            this.local_loading_territories = false
        },

        local_apply_filters () {
            this.local_check_start_date_errors()
            this.local_check_due_date_errors()
            if (this.local_filter_errors) return
            this.local_set_user_pref()
            this.$emit('update', 'filter', this.user_filters_pref)
        },

        local_check_start_date_errors() {
            if(!this.start_date.operator) return
            if(this.start_date.operator === 'is empty' || this.start_date.operator === 'is not empty') return
            if (this.start_date.operator === 'is within') {
                this.errors.start_date_from = !this.start_date.value ? true : false
                this.errors.start_date_to = !this.start_date.value_alt ? true : false
                return
            }
            if (this.start_date.operator) this.errors.start_date_from = !this.start_date.value ? true : false
        },

        local_check_due_date_errors() {
            if(!this.due_date.operator) return
            if(this.due_date.operator === 'is empty' || this.due_date.operator === 'is not empty') return
            if (this.due_date.operator === 'is within') {
                this.errors.due_date_from = !this.due_date.value ? true : false
                this.errors.due_date_to = !this.due_date.value_alt ? true : false
                return
            }
            if (this.due_date.operator) this.errors.due_date_from = !this.due_date.value ? true : false
        },

        local_set_user_pref () {
            this.user_filters_pref = {
                status: this.local_filter_projects,
                collaborators: this.selected_collaborators,
                territories: this.selected_territories,
                status_types: this.selected_status,
                start_date: {
                    operator: this.start_date.operator,
                    operator_value: this.start_date.operator_value,
                    value: this.start_date.value,
                    value_alt: this.start_date.value_alt,
                },
                due_date: {
                    operator: this.due_date.operator,
                    operator_value: this.due_date.operator_value,
                    value: this.due_date.value,
                    value_alt: this.due_date.value_alt,
                },
                visibility: this.local_filter_visibility,
            }
        },

        local_clear_all_filters() {
            this.local_reset_filters()
            this.errors = {...this.reset_errors}
            this.local_apply_filters()
        },

        local_reset_filters() {
            this.local_filter_projects = 'active'
            this.selected_collaborators = []
            this.selected_territories = []
            this.selected_status = []
            this.start_date.operator = null
            this.start_date.operator_value = null
            this.start_date.value = null
            this.start_date.value_alt = null
            this.due_date.operator = null
            this.due_date.operator_value = null
            this.due_date.value = null
            this.due_date.value_alt = null
            this.local_filter_visibility = 'all'
        },

        local_revert_filter () {
            this.errors = {...this.reset_errors}
            this.$emit('revert')
        },

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear'
        }),

        ...mapActions('Territory', {
            territory_index: 'index',
            territory_clear: 'clear'
        }),

        ...mapActions('User', {
            user_index: 'index',
            user_show: 'show',
            user_clear: 'clear'
        }),
    }
}
</script>

<style scoped>
.c-filters-side-pane.visible {
    transform: translateX(0);
}
.c-filters-side-pane {
    transition: 0.3s all;
    position: fixed;
    top: calc((100vh - 96vh)/2);
    left: 24px;
    height: 96vh;
    width: 500px;
    z-index: 10;
    background-color: white;
    transform: translateX(-528px);
    border-radius: 8px;
}
.c-filter-content {
    overflow-y: scroll;
    height: calc(96vh - 164px);
}
.c-radio-btn {
    border: 1px solid #3949AB;
    background-color: #E8EAF6;
}
.c-btn-clearAll.v-btn.v-btn--disabled {
    opacity: 0.5;
    background-color: #FFEBEE !important;
    color: #E53935 !important;
}
.c-combo-border {
    border: 1px solid rgba(238, 238, 238, 1) !important;
    background-color: #fafafa !important;
}
</style>

<template>
    <a-menu
        v-model="menuModal"
        v-bind="$attrs"
        :close-on-content-click="closeOnContentClick"
        :disabled="disabled"
    >
        <template v-slot:activator="{ on, value }">
            <slot name="trigger-wrap" :on="on" :value="value">
                <a-btn v-on="on" icon depressed class="u-rounded-corners" :class="[{ 'grey darken-3': value }]" height="32">
                    <slot name="trigger">
                        <a-icon :color="disabled ? 'grey darken-2' : 'white'" size="20">{{ triggerIcon }}</a-icon>
                    </slot>
                </a-btn>
            </slot>
        </template>

        <div @blur="menuModal = false" tabindex="0" style="outline: none;">
            <!-- Title and Search input -->
            <a-list v-if="localListLength > 4 || showTitle" class="u-list-condensed u-list-condensed--round-select u-overflow-y pa-2">
                <a-list-item v-if="showTitle" class="text-left px-5 py-1 grey--text text--darken-2">
                    <a-list-item-title class="md-body-2 font-weight-medium">
                        <slot name="title">Move Tasks between Milestones</slot>
                    </a-list-item-title>
                </a-list-item>
                <input
                    v-if="localListLength > 4"
                    v-model="searchText"
                    :placeholder="placeholder"
                    @click="closeOnContentClick = false"
                    type="text"
                    ref="refSearchText"
                    class="grey lighten-4 u-rounded-corners-lg grey--text text--darken-2 px-3 py-2 u-wfull md-body-2"
                >
            </a-list>

            <!-- List looped -->
            <slot name="list" :select="localEmit" :props="$props" :list="localFilteredList" :length="localListLength">
                <a-list
                    class="u-list-condensed u-list-condensed--round-select u-overflow-y c-tiny-scroll"
                    :class="[localListLength > 6 || showTitle ? 'pa-2 pt-0' : 'pa-2']"
                    :style="[{ maxHeight: maxHeight + 'px' }]"
                >
                    <a-list-item v-if="localFilteredList && !localFilteredList.length" class="md-body-2 grey--text text--darken-2 text-left px-4 mx-1 py-1">
                        <a-list-item-title class="text-left md-body-2">
                            <slot name="noData">No Milestone Found</slot>
                        </a-list-item-title>
                    </a-list-item>
                    <template v-else>
                        <template v-for="(item, index) in localFilteredList">
                            <slot name="item" :listItem="item" :itemIndex="index" :select="localEmit">
                                <a-list-item
                                    :key="item.id"
                                    :title="item[itemText]"
                                    @click="localEmit({ item })"
                                    class="u-rounded-corners-lg px-3 py-1 grey--text text--darken-2"
                                >
                                    <a-list-item-title class="md-body-2 text-truncate">{{ item[itemText] }}</a-list-item-title>
                                </a-list-item>
                            </slot>
                        </template>
                    </template>
                </a-list>
            </slot>

            <!-- Action -->
            <a-list v-if="allowAction" class="u-list-condensed u-list-condensed--round-select pa-2">
                <a-divider class="grey lighten-2"></a-divider>
                <slot name="action">
                    <a-list-item class="text-left px-4 py-1 mx-1 grey--text text--darken-2" @click="localEmit({ mode: 'create' })">
                        <a-list-item-title class="md-body-2 font-weight-medium">
                            <slot name="actionTitle">Move to New Milestone</slot>
                        </a-list-item-title>
                        <a-list-item-icon v-if="showActionIcon">
                            <a-icon size="18" color="white">{{ actionIcon }}</a-icon>
                        </a-list-item-icon>
                    </a-list-item>
                </slot>
            </a-list>
        </div>
    </a-menu>
</template>

<script>
export default {
    props: {
        itemText: {
            type: String,
            default: 'title'
        },
        triggerIcon: {
            type: String,
            default: 'import_export'
        },
        placeholder: {
            type: String,
            default: 'Search milestones...'
        },
        actionIcon: {
            type: String,
            default: 'content_copy'
        },
        minHeight: {
            type: String | Number,
            default: 224
        },
        maxHeight: {
            type: String | Number,
            default: 224
        },
        list: {
            type: Array,
            default: () => []
        },
        allowAction: {
            type: Boolean,
            default: false
        },
        showActionIcon: {
            type: Boolean,
            default: false
        },
        showTitle: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            searchText: '',
            menuModal: false,
            closeOnContentClick: false
        }
    },

    watch: {
        menuModal (val) {
            if (val && this.$refs.refSearchText) {
                setTimeout(() => this.$refs.refSearchText.focus(), 100)
            }
            this.$emit('active', val)
        },

        searchText (val) {
            if (val && val.trim()) this.closeOnContentClick = false
        },
    },

    computed: {
        localFilteredList () {
            const query = this.searchText && this.searchText.trim() ? this.searchText.toLowerCase() : null
            if (!query) return this.list

            return this.list.filter(milestone => {
                return milestone[this.itemText].toLowerCase().includes(query)
            })
        },

        localListLength () {
            return this.list && this.list.length
        }
    },

    methods: {
        localEmit ({ item, mode = 'select' } = {}) {
            this.$emit('change', { item, mode })
            this.menuModal = false
            this.closeOnContentClick = true
        }
    }
}
</script>

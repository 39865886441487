<template>
	<a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="py-6 pt-2 px-0 mb-14 pb-14">
            <g-page-header
                title="Preferences"
                subtitle="Manage your preferences."
                :loading="loading"
                icon="touch_app"
            ></g-page-header>

            <a-layout wrap align-start>
                <!-- <a-flex xs12 v-for="(items, group) in local_group_by(preference_org_list, 'resource_type')" :key="'group_'+group"> -->
                <a-flex xs12 pt-2>
                    <h2 class="md-heading-6 font-weight-medium primary--text">Organization</h2>
                    <p class="md-body-2 mb-3 grey--text text--darken-1 mb-6">These are organization level preferences.</p>
                    <!-- Meeting friendly view preference -->
                    <a-card class="pa-4 mb-4 u-rounded-corners u-shadow" v-if="localMeetingFriendView">
                        <div class="u-flex-center-y">
                            <h2 class="md-subtitle-1 primary--text">{{ localMeetingFriendView.label }}</h2>
                            <div class="md-body-2 grey--text text--darken-2 ml-2">{{ localMeetingFriendView.description }}</div>
                        </div>
                        <div class="u-flex-center-y mt-4 flex-wrap" style="column-gap: 1rem; row-gap: 1rem;">
                            <template v-for="item in meetingFriendlyOptions.values">
                                <a-sheet
                                    v-ripple="$can('preferences_organization.update')"
                                    @click="localUpdateFriendlyView(item)"
                                    :class="[($can('preferences_organization.update')) ? 'u-cursor-pointer' : '']"
                                    class="pa-4 u-rounded-corners u-flex-center-y u-border"
                                    style="column-gap: 0.5rem;"
                                    width="230"
                                    :key="item.slug"
                                    :color="item.value === 'Yes' ? 'indigo lighten-5' : 'grey lighten-4'"
                                >
                                    <a-icon size="18" :color="item.value === 'Yes' ? 'indigo darken-3' : 'grey darken-1'" :outlined="item.value === 'No'">check_circle</a-icon>
                                    <span class="md-body-2" :class="[item.value === 'Yes' ? 'indigo--text text--darken-3' : 'grey--text text--darken-1']">{{ item.label }}</span>
                                </a-sheet>
                            </template>
                        </div>
                    </a-card>

                    <template v-if="page_loading">
                        <a-divider class="grey lighten-2 mb-4"></a-divider>
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Preferences..</h3>
                    </template>

                    <template v-else>
                        <template v-for="(item, index) in localFilteredList">
                            <a-card :key="item.id" :class="[{ 'mt-3': index !== 0 }, 'u-shadow u-rounded-corners']">
                                <a-card-text class="px-4 py-3">
                                    <a-layout align-center>
                                        <a-flex>
                                            <h2 class="md-subtitle-1 primary--text">{{ item.label }} <span class="md-body-2 grey--text text--darken-2 ml-2 mb-0">{{ item.description }}</span></h2>
                                        </a-flex>
                                        <a-flex xs3>
                                            <a-select
                                                v-model="item.value"
                                                :items="item.values"
                                                item-text="label"
                                                item-value="value"
                                                background-color="grey lighten-4"
                                                class="u-border"
                                                :disabled="!($can('preferences_organization.update'))"
                                                :append-icon="!($can('preferences_organization.update')) ? '' : 'arrow_drop_down'"
                                                solo flat hide-details
                                                @input="local_update(item)"
                                            ></a-select>
                                        </a-flex>
                                    </a-layout>
                                </a-card-text>
                            </a-card>
                        </template>
                    </template>
                </a-flex>
            </a-layout>
        </a-container>

        <a-dialog v-model="dialog_preference_role" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl>
                        <a-layout wrap align-center>
                            <a-flex shrink>
                                <a-avatar color="orange">
                                    <a-icon color="white" size="24">warning</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-5">Title Change at Roles Page</h2>
                            </a-flex>
                        </a-layout>
                        <a-layout wrap align-center>
                            <a-flex>
                                <p class="md-body-1 grey--text text--darken-2 mb-0">
                                    Team's Roles page also has title labels. To keep things consistent, we recommended changing that title label as well.
                                </p>
                            </a-flex>
                        </a-layout>
                        <a-layout wrap align-center>
                            <a-flex>
                                <a-btn class="ma-0 elevation-0 px-3 green lighten-4 green--text text--darken-2" @click="dialog_preference_role_close()">Okay</a-btn>
                            </a-flex>
                            <a-flex shrink>
                                <a-btn color="primary" class="ma-0 elevation-0 px-3 right" @click="local_route_roles()">Go to Roles</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-snackbar v-model="snackbar_status" :timeout="snackbar_timeout" bottom left>
            {{ snackbar_text }}
            <a-btn color="primary lighten-3" text @click="snackbar_status = false">Close</a-btn>
        </a-snackbar>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'

export default {
    mixins: [mixinGetOrganizationPlan],

    data () {
        return {
            snackbar_status: false,
            dialog_preference_role: false,
            snackbar_timeout: 4000,
            snackbar_text: '',
            preference_role: '',
            meetingFriendlyOptions: [],
            friendlyView: {},
            page_loading: true
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        localFilteredList () {
            const filteredList = this.preference_org_list.filter(i => {
                return i.key !== 'organization.project.confidential_mode' && i.is_listable === 1
            })

            return filteredList.filter(item => {
                if (item.key === 'organization.confidence_score' && !this.localConfidenceScoreFlag) return
                if (item.key === 'organization.projects.widgets.confidence_score' && !this.localConfidenceScoreFlag) return

                const { flag_competitor_preferences } = this.user_self && this.user_self.organization
                if (['organization.competitor_type_selection', 'organization.link_competitor_type_product_tag'].includes(item.key) && flag_competitor_preferences === 0) return

                if (item.key === 'organization.inventory_view' && this.mixinGetOrgPlan({ field: 'subscription_plan.level' }) !== 2) return

                return item
            })
        },

        localConfidenceScoreFlag () {
            const { flag_confidence_score } = this.user_self && this.user_self.organization
            return flag_confidence_score === 1
        },

        localMeetingFriendView () {
            const item = this.preference_org_list.find(i => i.key === 'organization.project.confidential_mode')
            if (!item) return item

            return item
        },

        ...mapState('Preference', {
            preference_org_list: 'org_list',
            preference_item: 'item',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index () {
            await this.mixinFetchOrgPlanDetails()
            await this.preference_organization({ 'sort': 'order', 'filter[is_listable]': 1 })
            if (this.localMeetingFriendView) this.localFilterFriendlyOptions()
            this.page_loading = false
        },

        localFilterFriendlyOptions () {
            const list = _.cloneDeep(this.localMeetingFriendView)
            if (!this.localConfidenceScoreFlag) {
                const filteredList = list.values.filter(item => item.slug !== 'confidence_score')
                return this.meetingFriendlyOptions.values = _.cloneDeep(filteredList)
            }
            this.meetingFriendlyOptions = _.cloneDeep(list)
        },

        async local_update (item) {
            await this.preference_update({ ...item })
            if (this.preference_item.key === 'organization.label_salesengineer' || this.preference_item.key === 'organization.label_accountexecutive' ) {
                this.preference_role = this.preference_item.label.split(':')[1];
                this.dialog_preference_role = true
            }
            this.preference_index({ 'sort': 'order', 'filter[all]': 1 })
        },

        localUpdateFriendlyView (args) {
            if (!this.$can('preferences_organization.update')) return
            const pref = _.cloneDeep(this.localMeetingFriendView)
            const itemIndex = pref.values.findIndex(i => i.slug === args.slug)
            if (itemIndex === -1) return

            pref.values[itemIndex].value = args.value === 'Yes' ? 'No' : 'Yes'
            this.meetingFriendlyOptions = _.merge(this.localMeetingFriendView, pref)
            this.local_update({ ...this.meetingFriendlyOptions })
        },

        async dialog_preference_role_close () {
            await this.preference_clear_item()
            this.dialog_preference_role = false
        },

        async local_route_roles () {
            await this.preference_clear_item()
            this.$router.push({name: 'settings-roles'})
        },

        local_group_by (data, property) {
            return _.groupBy(data, property)
        },

        ...mapActions('Preference', {
            preference_index: 'index',
            preference_organization: 'organization',
            preference_update: 'update',
            preference_clear_item: 'clear_item',
        })
    },
}
</script>

<template>
    <div class="u-flex-center-y flex-wrap u-wfull">
        <div v-for="(tag, index) in selectedList" :key="index">
            <slot
                name="selection"
                :index="index"
                :selectionIndex="currentTagToRemove && currentTagToRemove.id === tag.id ? currentSelectionIndex : null"
                :confirmIndex="currentTagToRemove && currentTagToRemove.id === tag.id ? tagConfirmRemoveIndex : null"
                :item="tag"
            ></slot>
        </div>

        <slot name="placeholder" v-if="!showMenu && canUpdate">
            <template v-if="selectionType === 'single'">
                <div
                    style="flex-grow: 0; min-height: 24px; margin-top: 2px;"
                    :style="[{ flexBasis: minWidth + 'px', maxWidth: minWidth + 'px' }]"
                    class="d-inline-flex align-center"
                    :class="[localCheckSingleTagSelect ? 'u-cursor-pointer' : '']"
                    @click.stop="localCheckSingleTagSelect ? localOpenDropdown(): {}"
                >   
                    <template v-if="localCheckSingleTagSelect">
                        <a-icon color="grey" size="16" class="mr-1 u-icon-nudge" style="top: 1px">add</a-icon>
                        <span class="md-body-2 grey--text">Select {{ placeholderType }}</span>
                    </template>
                </div>
            </template>
            <template v-if="selectionType === 'multiple'">
                <div
                    style="flex-grow: 0; min-height: 24px; margin-top: 2px;"
                    :style="[{ flexBasis: minWidth + 'px', maxWidth: minWidth + 'px' }]"
                    :class="['d-inline-flex align-center u-cursor-pointer', { 'mb-1': selectedList.length }]"
                    @click.stop="localOpenDropdown()"
                >   
                    <a-icon color="grey" size="16" class="mr-1 u-icon-nudge" style="top: 1px">add</a-icon>
                    <span class="md-body-2 grey--text pt-1">
                        <template v-if="selectedList.length">
                        {{ canCreate ? 'Add ' : 'Select ' }} {{ placeholderType }}
                        </template>
                        <template v-else>
                            <template v-if="canCreate">Add {{ placeholderType }}</template>
                            <template v-else>Select {{ placeholderType }}</template>
                        </template>
                    </span>
                </div>
            </template>
        </slot>

        <template v-if="showMenu && canUpdate">
            <div
                class="u-relative"
                :id="attachId"
                :class="{ 'mb-1': selectedList && selectedList.length }"
                style="flex-grow: 0; height: 24px; margin-top: 2px;"
                :style="[{ flexBasis: minWidth + 'px', maxWidth: minWidth + 'px' }]"
            >
                <input
                    type="text"
                    v-model="modelSearchTag"
                    @click="localOpenDropdown()"
                    @paste="localOnPaste(modelSearchTag)"
                    :class="['md-body-2 grey--text text--darken-1 u-wfull']"
                    style="outline: none; height: 24px; min-width: 200px; max-width: 260px;"
                    autocomplete="off"
                >
                <slot name="input-placeholder" v-if="!modelSearchTag">
                    <span class="d-inline-flex align-center u-absolute md-body-2 grey--text text--lighten-1" :class="[{ 'mb-1': selectedList.length }]" style="left: 0px; top: 49%; transform: translateY(-50%); pointerEvents: none;">
                        <a-icon color="grey lighten-1" size="16" class="mr-1 u-icon-nudge" style="top: 1px">add</a-icon>
                        <span v-if="selectionType === 'single'" class="md-body-2 d-inline-block">{{ canCreate ? `Select ${placeholderType}` : `Select Existing ${placeholderType}` }}</span>
                        <span v-else class="md-body-2 d-inline-block pt-1">{{ canCreate ? `Add ${placeholderType}` : `Select Existing ${placeholderType}` }}</span>
                    </span>
                </slot>
                <a-menu v-model="modelDropdown" :attach="`#${attachId}`" :close-on-content-click="createLoading" offset-y nudge-bottom="30" min-width="100%" content-class="white u-shadow-md" transition="slide-y-transition">
                    <a-sheet class="u-rounded-corners-lg u-wfull">
                        <div class="u-flex-center u-wfull px-4 py-2 white" style="min-height: 53px;" v-if="tagSearchLoading || initialLoading">
                            <div class=" u-rounded-corners u-cursor-pointer u-flex-center grey lighten-4 px-2 py-3">
                                <a-sheet width="15" height="16" class="loader-animate u-rounded-corners"></a-sheet>
                                <a-sheet width="200" height="16" class="loader-animate u-rounded-corners ml-2"></a-sheet>
                            </div>
                        </div>
                        <div v-else>
                            <div class="c-tiny-scroll" style="overflow: auto; maxHeight: 219px" @scroll="localHandleScroll($event)" ref="scrollElementTags">
                                <ul class="c-custom-dropdown u-hfull px-4 white u-flex-center-y" style="min-height: 53px;" v-if="localCanShowNoData && (!localCheckInputTag.length || !canCreate)">
                                    <li class="u-rounded-corners px-2 u-flex-center-y">
                                        <span class="md-body-2 grey--text text--darken-1 text-truncate d-inline-block">No Data Found</span>
                                    </li>
                                </ul>
                                <ul v-else class="c-custom-dropdown c-tiny-scroll u-hfull" :class="[list.length > 0 && list[0].id !== 'create' ? 'pa-2' : 'pa-0']" tabindex="-1" >
                                    <template v-for="(tag, i) in list">
                                        <v-hover v-slot="{ hover }" :key="i">
                                            <li v-if="tag.id !== 'create'" @click="localSelectTag(tag)" data-link-type="tag-item" tabindex="0" class="pa-2 u-rounded-corners u-cursor-pointer" :class="[{ 'grey lighten-4': currentKeyIndex === i || hover }, { 'mt-1': i !== 0 }]">
                                                <slot name="item" :item="tag" :index="i">
                                                    <div class="u-flex-center-y">
                                                        <div class="u-rounded-corners mr-2" :style="{ width: '14px', height: '14px', flexShrink: 0, backgroundColor: $color(tag.color, 'color_bg', '#1565c0'), border: `1px solid ${$color(tag.color, 'color_text', '#1565c0')}` }"></div>
                                                        <span class="md-body-2 grey--text text--darken-1 text-truncate d-inline-block">{{ tag[itemText] }}</span>
                                                    </div>
                                                </slot>
                                            </li>
                                        </v-hover>
                                    </template>
                                </ul>
                                <ul v-if="lazyLoadCompState || lazyLoadTerrState" class="px-2 mb-2" :class="{'pt-2': localCanShowNoData}">
                                    <li class=" u-rounded-corners u-cursor-pointer u-flex-center grey lighten-4 px-2 py-3">
                                        <a-sheet width="15" height="16" class="loader-animate u-rounded-corners"></a-sheet>
                                        <a-sheet width="200" height="16" class="loader-animate u-rounded-corners ml-2"></a-sheet>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="modelSearchTag && modelSearchTag.trim() && canCreate">
                                <a-divider></a-divider>
                                <div class="pa-2">
                                    <v-hover v-slot="{ hover }" class="pa-2">
                                        <div                                         
                                            @click="canCreate ? localCreateTag() : ''"
                                            data-link-type="create-tag"
                                            tabindex="0"
                                            class="u-rounded-corners"
                                            :class="[                                    
                                                { 'u-cursor-pointer': canCreate },
                                                { 'pa-2': !localTagError },
                                                { 'grey lighten-4': ( hover) && canCreate },
                                            ]"
                                        >
                                            <div :class="{ 'u-border-error c-wiggle-short u-rounded-corners pa-2': localTagError }">
                                                <div class="u-flex-center-y">
                                                    <template v-if="canCreate">
                                                        <a-progress-circular width="2" size="16" color="indigo darken-1" class="mr-3" v-if="createLoading" indeterminate></a-progress-circular>
                                                        <a-icon size="16" color="grey darken-2" class="mr-2" v-else>add_circle</a-icon>
                                                    </template>
                                                    <span class="md-body-2 grey--text text--darken-1 text-truncate d-inline-flex align-center">
                                                        <template v-if="!canCreate">No Data Found</template>
                                                        <template v-else>
                                                            Create
                                                            <strong class="d-inline-block text-truncate ml-1">{{ modelSearchTag }}</strong>
                                                        </template>
                                                    </span>
                                                </div>
                                                <p v-if="localTagError" class="md-caption red--text text--darken-1 ml-6" style="marginBottom: -3px">{{ localTagError }}</p>
                                                <p v-else class="md-overline grey--text text--darken-1 ml-6 font-weight-bold" style="marginBottom: -3px">(Shift + Enter)</p>
                                            </div>
                                        </div>
                                    </v-hover>
                                </div>
                            </div>
                        </div>
                    </a-sheet>
                </a-menu>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        list: {
            type: Array,
            default: []
        },
        selectedList: {
            type: Array,
            default: () => []
        },
        attachId: {
            type: String,
        },
        initialLoading: {
            type: Boolean,
            default: false
        },
        canCreate: {
            type: Boolean,
            default: true
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        placeholderType: {
            type: String
        },
        itemText: {
            type: String,
            default: 'label'
        },
        selectionType: {
            type: String,
            default: 'multiple'
        },
        selectionId: {
            type: String,
        },
        noLoading: {
            type: Boolean,
            default: false
        },
        minWidth: {
            type: Number,
            default: 260,
        },
        totalCompPage: {
            type: Number,
            default:1
        },
        currCompPage: {
            type: Number,
            default: 1
        },
        lazyLoadCompState: {
            type: Boolean,
            default: false
        },
        totalTerrPage: {
            type: Number,
            default:1
        },
        currTerrPage: {
            type: Number,
            default: 1
        },
        lazyLoadTerrState: {
            type: Boolean,
            default: false
        },
    },

    data () {
        return {
            showMenu: false,
            currentKeyIndex: 0,
            modelDropdown: true,
            tagSearchLoading: false,
            tagError: null,
            createLoading: false,
            tagSearchTimeout: null,
            modelSearchTag: '',
            currentSelectionIndex: null,
            tagConfirmRemoveIndex: null,
            currentTagToRemove: {},
            tagRemoveTimeout: null,
            tagDestroyTimeout: null,
            removedTags: [],
            bulkCompChunkCount: 50,
            scrollElementTags: null
        }
    },

    watch: {
        modelSearchTag (val) {
            this.modelDropdown = !!_.trim(val)
            if (_.trim(val)) this.localSearchTags(val)
        },

        modelDropdown: {
            handler (val) {
                if (!val) {
                    this.currentKeyIndex = null
                    this.modelSearchTag = ''
                }
            },
            immediate: true
        },
    },

    mounted () {
        document.addEventListener('keydown', this.localGlobalKeyDown)
        this.$el.addEventListener('keydown', this.localKeyDown)
    },

    beforeDestroy () {
        document.removeEventListener('keydown', this.localGlobalKeyDown)
        this.$el.removeEventListener('keydown', this.localKeyDown)
    },

    computed: {
        localTagError () {
            return this.tagError
        },

        localFirstItemIsCreate () {
            const list = this.list && this.list
            return !!(list.length === 1 && list[0].id === 'create')
        },

        localCanShowNoData () {
            const listLength = (this.list && _.size(this.list)) ?? 0
            return !!(((!listLength || this.localFirstItemIsCreate) && !this.canCreate) || !listLength)
        },


        localCheckSingleTagSelect () {
            const list = this.selectedList.filter(item => item.category_id === this.selectionId)
            return list.length ? false : true
        },

        localCheckInputTag() {
            return this.modelSearchTag && this.modelSearchTag.trim()
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

     methods: {
         localOnPaste () {
            if (this.tagSearchTimeout) clearTimeout(this.tagSearchTimeout)
            this.localSearchTags(this.modelSearchTag)
        },

        async localSearchTags (value) {
            this.tagError = null
            clearTimeout(this.tagSearchTimeout)

            if (!this.modelDropdown) this.modelDropdown = true

            this.currentKeyIndex = 0
            if (!this.tagSearchLoading && !this.noLoading) this.tagSearchLoading = true
            this.tagSearchTimeout = setTimeout(async () => {
                this.localEmitEvent('search', { value, clearLoading: this.localClearSearchTimeout, resolveError: this.localShowError })
            }, 500)
        },

        localShowError (error) {
            this.createLoading = false
            this.modelDropdown = true
            if (!error) return this.tagSearchLoading = false

            this.tagError = error
            this.tagSearchLoading = false
            clearTimeout(this.tagSearchTimeout)
        },

        localClearSearchTimeout () {
            this.tagSearchLoading = false
            clearTimeout(this.tagSearchTimeout)
        },

        localOpenDropdown () {
            if (!this.noLoading) this.tagSearchLoading = true
            if (!this.showMenu) this.showMenu = true
            if (_.trim(this.modelSearchTag)) {
                if (this.modelDropdown) return this.localFocusInput()
                return this.localSearchTags(this.modelSearchTag)
            }

            if (this.modelDropdown) {
                this.currentKeyIndex = 0
                this.localEmitEvent('openDropdown', { clearLoading: this.localClearSearchTimeout })
                return this.localFocusInput()
            }

            this.modelDropdown = true
            this.currentKeyIndex = 0
            this.localFocusInput()
            this.localEmitEvent('openDropdown', { clearLoading: this.localClearSearchTimeout })
        },

        localKeyDown (evt) {
            this.localOnArrowDown(evt)
            this.localOnArrowUp(evt)
            this.localOnEnter(evt)
            this.localOnBackspace(evt)
            this.localOnShiftEnter(evt)
        },

        localGlobalKeyDown (evt) {
            this.localOnEscape(evt)
        },

        localOnEscape (evt) {
            if (evt.code !== 'Escape') return

            this.localResetTagIndices()
            this.localFocusInput()
            this.modelDropdown = false
            this.showMenu = false
            this.modelSearchTag = null
        },

        localResetTagIndices () {
            this.currentSelectionIndex = null
            this.tagConfirmRemoveIndex = null
        },

        localOnArrowDown (evt) {
            if (evt.code !== 'ArrowDown') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (!this.modelDropdown || this.tagSearchLoading || this.createLoading) return

            evt.preventDefault()
            if (this.currentKeyIndex === _.size(this.list) - 1 && (this.currCompPage < this.totalCompPage) && !this.loading) this.localEmitEvent('lazyLoadCompetitors', {page: this.currCompPage + 1, searchVal: this.localCheckInputTag})
            if (this.currentKeyIndex === _.size(this.list) - 1 && (this.currTerrPage < this.totalTerrPage) && !this.loading) this.localEmitEvent('lazyLoadTerritory', {page: this.currTerrPage + 1, searchVal: this.localCheckInputTag})
            if (this.currentKeyIndex === _.size(this.list) - 1 && ((this.currCompPage === this.totalCompPage && this.currTerrPage === this.totalTerrPage) || this.localCheckInputTag.length)) this.currentKeyIndex = 0
            else this.currentKeyIndex = this.currentKeyIndex + 1

            if (this.loading && this.currentKeyIndex >= _.size(this.list)) {
                this.currentKeyIndex = _.size(this.list) - 1
                this.$refs.scrollElementTags.scrollTop = this.$refs.scrollElementTags.scrollHeight
            }
            this.localFocusCurrentItem(evt, 'down')
            this.localFocusInput()
        },

        localOnArrowUp (evt) {
            if (evt.code !== 'ArrowUp') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (!this.modelDropdown || this.tagSearchLoading || this.createLoading) return

            evt.preventDefault()
            if (this.currentKeyIndex === 0) this.currentKeyIndex = _.size(this.list) - 1
            else this.currentKeyIndex = this.currentKeyIndex - 1

            this.localFocusCurrentItem(evt, 'up')
            this.localFocusInput()
        },

        async localOnEnter (evt) {
            if (evt.code !== 'Enter') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (this.tagSearchLoading || this.createLoading) return

            const listChildren = evt.target.parentElement.querySelector('.c-custom-dropdown')
            const isCreateTagLink = evt.target.tagName === 'LI' ? evt.target.dataset.linkType === 'create-tag' : (listChildren.children[this.currentKeyIndex] && listChildren.children[this.currentKeyIndex].dataset.linkType === 'create-tag')
            if (isCreateTagLink) {
                if (!this.canCreate) return this.createLoading = false
                return this.localCreateTag()
            }

            const selectedTag = this.list[this.currentKeyIndex]
            if (selectedTag && selectedTag.id !== 'create') this.localSelectTag(selectedTag)
            this.localFocusInput()
            // this.modelDropdown = false
        },

        async localOnShiftEnter(evt) {
            if (!evt.shiftKey) return
            if (evt.shiftKey && evt.code !== 'Enter') return
            await this.localCreateTag()
        },

        localOnBackspace (evt) {
            if (evt.code !== 'Backspace') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (_.trim(this.modelSearchTag) || this.tagSearchLoading || this.createLoading || this.createLoading) return
            if (!this.canUpdate) return

            this.localBackspaceTraverse(evt)
        },

        localFocusCurrentItem (evt, type = 'down') {
            const listChildren = evt.target.tagName === 'LI' ? evt.target.parentElement : evt.target.parentElement.querySelector('.c-custom-dropdown')
            if (listChildren) {
                const elArr = Array.from(listChildren.children)
                if (elArr && elArr[this.currentKeyIndex]) {
                    setTimeout(() => elArr[this.currentKeyIndex].focus(), 100)
                }
            }
        },

        async localCreateTag () {
            // this.createLoading = true
            this.localEmitEvent('create', { tag: { id: 'create', [this.itemText]: 'Create', value: this.modelSearchTag }, mode: 'create', resolveError: this.localShowError, resolveSuccess: this.localTagCreateSuccess, searchVal: this.localCheckInputTag  })
            this.localFocusInput()
        },

        localSelectTag (selectedTag) {
            this.localEmitEvent('create', { tag: selectedTag, mode: 'select', resolveError: this.localShowError, resolveSuccess: this.localTagCreateSuccess, searchVal: this.localCheckInputTag })
            this.localFocusInput()
            if (this.selectionType === 'single') this.showMenu = false
        },

        localTagCreateSuccess () {
            this.modelSearchTag = ''
            this.createLoading = false
        },

        localFocusInput () {
            setTimeout(() => {
                const input = this.$el.lastChild.firstChild
                if (input) input.focus()
            }, 200)
        },

        localEmitEvent (label, props = null) {
            this.$emit(label, props)
        },

        localBackspaceTraverse (evt) {
            if (this.currentSelectionIndex === null) this.localTraverseLeft()
            if (this.tagConfirmRemoveIndex !== null) {
                this.tagRemoveType = this.tagSearchType
                return this.localTagRemove()
            }
            if (this.currentSelectionIndex !== null) return this.localGetTagRemoveConfirm()
        },

        localTraverseLeft () {
            if (!this.canUpdate) return
            if (this.tagConfirmRemoveIndex) this.tagConfirmRemoveIndex = null

            const currentTypeTags = this.selectedList
            this.currentTagToRemove = currentTypeTags.slice(-1)[0]

            if (this.currentSelectionIndex === null) return this.currentSelectionIndex = currentTypeTags.length - 1
            if (this.currentSelectionIndex > 0) return this.currentSelectionIndex--
            if (this.currentSelectionIndex === 0 && currentTypeTags.length === 1) return this.currentSelectionIndex = currentTypeTags.length - 1
            if (this.currentSelectionIndex === 0) return this.currentSelectionIndex = currentTypeTags.length - 1
        },

        localGetTagRemoveConfirm () {
            if (this.tagRemoveTimeout) clearTimeout(this.tagRemoveTimeout)

            this.tagConfirmRemoveIndex = this.currentSelectionIndex
            this.tagRemoveTimeout = setTimeout(() => this.tagConfirmRemoveIndex = null, 2000)
        },

        async localTagRemove () {
            clearTimeout(this.tagDestroyTimeout)

            const lastTag = this.selectedList.splice(-1, 1)
            this.removedTags = [...this.removedTags, ...lastTag]
            await this.localDestoryTag()

            this.tagConfirmRemoveIndex = null
            this.currentSelectionIndex = null
            this.currentTagToRemove = {}
        },

        async localDestoryTag () {
            this.tagDestroyTimeout = setTimeout(async () => {
                const ceilCount = Math.ceil(this.removedTags.length / this.bulkCompChunkCount)
                for (let index = 0; index < ceilCount; index++) {
                    const chunkIds = this.removedTags.splice(0, this.bulkCompChunkCount)
                    if (!chunkIds || (chunkIds && !chunkIds.length)) continue
                    this.localEmitEvent('destroy', { ids: chunkIds })
                }
                clearTimeout(this.tagDestroyTimeout)
            }, 400)
        },

        localHandleScroll(e) {
            const scrollHeight = e.srcElement.scrollHeight, 
                  scrollTop = e.srcElement.scrollTop, 
                  offsetHeight = e.srcElement.offsetHeight;
            if (!this.loading && (scrollTop > (scrollHeight - offsetHeight - 1)) && (this.currCompPage < this.totalCompPage)) {
                return this.localEmitEvent('lazyLoadCompetitors', {page: this.currCompPage + 1, searchVal: this.localCheckInputTag})
            }
            if (!this.loading && (scrollTop > (scrollHeight - offsetHeight - 1)) && (this.currTerrPage < this.totalTerrPage)) {
                return this.localEmitEvent('lazyLoadTerritory', {page: this.currTerrPage + 1, searchVal: this.localCheckInputTag})
            }
        }
    }
}
</script>

<template>
    <div class="c-number-chart u-relative white">
        <p-tile-status
            :is_loading="item && (loading_statuses.includes(item.status) || chart_loading)"
            :has_error="item && item.status === 'error'"
            :has_stats="item && item.statistics && item.statistics.data && item.statistics.data.length && (primary_slot || secondary_slot)"
            @configure="$emit('configure', true)"
        >
            <template #loader>
                <div class="u-wfull u-hfull u-absolute text-center transparent u-opacity-40">
                    <a-img :src="require('../../../assets/images/loader.svg')" alt="Loading Line Chart.." width="48" height="48" contain class="d-inline-block mt-16"></a-img>
                </div>
            </template>
        </p-tile-status>
        <div class="d-flex align-end align-content-end flex-wrap u-hfull">
            <div class="text-h1 mb-2 u-wfull grey--text text--darken-3" v-if="primary_slot !== undefined">{{ primary_slot }}</div>
            <div class="text-h4 green--text darken-2 mb-1 u-wfull" v-if="secondary_slot !== undefined">{{ secondary_slot }}</div>
            <div class="text-body-2 grey--text text--darken-1 u-wfull">
                {{ description }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PTileStatus from './PartialTileStatus.vue'
import { formatCurrency } from '@/helpers/helper-currency.js'
import { formatTileOptions } from '../Helpers/helper-format-tile-options.js'

export default {
    name: 'PartialNumberChart',

    components: { PTileStatus },

    props: {
        item: {
            type: Object,
            default: {},
        },
    },

	data () {
		return {
            loading_statuses: ['loading', 'update-loading', 'new-loading'],
			local_chart: {},
			local_tile_options: {},
            stats_interval: null,
            stats_interval_countdown: 0,
            chart_loading: true,
			primary_slot: '',
			secondary_slot: '',
			primary_slot_description: '',
			secondary_slot_description: '',
		}
	},

    watch: {
        item: {
            handler (val) {
                this.local_index()
            },
            deep: true
        },
    },

    async mounted () {
        if (this.item) {
            await this.local_get_stats()
            await this.local_index()
        }
    },

	beforeDestroy () {
        clearInterval(this.stats_interval)
    },

    computed: {
        description() {
            if(this.primary_slot_description && this.secondary_slot_description) {
                return this.primary_slot_description + ' and ' + this.secondary_slot_description
            } else if (this.secondary_slot_description) {
                return this.secondary_slot_description
            } else {
                return this.primary_slot_description
            }
        },

        ...mapState('Tile', {
            tile_response: 'response',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index () {
            this.chart_loading = this.isRearrange ? false : true
            this.local_chart = _.cloneDeep(this.item)
            this.stats_interval_countdown = 0
            this.stats = []

            if (this.item && this.item.status === 'loaded') {
                this.stats = (this.item.statistics && this.item.statistics.data) || []
                this.local_clear_loading()
            }
            else {
                clearInterval(this.stats_interval)
                this.stats_interval = setInterval(async () => await this.local_get_stats_with_interval(), 1000)
                if (this.item && (this.item.status === 'error' || this.item.status === 'loaded')) this.local_set_error_state()
            }
        },

        local_clear_loading (time) {
            setTimeout(() => {
                this.chart_loading = false
            }, 1000)
        },

        async local_set_chart () {
            this.local_get_current_options()

            for(const stat of this.stats) {
                const { slot, value } = stat
                const optionIdentifier = _.camelCase(slot)
                const currencyValues = this.user_self.organization.currency

                if(stat.key !== 'none') {
                    if(this.local_tile_options[optionIdentifier]?.isCurrency) {
                        this[slot] = formatCurrency({ value, currencyValues })
                    }
                    else {
                        this[slot] = value
                    }

                    this[slot+'_description'] = this.local_tile_options[optionIdentifier]?.label
                } else {
                    this[slot] = ''
                    this[slot+'_description'] = ''
                }
            }
        },

        async local_get_stats () {
            this.local_chart = _.cloneDeep(this.item)
            await this.tile_show_stats({ id: this.local_chart.id })
            this.local_chart = _.cloneDeep(this.item)

            this.stats = (this.item.statistics ? this.item.statistics.data : []) || []
            await this.local_set_chart()
            clearInterval(this.stats_interval)
        },

        async local_get_stats_with_interval () {
            this.stats_interval_countdown = this.stats_interval_countdown + 1
            if ((this.item && this.item.status === 'error') || this.stats_interval_countdown >= 10) return this.local_set_error_state()
            if (this.item && (this.item.status === 'update-loading')) await this.local_get_stats()

            await this.local_fetch_tile_item()
            if (this.item && this.item.status === 'loaded') {
                this.stats_interval_countdown = 0
                await this.local_get_stats()
                return clearInterval(this.stats_interval)
            }
            if ((this.item && this.item.status === 'error') || this.stats_interval_countdown >= 10) return this.local_set_error_state()
        },

        local_set_error_state () {
            clearInterval(this.stats_interval)
            this.tile_update_tile_status({ id: this.local_chart.id, status: 'error' })
            this.stats_interval_countdown = 0
            this.chart_loading = false
        },

        async local_fetch_tile_item () {
            await this.tile_show({ id: this.local_chart.id, mode: 'show-modify', params: { include: 'tile_options,tile_filters,source' } })
        },

        local_get_current_options () {
            this.local_tile_options = formatTileOptions(this.local_chart.tile_options)
        },

        ...mapActions('Tile', {
            tile_show: 'show',
            tile_show_stats: 'show_stats',
            tile_update_tile_status: 'update_tile_status',
        }),
    },
}
</script>

<style>
.c-number-chart {
    display: flex;
    align-items: center;
    height: 227px;
    padding: 1rem;
}
</style>

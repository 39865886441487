<template>
    <a-card class="u-border u-rounded-corners" flat>
        <a-layout wrap class="pt-3 pb-2 px-2">
            <a-flex xs4 class="py-2 px-0">
                <div class="u-flex align-center pl-6">
                    <div class="mr-3">
                        <g-avatar :item="user" :size="48" :right-space="false"></g-avatar>
                    </div>
                    <div>
                        <h2 class="md-heading-6 font-weight-medium grey--text text--darken-3" :title="user.name && user.name.length > ($vuetify.breakpoint.lgAndDown ? 26 : 40) ? user.name : ''" v-if="user">{{ user.name | truncateText($vuetify.breakpoint.lgAndDown ? 26 : 40) }}</h2>
                        <p class="md-subtitle-2 font-weight-medium grey--text text--darken-2 mb-0" :title="user.email && user.email.length > ($vuetify.breakpoint.lgAndDown ? 26 : 40) ? user.email : ''" v-if="user">{{ user.email | truncateText($vuetify.breakpoint.lgAndDown ? 26 : 40) }}</p>
                    </div>
                </div>
            </a-flex>
            <a-spacer></a-spacer>
            <a-flex xs8 class="px-0 pr-5 py-2 text-right u-flex align-center justify-end" style="flex-wrap: wrap">
                <a-chip color="blue darken-2" class="mr-2" dark v-if="user && user.manager">
                    Manager: {{ user.manager.name | truncateText($vuetify.breakpoint.lgAndDown ? 20 : 40) }}
                </a-chip>
                <a-chip color="grey darken-2" v-if="user && user.role" class="mr-3" dark>
                    {{ user.role.name }}
                </a-chip>
                <a-sheet width="148" class="mr-3 text-right">
                    <p class="md-caption font-weight-bold grey--text text--darken-2 mb-0 text-uppercase" style="letter-spacing: 0.4px">Last login </p>
                    <p class="md-caption mt-n1 grey--text text--darken-1 mb-0" v-if="user && user.last_login_at">{{ local_format_date(user.last_login_at) }}</p>
                    <p class="md-caption mt-n1 grey--text text--darken-1 mb-0" v-else>(Never Logged In)</p>
                </a-sheet>
                <a-sheet width="86" class="mr-4 text-right" v-if="user.created_at">
                    <p class="md-caption font-weight-bold grey--text text--darken-2 mb-0 text-uppercase" style="letter-spacing: 0.4px">Joined </p>
                    <p class="md-caption mt-n1 grey--text text--darken-1 mb-0">{{ local_format_date(user.created_at, 'D MMM YYYY') }}</p>
                </a-sheet>
                <a-sheet class="text-right u-flex align-center justify-center">
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <span v-on="on">
                                <a-btn text icon class="pa-1" @click="user.id === user_self.id ? '' : $emit('toggle-block-user', user, user.is_active ? 0 : 1)">
                                    <a-icon v-if="user.is_active" size="16" color="green darken-1">fiber_manual_record</a-icon>
                                    <a-icon v-else size="18" color="red darken-3">block</a-icon>
                                </a-btn>
                            </span>
                        </template>
                        <div>
                            <div class="u-flex align-center justify-center">
                                <a-icon v-if="user.is_active" size="16" color="green darken-1">fiber_manual_record</a-icon>
                                <a-icon v-else size="18" color="red darken-1">block</a-icon>
                                <span class="white--text d-inline-block ml-2">Currently {{ user.is_active ? 'Active' : 'Blocked' }}</span>
                            </div>
                            <div class="white--text d-inline-block ml-2" v-if="user.id === user_self.id">You cannot block your own account</div>
                            <div class="white--text d-inline-block ml-2" v-else>Click to {{ user.is_active ? 'block the user' : 'unblock the user' }}</div>
                        </div>
                    </a-tooltip>
                </a-sheet>
                <template>
                    <template v-if="user.id !== user_self.id">
                        <a-tooltip bottom v-if="user.is_admin == 1">
                            <template v-slot:activator="{ on }">
                                <a-btn color="orange" text icon @click="$emit('toggle-admin', user.id, user.is_admin)" :disabled="local_action_state('downgrade', user.id)" :loading="local_action_state('downgrade', user.id)" v-on="on">
                                    <a-icon>star</a-icon>
                                </a-btn>
                            </template>
                            <span>Admin Account. Click to remove admin privilege.</span>
                        </a-tooltip>
                        <a-tooltip bottom v-if="user.is_admin == 0">
                            <template v-slot:activator="{ on }">
                                <a-btn color="grey" text icon @click="$emit('toggle-admin', user.id, user.is_admin)" :disabled="local_action_state('upgrade', user.id)" :loading="local_action_state('upgrade', user.id)" v-on="on">
                                    <a-icon>star</a-icon>
                                </a-btn>
                            </template>
                            <span>Regular Account. Click to add admin privilege.</span>
                        </a-tooltip>
                    </template>
                    <template v-if="user.id === user_self.id">
                        <a-tooltip bottom v-if="user.is_admin == 1">
                            <template v-slot:activator="{ on }">
                                <a-btn color="orange" text icon v-on="on">
                                    <a-icon>star</a-icon>
                                </a-btn>
                            </template>
                            <span class="text-center">You have Admin privilege.<br>You cannot downgrade yourself.</span>
                        </a-tooltip>
                    </template>
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <span v-on="on">
                                <a-btn color="grey" text icon @click="$emit('toggle-billing', user.id, user)">
                                    <a-icon :color="user && user.is_billing ? 'green' : ''">attach_money</a-icon>
                                </a-btn>
                            </span>
                        </template>
                        <span>Click to {{ user && user.is_billing ? 'remove' : 'add' }} billing privilege.</span>
                    </a-tooltip>
                </template>
                <a-btn text icon @click="$emit('edit-user', user.id)">
                    <a-icon size="20" color="grey darken-1">edit</a-icon>
                </a-btn>
            </a-flex>
        </a-layout>
        <a-divider></a-divider>
        <div class="px-3">
            <a-sheet class="u-flex align-center flex-wrap py-2">
                <g-tags v-for="territory in user.territories" :key="territory.id" :tag="territory" @destroy="$emit('remove-territory', user, territory)"></g-tags>
                <a-card @click="$emit('edit-territory', user.id)" class="u-rounded-corners-full md-caption u-flex align-center px-1 pr-2 my-1 mr-2" flat style="border: 1px dashed #c5c5c5; padding: 2px 8px;">
                    <a-icon size="16" color="grey darken-1">add</a-icon>
                    <span class="grey--text text--darken-1 ml-1">Add Territory</span>
                </a-card>
            </a-sheet>
        </div>
    </a-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        user: {
            type: Object,
            required: true
        },
    },

    computed: {
        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
            user_list: 'list',
            user_total_list: 'total_list',
            user_item: 'item',
            user_action: 'action',
        }),
    },

    methods: {
        local_format_date (date, format = 'MMM D, YYYY [at] hh:mm A') {
            return date ? moment(date).format(format) : null
        },

        local_action_state (action, id) {
            return this.loading && this.user_action.type === action && this.user_action.id === id
        },
    }
}
</script>

<style>

</style>

<template>
    <tr>
        <td class="pl-5 py-5">
            <loader-template width="260px" height="14px"></loader-template>
        </td>
        <td v-if="index%3 !== 0" class="py-5">
            <loader-template width="200px" height="14px"></loader-template>
        </td>
        <td v-if="index%3 !== 0" class="py-5">
            <loader-template width="30px" height="14px"></loader-template>
        </td>
        <td v-if="index%3 !== 0" class="py-5">
            <loader-template width="200px" height="14px"></loader-template>
        </td>
    </tr>
</template>

<script>
export default {
    props: {
        index: {
            type: Number,
            required: true
        }
    }
}
</script>

<template>
    <div
        :class="[{ 'c-summary-actions': !showActions }]"
        class="u-rounded-corners-full u-shadow u-flex u-absolute u-border-light"
        style="left: 50%; transform: translateX(-50%); bottom: -12px;"
    >
        <template v-if="showExpand">
            <a-btn
                @click="$emit('expand')"
                class="d-inline-flex items-center white px-2"
                style="border-radius: 50px 0px 0px 50px;"
                height="24"
                depressed
            >
                <a-icon size="16" color="grey darken-1" outlined>expand_circle_down</a-icon>
                <span class="md-caption grey--text text--darken-2 font-weight-medium ml-1">Expand</span>
            </a-btn>
            <a-sheet width="1" class="grey lighten-2"></a-sheet>
        </template>
            <!-- :style="[{ borderRadius: localStatusItem.description && localStatusItem.description.length > 50 ? '0px 50px 50px 0px' : '50px' }]" -->
        <a-btn
            @click="$emit('open')"
            :style="[{ borderRadius: showExpand ? '0px 50px 50px 0px' : '50px' }]"
            class="d-inline-flex items-center white px-2"
            height="24"
            depressed
        >
            <a-icon size="16" color="grey darken-1" outlined>arrow_outward</a-icon>
            <span class="md-caption grey--text text--darken-2 font-weight-medium">Open</span>
        </a-btn>
    </div>
</template>

<script>
export default {
    props: {
        showExpand: {
            type: Boolean,
            default: true
        },
        showActions: {
            type: Boolean,
            default: false
        },
        showOpen: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<template>
    <div
        class="u-flex u-relative align-stretch u-overflow-hidden"
        @dragenter="!localAttachmentsUpdating && localModuleHasAccess ? localHandleDragEnter() : ''"
        @drop="localHandleDrop"
    >
        <SDragOverlay
            v-if="filesDraggedOver"
            pageType="internal--dialog"
            @leave="localHandleLeave()"
            @enter="hoveredOnInfo=false"
            @enterInfo="hoveredOnInfo=true"
        />
        <a-sheet class="u-relative u-wfull pa-8 u-overflow-y c-tiny-scroll" :width="hideActivity ? 720 : ($vuetify.breakpoint.lgAndDown ? 680 : 720)" min-height="764" max-height="764">
            <div class="u-flex-center-y mb-4" v-if="showBreadcrumbs">
                <span
                    class="c-crumb-parent md-body-2 u-cursor-pointer body--text text--lighten-1 d-inline-block"
                    @click="!localAttachmentsUpdating ? $emit('openParent', parentItem) : {}"
                    :title="parentItem.title">{{ parentItem.title | truncateText(30) }}
                </span>
                <a-icon size="22" color="body lighten-1" class="mx-2">keyboard_arrow_right</a-icon>
                <span class="grey--text text--darken-2 md-body-2 font-weight-medium">{{ module === 'Testcase' ? 'Test Case' : 'Success Criterion' }}</span>
            </div>

            <div class="u-flex align-stretch mb-4">
                <!-- Status dropdown -->
                <div class="mr-4" v-if="!hideStatus">
                    <slot name="status"></slot>
                </div>

                <!-- Item code -->

                <span v-if="local_item.code && !local_is_external" class="u-flex align-center md-body-2 grey--text text--darken-1 mr-5 font-weight-medium">
                    <a-icon size="20" color="grey darken-1" class="mr-1">tag</a-icon>
                    <span>{{ local_item.code }}</span>
                </span>

                <!-- Direct delete button -->
                <template v-if="soloDelete && canDelete && canUpdate">
                    <div class="u-list-std">
                        <a-sheet @click="!localAttachmentsUpdating ? confirm_delete ? local_delete_item(editItem.id) : local_get_delete_confirm() : {}" :class="['u-cursor-pointer pa-1 px-2 u-flex-center-y u-rounded-corners', confirm_delete ? 'c-delete-confirm' : 'grey lighten-4']">
                            <a-icon class="mr-3" size="20" color="grey darken-2">delete</a-icon>
                            <span>{{ confirm_delete ? 'Confirm Delete?' : 'Delete Record' }}</span>
                        </a-sheet>
                    </div>
                </template>

                <!-- View more -->
                <template v-else>
                    <a-menu offset-y :close-on-content-click="false" :disabled="!canUpdate || !canDelete || localAttachmentsUpdating" min-width="200" v-if="canUpdate && canDelete">
                        <template v-slot:activator="{ on }">
                            <a-sheet v-on="on" height="inherit" width="36" class="u-flex-center grey lighten-4 u-rounded-corners u-cursor-pointer">
                                <a-icon size="20" color="grey darken-1">more_horiz</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-std">
                            <a-list-item @click="confirm_delete ? local_delete_item(editItem.id) : local_get_delete_confirm()" :class="[{ 'c-delete-confirm': confirm_delete }]">
                                <a-list-item-icon>
                                    <a-icon class="mr-3" size="20" color="grey darken-2">delete</a-icon>
                                </a-list-item-icon>
                                <a-list-item-content>{{ confirm_delete ? 'Confirm Delete?' : 'Delete Record' }}</a-list-item-content>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </template>

                <a-spacer></a-spacer>

                <!-- Saving indicator -->
                <div v-if="canUpdate" class="d-inline-flex align-center md-body-2 grey--text text--darken-1 font-weight-medium" :class="[{ 'mr-8': hideActivity }]">
                    <template v-if="timer_update_debounce">
                        <a-icon size="20" color="grey darken-1" class="mr-1">pending</a-icon>
                        <span>Saving...</span>
                    </template>
                    <template v-if="!timer_update_debounce && update_autosaved_at">
                        <a-icon size="20" color="green darken-1" class="mr-1">cloud_done</a-icon>
                        <span class="green--text text--darken-1">Saved</span>
                    </template>
                </div>
            </div>

            <span v-if="hideActivity" class="grey--text text--darken-3 u-cursor-pointer u-rounded-corners-lg u-absolute" @click="!localAttachmentsUpdating ? local_emit_close() : {}" style="font-size: 18px; right: 32px;" :style="[{ top: canDelete ? '32px' : '18px' }]">&times;</span>

            <!-- Title -->
            <div class="c-textarea-editor c-textarea-editor--item-view c-tiny-scroll u-relative mb-4" :style="[ { maxHeight: `${titleHeight !== 'auto' ? titleHeight + 'px' : ''} !important` }]">
                <a-textarea
                    v-model="item_title"
                    :disabled="!canUpdate || localAttachmentsUpdating"
                    class="font-weight-regular text-h5 font-weight-bold c-title-color"
                    background-color="transparent"
                    :placeholder="`${module === 'Testcase' ? module : 'Success Criterion'} title goes here..`"
                    rows="1"
                    @keyup="local_update_title()"
                    multi-line auto-grow solo flat hide-details
                >
                    <template #append v-if="(!item_title || (item_title && !item_title.trim().length)) || (item_title && item_title.trim().length > charLimitTitle)">
                        <div>
                            <a-icon color="red darken-1" size="20">info</a-icon>
                            <a-sheet class="c-tooltip-pointer c-tooltip-pointer--right-light u-absolute u-shadow px-3 py-1 md-caption u-rounded-corners red--text text--darken-1" style="right: -1px; z-index: 10">
                                <template v-if="item_title && item_title.trim().length > charLimitTitle">({{ item_title && item_title.trim().length }} / {{ charLimitTitle }})</template>
                                <template v-else>Required Field</template>
                            </a-sheet>
                        </div>
                    </template>
                </a-textarea>
            </div>

            <!-- Assignee & Dates & Time tracking & Attachments -->
            <div v-if="!hideMetas" class="u-flex align-stretch mb-5">
                <!-- Assignee -->
                <div class="mr-2 u-flex-center-y" v-if="!hideAssignee">
                    <slot name="assignee"></slot>
                </div>

                <!-- Dates -->
                <template v-if="!hideDate">
                    <div :class="['u-flex align-center mr-5', { 'u-cursor-pointer': canUpdate }]" v-if="local_is_testcase">
                        <s-date-picker :disabled="!canUpdate" :item="editItem" @update="local_date_update">
                            <template v-if="editItem.start_date && editItem.due_date">
                                <a-icon size="18" color="body lighten-3" class="mr-2">date_range</a-icon>
                                <g-moment class="md-body-2 body--text text--lighten-2" :value="editItem.start_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(editItem, 'range')"></g-moment>
                                <span class="mx-1 body--text text--lighten-2"> - </span>
                                <g-moment class="md-body-2" :class="[local_check_due_date(editItem) ? 'red--text text--darken-2' : 'body--text text--lighten-2']" :value="editItem.due_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(editItem, 'range')"></g-moment>
                            </template>
                            <template v-if="editItem.start_date && !editItem.due_date">
                                <a-icon size="18" color="body lighten-3" class="mr-2">today</a-icon>
                                <span class="md-body-2 body--text text--lighten-2 mr-1">Start Date: </span>
                                <g-moment class="md-body-2" :class="[local_check_due_date(editItem) ? 'red--text text--darken-2' : 'body--text text--lighten-2']" :value="editItem.start_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(editItem.start_date)"></g-moment>
                            </template>
                            <template v-if="!editItem.start_date && editItem.due_date">
                                <a-icon size="18" color="body lighten-3" class="mr-2">event</a-icon>
                                <span class="md-body-2 body--text text--lighten-2 mr-1">Due Date: </span>
                                <g-moment class="md-body-2" :class="[local_check_due_date(editItem) ? 'red--text text--darken-2' : 'body--text text--lighten-2']" :value="editItem.due_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(editItem.due_date)"></g-moment>
                            </template>
                            <template v-if="!editItem.start_date && !editItem.due_date">
                                <a-icon size="18" color="body lighten-3" class="mr-2">calendar_today</a-icon>
                                <span class="md-body-2 body--text text--lighten-2 mr-1">Select Date</span>
                            </template>
                        </s-date-picker>
                    </div>
                </template>

                <!-- Time tracker -->
                <template v-if="!hideTimeTrack">
                    <div v-if="local_is_testcase" class="u-cursor-pointer u-flex align-center mr-5" @click="$emit('open-timetracker', editItem)">
                        <a-tooltip bottom :disabled="!local_tracked_is_greater">
                            <template v-slot:activator="{ on }">
                                <div v-on="on" class="u-flex-center-y">
                                    <a-icon :color="local_tracked_is_greater ? 'orange darken-2' : 'body lighten-3'" size="18" class="mr-2">timer</a-icon>
                                    <span class="md-body-2 body--text text--lighten-2">
                                        {{ editItem.time_records_sum_duration_minutes ? local_get_time_text(editItem.time_records_sum_duration_minutes) : '0m' }}
                                        <template v-if="editItem.estimated_duration_text">
                                            / {{ editItem.estimated_duration_text }}
                                        </template>
                                    </span>
                                </div>
                            </template>
                            <div class="u-flex-center-y">
                                <a-icon size="18" color="orange darken-2" class="mr-1">info</a-icon>
                                <span>Over Estimation</span>
                            </div>
                        </a-tooltip>
                    </div>
                </template>
            </div>

            <!-- Tags -->
            <template v-if="!hideTags">
                <div class="pa-0 mb-4 d-inline-flex flex-wrap" v-if="local_item && local_item.tags && local_item.tags.length || canUpdate">
                    <template v-if="local_item && local_item.tags && local_item.tags.length">
                        <template v-for="tag in local_item.tags">
                            <g-tags :can-update="canUpdate" :tag="tag" type="Usecase" @destroy="local_associate_tag_destroy(tag, local_item)" :key="tag.id"></g-tags>
                        </template>
                    </template>
                    <a-menu bottom offset-y v-model="show_detail_view_tag_input" v-if="canUpdate" :disabled="localAttachmentsUpdating" :close-on-content-click="false" ref="refAddTagPopup" transition="slide-y-transition" nudge-bottom="8">
                        <template v-slot:activator="{ on }">
                            <g-add-btn icon-size="14" v-on="on">
                                <span class="md-caption body--text text--lighten-1 ml-1">Add Tag</span>
                            </g-add-btn>
                        </template>
                        <a-sheet class="pb-1" max-width="240">
                            <slot name="tag-inline">
                                <s-tags-inline-form
                                    :item="local_item"
                                    :can-update="$can('tags.store')"
                                    :list="local_get_filtered_tags(local_item)"
                                    :is-menu-open="show_detail_view_tag_input"
                                    :source="module"
                                    :type="tagType"
                                    :parent-type="parentType"
                                    @store="local_load_tags_list"
                                    @after-store="() => show_detail_view_tag_input = false"
                                ></s-tags-inline-form>
                            </slot>
                        </a-sheet>
                    </a-menu>
                </div>
            </template>

            <!-- Description -->
            <div>
                <h4 class="md-body-2 grey--text text--darken-1 font-weight-medium mb-3">Description</h4>
                <g-editor-box
                    v-model="item_description"
                    @update="local_update_description"
                    v-if="isOpen && load_desc_section"
                    :can-update="canUpdate && !localAttachmentsUpdating"
                    :model-is-open="isOpen"
                    :char-limit="charLimitDesc"
                    :editor-min-height="editorMinHeight"
                    :editor-height="editorHeight"
                    :readOnly="localAttachmentsUpdating"
                    :hideFocus="localAttachmentsUpdating"
                    :refresh="refreshDesc"
                    customMinHeight="100%"
                    customMaxHeight="100%"
                    :absolute-formatter="absoluteFormatter"
                    dense
                ></g-editor-box>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(response, 'description')">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ $response(response, 'description') }}
                </span>
            </div>

            <!-- Attachment section -->
            <div class="mt-4" v-if="localModuleHasAccess && isOpen">
                <SharedAttachmentSection
                    :list="localGetAttachmentData('local_list')"
                    :dropTransferList="localDroppedFilesList"
                    :sourceId="editItem.id"
                    :updateLoading="localAttachmentsUpdating"
                    :loading="mixinIsLoading('attachment')"
                    :canUpdate="canUpdate"
                    :sourceType="module"
                    @dropTransfer="localUpdateAttachmentTransfer"
                    @loadMore="localLoadMoreAttachments()"
                    @remove="localRemoveAttachmentItem"
                    @delete="localDeleteAttachmentItem"
                    :isExternal="local_is_external"
                    flat autoUpdate
                />
            </div>
        </a-sheet>

        <!-- Activities -->
        <template v-if="!hideActivity">
            <a-sheet class="grey lighten-4" width="360" min-height="764" max-height="764">
                <div class="u-flex-center-y pa-6 py-4">
                    <div>
                        <h4 class="md-body-2 grey--text text--darken-1 font-weight-medium" style="">Activities</h4>
                    </div>
                    <a-spacer></a-spacer>
                    <a-tooltip bottom :disabled="localAttachmentsUpdating">
                        <template v-slot:activator="{ on }">
                            <span v-on="on" class="grey--text text--darken-3 u-cursor-pointer u-rounded-corners-lg" @click="!localAttachmentsUpdating ? local_emit_close() : {}" style="font-size: 18px;">&times;</span>
                        </template>
                        <span class="md-body-2"><strong class="c-mono-font">Esc</strong> to close</span>
                    </a-tooltip>
                </div>

                <a-divider class="grey lighten-2"></a-divider>

                <!-- Comments list -->
                <a-sheet ref="refActivityScrollbar" class="pa-6 transparent u-overflow-y c-tiny-scroll" :min-height="`calc(${local_comment_list_height}px)`" :max-height="`calc(${local_comment_list_height}px)`">
                    <v-slide-y-transition group leave-absolute hide-on-leave key="transition-wrapper" tag="div">
                        <template v-if="loaders.sidebar_comments_loading && (editItem && (!editItem.comments || !editItem.comments.length))">
                            <loader-comments key="loader" wrapper-class="transparent" color="grey lighten-2"></loader-comments>
                        </template>
                        <template v-else>
                            <template v-if="editItem && editItem.comments && editItem.comments.length">
                                <div v-for="(comment, index) in editItem.comments" :key="comment.id" :class="[{ 'mt-4': index !== 0 }, { 'c-public-border-l': comment.visibility === 'external' && !local_is_external && parentItem.visibility === 'external' }]">
                                    <a-sheet :class="['u-rounded-corners pa-3']">
                                        <div class="u-flex">
                                            <g-avatar class="mr-2" :item="comment.created_by" no-shadow :size="34" :right-space="false"></g-avatar>
                                            <div>
                                                <div class="md-body-2 body--text text--darken-3" v-if="comment.created_by">{{ comment.created_by.name }}</div>
                                                <div class="u-flex-center-y">
                                                    <g-moment tag="div" class="md-caption body--text text--lighten-3" :value="comment.updated_at ? comment.updated_at : comment.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD [at] hh:mma" convert-local></g-moment>
                                                    <a-sheet class="body--text text--lighten-3 md-caption ml-2 transparent" v-if="comment.updated_at && local_date_diff(comment.created_at, comment.updated_at)">
                                                        (updated)
                                                    </a-sheet>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="edit_comment && !edit_comment.id || (edit_comment.id && comment.id !== edit_comment.id)">
                                            <p class="md-body-2 u-wfull mb-0 mt-2 grey--text text--darken-2" style="white-space: pre-wrap; line-height: 24px;">{{ comment.content }}</p>
                                            <div class="u-flex align-center mt-2" v-if="canComment && (comment.created_by_id === user_self.id)">
                                                <span class="md-caption u-cursor-pointer body--text text--lighten-3 mr-3" @click="local_edit_comment(comment)">Edit</span>
                                                <span class="md-caption u-cursor-pointer body--text text--lighten-3" v-if="confirm_delete_id !== comment.id" @click="local_confirm_delete(comment.id)">Delete</span>
                                                <a-tooltip bottom v-else>
                                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on" class="md-caption u-cursor-pointer accent--text text--darken-1" @click="local_delete_comment(comment)">Delete</span>
                                                    </template>
                                                    <span>Confirm delete?</span>
                                                </a-tooltip>
                                            </div>
                                        </div>
                                        <div class="" v-if="(edit_comment && edit_comment.id) && (edit_comment.id === comment.id)">
                                            <a-sheet class="u-rounded-corners">
                                                <a-tooltip bottom offset-y content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!comment_error.id">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on" class="c-comment-box--edit-view px-2 u-rounded-corners mt-2" :class="{ 'c-wiggle-short u-border-error': comment_error.id === edit_comment.id, 'u-border': !comment_error.id }">
                                                            <a-textarea
                                                                v-model="edit_comment.content"
                                                                placeholder="Add comment..."
                                                                class="py-2 md-body-2"
                                                                height="54"
                                                                @keyup.ctrl.enter="local_update_comment(edit_comment)"
                                                                dense hide-details solo flat no-resize autofocus
                                                            ></a-textarea>
                                                        </div>
                                                    </template>
                                                    <span v-if="comment_error.text">{{ comment_error.text }}</span>
                                                </a-tooltip>
                                                <a-sheet class="u-flex-center-y pl-3 mt-2">
                                                    <a-sheet @click="local_cancel_comment_edit()" v-ripple width="24" height="24" class="u-rounded-corners-full md-caption u-cursor-pointer u-flex-center body--text text--lighten-3">
                                                        Cancel
                                                    </a-sheet>
                                                    <a-spacer></a-spacer>
                                                    <span :class="[local_edit_comment_length > 5000 ? 'red--text text--darken-2' : 'body--text text--lighten-4', 'md-caption mr-2']">{{ local_edit_comment_length }} / 5000</span>
                                                    <a-sheet width="22" height="22" @click="local_update_comment(edit_comment)" v-ripple class="u-rounded-corners-full px-1 u-cursor-pointer u-flex-center-y">
                                                        <a-progress-circular v-if="loader_comment.edit === edit_comment.id" width="3" size="16" indeterminate color="orange darken-1"></a-progress-circular>
                                                        <a-icon v-else size="18" color="secondary">send</a-icon>
                                                    </a-sheet>
                                                </a-sheet>
                                            </a-sheet>
                                        </div>
                                    </a-sheet>
                                </div>
                            </template>
                            <a-sheet key="no-activity" :min-height="`calc(${canComment ? 492 : 662}px)`" class="transparent u-flex-center md-caption font-italic body--text text--lighten-3 font-weight-medium" v-else>
                                There are no activities recorded to display
                            </a-sheet>
                        </template>
                    </v-slide-y-transition>
                </a-sheet>

                <template v-if="canComment">
                    <a-divider class="grey lighten-2"></a-divider>

                    <!-- Comment box -->
                    <div class="pa-6 pb-4">
                        <a-tooltip bottom offset-y :disabled="comment_error.action_type !== 'store'" content-class="c-tooltip-pointer c-tooltip-pointer--right">
                            <template v-slot:activator="{ on }">
                                <div v-on="on" class="u-rounded-corners u-border-transparent" :class="{ 'c-wiggle-short u-border-error': comment_error.action_type === 'store' }">
                                    <a-sheet class="c-comment-box">
                                        <a-textarea
                                            v-model="local_get_comment"
                                            placeholder="Add comment..."
                                            class="py-2 md-body-2"
                                            height="54"
                                            :disabled="!canComment"
                                            @input="!local_get_comment || (local_get_comment && !local_get_comment.length) ? local_comment_error_reset() : ''"
                                            @keyup.ctrl.enter="local_store_comment"
                                            dense hide-details solo flat no-resize
                                        ></a-textarea>
                                    </a-sheet>
                                    <a-sheet class="u-flex-center-y px-3 pt-1" :class="{ 'pb-2': local_is_external }">
                                        <g-toggle v-if="!local_is_external" custom-bg dense-xl :disabled="!!(parentItem.visibility === 'internal')" :read-only="local_is_external" v-model="check_is_public" class="d-inline-flex align-center" :color="check_is_public ? 'orange darken-2' : 'grey darken-4'">
                                            <span :class="[check_is_public ? 'orange--text text--darken-2' : (!!(parentItem.visibility === 'internal') ? 'grey--text text--lighten-1' : 'grey--text text--darken-3'), 'font-weight-medium md-caption text-uppercase ml-2']">{{ check_is_public ? 'Public' : 'Private' }}</span>
                                        </g-toggle>
                                        <a-spacer></a-spacer>
                                        <a-sheet width="22" height="22" @click="local_store_comment()" v-ripple class="u-rounded-corners-full px-1 u-cursor-pointer u-flex-center-y">
                                            <a-progress-circular v-if="loader_comment.store" width="3" size="16" indeterminate color="orange darken-1"></a-progress-circular>
                                            <a-icon v-else size="18" color="secondary">send</a-icon>
                                        </a-sheet>
                                    </a-sheet>
                                </div>
                            </template>
                            <span>{{ comment_error.text }}</span>
                        </a-tooltip>
                        <div class="u-flex-center-y md-caption body--text text--lighten-4 mt-2">
                            <span :class="[{ 'red--text text--darken-2': local_comment_length > 5000 } ]">{{ local_comment_length }} / 5000</span>
                            <a-spacer></a-spacer>
                            <span class="u-flex-center-y">
                                <span>Press</span>
                                <span class="font-weight-bold mx-1">Ctrl + Enter</span>
                                <span>to comment</span>
                            </span>
                        </div>
                    </div>
                </template>
            </a-sheet>
        </template>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { STagsInlineForm, SDatePicker } from '@/config/config-shared-components'
import SDragOverlay from '@/components/Attachments/SharedDragOverlay.vue'
import { diffFormat, diffHuman } from '@/helpers/helper-date-filter'
import { calculateByMins } from '@/helpers/helper-time-tracker'
import SCommentField from '@/components/Shared/SharedCommentField.vue'
import mixinAttachmentsInternal from '@/mixins/mixin-internal-attachment'
import mixinAttachmentsExternal from '@/mixins/mixin-external-attachment'
import mixinLoader from '@/mixins/mixin-module-loading-setup'
import SharedAttachmentSection from '../Shared/SharedAttachmentSection.vue'

export default {
    name: 'SharedScTcItemView',

    mixins: [ mixinAttachmentsInternal, mixinLoader, mixinAttachmentsExternal ],

    props: {
        editItem: {
            type: Object,
        },
        testcaseList: {
            type: Array,
        },
        successCriteriaList: {
            type: Array,
        },
        module: {
            type: String,
            required: true
        },
        tagType: {
            type: String,
            default: 'usecase_tag'
        },
        parentItem: {
            type: Object,
        },
        parentType: {
            type: String,
        },
        loaders: {
            type: Object
        },
        commentType: {
            type: String,
        },
        showBreadcrumbs: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean,
        },
        canDelete: {
            type: Boolean,
        },
        canComment: {
            type: Boolean,
        },
        titleHeight: {
            type: Number | String,
            default: 'auto'
        },
        charLimitTitle: {
            type: Number,
            default: 1000
        },
        charLimitDesc: {
            type: Number,
            default: 50000
        },
        editorHeight: {
            type: Number,
            default: 300
        },
        editorMinHeight: {
            type: Number | String,
            default: 'auto'
        },
        absoluteFormatter: {
            type: Boolean,
            default: false
        },
        hideTimeTrack: {
            type: Boolean,
            default: false
        },
        hideActivity: {
            type: Boolean,
            default: false
        },
        hideDate: {
            type: Boolean,
            default: false
        },
        hideTags: {
            type: Boolean,
            default: false
        },
        hideAssignee: {
            type: Boolean,
            default: false
        },
        hideMetas: {
            type: Boolean,
            default: false
        },
        hideStatus: {
            type: Boolean,
            default: false
        },
        soloDelete: {
            type: Boolean,
            default: false
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        response: {
            type: Object | Array,
        },
    },

    components: {
        STagsInlineForm,
        SDatePicker,
        SCommentField,
        SDragOverlay,
        SharedAttachmentSection
    },

    data () {
        return {
            timer_update_debounce: null,
            confirm_delete_id: null,
            update_autosaved_at: null,
            show_detail_view_tag_input: false,
            confirm_delete: false,
            check_is_public: false,
            load_desc_section: false,
            comment: '',
            item_title: '',
            item_description: '',
            comment_data: {},
            edit_comment: {},
            comment_error: { id: null, text: null },
            loader_comment: { store: false, edit: '' },

            //Attachments
            filesDraggedOver: false,
            hoveredOnInfo: false,
            FILTER: {
                parent_id: 'filter[parent_id]',
                parent_type: 'filter[parent_type]',
                source_id: 'filter[source_id]',
                source_type: 'filter[source_type]',
                fields: 'fields[attachments]',
                page: 'page'
            },
            attachment_fields: 'id,name,size,extension,order,source_id,source_type,parent_id,parent_type',
            localDroppedFilesList: [],
            localAttachmentFilters: {},
            backupAttachmentList: [],
            localAttachmentTimeout: null,
            refreshDesc: false
        }
    },

    beforeDestroy () {
        this.localRemoveListeners()
    },

    watch: {
        isOpen: {
            handler (val) {
                if (!val) return
                this.confirm_delete = false
                this.confirm_delete_id = null
                this.item_description = ''
                this.local_index()
            },
            immediate: true
        },

        local_item_comments_length (newVal, oldVal) {
            if (newVal > oldVal) this.local_reset_activity_scrollbar()
        }
    },

    computed: {
        local_get_comment: {
            get () {
                return this.comment
            },
            set (val) {
                this.comment = val
                this.$emit('update-comment', val)
            }
        },

        local_comment_list_height () {
            return this.canComment ? 540 : 710
        },

        local_edit_comment_length () {
            return this.edit_comment.content.length
        },

        local_comment_length () {
            return this.comment.length
        },

        local_item_comments_length () {
            return this.editItem && this.editItem.comments && this.editItem.comments.length
        },

        local_item () {
            return this.editItem
        },

        local_is_testcase () {
            return this.module === 'Testcase'
        },

        local_has_time_track () {
            const { estimated_duration_minutes, estimated_duration_text, time_records_sum_duration_minutes } = this.editItem
            return !!(estimated_duration_minutes || estimated_duration_text || time_records_sum_duration_minutes)
        },

        local_tracked_is_greater () {
            return this.editItem && this.editItem.estimated_duration_minutes && (Number(this.editItem.time_records_sum_duration_minutes) > this.editItem.estimated_duration_minutes)
        },

        local_is_external () {
            const value = (this.user_self && this.user_self.scope === 'external') || this.$route.meta.view === 'external'
            if (value) this.check_is_public = true
            return value
        },

        local_comment_item () {
            return this.local_is_external ? this.comment_external_item : this.comment_item
        },

        local_comment_response () {
            return this.local_is_external ? this.comment_external_response : this.comment_response
        },

        localAttachmentsUpdating () {
            return this.mixinIsLoading('attachment-update') || this.mixinIsLoading('attachment-delete')
        },

        localModuleHasAccess () {
            const noAccessModule = []
            return !noAccessModule.includes(this.module)
        },

        localModuleIsTemplate () {
            const templateModules = ['TemplateSuccessCriterion', 'TemplateTestcase']
            return templateModules.includes(this.module)
        },

        ...mapState('Comment', {
            comment_item: 'item',
            comment_response: 'response',
        }),

        ...mapState('CommentExternal', {
            comment_external_item: 'item',
            comment_external_response: 'response',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Tag', {
            tag_list: 'list'
        }),

        ...mapState('Attachment', {
            attachment_list: 'list',
            attachment_local_list: 'local_list',
            attachment_delete_list: 'delete_list',
            attachment_upload_list: 'upload_list',
            attachment_meta: 'meta',
            attachment_response: 'response',
            attachment_delete_response: 'delete_response',
        }),

        ...mapState('AttachmentExternal', {
            attachment_external_list: 'list',
            attachment_external_local_list: 'local_list',
            attachment_external_delete_list: 'delete_list',
            attachment_external_upload_list: 'upload_list',
            attachment_external_meta: 'meta',
            attachment_external_response: 'response',
            attachment_external_delete_response: 'delete_response',
        }),
    },

    methods: {
        local_index () {
            this.item_title = _.cloneDeep(this.editItem.title)
            this.item_description = this.editItem.description_json
            this.load_desc_section = true
            if (!this.local_is_external) this.check_is_public = false

            if (this.localModuleHasAccess) {
                this.localLoadScTcAttachments()
                this.localAddListeners()
            }

            this.local_reset_activity_scrollbar()
            this.local_reset_comment()
        },

        async localLoadScTcAttachments () {
            this.mixinSetLoading('attachment')
            this.localGetAttachmentAction('clear_response')()
            this.localGetAttachmentAction('clear_upload_list')()
            this.localSetAttachmentFilters()
            await this.localGetAttachmentAction('index')({ params: this.localAttachmentFilters })
            this.mixinResetLoading('attachment')
            this.localGetAttachmentMixin('UpdateLocalAttachmentList')({ list: this.localGetAttachmentData('list') })
            this.backupAttachmentList =  _.cloneDeep(this.localGetAttachmentData('list'))
        },

        localSetAttachmentFilters () {
            this.localAttachmentFilters[this.FILTER.parent_id] = this.localModuleIsTemplate ? this.editItem.template_usecase_id :this.editItem.usecase_id
            this.localAttachmentFilters[this.FILTER.parent_type] = this.localModuleIsTemplate ? 'TemplateUsecase' : 'Usecase'
            this.localAttachmentFilters[this.FILTER.source_id] = this.editItem.id
            this.localAttachmentFilters[this.FILTER.source_type] = this.module
            this.localAttachmentFilters[this.FILTER.fields] = this.attachment_fields
            this.localAttachmentFilters[this.FILTER.page] = 1
            this.localAttachmentFilters.count = 5
        },

        localGetAttachmentData (field) {
            const prefix = this.local_is_external ? 'attachment_external_' : 'attachment_'
            return this[prefix + field]
        },

        localGetAttachmentAction (action) {
            const prefix = this.local_is_external ? 'attachment_external_' : 'attachment_'
            return this[prefix + action]
        },

        localGetAttachmentMixin (type) {
            const prefix = this.local_is_external ? 'mixinExternal' : 'mixin'
            return this[prefix + type]
        },

        local_reset_activity_scrollbar () {
            setTimeout(() => {
                if (this.$refs.refActivityScrollbar) this.$refs.refActivityScrollbar.$el.scrollTop = this.$refs.refActivityScrollbar.$el.scrollHeight
            }, 100)
        },

        local_update_title () {
            if (!this.item_title || this.item_title && this.item_title.length > this.charLimitTitle) return this.local_clear_debounce_timer()
            this.editItem.title = this.item_title
            this.local_update_debounced()
        },

        local_update_description (content) {
            this.editItem.description_json = content
            this.local_update_debounced()
        },

        async local_update_debounced () {
            this.local_clear_debounce_timer()
            this.timer_update_debounce = setTimeout(async () => this.local_item_update(), 500)
        },

        local_item_update () {
            this.local_set_autosave()
            let clonedItem = _.cloneDeep(this.editItem)
            Object.assign(clonedItem, { is_autosave: 1 })
            this.$emit('update', clonedItem)
            if (this.$status(this.response)) {
                this.local_set_autosave()
                this.local_clear_debounce_timer()
            }
        },

        local_set_autosave () {
            this.update_autosaved_at = moment().format('YYYY-MM-DD HH:mm:ss')
            this.editItem.updated_at = moment()
            this.editItem.updated_by_id = this.user_self.id
            this.$set(this.editItem, 'updated_by', this.user_self)
            setTimeout(() => this.update_autosaved_at = null, 5000)
        },

        async local_clear_debounce_timer (saved = false) {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
            }
        },

        local_date_update ({ item }) {
            this.editItem = item
            this.$emit('update', this.editItem)
        },

        local_get_filtered_tags (item) {
            return _.differenceBy(this.tag_list, item.tags, 'id')
        },

        local_delete_item (id) {
            this.$emit('destroy', id, this.parentItem.id)
        },

        // Tags CRUD
        async local_associate_tag_destroy (data, item) {
            this.local_remove_tag(data, item)
            await this.association_destroy(data.association)
        },

        async local_remove_tag (tag, item) {
            const index = item.tags.findIndex(item => item.id === tag.id)
            if (index !== -1) item.tags.splice(index, 1)
        },

        local_load_tags_list () {
            this.tag_index({ 'filter[type]': 'usecase_tag', 'fields[tags]': 'id,label,color,type' })
        },
        // Tags CRUD ----- END

        // Comments CRUD
        local_edit_comment (comment) {
            this.local_comment_error_reset()
            this.edit_comment = _.cloneDeep(comment)
            this.local_reset_comment()
        },

        local_reset_comment () {
            this.comment = ''
        },

        async local_update_comment (comment) {
            if (this.local_comment_has_error(comment, 'edit')) return
            this.local_comment_error_reset()
            this.loader_comment.edit = comment.id
            const item = this.editItem.comments.find(data => data.id === comment.id)
            const oldComment = _.cloneDeep(item.content)
            item.content = comment.content
            if (item.content !== oldComment) item.updated_at = moment()
            await this.local_comment_edit(comment)
            if (this.local_comment_response.status !== 'success') {
                this.loader_comment.edit = ''
                item.content = oldComment
                return
            }
            this.loader_comment.edit = ''
            this.$emit('comment-update')
            this.local_cancel_comment_edit()
        },

        async local_store_comment () {
            this.loader_comment.store = true
            await this.local_set_comment_vars()
            if (this.local_comment_has_error(this.comment_data, 'store')) return this.loader_comment.store = false
            await this.local_add_comment(this.comment_data)
            if (this.local_comment_response.status !== 'success') return this.loader_comment.store = false
            this.loader_comment.store = false
            this.local_add_comment_to_item(this.comment_data)
            this.local_update_parent_comment_count({ increase: true })
            setTimeout(() => {
                this.$emit('comment-store')
                this.local_comment_clear()
            }, 100)
        },

        local_comment_has_error (comment, action_type) {
            if (!comment || (comment && !comment.content.trim())) this.comment_error = { id: action_type === 'store' ? null : comment.id, text: 'Required Field', action_type }
            else if (comment && comment.content.length > 5000) this.comment_error = { id: action_type === 'store' ? null : comment.id, text: 'Max. of 5000 characters', action_type }
            else this.local_comment_error_reset()
            return !!(this.comment_error.id) || (this.comment_error.action_type === 'store')
        },

        local_comment_error_reset () {
            this.comment_error = { id: null, text: null, action_type: null }
        },

        local_comment_clear () {
            this.comment_clear_item()
            this.comment_external_clear_item()
            this.local_comment_error_reset()
        },

        local_update_parent_comment_count ({ increase }) {
            const clonedParent = _.cloneDeep(this.parentItem)
            if (!(clonedParent.aggregated_comments_count >= 0)) Object.assign(clonedParent, { aggregated_comments_count: 0 })
            const count = increase ? clonedParent.aggregated_comments_count + 1 : clonedParent.aggregated_comments_count - 1
            this.$set(clonedParent, 'aggregated_comments_count', count < 0 ? 0 : count)

            if (!this.local_is_external) return this.usecase_update_list_item({ id: clonedParent.id, item: clonedParent })
            this.usecase_external_update_list_item({ id: clonedParent.id, item: clonedParent })
        },

        local_add_comment_to_item (comment) {
            if (!this.editItem.comments) Object.assign(this.editItem, { comments: [] })
            if (!(this.editItem.comments_count >= 0)) Object.assign(this.editItem, { comments_count: 0 })

            this.editItem.comments.push(comment)
            this.$set(this.editItem, 'comments_count', this.editItem.comments_count + 1)
            this.local_update_item()
            this.check_is_public = this.local_is_external ? true : false
            this.comment = ''
            this.comment_data = {}
        },

        async local_set_comment_vars (id) {
            this.comment_data.commentable_type = this.module
            this.comment_data.visibility = (this.check_is_public || this.local_is_external) ? 'external' : 'internal'
            this.comment_data.commentable_id = this.editItem.id
            this.comment_data.content = this.local_get_comment
            this.comment_data.created_by = this.user_self
            this.comment_data.created_by_id = this.user_self.id
            this.comment_data.created_at = new Date()
        },

        async local_delete_comment (comment) {
            const index = this.editItem.comments.findIndex(item => item.id === comment.id)
            if (index !== -1) this.editItem.comments.splice(index, 1)
            this.$set(this.editItem, 'comments_count', this.editItem.comments_count - 1)
            this.local_update_item()
            await this.local_comment_delete({ id: comment.id })
            if (this.local_comment_response && this.local_comment_response.status !== 'success') return
            this.$notify('success', 'Comment deleted successfully!')
            this.$emit('comment-delete')
        },

        async local_add_comment (comment) {
            if (this.local_is_external) {
                await this.comment_external_store(comment)
                return
            }
            await this.comment_store(comment)
        },

        async local_comment_edit (comment) {
            if (this.local_is_external) return await this.comment_external_update(comment)
            await this.comment_update(comment)
        },

        local_confirm_delete (comment_id) {
            this.confirm_delete_id = comment_id
            setTimeout(() => this.confirm_delete_id = null, 3000)
        },

        async local_comment_delete (params) {
            this.local_update_parent_comment_count({ increase: false })
            if (this.local_is_external) return await this.comment_external_destroy(params)
            else await this.comment_destroy(params)
        },

        local_cancel_comment_edit () {
            this.edit_comment = {}
        },
        // Commenrs CRUD --- END

        local_update_item () {
            if (['Testcase', 'TemplateTestcase'].includes(this.module)) {
                const index = this.testcaseList.findIndex(({ id }) => id === this.editItem.id)
                this.$set(this.testcaseList, index, this.editItem)
                this.local_reset_activity_scrollbar()
                return
            }
            const index = this.successCriteriaList.findIndex(({ id }) => id === this.editItem.id)
            this.$set(this.successCriteriaList, index, this.editItem)
            this.local_reset_activity_scrollbar()
        },

        // Extras
        local_emit_close () {
            this.$emit('close', true)
        },

        local_date_diff (updated, created) {
            return moment(created).diff(moment(updated), 'seconds') > 5
        },

        local_get_delete_confirm () {
            this.confirm_delete = true
            setTimeout(() => this.confirm_delete = false, 3000)
        },

        local_check_due_date ({ due_date, status }) {
            if (status) return moment(due_date).isBefore() && status.status === 'Open'
        },

        local_get_time_text (value) {
            const { durationText } = calculateByMins(value)
            return durationText
        },

        local_date_output_format (date, type = null) {
            return diffFormat(date, type)
        },

        local_get_updated_at (date) {
            return diffHuman(date)
        },

        local_get_updated_data (item, type) {
            if (!item) return
            const hasUpdated = item.updated_at ?? null
            if (hasUpdated) {
                if (type === 'type') return 'Updated'
                if (type === 'user' && item.updated_by) return item.updated_by.name
            }
            if (type === 'type') return 'Created'
            if (type === 'user' && item.created_by) return item.created_by.name
        },

        // Attachments - CRUD

        localAddListeners () {
            this.localGetAttachmentMixin('AttachmentListeners')('add')
        },

        localRemoveListeners () {
            this.localGetAttachmentMixin('AttachmentListeners')('remove')
        },

        localHandleDragEnter (e) {
            this.filesDraggedOver = true
        },

        localHandleLeave (e) {
            if (this.hoveredOnInfo) return
            this.filesDraggedOver = false
        },

        localHandleDrop (e) {
            if (this.localAttachmentsUpdating || this.pageLoading) return
            const droppedFiles = Array.from(e.dataTransfer.files)
            if (_.size(droppedFiles) > 5) return this.localValidateAttachmentCount()
            this.localDroppedFilesList = _.cloneDeep(droppedFiles)
            this.filesDraggedOver = false
        },

        localValidateAttachmentCount () {
            this.$notify('warning', 'Sorry, you can only upload up to 5 files at a time')
            this.filesDraggedOver = false
        },

        localRemoveAttachmentItem (id) {
            this.localGetAttachmentMixin('RemoveAttachmentItem')(id)
        },

        localDeleteAttachmentItem (id) {
            this.localGetAttachmentMixin('DeleteAttachmentItem')(id)
            this.localUpdateScTcAttachment()
        },

        async localUpdateScTcAttachment () {
            this.localGetAttachmentAction('clear_response')()
            this.localGetAttachmentMixin('RemoveInvalidFiles')()
            await this.localBulkDeleteAttachment()
            await this.localUploadAttachment()
            this.mixinResetLoading('attachment-update')
            this.localRefreshDesc()
            this.$emit('attachmentLoading', false)
        },

        async localUploadAttachment () {
            if (!_.size(this.localGetAttachmentData('upload_list'))) return
            const formData = new FormData();

            const parent_id = this.localModuleIsTemplate ? this.editItem.template_usecase_id : this.editItem.usecase_id
            const parent_type = this.localModuleIsTemplate ? 'TemplateUsecase' : 'Usecase'

            formData.append('parent_id', parent_id)
            formData.append('parent_type', parent_type)
            formData.append('source_id', this.editItem.id)
            formData.append('source_type', this.module)
            formData.append('order', -1)
            this.localGetAttachmentData('upload_list').forEach(file => {
                formData.append('files[]', file)
                formData.append('ids[]', file.id)
            })
            await this.localGetAttachmentAction('upload')(formData)

            if (!this.$status(this.localGetAttachmentData('response'))) return this.localGetAttachmentMixin('HandleAttachmentErrors')()
            this.localShowScTcAttachments()
            this.localGetAttachmentAction('clear_upload_list')()
        },

        async localBulkDeleteAttachment () {
            if (!_.size(this.localGetAttachmentData('delete_list'))) return
            this.mixinSetLoading('attachment-delete')
            await this.localGetAttachmentAction('bulk_destroy')({ids: this.localGetAttachmentData('delete_list')})
            if (this.$status(this.localGetAttachmentData('delete_response'))) {
                if (!_.size(this.localGetAttachmentData('upload_list'))) this.localShowScTcAttachments()
                if (!_.size(this.localGetAttachmentData('local_list')) && !_.size(this.localGetAttachmentData('upload_list'))) this.localLoadScTcAttachments()
                this.localGetAttachmentAction('clear_delete_list')()
            }
            this.mixinResetLoading('attachment-delete')
        },

        async localShowScTcAttachments () {
            this.localAttachmentFilters.count = _.size(this.localGetAttachmentData('local_list'))
            this.localAttachmentFilters.page = 1
            await this.localGetAttachmentAction('index')({ mode: 'show', params: this.localAttachmentFilters})
            this.localGetAttachmentMixin('UpdateLocalAttachmentList')({ list: this.localGetAttachmentData('list')})
            this.backupAttachmentList = _.cloneDeep(this.localGetAttachmentData('list'))
            this.localUpdateAttachmentCount({ value: this.localGetAttachmentData('meta').total })
        },

        localUpdateAttachmentTransfer (params) {
            clearTimeout(this.localAttachmentTimeout)
            this.localGetAttachmentAction('clear_response')()
            const { list, transferFiles } = params || {}
            const totalUploadList = !!_.size(this.localGetAttachmentData('upload_list')) ? [...transferFiles, ...this.localGetAttachmentData('upload_list')] : [...transferFiles]
            this.localGetAttachmentMixin('PreserveListChanges')({ list: list, type: 'drop_transfer' })
            this.localGetAttachmentAction('update_upload_list')(totalUploadList)
            this.$emit('attachmentLoading', true)
            this.mixinSetLoading('attachment-update')
            this.localRefreshDesc()
            this.localAttachmentTimeout = setTimeout(() => this.localUpdateScTcAttachment(), 2000)
        },

        async localLoadMoreAttachments () {
            if (this.localGetAttachmentData('meta').total === _.size(this.localGetAttachmentData('list'))) return

            this.mixinSetLoading('attachment')

            const defaultCount = 5
            const isListShortOfItem = _.size(this.localGetAttachmentData('list'))%5 === 0
            const loadType = isListShortOfItem ? 'load_more' : 'index'
            this.localAttachmentFilters.count = isListShortOfItem ? defaultCount : this.localGetAttachmentData('meta').current_page*defaultCount
            this.localAttachmentFilters[this.FILTER.page] = isListShortOfItem ? this.localGetAttachmentData('meta').current_page+1 : 1
            await this.localGetAttachmentAction('index')({ mode: loadType, params: this.localAttachmentFilters })

            this.mixinResetLoading('attachment')
            this.localGetAttachmentMixin('PreserveListChanges')({ list: this.localGetAttachmentData('list'), type: 'load_more' })
            this.backupAttachmentList =  _.cloneDeep(this.localGetAttachmentData('list'))
        },

        localClearAttachmentUpdate () {
            this.localGetAttachmentMixin('UpdateLocalAttachmentList')({ list: this.backupAttachmentList })
            this.localGetAttachmentMixin('ClearAttachmentUpdate')()
        },

        localUpdateAttachmentCount ({ value }) {
            this.$set(this.editItem, 'attachments_count', value)
            this.local_update_item()
        },

        localRefreshDesc () {
            this.refreshDesc = true
            setTimeout(() => {
                this.refreshDesc = false
            }, 100);
        },

        ...mapActions('Tag', {
            tag_index: 'index'
        }),

        ...mapActions('Association', {
            association_destroy: 'destroy'
        }),

        ...mapActions('Usecase', {
            usecase_update_list_item: 'update_list_item',
        }),

        ...mapActions('UsecaseExternal', {
            usecase_external_update_list_item: 'update_list_item',
        }),

        ...mapActions('Comment', {
            comment_store: 'store',
            comment_update: 'update',
            comment_destroy: 'destroy',
            comment_clear_item: 'clear_item',
        }),

        ...mapActions('CommentExternal', {
            comment_external_store: 'store',
            comment_external_update: 'update',
            comment_external_destroy: 'destroy',
            comment_external_clear_item: 'clear_item',
        }),

        ...mapActions('Attachment', {
            attachment_index: 'index',
            attachment_upload: 'upload',
            attachment_update_upload_list: 'update_upload_list',
            attachment_remove_upload_item: 'remove_upload_list_item',
            attachment_bulk_destroy: 'bulk_destroy',
            attachment_clear_upload_list: 'clear_upload_list',
            attachment_clear_delete_list: 'clear_delete_list',
            attachment_clear_response: 'clear_response',
        }),

        ...mapActions('AttachmentExternal', {
            attachment_external_index: 'index',
            attachment_external_upload: 'upload',
            attachment_external_update_upload_list: 'update_upload_list',
            attachment_external_remove_upload_item: 'remove_upload_list_item',
            attachment_external_bulk_destroy: 'bulk_destroy',
            attachment_external_clear_upload_list: 'clear_upload_list',
            attachment_external_clear_delete_list: 'clear_delete_list',
            attachment_external_clear_response: 'clear_response',
        }),
    }
}
</script>

<style lang="scss" scoped>
.c-crumb-parent {
    &:hover {
        color: #0091ea !important;
        caret-color: #0091ea !important;
    }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.tile)?_c('span',_vm._b({staticClass:"d-inline-flex align-center u-rounded-corners-full u-cursor-pointer",class:{ 'my-1': !_vm.dense, 'mr-2': !_vm.noMargin },style:([
        {
            backgroundColor: _vm.$color(_vm.tag.color, 'color_bg'),
            color: _vm.$color(_vm.tag.color, 'color_text'),
            fontSize: _vm.dataFontSize + 'px',
            height: _vm.height + 'px'
        },
        _vm.noPadding ? '' : { padding: '2px 8px' }
    ]),attrs:{"title":_vm.methodGetLabel(_vm.tag) && _vm.methodGetLabel(_vm.tag).length >= _vm.textRange ? _vm.methodGetLabel(_vm.tag) : ''}},'span',_vm.$attrs,false),[_vm._v(" "+_vm._s(_vm._f("truncateText")(_vm.methodGetLabel(_vm.tag),_vm.textRange))+" "),(_vm.canUpdate && !_vm.hideClearIcon)?_c('a-icon',{staticClass:"c-icon u-rounded-corners-full u-icon-nudge ml-1",staticStyle:{"top":"0px"},attrs:{"data-tag-type":"remove-tag","size":"12","color":_vm.$color(_vm.tag.color, 'color_text')},on:{"click":function($event){$event.preventDefault();return _vm.$emit('destroy', _vm.tag, _vm.type)}}},[_vm._v("clear")]):_vm._e()],1):_c('span',_vm._b({staticClass:"u-rounded-corners md-body-2 d-inline-flex align-center flex-wrap",class:[{ 'mr-3 mb-3': !_vm.noMargin, 'pa-2': !_vm.noPadding }],style:({ backgroundColor: _vm.$color(_vm.tag.color, 'color_bg'), color: _vm.$color(_vm.tag.color, 'color_text'), transition: '0.4s box-shadow', boxShadow: '0px 0px 0 1px' }),attrs:{"title":_vm.methodGetLabel(_vm.tag) && _vm.methodGetLabel(_vm.tag).length >= _vm.textRange ? _vm.methodGetLabel(_vm.tag) : ''}},'span',_vm.$attrs,false),[_c('span',[_vm._v(_vm._s(_vm._f("truncateText")(_vm.methodGetLabel(_vm.tag),_vm.textRange)))]),(_vm.canUpdate && !_vm.hideClearIcon)?_c('a-icon',{class:['ml-2 u-rounded-corners u-cursor-pointer', _vm.$color(_vm.tag.color, 'name') === 'black' ? 'c-icon--dark' : 'c-icon'],attrs:{"data-tag-type":"remove-tag","color":_vm.$color(_vm.tag.color, 'color_text'),"size":"16"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('destroy', _vm.tag, _vm.type)}}},[_vm._v("clear")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
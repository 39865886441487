<template>
    <div>
         <a-container container--fluid grid-list-xl pa-0>
            <a-layout wrap align-center>
                <a-flex shrink class="pb-0">
                    <a-btn color="grey darken-2" height="32" class="ma-0 px-3 py-1 mb-2 u-border" small text :to="{name: 'projects-tasks'}" :exact="true">
                        <a-icon left class="mr-2" size="20">arrow_back</a-icon>
                        Back to tasks
                    </a-btn>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink class="pb-0">
                    <a-btn color="grey darken-2" height="32" class="green--text text--darken-2 ma-0 mb-2 px-3 py-1 u-border" small text @click="local_format()">
                        <a-icon left size="16" class="mr-2">cloud_download</a-icon>
                        Download Format
                    </a-btn>
                </a-flex>
            </a-layout>
            <a-layout wrap align-start>
                <a-flex xs12 class="pb-0">
                    <h2 class="md-heading-5 mb-1">Import Milestones and Tasks from CSV</h2>
                    <p class="md-body-2 grey--text text--darken-2 mb-0">You can import multiple milestones and tasks through a CSV file. Success will process the uploaded file and import the data into temporary queue. Please make sure you have data columns in the right order as specified in sample format. After the upload is complete, you can review the data that system parsed and import into the project.</p>
                </a-flex>
                <a-flex shrink mt-2>
                    <a-btn height="32" class="blue--text text--darken-2 u-border elevation-0 mx-0 px-3" small text @click="local_initiate()" :disabled="loading_file">
                        <a-icon left size="16" class="mr-2">arrow_upward</a-icon> Upload New File
                    </a-btn>
                    <input type="file" ref="csv_file" name="csv_file" class="d-none" @change="local_store()" accept=".csv">
                </a-flex>
                <a-flex shrink mt-2 v-if="task_upload_list && task_upload_list.length > 0">
                    <a-btn height="32" class="grey--text text--darken-2 u-border mx-0 px-3" text small @click="local_destroy(task_upload_list[0].project_id)">
                        <a-icon left size="16" class="mr-2">clear</a-icon> Clear Uploaded File
                    </a-btn>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink v-if="task_upload_list && task_upload_list.length > 0">
                    <a-menu bottom offset-y class="mr-2" :disabled="loading_save" v-if="task_upload_meta.acceptable_warning && task_upload_meta.existing_valid > 0">
                        <template v-slot:activator="{ on }">
                            <a-btn v-on="on" height="32" class="white--text mx-0 px-3 green darken-2" small text :disabled="loading_save" :loading="loading_save">
                                Save tasks
                                <a-icon left size="16" class="ma-0 pl-1">keyboard_arrow_down</a-icon>
                            </a-btn>
                        </template>
                        <a-list class="u-list-condensed">
                            <a-list-item @click="local_save()" :disabled="!!(task_upload_meta.new_valid <= 0)">
                                <a-list-item-title>
                                    <span class="md-body-2">Save Only New Records  ({{ task_upload_meta.new_valid }}) </span>
                                </a-list-item-title>
                            </a-list-item>
                            <a-list-item @click="local_save('overwrite')">
                                <a-list-item-title>
                                    <span class="md-body-2">Save and Overwrite Records ({{ task_upload_meta.valid }})</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                    <a-btn height="32" v-else class="white--text mx-0 px-3 green darken-2" text small @click="local_save()" :disabled="loading_save" :loading="loading_save">
                        Save tasks
                    </a-btn>
                </a-flex>
            </a-layout>
            <a-layout align-start>
                <a-flex xs12>
                    <a-card v-if="loading_file" flat class="blue darken-1 u-rounded-corners u-overflow-hidden mb-3">
                        <a-card-text class="pa-3">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-progress-circular indeterminate color="white" size="20" width="2"></a-progress-circular>
                                </a-flex>
                                <a-flex class="pl-0">
                                    <span class="md-body-2 white--text">File upload is currently under progress.</span>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card v-if="task_upload_response.status === 'error'" flat class="red darken-2 u-rounded-corners u-overflow-hidden mb-3">
                        <a-card-text class="pa-3">
                            <a-layout align-center>
                                <a-flex>
                                    <a-icon color="white" class="u-icon-nudge mr-2" size="20">error</a-icon> <span class="md-body-2 white--text">{{ task_upload_response.text }}</span>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card v-if="task_upload_list && task_upload_list.length > 0" class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden mb-3 pa-2">
                        <a-layout align-center class="pa-1">
                            <a-flex shrink class="u-cursor-pointer mr-2 u-rounded-corners-lg" :class="{'grey lighten-3': local_task_upload_filters.length === 0 }" @click="local_task_upload_filter('Processed', true)">
                                <p class="md-caption-2 primary--text text--darken-3 mb-0">
                                    <span class="mr-1"><a-icon color="primary darken-1" class="u-icon-nudge" style="top: -2px;" size="18">check_circle</a-icon></span>
                                    {{ task_upload_meta.processed }} Processed
                                </p>
                            </a-flex>
                            <a-flex shrink class="u-cursor-pointer mr-2 u-rounded-corners-lg" :class="{'grey lighten-3': local_is_filterd_task_upload_list('valid-all') }" @click="local_task_upload_filter('valid-all')">
                                <p class="md-caption-2 mb-0" :class="{'grey--text text--darken-2' : !local_is_filterd_task_upload_list('valid-all'), 'primary--text text--darken-3': local_is_filterd_task_upload_list('valid-all') }">
                                    <span class="mr-1"><a-icon color="green darken-2" class="u-icon-nudge" style="top: -2px;" size="18">done_all</a-icon></span>
                                    {{ task_upload_meta.valid }} Valid
                                </p>
                            </a-flex>
                            <a-flex shrink class="u-cursor-pointer mr-2 u-rounded-corners-lg" :class="{'grey lighten-3': local_is_filterd_task_upload_list('valid') }" @click="local_task_upload_filter('valid')">
                                <p class="md-caption-2 mb-0" :class="{'grey--text text--darken-2' : !local_is_filterd_task_upload_list('valid'), 'primary--text text--darken-3': local_is_filterd_task_upload_list('valid') }">
                                    <span class="mr-1"><a-icon color="green darken-2" class="u-icon-nudge" style="top: -2px;" size="18">check_circle</a-icon></span>
                                    {{ task_upload_meta.new_valid }} New Records
                                </p>
                            </a-flex>
                            <a-flex shrink class="u-cursor-pointer mr-2 u-rounded-corners-lg" :class="{'grey lighten-3': local_is_filterd_task_upload_list('existing-valid') }" @click="local_task_upload_filter('existing-valid')">
                                <p class="md-caption-2 mb-0" :class="{'grey--text text--darken-2' : !local_is_filterd_task_upload_list('existing-valid'), 'primary--text text--darken-3': local_is_filterd_task_upload_list('existing-valid') }">
                                    <span class="mr-1"><a-icon color="blue darken-1" class="u-icon-nudge" style="top: -2px;" size="18">check_circle</a-icon></span>
                                    {{ task_upload_meta.existing_valid }} Existing Records
                                </p>
                            </a-flex>
                            <a-flex shrink class="u-cursor-pointer mr-2 u-rounded-corners-lg" :class="{'grey lighten-3': local_is_filterd_task_upload_list('ignore') }" @click="local_task_upload_filter('ignore')">
                                <p class="md-caption-2 mb-0" :class="{'grey--text text--darken-2' : !local_is_filterd_task_upload_list('ignore'), 'primary--text text--darken-3': local_is_filterd_task_upload_list('ignore') }">
                                    <span class="mr-1"><a-icon color="yellow darken-2" class="u-icon-nudge" style="top: -1px;" size="19">error</a-icon></span>
                                    {{ task_upload_meta.invalid }} Invalid
                                </p>
                            </a-flex>
                        </a-layout>
                    </a-card>

                    <a-card class="neutral pa-0 ma-0 u-elevation-custom-1 u-rounded-corners" v-if="local_render && local_render.length > 0" style="overflow: auto">
                        <table class="csv-table u-rounded-corners">
                            <thead>
                                <tr class="csv-table-row-header">
                                    <th>&nbsp;</th>
                                    <th class="csv-table-row-upload-status">&bull;</th>
                                    <th>A</th>
                                    <th>B</th>
                                    <th>C</th>
                                    <th>D</th>
                                    <th>E</th>
                                    <th>F</th>
                                    <th>G</th>
                                    <th>H</th>
                                    <th>I</th>
                                </tr>
                                <tr class="csv-table-row-title">
                                    <th class="csv-table-cell-number" style="min-width: 48px;">1</th>
                                    <th class="text-center csv-table-row-upload-status"><a-icon color="grey darken-2" size="16">radio_button_unchecked</a-icon></th>
                                    <th style="min-width: 200px;">Milestone Title</th>
                                    <th style="min-width: 200px;">Task Title</th>
                                    <th style="min-width: 200px;">Description</th>
                                    <th style="min-width: 200px;">Assignee</th>
                                    <th style="min-width: 200px;">Start Date</th>
                                    <th style="min-width: 200px;">Due Date</th>
                                    <th style="min-width: 200px;">Visibility</th>
                                    <th style="min-width: 200px;">Priority</th>
                                    <th style="min-width: 250px;">Estimated Duration in Minutes</th>
                                </tr>
                            </thead>
                             <tbody>
                                <tr v-for="item in local_render" :key="item.id" :class="item.upload_status !== 'existing-valid' && !item.error && item.warning ? 'csv-table-row-data csv-table-row-status-warning' : 'csv-table-row-data ' + 'csv-table-row-status-' + item.upload_status">
                                    <td class="csv-table-cell-number" width="50px" style="text-align: center;">{{ item.line_no }}</td>
                                    <td class="text-center csv-table-row-upload-status" style="min-width: 48px;">
                                        <a-tooltip bottom left>
                                            <template v-slot:activator="{ on }">
                                                <a-icon v-on="on" v-if="item.upload_status === 'ignore'" color="yellow darken-2" class="u-cursor-pointer" size="16">error</a-icon>
                                                <a-icon v-on="on" v-if="item.upload_status === 'skipped'" color="yellow darken-2" class="u-cursor-pointer" size="16">warning</a-icon>
                                                <a-icon v-on="on" v-if="item.upload_status === 'valid'" color="green darken-2" class="u-cursor-pointer" size="16">check_circle</a-icon>
                                                <a-icon v-on="on" v-if="item.upload_status === 'existing-valid'" color="blue darken-1" class="u-cursor-pointer" size="16">check_circle</a-icon>
                                            </template>
                                            <span v-if="item.upload_status === 'ignore'">
                                                This row of data has some issues. Mouse over on the highlighted cells for more information.
                                            </span>
                                            <span v-if="item.upload_status === 'skipped'">
                                                This row will be skipped due to lack of data.
                                            </span>
                                            <span v-if="item.upload_status === 'valid' || item.upload_status === 'existing-valid'">
                                                All good.
                                            </span>
                                        </a-tooltip>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['Milestone Title']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.milestone_title, '25', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Milestone Title'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.milestone_title">{{ item.milestone_title }}</p>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['Task Title']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.task_title, '20', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Task Title'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td :class="[ item.upload_status === 'existing-valid' ? 'csv-table-cell-error' : 'csv-table-cell-existing-value' ]" v-else-if="(item.warning && item.warning['Task Title'])">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.task_title, '20', '...') }}</p>
                                            </template>
                                            <span>{{ item.warning['Task Title'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.task_title">{{ item.task_title }}</p>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['Description']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.description, '27', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Description'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.description">{{ item.description }}</p>
                                    </td>
                                    <td :class="{ 'csv-table-cell-ignore': item.ignored && item.ignored['Email'] && item.ignored['Email'][0].length > 0 }" v-if="item.warning && item.warning['Email']">
                                        <a-menu bottom offset-y v-if="item.assignee" transition="slide-y-transition" open-on-hover :close-on-content-click="false" min-width="360" max-width="360" max-height="600">
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0 text-truncate">&nbsp;{{ local_text_truncate(item.assignee, '27', '...') }}</p>
                                            </template>
                                            <a-sheet class="u-elevation-custom-1 u-rounded-corners">
                                                <div class="py-4 px-5 u-flex align-center" style="position: sticky; top: 0; left: 0; z-index: 10">
                                                    <a-avatar size="24" class="mr-2 u-icon-nudge" style="top: 0;">
                                                        <a-icon color="primary darken-1" size="23">info</a-icon>
                                                    </a-avatar>
                                                    <h2 class="md-subtitle-1 font-weight-bold primary--text text--darken-2">
                                                        Assignee
                                                    </h2>
                                                </div>
                                                <a-divider class="grey lighten-3"></a-divider>
                                                <div class="pa-5 align-center" v-if="item.assignee_availablity && item.assignee_availablity['Valid Assignee']">
                                                    <div class="u-flex pb-4">
                                                        <a-avatar size="24" color="indigo lighten-5" class="mr-2 u-icon-nudge" style="top: -1px;">
                                                            <p class="indigo--text text--darken-2 ma-0 font-weight-bold md-caption">{{ item.assignee_availablity['Valid Assignee'].length }}</p>
                                                        </a-avatar>
                                                        <h6 class="md-subtitle-2 text-uppercase ml-2 font-weight-bold indigo--text text--darken-2">
                                                            Valid Assignees
                                                        </h6>
                                                    </div>
                                                    <div class="align-center" v-if="item.assignee_availablity['Valid Assignee'].length !== 0">
                                                        <div v-for="user in item.assignee_availablity['Valid Assignee']" :key="user.label" class="d-inline-flex align-center mr-2 u-rounded-corners-full u-cursor-pointer grey lighten-4 grey--text text--darken-2 font-weight-medium" style="fontSize:12px; padding: 2px 8px">
                                                            {{ user.label }}
                                                        </div>
                                                    </div>
                                                    <div class="align-center" v-if="item.assignee_availablity['Valid Assignee'].length === 0">
                                                        <h3 class="md-body-2 grey--text mb-0 ml-1 text-capitalize">No valid assignee found</h3>
                                                    </div>
                                                </div>
                                                <a-divider class="grey lighten-3"></a-divider>
                                                <div class="pa-5 align-center" v-if="item.assignee_availablity && item.assignee_availablity['Invalid Assignee']">
                                                    <div class="u-flex pb-4">
                                                        <a-avatar size="24" color="amber lighten-5" class="mr-2 u-icon-nudge" style="top: -1px;">
                                                            <p class="amber--text text--darken-2 ma-0 font-weight-bold md-caption">{{ item.assignee_availablity['Invalid Assignee'].length }}</p>
                                                        </a-avatar>
                                                        <h6 class="md-subtitle-2 font-weight-bold text-uppercase ml-2 amber--text text--darken-2">
                                                            Invalid Assignees
                                                        </h6>
                                                    </div>
                                                    <div class="align-center" v-if="item.assignee_availablity['Invalid Assignee'].length !== 0">
                                                        <div v-for="user in item.assignee_availablity['Invalid Assignee']" :key="user.label" class="d-inline-flex align-center mr-2 u-rounded-corners-full u-cursor-pointer grey lighten-4 grey--text text--darken-2 font-weight-medium" style="fontSize:12px; padding: 2px 8px">
                                                            {{ user.label }}
                                                        </div>
                                                    </div>
                                                    <div class="align-center" v-if="item.assignee_availablity['Invalid Assignee'].length === 0">
                                                        <h3 class="md-body-2 grey--text mb-0 ml-1 text-capitalize">No invalid assignee found</h3>
                                                    </div>
                                                </div>
                                                <div class="px-5 pb-5 align-center" v-if="item.assignee_availablity && item.assignee_availablity['Invalid Assignee'] && item.assignee_availablity['Invalid Assignee'].length !== 0">
                                                    <a-alert v-if="item.warning && !item.warning['Tags'] && item.assignee_availablity && item.assignee_availablity['Invalid Assignee'] && item.assignee_availablity['Invalid Assignee'].length !== 0" color="amber lighten-5 u-alert-no-border" elevation="0" class="px-4 py-3 my-0">
                                                        <p class="md-subtitle-2 mb-0 amber--text text--darken-2 align-center">
                                                            Invalid and email addresses that does not exist in the system will be ignored.
                                                        </p>
                                                    </a-alert>
                                                </div>
                                            </a-sheet>
                                        </a-menu>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.assignee">{{ item.assignee }}</p>
                                    </td>
                                    <td :class="{ 'csv-table-cell-ignore': item.ignored && item.ignored['Start Date'] }" v-if="item.warning && item.warning['Start Date']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.start_date, '15', '...') }}</p>
                                            </template>
                                            <span>{{ item.warning['Start Date'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate">{{ item.start_date }}</p>
                                    </td>
                                    <td :class="{ 'csv-table-cell-ignore': item.ignored && item.ignored['Due Date'] }" v-if="item.warning && item.warning['Due Date']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.due_date, '15', '...') }}</p>
                                            </template>
                                            <span>{{ item.warning['Due Date'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate">{{ item.due_date }}</p>
                                    </td>
                                    <td :class="{ 'csv-table-cell-ignore': item.ignored && item.ignored['Visibility'] }" v-if="item.warning && item.warning['Visibility']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.visibility, '18', '...') }}</p>
                                            </template>
                                            <span>{{ item.warning['Visibility'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0" >{{ item.visibility }}</p>
                                    </td>
                                    <td :class="{ 'csv-table-cell-ignore': item.ignored && item.ignored['Priority'] }" v-if="item.warning && item.warning['Priority']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.priority, '20', '...') }}</p>
                                            </template>
                                            <span>{{ item.warning['Priority'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.priority">{{ item.priority }}</p>
                                    </td>
                                    <td :class="{ 'csv-table-cell-ignore': item.ignored && item.ignored['Estimated Duration in Minutes'] }" v-if="item.warning && item.warning['Estimated Duration in Minutes']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.estimated_duration_minutes, '20', '...') }}</p>
                                            </template>
                                            <span>{{ item.warning['Estimated Duration in Minutes'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.estimated_duration_minutes">{{ item.estimated_duration_minutes }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
    data() {
        return {
            loading_file: false,
            loading_save: false,
            local_task_upload_filters: []
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        local_render() {
            if (this.local_task_upload_filters.length) {
                return this.task_upload_list.filter(item => item.upload_status && this.local_task_upload_filters.includes(item.upload_status))
            }
            return this.task_upload_list;
        },

        local_check_valid_filter() {
            return this.local_is_filterd_task_upload_list('valid') && this.local_is_filterd_task_upload_list('existing-valid')
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapGetters('TaskUpload', {
            task_upload_list: 'list',
            task_upload_response: 'response',
            task_upload_meta: 'meta',
        }),
    },

    methods: {
        async local_index() {
            if (this.$can('tasks.store') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.local_fetch()
        },

        async local_fetch () {
            await this.task_upload_clear()
            await this.task_upload_index({ 'filter[project_id]': this.$route.params.id })
        },

        async local_store() {
            if (this.$refs.csv_file.files && this.$refs.csv_file.files.length === 0) return
            this.loading_file = true
            await this.task_upload_clear()
            await this.task_upload_store({project_id: this.$route.params.id, csv_file: this.$refs.csv_file.files[0]})

            if (this.task_upload_response.status == 'success') {
                this.local_task_upload_filters = []
                await this.local_fetch();
            }
            this.loading_file = false
        },

        async local_destroy(id) {
            this.$refs.csv_file.value = null;
            await this.task_upload_destroy(id)
        },

        async local_save(type = 'ignore') {
            this.loading_save = true;
            await this.task_upload_save({ type, project_id: this.$route.params.id })

            if (this.task_upload_response.status == 'success') {
                this.$router.push({name: 'projects-tasks'})
            }
            this.loading_save = false;
        },

        async local_initiate() {
            this.$refs.csv_file.value = null
            this.$refs.csv_file.click()
        },

        async local_format() {
            await this.task_upload_format()
        },

        local_text_truncate(text, length, separator) {
            return _.truncate(text, {length: length, separator: separator});
        },
        // Task Filters
        async local_task_upload_filter(status, clear = false) {
            if (clear === true) {
                this.local_task_upload_filters = []
                return
            }

            if(status === 'valid-all') {
                await this.local_check_valid_all_filter()
                return
            }

            const index = this.local_task_upload_filters.findIndex(item => item === status)
            if (index !== -1) {
                this.local_task_upload_filters.splice(index, 1)
                if(status === 'valid' || status === 'existing-valid') this.local_check_new_existing_filter()
                return
            }
            this.local_task_upload_filters.push(status)
            if(status === 'valid' || status === 'existing-valid') this.local_check_add_valid_filter()
        },

        local_check_add_valid_filter() {
            if (!this.local_check_valid_filter) return

            const index = this.local_task_upload_filters.findIndex(item => item === 'valid-all')
            if (index !== -1) return
            this.local_task_upload_filters.push('valid-all')
        },

        local_check_new_existing_filter() {
            const valid_index = this.local_task_upload_filters.findIndex(item => item === 'valid')
            const existing_valid_index = this.local_task_upload_filters.findIndex(item => item === 'existing-valid')
            if (valid_index === -1 && existing_valid_index === -1) {
                const valid_all_index = this.local_task_upload_filters.findIndex(item => item === 'valid-all')
                if (valid_all_index !== -1) this.local_task_upload_filters.splice(valid_all_index, 1)
            }
        },

        local_is_filterd_task_upload_list(status) {
            if (!this.local_task_upload_filters.length) return false

            const index = this.local_task_upload_filters.findIndex(item => item === status)
            return index !== -1
        },

        async local_check_valid_all_filter() {
            const index = this.local_task_upload_filters.findIndex(item => item === 'valid-all')
            if (index !== -1) {
                this.local_task_upload_filters.splice(index, 1)
                await this.local_remove_valid_records()
                return
            }

            this.local_task_upload_filters.push('valid-all')
            this.local_task_upload_filters.push('valid')
            this.local_task_upload_filters.push('existing-valid')
        },

        local_remove_valid_records() {
            const valid_index = this.local_task_upload_filters.findIndex(item => item === 'valid')
            if (valid_index !== -1) this.local_task_upload_filters.splice(valid_index, 1)

            const existing_valid_index = this.local_task_upload_filters.findIndex(item => item === 'existing-valid')
            if (existing_valid_index !== -1) this.local_task_upload_filters.splice(existing_valid_index, 1)
        },

        ...mapActions('TaskUpload', {
            task_upload_index: 'index',
            task_upload_store: 'store',
            task_upload_save: 'save',
            task_upload_format: 'format',
            task_upload_destroy: 'destroy',
            task_upload_clear: 'clear',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .csv-table {
        border-collapse: collapse !important;
        border-spacing: 5px;
        width: 100%;
    }

    .csv-table-row-header {
        th {
            background-color: #f8f9fb;
            border: 1px solid #ddd;
            color: #878384;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            padding: 4px;
            &:first-child {
                border-bottom: 4px solid #bcbcbc;
                border-right: 4px solid #bcbcbc;
                padding: 2px 5px !important;
                display: block;
            }
            &:first-child {
                position: sticky;
                left: 0;
                display: block;
            }
        }
    }

    .csv-table-row-title {
        th {
            &:not(.csv-table-cell-number) {
                border: 1px solid #ddd;
                background-color: #fff;
                color: #222;
                font-weight: 700;
                font-size: 14px;
                text-align: left;
                padding: 4px;
            }
            &:first-child {
                position: sticky;
                left: 0;
                border-left: 0;
            }
        }
    }

    .csv-table-cell-number {
        background-color: #f8f9fb;
        border: 1px solid #ddd;
        width: 48px;
        height: 32px;
        color: #878384;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }

    .csv-table-row-data {
        td {
            &:not(.csv-table-cell-number) {
                border: 1px solid #dddddd;
                background-color: #fff;
                color: #222;
                font-weight: 400;
                font-size: 14px;
                text-align: left;
                padding: 4px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 0;
            }
            &:first-child {
                position: sticky;
                left: 0;
                border-left: 0;
            }
        }
    }

    .csv-table-row-upload-status {
        position: sticky;
        left: 47px;
    }

    .csv-table-row-status-invalid td {
        background-color: #fff3e0 !important;
    }

    .csv-table-row-status-skipped td {
        background-color: #fff3e0 !important;
    }

    .csv-table-row-status-ignore td {
        background-color: #fff3e0 !important;
    }

    .csv-table-row-status-existing-valid td {
        background-color: #E3F2FD !important;
    }

    .csv-table-row-status-restore td {
        background-color: #E3F2FD !important;
    }

    .csv-table-column-identifier {
        width: 48px;
    }

    .csv-table-column-type {
        width: 48px;
    }

    .csv-table-column-title {
        width: calc(25% - 144px);
    }

    .csv-table-column-info {
        width: 48px;
    }

    .csv-table-row-status-invalid .csv-table-cell-error {
        border: 2px dashed orange !important;
    }

    .csv-table-row-status-existing-valid .csv-table-cell-error {
        border: 2px dashed orange !important;
    }

    .csv-table-row-status-restore .csv-table-cell-error {
        border: 2px dashed orange !important;
    }

    .csv-table-cell-existing-value {
        border: 2px dashed #41a0ee !important;
    }

    .csv-table-row-status-skipped .csv-table-cell-error,
    .csv-table-row-status-warning .csv-table-cell-error,
    .csv-table-row-status-ignore .csv-table-cell-error {
        border: 2px dashed orange !important;
    }

    .csv-table-cell-ignore,
    .csv-table-row-status-existing-valid .csv-table-cell-ignore {
        border: 2px dashed #a2a2a2 !important;
    }

    .csv-table-row-status-skipped .csv-table-cell-warning,
    .csv-table-row-status-warning .csv-table-cell-warning,
    .csv-table-row-status-existing-valid .csv-table-cell-warning {
        border: 2px dashed orange !important;
    }
</style>

<template>
    <div class="pt-5">
        <template>
            <gong-breadcrumb></gong-breadcrumb>
            <gong-header :installation="installation_item"></gong-header>
            <gong-tabs :installation="installation_item"></gong-tabs>
        </template>
        <div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import GongBreadcrumb from './Partials/GongBreadcrumb'
import GongHeader from './Partials/GongHeader'
import GongTabs from './Partials/GongTabs'

export default {
    components: {
        GongBreadcrumb,
        GongHeader,
        GongTabs,
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localGongHasAccess () {
            return !!(this.user_self && this.user_self.organization && this.user_self.organization.flag_gong_integration && this.user_self.organization.flag_gong_integration === 1)
        },

        ...mapState('WorkflowLog', {
            workflow_log_list: 'list',
            workflow_log_filters: 'filters',
        }),

        ...mapState('Installation', {
            installation_item: 'item',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Integration', {
            integration_statuses: 'list',
        }),
    },

    methods: {
        async localIndex () {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index') || !this.localGongHasAccess) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.integration_status_index()
            if (!this.localIntegrationHasAccess('gong')) {
                this.$router.replace({ name: 'appstore' })
            }
        },

        localIntegrationHasAccess (slug) {
            return !!this.integration_statuses[slug]
        },

        ...mapActions('Integration', {
            integration_status_index: 'statuses',
        }),

        ...mapActions('Meeting', {
            meeting_info_index: 'info_index',
        }),
    },
}
</script>

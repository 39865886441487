<template>
    <a-dialog
        v-model="dialogSearch"
        :transition="null"
        max-width="684"
        min-width="684"
        content-class="c-global-search-dialog u-rounded-corners-lg"
        hide-overlay
    >
        <a-sheet @mousemove="localTriggerMouseMove">
            <div class="u-flex-center-y u-relative">
                <a-icon
                    size="20"
                    color="grey"
                    class="u-absolute"
                    style="left: 20px; pointer-events: none"
                >
                    search
                </a-icon>
                <input
                    v-model="searchTerm"
                    :class="[searchTerm ? 'primary--text' : 'grey--text text--darken-1']"
                    @input="localSearchTrigger"
                    ref="refGlobalSearchInput"
                    class="u-hfull u-wfull u-font-16 u-leading-20 u-tracking py-5 pr-6 u-rounded-corners-lg"
                    type="text"
                    placeholder="Search projects or navigate..."
                    style="padding-left: 48px;"
                >
            </div>

            <div
                ref="refSearchList"
                class="c-search-list-container py-3 u-relative u-overflow-y c-tiny-scroll"
                style="min-height: 176px; max-height: 464px;"
            >
                <div
                    :class="[{ 'c-animate-search-loader': projectLoading }]"
                    class="u-absolute u-wfull"
                    style="
                        height: 2px;
                        top: 0px;
                        left: 0px;
                        z-index: 1;
                    "
                />

                <div
                    class="u-absolute u-wfull"
                    style="
                        height: 12px;
                        top: 0px;
                        left: 0px;
                        background: linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0.00) 100%);
                    "
                />

                <template v-if="localIsResultEmpty">
                    <div
                        class="text-center u-relative u-flex flex-column justify-end"
                        style="min-height: 146px;"
                    >
                        <div
                            class="u-absolute pt-2"
                            style="top: 0px; left: 50%; transform: translateX(-50%);"
                        >
                            <img
                                src="../../assets/images/global-search-empty.svg"
                                alt="Empty Search Results"
                                style="width: 160px;"
                            >
                        </div>
                        <div>
                            <h4 class="u-font-14 font-weight-medium u-leading-20 u-tracking blue-grey--text text--darken-1">
                                No results found
                            </h4>
                            <h5 class="u-font-12 font-weight-medium u-leading-20 u-tracking blue-grey--text text--lighten-2">
                                There are no projects or navigation related to this keyword.
                            </h5>
                        </div>
                    </div>
                </template>

                <template v-if="!localIsResultEmpty">
                    <template v-if="!localIsProjectsEmpty">
                        <div class="u-font-12 px-3 mb-3 mx-3 blue-grey--text text--lighten-2 u-font-weight-semibold u-leading-16 u-tracking text-capitalize">
                            {{ searchTerm && searchTerm.trim() ? '' : 'Recent' }} Projects
                        </div>
                        <template v-for="(project) in searchList">
                            <div
                                v-if="project._type === 'project'"
                                :key="project.id"
                                :data-menu-wrapper="`menu-wrapper-${localGetId()}`"
                                @mouseenter="localSetMouseHoverMenuIndex($event)"
                                class="c-search-item-wrapper u-no-select"
                                aria-selected="false"
                            >
                                <router-link
                                    :to="{ name: 'project-view', params: { id: project.id } }"
                                    :data-menu-index="`menu-item-${project._dataId}`"
                                    style="min-height: 44px;"
                                    class="c-search-item u-cursor-pointer u-relative u-rounded-corners-lg u-flex-center-y pa-3 mx-3 u-relative u-font-14 grey--text text--darken-2 font-weight-medium u-leading-20 u-tracking"
                                >
                                    <shared-search-project-content
                                        :project="project"
                                        :stage="localGetProjectStage(project)"
                                        :result="localGetProjectResult(project)"
                                        :confScoreMeta="confScoreMeta"
                                    />
                                    <a-spacer></a-spacer>
                                    <a-icon v-if="currentHoveredMenuId === project._dataId" size="16" color="grey darken-1">
                                        north_east
                                    </a-icon>
                                </router-link>
                            </div>
                        </template>
                    </template>

                    <a-divider
                        v-if="!localIsProjectsEmpty && !localIsRoutesEmpty"
                        class="grey lighten-4 mt-3 mb-3"
                    />

                    <template v-if="!localIsRoutesEmpty">
                        <div class="u-font-12 px-3 mb-3 mx-3 blue-grey--text text--lighten-2 u-font-weight-semibold u-leading-16 u-tracking text-capitalize">
                            Navigation {{ searchTerm && searchTerm.trim() ? '' : 'Suggestions' }}
                        </div>
                        <template v-for="route in searchList">
                            <div
                                v-if="route._type === 'route'"
                                :key="route.name"
                                :data-menu-wrapper="`menu-wrapper-${localGetId()}`"
                                @click="localAvoidSameRouteNavigation(route.name)"
                                @mouseenter="localSetMouseHoverMenuIndex($event)"
                                class="c-search-item-wrapper"
                                aria-selected="false"
                            >
                                <router-link
                                    :to="{ name: route.name, params: route.params ? route.params : {} }"
                                    :data-menu-index="`menu-item-${route._dataId}`"
                                    style="min-height: 44px;"
                                    class="c-search-item u-relative u-rounded-corners-lg u-flex-center-y py-2 px-3 mx-3 u-relative u-font-14 grey--text text--darken-2 font-weight-medium u-leading-20 u-tracking"
                                >
                                    <a-icon
                                        :outlined="route._isIconOutlined"
                                        size="20"
                                        color="grey darken-1"
                                        class="mr-2"
                                    >
                                        {{ route._icon }}
                                    </a-icon>
                                    <span>{{ route.meta ? route.meta.label : '' }}</span>
                                    <a-spacer></a-spacer>
                                    <a-icon v-if="currentHoveredMenuId === route._dataId" size="16" color="grey darken-1">
                                        north_east
                                    </a-icon>
                                </router-link>
                            </div>
                        </template>
                    </template>
                </template>
            </div>

            <div class="u-relative px-6 py-4 grey lighten-4" style="border-radius: 0px 0px 8px 8px">
                <div
                    class="u-absolute u-wfull"
                    style="
                        height: 12px;
                        top: 0px;
                        left: 0px;
                        background: linear-gradient(180deg, #EBEBEB 0%, rgba(255, 255, 255, 0.00) 100%);
                    "
                />

                <div
                    :class="[{ 'u-opacity-60': !searchTerm }]"
                    class="blue-grey--text text--darken-1 u-flex-center-y u-font-14 font-weight-medium u-tracking u-leading-20"
                >
                    <template v-if="localIsMacOs">
                        <a-icon
                            size="16"
                            color="blue-grey darken-1"
                            class="blue-grey lighten-4 u-rounded-corners mr-1"
                            style="padding: 2px; box-shadow: 0px -2px 0px 0px #B0BEC5 inset;"
                        >
                            keyboard_command_key
                        </a-icon>
                    </template>
                    <span
                        v-else
                        class="blue-grey lighten-4 blue-grey--text text--darken-1 u-rounded-corners mr-1 u-font-10"
                        style="padding: 0px 5px; box-shadow: 0px -2px 0px 0px #B0BEC5 inset;"
                    >
                        Ctrl
                    </span>
                    <a-icon
                        size="20"
                        color="blue-grey darken-1"
                        class="blue-grey lighten-4 u-rounded-corners"
                        style="padding: 2px; box-shadow: 0px -2px 0px 0px #B0BEC5 inset;"
                    >
                        $enter_key
                    </a-icon>
                    <span class="ml-2">Open in New Tab</span>
                </div>
            </div>
        </a-sheet>
    </a-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isUserOs } from '../../helpers/helper-detect-user-os'
import { ValidRoutes } from '../../helpers/helper-get-valid-routes'
import { storage } from '../../helpers/helper-local-storage'
import { useId } from '../../helpers/helper-use-id'
import { ScoreColor } from '../../helpers/helper-confidence-score-color'
import loadWorkspace from '@/mixins/mixin-workspace'
import EventBus from '@/config/config-eventbus';
import SharedHealthColor from './SharedHealthColor.vue'
import PartialTileScoreCount from '../../pages/Dashboard/Partials/PartialTileScoreCount.vue'
import SharedSearchProjectContent from './SharedSearchProjectContent.vue'

export default {
	components: {
        SharedHealthColor,
        PartialTileScoreCount,
        SharedSearchProjectContent
    },

    mixins: [loadWorkspace],

    data () {
        return {
            searchTerm: '',
            projectStages: [],
            projectResults: [],
            searchList: [],
            projectsList: [],
            confScoreMeta: [],
            recentRouteSearches: [],
            recentProjectSearches: [],
            dialogSearch: false,
            isPointerEvtDisabled: false,
            projectLoading: false,
            searchTimeout: null,
            currentMenuIndex: null,
            currentFocusedMenu: null,
            currentHoveredMenuId: null,
            confScoreMetafilters: {
                'filter[type]': 'confidence_score',
                'fields[metas]': 'id,type,status,selection_type,percentage,color',
                'sort': 'order',
            },
        }
    },

    watch: {
        '$route': {
            handler (val) {
                if (!this.dialogSearch) return
                this.localSaveRecentSearch(this.localOrderedSearchList[this.currentMenuIndex])
                this.localCloseSearchDialog()
            }
        },

        dialogSearch (val) {
            val ? this.localAddListeners() : this.localRemoveListeners()

            setTimeout(() => this.localFocusFirstMenu(), 100)
            this.localFocusSearchInput()
            this.localSetNavSuggestions()
            if (val) this.localFetchMetaData()
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localIsMacOs () {
            return isUserOs() === 'mac'
        },

        localIsResultEmpty () {
            return !_.size(this.searchList) && _.size(_.trim(this.searchTerm))
        },

        localIsProjectsEmpty () {
            if (_.size(this.searchList) === 0) return true

            return _.size(this.searchList.filter(item => item._type === 'project')) === 0
        },

        localIsRoutesEmpty () {
            return _.size(this.searchList.filter(Boolean).filter(item => item._type === 'route')) === 0
        },

        localOrderedSearchList () {
            return _.orderBy(this.searchList, '_type')
        },

        ...mapState('Meta', {
            meta_list: 'list'
        })
    },

    methods: {
        localIndex () {
            EventBus.$on('global-search-dialog', (val) => {
                if (!val) return this.localCloseSearchDialog()
                if (val) this.localOpenSearchDialog()
            })

            this.localAddListeners()
            this.localFetchConfScoreMeta()
        },

        async localFetchConfScoreMeta () {
            await this.meta_index(this.confScoreMetafilters)
            this.confScoreMeta = _.cloneDeep(this.meta_list)
        },

        localSearchTrigger (evt) {
            if (!_.trim(this.searchTerm)) {
                this.localSetNavSuggestions()
                this.$nextTick(() => this.localFocusFirstMenu())
                this.projectLoading = false
                return clearTimeout(this.searchTimeout)
            }

            this.projectLoading = true
            clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(() => this.localSearch(), 500)
        },

        async localSearch () {
            await this.localRoutesList()
            this.localGetMenuItems()
            setTimeout(() => this.localFocusFirstMenu(), 0)
            clearTimeout(this.searchTimeout)
        },

        localGetMenuItems () {
            const list = [...document.querySelectorAll('[data-menu-index]')]
            list.forEach(menu => menu.classList.remove('active-item-flag'))
        },

        async localRoutesList () {
            await this.localSearchRoutes()
            await this.localSearchProjects()
        },

        async localSearchRoutes () {
            new Promise((resolve) => {
                const routes = this.localPrepareRoutes()
                const list = routes.filter(route => {
                    const searchTerm = _.trim(this.searchTerm) ? this.searchTerm.toLowerCase() : null
                    if (!searchTerm) return route

                    return route?.meta?.label.toLowerCase().includes(_.trim(searchTerm))
                })

                this.searchList = this.searchList.filter(i => i._type !== 'route')
                this.searchList.push(...list.slice(0, 3))
                resolve()
            })
        },

        async localSearchProjects () {
            const { data = [], _status } = await this.project_search_index({
                query: _.trim(this.searchTerm) || '',
                workspace_id: this.current_workspace_id_mixin
            })
            if (_status === 'failed') return this.projectLoading = false

            this.projectsList = data.map(item => ({ ...item, _type: 'project', _dataId: this.localGetId() }))
            this.searchList = this.searchList.filter(i => i._type !== 'project')
            this.searchList.push(...this.projectsList.slice(0, 3))
            this.projectLoading = false
        },

        localPrepareRoutes () {
            const list = this.$router.getRoutes()
                .filter(route => {
                    const index = ValidRoutes().get().findIndex(validRoute => validRoute.name === route.name)
                    return index !== -1
                })

            return this.localMapRoutesWithIcon(list)
        },

        localSetNavSuggestions () {
            this.searchList = []
            this.localSetProjectSuggestions()
            this.localSetRouteSuggestions()
        },

        localFetchMetaData () {
            this.localFetchStages()
            this.localFetchResultMeta()
        },

        async localFetchStages () {
            const { data, _status } = await this.stage_index({
                'fields[stages]': 'id,name,status',
                'filter[workspace_id]': this.current_workspace_id_mixin,
                stateless: true
            })
            if (_status === 'failed') return

            this.projectStages = _.cloneDeep(data)
        },

        async localFetchResultMeta () {
            const { data, _status } = await this.meta_index({
                'filter[type]': 'project_result',
                'fields[metas]': 'id,status,value',
                stateless: true
            })
            if (_status === 'failed') return

            this.projectResults = _.cloneDeep(data)
        },

        localSetProjectSuggestions () {
            this.recentProjectSearches = storage.get('recentProjectSearch') || []
            this.searchList.push(...this.recentProjectSearches.filter(item =>
                item.workspace_id === this.current_workspace_id_mixin
            ))
        },

        localSetRouteSuggestions () {
            this.recentRouteSearches = storage.get('recentRouteSearch') || []

            const suggestions = []
            const suggestedRoutes = _.size(this.recentRouteSearches) ?
                this.recentRouteSearches.map(i => i.name) :
                ['dashboard-columns', 'projects', 'customers']

            suggestedRoutes.forEach(slug => {
                const routeItem = this.$router.getRoutes().find(route => route.name === slug)
                suggestions.push(routeItem)
            })
            this.searchList.push(...this.localMapRoutesWithIcon(suggestions))
        },

        localMapRoutesWithIcon (routes) {
            const routesList = ValidRoutes().get()

            return routes.map(route => {
                const index = routesList.findIndex(validRoute => validRoute.name === route.name)
                if (index === -1) return null
                return {
                    ...route,
                    params: (route?.params || routesList[index]?.params || {}),
                    _dataId: this.localGetId(),
                    _type: 'route',
                    _icon: routesList[index].icon,
                    _isIconOutlined: routesList[index].iconOutlined
                }
            }).filter(Boolean)
        },

        localAddListeners () {
            document.addEventListener('keydown', this.localHandleKeyDown)
        },

        localRemoveListeners () {
            document.removeEventListener('keydown', this.localHandleKeyDown)
        },

        localHandleKeyDown (evt) {
            if (!this.dialogSearch) return

            if (evt.key === 'Enter') return this.localOnKeyEnter(evt)
            if (evt.key === 'ArrowUp') return this.localOnArrowUp(evt)
            if (evt.key === 'ArrowDown') return this.localArrowDown(evt)
            if (evt.key === 'Escape') return this.localCloseSearchDialog(evt)
        },

        localOpenSearchDialog () {
            if (this.dialogSearch) return
            this.dialogSearch = true
            this.searchTerm = ''
            this.currentMenuIndex = null
        },

        localCloseSearchDialog () {
            if (!this.dialogSearch) return
            this.dialogSearch = false
            this.searchTerm = ''
            this.currentMenuIndex = null
            EventBus.$emit('global-search-dialog', false)
        },

        localOnKeyEnter (evt) {
            evt.preventDefault()
            evt.stopPropagation()
            if (evt.metaKey || evt.ctrlKey) return this.localOnCmdCtrl()

            const activeMenu = this.localGetFocusedMenu()
            if (!activeMenu || !('click' in activeMenu)) return

            this.localSaveRecentSearch(this.localOrderedSearchList[this.currentMenuIndex])
            activeMenu.click()
            this.localCloseSearchDialog()
        },

        localOnCmdCtrl () {
            const link = this.currentFocusedMenu.getAttribute('href')
            if (link) window.open(link, '_blank') // Opens in new tab

            this.localSaveRecentSearch(this.localOrderedSearchList[this.currentMenuIndex])
            this.localCloseSearchDialog()
        },

        localOnArrowUp (evt) {
            evt.preventDefault()
            evt.stopPropagation()
            this.isPointerEvtDisabled = true

            if (this.currentMenuIndex === null || this.currentMenuIndex <= 0) {
                return this.localFocusLastMenu()
            }

            this.currentMenuIndex--
            this.localFocusMenu()
            this.localScrollToFocusedElement()
        },

        localArrowDown (evt) {
            evt.preventDefault()
            evt.stopPropagation()
            this.isPointerEvtDisabled = true

            const list = [...document.querySelectorAll('[data-menu-index]')]
            if (this.currentMenuIndex === null || this.currentMenuIndex >= _.size(list) - 1) {
                return this.localFocusFirstMenu()
            }

            this.currentMenuIndex++
            this.localFocusMenu()
            this.localScrollToFocusedElement()
        },

        localFocusFirstMenu () {
            const list = [...document.querySelectorAll('[data-menu-index]')]
            this.localRemoveAllFocusedMenus(list)

            this.currentMenuIndex = 0
            if (!_.size(list)) return

            this.currentFocusedMenu = list[this.currentMenuIndex]
            list[this.currentMenuIndex].classList.add('active-item-flag')
            this.localSetCurrentHoveredMenu()
            this.localScrollToFocusedElement()
        },

        localFocusLastMenu () {
            const list = [...document.querySelectorAll('[data-menu-index]')]
            this.localRemoveAllFocusedMenus(list)

            this.currentMenuIndex = _.size(list) - 1
            if (!_.size(list)) return

            this.currentFocusedMenu = list[this.currentMenuIndex]
            list[this.currentMenuIndex].classList.add('active-item-flag')
            this.localSetCurrentHoveredMenu()
            this.localScrollToFocusedElement()
        },

        localFocusMenu () {
            const list = [...document.querySelectorAll('[data-menu-index]')]
            this.localRemoveAllFocusedMenus(list)

            list.forEach(menu => menu.classList.remove('active-item-flag'))
            this.currentFocusedMenu = list[this.currentMenuIndex]
            this.currentFocusedMenu.classList.add('active-item-flag')
            this.localSetCurrentHoveredMenu()
        },

        localRemoveAllFocusedMenus (menuList) {
            const list = menuList ? menuList : [...document.querySelectorAll('[data-menu-index]')]
            list.forEach(menu => menu.classList.remove('active-item-flag'))
        },

        localGetFocusedMenu () {
            const list = [...document.querySelectorAll('[data-menu-index]')]
            return list.find(item => item.classList.contains('active-item-flag'))
        },

        localFocusSearchInput () {
            setTimeout(() => {
                const input = this.$refs.refGlobalSearchInput
                if (!input || !('focus' in input)) return
                input.focus()
            }, 200)
        },

        localSetMouseHoverMenuIndex (evt) {
            if (this.isPointerEvtDisabled) return

            evt.preventDefault()
            evt.stopPropagation()

            const listItems = [...evt.target.closest('.c-search-list-container').querySelectorAll('[data-menu-wrapper]')]
            this.currentMenuIndex = listItems.findIndex(i => {
                return i.querySelector('[data-menu-index]').isEqualNode(evt.target) ||
                    i.querySelector('[data-menu-index]').isEqualNode(evt.target.querySelector('[data-menu-index]'))
            })

            this.localFocusMenu()
        },

        localSetCurrentHoveredMenu (index = null) {
            const listItems = [...document.querySelectorAll('[data-menu-wrapper]')]
            const menuId = listItems[index !== null ? index : this.currentMenuIndex].querySelector('[data-menu-index]').dataset.menuIndex
            this.currentHoveredMenuId = parseInt(menuId.split('-')[2])
        },

        localSaveRecentSearch (route) {
            route?._type === 'project' ?
                this.localSaveRecentProjects(route) :
                this.localSaveRecentRoutes(route)
        },

        localSaveRecentProjects (project) {
            const recents = storage.get('recentProjectSearch') || []
            const index = recents.findIndex(i => i.id === project.id)
            if (index !== -1) recents.splice(index, 1)

            if (_.size(recents) < 3) {
                this.localStoreRecentSearch(recents, project)
                return this.localPreserveRecentProjects(recents)
            }

            if (_.size(recents) === 3) {
                if (index === -1) recents.pop()
                this.localStoreRecentSearch(recents, project)
            }

            this.localPreserveRecentProjects(recents)
        },

        localSaveRecentRoutes (route) {
            const recents = storage.get('recentRouteSearch') || []
            const index = recents.findIndex(i => (('name' in route) && route.name === i.name))
            if (index !== -1) recents.splice(index, 1)

            if (_.size(recents) < 3) {
                this.localStoreRecentSearch(recents, route)
                return this.localPreserveRecentRoutes(recents)
            }

            if (_.size(recents) === 3) {
                if (index === -1) recents.pop()
                this.localStoreRecentSearch(recents, route)
            }

            this.localPreserveRecentRoutes(recents)
        },

        localPreserveRecentRoutes (recents) {
            storage.save('recentRouteSearch', recents)
            this.recentRouteSearches = recents
        },

        localPreserveRecentProjects (recents) {
            const recentsList = !_.size(recents) ? [] : recents.filter(i => i.workspace_id === this.current_workspace_id_mixin)
            storage.save('recentProjectSearch', recentsList)

            this.recentProjectSearches = recents
        },

        localStoreRecentSearch (recents, route) {
            if (route._type === 'project') {
                recents.unshift(route)
                return true
            }

            recents.unshift({
                name: route.name,
                _icon: route._icon,
                _isIconOutlined: route._isIconOutlined
            })
        },

        localAvoidSameRouteNavigation (routeName) {
            if (this.$route.name !== routeName) return
            this.localCloseSearchDialog()
        },

        localScrollToFocusedElement () {
            this.currentFocusedMenu.scrollIntoView({ block: 'center' })
        },

        localGetProject (field, project) {
            if (field === 'confidence_score.percentage') {
                return _.get(project, 'confidence_score.percentage') || '--'
            }

            if (field === 'confidence_score.color') {
                return this.localGetConfScoreColor(_.get(project, 'confidence_score.percentage'))
            }

            return _.get(project, field)
        },

        localGetConfScoreColor (percentage) {
            return ScoreColor(percentage, this.confScoreMeta).get()
        },

        // Extras
        localGetId () {
            return useId()
        },

        localTriggerMouseMove () {
            this.isPointerEvtDisabled = false
        },

        localGetProjectStage (project, field = null) {
            if (!_.size(this.projectStages) || !_.size(this.projectResults)) return {}

            let stageObj = { name: '', icon: '' }
            const status = _.get(project, 'status')
            const stage = this.projectStages.find(item => item.id === project.stage_id)
            if (!stage && status !== 'archived') return {}

            if (project.status === 'active') stageObj = { name: stage.name, icon: 'bolt' }
            if (project.status === 'queue') stageObj = { name: stage.name, icon: 'web_stories' }
            if (project.status === 'archived') stageObj = { name: 'Archived', icon: 'archive' }
            if (project.status === 'completed') {
                const result = this.projectResults.find(item => item.id === project.result_id)
                if (!stage) return {}

                if (_.get(result, 'status') ===  'Positive') stageObj = { name: 'Completed', icon: 'thumb_up' }
                if (_.get(result, 'status') ===  'Neutral') stageObj = { name: 'Completed', icon: 'linear_scale' }
                if (_.get(result, 'status') ===  'Negative') stageObj = { name: 'Completed', icon: 'thumb_down' }
            }

            return field === null ? stageObj : (_.get(stageObj, field) || null)
        },

        localGetProjectResult (project, field = null) {
            if (!_.size(this.projectResults)) return {}

            const result = this.projectResults.find(item => item.id === project.result_id)
            return field === null ? result : (_.get(result, field) || null)
        },

        ...mapActions('Project', {
            project_search_index: 'search_index'
        }),

        ...mapActions('Stage', {
            stage_index: 'index'
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
        }),
    }
}
</script>

<style lang="scss" scoped>
.c-search-item.active-item-flag {
    background-color: #F5F5F5 !important;
}

.c-nav-style {
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    --webkit-backdrop-filter: blur(6px);
    transition: 0.4s all;
}

.c-animate-search-loader {
    background: linear-gradient(
        90deg,
        #A8D5FB, #5F99F9,
        #5F99F9, #6482E1,
        #6482E1, #847EFD,
        #847EFD, #9671FF,
        #9671FF, #A8D5FB
    );
    background-size: 400% 400%;
    animation: search-loader 5s ease infinite;
}

@keyframes search-loader {
    0% { background-position: 0% 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0% 50%; }
}
</style>

<template>
    <g-dialog-box icon="notes" icon-size="24" hide-close-icon>
        <template #title>Create new project note for {{ projectTitle | truncateText(45) }}</template>
        <template #subtitle>You can add new Note to the projects</template>
        <template #body>
            <a-container container--fluid grid-list-xl class="pa-6">
                <a-layout wrap align-center>
                    <a-flex xs12>
                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title</label>
                        <a-text-field
                            v-model="item.title"
                            placeholder="Add new note title"
                            background-color="neutral"
                            solo flat hide-details
                        ></a-text-field>
                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="note_response.server && note_response.server.errors && note_response.server.errors.title">
                            <a-icon size="16" color="red darken-2">warning</a-icon>
                            {{ note_response.server.errors.title[0] }}
                        </span>
                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                            <a-icon size="16">info</a-icon>
                            Enter the title of the note.
                        </span>
                    </a-flex>
                    <a-flex xs12>
                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Description (Optional)</label>
                        <div class="neutral pa-3">
                            <p class="grey--text text--darken-1" v-if="dataEditorNoteDescription === null" @click="() => { item.content = '<p> </p>'; dataEditorNoteDescription.focus() }">Click to edit the description</p>
                            <editor-content v-else :editor="dataEditorNoteDescription"></editor-content>
                            <s-editor-menu :content="dataEditorNoteDescription"></s-editor-menu>
                        </div>
                        <span class="md-caption grey--text text--darken-2 d-block mt-2">
                            <a-icon size="16">info</a-icon>
                            You can summarize and describe the note.
                        </span>
                    </a-flex>
                    <a-flex xs8>
                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Category (Optional)</label>
                            <a-autocomplete
                            v-model="item.type_id"
                            :items="dataTypeList"
                            item-text="value"
                            item-value="id"
                            placeholder="Select Category"
                            background-color="neutral"
                            solo flat hide-details clearable
                        >
                        </a-autocomplete>
                        <span class="md-caption grey--text text--darken-2 d-block mt-2">
                            <a-icon size="16">info</a-icon>
                            Select the category of the note.
                        </span>
                    </a-flex>
                    <a-flex xs4>
                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Visibility</label>
                        <a-autocomplete
                            v-model="item.visibility"
                            :items="[{name: 'Private', value: 'internal'}, {name: 'Public', value: 'external'}]"
                            item-text="name"
                            item-value="value"
                            placeholder="visibility"
                            background-color="neutral"
                            solo flat hide-details
                        >
                        </a-autocomplete>
                        <span class="md-caption grey--text text--darken-2 d-block mt-2">
                            <a-icon size="16">info</a-icon>
                            Set the visibility of the note.
                        </span>
                    </a-flex>
                </a-layout>
            </a-container>
        </template>
        <template #footer>
            <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="$emit('store', item)" :loading="loading" :disabled="loading">Create Note</a-btn>
            <a-btn color="grey" class="my-0 ml-4" text @click="$emit('close')" :disabled="loading">Cancel</a-btn>
        </template>
    </g-dialog-box>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Editor, EditorContent } from 'tiptap'
import { Bold, Italic, OrderedList, BulletList, ListItem, History, } from 'tiptap-extensions'
import marked from 'marked'
import SEditorMenu from './SharedEditorMenu'

export default {
    props: {
        projectTitle: {
            type: String
        },
        item: {
            type: Object,
            default: {}
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean
        },
    },

    components: { EditorContent, SEditorMenu },

    data () {
        return {
            dataTypeList: [],
            dataEditorNoteDescription: null,
        }
    },

    watch: {
        isOpen (val) {
            if (val) {
                this.dataTypeList = []
                this.methodIndex()
            }
        }
    },

    beforeDestroy() {
        if (this.dataEditorNoteDescription != null) {
            this.dataEditorNoteDescription.destroy()
        }
    },

    mounted () {
        this.methodIndex()
    },

    computed: {
        ...mapState('Note', {
            note_response: 'response',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),
    },

    methods: {
        async methodIndex () {
            if (this.dataEditorNoteDescription !== null) this.dataEditorNoteDescription.destroy()

            const content = this.item.content ? this.item.content : ' '
            this.dataEditorNoteDescription = new Editor({
                extensions: [
                    new Bold(),
                    new Italic(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new History(),
                ],
                content: marked(content, { sanitize: true }),
                onUpdate: state => this.item.content = turndownService.turndown(state.getHTML()),
            })

            await this.meta_index({
                'filter[type]': 'note_type',
                'fields[metas]': 'id,value,status,type',
                'sort': 'order'
            })
            this.dataTypeList = _.cloneDeep(this.meta_list)
        },

        ...mapActions('Meta', {
            meta_index: 'index',
        }),
    }
}
</script>

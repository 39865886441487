<template>
    <div class="mb-14">
        <h3 class="text-h6 grey--text text--darken-3 font-weight-medium mb-6">Status</h3>

        <!-- Form -->
        <PStatusUpdateCreateForm
            v-if="permission_status('store')"
            :createLoading="mixinIsLoading('status-create')"
            :loading="mixinIsLoading('root')"
            :typeList="meta_list"
            :disableAll="localFilterSelected"
            @store="localStoreStatus"
        />

        <div v-if="mixinIsLoading('root')" class="mt-6">
            <a-sheet v-for="count in 3" :key="count" class="u-rounded-corners-lg u-border mb-6 pa-6">
                <div class="u-flex-center-y">
                    <loader-template width="36" height="36" class="u-rounded-corners-full mr-3"></loader-template>
                    <loader-template :width="Math.floor(Math.random() * 50) + 100" height="12" class="u-rounded-corners-full mr-3"></loader-template>
                </div>
                <div class="my-4">
                    <loader-template :width="Math.floor(Math.random() * 200) + 100" height="12" class="u-rounded-corners-full mr-3"></loader-template>
                </div>
                <div>
                    <loader-template width="100%" height="12" class="u-rounded-corners-full mb-2"></loader-template>
                    <loader-template width="100%" height="12" class="u-rounded-corners-full mb-2"></loader-template>
                    <loader-template :width="Math.floor(Math.random() * 200) + 100" height="12" class="u-rounded-corners-full"></loader-template>
                </div>
            </a-sheet>
        </div>

        <div v-else>
            <!-- Filters -->
            <div class="mt-6 u-flex-center-y">
                <div>
                    <a-menu bottom offset-y max-width="200" nudge-bottom="2">
                        <template v-slot:activator="{ on, value }">
                            <a-sheet class="u-rounded-corners u-cursor-pointer u-overflow-hidden u-border px-2 u-flex align-center" height="32" width="180" v-on="on">
                                <a-icon size="20" class="mr-1" :color="healthFilter.value !== 'all' ? 'blue' : 'grey lighten-1'">filter_list</a-icon>
                                <span class="md-body-2 grey--text text--darken-2 mx-1">{{ healthFilter.text }}</span>
                                <a-spacer></a-spacer>
                                <a-icon size="20" class="ma-0">arrow_drop_{{ value ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed u-list-condensed--medium">
                            <a-list-item :class="{ 'grey lighten-4': healthFilter.value === 'all' }" @click="localSetHealthFilter({ value: 'all', text: 'All Health' })">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-2">All Health</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                        <a-divider></a-divider>
                        <a-list class="u-list-condensed u-list-condensed--medium">
                            <template v-for="(health, index) in healthList">
                                <a-divider :key="health.value + '-divider'" v-if="index !== 0"></a-divider>
                                <a-list-item :class="{ 'grey lighten-4': health.value === healthFilter.value }" :key="'parent_type_key_' + health.value" @click="localSetHealthFilter(health)">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3">{{ health.text }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </template>
                        </a-list>
                    </a-menu>
                </div>

                <div class="ml-4">
                    <a-menu bottom offset-y max-width="200" nudge-bottom="2">
                        <template v-slot:activator="{ on, value }">
                            <a-sheet class="u-rounded-corners u-cursor-pointer u-overflow-hidden u-border px-2 u-flex align-center" height="32" width="240" v-on="on">
                                <a-icon size="20" class="mr-1" :color="typeFilter.id !== 'all' ? 'blue' : 'grey lighten-1'">filter_list</a-icon>
                                <span class="md-body-2 grey--text text--darken-2 mx-1 text-truncate">{{ typeFilter.value }}</span>
                                <a-spacer></a-spacer>
                                <a-icon size="20" class="ma-0">arrow_drop_{{ value ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed u-list-condensed--medium">
                            <a-list-item :class="{ 'grey lighten-4': typeFilter.id === 'all' }" @click="localSetTypeFilter({ id: 'all', value: 'All Type' })">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-2">All Type</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                        <a-divider></a-divider>
                        <a-list class="u-list-condensed u-list-condensed--medium">
                            <template v-for="(typeItem, index) in meta_list">
                                <a-divider :key="typeItem.id + '-divider'" v-if="index !== 0"></a-divider>
                                <a-list-item :class="{ 'grey lighten-4': typeItem.id === typeFilter.id }" :key="'parent_type_key_' + typeItem.value" @click="localSetTypeFilter(typeItem)">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 text-truncate">{{ typeItem.value }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </template>
                        </a-list>
                    </a-menu>
                </div>

                <div class="ml-4">
                    <a-menu bottom offset-y max-width="200" nudge-bottom="2">
                        <template v-slot:activator="{ on, value }">
                            <a-sheet class="u-rounded-corners u-cursor-pointer u-overflow-hidden u-border px-2 u-flex align-center" height="32" width="180" v-on="on">
                                <a-icon size="20" class="mr-1" :color="visibilityFilter.value !== 'all' ? 'blue' : 'grey lighten-1'">filter_list</a-icon>
                                <span class="md-body-2 grey--text text--darken-2 mx-1">{{ visibilityFilter.text }}</span>
                                <a-spacer></a-spacer>
                                <a-icon size="20" class="ma-0">arrow_drop_{{ value ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed u-list-condensed--medium">
                            <template v-for="(item, index) in visibilityList">
                                <a-divider :key="item.value + '-divider'" v-if="index !== 0"></a-divider>
                                <a-list-item :class="{ 'grey lighten-4': item.value === visibilityFilter.value }" :key="'parent_type_key_' + item.value" @click="localSetVisibilityFilter(item)">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3">{{ item.text }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </template>
                        </a-list>
                    </a-menu>
                </div>

                <div class="ml-4" v-if="mixinIsLoading('status-filter')">
                    <a-progress-circular size="22" width="3" color="orange darken-2" indeterminate></a-progress-circular>
                </div>

                <a-spacer></a-spacer>
                <v-slide-x-transition hide-on-leave leave-absolute>
                    <div class="ml-4" v-if="localFilterSelected">
                        <a-card v-ripple="true" :disabled="!localFilterSelected" @click="localClearFilters()" flat class="u-rounded-corners blue darken-2 u-cursor-pointer u-overflow-hidden u-shadow px-2 pr-3 u-flex-center-y" height="32">
                            <a-icon size="20" class="u-icon-nudge" style="top: 1px;" color="white">clear_all</a-icon>
                            <span class="md-body-2 white--text ml-1">Clear Filters</span>
                        </a-card>
                    </div>
                </v-slide-x-transition>
            </div>

            <div class="text-center mt-12" v-if="!localStatusesLength">
                <a-icon size="40" class="mb-2" color="grey">info</a-icon>
                <h3 class="md-subtitle-2 grey--text text--darken-2">No Data Found</h3>
                <p class="mb-0 md-body-2 grey--text text--darken-1">There is no data to fetch for the applied filters</p>
            </div>

            <!-- Status List -->
            <div class="mt-6">
                <a-sheet class="u-rounded-corners-lg u-border mb-6" v-for="statusItem in status_update_list" :key="statusItem.id">
                    <div class="pa-6">
                        <div class="u-flex-center-y">
                            <div class="u-flex-center-y">
                                <g-avatar :item="statusItem.created_by" :size="32" no-shadow :rightSpace="false"></g-avatar>
                                <span class="md-body-2 grey--text text--darken-2 ml-2 font-weight-medium">{{ statusItem.created_by.name }}</span>
                            </div>

                            <div class="u-flex-center-y" style="marginTop: 2px;">
                                <a-icon size="6" color="grey lighten-1" class="mx-2">fiber_manual_record</a-icon>
                                <g-moment class="md-caption grey--text text--darken-1" convert-local :value="statusItem.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD[,] h:mm A"></g-moment>
                            </div>

                            <div class="u-flex-center-y" style="marginTop: 2px;" v-if="statusItem.visibility === 'external'">
                                <a-icon size="6" color="grey lighten-1" class="mx-2">fiber_manual_record</a-icon>
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a-icon v-on="on" size="16" color="orange darken-2">public</a-icon>
                                    </template>
                                    <span>Visible to external</span>
                                </a-tooltip>
                            </div>

                            <a-spacer></a-spacer>

                            <a-menu bottom offset-y left min-width="120" v-if="statusItem.created_by_id === user_self.id && (permission_status('update') || permission_status('destroy'))">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" class="u-rounded-corners-full pa-1 u-flex-center u-cursor-pointer" v-ripple="true" @click="{}">
                                        <a-icon size="24" color="grey darken-2">more_vert</a-icon>
                                    </div>
                                </template>
                                <a-list class="u-list-condensed u-list-condensed--medium">
                                    <a-list-item @click="localEditStatus(statusItem)" v-if="permission_status('update')">Edit Status</a-list-item>
                                    <a-divider></a-divider>
                                    <a-list-item @click="localDeleteStatus(statusItem)" v-if="permission_status('destroy')" class="deep-orange--text text--darken-2">Delete Status</a-list-item>
                                </a-list>
                            </a-menu>
                        </div>

                        <div class="u-flex-center-y my-3 px-1" v-if="statusItem.type">
                            <a-icon size="12" :color="localGetTypeColor(statusItem.health) + ' darken-2'">fiber_manual_record</a-icon>
                            <span :class="[localGetTypeColor(statusItem.health) + '--text text--darken-2']" class="md-subtitle-1 ml-2 font-weight-medium">{{ statusItem.type.value }}</span>
                        </div>
                        <div class="px-1 mt-3 mb-0 md-body-2 grey--text text--darken-1" style="white-space: pre-wrap;" v-html="statusItem.description"></div>

                        <div v-if="statusItem.comments_count && $can('status_updates.index')" class="mt-5 d-inline-flex align-center mt-0 u-cursor-pointer" @click="localLoadComments(statusItem.id)">
                            <span class="mr-1 md-body-2 grey--text text--darken-2">{{ statusItem.comments_count }} Comment{{ statusItem.comments_count > 1 ? 's' : '' }}</span>
                            <a-icon size="20" color="grey darken-1">keyboard_arrow_{{ localIsExpanded(statusItem.id) ? 'up' : 'down' }}</a-icon>
                        </div>
                    </div>

                    <template v-if="$can('status_updates.comment')">
                        <a-progress-linear v-if="mixinIsLoading('store-' + statusItem.id)" color="indigo darken-2" height="1" indeterminate></a-progress-linear>
                        <a-divider v-else></a-divider>
                    </template>

                    <!-- Reply post input -->
                    <!-- <div class="u-flex align-start u-border pa-2 px-6 pt-4 grey lighten-5 u-rounded-corners-lg"> -->
                    <div style="borderRadius: 0px 0px 8px 8px;" :class="[{ 'c-wiggle-short u-border-error': mixinHasError(statusItem.id + '-comment-store') }]">
                        <div v-if="$can('status_updates.comment')" class="u-flex align-start u-border pa-2 px-6 pt-4 white c-hover-state" :style="[!localIsExpanded(statusItem.id) && !mixinIsLoading(statusItem.id) && !mixinIsLoading('store-' + statusItem.id) ? { borderRadius: '0px 0px 8px 8px' } : {}]" :class="[{ 'grey lighten-4': localIsExpanded(statusItem.id) || currentCommentId === statusItem.id }]">
                            <a-sheet class="c-plain-textarea u-overflow-hidden u-overflow-y u-wfull c-tiny-scroll transparent" max-height="40">
                                <a-tooltip bottom :disabled="!mixinHasError(statusItem.id + '-comment-store')">
                                    <template v-slot:activator="{ on }">
                                        <a-textarea
                                            v-on="on"
                                            v-model="commentModels[statusItem.id]"
                                            placeholder="Add comment..."
                                            rows="1"
                                            row-height="16"
                                            background-color="transparent"
                                            :disabled="mixinIsLoading('store-' + statusItem.id)"
                                            class="ma-0 px-0"
                                            @input="e => !e ? localResetCommentVar(statusItem.id) : ''"
                                            @blur="currentCommentId = null"
                                            @click="currentCommentId = statusItem.id"
                                            @keydown.enter="localAddComment($event, statusItem.id)"
                                            auto-grow hide-details solo flat
                                        ></a-textarea>
                                    </template>
                                    <span>{{ mixinGetError(statusItem.id + '-comment-store') }}</span>
                                </a-tooltip>
                            </a-sheet>
                            <a-spacer></a-spacer>
                            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" class="u-rounded-corners-full ml-1 u-flex-center u-cursor-pointer" @click="mixinIsLoading('store-' + statusItem.id) ? '' : localStoreComment(statusItem.id)" v-ripple="{ class: 'grey--text' }">
                                        <a-progress-circular v-if="mixinIsLoading('store-' + statusItem.id)" color="indigo darken-2" size="20" width="2" indeterminate></a-progress-circular>
                                        <a-icon color="grey darken-1" size="24" v-else>send</a-icon>
                                    </div>
                                </template>
                                <span class="c-mono-font md-caption">Ctrl/Cmd + Enter</span>
                            </a-tooltip>
                        </div>
                    </div>

                    <a-divider key="divider" v-if="localIsExpanded(statusItem.id)"></a-divider>

                    <!-- Replies -->
                    <template v-if="$can('status_updates.index')">
                        <v-slide-y-transition leave-absolute hide-on-leave>
                            <div v-if="localIsExpanded(statusItem.id) && mixinIsLoading(statusItem.id)" style="min-height: 109px;">
                                <div v-for="(count, index) in 1" :key="count">
                                    <a-divider v-if="index !== 0"></a-divider>
                                    <div class="px-6 py-4">
                                        <div class="u-flex-center-y">
                                            <div class="u-flex-center-y">
                                                <loader-template width="40" height="40" class="u-rounded-corners-full"></loader-template>
                                                <loader-template :width="Math.floor(Math.random() * 200) + 100" height="16" class="u-rounded-corners-full ml-3"></loader-template>
                                            </div>
                                            <loader-template :width="80" height="16" class="u-rounded-corners-full ml-3"></loader-template>
                                        </div>
                                        <div class="mt-3">
                                            <loader-template :width="Math.floor(Math.random() * 400) + 200" height="16" class="u-rounded-corners-full"></loader-template>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Replies -->
                            <a-sheet v-if="localIsExpanded(statusItem.id) && !mixinIsLoading(statusItem.id)" max-height="360" class="white py-6 pt-5 u-overflow-y c-tiny-scroll" style="borderRadius: 0px 0px 8px 8px !important;">
                                <div v-for="(comment, index) in statusItem.comments" :key="comment.id">
                                    <a-divider v-if="index !== 0" class="mt-4"></a-divider>
                                    <div class="px-6" :class="{ 'mt-4': index !== 0 }">
                                        <div class="u-flex-center-y">
                                            <div class="u-flex-center-y">
                                                <g-avatar :item="comment.created_by" :size="32" no-shadow :rightSpace="false"></g-avatar>
                                                <span class="md-body-2 grey--text text--darken-2 ml-2 font-weight-medium" v-if="comment.created_by">{{ comment.created_by.name }}</span>
                                            </div>
                                            <div class="u-flex-center-y" style="marginTop: 2px;">
                                                <a-icon size="6" color="grey lighten-1" class="mx-2">fiber_manual_record</a-icon>
                                                <g-moment class="md-caption grey--text text--darken-1" convert-local :value="comment.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD[,] h:mm A"></g-moment>
                                            </div>
                                        </div>
                                        <div class="mt-3 mb-0 md-body-2 grey--text text--darken-1" style="white-space: pre-wrap;" v-html="comment.content"></div>
                                    </div>
                                </div>
                            </a-sheet>
                        </v-slide-y-transition>
                    </template>
                </a-sheet>
            </div>

            <!-- Pagination -->
            <div v-if="localStatusesLength && !mixinIsLoading('root')" class="u-flex-center-y mt-8 u-sticky u-wfull" style="bottom: 0px; left: 0px;">
                <SPagination
                    :pagination="status_update_meta"
                    :hide="!localShowPaginationSection"
                    :loading="mixinIsLoading('root') || mixinIsLoading('pagination')"
                    :class="{ 'pa-4': !localShowPaginationSection }"
                    @paginate="localPaginate"
                />
            </div>
            <a-sheet v-else class="transparent" height="54"></a-sheet>
        </div>

        <a-dialog v-model="dialogEdiStatus" max-width="800">
            <a-card>
                <div class="py-5 px-6 u-flex-center-y">
                    <div class="u-flex-center-y">
                        <a-avatar size="40" color="primary">
                            <a-icon size="24" color="white">drive_file_rename_outline</a-icon>
                        </a-avatar>
                        <h3 class="text-h6 grey--text text--darken-2 ml-3">Update Status</h3>
                    </div>
                    <a-spacer></a-spacer>
                    <a-icon size="20" color="grey darken-1" class="u-cursor-pointer" @click="dialogEdiStatus = false">clear</a-icon>
                </div>
                <a-divider></a-divider>
                <div>
                    <PStatusUpdateCreateForm
                        :editMode="editMode"
                        :editItem="editItem"
                        :isOpen="dialogEdiStatus"
                        :error="mixinGetError('status-edit')"
                        :loading="mixinIsLoading('status-edit')"
                        :typeList="meta_list"
                        editorHeight="200"
                        @store="localUpdateStatus"
                    />
                </div>
            </a-card>
        </a-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import mixinErrors from '@/mixins/mixin-module-error-setup'
import { Validate } from '@/helpers/helper-validations'
import { PStatusUpdateCreateForm } from './Partials'
import { SPagination } from '@/config/config-shared-components'
import { notifier } from '../../helpers/helper-notification'
import EventBus from '@/config/config-eventbus'

export default {
    mixins: [
        mixinLoading,
        mixinErrors
    ],

    components: {
        PStatusUpdateCreateForm,
        SPagination,
    },

    data () {
        return {
            /* Numbers */
            statuses_count: 10,

            /* Boolean */
            editMode: false,
            dialogEdiStatus: false,
            showPagination: false,

            /* Object */
            currentCommentId: null,
            editItem: {},
            healthFilter: { text: 'All Health', value: 'all' },
            typeFilter: { id: 'all', value: 'All Type' },
            visibilityFilter: { text: 'All Visibility', value: 'all' },
            statusUpdate: { health: '', type_id: '', visibility: '', description: '' },
            commentItem: { commentable_type: 'StatusUpdate', commentable_id: '', content: '' },
            statusUpdateIncludes: {
                'include': 'type,commentsCount,createdBy',
                'fields[status_updates]': 'id,type_id,created_by_id,description,health,visibility,project_id,created_at,updated_at',
            },

            /* Array */
            commentModels: [],
            expandedList: [],
            visibilityList: [
                { text: 'All Visibility', value: 'all' },
                { text: 'Internal', value: 'internal' },
                { text: 'External', value: 'external' },
            ],
            healthList: [
                { text: 'Green', value: 'green', color: 'green' },
                { text: 'Yellow', value: 'yellow', color: 'orange' },
                { text: 'Red', value: 'red', color: 'red' },
            ]
        }
    },

    watch: {
        '$route.params.id': {
            handler (val) {
                this.localIndex()
            }
        },
    },

    mounted () {
        this.localIndex()
        EventBus.$on('notification', this.localOnNotification)
    },

    computed: {
        localProjectId () {
            return this.$route.params.id
        },

        localStatusesLength () {
            return this.status_update_list && this.status_update_list.length
        },

        localShowPaginationSection () {
            return this.status_update_meta.current_page >= 1 && this.status_update_meta.last_page !== 1 && this.status_update_meta.total > this.statuses_count
        },

        localFilters () {
            return {
                'filter[project_id]': this.localProjectId,
                'filter[health]': this.healthFilter.value,
                'filter[visibility]': this.visibilityFilter.value,
                'filter[type_id]': this.typeFilter.id,
            }
         },

         localFilterSelected () {
             return this.healthFilter.value !== 'all' || this.typeFilter.id !== 'all' || this.visibilityFilter.value !== 'all'
         },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('ProjectView', {
            project_item: 'item',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
            meta_filters: 'filters',
        }),

        ...mapState('StatusUpdate', {
            status_update_list: 'list',
            status_update_meta: 'meta',
            status_update_response: 'response',
        }),

        ...mapState('Comment', {
            comment_list: 'list',
            comment_item: 'item',
            comment_filters: 'filters',
            comment_response: 'response',
            comment_form_mode: 'form_mode',
        }),
    },

     methods: {
         async localIndex () {
            this.mixinSetLoading('root')
            await this.localGetStatuses()
            await this.localGetTypes()
            this.mixinResetLoading('root')
         },

         async localGetStatuses () {
             await this.status_update_index({
                 page: 1,
                 count: this.statuses_count,
                 ...this.localFilters,
                 ...this.statusUpdateIncludes
            })
         },

         async localGetTypes () {
            setTimeout(async () => {
                await this.meta_clear()
                await this.meta_index( { 'filter[type]': 'status_update_type', 'sort': 'order' } )
            }, 500)
         },

         localSetHealthFilter (filter) {
             if (this.healthFilter.value === filter.value) return
             this.healthFilter = filter
             this.localFilterStatuses()
         },

         localSetTypeFilter (type) {
             if (this.typeFilter.id === type.id) return
             this.typeFilter = type
             this.localFilterStatuses()
         },

         localSetVisibilityFilter (filter) {
             if (this.visibilityFilter.value === filter.value) return
             this.visibilityFilter = filter
             this.localFilterStatuses()
         },

         async localClearFilters () {
            this.mixinSetLoading('status-filter')
            this.healthFilter = { text: 'All Health', value: 'all' }
            this.typeFilter = { id: 'all', value: 'All Type' }
            this.visibilityFilter = { text: 'All Visibility', value: 'all' }
            await this.localGetStatuses()
            this.mixinResetLoading('status-filter')
         },

         async localFilterStatuses () {
             this.mixinSetLoading('status-filter')
             const params = {
                count: this.statuses_count,
                page: 1,
                ...this.localFilters,
                ...this.statusUpdateIncludes
            }
            await this.status_update_index(params)
            this.mixinResetLoading('status-filter')
         },

         /* Status Crud - Start */
         async localPaginate (slug) {
             this.mixinSetLoading('pagination')
             await this.status_update_index({
                count: this.statuses_count,
                page: this.status_update_meta.current_page,
                ...this.localFilters,
                ...this.statusUpdateIncludes
            })
            const currentPage = this.status_update_meta.current_page
            if (currentPage === 1 || slug !== 'from-store') this.$vuetify.goTo(370)
            this.mixinResetLoading('pagination')
         },

        async localStoreStatus (statusUpdate, resetProps) {
            this.mixinClearError('status-create')
            this.mixinSetLoading('status-create')

            statusUpdate = await this.localSetStatusCreateParams(statusUpdate)
            if (this.status_update_meta.current_page === 1) this.status_update_prepend(statusUpdate)
            await this.status_update_store(this.$appendId(statusUpdate))

            if (!this.$status(this.status_update_response)) {
                this.mixinResetLoading('status-edit')
                return this.mixinSetError({ slug: 'status-edit', message: 'Something went wrong. Please try again.' })
            }

            resetProps()
            this.localPaginate('from-store')
            this.$notify('success', 'Status Created Successfully!')
            this.mixinResetLoading('status-create')
        },

        async localSetStatusCreateParams (statusUpdate) {
            const type = this.meta_list.find(item => item.id === statusUpdate.type_id)
            Object.assign(statusUpdate, {
                type,
                created_at: new Date(),
                created_by: this.user_self,
                created_by_id: this.user_self.id,
                project_id: this.localProjectId
            })
            return statusUpdate
        },

        localEditStatus (status) {
            this.dialogEdiStatus = true
            this.editMode = true
            this.editItem = _.cloneDeep(status)
        },

        async localUpdateStatus (statusUpdate, resetProps) {
            this.mixinClearError('status-edit')
            this.mixinSetLoading('status-edit')

            const { message } = new Validate(statusUpdate.description, { silent: true }).length(1, 50000).run()
            if (message) {
                this.mixinResetLoading('status-edit')
                return this.mixinSetError({ slug: 'status-edit', message })
            }

            statusUpdate = await this.localSetStatusEditParams(statusUpdate)
            this.status_update_list_item(statusUpdate)
            await this.status_update_update(statusUpdate)

            if (!this.$status(this.status_update_response)) {
                this.mixinResetLoading('status-edit')
                return this.mixinSetError({ slug: 'status-edit', message: 'Something went wrong. Please try again.' })
            }

            resetProps()
            this.$notify('success', 'Status Updated Successfully!')
            this.localResetEditStatus()
            this.mixinResetLoading('status-edit')
        },

        async localSetStatusEditParams (statusUpdate) {
            const type = this.meta_list.find(item => item.id === statusUpdate.type_id)
            Object.assign(statusUpdate, {
                type,
                updated_at: new Date(),
                updated_by: this.user_self,
                updated_by_id: this.user_self.id,
                project_id: this.localProjectId
            })
            return statusUpdate
        },

        localResetEditStatus () {
            this.dialogEdiStatus = false
            this.editMode = false
            this.editItem = {}
        },

        localDeleteStatus (status) {
            this.mixinSetLoading(status.id)
            this.status_update_remove_item(status)
            this.status_update_destroy(status)
            this.mixinResetLoading(status.id)
            this.$notify('success', 'Status Deleted Successfully!')
        },
        /* Status Crud - End */

        /* Comment Crud - Start */
        localAddComment (evt, status_id) {
            if (evt && (evt.ctrlKey || evt.metaKey)) this.localStoreComment(status_id)
        },

        async localStoreComment (status_id) {
            this.mixinClearError(status_id)
            this.mixinSetLoading('store-' + status_id)

            this.commentItem.content = this.commentModels[status_id]
            const { message } = new Validate(this.commentItem.content, { silent: true }).required().length(1, 5000).run()
            if (message) {
                this.mixinResetLoading('store-' + status_id)
                return this.mixinSetError({ slug: status_id + '-comment-store', message })
            }

            this.localSetCommentParams(status_id)
            this.localAddCommentToStatus(status_id, this.commentItem)
            // this.comment_prepend(this.commentItem)
            await this.comment_store({ ...this.commentItem })
            this.$notify('success', 'Comment Added Successfully!')
            this.localResetCommentVar(status_id)
            this.mixinResetLoading('store-' + status_id)
        },

        async localSetCommentParams (status_id) {
            Object.assign(this.commentItem, {
                commentable_id: status_id,
                created_at: new Date(),
                created_by: this.user_self,
                created_by_id: this.user_self.id,
            })
        },

        localAddCommentToStatus (status_id, comment) {
            const index = this.status_update_list.findIndex(status => status.id === status_id)
            if (index === -1) return

            const statusItem = this.status_update_list[index]
            if (!this.localIsExpanded(status_id)) {
                if (!statusItem.hasOwnProperty('comments')) Object.assign(statusItem, { comments_count: statusItem.comments_count ? statusItem.comments_count + 1 : 1 })
                else statusItem.comments_count += 1

                this.status_update_list_item(statusItem)
                return true
            }

            if (!statusItem.hasOwnProperty('comments')) Object.assign(statusItem, { comments: [comment], comments_count: 1 })
            else {
                statusItem.comments.unshift(comment)
                statusItem.comments_count += 1
            }

            this.status_update_list_item(statusItem)
        },

        localResetCommentVar (status_id, slug = null) {
            if (status_id) this.mixinClearError(slug ?? (status_id + '-comment-store'))
            this.commentItem = { commentable_type: 'StatusUpdate', commentable_id: '', content: '' }
            this.commentModels = []
        },
        /* Comment Crud - End */

        /* Extras */
        localGetTypeColor (health) {
            switch (health) {
                case 'green': return 'green'
                case 'red': return 'red'
                case 'yellow': return 'orange'
                default: return 'grey'
            }
        },

        async localLoadComments (status_id) {
            if (this.localIsExpanded(status_id)) return this.localToggleExpand(status_id)
            this.mixinSetLoading(status_id)
            this.localToggleExpand(status_id)

            await this.comment_index({ include: 'createdBy', sort: '-created_at', 'filter[commentable_id]': status_id, 'filter[commentable_type]': 'StatusUpdate' })

            this.localAddCommentsToStatus(status_id)
            this.comment_clear()
            this.mixinResetLoading(status_id)
        },

        localAddCommentsToStatus (status_id) {
            const index = this.status_update_list.findIndex(status => status.id === status_id)
            if (index === -1) return

            const statusItem = this.status_update_list[index]
            if (!statusItem.hasOwnProperty('comments')) Object.assign(statusItem, { comments: [...this.comment_list] })
            else {
                statusItem.comments = []
                statusItem.comments.unshift(...this.comment_list)
            }
            this.status_update_list_item(statusItem)
        },

         localToggleExpand (id) {
            const index = this.expandedList.indexOf(id)
            if (index !== -1) this.expandedList.splice(index, 1)
            else this.expandedList.push(id)
        },

        localIsExpanded (id) {
            return this.expandedList.includes(id)
        },

        permission_status (type) {
            return this.$can('projects.update-only') && this.$can(`status_updates.${type}`)
        },

        localOnNotification (args) {
            console.log(this.localProjectId, _.get(args, 'data.module.data.id'))
            if (
                (!notifier.action(args?.type).isProjectStatusUpdate() || this.$router.isActive()) &&
                this.localProjectId === _.get(args, 'data.module.data.id')
            ) {
                return
            }

            this.localGetStatuses()
        },

        ...mapActions('ProjectView', {
            project_show: 'show',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),

         ...mapActions('StatusUpdate', {
            status_update_index: 'index',
            status_update_show: 'show',
            status_update_store: 'store',
            status_update_update: 'update',
            status_update_prepend: 'prepend',
            status_update_list_item: 'update_list_item',
            status_update_clear: 'clear',
            status_update_remove_item: 'remove_item',
            status_update_destroy: 'destroy',
            status_update_clear_item: 'clear_item'
        }),

        ...mapActions('Comment', {
            comment_index: 'index',
            comment_show: 'show',
            comment_store: 'store',
            comment_update: 'update',
            comment_prepend: 'prepend',
            comment_select: 'select',
            comment_clear: 'clear',
            comment_destroy: 'destroy',
            comment_clear_item: 'clear_item',
        }),
    }
}
</script>

<style lang="scss" scoped></style>

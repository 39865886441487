<template>
    <div class="mt-5">
        <g-settings-breadcrumb></g-settings-breadcrumb>
        <div class="u-flex u-wfull mt-6">
            <div class="u-sticky" style="shrink: 0; top: 88px; width: 288px;">
                <div class="mb-4">
                    <p style="letter-spacing: 0.3px;" class="md-subtitle-1 font-weight-medium text-uppercase grey--text text--darken-1 mb-3">Pick List</p>
                    <div class="transparent d-block">
                        <ul class="c-sidebar-menu pl-0">
                            <template v-for="menu in localMenuItems">
                                <li
                                    :key="menu.routeName"
                                    class="u-flex align-center pl-4 py-2 u-cursor-pointer"
                                    :class="{ 'grey lighten-2 c-link-active': $route.name === menu.routeName }"
                                    @click="$router.push({ name: menu.routeName })"
                                    style="--hover-bg: #e8e8e8"
                                >
                                    <span :class="[$route.name === menu.routeName ? 'grey--text text--darken-4 font-weight-medium' : 'grey--text text--darken-2']" class="md-subtitle-2 text-capitalize d-inline-block text-truncate">{{ menu.label }}</span>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="u-wfull pl-8" style="grow: 1;">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted () {
        this.localIndex()
    },

    computed: {
        localMenuItems () {
            const list = [
                { label: 'Test Case Statuses', routeName: 'settings-metas-testcases', show: true },
                { label: 'Success Criteria Statuses', routeName: 'settings-metas-goals', show: true },
                { label: 'Task Statuses', routeName: 'settings-metas-tasks', show: true },
                { label: 'Note Types', routeName: 'settings-metas-notes', show: true },
                { label: 'Comment Types', routeName: 'settings-metas-comments', show: true },
                { label: 'Project Results', routeName: 'settings-metas-results', show: true },
                { label: 'Collaborator Types', routeName: 'settings-metas-collaborators', show: true },
                { label: 'Ticket Types', routeName: 'settings-metas-ticket-type', show: true },
                { label: 'Ticket Statuses', routeName: 'settings-metas-ticket-status', show: true },
                { label: 'Ticket Priority', routeName: 'settings-metas-ticket-priority', show: true },
                { label: 'Competitor Types', routeName: 'settings-metas-competitor-type', show: true },
            ]
            return list.filter(i => i.show)
        },
    },

     methods: {
         async localIndex () {
            if (!this.$router.route.isSettingsMetasTestcases()) {
                this.$router.push({ name: 'settings-metas-testcases' })
            }
         }
    }
}
</script>

<style lang="scss" scoped>
.c-sidebar-menu {
    li {
        position: relative;
        transition: 0.2s all;
        &:hover {
            background-color: var(--hover-bg);
            transition: 0.2s all;
        }
        &--active {
            background-color: var(--active-bg) !important;
            transition: 0.2s all;
        }
        &::before {
            content: '';
            height: 100%;
            width: 1px;
            background-color: #e0e0e0;
            position: absolute;
            left: 0px;
            opacity: 1;
        }
    }
    .c-link-active {
        &::before {
            content: '';
            background-color: #000 !important;
            height: 100%;
            width: 2px;
            background-color: #e0e0e0;
            position: absolute;
            left: 0px;
            opacity: 1;
        }
    }
}
</style>

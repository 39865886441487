<template>
    <a-main>
        <template v-if="isLoading">
           <PAutomationWorkflowActionLoader/>
        </template>
        <template v-if="!isLoading">
            <div class="u-flex pa-6 u-relative">
                <div class="u-wfull c-action-icon-view">
                    <div class="align-center justify-center u-flex c-action-menu-icon purple darken-1" style="border: 2px solid #E1BEE7 !important">
                        <a-icon color="white" size="20">settings_suggest</a-icon>
                    </div>
                </div>
                <div class="ml-3">
                    <p class="md-subtitle-1 text-uppercase indigo--text text--darken-1 mb-0 font-weight-bold">Then: Action</p>
                    <p class="md-body-2 grey--text text--darken-1 mb-0">Action executes the changes to the system.</p>
                </div>
            </div>
            <a-divider class="grey lighten-3"></a-divider>
            <a-container container--fluid grid-list-xl class="u-cursor-pointer pa-6" v-if="!isLoading && actionList && actionList.length === 0">
                <a-layout align-center mt-0>
                    <a-flex xs6 xl6 pt-0 v-for="(item, index) in triggerList" :key="'div_'+index" :class="[canUpdate && localCheckActionSlug(item.slug) ? 'u-cursor-pointer' : 'u-opacity-40']" @click="canUpdate && localCheckActionSlug(item.slug) ? localAddAction(item.slug) : null">
                        <div class="grey pa-4 lighten-5 u-flex u-rounded-corners-xl"  style="border: 1px solid #EEEEEE !important;">
                            <div class="mr-2">
                                <a-icon color="grey" size="30" class="u-icon-nudge-xs">{{ item.icon }}</a-icon>
                            </div>
                            <div>
                                <p class="grey--text text--darken-1 md-subtitle-1 mb-0 font-weight-bold">{{ item.title }}</p>
                                <p class="md-caption grey--text mb-0 mt-2 font-weight-medium" style="font-size: 14px;">{{ item.description }}</p>
                                <p class="indigo--text text--darken-1 md-subtitle-2 mb-0 mt-5 font-weight-medium">Add Action
                                    <a-icon size="14" color="indigo" style="font-weight: 700; top: 0px;" class="u-icon-nudge-xs">arrow_forward</a-icon>
                                </p>
                            </div>
                        </div>
                    </a-flex>
                </a-layout>
            </a-container>
            <a-container container--fluid grid-list-xl pa-0 v-if="localActionList && localActionList.length">
                <div>
                    <div class="px-6 pt-6" v-if="isError">
                        <PAutomationWorkflowWarningInfo
                            :errorMessage="errorMessage"
                        />
                    </div>
                    <draggable v-model="localActionList" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                        <div class="u-rounded-corners-xl" :class="[canUpdate ? 'js-draggable-file-list-item' : '']"  v-for="(item, index) in localActionList" :key="index" :id="('action-'+item.id)">
                           <div class="js-drag-handle u-flex pa-6 u-relative c-action-section_hover" :class="[canUpdate ? 'u-cursor-pointer': '']" v-if="!localAccordionActionStatus(index)" @click="localAccordionActionToggle(index)">
                                <div class="c-action-drag-handle" v-if="canUpdate">
                                    <a-icon size="18" color="grey">drag_indicator</a-icon>
                                </div>
                                <p class="grey--text text--darken-3 mb-0" style="font-size: 16px; font-weight: 600;">
                                    <a-tooltip bottom v-if="!item.warning">
                                        <template v-slot:activator="{ on }">
                                            <a-icon size="20" v-on="on" color="grey darken-3" class="mr-1 u-icon-nudge-xs">{{localGetIcon(item.slug)}}</a-icon>
                                        </template>
                                        <span>{{ localActionTitle(item.slug) }}</span>
                                    </a-tooltip>
                                    <a-tooltip bottom v-else>
                                        <template v-slot:activator="{ on }">
                                            <a-icon v-on="on" color="amber darken-3" size="20" class="mr-1 u-icon-nudge-xs">info</a-icon>
                                        </template>
                                        <span>Invalid action</span>
                                    </a-tooltip>
                                </p>
                                <p v-if="!item.action_type" class="grey--text text--darken-2 mb-0 ml-2" style="font-size: 16px;">
                                    Choose the action type
                                </p>
                                <template v-if="!localCheckActionType(item)">
                                    <p class="grey--text text--darken-2 mb-0 ml-2" style="font-size: 16px;">
                                        <span v-if="item.field_name"> {{ localGetActionTypeName(item.action_type, item.slug) }} </span>
                                        <span v-if="item.field_name">the</span>
                                        <span v-if="item.slug !== 'collaborators' && item.field_type === 'multi-select' && item.action_type === 'remove'" class="ml-1">selected</span>
                                        <span v-if="item.slug === 'collaborators' && item.field_name" class="mx-1">selected collaborators {{ item.action_type === 'add' ? 'to': 'from'}}</span>
                                        <span v-if="item.slug === 'collaborators' && !item.field_name">Choose a field to {{ item.action_type }} selected collaborators {{ item.action_type === 'add' ? 'to': 'from'}} it</span>
                                        <span v-else :class="[item.field_name ? 'ml-1' : '']">{{ item.field_name ? localGetFieldValue(item.field_name, 'label') : 'Choose a field to' }}</span>
                                        <span v-if="item.slug !== 'collaborators' && !item.field_name" class="ml-1">{{ item.action_type }}</span>
                                        <template v-if="item.field_name && item.action_type === 'modify'">
                                            <span class="ml-1" v-if="item.field_type === 'multi-select'">and add</span>
                                            <span class="ml-1" v-else>to</span>
                                        </template>
                                    </p>
                                    <p class="grey--text text--darken-2 mb-0 ml-1" style="font-size: 16px; font-weight: 500;" :title="item.value ? item.value : null">
                                        <span v-if="['date','string','url','dynamic_date','number'].includes(item.field_type) && item.value">
                                            <g-moment v-if="item.field_type === 'date'" tag="span" :value="item.value"  input-format="YYYY-MM-DD" output-format="MMM DD, YYYY" convert-local></g-moment>
                                            <span v-else>{{ item.value | truncateText(70) }}</span>
                                        </span>
                                        <template v-if="item.source_value && item.source_value.length > 1">
                                            <a-tooltip bottom max-width="660">
                                                <template v-slot:activator="{ on }">
                                                    <span class="u-underline" v-on="on">{{ item.source_value.length }} {{ actionTagSlug.includes(item.source_field) ? 'Tags' : item.source_field }}</span>
                                                </template>
                                                <div class="pa-1 md-body-2">
                                                    <span v-for="(data, index) in localGetMultipleSelectedList(item)" :key="index" class="mb-1 pr-1">
                                                        {{ data.name }},
                                                    </span>
                                                </div>
                                            </a-tooltip>
                                        </template>
                                        <span v-if="item.source_value && item.source_value.length === 1 || item.source_id">
                                            {{ localFindSelectedItem(item) }}
                                        </span>
                                    </p>
                                </template>
                                <p class="grey--text text--darken-2 mb-0 ml-2" style="font-size: 16px;" v-if="item.action_type === 'remove' && item.is_action === 1">
                                    {{ item.action_type ? localGetActionTypeName(item.action_type, item.slug) : 'Choose the type' }} all the <span v-if="item.slug === 'collaborators'">collaborators from</span> {{ item.field_name ? localGetFieldValue(item.field_name, 'label') : 'Choose a field' }}
                                </p>
                                <a-spacer></a-spacer>
                                <template v-if="canUpdate">
                                    <div class="mr-4 u-cursor-pointer" @click="localAccordionActionToggle(index)">
                                        <a-icon size="24" color="grey" class="u-icon-nudge-xs">drive_file_rename_outline</a-icon>
                                    </div>
                                    <a-menu v-model="actionTypeModel['delete_'+item.id]" bottom left offset-y max-width="176" :close-on-content-click="true">
                                        <template v-slot:activator="{ on }">
                                            <a-icon size="24" :color="actionTypeModel['delete_'+item.id] ? 'red darken-1' : 'grey'" class="ml-3" v-on="on">remove_circle_outline</a-icon>
                                        </template>
                                        <a-card flat>
                                            <a-card-text class="pa-0">
                                                <div class="pa-3">
                                                    <p class="md-body-2 mb-0 text-center grey--text text--darken-3">Action will be deleted. This cannot be reversed.</p>
                                                </div>
                                                <a-btn style="border-radius: revert;" color="red darken-1" class="elevation-0 ma-0" block dark small @click="localRemoveAction(index, item.id)">Confirm Delete</a-btn>
                                            </a-card-text>
                                        </a-card>
                                    </a-menu>
                                </template>
                           </div>
                            <div class="u-flex pa-6 u-relative c-action-section_hover" v-if="localAccordionActionStatus(index)"  @click="localAccordionActionToggle(index)" :class="[canUpdate ? 'u-cursor-pointer': '']">
                                <div class="c-action-drag-handle" v-if="canUpdate">
                                    <a-icon size="18" color="grey darken-1">drag_indicator</a-icon>
                                </div>
                                <div class="u-flex">
                                    <p class="md-subtitle-1 indigo--text text--darken-3 mb-0 font-weight-medium">
                                        <a-icon size="20" color="indigo darken-1" class="mr-1 u-icon-nudge-xs">{{localGetIcon(item.slug)}}</a-icon> {{ localActionTitle(item.slug) }}
                                    </p>
                                </div>
                                <a-spacer></a-spacer>
                                <template v-if="canUpdate">
                                    <div class="u-cursor-pointer mr-4">
                                        <a-icon size="24" color="grey darken-2" class="u-icon-nudge-xs">drive_file_rename_outline</a-icon>
                                    </div>
                                    <a-menu v-model="actionTypeModel['delete_'+item.id]" bottom left offset-y max-width="176" :close-on-content-click="true">
                                        <template v-slot:activator="{ on }">
                                            <a-icon size="24" :color="actionTypeModel['delete_'+item.id] ? 'red darken-1' : 'grey'" class="ml-3" v-on="on">remove_circle_outline</a-icon>
                                        </template>
                                        <a-card flat>
                                            <a-card-text class="pa-0">
                                                <div class="pa-3">
                                                    <p class="md-body-2 mb-0 text-center grey--text text--darken-3">Action will be deleted. This cannot be reversed.</p>
                                                </div>
                                                <a-btn style="border-radius: revert;" color="red darken-1" class="elevation-0 ma-0" block dark small @click="localRemoveAction(index, item.id)">Confirm Delete</a-btn>
                                            </a-card-text>
                                        </a-card>
                                    </a-menu>
                                </template>
                            </div>
                            <template v-if="localAccordionActionStatus(index)">
                                <div class="px-6 pb-6">
                                    <div class="u-flex">
                                        <a-menu bottom v-model="actionTypeModel['action_'+item.id]" offset-y content-class="white u-shadow-md u-z16" :close-on-content-click="true" max-width="150" min-width="150">
                                            <template v-slot:activator="{ on }">
                                                <p :class="[actionTypeModel['action_'+item.id] ? 'grey lighten-5' : 'u-underline', item.action_type ? 'grey--text text--darken-2' : 'grey--text text--darken-1']" class="md-subtitle-2 u-rounded-corners d-inline-block mb-0 pa-1 u-cursor-pointer u-font-weight-semibold" v-on="on">
                                                    {{ item.action_type ? localGetActionTypeName(item.action_type, item.slug) : 'Choose the type' }}
                                                    <a-icon size="20" :color="item.action_type ? 'grey darken-1' : 'grey'" class="u-icon-nudge-xs">expand_more</a-icon>
                                                </p>
                                            </template>
                                            <a-sheet @blur="menuModal = false" tabindex="0" style="outline: none;" class="c-dropdown-scroll-parent u-rounded-corners-lg u-wfull">
                                                <ul tabindex="0" class="c-custom-dropdown u-hfull pa-3 c-tiny-scroll u-overflow-y" style="max-height: 250px;">
                                                    <template v-for="(action) in localActionTypesCheck(item.slug)">
                                                        <li :key="action.name"  tabindex="0"  class="pa-2 u-rounded-corners md-subtitle-1 u-cursor-pointer c-hover-state-light" :class="[item.action_type === action.name ? 'grey lighten-4' : '']" @click="$emit('update-action-type', item, action.name)" style="line-height: 0 !important;">
                                                            <span class="md-body-2 text-truncate d-inline-block" :class="[item.action_type === action.name ? 'grey--text text--darken-2' : 'grey--text text--darken-1']" style="font-size: 14px;"><a-icon size="20" :color="item.action_type === action.name ? 'grey darken-2' : 'grey'" class="u-icon-nudge mr-1">{{ action.icon }}</a-icon>{{ action.label }}</span>
                                                        </li>
                                                    </template>
                                                </ul>
                                            </a-sheet>
                                        </a-menu>
                                        <template v-if="item.action_type">
                                            <p class="md-subtitle-2 grey--text text--darken-2 mb-0 pa-1 c-action-section-text" style="font-size: 16px; font-weight: 500;">the
                                                <span v-if="item.slug !== 'collaborators' && item.field_type === 'multi-select' && item.action_type === 'remove'">selected</span>
                                                <span v-if="item.slug === 'collaborators'">selected collaborators {{ item.action_type === 'add' ? 'to': 'from'}}</span>
                                            </p>
                                            <a-menu bottom v-model="fieldTypeModel['field_'+item.id]" content-class="white u-shadow-md u-z16" offset-y :close-on-content-click="true" max-width="264" min-width="264">
                                                <template v-slot:activator="{ on }">
                                                    <p :class="[fieldTypeModel['field_'+item.id] ? 'grey lighten-5' : 'u-underline', item.field_name ? 'grey--text text--darken-2' : 'grey--text text--darken-1']" class="md-subtitle-2 u-rounded-corners d-inline-block mb-0 pa-1 u-cursor-pointer u-font-weight-semibold" v-on="on">
                                                        {{ item.field_name ? localGetFieldValue(item.field_name, 'label') : 'Choose a field' }}
                                                        <a-icon size="20" :color="item.field_name ? 'grey darken-1' : 'grey'" class="u-icon-nudge-xs">expand_more</a-icon>
                                                    </p>
                                                </template>
                                                <a-sheet @blur="menuModal = false" tabindex="0" style="outline: none;" class="c-dropdown-scroll-parent u-rounded-corners-lg u-wfull">
                                                    <ul tabindex="0" class="c-custom-dropdown u-hfull pa-2 c-tiny-scroll u-overflow-y" style="max-height: 250px;">
                                                        <template v-for="(action) in localFilterActionEntityList(item)">
                                                            <li :key="action.name"  tabindex="0"  class="pa-3 u-rounded-corners md-subtitle-1 u-cursor-pointer c-hover-state-light mb-0" @click="$emit('update-field', item, action.name)" :class="[item.field_name === action.name ? 'grey lighten-4' : '']" style="line-height: 0 !important;">
                                                                <span class="md-body-2 text-truncate d-inline-block grey--text" :class="[item.field_name === action.name ? 'grey--text text--darken-2' : 'grey--text text--darken-1']" style="font-size: 14px;"><a-icon size="20" :color="item.field_name === action.name ? 'grey darken-2' : 'grey'" class="u-icon-nudge mr-2">{{ action.field_icon }}</a-icon>{{ action.label }}</span>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </a-sheet>
                                            </a-menu>
                                        </template>
                                        <p class="md-subtitle-2 grey--text text--darken-2 mb-0 pa-1 c-action-section-text" style="font-size: 16px; font-weight: 500;" v-if="item.action_type !== 'remove' && item.is_action === 0 || item.field_type === 'multi-select'">
                                            <template v-if="item.action_type === 'modify'">
                                                <span v-if="item.field_type === 'multi-select'">and add</span>
                                                <span v-else>to</span>
                                            </template>
                                        </p>
                                        <a-spacer></a-spacer>
                                    </div>
                                    <div class="mb-2" v-if="localAccordionActionStatus(index)" :class="[item.field_name !== 'stage_id' ? 'mt-4': 'mt-2']">
                                        <template v-if="['modify', 'add'].includes(item.action_type) && item.is_action === 0 || item.action_type === 'remove' && item.field_type === 'multi-select' && item.is_action === 0">
                                            <template v-if="localGetFieldValue(item.field_name, 'type') === 'multi-select'">
                                                <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(item, 'source_value')">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <a-autocomplete
                                                                v-model="item.source_value"
                                                                :items="localGetActionValueList(item.field_name)"
                                                                item-text="name"
                                                                item-value="id"
                                                                :class="[localIsCheckErrors(item, 'source_value') ? 'c-wiggle-short u-border-error' : 'u-border']"
                                                                class="pa-0 u-rounded-corners-lg"
                                                                placeholder="Select value"
                                                                background-color="grey lighten-5"
                                                                :disabled="!canUpdate"
                                                                @change="$emit('change-value')"
                                                                solo flat hide-details clearable multiple deletable-chips small-chips auto-select-first hide-selected
                                                            >
                                                                <template #item="{ item }">
                                                                    <template v-if="item.type === 'competitor'">
                                                                        <span class="md-caption d-inline-block py-1 px-2 mr-2 mt-1 mb-1 u-rounded-corners u-cursor-pointer" :style="{backgroundColor: '#2196f3', color: '#fff'}">
                                                                            {{ item.name | truncateText(70) }}
                                                                        </span>
                                                                    </template>
                                                                    <template v-else-if="item.type === 'tags' || item.type === 'territory'">
                                                                        <g-tags :text-range="20" :tag="item" :key="item.id" hide-clear-icon></g-tags>
                                                                    </template>
                                                                    <template v-else-if="item.initial && item.role_id">
                                                                        <div class="u-flex-center-y">
                                                                            <g-avatar :item="item" :size="32"></g-avatar>
                                                                            <g-profile-info :item="item" set-max-width type-user></g-profile-info>
                                                                        </div>
                                                                    </template>
                                                                    <span :title="item.name" v-else>{{ item.name | truncateText(70) }}</span>
                                                                </template>
                                                                <template #selection="{ item, parent }">
                                                                    <template v-if="item.type === 'competitor'">
                                                                        <span class="md-body-2 d-inline-flex align-center pl-3 pr-2 py-1 mr-2 my-2 u-rounded-corners blue white--text">
                                                                            <span class="mr-2 md-body-2 white--text" :title="item.name">{{ item.name | truncateText(20) }}</span>
                                                                            <a-icon v-if="canUpdate" size="16" @click="parent.selectItem(item)" class="u-cursor-pointer white--text">cancel</a-icon>
                                                                        </span>
                                                                    </template>
                                                                    <template  v-else-if="item.initial && item.role_id">
                                                                        <span class="d-inline-flex align-center u-rounded-corners grey lighten-2 pl-3 pr-2 py-1 mr-2 my-2">
                                                                            <span class="mr-2 md-body-2 grey--text text--darken-4" :title="item.name">{{ item.name | truncateText(20) }}</span>
                                                                            <a-icon size="16" v-if="canUpdate" @click="parent.selectItem(item)" class="u-cursor-pointer">cancel</a-icon>
                                                                        </span>
                                                                    </template>
                                                                    <template v-else-if="item.type === 'tags' || item.type === 'territory'">
                                                                        <g-tags :tag="item" @destroy="() => parent.selectItem(item)" :hide-clear-icon="!canUpdate" :key="item.id"></g-tags>
                                                                    </template>
                                                                    <span :title="item.name" v-else>{{ item.name | truncateText(70) }}</span>
                                                                </template>
                                                            </a-autocomplete>
                                                        </div>
                                                    </template>
                                                    <span>{{ localGetMessageErrors(item, 'source_value') }}</span>
                                                </a-tooltip>
                                            </template>
                                            <template v-else-if="localGetFieldValue(item.field_name, 'type') === 'select'">
                                                <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(item, 'source_id')">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <a-autocomplete
                                                                v-model="item.source_id"
                                                                :items="localGetActionValueList(item.field_name, item.meta)"
                                                                item-text="name"
                                                                item-value="id"
                                                                placeholder="Select value"
                                                                class="pa-0 u-rounded-corners-lg"
                                                                :class="[localIsCheckErrors(item, 'source_id') ? 'c-wiggle-short u-border-error' : 'u-border']"
                                                                background-color="grey lighten-5"
                                                                @change="$emit('change-value', item)"
                                                                :disabled="!canUpdate"
                                                                solo flat hide-details
                                                            >
                                                                <template #item="{ item }">
                                                                    <template v-if="item.type === 'competitor'">
                                                                        <span class="md-caption d-inline-block py-1 px-2 mr-2 mt-1 mb-1 u-rounded-corners u-cursor-pointer" :style="{backgroundColor: '#2196f3', color: '#fff'}">
                                                                            {{ item.name | truncateText(70) }}
                                                                        </span>
                                                                    </template>
                                                                    <template v-else>
                                                                        <span :title="item.name">{{ item.name | truncateText(70) }}</span>
                                                                    </template>
                                                                </template>
                                                                <template #selection="{ item }">
                                                                    <template v-if="item.type === 'competitor'">
                                                                        <span class="md-caption d-inline-block py-1 px-2 mr-2 mt-1 mb-1 u-rounded-corners u-cursor-pointer" :style="{backgroundColor: '#2196f3', color: '#fff'}">
                                                                            {{ item.name | truncateText(70) }}
                                                                        </span>
                                                                    </template>
                                                                    <template v-else>
                                                                        <span :title="item.name">{{ item.name | truncateText(70) }}</span>
                                                                    </template>
                                                                </template>
                                                            </a-autocomplete>
                                                        </div>
                                                    </template>
                                                    <span>{{ localGetMessageErrors(item, 'source_id') }}</span>
                                                </a-tooltip>
                                            </template>
                                            <template v-else-if="['date', 'dynamic_date'].includes(localGetFieldValue(item.field_name, 'type'))">
                                                <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(item, 'value')">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <div v-if="item.field_type === 'dynamic_date'" class="c-custom-automation-append-outer">
                                                                <v-text-field
                                                                    v-model="item.value"
                                                                    placeholder="Choose a dynamic date variable..."
                                                                    :class="[localIsCheckErrors(item, 'value') ? 'c-wiggle-short u-border-error' : 'u-border']"
                                                                    background-color="grey lighten-5"
                                                                    class="pa-0 u-rounded-corners-lg"
                                                                    :disabled="!canUpdate"
                                                                    solo flat hide-details
                                                                    @input="$emit('change-value')"
                                                                    @click="!item.value ? localUpdateDynamicVariable(item) : null"
                                                                >
                                                                    <template slot="append">
                                                                        <div class="u-cursor-pointer c-dynamic-append px-3 py-2" @click="localUpdateDynamicVariable(item)">
                                                                            <p class="md-subtitle-2 mb-0" :class="[canUpdate ? 'indigo--text text--darken-1' : 'grey--text text--darken-1']">
                                                                                <a-icon size="20" :color="canUpdate ? 'indigo darken-1' : 'grey darken-1'" class="u-icon-nudge">wysiwyg</a-icon>
                                                                                View variables
                                                                            </p>
                                                                        </div>
                                                                    </template>
                                                                    <template slot="prepend" ma-0>
                                                                        <div class="u-dynamic-prepend grey lighten-5" style="margin-top: 0; border-right: 1px solid #eeee !important;">
                                                                            <a-icon size="24" color="grey darken-1">integration_instructions</a-icon>
                                                                        </div>
                                                                    </template>
                                                                </v-text-field>
                                                            </div>
                                                            <div v-else>
                                                                <a-sheet class="u-wfull u-rounded-corners-lg u-flex-center-y u-relative pr-2 mr-2" :class="[localIsCheckErrors(item, 'value') ? 'c-wiggle-short u-border-error' : 'u-border']" style="background: #FAFAFA;">
                                                                    <div class="u-dynamic-prepend grey lighten-5" style="margin-top: 0; border-right: 1px solid #eeee !important;">
                                                                        <a-icon size="24" color="grey darken-1">calendar_month</a-icon>
                                                                    </div>
                                                                    <vc-date-picker v-if="canUpdate" v-model="item.value" @input="$emit('change-value')" class="d-block u-wfull" :model-config="modelConfig" :is-required="!!(item.value)">
                                                                        <template v-slot="{ togglePopover }">
                                                                            <div v-on="on" class="u-wfull u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="togglePopover({ placement: 'bottom-start' })">
                                                                                <div class="md-body-2 grey--text pl-3 u-flex-center-y" :class="[item.value ? 'text--darken-4' : 'text--darken-5']" style="font-size: 16px; min-height: 48px !important;">
                                                                                    <span>{{ item.value ? localFormatDate(item.value) : 'Select a static date...' }}</span>
                                                                                </div>
                                                                            </div>
                                                                        </template>
                                                                    </vc-date-picker>
                                                                    <div v-else class="u-wfull u-flex align-stretch u-rounded-corners-lg grey--text text--darken-2" style="opacity: 0.5;">
                                                                        <div class="md-body-2 grey--text pl-3 u-flex-center-y" :class="[item.value ? 'text--darken-4' : 'text--darken-5']" style="font-size: 16px; min-height: 48px !important;">
                                                                            <span>{{ item.value ? localFormatDate(item.value) : 'Select a static date...' }}</span>
                                                                        </div>
                                                                    </div>
                                                                    <a-spacer></a-spacer>
                                                                    <a-icon size="20" v-if="item.value" color="grey darken-1" class="u-cursor-pointer pr-2" @click="$emit('clear-date', item)">cancel</a-icon>
                                                                </a-sheet>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <span>{{ localGetMessageErrors(item, 'value') }}</span>
                                                </a-tooltip>
                                                <div v-if="canUpdate" class="text-end">
                                                    <p v-if="item.field_type === 'dynamic_date'" @click="localUpdateDateType(item)" class="ml-3 u-cursor-pointer md-caption blue--text mb-0 mt-2 font-weight-medium d-inline-block" style="font-size: 14px;">
                                                        or select a static date
                                                    </p>
                                                    <p v-if="item.field_type === 'date'" @click="localUpdateDateType(item)" class="ml-3 u-cursor-pointer md-caption blue--text mb-0 mt-2 font-weight-medium d-inline-block" style="font-size: 14px;">
                                                        or choose a dynamic date variable
                                                    </p>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(item, 'value')">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <a-text-field
                                                                v-model="item.value"
                                                                placeholder="Enter some value"
                                                                @input="$emit('change-value')"
                                                                :class="[localIsCheckErrors(item, 'value') ? 'c-wiggle-short u-border-error' : 'u-border']"
                                                                background-color="grey lighten-5"
                                                                class="pa-0 u-rounded-corners-lg"
                                                                :disabled="!canUpdate"
                                                                solo flat hide-details
                                                            >
                                                            </a-text-field>
                                                        </div>
                                                    </template>
                                                    <span>{{ localGetMessageErrors(item, 'value') }}</span>
                                                </a-tooltip>
                                            </template>
                                        </template>
                                        <div v-if="item.action_type === 'remove' && item.field_name && item.is_action === 1" class="mt-4 py-4 px-3 amber lighten-5 u-rounded-corners-xl" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04);">
                                            <p class="amber--text text--darken-2 mb-0 c-remove-field">
                                                <a-icon size="20" color="amber darken-4" class="u-icon-nudge-xs mr-1" style="top: -3px;">warning</a-icon>
                                                Enabling the checkbox will clear all the {{ localGetFieldValue(item.field_name, 'label') }}.
                                            </p>
                                        </div>
                                        <div class="u-flex d-inline-flex mt-4 u-cursor-pointer" v-if="['multi-select'].includes(item.field_type) && item.action_type === 'remove' && item.field_name" @click="$emit('update-is-action', item)">
                                            <a-checkbox
                                                color="blue darken-2"
                                                class="ma-0 pa-0"
                                                :value="localCheckIsAction(item.is_action)"
                                                hide-details dense small
                                            >
                                            </a-checkbox>
                                            <p class="d-inline-flex md-subtitle-2 d-inline-block grey--text text--darken-2 mb-0">Clear all {{ localGetFieldValue(item.field_name, 'label') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <a-divider></a-divider>
                        </div>
                    </draggable>
                </div>
                <div class="pa-6" v-if="canUpdate" ref="refAddAction">
                    <div class="u-flex">
                        <a-menu :content-class="actionDropdown ? 'c-menu-content-active' : ''" transition="slide-y-transition" bottom right offset-y v-model="actionDropdown" :close-on-content-click="true" max-width="400" min-width="400" style="z-index: 2000000;">
                            <template v-slot:activator="{ on }">
                                <a-btn depressed small rounded class="u-flex-center primary lighten-1 white--text" v-on="on">
                                    <a-icon size="16" color="white" class="u-icon-nudge-xs mr-1">add</a-icon>
                                    <span>Add Action</span>
                                    <a-icon size="18" color="white" class="u-icon-nudge-xs ml-1">expand_{{ actionDropdown ? 'less': 'more' }}</a-icon>
                                </a-btn>
                            </template>
                            <div class="u-wfull white pa-0 u-rounded-corners-xl">
                                <template v-for="(item, index) in triggerList">
                                    <div class="u-flex py-5 px-5" :class="[localCheckActionSlug(item.slug) ? 'u-cursor-pointer' : 'u-opacity-40']"  :key="'div_'+index" @click="localCheckActionSlug(item.slug) ? localAddAction(item.slug) : null">
                                        <div class="align-center justify-center grey lighten-4 u-flex c-action-icon">
                                            <a-icon size="18" color="grey darken-1">{{ item.icon }}</a-icon>
                                        </div>
                                        <div class="ml-3">
                                            <p class="md-subtitle-2 grey--text text--darken-3 mb-0 u-font-semibold">{{ item.title }}</p>
                                            <p class="md-caption grey--text mb-0 font-weight-medium" style="font-size: 14px;">{{ item.subtitle }}</p>
                                        </div>
                                    </div>
                                    <a-divider :key="'divider_'+index"></a-divider>
                                </template>
                            </div>
                        </a-menu>
                    </div>
                </div>
            </a-container>
        </template>

        <!-- Dynamic Date Variables Popup -->
        <a-dialog v-model="dataDynamicDateVarDialog" persistent scrollable max-width="800">
            <ModalDynamicDateVariables
                :dateVariables="localDateVariables"
                :updateDateVariable="localUpdateDateVariable"
                :close="localCloseDateVarDialog"
            />
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState } from 'vuex'
import PAutomationWorkflowWarningInfo from './PartialAutomationWorkflowWarningInfo'
import PAutomationWorkflowActionLoader from './PartialAutomationWorkflowActionLoader'
import { dateVariables } from '../Variables/dynamic-variables'
import ModalDynamicDateVariables from '../Modals/ModalDynamicDateVariables'


export default {
    components: {
        PAutomationWorkflowWarningInfo,
        PAutomationWorkflowActionLoader,
        ModalDynamicDateVariables,
    },
    props: {
        triggerList: {
            type: Array,
            default: [],
        },
        customTagList: {
            type: Array,
            default: [],
        },
        systemTagList: {
            type: Array,
            default: [],
        },
        actionList: {
            type: Array,
            default: [],
        },
        actionEntityList: {
            type: Array,
            default: [],
        },
        canUpdate:{
            type: Boolean,
            default: true
        },
        isLoading:{
            type: Boolean,
            default: false
        },
        isError: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            valueFieldMaxLength: -1,
            valueAltFieldMaxLength: -1,
            snackbarTimeout: 4000,
            snackbarText: '',
            localSelectedAction: null,
            localActionRemoveId : null,
            actionDeleteTimeout: null,
            errorMessage: 'There is an issue with the action. Please reconfigure it.',
            actionDropdown: false,
            isWorkspaceUpdate: false,
            dataDynamicDateVarDialog: false,
            snackbarStatus: false,
            actionTypeModel:{},
            fieldTypeModel:{},
            accordionAction: [],
            accordionValueDate: [],
            localDateVariables : dateVariables,
            triggerIcon: [
                { slug:'project', icon: 'vertical_split'},
                { slug:'stage', icon: 'label_important'},
                { slug:'collaborators', icon: 'people_alt'},
            ],
            actionProjectTypes: [
                { name:'modify', label: 'Modify', icon: 'edit_note' },
                { name:'remove', label: 'Remove', icon: 'playlist_remove'},
            ],
            actionCollaboratorTypes: [
                { name:'add', label: 'Add', icon: 'edit_note' },
                { name:'remove', label: 'Remove', icon: 'playlist_remove'},
            ],
            actionTagSlug: ['project_label','project_product','project_platform', 'custom_tag'],
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
            attributes: {
                popover: {
                    visibility: 'hover',
                    isInteractive: true,
                },
            },
        }
    },

    mounted () {
        document.addEventListener('keyup', this.localClosedataDynamicDateVarDialog)
    },

    computed: {
        localActionList: {
            get() {
                return this.actionList
            },
            set(list) {
                this.localActionReorder(list)
            }
        },

        localLabelTagList () {
            return this.systemTagList.filter(({ type }) => type === 'label')
        },

        localProductTagList () {
            return this.systemTagList.filter(({ type }) => type === 'product')
        },

        localPlatformTagList () {
            return this.systemTagList.filter(({ type }) => type === 'platform')
        },

        ...mapState('AutomationWorkflow', {
            automation_workflow_item: 'item',
        }),

        ...mapState('User', {
            user_list: 'list',
        }),

        ...mapState('Competitor', {
            competitor_list: 'list',
        }),

        ...mapState('Stage', {
            stage_list: 'list',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Customer', {
            customer_list: 'list',
            customer_response: 'response',
        }),

        ...mapState('Partner', {
            partner_list: 'list',
            customer_response: 'response',
        }),

        ...mapState('Territory', {
            territory_list: 'list',
        }),

        ...mapState('TagSection', {
            tag_section_list: 'list',
        }),

        ...mapState('CustomField', {
            custom_field_list: 'list',
        }),
    },

    methods: {
        localGetActionValueList (field_name, meta = null) {
            const source_type = this.localRelatedTypeFilter(field_name)
            switch (source_type) {
                case 'project_result_id' : return this.localGetFieldvalues(field_name)
                case 'project_stage_id' : return this.localGetFieldvalues(field_name)
                case 'project_status_id' : return this.localGetFieldvalues(field_name)
                case 'custom_tag': return this.localCustomTagFilter(field_name).map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' }))
                case 'project_label': return this.localLabelTagList.map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' }))
                case 'project_product': return this.localProductTagList.map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' }))
                case 'project_platform': return this.localPlatformTagList.map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' }))
                case 'custom_field': return this.localGetFieldvalues(field_name)
                case 'competitors': return this.competitor_list.map(item => ({ id: item.id, name: item.name, type: 'competitor' }))
                case 'customer_id': return this.customer_list
                case 'collaborators': return this.user_list
                case 'partner_id': return this.partner_list
                case 'territories': return this.territory_list.map(item => ({ id: item.id, name: item.name, type: 'territory', color: item.color }))
                default: return []
            }
        },

        localClosedataDynamicDateVarDialog (evt) {
            if (evt.keyCode === 27) this.dataDynamicDateVarDialog = false
        },

        localCheckActionSlug (slug) {
            return !(this.automation_workflow_item && this.automation_workflow_item.automation_trigger
                   && this.automation_workflow_item.automation_trigger.slug === 'project-stage-changed' && slug === 'stage')
        },

        localCustomTagFilter (tag_slug) {
            const tags = this.customTagList.filter(item => item.type === tag_slug)
            return tags
        },

        localGetStageValues (field_name, meta = null) {
            if (meta === 'update_workspace') return this.localGetFieldvalues(field_name)
            return this.stage_list
        },

        localGetFieldvalues (field_name) {
            const fields = this.actionEntityList.find(item => item.name === field_name)
            return fields && fields.picklistValues ? fields.picklistValues : []
        },

        localRelatedTypeFilter (field_name) {
            const source = this.actionEntityList.find(item => item.name === field_name)
            return source && source.source_field ? source.source_field : null
        },

        localAddAction (slug) {
            this.$emit('add-action', slug)
            if (this.actionList.length) {
                this.localAccordionActionToggle(this.actionList.length - 1)
            }
            this.actionDropdown = false
        },

        localGetFieldValue (field_name, value = 'type') {
            const item = this.actionEntityList.find(item => item.name === field_name)
            return item ? item[value] : null
        },

        localGetMultipleSelectedList (item) {
            const source_field = this.localGetFieldValue(item.field_name, 'source_field')
            switch (source_field) {
                case 'custom_tag': return this.localCustomTagFilter(item.field_name).map(item => ({ id: item.id, name: item.label })).filter(i => item.source_value.includes(i.id))
                case 'project_label': return this.localLabelTagList.map(item => ({ id: item.id, name: item.label})).filter(i => item.source_value.includes(i.id))
                case 'project_product': return this.localProductTagList.map(item => ({ id: item.id, name: item.label})).filter(i => item.source_value.includes(i.id))
                case 'project_platform': return this.localPlatformTagList.map(item => ({ id: item.id, name: item.label})).filter(i => item.source_value.includes(i.id))
                case 'competitors': return this.competitor_list.filter(i => item.source_value.includes(i.id))
                case 'collaborators': return this.user_list.filter(i => item.source_value.includes(i.id))
                case 'territories': return this.territory_list.filter(i => item.source_value.includes(i.id))
            }
        },

        localActionTypesCheck (slug) {
            if (slug === 'collaborators') {
                return this.actionCollaboratorTypes
            }
            return this.actionProjectTypes
        },

        localFindSelectedItem (item) {
            const source_field = this.localGetFieldValue(item.field_name, 'source_field')
            switch (source_field) {
                case 'custom_tag':
                    const customTag = this.customTagList.find(i => item.source_value.includes(i.id))
                    return customTag ? customTag.label : '-'
                case 'project_product':
                case 'project_platform':
                case 'project_label':
                        const systemTag = this.systemTagList.find(i => item.source_value.includes(i.id))
                        return systemTag ? systemTag.label : '-'
                case 'competitors':
                        const competitor = this.competitor_list.find(i => item.source_value.includes(i.id))
                        return competitor ? competitor.name : '-'
                case 'collaborators':
                        const user = this.user_list.find(i => item.source_value.includes(i.id))
                        return user ? user.name : '-'
                case 'territories':
                        const territory = this.territory_list.find(i => item.source_value.includes(i.id))
                        return territory ? territory.name : '-'
                case 'custom_field':
                    if (item.field_type === 'select') {
                        const fieldItem = this.actionEntityList.find(list => list.name === item.field_name)
                        if (fieldItem.picklistValues.length) {
                            const pickListItem = fieldItem.picklistValues.find(i => i.id === item.source_id)
                            return pickListItem ? pickListItem.name : '-'
                        }
                    }
                case 'partner_id':
                    const partner = this.partner_list.find(i => item.source_id.includes(i.id))
                    return partner ? partner.name : '-'
                default: return item.value
            }
        },

        localGetActionTypeName (name, slug = null) {
            const item = this.localActionTypesCheck(slug).find(item => item.name === name)
            return item ? item.label : null
        },

        localCheckIsAction (isAction) {
            return isAction === 1 ? true : false
        },

        localFilterActionEntityList (action) {
            const field_list = this.actionEntityList.filter(list => list.field_type === action.slug)
            if (action.action_type === 'remove') {
                return field_list.filter(item => item.name !== 'title')
            }
            return field_list
        },

        localGetIcon (slug) {
            const item = this.triggerIcon.find(item => item.slug === slug)
            return item ? item.icon : null
        },

        localActionTitle (slug) {
            const item = this.triggerList.find(item => item.slug === slug)
            return item ? item.title : null
        },

        localGetMessageErrors (action, field_name) {
            if (!action.warning || !field_name) return null
            return action.warning[field_name] && action.warning[field_name][0] ? action.warning[field_name][0] : ''
        },

        localIsCheckErrors (action, field_name) {
            if (!action.warning || !field_name) return null
            return action.warning[field_name] && action.warning[field_name][0] ? true : false
        },

        localSelectRemoveAction (id) {
            clearTimeout(this.actionDeleteTimeout)
            this.localActionRemoveId = id
            this.actionDeleteTimeout = setTimeout(() => this.localActionRemoveId = null, 5000)
        },

        localRemoveAction (index, id) {
            this.$emit('remove-action', id)
            this.accordionAction[index] = null
        },

        localAccordionActionStatus (index) {
            if (this.accordionAction[index]) return this.accordionAction[index]
            this.accordionAction[index] = false
            return this.accordionAction[index]
        },

        localAccordionActionToggle (index) {
            if (!this.canUpdate) return false
            this.accordionValueDate = []
            if (this.accordionAction[index] === undefined || this.accordionAction[index] === null) {
                this.$set(this.accordionAction, index, true);
            } else {
                this.$set(this.accordionAction, index, !this.accordionAction[index]);
            }
            this.actionTypeModel = {}
            this.fieldTypeModel = {}
        },

        localIsUpdateWorkspace (action) {
            const is_update = action.meta ? '' : 'update_workspace'
            this.$emit('update-workspace', action, is_update)
        },

        localActionReorder (list) {
            this.$emit('remove-reorder', list)
        },

        localUpdateDateType (action) {
            const type = action.field_type === 'dynamic_date' ? 'date' : 'dynamic_date'
            this.$emit('update-date-type', action, type)
        },

        localUpdateDynamicVariable (action) {
            this.localSelectedAction = action
            this.dataDynamicDateVarDialog = true
        },

        localFormatDate(date) {
            return date ? moment(date).format('MMM D, YYYY') : null
        },

        localAccordionDateToggle (index) {
            if (this.accordionValueDate[index] === undefined || this.accordionValueDate[index] === null) this.$set(this.accordionValueDate, index, true);
            else this.$set(this.accordionValueDate, index, !this.accordionValueDate[index]);
            this.$emit('change-value')
        },

        async localUpdateDateVariable (evt) {
            this.$emit('update-date-variable', this.localSelectedAction, evt.text)
            this.dataDynamicDateVarDialog = false
        },

        localCloseDateVarDialog () {
            this.dataDynamicDateVarDialog = false
        },
        
        localCheckActionType (item) {
            return !(['modify','add'].includes(item.action_type) || item.action_type === 'remove' && item.is_action === 0)
        }
    },
}
</script>

<style scoped lang="scss">
.c-action-icon-view {
    width: 48px !important;
}
.c-action-menu-icon {
    box-sizing: border-box;
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 8px !important;
}
.c-add-action{
    width: 320px;
    right: 0;
    top: 39px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08) !important;
    border-radius: 8px !important;
}
.c-menu-content-active {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08) !important;
    border: 1px solid #EEEEEE !important;
    border-radius: 8px !important;
}
.c-action-icon {
    width: 40px !important;
    height: 40px !important;
    border-radius: 4px;
}
.c-dynamic-append:hover {
    background: #E8EAF6;
    border-radius: 4px;
}

.u-dynamic-prepend {
    padding: 12px;
    border-radius: 5px 0px 0px 5px;
}
.c-trigger-action-icon {
    width: 40px !important;
    height: 40px !important;
}
.c-remove-field {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}
.c-action-drag-handle {
    position: absolute;
    left: 3px;
    top: 23px;
    display: none;
}

.c-action-section-text {
    position: relative !important;
    bottom: 2px !important;
}
.c-action-section {
    &_hover {
        &:hover {
            .c-action-drag-handle {
                display: inline-block !important;
            }
        }
    }
}
</style>


<template>
    <div>
        <template v-if="!can_internal_mixin('documents.index')">
            <a-sheet class="transparent text-center mx-auto mt-8" max-width="230">
                <a-icon size="48" color="orange darken-4">warning</a-icon>
                <h2 class="md-subtitle-1 mt-1 grey--text text--darken-1">You do not have sufficient permission to view this page.</h2>
            </a-sheet>
        </template>
        <template v-else>
            <a-container container--fluid grid-list-xl pa-6>
                <a-layout align-start v-if="!page_loading && permission_document('store')">
                    <a-flex :class="{ 'pb-0': document_list && !document_list.length }">
                        <div class="md-body-2 text-center mt-3 u-flex align-center justify-center">
                            <div v-if="permission_document('store')">
                                <a-btn small text color="primary lighten-1" @click="local_initiate()" :disabled="loading_file">
                                    <a-icon size="20" left class="mr-2">cloud_upload</a-icon> Upload Document
                                </a-btn>
                                <input type="file" ref="doc_upload" name="doc_upload" class="d-none" @change="local_doc_upload()" multiple :accept="local_file_types">
                            </div>
                        </div>
                    </a-flex>
                </a-layout>
                <a-layout wrap align-start v-if="page_loading">
                    <a-flex xs12>
                        <a-responsive class="my-4">
                            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching {{ local_module_text }}..</h3>
                        </a-responsive>
                    </a-flex>
                </a-layout>
                <template v-if="!page_loading">
                    <a-layout wrap align-start v-if="!document_list.length && (!uploadDocsList.length)">
                        <a-flex xs12>
                            <a-responsive class="my-4">
                                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Documents or Links Found</h3>
                                <p class="md-body-2 text-center grey--text mb-0 mx-auto" style="max-width: 350px;">Add your project specific documents to share within your team or with customer.</p>
                            </a-responsive>
                        </a-flex>
                    </a-layout>
                    <div v-if="uploadDocsList.length" class="px-6 mb-8">
                        <h2 class="md-subtitle-1 grey--text text--darken-1 mb-3">Uploading {{ uploadDocsList.length }} {{ uploadDocsList.length > 1 ? 'files' : 'file' }}</h2>
                        <a-card v-for="doc in uploadDocsList" class="u-elevation-custom-1 u-rounded-corners mb-2" :key="doc.last_modified">
                            <a-container container--fluid grid-list-xl class="py-3 px-2">
                                <a-layout align-center py-2>
                                    <a-flex py-0 shrink>
                                        <img src="../../../assets/images/filetype-icons/unknown.svg" width="48" alt="Unkown Filetype" style="display: block;">
                                        <!-- <img v-if="doc.type" :src="require('../../assets/images/filetype-icons/'+(doc.type.split('/')[1].length > 5 ? 'xlsx' : doc.type.split('/')[1])+'.svg')" width="48" :alt="doc.type.split('/')[1]" style="display: block;"> -->
                                    </a-flex>
                                    <a-flex py-0>
                                        <h2 class="md-subtitle-1 primary--text d-block text-no-wrap text-truncate" style="max-width: 360px; line-height: 1.5 !important;">{{ doc.name }}</h2>
                                        <p class="md-body-2 grey--text text--darken-2 mb-0">Size: {{ doc.size | sizer }}</p>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-progress-linear class="u-rounded-corners" indeterminate height="1" color="teal"></a-progress-linear>
                        </a-card>
                    </div>
                    <a-layout wrap align-center v-if="document_list.length > 0 && permission_document('index')">
                        <a-flex xs12 v-if="!document_list.length">
                            <a-responsive class="my-4">
                                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1">No Documents Found</h3>
                                <p class="md-body-2 text-center grey--text mb-0" v-if="permission_document('store')">Add your project specific documents to share within your team or with customer.</p>
                            </a-responsive>
                        </a-flex>
                        <template v-else>
                            <a-flex shrink class="py-0">
                                <h4 class="md-subtitle-1 grey--text text--darken-1">Files ({{ document_list.length }})</h4>
                            </a-flex>
                            <a-flex xs12>
                                <draggable v-model="document_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                                    <div v-for="document in document_list" :key="document.id" :class="{ 'js-draggable-file-list-item': permission_document('update')}">
                                        <a-card class="u-elevation-custom-1 u-cursor-pointer my-3">
                                            <a-card-text class="pa-0">
                                                <a-container container--fluid grid-list-xl class="py-3 px-6">
                                                    <a-layout align-center>
                                                        <a-flex shrink class="px-0" :class="{'u-opacity-30': !permission_document('update')}">
                                                            <a-icon class="grey--text text--lighten-1 js-drag-handle">drag_indicator</a-icon>
                                                        </a-flex>
                                                        <a-flex shrink class="px-0">
                                                            <img src="../../../assets/images/filetype-icons/unknown.svg" width="48" alt="Unkown Filetype" style="display: block;" v-if="document.extension === null">
                                                            <img :src="require('../../../assets/images/filetype-icons/'+document.extension+'.svg')" width="48" :alt="document.extension" style="display: block;" v-else>
                                                        </a-flex>
                                                        <a-flex class="text-truncate">
                                                            <h2 v-if="document.title" class="md-subtitle-1 primary--text text-truncate mb-2" style="line-height: 1.5 !important;">
                                                                {{ document.title }}
                                                                <span class="md-body-2 grey--text text--darken-2">({{ document.name }})</span>
                                                            </h2>
                                                            <h2 class="md-subtitle-1 primary--text d-block text-no-wrap text-truncate mb-2" style="max-width: 360px; line-height: 1.5 !important;" v-else>{{ document.name }}</h2>
                                                            <p class="md-body-2 grey--text text--darken-2 mb-0">Size: {{ document.size | sizer }} <span class="mx-2">&bull;</span> <span v-if="document.created_by">Uploaded by {{ document.created_by.name }}</span></p>
                                                        </a-flex>
                                                        <a-flex
                                                            v-if="has_permission_mixin('projects.download') || $can('projects.download') || has_permission_mixin('documents.show') || permission_document('update') || permission_document('destroy')"
                                                            class="u-flex-center-y"
                                                            style="column-gap: 4px;"
                                                            shrink
                                                        >
                                                            <a-tooltip bottom v-if="has_permission_mixin('projects.download') && $can('projects.download') && has_permission_mixin('documents.show')">
                                                                <template v-slot:activator="{ on }">
                                                                    <a-btn text icon color="grey" class="mx-0" v-on="on" :disabled="loading" :loading="local_loading_get(document.id, 'download')" @click="local_document_download(document.id)">
                                                                        <a-icon size="16">cloud_download</a-icon>
                                                                    </a-btn>
                                                                </template>
                                                                <span>Download</span>
                                                            </a-tooltip>
                                                            <a-tooltip bottom v-if="permission_document('update')">
                                                                <template v-slot:activator="{ on }">
                                                                    <a-btn icon text small color="grey" class="mx-0" v-on="on" :disabled="loading" @click="local_document_edit(document.id)">
                                                                        <a-icon size="16">edit</a-icon>
                                                                    </a-btn>
                                                                </template>
                                                                <span>Edit</span>
                                                            </a-tooltip>
                                                            <template v-if="permission_document('destroy')">
                                                                <a-btn icon text small color="grey" v-if="!local_is_selected(document.id)" class="mx-0" @click="local_add_item_to_delete(document.id)">
                                                                    <a-icon size="16">delete</a-icon>
                                                                </a-btn>
                                                                <a-tooltip bottom v-else>
                                                                    <template v-slot:activator="{ on }">
                                                                        <a-btn icon text small color="red" class="mx-0" v-on="on" :disabled="loading" :loading="local_loading_get(document.id, 'destroy')" @click="local_document_destroy(document.id)">
                                                                            <a-icon size="16">delete</a-icon>
                                                                        </a-btn>
                                                                    </template>
                                                                    <span>Confirm?</span>
                                                                </a-tooltip>
                                                            </template>
                                                        </a-flex>
                                                    </a-layout>
                                                </a-container>
                                            </a-card-text>
                                        </a-card>
                                    </div>
                                </draggable>
                            </a-flex>
                        </template>
                    </a-layout>
                </template>
            </a-container>
            <a-dialog v-model="dialog_document_form" max-width="600" persistent>
                <a-card>
                    <a-card-text class="pa-0">
                        <a-form @submit.prevent="local_store()">
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-center>
                                    <a-flex shrink class="pr-1">
                                        <a-avatar class="primary darken-1" size="40">
                                            <a-icon dark>description</a-icon>
                                        </a-avatar>
                                    </a-flex>
                                    <a-flex>
                                        <h2 class="md-heading-6 primary--text text--darken-1">Edit Document Information</h2>
                                        <p class="md-subtitle-2 mb-0">Edit information related to document.</p>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-center>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Filename</label>
                                        <a-text-field
                                            v-model="document_item.name"
                                            placeholder="Document Filename"
                                            background-color="neutral"
                                            solo flat hide-details readonly
                                        >
                                        </a-text-field>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="document_response.server && document_response.server.errors && document_response.server.errors.name">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ document_response.server.errors.name[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            This is the original filename of the document.
                                        </span>
                                    </a-flex>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title (Optional)</label>
                                        <a-text-field
                                            v-model="document_item.title"
                                            placeholder="Enter Document Title"
                                            background-color="neutral"
                                            solo flat hide-details
                                        >
                                        </a-text-field>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="document_response.server && document_response.server.errors && document_response.server.errors.title">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ document_response.server.errors.title[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            This will help you identify the document.
                                        </span>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-center>
                                    <a-flex shrink>
                                        <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_document_update()" :loading="loading" :disabled="loading">Save Document</a-btn>
                                        <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_document_form_cancel()" :disabled="loading">Cancel</a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-form>
                    </a-card-text>
                </a-card>
            </a-dialog>
            <a-dialog max-width="480px" v-model="dialog_download_link" persistent>
                <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden" flat>
                    <a-progress-linear class="my-0" height="3" color="orange darken-3" :indeterminate="true" v-if="loading"></a-progress-linear>
                    <a-card-text class="pa-2">
                        <a-container container--fluid grid-list-xl class="pa-4">
                            <a-layout align-start>
                                <a-flex shrink>
                                    <a-icon size="72" color="grey lighten-1">cloud_download</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 mb-2">
                                        <span v-if="loading">Preparing file...</span>
                                        <span v-else>Ready to Download</span>
                                    </h2>
                                    <p class="md-body-2 mb-0 grey--text text--darken-1">
                                        <span v-if="loading">Success is preparing your file for download. It may take few seconds to get ready.</span>
                                        <span v-else>Your file is ready to download. Click on the "Save File" button to download the report.</span>
                                    </p>
                                    <a-btn :href="document_links.download" small class="elevation-0 px-3 ma-0 mt-3" color="primary" :disabled="loading" @click="local_document_download_save()" download>
                                        <a-icon size="14" class="mr-2">save</a-icon>
                                        Save File
                                    </a-btn>
                                    <a-btn class="elevation-0 px-3 ma-0 mt-3" color="grey" text @click="dialog_download_link = !dialog_download_link" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-card-text>
                </a-card>
            </a-dialog>
            <a-snackbar v-model="snackbar_status" :timeout="5000" bottom center>
                {{snackbar_text}} Imported Successfully!
            </a-snackbar>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import ExternalPermissionMixin from '@/mixins/mixin-external-view-permission'

export default {
    mixins: [ExternalPermissionMixin],

    data() {
        return {
            export_mode: 'new',
            dialog_document_form: false,
            dialog_import_form: false,
            dialog_export_link_form: false,
            dialog_export_document_form: false,
            dialog_download_link: false,
            loading_item: null,
            loading_action: null,
            data_template_group_list: [],
            delete_items_list: [],
            snackbar_status: false,
            snackbar_text: '',
            page_loading: true,
            form_add_mode: 'upload',
            loading_file: false,
            uploadDocsList: [],
            local_file_types: '.jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx,.csv,.ppt,.pptx,.txt,.zip',
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        document_list: {
            get() {
                return this.$store.state.DocumentExternal.list
            },
            set(list) {
                this.local_document_reorder(list)
            }
        },
        local_module_text () {
            if (!this.permission_mixin('documents.index')) return 'data'
            return 'Documents'
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('TemplateGroup', {
            template_group_list: 'list',
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),
        ...mapState('TemplateDocument', {
            template_document_item: 'item',
            template_document_response: 'response',
        }),
        ...mapState('DocumentExternal', {
            document_item: 'item',
            document_filters: 'filters',
            document_links: 'links',
            document_response: 'response',
            document_form_mode: 'form_mode',
        }),
        ...mapState('Project', {
            project_item: 'item',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_index() {
            if (!this.can_internal_mixin('documents.index')) return this.page_loading = false
            if (!this.can_external_mixin('documents.index')) {
                return this.$router.replace({name: 'errors-unauthorized'})
            }

            if (this.can_external_mixin('documents.index') && this.can_internal_mixin('documents.index')) {
                await this.document_clear()
                await this.local_fetch_document()
            }

            this.page_loading = false
            if(!this.project_item) await this.project_show({id: this.$route.params.id})
        },
        async local_fetch_document() {
            await this.document_index( { 'include': 'createdBy', 'filter[project_id]': this.$route.params.id, 'sort' : 'order' } )
        },
        async local_document_fetch() {
            await this.document_clear()
            this.document_filters.page = 0;
            this.document_filters.project_id = this.$route.params.id
            this.document_item.project_id = this.$route.params.id
            await this.document_index( { 'include': 'createdBy', 'filter[project_id]': this.$route.params.id } )
        },
        async local_document_edit(id) {
            this.dialog_document_form = true
            await this.document_select({id: id})
        },
        async local_document_update() {
            await this.document_update(this.document_item)
            if (this.document_response.status === 'success') {
                this.dialog_document_form = false
                await this.document_clear_item()
            }
        },
        async local_document_form_cancel() {
            this.dialog_document_form = false
            await this.document_clear_item()
        },
        local_add_item_to_delete (id) {
            this.delete_items_list.push(id)
            setTimeout(() => this.local_remove_delete_item(id), 3000)
        },
        local_remove_delete_item (id) {
            this.delete_items_list.splice(this.delete_items_list.indexOf(id))
        },
        local_is_selected (id) {
            return this.delete_items_list.includes(id)
        },
        async local_document_destroy(id) {
            await this.local_loading_set(id, 'destroy')
            await this.document_destroy({id: id})
            await this.local_loading_clear()
            this.local_remove_delete_item(id)
        },
        async local_document_visibility_toggle(id, status) {
            await this.local_loading_set(id, 'visibility')
            await this.document_select({id: id})
            this.document_item.visibility = status === 'external' ? 'internal' : 'external'
            await this.document_visibility(this.document_item)
            await this.local_loading_clear()
            if (this.template_document_response.status === 'success') {
                this.document_clear_item()
            }
        },
        async local_document_reorder(list) {
            await this.document_reorder({list: list})
        },
        async local_document_download(id) {
            this.dialog_download_link = true
            await this.document_download({id: id})
        },
        local_document_download_save() {
            this.dialog_download_link = false
        },
        local_initiate() {
            this.$refs.doc_upload.value = null
            this.$refs.doc_upload.click()
        },
        async local_doc_upload() {
            const fileList = Array.from(this.$refs.doc_upload.files)
            if (fileList && fileList.length > 0) {
                this.loading_file = true
                this.uploadDocsList = [...fileList]
                fileList.forEach(async item => {
                    await this.document_store(this.local_upload_action(item))
                    if (this.document_response.status == 'success') this.uploadDocsList.splice(this.uploadDocsList.findIndex(file => file.name === item.name), 1)
                    if (!this.uploadDocsList.length) this.loading_file = false
                })
            }
        },
        local_upload_action(item) {
            const form_data = new FormData()
            form_data.append('file', item)
            form_data.append('project_id', this.$route.params.id)
            form_data.append('id', uuidv4())
            return form_data
        },
        async local_loading_set(id, action) {
            this.loading_item = id
            this.loading_action = action
        },
        async local_loading_clear() {
            this.loading_item = null
            this.loading_action = null
        },
        local_loading_get(id, action) {
            return this.loading && this.loading_item == id && this.loading_action == action
        },
        permission_document(type) {
            return this.permission_mixin('documents.' + type)
        },
        ...mapActions('Project', {
            project_show: 'show',
        }),
        ...mapActions('DocumentExternal', {
            document_index: 'index',
            document_show: 'show',
            document_store: 'doc_upload',
            document_visibility: 'visibility',
            document_update: 'update',
            document_select: 'select',
            document_download: 'download',
            document_destroy: 'destroy',
            document_reorder: 'reorder',
            document_template_import: 'template_import',
            document_clear: 'clear',
            document_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_store: 'store',
            template_group_clear: 'clear',
        }),
    }
}
</script>

<style>
.dropzone-custom-content {
    padding: 2rem;
    border: 2px dashed #c1c1c1 !important;
    border-radius: .25rem;
    cursor: pointer;
}
.dz-preview {
    background-color: #fff;
    border-radius: .25rem;

    margin-top: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.06), 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.06) !important;
}
.dz-preview .dz-details {
    padding: 1rem;
}
.dz-preview .dz-details .dz-size,
.dz-preview .dz-details .dz-filename {
    display: inline-block;
    font-size: .825rem;
}
.dz-preview .dz-image,
.dz-preview .dz-success-mark,
.dz-preview .dz-error-mark {
    display: none;
}
.dz-preview .dz-progress .dz-upload {
    display: block;
    background-color: #43a047;
    height: 2px;
}
.dz-preview.dz-success {
    display: none;
}
.dz-preview.dz-error .dz-progress {
    display: none;
}
.dz-preview.dz-error .dz-error-message {
    padding: 1rem;
    padding-top: 0;
    font-size: .825rem;
    color: red;
}
.dz-preview.dz-error .dz-details {
    padding-bottom: 0;
}
</style>

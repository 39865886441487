<template>
    <v-spacer>
        <slot></slot>
    </v-spacer>
</template>

<script>
import { VSpacer } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VSpacer }
}
</script>

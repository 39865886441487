<template>
    <a-layout wrap align-start mb-1>
        <a-flex xs9 v-if="canStore">
            <a-btn small dark color="blue" class="ma-0 elevation-0" @click="$emit('create')">Create New Use Case</a-btn>
        </a-flex>
        <a-spacer></a-spacer>
        <!-- <a-flex shrink class="pl-0" v-if="list && list.length">
            <a-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <a-btn small text icon color="neutral" class="ma-0 elevation-0 right" @click="$emit('expand-all')" v-if="expandedAll" v-on="on" :disabled="list.length === 0">
                        <a-icon size="18" color="neutral darken-4">unfold_less</a-icon>
                    </a-btn>
                    <a-btn small text icon color="neutral" class="ma-0 elevation-0 right" @click="$emit('expand-all')" v-else v-on="on" :disabled="list.length === 0">
                        <a-icon size="18" color="neutral darken-4">unfold_more</a-icon>
                    </a-btn>
                </template>
                <span>Expand/Collapse Use Case</span>
            </a-tooltip>
        </a-flex> -->
        <a-flex shrink class="pl-0" v-if="(canDelete && (list && list.length))">
            <a-menu bottom left offset-y>
                <template v-slot:activator="{ on }">
                    <a-btn small icon color="neutral" class="ma-0 elevation-0 right" v-on="on">
                        <a-icon size="18" color="neutral darken-4">more_vert</a-icon>
                    </a-btn>
                </template>
                <a-list class="u-list-condensed">
                    <a-list-item :class="[{ 'grey lighten-4': bulkSelected }]" @click="$emit('bulk-select', true)" :disabled="list.length === 0 || loading">
                        <a-list-item-content>
                            <a-list-item-title>Delete Selected</a-list-item-title>
                        </a-list-item-content>
                    </a-list-item>
                    <a-list-item @click="$emit('delete-all', true)" :disabled="list.length === 0 || loading || bulkSelected">
                        <a-list-item-title :class="[(bulkSelected ? 'grey--text' : 'red--text text--darken-1')]">Delete all Use Cases</a-list-item-title>
                    </a-list-item>
                </a-list>
            </a-menu>
        </a-flex>
    </a-layout>
</template>

<script>
export default {
    props: {
        list: {
            type: Array
        },
        expandedAll: {
            type: Boolean
        },
        loading: {
            type: Boolean
        },
        bulkSelected: {
            type: Boolean,
            default: false
        },
        canStore: {
            type: Boolean
        },
        canDelete: {
            type: Boolean
        }
    }
}
</script>

<style>

</style>

const api_endpoint = '/report-templates'

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        links: {},
        filters: {
            status: 'all',
            include: '',
            page: 1,
            count: 10,
            sort_direction: 'asc'
        },
        response: [],
        defaults: {
            list: [],
            item: {},
            links: {},
            meta: {},
            filters: {
                status: 'all',
                include: '',
                page: 1,
                count: 10,
                sort_field: 'company_name',
                sort_direction: 'asc'
            },
            response: [],
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        ADD_BLOCK_TEMPLATE(state, payload) {
            state.block_template = payload
        },
        UPDATE_ACTIVE_STATUS(state, payload) {
            const index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index].is_active = payload.state;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_TEMPLATE_NAME(state, item) {
            state.item.name = item.name;
        },
        UPDATE_LINKS(state, links) {
            state.links = links;
        },
        MEMBER_ITEM(state, member_item) {
            state.member_item = member_item;
        },
        UPDATE_LIST_ITEM(state, payload) {
            const index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index].name = payload.name;
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        APPEND_ITEM(state, item) {
            state.list.unshift({ ...item, is_active: 1 });
        },
        ADD_ITEM(state, item) {
            state.list.push({ ...item, is_active: 1 });
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        async actionTemplateIndex(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data: response } } = await axios.get(api_endpoint, { params: { ...payload } })
                context.commit('UPDATE_LIST', response)
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async actionPOCDocTemplateIndex(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data: response } } = await axios.get(`${api_endpoint}`, { params: { ...payload } })
                context.commit('UPDATE_LIST', response)
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async actionTemplateStore(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.post(api_endpoint, payload)
                context.commit('APPEND_ITEM', payload)
                context.commit('UPDATE_ITEM', payload)
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.errors.name[0], status: 'error' });
            }
        },
        async actionStoreTemplateCloned(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.post(`${api_endpoint}/clone`, { ...payload })
                context.commit('ADD_ITEM', { id: payload.id, name: payload.name })
                context.commit('UPDATE_ITEM', { id: payload.id, name: payload.name })
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.errors.name[0], status: 'error' });
            }
        },
        async actionTemplateUpdate(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.patch(`${api_endpoint}/${payload.id}`, payload)
                if (!payload.view) context.commit('UPDATE_LIST_ITEM', payload)
                else context.commit('UPDATE_TEMPLATE_NAME', payload)
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.errors.name[0], status: 'error' });
            }
        },
        async actionTemplateShow(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data: response } } = await axios.get(`${api_endpoint}/${payload.report_template_id}`, { params: { ...payload } })
                context.commit('UPDATE_ITEM', response)
                context.dispatch('ReportTemplateBlock/actionTemplateBlockList', response.report_template_blocks, { root: true })
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async actionTemplateDelete(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('REMOVE_ITEM', payload.id)
            try {
                await axios.delete(`${api_endpoint}/${payload.id}`)
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async actionToggleActive(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.patch(`${api_endpoint}/${payload.id}/states/is-active`, { state: payload.state })
                this.dispatch('Interface/loader_stop')
                context.commit('UPDATE_ACTIVE_STATUS', payload)
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async actionTemplateReorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload)
            try {
                await axios.patch(`${api_endpoint}/reorder`, { data: { ...payload } })
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async actionReportGenerate(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: response } = await axios.post(`${api_endpoint}/generate/${payload.template_id}/${payload.project_id}`)
                // context.commit('UPDATE_ITEM', response)
                context.commit('UPDATE_LINKS', response.link);
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                const errorMsg = error.response.data.errors ? error.response.data.errors.name[0] : 'Document failed to download. Please try again.'
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: errorMsg, status: 'error' });
            }
        },
        actionTemplateLinkClear(context) {
            return new Promise((resolve, reject) => {
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_LINKS', _.cloneDeep(context.state.defaults.links));
                resolve('Cleared');
            });
        },
        actionTemplateClear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('UPDATE_LINKS', _.cloneDeep(context.state.defaults.links));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        actionTemplateClearItem(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        member_clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('MEMBER_ITEM', _.cloneDeep(context.state.defaults.member_item));
                resolve('Cleared');
            });
        },
    },

    getters: {
        getTemplateList: (state) => {
            const list = [...state.list]
            return list
        },
        getFindTemplateItem: (state) => (id) => {
            const template = { ...state.list.find(item => item.id === id) }
            return template
        },
        getTemplateItem: (state) => {
            const template = { ...state.item }
            return template
        },
        getBlock: (state) => (id) => {
            const block = state.item.report_template_blocks.find(block => block.id === id)
            return block
        },
        // getBlockAttrs: (state) => (id, name) => {
        //     const block = state.item.report_template_blocks.find(block => block.id === id)
        //     if (block) {
        //         const attr = block.report_template_block_attributes.find(item => item.rb_attribute_name === name)
        //         return attr
        //     }
        // },
        getDownloadLink: (state) => state.links,
        getTemplateResponse: (state) => state.response,
    }
}

<template>
    <div class="c-bar-vertical-chart u-relative u-border-r">
        <div class="c-bar-vertical-chart__column white" style="z-index: 2">
            <p-faux-loader :is_loading="tile_faux_loader && tile_faux_loader.includes(item.id)" img_alt="Loading Bar Chart.."></p-faux-loader>
            <p-tile-status
                :is_loading="item && (loading_statuses.includes(item.status) || chart_loading)"
                :has_error="item && item.status === 'error'"
                :has_stats="item && item.statistics && item.statistics.data  && item.statistics.data.length"
                @configure="$emit('configure', true)">
                <template #loader>
                    <div class="u-wfull u-hfull u-absolute text-center transparent u-opacity-40">
                        <a-img :src="require('../../../assets/images/loader.svg')" alt="Loading Bar Chart.." width="48" height="48" contain class="d-inline-block mt-16"></a-img>
                    </div>
                </template>
            </p-tile-status>
            <VueApexCharts
                ref="barVerticalChart"
                :options="chart_options"
                :series="series"
                width="536"
                height="195"></VueApexCharts>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VueApexCharts from "vue-apexcharts"
import PTileStatus from './PartialTileStatus.vue'
import { formatCurrency } from '@/helpers/helper-currency.js'
import { formatTileOptions } from '../Helpers/helper-format-tile-options.js'
import PFauxLoader from './PartialFauxLoader.vue'

export default {
    name: "BarVerticalChart",

    components: { VueApexCharts, PTileStatus, PFauxLoader },

    props: {
        isRearrange: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: {}
        }
    },

    data: function () {
        return {
            loading_statuses: ['loading', 'update-loading', 'new-loading', 'force-loading'],
            local_chart: {},
            stats_interval: null,
            stats_interval_countdown: 0,
            local_tile_options: [],
            stats: [],
            chart_loading: true,
            series: [],
            chart_options: {
                chart: {
                    type: "bar",
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 8,
                        dataLabels: {
                            position: "top",
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    padding: {
                        top: -16,
                        bottom: -8,
                        left: 6,
                        right: 0
                    }
                },
                legend: {
                    show: false,
                },
                xaxis: {
                    categories: [],
                    labels: {
                        style: {
                            fontFamily: "Inter, sans-serif",
                            fontWeight: 700,
                        },
                        // formatter: function (val) {
                        //     return [ val, '(MAR-JUN)'];
                        // }
                    },
                    position: "bottom",
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        fill: {
                            type: "gradient",
                            gradient: {
                                colorFrom: "#D8E3F0",
                                colorTo: "#BED1E6",
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                },
                yaxis: {
                    min: 0,
                    forceNiceScale: true,
                    show: true,
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: true,
                        style: {
                            fontFamily: "Inter, sans-serif",
                        },
                        offsetX: -6,
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
                fill: {
                    opacity: 1,
                    colors: ['#9C27B0']
                },
                tooltip: {
                    y: {
                        title: {}
                    },
                    z: {}
                }
            },
        };
    },

    watch: {
        item: {
            handler (val) {
                this.local_watch_index()
            },
            deep: true
        },

        isRearrange (val) {
            this.local_set_animation(!val)
        }
    },

    async mounted () {
        if (this.item) {
            await this.local_get_stats()
            await this.local_index()
        }
    },

    beforeDestroy () {
        clearInterval(this.stats_interval)
    },

    computed: {
        ...mapState('Tile', {
            tile_response: 'response',
            tile_faux_loader: 'faux_loader'
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index() {
            this.chart_loading = this.isRearrange ? false : true
            this.local_chart = _.cloneDeep(this.item)
            this.stats_interval_countdown = 0
            this.stats = []

            if (this.item && this.item.status === 'loaded') {
                this.stats = (this.item.statistics ? this.item.statistics.data : []) || []
                this.local_clear_loading()
            }
            else {
                await this.local_watch_index()
            }
        },

        async local_watch_index () {
            this.chart_loading = this.isRearrange ? false : true
            if (this.item && this.item.status !== 'loaded') {
                clearInterval(this.stats_interval)
                await this.local_get_stats()
                this.stats_interval = setInterval(async () => await this.local_get_stats_with_interval(), 3000)
                if (this.item && (this.item.status === 'error' || this.item.status === 'loaded')) this.local_set_error_state()
            }
        },

        local_clear_loading () {
            setTimeout(() => {
                this.chart_loading = false
            }, 1000)
        },

        async local_set_chart() {
            this.local_get_current_options()
            await this.local_set_values()
            await this.local_set_labels()
        },

        async local_update_chart() {
            if (this.$refs && this.$refs.barVerticalChart) this.$refs.barVerticalChart.updateOptions({ ...this.chart_options });
        },

        async local_get_stats () {
            this.local_chart = _.cloneDeep(this.item)
            const params = this.item.status === 'force-loading' ? {force: true} : null
            await this.tile_show_stats({ id: this.local_chart.id, params: params })
            this.local_chart = _.cloneDeep(this.item)

            this.stats = (this.item.statistics ? this.item.statistics.data : []) || []
            await this.local_set_chart()
            await this.local_update_chart()
            clearInterval(this.stats_interval)
        },

         async local_get_stats_with_interval () {
            this.stats_interval_countdown = this.stats_interval_countdown + 1
            if ((this.item && this.item.status === 'error') || this.stats_interval_countdown >= 10) return this.local_set_error_state()
            if (this.item && (this.item.status === 'update-loading' || this.item.status === 'force-loading')) await this.local_get_stats()

            await this.local_fetch_tile_item() // triggering watch-index here!!!!!
            if (this.item && this.item.status === 'loaded') {
                this.tile_faux_remove_item(this.item.id)
                this.stats_interval_countdown = 0
                await this.local_get_stats()
                this.local_clear_loading()
                return clearInterval(this.stats_interval)
            }
            if ((this.item && this.item.status === 'error') || this.stats_interval_countdown >= 10) return this.local_set_error_state()
        },

        local_set_error_state () {
            clearInterval(this.stats_interval)
            this.tile_faux_remove_item(this.item.id)
            this.tile_update_tile_status({ id: this.local_chart.id, status: 'error' })
            this.stats_interval_countdown = 0
            this.local_clear_loading()
        },

        async local_set_values () {
            const series = {
                name: '',
                data: []
            }
            const categories = []

            for(const stat of this.stats) {
                series.data.push(stat.value)
                categories.push(stat.key.substring(0, 3))
            }

            this.series = [series]
            this.chart_options.xaxis.categories = categories;
        },

        async local_set_labels () {
            if(this.local_tile_options.yAxisValue.isCurrency) {
                const currencyValues = this.user_self.organization.currency
                this.chart_options.yaxis.labels.formatter = (value) => { return formatCurrency({ value, currencyValues, restrictDigit: true }) }
                this.chart_options.tooltip.y.formatter = (value) => { return formatCurrency({ value, currencyValues }) }
            }
            else {
                this.chart_options.yaxis.labels.formatter = (value) => { return value }
                this.chart_options.tooltip.y.formatter = (value) => { return value }
            }

            this.chart_options.tooltip.y.title.formatter = (value) => { return this.local_tile_options.yAxisValue.label }
        },

        async local_fetch_tile_item () {
            await this.tile_show({ id: this.local_chart.id, mode: 'show-modify', params: { include: 'tile_options,tile_filters,source' } })
        },

        local_set_animation (value) {
            if (!this.$refs || !this.$refs.barVerticalChart) return
            this.$refs.barVerticalChart.updateOptions({
                chart: {
                    animations: {
                        enabled: value,
                    }
                }
            })
        },

        local_get_current_options () {
            this.local_tile_options = formatTileOptions(this.local_chart.tile_options)
        },

        ...mapActions('Tile', {
            tile_show: 'show',
            tile_show_stats: 'show_stats',
            tile_update_tile_status: 'update_tile_status',
            tile_faux_remove_item: 'remove_faux_item'
        }),
    },
};
</script>

<style scoped>
.c-bar-vertical-chart {
    height: 227px;
    width: 568px;
}

.c-bar-vertical-chart__column {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.c-bar-vertical-chart__column > div {
    min-height: auto!important;
}
</style>

<template>
    <div
        :style="[
            { border: '1px solid' + (isZero ? '#BDBDBD' : dataColor) },
            { borderRadius: '3px', width: '22px', height: '12px', padding: '1px' }
        ]"
    >
        <a-sheet
            :color="dataColor"
            :width="value + '%'"
            class="u-hfull"
            style="border-radius: 2px;"
        />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number | String,
            required: true
        },
        color: {
            type: String | Object
        },
        isZero: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        dataColor () {
            if (this.color && this.color.startsWith('#')) return this.color
            return this.$color(this.color ?? 'grey-dark', 'color_bg')
        }
    }
}
</script>

<template>
    <div
        class="c-summary-tile u-relative u-hfull u-rounded-corners-xl"
        :style="[
            count.total && !mixLoading ? { background: localGetGradientColor } : {},
            mixLoading ? { background: 'linear-gradient(180deg, #EBEBEB 0%, #FFF 34.94%)' } : {},
        ]"
    >
        <loader-sc-task-summary v-if="mixLoading" />

        <template v-else>
            <template v-if="localIsEmpty || !mixHasPermission('tasks.index')">
                <partial-empty-state-summary
                    :canView="mixHasPermission('tasks.index')"
                    title="Task"
                    icon="check_circle"
                    modelSlug="tasks"
                    class="pb-4"
                    iconSize="26"
                    iconOutlined
                />
            </template>

            <div v-else class="u-relative pt-5">
                <a-sheet width="200" height="128" class="mx-auto transparent u-relative" style="z-index: 1">
                    <DoughnutChart
                        :chart-data="chartData"
                        :chart-options="chartOptions"
                        :width="100"
                        :height="100"
                    />
                </a-sheet>

                <div
                    class="pa-5 pt-0 text-center u-flex-center flex-column u-hfull u-absolute"
                    style="
                        position: absolute;
                        transform: translateX(-50%);
                        bottom: -50px;
                        left: 50%;
                        width: 100%;
                    "
                >
                    <h3 class="grey lighten-2 u-rounded-corners-full py-1 px-2 md-caption u-font-weight-semibold grey--text text--darken-3">
                        {{ localGetPercentage }}%
                    </h3>
                    <h3 class="md-body-1 grey--text text--darken-3 mt-2 u-font-weight-semibold">
                        {{ count.completed }} / {{ count.total }}
                    </h3>
                    <h3 class="md-body-2 grey--text text--darken-2 u-font-weight-semibold mt-2">
                        Task Progress
                    </h3>
                </div>
            </div>
        </template>

        <partial-project-summary-actions
            v-if="mixHasPermission('tasks.index') && !mixLoading"
            :showExpand="false"
            @open="mixOpenInNewWindow('projects-tasks')"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'
import { Doughnut as DoughnutChart } from 'vue-chartjs/legacy'
import MixinModalStateInjection from '../Mixins/MixinModalStateInjection'
import PartialProjectSummaryActions from './PartialProjectSummaryActions.vue'
import PartialEmptyStateSummary from './PartialEmptyStateSummary.vue'
import LoaderScTaskSummary from '../Loaders/LoaderScTaskSummary.vue'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
	components: {
        DoughnutChart,
        PartialProjectSummaryActions,
        PartialEmptyStateSummary,
        LoaderScTaskSummary
    },

    mixins: [MixinModalStateInjection],

    props: {
        noFetch: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            loading: true,
            count: { total: 0, completed: 0 },
            gradients: {
                completed: 'linear-gradient(180deg, #EDFFEE 0%, #FFF 34.94%)',
                started: 'linear-gradient(180deg, #E0ECFF 0%, #FFF 34.94%)',
                unstarted: 'linear-gradient(180deg, #EBEBEB 0%, #FFF 34.94%)'
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: { legend: false }
            },
            chartData: {
                labels: [' Completed', ' Started', ' Unstarted'],
                datasets: [{
                    backgroundColor: [
                        '#43A047',
                        '#448AFF',
                        '#BDBDBD'
                    ],
                    data: [],
                    hoverOffset: 4,
                    cutout: '80%',
                    circumference: 180,
                    rotation: 270,
                    borderWidth: 1,
                    borderColor: '#fff',
                    borderRadius: 20,
                    borderWidth: 2
                }]
            },
        }
    },

    computed: {
        localIsEmpty () {
            const { total } = this.count || {}
            return total === 0
        },

        localGetPercentage () {
            const { total, completed } = this.count
            if (total === 0 || !total) return 0

            return Math.floor((Number(completed) / Number(total)) * 100)
        },

        localGetGradientColor () {
            const highest = Object.entries(this.count).reduce((acc, current) => {
                if (acc.value > current[1]) return acc
                if (current[0] === 'total') return acc

                acc = { key: current[0], value: current[1] }
                return acc
            }, { key: '', value: 0 })

            if (this.count.passed === highest.value) {
                highest.key = 'passed'
                highest.value = this.count.passed
            }

            return this.gradients[highest.key]
        },
    },

    methods: {
        async localIndex () {
            if (!this.mixHasPermission('tasks.index')) return

            this.mixSetLoading()
            this.localResetChart()
            if (this.noFetch || this.mixLatestSyncTimeDiff() >= 5) {
                this.localNoFetchSetup()
                setTimeout(() => this.mixClearLoading(), 500)
                return
            }

            await this.localGetTaskStats()
            this.mixClearLoading()
        },

        localNoFetchSetup () {
            this.count = this.mixGetSavedSummary.tasksStats
            this.localSetupChartStats()
        },

        async localGetTaskStats () {
            const params = {
                include: 'tasksUnstartedCount,tasksStartedCount',
                'fields[projects]': 'id,total_tasks,total_completed_tasks'
            }
            const { data, status } = await this.project_show({
                id: this.mixParentState.projectId,
                stateless: true,
                params
            })

            if (status !== 'success') return
            this.count = {
                total: data.total_tasks,
                completed: data.total_completed_tasks,
                started: data.tasks_started_count,
                unstarted: data.tasks_unstarted_count,
            }
            this.localSetupChartStats()
            this.mixSaveProject({ tasksStats: this.count })
        },

        localSetupChartStats () {
            const labels = ['completed', 'started', 'unstarted']
            labels.forEach(label => {
                this.chartData.datasets[0].data.push(this.count[label])
            })
        },

        localResetChart () {
            this.chartData.datasets[0].data = []
        },

        ...mapActions('ProjectView', {
            project_show: 'show',
        }),
    }
}
</script>

import store from '../models'
import router from '../router'

async function guardUserAuthenticated(to, from, next) {
    if (!store.state.User.self.id) {
        await store.dispatch('User/me')
    }

    if (store.state.User.self.id) {
        return next()
    } else return router.replace('/login')
}

export default guardUserAuthenticated

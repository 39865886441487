<template>
	<div>
        <s-dashboard-layout>
            <template v-slot:status-filter>
                <a-flex shrink class="pl-1">
                    <a-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <a-card class="u-rounded-corners u-border d-flex align-center" flat height="36" min-width="140" max-width="140" v-on="on">
                                <a-card-text class="px-2 py-1 u-flex align-center">
                                    <template v-if="local_model_loading === filter.STATUS">
                                        <a-progress-circular color="orange darken-2" size="20" width="2" indeterminate></a-progress-circular>
                                    </template>
                                    <a-icon v-else size="20" class="ma-0" color="blue">filter_list</a-icon>
                                    <span class="md-body-2 mx-1">{{ local_filter_value_get(filter_list_status, project_filters[filter.STATUS], 'label') }}</span>
                                    <a-spacer></a-spacer>
                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <a-list class="u-list-condensed">
                            <h4 class="u-font-12 font-weight-medium grey--text pt-1 px-4">Status</h4>
                            <template v-for="item in filter_list_status">
                                <a-list-item v-if="local_filter_value_get(filter_list_status, project_filters[filter.STATUS], 'label') !== item.label" :key="'status_key_' + item.value" @click="local_filter_value_set(filter.STATUS, item.value)">
                                    <a-list-item-title>
                                        <span class="md-body-2">{{ item.label }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                                <a-list-item v-else :key="'status_key_' + item.value" class="grey lighten-4">
                                    <a-list-item-title>
                                        <span class="md-body-2">{{ item.label }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </template>
                        </a-list>
                    </a-menu>
                </a-flex>
            </template>
            <template v-slot:team-filter>
                <a-flex shrink class="pl-1">
                    <a-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <a-card class="u-rounded-corners u-border d-flex align-center" flat height="36" min-width="170" max-width="170" v-on="on">
                                <a-card-text class="px-2 py-1 u-flex align-center">
                                    <template v-if="local_model_loading === filter.HIERARCHY">
                                        <a-progress-circular color="orange darken-2" size="20" width="2" indeterminate></a-progress-circular>
                                    </template>
                                    <a-icon v-else size="20" class="ma-0" color="blue">filter_list</a-icon>
                                    <span class="md-body-2 mx-1">{{ local_filter_value_get(local_team_filter_list, project_filters[filter.HIERARCHY], 'label') }}</span>
                                    <a-spacer></a-spacer>
                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <a-list class="u-list-condensed">
                            <h4 class="u-font-12 font-weight-medium grey--text pt-1 px-4">Team</h4>
                            <template v-for="item in local_team_filter_list">
                                <a-list-item v-if="local_filter_value_get(local_team_filter_list, project_filters[filter.HIERARCHY], 'label') !== item.label" :key="'status_key_' + item.value" @click="local_filter_value_set(filter.HIERARCHY, item.value)">
                                    <a-list-item-title>
                                        <span class="md-body-2">{{ item.label }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                                <a-list-item v-else :key="'status_key_' + item.value" class="grey lighten-4">
                                    <a-list-item-title>
                                        <span class="md-body-2">{{ item.label }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </template>
                        </a-list>
                    </a-menu>
                </a-flex>
            </template>
            <template v-slot:territory>
                <a-flex shrink class="pl-1 py-0">
                    <a-card class="c-territory-filter u-rounded-corners d-inline-flex align-items-center u-border u-overflow-y" flat min-height="36" max-height="36" min-width="420" max-width="420">
                        <a-autocomplete
                            v-model="selected_territory"
                            :items="filter_list_territories"
                            ref="filter_dropdown"
                            item-text="label"
                            item-value="id"
                            :disabled="loading"
                            class="px-0"
                            return-object
                            :search-input.sync="search"
                            @input="local_selected_territory(selected_territory)"
                            solo flat hide-selected multiple hide-details small-chips
                        >
                            <template v-slot:selection="{ item, selected }">
                                <a-chip :input-value="selected" small :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}" class="chip--select-multi mx-1 mb-0" style="margin-top: 2px !important">
                                    <div class="d-inline-flex align-center">
                                        <a-icon size="16" :color="$color(item.color, 'color_text')" class="mr-1">fiber_manual_record</a-icon>
                                        <span class="md-body-2" :style="{ color: $color(item.color, 'color_text') }">{{ item.label | truncateText(30) }}</span>
                                        <a-icon size="18" v-if="!(selected_territory.length === 1 && selected_territory[0].value === 'all')" :color="$color(item.color, 'color_text')" class="u-cursor-pointer ml-1" @click="fetch_territories(selected_territory, item)">cancel</a-icon>
                                    </div>
                                </a-chip>
                            </template>
                            <template v-slot:item="{ item }">
                                <div>
                                    <a-chip small :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}" class="chip--select-multi mx-1 mb-0">
                                        <div class="d-inline-flex align-center">
                                            <a-icon size="16" :color="$color(item.color, 'color_text')" class="mr-1">fiber_manual_record</a-icon>
                                            <span class="md-body-2" :style="{ color: $color(item.color, 'color_text') }">{{ item.label | truncateText(45) }}</span>
                                        </div>
                                    </a-chip>
                                </div>
                            </template>
                        </a-autocomplete>
                    </a-card>
                </a-flex>
            </template>
            <template v-slot:sort>
                <a-flex shrink class="pl-1">
                    <a-menu bottom offset-y>
                        <template v-slot:activator="{ on }">
                            <a-card class="u-rounded-corners u-border u-flex-center-y" flat height="36" min-width="160" max-width="160" v-on="on">
                                <a-card-text class="px-2 py-1 u-flex align-center">
                                    <a-icon size="20" class="ma-0 mr-1" :color="local_filter_value_get(filter_list_sort_field, project_filters[filter.SORT], 'value') !== 'title' ? 'blue' : 'grey lighten-1'">sort</a-icon>
                                    <span class="md-body-2 mx-1">{{ local_filter_value_get(filter_list_sort_field, project_filters[filter.SORT], 'label') }}</span>
                                    <a-spacer></a-spacer>
                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <a-list class="u-list-condensed">
                            <a-list-item v-for="item in filter_list_sort_field" :key="'status_key_' + item.value" @click="local_filter_value_set(filter.SORT, item.value)">
                                <a-list-item-title>
                                    <span class="md-body-2">{{ item.label }}</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
            </template>
            <template v-slot:zoom>
                <a-flex shrink class="pl-1">
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-card class="u-rounded-corners u-border u-cursor-pointer u-flex-center" flat height="36" :class="{'u-opacity-30': interface_day_width >= 56}" @click="local_interface_zoom_in(8)" v-on="on">
                                <a-card-text class="px-2 py-1">
                                    <a-icon size="20" class="ma-0">zoom_in</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <span>Zoom In</span>
                    </a-tooltip>
                </a-flex>
                <a-flex shrink class="pl-1">
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-card class="u-rounded-corners u-border u-cursor-pointer u-flex-center" flat height="36" :class="{'u-opacity-30': interface_day_width <= 8}" @click="local_interface_zoom_out(8)" v-on="on">
                                <a-card-text class="px-2 py-1">
                                    <a-icon size="20" class="ma-0">zoom_out</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <span>Zoom Out</span>
                    </a-tooltip>
                </a-flex>
            </template>
            <div class="u-flex-center-y">
                <a-sheet class="md-caption pa-2 mb-2 mt-n0 d-inline-flex align-center orange lighten-4 u-rounded-corners">
                    <a-icon size="16" color="brown darken-1" class="u-icon-nudge mr-2" style="top: 0px;">info</a-icon>
                    <span class="font-weight-medium brown--text text--darken-1 md-body-2">Projects without start date are not included in the view</span>
                </a-sheet>
                <a-spacer></a-spacer>
                <p class="md-body-2 grey--text text--darken-3 mb-0 mt-1">{{ (project_list.length === 0) ? 'No Projects' : ((project_list.length === 1) ? '1 Project' : project_list.length + ' Projects' ) }}</p>
            </div>
        </s-dashboard-layout>
        <a-sheet color="#eee" height="1" class="u-wfull"></a-sheet>
        <div class="c-timeline" style="height: calc(100vh - 204px); overflow: auto;" @scroll="local_scroll_event($event)">
            <div class="c-timeline-headers white u-shadow-md mb-2" :style="`width: ${interface_day_width * timeline_days_count}px; position: sticky; top: 0; z-index: 2;`">
                <div class="d-flex">
                    <div v-for="index in timeline_months_count" :key="'month_'+index" :style="`flex-grow: 0; flex-shrink: 0; min-width: ${local_moment_add(timeline_start_date, index-1, 'month').daysInMonth() * interface_day_width}px; max-width: ${local_moment_add(timeline_start_date, index-1, 'months').daysInMonth() * interface_day_width}px; text-align: center; border-bottom: 1px solid #eee; border-right: 1px solid #eee; padding-top: 12px; padding-bottom: 12px;`">
                        <h2 class="md-subtitle-2 font-weight-bold grey--text text--darken-3">{{ local_moment_add(timeline_start_date, index-1, 'months').format('MMMM YYYY') }}</h2>
                    </div>
                </div>
                <div class="d-flex" v-if="interface_day_width > 8">
                    <div v-for="index in timeline_days_count" :key="'day_'+index" :style="`flex-grow: 0; flex-shrink: 0; min-width: ${interface_day_width}px; max-width: ${interface_day_width}px; text-align: center; border-bottom: 1px solid #eee; border-right: 1px solid #eee; padding-top: 8px; padding-bottom: 8px;`">
                        <span class="md-caption">{{ local_moment_add(timeline_start_date, index-1, 'days').format('D') }}</span>
                    </div>
                </div>
            </div>
            <div class="d-flex" :style="`width: ${timeline_days_count * interface_day_width}px; position: relative; height: 48px;`" v-for="project in project_list" :key="project.id">
                <a-card class="c-timeline-bar u-cursor-pointer d-inline-flex align-center u-rounded-corners u-shadow-md ma-0 px-2 py-1" :style="`width: ${local_project_duration(project) * interface_day_width}px; position: absolute; top: 8px; left: ${local_project_start_offset(project) * interface_day_width}px;`">
                    <div class="u-flex-center-y u-sticky text-truncate" style="left: 20px;">
                        <router-link :title="project.title" class="md-caption mb-0 text-truncate d-inline-flex align-center" target="_blank" :to="{name: 'projects-overview', params: {id: project.id}}">
                            <a-icon v-if="project.status !== 'active'" color="grey darken-1" size="16" style="position: relative;">fiber_manual_record</a-icon>
                            <a-icon v-if="project.status === 'active' && project.health_color === 'green'" color="green" size="16" style="position: relative;">fiber_manual_record</a-icon>
                            <a-icon v-if="project.status === 'active' && project.health_color === 'yellow'" color="orange lighten-2" size="16" style="position: relative;">fiber_manual_record</a-icon>
                            <a-icon v-if="project.status === 'active' && project.health_color === 'red'" color="red" size="16" style="position: relative;">fiber_manual_record</a-icon>
                            <span class="u-opacity-50 ml-1" v-if="project.customer" :title="project.customer.name && project.customer.name.length >= 30 ? project.customer.name : ''">{{ project.customer.name | truncateText(30) }}</span>
                            <h3 class="ml-2 font-weight-bold d-inline-block text-truncate" style="max-width: 600px">
                                {{ project.title }}
                            </h3>
                            <a-tooltip bottom v-if="project.due_date === null">
                                <template v-slot:activator="{ on }">
                                    <a-icon class="ml-1 yellow--text text--darken-3" size="14" v-on="on">error</a-icon>
                                </template>
                                <span>No End Date</span>
                            </a-tooltip>
                            <a-icon class="ml-1" color="grey" size="14">launch</a-icon>
                        </router-link>
                    </div>
                </a-card>
                <div v-for="index in timeline_days_count" :key="index" :style="`flex-grow: 0; flex-shrink: 0; min-width: ${interface_day_width}px; max-width: ${interface_day_width}px; text-align: center; border-left: 1px solid #eee;`"></div>
            </div>
        </div>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import { SDashboardLayout } from '@/config/config-shared-components'
// import DashboardInsightsMiniComponent from './DashboardInsightsMini'
import loadWorkspace from '@/mixins/mixin-workspace'

export default {
    mixins: [loadWorkspace],
    components: { SDashboardLayout },
    data() {
        return {
            local_model_loading: null,
            timeline_start_date: null,
            timeline_end_date: null,
            timeline_days_count: null,
            timeline_months_count: null,
            interface_day_width: 16,
            filter_list_status: [
                {'label': 'Active', 'value': 'active'},
                {'label': 'Queue', 'value': 'queue'},
                {'label': 'Completed', 'value': 'completed'},
                {'label': 'All Projects', 'value': 'all'},
            ],
            filter_list_manager: [
                {'label': 'All Projects', 'value': 'all'},
                {'label': 'Direct Reports', 'value': 'direct_reports'},
                {'label': 'All Reports', 'value': 'all_reports'},
                {'label': 'Assigned to me', 'value': 'self'},
            ],
            filter_list_team: [
                {'label': 'All Projects', 'value': 'all'},
                {'label': 'Assigned to me', 'value': 'self'}
            ],
            filter_list_sort_field: [
                {'label': 'Title', 'value': 'title'},
                {'label': 'Start Date', 'value': 'start_date'},
                {'label': 'End Date', 'value': 'due_date'},
            ],
            selected_territory: [],
            search: '',
            local_territory_filters: [],
            sort_direction: '',
            project_filters_index: {
                'include': 'customer',
                'fields[projects]': 'id,title,status,health_color,customer_id,start_date,due_date',
                'fields[customer]': 'id,name',
                // 'fields[stage]': 'id,name',
                'fields[result]': 'id,status,value',
                // 'fields[tags]': 'tags.id,tags.label,tags.type,tags.color',
                // 'fields[territories]': 'territories.id,territories.name,territories.color',
                // 'fields[keyCollaborators]': 'id,scope,type_id,user_id',
                // 'fields[keyCollaborators.user]': 'id,name,color,initial',
                // 'fields[keyCollaborators.type]': 'id,value',
            },
            project_filters: {},
            project_includes: 'customer,result',
            filter: {
                SORT: 'sort',
                STATUS: 'filter[status]',
                HIERARCHY: 'filter[hierarchy_report]',
                WORKSPACE: 'filter[workspace_id]',
                TERRITORY: 'filter[territory]',
                START_DATE: 'filter[start_date]',
            },
            local_team_filter_list: [],
        }
    },
    watch: {
        workspace_current(oldValue, newValue) {
            this.local_index()
        }
    },
    computed: {
        filter_list_territories() {
            const result = [];
            result.push({'label': 'All Territories', 'value': 'all', 'color': 'blue-grey-dark'})
            this.territory_list.forEach(element => {
                result.push({'label': element.name, 'value': element.id, 'color': element.color})
            })
            result.push({'label': 'No Territory', 'value': 'none', 'color': 'blue-grey-dark'})
            return result;
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Project', {
            project_list: 'list',
            project_response: 'response',
            // project_filters: 'filters',
            project_meta: 'meta',
        }),
        ...mapState('Territory', {
            territory_list: 'list',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),
    },
    mounted() {
        this.local_index()
    },
    methods: {
        async local_index() {
            this.local_timeline_setup_start_date()
            this.local_timeline_setup_end_date()
            this.local_timeline_setup_calculate_count()

            this.territory_clear()
            await this.territory_index({ 'fields[territories]': 'id,name,color', 'count': 999 })

            if (this.user_self && this.user_self.territories && this.user_self.territories.length) {
                this.user_self.territories.length === 1 ? this.project_filters[this.filter.TERRITORY] = this.user_self.territories[0].id : this.project_filters[this.filter.TERRITORY] = _.map(this.user_self.territories, 'id')
            } else {
                this.project_filters[this.filter.TERRITORY] = 'all' // If user doesn't have any territory - set the default to "all" ( To show all Projects )
            }
            this.local_add_filters()
            this.selected_territory = await this.local_territories_filter()
            await this.project_index({
                ...this.project_filters,
                ...this.project_filters_index
            })
            this.local_set_hierarchy_list()

            let content = document.querySelector(".c-timeline");
            content.scrollLeft = 2475;
        },
        local_add_filters() {
            this.project_filters[this.filter.WORKSPACE] = this.current_workspace_id_mixin
            this.project_filters[this.filter.STATUS] = 'active'
            this.project_filters[this.filter.HIERARCHY] = 'all'
            this.project_filters[this.filter.START_DATE] = 'not_null'
            // this.project_filters[this.filter.PAGE] = 1
            // this.project_filters[this.filter.COUNT] = 999
            // this.project_filters[this.filter.FIELDS] = ['title', 'deal_amount', 'start_date', 'due_date', 'status'];
            // this.project_filters[this.filter.ATTRIBUTES] = ['health_color'];
            // this.project_filters.relationships = ['customer', 'health', 'territories'];
            // this.project_filters[this.filter.RELATIONSHIPS] = ['customer', 'territories'];
            // this.project_filters[this.filter.COUNTS] = ['none'];
            this.project_filters[this.filter.SORT] = 'due_date'
            // this.project_filters[this.filter.SORT_DIR] = 'asc'
        },
        async local_selected_territory(val) {
            let all_territory = _.find(val, ['value', 'all'])
            let none_territory = _.find(val, ['value', 'none'])
            let all_territory_index = this.selected_territory.length > 1 ? this.selected_territory.findIndex(item => item && item.value === 'all') === this.selected_territory.length - 1 : ''
            let none_territory_index = this.selected_territory.length > 1 ? this.selected_territory.findIndex(item => item && item.value === 'none') === this.selected_territory.length - 1 : ''

            this.$refs.filter_dropdown.ref.blur()
            this.$nextTick(async () => {
                if (all_territory_index) {
                    this.selected_territory = this.selected_territory.filter(item => item && item.value === 'all')
                    await this.fetch_territories(this.selected_territory)
                    return
                }
                if (none_territory_index) {
                    this.selected_territory = this.selected_territory.filter(item => item && item.value === 'none')
                    await this.fetch_territories(this.selected_territory)
                    return
                }
                if (all_territory && this.selected_territory.length === 1) {
                    await this.local_filter_value_set(this.filter.TERRITORY, all_territory.value)
                    return
                }
                if (none_territory && this.selected_territory.length === 1) {
                    await this.local_filter_value_set(this.filter.TERRITORY, none_territory.value)
                    return
                }
                if (all_territory && this.selected_territory.length > 1 && !all_territory_index) {
                    this.selected_territory.splice(this.selected_territory.findIndex(i => i.value === 'all'), 1)
                }
                if (none_territory && this.selected_territory.length > 1 && !none_territory_index) {
                    this.selected_territory.splice(this.selected_territory.findIndex(i => i.value === 'none'), 1)
                }
                await this.fetch_territories(val)
            })
        },
        local_filter_value_get(list, value, key) {
            if (value && typeof value === 'string' && value.charAt(0) === '-') return _.get(_.find(list, {value: value.substring(1)}), key)
            return _.get(_.find(list, {value: value}), key)
        },
        async local_territories_filter(list = this.filter_list_territories, value = this.project_filters[this.filter.TERRITORY], key = 'label') {
            if (Array.isArray(value)) {
                let filtered_territories_label = []
                value.filter(item => filtered_territories_label.push(_.find(list, ['value', item])))
                return filtered_territories_label
            }
            return [_.find(list, {value: value})]
        },
        async fetch_territories(val, territory_to_remove = null) {
            this.project_filters[this.filter.TERRITORY] = []
            territory_to_remove ? this.selected_territory.splice(this.selected_territory.findIndex(i => i.value === territory_to_remove.value), 1) : null
            if (this.selected_territory.length > 0) {
                await this.local_filter_value_set(this.filter.TERRITORY, this.selected_territory)
            } else {
                this.selected_territory.push({'label': 'All Territories', 'value': 'all', 'color': 'blue-grey-dark'})
                await this.local_filter_value_set(this.filter.TERRITORY, 'all')
            }
        },
        async local_filter_value_set(filter, value) {
            this.local_model_loading = filter
            if (value === 'all' || value === 'none' && !Array.isArray(value)) {
                // if (filter === 'sort_direction') {
                //     this.project_filters[this.filter.SORT] = this.project_filters[this.filter.SORT].charAt(0) === '-' ? this.project_filters[this.filter.SORT].substring(1) : this.project_filters[this.filter.SORT]
                //     this.project_filters[this.filter.SORT] = `${value}${this.project_filters[this.filter.SORT]}`
                // }
                this.project_filters[filter] = value
                this.local_filter_project()
            } else {
                if (filter === this.filter.TERRITORY) {
                    const finalValue = value.filter(item => item), territoryArr = []
                    finalValue.forEach(item => {
                        if (item.value === 'all' || item.value === 'none') {
                            this.project_filters[filter] = item.value
                        } else {
                            territoryArr.push(item.value)
                            this.project_filters[filter] = [...this.project_filters[filter], ...territoryArr]
                        }
                        this.local_filter_project()
                    })
                } else {
                    if (filter === 'sort_direction') {
                        this.sort_direction = value
                        this.project_filters[this.filter.SORT] = this.project_filters[this.filter.SORT].charAt(0) === '-' ? this.project_filters[this.filter.SORT].substring(1) : this.project_filters[this.filter.SORT]
                        this.project_filters[this.filter.SORT] = `${value}${this.project_filters[this.filter.SORT]}`
                    } else if (filter === 'sort') {
                        this.project_filters[filter] = value
                        this.project_filters[filter] = this.project_filters[filter].charAt(0) === '-' ? this.project_filters[filter].substring(1) : this.project_filters[filter]
                        this.project_filters[filter] = `${this.sort_direction}${this.project_filters[filter]}`
                    } else {
                        // filter === this.filter.STATUS && !value ? this.project_filters[filter] = null : this.project_filters[filter] = value
                        this.project_filters[filter] = value
                    }
                    this.local_filter_project()
                }
            }
        },
        async local_filter_project () {
            await this.project_index({
                ...this.project_filters,
                ...this.project_filters_index
            })
            this.local_model_loading = null
        },
        async local_sort_project (sortKey, sortValue) {
            await this.project_index({
                ...this.project_filters_index,
                sort: sortValue.toLowerCase(),
                'filter[workspace_id]': this.current_workspace_id_mixin,
            })
        },
        async local_timeline_setup_start_date() {
            this.timeline_start_date = moment().subtract(6, 'month').startOf('month')
        },
        async local_timeline_setup_end_date() {
            this.timeline_end_date = moment().add(6, 'month').endOf('month')
        },
        async local_timeline_setup_calculate_count() {
            this.timeline_days_count = Math.ceil(this.timeline_end_date.diff(this.timeline_start_date, 'days', true))
            this.timeline_months_count = Math.ceil(this.timeline_end_date.diff(this.timeline_start_date, 'months', true))
        },
        async local_timeline_prepend_month(count) {
            this.timeline_start_date = moment(this.timeline_start_date).subtract(count, 'month').startOf('month')
            await this.local_timeline_setup_calculate_count()
        },
        async local_timeline_append_month(count) {
            this.timeline_end_date = moment(this.timeline_end_date).add(count, 'month').endOf('month')
            await this.local_timeline_setup_calculate_count()
        },
        local_moment_add(moment_object, count, unit) {
            return moment(moment_object).add(count, unit)
        },
        local_interface_zoom_in(amount) {
            if (this.interface_day_width === 56) return;
            this.interface_day_width += amount
        },
        local_interface_zoom_out(amount) {
            if (this.interface_day_width === 8) return;
            this.interface_day_width -= amount
        },
        local_project_duration(project) {
            if (project.due_date !== null) {
                return Math.floor(moment(project.due_date).diff(moment(project.start_date), 'days', true)) + 1
            }
            let start_offset = this.local_project_start_offset(project)
            return (this.timeline_days_count - start_offset);
        },
        local_project_start_offset(project) {
            let count = Math.ceil(moment(project.start_date).diff(this.timeline_start_date, 'days', true))
            if (count < 0) { count++ }
            return count
        },
        local_scroll_event(e) {
            // if (e.target.scrollLeft === 0) {
            //     this.local_timeline_prepend_month(3)
            // }
            // if (e.target.scrollLeft === e.target.scrollLeftMax) {
            //     this.local_timeline_append_month(3)
            // }
        },
        local_set_hierarchy_list () {
            if (this.user_self && this.user_self.is_manager) return this.local_team_filter_list = this.filter_list_manager
            this.local_team_filter_list = this.filter_list_team
        },
        local_emit_change_dashboard_view(view) {
            this.$emit('changeDashboardView', view)
        },
        ...mapActions('Project', {
            project_index: 'index',
            project_clear: 'clear',
        }),
        ...mapActions('Territory', {
            territory_index: 'index',
            territory_clear: 'clear',
        }),
    }
}
</script>

<template>
    <a-main>
        <a-container container--fluid grid-list-xl pa-0>
            <template v-if="isLogLoading">
                <PAutomationWorkflowLogListLoader/>
            </template>
            <template>
            <a-layout align-center v-if="!isLogLoading">
                <a-flex shrink pr-2>
                    <a-menu bottom offset-y v-model="statusDropdown" :close-on-content-click="false"  min-width="240" max-width="240">
                        <template v-slot:activator="{ on }">
                            <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="240" v-on="on">
                                <span class="md-body-2 grey--text text--darken-2 u-flex mx-1">
                                    <a-icon size="20" :color="localIsStatusFilter ? 'blue' : 'grey darken-1'" class="mr-1 u-icon-nudge">filter_list</a-icon>
                                    <span>Status:</span>
                                    <span class="ml-1 grey--text text--darken-3 font-weight-medium">
                                        <template v-if="selectedStatusValues && selectedStatusValues.length === 1">
                                            <span class="md-body-2 grey--text text--darken-3 ml-1 text-capitalize">{{ localLogGetStatusValue(selectedStatusValues[0]) }}</span>
                                        </template>
                                        <template v-else-if="selectedStatusValues && selectedStatusValues.length > 1">{{ selectedStatusValues.length + ' Selected' }}</template>
                                        <template v-else>All Status</template>
                                    </span>
                                </span>
                                <a-spacer></a-spacer>
                                <a-progress-circular v-if="filterLoadingType === 'status'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                <a-icon v-else size="20" class="ma-0">arrow_drop_{{ statusDropdown ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed u-overflow-y" style="max-height: 300px;">
                            <a-list-item v-for="(status, index) in logStatus" class="pr-5" :key="'status'+index" @click="$emit('filter-status', status.value)">
                                <a-list-item-icon class="ma-0 pa-0 u-flex justify-center">
                                    <a-checkbox dense hide-details color="blue darken-2" :value="localAutomationStatusIsSelected(status.value)" class="ma-0 pa-0"></a-checkbox>
                                </a-list-item-icon>
                                <a-list-item-title>
                                    <div class="u-flex">
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">{{ status.label }}</span>
                                        <a-spacer></a-spacer>
                                        <div class="c-filter-status" :class="['c-filter-'+status.value]"></div>
                                    </div>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                        <a-divider></a-divider>
                        <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                            <a-list-item class="pr-5" @click="$emit('filter-status', null)">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">All Status</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-flex shrink pr-2>
                    <a-menu bottom offset-y v-model="timeDropdown"  min-width="197" max-width="197">
                        <template v-slot:activator="{ on }">
                            <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="197" v-on="on">
                                <span class="md-body-2 grey--text text--darken-2 mx-1">
                                    <a-icon size="20" :color="selectedTimeValue && selectedTimeValue.value !== 1 && localIsTimeFilter ? 'blue' : 'grey darken-1'" class="mr-1 u-icon-nudge">filter_list</a-icon>
                                    <span>Time:</span>
                                    <span class="ml-1 grey--text text--darken-3 font-weight-medium">{{ selectedTimeValue.label }}</span>
                                </span>
                                <a-spacer></a-spacer>
                                <a-progress-circular v-if="filterLoadingType === 'time'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                <a-icon v-else size="20" class="ma-0">arrow_drop_{{ timeDropdown ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                            <a-list-item v-for="local_time in logTimes" class="pr-5" :class="{ 'grey lighten-4': selectedTimeValue.value === local_time.value }" :key="local_time.value" @click="$emit('filter-time', local_time)">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">{{ local_time.label }}</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
                </a-layout>
                <a-layout align-center class="u-rounded-corners pb-12 mt-2" v-if="list && list.length">
                    <a-flex pt-0>
                        <a-sheet class="u-overflow-hidden">
                            <a-container container--fluid grid-list-xl py-4 px-5>
                                <a-layout align-center>
                                    <a-flex shrink xs4 xl4>
                                        <h2 class="md-subtitle-1 font-weight-bold indigo--text text--darken-1">Trigger</h2>
                                    </a-flex>
                                    <a-flex shrink xs4 xl4>
                                        <h2 class="md-subtitle-1 font-weight-bold indigo--text text--darken-1">Status</h2>
                                    </a-flex>
                                    <a-flex shrink xs4 xl4>
                                        <h2 class="md-subtitle-1 font-weight-bold indigo--text text--darken-1">Date & Time</h2>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-sheet>
                        <a-divider></a-divider>
                        <div v-for="(item, index) in list" :key="item.id">
                            <a-sheet class="u-overflow-hidden">
                                <a-container container--fluid grid-list-xl py-4 px-5 class="u-cursor-pointer" @click="localShowLogInfo(item.id)">
                                    <a-layout align-center>
                                        <a-flex shrink xs4 xl4>
                                            <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-3" v-if="item.automation_trigger" :title="item.automation_trigger.title">{{ item.automation_trigger.title | truncateText(60) }}</h2>
                                            <div class="u-flex mt-1">
                                                <a-icon size="20" color="blue accent-4">tune</a-icon>
                                                <span class="mx-2 md-subtitle-2 grey--text" style="font-weight: 700;">{{ item.automation_condition_count }} 
                                                    <a-icon size="16" color="grey" style="font-weight: 700;" class="u-icon-nudge-xs">arrow_forward</a-icon>
                                                </span>
                                                <template v-if="type === 'validation'">
                                                    <a-icon size="20" color="pink">fact_check</a-icon>
                                                    <span class="mx-2 md-subtitle-2 grey--text" style="font-weight: 700;">{{ item.automation_validation_count }}</span>
                                                </template>
                                                <template v-if="type === 'action'">
                                                    <a-icon size="20" color="purple darken-1">settings_suggest</a-icon>
                                                    <span class="mx-2 md-subtitle-2 grey--text" style="font-weight: 700;">{{ item.automation_action_count }}</span>
                                                </template>
                                            </div>
                                        </a-flex>
                                        <a-flex shrink xs4 xl4>
                                            <div class="c-log-status c-log-warning" style="width: 146px;" v-if="item.status === 'condition-failed'">Condition Not Met</div>
                                            <div class="c-log-status c-log-error" style="width: 134px;" v-if="item.status === 'validation-failed'">Validation Failed</div>
                                            <div class="c-log-status c-log-warning" style="width: 134px;" v-if="item.status === 'action-failed'">Action Failed</div>
                                            <div v-if="item.status === 'success'" class="c-log-status c-log-success" style="width: 90px;">Success</div>
                                        </a-flex>
                                        <a-flex shrink xs4 xl4>
                                            <g-moment tag="h2" :value="item.updated_at" class="md-subtitle-1 font-weight-medium grey--text text--darken-2" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-sheet>
                            <a-divider></a-divider>
                        </div>
                    </a-flex>
                </a-layout>
            </template>
            <template v-if="!isLogLoading && list && list.length === 0">
                <a-container container--fluid grid-list-xl class="mt-12">
                    <a-layout align-center justify-center>
                        <a-flex xs4>
                            <img :src="require('../../../assets/images/automation/list-empty.svg')" alt="empty-logs" class="u-wfull" style="width: 70%; margin: 0px auto; display: block;">
                            <h2 class="md-body-1 font-weight-medium mb-0 mt-8 grey--text text--darken-3 text-center text-uppercase">No Logs found!</h2>
                            <p class="md-body-2 mb-0 mt-3 grey--text text--darken-1 text-center">There is no data to fetch right now.Try narrowing your filter or add new {{ type === 'action' ? 'automation' : type }}.</p>
                        </a-flex>
                    </a-layout>
                </a-container>
            </template>
        </a-container>
        <!-- Automation log Popup -->
        <a-dialog v-model="logsDialog" scrollable max-width="700">
            <SAutomationLogInfo
                :item="automation_log_item"
                :isLoading="mixinIsLoading('workflow-log-show')"
                :logType="'automation'"
                @close="localLogDialogClose"
            />
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import { SAutomationLogInfo } from '@/config/config-shared-components'
import PAutomationWorkflowLogListLoader from './PartialAutomationWorkflowLogListLoader'

export default {
    mixins: [
        mixinLoading
    ],

    components: {
        SAutomationLogInfo,
        PAutomationWorkflowLogListLoader,
    },

    props: {
        list: {
            type: Array,
            default: [],
        },
        selectedStatusValues: {
            type: Array,
            default: [],
        },
        selectedTimeValue: {
            type: Object,
            default: {},
        },
        type: {
            type: String,
            default: null,
        },
        isLogLoading:{
            type: Boolean,
            default: true
        },
        filterLoadingType: {
            type: String,
            default: null,
        }
    },

    data() {
        return {
            statusDropdown: false,
            timeDropdown: false,
            logsDialog: false,

            localLogInfoType: 'conditions',

            localLogConditionList: [],
            localLogValidationList: [],

            logActionStatus: [
                { label: 'Condition Failed', value: 'condition-failed' },
                { label: 'Success', value: 'success' },
            ],
            logValidationStatus: [
                { label: 'Condition Failed', value: 'condition-failed' },
                { label: 'Validation Failed', value: 'validation-failed' },
                { label: 'Success', value: 'success' },
            ],
            logTimes: [
                { label: 'Last 1 hour', value: 1 },
                { label: 'Last 8 hour', value: 8 },
                { label: 'Last 24 hour', value: 24 },
                { label: 'Last 7 days', value: 7},
            ],

            automation_log_filters: {},
            AUTOMATION_LOG_FILTERS: {
                FIEDS: 'fields[automation_logs]',
                INCLUDE: 'include',
                STATUS: 'filter[status]',
                CREATED_BY: 'fields[createdBy]',
                UPDATED_BY: 'fields[updatedBy]',
                TRIGGER: 'fields[automationTrigger]',
                WORKFLOW_ID: 'filter[automation_workflow_id]',
                WORKFLOW: 'fields[automationWorkflow]',
                TIME: 'filter[created_at]',
                SORT: 'sort',
                STATUS: 'filter[status]',
            },
        }
    },

    computed: {
        localIsTimeFilter () {
            return this.selectedTimeValue && this.selectedTimeValue.label !== 'All time'
        },

        logStatus () {
            if (this.type === 'action') return this.logActionStatus

            return this.logValidationStatus
        },

        localIsStatusFilter () {
            return this.selectedStatusValues && this.selectedStatusValues.length
        },

        ...mapState('AutomationLog', {
            automation_log_item: 'item',
        }),
    },

    methods: {
        async localShowLogInfo (id) {
            await this.automation_log_clear_item()
            this.localAutomationLogShow(id)
            this.logsDialog = true
        },

        localLogDialogClose () {
            this.logsDialog = false
        },

        localAutomationStatusIsSelected (value) {
            return this.selectedStatusValues.includes(value)
        },

        localLogGetStatusValue (value) {
            const log = this.logStatus.find(item => item.value === value)
            return log ? log.label : ''
        },

        localUpdateLogInfoType (type) {
            this.localLogInfoType = type
        },

        async localAutomationLogShow (id) {
            this.mixinSetLoading('workflow-log-show')
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.SORT] = null
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.FIEDS] = 'id,stage_from,stage_to,resource_name,resource_type,resource_id,status,automation_trigger_id,automation_workflow_id,created_by_id,created_at'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.INCLUDE] = 'automationTrigger,createdBy,automationWorkflow,automationLogEntries'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.CREATED_BY] = 'id,name'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.UPDATED_BY] = 'id,name'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.WORKFLOW] = 'id,title,type'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TRIGGER] = 'id,title,slug'
            await this.automation_log_show({ id: id, params: this.automation_log_filters })
            this.mixinResetLoading('workflow-log-show')
        },

        ...mapActions('AutomationLog', {
            automation_log_show:'show',
            automation_log_clear_item : 'clear_item',
        }),
    },
}
</script>

<style lang="scss" scoped>
.c-filter-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.c-filter-success{
    background: #7aee80 !important;
    position: relative;
    top: 4px;
}
.c-filter-condition-failed, .c-filter-action-failed {
    background: #fce49e !important;
    position: relative;
    top: 4px;
}
.c-filter-validation-failed {
    background: #E53935 !important;
    position: relative;
    top: 4px;
}
.c-log-error {
    background: #FFEBEE !important;
    color: #E53935;
}
.c-log-status {
    border-radius: 4px !important;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.26px;
    text-transform: uppercase;
    padding: 4px;
}
.c-log-success {
    background: #C8E6C9 !important;
    color: #43A047;
}
.c-log-warning {
    background: #FFECB3 !important;
    color: #FF8F00;
}
</style>


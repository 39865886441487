<template>
    <div>
        <div v-for="(tasks, header, index) in taskGroup" class="mb-6" :key="'task_group_'+header">
            <a-card flat class="u-border u-cursor-pointer">
                <PartialTaskGroupHeader
                    :tasks="tasks"
                    :groupedBy="groupBy"
                    :header="header"
                    :tasksCount="tasks && tasks.length || 0"
                    :isClosed="!!localIsAccordianClosed(index)"
                    @collapse="localCollapse(index)"
                    @expand="localExpand(index)"
                    @click="localIsAccordianClosed(index) ? localCollapse(index) : localExpand(index)"
                />
                <a-divider></a-divider>
                <div v-if="localIsAccordianClosed(index) || groupBy === 'ungrouped'">
                    <slot name="tasks-list" :header="header" :headerIndex="index" :tasks="tasks"></slot>
                </div>
            </a-card>
        </div>
    </div>
</template>

<script>
import PartialTaskGroupHeader from './PartialTaskGroupHeader.vue'
import { SComponentIsVisible } from '@/config/config-shared-components'

export default {
    components: { SComponentIsVisible, PartialTaskGroupHeader },

    props: {
        taskGroup: {
            type: Object
        },
        groupBy: {
            type: String
        },
    },

    data () {
        return {
            shrinked_projects: [],
        }
    },

    methods: {
        localCollapse (index) {
            if (this.groupBy === 'ungrouped') return

            const item = this.shrinked_projects.find(item => item === index)
            if (!item) this.shrinked_projects.push(index)
        },

        localExpand (index) {
            if (this.groupBy === 'ungrouped') return

            const itemIndex = this.shrinked_projects.findIndex(item => item === index)
            if (itemIndex !== -1) this.shrinked_projects.splice(itemIndex, 1)
        },

        localIsAccordianClosed (index) {
            const projectIndex = this.shrinked_projects.findIndex(item => item === index)
            return projectIndex !== -1 ? false : true
        },
    }
}
</script>

<template>
    <div class="mb-12 u-relative">
        <div>
            <div class="u-rounded-corners-lg py-0 pb-6">
                <!-- Loading -->
                <div v-if="local_loading" class="mt-6">
                    <PartialProjectUcsLoader
                        v-for="count in 7"
                        :key="count"
                        class="mb-4 u-border u-rounded-corners-lg pa-4 mb-6"
                    />
                </div>

                <template v-if="!local_loading">
                    <!-- Toolbar -->
                    <PListViewToolbar
                        :tcStatuses="testcase_status_list"
                        :scStatuses="success_criterion_status_list"
                        :selectAllState="local_get_select_all_state"
                        :modeSelected="filter_mode_selected"
                        :checkedAll="filter_checkbox_all"
                        :ucBulkCount="uc_bulk_ids.length"
                        :scBulkCount="sc_bulk_ids.length"
                        :tcBulkCount="tc_bulk_ids.length"
                        :selectedFilter="filter_selected"
                        :bulk-loading="local_has_bulk_ops_loading"
                        @clearBulkSelection="local_clear_bulk_selection"
                        @filterSelectAll="local_filter_select_all"
                        @filterByStatus="local_filter_by_status"
                        @filterSelected="local_on_filter_selected"
                        @expandAll="local_on_expand_collapse_all"
                        @applyAction="local_on_apply_action"
                        @bulkAssign="local_bulk_assign_modal_open"
                        @bulkRemove="local_bulk_remove_modal_open"
                        @bulkStatusUpdate="local_bulk_status_update_model"
                        @removeCollaborators="local_remove_collabs_model"
                        @addTags="local_add_tags_modal"
                        @removeTags="local_remove_tags_modal"
                        @bulkDelete="local_bulk_delete_model"
                        @exportAsTemplate="local_template_export_form"
                        class="mt-4 mb-6"
                    >
                        <template #usecase-add>
                            <a-sheet class="u-rounded-corners-lg u-border mt-6 u-relative" :class="[local_is_loading('usecase-bulk-store') ? 'grey lighten-5' : '']">
                                <v-text-field
                                    v-model="usecase_title"
                                    @input="e => !e ? usecase_title_error = null : ''"
                                    @keydown.enter="local_usecase_store"
                                    @paste="local_bulk_usecase_store"
                                    :disabled="local_is_loading('usecase-bulk-store')"
                                    ref="refUsecaseCreateInput"
                                    placeholder="Add New Use Case & Hit Enter"
                                    class="px-2 md-subtitle-1"
                                    :class="[{ 'c-wiggle-short u-border-error': usecase_title_error }]"
                                    background-color="transparent"
                                    solo flat hide-details
                                >
                                    <template #prepend-inner>
                                        <div class="mr-1">
                                            <a-progress-circular v-if="local_is_loading('usecase-bulk-store')" width="2" size="20" class="mr-1" indeterminate color="orange darken-2"></a-progress-circular>
                                            <a-icon v-else size="24" color="grey darken-1">add_circle_outline</a-icon>
                                        </div>
                                    </template>
                                    <template #append v-if="usecase_title_error">
                                        <a-tooltip bottom offset-y>
                                            <template v-slot:activator="{ on }">
                                                <a-icon color="red darken-1" size="20" v-on="on">info</a-icon>
                                            </template>
                                            <span>{{ usecase_title_error }}</span>
                                        </a-tooltip>
                                    </template>
                                </v-text-field>
                                <!-- <span v-if="usecase_title_error" class="u-absolute grey lighten-5 u-border" style="bottom: -24px; left: 32px;">
                                    <a-icon>info</a-icon>
                                    <span>{{ usecase_title_error }}</span>
                                </span> -->
                            </a-sheet>

                            <v-slide-y-transition hide-on-leave leave-absolute>
                                <PReviewUpdateBar
                                    v-if="error_bulk_items.uc.length"
                                    @cancel="local_cancel_review_update('uc')"
                                    @update="local_open_review_uc"
                                    height="48"
                                    module="Use Cases"
                                    class="u-rounded-corners-lg mt-6"
                                />
                            </v-slide-y-transition>
                        </template>

                        <template #show-selected="{ visible }">
                            <div class="mt-6 u-flex-center-y u-cursor-pointer" :class="{ 'mr-5': filter_selected.value !== 'all-uc' }" v-ripple="false">
                                <g-toggle custom-bg dense-xl hide-clear-icon color="blue" class="u-flex-center-y" :disabled="!visible" :value="show_selected" @change="local_toggle_selected()">
                                    <span class="ml-2 md-caption" :class="[(show_selected ? 'font-weight-medium blue' : 'grey') + '--text text--darken-1']" style="margin-bottom: 0px; letter-spacing: 0.5px;">SHOW SELECTED</span>
                                </g-toggle>
                            </div>
                        </template>

                        <template #select-all="{ loading }">
                            <a-sheet class="c-check-box mr-4 u-border u-rounded-corners-lg d-inline-flex align-center pl-2 pr-0">
                                <a-checkbox
                                    v-model="filter_checkbox_all"
                                    @change="local_filter_select_all"
                                    :indeterminate="local_get_select_all_state"
                                    :ripple="false"
                                    :disabled="local_has_bulk_ops_loading || loading"
                                    class="ma-0 pa-0 py-2"
                                    color="blue"
                                    hide-details dense
                                ></a-checkbox>
                                <a-menu offset-y bottom content-class="u-rounded-corners-lg" :disabled="local_has_bulk_ops_loading || loading" nudge-bottom="2" :min-width="filter_mode_selected.mode_slug && ['status', 'visibility'].includes(filter_mode_selected.mode_slug) ? 220 : 150">
                                    <template v-slot:activator="{ on, value }">
                                        <div v-on="on" class="u-flex-center-y u-cursor-pointer" :class="[(!filter_mode_selected.mode_slug || !['status', 'visibility'].includes(filter_mode_selected.mode_slug)) ? 'py-2 px-1' : 'py-2 pl-3 pr-2']" v-ripple="!filter_mode_selected.mode_slug || !['status', 'visibility'].includes(filter_mode_selected.mode_slug)" :style="[{ borderRadius: '0px 4px 4px 0px' }, (filter_mode_selected.mode_slug && ['status', 'visibility'].includes(filter_mode_selected.mode_slug)) ? { minWidth: '120px' } : '', { minHeight: '40px' }]">
                                            <a-icon size="18" color="grey darken-2" v-if="!filter_mode_selected.mode_slug || !['status', 'visibility'].includes(filter_mode_selected.mode_slug)">arrow_drop_{{ value ? 'up' : 'down' }}</a-icon>
                                            <template v-else>
                                                <span class="md-subtitle-2 mr-2" :class="['body--text text--darken-1']">
                                                    <template v-if="filter_mode_selected.mode_slug === 'status'">By Status: </template>
                                                    <template v-else-if="filter_mode_selected.mode_slug === 'visibility'">By Visibility: </template>
                                                    <template v-else>Select: </template>
                                                    <span class="indigo--text text--darken-3 ml-1">{{ filter_mode_selected.label }}</span>
                                                </span>
                                                <a-spacer></a-spacer>
                                                <a-icon size="24" color="grey darken-1">arrow_drop_{{ value ? 'up' : 'down' }}</a-icon>
                                            </template>
                                        </div>
                                    </template>
                                    <a-list class="u-list-std">
                                        <a-list-item class="u-cursor-pointer" @click="local_filter_mode('All', 'all')">
                                            <a-list-item-content class="md-body-2 body--text text--darken-1">All</a-list-item-content>
                                        </a-list-item>
                                        <a-list-item class="u-cursor-pointer" @click="local_filter_mode('None', 'none')">
                                            <a-list-item-content class="md-body-2 body--text text--darken-1">None</a-list-item-content>
                                        </a-list-item>
                                        <a-list-item id="c-inline-menu" class="u-cursor-pointer" :class="{ 'grey lighten-5': inlineMenu }">
                                            <a-list-item-content class="md-body-2 body--text text--darken-1" @click="{}">
                                                <div class="u-flex-center-y">
                                                    <span>By {{ filter_selected && filter_selected.value === 'all-uc' ? 'Visibility' : 'Status' }}</span>
                                                    <a-spacer></a-spacer>
                                                    <a-icon size="18">keyboard_arrow_right</a-icon>
                                                </div>
                                            </a-list-item-content>
                                            <a-menu v-model="inlineMenu" content-class="elevation-4" open-on-hover offset-x right activator="#c-inline-menu" transition="slide-x-transition">
                                                <a-list class="u-list-std">
                                                    <template v-if="filter_selected && filter_selected.value === 'all-uc'">
                                                        <a-list-item class="u-cursor-pointer" v-for="visibility in ['Internal', 'External']" :key="visibility" @click="local_filter_mode(visibility, 'visibility')">
                                                            <a-list-item-content class="md-body-2 body--text text--darken-1">{{ visibility }}</a-list-item-content>
                                                        </a-list-item>
                                                    </template>
                                                    <template v-else>
                                                        <template v-if="local_sc_selection_enabled">
                                                            <a-list-item class="u-cursor-pointer" v-for="status in success_criterion_status_list" :key="status.id" @click="local_filter_mode(status, 'status')">
                                                                <a-list-item-content class="md-body-2 body--text text--darken-1">{{ status.value }}</a-list-item-content>
                                                            </a-list-item>
                                                        </template>
                                                        <template v-if="local_tc_selection_enabled">
                                                            <a-list-item class="u-cursor-pointer" v-for="status in testcase_status_list" :key="status.id" @click="local_filter_mode(status, 'status')">
                                                                <a-list-item-content class="md-body-2 body--text text--darken-1">{{ status.value }}</a-list-item-content>
                                                            </a-list-item>
                                                        </template>
                                                    </template>
                                                </a-list>
                                            </a-menu>
                                        </a-list-item>
                                    </a-list>
                                </a-menu>
                            </a-sheet>
                        </template>
                    </PListViewToolbar>

                    <template v-if="local_filtered_ucs && !local_filtered_ucs.length && filter_selected && !local_is_loading('filter-selected')">
                        <a-sheet class="text-center mx-auto transparent" width="450">
                            <a-icon size="64" color="grey lighten-1">info</a-icon>
                            <h2 class="md-heading-6 grey--text text--darken-1 mt-2">
                                <template v-if="local_uc_selection_enabled">No Use Cases Found</template>
                                <template v-if="local_sc_selection_enabled">No Success Criteria Found</template>
                                <template v-if="local_tc_selection_enabled">No Test Cases Found</template>
                            </h2>
                            <p class="md-subtitle-1 grey--text text--darken-2 mb-0">
                                Try applying different filter or add new
                                <span class="text-lowercase" v-if="local_uc_selection_enabled">Use Case</span>
                                <span class="text-lowercase" v-if="local_sc_selection_enabled">Success Criterion</span>
                                <span class="text-lowercase" v-if="local_tc_selection_enabled">Test Case</span>
                            </p>
                        </a-sheet>
                    </template>

                    <template v-if="local_is_loading('filter-selected')">
                        <loader-usecases
                            v-for="count in 4"
                            :key="count"
                            class="mb-4 u-border u-rounded-corners-lg pa-6 py-7 mb-6"
                        />
                    </template>

                    <!-- Usecase list -->
                    <template v-else>
                        <a-responsive class="my-4 mt-12" v-if="local_filtered_ucs && !local_filtered_ucs.length && !filter_selected">
                            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1">No Use Cases Found</h3>
                            <p class="md-body-2 text-center grey--text mb-0">Add new use case from the above field or press '/' to focus</p>
                        </a-responsive>

                        <template v-if="local_filtered_ucs && local_filtered_ucs.length">
                                <!-- scrollSensitivity: 300,
                                forceFallback: true, -->
                            <draggable
                                v-model="usecase_list"
                                :options="{
                                    handle: '.js-drag-handle',
                                    filter: '.ignore-on-drag',
                                }"
                                @start="onDrag = true"
                                @end="onDrag = false"
                                draggable=".js-draggable-file-list-item"
                            >
                                <template v-for="(usecase, index) in local_filtered_ucs">
                                    <p-usecase-card
                                        :usecase="usecase"
                                        handle="js-drag-handle"
                                        class="u-border"
                                        :class="[{ 'mt-4': index !== 0 }]"
                                        :key="usecase.id"
                                        :parentIndex="index"
                                        :currentEditId="currentEditId"
                                        :focusedElementId="focusedElementId"
                                        :disable-navigation="false"
                                        :onDrag="onDrag"
                                        :scFilteredList="local_filtered_scs"
                                        :tcFilteredList="local_filtered_tcs"
                                        :sc-status-list="success_criterion_status_list"
                                        :tc-status-list="testcase_status_list"
                                        :collab-list="collaborator_list"
                                        :selectedStatusId="selected_status_id"
                                        :enableUcSelection="filter_selected && filter_selected.value === 'all-uc'"
                                        :enableScSelection="filter_selected && filter_selected.value === 'all-sc'"
                                        :enableTcSelection="filter_selected && filter_selected.value === 'all-tc'"
                                        :isExpanded="local_check_expanded(usecase.id)"
                                        :bulkEditMode="!!(filter_selected && filter_selected.value)"
                                        :loading-type="loading_module"
                                        :titleErrors="title_errors"
                                        :currentViewId="currentViewId"
                                        @inlineUpdate="local_title_inline_update"
                                        @toggleExpand="local_toggle_expand"
                                        @inputFocus="local_title_edit_focus"
                                        @editUc="local_show_uc_edit_pane"
                                        @edit="local_show_edit_pane"
                                        @clearKeyboardFocus="local_on_escape"
                                        @scBulkStore="local_sc_bulk_store"
                                        @tcBulkStore="local_tc_bulk_store"
                                    >
                                        <!-- :disable-navigation="bulk_delete_loading || show_bulk_delete" -->
                                        <template #selectable="{ loading }">
                                            <v-slide-x-transition leave-absolute hide-on-leave>
                                                <a-checkbox
                                                    :value="local_is_bulk_selected(usecase.id, 'uc')"
                                                    @click.stop="local_bulk_select_uc(usecase.id)"
                                                    :disabled="local_has_bulk_ops_loading || loading"
                                                    class="ma-0 pa-0"
                                                    style="margin-top: 2px !important;"
                                                    color="blue"
                                                    :ripple="false"
                                                    dense hide-details
                                                ></a-checkbox>
                                            </v-slide-x-transition>
                                        </template>
                                        <template #assignees>
                                            <s-assignee-dropdown
                                                :item="usecase"
                                                :users-list="usecase.assignees"
                                                :project-id="local_project_id"
                                                :local_loading="local_loading"
                                                :can-update="local_can('update') && !(filter_selected && filter_selected.value)"
                                                :size="30"
                                                @assignee-store="local_get_usecase(usecase.id)"
                                                @change-visibility="local_visibility_toggle(usecase.id, $event)"
                                                @click.stop="{}"
                                                @menuOpen="mixinGetCollabs"
                                                module-type="Usecase"
                                                class="u-flex align-center justify-end mr-2"
                                                :hideExternalConfirmation="usecase.visibility === 'external'"
                                            ></s-assignee-dropdown>
                                        </template>
                                        <template #success-criteria-stats>
                                            <a-sheet class="transparent">
                                                <s-stats
                                                    module="Success Criteria"
                                                    :total="usecase.success_criteria_count"
                                                    :open="usecase.success_criteria_open_count"
                                                    :closed="usecase.success_criteria_closed_count"
                                                    :passed="usecase.success_criteria_passed_count"
                                                    :failed="usecase.success_criteria_failed_count"
                                                    :invalid="usecase.success_criteria_invalid_count"
                                                    no-empty-text
                                                ></s-stats>
                                            </a-sheet>
                                        </template>
                                        <template #sc-selectable="{ item, loading }">
                                            <v-slide-x-transition leave-absolute hide-on-leave>
                                                <a-checkbox
                                                    :value="local_is_bulk_selected(item.id, 'sc')"
                                                    @click.stop="local_bulk_select_sc(item.id, 'sc')"
                                                    :disabled="local_has_bulk_ops_loading || loading"
                                                    class="ma-0 pa-0"
                                                    style="margin-top: 2px !important;"
                                                    color="blue"
                                                    :ripple="false"
                                                    dense hide-details
                                                ></a-checkbox>
                                            </v-slide-x-transition>
                                        </template>
                                        <template #tc-selectable="{ item }">
                                            <v-slide-x-transition leave-absolute hide-on-leave>
                                                <a-checkbox
                                                    :value="local_is_bulk_selected(item.id, 'tc')"
                                                    @click.stop="local_bulk_select_tc(item.id, 'tc')"
                                                    :disabled="local_has_bulk_ops_loading || loading"
                                                    class="ma-0 pa-0"
                                                    style="margin-top: 2px !important;"
                                                    color="blue"
                                                    :ripple="false"
                                                    dense hide-details
                                                ></a-checkbox>
                                            </v-slide-x-transition>
                                        </template>
                                        <template #sc-review-update="{ usecaseId, mode }" v-if="error_bulk_items.sc.length">
                                            <PReviewUpdateBar
                                                v-if="bulkModule.parent === usecaseId && bulkModule.mode === mode"
                                                module="Success Criteria"
                                                @cancel="local_cancel_review_update('sc')"
                                                @update="local_open_review_uc"
                                            />
                                        </template>
                                        <template #tc-review-update="{ usecaseId, mode }" v-if="error_bulk_items.tc.length">
                                            <PReviewUpdateBar
                                                v-if="bulkModule.parent === usecaseId && bulkModule.mode === mode"
                                                module="Test Cases"
                                                @cancel="local_cancel_review_update('tc')"
                                                @update="local_open_review_uc"
                                            />
                                        </template>
                                    </p-usecase-card>
                                </template>
                            </draggable>
                        </template>
                    </template>

                    <div v-if="pagination_loading" class="mt-4">
                        <PartialProjectUcsLoader
                            v-for="count in 5"
                            :key="count"
                            class="mb-4 u-border u-rounded-corners-lg pa-4 mb-4"
                        />
                    </div>
                </template>
            </div>
        </div>

        <!-- Detail View -->
        <a-dialog v-model="dialog_detail_view" :width="$vuetify.breakpoint.lgAndDown ? 1040 : 1080" :persistent="localAttachmentLoading">
            <s-sc-tc-item-view
                :edit-item="local_edit_pane_item"
                :module="edit_pane_module"
                :loaders="loaders"
                :is-open="dialog_detail_view"
                tag-type="usecase_tag"
                :parent-item="selected_usecase_item"
                :testcase-list="testcase_list"
                :success-criteria-list="success_criterion_list"
                :can-update="check_permission('update')"
                :can-delete="check_permission('destroy')"
                :can-comment="$can('usecases.comment')"
                @attachmentLoading="localSetLoadingStatus"
                @openParent="local_show_uc_edit_pane($event, 'fromChild')"
                @close="local_clear_edit_pane_view"
                @update="local_update_pane_item"
                @destroy="local_destroy_pane_item"
                @open-timetracker="local_open_time_track"
                show-breadcrumbs
            >
                <template #status>
                    <GNonActiveInfo
                        :stage="project_item && project_item.status"
                        :project="project_item"
                        :disabled="!check_permission('update') || ((check_permission('update') && project_item && project_item.status === 'active'))"
                    >
                        <s-status-menu
                            :can-update="check_permission('update') && (project_item && project_item.status === 'active')"
                            :parent-list="edit_pane_module === 'Testcase' ? testcase_list : success_criterion_list"
                            :status-list="edit_pane_module === 'Testcase' ? testcase_status_list : success_criterion_status_list"
                            :item="local_edit_pane_item"
                            :status-item="edit_pane_module === 'Testcase' ? local_get_tc_status_item(local_edit_pane_item.status_id) : local_get_sc_status_item(local_edit_pane_item.status_id)"
                            @statusCommentStore="local_add_status_comment"
                            @update="local_update_list_item_status(edit_pane_module, $event)"
                            :module="edit_pane_module"
                            only-as-id
                        ></s-status-menu>
                        <template #move-stage="{ moveStage }">
                            <span @click="localCheckPlanStageUpdate(moveStage)" class="d-inline-flex align-center u-cursor-pointer">
                                <h3 class="blue--text md-body-2 font-weight-medium">Move to active stage</h3>
                                <a-icon size="16" color="blue" class="ml-1">trending_flat</a-icon>
                            </span>
                        </template>
                    </GNonActiveInfo>
                </template>
                <template #assignee>
                    <s-assignee-dropdown
                        :item="local_edit_pane_item"
                        :users-list="local_edit_pane_item.assignees"
                        :project-id="local_project_id"
                        :can-update="check_permission('update')"
                        :size="32"
                        :count="6"
                        :module-type="edit_pane_module"
                        @assignee-store="local_get_module(edit_pane_module, local_edit_pane_item.id)"
                        @change-visibility="local_visibility_toggle(local_edit_pane_item.usecase_id, $event)"
                        @menuOpen="mixinGetCollabs"
                        class="u-flex align-center justify-end mr-3"
                        :hideExternalConfirmation="!!localIsUsecaseExternal"
                    ></s-assignee-dropdown>
                </template>
            </s-sc-tc-item-view>
        </a-dialog>

        <!-- UC detail view -->
        <a-dialog v-model="dialog_uc_detail_view" :width="$vuetify.breakpoint.lgAndDown ? 1040 : 1080" :persistent="localAttachmentLoading">
            <ModalUsecaseDetail
                :usecase="usecase_detail_item"
                :isOpen="dialog_uc_detail_view"
                @attachmentLoading="localSetLoadingStatus"
                @update="local_update_usecase_list_item"
                @delete="local_usecase_delete"
            >
                <template #assignee>
                    <s-assignee-dropdown
                        :item="usecase_detail_item"
                        :users-list="usecase_detail_item.assignees"
                        :project-id="local_project_id"
                        :can-update="check_permission('update') && !localAttachmentLoading"
                        :count="6"
                        :size="32"
                        @assignee-store="local_get_usecase(usecase_detail_item.id)"
                        @unassigned="local_get_usecase(usecase_detail_item.id)"
                        @change-visibility="local_visibility_toggle(usecase_detail_item.id, $event)"
                        @menuOpen="mixinGetCollabs"
                        module-type="Usecase"
                        class="u-flex align-center justify-end"
                        :hideExternalConfirmation="usecase_detail_item.visibility === 'external'"
                    ></s-assignee-dropdown>
                </template>
            </ModalUsecaseDetail>
        </a-dialog>

        <!-- Time tracking -->
        <a-dialog v-model="dialog_est_list" persistent scrollable max-width="700">
            <template v-if="time_track_testcase">
                <s-time-tracker
                    model="test case"
                    :title="time_track_testcase ? time_track_testcase.title : ''"
                    @add-entry="local_add_entry"
                    @delete-entry="local_delete_entry"
                    @close="local_close_time_track"
                    :item="time_track_testcase"
                    :loading="loading"
                    :records="time_record_list"
                    :can-update="check_permission('update')"
                >
                    <template v-slot:record-progress>
                        <s-time-progress
                            :limit="700"
                            :item="time_track_testcase"
                            :sum-mins="time_track_testcase.time_records_sum_duration_minutes"
                            :est-mins="time_track_testcase.estimated_duration_minutes"
                            :est-text="time_track_testcase.estimated_duration_text"
                            class="pb-2"
                            :can-update="check_permission('update')"
                            allow-est-edit
                            @est-update="local_est_update"
                        ></s-time-progress>
                    </template>
                </s-time-tracker>
            </template>
        </a-dialog>

        <!-- Bulk Assign -->
        <a-dialog v-model="dialog_bulk_edit" persistent max-width="600">
            <ModalBulkAssign
                :isOpen="dialog_bulk_edit"
                :module="filter_selected && filter_selected.value"
                :loading="local_has_bulk_ops_loading"
                :isAddAssignee="isAddAssignee"
                :isAddTag="isAddTag"
                :isRemoveAssignee="isRemoveAssignee"
                :isRemoveTag="isRemoveTag"
                :usecaseTags="tag_list"
                :ucBulkCount="uc_bulk_ids.length"
                :scBulkCount="sc_bulk_ids.length"
                :tcBulkCount="tc_bulk_ids.length"
                :ucFilteredList="local_filtered_selected_ucs"
                :scFilteredList="local_filtered_selected_scs"
                :tcFilteredList="local_filtered_selected_tcs"
                @assignCollaborators="local_bulk_assign_collabs"
                @removeCollaborators="local_remove_collabs"
                @assignTags="local_bulk_add_tags"
                @removeTags="local_bulk_remove_tags"
                @close="dialog_bulk_edit = false"
                @after-store="usecase_uc_upsert"
            />
        </a-dialog>

        <!-- Bulk Status Update -->
        <a-dialog v-model="dialog_bulk_status_update" max-width="500">
            <ModalBulkStatusUpdate
                :isOpen="dialog_bulk_status_update"
                :module="filter_selected && filter_selected.value"
                :currentFilter="filter_mode_selected"
                :tcStatuses="testcase_status_list"
                :scStatuses="success_criterion_status_list"
                :loading="local_is_loading('bulk-status-update')"
                :scCount="sc_bulk_ids.length"
                :tcCount="tc_bulk_ids.length"
                :commentError="comment_error"
                @update="local_bulk_status_update"
                @close="dialog_bulk_status_update = false"
            />
        </a-dialog>

        <!-- Bulk Remove Collabs -->
        <a-dialog v-model="dialog_remove_collabs" max-width="350">
            <ModalRemoveCollaborators
                :isOpen="dialog_remove_collabs"
                :loading="local_is_loading('bulk-remove-assignee')"
                @confirm="local_remove_collabs"
                @close="dialog_remove_collabs = false"
            />
        </a-dialog>

        <!-- Bulk Delete -->
        <a-dialog v-model="dialog_bulk_delete" max-width="350">
            <ModalBulkDelete
                :module="filter_selected && filter_selected.value"
                :ucCount="uc_bulk_ids.length"
                :scCount="sc_bulk_ids.length"
                :tcCount="tc_bulk_ids.length"
                :loading="local_is_loading('bulk-delete')"
                @confirm="local_bulk_delete_confirm"
                @close="dialog_bulk_delete = false"
            />
        </a-dialog>

        <!-- Review errored titles -->
        <a-dialog v-model="dialog_review_create_uc" persistent max-width="700">
            <ModalReviewCreate
                :list="error_item_list"
                :isOpen="dialog_review_create_uc"
                :module="error_item_module"
                @create="e => local_review_and_update(e, error_item_module, true)"
                @remove="local_update_review_list"
                @close="dialog_review_create_uc = false"
            />
        </a-dialog>

        <!-- Export form -->
        <a-dialog v-model="dialog_export_form" max-width="600" persistent>
            <ModalExportForm
                :loading="loading"
                :isOpen="dialog_export_form"
                @close="dialog_export_form = false"
            />
        </a-dialog>

        <!-- Delete All -->
        <a-dialog max-width="360" v-model="dialog_usecase_delete_all">
            <a-card class="pa-8">
                <div class="mb-5">
                    <p class="md-subtitle-1 grey--text text--darken-3 font-weight-medium mb-3">Are you sure you want to delete all the use cases in this project?</p>
                    <p class="md-body-2 grey--text text--darken-1 mb-0">This action cannot be undone.</p>
                </div>
                <div>
                    <a-btn small class="ma-0 px-3 elevation-0 red lighten-5 red--text text--darken-2" @click="local_usecase_delete_all()" :loading="loading">Delete all Use Cases</a-btn>
                    <a-btn small color="grey" class="ma-0 ml-4 grey lighten-4" text @click="dialog_usecase_delete_all = !dialog_usecase_delete_all">Cancel</a-btn>
                </div>
            </a-card>
        </a-dialog>

        <!-- User Upgrade Popup -->
        <a-dialog max-width="400" v-model="dialog_user_upgrade_modal">
            <SUserUpgradeModal @close="dialog_user_upgrade_modal = false" />
        </a-dialog>

        <!-- Admin Upgrade Popup -->
        <a-dialog max-width="600" v-model="dialog_admin_upgrade_modal">
            <SAdminUpgradeModal
                :isOpen="dialog_admin_upgrade_modal"
                @upgrade="{}"
                @close="dialog_admin_upgrade_modal = false"
            >
                <template #plan-info-text>
                    Your current <span class="secondary--text font-weight-bold">Starter</span> plan is limited to {{ $plan('active_projects_limit') }} active projects.
                </template>
            </SAdminUpgradeModal>
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {
    SUserListPopup,
    SAssigneeForm,
    SAssigneeDropdown,
    SStats,
    SScTcItemView,
    SStatusMenu,
    STimeTracker,
    STimeProgress,
    SPagination,
    SUserUpgradeModal,
    SAdminUpgradeModal
} from '@/config/config-shared-components'
import {
    PUsecaseCard,
    PListViewToolbar,
    PReviewUpdateBar,
    PartialProjectUcsLoader,
} from './Partials'
import {
    ModalExportForm,
    ModalBulkAssign,
    ModalBulkStatusUpdate,
    ModalRemoveCollaborators,
    ModalBulkDelete,
    ModalUsecaseDetail,
    ModalReviewCreate
} from './Modals'
import LoaderUsecases from './Loaders/LoaderUsecases.vue'
import TemplateImportStatus from '@/mixins/mixin-template-import-status'
import mixinSearchCollaborators from '@/mixins/mixin-search-collaborators'
import { Validate } from '@/helpers/helper-validations'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'

// import MixinUsecaseCrud from './Mixins/MixinUsecaseCrud'
// import MixinKeyboardNavigation from './Mixins/MixinKeyboardNavigation'

export default {
    mixins: [
        TemplateImportStatus,
        mixinSearchCollaborators,
        mixinGetOrganizationPlan
        // MixinUsecaseCrud,
        // MixinKeyboardNavigation
    ],

    components: {
        SPagination,
        SUserListPopup,
        SAssigneeForm,
        SAssigneeDropdown,
        SStats,
        SScTcItemView,
        SStatusMenu,
        STimeTracker,
        STimeProgress,
        PUsecaseCard,
        PListViewToolbar,
        PReviewUpdateBar,
        ModalExportForm,
        ModalBulkAssign,
        ModalBulkStatusUpdate,
        PartialProjectUcsLoader,
        ModalRemoveCollaborators,
        ModalBulkDelete,
        ModalUsecaseDetail,
        ModalReviewCreate,
        SUserUpgradeModal,
        SAdminUpgradeModal,
        LoaderUsecases,
    },

    data() {
        return {
            default_expand_count: 0,
            usecase_title: '',
            comment_error: '',
            filter_selected: null,
            show_bulk_delete: false,
            show_selected: false,
            filter_checkbox_all: false,
            title_errors: { uc_title: false, sc_title: false, tc_title: false },
            filter_mode_selected: { mode_slug: '', label: '', status: '', status_id: '' },
            expanded_ids: [],
            paginatedUcs: [],
            currentUcLoadingIds: [],
            inlineMenu: false,
            bulk_selected_all: false,
            bulk_delete_ids: [],
            pagination_loading: false,
            page_numbers_loading: false,
            local_page_number: null,
            usecases_count: 20,
            chunk_count: 30,
            selected_status_id: null,
            error_item_module: '',
            error_item_list: [],
            error_bulk_items: { uc: [], sc: [], tc: [] },
            usecase_title_error: null,
            uc_bulk_ids: [],
            sc_bulk_ids: [],
            tc_bulk_ids: [],
            original_uc_list: [],
            original_sc_list: [],
            original_tc_list: [],
            error_msgs: {
                required_msg: 'Usecase "Title", required field.',
                max_length_exceed: 'Title should not be more than 1000 chars.',
            },
            edit_pane_module: '',
            bulkModule: { parent: '', mode: '' },
            local_edit_pane_item: { tags: [], comments: [] },
            selected_usecase_item: {},
            onDrag: false,
            dialog_est_list: false,
            time_track_testcase: null,
            showEstimateEditForm: false,
            validationError: null,
            usecase_detail_item: {},
            currentViewId: null,
            dialog_review_create_uc: false,
            dialog_uc_detail_view: false,
            dialog_detail_view: false,
            dialog_usecase_form: false,
            dialog_export_form: false,
            dialog_bulk_edit: false,
            dialog_bulk_remove: false,
            dialog_bulk_status_update: false,
            dialog_remove_collabs: false,
            dialog_bulk_delete: false,
            dialog_usecase_delete_all: false,
            dialog_user_upgrade_modal: false,
            dialog_admin_upgrade_modal: false,
            accordion_usecases: [],
            accordion_usecases_all: false,
            search_collaborator: '',
            usecase_create_another: true,
            local_user_edit_type: null,
            local_user_edit_item: null,
            local_selected_user_add_item: null,
            autosave_user_add_status: 'none',
            local_loading: true,
            bulk_delete_loading: false,
            loading_module: null,
            testcase_status_list: [],
            success_criterion_status_list: [],
            currentParent: null,
            currentChild: null,
            currentParentId: null,
            currentChildId: null,
            parentChildrenCount: 0,
            prevNavElement: null,
            focusedElementId: null,
            currentEditId: null,
            loaders: {
                sidebar_tags_loading: false,
                sidebar_time_est_loading: false,
                sidebar_comments_loading: false,
                content_autosave_response: false,
                sc_store: false,
                tc_store: false,
            },
            local_testcase_status_fields: { 'filter[type]': 'testcase_status', 'fields[metas]': 'id,type,value,status', 'sort': 'order' },
            local_success_criterion_status_fields: { 'filter[type]': 'success_criterion_status', 'fields[metas]': 'id,type,value,status', 'sort': 'order' },
            time_record_params: {
                'include': 'createdBy',
                'fields[time_records]': 'id,duration_minutes,duration_text,description,created_at,start_date,resource_type,resource_id,created_by_id',
                'fields[created_by]': 'id,name,avatar,color,initial',
                'aggregate[duration_minutes]': 'count'
            },
            local_usecase_fields: {
                'include': 'tags,assignees.collaborator.type,successCriteriaCount,successCriteriaOpenCount,successCriteriaClosedCount,successCriteriaPassedCount,successCriteriaFailedCount,successCriteriaInvalidCount,attachmentsCount',
                'fields[usecases]': 'id,code,title,visibility,project_id,order,description_json',
                'fields[tags]': 'tags.id,tags.label,tags.type,tags.color',
                'filter[collaborators]': true,
                page: 1
            },
            local_success_criterion_fields: {
                'include': 'tags,assignees.collaborator.type,commentsCount,tagsCount,attachmentsCount',
                'fields[success_criteria]': 'id,title,status_id,usecase_id,order,description_json',
                'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                'filter[collaborators]': true
            },
            local_testcase_fields: {
                'include': 'tags,assignees.collaborator.type,tagsCount,commentsCount,attachmentsCount',
                'fields[testcases]': 'id,title,status_id,order,description_json,usecase_id,estimated_duration_minutes,estimated_duration_text',
                'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                'aggregate[time_records.duration_minutes]': 'sum',
                'filter[collaborators]': true
            },
            tag_dialogue_status: false,
            isAddAssignee: false,
            isAddTag: false,
            isRemoveAssignee: false,
            isRemoveTag: false,
            localAttachmentLoading: false
        }
    },

    watch: {
        dialog_detail_view (val) {
            if (val) return this.currentViewId = this.local_edit_pane_item.id
            this.currentViewId = null
            this.local_clear_edit_pane_view()
            this.local_reset_edit_pane()
        },

        dialog_uc_detail_view (val) {
            if (val) return this.currentViewId = this.usecase_detail_item.id
            this.currentViewId = null
        },
        
        dialog_bulk_edit(val) {
            if (!val) this.local_reset_flags()
        }
    },

    mounted() {
        this.local_index()
        // document.addEventListener('keydown', this.local_escap_cancel_bulk_delete)
        document.addEventListener('keydown', this.local_keyboad_navigate_list)
        window.addEventListener('scroll', this.localInfiniteScrolling)
    },

    beforeDestroy () {
        // document.removeEventListener('keydown', this.local_escap_cancel_bulk_delete)
        document.removeEventListener('keydown', this.local_keyboad_navigate_list)
        window.removeEventListener('scroll', this.localInfiniteScrolling)
    },

    computed: {
        usecase_list: {
            get() {
                return this.$store.state.Usecase.list.filter(item => !this.localIsUcLoading(item.id))
            },
            set(list) {
                this.local_reorder(list)
            }
        },

        local_usecase_list_length () {
            return this.usecase_list && this.usecase_list.length
        },

        local_tc_fields () {
            return {
                project_id: this.local_project_id,
                include: 'assignees.collaborator.type',
                'fields[testcases]': 'id,usecase_id',
                'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                'filter[collaborators]': true
            }
        },

        local_sc_fields () {
            return {
                project_id: this.local_project_id,
                include: 'assignees.collaborator.type',
                'fields[success_criteria]': 'id,usecase_id',
                'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                'filter[collaborators]': true
            }
        },

        local_get_select_all_state () {
            if (!this.filter_selected) return false

            const value = this.filter_selected ? this.filter_selected.value : null
            if (value === 'all-uc') return !!(this.uc_bulk_ids.length && (this.uc_bulk_ids.length <= this.usecase_list.length))
            if (value === 'all-sc') return !!(this.sc_bulk_ids.length && (this.sc_bulk_ids.length <= this.local_filtered_scs.length))
            if (value === 'all-tc') return !!(this.tc_bulk_ids.length && (this.tc_bulk_ids.length <= this.local_filtered_tcs.length))
        },

        local_filtered_scs () {
            // if (!this.local_sc_selection_enabled) return this.success_criterion_list
            // if (this.local_sc_selection_enabled && this.selected_status_id) return this.success_criterion_list.filter(item => item.status_id === this.selected_status_id)
            if (this.show_selected) return this.success_criterion_list.filter(item => this.sc_bulk_ids.includes(item.id))
            return this.success_criterion_list
        },

        local_filtered_tcs () {
            // if (!this.local_tc_selection_enabled) return this.testcase_list
            // if (this.local_tc_selection_enabled && this.selected_status_id) return this.testcase_list.filter(item => item.status_id === this.selected_status_id)
            if (this.show_selected) return this.testcase_list.filter(item => this.tc_bulk_ids.includes(item.id))
            return this.testcase_list
        },

        local_filtered_ucs () {
            if (this.local_uc_selection_enabled) return this.local_uc_visibility_filtered
            if (this.local_sc_selection_enabled) {
                const scIds = this.local_filtered_scs.map(({ usecase_id }) => usecase_id)
                return this.usecase_list
                    .filter(({ id }) => {
                        let index = scIds.findIndex(ucId => ucId === id)
                        if (index !== -1) return true
                    })
            }

            if (this.local_tc_selection_enabled) {
                const tcIds = this.local_filtered_tcs.map(({ usecase_id }) => usecase_id)
                return this.usecase_list
                    .filter(({ id }) => {
                        let index = tcIds.findIndex(ucId => ucId === id)
                        if (index !== -1) return true
                    })
            }

            return this.usecase_list
        },

        local_uc_visibility_filtered () {
            // const mode = this.filter_mode_selected && this.filter_mode_selected.mode_slug === 'visibility'
            // if (mode) return this.usecase_list.filter(item => item.visibility === this.filter_mode_selected.label.toLowerCase())
            if (this.show_selected) {
                return this.usecase_list.filter(item => this.uc_bulk_ids.includes(item.id))
            }
            return this.usecase_list
        },

        local_uc_selection_enabled () {
            return this.filter_selected && this.filter_selected.value === 'all-uc'
        },

        local_sc_selection_enabled () {
            return this.filter_selected && this.filter_selected.value === 'all-sc'
        },

        local_tc_selection_enabled () {
            return this.filter_selected && this.filter_selected.value === 'all-tc'
        },

        local_show_paginate_section () {
            return this.usecase_meta.current_page >= 1 && this.usecase_meta.last_page !== 1 && this.usecase_meta.total > this.usecases_count
        },

        local_resource_type () {
            if (this.local_uc_selection_enabled) return 'Usecase'
            if (this.local_sc_selection_enabled) return 'SuccessCriterion'
            if (this.local_tc_selection_enabled) return 'Testcase'
        },

        local_has_bulk_ops_loading () {
            const loadTypes = ['bulk-assign', 'bulk-status-update', 'bulk-remove-assignee', 'bulk-remove-tags', 'bulk-delete']
            return loadTypes.includes(this.loading_module)
        },

        local_project_id () {
            return this.$route.params.id
        },

        localIsUsecaseExternal () {
            if (this.edit_pane_module === 'Usecase') return this.local_edit_pane_item.visibility === 'external'

            const ucItem = this.usecase_list.find(item => item.id === this.local_edit_pane_item.usecase_id)
            if (!ucItem) return false
            return ucItem.visibility === 'external'
        },

        local_filtered_selected_ucs() {
            return this.uc_bulk_ids.map(item => this.local_filtered_ucs.find(uc => uc.id === item))
        },

        local_filtered_selected_scs() {
            return this.sc_bulk_ids.map(item => this.local_filtered_scs.find(sc => sc.id === item))
        },     

        local_filtered_selected_tcs() {
            return this.tc_bulk_ids.map(item => this.local_filtered_tcs.find(tc => tc.id === item))
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Usecase', {
            usecase_item: 'item',
            usecase_meta: 'meta',
            usecase_response: 'response',
        }),

        ...mapState('SuccessCriterion', {
            success_criterion_item: 'item',
            success_criterion_list: 'list',
            success_criterion_response: 'response',
        }),

        ...mapState('Testcase', {
            testcase_list: 'list',
            testcase_item: 'item',
            testcase_response: 'response',
        }),

        ...mapState('TemplateGroup', {
            template_group_list: 'list',
            template_group_item: 'item',
            template_group_response: 'response',
        }),

        ...mapState('TemplateUsecase', {
            template_usecase_item: 'item',
            template_usecase_response: 'response',
        }),

        ...mapState('TimeRecord', {
            time_record_list: 'list',
            time_record_response: 'response',
        }),

        ...mapState('ProjectView', {
            project_item: 'item',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Collaborator', {
            collaborator_list: 'list',
        }),

        ...mapState('Assignee', {
            assignee_response: 'response',
        }),

        ...mapState('Comment', {
            comment_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
        }),
    },

    methods: {
        async local_index() {
            if (this.$can('usecases.index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.$vuetify.goTo(0)
            await this.local_load_testcase_status_list()
            this.local_load_success_criterion_status_list()

            await this.usecase_clear()
            await this.local_usecase_index()
            this.local_loading = false
        },

        localInfiniteScrolling (evt) {
            if (this.pagination_loading || this.local_loading) return
            if (this.paginationTimeout) return
            if (_.get(this.usecase_meta, 'current_page') >= this.last_page_number) {
                this.pagination_loading = false
                return this.paginationTimeout = null
            }

            const { scrollTop, clientHeight, scrollHeight } = document.documentElement
            if ((scrollTop + clientHeight + 600) >= scrollHeight) {
                if (this.onDrag) return

                this.pagination_loading = true
                this.paginationTimeout = setTimeout(() => {
                    this.local_paginate({ page: this.usecase_meta.current_page })
                }, 1000)
            }
        },

        async local_paginate (pagination) {
            if (this.usecase_meta && this.usecase_meta.current_page > this.usecase_meta.last_page) {
                this.pagination_loading = false
                return this.paginationTimeout = null
            }

            this.local_usecase_fields.page = pagination.page + 1
            if (this.local_usecase_fields.page > this.last_page_number) {
                this.pagination_loading = false
                return this.paginationTimeout = null
            }

            this.paginatedUcs = []
            await this.localPaginationIndex()
        },

        async localPaginationIndex () {
            const params = {
                params: {
                    ...this.local_usecase_fields,
                    count: !this.filter_selected ? this.usecases_count : 999,
                    'filter[project_id]': this.$route.params.id,
                },
                mode: 'select-index',
                stateless: true
            }

            this.paginatedUcs = await this.usecase_index(params)
            this.currentUcLoadingIds = _.map(this.paginatedUcs, 'id')
            this.local_page_number = this.usecase_meta.current_page
            this.last_page_number = this.usecase_meta.last_page

            const usecase_ids = _.size(this.currentUcLoadingIds) ? this.currentUcLoadingIds.join(',') : []
            if (_.size(usecase_ids)) await this.local_fetch_tc_sc_list({ usecase_ids, isPagination: true })

            this.local_expand_all_upto(this.default_expand_count)
            this.local_set_originals()

            if (this.local_loading) this.local_loading = false
            this.pagination_loading = false
            this.paginationTimeout = null
            this.currentUcLoadingIds = []
        },

        localIsUcLoading (id) {
            return this.currentUcLoadingIds.includes(id)
        },

        local_reset_flags () {
            this.isAddAssignee = false,
            this.isAddTag = false,
            this.isRemoveAssignee = false,
            this.isRemoveTag = false
        },

        local_open_review_uc (module) {
            this.error_item_list = []
            switch (module) {
                case 'Use Cases': {
                    this.error_item_list = this.error_bulk_items.uc
                    this.error_item_module = module
                    break;
                }
                case 'Success Criteria': {
                    this.error_item_list = this.error_bulk_items.sc
                    this.error_item_module = module
                    break;
                }
                case 'Test Cases': {
                    this.error_item_list = this.error_bulk_items.tc
                    this.error_item_module = module
                    break;
                }
            }
            this.dialog_review_create_uc = true
        },

        local_cancel_review_update (module) {
            this.error_bulk_items[module] = []
            this.error_bulk_items[module] = []
            this.error_bulk_items[module] = []
        },

        local_update_review_list (list, module) {
            switch (module) {
                case 'Use Cases': return this.error_bulk_items.uc = list
                case 'Success Criteria': return this.error_bulk_items.sc = list
                case 'Test Cases': return this.error_bulk_items.tc = list
            }
        },

        async local_review_and_update (list, module, direct = false) {
            switch (module) {
                case 'Use Cases': {
                    this.error_bulk_items.uc = []
                    this.local_bulk_usecase_store(list, direct)
                    return true
                }
                case 'Success Criteria': return this.local_error_update_sc(list)
                case 'Test Cases': return this.local_error_update_tc(list)
            }
        },

        async local_bulk_usecase_store (event, direct = false) {
            const currentPage = this.usecase_meta.current_page
            this.local_set_loading('usecase-bulk-store')
            let cursorStartPosition = event.target.selectionStart,
                cursorEndPosition = event.target.selectionEnd,
                inputValue =  event.target.value,
                clipboardData = event.clipboardData.getData('text'),
                clipboardDataLength = clipboardData.split('\n').length

            const usecaseTitle = (clipboardDataLength > 1 ? '' : inputValue.substring(0, cursorStartPosition)) + clipboardData + (clipboardDataLength > 1 ? '' : inputValue.substring(cursorEndPosition))
            let usecasesNew = usecaseTitle.split('\n')
            usecasesNew = this.local_sanitize_ucs(usecasesNew, direct)

            const ucIds = usecasesNew.map(({ id }) => id)
            // const ucsGreaterCount = ucIds.length > this.usecases_count
            // let filteredUcs = []
            // if (currentPage === 1) {
            //     if (ucsGreaterCount) {
            //         filteredUcs = _.cloneDeep(usecasesNew)
            //         filteredUcs = filteredUcs.splice(0, this.usecases_count)
            //         this.usecase_uc_bulk_upsert(filteredUcs)
            // }
            this.usecase_uc_bulk_upsert(usecasesNew)

            if (!usecasesNew.length) {
                setTimeout(() => this.usecase_title = '', 0)
                this.local_set_loading(null)
                return true
            }

            await this.local_add_ucs_as_chunks(usecasesNew)
            this.usecase_title = ''

            await this.usecase_uc_bulk_upsert({ ids: ucIds, loading_status: null, bulk_type: 'update' })
             /* If current page is equal to 1 */
            // const filteredUcIds = filteredUcs.map(({ id }) => id)
            // await this.local_usecase_index_bulk_mode(true, ucsGreaterCount ? filteredUcIds : ucIds)
            this.local_set_loading(null)
            this.$notify('success', `Usecase${clipboardDataLength > 1 ? '(s)' : ''} created successfully!`)
            setTimeout(() => {
                this.$refs.refUsecaseCreateInput.focus()
            }, 500)
            // this.local_remove_selected()
            // this.expanded_ids = ucIds.slice(0, this.default_expand_count)
        },

        async local_add_ucs_as_chunks (usecasesNew) {
            const ceilCount = Math.ceil(usecasesNew.length / this.chunk_count)
            let list = _.cloneDeep(usecasesNew)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                await this.usecase_bulk_store({ data: finalSet.reverse(), project_id: this.local_project_id, order: -1 })
            }
        },

        async local_usecase_store (value) {
            if (!this.usecase_title || (this.usecase_title && !this.usecase_title.trim())) return
            this.error_bulk_items.uc = []
            const { message } = new Validate(this.usecase_title, { silent: true }).length(1, 1000).run()
            this.usecase_title_error = message
            if (message) return

            const currentPage = this.usecase_meta.current_page
            const usecaseItem = this.$appendId({ title: _.cloneDeep(this.usecase_title), project_id: this.local_project_id, assignees: [], order: -1, attachments_count: 0, loading_status: 'store' })

            if (currentPage === 1) this.usecase_uc_upsert(_.cloneDeep(usecaseItem))
            this.usecase_title = ''
            await this.usecase_store(_.cloneDeep(usecaseItem))

            /* If current page is equal to 1 */
            await this.usecase_show(_.cloneDeep({ ...usecaseItem, mode: 'upsert-list-item' }))
            this.usecase_uc_upsert(_.cloneDeep({ ...usecaseItem, loading_status: null }))
            this.local_toggle_expand({ id: usecaseItem.id, is_expanded: false })

            const ucIds = [this.usecase_list.slice(-1)[0].id]
            this.local_remove_selected({ usecase_ids: ucIds })
            this.$notify('success', 'Usecase created successfully!')
            setTimeout(() => this.$refs.refUsecaseCreateInput.focus(), 500)
        },

        local_sanitize_ucs (usecasesNew, direct) {
            if (!direct) {
                usecasesNew = usecasesNew.filter(item => (!!item && (item && item.trim())))
                usecasesNew = usecasesNew.map(title => {
                    if (title && title.trim()) {
                        return this.$appendId({ title, assignees: [], loading_status: 'store' })
                    }
                })
            }

            usecasesNew = usecasesNew.filter(item => {
                const { message } = new Validate(item.title, { field: 'title', silent: true }).length(1, 1000).run()
                if (!message) {
                    const prevErrorIndex = this.error_bulk_items.uc.findIndex(prev => prev.id === item.id)
                    if (prevErrorIndex !== -1) this.error_bulk_items.uc.splice(prevErrorIndex, 1)
                    return item
                }
                this.error_bulk_items.uc.push(item)
                return null
            })

            return usecasesNew
        },

        local_remove_selected (args) {
            // let clonedList = _.cloneDeep(this.usecase_list)
            // if (this.local_usecase_list_length > this.usecases_count) clonedList.splice(0, this.usecases_count)
            // else clonedList = null

            // const ids = clonedList ? clonedList.map(({ id }) => id) : null
            // if (ids) this.usecase_remove_selected({ usecase_ids: ids })
        },

        async local_load_testcase_status_list () {
            await this.meta_index(this.local_testcase_status_fields)
            this.testcase_status_list = _.cloneDeep(this.meta_list)
        },

        async local_load_success_criterion_status_list () {
            await this.meta_index(this.local_success_criterion_status_fields)
            this.success_criterion_status_list = _.cloneDeep(this.meta_list)
        },

        // Testcase List --- Start
        async local_error_update_tc (tcsNew) {
            tcsNew = tcsNew.filter(item => {
                const { message } = new Validate(item.title, { field: 'title', silent: true }).length(1, 1000).run()
                if (!message) {
                    const prevErrorIndex = this.error_bulk_items.tc.findIndex(prev => prev.id === item.id)
                    if (prevErrorIndex !== -1) this.error_bulk_items.tc.splice(prevErrorIndex, 1)
                    return item
                }
                this.error_bulk_items.tc.push(item)
                return null
            })

            if (!tcsNew.length) return
            const { usecase_id } = tcsNew[0]
            this.testcase_tc_bulk_upsert(tcsNew)
            await this.testcase_bulk_store({ data: tcsNew, usecase_id })
            this.error_bulk_items.tc = []

            const tcIds = tcsNew.map(({ id }) => id)
            await this.local_tc_bulk_index({ ids: tcIds })
            this.testcase_tc_bulk_upsert({ ids: tcIds, loading_status: null, bulk_type: 'update' })
        },

        async local_tc_bulk_store (tcsNew, usecase_id, clearLoading) {
            this.bulkModule = { parent: usecase_id, mode: 'tc' }
            tcsNew = tcsNew.filter(item => (!!item && (item && item.trim())))
            tcsNew = tcsNew.map(title => {
                if (title && title.trim()) {
                    return this.$appendId({ title, usecase_id, assignees: [], status_id: null, loading_status: 'store' })
                }
            })
            tcsNew = tcsNew.filter(item => {
                const { message } = new Validate(item.title, { field: 'title', silent: true }).length(1, 1000).run()
                if (!message) {
                    const prevErrorIndex = this.error_bulk_items.tc.findIndex(prev => prev.id === item.id)
                    if (prevErrorIndex !== -1) this.error_bulk_items.tc.splice(prevErrorIndex, 1)
                    return item
                }
                this.error_bulk_items.tc.push(item)
                return null
            })

            if (!tcsNew.length) return clearLoading()
            this.testcase_tc_bulk_upsert(tcsNew)
            await this.local_tc_sc_chunk_bulk_add(tcsNew, usecase_id, this.testcase_bulk_store)
            clearLoading()
            this.$notify('success', `Testcase${tcsNew.length > 1 ? '(s)' : ''} created successfully!`)

            const tcIds = tcsNew.map(({ id }) => id)
            await this.local_tc_bulk_index({ ids: tcIds })
            this.testcase_tc_bulk_upsert({ ids: tcIds, loading_status: null, bulk_type: 'update' })
        },

        async local_tc_sc_chunk_bulk_add (newItems, usecase_id, callback) {
            const ceilCount = Math.ceil(newItems.length / this.chunk_count)
            let list = _.cloneDeep(newItems)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                await callback({ data: finalSet, usecase_id, project_id: this.local_project_id })
            }
        },

        async local_tc_bulk_index (args) {
            const { ids = null, mode = null } = args || {}
            const params =  {
                mode: mode ?? 'update-list-selected-item',
                params: {
                    ...this.local_testcase_fields,
                    'filter[project_id]': this.local_project_id,
                    'filter[collaborators]': true,
                    project_id: this.local_project_id,
                    count: 999
                }
            }

            if (ids) params[params['filter[id]']] = ids.join(',')
            await this.testcase_index(params)
        },
        // Testcase List --- End

        // Success Criteria List --- Start
        async local_error_update_sc (scsNew) {
            scsNew = scsNew.filter(item => {
                const { message } = new Validate(item.title, { field: 'title', silent: true }).length(1, 1000).run()
                if (!message) {
                    const prevErrorIndex = this.error_bulk_items.sc.findIndex(prev => prev.id === item.id)
                    if (prevErrorIndex !== -1) this.error_bulk_items.sc.splice(prevErrorIndex, 1)
                    return item
                }
                this.error_bulk_items.sc.push(item)
                return null
            })

            if (!scsNew.length) return
            const { usecase_id } = scsNew[0]
            this.success_criterion_sc_bulk_upsert(scsNew)
            await this.success_criterion_bulk_store({ data: scsNew, usecase_id })
            this.error_bulk_items.sc = []

            const scIds = scsNew.map(({ id }) => id)
            await this.local_sc_bulk_index({ ids: scIds })
            this.success_criterion_sc_bulk_upsert({ ids: scIds, loading_status: null, bulk_type: 'update' })
        },

        async local_sc_bulk_store (scsNew, usecase_id, clearLoading) {
            this.bulkModule = { parent: usecase_id, mode: 'sc' }
            scsNew = scsNew.filter(item => (!!item && (item && item.trim())))
            scsNew = scsNew.map(title => {
                if (title && title.trim()) {
                    return this.$appendId({ title, usecase_id, assignees: [], status_id: null, loading_status: 'store' })
                }
            })
            scsNew = scsNew.filter(item => {
                const { message } = new Validate(item.title, { field: 'title', silent: true }).length(1, 1000).run()
                if (!message) {
                    const prevErrorIndex = this.error_bulk_items.sc.findIndex(prev => prev.id === item.id)
                    if (prevErrorIndex !== -1) this.error_bulk_items.sc.splice(prevErrorIndex, 1)
                    return item
                }
                this.error_bulk_items.sc.push(item)
                return null
            })

            if (!scsNew.length) return clearLoading()
            this.success_criterion_sc_bulk_upsert(scsNew)
            await this.local_tc_sc_chunk_bulk_add(scsNew, usecase_id, this.success_criterion_bulk_store)
            clearLoading()
            this.local_get_usecase_scs_count(usecase_id)
            this.$notify('success', `Success Criteria${scsNew.length > 1 ? '(s)' : ''} created successfully!`)

            const scIds = scsNew.map(({ id }) => id)
            await this.local_sc_bulk_index({ ids: scIds })
            this.success_criterion_sc_bulk_upsert({ ids: scIds, loading_status: null, bulk_type: 'update' })
        },

        async local_fetch_tc_sc_list (args) {
            const { usecase_ids = null, isPagination = false } = args || {}
            await this.local_sc_index({ usecase_ids, isPagination })
            await this.local_tc_index({ usecase_ids, isPagination })
        },

        async local_sc_bulk_index (args) {
            const { ids = null, mode = null } = args || {}
            const params =  {
                mode: mode ?? 'update-list-selected-item',
                params: {
                    ...this.local_success_criterion_fields,
                    'filter[project_id]': this.local_project_id,
                    'filter[collaborators]': true,
                    project_id: this.local_project_id,
                    count: 999
                }
            }

            if (ids) params[params['filter[id]']] = ids.join(',')
            await this.success_criterion_index(params)
        },

        async local_sc_index (args) {
            const { bulkMode = false, usecase_ids = null, ids = null, updateAddMode = null, isPagination = false } = args || {}
            const usecaseIds = usecase_ids ? usecase_ids : this.usecase_list.map(({ id }) => id).join(',')
            const params =  {
                params: {
                    ...this.local_success_criterion_fields,
                    'filter[project_id]': this.local_project_id,
                    'filter[collaborators]': true,
                    'filter[usecase_id]': usecaseIds,
                    project_id: this.local_project_id,
                    count: 999
                }
            }

            if (bulkMode) {
                params.params['filter[id]'] = ids ?? this.sc_bulk_ids.join(',')
                params['mode'] = 'update-list-selected-item'
            }
            if (updateAddMode) params['mode'] = 'bulk-mode-list-update'
            if (isPagination) params['mode'] = 'pagination-index'

            await this.success_criterion_index(params)
        },

        async local_tc_index (args) {
            const { bulkMode = false, usecase_ids = null, ids = null, updateAddMode = null, isPagination = false } = args || {}
            const usecaseIds = usecase_ids ? usecase_ids : this.usecase_list.map(({ id }) => id).join(',')
            const params = {
                params: {
                    ...this.local_testcase_fields,
                    'filter[project_id]': this.local_project_id,
                    'filter[usecase_id]': usecaseIds,
                    'filter[collaborators]': true,
                    project_id: this.local_project_id,
                    count: 999
                }
            }

            if (bulkMode) {
                params.params['filter[id]'] = ids ?? this.tc_bulk_ids.join(',')
                params['mode'] = 'update-list-selected-item'
            }
            if (updateAddMode) params['mode'] = 'bulk-mode-list-update'
            if (isPagination) params['mode'] = 'pagination-index'

            await this.testcase_index(params)
        },
        // Success Criteria List --- End

        // Usecase module --- Start
        async local_usecase_index (args) {
            const { onlyUcIndex = false, params } = args || {}
            const localParams = {
                params: {
                    ...this.local_usecase_fields,
                    count: !this.filter_selected ? this.usecases_count : 999,
                    'filter[project_id]': this.$route.params.id,                   
                }
            }

            await this.usecase_index(params ?? localParams)
            this.local_page_number = this.usecase_meta.current_page
            this.last_page_number = this.usecase_meta.last_page

            if (!onlyUcIndex) {
                await this.local_fetch_tc_sc_list()
                this.local_expand_all_upto(this.default_expand_count)
            }
            this.local_set_originals()
            if (this.local_loading) this.local_loading = false
        },

        async local_usecase_index_bulk_mode (onlyUcIndex = false, ids) {
            const params = {
                mode: 'update-list-selected-item',
                params: {
                    ...this.local_usecase_fields,
                    count: !this.filter_selected ? this.usecases_count : 999,
                    'filter[project_id]': this.$route.params.id,
                    'filter[id]': ids.join(','),
                }
            }

            await this.local_usecase_index({ onlyUcIndex, params })
        },

        local_set_originals () {
            this.original_uc_list = _.cloneDeep(this.usecase_list)
            this.original_sc_list = [...this.original_sc_list, ..._.cloneDeep(this.success_criterion_list)]
            this.original_tc_list = [...this.original_tc_list, ..._.cloneDeep(this.testcase_list)]
        },

        async local_reorder(list) {
            this.usecase_reorder({list: list, project_id: this.$route.params.id})
        },

        async local_usecase_delete_all() {
            await this.usecase_delete_all({project_id: this.project_item.id})
            if (this.usecase_response.status !== 'success') {
                return this.$notify('error', 'Use Case delete all operation failed.')
            }
            this.dialog_usecase_delete_all = false
            this.$notify('success', 'Deleted all the use cases')
        },

        async local_visibility_toggle (id, state) {
            const usecase_item = this.usecase_list.find(item => item.id === id)
            usecase_item.visibility = state === 'internal' ? 'external' : 'internal'
            await this.usecase_visibility(Object.assign({}, { id, state: usecase_item.visibility }))
            if (this.usecase_response.status !== 'success') this.$notify('error', 'Error updating usecase visibility.')
            if (this.dialog_uc_detail_view) this.local_update_uc_detail_item(id)
        },

        local_bulk_select_all_toggle () {
            if (this.bulk_delete_loading || !this.local_usecase_list_length) return
            this.bulk_selected_all = !this.bulk_selected_all
            if (this.bulk_selected_all) {
                this.bulk_delete_ids = this.usecase_list.map(({id}) => id)
                return true
            }
            this.bulk_delete_ids = []
        },

        local_bulk_select_uc (usecase_id) {
            const hasIdIndex = this.uc_bulk_ids.findIndex(id => id === usecase_id)
            if (hasIdIndex !== -1) this.uc_bulk_ids.splice(hasIdIndex, 1)
            else this.uc_bulk_ids.push(usecase_id)

            if (!this.uc_bulk_ids.length && this.filter_checkbox_all) this.filter_checkbox_all = false
            if (this.uc_bulk_ids.length === this.local_filtered_ucs.length) this.filter_checkbox_all = true
        },

        local_bulk_select_sc (tc_id) {
            const hasIdIndex = this.sc_bulk_ids.findIndex(id => id === tc_id)
            if (hasIdIndex !== -1) this.sc_bulk_ids.splice(hasIdIndex, 1)
            else this.sc_bulk_ids.push(tc_id)

            if (!this.sc_bulk_ids.length && this.filter_checkbox_all) this.filter_checkbox_all = false
            if (this.sc_bulk_ids.length === this.local_filtered_scs.length) this.filter_checkbox_all = true
        },

        local_bulk_select_tc (sc_id) {
            const hasIdIndex = this.tc_bulk_ids.findIndex(id => id === sc_id)
            if (hasIdIndex !== -1) this.tc_bulk_ids.splice(hasIdIndex, 1)
            else this.tc_bulk_ids.push(sc_id)

            if (!this.tc_bulk_ids.length && this.filter_checkbox_all) this.filter_checkbox_all = false
            if (this.tc_bulk_ids.length === this.local_filtered_tcs.length) this.filter_checkbox_all = true
        },

        local_is_bulk_selected (id, type) {
            if (type === 'uc') return this.uc_bulk_ids.includes(id)
            if (type === 'sc') return this.sc_bulk_ids.includes(id)
            if (type === 'tc') return this.tc_bulk_ids.includes(id)
        },

        local_filter_select_all (value, slug = null) {
            if (!this.filter_selected) return

            if (this.filter_selected && this.filter_selected.value === 'all-uc') {
                if (!value) this.uc_bulk_ids = []
                else {
                    this.uc_bulk_ids = []
                    if (this.filter_mode_selected && this.filter_mode_selected.mode_slug === 'visibility') {
                        const filterdIds = this.usecase_list.filter(uc => uc.visibility === this.filter_mode_selected.label.toLowerCase()).map(({ id }) => id)
                        this.uc_bulk_ids = [...filterdIds]
                    } else this.uc_bulk_ids = [...this.usecase_list.map(({ id }) => id)]
                }
            }
            if (this.filter_selected && this.filter_selected.value === 'all-sc') {
                if (!value) this.sc_bulk_ids = []
                else {
                    this.sc_bulk_ids = []
                    if (this.selected_status_id) {
                        const filterdIds = this.success_criterion_list.filter(sc => sc.status_id === this.selected_status_id).map(({ id }) => id)
                        // const filterdIds = this.local_filtered_scs.filter(sc => sc.status_id === this.selected_status_id).map(({ id }) => id)
                        this.sc_bulk_ids = [...filterdIds]
                    } else {
                        this.sc_bulk_ids = [...this.success_criterion_list.map(({ id }) => id)]
                        // this.sc_bulk_ids = [...this.local_filtered_scs.map(({ id }) => id)]
                    }
                }
            }

            if (this.filter_selected && this.filter_selected.value === 'all-tc') {
                if (!value) this.tc_bulk_ids = []
                else {
                    if (this.selected_status_id) {
                        this.tc_bulk_ids = []
                        const filterdIds = this.testcase_list.filter(sc => sc.status_id === this.selected_status_id).map(({ id }) => id)
                        // const filterdIds = this.local_filtered_tcs.filter(sc => sc.status_id === this.selected_status_id).map(({ id }) => id)
                        this.tc_bulk_ids = [...filterdIds]
                    } else {
                        this.tc_bulk_ids = []
                        this.tc_bulk_ids = [...this.testcase_list.map(({ id }) => id)]
                        // this.tc_bulk_ids = [...this.local_filtered_tcs.map(({ id }) => id)]
                    }
                }
            }
        },

        async local_clear_bulk_selection (value, clearLoading) {
            this.local_loading = true
            this.local_clear_bulk_ids()
            this.local_reset_filters()
            this.local_usecase_fields.page = 1
            clearLoading()
            await this.usecase_clear()
            await this.success_criterion_clear()
            await this.testcase_clear()
            await this.local_usecase_index()
        },

        local_reset_filters () {
            this.filter_mode_selected = { mode_slug: '', label: '', status: '', status_id: '' }
            this.filter_selected = null
            this.selected_status_id = null
            this.show_selected = false
        },

        local_clear_bulk_ids () {
            this.uc_bulk_ids = []
            this.sc_bulk_ids = []
            this.tc_bulk_ids = []
        },

        local_filter_by_status (status_id, type) {
            this.selected_status_id = status_id
        },

        local_expand_all_upto (limit = 5, expandedAll, slug, clearLoading) {
            if (expandedAll === false && slug === 'custom') {
                if (clearLoading) clearLoading()
                return this.expanded_ids = []
            }
            const ids = this.usecase_list.map(({ id }) => id)
            this.expanded_ids = limit !== -1 ? ids.slice(0, limit) : ids
            if (clearLoading) clearLoading()
        },

        local_toggle_expand (params) {
            const { id, is_expanded, clearLoading } = params || {}
            if (is_expanded) {
                const index = this.expanded_ids.findIndex(item => item === id)
                if (index !== -1) this.expanded_ids.splice(index, 1)
                if (clearLoading) clearLoading()
                return true
            }
            this.expanded_ids.push(id)
            if (clearLoading) clearLoading()
        },

        local_check_expanded (usecase_id) {
            const index = this.expanded_ids.findIndex(id => id === usecase_id)
            return index !== -1
        },

        async local_usecase_delete (id) {
            this.dialog_uc_detail_view = false
            await this.usecase_destroy({ id })
            this.$notify('success', 'Use Case deleted successfully!')
        },
        // Usecase module --- End

        // Usecase templates CRUD -----
        async local_template_export_form() {
            this.template_group_clear()
            await this.template_usecase_clear_item()
            this.dialog_export_form = true
            if (this.$can('templates.index')) {
                await this.template_group_index({
                    'fields[template_groups]': 'id,name',
                    'filter[type]': 'usecases'
                })
            }
        },

        async local_template_import_form() {
            this.local_set_loading('import-form')
            if (this.$can('templates.index') == true) {
                await this.template_group_index({
                    include: 'templateUsecasesCount',
                    'fields[template_groups]': 'id,name',
                    'filter[type]': 'usecases'
                })
            }
            this.local_set_loading(null)
        },

        async local_template_import(id) {
            this.local_set_loading('import-' + id)
            const import_item = {}
            import_item.project_id = this.local_project_id
            import_item.template_group_id = id
            await this.usecase_template_import(import_item)
            this.local_usecase_index()
            this.$notify('success', 'Use Case imported successfully!')
            this.local_set_loading(null)
        },
        // Usecase templates CRUD ----- END

        // Filters Bulk Selection --- Start
        async local_on_filter_selected (filter, clearLoading) {
            this.local_set_loading('filter-selected')
            if (this.filter_selected && this.filter_selected.value !== filter.value) {
                this.local_clear_bulk_ids()
                this.filter_mode_selected = { mode_slug: '', label: '', status: '', status_id: '' }
                this.selected_status_id = null
            }
            this.filter_selected = filter
            this.filter_checkbox_all = false
            this.local_usecase_fields.page = 1
            this.show_selected = false
            await this.local_usecase_index({ onlyUcIndex: true })
            if (filter.value === 'all-sc') await this.local_sc_bulk_index({ mode: 'bulk-mode-list-update' })
            if (filter.value === 'all-tc') await this.local_tc_bulk_index({ mode: 'bulk-mode-list-update' })
            this.local_set_originals()
            this.local_expand_all_upto(-1)
            this.local_set_loading(null)
            clearLoading()
        },

        local_on_expand_collapse_all (expand, clearLoading) {
            const count = expand ? -1 : this.default_expand_count
            this.local_expand_all_upto(count, expand, 'custom', clearLoading)
        },

        local_on_apply_action () {},
        // Filters Bulk Selection --- End

        // Bulk Assignee / Tags --- Start
        local_bulk_assign_modal_open () {
            this.dialog_bulk_edit = true
            this.isAddAssignee = true
        },

        local_add_tags_modal() {
            this.dialog_bulk_edit = true
            this.isAddTag = true
            this.tag_dialogue_status = true
        },

        async local_bulk_assign_collabs (selectedCollabs, assigneeCount, bulkids, getmodule) {
            const bulkIds = [...new Set(bulkids)]
            if (this.filter_selected && assigneeCount) {
                this.local_set_loading('bulk-assign')
                switch (this.filter_selected.value) {
                    case 'all-uc': {
                        await this.local_uc_bulk_assign(selectedCollabs, this.uc_bulk_ids, bulkIds)
                        break;
                    }
                    case 'all-sc': {
                        await this.local_sc_bulk_assign(selectedCollabs, this.sc_bulk_ids, bulkIds)
                        break;
                    }
                    case 'all-tc': {
                        await this.local_tc_bulk_assign(selectedCollabs, this.tc_bulk_ids, bulkIds)
                        break;
                    }
                }
            }
            this.dialog_bulk_edit = false
            this.$notify('success', `Successfully Updated ${assigneeCount} ${getmodule}!`)
        },

        async local_uc_bulk_assign (collabIds, ucIds, bulkIds) {
            let filteredUcIds = bulkIds
            if (ucIds.length !== bulkIds.length) {
                filteredUcIds = ucIds.filter(id => !bulkIds.includes(id))
            }

            const ceilCount = Math.ceil(filteredUcIds.length / this.chunk_count)
            const list = _.cloneDeep(filteredUcIds)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                await this.assignee_bulk_assign({ ...this.local_set_bulk_assign_params(collabIds, finalSet) })
                this.local_usecase_fields.page = 1
                await this.local_usecase_index_bulk_mode(true, finalSet)
            }
            this.local_set_loading(null)
        },

        async local_sc_bulk_assign (collabIds, scIds, bulkIds) {
            let filteredScIds = bulkIds
            if (scIds.length !== bulkIds.length) {
                filteredScIds = scIds.filter(id => !bulkIds.includes(id))
            }

            const ceilCount = Math.ceil(filteredScIds.length / this.chunk_count)
            const list = _.cloneDeep(filteredScIds)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                await this.assignee_bulk_assign({ ...this.local_set_bulk_assign_params(collabIds, finalSet) })
                this.local_sc_bulk_index({ ids: finalSet })
            }
            this.local_set_loading(null)
        },

        async local_tc_bulk_assign (collabIds, tcIds, bulkIds) {
            let filteredTcIds = bulkIds
            if (tcIds.length !== bulkIds.length) {
                filteredTcIds = tcIds.filter(id => !bulkIds.includes(id))
            }

            const ceilCount = Math.ceil(filteredTcIds.length / this.chunk_count)
            const list = _.cloneDeep(filteredTcIds)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                await this.assignee_bulk_assign({ ...this.local_set_bulk_assign_params(collabIds, finalSet) })
                this.local_tc_bulk_index({ ids: finalSet })
            }
            this.local_set_loading(null)
        },

        async local_chunk_sc_tc_index (params, ids, callback) {
            const ceilCount = Math.ceil(ids.length / this.chunk_count)
            let list = _.cloneDeep(ids)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                await callback({ ...params, scIds: finalSet })
            }
        },

        local_set_bulk_assign_params (collabIds, moduleIds) {
            return {
                project_id: this.local_project_id,
                resource_type: this.local_resource_type,
                resource_ids: moduleIds,
                user_ids: collabIds.map(({ user_id }) => user_id)
            }
        },

        async local_bulk_add_tags (selectedTags, tagCount, scopeTagIds, getmodule) {
            const bulkIds = [...new Set(scopeTagIds)]
            if (this.filter_selected && tagCount) {
                this.local_set_loading('bulk-assign')
                switch (this.filter_selected.value) {
                    case 'all-uc': {
                        await this.local_uc_bulk_add_tags(selectedTags, this.uc_bulk_ids, bulkIds)
                        break;
                    }
                    case 'all-sc': {
                        await this.local_sc_bulk_add_tags(selectedTags, this.sc_bulk_ids, bulkIds)
                        break;
                    }
                    case 'all-tc': {
                        await this.local_tc_bulk_add_tags(selectedTags, this.tc_bulk_ids, bulkIds)
                        break;
                    }
                }
            }
            this.dialog_bulk_edit = false
            this.$notify('success', `Successfully Updated ${tagCount} ${getmodule}!`)
        },

        async local_sc_bulk_add_tags (tagIds, scIds, scopeTagIds) {
            let filteredScIds = scopeTagIds
            if (scIds.length !== scopeTagIds.length) {
                filteredScIds = scIds.filter(id => !scopeTagIds.includes(id))
            }

            const ceilCount = Math.ceil(filteredScIds.length / this.chunk_count)
            const list = _.cloneDeep(filteredScIds)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                await this.association_bulk_store_scope({ ...this.local_set_bulk_assign_tag_params(tagIds, finalSet, "SuccessCriterion") })
                this.local_usecase_fields.page = 1
                await this.local_sc_bulk_index({ ids: finalSet })
            }
            this.local_set_loading(null)
        },

        async local_tc_bulk_add_tags (tagIds, tcIds, scopeTagIds) {
            let filteredTcIds = scopeTagIds
            if (tcIds.length !== scopeTagIds.length) {
                filteredTcIds = tcIds.filter(id => !scopeTagIds.includes(id))
            }

            const ceilCount = Math.ceil(filteredTcIds.length / this.chunk_count)
            const list = _.cloneDeep(filteredTcIds)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                await this.association_bulk_store_scope({ ...this.local_set_bulk_assign_tag_params(tagIds, finalSet, "Testcase") })
                this.local_usecase_fields.page = 1
                await this.local_tc_bulk_index({ ids: finalSet })
            }
            this.local_set_loading(null)
        },

        async local_uc_bulk_add_tags (tagIds, ucIds, scopeTagIds) {
            let filteredUcIds = scopeTagIds
            if (ucIds.length !== scopeTagIds.length) {
                filteredUcIds = ucIds.filter(id => !scopeTagIds.includes(id))
            }

            const ceilCount = Math.ceil(filteredUcIds.length / this.chunk_count)
            const list = _.cloneDeep(filteredUcIds)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                await this.association_bulk_store_scope({ ...this.local_set_bulk_assign_tag_params(tagIds, finalSet, "Usecase") })
                this.local_usecase_fields.page = 1
                await this.local_usecase_index_bulk_mode(true, finalSet)
            }
            this.local_set_loading(null)
        },

        local_set_bulk_assign_tag_params(tagIds, moduleIds, sourceType) {
            return {
                source_type: sourceType,
                target_type: "Tag",
                type: "usecase_tag",
                source_ids: moduleIds,
                target_ids: tagIds.map(({id})=> id),
            }
        },
        // Bulk Assignee --- End

        // Bulk Status Update --- Start
        local_bulk_status_update_model () {
            this.comment_error = ''
            this.dialog_bulk_status_update = true
        },

        async local_bulk_status_update (selectedStatus, clearLoading) {
            if (this.filter_selected) {
                this.local_set_loading('bulk-status-update')
                switch (this.filter_selected.value) {
                    case 'all-sc': {
                        if (selectedStatus.comment && selectedStatus.comment.trim()) {
                            const { success } = await this.local_add_comment_to_status(selectedStatus.comment, 'SuccessCriterion', this.sc_bulk_ids)
                            if (!success) return this.local_set_comment_error(clearLoading)
                        }
                        await this.local_sc_bulk_status_update(selectedStatus, this.sc_bulk_ids)
                        break;
                    }
                    case 'all-tc': {
                        if (selectedStatus.comment && selectedStatus.comment.trim()) {
                            const { success } = await this.local_add_comment_to_status(selectedStatus.comment, 'Testcase', this.tc_bulk_ids)
                            if (!success) return this.local_set_comment_error(clearLoading)
                        }
                        await this.local_tc_bulk_status_update(selectedStatus, this.tc_bulk_ids)
                        break;
                    }
                }
            }
            clearLoading()
            this.dialog_bulk_status_update = false
        },

        local_set_comment_error (clearLoading) {
            this.comment_error = 'Maximum of 5000 characters'
            this.local_set_loading(null)
            clearLoading()
        },

        async local_add_comment_to_status (comment, module, ids) {
            const { message } = new Validate(comment, { silent: true }).length(1, 5000).run()
            if (message) return { success: false }

            this.local_comment_store(comment, module, ids)
            return { success: true }
        },

        async local_comment_store (comment, type, ids) {
            if (!comment || (comment && !comment.trim())) return
            const comment_item = {}
            comment_item.commentable_type = type
            comment_item.visibility = 'internal'
            comment_item.content = comment

            const ceilCount = Math.ceil(ids.length / this.chunk_count)
            let list = _.cloneDeep(ids)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                comment_item.commentable_ids = finalSet
                await this.comment_bulk_store(comment_item)
            }
        },

        async local_sc_bulk_status_update (selectedStatus, scIds) {
            await this.local_add_status_as_chunks(selectedStatus, scIds, this.success_criterion_bulk_status_update, 'sc')
            this.local_set_loading(null)
        },

        async local_tc_bulk_status_update (selectedStatus, tcIds) {
            await this.local_add_status_as_chunks(selectedStatus, tcIds, this.testcase_bulk_status_update, 'tc')
            this.local_set_loading(null)
        },

        async local_add_status_as_chunks (status, ids, callback, mode) {
            const ceilCount = Math.ceil(ids.length / this.chunk_count)
            let list = _.cloneDeep(ids)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                const params = this.local_set_bulk_status_update_params(status, finalSet)
                await callback(params)
                this['local_' + mode + '_bulk_index']({ bulkMode: true, ids: finalSet })
            }
        },

        async local_get_sc_tc_index_as_chunks (ids, callback) {
            const ceilCount = Math.ceil(ids.length / this.chunk_count)
            let list = _.cloneDeep(ids)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                await callback({ bulkMode: true, finalSet })
            }
        },

        local_set_bulk_status_update_params ({ status, comment }, moduleIds) {
            return {
                project_id: this.local_project_id,
                ids: moduleIds,
                status_id: status.id,
            }
        },
        // Bulk Status Update --- End

        // Remove Collabs / Tags --- Start
        local_remove_collabs_model () {
            this.dialog_remove_collabs = true
        },

        local_bulk_remove_modal_open () {
            this.isRemoveAssignee = true
            this.dialog_bulk_edit = true
        },

        local_remove_tags_modal() {
            this.isRemoveTag = true
            this.tag_dialogue_status = false
            this.dialog_bulk_edit = true
        },

        async local_remove_collabs (selectedCollabs, assignCount, scopeAssigneeIds, getmodule) {
            let bulk_ids = [...new Set(scopeAssigneeIds)]
            if (this.filter_selected) {
                this.local_set_loading('bulk-remove-assignee')
                switch (this.filter_selected.value) {
                    case 'all-uc': {
                        if (selectedCollabs && selectedCollabs.length) await this.local_uc_bulk_remove(selectedCollabs, bulk_ids)
                        else await this.local_uc_bulk_remove_assignee(bulk_ids)
                        break;
                    }
                    case 'all-sc': {
                        if (selectedCollabs && selectedCollabs.length) await this.local_sc_bulk_remove(selectedCollabs, bulk_ids)
                        else await this.local_sc_bulk_remove_assignee(bulk_ids)
                        break;
                    }
                    case 'all-tc': {
                        if (selectedCollabs && selectedCollabs.length) await this.local_tc_bulk_remove(selectedCollabs, bulk_ids)
                        else await this.local_tc_bulk_remove_assignee(bulk_ids)
                        break;
                    }
                }
            }
            this.dialog_remove_collabs = false
            this.dialog_bulk_edit = false
            this.$notify('success', `Successfully Updated ${assignCount} ${getmodule}!`)
        },

        async local_uc_bulk_remove (collabIds, ucIds) {
            const ceilCount = Math.ceil(ucIds.length / this.chunk_count)
            let list = _.cloneDeep(ucIds)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                await this.assignee_bulk_remove({ ...this.local_set_bulk_assign_params(collabIds, finalSet) })
                this.local_usecase_fields.page = 1
                await this.local_usecase_index_bulk_mode(true, finalSet)
            }
            this.local_set_loading(null)
        },

        async local_sc_bulk_remove (collabIds, scIds) {
            const ceilCount = Math.ceil(scIds.length / this.chunk_count)
            let list = _.cloneDeep(scIds)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                await this.assignee_bulk_remove({ ...this.local_set_bulk_assign_params(collabIds, finalSet) })
                this.local_sc_bulk_index({ ids: finalSet })
            }
            this.local_set_loading(null)
        },

        async local_tc_bulk_remove (collabIds, tcIds) {
            const ceilCount = Math.ceil(tcIds.length / this.chunk_count)
            let list = _.cloneDeep(tcIds)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                await this.assignee_bulk_remove({ ...this.local_set_bulk_assign_params(collabIds, finalSet) })
                this.local_tc_bulk_index({ ids: finalSet })
            }
            this.local_set_loading(null)
        },

        async local_uc_bulk_remove_assignee (ucIds) {
            await this.local_remove_uc_assignee_by_chunks(ucIds)
            this.local_set_loading(null)
        },

        async local_remove_uc_assignee_by_chunks (ids, callback) {
            const ceilCount = Math.ceil(ids.length / this.chunk_count)
            let list = _.cloneDeep(ids)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                const params = this.local_set_assign_remove_params(finalSet)
                await this.assignee_bulk_remove(params)
                this.local_usecase_fields.page = 1
                await this.local_usecase_index_bulk_mode(true, finalSet)
            }
        },

        async local_sc_bulk_remove_assignee (scIds) {
            await this.local_remove_assignee_by_chunks(scIds, this.local_sc_bulk_index)
            this.local_set_loading(null)
        },

        async local_tc_bulk_remove_assignee (tcIds) {
            await this.local_remove_assignee_by_chunks(tcIds, this.local_tc_bulk_index)
            this.local_set_loading(null)
        },

        async local_remove_assignee_by_chunks (ids, callback) {
            const ceilCount = Math.ceil(ids.length / this.chunk_count)
            let list = _.cloneDeep(ids)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                const params = this.local_set_assign_remove_params(finalSet)
                await this.assignee_bulk_remove(params)
                callback({ bulkMode: true, ids: finalSet })
            }
        },

        local_set_assign_remove_params (moduleIds) {
            return {
                project_id: this.local_project_id,
                resource_type: this.local_resource_type,
                resource_ids: moduleIds,
            }
        },

        async local_bulk_remove_tags (selectedTags, tagCount, bulkIds, getmodule) {
            let bulk_ids = [...new Set(bulkIds)]
            if (this.filter_selected && tagCount) {
                this.local_set_loading('bulk-remove-tags')
                switch (this.filter_selected.value) {
                    case 'all-uc': {
                        await this.local_uc_bulk_remove_tags(selectedTags, bulk_ids)
                        break;
                    }
                    case 'all-sc': {
                        await this.local_sc_bulk_remove_tags(selectedTags, bulk_ids)
                        break;
                    }
                    case 'all-tc': {
                        await this.local_tc_bulk_remove_tags(selectedTags, bulk_ids)
                        break;
                    }
                }
            }
            this.dialog_bulk_edit = false
            this.$notify('success', `Successfully Updated ${tagCount} ${getmodule}!`)
        },

        async local_uc_bulk_remove_tags (tagIds, ucIds) {
            const ceilCount = Math.ceil(ucIds.length / this.chunk_count)
            let list = _.cloneDeep(ucIds)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                const ucList = this.usecase_list.filter(i => finalSet.includes(i.id))
                const tagList = _.flattenDeep(_.map(ucList, 'tags'))
                const targetIds = _.map(_.size(tagIds) ? tagIds : tagList, 'id')
                const associationIds = this.local_get_association_ids(tagIds, ucList)
                const ceilCountTags = Math.ceil(associationIds.length / this.chunk_count)
                for (let index = 0; index < ceilCountTags; index++) {
                    const finalIds = associationIds.splice(0, this.chunk_count)
                    if (targetIds.length) await this.association_bulk_destroy({ ...this.local_set_bulk_remove_tags_params(ucList, tagIds, finalSet, "Usecase", finalIds) })
                    this.local_usecase_fields.page = 1
                    await this.local_usecase_index_bulk_mode(true, finalSet)
                }
            }
            this.local_set_loading(null)
        },

        async local_sc_bulk_remove_tags (tagIds, ucIds) {
            const ceilCount = Math.ceil(ucIds.length / this.chunk_count)
            let list = _.cloneDeep(ucIds)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                const scList = this.success_criterion_list.filter(i => finalSet.includes(i.id))
                const tagList = _.flattenDeep(_.map(scList, 'tags'))
                const targetIds = _.map(_.size(tagIds) ? tagIds : tagList, 'id')
                const associationIds = this.local_get_association_ids(tagIds, scList)
                const ceilCountTags = Math.ceil(associationIds.length / this.chunk_count)
                for (let index = 0; index < ceilCountTags; index++) {
                    const finalIds = associationIds.splice(0, this.chunk_count)
                    if (targetIds.length) await this.association_bulk_destroy({ ...this.local_set_bulk_remove_tags_params(scList, tagIds, finalSet, "SuccessCriterion", finalIds) })
                    this.local_usecase_fields.page = 1
                    await this.local_sc_bulk_index({ ids: finalSet })
                }
            }
            this.local_set_loading(null)
        },

        async local_tc_bulk_remove_tags (tagIds, ucIds) {
            const ceilCount = Math.ceil(ucIds.length / this.chunk_count)
            let list = _.cloneDeep(ucIds)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                const tcList = this.testcase_list.filter(i => finalSet.includes(i.id))
                const tagList = _.flattenDeep(_.map(tcList, 'tags'))
                const targetIds = _.map(_.size(tagIds) ? tagIds : tagList, 'id')
                const associationIds = this.local_get_association_ids(tagIds, tcList)
                const ceilCountTags = Math.ceil(associationIds.length / this.chunk_count)
                for (let index = 0; index < ceilCountTags; index++) {
                    const finalIds = associationIds.splice(0, this.chunk_count)
                    if (targetIds.length) await this.association_bulk_destroy({ ...this.local_set_bulk_remove_tags_params(tcList, tagIds, finalSet, "Testcase", finalIds) })
                    this.local_usecase_fields.page = 1
                    await this.local_tc_bulk_index({ ids: finalSet })
                }
            }
            this.local_set_loading(null)
        },

        local_get_association_ids(tagIds, scopeList) {
            let ids = [] 
            const tagList = _.flattenDeep(scopeList.map(({tags}) => tags))
            if (!tagIds.length) return tagList.map(({association}) => association.id)

            tagList.forEach(tag => {
                const tagIDs = tagIds.find(item => item.id === tag.id)
                if (tagIDs) ids.push(tag)
            })
            return ids.map(({association}) => association.id)
        },

        local_set_bulk_remove_tags_params(scopeList, tagIds, moduleIds, sourceType, ids) {
            const tagList = _.flattenDeep(_.map(scopeList, 'tags'))
            const targetIds = _.map(_.size(tagIds) ? tagIds : tagList, 'id')

            return {
                source_type: sourceType,
                target_type: "Tag",
                source_ids: moduleIds,
                ids,
                target_ids: targetIds,
            }
        },
        // Remove Collabs / Tags --- End

        // Bulk Delete --- Start
        local_bulk_delete_model () {
            this.dialog_bulk_delete = true
        },

        async local_bulk_delete_confirm () {
            if (this.filter_selected) {
                this.local_set_loading('bulk-delete')
                switch (this.filter_selected.value) {
                    case 'all-uc':
                        await this.local_uc_bulk_delete(this.uc_bulk_ids)
                        break;
                    case 'all-sc':
                        await this.local_sc_bulk_delete(this.sc_bulk_ids)
                        break;
                    case 'all-tc':
                        await this.local_tc_bulk_delete(this.tc_bulk_ids)
                        break;
                }
            }
            this.dialog_bulk_delete = false
        },

        async local_uc_bulk_delete (ucIds) {
            this.usecase_delete_selected(this.uc_bulk_ids)
            await this.local_delete_by_chunks(ucIds, this.usecase_bulk_delete)
            // await this.usecase_bulk_delete({ ...this.local_set_bulk_delete_params(ucIds) })
            this.local_usecase_fields.page = 1
            this.filter_checkbox_all = false
            this.local_set_loading(null)
            this.uc_bulk_ids = []
        },

        async local_sc_bulk_delete (scIds) {
            this.success_criterion_delete_selected(this.sc_bulk_ids)
            await this.local_delete_by_chunks(scIds, this.success_criterion_bulk_delete)
            // await this.success_criterion_bulk_delete({ ...this.local_set_bulk_delete_params(scIds) })
            this.filter_checkbox_all = false
            this.local_set_loading(null)
            this.sc_bulk_ids = []
        },

        async local_tc_bulk_delete (tcIds) {
            this.testcase_delete_selected(this.tc_bulk_ids)
            await this.local_delete_by_chunks(tcIds, this.testcase_bulk_delete)
            // await this.testcase_bulk_delete({ ...this.local_set_bulk_delete_params(tcIds) })
            this.filter_checkbox_all = false
            this.local_set_loading(null)
            this.tc_bulk_ids = []
        },

        async local_delete_by_chunks (ids, callback) {
            const ceilCount = Math.ceil(ids.length / this.chunk_count)
            let list = _.cloneDeep(ids)
            for (let index = 0; index < ceilCount; index++) {
                const finalSet = list.splice(0, this.chunk_count)
                await callback({ ...this.local_set_bulk_delete_params(finalSet) })
            }
        },

        local_set_bulk_delete_params (moduleIds) {
            return {
                ids: moduleIds,
                project_id: this.local_project_id,
            }
        },
        // Bulk Delete --- End

        // Timetrack --- Start
        async local_open_time_track (testcase) {
            this.time_track_testcase = testcase
            this.dialog_est_list = true
            this.local_time_record_index()
        },

        async local_time_record_index () {
            await this.time_record_index({
                ...this.time_record_params,
                'filter[resource_type]': 'Testcase',
                'filter[resource_id]': this.time_track_testcase.id,
                'sort': '-created_at'
            })
        },
        // Timetrack --- Start

        // TC and SC detail view --- Start
        async local_show_uc_edit_pane (usecase, fromChild = null) {
            if (fromChild) this.local_clear_edit_pane_view()
            this.usecase_detail_item = {}
            this.usecase_detail_item = _.cloneDeep(usecase)
            this.dialog_uc_detail_view = true
            await this.usecase_show({
                id: usecase.id,
                mode: 'only-update-item',
                params: {
                    ...this.local_usecase_fields,
                    // 'include': 'tags,assignees.collaborator.type,updated_by,created_by',
                    'include': 'tags,assignees.collaborator.type',
                    // 'fields[usecases]': 'id,title,description_json,code,project_id,visibility,updated_at,created_at,created_by_id,updated_by_id',
                    'fields[usecases]': 'id,title,description_json,code,project_id,visibility,updated_at,created_at',
                    // 'fields[updated_by]': 'id,name',
                    // 'fields[created_by]': 'id,name',
                }
            })
            this.usecase_detail_item = _.cloneDeep(this.usecase_item)
            this.usecase_clear_item()
        },

        async local_show_edit_pane (edit_item, module, parentUsecase) {
            this.local_clear_pane_store_items(module)
            if (this.local_edit_pane_item.id && (this.local_edit_pane_item.id !== edit_item.id)) {
                this.local_update_list_item(_.cloneDeep(this.local_edit_pane_item), this.edit_pane_module)
            }
            if (this.local_edit_pane_item && this.local_edit_pane_item.id === edit_item.id) return

            // this.local_set_shortcut()
            this.selected_usecase_item = parentUsecase
            await this.local_reset_edit_pane()
            this.edit_pane_module = module
            this.local_edit_pane_item = _.cloneDeep(edit_item)
            if (!this.dialog_detail_view) this.dialog_detail_view = true
            await this.local_setup_pane(edit_item)
        },

        local_clear_pane_store_items () {
            if (module === 'Testcase') return this.testcase_clear_item()
            this.success_criterion_clear_item()
        },

        local_update_list_item (item, type) {
            const hasError = this.local_pane_response && this.local_pane_response.status !== 'success'
            if (hasError) {
                const oldItem = (type === 'Testcase' ? this.testcase_list : this.success_criterion_list).find(testcase => testcase.id === item.id)
                if (type === 'Testcase') return this.testcase_update_list(oldItem)
                return this.success_criterion_update_list(oldItem)
            }
            if (type === 'Testcase') return this.testcase_update_list(item)
            this.success_criterion_update_list(item)
        },

        async local_setup_pane (edit_item) {
            this.local_load_status(edit_item)
            this.local_load_time_est_tags(edit_item)
            this.local_load_comments(edit_item)
            this.local_load_assignee_and_static_fields(edit_item)
        },

        async local_load_status (model) {
            this.loaders.sidebar_time_est_loading = true
            const params = { include: 'status', 'fields[status]': 'id,type,value,status' }
            const testcase_params = { 'fields[testcases]': 'id,status_id', }
            const success_criteria_params = { 'fields[success_criteria]': 'id,status_id' }

            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(model.id, { ...params, ...testcase_params }, 'update-item')
                this.local_edit_pane_item.status_id = this.testcase_item.status_id
                this.local_edit_pane_item.status = this.testcase_item.status
                // this.local_merge_pane_item(this.testcase_item)
            } else {
                await this.local_success_criterion_show_item(model.id, { ...params, ...success_criteria_params }, 'update-item')
                this.local_edit_pane_item.status_id = this.success_criterion_item.status_id
                this.local_edit_pane_item.status = this.success_criterion_item.status
                // this.local_merge_pane_item(this.success_criterion_item)
            }
            this.loaders.sidebar_time_est_loading = false
        },

        async local_load_time_est_tags (model) {
            this.loaders.sidebar_time_est_loading = true
            const params = { include: 'tags', 'fields[tags]': 'tags.id,label,tags.type,color' }
            const testcase_params = { 'fields[testcases]': 'id,estimated_duration_text,estimated_duration_minutes', 'aggregate[time_records.duration_minutes]': 'sum' }
            const success_criteria_params = { 'fields[success_criteria]': 'id' }

            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(model.id, { ...params, ...testcase_params }, 'update-item')
                this.local_edit_pane_item.tags = this.testcase_item.tags
                this.local_edit_pane_item.time_records_sum_duration_minutes = this.testcase_item.time_records_sum_duration_minutes
                this.local_edit_pane_item.estimated_duration_minutes = this.testcase_item.estimated_duration_minutes
                this.local_edit_pane_item.estimated_duration_text = this.testcase_item.estimated_duration_text
            } else {
                await this.local_success_criterion_show_item(model.id, { ...params, ...success_criteria_params }, 'update-item')
                this.local_edit_pane_item.tags = this.success_criterion_item.tags
            }
            this.loaders.sidebar_time_est_loading = false
        },

        async local_load_comments (item) {
            this.loaders.sidebar_comments_loading = true
            const params = {
                include: 'comments.createdBy',
                'fields[comments]': 'id,commentable_id,commentable_type,content,created_at,created_by_id,updated_at,visibility',
                'fields[comments.createdBy]': 'id,name,avatar,color,initial',
            }

            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(item.id, { ...params, 'fields[testcases]': 'id' }, 'update-item')
                this.local_edit_pane_item.comments = this.testcase_item.comments
                // this.local_merge_pane_item({ comments: this.testcase_item.comments })
            } else {
                await this.local_success_criterion_show_item(item.id, { ...params, 'fields[success_criteria]': 'id' }, 'update-item')
                this.local_edit_pane_item.comments = this.success_criterion_item.comments
                // this.local_merge_pane_item({ comments: this.success_criterion_item.comments })
            }
            this.loaders.sidebar_comments_loading = false
        },

        async local_load_assignee_and_static_fields (item) {
            const params = { include: 'assignees.collaborator.type', 'filter[collaborators]': true, 'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial', project_id: this.local_project_id }
            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(item.id, { ...params, 'fields[testcases]': 'id,title,description_json,code,start_date,due_date,usecase_id' }, 'update-item')
                this.local_edit_pane_item.assignees = this.testcase_item.assignees
                Object.assign(this.local_edit_pane_item, this.testcase_item)
                // this.local_edit_pane_item.title = this.testcase_item.title
                // this.local_edit_pane_item.description_json = this.testcase_item.description_json
            } else {
                await this.local_success_criterion_show_item(item.id, { ...params, 'fields[success_criteria]': 'id,title,description_json,code,usecase_id' }, 'update-item')
                this.local_edit_pane_item.assignees = this.success_criterion_item.assignees
                Object.assign(this.local_edit_pane_item, this.success_criterion_item)
                // this.local_edit_pane_item.title = this.success_criterion_item.title
                // this.local_edit_pane_item.description_json = this.success_criterion_item.description_json
            }
        },

        local_merge_pane_item (item) {
            Object.assign(this.local_edit_pane_item, { ...item })
        },

        local_update_pane_item (evt) {
            if (this.edit_pane_module === 'Testcase') this.local_testcase_update(evt)
            else this.local_success_criterion_update(evt)
        },

        async local_testcase_update (item) {
            await this.testcase_update(item)
            if (this.testcase_response && this.testcase_response.status !== 'success') {
                this.loaders.content_autosave_response = false
                return this.$notify('error', 'Something went wrong, please re-check the fields.')
            }
        },

        async local_success_criterion_update(item) {
            await this.success_criterion_update(item)
            if (this.success_criterion_response && this.success_criterion_response.status !== 'success') {
                this.loaders.content_autosave_response = false
                return this.$notify('error', 'Something went wrong, please re-check the fields.')
            }
        },

        local_destroy_pane_item (evt, usecase_id) {
            if (this.edit_pane_module === 'Testcase') this.local_testcase_destroy(evt)
            else this.local_success_criterion_destroy(evt, usecase_id)
            this.local_clear_edit_pane_view()
            this.local_reset_edit_pane(true)
        },

        async local_testcase_destroy (id) {
            await this.testcase_destroy({id: id})
            if (this.testcase_response && this.testcase_response.status !== 'success') return
            this.$notify('success', 'Test Case deleted successfully!')
        },

        async local_success_criterion_destroy(id, usecase_id) {
            await this.success_criterion_destroy({id: id})
            if (this.success_criterion_response && this.success_criterion_response.status !== 'success') return
            this.local_get_usecase(usecase_id, 'fetchScCounts')
            this.$notify('success', 'Success Criteria deleted successfully!')
        },

        local_clear_edit_pane_view () {
            this.dialog_detail_view = false
            this.testcase_clear_item()
            this.success_criterion_clear_item()
            this.local_edit_pane_item = {}
        },

        async local_reset_edit_pane () {
            this.loaders.content_autosave_response = false
            this.local_edit_pane_item = { tags: [], comments: [], status_id: null, status: {} }
        },

        local_get_module (module, id) {
            if (module === 'SuccessCriterion') this.local_get_success_criterion(id, 'edit')
            if (module === 'Testcase') this.local_get_testcase(id, 'edit')
        },

        async local_get_success_criterion (success_criterion_id) {
            await this.success_criterion_show({
                id: success_criterion_id,
                params: {
                    project_id: this.local_project_id,
                    include: 'assignees.collaborator.type',
                    'fields[success_criteria]': 'id,usecase_id',
                    'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                    'filter[collaborators]': true
                }
            })

            Object.assign(this.local_edit_pane_item, this.success_criterion_item)
        },

        async local_get_testcase (testcase_id) {
            await this.testcase_show({
                id: testcase_id,
                params: {
                    project_id: this.local_project_id,
                    include: 'assignees.collaborator.type',
                    'fields[testcases]': 'id,usecase_id',
                    'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                    'filter[collaborators]': true
                }
            })

            Object.assign(this.local_edit_pane_item, this.testcase_item)
        },

        async local_update_list_item_status (module, item) {
            if (module === 'Testcase') return this.testcase_tc_upsert(item)
            await this.success_criterion_sc_upsert(item)
            this.local_get_usecase(item.usecase_id, 'fetchScCounts')
        },
        // TC and SC detail view --- End

        // Filterssss --- Start
        local_filter_mode (item, slug) {
            if (!['status', 'visibility'].includes(slug)) {
                this.selected_status_id = null
                this.local_update_filter_mode(item, slug, !!(slug === 'all'))
                this.local_filter_select_all(this.filter_checkbox_all)
                // this.filter_checkbox_all = !!(slug === 'all')
                // this.local_filter_select_all(this.filter_checkbox_all)
                // this.$set(this.filter_mode_selected, 'label', item)
                return
            }

            if (slug === 'visibility') {
                this.local_update_filter_mode(item, slug)
                this.local_filter_select_all(true, slug)
                this.filter_checkbox_all = true
                // this.filter_checkbox_all = !!(slug === 'all')
                // this.$set(this.filter_mode_selected, 'label', item)
                // this.local_filter_select_all(true)
                return true
            }

            this.$set(this.filter_mode_selected, 'mode_slug', slug)
            this.filter_mode_selected.label = item.value
            this.filter_mode_selected.status = item.status
            this.filter_mode_selected.status_id = item.id
            this.selected_status_id = item.id
            this.local_filter_select_all(true, slug)
            this.filter_checkbox_all = true
        },

        local_update_filter_mode (item, slug, checkedAll = null) {
            this.$set(this.filter_mode_selected, 'mode_slug', slug)
            this.filter_checkbox_all = checkedAll !== null ? checkedAll : !!(slug === 'all')
            this.$set(this.filter_mode_selected, 'label', item)
        },
        // Filterssss --- End

        // Keyboard navigations --- Start
        local_title_edit_focus ({ id, type, parentId }) {
            const element = document.getElementById(id)
            if (type !== 'parent') {
                const parent = document.getElementById(parentId)
                this.local_set_parent_focus(parent, 'noFocus')
                this.local_set_child_focus(element)
            } else this.local_set_parent_focus(element)
            this.local_on_enter('onClick')
        },

        local_keyboad_navigate_list (event) {
            const { which: code, ctrlKey, shiftKey } = event
            const [firstSlug, lastSlug] = event.code.split('Key')
             const [firstDigitSlug, lastDigitSlug] = event.code.split('Digit')
            //  const [firstArrowSlug, lastArrowSlug] = code.split('Arrow')
             const keys = ['Shift', 'Control', 'Alt', 'Tab', 'CapsLock']
             const testRegex = /[a-zA-Z0-9-_ ]/gi
             if (this.currentEditId) return

             if (((!testRegex.test(lastSlug) && !testRegex.test(lastDigitSlug)) || keys.includes(event.key))) {
                 if (!this.currentEditId) {
                     event.preventDefault()
                     event.stopPropagation()
                 }
             }

            if (code === 40) this.local_on_arrow_down() // arrow_down
            if (code === 38) this.local_on_arrow_up() // arrow_up
            if (code === 13 && !ctrlKey && !shiftKey) this.local_on_enter() // enter
            if (code === 27) this.local_on_escape() // escape
            // TODO: Add shortcut key to focus usecase create input
            //if (code === 191) { // forward slash ("/")
            //    if (!this.currentEditId) {
            //        event.preventDefault()
            //        event.stopPropagation()
            //        this.local_focus_usecase_create()
            //    }
            //}
        },

        local_on_arrow_down () {
            if (this.currentEditId) this.currentEditId = null
            if (this.prevNavElement === null) return true

            if (this.parentChildrenCount > 0) {
                if (this.prevNavElement !== 'child') {
                    let currentItem = this.currentParent.querySelector('.c-focusable-child')
                    const isTitleElement = currentItem.classList.contains('c-section-title')
                    currentItem = isTitleElement ? currentItem.nextElementSibling : currentItem
                    return this.local_set_child_focus(currentItem)
                }

                let nextChildCheck = this.currentChild.nextElementSibling
                let nextChild = !nextChildCheck ? this.currentChild.parentElement : nextChildCheck

                if (nextChildCheck) {
                    let isDragElementCheck = (nextChildCheck && nextChild.classList.contains('c-drag-parent')) ?? null
                    nextChild = isDragElementCheck ? nextChild.firstElementChild : nextChild
                } else {
                    let isDragElement = nextChild ? nextChild.classList.contains('c-drag-parent') : null
                    nextChild = isDragElement ? nextChild.nextElementSibling : nextChild
                }

                let isInputField = nextChild ? nextChild.classList.contains('c-add-input') : null
                nextChild = isInputField ? nextChild.nextElementSibling : nextChild

                const isTitleElement = nextChild ? nextChild.classList.contains('c-section-title') : null
                nextChild = isTitleElement ? nextChild.nextElementSibling : nextChild

                let isDragElement = nextChild ? nextChild.classList.contains('c-drag-parent') : null
                nextChild = isDragElement ? nextChild.firstElementChild : nextChild
                if (nextChild) return this.local_set_child_focus(nextChild)

                isInputField = nextChild ? nextChild.classList.contains('c-add-input') : null
                nextChild = isInputField ? nextChild.nextElementSibling : nextChild
                if (nextChild) return this.local_set_child_focus(nextChild)

                const nextParent = this.currentParent.nextElementSibling
                if (nextParent) return this.local_set_parent_focus(nextParent)
            }

            if (this.parentChildrenCount === 0) {
                const nextParent = this.currentParent.nextElementSibling
                if (nextParent) return this.local_set_parent_focus(nextParent)
            }
        },

        local_on_arrow_up () {
            if (this.currentEditId) this.currentEditId = null
            if (this.prevNavElement === null) return true

            if (this.parentChildrenCount > 0) {
                if (this.prevNavElement !== 'child') {
                    const previousParentSibling = this.currentParent.previousElementSibling
                    if (!previousParentSibling) return true

                    this.parentChildrenCount = parseInt(previousParentSibling.dataset.childCount)
                    if (!this.parentChildrenCount) {
                        this.local_set_parent_focus(previousParentSibling, 'noFocus')
                        this.local_set_child_focus(null)
                        return true
                    }

                    const prevParentSiblingLastChild = Array.from(previousParentSibling.getElementsByClassName('c-focusable-child')).slice(-1)[0]
                    this.local_set_parent_focus(previousParentSibling, 'noFocus')
                    this.local_set_child_focus(prevParentSiblingLastChild)
                    return true
                }

                let prevChildSiblingCheck = this.currentChild.previousElementSibling
                let prevChildSibling = !prevChildSiblingCheck ? this.currentChild.parentElement : prevChildSiblingCheck
                if (prevChildSiblingCheck) {
                    let isDragElementCheck = prevChildSiblingCheck && prevChildSibling.classList.contains('c-drag-parent')
                    prevChildSibling = isDragElementCheck ? prevChildSibling.lastElementChild : prevChildSibling
                } else {
                    let isDragElement = prevChildSibling ? prevChildSibling.classList.contains('c-drag-parent') : null
                    prevChildSibling = isDragElement ? prevChildSibling.previousElementSibling : prevChildSibling
                }

                let isInputField = prevChildSibling ? prevChildSibling.classList.contains('c-add-input') : null
                prevChildSibling = isInputField ? prevChildSibling.previousElementSibling : prevChildSibling
                let isChild = prevChildSibling ? prevChildSibling.classList.contains('c-focusable-child') : null
                if (isChild) return this.local_set_child_focus(prevChildSibling)
                let isTitle = prevChildSibling ? prevChildSibling.classList.contains('c-section-title') : null
                if (isTitle) {
                    let prevChildOfTitle = prevChildSibling.previousElementSibling
                    let isInputField = prevChildOfTitle ? prevChildOfTitle.classList.contains('c-add-input') : null
                    prevChildOfTitle = isInputField ? prevChildOfTitle.previousElementSibling : prevChildOfTitle
                    let isDragElement = prevChildOfTitle ? prevChildOfTitle.classList.contains('c-drag-parent') : null
                    prevChildOfTitle = isDragElement ? prevChildOfTitle.lastElementChild : prevChildOfTitle
                    let isChild = prevChildOfTitle ? prevChildOfTitle.classList.contains('c-focusable-child') : null
                    if (isChild) return this.local_set_child_focus(prevChildOfTitle)

                    const childParent = prevChildSibling.parentElement.parentElement
                    return this.local_set_parent_focus(childParent)
                }

                const currentChildParent = this.currentChild.parentElement
                this.local_set_parent_focus(currentChildParent)
                this.local_set_child_focus(null)
                return true
            }

            if (this.parentChildrenCount === 0) {
                const prevParent = this.currentParent.previousElementSibling
                if (!prevParent) return true

                this.parentChildrenCount = parseInt(prevParent.dataset.childCount)
                if (!this.parentChildrenCount) return this.local_set_parent_focus(prevParent)

                const parentLastChild = Array.from(prevParent.getElementsByClassName('c-focusable-child')).slice(-1)[0]
                this.local_set_parent_focus(prevParent, 'noFocus')
                this.local_set_child_focus(parentLastChild)
                return true
            }
        },

        local_on_enter (type = null) {
            if (this.currentEditId && !type) return this.currentEditId = null
            this.currentEditId = this.focusedElementId
            if (this.prevNavElement !== 'child') {
                const currentParent = this.currentParent
                if (currentParent) {
                    setTimeout(() => {
                        const input = currentParent.querySelector('.c-focusable-parent-input')
                        input.focus()
                    }, 200)
                }
                return true
            }

            const currentChild = this.currentChild
            if (currentChild) {
                setTimeout(() => {
                    const input = currentChild.querySelector('.c-focusable-child-input')
                    input.focus()
                }, 200)
            }
        },

        local_on_escape () {
            this.currentEditId = null
            this.focusedElementId = null
        },

        local_focus_usecase_create () {
            this.$refs.refUsecaseCreateInput.focus()
        },

        local_set_child_focus (child, noFocus) {
            this.currentChild = child
            this.currentChildId = (child && child.id) ?? null
            if (child && !noFocus) {
                this.prevNavElement = 'child'
                this.focusedElementId = this.currentChildId
                // this.currentEditId = this.focusedElementId
                setTimeout(() => {
                    const viewPointElement = document.getElementById('view-point-' + child.id)
                    if (viewPointElement) viewPointElement.scrollIntoView({ behavior: 'smooth' })
                }, 200)
            }
        },

        local_set_parent_focus (parent, noFocus) {
            this.currentParent = parent
            this.currentParentId = parent.id ?? null

            if (!this.expanded_ids.includes(parent.id)) this.local_toggle_expand({ id: parent.id, is_expanded: false })
            if (parent && !noFocus) {
                this.prevNavElement = 'parent'
                this.focusedElementId = this.currentParentId
                // this.currentEditId = this.focusedElementId
                this.parentChildrenCount = parseInt(parent.dataset.childCount)
                setTimeout(() => {
                    const viewPointElement = document.getElementById('view-point-' + parent.id)
                    if (viewPointElement) viewPointElement.scrollIntoView({ behavior: 'smooth' })
                }, 200)
            }
        },
        // Keyboard navigations --- End

        localCheckPlanStageUpdate (stageToMove) {
            const { is_admin } = this.user_self || {}
            const { projects_active_count } = this.mixinGetOrgPlan() || {}

            if (this.$plan('active_projects_limit') === -1) return stageToMove()
            if (projects_active_count < this.$plan('active_projects_limit')) return stageToMove()

            if (is_admin) this.dialog_admin_upgrade_modal = true
            else this.dialog_user_upgrade_modal = true
        },

        // Extras -----
        local_toggle_selected () {
            this.show_selected = !this.show_selected
        },

        local_get_uc_scs (usecase_id) {
            return this.local_filtered_scs.filter(item => item.usecase_id === usecase_id)
        },

        local_get_uc_tcs (usecase_id) {
            return this.local_filtered_tcs.filter(item => item.usecase_id === usecase_id)
        },

        async local_usecase_show_item ({ id, params, mode }) {
            await this.usecase_show_item({ mode, params, id })
        },

        async local_testcase_show_item (id, params, type) {
            await this.testcase_show_item({ id, params: { ...params }, type })
        },

        async local_success_criterion_show_item (id, params, type) {
            await this.success_criterion_show_item({ id, params: { ...params }, type })
        },

        local_get_sc_status_item (status_id) {
            return this.success_criterion_status_list.find(status => status.id === status_id)
        },

        local_get_tc_status_item (status_id) {
            return this.testcase_status_list.find(status => status.id === status_id)
        },

        local_add_status_comment ({ comment, item_id, module }) {
            this.local_update_tc_sc_item_comment({ comment, item_id, module })
            this.local_edit_pane_item.comments.push(comment)
            const commentsCount = this.local_edit_pane_item.comments_count ? this.local_edit_pane_item.comments_count + 1 : 1
            this.$set(this.local_edit_pane_item, 'comments_count', commentsCount)
            if (module === 'Testcase') {
                const index = this.testcase_list.findIndex(({ id }) => id === item_id)
                this.$set(this.testcase_list, index, this.local_edit_pane_item)
                return
            }
            const index = this.success_criterion_list.findIndex(({ id }) => id === item_id)
            this.$set(this.success_criterion_list, index, this.local_edit_pane_item)
        },

        local_update_tc_sc_item_comment ({ module, item_id }) {
            const newItem = (module === 'Testcase' ? this.testcase_list : this.success_criterion_list).find(data => data.id === item_id)
            this.$set(newItem, 'comments_count', newItem.comments_count ? newItem.comments_count + 1 : 1)
            Object.assign(this.selected_usecase_item, { aggregated_comments_count: this.selected_usecase_item.aggregated_comments_count ? this.selected_usecase_item.aggregated_comments_count + 1 : 1 })

            if (module === 'Testcase') return this.testcase_update_list(newItem)
            this.success_criterion_update_list(newItem)
            this.local_get_usecase_scs_count(this.selected_usecase_item.id)
            return true
        },

        async local_get_usecase_scs_count (id) {
            await this.local_usecase_show_item({
                id, mode: 'upsert-list-item',
                params: { 'include': 'successCriteriaCount,successCriteriaOpenCount,successCriteriaClosedCount,successCriteriaPassedCount,successCriteriaFailedCount,successCriteriaInvalidCount' }
            })
        },

        // Time tracker CRUD
        async local_time_record_index () {
            await this.time_record_index({
                ...this.time_record_params,
                'filter[resource_type]': 'Testcase',
                'filter[resource_id]': this.time_track_testcase.id,
                'sort': '-created_at'
            })
        },

        async local_open_time_track (testcase) {
            this.time_track_testcase = testcase
            this.dialog_est_list = true
            this.local_time_record_index()
        },

        local_close_time_track ({ resetFields }) {
            resetFields()
            this.time_record_clear()
            this.time_track_testcase = null
            this.dialog_est_list = false
            // this.$emit('close-timetrack-form')
        },

        async local_add_entry ({ formData: record, resetFields, mode, record_id: id }) {
            const params = { ...record, mode: 'create', resource_type: 'Testcase', resource_id: this.time_track_testcase.id }
            if (mode && mode === 'edit') await this.time_record_update({ ...record, mode, id })
            else await this.time_record_store({ ...params })
            this.local_clear_time_record(resetFields)
        },

        async local_delete_entry ({ id, resetFields }) {
            await this.time_record_destroy({ id })
            this.local_clear_time_record(resetFields)
        },

        async local_est_update (evt) {
            await this.testcase_update(evt)
        },

        async local_clear_time_record (resetFields) {
            if (this.time_record_response && this.time_record_response.status === 'success') {
                // await this.local_testcase_show(this.time_track_testcase.id, 'show')
                await this.local_testcase_show(this.time_track_testcase.id, 'show', {
                    'fields[testcases]': 'id,estimated_duration_minutes,usecase_id,estimated_duration_text',
                    'aggregate[time_records.duration_minutes]': 'sum',
                })
                // this.time_track_testcase = _.cloneDeep(this.testcase_item)
                this.time_track_testcase.time_records_sum_duration_minutes = this.testcase_item.time_records_sum_duration_minutes
                this.time_track_testcase.estimated_duration_minutes = this.testcase_item.estimated_duration_minutes
                this.time_track_testcase.estimated_duration_text = this.testcase_item.estimated_duration_text
                resetFields()
            }
        },

        async local_testcase_show (id, mode, params) {
            await this.testcase_show({ id, mode, params: params ?? this.local_tc_fields })
        },
        // Time Tracker ----- END

        local_set_loading (value) {
            this.loading_module = value
        },

        local_is_loading (type) {
            return this.loading_module === type
        },

        local_can (type) {
            return this.$can(`usecases.${type}`) && this.$can('projects.update-only')
        },

        check_permission (type) {
            return this.$can(`usecases.${type}`) && this.$can('projects.update-only')
        },

        async local_get_usecase (usecase_id, fetchScCounts = null) {
            let params = {
                project_id: this.local_project_id,
                include: 'assignees.collaborator.type',
                'fields[usecases]': 'id,title,description_json,visibility',
                'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                'filter[collaborators]': true
            }

            if (fetchScCounts) {
                params = {
                    'include': 'successCriteriaCount,successCriteriaOpenCount,successCriteriaClosedCount,successCriteriaPassedCount,successCriteriaFailedCount,successCriteriaInvalidCount',
                    'fields[usecases]': 'id',
                    project_id: this.local_project_id
                }
            }

            await this.usecase_show({ params, id: usecase_id })
            if (this.dialog_uc_detail_view) this.local_update_uc_detail_item(usecase_id)
        },

        local_update_uc_detail_item (usecase_id) {
            const usecase = this.usecase_list.find(item => item.id === usecase_id)
            if (usecase) Object.assign(this.usecase_detail_item, usecase)
        },

        local_update_usecase_list_item (usecase) {
            if (!usecase.title || (usecase.title && !usecase.title.trim())) return
            const usecase_item = this.usecase_list.find(item => item.id === usecase.id)
            if (usecase_item) Object.assign(usecase_item, usecase)
        },

        async local_title_inline_update (item, module, props) {
            let clonedItem = _.cloneDeep(item)
            Object.assign(clonedItem, { is_autosave: 1 })
            switch (module) {
                case 'uc': return this.local_uc_inline_update(item, clonedItem, props)
                case 'sc': return this.local_sc_inline_update(item, clonedItem, props)
                case 'tc': return this.local_tc_inline_update(item, clonedItem, props)
            }
        },

        async local_uc_inline_update (item, clonedItem, props) {
            const { clear = false, escape = true } = props || {}
            const originalItem = this.original_uc_list.find(item => item.id === clonedItem.id)
            if (clear) return this.local_reset_inline_title(item, originalItem)

            if (item.title) await this.usecase_update(clonedItem)
            // else await this.local_usecase_index({ onlyUcIndex: true })

            if (!this.$status(this.usecase_response)) return this.local_inline_edit_throw_error(item, originalItem, clonedItem, 'uc')
            this.title_errors.uc_title = false
            this.original_uc_list = _.cloneDeep(this.usecase_list)
            if (escape) this.local_on_escape()
        },

        async local_sc_inline_update (item, clonedItem, props) {
            const { clear = false, escape = true } = props || {}
            const originalItem = this.original_sc_list.find(item => item.id === clonedItem.id)
            if (clear) return this.local_reset_inline_title(item, originalItem)

            if (item.title) await this.success_criterion_update(clonedItem)
            else await this.local_sc_index()

            if (!this.$status(this.success_criterion_response)) return this.local_inline_edit_throw_error(item, originalItem, clonedItem, 'sc')
            this.title_errors.sc_title = false
            this.original_sc_list = _.cloneDeep(this.success_criterion_list)
            if (escape) this.local_on_escape()
        },

        async local_tc_inline_update (item, clonedItem, props) {
            const { clear = false, escape = true } = props || {}
            const originalItem = this.original_tc_list.find(item => item.id === clonedItem.id)
            if (clear) return this.local_reset_inline_title(item, originalItem)

            if (item.title) await this.testcase_update(clonedItem)
            else await this.local_tc_index()

            if (!this.$status(this.testcase_response)) return this.local_inline_edit_throw_error(item, originalItem, clonedItem, 'tc')
            this.title_errors.tc_title = false
            this.original_tc_list = _.cloneDeep(this.testcase_list)
            if (escape) this.local_on_escape()
        },

        local_inline_edit_throw_error (item, originalItem, clonedItem, module) {
            const titleEmpty = !clonedItem.title || (clonedItem.title && !clonedItem.title.trim())
            if (titleEmpty) this.local_reset_inline_title(item, originalItem)
            this.title_errors[module + '_title'] = !titleEmpty
            this.$notify('error', titleEmpty ? this.error_msgs.required_msg : this.error_msgs.max_length_exceed)
        },

        local_reset_inline_title (item, originalItem) {
            if (!originalItem) return
            Object.assign(item, { title: originalItem.title })
            this.title_errors = { uc_title: false, sc_title: false, tc_title: false }
            this.local_on_escape()
        },

        localSetLoadingStatus (e) {
            this.localAttachmentLoading = e
        },
        // Extras ----- END

        ...mapActions('Usecase', {
            usecase_index: 'index',
            usecase_show: 'show',
            usecase_show_item: 'show_item',
            usecase_store: 'store',
            usecase_bulk_store: 'bulk_store',
            usecase_uc_upsert: 'uc_upsert',
            usecase_uc_bulk_upsert: 'bulk_upsert',
            usecase_update: 'update',
            usecase_visibility: 'visibility',
            usecase_reorder: 'reorder',
            usecase_template_import: 'template_import',
            usecase_bulk_delete: 'bulk_delete',
            usecase_remove_selected: 'remove_selected',
            usecase_destroy: 'destroy',
            usecase_delete_all: 'delete_all',
            usecase_delete_selected: 'delete_selected',
            usecase_clear: 'clear',
            usecase_clear_item: 'clear_item',
        }),

        ...mapActions('SuccessCriterion', {
            success_criterion_update_list: 'update_list',
            success_criterion_update: 'update',
            success_criterion_bulk_status_update: 'bulk_status_update',
            success_criterion_index: 'global_index',
            success_criterion_sc_upsert: 'sc_upsert',
            success_criterion_sc_bulk_upsert: 'bulk_upsert',
            success_criterion_bulk_store: 'bulk_store',
            success_criterion_show: 'show',
            success_criterion_show_item: 'show_item',
            success_criterion_bulk_delete: 'bulk_delete',
            success_criterion_destroy: 'destroy',
            success_criterion_delete_selected: 'delete_selected',
            success_criterion_clear_item: 'clear_item',
            success_criterion_clear: 'clear',
        }),

        ...mapActions('Testcase', {
            testcase_update_list: 'update_list',
            testcase_update: 'update',
            testcase_tc_upsert: 'tc_upsert',
            testcase_bulk_status_update: 'bulk_status_update',
            testcase_index: 'global_index',
            testcase_show: 'show',
            testcase_show_item: 'show_item',
            testcase_tc_bulk_upsert: 'bulk_upsert',
            testcase_bulk_store: 'bulk_store',
            testcase_bulk_delete: 'bulk_delete',
            testcase_destroy: 'destroy',
            testcase_delete_selected: 'delete_selected',
            testcase_clear_item: 'clear_item',
            testcase_clear: 'clear',
        }),

        ...mapActions('Collaborator', {
            collaborator_index: 'index',
        }),

        ...mapActions('TimeRecord', {
            time_record_index: 'index',
            time_record_store: 'store',
            time_record_update: 'update',
            time_record_destroy: 'destroy',
            time_record_clear: 'clear',
        }),

        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_clear: 'clear',
        }),

        ...mapActions('TemplateUsecase', {
            template_usecase_clear_item: 'clear_item',
        }),

        ...mapActions('Assignee', {
            assignee_store: 'store',
            assignee_bulk_assign: 'bulk_assign',
            assignee_bulk_remove: 'bulk_remove',
            assignee_destroy: 'destroy',
        }),

        ...mapActions('Comment', {
            comment_bulk_store: 'bulk_store',
            comment_clear_item: 'clear_item',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
        }),

        ...mapActions('Association', {
            association_store: 'store',
            association_bulk_store_scope: 'bulk_store_scope',
            association_bulk_destroy: 'bulk_delete_scope',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .sortable-ghost {
        opacity: 0.8;
        box-shadow: 0px 2px 6px 4px rgb(173, 173, 173);
        border: 1px dashed rgb(116, 116, 116);
        background: #d2f1ff;
    }
    .sortable-chosen {
        .c-expand { display: none; }
        opacity: 1 !important;
        box-shadow: 0px 2px 6px 4px rgb(173, 173, 173);
        border: none !important;
        border: 1px dashed rgb(116, 116, 116);
        background: #f5f5f5 !important;
    }
</style>

<template>
    <v-stepper v-model="showModel" v-on="$listeners" v-bind="$attrs">
        <slot></slot>
    </v-stepper>
</template>

<script>
import { VStepper } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VStepper },
    props: ['value'],

    data () {
        return {
            ref: null
        }
    },

    mounted () {
        this.ref = this.$el
    },

    computed: {
        showModel: {
            get () {
                return this.value
            },
            set (val) {
                this.$emit('change', val)
            }
        }
    }
}
</script>

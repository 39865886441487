<template>
    <a-card class="c-territory-filter u-rounded-corners u-overflow-hidden u-elevation-custom-1 d-inline-flex align-center grey lighten-3" max-width="430" min-width="430">
        <a-autocomplete
            v-model="selected"
            :items="territoryList"
            ref="filter_dropdown"
            item-text="label"
            item-value="id"
            :disabled="loading"
            class="pa-0"
            return-object
            :search-input.sync="search"
            @input="local_selected_territory(selected)"
            solo text hide-selected multiple hide-details small-chips
        >
            <template v-slot:selection="{ item, selected }">
                <a-chip :input-value="selected" small :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}" class="chip--select-multi mx-1 mb-0" style="margin-top: 2px !important">
                    <div class="d-inline-flex align-center">
                        <a-icon size="16" :color="$color(item.color, 'color_text')" class="mr-1">fiber_manual_record</a-icon>
                        <span class="md-body-2" :style="{ color: $color(item.color, 'color_text') }">{{ item.label }}</span>
                        <a-icon size="18" v-if="!(selected.length === 1 && selected[0].value === 'all')" :color="$color(item.color, 'color_text')" class="u-cursor-pointer ml-1" @click="$emit('clear-territory', selected, item)">cancel</a-icon>
                    </div>
                </a-chip>
            </template>
            <template v-slot:item="{ item }">
                <div>
                    <a-chip small :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}" class="chip--select-multi mx-1 mb-0">
                        <div class="d-inline-flex align-center">
                            <a-icon size="16" :color="$color(item.color, 'color_text')" class="mr-1">fiber_manual_record</a-icon>
                            <span class="md-body-2" :style="{ color: $color(item.color, 'color_text') }">{{ item.label }}</span>
                        </div>
                    </a-chip>
                </div>
            </template>
        </a-autocomplete>
    </a-card>
</template>

<script>
export default {
    props: {
        selectedTerritory: {
            type: String | Array | Object,
        },
        territoryList: {
            type: Array,
        },
        loading: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            search: '',
            selected: this.selectedTerritory
        }
    },

    methods: {
        local_selected_territory (value) {
            this.$emit('updated', { val: value, ref: this.$refs.filter_dropdown.ref })
        }
    }
}
</script>

<style>

</style>

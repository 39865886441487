<template>
	<a-main>
        <a-container grid-list-xl container--fluid class="pa-0 mx-0 pb-16 mb-16" tabindex="0">
            <g-page-header
                title="Settings"
                subtitle="Manage your application settings."
                :loading="loading"
                icon="settings"
            ></g-page-header>
            <a-layout wrap align-start>
                <a-flex xs12>
                    <a-card flat :to="{ name: 'settings-sso' }" ripple class="u-border" v-if="user_self.is_admin === 1">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">vpn_key</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Single Sign-on <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage and configure your SSO Identity Provider.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <div class="u-flex-center-y">
                                        <a-btn @click.prevent="local_upgrade_plan('sso')" v-if="$plan('sso:no-access')" color="yellow darken-3" height="32" class="elevation-0 px-3 font-weight-medium" dark small>
                                            <a-icon class="mr-1" size="16">flash_on</a-icon> Upgrade
                                        </a-btn>
                                        <a-icon size="24" v-else>chevron_right</a-icon>
                                    </div>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                     <a-card flat :to="{name: 'settings-roles'}" ripple class="u-border mt-4" v-if="user_self.is_admin === 1">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">verified_user</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Roles <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage roles under your organization.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat :to="{name: 'settings-workspace'}" ripple class="u-border mt-4" v-if="$can('workspaces.index')">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">apartment</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Workspace <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage organization workspaces.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat :to="{name: 'settings-organization'}" ripple class="u-border mt-4" v-if="user_self.is_admin === 1">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">supervised_user_circle</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Organization <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage your organization profile.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat :to="{name: 'settings-confidence-score'}" ripple class="u-border mt-4" v-if="localCheckConfidenceScoreVisibility && $can('confidence_score.index')">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">scoreboard</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Confidence Score <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Create and manage fields of confidence score for your projects.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <template v-if="user_self && user_self.organization && user_self.organization.flag_automation === 1 && user_self.is_admin === 1">
                        <a-card flat :to="{name: 'settings-validations'}" ripple class="u-border mt-4">
                            <a-card-text class="pa-6 mb-1">
                                <a-layout align-center>
                                    <a-flex shrink>
                                        <a-icon size="24" color="primary">fact_check</a-icon>
                                    </a-flex>
                                    <a-flex>
                                        <h2 class="md-heading-6 font-weight-normal primary--text">Validation
                                            <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage and configure validation workflows.</span>
                                        </h2>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon size="24">chevron_right</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-card-text>
                        </a-card>
                        <a-card flat :to="{name: 'settings-automations'}" ripple class="u-border mt-4">
                            <a-card-text class="pa-6 mb-1">
                                <a-layout align-center>
                                    <a-flex shrink>
                                        <a-icon size="24" color="primary">$automation</a-icon>
                                    </a-flex>
                                    <a-flex>
                                        <h2 class="md-heading-6 font-weight-normal primary--text">Automation
                                            <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage and configure automation workflows.</span>
                                        </h2>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon size="24">chevron_right</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-card-text>
                        </a-card>
                    </template>
                    <a-card flat :to="{name: 'settings-preferences'}" ripple class="u-border mt-4" v-if="user_self.is_admin === 1">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">touch_app</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Preferences <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage your organization preferences.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat :to="{name: 'settings-notification-preferences'}" ripple class="u-border mt-4" v-if="user_self && user_self.is_admin === 1">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary" outlined>notifications_active</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Notification Preferences<span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage your organization notifications preferences.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat :to="{name: 'settings-document-generator'}" ripple class="u-border mt-4">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">source</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Document Generator <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Create and manage document templates.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat :to="{name: 'master-templates-system-list-usecases'}" ripple class="u-border mt-4" v-if="$can('templates.show')">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">library_books</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Templates <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage system and master templates.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat :to="{name: 'settings-tags-list', params: { id: 'label' }}" ripple class="u-border mt-4" v-if="user_self.role && user_self.role.scope === 'internal'">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">label</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Tags <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Customize tags associated with projects.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat :to="{name: 'settings-metas-testcases'}" ripple class="u-border mt-4" v-if="$can('metas.index')">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">list_alt</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Pick List <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Customize labels associated with test case, success criteria, comment and other resources.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat v-if="localCheckInventoryVisibility" :to="localHasOrgFlagEnabled ? { name: 'settings-metas-inventory-categories' } : ''" :ripple="false" class="u-border mt-4">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">memory</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">
                                        Inventory
                                        <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0" v-if="!localHasOrgFlagEnabled">Enable Inventory Module and customize the workflow</span>
                                        <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0" v-else>Customize categories and tags associated with inventory.</span>
                                    </h2>
                                </a-flex>
                                <a-flex shrink>
                                    <div class="u-flex-center-y">
                                        <a-btn href="mailto:support@success.app" v-if="!localHasOrgFlagEnabled" color="secondary" height="32" class="elevation-0 px-3 font-weight-medium" dark small>
                                            <a-icon class="mr-1" size="18">outgoing_mail</a-icon>
                                            <span class="md-body-2 white--text font-weight-medium">Request for Access</span>
                                        </a-btn>
                                        <a-icon size="24" v-else>chevron_right</a-icon>
                                    </div>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat :to="{name: 'settings-competitors'}" v-test-link.competitors ripple class="u-border mt-4" v-if="user_self.role && user_self.role.scope === 'internal'">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">domain</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Competitors <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage competitors to include in project.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                     <a-card flat :to="{name: 'territories'}" ripple class="u-border mt-4" v-if="user_self.role && user_self.role.scope === 'internal'">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">terrain</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Territories <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage territories to group users and project for reporting.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat :to="{name: 'customers'}" v-test-link.customers ripple class="u-border mt-4" v-if="user_self.role && user_self.role.scope === 'internal'">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">account_box</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Accounts <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage accounts and contacts to include in project.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat :to="{name: 'partners'}" ripple class="u-border mt-4" v-if="user_self.role && user_self.role.scope === 'internal'">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">contacts</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Partners <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage partners to include in project.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat :to="{name: 'settings-custom-fields-list', params: { id: 'project' }}" ripple class="u-border mt-4" v-if="user_self.role && user_self.role.scope === 'internal'">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">ballot</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Custom Fields <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage custom fields under your organization.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <!-- Layout Designer-->
                    <a-card flat :to="{name: 'layout-designer'}" ripple class="u-border mt-4" v-if="user_self.role && user_self.role.scope === 'internal' && $can('layout_designer.index')">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">space_dashboard</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Layout Designer <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Build sections for custom fields & tags.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <!-- user_self.organization && user_self.organization.stripe_customer_id -->
                    <a-card flat :to="{ name: 'settings-billing' }" ripple class="u-border mt-4" v-if="user_self.is_billing === 1">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">receipt</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Billing <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Setup and manage your billing account.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat :to="{ name: 'settings-tokens' }" ripple class="u-border mt-4">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">code</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Developer API <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Manage tokens to use with Success API and read documentation.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat :to="{ name: 'settings-authentication-log' }" ripple class="u-border mt-4" v-if="user_self.is_admin === 1">
                        <a-card-text class="pa-6 mb-1">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">summarize</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-normal primary--text">Authentication Logs <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">View all current and expired sessions of the user accounts.</span></h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-icon size="24">chevron_right</a-icon>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- User Upgrade Popup -->
        <a-dialog max-width="400" v-model="dialog_user_upgrade_modal">
            <SUserUpgradeModal @close="dialog_user_upgrade_modal = false" />
        </a-dialog>

        <!-- Admin Upgrade Popup -->
        <a-dialog max-width="600" v-model="dialog_admin_upgrade_modal">
            <SAdminUpgradeModal
                :isOpen="dialog_admin_upgrade_modal"
                @upgrade="{}"
                @close="dialog_admin_upgrade_modal = false"
            />
        </a-dialog>
	</a-main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { SUserUpgradeModal, SAdminUpgradeModal } from '@/config/config-shared-components'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'
import mixinGetPreference from '@/mixins/mixin-get-preference'

export default {
    mixins: [mixinGetOrganizationPlan, mixinGetPreference],

    components: {
        SUserUpgradeModal,
        SAdminUpgradeModal
    },

    data () {
        return {
            upgrade_module: null,
            dialog_stage_form: false,
            dialog_user_upgrade_modal: false,
            dialog_admin_upgrade_modal: false,
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        localCheckInventoryVisibility () {
            const level = this.mixinGetOrgPlan({ field: 'subscription_plan.level' })
            if (level === 1) return false
            if (level === 2) return this.localHasOrgFlagEnabled || this.mixinGetPreference('organization.inventory_view') === 'Yes'
            if (level === 3) return this.localHasOrgFlagEnabled

            return false
        },

        localCheckConfidenceScoreVisibility () {
            return this.localHasOrgConfidenceScoreFlagEnabled && this.mixinGetPreference('organization.confidence_score') === 'Yes'
        },

        localHasOrgFlagEnabled () {
            const { organization } = this.user_self || {}
            return (organization && organization.flag_inventory === 1)
        },

        localHasOrgConfidenceScoreFlagEnabled () {
            const { organization } = this.user_self || {}
            return (organization && organization.flag_confidence_score === 1)
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index () {
            if (this.user_self && this.user_self.scope === 'external') {
                this.$router.replace({name: 'dashboard'})
            }
            await this.preference_index()
        },

        local_upgrade_plan (module) {
            const { is_admin } = this.user_self || {}

            this.dialog_admin_upgrade_modal = false
            this.dialog_user_upgrade_modal = false
            this.upgrade_module = module

            if (is_admin === 1) this.dialog_admin_upgrade_modal = true
            else this.dialog_user_upgrade_modal = true
        },

        ...mapActions('Preference', {
            preference_index: 'index',
        })
    },
}
</script>

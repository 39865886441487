<template>
    <div class="md-subtitle-1 font-weight-medium grey--text text--darken-2 d-inline-flex align-center" style="height: 48px;">
        <template v-if="!localRouteIsProject">
            <template v-for="(routeName, index) in routesList">
                <router-link
                    :key="index"
                    class="grey--text text--darken-1"
                    :to="{ name: routeName.name, params: routeName.params ? routeName.params : {} }"
                >
                   <span v-if="index !== 0" class="md-body-1 grey--text mx-2">/</span>
                   <span>{{ routeName.label }}</span>
                </router-link>
            </template>
        </template>

        <template v-if="localRouteIsProject && project_item">
            <router-link class="grey--text text--darken-1" :to="{ name: 'dashboard-columns' }">Dashboard</router-link>
            <a-icon class="mx-2" size="18" color="grey darken-2">chevron_right</a-icon>
            <router-link class="grey--text text--darken-1" :to="{ name: 'projects-overview' }">{{ project_item.title | truncateText(25)  }}</router-link>
            <template v-if="subRoute">
                <a-icon class="mx-2" size="18" color="grey darken-2">chevron_right</a-icon>
                <router-link class="grey--text text--darken-1" :to="{ name: subRoute.parentSlug }">{{ subRoute.parentName  }}</router-link>
                <a-icon class="mx-2" size="18" color="grey darken-2">chevron_right</a-icon>
                <span>{{ subRoute.title | truncateText(20) }}</span>
            </template>
            <template v-else>
                <a-icon class="mx-2" size="18" color="grey darken-2">chevron_right</a-icon>
                <span class="grey--text text--darken-1">{{ localCurrentProjectRoute }}</span>
            </template>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data () {
        return {
            subRoute: null,
            routesList: []
        }
    },

    watch: {
        '$route': {
            handler (to, from) {
                this.routesList = []
                this.localCurrentRoute()
                this.subRoute = null
                const subRoutes = ['projects-tickets-view', 'projects-notes-view', 'projects-usecases-template-import']
                const routeName = to.name
                if (!subRoutes.includes(routeName)) return
                this.subRoute = { parentSlug: from.name, parentName: from.meta.label, title: to.meta.label }
            },
            immediate: true
        },
    },

    computed: {
        localRouteIsProject () {
            return this.$route?.meta?.parent === 'projects'
        },

        localCurrentProjectRoute () {
            return this.$route.meta.label
        },

         ...mapState('ProjectView', {
            project_item: 'item',
        }),
    },

    methods: {
        localCurrentRoute () {
            if (this.$route.name === 'settings-automation-validations-logs') {
                return this.localDynamicAutoValidationRoute(this.$route)
            }

            const routeName = this.localReadableRouteName(this.$route)
            this.routesList.push({ ...this.$route, label: routeName })

            const list = this.localGetRoutes(this.$route)
            // if (_.size(list) === 1 && list[0].name === this.$route.name) return list

            this.routesList = list
        },

        localDynamicAutoValidationRoute (route) {
            const type = route.params.type
            const parentRoute = this.$router.matcher.getRoutes().find(i => i.name === `settings-${type}s`)

            this.routesList.push({ ...parentRoute, label: _.get(parentRoute, 'meta.label') })
            this.routesList.push({
                ...route,
                label: _.get(route, 'meta.label'),
                meta: {
                    label: _.get(route, 'meta.label'),
                    parent: { label: _.get(parentRoute, 'meta.label'), name: parentRoute.name }
                }
            })

            if (_.get(parentRoute, 'meta.parent.name')) this.localGetRoutes(parentRoute)
        },

        localGetRoutes (route) {
            if (!_.get(route, 'meta.parent.name')) {
                return this.routesList
            }

            const matched = this.$router.matcher.getRoutes().find(i => i.name === _.get(route, 'meta.parent.name'))
            this.routesList.unshift({ ...matched, label: _.get(matched, 'meta.label') || this.localReadableRouteName(matched) })

            if (_.get(matched, 'meta.parent.name')) this.localGetRoutes(matched)
            return this.routesList
        },

        localReadableRouteName (matched) {
            if (this.$route?.meta?.breadcrumbLabel) return this.$route?.meta?.breadcrumbLabel
            if (this.$route?.meta?.label) return this.$route?.meta?.label

            return matched.name.split('-').map(i => i.charAt(0).toUpperCase() + i.slice(1)).join(' ')
        },
    }
}
</script>

<template>
	<div class="c-radial-bar-chart">
		<div class="c-radial-bar-chart__column">
			<VueApexCharts
				type="radialBar"
				:options="chartOptions"
				:series="series"
				width="100%"
				height="100%"
			></VueApexCharts>
		</div>
	</div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	components: { VueApexCharts },
	props: {
		title: String,
		description: String,
	},
	props: {
		isRearrange: {
			type: Boolean,
			default: false
		},
	},
	data: function () {
		return {
			series: [70],
			chartOptions: {
				chart: {
					type: "radialBar",
					toolbar: {
						show: false,
					}
				},
				grid: {
					padding: {
						bottom: 0,
						right: 0,
						top: 0,
						left: 0,
					}
				},
				legend: {
					show: false,
				},
				selection: {
					enabled: false,
				},
				plotOptions: {
					radialBar: {
						hollow: {
							color: '#000',
							size: "70%",
						},
						dataLabels: {
							showOn: 'always',
							name: {
								color: "#9aa1a9",
								fontSize: "14px",
								fontFamily: "Inter, sans-serif",
								offsetY: -12,
							},
							value: {
								fontSize: "20px",
								fontFamily: "Inter, sans-serif",
								color: "#505d6f",
								offsetY: 2,
							}
						}
					},
				},
				labels: ["Progress"],
				stroke: {
					lineCap: "round",
				},
				fill: {
					type: 'solid',
					colors: ['#9C27B0']
				},
			},
		};
	},
	mounted() {
		this.local_index();
	},
	methods: {
		local_index () {
			this.local_disable_animation();
		},
		local_disable_animation() {
			if(this.isRearrange) {
				this.$refs.radialChart.updateOptions({
					chart: {
						animations: {
							enabled: false,
						}
					}
				})
			}
		},
	},
};
</script>

<style scoped>
.c-radial-bar-chart {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 227px;
}

.c-radial-bar-chart__column {
	width: 236px;
	height: 236px;
}
</style>
<template>
    <a-layout align-start>
        <a-flex>
            <a-tabs class="mb-0" color="neutral" slider-color="primary">
                <a-tab ripple class="mx-2" :to="{name: 'admin-users-enabled'}" exact>
                    <span class="md-body-2">Active Users</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'admin-users-disabled'}" exact>
                    <span class="md-body-2">Blocked Users</span>
                </a-tab>
            </a-tabs>
        </a-flex>
    </a-layout>
</template>

<script>
export default {

}
</script>

<template>
    <draggable
        v-model="localList"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot></slot>
    </draggable>
</template>

<script>
export default {
    props: {
        list: {
            type: Array
        }
    },

    computed: {
        localList: {
            get () {
                return _.cloneDeep(this.list)
            },
            set (value) {
                console.log('HELOOO: ', value)
                // this.$emit('reorder', value)
            }
        }
    }
}
</script>

<template>
    <g-project-page parentClass="mb-16">
        <div
            class="u-relative pa-10"
            @dragenter="!localAttachmentsUpdating ? localHandleDragEnter() : ''"
            @drop="localHandleDrop"
        >
            <SDragOverlay
                v-if="filesDraggedOver"
                pageType="external"
                @leave="localHandleLeave()"
                @enter="hoveredOnInfo=false"
                @enterInfo="hoveredOnInfo=true"
            />
            <div class="u-flex-center-y d-inline-flex u-cursor-pointer mb-4" @click="$router.push({ name: 'external-projects-usecases' })">
                <a-icon size="16" color="grey darken-1">arrow_back</a-icon>
                <span class="md-body-2 ml-2 grey--text text--darken-1 font-weight-medium">Back to Scope</span>
            </div>

            <!-- Usecase section -->
            <a-layout>
                <a-flex xs12>
                    <loader-usecase-view class="mb-4" v-if="local_loading"></loader-usecase-view>
                    <template v-else>
                        <s-usecase-view
                            :item="usecase_item"
                            :can-update="permission_usecase('update') && !localAttachmentsUpdating"
                            :can-delete="permission_usecase('destroy')"
                            :loading="localAttachmentsUpdating"
                            :response="usecase_response"
                            :attachmentLoading="localAttachmentsUpdating"
                            @update="usecase_update"
                            @delete="local_destroy"
                            @updateTitle="usecase_update"
                            @updateUsecase="usecase_update"
                        >
                            <template #assignee>
                                <div class="mr-6 u-flex-center-y">
                                    <s-assignee-dropdown
                                        :item="usecase_item"
                                        :users-list="local_member_list(usecase_item.assignees)"
                                        :project-id="$route.params.id"
                                        :can-update="permission_usecase('update') && !localAttachmentsUpdating"
                                        @menuOpen="mixinGetCollabs"
                                        module-type="Usecase"
                                        class="u-flex align-center justify-end"
                                    ></s-assignee-dropdown>
                                </div>
                            </template>
                            <template #attachment-section>
                                <SAttachmentSection
                                    :list="dialog_detail_view ? localUsecaseAttachmentList : attachment_local_list"
                                    :customMetaItem="localUsecaseAttachmentMeta"
                                    :dropTransferList="localDroppedFilesList"
                                    :sourceId="usecase_item.id"
                                    :updateLoading="localAttachmentsUpdating"
                                    :loading="mixinIsLoading('attachment')"
                                    :canUpdate="permission_usecase('update')"
                                    sourceType="Usecase"
                                    @dropTransfer="localUpdateAttachmentTransfer"
                                    @loadMore="localLoadMoreAttachments()"
                                    @remove="localRemoveAttachmentItem"
                                    @delete="localDeleteAttachmentItem"
                                    autoUpdate
                                    isExternal
                                >
                                </SAttachmentSection>
                            </template>
                        </s-usecase-view>
                    </template>
                </a-flex>
            </a-layout>

            <a-divider class="my-10"></a-divider>
            <!-- Success Criteria section -->
            <a-layout>
                <a-flex xs12 py-0>
                    <div class="u-flex align-center">
                        <div class="d-inline-flex align-center">
                            <h2 class="secondary--text text-h6">Success Criteria</h2>
                            <span class="u-rounded-corners-full grey darken-2 white--text font-weight-medium md-caption ml-2 u-flex-center" style="width: 22px; height: 22px;">{{ local_scs_length }}</span>
                        </div>
                    </div>

                    <div class="mt-6" v-if="local_loading">
                        <a-sheet class="mb-4 px-6 py-8 u-shadow u-rounded-corners" v-for="i in 3" :key="i">
                            <div class="skeleton skeleton--text"></div>
                            <div class="u-flex-center-y">
                                <a-sheet width="120" class="skeleton mr-2 skeleton--text"></a-sheet>
                                <a-sheet width="240" class="skeleton mr-2 skeleton--text"></a-sheet>
                                <a-sheet width="200" class="skeleton mr-2 skeleton--text"></a-sheet>
                                <a-sheet width="120" class="skeleton mr-2"></a-sheet>
                            </div>
                        </a-sheet>
                    </div>

                    <div v-else>
                        <draggable v-model="success_criterion_list" :options="{handle: '.drag_handle_icon'}">
                            <template v-for="(success_criterion) in success_criterion_list">
                                <s-sc-tc-card
                                    @click="local_show_edit_pane(success_criterion, 'SuccessCriterion')"
                                    :item="success_criterion"
                                    :can-update="permission_usecase('update') && !localAttachmentsUpdating"
                                    handle="drag_handle_icon"
                                    :class="['mt-6 u-cursor-pointer']"
                                    :is-loading="local_loading"
                                    :key="success_criterion.id"
                                    module="SuccessCriterion"
                                >
                                    <template #status>
                                        <GNonActiveInfo :disabled="!permission_usecase('update') || (permission_usecase('update') && project_item && project_item.status === 'active')">
                                            <s-status-menu
                                                :can-update="permission_usecase('update') && (project_item && project_item.status === 'active') && !localAttachmentsUpdating"
                                                :parent-list="success_criterion_list"
                                                :status-list="success_criterion_status_list"
                                                :item="success_criterion"
                                                :statusItem="success_criterion.status"
                                                @statusCommentStore="local_add_status_comment(true, $event)"
                                                module="SuccessCriterion"
                                                @click.stop="{}"
                                                class="mr-6"
                                                outline
                                            ></s-status-menu>
                                        </GNonActiveInfo>
                                    </template>
                                    <template #assignee>
                                        <div class="u-flex-center-y mr-6">
                                            <s-assignee-dropdown
                                                :item="success_criterion"
                                                :users-list="local_member_list(success_criterion.assignees)"
                                                :project-id="$route.params.id"
                                                :can-update="permission_usecase('update') && !localAttachmentsUpdating"
                                                @menuOpen="mixinGetCollabs"
                                                module-type="SuccessCriterion"
                                                :size="26"
                                                class="u-flex align-center justify-end"
                                                is-external hide-empty-text
                                            ></s-assignee-dropdown>
                                        </div>
                                    </template>
                                </s-sc-tc-card>
                            </template>
                        </draggable>
                    </div>

                    <template v-if="permission_usecase('update')">
                        <a-text-field
                            v-model="success_criterion_title"
                            placeholder="Add new Success Criteria"
                            :class="['u-rounded-corners mt-6 u-shadow']"
                            solo flat hide-details
                            :disabled="local_is_loading('sc_store') || localAttachmentsUpdating"
                            v-if="!local_loading"
                            @keydown.enter="local_success_criterion_store"
                        >
                            <template #prepend-inner>
                                <a-progress-circular width="3" size="20" class="mr-1" indeterminate color="orange darken-2" v-if="local_is_loading('sc_store')"></a-progress-circular>
                                <a-icon size="24" color="body lighten-4" v-else>add</a-icon>
                            </template>
                            <template #append>
                                <span @click="local_is_loading('sc_store') ? '' : local_success_criterion_store()" v-ripple class="u-cursor-pointer d-block px-1 u-rounded-corners">
                                    <span class="md-caption text-uppercase body--text text--lighten-3 mr-2">Enter</span>
                                    <a-icon size="24" color="body lighten-4">keyboard_return</a-icon>
                                </span>
                            </template>
                        </a-text-field>
                        <g-field-info show-icon-on-error :error="$response(success_criterion_response, 'title')" :has-error="$response(success_criterion_response, 'title')"></g-field-info>
                    </template>
                </a-flex>
            </a-layout>

            <a-divider class="grey lighten-2 mt-10 mb-8"></a-divider>

            <!-- Testcase section -->
            <a-layout>
                <a-flex xs12 py-0>
                    <div class="u-flex align-center">
                        <div class="d-inline-flex align-center">
                            <h2 class="secondary--text text-h6">Test Cases</h2>
                            <span class="u-rounded-corners-full grey darken-2 white--text font-weight-medium md-caption ml-2 u-flex-center" style="width: 22px; height: 22px;">{{ local_tcs_length }}</span>
                        </div>
                    </div>

                    <div class="mt-6" v-if="local_loading">
                        <a-sheet class="mb-4 px-6 py-8 u-shadow u-rounded-corners" v-for="i in 3" :key="i">
                            <div class="skeleton skeleton--text"></div>
                            <div class="u-flex-center-y">
                                <a-sheet width="120" class="skeleton mr-2 skeleton--text"></a-sheet>
                                <a-sheet width="240" class="skeleton mr-2 skeleton--text"></a-sheet>
                                <a-sheet width="200" class="skeleton mr-2 skeleton--text"></a-sheet>
                                <a-sheet width="120" class="skeleton mr-2"></a-sheet>
                            </div>
                        </a-sheet>
                    </div>

                    <div v-else>
                        <draggable v-model="testcase_list" :options="{handle: '.drag_handle_icon'}">
                            <template v-for="(testcase) in testcase_list">
                                <s-sc-tc-card
                                    @click="local_show_edit_pane(testcase, 'Testcase')"
                                    :can-update="permission_usecase('update') && !localAttachmentsUpdating"
                                    :item="testcase"
                                    :class="[{ 'drag_handle_icon': permission_usecase('update') }, 'mt-6 u-cursor-pointer']"
                                    :key="testcase.id"
                                    module="Testcase"
                                    hide-time-track
                                >
                                    <template #status>
                                        <GNonActiveInfo :disabled="!permission_usecase('update') || (permission_usecase('update') && project_item && project_item.status === 'active') || localAttachmentsUpdating">
                                            <s-status-menu
                                                :can-update="permission_usecase('update') && (project_item && project_item.status === 'active') && !localAttachmentsUpdating"
                                                :parent-list="testcase_list"
                                                :status-list="testcase_status_list"
                                                :item="testcase"
                                                :statusItem="testcase.status"
                                                @statusCommentStore="local_add_status_comment(true, $event)"
                                                @click.stop="{}"
                                                module="Testcase"
                                                class="mr-6"
                                                outline
                                            ></s-status-menu>
                                        </GNonActiveInfo>
                                    </template>
                                    <template #assignee>
                                        <div class="u-flex-center-y mr-6">
                                            <s-assignee-dropdown
                                                :item="testcase"
                                                :users-list="local_member_list(testcase.assignees)"
                                                :project-id="$route.params.id"
                                                :can-update="permission_usecase('update') && !localAttachmentsUpdating"
                                                @menuOpen="mixinGetCollabs"
                                                module-type="Testcase"
                                                :size="26"
                                                class="u-flex align-center justify-end"
                                                is-external hide-empty-text
                                            ></s-assignee-dropdown>
                                        </div>
                                    </template>
                                </s-sc-tc-card>
                            </template>
                        </draggable>
                    </div>

                    <template v-if="permission_usecase('update')">
                        <a-text-field
                            v-model="testcase_title"
                            placeholder="Add new Test Case"
                            :class="['u-rounded-corners mt-6 u-shadow']"
                            solo flat hide-details
                            :disabled="local_is_loading('tc_store') || localAttachmentsUpdating"
                            v-if="!local_loading"
                            @keydown.enter="local_testcase_store"
                        >
                            <template #prepend-inner>
                                <a-progress-circular width="3" size="20" class="mr-1" indeterminate color="orange darken-2" v-if="local_is_loading('tc_store')"></a-progress-circular>
                                <a-icon size="24" color="body lighten-4" v-else>add</a-icon>
                            </template>
                            <template #append>
                                <span @click="local_is_loading('tc_store') ? '' : local_testcase_store()" v-ripple class="u-cursor-pointer d-block px-1 u-rounded-corners">
                                    <span class="md-caption text-uppercase body--text text--lighten-3 mr-2">Enter</span>
                                    <a-icon size="24" color="body lighten-4">keyboard_return</a-icon>
                                </span>
                            </template>
                        </a-text-field>
                        <g-field-info show-icon-on-error :error="$response(testcase_response, 'title')" :has-error="$response(testcase_response, 'title')"></g-field-info>
                    </template>
                </a-flex>
            </a-layout>

            <!-- Detail View -->
            <a-dialog v-model="dialog_detail_view" :width="$vuetify.breakpoint.lgAndDown ? 1040 : 1080" :persistent="localAttachmentLoading">
                <s-sc-tc-item-view
                    :edit-item="local_edit_pane_item"
                    :module="edit_pane_module"
                    :loaders="loaders"
                    :is-open="dialog_detail_view"
                    :parent-item="usecase_item"
                    :testcase-list="testcase_list"
                    :success-criteria-list="success_criterion_list"
                    :can-update="permission_usecase('update')"
                    :can-delete="permission_usecase('destroy')"
                    :can-comment="permission_mixin('usecases.comment', 'projects.show')"
                    @attachmentLoading="localSetLoadingStatus"
                    @close="local_clear_edit_pane_view"
                    @update="local_update_pane_item"
                    @destroy="local_destroy_pane_item"
                    hide-tags hide-time-track
                >
                    <template #status>
                        <GNonActiveInfo :disabled="!permission_usecase('update') || (permission_usecase('update') && project_item && project_item.status === 'active')">
                            <s-status-menu
                                :can-update="permission_usecase('update') && (project_item && project_item.status === 'active')"
                                :parent-list="local_pane_model_parent_list"
                                :status-list="local_pane_status_list"
                                :item="local_edit_pane_item"
                                :statusItem="local_edit_pane_item.status"
                                :module="edit_pane_module"
                                @statusCommentStore="local_add_status_comment(false, $event)"
                                input-class="py-1"
                                update-list-item
                            ></s-status-menu>
                        </GNonActiveInfo>
                    </template>
                    <template #assignee>
                        <s-assignee-dropdown
                            :item="local_edit_pane_item"
                            :users-list="local_member_list(local_edit_pane_item.assignees)"
                            :project-id="$route.params.id"
                            :can-update="permission_usecase('update')"
                            :size="32"
                            :module-type="edit_pane_module"
                            @menuOpen="mixinGetCollabs"
                            class="u-flex align-center justify-end mr-3"
                            is-external
                        ></s-assignee-dropdown>
                    </template>
                </s-sc-tc-item-view>
            </a-dialog>

            <!-- Dialog leave page -->
            <a-dialog v-model="dialogLeavePage" max-width="420">
                <div class="white pa-6 pb-4">
                    <div class="mb-3 text-center">
                        <div class="md-subtitle-1 font-weight-medium grey--text text--darken-4">
                            The attachments are currently being uploaded.
                        </div>
                        <div class="mt-2 md-body-2 grey--text text--darken-2">Please wait...</div>
                    </div>
                    <div class="text-center">
                        <LoaderDotAnimation customSize="10"></LoaderDotAnimation>
                    </div>
                </div>
            </a-dialog>
        </div>
    </g-project-page>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {
    SUserListPopup,
    SAssigneeForm,
    SUsecaseView,
    SAssigneeDropdown,
    SScTcCard,
    SStatusMenu,
    SScTcItemView,
    SAttachmentSection
} from '@/config/config-shared-components'
import ExternalPermissionMixin from '@/mixins/mixin-external-view-permission'
import mixinSearchCollaborators from '@/mixins/mixin-search-collaborators'
import SDragOverlay from '@/components/Attachments/SharedDragOverlay.vue'
import mixinAttachmentsExternal from '@/mixins/mixin-external-attachment'
import mixinLoader from '@/mixins/mixin-module-loading-setup'
import LoaderDotAnimation from '@/components/Loader/LoaderDotAnimation.vue'

export default {
    mixins: [ ExternalPermissionMixin, mixinSearchCollaborators, mixinLoader, mixinAttachmentsExternal ],

    components: {
        SUserListPopup,
        SAssigneeForm,
        SUsecaseView,
        SStatusMenu,
        SAssigneeDropdown,
        SScTcCard,
        SScTcItemView,
        SAttachmentSection,
        SDragOverlay,
        mixinAttachmentsExternal,
        mixinLoader,
        LoaderDotAnimation
    },

    data() {
        return {
            loaders: {
                sidebar_tags_loading: false,
                sidebar_comments_loading: false,
                content_autosave_response: false,
                sc_store: false,
                tc_store: false,
            },
            edit_pane_module: '',
            local_edit_pane_item: { tags: [], comments: [] },
            dialog_detail_view: false,
            testcase_title: '',
            success_criterion_title: '',
            testcase_status_list: [],
            success_criterion_status_list: [],
            local_collaborator_list: [],
            local_loading: true,
            local_usecase_fields: {
                'include': 'assignees,updated_by,created_by',
                'fields[usecases]': 'id,title,description_json,code,project_id,status,visibility,updated_at,created_at,updated_by_id,created_by_id',
                'fields[updated_by]': 'id,name',
                'fields[created_by]': 'id,name',
                'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
            },
            local_testcase_fields: {
                'filter[usecase_id]': this.$route.params.usecase_id,
                'include': 'assignees,status,commentsCount,updated_by,created_by,attachmentsCount',
                'fields[testcases]': 'id,usecase_id,title,description_json,code,status_id,start_date,due_date,updated_at,created_at,updated_by_id,created_by_id',
                'fields[status]': 'id,type,value,status',
            },
            local_success_criterion_fields: {
                'filter[usecase_id]': this.$route.params.usecase_id,
                'include': 'assignees,status,commentsCount,updated_by,created_by,attachmentsCount',
                'fields[status]': 'id,type,value,status',
                'fields[success_criteria]': 'id,usecase_id,title,code,status_id,description_json,updated_at,created_at,updated_by_id,created_by_id',
            },
            local_testcase_status_fields: { 'filter[type]': 'testcase_status', 'fields': 'id,type,value,status', 'sort': 'order' },
            local_success_criterion_status_fields: {'filter[type]': 'success_criterion_status', 'fields': 'id,type,value,status', 'sort': 'order' },

            //Attachments
            filesDraggedOver: false,
            hoveredOnInfo: false,
            FILTER: {
                parent_id: 'filter[parent_id]',
                parent_type: 'filter[parent_type]',
                source_id: 'filter[source_id]',
                source_type: 'filter[source_type]',
                fields: 'fields[attachments]',
                page: 'page'
            },
            attachment_fields: 'id,name,size,extension,order,source_id,source_type,parent_id,parent_type',
            localDroppedFilesList: [],
            localAttachmentFilters: {},
            backupAttachmentList: [],
            localAttachmentTimeout: null,
            dialogLeavePage: false,
            pageToLeave: null,
            localAttachmentLoading: false,
            localUsecaseAttachmentList: [],
            localUsecaseAttachmentMeta: {}
        }
    },

    watch: {
        dialog_detail_view (val) {
            if (!val) this.local_clear_edit_pane_view()
        },

        localAttachmentsUpdating (val) {
            if (!val) this.dialogLeavePage = false
        }
    },

    mounted() {
        this.local_index()
    },

    beforeRouteLeave (toRoute, fromRoute, nextRoute) {
        if (!this.localAttachmentsUpdating) return nextRoute()
        this.dialogLeavePage = true
        nextRoute(false)
        this.pageToLeave = nextRoute
    },

    computed: {
        testcase_list: {
            get() {
                return this.$store.state.TestcaseExternal.list
            },
            set(list) {
                this.local_testcase_reorder(list)
            }
        },

        success_criterion_list: {
            get() {
                return this.$store.state.SuccessCriterionExternal.list
            },
            set(list) {
                this.local_success_criterion_reorder(list)
            }
        },

        local_tcs_length () {
            return this.testcase_list && this.testcase_list.length
        },

        local_scs_length () {
            return this.success_criterion_list && this.success_criterion_list.length
        },

        local_pane_status_list () {
            if (this.edit_pane_module === 'Testcase') return this.testcase_status_list
            return this.success_criterion_status_list
        },

        local_pane_model_parent_list () {
            if (this.edit_pane_module === 'Testcase') return this.testcase_list
            return this.success_criterion_list
        },

        localAttachmentsUpdating () {
            return this.mixinIsLoading('attachment-update') || this.mixinIsLoading('attachment-delete')
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('UsecaseExternal', {
            usecase_item: 'item',
            usecase_response: 'response',
            usecase_form_mode: 'form_mode',
        }),

        ...mapState('TestcaseExternal', {
            testcase_item: 'item',
            testcase_filters: 'filters',
            testcase_response: 'response',
            testcase_form_mode: 'form_mode',
        }),

        ...mapState('SuccessCriterionExternal', {
            success_criterion_item: 'item',
            success_criterion_filters: 'filters',
            success_criterion_response: 'response',
            success_criterion_form_mode: 'form_mode',
        }),

        ...mapState('Project', {
            project_item: 'item',
        }),

        ...mapState('CollaboratorExternal', {
            collaborator_list: 'list',
            collaborator_filters: 'filters',
        }),

        ...mapState('CommentExternal', {
            comment_item: 'item',
            comment_list: 'list',
            comment_filters: 'filters',
            comment_form_mode: 'form_mode',
            comment_response: 'response',
        }),

        ...mapState('MetaExternal', {
            meta_list: 'list',
            meta_filters: 'filters',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('Association', {
            association_response: 'response',
        }),

        ...mapState('AssigneeExternal', {
            assignee_response: 'response',
        }),

        ...mapState('AttachmentExternal', {
            attachment_list: 'list',
            attachment_local_list: 'local_list',
            attachment_delete_list: 'delete_list',
            attachment_upload_list: 'upload_list',
            attachment_meta: 'meta',
            attachment_response: 'response',
            attachment_delete_response: 'delete_response',
        }),
    },

    methods: {
        async local_index() {
            if (!this.can_internal_mixin('usecases.index')) return this.local_loading = false
            if (!this.can_external_mixin('usecases.index') || !this.can_internal_mixin('usecases.index')) {
                return this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.usecase_clear_item()
            await this.testcase_clear()
            await this.success_criterion_clear()

            await this.usecase_select({ id: this.$route.params.usecase_id })
            this.local_load_usecase_child_data()
            this.localLoadUsecaseAttachments()

            this.local_loading = false
            this.local_set_async_usecase()
            this.localAddListeners()
        },

        async localLoadUsecaseAttachments () {
            this.mixinSetLoading('attachment')
            this.attachment_clear_response()
            this.attachment_clear_upload_list()
            this.localSetAttachmentFilters()
            await this.attachment_index({ params: this.localAttachmentFilters })
            this.localSetAttachmentParams()
            this.mixinResetLoading('attachment')
            this.mixinExternalUpdateLocalAttachmentList({ list: this.attachment_list })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localSetAttachmentFilters () {
            this.localAttachmentFilters[this.FILTER.parent_id] = this.$route.params.id
            this.localAttachmentFilters[this.FILTER.parent_type] = 'Project'
            this.localAttachmentFilters[this.FILTER.source_id] = this.$route.params.usecase_id
            this.localAttachmentFilters[this.FILTER.source_type] = 'Usecase'
            this.localAttachmentFilters[this.FILTER.fields] = this.attachment_fields
            this.localAttachmentFilters[this.FILTER.page] = 1
            this.localAttachmentFilters.count = 5
        },

        async local_set_async_usecase () {
            await this.usecase_show({id: this.$route.params.usecase_id, params: this.local_usecase_fields })
            // this.local_set_usecase_direct_fields()
        },

        local_load_usecase_child_data () {
            this.local_load_testcase_list()
            this.local_load_success_criterion_list()
            this.local_load_collaborator_list()
            this.local_load_testcase_status_list()
            this.local_load_success_criterion_status_list()
        },

        async local_load_testcase_list () {
            await this.testcase_index({ params : this.local_testcase_fields })
        },
        async local_load_success_criterion_list () {
            await this.success_criterion_index({ params : this.local_success_criterion_fields })
        },

        async local_load_collaborator_list () {
            await this.collaborator_index({
                'filter[project_id]': this.$route.params.id,
                'include': 'user,type',
                count: 200, page: 1
            });
            this.local_collaborator_filter_list()
        },

        async local_load_testcase_status_list () {
            await this.meta_index(this.local_testcase_status_fields)
            this.testcase_status_list = _.cloneDeep(this.meta_list)
        },

        async local_load_success_criterion_status_list () {
            await this.meta_index(this.local_success_criterion_status_fields)
            this.success_criterion_status_list = _.cloneDeep(this.meta_list)
        },

        async local_destroy (id) {
            await this.usecase_destroy({id: id})
            if (this.usecase_response.status === 'success') {
                this.$router.push({name: 'external-projects-usecases'})
            }
        },

        // Sidebar Pane CRUD
        async local_show_edit_pane (edit_item, module) {
            if (this.localAttachmentsUpdating) return
            this.local_clear_pane_store_items(module)
            if (this.local_edit_pane_item.id && (this.local_edit_pane_item.id !== edit_item.id)) {
                this.local_update_list_item(_.cloneDeep(this.local_edit_pane_item), this.edit_pane_module)
            }
            if (this.local_edit_pane_item && this.local_edit_pane_item.id === edit_item.id) return

            await this.local_reset_edit_pane()
            this.edit_pane_module = module
            this.localSetAttachmentParams()
            if (!this.dialog_detail_view) this.dialog_detail_view = true
            this.local_edit_pane_item = _.cloneDeep(edit_item)
            await this.local_setup_pane(edit_item)
        },

        local_clear_pane_store_items () {
            if (module === 'Testcase') return this.testcase_clear_item()
            this.success_criterion_clear_item()
        },

        async local_setup_pane (edit_item) {
            this.local_load_status(edit_item)
            this.local_load_comments(edit_item)
            this.local_load_assignee_and_static_fields(edit_item)
        },

        async local_load_status (model) {
            this.loaders.sidebar_time_est_loading = true
            const params = { include: 'status', 'fields[status]': 'id,type,value,status' }
            const testcase_params = { 'fields[testcases]': 'id,status_id', }
            const success_criteria_params = { 'fields[success_criteria]': 'id,status_id' }

            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(model.id, { ...params, ...testcase_params }, 'update-item')
                this.local_edit_pane_item.status_id = this.testcase_item.status_id
                this.local_edit_pane_item.status = this.testcase_item.status
            } else {
                await this.local_success_criterion_show_item(model.id, { ...params, ...success_criteria_params }, 'update-item')
                this.local_edit_pane_item.status_id = this.success_criterion_item.status_id
                this.local_edit_pane_item.status = this.success_criterion_item.status
            }
            this.loaders.sidebar_time_est_loading = false
        },

        async local_load_comments (item) {
            this.loaders.sidebar_comments_loading = true
            const params = {
                include: 'comments.createdBy',
                'fields[comments]': 'id,commentable_id,commentable_type,content,created_at,created_by_id,updated_at,visibility',
                'fields[comments.createdBy]': 'id,name,avatar,color,initial',
            }

            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(item.id, { ...params, 'fields[testcases]': 'id' }, 'update-item')
                this.local_edit_pane_item.comments = this.testcase_item.comments
            } else {
                await this.local_success_criterion_show_item(item.id, { ...params, 'fields[success_criteria]': 'id' }, 'update-item')
                this.local_edit_pane_item.comments = this.success_criterion_item.comments
            }
            this.loaders.sidebar_comments_loading = false
        },

        async local_load_assignee_and_static_fields (item) {
            const params = { include: 'assignees', 'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial' }
            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(item.id, { ...params, 'fields[testcases]': 'id,title,description_json' }, 'update-item')
                this.local_edit_pane_item.assignees = this.testcase_item.assignees
            } else {
                await this.local_success_criterion_show_item(item.id, { ...params, 'fields[success_criteria]': 'id,title,description_json' }, 'update-item')
                this.local_edit_pane_item.assignees = this.success_criterion_item.assignees
            }
        },

        local_merge_pane_item (item) {
            Object.assign(this.local_edit_pane_item, { ...item })
        },

        local_update_pane_item (evt) {
            if (this.edit_pane_module === 'Testcase') this.local_testcase_update(evt)
            else this.local_success_criterion_update(evt)
        },

        local_destroy_pane_item (evt) {
            if (this.edit_pane_module === 'Testcase') this.local_testcase_destroy(evt)
            else this.local_success_criterion_destroy(evt)
            this.local_clear_edit_pane_view()
            this.local_reset_edit_pane(true)
        },

        local_clear_edit_pane_view () {
            if (this.local_edit_pane_item.id) {
                this.local_update_list_item(_.cloneDeep(this.local_edit_pane_item), this.edit_pane_module)
            }
            this.dialog_detail_view = false
            this.local_edit_pane_item = {}
            this.attachment_update_list(this.localUsecaseAttachmentList)
            this.attachment_update_local_meta(this.localUsecaseAttachmentMeta)
            this.mixinExternalUpdateLocalAttachmentList({ list: this.localUsecaseAttachmentList })
            this.testcase_clear_item()
            this.success_criterion_clear_item()
        },

        async local_reset_edit_pane () {
            this.loaders.content_autosave_response = false
            this.local_edit_pane_item = { tags: [], comments: [], status_id: null, status: {} }
        },

        local_adjust_pane_height () {
            this.$refs.refNavDrawer.$el.firstChild.scrollTop = this.$refs.refNavDrawer.$el.firstChild.scrollHeight
        },

        local_get_filtered_tags (item) {
            return _.differenceBy(this.tag_list, item.tags, 'id')
        },
        // Sidebar Pane CURD ----- END

        // Testcase CRUD
        async local_testcase_store () {
            if (!this.testcase_title || (this.testcase_title && !this.testcase_title.trim())) return
            this.local_set_loading('tc_store', true)
            await this.testcase_clear_item()
            this.testcase_item.usecase_id = this.$route.params.usecase_id
            this.testcase_item.title = this.testcase_title
            await this.testcase_store(this.testcase_item)
            if (this.$response(this.testcase_response, 'title')) return this.local_set_loading('tc_store', false)
            await this.local_testcase_show(this.testcase_item.id, 'add')
            this.testcase_title = ''
            this.local_set_loading('tc_store', false)
        },

        async local_testcase_update (item) {
            await this.testcase_update(item)
            if (this.testcase_response && this.testcase_response.status !== 'success') {
                this.loaders.content_autosave_response = false
                return this.$notify('error', 'Something went wrong, please re-check the fields.')
            }
        },

        local_update_list_item (item, type) {
            const hasError = this.local_pane_response && this.local_pane_response.status !== 'success'
            if (hasError) {
                const oldItem = (type === 'Testcase' ? this.testcase_list : this.success_criterion_list).find(testcase => testcase.id === item.id)
                if (type === 'Testcase') return this.testcase_update_list(oldItem)
                return this.success_criterion_update_list(oldItem)
            }
            if (type === 'Testcase') return this.testcase_update_list(item)
            this.success_criterion_update_list(item)
        },

        async local_testcase_show (id, mode) {
            await this.testcase_show({ id: id, mode: mode ? mode : this.testcase_form_mode , params: this.local_testcase_fields })
        },

        async local_testcase_reorder (list) {
            this.testcase_reorder({list: list})
        },

        async local_testcase_destroy (id) {
            await this.testcase_destroy({id: id})
            if (this.testcase_response && this.testcase_response.status !== 'success') return
            this.$notify('success', 'Test Case deleted successfully!')
        },
        // Testcase CRUD ----- END

        // SuccessCriterion CRUD
        async local_success_criterion_store() {
            if (!this.success_criterion_title || (this.success_criterion_title && !this.success_criterion_title.trim())) return
            this.local_set_loading('sc_store', true)
            await this.success_criterion_clear_item()
            this.success_criterion_item.usecase_id = this.$route.params.usecase_id
            this.success_criterion_item.title = this.success_criterion_title
            await this.success_criterion_store(this.success_criterion_item)
            if (this.success_criterion_response.status !== 'success') return this.local_set_loading('sc_store', false)
            await this.local_success_criterion_show(this.success_criterion_item.id, 'add')
            this.success_criterion_title = ''
            this.local_set_loading('sc_store', false)
        },

        async local_success_criterion_show(id, mode) {
            await this.success_criterion_show({ id: id, mode: mode ? mode : this.success_criterion_form_mode, params: this.local_success_criterion_fields })
        },

        async local_success_criterion_update(item) {
            await this.success_criterion_update(item)
            if (this.success_criterion_response && this.success_criterion_response.status !== 'success') {
                this.loaders.content_autosave_response = false
                return this.$notify('error', 'Something went wrong, please re-check the fields.')
            }
        },

        async local_success_criterion_reorder(list) {
            this.success_criterion_reorder({list: list})
        },

        async local_success_criterion_destroy(id) {
            await this.success_criterion_destroy({id: id})
            if (this.success_criterion_response && this.success_criterion_response.status !== 'success') return
            this.$notify('success', 'Success Criteria deleted successfully!')
        },
        // SuccessCriterion CRUD ----- END

        // Comments CRUD
        local_add_status_comment (direct_edit = false, { comment, item_id, module }) {
            if (direct_edit) return this.local_update_tc_sc_item_comment(module, item_id, comment)

            this.local_edit_pane_item.comments.push(comment)
            this.$set(this.local_edit_pane_item, 'comments_count', this.local_edit_pane_item.comments_count + 1)
            if (module === 'Testcase') {
                const index = this.testcase_list.findIndex(({ id }) => id === item_id)
                this.$set(this.testcase_list, index, this.local_edit_pane_item)
                return
            }
            const index = this.success_criterion_list.findIndex(({ id }) => id === item_id)
            this.$set(this.success_criterion_list, index, this.local_edit_pane_item)
        },

        local_update_tc_sc_item_comment (module, item_id) {
            const newItem = (module === 'Testcase' ? this.testcase_list : this.success_criterion_list).find(data => data.id === item_id)
            this.$set(newItem, 'comments_count', newItem.comments_count + 1)

            if (module === 'Testcase') return this.testcase_update_list(newItem)
            return this.success_criterion_update_list(newItem)
        },
        // Comments CRUD ----- END

        // Extras
        permission_usecase (type) {
            return this.permission_mixin('usecases.' + type)
        },

        async local_usecase_show_item (params, type) {
            await this.usecase_show_item({ id: this.usecase_item.id, params: { ...params }, type })
        },

        async local_testcase_show_item (id, params, type) {
            await this.testcase_show_item({ id, params: { ...params }, type })
        },

        async local_success_criterion_show_item (id, params, type) {
            await this.success_criterion_show_item({ id, params: { ...params }, type })
        },

        local_set_loading (type, value) {
            this.loaders[type] = value
        },

        local_show_autosave_response () {
            this.loaders.content_autosave_response = true
            setTimeout(() => this.loaders.content_autosave_response = false, 2000)
        },

        local_is_loading (type) {
            return this.loaders[type]
        },

        local_collaborator_filter_list () {
            this.local_collaborator_list = this.collaborator_list
        },

        local_member_list (list) {
            return this.local_collaborator_list.filter(item => !(_.find(list, { id: item.user_id })))
        },

        // Attachments - CRUD

        localAddListeners () {
            window.removeEventListener('beforeunload', this.localBeforeUnloadEvent)
            this.mixinExternalAttachmentListeners('add')
        },

        localRemoveListeners () {
            window.removeEventListener('beforeunload', this.localBeforeUnloadEvent)
            this.mixinExternalAttachmentListeners('remove')
        },

        localBeforeUnloadEvent () {
            if (this.localAttachmentsUpdating) {
                return e.returnValue = 'You have unsaved changes. Are you sure you want to leave?'
            }
        },

        localHandleDragEnter (e) {
            this.filesDraggedOver = true
        },

        localHandleLeave (e) {
            if (this.hoveredOnInfo) return
            this.filesDraggedOver = false
        },

        localHandleDrop (e) {
            if (this.localAttachmentsUpdating || this.local_loading) return
            const droppedFiles = Array.from(e.dataTransfer.files)
            if (_.size(droppedFiles) > 5) return this.localValidateAttachmentCount()
            this.localDroppedFilesList = _.cloneDeep(droppedFiles)
            this.filesDraggedOver = false
        },

        localValidateAttachmentCount () {
            this.$notify('warning', 'Sorry, you can only upload up to 5 files at a time')
            this.filesDraggedOver = false
        },

        localRemoveAttachmentItem (id) {
            this.mixinExternalRemoveAttachmentItem(id)
        },

        localDeleteAttachmentItem (id) {
            this.mixinExternalDeleteAttachmentItem(id)
            this.localUpdateUsecaseAttachment()
        },

        async localUpdateUsecaseAttachment () {
            this.attachment_clear_response()
            this.mixinExternalRemoveInvalidFiles()
            await this.localBulkDeleteAttachment()
            await this.localUploadAttachment()
            this.mixinResetLoading('attachment-update')
        },

        async localUploadAttachment () {
            if (!_.size(this.attachment_upload_list)) return
            const formData = new FormData();
            formData.append('parent_id', this.usecase_item.project_id)
            formData.append('parent_type', 'Project')
            formData.append('source_id', this.usecase_item.id)
            formData.append('source_type', 'Usecase')
            formData.append('order', -1)
            this.attachment_upload_list.forEach(file => {
                formData.append('files[]', file)
                formData.append('ids[]', file.id)
            })
            await this.attachment_upload(formData)

            if (!this.$status(this.attachment_response)) return this.mixinExternalHandleAttachmentErrors()
            this.localShowUsecaseAttachments()
            this.attachment_clear_upload_list()
        },

        async localBulkDeleteAttachment () {
            if (!_.size(this.attachment_delete_list)) return
            this.mixinSetLoading('attachment-delete')
            await this.attachment_bulk_destroy({ids: this.attachment_delete_list})
            if (this.$status(this.attachment_delete_response)) {
                if (!_.size(this.attachment_upload_list)) this.localShowUsecaseAttachments()
                if (!_.size(this.attachment_local_list) && !_.size(this.attachment_upload_list)) this.localLoadUsecaseAttachments()
                this.attachment_clear_delete_list()
            }
            this.mixinResetLoading('attachment-delete')
        },

        async localShowUsecaseAttachments () {
            this.localAttachmentFilters.count = _.size(this.attachment_local_list)
            this.localAttachmentFilters.page = 1
            await this.attachment_index({ mode: 'show', params: this.localAttachmentFilters})
            this.localSetAttachmentParams()
            this.mixinExternalUpdateLocalAttachmentList({ list: this.attachment_list})
            this.backupAttachmentList = _.cloneDeep(this.attachment_list)
        },

        localUpdateAttachmentTransfer (params) {
            clearTimeout(this.localAttachmentTimeout)
            this.attachment_clear_response()
            const { list, transferFiles } = params || {}
            const totalUploadList = !!_.size(this.attachment_upload_list) ? [...transferFiles, ...this.attachment_upload_list] : [...transferFiles]
            this.mixinExternalPreserveListChanges({ list: list, type: 'drop_transfer' })
            this.attachment_update_upload_list(totalUploadList)
            this.mixinSetLoading('attachment-update')
            this.localAttachmentTimeout = setTimeout(() => this.localUpdateUsecaseAttachment(), 2000)
        },

        async localLoadMoreAttachments () {
            if (this.attachment_meta.total === _.size(this.attachment_list)) return

            this.mixinSetLoading('attachment')

            const defaultCount = 5
            const isListShortOfItem = _.size(this.attachment_list)%5 === 0
            const loadType = isListShortOfItem ? 'load_more' : 'index'
            this.localAttachmentFilters.count = isListShortOfItem ? defaultCount : this.attachment_meta.current_page*defaultCount
            this.localAttachmentFilters[this.FILTER.page] = isListShortOfItem ? this.attachment_meta.current_page+1 : 1
            await this.attachment_index({ mode: loadType, params: this.localAttachmentFilters })
            this.localSetAttachmentParams()

            this.mixinResetLoading('attachment')
            this.mixinExternalPreserveListChanges({ list: this.attachment_list, type: 'load_more' })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localClearAttachmentUpdate () {
            this.mixinExternalUpdateLocalAttachmentList({ list: this.backupAttachmentList })
            this.mixinExternalClearAttachmentUpdate()
        },

        localSetAttachmentParams () {
            this.localUsecaseAttachmentList = _.cloneDeep(this.attachment_list)
            this.localUsecaseAttachmentMeta = _.cloneDeep(this.attachment_meta)
            this.localUsecaseAttachmentMeta.attachment_list_count = _.size(this.localUsecaseAttachmentList)
        },

        localSetLoadingStatus (e) {
            this.localAttachmentLoading = e
        },

        ...mapActions('UsecaseExternal', {
            usecase_show: 'show',
            usecase_activities: 'activities',
            usecase_update: 'update',
            usecase_update_status: 'update_status',
            usecase_assign: 'assign',
            usecase_unassign: 'unassign',
            usecase_show_item: 'show_item',
            usecase_visibility: 'visibility',
            usecase_business_driver_store: 'business_driver_store',
            usecase_business_driver_destroy: 'business_driver_destroy',
            usecase_destroy: 'destroy',
            usecase_select: 'select',
            usecase_clear_item: 'clear_item',
        }),

        ...mapActions('TestcaseExternal', {
            testcase_index: 'index',
            testcase_update_list: 'update_list',
            testcase_store: 'store',
            testcase_activities: 'activities',
            testcase_update: 'update',
            testcase_show: 'show',
            testcase_show_item: 'show_item',
            testcase_assign: 'assign',
            testcase_unassign: 'unassign',
            testcase_status: 'status',
            testcase_comment_store: 'comment_store',
            testcase_comment_update: 'comment_update',
            testcase_comment_destroy: 'comment_destroy',
            testcase_reorder: 'reorder',
            testcase_business_driver_store: 'business_driver_store',
            testcase_business_driver_destroy: 'business_driver_destroy',
            testcase_destroy: 'destroy',
            testcase_select: 'select',
            testcase_clear: 'clear',
            testcase_clear_item: 'clear_item',
        }),

        ...mapActions('SuccessCriterionExternal', {
            success_criterion_index: 'index',
            success_criterion_update_list: 'update_list',
            success_criterion_store: 'store',
            success_criterion_update: 'update',
            success_criterion_show: 'show',
            success_criterion_show_item: 'show_item',
            success_criterion_activities: 'activities',
            success_criterion_status: 'status',
            success_criterion_assign: 'assign',
            success_criterion_unassign: 'unassign',
            success_criterion_comment_store: 'comment_store',
            success_criterion_comment_update: 'comment_update',
            success_criterion_comment_destroy: 'comment_destroy',
            success_criterion_reorder: 'reorder',
            success_criterion_business_driver_store: 'business_driver_store',
            success_criterion_business_driver_destroy: 'business_driver_destroy',
            success_criterion_destroy: 'destroy',
            success_criterion_select: 'select',
            success_criterion_clear: 'clear',
            success_criterion_clear_item: 'clear_item',
        }),

        ...mapActions('CommentExternal', {
            comment_store: 'store',
            comment_update: 'update',
            comment_clear_item: 'clear_item',
            comment_destroy: 'destroy',
        }),

        ...mapActions('MetaExternal', {
            meta_index: 'index',
            client_meta_index: 'client_index',
            meta_clear: 'clear',
        }),

        ...mapActions('CollaboratorExternal', {
            collaborator_index: 'index',
            collaborator_clear: 'clear',
        }),

        ...mapActions('Association', {
            association_store: 'store',
            association_destroy: 'destroy',
        }),

        ...mapActions('AssigneeExternal', {
            assignee_store: 'store',
            assignee_destroy: 'destroy',
        }),

        ...mapActions('AttachmentExternal', {
            attachment_index: 'index',
            attachment_upload: 'upload',
            attachment_update_local_meta: 'update_meta',
            attachment_update_list: 'update_list',
            attachment_update_upload_list: 'update_upload_list',
            attachment_remove_upload_item: 'remove_upload_list_item',
            attachment_bulk_destroy: 'bulk_destroy',
            attachment_clear_upload_list: 'clear_upload_list',
            attachment_clear_delete_list: 'clear_delete_list',
            attachment_clear_response: 'clear_response',
        }),
    },
}
</script>

<style lang="scss">
    .ProseMirror {
        outline: none;
    }
    .menububble {
        transition: all 0.3s;
        position: absolute;
        background-color: #222;
        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-radius: 0.25rem;
        visibility: hidden;
        opacity: 0;
    }
    .menububble.is-active {
        visibility: visible;
        opacity: 1;
    }
    .v-textarea textarea {
        padding: 0;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .c-profiles {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        margin-left: 8px;
        &--ml-n2 {
            margin-left: -8px;
        }
    }
</style>

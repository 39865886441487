<template>
    <div class="mb-12">
        <!-- <a-flex xs12 pt-0>
            <a-btn small dark color="blue" class="ma-0 elevation-0" @click="local_collaborator_create()">Add Collaborator</a-btn>
        </a-flex> -->
        <div class="mb-4">
            <h3 class="text-h6 grey--text text--darken-3 font-weight-medium">Collaborators</h3>
        </div>

        <v-slide-y-transition group hide-on-leave>
            <div v-if="local_loading" key="loader">
                <a-sheet :class="[{ 'mt-4': index !== 0 }, 'u-border u-rounded-corners']" v-for="(count, index) in 3" :key="count + '-parent'">
                    <div class="pa-7">
                        <loader-template height="12" :width="Math.floor(Math.random() * 300) + 200" class="u-rounded-corners-full"></loader-template>
                    </div>
                    <template v-for="i in 2">
                        <div :key="i">
                            <a-divider></a-divider>
                            <div class="pa-4 px-6 u-flex-center-y">
                                <loader-template width="40" height="40" class="u-rounded-corners-full mr-4"></loader-template>
                                <loader-template height="12" :width="Math.floor(Math.random() * 200) + 100" class="u-rounded-corners-full"></loader-template>
                            </div>
                        </div>
                    </template>
                </a-sheet>
            </div>

            <template v-else>
                <div v-test-label.internal.info key="collabs">
                    <template v-for="(collab_item, index) in collaborator_type_list">
                        <div :key="collab_item.id" :class="{ 'mt-4': index !== 0 }">
                            <a-card flat class="u-border u-rounded-corners">
                                <a-container container--fluid grid-list-xl class="py-4 px-6 grey lighten-5">
                                    <a-layout wrap align-center>
                                        <a-flex xs12 class="u-flex align-center ">
                                            <h2 class="md-subtitle-1 primary--text">{{ collab_item.value }}</h2>
                                            <a-spacer></a-spacer>
                                            <a-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <a-avatar v-on="on" color="grey lighten-3" size="32">
                                                        <a-icon size="14" color="grey darken-1">lock</a-icon>
                                                    </a-avatar>
                                                </template>
                                                <span>Internal</span>
                                            </a-tooltip>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                                <a-divider></a-divider>

                                <template v-if="local_filter(collaborator_list, { type: { value: collab_item.value } }).length">
                                    <div v-for="(collaborator, collabIndex) in local_filter(collaborator_list, { type: { value: collab_item.value } })" :key="collaborator.id">
                                        <a-divider v-if="collabIndex !== 0"></a-divider>
                                        <a-container container--fluid grid-list-xl class="py-4 px-5 pr-7">
                                            <a-layout wrap align-center>
                                                <a-flex shrink>
                                                    <g-avatar :item="collaborator" :right-space="false"></g-avatar>
                                                </a-flex>
                                                <a-flex class="u-flex align-center">
                                                    <p class="md-subtitle-1 grey--text text--darken-2 mb-0" v-if="collaborator && collaborator.user">{{ collaborator.user.name }}</p>
                                                    <a-icon size="14" color="red lighten-1" class="ml-2" v-if="(collaborator.user && collaborator.user.is_active === 0)">not_interested</a-icon>
                                                </a-flex>
                                                <a-flex shrink v-if="check_permission('destroy')">
                                                    <a-btn v-test-btn.remove icon text color="grey darken-2" small @click="local_destroy(collaborator.id, 'team', collab_item.id)">
                                                        <a-icon size="16">clear</a-icon>
                                                    </a-btn>
                                                </a-flex>
                                            </a-layout>
                                        </a-container>
                                    </div>
                                </template>
                                <div v-else class="text-center py-6">
                                    <h2 v-test-label.message.info class="body-2 grey--text text--darken-2">No {{ collab_item.value }} added.</h2>
                                </div>

                                <a-divider></a-divider>
                                <div class="u-wfull u-flex-center-y px-6" v-if="check_permission('update')">
                                    <a-progress-circular v-if="mixinIsLoading(`addUser-${collab_item.id}`)" width="3" size="24" class="mr-4" indeterminate color="orange darken-2"></a-progress-circular>
                                    <GUsersSelectDropdown
                                        v-test-input:[collab_item.order]
                                        v-model="local_selected_collaborator[collab_item.id]"
                                        :list="local_user_list"
                                        itemValue="id"
                                        :loading="mixinIsLoading('search')"
                                        :disabled="mixinIsLoading(`addUser-${collab_item.id}`)"
                                        :inputStyle="{ minHeight: '30px' }"
                                        @search="localSearchUsers"
                                        @select="local_add_collaborator(collab_item.id, 'team')"
                                        :placeholder="`Select ${collab_item.value}`"
                                        noDataText="No User found"
                                        class="u-wfull pl-0"
                                        dropdownClass="c-tiny-scroll py-2"
                                    />
                                </div>
                            </a-card>
                        </div>
                    </template>
                </div>

                <!-- Clients -->
                <a-card v-test-label.externalclient.info class="u-border mt-4 u-rounded-corners" flat key="clients">
                    <div class="u-flex align-center px-6 py-4 grey lighten-5">
                        <h2 class="md-subtitle-1 primary--text">Clients</h2>
                        <a-spacer></a-spacer>
                        <a-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a-avatar v-on="on" color="#f78c2c" size="32">
                                    <a-icon size="14" color="white">lock</a-icon>
                                </a-avatar>
                            </template>
                            <span>External</span>
                        </a-tooltip>
                    </div>
                    <a-divider></a-divider>

                    <template v-if="local_filter(collaborator_list, { group: 'client' }).length">
                        <div v-for="(collaborator, index) in local_filter(collaborator_list, { group: 'client' })" :key="collaborator.id">
                            <a-divider v-if="index !== 0"></a-divider>
                            <a-container container--fluid grid-list-xl class="py-4 px-5 pr-7">
                                <a-layout wrap align-center>
                                    <a-flex shrink>
                                        <g-avatar :item="collaborator" :right-space="false"></g-avatar>
                                    </a-flex>
                                    <a-flex class="u-flex align-center">
                                        <p class="md-subtitle-1 grey--text text--darken-2 mb-0">{{ collaborator.user.name }}</p>
                                        <span class="md-caption red--text text--lighten-1 red lighten-5 px-2 ml-2" style="border-radius: 25px" v-if="(collaborator.user && collaborator.user.is_active === 0)">Blocked</span>
                                        <!-- <p class="md-caption mb-0 grey--text">Client <span v-if="collaborator && collaborator.user && collaborator.user.is_active === 0 && collaborator.user.is_invited === false">(Blocked)</span><span v-if="collaborator && collaborator.user && collaborator.user.is_active === 0 && collaborator.user.is_invited === true">(Invited)</span></p> -->
                                    </a-flex>
                                    <a-flex shrink v-if="check_permission('destroy')">
                                        <a-btn v-test-btn.remove icon text color="grey darken-2" small @click="local_destroy(collaborator.id, 'client')">
                                            <a-icon size="16">clear</a-icon>
                                        </a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </div>
                    </template>
                    <div v-else class="text-center py-6">
                        <h2 v-test-label.message.info class="body-2 grey--text text--darken-2">No Client added.</h2>
                    </div>

                    <a-divider></a-divider>
                    <div class="u-wfull u-flex-center-y px-6" v-if="check_permission('update')">
                        <a-progress-circular v-if="mixinIsLoading('client')" width="3" size="24" class="mr-4" indeterminate color="orange darken-2"></a-progress-circular>
                        <GUsersSelectDropdown
                            v-test-input:client
                            v-model="collaborator_item.client_id"
                            :list="local_customer_contact_list"
                            itemText="user.name"
                            itemValue="id"
                            :loading="mixinIsLoading('search')"
                            :disabled="mixinIsLoading('client')"
                            :inputStyle="{ minHeight: '30px' }"
                            @search="local_load_external_list('client')"
                            @select="local_add_collaborator(null, 'client', $event)"
                            :placeholder="`Select Customer`"
                            noDataText="No Client found"
                            class="u-wfull pl-0"
                            dropdownClass="c-tiny-scroll py-2"
                            returnObject
                        >
                            <template #item="{ props }">
                                <div class="u-flex-center-y">
                                    <g-avatar :item="props.item"></g-avatar>
                                    <g-profile-info :item="props.item" set-max-width hideBlock></g-profile-info>
                                </div>
                            </template>
                        </GUsersSelectDropdown>
                    </div>
                </a-card>

                <!-- Partners -->
                <a-card v-test-label.externalpartner.info class="u-border mt-4 u-rounded-corners" flat v-if="project_item.partner && project_item.partner.id" key="partners">
                    <div class="u-flex align-center px-6 py-4 grey lighten-5">
                        <h2 class="md-subtitle-1 primary--text">Partners</h2>
                        <a-spacer></a-spacer>
                        <a-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a-avatar v-on="on" color="#f78c2c" size="32">
                                    <a-icon size="14" color="white">lock</a-icon>
                                </a-avatar>
                            </template>
                            <span>External</span>
                        </a-tooltip>
                    </div>
                    <a-divider></a-divider>

                    <template v-if="local_filter(collaborator_list, {group: 'partner'}).length">
                        <div v-for="(collaborator, index) in local_filter(collaborator_list, {group: 'partner'})" :key="collaborator.id">
                            <a-divider v-if="index !== 0"></a-divider>
                            <a-container container--fluid grid-list-xl class="py-4 px-5 pr-7">
                                <a-layout wrap align-center>
                                    <a-flex shrink>
                                        <g-avatar :item="collaborator" :right-space="false"></g-avatar>
                                    </a-flex>
                                    <a-flex class="u-flex align-center">
                                        <p class="md-subtitle-2 mb-1">{{ collaborator.user.name }}</p>
                                        <span class="md-caption red--text text--lighten-1 red lighten-5 px-2 ml-2" style="border-radius: 25px" v-if="(collaborator.user && collaborator.user.is_active === 0)">Blocked</span>
                                        <!-- <p class="md-caption mb-0 grey--text">Partner <span v-if="collaborator.user.is_active === 0 && collaborator.user.is_invited === false">(Blocked)</span><span v-if="collaborator.user.is_active === 0 && collaborator.user.is_invited === true">(Invited)</span></p> -->
                                    </a-flex>
                                    <a-flex shrink v-if="check_permission('destroy')">
                                        <a-btn v-test-btn.remove icon text color="grey darken-2" small @click="local_destroy(collaborator.id, 'partner')">
                                            <a-icon size="16">clear</a-icon>
                                        </a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </div>
                    </template>
                    <div v-else class="text-center py-6">
                        <h2 v-test-label.message.info class="body-2 grey--text text--darken-2">No Partner added.</h2>
                    </div>

                    <a-divider></a-divider>
                    <div class="u-wfull u-flex-center-y px-6" v-if="check_permission('update')">
                        <a-progress-circular v-if="mixinIsLoading('partner')" width="3" size="24" class="mr-4" indeterminate color="orange darken-2"></a-progress-circular>
                        <GUsersSelectDropdown
                            v-test-input:partner
                            v-model="collaborator_item.partner_id"
                            :list="local_partner_contact_list"
                            itemValue="id"
                            :loading="mixinIsLoading('search')"
                            :disabled="mixinIsLoading('partner')"
                            :inputStyle="{ minHeight: '30px' }"
                            @search="local_load_external_list('partner')"
                            @select="local_add_collaborator(null, 'partner', $event)"
                            :placeholder="`Select Partner`"
                            noDataText="No Partner found"
                            class="u-wfull pl-0"
                            dropdownClass="c-tiny-scroll py-2"
                            returnObject
                        >
                            <template #item="{ props }">
                                <div class="u-flex-center-y">
                                    <g-avatar :item="props.item"></g-avatar>
                                    <g-profile-info :item="props.item" set-max-width hideBlock></g-profile-info>
                                </div>
                            </template>
                        </GUsersSelectDropdown>
                    </div>
                </a-card>
            </template>
        </v-slide-y-transition>


        <a-dialog v-model="dialog_client_share" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl pa-6>
                        <a-layout wrap align-center>
                            <a-flex shrink py-0 pr-2>
                                <a-avatar color="orange">
                                    <a-icon color="white" size="24">lock_open</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex py-0>
                                <h2 class="md-heading-5">Sharing with Client</h2>
                            </a-flex>
                        </a-layout>
                        <a-layout wrap align-center>
                            <a-flex>
                                <p class="md-body-1 grey--text text--darken-2 mb-0">You are about to share the project with the client and the title of the project will be visible to client as "<span class="font-weight-bold">{{ project_item.title }}</span>". Would like you to change it before sharing?</p>
                            </a-flex>
                        </a-layout>
                        <a-layout wrap align-center v-if="dialog_client_share_status">
                            <a-flex>
                                <a-text-field
                                    v-model="dialog_client_share_title"
                                    placeholder="Project Title"
                                    background-color="neutral"
                                    solo flat hide-details
                                    v-test-input.title
                                ></a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="project_title_response && 'server' in this.project_response" v-test-label.title.error>
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ this.project_response.server.errors.title[0] }}
                                </span>
                            </a-flex>
                        </a-layout>
                        <a-layout wrap align-center>
                            <a-flex shrink v-if="dialog_client_share_status">
                                <a-btn v-test-btn:update class="ma-0 elevation-0 green lighten-4 green--text text--darken-2" @click="local_client_first_share()" :loading="loading">Update and Share</a-btn>
                            </a-flex>
                            <a-flex shrink v-else>
                                <a-btn v-test-btn:share class="ma-0 elevation-0 green lighten-4 green--text text--darken-2" @click="local_no_change()" :loading="loading">Looks okay</a-btn>
                            </a-flex>
                            <a-flex shrink v-if="!dialog_client_share_status">
                                <a-btn v-test-btn:change color="primary" class="ma-0" text @click="local_change_client_share_title()">Change Now</a-btn>
                            </a-flex>
                            <a-spacer></a-spacer>
                            <a-flex shrink>
                                <a-btn v-test-btn:cancel color="grey" class="ma-0" text @click="local_first_share_close()">Cancel Sharing</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
         <a-dialog v-model="dialog_collaborator_form" max-width="800" persistent>
            <a-card>
                <a-card-text class="pa-0">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>person</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Add collaborator in project</h2>
                                    <p class="md-subtitle-2 mb-0">You can add list of collaborators  in the project.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12 pt-0>
                                    <v-radio-group class="mt-0" hide-details v-model="collaborator_group" row>
                                        <v-radio label="Add Team User" value="team"></v-radio>
                                        <v-radio label="Create and Add Team User" value="new"></v-radio>
                                        <v-radio label="Add Client" value="client"></v-radio>
                                        <v-radio label="Add Partner" value="partner"></v-radio>
                                    </v-radio-group>
                                </a-flex>
                                <a-flex xs12 v-if="collaborator_group === 'team'">
                                   <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">User</label>
                                        <a-autocomplete
                                            v-model="collaborator_item.user_id"
                                            :items="local_user_list"
                                            item-value="id"
                                            item-text="name"
                                            placeholder="Select User"
                                            background-color="neutral"
                                            solo flat hide-details clearable
                                        >
                                        <template v-slot:no-data>
                                                <div class="px-4 py-2">
                                                    <span class="md-body-1" v-if="local_user_search != null"> <strong>{{ local_user_search }}</strong></span>
                                                    <span class="md-body-1 grey--text" v-else>No Users found</span>
                                                </div>
                                            </template>
                                            <template v-slot:selection="{ item }">
                                                <span v-if="item === Object(item)" class="md-body-2 d-inline-block py-1 u-rounded-corners text-truncate" style="max-width: 300px" :title="item.name" >{{ item.name }} </span>
                                                <span v-else class="md-body-2 d-inline-block py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                            </template>
                                            <template v-slot:item="{ item }" class="pa-3">
                                                <a-list-item-content>
                                                    <a-layout align-center class="py-0">
                                                        <div class="member-avatar mr-2">
                                                            <a-avatar size="32" color="grey" v-if="item.profile && item.profile.avatar">
                                                                <a-avatar size="30" color="white">
                                                                    <a-img :src="item.profile.avatar" contain></a-img>
                                                                </a-avatar>
                                                            </a-avatar>
                                                            <a-avatar size="32" color="indigo" v-if="!item.profile || item.profile.avatar == null">
                                                                <span class="md-caption font-weight-bold white--text">{{ local_get_initials(item.name) }}</span>
                                                            </a-avatar>
                                                        </div>
                                                        <div class="member-label u-cursor-pointer">
                                                            <h5 class="md-subtitle-1 text-truncate" style="max-width: 300px" :title=" item.name" >{{ item.name }}</h5>
                                                            <p class="md-caption grey--text text--darken-1 mb-0">{{ item.role.name }}</p>
                                                        </div>
                                                    </a-layout>
                                                </a-list-item-content>
                                            </template>
                                        </a-autocomplete>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="collaborator_response.server && collaborator_response.server.errors && collaborator_response.server.errors.user_id">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ collaborator_response.server.errors.user_id[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Select the user.
                                        </span>
                                    </a-flex>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Select User Role</label>
                                        <a-autocomplete
                                            v-model="collaborator_item.type_id"
                                            :items="collaborator_type_list"
                                            item-text="value"
                                            item-value="id"
                                            placeholder="Select Role"
                                            background-color="neutral"
                                            solo flat hide-details
                                        >
                                        </a-autocomplete>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="collaborator_response.server && collaborator_response.server.errors && collaborator_response.server.errors.type_id">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ collaborator_response.server.errors.type_id[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Role of the user from the list.
                                        </span>
                                    </a-flex>
                                </a-flex>
                                <a-flex v-if="collaborator_group === 'new'">
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                        <a-text-field
                                            v-model="user_item.name"
                                            placeholder="Enter Name"
                                            background-color="neutral"
                                            solo flat hide-details
                                        >
                                        </a-text-field>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="user_response.server && user_response.server.errors && user_response.server.errors.name">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ user_response.server.errors.name[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            The name will be helpful to identify the user.
                                        </span>
                                    </a-flex>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Email</label>
                                        <a-text-field
                                            v-model="user_item.email"
                                            placeholder="Enter Email Address"
                                            background-color="neutral"
                                            solo flat hide-details
                                        >
                                        </a-text-field>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="user_response.server && user_response.server.errors && user_response.server.errors.email">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ user_response.server.errors.email[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Email address will be used as username for loggin in.
                                        </span>
                                    </a-flex>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Role</label>
                                        <a-autocomplete
                                            v-model="user_item.role_id"
                                            :items="role_list"
                                            item-text="name"
                                            item-value="id"
                                            placeholder="Select Role"
                                            background-color="neutral"
                                            solo flat hide-details
                                        >
                                        </a-autocomplete>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="user_response.server && user_response.server.errors && user_response.server.errors.role_id">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ user_response.server.errors.role_id[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Role of the user from the list.
                                        </span>
                                    </a-flex>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Manager (Optional)</label>
                                        <a-autocomplete
                                            v-model="user_item.manager_id"
                                            :items="user_list"
                                            item-value="id"
                                            item-text="name"
                                            placeholder="Select Manager"
                                            background-color="neutral"
                                            solo flat hide-details clearable
                                        >
                                        <template v-slot:no-data>
                                                <div class="px-4 py-2">
                                                    <span class="md-body-1" v-if="local_manager_search != null"> <strong>{{ local_manager_search }}</strong></span>
                                                    <span class="md-body-1 grey--text" v-else>No Users found</span>
                                                </div>
                                            </template>
                                            <template v-slot:selection="{ item }">
                                                <span v-if="item === Object(item)" class="md-body-2 d-inline-block py-1 u-rounded-corners text-truncate" style="max-width: 300px" :title="item.name" >{{ item.name }} </span>
                                                <span v-else class="md-body-2 d-inline-block py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                            </template>
                                            <template v-slot:item="{ item }" class="pa-3">
                                                <a-list-item-content>
                                                    <a-layout align-center class="py-0">
                                                        <div class="member-avatar mr-2">
                                                            <a-avatar size="32" color="grey" v-if="item.profile && item.profile.avatar">
                                                                <a-avatar size="30" color="white">
                                                                    <a-img :src="item.profile.avatar" contain></a-img>
                                                                </a-avatar>
                                                            </a-avatar>
                                                            <a-avatar size="32" color="indigo" v-if="!item.profile || item.profile.avatar == null">
                                                                <span class="md-caption font-weight-bold white--text">{{ local_get_initials(item.name) }}</span>
                                                            </a-avatar>
                                                        </div>
                                                        <div class="member-label u-cursor-pointer">
                                                            <h5 class="md-subtitle-1 text-truncate" style="max-width: 300px" :title=" item.name" >{{ item.name }}</h5>
                                                            <p class="md-caption grey--text text--darken-1 mb-0">{{ item.role.name }}</p>
                                                        </div>
                                                    </a-layout>
                                                </a-list-item-content>
                                            </template>
                                        </a-autocomplete>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="user_response.server && user_response.server.errors && user_response.server.errors.manager_id">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ user_response.server.errors.manager_id[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Select the manager of this user.
                                        </span>
                                    </a-flex>
                                    <a-flex xs12 ml-1 pl-0 v-if="user_form_mode === 'add'">
                                        <a-checkbox v-model="user_item.send_email_invite" class="mx-2 mt-1" color="primary" hide-details label="Send user invitation via email"></a-checkbox>
                                    </a-flex>
                                </a-flex>
                                <a-flex v-if="collaborator_group === 'client'">
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Select Client</label>
                                        <a-autocomplete
                                            v-model="collaborator_item.contact_id"
                                            :items="local_customer_contact_list"
                                            item-value="id"
                                            item-text="name"
                                            placeholder="Select Client"
                                            background-color="neutral"
                                            solo flat hide-details clearable
                                        >
                                        <template v-slot:item="{ item }" class="pa-3">
                                            <a-list-item-content>
                                                <a-layout align-center class="py-0">
                                                    <div class="member-avatar mr-2">
                                                        <a-avatar size="32" color="grey" v-if="item.profile && item.profile.avatar">
                                                            <a-avatar size="30" color="white">
                                                                <a-img :src="item.profile.avatar" contain></a-img>
                                                            </a-avatar>
                                                        </a-avatar>
                                                        <a-avatar size="32" color="indigo" v-if="!item.profile || item.profile.avatar == null">
                                                            <span class="md-caption font-weight-bold white--text">{{ local_get_initials(item.name) }}</span>
                                                        </a-avatar>
                                                    </div>
                                                    <div class="member-label u-cursor-pointer mb-1">
                                                        <h5 class="md-subtitle-1 text-truncate" style="max-width: 300px">{{ item.name }}</h5>
                                                    </div>
                                                </a-layout>
                                            </a-list-item-content>
                                        </template>
                                        </a-autocomplete>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="collaborator_response.server && collaborator_response.server.errors && collaborator_response.server.errors.contact_id">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ collaborator_response.server.errors.contact_id[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Select the manager of this user.
                                        </span>
                                    </a-flex>
                                </a-flex>
                                <a-flex v-if="collaborator_group === 'partner'">
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Select Partner</label>
                                        <a-autocomplete
                                            v-model="collaborator_item.contact_id"
                                            :items="local_partner_contact_list"
                                            item-value="id"
                                            item-text="name"
                                            placeholder="Select Partner"
                                            background-color="neutral"
                                            solo flat hide-details clearable
                                        >
                                        <template v-slot:item="{ item }" class="pa-3">
                                            <a-list-item-content>
                                                <a-layout align-center class="py-0">
                                                    <div class="member-avatar mr-2">
                                                        <a-avatar size="32" color="grey" v-if="item.profile && item.profile.avatar">
                                                            <a-avatar size="30" color="white">
                                                                <a-img :src="item.profile.avatar" contain></a-img>
                                                            </a-avatar>
                                                        </a-avatar>
                                                        <a-avatar size="32" color="indigo" v-if="!item.profile || item.profile.avatar == null">
                                                            <span class="md-caption font-weight-bold white--text">{{ local_get_initials(item.name) }}</span>
                                                        </a-avatar>
                                                    </div>
                                                    <div class="member-label u-cursor-pointer mb-1">
                                                        <h5 class="md-subtitle-1 text-truncate" style="max-width: 300px">{{ item.name }}</h5>
                                                    </div>
                                                </a-layout>
                                            </a-list-item-content>
                                        </template>
                                        </a-autocomplete>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="collaborator_response.server && collaborator_response.server.errors && collaborator_response.server.errors.contact_id">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ collaborator_response.server.errors.contact_id[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Select the manager of this user.
                                        </span>
                                    </a-flex>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_add_collaborator()" :loading="loading" :disabled="loading">Add collaborator </a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_collaborator_form = !dialog_collaborator_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-snackbar v-model="snackbar_status" bottom>
            {{ snackbar_message }}
        </a-snackbar>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import TemplateImportStatus from '@/mixins/mixin-template-import-status'
import mixinLoading from '@/mixins/mixin-module-loading-setup'

export default {
    name: 'ProjectCollaborators',
    mixins: [TemplateImportStatus, mixinLoading],

    data () {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            clients: [],
            team_member_field: null,
            account_executive_field: null,
            sales_engineer_field: null,
            client_field: null,
            partner_field: null,
            customer_contact_list: [],
            partner_contact_list: [],
            collaborator_type_list: [],
            dialog_team_form: false,
            dialog_client_form: false,
            dialog_client_share_obj: {},
            dialog_client_share: false,
            dialog_client_share_title: null,
            dialog_client_share_status: false,
            project_title_response: false,
            local_assignee_search: null,
            snackbar_status: false,
            snackbar_message: '',
            collaborator_group: 'team',
            dialog_collaborator_form: false,
            local_user_search: null,
            local_selected_collaborator: [],
            local_loading: true,
            searchTimeout: null,
        }
    },

    mounted () {
        this.local_index()
    },

    watch: {
        team_member_field(newValue) {
            if (newValue != null) {
                this.local_store(newValue, 'Team')
            }
        },

        account_executive_field(newValue) {
            if (newValue != null) {
                this.local_store(newValue, 'Account Executive')
            }
        },

        sales_engineer_field(newValue) {
            if (newValue != null) {
                this.local_store(newValue, 'Sales Engineer')
            }
        },

        client_field(newValue) {
            if (newValue != null) {
                if (this.local_filter(this.collaborator_list, {type: 'client'}).length > 0) {
                    this.local_store(newValue, 'Client')
                } else {
                    this.dialog_client_share = true;
                    this.dialog_client_share_obj = newValue;
                }
            }
        },

        partner_field(newValue) {
            if (newValue != null) {
                this.local_store(newValue, 'Partner')
            }
        }
    },

    computed: {
        local_user_list() {
            return _.filter(this.user_list, (user) => _.findIndex(this.collaborator_list, {user: {id: user.id}}) < 0)
        },

        local_customer_contact_list() {
            return _.filter(this.customer_contact_list, (contact) => _.findIndex(this.collaborator_list, {contact_id: contact.id}) < 0)
        },

        local_partner_contact_list() {
            return _.filter(this.partner_contact_list, (contact) => _.findIndex(this.collaborator_list, {contact_id: contact.id}) < 0)
        },

        ...mapState('User', {
            user_self: 'self',
            user_list: 'list',
            user_item: 'item',
            user_action: 'action',
            user_form_mode: 'form_mode',
            user_response: 'response',
        }),

        ...mapState('ProjectView', {
            project_item: 'item',
            project_response: 'response',
        }),

        ...mapState('Collaborator', {
            collaborator_list: 'list',
            collaborator_item: 'item',
            collaborator_filters: 'filters',
            collaborator_response: 'response',
        }),

        ...mapState('Contact', {
            contact_list: 'list',
            contact_filters: 'filters',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
            meta_filters: 'filters',
        }),

        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_index() {
            if (!this.$can('collaborators.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.$vuetify.goTo(0)
            this.collaborator_clear();
            this.user_clear();
            this.contact_clear();

            this.collaborator_index({'filter[project_id]' : this.$route.params.id, 'include': 'user.role,type,contact', count: 200, page: 1 });
            if (this.project_item.id !== this.$route.params.id) await this.project_show_item({id: this.$route.params.id})

            this.local_get_partner()
            await this.local_load_collaborator_type_list()
            this.local_loading = false
        },

        localSearchUsers (val) {
            this.mixinSetLoading('search')
            clearTimeout(this.searchTimeout)

            this.searchTimeout = setTimeout(async () => {
                await this.fetchUsers(val)
                this.mixinResetLoading('search')
                clearTimeout(this.searchTimeout)
            }, 500)
        },

        async fetchUsers (searchValue = null) {
            const params = { 'include': 'role', 'sort': 'name', 'filter[is_active]': 1, count: 20, page: 1 }
            if (searchValue) params['filter[name]'] = searchValue
            await this.user_index(params)
        },

        async local_get_partner () {
            const params = { include: 'partner', 'fields[partner]': 'id,name', 'fields[projects]': 'id,partner_id' }
            await this.project_show_item({
                id: this.$route.params.id,
                params: params
            })
        },

        async local_load_collaborator_type_list() {
            await this.meta_index({'filter[type]': 'collaborator_type', 'sort': 'order'})
            this.collaborator_type_list = _.cloneDeep(this.meta_list)
            await this.meta_clear()
        },

        async local_collaborator_create(){
            await this.collaborator_clear_item()
            this.dialog_collaborator_form = true
            this.collaborator_group = 'team'
        },

        async local_add_collaborator(meta_id, type, contact) {
            if (type === 'team') {
                this.mixinSetLoading(`addUser-${meta_id}`)
                this.collaborator_item.type_id = meta_id
                this.collaborator_item.user_id = this.local_selected_collaborator[meta_id]
                await this.local_store_collaborator(type)
                this.local_check_if_key_collab(meta_id)
                this.local_selected_collaborator = []
                return
            }

            Object.assign(this.collaborator_item, { contact_id: contact.id } )
            this.dialog_client_share_obj = { type, contact_id: contact.id }
            if (type === 'client') {
                this.mixinSetLoading('client')
                if (this.local_filter(this.collaborator_list, {group: 'client'}).length === 0 && contact.id !== 'no-data') {
                    this.dialog_client_share = true
                } else {
                    await this.local_store_collaborator(type)
                    this.collaborator_item.client_id = null
                    this.customer_contact_list = []
                }
                return this.mixinResetLoading('client')
            }

            this.mixinSetLoading('partner')
            await this.local_store_collaborator(type)
            this.collaborator_item.partner_id = null
            this.partner_contact_list = []
            this.mixinResetLoading('partner')
        },

        async local_check_if_key_collab (meta_id) {
            const keyCollabIndex = this.collaborator_type_list.findIndex(collab => collab.is_starred === 1 && collab.id === meta_id)
            if (keyCollabIndex === -1) return this.mixinResetLoading(`addUser-${meta_id}`)
            await this.local_get_key_collabs()
            if (this.mixinIsLoading(`addUser-${meta_id}`)) this.mixinResetLoading(`addUser-${meta_id}`)
        },

        async local_get_key_collabs () {
            await this.project_show_item({ id: this.$route.params.id, params: { 'include': 'keyCollaborators.user,keyCollaborators.type', 'fields[projects]': 'id', } })
        },

        async local_store_collaborator(type) {
            this.collaborator_item.group = type
            this.collaborator_item.project_id = this.$route.params.id
            if (type !== 'team') this.collaborator_item.contact_id = this.dialog_client_share_obj.contact_id
            await this.collaborator_store(this.collaborator_item)
            if (this.collaborator_response.status !== 'success') return
            this.local_collaborator_show(this.collaborator_item.id, 'add', { 'include': 'user.role,type,contact' })
            this.collaborator_clear_item()
        },

        async local_collaborator_show(id, mode, include) {
              await this.collaborator_show({ id:id, mode: mode, params : include })
              await this.collaborator_clear_item()
        },

        local_load_external_list(external) {
            this.mixinSetLoading('search')
                clearTimeout(this.searchTimeout)

                this.searchTimeout = setTimeout(async () => {
                (external === 'client') ? await this.local_load_customer_list() : await this.local_load_partner_list()
                this.mixinResetLoading('search')
                clearTimeout(this.searchTimeout)
            }, 500)
        },

        async local_load_customer_list() {
            await this.contact_clear();
            if (this.project_item.customer_id) {
                await this.contact_index({
                    params: {
                        //'include': 'bridges',
                        'filter[parent_id]': this.project_item.customer_id,
                        'filter[parent_type]': 'Customer',
                        'sort': 'name'
                    }
                })
                this.customer_contact_list = _.cloneDeep(this.contact_list)
            }
        },

        async local_load_partner_list() {
            await this.contact_clear();
            if (this.project_item.partner_id) {
            await this.contact_index({
                    params: {
                        //'include': 'bridges',
                        'filter[parent_id]': this.project_item.partner_id,
                        'filter[parent_type]': 'Partner',
                        'sort': 'name'
                    }
                })
                this.partner_contact_list = _.cloneDeep(this.contact_list)
            }        
        },

        async local_store(user_id, type) {
            if (user_id != null) {
                this.collaborator_item.user_id = user_id;
                this.collaborator_item.project_id = this.$route.params.id;
                this.collaborator_item.group = type;
                await this.collaborator_store()
                if (this.collaborator_response && this.collaborator_response.status === 'error') {
                    this.snackbar_status = true
                    this.snackbar_message = this.collaborator_response.text
                }
                await this.collaborator_clear_item()
                this.dialog_client_share_obj = {};
            }
        },

        async local_client_first_share() {
            await this.project_update({id: this.project_item.id, title: this.dialog_client_share_title, start_date: this.project_item.start_date})
            if (!('server' in this.project_response)) {
                this.project_item.title = this.dialog_client_share_title
                await this.local_store_collaborator('client')
                this.dialog_client_share_status = false
                this.local_first_share_close()
                this.project_title_response = false
            } 
            else this.project_title_response = true
        },

        async local_destroy(id, type, keyCollabId = null) {
            await this.collaborator_destroy({id: id})
            if (keyCollabId) this.local_check_if_key_collab(keyCollabId)
        },

        local_filter(data, filter) {
            return _.filter(data, filter)
        },

        local_get_initials(name) {
            let initials_array = _.split(name, ' ', 2)
            let initial_first = _.truncate(initials_array[0], {length: 1, omission: ''})
            let initial_second = _.truncate(initials_array[1], {length: 1, omission: ''})
            return initial_first + initial_second
        },

        local_collaborators_team() {
            return _.filter(this.project_item.collaborators, {group: 'team'});
        },

        local_collaborators_client() {
            return _.filter(this.project_item.collaborators, {group: 'client'});
        },

        local_team_edit() {
            this.dialog_team_form = true
        },

        local_change_client_share_title() {
            this.project_title_response = false
            this.dialog_client_share_status = true
            this.dialog_client_share_title = this.project_item.title
        },

        async local_client_edit() {
            this.dialog_client_form = true
            this.clients = this.project_item.clients;
            this.contact_filters.customer_id = this.project_item.customer_id;
            await this.contact_clear();
            await this.contact_index();
        },

        local_user_list_filtered() {
            return _.reduce(this.user_list, (result, user_item, key) => {
                if (user_item.id != this.project_item.account_executive_id && user_item.id != this.project_item.sales_engineer_id)
                {
                    result.push(user_item)
                }
                return result
            }, [])
        },

        async local_no_change() {
            await this.local_store_collaborator('client')
            this.local_first_share_close()
        },

        local_first_share_close() {
            this.customer_contact_list = []
            this.client_field = null
            this.dialog_client_share = false
            this.dialog_client_share_title = null
            this.dialog_client_share_obj = {}
            this.dialog_client_share_status = false
            this.collaborator_item.contact_id = null
            this.collaborator_item.client_id = null
        },

        async local_collaborator_group_update(id, type_id) {
            await this.collaborator_clear_item()
            await this.collaborator_select({id: id})
            this.collaborator_item.type_id = type_id
            this.collaborator_item.id = id
            await this.collaborator_update(this.collaborator_item)
            if (this.collaborator_response.status !== 'success') return
            this.local_collaborator_show(this.collaborator_item.id, 'show', { 'include': 'type' })
            this.dialog_collaborator_form = false
        },

        local_remove_user(list, id, type_id) {
            list.splice(_.findIndex(list, { id: id }), 1)
        },

        local_collaborator_get_by_id(id, property) {
            return _.get(_.find(this.collaborator_type_list, {id: id}), property)
        },

        check_permission(type) {
            return this.$can(`collaborators.${type}`) && this.$can('projects.update-only')
        },

        ...mapActions('User', {
            user_index: 'index',
            user_store: 'store',
            user_clear: 'clear',
            user_clear_item: 'clear_item',
        }),

        ...mapActions('ProjectView', {
            project_show_item: 'show',
            project_update: 'update',
        }),

        ...mapActions('Contact', {
            contact_index: 'index',
            contact_clear: 'clear',
        }),

        ...mapActions('Collaborator', {
            collaborator_index: 'index',
            collaborator_store: 'store',
            collaborator_show: 'show',
            collaborator_update: 'update',
            collaborator_destroy: 'destroy',
            collaborator_select: 'select',
            collaborator_clear: 'clear',
            collaborator_clear_item: 'clear_item',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
            meta_clear_item: 'clear_item',
        }),
    }
}
</script>

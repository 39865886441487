export const projectVariables = [
    {
        variable: '{{project.title}}',
        description: 'Adds project title.'
    },
    {
        variable: '{{project.description}}',
        description: 'Adds project description.'
    },
    {
        variable: '{{project.next_steps}}',
        description: 'Project next steps.'
    },
    {
        variable: '{{project.start_date}}',
        description: 'Start Date of the project.'
    },
    {
        variable: '{{project.end_date}}',
        description: 'End date of the project.'
    },
    {
        variable: '{{project.estimated_start_date}}',
        description: 'Estimated start date of the project.'
    },
    {
        variable: '{{project.estimated_end_date}}',
        description: 'Estimated end date of the project.'
    },
    {
        variable: '{{project.result}}',
        description: 'Result of the project'
    },
    {
        variable: '{{project.customer}}',
        description: 'Customer information.'
    },
    {
        variable: '{{project.partners}}',
        description: 'Project partners.'
    },
    {
        variable: '{{project.deal_amount}}',
        description: 'Deal amount of the project'
    },
    {
        variable: '{{project.stage}}',
        description: 'Stage of the project'
    },
    {
        variable: '{{project.health}}',
        description: 'Health of the project'
    },
    {
        variable: '{{project.competitors}}',
        description: 'Project competitors'
    },
    {
        variable: '{{project.sales_engineer.email}}',
        description: 'Adds sales engineer\'s email.'
    },
    {
        variable: '{{project.sales_engineer.name}}',
        description: 'Adds sales engineer\'s name.'
    },
    {
        variable: '{{project.account_executive.email}}',
        description: 'Adds account executive\'s email.'
    },
    {
        variable: '{{project.account_executive.name}}',
        description: 'Adds account executive\'s name.'
    },
    {
        variable: '{{project.products.tags}}',
        description: 'Adds product tags from project.'
    },
    {
        variable: '{{project.labels.tags}}',
        description: 'Adds label tags from project.'
    },
    {
        variable: '{{project.platforms.tags}}',
        description: 'Adds platform tags from project.'
    },
    {
        variable: '{{project.internal_collaborators.emails}}',
        description: 'Adds internal collaborators emails.'
    },
    {
        variable: '{{project.internal_collaborators.names}}',
        description: 'Adds internal collaborators names.'
    },
]

export const usecaseVariables = [
    {
        variable: '{{usecase.title}}',
        description: 'Adds usecase title.'
    },
    {
        variable: '{{usecase.status}}',
        description: 'Current status of the usecase'
    },
    {
        variable: '{{usecase.visibility}}',
        description: 'Current visibility of the usecase'
    },
]

export const taskVariables = [
    {
        variable: '{{task.title}}',
        description: 'Adds task title.'
    },
    {
        variable: '{{task.status}}',
        description: 'Current status of the task'
    },
    {
        variable: '{{task.visibility}}',
        description: 'Current visibility of the task'
    },
    {
        variable: '{{task.milestone}}',
        description: 'Milestone information'
    },
    {
        variable: '{{task.due_date}}',
        description: 'Due date of the task'
    },
    {
        variable: '{{task.priority}}',
        description: 'Priority of the task'
    },
]

export const documentVariables = [
    {
        variable: '{{today.date}}',
        description: 'Date of the generated document.'
    },
]

import Vue from 'vue'

Vue.filter('sizer', function (bytes, roundTo = 2) {
    if (!bytes) return ''
    const intergerByte = parseInt(bytes), sizes = ['Bytes', 'KB', 'MB']
    const convertedSize = parseInt(Math.floor(Math.log(intergerByte) / Math.log(1024)), 10)

    if (convertedSize === 0) return `${intergerByte} ${sizes[convertedSize]}`
    return `${(intergerByte / (1024 ** convertedSize)).toFixed(roundTo)} ${sizes[convertedSize]}`
})

<template>
    <a-container grid-list-xl class="pa-0 white u-rounded-corners u-wfull">
        <div ref="refContainer">
            <div class="u-sticky" style="top: 0; z-index: 20">
                <div class="pa-5 u-flex white justify-space-between align-center u-rounded-corners">
                    <div class="u-flex align-center">
                        <div :class="[localActivityIsStageChange ? 'green darken-2' : 'deep-purple', 'u-rounded-corners-full u-flex justify-center']" style="width: 32px; height: 32px">
                            <a-icon size="20" color="white">{{ localActivityIsStageChange ? 'wifi_protected_setup' : 'scoreboard'}}</a-icon>
                        </div>
                        <div class="ml-4 u-flex align-center">
                            <div :class="[localActivityIsStageChange ? 'green--text text--darken-2' : 'deep-purple--text', 'md-heading-6']">{{ localActivityIsStageChange ? 'Stage Change Activity' : 'Confidence Score'}}</div>
                            <div v-if="item && activityType !== 'stage_change'" class="ml-2 grey--text text--darken-1">({{ activityType === 'project_update' ? 'Form Update' : 'Configuration Update'}})</div>
                        </div>
                    </div>
                    <div>
                        <a-btn icon plain @click="$emit('close')">
                            <a-icon size="24" color="grey darken-1">close</a-icon>
                        </a-btn>
                    </div>
                </div>
                <a-divider></a-divider>
                <div class="py-3 px-5 u-flex white md-body-2">
                    <div class="grey--text text--darken-1 font-weight-medium">
                        <template  v-if="!localActivityIsStageChange">
                            <span>{{ local_date_output(item.updated_at) }}</span>
                        </template>
                        <template  v-else>
                            <span>{{ local_date_output(item.created_at) }}</span>
                        </template>
                    </div>
                    <a-icon size="8" class="px-4" color="grey lighten-1">fiber_manual_record</a-icon>
                    <div class="grey--text text--darken-1">
                        Updated by
                        <template v-if="!localActivityIsStageChange">
                            <span v-if="item && item.updated_by" :title="item.updated_by.name" class="font-weight-medium">{{ item.updated_by.name | truncateText(25) }}</span>
                        </template>
                        <template v-else>
                            <template v-if="item && item.created_by && item.created_by.name">
                                <span :title="item.created_by.name" class="font-weight-medium">{{ item.created_by.name | truncateText(25) }}</span>
                            </template>
                            <template v-else>
                                <span class="font-weight-medium text-capitalize"> {{ item.created_by_type }}</span>
                            </template>
                        </template>
                    </div>
                    <a-icon size="8" class="px-4" color="grey lighten-1">fiber_manual_record</a-icon>
                    <div class="u-flex align-center">
                        <template v-if="localActivityIsStageChange">
                            <div class="grey--text text--darken-1">{{ item.days > 1 ? item.days + ' days' : item.days + ' day' }}</div>
                        </template>
                        <template v-else-if="item && item.new_values">
                            <div class="grey--text text--darken-3 u-flex align-center">
                                <a-icon size="20" color="grey darken-1" class="mr-2" outlined>scoreboard</a-icon>
                                <span>{{ item.new_values.current_score ? item.new_values.current_score : '-' }}</span>
                                <span class="px-1">/</span>
                                <span>{{ item.new_values.total }}</span>
                            </div>
                            <div v-if="item.new_values.current_score" class="px-2">- <span style="font-weight: 600">{{ Math.round((item.new_values.current_score/item.new_values.total)* 100) }}%</span></div>
                        </template>
                    </div>
                </div>
                <a-divider></a-divider>
            </div>
            <div class="u-wfull c-log-section c-tiny-scroll" :style="[ localActivityIsStageChange ? { height: '100%'} : { height: '500px'} ]">
                <template>
                    <table class="u-wfull">
                        <colgroup>
                            <col span="1" style="width: 40%;">
                            <col span="1" style="width: 27.5%;">
                            <col span="1" style="width: 5%;">
                            <col span="1" style="width: 27.5%;">
                        </colgroup>

                        <tbody class="grey--text text--darken-2 md-body-2">
                            <tr class="md-subtitle-2 u-sticky u-wfull indigo--text text--darken-1" style="top: 0px; z-index: 10">
                                <th class="pl-5">{{ localActivityIsStageChange ? 'Workspace' : 'Section & Fields' }}</th>
                                <th>From value</th>
                                <th></th>
                                <th>To value</th>
                            </tr>
                            <template v-if="!loading">
                                <template v-if="localActivityIsStageChange">
                                    <tr>
                                        <td class="pl-5 py-5 grey--text text--darken-3">
                                            <template v-if="item.stage_to && item.stage_to.id && item.stage_to.workspace">
                                                <span :title="item.stage_to.workspace.title">{{ item.stage_to.workspace.title | truncateText(20) }}</span>
                                            </template>
                                            <template v-else>
                                                <span :title="project_item.workspace.title">{{ project_item.workspace ? project_item.workspace.title : '' | truncateText(20) }}</span>
                                            </template>
                                        </td>
                                        <td class="py-5">
                                            <div v-if="item.stage_from" :title="item.stage_from.name">{{ item.stage_from.name | truncateText(20) }}</div>
                                            <div v-else-if="!item.stage_from && item.status_from" class="text-capitalize">{{ item.status_from }}</div>
                                            <template v-else>
                                                <a-icon size="16" color="grey">remove</a-icon>
                                            </template>
                                        </td>
                                        <td class="py-5 text-center">
                                            <a-icon size="16" color="grey">east</a-icon>
                                        </td>
                                        <td class="py-5 text-capitalize">
                                            <div v-if="item.stage_to" :title="item.stage_to.name">{{ item.stage_to.name | truncateText(20) }}</div>
                                            <div v-if="!item.stage_to && item.status_to">{{ item.status_to }}</div>
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <template v-for="(section, index) in item.modification_logs">
                                        <tr :key="index" class="font-weight-medium py-3" :class="[{'c-log-row': index !== item.length-1}]">
                                            <td class="px-5 py-3 grey--text text--darken-2">
                                                Section:
                                                <span class="grey--text text--darken-3" :title="local_section_name(section)">{{ local_section_name(section) | truncateText(85) }}</span>
                                            </td>
                                        </tr>
                                        <template v-for="(item, index) in section">
                                            <tr :key="item.id" :class="[{'c-log-row': index !== item.length-1}, 'grey--text text--darken-3']">
                                                <td class="px-5 py-4 grey--text text--darken-3">
                                                    <div class="u-flex align-center">
                                                        <a-icon size="18" color="grey" class="mr-2">{{ local_get_field_type_icon(item.field_type) }}</a-icon>
                                                        <span :title="item.field_name">{{ item.field_name | truncateText(30) }}</span>
                                                    </div>
                                                </td>
                                                <template v-if="item.field_type !== 'multi_select'">
                                                    <td class="py-4">
                                                        <template v-if="item.old_values && item.old_values.value">
                                                            <span v-if="item.field_type === 'score'">{{ local_get_modified_values('old_values', item) | truncateText(20) }}</span>
                                                            <template v-else>
                                                                <span
                                                                    :title="local_get_modified_values('old_values', item).label"
                                                                >
                                                                    {{ local_get_modified_values('old_values', item).label | truncateText(20) }}
                                                                </span>
                                                                <span> ({{ local_get_modified_values('old_values', item).value }})</span>
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            <a-icon size="16" color="grey">remove</a-icon>
                                                        </template>
                                                    </td>
                                                </template>
                                                <template v-else>
                                                    <td class="py-4">
                                                        <div class="">
                                                            <template v-if="item.old_values && item.old_values.value && item.old_values.value.length">
                                                                <div v-for="(select, valueIndex) in local_get_multiselect_value('old_values', item)" :key="select.id">
                                                                    <div :class="{'mb-2' : valueIndex !== local_get_multiselect_value('old_values', item, 'length') - 1}">
                                                                        <span :title="select.label">{{ select.label | truncateText(10) }}</span>
                                                                        <span class="grey--text text--darken-2"> ({{ select.value }})</span>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                <a-icon size="16" color="grey">remove</a-icon>
                                                            </template>
                                                        </div>
                                                    </td>
                                                </template>
                                                <td class="py-4 text-center">
                                                    <a-icon size="16" color="grey">east</a-icon>
                                                </td>
                                                <template v-if="item.field_type !== 'multi_select'">
                                                    <td class="py-4 pr-4">
                                                        <template v-if="item.new_values && item.new_values.value">
                                                            <span v-if="item.field_type === 'score'">{{ local_get_modified_values('new_values', item) | truncateText(20) }}</span>
                                                            <template v-else>
                                                                <span
                                                                    :title="local_get_modified_values('new_values', item).label"
                                                                >
                                                                    {{ local_get_modified_values('new_values', item).label | truncateText(20) }}
                                                                </span>
                                                                <span> ({{ local_get_modified_values('new_values', item).value }})</span>
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            <a-icon size="16" color="grey">remove</a-icon>
                                                        </template>
                                                    </td>
                                                </template>
                                                <template v-else>
                                                    <td class="py-4 pr-4">
                                                        <div class="">
                                                            <template v-if="item.new_values && item.new_values.value && item.new_values.value.length">
                                                                <div v-for="(select, valueIndex) in local_get_multiselect_value('new_values', item)" :key="select.id">
                                                                    <div :class="{'mb-2' : valueIndex !== local_get_multiselect_value('new_values', item, 'length') - 1}">
                                                                        <span :title="select.label">{{ select.label | truncateText(10) }}</span>
                                                                        <span class="grey--text text--darken-2"> ({{ select.value }})</span>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                <a-icon size="16" color="grey">remove</a-icon>
                                                            </template>
                                                        </div>
                                                    </td>
                                                </template>
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                                <template v-if="local_change_in_weightage && !localActivityIsStageChange">
                                    <tr>
                                        <td class="pl-5 py-5 deep-purple--text md-subtitle-2">
                                            <div class="u-flex align-center">
                                                <div class="mr-2">Total Confidence Score</div>
                                                <a-tooltip bottom nudge-right="14">
                                                    <template v-slot:activator="{ on }">
                                                        <a-icon v-on="on" color="blue lighten-1" class="u-cursor-pointer" size="16">info</a-icon>
                                                    </template>
                                                    <span>The confidence score total has been updated</span>
                                                </a-tooltip>
                                            </div>
                                        </td>
                                        <td class="py-5 grey--text text--darken-3 u-flex align-center">
                                            <div class="grey--text text--darken-3 u-flex align-center">
                                                <span>{{ item.old_values.current_score ? item.old_values.current_score : '-'  }}</span>
                                                <span class="px-1">/</span>
                                                <span>{{ item.old_values.total }}</span>
                                            </div>
                                            <div v-if="item.old_values.current_score" class="px-2">- <span style="font-weight: 600">{{ Math.round((item.old_values.current_score/item.old_values.total)* 100) }}%</span></div>
                                        </td>
                                        <td class="py-5 text-center">
                                            <a-icon size="16" color="grey">east</a-icon>
                                        </td>
                                        <td class="py-5 grey--text text--darken-3 u-flex align-center">
                                            <div class="grey--text text--darken-3 u-flex align-center">
                                                <span>{{ item.new_values.current_score ? item.new_values.current_score : '-' }}</span>
                                                <span class="px-1">/</span>
                                                <span>{{ item.new_values.total }}</span>
                                            </div>
                                            <div v-if="item.new_values.current_score" class="px-2">- <span style="font-weight: 600">{{ Math.round((item.new_values.current_score/item.new_values.total)* 100) }}%</span></div>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                            <template v-else>
                                <template v-for="n in local_activity_loader_count">
                                    <PartialActivityDetailLoader :key="n" :index="n"></PartialActivityDetailLoader>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </template>
            </div>
        </div>
    </a-container>
</template>

<script>
import PartialActivityDetailLoader from './PartialActivityDetailLoader.vue'
import { diffSimple } from '@/helpers/helper-date-filter.js'
import { mapState } from 'vuex'

export default {
    components: { PartialActivityDetailLoader },

    props: {
        item: {
            type: Object,
            required: true
        },

        activityType: {
            type: String,
            required: true
        },

        loading: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            local_field_type_list: [
                { type: 'score', icon: 'pin' },
                { type: 'dropdown', icon: 'arrow_drop_down_circle' },
                { type: 'multi_select', icon: 'check_box' },
            ],
        }
    },

    computed: {
        local_activity_loader_count () {
            if (this.item && !this.item.stage_to) return 5
            return 1
        },

        local_change_in_weightage () {
            if (this.item && (this.item.stage_to || this.item.status_to === 'archived')) return
            return (this.item.old_values.total !== this.item.new_values.total)
        },

        local_get_service_name () {
            if (this.project_item && this.project_item.salesforce_bridge) return this.project_item.salesforce_bridge.service
            return this.project_item.hubspot_bridge.service
        },

        localActivityIsStageChange () {
            return this.activityType === 'stage_change'
        },

        ...mapState('ProjectView', {
            project_item: 'item',
        }),

        ...mapState('ProjectActivities', {
            activity_item: 'item',
        }),
    },

    methods: {
        local_section_name (item) {
            if (item && item.length && item[0].section) return item[0].section.name
        },

        local_get_field_type_icon(type) {
            const custom_field_item = this.local_field_type_list.find(item => item.type === type)
            if (custom_field_item) return custom_field_item.icon
        },

        local_date_output (date) {
            return diffSimple(date, {type: 'includeYear'})
        },

        local_get_modified_values (type, item) {
            return item[type].value
        },

        local_get_multiselect_value (modify_type, field, type = null) {
            const value_list = _.cloneDeep(field[modify_type].value)
            if (type === 'length') return value_list.length

            return value_list
        },
    }
}
</script>

<style scoped>
.c-log-section {
    overflow-y: scroll !important;
}
table {
    border-collapse: collapse;
}
th {
    background-color: #F5F5F5;
    font-weight: 500;
    text-align: left;
    padding: 0.75rem 0;
}
td {
    vertical-align: top;
}
.c-log-row {
    border-bottom: 1px solid #EEEEEE;
}
</style>

<template>
    <div>
        <g-settings-breadcrumb previousRoute="settings"></g-settings-breadcrumb>

        <div class="text-center">
            <a-sheet class="transparent mx-auto" width="500" height="500">
                <img src="../../assets/images/sso-upgrad-banner.svg" class="d-block u-wfull u-hfull" alt="Plan Upgrade">
            </a-sheet>
            <h2 class="md-heading-4 font-weight-bold text-center">Success Inventory</h2>
            <p class="md-subtitle-1 text-center grey--text text--darken-2 mt-3 mx-auto" style="max-width: 480px;">Looks like your current plan doesn't support this option. Please upgrade your plan to access Inventory along with other additional features.</p>
            <a-btn @click="local_upgrade_plan()" color="yellow darken-3" class="pa-3 mt-6 font-weight-medium" dark depressed height="32">
                <a-icon class="mr-1" size="16" left>flash_on</a-icon> Upgrade
            </a-btn>
        </div>

        <!-- User Upgrade Popup -->
        <a-dialog max-width="400" v-model="dialog_user_upgrade_modal">
            <SUserUpgradeModal :isOpen="dialog_user_upgrade_modal" @close="dialog_user_upgrade_modal = false" />
        </a-dialog>

        <!-- Admin Upgrade Popup -->
        <a-dialog max-width="600" v-model="dialog_admin_upgrade_modal">
            <SAdminUpgradeModal
                :isOpen="dialog_admin_upgrade_modal"
                @upgrade="{}"
                @close="dialog_admin_upgrade_modal = false"
            />
        </a-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { SUserUpgradeModal, SAdminUpgradeModal } from '@/config/config-shared-components'

export default {
    components: {
        SUserUpgradeModal,
        SAdminUpgradeModal
    },

    data () {
        return {
            dialog_user_upgrade_modal: false,
            dialog_admin_upgrade_modal: false,
        }
    },

    computed: {
        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        local_upgrade_plan () {
            const { is_admin } = this.user_self || {}

            this.dialog_admin_upgrade_modal = false
            this.dialog_user_upgrade_modal = false

            if (is_admin === 1) this.dialog_admin_upgrade_modal = true
            else this.dialog_user_upgrade_modal = true
        },
    }
}
</script>

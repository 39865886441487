<template>
    <!-- <a-sheet class="u-flex flex-column" color="#F0EFF5" height="100vh"> -->
    <a-sheet class="u-flex flex-column" color="#f1f1f1" height="100vh">
        <a-sheet color="transparent" v-if="emailLayout" height="calc(100vh - 90px)" class="d-flex align-center justify-center text-center">
            <a-sheet color="transparent" min-width="250" max-width="700" class="c-login u-relative">
                <div class="text-center mb-8">
                    <img v-if="orgLogo" :src="orgLogo" style="max-height: 72px;max-width: 250px" class="d-inline-block">
                    <img v-if="orgLogo === null" src="../assets/images/success-imgs/logo-success-full.svg" style="max-height: 32px;max-width: 256px" class="d-inline-block">
                </div>
                <template v-if="auth_organization && !auth_organization.sso_provider || show_email_login">
                    <div class="">
                        <a-sheet class="c-login__input py-2 px-6 u-rounded-corners u-shadow mb-4" min-height="42">
                            <form @submit.prevent="methodLogin">
                                <input
                                    v-model="data.email"
                                    placeholder="ENTER LOGIN EMAIL ADDRESS"
                                    class="c-input-email grey--text text--darken-3 text-body-2 u-rounded-corners--lg u-wfull"
                                    style="outline: none"
                                    autofocus
                                >
                            </form>
                        </a-sheet>
                        <a-btn class="c-login__button body-2 u-elevation-custom-1 u-wfull u-rounded-corners--lg font-weight-medium px-6 grey darken-4 grey--text text--lighten-3" :loading="local_loading" @click="methodLogin" min-height="42">Login</a-btn>
                    </div>
                    <div class="u-absolute mt-4 u-wfull">
                        <!-- {{ auth_response }} -->
                        <v-slide-y-transition>
                            <a-alert dense color="#f24e51" dark text border="left" v-if="auth_response && ($response(auth_response, 'email') || auth_response.status === 'error')">
                                <span style="font-size: 14px !important;" class="d-block text-center">{{ $response(auth_response, 'email') ? $response(auth_response, 'email') : (auth_response.server ? auth_response.server.message : '') }}</span>
                            </a-alert>
                            <a-alert dense color="#f24e51" dark text class="text-left" border="left" v-if="auth_response.text && auth_response.text === 404">
                                <span style="font-size: 14px !important;" class="md-caption d-block text-center">The email entered is invalid.</span>
                            </a-alert>
                        </v-slide-y-transition>
                    </div>
                </template>
                <template v-if="auth_organization && auth_organization.sso_provider && !show_email_login">
                    <a-card v-ripple="{ class: 'red--text' }" v-if="auth_organization && (auth_organization.sso_provider === 'GoogleOAuth' || auth_organization.sso_provider === 'GoogleSAML')" :href="local_sso_url" min-height="42" class="u-rounded-corners u-elevation-custom-1 white pa-2 u-cursor-pointer" style="display: flex; align-items: center; justify-content: center;">
                        <img src="../assets/images/sso/google-icon.svg" style="max-height: 16px;" alt="Login using Google account" class="d-inline-block mr-3">
                        <div class="shrink">
                            <span class="text-caption d-block text-uppercase font-weight-medium grey--text text--darken-3">Login using Google</span>
                        </div>
                    </a-card>
                    <a-card v-ripple="{ class: 'blue--text' }" v-else-if="auth_organization && auth_organization.sso_provider === 'OktaSAML'" :href="local_sso_url" min-height="42" class="u-rounded-corners u-elevation-custom-1 white pa-2 u-cursor-pointer" style="display: flex; align-items: center; justify-content: center;">
                        <div><img src="../assets/images/sso/okta-icon.png" style="max-height: 26px;" alt="Login using Okta account" class="d-block mr-3"></div>
                        <div class="shrink">
                            <span class="text-caption d-block text-uppercase font-weight-medium grey--text text--darken-3">Login using Okta</span>
                        </div>
                    </a-card>
                    <a-card v-ripple="{ class: 'blue--text' }" v-else-if="auth_organization && auth_organization.sso_provider === 'ADFSSAML'" :href="local_sso_url" min-height="42" class="u-rounded-corners u-elevation-custom-1 white pa-2 u-cursor-pointer" style="display: flex; align-items: center; justify-content: center;">
                        <div><img src="../assets/images/sso/microsoft-icon.svg" style="max-height: 20px;" alt="Login using Microsoft account" class="d-block mr-3"></div>
                        <div class="shrink">
                            <span class="text-caption d-block text-uppercase font-weight-medium grey--text text--darken-3">Login using Microsoft</span>
                        </div>
                    </a-card>
                    <a-card v-ripple="{ class: 'blue--text' }" v-else-if="auth_organization && auth_organization.sso_provider === 'AzureSAML'" :href="local_sso_url" min-height="42" class="u-rounded-corners u-elevation-custom-1 white pa-2 u-cursor-pointer" style="display: flex; align-items: center; justify-content: center;">
                        <div><img src="../assets/images/sso/azure-icon.svg" style="max-height: 24px;" alt="Login using Microsoft account" class="d-block mr-3"></div>
                        <div class="shrink">
                            <span class="text-caption d-block text-uppercase font-weight-medium grey--text text--darken-3">Login using Azure</span>
                        </div>
                    </a-card>
                    <a-card v-ripple="{ class: 'blue--text' }" v-else-if="auth_organization && auth_organization.sso_provider === 'OneLoginSAML'" :href="local_sso_url" min-height="42" class="u-rounded-corners u-elevation-custom-1 white pa-2 u-cursor-pointer" style="display: flex; align-items: center; justify-content: center;">
                        <div><img src="../assets/images/sso/one-login-icon.svg" style="max-height: 24px;" alt="Login using Microsoft account" class="d-block mr-3"></div>
                        <div class="shrink">
                            <span class="text-caption d-block text-uppercase font-weight-medium grey--text text--darken-3">Login using OneLogin</span>
                        </div>
                    </a-card>
                    <a-card v-ripple="{ class: 'blue--text' }" v-else-if="auth_organization && auth_organization.sso_provider === 'SalesforceSAML'" :href="local_sso_url" min-height="42" class="u-rounded-corners u-elevation-custom-1 white pa-2 u-cursor-pointer" style="display: flex; align-items: center; justify-content: center;">
                        <div><img src="../assets/images/sso/salesforce-icon.svg" style="max-height: 20px;" alt="Login using Microsoft account" class="d-block mr-3"></div>
                        <div class="shrink">
                            <span class="text-caption d-block text-uppercase font-weight-medium grey--text text--darken-3">Login using Salesforce</span>
                        </div>
                    </a-card>
                    <a-card v-ripple="{ class: 'blue--text' }" v-else-if="auth_organization && auth_organization.sso_provider === 'VMwareSAML'" :href="local_sso_url" min-height="42" class="u-rounded-corners u-elevation-custom-1 white pa-2 u-cursor-pointer" style="display: flex; align-items: center; justify-content: center;">
                        <div><img src="../assets/images/sso/vmware-icon.svg" style="max-height: 26px;" alt="Login using Microsoft account" class="d-block mr-3"></div>
                        <div class="shrink">
                            <span class="text-caption d-block text-uppercase font-weight-medium grey--text text--darken-3">Login using VMWare</span>
                        </div>
                    </a-card>
                    <a-card v-ripple="{ class: 'blue--text' }" v-else :href="local_sso_url" min-height="42" class="u-rounded-corners u-elevation-custom-1 white pa-2 u-flex align-center u-cursor-pointer" style="display: flex; align-items: center; justify-content: center;">
                        <a-icon size="20" class="mr-3">vpn_key</a-icon>
                        <div class="shrink">
                            <span class="text-caption d-block text-uppercase font-weight-medium grey--text text--darken-3">Login using SSO</span>
                        </div>
                    </a-card>
                    <a-sheet @click="show_email_login = true" min-height="42" class="u-rounded-corners transparent pa-2 u-cursor-pointer mt-4" style="display: flex; align-items: center; justify-content: center;">
                        <div class="shrink">
                            <span class="text-caption d-block text-uppercase font-weight-medium grey--text text--darken-1">Login using Email</span>
                        </div>
                    </a-sheet>
                </template>
                <template v-if="!auth_organization && !local_loading">
                    <a-sheet min-height="42" class="u-rounded-corners transparent pa-2 mt-4" style="display: flex; align-items: center; justify-content: center;">
                        <div class="shrink">
                            <a-icon size="42" color="red darken-1">warning</a-icon>
                            <span class="md-subtitle-1 d-block text-uppercase font-weight-medium grey--text text--darken-1 mt-2">Organization Not Found</span>
                            <span class="md-body-2 d-block grey--text text--darken-3">Please re-check the URL or contact your administrator.</span>
                        </div>
                    </a-sheet>
                </template>
            </a-sheet>
        </a-sheet>
        <s-footer :logo="orgLogo" />
    </a-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SFooter } from '@/config/config-shared-components'

export default {
    components: {
        SFooter
    },

    data () {
        return {
            data: {
                email: '',
            },
            emailLayout: true,
            orgLogo: false,
            local_loading: true,
            show_email_login: false,
            sso_url: process.env.VUE_APP_CORE_API_URL + '/' + window.location.hostname.split('.')[0] + '/auth/sso/initiate'
        }
    },

    async mounted () {
        this.local_index()
    },

    computed: {
        local_sso_url () {
            const redirectPath = this.$route.query.redirect ? '?redirect=' + this.$route.query.redirect : ''
            return this.sso_url + redirectPath
        },

        ...mapGetters('Auth',{
            auth_response: 'response',
            auth_organization: 'organization',
        }),

        ...mapGetters('SSO',{
            sso_list: 'list',
        }),
    },

    methods: {
        async local_index () {
            await this.auth_get_organization()
            this.orgLogo = this.auth_organization ? this.auth_organization.logo : null
            this.local_loading = false
        },

        async methodLogin () {
            this.local_loading = true
            await this.auth_clear()
            await this.auth_login({ ...this.data, redirect: this.$route.query.redirect })
            if (this.auth_response.status === 'success') {
                this.$emit('loggedIn', true)
                return this.local_loading = false
            }
            this.local_loading = false
        },

        async local_sso_login () {
            // await this.sso_auth({ domain: this.auth_organization.domain })
        },

        ...mapActions('SSO',{
            sso_index: 'index',
            sso_auth: 'auth',
        }),

        ...mapActions('Auth',{
            auth_login: 'login',
            auth_validate: 'validate',
            auth_get_organization: 'getOrganization',
            auth_clear: 'clear',
        })
    }
}
</script>

<style lang="scss" scoped>
.c-input-email::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #b8b8b8;
    font-size: 12px;
    font-weight: 500;
}
.c-input-email::-moz-placeholder { /* Firefox 19+ */
    color: #b8b8b8;
    font-size: 12px;
    font-weight: 500;
}
.c-input-email::-ms-input-placeholder { /* IE 10+ */
    color: #b8b8b8;
    font-size: 12px;
    font-weight: 500;
}
.c-input-email::-moz-placeholder { /* Firefox 18- */
    color: #b8b8b8;
    font-size: 12px;
    font-weight: 500;
}
</style>

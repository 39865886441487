<template>
    <a-main>
        <template v-if="isLoading">
          <PValidationWorkflowConditionsLoader/>
        </template>
        <template v-if="!isLoading">
            <div class="u-flex pa-6">
                <div class="c-condition-icon-view">
                    <div class="align-center justify-center u-flex c-condition-menu-icon blue accent-4" style="border: 2px solid #BBDEFB !important">
                        <a-icon color="white" size="20">tune</a-icon>
                    </div>
                </div>
                <div class="ml-3">
                    <p class="md-subtitle-1 text-uppercase indigo--text text--darken-1 mb-0 font-weight-bold">If: Conditions</p>
                    <p class="md-body-2 grey--text text--darken-1 mb-0">All conditions must be fulfilled for a validation to take place.</p>
                </div>
            </div>
            <a-divider class="grey lighten-3"></a-divider>
            <div class="pa-6">
                <div v-if="isError" class="mb-6">
                    <PValidationWorkflowWarningInfo
                        :errorMessage="errorMessage"
                    />
                </div>
                <template v-if="conditionList && conditionList.length">
                    <div v-for="(item, group_index) in conditionList" :key="item.group_id">
                        <a-sheet v-if="group_index !== 0" height="1" class="u-wfull u-relative my-7 c-condition-or-shreet">
                            <span class="u-absolute md-body-2 font-weight-medium body--text text--lighten-2 d-inline-block c-condition-or-card">OR</span>
                        </a-sheet>
                        <div v-for="(condition, condition_index) in item.conditions" :key="condition.id">
                            <a-layout align-start>
                                <a-flex shrink>
                                    <div class="align-center justify-center u-flex">
                                        <p v-if="condition_index === 0" class="u-font-semibold md-subtitle-1 deep-purple--text text--darken-1 mb-0 mt-3 font-weight-bold">IF</p>
                                        <p v-else class="u-font-semibold md-subtitle-1 deep-purple--text text--darken-1 mb-0 mt-3 font-weight-bold">&</p>
                                    </div>
                                </a-flex>
                                <a-flex shrink xs4 xl4>
                                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(condition, 'field_name')">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <v-autocomplete
                                                    v-model="condition.field_name"
                                                    :items="conditionEntityList"
                                                    item-text="label"
                                                    item-value="name"
                                                    placeholder="Select Field"
                                                    class="pa-0 u-rounded-corners-lg"
                                                    :class="[!condition.field_name && localIsCheckErrors(condition, 'field_name') ? 'c-wiggle-short u-border-error' : ' u-border']"
                                                    :disabled="!canUpdate"
                                                    item-color="indigo darken-2"
                                                    @change="$emit('update-source', condition)"
                                                    background-color="grey lighten-5"
                                                    key="property-field"
                                                    solo flat hide-details
                                                >
                                                    <template #item="{ item }">
                                                        <div class="u-flex-center-y">
                                                            <span class="md-subtitle-2 grey--text text--darken-1">{{ item.label }}</span>
                                                        </div>
                                                    </template>
                                                    <template #prepend-inner>
                                                        <a-icon size="18" color="grey darken-2" class="mr-1">{{ localGetIcon(condition.field_name) }}</a-icon>
                                                    </template>
                                                </v-autocomplete>
                                            </div>
                                        </template>
                                        <span>{{ localGetMessageErrors(condition, 'field_name') }}</span>
                                    </a-tooltip>
                                </a-flex>
                                <a-flex shrink xs4 xl4>
                                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(condition, 'operator') && !hasCustomFieldTextInfoCheck(condition)">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <v-autocomplete
                                                    v-model="condition.operator"
                                                    :items="localSelectedOpertors(condition.field_name)"
                                                    item-text="label"
                                                    item-value="key"
                                                    placeholder="Choose the operator"
                                                    :disabled="!canUpdate"
                                                    class="pa-0 u-rounded-corners-lg"
                                                    :class="[
                                                        hasCustomFieldTextInfoCheck(condition) ? 'u-border-disable' : 'u-border',
                                                        { 'c-wiggle-short u-border-error' :  !condition.operator && localIsCheckErrors(condition, 'operator') },
                                                    ]"
                                                    item-color="indigo darken-2"
                                                    background-color="grey lighten-5"
                                                    @change="$emit('change-operator', condition)"
                                                    solo flat hide-details
                                                >
                                                    <template #item="{ item }">
                                                        <div class="u-flex-center-y">
                                                            <span class="md-subtitle-2 grey--text text--darken-1">{{ item.label }}</span>
                                                        </div>
                                                    </template>
                                                    <template #append>
                                                        <a-icon v-if="hasCustomFieldTextInfoCheck(condition)" size="20" color="amber darken-3" class="mr-2">info</a-icon>
                                                    </template>
                                                </v-autocomplete>
                                            </div>
                                        </template>
                                        <span>{{ hasCustomFieldTextInfoCheck(condition) ? customFieldTextInfo : localGetMessageErrors(condition, 'operator') }}</span>
                                    </a-tooltip>
                                </a-flex>
                                <a-flex shrink xs4 xl4>
                                    <template v-if="noRequiredRules.includes(condition.operator)">
                                        <a-sheet class="grey lighten-4 body--text text--lighten-3 px-3 u-flex-center-y u-rounded-corners-lg u-border" height="48">
                                            No value required
                                        </a-sheet>
                                    </template>
                                    <template v-else>
                                        <template v-if="localSelectedFieldType(condition.field_name) === 'date'">
                                            <a-layout align-start pa-0>
                                                <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(condition, 'value')">
                                                    <template v-slot:activator="{ on }">
                                                        <a-flex v-on="on" shrink xs12 xl12>
                                                            <a-sheet class="u-wfull u-rounded-corners-lg u-flex-center-y u-relative pr-2 mr-2" :class="[localIsCheckErrors(condition, 'value') ? 'c-wiggle-short u-border-error' : 'u-border']" style="background: #FAFAFA;">
                                                                <vc-date-picker v-if="canUpdate" v-model="condition.value" @input="$emit('change-value')" class="d-block u-wfull"  :max-date="condition.value_alt" :model-config="modelConfig" :is-required="!!(condition.value)">
                                                                    <template v-slot="{ togglePopover }">
                                                                        <div class="u-wfull u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="togglePopover({ placement: 'bottom-start' })">
                                                                            <div class="md-body-2 grey--text pl-3 u-flex-center-y" :class="[condition.value ? 'text--darken-4' : 'text--darken-5']" style="font-size: 16px;min-height: 48px !important;">
                                                                                <span>{{ condition.value ? localFormatDate(condition.value) : 'Value' }}</span>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </vc-date-picker>
                                                                <div v-else class="u-wfull u-flex align-stretch u-rounded-corners-lg grey--text text--darken-2" style="opacity: 0.5;">
                                                                    <div class="md-body-2 grey--text pl-3 u-flex-center-y" :class="[condition.value ? 'text--darken-4' : 'text--darken-5']" style="font-size: 16px; min-height: 48px !important;">
                                                                        <span>{{ condition.value ? localFormatDate(condition.value) : 'Value' }}</span>
                                                                    </div>
                                                                </div>  
                                                            </a-sheet>
                                                        </a-flex>
                                                    </template>
                                                    <span>{{ localGetMessageErrors(condition, 'value') }}</span>
                                                </a-tooltip>
                                                <a-tooltip bottom v-if="condition.operator === 'between'" content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(condition, 'value_alt')">
                                                    <template v-slot:activator="{ on }">
                                                        <a-flex v-on="on" shrink xs12 xl12>
                                                            <a-sheet class="u-wfull u-rounded-corners-lg u-flex-center-y u-relative pr-2 mr-2" :class="[localIsCheckErrors(condition, 'value_alt') ? 'c-wiggle-short u-border-error' : 'u-border']" style="background: #FAFAFA;">
                                                                <vc-date-picker v-if="canUpdate" v-model="condition.value_alt" @input="$emit('change-value')" class="d-block u-wfull" :min-date="condition.value" :model-config="modelConfig" :is-required="!!(condition.value_alt)">
                                                                    <template v-slot="{ togglePopover }">
                                                                        <div  class="u-wfull u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="togglePopover({ placement: 'bottom-start' })">
                                                                            <div class="md-body-2 grey--text pl-3 u-flex-center-y" :class="[condition.value_alt ? 'text--darken-4' : 'text--darken-5']" style="font-size: 16px;min-height: 48px !important;">
                                                                                <span>{{ condition.value_alt ? localFormatDate(condition.value_alt) : 'Value' }}</span>
                                                                            </div>
                                                                        </div> 
                                                                    </template>
                                                                </vc-date-picker>
                                                                <div v-else class="u-wfull u-flex align-stretch u-rounded-corners-lg grey--text text--darken-2" style="opacity: 0.5;">
                                                                    <div class="md-body-2 grey--text pl-3 u-flex-center-y" :class="[condition.value_alt ? 'text--darken-4' : 'text--darken-5']" style="font-size: 16px; min-height: 48px !important;">
                                                                        <span>{{ condition.value_alt ? localFormatDate(condition.value_alt) : 'Value' }}</span>
                                                                    </div>
                                                                </div>  
                                                            </a-sheet>
                                                        </a-flex>
                                                    </template>
                                                    <span>{{ localGetMessageErrors(condition, 'value_alt') }}</span>
                                                </a-tooltip>
                                            </a-layout>
                                        </template>
                                        <a-tooltip v-else bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(condition, 'value')">
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on">
                                                    <template v-if="['string', 'url', 'number', null].includes(localSelectedFieldType(condition.field_name))">
                                                        <a-text-field
                                                            v-model="condition.value"
                                                            placeholder="Enter the value"
                                                            background-color="grey lighten-5"
                                                            :disabled="!canUpdate"
                                                            @input="$emit('change-value')"
                                                            class="pa-0 u-rounded-corners-lg"
                                                            :class="[localIsCheckErrors(condition, 'value') ? 'c-wiggle-short u-border-error' : 'u-border']"
                                                            @keypress="localIsNumber($event, condition.operator, condition.field_name)"
                                                            solo flat hide-details
                                                        >
                                                        </a-text-field>
                                                    </template>
                                                    <template v-if="localSelectedFieldType(condition.field_name) === 'select' || localSelectedFieldType(condition.field_name) === 'multi-select'">
                                                        <v-autocomplete
                                                            v-model="condition.value"
                                                            :items="localGetConditionsValueList(condition.field_name)"
                                                            item-text="name"
                                                            item-value="id"
                                                            placeholder="Select Value"
                                                            background-color="grey lighten-5"
                                                            class="pa-0 u-rounded-corners-lg"
                                                            :disabled="!canUpdate"
                                                            @change="$emit('change-value')"
                                                            :class="[!condition.value && localIsCheckErrors(condition, 'value') ? 'c-wiggle-short u-border-error' : ' u-border']"
                                                            item-color="indigo darken-2"
                                                            key="value-dropdown-field"
                                                            solo flat hide-details
                                                        >
                                                            <template #item="{ item }">
                                                                <template v-if="item.type === 'competitor'">
                                                                    <span class="md-caption d-inline-block py-1 px-2 mr-2 mt-1 mb-1 u-rounded-corners u-cursor-pointer" :style="{backgroundColor: '#2196f3', color: '#fff'}">
                                                                        {{ item.name | truncateText(70) }}
                                                                    </span>
                                                                </template>
                                                                <template v-else-if="item.initial && item.role_id">
                                                                    <div class="u-flex-center-y">
                                                                        <g-avatar :item="item" :size="32"></g-avatar>
                                                                        <g-profile-info :item="item" set-max-width type-user></g-profile-info>
                                                                    </div>
                                                                </template>
                                                                <template v-else-if="item.type === 'tags' || item.type === 'territory'">
                                                                    <g-tags :text-range="20" :tag="item" :key="item.id" hide-clear-icon></g-tags>
                                                                </template>
                                                                <span :title="item.name" v-else>{{ item.name | truncateText(70) }}</span>
                                                            </template>
                                                            <template #selection="{ item }">
                                                                <template v-if="item.type === 'competitor'">
                                                                    <span class="md-caption d-inline-block py-1 px-2 mr-2 mt-1 mb-1 u-rounded-corners u-cursor-pointer" :style="{backgroundColor: '#2196f3', color: '#fff'}">
                                                                        {{ item.name | truncateText(70) }}
                                                                    </span>
                                                                </template>
                                                                <template v-else-if="item.initial && item.role_id">
                                                                    <span class="d-inline-flex align-center u-rounded-corners grey lighten-2 pl-3 pr-2 py-1 mr-1 my-2">
                                                                        <span class="mr-2 md-body-2 grey--text text--darken-4" :title="item.name">{{ item.name | truncateText(20) }}</span>
                                                                    </span>
                                                                </template>
                                                                <template v-else-if="item.type === 'tags' || item.type === 'territory'">
                                                                    <g-tags :text-range="20" :tag="item" :key="item.id" hide-clear-icon></g-tags>
                                                                </template>
                                                                <span :title="item.name" v-else>{{ item.name | truncateText(70) }}</span>
                                                            </template>
                                                        </v-autocomplete>
                                                    </template>
                                                </div>
                                            </template>
                                            <span>{{ localGetMessageErrors(condition, 'value') }}</span>
                                        </a-tooltip>
                                    </template>
                                </a-flex>
                                <a-flex shrink class="align-center justify-center" v-if="canUpdate">
                                    <a-tooltip bottom v-if="conditionDeletedId === condition.id">
                                        <template v-slot:activator="{ on }">
                                            <a-icon size="16" color="red darken-1" class="mt-4" v-on="on" @click="localRemoveCondition(condition, 'row')">cancel</a-icon>
                                        </template>
                                        <span>Confirm Delete?</span>
                                    </a-tooltip>
                                    <a-icon size="16" color="grey darken-1" v-else class="mt-4" @click="localConfirmConditionsRemove(condition.id)">cancel</a-icon>
                                </a-flex>
                            </a-layout>
                        </div>
                        <a-layout align-start class="mt-2" v-if="canUpdate">
                            <a-flex shrink>
                                <a-btn depressed small rounded class="ml-9 u-flex-center deep-purple darken-1 white--text" @click="$emit('add-group', item.group_id)">
                                    <a-icon size="16" color="white" class="u-icon-nudge-xs mr-1">add</a-icon>
                                    <span>Add Condition</span>
                                </a-btn>                        
                            </a-flex>
                            <a-spacer></a-spacer>
                            <a-flex shrink pr-0>
                                <a-btn depressed small rounded class="u-flex-center primary lighten-1 white--text" @click="$emit('add-group', 'new', group_index)">
                                    <a-icon size="16" color="white" class="u-icon-nudge-xs mr-1">add</a-icon>
                                    <span>Add Condition Set</span>
                                </a-btn>
                            </a-flex>
                            <a-flex shrink>
                                <a-tooltip bottom v-if="conditionDeletedId === item.group_id">
                                    <template v-slot:activator="{ on }">
                                        <a-icon size="16" color="red darken-1" class="ml-3" v-on="on" @click="localRemoveCondition(item, 'group')">delete</a-icon>
                                    </template>
                                    <span>Confirm Delete?</span>
                                </a-tooltip>
                                <a-icon size="16" color="grey darken-1" v-else class="ml-3" @click="localConfirmConditionsRemove(item.group_id, 'group')">delete</a-icon>
                            </a-flex>
                        </a-layout>
                    </div>
                </template>
            </div>
        </template>
    </a-main>
</template>

<script>
import { mapState } from 'vuex'
import PValidationWorkflowWarningInfo from './PartialValidationWorkflowWarningInfo'
import PValidationWorkflowConditionsLoader from './PartialValidationWorkflowConditionsLoader'

export default {
    components: {
        PValidationWorkflowWarningInfo,
        PValidationWorkflowConditionsLoader,
    },
    props: {
        conditionList: {
            type: Array,
            default: [],
        },
        customTagList: {
            type: Array,
            default: [],
        },
        systemTagList: {
            type: Array,
            default: [],
        },
        conditionEntityList: {
            type: Array,
            default: [],
        },
        isLoading:{
            type: Boolean,
            default: true
        },
        isError: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        hasCustomFieldTextInfoCheck: {
            type: Function,
            default: () => {}
        },
    },
    data() {
        return {   
            valueFieldMaxLength: -1,
            valueAltFieldMaxLength: -1,
            conditionDeletedId: null,
            conditionDeleteTimeout: null,
            errorMessage: 'There is an issue with the conditions. Please reconfigure it.',
            customFieldTextInfo: 'This operator will be deprecated for use with text field. It is recommended to use with number field.',
            accordionValueDate: [],
            accordionValueAtDate: [],
            iconTypes: [
                { type: 'string', icon: 'text_fields'},
                { type: 'number', icon: 'pin'},
                { type: 'date', icon: 'calendar_today'},
                { type: 'select', icon: 'arrow_drop_down_circle'},
                { type: 'multi-select', icon: 'arrow_drop_down_circle'},
                { type: 'url', icon: 'link'},
            ],
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
            attributes: {
                popover: {
                    visibility: 'hover',
                    isInteractive: true,
                },
            },
            numberRules: [
                'number',
                'greater-than',
                'greater-than-or-equal',
                'less-than',
                'less-than-or-equal',
                'maximum',
                'minimum',
                'maximum-characters',
                'minimum-characters',
            ],
            noRequiredRules: ['required','numeric','string','boolean','empty','not-empty','string','url','email'],
        }
    },

    computed: {
        localLabelTagList () {
            return this.systemTagList.filter(({ type }) => type === 'label')
        },

        localProductTagList () {
            return this.systemTagList.filter(({ type }) => type === 'product')
        },

        localPlatformTagList () {
            return this.systemTagList.filter(({ type }) => type === 'platform')
        },

        ...mapState('User', {
            user_list: 'list',
        }),

        ...mapState('Competitor', {
            competitor_list: 'list',
            competitor_response: 'response',
        }),

        ...mapState('Stage', {
            stage_list: 'list',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Customer', {
            customer_list: 'list',
            customer_response: 'response',
        }),

        ...mapState('Partner', {
            partner_list: 'list',
            customer_response: 'response',
        }),

        ...mapState('Territory', {
            territory_list: 'list',
        }),

        ...mapState('TagSection', {
            tag_section_list: 'list',
        }),

        ...mapState('CustomField', {
            custom_field_list: 'list',
        }),
    },

    methods: {
        localGetConditionsValueList (field_name) {
            const related_type = this.localRelatedTypeFilter(field_name)
            
            switch (related_type) {
                case 'project_health': return this.localGetFieldvalues(field_name)
                case 'workspace_id' : return this.localGetFieldvalues(field_name)
                case 'result_id' : return this.localGetFieldvalues(field_name)
                case 'project_stage_id' : return this.localGetFieldvalues(field_name)
                case 'project_status_id' : return this.localGetFieldvalues(field_name)
                case 'custom_tag': return this.localCustomTagFilter(field_name).map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' }))
                case 'project_label': return this.localLabelTagList.map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' }))
                case 'project_product': return this.localProductTagList.map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' }))
                case 'project_platform': return this.localPlatformTagList.map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' }))
                case 'custom_field': return this.localGetFieldvalues(field_name)
                case 'competitors': return this.competitor_list.map(item => ({ id: item.id, name: item.name, type: 'competitor' }))
                case 'customer_id': return this.customer_list
                case 'collaborators': return this.user_list
                case 'partner_id': return this.partner_list
                case 'territories': return this.territory_list.map(item => ({ id: item.id, name: item.name, type: 'territory', color: item.color }))
                default: return []
            }
        },

        localCustomTagFilter (tag_slug) {
            return this.customTagList.filter(item => item.type === tag_slug)
        },

        localRelatedTypeFilter(field_name) {
            const related = this.conditionEntityList.find(item => item.name === field_name)
            return related && related.related_type ? related.related_type : null
        },

        localGetFieldvalues(field_name) {
            const fields = this.conditionEntityList.find(item => item.name === field_name)
            return fields && fields.picklistValues ? fields.picklistValues : []
        },

        localConfirmConditionsRemove (id) {
            clearTimeout(this.conditionDeleteTimeout)
            this.conditionDeletedId = id
            this.conditionDeleteTimeout = setTimeout(() => this.conditionDeletedId = null, 5000)
        },

        localSelectedOpertors (field_name) {
            const opertors = this.conditionEntityList.find(item => item.name === field_name)
            return opertors ? opertors.operators : []
        },

        localGetIcon (field_name) {
            if(!field_name) return null
            if (field_name === 'deal_amount') return 'monetization_on'
            const type = this.localSelectedFieldType(field_name)
            const item = this.iconTypes.find(item => item.type === type)
            return item ? item.icon : null
        },

        localSelectedFieldType (field_name) {
            const item = this.conditionEntityList.find(item => item.name === field_name)
            return item ? item.type : null
        },

        localGetMessageErrors (condition, field_name) {
            if (this.hasCustomFieldTextInfoCheck(condition) === true) return this.customFieldTextInfo
            if (!condition.warning || !field_name) return null
            return condition.warning[field_name] && condition.warning[field_name][0] ? condition.warning[field_name][0] : ''
        },

        localIsCheckErrors (condition, field_name) {
            if (!condition.warning || !field_name) return null
            return condition.warning[field_name] && condition.warning[field_name][0] ? true : false
        },

        localFormatDate (date) {
            return date ? moment(date).format('MMM D, YYYY') : null
        },

        localAccordionDateToggle (index, type = null) {
            if (type === 'value') {
                if (this.accordionValueDate[index] === undefined || this.accordionValueDate[index] === null) this.$set(this.accordionValueDate, index, true);
                else this.$set(this.accordionValueDate, index, !this.accordionValueDate[index]);
            }
            if (type === 'value_at') {
                if (this.accordionValueAtDate[index] === undefined || this.accordionValueAtDate[index] === null) this.$set(this.accordionValueAtDate, index, true);
                else this.$set(this.accordionValueAtDate, index, !this.accordionValueAtDate[index]);
            }
            this.$emit('change-value')
        },

        localRemoveCondition (condition, type) {
            this.$emit('remove-condition', condition, type)
            this.conditionDeletedId = null
        },

        localIsNumber (evt, type, field_name = null) {
            const fieldType = this.localSelectedFieldType(field_name) === 'number' ? 'number' : type;
            if (!this.numberRules.includes(fieldType)) return
            const charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            }
        },
    },
}
</script>

<style scoped>
.c-condition-icon-view {
    width: 48px !important;
}
.c-condition-menu-icon {
    box-sizing: border-box;
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 8px !important;
}
.c-condition-or-shreet {
    border-radius: 1px;
    background: radial-gradient(50% 50.00% at 50% 50.00%, #616161 0%, rgba(97, 97, 97, 0.08) 100%);
}
.c-condition-or-card {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 4px;
    background: #FFF;
    border: 1px solid #BDBDBD;
    font-size: 12px;
    font-weight: 600;
    color: #616161;
    width: 33px;
    height: 24px;
    padding: 2px 7px;
}
</style>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('Role', {
            role_external_permission: 'external_permission',
        }),
    },

    methods: {
        permission_mixin (type, project_type) {
            const isTypeIndex = type.split('.')[1] === 'index'
            const { permissions } = this.role_external_permission
            return this.$can(type, permissions) &&
                    this.$can(project_type ?? `${ isTypeIndex ? 'projects.index' : 'projects.update-only' }`, permissions) &&
                    this.$can(type) &&
                    this.$can(project_type ?? `${ isTypeIndex ? 'projects.index' : 'projects.update-only' }`)
        },

        can_external_mixin (type) {
            const { permissions } = this.role_external_permission
            if (permissions) {
                if (type.split('.')[1] === 'index') return this.$can(type, permissions) && this.$can('projects.index', permissions)
                return this.$can(type, permissions) && this.$can('projects.update-only', permissions)
            }
        },

        can_internal_mixin (type) {
            if (type.split('.')[1] === 'index') return this.$can(type) && this.$can('projects.index')
            return this.$can(type) && this.$can('projects.update-only')
        },

        has_permission_mixin (type) {
            const { permissions } = this.role_external_permission
            return this.$can(type, permissions)
        },   
    }
}

<template>
	<a-main>
        <a-container grid-list-xl container--fluid pa-0 mx-0>
            <g-page-header
                title="Insights"
                subtitle="View statistics of currently active projects."
                icon="assessment"
            />

            <a-layout wrap align-start v-if="insight_meta">
                <a-flex xs6 pt-0>
                    <a-layout wrap align-start>
                        <a-flex xs6>
                            <div class="c-project-card u-border px-4 py-3 my-0">
                                <a-layout wrap align-center>
                                    <a-flex xs12 class="pb-0">
                                        <h2 class="display-1 font-weight-light mb-1 text-center" v-if="insight_meta.lifetime_count >= 0"> {{ insight_meta.lifetime_count }}d</h2>
                                        <h2 class="display-1 font-weight-medium grey--text text--lighten-1 mb-1 text-center" v-else>...</h2>
                                    </a-flex>
                                    <a-flex xs12 class="pt-0">
                                        <p class="body-1 mb-0 grey--text text--darken-1 text-center"><a-icon size="16" color="grey darken-1" style="position: relative; top: -3px;">redo</a-icon> Lifetime</p>
                                    </a-flex>
                                </a-layout>
                            </div>
                        </a-flex>
                        <a-flex xs6>
                            <div class="c-project-card u-border px-4 py-3 my-0">
                                <a-layout wrap align-center>
                                    <a-flex xs12 class="pb-0">
                                        <h2 class="display-1 font-weight-light mb-1 text-center" v-if="insight_meta.projects_count >= 0"> {{ insight_meta.projects_count }}</h2>
                                        <h2 class="display-1 font-weight-medium grey--text text--lighten-1 mb-1 text-center" v-else>...</h2>
                                    </a-flex>
                                    <a-flex xs12 class="pt-0">
                                        <p class="body-1 mb-0 grey--text text--darken-1 text-center"><a-icon size="16" color="grey darken-1" style="position: relative; top: -3px;">work</a-icon> Active</p>
                                    </a-flex>
                                </a-layout>
                            </div>
                        </a-flex>
                        <a-flex xs12>
                            <a-card class="u-border my-2 u-rounded-corners" flat>
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl pa-6>
                                        <a-layout wrap align-center>
                                            <a-flex shrink>
                                                <h2 class="md-subtitle-1 font-weight-regular">Active Projects by Sales Engineer</h2>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                    <a-divider></a-divider>
                                    <div v-for="sales_engineer in insight_meta.sales_engineers" :key="sales_engineer.id">
                                        <a-container container--fluid grid-list-xl pa-6>
                                            <a-layout wrap align-center>
                                                <a-flex shrink class="u-flex">
                                                    <g-avatar :item="sales_engineer" :right-space="false"></g-avatar>
                                                    <div class="ml-3">
                                                        <h2 class="md-subtitle-1 grey--text text--darken-2 font-weight-medium">{{ sales_engineer.name | truncateText(30) }}</h2>
                                                        <span class="md-body-2 grey--text text--darken-1" v-if="sales_engineer.role">{{ sales_engineer.role.name | truncateText(30) }}</span>
                                                    </div>
                                                </a-flex>
                                                <a-spacer></a-spacer>
                                                <a-flex shrink v-if="sales_engineer.project_details" class="u-flex align-center">
                                                    <div class="md-subtitle-1 font-weight-regular grey--text text--darken-2 mr-3 text-right" style="width: 100px;" v-if="sales_engineer.project_details.projects_count == null">0 Projects</div>
                                                    <div class="md-subtitle-1 font-weight-regular grey--text text--darken-2 mr-3 text-right" style="width: 100px;" v-else>{{ sales_engineer.project_details.projects_count }} Project{{ sales_engineer.project_details.projects_count === 1 ? '' : 's' }}</div>
                                                    <g-currency tag="span" class="md-subtitle-1 font-weight-medium mr-2 green--text text--darken-1 text-right" style="width: 120px;" :value="sales_engineer.project_details.deal_amount"></g-currency>
                                                </a-flex>
                                            </a-layout>
                                        </a-container>
                                        <a-divider></a-divider>
                                    </div>
                                </a-card-text>
                            </a-card>
                        </a-flex>
                        <a-flex xs12>
                            <a-card class="u-border my-2 u-rounded-corners" flat>
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl pa-6>
                                        <a-layout wrap align-center>
                                            <a-flex shrink>
                                                <h2 class="md-subtitle-1 font-weight-regular">Active Projects by Account Executive</h2>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                    <a-divider></a-divider>
                                    <div v-for="account_executive in insight_meta.account_executives" :key="account_executive.id">
                                        <a-container container--fluid grid-list-xl pa-6>
                                            <a-layout wrap align-center>
                                                <a-flex shrink class="u-flex">
                                                    <g-avatar :item="account_executive" :right-space="false"></g-avatar>
                                                    <div class="ml-3">
                                                        <h2 class="md-subtitle-1 grey--text text--darken-2 font-weight-medium">{{ account_executive.name | truncateText(30) }}</h2>
                                                        <span class="md-body-2 grey--text text--darken-1" v-if="account_executive.role">{{ account_executive.role.name | truncateText(30) }}</span>
                                                    </div>
                                                </a-flex>
                                                <a-spacer></a-spacer>
                                                <a-flex shrink v-if="account_executive.project_details" class="u-flex align-center">
                                                    <div class="md-subtitle-1 font-weight-regular grey--text text--darken-2 mr-3 text-right" style="width: 100px;" v-if="account_executive.project_details.projects_count == null">0 Projects</div>
                                                    <div class="md-subtitle-1 font-weight-regular grey--text text--darken-2 mr-3 text-right" style="width: 100px;" v-else>{{ account_executive.project_details.projects_count }} Project{{ account_executive.project_details.projects_count === 1 ? '' : 's' }}</div>
                                                    <g-currency tag="span" class="md-subtitle-1 font-weight-medium mr-2 green--text text--darken-1 text-right" style="width: 120px;" :value="account_executive.project_details.deal_amount"></g-currency>
                                                </a-flex>
                                            </a-layout>
                                        </a-container>
                                        <a-divider></a-divider>
                                    </div>
                                </a-card-text>
                            </a-card>
                        </a-flex>
                        <a-flex xs12>
                            <a-card class="u-border my-2 u-rounded-corners" flat>
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl pa-6>
                                        <a-layout wrap align-center>
                                            <a-flex xs5>
                                                <h2 class="md-subtitle-1 font-weight-regular">Active Projects by Role</h2>
                                            </a-flex>
                                            <a-flex xs7>
                                                <a-autocomplete
                                                    v-model="role_id"
                                                    :items="role_list"
                                                    item-text="name"
                                                    item-value="id"
                                                    placeholder="Select any Role"
                                                    class="elevation-0"
                                                    background-color="neutral"
                                                    @change="local_role_project_count()"
                                                    solo flat hide-details
                                                >
                                                </a-autocomplete>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                    <a-divider></a-divider>
                                    <div v-if="insight_member_item && insight_member_item.length">
                                        <div v-for="role_member in insight_member_item" :key="role_member.id">
                                            <a-container container--fluid grid-list-xl pa-6>
                                                <a-layout wrap align-center>
                                                    <a-flex shrink class="u-flex align-center">
                                                        <g-avatar :item="role_member" :right-space="false"></g-avatar>
                                                        <h2 class="md-subtitle-1 ml-3 grey--text text--darken-2 font-weight-medium">{{ role_member.name | truncateText(30) }}</h2>
                                                    </a-flex>
                                                    <a-spacer></a-spacer>
                                                    <a-flex shrink class="u-flex align-center">
                                                        <div class="md-subtitle-1 font-weight-regular grey--text text--darken-2 mr-3 text-right" style="width: 100px;" v-if="role_member.project_details.projects_count == null">0 Projects</div>
                                                        <div class="md-subtitle-1 font-weight-regular grey--text text--darken-2 mr-3 text-right" style="width: 100px;" v-else>{{ role_member.project_details.projects_count }} Project{{ role_member.project_details.projects_count === 1 ? '' : 's' }}</div>
                                                        <g-currency tag="span" class="md-subtitle-1 font-weight-medium mr-2 green--text text--darken-1 text-right" style="width: 120px;" :value="role_member.project_details.deal_amount"></g-currency>
                                                    </a-flex>
                                                </a-layout>
                                            </a-container>
                                            <a-divider></a-divider>
                                        </div>
                                        <div v-if="report_member_item.role_members && report_member_item.role_members.length == 0">
                                            <h2 class="md-subtitle-1 mb-0 text-center pa-4">No Result Found</h2>
                                        </div>
                                    </div>
                                </a-card-text>
                            </a-card>
                        </a-flex>
                        <a-flex xs12>
                            <a-card class="u-border my-2 u-rounded-corners" flat>
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl pa-6>
                                        <a-layout wrap align-center>
                                            <a-flex shrink>
                                                <h2 class="text-subtitle-1 font-weight-medium grey--text text--darken-3">Projects by Competitor</h2>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                    <a-divider></a-divider>
                                    <div v-for="competitor in insight_meta.competitors" :key="competitor.id">
                                        <a-container container--fluid grid-list-xl pa-6>
                                            <a-layout wrap align-center>
                                                <a-flex shrink>
                                                    <h2 class="md-subtitle-1 grey--text text--darken-2 font-weight-medium">{{ competitor.name }}</h2>
                                                </a-flex>
                                                <a-spacer></a-spacer>
                                                <a-flex shrink>
                                                    <span class="md-subtitle-1 font-weight-regular grey--text text--darken-2">{{ competitor.projects_count }} Projects</span>
                                                </a-flex>
                                            </a-layout>
                                        </a-container>
                                        <a-divider></a-divider>
                                    </div>
                                </a-card-text>
                            </a-card>
                        </a-flex>
                        <a-flex xs12 v-if="insight_meta.active_stages && insight_meta.active_stages.length">
                            <a-card class="u-border my-2 u-rounded-corners" flat>
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl pa-6>
                                        <a-layout wrap align-center>
                                            <a-flex shrink>
                                                <h2 class="text-subtitle-1 font-weight-medium grey--text text--darken-3">Project Lifetime by Active Stages</h2>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                    <a-divider></a-divider>
                                    <div v-for="stage in local_ordered_velocity_by_stage_list" :key="stage.id">
                                        <a-container container--fluid grid-list-xl pa-6 v-if="stage.status !== 'Completed' && stage.status !== 'Queue'">
                                            <a-layout wrap align-center>
                                                <a-flex shrink>
                                                    <h2 class="md-subtitle-1 grey--text text--darken-2 font-weight-medium">{{ stage.name }}</h2>
                                                </a-flex>
                                                <a-spacer></a-spacer>
                                                <a-flex shrink>
                                                    <span class="md-subtitle-1 font-weight-regular grey--text text--darken-2">{{ stage.days_count }} Days</span>
                                                </a-flex>
                                            </a-layout>
                                        </a-container>
                                        <a-divider></a-divider>
                                    </div>
                                </a-card-text>
                            </a-card>
                        </a-flex>
                    </a-layout>
                </a-flex>
                <a-flex xs6 pt-0>
                    <a-layout wrap align-start>
                        <a-flex xs12>
                            <a-card class="u-border u-rounded-corners" flat>
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl pa-6>
                                        <a-layout wrap align-center>
                                            <a-flex shrink>
                                                <h2 class="md-subtitle-1 font-weight-regular">Projects by Stage</h2>
                                            </a-flex>
                                        </a-layout>
                                        <a-layout wrap align-center justify-center>
                                            <a-flex shrink>
                                                <apexchart width="360" type="donut" :options="chart_poc_count_by_stage().chartOptions" :series="chart_poc_count_by_stage().chartOptions.series"></apexchart>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-card-text>
                            </a-card>
                        </a-flex>
                        <a-flex xs12>
                            <a-card class="u-border u-rounded-corners" flat>
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl pa-6>
                                        <a-layout wrap align-center>
                                            <a-flex shrink>
                                                <h2 class="md-subtitle-1 font-weight-regular">Project Value by Stage</h2>
                                            </a-flex>
                                        </a-layout>
                                        <a-layout wrap align-center>
                                            <a-flex xs12>
                                                <apexchart width="100%" type="bar" :options="chart_poc_amount_by_stage().chartOptions" :series="chart_poc_amount_by_stage().series"></apexchart>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-card-text>
                            </a-card>
                        </a-flex>
                        <a-flex xs12>
                            <a-card class="u-border u-rounded-corners" flat>
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl pa-6>
                                        <a-layout wrap align-center>
                                            <a-flex shrink>
                                                <h2 class="md-subtitle-1 font-weight-regular">Projects by Result</h2>
                                            </a-flex>
                                        </a-layout>
                                        <a-layout wrap align-center justify-center>
                                            <a-flex shrink>
                                                <apexchart width="360" type="donut" :options="chart_poc_count_by_result().chartOptions" :series="chart_poc_count_by_result().chartOptions.series"></apexchart>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-card-text>
                            </a-card>
                        </a-flex>
                    </a-layout>
                </a-flex>
            </a-layout>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Apexchart from 'vue-apexcharts'

import loadWorkspace from '@/mixins/mixin-workspace'

export default {
    mixins: [loadWorkspace],
    components: { Apexchart },
    data () {
        return {
            role_id: '',
            local_filter: {},
            filter: {
                WORKSPACE: 'filter[workspace_id]'
            },
            local_stage_list: [],
        }
    },
    mounted() {
        this.local_index()
    },
    watch: {
        workspace_current(newValue, oldValue) {
            this.local_index()
        }
    },
    computed: {
        local_ordered_velocity_by_stage_list(){
            return this.insight_meta.active_stages
        },
        chart_poc_stages() {
            let data = [];
            _.forEach(this.report_item.project_by_stage, (value, key) => {
                if (key) {
                    data.push(key)
                }
            })
            return data
        },
        chart_poc_count() {
            let data = [];
            _.forEach(this.report_item.project_by_stage, (value, key) => {
                if (value) {
                    data.push(value.project_count)
                } else {
                    data.push(0)
                }
            })
            return [{ name: "Project", values: data}]
        },
        chart_poc_amount() {
            let data = [];
            _.forEach(this.report_item.project_by_stage, (value, key) => {
                if (value) {
                    data.push(value.project_value)
                } else {
                    data.push(0)
                }
            })
            return [{ name: "Value", values: data}]
        },
        local_ordered_stage_list(){
            const result_stages = []
            const queue_stage = this.insight_meta.stages.splice(this.insight_meta.stages.findIndex(i => i.status === 'queue'), 1)
            const completed_stage = this.insight_meta.stages.splice(this.insight_meta.stages.findIndex(i => i.status === 'completed'), 1)
            result_stages.push(queue_stage[0])
            this.insight_meta.stages.forEach(item => item.status !== 'queue' && item.status !== 'completed' ? result_stages.push(item) : null)
            result_stages.push(completed_stage[0])
            this.local_stage_list = result_stages
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Report', {
            report_item: 'item',
            report_filters: 'filters',
            report_member_item: 'member_item',
        }),
        ...mapState('Insight', {
            insight_meta: 'insights',
            insight_member_item: 'member_item',
        }),
        ...mapState('Role', {
            role_list: 'list',
        }),
        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key',
        }),
        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('reports.index') == false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.local_filter[this.filter.WORKSPACE] = this.current_workspace_id_mixin
            this.insights_member_clear_item()
            await this.insights_clear()
            await this.insights_lifetime(this.local_filter)
            await this.insights_projects(this.local_filter)
            await this.insights_stages(this.local_filter)
            await this.insights_project_result(this.local_filter)
            await this.insights_sales_engg(this.local_filter)
            await this.insights_account_exe(this.local_filter)
            await this.insights_projects_competitors(this.local_filter)
            await this.insights_lifetime_stages(this.local_filter)
            await this.role_index({ 'filter[scope]': 'internal' })
            this.local_ordered_stage_list
        },
        async local_role_project_count() {
            if (this.role_id !== null) {
                await this.insights_role_project_count({
                    role_id: this.role_id,
                    params: { 'filter[workspace_id]': this.current_workspace_id_mixin }
                })
            }
        },
        chart_poc_count_by_stage() {
            let data = {
                chartOptions: {
                    chart: {
                        id: 'poc-count-by-stage',
                        toolbar: {
                            show: false
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: this.percentage_formatter
                    },
                    legend: {
                        show: false,
                    },
                    series: [],
                    labels: [],
                },
            }
            _.forEach(_.groupBy(this.local_stage_list, 'name'), (value, key) => {
                const field = value[0]
                if (key && field) {
                    data.chartOptions.series.push(field && field.statistics && field.statistics.project_count >= 0 ? field.statistics.project_count : 0)
                    data.chartOptions.labels.push(key)
                }
            })
            return data
        },
        chart_poc_count_by_result() {
            let data = {
                chartOptions: {
                    chart: {
                        id: 'poc-count-by-result',
                        toolbar: {
                            show: false
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: this.percentage_formatter
                    },
                    legend: {
                        show: false,
                    },
                    series: [],
                    labels: [],
                    colors: [],
                },
            }
            _.forEach(this.local_create_project_status_group(), (value, key) => {
                if(value.status === 'Positive') data.chartOptions.colors.push('#62b051');
                if(value.status === 'Negative') data.chartOptions.colors.push('#e64d35');
                if(value.status === 'Neutral') data.chartOptions.colors.push('#30419f');
                data.chartOptions.series.push(value && value.statistics && value.statistics.project_count >= 0 ? value.statistics.project_count : 0)
                data.chartOptions.labels.push(value.value)
            })
            return data
        },
        chart_poc_amount_by_stage() {
            let data = {
                chartOptions: {
                    chart: {
                        id: 'poc-amount-by-stage',
                        toolbar: {
                            show: false
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    tooltip: {
                        y: {
                            formatter: (value) => this.user_self.organization.currency.symbol + value,
                        }
                    },
                    xaxis: {
                        categories: []
                    }
                },
                series: [{
                    name: 'Deal Value',
                    data: []
                }]
            }
            _.forEach(_.groupBy(this.local_stage_list, 'name'), (value, key) => {
                const field = value[0]
                if (key) {
                    data.chartOptions.xaxis.categories.push(key)
                    data.series[0].data.push((field && field.statistics && parseInt(field.statistics.deal_amount) >= 0) ? parseInt(field.statistics.deal_amount) : 0)
                }
            })
            return data
        },
        local_create_project_status_group() {
            if(this.insight_meta.project_result) {
                const positive_status_list = this.insight_meta.project_result.filter(item => item.status === 'Positive')
                const negative_status_list = this.insight_meta.project_result.filter(item => item.status === 'Negative')
                const neutral_status_list = this.insight_meta.project_result.filter(item => item.status === 'Neutral')

                return [...positive_status_list,...negative_status_list,...neutral_status_list ]

            }
            return []
        },
        percentage_formatter (value) {
            const fixedToTwoDecimal = value.toFixed(2)
            if (fixedToTwoDecimal.toString().split('.')[1] !== '00') return value.toFixed(2) + '%'
            return value + '%'
        },
        ...mapActions('Insight', {
            insights_lifetime: 'lifetime',
            insights_lifetime_stages: 'lifetime_stages',
            insights_projects: 'projects',
            insights_projects_competitors: 'projects_competitors',
            insights_account_exe: 'account_exe',
            insights_sales_engg: 'sales_engg',
            insights_stages: 'stages',
            insights_project_result: 'project_result',
            insights_role_project_count: 'role_project_count',
            insights_member_clear_item: 'member_clear_item',
            insights_clear: 'clear',
        }),
        ...mapActions('Role', {
            role_index: 'index',
        })
    }
}
</script>

<style lang="scss" scoped>
    .c-project-card {
        min-height: 92px !important;
    }
</style>

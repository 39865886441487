<template>
    <div class="u-flex align-stretch u-overflow-hidden">
        <a-sheet class="u-wfull pa-8 u-overflow-y" :width="$vuetify.breakpoint.lgAndDown ? 680 : 720" min-height="800" max-height="800">
            <div class="u-flex align-stretch mb-4">
                <!-- Status dropdown -->
                <div class="mr-4">
                    <slot name="status"></slot>
                </div>

                <!-- Item code -->
                <span class="u-flex align-center md-body-2 grey--text text--darken-1 mr-5 font-weight-medium">
                    <a-icon size="20" color="grey darken-1" class="mr-1">tag</a-icon>
                    <span>{{ local_item.code }}</span>
                </span>

                <!-- View more -->
                <a-menu offset-y :close-on-content-click="false" :disabled="!canUpdate" min-width="200" v-if="canUpdate">
                    <template v-slot:activator="{ on }">
                        <a-sheet v-on="on" height="inherit" width="36" class="u-flex-center grey lighten-4 u-rounded-corners u-cursor-pointer">
                            <a-icon size="20" color="grey darken-1">more_horiz</a-icon>
                        </a-sheet>
                    </template>
                    <a-list class="u-list-std">
                        <a-list-item @click="confirm_delete ? $emit('destroy', editItem.id) : local_get_delete_confirm()" :class="[{ 'c-delete-confirm': confirm_delete }]">
                            <a-list-item-icon>
                                <a-icon class="mr-3" size="20" color="grey darken-2">delete</a-icon>
                            </a-list-item-icon>
                            <a-list-item-content>{{ confirm_delete ? 'Confirm Delete?' : 'Delete Record' }}</a-list-item-content>
                        </a-list-item>
                    </a-list>
                </a-menu>

                <a-spacer></a-spacer>

                <div v-if="canUpdate" class="d-inline-flex align-center md-body-2 grey--text text--darken-1 font-weight-medium">
                    <template v-if="timer_update_debounce">
                        <a-icon size="20" color="grey darken-1" class="mr-1">pending</a-icon>
                        <span>Saving...</span>
                    </template>
                    <template v-if="!timer_update_debounce && update_autosaved_at">
                        <a-icon size="20" color="green darken-1" class="mr-1">cloud_done</a-icon>
                        <span class="green--text text--darken-1">Saved</span>
                    </template>
                </div>
            </div>

            <!-- Title -->
            <div class="c-textarea-editor c-textarea-editor--item-view u-relative mb-4">
                <a-textarea
                    v-model="item_title"
                    :disabled="!canUpdate"
                    class="font-weight-regular text-h5 font-weight-bold c-title-color"
                    background-color="transparent"
                    :placeholder="`${module === 'Testcase' ? module : 'Success Criterion'} title goes here..`"
                    multi-line auto-grow solo flat hide-details
                    rows="1"
                    @keyup="local_update_title"
                >
                    <template #append v-if="(!item_title || (item_title && !item_title.trim().length)) || (item_title && item_title.trim().length > 255)">
                        <div>
                            <a-icon color="red darken-1" size="20">info</a-icon>
                            <a-sheet class="c-tooltip-pointer c-tooltip-pointer--right-light u-absolute u-shadow px-3 py-1 md-caption u-rounded-corners red--text text--darken-1" style="right: -1px;">
                                <template v-if="item_title && item_title.trim().length > 255">({{ item_title && item_title.trim().length }} / 255)</template>
                                <template v-else>Required Field</template>
                            </a-sheet>
                        </div>
                    </template>
                </a-textarea>
            </div>

            <!-- Assignee & Dates & Time tracking & Attachments -->
            <div class="u-flex align-stretch mb-5">
                <!-- Assignee -->
                <div class="mr-2 u-flex-center-y">
                    <slot name="assignee"></slot>
                </div>

                <!-- Dates -->
                <div :class="['u-flex align-center mr-5', { 'u-cursor-pointer': canUpdate }]" v-if="local_is_testcase">
                    <s-date-picker :disabled="!canUpdate" :item="editItem" @update="local_date_update">
                        <template v-if="editItem.start_date && editItem.due_date">
                            <a-icon size="18" color="body lighten-3" class="mr-2">date_range</a-icon>
                            <g-moment class="md-body-2 body--text text--lighten-2" :value="editItem.start_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(editItem, 'range')"></g-moment>
                            <span class="mx-1 body--text text--lighten-2"> - </span>
                            <g-moment class="md-body-2 body--text text--lighten-2" :value="editItem.due_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(editItem, 'range')"></g-moment>
                        </template>
                        <template v-if="editItem.start_date && !editItem.due_date">
                            <a-icon size="18" color="body lighten-3" class="mr-2">today</a-icon>
                            <span class="md-body-2 body--text text--lighten-2 mr-1">Start Date: </span>
                            <g-moment class="md-body-2 body--text text--lighten-2" :value="editItem.start_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(editItem.start_date)"></g-moment>
                        </template>
                        <template v-if="!editItem.start_date && editItem.due_date">
                            <a-icon size="18" color="body lighten-3" class="mr-2">event</a-icon>
                            <span class="md-body-2 body--text text--lighten-2 mr-1">Due Date: </span>
                            <g-moment class="md-body-2" :class="[local_check_due_date(editItem) ? 'red--text text--darken-2': 'body--text text--lighten-2']" :value="editItem.due_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(editItem.due_date)"></g-moment>
                        </template>
                        <template v-if="!editItem.start_date && !editItem.due_date">
                            <a-icon size="18" color="body lighten-3" class="mr-2">calendar_today</a-icon>
                            <span class="md-body-2 body--text text--lighten-2 mr-1">Select Date</span>
                        </template>
                    </s-date-picker>
                </div>

                <!-- Time tracker -->
                <template v-if="!hideTimeTrack">
                    <div v-if="local_is_testcase" class="u-cursor-pointer u-flex align-center mr-5" @click="$emit('open-timetracker', editItem)">
                        <a-icon :color="local_tracked_is_greater ? 'orange darken-2' : 'body lighten-3'" size="18" class="mr-2">timer</a-icon>
                        <span class="md-body-2 body--text text--lighten-2">
                            {{ editItem.time_records_sum_duration_minutes ? local_get_time_text(editItem.time_records_sum_duration_minutes) : '0m' }}
                            <template v-if="editItem.estimated_duration_text">
                                / {{ editItem.estimated_duration_text }}
                            </template>
                        </span>
                    </div>
                </template>

                <!-- Attachments -->
                <!-- <div class="u-flex-center-y mr-6">
                    <a-icon size="18" color="body lighten-2">attach_file</a-icon>
                    <span class="md-body-2 body--text text--lighten-2 ml-1">2</span>
                </div> -->
            </div>

            <!-- Tags -->
            <template v-if="!hideTags">
                <div class="pa-0 d-inline-flex flex-wrap" v-if="local_item && local_item.tags && local_item.tags.length">
                    <template v-if="local_item && local_item.tags && local_item.tags.length">
                        <template v-for="tag in local_item.tags">
                            <g-tags :can-update="canUpdate" :tag="tag" type="Usecase" @destroy="local_associate_tag_destroy(tag, local_item)" :key="tag.id"></g-tags>
                        </template>
                    </template>
                    <a-menu bottom offset-y v-model="show_detail_view_tag_input" v-if="canUpdate" :close-on-content-click="false" ref="refAddTagPopup" content-class="u-shadow" transition="slide-y-transition" nudge-bottom="8">
                        <template v-slot:activator="{ on }">
                            <g-add-btn icon-size="14" v-on="on">
                                <span class="md-caption body--text text--lighten-1 ml-1">Add Tag</span>
                            </g-add-btn>
                        </template>
                        <a-sheet class="pb-1" max-width="240">
                            <slot name="tag-inline">
                                <s-tags-inline-form
                                    :item="local_item"
                                    :can-update="$can('tags.store')"
                                    :list="local_get_filtered_tags(local_item)"
                                    :is-menu-open="show_detail_view_tag_input"
                                    :source="module"
                                    :type="tagType"
                                    @store="local_load_tags_list"
                                    @after-store="() => show_detail_view_tag_input = false"
                                ></s-tags-inline-form>
                            </slot>
                        </a-sheet>
                    </a-menu>
                </div>
            </template>

            <!-- Description -->
            <div>
                <h4 class="md-body-2 grey--text text--darken-1 font-weight-medium mb-3">Description</h4>
                <g-editor-box
                    v-model="item_description"
                    @update="local_update_debounced"
                    v-if="isOpen && load_desc_section"
                    :can-update="canUpdate"
                    :model-is-open="isOpen"
                    dense
                ></g-editor-box>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(response, 'description')">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ $response(response, 'description') }}
                </span>
            </div>
        </a-sheet>

        <!-- Activities -->
        <a-sheet class="grey lighten-4" width="360" min-height="800" max-height="800">
            <div class="u-flex-center-y pa-6">
                <div>
                    <h4 class="md-body-1 grey--text text--darken-1 font-weight-medium" style="">Activities</h4>
                    <span class="u-flex align-center md-caption grey--text text--darken-1 mr-4 font-weight-medium">
                        <span>
                            <span>{{ local_get_updated_data(editItem, 'type') }}</span>
                            <span> {{ local_get_updated_at(editItem.updated_at) }} by</span>
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on"> {{ local_get_updated_data(editItem, 'user') | truncateText(9) }}</span>
                                </template>
                                <span> {{ local_get_updated_data(editItem, 'user') }}</span>
                            </a-tooltip>
                        </span>
                    </span>
                </div>
                <a-spacer></a-spacer>
                <span class="grey--text text--darken-3 u-cursor-pointer u-rounded-corners-lg" @click="$emit('close', true)" style="font-size: 18px;">&times;</span>
            </div>

            <a-divider class="grey lighten-2"></a-divider>

            <!-- Comments list -->
            <a-sheet ref="refActivityScrollbar" class="pa-6 transparent u-overflow-y" :min-height="`calc(${local_comment_list_height}px)`" :max-height="`calc(${local_comment_list_height}px)`">
                <v-slide-y-transition group leave-absolute hide-on-leave tag="div">
                    <template v-if="loaders.sidebar_comments_loading && (editItem && (!editItem.comments || !editItem.comments.length))">
                        <loader-comments key="loader" wrapper-class="transparent" color="grey lighten-2"></loader-comments>
                    </template>
                    <template v-else>
                        <template v-if="editItem && editItem.comments && editItem.comments.length">
                            <a-sheet :class="[{ 'mt-4': index !== 0 }, { 'c-public-border-l': comment.visibility === 'external' }, 'u-rounded-corners-lg pa-3']" v-for="(comment, index) in editItem.comments" :key="comment.id">
                                <div class="u-flex">
                                    <g-avatar class="mr-2" :item="comment.created_by" no-shadow :size="34" :right-space="false"></g-avatar>
                                    <div class="u-flex align-center flex-wrap">
                                        <span class="md-body-2 body--text text--darken-3" v-if="comment.created_by">{{ comment.created_by.name }}</span>
                                        <g-moment tag="div" class="md-caption body--text text--lighten-3" :value="comment.updated_at ? comment.updated_at : comment.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD [at] hh:mma" convert-local></g-moment>
                                        <a-sheet class="body--text text--lighten-3 md-caption ml-2 transparent" v-if="comment.updated_at && local_date_diff(comment.created_at, comment.updated_at)">
                                            (updated)
                                        </a-sheet>
                                    </div>
                                </div>
                                <template v-if="edit_comment && !edit_comment.id || (edit_comment.id && comment.id !== edit_comment.id)">
                                    <p class="md-body-2 u-wfull mb-0 mt-2 grey--text text--darken-2" style="white-space: pre-wrap; line-height: 24px;">{{ comment.content }}</p>
                                    <div class="u-flex align-center mt-2" v-if="canComment">
                                        <span class="md-caption u-cursor-pointer body--text text--lighten-3 mr-3" @click="local_edit_comment(comment)">Edit</span>
                                        <span class="md-caption u-cursor-pointer body--text text--lighten-3" @click="local_delete_comment(comment)">Delete</span>
                                    </div>
                                </template>
                                <div class="" v-if="(edit_comment && edit_comment.id) && (edit_comment.id === comment.id)">
                                    <a-sheet class="u-rounded-corners">
                                        <a-sheet class="c-comment-box--edit-view u-border px-2 u-rounded-corners mt-2">
                                            <a-textarea
                                                v-model="edit_comment.content"
                                                placeholder="Add comment..."
                                                class="py-2 md-body-2"
                                                height="54"
                                                @keyup.ctrl.enter="local_update_comment(edit_comment)"
                                                dense hide-details solo flat no-resize autofocus
                                            ></a-textarea>
                                        </a-sheet>
                                        <a-sheet class="u-flex-center-y pl-3 mt-2">
                                            <a-sheet @click="local_cancel_comment_edit()" v-ripple width="24" height="24" class="u-rounded-corners-full md-caption u-cursor-pointer u-flex-center body--text text--lighten-3">
                                                Cancel
                                            </a-sheet>
                                            <a-spacer></a-spacer>
                                            <span :class="[local_edit_comment_length > 5000 ? 'red--text text--darken-2' : 'body--text text--lighten-4', 'md-caption mr-2']">{{ local_edit_comment_length }} / 5000</span>
                                            <a-sheet width="22" height="22" @click="local_update_comment(edit_comment)" v-ripple class="u-rounded-corners-full px-1 u-cursor-pointer u-flex-center-y">
                                                <a-progress-circular v-if="loader_comment.edit === edit_comment.id" width="3" size="16" indeterminate color="orange darken-1"></a-progress-circular>
                                                <a-icon v-else size="18" color="secondary">send</a-icon>
                                            </a-sheet>
                                        </a-sheet>
                                    </a-sheet>
                                </div>
                            </a-sheet>
                        </template>
                        <a-sheet key="no-activity" :min-height="`calc(${canComment ? 492 : 662}px)`" class="transparent u-flex-center md-caption font-italic body--text text--lighten-3 font-weight-medium" v-else>
                            There are no activities recorded to display
                        </a-sheet>
                    </template>
                </v-slide-y-transition>
            </a-sheet>

            <template v-if="canComment">
                <a-divider class="grey lighten-2"></a-divider>

                <!-- Comment box -->
                <div class="pa-6 pb-4">
                    <a-sheet class="pa-1 u-rounded-corners">
                        <a-sheet class="c-comment-box">
                            <a-textarea
                                v-model="local_get_comment"
                                placeholder="Add comment..."
                                class="py-2 md-body-2"
                                height="54"
                                :disabled="!canComment"
                                @keyup.ctrl.enter="local_store_comment"
                                dense hide-details solo flat no-resize
                            ></a-textarea>
                        </a-sheet>
                        <a-sheet class="u-flex-center-y px-4 pt-1">
                            <g-toggle custom-bg dense-xl :disabled="!!(usecase_item.visibility === 'internal')" v-model="check_is_public" class="d-inline-flex align-center" :color="check_is_public ? 'orange darken-2' : 'grey darken-4'">
                                <span :class="[check_is_public ? 'orange--text text--darken-2' : (!!(usecase_item.visibility === 'internal') ? 'grey--text text--lighten-1' : 'grey--text text--darken-3'), 'font-weight-medium md-caption text-uppercase ml-2']">{{ check_is_public ? 'Public' : 'Private' }}</span>
                            </g-toggle>
                            <a-spacer></a-spacer>
                            <a-sheet width="22" height="22" @click="local_store_comment()" v-ripple class="u-rounded-corners-full px-1 u-cursor-pointer u-flex-center-y">
                                <a-progress-circular v-if="loader_comment.store" width="3" size="16" indeterminate color="orange darken-1"></a-progress-circular>
                                <a-icon v-else size="18" color="secondary">send</a-icon>
                            </a-sheet>
                        </a-sheet>
                    </a-sheet>
                    <div class="u-flex-center-y md-caption body--text text--lighten-4 mt-2">
                        <span :class="[{ 'red--text text--darken-2': local_comment_length > 5000 } ]">{{ local_comment_length }} / 5000</span>
                        <a-spacer></a-spacer>
                        <span class="u-flex-center-y">
                            <span>Press</span>
                            <span class="font-weight-bold mx-1">Ctrl + Enter</span>
                            <span>to comment</span>
                        </span>
                    </div>
                </div>
            </template>
        </a-sheet>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { STagsInlineForm, SDatePicker } from '@/config/config-shared-components'
import { diffFormat, diffHuman } from '@/helpers/helper-date-filter'
import { calculateByMins } from '@/helpers/helper-time-tracker'

export default {
    props: {
        editItem: {
            type: Object,
        },
        module: {
            type: String,
            required: true
        },
        tagType: {
            type: String,
        },
        loaders: {
            type: Object
        },
        commentType: {
            type: String,
        },
        canUpdate: {
            type: Boolean,
        },
        canComment: {
            type: Boolean,
        },
        hideTimeTrack: {
            type: Boolean,
            default: false
        },
        hideTags: {
            type: Boolean,
            default: false
        },
        isOpen: {
            type: Boolean,
        },
        response: {
            type: Object | Array,
        },
    },

    components: { STagsInlineForm, SDatePicker },

    data () {
        return {
            timer_update_debounce: null,
            update_autosaved_at: null,
            show_detail_view_tag_input: false,
            confirm_delete: false,
            check_is_public: false,
            load_desc_section: false,
            comment: '',
            item_title: '',
            item_description: '',
            edit_comment: {},
            loader_comment: {
                store: false,
                edit: '',
            }
        }
    },

    mounted () {
        this.local_index()
    },

    watch: {
        isOpen (val) {
            if (val) {
                this.item_description = ''
                this.local_index()
            }
        },

        local_item_comments_length (newVal, oldVal) {
            if (newVal > oldVal) this.local_reset_activity_scrollbar()
        }
    },

    computed: {
        local_get_comment: {
            get () {
                return this.comment
            },
            set (val) {
                this.comment = val
                this.$emit('update-comment', val)
            }
        },

        local_comment_list_height () {
            return this.canComment ? 540 : 710
        },

        local_edit_comment_length () {
            return this.edit_comment.content.length
        },

        local_comment_length () {
            return this.comment.length
        },

        local_item_comments_length () {
            return this.editItem && this.editItem.comments && this.editItem.comments.length
        },

        local_item () {
            return this.editItem
        },

        local_is_testcase () {
            return this.module === 'Testcase'
        },

        local_has_time_track () {
            const { estimated_duration_minutes, estimated_duration_text, time_records_sum_duration_minutes } = this.editItem
            return !!(estimated_duration_minutes || estimated_duration_text || time_records_sum_duration_minutes)
        },

        local_tracked_is_greater () {
            return this.editItem && (Number(this.editItem.time_records_sum_duration_minutes) > this.editItem.estimated_duration_minutes)
        },

        ...mapState('Comment', {
            comment_item: 'item',
            comment_response: 'response',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Tag', {
            tag_list: 'list'
        }),

        ...mapState('Usecase', {
            usecase_item: 'item',
        }),

        ...mapState('Testcase', {
            testcase_list: 'list',
        }),

        ...mapState('SuccessCriterion', {
            success_criterion_list: 'list',
        }),
    },

    methods: {
        local_index () {
            this.item_title = _.cloneDeep(this.editItem.title)
            this.item_description = this.editItem.description
            this.load_desc_section = true
            this.local_reset_activity_scrollbar()
            this.local_reset_comment()
        },

        local_reset_activity_scrollbar () {
            this.$nextTick(() => {
                this.$refs.refActivityScrollbar.$el.scrollTop = this.$refs.refActivityScrollbar.$el.scrollHeight
            })
        },

        local_update_title () {
            if (!this.item_title || this.item_title && this.item_title.length > 255) return this.local_clear_debounce_timer()
            this.timer_update_debounce = setTimeout(async () => {
                this.editItem.title = this.item_title
                this.local_set_autosave()
                this.editItem.is_autosave = 1
                this.$emit('update', this.editItem)
                this.local_clear_debounce_timer()
            }, 500)
        },

        local_set_autosave () {
            this.update_autosaved_at = moment().format('YYYY-MM-DD HH:mm:ss')
            this.editItem.updated_at = moment()
            this.editItem.updated_by_id = this.user_self.id
            this.$set(this.editItem, 'updated_by', this.user_self)
            setTimeout(() => this.update_autosaved_at = null, 5000)
        },

        async local_clear_debounce_timer (saved = false) {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                this.editItem.is_autosave = 0
            }
        },

        async local_update_debounced (content) {
            this.local_clear_debounce_timer()
            this.timer_update_debounce = setTimeout(async () => {
                this.item_description = JSON.stringify(content)
                this.editItem.description = JSON.stringify(content)
                this.editItem.is_autosave = 1
                this.local_set_autosave()
                this.$emit('update', this.editItem)
                if (this.$status(this.response)) {
                    this.editItem.is_autosave = 0
                    this.local_clear_debounce_timer()
                }
            }, 500)
        },

        local_date_update ({ item }) {
            this.editItem = item
            this.$emit('update', this.editItem)
        },

        local_get_filtered_tags (item) {
            return _.differenceBy(this.tag_list, item.tags, 'id')
        },

        // Tags CRUD
        async local_associate_tag_destroy (data, item) {
            this.local_remove_tag(data, item)
            await this.association_destroy(data.association)
        },

        async local_remove_tag (tag, item) {
            const index = item.tags.findIndex(item => item.id === tag.id)
            if (index !== -1) item.tags.splice(index, 1)
        },

        local_load_tags_list () {
            this.tag_index({ 'filter[type]': 'usecase_tag', 'fields[tags]': 'id,label,color,type' })
        },
        // Tags CRUD ----- END

        // Comments CRUD
        local_edit_comment (comment) {
            this.edit_comment = _.cloneDeep(comment)
            this.local_reset_comment()
        },

        local_reset_comment () {
            this.comment = ''
        },

        async local_update_comment (comment) {
            this.loader_comment.edit = comment.id
            const item = this.editItem.comments.find(data => data.id === comment.id)
            const oldComment = _.cloneDeep(item.content)
            item.content = comment.content
            item.updated_at = new Date()
            await this.comment_update(comment)
            if (this.comment_response.status !== 'success') {
                this.loader_comment.edit = ''
                item.content = oldComment
                return
            }
            this.loader_comment.edit = ''
            this.$emit('comment-update')
            this.local_cancel_comment_edit()
        },

        async local_store_comment () {
            this.loader_comment.store = true
            await this.local_set_comment_vars()
            if (!this.comment_item.content || (this.comment_item.content && !this.comment_item.content.trim())) return this.loader_comment.store = false
            await this.comment_store(this.comment_item)
            if (this.comment_response.status !== 'success') return this.loader_comment.store = false
            this.loader_comment.store = false
            this.local_add_comment_to_item(this.comment_item)
            setTimeout(() => {
                this.$emit('comment-store')
                this.local_reset_activity_scrollbar()
                this.comment_clear_item()
            }, 100)
        },

        local_add_comment_to_item (comment) {
            if (!this.editItem.comments) Object.assign(this.editItem, { comments: [] })
            this.editItem.comments.push(comment)
            this.$set(this.editItem, 'comments_count', this.editItem.comments_count + 1)
            this.local_update_item()
            this.check_is_public = false
            this.comment = ''
        },

        async local_set_comment_vars (id) {
            this.comment_item.commentable_type = this.module
            this.comment_item.visibility = this.check_is_public ? 'external' : 'internal'
            this.comment_item.commentable_id = this.editItem.id
            this.comment_item.content = this.local_get_comment
            this.comment_item.created_by = this.user_self
            this.comment_item.created_by_id = this.user_self.id
            this.comment_item.created_at = new Date()
        },

        local_delete_comment (comment) {
            const index = this.editItem.comments.findIndex(item => item.id === comment.id)
            if (index !== -1) this.editItem.comments.splice(index, 1)
            this.comment_destroy({ id: comment.id })
            if (this.comment_response && this.comment_response.status !== 'success')
            this.$set(this.editItem, 'comments_count', this.editItem.comments_count - 1)
            this.local_update_item()
            this.$notify('success', 'Comment deleted successfully!')
            this.$emit('comment-delete')
        },

        local_cancel_comment_edit () {
            this.edit_comment = {}
        },
        // Commenrs CRUD --- END

        local_update_item () {
            if (this.module === 'Testcase') {
                const index = this.testcase_list.findIndex(({ id }) => id === this.editItem.id)
                this.$set(this.testcase_list, index, this.editItem)
                return
            }
            const index = this.success_criterion_list.findIndex(({ id }) => id === this.editItem.id)
            this.$set(this.success_criterion_list, index, this.editItem)
        },

        // Extras
        local_date_diff (updated, created) {
            return moment(created).diff(moment(updated), 'seconds') > 5
        },

        local_get_delete_confirm () {
            this.confirm_delete = true
            setTimeout(() => this.confirm_delete = false, 3000)
        },

        local_check_due_date ({ due_date, status }) {
            if (status) return moment(due_date).isBefore() && status.status === 'Open'
        },

        local_get_time_text (value) {
            const { durationText } = calculateByMins(value)
            return durationText
        },

        local_date_output_format (date, type = null) {
            return diffFormat(date, type)
        },

        local_get_updated_at (date) {
            return diffHuman(date)
        },

        local_get_updated_data (item, type) {
            if (!item) return
            const hasUpdated = item.updated_at ?? null
            if (hasUpdated) {
                if (type === 'type') return 'Updated'
                if (type === 'user' && item.updated_by) return item.updated_by.name
            }
            if (type === 'type') return 'Created'
            if (type === 'user' && item.created_by) return item.created_by.name
        },

        ...mapActions('Tag', {
            tag_index: 'index'
        }),

        ...mapActions('Association', {
            association_destroy: 'destroy'
        }),

        ...mapActions('Comment', {
            comment_store: 'store',
            comment_update: 'update',
            comment_destroy: 'destroy',
            comment_clear_item: 'clear_item',
        }),
    }
}
</script>

<style scoped>

</style>

<template>
    <div class="c-heading u-relative u-flex align-center my-2" style="min-height: 40px">
        <!-- Block List Menu -->
        <block-list-menu v-if="$can('document_generator.update')" @addBlock="methodStoreReportBlock"></block-list-menu>

        <!-- Block Information -->
        <div class="u-absolute" style="top: 50%; transform: translateY(-50%); left: -40px">
            <a-menu offset-x left :nudge-top="50" v-model="dataSideMenu" transition="slide-x-transition" :close-on-content-click="false" min-width="350" max-width="350" style="max-width: 24px !important;" content-class="u-border c-radius u-elevation-custom-1">
                <template v-slot:activator="{ on }">
                    <a v-on="on" v-ripple="{ class: 'info--text' }" style="padding: 0 4px 0 4px;" class="c-component__menu u-flex align-center u-rounded-corners list-group-item js-drag-handle" :class="[ dataSideMenu ? 'is-active' : '' ]"><a-icon class="c-component__drag" size="26">drag_indicator</a-icon></a>
                </template>
                <div class="c-radius u-elevation-custom-1 py-3 px-4 white" style="max-width: 350px;">
                    <div class="u-flex align-center">
                        <h2 class="grey--text text--darken-3 title font-weight-bold" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.title }}</h2>
                        <a-spacer></a-spacer>
                        <a-btn small icon text color="red darken-1 ma-0" class="ma-0" v-if="$can('document_generator.destroy')" @click="methodRemoveComponent">
                            <svg width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-trash red--text text--darken-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a-btn>
                    </div>
                    <p class="grey--text text--darken-1 mb-0 body-2 font-weight-medium" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.description }}</p>
                    <template v-if="$can('document_generator.update')">
                        <template v-for="defBlockAttr in dataGetBlockInfo.attributes">
                            <div :key="defBlockAttr.id">
                                <div class="grey--text text--darken-1 text-uppercase body-2 font-weight-bold mb-2 mt-4" style="letter-spacing: 1px;">{{ defBlockAttr.title }}</div>
                                <a-layout align-center wrap>
                                    <template v-for="(menu) in defBlockAttr.options">
                                        <a-flex xs3 class="pa-0 mb-2" v-if="menu.value" :key="menu.id">
                                            <a-btn small text :class="[methodBlockAttrs(clonedPrimaryText.id, defBlockAttr.id) && methodBlockAttrs(clonedPrimaryText.id, defBlockAttr.id).value.value === menu.value ? 'grey lighten-2' : '']" @click="methodAlignBlock(menu, defBlockAttr.id, methodBlockAttrs(clonedPrimaryText.id, defBlockAttr.id).id)" class="u-border c-radius ma-0 mx-auto" style="min-height: 40px !important; min-width: 60px !important; max-width: 60px !important;">
                                                <a-icon size="24" color="grey darken-2">{{ 'format_align_' + menu.value }}</a-icon>
                                            </a-btn>
                                        </a-flex>
                                    </template>
                                </a-layout>
                            </div>
                        </template>
                    </template>
                </div>
            </a-menu>
        </div>

        <!-- Block -->
        <!-- <div v-if="(clonedPrimaryText && clonedPrimaryText.content.length)" ref="editorRef" v-once class="c-text-editor c-xspacing u-wfull grey--text text--darken-2 my-2" :id="clonedPrimaryText.id" contenteditable @keydown.enter.prevent="methodCatchEnterKey" @blur="methodUpdateOnBlur" @input="methodUpdateOnInput"> -->
        <div v-if="clonedPrimaryText" class="u-wfull" :style="[currentAlignment ? { textAlign: currentAlignment.value } : '']">
            <div v-if="content_local" ref="editorRef" v-once class="c-text-editor ss c-xspacing u-wfull grey--text text--darken-2 my-2" placeholder="Primary Text" :id="clonedPrimaryText.id" :contenteditable="$can('document_generator.update')" @keyup="methodKeyUp" @keydown="methodKeyDown" @keydown.enter.prevent="methodCatchEnterKey" @blur="methodUpdateOnBlur" @input="methodUpdateOnInput" v-text="content_local"></div>
            <div v-else ref="editorRef" v-once class="c-text-editor ss c-xspacing u-wfull grey--text text--darken-2 my-2" placeholder="Primary Text" :id="clonedPrimaryText.id" :contenteditable="$can('document_generator.update')" @keyup="methodKeyUp" @keydown="methodKeyDown" @keydown.enter.prevent="methodCatchEnterKey" @blur="methodUpdateOnBlur" @input="methodUpdateOnInput" v-text="content_local"></div>
            <!-- <div v-if="clonedPrimaryText && (!clonedPrimaryText.content || clonedPrimaryText.content === '') || !content || content === ''" ref="editorHideRef" v-once class="c-text-editor cc c-xspacing u-wfull grey--text text--darken-2 my-2" :id="clonedPrimaryText.id" placeholder="Primary Text" contenteditable @keydown="methodKeyDown" @keydown.enter.prevent="methodCatchEnterKey" @blur="methodUpdateOnBlur" @input="methodUpdateOnInput">{{ content }}</div> -->
        </div>
        <!-- <div v-else ref="editorRef" v-once class="c-text-editor c-xspacing u-wfull grey--text text--darken-2 my-2" :id="clonedPrimaryText.id" placeholder="Primary Text" contenteditable @keydown="methodKeyDown" @keydown.enter.prevent="methodCatchEnterKey" @blur="methodUpdateOnBlur" @input="methodUpdateOnInput"></div> -->
        <!-- <template v-else>
            <div @click="methodFocusEditor" class="u-cursor-pointer">
                <input type="text" placeholder="Primary Text" disabled v-once class="c-text-editor u-cursor-pointer c-xspacing u-wfull grey--text text--darken-2">
            </div>
        </template> -->
    </div>
</template>

<script>
import TurndownService from 'turndown'
import marked from 'marked'
import { mapState, mapActions, mapGetters } from 'vuex'
import EventBus from '@/config/config-eventbus'
import BlockListMenu from '../BlockListMenu.vue'



export default {
    components: { BlockListMenu },

    props: ['item', 'index', 'lastIndex', 'list'],

    data () {
        return {
            clonedPrimaryText: this.item,
            content_local: '',
            currentAlignment: { label: 'Left', value: 'left' },
            currentFormatting: '',
            showFormatter: false,
            showControls: false,
            dataSideMenu: false,
            isEnter: false,
            dataAutoSaveTimeout: null,
            showEditor: false,
            lengthToDelete: 0,
            format: {
                center: false,
                right: false,
                left: false,
            },
        }
    },

    watch: {
        lastIndex (value) {
            if (value) {
                // this.$refs.editorRef.focus()
            }
        },
    },

    mounted () {
        this.methodIndex()
    },

    computed: {
        dataTextAlignment () {
            const defaultAttr = this.dataGetBlockInfo.attributes.find(item => item.title === 'Alignment')
            const ratioAttr = this.clonedPrimaryText.report_template_block_attributes.find(item => item.rb_attribute_id === defaultAttr.id)
            this.currentAlignment = ratioAttr.value
        },

        dataGetBlockInfo () {
            return this.getBlockContent(this.clonedPrimaryText.report_block_id)
        },

        dataCheckAlignment () {
            ['center', 'right', 'left'].forEach(item => {
                if (this.currentAlignment.value === item) this.format[item] = true
                else this.format[item] = false
            })
        },

        ...mapGetters('ReportBlock', [
            'getDefaultBlockAttr',
            'getBlockContent'
        ]),

        ...mapGetters('ReportTemplateBlock', [
            'getBlockAttrs',
        ]),

        ...mapGetters('ReportTemplate', [
            'getBlock',
        ]),
    },

    methods: {
        methodIndex () {
            this.dataTextAlignment
            this.dataCheckAlignment
            const htmlContent = this.methodCompiledMarkdown(this.clonedPrimaryText.content).replace(/\\_/g, '_')
            this.content_local = htmlContent
            // this.content = this.clonedPrimaryText.content
        },

        dataGetBlockElement () {
            return document.getElementById(this.clonedPrimaryText.id)
        },

        methodFocusEditor () {
            // this.showEditor = true
            // setTimeout(() => this.$refs.editorRef.focus(), 0);
        },

        methodBlockAttrs (blk_id, attr_id) {
            const attr = this.getBlockAttrs(blk_id, attr_id)
            this.currentAlignment = attr.value
            return attr
        },

        methodUpdateOnInput (evt) {
            const { id, innerText: text, outerHTML: outHTML, innerHTML: html, children: childEls } = evt.target
            const element = document.getElementById(id)

            if (evt.inputType === 'insertFromPaste') this.methodBlurAndFocus()
            // if (text.length === 0) this.methodRemoveComponent()
            this.methodLiveUpdate(text, html)
        },

        methodBlurAndFocus () {
            const element = this.dataGetBlockElement()
            this.$nextTick(() => element.blur())
            setTimeout(() => {
                element.focus()
                this.methodSetCaretPosition(element, element.innerText.length)
            }, 500);
        },

        methodUpdateOnBlur (evt) {
            if (!this.$can('document_generator.update')) return
            const { id, innerText: text, outerHTML: outHTML, innerHTML: html } = evt.target
            const element = document.getElementById(id)
            const stripedHtml = html.replace(/(<([^>]+)>)/gi, '')
            const stripImage = stripedHtml.replace(/<img.*?src="(.*?)"[^\>]+>/ig, '')
            const markDown = marked(stripImage, { sanitize: true })
            const serializedContent = this.methodSerializeHtml(markDown)
            // const serializedContent = this.methodSerializeHtml(html)

            evt.preventDefault()
            this.clonedPrimaryText.content = serializedContent.replace(/\&nbsp;/g, '').replace(/\\_/g, '_')
            // this.clonedPrimaryText.content_html = serializedContent.replace(/\\_/g, '_')
            element.innerHTML = serializedContent
            // this.content = this.clonedPrimaryText.content

            EventBus.$emit('editBlock', this.clonedPrimaryText)
            // if (!this.clonedPrimaryText.content.length) return this.showEditor = false
            this.methodUpdateBlock()
        },

        methodLiveUpdate (text, html) {
            if (!this.$can('document_generator.update')) return
            clearTimeout(this.dataAutoSaveTimeout)
            const stripedHtml = html.replace(/(<([^>]+)>)/gi, '')
            const stripImage = stripedHtml.replace(/<img.*?src="(.*?)"[^\>]+>/ig, '')
            const markDown = marked(stripImage, { sanitize: true })
            const serializedContent = this.methodSerializeHtml(markDown)
            // const serializedContent = this.methodSerializeHtml(html)

            // this.clonedPrimaryText.content = text.trim()
            this.clonedPrimaryText.content = serializedContent.replace(/\&nbsp;/g, '').replace(/\\_/g, '_')
            // this.clonedPrimaryText.content_html = serializedContent.replace(/\\_/g, '_')
            EventBus.$emit('editBlock', this.clonedPrimaryText)

            // if (!this.clonedPrimaryText.content.length) return
            this.dataAutoSaveTimeout = setTimeout(async () => {
                this.methodUpdateBlock()
                clearTimeout(this.dataAutoSaveTimeout)
            }, 500);
        },

        methodSerializeHtml (html) {
            // let serializedHtml = html.replace(/(<\/?(?:p|b|strong|i|em|u)[^>]*>)|<[^>]+>/ig, '$1') // Remove all tags except the mentioned ones
            let serializedHtml = html.replace(/(<([^>]+)>)/g, '') // Remove all tags
            serializedHtml = serializedHtml.replace(/<img.*?src="(.*?)"[^\>]+>/ig, '$1') // Remove <img> tag
            serializedHtml = serializedHtml.replace(/(<[^>]+) style=".*?"/ig, '$1') // Remove style attribute
            // serializedHtml = serializedHtml.replace(/\&nbsp;/g, '') // Remove &nbsp; character

            if (serializedHtml.children && serializedHtml.children.length) {
                Array.from(serializedHtml.children).forEach((element, index) => {
                    for (let prop in element.dataset) delete element[index].dataset[prop]
                })
            }

            return serializedHtml
        },

        async methodAlignBlock (menu, attrDefId, attrId) {
            this.currentFormatting = { default_id: attrDefId, menu_attr_id: attrId }
            this.currentAlignment = menu
            this.dataCheckAlignment
            await this.methodUpdateBlockAttr()
            setTimeout(() => {
                this.actionTemplateBlockShow({ id: this.clonedPrimaryText.id, index: this.index })
            }, 100);
        },

        methodCatchEnterKey (enter) {
            //
        },

        // isEdited(evt) {
        //     clearTimeout(this.dataAutoSaveTimeout)
        //     const { id, innerText: text, innerHTML: html } = evt.target
        //     const keyCode = evt.keyCode
        //     const element = document.getElementById(this.clonedPrimaryText.id)
        //     const headingEl = document.createElement('div')

        //     if (this.isEnter) {
        //         headingEl.innerText = element.innerHTML.replace( /(<([^>]+)>)/ig, '').trim()
        //         this.clonedPrimaryText.content = headingEl.innerText
        //         this.clonedPrimaryText.content_html = headingEl.outerHTML.replace(/&amp;/ig, '&')
        //         element.innerHTML = headingEl.innerText.trim()
        //         element.childNodes[0].data = element.childNodes[0].data.trim()
        //         this.$nextTick(() => this.methodSetCaretPosition(element, element.childNodes[0].length))
        //         return evt.preventDefault()
        //     } else if (this.isSpace) {
        //         headingEl.innerText = element.innerText
        //         this.clonedPrimaryText.content = text.trim()
        //         this.clonedPrimaryText.content_html = text.trim()
        //     } else {
        //         this.clonedPrimaryText.content = text.trim()
        //         this.clonedPrimaryText.content_html = text.trim()
        //     }

        //     EventBus.$emit('editBlock', this.clonedPrimaryText)

        //     if (!this.clonedPrimaryText.content.length) return
        //     this.dataAutoSaveTimeout = setTimeout(async () => {
        //         this.methodUpdateBlock()
        //         clearTimeout(this.dataAutoSaveTimeout)
        //     }, 500);
        // },

        methodSetCaretPosition (element, caretLocation) {
            const range = document.createRange();
            const selection = window.getSelection();
            range.setStart(element.firstChild, caretLocation);
            range.setEnd(element.firstChild, caretLocation);
            selection.removeAllRanges();
            selection.addRange(range);
        },

        methodKeyUp (evt) {
            const element = this.dataGetBlockElement()
            if (evt.target.innerText.length === 0) {
                element.blur()
                setTimeout(() => element.focus(), 100)
            }
        },

        methodKeyDown (evt) {
            if (evt.key === 'Backspace' || evt.key === 'Delete') {
                if (evt.target.innerText.length === 0) this.lengthToDelete = this.lengthToDelete + 1
                if (evt.target.innerText.length === 0 && this.lengthToDelete === 1) return this.methodRemoveComponent()
            }
        },

        methodCompiledMarkdown (content) {
            if (!content) return ''
            return turndownService.turndown(content)
        },

        async methodUpdateBlock () {
            this.actionTemplateBlockEdit({
                id: this.clonedPrimaryText.id,
                report_block_id: this.clonedPrimaryText.report_block_id,
                report_template_id: this.$route.params.template_id,
                content: this.clonedPrimaryText.content,
                // content_html: this.clonedPrimaryText.content_html,
            })
            EventBus.$emit('editBlock', this.clonedPrimaryText)
        },

        async methodUpdateBlockAttr () {
            const params = {
                rt_block_id: this.clonedPrimaryText.id,
                rb_attribute_id: this.currentFormatting.default_id,
                value: JSON.stringify(this.currentAlignment)
            }

            this.actionTemplateBlockAttrEdit({ id: this.currentFormatting.menu_attr_id ? this.currentFormatting.menu_attr_id : null, ...params })
            EventBus.$emit('editBlock', this.clonedPrimaryText)
        },

        async methodStoreReportBlock (component) {
            await this.actionTemplateBlockStore({
                index: this.index + 1,
                data: { report_template_id: this.$route.params.template_id, report_block_id: component.id },
                params: { include: 'reportBlock' }
            })
            this.actionTemplateBlockReorder({ list: this.list })
            // EventBus.$emit('reorderBlock', { index: this.index + 1 })
        },

        onPaste (evt) {
            // console.log(evt, evt.clipboardData.getData('text'))
        },

        methodRemoveComponent() {
            this.actionFindAndRemoveBlock(this.clonedPrimaryText)
            this.actionTemplateBlockDelete({ id: this.clonedPrimaryText.id })
            // EventBus.$emit('removeComponent', this.clonedPrimaryText)
        },

        ...mapActions('ReportTemplateBlock', [
            'actionTemplateBlockStore',
            'actionTemplateBlockEdit',
            'actionTemplateBlockReorder',
            'actionTemplateBlockAttrsStore',
            'actionTemplateBlockAttrEdit',
            'actionTemplateBlockShow',
            'actionFindAndRemoveBlock',
            'actionTemplateBlockDelete'
        ]),
    }
}
</script>

<style lang="scss" scoped>
    [contenteditable]:empty:before {
        content: attr(placeholder);
        display: block;
        color: rgba(99, 98, 98, 0.25);
    }

    .c-text-editor {
        font-size: 20px;
        font-weight: normal;
        transition: 0.4s all;
        font-family: Arial !important;
        &:focus {
            // background: #f5f5f5 !important;
            background: #fbfbfb !important;
            transition: 0.4s all;
        }
    }
</style>

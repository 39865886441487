<template>
    <v-list v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </v-list>
</template>

<script>
import { VList } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VList }
}
</script>

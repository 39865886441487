<template>
	<a-main>
        <a-container grid-list-xl container--fluid mb-10 pa-0 pt-4>
            <a-layout wrap align-center mb-4>
                <a-flex class="py-0">
                    <a-card class="u-rounded-corners px-4 u-border" flat>
                        <a-text-field
                            v-model="search_user"
                            placeholder="Search user by name or email"
                            background-color="white"
                            prepend-icon="search"
                            :loading="search_loading"
                            solo flat hide-details autofocus
                            @input="local_search_users()"
                        ></a-text-field>
                    </a-card>
                </a-flex>
                <!-- <a-flex shrink class="pl-1">
                    <a-progress-circular color="orange darken-2" size="24" width="2" :indeterminate="loading"></a-progress-circular>
                </a-flex> -->
            </a-layout>
            <a-layout wrap>
                <a-flex shrink pr-1>
                    <a-menu bottom offset-y v-model="role_dropdown" :close-on-content-click="false" max-width="280">
                        <template v-slot:activator="{ on }">
                            <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-border py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="264" v-on="on">
                                <span class="md-body-2 grey--text text--darken-2 mx-1">
                                    <span class="font-weight-medium">Role: </span>
                                    <span class="grey--text text--darken-1 font-weight-normal">
                                        <template v-if="selected_roles && selected_roles.length === 1">{{ local_get_role_name(selected_roles[0]) | truncateText(22) }}</template>
                                        <template v-else-if="selected_roles && selected_roles.length > 1">{{ selected_roles.length + ' Selected' }}</template>
                                        <template v-else>All Roles</template>
                                    </span>
                                </span>
                                <a-spacer></a-spacer>
                                <a-progress-circular v-if="filter_loading === 'role'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                <a-icon v-else size="20" class="ma-0">arrow_drop_{{ role_dropdown ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed">
                            <a-sheet class="c-input-wrap px-2">
                                <a-text-field
                                    v-model="local_search_role"
                                    placeholder="Search Role"
                                    background-color="neutral"
                                    class="md-body-2 font-weight-normal"
                                    solo flat hide-details autofocus
                                >
                                </a-text-field>
                            </a-sheet>
                        </a-list>
                        <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                            <a-list-item v-for="role in local_filtered_role_list" class="pr-5" :class="{ 'grey lighten-4': local_role_is_selected(role.id) }" :disabled="local_loading || pagination_loading" :key="role.id" @click="local_role_filter(role.id)">
                                <a-list-item-icon class="ma-0 pa-0 u-flex justify-center">
                                    <a-checkbox dense hide-details color="green darken-2" class="ma-0 pa-0" :value="local_role_is_selected(role.id)"></a-checkbox>
                                </a-list-item-icon>
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">{{ role.name }}</span>
                                </a-list-item-title>
                            </a-list-item>
                            <a-list-item v-if="local_search_role && (local_filtered_role_list && !local_filtered_role_list.length)">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">No Roles Found</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                        <a-divider></a-divider>
                        <a-list class="u-list-condensed u-overflow-y">
                            <a-list-item class="pr-5" :disabled="local_loading || pagination_loading" @click="local_role_filter()">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">All Roles</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                        <!-- <a-divider></a-divider>
                        <a-list class="u-list-condensed">
                            <span class="md-caption u-flex align-center mx-4 mb-2 mt-1 font-weight-medium grey--text text--darken-1">
                                <a-icon size="14" color="grey darken-2" class="mr-1">lightbulb</a-icon>
                                <span>Min. 1 role should be selected</span>
                            </span>
                        </a-list> -->
                    </a-menu>
                </a-flex>
                <a-flex shrink pr-1>
                    <a-menu bottom offset-y v-model="territory_dropdown" :close-on-content-click="false" max-width="280">
                        <template v-slot:activator="{ on }">
                            <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-border py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="264" v-on="on">
                                <div class="md-body-2 grey--text text--darken-2 mx-1 u-flex align-center">
                                    <span class="font-weight-medium mr-2">Territory: </span>
                                    <span class="grey--text text--darken-1 font-weight-normal">
                                        <template v-if="selected_territories && selected_territories.length === 1 && selected_territories[0] !== 'All Territories' && selected_territories[0] !== 'No Territory'">
                                            <g-tags :tag="local_get_role_territory(selected_territories[0])" :text-range="22" hide-clear-icon dense></g-tags>
                                        </template>
                                        <template v-else-if="selected_territories && selected_territories.length > 1">{{ selected_territories.length + ' Selected' }}</template>
                                        <template v-else-if="selected_territories[0] === 'No Territory'">No Territory</template>
                                        <template v-else>All Territories</template>
                                    </span>
                                </div>
                                <a-spacer></a-spacer>
                                <a-progress-circular v-if="filter_loading === 'territory'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                <a-icon v-else size="20" class="ma-0">arrow_drop_{{ territory_dropdown ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed">
                            <a-sheet class="c-input-wrap px-2">
                                <a-text-field
                                    v-model="local_search_territory"
                                    placeholder="Search Territory"
                                    background-color="neutral"
                                    class="md-body-2 font-weight-normal"
                                    solo flat hide-details autofocus
                                >
                                </a-text-field>
                            </a-sheet>
                        </a-list>
                        <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                            <a-list-item v-for="territory in local_filtered_territory_list" class="pr-5" :class="{ 'grey lighten-4': local_territory_is_selected(territory.id) }" :disabled="local_loading || pagination_loading" :key="territory.id" @click="local_territory_filter(territory.id)">
                                <a-list-item-icon class="ma-0 pa-0 u-flex justify-center">
                                    <a-checkbox dense hide-details color="green darken-2" class="ma-0 pa-0" :value="local_territory_is_selected(territory.id)"></a-checkbox>
                                </a-list-item-icon>
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">{{ territory.name }}</span>
                                </a-list-item-title>
                            </a-list-item>
                            <a-list-item v-if="local_search_territory && (local_filtered_territory_list && !local_filtered_territory_list.length)">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">No Territories Found</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                        <a-divider></a-divider>
                        <a-list class="u-list-condensed u-overflow-y">
                            <a-list-item class="pr-5" :disabled="local_loading || pagination_loading" @click="local_territory_filter('no_territory')">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">No Territory</span>
                                </a-list-item-title>
                            </a-list-item>
                            <a-list-item class="pr-5" :disabled="local_loading || pagination_loading" @click="local_territory_filter()">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">All Territories</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                        <!-- <a-divider></a-divider>
                        <a-list class="u-list-condensed">
                            <span class="md-caption u-flex align-center mx-4 mb-2 mt-1 font-weight-medium grey--text text--darken-1">
                                <a-icon size="14" color="grey darken-2" class="mr-1">lightbulb</a-icon>
                                <span>Min. 1 role should be selected</span>
                            </span>
                        </a-list> -->
                    </a-menu>
                </a-flex>
                <a-flex shrink pr-1>
                    <a-menu bottom offset-y v-model="user_privilege_dropdown" max-width="224">
                        <template v-slot:activator="{ on }">
                            <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-border py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="224" v-on="on">
                                <span class="md-body-2 grey--text text--darken-2 mx-1">
                                    <span class="font-weight-medium">Privilege: </span>
                                    <span class="grey--text text--darken-1 font-weight-normal">{{ selected_privilege.label }}</span>
                                </span>
                                <a-spacer></a-spacer>
                                <a-progress-circular v-if="filter_loading === 'privilege'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                <a-icon v-else size="20" class="ma-0">arrow_drop_{{ user_privilege_dropdown ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                            <a-list-item v-for="user_privilege in user_privileges" class="pr-5" :class="{ 'grey lighten-4': selected_privilege.value === user_privilege.value }" :disabled="local_loading || pagination_loading" :key="user_privilege.value" @click="local_user_privilege_filter(user_privilege)">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">{{ user_privilege.label }}</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink pr-1>
                    <a-menu bottom offset-y v-model="user_status_dropdown" max-width="224">
                        <template v-slot:activator="{ on }">
                            <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-border py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="224" v-on="on">
                                <span class="md-body-2 grey--text text--darken-2 mx-1">
                                    <span class="font-weight-medium">Status: </span>
                                    <span class="grey--text text--darken-1 font-weight-normal">{{ selected_status.label }}</span>
                                </span>
                                <a-spacer></a-spacer>
                                <a-progress-circular v-if="filter_loading === 'status'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                <a-icon v-else size="20" class="ma-0">arrow_drop_{{ user_status_dropdown ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                            <a-list-item v-for="user_status in user_statuses" class="pr-5" :class="{ 'grey lighten-4': selected_status.value === user_status.value }" :disabled="local_loading || pagination_loading" :key="user_status.value" @click="local_user_status_filter(user_status)">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">{{ user_status.label }}</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-flex shrink pr-1>
                    <a-menu bottom offset-y v-model="sort_dropdown" max-width="200">
                        <template v-slot:activator="{ on }">
                            <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-border py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="200" v-on="on">
                                <span class="md-body-2 grey--text text--darken-2 mx-1">
                                    <span class="font-weight-medium">Sort By: </span>
                                    <span class="grey--text text--darken-1 font-weight-normal">{{ selected_sort.label }}</span>
                                </span>
                                <a-spacer></a-spacer>
                                <a-progress-circular v-if="filter_loading === 'sort'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                <a-icon v-else size="20" class="ma-0">arrow_drop_{{ sort_dropdown ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                            <a-list-item v-for="sort_item in sort_fields" class="pr-5" :class="{ 'grey lighten-4': selected_sort.value === sort_item.value }" :disabled="local_loading || pagination_loading" :key="sort_item.value" @click="local_apply_sorting(sort_item, sort_direction)">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">{{ sort_item.label }}</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-flex shrink>
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-card class="u-rounded-corners u-overflow-hidden u-border u-cursor-pointer u-flex align-center justify-center" flat @click="local_apply_sorting(selected_sort, sort_direction === '-' ? '' : '-')" v-on="on" height="40" width="40">
                                <a-progress-circular v-if="filter_loading === 'sort_direction'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                <a-icon v-else size="20" class="ma-0">{{ (sort_direction === '') ? 'arrow_downward' : 'arrow_upward' }}</a-icon>
                            </a-card>
                        </template>
                        <span v-if="sort_direction === ''">Sorted Ascending</span>
                        <span v-if="sort_direction === '-'">Sorted Descending</span>
                    </a-tooltip>
                </a-flex>
            </a-layout>

            <a-layout wrap align-center mt-4>
                <a-flex xs12 v-if="local_loading">
                    <h2 class="title grey--text text--darken-2 text-center font-italic">Loading...</h2>
                </a-flex>
                <a-flex xs12 v-if="!local_loading && (user_list && !user_list.length)">
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                        <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
                    </a-responsive>
                </a-flex>
                <template v-if="!local_loading">
                    <a-flex xs12 v-for="(user) in local_user_enabled_list" :key="user.id">
                        <p-user-list
                            :user="user"
                            @toggle-admin="local_upgrade"
                            @toggle-billing="local_toggle_billing_update"
                            @toggle-block-user="local_toggle_disable"
                            @edit-territory="local_user_territory_edit"
                            @edit-user="local_edit"
                            @remove-territory="local_remove_association"
                        ></p-user-list>
                    </a-flex>
                </template>
            </a-layout>

            <a-layout class="mx-0 c-sticky-paginate-parent u-border mt-3 u-rounded-corners" v-if="local_user_enabled_list && local_user_enabled_list.length" :class="{ 'pa-4': !local_show_paginate_section }">
                <a-flex class="u-flex align-center py-0">
                    <div class="md-body-2 grey--text text--darken-2 font-weight-medium mr-2 d-inline-block">Showing {{ user_meta.from }} - {{ user_meta.to }} out of {{ user_meta.total }} user{{ user_meta.total > 1 ? 's' : '' }}</div>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex class="mr-16 pr-16 py-0 u-flex align-center justify-end" v-if="local_show_paginate_section">
                    <a-progress-circular v-if="pagination_loading" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                    <div class="text-right">
                        <a-pagination
                            v-model="user_meta.current_page"
                            :length="user_meta.last_page"
                            :total-visible="10"
                            class="c-inventory-pagination py-1"
                            @input="local_paginate()"
                        ></a-pagination>
                    </div>
                </a-flex>
            </a-layout>
        </a-container>

        <a-dialog v-model="dialog_user_form" max-width="600" scrollable persistent>
            <g-dialog-box hide-close-icon icon="person" icon-size="24">
                <template #title>
                    {{ (user_form_mode == 'add') ? 'Create New User' : 'Edit Existing User' }}
                </template>
                <template #subtitle>
                    {{ (user_form_mode == 'add') ? 'You can add new team member to collaborate with.' : 'You can edit existing team member information.' }}
                </template>
                <template #body>
                    <PUserEditBody
                        :manager-list="local_manager_list"
                        :form-mode="user_form_mode"
                    ></PUserEditBody>
                </template>
                <template #footer>
                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_store()" :loading="loading" :disabled="loading">Save User</a-btn>
                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="localCloseUserForm()" :disabled="loading">Cancel</a-btn>
                </template>
            </g-dialog-box>
        </a-dialog>


        <a-dialog max-width="700" v-model="dialog_territories_form" persistent>
            <g-dialog-box-simple @close="dialog_territories_form_close">
                <template #title>Manage territories for <span class="grey--text text--darken-1">{{ user_item.name | truncateText(25) }}</span></template>
                <template #body>
                    <s-territory-form
                        :item="user_item"
                        :list="local_difference_by(territory_list, user_item.territories, 'id')"
                        :can-update="$can('territories.store')"
                        :form-close="dialog_territories_form"
                        source="User"
                        type="user_territory"
                    >
                        <template #item="{ item, destroy }">
                            <g-tags :tag="item" :can-update="true" :key="item ? item.id : index" @destroy="destroy"></g-tags>
                        </template>
                    </s-territory-form>
                </template>
            </g-dialog-box-simple>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { STerritoryForm } from '@/config/config-shared-components'
import { PUserList, PUserEditBody } from './Partials'

export default {
    components: { STerritoryForm, PUserList, PUserEditBody },

    data () {
        return {
            local_loading: true,
            search_loading: false,
            filter_loading: null,
            pagination_loading: false,
            selected_roles: [],
            local_search_role: '',
            role_dropdown: false,
            selected_territories: [],
            local_search_territory: '',
            territory_dropdown: false,
            sort_direction: '-',
            sort_dropdown: false,
            selected_sort: { label: 'Joined Date', value: 'created_at' },
            sort_fields: [
                { label: 'Name', value: 'name' },
                { label: 'Email', value: 'email' },
                { label: 'Joined Date', value: 'created_at' },
                { label: 'Last Login', value: 'last_login_at' },
            ],
            user_status_dropdown: false,
            selected_status: { label: 'Active Users', value: 1 },
            user_statuses: [
                { label: 'All Users', value: null },
                { label: 'Active Users', value: 1 },
                { label: 'Blocked Users', value: 0 },
            ],
            dialog_user_form: false,
            dialog_territories_form: false,
            user_territory_item: null,
            user_territory_search: null,
            local_manager_search: null,
            autosave_territory_status: 'none',
            territory_exist_message: false,
            local_assignee_search: null,
            local_manager_search: null,
            user_filters: {},
            user_count: 25,
            USER_FILTERS: {
                PAGE: 'page',
                SORT: 'sort',
                COUNT: 'count',
                FIELDS: 'fields[users]',
                SEARCH: 'filter[user_search]',
                ROLE: 'filter[role_id]',
                TERRITORY_FIELDS: 'fields[territories]',
                ROLE_FIELDS: 'fields[role]',
                MANAGER_FIELDS: 'fields[manager]',
                INCLUDE: 'include',
                IS_ACTIVE: 'filter[is_active]',
                USER_ROLE_FILTER: 'filter[role_id]',
                USER_ADMIN: 'filter[is_admin]',
                USER_BILLING: 'filter[is_billing]',
            },
            role_filters: {},
            ROLE_FILTERS: {
                SORT: 'sort',
                COUNT: 'count',
                FIELDS: 'fields[roles]',
                SCOPE: 'filter[scope]',
            },
            territory_filters: {},
            TERRITORY_FILTERS: {
                COUNT: 'count',
                FIELDS: 'fields[territories]',
            },
            local_page_count: null,
            search_user: null,
            local_search_role: '',
            search_role: false,
            role_filter_mode: true,
            select_role_name: null,
            search_timeout: null,
            user_privilege_dropdown: false,
            user_privileges: [
                { label: 'All Privileges', value: 0 },
                { label: 'Admin and Billing', value: 1 },
                { label: 'Only Admin', value: 2 },
                { label: 'Only Billing', value: 3 },
                { label: 'No Privileges', value: 4 },
            ],
            selected_privilege: { label: 'All Privileges', value: 0 },
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        local_user_enabled_list() {
            return [...this.user_list ]
        },

        local_filtered_role_list() {
            return this.role_list.filter(list => list.name.toLowerCase().includes(this.local_search_role.toLowerCase()))
        },

        local_filtered_territory_list() {
            return this.territory_list.filter(list => list.name.toLowerCase().includes(this.local_search_territory.toLowerCase()))
        },

        local_manager_list() {
            if (this.user_form_mode == 'edit') {
                return _.remove(_.cloneDeep(this.user_total_list), (user) => {
                    return user.id != this.user_item.id
                })
            }
            return this.user_total_list
        },

        local_show_paginate_section () {
            return this.user_meta.current_page >= 1 && this.user_meta.last_page !== 1 && this.user_meta.total > 25
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
            user_list: 'list',
            user_total_list: 'total_list',
            user_item: 'item',
            user_meta: 'meta',
            user_action: 'action',
            user_form_mode: 'form_mode',
            user_response: 'response',
        }),

        ...mapState('Role', {
            role_list: 'list',
        }),

        ...mapState('Association', {
            association_response: 'response',
        }),

        ...mapState('Territory', {
            territory_list: 'list',
            territory_item: 'item',
        }),
    },

    watch: {
        dialog_territories_form(new_value, old_value){
            if (new_value === false) {
                this.project_territory_search = null
            }
        },

        search_user(new_value, old_value){
            if (!new_value) {
                this.user_filters[this.USER_FILTERS.SEARCH] = null
                this.local_load_users()
            }
        }
    },

    methods: {
        async local_index() {
            await this.user_clear()
            this.role_clear()
            this.territory_clear()

            await this.local_load_users()
            this.local_page_count = this.user_meta.current_page
            this.local_loading = false

            this.local_load_roles()
            this.local_load_territories()
        },

        async local_search_users() {
            clearTimeout(this.search_timeout)
            this.search_timeout = window.setTimeout(async () => {
                if (!this.search_user.trim()) return
                if (!this.search_user || this.search_user.trim().length <= 3) {
                    this.$notify('error', 'Try more than 3 characters to search.')
                    return
                }
                this.search_loading = true
                this.user_filters[this.USER_FILTERS.SEARCH] = this.search_user
                this.user_filters[this.USER_FILTERS.COUNT] = this.user_count
                this.user_filters[this.USER_FILTERS.PAGE] = 1
                await this.local_fetch_users()
                this.search_loading = false
                this.local_search_mode = true
            }, 1000)
        },

        async local_load_users (sort_by, is_active) {
            sort_by = (this.selected_sort && this.selected_sort.value) ?? null
            is_active = (this.selected_status && this.selected_status.value) ?? null

            this.user_filters[this.USER_FILTERS.PAGE] = 1
            this.user_filters[this.USER_FILTERS.COUNT] = this.user_count
            this.user_filters[this.USER_FILTERS.IS_ACTIVE] = is_active ?? 'all'
            this.user_filters[this.USER_FILTERS.FIELDS] = 'id,name,email,last_login_at,role_id,job_title,avatar,color,initial,is_active,is_admin,manager_id,role_id,is_billing,created_at'
            this.user_filters[this.USER_FILTERS.INCLUDE] = 'role,manager,territories'
            this.user_filters[this.USER_FILTERS.ROLE_FIELDS] = 'id,name'
            this.user_filters[this.USER_FILTERS.MANAGER_FIELDS] = 'id,name'
            this.user_filters[this.USER_FILTERS.TERRITORY_FIELDS] = 'territories.id,territories.name,territories.color'
            this.user_filters[this.USER_FILTERS.SORT] = this.sort_direction + (sort_by ?? 'created_at')
            await this.local_fetch_users()
        },

        async local_load_roles () {
            this.role_filters[this.ROLE_FILTERS.COUNT] = 999
            this.role_filters[this.ROLE_FILTERS.FIELDS] = 'id,name'
            this.role_filters[this.ROLE_FILTERS.SCOPE] = 'internal'
            this.role_filters[this.ROLE_FILTERS.SORT] = 'order'
            await this.role_index({ ...this.role_filters })
            // this.filters['filter[role_id]'] = this.selected_roles.join(',')
            // await this.role_index(this.role_filters)
        },

        async local_role_filter (role_id) {
            this.filter_loading = 'role'
            if (!role_id) {
                this.selected_roles = []
                this.user_filters[this.USER_FILTERS.ROLE]  = null
                this.filter_loading = null
                this.role_dropdown = false
                return this.local_load_users()
            }

            if (this.selected_roles && !this.selected_roles.length) this.selected_roles.push(role_id)
            else {
                const index = this.selected_roles.findIndex(id => id === role_id)
                if (index !== -1) this.selected_roles.splice(index, 1)
                else this.selected_roles.push(role_id)
            }
            this.user_filters[this.USER_FILTERS.ROLE] = this.selected_roles.join(',')
            await this.local_load_users()
            this.filter_loading = null
        },

        local_role_is_selected (role_id) {
            return this.selected_roles.includes(role_id)
        },

        local_territory_is_selected (territory_id) {
            return this.selected_territories.includes(territory_id)
        },

        async local_apply_sorting (sort_item, direction) {
            this.filter_loading = (direction !== this.sort_direction) ? 'sort_direction' : 'sort'
            this.selected_sort = sort_item
            this.sort_direction = direction
            await this.local_load_users()
            this.filter_loading = null
        },

        async local_user_status_filter (status) {
            this.filter_loading = 'status'
            this.selected_status = status
            await this.local_load_users()
            this.filter_loading = null
        },

        async local_territory_filter (territory_id) {
            this.filter_loading = 'territory'
            if (territory_id === 'no_territory') {
                this.selected_territories = ['No Territory']
                this.user_filters['filter[territory_id]'] = 'none'
                this.filter_loading = null
                this.territory_dropdown = false
                return this.local_load_users()
            }
            if (!territory_id) {
                this.selected_territories = []
                this.user_filters['filter[territory_id]'] = null
                this.filter_loading = null
                this.territory_dropdown = false
                return this.local_load_users()
            }

            if (this.selected_territories && !this.selected_territories.length) this.selected_territories.push(territory_id)
            else {
                const index = this.selected_territories.findIndex(id => id === territory_id)
                if (this.selected_territories[0] === 'No Territory') this.selected_territories.splice(0, 1)
                if (index !== -1) this.selected_territories.splice(index, 1)
                else this.selected_territories.push(territory_id)
            }

            this.user_filters['filter[territory_id]'] = this.selected_territories
            await this.local_load_users()
            this.filter_loading = null
        },

        local_get_role_name (role_id) {
            const role = this.role_list.find(item => item.id === role_id)
            return role ? role.name : ''
        },

        local_get_role_territory (territory_id) {
            return this.territory_list.find(item => item.id === territory_id)
        },

        async local_load_territories() {
            this.territory_filters[this.TERRITORY_FILTERS.COUNT] = 999
            this.territory_filters[this.TERRITORY_FILTERS.FIELDS] = 'id,name,color'
            await this.territory_index(this.territory_filters)
        },

        async local_fetch_users() {
            await this.user_index({...this.user_filters})
        },

        // async local_paginate(value) {
        //     this.user_filters[this.USER_FILTERS.PAGE] = value
        //     this.local_fetch_users()
        // },

        async local_edit(id) {
            await this.user_select({ id })
            this.dialog_user_form = true
        },

        localCloseUserForm () {
            this.dialog_user_form = false
            this.user_clear_item()
        },

        async local_store () {
            let managerId = null
            if (this.user_item.manager_id) managerId = this.user_item.manager_id
            if (this.user_item.manager) managerId = this.user_item.manager.id
            if (!this.user_item.manager) managerId = null

            await this.user_update({
                id: this.user_item.id,
                name: this.user_item.name,
                email: this.user_item.email,
                job_title: this.user_item.job_title,
                role_id: this.user_item.role_id,
                manager_id: managerId,
            })

            if (this.user_response.status !== 'success') return this.$notify('error', 'Something went wrong! Please re-check the fields and try again.')

            const userId = (this.user_item && this.user_item.id) ?? null
            this.local_user_show(userId, this.user_form_mode, { 'include': 'role,manager,territories', 'fields[territories]': 'territories.id,color,name', 'fields[users]': 'id,name,initial,email,avatar,job_title,color,is_active,is_billing,is_admin,role_id,manager_id,created_at', 'fields[role]': 'id,name' })
            this.$notify('success', 'User updated successfully!')
            this.localCloseUserForm()
            if (this.user_item.id === this.user_self.id) this.user_me()
        },

        async local_toggle_billing_update(id, { is_billing }) {
            await this.user_billing_toggle({ id, state: is_billing ? 0 : 1 })
            if (this.user_response.status === 'success') {
                this.$notify('success', `${ is_billing === 1 ? 'Disabled' : 'Enabled' } billing privilege`)
            }
        },

        async local_user_show(id, mode, include = null) {
            if (id === this.user_self.id) mode = 'self'
            await this.user_show({ id , mode, params: include })
        },

        async local_upgrade(id, state) {
            const upgrade_item = {}
            upgrade_item.id = id
            upgrade_item.state = state ? 0 : 1
            await this.user_upgrade(upgrade_item)
            if (this.user_response.status === 'success') {
                await this.local_user_show(id, 'show', {  'fields': 'users.id,users.is_admin' })
                await this.user_clear_item()
                this.$notify('success', `${ state === 1 ? 'Disabled' : 'Enabled' } admin privilege`)
            }
        },

        async local_toggle_disable({ id, name }, state) {
            await this.user_disable({id: id, state : state })
            if (this.user_response.status === 'success') {
                this.$notify('success', `${this.$options.filters.truncateText(name, 30)} has been ${ state === 0 ? 'blocked' : 'unblocked' }`)
                this.local_user_show(id, 'update', { 'include': 'role,manager,territories', 'fields[territories]': 'territories.id,color,name', 'fields[users]': 'id,name,email,avatar,color,is_active,is_billing,is_admin,role_id,manager_id,created_at', 'fields[role]': 'id,name' })
            }
        },

        local_user_territory_edit(user_id) {
            this.user_select({id: user_id})
            this.dialog_territories_form = true
        },

        async local_user_territory_store() {
            if (!this.user_territory_item) return
            this.territory_exist_message = false
            clearTimeout(this.autosave_territory_timeout)
            this.autosave_territory_status = 'loading'
            if ((typeof this.user_territory_item) === 'string' && this.$can('territories.store')) {
                await this.territory_clear_item()
                let territory_exist = this.territory_list.filter((item) => item.name.split(' ').join('-').toLowerCase() === this.user_territory_item.split(' ').join('-').toLowerCase())
                if (territory_exist.length) {
                    this.local_territory_exist_error()
                    return
                }
                this.territory_item.name = this.user_territory_item
                this.territory_item.type = 'territory'
                await this.territory_store({ ...this.territory_item })
                this.user_territory_item = _.cloneDeep(this.territory_item)
            }
            const data = { source_type : "User", source_id : this.user_item.id, target_type: "Territory", target_id : this.user_territory_item.id, type: 'user_territory' }
            await this.local_association_store(this.user_item.id, data, 'territories')
            this.user_territory_item = null
            await this.territory_clear_item()
            this.autosave_territory_status = 'done'
            this.autosave_territory_timeout = window.setTimeout(() => {
                this.autosave_territory_status = 'none'
                clearTimeout(this.autosave_territory_timeout)
            }, 2000)
        },

        async local_user_territory_destroy(user_id, territory_id) {
            clearTimeout(this.autosave_territory_timeout)
            this.autosave_territory_status = 'loading'
            await this.user_territory_destroy({id: user_id, territory_id: territory_id})
            this.autosave_territory_status = 'done'
            this.autosave_territory_timeout = window.setTimeout(() => {
                this.autosave_territory_status = 'none'
                clearTimeout(this.autosave_territory_timeout)
            }, 2000)
        },

        async dialog_territories_form_close() {
            this.project_territory_search = null
            this.dialog_territories_form = false
            this.local_user_show(this.user_item.id, 'show', { include: 'territories', 'fields[territories]': 'territories.id,color,name' })
        },

        local_difference_by(source_data, target_data, property) {
            return _.differenceBy(source_data, target_data, property)
        },

        local_territory_exist_error() {
            this.autosave_territory_status = 'none'
            this.user_territory_item = null
            this.territory_exist_message = !this.territory_exist_message
            setTimeout(() => {
                this.territory_exist_message = false
            }, 3000);
        },

        local_get_initials(name) {
            let initials_array = _.split(name, ' ', 2)
            let initial_first = _.truncate(initials_array[0], {length: 1, omission: ''})
            let initial_second = _.truncate(initials_array[1], {length: 1, omission: ''})
            return initial_first + initial_second
        },

        async local_association_store(id,  data, include) {
            await this.association_store(data)
            if (this.association_response.status === 'success')  this.local_user_show(id, 'show', { 'include': include })
        },

        async local_association_destroy(id, data, include) {
            await this.association_destroy(data.association)
            if (this.association_response.status === 'success')  this.local_user_show(id, 'show', { 'include': include })
        },

        async local_paginate () {
            if(this.local_page_count == this.user_meta.current_page) return
            this.pagination_loading = true
            await this.$vuetify.goTo(0)
            this.user_filters[this.USER_FILTERS.PAGE] = this.user_meta.current_page
            await this.local_fetch_users()
            this.local_page_count = this.user_meta.current_page
            this.pagination_loading = false
        },

        async local_remove_association (user, data) {
            this.local_remove_territory(user, data)
            await this.association_destroy(data.association)
            if (this.association_response.status !== 'success') return
        },

        async local_remove_territory (user, territory) {
            const index = user.territories.findIndex(item => item.id === territory.id)
            if (index !== -1) user.territories.splice(index, 1)
        },

        async local_user_privilege_filter (privilege) {
            this.filter_loading = 'privilege'
            this.selected_privilege = privilege

            switch (privilege.value) {
                case 0:
                    this.user_filters[this.USER_FILTERS.USER_ADMIN] = null
                    this.user_filters[this.USER_FILTERS.USER_BILLING] = null
                    break
                case 1:
                    this.user_filters[this.USER_FILTERS.USER_ADMIN] = 1
                    this.user_filters[this.USER_FILTERS.USER_BILLING] = 1
                    break
                case 2:
                    this.user_filters[this.USER_FILTERS.USER_ADMIN] = 1
                    this.user_filters[this.USER_FILTERS.USER_BILLING] = 0
                    break
                case 3:
                    this.user_filters[this.USER_FILTERS.USER_ADMIN] = 0
                    this.user_filters[this.USER_FILTERS.USER_BILLING] = 1
                    break
                case 4:
                    this.user_filters[this.USER_FILTERS.USER_ADMIN] = 0
                    this.user_filters[this.USER_FILTERS.USER_BILLING] = 0
                    break
            }
            await this.local_load_users()
            this.filter_loading = null
        },

        ...mapActions('Association', {
            association_store: 'store',
            association_destroy: 'destroy',
        }),

        ...mapActions('User', {
            user_index: 'index',
            user_enabled: 'enabled',
            user_me: 'me',
            user_show: 'show',
            user_store: 'store',
            user_update: 'update',
            user_update_meta: 'update_meta',
            user_select: 'select',
            user_upgrade: 'upgrade',
            user_downgrade: 'downgrade',
            user_billing_toggle: 'billing_toggle',
            user_enable: 'enable',
            user_disable: 'disable',
            user_territory_store: 'territory_store',
            user_territory_destroy: 'territory_destroy',
            user_clear: 'clear',
            user_clear_item: 'clear_item',
        }),

        ...mapActions('Role', {
            role_index: 'index',
            role_clear: 'clear',
            role_clear_item: 'clear_item',
        }),

        ...mapActions('Territory', {
            territory_index: 'index',
            territory_store: 'store',
            territory_clear: 'clear',
            territory_clear_item: 'clear_item',
        }),

        ...mapActions('Association', {
            association_store: 'store',
            association_destroy: 'destroy',
        }),

    },
}
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>

<template>
	<a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="py-6 pt-2 px-0 mb-10">
            <g-page-header
                title="Organization"
                subtitle="Manage your organization profile."
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                icon="supervised_user_circle"
            ></g-page-header>
            <a-layout wrap align-start>
                <a-flex xs12>
                    <a-card class="u-elevation-custom-1">
                        <a-card-text class="pa-0">
                            <a-form>
                                <a-container container--fluid grid-list-xl class="py-6 px-4 pt-0">
                                    <a-layout wrap align-start class="px-3 mb-2">
                                        <a-flex xs4 xl3 class="pr-4">
                                            <a-layout wrap class="pa-0">
                                                <a-flex>
                                                    <a-sheet min-width="100%" min-height="280" max-height="280" tile class="c-org-logo u-rounded-corners text-center u-flex align-center justify-center" style="position: relative; border: 1px dashed #ccc">
                                                        <a-avatar size="256" tile color="white" class="u-rounded-corners" style="margin-top: 12px; margin-bottom: 12px" v-if="organization_item && organization_item.logo">
                                                            <a-img :src="organization_item.logo" class="u-rounded-corners" contain></a-img>
                                                        </a-avatar>
                                                        <div v-if="!organization_item || organization_item && !organization_item.logo && !loading" @click="local_logo_select()" class="c-org-logo__img u-cursor-pointer text-center">
                                                            <a-btn small icon color="transparent" class="ma-0 elevation-0" :disabled="loading">
                                                                <a-icon size="64" color="grey lighten-1">cloud_upload</a-icon>
                                                            </a-btn>
                                                            <span class="grey--text text--darken-1 body-2 d-block mt-6">Upload Organization Logo</span>
                                                        </div>
                                                    </a-sheet>
                                                </a-flex>
                                                <a-flex xs12 shrink class="text-center">
                                                    <input type="file" ref="organization_logo" accept="image/jpg,image/jpeg,image/png" style="display: none;" @change="local_logo_upload()">
                                                    <template v-if="organization_item && organization_item.logo">
                                                        <a-btn small color="primary" class="text-center u-rounded-corners" text @click="local_logo_select()" :disabled="loading">
                                                            Change Logo
                                                        </a-btn>
                                                        <a-btn small color="red darken-1" class="text-center u-rounded-corners" text @click="local_logo_destroy()" :disabled="loading">
                                                            Remove Logo
                                                        </a-btn>
                                                    </template>
                                                    <span class="caption font-weight-normal d-block grey--text text--darken-1 mt-1"><strong>JPG/JPEG/PNG</strong> &bull; Max <strong>2MB</strong> &bull; Size <strong>512x512</strong></span>
                                                </a-flex>
                                            </a-layout>
                                        </a-flex>
                                        <a-flex xs8 xl9>
                                            <a-layout wrap align-center>
                                                <a-flex xs12>
                                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Company Name</label>
                                                    <a-text-field
                                                        v-model="org_form_item.name"
                                                        :disabled="local_loading"
                                                        placeholder="Enter Company Name"
                                                        background-color="neutral"
                                                        solo flat hide-details
                                                    >
                                                    </a-text-field>
                                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="organization_response.server && organization_response.server.errors && organization_response.server.errors.name">
                                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                                        {{ organization_response.server.errors.name[0] }}
                                                    </span>
                                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                        <a-icon size="16">info</a-icon>
                                                        Your organization's name
                                                    </span>
                                                </a-flex>
                                                <a-flex xs4>
                                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">City</label>
                                                    <a-text-field
                                                        v-model="org_form_item.city"
                                                        :disabled="local_loading"
                                                        placeholder="Enter City"
                                                        background-color="neutral"
                                                        solo flat hide-details
                                                    >
                                                    </a-text-field>
                                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="organization_response.server && organization_response.server.errors && organization_response.server.errors.city">
                                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                                        {{ organization_response.server.errors.city[0] }}
                                                    </span>
                                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                        <a-icon size="16">info</a-icon>
                                                        Enter the name of the city
                                                    </span>
                                                </a-flex>
                                                <a-flex xs4>
                                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">State</label>
                                                    <a-text-field
                                                        v-model="org_form_item.state"
                                                        :disabled="local_loading"
                                                        placeholder="Enter State"
                                                        background-color="neutral"
                                                        solo flat hide-details
                                                    >
                                                    </a-text-field>
                                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="organization_response.server && organization_response.server.errors && organization_response.server.errors.state">
                                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                                        {{ organization_response.server.errors.state[0] }}
                                                    </span>
                                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                        <a-icon size="16">info</a-icon>
                                                        Enter the name of the state
                                                    </span>
                                                </a-flex>
                                                <a-flex xs4>
                                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Zipcode</label>
                                                    <a-text-field
                                                        v-model="org_form_item.zipcode"
                                                        :disabled="local_loading"
                                                        placeholder="Enter Zipcode"
                                                        background-color="neutral"
                                                        solo flat hide-details
                                                    >
                                                    </a-text-field>
                                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="organization_response.server && organization_response.server.errors && organization_response.server.errors.zipcode">
                                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                                        {{ organization_response.server.errors.zipcode[0] }}
                                                    </span>
                                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                        <a-icon size="16">info</a-icon>
                                                        Enter the zipcode
                                                    </span>
                                                </a-flex>
                                            </a-layout>
                                            <a-layout wrap align-center>
                                                <a-flex xs3>
                                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Country</label>
                                                    <a-autocomplete
                                                        :items="country_list"
                                                        :disabled="local_loading"
                                                        item-text="name"
                                                        item-value="id"
                                                        v-model="org_form_item.country_id"
                                                        placeholder="Select Country"
                                                        background-color="neutral"
                                                        solo flat hide-details
                                                    >
                                                        <template v-slot:item="{ item }">
                                                            <a-list-item-content>
                                                                <a-list-item-subtitle>{{ item.name }}</a-list-item-subtitle>
                                                            </a-list-item-content>
                                                        </template>
                                                    </a-autocomplete>
                                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="organization_response.server && organization_response.server.errors && organization_response.server.errors.country_id">
                                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                                        {{ organization_response.server.errors.country_id[0] }}
                                                    </span>
                                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                        <a-icon size="16">info</a-icon>
                                                        Select country
                                                    </span>
                                                </a-flex>
                                                <a-flex xs3>
                                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Currency</label>
                                                    <a-autocomplete
                                                        :items="currency_list"
                                                        :disabled="local_loading"
                                                        item-text="name"
                                                        item-value="id"
                                                        v-model="org_form_item.currency_id"
                                                        placeholder="Select Currency"
                                                        background-color="neutral"
                                                        solo flat hide-details
                                                    >
                                                        <template v-slot:item="{ item }">
                                                            <a-list-item-content>
                                                                <a-list-item-subtitle>{{ item.name }}</a-list-item-subtitle>
                                                            </a-list-item-content>
                                                        </template>
                                                    </a-autocomplete>
                                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="organization_response.server && organization_response.server.errors && organization_response.server.errors.currency_id">
                                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                                        {{ organization_response.server.errors.currency_id[0] }}
                                                    </span>
                                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                        <a-icon size="16">info</a-icon>
                                                        Select currency
                                                    </span>
                                                </a-flex>
                                                <a-flex xs3>
                                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Timezone</label>
                                                    <a-autocomplete
                                                        :items="timezone_list"
                                                        :disabled="local_loading"
                                                        item-text="name"
                                                        item-value="id"
                                                        v-model="org_form_item.timezone_id"
                                                        placeholder="Select Timezone"
                                                        background-color="neutral"
                                                        solo flat hide-details
                                                    >
                                                        <template v-slot:item="{ item }">
                                                            <a-list-item-content :title="item.name + ' (' + item.offset + ')'">
                                                                <a-list-item-subtitle>{{ item.name }} ({{ item.offset }})</a-list-item-subtitle>
                                                            </a-list-item-content>
                                                        </template>
                                                    </a-autocomplete>
                                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="organization_response.server && organization_response.server.errors && organization_response.server.errors.timezone_id">
                                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                                        {{ organization_response.server.errors.timezone_id[0] }}
                                                    </span>
                                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                        <a-icon size="16">info</a-icon>
                                                        Select timezone
                                                    </span>
                                                </a-flex>
                                                <a-flex xs3>
                                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Fiscal Month</label>
                                                    <a-autocomplete
                                                        :items="month_list"
                                                        :disabled="local_loading"
                                                        item-text="name"
                                                        item-value="id"
                                                        v-model="org_form_item.financial_month"
                                                        placeholder="Select Fiscal Month"
                                                        background-color="neutral"
                                                        solo flat hide-details
                                                    >
                                                        <template v-slot:item="{ item }">
                                                            <a-list-item-content>
                                                                <a-list-item-subtitle>{{ item.name }}</a-list-item-subtitle>
                                                            </a-list-item-content>
                                                        </template>
                                                    </a-autocomplete>
                                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="organization_response.server && organization_response.server.errors && organization_response.server.errors.timezone_id">
                                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                                        {{ organization_response.server.errors.timezone_id[0] }}
                                                    </span>
                                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                        <a-icon size="16">info</a-icon>
                                                        Select fiscal month
                                                    </span>
                                                </a-flex>
                                            </a-layout>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                                <a-divider></a-divider>
                                <a-container container--fluid grid-list-xl class="pa-6">
                                    <a-layout wrap align-center>
                                        <a-flex shrink>
                                            <a-btn color="grey" class="ma-0 elevation-0 pl-0" text :to="{name: 'settings'}">
                                                <a-icon left class="mx-2">keyboard_arrow_left</a-icon>
                                                Cancel
                                            </a-btn>
                                        </a-flex>
                                        <a-spacer></a-spacer>
                                        <a-flex shrink>
                                            <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_store()" :loading="loading" :disabled="loading">Save Organization Profile</a-btn>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-form>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
        <a-snackbar v-model="snackbar_status" auto-height :timeout="snackbar_timeout" bottom left>
            <div class="u-flex align-center">
                <div class="white--text">{{ snackbar_text }}</div>
                <a-btn color="red lighten-3" class="" text small plain @click="snackbar_status = false">Close</a-btn>
            </div>
        </a-snackbar>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            month_list: [
                { id: 1, name: 'January' },
                { id: 2, name: 'February' },
                { id: 3, name: 'March' },
                { id: 4, name: 'April' },
                { id: 5, name: 'May' },
                { id: 6, name: 'June' },
                { id: 7, name: 'July' },
                { id: 8, name: 'August' },
                { id: 9, name: 'September' },
                { id: 10, name: 'October' },
                { id: 11, name: 'November' },
                { id: 12, name: 'December' },
            ],
            org_form_item: {},
            local_loading: true,
            snackbar_status: false,
            snackbar_timeout: 3000,
            snackbar_text: '',
            dialog_stage_form: false,
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Organization', {
            organization_item: 'item',
            organization_response: 'response',
        }),
        ...mapState('Country', {
            country_list: 'list',
        }),
        ...mapState('Currency', {
            currency_list: 'list',
        }),
        ...mapState('Timezone', {
            timezone_list: 'list',
        }),
    },
    methods: {
        async local_index() {
            await this.organization_clear()
            await this.organization_me()
            await this.country_index()
            await this.currency_index()
            await this.timezone_index()
            this.org_form_item = _.cloneDeep(this.organization_item)
            this.local_loading = false
        },
        async local_store() {
            this.local_loading = true
            await this.organization_update(this.org_form_item)
            if (this.organization_response.status === 'success') {
                this.snackbar_text = 'Organization updated successfully!'
                this.snackbar_status = true
                await this.organization_me()
                this.org_form_item = _.cloneDeep(this.organization_item)
                // await this.user_me()
                // this.$router.push('/dashboard')
            }
            this.local_loading = false

        },
        local_logo_select() {
            this.$refs.organization_logo.value = ''
            this.$refs.organization_logo.click()
        },
        async local_logo_upload() {
            this.local_clear_snackbar()
            if (this.$refs.organization_logo.files.length > 0) {
                await this.organization_logo_update({logo: this.$refs.organization_logo.files[0]})
                if (this.organization_response.status === 'error') {
                    this.snackbar_text = 'Unable to upload the logo. Please make sure the image meets the requirement outlined above.'
                    this.snackbar_status = true
                    return
                }
                await this.organization_me()
            }
        },
        local_clear_snackbar() {
            this.snackbar_status = false
            this.snackbar_text = null
        },
        async local_logo_destroy() {
            await this.organization_logo_destroy()
            await this.organization_me()
        },
        ...mapActions('Organization', {
            organization_me: 'me',
            organization_update: 'update',
            organization_logo_update: 'logo_update',
            organization_logo_destroy: 'logo_destroy',
            organization_clear: 'clear',
        }),
        ...mapActions('User', {
            user_me: 'me',
        }),
        ...mapActions('Country', {
            country_index: 'index',
        }),
        ...mapActions('Currency', {
            currency_index: 'index',
        }),
        ...mapActions('Timezone', {
            timezone_index: 'index',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .c-org-logo {
        &__img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
            width: 180px !important;
            height: 96px !important;
            .a-btn {
                width: 80px !important;
                height: 80px !important;
            }
        }
    }
</style>

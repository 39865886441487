<template>
    <SDropdown v-bind="$attrs" v-on="$listeners" :list="localOrderedList($attrs.list)">
        <template #input="{ open }">
            <a-sheet @click.stop="open()" class="u-flex-center-y px-3 u-cursor-pointer transparent" width="100%" height="100%">
                <a-sheet v-if="!item.assignees || !item.assignees.length" color="grey" class="u-rounded-corners-full u-flex-center" :width="avatarSize" :height="avatarSize">
                    <a-icon color="white" :size="avatarSize / 2">group_add</a-icon>
                </a-sheet>
                <SUsersDeck v-else :list="item.assignees" :hideList="isActive" :avatar-size="avatarSize" :count="2" />
            </a-sheet>
        </template>
        <template #menu-list="{ list, focusIndex, selectItem, classList, styleList }">
            <div class="u-hfull">
                <div class="u-flex-center-y py-3 px-4">
                    <a-icon color="indigo darken-2" size="18">group_add</a-icon>
                    <h5 class="md-subtitle-2 indigo--text text--darken-2 ml-2">Assign to collaborators</h5>
                </div>
                <input
                    :autofocus="isActive"
                    @input="searchFn"
                    type="text"
                    placeholder="Search assignees"
                    class="u-wfull u-hfull px-4 py-3 md-body-2 grey lighten-4"
                    style="outline: none; border-top: 1px solid #EEEEEE !important; border-bottom: 1px solid #EEEEEE !important;"
                >
            </div>
            <ul :class="['c-custom-dropdown white u-hfull pa-2', classList]" :style="styleList">
                <li v-if="list && list.length === 0" class="pa-2 u-rounded-corners u-cursor-pointer md-body-2 grey--text text--darken-1">
                    No Assignees Found
                </li>
                <template v-else>
                    <template v-for="(collab, index) in localOrderedList(list)">
                        <li
                            :key="collab.user_id"
                            :class="[{ 'grey lighten-4': focusIndex === index }]"
                            :data-link-type="collab.id !== 'no-data' ? 'tag-item' : 'no-data'"
                            @click="selectItem(collab)"
                            class="pa-2 u-rounded-corners u-cursor-pointer md-subtitle-1 c-hover-state-light"
                        >
                            <div class="u-flex-center-y">
                                <div class="u-flex">
                                    <g-avatar :item="collab" :size="32" fontSize="10" style="margin-top: 2px;"></g-avatar>
                                    <g-profile-info :item="collab" set-max-width></g-profile-info>
                                </div>
                                <template v-if="isAddedFn(collab, item)">
                                    <a-spacer></a-spacer>
                                    <a-icon color="indigo darken-2" size="20">done</a-icon>
                                </template>
                            </div>
                        </li>
                    </template>
                </template>
            </ul>
        </template>
    </SDropdown>
</template>

<script>
import SDropdown from '@/components/Shared/SharedDropdown.vue'
import { SUsersDeck } from '@/config/config-shared-components'

export default {
    components: { SDropdown, SUsersDeck },

    props: {
        item: {
            type: Object
        },
        searchFn: {
            type: Function
        },
        isAddedFn: {
            type: Function
        },
        avatarSize: {
            type: Number | String,
            default: 32
        },
        isActive: {
            type: Boolean,
            default: false
        },
        hideSelectionIcon: {
            type: Boolean,
            default: false
        },
    },

    watch: {
        isActive: {
            handler (val) {
                if (val) this.searchFn(null, { immediate: true })
            },
            immediate: true
        }
    },

    methods: {
        localOrderedList (list) {
            const selected = list.filter(i => this.isAddedFn(i, this.item))
            const deSelected = list.filter(i => !this.isAddedFn(i, this.item))
            return [...selected, ...deSelected]
        }
    },
}
</script>

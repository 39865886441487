import { mapActions, mapGetters } from 'vuex'

export default {
    watch: {
        workspace_current() {
            const whitelist = [
                'dashboard',
                'dashboard-insights',
                'dashboard-insights-type',
                'dashboard-columns',
                'dashboard-timeline',
                'settings-workspace-view',
                'settings-workspace-stages',
                'settings-workspace-members',
            ]

            if (!whitelist.includes(this.$route.name)) {
                if ('localIndex' in this) {
                    this.localIndex()
                }

                if ('local_index' in this) {
                    this.local_index()
                }
            }
        }
    },

    computed: {
        active_workspaces_mixin() {
            return this.workspace_board_list.filter(workspace => workspace.is_active)
        },
        default_workspace_mixin() {
            return this.workspace_board_list.find(workspace => workspace.is_default)
        },
        current_workspace_id_mixin() {
            return this.current_workspace_mixin ? this.current_workspace_mixin.id : null
        },
        current_workspace_mixin() {
            const prefWorkspaceValue = this.preference_workspace ? this.preference_workspace.value : null
            const workspace_id = this.workspace_current ?? prefWorkspaceValue
            const active = this.active_workspaces_mixin.find(workspace => workspace.id === workspace_id)
            return active
        },
        settings_workspace_id_mixin() {
            return this.settings_workspace_mixin ? this.settings_workspace_mixin.id : null
        },
        settings_workspace_mixin() {
            const { params: { id: id } } = this.$route
            return this.workspace_list.find(workspace => workspace.id === id)
        },
        ...mapGetters('User', {
            user_self: 'self',
        }),
        ...mapGetters('Preference', {
            preference_workspace: 'pref_workspace',
        }),
        ...mapGetters('Workspace', {
            workspace_list: 'list',
            workspace_board_list: 'board_list',
            workspace_current: 'current'
        })
    },

    methods: {
        ...mapActions('Workspace', {
            workspace_board: 'board',
            workspace_clear: 'clear'
        })
    }
}

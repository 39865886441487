<template>
    <g-dialog-box icon="insert_drive_file" hide-close-icon>
        <template #title>Export Use Cases as Template</template>
        <template #subtitle>You can save list of use cases to import them in new projects.</template>
        <template #body="{ bodyClass }">
            <a-form>
                <a-container :class="bodyClass">
                    <a-layout wrap align-center>
                        <a-flex xs12 pt-0>
                            <v-radio-group class="ma-0 pa-0" v-model="export_mode" row @change="local_export_mode_change()">
                                <v-radio label="New Template" value="new"></v-radio>
                                <v-radio label="Existing Template" value="existing" :disabled="!$can('templates.update') || !$can('templates.index')"></v-radio>
                            </v-radio-group>
                        </a-flex>
                        <a-flex xs12 v-if="export_mode === 'new'">
                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Group Name</label>
                            <a-text-field
                                v-model="template_usecase_item.name"
                                placeholder="Enter Template Name"
                                background-color="neutral"
                                solo flat hide-details autofocus
                            >
                            </a-text-field>
                            <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_group_response.server && template_group_response.server.errors && template_group_response.server.errors.name">
                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                {{ template_group_response.server.errors.name[0] }}
                            </span>
                            <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                <a-icon size="16">info</a-icon>
                                The name will be helpful to identify the user.
                            </span>
                        </a-flex>
                        <a-flex xs12 v-if="export_mode === 'existing'">
                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Group</label>
                            <a-autocomplete
                                v-model="template_usecase_item.id"
                                :items="template_group_list"
                                item-text="name"
                                item-value="id"
                                placeholder="Select Template"
                                background-color="neutral"
                                solo flat hide-details autofocus
                            >
                            </a-autocomplete>
                            <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_usecase_response.server && template_usecase_response.server.errors && template_usecase_response.server.errors.template_group_id">
                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                {{ template_usecase_response.server.errors.template_group_id[0] }}
                            </span>
                            <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                <a-icon size="16">info</a-icon>
                                The name will be helpful to identify the user.
                            </span>
                        </a-flex>
                    </a-layout>
                </a-container>
            </a-form>
        </template>
        <template #footer>
            <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_template_export()" :loading="loading" :disabled="loading">Save Template</a-btn>
            <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_close_template_export_form()" :disabled="loading">Cancel</a-btn>
        </template>
    </g-dialog-box>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: 'ModalImportForm',

    props: {
        isOpen: {
            type: Boolean
        },
        loading: {
            type: Boolean
        },
    },

    data () {
        return {
            export_mode: 'new'
        }
    },

    watch: {
        isOpen (val) {
            if (val) this.export_mode = 'new'
        }
    },

    computed: {
        ...mapState('Usecase', {
            usecase_list: 'list',
        }),

        ...mapState('TemplateGroup', {
            template_group_item: 'item',
            template_group_list: 'list',
            template_group_response: 'response',
        }),

        ...mapState('TemplateUsecase', {
            template_usecase_item: 'item',
            template_usecase_response: 'response',
        }),
    },

    methods: {
        async local_template_export () {
            if (this.export_mode === 'new') return this.local_template_group_store()
            this.local_template_group_export(this.template_usecase_item.id)
        },

        async local_template_group_store () {
            await this.template_group_store({ name: this.template_usecase_item.name, type: 'usecases' })
            if (this.template_group_response.status !== 'success') return
            await this.local_template_group_export(this.template_group_item.id)
        },

        async local_template_group_export (group_id) {
            const template_export_item = {}
            template_export_item.template_group_id = group_id
            template_export_item.project_id = this.$route.params.id
            await this.template_usecase_export(template_export_item)
            if (this.template_usecase_response.status !== 'success') return
            this.$emit('close', true)
            this.$notify('success', 'Usecases exported successfully!')
        },

        async local_close_template_export_form () {
            await this.template_usecase_clear_item()
            this.local_export_mode_change()
            this.$emit('close', true)
        },

        local_export_mode_change () {
            this.template_group_clear_item()
            this.template_usecase_clear_item()
        },

        ...mapActions('TemplateGroup', {
            template_group_store: 'store',
            template_group_clear_item: 'clear_item',
        }),

        ...mapActions('TemplateUsecase', {
            template_usecase_export: 'template_export',
            template_usecase_clear_item: 'clear_item',
        }),
    }
}
</script>

<template>
    <a-main>
        <template v-if="isLoading">
            <PAutomationSummaryLoader/>
        </template>
        <template v-if="!isLoading">
            <div class="u-flex pa-6">
                <div class="u-wfull c-summary-icon-view">
                    <div class="align-center justify-center u-flex c-menu-icon grey darken-3" style="border : 2px solid #E0E0E0 !important">
                        <a-icon color="white" size="20">info</a-icon>
                    </div>
                </div>
                <div class="w-full">
                    <p class="md-subtitle-1 text-uppercase indigo--text text--darken-1 mb-0 font-weight-bold">Automation Detail</p>
                    <p class="md-body-2 text--grey text--darken-1 mb-0">Automation title and description goes here.</p>
                </div>
            </div>
            <a-divider class="grey lighten-3"></a-divider>
            <div class="u-flex pa-6">
                <div class="u-wfull c-summary-detail-left">
                    <div class="automation-editor-title">
                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium grey--text text--darken-1">Title</label>
                        <a-text-field
                            v-model="item.title"
                            :placeholder="`Title of the ${type}`"
                            background-color="grey lighten-5"
                            :disabled="!canUpdate"
                            :class="[titleError ? 'c-field-error-border c-wiggle-short' : 'u-border']"
                            @input="$emit('change-value')"
                            solo flat hide-details
                        >
                        </a-text-field>
                        <div style="height: 24px !important;">
                            <span class="md-caption red--text text--darken-1" v-if="$response(response, 'title')">
                                {{ $response(response, 'title') }}
                            </span>
                            <span class="md-caption c-field-error mt-1" v-if="titleError">
                                <a-icon size="16" class="c-field-error u-icon-nudge-xs">info</a-icon>
                                Please update the title of the automation.
                            </span>
                        </div>
                    </div>
                    <div class="mt-8">
                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium grey--text text--darken-1">Description (Optional)</label>
                        <a-textarea
                            v-model="item.description"
                            :placeholder="`Type a brief description about the ${type} here`"
                            background-color="grey lighten-5"
                            :disabled="!canUpdate"
                            class="u-border"
                            @input="$emit('change-value')"
                            row="2"
                            multi-line auto-grow solo flat hide-details no-resize
                        >
                        </a-textarea>
                        <span class="md-caption red--text text--darken-1" v-if="$response(response, 'description')">
                            {{ $response(response, 'description') }}
                        </span>
                    </div>
                </div>
                <div class="u-wfull c-summary-detail-right">
                    <div class="c-summary-detail-user-info">
                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium grey--text text--darken-1">Updated by</label>
                        <div class="u-flex" v-if="item.updated_by">
                            <g-avatar :item="item.updated_by"></g-avatar>
                            <div>
                                <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-2" :title="item.updated_by.name">{{ item.updated_by.name | truncateText(localIsLargedown ? 25 : 30) }}</h2>
                                <g-moment tag="span" :value="item.updated_at" class="md-subtitle-2 grey--text" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                            </div>
                        </div>
                        <div style="height: 24px !important;"></div>
                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium grey--text text--darken-1 mt-8">Created by</label>
                        <div class="u-flex" v-if="item.created_by">
                            <g-avatar :item="item.created_by"></g-avatar>
                            <div>
                                <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-2" :title="item.created_by.name">{{ item.created_by.name | truncateText(localIsLargedown ? 25 : 30) }}</h2>
                                <g-moment tag="span" :value="item.created_at" class="md-subtitle-2 grey--text" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>        
    </a-main>
</template>

<script>
import PAutomationSummaryLoader from './PartialAutomationSummaryLoader'

export default {
    components: {
        PAutomationSummaryLoader,
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: null,
        },
        response: {
            type: Object | Array,
            default: []
        },
        isLoading:{
            type: Boolean,
            default: true
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        titleError: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        localIsLargedown () {
            return this.$vuetify.breakpoint.lgAndDown
        },
    }
}
</script>

<style scoped>
.c-summary-icon-view {
    width: 6% !important;
}
.automation-editor-title .v-input__slot {
    padding: 0 !important;
}
.automation-editor-title .v-text-field.v-text-field--solo .v-input__control {
    min-height: 44px !important;
}
.automation-editor-title input {
    max-height: 40px !important;
    line-height: 40px !important;
    padding: 9px;
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.26px;
    color: #424242;
}
.c-summary-tigger-icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.c-menu-icon {
    box-sizing: border-box;
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 8px !important;
}
.c-summary-detail-left{
    width: 60% !important;
}
.c-summary-detail-right{
    width: 40% !important;
}
.c-summary-detail-user-info{
    width: 80%; 
    margin: 0px auto;
}
.c-field-error {
    color: #FF8F00 !important;
    font-weight: 500 !important;
}
.c-field-error-border {
    border: 1px solid #FF8F00 !important;
}
</style>


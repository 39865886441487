<template>
    <a-card>
        <div class="px-7 py-6 u-flex-center-y">
            <div class="u-flex-center-y">
                <a-avatar size="40" color="indigo darken-2">
                    <a-icon size="24" color="white">cloud_download</a-icon>
                </a-avatar>
                <h3 class="text-h6 indigo--text text--darken-3 font-weight-medium ml-3">Import from Master Templates</h3>
            </div>
            <a-spacer></a-spacer>
            <a-icon :class="[{ 'u-opacity-40': loading }]" size="20" color="grey darken-2" @click="loading ? {} : $emit('close')">clear</a-icon>
        </div>

        <a-divider></a-divider>

        <a-sheet color="blue-grey lighten-5" class="mt-6 mx-7 u-rounded-corners-lg u-flex-center-y px-3 py-2">
            <a-icon size="24" color="blue-grey darken-2">error</a-icon>
            <span class="md-subtitle-1 ml-3 blue-grey--text text--darken-2">Master Template can be imported only once into a project and cannot be removed.</span>
        </a-sheet>

        <a-sheet class="pa-6 px-7 pb-7 u-overflow-y" max-height="600">
            <template v-for="(template, index) in templateList">
                <div :key="template.id" class="u-border u-rounded-corners-lg" :class="{ 'mt-5': index !== 0 }">
                    <a-sheet class="grey lighten-5">
                        <div class="pa-4 u-flex-center-y">
                            <div>
                                <h3 class="md-subtitle-1 font-weight-medium grey--text text--darken-2">{{ template.name }}</h3>
                                <div class="u-flex-center-y md-body-2 mt-1">
                                    <span class="mr-2 grey--text text--darken-1">Usecases: <strong>{{ template.master_template_all_usecases_count }}</strong></span>
                                    <span class="mr-2 grey--text text--darken-1">Tasks: <strong>{{ template.master_template_all_tasks_count }}</strong></span>
                                    <span class="mr-2 grey--text text--darken-1">Links: <strong>{{ template.master_template_all_links_count }}</strong></span>
                                    <span class="mr-2 grey--text text--darken-1">Documents: <strong>{{ template.master_template_all_documents_count }}</strong></span>
                                    <span class="grey--text text--darken-1">Notes: <strong>{{ template.master_template_all_notes_count }}</strong></span>
                                </div>
                            </div>
                            <a-spacer></a-spacer>
                            <a-sheet
                                @click="localHasTemplateGroupId ? '' : selectedTemplateId = template.id"
                                width="140"
                                :class="[selectedTemplateId === template.id ? 'indigo darken-2 white--text' : 'grey--text text--darken-2', localHasTemplateGroupId ? 'u-opacity-50' : 'u-cursor-pointer']"
                                class="u-border text-uppercase md-caption font-weight-medium u-rounded-corners-lg px-3 py-2"
                                v-ripple="localHasTemplateGroupId ? false : { class: 'grey--text text--lighten-1' }">
                                Select Template
                            </a-sheet>
                        </div>
                        <template v-if="selectedTemplateId === template.id">
                            <a-divider></a-divider>
                            <div class="u-flex-center-y pa-4 u-wfull white">
                                <a-progress-linear height="8" v-model="percent" class="u-rounded-corners-full" color="teal accent-4"></a-progress-linear>
                                <a-btn depressed v-if="percent === 100" class="teal lighten-2 white--text ml-12 u-rounded-corners-lg" width="140">
                                    <a-icon size="20" color="white" class="mr-2">cloud_done</a-icon>
                                    Imported
                                </a-btn>
                                <a-btn v-else depressed class="primary white--text ml-12 u-rounded-corners-lg" @click="$emit('update', template.id)" width="140">
                                    <a-icon size="20" color="white" class="mr-3" v-if="!loading">cloud_download</a-icon>
                                    <template v-else>{{ percent ? percent : 0 }}%</template>
                                    {{ loading ? 'Imported' : 'Import' }}
                                </a-btn>
                            </div>
                        </template>
                    </a-sheet>
                </div>
            </template>
        </a-sheet>
    </a-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        isOpen: {
            type: Boolean
        },
        percent: {
            type: Number
        },
        loading: {
            type: Boolean
        },
        templateList: {
            type: Array
        },
    },

    data () {
        return {
            selectedTemplateId: null
        }
    },

    watch: {
        isOpen (val) {
            if (val) this.localIndex()
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localHasTemplateGroupId () {
            return this.project_item && this.project_item.template_group_id
        },

        ...mapState('ProjectView', {
            project_item: 'item'
        })
    },

     methods: {
         async localIndex () {
             this.selectedTemplateId = null
         }
    }
}
</script>

<style lang="scss" scoped></style>

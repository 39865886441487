const api_endpoint = '/api/inventory-bookings';

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        query: {
            filter: {},
            sort: 'order',
            include: [],
            fields: {},
            append: [],
        },
        query_filters: {
            page: 1,
            count: 10,
        },
        response: {},
        defaults: {
            list: [],
            import_list: [],
            item: {},
            meta: {},
            query: {
                filter: {},
                sort: 'order',
                include: [],
                fields: {},
                append: [],
            },
            query_filters: {
                page: 1,
                count: 10,
            },
            response: {},
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'uuid': payload.uuid });
            state.list[index] = payload.item;
        },
        UPDATE_QUERIES(state, query) {
            state.query = query;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        IMPORT_LIST(state, items) {
            state.import_list = _.union(state.list, items);
        },
        IMPORT_UPDATE_LIST(state, items) {
            state.import_list = items;
        },
        PREPEND_ITEM(state, item) {
            state.list.unshift(item);
        },
        APPEND_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, uuid) {
            let index = _.findIndex(state.list, { 'uuid': uuid });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        CLEAR_RESPONSE(state) {
            state.response = {};
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        // index(context, payload) {
        //     this.dispatch('Interface/loader_start')
        //     return axios.get(api_endpoint, { params: context.state.query })
        //         .then((response) => {
        //             if (context.state.query_filters.page == 1) {
        //                 context.commit('UPDATE_LIST', response.data.data);
        //             }
        //             else {
        //                 context.commit('APPEND_LIST', response.data.data);
        //             }
        //             context.commit('UPDATE_META', response.data.meta);
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
        //         })
        //         .catch((error) => {
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
        //         });
        // },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.uuid)
                .then((response) => {
                    // context.commit('UPDATE_ITEM', response.data.data);
                    // context.commit('UPDATE_LIST_ITEM', { uuid: payload.uuid, item: response.data.data });
                    // this.dispatch('Interface/loader_stop')
                    // context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, payload.data)
                .then((response) => {
                    (response.data.data.order > 0)
                        ? context.commit('APPEND_ITEM', response.data.data)
                        : context.commit('PREPEND_ITEM', response.data.data)
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: response.data.data });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.data.uuid, payload.data)
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { uuid: context.state.item.uuid, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        import(context, payload) {
            this.dispatch('Interface/loader_start')
            let form_data = new FormData()
            form_data.append('csv_file', payload.csv_file)
            return axios.post(api_endpoint + '/import', form_data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US',
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    let is_valid = response.data.data.filter(list => list.is_valid === 0)
                    if (is_valid.length === 0) {
                        context.commit('APPEND_LIST', response.data.data);
                    } else {
                        context.commit('IMPORT_LIST', response.data.data);
                    }
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        disabled_uploaded(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/disabled_uploaded')
                .then((response) => {
                    context.commit('APPEND_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        import_index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/import_index')
                .then((response) => {
                    context.commit('IMPORT_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        enable_uploaded(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/enable_uploaded')
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear_uploaded(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/clear_uploaded')
                .then((response) => {
                    context.commit('UPDATE_LIST', []);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear_imported(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/clear_imported')
                .then((response) => {
                    context.commit('IMPORT_UPDATE_LIST', []);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        expand(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + context.state.item.uuid + '/expand', context.state.item)
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { uuid: context.state.item.uuid, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        collapse(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + context.state.item.uuid + '/collapse', context.state.item)
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { uuid: context.state.item.uuid, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        expand_all(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/expand_all', payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        collapse_all(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/collapse_all', payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.uuid)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.uuid);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'uuid': payload.uuid })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('IMPORT_LIST', _.cloneDeep(context.state.defaults.import_list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_QUERIES', _.cloneDeep(context.state.defaults.query));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },
    getters: {
        list: (state) => [...state.list],
        item: (state) => {
            return { ...state.item }
        },
        meta: (state) => {
            return { ...state.meta }
        },
        query: (state) => {
            return { ...state.query }
        },
        query_filters: (state) => {
            return { ...state.query_filters }
        },
        response: (state) => {
            return { ...state.response }
        },
        defaults: (state) => {
            return { ...state.defaults }
        },
        form_mode: (state) => state.form_mode,
        import_list: state => {
            return state.import_list.filter(list => list.is_valid === 0)
        }
    }
}

<template>
    <div>
        <a-autocomplete
            v-model="dataTagInput"
            :items="list"
            item-text="label"
            item-value="id"
            :placeholder="`Select${canUpdate ? ' or create ' : ' '}Tag`"
            background-color="white"
            :search-input.sync="dataTagSearch"
            :disabled="dataLocalLoading"
            ref="refTagInput"
            v-bind="$attrs"
            @keyup.delete="() => {}"
            @keyup.enter="canUpdate ? methodTagStore(dataTagSearch) : ''"
            @input="methodSelectTag('select')"
            autofocus solo flat hide-details hide-selected
        >
            <template v-slot:no-data>
                <a-divider class="mb-1"></a-divider>
                <div class="md-subtitle-1 px-4 py-2 grey--text text--darken-1 text-center">No Tags Found</div>
            </template>
            <template v-slot:selection="{ item }">
                <g-tags :tag="item" @destroy="methodAssociationDestroy"></g-tags>
            </template>
            <template v-slot:item="{ item }">
                <g-tags :tag="item" hide-clear-icon></g-tags>
            </template>
            <template v-slot:prepend-item>
                <div v-if="$can('tags.store') && dataTagSearch" class="px-4 py-2 u-cursor-pointer u-sticky white u-border-d u-flex-center-y" style="top: 0px; min-height: 48px; z-index: 1" v-ripple @click="$can('tags.store') ? methodTagStore(dataTagSearch) : ''">
                    <span class="md-body-2 blue--text text--darken-1" v-if="dataTagSearch != null && dataTagSearch != ''">Create <strong>"{{ dataTagSearch }}"</strong></span>
                    <a-spacer></a-spacer>
                    <a-icon size="16" color="blue darken-2" @click.stop="dataTagSearch = null">clear</a-icon>
                </div>
            </template>
        </a-autocomplete>
        <span class="md-caption red--text text--darken-2 u-flex align-start mt-2 px-2" v-if="tag_response.server && tag_response .server.errors && tag_response.server.errors.label">
            <a-icon size="20" color="red darken-2" class="u-icon-nudge mr-1" style="top: 1px;">info</a-icon>
            <span>{{ tag_response.server.errors.label[0] }}</span>
        </span>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    props: {
        title: {
            type: String,
            default: 'Add new tags'
        },
        item: {
            type: Object | String,
            required: true
        },
        list: {
            type: Array,
            default: () => [],
            required: true
        },
        parentType: {
            type: String,
            default: ''
        },
        source: {
            type: String,
        },
        type: {
            type: String,
        },
        canUpdate: {
            type: Boolean
        },
        isMenuOpen: {
            type: Boolean,
            default: true
        },
        updateType: {
            type: String,
            default: 'auto_save'
        },
    },

    data () {
        return {
            dataTagInput: null,
            dataTagSearch: null,
            dataAddedTagId: null,
            dataLocalLoading: false,
            local_tag_fields: { 'fields[tags]': 'id,label,type,color' },
        }
    },

    watch: {
        isMenuOpen: {
            async handler (val) {
                if (val) {
                    this.dataTagSearch = null
                    await this.tag_clear_item()
                    this.tag_index({ ...this.local_tag_fields, 'filter[type]': this.type })
                }
            },
            immediate: true
        },
    },

    computed: {
        ...mapState('Association', {
            association_item: 'item',
            association_response: 'response',
        }),

        ...mapState('Tag', {
            tag_item: 'item',
            tag_response: 'response',
        }),
    },

    methods: {
        async methodTagStore (tag_name) {
            if (!tag_name || (tag_name && !tag_name.trim())) return this.dataTagSearch = null
            if (this.dataTagInput) return this.dataTagSearch = null

            this.methodLoading(true)
            await this.tag_clear_item()

            await this.methodSetTagVars(tag_name)
            await this.tag_store({ ...this.tag_item, mode: 'only-update' })

            if (this.tag_response && this.tag_response.status !== 'success') return this.methodResetInput()

            this.dataAddedTagId = this.tag_item.id
            this.methodAddTag(this.tag_item, 'new')
            this.methodResetInput()
            this.$emit('store', true)
            await this.methodAssociationStore(this.dataAddedTagId)
        },

        methodResetInput (delay = 0) {
            this.methodLoading(false)
            this.dataTagSearch = null
            this.dataTagInput = null
            setTimeout(() => this.$refs.refTagInput.ref.blur(), delay)
        },

        async methodAddAssociationId (associateId, tagId) {
            const index = this.item.tags.findIndex(item => item.id === tagId)
            if (index !== -1) {
                // this.$set(this.item.tags, index, { ...this.item.tags[index], ...{ association: { id: associateId } }})
                Object.assign(this.item.tags[index], { association: { id: associateId } })
            }
        },

        async methodSetTagVars (label) {
            this.tag_item.label = label
            this.tag_item.type = ['Usecase', 'TemplateUsecase'].includes(this.parentType) ? 'usecase_tag' : this.type
            this.tag_item.color = this.$randomColor()
        },

        async methodSelectTag (arg) {
            if (!this.dataTagInput) return this.dataTagSearch = null
            this.methodLoading(true)
            if (arg && arg === 'select') this.methodAddTag(this.dataTagInput)
            this.methodLoading(false)
            if (this.updateType === 'manual_save') {
                this.$emit('get-tag-update')
                await this.methodAddAssociationId(this.association_item.id, this.dataAddedTagId ?? this.dataTagInput)
                this.dataTagInput = null
                this.dataAddedTagId = null
                this.tag_clear_item()
                return
            }
            this.methodAssociationStore(this.dataAddedTagId ?? this.dataTagInput)
        },

        async methodAssociationStore (target_id) {
            if (this.updateType === 'manual_save') {
                this.$emit('get-tag-update')
                await this.methodAddAssociationId(this.association_item.id, target_id)
                this.dataTagInput = null
                this.dataAddedTagId = null
                this.tag_clear_item()
                return
            }
            const modifyType = ['label', 'product', 'platform'].includes(this.type) ? 'project_' + this.type : this.type
            const data = { source_type: this.source, source_id: this.item.id , target_type: 'Tag', target_id, type: modifyType  }
            await this.association_store(data)
            if (this.association_response.status !== 'success') return
            await this.methodAddAssociationId(this.association_item.id, target_id)
            // this.$emit('after-store', true)
            this.dataTagInput = null
            this.dataAddedTagId = null
            this.tag_clear_item()
        },

        async methodAssociationDestroy (data) {
            this.methodRemoveTag(data)
            await this.association_destroy(data.association)
            if (this.association_response.status !== 'success') return
            this.$emit('after-remove', this.item)
        },

         methodAddTag (tag, type) {
            if (this.item && !this.item.tags) Object.assign(this.item, { tags: [] })
            if (!type) {
                const tagItem = this.list.find(item => item.id === tag)
                this.item.tags.push(_.cloneDeep(tagItem))
                if (this.updateType === 'manual_save') this.$emit('get-selected-tag', tagItem)
            } else {
                this.item.tags.push(_.cloneDeep(tag))
                if (this.updateType === 'manual_save') this.$emit('get-selected-tag', tag)
            }
            this.$emit('after-store', this.item)
        },

        async methodRemoveTag (tag) {
            const index = this.item.tags.findIndex(item => item.id === tag.id)
            if (index !== -1) this.item.tags.splice(index, 1)
        },

        methodLoading (val) {
            this.dataLocalLoading = val
        },

        ...mapActions('Association', {
            association_store: 'store',
            association_destroy: 'destroy',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_clear_item: 'clear_item',
        }),
    }
}
</script>

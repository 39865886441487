<template>
    <a-sheet
        :class="[
            'c-editor-rich-text u-relative u-rounded-corners',
            isFocused ?
                (localLimitError ? 'c-editor-rich-text--focus c-editor-rich-text--focus-error' : 'c-editor-rich-text--focus') :
                ''
        ]"
        style="outline: none"
        @click="!hideFocus ? localSetFocus() : localFocusEditor()"
        @blur.native="localTriggerEditorClose"
        tabindex="0"
    >
        <!-- Editor view -->
        <div @click="localGetMouseXY">
            <editor-content
                :editor="editor"
                :class="['md-body-1 u-relative pa-4 u-flex-center-y u-wfull', textColor]"
                style="min-height: 72px; line-height: 24px !important; cursor: text"
            />
        </div>

        <a-menu
            v-if="editor && editor.getAttributes('link') && editor.getAttributes('link').href && isFocused"
            v-model="dialogPopover"
            :close-on-click="false"
            :position-x="posX"
            :position-y="posY"
            :close-on-content-click="false"
            nudge-bottom="12"
            activator="c-linker"
            transition="slide-y-transition"
            offset-y bottom absolute
        >
            <a-sheet class="c-link-formatter d-inline-flex align-center u-elevation-custom-1 u-rounded-corners px-3 py-1" min-height="32">
                <a-icon size="16" color="grey darken-1" class="u-icon-nudge-xs" style="top: 1px">public</a-icon>
                <a
                    target="_blank"
                    class="md-body-2 d-block blue--text text--darken-4 mx-2"
                    :href="editor.getAttributes('link').href"
                    :title="editor.getAttributes('link').href && editor.getAttributes('link').href.length > 25 ? editor.getAttributes('link').href : ''"
                    rel="noopener noreferrer nofollow"
                >
                    {{ editor.getAttributes('link').href | truncateText(25) }}
                </a>
                <a-spacer></a-spacer>
                <div class="u-flex-center-y">
                    <a-btn v-clipboard:copy="editor.getAttributes('link').href" v-clipboard:success="localOnLinkCopySuccess" small icon dense>
                        <a-icon size="16" color="grey darken-1">content_copy</a-icon>
                    </a-btn>
                    <a-btn @click="localOpenLinkDialog('edit-mode')" small icon dense class="mx-1">
                        <a-icon size="16" color="grey darken-1">edit</a-icon>
                    </a-btn>
                    <a-btn @click="localRunCommand('unsetLink')" small icon dense>
                        <a-icon size="16" color="grey darken-1">link_off</a-icon>
                    </a-btn>
                </div>
            </a-sheet>
        </a-menu>

        <!-- Format bar -->
        <template v-if="editor && isFocused">
            <div
                :class="['c-editor-rich-text__formatter' ,{ 'u-sticky': !noSticky }]"
                :style="[{ bottom: formatterStickyZero ? '0px !important' : '83px !important' }]"
            >
                <template v-if="canUpdate">
                    <slot name="format-title" v-bind:execute="editor" v-bind:isActive="editor.isActive(localHeadingLevel)">
                        <div key="format-title" class="c-editor-rich-text--format-section-border">
                            <a-menu v-model="menu_header" :close-on-content-click="false" :disabled="!canUpdate" top offset-y content-class="editor-menu-popup">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">
                                        <a-sheet :min-height="localFormatHeight" v-ripple="canUpdate" :class="['u-flex-center u-cursor-pointer transparent', dense ? 'px-4' : 'px-4', { 'is-active': localHeadingLevel }]" :min-width="50">
                                            <span style="min-width: 34px;" :class="['md-subtitle-1 font-weight-medium text-capitalize', localHeadingLevel ? (localLimitError ? 'pink--text text--darken-3' : 'indigo--text text--darken-2') : 'body--text text--lighten-2']">{{ localHeadingLevel ? localHeadingLevel : 'Text' }}</span>
                                            <a-spacer></a-spacer>
                                            <a-icon size="20" color="body lighten-2" class="ml-1">arrow_drop_{{ menu_header ? 'up' : 'down' }}</a-icon>
                                        </a-sheet>
                                    </div>
                                </template>
                                <a-sheet class="u-flex-center-y">
                                    <template v-for="(title, index) in titles">
                                        <span @click="localSetTitleFormat(title)" v-ripple="canUpdate" :class="['md-subtitle-1 u-cursor-pointer font-weight-medium text-capitalize pa-2 d-inline-block', localTitleActiveColor(title)]" :key="index">{{ title }}</span>
                                    </template>
                                </a-sheet>
                            </a-menu>
                        </div>
                    </slot>
                    <div key="format-text" class="c-editor-rich-text--format-section-border u-flex-center-y">
                        <slot name="format-bold" v-bind:execute="editor" v-bind:isActive="editor.isActive('bold')">
                            <a-sheet
                                :min-height="localFormatHeight"
                                :class="[localFormatIconStyles, { 'is-active': editor.isActive('bold') }, localAdjustSpacing]"
                                v-ripple="canUpdate"
                                @click="localRunCommand('toggleBold')"
                            >
                                <a-icon :size="iconSize" :color="localShowFormatActiveColor('bold')">format_bold</a-icon>
                            </a-sheet>
                        </slot>
                        <slot name="format-italic" v-bind:execute="editor" v-bind:isActive="editor.isActive('italic')">
                            <a-sheet
                                v-ripple="canUpdate"
                                :min-height="localFormatHeight"
                                :class="[localFormatIconStyles, { 'is-active': editor.isActive('italic') }, localAdjustSpacing]"
                                @click="localRunCommand('toggleItalic')"
                            >
                                <a-icon :size="iconSize" :color="localShowFormatActiveColor('italic')">format_italic</a-icon>
                            </a-sheet>
                        </slot>
                        <slot name="format-underline" v-bind:execute="editor" v-bind:isActive="editor.isActive('underline')">
                            <a-sheet
                                v-ripple="canUpdate"
                                :min-height="localFormatHeight"
                                :class="[localFormatIconStyles, { 'is-active': editor.isActive('underline') }, localAdjustSpacing]"
                                @click="localRunCommand('toggleUnderline')"
                            >
                                <a-icon :size="iconSize" :color="localShowFormatActiveColor('underline')">format_underline</a-icon>
                            </a-sheet>
                        </slot>
                        <slot name="format-underline" v-bind:execute="editor" v-bind:isActive="editor.isActive('strike')">
                            <a-sheet
                                v-ripple="canUpdate"
                                :min-height="localFormatHeight"
                                :class="[localFormatIconStyles, { 'is-active': editor.isActive('strike') }, localAdjustSpacing]"
                                @click="localRunCommand('toggleStrike')"
                            >
                                <a-icon :size="iconSize" :color="localShowFormatActiveColor('strike')">strikethrough_s</a-icon>
                            </a-sheet>
                        </slot>
                    </div>
                    <div key="format-list" class="u-flex-center-y">
                        <slot name="format-orderedlist" v-bind:execute="editor" v-bind:isActive="editor.isActive('orderedList')">
                            <a-sheet
                                v-ripple="canUpdate"
                                :min-height="localFormatHeight"
                                :class="[localFormatIconStyles, { 'is-active': editor.isActive('orderedList') }, localAdjustSpacing]"
                                @click="localRunCommand('toggleOrderedList')"
                            >
                                <a-icon :size="iconSize" :color="localShowFormatActiveColor('orderedList')">
                                    format_list_numbered
                                </a-icon>
                            </a-sheet>
                        </slot>
                        <slot name="format-bulletlist" v-bind:execute="editor" v-bind:isActive="editor.isActive('bulletList')">
                            <a-sheet
                                v-ripple="canUpdate"
                                :min-height="localFormatHeight"
                                :class="[localFormatIconStyles, { 'is-active': editor.isActive('bulletList') }, localAdjustSpacing]"
                                @click="localRunCommand('toggleBulletList')"
                            >
                                <a-icon :size="iconSize" :color="localShowFormatActiveColor('bulletList')">
                                    format_list_bulleted
                                </a-icon>
                            </a-sheet>
                        </slot>
                        <slot name="format-link" v-bind:execute="editor" v-bind:isActive="editor.isActive('link')">
                            <a-sheet
                                v-ripple="canUpdate"
                                :min-height="localFormatHeight"
                                :class="[localFormatIconStyles, { 'is-active': editor.isActive('link') }, localAdjustSpacing]"
                                @click="localOpenLinkDialog()"
                            >
                                <a-icon :size="iconSize" :color="localShowFormatActiveColor('link')">
                                    {{ editor.isActive('link') ? 'link_off' : 'link' }}
                                </a-icon>
                            </a-sheet>
                        </slot>
                        <slot name="emoji">
                            <a-menu v-model="emoji_dropdown" attach=".c-editor-rich-text" max-height="300" max-width="300" :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                    <a-sheet v-on="on" :min-height="localFormatHeight" :class="[localFormatIconStyles, localAdjustSpacing]" v-ripple="canUpdate">
                                        <a-icon :size="iconSize" color="body lighten-2">insert_emoticon</a-icon>
                                    </a-sheet>
                                </template>
                                <Picker :data="emojiIndex" set="facebook" style="max-width: 300px; max-height: 300px;" :showSearch="false" :showPreview="false" @select="localAddEmoji" />
                            </a-menu>
                        </slot>
                        <slot name="format-codeblock" v-bind:execute="editor" v-bind:isActive="editor.isActive('codeBlock')">
                            <a-sheet
                                :min-height="localFormatHeight"
                                :class="[localFormatIconStyles, { 'is-active': editor.isActive('codeBlock') }, localAdjustSpacing]"
                                v-ripple="canUpdate"
                                @click="localRunCommand('toggleCodeBlock')"
                            >
                                <a-icon :size="iconSize" :color="localShowFormatActiveColor('codeBlock')">code</a-icon>
                            </a-sheet>
                        </slot>
                    </div>
                    <a-spacer key="spacer"></a-spacer>
                    <slot name="format-charcount" v-bind:count="charCount" v-bind:limit="charLimit">
                        <a-tooltip v-if="localLimitError" bottom content-class="c-tooltip-pointer c-tooltip-pointer--right">
                            <template v-slot:activator="{ on }">
                                <a-sheet
                                    v-on="on"
                                    width="36"
                                    height="calc(100% - 8px)"
                                    class="md-body-2 u-rounded-corners mr-n1 font-weight-bold pink lighten-4 pink--text text--darken-3 u-flex-center"
                                >
                                    {{ charLimit - charCount }}
                                </a-sheet>
                            </template>
                            <span>Max characters limit reached</span>
                        </a-tooltip>
                    </slot>
                </template>
                <template v-if="!canUpdate">
                    <span class="md-caption grey--text text--lighten-1" :class="[dense ? 'pl-4' : 'pl-6']">Format options are not available</span>
                    <a-spacer></a-spacer>
                </template>
                <template v-if="!hideCloseBtn">
                    <div
                        @click="localTriggerEditorClose('close')"
                        :class="['c-editor-rich-text__close-btn u-flex-center u-cursor-pointer ml-2 px-3']"
                    >
                        <a-icon size="16" color="body lighten-1">close</a-icon>
                    </div>
                </template>
            </div>
        </template>

        <!-- Link add/remove dialog box -->
        <a-dialog
            v-model="dialog_link"
            attach=".c-editor-rich-text"
            width="500"
            transition="slide-y-transition"
            origin="top center"
            content-class="c-editor-rich-text__link-dialog"
            hide-overlay
        >
            <a-card class="pa-6">
                <h2 class="md-subtitle-1 grey--text text--darken-3 mb-3">Insert or Update Link</h2>
                <div class="u-relative">
                    <a-text-field
                        v-model="link_model_value"
                        placeholder="Ex: https://success.app"
                        class="u-border u-rounded-corners"
                        @input="localValidateUrl(link_model_value)"
                        solo flat autofocus hide-details
                    ></a-text-field>
                    <span class="u-absolute" style="bottom: -24px; left: 0px;" v-if="url_error">
                        <a-icon size="16" color="red darken-1">info</a-icon>
                        <span class="ml-1 red--text text--darken-1 md-caption">{{ url_error }}</span>
                    </span>
                </div>
                <span class="u-flex-center-y" :class="[url_error ? 'mt-8' : 'mt-6']">
                    <a-btn small depressed class="primary white--text mr-2" @click="localRunCommand('setLink')" :disabled="!!url_error">Update</a-btn>
                    <a-btn small depressed class="grey--text text--darken-1" @click="dialog_link = false">Cancel</a-btn>
                    <a-spacer></a-spacer>
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-btn v-on="on" small icon dense class="grey--text text--darken-1 grey lighten-5" :disabled="!link_model_value || !(link_model_value && link_model_value.trim())" @click="localRunCommand('unsetLink')">
                                <a-icon size="18">link_off</a-icon>
                            </a-btn>
                        </template>
                        <span>Unlink</span>
                    </a-tooltip>
                </span>
            </a-card>
        </a-dialog>
    </a-sheet>
</template>

<script>
import { Editor, EditorContent, BubbleMenu, VueRenderer } from '@tiptap/vue-2'
import { generateHTML } from '@tiptap/html'
import { SEditorFormatBar } from '@/config/config-shared-components'
import tippy from 'tippy.js'

// Tiptap extensions
import Link from '@tiptap/extension-link'
import Mention from '@tiptap/extension-mention'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Underline from '@tiptap/extension-underline'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import Typography from '@tiptap/extension-typography'
import Placeholder from '@tiptap/extension-placeholder'
import HardBreak from '@tiptap/extension-hard-break'
import { SEmojiList } from '@/config/config-shared-components'
import CodeBlock from '@tiptap/extension-code-block'

// Emoji config
import data from 'emoji-mart-vue-fast/data/facebook.json'
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
import { Base64 } from 'js-base64'
const emojiIndex = new EmojiIndex(data)

export default {
    name: 'PartialEditorBox',

    inheritAttrs: false,

    props: {
        dense: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean | Object,
            default: null
        },
        modelIsOpen: {
            type: Boolean,
            default: false
        },
        value: {
            type: String | Object,
            required: true
        },
        textColor: {
            type: String,
            default: 'primary--text'
        },
        returnType: {
            type: String,
            default: 'json'
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        hideDivider: {
            type: Boolean,
            default: false
        },
        hideCloseBtn: {
            type: Boolean,
            default: false
        },
        noSticky: {
            type: Boolean,
            default: false
        },
        formatterStickyZero: {
            type: Boolean,
            default: false
        },
        editorHeight: {
            type: Number,
            default: 300
        },
        customMinHeight: {
            type: Number | String,
            default: 300
        },
        customMaxHeight: {
            type: Number | String,
            default: 300
        },
        editorClass: {
            type: Array,
            default: () => []
        },
        editorMinHeight: {
            type: Number | String,
            default: 120
        },
        hideFocus: {
            type: Boolean,
            default: false
        },
        noEncrypt: {
            type: Boolean,
            default: false
        },
        refresh: {
            type: Boolean,
            default: false
        },
        iconSize: {
            type: String | Number,
            default: '20'
        },
        iconColor: {
            type: String,
            default: '#444'
        },
        charLimit: {
            type: Number,
            default: 50000
        }
    },

    components: { EditorContent, SEditorFormatBar, BubbleMenu, Picker },

    data () {
        return {
            descTimeout: null,
            emojiIndex: emojiIndex,
            emoji_dropdown: false,
            editor: null,
            dialog_link: false,
            dialogPopover: false,
            link_model_value: '',
            link_edit_mode: false,
            show_editor: false,
            menu_header: false,
            url_error: null,
            url_length: 2000,
            selected_heading: 'text',
            charCount: 0,
            isFocused: false,
            show_more_desc: false,
            showFormatBar: false,
            titles: ['text', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
            posX: 0,
            posY: 0
        }
    },

    watch: {
        dialogPopover (val) {
            setTimeout(() => {
                if (!val && this.editor.isActive('link') && !this.dialog_link) this.dialogPopover = true
            }, 0)
        },

        modelIsOpen (val) {
            if (val) this.localIndex()
        },

        hideFocus (val) {
            if (val) {
                this.charCount = this.editor.state.doc.textContent.length
            }
        },

        refresh (value) {
            if (value) this.localIndex()
        }
    },

    mounted () {
        this.$el.addEventListener('keydown', this.localKeyDown)
        this.localIndex()
    },

    beforeDestroy () {
        this.$el.removeEventListener('keydown', this.localKeyDown)
        if (this.editor) this.editor.destroy()
    },

    computed: {
        localReturnData () {
            const value = this.returnType === 'json' ? this.editor.getJSON() : this.editor.getHTML()
            if (this.noEncrypt) return value

            return {
                value,
                isEmpty: this.editor.isEmpty,
                encodedData: this.editor.isEmpty ? null : Base64.encode(JSON.stringify(value)),
            }
        },

        localTiptapPacks () {
            return [StarterKit, Document, Underline, ListItem, BulletList, OrderedList, Link, Mention, HardBreak, CodeBlock]
        },

        localEditorContent () {
            const emptyText = { type: 'doc', content: [] }
            const value = !this.value ? emptyText : ((typeof this.value) === 'string' ? JSON.parse(this.value) : this.value)
            return generateHTML(value, this.localTiptapPacks)
        },

        localHeadingLevel () {
            let isLevel = false
            if (this.editor) {
                for (const iterator of [...Array(6).keys()]) {
                    const hasLevel = this.editor.isActive('heading', { level: iterator + 1 })
                    const hasPara = this.editor.isActive('paragraph')
                    if (hasLevel) isLevel = 'h' + (iterator + 1)
                    if (hasPara) isLevel = 'text'
                }
            }
            return isLevel
        },

        localFormatIconStyles () {
            const cursorPointer = this.canUpdate ? 'u-cursor-pointer' : ''
            return 'transparent u-flex-center ' + cursorPointer
        },

        localAdjustSpacing () {
            let padding = 'px-3'
            if (this.dense) padding = 'px-3'
            return padding
        },

        localFormatHeight () {
            // let height = 48
            // if (this.dense) height = 40
            return 40
        },

        localTippyStartProps () {
            return {
                appendTo: () => document.body,
                showOnCreate: true, interactive: true, trigger: 'manual', placement: 'bottom-start',
            }
        },

        localLimitError () {
            return (this.charLimit - this.charCount) <= 100
        },
    },

    methods: {
        localIndex () {
            if (this.editor) this.editor.destroy()
            this.localEditorSetup()
            this.charCount = 0
            // if (this.dense) this.localSetFocus()
        },

        localEditorSetup () {
            const limit = this.charLimit
            this.editor = new Editor({
                editorProps: {
                    handleTextInput(view) {
                        if (view.state.doc.textContent.length >= limit && view.state.selection.empty) return true
                    },
                    handlePaste(view, event, slice) {
                        if (view.state.doc.textContent.length + slice.size > limit) {
                            slice.content = new DocumentFragment()
                        }
                    }
                },
                extensions: [
                    StarterKit, Document, Underline, ListItem, BulletList, OrderedList, Typography, CodeBlock,
                    Link.configure({
                        HTMLAttributes: { class: 'c-linker' },
                        openOnClick: false
                    }),
                    Placeholder.configure({
                        placeholder: this.canUpdate ? 'Write something down...' : 'No description...',
                        showOnlyWhenEditable: false
                    }),
                    Mention.configure({
                        HTMLAttributes: { class: 'c-emoji' },
                        suggestion: {
                            char: ':',
                            command: ({ editor, range, props }) => {
                                editor.chain().focus().insertContentAt(range, props.label).run()
                            },
                            items: this.localSearchEmojis,
                            render: this.localEmojiRender,
                        },
                    })
                ],
                // autofocus: this.autofocus ?? false,
                content: this.localEditorContent,
                editable: this.canUpdate && !this.readOnly,
                parseOptions: { preserveWhitespace: 'full' },
                onUpdate: ({ editor }) => {
                    this.localTextCountUpdate(editor)
                    this.localUpdate()
                },
                onFocus: ({ editor }) => !this.hideFocus ? this.localSetFocus(false) : '',
                onBlur: ({ editor, event }) => {
                    if (event && this.$el.contains(event.relatedTarget) || this.dialog_link) return
                    this.localTextCountUpdate(editor)
                    this.localUpdate()
                    this.localTriggerEditorClose('close')
                }
            })
        },

        localTextCountUpdate (editor = this.editor) {
            const textCount = editor.state.doc.textContent.length
            this.charCount = textCount
        },

        localUpdate () {
            clearTimeout(this.descTimeout)
            this.descTimeout = setTimeout(() => {
                this.$emit('input', this.localReturnData)
                if (this.charCount <= this.charLimit) this.$emit('update', this.localReturnData)
            }, 500)
        },

        localSetFocus(set_editor_focus = true) {
            if (set_editor_focus && this.editor) this.editor.commands.focus()
            this.isFocused = true
            this.showFormatBar = true
            this.$emit('isFocused', this.isFocused)
            this.charCount = this.editor.state.doc.textContent.length
        },

        localFocusEditor () {
            if (!this.editor) return
            this.editor.commands.focus()
            this.showFormatBar = true
        },

        localFocusOut () {
            this.showFormatBar = false
        },

        localSetTitleFormat (title) {
            if (!this.canUpdate) return
            title !== 'text' ?
                this.editor.chain().focus().toggleHeading({ level: Number(title.split('h')[1]) }).run() :
                this.editor.chain().focus().setParagraph().run()
        },

        localGetMouseXY (evt) {
            this.dialogPopover = false
            this.posX = evt.clientX
            this.posY = evt.clientY
            setTimeout(() => {
                if (this.editor && this.editor.isActive('link')) this.dialogPopover = true
                else this.dialogPopover = false
            }, 400)
        },

        localOpenLinkDialog (mode) {
            if (mode === 'edit-mode') this.dialogPopover = false
            this.url_error = null
            if (!this.canUpdate) return
            const previousUrl = this.editor.getAttributes('link').href
            this.link_edit_mode = mode ?? false
            this.link_model_value = previousUrl ?? ''
            this.dialog_link = true
        },

        localRunCommand (command) {
            if (!this.canUpdate) return
            const execute = this.editor.chain().focus()
            switch (command) {
                case 'setLink':
                case 'unsetLink': return this.localExecuteLink(command, execute)
                case 'toggleBold': return execute.toggleBold().run()
                case 'toggleItalic': return execute.toggleItalic().run()
                case 'toggleUnderline': return execute.toggleUnderline().run()
                case 'toggleStrike': return execute.toggleStrike().run()
                case 'toggleBulletList': return execute.toggleBulletList().run()
                case 'toggleOrderedList': return execute.toggleOrderedList().run()
                case 'toggleOrderedList': return execute.toggleOrderedList().run()
                case 'toggleCodeBlock': return execute.toggleCodeBlock().run()
            }
        },

        localExecuteLink (command, execute) {
            this.url_error = null
            const previousUrl = this.editor.getAttributes('link').href
            this.link_model_value = this.link_edit_mode ? this.link_model_value : (previousUrl ?? this.link_model_value)
            if (!this.localValidateUrl(this.link_model_value)) return
            this.dialog_link = true
            if (this.link_model_value === null) return
            if (this.link_model_value === '' || command === 'unsetLink') {
                this.link_model_value = ''
                this.link_edit_mode = false
                this.localLinkDialogsReset()
                return execute.extendMarkRange('link').unsetLink().run()
            }
            execute.extendMarkRange('link').setLink({ href: this.link_model_value, target: '_blank' }).run()
            this.localLinkDialogsReset()
        },

        localValidateUrl (url) {
            this.url_error = null
            const regex = new RegExp('(http(s)?:\/\/)(www\.)?([-a-zA-Z0-9@:%._\+~#=]{2,256})(\.[-a-zA-Z0-9@:%_\+~#=]{2,256}){1,5}(\/[-a-zA-Z0-9@:%_\+~#=]{2,256}){0,4}', 'gi')
            // const regex = new RegExp('(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)', 'gi')
            // const regex = new RegExp('(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)', 'i')
            // const regex = new RegExp('(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)', 'gi')
            // const regex = new RegExp('(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)', 'gi')
            const isValid = regex.test(url)
            const maxLengthBreach = url && url.length > this.url_length

            if (!isValid) this.url_error = 'Not a valid URL'
            if (maxLengthBreach) this.url_error = `Maximum of ${ this.url_length } characters`
            return isValid && !maxLengthBreach
        },

        localLinkDialogsReset () {
            this.dialog_link = false
            this.dialogPopover = false
        },

        localTitleActiveColor (title) {
            return this.localHeadingLevel === title ? (this.localLimitError ? 'pink--text text--darken-3 pink lighten-5' : 'indigo--text text--darken-2 grey lighten-3') : 'body--text text--lighten-2'
        },

        localShowFormatActiveColor (type) {
            return this.editor.isActive(type) ? (this.localLimitError ? 'pink darken-3' : 'indigo darken-2') : 'body lighten-2'
        },

        localOnLinkCopySuccess (evt, link) {
            this.$notify('success', 'Link Copied!')
        },

        localKeyDown (evt) {
            if (evt.code === 'Escape' && this.isFocused) {
                evt.preventDefault()
                evt.stopPropagation()
                this.localTriggerEditorClose('escape')
                this.editor.commands.blur()
                document.querySelector('.c-task-edit-view').parentElement.parentElement.focus()
            }
        },

        async localTriggerEditorClose (evt) {
            if (evt && ['escape', 'close'].includes(evt)) return this.localCloseEditor()
            if (evt && this.$el.contains(evt.target) || this.dialog_link) return

            this.localCloseEditor()
        },

        localCloseEditor () {
            setTimeout(() => {
                // this.editor.commands.blur()
                if (this.dialog_link) return
                this.isFocused = false
                this.menu_header = false
                this.focusClass = ''
                this.showFormatBar = false
                this.$emit('isFocused', this.isFocused)
            }, 100)
        },

        // Emoji methods
        localAddEmoji (emoji) {
            this.editor.chain().focus().insertContent(emoji.native).run()
            this.emoji_dropdown = false
        },

        localSearchEmojis (query) {
            return Object.keys(this.emojiIndex._emojis)
                        .map(item => {
                            const emojiValue = this.emojiIndex._emojis
                            const searchText = item.toLowerCase().startsWith(query.toLowerCase())
                            if (searchText) return emojiValue[item]
                        })
                        .filter(i => i !== undefined)
                        .slice(0, 5)
        },

        localEmojiRender () {
            let component
            let popup

            return {
                onStart: props => {
                    component = new VueRenderer(SEmojiList, { parent: this, propsData: props })
                    popup = tippy('body', {
                        getReferenceClientRect: props.clientRect,
                        content: component.element,
                        ...this.localTippyStartProps
                    })
                },

                onUpdate (props) {
                    component.updateProps(props)
                    popup[0].setProps({ getReferenceClientRect: props.clientRect })
                },

                onKeyDown (props) {
                    if (props.event.key === 'Escape') return popup[0].hide()
                    return component.ref?.onKeyDown(props)
                },

                onExit () {
                    popup[0].destroy()
                    component.destroy()
                },
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.is-active {
    transition: 0.4s ease-in-out;
    color: #3949AB !important;
}
</style>

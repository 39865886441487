import { mapState, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState('Attachment', {
            attachment_local_list: 'local_list',
            attachment_delete_list: 'delete_list',
            attachment_upload_list: 'upload_list',
        })
    },

    methods: {
        mixinAttachmentListeners (type) {
            if (type === 'add') {
                window.addEventListener("dragover", this.localPreventDragDrop);
                window.addEventListener("drop", this.localPreventDragDrop);
                return
            }
            window.removeEventListener("dragover", this.localPreventDragDrop);
            window.removeEventListener("drop", this.localPreventDragDrop);
        },

        localPreventDragDrop (e) {
            e.preventDefault();
        },

        mixinRemoveAttachmentItem (id) {
            this.attachment_clear_response()
            const list = _.cloneDeep(this.attachment_local_list)
            const index = _.findIndex(list, { 'id': id })
            if (index !== -1) list.splice(index, 1)
            this.attachment_update_local_list(list)
            this.attachment_remove_upload_item(id)
        },

        async mixinDeleteAttachmentItem (id) {
            this.attachment_clear_response()
            this.attachment_update_delete_list(id)
            const list = _.cloneDeep(this.attachment_local_list)
            const index = _.findIndex(list, { 'id': id })
            if (index !== -1) list.splice(index, 1)
            this.attachment_update_local_list(list)
        },

        mixinRemoveInvalidFiles () {
            const list = _.cloneDeep(this.attachment_local_list)
            list.forEach((file, index) => {
                if(file.error) this.attachment_remove_upload_item(file.id)
            })
            const updatedList = list.filter(file => !file.error)
            this.attachment_update_local_list(updatedList)

            if (_.size(updatedList) === _.size(list)) return
            this.$notify('error', 'One or more files could not be uploaded due to their size (>100M) or unsupported format', { maxWidth: 400 })
        },

        mixinClearAttachmentUpdate () {
            this.attachment_clear_delete_list()
            this.attachment_clear_upload_list()
            this.attachment_clear_response()
        },

        mixinUpdateLocalAttachmentList (params) {
            const { list } = params || {}
            this.attachment_update_local_list(list)
        },

        mixinPreserveListChanges (params) {
            const { list, type } = params || {}
            const preservedList = list.filter(file => {
                return !this.attachment_delete_list.includes(file.id)
            })

            const updateList = type === 'load_more' ? [...this.attachment_upload_list, ...preservedList] : [...preservedList]
            this.mixinUpdateLocalAttachmentList({ list: updateList })
        },

        mixinHandleAttachmentErrors () {
            this.$notify('error', 'Error occured while updating attachments')
        },

        ...mapActions('Attachment', {
            attachment_update_local_list: 'update_local_list',
            attachment_update_delete_list: 'update_delete_list',
            attachment_remove_upload_item: 'remove_upload_list_item',
            attachment_clear_upload_list: 'clear_upload_list',
            attachment_clear_delete_list: 'clear_delete_list',
            attachment_clear_response: 'clear_response',
        }),
    }
}

<template>
    <div>
        <div class="u-flex-center-y">
            <template v-if="local_can('update') && local_uc_length">
                <!-- Filter checkbox - dropdown -->
                <a-sheet class="u-border u-rounded-corners-lg d-inline-flex align-center">
                    <a-menu offset-y bottom content-class="u-rounded-corners-lg" nudge-bottom="2" :disabled="bulkLoading || local_filter_loading">
                        <template v-slot:activator="{ on, value }">
                            <div v-on="on" class="u-flex-center-y u-cursor-pointer py-2 pl-3 pr-2" style="min-width: 128px;">
                                <span class="md-subtitle-2 mr-2" :class="['body--text text--' + (filter_selected.label ? 'darken-1' : 'lighten-1')]">
                                    Bulk Edit{{ filter_selected.label ? ':' : '' }}
                                    <span class="indigo--text text--darken-3 ml-1" v-if="filter_selected.label">{{ filter_selected.label }}</span>
                                </span>
                                <a-spacer></a-spacer>
                                <a-progress-circular v-if="local_filter_loading" class="ml-1" width="2" size="24" color="orange darken-2" indeterminate></a-progress-circular>
                                <a-icon size="24" color="grey darken-1" class="ml-1" v-else>arrow_drop_{{ value ? 'up' : 'down' }}</a-icon>
                            </div>
                        </template>
                        <a-list class="u-list-std">
                            <a-list-item v-for="filter in filters_selection_list" @click="local_select_filter(filter)" :key="filter.value">
                                <a-list-item-content class="md-body-2">{{ filter.label }}</a-list-item-content>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-sheet>
                <h3 class="md-subtitle-1 indigo--text text--darken-3 font-weight-medium ml-4">
                    <template v-if="ucBulkCount">{{ ucBulkCount }} Use Cases Selected</template>
                    <template v-if="scBulkCount">{{ scBulkCount }} Success Criteria Selected</template>
                    <template v-if="tcBulkCount">{{ tcBulkCount }} Test Cases Selected</template>
                </h3>
            </template>
            <div v-else class="d-inline-flex align-center">
                <h2 class="secondary--text text-h6">Scope</h2>
                <span class="u-rounded-corners-full grey darken-2 white--text font-weight-medium md-caption ml-2 u-flex-center" style="width: 22px; height: 22px;">{{ local_uc_length }}</span>
            </div>

            <a-spacer></a-spacer>

            <template v-if="local_is_filter_selected && local_can('update')">
                <a-btn 
                    depressed 
                    class="ma-0 py-1 px-6 u-rounded-corners-lg" 
                    :disabled="bulkLoading" 
                    :loading="local_is_loading('clear-all-filters')" 
                    @click="bulkLoading ? '' : local_clear_select_filter()" 
                    color="#e0e0e0"
                >
                    <span class="md-subtitle-1">Close</span>
                </a-btn>

                <!-- Delete button -->
                <!-- <a-sheet class="u-border u-rounded-corners-lg d-inline-flex align-center px-2 py-1 u-cursor-pointer mr-3" height="32" v-ripple="{ class: 'red--text' }">
                    <a-icon size="18" color="red darken-1">delete</a-icon>
                    <span class="red--text text--darken-1 md-body-2 ml-1">Delete</span>
                </a-sheet> -->
            </template>

            <!-- Expand/Collaps All -->
            <a-tooltip bottom v-if="!local_is_filter_selected && local_uc_length" :disabled="bulkLoading || local_filter_loading">
                <template v-slot:activator="{ on }">
                    <div 
                        v-on="on" 
                        @click="(bulkLoading || expandAllLoading || local_filter_loading) ? '' : local_expand_toggle()" 
                        class="u-border u-rounded-corners-lg d-inline-flex align-center px-3 py-1 u-cursor-pointer mr-3" 
                        style="height: 40px" 
                        v-ripple="true"
                    >
                        <a-progress-circular v-if="expandAllLoading" color="orange darken-2" width="2" size="18" indeterminate></a-progress-circular>
                        <a-icon v-else size="18" color="grey darken-1">unfold_{{ list_expand_all ? 'less' : 'more' }}</a-icon>
                    </div>
                </template>
                <span>{{ list_expand_all ? 'Collapse All' : 'Expand All'}}</span>
            </a-tooltip>

            <!-- More Options -->
            <div v-if="($can('templates.store') || ($can('usecases.update') && $can('projects.update-only'))) && !filter_selected.value && !action_selected.value">
                <a-menu bottom left offset-y content-class="u-rounded-corners-lg" nudge-bottom="2" :disabled="bulkLoading">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" style="height: 40px;" class="ma-0 py-2 pl-2 pr-4 u-flex-center-y u-cursor-pointer u-border u-rounded-corners-lg" v-ripple="true">
                            <a-icon size="20" color="neutral darken-4" class="mr-1">more_vert</a-icon>
                            <span class="md-subtitle-2 body--text text--darken-1">More Options</span>
                        </div>
                    </template>
                    <a-list class="u-list-std">
                        <!-- <a-list-item v-if="local_can('update')" :disabled="local_is_filter_selected" @click="local_expand_toggle()">
                            <a-list-item-content class="md-body-2">{{ list_expand_all ? 'Collapse All' : 'Expand All'  }}</a-list-item-content>
                        </a-list-item> -->
                        <a-list-item :to="{name: 'projects-usecases-template-import'}" :disabled="local_is_filter_selected" v-if="local_can('update')">
                            <a-list-item-content class="md-body-2">Import from Templates</a-list-item-content>
                        </a-list-item>
                        <a-list-item :to="{name: 'projects-usecases-import'}" :disabled="local_is_filter_selected" v-if="local_can('update')">
                            <a-list-item-content class="md-body-2">Import from CSV</a-list-item-content>
                        </a-list-item>
                        <template v-if="$can('templates.store') && local_uc_length">
                            <a-list-item @click="$emit('exportAsTemplate')" :disabled="!!(local_uc_length === 0 || local_is_filter_selected)">
                                <a-list-item-content class="md-body-2">Export as Template</a-list-item-content>
                            </a-list-item>
                        </template>
                    </a-list>
                </a-menu>
            </div>
        </div>

        <slot name="usecase-add" v-if="!local_is_filter_selected && local_can('update')"></slot>

        <div class="u-flex-center-y">
            <template v-if="filter_selected.label">
                <div class="mt-6">
                    <slot name="select-all" :loading="local_filter_loading"></slot>
                </div>
            </template>

            <!-- Bulk edit icons -->
            <template v-if="!local_filter_loading && ((modeSelected && modeSelected.mode_slug && modeSelected.mode_slug !== 'none') || (ucBulkCount || scBulkCount || tcBulkCount))">
                <a-sheet class="u-border u-rounded-corners-lg d-inline-flex align-center pa-1 mt-6">
                    <a-tooltip bottom :disabled="bulkLoading || !localGetCount">
                        <template v-slot:activator="{ on }">
                            <div 
                                v-on="on" 
                                @click="(bulkLoading || !localGetCount) ? '' : $emit('bulkAssign')" 
                                :class="{ 'u-opacity-40': !localGetCount }" 
                                class="u-cursor-pointer u-rounded-corners-lg u-flex-center px-2 py-1 mr-0" 
                                v-ripple="!localGetCount ? false : { class: 'grey--text text--darken-1' }"
                            >
                                <a-icon size="24" color="grey darken-1">person_add_alt_1</a-icon>
                            </div>
                        </template>
                        <span>Assign Collaborators</span>
                    </a-tooltip>

                    <a-tooltip bottom :disabled="bulkLoading || !localGetCount">
                        <template v-slot:activator="{ on }">
                            <div 
                                v-on="on" 
                                @click="(bulkLoading || !localGetCount) ? '' : $emit('bulkRemove')" 
                                :class="{ 'u-opacity-40': !localGetCount }" 
                                class="u-cursor-pointer u-rounded-corners-lg u-flex-center px-2 py-1" 
                                v-ripple="!localGetCount ? false : { class: 'grey--text text--darken-1' }"
                            >
                                <a-icon size="24" color="grey darken-1">person_remove</a-icon>
                            </div>
                        </template>
                        <span>Remove Collaborators</span>
                    </a-tooltip>
                </a-sheet>

                <a-sheet class="u-border u-rounded-corners-lg d-inline-flex align-center mt-6 ml-4" style="padding: 2px 3px">
                    <a-tooltip bottom :disabled="bulkLoading || !localGetCount">
                        <template v-slot:activator="{ on }">
                            <div 
                                v-on="on" 
                                @click="(bulkLoading || !localGetCount) ? '' : $emit('addTags')" 
                                class="u-cursor-pointer u-rounded-corners-lg u-flex-center pa-2" 
                                v-ripple
                            >
                                <a-icon size="20">$scope_bulkadd_tag</a-icon>
                            </div>
                        </template>
                        <span>Add Tags</span>
                    </a-tooltip>

                    <a-tooltip bottom :disabled="bulkLoading || !localGetCount" class="pa-2">
                        <template v-slot:activator="{ on }">
                            <div 
                                v-on="on" 
                                @click="(bulkLoading || !localGetCount) ? '' : $emit('removeTags')" 
                                class="u-cursor-pointer u-rounded-corners-lg u-flex-center pa-2" 
                                v-ripple
                            >
                                <a-icon size="20">$scope_bulkremove_tag</a-icon>
                            </div>
                        </template>
                        <span>Remove Tags</span>
                    </a-tooltip>
                </a-sheet>

                <a-sheet v-if="filter_selected.value !== 'all-uc'" class="u-border u-rounded-corners-lg d-inline-flex align-center pa-1 mt-6 ml-4">
                    <template v-if="project_item && project_item.status !== 'active'">
                        <a-tooltip bottom v-if="filter_selected.value !== 'all-uc'">
                            <template v-slot:activator="{ on }">
                                <div v-on="on" :class="{ 'u-opacity-40': !localGetCount }" class="u-cursor-pointer u-rounded-corners-lg u-flex-center px-2 py-1">
                                    <a-icon size="22" color="grey lighten-1">$progress</a-icon>
                                </div>
                            </template>
                            <span class="u-flex-center-y grey--text text--lighten-2">
                                <a-icon size="18" color="grey lighten-2" class="mr-2">info</a-icon>
                                Project is not active
                            </span>
                        </a-tooltip>
                    </template>
                    <template v-else>
                        <a-tooltip bottom :disabled="bulkLoading || !localGetCount" v-if="filter_selected.value !== 'all-uc'">
                            <template v-slot:activator="{ on }">
                                <div 
                                    v-on="on" 
                                    @click="(bulkLoading || !localGetCount) ? '' : $emit('bulkStatusUpdate')" 
                                    :class="{ 'u-opacity-40': !localGetCount }" 
                                    class="u-cursor-pointer u-rounded-corners-lg u-flex-center px-2 py-1" 
                                    v-ripple="!localGetCount ? false : { class: 'grey--text text--darken-1' }"
                                >
                                    <a-icon size="22" color="grey darken-1">$progress</a-icon>
                                </div>
                            </template>
                            <span>Change Status</span>
                        </a-tooltip>
                    </template>
                </a-sheet>

                <a-sheet class="u-border u-rounded-corners-lg d-inline-flex align-center pa-1 mt-6 ml-4">
                    <template v-if="local_can('destroy')">
                        <!-- <a-divider vertical class="mx-2 grey lighten-2"></a-divider> -->
                        <a-tooltip bottom :disabled="bulkLoading || !localGetCount" content-class="red darken-2 u-elevation-custom-1">
                            <template v-slot:activator="{ on }">
                                <div 
                                    v-on="on" 
                                    @click="(bulkLoading || !localGetCount) ? '' : $emit('bulkDelete')" 
                                    :class="{ 'u-opacity-40': !localGetCount }" 
                                    class="u-cursor-pointer u-rounded-corners-lg u-flex-center px-2 py-1" 
                                    v-ripple="!localGetCount ? false : { class: 'red--text text--lighten-1' }"
                                >
                                    <a-icon size="24" color="red darken-1">delete</a-icon>
                                </div>
                            </template>
                            <span class="white--text">
                                Delete
                                <template v-if="filter_selected.value === 'all-uc'">Use Cases</template>
                                <template v-if="filter_selected.value === 'all-sc'">Success Criteria</template>
                                <template v-if="filter_selected.value === 'all-tc'">Test Cases</template>
                                <!-- <template v-if="filter_selected.value === 'all-uc'">{{ ucBulkCount ? ucBulkCount : '' }} Use Cases</template>
                                <template v-if="filter_selected.value === 'all-sc'">{{ scBulkCount ? scBulkCount : '' }} Success Criteria</template>
                                <template v-if="filter_selected.value === 'all-tc'">{{ tcBulkCount ? tcBulkCount : '' }} Test Cases</template> -->
                            </span>
                        </a-tooltip>
                    </template>
                </a-sheet>

                <!-- No count info -->
                <a-sheet class="u-flex-center-y mt-6 ml-4 px-3 blue-grey lighten-4 u-rounded-corners-lg" height="40" v-if="!localGetCount && checkedAll">
                    <a-icon size="20" color="blue-grey darken-1" class="u-icon-nudge" style="top: 1px;">info</a-icon>
                    <div class="blue-grey--text text--darken-1 font-weight-medium md-body-2 ml-2">No items found for this selection</div>
                </a-sheet>
            </template>


            <!-- Loading -->
            <div v-if="bulkLoading" class="u-flex-center-y mt-6 ml-3">
                <a-progress-circular color="orange darken-2" width="3" size="26" indeterminate></a-progress-circular>
                <!-- <span class="ml-2 md-body-2 grey--text text--darken-1">Loading...</span> -->
            </div>

            <a-spacer></a-spacer>

            <!-- Show only selected -->
            <slot name="show-selected" :visible="!!localGetCount" v-if="local_is_filter_selected"></slot>

            <!-- Expand/Collaps All -->
            <div 
                v-if="local_is_filter_selected && (selectedFilter && selectedFilter.value !== 'all-uc')" 
                @click="(bulkLoading || expandAllLoading || local_filter_loading) ? '' : local_expand_toggle()" 
                class="u-border mt-6 u-rounded-corners-lg white d-inline-flex align-center px-3 py-1 u-cursor-pointer" 
                style="min-height: 40px" 
                v-ripple="true"
            >
                <span class="md-body-2">{{ list_expand_all ? 'Collapse All' : 'Expand All' }}</span>
                <a-progress-circular v-if="expandAllLoading" color="orange darken-2" class="ml-2" width="2" size="18" indeterminate></a-progress-circular>
                <a-icon size="18" class="ml-2" color="grey darken-1" v-else>unfold_{{ list_expand_all ? 'less' : 'more' }}</a-icon>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'PListViewToolbar',

    props: {
        tcStatuses: {
            type: Array
        },
        scStatuses: {
            type: Array
        },
        ucBulkCount: {
            type: Number
        },
        scBulkCount: {
            type: Number
        },
        tcBulkCount: {
            type: Number
        },
        selectedFilter: {
            type: Object
        },
        modeSelected: {
            type: Object
        },
        selectAllState: {
            type: Boolean,
            default: false
        },
        checkedAll: {
            type: Boolean,
        },
        bulkLoading: {
            type: Boolean,
        },
    },

    data () {
        return {
            list_expand_all: false,
            expandAllLoading: false,
            showSelected: false,
            loading_module: null,
            filter_selected: { label: '', value: '' },
            filters_selection_list: [
                { label: 'Use Cases', value: 'all-uc' },
                { label: 'Success Criteria', value: 'all-sc' },
                { label: 'Test Cases', value: 'all-tc' },
            ],
            module_selected: { label: '', value: '' },
            action_selected: { label: '', value: '' }
        }
    },


    watch: {
        selectedFilter (value) {
            this.list_expand_all = !!(value && value.value)
        },
    },

    computed: {
        local_status_list () {
            if (this.module_selected.value || ['all-tc', 'all-sc'].includes(this.filter_selected.value)) {
                if (this.module_selected.value === 'module-tc' || this.filter_selected.value === 'all-tc') return this.tcStatuses
                if (this.module_selected.value === 'module-sc' || this.filter_selected.value === 'all-sc') return this.scStatuses
            }
            return []
        },

        local_is_filter_selected () {
            return !!this.filter_selected.label
        },

        local_show_visibility_dropdown () {
            return this.module_selected.value === 'module-uc' || this.filter_selected.value === 'all-uc'
        },

        local_show_status_dropdown () {
            return ['module-tc', 'module-sc'].includes(this.module_selected.value) || ['all-sc', 'all-tc'].includes(this.filter_selected.value)
        },

        localGetCount () {
            if (!this.filter_selected || (this.filter_selected && !this.filter_selected.value)) return 0

            switch (this.filter_selected.value) {
                case 'all-uc': return this.ucBulkCount
                case 'all-sc': return this.scBulkCount
                case 'all-tc': return this.tcBulkCount
            }
        },

        local_uc_length () {
            return (this.usecase_list && this.usecase_list.length) ?? 0
        },

        local_filter_loading () {
            return this.local_is_loading('filter-selected')
        },

        ...mapState('Usecase', {
            usecase_list: 'list'
        }),

        ...mapState('ProjectView', {
            project_item: 'item'
        })
    },

    methods: {
        local_select_filter (filter) {
            this.loading_module = 'filter-selected'
            this.filter_selected = filter
            this.$emit('filterSelected', filter, this.local_clear_loading)
        },

        local_clear_loading () {
            this.loading_module = null
        },

        local_is_loading (type) {
            return this.loading_module === type
        },

        local_expand_toggle () {
            this.expandAllLoading = true
            setTimeout(() => {
                this.list_expand_all = !this.list_expand_all
                this.$emit('expandAll', this.list_expand_all, this.local_clear_expand_loading)
            }, 100)
        },

        local_clear_expand_loading () {
            this.expandAllLoading = false
        },

        local_trigger_action () {
            this.$emit('applyAction')
        },

        // Extras
        local_can (type) {
            return this.$can(`usecases.${type}`) && this.$can('projects.update-only')
        },

        local_clear_select_filter () {
            this.loading_module = 'clear-all-filters'
            setTimeout(() => {
                this.filter_selected = { label: '', value: '' }
                this.module_selected = { label: '', value: '' }
                this.action_selected = { label: '', value: '' }
                this.$emit('clearBulkSelection', true, this.local_clear_loading)
            }, 200)
        },
    }
}
</script>

<template>
    <a-main>
        <a-responsive class="grey darken-4 mt-5 px-2 u-rounded-corners-lg">
            <a-container grid-list-xl container--fluid class="py-2">
                <a-layout align-start>
                    <a-flex shrink class="px-0">
                        <a-btn color="white" text :to="{name: 'customers'}" exact>
                            <a-icon class="mr-1">arrow_back</a-icon> Back to Accounts
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive>
        <a-container grid-list-xl container--fluid class="pa-6 pt-3 px-0 mb-12">
            <g-page-header
                :title="(resource_name) ? resource_name+'\'s Notes' : 'Loading...'"
                subtitle="Manage notes linked to this account"
                icon="notes"
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                class="pr-1"
            >
                <template v-slot:action v-if="$can('notes.store')">
                    <div class="d-flex align-center">
                        <div class="pl-0 mr-3" v-if="$can('notes.store') && $can('templates.show') || $can('notes.store') && $can('templates.store')">
                            <a-menu bottom left offset-y>
                                <template v-slot:activator="{ on }">
                                    <a-btn color="neutral" icon class="ma-0 elevation-0" v-on="on">
                                        <a-icon size="24" color="neutral darken-4">more_horiz</a-icon>
                                    </a-btn>
                                </template>
                                <a-list>
                                    <a-list-item @click="local_template_import_form()" v-if="$can('templates.show')">
                                        <a-list-item-title>Import from template</a-list-item-title>
                                    </a-list-item>
                                    <!-- <a-list-item @click="local_template_export_form()" v-if="$can(user_self, 'templates.store')" :disabled="note_list_project.length === 0"> -->
                                    <a-list-item @click="local_template_export_form()" v-if="$can('templates.store')">
                                        <a-list-item-title>Export as template</a-list-item-title>
                                    </a-list-item>
                                </a-list>
                            </a-menu>
                        </div>
                        <a-btn color="primary" class="elevation-0 mx-0" dark @click="local_note_create()">
                            <a-icon class="mr-2" color="white" size="18">add</a-icon>
                            Create New Note
                        </a-btn>
                    </div>
                </template>
            </g-page-header>
            <a-layout wrap align-start>
                <a-flex xs12>
                    <a-card
                        v-for="(note, index) in note_list"
                        :key="note.id"
                        :class="[{ 'mt-3': index !== 0 }]"
                        class="u-border u-cursor-pointer my-2 d-block u-rounded-corners"
                        flat
                    >
                        <router-link
                            tag="a"
                            class="d-block pa-1"
                            :class="{ 'yellow lighten-4': note.visibility === 'external', }"
                            :to="{name: 'customers-notes-view', params: {note_id: note.id}}"
                        >
                            <a-card-text class="pa-0">
                                <a-container container--fluid grid-list-xl class="py-3 px-4">
                                    <a-layout align-center :class="{ 'wrap': local_screen_lg }">
                                        <a-flex :class="[{ 'pb-0': local_screen_lg }, 'text-truncate u-wfull']">
                                            <h2 class="md-body-1 text-truncate mb-2" :class="{ 'mb-1': local_screen_lg }" :title="note.title">
                                                {{ note.title }}
                                            </h2>
                                        </a-flex>
                                        <a-spacer></a-spacer>
                                        <a-flex :grow="!local_screen_lg" :shrink="local_screen_lg" :class="{ 'pt-0': local_screen_lg }" style="max-width: 50px" v-if="local_bridge_value(note, 'salesforce', 'account.salesforce_id')">
                                            <a-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <img src="../../assets/images/icon-salesforce.svg" height="12" alt="Salesforce" class="d-block">
                                                    </div>
                                                </template>
                                                <span>Salesforce Synced</span>
                                            </a-tooltip>
                                        </a-flex>
                                        <a-flex :grow="!local_screen_lg" :shrink="local_screen_lg" :class="{ 'pt-0': local_screen_lg }" style="max-width: 180px">
                                            <a-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on" class="u-flex-center-y">
                                                        <a-icon size="18" color="grey darken-2" class="u-icon-nudge mr-1">post_add</a-icon>
                                                        <h3 v-if="note.type" style="max-width: 150px;" class="md-body-2 grey--text text--darken-2 text-truncate">
                                                            {{ note.type.value }}
                                                        </h3>
                                                        <span class="md-body-2 grey--text text--darken-2" v-else>No Category</span>
                                                    </div>
                                                </template>
                                                <span>Category</span>
                                            </a-tooltip>
                                        </a-flex>
                                        <a-flex :grow="!local_screen_lg" :shrink="local_screen_lg" :class="{ 'pt-0': local_screen_lg }">
                                            <a-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <a-icon size="18" color="grey darken-2" class="u-icon-nudge mr-1">person</a-icon>
                                                        <span class="md-body-2 grey--text text--darken-2">{{ (note.updated_by) ? note.updated_by.name : 'Salesforce' }}</span>
                                                    </div>
                                                </template>
                                                <span>Updated User</span>
                                            </a-tooltip>
                                        </a-flex>
                                        <a-flex :grow="!local_screen_lg" :shrink="local_screen_lg" :class="{ 'pt-0': local_screen_lg }" style="min-width: 180px;">
                                            <a-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <a-icon size="18" color="grey darken-2" class="u-icon-nudge mr-1">date_range</a-icon>
                                                        <g-moment class="md-body-2 grey--text text--darken-2" :value="note.updated_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                                                    </div>
                                                </template>
                                                <span>Updated Date and Time</span>
                                            </a-tooltip>
                                        </a-flex>
                                        <a-flex :grow="!local_screen_lg" :shrink="local_screen_lg" :class="{ 'pt-0': local_screen_lg }" style="min-width: 180px;">
                                            <g-attachment-count-info
                                                :count="note.attachments_count"
                                                fontSize="14"
                                            />
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-card-text>
                        </router-link>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog v-model="dialog_import_form" max-width="800" persistent>
            <a-card>
                 <a-container container--fluid grid-list-xl class="pa-6">
                    <a-layout wrap align-center>
                        <a-flex shrink class="pr-1">
                            <a-avatar class="primary darken-1" size="40">
                                <a-icon size="18" dark>system_update_alt</a-icon>
                            </a-avatar>
                        </a-flex>
                        <a-flex>
                            <h2 class="md-heading-6 primary--text text--darken-1">Import Notes from Templates</h2>
                            <p class="md-subtitle-2 mb-0">You can import multiple notes into accounts.</p>
                        </a-flex>
                        <a-flex shrink>
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a-btn small text icon color="grey"  @click="dialog_import_form = !dialog_import_form" class="mx-0 mt-0 mr-0 mb-0 pa-0 px-3 right" v-on="on">
                                        <a-icon size="26">cancel</a-icon>
                                    </a-btn>
                                </template>
                                <span>Close</span>
                            </a-tooltip>
                        </a-flex>
                    </a-layout>
                </a-container>
                <a-divider></a-divider>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl pa-6>
                        <a-layout wrap align-center>
                            <a-flex>
                                <a-responsive class="py-4 my-4" v-if="!loading && template_group_list.length == 0">
                                    <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">NO NOTE TEMPLATE FOUND</h3>
                                    <p class="md-body-2 text-center grey--text mb-0">There is no notes template present in your Organization<br></p>
                                    <p class="md-body-2 text-center grey--text mb-0"><a-icon size="22" color="orange">emoji_objects</a-icon>You can create new template manually under Settings or export notes from existing projects.</p>
                                </a-responsive>
                                <a-card
                                    v-for="template_group in template_group_list"
                                    :key="template_group.id"
                                    class="u-elevation-custom-1 my-2 neutral"
                                >
                                    <a-card-text class="pa-0">
                                        <a-container container--fluid grid-list-xl class="py-3 px-4">
                                            <a-layout align-center>
                                                <a-flex>
                                                    <h2 class="md-body-1">
                                                        {{ template_group.name }} <span class="md-body-2 grey--text text--darken-1">{{ template_group.template_note_count }} Notes</span>
                                                    </h2>
                                                </a-flex>
                                                <a-flex shrink>
                                                    <a-btn text class="elevation-0 white--text" :class="{ 'blue darken-1': template_group.template_note_count > 0, 'grey lighten-1': template_group.template_note_count === 0 }" @click="local_template_import(template_group.id)" small :disabled="!template_group.template_note_count || loading">Import</a-btn>
                                                </a-flex>
                                            </a-layout>
                                        </a-container>
                                    </a-card-text>
                                </a-card>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog v-model="dialog_export_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="local_user_store()">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>insert_drive_file</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Export Notes as Template</h2>
                                    <p class="md-subtitle-2 mb-0">You can save list of notes to import them in new projects.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <v-radio-group v-model="export_mode" class="mt-0" hide-details row @change="local_export_mode_change">
                                        <v-radio label="New Template" value="new"></v-radio>
                                        <v-radio label="Existing Template" value="existing" :disabled="!$can('templates.update') || !this.$can('templates.show')"></v-radio>
                                    </v-radio-group>
                                </a-flex>
                                <a-flex xs12 v-if="export_mode == 'new'">
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Group Name</label>
                                    <a-text-field
                                        v-model="template_note_item.name"
                                        placeholder="Enter Template Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_group_response.server && template_group_response.server.errors && template_group_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_group_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the user.
                                    </span>
                                </a-flex>
                                <a-flex xs12 v-if="export_mode == 'existing'">
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Group</label>
                                    <a-autocomplete
                                        v-model="template_note_item.id"
                                        :items="template_group_list"
                                        item-text="name"
                                        item-value="id"
                                        placeholder="Select Template"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-autocomplete>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_note_response.server && template_note_response.server.errors && template_note_response.server.errors.template_group_id">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_note_response.server.errors.template_group_id[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the user.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_template_export()" :loading="loading" :disabled="loading">Save Template</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_export_form = !dialog_export_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog max-width="800" v-model="dialog_note_form" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="40">
                                    <a-icon dark>notes</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1 mb-1">Account Note</h2>
                                <p class="md-subtitle-2 mb-0">You can add new note to the account</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="px-6 py-4">
                        <a-layout wrap align-start v-if="user_self.role && user_self.role.scope === 'internal' && this.$can('templates.show')">
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Select From Template (Optional)</label>
                                <a-autocomplete
                                    v-model="local_template_note_id"
                                    :items="template_note_list"
                                    item-text="title"
                                    item-value="id"
                                    placeholder="Select a note from existing template"
                                    class="elevation-0"
                                    background-color="neutral"
                                    solo flat hide-details clearable
                                    @change="local_select_template_note(local_template_note_id)"
                                >
                                <template v-slot:no-data>
                                    <div class="px-4 py-2">
                                        <span class="md-body-1" v-if="local_template_note_search != null && local_template_note_search != ''"> <strong>{{ local_template_note_search }}</strong></span>
                                        <span class="md-body-1 grey--text" v-else>No note template found</span>
                                    </div>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <span v-if="item === Object(item)" class="md-body-3 d-block py-1 u-rounded-corners text-truncate" style="max-width: 630px" :title="item.title">{{ item.title }} </span>
                                    <span v-else class="md-body-2 d-block text-truncate py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <a-layout align-center class="py-0">
                                        <a-flex style="max-width: 630px">
                                            <h5 class="md-subtitle-1 text-truncate" :title="item.title">{{ item.title }}</h5>
                                            <p class="md-caption d-block grey--text text-truncate text--darken-1 mt-1 mb-0" v-if="item.template_group">{{ item.template_group.name }}</p>
                                        </a-flex>
                                    </a-layout>
                                </template>
                                </a-autocomplete>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="note_response.server && note_response.server.errors && note_response.server.errors.template_note_id">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ note_response.server.errors.template_note_id[0] }}
                                </span>
                            </a-flex>
                        </a-layout>
                        <a-layout wrap align-start>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Note Title</label>
                                <a-text-field
                                    v-model="note_item.title"
                                    placeholder="Enter new note title"
                                    background-color="neutral"
                                    solo flat hide-details
                                >
                                </a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="note_response.server && note_response.server.errors && note_response.server.errors.title">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ note_response.server.errors.title[0] }}
                                </span>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink>
                                <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_note_store()" :loading="loading" :disabled="loading">Save</a-btn>
                                <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_note_form_close()" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-snackbar v-model="snackbar_status" :timeout="5000" bottom center>
            Note Imported Successfully!
        </a-snackbar>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            resource_name: null,
            type_list: [],
            export_mode: 'new',
            dialog_import_form: false,
            dialog_export_form: false,
            snackbar_status: false,
            dialog_note_form: false,
            local_template_note_id: '',
            local_template_note_search: null,
            local_note_filter: {
                'include': 'type,updatedBy,bridges,attachmentsCount',
                'fields[type]': 'id,type,value',
                'sort': 'order',
                'filter[parent_id]' : this.$route.params.id,
                'filter[parent_type]' : 'Customer',
            },
            local_template_fields: { 'include': 'templateGroup','sort': 'title'},

        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        local_screen_lg() {
            return this.$vuetify.breakpoint.lgAndDown
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Note', {
            note_list: 'list',
            note_item: 'item',
            note_filters: 'filters',
            note_response: 'response',
        }),
        ...mapState('TemplateGroup', {
            template_group_list: 'list',
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),
        ...mapState('TemplateNote', {
            template_note_list: 'list',
            template_note_item: 'item',
            template_note_response: 'response',
            template_note_filters: 'filters',
        }),
        ...mapState('Customer', {
            customer_item: 'item',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('notes.show') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            this.local_customer_list()
            this.local_load_note_list()

            if (this.$can('templates.show')) {
                this.template_note_index(this.local_template_fields)
            }
        },
        async local_load_note_list() {
            await this.note_index(this.local_note_filter)
        },
        async local_customer_list() {
            await this.customer_clear_item()
            await this.note_clear()
            await this.customer_show({id: this.$route.params.id})
            this.resource_name = this.$options.filters.truncateText(this.customer_item.name, 50)
        },
        async local_note_create() {
            await this.note_clear_item()
            this.local_template_note_id = ''
            this.dialog_note_form = true
        },
        async local_note_form_close() {
            await this.note_clear_item()
            this.local_template_note_id = ''
            this.dialog_note_form = false
        },
        async local_select_template_note(id) {
            if(!id) {
                await this.note_clear_item()
                return
            }
            await this.template_note_select({id: id})
            const template_note_item = { ...this.template_note_item }
            this.$set(this.note_item, 'title', template_note_item.title)
            this.$set(this.note_item, 'description', template_note_item.description)
            this.$set(this.note_item, 'type_id', template_note_item.type_id)
            this.$set(this.note_item, 'template_note_id', template_note_item.id)
            await this.template_note_clear_item()
        },
        async local_note_store() {
            if(!this.note_item.description) this.$set(this.note_item, 'content', ' ')
            this.$set(this.note_item, 'visibility', 'internal')
            this.$set(this.note_item, 'parent_type', 'Customer')
            this.$set(this.note_item, 'parent_id', this.customer_item.id)
            this.$set(this.note_item, 'list_addition', false)
            this.note_item.order = -1
            await this.note_store(this.note_item)
            if (this.note_response.status === 'success') {
                this.$router.push({name: 'customers-notes-view', params: {note_id: this.note_item.id} })
                await this.note_clear_item()
            }
        },
        async local_template_export_form() {
            if (this.$can('templates.show') == true) {
                await this.template_group_clear()
                this.template_group_filters.type = 'Note'
                await this.template_group_index({
                    include: 'templateNoteCount',
                    'filter[type]': 'notes'
                })
            }
            this.dialog_export_form = !this.dialog_export_form
            this.template_note_item.name = null
            this.template_note_item.id = null
            this.export_mode = 'new'
        },
        async local_template_import_form() {
            if (this.$can('templates.show') == true) {
                await this.template_group_clear()
                this.template_group_filters.type = 'Note'
                await this.template_group_index({
                    include: 'templateNoteCount',
                    'filter[type]': 'notes'
                })
            }
            this.dialog_import_form = !this.dialog_import_form
        },
        async local_template_import(id) {
            const note_import_item = {}
            note_import_item.parent_type = 'Customer'
            note_import_item.template_group_id = id
            note_import_item.parent_id = this.customer_item.id
            await this.note_template_import(note_import_item)

            if (this.note_response.status !== 'success') return
            this.local_index()
            this.$notify('success', 'Notes imported successfully!')
        },
        local_export_mode_change () {
            this.template_group_clear_item()
            this.template_note_clear_item()
        },
        async local_template_export() {
            if(this.export_mode === 'new') return this.local_note_template_group_store()
            this.local_note_template_export_action(this.template_note_item.id)
        },
        async local_note_template_group_store() {
            await this.template_group_store({ name: this.template_note_item.name, type: 'notes' })
            if (!this.$status(this.template_group_response)) return
            this.local_note_template_export_action(this.template_group_item.id)
        },
        async local_note_template_export_action(group_id) {
            const note_ids = this.note_list.map(item => item.id);
            const note_template_export_item = {}
            note_template_export_item.data = note_ids
            note_template_export_item.template_group_id = group_id
            await this.template_note_export(note_template_export_item)
            if (!this.$status(this.template_note_response)) return
            this.dialog_export_form = false
            this.$notify('success', 'Notes exported successfully!')
        },
        local_bridge_value(customer, service, key) {
            if (customer.bridges) {
                const result = customer.bridges.find(item => item.service === service && item.key === key)
                return (result && result.value) ?? ''
            }
        },
        local_check_bridge(customer, service, key) {
            return customer.bridges && customer.bridges.find(item => {
                return item.service === service && item.key === key
            })
        },
        ...mapActions('Note', {
            note_index: 'index',
            note_store: 'store',
            note_template_import: 'template_import',
            note_clear: 'clear',
            note_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_store: 'store',
            template_group_clear: 'clear',
            template_group_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateNote', {
            template_note_index: 'index',
            template_note_select: 'select',
            template_note_export: 'template_export',
            template_note_clear: 'clear',
            template_note_clear_item: 'clear_item',
        }),
        ...mapActions('Customer', {
            customer_show: 'show',
            customer_clear_item: 'clear_item',
        }),
    },
}
</script>

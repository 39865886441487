<template>
    <a-main :style="[preview_mode ? {height: '90vh'} : '']" :class="[preview_mode ? 'u-sticky u-overflow-hidden' : '']">
        <g-settings-breadcrumb></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="py-8 mb-12">
            <a-layout align-center>
                <g-page-header
                    title="Confidence Score"
                    :subtitle="`Create and manage fields of confidence score for your projects.`"
                    icon="scoreboard"
                    class="px-0 mb-1"
                >
                </g-page-header>
                <a-btn
                    v-if="$can('confidence_score.update')"
                    class="text-uppercase white u-flex md-subtitle-2 py-2 px-3 mr-4 u-rounded-corners u-cursor-pointer"
                    @click="mixinIsLoading('store') ? {} : dialog_customize = true"
                    depressed plain
                >
                    <a-icon size="20" color="grey darken-1" outlined>palette</a-icon>
                    <span class="grey--text text--darken-3 ml-2">Customize</span>
                </a-btn>
                <a-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <a-btn
                            v-on="on"
                            max-width="48"
                            min-width="48"
                            class="white u-flex md-subtitle-2 px-0 u-rounded-corners u-cursor-pointer"
                            :href="confidenceScoreHelpLink"
                            target="_blank"
                            depressed plain
                            >
                            <!-- <a-icon size="20" color="grey darken-1" outlined>calculate</a-icon> -->
                            <a-icon size="20" color="grey darken-1" outlined>question_mark</a-icon>
                        </a-btn>
                    </template>
                    <span>Help</span>
                </a-tooltip>
            </a-layout>
            <div v-if="page_loading">
                <a-divider class="grey lighten-2 mb-4"></a-divider>
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Sections..</h3>
            </div>
            <a-responsive v-if="!page_loading && !preview_mode" :max-width="$vuetify.breakpoint.lgAndDown ? '940' : '1264'" class="mx-auto">
                <a-layout v-if="local_loading" class="mt-8 mx-2">
                    <PConfidenceLoader></PConfidenceLoader>
                </a-layout>
                <template v-if="local_config_update_status">
                    <div class="py-12">
                    <div class="my-12 u-flex justify-center">
                        <a-img :src="require('/src/assets/images/confidence-score/recalculation-inprogress.png')" contain width="300px" height="232px"></a-img>
                    </div>
                    <div class="text-center mx-auto grey--text text--darken-2" style="max-width: 520px">
                        <div>The recalculation process is currently underway.</div>
                        <div>Please try after some time.</div>
                    </div>
                </div>
                </template>
                <div v-if="!local_loading && !preview_mode && !local_config_update_status">
                    <a-layout align-center class="my-4" v-if="(backup_list && backup_list.length) || unsaved_changes">
                        <a-flex class="u-flex align-center">
                            <div class="md-heading-6 indigo--text text--darken-1 font-weight-medium mr-3">Sections & Fields</div>
                            <a-tooltip bottom :nudge-right="236" :disabled="unsaved_changes && !!local_check_empty_sections(local_confidence_section_list)">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" class="md-caption font-weight-bold grey darken-3 px-2 py-1 white--text u-rounded-corners-full u-flex" v-if="local_confidence_section_list">
                                        <a-icon v-if="!unsaved_changes && !!local_check_empty_sections(local_confidence_section_list)" size="16" color="amber darken-2" class="mr-1">info</a-icon>
                                        <span>
                                            {{ local_confidence_section_list.length }} {{ local_confidence_section_list.length > 1 ? 'Sections' : 'Section'}}
                                        </span>
                                    </div>
                                </template>
                                <span class="u-flex align-center">
                                    Sections without field will not be shown under "Project
                                    <a-icon size="16" color="white" class="mx-1">arrow_forward</a-icon>
                                    Confidence Score".
                                </span>
                            </a-tooltip>
                        </a-flex>
                        <a-spacer></a-spacer>
                        <a-flex shrink class="u-flex">
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a-sheet @click="mixinIsLoading('store') ? {} : local_switch_preview_mode()" v-on="on" height="32" width="32" class="mr-3 pa-0 white u-shadow u-flex-center-y justify-center u-cursor-pointer u-rounded-corners">
                                        <a-icon size="18">visibility</a-icon>
                                    </a-sheet>
                                </template>
                                <span>Preview</span>
                            </a-tooltip>
                            <template v-if="$can('confidence_score.update')">
                                <a-btn
                                    class="mr-3 grey lighten-1 white--text"
                                    :disabled="!local_changes_unsaved || mixinIsLoading('store')"
                                    @click="dialog_cancel_changes = true"
                                    small height="32"
                                    depressed
                                >
                                    Cancel
                                </a-btn>
                                <a-btn
                                    :loading="mixinIsLoading('store')"
                                    class="indigo darken-1 white--text c-save-btn"
                                    :disabled="!local_changes_unsaved"
                                    @click="layout_modifications_log_list && layout_modifications_log_list.length ? local_review_changes() : local_store_layout()"
                                    height="32"
                                    small depressed
                                >
                                    {{ layout_modifications_log_list && layout_modifications_log_list.length ? 'Review & Save' : 'Save' }}
                                </a-btn>
                            </template>
                        </a-flex>
                    </a-layout>
                    <draggable
                        v-model="local_confidence_section_list"
                        :options="{handle: '.js-drag-handle'}"
                        scroll-sensitivity="400"
                        v-bind="local_drag_options"
                        ghost-class="c-ghost"
                        @end="local_trigger_sections_modified()"
                    >
                        <transition-group type="transition" name="flip-list">
                            <a-card
                                v-for="(section, index) in local_confidence_section_list"
                                :key="section.id"
                                color="white"
                                min-height="160"
                                class="mb-8 u-shadow u-rounded-corners c-section-card u-relative"
                            >
                                <v-hover v-slot="{ hover }">
                                    <div class="pa-6">
                                        <a-layout align-center>
                                            <a-flex class="u-flex align-center">
                                                <div style="width: 24px" class="u-flex" @mouseover="sectionDrag=true" @mouseleave="sectionDrag=false" v-if="$can('confidence_score.update')">
                                                    <a-icon v-if="hover" size="20" color="grey" :class="[{'js-drag-handle u-cursor-pointer': !mixinIsLoading('store')}]">drag_indicator</a-icon>
                                                </div>
                                                <div
                                                    :class="[(response_title && (response_title.id === section.id) && response_title.status === 'error' ? 'c-border-error c-wiggle-short': ''),'u-rounded-corners u-wfull']"
                                                    :style="[(currentEditId === section.id) ? { border: '2px solid #1495bd' } : { cursor: 'text' }]"
                                                >
                                                    <div v-if="currentEditId === section.id" class="u-flex-center-y u-wfull py-1">
                                                        <textarea
                                                            v-model="section.name"
                                                            rows="1"
                                                            class="u-wfull md-subtitle-1 d-block pl-1"
                                                            style="outline: none; resize: none;"
                                                            :ref="'title_input' + section.id"
                                                            @click.stop="{}"
                                                            @blur="local_focus_title_input(section, 'blur')"
                                                            @keydown.esc="local_inline_update(section, 'clear')"
                                                            @keydown.enter.prevent="local_inline_update(section, 'enter')"
                                                            :disabled="!$can('confidence_score.update')"
                                                            autofocus
                                                        ></textarea>
                                                        <a-btn icon small depressed width="24" height="24" class="mr-2" @click.stop="local_inline_update(section, 'click')">
                                                            <a-icon color="green darken-1">check</a-icon>
                                                        </a-btn>
                                                        <a-btn icon small depressed width="24" height="24" @mousedown="local_revert_section_title(section)" @click.stop="local_inline_update(section, 'clear')">
                                                            <a-icon color="red darken-1">clear</a-icon>
                                                        </a-btn>
                                                    </div>
                                                    <div class="u-wfull u-flex" @click="(response_title && response_title.id) || mixinIsLoading('store') ? {} : local_title_edit_focus(section)" v-else>
                                                        <h2 class="c-module-title md-subtitle-1 grey--text text--darken-4 font-weight-medium" :title="section.name">
                                                            {{ section.name | truncateText(local_is_lgdown ? 60 : 100) }}
                                                        </h2>
                                                        <a-tooltip bottom nudge-top="10" nudge-right="254" v-if="!unsaved_changes && (section.fields && !section.fields.length)">
                                                            <template v-slot:activator="{ on }">
                                                                <a-icon v-on="on" class="ml-2 u-cursor-pointer" color="amber darken-2" size="20">info</a-icon>
                                                            </template>
                                                            <span class="u-flex align-center">
                                                                Sections without field will not be shown under "Project
                                                                <a-icon size="16" color="white" class="mx-1">arrow_forward</a-icon>
                                                                Confidence Score".
                                                            </span>
                                                        </a-tooltip>
                                                    </div>
                                                </div>
                                            </a-flex>
                                            <a-spacer></a-spacer>
                                            <a-flex shrink v-if="$can('confidence_score.update')">
                                                <div class="u-flex align-center">
                                                    <a-menu
                                                        bottom
                                                        offset-y
                                                        min-width="200"
                                                        :disabled="mixinIsLoading('store')"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on" class="ml-8 u-flex u-cursor-pointer" style="paddingTop: 1px">
                                                                <a-icon size="20">more_vert</a-icon>
                                                            </div>
                                                        </template>
                                                        <a-list class="u-list-condensed py-2 pa-1">
                                                            <template v-for="(item) in section_more_options">
                                                                <a-list-item v-if="(item.value === 'moveUp' && index > 0) || (item.value === 'moveDown' && index < local_confidence_section_list.length-1) || (item.value === 'rename')" :key="item.value" class="mx-1 u-rounded-corners-lg" @click="local_modify_section(section, index, item.value)">
                                                                    <a-list-item-title>
                                                                        <span :class="['md-subtitle-2 grey--text text--darken-1']">{{ item.label }}</span>
                                                                    </a-list-item-title>
                                                                </a-list-item>
                                                            </template>
                                                            <!-- <a-divider></a-divider> -->
                                                            <a-list-item class="mx-1 u-rounded-corners-lg" @click="local_select_delete_section(section)">
                                                                <a-list-item-title class="red--text">
                                                                    <span class="md-subtitle-2">Delete</span>
                                                                </a-list-item-title>
                                                            </a-list-item>
                                                        </a-list>
                                                    </a-menu>
                                                </div>
                                            </a-flex>
                                        </a-layout>
                                    </div>
                                </v-hover>
                                <a-divider></a-divider>
                                <draggable
                                    v-model="section.fields"
                                    group="a"
                                    scroll-sensitivity="400"
                                    ghost-class="c-ghost"
                                    :options="{handle: '.js-drag-handle'}"
                                    v-bind="local_drag_options"
                                    class="px-6"
                                    @end="local_reorder_field"
                                >
                                    <transition-group type="transition" name="flip-list" :class="[{'mt-6': section.fields && section.fields.length},'u-wfull c-section-container']" :data-section-id="section.id">
                                        <div
                                            v-for="(field, fieldIndex) in section.fields"
                                            :key="field.id"
                                            :data-field-id="field.id"
                                            class="u-relative"
                                            :class="[{'c-field-card': field.id !== 'dummy_id'}, field.width === '50%' ? 'c-field-card__half' : 'c-field-card__full', 'mb-5 u-rounded-corners']"
                                        >
                                            <PConfidenceFieldCard
                                                @hover="local_remove_hover_element(field, fieldIndex, index)"
                                                @ondrag="hide_add_field = true"
                                                @dragend="hide_add_field = false"
                                                @leave="hide_add_field = false"
                                                :class="[{'u-cursor-pointer js-drag-handle' : $can('confidence_score.update') && !mixinIsLoading('store')}]"
                                                v-if="field && field.id !== 'dummy_id'"
                                                :item="field"
                                                :loading="mixinIsLoading('store')"
                                                @edit="local_edit_field(field, index)"
                                                @delete="local_delete_field"
                                            >
                                            </PConfidenceFieldCard>

                                            <!-- Add field hover UI -->
                                            <template v-if="!hide_add_field && field.id !== 'dummy_id' && section.is_active">
                                                <div class="u-absolute"
                                                    v-if="field && field.insertable"
                                                    :class="[field.width === '50%' ? (field.solo_field ? 'c-add-field-solo' : 'c-add-field-sm') : 'c-add-field-lg', 'u-wfull u-cursor-pointer']"
                                                    @mouseover="mixinIsLoading('store') ? {} : local_display_hover_element(field, fieldIndex, index)"
                                                >
                                                </div>
                                            </template>
                                            <div v-if="(field.id === 'dummy_id')">
                                                <div
                                                    style="top: -8px; height: 16px"
                                                    :class="[field.width === '50%' ? ($vuetify.breakpoint.lgAndDown ? 'c-add-field-sm': 'c-add-field-lg')  : 'u-wfull']"
                                                    class="align-center u-flex u-absolute"

                                                >
                                                    <div class="c-insert-field-divider c-insert-field-divider__left"></div>
                                                    <a-menu
                                                        v-model="dropdown[field.id]"
                                                        max-width="280"
                                                        min-width="280"
                                                        nudge-bottom="12"
                                                        nudge-left="140"
                                                        absolute
                                                        :close-on-content-click="false"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <a-icon v-on="on" size="18" color="indigo darken-1" class="u-cursor-pointer">add_circle</a-icon>
                                                        </template>
                                                        <a-sheet class="pa-5 u-overflow-y c-tiny-scroll" style="maxHeight: 250px;">
                                                            <template>
                                                                <div v-for="option in local_field_type_list" :key="option.id">
                                                                    <v-hover v-slot="{ hover }">
                                                                        <div :class="[ hover ? 'indigo lighten-5' : '','mb-3 px-3 py-2 rounded u-flex justify-space-between align-center u-cursor-pointer u-border']" @click="local_add_field('insert', option, section, fieldIndex)">
                                                                            <div class="u-flex align-center">
                                                                                <a-icon size="20" color="grey darken-1" class="mr-2">{{ option.icon }}</a-icon>
                                                                                <div class="grey--text text--darken-2">{{ option.label }}</div>
                                                                            </div>
                                                                            <div v-if="hover" class="u-flex align-center">
                                                                                <a-icon size="18" color="indigo darken-1">add_circle</a-icon>
                                                                            </div>
                                                                        </div>
                                                                    </v-hover>
                                                                </div>
                                                            </template>
                                                        </a-sheet>
                                                    </a-menu>
                                                    <div class="c-insert-field-divider c-insert-field-divider__right"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </transition-group>
                                </draggable>
                                <template v-if="$can('confidence_score.update')">
                                    <div class="d-flex justify-center" :class="[(section.fields && section.fields.length) ? 'pa-6 pt-3' : 'pa-6', 'u-wfull']">
                                        <a-menu
                                            v-model="dropdown[section.id]"
                                            max-width="280"
                                            min-width="280"
                                            nudge-bottom="24"
                                            nudge-left="140"
                                            absolute
                                            :disabled="mixinIsLoading('store')"
                                            :close-on-content-click="false"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <div class="u-wfull" style="border: 1px dashed #BDBDBD; height: 48px">
                                                    <button
                                                        v-on="on"
                                                        class="rounded c-field-menu text-capitalize u-wfull u-flex align-center justify-center"
                                                        style="height: 100%"
                                                    >
                                                        <div class="d-flex justify-center align-center">
                                                            <a-icon size="20" color="grey darken-3">add</a-icon>
                                                            <span class="md-subtitle-2 grey--text text--darken-3 text-uppercase ml-1" style="font-weight: 600">Add Field</span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </template>
                                            <a-sheet class="pa-5 u-overflow-y c-tiny-scroll" style="maxHeight: 250px;">
                                                <template>
                                                    <div v-for="option in local_field_type_list" :key="option.id">
                                                        <v-hover v-slot="{ hover }">
                                                            <div :class="[ hover ? 'indigo lighten-5' : '','mb-3 px-3 py-2 rounded u-flex justify-space-between align-center u-cursor-pointer u-border']" @click="local_add_field('append', option, section)">
                                                                <div class="u-flex align-center">
                                                                    <a-icon size="20" color="grey darken-1" class="mr-2">{{ option.icon }}</a-icon>
                                                                    <div class="grey--text text--darken-2">{{ option.label }}</div>
                                                                </div>
                                                                <div v-if="hover" class="u-flex align-center">
                                                                    <a-icon size="18" color="indigo darken-1">add_circle</a-icon>
                                                                </div>
                                                            </div>
                                                        </v-hover>
                                                    </div>
                                                </template>
                                            </a-sheet>
                                        </a-menu>
                                    </div>
                                </template>

                                <!-- Section add UI -->
                                <template v-if="(index !== local_confidence_section_list.length - 1) && $can('confidence_score.update') && !sectionDrag">
                                    <div class="c-add-section u-relative u-absolute u-wfull" style="height: 32px">
                                        <div class="c-add-section__bar u-absolute u-wfull u-flex align-center" style="top: 7px">
                                            <div class="c-insert-field-divider c-insert-field-divider__left"></div>
                                            <a-icon size="18" @click="mixinIsLoading('store') ? {} : local_create_section(index)" color="indigo darken-1" class="u-cursor-pointer">add_circle</a-icon>
                                            <div class="c-insert-field-divider c-insert-field-divider__right"></div>
                                        </div>
                                    </div>
                                </template>
                            </a-card>
                        </transition-group>
                    </draggable>
                    <a-card
                        v-if="((backup_list && backup_list.length) || unsaved_changes) && $can('confidence_score.update')"
                        flat
                        height="48"
                        class="mb-12 u-shadow"
                    >
                        <button
                            class="rounded c-field-menu text-capitalize u-wfull u-flex align-center justify-center"
                            style="height: 100%"
                            @click="mixinIsLoading('store') ? {} : local_create_section()"
                        >
                            <a-icon size="20" color="indigo darken-2">add</a-icon>
                            <span class="md-subtitle-2 indigo--text text--darken-2 text-uppercase ml-1" style="font-weight: 600">Add Section</span>
                        </button>
                    </a-card>
                    <div class="u-wfull u-flex flex-column align-center mt-14" v-if="$can('confidence_score.update') && (backup_list && !backup_list.length) && (local_confidence_section_list && !local_confidence_section_list.length)">
                        <div>
                            <a-img :src="require('/src/assets/images/confidence-score/add-section.png')" contain width="150px" height="160px"></a-img>
                        </div>
                        <div class="grey--text text--darken-3 text-center mt-6" style="max-width: 580px; letter-spacing: -0.26px">
                            <div class="md-heading-5 font-weight-medium">
                                Confidence Score
                            </div>
                            <div class="mt-4">
                                The confidence score doesn't have any sections/fields. Please configure the same to determine the confidence score of your projects.
                            </div>
                        </div>
                        <a-btn v-if="$can('confidence_score.update')" color="indigo darken-1 white--text mt-8" width="150px" @click="mixinIsLoading('store') ? {} : local_create_section()" depressed>
                            <a-icon size="20">add</a-icon>
                            <div class="md-subtitle-2 text-uppercase">Add Section</div>
                        </a-btn>
                    </div>
                </div>
            </a-responsive>

            <PConfidenceFieldPreview
                v-if="!page_loading && preview_mode"
                :list="local_preview_list"
                :total-score="total_score"
                :config-data="configuration_meta"
                class="u-sticky"
                style="top: 0"
                @update="local_update_field_value"
                @exit="preview_mode = false"
            >
            </PConfidenceFieldPreview>
        </a-container>

        <!-- Confidence field dialog -->
        <a-dialog class="u-hfull" v-model="dialog_confidence_field_form" max-width="640" scrollable persistent>
            <PConfidenceFieldForm
                :item="local_confidence_field_item"
                :backup-item="backup_field_item"
                :list="local_confidence_section_list"
                :field-type="local_field_type"
                :mode="local_form_mode"
                :is-open="dialog_confidence_field_form"
                @create="local_create_field"
                @update="local_update_field"
                @close="dialog_confidence_field_form = false"
                scrollable
            >
            </PConfidenceFieldForm>
        </a-dialog>

        <!-- Review and save dialog-->
        <a-dialog v-model="dialog_review_changes" max-width="1036" scrollable persistent>
            <PReviewChangesDialog
                style="overflow-y: hidden !important"
                :list="layout_modifications_log_list"
                :is-open="dialog_review_changes"
                :loading-store="mixinIsLoading('store')"
                @store="local_store_layout()"
                @revert="local_revert_item"
                @close="local_exit_review()"
            >
            </PReviewChangesDialog>
        </a-dialog>

        <!-- Dialog confirm delete -->
        <a-dialog max-width="450" persistent v-model="dialog_confirm_delete">
            <a-card flat class="pa-6 c-radius" style="position: relative">
                <a-card-text class="pa-0">
                    <a-card-text class="pa-0">
                        <div class="md-heading-6 font-weight-medium mb-3 grey--text text--darken-3">Are you sure you want to delete this section?</div>
                        <div class="md-body-1 grey--text text--darken-1" style="line-height: 1.4">Please note that this action will delete all the fields under this section. Do you want to proceed?</div>
                        <div class="mt-6">
                            <a-btn @click="local_delete_section()" small text class="red lighten-4 red--text text--darken-2 u-rounded-corners px-4 py-4 mr-4" dark>Yes, Delete</a-btn>
                            <a-btn @click="local_cancel_delete()" small text class="grey lighten-4 grey--text u-rounded-corners px-4 py-4" dark>Cancel</a-btn>
                        </div>
                    </a-card-text>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!--Cancel change alert-->
        <a-dialog v-model="dialog_cancel_changes" persistent max-width="400">
            <SCancelChanges
                @success="local_cancel_changes()"
                @close="dialog_cancel_changes = false"
                class="pa-6"
            />
        </a-dialog>

        <!--Leaving page without save alert -->
        <a-dialog v-model="dialog_leave_page_alert" persistent max-width="400">
            <SCancelChanges
                @success="local_leave_page()"
                @close="dialog_leave_page_alert = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>

        <!-- Customize -->
        <a-dialog v-model="dialog_customize" :persistent="local_config_status === 'in-progress'" max-width="884">
            <PConfidenceConfig
                :config-data="configuration_meta"
                :is-open="dialog_customize"
                :loading="mixinIsLoading('config-update')"
                @config-status-change="e => local_config_status = e"
                @update="local_config_update"
                @close="dialog_customize = false"
            >
            </PConfidenceConfig>
        </a-dialog>
    </a-main>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { Validate } from '@/helpers/helper-validations'
import PConfidenceFieldForm from './Partials/PartialConfidenceFieldForm.vue'
import PConfidenceFieldCard from './Partials/PartialConfidenceFieldCard.vue'
import PConfidenceFieldPreview from './Partials/PartialConfidenceFieldPreview.vue'
import PReviewChangesDialog from './Partials/PartialReviewChangesDialog.vue'
import PConfidenceLoader from './Partials/PartialConfidenceLoaderTemplate.vue'
import PConfidenceConfig from './Partials/PartialConfidenceConfig.vue'
import SCancelChanges from '/src/components/Shared/SharedCancelChanges'
import mixinGetPreference from '@/mixins/mixin-get-preference'
import mixinLoading from '@/mixins/mixin-module-loading-setup'

export default {
    mixins: [mixinGetPreference, mixinLoading],

    components: { PConfidenceFieldForm, PConfidenceFieldCard, PConfidenceFieldPreview, PReviewChangesDialog, SCancelChanges, PConfidenceLoader, PConfidenceConfig },

    data () {
        return {
            //Boolean
            page_loading: true,
            local_loading: false,
            onDrag: false,
            preview_mode: false,
            unsaved_changes: false,
            delete_process: false,
            dialog_confidence_field_form: false,
            dialog_review_changes: false,
            dialog_confirm_delete: false,
            hide_add_field: false,
            sectionDrag: false,
            dialog_leave_page_alert: false,
            dialog_cancel_changes: false,
            preview_mode: false,
            reorder_changes: false,
            dialog_customize: false,

            //Variables
            currentEditId: null,
            current_remove_section: null,
            untitled_section: 1,
            current_section: null,
            section_hover_timer: null,
            local_form_mode: null,
            field_counter: 0,
            field_hover_timer: null,
            dragItem: null,
            to_page_route: null,
            current_append_section : null,
            total_score: 0,
            local_config_status: 'no-change',
            confidenceScoreHelpLink: 'https://help.success.app/en/articles/8523418-how-confidence-score-works',

            //Object
            response_title: {},
            local_field_item: { label: null, name: null, description: null, width: '50%', options: [], is_active: 1, minimum_value: 0, maximum_value: null, max_score: null, weight: null },
            backup_field_item: {},
            local_field_type: {},
            local_confidence_field_item: {},
            open_range_score_item: { minimum_value: 0, maximum_value: 100 },
            meta_filters: {
                'filter[type]': 'confidence_score',
                'fields[metas]': 'id,type,status,selection_type,percentage,color',
                'sort': 'order',
            },
            confidence_score_params: {
                'include': 'fields',
                'confidence_score_config_access': 1
            },

            //Arrays
            local_confidence_section_list: [],
            section_more_options: [
                { label: 'Rename', value: 'rename'},
                { label: 'Move up', value:'moveUp' },
                { label:'Move down', value:'moveDown' },
            ],
            backup_list: [],
            local_preview_list: [],
            local_field_type_list: [
                { type: 'score', label: 'Score', icon: 'pin' },
                { type: 'dropdown', label: 'Selection (Single)', icon: 'arrow_drop_down_circle' },
                { type: 'multi_select', label: 'Selection (Multiple)', icon: 'check_box' },
            ],
            dropdown: [],
            all_score_list: [],
            modified_field_list: [],
            layout_modifications_log_list: [],
            existing_fields: [],
            existing_sections: [],
            field_modified: [],
            section_modified: [],
            configuration_meta: []
        }
    },

    mounted () {
        this.local_index()
        window.addEventListener('beforeunload', this.local_handler_close)
    },

    beforeDestroy () {
        window.removeEventListener('beforeunload', this.local_handler_close)
    },

    beforeRouteLeave (toPage, fromPage, next) {
        if (!this.unsaved_changes && !this.reorder_changes) return next()
        next(false)
        this.dialog_leave_page_alert = true
        this.to_page_route = next
    },

    watch: {
        layout_modifications_log_list (val) {
            this.unsaved_changes = !!(val && val.length)
        },
    },

    computed: {
        local_drag_options () {
            return {
                animation: 400,
                group: "field",
                disabled: false,
                ghostClass: "c-ghost",
                dropzone: '.list-dropzone',
                dropzoneOverClass: 'non-droppable'
            }
        },

        local_is_lgdown () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        local_config_update_status () {
            return (this.confidence_layout_meta && this.confidence_layout_meta.config_in_progress === 1)
        },

        local_changes_unsaved () {
            return this.unsaved_changes || this.reorder_changes
        },

        ...mapState('ConfidenceScore', {
            confidence_layout_list: 'list',
            confidence_layout_meta: 'meta',
            confidence_layout_response: 'response'
        }),

        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key'
        }),

        ...mapState('Meta', {
            meta_list: 'list',
            meta_response: 'response'
        }),
    },

    methods: {
        async local_index () {
            if (!this.$can('confidence_score.index') || !this.local_has_preference()) {
                this.$router.replace({name: 'errors-unauthorized'})
                return
            }
            await this.local_fetch_preferences()
            await this.confidence_layout_index(this.confidence_score_params)
            this.local_load_configuration_meta()
            this.backup_list = _.cloneDeep(this.confidence_layout_list)
            this.local_confidence_section_list = _.cloneDeep(this.confidence_layout_list)
            this.local_set_insertable_fields()
            this.local_set_existing_params()
            this.page_loading = false
        },

        async local_load_configuration_meta () {
            await this.meta_clear()
            await this.meta_index({...this.meta_filters})
            this.configuration_meta = _.cloneDeep(this.meta_list)
        },

        local_has_preference () {
            return this.preference_get_property_by_key('organization.confidence_score', 'value') === 'Yes'
        },

        async local_fetch_preferences () {
            await this.preference_index()
        },

        local_set_existing_params () {
            this.existing_fields = this.backup_list.flatMap(section => section.fields)
            this.existing_sections = this.backup_list.flatMap(section => section)
        },

        local_set_insertable_fields () {
            this.field_counter = 0
            let sectionIndex = 0
            this.local_confidence_section_list.forEach((section, index) => {
                if (index !== sectionIndex) this.field_counter = 0
                sectionIndex = index
                section.fields.forEach((item, fieldIndex) => {
                    item.insertable = false
                    item.solo_field = false
                    if (item.width === '50%') {
                        ++this.field_counter
                        if(this.field_counter % 2 !== 0 && (fieldIndex+1 < section.fields.length) && (section.fields[fieldIndex+1].width === '100%')) {
                            item.insertable = true
                            item.solo_field = true
                            this.field_counter = 0
                        } else if (this.field_counter % 2 === 0) {
                            item.insertable = true
                            item.solo_field = false
                            this.field_counter = 0
                        }
                    }
                    if (item.width === '100%') {
                        item.insertable = true
                        item.solo_field = false
                        this.field_counter = 0
                    }
                })
            })
        },

        local_display_hover_element (field, index, sectionIndex) {
            this.local_remove_hover_element_on_select()
            if (index === this.local_confidence_section_list[sectionIndex].fields.length-1) return
            clearTimeout(this.field_hover_timer)
            this.field_hover_timer = setTimeout(() => {
                this.hide_add_field = true
                this.local_confidence_section_list[sectionIndex].fields.splice(index+1, 0, {id: 'dummy_id', name: 'field_adder', width: '100%'})
            }, 600);
        },

        local_remove_hover_element_on_select () {
            this.local_confidence_section_list.forEach(section => {
                section.fields.forEach((field, index) => {
                    if (field.id === 'dummy_id') section.fields.splice(index, 1)
                })
            })
            this.hide_add_field = false
        },

        local_remove_hover_element (field, index, sectionIndex) {
            clearTimeout(this.field_hover_timer)
            this.dropdown['dummy_id'] = false
            this.dropdown = []
            const dummy_index = this.local_confidence_section_list[sectionIndex].fields.findIndex(field => field.id === 'dummy_id')
            if (dummy_index === index || dummy_index === -1) return
            this.local_confidence_section_list[sectionIndex].fields.splice(dummy_index, 1)
            this.hide_add_field = true
        },

        local_check_empty_sections (sectionList) {
            const empty_sections = sectionList.filter(section => {
                return (section.fields && !section.fields.length)
            })
            return empty_sections && empty_sections.length
        },

        local_title_edit_focus (section) {
            if (!this.$can('confidence_score.update')) return
            this.current_edit_section = _.cloneDeep(section)

            setTimeout(() => {
                this.local_on_enter('title_input' + section.id)
                this.currentEditId = section.id
            }, 100);
        },

        local_on_enter (input) {
            setTimeout(() => {
                this.$refs[input][0].focus()
            }, 200)
        },

        local_reorder_field (event) {
            const toSectionId = event.to.getAttribute('data-section-id')
            const fieldId = event.item.getAttribute('data-field-id')
            this.local_set_field_section({sectionId: toSectionId, fieldId})
            this.local_set_insertable_fields()
            this.local_trigger_sections_modified()
            this.local_group_log_list('drag', fieldId, toSectionId)
        },

        local_set_field_section (props) {
            const { sectionId, fieldId } = props
            const sectionIndex = _.findIndex(this.local_confidence_section_list, {id: sectionId})
            const fieldIndex = _.findIndex(this.local_confidence_section_list[sectionIndex].fields, {id: fieldId})
            this.local_confidence_section_list[sectionIndex].fields[fieldIndex].confidence_score_section_id = sectionId
        },

        local_focus_title_input (section, type) {
            this.inline_update_action = type
            if (this.inline_update_action === 'enter') return this.currentEditId = null
            const savedSectionIndex = _.findIndex(this.backup_list, {id: section.id})

            if (savedSectionIndex !== -1) {
                if (section.name && (section.name.trim() === this.backup_list[savedSectionIndex].name)) {
                    this.local_update_log('modified', { arg: { module: 'section', ...section }})
                    return this.currentEditId = null
                }
            }
            this.local_validate_section_title (section)
            if (this.response_title && this.response_title.id) {
                this.local_revert_section_title(section)
                this.$notify('error', this.response_title.text)
            } else this.local_update_log('modified', { arg: { module: 'section', ...section }})

            setTimeout(() => {
                this.response_title = {}
                this.currentEditId = null
                this.current_edit_section = {}
                this.inline_update_action = null
            }, 100);
        },

        local_inline_update (section, type) {
            if (this.inline_update_action === 'blur' && type !== 'enter') return
            if (type === 'clear') return this.local_revert_section_title(section)
            this.local_focus_title_input(section, type)
        },

        local_validate_section_title (section) {
            const { message } = new Validate(section.name, { silent: true }).length(1, 255).run()
            if (message) this.response_title = { id: section.id, text: message, status: 'error'}
            else this.response_title = {}
        },

        local_revert_section_title (section) {
            const backup_index = _.findIndex(this.backup_list, {id: section.id})
            const index = _.findIndex(this.local_confidence_section_list, {id: section.id})
            this.local_confidence_section_list[index].name = backup_index !== -1 ? this.backup_list[backup_index].name : this.current_edit_section.name
            this.local_update_log('reverted', { arg: { module: 'section', ...section }})
        },

        local_modify_section (section, section_index, action) {
            if (action === 'deleteFields') this.local_confidence_section_list[section_index].sublist = []
            if (action === 'rename') this.local_title_edit_focus(section)
            if (action === 'moveUp' && section_index > 0) {
                this.local_confidence_section_list.splice(section_index - 1, 0, section)
                this.local_confidence_section_list.splice(section_index + 1, 1)
            }
            if (action === 'moveDown' && section_index < this.local_confidence_section_list.length-1) {
                this.local_confidence_section_list.splice(section_index + 2, 0, section)
                this.local_confidence_section_list.splice(section_index , 1)
            }
            this.local_trigger_sections_modified()
        },

        local_select_delete_section (section) {
            this.current_remove_section = section.id
            this.dialog_confirm_delete = true
        },

        local_cancel_delete () {
            this.current_remove_section = null
            this.dialog_confirm_delete = false
        },

        local_create_section (index) {
            const section = { name: 'Untitled section ' + this.untitled_section++,  fields: [], is_active: 1 }
            this.$appendId(section)
            section.new = true
            if (index === 0 || index) this.local_confidence_section_list.splice(index + 1, 0, section)
            else this.local_confidence_section_list.push(section)
            this.local_update_log('created', { arg: { module: 'section', ...section }})
        },

        local_delete_section () {
            const index = _.findIndex(this.local_confidence_section_list, {id: this.current_remove_section})
            const section = this.local_confidence_section_list[index]
            this.local_confidence_section_list.splice(index, 1)
            this.local_update_log('deleted', { arg: { module: 'section', ...section }})
            this.current_remove_section = null
            this.dialog_confirm_delete = false
            this.local_set_total_score()
        },

        local_add_field (action, field, section, fieldIndex = null) {
            this.insert_index = action === 'insert' ? fieldIndex : null
            this.local_form_mode = 'create'
            this.local_field_insert_action = action
            this.current_append_section = section
            this.local_confidence_field_item = _.cloneDeep(this.local_field_item)
            this.$appendId(this.local_confidence_field_item)

            this.local_field_type = field.type

            if (field.type !== 'score') {
                const optionItem = this.$appendId({ color: this.$randomColor(), label: null, value: null })
                this.local_confidence_field_item.options.push(optionItem)
            }
            if (field.type === 'score') Object.assign(this.local_confidence_field_item, {...this.open_range_score_item})
            if (field.type === 'multi_select') this.local_confidence_field_item.width = '100%'

            this.dialog_confidence_field_form = true
        },

        local_edit_field (item, section_index) {
            this.local_form_mode = 'edit'
            const savedFields = this.backup_list.flatMap(section => section.fields)
            this.local_confidence_field_item = _.cloneDeep(item)
            this.backup_field_item = savedFields.find(field => field.id === item.id)
            this.local_field_type = item.type
            this.dialog_confidence_field_form = true
        },

        async local_create_field (item) {
            this.dialog_confidence_field_form = false
            const field_item = _.cloneDeep(item)
            field_item.type = this.local_field_type
            field_item.new = true
            field_item.confidence_score_section_id = this.current_append_section.id
            this.$appendId(field_item)

            this.dropdown[item.id] = false
            this.local_remove_hover_element_on_select()
            this.dropdown['dummy_id'] = false


            const sectionIndex = _.findIndex(this.local_confidence_section_list, {id: this.current_append_section.id})
            if (this.local_field_insert_action === 'append') this.local_confidence_section_list[sectionIndex].fields.push(field_item)
            else this.local_confidence_section_list[sectionIndex].fields.splice(this.insert_index, 0, field_item)

            this.local_calculate_field_max_score(field_item)
            this.local_set_total_score()


            const params = _.cloneDeep(field_item)
            params.confidence_score_section = this.current_append_section

            this.local_set_insertable_fields()

            this.local_update_log('created', { arg: {...params, module: 'field' } })
        },

        async local_update_field (params) {
            this.dialog_confidence_field_form = false
            const {arg, modified_item, color_modified_option_ids} = params
            this.modified_field_list.push(modified_item)

            const sectionIndex = _.findIndex(this.local_confidence_section_list, {id: arg.confidence_score_section_id})
            const fieldIndex = _.findIndex(this.local_confidence_section_list[sectionIndex].fields, {id: arg.id})
            const section = this.local_confidence_section_list[sectionIndex]
            Object.assign(this.local_confidence_section_list[sectionIndex].fields[fieldIndex], arg)

            arg.confidence_score_section = section
            if (arg.new) this.local_update_log('modified', { arg: {...arg, module: 'field'} })
            else if (this.local_check_modified_item(modified_item)) this.local_update_log('modified', { arg: {...arg, module: 'field'}, toVal: modified_item })
            else this.local_remove_update_log(modified_item)

            this.reorder_changes = (!!_.size(color_modified_option_ids))

            this.local_calculate_field_max_score(arg)
            this.local_set_total_score()
            this.local_set_insertable_fields()
        },

        local_check_modified_item (item) {
            const key = 'description'
            if (item.type === 'score') return !!(item.label || item.name || (key in item) || item.maximum_value || item.minimum_value || item.width)

            return !!(item.label || item.name || (key in item) || (item.options && item.options.length) || this.local_check_option_modified(item) || item.minimum_value || item.maximum_value || item.width)
        },

        local_check_option_modified (item) {
            const modified_option = item.options.filter(option => {
                const index = this.backup_field_item.options.findIndex(item => item.id === option.id)
                if (index !== -1) return (this.backup_field_item.options[index].label !== option.label) || (this.backup_field_item.options[index].value !== option.value)
            })
            return !!(modified_option && modified_option.length)
        },

        local_remove_update_log (item) {
            const logIndex = this.layout_modifications_log_list.findIndex(log => log.arg.id === item.id)
            if (!this.local_check_modified_item(item) && logIndex !== -1) this.layout_modifications_log_list.splice(logIndex, 1)
        },

        local_delete_field (item) {
            const sectionIndex = _.findIndex(this.local_confidence_section_list, {id: item.confidence_score_section_id})
            const fieldIndex = _.findIndex(this.local_confidence_section_list[sectionIndex].fields, {id: item.id})
            this.local_confidence_section_list[sectionIndex].fields.splice(fieldIndex, 1)
            this.local_set_total_score()

            this.local_set_insertable_fields()
            const section = this.local_confidence_section_list[sectionIndex]
            const params = _.cloneDeep(item)
            params.confidence_score_section = section

            this.local_update_log('deleted', { arg: {...params, module: 'field'} })
        },

        local_update_log (action, data) {
            if (action === 'modified' && data.arg.module === 'section') return this.local_set_section_modified_params(data)
            if (action === 'modified' && data.arg.module === 'field') return this.local_set_field_modified_params(data)
            if (action === 'reverted' && data.arg.module === 'section') return this.local_delete_section_modified_log(data)
            if (action === 'deleted' && data.arg.module === 'section') return this.local_set_section_deleted_params(data)
            if (action === 'deleted' && data.arg.module === 'field') return this.local_set_field_deleted_params(data)
            this.layout_modifications_log_list.push({action, arg: _.cloneDeep(data.arg)})
        },

        local_set_section_modified_params (data) {
            const logIndex = this.layout_modifications_log_list.findIndex(log => log.arg.id === data.arg.id)
            if (data.arg && data.arg.new) {
                if (logIndex !== -1) this.layout_modifications_log_list[logIndex].arg.name = data.arg.name
                return
            }
            const oldVal = this.backup_list.find(item => item.id === data.arg.id)
            const newVal = data.arg
            const action = 'modified'

            // if Section title is reverted manually by input
            if (logIndex !== -1 ) {
                if (oldVal.name.trim() === newVal.name.trim()) {
                    return this.layout_modifications_log_list.splice(logIndex, 1)
                }
            }
            if (logIndex !== -1) return this.layout_modifications_log_list[logIndex].toVal.name = data.arg.name
            if (oldVal.name.trim() !== newVal.name.trim()) this.layout_modifications_log_list.push({action, arg: {...oldVal, module: data.arg.module}, toVal: newVal})
        },

        local_delete_section_modified_log (data) {
            const logIndex = this.layout_modifications_log_list.findIndex(log => (log.arg.id === data.arg.id) && !data.arg.new)
            if (logIndex !== -1) this.layout_modifications_log_list.splice(logIndex, 1)
        },

        local_set_field_modified_params (data) {
            const logIndex = this.layout_modifications_log_list.findIndex(log => log.arg.id === data.arg.id)
            // Modified new item
            if (data.arg && data.arg.new) {
                if (logIndex !== -1) return this.layout_modifications_log_list[logIndex].arg = data.arg

                // If new item is grouped in section and modified
                const sectionIndex = this.layout_modifications_log_list.findIndex(log => log.arg.id === data.arg.confidence_score_section_id)
                if (sectionIndex !== -1) {
                    const fieldIndex = this.layout_modifications_log_list[sectionIndex].arg.fields.findIndex(field => field.arg.id === data.arg.id)
                    if (fieldIndex !== -1) this.layout_modifications_log_list[sectionIndex].arg.fields[fieldIndex].arg = _.cloneDeep(data.arg)
                }
                return
            }

            // Modified existing item
            const savedFieldList = this.backup_list.flatMap(section => {
                return section.fields.map(field => field)
            })
            const oldVal = savedFieldList.find(field => field.id === data.arg.id)
            oldVal.confidence_score_section = data.arg.confidence_score_section
            const newVal = data.toVal
            const action = 'modified'
            if (logIndex !== -1) return this.layout_modifications_log_list.splice(logIndex, 1, {action, arg: {...oldVal, module: data.arg.module}, toVal: newVal})
            this.layout_modifications_log_list.push({action, arg: {...oldVal, module: data.arg.module}, toVal: newVal})
        },

        local_set_section_deleted_params (data) {
            const logIndex = this.layout_modifications_log_list.findIndex(log => log.arg.id === data.arg.id)
            // 1. New section deleted
            if (data.arg.new) {
                // New section with no fields
                if (data.arg && !data.arg.fields.length && logIndex !== -1) return this.layout_modifications_log_list.splice(logIndex, 1)

                //New section deleted with old fields and new fields
                if (data.arg && data.arg.fields.length) {
                    if (logIndex !== -1) {
                        this.layout_modifications_log_list.splice(logIndex, 1)
                    }

                    data.arg.fields.forEach(field => {
                        if (!field.new) {
                            const savedFieldItem = this.existing_fields.find(item => item.id === field.id)
                            const savedFieldSection = this.existing_sections.find(item => item.id === savedFieldItem.confidence_score_section_id)
                            field.confidence_score_section = savedFieldSection
                            field.confidence_score_section_id = savedFieldSection.id
                            this.layout_modifications_log_list.push({action: 'deleted', arg: {...field, module: 'field'} })
                        }
                    })
                    return
                }
            }
            if (!data.arg.new) {
                // If Modified section is deleted, revert back to the saved form
                const savedSection = this.backup_list.find(section => section.id === data.arg.id)
                data.arg.name = savedSection.name

                if (data.arg && data.arg.fields.length) {
                    // Check for new fields and permanently delete them
                    data.arg.fields.forEach(field => {
                        const logIndex = this.layout_modifications_log_list.findIndex(log => log.arg.id === field.id)
                        if (logIndex !== -1) this.layout_modifications_log_list.splice(logIndex, 1)
                    })

                    // Group the old fields along with section
                    const deleted_fields = data.arg.fields.filter(field => !field.new)

                    // Group the deleted field into the section
                    const loggedDeletedFieldList = this.layout_modifications_log_list.filter(log => log.arg.confidence_score_section_id === data.arg.id)
                    loggedDeletedFieldList.forEach(item => {
                        const loggedDeletedFieldIndex = this.layout_modifications_log_list.findIndex(log => log.arg.confidence_score_section_id === data.arg.id)
                        const deletedFieldLog = this.layout_modifications_log_list.find(log => log.arg.confidence_score_section_id === data.arg.id)

                        if (loggedDeletedFieldIndex !== -1) {
                            this.layout_modifications_log_list.splice(loggedDeletedFieldIndex, 1)
                            deleted_fields.splice(deletedFieldLog.arg.field_delete_index, 0, deletedFieldLog.arg)
                        }
                    })

                    data.arg.fields = _.cloneDeep(deleted_fields)

                    const deleteIndex = this.existing_sections.findIndex(item => item.id === data.arg.id)
                    data.arg.section_delete_index = deleteIndex

                    this.layout_modifications_log_list.push({action: 'deleted', arg: {...data.arg} })
                    return
                }
            }
            // 3. Old section no fields
            const deleteIndex = this.existing_sections.findIndex(item => item.id === data.arg.id)
            data.arg.section_delete_index = deleteIndex

            this.layout_modifications_log_list.push({action: 'deleted', arg: {...data.arg} })
        },

        local_set_field_deleted_params (data) {
            const logIndex = this.layout_modifications_log_list.findIndex(log => log.arg.id === data.arg.id)
            if (data.arg.new) {
                // 1. If new field remove the created log
                if (logIndex !== -1) return this.layout_modifications_log_list.splice(logIndex, 1)
            }

            // 3. If modified existing field is deleted, show deleted log with saved data
            if (!data.arg.new) {
                if (logIndex !== -1) {
                    this.layout_modifications_log_list.splice(logIndex, 1)
                    const existing_field_data = this.existing_fields.find(field => field.id === data.arg.id)
                    return this.layout_modifications_log_list.push({action: 'deleted', arg: {...existing_field_data, module: 'field'} })
                }
            }

            // 2. Existing field add deleted log
            const sectionIndex = this.backup_list.findIndex(item => item.id === data.arg.confidence_score_section_id)
            const deleteIndex = this.backup_list[sectionIndex].fields.findIndex(field => field.id === data.arg.id)
            data.arg.field_delete_index = deleteIndex
            this.layout_modifications_log_list.push({action: 'deleted', arg: {...data.arg} })
        },

        local_review_changes () {
            this.local_remove_hover_element_on_select()
            this.local_group_log_list()
            this.layout_modifications_log_list.reverse()
            this.dialog_review_changes = true
        },

        local_group_log_list (action = null, fieldId, sectionId) {
            const fields = this.layout_modifications_log_list.filter(log => log.arg.module === 'field' && log.action === 'created')
            if (action === 'drag') {
                this.layout_modifications_log_list.forEach((log, index) => {
                    // If new field is dropped from old to new section -> Group into new section
                    const logIndex = this.layout_modifications_log_list.findIndex(log => log.arg.id === fieldId)
                    if (log.arg.id === fieldId) return this.layout_modifications_log_list[logIndex].arg.confidence_score_section_id = sectionId
                    if (log.arg.module === 'section' && log.action === 'created') {
                        const fieldIndex = log.arg.fields.findIndex(field => field.arg.id === fieldId)
                        if (fieldIndex !== -1) {
                            const field = log.arg.fields[fieldIndex]
                            field.arg.confidence_score_section_id = sectionId
                            this.layout_modifications_log_list.push({action: 'created', arg: {...field.arg} })
                            this.layout_modifications_log_list[index].arg.fields.splice(fieldIndex, 1)
                        }
                    }
                })

            }
            this.layout_modifications_log_list.forEach((log, index) => {
                const match = fields.filter(field => {
                    return field.arg.confidence_score_section_id === log.arg.id
                })
                if (log.action === 'created' && log.arg.module === 'section' && (match && match.length)) {
                    this.layout_modifications_log_list[index].arg.fields.push(...match)
                    match.forEach(item => {
                        const logIndex = this.layout_modifications_log_list.findIndex(log => log.arg.id === item.arg.id)
                        if (logIndex !== -1) this.layout_modifications_log_list.splice(logIndex, 1)
                    })
                }
            })
        },

        local_calculate_field_max_score (field) {
            const scores = field.options.map(item => item.value)
            let max_score = 0
            if (field.type === 'dropdown') {
                max_score = parseInt(scores[0])
                scores.forEach(score => {
                    if (parseInt(score) > max_score) {
                        max_score = score
                    }
                })
            }

            if (field.type === 'score') {
                max_score = field.maximum_value
            }

            if (field.type === 'multi_select') {
                scores.forEach(score => {
                    max_score = parseInt(max_score) + parseInt(score)
                })
            }

            this.local_set_field_max_score(field, max_score)
        },

        local_set_field_max_score (field, max_score) {
            const section_index = _.findIndex(this.local_confidence_section_list, {id: field.confidence_score_section_id})
            const index = _.findIndex(this.local_confidence_section_list[section_index].fields, {id: field.id})
            if (index !== -1) this.local_confidence_section_list[section_index].fields[index].maximum_value = parseInt(max_score)
        },

        local_set_field_weight () {
            const weight_list = []
            this.all_score_list.forEach(item => {
                let weight
                weight = (parseInt(item.maximum_value)*100)/parseInt(this.total_score)
                weight_list.push({ id: item.id, weight: weight.toFixed(2) })
            })

            this.local_confidence_section_list.forEach((section, index) => {
                this.local_confidence_section_list[index].fields.forEach((field, fieldIndex) => {
                    const item_index = _.findIndex(weight_list, {id: field.id})
                    if (item_index !== -1) field.weight = weight_list[item_index].weight
                })
            })
        },

        local_set_total_score () {
            this.local_remove_hover_element_on_select()
            this.total_score = 0
            this.all_score_list = []
            this.all_score_list = this.local_confidence_section_list.flatMap(section => {
                return section.fields.map(({id, maximum_value}) => ({id, maximum_value}))
            })
            let scoreVal = 0
            this.all_score_list.forEach(item => {
                scoreVal = (parseInt(scoreVal) + parseInt(item.maximum_value))
            })
            this.total_score = scoreVal
            this.local_set_field_weight()
        },

        local_switch_preview_mode () {
            const preview_list = this.local_confidence_section_list.filter(section => {
                return !!(section.fields && section.fields.length)
            })
            this.local_preview_list = _.cloneDeep(preview_list)
            this.local_remove_hover_element_on_select()
            this.local_set_custom_field_value()
            this.local_set_section_total_score()
            this.local_set_total_score()
            this.preview_mode = true
        },

        local_update_field_value (props) {
            const { item, section_id, value, action } = props
            const list = _.cloneDeep(this.local_preview_list)
            const index = _.findIndex(list, {id: section_id})
            const fieldIndex = _.findIndex(list[index].fields, {id: item.id})

            if (action === 'destroy') {
                const itemIndex = _.findIndex(item.value, {id: value})
                list[index].fields[fieldIndex].value.splice(itemIndex, 1)
            } else {
                if (item.type === 'multi_select') list[index].fields[fieldIndex].value.push(value)
                else list[index].fields[fieldIndex].value = value
            }
            this.local_preview_list = list
            this.local_set_section_score(section_id, index)
        },

        local_set_section_score (section_id, section_index) {
            let current_section_score = 0
            const list = _.cloneDeep(this.local_preview_list)
            const section = list.find(section => section.id === section_id)
            section.current_score = 0

            section.fields.forEach(field => {
                if (field.type === 'multi_select' && field.value && field.value.length) {
                    let value_total = 0
                    field.value.forEach(value => {
                        value_total = parseInt(value_total) + parseInt(value.value)
                    })
                    current_section_score = parseInt(current_section_score) + parseInt(value_total)
                }

                const field_score = field.type === 'dropdown' && field.value && field.value.value ? parseInt(field.value.value) : parseInt(field.value)
                if (field && field_score > 0) {
                    current_section_score = parseInt(current_section_score) + field_score
                }
            })

            list[section_index].current_score = current_section_score
            this.local_preview_list = list
        },

        local_set_section_total_score () {
            let section_total = 0
            this.local_preview_list.forEach(section => {
                section.fields.forEach((field, index) => {
                    section_total = parseInt(section_total) + parseInt(field.maximum_value)
                    section.total_score = section_total
                    if (index === section.fields.length-1) return section_total = 0
                })
            })
        },

        local_set_custom_field_value () {
            this.local_preview_list.forEach(item => {
                item.fields.forEach(item => {
                    const value = item.type === 'multi_select' ? [] : ''
                    Object.assign(item, {value: value})
                })
            })
        },

        async local_store_layout () {
            this.mixinSetLoading('store')
            this.local_set_store_params()
            await this.confidence_layout_store(this.local_confidence_section_list)

            if (this.confidence_layout_response && this.confidence_layout_response.status === 'success') {
                await this.confidence_layout_index({'include': 'fields'})

                this.local_confidence_section_list = _.cloneDeep(this.confidence_layout_list)
                this.backup_list = _.cloneDeep(this.confidence_layout_list)
                this.local_set_insertable_fields()
                this.local_set_existing_params()
                this.local_reset_variables()
                this.$notify('success', 'Fields saved successfully!')
            } else {
                if (_.has(this.confidence_layout_response , 'text.errors.config_in_progress')) this.$notify('error', this.confidence_layout_response.text.errors.config_in_progress[0])
                else this.$notify('error', 'An error occurred while saving the changes, please reload the page.')
            }
            this.dialog_review_changes = false
            this.mixinResetLoading('store')
        },

        local_set_store_params () {
            this.local_confidence_section_list.forEach(section => {
                section.fields.forEach(field => {
                    delete field.confidence_score_section
                })
            })
        },

        local_trigger_sections_modified () {
            if ((this.backup_list && this.backup_list.length) !== (this.local_confidence_section_list && this.local_confidence_section_list.length)) return this.reorder_changes = true

            this.local_check_sections_modified()
            this.local_check_fields_modified()
            this.reorder_changes = !!(this.section_modified && this.section_modified.length) || !!(this.field_modified && this.field_modified.length)
        },

        local_check_sections_modified () {
            this.section_modified = this.local_confidence_section_list.filter((section, sectionIndex) => {
                if (this.backup_list.length === this.local_confidence_section_list.length) {
                    return (this.backup_list[sectionIndex].id !== this.local_confidence_section_list[sectionIndex].id) || (this.backup_list[sectionIndex].fields.length !== this.local_confidence_section_list[sectionIndex].fields.length)
                }
            })
        },

        local_check_fields_modified () {
            this.field_modified = []
            const current_list = _.cloneDeep(this.local_confidence_section_list)
            current_list.forEach((section, sectionIndex) => {
                if (this.backup_list[sectionIndex].fields.length === (current_list[sectionIndex].fields.length)) {
                    current_list[sectionIndex].fields.forEach((field, index) => {
                        current_list[sectionIndex].fields[index].modified = (this.backup_list[sectionIndex].fields[index].id !== current_list[sectionIndex].fields[index].id)
                    })
                }
            })

            current_list.forEach((section, sectionIndex) => {
                current_list[sectionIndex].fields.forEach((field, index) => {
                    if (current_list[sectionIndex].fields[index].modified) this.field_modified.push(field)
                })
            })
        },

        local_revert_item (log) {
            if (log.module === 'field') this.local_revert_field_data(log.item)
            else this.local_revert_section_data(log.item)
            this.local_set_insertable_fields()
            if (!(_.size(this.layout_modifications_log_list))) this.dialog_review_changes = false
        },

        local_exit_review () {
            this.dialog_review_changes = false
            this.layout_modifications_log_list.reverse()
        },

        local_revert_section_data (item) {
            const { arg, action } = item
            const logIndex = this.layout_modifications_log_list.findIndex(log => log.arg.id === arg.id)
            if (logIndex !== -1) this.layout_modifications_log_list.splice(logIndex, 1)
            // 1. Empty or existing section with fields is deleted and reverted
            if (action === 'deleted') {
                this.local_confidence_section_list.splice(arg.section_delete_index, 0, arg)
            }
            if (action === 'modified') {
                const sectionIndex = _.findIndex(this.local_confidence_section_list, {id: arg.id})
                this.local_confidence_section_list[sectionIndex].name = arg.name
            }
            this.local_set_total_score()
        },

        local_revert_field_data (item) {
            const { arg, action } = item
            const logIndex = this.layout_modifications_log_list.findIndex(log => log.arg.id === arg.id)
            const savedItem = this.existing_fields.find(item => item.id === arg.id)
            const sectionIndex = _.findIndex(this.local_confidence_section_list, {id: arg.confidence_score_section_id})
            if (logIndex !== -1) this.layout_modifications_log_list.splice(logIndex, 1)

            if (action === 'deleted') {
                // 1. Single Existing field in existing section is deleted and reverted
                if (sectionIndex !== -1) this.local_confidence_section_list[sectionIndex].fields.splice(arg.field_delete_index, 0, arg)
                // 2. If existing field is moved to new section and deleted along with new section
                // - On revert move to old existing section
                if (sectionIndex === -1) {
                    const backupSectionIndex = _.findIndex(this.backup_list, {id: savedItem.confidence_score_section_id})
                    const backupFieldIndex = _.findIndex(this.backup_list[backupSectionIndex].fields, {id: savedItem.confidence_score_section_id})
                    this.local_confidence_section_list[backupSectionIndex].fields.splice(backupFieldIndex, 0, savedItem)
                }
            }

            if (action === 'modified') {
                const fieldIndex = _.findIndex(this.local_confidence_section_list[sectionIndex].fields, {id: arg.id})
                this.local_confidence_section_list[sectionIndex].fields[fieldIndex] = _.cloneDeep(savedItem)
            }

            this.local_set_total_score()
        },

        local_handler_close (e) {
            if (this.unsaved_changes || this.reorder_changes) {
                e.returnValue = 'Are you sure to close the tab?';
            }
        },

        local_cancel_changes () {
            this.local_confidence_section_list = _.cloneDeep(this.backup_list)
            this.dialog_cancel_changes = false
            this.local_reset_variables()
            this.local_set_insertable_fields()
        },

        local_reset_variables () {
            this.unsaved_changes = false
            this.reorder_changes = false
            this.response_title = {}
            this.layout_modifications_log_list = []
            this.field_modified = []
            this.section_modified = []
            this.untitled_section = 1
            this.current_edit_section = {}
        },

        local_leave_page () {
            this.unsaved_changes = false
            this.reorder_changes = false
            this.to_page_route()
        },

        async local_config_update (params) {
            this.mixinSetLoading('config-update')
            for (const item of params) {
                await this.meta_update({...item})
            }

            if (this.meta_response && this.meta_response.status === 'success') {
                this.dialog_customize = false
                this.$notify('success', 'Progress Bar configuration saved successfully!')
                this.mixinResetLoading('config-update')
                this.configuration_meta = []
                this.local_load_configuration_meta()
            }
        },

        ...mapActions('ConfidenceScore', {
            confidence_layout_index: 'index',
            confidence_layout_store: 'store',
        }),

        ...mapActions('Preference', {
            preference_index: 'index',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_update: 'update',
            meta_clear: 'clear'
        }),
    }
}
</script>

<style scoped>
    .c-section-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .c-ghost {
        background: #c8ebfb;
        opacity: 0.5;
    }
    .c-field-card {
        width: 100%;
        background-color: #FAFAFA !important;
        border: 1px solid #EEEEEE !important;
    }
    .c-field-card__half {
        width: 49% !important;
    }
    .c-field-card__half--preview {
        width: 50% !important;
    }
    .c-field-card__full{
        width: 100% !important;
    }
    .c-save-btn.v-btn.v-btn--disabled {
        opacity: 0.5;
        background-color: #3949AB !important;
        color: #fff !important;
    }
    .c-add-section__bar {
        visibility: hidden !important;
        z-index: 0;
    }
    .c-add-section:hover .c-add-section__bar {
        visibility: visible !important;
    }
    .c-add-field-sm {
        top: 100px;
        height: 16px;
        left: -294px;
    }
    .c-add-field-lg {
        top: 100px;
        height: 16px;
    }
    .c-add-field-solo {
        top: 100px;
        height: 16px;
        left: 320px
    }
</style>

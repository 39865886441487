const api_endpoint = 'subscription-plans'

export default {
    namespaced: true,

    state: {
        item: {},
        reportItem: [],
        dayStats: [],
        cycles: [],
        response: [],
        defaults: {
            item: {},
            reportItem: [],
            dayStats: [],
            cycles: [],
            response: [],
        },
    },

    mutations: {
        UPDATE_REPORT_ITEM (state, item) {
            state.reportItem = item
        },

        UPDATE_DAY_STATS (state, list) {
            state.dayStats = list
        },

        UPDATE_ITEM (state, item) {
            state.item = item
        },

        UPDATE_CYCLES (state, list) {
            state.cycles = list
        },

        THROW_RESPONSE (state, response) {
            state.response = response
        },
    },

    actions: {
        async actionBillingStats ({ dispatch, commit }) {
            dispatch('loader_start')
            try {
                const { data: { data } } = await axios.get(`${api_endpoint}/billing-stats`)
                commit('UPDATE_ITEM', data)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async actionFetchCycles ({ dispatch, commit }) {
            dispatch('loader_start')
            try {
                const { data: { data } } = await axios.get(`${api_endpoint}/cycle/list`)
                commit('UPDATE_CYCLES', data)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async actionFetchUsageStats ({ dispatch, commit }, params) {
            dispatch('loader_start')
            try {
                const { data: { data } } = await axios.get(`${api_endpoint}/usage-stats`, { params })
                commit('UPDATE_DAY_STATS', data)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async actionGetProjects ({ dispatch, commit }, params) {
            dispatch('loader_start')
            try {
                const { data } = await axios.get(`${api_endpoint}/${params.id}/project-details`, { params: { ...params.params } })
                commit('UPDATE_REPORT_ITEM', data)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async actionCancelDowngrade ({ dispatch }) {
            dispatch('loader_start')
            try {
                await axios.patch(`${api_endpoint}/schedule/cancel`)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async throw_success ({ commit }) {
            commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            this.dispatch('Interface/loader_stop')
        },

        async throw_error ({ commit }, error) {
            this.dispatch('Interface/loader_stop')
            commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
        },

        async loader_start () {
            this.dispatch('Interface/loader_start')
        },

        async clear ({ commit }) {
            //commit('UPDATE_LIST', [])
            commit('THROW_RESPONSE', [])
        },

        async actionClearItem ({ commit }) {
            commit('UPDATE_ITEM', {})
            commit('UPDATE_REPORT_ITEM', {})
            commit('THROW_RESPONSE', [])
        },

        async clear_response ({ commit }) {
            commit('THROW_RESPONSE', [])
        },
    }
}

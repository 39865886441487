<template>
    <v-alert v-on="$listeners" v-bind="$attrs">
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
            <!-- <template v-if="scopedSlotName === 'default'">
                <slot></slot>
            </template> -->
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
        <slot></slot>
    </v-alert>
</template>

<script>
import { VAlert } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VAlert },

    data () {
        return {
            ref: null
        }
    },

    mounted () {
        this.ref = this.$el
    },
}
</script>

<template>
    <a-container grid-list-xl container--fluid class="pa-0 u-relative">
        <PartialLoaderTemplate v-if="mixinIsLoading('fields')"></PartialLoaderTemplate>
        <a-responsive v-else ref="refContainer">
            <div class="u-flex align-center justify-space-between mb-6">
                <div class="font-weight-medium grey--text text--darken-3" style="fontSize: 20px">
                    Confidence Score
                </div>
                <div class="u-flex">
                    <div class="u-border u-rounded-corners">
                        <a-btn color="white" :disabled="mixinIsLoading('store')" depressed @click="local_show_score_summary()">
                            <a-icon size="16" outlined>summarize</a-icon>
                            <span class="ml-1 grey--text text--darken-3 font-weight-medium">Summary</span>
                        </a-btn>
                    </div>
                    <div class="u-border u-rounded-corners ml-4">
                        <a-btn color="white" :to="{ name: 'projects-activities' }" depressed>
                            <a-icon size="16" outlined>history</a-icon>
                            <span class="ml-1 grey--text text--darken-3 font-weight-medium">Activities</span>
                        </a-btn>
                    </div>
                </div>
            </div>
            <template v-if="local_config_status">
                <div class="u-border py-12 u-rounded-corners u-overflow-y c-tiny-scroll" :style="{ height: `${container_height}px` }">
                    <div class="my-12 u-flex justify-center">
                        <a-img :src="require('/src/assets/images/confidence-score/recalculation-inprogress.png')" contain width="300px" height="232px"></a-img>
                    </div>
                    <div class="text-center mx-auto grey--text text--darken-2" style="max-width: 520px">
                        <div>The recalculation process is currently underway.</div>
                        <div>Please try after sometime.</div>
                    </div>
                </div>
            </template>
            <template v-if="section_list && !section_list.length && !local_config_status">
                <div class="u-border u-flex flex-column align-center u-rounded-corners py-12 u-overflow-y c-tiny-scroll" style="letter-spacing: -0.26px" :style="{ height: `${container_height}px` }">
                    <div class="mb-10">
                        <a-img :src="require('/src/assets/images/confidence-score/empty-sections.png')" contain width="300px" height="232px"></a-img>
                    </div>
                    <div class="mb-4 font-weight-medium grey--text text--darken-3 text-uppercase" style="font-size: 20px">No fields are added</div>
                    <div class="text-center grey--text text--darken-2" style="max-width: 520px">
                        <template v-if="!$can('confidence_score.index')">
                            <div>It appears that the fields are not yet configured.</div>
                        </template>
                        <template v-else>
                            <div>The confidence score has not been configured yet.</div>
                        </template>
                    </div>
                    <a-btn v-if="$can('confidence_score.index')" depressed color="grey darken-4" class="mt-6" :to="{ name: 'settings-confidence-score'}">
                        <a-icon size="18" color="white" class="pr-1">login</a-icon>
                        <span class="white--text">Go To Configuration</span>
                    </a-btn>
                </div>
            </template>
            <template v-if="section_list && section_list.length && !local_config_status">
                <div class="u-border-t u-rounded-corners"  ref="fieldsContainer">
                    <template v-for="(section, index) in section_list">
                        <a-card
                            v-if="section && section.fields && section.fields.length"
                            :key="section.id"
                            :id="section.id"
                            min-height="160"
                            :class="{'mt-6': index}"
                            class="u-rounded-corners c-section-card"
                            :style="[index === 0 ? { borderTop: 'transparent !important' } : '']"
                            flat
                        >
                            <div class="px-5 py-3 u-flex align-center justify-space-between">
                                <div
                                    :class="['u-rounded-corners u-wfull']"
                                >
                                    <div class="u-wfull u-flex">
                                        <div class="c-module-title indigo--text text--darken-1 font-weight-medium" :title="section.name">
                                            {{ section.name | truncateText(local_is_lgdown ? 60 : 100) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="u-rounded-corners grey lighten-4 px-4 py-2 text-center" style="min-width: 130px">
                                    <div v-if="section.current_score" class="md-subtitle-2 grey--text text--darken-3">{{ section.current_score }} / {{ section.total_score }}</div>
                                    <div v-else>
                                        <a-icon size="12">horizontal_rule</a-icon>
                                        <span class="md-subtitle-2 grey--text text--darken-3 ml-1">/ {{ section.total_score }}</span>
                                    </div>
                                </div>
                            </div>
                            <a-divider v-if="local_show_divider(section)"></a-divider>
                            <div>
                                <transition-group type="transition" name="flip-list" class="u-wfull c-section-container">
                                    <div
                                        v-for="(field, fieldIndex) in section.fields"
                                        :ref="'field_' + field.id"
                                        :key="field.id"
                                        class="u-relative js-drag-handle c-field-card"
                                        :class="[field.width === '50%' ? 'c-field-card__half' : 'c-field-card__full']"
                                    >
                                        <a-divider v-if="local_show_item_divider(section, field, fieldIndex)"></a-divider>
                                        <PConfidenceFieldCard
                                            :item="field"
                                            module-type='preview'
                                            class="px-6 py-8"
                                        >
                                            <template #input>
                                                <template v-if="field.type === 'dropdown'">
                                                    <template v-if="field && field.confidence_score_values">
                                                        <div class="u-wfull mt-4 mb-6">
                                                            <v-autocomplete
                                                                v-model="field.confidence_score_values.field_value"
                                                                :ref="'input_' + field.id"
                                                                :items="field.options"
                                                                item-text="label"
                                                                item-value="id"
                                                                placeholder="Select an option"
                                                                class="u-rounded-corners u-border u-wfull prevent-select"
                                                                color="grey darken-1"
                                                                background-color="grey lighten-5"
                                                                :key="field.id"
                                                                :disabled="mixinIsLoading('store') || !local_has_permission()"
                                                                @input="local_add_value(field, section.id)"
                                                                append-icon="arrow_drop_down"
                                                                return-object solo flat hide-details
                                                            >
                                                                <template v-slot:no-data>
                                                                    <div class="px-4 py-2">
                                                                        <span class="md-subtitle-2 py-2 grey--text text--darken-1 text-center">No Data Found</span>
                                                                    </div>
                                                                </template>
                                                                <template v-slot:selection="{ item }">
                                                                    <span v-if="item === Object(item)" class="md-body-3 d-block py-1 u-rounded-corners text-truncate" style="max-width: 630px" :title="item.label">{{ item.label }} </span>
                                                                </template>
                                                                <template v-slot:item="{ item }">
                                                                    <a-layout align-center class="py-0">
                                                                        <a-flex style="max-width: 630px;">
                                                                            <h5 class="md-subtitle-1 text-truncate" :title="item.label">{{ item.label }}</h5>
                                                                        </a-flex>
                                                                    </a-layout>
                                                                </template>
                                                            </v-autocomplete>
                                                        </div>
                                                    </template>
                                                </template>
                                                <template v-if="field.type === 'score'">
                                                    <div class="u-wfull mt-4 mb-6 u-relative" v-if="field && field.confidence_score_values">
                                                        <v-text-field
                                                            v-model="field.confidence_score_values.field_value"
                                                            :ref="'input_' + field.id"
                                                            type="number"
                                                            title=""
                                                            min="0"
                                                            background-color="grey lighten-5"
                                                            placeholder="Enter the score"
                                                            class="u-rounded-corners u-border u-wfull"
                                                            @keydown="local_prevent_non_numerics"
                                                            @input="local_validate_field(field, section.id)"
                                                            @keyup.enter.prevent="local_validate_field(field, section.id)"
                                                            @focus="local_focus_input(field)"
                                                            :disabled="mixinIsLoading('store')|| !local_has_permission()"
                                                            solo flat hide-details>
                                                        </v-text-field>
                                                        <div class="md-caption mt-2 u-absolute" :class="[(error_response && local_get_score_error(field.id)) ? 'red--text text--darken-1': 'grey--text']">
                                                            <template v-if="error_response && local_get_score_error(field.id)">
                                                                <a-icon size="16" color="red darken-1" class="u-icon-nudge">info</a-icon>
                                                                Score must be between {{ field.minimum_value }} - {{ field.maximum_value }}
                                                            </template>
                                                            <template v-else>Enter the score between {{ field.minimum_value }} - {{ field.maximum_value }} </template>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-if="field.type === 'multi_select'">
                                                    <div class="mt-4" :class="'c-scroll-container__' + field.id">
                                                        <div class="d-inline-flex flex-wrap align-center" :class="[currentSelectionId === field.id ? 'u-border-transparent' : '', 'u-border-transparent']">
                                                            <template v-if="field && field.confidence_score_values && field.confidence_score_values.field_value && field.confidence_score_values.field_value.length">
                                                                <template v-for="item in local_get_selected_list(field)">
                                                                    <g-tags :tag="item" :height="24" type="Option" :key="item.id" :hide-clear-icon="!local_has_permission()" @destroy="local_value_destroy(field, item.id, section.id)"></g-tags>
                                                                </template>
                                                            </template>
                                                            <a-menu
                                                                v-model="show_option_add_input[field.id]"
                                                                max-height="220"
                                                                :disabled="!local_has_permission()"
                                                                nudge-bottom="24"
                                                                bottom
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <g-add-btn v-on="on" :class="{'u-opacity-50': !local_has_permission()}" >
                                                                        <span :class="['md-caption body--text text--lighten-1 ml-1']">Select</span>
                                                                    </g-add-btn>
                                                                </template>
                                                                <a-text-field
                                                                    v-model="local_field_search[field.id]"
                                                                    class="c-field-search u-rounded-corners white u-relative"
                                                                    solo flat autofocus
                                                                    prepend-inner-icon="search"
                                                                    hide-details
                                                                    placeholder="Search"
                                                                >
                                                                </a-text-field>
                                                                <a-sheet class="pa-0 u-overflow-y c-tiny-scroll" style="maxHeight: 160px;">
                                                                    <template v-if="local_filtered_items(field) && local_filtered_items(field).length">
                                                                        <div v-for="option in local_filtered_items(field)" :key="option.id">
                                                                            <v-hover v-slot="{ hover }">
                                                                                <div :class="[ hover ? 'grey lighten-5' : '']" class="u-flex-center-y pa-4 u-cursor-pointer" @click="local_add_value(field, section.id, option)">
                                                                                    <div class="u-rounded-corners mr-2" :style="{ width: '16px', height: '16px', flexShrink: 0, backgroundColor: $color(option.color, 'color_bg', '#1565c0'), border: `1px solid ${$color(option.color, 'color_text', '#1565c0')}` }"></div>
                                                                                    <span class="md-body-2 grey--text text--darken-1 text-truncate d-inline-block">{{ option.label | truncateText(16) }}</span>
                                                                                </div>
                                                                            </v-hover>
                                                                            <a-divider></a-divider>
                                                                        </div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="u-flex align-center pa-4 justify-center">
                                                                            <div class="grey--text text-uppercase md-subtitle-2 text-center">
                                                                                <span>No data found</span>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </a-sheet>
                                                            </a-menu>
                                                        </div>
                                                    </div>
                                                </template>
                                            </template>
                                        </PConfidenceFieldCard>
                                    </div>
                                </transition-group>
                            </div>
                        </a-card>
                    </template>
                </div>
            </template>
        </a-responsive>

        <!--Progress bar-->
        <div
            v-if="!mixinIsLoading('fields') && (section_list && section_list.length)"
            class="u-border white u-rounded-corners py-4 px-6 my-6 u-flex align-center u-wfull c-progress-container"
            ref="refBar"
        >
            <a-progress-linear
                v-model="local_score_output"
                background-color="#E6EAF5"
                height="16"
                :class="['c-custom-progress-linear' + `__${local_progress_status}`, 'c-custom-progress-linear u-rounded-corners-full u-wfull']"
            >
            </a-progress-linear>
            <div class="ml-6 grey--text text--darken-3 u-flex align-end" style="fontSize: 20px; letter-spacing: -0.26px">
                <div class="font-weight-bold mr-4">
                    <template v-if="local_get_score_percentage()">
                        <span class="indigo--text text--darken-1 u-flex align-center">({{ local_get_score_percentage() }}%)</span>
                    </template>
                </div>
                <div>
                    <template v-if="current_score">
                        <div class="u-flex align-center">
                            <span class="font-weight-bold">{{ current_score }}</span>
                            <span class="md-subtitle-1"><span class="ml-1 mr-1">/</span>{{ total_score }}</span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="u-flex align-center">
                            <a-icon size="14">horizontal_rule</a-icon>
                            <span class="md-subtitle-1"><span class="ml-1 mr-1">/</span>{{ total_score }}</span>
                        </div>
                    </template>
                </div>
            </div>
            <template v-if="unsaved_changes">
                <div class="pr-0 mr-4 ml-6">
                    <a-btn height="32" color="grey lighten-3" :disabled="mixinIsLoading('store')" depressed small @click="dialog_cancel_changes = true" class="px-4 py-2 u-flex align-center text--darken-2 u-rounded-corners">
                        <span class="md-body-2 grey--text" style="font-weight: 600">CANCEL</span>
                    </a-btn>
                </div>
                <div>
                    <a-btn
                        :loading="mixinIsLoading('store')"
                        :disabled="!!(error_response && error_response.length)"
                        @click="local_confidence_value_store()"
                        color="indigo darken-1"
                        class="c-save-btn mr-4 white--text px-4 py-2 u-flex align-center white--text text--darken-2 u-rounded-corners"
                        height="32"
                        small depressed
                    >
                        <span class="md-body-2" style="font-weight: 600">SAVE</span>
                    </a-btn>
                </div>
            </template>
        </div>

        <!--Score summary -->
        <a-dialog v-model="dialog_score_summary" max-width="1032">
            <ProjectConfidenceScoreSummary
                :list="local_summary_list"
                :config-data="configuration_meta"
                :is-open="dialog_score_summary"
                class="u-wfull"
                @close="dialog_score_summary=false"
                scrollable
            >
            </ProjectConfidenceScoreSummary>
        </a-dialog>

        <!--Cancel change alert-->
        <a-dialog v-model="dialog_cancel_changes" persistent max-width="400">
            <SCancelChanges
                @success="local_cancel_changes()"
                @close="dialog_cancel_changes = false"
                class="pa-6"
            />
        </a-dialog>

        <!--Leaving page without save alert -->
        <a-dialog v-model="leavePage" persistent max-width="400">
            <SCancelChanges
                @success="local_leave_page()"
                @close="$emit('cancel')"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>
    </a-container>
</template>

<script>
import PConfidenceFieldCard from './Partials/PartialConfidenceFieldItem.vue'
import ProjectConfidenceScoreSummary from './ProjectConfidenceScoreSummary.vue'
import { mapState, mapActions } from 'vuex'
import SCancelChanges from '/src/components/Shared/SharedCancelChanges'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import PartialLoaderTemplate from './Partials/PartialConfidenceLoaderTemplate.vue'
import { number } from '@/helpers/helper-number-filter'

export default {
    mixins: [mixinLoading],

    components: { PConfidenceFieldCard, SCancelChanges, PartialLoaderTemplate, ProjectConfidenceScoreSummary },

    props: {
        leavePage: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            preview_list: [],
            local_field_options: [],
            show_option_add_input: [],
            error_response: [],
            dataOptionSearch: null,
            currentSelectionId: null,
            error_item: {score: {}},
            validationTimeout: null,
            currentEditField: null,
            local_score_output: 0,
            current_score: 40,
            total_score: 0,
            container_height: 0,
            score_update_timeout: null,
            section_list: [],
            score_filters: {
                'fields[projects]': 'id,confidence_score',
                'filter[confidence_score]': 1,
                'filter[confidence_score_fields]': 1
            },
            confidence_field_filters: {
                'score_fields[section]': 'id,name,order',
                'score_fields[fields]': 'id,name,is_active,label,confidence_score_section_id,maximum_value,minimum_value,options,order,type,weight,width,description',
                'score_fields[values]': 'confidence_score_field_id,id,label,project_id,value,value_id,value_json'
            },
            meta_filters: {
                'filter[type]': 'confidence_score',
                'fields[metas]': 'id,type,status,selection_type,percentage,color',
                'sort': 'order',
            },
            unsaved_changes: false,
            modified_fields: [],
            dialog_leave_page_alert: false,
            dialog_cancel_changes: false,
            dialog_score_summary: false,
            local_summary_list: [],
            range_1: {},
            range_2: {},
            range_3: {},
            configuration_meta: [],
            filter_item_length: {},
            local_field_search: {}
        }
    },

    watch: {
        unsaved_changes (val) {
            this.confidence_update_unsaved_status(val)
        },

        dialog_score_summary: {
            immediate: true,
            handler (val) {
                if (!val && this.confidence_form_mode === 'field_edit') this.local_focus_item()
            }
        }
    },

    mounted() {
        this.local_index()
        window.addEventListener('beforeunload', this.local_handler_close)
    },

    beforeDestroy () {
        window.removeEventListener('beforeunload', this.local_handler_close)
        window.removeEventListener('resize', this.local_update_container_height)
    },

    computed: {
        local_is_lgdown () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        local_config_status () {
            return (this.project_item.confidence_score_record && this.project_item.confidence_score_record.config_in_progress === 1)
        },

        local_progress_status () {
            if (!this.local_score_output) return
            if (this.local_score_output <= this.range_1.percentage) return this.range_1.color
            if ((this.local_score_output <= this.range_2.percentage) && (this.local_score_output > this.range_1.percentage)) return this.range_2.color
            if (this.local_score_output > this.range_2.percentage) return this.range_3.color
        },

        ...mapState('ConfidenceScoreValues', {
            confidence_form_mode: 'form_mode',
            confidence_edit_item: 'item',
            confidence_response: 'response'
        }),

        ...mapState('ProjectView', {
            project_item: 'item',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),
    },

    methods: {
        async local_index () {
            this.mixinSetLoading('fields')
            if (!this.$can('poc_confidence_score.index')) return
            this.local_set_container_height()
            await this.local_project_show()
            this.section_list = _.cloneDeep(this.project_item.confidence_score_fields)
            this.local_set_field_value()
            this.local_load_configuration_meta()
            this.backup_list = _.cloneDeep(this.section_list)
            if (this.confidence_form_mode === 'view_summary') this.local_show_score_summary()
            this.local_set_score_params()
            this.local_set_solo_fields()
        },

        async local_load_configuration_meta () {
            await this.meta_clear()
            await this.meta_index({...this.meta_filters})
            this.configuration_meta = _.cloneDeep(this.meta_list)
            this.local_set_config_data()
        },

        local_set_config_data () {
            this.configuration_meta.forEach(item => {
                if (item.color === 'red-dark') this.range_1 = item
                if (item.color === 'yellow-dark') this.range_2 = item
                if (item.color === 'green-dark') this.range_3 = item
            })
        },

        local_set_score_params () {
            this.local_set_score()
            this.local_set_section_total_score()
            this.local_set_all_section_score()
            this.local_set_current_score()
        },

        async local_project_show() {
            await this.project_show({
                id: this.$route.params.id,
                params: { ...this.score_filters, ...this.confidence_field_filters}
            })
            this.mixinResetLoading('fields')
        },

        local_focus_item () {
            if (this.confidence_edit_item && !this.confidence_edit_item.id) return
            this.$nextTick(() => {
                if (this.confidence_edit_item.type === 'multi_select') {
                    const rect = this.$refs['field_' + this.confidence_edit_item.id][0].getBoundingClientRect();
                    const height = rect.top;
                    this.$refs.fieldsContainer.scrollTop = height - 200
                    return
                }
                const input = this.$refs['input_' + this.confidence_edit_item.id][0]
                input.focus()
                this.confidence_clear_item()
            })
        },

        local_set_container_height () {
            this.local_update_container_height()
            window.addEventListener('resize', this.local_update_container_height)
        },

        local_update_container_height () {
            this.container_height = window.innerHeight - 286
        },

        local_set_solo_fields () {
            this.field_counter = 0
            this.section_list.forEach((section ,index) => {
                section.fields.forEach((item, fieldIndex) => {
                    item.solo_field = false
                    if (item.width === '50%') {
                        ++this.field_counter
                        if(this.field_counter % 2 !== 0 && (fieldIndex+1 < section.fields.length) && (section.fields[fieldIndex+1].width === '100%')) {
                            item.solo_field = true
                            this.field_counter = 0
                        } else if (this.field_counter % 2 === 0) {
                            item.solo_field = false
                            this.field_counter = 0
                        }
                    }
                    if (item.width === '100%') {
                        item.solo_field = false
                        this.field_counter = 0
                    }
                })
            })
        },

        local_prevent_non_numerics (e) {
            if (!number(e)) e.preventDefault()
        },

        local_focus_input (field) {
            this.currentEditField = field.id
        },

        local_filtered_items (field, type) {
            const selectedItemIds = field.confidence_score_values.field_value && field.confidence_score_values.field_value.length ? field.confidence_score_values.field_value.map(item => item.id) : []
            const filtered = field.options.filter(item => {
                return selectedItemIds.indexOf(item.id) === -1
            })
            if (this.local_field_search[field.id]) return this.local_get_search_result(field)
            this.filter_item_length[field.id] = filtered.length
            return filtered
        },

        local_set_field_value () {
            this.section_list.forEach(section => {
                section.fields.forEach(field => {
                    if (field && !field.confidence_score_values) {
                        field.confidence_score_values = {}
                        field.confidence_score_values.field_value = field.type !== 'multi_select' ? null : []
                    }
                })
            })
        },

        local_get_search_result (field) {
            const searchResult = field.options.filter(option => {
                return option.label.toLowerCase().startsWith(this.local_field_search[field.id].toLowerCase())
            })
            return searchResult
        },

        local_add_value (item, section_id, option) {
            const value = item.type === 'multi_select' ? option : item.confidence_score_values.field_value
            const props = {item: item, section_id, value: value, action: 'add'}
            this.local_update_field_value(props)
            this.show_option_add_input[item.id] = false
            this.currentSelectionId = item.id
        },

        local_value_destroy (field, item_id, section_id) {
            const props = {item: field, section_id, value: item_id, action: 'destroy'}
            this.currentSelectionId = field.id
            this.local_update_field_value(props)
            this.show_option_add_input[field.id] = false
            this.currentSelectionId = null
        },

        local_get_selected_list (field, length = null) {
            if (length) return !!(field.confidence_score_values &&  field.confidence_score_values.field_value && field.confidence_score_values.field_value.length)
            if (field && field.confidence_score_values && field.confidence_score_values.field_value &&!field.confidence_score_values.field_value.length) return
            return field.confidence_score_values.field_value
        },

        local_validate_field (field, section_id) {
            clearTimeout(this.score_update_timeout)
            const max_score = parseInt(field.maximum_value)
            const min_score = parseInt(field.minimum_value)
            const value = parseInt(field.confidence_score_values.field_value)
            if (field.confidence_score_values.field_value && (value < min_score) || (value > max_score)) {
                if (this.error_response.indexOf(field.id) !== -1) return
                this.error_response.push(field.id)
            } else {
                const fieldIndex = this.error_response.findIndex(item => item === field.id)
                this.error_response.splice(fieldIndex, 1)
            }

            const props = {item: field, section_id, value: field.confidence_score_values.field_value, action: 'add'}
            if (this.error_response && !this.error_response.length) {
                this.score_update_timeout = setTimeout(() => {
                    this.local_update_field_value(props)
                }, 1000)
            }
        },

        local_update_field_value (props) {
            const { item, section_id, value, action } = props
            const list = _.cloneDeep(this.section_list)
            const index = _.findIndex(list, {id: section_id})
            const fieldIndex = _.findIndex(list[index].fields, {id: item.id})
            if (action === 'destroy') {
                const itemIndex = _.findIndex(item.confidence_score_values.field_value, {id: value})
                list[index].fields[fieldIndex].confidence_score_values.field_value.splice(itemIndex, 1)
            } else {
                if (item.type === 'multi_select') {
                    list[index].fields[fieldIndex].confidence_score_values.field_value = (!list[index].fields[fieldIndex].confidence_score_values.field_value) ? [] : [...list[index].fields[fieldIndex].confidence_score_values.field_value]
                    list[index].fields[fieldIndex].confidence_score_values.field_value.push(value)
                }
                else list[index].fields[fieldIndex].confidence_score_values.field_value = value
            }
            this.section_list = _.cloneDeep(list)
            this.local_set_section_score(section_id, index)
            this.local_set_current_score()
            this.local_check_unsaved_changes(item)
        },

        local_set_section_score (section_id, section_index) {
            let current_section_score = 0
            const list = _.cloneDeep(this.section_list)
            const section = list.find(section => section.id === section_id)
            section.current_score = 0

            section.fields.forEach(field => {
                if (field.type === 'multi_select' && field.confidence_score_values.field_value && field.confidence_score_values.field_value.length) {
                    let value_total = 0
                    field.confidence_score_values.field_value.forEach(value => {
                        value_total = parseInt(value_total) + parseInt(value.value)
                    })
                    current_section_score = parseInt(current_section_score) + parseInt(value_total)
                }
                const field_score = field.type === 'dropdown' && field.confidence_score_values.field_value && field.confidence_score_values.field_value.value ? parseInt(field.confidence_score_values.field_value.value) : parseInt(field.confidence_score_values.field_value)
                if (field && field_score > 0) {
                    current_section_score = parseInt(current_section_score) + field_score
                }
            })

            list[section_index].current_score = current_section_score
            this.section_list = list
        },

        local_set_all_section_score () {
            const list = _.cloneDeep(this.section_list)

            list.forEach((section, section_index) => {
                let current_section_score = 0
                section.current_score = 0

                section.fields.forEach(field => {
                    if (field.type === 'multi_select' && field.confidence_score_values.field_value && field.confidence_score_values.field_value.length) {
                        let value_total = 0
                        field.confidence_score_values.field_value.forEach(value => {
                            value_total = parseInt(value_total) + parseInt(value.value)
                        })
                        current_section_score = parseInt(current_section_score) + parseInt(value_total)
                    }
                    const field_value = field.type === 'dropdown' && field.confidence_score_values.field_value && field.confidence_score_values.field_value.value ? parseInt(field.confidence_score_values.field_value.value) : parseInt(field.confidence_score_values.field_value)
                    if (field && field_value > 0) {
                        current_section_score = parseInt(current_section_score) + field_value
                    }
                })

                list[section_index].current_score = current_section_score
            })
            this.section_list = list
        },

        local_set_section_total_score () {
            let section_total = 0
            this.section_list.forEach(section => {
                section.fields.forEach((field, index) => {
                    section_total = parseInt(section_total) + parseInt(field.maximum_value)
                    section.total_score = section_total
                    if (index === section.fields.length-1) return section_total = 0
                })
            })
        },

        local_check_unsaved_changes (item) {
            const stored_item = this.backup_list.flatMap(section => {
                return section.fields.map(({ id, confidence_score_values}) => ({id, value: confidence_score_values.field_value}))
            })

            const fields_list = this.section_list.flatMap(section => {
                return section.fields.map(({ id, confidence_score_values}) => ({id, value: confidence_score_values.field_value}))
            })

            const backupIndex = _.findIndex(stored_item, {id: item.id})
            const index = _.findIndex(this.modified_fields, {id: item.id})

            if (backupIndex === -1) return

            if (item.type === 'score') {
                if (!stored_item[backupIndex].value) {
                    !(_.isEmpty(item.confidence_score_values.field_value)) ? this.local_add_modified_item(item.id) : this.modified_fields.splice(index, 1)
                }
                if (stored_item[backupIndex].value) {
                    if (stored_item[backupIndex].value != item.confidence_score_values.field_value) this.local_add_modified_item(item.id)
                    else this.modified_fields.splice(index, 1)
                }
            }

            if (item.type === 'dropdown') {
                if (!stored_item[backupIndex].value) {
                    if ((item.confidence_score_values.field_value && item.confidence_score_values.field_value.id)) this.local_add_modified_item(item.id)
                    else this.modified_fields.splice(index, 1)
                }
                if (stored_item[backupIndex].value) {
                    if (!item.confidence_score_values.field_value || !item.confidence_score_values.field_value.id) this.local_add_modified_item(item.id)
                    else if (stored_item[backupIndex].value.id != item.confidence_score_values.field_value.id) this.local_add_modified_item(item.id)
                    else this.modified_fields.splice(index, 1)
                }
            }

            if (item.type === 'multi_select') {
                const fieldIndex = _.findIndex(fields_list, {id: item.id})
                if (!stored_item[backupIndex].value) stored_item[backupIndex].value = []

                if ((stored_item[backupIndex].value && stored_item[backupIndex].value.length) != (fields_list[fieldIndex].value && fields_list[fieldIndex].value.length)) this.local_add_modified_item(item.id)
                else if (this.local_check_value_modified(stored_item[backupIndex].value, fields_list[fieldIndex].value)) this.local_add_modified_item(item.id)
                else this.modified_fields.splice(index, 1)
            }

            this.unsaved_changes = !!(this.modified_fields && this.modified_fields.length)
        },

        local_add_modified_item (id) {
            const already_modified = this.modified_fields.indexOf(id) !== -1
            if (already_modified) return
            this.modified_fields.push(id)
        },

        local_check_value_modified (storedValue, currentValue) {
            storedValue.forEach((item, storedIndex) => {
                const index = _.findIndex(currentValue, {id: item.id})
                item.modified = (index === -1) || index !== storedIndex
            })
            const modified = storedValue.filter(item => item.modified)
            return !!(modified && modified.length)
        },

        local_get_score_error (fieldId) {
            return this.error_response.indexOf(fieldId) !== -1
        },

        local_set_current_score () {
            let score = 0
            this.section_list.forEach(section => {
                if (section && section.current_score) score = parseInt(score) + parseInt(section.current_score)
                this.current_score = parseInt(score)
                const score_ratio = parseInt(this.current_score) / parseInt(this.total_score)
                this.local_score_output = Math.round(score_ratio*100)
            })
        },

        local_get_score_percentage () {
            const score_ratio = this.current_score/this.total_score
            return Math.round(score_ratio*100)
        },

        local_set_score () {
            this.total_score = this.project_item.confidence_score_record.total
            this.current_score = this.project_item.confidence_score_record.current_score
        },

        local_handler_close (e) {
            if (this.unsaved_changes) {
                e.returnValue = 'Are you sure to close the tab?';
            }
        },

        async local_confidence_value_store () {
            this.mixinSetLoading('store')
            const field_list_params = this.local_get_field_list_params()
            const params = {
                project_id: this.$route.params.id,
                fields: field_list_params
            }
            await this.confidence_store(params)

            if (this.confidence_response && this.confidence_response.status !== 'success') {
                if (_.has(this.confidence_response , 'server.errors.config_in_progress')) this.$notify('error', this.confidence_response.server.errors.config_in_progress[0])
                else this.$notify('error', 'An error occurred while saving the changes, please reload the page.')
                this.mixinResetLoading('store')
                return
            }

            this.$notify('success', 'Scores updated successfully!')

            await this.local_project_show()
            this.section_list = _.cloneDeep(this.project_item.confidence_score_fields)
            this.backup_list = _.cloneDeep(this.section_list)
            this.mixinResetLoading('store')
            this.local_set_score_params()
            this.local_reset_params()
        },

        local_get_field_list_params () {
            const list = _.cloneDeep(this.section_list)
            const field_list = list.flatMap(section => {
                return section.fields.map(({id, confidence_score_values, type}) => ({ id : confidence_score_values.id, confidence_score_field_id: id, field_value: confidence_score_values.field_value, type, value: null, label: null, value_id: null, value_json: [] }))
            })
            field_list.forEach(item => {
                if (!item.id) this.$appendId(item)
            })
            field_list.forEach(item => {
                if (item.type === 'score') {
                    item.value = item.field_value && item.field_value.value ? item.field_value.value : item.field_value
                    item.value_json = []
                    item.label = null
                    item.value_id = null
                }
                if (item.type === 'dropdown') {
                    item.value = item.field_value && item.field_value.value ? item.field_value.value : null
                    item.value_json = []
                    item.label = item.field_value && item.field_value.label ? item.field_value.label : null
                    item.value_id = item.field_value && item.field_value.id ? item.field_value.id : null
                }
                if (item.type === 'multi_select') {
                    item.value = null
                    item.value_json = item.field_value
                    item.label = null
                    item.value_id = null
                }
            })
            return field_list
        },

        local_cancel_changes () {
            this.section_list = _.cloneDeep(this.backup_list)
            this.local_set_score_params()
            this.local_reset_params()
            this.dialog_cancel_changes = false
        },

        local_reset_params () {
            this.error_response = []
            this.unsaved_changes = false
            this.modified_fields = []
        },

        local_show_score_summary () {
            this.local_set_summary_section_total_score()
            this.local_filter_summary_list()
            this.dialog_score_summary = true
            this.confidence_clear_item()
        },

        local_set_summary_section_total_score () {
            this.local_summary_list = _.cloneDeep(this.backup_list)
            let section_total = 0
            this.local_summary_list.forEach(section => {
                section.fields.forEach((field, index) => {
                    section_total = parseInt(section_total) + parseInt(field.maximum_value)
                    section.total_score = section_total
                    if (index === section.fields.length-1) return section_total = 0
                })
            })

        },

        local_filter_summary_list () {
            this.local_summary_list.forEach((section, index) => {
                const filteredFields = section.fields.filter((field, fieldIndex) => {
                    if (field.type !== 'multi_select') return (field.confidence_score_values && field.confidence_score_values.field_value)
                    return !!(field.confidence_score_values && field.confidence_score_values.field_value && field.confidence_score_values.field_value.length)
                })
                section.fields = _.cloneDeep(filteredFields)
            })
        },

        local_has_permission () {
            return this.$can('projects.update-only') && this.$can('poc_confidence_score.update')
        },

        local_show_divider (section) {
            if (section.fields.length === 1 && section.fields[0].width === '50%') return true
            if (section.fields.length > 1 && section.fields[0].width === '50%' && section.fields[1].width !== '50%') return true
        },

        local_show_item_divider (section, field, index) {
            if (index === 0 && section.fields.length === 1 && field.width === '50%') return false
            if (index === 0 && section.fields.length > 1 && field.width === '50%' && section.fields[1].width !== '50%') return false
            return !field.solo_field
        },

        local_leave_page () {
            window.removeEventListener('resize', this.local_update_container_height)
            this.$emit('leavePage')
        },

        ...mapActions('ConfidenceScoreValues', {
            confidence_store: 'store',
            confidence_update_form_mode: 'update_form_mode',
            confidence_update_unsaved_status: 'update_unsaved_status',
            confidence_clear_item: 'clear_item'
        }),

        ...mapActions('ProjectView', {
            project_show: 'show',
            project_replace_item: 'replace_item'
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear'
        }),
    }
}
</script>

<style scoped>
    .c-section-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .c-section-card {
        border: 1px solid rgba(238, 238, 238, 1) !important;
    }
    .c-field-card {
        width: 100%;
    }
    .c-field-card__half {
        width: 50% !important;
    }
    .c-field-card__half--preview {
        width: 50% !important;
    }
    .c-field-card__full{
        width: 100% !important;
    }
    .prevent-select {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .c-progress-container {
        position: sticky !important;
        bottom: 0;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.06) !important;
    }
</style>

<template>
    <div
        @mouseover="local_edit_mode(item)"
        @mouseleave="local_remove_edit_mode()"
        class="pa-4"
    >
        <div class="u-flex align-start">
            <a-icon
                size="20"
                color="grey"
                style="marginTop: 2px"
            >
                {{ local_get_field_type_icon(item) }}
            </a-icon>
            <span class="md-body-1 px-2 font-weight-medium grey--text text--darken-3" v-if="moduleType === 'preview'">
                <template>{{ item.label }}</template>
            </span>
            <span v-else-if="item.width === '50%'" class="md-body-1 font-weight-medium px-3 grey--text text--darken-2">
                <template v-if="item.width === '50%' && local_is_lgdown">{{ item.label | truncateText(30) }}</template>
                <template v-else-if="item.width === '50%'">{{ item.label | truncateText(50) }}</template>
            </span>
            <span v-else class="md-body-1 font-weight-medium px-3 grey--text text--darken-2">
                <template v-if="local_is_lgdown">{{ item.label | truncateText(80) }}</template>
                <template v-else>{{ item.label | truncateText(110) }}</template>
            </span>

            <a-tooltip right max-width="600" v-if="item.description && moduleType === 'preview'">
                <template v-slot:activator="{ on }">
                    <a-icon v-on="on" size="16" color="grey" class="u-cursor-pointer mt-1">info</a-icon>
                </template>
                <div class="py-1 u-word-wrap">{{ item.description }}</div>
            </a-tooltip>
        </div>
        <div class="u-flex ml-8 mt-4 md-body-2 grey--text text--darken-1" v-if="moduleType !== 'preview'">
            <div>Maximum score: <span class="md-subtitle-2 grey--text text--darken-2">{{ item.maximum_value }}</span></div>
            <a-icon size="6" class="mx-2" color="grey lighten-1">fiber_manual_record</a-icon>
            <div>Weight: <span class="md-subtitle-2 grey--text text--darken-2">{{ item.weight }}%</span></div>
        </div>
        <div class="c-edit-container" v-if="show_edit_mode && edit_item.id === item.id && moduleType !== 'preview' && $can('confidence_score.update')" style="z-index: 0">
            <a-icon outlined size="18" color="grey darken-3" class="u-cursor-pointer" @click.stop="local_edit_field(item)">edit</a-icon>
            <a-tooltip bottom v-if="current_delete_item === item.id">
                <template v-slot:activator="{ on }">
                    <a-icon v-on="on" outlined size="18" @click.stop="local_delete_field(item)" color="red" class="ml-3 u-cursor-pointer">delete</a-icon>
                </template>
                <span>Confirm Delete?</span>
            </a-tooltip>
            <a-tooltip bottom v-else>
                <template v-slot:activator="{ on }">
                    <a-icon v-on="on" outlined size="18" @click.stop="local_select_delete_item(item)" color="grey darken-3" class="ml-3 u-cursor-pointer">delete</a-icon>
                </template>
                <span v-if="current_delete_item === item.id">Confirm Remove?</span>
                <span v-else>Delete field</span>
            </a-tooltip>
        </div>
        <div>
            <slot name="input"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },

        moduleType: {
            type: String,
            default: 'configure'
        }
    },

    data () {
        return {
            show_edit_mode: false,
            edit_item: {},
            current_delete_item: null,
            local_field_type_list: [
                { type: 'score', label: 'Score', icon: 'pin' },
                { type: 'dropdown', label: 'Selection (Single)', icon: 'arrow_drop_down_circle' },
                { type: 'multi_select', label: 'Selection (Multiple)', icon: 'check_box' },
            ],
            refreshItem: false
        }
    },

    computed: {
        local_is_lgdown () {
            return this.$vuetify.breakpoint.lgAndDown
        },
    },

    methods: {
        local_get_field_type_icon(field) {
            const type = field.type
            const custom_field_item = this.local_field_type_list.find(item => item.type === type)
            if (custom_field_item) return custom_field_item.icon
        },

        local_edit_mode (field) {
            this.$emit('hover')
            this.edit_item = field
            this.show_edit_mode = true
        },

        local_remove_edit_mode () {
            this.$emit('leave')
            this.edit_item = {}
            this.show_edit_mode = false
        },

        local_select_delete_item (item) {
            this.current_delete_item = item.id
            setTimeout(() => {
                this.current_delete_item = null
            }, 3000);
        },

        local_delete_field (item) {
            this.$emit('delete', item)
        },

        local_edit_field (item) {
            this.$emit('edit', item)
        },
    }
}
</script>

<style scoped>
    .c-edit-container {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        z-index: 10;
        opacity: 0.75;
        background-color: #FAFAFA !important;
        padding: 1rem;
        top: 0px;
        right: 0px;
        width: 20%;
        height: 93px;
    }
</style>

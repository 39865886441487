const api_endpoint = '/assignees';

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        response: [],
        defaults: {
            list: [],
            item: {},
            response: [],
        },
    },
    mutations: {
        UPDATE_ITEM(state, payload) {
            state.item = payload
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        async bulk_assign ({ dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.post(api_endpoint + '/bulk', payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async bulk_remove ({ dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.delete(api_endpoint + '/bulk', { params: payload })
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, payload)
                .then((response) => {
                    context.commit('UPDATE_ITEM', payload)
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success'});
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },

        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },

        async throw_success ({ commit }) {
            commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            this.dispatch('Interface/loader_stop')
        },

        async throw_error ({ commit }, error) {
            this.dispatch('Interface/loader_stop')
            commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
        },

        async loader_start () {
            this.dispatch('Interface/loader_start')
        },

        clear(context, payload) {
            context.commit('UPDATE_ITEM', context.state.defaults.item)
            context.commit('THROW_RESPONSE', context.state.defaults.response)
        }
    },
}

<template>
    <a-tooltip bottom :content-class="tooltipClass">
        <template v-slot:activator="{ on }">
            <span v-on="on" :class="contentClass">
                {{ formattedSimpleTime }}
            </span>
        </template>
        <span :class="hoverClass">{{ formattedFullTime }}</span>
    </a-tooltip>
</template>

<script>
export default {
    props: {
        timestamp: {
            type: String,
            required: true,
        },
        tooltipClass: {
            type: String,
            default: 'c-tooltip-pointer c-tooltip-pointer--right'
        },
        contentClass: {
            type: String,
            default: ''
        },
        hoverClass: {
            type: String,
            default: ''
        },
        showPastFormat: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            formattedFullTime: null,
            formattedSimpleTime: null,
            relativeTimeFormat: {
                past: '%s',
                s : '%d secs',
                ss: '%d secs',
                m:  "a min",
                mm: "%d mins",
                h:  "1 hr",
                hh: "%d hrs",
            }
        }
    },


    mounted () {
        this.localIndex()
    },

    methods: {
        localIndex () {
            this.formattedFullTime = moment.utc(this.timestamp).local().format('MMM D, YYYY [at] h:mm A')
            this.localCalcSimpleTime()
        },

        localCalcSimpleTime () {
            const diffInDays = moment(this.timestamp).diff(moment().format('YYYY-MM-DD'), 'days')
            const diffInMonths = moment(this.timestamp).diff(moment().format('YYYY-MM-DD'), 'months')
            const diffInYears = moment(this.timestamp).diff(moment().format('YYYY-MM-DD'), 'years')

            if (diffInYears >= 1) return this.formattedSimpleTime = diffInYears + (diffInYears === 1 ? ' year' : ' years') + (this.showPastFormat ? ' ago' : '')
            if (diffInDays > 90) return this.formattedSimpleTime = diffInMonths + ' months' + (this.showPastFormat ? ' ago' : '')
            if (diffInDays >= 1 && diffInDays <= 90) return this.formattedSimpleTime = diffInDays + (diffInDays > 1 ? ' days' : ' day') + (this.showPastFormat ? ' ago' : '')

            const momentObj = _.cloneDeep(moment)
            if (this.showPastFormat) this.relativeTimeFormat['past'] = this.relativeTimeFormat.past + ' ago'
            momentObj.updateLocale('en', { relativeTime: this.relativeTimeFormat })

            this.formattedSimpleTime = momentObj.utc(this.timestamp).local().fromNow()
        }
    }
}
</script>

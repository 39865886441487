import ModalAddCompetitor from './ModalAddCompetitor.vue'
import ModalCompetitorView from './ModalCompetitorView.vue'
import ModalProjectDelete from './ModalProjectDelete.vue'
import ModalStageChange from './ModalStageChange.vue'
import ModalWorkspaceChange from './ModalWorkspaceChange.vue'
import ModalTemplateImport from './ModalTemplateImport.vue'
import ModalProjectStaticFieldsUpdate from './ModalProjectStaticFieldsUpdate.vue'
import ModalExitPresentationMode from './ModalExitPresentationMode.vue'

export {
    ModalExitPresentationMode,
    ModalProjectStaticFieldsUpdate,
    ModalTemplateImport,
    ModalWorkspaceChange,
    ModalStageChange,
    ModalProjectDelete,
    ModalCompetitorView,
    ModalAddCompetitor
}

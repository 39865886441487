<template>
	<a-responsive class="white u-elevation-custom-1">
        <a-container grid-list-xl container--fluid class="py-0 px-0">
            <a-layout align-center>
                <a-flex shrink>
                    <a-tabs class="mb-0" slider-color="blue">
                        <a-tab ripple class="mx-2 ml-0" :to="{ name: 'appstore-webhook-workflows' }" exact :disabled="installation.is_active === 0">
                            <a-icon size="16" class="mr-1">device_hub</a-icon>
                            <span class="md-body-2">Workflows</span>
                        </a-tab>
                        <a-tab ripple class="mx-2" :to="{ name: 'appstore-webhook-logs' }" exact>
                            <a-icon size="16" class="mr-1">menu_open</a-icon>
                            <span class="md-body-2">Logs</span>
                        </a-tab>
<!--                        <a-tab ripple class="mx-2" :to="{ name: 'appstore-outgoing-webhooks-accounts' }" exact>-->
<!--                            <a-icon size="16" class="mr-1">vpn_key</a-icon>-->
<!--                            <span class="md-body-2">Accounts</span>-->
<!--                        </a-tab>-->
                        <a-tab ripple class="mx-2" :to="{ name: 'appstore-webhook-settings' }">
                            <a-icon color="grey darken-2" size="16" class="mr-1">settings</a-icon>
                            <span class="md-body-2">Settings</span>
                        </a-tab>
                    </a-tabs>
                </a-flex>
            </a-layout>
        </a-container>
    </a-responsive>
</template>

<script>
export default {
    props: {
        installation: {
            type: Object,
            default: {},
            required: true,
        }
    }
}
</script>

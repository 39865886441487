<template>
    <div class="u-relative">
        <template v-if="pageLoading">
            <PartialProjectTaskLoader></PartialProjectTaskLoader>
        </template>
        <template v-else>
            <div class="u-flex justify-space-between">
                <PartialTaskAddMenu
                    v-if="localHasPermissionTo('update')"
                    :currentEditModule="currentBulkEditModule"
                    @change="localCreateBtnModule({ ...$event, order: -1 })"
                />
                <h3 v-else class="text-h6 grey--text text--darken-3 font-weight-medium">Tasks</h3>

                <!-- Magic Island -->
                <a-sheet height="40" class="c-magic-island u-flex u-z12" style="column-gap: 12px;" v-if="bulkItems.length">
                    <a-sheet class="grey darken-4 white--text u-rounded-corners-2xl u-flex-center" width="40">
                        {{ bulkItems.length }}
                    </a-sheet>

                    <div class="grey darken-4 u-flex-center pa-1 u-rounded-corners-2xl pr-6" style="column-gap: 24px;">
                        <PartialIslandSwitcher
                            :currentModule="currentBulkEditModule"
                            @change="localOnIslandAction($event, 'module-switcher')"
                        />
                        <div class="u-flex-center-y" style="column-gap: 12px;">
                            <GNonActiveInfo
                                :project="project_item"
                                :stage="localProjectStatus"
                                v-if="localHasPermissionTo('update') && localProjectStatus !== 'active'"
                            >
                                <v-btn depressed icon height="32" class="u-rounded-corners ma-0 pa-0">
                                    <a-icon color="grey darken-2" size="20">check_circle</a-icon>
                                </v-btn>
                            </GNonActiveInfo>
                            <PartialIslandMenu
                                v-else
                                :list="taskMetaList.filter(i => i.status === 'Completed')"
                                :disabled="currentBulkEditModule === 'milestone'"
                                @change="localOnIslandAction($event, 'change-status')"
                                itemText="value"
                                triggerIcon="check_circle"
                            >
                                <template #title>Mark as Completed</template>
                                <template #noData>No Status Found</template>
                            </PartialIslandMenu>
                            <PartialIslandMenu
                                :list="localMilestoneMenuList"
                                :disabled="currentBulkEditModule === 'milestone'"
                                @change="localOnIslandAction($event, 'change-milestone')"
                            />
                            <PartialIslandMenu
                                v-if="currentBulkEditModule === 'task'"
                                :list="localMilestoneMenuList"
                                :disabled="currentBulkEditModule === 'milestone'"
                                @change="localOnIslandAction($event, 'copy-milestone')"
                                triggerIcon="content_copy"
                            >
                                <template #title>Copy Tasks between Milestones</template>
                            </PartialIslandMenu>
                            <a-btn v-if="currentBulkEditModule === 'milestone'" icon depressed class="u-rounded-corners" height="32" @click="localDuplicateMilestones()">
                                <a-icon color="white" size="20">content_copy</a-icon>
                            </a-btn>
                            <template v-if="localHasPermissionTo('destroy')">
                                <a-btn v-if="!showBulkDelConfirmation" icon depressed class="u-rounded-corners" height="32" @click.stop="localBulkDelete()">
                                    <a-icon color="white" size="20">delete</a-icon>
                                </a-btn>
                                <a-tooltip v-else bottom content-class="c-tooltip-pointer c-tooltip-pointer--right">
                                    <template v-slot:activator="{ on }">
                                        <a-btn v-on="on" icon depressed class="u-rounded-corners grey darken-3" height="32" @click="localBulkDelete()">
                                            <a-icon color="orange darken-2" size="20">delete</a-icon>
                                        </a-btn>
                                    </template>
                                    <span>Confirm Delete?</span>
                                </a-tooltip>
                            </template>
                        </div>
                    </div>

                    <a-card flat @click="localExitBulkMode()" class="grey darken-4 u-rounded-corners-2xl u-flex-center u-cursor-pointer" width="40" height="40">
                        <a-icon size="16" color="white">close</a-icon>
                    </a-card>
                </a-sheet>

                <!-- More options -->
                <div class="u-flex-center-y" style="column-gap: 16px;">
                    <a-menu offset-y left min-width="180" content-class="u-rounded-corners-lg u-z16">
                        <template v-slot:activator="{ on }">
                            <PartialActionButton v-on="on" height="40" width="40" class="px-1 u-flex-center">
                                <a-icon size="20" color="grey darken-2">more_vert</a-icon>
                            </PartialActionButton>
                        </template>
                        <a-list class="u-list-condensed u-list-condensed--round-select pa-1">
                            <a-list-item class="u-rounded-corners-lg px-3" @click="dialogExportForm = true">
                                <a-list-item-content class="grey--text text--darken-2 md-subtitle-1">Export as Template</a-list-item-content>
                            </a-list-item>
                            <a-list-item class="u-rounded-corners-lg px-3" @click="dialogKeyboardShortcuts = true">
                                <a-list-item-content class="grey--text text--darken-2 md-subtitle-1">Keyboard shortcuts</a-list-item-content>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </div>
            </div>

            <!-- Filters -->
            <div class="u-flex my-6" style="column-gap: 16px;">
                <!-- View -->
                <a-menu offset-y width="220" content-class="u-rounded-corners-lg u-z16">
                    <template v-slot:activator="{ on }">
                        <PartialActionButton v-on="on" height="40" min-width="184" max-width="184" class="u-flex-center-y px-3" style="column-gap: 6px;">
                            <a-icon size="22" color="grey">list</a-icon>
                            <span class="font-weight-medium md-body-2 text-capitalize d-inline-flex align-items-center" style="column-gap: 4px;">
                                <span class="grey--text">View:</span>
                                <span class="grey--text text--darken-2 d-block text-truncate">{{ currentBulkEditModule }}</span>
                            </span>
                            <a-spacer></a-spacer>
                            <a-icon size="20" color="grey darken-2">arrow_drop_down</a-icon>
                        </PartialActionButton>
                    </template>
                    <a-list class="u-list-condensed u-list-condensed--round-select pa-1">
                        <a-list-item class="px-3 u-rounded-corners-lg" @click="localSwitchView({ slug: 'task' })">
                            <a-list-item-content class="md-subtitle-1 grey--text text--darken-2">Tasks</a-list-item-content>
                            <a-list-item-icon>
                                <PartialShortcutKey>T</PartialShortcutKey>
                            </a-list-item-icon>
                        </a-list-item>
                        <a-list-item class="px-3 u-rounded-corners-lg" @click="localSwitchView({ slug: 'milestone' })">
                            <a-list-item-content class="md-subtitle-1 grey--text text--darken-2">Milestones</a-list-item-content>
                            <a-list-item-icon>
                                <PartialShortcutKey>M</PartialShortcutKey>
                            </a-list-item-icon>
                        </a-list-item>
                    </a-list>
                </a-menu>

                <!-- Tasks filter -->
                <a-menu v-if="currentBulkEditModule === 'task'" offset-y min-width="250" content-class="u-rounded-corners-lg u-z16">
                    <template v-slot:activator="{ on }">
                        <PartialActionButton v-if="currentBulkEditModule === 'task'" v-on="on" height="40" min-width="250" max-width="250" class="u-flex-center-y px-3" style="column-gap: 6px;">
                            <a-icon size="22" color="grey">filter_list</a-icon>
                            <span class="font-weight-medium md-body-2 text-capitalize d-inline-flex align-items-center" style="column-gap: 4px;">
                                <span class="grey--text">Tasks:</span>
                                <span class="grey--text text--darken-2 d-block text-truncate">{{ taskStatusFilter.text }}</span>
                            </span>
                            <a-spacer></a-spacer>
                            <a-icon size="20" color="grey darken-2">arrow_drop_down</a-icon>
                        </PartialActionButton>
                    </template>
                    <a-list class="u-list-condensed u-list-condensed--round-select pa-1">
                        <a-list-item
                            v-for="status in taskStatusFilterList"
                            :key="status.value"
                            class="px-3 u-rounded-corners-lg"
                            @click="localFilterByTaskStatus(status)"
                        >
                            <a-list-item-content class="md-subtitle-1 grey--text text--darken-2">{{ status.text }}</a-list-item-content>
                            <a-list-item-icon>
                                <PartialShortcutKey>{{ status.keyboard }}</PartialShortcutKey>
                            </a-list-item-icon>
                        </a-list-item>
                    </a-list>
                </a-menu>

                <a-spacer></a-spacer>

                <PartialActionButton
                    v-if="localHasPermissionTo('update')"
                    @click="!localHasListData ? {} : localToggleSelectAll()"
                    :class="[{ 'indigo darken-2': isSelectAll, 'u-opacity-60': !localHasListData }]"
                    :disabled="!localHasListData"
                    class="u-flex-center-y px-3 u-no-select"
                    height="40"
                >
                    <a-icon size="20" :color="!isSelectAll ? (!localHasListData ? 'grey' : 'grey darken-1') : 'white'">done_all</a-icon>
                    <span class="md-subtitle-2 ml-1" :class="[!isSelectAll ? (!localHasListData ? 'grey--text' : 'grey--text text--darken-2') : 'white--text']">Select All</span>
                </PartialActionButton>
                <PartialActionButton
                    @click="currentBulkEditModule === 'milestone' ? {} : localToggleExpandAll()"
                    :class="[{ 'u-item-disabled': currentBulkEditModule === 'milestone' }]"
                    :disabled="currentBulkEditModule === 'milestone'"
                    class="u-flex-center px-1"
                    height="40"
                    width="40"
                >
                    <a-icon size="20" :color="currentBulkEditModule === 'milestone' ? 'grey lighten-1' : 'grey darken-2'">{{ isExpandAll ? 'unfold_more' : 'unfold_less'}}</a-icon>
                </PartialActionButton>
            </div>

            <!-- Milestone view -->
            <template v-if="currentViewMode === 'milestone' || currentViewMode === 'select-all-milestones'">
                <PartialMagicTable
                    ref="refMagicTable"
                    :items="localMilestones"
                    :columns="milestoneColumnsList"
                    :viewMode="currentViewMode"
                    :selectAll="isSelectAll"
                    :canUpdate="localHasPermissionTo('update')"
                    :hasDeleteConfirmation="showBulkDelConfirmation"
                    :avoidValueToggle="['tasks_count']"
                    @reorder="localReorder"
                    @clearSelectAll="() => isSelectAll = false"
                    @create="localCreateMilestone"
                    @bulkSelect="localBulkSelect"
                    hideAddColumn
                >
                    <template #itemIcon="{ handle }" v-if="localHasPermissionTo('update') && !localBulkMode">
                        <a-icon class="c-task-drag-handle" size="20" :class="[handle]" color="grey darken-1">drag_indicator</a-icon>
                    </template>
                    <template #cellDisplay="{ item, colSlug, column, switchDisplay }">
                        <div
                            @click="switchDisplay"
                            class="u-flex align-center u-wfull"
                            :style="[{ width: column && (column.minWidth - (column && column._order !== 0 ? 0 : 52) + 'px') }]"
                        >
                            <template v-if="colSlug === 'title'">
                                <p class="text-truncate md-subtitle-1 ma-0" :title="localGetCellValue(item, colSlug, column)">{{ localGetCellValue(item, colSlug, column) || '-' }}</p>
                            </template>
                            <template v-if="colSlug === 'tasks_count'">
                                <p class="text-truncate md-subtitle-1 ma-0 px-3">{{ localGetCellValue(item, colSlug, column) || '0' }}</p>
                            </template>
                            <template v-if="colSlug === 'assignees'">
                                <a-sheet v-if="!item.assignees || !item.assignees.length" color="grey" class="u-rounded-corners-full u-flex-center mx-3" width="32" height="32">
                                    <a-icon color="white" size="16">{{ localHasPermissionTo('update') ? 'group_add' : 'people_alt' }}</a-icon>
                                </a-sheet>
                                <SUsersDeck v-else class="px-3" :list="item.assignees" :avatar-size="32" :count="2" />
                            </template>
                            <template v-if="dateFieldSlugs.includes(colSlug)">
                                <p class="text-truncate ma-0 md-subtitle-1 px-3">{{ localFormatValue(localGetCellValue(item, colSlug, column), colSlug, item) || '-' }}</p>
                            </template>
                        </div>
                    </template>
                    <template #cellItem="{ item, colSlug, column, isActive }" v-if="localHasPermissionTo('update')">
                        <template v-if="colSlug === 'title'">
                            <input
                                type="text"
                                class="u-wfull c-autofocus white u-hfull"
                                :value="item.title"
                                @blur="localBlurMilestoneTitle($event, item)"
                                @keydown.enter="localBlurMilestoneTitle($event, item)"
                                @input="localUpdateMilestoneTitle($event, item)"
                            >
                        </template>
                        <template v-else-if="colSlug === 'assignees'">
                            <PartialAssigneeDropdown
                                :forceOpen="isActive"
                                :list="collaboratorsList"
                                :minWidth="column && column.minWidth"
                                :attachId="`attach-${colSlug}-${item.id}`"
                                :dropdownWidth="300"
                                :dropdownHeight="350"
                                :closeOnClick="false"
                                :grouped="false"
                                :item="item"
                                :isActive="isActive"
                                :searchFn="localSearchCollabs"
                                :isAddedFn="localIsAssigneeAdded"
                                @openDropdown="({ clearLoading }) => clearLoading()"
                                @select="localUpdateMilestone($event, item, colSlug)"
                                placeholderType="Select Assignees"
                                class="u-hfull"
                                noLoading manualMode hideArrow
                            />
                        </template>
                        <template v-else-if="dateFieldSlugs.includes(colSlug)">
                            <div v-outside-click class="u-relative u-wfull white">
                                <div @click="localFocusOut()" class="md-subtitle-1 u-wfull u-hfull u-flex-center-y px-3 grey--text text--darken-2">
                                    {{ item[colSlug] ? localFormatDate(item[colSlug]) : '' }}
                                </div>
                                <div class="u-absolute u-wfull u-rounded-corners-lg" style="left: 0px; top: 56px; z-index: 10; min-width: 252px;">
                                    <vc-date-picker
                                        v-model="dateModels[`${item.id}/${colSlug}`]"
                                        :min-date="colSlug === 'due_date' ? item['start_date'] : null"
                                        :max-date="colSlug === 'start_date' ? item['due_date'] : null"
                                        class="d-block u-wfull u-shadow-md"
                                        @input="localUpdateDateFieldMilestone($event, colSlug, item)"
                                        :model-config="modelConfig"
                                    />
                                </div>
                            </div>
                        </template>
                        <span v-else>{{ (item[colSlug] || '-') }}</span>
                    </template>
                </PartialMagicTable>
            </template>

            <!-- Tasks view -->
            <template v-if="!currentViewMode || currentViewMode === 'task' || currentViewMode === 'select-all-tasks'">
                <PartialTable
                    ref="refMagicTable"
                    :wrapItems="localMilestones"
                    :items="localMilestones"
                    :columns="columnsList"
                    :viewMode="currentViewMode"
                    :avoidValueToggle="localAvoidValueToggle"
                    :noDirectEditCols="localNoDirectEditCols"
                    :itemRanges="taskRanges"
                    :expandAll="isExpandAll"
                    :selectAll="isSelectAll"
                    :currentTaskFilter="taskStatusFilter"
                    :canUpdate="localHasPermissionTo('update')"
                    @openDialog="localOpenDialogEdits"
                    @onAccordianToggle="localFocusOut()"
                    @clearSelectAll="() => isSelectAll = false"
                    @editItem="localEditTask"
                    @create="localCreateModule"
                    @reorder="localReorder"
                    @bulkSelect="localBulkSelect"
                    @updateColumns="localUpdatedColumns"
                    @currentRow="localSelectRow"
                >
                    <template #wrapItem="{ item, isNoMilestone, isLoading, isToggledOn, canUpdate, toggleMilestone }">
                        <a-icon color="grey darken-1" size="22">keyboard_arrow_{{ isToggledOn ? 'up' : 'down' }}</a-icon>
                        <a-sheet :min-width="milestoneTitleEditId !== item.id ? 'auto' : 316" max-width="316" class="ml-4 u-relative" @click.stop="canUpdate && !localBulkMode ? localEditMilestoneTitle(item.id) : toggleMilestone()">
                            <h3 v-if="milestoneTitleEditId !== item.id || isNoMilestone" class="md-subtitle-1 font-weight-medium grey--text text--darken-3 text-truncate u-wfull">{{ item.title }}</h3>
                            <template v-else>
                                <div :class="['c-milestone-title-edit', 'c-milestone-title-edit--focus']"></div>
                                <input
                                    :ref="`refMilestoneTitle-${item.id}`"
                                    :value="item.title"
                                    placeholder="Milestone Title..."
                                    type="text"
                                    class="c-autofocus white u-wfull"
                                    style="max-width: 316px;"
                                    @blur="localBlurMilestoneTitle($event, item)"
                                    @keydown.enter="localBlurMilestoneTitle($event, item)"
                                    @keydown.escape="localFocusOut()"
                                    @input="localUpdateMilestoneTitle($event, item)"
                                >
                            </template>
                        </a-sheet>
                        <template v-if="!isNoMilestone">
                            <a-tooltip v-if="item.visibility === 'external'" bottom content-class="c-tooltip-pointer c-tooltip-pointer--right">
                                <template v-slot:activator="{ on }">
                                    <a-icon v-on="on" color="amber darken-3" size="20" class="ml-3">public</a-icon>
                                </template>
                                <span>Public</span>
                            </a-tooltip>
                            <template v-if="isLoading">
                                <a-progress-circular width="2" size="16" color="orange darken-1" indeterminate class="ml-2"></a-progress-circular>
                            </template>
                            <span v-else class="grey--text text--darken-1 md-body-2 font-weight-medium ml-3">({{ item.tasks_completed_count || 0 }}/{{ item.tasks_count || 0 }} Tasks)</span>
                            <a-menu
                                v-if="localHasPermissionTo('destroy') && !localBulkMode"
                                v-model="modelMilestoneDelete[item.id]"
                                :close-on-content-click="false"
                                :disabled="!localHasPermissionTo('destroy')"
                                content-class="u-z16"
                                min-width="200"
                                offset-y right
                            >
                                <template v-slot:activator="{ on }">
                                    <a-icon v-on="on" size="20" color="grey darken-1" class="ml-3">more_horiz</a-icon>
                                </template>
                                <a-list class="u-list-std">
                                    <a-list-item
                                        @click="confirmDeleteMilestone === item.id ? localDeleteMilestone() : localGetDeleteConfirm('milestone', item.id)"
                                        :class="[{ 'c-delete-confirm': confirmDeleteMilestone === item.id }]"
                                    >
                                        <a-list-item-icon>
                                            <a-icon class="mr-3" size="20" color="grey darken-2">delete</a-icon>
                                        </a-list-item-icon>
                                        <a-list-item-content>{{ confirmDeleteMilestone === item.id ? 'Confirm Delete?' : 'Delete Milestone' }}</a-list-item-content>
                                    </a-list-item>
                                </a-list>
                            </a-menu>
                        </template>
                    </template>
                    <template #wrapExtras="{ item }">
                        <div class="u-flex-center-y">
                            <div>
                                <PartialSimpleAssigneeDropdown
                                    :list="collaboratorsList"
                                    :item="item"
                                    :searchFn="localSearchCollabs"
                                    :isAddedFn="localIsAssigneeAdded"
                                    :disabled="!localHasPermissionTo('update') || localBulkMode"
                                    :canUpdate="localHasPermissionTo('update')"
                                    :projectId="localProjectId"
                                    @active="val => val ? localSearchCollabs() : ''"
                                    @select="localUpdateMilestone($event, item, 'assignees')"
                                    avatarSize="26"
                                    fontSize="10"
                                    min-width="300"
                                    placeholderType="Select Assignees"
                                    content-class="u-z16 u-rounded-corners-lg"
                                    closeOnClick left
                                />
                            </div>
                            <a-icon size="6" color="grey" class="mr-3">fiber_manual_record</a-icon>
                                <!-- :disabled="!(localHasPermission('projects.update-only') && $can('projects-start-end-dates.destroy'))"  -->
                                <!-- @update="localUpdateDates" -->
                            <SDatePicker
                                :item="item"
                                :disabled="!localHasPermissionTo('update') || localBulkMode"
                                @update="localHasPermissionTo('update') ? localUpdateMilestone($event, item, 'date') : {}"
                                titlePosition="left"
                            >
                                <p class="md-caption grey--text text--darken-1 mb-0 text-right" v-if="item.start_date && item.due_date">
                                    <g-moment class="md-caption" :value="item.start_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment> –
                                    <g-moment class="md-caption" :value="item.due_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                </p>
                                <p class="md-caption grey--text text--darken-1 mb-0 text-right" v-if="item.start_date && !item.due_date">
                                    <g-moment class="md-caption" :value="item.start_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment> –
                                    No Due Date
                                </p>
                                <p class="md-caption grey--text text--darken-1 mb-0 text-right" v-if="!item.start_date && item.due_date">
                                    Due on <g-moment class="md-caption" :value="item.due_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                </p>
                                <p class="md-caption grey--text text--darken-1 mb-0 text-right" v-if="!item.start_date && !item.due_date">
                                    <a-icon size="16" color="grey" class="mr-1 u-icon-nudge">event</a-icon>
                                    No Due Date
                                </p>
                            </SDatePicker>
                        </div>
                    </template>
                    <template #itemIcon="{ handle }" v-if="localHasPermissionTo('update') && !localBulkMode">
                        <a-icon class="c-task-drag-handle" size="20" :class="[handle]" color="grey darken-1">drag_indicator</a-icon>
                    </template>
                    <template #cellDisplay="{ item, colSlug, column, switchDisplay }">
                        <div
                            @click="switchDisplay"
                            class="u-flex align-center"
                            :style="[{ width: column && (column.minWidth - (column && column._order !== 0 ? 0 : 52) + 'px') }]"
                        >
                            <template v-if="colSlug === 'title'">
                                <p class="text-truncate md-subtitle-1 ma-0" :title="localGetCellValue(item, colSlug, column)">{{ localGetCellValue(item, colSlug, column) || '-' }}</p>
                            </template>
                            <template v-if="colSlug === 'id'">
                                <div v-clipboard:copy="item.id" v-clipboard:success="localIdCopy" class="px-3 u-flex align-center u-rounded-corners u-wfull md-subtitle-2 elevation-0">
                                    <p :id="item.id + 'id'" class="u-wfull md-subtitle-1 ma-0 mr-3 py-1 u-flex grey--text text--darken-2">
                                        {{ item.id }}
                                    </p>
                                    <a-icon size="16" color="grey darken-1">content_copy</a-icon>
                                </div>
                            </template>
                            <template v-if="colSlug === 'visibility'">
                                <PartialVisibilityDisplay class="px-3" :visibility="localGetCellValue(item, colSlug, column)" />
                            </template>
                            <template v-if="colSlug === 'comments_count'">
                                <div class="u-flex-center-y px-3">
                                    <a-icon size="20" color="grey lighten-1">chat_bubble</a-icon>
                                    <p class="text-truncate ma-0 md-subtitle-1 ml-1">{{ localGetCellValue(item, colSlug, column) || '0' }}</p>
                                </div>
                            </template>
                            <template v-if="colSlug === 'attachments_count'">
                                <div class="u-flex-center-y px-3">
                                    <g-attachment-count-info
                                        :count="localGetCellValue(item, colSlug, column)"
                                        class="ml-3"
                                        fontSize="14"
                                        iconSize="18"
                                        customColor="grey--text text--darken-3"
                                    />
                                </div>
                            </template>
                            <template v-if="colSlug === 'estimated_duration_text'">
                                <div class="u-flex-center-y text-truncate u-wfull u-hfull px-3" @click="localOpenTimeTrack(item)">
                                    <a-tooltip bottom offset-y content-class="c-tooltip-pointer c-tooltip-pointer--right">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="u-flex-center-y text-truncate">
                                                <a-icon size="20" color="grey darken-1" class="mr-1" outlined>timer</a-icon>
                                                <span class="md-body-2 md-subtitle-1 font-weight-medium u-wfull u-hfull d-block text-truncate">
                                                    {{ item.time_records_sum_duration_minutes ? localGetTimeEstText(item.time_records_sum_duration_minutes) : '0m' }}
                                                    <template v-if="localGetCellValue(item, colSlug, column)">
                                                        / {{ localGetCellValue(item, colSlug, column) }}
                                                    </template>
                                                </span>
                                            </div>
                                        </template>
                                        <span>
                                            {{ item.time_records_sum_duration_minutes ? localGetTimeEstText(item.time_records_sum_duration_minutes) : '0m' }}
                                            <template v-if="localGetCellValue(item, colSlug, column)">
                                                / {{ localGetCellValue(item, colSlug, column) }}
                                            </template>
                                        </span>
                                    </a-tooltip>
                                </div>
                            </template>
                            <template v-if="colSlug === 'description_json'">
                                <a-menu v-if="item.description_json" :close-on-content-click="false" open-on-hover content-class="u-elevation-custom-1 c-border u-z12" min-width="300" max-width="600" max-height="400" nudge-left="20" offset-x top>
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="blue--text text--darken-1 md-subtitle-2 font-weight-medium u-rounded-corners px-3 u-wfull u-hfull u-flex-center-y">
                                            View Description
                                        </div>
                                    </template>
                                    <a-card class="u-rounded-corners" style="z-index: 12">
                                        <div class="pt-4 white" style="position: sticky; top: 0">
                                            <div class="u-flex align-center px-4">
                                                <h2 class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase">Description</h2>
                                            </div>
                                            <a-divider class="grey lighten-2 mt-2"></a-divider>
                                        </div>
                                        <g-editor-box
                                            v-model="item.description_json"
                                            :can-update="false"
                                            :customMaxHeight="250"
                                            customMinHeight="auto"
                                            dense hardRefresh hideFooter hideFocus hideBorder hideCloseBtn hideDivider
                                        ></g-editor-box>
                                    </a-card>
                                </a-menu>
                                <p class="grey--text text--darken-1 u-flex-center ma-0 px-3" v-else> - </p>
                            </template>
                            <template v-if="dateFieldSlugs.includes(colSlug)">
                                <p class="text-truncate ma-0 md-subtitle-1 px-3" :class="[{ 'red--text text--darken-2': colSlug === 'due_date' && localDateIsBefore(item.due_date, item.status) }]">
                                    {{ localFormatValue(localGetCellValue(item, colSlug, column), colSlug, item) || '-' }}
                                </p>
                            </template>
                            <template v-if="colSlug === 'milestone_id'">
                                <p class="text-truncate md-subtitle-1 ma-0 px-3">{{ localFormatValue(localGetCellValue(item, colSlug, column), colSlug, item, 'title') || '-' }}</p>
                            </template>
                            <template v-if="colSlug === 'status_id'">
                                <GNonActiveInfo
                                    :project="project_item"
                                    :stage="localProjectStatus"
                                    :disabled="!localHasPermissionTo('update') || ((localHasPermissionTo('update') && localProjectStatus === 'active'))"
                                    contentClass="u-z16"
                                    class="px-3 u-wfull u-hfull u-flex-center-y"
                                >
                                    <div v-if="item.status" class="u-flex-center-y u-wfull u-hfull">
                                        <g-task-meter :value="item.status.percentage" :color="item.status.color" />
                                        <p class="ma-0 md-subtitle-1 grey--text text--darken-2 ml-3">{{ item.status.value }}</p>
                                    </div>
                                    <span v-else> - </span>
                                </GNonActiveInfo>
                                <!-- <p class="text-capitalize text-truncate ma-0">{{ localFormatValue(localGetCellValue(item, colSlug, column), colSlug, item, 'value') || '-' }}</p> -->
                            </template>
                            <template v-if="colSlug === 'priority'">
                                <div class="u-flex-center-y px-3">
                                    <a-icon v-if="localGetPriority('value', item.priority)" :size="localGetPriority('iconSize', item.priority)" :color="localGetPriority('iconColor', item.priority)">
                                        {{ localGetPriority('icon', item.priority) }}
                                    </a-icon>
                                    <p
                                        :class="[
                                            'ma-0 text-truncate text-uppercase font-weight-medium md-subtitle-1',
                                            localGetPriority('value', item.priority) ? 'ml-3' : 'ml-2',
                                            localGetPriority('textColor', item.priority)
                                        ]
                                    ">
                                        {{ localGetPriority('value', item.priority) ? localGetPriority('label', item.priority) : '-' }}
                                    </p>
                                </div>
                            </template>
                            <template v-if="colSlug === 'assignees'">
                                <a-sheet v-if="!item.assignees || !item.assignees.length" color="grey" class="u-rounded-corners-full u-flex-center mx-3" width="32" height="32">
                                    <a-icon color="white" size="16">{{ localHasPermissionTo('update') ? 'group_add' : 'people_alt' }}</a-icon>
                                </a-sheet>
                                <SUsersDeck v-else :list="item.assignees" :avatar-size="32" :count="2" class="px-3" />
                            </template>
                        </div>
                    </template>
                    <template #cellItem="{ item, colSlug, isActive, column, isNoMilestone }" v-if="localHasPermissionTo('update')">
                        <!-- dropdownCols: 'priority', 'status_id', 'milestone_id' -->
                        <div v-if="dropdownCols.includes(colSlug)" class="u-relative u-wfull u-hfull u-flex align-stretch u-hfull" :id="`${colSlug}-${item.id}`">
                            <SDropdown
                                :value="item[colSlug]"
                                :forceOpen="isActive"
                                :grouped="colSlug === 'status_id'"
                                :list="localGetProp(colSlug, 'list')"
                                :minWidth="column && column.minWidth"
                                :attachId="`attach-${colSlug}-${item.id}`"
                                :item-text="localGetProp(colSlug, 'itemText')"
                                :item-value="localGetProp(colSlug, 'itemValue')"
                                :groupedList="localGetProp(colSlug, 'groupedList')"
                                :dropdownWidth="localGetProp(colSlug, 'dropdownWidth')"
                                :placeholderType="localGetProp(colSlug, 'placeholder')"
                                :closeOnClick="!!bulkItems.length"
                                :closeOnContentClick="!!bulkItems.length"
                                @select="localSelectItem($event, item, colSlug, isNoMilestone)"
                                @openDropdown="({ clearLoading }) => clearLoading()"
                                class="u-hfull"
                                noLoading manualMode
                            >
                                <template #item="{ item: selectedItem, isSelected }">
                                    <template v-if="colSlug === 'milestone_id'">
                                        <p :class="[isSelected ? 'indigo--text text--darken-3' : 'grey--text text--darken-2']" class="text-truncate ma-0 md-subtitle-1">
                                            {{ selectedItem[localGetProp(colSlug, 'itemText')] }}
                                        </p>
                                    </template>
                                    <template v-if="colSlug === 'status_id'">
                                        <div class="u-flex-center-y">
                                            <a-icon size="20" :color="$color(localGetStatus('color', selectedItem.id), 'color_bg')">fiber_manual_record</a-icon>
                                            <p :class="['ma-0 ml-3 text-truncate md-subtitle-1 grey--text text--darken-2']">
                                                {{ localGetStatus('value', selectedItem.id) }}
                                            </p>
                                            <a-spacer></a-spacer>
                                            <span :class="['md-subtitle-2 grey--text text--darken-2']">{{ localGetStatus('percentage', selectedItem.id) }}%</span>
                                        </div>
                                    </template>
                                    <template v-if="colSlug === 'priority'">
                                        <div class="u-flex-center-y">
                                            <a-icon :size="localGetPriority('iconSize', selectedItem.value)" :color="localGetPriority('iconColor', selectedItem.value)">
                                                {{ localGetPriority('icon', selectedItem.value) }}
                                            </a-icon>
                                            <p :class="['ma-0 ml-3 text-truncate text-uppercase font-weight-medium md-subtitle-1', localGetPriority('textColor', selectedItem.value)]"
                                            >
                                                <!-- { 'text-uppercase font-weight-medium md-subtitle-1': localGetPriority('value', selectedItem.value) !== 'no-priority' }, -->
                                                {{ localGetPriority('label', selectedItem.value) }}
                                            </p>
                                        </div>
                                    </template>
                                </template>
                            </SDropdown>
                        </div>
                        <template v-else-if="dateFieldSlugs.includes(colSlug)">
                            <div v-outside-click class="u-relative u-wfull white">
                                <div @click="localFocusOut()" class="md-subtitle-1 u-wfull u-hfull u-flex-center-y px-3 grey--text text--darken-2">
                                    {{ item[colSlug] ? localFormatDate(item[colSlug]) : '' }}
                                </div>
                                <div class="u-absolute u-wfull u-rounded-corners-lg" style="left: 0px; top: 56px; z-index: 16; min-width: 252px;">
                                    <vc-date-picker
                                        v-model="dateModels[`${item.id}/${colSlug}`]"
                                        :min-date="colSlug === 'due_date' ? item['start_date'] : null"
                                        :max-date="colSlug === 'start_date' ? item['due_date'] : null"
                                        class="d-block u-wfull u-shadow-md"
                                        @input="localUpdateDate(item, colSlug, isNoMilestone)"
                                        :model-config="modelConfig"
                                    />
                                </div>
                            </div>
                        </template>
                        <template v-else-if="colSlug === 'assignees'">
                            <PartialAssigneeDropdown
                                :forceOpen="isActive"
                                :list="collaboratorsList"
                                :minWidth="column && column.minWidth"
                                :attachId="`attach-${colSlug}-${item.id}`"
                                :dropdownWidth="300"
                                :dropdownHeight="350"
                                :close-on-click="false"
                                :grouped="false"
                                :item="item"
                                :isActive="isActive"
                                placeholderType="Select Assignees"
                                :searchFn="localSearchCollabs"
                                :isAddedFn="localCheckAssigneeAdded"
                                :hideSelectionIcon="localBulkMode"
                                @openDropdown="({ clearLoading }) => clearLoading()"
                                @select="localSelectItem($event, item, colSlug, isNoMilestone)"
                                class="u-hfull"
                                noLoading manualMode hideArrow
                            />
                        </template>
                        <template v-else-if="colSlug === 'visibility'">
                            <SDropdown
                                :forceOpen="isActive"
                                :minWidth="column && column.minWidth"
                                :attachId="`attach-${colSlug}-${item.id}`"
                                :dropdownWidth="300"
                                :dropdownHeight="350"
                                :closeOnClick="false"
                                @openDropdown="({ clearLoading }) => clearLoading()"
                                @select="localSelectItem($event, item, colSlug, isNoMilestone)"
                                :grouped="false"
                                class="u-hfull"
                                noLoading manualMode hideSearch
                            >
                                <template #input-placeholder>
                                    <PartialVisibilityDisplay
                                        :visibility="localGetCellValue(item, colSlug, column)"
                                        class="u-hfull px-3 white"
                                    />
                                </template>
                                <template #menu-list="{ selectItem }">
                                    <div class="u-hfull">
                                        <div class="u-flex-center-y py-3 px-4">
                                            <a-icon color="indigo darken-2" size="18">visibility</a-icon>
                                            <h5 class="md-subtitle-2 indigo--text text--darken-2 ml-2">Task Visibility</h5>
                                        </div>
                                        <a-divider></a-divider>
                                    </div>
                                    <PartialVisibilityToggleSection :action="selectItem" :item="item" />
                                </template>
                            </SDropdown>
                        </template>
                        <template v-else-if="colSlug === 'title'">
                            <input
                                type="text"
                                :ref="`refInput-${item.id}`"
                                class="u-wfull c-autofocus white u-hfull"
                                :value="item.title"
                                @blur="localBlurTaskTitle($event, item, colSlug, isNoMilestone)"
                                @keydown.enter="localBlurTaskTitle($event, item, colSlug, isNoMilestone)"
                                @keydown.escape="localFocusOut({ item })"
                                @input="localUpdateTitle($event, item, colSlug, isNoMilestone)"
                            >
                        </template>
                        <span v-else>{{ (item[colSlug] || '-') }}</span>
                    </template>
                </PartialTable>
            </template>
        </template>

        <a-dialog v-model="dialogEstList" scrollable persistent max-width="700">
            <template v-if="timeTrackTaskItem">
                <s-time-tracker
                    :item="timeTrackTaskItem"
                    :records="time_record_list"
                    :index-loading="recordIndexLoading"
                    :can-update="localHasPermissionTo('update')"
                    :title="timeTrackTaskItem ? timeTrackTaskItem.title : ''"
                    :status="timeTrackTaskItem.status"
                    @add-entry="localAddTimeEntry"
                    @delete-entry="localDeleteTimeEntry"
                    @close="localCloseTimeTrack"
                >
                    <template v-slot:record-progress>
                        <s-time-progress
                            :limit="700"
                            :item="timeTrackTaskItem"
                            :can-update="localHasPermissionTo('update')"
                            :est-text="timeTrackTaskItem.estimated_duration_text"
                            :est-mins="timeTrackTaskItem.estimated_duration_minutes"
                            :sum-mins="timeTrackTaskItem.time_records_sum_duration_minutes"
                            @est-update="localEstUpdate"
                            class="pb-2"
                            allow-est-edit
                        ></s-time-progress>
                    </template>
                </s-time-tracker>
            </template>
        </a-dialog>

        <a-dialog v-model="dialogTaskEdit" max-width="1080" :persistent="localAttachmentLoading">
            <ModalTaskEdit
                :item="currentEditItem"
                :milestones="localMilestoneMenuList"
                :collaborators="collaboratorsList"
                :isOpen="dialogTaskEdit"
                :canUpdate="localHasPermissionTo('update')"
                :canDelete="localHasPermissionTo('destroy')"
                :canComment="$can('tasks.comment')"
                :previousTaskId="previousTaskId"
                :nextTaskId="nextTaskId"
                :taskMetaList="taskMetaList"
                :project="project_item"
                @attachmentLoading="localSetLoadingStatus"
                @navigate="localNavigateFromDetailView"
                @select="localSelectItem"
                @descriptionUpdate="localUpdateDescription"
                @delete="localDeleteTask"
                @openTimetrack="localOpenTimeTrack"
                @close="localCloseTaskDialogEdit"
                @commentOps="localTaskFieldsUpdate"
            />
        </a-dialog>

        <a-dialog v-model="dialogKeyboardShortcuts" max-width="700" scrollable>
            <ModalTaskKeyboardShortcuts
                @close="dialogKeyboardShortcuts = false"
            />
        </a-dialog>

        <a-dialog v-model="dialogExportForm" max-width="600" persistent>
            <ModalExportAsTemplate
                :isOpen="dialogExportForm"
                @close="dialogExportForm = false"
            />
        </a-dialog>

        <a-dialog v-model="dialogImportForm" max-width="600" persistent>
            <ModalImportFromTemplate
                :isOpen="dialogImportForm"
                @refresh="localIndex()"
                @close="dialogImportForm = false"
            />
        </a-dialog>

        <!-- User Upgrade Popup -->
        <a-dialog max-width="400" v-model="dialogUserUpgradeModal">
            <SUserUpgradeModal @close="dialogUserUpgradeModal = false" />
        </a-dialog>

        <!-- Admin Upgrade Popup -->
        <a-dialog max-width="600" v-model="dialogAdminUpgradeModal">
            <SAdminUpgradeModal
                :isOpen="dialogAdminUpgradeModal"
                @upgrade="{}"
                @close="dialogAdminUpgradeModal = false"
            >
                <template #plan-info-text>
                    Your current <span class="secondary--text font-weight-bold">Starter</span> plan is limited to {{ $plan('active_projects_limit') }} active projects.
                </template>
            </SAdminUpgradeModal>
        </a-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { calculateByMins, calculateByText } from '@/helpers/helper-time-tracker.js'
import { storage } from '@/helpers/helper-local-storage';
import SDropdown from '@/components/Shared/SharedDropdown.vue'
import SDatePicker from '@/components/Shared/SharedDatePicker.vue'
import PartialTable from './Partials/PartialTable.vue'
import PartialMagicTable from './Partials/PartialMagicTable.vue'
import PartialIslandSwitcher from './Partials/PartialIslandSwitcher.vue'
import PartialIslandActions from './Partials/PartialIslandActions.vue'
import PartialIslandMenu from './Partials/PartialIslandMenu.vue'
import PartialTaskAddMenu from './Partials/PartialTaskAddMenu.vue'
import PartialAssigneeDropdown from './Partials/PartialAssigneeDropdown.vue'
import PartialSimpleAssigneeDropdown from './Partials/PartialSimpleAssigneeDropdown.vue'
import PartialVisibilityToggleSection from './Partials/PartialVisibilityToggleSection.vue'
import PartialVisibilityDisplay from './Partials/PartialVisibilityDisplay.vue'
import PartialActionButton from './Partials/PartialActionButton.vue'
import PartialShortcutKey from './Partials/PartialShortcutKey.vue'
import ModalTaskEdit from './Modals/ModalTaskEdit.vue'
import ModalExportAsTemplate from './Modals/ModalExportAsTemplate.vue'
import ModalImportFromTemplate from './Modals/ModalImportFromTemplate.vue'
import ModalTaskKeyboardShortcuts from './Modals/ModalTaskKeyboardShortcuts.vue'
import { SAssigneeDropdown, STimeTracker, STimeProgress, SUsersDeck } from '@/config/config-shared-components'
import mixinSearchCollaborators from '@/mixins/mixin-search-collaborators'
import PartialProjectTaskLoader from './Partials/PartialProjectTaskLoader'
import { Base64 } from 'js-base64'

export default {
    mixins: [
        mixinSearchCollaborators
    ],

    components: {
        SDropdown,
        SUsersDeck,
        SDatePicker,
        PartialTable,
        STimeTracker,
        ModalTaskEdit,
        STimeProgress,
        PartialMagicTable,
        SAssigneeDropdown,
        PartialIslandMenu,
        PartialShortcutKey,
        PartialTaskAddMenu,
        PartialActionButton,
        PartialIslandActions,
        ModalExportAsTemplate,
        PartialIslandSwitcher,
        PartialAssigneeDropdown,
        ModalImportFromTemplate,
        PartialProjectTaskLoader,
        PartialVisibilityDisplay,
        ModalTaskKeyboardShortcuts,
        PartialSimpleAssigneeDropdown,
        PartialVisibilityToggleSection,
    },

    data () {
        return {
            pageLoading: true,
            dateModels: {},
            titleModels: {},
            currentSelectedRow: {},
            hasTitleError: false,
            dialogExportForm: false,
            dialogImportForm: false,
            dialogKeyboardShortcuts: false,
            dialogUserUpgradeModal: false,
            dialogAdminUpgradeModal: false,
            exportMode: 'new',
            previousTaskId: null,
            nextTaskId: null,
            isTitleEdited: false,
            taskStatusFilter: { text: 'All Tasks', value: 'all' },
            taskStatusFilterList: [
                { text: 'All Tasks', value: 'all', keyboard: 'A' },
                { text: 'Completed Tasks', value: 'completed', keyboard: 'C' },
                { text: 'Incomplete Tasks', value: 'incomplete', keyboard: 'I' },
            ],
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
            milestoneColumnsList: [
                { label: 'Milestone', slug: 'title', type: 'string' },
                { label: 'Assignees', slug: 'assignees', type: 'array' },
                { label: 'Start Date', slug: 'start_date', type: 'date' },
                { label: 'Due Date', slug: 'due_date', type: 'date' },
                { label: 'Tasks Count', slug: 'tasks_count', type: 'string' },
            ],
            columnsList: [
                { label: 'Title', slug: 'title', order: 1, type: 'string', minWidth: 500, selected: true },
                { label: 'Milestone', slug: 'milestone_id', order: 2, type: 'string', minWidth: 200, selected: true },
                { label: 'Description', slug: 'description_json', order: 3, type: 'string', minWidth: 200, selected: true },
                { label: 'ID', slug: 'id', order: 4, type: 'string', minWidth: 390, selected: true },
                { label: 'Visibility', slug: 'visibility', order: 5, type: 'string', minWidth: 200, selected: true },
                { label: 'Priority', slug: 'priority', order: 6, type: 'string', minWidth: 200, selected: true },
                { label: 'Status', slug: 'status_id', order: 7, type: 'string', minWidth: 200, selected: true },
                { label: 'Start Date', slug: 'start_date', order: 8, type: 'date', minWidth: 200, selected: true },
                { label: 'Due Date', slug: 'due_date', order: 9, type: 'date', minWidth: 200, selected: true },
                { label: 'Assignees', slug: 'assignees', order: 10, type: 'array', minWidth: 200, selected: true },
                { label: 'Est. Duration', slug: 'estimated_duration_text', order: 11, type: 'string', minWidth: 200, selected: true },
                { label: 'Comments', slug: 'comments_count', order: 12, type: 'string', minWidth: 200, selected: true },
                { label: 'Attachments', slug: 'attachments_count', order: 13, type: 'string', minWidth: 200, selected: true },
            ],
            prefObject: {
                view: null,
                status: null,
                isExpandAll: null,
                columns: []
            },
            priorityList: [
                { id: 'high', label: 'High', value: 'high', textColor: 'deep-orange--text text--darken-2', icon: 'keyboard_double_arrow_up', iconColor: 'deep-orange darken-2', iconSize: 24 },
                { id: 'medium', label: 'Medium', value: 'medium', textColor: 'amber--text text--darken-2', icon: 'drag_handle', iconColor: 'amber darken-2', iconSize: 24 },
                { id: 'low', label: 'Low', value: 'low', textColor: 'light-blue--text', icon: 'keyboard_double_arrow_down', iconColor: 'light-blue', iconSize: 24 },
                { id: 'no-priority', label: 'No Priority', value: null, textColor: 'grey--text text--darken-2', icon: 'close', iconColor: 'grey darken-2', iconSize: 24 },
            ],
            dropdownCols: ['priority', 'status_id', 'milestone_id'],
            dateFieldSlugs: ['start_date', 'due_date'],
            collaboratorsList: [],
            modelCollabSearch: '',
            searchCollabTimeout: null,
            timeTrackTaskItem: null,
            dialogEstList: false,
            recordIndexLoading: false,
            localTaskItem: {},
            bulkItems: [],
            clearAll: null,
            taskMetaList: [],
            clonedTasks: [],
            clonedMilestones: [],
            titleSaveTimeout: null,
            showBulkDelConfirmation: false,
            singleMilestoneEdit: null,
            singleTaskEdit: null,
            currentBulkEditModule: 'task',
            currentViewMode: null,
            taskRanges: {},
            milestoneTitleEditId: null,
            milestoneTitles: {},
            milestoneTitleTimeout: null,
            isExpandAll: true,
            isSelectAll: false,
            confirmDeleteMilestone: null,
            modelMilestoneDelete: {},
            currentEditItem: {},
            dialogTaskEdit: false,
            currentPreference: null,
            taskPrefKey: 'user.projects.tasks.options',
            localAttachmentLoading: false
        }
    },

    watch: {
        prefObject: {
            handler (val) {
                this.localTaskPrefUpdate(val)
            },
            deep: true
        }
    },

    mounted () {
        document.addEventListener('keydown', this.localKeyDown)
        this.localIndex()
    },

    beforeDestroy () {
        document.removeEventListener('keydown', this.localKeyDown)
    },

    computed: {
        localTaskMetaStatuses () {
            return [...new Set(this.taskMetaList.map(i => i.status))]
        },

        localMilestoneMenuList () {
            const list = this.milestone_list.map(i => ({ id: i.id, title: i.title }))
            list.push({ id: 'no-milestone', title: 'No Milestone' })
            return list
        },

        localGroupedMetaList () {
            const types = [...new Set(this.taskMetaList.map(i => i.status))]
            const result = []
            types.forEach(item => {
                result.push({ type: item, list: this.taskMetaList.filter(({ status }) => status === item) })
            })

            return result
        },

        localProjectId () {
            return this.$route.params.id
        },

        localFetchAllTasks () {
            const list = this.milestone_list.map(milestone => milestone.tasks)
                .reduce((prev, current) => {
                    prev = [...prev, ...current];
                    return prev
                }, [])

            return [...list, ...this.task_list]
        },

        localHasListData () {
            if (this.currentBulkEditModule === 'task') {
                return !!(_.size(this.localFetchAllTasks) > 0)
            }
            return !!(_.size(this.milestone_list) > 0)
        },

        localMilestones () {
            if (['select-all-milestones', 'milestone'].includes(this.currentViewMode)) {
                const list = _.cloneDeep(this.milestone_list)
                return list
            }

            const noMilestoneTasks = { id: 'no-milestone', title: 'No Milestone', tasks: this.task_list }
            const list = [..._.cloneDeep(this.milestone_list), noMilestoneTasks]
            if (this.taskStatusFilter.value === 'all') return [...this.milestone_list, noMilestoneTasks]
            return list.map(milestone => {
                milestone['tasks'] = milestone.tasks.filter(task => {
                    if (this.taskStatusFilter.value === 'completed' && task.status.status === 'Completed') {
                        return task
                    }
                    if (this.taskStatusFilter.value !== 'completed' && task.status.status !== 'Completed') {
                        return task
                    }
                })
                return milestone
            })
        },

        localBulkMode () {
            return !!_.size(this.bulkItems)
        },

        localDataIsEmpty () {
            return !_.size(this.localMilestones) && !_.size(this.task_list)
        },

        localProjectStatus () {
            return this.project_item && this.project_item.status
        },

        localAvoidValueToggle () {
            const list = []
            if (this.localHasPermissionTo('update') && this.localProjectStatus !== 'active') {
                list.push('status_id')
            }
            return list
        },

        localNoDirectEditCols () {
            return ['description_json', 'comments_count', 'id', 'estimated_duration_text', 'attachments_count']
        },

        localGetSelectedMilestoneIds () {
            return [...new Set(this.bulkItems.map(i => i.milestone_id).filter(i => !!i))]
        },

        ...mapState('TimeRecord', {
            time_record_list: 'list',
            time_record_response: 'response',
        }),

        ...mapState('Meta', {
            meta_list: 'list'
        }),

        ...mapState('ProjectView', {
            project_item: 'item'
        }),

        ...mapState('Preference', {
            preference_list: 'list'
        }),

        ...mapState('Task', {
            task_list: 'list',
            task_item: 'item'
        }),

        ...mapState('Milestone', {
            milestone_list: 'list'
        }),

        ...mapState('Collaborator', {
            collaborator_list: 'list'
        }),

        ...mapState('TemplateTask', {
            template_task_item: 'item',
            template_task_response: 'response',
        }),

        ...mapState('User', {
            user_self: 'self'
        }),
    },

    methods: {
        async localIndex () {
            if (this.$can('tasks.index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            this.pageLoading = true
            await this.localFetchPreference()
            await this.localFetchMilestoneAndTasks()
            await this.localFetchTaskMeta()
            this.localGetCollabs()

            this.pageLoading = false
        },

        async localFetchPreference () {
            const params = {
                id: uuidv4(),
                key: this.taskPrefKey,
                resource_id: this.user_self.id,
                resource_type: 'User',
                label: 'User Project Tasks Options',
            }

            await this.localFetchAllPrefs()
            const taskPref = this.preference_list.find(i => i.key === this.taskPrefKey)
            if (taskPref) {
                this.currentPreference = _.cloneDeep(taskPref)
                return this.localSetPrefProps(taskPref)
            }

            params['value'] = JSON.stringify({ view: 'task', status: 'all', isExpandAll: true, columns: this.columnsList })
            this.preference_upsert(params)
            this.currentPreference = params
            this.localSetPrefProps(params)
        },

        localSetPrefProps (preference) {
            const { view, status, isExpandAll, columns } = JSON.parse(preference.value) || {}
            const taskStatus = this.taskStatusFilterList.find(i => i.value === status)
            this.currentViewMode = view
            this.currentBulkEditModule = view
            this.taskStatusFilter = taskStatus
            this.isExpandAll = isExpandAll
            const list = _.cloneDeep(this.columnsList).map(col => {
                const storedPrefIndex = columns.findIndex(i => i.slug === col.slug)
                if (storedPrefIndex === -1) return col
                col = { ...col, ...columns[storedPrefIndex] }
                return col
            })
            this.columnsList = _.orderBy(list, 'order', 'asc')
        },

        localTaskPrefUpdate (val) {
            const clonedVal = _.cloneDeep(val)
            if (!clonedVal.view) clonedVal['view'] = 'task'
            if (!clonedVal.status) clonedVal['status'] = 'all'
            if (clonedVal.isExpandAll === null) clonedVal['isExpandAll'] = true

            this.currentPreference.value = JSON.stringify({ ...clonedVal, columns: this.columnsList })
            this.preference_upsert(this.currentPreference)
        },

        async localFetchMilestoneAndTasks () {
            await this.localFetchMilestones()
            await this.localFetchTasks()

            // TODO: v2 -> Virtual scroller to improve performance
            // this.localUpdateTaskRanges()
        },

        // TODO: v2 -> Virtual scroller to improve performance
        localUpdateTaskRanges () {
            // if (_.size(this.milestone_list)) {
            //     this.milestone_list.forEach((milestone, index) => {
            //         this.taskRanges[milestone.id] = { startIndex: 0, endIndex: index < 2 ? 10 : 10 }
            //     })
            // }

            // if (!_.size(this.milestone_list) || _.size(this.task_list)) {
            //     this.taskRanges['no-milestone'] = { startIndex: 0, endIndex: 10 }
            // }
        },

        async localSearchCollabs (evt, props = {}) {
            let timeout = 300
            clearTimeout(this.searchCollabTimeout)

            const { immediate = false } = props
            const value = !evt ? '' : evt.target.value
            if (immediate || !_.trim(value)) timeout = 0

            this.searchCollabTimeout = setTimeout(async () => {
                const params = { 'filter[project_id]': this.$route.params.id, 'filter[user]': value, count: 1000, page: 1 }
                await this.collaborator_index({ ...params, include: 'user.role,type,contact' })
                this.localGetCollabs()
                clearTimeout(this.searchCollabTimeout)
            }, timeout);
        },

        async localFetchMilestones () {
            await this.milestone_index({
                'filter[project_id]': this.$route.params.id,
                'include': 'tasksCount,tasksCompletedCount,assignees.collaborator.type,tasks.status,tasks.assignees.collaborator.type',
                'aggregate[tasks.time_records.duration_minutes]': 'sum',
                'fields[milestones]': 'id,title,completion_percentage,due_date,order,project_id,start_date,template_group_id,visibility',
                'fields[tasks]': 'id,title,milestone_id,description_json,priority,start_date,due_date,status_id,estimated_duration_text,estimated_duration_minutes,visibility,order,project_id,template_group_id',
                'fields[tasks.status]': 'id,status,value,color,percentage',
                'fields[tasks.assignees.collaborator]': 'id,user_id,project_id,scope,group,type_id',
                'fields[tasks.assignees.collaborator.type]': 'id,type,value,status,color,is_active,is_starred',
                withTaskCommentsCount: 1,
                withTaskAttachmentsCount: 1,
                count: 5000,
                sort: 'order'
            })
            this.clonedMilestones = _.cloneDeep(this.milestone_list)
        },

        async localFetchTasks () {
            await this.task_index({
                'include': 'assignees.collaborator.type,status,assignees.collaborator.type,commentsCount,attachmentsCount',
                'fields[tasks]': 'id,title,milestone_id,description_json,priority,start_date,due_date,status_id,estimated_duration_text,estimated_duration_minutes,visibility,order,project_id,template_group_id',
                'fields[status]': 'id,status,value,color,percentage',
                'filter[project_id]': this.$route.params.id,
                'aggregate[time_records.duration_minutes]': 'sum',
                'filter[milestone_id]': 'none',
                sort: 'order',
                count: 5000
            })
            this.clonedTasks = _.cloneDeep(this.task_list)
            this.localSetupDatesInModel()
        },

        localSetupDatesInModel () {
            this.milestone_list.forEach(milestone => {
                milestone.tasks.forEach(item => {
                    this.$set(this.dateModels, `${item.id}/start_date`, item.start_date)
                    this.$set(this.dateModels, `${item.id}/due_date`, item.due_date)
                })
            })

            this.clonedTasks.forEach(item => {
                this.$set(this.dateModels, `${item.id}/start_date`, item.start_date)
                this.$set(this.dateModels, `${item.id}/due_date`, item.due_date)
            })
        },

        async localFetchTaskMeta () {
            await this.meta_index({
                'filter[type]': 'task_status',
                'fields[metas]': 'id,status,value,color,percentage,is_starred',
                sort: 'order'
            })
            const list = []
            const types = ['Unstarted', 'Started', 'Completed']
            const items = _.cloneDeep(this.meta_list)
            types.forEach(type => {
                items.filter(item => {
                    if (item.status === type) list.push(item)
                })
            })
            this.taskMetaList = list.map((item, index) => ({ ...item, _index: index }))
        },

        localGetCollabs () {
            this.collaboratorsList = _.cloneDeep(this.collaborator_list)
        },

        localUpdateDescription (task, props) {
            const { encodedData, value, isEmpty } = props || {}
            const item = _.cloneDeep(task)
            item['description_json'] = encodedData

            this.localTaskFieldsUpdate({ ...task, description_json: isEmpty ? null : value }, !task.milestone_id)
            this.localTaskUpdate(item, 'description_json', { isEmpty })
        },

        async localSelectItem (props, task, colSlug, isNoMilestone) {
            let list = []
            const { item, value } = props || {}
            const whiteList = ['visibility', 'status_id', 'assignees']
            const bulkList = _.cloneDeep(this.bulkItems)
            if (isNoMilestone) list = _.cloneDeep(this.task_list)
            else {
                const milestone = this.milestone_list.find(i => i.id === task.milestone_id)
                list = _.cloneDeep(milestone.tasks)
            }

            this.singleTaskEdit = list.find(i => i.id === task.id)
            if (!this.singleTaskEdit) return false

            if (colSlug === 'title') {
                const isNotNewTask = (!task._mode || (task._mode && task._mode !== 'create'))
                const clonedTask = _.cloneDeep(task)
                if (!_.trim(value) && (task._mode && task._mode === 'create')) {
                    return !isNoMilestone ? this.milestone_task_remove({ ...task }) : this.task_remove(task.id)
                }

                const { _mode } = this.singleTaskEdit || {}
                if (!_mode || (_mode && _mode !== 'create')) {
                    // if (!_.trim(value) || (_.trim(this.singleTaskEdit.title) === _.trim(value))) return
                    if (!_.trim(value)) return
                    this.singleTaskEdit = { ...this.singleTaskEdit, title: isNotNewTask && (!_.trim(value)) ? clonedTask.title : value }
                    if (this.dialogTaskEdit) this.localTaskFieldsUpdate(this.singleTaskEdit, isNoMilestone)
                    if (this.isTitleEdited) {
                        this.localTaskUpdate({
                            id: this.singleTaskEdit.id,
                            title: this.singleTaskEdit.title,
                            milestone_id: isNoMilestone ? null : task.milestone_id
                        })
                    }

                    this.isTitleEdited = false
                    return true
                }

                this.singleTaskEdit = { ...this.singleTaskEdit, title: isNotNewTask && (!_.trim(value)) ? clonedTask.title : value }
                this.localRemoveModeFromNewItem(task, isNoMilestone)

                await this.localTaskBulkCreate({ list: [this.singleTaskEdit], id: this.singleTaskEdit.id, order: this.singleTaskEdit.order, type: 'single' })
                if (!isNoMilestone) this.localFetchMilestone(this.singleTaskEdit.milestone_id)
                return true
            }

            if (colSlug === 'visibility') {
                const ids = _.size(this.bulkItems) ? this.bulkItems.map(i => i.id) : [this.singleTaskEdit.id]
                this.singleTaskEdit[colSlug] = item.visibility

                if (_.size(this.bulkItems)) this.localUpdateVisibilityBulkItems(item, !this.singleTaskEdit.milestone_id)
                else this.localTaskFieldsUpdate(this.singleTaskEdit, isNoMilestone)

                await this.task_bulk_visibility({ ids, visibility: item.visibility, project_id: this.localProjectId })
                this.localFetchMilestonesAfterTaskUpdate(this.localBulkMode ? _.cloneDeep(this.bulkItems) : [_.cloneDeep(this.singleTaskEdit)])
                return true
            }

            if (colSlug === 'status_id') {
                const ids = _.size(this.bulkItems) ? this.bulkItems.map(i => i.id) : [this.singleTaskEdit.id]
                this.singleTaskEdit['status'] = item
                this.singleTaskEdit['status_id'] = item.id

                if (_.size(this.bulkItems)) this.localUpdateStatusBulkItems(item)
                else this.localTaskFieldsUpdate(this.singleTaskEdit, isNoMilestone)

                await this.task_bulk_state_update({ ids, status_id: item.id, project_id: this.localProjectId })
                this.localStatusUpdateAfterOps(item)
                return true
            }

            if (colSlug === 'assignees' && this.localBulkMode) {
                this.localBulkUpdateTaskAssignee(colSlug, item, isNoMilestone)
                this.localFetchMilestonesAfterTaskUpdate(_.cloneDeep(this.bulkItems))
                return true
            }

            if (colSlug === 'attachments_count') {
                this.singleTaskEdit[colSlug] = value
                this.localTaskFieldsUpdate(this.singleTaskEdit, isNoMilestone)
                return true
            }

            if (!_.size(this.bulkItems)) {
                this.localUpdateTasks(item, value, colSlug, task, isNoMilestone)
                if (!whiteList.includes(colSlug)) await this.localTaskUpdate(this.singleTaskEdit)
                if (colSlug === 'milestone_id') {
                    if (isNoMilestone || (item.id && item.id !== 'no-milestone')) {
                        this.localFetchMilestonesAfterTaskUpdate([{ ...this.singleTaskEdit, milestone_id: item.id }])
                    }
                }
                return true
            }

            this.bulkItems.forEach(task => {
                this.singleTaskEdit = task
                this.localUpdateTasks(item, value, colSlug, task, isNoMilestone)
            })

            if (!whiteList.includes(colSlug)) {
                this.localTaskBulkUpdate({ list: bulkList, slug: colSlug, params: props })
            }
        },

        localRemoveModeFromNewItem (task, isNoMilestone) {
            if (isNoMilestone) {
                const taskIndex = this.task_list.findIndex(i => i.id === task.id)
                delete this.task_list[taskIndex]._mode
                return true
            }

            const milestoneIndex = this.milestone_list.findIndex(i => i.id === task.milestone_id)
            const taskIndex = this.milestone_list[milestoneIndex].tasks.findIndex(i => i.id === task.id)
            delete this.milestone_list[milestoneIndex].tasks[taskIndex]._mode
        },

        localStatusUpdateAfterOps (status) {
            this.localFetchMilestonesAfterTaskUpdate(this.localBulkMode ? _.cloneDeep(this.bulkItems) : [this.singleTaskEdit])

            if (!this.localBulkMode) this.localAddSystemEntryToTaskTimeEst(status, this.singleTaskEdit)
            else this.bulkItems.forEach(taskItem => this.localAddSystemEntryToTaskTimeEst(status, taskItem))
        },

        async localAddSystemEntryToTaskTimeEst (status, task) {
            const { id, milestone_id, estimated_duration_text, time_records_sum_duration_minutes } = task || {}
            if (status.status === 'Completed' && estimated_duration_text && !time_records_sum_duration_minutes) {
                await this.localTaskShow(id, 'show')
                if (this.dialogTaskEdit) this.currentEditItem = { ...this.currentEditItem, ...this.task_item }
                this.localTaskFieldsUpdate({ ...task, ...this.task_item }, !milestone_id)
            }
        },

        localUpdateVisibilityBulkItems (item, isNoMilestone) {
            this.bulkItems.forEach(task => {
                this.singleTaskEdit = task
                this.singleTaskEdit['visibility'] = item.visibility
                this.localTaskFieldsUpdate(this.singleTaskEdit, isNoMilestone)
            })
        },

        localUpdateStatusBulkItems (item) {
            this.bulkItems.forEach(task => {
                this.singleTaskEdit = task
                this.singleTaskEdit['status'] = item
                this.singleTaskEdit['status_id'] = item.id
                this.localTaskFieldsUpdate(this.singleTaskEdit, !task.milestone_id)
            })
        },

        localGetBulkUpdateVal (props, slug) {
            const { item, value } = props || {}
            switch (slug) {
                case 'milestone_id': return item.id === 'no-milestone' ? null : item.id
                case 'priority': return item.value
                case 'start_date':
                case 'due_date': return value
            }
        },

        async localUpdateTasks (item, value, colSlug, task, isNoMilestone) {
            const whiteList = ['milestone_id', 'assignees']

            this.localUpdateTaskMilestone(colSlug, item, isNoMilestone)
            this.localUpdateTaskPriority(colSlug, item, isNoMilestone)
            this.localUpdateTaskStatus(colSlug, item, isNoMilestone)
            this.localUpdateTaskVisibility(colSlug, item, isNoMilestone)
            this.localUpdateTaskAssignee(colSlug, item, isNoMilestone)
            this.localUpdateTaskDates(colSlug, value, isNoMilestone)

            if (!whiteList.includes(colSlug)) {
                await this.localTaskFieldsUpdate(this.singleTaskEdit, isNoMilestone)
            }

            // Update milestone's visibility
            if (!isNoMilestone && ['milestone_id', 'visibility', 'assignees'].includes(colSlug)) {
                this.localFetchMilestone(task.milestone_id)
            }
        },

        async localFetchMilestone (milestoneId, timeout = 500) {
            setTimeout(async () => {
                await this.milestone_show({
                    id: milestoneId,
                    params: {
                        'include': 'tasksCount,tasksCompletedCount',
                        'filter[project_id]': this.localProjectId,
                        'fields[milestones]': 'id,project_id,visibility',
                        sort: 'order'
                    }
                })
            }, timeout)
        },

        // Tasks fields update - Start
        async localUpdateTaskMilestone (colSlug, item) {
            const clonedTaskItem = _.cloneDeep(this.singleTaskEdit)
            if (colSlug !== 'milestone_id' || clonedTaskItem.milestone_id === item.id) return

            const milestoneId = item.id === 'no-milestone' ? null : item.id
            this.singleTaskEdit['milestone_id'] = milestoneId

            if (!clonedTaskItem.milestone_id) {
                this.task_remove(clonedTaskItem.id)
                this.milestone_task_store({ milestone_id: milestoneId, data: this.singleTaskEdit })
                this.localUpdateModalTaskItem({ ...this.singleTaskEdit, milestone_id: milestoneId })

                const milestone = this.milestone_list.find(i => i.id === milestoneId)
                this.task_reorder({ list: milestone.tasks, type: 'no-local-reorder' })
                return true
            }

            if (item.id === 'no-milestone') {
                this.milestone_task_remove({ ...clonedTaskItem, milestone_id: clonedTaskItem.milestone_id })
                this.task_prepend_item({ ...this.singleTaskEdit, milestone: null, milestone_id: null })
                this.localUpdateModalTaskItem({ ...this.singleTaskEdit, milestone: null, milestone_id: null })
                this.task_reorder({ list: this.task_list, type: 'no-local-reorder' })
                return true
            }

            this.milestone_task_remove({ ...this.singleTaskEdit, milestone_id: clonedTaskItem.milestone_id })
            this.milestone_task_store({ milestone_id: milestoneId, data: this.singleTaskEdit })
            this.localUpdateModalTaskItem({ ...this.singleTaskEdit, milestone_id: milestoneId })

            const milestone = this.milestone_list.find(i => i.id === milestoneId)
            this.task_reorder({ list: milestone.tasks, type: 'no-local-reorder' })

            // this.localFetchMilestone(i)
        },

        localUpdateTaskPriority (colSlug, item) {
            if (colSlug === 'priority') this.singleTaskEdit[colSlug] = item.value
        },

        async localBulkUpdateTaskStatus (colSlug, list, item) {
            list.forEach(task => {
                this.singleTaskEdit = task
                this.localUpdateTaskStatus('status_id', item)
                if (task.milestone_id) this.milestone_task_update(this.singleTaskEdit)
                else this.task_update_list(this.singleTaskEdit)
            })
            await this.task_bulk_state_update({ ids: list.map(i => i.id), status_id: item.id, project_id: this.localProjectId })
            this.localStatusUpdateAfterOps(item)
        },

        localUpdateTaskStatus (colSlug, item) {
            if (colSlug !== 'status_id') return

            this.singleTaskEdit['status'] = item
            this.singleTaskEdit['status_id'] = item.id
        },

        async localUpdateTaskVisibility (colSlug, item) {
            if (colSlug === 'visibility') {
                this.singleTaskEdit[colSlug] = item.visibility
            }
        },

        localCheckAssigneeAdded (collab, item) {
            if (!this.localBulkMode) {
                return !!this.localIsAssigneeAdded(collab, item)
            }

            const bulkList = this.bulkItems
            const isAssignedChecklist = []

            bulkList.forEach(item => {
                isAssignedChecklist.push(this.localIsAssigneeAdded(collab, item))
            })

            return !isAssignedChecklist.some(i => i === false) // send FALSE if result is TRUE to show/hide tick icon
        },

        localBulkUpdateTaskAssignee (colSlug, collab, isNoMilestone) {
            if (colSlug !== 'assignees') return

            const bulkList = this.bulkItems
            const isAssignedChecklist = []

            bulkList.forEach(item => {
                isAssignedChecklist.push(this.localIsAssigneeAdded(collab, item))
            })

            const collabPresence = isAssignedChecklist.some(i => i === false)

            // Collab is not assigned to atleast one task
            if (collabPresence) {
                bulkList.forEach(async item => {
                    this.singleTaskEdit = item
                    const isAssigned = this.localIsAssigneeAdded(collab, this.singleTaskEdit)
                    if (!isAssigned) await this.localAddAssignee(collab, this.singleTaskEdit, isNoMilestone)
                    if (item.visibility === 'internal' && collab.scope === 'external') {
                        this.localTaskFieldsUpdate({ ...item, visibility: 'external' }, !item.milestone_id)
                    }
                })
            }

            // Collab is assigned to all the tasks
            if (!collabPresence) {
                bulkList.forEach(item => {
                    this.singleTaskEdit = item
                    const isAssigned = this.localIsAssigneeAdded(collab, this.singleTaskEdit)
                    if (isAssigned) this.localRemoveAssignee(isAssigned, this.singleTaskEdit, isNoMilestone)
                })
            }
        },

        async localUpdateTaskAssignee (colSlug, item, isNoMilestone) {
            if (colSlug !== 'assignees') return

            const isAssigned = this.localIsAssigneeAdded(item, this.singleTaskEdit)
            isAssigned ?
                this.localRemoveAssignee(isAssigned, this.singleTaskEdit, isNoMilestone) :
                await this.localAddAssignee(item, this.singleTaskEdit, isNoMilestone)

            if (this.singleTaskEdit.visibility === 'internal' && item.scope === 'external') {
                this.localTaskFieldsUpdate({ ...this.singleTaskEdit, visibility: 'external' }, !this.singleTaskEdit.milestone_id)
            }
        },

        localUpdateTaskDates (colSlug, value) {
            if (['start_date', 'due_date'].includes(colSlug)) this.singleTaskEdit[colSlug] = value
        },

        localUpdateTaskTitle (colSlug, value, isNoMilestone) {
            if (!_.trim(value)) return
            this.singleTaskEdit[colSlug] = value
            this.localTaskFieldsUpdate(this.singleTaskEdit, isNoMilestone)

            // setTimeout(() => {
            //     const clonedItem = _.cloneDeep(this.singleTaskEdit)
            //     delete clonedItem._mode
            //     this.localTaskFieldsUpdate(clonedItem, isNoMilestone)
            // }, 500)
        },

        async localTaskFieldsUpdate (item, isNoMilestone) {
            if (!_.trim(item.title)) return
            this.localUpdateModalTaskItem(item)

            !isNoMilestone ?
                    await this.milestone_task_update(item) :
                    await this.task_update_list(item)
        },

        localUpdateModalTaskItem (item) {
            if (!this.dialogTaskEdit) return

            this.currentEditItem = _.cloneDeep(item)
        },
        // Tasks fields update - End

        // Magic island actions - Start
        async localTaskBulkCreate (props) {
            const { list, order = -1, type, id = uuidv4() } = props || {}
            const params = { order }
            if (type === 'single') params['id'] = id

            await this.task_bulk_create({
                data: list.map(data => ({ ...data, ...params }))
            })
        },

        async localTaskBulkUpdate (props) {
            const { list = _.cloneDeep(this.bulkItems), slug, params } = props || {}
            await this.task_bulk_update({
                slug,
                ids: list.map(i => i.id),
                value: this.localGetBulkUpdateVal(params, slug)
            })
        },

        async localTaskBulkClone (props) {
            const { data, project_id, milestone_id } = props || {}
            await this.task_bulk_clone({ data, project_id, milestone_id })
        },

        async localOnIslandAction (props, type) {
            const { item, mode } = props || {}
            const bulkList = _.cloneDeep(this.bulkItems)

            if (type === 'module-switcher') {
                this.localSwitchView({ slug: item.value })
            }

            if (type === 'change-status') {
                this.localBulkUpdateTaskStatus('status_id', bulkList, item)
            }

            if (type === 'change-milestone' && mode === 'select') {
                this.localSelectChangeMilestone(item, bulkList, props)
            }

            if (type === 'copy-milestone') {
                this.localCopyToMilestone(item)
            }

            // TODO: v2 -> Virtual scroller to improve performance
            // this.localUpdateTaskRanges()

            // if (type === 'change-milestone' && mode === 'create') {
            //     const uuid = uuidv4()
            //     await this.localChangeTaskMilestone(uuid)

            //     const milestone = this.milestone_list.find(i => i.id === uuid)
            //     bulkList.forEach(task => {
            //         this.singleTaskEdit = task
            //         this.localUpdateTaskMilestone('milestone_id', milestone)
            //     })
            //     this.localTaskBulkUpdate({ list: bulkList, slug: 'milestone_id', params: { item: milestone } })
            // }

            // if (type === 'copy-new-milestone') {
            //     const uuid = uuidv4()
            //     this.localCreateMilestone(uuid)

            //     const milestone = this.milestone_list.find(i => i.id === uuid)
            //     const bulkList = _.cloneDeep(this.bulkItems).map(i => {
            //         i['id'] = uuidv4()
            //         i['milestone_id'] = uuid
            //         return i
            //     })
            //     milestone['tasks'] = bulkList
            //     this.milestone_update_local(milestone)
            // }

            // this.milestone_task_update(this.singleTaskEdit)
        },

        async localSelectChangeMilestone (item, bulkList, props) {
            const milestone = this.milestone_list.find(i => i.id === item.id)
            const isNoMilestone = item.id === 'no-milestone'

            let changeList = _.cloneDeep(bulkList)
            changeList = changeList.map(item => {
                item['milestone_id'] = isNoMilestone ? null : milestone.id
                return item
            })

            if (!isNoMilestone) this.localChangeMilestoneCrud(milestone, changeList, bulkList)
            if (isNoMilestone) this.localChangeMilestoneTaskCrud(changeList, bulkList)

            await this.localTaskBulkUpdate({ list: bulkList, slug: 'milestone_id', params: props })
            this.localTriggerTaskReorder(isNoMilestone ? this.task_list : milestone.tasks)
            if (!isNoMilestone) await this.localFetchMilestone(item.id)
            this.bulkItems = changeList
        },

        localChangeMilestoneCrud (milestone, changeList, bulkList) {
            milestone['tasks'] = [...changeList, ...milestone.tasks]
            this.milestone_update_local(milestone)
            bulkList.forEach(i => {
                !i.milestone_id ? this.task_remove(i.id) : this.milestone_task_remove(i)
            })

            const arrSet = [...new Set(bulkList.map(i => i.milestone_id))].filter(i => !!i)
            arrSet.forEach(i => {
                if (i !== 'no-milestone') this.localFetchMilestone(i)
            })
        },

        localChangeMilestoneTaskCrud (changeList, bulkList) {
            this.task_add_list(changeList)
            bulkList.forEach(i => {
                !i.milestone_id ? this.task_remove(i.id) : this.milestone_task_remove(i)
            })

            const arrSet = [...new Set(bulkList.map(i => i.milestone_id))]
            arrSet.forEach(id => {
                if (id !== 'no-milestone') this.localFetchMilestone(id)
            })
        },

        localFetchMilestonesAfterTaskUpdate (bulkList) {
            const arrSet = [...new Set(bulkList.map(i => i.milestone_id).filter(i => !!i))]
            arrSet.forEach(id => this.localFetchMilestone(id))
        },

        async localCopyToMilestone (item) {
            const milestoneId = item.id === 'no-milestone' ? null : item.id
            const changeList = _.cloneDeep(this.bulkItems).map(i => {
                const id = uuidv4()
                i['new_id'] = id
                i['old_id'] = i.id
                i['milestone_id'] = milestoneId
                i['id'] = id
                i['comments_count'] = 0
                return i
            })
            const paramsList = changeList.map(({ new_id, old_id }) => ({ new_id, old_id }))

            if (!milestoneId) {
                this.task_add_list(changeList)
                await this.localTaskBulkClone({ data: paramsList, project_id: this.localProjectId, milestone_id: milestoneId })
                this.task_reorder({ list: this.task_list, type: 'no-local-reorder' })
                return true
            }

            const milestone = this.milestone_list.find(i => i.id === item.id)
            milestone['tasks'] = [...changeList, ...milestone['tasks']]
            this.milestone_update_local(milestone)
            await this.localTaskBulkClone({ data: paramsList, project_id: this.localProjectId, milestone_id: milestoneId })
            this.task_reorder({ list: milestone.tasks, type: 'no-local-reorder' })
            this.localFetchMilestone(item.id)

            // if (!milestoneId) this.localChangeMilestoneCrud(milestone, changeList, this.bulkItems)
            // if (milestoneId) this.localChangeMilestoneTaskCrud(changeList, this.bulkItems)
        },

        localTriggerTaskReorder (list) {
            this.task_reorder({ list, type: 'no-local-reorder' })
        },

        async localChangeTaskMilestone (uuid) {
            const newMilestone = { id: uuid, title: 'Untitled Milestone', project_id: this.localProjectId, tasks: [], assignees: [], start_date: null, due_date: null, visibility: 'internal' }
            this.milestone_list.splice(this.milestone_list.length, 0, newMilestone)

            // this.milestone_store_local(newMilestone)
            await this.milestone_store({ id: newMilestone.id, title: newMilestone.title, project_id: newMilestone.project_id })
        },

        localCopyTaskMilestone (task, item) {

        },

        localCopyToSameMilestone () {},

        localCopyToNewMilestone () {},
        // Magic island actions - End

        // Task assignee ops - Start
        async localRemoveAssignee (isAssigned, taskItem, isNoMilestone) {
            const index = taskItem.assignees.findIndex(i => i.assignee.id === isAssigned.assignee.id)
            if (index === -1) return true

            taskItem.assignees.splice(index, 1)
            this.localTaskFieldsUpdate(taskItem, isNoMilestone)
            await this.assignee_destroy({ id: isAssigned.assignee.id })
        },

        async localAddAssignee (collab, item, isNoMilestone) {
            const { item: taskItem, assigneeProps } = this.localAddAssigneeSetup(collab, item)

            this.localTaskFieldsUpdate(taskItem, isNoMilestone)
            await this.assignee_store({ ...assigneeProps, project_id: taskItem.project_id, resource_type: 'Task' })
        },

        localAddAssigneeSetup (collab, item) {
            const assigneeId = uuidv4()
            const collabItem = _.cloneDeep(collab)
            const assigneeProps = { id: assigneeId, resource_id: item.id, user_id: collab.user_id }
            const assignee = { ...collab.user, assignee: assigneeProps, collaborator: collabItem }

            if (!item.hasOwnProperty('assignees')) item['assignees'] = [assignee]
            else item.assignees.push(assignee)

            return { item, assigneeProps }
        },
        // Task assignee ops - End

        localUpdateDate (item, slug, isNoMilestone) {
            const value = this.dateModels[item.id + '/' + slug]
            this.localSelectItem({ item, value }, item, slug, isNoMilestone)
            this.localFocusOut()
        },

        localBlurTaskTitle (evt, task, colSlug, isNoMilestone) {
            // clearTimeout(this.titleSaveTimeout)
            if (!_.trim(task.title) && this.localGetPropValue(task, '_mode') === 'create') {
                !isNoMilestone ? this.milestone_task_remove({ ...task }) : this.task_remove(task.id)
                this.localFocusOut({ cancelEscape: true })
                return this.localKeyDown({ code: 'Escape' })
            }

            if (_.trim(task.title)) {
                this.localFocusOut({ cancelEscape: true })
                this.localKeyDown({ code: 'Escape' })
            }

            this.localUpdateTitle(evt, task, colSlug, isNoMilestone, 0)
        },

        localUpdateTitle (evt, task, colSlug, isNoMilestone, timeout = 250) {
            // if (timeout !== 0 && (!task.hasOwnProperty('_mode') || task._mode !== 'create')) return
            if (timeout !== 0) this.isTitleEdited = true
            const value = _.cloneDeep(evt.target.value)
            clearTimeout(this.titleSaveTimeout)

            if ((!_.trim(value) || _.size(_.trim(value)) > 255) && !this.hasTitleError) {
                this.hasTitleError = true
                if (!_.trim(value)) this.$notify('error', 'Task title is a required field!')
                if (_.size(_.trim(value)) > 255) this.$notify('error', 'Maximum of 255 characters allowed!')
                setTimeout(() => this.hasTitleError = false, 3000)

                if (task && (!task._mode || task._mode !== 'create')) return false
            }

            this.singleTaskEdit = task
            this.localUpdateTaskTitle('title', value, isNoMilestone)

            this.titleSaveTimeout = setTimeout(() => {
                this.localSelectItem({ value }, this.singleTaskEdit, colSlug, isNoMilestone)
            }, timeout)
        },

        localUpdatedColumns (cols) {
            this.columnsList = cols.map((i, index) => ({ ...i, order: index + 1 }))
            this.localUpdatePref('columns', cols)
        },

        localSelectRow (row) {
            this.currentSelectedRow = row
        },

        localUpdatePref (slug, value) {
            this.$set(this.prefObject, slug, value)
        },

        localBulkSelect (value, clearAll) {
            this.bulkItems = value
            this.clearAll = clearAll
        },

        localGetCellValue (item, slug, col) {
            const [firstSlug, secondSlug] = slug.split('.')
            let value = null

            if (!secondSlug) value = item[firstSlug]
            else value = item[firstSlug][secondSlug]

            if (col && col.type === 'array') return !value.length ? null : value
            return value
        },

        localFormatValue (value, slug, item, field) {
            let result = null
            if (slug === 'milestone_id') result = this.milestone_list.find(i => i.id === item.milestone_id)
            if (slug === 'status_id') result = this.taskMetaList.find(i => i.id === item.status_id)
            if (slug === 'priority') result = this.priorityList.find(i => i.id === item.priority)
            if (this.dateFieldSlugs.includes(slug)) return item[slug] ? this.localFormatDate(item[slug]) : null

            return result ? result[field] : value
        },

        localGetItemValue (props, objSlug) {
            const { value, list, findBy } = props || {}
            const [firstField, secondField] = findBy.split('.')
            if (!value) return null

            const index = list.findIndex(i => {
                if (!secondField) return i[firstField] === value
                return i[firstField][secondField] === value
            })

            if (index === -1) return null

            const item = list[index]
            return item && item.hasOwnProperty(objSlug) ? item[objSlug] : null
        },

        localGetProp (slug, field) {
            const hasSlug = this.dropdownCols.includes(slug)
            if (!hasSlug) return 'id'

            let props = {}
            if (slug === 'priority') props = { itemText: 'label', itemValue: 'id', list: this.priorityList, groupedList: [], dropdownWidth: '100%', placeholder: 'Priority' }
            if (slug === 'milestone_id') props = { itemText: 'title', itemValue: 'id', list: this.localMilestoneMenuList, groupedList: [], dropdownWidth: '340', placeholder: 'Milestone' }
            if (slug === 'status_id') props = { itemText: 'value', itemValue: 'id', list: this.taskMetaList, groupedList: this.localGroupedMetaList, dropdownWidth: '250', placeholder: 'Status' }

            return field ? props[field] : props
        },

        localGetPriority (field, value) {
            const priority = this.priorityList.find(i => i.value === value)
            if (!priority) return '-'

            return priority[field]
        },

        localGetStatus (field, statusId) {
            const status = this.taskMetaList.find(i => i.id === statusId)
            if (!status) return '-'

            return status[field]
        },

        localIsAssigneeAdded (collab, item) {
            if (!item.assignees || _.size(item.assignees) === 0) return false

            const hasCollab = item.assignees.find(item => item.assignee.user_id === collab.user_id)
            return hasCollab || false
        },

        localKeyDown (evt) {
            if (evt.target && evt.target.tagName === 'BODY') {
                if (!evt.shiftKey && !evt.ctrlKey && !evt.metaKey && !evt.altKey) {
                    if (evt.code === 'KeyM') this.localSwitchView({ slug: 'milestone' })
                    if (evt.code === 'KeyT') this.localSwitchView({ slug: 'task' })
                    if (evt.code === 'KeyO' && this.currentViewMode === 'task' && _.size(Object.keys(this.currentSelectedRow))) {
                        this.localEditTask(this.currentSelectedRow)
                    }
                    if (['KeyA', 'KeyC', 'KeyI'].includes(evt.code)) {
                        this.localTaskFilterShortcut(evt.code)
                    }
                }

                if (this.localHasPermissionTo('update') && (evt.code === 'KeyA' && (evt.ctrlKey || evt.metaKey))) {
                    evt.preventDefault()
                    this.isSelectAll = true
                }

                if (evt.code === 'KeyE' && (evt.ctrlKey || evt.metaKey)) {
                    evt.preventDefault()
                    this.localToggleExpandAll()
                }

                if (this.localHasPermissionTo('destroy') && (['Delete', 'Backspace'].includes(evt.code) && (evt.ctrlKey || evt.metaKey))) {
                    if (_.size(this.bulkItems)) this.localBulkDelete()
                }
            }

            if (evt.code === 'Escape') {
                // this.bulkItems = []
                this.localFocusOut({ cancelEscape: true })
                if (this.clearAll) this.clearAll()
            }
        },

        localTaskFilterShortcut (code) {
            const value = { KeyA: 'all', KeyC: 'completed', KeyI: 'incomplete' }
            const status = this.taskStatusFilterList.find(i => i.value === value[code])
            this.localFilterByTaskStatus(status)
        },

        // Extras
        localFormatDate (date, range = false, simple = false) {
            if (!date) return null
            if (range) return moment(date).format('YYYY-MM-DD')
            if (simple) return moment(date).format('MMM D')

            return date ? moment(date).format('MMM D, YYYY') : null
        },

        localFocusOut ({ item = null, cancelEscape = false } = {}) {
            if (!cancelEscape) this.localTriggerEscape()
            this.milestoneTitleEditId = null
            if (item && !_.trim(item.title)) {
                !item.milestone_id ? this.task_remove(item.id) : this.milestone_task_remove(item)
            }
        },

        localTriggerEscape () {
            document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape', code: 'Escape' }))
        },

        localGetTimeEstText (value) {
            const { durationText } = calculateByMins(value)
            return durationText
        },

        async localOpenTimeTrack (task) {
            if (this.clearAll) this.clearAll()
            this.timeTrackTaskItem = task
            this.dialogEstList = true
            this.localFocusOut()
            this.localFetchTimeRecords()
        },

        async localFetchTimeRecords () {
            this.recordIndexLoading = true
            const params = {
                'include': 'createdBy',
                'fields[time_records]': 'id,duration_minutes,duration_text,description,created_at,start_date,resource_type,resource_id,created_by_id',
                'fields[created_by]': 'id,name,avatar,color,initial',
                'aggregate[duration_minutes]': 'count'
            }
            await this.time_record_index({
                ...params,
                'filter[resource_type]': 'Task',
                'filter[resource_id]': this.timeTrackTaskItem.id,
                'sort': '-created_at'
            })
            this.recordIndexLoading = false
        },

        local_calculate_mins (evt) {
            const { totalMins } = calculateByText(evt)
            this.task_item.estimated_duration_minutes = totalMins
        },

        localCloseTaskDialogEdit () {
            this.dialogTaskEdit = false
        },

        async localAddTimeEntry ({ formData: record, resetFields, mode, record_id: id }) {
            const params = { ...record, mode: 'create', resource_type: 'Task', resource_id: this.timeTrackTaskItem.id }
            if (mode && mode === 'edit') await this.time_record_update({ ...record, mode, id })
            else await this.time_record_store({ ...params })
            this.localClearTimeRecord(resetFields)
        },

        async localDeleteTimeEntry ({ id, resetFields }) {
            await this.time_record_destroy({ id })
            this.localClearTimeRecord(resetFields)
        },

        async localEstUpdate (evt) {
            await this.localTaskUpdate(evt)
            this.localTaskItem = _.cloneDeep(this.evt)
            this.currentEditItem = _.cloneDeep(this.evt)
            this.localUpdateMilestoneTask(evt)
            // if (!_.size(this.bulkItems)) return this.localUpdateMilestoneTask(evt)
            // this.localBulkEstUpdate(evt)
        },

        localBulkEstUpdate (evt) {
            const { time_records_sum_duration_minutes, estimated_duration_minutes, estimated_duration_text } = evt || {}
            this.bulkItems.forEach(item => {
                this.milestone_task_update({ ...item, time_records_sum_duration_minutes, estimated_duration_minutes, estimated_duration_text })
            })
        },

        async localClearTimeRecord (resetFields) {
            if (this.time_record_response && this.time_record_response.status === 'success') {
                await this.localTaskShow(this.timeTrackTaskItem.id, 'show')
                this.localTaskItem = _.cloneDeep(this.task_item)
                this.currentEditItem = _.cloneDeep(this.task_item)
                this.timeTrackTaskItem = this.localTaskItem
                this.localUpdateMilestoneTask(this.localTaskItem)
                resetFields()
            }
        },

        localCloseTimeTrack ({ resetFields }) {
            resetFields()
            this.time_record_clear()
            this.timeTrackTaskItem = null
            this.dialogEstList = false
        },

        // Bulk delete - Start
        localBulkDelete () {
            if (this.showBulkDelConfirmation) {
                this.currentBulkEditModule === 'task' ? this.localBulkDeleteTasks() : this.localBulkDeleteMilestones()
                return true
            }

            this.showBulkDelConfirmation = true
            setTimeout(() => this.showBulkDelConfirmation = false, 3000)
        },

        async localBulkDeleteTasks () {
            this.bulkItems.forEach(async taskItem => {
                taskItem.milestone_id ? await this.milestone_task_remove(taskItem) : await this.task_remove(taskItem.id)
            })

            // TODO: v2 -> Virtual scroller to improve performance
            // this.localUpdateTaskRanges()

            if (_.size(this.localGetSelectedMilestoneIds)) {
                [...new Set(this.localGetSelectedMilestoneIds)].forEach(miltestoneId => this.localFetchMilestone(miltestoneId))
            }

            this.showBulkDelConfirmation = false
            this.localFocusOut()
            if (this.clearAll) this.clearAll()

            const taskIds = _.cloneDeep(this.bulkItems).map(i => i.id).filter(i => !!i)
            await this.task_bulk_destroy({ ids: taskIds })

            this.isSelectAll = false
        },

        localBulkDeleteMilestones () {
            this.bulkItems.forEach(milestone => this.milestone_remove_local(milestone.id))
            this.milestone_bulk_destroy({ ids: this.bulkItems.map(i => i.id) })

            this.showBulkDelConfirmation = false
            this.localFocusOut()
            if (this.clearAll) this.clearAll()

            this.isSelectAll = false
        },

        localExitBulkMode () {
            if (this.clearAll) this.clearAll()
            this.localTriggerEscape()
        },
        // Bulk delete - End

        localUpdateMilestoneTask (task) {
            let taskItem = {}

            if (task.milestone_id) {
                const milestone = this.milestone_list.find(milestone => milestone.id === task.milestone_id)
                taskItem = milestone.tasks.find(item => item.id === task.id)
            } else taskItem = this.task_list.find(i => i.id === task.id)

            taskItem.time_records_sum_duration_minutes = task.time_records_sum_duration_minutes
            taskItem.estimated_duration_minutes = task.estimated_duration_minutes
            taskItem.estimated_duration_text = task.estimated_duration_text
            this.localTaskFieldsUpdate(taskItem, !task.milestone_id)
        },

        async localTaskShow(id, mode) {
            const params = {
                'include': 'milestone,assignees.collaborator.type,status',
                'fields[status]': 'id,status,value,color,percentage',
                'aggregate[time_records.duration_minutes]': 'sum'
            }
            await this.task_show({ id: id, mode, params })
            // if (this.pane_task) this.localTaskItem = { ...this.task_item }
        },

        localOpenDialogEdits (props) {
            const { item, column } = props || {}
            switch (column) {
                case 'estimated_duration_text': return this.localOpenTimeTrack(item)
                case 'description_json':
                case 'comments_count': return this.localEditTask(item)
                case 'attachments_count': return this.localEditTask(item)
            }
        },

        // Record task item changes - Start
        localTriggerSave (value, field, id) {
            const item = this.originalArr.find(i => i.id === id)
            const changeExists = this.changesArr.findIndex(i => i.id === id)
            let obj = { id, changes: [] }

            if (changeExists === -1) {
                obj.changes.push({ field, oldValue: item[field], newValue: value })
                this.changesArr.push(obj)
                return true
            }

            obj = { ...this.changesArr[changeExists] }
            const fieldExists = obj.changes.findIndex(i => i.field === field)
            if (fieldExists !== -1) obj.changes[fieldExists] = { ...obj.changes[fieldExists], newValue: value }
            else obj.changes.push({ field, oldValue: item[field], newValue: value })

            this.changesArr[changeExists] = { ...obj }
        },

        locaTriggerToast (value, field, id) {
            this.updatedField = { value, field, id }
            this.toastMessage = `Updated the ${field} for ${this.changesArr.length} tasks`
            this.showToastMessage = true
            clearTimeout(this.toastTimeout)
            this.toastTimeout = setTimeout(() => {
                this.showToastMessage = false
                this.updatedField = null
                this.toastMessage = null
            }, 5000);
        },

        localUpdateOriginalArr () {
            this.originalArr = _.cloneDeep(this.clonedLists)
            this.changesArr = []
        },

        localUndo () {
            const { field, id } = this.updatedField || {}
            const changeIndex = this.changesArr.findIndex(i => i.id === id)
            const changeItem = this.changesArr[changeIndex]
            const changeFieldIndex = changeItem.changes.findIndex(i => i.field === field)
            const listItemIndex = this.clonedLists.findIndex(i => i.id === id)

            this.clonedLists[listItemIndex][field] = changeItem.changes[changeFieldIndex].oldValue
            changeItem.changes.splice(changeFieldIndex, 1)
            this.changesArr[changeIndex] = { ...this.changesArr[changeIndex], changes: changeItem.changes }

            if (changeItem.changes.length === 0) this.changesArr.splice(changeIndex, 1)

            this.showToastMessage = false
            clearTimeout(this.toastTimeout)
            this.updatedField = null
        },

        localReset () {
            this.changesArr = []
            this.clonedLists = _.cloneDeep(this.originalArr)
        },
        // Record task item changes - End

        localSwitchView (props) {
            const { slug } = props || {}

            this.currentBulkEditModule = slug
            this.currentViewMode = slug
            this.localUpdatePref('view', slug)
        },

        async localCreateBtnModule (props) {
            const { slug } = props || {}
            if (slug === 'new-task') {
                setTimeout(async () => {
                    await this.localRemoveOldCreateTask()
                    this.localCreateTask(_.size(this.milestone_list) ? props : { item: { id: 'no-milestone' }, order: -1 })
                }, 50)
            }
            if (slug === 'new-milestone') this.localCreateMilestone(props)
            if (slug === 'import-csv') this.$router.push({ name: 'projects-tasks-import' })
            if (slug === 'import-template') this.dialogImportForm = true
        },

        async localRemoveOldCreateTask () {
            if (_.size(this.milestone_list)) {
                if (!_.size(this.milestone_list[0].tasks)) return

                const firstTask = this.milestone_list[0].tasks[0]
                if (this.localGetPropValue(firstTask, '_mode') === 'create') {
                    this.milestone_list[0].tasks.splice(0, 1)
                }

                return true
            }

            if (!_.size(this.task_list)) return

            const firstTask = this.task_list[0]
            if (this.localGetPropValue(firstTask, '_mode') === 'create') {
                this.task_list.splice(0, 1)
            }
        },

        localCreateModule (props) {
            const { _module } = props || {}
            _module === 'task' ? this.localCreateTask(props) : this.localCreateMilestone(props)
        },

        async localCreateMilestone (props) {
            const { item, order, callback, _type = null } = props || {}
            const param = { ...this.localConstructMilestoneObj(item), project_id: this.localProjectId, order }
            const mCount = _.size(this.milestone_list)

            this.taskRanges[param.id] = { startIndex: 0, endIndex: 4 }
            const localOrder = _type === 'exact_order' ? order : (order === -1 ? 0 : mCount + 1)
            this.milestone_list.splice(localOrder, 0, param)

            await this.milestone_store({ ...param, order: order === 1 ? mCount + 1 : -1 })
            this.milestone_reorder(this.milestone_list.map(i => ({ id: i.id })))

            if (callback) callback(order)
        },

        localConstructMilestoneObj (item, mode = 'create') {
            const result = {
                ...item,
                id: item && item.id ? item.id : uuidv4(),
                title: item && item.title ? item.title : 'Untitled Milestone',
                project_id: item && item.project_id ? item.project_id : this.localProjectId,
                start_date: null,
                due_date: null,
                tasks: [],
                assignees: [],
                tasks_completed_count: 0,
                tasks_count: 0,
                visibility: 'internal',
            }

            if (mode) result['_mode'] = 'create'

            return result
        },

        async localCreateTask (props) {
            const { item, order } = props || {}

            if (item && item.id === 'no-milestone') {
                await this.task_add(this.localConstructTaskObj(uuidv4(), null, order))
                return
            }

            let mIndex = 0
            if (order !== -1) mIndex = this.milestone_list.findIndex(i => i.id === item.id)

            const taskId = uuidv4()
            const milestoneId = this.milestone_list[mIndex].id
            if (this.milestone_list[mIndex].hasOwnProperty('tasks')) {
                if (order === -1) this.milestone_list[mIndex].tasks.unshift(this.localConstructTaskObj(taskId, milestoneId, order))
                else this.milestone_list[mIndex].tasks.push(this.localConstructTaskObj(taskId, milestoneId, order))
            } else {
                this.milestone_list[mIndex] = {
                    ...this.milestone_list[mIndex],
                    tasks: [this.localConstructTaskObj(taskId, milestoneId)]
                }
            }

            this.milestone_update_local(this.milestone_list[mIndex])
        },

        localConstructTaskObj (taskId, milestoneId, order, mode = 'create') {
            const meta = this.taskMetaList.find(i => i.is_starred)
            const result = {
                id: taskId,
                order,
                title: '',
                description_json: null,
                start_date: null,
                due_date: null,
                visibility: 'internal',
                priority: null,
                status: meta,
                status_id: meta.id,
                assignees: [],
                milestone_id: milestoneId,
                project_id: this.localProjectId,
                estimated_duration_minutes: null,
                estimated_duration_text: null,
                time_records_sum_duration_minutes: null,
                attachments_count: 0
            }

            if (mode) result['_mode'] = 'create'

            return result
        },

        localEditMilestoneTitle (milestoneId) {
            if (!this.localHasPermissionTo('update')) return

            this.milestoneTitleEditId = milestoneId
            setTimeout(() => {
                const input = this.$refs[`refMilestoneTitle-${milestoneId}`]
                if (input) input.focus()
            }, 200);
        },

        localBlurMilestoneTitle (evt, item) {
            this.localUpdateMilestoneTitle(evt, item, 0)
        },

        localUpdateMilestoneTitle (evt, item, ms = 500) {
            if (ms !== 0) this.isTitleEdited = true
            clearTimeout(this.milestoneTitleTimeout)

            const value = evt.target.value
            if (!_.trim(value) || (_.size(_.trim(value)) > 255) || (!_.trim(value) === _.trim(item.title))) {
                if (_.size(_.trim(value)) > 255) this.$notify('error', 'Maximum of 255 characters allowed!')
                if (ms === 0) this.localFocusOutAndEsc()
                return true
            }

            const mIndex = this.milestone_list.findIndex(i => i.id === item.id)
            if (this.milestone_list[mIndex].hasOwnProperty('_mode')) delete this.milestone_list[mIndex]._mode

            this.milestone_update_local({ ...this.milestone_list[mIndex], title: value })
            if (ms === 0) this.localFocusOutAndEsc()

            if (this.isTitleEdited) {
                this.milestoneTitleTimeout = setTimeout(() => {
                    this.milestone_update({ id: item.id, title: value })
                }, ms);
            }
        },

        localFocusOutAndEsc () {
            this.localFocusOut({ cancelEscape: true })
            this.localKeyDown({ code: 'Escape' })
        },

        // Milestone update - Start
        localBulkUpdateMilestoneView (evt, field) {
            const { item } = evt || {}
            const bulkList = this.bulkItems

            if (field === 'assignees') {
                this.localBulkUpdateMilestoneAssignee(item, field)
                if (item.scope === 'external') {
                    bulkList.forEach(i => this.milestone_update_local({ ...i, visibility: 'external' }))
                    // bulkList.forEach(i => this.localFetchMilestone(i.id))
                }
                this.localFocusOut({ cancelEscape: true })
            }

            if (field === 'date') {
                const { type, value } = evt || {}
                bulkList.forEach(milestoneItem => {
                    milestoneItem[type] = value
                    this.milestone_update_local(milestoneItem)
                })
                this.milestone_bulk_update({ ids: bulkList.map(i => i.id), [type]: value })
                this.localFocusOut()
            }
        },

        localBulkUpdateMilestoneAssignee (collab, field) {
            const bulkList = this.bulkItems
            const isAssignedChecklist = []

            bulkList.forEach(item => {
                isAssignedChecklist.push(this.localIsAssigneeAdded(collab, item))
            })

            const collabPresence = isAssignedChecklist.some(i => i === false)

            // Collab is not assigned to atleast one task
            if (collabPresence) {
                bulkList.forEach(item => {
                    this.singleMilestoneEdit = item
                    const isAssigned = this.localIsAssigneeAdded(collab, this.singleMilestoneEdit)
                    if (!isAssigned) this.localAddMilestoneAssignee(collab, this.singleMilestoneEdit)
                })
            }

            // Collab is assigned to all the tasks
            if (!collabPresence) {
                bulkList.forEach(item => {
                    this.singleMilestoneEdit = item
                    const isAssigned = this.localIsAssigneeAdded(collab, this.singleMilestoneEdit)
                    if (isAssigned) this.localRemoveMilestoneAssignee(isAssigned, this.singleMilestoneEdit)
                })
            }
        },

        localUpdateMilestone (evt, milestone, field) {
            const { item: user } = evt || {}

            if (this.localBulkMode) {
                return this.localBulkUpdateMilestoneView(evt, field)
            }

            this.singleMilestoneEdit = _.cloneDeep(milestone)
            this.localUpdateMilestoneAssignee(user, field)
            this.localUpdateMilestoneDates(evt, field)

            if (field === 'assignees' && user.scope === 'external') {
                this.singleMilestoneEdit.visibility = 'external'
            }

            this.milestone_update_local(this.singleMilestoneEdit)
            this.milestone_update(this.singleMilestoneEdit)
            this.localFocusOut()
        },

        localUpdateMilestoneAssignee (collab, field) {
            if (field !== 'assignees') return

            const isAssigned = this.localIsAssigneeAdded(collab, this.singleMilestoneEdit)
            isAssigned ?
                this.localRemoveMilestoneAssignee(isAssigned, this.singleMilestoneEdit) :
                this.localAddMilestoneAssignee(collab, this.singleMilestoneEdit)
        },

        localAddMilestoneAssignee (collab, item) {
            const { item: milestone, assigneeProps } = this.localAddAssigneeSetup(collab, item)

            this.milestone_update_local(milestone)
            this.assignee_store({ ...assigneeProps, project_id: milestone.project_id, resource_type: 'Milestone' })
        },

        localRemoveMilestoneAssignee (isAssigned, milestone) {
            const index = milestone.assignees.findIndex(i => i.assignee.id === isAssigned.assignee.id)
            if (index === -1) return true

            milestone.assignees.splice(index, 1)
            this.milestone_update_local(milestone)
            this.assignee_destroy({ id: isAssigned.assignee.id })
        },

        localUpdateMilestoneDates (evt, field) {
            if (field !== 'date') return

            const { type, value, item } = evt || {}
            this.singleMilestoneEdit = { ...item, [type]: value || null }
        },

        localUpdateDateFieldMilestone (value, slug, item) {
            if (_.size(this.bulkItems)) {
                return this.localBulkUpdateMilestoneView({ type: slug, value }, 'date')
            }

            this.singleMilestoneEdit = { ...item, [slug]: value }

            this.milestone_update_local(this.singleMilestoneEdit)
            this.milestone_update(this.singleMilestoneEdit)
            this.localFocusOut()
        },

        localDeleteMilestone () {
            if (!this.localHasPermissionTo('destroy')) return
            this.milestone_remove_local(this.confirmDeleteMilestone)
            // this.milestone_destroy({ id: this.confirmDeleteMilestone })
            this.milestone_bulk_destroy({ ids: [this.confirmDeleteMilestone] })
        },

        localGetDeleteConfirm (slug, id) {
            if (!this.localHasPermissionTo('destroy')) return

            slug === 'milestone' ? this.confirmDeleteMilestone = id : null
            setTimeout(() => this.confirmDeleteMilestone = null, 3000)
        },

        async localDuplicateMilestones () {
            const mIds = []
            const newMilestones = []
            const bulkList = _.cloneDeep(this.bulkItems)

            for (let index = 0; index < bulkList.length; index++) {
                const milestone = bulkList[index]
                const newId = uuidv4()

                mIds.push({ new_id: newId, old_id: milestone.id })
                milestone.tasks = milestone.tasks
                    .filter(item => item['milestone_id'] = newId)
                    .map(i => ({ ...i, time_records_sum_duration_minutes: null, comments_count: 0 }))

                milestone.id = newId
                milestone.title = milestone.title + ' (Copy)'
                newMilestones.push(milestone)
            }

            await this.milestone_add_list(newMilestones)
            await this.milestone_clone({ ids: mIds, project_id: this.localProjectId })
            this.localFetchMilestones()

            // TODO: v2 -> Virtual scroller to improve performance
            // this.localUpdateTaskRanges()
        },
        // Milestone update - End

        // Task edit popup - Start
        localEditTask (task) {
            if (this.clearAll) this.clearAll()
            this.localFocusOut()
            this.localNavigateFromDetailView(task.id)
            this.dialogTaskEdit = true
        },

        localNavigateFromDetailView (taskId) {
            const count = _.size(this.localFetchAllTasks)
            const currentIndex = this.localFetchAllTasks.findIndex(i => i.id === taskId)
            this.currentEditItem = _.cloneDeep(this.localFetchAllTasks[currentIndex])
            if (currentIndex >= 0 && currentIndex <= count) {
                if (currentIndex === 0) this.previousTaskId = null
                else this.previousTaskId = this.localFetchAllTasks[currentIndex - 1].id

                if ((currentIndex + 1) === count) this.nextTaskId = null
                else this.nextTaskId = this.localFetchAllTasks[currentIndex + 1].id
            }
        },

        localDeleteTask (item) {
            !item.milestone_id ? this.task_remove(item.id) : this.milestone_task_remove(item)
            this.task_destroy(item)
        },
        // Task edit popup - End

        localFilterByTaskStatus (status) {
            this.taskStatusFilter = status
            this.localUpdatePref('status', status.value)
        },

        localToggleSelectAll () {
            this.isSelectAll = !this.isSelectAll
        },

        localToggleExpandAll () {
            this.isExpandAll = !this.isExpandAll
            this.localUpdatePref('isExpandAll', this.isExpandAll)
        },

        async localReorder (props) {
            const { type, list } = props || {}
            if (type === 'task') {
                this.task_reorder({ list, type: 'no-local-reorder' })
            }
            if (type === 'milestone') {
                this.milestone_update_list(list)
                this.milestone_reorder(list.map(i => ({ id: i.id })))
            }
        },

        // Extras
        localCanShowMenu (id) {
            return this.milestoneTitleEditId === id ||
                this.confirmDeleteMilestone === id ||
                this.modelMilestoneDelete[id]
        },

        localHasPermissionTo (type) {
            return this.$can('projects.update-only') && this.$can(`tasks.${type}`)
        },

        localClearMilestoneDates (evt, clear, field) {
            evt.stopPropagation()
            evt.preventDefault()

            clear(field)
        },

        async localTaskUpdate (task, field = null, additionProps = {}) {
            const { isEmpty = false } = additionProps || {}

            const clonedTask = _.cloneDeep(task)
            if (clonedTask.hasOwnProperty('description_json') && field !== 'description_json') {
                const jsonDesc = (clonedTask.description_json && (typeof clonedTask.description_json !== 'string')) ? JSON.stringify(clonedTask.description_json) : clonedTask.description_json
                clonedTask['description_json'] = isEmpty || !clonedTask.description_json ? null : Base64.encode(jsonDesc)
            }
            await this.task_update(clonedTask)
        },

        // Project --- Start
        localCheckPlanStageUpdate (stageToMove) {
            const { is_admin } = this.user_self || {}
            const { projects_active_count } = this.mixinGetOrgPlan() || {}

            if (this.$plan('active_projects_limit') === -1) return stageToMove()
            if (projects_active_count < this.$plan('active_projects_limit')) return stageToMove()

            if (is_admin) this.dialogAdminUpgradeModal = true
            else this.dialogUserUpgradeModal = true
        },
        // Project --- End

        localDateIsBefore (date, status) {
            if (!date) return false
            return moment(date).isBefore() && (status && status.status !== 'Completed')
        },

        localGetPropValue (item, field, defaultType = null) {
            if (!item.hasOwnProperty(field)) return defaultType
            return item[field] || defaultType
        },

        localFetchAllPrefs () {
            this.preference_index()
        },

        localSetLoadingStatus (e) {
            this.localAttachmentLoading = e
        },

        localIdCopy () {
            this.$notify('success', 'ID Copied!')
        },

        ...mapActions('TimeRecord', {
            time_record_index: 'index',
            time_record_store: 'store',
            time_record_update: 'update',
            time_record_destroy: 'destroy',
            time_record_clear: 'clear',
        }),

        ...mapActions('Meta', {
            meta_index: 'index'
        }),

        ...mapActions('Preference', {
            preference_index: 'index',
            preference_upsert: 'upsert_store',
        }),

        ...mapActions('Task', {
            task_index: 'index',
            task_show: 'show',
            task_update: 'new_update',
            task_add: 'add',
            task_add_list: 'add_list',
            task_prepend_item: 'prepend_item',
            task_remove: 'remove',
            task_reorder: 'reorder',
            task_destroy: 'destroy',
            task_bulk_destroy: 'bulk_destroy',
            task_bulk_visibility: 'bulk_visibility',
            task_bulk_create: 'bulk_create',
            task_bulk_clone: 'bulk_clone',
            task_bulk_update: 'bulk_update',
            task_bulk_state_update: 'state_update',
            task_update_list: 'task_update',
        }),

        ...mapActions('Milestone', {
            milestone_index: 'index',
            milestone_store: 'store',
            milestone_clone: 'clone',
            milestone_update: 'update',
            milestone_show: 'show',
            milestone_bulk_update: 'bulk_update',
            milestone_destroy: 'destroy',
            milestone_bulk_destroy: 'bulk_destroy',
            milestone_update_list: 'update_list',
            milestone_reorder: 'reorder_milestone',
            milestone_reorder_tasks: 'reorder_tasks',
            milestone_add_list: 'add_list',
            milestone_store_index_local: 'store_index_local',
            milestone_store_local: 'store_local',
            milestone_update_local: 'update_local',
            milestone_remove_local: 'remove_local',
            milestone_task_store: 'task_store',
            milestone_task_update: 'task_update',
            milestone_task_remove: 'task_remove',
        }),

        ...mapActions('Assignee', {
            assignee_store: 'store',
            assignee_bulk_assign: 'bulk_assign',
            assignee_clear: 'clear',
            assignee_destroy: 'destroy',
        }),

        ...mapActions('Collaborator', {
            collaborator_index: 'index',
            collaborator_clear: 'clear',
        }),
    }
}
</script>

<style lang="scss" scoped>
.c-milestone-title-edit {
    width: 332px;
    height: 36px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -8px;
    border-radius: 4px;
    pointer-events: none;

    &--focus {
        border: 2px solid #3949ab;
    }

    &--error {
        border: 2px solid #d81b60;
    }
}
</style>

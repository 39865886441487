<template>
    <a-responsive class="grey darken-4" style="border-radius: 4px 4px 0px 0px;">
        <a-container grid-list-xl container--fluid class="py-2 px-5">
            <a-layout align-start>
                <a-flex shrink class="px-0">
                    <a-btn color="white" text :to="{name: 'appstore'}" exact>
                        <a-icon class="mr-1">arrow_back</a-icon> Back to Apps
                    </a-btn>
                </a-flex>
            </a-layout>
        </a-container>
    </a-responsive>
</template>

<script>
export default {}
</script>

export default {
    namespaced: true,
    state: {
        globalAsideNavbar: true,
        sidebar: true,
        keydown: null,
        defaults: {
            sidebar: true,
            keydown: null,
        }
    },

    mutations: {
        TOGGLE_GLOBAL_ASIDE_NAVBAR (state, value) {
            state.globalAsideNavbar = value
        },

        TOGGLE_SIDEBAR (state, value) {
            state.sidebar = value
        },

        SET_KEYDOWN (state, value) {
            state.keydown = value
        },
    },

    actions: {
        toggleGlobalAsideNavbar ({ commit }, payload) {
            commit('TOGGLE_GLOBAL_ASIDE_NAVBAR', payload)
        },

        toggleSidebar ({ commit }, payload) {
            commit('TOGGLE_SIDEBAR', payload)
        },

        setKeydown ({ commit }, payload) {
            commit('SET_KEYDOWN', payload)
        },
    },

    getters: {
        globalAsideNavbar: state => state.globalAsideNavbar,
        sidebar: state => state.sidebar,
        keydown: state => state.keydown
    }
}

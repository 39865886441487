<template>
    <div>
        <template v-if="total === 0 && !noEmptyText">
            <span class="md-caption font-weight-medium text-uppercase orange--text text--darken-3 u-cursor-pointer" style="letter-spacing: 0.6px;">
                <a-icon size="16" color="orange darken-3" class="u-icon-nudge">info</a-icon>
                Configure {{ module }}
            </span>
        </template>
        <template v-else>
            <a-tooltip bottom left :disabled="(!passed && !failed && !invalid && !open)">
                <template v-slot:activator="{ on }">
                    <div class="d-inline-flex align-center" v-on="on">
                        <a-sheet class="c-testcase-count-wrap" :width="100 - (local_current_counts)" color="grey lighten-3">
                            <a-sheet v-if="passed"  class="c-testcase-count-wrap--strip" color="#00B894" style="z-index: 4;" :width="(passed / total) * 100"></a-sheet>
                            <a-sheet v-if="failed" class="c-testcase-count-wrap--strip" color="#CC2929" style="z-index: 3;" :width="(failed / total) * 100"></a-sheet>
                            <a-sheet  v-if="invalid" class="c-testcase-count-wrap--strip grey darken-2" style="z-index: 2;" :width="(invalid / total) * 100"></a-sheet>
                            <a-sheet v-if="open" class="c-testcase-count-wrap--strip" color="grey lighten-3" style="z-index: 1;" :width="(open / total) * 100"></a-sheet>
                        </a-sheet>
                        <span class="md-caption grey--text text--darken-2 ml-2">{{ closed ? closed : 0 }}/{{ total ? total : 0 }} {{ module }}</span>
                    </div>
                </template>
                <span>
                    <span v-if="passed" class="mr-1">{{ passed }} passed </span>
                    <span v-if="failed" class="mr-1"> {{ failed }} failed </span>
                    <span v-if="invalid" class="mr-1"> {{ invalid }} invalid </span>
                    <span v-if="open">{{ open }} open </span>
                    <span> out of {{ total }} </span> <span class="text-lowercase">{{ module }}</span>
                </span>
            </a-tooltip>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        module: {
            type: String,
            required: true
        },
        total: {
            type: String | Number,
            required: true
        },
        open: {
            type: String | Number,
            default: 0
        },
        closed: {
            type: String | Number,
            default: 0
        },
        passed: {
            type: String | Number,
            default: 0
        },
        failed: {
            type: String | Number,
            default: 0
        },
        invalid: {
            type: String | Number,
            default: 0
        },
        noEmptyText: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        local_current_counts () {
            let count = 0
            if (this.failed) count++
            if (this.invalid) count++
            if (this.open) count++
            if (this.passed) count++

            return count * 4
        },
    }
}
</script>

<style lang="scss" scoped>
    .c-testcase-count-wrap {
        overflow: hidden;
        border-radius: 25px;
        display: inline-flex;
        align-items: center;
        height: 6px;
        z-index: 0;
        &--strip {
            display: inline-block;
            margin-left: -4px;
            border-radius: 0 25px 25px 0;
            height: 100%;
        }
    }
</style>

<template>
    <div class="u-flex-center flex-column u-hfull">
        <a-icon :size="iconSize" color="grey darken-2" :outlined="iconOutlined">{{ icon }}</a-icon>
        <h3 class="md-body-2 mt-1 grey--text text--darken-2 u-font-weight-semibold">{{ title }}</h3>
        <p v-if="canView" class="mb-0 mt-2 md-body-2 grey--text">
            <slot name="description">There are no {{ modelSlug }} to show.</slot>
        </p>
        <p v-else class="mb-0 mt-2 md-body-2 grey--text">You don't have access to this tile.</p>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        },
        icon: {
            type: String
        },
        modelSlug: {
            type: String
        },
        iconSize: {
            type: String,
            default: '32'
        },
        canView: {
            type: Boolean,
        },
        iconOutlined: {
            type: Boolean,
            default: false
        },
    }
}
</script>

import { render, staticRenderFns } from "./ProjectsCollaborators.vue?vue&type=template&id=3d1b528b"
import script from "./ProjectsCollaborators.vue?vue&type=script&lang=js"
export * from "./ProjectsCollaborators.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VRadio,VRadioGroup,VSlideYTransition})

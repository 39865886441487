<template>
    <div>
        <div v-for="i in mini ? 2 : 3" :key="i" :class="[{ 'mt-6': i !== 1 }]">
            <loader-template
                width="60"
                height="18"
                class="u-rounded-corners-full mb-2"
            />
            <div class="u-flex-center-y flex-wrap" style="gap: 8px">
                <template v-for="j in 3">
                    <loader-template
                        :key="j"
                        width="70"
                        height="26"
                        class="u-rounded-corners-xl"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mini: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<template>
    <main class="container-wrapper u-hfull">
        <a-sheet v-if="(show_maintenance_msg && maintenance_enabled === 'true') && !global_loading && !show_maintenance_mode" class="justify-center px-4 md-caption pa-2 d-inline-flex align-center u-wfull orange lighten-4">
            <div class="u-flex-center">
                <a-icon size="20" color="brown darken-1" class="mr-2" style="top: -2px;">warning</a-icon>
                <span class="font-weight-medium brown--text text--darken-1 md-body-2">{{ maintenance_msg }}</span>
            </div>
            <a-icon size="20" color="brown darken-2" class="ml-8" @click="show_maintenance_msg = false">clear</a-icon>
        </a-sheet>

        <!-- <a-sheet
            v-if="!global_loading && dataLoggedUser !== null && !show_maintenance_mode && hasDesktopNotificationPref === false"
            class="c-notify-permission px-5 md-caption py-3 font-weight-medium d-inline-flex grey darken-3 white--text u-rounded-corners-lg elevation-3"
            style="column-gap: 3px; z-index: 20"
        >
            Success App needs your permission to
            <span
                @click="localAskForNotificationPermission()"
                class="u-font-weight-semibold white--text u-cursor-pointer"
                style="text-decoration: underline; text-underline-offset: 4px; text-decoration-color: #ccc;"
            >
                enable desktop notifications
            </span>
        </a-sheet> -->

        <template v-if="show_maintenance_mode && !global_loading">
            <div class="u-flex-center u-hfull">
                <div class="pa-4 my-4">
                    <div class="text-center">
                        <img v-if="orgLogo" :src="orgLogo" style="max-height: 72px;max-width: 250px" class="d-inline-block">
                        <img v-if="orgLogo === null" src="../assets/images/success-imgs/logo-success-full.svg" style="max-height: 32px;max-width: 256px" class="d-inline-block">
                    </div>
                    <div class="text-center my-4">
                        <a-sheet class="transparent">
                            <img src="../assets/images/maintenance-mode.svg" style="max-width: 256px" class="d-inline-block">
                        </a-sheet>
                        <h2 class="md-heading-6 font-weight-bold grey--text text--darken-3">
                            Under Maintenance
                        </h2>
                        <a-sheet max-width="520" class="font-weight-medium grey--text text--darken-2 transparent mb-0 mt-2 md-subtitle-2">
                            {{ maintenance_msg }}
                        </a-sheet>
                    </div>
                </div>
            </div>
        </template>

        <!-- Global loading icon -->
        <template v-if="global_loading && !same_previous_route">
            <div class="preloader">
                <div class="preloader-item">
                    <img src="../assets/images/loader.svg" alt="Loading Success">
                </div>
            </div>
        </template>
        <template v-else>
            <template v-if="!show_maintenance_mode">
                <!-- Show only if user data exist after login and user is not external -->
                <template v-if="!global_loading">
                    <template v-if="dataLoggedUser !== null && !local_route_is_logout">
                        <div style="position: fixed; top: 0; left: 0px; width: 100%; z-index: 220;">
                            <a-progress-linear
                                :indeterminate="interface_loader"
                                :color="(interface_loader) ? 'blue lighten-1' : 'grey'"
                                class="ma-0"
                                height="2"
                            ></a-progress-linear>
                        </div>

                        <navigation-layout :show-top-nav="local_can_show_nav" :showGlobalAside="local_can_show_nav" :has-workspace="!!(local_has_workspace)">
                            <template #internal-side-nav="{ visible, navBarLoading }" v-if="local_can_show_nav">
                                <s-internal-navbar-aside
                                    :navBarLoading="navBarLoading"
                                    :visibility="visible"
                                />
                            </template>
                            <!-- <a-sheet :width="(!isExternalUser && !isExternalView && !check_empty_workspace && !local_is_project_view && local_has_workspace) ? 'calc(100% - 96px)' : ''" class="ml-auto transparent"> -->
                            <template v-if="!isExternalUser">
                                <a-sheet class="transparent u-wfull" :class="[{ 'pr-5': (!isExternalView && !check_empty_workspace && !local_is_project_view && local_has_workspace) }, { 'px-12': !local_has_workspace }, { 'mt-2': isExternalUser && !isExternalView }]">
                                    <router-view v-if="check_empty_workspace === false || local_is_workspace_page" :workspaceNotFoundError="check_empty_workspace === false && local_is_workspace_page && local_has_workspace"></router-view>
                                </a-sheet>
                            </template>
                            <template v-if="isExternalUser">
                                <router-view></router-view>
                            </template>
                        </navigation-layout>
                        <!-- <template v-if="check_empty_workspace === false && !local_is_project_view && local_has_workspace">
                            <s-navigation-bar></s-navigation-bar>
                            <navbar-layout></navbar-layout>
                        </template> -->
                        <!-- <a-sheet :style="[!isExternalUser && !isExternalView && !check_empty_workspace ? { 'margin-left': '96px' } : '']" class="pr-6 transparent"> -->
                        <!-- <a-sheet :width="!isExternalUser && !isExternalView && !check_empty_workspace && !local_is_project_view && local_has_workspace ? 'calc(100% - 96px)' : ''" class="pr-6 ml-auto transparent">
                            <router-view v-if="check_empty_workspace === false || local_is_workspace_page" :workspaceNotFoundError="check_empty_workspace === false && local_is_workspace_page && local_has_workspace"></router-view>
                        </a-sheet> -->

                        <template v-if="check_empty_workspace && !isExternalUser">
                            <a-dialog v-model="show_empty_workspace_error" max-width="700" :persistent="!!(!local_user_is_admin || (local_user_is_admin && !local_is_workspace_page))">
                                <a-card class="text-center pa-4 u-rounded-corners">
                                    <div class="mb-2"><a-icon size="40" class="mr-1" color="orange lighten-1">sentiment_very_dissatisfied</a-icon></div>
                                    <h3 class="md-subtitle-1 grey--text text--darken-1">We couldn't find any available workspaces for you.</h3>
                                    <p class="mt-1 mb-0 md-body-2 grey--text" v-if="!local_user_is_admin">Please ask your Success administrator to invite you into a workspace.</p>
                                    <template v-if="local_user_is_admin">
                                        <a-btn small dark class="mt-3 u-rounded-corners primary elevation-0" v-if="!local_is_workspace_page" :to="{ name: 'settings-workspace' }">Manage Workspace</a-btn>
                                        <a-btn small dark class="mt-3 u-rounded-corners primary elevation-0" v-else @click="show_empty_workspace_error = false">Manage Workspace</a-btn>
                                    </template>
                                </a-card>
                            </a-dialog>
                        </template>
                    </template>
                </template>

                <!-- Show only if there is no-user -->
                <template v-if="(dataLoggedUser === null && !global_loading) || local_route_is_logout">
                    <router-view v-if="local_route_is_public || local_route_is_logout" @logged-in="local_get_loggedin_user"></router-view>
                </template>

                <a-dialog content-class="elevation-0 u-overflow-hidden" v-model="show_magic_link_popup" persistent max-width="550">
                    <a-sheet class="justify-center u-flex align-center transparent">
                        <a-sheet class="text-center u-rounded-corners u-shadow pa-9">
                            <SMagicLinkBanner v-once />
                            <div class="pt-4 pb-4 text-center">
                                <span class="px-3 py-1 subtitle-2 ont-weight-medium indigo lighten-5 d-inline-flex align-center indigo--text text--darken-1 u-rounded-corners-full">
                                    Success is now completely password-less
                                </span>
                            </div>
                            <div class="text-center px-9">
                                <h2 class="mb-3 text-h5 grey--text text--darken-3 font-weight-bold">We've sent a magic link!</h2>
                                <p class="mb-0 subtitle-2 grey--text text--darken-1">By clicking on the link sent to your email, you will be automatically logged in.</p>
                            </div>
                        </a-sheet>
                    </a-sheet>
                </a-dialog>

                <!-- Notifcation slider -->
                <s-notify></s-notify>

                <template v-if="!isExternalUser">
                    <shared-global-search />

                    <shared-global-overlay v-if="show_global_overlay" />

                    <s-automation-error-popup></s-automation-error-popup>
                </template>
            </template>
        </template>
    </main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { SInternalNavbar, SMagicLinkBanner, SAutomationErrorPopup } from '@/config/config-shared-components'
import LoginLayout from '@/layouts/LoginLayout.vue'
import NavigationLayout from '@/layouts/NavigationLayout.vue'
import SNotify from '@/components/Shared/SharedNotify'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'
import { storage } from '@/helpers/helper-local-storage';
import SInternalNavbarAside from '../components/Shared/SharedInternalNavbarAside.vue'
// import { detectIncognito } from 'detectincognitojs' /* TODO: Implement once websockets is available in backend */
import SharedGlobalOverlay from '../components/Shared/SharedGlobalOverlay.vue'
import SharedGlobalSearch from '../components/Shared/SharedGlobalSearch.vue'
import EventBus from '@/config/config-eventbus'
import { ValidRoutes } from '../helpers/helper-get-valid-routes'

export default {
    mixins: [mixinGetOrganizationPlan],

    components: {
        SNotify,
        SAutomationErrorPopup,
        NavigationLayout,
        LoginLayout,
        SMagicLinkBanner,
        SInternalNavbar,
        SInternalNavbarAside,
        SharedGlobalOverlay,
        SharedGlobalSearch
    },

    data() {
        return {
            hasDesktopNotificationPref: null,
            different_workspace_url: null,
            check_empty_workspace: null,
            show_empty_workspace_error: false,
            dataLoggedUser: null,
            global_loading: true,
            same_previous_route: null,
            show_magic_link_popup: false,
            show_maintenance_msg: true,
            show_maintenance_mode: false,
            show_global_overlay: false,
            org_self_fields: {
                'fields[organizations]': 'id,subscription_plan_id,name,logo,domain,projects_active_count',
                'fields[subscription_plan]': 'id,name,level'
            },
            orgLogo: null,
            heap_script_enabled: process.env.VUE_APP_HEAP_SCRIPT_ENABLED,
            mixpanel_script_enabled: process.env.VUE_APP_MIXPANEL_SCRIPT_ENABLED,
            intercom_script_enabled: process.env.VUE_APP_INTERCOM_SCRIPT_ENABLED,
            pendo_script_enabled: process.env.VUE_APP_PENDO_SCRIPT_ENABLED,
            maintenance_enabled: process.env.VUE_APP_MAINTENANCE_BANNER_ENABLED,
            is_down_for_maintenance: process.env.VUE_APP_MAINTENANCE_MODE_ENABLED,
            maintenance_timeout: process.env.VUE_APP_MAINTENANCE_BANNER_TIMEOUT,
            maintenance_msg: process.env.VUE_APP_MAINTENANCE_BANNER_MESSAGE,
        }
    },

    watch: {
        $route(to, from) {
            if (to.name === from.name) this.same_previous_route = from
        }
    },

    async created() {
        this.global_loading = true
        const token = new URLSearchParams(window.location.search).get('token')
        const redirect = new URLSearchParams(window.location.search).get('redirect')
        if (token) {
            await this.local_validate_token(token, redirect)
            return
        }
        await this.local_verify_auth()
    },

    mounted () {
        this.localCheckForMaintenanceFlags()
        EventBus.$on('global-search-dialog', this.localToggleGlobalOverlay)
    },

    computed: {
        local_user_is_admin() {
            return this.user_self && this.user_self.is_admin === 1
        },

        isExternalUser() {
            return this.user_self && this.user_self.scope === 'external'
        },

        isExternalView () {
            return this.$route.meta.view && this.$route.meta.view === 'external'
        },

        local_is_workspace_page() {
            const { name: name } = this.$route
            const is_workspace_page = name === 'settings-workspace' || name === 'settings-workspace-stages' || name === 'settings-workspace-members'
            is_workspace_page ? '' : this.show_empty_workspace_error = true

            return is_workspace_page
        },

        local_route_is_public() {
            const { meta: { public: publicRoute } } = this.$route
            return publicRoute && (this.dataLoggedUser === null)
        },

        local_pref_has_workspace() {
            return this.preference_list.find(item => item.key === 'user.workspace')
        },

        local_user_workspace_unmatch() {
            const user_workspace_preference = this.preference_list.find(item => item.key === 'user.workspace')
            if (!user_workspace_preference) return false

            const { workspace_id } = this.$route.query // check for "workspace_id" in url
            const workspace_matched = this.workspace_board_list.find(item => item.id === user_workspace_preference.value)
            if (workspace_id) {
                const has_workspace_from_url = this.workspace_board_list.find(item => item.id === workspace_id)
                if (has_workspace_from_url && (workspace_matched && workspace_matched.id !== workspace_id)) {
                    this.different_workspace_url = workspace_id
                    return false
                }
            }
            this.different_workspace_url = null
            return !!workspace_matched // convert to boolean
        },

        local_is_project_view () {
            const { meta: { parent } } = this.$route
            return parent && parent === 'projects'
        },

        local_has_workspace () {
            return this.workspace_board_list && this.workspace_board_list.length
        },

        local_can_show_nav () {
            return !!(this.check_empty_workspace === false && !this.local_is_project_view && this.local_has_workspace)
        },

        local_route_is_logout () {
            return this.$route.name === 'logout'
        },

        local_has_full_width_routes () {
            const { name: name } = this.$route
            const wildCards = ['playground', 'projects-tasks', 'tasks']
            return wildCards.includes(name)
        },

        local_whitelist_route_paths () {
            return ['/logout']
        },

        ...mapGetters('Workspace', {
            workspace_board_list: 'board_list',
        }),

        ...mapGetters('Auth', {
            auth_response: 'response',
        }),

        ...mapState('User', {
            user_self: 'self',
            user_response: 'response'
        }),

        ...mapState('Interface', {
            interface_loader: 'loader',
        }),

        ...mapState('Applet', {
            applet_list: 'list',
        }),

        ...mapState('Slack', {
            slack_status: 'status',
        }),

        ...mapState('MicrosoftTeam', {
            microsoft_team_status: 'status',
        }),

        ...mapState('Preference', {
            preference_list: 'list',
        }),

        ...mapGetters('Preference', {
            preference_workspace: 'pref_workspace',
        }),
    },

    methods: {
        async local_index() {
            await this.auth_user_permission_index()

            const userRole = (this.user_self && this.user_self.role) || null
            if (userRole && userRole.scope === 'internal') {
                this.localShowPaymentStatus()
                this.localFetchSubscriptionPlans()
                await this.mixinFetchOrgPlanDetails()
                // this.localInitializeEcho() /* TODO: Implement once websockets is available in backend */
                this.orgLogo = this.mixinGetOrgPlan({ field: 'logo' })
                await this.workspace_board({ params: { without_pagination: 1 } })
                await this.preference_index()
                await this.local_check_empty_workspace()
                // this.localCheckDesktopNotification() /* TODO: Implement once websockets is available in backend */

                this.local_setup_scripts()

                setTimeout(() => ValidRoutes().configure(), 0)
            }

            if (userRole && userRole.scope === 'external') storage.removeOrgKeys()
        },

        local_setup_scripts () {
            if (this.intercom_script_enabled === 'true') this.local_initialize_intercom()
        },

        localShowPaymentStatus () {
            const { hash } = this.$route
            if (!hash) return

            const hashText = hash.split('#')[1]
            if (hashText === 'no-payment-method') this.$notify('warning', 'Please add the payment method!')
            if (hashText === 'upgrade-successful') this.$notify('success', 'Successfully upgraded!')
            if (hashText === 'upgrade-cancelled') this.$notify('error', 'Cancelled plan upgrade!')

            setTimeout(() => this.$router.push({ name: this.$router.name }), 4000)
        },

        localFetchSubscriptionPlans () {
            const { organization: { subscription_plan: { level } } } = this.user_self || {}
            if (level === 3) return true

            this.subscription_plans_index({ 'fields[subscription_plans]': 'id,level,name,unit_amount,base_amount' })
        },

        async local_initialize_intercom () {
            const { id: user_id, name: userName, email, organization, is_admin, role: { name, source, scope } } = this.user_self || {}
            const stages = await this.localFetchStages()
            const activeIntegrations = await this.localFetchIntegrations()
            const queueProjsCount = stages.find(i => i.status === 'queue').projects_count

            Intercom('boot', {
                app_id: process.env.VUE_APP_INTERCOM_SCRIPT_API_KEY,
                id: user_id,
                email: email,
                name: userName,
                is_admin: is_admin,
                user_type: scope,
                role: { name, role_source_name: source.name },
                organization_id: organization.id,
                organization_slug: organization.slug,
                is_project_queue: queueProjsCount > 0 ? 1 : 0,
                ...activeIntegrations,
            })
        },

        async localFetchStages () {
            let { value: workspaceId } = this.preference_list.find(i => i.key === 'user.workspace') || {}
            if (!workspaceId) workspaceId = this.workspace_board_list[0].id

            const { data: stageList, _status } = await this.stage_index({
                include: 'projects',
                'fields[stages]': 'id,status',
                'filter[workspace_id]': workspaceId,
                stateless: true
            })
            if (_status === 'error' && !stageList) return []
            return _.cloneDeep(stageList)
        },

        async localFetchIntegrations () {
            const activeItems = { salesforce: 0, webhook: 0, jira: 0, hubspot: 0, slack: 0, msTeams: 0 }
            await this.applet_index()
            await this.slack_status_show()
            await this.microsoft_team_status_show()

            this.applet_list.forEach(item => activeItems[item.slug] = item.is_active)
            activeItems['slack'] = this.slack_status.slack_connection_status ? 1 : 0
            activeItems['msTeams'] = this.microsoft_team_status.microsoft_team_connection_status ? 1 : 0

            return {
                is_salesforce_connected: activeItems['salesforce'],
                is_webhook_connected: activeItems['webhook'],
                is_jira_connected: activeItems['jira'],
                is_hubspot_connected: activeItems['hubspot'],
                is_slack_connected: activeItems['slack'],
                is_ms_teams_connected: activeItems['msTeams'],
            }
        },

        async local_check_empty_workspace() {
            if (this.workspace_board_list && this.workspace_board_list.length) {
                const firstAvailWorkspaceId = _.get(this.workspace_board_list, '0.id')
                const savedWorkspaceId = _.get(this.preference_workspace, 'value')
                this.workspace_update_current({ id: savedWorkspaceId || firstAvailWorkspaceId })

                if (!this.local_pref_has_workspace) await this.local_store_workspace_pref(firstAvailWorkspaceId)
                if (this.local_pref_has_workspace && !this.local_user_workspace_unmatch) await this.local_switch_workspace(firstAvailWorkspaceId)
                this.check_empty_workspace = false
            } else {
                this.check_empty_workspace = true
                this.show_empty_workspace_error = true
            }
        },

        async local_store_workspace_pref(workspaceId) {
            await this.preference_store({
                resource_type: 'User',
                value: workspaceId,
                label: 'Workspace',
                key: 'user.workspace'
            })
        },

        async local_switch_workspace(workspaceId) {
            await this.preference_workspace_switch({
                value: this.different_workspace_url || workspaceId,
                resource_type: 'User',
                label: 'Workspace',
                key: 'user.workspace'
            })
        },

        async local_verify_auth() {
            await this.auth_verify()
            if (this.auth_response.status === 'success') await this.local_fetch_loggedin_user()
            else {
                if (!this.$route.meta.public) {
                    let locationPath = window.location.pathname
                    locationPath = this.local_whitelist_route_paths.includes(locationPath) ? '/dashboard/columns' : locationPath
                    const redirectPath = this.$route.query.redirect ? '?redirect=' + this.$route.query.redirect : locationPath
                    if (!this.local_check_route_name('login')) this.$router.replace(`/login?redirect=${redirectPath}`)
                    this.local_stop_loading()
                } else this.local_stop_loading()
            }
        },

        async local_validate_token (token, redirect) {
            await this.auth_validate(token)
            if (this.auth_response.status === 'success') {
                await this.user_me()
                await this.local_index()
                this.dataLoggedUser = this.user_self
                await this.$router.replace(redirect)
                this.local_stop_loading()
            }
            if (this.auth_response.text === 404 || this.auth_response.text === 401) this.local_show_token_validate_error()
        },

        local_show_token_validate_error () {
            this.local_stop_loading()
            this.$router.replace('/login/validate-error')
        },

        async local_fetch_loggedin_user (from_login = false) {
            if (from_login) this.show_magic_link_popup = true
            if (!from_login) {
                await this.user_me()
                if (this.$status(this.user_response)) {
                    this.local_check_route_and_redirect(this.user_self, from_login)
                }
            }
        },

        async local_check_route_and_redirect (user, from_login = false) {
            let redirectPath = new URLSearchParams(window.location.search).get('redirect') || '/dashboard/columns'
            redirectPath = this.local_whitelist_route_paths.includes(redirectPath) || redirectPath === 'logout' ? '/dashboard/columns' : redirectPath
            this.global_loading = true
            this.dataLoggedUser = user

            if (from_login) this.$router.replace(redirectPath).then(_ => this.local_stop_loading())
            if (this.$route.path === '/login' || this.$route.path === '/login/validate') this.$router.replace(redirectPath).then(_ => this.local_stop_loading())

            this.local_index()
            this.local_stop_loading()
        },

        local_stop_loading () {
            this.global_loading = false
        },

        local_get_loggedin_user(is_logged) {
            if (is_logged) this.local_fetch_loggedin_user(true)
        },

        local_check_route_name(route) {
            return this.$route.name === route
        },

        localCheckForMaintenanceFlags () {
            // Maintenance banner
            if (this.maintenance_enabled === 'true') {
                const timeout = parseInt(this.maintenance_timeout)
                setTimeout(() => this.show_maintenance_msg = false, (isNaN(timeout) ? 5 : timeout) * 1000)
            } else this.show_maintenance_msg = false

            // Maintenance mode
            if (this.is_down_for_maintenance) {
                this.show_maintenance_mode = !!(this.is_down_for_maintenance === 'true')
            }
        },

        /* TODO: Implement once websockets is available in backend */
        // localInitializeEcho () {
        //     this.$echo.initialize()
        // },

        /* TODO: Implement once websockets is available in backend */
        // async localCheckDesktopNotification () {
        //     const browser = await detectIncognito()
        //     if (this.$notification.hasDeniedPermission || browser.isPrivate) {
        //         // "null" will hide the request popup to enable desktop notifications
        //         return this.hasDesktopNotificationPref = null
        //     }

        //     this.hasDesktopNotificationPref = this.$notification.hasPermission
        // },

        /* TODO: Implement once websockets is available in backend */
        // localAskForNotificationPermission () {
        //     this.$notification.requestPermission()
        //         .then(res => {
        //             this.hasDesktopNotificationPref = true
        //             this.localPlaySound()
        //         })
        //         .catch(e => this.hasDesktopNotificationPref = null)
        // },

        // localPlaySound () {
        //     const sound = new Audio(Blink)
        //     sound.play()
        // },

        localToggleGlobalOverlay (value) {
            this.show_global_overlay = value
        },

        ...mapActions('Applet', {
            applet_index: 'index',
        }),

        ...mapActions('Stage', {
            stage_index: 'index',
        }),

        ...mapActions('Auth', {
            auth_validate: 'validate',
            auth_verify: 'verify',
            auth_user_permission_index: 'userPermissions',
        }),

        ...mapActions('User', {
            user_me: 'me',
        }),

        ...mapActions('Workspace', {
            workspace_board: 'board',
            workspace_update_current: 'switch_workspace'
        }),

        ...mapActions('Preference', {
            preference_index: 'index',
            preference_store: 'store',
            preference_workspace_switch: 'switch_workspace',
        }),

        ...mapActions('SystemSubscriptionPlans', {
            subscription_plans_index: 'index',
        }),

        ...mapActions('Slack', {
            slack_status_show: 'status',
        }),

        ...mapActions('MicrosoftTeam', {
            microsoft_team_status_show: 'status',
        }),
    }
}
</script>

<style lang="scss" scoped>
.c-notify-permission {
    position: fixed;
    bottom: 24px;
    left: 24px;
    z-index: 2;
    animation: notify-animate 0.5s ease-out-in;
}

@keyframes notify-animate {
    0% {
        bottom: -200px;
        transform: scale(1);
    }
    50% {
        bottom: 28px;
        transform: scale(1.05);
    }
    100% {
        bottom: 24px;
        transform: scale(1);
    }
}
</style>

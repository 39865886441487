<template>
    <a-main class="">
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container class="mt-8" v-if="local_loading">
            <a-layout align-center>
                <a-flex xs12>
                    <h2 class="headline grey--text text--darken-2 text-center mt-4 font-italic">Loading...</h2>
                </a-flex>
            </a-layout>
        </a-container>
        <template v-else>
            <template v-if="$plan('sso:no-access')">
                <div class="text-center mb-8">
                    <a-sheet class="transparent mx-auto" width="500" height="500">
                        <img src="../../assets/images/sso-upgrad-banner.svg" class="d-block u-wfull u-hfull" alt="Plan Upgrade">
                    </a-sheet>
                    <h2 class="md-heading-4 font-weight-bold text-center">Success Single Sign-on</h2>
                    <p class="md-subtitle-1 text-center grey--text text--darken-2 mt-3 mx-auto" style="max-width: 500px;">Looks like your current plan doesn't support this option. Please upgrade your plan to access Single sign-on along with other additional features.</p>
                    <a-btn @click="local_upgrade_plan()" color="yellow darken-3" class="pa-3 mt-6 font-weight-medium" dark depressed height="32">
                        <a-icon class="mr-1" size="16" left>flash_on</a-icon> Upgrade
                    </a-btn>
                </div>
            </template>

            <template v-else>
                <a-container grid-list-xl v-if="local_has_sso">
                    <a-layout justify-start align-start>
                        <g-page-header
                            title="Single Sign-on"
                            subtitle="Manage and configure your SSO Identity Provider."
                            :loading="loading"
                            icon="vpn_key"
                            class="px-4 mb-1 mt-2"
                        >
                        </g-page-header>
                    </a-layout>

                    <a-layout justify-start align-start>
                        <a-flex xs12>
                            <a-card class="u-elevation-custom-1">
                                <template v-for="(list, index) in localSSOProviderList">
                                    <a-card-text class="pa-5" :key="index">
                                        <a-layout align-center>
                                            <a-flex>
                                                <h2 class="md-heading-6 grey--text text--darken-2 font-weight-normal">{{ list.title }}</h2>
                                                <p class="md-subtitle-2 mb-0 grey--text mt-1">{{ list.subtitle }}</p>
                                            </a-flex>
                                            <a-spacer></a-spacer>
                                            <a-flex shrink>
                                                <template v-if="list.type === 'sso_status'">
                                                    <p class="text-uppercase font-weight-bold mb-0 green--text text--darken-1"><a-icon color="green darken-1" size="20" class="u-icon-nudge">check_circle</a-icon> Active</p>
                                                </template>
                                                <template v-if="list.type === 'sso_provider'">
                                                    <p class="md-heading-6 mb-0 grey--text  text--darken-2">{{ local_sso_provider[user_self.organization.sso_provider] }}
                                                        <a :href="sso_url" class="ml-1">
                                                            <a-icon color="grey" size="20" class="u-icon-nudge ml-1">edit</a-icon>
                                                        </a>
                                                    </p>
                                                </template>
                                                <template v-if="list.type === 'sso_domain'">
                                                    <p class="md-heading-6 mb-0 grey--text  text--darken-2">{{ user_self.organization.domain }}
                                                        <a :href="sso_url" class="ml-1">
                                                            <a-icon color="grey" size="20" class="u-icon-nudge ml-1">edit</a-icon>
                                                        </a>
                                                    </p>
                                                </template>
                                                <template v-if="list.type === 'sso_signup_status'">
                                                    <g-toggle custom-bg dense-xl @change="local_organization_toggle('automatic_signup', $event)"  v-model="user_self.organization.automatic_signup" class="d-inline-flex align-center" color="green darken-1">
                                                    </g-toggle>
                                                </template>
                                                <template v-if="list.type === 'sso_user_role'">
                                                    <p class="md-heading-6 mb-0 grey--text  text--darken-2">{{ local_default_role ? local_default_role.name : '' }}
                                                        <router-link :to="{name: 'settings-roles'}"><a-icon color="grey" size="20" class="u-icon-nudge ml-1">edit</a-icon></router-link>
                                                    </p>
                                                </template>
                                                <template v-if="list.type === 'sso_magic_link_status'">
                                                    <g-toggle custom-bg dense-xl @change="local_organization_toggle('magic_link_signin', $event)"  v-model="user_self.organization.magic_link_signin" class="d-inline-flex align-center" color="green darken-1">
                                                    </g-toggle>
                                                </template>
                                            </a-flex>
                                        </a-layout>
                                    </a-card-text>
                                    <a-divider :key="index + '-divider'"></a-divider>
                                </template>
                            </a-card>
                        </a-flex>
                    </a-layout>
                </a-container>

                <a-container grid-list-xl v-else>
                    <a-layout justify-center align-center class="py-0">
                        <a-flex xs5 class="py-0">
                            <a-img :src="require('../../assets/images/illustration-sso.svg')" contain></a-img>
                        </a-flex>
                    </a-layout>
                    <a-layout justify-center align-center>
                        <a-flex xs6>
                            <h2 class="md-heading-4 font-weight-bold text-center">Let's setup Single Sign-on</h2>
                            <p class="md-subtitle-1 text-center grey--text text--darken-1">Single sign-on helps you simplify the authentication and new user onboarding process. Success uses WorkOS to connect with identity providers and supports Google, Okta, Microsoft ADFS, Azure AD and OneLogin.</p>
                        </a-flex>
                    </a-layout>

                    <a-layout justify-center align-center>
                        <a-flex shrink align-self-end class="mx-3">
                            <img src="../../assets/images/logo-google-bw-full.svg" style="max-height: 28px; opacity: .3;">
                        </a-flex>
                        <a-flex shrink align-self-start class="mx-3">
                            <img src="../../assets/images/logo-okta-bw-full.svg" style="max-height: 24px; opacity: .3;">
                        </a-flex>
                        <a-flex shrink class="mx-3">
                            <img src="../../assets/images/logo-microsoft-bw-full.svg" style="max-height: 24px; opacity: .3;">
                        </a-flex>
                        <a-flex shrink class="mx-3">
                            <img src="../../assets/images/logo-azure-bw-full.svg" style="max-height: 24px; opacity: .3;">
                        </a-flex>
                        <a-flex shrink align-self-end class="mx-3">
                            <img src="../../assets/images/logo-onelogin-bw-full.svg" style="max-height: 24px; opacity: .3;">
                        </a-flex>
                    </a-layout>

                    <a-layout justify-center align-center v-if="(user_self && user_self.organization && user_self.organization.workos_organization_id) && $plan('sso:full-access')">
                        <a-flex xs2 class="mt-4">
                            <a-btn :href="sso_url" color="black darken--1" class="elevation-0 u-rounded-corners" style="letter-spacing: 1px" dark block>
                                <a-icon size="20" class="mr-2">device_hub</a-icon>
                                Configure SSO
                            </a-btn>
                        </a-flex>
                        <!--<a-flex xs8 class="mt-4" v-if="$plan('sso:no-access')">
                            <p class="md-subtitle-2 font-weight-medium mb-0 text-center grey--text text--darken-3">
                                Your current <strong class="secondary--text">{{ localCurrentPlan.name }}</strong> plan doesn't support this action.
                            </p>
                            <p class="md-subtitle-2 font-weight-regular mb-0 text-center grey--text text--darken-2">
                                Please upgrade to activate SSO for your organization account.
                            </p>
                        </a-flex>-->
                    </a-layout>
                </a-container>
            </template>
        </template>

        <!-- User Upgrade Popup -->
        <a-dialog max-width="400" v-model="dialog_user_upgrade_modal">
            <SUserUpgradeModal :isOpen="dialog_user_upgrade_modal" @close="dialog_user_upgrade_modal = false" />
        </a-dialog>

        <!-- Admin Upgrade Popup -->
        <a-dialog max-width="600" v-model="dialog_admin_upgrade_modal">
            <SAdminUpgradeModal
                :isOpen="dialog_admin_upgrade_modal"
                @upgrade="{}"
                @close="dialog_admin_upgrade_modal = false"
            />
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { SUserUpgradeModal, SAdminUpgradeModal } from '@/config/config-shared-components'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'

export default {
    mixins: [mixinGetOrganizationPlan],

    components: {
        SUserUpgradeModal,
        SAdminUpgradeModal
    },

    data () {
        return {
            dialog_user_upgrade_modal: false,
            dialog_admin_upgrade_modal: false,
            local_loading: true,
            sso_url: process.env.VUE_APP_CORE_API_URL + '/' + window.location.hostname.split('.')[0] + '/internal/sso/configure',
            local_sso_provider: {
                'GoogleOAuth': 'Google',
                'OktaSAML': 'Okta',
                'AzureSAML': 'Azure',
                'ADFSSAML': 'ADFS',
                'OneLoginSAML': 'OneLogin',
            },
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        localSSOProviderList () {
            const list = [
                { title: 'Single Sign-On Connection Status', subtitle: 'Indicates the current status of SSO connection',  type: 'sso_status' },
                { title: 'Identity Provider', subtitle: 'Indicates the current active Identity Provider (IdP) connected',  type: 'sso_provider' },
                { title: 'Domain', subtitle: 'Organization domain name for mapping the internal users',  type: 'sso_domain' },
                { title: 'Automatic Sign Up', subtitle: 'Organization users can login & create account by Just-in-Time (JIT) user provisioning',  type: 'sso_signup_status' },
                { title: 'Default Role', subtitle: 'Default role for Just-in-Time (JIT) user provisioning',  type: 'sso_user_role' },
                { title: 'Magic Link Sign In', subtitle: 'Email sign in for organization level users',  type: 'sso_magic_link_status' },
            ]
            return list
        },

        local_default_role() {
            return this.role_list.find(item => item.is_default)
        },

        local_has_sso() {
            return this.user_self && this.user_self.organization && this.user_self.organization.workos_organization_id && this.user_self.organization.sso_provider
        },

        localCurrentPlan () {
            const { subscription_plan } = this.mixinGetOrgPlan() || {}
            return subscription_plan
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapGetters('Auth',{
            auth_response: 'response',
            auth_organization: 'organization',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Organization', {
            organization_item: 'item',
            organization_response: 'response',
        }),

        ...mapState('Role', {
            role_list: 'list',
            role_entry_point_item: 'entry_point_item',
        }),
    },

    methods: {
        async local_index () {
            if (this.$plan('sso:no-access')) return this.local_loading = false

            this.role_index()
            // await this.auth_get_organization()
            await this.user_me()
            if ((this.user_self && this.user_self.organization && this.user_self.organization.workos_organization_id) && !this.user_self.organization.sso_provider) {
                await this.sso_index()
                if (this.auth_response && this.auth_response.status === 'success') {
                    await this.user_me()
                    this.local_loading = false
                }
            }
            this.local_loading = false
        },

        local_setup_sso () {
            this.sso_setup({
                slug: window.location.href,
                workos_organization_id: this.user_self.organization.workos_organization_id
            })
        },

        async local_organization_toggle (field, value) {
            const organization_item = this.user_self.organization
            organization_item[field] = value ? 1 : 0
            await this.organization_update(organization_item)
            if (this.organization_response && this.organization_response.status !== 'success') return
            this.user_me()
        },

        local_upgrade_plan () {
            const { is_admin } = this.user_self || {}

            this.dialog_admin_upgrade_modal = false
            this.dialog_user_upgrade_modal = false

            if (is_admin === 1) this.dialog_admin_upgrade_modal = true
            else this.dialog_user_upgrade_modal = true
        },

        ...mapActions('SSO', {
            sso_index: 'index',
            sso_setup: 'setup',
        }),

        ...mapActions('User', {
            user_me: 'me',
        }),

        ...mapActions('Auth',{
            auth_get_organization: 'getOrganization',
        }),

        ...mapActions('Organization', {
            organization_update: 'update',
        }),

        ...mapActions('Role', {
            role_index: 'index',
            role_entry_point_show: 'entry_point_show',
        }),
    },
}
</script>

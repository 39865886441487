<template>
    <a-sheet height="40" class="primary d-inline-flex u-rounded-corners-2xl">
        <a-btn @click="$emit('change', { slug: currentEditModule === 'task' ? 'new-task' : 'new-milestone' })" depressed class="primary white--text u-flex-center-y px-3 mx-0 c-add-task-btn" height="100%">
            <a-icon size="20" class="mr-1 u-no-select">add</a-icon>
            <span class="u-no-select">New</span>
        </a-btn>
        <a-sheet class="white" width="1"></a-sheet>
        <a-menu bottom offset-y nudge-bottom="4" content-class="u-z12">
            <template v-slot:activator="{ on }">
                <a-btn v-on="on" depressed class="primary" height="100%" width="36" min-width="36">
                    <a-icon color="white" size="20">keyboard_arrow_down</a-icon>
                </a-btn>
            </template>
            <a-list class="u-list-std" style="max-height: 250px;">
                <a-list-item class="u-flex-center-y" v-for="item in localMenuList" :key="item.value" @click="$emit('change', { slug: item.value, item })">
                    <a-icon size="20" color="grey darken-4" :outlined="item.outlined">{{ item.icon }}</a-icon>
                    <span class="md-body-2 grey--text text--darken-3 ml-2">{{ item.text }}</span>
                </a-list-item>
            </a-list>
        </a-menu>
    </a-sheet>
</template>

<script>
export default {
    props: {
        currentEditModule: {
            type: String
        }
    },

    computed: {
        localMenuList () {
            const menus = [
                { text: 'Import from CSV', value: 'import-csv', icon: 'file_open', outlined: true },
                { text: 'Import from Templates', value: 'import-template', icon: 'library_books', outlined: true },
            ]

            if (this.currentEditModule !== 'milestone') {
                menus.unshift({ text: 'Add new Milestone', value: 'new-milestone', icon: 'add', outlined: true })
            }

            return menus
        }
    },
}
</script>

<template>
    <div>
        <div class="u-flex align-center justify-space-between">
            <div class="u-flex align-center">
                <h3 class="c-letter-space text-uppercase md-caption font-weight-medium grey--text text--darken-2">
                    Attachments
                    <span
                        class="ml-1 primary lighten-2 u-rounded-corners-full white--text pa-1 px-2"
                    >
                    {{ localGetAttachmentMeta('total') ? localGetAttachmentMeta('total') : 0 }}
                    </span>
                </h3>
                <div
                    v-if="localGetAttachmentData('upload_list') && localGetAttachmentData('upload_list').length"
                    class="u-flex align-center ml-2 amber darken-2 u-rounded-corners-full"
                    style="padding: 3px 8px 3px 4px"
                >
                    <div
                        style="width: 20px; height: 20px"
                        class="u-flex align-center justify-center u-rounded-corners-full white"
                    >
                        <span class="u-font-weight-semibold amber--text text--darken-2 md-caption">{{ localGetValidFilesCount('upload_list') }}</span>
                    </div>
                    <span class="ml-2 white--text md-body-2">Pending</span>
                </div>
            </div>
            <div v-if="canUpdate" class="u-flex align-center">
                <a-menu
                    content-class="u-elevation-custom-1"
                    nudge-left="224"
                    nudge-top="55"
                    max-width="234"
                    open-on-hover
                >
                    <template v-slot:activator="{ on }">
                        <div v-on="on" class="u-flex align-center mr-4 u-cursor-pointer">
                            <a-icon size="16" color="grey darken-1">help_outline</a-icon>
                            <span class="grey--text text--darken-2 md-caption font-weight-medium ml-1">Allowed file formats</span>
                        </div>
                    </template>
                    <div>
                        <div class="white py-2 px-3 md-caption font-weight-medium">
                            PNG, DOC, PDF, JPG, PPT, DOCX, PPTX, ZIP, TXT, XLS, XLXS, CSV, GIF
                        </div>
                    </div>
                </a-menu>
                <a-btn
                    height="32"
                    color="indigo lighten-5"
                    class="u-font-weight-semibold pa-0 pl-3 pr-4"
                    @click="localInitiate()"
                    :disabled="updateLoading"
                    depressed
                >
                    <div class="c-attachment-icon">
                        <a-icon size="20" class="font-weight-medium" color="indigo darken-1">attach_file</a-icon>
                    </div>
                    <span :class="[updateLoading ? 'grey--text' : 'indigo--text', 'text--darken-1 d-inline-block ml-2']">Attach</span>
                </a-btn>
            </div>
            <input type="file" ref="doc_upload" name="doc_upload" class="d-none" multiple @input="localUpdateUploadList()" :accept="localFileTypes">
        </div>
        <template v-if="loading">
            <div class="mt-6">
                <div v-for="count in 1" class="mb-1" :key="count">
                    <loader-template height="52" width="100%"></loader-template>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="filesList && !filesList.length" class="py-5 mt-8" style="border: 1px solid #E0E0E0">
                <div class="text-center grey--text text--darken-1 md-body-2 font-weight-medium">
                    <template v-if="canUpdate">
                        Drag and drop your files anywhere on this page to attach them
                    </template>
                    <template v-else>
                        No Attachments Found!
                    </template>
                </div>
            </div>
            <div v-else class="mt-6">
                <div v-for="(file, index) in filesList" :key="file.id">
                    <div
                        v-if="file.name"
                        class="py-2 px-3 u-flex justify-space-between align-center"
                        style="height: 52px"
                        :class="[file && file.error || $response(localGetAttachmentData('response'), `files.${index}`) ? 'red lighten-5' : '', flat ? 'u-border-b' : 'u-shadow white']"
                        :style="[ flat ? { 'margin-bottom': '0px' } : { 'margin-bottom': '1px' }]"
                    >
                        <div class="u-flex align-center">
                            <v-icon v-if="localIconFormat(file)" class="mr-3" size="16" :color="localIconFormat(file).color">{{ localIconFormat(file).icon }}</v-icon>
                            <img
                                v-else
                                class="mr-3"
                                src="../../../src/assets/images/icon-file-unsupported.svg"
                                width="16"
                                alt="Unkown Filetype"
                                style="display: block; color: '#f57c00'"
                            >
                            <span class="grey--text text--darken-2 md-body-2 text-truncate" :title="file.name">
                                <template v-if="file && file.error">
                                    {{ file.name | truncateText(25) }}
                                </template>
                                <template v-else>
                                    {{ file.name | truncateText(localIsLgdown ? 50 : 60)}}
                                </template>
                            </span>
                        </div>
                        <template v-if="!file.toBeUploaded">
                            <div class="u-flex-center-y">
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a-btn
                                            v-on="on"
                                            :disabled="updateLoading"
                                            color="grey"
                                            :class="[autoUpdate ? 'mr-2' : 'mr-3']"
                                            @click="localRequestAttachmentPreview(file, 'select')"
                                            text icon
                                        >
                                            <a-icon size="16" color="grey">visibility</a-icon>
                                        </a-btn>
                                    </template>
                                    <span>Preview</span>
                                </a-tooltip>
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a-btn
                                            v-on="on"
                                            :disabled="updateLoading"
                                            color="grey"
                                            :class="[autoUpdate ? 'mr-2' : 'mr-3']"
                                            @click="localRequestAttachmentDownload(file.id)"
                                            text icon
                                        >
                                            <a-icon size="16" color="grey">cloud_download</a-icon>
                                        </a-btn>
                                    </template>
                                    <span>Download</span>
                                </a-tooltip>
                                <template v-if="autoUpdate">
                                    <a-menu
                                        v-if="canUpdate"
                                        :close-on-content-click="false"
                                        :disabled="updateLoading"
                                        max-width="340"
                                        bottom right offset-y
                                    >
                                        <template v-slot:activator="{ on }">
                                            <a-btn icon text small color="grey" v-on="on">
                                                <a-icon size="16">delete</a-icon>
                                            </a-btn>
                                        </template>
                                        <div class="white">
                                            <div class="pa-5">
                                                <div class="md-body-1 font-weight-medium mb-2">Are you sure to delete this attachment?</div>
                                                <p class="md-body-2 mb-4">This will delete the attachment permanently.</p>
                                                <a-btn
                                                    class="ma-0"
                                                    color="red"
                                                    @click="localDeleteItem(file.id)"
                                                    :loading="updateLoading"
                                                    dark small depressed
                                                >
                                                    Confirm Delete
                                                </a-btn>
                                            </div>
                                        </div>
                                    </a-menu>
                                </template>
                                <template v-else>
                                    <a-tooltip bottom v-if="currentDeleteItem === file.id">
                                        <template v-slot:activator="{ on }">
                                            <div @click="localDeleteItem(file.id)" v-on="on"
                                                class="u-rounded-corners-full u-cursor-pointer c-attachment-delete-btn"
                                            >
                                                <a-icon size="16" color="red">delete</a-icon>
                                            </div>
                                        </template>
                                        <span>Confirm Delete?</span>
                                    </a-tooltip>
                                    <a-tooltip bottom min-width="100" v-else-if="canUpdate" :disabled="updateLoading">
                                        <template v-slot:activator="{ on }">
                                            <div @click="!updateLoading ? localSelectDeleteItem(file.id) : {}" v-on="on"
                                                :class="[{'u-cursor-pointer': !updateLoading}, 'u-rounded-corners-full c-attachment-delete-btn']"
                                            >
                                                <a-icon size="16" color="grey">delete</a-icon>
                                            </div>
                                        </template>
                                        <div class="text-center">Delete</div>
                                    </a-tooltip>
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            <div class="u-flex align-center">
                                <template v-if="autoUpdate">
                                    <template v-if="(file && file.error) || $response(localGetAttachmentData('response'), `files.${index}`)">
                                        <span class="md-caption red--text text--lighten-1 mx-6">
                                            <template v-if="file && file.error">
                                                {{ file.errorMessage }}
                                            </template>
                                            <template v-else>
                                                {{ $response(localGetAttachmentData('response'), `files.${index}`) }}
                                            </template>
                                        </span>
                                    </template>
                                </template>
                                <template v-else>
                                    <a-tooltip
                                        v-if="(file && file.error) || $response(localGetAttachmentData('response'), `files.${index}`)"
                                        bottom
                                    >
                                        <template v-slot:activator="{ on }">
                                            <a-icon
                                                v-on="on"
                                                size="18"
                                                color="red lighten-1"
                                                class="u-cursor-pointer"
                                            >
                                                error
                                            </a-icon>
                                        </template>
                                        <span>
                                            <template v-if="file && file.error">
                                                {{ file.errorMessage }}
                                            </template>
                                            <template v-else>
                                                {{ $response(localGetAttachmentData('response'), `files.${index}`) }}
                                            </template>
                                        </span>
                                    </a-tooltip>
                                    <div class="mr-6 u-cursor-pointer">
                                        <a-tooltip bottom nudge-left="176">
                                            <template v-slot:activator="{ on }">
                                                <a-img
                                                    v-on="on"
                                                    :src="require('../../assets/images/icon-upload-pending.svg')"
                                                    width="20"
                                                    class="grey--text ml-6"
                                                >
                                                </a-img>
                                            </template>
                                            <span>This file will be uploaded once this page is saved</span>
                                        </a-tooltip>
                                    </div>
                                </template>
                                <span class="md-caption grey--text text--darken-1 mr-6">{{ file.size | sizer }}</span>
                                <a-btn v-if="!updateLoading && !autoUpdate" text icon color="grey">
                                    <a-icon size="16" color="grey" @click="localClearFile(file.id)">clear</a-icon>
                                </a-btn>
                                <a-progress-circular
                                    v-else
                                    color="blue lighten-2"
                                    class="mr-2"
                                    size="18"
                                    width="2"
                                    :indeterminate="updateLoading"
                                >
                                </a-progress-circular>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="c-load-more__container mt-4" v-if="!updateLoading && (localGetAttachmentMeta('total') > localGetAttachmentMeta('attachment_list_count'))">
                <div class="c-load-more__bar">
                    <a-divider :class="[autoUpdate ? 'lighten-3' : 'lighten-2', 'grey']"></a-divider>
                </div>
                <a-btn elevation="1" @click="$emit('loadMore')" max-width="85" rounded class="c-load-more__button" height="24">
                    <a-icon size="12" color="grey darken-1">autorenew</a-icon>
                    <span class="md-overline grey--text text--darken-2 font-weight-medium">Load more</span>
                </a-btn>
            </div>
        </template>

        <!-- Download dialog -->
        <a-dialog v-model="dialogAttachmentDownloader" persistent max-width="480">
            <SAttachmentDownloader
                :loading="mixinIsLoading('download-link')"
                :link="localGetAttachmentData('download_link')"
                @close="dialogAttachmentDownloader = false"
            >
            </SAttachmentDownloader>
        </a-dialog>

        <!-- View dialog -->
        <a-dialog v-model="dialogAttachmentViewer" max-width="960">
            <SAttachmentViewer
                :item="localAttachmentItem"
                :isOpen="dialogAttachmentViewer"
                :list="localGetAttachmentData('list')"
                :link="localGetAttachmentData('preview_link')"
                :loading="mixinIsLoading('preview-link')"
                :meta="localGetAttachmentData('meta')"
                @navigate="localNavigateView"
                @close="dialogAttachmentViewer = false"
            >
            </SAttachmentViewer>
        </a-dialog>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import LoaderTemplate from '../Loader/LoaderTemplate.vue'
import SAttachmentDownloader from '../Attachments/SharedAttachmentDownloader.vue'
import SAttachmentViewer from '../Attachments/SharedAttachmentViewer.vue'
import mixinLoader from '@/mixins/mixin-module-loading-setup'

export default {
    name: 'SharedAttachmentSection',

    mixins: [mixinLoader],

    components: { LoaderTemplate, SAttachmentDownloader, SAttachmentViewer },

    props: {
        list: {
            type: Array,
            required: true
        },

        customMetaItem: {
            type: Object,
            default: null
        },

        dropTransferList: {
            type: Array
        },

        sourceType: {
            type: String,
            required: true
        },

        sourceId: {
            type: String,
            required: true
        },

        updateLoading: {
            type: Boolean,
            required: true
        },

        loading: {
            type: Boolean,
            required: true
        },

        isExternal: {
            type: Boolean,
            default: false
        },

        flat: {
            type: Boolean,
            default: false
        },

        canUpdate: {
            type: Boolean,
            required: true
        },

        autoUpdate: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            localFileTypes: '.jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx,.csv,.ppt,.pptx,.txt,.zip',
            filesList: [],
            fileExtIconFormats: [
                { extensions: ['jpg', 'jpeg', 'png', 'gif'], type: 'image', icon: 'image', color: '#FF7043' },
                { extensions: ['pdf', 'docx', 'doc', 'zip', 'txt'], type: 'document', icon: 'description', color: '#7E57C2' },
                { extensions: ['ppt', 'pptx'], type: 'presentation', icon: 'article', color: '#42A5F5' },
                { extensions: ['xlsx', 'xls', 'csv'], type: 'spreadsheet', icon: 'article', color: '#43A047' },
            ],
            currentDeleteItem: null,
            deleteTimeout: null,
            dialogAttachmentDownloader: false,
            dialogAttachmentViewer: false,
            attachment_fields: 'id,path,name,size,extension',
            localAttachmentItem: {},
            totalUploadList: []
        }
    },

    watch: {
        list: {
            handler (newVal, oldVal) {
                this.localIndex()
            },
            immediate: true,
            deep: true
        },

        dropTransferList (newVal, oldVal) {
            if (newVal) this.localUpdateUploadList('drop')
        }
    },

    computed: {
        localIsLgdown () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        localValidFilesCount () {
            let count = 0
            const validFiles = this.localGetAttachmentData('upload_list').filter(file => {
                return !file.error
            })
            if (!this.$status(this.localGetAttachmentData('response'))) {
                if (this.localGetAttachmentData('response') && !this.localGetAttachmentData('response').server) return
                const keys = Object.keys(this.localGetAttachmentData('response').server.errors)
                count = _.size(keys)
            }
            count = _.size(validFiles) - count
            return count
        },

        ...mapState('Attachment', {
            attachment_list: 'list',
            attachment_upload_list: 'upload_list',
            attachment_meta: 'meta',
            attachment_preview_link: 'preview_link',
            attachment_download_link: 'download_link',
            attachment_response: 'response'
        }),

        ...mapState('AttachmentExternal', {
            attachment_list_external: 'list',
            attachment_upload_list_external: 'upload_list',
            attachment_meta_external: 'meta',
            attachment_preview_link_external: 'preview_link',
            attachment_download_link_external: 'download_link',
            attachment_response_external: 'response'
        })
    },

    methods: {
        localIndex () {
            this.filesList = _.cloneDeep(this.list)
        },

        localGetAttachmentData (field) {
            const prefix = 'attachment_'
            return this.isExternal ? this[prefix + field + '_external'] : this[prefix + field]
        },

        localGetAttachmentAction (action) {
            const prefix = 'attachment_'
            return this.isExternal ? this[prefix + action + '_external'] : this[prefix + action]
        },

        localGetAttachmentMeta (type) {
            if (!this.localGetAttachmentData('meta')) return
            if (type === 'attachment_list_count') {
                return this.customMetaItem ? this.customMetaItem[type] : _.size(this.localGetAttachmentData('list'))
            }
            return !this.customMetaItem ? this.localGetAttachmentData('meta')[type] : this.customMetaItem[type]
        },

        localInitiate () {
            this.$refs.doc_upload.value = null
            this.$refs.doc_upload.click()
        },

        localUpdateUploadList (type) {
            const newFilesList = type === 'drop' ? Array.from(this.dropTransferList) : Array.from(this.$refs.doc_upload.files)

            newFilesList.forEach(file => {
                this.$appendId(file)
                file.toBeUploaded = true
            })

            if (type === 'drop' && this.localCheckUnsupportedFormats(newFilesList)) {
                this.$notify('warning', "Sorry, we can't attach some files. Please ensure they are of supported file types", { maxWidth: 400 })
            }

            const sizeValidatedList = this.localIdentifyLargeFiles(newFilesList)
            const formatValidatedFiles = this.localIdentifySupportedFormats(sizeValidatedList)
            this.totalUploadList = [...formatValidatedFiles, ...this.localGetAttachmentData('upload_list')]
            if (this.localGetValidFilesCount('dropList') > 5) return this.$notify('warning', 'Sorry, you can only attach up to 5 files at a time')

            this.$emit('dropTransfer', { list: [...this.totalUploadList, ...this.localGetAttachmentData('list') ], transferFiles: [...formatValidatedFiles] })
        },

        localGetValidFilesCount (type) {
            const list = type === 'dropList' ? this.totalUploadList : this.localGetAttachmentData(type)
            let count = 0
            const validFiles = list.filter(file => {
                return !file.sizeError
            })
            if (!this.$status(this.localGetAttachmentData('response'))) {
                if (this.localGetAttachmentData('response') && !this.localGetAttachmentData('response').server) return
                const keys = Object.keys(this.localGetAttachmentData('response').server.errors)
                count = _.size(keys)
            }
            count = _.size(validFiles) - count
            return count
        },

        localIdentifyLargeFiles (list) {
            const files = _.cloneDeep(list)
            files.forEach(file => {
                if (this.localValidateFileSize(file.size, 102400)) {
                    file.error = true
                    file.errorType = 'size'
                    file.errorMessage = this.autoUpdate ? 'File size has exceeded 100 MB' : 'This file will not be attached since the file size exceeds 100 MB'
                }
            })
            return files
        },

        localValidateFileSize (size, maxSize, roundTo = 2) {
            if (!size) return ''
            const integerByte = parseInt(size)

            const fileSize = (integerByte / 1024).toFixed(roundTo)
            return (fileSize > maxSize)
        },

        localClearFile (id) {
            this.$emit('remove', id)
        },

        localSelectDeleteItem (id) {
            clearTimeout(this.deleteTimeout)
            this.currentDeleteItem = id
            this.deleteTimeout = setTimeout(() => {
                this.currentDeleteItem = null
            }, 3000);
        },

        localDeleteItem (id) {
            this.currentDeleteItem = null
            this.$emit('delete', id)
        },

        localIconFormat (file) {
            const ext = this.localGetFileExt(file.name)
            return this.fileExtIconFormats.find(item => {
                return item.extensions.includes(ext)
            })
        },

        localIdentifySupportedFormats (list) {
            const files = _.cloneDeep(list)
            files.forEach(file => {
                if (!this.localIsFormatSupported(file.name)) {
                    file.error = true
                    file.errorType = 'format'
                    file.errorMessage = this.autoUpdate ? 'File is of unsupported format' : 'This file will not be attached since the file is of unsupported format'
                }
            })
            return files
        },

        localCheckUnsupportedFormats(list) {
            const unsupportedFileList = list.filter(file => {
                return !this.localIsFormatSupported(file.name)
            })
            return _.some(unsupportedFileList)
        },

        localIsFormatSupported (fileName) {
            const fileExt = fileName.split('.').pop()
            return this.localFileTypes.includes(`.${fileExt}`)
        },

        localGetFileExt (fileName) {
            return fileName.split('.').pop()
        },

        async localRequestAttachmentDownload (id) {
            this.mixinSetLoading('download-link')
            this.localGetAttachmentAction('clear')()
            this.dialogAttachmentDownloader = true
            const params = {
                'fields[attachments]': this.attachment_fields
            }
            await this.localGetAttachmentAction('download')({ id, params })
            this.mixinResetLoading('download-link')
        },

        async localRequestAttachmentPreview (file, type) {
            this.mixinSetLoading('preview-link')
            this.localGetAttachmentAction('clear')()
            this.dialogAttachmentViewer = true
            const params = {
                'fields[attachments]': this.attachment_fields
            }
            this.localAttachmentItem = _.cloneDeep(file)

            const index = _.findIndex(this.list, { id: file.id })
            if (index === this.list.length-1 && this.list.length !== this.localGetAttachmentMeta.total && type === 'select') this.$emit('loadMore')

            await this.localGetAttachmentAction('preview')({ id: file.id, params })
            this.mixinResetLoading('preview-link')
        },

        async localNavigateView (params) {
            const { direction, item } = params || {}
            const index = _.findIndex(this.list, { id: item.id })
            if (index === -1) return
            if (direction === 'left' && index === 0) return
            if (direction === 'right' && index === this.list.length-1) return

            const nextFile = this.list[index+1]
            const prevFile = this.list[index-1]

            if (index+1 === this.list.length-1) this.$emit('loadMore')
            const file = direction === 'right' ? nextFile : prevFile

            this.localRequestAttachmentPreview (file, 'navigate')
        },

        ...mapActions('Attachment', {
            attachment_download: 'download',
            attachment_preview: 'preview',
            attachment_clear: 'clear'
        }),

        ...mapActions('AttachmentExternal', {
            attachment_download_external: 'download',
            attachment_preview_external: 'preview',
            attachment_clear_external: 'clear'
        })
    }
}
</script>

<style scoped>
.c-attachment-icon {
    transform: rotate(45deg) !important;
}
.c-load-more__container {
    height: 24px;
    position: relative;
    width: 100%;
}
.c-load-more__bar {
    position: absolute;
    top: 12px;
    width: 100%;
}
.c-load-more__button {
    position: absolute;
    top: 0px;
    left: calc(50% - 42.5px);
}
.c-attachment-delete-btn {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
}
.c-attachment-delete-btn:hover {
    background-color: #fafafafb;
}
</style>

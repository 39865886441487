<template>
    <div class="t-p-2" v-bind="$attrs" v-on="$listeners">
        <slot name="format-bold" v-bind:execute="editor.chain().focus().toggleBold().run" v-bind:isActive="editor.isActive('bold')">
            <a-menu offset-y>
                <template v-slot:activator="{ on }">
                    <button v-on="on" :class="[{ 'is-active': local_heading_level }, iconClass ]">
                        {{ 'H' + (local_heading_level ? local_heading_level : '') }}
                    </button>
                </template>
                <a-list class="u-list-condensed">
                    <a-list-item v-for="i in 6" :key="i" :class="{ 'grey lighten-3': local_heading_level === i }" @click="editor.chain().focus().toggleHeading({ level: i }).run()">
                        <a-list-item-content>
                            <component :is="'h' + i">Heading {{ i }}</component>
                        </a-list-item-content>
                    </a-list-item>
                </a-list>
            </a-menu>
        </slot>
        <slot name="format-bold" v-bind:execute="editor.chain().focus().toggleBold().run" v-bind:isActive="editor.isActive('bold')">
            <button
                @click="editor.chain().focus().toggleBold().run()"
                :class="[{ 'is-active': editor.isActive('bold') }, iconClass ]"
            >
                <a-icon :size="iconSize" :color="iconColor">format_bold</a-icon>
            </button>
        </slot>
        <slot name="format-italic" v-bind:execute="editor.chain().focus().toggleItalic().run" v-bind:isActive="editor.isActive('italic')">
            <button
                @click="editor.chain().focus().toggleItalic().run()"
                :class="[{ 'is-active': editor.isActive('italic') }, iconClass]"
            >
                <a-icon :size="iconSize" :color="iconColor">format_italic</a-icon>
            </button>
        </slot>
        <slot name="format-italic" v-bind:execute="editor.chain().focus().toggleUnderline().run" v-bind:isActive="editor.isActive('underline')">
            <button
                @click="editor.chain().focus().toggleUnderline().run()"
                :class="[{ 'is-active': editor.isActive('underline') }, iconClass]"
            >
                <a-icon :size="iconSize" :color="iconColor">format_underline</a-icon>
            </button>
        </slot>
        <slot name="format-italic" v-bind:execute="editor.chain().focus().toggleStrike().run" v-bind:isActive="editor.isActive('strike')">
            <button
                @click="editor.chain().focus().toggleStrike().run()"
                :class="[{ 'is-active': editor.isActive('strike') }, iconClass]"
            >
                <a-icon :size="iconSize" :color="iconColor">strikethrough_s</a-icon>
            </button>
        </slot>
        <slot name="format-italic" v-bind:execute="editor.chain().focus().toggleBulletList().run" v-bind:isActive="editor.isActive('bulletList')">
            <button
                @click="editor.chain().focus().toggleBulletList().run()"
                :class="[{ 'is-active': editor.isActive('bulletList') }, iconClass]"
            >
                <a-icon :size="iconSize" :color="iconColor">format_list_numbered</a-icon>
            </button>
        </slot>
        <slot name="format-italic" v-bind:execute="editor.chain().focus().toggleOrderedList().run" v-bind:isActive="editor.isActive('orderedList')">
            <button
                @click="editor.chain().focus().toggleOrderedList().run()"
                :class="[{ 'is-active': editor.isActive('orderedList') }, iconClass]"
            >
                <a-icon :size="iconSize" :color="iconColor">format_list_bulleted</a-icon>
            </button>
        </slot>
        <slot name="format-code" v-bind:execute="editor.chain().focus().toggleCodeBlock().run" v-bind:isActive="editor.isActive('codeBlock')">
            <button
                @click="editor.chain().focus().toggleCodeBlock().run()"
                :class="[{ 'is-active': editor.isActive('codeBlock') }, iconClass]"
            >
                <a-icon :size="iconSize" :color="iconColor">code</a-icon>
            </button>
        </slot>
        <slot name="format-blockquote" v-bind:execute="editor.chain().focus().toggleBlockquote().run" v-bind:isActive="editor.isActive('blockquote')">
            <button
                @click="editor.chain().focus().toggleBlockquote().run()"
                :class="[{ 'is-active': editor.isActive('blockquote') }, iconClass]"
            >
                <a-icon :size="iconSize" :color="iconColor">format_quote</a-icon>
            </button>
        </slot>
        <slot name="format-hr" v-bind:execute="editor.chain().focus().setHorizontalRule().run">
            <button
                @click="editor.chain().focus().setHorizontalRule().run()"
                :class="[iconClass]"
            >
                <a-icon :size="iconSize" :color="iconColor">horizontal_rule</a-icon>
            </button>
        </slot>
    </div>
</template>

<script>
export default {
    props: {
        editor: {
            type: Function | Object,
            required: true
        },
        iconClass: {
            type: String,
            default: 'c-button'
        },
        iconSize: {
            type: String | Number,
            default: '24'
        },
        iconColor: {
            type: String,
            default: '#444'
        }
    },

    computed: {
        local_heading_level () {
            let isLevel = false
            if (this.editor) {
                for (const iterator of [...Array(6).keys()]) {
                    const hasLevel = this.editor.isActive('heading', { level: iterator + 1 })
                    if (hasLevel) isLevel = iterator + 1
                }
            }
            return isLevel
        }
    }
}
</script>

<style lang="scss" scoped>
.c-button {
    transition: 0.4s ease-in-out !important;
    padding: 4px !important;
    text-align: center !important;
    display: inline-block !important;
    font-weight: bold !important;
    color: #272727 !important;
    border-radius: 6px !important;
    margin-right: 4px !important;
    width: 24px !important;
    &:last-child {
        margin-right: 0px !important;
    }
}

// .is-active {
//     // @apply t-text-gray-200 t-bg-gray-800 t-transition t-duration-500 t-ease-in-out;
//     @apply t-text-gray-200 t-bg-gray-800 t-transition t-duration-500 t-ease-in-out;
//     .v-icon {
//         color: #fff !important;
//     }
// }
</style>

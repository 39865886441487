<template>
	<div class="c-number-chart u-relative u-elevation-custom-1 u-rounded-corners-lg white">
        <div class="d-flex align-end align-content-end flex-wrap u-hfull">
            <div class="text-h1 mb-2 u-wfull grey--text text--darken-3">100</div>
            <div class="text-h4 green--text darken-2 mb-1 u-wfull">$20,784</div>
            <div class="text-body-2 grey--text text--darken-1 u-wfull">
                Project Count and Average Amount
            </div>
        </div>
    </div>
</template>

<script>
export default {
	name: 'NumberChart'
}
</script>

<style scoped>
.c-number-chart {
	width: 272px;
	height: 227px;
}
</style>
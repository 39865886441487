<template>
    <div>
        <template v-if="isLoading">
            <div class="c-left-menu">
                <div class="c-left-select-menu-info align-center justify-center u-flex u-cursor-pointer">
                    <div class="c-left-menu-info align-center justify-center u-flex">
                        <loader-template height="40px" width="40px" class="u-rounded-corners-full"></loader-template>
                    </div>
                </div>
                <div class="c-left-menu-section mt-3">
                    <div class="c-left-menu-section-info c-left-menu-under align-center justify-center u-flex u-cursor-pointer">
                        <loader-template height="38px" width="38px" class="u-rounded-corners-full"></loader-template>
                    </div>
                    <div class="c-left-menu-section-info c-left-menu-under align-center justify-center u-flex u-cursor-pointer">
                        <loader-template height="38px" width="38px" class="u-rounded-corners-full"></loader-template>
                    </div>
                    <div class="c-left-menu-section-info c-left-menu-under mt-1 align-center justify-center u-flex u-cursor-pointer">
                        <loader-template height="38px" width="38px" class="u-rounded-corners-full"></loader-template>
                    </div>
                    <div class="c-left-menu-section-info c-left-menu-under mt-1 align-center justify-center u-flex u-cursor-pointer">
                        <loader-template height="38px" width="38px" class="u-rounded-corners-full"></loader-template>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="!isLoading">
            <div class="c-left-menu">
                <div class="c-left-select-menu-info align-center justify-center u-flex u-cursor-pointer" @click="$emit('action', 'workflow-summary')">
                    <div class="c-left-menu-info align-center justify-center u-flex" :class="[localActiveSelectMenu('class', 'workflow-summary')]">
                        <a-icon :color="localActiveSelectMenu('color', 'workflow-summary')" size="22">info</a-icon>
                    </div>
                </div>
                <div class="c-left-menu-section mt-3">
                    <div class="c-left-menu-section-info c-left-menu-under align-center justify-center u-flex u-cursor-pointer" :class="[localActiveSelectMenu('class', 'workflow-workspace')]" @click="$emit('action', 'workflow-workspace')">
                        <a-icon :color="localActiveSelectMenu('color', 'workflow-workspace')" size="22"> apartment</a-icon>
                    </div>
                    <div class="c-left-menu-section-info c-left-menu-under align-center justify-center u-flex u-cursor-pointer" :class="[localActiveSelectMenu('class', 'workflow-trigger')]" @click="$emit('action', 'workflow-trigger')">
                        <a-icon :color="localActiveSelectMenu('color', 'workflow-trigger')" size="22">flash_on</a-icon>
                    </div>
                    <div class="c-left-menu-section-info c-left-menu-under mt-1 align-center justify-center u-flex u-cursor-pointer" :class="[localActiveSelectMenu('class', 'workflow-conditions')]" @click="$emit('action', 'workflow-conditions')">
                        <a-icon :color="localActiveSelectMenu('color', 'workflow-conditions')" size="22">tune</a-icon>
                    </div>
                    <div class="c-left-menu-section-info mt-1 c-left-menu-under align-center justify-center u-flex u-cursor-pointer" :class="[localActiveSelectMenu('class', 'workflow-validations')]" @click="$emit('action', 'workflow-validations')">
                        <a-icon :color="localActiveSelectMenu('color', 'workflow-validations')" size="22">fact_check</a-icon>
                    </div>
                </div>
                <div class="c-left-select-menu-info align-center mt-3 justify-center u-flex u-cursor-pointer">
                    <div class="c-left-menu-info align-center justify-center u-flex" @click="$emit('page', 'logs')" :class="[page === 'logs' ? 'indigo lighten-5' : '']">
                        <a-icon :color="page === 'logs' ? 'indigo darken-1' : 'grey'" size="22">menu_open</a-icon>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        activeMenu: {
            type: String,
            default: 'workflow-summary',
        },
        page: {
            type: String,
            default: null,
        },
    },

    methods: {
        localActiveSelectMenu (type, menu) {
            if (this.activeMenu === menu) {
                return type === 'color' ? 'indigo darken-1' : 'indigo lighten-5'
            } 
            return  type === 'color' ? 'grey' : 'white'
        }
    }
}
</script>

<style scoped>
.c-left-menu {
    position: sticky !important;
    top: 100px !important;
}
.c-left-menu-info {
    width: 40px !important;
    height: 40px !important;
    border-radius: 24px !important;
}
.c-left-menu-under {
    position: relative;
    left: 4px;
    top: 4px;
}
.c-left-select-menu-info {
    width: 48px !important;
    height: 48px !important;
    background: #FFFFFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 24px !important;
}
.c-left-menu-section {
    width: 48px;
    background: #FFFFFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 24px !important;
    height: 180px !important;
}
.c-left-menu-section-info {
    width: 40px !important;
    height: 40px !important;
    border-radius: 24px !important;
}
</style>
<template>
	<a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container container--fluid grid-list-xl class="pa-6 pt-3 px-0 pb-16 mb-16">
            <g-page-header
                title="Manage Accounts"
                subtitle="You can create accounts and contacts to include in project."
                icon="account_box"
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                class="pr-1"
            >
                <template v-slot:action v-if="check_user_customer_permission('store')">
                    <a-btn v-test-btn.create color="primary" class="elevation-0 mx-0" dark @click="local_customer_create()">
                        <a-icon class="mr-2" color="white" size="18">add</a-icon>
                        Create New Account
                    </a-btn>
                </template>
            </g-page-header>
            <a-layout align-center class="my-0 mb-2">
                <a-flex class="py-0">
                    <a-card class="c-search-inventory px-3 d-flex align-center u-border u-rounded-corners mb-1" flat>
                        <a-icon size="24" color="grey" style="max-width: 20px;">search</a-icon>
                        <a-text-field solo flat hide-details dense placeholder="Search by account name" :loading="search_loading" class="" v-model="search_term" @input="local_search_filter(search_term)" v-test-input.search_term></a-text-field>
                    </a-card>
                </a-flex>
            </a-layout>
            <a-layout v-if="local_loading || (!local_loading && !customer_list.length)">
                <a-flex>
                    <a-responsive class="my-4" v-if="local_loading">
                        <!-- <a-divider class="grey lighten-2 mb-4"></a-divider> -->
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Accounts..</h3>
                    </a-responsive>
                    <a-responsive class="my-4" v-if="!local_loading && !customer_list.length">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                        <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <a-layout>
                <a-flex xs12>
                    <a-card
                        v-for="(customer, index) in customer_list"
                        :key="customer.id"
                        :class="[{ 'mt-4': index !== 0 }]"
                        class="pa-1 u-border"
                        flat
                    >
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl>
                                <a-layout wrap align-center>
                                    <a-flex shrink xs7 xl9>
                                        <h2 class="md-heading-6 font-weight-bold mb-1" :title="customer.name">{{ customer.name | truncateText($vuetify.breakpoint.lgAndDown ? 55 : 100) }}</h2>
                                        <p class="md-subtitle-1 grey--text text--darken-2 mb-0" v-if="!customer.contacts_count || customer.contacts_count == 0">No Contacts</p>
                                        <p class="md-subtitle-1 grey--text text--darken-2 mb-0" v-if="customer.contacts_count == 1">1 Contact</p>
                                        <p class="md-subtitle-1 grey--text text--darken-2 mb-0" v-if="customer.contacts_count > 1">{{ customer.contacts_count }} Contacts</p>
                                    </a-flex>
                                    <a-spacer></a-spacer>
                                    <a-flex shrink v-if="local_check_bridge(customer, 'salesforce', 'account.salesforce_id')">
                                        <a-tooltip bottom left v-if="user_self && user_self.organization && user_self.organization.salesforce_instance_url">
                                            <template v-slot:activator="{ on }">
                                                <a :href="user_self.organization.salesforce_instance_url + '/' + local_bridge_value(customer, 'salesforce', 'account.salesforce_id')" target="_blank" rel="noopener" v-on="on">
                                                    <img src="../../assets/images/icon-salesforce.svg" class="d-block" height="16" alt="Salesforce">
                                                </a>
                                            </template>
                                            <span class="text-center">Imported from Salesforce.<br><span>Click to open Account.</span></span>
                                        </a-tooltip>
                                        <a-tooltip bottom left v-else>
                                            <template v-slot:activator="{ on }">
                                                <img src="../../assets/images/icon-salesforce.svg" class="d-block" height="16" alt="Salesforce" v-on="on">
                                            </template>
                                            <span class="text-center">Imported from Salesforce.</span>
                                        </a-tooltip>
                                    </a-flex>
                                    <a-flex shrink v-if="local_check_bridge(customer, 'hubspot', 'hubspot.customer.create')">
                                        <a-tooltip bottom left v-if="localHubspotInstanceUrl && localHubspotPortalId">
                                            <template v-slot:activator="{ on }">
                                                <a :href="localHubspotInstanceUrl +'/contacts/'+localHubspotPortalId+'/companies/'+ local_bridge_value(customer, 'hubspot', 'hubspot.customer.create')" target="_blank" rel="noopener" v-on="on">
                                                    <img :src="require('../../assets/images/apps/hubspot/logo.svg')" class="d-block mt-1" height="25" alt="Hubspot">
                                                </a>
                                            </template>
                                            <span class="text-center">Imported from Hubspot.<br><span>Click to open Account.</span></span>
                                        </a-tooltip>
                                        <a-tooltip bottom left v-else>
                                            <template v-slot:activator="{ on }">
                                                <img :src="require('../../assets/images/apps/hubspot/logo.svg')" class="d-block" height="16" alt="Hubspot" v-on="on">
                                            </template>
                                            <span class="text-center">Imported from Salesforce.</span>
                                        </a-tooltip>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-btn class="mx-2" text icon :to="{name: 'customers-notes', params: {id: customer.id}}" v-if="$can('notes.show')" exact>
                                            <a-icon>notes</a-icon>
                                        </a-btn>
                                        <a-btn class="mx-2" text icon :to="{name: 'customers-contacts', params: {id: customer.id}}" v-test-link.customers-contacts v-test-id="customer.id" exact>
                                            <a-icon>contact_mail</a-icon>
                                        </a-btn>
                                        <a-btn v-test-btn.edit v-test-id="customer.id" class="mx-2" text icon @click="local_customer_edit(customer.id)" v-if="check_user_customer_permission('update')">
                                            <a-icon>edit</a-icon>
                                        </a-btn>
                                        <template v-if="check_user_customer_permission('destroy')">
                                            <a-tooltip bottom v-if="customer.total_projects_count > 0">
                                                <template v-slot:activator="{ on }">
                                                    <div class="d-inline-block" v-on="on">
                                                        <a-btn v-test-btn.delete v-test-id="customer.id" size="16" text icon disabled>
                                                            <a-icon>delete</a-icon>
                                                        </a-btn>
                                                    </div>
                                                </template>
                                                <div class="text-center" style="max-width: 200px;">This account contains {{ customer.total_projects_count === 1 ? customer.total_projects_count + ' project' : customer.total_projects_count + ' projects' }} and cannot be deleted</div>
                                            </a-tooltip>
                                            <a-menu v-else left offset-y transition="slide-y-transition" max-width="340">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn v-test-btn.delete v-test-id="customer.id" v-on="on" color="grey darken-1" size="16" text icon>
                                                        <a-icon>delete</a-icon>
                                                    </a-btn>
                                                </template>
                                                <a-card class="u-rounded-corners u-elevation-custom-1 pa-3" min-width="320" max-width="340">
                                                    <a-card-text class="pa-1">
                                                        <h2 class="body-1 font-weight-normal mb-1 grey--text text--darken-1"><strong>Are you sure you want to delete the account?</strong> <br> This account and related data will be permanently deleted. This action cannot be undone.</h2>
                                                        <div class="u-flex align-center justify-space-between mt-2">
                                                            <p class="caption grey--text text--darken-1 mb-0 font-weight-normal">Click "Delete" to continue</p>
                                                            <a-btn v-test-btn:confirm.delete v-test-id="customer.id" @click="customer_destroy({ id: customer.id })" small text class="red darken-1 u-rounded-corners ma-0 pa-1 px-2 ml-2" dark>Delete</a-btn>
                                                        </div>
                                                    </a-card-text>
                                                </a-card>
                                            </a-menu>
                                        </template>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
            <a-layout wrap align-start v-if="!local_loading">
                <a-flex xs12 class="u-flex align-center">
                    <a-btn v-if="customer_meta && (customer_meta.current_page < customer_meta.last_page)" :loading="loading" :disabled="loading" @click="local_paginate(customer_meta.current_page + 1)" small text class="primary" dark>
                        Load More
                    </a-btn>
                    <a-spacer></a-spacer>
                    <span class="md-body-2 grey--text text--darken-1" v-if="customer_meta.total">Showing <strong>{{ customer_list.length }}</strong> out of <strong>{{ customer_meta.total }}</strong> accounts</span>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog v-model="dialog_customer_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>account_box</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (customer_form_mode == 'add') ? 'Create New Account' : 'Edit Existing Account' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (customer_form_mode == 'add') ? 'You can add new account to manage projects.' : 'You can edit existing account information.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6 py-2">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                    <a-text-field
                                        v-model="customer_item.name"
                                        placeholder="Enter Account Name"
                                        background-color="neutral"
                                        solo flat hide-details autofocus
                                        v-test-input.name
                                    >
                                    </a-text-field>
                                    <span v-test-label.name.error class="md-caption red--text text--darken-2 d-block mt-2" v-if="customer_response.server && customer_response.server.errors && customer_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ customer_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the account.
                                    </span>
                                </a-flex>
                            </a-layout>
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Domain</label>
                                    <a-text-field
                                        v-model="customer_item.domain"
                                        placeholder="Enter the Domain"
                                        background-color="neutral"
                                        solo flat hide-details
                                        v-test-input.domain
                                    >
                                    </a-text-field>
                                    <span v-test-label.domain.error class="md-caption red--text text--darken-2 d-block mt-2" v-if="customer_response.server && customer_response.server.errors && customer_response.server.errors.domain">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ customer_response.server.errors.domain[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The domain will be helpful to identify the account.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn v-test-btn.save color="primary" class="ma-0 elevation-0 px-3" @click="local_customer_store()" :loading="loading" :disabled="loading">Save Account</a-btn>
                                    <a-btn v-test-btn.cancel class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_customer_form = !dialog_customer_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: 'Customers',
    data() {
        return {
            search_term: null,
            dialog_customer_form: false,
            local_loading: true,
            search_loading: false,
            customer_filter: {},
            customer_count: 100,
            FILTERS: {
                PAGE: 'page',
                COUNT: 'count',
                NAME: 'filter[name]'
            },
        }
    },
    mounted() {
        this.local_index()
    },

    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Customers', to: '/customers', exact: true})
            return breadcrumbs
        },

        localHubspotPortalId () {
            return this.user_self && this.user_self.organization && this.user_self.organization.hubspot_portal_id
        },

        localHubspotInstanceUrl () {
            return this.user_self && this.user_self.organization && this.user_self.organization.hubspot_instance_url
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Customer', {
            customer_self: 'self',
            customer_list: 'list',
            customer_meta: 'meta',
            customer_item: 'item',
            customer_form_mode: 'form_mode',
            customer_response: 'response',
        }),
    },
    watch: {
        search_term(newValue, oldValue) {
            if (newValue == '' && newValue != oldValue || newValue == null && newValue != oldValue) {
                this.local_loading = true
                this.local_index()
            }
        },
    },
    methods: {
        async local_index() {
            await this.customer_clear()
            this.customer_filter[this.FILTERS.PAGE] = 1
            this.customer_filter[this.FILTERS.COUNT] = this.customer_count
            await this.local_customer_index()
            this.local_loading = false
        },
        async local_customer_edit(id) {
            await this.customer_select({id: id})
            this.dialog_customer_form = true
        },
        async local_customer_create() {
            await this.customer_clear_item()
            this.dialog_customer_form = true
        },
        async local_customer_store() {
            if (this.customer_form_mode !== 'add') return this.local_customer_update()
            await this.customer_store({ type: 'add', ...this.customer_item })
            this.local_close_add_dialog(this.customer_form_mode)
        },
        async local_customer_update() {
            await this.customer_update()
            this.local_close_add_dialog()
        },
        local_close_add_dialog(type = null) {
            if (this.customer_response.status == 'success') {
                if (!this.loading) this.dialog_customer_form = false
                if (type && type === 'add') this.customer_meta.total = this.customer_meta.total + 1
            }
        },
        local_bridge_value(customer, service, key) {
            if (customer.bridges) {
                const result = customer.bridges.find(item => item.service === service && item.key === key)
                return (result && result.value) ?? ''
            }
        },
        local_check_bridge(customer, service, key) {
            return customer.bridges && customer.bridges.find(item => {
                return item.service === service && item.key === key
            })
        },
        async local_paginate(value) {
            this.customer_filter[this.FILTERS.PAGE] = value
            await this.local_customer_index()
        },
        async local_customer_index(params = {}) {
            await this.customer_index({
                include: 'contactsCount,totalProjectsCount,bridges',
                'sort': 'name',
                ...this.customer_filter,
                ...params,
            })
            this.search_loading = false
        },
        check_user_customer_permission(type, client_allowed = false) {
            if (this.user_self.role.scope === 'external') {
                return client_allowed
            }
            return this.$can(`customers.${type}`) && this.user_self.role.scope === 'internal'
        },
        local_search_filter(value){
            this.search_loading = true
            clearTimeout(this.search_timeout)
            this.customer_filter[this.FILTERS.COUNT] = 999
            this.customer_filter[this.FILTERS.PAGE] = 1
            this.customer_filter[this.FILTERS.NAME] = value
            this.search_timeout = window.setTimeout(async () => {
                if (!this.search_term.trim()) return this.search_loading = false
                if (!this.search_term || this.search_term.trim().length <= 3) {
                    const search_error = 'Try more than 3 characters to search.'
                    this.$notify('error', search_error)
                    return this.search_loading = false
                }
                this.search_loading = false
                await this.local_customer_index()
            }, 500)
        },
        ...mapActions('Customer', {
            customer_index: 'index',
            customer_store: 'store',
            customer_update: 'update',
            customer_select: 'select',
            customer_upgrade: 'upgrade',
            customer_downgrade: 'downgrade',
            customer_destroy: 'destroy',
            customer_reorder: 'reorder',
            customer_clear: 'clear',
            customer_clear_item: 'clear_item',
        }),
    },
}
</script>

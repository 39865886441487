import generateBaseUrl from '@/config/config-api'
import { storage } from '../../helpers/helper-local-storage';
const axios_external = generateBaseUrl(process.env.VUE_APP_CORE_API_URL, 'external')
const api_endpoint = '/users';

export default {
    namespaced: true,
    state: {
        list: [],
        total_list: [],
        item: { territories: [], send_email_invite: true },
        self: {},
        meta: {},
        token_list: [],
        token_item: {},
        current_invitee: {},
        action: {
            type: null,
            id: null,
        },
        filters: {
            status: 'all',
            page: 1,
            count: 10,
            sort_field: 'name',
            sort_direction: 'asc'
        },
        response: {},
        defaults: {
            list: [],
            total_list: [],
            item: { territories: [], send_email_invite: true },
            meta: {},
            action: {
                type: null,
                id: null,
            },
            current_invitee: {},
            filters: {
                status: 'all',
                page: 1,
                count: 10,
                sort_field: 'name',
                sort_direction: 'asc'
            },
            response: {},
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ALL_USERS_LIST(state, items) {
            state.total_list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            if (index !== -1) state.list[index] = Object.assign(state.list[index], payload.item)
        },
        UPDATE_SELF(state, item) {
            if (state.self && state.self.id) return Object.assign(state.self, item)
            state.self = item
        },
        UPDATE_BILLING(state, payload) {
            const index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index].is_billing = payload.state
            // Object.assign(state.list[index], payload.item)
        },
        UPDATE_USER_BILLING(state, payload) {
            state.self.is_billing = payload.state
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        UPDATE_ACTION(state, payload) {
            state.action = payload;
        },
        UPDATE_ITEM_TERRITORIES(state, territories) {
            state.item.territories = territories;
        },
        UPDATE_TOKENS(state, payload) {
            state.token_list = payload
        },
        ADD_TOKEN(state, payload) {
            state.token_list.unshift(payload)
            state.token_item = payload
        },
        REMOVE_TOKEN(state, payload) {
            state.token_list.splice(state.token_list.findIndex(i => i.id === payload), 1)
        },
        UPDATE_TOKEN_ITEM(state) {
            state.token_item = {}
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        ADD_ITEM(state, item) {
            state.list.unshift(item);
        },
        ADD_INVITES_USER(state, item) {
            state.current_invitee = item
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        CLEAR_CURRENT_INVITEE(state) {
            state.current_invitee = {};
        },
        CLEAR_RESPONSE(state) {
            state.response = {};
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint, { params: payload })
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    // if (payload.page === 1) context.commit('UPDATE_LIST', response.data.data);
                    // else context.commit('APPEND_LIST', response.data.data);
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        total_index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint, { params: payload })
                .then((response) => {
                    if (payload.page === 1) context.commit('UPDATE_ALL_USERS_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        // enabled(context, payload) {
        //     this.dispatch('Interface/loader_start')
        //     return axios.get(api_endpoint + '/enabled')
        //         .then((response) => {
        //             context.commit('UPDATE_LIST', response.data.data);
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
        //         })
        //         .catch((error) => {
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
        //         });
        // },
        // disabled(context, payload) {
        //     this.dispatch('Interface/loader_start')
        //     return axios.get(api_endpoint + '/disabled')
        //         .then((response) => {
        //             context.commit('UPDATE_LIST', response.data.data);
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
        //         })
        //         .catch((error) => {
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
        //         });
        // },
        invites(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/invites')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        me(context, payload) {
            this.dispatch('Interface/loader_start')
            const axios = generateBaseUrl(process.env.VUE_APP_CORE_API_URL, '')
            return axios.get('/auth/user', { params: payload })
                .then((response) => {
                    context.commit('UPDATE_SELF', response.data.data);
                    storage.save('myProfile', response.data.data)
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                    context.commit('FORM_EDIT');
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id, { params: payload.params })
                .then((response) => {
                    if (payload && payload.mode === 'add') context.commit('ADD_ITEM', response.data.data)
                    if (payload && payload.mode === 'self') context.commit('UPDATE_SELF', response.data.data)
                    if (payload && payload.mode !== 'add') {
                        context.commit('UPDATE_ITEM', response.data.data);
                        context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                    context.commit('FORM_EDIT');
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    // context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, payload)
                .then((response) => {
                    context.commit('UPDATE_ITEM', payload)
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id, payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        switch_workspace(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.user_id + '/workspace/' + payload.workspace_id)
                .then((response) => {
                    context.commit('UPDATE_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        resend(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_ACTION', { type: 'resend', id: payload.id })
            return axios.patch(api_endpoint + '/' + payload.id + '/resend', {})
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('UPDATE_ACTION', { type: null, id: null })
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_ACTION', { type: null, id: null })
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_ACTION', { type: 'destroy', id: payload.id })
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_ACTION', { type: null, id: null })
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_ACTION', { type: null, id: null })
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        token_index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/tokens')
                .then((response) => {
                    context.commit('UPDATE_TOKENS', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        token_store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/tokens', { token_name: payload.token_name })
                .then((response) => {
                    context.commit('ADD_TOKEN', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        token_destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/tokens/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_TOKEN', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear_token(context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_TOKEN_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                resolve('Cleared');
            });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item')
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        // enable(context, payload) {
        //     this.dispatch('Interface/loader_start')
        //     context.commit('UPDATE_ACTION', { type: 'enable', id: payload.id })
        //     return axios.patch(api_endpoint + '/' + payload.id + '/enable', {})
        //         .then((response) => {
        //             context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('REFRESH_LIST');
        //             context.commit('UPDATE_ACTION', { type: null, id: null })
        //             context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
        //         })
        //         .catch((error) => {
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('UPDATE_ACTION', { type: null, id: null })
        //             context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
        //         });
        // },
        disable(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_ACTION', { type: 'disable', id: payload.id })
            return axios.patch(api_endpoint + '/' + payload.id + '/states/is-active', payload)
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('UPDATE_ACTION', { type: null, id: null })
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_ACTION', { type: null, id: null })
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        upgrade(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_ACTION', { type: 'upgrade', id: payload.id })
            return axios.patch(api_endpoint + '/' + payload.id + '/states/is-admin', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('UPDATE_ACTION', { type: null, id: null })
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_ACTION', { type: null, id: null })
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        downgrade(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_ACTION', { type: 'downgrade', id: payload.id })
            return axios.patch(api_endpoint + '/' + payload.id + '/downgrade', {})
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('UPDATE_ACTION', { type: null, id: null })
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_ACTION', { type: null, id: null })
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        billing_toggle(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/states/is-billing', { ...payload })
                .then((response) => {
                    context.commit('UPDATE_USER_BILLING', payload);
                    context.commit('UPDATE_BILLING', payload);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_ACTION', { type: null, id: null })
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        permission_update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/permissions/' + payload.permission_id, { value: payload.value })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        permission_destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id + '/permissions/' + payload.permission_id)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        profile_update(context, payload) {
            this.dispatch('Interface/loader_start')
            const scoped_axios = payload.scope === 'internal' ? axios : axios_external
            return scoped_axios.patch(api_endpoint + '/self', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        profile_avatar_update(context, payload) {
            this.dispatch('Interface/loader_start')
            const scoped_axios = payload.scope === 'internal' ? axios : axios_external
            let form_data = new FormData()
            form_data.append('avatar', payload.avatar)
            return scoped_axios.post(api_endpoint + '/self/avatar', form_data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US',
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        profile_avatar_destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            const scoped_axios = payload.scope === 'internal' ? axios : axios_external
            return scoped_axios.delete(api_endpoint + '/self/avatar')
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        password_update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/password', context.state.item)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        territory_store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + payload.id + '/territories/' + payload.territory_id, payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_ITEM_TERRITORIES', response.data.data)
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        territory_destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id + '/territories/' + payload.territory_id)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_ITEM_TERRITORIES', response.data.data)
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        changelog_read(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/changelog_read', {})
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        update_user_self(context, payload) {
            context.commit('UPDATE_SELF', payload)
        },
        clear_current_invitee(context) {
            return new Promise((resolve, reject) => {
                context.commit('CLEAR_CURRENT_INVITEE', {});
            })
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },

    getters: {
        item: (state) => {
            const item = { ...state.item }
            return item
        },
        self: (state) => state.self,
        orgLogo: (state) => state.self.organization.logo ?? null,
    }
}

<template>
    <div class="c-donut-chart u-relative u-elevation-custom-1 u-rounded-corners-lg white">
        <div class="c-donut-chart__column">
            <VueApexCharts
                ref="donutChart"
                type="donut"
                width="100%"
                height="100%"
                :options="chartOptions"
                :series="series"
            ></VueApexCharts>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'DonutChart',

    components: { VueApexCharts },

    data () {
        return {
            series: [20, 40, 9, 25, 6],
            chartOptions: {
                chart: {
                    toolbar: { show: false, },
                    offsetY: -3,
                },
                grid: {
                    padding: { bottom: 0, right: 0, top: 6, left: 0 }
                },
                states: {
                    active: {
                        filter: { type: 'none', },
                    },
                },
                tooltip: {
                    x: {
                        format: "dd MMM",
                        formatter: undefined,
                    },
                    y: {
                        formatter: (value) => {
                            return value + "%";
                        },
                    },
                },
                selection: {
                    enabled: false,
                },
                labels: ["Apple", "Amazon", "Google", "Tesla", "IBM"],
                colors: ["#9C27B0", "#E91E63", "#FF7043", "#42A5F5", "78909C"],
                stroke: {
                    width: 0,
                },
                dataLabels: {
                    style: {
                        fontFamily: "Inter, sans-serif",
                        fontSize: "14px",
                        fontWeight: "700",
                    },
                    formatter: function (val) {
                        return `${val}%`;
                    },
                    dropShadow: {
                        enabled: false,
                    },
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontFamily: "Inter, sans-serif",
                                    color: "#505d6f",
                                    fontSize: "20px",
                                    offsetY: 0,
                                    formatter: function () {
                                        return "$2,457,690";
                                    },
                                },
                                value: {
                                    show: true,
                                    fontFamily: "Inter, sans-serif",
                                    color: "#9aa1a9",
                                    fontSize: "14px",
                                    formatter: function () {
                                        return "Total";
                                    },
                                },
                                total: {
                                    show: true,
                                    fontSize: "20px",
                                    color: "#505d6f",
                                    showAlways: true,
                                    formatter: function () {
                                        return "Total";
                                    },
                                },
                            },
                        },
                    },
                },
            },
        };
    },
};
</script>

<style scoped>
.c-donut-chart {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 227px;
    width: 272px;
}

.c-donut-chart__column {
    width: 211px;
    height: 211px;
}
</style>

<template>
    <div class="mb-12">
        <div class="u-flex-center-y">
            <div v-if="permission_note_store()">
                <a-menu bottom offset-y>
                    <template v-slot:activator="{ on }">
                        <a-btn small dark color="blue" class="ma-0 elevation-0" v-on="on">
                            Create New Note <a-icon class="ml-1 mr-0">arrow_drop_down</a-icon>
                        </a-btn>
                    </template>
                    <a-list class="u-list-condensed">
                        <a-list-item @click="local_note_create('Project', project_item.id)">
                            <a-list-item-title>
                                <span class="md-body-2">Create Project Note</span>
                            </a-list-item-title>
                        </a-list-item>
                        <a-list-item @click="local_note_create('Customer', project_item.customer_id)">
                            <a-list-item-title>
                                <span class="md-body-2">Create Account Note</span>
                            </a-list-item-title>
                        </a-list-item>
                    </a-list>
                </a-menu>
            </div>
            <a-spacer></a-spacer>
            <div>
                <a-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <a-btn small icon color="neutral" class="ma-0 elevation-0" @click="local_index()" v-on="on">
                            <a-icon size="18" color="neutral darken-4">refresh</a-icon>
                        </a-btn>
                    </template>
                    <span>Reload</span>
                </a-tooltip>
            </div>
            <div class="mx-2">
                <a-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <a-btn small icon color="neutral" class="ma-0 elevation-0" @click="local_accordion_note_toggle_all()" v-if="accordion_notes_all" v-on="on" :disabled="note_list_project.length === 0 && note_list_customer.length === 0">
                            <a-icon size="18" color="neutral darken-4">unfold_less</a-icon>
                        </a-btn>
                        <a-btn small icon color="neutral" class="ma-0 elevation-0" @click="local_accordion_note_toggle_all()" v-else v-on="on" :disabled="note_list_project.length === 0 && note_list_customer.length === 0">
                            <a-icon size="18" color="neutral darken-4">unfold_more</a-icon>
                        </a-btn>
                    </template>
                    <span>Expand/Collapse Note</span>
                </a-tooltip>
            </div>
            <div v-if="$can('templates.store') || $can('templates.index') && $can('notes.store') && $can('projects.update-only')">
                <a-menu bottom left offset-y>
                    <template v-slot:activator="{ on }">
                        <a-btn small icon color="neutral" class="ma-0 elevation-0" v-on="on">
                            <a-icon size="18" color="neutral darken-4">more_horiz</a-icon>
                        </a-btn>
                    </template>
                    <a-list class="u-list-std">
                        <a-list-item @click="local_template_import_form()" v-if="$can('templates.index') && $can('projects.update-only') && $can('notes.store')">
                            <a-list-item-title>Import from Templates</a-list-item-title>
                        </a-list-item>
                        <a-list-item @click="local_template_export_form()" v-if="$can('templates.store')" :disabled="note_list_project.length === 0">
                            <a-list-item-title>Export as Template</a-list-item-title>
                        </a-list-item>
                    </a-list>
                </a-menu>
            </div>
        </div>

        <div class="my-4" v-if="local_loading">
            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Notes..</h3>
        </div>

        <div v-if="!local_loading" class="my-6">
            <div v-if="note_list_project && note_list_project.length === 0 && note_list_customer && note_list_customer.length == 0">
                <a-flex xs12>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                        <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
                    </a-responsive>
                </a-flex>
            </div>
            <div v-if="note_list_project && note_list_project.length > 0">
                <h4 class="md-subtitle-1 mb-3 grey--text text--darken-1">
                    Project Notes ({{ note_list_project.length }})
                    <a-tooltip right max-width="240">
                        <template v-slot:activator="{ on }">
                            <a-icon size="18" class="ml-2 u-icon-nudge u-cursor-pointer" v-on="on">info</a-icon>
                        </template>
                        <span>
                            <span>These notes are linked to Project and will be only visible in this project.</span>
                            <span v-if="project_item.salesforce_id && project_item.salesforce_id !== null"><br><br>Syncs with Opportunity under the Salesforce when the workflow is active.</span>
                        </span>
                    </a-tooltip>
                </h4>
                <draggable v-model="note_list_project" :options="{handle: '.js-drag-handle'}" @end="local_reorder(note_list_project)" draggable=".js-draggable-file-list-item">
                    <a-card
                        v-for="(note, index) in note_list_project"
                        :key="note.id"
                        class="u-border u-rounded-corners my-2"
                        flat
                        :class="{ 'yellow lighten-4': note.visibility === 'external', 'js-draggable-file-list-item': permission_note_update(note) }"
                    >
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="py-7 px-6">
                                <a-layout>
                                    <a-flex shrink pa-0 class="u-cursor-pointer" v-if="user_self.role && user_self.scope === 'internal'" :class="{'u-opacity-30': !permission_note_update(note)}">
                                        <a-icon size="20" class="grey--text text--lighten-1 js-drag-handle u-icon-nudge">drag_indicator</a-icon>
                                    </a-flex>
                                    <a-flex xs12 py-0 class="text-truncate">
                                        <router-link tag="div" class="u-cursor-pointer mb-1" :to="{name: 'projects-notes-view', params: {note_id: note.id}}" v-if="$can('notes.index')">
                                            <h2 class="md-subtitle-1 grey--text text--darken-3 text-truncate" :title="note.title">
                                                {{ note.title }}
                                            </h2>
                                        </router-link>
                                        <div class="u-flex align-center mt-1 u-cursor-pointer" @click="$router.push({name: 'projects-notes-view', params: {note_id: note.id}})">
                                            <div class="mr-4" v-if="local_bridge_value(note, 'salesforce', 'opportunity.salesforce_id') && user_self.scope === 'internal'">
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <img src="../../assets/images/icon-salesforce.svg" height="12" alt="Salesforce" class="d-block">
                                                        </div>
                                                    </template>
                                                    <span>Salesforce Synced</span>
                                                </a-tooltip>
                                            </div>
                                            <div class="mr-4" style="max-width: 200px;" v-if="user_self.scope === 'internal'">
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on" class="u-flex-center-y">
                                                            <a-icon size="14" color="grey" class="mr-1">post_add</a-icon>
                                                            <h3 v-if="note.type" style="max-width: 150px;" class="md-caption text-truncate grey--text text--darken-2">
                                                                {{ note.type.value }}
                                                            </h3>
                                                            <span class="md-caption grey--text text--darken-2" v-else>No Category</span>
                                                        </div>
                                                    </template>
                                                    <span>Category</span>
                                                </a-tooltip>
                                            </div>
                                            <div class="mr-4" style="max-width: 200px;">
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <a-icon size="14" color="grey" class="mr-1">person</a-icon>
                                                            <span class="md-caption grey--text text--darken-2" v-if="note && note.updated_by">{{ note.updated_by.name }}</span>
                                                            <template v-else>
                                                                <span class="md-body-2 grey--text text--darken-2" v-if="local_bridge_value(note, 'salesforce', 'opportunity.salesforce_id')">Salesforce</span>
                                                            </template>
                                                        </div>
                                                    </template>
                                                    <span>Updated User</span>
                                                </a-tooltip>
                                            </div>
                                            <div class="mr-4">
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <a-icon size="14" color="grey" class="mr-1">date_range</a-icon>
                                                            <g-moment class="md-caption grey--text text--darken-2" :value="note.updated_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD [at] hh:mma" convert-local></g-moment>
                                                        </div>
                                                    </template>
                                                    <span>Updated Date and Time</span>
                                                </a-tooltip>
                                            </div>
                                            <g-attachment-count-info
                                                :count="note.attachments_count"
                                                fontSize="14"
                                                iconSize="12"
                                            />
                                        </div>
                                    </a-flex>
                                    <a-flex shrink pa-0>
                                        <a-btn text icon small color="grey" class="ma-0" v-if="local_accordion_note_status('Project', index)" @click="local_accordion_note_toggle('Project', index)">
                                            <a-icon size="16">unfold_less</a-icon>
                                        </a-btn>
                                        <a-btn text icon small color="grey" class="ma-0" v-else @click="local_accordion_note_toggle('Project', index)">
                                            <a-icon size="16">unfold_more</a-icon>
                                        </a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider v-if="local_accordion_note_status('Project', index)"></a-divider>
                            <a-container container--fluid grid-list-xl v-if="local_accordion_note_status('Project', index)">
                                <a-layout align-center>
                                    <a-flex shrink class="pb-0 py-4 px-6">
                                        <p class="md-caption text-uppercase font-weight-bold mb-1">Description</p>
                                        <div v-if="note.content != null && note.content != '' && note.content != ' '" class="u-richtext" v-html="local_converted_desc(note.content)"></div>
                                        <p v-else class="md-body font-italic grey--text">No description</p>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </draggable>
            </div>
            <div v-if="note_list_customer.length > 0" class="mt-7">
                <h4 class="md-subtitle-1 mb-3 grey--text text--darken-1">
                    Account Notes ({{ note_list_customer.length }})
                    <a-tooltip right max-width="240">
                        <template v-slot:activator="{ on }">
                            <a-icon size="18" class="ml-2 u-icon-nudge u-cursor-pointer" v-on="on">info</a-icon>
                        </template>
                        <span>
                            <span>These notes are linked to Account and will be visible in all the projects that is under this Account.</span>
                            <span v-if="project_item.salesforce_id && project_item.salesforce_id !== null"><br><br>Syncs with Account under the Salesforce when the workflow is active.</span>
                        </span>
                    </a-tooltip>
                </h4>
                <draggable v-model="note_list_customer" :options="{handle: '.js-drag-handle'}" @end="local_reorder(note_list_customer)" draggable=".js-draggable-file-list-item">
                    <a-card
                        v-for="(note, index) in note_list_customer"
                        :key="note.id"
                        flat
                        class="u-border u-rounded-corners my-2"
                        :class="{ 'yellow lighten-4': note.visibility == 'external', 'js-draggable-file-list-item': permission_note_update(note) }"
                    >
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="py-7 px-6">
                                <a-layout>
                                    <a-flex shrink pa-0 class="u-cursor-pointer" v-if="user_self.role && user_self.scope === 'internal'" :disabled="!permission_note_update(note)" :class="{'u-opacity-30': !permission_note_update(note)}">
                                        <a-icon size="20" class="grey--text text--lighten-1 js-drag-handle u-icon-nudge">drag_indicator</a-icon>
                                    </a-flex>
                                    <a-flex xs12 py-0 class="text-truncate">
                                        <router-link tag="div" class="u-cursor-pointer" :to="{name: 'projects-notes-view', params: {note_id: note.id}}" v-if="$can('notes.index')">
                                            <h2 class="md-subtitle-1 grey--text text--darken-3 text-truncate" :title="note.title">
                                                {{ note.title }}
                                            </h2>
                                        </router-link>
                                        <div class="u-flex align-center mt-1 u-cursor-pointer" @click="$router.push({name: 'projects-notes-view', params: {note_id: note.id}})">
                                            <div class="mr-4" v-if="local_bridge_value(note, 'salesforce', 'account.salesforce_id') && user_self.scope === 'internal'">
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <img src="../../assets/images/icon-salesforce.svg" height="12" alt="Salesforce" class="d-block">
                                                        </div>
                                                    </template>
                                                    <span>Salesforce Synced</span>
                                                </a-tooltip>
                                            </div>
                                            <div class="mr-4" style="max-width: 180px;" v-if="user_self.scope === 'internal'">
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on" class="u-flex-center-y">
                                                            <a-icon size="14" color="grey" class="mr-1">post_add</a-icon>
                                                            <h3 v-if="note.type" style="max-width: 150px;" class="md-caption text-truncate grey--text text--darken-2">
                                                                {{ note.type.value }}
                                                            </h3>
                                                            <span class="md-caption grey--text text--darken-2" v-else>No Category</span>
                                                        </div>
                                                    </template>
                                                    <span>Category</span>
                                                </a-tooltip>
                                            </div>
                                            <div class="mr-4" style="max-width: 200px;">
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <a-icon size="14" color="grey" class="mr-1">person</a-icon>
                                                            <span class="md-caption grey--text text--darken-2" v-if="note && note.updated_by">{{ note.updated_by.name }}</span>
                                                            <template v-else>
                                                                <span class="md-body-2 grey--text text--darken-2" v-if="local_bridge_value(note, 'salesforce', 'account.salesforce_id')">Salesforce</span>
                                                            </template>
                                                        </div>
                                                    </template>
                                                    <span>Updated User</span>
                                                </a-tooltip>
                                            </div>
                                            <div class="mr-4">
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <a-icon size="14" color="grey" class="mr-1">date_range</a-icon>
                                                            <g-moment class="md-caption grey--text text--darken-2" :value="note.updated_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD [at] hh:mma" convert-local></g-moment>
                                                        </div>
                                                    </template>
                                                    <span>Updated Date and Time</span>
                                                </a-tooltip>
                                            </div>
                                            <g-attachment-count-info
                                                :count="note.attachments_count"
                                                fontSize="14"
                                                iconSize="12"
                                            />
                                        </div>
                                    </a-flex>
                                    <a-flex shrink pa-0>
                                        <a-btn text icon small color="grey" class="ma-0" v-if="local_accordion_note_status('Customer', index)" @click="local_accordion_note_toggle('Customer', index)">
                                            <a-icon size="16">unfold_less</a-icon>
                                        </a-btn>
                                        <a-btn text icon small color="grey" class="ma-0" v-else @click="local_accordion_note_toggle('Customer', index)">
                                            <a-icon size="16">unfold_more</a-icon>
                                        </a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider v-if="local_accordion_note_status('Customer', index)"></a-divider>
                            <a-container container--fluid grid-list-xl v-if="local_accordion_note_status('Customer', index)">
                                <a-layout align-center>
                                    <a-flex shrink class="py-4 px-6">
                                        <p class="md-caption text-uppercase font-weight-bold mb-1">Description</p>
                                        <div v-if="note.content != null && note.content != '' && note.content != '<p></p>'" class="u-richtext" v-html="local_converted_desc(note.content)"></div>
                                        <p v-else class="md-body font-italic grey--text mb-0">No description</p>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </draggable>
            </div>
        </div>

        <a-dialog v-model="dialog_import_form" max-width="800" persistent>
            <a-card>
                 <a-container container--fluid grid-list-xl class="pa-6">
                    <a-layout wrap align-center>
                        <a-flex shrink class="pr-1">
                            <a-avatar class="primary darken-1" size="40">
                                <a-icon size="18" dark>system_update_alt</a-icon>
                            </a-avatar>
                        </a-flex>
                        <a-flex>
                            <h2 class="md-heading-6 primary--text text--darken-1">Import Notes from Templates</h2>
                            <p class="md-subtitle-2 mb-0">You can import multiple note into projects.</p>
                        </a-flex>
                        <a-flex shrink>
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a-btn small text icon color="grey"  @click="dialog_import_form = !dialog_import_form" class="mx-0 mt-0 mr-0 mb-0 pa-0 px-3 right" v-on="on">
                                        <a-icon size="20">cancel</a-icon>
                                    </a-btn>
                                </template>
                                <span>Close</span>
                            </a-tooltip>
                        </a-flex>
                    </a-layout>
                </a-container>
                <a-divider></a-divider>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex>
                                <a-responsive class="py-4 my-4" v-if="!loading && template_group_list.length == 0">
                                    <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">NO NOTE TEMPLATE FOUND</h3>
                                    <p class="md-body-2 text-center grey--text mb-0">There is no notes template present in your Organization<br></p>
                                    <p class="md-body-2 text-center grey--text mb-0"><a-icon size="22" color="orange">emoji_objects</a-icon>You can create new template manually under Settings or export notes from existing projects.</p>
                                </a-responsive>
                                <a-card
                                    v-for="template_group in template_group_list"
                                    :key="template_group.id"
                                    class="u-elevation-custom-1 my-2 neutral"
                                >
                                    <a-card-text class="pa-0">
                                        <a-container container--fluid grid-list-xl class="py-3 px-4">
                                            <a-layout align-center>
                                                <a-flex>
                                                    <h2 class="md-body-1">
                                                        {{ template_group.name }} <span class="md-body-2 grey--text text--darken-1">{{ template_group.template_note_count }} Notes</span>
                                                    </h2>
                                                </a-flex>
                                                <a-flex shrink>
                                                    <p class="caption grey--text text--darken-1 text-center mb-1 font-weight-bold">Import notes under</p>
                                                    <div class="d-flex align-center">
                                                        <a-btn text class="elevation-0 white--text mr-2" :class="{ 'blue darken-1': template_group.template_note_count > 0, 'grey lighten-1': template_group.template_note_count === 0 }" @click="local_template_import(template_group.id)" small :disabled="template_group.template_note_count == 0 || loading">Project</a-btn>
                                                        <a-btn text class="elevation-0 white--text" :class="{ 'blue darken-1': template_group.template_note_count > 0, 'grey lighten-1': template_group.template_note_count === 0 }" @click="local_template_import(template_group.id, 'Customer')" small :disabled="template_group.template_note_count == 0 || loading">Account</a-btn>
                                                    </div>
                                                </a-flex>
                                            </a-layout>
                                        </a-container>
                                    </a-card-text>
                                </a-card>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog v-model="dialog_export_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>insert_drive_file</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Export Notes as Template</h2>
                                    <p class="md-subtitle-2 mb-0">You can save list of notes to import them in new projects.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12 pt-0>
                                    <v-radio-group class="mt-0" hide-details v-model="export_mode" row @change="local_export_mode_change">
                                        <v-radio label="New Template" value="new"></v-radio>
                                        <v-radio label="Existing Template" value="existing" :disabled="!$can('templates.update') || !$can('templates.index')"></v-radio>
                                    </v-radio-group>
                                </a-flex>
                                <a-flex xs12 v-if="export_mode == 'new'">
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Group Name</label>
                                    <a-text-field
                                        v-model="template_note_item.name"
                                        placeholder="Enter Template Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_group_response.server && template_group_response.server.errors && template_group_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_group_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the user.
                                    </span>
                                </a-flex>
                                <a-flex xs12 v-if="export_mode == 'existing'">
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Group</label>
                                    <a-autocomplete
                                        v-model="template_note_item.id"
                                        :items="template_group_list"
                                        item-text="name"
                                        item-value="id"
                                        placeholder="Select Template"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-autocomplete>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_note_response.server && template_note_response.server.errors && template_note_response.server.errors.template_group_id">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_note_response.server.errors.template_group_id[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the user.
                                    </span>
                                </a-flex>
                            </a-layout>
                            <a-layout wrap aling-center>
                                <a-flex xs12 class="pb-0">
                                    <div class="caption brown--text text--darken-1 font-weight-medium orange lighten-3 pa-2 u-rounded-corners"><span class="font-weight-bold">NOTE</span>: Account notes will not be exported</div>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_template_group_export()" :loading="loading" :disabled="loading">Save Template</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_export_form = !dialog_export_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog v-model="dialog_upload_form" max-width="640">
            <a-card class="neutral">
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl>
                        <a-layout wrap align-center>
                            <a-flex>
                                <a-responsive class="py-4 my-4">
                                    <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-3 mb-1">Import from CSV File</h3>
                                    <p class="md-body-2 text-center grey--text text--darken-2 mb-2">You can bulk upload note, testcase and success criteria from CSV file. You can drag and drop here to upload the file or <a href="#">select the file from your computer</a>.</p>
                                    <p class="md-body-2 text-center grey--text text--darken-2 mb-0">Please make sure the file is in the format provided by Success.<br>Click here to <a href="#">download the sample format</a>.</p>
                                </a-responsive>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog max-width="800" v-model="dialog_note_form" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="40">
                                    <a-icon dark>notes</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex v-if="note_parent_type === 'Project'">
                                <h2 class="md-heading-6 primary--text text--darken-1 mb-1">Project Note</h2>
                                <p class="md-subtitle-2 mb-0">You can add new note to the project</p>
                            </a-flex>
                            <a-flex v-if="note_parent_type === 'Customer'">
                                <h2 class="md-heading-6 primary--text text--darken-1 mb-1">Account Note</h2>
                                <p class="md-subtitle-2 mb-0">You can add new note to the account</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6 pb-4">
                        <a-layout wrap align-start v-if="user_self.role && user_self.scope === 'internal' && this.$can('templates.index')">
                            <a-flex xs12 pt-0>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Select From Template (Optional)</label>
                                <a-autocomplete
                                    v-model="local_template_note_id"
                                    :items="template_note_list"
                                    item-text="title"
                                    item-value="id"
                                    placeholder="Select a note from existing template"
                                    class="elevation-0"
                                    background-color="neutral"
                                    solo flat hide-details clearable
                                    @change="local_select_template_note(local_template_note_id)"
                                >
                                <template v-slot:no-data>
                                    <div class="px-4 py-2">
                                        <span class="md-body-1" v-if="local_template_note_search != null && local_template_note_search != ''"> <strong>{{ local_template_note_search }}</strong></span>
                                        <span class="md-body-1 grey--text" v-else>No note template found</span>
                                    </div>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <span v-if="item === Object(item)" class="md-body-3 d-block py-1 u-rounded-corners text-truncate" style="max-width: 630px" :title="item.title">{{ item.title }} </span>
                                    <span v-else class="md-body-2 d-block text-truncate py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <a-layout align-center class="py-0">
                                        <a-flex style="max-width: 630px">
                                            <h5 class="md-subtitle-1 text-truncate" :title="item.title">{{ item.title }}</h5>
                                            <p class="md-caption d-block grey--text text-truncate text--darken-1 mb-0" v-if="item.template_group">{{ item.template_group.name }}</p>
                                        </a-flex>
                                    </a-layout>
                                </template>
                                </a-autocomplete>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="note_response.server && note_response.server.errors && note_response.server.errors.template_note_id">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ note_response.server.errors.template_note_id[0] }}
                                </span>
                            </a-flex>
                        </a-layout>
                        <a-layout wrap align-start>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Note Title</label>
                                <a-text-field
                                    v-model="note_item.title"
                                    placeholder="Enter new note title"
                                    background-color="neutral"
                                    solo flat hide-details
                                >
                                </a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="note_response.server && note_response.server.errors && note_response.server.errors.title">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ note_response.server.errors.title[0] }}
                                </span>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink>
                                <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_note_store()" :loading="loading" :disabled="loading">Save</a-btn>
                                <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_note_form_close()" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-snackbar v-model="snackbar_status" :timeout="5000" bottom center>
            Note Imported Successfully!
        </a-snackbar>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import marked from 'marked'
import TemplateImportStatus from '@/mixins/mixin-template-import-status'

export default {
    mixins: [TemplateImportStatus],
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            export_mode: 'new',
            dialog_note_form: false,
            dialog_import_form: false,
            dialog_export_form: false,
            dialog_upload_form: false,
            template_groups_selected: [],
            accordion_notes: {
                'Project': [],
                'Customer': [],
            },
            accordion_notes_all: false,
            snackbar_status: false,
            note_list_project: [],
            note_list_customer: [],
            note_parent_type: '',
            note_parent_id: '',
            local_template_note_search: null,
            local_template_note_id: '',
            local_loading: true,
            local_template_fields: { 'include': 'templateGroup','sort': 'title'},
            local_note_fields: { 'include': 'type,updatedBy,bridges,attachmentsCount', 'fields[type]': 'id,type,value', 'sort': 'order' }
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Note', {
            note_list: 'list',
            note_item: 'item',
            note_filters: 'filters',
            note_response: 'response',
            note_form_mode: 'form_mode',
        }),
        ...mapState('TemplateGroup', {
            template_group_list: 'list',
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),
        ...mapState('TemplateNote', {
            template_note_list: 'list',
            template_note_item: 'item',
            template_note_response: 'response',
            template_note_filters: 'filters',
        }),
        ...mapState('ProjectView', {
            project_item: 'item'
        }),
        ...mapState('Comment', {
            comment_item: 'item',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('notes.index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.$vuetify.goTo(0)
            await this.note_clear()

            if (!this.project_item.id) await this.project_show({id: this.$route.params.id})

            this.local_fetch('Project', this.$route.params.id)
            this.local_user_external_fetch_notes()
            this.local_user_has_permission_view_template()
        },

        async local_user_has_permission_view_template() {
            if(this.user_self.role && this.user_self.scope === 'internal' && this.$can('templates.index')) {
                this.template_note_filters.template_group_id = null;
                this.template_note_index(this.local_template_fields )
            }
        },
        async local_user_external_fetch_notes() {
            if (_.get('role.scope') === 'external') {
                await this.local_fetch('Customer', this.project_item.id)
            } else {
                await this.local_fetch('Customer', this.project_item.customer_id)
            }
            this.local_loading = false
        },
        async local_fetch(parent_type, parent_id) {
            if (parent_type === 'Project') {
                await this.note_index({ ...this.local_note_fields, 'filter[parent_id]': this.project_item.id })
                this.note_list_project = _.cloneDeep(this.note_list)
            }
            if (parent_type === 'Customer') {
                await this.note_index({ ...this.local_note_fields, 'filter[parent_id]': this.project_item.customer_id })
                this.note_list_customer = _.cloneDeep(this.note_list)
            }
            await this.note_clear()
        },
        async local_select_template_note(id) {
            if(!id) {
                await this.note_clear_item()
                return
            }
            await this.template_note_select({id: id})
            const template_note_item = { ...this.template_note_item }
            this.note_item.title = template_note_item.title
            this.note_item.content = template_note_item.description
            this.note_item.type_id = template_note_item.type_id
            this.note_item.visibility = template_note_item.visibility
            this.note_item.template_note_id = this.template_note_item.id
            await this.template_note_clear_item()
        },
        async local_note_create(parent_type, parent_id) {
            await this.note_clear_item()
            this.note_parent_type = parent_type
            this.note_parent_id = parent_id
            this.local_template_note_id = ''
            this.dialog_note_form = true
        },
        async local_note_form_close() {
            await this.note_clear_item()
            this.local_template_note_id = ''
            this.dialog_note_form = false
        },
        async local_note_store() {
            this.note_item.parent_type = this.note_parent_type
            this.note_item.parent_id = this.note_parent_id
            if(!this.note_item.content) this.note_item.content = ' '
            this.note_item.order = -1
            this.note_item.list_addition = false
            this.note_item.parent_id = this.note_parent_id
            //if(this.user_self.scope === 'external') this.note_item.is_client_visible = 1

            await this.note_store(this.note_item)
            if (this.note_response.status === 'success') {
                this.$router.push({name: 'projects-notes-view', params: {note_id: this.note_item.id} })
            }
        },
        async local_update() {
            await this.note_update()
            await this.note_clear_item()
        },
        async local_assign(id, user_id) {
            await this.note_select({id: id});
            this.note_item.assignee_id = user_id
            await this.note_update()
            await this.note_clear_item()
        },
        async local_reorder(list) {
            let compact_list = list.map(item => {
                return {id: item.id}
            })
            await this.note_reorder({list: compact_list})
        },
        local_due_date_passed(date, status) {
            return moment(date).isBefore() && status == 'Closed'
        },
        local_get_initials(name) {
            let initials_array = _.split(name, ' ', 2)
            let initial_first = _.truncate(initials_array[0], {length: 1, omission: ''})
            let initial_second = _.truncate(initials_array[1], {length: 1, omission: ''})
            return initial_first + initial_second
        },
        async local_template_export_form() {
            if (this.$can('templates.index') == true) {
                await this.template_group_clear()
                this.template_group_filters.type = 'Note'
                await this.template_group_index({'include': 'templateNoteCount','filter[type]': 'notes' })
            }
            this.dialog_export_form = !this.dialog_export_form
            this.template_note_item.name = null
            this.template_note_item.id = null
            this.export_mode = 'new'
        },
        async local_template_import_form() {
            if (this.$can('templates.index') == true) {
                await this.template_group_clear()
                this.template_group_filters.type = 'Note'
                await this.template_group_index({
                    include: 'templateNoteCount',
                    'filter[type]': 'notes'
                })
            }
            this.dialog_import_form = !this.dialog_import_form
        },
        async local_template_import(id, parent_type = 'Project') {
            if (!parent_type) return
            const note_import_item = {}
            note_import_item.parent_type = parent_type
            note_import_item.template_group_id = id
            note_import_item.parent_id = parent_type === 'Project' ? this.$route.params.id : this.project_item.customer_id
            await this.note_template_import(note_import_item)

            if (this.note_response.status !== 'success') return
            this.local_index()
            this.$notify('success', 'Notes imported successfully!')
        },
        local_export_mode_change () {
            this.template_group_clear_item()
            this.template_note_clear_item()
        },
        async local_template_group_export() {
            if(this.export_mode === 'new') return this.local_note_template_group_store()
            this.local_note_template_export_action(this.template_note_item.id)
        },
        async local_note_template_group_store() {
            await this.template_group_store({ name: this.template_note_item.name, type: 'notes' })
            if (!this.$status(this.template_group_response)) return
            this.local_note_template_export_action(this.template_group_item.id)
        },
        async local_note_template_export_action(group_id) {
            const note_ids = this.note_list_project.map(item => item.id);
            const note_template_export_item = {}
            note_template_export_item.data = note_ids
            note_template_export_item.template_group_id = group_id
            await this.template_note_export(note_template_export_item)
            if (!this.$status(this.template_note_response)) return
            this.dialog_export_form = false
            this.$notify('success', 'POC notes exported successfully!')
        },
        local_bridge_value(note, service, key) {
            if (note.bridges) {
                const result = note.bridges.find(item => item.service === service && item.key === key)
                return (result && result.value) ?? ''
            }
        },
        local_accordion_note_status(list, index) {
            if (this.accordion_notes[list][index]) {
                return this.accordion_notes[list][index]
            } else {
                this.accordion_notes[list][index] = false
                return this.accordion_notes[list][index]
            }
        },
        local_accordion_note_toggle(list, index) {
            if (this.accordion_notes[list][index] === undefined || this.accordion_notes[list][index] === null) {
                this.$set(this.accordion_notes[list], index, true);
            } else {
                this.$set(this.accordion_notes[list], index, !this.accordion_notes[list][index]);
            }
        },
        local_accordion_note_toggle_all() {
            this.accordion_notes_all = !this.accordion_notes_all
            this.accordion_notes['Project'] = this.accordion_notes['Project'].map(x => this.accordion_notes_all)
            this.accordion_notes['Customer'] = this.accordion_notes['Customer'].map(x => this.accordion_notes_all)
        },
        permission_note_store(client_allowed = false) {
            if (this.user_self.scope === 'external') {
                return client_allowed
            }
            return  this.$can('notes.store') && this.$can('projects.update-only') && this.user_self.scope === 'internal'
        },
        permission_note_update(note_item, client_allowed = false) {
            if (this.user_self.scope === 'external') {
                return client_allowed
            }
            return this.$can('notes.update') && this.$can('projects.update-only') && this.user_self.scope === 'internal'
        },
        local_converted_desc(description) {
            return marked(description, { sanitize: true })
        },
        ...mapActions('Note', {
            note_index: 'index',
            note_show: 'show',
            note_store: 'store',
            note_template_store: 'template_store',
            note_update: 'update',
            note_reorder: 'reorder',
            note_template_import: 'template_import',
            note_template_account_import: 'template_account_import',
            note_select: 'select',
            note_clear: 'clear',
            note_clear_item: 'clear_item',
        }),
        ...mapActions('ProjectView', {
            project_show: 'show'
        }),
        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_store: 'store',
            template_group_clear: 'clear',
            template_group_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateNote', {
            template_note_index: 'index',
            template_note_select: 'select',
            template_note_export: 'template_export',
            template_note_clear: 'clear',
            template_note_clear_item: 'clear_item',
        }),
        ...mapActions('Comment', {
            comment_store: 'store',
            comment_clear_item: 'clear_item',
        }),
    },
}
</script>

<template>
    <a-layout align-start>
        <a-flex>
            <a-tabs class="mb-0" background-color="transparent" color="indigo darken-1" slider-color="indigo darken-1">
                <a-tab ripple class="mx-2" :to="{name: 'appstore-salesforce-microservices-workflows'}">
                    <span class="md-body-2">Workflows</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'appstore-salesforce-microservices-logs'}">
                    <span class="md-body-2">Logs</span>
                </a-tab>
                <!-- <a-tab ripple class="mx-2" :to="{name: 'appstore-salesforce-microservices-accounts'}">
                    <span class="md-body-2">Accounts</span>
                </a-tab> -->
            </a-tabs>
            <a-divider class="grey lighten-2"></a-divider>
        </a-flex>
    </a-layout>
</template>

<script>
export default {

}
</script>

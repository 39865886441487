<template>
    <a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <template v-if="localLoading">
            <a-container grid-list-xl pa-6>
                <a-layout flex-wrap>
                    <a-flex xs12>
                        <v-slide-y-transition group leave-absolute hide-on-leave>
                            <!-- Loading Indicator -->
                            <div class="mt-8" key="loading" v-if="localLoading">
                                <h2 class="text-h6 grey--text text--darken-1 text-center c-mono-font mr-3">Loading templates</h2>
                            </div>
                        </v-slide-y-transition>
                    </a-flex>
                </a-layout>
            </a-container>
        </template>

        <v-slide-y-transition hide-on-leave leave-absolute>
            <a-container container--fluid grid-list-xl py-8 pt-6 v-if="!localLoading">
                <a-layout fill-height>
                    <a-flex xs3 xl2>
                        <div style="position: sticky; top: 88px;">
                            <div class="mb-4">
                                <p style="letter-spacing: 0.2px;" class="md-body-2 font-weight-medium text-uppercase grey--text text--darken-1 mb-2 ml-2">System Templates</p>
                                <div class="mb-4">
                                    <div v-for="item in system_templates" :key="item.id" class="c-master_template-item px-0 py-0 pl-2" :class="[($route.name === item.router_name || $route.name.includes(item.router_name.slice(0, -1))) ? 'grey lighten-2' : '']">
                                        <router-link :to="{ name: item.router_name }">
                                            <div class="u-flex align-center pr-2">
                                                <div class="u-flex align-center u-cursor-pointer u-wfull">
                                                    <a-icon class="mr-2 u-opacity-30" color="#a9a9a9" size="22">drag_indicator</a-icon>
                                                    <div class="u-cursor-pointer u-wfull py-2" style="min-height: 38px;">
                                                        <span class="md-body-2 grey--text text--darken-4 font-weight-medium text-truncate d-block" style="max-width: 200px;">{{ item.label }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                                <p style="letter-spacing: 0.2px;" class="md-body-2 font-weight-medium text-uppercase grey--text text--darken-1 mb-2 ml-2">Master Templates</p>
                                <draggable tag="div" style="max-height: 532px; overflow-y: auto;" class="c-scrollbar c-master_template-list u-list-condensed py-0 transparent d-block" v-model="template_master_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                                    <div v-for="item in template_master_list" :key="item.id" class="c-master_template-item px-0 py-0 pl-2" :class="[local_current_route_id === item.id ? 'grey lighten-2' : '', { 'js-draggable-file-list-item': $can('templates.update') }]">
                                        <router-link :to="{ name: 'master-templates-usecases', params: { id: item.id } }">
                                            <div class="u-flex align-center pr-2">
                                                <div class="u-flex align-center u-cursor-pointer u-wfull">
                                                    <a-icon class="js-drag-handle mr-2" :class="{'u-opacity-30': !$can('templates.update')}" color="#a9a9a9" size="22">drag_indicator</a-icon>
                                                    <div class="u-cursor-pointer u-wfull py-2" style="min-height: 38px;">
                                                        <span class="md-body-2 grey--text text--darken-4 font-weight-medium text-truncate d-block" style="max-width: 200px;">{{ item.name }}</span>
                                                    </div>
                                                </div>
                                                <a-spacer></a-spacer>
                                                <div class="u-flex align-center">
                                                    <a-icon size="14" :color="item.is_active ? 'green darken-1' : 'grey lighten-1'">fiber_manual_record</a-icon>
                                                </div>
                                                <div class="c-master_template-item__action u-flex align-center">
                                                    <template v-if="$can('templates.update')">
                                                        <a-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <div v-on="on" @click="local_master_template_toggle_active(item.id, !item.is_active)" v-ripple style="border-radius: 0px 0px 0px 6px;" class="c-master_template-item__action-item u-cursor-pointer">
                                                                    <a-icon size="14" :color="item.is_active ? 'green darken-1' : 'grey lighten-1'">fiber_manual_record</a-icon>
                                                                </div>
                                                            </template>
                                                            <span>{{ item.is_active ? 'Enabled' : 'Disabled' }}</span>
                                                        </a-tooltip>
                                                        <a-divider vertical></a-divider>
                                                        <div @click="local_master_template_edit(item.id)" v-ripple class="c-master_template-item__action-item u-cursor-pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-pencil grey--text text--darken-1" viewBox="0 0 16 16">
                                                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                                                            </svg>
                                                        </div>
                                                    </template>
                                                    <a-divider vertical></a-divider>
                                                    <template v-if="$can('templates.destroy')">
                                                        <div v-if="local_remove_id !== item.id" @click="local_set_destroy_id(item.id)" v-ripple class="c-master_template-item__action-item u-cursor-pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-trash grey--text text--darken-1" viewBox="0 0 16 16">
                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                            </svg>
                                                        </div>
                                                        <a-tooltip bottom v-else>
                                                            <template v-slot:activator="{ on }">
                                                                <div v-on="on" @click="local_master_template_destroy(item.id)" v-ripple class="c-master_template-item__action-item u-cursor-pointer u-rounded-corners-full">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-trash red--text text--darken-2" viewBox="0 0 16 16">
                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                    </svg>
                                                                </div>
                                                            </template>
                                                            <span>Confirm ?</span>
                                                        </a-tooltip>
                                                    </template>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </draggable>
                                <a-divider class="grey lighten-2 mt-3"></a-divider>
                                <div class="u-flex align-center pa-1 mt-2 pl-2 u-cursor-pointer" v-ripple  @click="local_create_master_template()" v-if="$can('templates.store')">
                                    <a-icon v-ripple size="18" color="#a3a3a3" class="d-inline-block u-cursor-pointer u-rounded-corners">add</a-icon>
                                    <span class="md-body-2 ml-2" style="color: #a3a3a3">Create new template</span>
                                </div>
                            </div>
                        </div>
                    </a-flex>
                    <a-flex xs9 xl10 class="pb-12">
                        <router-view></router-view>
                    </a-flex>
                </a-layout>
            </a-container>
        </v-slide-y-transition>

        <a-dialog v-model="dialog_master_template_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>library_books</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (template_master_form_mode === 'add') ? 'Create New Master Template' : 'Edit Existing Master Template' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (template_master_form_mode === 'add') ? 'You can add new master template.' : 'You can edit existing master template.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name <span class="red--text text--darken-2">*</span></label>
                                    <a-text-field
                                        v-model="local_template_name"
                                        placeholder="Enter master template name"
                                        background-color="neutral"
                                        solo flat hide-details autofocus
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_master_response.server && template_master_response.server.errors && template_master_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">info</a-icon>
                                        {{ template_master_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the master template.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_master_template_store()" :loading="loading" :disabled="loading">{{ template_master_form_mode === 'add' ? 'Create master template' : 'Update existing master template'}}</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click.prevent="local_close_dialog()" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                                <a-spacer></a-spacer>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    data() {
        return {
           system_templates: [
              { id: 1, label: 'Scope', slug: 'usecases', router_name: 'master-templates-system-list-usecases', type: 'system'},
              { id: 2, label: 'Tasks', slug: 'tasks', router_name: 'master-templates-system-list-tasks', type: 'system'},
              { id: 3, label: 'Notes', slug: 'notes', router_name: 'master-templates-system-list-notes', type: 'system'},
              { id: 4, label: 'Documents', slug: 'documents', router_name: 'master-templates-system-list-documents', type: 'system'},
              { id: 5, label: 'Links',slug: 'links', router_name: 'master-templates-system-list-links', type: 'system'},
          ],
          template_list: [],
          localLoading: true,
          dialog_master_template_form: false,
          local_remove_id: null,
          local_template_name: null,
          local_template_master_item: {},
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'templates', to: '/templates', exact: true})
            return breadcrumbs
        },

        local_avoided_routes () {
            const blackList = ['master-templates-notes-view', 'master-templates-usecases-view']
            const { name } = this.$route
            return !!(blackList.includes(name))
        },

        template_master_list: {
            get() {
                return this.$store.state.TemplateMaster.list
            },
            set(list) {
                this.local_group_reorder(list)
            }
        },

        local_current_route_id() {
            return this.$route.params.id
        },

        ...mapState('TemplateMaster', {
            template_master_item: 'item',
            template_master_form_mode: 'form_mode',
            template_master_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_index () {
            await this.template_master_index({
                'fields[template_groups]': 'id,name,order,is_active,type',
                'filter[type]': 'master_template'
            })

            this.localLoading = false
        },

        async local_master_template_edit(id) {
            await this.template_master_select({id: id})
            this.local_template_name = this.template_master_item.name
            this.dialog_master_template_form = true
        },

        async local_create_master_template() {
            await this.template_master_clear_item()
            this.dialog_master_template_form = true
        },

        async local_master_template_store() {
            this.local_template_master_item.type = 'master_template'
            this.local_template_master_item.name = this.local_template_name
            if(this.template_master_form_mode === 'add') {
                this.local_template_master_item.is_active = 1
                await this.template_master_store(this.local_template_master_item)
            }else {
                this.local_template_master_item.id = this.template_master_item.id
                this.local_template_master_item.is_active = this.template_master_item.is_active
                await this.template_master_update(this.local_template_master_item)
            }
            if (this.template_master_response.status === 'success') {
                this.$router.replace({ name: 'master-templates-usecases', params: { id: this.template_master_item.id }})
                this.local_close_dialog()
            }
        },

        async local_template_master_show(id) {
            await this.template_master_show({
                'fields[template_groups]': 'id,name,order,is_active,type',
                'id': id,
            })
        },

        async local_close_dialog() {
            if(this.$route.params.id) {
                await this.template_master_select({ id : this.$route.params.id })
            }
            this.dialog_master_template_form = false
            this.local_template_name = null
            this.local_template_master_item = {}
        },

        async local_group_reorder(list) {
            await this.template_master_reorder({list: list})
        },

        async local_master_template_toggle_active(id, is_active) {
            await this.template_master_active({ id: id, state: is_active ? 1 : 0 })
            await this.local_template_master_show(id)
            await this.template_master_select({id: id})
        },

        local_switch_route({ id }) {
            if (this.$route.params.id === id) return
            this.$router.push({name: 'master-templates-usecases', params:  { id }})
        },

        local_set_destroy_id(id) {
            this.local_remove_id = id
            setTimeout(() => this.local_remove_id = null, 3000)
        },

        async local_master_template_destroy(id) {
            await this.template_master_destroy({id:id})
            if (this.template_master_response.status === 'success') {
                this.$router.replace({ name: 'master-templates-system-list-usecases'})
            }
        },

        ...mapActions('TemplateMaster', {
            template_master_index: 'index',
            template_master_store: 'store',
            template_master_show: 'show',
            template_master_update: 'update',
            template_master_active: 'toggle_active',
            template_master_reorder: 'reorder',
            template_master_select: 'select',
            template_master_destroy: 'destroy',
            template_master_clear: 'clear',
            template_master_clear_item: 'clear_item',
        }),

        ...mapActions('TemplateTask', {
            template_task_show: 'show',
            template_task_update: 'update',
            template_task_visibility: 'visibility',
            template_task_select: 'select',
            template_task_clear_item: 'clear_item',
        }),

        ...mapActions('TemplateMilestone', {
            template_milestone_index: 'index',
            template_milestone_show: 'show',
            template_milestone_store: 'store',
            template_milestone_update: 'update',
            template_milestone_group_move: 'group_milestone_move',
            template_milestone_group_copy: 'group_copy_store',
            template_milestone_select: 'select',
            template_milestone_task_store: 'task_store',
            template_milestone_task_reorder: 'tasks_reorder',
            template_milestone_task_select: 'task_select',
            template_milestone_task_update: 'task_update',
            template_milestone_task_destroy: 'task_destroy',
            template_milestone_reorder: 'reorder',
            template_milestone_destroy: 'destroy',
            template_milestone_clear_item: 'clear_item',
            template_milestone_clear : 'clear',
        }),
         ...mapActions('TemplateTask', {
            template_task_index: 'index',
            template_task_show: 'show',
            template_task_store: 'store',
            template_task_group_move: 'group_task_move',
            template_task_group_copy: 'group_copy_store',
            template_task_select_item: 'select_item',
            template_task_complete: 'complete',
            template_task_uncomplete: 'uncomplete',
            template_task_client_visible: 'client_visible',
            template_task_client_invisible: 'client_invisible',
            template_task_update: 'update',
            template_task_select: 'select',
            template_task_upgrade: 'upgrade',
            template_task_downgrade: 'downgrade',
            template_task_destroy: 'destroy',
            template_task_reorder: 'reorder',
            template_task_clear: 'clear',
            template_task_clear_item: 'clear_item',
        }),
    }
}
</script>

<style lang="scss" scoped>
    .sidebar {
        position: sticky;
        top: 80px;
        left: 0px;
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
        min-height: 100%;
    }
    .tags {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .c-master_template-item {
        position: relative;
        overflow-x: hidden;
        &__action {
            visibility: hidden;
            position: absolute;
            right: -1000px;
            top: 0px;
            background: #fff;
            border-radius: 0px 0px 0px 6px;
            box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 16%);
            // transition: 0.1s ease-in-out;
        }
        &__action-item {
            display: flex;
            align-items: center;
            padding: 5px 10px;
        }
        &:hover {
            .c-master_template-item__action {
                visibility: visible;
                right: 0px;
                // transition: 0.1s ease-in-out;
            }
        }
    }
    // .action_visible {
    //     visibility: visible;
    //     right: 0px;
    //     transition: 0.2s ease-in-out;
    // }
</style>

<template>
    <a-responsive v-bind="$attrs" class="neutral task-detail-pane elevation-12" style="margin-top: 58px;">
        <a-container container--fluid grid-list-xs class="white px-6 pt-6 pb-4" v-if="task.id">
            <a-layout wrap align-start class="mb-4">
                <a-flex class="u-flex align-center">
                    <slot name="status"></slot>
                    <!-- <a-btn class="ma-0 elevation-0" small :disabled="!canUpdate" v-if="!task.completed_at" @click="$emit('toggle-complete', task.id, task.completed_at, task.milestone_id)">
                        <a-icon size="20" color="grey darken-3" left class="mr-2">radio_button_unchecked</a-icon> Mark Complete
                    </a-btn>
                    <a-btn class="ma-0 elevation-0" small color="teal" :disabled="!canUpdate" :dark="canUpdate" v-if="task.completed_at" @click="$emit('toggle-complete', task.id, task.completed_at, task.milestone_id)">
                        <a-icon size="20" color="white" left class="mr-2">done</a-icon> Mark Incomplete
                    </a-btn> -->
                    <!-- <a-btn color="red" class="ml-2" text small @click="$emit('destroy', task.id, task.milestone_id)" :disabled="!canDelete" :dark="canDelete">
                        Delete
                    </a-btn> -->
                    <a-btn v-if="local_destroy_item_id !== task.id" icon text small color="grey" @click="local_destroy_item_id = task.id" class="ma-0 ml-3">
                        <a-icon size="18">delete</a-icon>
                    </a-btn>
                    <a-tooltip bottom right v-if="local_destroy_item_id === task.id">
                        <template v-slot:activator="{ on }">
                            <a-btn v-on="on" icon text small color="red" class="ma-0 ml-3" @click="$emit('destroy', task.id, task.milestone_id)" :disabled="!canDelete" :dark="canDelete">
                                <a-icon color="red" size="18" >delete</a-icon>
                            </a-btn>
                        </template>
                        <span>Confirm Delete?</span>
                    </a-tooltip>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink>
                    <!-- <a-btn color="red" class="ml-2" text small icon @click="$emit('destroy', task.id, task.milestone_id)" :disabled="!canDelete" :dark="canDelete">
                        <a-icon size="18" color="red">delete</a-icon>
                    </a-btn> -->
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-btn v-on="on" color="grey" text small dark icon v-ripple="false" @click="local_close">
                                <a-icon size="18" color="grey">login</a-icon>
                            </a-btn>
                        </template>
                        <span>Close</span>
                    </a-tooltip>
                </a-flex>
            </a-layout>
            <div class="mb-10">
                <a-layout wrap class="mb-1 u-relative">
                    <a-flex xs12>
                        <a-textarea
                            v-model="task.title"
                            :class="[ {'indigo': title_edit_mode && canUpdate}, 'font-weight-regular content-editable u-border']"
                            placeholder="Enter Title"
                            rows="1"
                            :readonly="!canUpdate"
                            multi-line auto-grow solo flat hide-details
                            @input="local_validation('title')"
                            @focus="local_focus('title')"
                            @blur="revert_mode ? {} : local_update('title')"
                        ></a-textarea>
                    </a-flex>
                    <div class="u-absolute" style="bottom: -32px; right: 0">
                        <div v-if="title_edit_mode && canUpdate">
                           <v-hover v-slot="{ hover }">
                                <a-btn x-small min-width="2" height="28" class="mr-2 u-elevation-custom-1" :style="{'background-color': hover ? '#E8F5E9' : ''}">
                                    <a-icon x-small :style="{'color': hover ? '#388E3C' : ''}">done</a-icon>
                                </a-btn>
                            </v-hover>
                            <v-hover v-slot="{ hover }">
                                <a-btn class="u-elevation-custom-1" x-small min-width="2" height="28" @mousedown="local_revert_changes('title')" :style="{'background-color': hover ? '#FFEBEE' : ''}">
                                    <a-icon x-small :style="{'color': hover ? '#E53935' : ''}">close</a-icon>
                                </a-btn>
                            </v-hover>
                        </div>
                    </div>
                    <div class="u-absolute" style="bottom: -28px; left: 0">
                        <span class="md-caption red--text text--darken-2 d-block mt-1" v-if="error_response && error_response.title">
                            <a-icon size="16" color="red darken-2">warning</a-icon>
                            {{ error_response.title }}
                        </span>
                    </div>
                </a-layout>
            </div>
            <a-layout wrap mt-4 class="mb-1 u-relative">
                <a-flex xs12>
                    <a-textarea
                        v-model="task.description"
                        :class="[ {'indigo': description_edit_mode && canUpdate}, 'font-weight-regular content-editable u-border overflow-auto u-dashboard-scroll']"
                        rows="2"
                        style="maxHeight: 320px"
                        :placeholder="(user_self.scope === 'internal') ? 'Enter Description' : 'No Description'"
                        :readonly="!canUpdate"
                        multi-line no-resize
                        auto-grow solo flat hide-details
                        @input="local_validation('description')"
                        @focus="local_focus('description')"
                        @blur="revert_mode ? {} : local_update('description')"
                    ></a-textarea>
                </a-flex>
                <div class="u-absolute" style="bottom: -32px; right: 0">
                    <div v-if="description_edit_mode && canUpdate">
                        <v-hover v-slot="{ hover }">
                            <a-btn x-small min-width="2" height="28" class="mr-2 u-elevation-custom-1" :style="{'background-color': hover ? '#E8F5E9' : ''}">
                                <a-icon x-small :style="{'color': hover ? '#388E3C' : ''}">done</a-icon>
                            </a-btn>
                        </v-hover>
                        <v-hover v-slot="{ hover }">
                            <a-btn class="u-elevation-custom-1" x-small min-width="2" height="28" @mousedown="local_revert_changes('description')" :style="{'background-color': hover ? '#FFEBEE' : ''}">
                                <a-icon x-small :style="{'color': hover ? '#E53935' : ''}">close</a-icon>
                            </a-btn>
                        </v-hover>
                    </div>
                </div>
                <div class="u-absolute" style="bottom: -28px; left: 0">
                    <span class="md-caption red--text text--darken-2 d-block mt-1" v-if="error_response && error_response.description">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ error_response.description }}
                    </span>
                </div>
            </a-layout>
            <a-layout mt-10>
                <a-flex pb-0>
                    <slot name="assignees"></slot>
                </a-flex>
                <a-spacer></a-spacer>
            </a-layout>
        </a-container>
        <a-divider></a-divider>
        <a-container container--fluid grid-list-xs class="white px-6 pb-3 pt-3" v-if="task && task.id">
            <a-layout align-center class="mb-3">
                <a-flex shrink align-self-center class="mr-2">
                    <a-icon size="24" color="grey lighten-1">event</a-icon>
                </a-flex>
                <a-flex>
                    <span class="md-caption grey--text mb-0">Start Date</span>
                    <a-menu :close-on-content-click="false" v-model="task_start_date" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" :disabled="!canUpdate || error_fields">
                        <template v-slot:activator="{ on }">
                            <div v-if="task.start_date" v-on="on">
                                <g-moment input-format="YYYY-MM-DD" output-format="MMMM D, YYYY" :value="task.start_date" class="u-cursor-pointer md-body-2"></g-moment>
                            </div>
                            <div v-else v-on="on">
                                <span class="md-body-2 grey--text u-cursor-pointer">No Start Date</span>
                            </div>
                        </template>
                        <a-date-picker v-model="task.start_date" :max="task.due_date" no-title scrollable @input="local_update()"></a-date-picker>
                    </a-menu>
                    <p class="blue--text caption mb-0 u-cursor-pointer" v-if="task.start_date !== null && canUpdate && !error_fields" @click="local_clear_date('start_date')">Clear Start Date</p>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink align-self-center class="mr-2">
                    <a-icon size="24" color="grey lighten-1">event</a-icon>
                </a-flex>
                <a-flex style="min-width: 175px">
                    <span class="md-caption grey--text mb-0">Due Date</span>
                    <a-menu :close-on-content-click="false" v-model="task_due_date" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" :disabled="!canUpdate || error_fields">
                        <template v-slot:activator="{ on }">
                            <div v-if="task.due_date" v-on="on">
                                <g-moment input-format="YYYY-MM-DD" output-format="MMMM D, YYYY" :value="task.due_date" class="u-cursor-pointer md-body-2" :class="{'red--text text--darken-2': local_due_date_passed(task.due_date, task.completed_at)}"></g-moment>
                            </div>
                            <div v-else v-on="on">
                                <span class="md-body-2 grey--text u-cursor-pointer">No Due Date</span>
                            </div>
                        </template>
                        <a-date-picker v-model="task.due_date" :min="task.start_date" no-title scrollable @input="local_update()"></a-date-picker>
                    </a-menu>
                    <p class="blue--text caption mb-0 u-cursor-pointer" v-if="task.due_date !== null && canUpdate && !error_fields" @click="local_clear_date('due_date')">Clear Due Date</p>
                </a-flex>
                <a-flex xs12 text-right pa-0 v-if="$response(task_response, 'due_date')">
                    <span class="md-caption red--text text--darken-1">{{ $response(task_response, 'due_date') }}</span>
                </a-flex>
            </a-layout>
            <a-layout align-center class="mb-3">
                <a-flex shrink align-self-center class="mr-2">
                    <a-icon size="24" color="grey lighten-1">priority_high</a-icon>
                </a-flex>
                <a-flex>
                    <p class="md-caption grey--text mb-0">Priority</p>
                    <a-menu offset-y :disabled="!canUpdate || error_fields">
                        <template v-slot:activator="{ on }">
                            <p class="md-subtitle-1 mb-0 u-cursor-pointer grey--text" v-if="task.priority === null" v-on="on">No Priority</p>
                            <p class="md-subtitle-1 mb-0 u-cursor-pointer" v-else v-on="on">
                                <a-chip small :class="{
                                    'red lighten-4 red--text text--darken-2 ml-0': task.priority === 'high',
                                    'blue lighten-4 blue--text text--darken-2 ml-0': task.priority ===  'low'
                                }">
                                    {{ (task.priority === 'high') ? 'High' : '' }}
                                    {{ (task.priority ===  'low') ? 'Low' : '' }}
                                    <a-icon small>keyboard_arrow_down</a-icon>
                                </a-chip>
                            </p>
                        </template>
                        <a-list>
                            <a-list-item @click="$emit('priority-update', 'high')">
                                <a-list-item-title>
                                    High
                                </a-list-item-title>
                            </a-list-item>
                            <a-list-item @click="$emit('priority-update', 'low')">
                                <a-list-item-title>
                                    Low
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                    <p class="blue--text caption mb-0 u-cursor-pointer" v-if="task.priority !== null && canUpdate && !error_fields" @click="$emit('priority-update', null)">Clear Priority</p>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink>
                    <div class="u-flex align-end">
                        <a-icon size="24"  class="mr-2 mb-1" color="grey lighten-1">assistant_photo</a-icon>
                        <div style="min-width: 175px">
                            <span class="md-caption grey--text mb-0">Milestone</span>
                            <a-menu bottom right offset-y min-width="240" :disabled="!canUpdate || error_fields">
                                <template v-slot:activator="{ on }">
                                    <p v-if="task.milestone_id !== null && task.milestone" v-on="on" class="md-subtitle-1 mb-0 u-cursor-pointer">
                                        {{ task.milestone.title | truncateText(13) }}
                                    </p>
                                    <p v-else v-on="on" class="md-subtitle-1 mb-0 u-cursor-pointer">No Milestone</p>
                                </template>
                                <a-list class="u-list-condensed u-overflow-y" style="max-height: 250px;" v-if="milestoneList && milestoneList.length > 0">
                                    <a-list-item v-for="milestone in milestoneList" :key="milestone.id"  :disabled="task.milestone && task.milestone_id !== null && milestone.id == task.milestone.id" @click="$emit('milestone-update', milestone.id)">
                                        <a-list-item-content>
                                            <a-list-item-title class="text-truncate" style="max-width: 300px">{{ milestone.title }}</a-list-item-title>
                                        </a-list-item-content>
                                    </a-list-item>
                                    <a-list-item :disabled="task.milestone_id == null" @click="$emit('milestone-update', null)">
                                        <a-list-item-content>
                                            <a-list-item-title>No Milestone</a-list-item-title>
                                        </a-list-item-content>
                                    </a-list-item>
                                </a-list>
                            </a-menu>
                        </div>
                    </div>
                </a-flex>
            </a-layout>
        </a-container>
        <a-divider></a-divider>
        <a-container container--fluid grid-list-xs class="pa-6 mb-5" v-if="task.id">
            <a-form @submit.prevent="local_comment_store()">
                <a-layout wrap align-start v-if="canComment">
                    <a-flex xs9 class="mb-4">
                        <a-textarea
                            v-model="comment_item.content"
                            class="elevation-1"
                            placeholder="Add Your Comment"
                            rows="2"
                            multi-line
                            auto-grow solo flat hide-details
                            :disabled="error_fields"
                        >
                        </a-textarea>
                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="comment_response.server && comment_response.server.errors && comment_response.server.errors.comment_text">
                            <a-icon size="16" color="red darken-2">warning</a-icon>
                            {{ comment_response.server.errors.comment_text[0] }}
                        </span>
                    </a-flex>
                    <a-flex xs3>
                        <a-btn class="ma-2 elevation-0 primary lighten-1" :disabled="error_fields" block @click="local_comment_store()">
                            <a-icon>keyboard_return</a-icon>
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-form>
            <a-layout wrap align-center>
                <a-flex xs12>
                    <p class="md-body-2 my-4 pb-3 grey--text text--darken-1 text-center" v-if="comment_list && !comment_list.length"><a-icon size="16" color="grey" class="mr-2">chat_bubble</a-icon> No comments for this task.</p>
                    <div v-else>
                        <p class="md-body-2 mb-2 grey--text text--darken-1"><a-icon size="16" color="grey" class="mr-2">chat_bubble</a-icon> Comments</p>
                        <a-layout class="my-4" v-for="comment  in comment_list" :key="comment.id">
                            <a-tooltip bottom v-if="comment && comment.created_by">
                                <template v-slot:activator="{ on }">
                                    <g-avatar v-on="on" :item="comment.created_by"></g-avatar>
                                </template>
                                <span>{{ comment.created_by.name }}</span>
                            </a-tooltip>
                            <a-flex>
                                <a-card class="u-elevation-custom-1">
                                    <a-card-text class="pa-3">
                                        <!-- <h4 class="md-caption primary--text">{{ (comment.creator) ? comment.creator.name : '–' }}</h4> -->
                                        <p class="md-body-2 mb-0 text-wrap-pre">{{ comment.content }}</p>
                                    </a-card-text>
                                </a-card>
                                <a-layout align-center>
                                    <a-flex shrink>
                                        <g-moment class="md-caption grey--text d-block pt-2" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM D, YYYY h:mma" :value="comment.created_at" convert-local></g-moment>
                                    </a-flex>
                                </a-layout>
                            </a-flex>
                        </a-layout>
                    </div>
                </a-flex>
            </a-layout>
        </a-container>
    </a-responsive>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    inheritAttrs: false,
    props: {
        task: {
            type: Object,
            required: true
        },
        milestoneList: {
            type: Array,
        },
        canDelete: {
            type: Boolean
        },
        canUpdate: {
            type: Boolean
        },
        canComment: {
            type: Boolean
        },
        revertItem: {
            type: Object,
        }
    },

    data () {
        return {
            local_destroy_item_id: null,
            task_start_date: null,
            task_due_date: null,
            error_response: {},
            title_edit_mode: false,
            description_edit_mode: false,
            revert_mode: false,
            revert_target: null,
            storedItem: {},
        }
    },

    watch: {
        task (val) {
            if (!Object.keys(val).length) return
            this.storedItem = {...val}
            this.error_response = {}
        },

        error_fields (val) {
            const status = val ? true : false
            this.$emit('error', status)
        },

        revertItem (val, oldVal) {
            if (this.revert_target === 'title') {
                this.task.title = val.title
                this.error_response.title = null
                return
            }
            this.task.description = val.description
            this.error_response.description = null
        },

    },

    computed: {
        error_fields () {
            if (!Object.keys(this.task).length) return
            const title_error = (!this.task.title || (this.task.title && this.task.title.length > 255))
            const description_error = (this.task.description && this.task.description.length > 50000)
            return !!(title_error || description_error)
        },
        local_title_modified() {
            const title_modified =  (this.task.title && this.storedItem.title !== this.task.title.trim())
            return title_modified
        },
        local_description_modified() {
            const description_modified = (this.storedItem.description !== this.task.description)
            return description_modified
        },
        ...mapState('TaskExternal', {
            task_item: 'item',
            task_response: 'response',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('CommentExternal', {
            comment_item: 'item',
            comment_list: 'task_comments',
            comment_response: 'response',
        }),
    },

    methods: {
        local_update (field) {
            this.title_edit_mode = false
            this.description_edit_mode = false

            if (!field) return this.$emit('task-update', this.task)

            if (field === 'title') {
                if (!this.local_title_modified || this.error_response.title) return
                this.storedItem.title = this.task.title
                return this.$emit('task-update', { title: this.task.title })
            }

            if (!this.local_description_modified || this.error_response.description) return
            this.storedItem.description = this.task.description
            return this.$emit('task-update', { description: this.task.description })

        },

        async local_comment_store() {
            this.comment_item.commentable_type = 'Task'
            this.comment_item.commentable_id = this.task.id
            this.comment_item.visibility = 'internal'
            await this.comment_store(this.comment_item)
            if (this.comment_response.status !== 'success') return
            await this.comment_show({id : this.comment_item.id, type: 'Task', params : { 'include': 'createdBy',}})
            await this.comment_clear_item()
        },

        local_clear_date (type) {
            this.task[type] = null
            this.local_update()
        },

        local_due_date_passed(date, completed_at) {
            return moment(date).isBefore() && !completed_at
        },

        local_validation (category) {
            this.$emit('task-item-edited')
            if (category === 'title') {
                if (!this.task.title) this.error_response.title = 'Required field.'
                else if (!!this.task.title && this.task.title.length > 255) this.error_response.title = 'Only maximum of 255 characters allowed.'
                else this.error_response.title = null
            }
            if (category === 'description') {
                if (!!this.task.description && this.task.description.length > 50000) this.error_response.description = 'Only maximum of 50000 characters allowed.'
                else this.error_response.description = null
            }
        },

        async local_revert_changes (type) {
            this.title_edit_mode = false
            this.description_edit_mode = false
            this.revert_mode = true
            this.revert_target = type
            this.$emit('revert', this.storedItem)
        },

        local_close () {
            this.error_response = {}
            this.$emit('close')
        },

        local_focus(type) {
            if (type === 'title') {
                this.title_edit_mode = true
                this.description_edit_mode = false
                this.revert_mode = false
                return
            }
            this.title_edit_mode = false
            this.description_edit_mode = true
            this.revert_mode = false
        },

        ...mapActions('CommentExternal', {
            comment_store: 'store',
            comment_show: 'show',
            comment_clear_item: 'clear_item',
        }),
    }
}
</script>

<style scoped>
    .c-update-btn.v-btn.v-btn--disabled {
        opacity: 0.7;
        background-color: #C8E6C9 !important;
        color: #81C784 !important;
    }
</style>

const api_endpoint = '/slack';

export default {
    namespaced: true,
    state: {
        status: '',
        response: [],
        defaults: {
            status: '',
            response: [],
        },
    },
    mutations: {
        UPDATE_STATUS(state, item) {
            state.status = item;
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        status(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/status')
                .then((response) => {
                    context.commit('UPDATE_STATUS', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        revoke_access(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/revoke-access')
                .then((response) => {
                    context.dispatch('status')
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.status));
                resolve('Cleared');
            });
        },
    },
}

<template>
    <PartialMetaLayout
        title="Task Statuses"
        subtitle="Customize labels associated with tasks"
        :loading="loading"
    >
        <div>
            <draggable v-if="meta_list && meta_list.length" v-model="meta_list" :options="{handle: '.js-drag-handle'}" tag="div" class="u-elevation-custom-1 white u-rounded-corners">
                <a-card flat v-for="(meta, index) in meta_list" :key="meta.id">
                    <a-divider v-if="index !== 0" class="grey lighten-4"></a-divider>
                    <a-card-text class="pa-0">
                        <a-container container--fluid grid-list-xl class="pa-4 px-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pl-0 pr-1" v-if="loader_item === meta.id">
                                    <a-progress-circular color="blue lighten-2" size="20" width="2" indeterminate></a-progress-circular>
                                </a-flex>
                                <template v-else>
                                    <a-flex shrink class="px-0" v-if="check_meta_permission('update')">
                                        <a-icon class="grey--text text--lighten-1 u-cursor-pointer js-drag-handle">drag_indicator</a-icon>
                                    </a-flex>
                                </template>
                                <a-flex>
                                    <h2 class="md-subtitle-1 font-weight-medium mb-0">{{ meta.value }}<span class="mx-2 md-caption grey--text text--lighten-1">&bull;</span>{{ meta.percentage }}%</h2>
                                </a-flex>
                                <a-flex shrink>
                                    <a-chip color="primary" text-color="white" small>{{ meta.status }}</a-chip>
                                </a-flex>
                                <a-flex shrink v-if="check_meta_permission('update')">
                                    <s-color-palette v-model="meta.color" @input="color => local_update_debounced(color, meta)"></s-color-palette>
                                </a-flex>
                                <a-flex shrink v-if="check_meta_permission('update')">
                                    <a-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <a-icon size="24" v-if="meta.is_starred" color="orange" v-on="on" @click="local_meta_toggle_starred(meta, 0)">star</a-icon>
                                            <a-icon v-else size="24" @click='local_meta_toggle_starred(meta, 1)' v-on="on">star_border</a-icon>
                                        </template>
                                        <span class="text-center" v-if="meta.is_starred">Default</span>
                                        <span class="text-center" v-else>Set as default</span>
                                    </a-tooltip>
                                </a-flex>
                                <a-flex shrink pl-1 v-if="check_meta_permission('update')">
                                    <a-btn color="grey darken-2" @click="local_edit(meta.id)" text icon small>
                                        <a-icon small>edit</a-icon>
                                    </a-btn>
                                </a-flex>
                                <a-flex shrink pl-0 v-if="check_meta_permission('destroy')">
                                    <a-tooltip bottom v-if="meta.is_starred">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <a-btn color="grey darken-2" text icon small disabled>
                                                    <a-icon small>delete</a-icon>
                                                </a-btn>
                                            </div>
                                        </template>
                                        <span>You cannot delete this value, it is set as default</span>
                                    </a-tooltip>
                                    <a-btn v-else color="grey darken-2" @click="local_show_transfer_dialog(meta)" text icon small>
                                        <a-icon small>delete</a-icon>
                                    </a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-card-text>
                </a-card>
            </draggable>
            <a-card v-if="check_meta_permission('store')" class="u-elevation-custom-1" :class="{ 'mt-3': meta_list && meta_list.length }">
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                    <a-container container--fluid grid-list-xl class="py-2">
                        <a-layout wrap align-center>
                            <a-flex shrink class="pr-0">
                                <a-icon color="grey" size="20">add</a-icon>
                            </a-flex>
                            <a-flex pl-0>
                                <a-text-field
                                    v-model="meta_item_value"
                                    placeholder="Type picklist value and hit enter"
                                    solo flat hide-details
                                    @keyup.enter="local_store()"
                                >
                                </a-text-field>
                            </a-flex>
                            <a-flex shrink>
                                <a-menu bottom offset-y>
                                    <template v-slot:activator="{ on }">
                                        <a-chip class="px-2" color="grey darken-2" text-color="white" small left v-on="on">
                                            {{ meta_item_status }}
                                            <a-icon class="ml-1" color="white" size="18">arrow_drop_down</a-icon>
                                        </a-chip>
                                    </template>
                                    <a-list>
                                        <a-list-item v-for="(status, index) in statuses" :key="'status_'+index" @click="local_meta_status_select(status)">
                                            <a-list-item-title>{{ status }}</a-list-item-title>
                                        </a-list-item>
                                    </a-list>
                                </a-menu>
                            </a-flex>
                            <a-flex shrink class="pr-4">
                                <a-btn color="grey darken-2" text icon small @click="local_store()">
                                    <a-icon small>keyboard_return</a-icon>
                                </a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
            <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(meta_response, 'value') && meta_form_mode === 'add'">
                <a-icon size="16" color="red darken-2">warning</a-icon>
                {{ $response(meta_response, 'value') }}
            </span>
            <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(meta_response, 'percentage') && meta_form_mode === 'add'">
                <a-icon size="16" color="red darken-2">warning</a-icon>
                {{ $response(meta_response, 'percentage') }}
            </span>
        </div>

        <!-- Meta edit form -->
        <a-dialog v-model="dialog_meta_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>view_column</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (meta_form_mode == 'add') ? 'Create Picklist Value' : 'Edit Existing Picklist Value' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (meta_form_mode == 'add') ? 'You can add new picklist value to the system.' : 'You can edit existing picklist value.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Label</label>
                                    <a-text-field
                                        v-model="meta_item.value"
                                        placeholder="Enter Label"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(meta_response, 'value') && meta_form_mode == 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ $response(meta_response, 'value') }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Enter the label to be selected.
                                    </span>
                                </a-flex>
                                <a-flex xs12 pb-2 class="c-percent-slider">
                                    <label class="md-body-2 d-block mt-0 font-weight-medium mr-2">Percentage <span class="md-body-2 grey--text text--darken-3 font-weight-medium ml-1">{{ meta_item.percentage }}%</span></label>
                                    <a-slider
                                        v-model="meta_item.percentage"
                                        color="green darken-1"
                                        step="1"
                                        height="32"
                                        track-color="grey lighten-2"
                                        prepend-icon="remove"
                                        append-icon="add"
                                        @click:append="meta_item.percentage++"
                                        @click:prepend="meta_item.percentage--"
                                        hide-details
                                    ></a-slider>
                                    <span class="md-caption red--text text--darken-2 d-block" v-if="$response(meta_response, 'percentage') && meta_form_mode == 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ $response(meta_response, 'percentage') }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 u-flex align-center" v-else>
                                        <a-icon size="16" class="mr-1">info</a-icon>
                                        Select percentage value for the status
                                    </span>
                                </a-flex>
                                <a-flex xs12 pt-2>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Status</label>
                                    <a-select
                                        v-model="meta_item.status"
                                        :items="statuses"
                                        placeholder="Status"
                                        :disabled="(meta_item && meta_item.tasks_count > 0)"
                                        :append-icon="(meta_item && meta_item.tasks_count > 0) ? '' : 'arrow_drop_down'"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-select>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(meta_response, 'status')">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ $response(meta_response, 'status') }}
                                    </span>
                                    <template v-else>
                                        <span class="md-caption grey--text text--darken-2 u-flex align-center mt-2" v-if="meta_item && !meta_item.tasks_count">
                                            <a-icon size="16" class="mr-1">info</a-icon>
                                            Select the status for the label.
                                        </span>
                                        <span class="md-caption d-block mt-2" v-else>
                                            <div class="u-flex align-center u-rounded-corners">
                                                <a-icon size="20" class="mr-1 u-icon-nudge" style="top: 0px;" color="brown darken-1">note_alt</a-icon>
                                                <span class="md-caption brown--text text--darken-1 font-weight-medium">NOTE: Status can not be updated, it is associated with one or more tasks.</span>
                                            </div>
                                        </span>
                                    </template>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_update()" :loading="loading" :disabled="loading">Save Value</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_meta_form = !dialog_meta_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-dialog max-width="480" v-model="dialog_transfer_meta" persistent>
            <a-card class="pa-6 pb-7 px-8">
                <h2 class="md-heading-5 grey--text text--darken-3">Delete Task Status</h2>
                <div class="mt-2 md-subtitle-1 grey--text text--darken-3">
                    <span>You are about to delete</span>
                    <div class="d-inline-flex align-center grey lighten-4 py-1 px-2 pr-3 ml-2 u-rounded-corners-full">
                        <a-icon size="14" class="u-icon-nudge mr-1" style="top: 1px;" :color="$color(meta_to_delete.color, 'color_bg')">fiber_manual_record</a-icon>
                        <span class="md-caption font-weight-medium grey--text text--darken-1">{{ meta_to_delete.value | truncateText(15) }}</span>
                        <span class="md-caption font-weight-medium grey--text text--darken-1 ml-2">{{ meta_to_delete.percentage }}%</span>
                    </div>
                    <template v-if="meta_to_delete.tasks_count > 0">
                        <div class="md-subtitle-1 grey--text text--darken-1 mt-4">
                            It is associated with <span class="font-weight-medium grey--text text--darken-2">{{ meta_to_delete.tasks_count }}</span> tasks. Select a status below to transfer these tasks.
                        </div>
                        <a-autocomplete
                            v-model="transfer_meta"
                            :items="local_filtered_meta()"
                            item-text="value"
                            item-value="id"
                            background-color="neutral"
                            placeholder="Select status to transfer"
                            class="u-rounded-corners mt-4"
                            solo flat hide-details clearable
                        >
                            <template #no-data>
                                <div class="md-body-1 grey--text px-4 py-2">No Data Found</div>
                            </template>
                            <template v-slot:item="{ item }">
                                <div class="u-flex align-center u-wfull">
                                    <div class="u-flex align-center">
                                        <a-icon size="18" class="u-icon-nudge mr-1" style="top: 1px;" :color="$color(item.color, 'color_bg')">fiber_manual_record</a-icon>
                                        <span class="md-body-2 grey--text text--darken-1">{{ item.value | truncateText(30) }}</span>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <span class="md-body-2 grey--text text--darken-1">{{ item.percentage }}%</span>
                                </div>
                            </template>
                        </a-autocomplete>
                    </template>
                    <template v-else>
                        <div class="md-subtitle-1 grey--text text--darken-1 mt-1">
                            Are you sure to delete this status? This action cannot be reverted.
                        </div>
                    </template>
                </div>
                <div :class="[meta_to_delete.tasks_count > 0 ? 'mt-6' : 'mt-5']">
                    <a-btn small class="ma-0 px-3 elevation-0 red lighten-4 red--text text--darken-2" v-if="meta_to_delete" @click="local_destroy(meta_to_delete.id)" :disabled="meta_to_delete.tasks_count > 0 ? local_check_valid_id(transfer_meta) : false" :loading="loading">Delete Status</a-btn>
                    <a-btn small color="grey" class="ma-0 ml-4 grey lighten-4" text @click="dialog_transfer_meta = !dialog_transfer_meta">Cancel</a-btn>
                </div>
            </a-card>
        </a-dialog>
    </PartialMetaLayout>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MetaPermission from '@/mixins/mixin-meta-permission'
import { SColorPalette } from '@/config/config-shared-components'
import { TabMeta } from '@/config/config-tab-components'
import { PartialMetaLayout } from './Partials'

export default {
    mixins: [MetaPermission],

    components: {
        TabMeta, SColorPalette, PartialMetaLayout
    },

    data() {
        return {
            dialog_meta_form: false,
            dialog_transfer_meta: false,
            statuses: ['Unstarted', 'Started', 'Completed'],
            meta_item_status: 'Unstarted',
            meta_item_value: '',
            timer_update_debounce: null,
            loader_item: null,
            transfer_meta: null,
            meta_to_delete: {}
        }
    },

    mounted() {
        this.local_index()
    },

    watch:{
        dialog_meta_form(newValue, oldValue) {
            if (newValue === false) {
                this.meta_clear_item()
            }
        },
    },

    computed: {
        breadcrumb_items () {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Types & Statuses', to: '/settings/metas', exact: true})
            return breadcrumbs
        },

        meta_list: {
            get() {
                return this.$store.state.Meta.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },

        local_starred_meta () {
            return this.meta_list.find(item => item.is_starred)
        },

        ...mapState('Meta', {
            meta_item: 'item',
            meta_filters: 'filters',
            meta_form_mode: 'form_mode',
            meta_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index () {
            if (this.check_meta_permission('index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.meta_clear()
            await this.meta_index({
                'filter[type]': 'task_status',
                'include': 'tasksCount',
                'sort': 'order'
            })
        },

        async local_edit (id) {
            await this.meta_select({id: id})
            this.dialog_meta_form = true
        },

        async local_create () {
            await this.meta_clear_item()
            this.dialog_meta_form = true
        },

        async local_store () {
            this.meta_item.type = 'task_status'
            this.meta_item.value = this.meta_item_value
            this.meta_item.status = this.meta_item_status
            delete this.meta_item.percentage
            await this.meta_store(this.meta_item)
            if (this.meta_response.status === 'success') {
                this.meta_item_value = ''
                this.meta_item_status = 'Unstarted'
                await this.meta_clear_item()
            }
        },

        async local_update () {
            await this.meta_update(this.meta_item)
            if (this.meta_response.status === 'success') {
                this.meta_item_status = 'Unstarted'
                this.dialog_meta_form = false
                await this.meta_clear_item()
                this.local_clear_debounce_timer()
            }
        },

        async local_destroy (id) {
            await this.meta_destroy({ id: id, transfer_meta_id: this.transfer_meta ?? null })
            if (this.dialog_transfer_meta) this.dialog_transfer_meta = false
        },

        local_show_transfer_dialog (meta) {
            this.transfer_meta = null
            this.meta_to_delete = meta
            this.dialog_transfer_meta = true
        },

        async local_reorder (list) {
            this.meta_reorder({list: list})
        },

        async local_meta_status_select (status) {
            this.meta_item_status = status
        },

        async local_update_debounced (color, meta) {
            await this.meta_select({id: meta.id})
            this.local_clear_debounce_timer()
            if (color) this.meta_item.color = color
            this.loader_item = meta.id
            this.timer_update_debounce = setTimeout(this.local_update, 200)
        },

        async local_clear_debounce_timer () {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                this.loader_item = null
            }
        },

        async local_meta_toggle_starred (meta, is_starred) {
            if (meta.is_starred && !is_starred) return
            if (this.local_starred_meta) this.meta_remove_default({ id: this.local_starred_meta.id, state: 0 })
            this.meta_toggle_starred({ id: meta.id, state: is_starred })
        },

        local_filtered_meta () {
            if (this.meta_to_delete) {
                return this.meta_list.filter(({ id }) => id !== this.meta_to_delete.id)
            }
        },

        local_check_valid_id (id) {
            const metaIds = this.meta_list.map(({ id }) => id)
            return !(metaIds.includes(id))
        },

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_store: 'store',
            meta_update: 'update',
            meta_select: 'select',
            meta_toggle_starred: 'toggle_starred',
            meta_remove_default: 'remove_default',
            meta_destroy: 'destroy',
            meta_reorder: 'reorder',
            meta_clear: 'clear',
            meta_clear_item: 'clear_item',
        }),
    },
}
</script>

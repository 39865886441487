<template>
    <a-main>
        <a-container container--fluid grid-list-xl pa-6>
            <a-layout wrap align-start v-if="check_permission('store')">
                <a-flex shrink py-0 class="u-flex align-center">
                    <a-btn small dark color="blue" class="ma-0 elevation-0" @click="local_ticket_create()">
                        Create Ticket
                    </a-btn>
                </a-flex>
            </a-layout>

            <!-- Page Loading -->
            <a-layout v-if="page_loading">
                <a-flex>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-medium text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Tickets..</h3>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <a-layout v-if="!page_loading && (ticket_list && !ticket_list.length)">
                <a-flex>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-medium text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                        <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
                    </a-responsive>
                </a-flex>
            </a-layout>

            <!-- Tickets List -->
            <a-layout wrap align-start v-if="!page_loading">
                <a-flex xs12>
                    <draggable v-model="ticket_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                        <div class="u-wfull relative u-rounded-corners">
                            <div
                                v-for="(ticket) in ticket_list"
                                :key="ticket.id"
                                @click="local_show_ticket(ticket.id)"
                                class="u-wfull js-draggable-file-list-item white u-elevation-custom-1 u-cursor-pointer u-rounded-corners mb-2 pa-4"
                            >
                                <div class="u-flex-center-y">
                                    <h2 class="md-subtitle-1 grey--text text--darken-4 text-truncate pr-2" :title="ticket.title">
                                        {{ ticket.title }}
                                    </h2>
                                    <a-spacer></a-spacer>
                                    <div class="u-flex-center-y" style="column-gap: 16px;">
                                        <div class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left u-cursor-pointer">
                                            <s-meta-ticket-dropdown :can-update="check_permission('update')" :meta-item="ticket.priority" :meta-list="priority_list" text-length="6" :loading="loading_ticket_item(ticket.id, 'priority_id', ticket.priority_id)" @updateMeta="local_update_meta(ticket.id, 'priority', ...arguments)"></s-meta-ticket-dropdown>
                                        </div>
                                        <div class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left u-cursor-pointer">
                                            <s-meta-ticket-dropdown :can-update="false" :meta-item="ticket.status" :meta-list="status_list" :loading="loading_ticket_item(ticket.id, 'status_id', ticket.status_id)" @updateMeta="local_update_meta(ticket.id, 'status', ...arguments)"></s-meta-ticket-dropdown>
                                        </div>
                                        <div class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-right u-cursor-pointer" @click="local_show_ticket(ticket.id)">
                                            <s-user-list-popup
                                                :item="ticket"
                                                :can_update="false"
                                                :count="local_screen_lg ? 1 : 1"
                                                v-if="ticket.assignees && ticket.assignees.length"
                                            ></s-user-list-popup>
                                            <a-avatar v-else size="39" color="transparent" class="ma-0 pa-0">
                                                <a-icon size="40" color="grey lighten-1">account_circle</a-icon>
                                            </a-avatar>
                                        </div>
                                    </div>
                                </div>
                                <div class="u-flex align-center u-wfull mt-1">
                                    <s-meta-ticket-dropdown
                                        :can-update="check_permission('update')"
                                        :meta-item="ticket.type"
                                        :meta-list="type_list"
                                        :loading="loading_ticket_item(ticket.id, 'type_id', ticket.type_id)"
                                        @updateMeta="local_update_meta(ticket.id, 'type', ...arguments)"
                                        max-width="150"
                                        inline
                                    ></s-meta-ticket-dropdown>
                                    <a-icon size="5" color="grey lighten-2" class="ml-3">fiber_manual_record</a-icon>
                                    <p class="md-body-2 grey--text text--darken-1 u-flex align-center mb-0 ml-2" @click="local_show_ticket(ticket.id)">
                                        <span class="mr-1">
                                            Reported by
                                            <a-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on" class="font-weight-medium">
                                                        {{ ticket.created_by ? ticket.created_by.name: '' }}
                                                    </span>
                                                </template>
                                                <div class="u-flex align-center">
                                                    <g-avatar :item="ticket.created_by" no-border :size="28" class="mr-2" :right-space="false"></g-avatar>
                                                    <g-profile-info dark :item="ticket.created_by"></g-profile-info>
                                                </div>
                                            </a-tooltip>
                                            on
                                        </span>
                                        <a-menu bottom open-on-hover offset-y>
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on">
                                                    <g-moment :value="ticket.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD" convert-local></g-moment>
                                                </div>
                                            </template>
                                            <a-sheet class="md-body-2 u-elevation-custom-1 u-rounded-corners primary darken-1 grey--text text--lighten-1 pa-2 px-3">
                                                <g-moment :value="ticket.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mm A" convert-local></g-moment>
                                            </a-sheet>
                                        </a-menu>
                                        <a-icon size="5" color="grey lighten-2" class="ml-3">fiber_manual_record</a-icon>
                                        <span class="md-body-2 grey--text text--lighten-1 ml-3" v-if="ticket.code">{{ ticket.code }}</span>
                                        <a-icon size="5" color="grey lighten-2" class="ml-3">fiber_manual_record</a-icon>
                                        <g-attachment-count-info
                                            :count="ticket.attachments_count"
                                            class="ml-3"
                                            fontSize="14"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </draggable>
                </a-flex>
            </a-layout>

            <!-- Load More Button -->
            <a-layout wrap align-start v-if="!page_loading">
                <a-flex xs12 class="u-flex align-center">
                    <a-btn v-if="ticket_meta && (ticket_meta.current_page < ticket_meta.last_page)" :loading="loading" :disabled="loading" @click="local_paginate(FILTERS.PAGE, ticket_meta.current_page + 1, 'pagination')" small text class="primary" dark>
                        Load More
                    </a-btn>
                    <a-spacer></a-spacer>
                    <span class="md-body-2 grey--text text--darken-1" v-if="ticket_meta.total">Showing <strong>{{ ticket_list.length }}</strong> out of <strong>{{ ticket_meta.total }}</strong> tickets</span>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Ticket Add/Edit form -->
        <a-dialog v-model="dialog_ticket_form" :max-width="700" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="45">
                                        <a-icon dark>account_tree</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">Create Ticket</h2>
                                    <p class="md-subtitle-2 mb-0 grey--text text--darken-1">You can create new ticket</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout align-center flex-wrap>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title</label>
                                    <a-text-field
                                        v-model="ticket_item.title"
                                        background-color="neutral"
                                        placeholder="Enter Title"
                                        class="u-border"
                                        clear-icon="backspace"
                                        solo flat hide-details clearable autofocus
                                    ></a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.title">
                                        <a-icon size="16" class="u-icon-nudge-xs" color="red darken-2">warning</a-icon>
                                        {{ ticket_response.server.errors.title[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                        Enter title of the ticket.
                                    </span>
                                </a-flex>
                                <a-flex xs6>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Type</label>
                                    <a-select
                                        v-model="ticket_item.type_id"
                                        :items="type_list"
                                        item-text="value"
                                        item-value="id"
                                        placeholder="Select Type"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                        <template v-slot:selection="{ item }">
                                            <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ local_truncate_text(item.value, 26) }}</span>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ local_truncate_text(item.value, 35) }}</span>
                                        </template>
                                    </a-select>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.type_id">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ ticket_response.server.errors.type_id[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                        Select type of the ticket
                                    </span>
                                </a-flex>
                                <a-flex xs6>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Priority</label>
                                    <a-select
                                        v-model="ticket_item.priority_id"
                                        :items="priority_list"
                                        item-text="value"
                                        item-value="id"
                                        placeholder="Select Priority"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                        <template v-slot:selection="{ item }">
                                            <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ local_truncate_text(item.value, 26) }}</span>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ local_truncate_text(item.value, 35) }}</span>
                                        </template>
                                    </a-select>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.priority_id">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ ticket_response.server.errors.priority_id[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                        Select priority of the ticket
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Description (Optional)</label>
                                    <div class="neutral pa-3 u-rounded-corners c-scrollbar" style="height: 150px; max-height: 150px; overflow-y: auto" @click="() => { ticket_item.description = '<p> </p>'; editor_ticket_description.focus() }">
                                        <p class="grey--text text--darken-1 u-hfull" v-if="editor_ticket_description  === null" @click="() => { ticket_item.description = '<p> </p>'; editor_ticket_description.focus() }">Click to edit the description</p>
                                        <editor-content v-else class="u-hfull" :editor="editor_ticket_description"></editor-content>
                                        <editor-menu-bubble :editor="editor_ticket_description" :keep-in-bounds="false" v-slot="{ commands, isActive, menu }">
                                            <div
                                                class="menububble"
                                                :class="{ 'is-active': menu.isActive }"
                                                :style="`left: ${menu.left - 16}px; bottom: ${menu.bottom + 8}px;`"
                                            >
                                                <a-btn icon text small class="mx-0" :color="(isActive.bold()) ? 'blue' : 'grey'" @click="commands.bold">
                                                    <a-icon size="16">format_bold</a-icon>
                                                </a-btn>
                                                <a-btn icon text small class="mx-0" :color="(isActive.italic()) ? 'blue' : 'grey'" @click="commands.italic">
                                                    <a-icon size="16">format_italic</a-icon>
                                                </a-btn>
                                                <a-divider vertical dark></a-divider>
                                                <a-btn icon text small class="mx-0" :color="(isActive.bullet_list()) ? 'blue' : 'grey'" @click="commands.bullet_list">
                                                    <a-icon size="16">format_list_bulleted</a-icon>
                                                </a-btn>
                                                <a-btn icon text small class="mx-0" :color="(isActive.ordered_list()) ? 'blue' : 'grey'" @click="commands.ordered_list">
                                                    <a-icon size="16">format_list_numbered</a-icon>
                                                </a-btn>
                                            </div>
                                        </editor-menu-bubble>
                                    </div>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.description">
                                        <a-icon size="16" class="u-icon-nudge-xs" color="red darken-2">warning</a-icon>
                                        {{ ticket_response.server.errors.description[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                        You can summarize and describe the ticket.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <a-btn color="primary" :loading="loading" :disabled="loading" class="ma-0 elevation-0 px-3" @click="local_ticket_store">Create Ticket</a-btn>
                                    <a-btn color="grey" class="ml-2" text :disabled="loading" @click="local_close_create_form">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!-- Snackbar -->
        <a-snackbar v-model="snackbar_status" auto-heigh :color="snackbar_text.type" :timeout="3000" bottom center>
            <div class="text-center">
                {{snackbar_text.content}}
            </div>
        </a-snackbar>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
    Bold,
    Italic,
    Strike,
    OrderedList,
    BulletList,
    ListItem,
    History,
} from 'tiptap-extensions'
import marked from 'marked'
import { SUserListPopup, SMetaTicketDropdown } from '@/config/config-shared-components'

export default {
    components: {
        EditorContent, EditorMenuBubble, SUserListPopup, SMetaTicketDropdown,
    },

    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            editor_ticket_description: null,
            ticket_description: '',
            status_list: [],
            priority_list: [],
            type_list: [],
            page_loading: true,
            loading_ticket: {},
            dialog_ticket_form: false,
            ticket_loader_type: null,
            ticket_filter: {},
            ticket_count: 100,
            snackbar_status: false,
            snackbar_text: { type: '', content: '' },
            FILTERS: {
                PAGE: 'page',
                COUNT: 'count'
            }
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        local_screen_lg() {
            return this.$vuetify.breakpoint.lgAndDown
        },

        table_style () {
            return {
                title: {},
                drag: { width: (this.$vuetify.breakpoint.lgAndDown ?  30 : 30) + 'px' },
                assignee: { width: (this.$vuetify.breakpoint.lgAndDown ?  50 : 50) + 'px' },
                type: { width: (this.$vuetify.breakpoint.lgAndDown ?  120 : 120) + 'px' },
                priority: { width: (this.$vuetify.breakpoint.lgAndDown ?  110 : 120) + 'px' },
                status: { width: (this.$vuetify.breakpoint.lgAndDown ?  140 : 140) + 'px' },
                action: { width: (this.$vuetify.breakpoint.lgAndDown ?  80 : 80) + 'px' },
            }
        },

        ticket_list: {
            get() {
                return this.$store.state.TicketExternal.list
            },
            set(list) {
                this.ticket_reorder({ data: list })
            }
        },

        local_ticket_fields () {
            return {
                'include': 'status,priority,type,createdBy,assignees.role,attachmentsCount',
                'filter[is_global]': 0,
                'filter[parent_id]': this.$route.params.id,
                'sort': 'order'
            }
        },

        local_project_id () {
            return this.$route.params.id
        },

        ...mapState('TicketExternal', {
            ticket_search_list: 'search_list',
            ticket_item: 'item',
            ticket_meta: 'meta',
            ticket_form_mode: 'form_mode',
            ticket_response: 'response',
        }),

        ...mapState('MetaExternal', {
            meta_list: 'list',
        }),

        ...mapState('ProjectExternal', {
            project_item: 'item',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_index() {
            if (this.$can('poc_tickets.index') === false) {
                return this.$router.replace({ name: 'errors-unauthorized' })
            }
            this.ticket_filter[this.FILTERS.COUNT] = this.ticket_count
            this.local_ticket_index()
            await this.local_load_status()
            await this.local_load_priority()
            await this.local_load_type()
            this.page_loading = false
        },

        async local_load_status () {
            await this.meta_index({
                'filter[type]': 'ticket_status',
                'sort': 'order'
            })
            this.status_list = this.meta_list
        },

        async local_load_priority () {
            await this.meta_index({
                'filter[type]': 'ticket_priority',
                'sort': 'order'
            })
            this.priority_list = this.meta_list
        },

        async local_load_type () {
            await this.meta_index({
                'filter[type]': 'ticket_type',
                'sort': 'order'
            })
            this.type_list = this.meta_list
        },

        // Create ticket
        async local_ticket_create () {
            await this.ticket_clear_item()
            this.local_set_description()
            this.local_set_picklist()
            setTimeout(() => this.dialog_ticket_form = true, 0)
            this.snackbar_status = false
        },

        local_set_picklist () {
            this.ticket_item.status_id = this.local_get_starred_meta_id(this.status_list)
            this.ticket_item.priority_id = this.local_get_starred_meta_id(this.priority_list)
            this.ticket_item.type_id = this.local_get_starred_meta_id(this.type_list)
        },

        local_get_starred_meta_id (list) {
            if (list.length === 0) return null
            const list_item = list.find(item => item.is_starred)
            return list_item ? list_item.id : list[0].id
        },

        async local_ticket_store () {
            this.ticket_loader_type = 'add'
            this.ticket_item.is_global = 0
            this.ticket_item.description = this.ticket_description

            const params = { parent_id: this.local_project_id, parent_type: 'Project' }
            await this.ticket_store({ ...this.ticket_item, ...params, mode: 'ticket_create' })
            if (this.ticket_response.status !== 'success') return this.ticket_loader_type = null

            setTimeout(async () => {
                await this.local_show_ticket(this.ticket_item.id)
                this.ticket_meta.total = this.ticket_meta.total + 1
                this.ticket_loader_type = null
                this.dialog_ticket_form = false
            }, 400)
        },

        async local_close_create_form () {
            await this.ticket_clear_item()
            this.dialog_ticket_form = false
            if (this.editor_ticket_description) this.editor_ticket_description.destroy()
        },

        local_set_description () {
            if (this.editor_ticket_description) this.editor_ticket_description.destroy()
            const content = this.ticket_item.description ? this.ticket_item.description : ' '
            this.editor_ticket_description = new Editor({
                extensions: [
                    new Bold(),
                    new Italic(),
                    new Strike(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new History(),
                ],
                content: marked(content, { sanitize: true }),
                onUpdate: state => this.ticket_description = turndownService.turndown(state.getHTML()),
            })
        },

        async local_paginate (filter, value, type = null) {
            this.ticket_filter[filter] = value
            await this.local_ticket_index({ type })
        },

        async local_ticket_index (params = {}) {
            await this.ticket_index({
                ...this.local_ticket_fields,
                ...this.ticket_filter,
                ...params
            })
        },

        // Metas Update
        async local_update_meta (id, field, { meta_id }) {
            this.snackbar_status = false
            await this.ticket_select({ id })
            if (this.ticket_item[field + '_id'] === meta_id) return

            this.local_set_ticket_loading(id, field)
            this.ticket_item[field + '_id'] = meta_id
            await this.ticket_update({
                id: id,
                item: this.ticket_item,
                mode: 'update',
                params: {
                    ...this.local_ticket_fields,
                    workspace_id: this.current_workspace_id_mixin
                }
            })
            if (this.ticket_response.status === 'success') {
                this.local_notify_response('Ticket updated successfully', 'success')
                this.loading_ticket = {}
            }
        },

        // Extras
        local_set_ticket_loading (id, field) {
            this.loading_ticket.id = id
            this.loading_ticket[field + '_id'] = this.ticket_item[field + '_id']
        },

        local_show_ticket (id) {
            this.$router.push({ name: 'projects-tickets-view', params: { id: this.project_item.id, ticket_id: id } })
        },

        local_format_date (date) {
            return date !== null ? moment(date).format('MMM D, YYYY') : null
        },

        local_truncate_text(text, range) {
            if (!text) return
            return text.length > range ? text.slice(0, range) + '...' : text
        },

        loading_ticket_item (id, field = null, meta_id = null) {
            if (!meta_id) return this.loading_ticket.id === id
            return this.loading_ticket.id === id && this.loading_ticket[field] === meta_id
        },

        local_notify_response (content, type) {
            this.snackbar_text.content = content
            this.snackbar_text.type = type ?? 'success'
            this.snackbar_status = true
        },

        check_permission (type) {
            return this.$can(`poc_tickets.${type}`) && this.$can('projects.update-only')
        },

        ...mapActions('MetaExternal', {
            meta_index: 'index',
        }),

        ...mapActions('TicketExternal', {
            ticket_index: 'index',
            ticket_store: 'store',
            ticket_update: 'update',
            ticket_select: 'select',
            ticket_show: 'show',
            ticket_reorder: 'reorder',
            ticket_clear_item: 'clear_item',
        }),
    },
}
</script>

<style lang="scss" scoped>
.c-table {
    display: table !important;
    &__view-action { color: rgb(175, 175, 175) !important; }
    &__content-row {
        &:hover {
            .c-table__view-action { color: #333 !important; }
        }
    }
    // .c-table__view-action-button--edit, .c-table__view-action-button--delete {
    //     transition: 0.1s ease-in;
    // }
    .c-table__view-action-button--edit, .c-table__view-action-button--delete {
        &:hover {
            // margin-bottom: 2px;
            // box-shadow: 0px 1px 4px rgba($color: #000000, $alpha: 0.2);
            transition: 0.1s ease-in;
            // .a-icon { color: rgb(235, 137, 10) !important }
        }
    }
    .c-table__view-action-button--delete {
        &:hover {
            .a-icon { color: rgb(245, 43, 43) !important }
        }
    }
    &__cell {
            display: table-cell !important;
            vertical-align: middle !important;
            white-space: nowrap !important;
            background: #fff !important;
            &:first-child {
                border-radius: 4px 0px 0px 4px;
            }
            &:last-child {
                border-radius: 0px 4px 4px 0px;
            }
        }
}
</style>

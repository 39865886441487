<template>
	<a-main>
        <c-dashboard-external v-if="user_self && user_self.scope === 'external'"></c-dashboard-external>
        <router-view v-else></router-view>
	</a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    components: {
        'c-dashboard-timeline': () => import('./DashboardTimeline'),
        'c-dashboard-columns': () => import('./DashboardColumns'),
        'c-dashboard-external': () => import('@/pages/External/ExternalDashboard/ExternalDashboard.vue'),
    },
    data() {
        return {
            dashboard_view: 'columns',
        }
    },
    computed: {
        local_insights_routes () {
            return ['dashboard-insights', 'dashboard-insights-type', 'dashboard-insights-tile-view']
        },

        ...mapState('User', {
            user_self: 'self',
        }),
    },
    mounted() {
        this.local_index()
    },
    methods: {
        async local_index() {
            const { name } = this.$route
            const dashboardTypes = ['dashboard-columns', 'dashboard-timeline', 'dashboard-insights', 'dashboard-insights-type', 'dashboard-insights-tile-view']
            if (this.user_self && this.user_self.scope === 'internal' && !dashboardTypes.includes(name)) {
                this.$router.push({ name: 'dashboard-columns' })
            }

            if (this.user_self && this.user_self.scope === 'internal') {
                await this.user_show({
                    id: this.user_self.id,
                    mode: 'self',
                    params: { 'include': 'territories', 'fields[users]': 'id', 'fields[territories]': 'territories.id,territories.name,territories.color,territories.order' }
                })
            }
        },
        ...mapActions('User', {
            user_show: 'show',
        }),
    },
}
</script>

<style scoped>
    .u-fab-nudge {
        bottom: 96px !important;
        right: 20px;
    }
</style>

const api_endpoint = '/preferences'

export default {
    namespaced: true,
    state: {
        list: [],
        org_list: [],
        item: {},
        page_preference_item: {},
        meta: {},
        user_preference: [],
        user_types: [],
        filters: {
            page: 1,
            count: 10,
            sort_field: 'order',
            sort_direction: 'desc'
        },
        response: [],
        defaults: {
            list: [],
            item: {},
            meta: {},
            user_types: [],
            user_preference: [],
            filters: {
                page: 1,
                count: 10,
                sort_field: 'order',
                sort_direction: 'desc'
            },
            response: [],
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ORG_LIST(state, items) {
            state.org_list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_PAGE_PREFERENCE(state, item) {
            if (item && !item.length) return
            state.page_preference_item = item[0];
        },
        UPDATE_USER_PREFERENCE(state, items) {
            state.user_preference = items
        },
        UPDATE_USER_TYPES(state, items) {
            state.user_types = items
        },
        UPSERT_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id })
            if (index !== -1) Object.assign(state.list[index], payload)
            else state.list.push(payload)
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            if (index !== -1) state.list[index] = payload.item;
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        ADD_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        REFRESH_ITEM(state) {
            let item_temp = _.cloneDeep(state.item);
            state.item = [];
            state.item = item_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        index(context, payload = {}) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint, { params: { 'filter[all]': 1, ...payload } })
                .then(async (response) => {
                    if (payload && payload.mode === 'page_preference') await context.commit('UPDATE_PAGE_PREFERENCE', response.data.data)
                    else await context.commit('UPDATE_LIST', response.data.data)
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        organization (context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint, { params: { 'filter[is_listable]': 1 } })
                .then(async (response) => {
                    await context.commit('UPDATE_ORG_LIST', response.data.data)
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        user_types(context, payload) {
            this.dispatch('Interface/loader_start')
            const params = payload && payload.params ? payload.params : { 'filter[type]': 'User' }
            return axios.get(api_endpoint, { params: { ...params } })
                .then(async (response) => {
                    await context.commit('UPDATE_USER_TYPES', response.data.data)
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        user_preference(context, payload) {
            this.dispatch('Interface/loader_start')
            const params = payload && payload.params ? payload.params : { 'filter[type]': 'User' }
            return axios.get(api_endpoint, { params: { ...params } })
                .then(async (response) => {
                    await context.commit('UPDATE_USER_PREFERENCE', response.data.data)
                    // if (query_filters.page == 1) await context.commit('UPDATE_LIST', response.data.data)
                    // else await context.commit('APPEND_LIST', response.data.data)
                    if (context.getters.pref_workspace) {
                        context.dispatch('Workspace/switch_workspace', { id: context.getters.pref_workspace.value }, { root: true })
                    }
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.put(api_endpoint, payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.data });
                });
        },
        upsert_store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.put(api_endpoint, payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.data });
                });
        },
        switch_workspace(context, payload) {
            this.dispatch('Interface/loader_start')
            payload.id = context.getters.pref_workspace?.id || {}
            return axios.put(api_endpoint, payload)
                .then(async (response) => {
                    await context.dispatch('index')
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.data });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.put(api_endpoint, payload)
                .then(async (response) => {
                    await context.dispatch('index', { 'filter[all]': 1 })
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.data });
                });
        },
        clear_user_types (context) {
            context.commit('UPDATE_USER_TYPES', [])
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        clear_user_types (context) {
            context.commit('UPDATE_USER_TYPES', [])
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },

    getters: {
        pref_workspace: (state) => {
            return state.list.find(item => item.key && item.key === 'user.workspace')
        },
        get_property_by_key: (state) => (key, property) => {
            return _.get(_.find(state.list, {key: key}), property)
        }
    }
}

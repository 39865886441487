<template>
    <div>
        <!-- No reasons found -->
        <div>
            <div class="u-flex-center-y mb-3">
                <span class="md-md-body-2 grey--text text--darken-2">Result Reason</span>
                <a-spacer></a-spacer>
                <span v-if="$can('metas.update')" class="md-md-body-2 u-flex-center-y u-cursor-pointer" @click="$router.push({ name: 'settings-metas-result-reasons', params: { id: resultItem.id } })">
                    <a-icon size="20" color="blue darken-2">add</a-icon>
                    <span class="blue--text text--darken-2 ml-1">Create</span>
                </span>
            </div>

            <v-slide-y-transition leave-absolute hide-on-leave>
                <div v-if="reasonLoading" class="u-border u-rounded-corners-lg u-flex-center-y" style="min-height: 78px">
                    <div class="grey lighten-5 u-border pa-4">
                        <div v-for="(count, index) in 2" class="u-flex-center-y" :class="{ 'mt-2': index !== 0 }" :key="count">
                            <loader-template :width="Math.floor(Math.random() * 300) + 200" height="12" class="u-rounded-corners-full"></loader-template>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <v-slide-y-transition leave-absolute hide-on-leave>
                        <!-- No Reasons Text -->
                        <div v-if="!localReasonsLength">
                            <div class="u-border u-rounded-corners-lg">
                                <a-sheet color="grey lighten-5" min-height="76" class="u-rounded-corners-lg u-flex-center px-3 py-2">
                                    <a-icon size="20" color="grey darken-4">info</a-icon>
                                    <p class="mb-0 md-body-2 grey--text text--darken-2 ml-2">There are no reasons available for <strong>{{ resultItem.value | truncateText(20) }}.</strong></p>
                                </a-sheet>
                            </div>
                            <span 
                                v-if="automation_validation_status && !automation_validation_status.result_reason_status" 
                                class="md-caption grey--text u-absolute" 
                                style="right: 0px; bottom: -24px;"
                            >
                                (Optional)
                            </span>
                            <SharedValidationFormError
                                identifier="result_reason"
                            />
                        </div>
                       
                        <!-- List -->
                        <div v-else class="u-relative">
                            <a-sheet class="u-rounded-corners-lg u-border u-overflow-y c-tiny-scroll" max-height="300">
                                <div class="grey lighten-5" v-for="(reason, index) in meta_result_reason_list" :key="reason.id">
                                    <a-divider v-if="index !== 0" :key="reason.id + '-divider'"></a-divider>
                                    <div class="u-flex align-start pa-4 py-3 u-cursor-pointer" @click="$emit('updateReason', reason.id)" :key="reason.id">
                                        <a-checkbox
                                            color="blue darken-2"
                                            :disabled="canUpdate"
                                            :input-value="local_is_project_result_reason_added(reason.id)"
                                            @click.stop="$emit('updateReason', reason.id)"
                                            class="ma-0 pa-0"
                                            hide-details dense
                                        ></a-checkbox>
                                        <p class="mb-0 md-body-2 grey--text text--darken-2 ml-2">{{ reason.title }}</p>
                                    </div>
                                </div>
                            </a-sheet>
                            <span 
                                v-if="automation_validation_status && !automation_validation_status.result_reason_status" 
                                class="md-caption grey--text u-absolute" 
                                style="right: 0px; bottom: -24px;"
                            >
                                (Optional)
                            </span>
                            <SharedValidationFormError
                                identifier="result_reason"
                            />
                        </div>
                    </v-slide-y-transition>
                </div>
            </v-slide-y-transition>
        </div>

        <!-- Result comment -->
        <div class="mt-5 mb-4 u-relative">
            <span class="md-md-body-2 grey--text text--darken-1 d-block mb-3">Comment</span>
            <a-sheet :class="[!commentError ? 'u-border' : 'c-wiggle-short u-border-error']" class="u-rounded-corners-lg" height="104">
                <a-tooltip bottom :disabled="!commentError">
                    <template v-slot:activator="{ on }">
                        <textarea
                            v-on="on"
                            v-model="resultComment"
                            placeholder="Post your comment"
                            @input="localAddComment"
                            style="resize: none; outline: none"
                            class="grey lighten-5 u-wfull u-hfull md-body-2 px-4 py-2 u-rounded-corners-lg c-tiny-scroll"
                            solo flat hide-details multiple chips small-chips no-resize
                        ></textarea>
                    </template>
                    <span>{{ commentError }}</span>
                </a-tooltip>
            </a-sheet>
            <span 
                v-if="automation_validation_status && !automation_validation_status.result_comment_status" 
                class="md-caption grey--text u-absolute" style="right: 0px; bottom: -24px;"
            >
                (Optional)
            </span>
            <SharedValidationFormError
                identifier="comment"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import SharedValidationFormError from './SharedValidationFormError.vue'
import { Validate } from '@/helpers/helper-validations'

export default {
    components: {
        SharedValidationFormError
    },
    props: {
        moveParams: {
            type: Object
        },
        projectReasonList: {
            type: Array
        },
        reasonLoading: {
            type: Boolean
        },
        resultItem: {
            type: Object
        },
        response: {
            type: Object
        },
        bgGrey: {
            type: Boolean,
            default: false
        },
        isModalOpen: {
            type: Boolean,
            default: false
        },
        projectComment: {
            type: String,
            default: null
        },
        commentClass: {
            type: String,
            default: 'u-elevation-custom-1'
        },
        canUpdate: {
            type: Boolean,
            default: false
        },
    },

    data () {
        return {
            commentError: '',
            resultComment: ''
        }
    },

    watch: {
        projectComment: {
            handler (val) {
                if (_.trim(val)) this.resultComment = val
            },
            immediate: true,
        },

        isModalOpen: {
            handler (newVal, oldVal) {
                if (!oldVal && !_.trim(this.projectComment)) this.resultComment = ''
            },
            immediate: true
        },
    },

    computed: {
        localReasonsLength () {
            return (this.meta_result_reason_list && this.meta_result_reason_list.length) || 0
        },

        ...mapState('MetaResultReason', {
            meta_result_reason_list: 'list',
        }),

        ...mapState('AutomationValidation', {
            automation_validation_status: 'status',
        }),
    },

    methods: {
        local_is_project_result_reason_added (id) {
            if (!this.projectReasonList.length) return false

            const index = this.projectReasonList.findIndex(item => item === id)
            return index !== -1
        },

        localAddComment () {
            const { message } = new Validate(this.resultComment, { silent: true }).length(0, 50000).run()
            if (message) {
                this.$emit('error', true)
                return this.commentError = message
            }
            this.commentError = ''
            this.$emit('error', false)
            this.$emit('updateComment', this.resultComment)
        },
    }
}
</script>

<template>
    <a-main>
        <div class="my-6 u-flex justify-space-between">
            <div>
                <h2 class="mb-1 md-subtitle-1 font-weight-bold grey--text text--darken-3">Usage</h2>
                <h4 class="md-body-2 grey--text text--darken-1 font-weight-medium">POC usage statistics <strong>(Active POCs vs Days in billing cycle)</strong></h4>
            </div>
            <a-sheet class="u-flex u-rounded-corners u-shadow" height="44" style="align-items: stretch">
                <v-slide-x-reverse-transition leave-absolute>
                    <div
                        v-if="!local_loading"
                        v-ripple="currentRangeIndex !== 0"
                        class="px-3 u-flex-center-y"
                        :class="[currentRangeIndex === 0 ? 'grey lighten-3': 'u-cursor-pointer']"
                        style="border-right: 1px solid rgba(0,0,0,0.06); border-radius: 4px 0px 0px 4px;"
                        @click="currentRangeIndex !== 0 ? localPreviousCycle() : {}"
                    >
                        <a-icon size="18" color="grey darken-1" :class="[{ 'u-opacity-40': currentRangeIndex === 0 }]">keyboard_arrow_left</a-icon>
                    </div>
                </v-slide-x-reverse-transition>
                <a-menu offset-y bottom content-class="white" transition="slide-y-transition">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" style="flex: 1; min-width: 180px" v-ripple class="md-subtitle-2 u-cursor-pointer u-flex-center grey--text text--darken-2">
                            <loader-template width="100%" height="100%" style="border-radius: 0px !important" v-if="local_loading" />
                            <span class="px-3 d-inline-block" v-else>
                                {{ localDateFormatter(currentRangeStartDate) }} - {{ localDateFormatter(currentRangeEndDate, 'MMM D[,] YYYY') }}
                            </span>
                        </div>
                    </template>
                    <a-list class="u-list-std c-tiny-scroll" ref="refCycleList" style="max-height: 200px">
                        <a-list-item v-for="cycle in subscription_plan_cycles" tabindex="0" :ref="`refCycle-${cycle.start_date}`" :class="[{ 'grey lighten-4': cycle.start_date === currentRangeStartDate }]" @click="localFetchStatsFromRange(cycle)" :key="cycle.start_date">
                            <div class="u-flex-center u-wfull">
                                <span class="md-body-2 grey--text text--darken-2">{{ localDateFormatter(cycle.start_date) }} - {{ localDateFormatter(cycle.end_date, 'MMM D[,] YYYY') }}</span>
                            </div>
                        </a-list-item>
                    </a-list>
                </a-menu>
                <v-slide-x-transition leave-absolute>
                    <div
                        v-if="!local_loading"
                        v-ripple="currentRangeIndex !== localGetCyclesLength - 1"
                        class="px-3 u-flex-center-y"
                        :class="[currentRangeIndex === localGetCyclesLength - 1 ? 'grey lighten-3': 'u-cursor-pointer']"
                        style="border-left: 1px solid rgba(0,0,0,0.06); border-radius: 0px 4px 4px 0px;"
                        @click="currentRangeIndex !== localGetCyclesLength - 1 ? localNextCycle() : {}"
                    >
                        <a-icon size="18" color="grey darken-1" :class="[{ 'u-opacity-40': currentRangeIndex === localGetCyclesLength - 1 }]">keyboard_arrow_right</a-icon>
                    </div>
                </v-slide-x-transition>
            </a-sheet>
        </div>

        <div v-if="local_loading" class="py-6 text-center white u-rounded-corners-lg u-shadow">
            <img src="../../assets/images/graph-loader.svg" style="height: 300px; object-fit: contain; display: inline-block" alt="Loading...">
            <div class="justify-center mt-4 d-inline-flex align-center u-wfull">
                <h2 class="mr-2 text-center md-subtitle-1 grey--text text--darken-2 font-weight-medium">Fetching usage data...</h2>
            </div>
        </div>

        <div class="u-relative u-wfull" style="min-height: 500px;" v-else>
            <!-- Timeline Loader -->
            <v-fade-transition leave-absolute>
                <div v-if="chartLoading" class="u-wfull u-hfull u-absolute u-rounded-corners-lg u-shadow" style="right: 0px; top: 0px; background-color: rgba(255,255,255,0.3); backdrop-filter: blur(12px); z-index: 2;">
                    <div class="c-firefox-blur u-wfull u-hfull u-absolute" style="right: 0px; top: 0px;"></div>
                    <div class="text-center u-wfull u-hfull u-absolute transparent">
                        <a-img :src="require('../../assets/images/loader.svg')" alt="Loading Chart..." width="48" height="48" contain class="d-inline-block" style="margin-top: 200px;"></a-img>
                    </div>
                </div>
            </v-fade-transition>

            <a-sheet class="py-8 text-center u-rounded-corners-lg u-shadow" v-if="emptyProjects || (subscription_plan_day_stats && !subscription_plan_day_stats.length)">
                <a-icon color="grey lighten-1" size="48">info</a-icon>
                <h2 class="mt-4 md-subtitle-1">No data found for the cycle <br> <strong>{{ localDateFormatter(currentRangeStartDate) }} - {{ localDateFormatter(currentRangeEndDate, 'MMM D[,] YYYY') }}</strong></h2>
            </a-sheet>

            <a-sheet v-else :width="chartWidth" class="pt-8 pl-12 c-billing-graph u-relative u-rounded-corners-lg u-shadow pa-8" :class="[{ 'loading-cursor': projectLoading }, availablePlanLevels.length > 1 ? 'pb-8' : 'pb-16']">
                <span class="u-absolute md-body-2 font-weight-medium grey--text text--darken-4" :style="{ bottom: availablePlanLevels.length > 1 ? '75px' : '30px' }" style="left: 50%; transform: translateX(-50%)">Days of Usage</span>
                <span class="u-absolute md-body-2 font-weight-medium grey--text text--darken-4" style="left: -22px; top: 50%; transform: translateY(-50%) rotate(-90deg);">Active Projects</span>
                <VueApexCharts
                    ref="barVerticalChart"
                    :options="chart_options"
                    :series="series"
                    :width="chartWidth - 64"
                    height="500"
                ></VueApexCharts>

                <!-- Legends -->
                <div class="u-flex-center pt-14" style="column-gap: 24px;" v-if="availablePlanLevels.length > 1">
                    <div class="u-flex-center-y" style="column-gap: 8px;" v-if="localHasPlanLevel(1)">
                        <a-sheet :color="planColors[1]" class="u-rounded-corners" width="16" height="16"></a-sheet>
                        <span class="md-body-2 grey--text text--darken-1 font-weight-medium">Starter</span>
                    </div>
                    <div class="u-flex-center-y" style="column-gap: 8px;" v-if="localHasPlanLevel(2)">
                        <a-sheet :color="planColors[2]" class="u-rounded-corners" width="16" height="16"></a-sheet>
                        <span class="md-body-2 grey--text text--darken-1 font-weight-medium">Professional</span>
                    </div>
                    <div class="u-flex-center-y" style="column-gap: 8px;" v-if="localHasPlanLevel(3)">
                        <a-sheet :color="planColors[3]" class="u-rounded-corners" width="16" height="16"></a-sheet>
                        <span class="md-body-2 grey--text text--darken-1 font-weight-medium">Enterprise</span>
                    </div>
                </div>

                <v-scroll-y-transition group leave-absolute>
                    <template v-if="openProjectStats">
                        <div key="overlay" @click="openProjectStats = false" class="u-absolute u-wfull u-hfull u-rounded-corners-lg" style="left: 0px; top: 0px; background: rgba(0,0,0,0.2)"></div>
                        <a-sheet
                            key="dialog"
                            width="640"
                            class="u-absolute u-rounded-corners-lg u-shadow"
                            style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
                        >
                            <div class="py-5 pa-6 u-flex-center-y" v-if="subscription_plan_report_item && subscription_plan_report_item.id">
                                <h3 class="md-heading-6 font-weight-medium grey--text text--darken-3">Active Projects of <strong>{{ localDateFormatter(subscription_plan_report_item.start_date) }} - {{ subscription_plan_report_item.data && subscription_plan_report_item.data.length }} Projects</strong></h3>
                                <a-spacer></a-spacer>
                                <a-btn icon small class="ma-0 pa-0" @click="openProjectStats = false">
                                    <a-icon size="20" color="grey lighten-1">cancel</a-icon>
                                </a-btn>
                            </div>
                            <a-divider></a-divider>
                            <div class="u-list-std" v-if="subscription_plan_report_item && subscription_plan_report_item.data">
                                <a-list class="py-0 c-tiny-scroll u-overflow-y u-rounded-corners-lg" style="max-height: 270px;">
                                    <a-list-item v-for="project in subscription_plan_report_item.data" :key="project.id" class="px-6 py-3 u-flex-center-y">
                                        <h3 class="md-subtitle-2 grey--text text--darken-1 text-truncate" style="max-width: 400px;">
                                            {{ project.title }}
                                        </h3>
                                        <a-spacer></a-spacer>
                                        <span class="px-2 py-1 md-caption font-weight-medium secondary--text text-uppercase indigo lighten-5 u-rounded-corners d-inline-block text-truncate" style="max-width: 150px">
                                            {{ project.stage }}
                                        </span>
                                    </a-list-item>
                                </a-list>
                            </div>
                        </a-sheet>
                    </template>
                </v-scroll-y-transition>
            </a-sheet>
        </div>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'

export default {
    mixins: [mixinGetOrganizationPlan],

    components: { VueApexCharts },

    data () {
        return {
            local_loading: true,
            chartLoading: false,
            projectLoading: false,
            openProjectStats: false,
            emptyProjects: false,
            chartWidth: '100%',
            stripe_url: process.env.VUE_APP_CORE_API_URL + '/' + window.location.hostname.split('.')[0] + '/internal/stripe/configure',
            currentRangeStartDate: '',
            currentRangeEndDate: '',
            currentRangeIndex: 0,
            availablePlanLevels: [],
            planColors: { 1: '#1E88E5', 2: '#3949AB', 3: '#222222' },
            series: [],
            chart_options: {
                chart: {
                    type: 'bar',
                    toolbar: { show: false },
                    animations: { enabled: true },
                    events: { dataPointSelection: this.localCurrentPlanLevel === 3 ? '' : this.localGetProjectsForDay }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 8,
                        dataLabels: { position: 'top' },
                    },
                },
                dataLabels: { enabled: false },
                grid: {
                    borderColor: 'rgba(0,0,0,0.1)',
                    strokeDashArray: 4,
                    position: 'back',
                    padding: { top: -16, bottom: -8, left: 8, right: 0 }
                },
                legend: { show: false },
                xaxis: {
                    categories: [],
                    labels: {
                        style: { fontFamily: 'Inter, sans-serif', fontWeight: 700, colors: 'rgba(0,0,0,0.8)' },
                        formatter: function (val) {
                            return moment(val).format('D')
                        }
                    },
                    position: 'bottom',
                    axisBorder: { show: false },
                    axisTicks: { show: false },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                },
                yaxis: {
                    show: true,
                    axisTicks: { show: false },
                    labels: {
                        show: true,
                        style: { fontFamily: 'Inter, sans-serif' },
                        offsetX: -14,
                        formatter: (val) => val,
                    },
                },
                fill: {
                    opacity: 1,
                    colors: [ this.localGenerateFillColors ],
                },
                tooltip: {
                    theme: 'dark',
                    custom: this.localCustomTooltip
                }
            },
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localGetCyclesLength () {
            return _.size(this.subscription_plan_cycles)
        },

        localCurrentPlanLevel () {
            return this.subscription_plan_item &&
                this.subscription_plan_item.subscription_plan &&
                this.subscription_plan_item.subscription_plan.level
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('SubscriptionPlan', {
            subscription_plan_item: 'item',
            subscription_plan_cycles: 'cycles',
            subscription_plan_day_stats: 'dayStats',
            subscription_plan_report_item: 'reportItem'
        }),
    },

    methods: {
        async localIndex() {
            if (this.user_self && this.user_self.is_billing !== 1) {
                return this.$router.replace({name: 'errors-unauthorized'})
            }
            this.$vuetify.goTo(0)

            await this.actionFetchCycles()
            await this.localSetCurrentMonthRange()
            this.localSetChartCategories()
            await this.actionFetchUsageStats({ start_date: this.currentRangeStartDate, end_date: this.currentRangeEndDate })
            this.localGetAvailableLevelsForMonth()
            this.localSetChartSeries()

            this.local_loading = false
        },

        async localSetChartCategories () {
            const dateList = await this.localGetDatesBetween(this.currentRangeStartDate, this.currentRangeEndDate)
            this.chart_options.xaxis.categories = dateList
            setTimeout(() => {
                if (this.$refs.barVerticalChart) this.$refs.barVerticalChart.refresh()
            }, 300)
            this.chartWidth = this.$el.clientWidth
        },

        async localGetDatesBetween (start, end) {
            const startDate = moment(start)
            const endDate = moment(end)
            const now = startDate.clone()
            const dates = []

            while (now.isSameOrBefore(endDate)) {
                dates.push(now.format('YYYY-MM-DD'))
                now.add(1, 'days')
            }

            return dates
        },

        localSetChartSeries () {
            this.emptyProjects = false
            let arr = { name: 'Month', data: [] }
            this.chart_options.xaxis.categories.forEach(i => {
                const stat = this.subscription_plan_day_stats.find(d => {
                    return moment(moment(d.start_date).format('YYYY-MM-DD')).isSame(moment(i).format('YYYY-MM-DD'))
                })

                if (stat) arr.data.push(stat.active_projects)
                else arr.data.push(0)
            })

            const projectEmptyAllDay = arr.data.every(v => v === 0)
            if (projectEmptyAllDay) this.emptyProjects = true
            this.series = [arr]
        },

        async localSetCurrentMonthRange (startDate = null, endDate = null) {
            const index = this.subscription_plan_cycles.findIndex(i => !!moment(i.start_date).isSame(new Date(), 'month'))
            const { start_date, end_date } = this.subscription_plan_cycles[index] || this.subscription_plan_cycles[0] || {}

            this.currentRangeIndex = index
            this.currentRangeStartDate = startDate ?? start_date ?? moment().startOf('month').format('YYYY-MM-DD')
            this.currentRangeEndDate = endDate ?? end_date ?? moment().endOf('month').format('YYYY-MM-DD')
        },

        async localFetchStatsFromRange (cycle) {
            this.chartLoading = true

            await this.localSetCurrentMonthRange(cycle.start_date, cycle.end_date)
            this.localSetChartCategories()
            await this.actionFetchUsageStats({ start_date: this.currentRangeStartDate, end_date: this.currentRangeEndDate })
            this.actionFetchCycles()
            this.localGetAvailableLevelsForMonth()
            this.localSetChartSeries()

            this.chartLoading = false
        },

        localNextCycle () {
            if (this.currentRangeIndex === this.localGetCyclesLength - 1 || this.chartLoading) return

            const { start_date, end_date } = this.subscription_plan_cycles[this.currentRangeIndex + 1]
            this.currentRangeStartDate = start_date
            this.currentRangeEndDate = end_date
            this.currentRangeIndex = this.currentRangeIndex + 1
            this.localFetchStatsFromRange({ start_date, end_date })
        },

        localPreviousCycle () {
            if (this.currentRangeIndex === 0 || this.chartLoading) return

            const { start_date, end_date } = this.subscription_plan_cycles[this.currentRangeIndex - 1]
            this.currentRangeStartDate = start_date
            this.currentRangeEndDate = end_date
            this.currentRangeIndex = this.currentRangeIndex - 1
            this.localFetchStatsFromRange({ start_date, end_date })
        },

        localCustomTooltip ({ series, seriesIndex, dataPointIndex, w }) {
            const date = w.config.xaxis.categories[dataPointIndex]
            const { item, day } = this.localGetStatsFromIndex(date)

            return (
                `
                    <div class="primary u-rounded-corners pa-3">
                        <h4 class="font-weight-bold white--text md-body-2">${ item.plan }</h4>
                        <h4 class="font-weight-bold grey--text text--darken-1 md-body-2">${ day }</h4>
                        <h4 class="font-weight-bold white--text md-body-2">${ item.active_projects } Active Projects</h4>
                    </div>
                `
            )
        },

        localGetStatsFromIndex (pointIndexDate) {
            const item = this.subscription_plan_day_stats.find(i => moment(moment(i.start_date).format('YYYY-MM-DD')).isSame(moment(pointIndexDate).format('YYYY-MM-DD')))
            const day = moment(pointIndexDate).format('MMM D')

            return { item, day }
        },

        async localGetProjectsForDay (evt, data, conf) {
            if (this.projectLoading) return
            if (this.localCurrentPlanLevel === 3) return

            const { dataPointIndex } = conf
            const date = this.chart_options.xaxis.categories[dataPointIndex]
            const { item } = this.localGetStatsFromIndex(date)

            this.projectLoading = true
            await this.actionGetProjects({ id: item.id, params: { 'fields[statistics_reports]': 'id,data,start_date' } })
            this.projectLoading = false
            this.openProjectStats = true
        },

        localGetAvailableLevelsForMonth () {
            [1, 2, 3].forEach(level => {
                const index = this.subscription_plan_day_stats.findIndex(i => (i.level === level && i.active_projects > 0))
                if (index !== -1) this.availablePlanLevels.push(level)
            })
        },

        localHasPlanLevel (level) {
            return this.availablePlanLevels.includes(level)
        },

        localGenerateFillColors (conf) {
            const { dataPointIndex } = conf
            const date = this.chart_options.xaxis.categories[dataPointIndex]
            const { item } = this.localGetStatsFromIndex(date)

            return item ? this.planColors[item.level] : 'rgba(0,0,0,0)'
        },

        localDateFormatter (date, format = 'MMM D') {
            return moment(date).format(format)
        },

        ...mapActions('User', {
            user_me: 'me',
        }),

        ...mapActions('SubscriptionPlan', [
            'actionFetchCycles',
            'actionFetchUsageStats',
            'actionGetProjects',
            'actionClearItem'
        ]),
    },
}
</script>

<style scoped>
.c-billing-graph > div {
    min-height: auto !important;
}

.loading-cursor {
    cursor: wait !important;
}
</style>

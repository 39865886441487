<template>
    <a-card>
        <div class="u-flex-center-y px-6 py-5">
            <div class="u-flex-center-y">
                <h2 class="text-h6 primary--text text--darken-1 font-weight-medium">Competitor Details</h2>
                <a-menu offset-y bottom open-on-hover v-if="$can('competitors.update') || $can('projects.update-only')">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" class="ml-2">
                            <a-icon>keyboard_arrow_down</a-icon>
                        </div>
                    </template>
                    <a-list class="u-list-std">
                        <a-list-item @click="$router.push({ name: 'settings-competitors' })" v-if="$can('competitors.update')">
                            <a-list-item-content class="md-body-2 grey--text text--darken-3">Edit Competitor</a-list-item-content>
                        </a-list-item>
                        <a-list-item @click="$emit('delete', item, 'detail-view')" v-if="$can('projects.update-only')">
                            <a-list-item-content class="md-body-2 red--text text--darken-2">Remove Competitor</a-list-item-content>
                        </a-list-item>
                    </a-list>
                </a-menu>
            </div>
            <a-spacer></a-spacer>
            <a-icon size="20" color="grey darken-1" @click="$emit('close')">clear</a-icon>
        </div>

        <a-divider></a-divider>

        <a-sheet max-height="500" class="c-main-content u-overflow-y pa-6 pt-5">
            <!-- Name -->
            <div class="mb-1">
                <a-tooltip right offset-overflow :disabled="!item.link">
                    <template v-slot:activator="{ on }">
                        <a v-on="on" :href="item.link" target="_blank" rel="noopener noreferrer" class="d-inline-flex align-center">
                            <h2 class="md-heading-6 font-weight-medium grey--text text--darken-4">{{ item.name }}</h2>
                            <a-icon v-if="item.link" size="14" color="grey darken-2" class="ml-1" style="flex: shrink: 0">launch</a-icon>
                        </a>
                    </template>
                    <span>{{ item.link }}</span>
                </a-tooltip>
            </div>
            <div class="u-flex-center-y" v-if="item.category || item.domain">
                <span v-if="item.category" class="md-caption text-uppercase font-weight-bold secondary--text" :title="item.category ? item.category.value : ''" style="letter-spacing: 0.2px">{{ item.category ? item.category.value : '' | truncateText(20) }}</span>
                <a-icon v-if="item.category && item.domain" size="8" color="grey lighten-1" class="mx-2">fiber_manual_record</a-icon>
                <template v-if="item.domain">
                    <v-hover v-slot="{ hover }">
                        <span :class="{ 'u-underline': hover }" class="md-caption text-uppercase font-weight-bold grey--text text--darken-2" style="letter-spacing: 0.2px">{{ item.domain }}</span>
                    </v-hover>
                </template>
            </div>
            <a-sheet class="mt-3" v-if="item.description_json && show_description">
                <div class="mb-0">
                    <a-icon size="18" color="grey darken-1" class="mr-1">info</a-icon>
                    <span class="md-body-2 grey--text text--darken-3 text-uppercase font-weight-bold">Description</span>
                </div>
                <g-editor-box
                    v-if="show_description"
                    v-model="item.description_json"
                    customHeight="auto"
                    :modelIsOpen="isOpen"
                    :canUpdate="false"
                    textColor="grey--text text--darken-2"
                    readOnly dense hideFooter hideCloseBtn hideFocus
                ></g-editor-box>
            </a-sheet>

            <!-- Stength -->
            <div :class="[item.description_json ? 'mt-1' : 'mt-4']">
                <div class="mb-3">
                    <a-icon size="18" color="green darken-1" class="mr-1">check_circle</a-icon>
                    <span class="md-body-2 grey--text text--darken-3 text-uppercase font-weight-bold">Strengths</span>
                    <a-progress-circular v-if="isLoading" size="16" width="2" color="orange darken-2" class="ml-2" indeterminate></a-progress-circular>
                </div>
                <p class="u-rounded-corners-lg grey--text text--darken-2 mb-0" style="white-space: pre-wrap">
                    <span v-if="item.strengths">{{ item.strengths }}</span>
                    <span v-else class="md-body-2 grey--text">No Strengths</span>
                </p>
            </div>

            <!-- Weakness -->
            <div class="mt-4">
                <div class="mb-3">
                    <a-icon size="18" color="red darken-1" class="mr-1">cancel</a-icon>
                    <span class="md-body-2 grey--text text--darken-3 text-uppercase font-weight-bold">Weaknesses</span>
                    <a-progress-circular v-if="isLoading" size="16" width="2" color="orange darken-2" class="ml-2" indeterminate></a-progress-circular>
                </div>
                <p class="u-rounded-corners-lg grey--text text--darken-2 mb-0" style="white-space: pre-wrap">
                    <span v-if="item.weaknesses">{{ item.weaknesses }}</span>
                    <span v-else class="md-body-2 grey--text">No Weaknesses</span>
                </p>
            </div>
        </a-sheet>
    </a-card>
</template>

<script>
export default {
    name: 'ModalCompetitorView',

    props: {
        item: {
            type: Object,
        },
        isOpen: {
            type: Boolean
        },
        canUpdate: {
            type: Boolean
        },
        isLoading: {
            type: Boolean
        }
    },

    data () {
        return {
            currentInput: '',
            updateTimeout: null,
            show_description: false
        }
    },

    watch: {
        isOpen (val) {
            if (val) this.localIndex()
        }
    },

    mounted () {
        this.localIndex()
    },

    methods: {
        async localIndex () {
            this.show_description = false
            setTimeout(() => this.show_description = true, 500)
            this.localResetScroll()
        },

        localResetScroll () {
            const el = document.querySelector('.c-main-content')
            el.scrollTop = 0
        },

    }
}
</script>

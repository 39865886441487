<template>
    <a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="pa-6 pt-2 px-0 mb-12">
            <g-page-header
                title="Competitors"
                subtitle="Manage all your competitor knowledge base here. Then add competitors from this list under project."
                icon="domain"
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                class="pr-1"
            >
                <template v-slot:action>
                    <a-btn v-test-btn.create color="primary" v-if="$can('competitors.store')" class="elevation-0 mx-0" dark @click="local_competitor_create()">
                        <a-icon class="mr-2" color="white" size="18">add</a-icon>
                        Create New Competitor
                    </a-btn>
                </template>
            </g-page-header>
            <a-layout v-if="!local_loading && local_org_preference_link_tag()">
                <a-flex shrink pr-1>
                    <a-menu bottom offset-y v-model="product_tag_dropdown" :close-on-content-click="false" max-width="280">
                        <template v-slot:activator="{ on }">
                            <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="312" v-on="on">
                                <div class="md-body-2 grey--text text--darken-2 mx-1 u-flex align-center">
                                    <a-icon size="20" :class="[selected_product_tags.length ? 'blue--text' : '' , 'pr-2']">filter_list</a-icon>
                                    <span class="font-weight-medium mr-2">Product Tags: </span>
                                    <span class="grey--text text--darken-1 font-weight-normal">
                                        <template v-if="selected_product_tags && selected_product_tags.length === 1 && selected_product_tags[0] !== 'All Tags' && selected_product_tags[0] !== 'No Tag'">
                                            <g-tags :tag="local_get_tag(selected_product_tags[0])" :text-range="22" hide-clear-icon dense></g-tags>
                                        </template>
                                        <template v-else-if="selected_product_tags && selected_product_tags.length > 1">{{ selected_product_tags.length + ' Selected' }}</template>
                                        <template v-else-if="selected_product_tags[0] === 'No Tag'">No Tag</template>
                                        <template v-else>All Tags</template>
                                    </span>
                                </div>
                                <a-spacer></a-spacer>
                                <a-progress-circular v-if="filter_loading === 'tags'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                <a-icon v-else size="20" class="ma-0">arrow_drop_{{ product_tag_dropdown ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed">
                            <a-sheet class="c-input-wrap px-2">
                                <a-text-field
                                    v-model="local_search_tag"
                                    placeholder="Search Product Tags"
                                    background-color="neutral"
                                    class="md-body-2 font-weight-normal"
                                    solo flat hide-details autofocus
                                >
                                </a-text-field>
                            </a-sheet>
                        </a-list>
                        <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                            <template v-if="local_search_tag">
                                <a-list-item v-for="tag in local_filtered_product_tag_list" class="pr-5" :class="{ 'grey lighten-4': local_tag_is_selected(tag.id) }" :disabled="local_loading || pagination_loading" :key="tag.id" @click="local_filter_competitor_by_tags(tag.id)">
                                    <a-list-item-icon class="ma-0 pa-0 u-flex justify-center">
                                        <a-checkbox dense hide-details color="green darken-2" class="ma-0 pa-0" :value="local_tag_is_selected(tag.id)"></a-checkbox>
                                    </a-list-item-icon>
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">{{ tag.label }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                                <a-list-item v-if="local_search_tag && (local_filtered_product_tag_list && !local_filtered_product_tag_list.length)">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">No Tags Found</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </template>
                            <template v-else>
                                <a-list-item v-for="tag in product_tag_list" class="pr-5" :class="{ 'grey lighten-4': local_tag_is_selected(tag.id) }" :disabled="local_loading || pagination_loading" :key="tag.id" @click="local_filter_competitor_by_tags(tag.id)">
                                    <a-list-item-icon class="ma-0 pa-0 u-flex justify-center">
                                        <a-checkbox dense hide-details color="green darken-2" class="ma-0 pa-0" :value="local_tag_is_selected(tag.id)"></a-checkbox>
                                    </a-list-item-icon>
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">{{ tag.label }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </template>
                        </a-list>
                        <a-divider></a-divider>
                        <a-list class="u-list-condensed u-overflow-y">
                            <a-list-item class="pr-5" :disabled="local_loading || pagination_loading" @click="local_filter_competitor_by_tags('no_tag')">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">No Tag</span>
                                </a-list-item-title>
                            </a-list-item>
                            <a-list-item class="pr-5" :disabled="local_loading || pagination_loading" @click="local_filter_competitor_by_tags()">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">All Tags</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
            </a-layout>
            <a-layout v-if="local_loading || (!local_loading && !competitor_list.length)">
                <a-flex>
                    <a-responsive class="my-4" v-if="local_loading">
                        <a-divider class="grey lighten-2 mb-4"></a-divider>
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Competitors..</h3>
                    </a-responsive>
                    <a-responsive class="my-4" v-if="!local_loading && !competitor_list.length">
                        <a-divider class="grey lighten-2 mb-4"></a-divider>
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                        <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <a-layout v-else>
                <a-flex xs12>
                    <draggable v-model="competitor_list" :options="{handle: '.js-drag-handle'}">
                        <a-card v-for="(competitor, index) in competitor_list" class="u-elevation-custom-1 u-rounded-corners mb-4" :key="competitor.id">
                            <a-card-text class="pa-0">
                                <a-container container--fluid grid-list-xl class="px-6 py-4">
                                    <a-layout wrap align-center>
                                        <a-flex shrink class="pl-1 px-0" v-if="$can('competitors.update')">
                                            <a-icon class="grey--text text--lighten-1 u-cursor-pointer js-drag-handle">drag_indicator</a-icon>
                                        </a-flex>
                                        <a-flex xs10 xl9>
                                            <h2 class="md-heading-6 font-weight-medium">{{ competitor.name }}</h2>
                                        </a-flex>
                                        <a-spacer></a-spacer>
                                        <a-flex shrink v-if="$can('competitors.update-only')">
                                            <a-btn v-test-btn.edit v-test-id="competitor.id" color="grey" text icon @click="local_competitor_edit(competitor.id)">
                                                <a-icon>edit</a-icon>
                                            </a-btn>
                                        </a-flex>
                                        <a-flex shrink v-if="$can('competitors.destroy')">
                                            <a-menu left offset-y transition="slide-y-transition" max-width="340">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn v-test-btn.delete v-test-id="competitor.id" v-on="on" color="grey darken-1" size="16" text icon>
                                                        <a-icon>delete</a-icon>
                                                    </a-btn>
                                                </template>
                                                <a-card class="u-rounded-corners u-elevation-custom-1 pa-3" min-width="320" max-width="340">
                                                    <a-card-text class="pa-1">
                                                        <h2 class="body-2 font-weight-normal mb-1 grey--text text--darken-1"><strong>Are you sure you want to delete the competitor?</strong> <br> This competitor will be permanently deleted and removed from the assigned project. This action cannot be undone.</h2>
                                                        <div class="mt-2">
                                                            <a-btn v-test-btn.delete v-test-id="competitor.id" @click="local_competitor_destroy(competitor.id)" small text class="red darken-1 u-rounded-corners ma-0 pa-1 px-2" dark>Delete</a-btn>
                                                        </div>
                                                    </a-card-text>
                                                </a-card>
                                            </a-menu>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-card-text>
                            <template v-if="local_org_preference_link_tag()">
                                <a-divider></a-divider>
                                <a-card-text>
                                    <a-layout>
                                        <div class="u-flex align-center flex-wrap ml-4 u-wfull py-5">
                                            <template v-for="(tag, tagIndex) in localGetSelectedTags(competitor.id)">
                                                <g-tags
                                                    :tag="tag"
                                                    :key="tag.id"
                                                    :class="[
                                                        { 'c-ring-blue': currentSelectionIndex === tagIndex && selected_competitor_index === index },
                                                        { 'c-ring-red u-opacity-70': tagConfirmRemoveIndex === tagIndex && selected_competitor_index === index }
                                                    ]"
                                                    :canUpdate="$can('competitors.update-only')"
                                                    @destroy="localDestroyTag(tag)"
                                                    dense
                                                    tile
                                                ></g-tags>
                                            </template>
                                            <template v-if="!$can('competitors.update-only') && localGetSelectedTags(competitor.id) && !localGetSelectedTags(competitor.id).length">
                                                <span class="grey--text md-body-2">No Tags Added</span>
                                            </template>
                                            <template v-if="$can('competitors.update-only')">
                                                <div v-if="selected_competitor_index !== index" style="flex-grow: 1; height: 24px;" :class="['d-inline-flex align-center u-cursor-pointer', { 'mb-3': localGetSelectedTags(competitor.id) && localGetSelectedTags(competitor.id).length }]" @click="localShowTagSearchInput(competitor, index)">
                                                    <a-icon color="grey" size="16" class="mr-1">add</a-icon>
                                                    <a-sheet class="md-body-2 grey--text" min-width="150">
                                                        <template v-if="localGetSelectedTags(competitor.id) && localGetSelectedTags(competitor.id).length">
                                                            {{ $can('competitors.update-only') && $can('tags.store') ? 'Add Tag' : 'Select Existing Tag' }}
                                                        </template>
                                                        <template v-else>
                                                            <template v-if="$can('competitors.update-only') && $can('tags.store')">Create New or Add Existing Tag</template>
                                                            <template v-else>Select Existing Tag</template>
                                                        </template>
                                                    </a-sheet>
                                                </div>
                                                <SCompetitorTagsCombobox
                                                    v-if="selected_competitor_index === index"
                                                    :initialLoading="mixinIsLoading('tag-search-' + competitor.id)"
                                                    :tagType="tagSearchType"
                                                    :id="'product' + 'TagSearchInput'"
                                                    :tagList="localFilteredTags"
                                                    :tagSearchType="tagSearchType"
                                                    :tagsSelected="localGetSelectedTags(competitor.id)"
                                                    :canCreate="$can('competitors.update-only') && $can('tags.store')"
                                                    @openDropdown="localShowTagSearchInput(competitor, index)"
                                                    @backspace="localOnBackspace"
                                                    @escape="localOnEscape"
                                                    @search="localSearchTags"
                                                    @create="localCreateTagNew"
                                                    @select="localAttachTagsToCompetitor"
                                                ></SCompetitorTagsCombobox>
                                            </template>
                                        </div>
                                    </a-layout>
                                </a-card-text>
                            </template>
                        </a-card>
                    </draggable>
                </a-flex>
            </a-layout>
        </a-container>

        <template v-if="dialog_competitor_form">
            <a-dialog v-model="dialog_competitor_form" max-width="608" persistent>
                <a-card>
                    <a-card-text class="pa-0">
                        <a-form @submit.prevent="() => false">
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-start>
                                    <a-flex shrink class="pr-1">
                                        <a-avatar class="primary darken-1" size="40">
                                            <a-icon dark>domain</a-icon>
                                        </a-avatar>
                                    </a-flex>
                                    <a-flex>
                                        <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (competitor_form_mode == 'add') ? 'Create New Competitor' : 'Edit Existing Competitor' }}</h2>
                                        <p class="md-subtitle-2 mb-0">{{ (competitor_form_mode == 'add') ? 'You can add new competitor to manage competitor.' : 'You can edit existing competitor information.' }}</p>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-center>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                        <a-text-field
                                            v-model="competitor_item.name"
                                            placeholder="Enter Competitor Name"
                                            background-color="neutral"
                                            solo flat hide-details
                                            autofocus
                                            v-test-input.name>
                                        </a-text-field>
                                        <span 
                                            class="md-caption red--text text--darken-2 d-block mt-2" 
                                            v-if="competitor_response.server && competitor_response.server.errors && competitor_response.server.errors.name"  
                                            v-test-label.name.error
                                        >
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ competitor_response.server.errors.name[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2 align-center" v-else>
                                            <a-icon size="16">info</a-icon>
                                            <span>The name will be helpful to identify the competitor.</span>
                                        </span>
                                    </a-flex>

                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Domain (Optional)</label>
                                        <a-text-field
                                            v-model="competitor_item.domain"
                                            placeholder="Enter the Domain"
                                            background-color="neutral"
                                            solo flat hide-details
                                            v-test-input.domain
                                            >
                                        </a-text-field>
                                        <span 
                                            class="md-caption red--text text--darken-2 d-block mt-2" v-if="competitor_response.server && competitor_response.server.errors && competitor_response.server.errors.domain" v-test-label.domain.error>
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ competitor_response.server.errors.domain[0] }}
                                        </span>

                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            The domain can have 2 subdomains & 2 extensions. (Eg: google.company.success.co.org)
                                        </span>
                                    </a-flex>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Link (Optional)</label>
                                        <a-text-field
                                            v-model="competitor_item.link"
                                            placeholder="Add a link"
                                            background-color="neutral"
                                            solo flat hide-details
                                            @input="local_competitor_link_validate(competitor_item.link)"
                                            v-test-input.link
                                            >
                                        </a-text-field>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="error_link_response" v-test-label.link.error>
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ error_link_response }}
                                        </span>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-else-if="competitor_response.server && competitor_response.server.errors && competitor_response.server.errors.link" v-test-label.link.error>
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ competitor_response.server.errors.link[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            The link will be helpful to identify the competitor.
                                        </span>
                                    </a-flex>

                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Description (Optional)</label>
                                        <a-sheet class="grey lighten-4 u-rounded-corners-lg">
                                            <g-editor-box
                                                v-model="competitor_item.description_json"
                                                :can-update="$can('competitors.update')"
                                                :editorClass="['px-3 neutral']"
                                                :customHeight="200"
                                                :modelIsOpen="dialog_competitor_form"
                                                dense
                                                hideCloseBtn hideFocus
                                                v-test-input.description
                                            ></g-editor-box>
                                        </a-sheet>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                            <a-icon size="16">info</a-icon>
                                            Description about the competitor.
                                        </span>
                                    </a-flex>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Strengths (Optional)</label>
                                        <a-textarea
                                            v-model="competitor_item.strengths"
                                            placeholder="Enter Strengths"
                                            background-color="neutral"
                                            solo flat hide-details
                                            v-test-input.strengths
                                        >
                                        </a-textarea>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="competitor_response.server && competitor_response.server.errors && competitor_response.server.errors.strengths" v-test-label.strengths.error>
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ competitor_response.server.errors.strengths[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Description of competitor’s key selling points and advantages.
                                        </span>
                                    </a-flex>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Weaknesses (Optional)</label>
                                        <a-textarea
                                            v-model="competitor_item.weaknesses"
                                            placeholder="Enter Weaknesses"
                                            background-color="neutral"
                                            solo flat hide-details
                                            v-test-input.weaknesses
                                        >
                                        </a-textarea>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="competitor_response.server && competitor_response.server.errors && competitor_response.server.errors.weaknesses" v-test-label.weaknesses.error>
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ competitor_response.server.errors.weaknesses[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Description of competitor’s key disadvantages.
                                        </span>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-center>
                                    <a-flex shrink>
                                        <a-btn v-test-btn.save color="primary" class="ma-0 elevation-0 px-3" @click="local_competitor_store()" :loading="loading" :disabled="loading || !!error_link_response">Save Competitor</a-btn>
                                        <a-btn v-test-btn.cancel class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_competitor_form = !dialog_competitor_form" :disabled ="loading">Cancel</a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-form>
                    </a-card-text>
                </a-card>
            </a-dialog>
        </template>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import mixinErrors from '@/mixins/mixin-module-error-setup'
import { Validate } from '@/helpers/helper-validations'
import { SCompetitorTagsCombobox } from '../../config/config-shared-components'

export default {
    name: 'Competitors',
    components: {
        SCompetitorTagsCombobox
    },

    mixins: [
        mixinLoading,
        mixinErrors,
    ],

    data() {
        return {
            dialog_competitor_form: false,
            local_loading: true,
            error_link_response: '',
            currentTagToRemove: {},
            currentTagList: [],
            removedTags: [],
            tagBulkAttachIds: [],
            selectedTags: { product: [] },
            systemTagTypes: ['label', 'product', 'platform'],
            createTagItem: { id: 'create', label: 'Create', value: 'create' },
            selected_competitor_index: null,
            tagSearchType: 'product',
            currentSelectionIndex: null,
            tagConfirmRemoveIndex: null,
            tagRemoveType: null,
            tagDestroyTimeout: null,
            link_tag_competitor_item: {},
            bulkChunkCount: 50,
            product_tag_dropdown: false,
            selected_product_tags: [],
            pagination_loading: false,
            filter_loading: false,
            local_search_tag: '',
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Competitors', to: '/settings/competitors', exact: true})
            return breadcrumbs
        },

        competitor_list: {
            get() {
                return this.$store.state.Competitor.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },

        localFilteredTags () {
            if (this.currentTagList && !this.currentTagList.length) return []
            return this.currentTagList
        },

        local_filtered_product_tag_list () {
            if (this.product_tag_list && !this.product_tag_list.length) return
            return this.product_tag_list.filter(list => list.label.toLowerCase().includes(this.local_search_tag.toLowerCase()))
        },

        ...mapState('Competitor', {
            competitor_item: 'item',
            competitor_form_mode: 'form_mode',
            competitor_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key'
        }),

        ...mapState('Tag', {
            tag_list: 'list',
            tag_response: 'response'
        }),

    },
    methods: {
        async local_index() {
            await this.preference_index()
            await this.tag_index({ sort: 'order','filter[type]': this.tagSearchType, 'fields[tags]': 'id,label,color,type' })
            this.product_tag_list = [...this.tag_list]
            if (this.$can('competitors.index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.competitor_clear()
            await this.competitor_index({
                'include': 'products',
                'sort': 'order'
            })
            this.local_loading = false
        },

        local_org_preference_link_tag () {
            return this.preference_get_property_by_key('organization.link_competitor_product_tag', 'value') === 'Yes'
        },

        local_get_tag (tag_id) {
            return this.tag_list.find(item => item.id === tag_id)
        },

        local_tag_is_selected (tag_id) {
            return this.selected_product_tags.includes(tag_id)
        },

        async local_filter_competitor_by_tags (tag_id) {
            this.filter_loading = 'tag'
            if (tag_id === 'no_tag')  return this.local_no_tag_filter()
            if (!tag_id) return this.local_all_tags_filter()

            // Filter competitors by product tags
            if (this.selected_product_tags && !this.selected_product_tags.length) {
                this.selected_product_tags.push(tag_id)
            }
            else {
                const index = this.selected_product_tags.findIndex(id => id === tag_id)
                if (this.selected_product_tags[0] === 'No Tag') this.selected_product_tags.splice(0, 1)
                if (index !== -1) this.selected_product_tags.splice(index, 1)
                else this.selected_product_tags.push(tag_id)
            }
            await this.competitor_index({
                'include': 'products',
                'sort': 'order',
                'filter[product_id]': this.selected_product_tags
            })
            this.filter_loading = null
        },

        local_no_tag_filter () {
            this.selected_product_tags = ['No Tag']
            this.filter_loading = null
            this.product_tag_dropdown= false
            this.competitor_index({
                'filter[product_id]': 'none',
                'include': 'products',
                'sort': 'order',
            })
        },

        local_all_tags_filter () {
             this.selected_product_tags = []
            this.filter_loading = null
            this.product_tag_dropdown = false
            return this.competitor_index({
                'filter[product_id]': null,
                'include': 'products',
                'sort': 'order',
            })
        },

        async local_competitor_edit(id) {
            await this.competitor_clear_item()
            await this.competitor_select({id: id})
            this.error_link_response = ''
            this.error_domain_response = ''
            this.dialog_competitor_form = true
        },

        async local_competitor_create() {
            await this.competitor_clear_item()
            this.error_link_response = ''
            this.error_domain_response = ''
            this.dialog_competitor_form = true
        },

        async local_competitor_store() {
            if (this.competitor_form_mode == 'add') {
                if (!this.error_link_response && !this.error_domain_response) {
                    this.competitor_item.order = -1
                    await this.competitor_store(this.competitor_item)
                }
            } else this.local_competitor_update()

            if (this.$status(this.competitor_response)) {
                await this.competitor_show({id : this.competitor_item.id , mode: 'add' })
                this.dialog_competitor_form = false
            }
        },

        async local_competitor_update() {
            if (!this.error_link_response && !this.error_domain_response) await this.competitor_update(this.competitor_item)

            if (this.$status(this.competitor_response)) {
                await this.competitor_show({id : this.competitor_item.id , mode: 'edit', params: { 'include': 'products'} })
                this.dialog_competitor_form = false
            }
        },

        async local_competitor_destroy (id) {
            this.selected_competitor_index = null
            this.competitor_destroy({id: id})
        },

        async local_competitor_link_validate(link) {
            if (!link) this.error_link_response = ''
            else {
                let { message } = new Validate(link, { silent: true }).isUrl(link).length(0, 2048).run()
                if (message) {
                    this.error_link_response = (!message) ? '' : this.error_link_response = message
                    return
                } else {
                    let { message } = new Validate(link, { silent: true }).urlDomainLength(link).run()
                    this.error_link_response = (!message) ? '' : this.error_link_response = message
                }
            }
        },

        async local_reorder(list) {
            this.selected_competitor_index = null
            this.competitor_reorder({list: list})
        },

        /* Tags - Start */
        async localShowTagSearchInput (competitor, index) {
            this.selected_competitor_index = index
            this.link_tag_competitor_item = competitor
            this.mixinSetLoading('tag-search-' + competitor.id)
            this.localResetTagIndices()
            this.currentTagList = []
            this.currentSelectionIndex = null
            await this.tag_index({ count: 5, sort: 'order','filter[source_id]': competitor.id, 'filter[type]': this.tagSearchType, 'fields[tags]': 'id,label,color,type' })
            this.currentTagList = this.tag_list
            this.mixinResetLoading('tag-search-' + competitor.id)
        },

        localGetSelectedTags (id, mode = null) {
            const selectedCompetitor = this.competitor_list.filter(item => item.id === id)
            if (selectedCompetitor && selectedCompetitor.length && selectedCompetitor[0].products && !selectedCompetitor[0].products.length) return []
            return selectedCompetitor[0].products
        },

        localResetTagIndices () {
            this.currentSelectionIndex = null
            this.tagConfirmRemoveIndex = null
        },

        localTraverseLeft () {
            if (!this.$can('competitors.update-only')) return
            if (this.tagConfirmRemoveIndex) this.tagConfirmRemoveIndex = null
            const currentTypeTags = this.localGetSelectedTags(this.link_tag_competitor_item.id)
            this.currentTagToRemove = currentTypeTags.slice(-1)[0]

            if (this.currentSelectionIndex === null) return this.currentSelectionIndex = currentTypeTags.length - 1
            if (this.currentSelectionIndex > 0) return this.currentSelectionIndex--
            if (this.currentSelectionIndex === 0 && currentTypeTags.length === 1) return this.currentSelectionIndex = currentTypeTags.length - 1
            if (this.currentSelectionIndex === 0) return this.currentSelectionIndex = currentTypeTags.length - 1
        },

        localOnBackspace (evt) {
            if (evt.code !== 'Backspace') return
            if (evt.shiftKey || evt.ctrlKey) return

            if (this.currentSelectionIndex === null) this.localTraverseLeft()
            if (this.tagConfirmRemoveIndex !== null) {
                this.tagRemoveType = this.tagSearchType
                return this.localTagRemove(this.link_tag_competitor_item.id)
            }
            if (this.currentSelectionIndex !== null) return this.localGetTagRemoveConfirm()
        },

        localOnEscape (evt) {
            if (evt.code !== 'Escape') return
            if (evt.shiftKey || evt.ctrlKey) return

            if (this.tagConfirmRemoveIndex) this.tagConfirmRemoveIndex = null
            if (this.currentSelectionIndex !== null) this.currentSelectionIndex = null
        },

        async localTagRemove (competitor_id) {
            clearTimeout(this.tagDestroyTimeout)
            const tagList = this.localGetSelectedTags(competitor_id)
            const lastTag = tagList && tagList.length ? tagList.slice(-1) : null
            this.tagConfirmRemoveIndex = null
            this.currentSelectionIndex = null
            this.currentTagToRemove = {}
            if (!lastTag) return
            this.localDestroyTag(lastTag[0])
        },

        localDestroyTag (tag) {
            this.competitor_remove_tag({competitor_id: tag.association.source_id, tag_id: tag.id})
            this.association_destroy({ id: tag.association.id, source_id: tag.association.source_id, source_type: 'Competitor' })
        },

        async localSearchTags ({ type, value, clearLoading, showError }) {
            this.localResetTagIndices()
            await this.tag_index({
                count: 20,
                sort: 'order',
                'filter[type]': this.tagSearchType,
                'filter[source_id]': this.link_tag_competitor_item.id,
                'filter[label_filter]': (value && value.toLowerCase()) || '',
                'fields[tags]': 'id,label,color,type',
            })
            this.currentTagList = [...this.tag_list, ...[this.createTagItem]]
            clearLoading()
        },

        async localCreateTagNew (createProps) {
            const { value: searchText, tagModule: mode, showError, showSuccess } = createProps || {}
            const hasPermission = this.$can('competitors.update-only') && this.$can('tags.store')
            if (!hasPermission) return this.mixinResetLoading('tag-create')

            const tagItem = this.$appendId({ color: this.$randomColor(), label: searchText, type: this.tagSearchType })
            await this.tag_store(tagItem)
            if (!this.$status(this.tag_response)) {
                this.mixinSetError({ slug: 'tag-create', message: this.$response(this.tag_response, 'label') })
                this.mixinResetLoading('tag-create')
                showError(this.$response(this.tag_response, 'label'))
                return true
            }
            showSuccess()
            this.selectedTags[ this.tagSearchType ].push(tagItem)
            this.localAddTagToCompetitor(tagItem)
            await this.localAttachTag(tagItem, 'new')
            this.competitor_show({ id: this.link_tag_competitor_item.id, mode: 'edit', params: { 'include': 'products'}})
        },

        localAddTagToCompetitor (tag) {
            const competitor = _.cloneDeep(this.link_tag_competitor_item)
            const competitorTags = (competitor && competitor.tags) ?? []
            this.$set(competitor, 'tags', [...competitorTags, tag])
        },

        async localAttachTagsToCompetitor (params) {
            const { tag, showSuccess, mode = null } = params || {}
            this.localResetTagIndices()
            this.selectedTags[this.tagSearchType].push(tag)
            await this.localAttachTag(tag, mode)
            showSuccess()
            const chunkIds = this.tagBulkAttachIds.splice(0, this.bulkChunkCount)
            await this.association_store({
                target_id: chunkIds[0].target_id,
                type: `competitor_${tag.type}`,
                source_type: 'Competitor',
                source_id: this.link_tag_competitor_item.id,
                target_type: 'Tag'
            })
            await this.competitor_show({ id: this.link_tag_competitor_item.id, mode: 'edit', params: { 'include': 'products'}})
        },

        async localAttachTag (tag, mode = null) {
            const tagType = `competitor_${tag.type}`
            const data = this.$appendId({
                source_type: 'Competitor',
                source_id: this.link_tag_competitor_item.id,
                target_type: 'Tag',
                target_id: tag.id,
                type: tagType
            })
            if (mode === 'new') {
                await this.association_store(data)
                this.localAttachTagAssociation(tag.id, data)
            } else this.tagBulkAttachIds.push({ id: data.id, target_id: tag.id })
        },

        async localAttachTagAssociation (id, association) {
            const index = this.link_tag_competitor_item.products.findIndex(item => item.id === id)
            if (index === -1) return
            Object.assign(this.link_tag_competitor_item.tags[index], { association })
        },

        localGetTagRemoveConfirm () {
            if (this.tagRemoveTimeout) clearTimeout(this.tagRemoveTimeout)

            this.tagConfirmRemoveIndex = this.currentSelectionIndex
            this.tagRemoveTimeout = setTimeout(() => this.tagConfirmRemoveIndex = null, 2000)
        },

        ...mapActions('Competitor', {
            competitor_index: 'index',
            competitor_show: 'show',
            competitor_store: 'store',
            competitor_update: 'update',
            competitor_select: 'select',
            competitor_destroy: 'destroy',
            competitor_remove_tag: 'remove_tag',
            competitor_reorder: 'reorder',
            competitor_clear: 'clear',
            competitor_clear_item: 'clear_item',
        }),

        ...mapActions('Association', {
            association_store: 'store',
            association_bulk_store: 'bulk_store',
            association_destroy: 'destroy',
            association_bulk_destroy: 'bulk_destroy',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_clear: 'clear',
            tag_clear_item: 'clear_item',
        }),

        ...mapActions('Preference', {
            preference_index: 'index'
        }),
    },
}
</script>

<template>
    <div class="c-heading u-relative u-flex align-center my-2" style="min-height: 40px">
        <!-- Block List Menu -->
        <block-list-menu v-if="$can('document_generator.update')" @addBlock="methodStoreReportBlock"></block-list-menu>

        <!-- Block Information -->
        <div class="u-absolute" style="top: 50%; transform: translateY(-50%); left: -40px">
            <a-menu offset-x left :nudge-top="50" v-model="dataSideMenu" transition="slide-x-transition" :close-on-content-click="false" min-width="350" max-width="350" style="max-width: 24px !important;" content-class="u-border c-radius u-elevation-custom-1">
                <template v-slot:activator="{ on }">
                    <a v-on="on" v-ripple="{ class: 'info--text' }" style="padding: 0 4px 0 4px;" class="c-component__menu u-flex align-center u-rounded-corners list-group-item js-drag-handle" :class="[ dataSideMenu ? 'is-active' : '' ]"><a-icon class="c-component__drag" size="26">drag_indicator</a-icon></a>
                </template>
                <div class="c-radius u-elevation-custom-1 py-3 px-4 white" style="max-width: 350px;">
                    <div class="u-flex align-center">
                        <h2 class="grey--text text--darken-3 font-weight-bold title" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.title }}</h2>
                        <a-spacer></a-spacer>
                        <a-btn small icon text color="red darken-1 ma-0" v-if="$can('document_generator.destroy')" class="ma-0" @click="methodRemoveComponent">
                            <svg width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-trash red--text text--darken-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a-btn>
                    </div>
                    <p class="grey--text text--darken-1 mb-0 body-2 font-weight-medium" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.description }}</p>
                </div>
            </a-menu>
        </div>

        <!-- Block -->
        <!-- <ol v-if="clonedNumList" contenteditable v-once v-html="clonedNumList.content_html" :id="clonedNumList.id" @blur="methodUpdateOnBlur" @input="methodUpdateOnInput" class="c-dg-list-editor c-xspacing u-wfull ma-0 grey--text text--darken-2"></ol> -->
        <ol v-if="clonedNumList" :contenteditable="$can('document_generator.update')" v-once v-html="dataHtml" :id="clonedNumList.id" @blur="methodUpdateOnBlur" @keyup="methodKeyUp" @input="methodUpdateOnInput" class="c-dg-list-editor c-xspacing u-wfull ma-0 my-2 grey--text text--darken-2"></ol>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import EventBus from '@/config/config-eventbus'
import BlockListMenu from '../BlockListMenu.vue'
import TurndownService from 'turndown'
import marked from 'marked'



export default {
    components: { BlockListMenu },

    props: ['item', 'index', 'list'],

    data () {
        return {
            clonedNumList: this.item,
            dataSideMenu: false,
            dataAutoSaveTimeout: null,
            dataHtml: null,
        }
    },

    mounted () {
        this.methodIndex()
    },

    computed: {
        dataGetBlockInfo () {
            return this.getBlockContent(this.clonedNumList.report_block_id)
        },

        ...mapGetters('ReportBlock', [
            'getBlockContent'
        ]),
    },

    methods: {
        methodIndex () {
            this.dataHtml = this.clonedNumList.content && !this.clonedNumList.content ? null : marked(this.methodStripSplChars()).replace(/(<\/?(?:li)[^>]*>)|<[^>]+>/ig, '$1')
            document.getElementById(this.clonedNumList.id).innerHTML = this.dataHtml
        },

        dataGetBlockElement () {
            return document.getElementById(this.clonedNumList.id)
        },

        methodStripSplChars () {
            // const filtered = this.clonedNumList.content_html.replace(/(<.*?)/ig, '&lt;')
            const filtered = this.clonedNumList.content.replace(/(<.*?)/ig, '&lt;')
            return filtered.replace(/(>.*?)/ig, '&gt;')
        },

        methodUpdateOnInput (evt) {
            const { id, innerText: text, outerHTML: outHTML, innerHTML: html, children: childEls } = evt.target
            const element = this.dataGetBlockElement()

            if (evt.inputType === 'insertFromPaste') element.blur()
            if (childEls.length === 0 && text.length === 0) this.methodRemoveComponent()
            this.methodLiveUpdate(text, outHTML)
        },

        methodKeyUp (evt) {
            // console.log(evt.key)
            // const element = this.dataGetBlockElement()
            // if (evt.inputType === 'insertFromPaste') {
            //     console.log('ehehehehe keyyyyyyyyy')
            //     element.blur()
            // }
        },

        methodUpdateOnBlur (evt) {
            if (!this.$can('document_generator.update')) return
            const { id, innerText: text, outerHTML: outHTML, innerHTML: html } = evt.target
            const element = document.getElementById(id)
            const stripedImage = outHTML.replace(/<img.*?src="(.*?)"[^\>]+>/ig, '')
            const serializedContent = this.methodSerializeHtml(stripedImage)

            evt.preventDefault()
            // this.clonedNumList.content = text.trim()
            // this.clonedNumList.content_html = this.methodCompiledMarkdown(serializedContent)
            this.clonedNumList.content = this.methodCompiledMarkdown(serializedContent)
            this.dataHtml = serializedContent
            // element.outerHTML = serializedContent

            EventBus.$emit('editBlock', this.clonedNumList)
            if (!this.clonedNumList.content.length) return
            this.methodUpdateBlock()
        },

        methodLiveUpdate (text, html) {
            if (!this.$can('document_generator.update')) return
            clearTimeout(this.dataAutoSaveTimeout)
            const stripedImage = html.replace(/<img.*?src="(.*?)"[^\>]+>/ig, '')
            const serializedContent = this.methodSerializeHtml(stripedImage)

            // this.clonedNumList.content = text.trim()
            // this.clonedNumList.content_html = this.methodCompiledMarkdown(serializedContent)
            this.clonedNumList.content = this.methodCompiledMarkdown(serializedContent)
            EventBus.$emit('editBlock', this.clonedNumList)

            if (!this.clonedNumList.content.length) return
            this.dataAutoSaveTimeout = setTimeout(async () => {
                this.methodUpdateBlock()
                clearTimeout(this.dataAutoSaveTimeout)
            }, 500);
        },

        methodSerializeHtml (html) {
            let serializedHtml = html.replace(/(<\/?(?:ol|li)[^>]*>)|<[^>]+>/ig, '$1')
            serializedHtml = serializedHtml.replace(/<img.*?src="(.*?)"[^\>]+>/ig, '')
            serializedHtml = serializedHtml.replace(/(<[^>]+) style=".*?"/ig, '$1')

            if (serializedHtml.children && serializedHtml.children.length) {
                Array.from(serializedHtml.children).forEach((element, index) => {
                    for (let prop in element.dataset) delete element[index].dataset[prop]
                })
            }

            return serializedHtml
        },

        methodCompiledMarkdown (content) {
            return turndownService.turndown(content)
        },

        async methodUpdateBlock () {
            this.actionTemplateBlockEdit({
                id: this.clonedNumList.id,
                report_block_id: this.clonedNumList.report_block_id,
                report_template_id: this.$route.params.template_id,
                content: this.clonedNumList.content,
                // content_html: this.clonedNumList.content_html,
            })
            EventBus.$emit('editBlock', this.clonedNumList)
        },

        async methodStoreReportBlock (component) {
            await this.actionTemplateBlockStore({
                index: this.index + 1,
                data: { report_template_id: this.$route.params.template_id, report_block_id: component.id },
                params: { include: 'reportBlock' }
            })
            this.actionTemplateBlockReorder({ list: this.list })
            // EventBus.$emit('reorderBlock', { index: this.index + 1 })
            // EventBus.$emit('storeBlock', { index: this.index + 1, component })
        },

        methodRemoveComponent () {
            this.actionFindAndRemoveBlock(this.clonedNumList)
            this.actionTemplateBlockDelete({ id: this.clonedNumList.id })
            // EventBus.$emit('removeComponent', this.clonedNumList)
        },

        ...mapActions('ReportTemplateBlock', [
            'actionTemplateBlockStore',
            'actionTemplateBlockEdit',
            'actionTemplateBlockReorder',
            'actionTemplateBlockAttrsStore',
            'actionTemplateBlockAttrEdit',
            'actionFindAndRemoveBlock',
            'actionTemplateBlockDelete'
        ]),
    }
}
</script>

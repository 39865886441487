<template>
    <a-sheet class="u-hfull" style="border-bottom: 1px solid #eee;">
        <table class="c-review-changes-table u-wfull u-hfull" style="border-collapse: collapse;">
            <tr>
                <th class="py-4 px-6 md-body-2 font-weight-bold grey--text text--darken-3 text-uppercase text-left" style="width: 120px; letter-spacing: 0.5px; border-bottom: 1px solid #EEEEEE;">Module</th>
                <th class="py-4 px-6 md-body-2 font-weight-bold grey--text text--darken-3 text-uppercase text-left" style="width: 150px; letter-spacing: 0.5px; border-bottom: 1px solid #EEEEEE;">Field</th>
                <th class="py-4 px-6 md-body-2 font-weight-bold grey--text text--darken-3 text-uppercase text-left" style="width: 300px; letter-spacing: 0.5px; border-bottom: 1px solid #EEEEEE;">Current Value</th>
                <th class="py-4 px-6 md-body-2 font-weight-bold grey--text text--darken-3 text-uppercase text-left" style="width: 300px; letter-spacing: 0.5px; border-bottom: 1px solid #EEEEEE;">New Value</th>
                <th class="py-4 px-6 text-center" style="width: 130px; border-bottom: 1px solid #EEEEEE;">*</th>
            </tr>
            <tr v-if="list && !list.length">
                <td colspan="5" class="text-center py-8">
                    <a-avatar color="grey lighten-1" size="48">
                        <a-icon size="24" color="white">info</a-icon>
                    </a-avatar>
                    <h3 class="md-subtitle-1 body--text text--lighten-1 mt-2">There are no changes to display.</h3>
                </td>
            </tr>
            <template v-else>
                <tr v-for="item in list" :key="item.module === 'Filters' ? item.row_id : item.key">
                    <td class="py-3 px-6 grey--text text--darken-2 md-body-2 text-left" style="width: 120px; vertical-align: top;">{{ item.module }}</td>
                    <td class="py-3 px-6 grey--text text--darken-2 md-body-2 text-left text-capitalize" style="width: 150px; vertical-align: top;" v-if="item.hasOwnProperty('field_label')">{{ item.field_label === 'Due Date' ? 'End Date' : item.field_label }}</td>
                    <td class="py-3 px-6 grey--text text--darken-2 md-body-2 text-left text-capitalize" style="width: 150px; vertical-align: top;" v-else>{{ local_get_formatted_field(item.key) }}</td>

                    <!-- Old Value -->
                    <td class="text-left py-3 px-6" style="width: 300px; vertical-align: top;" v-if="item.module === 'Filters'">
                        <!-- <div class="u-flex-center-y md-body-2 font-weight-medium body--text text--darken-1">
                            <div class="mr-2"><strong>Groups:</strong> {{ item.oldValue.groups }}</div>
                            <div><strong>Conditions:</strong> {{ item.oldValue.rows }}</div>
                        </div> -->
                        <div class="md-body-2 body--text text--lighten-1" v-if="item.oldValue.value && item.oldValue.value.entity_property_id">
                            <div class="text-capitalize" style="margin-bottom: 4px;">
                                <span class="mr-1 md-body-2 font-weight-medium body--text text--darken-1">Field: </span>
                                {{ item.oldValue && item.oldValue.value && item.oldValue.value.entity_property_id ? local_get_entity_key(item.oldValue.value.entity_property_id, 'label') : '' }}
                            </div>
                            <div class="u-flex-center-y" style="margin-bottom: 4px;">
                                <span class="mr-1 md-body-2 font-weight-medium body--text text--darken-1">Operator: </span>
                                {{ local_get_operator_type(item.oldValue.value.entity_property_id, item.oldValue.value.operator ) }}
                            </div>
                            <template v-if="!['empty', 'not-empty'].includes(item.oldValue.value.operator)">
                                <div class="u-flex-center-y" style="margin-bottom: 4px;">
                                    <span class="mr-1 md-body-2 font-weight-medium body--text text--darken-1">Value {{ item.oldValue && item.oldValue.value && item.oldValue.value.operator === 'between' ? '1:' : '' }} </span>
                                    <template v-if="tag_type_modules.includes(local_get_entity_key(item.oldValue.value.entity_property_id))">
                                        <g-tags :text-range="20" :tag="local_get_config_value_label(item.oldValue.value.value, item.oldValue.value.entity_property_id)" hide-clear-icon></g-tags>
                                    </template>
                                    <template v-else-if="local_get_entity_key(item.oldValue.value.entity_property_id) === 'competitor_id'">
                                        <span class="md-caption d-inline-block py-1 px-2 mr-2 mt-1 mb-1 u-rounded-corners u-cursor-pointer" :style="{backgroundColor: '#2196f3', color: '#fff'}">
                                            {{ local_get_config_value_label(item.oldValue.value.value, item.oldValue.value.entity_property_id) | truncateText(14) }}
                                        </span>
                                    </template>
                                    <template v-else-if="custom_tag_list.find(tag => tag.type === local_get_entity_key(item.oldValue.value.entity_property_id))">
                                        <g-tags :text-range="20" :tag="local_get_config_value_label(item.oldValue.value.value, item.oldValue.value.entity_property_id)" hide-clear-icon></g-tags>
                                    </template>
                                    <template v-else>
                                        {{ local_get_config_value_label(item.oldValue.value.value, item.oldValue.value.entity_property_id) }}
                                    </template>
                                </div>
                                <div class="u-flex-center-y" v-if="item.oldValue.value.operator === 'between'">
                                    <span class="mr-1 md-body-2 font-weight-medium body--text text--darken-1">Value 2: </span>
                                    {{ local_get_config_value_label(item.oldValue.value.value_alt, item.oldValue.value.entity_property_id) }}
                                </div>
                            </template>
                        </div>
                    </td>
                    <td class="py-3 px-6 grey--text text--darken-2 md-body-2 text-left" style="width: 300px; vertical-align: top;" v-else-if="item.hasOwnProperty('oldValueLabel') && item.module !== 'Timeline'">{{ item.oldValueLabel }}</td>
                    <td class="py-3 px-6 grey--text text--darken-2 md-body-2 text-left text-capitalize" style="width: 300px; vertical-align: top;" v-else>
                        <template v-if="item.module === 'Timeline' && ['start date', 'due date'].includes(item.oldValueLabel)">{{ local_format_date(item.oldValue) }}</template>
                        <template v-else>{{ local_get_formatted_value(item.oldValue) | truncateText(item.key === 'description' ? 120 : 200) }}</template>
                    </td>

                    <!-- New Value -->
                    <td class="text-left py-3 px-6" style="width: 300px; vertical-align: top;" v-if="item.module === 'Filters'">
                        <div class="red--text text--darken-1" v-if="['filter_deleted', 'group_filter_deleted'].includes(item.key)">Deleted</div>
                        <template v-if="item.newValue.value && !['filter_deleted', 'group_filter_deleted'].includes(item.key)">
                            <div class="md-body-2 body--text text--lighten-1">
                                <div class="text-capitalize u-flex-center-y" style="margin-bottom: 2px;">
                                    <span class="mr-1 md-body-2 body--text text--darken-1 font-weight-medium">Field: </span>
                                    {{ item.newValue.value.entity_property_id ? local_get_entity_key(item.newValue.value.entity_property_id, 'label') : '' }}
                                </div>
                                <div class="u-flex-center-y" style="margin-bottom: 2px;">
                                    <span class="mr-1 md-body-2 body--text text--darken-1 font-weight-medium">Operator: </span>
                                    {{ local_get_operator_type(item.newValue.value.entity_property_id, item.newValue.value.operator ) }}
                                </div>

                                <template v-if="!['empty', 'not-empty'].includes(item.newValue.value.operator)">
                                    <div class="u-flex-center-y" style="margin-bottom: 2px;">
                                        <span class="mr-1 md-body-2 body--text text--darken-1 font-weight-medium">Value {{ item.oldValue && item.oldValue.value && item.oldValue.value.operator === 'between' ? '1:' : '' }} </span>
                                        <template v-if="tag_type_modules.includes(local_get_entity_key(item.newValue.value.entity_property_id))">
                                            <g-tags :text-range="20" :tag="local_get_config_value_label(item.newValue.value.value, item.newValue.value.entity_property_id)" hide-clear-icon></g-tags>
                                        </template>
                                        <template v-else-if="local_get_entity_key(item.newValue.value.entity_property_id) === 'competitor_id'">
                                            <span class="md-caption d-inline-block py-1 px-2 mr-2 mt-1 mb-1 u-rounded-corners u-cursor-pointer" :style="{backgroundColor: '#2196f3', color: '#fff'}">
                                                {{ local_get_config_value_label(item.newValue.value.value, item.newValue.value.entity_property_id) | truncateText(14) }}
                                            </span>
                                        </template>
                                        <template v-else-if="custom_tag_list.find(tag => tag.type === local_get_entity_key(item.newValue.value.entity_property_id))">
                                            <g-tags :text-range="20" :tag="local_get_config_value_label(item.newValue.value.value, item.newValue.value.entity_property_id)" hide-clear-icon></g-tags>
                                        </template>
                                        <template v-else>
                                            {{ local_get_config_value_label(item.newValue.value.value, item.newValue.value.entity_property_id) }}
                                        </template>
                                    </div>
                                    <div class="u-flex-center-y" style="margin-bottom: 2px;" v-if="item.newValue.value.operator === 'between'">
                                        <span class="mr-1 md-body-2 body--text text--darken-1 font-weight-medium">Value 2: </span>
                                        {{ local_get_config_value_label(item.newValue.value.value_alt, item.newValue.value.entity_property_id) }}
                                    </div>
                                </template>
                            </div>
                        </template>
                    </td>
                    <td class="py-3 px-6 grey--text text--darken-2 md-body-2 text-left" style="width: 300px; vertical-align: top;" v-else-if="item.hasOwnProperty('newValueLabel') && item.module !== 'Timeline'">{{ item.newValueLabel }}</td>
                    <td class="py-3 px-6 grey--text text--darken-2 md-body-2 text-left text-capitalize" style="width: 300px; vertical-align: top;" v-else>
                        <template v-if="item.module === 'Timeline' && ['start date', 'due date'].includes(item.newValueLabel)">{{ local_format_date(item.newValue) }}</template>
                        <template v-else>{{ local_get_formatted_value(item.newValue) | truncateText(item.key === 'description' ? 120 : 200) }}</template>
                    </td>
                    <td class="py-3 px-6 md-body-2 text-right red--text u-cursor-pointer" @click="$emit('clear', item.key, item.module, item)" style="width: 120px; vertical-align: top;">
                        <div class="u-flex-center">
                            <a-icon size="14" color="red darken-2" class="mr-1">cancel</a-icon>
                            <span>Clear</span>
                        </div>
                    </td>
                </tr>
            </template>
        </table>
    </a-sheet>
</template>

<script>
import { mapState } from 'vuex'
import MixinTileReviewChange from '../Mixins/MixinTileReviewChangeHelpers'

export default {
    props: {
        list: {
            type: Array,
            required: true
        },
        custom_tag_list: {
            type: Array,
            default: []
        },
        system_tag_list: {
            type: Array,
            default: []            
        }
    },

    mixins: [MixinTileReviewChange],

    data () {
        return {
            tag_type_modules: [
                'label_tag_id',
                'product_tag_id',
                'platform_tag_id',
                'territory_id',
            ],
        }
    },

    computed: {
        local_currency_symbol () {
            return this.user_self?.organization?.currency?.symbol
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('BoardEntity', {
            board_entity_list: 'list',
        }),

        ...mapState('Customer', {
            customer_list: 'list',
        }),

        ...mapState('Partner', {
            partner_list: 'list',
        }),

        ...mapState('Stage', {
            stage_list: 'list',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
        }),

        ...mapState('Competitor', {
            competitor_list: 'list',
        }),

        ...mapState('Territory', {
            territory_list: 'list',
        }),

        ...mapState('Workspace', {
            workspace_search_list: 'search_list',
        }),

        ...mapState('CustomField', {
            custom_field_list: 'list',
        }),

        ...mapState('TagSection', {
            tag_section_list: 'list',
        }),
    },

    methods: {
        local_get_config_value_label (value, prop_id) {
            const prop_key = this.local_get_entity_key(prop_id)
            const custom_tag_item = this.tag_section_list.find(tag => tag.slug === prop_key)
            if (custom_tag_item) return this.custom_tag_list.find(item => item.id === value)

            const custom_field_name = prop_key.split('.')[1]
            const custom_field = this.custom_field_list.find(field => (field.type === 'dropdown' || field.type === 'date') && field.name === custom_field_name)
            if (custom_field && custom_field.type === 'dropdown') return custom_field.options.find(option => option.value === value)?.label 
            if (custom_field && custom_field.type === 'date') return moment(value).format('MMM D, YYYY')

            switch (prop_key) {
                case 'customer_id': {
                    const customer = this.customer_list.find(item => item.id === value)
                    return customer ? customer.name : ''
                }
                case 'partner_id': {
                    const partner = this.partner_list.find(item => item.id === value)
                    return partner ? partner.name : ''
                }
                case 'stage_id': {
                    const stage = this.stage_list.find(item => item.id === value)
                    return stage ? stage.name : ''
                }
                case 'result_id': {
                    const result = this.meta_list.find(item => item.id === value)
                    return result ? result.value : ''
                }
                case 'workspace_id': {
                    const workspace = this.workspace_search_list.find(item => item.id === value)
                    return workspace ? workspace.title : ''
                }
                case 'workspace_id': {
                    const workspace = this.workspace_search_list.find(item => item.id === value)
                    return workspace ? workspace.title : ''
                }
                case 'competitor_id': {
                    const competitor = this.competitor_list.find(item => item.id === value)
                    return competitor ? competitor.name : ''
                }
                case 'territory_id': {
                    const territory = this.territory_list.find(item => item.id === value)
                    return territory ?? ''
                }
                case 'deal_amount': return this.local_simple_currency_formatter(value)
                // Tags
                case 'label_tag_id':
                case 'product_tag_id':
                case 'platform_tag_id': {
                    const tag = this.system_tag_list.find(item => item.id === value)
                    return tag ?? ''
                }
                // Date values
                case 'start_date':
                case 'due_date':
                case 'estimated_start_date':
                case 'estimated_end_date': return moment(value).format('MMM D, YYYY')
                default: return value
            }
        },

        local_simple_currency_formatter (value) {
            value = value.replace(/,/g, '')
            const formatted = Intl.NumberFormat(this.local_currency_format).format(value)
            value = isNaN(value) ? value : ((!formatted || formatted === '0') ? '' : formatted)
            return this.local_currency_symbol + value
        },

        local_format_date (date, range = false, simple = false) {
            if (range) return moment(date).format('YYYY-MM-DD')
            if (simple) return moment(date).format('MMM D')

            return date ? moment(date).format('MMM D, YYYY') : null
        },

        local_get_formatted_value (value) {
            if (value === 'due_date') return 'End Date'
            return value ? value.split('_').join(' ') : ''
        },

        local_get_formatted_field (key) {
            switch (key) {
                case 'workspace_id': return 'Title'
                case 'due_date': return 'End Date'
                case 'timeline_field': return 'Field'
                case 'timeline_date_option': return 'Date Option'
                default: return key.split('_').join(' ')
            }
        }
    }
}
</script>

<style>

</style>

import { render, staticRenderFns } from "./GlobalRichTextEditor.vue?vue&type=template&id=684ff519&scoped=true"
import script from "./GlobalRichTextEditor.vue?vue&type=script&lang=js"
export * from "./GlobalRichTextEditor.vue?vue&type=script&lang=js"
import style0 from "./GlobalRichTextEditor.vue?vue&type=style&index=0&id=684ff519&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684ff519",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})

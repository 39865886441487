<template>
	<a-main class="mt-2">
        <div class="grey darken-4 pa-2 u-rounded-corners-lg">
            <a-btn color="white" text :to="{name: 'appstore-salesforce-microservices-workflows'}" exact>
                <a-icon class="mr-1">arrow_back</a-icon> Back to workflows
            </a-btn>
        </div>
        <div v-if="pageLoading" class="mb-12 mt-8">
            <div class="u-flex-center-y">
                <loader-template height="50" width="100%" class="u-rounded-corners-lg"></loader-template>
                <loader-template height="32" width="60" class="ml-4 u-rounded-corners-full"></loader-template>
            </div>

            <div class="u-flex-center-y mt-8">
                <loader-template height="40" width="120" class="u-rounded-corners-lg"></loader-template>
                <a-spacer></a-spacer>
                <div class="u-flex-center-y">
                    <loader-template height="40" width="140" class="u-rounded-corners-lg"></loader-template>
                    <loader-template height="40" width="140" class="ml-5 u-rounded-corners-lg"></loader-template>
                </div>
            </div>

            <!-- Conditions Loader -->
            <a-sheet v-for="i in 2" :key="i" class="u-shadow u-rounded-corners-lg mt-8">
                <div class="u-flex-center-y px-6 py-5">
                    <loader-template height="50" width="50" class="u-rounded-corners-full"></loader-template>
                    <div class="ml-4">
                        <loader-template height="16" width="140" class="mb-2 u-rounded-corners-lg"></loader-template>
                        <loader-template height="16" :width="Math.floor(Math.random() * 300) + 200" class="u-rounded-corners-lg"></loader-template>
                    </div>
                </div>
                <a-divider></a-divider>
                <div class="px-6 py-5">
                    <div class="u-flex-center-y" style="columnGap: 24px;" v-for="m in 3" :key="m" :class="[{ 'mt-6': m !== 1 }]">
                        <loader-template height="40" style="flex: 0 0 50px;" class="u-rounded-corners-lg"></loader-template>
                        <loader-template height="40" style="flex: 1 0 200px;" class="u-rounded-corners-lg"></loader-template>
                        <loader-template height="40" v-if="i === 1" style="flex: 1 0 200px;" class="u-rounded-corners-lg"></loader-template>
                        <loader-template height="40" style="flex: 1 0 200px;" class="u-rounded-corners-lg"></loader-template>
                        <loader-template height="40" style="flex: 0 0 50px;" class="u-rounded-corners-lg"></loader-template>
                    </div>
                </div>
                <a-divider></a-divider>
                <div class="u-flex-center-y px-6 py-5">
                    <div class="u-flex-center-y">
                        <loader-template height="32" width="140" class="u-rounded-corners-lg"></loader-template>
                        <loader-template height="32" width="140" class="ml-5 u-rounded-corners-lg"></loader-template>
                    </div>
                    <a-spacer></a-spacer>
                    <loader-template height="32" width="100" class="u-rounded-corners-lg"></loader-template>
                </div>
            </a-sheet>
        </div>

        <div v-if="!pageLoading">
            <div class="py-8">
                <div class="u-relative u-flex-center-y" :style="{ minWidth: 'auto', maxWidth: '80%' }">
                    <input
                        v-model="localWorkflowTitle"
                        placeholder="Enter Workflow Title"
                        background-color="transparent"
                        ref="refWorkflowTitle"
                        class="c-workflow-title md-heading-5 grey--text text--darken-3 u-wfull u-absolute"
                        :disabled="true"
                        style="left: 0px; transform: translateY(-50%); top: 50%; outline: none;"
                    />
                    <span class="md-heading-5 u-wfull" style="color: transparent; visibility: hidden; white-space: pre; pointer-events: none;">{{ localWorkflowTitle }}</span>
                </div>
                <p class="body-1 mt-1 ma-0" v-if="microservice_workflow_item.description"> {{ microservice_workflow_item.description }} </p>
            </div>

            <div class="px-5 py-4 u-rounded-corners-lg green lighten-4">
                <div class="u-flex-center-y">
                    <a-icon color="green darken-1" size="20">check_circle</a-icon>
                    <h3 class="md-subtitle-1 font-weight-medium ml-2 green--text text--darken-3">
                        This workflow is currently active and running.
                    </h3>
                </div>
            </div>

            <div class="u-flex-center-y mt-7">
                <a-btn text class="u-flex-center-y u-cursor-pointer u-shadow px-4 py-2 white u-rounded-corners" @click="$router.push({ name: 'appstore-salesforce-microservices-logs' })">
                    <a-icon size="20" color="grey darken-1" class="u-icon-nudge" style="top: 1px;">menu_open</a-icon>
                    <span class="md-body-2 grey--text text--darken-1 d-inline-block ml-2 text-uppercase font-weight-medium">View Logs</span>
                </a-btn>
            </div>

            <!-- Conditions -->
            <a-sheet class="u-shadow u-rounded-corners-lg mt-7" v-if="microservice_workflow_item && microservice_workflow_item.conditions || microservice_workflow_item && ['project.create','project.update'].includes(microservice_workflow_item.key)">
                <div class="pa-6 u-flex-center-y">
                    <div class="u-flex-center-y">
                        <a-sheet width="48" height="48" class="u-border-light u-flex-center u-rounded-corners">
                            <img class="d-block" v-if="microservice_workflow_item.from === 'salesforce'" style="width: 32px; height: 32px; object-fit: contain;" :src="require('../../assets/images/logo-salesforce.svg')" alt="Salesforce">
                            <img class="d-block" v-if="microservice_workflow_item.from === 'success'" style="width: 32px; height: 32px; object-fit: contain;" :src="require('../../assets/images/success-imgs/icon-success.svg')" alt="Success">
                        </a-sheet>
                        <div class="ml-5">
                            <h3 class="md-subtitle-1 secondary--text font-weight-bold text-uppercase">Conditions</h3>
                            <h4 class="md-subtitle-1 grey--text text--darken-3 font-weight-medium">{{ localConditionLabel ? localConditionLabel : localWorkflowTitle }}</h4>
                        </div>
                    </div>
                </div>
                 <template>
                    <a-divider></a-divider>
                    <template v-if="microservice_workflow_item && microservice_workflow_item.conditions && microservice_workflow_item.conditions.length">
                        <div class="pa-6 pt-5 pb-6" v-for="(list, index) in microservice_workflow_item.conditions" :key="index">
                            <div class="u-flex-center-y mt-1">
                                <div class="mr-6" style="width: 50px; height: 50px; flex: 0 0 auto;">
                                    <a-tooltip bottom v-if="list.condition_status === 'valid'">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="u-flex-center u-rounded-corners" style="width: 50px; height: 50px; background-color: rgb(232, 245, 233);">
                                                <a-icon color="green" size="20">check_circle</a-icon>
                                            </div>
                                        </template>
                                        <span>Valid</span>
                                    </a-tooltip>
                                    <div  v-if="list.condition_status === 'and'" class="u-flex-center">
                                        <p class="font-weight-medium md-caption-2 indigo--text text--darken-1 mb-0 mt-3">
                                            AND
                                        </p>
                                    </div>
                                </div>

                                <div class="white" style="flex: 1 0 200px; zIndex: 2;">
                                    <div class="u-border-light u-flex-center-y u-rounded-corners-lg mr-3" style="height: 50px;">
                                        <div class="u-rounded-corners-lg u-wfull">
                                            <p class="body-2 ml-2 ma-0">{{ list.condition_field }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="white" style="flex: 1 0 200px; zIndex: 2;">
                                    <div class="u-border-light u-flex-center-y u-rounded-corners-lg mr-3" style="height: 50px;">
                                        <div class="u-rounded-corners-lg u-wfull">
                                            <p class="body-2 ml-2 ma-0">{{ list.condition_operator }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="white" style="flex: 1 0 200px; zIndex: 2;" :class="[list.condition_value === null ? 'grey lighten-3' : '']">
                                    <div class="u-border-light u-flex-center-y u-rounded-corners-lg" style="height: 50px;">
                                        <div class="u-rounded-corners-lg u-wfull">
                                            <p class="body-2 ml-2 ma-0" :title="list.condition_value">{{ list.condition_value }}</p>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </template>
                    <template v-if="microservice_workflow_item && microservice_workflow_item.and_conditions && microservice_workflow_item.and_conditions.length">
                        <a-sheet color="grey lighten-2" height="1" class="u-wfull u-relative my-7">
                            <span class="u-absolute md-body-2 font-weight-medium body--text text--lighten-2 d-inline-block pa-1 px-2 grey lighten-5" style="left: 50%; top: 50%; transform: translate(-50%, -50%); z-index: 1">OR</span>
                        </a-sheet>
                        <div class="pa-6 pt-5 pb-6" v-for="(list, index) in microservice_workflow_item.and_conditions" :key="index">
                            <div class="u-flex-center-y mt-1">
                                <div class="mr-6" style="width: 50px; height: 50px; flex: 0 0 auto;">
                                    <a-tooltip bottom v-if="list.condition_status === 'valid'">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="u-flex-center u-rounded-corners" style="width: 50px; height: 50px; background-color: rgb(232, 245, 233);">
                                                <a-icon color="green" size="20">check_circle</a-icon>
                                            </div>
                                        </template>
                                        <span>Valid</span>
                                    </a-tooltip>
                                    <div  v-if="list.condition_status === 'and'" class="u-flex-center">
                                        <p class="font-weight-medium md-caption-2 indigo--text text--darken-1 mb-0 mt-3">
                                            AND
                                        </p>
                                    </div>
                                </div>

                                <div class="white" style="flex: 1 0 200px; zIndex: 2;">
                                    <div class="u-border-light u-flex-center-y u-rounded-corners-lg mr-3" style="height: 50px;">
                                        <div class="u-rounded-corners-lg u-wfull">
                                            <p class="body-2 ml-2 ma-0">{{ list.condition_field }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="white" style="flex: 1 0 200px; zIndex: 2;">
                                    <div class="u-border-light u-flex-center-y u-rounded-corners-lg mr-3" style="height: 50px;">
                                        <div class="u-rounded-corners-lg u-wfull">
                                            <p class="body-2 ml-2 ma-0">{{ list.condition_operator }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="white" style="flex: 1 0 200px; zIndex: 2;" :class="[list.condition_value === null ? 'grey lighten-3' : '']">
                                    <div class="u-border-light u-flex-center-y u-rounded-corners-lg" style="height: 50px;">
                                        <div class="u-rounded-corners-lg u-wfull">
                                            <p class="body-2 ml-2 ma-0" :title="list.condition_value">{{ list.condition_value }}</p>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </template>
                    <div class="pa-6 pt-5 pb-6" v-if="microservice_workflow_item && ['project.create','project.update'].includes(microservice_workflow_item.key)">
                        <div class="u-flex-center-y mt-1">
                            <div class="mr-6" style="width: 50px; height: 50px; flex: 0 0 auto;">
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="u-flex-center u-rounded-corners" style="width: 50px; height: 50px; background-color: rgb(232, 245, 233);">
                                            <a-icon color="green" size="20">check_circle</a-icon>
                                        </div>
                                    </template>
                                    <span>Valid</span>
                                </a-tooltip>
                            </div>

                            <div class="white" style="flex: 1 0 200px; zIndex: 2;">
                                <div class="u-border-light u-flex-center-y u-rounded-corners-lg mr-3" style="height: 50px;">
                                    <div class="u-rounded-corners-lg u-wfull">
                                        <p class="body-2 ml-2 ma-0">Type</p>
                                    </div>
                                </div>
                            </div>
                            <div class="white" style="flex: 1 0 200px; zIndex: 2;">
                                <div class="u-border-light u-flex-center-y u-rounded-corners-lg mr-3" style="height: 50px;">
                                    <div class="u-rounded-corners-lg u-wfull">
                                        <p class="body-2 ml-2 ma-0">Is not</p>
                                    </div>
                                </div>
                            </div>

                            <div class="white" style="flex: 1 0 200px; zIndex: 2;">
                                <div class="u-border-light u-flex-center-y u-rounded-corners-lg" style="height: 50px;">
                                    <div class="u-rounded-corners-lg u-wfull">
                                        <p class="body-2 ml-2 ma-0">Renewal</p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </template>
            </a-sheet>


            <!-- Mappings -->
            <a-sheet class="u-shadow u-rounded-corners-lg mt-7 mb-12">
                <div class="pa-6 u-flex-center-y">
                    <div class="u-flex-center-y">
                        <a-sheet width="48" height="48" class="u-border-light u-flex-center u-rounded-corners">
                            <img class="d-block" style="width: 32px; height: 32px; object-fit: contain;" v-if="localWorkflowFrom === 'success'" src="../../assets/images/logo-salesforce.svg" alt="Salesforce">
                            <img class="d-block" style="width: 32px; height: 32px; object-fit: contain;"  v-if="localWorkflowFrom === 'salesforce'" src="../../assets/images/success-imgs/icon-success.svg" alt="Success">
                        </a-sheet>
                        <div class="ml-5">
                            <h3 class="md-subtitle-1 secondary--text font-weight-bold text-uppercase">Mappings</h3>
                            <h4 class="md-subtitle-1 grey--text text--darken-3 font-weight-medium">{{ localWorkflowTitle }}</h4>
                        </div>
                    </div>
                </div>

                <template v-if="localMappingsList && localMappingsList.length">
                    <a-divider></a-divider>
                    <div class="pa-6 pt-5 pb-6">
                        <div class="u-flex-center-y" :class="[{ 'mt-6': index !== 0 }]" v-for="(mapping, index) in localMappingsList" :key="mapping.id">
                            <div class="mr-6" style="width: 50px; height: 50px; flex: 0 0 auto;">
                                <a-tooltip bottom v-if="!mapping.direction">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="u-flex-center u-rounded-corners" style="width: 50px; height: 50px; background-color: rgb(232, 245, 233);">
                                            <a-icon color="green" size="20">check_circle</a-icon>
                                        </div>
                                    </template>
                                    <span>Valid</span>
                                </a-tooltip>
                                <a-tooltip bottom v-if="mapping.direction === 'incoming'">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="u-flex-center grey lighten-4 u-rounded-corners" style="width: 50px; height: 50px;">
                                            <a-icon color="grey" size="20">south_west</a-icon>
                                        </div>
                                    </template>
                                    <span>Incoming</span>
                                </a-tooltip>
                                <a-tooltip bottom v-if="mapping.direction === 'outgoing'">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="u-flex-center grey lighten-4 u-rounded-corners" style="width: 50px; height: 50px;">
                                            <a-icon color="grey" size="20">north_east</a-icon>
                                        </div>
                                    </template>
                                    <span>Outgoing</span>
                                </a-tooltip>
                                <a-tooltip bottom v-if="mapping.direction === 'two-way'">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="u-flex-center grey lighten-4 u-rounded-corners" style="width: 50px; height: 50px;">
                                            <a-icon color="grey" size="20">open_in_full</a-icon>
                                        </div>
                                    </template>
                                    <span>Two way</span>
                                </a-tooltip>
                            </div>

                            <div class="white" style="flex: 1 0 200px; zIndex: 2;">
                                <div class="u-border-light u-flex-center-y u-rounded-corners-lg">
                                    <div class="u-flex-center pl-1" style="width: 44px; height: 60px;">
                                        <template v-if="localWorkflowFrom === 'salesforce'">
                                            <img :src="require('../../assets/images/logo-salesforce.svg')" alt="Salesforce" style="display: block; max-width: 24px; max-height: 24px;">
                                        </template>
                                        <template v-if="localWorkflowFrom === 'success'">
                                            <img :src="require('../../assets/images/success-imgs/icon-success.svg')" alt="Success" style="display: block; max-width: 24px; max-height: 24px;">
                                        </template>
                                    </div>
                                    <a-divider class="grey lighten-2 mx-1" vertical></a-divider>
                                    <template v-if="localWorkflowFrom === 'salesforce'">
                                        <div class="u-rounded-corners-lg u-wfull pa-2">
                                           <p class="body-2 ml-1 ma-0" :title="mapping.salesforce_field_label">{{ mapping.salesforce_field_label | truncateText(120) }}</p>
                                           <span class="body-2 ml-1 grey--text text--darkan-1" :title="mapping.salesforce_field_name">
                                                {{ mapping.salesforce_field_name | truncateText(120) }}
                                            </span>
                                        </div>
                                    </template>
                                    <template v-if="localWorkflowFrom === 'success'">
                                        <div class="u-rounded-corners-lg u-wfull pa-2">
                                           <p class="body-2 ml-1 ma-0" :title="mapping.success_field_label">{{ mapping.success_field_label | truncateText(120) }}</p>
                                           <span class="body-2 ml-1 grey--text text--darkan-1" :title="mapping.success_field_name">
                                                {{ mapping.success_field_name | truncateText(120) }}
                                            </span>
                                        </div>
                                    </template>
                                </div>
                            </div>

                            <div class="c-connector u-relative u-flex-center-y grey lighten-1" style="width: 72px; height: 4px;"></div>

                            <div class="white" style="flex: 1 0 200px; zIndex: 2;">
                                <div class="u-border-light u-flex-center-y u-rounded-corners-lg">
                                    <div class="u-flex-center pl-1" style="width: 44px; height: 60px;">
                                        <template v-if="localWorkflowTo === 'success'">
                                            <img :src="require('../../assets/images/success-imgs/icon-success.svg')" alt="Success" style="display: block; max-width: 24px; max-height: 24px;">
                                        </template>
                                        <template v-if="localWorkflowTo === 'salesforce'">
                                            <img :src="require('../../assets/images/logo-salesforce.svg')" alt="Salesforce" style="display: block; max-width: 24px; max-height: 24px;">
                                        </template>
                                    </div>
                                    <a-divider class="grey lighten-2 mx-1" vertical></a-divider>
                                    <template v-if="localWorkflowTo === 'success'">
                                        <div class="u-rounded-corners-lg u-wfull pa-2">
                                           <p class="body-2 ml-1 ma-0" :title="mapping.success_field_label">{{ mapping.success_field_label | truncateText(120) }}</p>
                                           <span class="body-2 ml-1 grey--text text--darkan-1" :title="mapping.success_field_name">
                                                {{ mapping.success_field_name | truncateText(120) }}
                                            </span>
                                        </div>
                                    </template>
                                    <template v-if="localWorkflowTo === 'salesforce'">
                                        <div class="u-rounded-corners-lg u-wfull pa-2">
                                           <p class="body-2 ml-1 ma-0" :title="mapping.salesforce_field_label">{{ mapping.salesforce_field_label | truncateText(120) }}</p>
                                           <span class="body-2 ml-1 grey--text text--darkan-1" :title="mapping.salesforce_field_name">
                                                {{ mapping.salesforce_field_name | truncateText(120)}}
                                            </span>
                                        </div>
                                    </template>
                                </div> 
                            </div>
                        </div>
                    </div>
                </template>
            </a-sheet>
        </div>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mixinLoading from '@/mixins/mixin-module-loading-setup'

export default {
    mixins: [mixinLoading],

    data () {
        return {
            pageLoading: false,
            dialogCancelChanges: false,
            dialogLeavePageModal: false,
            showTitleEditIcon: true,
            workflowTitle: null,
            workflowUpdateDebounce: null,
            cancelModuleType: null,
            pageToLeave: null,
            sourceFields: {},
            destinationFields: {},
            metaRefreshModule: [],

            /* Mappings */
            removedMappings: [],
            mappingsListCopy: [],
            mappingsList: [],
            mappingParams: {
                order: 1,
                field_name: null,
                field_source: null,
                value: null,
                type: 'new'
            },

            /* Conditions */
            conditionsListCopy: [],
            conditionsList: [],
            conditionChanges: false,
            clearConditionChangesTrigger: null,
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localWorkflowTitle() {
            return this.microservice_workflow_item ? this.microservice_workflow_item.label : 'Untitle Workflow'
        },

        localConditionLabel() {
            return this.microservice_workflow_item && this.microservice_workflow_item.condition_label ? this.microservice_workflow_item.condition_label : null
        },

        localMappingsList() {
            return this.microservice_workflow_item.mapping && this.microservice_workflow_item.mapping.length ? this.microservice_workflow_item.mapping : []
        },

        localWorkflowFrom() {
            return this.microservice_workflow_item ? this.microservice_workflow_item.from : ''
        },

        localWorkflowTo() {
            return this.microservice_workflow_item ? this.microservice_workflow_item.to : ''
        },

        localSalesforceMicroserviceReportUrl() {
            return this.user_self && this.user_self.organization && this.user_self.organization.salesforce_microservice_report_url
        },

        localSalesforceMicroserviceReportToken() {
            return this.user_self && this.user_self.organization && this.user_self.organization.salesforce_microservice_report_token
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('MicroServiceWorkflow', {
            microservice_workflow_item: 'item',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async localIndex() {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.$router.replace({ name: 'errors-unauthorized' })
            }

            this.pageLoading = true
            await this.microservice_workflow_clear_item()
            await this.microservice_workflow_show_key(
                { 
                    url: this.localSalesforceMicroserviceReportUrl,
                    workflow_id: this.$route.params.workflow_id,  
                    token: this.localSalesforceMicroserviceReportToken
                }
            )
            this.pageLoading = false
        },

        ...mapActions('MicroServiceWorkflow', {
            microservice_workflow_show_key: 'show_key',
            microservice_workflow_clear_item: 'clear_item',
        }),
    },
}
</script>

<style lang="scss">
    .editor-title .v-input__slot {
        padding: 0 !important;
    }
    .editor-title .v-input__control {
        min-height: 51px !important;
    }
    .editor-title input {
        font-family: "Inter", sans-serif !important;
        font-size: 2.125rem !important;
        max-height: 40px !important;
        line-height: 40px !important;
        letter-spacing: 0.0073529412px !important;
    }
    .c-connector {
        &::before {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: #bdbdbd;
            border-radius: 100%;
            left: -6px;
        }
        &::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: #bdbdbd;
            border-radius: 100%;
            right: -6px;
        }
    }
</style>

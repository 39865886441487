<template>
    <v-list-item-avatar v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </v-list-item-avatar>
</template>

<script>
import { VListItemAvatar } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VListItemAvatar }
}
</script>

<template>
    <div>
        <a-tooltip bottom offset-y :disabled="!hasError">
            <template v-slot:activator="{ on }">
                <div v-on="on">
                    <a-autocomplete
                        v-model="searchTag"
                        :items="tagsList"
                        item-text="label"
                        item-value="id"
                        :append-icon="null"
                        :search-input.sync="searchTagApi"
                        @blur="localBlurTagField"
                        @keyup.delete="{}"
                        @keyup.enter="localSetTag(searchTag, 'onEnter')"
                        :loading="isLoading"
                        placeholder="Search tag"
                        class="u-rounded-corners u-hfull md-body-2 u-overflow-hidden"
                        :class="[{ 'c-wiggle-short u-border-error': hasError }]"
                        :hide-no-data="!$can('tags.store')"
                        :no-data-text="$can('tags.store') ? (isLoading ? 'Searching...' : 'No data available') : 'No data available'"
                        solo flat hide-details return-object auto-select-first dense autofocus hide-selected
                    >
                        <template #selection="{ item }">
                            <g-tags :tag="item" hide-clear-icon></g-tags>
                        </template>
                        <template #item="{ item }">
                            <div v-if="item && item.id === 'new' && $can('tags.store')" style="height: 32px" class="u-flex-center-y u-cursor-pointer" @click="localSetTag(item, 'onNew')">
                                <a-icon size="20" color="indigo darken-1">add</a-icon>
                                <span :class="['md-body-2 indigo--text text--darken-1 ml-1']">Create <span class="font-weight-bold">{{ item.label | truncateText(30) }}</span></span>
                            </div>
                            <div v-else-if="item && item.id === 'new' && !$can('tags.store')" @click="localBlurTagField()" style="height: 32px" class="u-flex-center-y">
                                No Data Found
                            </div>
                            <div v-else-if="item && item.id === 'no-data'" style="height: 32px" class="u-flex-center-y">
                                <span :class="['md-body-2 body--text text--lighten-1']" v-if="$can('tags.store')">{{ item.label }}</span>
                                <span :class="['md-body-2 body--text text--lighten-1']" v-else>No Data Found</span>
                            </div>
                            <template v-else>
                                <div @click="localSetTag(item, 'onSelect')">
                                    <g-tags :tag="item" hide-clear-icon></g-tags>
                                </div>
                            </template>
                        </template>
                    </a-autocomplete>
                </div>
            </template>
            <span>{{ error }}</span>
        </a-tooltip>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        item: {
            type: Object
        },
        isOpen: {
            type: Boolean
        },
        tagType: {
            type: String
        },
        error: {
            type: String
        },
        hasError: {
            type: Boolean
        },
        isLoading: {
            type: Boolean
        },
    },

    data () {
        return {
            searchTag: '',
            searchTagApi: '',
            tagSearchTimeout: null,
            tagsList: [],
        }
    },

    watch: {
        searchTag (val) {
            if (!val || (val && !val.name)) {
                this.searchTagApi = ''
                const index = this.tagsList.findIndex(item => item.id === 'new')
                if (index !== -1) this.tagsList.splice(index, 1)
            }
        },

        searchTagApi (val) {
            if (!val || (val && !val.trim())) {
                const index = this.tagsList.findIndex(item => item.id === 'new')
                if (index !== -1) this.tagsList.splice(index, 1)
            }
            this.localTriggerTagSearch(val)
        },
    },

    computed: {
        ...mapState('Tag', {
            tag_list: 'list'
        }),
    },

     methods: {
         async localTriggerTagSearch (searchText) {
            if (!searchText || (searchText && !searchText.trim())) {
                this.localResetLoading('tag-search')
                return clearTimeout(this.tagSearchTimeout)
            }
            this.localSetLoading('tag-search')
            await this.localResetTag()
            this.tagSearchTimeout = setTimeout(async () => this.localTagSearch(searchText), 500)
        },

        async localTagSearch (searchText) {
            const hasPermission = this.$can('projects.update-only') && this.$can('tags.index')
            if (!hasPermission) return this.localResetLoading('tag-search')
            await this.tag_index({
                count: 20,
                'fielter[type]': this.tagType.toLowerCase(),
                'filter[label_filter]': searchText ? searchText.toLowerCase() : '',
                'fields[tags]': 'id,label,color,type',
            })

            this.localSetTagList(hasPermission, searchText)
            clearTimeout(this.tagSearchTimeout)
            this.localResetLoading('tag-search')
        },

        localSetTagList (permission, searchText) {
            const noData = permission ? { id: 'new', label: searchText } : { id: 'no-data', label: 'No Data Found' }
            const projTags = this.item.tags ?? []
            const filteredList = this.tag_list.filter(item => {
                const index = projTags.findIndex(i => i.id === item.id)
                return (index !== -1) || (item.type !== this.tagType.toLowerCase()) ? null : item
            })
            this.tagsList = filteredList.length ? _.cloneDeep(filteredList) : [noData]
        },

        async localResetTag () {
            this.tagsList = []
            await this.tag_clear()
            clearTimeout(this.tagSearchTimeout)
        },

        localBlurTagField () {
            this.searchTag = ''
            this.searchTagApi = ''
            this.localResetError('tag-create')
            this.localResetLoading('tag-create')
            this.$emit('blur', true)
        },

         localSetTag (item, type) {
             this.$emit('update', item, this.localBlurTagField)
         },

        /* Extras */
         localSetLoading (type) {
             this.$emit('setLoading', type)
         },

         localResetLoading (type) {
             this.$emit('clearLoading', type)
         },

         localResetError (type) {
             this.$emit('clearError', type)
         },

         ...mapActions('Tag', {
             tag_index: 'index',
             tag_clear: 'clear',
         })
    }
}
</script>

<style lang="scss" scoped></style>

<template>
    <div class="mt-4">
        <ol>
            <li v-for="route in routeList" :key="route.name" class="mb-2">
                <a-icon
                    :outlined="route._isIconOutlined"
                    size="20"
                    color="grey darken-1"
                    class="mr-2"
                >
                    {{ route._icon }}
                </a-icon>
                <span>{{ route.meta ? route.meta.label : '' }}</span>
            </li>
        </ol>
    </div>
</template>

<script>
import { ValidRoutes } from '../../helpers/helper-get-valid-routes'

export default {
    data () {
        return {
            routeList: []
        }
    },

    mounted () {
        this.localRoutes()
    },

    methods: {
        localRoutes () {
            setTimeout(() => {
                this.routeList = []
                const list = this.$router.getRoutes().map(route => {
                    const routesList = ValidRoutes().get()
                    const index = routesList.findIndex(validRoute => validRoute.name === route.name)
                    if (index === -1) return false
                    return {
                        ...route,
                        _type: 'routes',
                        _icon: routesList[index]?.icon || 'home',
                        _isIconOutlined: routesList[index].iconOutlined
                    }
                }).filter(Boolean)

                console.log({ list })
                this.routeList = list
            }, 100)
        }
    }
}
</script>

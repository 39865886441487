<template>
	<a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="py-6 pt-2 px-0 mb-10">
            <g-page-header
                 title="Roles"
                subtitle="Manage roles in the system. You can customize the permission for each action allowed."
                icon="verified_user"
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
            >
                <template v-slot:action>
                    <a-btn color="primary" class="elevation-0 mx-0" dark @click="local_role_create()">
                        <a-icon class="mr-2" color="white" size="18">add</a-icon>
                        Create New Role
                    </a-btn>
                </template>
            </g-page-header>

            <!-- Loading Indicator -->
            <a-layout class="pb-5" v-if="local_loading">
                <a-flex xs12>
                    <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching roles...</h3>
                </a-flex>
            </a-layout>

            <!-- Internal and External roles -->
            <a-layout class="pb-5" v-else>
                <a-flex xs12>
                    <!-- Internal Roles -->
                    <div class="u-flex align-center mb-4">
                        <h2 class="body-2 grey--text text--darken-1 font-weight-medium text-uppercase">Internal</h2>
                        <a-divider class="mb-1 ml-4"></a-divider>
                    </div>
                    <draggable v-model="role_list" :options="{handle: '.js-drag-handle'}">
                        <template v-for="role in role_list">
                            <a-card v-if="role && role.scope === 'internal'" class="u-elevation-custom-1" :key="role.id">
                                <a-card-text class="pa-0 mb-2">
                                    <a-container container--fluid grid-list-xl class="px-5">
                                        <a-layout wrap align-center>
                                            <a-flex shrink class="px-0">
                                                <a-icon class="grey--text text--lighten-1 u-cursor-pointer js-drag-handle">drag_indicator</a-icon>
                                            </a-flex>
                                            <a-flex shrink xs6 xl9>
                                                <h2 class="text-subtitle-2 font-weight-medium">{{ role.name }} </h2>
                                                <span class="text-body-2 grey--text text--darken-1 d-inline-block" v-if="role.description">{{ role.description }}</span>
                                            </a-flex>
                                            <a-spacer></a-spacer>
                                            <a-flex shrink>
                                                <a-chip class="mr-4" color="grey lighten-2" label small>{{ (role.source && role.source.name) ? role.source.name : '–' }}</a-chip>
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <a-btn class="mr-2" color="grey darken-2" @click="local_change_default(role)" v-on="on" text icon small>
                                                            <a-icon small v-if="role.is_default === 1">star</a-icon>
                                                            <a-icon small v-else>star_border</a-icon>
                                                        </a-btn>
                                                    </template>
                                                    <span>Default Role</span>
                                                </a-tooltip>
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <a-btn class="mr-2" color="grey darken-2" exact :to="{name: 'settings-roles-view', params: {id: role.id}}" v-on="on" text icon small>
                                                            <a-icon small>verified_user</a-icon>
                                                        </a-btn>
                                                    </template>
                                                    <span>Manage Permissions</span>
                                                </a-tooltip>
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <a-btn color="grey darken-2" @click="local_role_edit(role.id)" v-on="on" text icon small>
                                                            <a-icon small>edit</a-icon>
                                                        </a-btn>
                                                    </template>
                                                    <span>Edit Role</span>
                                                </a-tooltip>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-card-text>
                            </a-card>
                        </template>
                    </draggable>

                    <!-- External Roles -->
                    <div class="u-flex align-center mt-10 mb-4">
                        <h2 class="body-2 grey--text text--darken-1 font-weight-medium text-uppercase">External</h2>
                        <a-divider class="mb-1 ml-4"></a-divider>
                    </div>
                    <a-card class="u-elevation-custom-1" v-for="role in external_roles" :key="role.id">
                        <a-card-text class="pa-0 mb-2">
                            <a-container container--fluid grid-list-xl class="px-5">
                                <a-layout wrap align-center>
                                    <a-flex shrink xs6 xl9>
                                        <h2 class="text-subtitle-2 font-weight-bold">{{ role.name }} </h2>
                                        <span class="text-body-2 grey--text text--darken-1 d-inline-block" v-if="role.description">{{ role.description }}</span>
                                    </a-flex>
                                    <a-spacer></a-spacer>
                                    <a-flex shrink>
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <a-btn class="mr-2" color="grey darken-2" exact :to="{name: 'settings-roles-view', params: {id: role.id}}" v-on="on" text icon small>
                                                    <a-icon small>verified_user</a-icon>
                                                </a-btn>
                                            </template>
                                            <span>Manage Permissions</span>
                                        </a-tooltip>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Create/Edit role form -->
        <a-dialog v-model="dialog_role_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>verified_user</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (role_form_mode == 'add') ? 'Create New Role' : 'Edit Existing Role' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (role_form_mode == 'add') ? 'You can add new role to the system.' : 'You can edit existing role in the system.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                    <a-text-field
                                        v-model="role_item.name"
                                        placeholder="Enter Role Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="role_response.server && role_response.server.errors && role_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ role_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the role.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Description</label>
                                    <a-textarea
                                        v-model="role_item.description"
                                        placeholder="Enter Description"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-textarea>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="role_response.server && role_response.server.errors && role_response.server.errors.description">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ role_response.server.errors.description[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        You can summarize what kind of actions this role can take.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Type</label>
                                    <a-autocomplete
                                        v-model="role_item.source_id"
                                        :items="local_internal_roles"
                                        item-text="name"
                                        item-value="id"
                                        placeholder="Select Type"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-autocomplete>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="role_response.server && role_response.server.errors && role_response.server.errors.source_id">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ role_response.server.errors.source_id[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Default permissions will be applied based on the type selected.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_role_store()" :loading="loading" :disabled="loading">Save Role</a-btn>
                                    <a-btn class="elevation-0 px-3 ma-0 ml-3" color="grey" text @click="dialog_role_form = !dialog_role_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            dialog_role_form: false,
            external_roles: [],
            local_loading: true
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Roles', to: '/settings/roles', exact: true})
            return breadcrumbs
        },
        role_list: {
            get() {
                return this.$store.state.Role.list
            },
            set(list) {
                this.local_role_reorder(list)
            }
        },
        local_internal_roles() {
            return this.system_role_list.filter(item => item.scope === 'internal')
        },
        ...mapState('Role', {
            role_source_list: 'source_list',
            role_item: 'item',
            role_form_mode: 'form_mode',
            role_response: 'response',
        }),
        ...mapState('SystemRole', {
            system_role_list: 'list',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
            user_list: 'list'
        }),
    },
    methods: {
        async local_index() {
            await this.role_index({ 'include': 'source', 'sort': 'order' })
            this.external_roles = this.role_list.filter(role => role.scope === 'external')
            this.local_loading = false
        },
        async local_role_edit(id) {
            await this.role_select({id: id})
            this.dialog_role_form = true
            await this.system_role_index()
        },
        async local_role_create() {
            await this.role_clear_item()
            this.dialog_role_form = true
            await this.system_role_index()
        },
        async local_role_store() {
            if (this.role_form_mode === 'add') {
                await this.role_store({ ...this.role_item, order: -1 })
                if (this.role_response.status === 'success') {
                    await this.role_index({ 'include': 'source' })
                    this.dialog_role_form = false
                }
            } else {
                await this.role_update({
                    id: this.role_item.id,
                    name: this.role_item.name,
                    description: this.role_item.description,
                    source_id: this.role_item.source_id
                })
                if (this.role_response.status === 'success') {
                    if (this.role_item.id === this.user_self.role_id ) this.user_me()
                    this.dialog_role_form = false
                }
            }
        },
        async local_role_destroy(id) {
            await this.role_destroy({id: id})
        },
        async local_role_reorder(list) {
            this.role_reorder({list: list})
        },
        async local_change_default(role) {
            if (role.is_default === 1) return
            await this.role_change_default({ id: role.id, state: 1 })
        },
        ...mapActions('Role', {
            role_index: 'index',
            role_source_index: 'source_index',
            role_store: 'store',
            role_change_default: 'update_default',
            role_update: 'update',
            role_select: 'select',
            role_destroy: 'destroy',
            role_reorder: 'reorder',
            role_clear: 'clear',
            role_clear_item: 'clear_item',
        }),
        ...mapActions('User', {
            user_me: 'me',
        }),
        ...mapActions('SystemRole', {
            system_role_index: 'index',
        }),
    },
}
</script>

<template>
    <div>
        <slot :showDialog="methodShowUsersDialog">
            <s-user-list-popup
                v-bind="$attrs"
                :item="editItem"
                :can_update="canUpdate"
                @show_add_users_dialog="methodShowUsersDialog"
                class="u-flex align-center mr-3"
                :class="deckClass"
                style="min-width: 96px; max-width: 96px; min-height: 32px;"
            ></s-user-list-popup>
        </slot>
        <a-dialog max-width="824" v-model="dataDialogUsersForm" persistent>
            <g-dialog-box-simple @close="methodCloseForm">
                <template #root-title>
                    <div class="u-flex align-center">
                        <h2 class="md-heading-5 primary--text text--darken-1 mr-3">
                            <slot name="header-title"><span class="text-capitalize">{{ moduleType.split('-').join(' ') }}</span> Assignees</slot>
                        </h2>
                    </div>
                    <p class="md-body-2 grey--text text--darken-1 mb-0">Manage users under <strong class="grey--text text--darken-2">{{ editItem.title | truncateText(75) }}</strong></p>
                </template>
                <template #body>
                    <template v-if="editItem.assignees && editItem.assignees.length">
                        <div class="mt-3">
                            <h2 class="md-subtitle-2 grey--text text--darken-1 font-weight-regular mb-2" v-if="editItem.assignees.length > 1"><strong>Total:</strong> {{ editItem.assignees.length }} members</h2>
                            <div class="u-flex align-center u-wrap">
                                <a-sheet width="248" max-width="248" class="md-subtitle-1 u-flex pl-2 mr-2 my-1 grey lighten-4" style="border-radius: 50px;" v-for="assignee in editItem.assignees" :key="assignee.id">
                                    <div class="u-flex align-center py-1">
                                        <div class="member-avatar">
                                            <g-avatar :item="assignee" :size="26"></g-avatar>
                                        </div>
                                        <div class="member-label">
                                            <h2 v-if="assignee && assignee.name" class="md-body-2 font-weigh-medium grey--text text--darken-3">{{ assignee.name | truncateText(20, '..') }}</h2>
                                            <h2 v-else class="md-body-2 font-weigh-medium grey--text text--darken-3">{{ assignee.assignee.name | truncateText(20, '..') }}</h2>
                                        </div>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <a-sheet @click="methodUserAssign(assignee.assignee.id, true)" style="border-radius: 0px 50px 50px 0px;" class="grey lighten-4 u-cursor-pointer px-2 u-flex align-center justify-center">
                                        <a-icon color="#888" class="c-icon u-rounded-corners-full" size="14">clear</a-icon>
                                    </a-sheet>
                                </a-sheet>
                            </div>
                        </div>
                    </template>
                    <div class="mt-6">
                        <s-assignee-field
                            :list="usersList"
                            :item="editItem"
                            :show-menu="dataExternalWarning.value || dataAutosaveStatus === 'loading'"
                            :is-external="dataExternalWarning.value"
                            :loading="loading || dataAutosaveStatus === 'loading'"
                            @assign="methodUserAssign"
                        ></s-assignee-field>
                    </div>
                    <template v-if="!hideExternalConfirmation">
                        <div class="my-6" v-if="dataExternalWarning.value">
                            <div class="u-flex align-center orange lighten-3 u-rounded-corners pa-2">
                                <div class="caption brown--text text--darken-1 font-weight-medium mr-2"><span class="font-weight-bold">NOTE</span>:
                                    <strong>{{ dataExternalWarning.user.name }}</strong> is an external collaborator. Adding an external collaborator will change the visibility of {{ parentType.toLowerCase() }} from <strong>private</strong> to <strong>public</strong>
                                </div>
                                <div class="u-flex align-center">
                                    <a-btn text small style="background: rgba(0,0,0,0.3)" class="u-rounded-corners ma-0 orange--text text--lighten-4 mr-2" @click="methodAddExternal(dataExternalWarning.user.id)">Okay</a-btn>
                                    <a-btn text small style="background: rgba(0,0,0,0.3)" class="u-rounded-corners ma-0 orange--text text--lighten-4" @click="methodCancelExternal()">Cancel</a-btn>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </g-dialog-box-simple>
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import { SUserListPopup, SAssigneeField } from '@/config/config-shared-components'
import SUserListPopup from './SharedUserListPopup.vue'
import SAssigneeField from './SharedAssigneeField.vue'

export default {
    props: {
        usersList: {
            type: Array,
        },
        editItem: {
            type: Object,
        },
        parentType: {
            type: String
        },
        moduleType: {
            type: String,
        },
        projectId: {
            type: String,
            default: ''
        },
        isExternal: {
            type: Boolean,
        },
        hideExternalConfirmation: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        deckClass: {
            type: String,
        },
    },

    components: { SUserListPopup, SAssigneeField },

    data () {
        return {
            search_collaborator: '',
            dataDialogUsersForm: false,
            dataAutosaveStatus: 'none',
            dataExternalWarning: { user: '', value: false },
        }
    },

    mounted () {
        this.local_reset_assignee_variables()
    },

    computed: {
        isExternalView () {
            return this.$route.meta.view === 'external' || this.user_self.scope === 'external'
        },

        ...mapState('AssigneeExternal', {
            assignee_external_item: 'item',
            assignee_external_response: 'response',
        }),

        ...mapState('Assignee', {
            assignee_item: 'item',
            assignee_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async methodUserAssign (user_id, unassign = false) {
            const assignee_param = { resource_type: this.moduleType, resource_id: this.editItem.id, user_id }
            if (this.projectId) assignee_param.project_id = this.projectId
            if (!unassign) {
                const isExternal = (this.editItem.visibility && this.editItem.visibility === 'external') ?? this.isExternal
                if (!this.dataExternalWarning.value && !isExternal) {
                    const user_external = this.local_check_user_external(user_id)
                    if (user_external && this.hideExternalConfirmation) return this.methodAddExternal(user_external.id)
                    if (user_external) return this.dataExternalWarning = { user: user_external, value: true }
                }
            }
            this.dataAutosaveStatus = 'none'
            this.dataAutosaveStatus = 'loading'
            await this.local_assign_unassign_user(unassign, user_id, assignee_param)
            this.local_reset_assignee_variables()
        },

        local_check_user_external (user_id) {
            const { user } = _.find(this.usersList, { user_id })
            return user && user.scope === 'external' ? user : false
        },

        async local_assign_unassign_user (unassign, user_id, assignee_param) {
            if (unassign) {
                this.local_remove_user(this.editItem, user_id)
                await this.local_unassign({ id: user_id })
                this.$emit('unassigned')
            } else {
                this.local_add_user(this.editItem, user_id, assignee_param)
                await this.local_assign(assignee_param)
                this.$emit('assigned')
                this.local_association_id(this.editItem, user_id, assignee_param)
            }
        },

        local_association_id (item, user_id, { resource_id }) {
            const user_item = item.assignees.find(user => user.id === user_id)
            Object.assign(user_item, { assignee: { id: (this.isExternalView ? this.assignee_external_item.id : this.assignee_item.id), resource_id, user_id } })
        },

        local_add_user (item, user_id) {
            const added_user = this.usersList.find(item => item.user_id === user_id)['user']
            item.assignees.push(Object.assign(added_user, { assignee: { user_id } }))
        },

        local_remove_user (item, assignee_id) {
            const index = item.assignees.findIndex(item => item.assignee.id === assignee_id)
            if (index !== -1) item.assignees.splice(index, 1)
        },

        local_reset_assignee_variables () {
            this.local_assign_clear()
            this.local_collaborator_item()
            this.search_collaborator = ''
            this.dataAutosaveStatus = 'done'
            setTimeout(() => this.dataAutosaveStatus = 'none', 100)
        },

        local_collaborator_item () {
            const search = this.search_collaborator
            return _.filter(this.usersList, function(list) {
                     return list.user.name.toLowerCase().indexOf(search.toLowerCase())>=0;
                });
        },

        async methodAddExternal (user_id) {
            this.$emit('change-visibility', 'external')
            this.methodAssignExternalUser(user_id, true)
            this.dataExternalWarning = { user: '', value: false }
        },

        async methodAssignExternalUser (user_id, external = false) {
            const assignee_param = { resource_type: this.moduleType, resource_id: this.editItem.id, user_id }
            if (this.projectId) assignee_param.project_id = this.projectId

            this.local_add_user(this.editItem, user_id, assignee_param)
            await this.local_assign(assignee_param)
            this.$emit('assigned', external ?? null)
            this.local_association_id(this.editItem, user_id, assignee_param)
        },

        methodCancelExternal () {
            this.dataExternalWarning = { user: '', value: false }
        },

        methodCloseForm () {
            this.dataExternalWarning = { user: '', value: false }
            this.dataDialogUsersForm = false
            this.methodEmitDialogStatus()
        },

        methodShowUsersDialog () {
            this.dataAutosaveStatus = 'none'
            this.dataDialogUsersForm = true
            this.methodEmitDialogStatus()
        },

        methodEmitDialogStatus () {
            this.$emit('open', this.dataDialogUsersForm)
        },

        async local_assign (assign_param) {
            if (!this.isExternalView) return await this.assignee_store(assign_param)
            await this.assignee_external_store(assign_param)
        },

        async local_unassign (assign_param) {
            if (!this.isExternalView) return await this.assignee_destroy(assign_param)
            await this.assignee_external_destroy(assign_param)
        },

        async local_assign_clear () {
            if (!this.isExternalView) return await this.assignee_clear()
            await this.assignee_external_clear()
        },

        ...mapActions('AssigneeExternal', {
            assignee_external_store: 'store',
            assignee_external_clear: 'clear',
            assignee_external_destroy: 'destroy',
        }),

        ...mapActions('Assignee', {
            assignee_store: 'store',
            assignee_clear: 'clear',
            assignee_destroy: 'destroy',
        }),
    }
}
</script>

<template>
    <a-main>
        <a-container grid-list-xl container--fluid mb-10 pa-0>
            <g-page-header
                title="Manage Team Members"
                subtitle="You can invite team members into the system and add them as collaborators in projects."
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                icon="people_alt"
                class="pr-0"
                iconOutlined
            >
                <template v-slot:action v-if="$route.name !== 'teams-disabled'">
                    <a-btn color="primary" class="elevation-0 mx-0" dark @click="local_create()">
                        <a-icon class="mr-2" color="white" size="18">add</a-icon>
                        Create New User
                    </a-btn>
                </template>
            </g-page-header>
            <router-view></router-view>
        </a-container>

        <!-- User create form -->
        <a-dialog v-model="dialog_user_form" max-width="600" scrollable persistent>
            <g-dialog-box hide-close-icon icon="person" icon-size="24">
                <template #title>
                    Create New User
                </template>
                <template #subtitle>
                    You can add new team member to collaborate with.
                </template>
                <template #body>
                    <p-user-edit-body
                        :manager-list="user_total_list"
                        :form-mode="user_form_mode"
                    ></p-user-edit-body>
                </template>
                <template #footer>
                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_store()" :loading="loading" :disabled="loading">Save User</a-btn>
                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_user_form = !dialog_user_form" :disabled="loading">Cancel</a-btn>
                </template>
            </g-dialog-box>
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { TabTeam } from '@/config/config-tab-components'
import { PUserEditBody } from './Partials'

export default {
    components: {
        TabTeam, PUserEditBody
    },

    data () {
        return {
            dialog_user_form: false,
            local_manager_search: null,
        }
    },

    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Teams', to: '/teams', exact: true})
            return breadcrumbs
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_list: 'list',
            user_total_list: 'total_list',
            user_item: 'item',
            user_meta: 'meta',
            user_form_mode: 'form_mode',
            user_response: 'response',
        }),

        ...mapState('Role', {
            role_list: 'list',
        }),
    },

    methods: {
        async local_create() {
            await this.user_clear_item()
            this.dialog_user_form = true
        },

        async local_store() {
            let manager_id = null
            if (this.user_item.manager_id) manager_id = this.user_item.manager_id
            if (this.user_item.manager) {
                manager_id = this.user_item.manager.id
                delete this.user_item.manager
            }

            if (this.user_form_mode === 'add') await this.user_store({ ...this.user_item, manager_id })
            if (this.user_response.status !== 'success') return this.$notify('error', 'Something went wrong! Please re-check the fields and try again.')

            this.dialog_user_form = false
            this.$notify('success', 'User created successfully!')
            this.local_user_show(this.user_item.id, this.user_form_mode, { 'include': 'role,manager,territories' })
            if (this.user_form_mode === 'add') {
                this.user_meta.total++
                this.user_meta.to++
            }
        },

        async local_user_show(id, mode, include = null) {
            await this.user_show({id: id ,mode: mode, params: include })
        },

        ...mapActions('User', {
            user_index: 'index',
            users_total_index: 'total_index',
            user_show: 'show',
            user_store: 'store',
            user_clear: 'clear',
            user_clear_item: 'clear_item',
        }),
        ...mapActions('Role', {
            role_index: 'index',
            role_clear: 'clear',
            role_clear_item: 'clear_item',
        }),
    }
}
</script>

<style lang="scss" scoped>

</style>

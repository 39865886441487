<template>
    <div>
        <InternalNotes v-if="local_user_is_external" />
        <ExternalNotes v-else />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import InternalNotes from '@/pages/Projects/ProjectsNotes.vue'
import ExternalNotes from '@/pages/External/ExternalProjects/ExternalProjectsNotes.vue'

export default {
    components: {
        ExternalNotes,
        InternalNotes
    },

    computed: {
        local_user_is_external() {
            return this.user_self && this.user_self.scope === 'internal'
        },
        ...mapState('User', {
            user_self: 'self'
        })
    }

}
</script>

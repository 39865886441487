<template>
    <a-main class="mb-16">
        <a-layout align-start v-if="$can('templates.store')">
            <a-flex>
                <a-form @submit.prevent="local_group_store()">
                    <a-text-field
                        v-model="template_group_name"
                        placeholder="Add new document group"
                        prepend-inner-icon="add"
                        class="u-elevation-custom-1"
                        :loading="loading"
                        solo flat hide-details autofocus
                    ></a-text-field>
                </a-form>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_group_response.server && template_group_response.server.errors && template_group_response.server.errors.name && template_group_form_mode == 'add'">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ template_group_response.server.errors.name[0] }}
                </span>
            </a-flex>
        </a-layout>
         <a-responsive class="py-4 my-4" v-if="!loading && template_group_list.length == 0 && !local_loading">
            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
            <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
        </a-responsive>
        <template v-if="local_loading">
            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1">Loading..</h3>
        </template>
        <draggable v-model="template_group_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
            <div class="my-2 mt-0" :id="'document-'+template_group.id" :class="{ 'js-draggable-file-list-item': $can('templates.update') }" v-for="(template_group, index) in template_group_list" :key="template_group.id">
                <a-container container--fluid grid-list-xl class="pa-4 u-elevation-custom-1 white" :class="[template_group_expanded === index ? '' : 'u-rounded-corners']">
                    <a-layout wrap align-center>
                        <a-flex shrink class="u-cursor-pointer pr-0">
                            <a-icon class="grey--text text--darken-1 js-drag-handle u-icon-nudge" :class="{'u-opacity-30': !$can('templates.update')}">drag_indicator</a-icon>
                        </a-flex>
                        <a-flex xs7 xl9 class="u-cursor-pointer" @click="local_group_toggle(index)">
                            <h2 class="subtitle-1 u-word-wrap">{{ template_group.name }}</h2>
                            <p class="md-body-2 mb-0 grey--text text--darken-2 mt-2" v-if="template_group_expanded !== index">
                                <span>{{ template_group.template_documents_count ? template_group.template_documents_count : 0 }} Documents<span class="mx-2"></span></span>
                            </p>
                        </a-flex>
                        <a-spacer></a-spacer>
                        <a-flex shrink  v-if="$can('templates.update')">
                            <a-tooltip bottom offset-overflow>
                                <template v-slot:activator="{ on }">
                                    <a-btn v-on="on" icon class="mr-2">
                                        <a-icon size="16" class="grey--text text--darken-1">info</a-icon>
                                    </a-btn>
                                </template>

                                <div class="py-1">
                                    <p v-if="template_group.created_by" class="text-body-2 grey--text text--lighten-2 mb-0">Created by
                                        <span class="text-subtitle-2 white--text">{{ template_group.created_by.name }}</span>
                                        <span class="ml-2 text-caption">{{ local_date_output_format(template_group.created_at) }}</span>
                                    </p>

                                    <p v-if="template_group.updated_by && template_group.created_at !== template_group.updated_at" class="text-body-2 grey--text text--lighten-2 mb-0">Last updated by
                                        <span class="text-subtitle-2 white--text">{{ template_group.updated_by.name }}</span>
                                        <span class="text-caption ml-2">{{ local_date_output_format(template_group.updated_at) }}</span>
                                    </p>
                                </div>
                            </a-tooltip>
                            <template>
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a-btn small text icon v-on="on" class="ma-0" color="grey darken-1" @click="local_initiate(template_group.id, index)" :disabled="loading_file">
                                            <a-icon size="20">cloud_upload</a-icon>
                                        </a-btn>
                                    </template>
                                    <span>Upload Document</span>
                                </a-tooltip>
                                <input type="file" ref="doc_upload" name="doc_upload" class="d-none" multiple @change="local_doc_upload()" :accept="local_file_types">
                            </template>
                        </a-flex>
                        <a-flex shrink>
                            <template v-if="! $can('templates.update')">
                                <a-tooltip bottom offset-overflow>
                                    <template v-slot:activator="{ on }">
                                        <a-btn v-on="on" icon :class="[$can('templates.update') ? 'mr-2' : '']">
                                            <a-icon size="16" color="grey--text text--darken-1">info</a-icon>
                                        </a-btn>
                                    </template>

                                    <div class="py-1">
                                        <p v-if="template_group.created_by" class="text-body-2 grey--text text--lighten-2 mb-0">Created by
                                            <span class="text-subtitle-2 white--text">{{ template_group.created_by.name }}</span>
                                            <span class="ml-2 text-caption">{{ local_date_output_format(template_group.created_at) }}</span>
                                        </p>

                                        <p v-if="template_group.updated_by && template_group.created_at !== template_group.updated_at" class="text-body-2 grey--text text--lighten-2 mb-0">Last updated by
                                            <span class="text-subtitle-2 white--text">{{ template_group.updated_by.name }}</span>
                                            <span class="text-caption ml-2">{{ local_date_output_format(template_group.updated_at) }}</span>
                                        </p>
                                    </div>
                                </a-tooltip>
                            </template>
                            <a-btn icon text color="grey darken-1" @click="local_template_clone_create(template_group.id, template_group)" v-if="$can('templates.store')">
                                <a-icon size="16">content_copy</a-icon>
                            </a-btn>
                            <a-btn icon text color="grey darken-1"  @click="local_group_edit(template_group.id)" v-if="$can('templates.update')">
                                <a-icon size="16">edit</a-icon>
                            </a-btn>
                            <a-menu bottom left offset-y :close-on-content-click="false" max-width="320" v-if="$can('templates.destroy')">
                                <template v-slot:activator="{ on }">
                                    <a-btn icon text color="grey darken-1" v-on="on">
                                        <a-icon size="16">delete</a-icon>
                                    </a-btn>
                                </template>
                                <a-card>
                                    <a-card-text class="pa-4">
                                        <h2 class="md-heading-6 mb-2">Are you sure to delete this document group?</h2>
                                        <p class="md-body-2 mb-4">This will delete all documents under this template and cannot be undone.</p>
                                        <a-btn class="ma-0 elevation-0" color="red" @click="local_group_destroy(template_group.id)" :loading="loading" dark small>
                                            <a-icon size="16" left class="mr-2">delete</a-icon> Delete
                                        </a-btn>
                                    </a-card-text>
                                </a-card>
                            </a-menu>
                            <a-btn icon text color="grey darken-1" @click="local_group_toggle(index)">
                                <a-icon v-if="template_group_expanded === index">arrow_drop_up</a-icon>
                                <a-icon v-else>arrow_drop_down</a-icon>
                            </a-btn>
                        </a-flex>
                    </a-layout>
                </a-container>
                <a-divider class="grey lighten-3" v-if="template_group_expanded === index"></a-divider>
                <div v-if="template_group_expanded === index">
                    <div v-if="uploadDocsList.length" :class="[ template_document_list && template_document_list.length ? 'mb-3': '' ]">
                        <h2 class="md-subtitle-1 grey--text text--darken-2 my-2 ml-6">Uploading {{ uploadDocsList.length }} {{ uploadDocsList.length > 1 ? 'files' : 'file' }}</h2>
                        <a-card v-for="doc in uploadDocsList" class="u-elevation-custom-1 u-rounded-corners mb-2" :key="doc.last_modified">
                            <a-container container--fluid grid-list-xl class="py-3 px-2">
                                <a-layout align-center py-2>
                                    <a-flex py-0 shrink>
                                        <img src="../../assets/images/filetype-icons/unknown.svg" width="48" alt="Unkown Filetype" style="display: block;">
                                        <!-- <img v-if="doc.type" :src="require('../../assets/images/filetype-icons/'+(doc.type.split('/')[1].length > 5 ? 'xlsx' : doc.type.split('/')[1])+'.svg')" width="48" :alt="doc.type.split('/')[1]" style="display: block;"> -->
                                    </a-flex>
                                    <a-flex py-0>
                                        <h2 class="md-subtitle-1 primary--text d-block text-no-wrap text-truncate" style="max-width: 360px; line-height: 1.5 !important;">{{ doc.name }}</h2>
                                        <p class="md-body-2 grey--text text--darken-2 mb-0">Size: {{ doc.size | sizer }}</p>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-progress-linear class="u-rounded-corners" indeterminate height="1" color="teal"></a-progress-linear>
                        </a-card>
                    </div>
                    <template v-if="template_document_list.length !== 0">
                        <draggable v-model="template_document_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                            <div v-for="(template_document, index) in template_document_list" :key="template_document.id" class="grey lighten-5 u-elevation-custom-1" :class="{'js-draggable-file-list-item': $can('templates.update')}">
                                <a-card tile class="transparent u-relative" flat>
                                    <div v-if="template_document.visibility === 'external'" class="c-visibility-bar"></div>
                                    <a-divider v-if="index !== 0" class="grey lighten-3" :key="template_document.id + '-divider'"></a-divider>
                                    <a-card-text class="pa-0">
                                        <a-container container--fluid grid-list-xl class="py-4 px-6">
                                            <a-layout align-center>
                                                <a-flex shrink class="px-0" v-if="user_self.role && user_self.role.scope === 'internal'">
                                                    <a-icon class="grey--text text--lighten-1 u-cursor-pointer js-drag-handle" :class="{'u-opacity-30': !$can('templates.update')}">drag_indicator</a-icon>
                                                </a-flex>
                                                <a-flex shrink class="px-0">
                                                    <img src="../../assets/images/filetype-icons/unknown.svg" width="48" alt="Unkown Filetype" style="display: block;" v-if="template_document.extension === null">
                                                    <a-img :src="require('../../assets/images/filetype-icons/'+template_document.extension+'.svg')" width="48" :alt="template_document.extension" style="display: block;" v-else></a-img>
                                                </a-flex>
                                                <a-flex xs2 md3 lg4 xl7>
                                                    <h2 :class="[ template_document.title.length > 60 || template_document.title.length > 30 ? 'text-truncate' : '', 'md-subtitle-1 primary--text d-block text-no-wrap mb-1']" style="max-width: 700px; line-height: 1.5 !important;" v-if="template_document.title">{{ template_document.title }} <span class="md-body-2 grey--text text--darken-2">({{ template_document.name }})</span></h2>
                                                    <h2 :class="[ template_document.name.length > 60 || template_document.name.length > 30 ? 'text-truncate' : '', 'md-subtitle-1 primary--text d-block text-no-wrap mb-1' ]" style="max-width: 700px; line-height: 1.5 !important;" v-else>{{ template_document.name }}</h2>
                                                    <p class="md-body-2 grey--text text--darken-2 mb-0">Size: {{ template_document.size | sizer }}</p>
                                                </a-flex>
                                                <a-spacer></a-spacer>
                                                <a-flex shrink v-if="$can('templates.update')">
                                                    <a-menu bottom right offset-y transition="slide-y-transition" max-height="300" min-width="320" max-width="320">
                                                        <template v-slot:activator="{ on }">
                                                            <span v-on="on" class="md-body-2 grey lighten-3 pl-2 u-rounded-corners grey--text text--darken-3 d-inline-block">Copy to <a-icon>arrow_drop_down</a-icon></span>
                                                        </template>
                                                        <a-list class="u-list-condensed">
                                                            <template v-for="document_group in template_group_list">
                                                                <a-list-item :key="document_group.id"  @click="local_copy_template_document_group(document_group.id, template_group.id, template_document)">
                                                                    <a-list-item-title class="md-body-2" :title="document_group.name">
                                                                        {{ document_group.name }}
                                                                    </a-list-item-title>
                                                                </a-list-item>
                                                            </template>
                                                        </a-list>
                                                    </a-menu>
                                                </a-flex>
                                                <a-flex shrink v-if="template_group_list.length > 1 && $can('templates.update')">
                                                    <a-menu bottom left offset-y transition="slide-y-transition" max-height="300" min-width="320" max-width="320">
                                                        <template v-slot:activator="{ on }">
                                                            <span v-on="on" class="md-body-2 grey lighten-3 pl-2 u-rounded-corners grey--text text--darken-3 d-inline-block">Move to <a-icon>arrow_drop_down</a-icon></span>
                                                        </template>
                                                        <a-list class="u-list-condensed">
                                                            <template v-for="document_group in template_group_list">
                                                                <a-list-item :key="document_group.id" v-if="template_document.template_group_id !== document_group.id" @click="local_change_template_document_group(document_group.id, template_group.id, template_document)">
                                                                    <a-list-item-title class="md-body-2" :title="document_group.name">
                                                                        {{ document_group.name }}
                                                                    </a-list-item-title>
                                                                </a-list-item>
                                                            </template>
                                                        </a-list>
                                                    </a-menu>
                                                </a-flex>
                                                <a-flex shrink v-if="$can('templates.update')">
                                                    <a-tooltip bottom  v-if="template_document.visibility === 'internal' || template_document.visibility === null">
                                                        <template v-slot:activator="{ on }">
                                                            <a-btn text icon color="grey" class="mx-0" v-on="on" :disabled="loading" :loading="local_loading_get(template_document.id, 'visibility')" @click="$can('templates.update') ? local_visibility_toggle(template_document.id, template_document.visibility) : ''">
                                                                <a-icon size="16">lock</a-icon>
                                                            </a-btn>
                                                        </template>
                                                        <span>Private</span>
                                                    </a-tooltip>
                                                    <a-tooltip bottom v-else>
                                                    <template v-slot:activator="{ on }">
                                                        <a-btn text icon color="orange darken-2" class="mx-0" v-on="on" v-if="!$can('templates.update')">
                                                            <a-icon size="16">lock_open</a-icon>
                                                        </a-btn>
                                                        <a-btn text icon color="orange darken-2" class="mx-0" v-on="on" v-if="$can('templates.update')" :loading="local_loading_get(template_document.id, 'visibility')" @click="$can('templates.update') ? local_visibility_toggle(template_document.id, template_document.visibility) : ''">
                                                            <a-icon size="16">lock_open</a-icon>
                                                        </a-btn>
                                                    </template>
                                                    <span>Visible to external collaborators when imported into project.</span>
                                                </a-tooltip>
                                                </a-flex>
                                                <a-flex shrink>
                                                    <a-btn icon text small color="grey" class="mx-0" @click="local_download(template_document.id)" v-if="$can('templates.index')">
                                                        <a-icon size="16">cloud_download</a-icon>
                                                    </a-btn>
                                                    <a-btn icon text small color="grey" class="mx-0" @click="local_edit(template_document.id)" v-if="$can('templates.update')">
                                                        <a-icon size="16">edit</a-icon>
                                                    </a-btn>
                                                    <a-btn icon text small color="grey" class="mx-0" @click="local_destroy(template_document)" v-if="$can('templates.destroy')">
                                                        <a-icon size="16">delete</a-icon>
                                                    </a-btn>
                                                </a-flex>
                                            </a-layout>
                                        </a-container>
                                    </a-card-text>
                                </a-card>
                            </div>
                        </draggable>
                    </template>
                    <template v-else-if="!loading">
                        <div class="grey lighten-5 u-elevation-custom-1">
                            <a-card tile class="transparent u-relative" flat>
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl class="py-4 px-6">
                                        <a-layout align-center>
                                            <a-flex>
                                                <p class="mb-0">No Documents Found</p>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-card-text>
                            </a-card>
                        </div>
                    </template>
                </div>
                <!-- <a-container container--fluid grid-list-xl class="pa-0 transparent" v-if="template_group_expanded === index"  :class="{ 'pt-3': template_document_list && template_document_list.length }">
                    <a-layout wrap align-center>
                        <a-flex pt-0>
                        </a-flex>
                     </a-layout>
                </a-container> -->
            </div>
        </draggable>

        <a-dialog v-model="dialog_template_document_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="local_store()">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>description</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Edit Document Information</h2>
                                    <p class="md-subtitle-2 mb-0">Edit information related to document.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Filename</label>
                                    <a-text-field
                                        v-model="template_document_item.name"
                                        placeholder="Document Filename"
                                        background-color="neutral"
                                        solo flat hide-details readonly
                                    >
                                    </a-text-field>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                        <a-icon size="16">info</a-icon>
                                        This is the original filename of the document.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title (Optional)</label>
                                    <a-text-field
                                        v-model="template_document_item.title"
                                        placeholder="Enter Title"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_document_response.server && template_document_response.server.errors && template_document_response.server.errors.title">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_document_response.server.errors.title[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        This will help you identify the document.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_update()" :loading="loading" :disabled="loading">Save Document</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_template_document_form_cancel()" :disabled="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog v-model="dialog_template_group_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>library_books</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Edit Document Group</h2>
                                    <p class="md-subtitle-2 mb-0">Edit information related to document group.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                    <a-text-field
                                        v-model="template_group_item.name"
                                        placeholder="Enter Group Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_group_response.server && template_group_response.server.errors && template_group_response.server.errors.name && template_group_form_mode =='edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_group_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        This will help you identify the group.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_group_update()" :loading="loading" :disabled="loading">Save Group</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_group_form_cancel()" :disabled="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog max-width="480px" v-model="dialog_download_link" :persistent="loading">
            <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                <a-progress-linear class="my-0" height="3" color="orange darken-3" :indeterminate="true" v-if="loading"></a-progress-linear>
                <a-card-text class="pa-2">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout align-start>
                            <a-flex shrink>
                                <a-icon size="72" color="grey lighten-1">cloud_download</a-icon>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 mb-2">
                                    <span v-if="loading">Preparing file...</span>
                                    <span v-else>Ready to Download</span>
                                </h2>
                                <p class="md-body-2 mb-0 grey--text text--darken-1">
                                    <span v-if="loading">Success is preparing your file for download. It may take few seconds to get ready.</span>
                                    <span v-else>Your file is ready to download. Click on the "Save File" button to download the report.</span>
                                </p>
                                <a-btn :href="template_document_links.download" small class="elevation-0 px-3 ma-0 mt-3" color="primary" :disabled="loading" @click="local_download_save()" download>
                                    <a-icon size="14" class="mr-2">save</a-icon>
                                    Save File
                                </a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog v-model="dialog_template_group_clone_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>content_copy</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Duplicate Document Group</h2>
                                    <p class="md-subtitle-2 mb-0">Creates a duplicate of the document group.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                    <a-text-field
                                        v-model="local_template_group_clone_item.name"
                                        placeholder="Enter Group Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_document_response.server && template_document_response.server.errors && template_document_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_document_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        This will help you identify the group.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_group_clone()" :loading="loading" :disabled="loading">duplicate</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_group_clone_cancel()" :disabled="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-snackbar v-model="snackbar_status" color="green darken-3" class="text-center" :timeout="4000" bottom center>
            <div class="text-center" style="width: 100%">{{snackbar_text}}</div>
        </a-snackbar>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { diffHuman } from '@/helpers/helper-date-filter'
import { v4 as uuidv4 } from 'uuid'

export default {
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            list_loading: false,
            snackbar_status: false,
            snackbar_text: '',
            template_group_name: null,
            template_document_url: '',
            template_group_expanded: null,
            dialog_template_document_form: false,
            dialog_template_group_form: false,
            dialog_download_link: false,
            local_file_types: '.jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx,.csv,.ppt,.pptx,.txt,.zip',
            dropzoneOptions: {
                url: '/api/template-documents',
                params: {template_group_id: null},
                withCredentials: true,
                createImageThumbnails: false,
                uploadMultiple: false,
                acceptedFiles: '.jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx,.csv,.ppt,.pptx,.txt,.zip',
                maxFilesize: 100,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-Timezone': moment.tz.guess(true),
                    'X-CSRF-TOKEN': window.axios.defaults.headers.common['X-CSRF-TOKEN'],
                }
            },
            local_template_group_clone_item: {},
            dialog_template_group_clone_form: false,
            local_loading: false,
            loading_file: false,
            uploadDocsList: [],
            current_template_group_id: null,
            loading_item: null,
            loading_action: null,
            local_template_group_item: null,
        }
    },
    mounted() {
        this.local_index()
    },
    watch: {
        template_group_expanded(newValue, oldValue) {
            if (newValue != null) {
                this.local_list(this.template_group_list[newValue].id)
                this.dropzoneOptions.params.template_group_id = this.template_group_list[newValue].id
            }
        },
        dialog_template_group_form(newValue, oldValue) {
            if (newValue === false) {
                this.template_group_clear_item();
            }
        },
    },
    computed: {
        local_linked_template_group_id() {
            return this.$route.params.group_id
        },
        template_document_list: {
            get() {
                return this.$store.state.TemplateDocument.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },
        template_document_item: {
            get () {
                return this.$store.state.TemplateDocument.item
            },
            set (item) {
                this.$store.state.TemplateDocument.item = item
            }
        },
        template_group_list: {
            get() {
                return this.$store.state.TemplateGroup.list
            },
            set(list) {
                this.local_group_reorder(list)
                this.template_group_expanded = null
            }
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('TemplateGroup', {
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),
        ...mapState('TemplateDocument', {
            template_document_item: 'item',
            template_document_filters: 'filters',
            template_document_response: 'response',
            template_document_links: 'links',
            template_document_form_mode: 'form_mode',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('templates.show') == false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.local_loading = true
            await this.template_group_clear()
            await this.local_load_document_template_group()

            if(this.local_linked_template_group_id) {
                setTimeout(() => {
                    this.$vuetify.goTo('#document-'+this.local_linked_template_group_id)
                    this.template_group_expanded = this.template_group_list.findIndex(list => list.id === this.local_linked_template_group_id);
                }, 500)
            }
            this.local_loading = false
        },
        async local_load_document_template_group() {
            await this.template_group_index({
                'filter[type]': 'documents',
                'fields[template_groups]': 'id,name,order,type,created_at,updated_at,created_by_id,updated_by_id',
                'include': 'templateDocumentsCount,created_by,updated_by',
                'sort': 'order'
            })
        },
        async local_document_template_group_show(id) {
            await this.template_group_show({
                'fields[template_groups]': 'id,name,order,type,created_at,updated_at,created_by_id,updated_by_id',
                'include': 'templateDocumentsCount,created_by,updated_by',
                'sort': 'order',
                'id': id,
            })
        },
        async local_list(id) {
            this.list_loading = true
            await this.template_document_clear()
            await this.template_document_index({
                'filter[template_group_id]': id,
                'fields[template_documents]': 'id,name,title,extension,size,order,template_group_id,visibility',
                'sort': 'order',
            })
            this.list_loading = false
        },
        async local_store(id) {
            await this.template_document_clear_item()
            this.template_document_item.url = this.template_document_url
            await this.template_document_store({
                ...this.template_document_item,
                   template_group_id: id,
                   order: -1
            })

            if (this.template_document_response.status === 'success') {
                await this.template_document_clear_item()
                this.template_document_url = ''
            }
        },
        local_initiate(template_group_id, index) {
            this.uploadDocsList = []
            this.current_template_group_id = template_group_id
            this.$refs.doc_upload[0].value = null
            this.$refs.doc_upload[0].click()
            this.template_group_expanded = index
        },
        async local_doc_upload() {
            const fileList = Array.from(this.$refs.doc_upload[0].files)
            if (fileList && fileList.length > 0) {
                this.loading_file = true
                this.uploadDocsList = [...fileList]
                fileList.forEach(async item => {                    
                    await this.template_document_store(this.local_upload_action(item, this.current_template_group_id))
                    if (this.template_document_response.status === 'success') {
                        this.uploadDocsList.splice(this.uploadDocsList.findIndex(file => file.name === item.name), 1)
                        await this.local_document_template_group_show(this.current_template_group_id)
                    }
                    this.template_document_clear_item()
                    if (!this.uploadDocsList.length) this.loading_file = false
                })
            }
        },
        local_upload_action(item, template_group_id) {
            const form_data = new FormData()
            form_data.append('file', item)
            form_data.append('id', uuidv4())
            form_data.append('template_group_id', template_group_id)
            form_data.append('order', -1)
            return form_data
        },
        async local_edit(id) {
            this.dialog_template_document_form = true
            await this.template_document_select({id: id})
        },
        async local_update() {
            await this.template_document_update(this.template_document_item)
            if (this.template_document_response.status === 'success') {
                await this.local_document_template_group_show(this.template_document_item.template_group_id)
                this.dialog_template_document_form = false
                await this.template_document_clear_item()
            }
        },
        async local_template_clone_create(group_id, template_group) {
            this.local_template_group_item = template_group
            this.local_template_group_clone_item.name = ''
            this.local_template_group_clone_item.template_group_id = group_id
            this.dialog_template_group_clone_form = true
        },
        local_group_clone_cancel() {
            this.dialog_template_group_clone_form = false
            this.local_group_clear_item()
        },
        local_group_clear_item() {
            this.template_document_clear_item()
            this.local_template_group_clone_item.name = null
            this.local_template_group_clone_item.template_group_id = null
        },
        async local_group_clone() {
            this.local_template_group_clone_item.template_documents_count = this.local_template_group_item.template_documents_count
            this.local_template_group_clone_item.type = this.local_template_group_item.type
            await this.template_document_group_clone(this.$appendId(this.local_template_group_clone_item))
            if (this.template_document_response.status == 'success') {
                await this.local_group_clone_item_add()
                this.template_group_expanded = null
                this.dialog_template_group_clone_form = false
                this.local_group_clear_item()
                this.local_show_group_message('Document group duplicated successfully')
            }
        },
        async local_group_clone_item_add() {
            const group_item = {}
            group_item.id = this.local_template_group_clone_item.id
            group_item.name = this.local_template_group_clone_item.name
            group_item.template_documents_count = this.local_template_group_clone_item.template_documents_count
            group_item.type = this.local_template_group_clone_item.type
            await this.template_group_clone_add_item(group_item)
            await this.local_document_template_group_show(group_item.id)
            this.local_template_group_item = null
        },
        async local_destroy(document) {
            await this.template_document_destroy({id: document.id})
            this.template_group_list[this.template_group_expanded].template_documents_count = this.template_document_list.length
            await this.local_document_template_group_show(document.template_group_id)
        },
        async local_visibility_toggle(id, status) {
            if (status == 0) {
                await this.template_document_client_visible({id: id})
                await this.template_document_show({id: id})
            } else {
                await this.template_document_client_invisible({id: id})
                await this.template_document_show({id: id})
            }
        },
        async local_reorder(list) {
            await this.template_document_reorder({list: list})
            this.local_load_document_template_group()
        },
        async local_group_reorder(list) {
            await this.template_group_reorder({list: list})
            this.local_load_document_template_group()
        },
        async local_download(id) {
            this.dialog_download_link = true
            await this.template_document_download({id: id})
        },
        local_download_save() {
            this.dialog_download_link = false
        },
        async local_group_store() {
            this.template_group_item.name = this.template_group_name;
            this.template_group_item.name = this.template_group_name;
            await this.template_group_store({
                name: this.template_group_item.name,
                type: 'documents',
                model: 'template',
                'order': -1
            })

            if (this.template_group_response.status == 'success') {
                this.template_group_expanded = null
                this.template_group_name = ''
                this.dialog_template_group_form = false
                await this.local_document_template_group_show(this.template_group_item.id)
                this.template_group_clear_item()
            }
        },
         async local_group_update() {
            await this.template_group_update({
                ...this.template_group_item
            })
            if (this.template_group_response.status === 'success') {
                this.dialog_template_group_form = false
                await this.local_document_template_group_show(this.template_group_item.id)
                this.template_group_clear_item()
            }
        },
        async local_change_template_document_group(new_group_id, old_group_id, template_document) {
            this.snackbar_status = false
            template_document.template_group_id = new_group_id

            this.template_document_select_item({ data: template_document })
            await this.template_document_group_move({ ...template_document })
            if (this.template_document_response.status === 'success') {
                await this.local_update_group_meta_counts({ group_id: new_group_id, increase: true })
                await this.local_update_group_meta_counts({ group_id: old_group_id, increase: false })
                await this.local_document_template_group_show(new_group_id)
                await this.local_document_template_group_show(old_group_id)
                this.local_show_group_message('Document moved successfully')
            }
        },
        async local_copy_template_document_group(new_group_id, old_group_id, template_document) {
            this.snackbar_status = false
            template_document.template_group_id = new_group_id
            template_document.old_template_group_id = old_group_id

            await this.template_document_group_copy({
                template_document_id: template_document.id,
                template_group_id: new_group_id,
                old_template_group_id: old_group_id
            })
            if (this.template_document_response.status === 'success') {
                await this.local_update_group_meta_counts({ group_id: new_group_id, increase: true })
                await this.local_document_template_group_show(new_group_id)
                this.local_show_group_message('Document copied successfully')
            }
        },
        local_show_group_message(text) {
            this.snackbar_text = `${ text }`
            this.snackbar_status = !this.snackbar_status
        },
        async local_group_form_cancel() {
            this.dialog_template_group_form = false
            await this.template_group_clear_item()
        },
        async local_template_document_form_cancel() {
            this.dialog_template_document_form = false
            await this.template_document_clear_item()
        },
        local_group_edit(id) {
            this.template_group_select({id: id})
            this.dialog_template_group_form = true
        },
        async local_group_destroy(id) {
            this.template_group_destroy({id: id})
            this.template_group_expanded = null
        },
        local_group_toggle(index) {
            if (this.template_group_expanded === index) {
                this.template_group_expanded = null
            }
            else {
                this.template_group_expanded = index
            }
        },

        async local_visibility_toggle(id, status) {
            await this.local_loading_set(id, 'visibility')
            await this.template_document_select({id: id})
            this.template_document_item.visibility = status === 'external' ? 'internal' : 'external'
            this.template_document_item.state = status === 'external' ? 'internal' : 'external'
            await this.template_document_visibility(this.template_document_item)
            await this.local_loading_clear()
            if (this.template_document_response.status === 'success') {
                await this.local_document_template_group_show(this.template_document_item.template_group_id)
                this.template_document_clear_item()
            }
        },
        async local_loading_set(id, action) {
            this.loading_item = id
            this.loading_action = action
        },
        async local_loading_clear() {
            this.loading_item = null
            this.loading_action = null
        },
        local_loading_get(id, action) {
            return this.loading && this.loading_item == id && this.loading_action == action
        },

        async local_update_group_meta_counts ({ group_id, increase = false }) {
            const index = this.template_group_list.findIndex(item => item.id === group_id)
            if (index === -1) return

            const temp_group = this.template_group_list[index]
            temp_group.group_id = group_id
            if(!temp_group.template_documents_count) temp_group.template_documents_count = 0

            increase ? temp_group.template_documents_count++ : temp_group.template_documents_count--
            this.template_change_group_update(temp_group)
        },
        local_date_output_format (date) {
            return diffHuman(date)
        },

        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_show: 'show',
            template_group_clone_add_item: 'add_group_item',
            template_change_group_update: 'change_group_update',
            template_group_store: 'store',
            template_group_update: 'update',
            template_group_reorder: 'reorder',
            template_group_select: 'select',
            template_group_destroy: 'destroy',
            template_group_clear: 'clear',
            template_group_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateDocument', {
            template_document_index: 'index',
            template_document_show: 'show',
            template_document_visibility: 'visibility',
            template_document_store: 'store',
            template_document_group_move: 'group_document_move',
            template_document_group_update: 'group_document_update',
            template_document_group_copy: 'group_copy_store',
            template_document_group_clone: 'group_clone_store',
            template_document_select_item: 'select_item',
            template_document_client_visible: 'client_visible',
            template_document_client_invisible: 'client_invisible',
            template_document_update: 'update',
            template_document_select: 'select',
            template_document_download: 'download',
            template_document_destroy: 'destroy',
            template_document_reorder: 'reorder',
            template_document_clear: 'clear',
            template_document_clear_item: 'clear_item',
        })
    }
}
</script>

<style>
.dropzone-custom-content {
    padding: 2rem;
    border: 2px dashed #eee;
    border-radius: .25rem;
    cursor: pointer;
}
.dz-preview {
    background-color: #fff;
    border-radius: .25rem;

    margin-top: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.06), 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.06) !important;
}
.dz-preview .dz-details {
    padding: 1rem;
}
.dz-preview .dz-details .dz-size,
.dz-preview .dz-details .dz-filename {
    display: inline-block;
    font-size: .825rem;
}
.dz-preview .dz-image,
.dz-preview .dz-success-mark,
.dz-preview .dz-error-mark {
    display: none;
}
.dz-preview .dz-progress .dz-upload {
    display: block;
    background-color: #43a047;
    height: 2px;
}
.dz-preview.dz-success {
    display: none;
}
.dz-preview.dz-error .dz-progress {
    display: none;
}
.dz-preview.dz-error .dz-error-message {
    padding: 1rem;
    padding-top: 0;
    font-size: .825rem;
    color: red;
}
.dz-preview.dz-error .dz-details {
    padding-bottom: 0;
}
</style>

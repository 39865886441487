<template>
    <a-main>
        <g-settings-breadcrumb class="mt-5" previousRoute="inventory"></g-settings-breadcrumb>
        <a-container container--fluid grid-list-xl px-0>
            <g-page-header title="Booking Status" subtitle="List of inventory bookings." :breadcrumb-items="breadcrumb_items" icon="memory">
            </g-page-header>
            <a-container grid-list-xl container--fluid class="pa-0 pt-2 mt-0">
                <a-layout align-start class="my-0">
                    <a-flex shrink class="py-0 pr-0 mb-1 c-margin-right">
                        <a-menu bottom offset-y transition="slide-y-transition" min-width="210" max-width="210" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 d-flex" min-width="210" max-width="210" max-height="36" v-on="on">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div>
                                            <a-icon size="20" class="ma-0">filter_list</a-icon>
                                            <span class="grey--text text--darken-2 mx-1">Status: <span class="font-weight-medium" v-if="inventory_filter_types">{{ inventory_filter_types && inventory_filter_types[filter.STATUS] ? inventory_filter_types[filter.STATUS] : 'In Use' }}</span></span>
                                        </div>
                                        <div class="text-xs-right">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-list class="u-list-condensed">
                                <template v-for="(item, index) in inventory_status_list">
                                    <a-list-item :key="'status' + index" v-if="inventory_filter_types && inventory_filter_types[filter.STATUS] !== item.name" @click="local_inventory_status_filter(item)">
                                        <a-list-item-title>
                                            {{ item.name }}
                                        </a-list-item-title>
                                    </a-list-item>
                                    <a-list-item :key="'status' + index" v-else class="grey lighten-3">
                                        <a-list-item-title>
                                            {{ item.name }}
                                        </a-list-item-title>
                                    </a-list-item>
                                </template>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink class="py-0 px-0">
                        <a-layout class="ma-0 pa-0">
                            <a-flex shrink class="py-0 px-0" :class="{ 'c-margin-right': show_selected_filters }">
                                <a-card class="pa-0 white u-elevation-custom-1 u-rounded-corners xs-mt-1 xl-mt-0 d-flex align-center grey--text text--darken-1 px-2 u-cursor-pointer" min-height="35" v-if="loading || local_loading">
                                    <a-icon size="18" color="grey darken-2" class="mr-1">filter_alt</a-icon>
                                    Filters
                                </a-card>
                                <a-card @click="local_open_filter_pane()" class="pa-0 white u-elevation-custom-1 u-rounded-corners xs-mt-1 xl-mt-0 d-flex align-center grey--text text--darken-1 px-2 u-cursor-pointer" min-height="35" v-else>
                                    <a-icon size="18" color="grey darken-2" class="mr-1">filter_alt</a-icon>
                                    Filters
                                </a-card>
                            </a-flex>
                            <div v-if="show_selected_filters">
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.PROJECT] && inventory_filter_types[filter.PROJECT] !== 'ALL'" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Project:</strong> {{ inventory_filter_types[filter.PROJECT] && inventory_filter_types[filter.PROJECT] === 'ALL' ? 'All' : inventory_filter_types[filter.PROJECT] }}
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer ml-2" @click="local_clear_filters(filter.PROJECT, 'ALL')" style="min-height: 28px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.ASSIGNEE] && inventory_filter_types[filter.ASSIGNEE] !== 'ALL'" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Assignee:</strong> {{ inventory_filter_types[filter.ASSIGNEE] && inventory_filter_types[filter.ASSIGNEE] === 'ALL' ? 'All' : inventory_filter_types[filter.ASSIGNEE] }}
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer ml-2" @click="local_clear_filters(filter.ASSIGNEE, 'ALL')" style="min-height: 28px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.REQUESTER] && inventory_filter_types[filter.REQUESTER] !== 'ALL'" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Requester:</strong> {{ inventory_filter_types[filter.REQUESTER] && inventory_filter_types[filter.REQUESTER] === 'ALL' ? 'All' : inventory_filter_types[filter.REQUESTER] }}
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer ml-2" @click="local_clear_filters(filter.REQUESTER, 'ALL')" style="min-height: 28px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.OWNER] && inventory_filter_types[filter.OWNER] !== 'ALL'" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Owner:</strong> {{ inventory_filter_types[filter.OWNER] && inventory_filter_types[filter.OWNER] === 'ALL' ? 'All' : inventory_filter_types[filter.OWNER] }}
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer ml-2" @click="local_clear_filters(filter.OWNER, 'ALL')" style="min-height: 28px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.BOOKING_FROM] && inventory_filter_types && inventory_filter_types[filter.BOOKING_TO]" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Availability:</strong> {{ local_format_date(inventory_filter_types[filter.BOOKING_FROM]) }} - {{ local_format_date(inventory_filter_types[filter.BOOKING_TO]) }}
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer ml-2" @click="local_clear_filters('date', null)" style="min-height: 28px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.SORT] !== 'created_at' || inventory_filter_types && inventory_filter_types[filter.SORT_ORDER] !== 'asc'" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Sort by:</strong> {{ inventory_filter_types[filter.SORT] === 'booking_date' || inventory_filter_types[filter.SORT] === 'created_at' ? 'Booking Date' : inventory_filter_types[filter.SORT] }} ({{ inventory_filter_types[filter.SORT_ORDER] === 'Ascending' || inventory_filter_types[filter.SORT_ORDER] === 'asc' ? 'Asc' : 'Desc' }})
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer ml-2" @click="local_clear_filters(filter.SORT, 'booking_date')" style="min-height: 28px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                            </div>
                        </a-layout>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink class="py-0 pl-0 mb-1">
                        <a-menu offset-y left bottom :disabled="inventory_booking_list.length === 0">
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <a-btn small text color="grey darken-1" v-if="inventory_booking_list.length" height="36" class="grey lighten-2 ma-0 px-4 u-rounded-corners">
                                        <a-icon left size="16" class="mr-2">save_alt</a-icon> Download <a-icon right size="18" class="ml-2">arrow_drop_down</a-icon>
                                    </a-btn>
                                    <a-tooltip bottom v-else>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <a-btn small text disabled color="grey darken-1" height="36" class="grey lighten-2 ma-0 px-4 u-rounded-corners">
                                                    <a-icon left size="16" class="mr-2">save_alt</a-icon> Download <a-icon right size="18" class="ml-2">arrow_drop_down</a-icon>
                                                </a-btn>
                                            </div>
                                        </template>
                                        <span class="d-block text-center" style="max-width: 256px;">There are no inventories to download, try changing the status filter.</span>
                                    </a-tooltip>
                                </div>
                            </template>
                            <a-list class="u-list-condensed">
                                <a-list-item  @click="local_export('csv')">
                                    <a-list-item-title class="md-body-2">Download as CSV</a-list-item-title>
                                </a-list-item>
                                <a-list-item @click="local_export('xlsx')">
                                    <a-list-item-title class="md-body-2">Download as Excel</a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink class="py-0 pl-1">
                        <a-progress-circular color="orange darken-2" size="24" width="2" :indeterminate="loading"></a-progress-circular>
                    </a-flex>
                </a-layout>
                <a-responsive class="py-4 my-4" v-if="local_loading">
                    <h3 class="md-title font-weight-medium text-center grey--text text--darken-1 mb-1">Loading...</h3>
                </a-responsive>
                <a-responsive class="py-4 my-4" v-if="booking_list && !booking_list.length && !local_loading">
                    <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Bookings Found</h3>
                    <p class="md-body-2 text-center grey--text mb-0">There is no booking to fetch right now.<br>Try narrowing your filter or add new bookings.</p>
                </a-responsive>
                <template v-if="!local_loading && (booking_list && booking_list.length)">
                    <s-booking-list :booking_list="booking_list"></s-booking-list>
                </template>
            </a-container>
        </a-container>
        <a-responsive class="grey lighten-3 c-inventory-filters-pane elevation-12 pb-4" :class="{'visible': pane_filters}">
            <div class="py-4">
                <a-layout align-center class="px-5 mb-4">
                    <a-flex shrink class="mr-2">
                        <a-avatar class="primary darken-1" size="45">
                            <a-icon dark>filter_list</a-icon>
                        </a-avatar>
                    </a-flex>
                    <a-flex>
                        <h2 class="md-heading-6 primary--text text--darken-1">Filter Bookings</h2>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink>
                        <a-btn icon small @click="pane_filters = false"><a-icon color="primary" size="18">cancel</a-icon></a-btn>
                    </a-flex>
                </a-layout>
                <a-divider class="my-2"></a-divider>
                <a-layout class="wrap px-5 mt-4">
                    <a-flex xs12 class="mb-4">
                        <a-menu bottom offset-y v-model="search_poc" :close-on-content-click="filter_mode" min-width="352" max-width="352" max-height="500" transition="slide-y-transition" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card class="u-rounded-corners u-overflow-hidden c-border-c7 elevation-0" min-width="100%" v-on="on" @click="local_search_poc = ''">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div>
                                            <a-icon size="20" class="ma-0">filter_list</a-icon>
                                            <span class="grey--text text--darken-2 mx-1">Project: <span class="font-weight-medium" v-if="inventory_filter_types" :title="inventory_filter_types[filter.PROJECT] === 'ALL' ? null : inventory_filter_types[filter.PROJECT]">{{ inventory_filter_types && inventory_filter_types[filter.PROJECT] ? inventory_filter_types[filter.PROJECT] === 'ALL' ? 'All' : local_truncate_text(inventory_filter_types[filter.PROJECT], 25) : 'All' }}</span></span>
                                        </div>
                                        <div class="text-xs-right">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-divider></a-divider>
                            <a-card class="c-search-inputs-inventory u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                                <a-text-field
                                    v-model="local_search_poc"
                                    placeholder="Search projects"
                                    background-color="neutral"
                                    ref="search_poc_input"
                                    class="body-2 font-weight-normal"
                                    solo flat hide-details
                                    @click="filter_mode = false"
                                >
                                </a-text-field>
                                <a-divider></a-divider>
                                <a-list style="max-height: 384px;" class="overflow-y-auto py-0">
                                    <a-list-item @click="local_inventory_filter(filter.PROJECT,'ALL', 'All')" v-if="!local_search_poc && local_filtered_poc_list.length !== 0">
                                        <a-list-item-title>
                                            All
                                        </a-list-item-title>
                                    </a-list-item>
                                    <a-list-item v-for="(item, index) in local_filtered_poc_list" :key="'poc' + index" @click="local_inventory_filter(filter.PROJECT, item.id, item.title)">
                                        <a-list-item-title :title="item.title">
                                            {{ item.title }}
                                        </a-list-item-title>
                                    </a-list-item>
                                    <a-list-item v-if="local_filtered_poc_list.length === 0" disabled>
                                        <a-list-item-content>
                                            <a-list-item-title v-if="loading">
                                                <h2 class="md-subtitle-1 pa-0 mb-0 text-center">Loading projects...</h2>
                                            </a-list-item-title>
                                            <a-list-item-title v-else>
                                                <h2 class="md-subtitle-1 mb-0 text-center">No projects found</h2>
                                            </a-list-item-title>
                                        </a-list-item-content>
                                    </a-list-item>
                                </a-list>
                            </a-card>
                        </a-menu>
                    </a-flex>
                    <a-flex xs12 class="mb-4">
                        <a-menu bottom offset-y v-model="search_user" :close-on-content-click="filter_mode" min-width="352" max-width="352" max-height="500" transition="slide-y-transition" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card flat class="u-rounded-corners u-overflow-hidden c-border-c7 elevation-0" min-width="100%" v-on="on" @click="local_search_user = ''">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div>
                                            <a-icon size="20" class="ma-0">filter_list</a-icon>
                                            <span class="grey--text text--darken-2 mx-1">Assignee: <span class="font-weight-medium" v-if="inventory_filter_types && inventory_filter_types[filter.ASSIGNEE]" :title="inventory_filter_types[filter.ASSIGNEE] === 'ALL' ? null : inventory_filter_types[filter.ASSIGNEE]">{{ inventory_filter_types && inventory_filter_types[filter.ASSIGNEE] === 'ALL' ? 'All' : local_truncate_text(inventory_filter_types[filter.ASSIGNEE], 20) }}</span></span>
                                        </div>
                                        <div class="text-xs-right">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-divider></a-divider>
                            <a-card flat class="c-search-inputs-inventory u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                                <a-text-field
                                    v-model="local_search_user"
                                    placeholder="Search Users"
                                    background-color="neutral"
                                    ref="search_user_input"
                                    class="body-2 font-weight-normal"
                                    @input="localSearchUsers(local_search_user, 'assignee')"
                                    @click="filter_mode = false"
                                    solo flat hide-details
                                >
                                </a-text-field>
                                <a-divider></a-divider>
                                <a-list class="py-0" v-if="mixinIsTypeLoading('assignee')">
                                    <a-list-item class="py-1" disabled>
                                        <h2 class="md-subtitle-1 pa-0 mb-0 text-center">Loading Users...</h2>
                                    </a-list-item>
                                </a-list>
                                <template v-else>
                                    <a-list style="max-height: 400px;" class="overflow-y-auto py-0">
                                        <a-list-item @click="local_inventory_filter(filter.ASSIGNEE,'ALL', 'All')" v-if="!local_search_user && user_list && user_list.length !== 0">
                                            <a-layout align-center class="py-0">
                                                <div class="member-avatar mr-2">
                                                    <a-avatar size="32" style="padding: 2px;" color="grey lighten-2">
                                                        <a-icon size="18">people</a-icon>
                                                    </a-avatar>
                                                </div>
                                                <div class="member-label u-cursor-pointer">
                                                    <h5 class="md-subtitle-1">All</h5>
                                                </div>
                                            </a-layout>
                                        </a-list-item>
                                        <a-list-item class="py-1" v-for="(item, index) in user_list" :key="'poc' + index" @click="local_inventory_filter(filter.ASSIGNEE, item.id, item.name)">
                                            <a-layout align-center class="py-2">
                                                <g-avatar :item="item"></g-avatar>
                                                <g-profile-info :item="item" set-max-width type-user></g-profile-info>
                                            </a-layout>
                                        </a-list-item>
                                        <a-list-item v-if="user_list && user_list.length === 0" disabled>
                                            <a-list-item-content>
                                                <a-list-item-title v-if="loading">
                                                    <h2 class="md-subtitle-1 pa-0 mb-0 text-center">Loading Users...</h2>
                                                </a-list-item-title>
                                                <a-list-item-title v-else>
                                                    <h2 class="md-subtitle-1 mb-0 text-center">No Users found</h2>
                                                </a-list-item-title>
                                            </a-list-item-content>
                                        </a-list-item>
                                    </a-list>
                                </template>
                            </a-card>
                        </a-menu>
                    </a-flex>
                    <a-flex xs12 class="mb-2">
                        <a-menu bottom offset-y v-model="search_requester_user" :close-on-content-click="requester_filter_mode" min-width="352" max-width="352" max-height="500" transition="slide-y-transition" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card flat class="u-rounded-corners u-overflow-hidden c-border-c7 elevation-0" min-width="100%" v-on="on" @click="local_search_requester = ''">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div>
                                            <a-icon size="20" class="ma-0">filter_list</a-icon>
                                            <span class="grey--text text--darken-2 mx-1">Requester: <span class="font-weight-medium" v-if="inventory_filter_types && inventory_filter_types[filter.REQUESTER]" :title="inventory_filter_types[filter.REQUESTER] === 'ALL' ? null : inventory_filter_types[filter.REQUESTER]">{{ inventory_filter_types && inventory_filter_types[filter.REQUESTER] === 'ALL' ? 'All' : local_truncate_text(inventory_filter_types[filter.REQUESTER], 20) }}</span></span>
                                        </div>
                                        <div class="text-xs-right">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-divider></a-divider>
                            <a-card flat class="c-search-inputs-inventory u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                                <a-text-field
                                    v-model="local_search_requester"
                                    placeholder="Search Users"
                                    background-color="neutral"
                                    ref="search_requester_user_input"
                                    class="body-2 font-weight-normal"
                                    @input="localSearchUsers(local_search_requester, 'requester')"
                                    @click="requester_filter_mode = false"
                                    solo flat hide-details
                                >
                                </a-text-field>
                                <a-divider></a-divider>
                                <a-list class="py-0" v-if="mixinIsTypeLoading('requester')">
                                    <a-list-item class="py-1" disabled>
                                        <h2 class="md-subtitle-1 pa-0 mb-0 text-center">Loading Users...</h2>
                                    </a-list-item>
                                </a-list>
                                <template v-else>
                                    <a-list style="max-height: 400px;" class="overflow-y-auto py-0">
                                        <a-list-item @click="local_inventory_filter(filter.REQUESTER,'ALL', 'All')" v-if="!local_search_requester && user_list && user_list.length !== 0">
                                            <a-layout align-center class="py-0">
                                                <div class="member-avatar mr-2">
                                                    <a-avatar size="32" style="padding: 2px;" color="grey lighten-2">
                                                        <a-icon size="18">people</a-icon>
                                                    </a-avatar>
                                                </div>
                                                <div class="member-label u-cursor-pointer">
                                                    <h5 class="md-subtitle-1">All</h5>
                                                </div>
                                            </a-layout>
                                        </a-list-item>
                                        <a-list-item class="py-1" v-for="(item, index) in user_list" :key="'poc' + index" @click="local_inventory_filter(filter.REQUESTER, item.id, item.name)">
                                            <a-layout align-center class="py-2">
                                                <g-avatar :item="item"></g-avatar>
                                                <g-profile-info :item="item" set-max-width type-user></g-profile-info>
                                            </a-layout>
                                        </a-list-item>
                                        <a-list-item v-if="user_list && user_list.length === 0" disabled>
                                            <a-list-item-content>
                                                <a-list-item-title v-if="loading">
                                                    <h2 class="md-subtitle-1 pa-0 mb-0 text-center">Loading Users...</h2>
                                                </a-list-item-title>
                                                <a-list-item-title v-else>
                                                    <h2 class="md-subtitle-1 mb-0 text-center">No Users found</h2>
                                                </a-list-item-title>
                                            </a-list-item-content>
                                        </a-list-item>
                                    </a-list>
                                </template>
                            </a-card>
                        </a-menu>
                    </a-flex>
                    <a-flex xs12 class="my-3">
                        <a-divider class="grey lighten-2"></a-divider>
                    </a-flex>
                    <a-flex xs12 class="mb-2">
                        <div class="align-center" style="display: flex">
                            <h2 class="title grey--text text--darken-2 font-weight-medium mr-4">Sort</h2>
                        </div>
                    </a-flex>
                    <a-flex xs12 class="mb-4">
                        <a-menu bottom offset-y min-width="352" max-width="352" max-height="500" transition="slide-y-transition" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card flat class="u-overflow-hidden u-rounded-corners c-border-c7 elevation-0" min-width="100%" v-on="on">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div>
                                            <a-icon size="20" class="ma-0">sort</a-icon>
                                            <span class="grey--text text--darken-2 mx-1">Sort by: <span class="font-weight-medium">{{ inventory_filter_types && inventory_filter_types[filter.SORT] ? inventory_filter_types[filter.SORT] === 'created_at' ? 'Booked Date' : inventory_filter_types[filter.SORT] : 'Booked Date' }}</span></span>
                                        </div>
                                        <div class="text-xs-right">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-list class="">
                                <a-list-item v-for="(item, index) in sort_inventory" :key="'sortt' + index" @click="local_inventory_filter(filter.SORT, item.value, item.name)">
                                    <a-list-item-title>
                                        Sort by: {{ item.name }}
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex xs12 class="mb-4">
                        <a-menu bottom offset-y min-width="352" max-width="352" max-height="500" transition="slide-y-transition" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card flat class="u-overflow-hidden u-rounded-corners c-border-c7 elevation-0" min-width="100%" v-on="on">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div v-if="inventory_filter_types">
                                            <a-icon size="22" class="ma-0">{{ inventory_filter_types[filter.SORT_ORDER] === 'Ascending' || inventory_filter_types[filter.SORT_ORDER] === 'asc'  ? 'arrow_downward' : 'arrow_upward' }}</a-icon>
                                            <span class="grey--text text--darken-2 mx-1">Sort: <span class="font-weight-medium">{{ inventory_filter_types[filter.SORT_ORDER] === 'Ascending' || inventory_filter_types[filter.SORT_ORDER] === 'asc' ? 'Ascending' : inventory_filter_types[filter.SORT_ORDER] }}</span></span>
                                        </div>
                                        <div class="text-xs-right">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-list class="">
                                <a-list-item v-for="(item, index) in sort_order_inventory" :key="'sort_order' + index" @click="local_inventory_filter(filter.SORT_ORDER, item.value, item.name)">
                                    <a-list-item-title>
                                        {{ item.name }}
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                </a-layout>
                <a-layout class="ma-0 pa-0 px-4 mt-4">
                    <a-flex xs12>
                        <a-btn color="white" class="primary ma-0 u-rounded-corners" text @click="local_fetch_inventory()">Filter</a-btn>
                        <a-btn color="grey" class="grey lighten-2 my-0 u-rounded-corners ml-2" text @click="localCloseFilterPane()">Cancel</a-btn>
                    </a-flex>
                </a-layout>
            </div>
        </a-responsive>
        <a-dialog max-width="480px" v-model="dialog_download_link" persistent>
            <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden" flat>
                <a-progress-linear class="my-0" height="3" color="orange darken-3" :indeterminate="true" v-if="loading"></a-progress-linear>
                <a-card-text class="pa-2">
                    <a-container container--fluid grid-list-xl class="pa-4">
                        <a-layout align-start>
                            <a-flex shrink>
                                <a-icon size="72" color="grey lighten-1">cloud_download</a-icon>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 mb-2">
                                    <span v-if="loading">Preparing file...</span>
                                    <span v-else>Ready to Download</span>
                                </h2>
                                <p class="md-body-2 mb-0 grey--text text--darken-1">
                                    <span v-if="loading">Success is preparing your report for download. It may take few seconds to get ready.</span>
                                    <span v-else>Your report is ready to download. Click on the "Save File" button to download the report.</span>
                                </p>
                                <a-btn :href="inventory_booking_links.download" small class="elevation-0 px-3 ma-0 mt-3" color="primary" :disabled="loading" @click="local_download_save()">
                                    <a-icon size="14" class="mr-2">save</a-icon>
                                    Save File
                                </a-btn>
                                <a-btn class="elevation-0 px-3 ma-0 mt-3 ml-2" color="grey" small text @click="local_download_close()" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { SBookingList } from '@/config/config-shared-components'
import mixinSearchUsers from '@/mixins/mixin-search-users'

export default {
    mixins: [mixinSearchUsers],

    components: { SBookingList },

    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            sort_inventory: [
                {name: 'Booking Date', value: 'created_at'},
                {name: 'Start Date', value: 'booking_from'},
                {name: 'End Date', value: 'actual_booking_to'},
            ],
            sort_order_inventory: [
                {name: 'Ascending', value: 'asc'},
                {name: 'Descending', value: 'desc'},
            ],
            inventory_status_list: [
                { name: 'In Use', value: 'In Use'},
                { name: 'Upcoming', value: 'Booked'},
                { name: 'Past', value: 'Returned'},
                { name: 'Cancelled', value: 'Cancelled'},
            ],
            pane_filters: false,
            local_loading: false,
            local_filter_poc: 'All',
            local_requester: 'All',
            local_assignee: 'All',
            local_owner: 'All',
            local_sorted_by: 'Booking Date',
            local_sort_order: 'asc',
            date_range_picker: {
                start: null,
                end: null
            },
            inventory_filter_types: null,
            show_selected_filters: false,
            booking_list: [],
            local_search_user: '',
            local_search_poc: '',
            local_search_requester: '',
            requester_filter_mode: false,
            owner_filter_mode: false,
            dialog_download_link: false,
            search_user: false,
            search_poc: false,
            search_owner: false,
            search_requester_user: false,
            filter_mode: false,
            include: 'project.customer,inventory.category,inventory.customStatus,inventory.owner,inventoryTrashed,assignee.role,requester.role',
            filter: {
                STATUS: 'filter[status]',
                REQUESTER: 'filter[requester_id]',
                ASSIGNEE: 'filter[assignee_id]',
                // OWNER: 'filter[owner_id]',
                PROJECT: 'filter[project_id]',
                SORT_ORDER: 'sort_order',
                SORT: 'sort',
                PAGE: 'page',
            },
            inventory_booking_filters: {
                'filter[status]': 'In Use',
                'filter[requester_id]': 'ALL',
                'filter[assignee_id]': 'ALL',
                // 'filter[owner_id]': 'ALL',
                'filter[project_id]': 'ALL',
                'sort_order': 'asc',
                'sort': 'created_at',
                'page': 1,
            },
        }
    },

    mounted() {
        this.local_index()
    },

    watch: {
        search_user(val) {
            if (val) {
                this.localSearchUsers(null, 'assignee')
                setTimeout(() => this.$refs.search_user_input.ref.focus(), 500)
            }
        },
        search_requester_user(val) {
            if (val) {
                this.localSearchUsers(null, 'requester')
                setTimeout(() => this.$refs.search_requester_user_input.ref.focus(), 500)
            }
        },
        search_poc(val) {
            if (val) {
                setTimeout(() => this.$refs.search_poc_input.ref.focus(), 500)
            }
        },
    },
    computed: {
        local_date_range_filter: {
            get () {
                return this.date_range_picker
            },
            set (value) {
                this.date_range_picker = value
                if (this.date_range_picker && this.date_range_picker.start || this.date_range_picker && this.date_range_picker.end) {
                    this.local_inventory_date_filter()
                }
            }
        },
        local_filtered_poc_list() {
            return this.project_list.filter(list => list.title.toLowerCase().includes(this.local_search_poc.toLowerCase()))
        },
        local_has_inventory_filter() {
            return this.inventory_filter_types && this.inventory_filter_types[this.filter.PROJECT] !== 'ALL' || this.inventory_filter_types && this.inventory_filter_types[this.filter.ASSIGNEE] !== 'ALL' ||
                    this.inventory_filter_types && this.inventory_filter_types[this.filter.REQUESTER] !== 'ALL' || this.inventory_filter_types && this.inventory_filter_types[this.filter.OWNER] !== 'ALL' ||
                    this.inventory_filter_types && this.inventory_filter_types[this.filter.BOOKING_FROM] || this.inventory_filter_types && this.inventory_filter_types[this.filter.BOOKING_TO] ||
                    this.inventory_filter_types && this.inventory_filter_types[this.filter.SORT] !== 'booking_date' || this.inventory_filter_types && this.inventory_filter_types[this.filter.SORT_ORDER] !== 'asc'
                    ? true : false
        },
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('Tag', {
            tag_filters: 'filters',
        }),
        ...mapState('User', {
            user_self: 'self',
            user_list: 'list',
            user_filters: 'filters',
            user_response: 'response',
        }),
        ...mapState('InventoryBooking', {
            'inventory_booking_all': 'list',
            'inventory_booking_state_filters': 'filters'
        }),
        ...mapState('Project', {
            project_list: 'list',
            project_item: 'item',
            project_filters: 'filters',
        }),
        ...mapGetters('InventoryBooking', {
            inventory_booking_list: 'list',
            inventory_booking_item: 'item',
            inventory_booking_links: 'links',
            inventory_booking_defaults: 'defaults',
        }),
    },

    methods: {
        async local_index () {
            if (!this.$can('inventories.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.local_loading = true
            this.project_clear()
            this.project_clear_item()

            await this.inventory_booking_filter_clear()
            this.inventory_booking_clear()
            await this.inventory_booking_index({
                include: this.include,
                'sort': 'created_at',
                'filter[status]': 'In Use'
            })
            this.booking_list = this.inventory_booking_list

            //this.user_clear()
            //this.user_filters.sort_field = 'name'
            //await this.user_index({
            //    'include': 'role',
            //    'sort': 'name',
            //    'filter[is_active]': 1,
            //    count: 999,
            //    page: 1
            //})

            this.inventory_filter_types = _.cloneDeep(this.inventory_booking_filters)
            await this.local_load_inventory_custom_statuses()
            this.local_loading = false
        },
        async local_inventory_filter(filter, value, label = null) {
            if(filter === this.filter.PROJECT) this.local_filter_poc = label
            if(filter === this.filter.ASSIGNEE) this.local_assignee = label
            if(filter === this.filter.REQUESTER) this.local_requester = label
            if(filter === this.filter.SORT) this.local_sorted_by = value
            if (filter === this.filter.SORT_ORDER) this.local_sort_order = value

            this.filter_mode = true
            this.requester_filter_mode = true
            this.owner_filter_mode = true
            this.inventory_filter_types[filter] = label
            this.$set(this.inventory_booking_filters, filter, value)
            this.local_has_inventory_filter
        },
        local_inventory_date_filter() {
            if (this.date_range_picker && this.date_range_picker.start || this.date_range_picker && this.date_range_picker.end) {
                // this.inventory_booking_filters[this.filter.BOOKING_FROM] = this.local_format_date(this.date_range_picker.start, true)
                // this.inventory_booking_filters[this.filter.BOOKING_TO] = this.local_format_date(this.date_range_picker.end, true)
                this.inventory_filter_types = Object.assign({}, this.inventory_filter_types, { booking_from: this.local_format_date(this.date_range_picker.start, true), booking_to: this.local_format_date(this.date_range_picker.end, true)})
                this.local_has_inventory_filter
            }
        },
        local_load_inventory_custom_statuses() {
            this.tag_custom_status_clear()
            this.tag_filters.type = 'Inventory Custom Status'
            this.tag_index({ 'filter[type]': 'inventory_custom_status'})
        },
        localCloseFilterPane () {
            this.pane_filters = false
        },
        local_open_filter_pane () {
            this.pane_filters = true
            this.local_load_projects()
        },
        async local_load_projects() {
            this.project_index({
                'sort': 'title',
                'fields[projects]': 'id,title',
                'filter[status]': 'active,queue',
                count: 999,
                page: 1
            })
        },
        async local_clear_filters(type, defaultValue) {
            this.local_loading = true

            if (type === 'date') {
                this.local_clear_date_filter()
                this.inventory_filter_types[this.filter.BOOKING_FROM] = null
                this.inventory_filter_types[this.filter.BOOKING_TO] = null
            } else if (type === 'sort') {
                this.inventory_booking_filters[this.filter.SORT] = 'created_at'
                this.inventory_booking_filters[this.filter.SORT_ORDER] = 'asc'
                this.inventory_filter_types[this.filter.SORT] = 'created_at'
                this.inventory_filter_types[this.filter.SORT_ORDER] = 'asc'
            } else if (type === 'poc') {
                this.inventory_booking_filters[this.filter.PROJECT] = null
                this.inventory_filter_types[this.filter.PROJECT] = 'ALL'
            } else {
                this.inventory_booking_filters[type] = defaultValue
                this.inventory_filter_types[type] = defaultValue
            }

            await this.inventory_booking_filter_update({ filters: this.inventory_booking_filters })
            await this.inventory_booking_index({
                include: this.include,
                ...this.inventory_booking_filters
            })
            this.local_loading = false
            this.booking_list = this.inventory_booking_list
            this.local_has_inventory_filter ? this.show_selected_filters = true : this.show_selected_filters = false
        },
        async local_fetch_inventory() {
            this.pane_filters = false
            this.local_has_inventory_filter ? this.show_selected_filters = true : this.show_selected_filters = false
            this.local_loading = true
            let sortValue = this.inventory_booking_filters[this.filter.SORT]
            const order = this.inventory_booking_filters[this.filter.SORT_ORDER]
            this.inventory_booking_filters[this.filter.SORT] = `${order === 'asc' ? '' : '-'}` + sortValue

            await this.inventory_booking_filter_update({ filters: this.inventory_booking_filters })
            await this.inventory_booking_index({
                include: this.include,
                ...this.inventory_booking_filters
            })

            this.inventory_booking_filters[this.filter.SORT] = sortValue
            this.local_loading = false
            this.booking_list = this.inventory_booking_list
        },
        async local_inventory_status_filter(item) {
            this.local_loading = true
            this.inventory_filter_types[this.filter.STATUS] = item.name
            this.$set(this.inventory_booking_filters, this.filter.STATUS, item.value)
            await this.inventory_booking_filter_update({ filters: this.inventory_booking_filters })
            await this.inventory_booking_index({
                include: this.include,
                ...this.inventory_booking_filters
            })
            this.booking_list = this.inventory_booking_list
            this.local_loading = false
        },
        async local_export(format) {
            // TODO: Add these two filters as separate object
            // this.inventory_booking_filters.page = 1
            // this.inventory_booking_filters.download_format = format
            this.inventory_booking_filters[this.filter.PAGE] = 1
            this.inventory_booking_filters.download_format = format
            await this.inventory_booking_filter_update({ filters: this.inventory_booking_filters })
            this.dialog_download_link = true
            await this.inventory_booking_export()
        },
        local_download_save() {
            this.dialog_download_link = false
        },
        local_download_close() {
            this.dialog_download_link = false
        },
        local_clear_date_filter () {
            this.date_range_picker.start = null
            this.date_range_picker.end = null
            // this.inventory_filter_types[this.filter.BOOKING_FROM] = this.date_range_picker.start
            // this.inventory_filter_types[this.filter.BOOKING_TO] = this.date_range_picker.end
            // this.inventory_booking_filters[this.filter.BOOKING_FROM] = this.date_range_picker.start
            // this.inventory_booking_filters[this.filter.BOOKING_TO] = this.date_range_picker.end
        },
        local_format_date(date, range = false, simple = false) {
            if (range) return moment(date).format('YYYY-MM-DD')

            if (simple) return moment(date).format('MMM D')

            return date ? moment(date).format('MMM D, YYYY') : null
        },
        local_truncate_text(text, range) {
            if (!text) return

            return text.length > range ? text.slice(0, range) + '...' : text
        },

        /* Search users */
        async localSearchUsers (val, type = null) {
            const args = { 'include': 'role', 'sort': 'name', 'filter[is_active]': 1, count: 20, page: 1 }
            await this.user_clear()
            await this.mixinSearchUsers(val, args, type)
        },

        ...mapActions('InventoryBooking', {
            inventory_booking_index: 'index',
            inventory_booking_return: 'booking_return',
            inventory_booking_cancel: 'booking_cancel',
            inventory_booking_export: 'export',
            inventory_booking_filter_update: 'update_filters',
            inventory_booking_filter_clear: 'clear_filters',
            inventory_booking_clear: 'clear',
        }),
        ...mapActions('Meta', {
            meta_index: 'index',
            meta_store: 'store',
            meta_clear: 'clear',
            meta_clear_item: 'clear_item',
        }),
        ...mapActions('Project', {
            project_index: 'index',
            project_clear: 'clear',
            project_clear_item: 'clear_item',
        }),
        ...mapActions('User', {
            user_index: 'index',
            user_clear: 'clear',
            user_clear_item: 'clear_item',
        }),
        ...mapActions('Tag', {
            tag_index: 'index',
            tag_custom_status_clear: 'clear_custom_status'
        }),
    },
}
</script>

<style lang="scss" scoped>
    .c-inventory-detail-pane {
        transition: 0.3s all;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 4;
        height: 100vh;
        width: 600px;
        overflow-y: auto;
        transform: translateX(600px);
    }

    .c-inventory-detail-pane.visible {
        transform: translateX(0);
    }

    .c-inventory-filters-pane {
        transition: 0.3s all;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 200;
        height: 100vh;
        width: 400px;
        overflow-y: auto;
        transform: translateX(-400px);
    }

    .c-inventory-filters-pane.visible {
        transform: translateX(0);
    }

    .c-inventory-history::-webkit-scrollbar-track,
    .c-inventory-detail-pane::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 8px;
        background-color: #F5F5F5;
    }

    .c-inventory-history::-webkit-scrollbar {
        // width: 8px;
        width: 2px;
        background-color: #F5F5F5;
    }

    .c-inventory-detail-pane::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }

    .c-inventory-history::-webkit-scrollbar-thumb,
    .c-inventory-detail-pane::-webkit-scrollbar-thumb {
        border-radius: 8px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #d2d2d2;
    }

    .c-border-c7 {
        border: 1px solid #000;
        border-color: #c7c7c7 !important
    }

    .c-margin-right {
        margin-right: 20px !important;
    }
</style>

<template>
    <div>
        <a-layout align-center class="mt-2">
            <a-flex>
                <a-card class="u-elevation-custom-1 u-rounded-corners u-shadow">
                    <template v-for="(log, index) in logList">
                        <a-layout align-center class="ma-0 py-2 px-4 u-cursor-pointer" :key="'log_section_1'+index" @click="localAccordionLogToggle(index)">
                            <a-flex shrink>
                                <div class="d-flex align-center">
                                    <img :src="require('../../../assets/images/success-imgs/icon-success.svg')" v-if="!localCheckSourece(log.source_service, log.target_service)" alt="Success" class="u-rounded-corners-lg" style="display: inline-block; max-width: 25px; max-height: 25px;">
                                    <img :src="require('../../../assets/images/logo-salesforce.svg')" v-else alt="Salesforce" style="display: inline-block; max-width: 25px; max-height: 25px;">
                                    <a-icon class="mx-2" size="16">arrow_forward</a-icon>
                                    <img :src="require('../../../assets/images/logo-salesforce.svg')" v-if="!localCheckSourece(log.source_service, log.target_service)" alt="Salesforce" style="display: inline-block; max-width: 25px; max-height: 25px;">
                                    <img :src="require('../../../assets/images/success-imgs/icon-success.svg')" v-else alt="Success" class="u-rounded-corners-lg"  style="display: inline-block; max-width: 25px; max-height: 25px;">
                                </div>
                            </a-flex>
                            <a-flex shrink xl6 xs6>
                                <p class="md-subtitle-1 grey--text text--darken-4 mb-0">{{ localGetWorkflowTitle(log.workflow_id) }}</p>
                            </a-flex>
                            <a-spacer></a-spacer>
                            <a-flex shrink>
                                <div class="u-flex u-wfull">
                                    <div class="mr-5">
                                        <s-log-status :label="log.status" :status="log.status"></s-log-status>
                                    </div>
                                    <div class="mr-5">
                                        <g-moment :value="log.updated_at" class="grey--text text--darken-5" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                                    </div>
                                    <a-btn small icon class="ma-0 grey lighten-1" width="24" height="24">
                                        <a-icon v-if="localAccordionLogStatus(index)" size="18" color="white">expand_less</a-icon>
                                        <a-icon v-else size="18" color="white">expand_more</a-icon>
                                    </a-btn>
                                </div>
                            </a-flex>
                        </a-layout>
                        <template v-if="localAccordionLogStatus(index)">
                            <a-divider class="grey lighten-3" :key="'log_source_divider'+index"></a-divider>
                            <a-layout align-center class="grey lighten-4 ma-0 py-1 px-4" :key="'log_section_2'+index">
                                <a-flex xl1 xs2 class="mr-4">
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Source</h2>
                                    <p class="body-2 mb-0 mt-1 text-capitalize">{{ log.source_service }}</p>
                                </a-flex>
                                <a-flex shrink xl1 xs2 class="mr-4">
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Source Type</h2>
                                    <p class="body-2 mb-0 mt-1">{{ log.source_object_label }}</p>
                                </a-flex>
                                <a-flex shrink xl4 xs3 class="mr-4">
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Source Object Label</h2>
                                    <template v-if="log.source_service === 'salesforce' && (log.source_object_label === 'Opportunity' || log.source_object_label === 'POC')">
                                        <span v-if="log.event_type === 'deleted'" :title="log.source_object_value"> {{ log.target_object_value | truncateText(40) }} </span>
                                        <a v-else :title="log.source_object_value" class="body-2 blue--text mb-0 mt-1" :href="localSalesforceBaseUrl+'/'+log.source_object_id" target="_blank" rel="noopener">
                                            {{ log.source_object_value | truncateText(40) }}
                                        </a>
                                    </template>
                                    <template v-if="log.source_service === 'salesforce' && log.source_object_label === 'Tech Stack'">
                                        <a :title="log.source_object_value" class="body-2 blue--text mb-0 mt-1" :href="localSalesforceBaseUrl+'/'+log.source_object_id" target="_blank" rel="noopener">
                                            {{ log.source_object_value | truncateText(40) }}
                                        </a>
                                    </template>
                                    <template v-if="log.source_service === 'salesforce' && log.source_object_label === 'Account'">
                                        <a :title="log.source_object_value" class="body-2 blue--text mb-0 mt-1" :href="localSalesforceBaseUrl+'/'+log.source_object_id" target="_blank" rel="noopener">
                                            {{ log.source_object_value | truncateText(40) }}
                                        </a>
                                    </template>
                                    <template v-if="log.source_service === 'salesforce' && log.source_object_label === 'Contact'">
                                        <a :title="log.source_object_value" class="body-2 blue--text mb-0 mt-1" :href="localSalesforceBaseUrl+'/'+log.source_object_id" target="_blank" rel="noopener">
                                            {{ log.source_object_value | truncateText(40) }}
                                        </a>
                                    </template>
                                    <template v-if="log.source_service === 'success' && log.source_object_label === 'Project' && log.source_object_value === 'Status Update'">
                                        <p class="body-2 mb-0 mt-1" :title="log.source_object_value">{{ log.source_object_value | truncateText(40) }}</p>
                                    </template>
                                    <template v-if="log.source_service === 'success' && log.source_object_label === 'Project' && log.source_object_value !== 'Status Update'">
                                        <router-link :title="log.source_object_value" target="_blank"  tag="a" class="body-2 blue--text mb-0 mt-1" :to="{name: `projects-overview`, params: { id: log.source_object_id }}">
                                            {{ log.source_object_value | truncateText(40) }}
                                        </router-link>
                                    </template>
                                    <template v-if="log.source_service === 'success' && log.source_object_label === 'Note'">
                                        <p class="body-2 mb-0 mt-1" :title="log.source_object_value">{{ log.source_object_value | truncateText(40) }}</p>
                                    </template>
                                    <template v-if="log.source_object_label === 'Customer'">
                                        <router-link :title="log.source_object_value" target="_blank"  tag="a" class="body-2 blue--text mb-0 mt-1" :to="{name: `customers`}">
                                            {{ log.target_object_value | truncateText(40) }}
                                        </router-link>
                                    </template>
                                    <template v-if="log.source_service === 'success' && log.source_object_label === 'Contact'">
                                        <p class="body-2 mb-0 mt-1" :title="log.source_object_value">{{ log.source_object_value | truncateText(40) }}</p>
                                    </template>
                                    <p v-if="!log.source_object_value" class="body-2 mb-0 mt-1">-</p>
                                </a-flex>
                                <a-flex shrink xl2 xs2 class="mr-4">
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Request Payload</h2>
                                    <p @click="localSelectLogItem(log, 'request_payload')" class="body-2 u-cursor-pointer blue--text mb-0 mt-1">View Playload</p>                                    
                                </a-flex>
                                <a-flex shrink>
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Log entries</h2>
                                    <p @click="localShowLogEntriesInformation(log)" class="body-2  u-cursor-pointer blue--text mb-0 mt-1">View Detailed Logs</p>
                                </a-flex>
                            </a-layout>
                            <a-divider class="grey lighten-2" :key="'log_target_divider'+index"></a-divider>
                            <a-layout align-center class="grey lighten-4 ma-0 py-1 px-4" :key="'log_section_3'+index">
                                <a-flex xl1 xs2 class="mr-4">
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Target</h2>
                                    <p class="body-2 mb-0 mt-1 text-capitalize">{{ log.target_service }}</p>
                                </a-flex>
                                <a-flex shrink xl1 xs2 class="mr-4">
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Target Type</h2>
                                    <p class="body-2 mb-0 mt-1">{{ log.target_object_label | truncateText(40) }}</p>       
                                </a-flex>
                                <a-flex shrink xl4 xs3 class="mr-4">
                                    <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Target Object Label</h2>
                                    <template v-if="log.target_service === 'salesforce' && log.target_object_label === 'Contact'">
                                        <a :title="log.target_object_value" class="body-2 blue--text mb-0 mt-1" :href="localSalesforceBaseUrl+'/'+log.target_object_id" target="_blank" rel="noopener">
                                            {{ log.target_object_value | truncateText(40) }}
                                        </a>
                                    </template>
                                    <template v-if="log.target_service === 'salesforce' && log.target_object_label === 'ContentNote'">
                                        <a :title="log.target_object_value" class="body-2 blue--text mb-0 mt-1" :href="localSalesforceBaseUrl+'/'+log.target_object_id" target="_blank" rel="noopener">
                                            {{ log.target_object_value | truncateText(40) }}
                                        </a>
                                    </template>
                                    <template v-if="log.target_object_label === 'Project'">
                                        <span v-if="log.event_type === 'deleted'"> {{ log.target_object_value | truncateText(40) }} </span>
                                        <router-link v-else :title="log.target_object_value" target="_blank"  tag="a" class="body-2 blue--text mb-0 mt-1" :to="{name: `projects-overview`, params: { id: log.target_object_id ? log.target_object_id : log.success_parent_id }}">
                                            {{ log.target_object_value | truncateText(40) }}
                                        </router-link>
                                    </template>
                                    <template v-if="log.target_object_label === 'Customer'">
                                        <router-link :title="log.target_object_value" target="_blank"  tag="a" class="body-2 blue--text mb-0 mt-1" :to="{name: `customers`}">
                                            {{ log.target_object_value | truncateText(40) }}
                                        </router-link>
                                    </template>
                                    <template v-if="log.target_service === 'success' && log.target_object_label === 'Contact'">
                                        <p class="body-2 mb-0 mt-1" :title="log.target_object_value">{{ log.target_object_value | truncateText(40) }}</p>
                                    </template>
                                    <template v-if="log.target_object_label === 'Opportunity' || log.target_object_label === 'POC'">
                                        <span v-if="log.event_type === 'deleted'" :title="log.target_object_value"> {{ log.target_object_value | truncateText(40) }} </span>
                                        <a v-else :title="log.target_object_value" class="body-2 blue--text mb-0 mt-1" :href="localSalesforceBaseUrl+'/'+log.target_object_id" target="_blank" rel="noopener">
                                            {{ log.target_object_value | truncateText(40) }}
                                        </a>
                                    </template>
                                    <template v-if="log.target_service === 'success' && log.source_object_label === 'Account'">
                                        <p class="body-2 mb-0 mt-1" :title="log.target_object_value">{{ log.target_object_value | truncateText(40) }}</p>
                                    </template>
                                    <p v-if="!log.target_object_value" class="body-2 mb-0 mt-1">-</p>
                                </a-flex>
                                <a-flex shrink>
                                    <template>
                                        <h2 class="caption grey--text text--darken-5 font-weight-bold text-uppercase">Response Payload</h2>
                                        <p @click="localSelectLogItem(log, 'responce_payload')" class="body-2  u-cursor-pointer blue--text mb-0 mt-1">View Playload</p> 
                                    </template>                                   
                                </a-flex>
                            </a-layout>
                        </template>
                        <a-divider class="grey lighten-3" :key="'log_divider_last'+index"></a-divider>
                    </template>
                </a-card>
            </a-flex>
        </a-layout>
        <a-dialog v-model="dialogPayloadInformation" max-width="840">
            <a-card style="max-height: 700px;">
                <a-sheet class="py-5 px-6">
                    <p class="text-h6 indigo--text text--darken-1 font-weight-medium ma-0 pa-0">
                        <a-avatar class="indigo darken-1 mr-1" size="40">
                            <a-icon size="20" dark>dynamic_form</a-icon>
                        </a-avatar>
                        Payload Information
                    </p>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <a-sheet class="py-5 px-6 grey lighten-4" v-if="selectLogitem">
                    <p class="md-subtitle-1 grey--text text--darken-4 mb-0 mr-3">{{ localGetWorkflowTitle(selectLogitem.workflow_id) }}</p>
                    <div class="d-flex align-center mt-5">
                        <img src="../../../assets/images/success-imgs/icon-success.svg" v-if="!localCheckSourece(selectLogitem.source_service, selectLogitem.target_service)" alt="Success" style="display: inline-block; max-width: 25px; max-height: 25px;">
                        <img src="../../../assets/images/logo-salesforce.svg" v-else alt="Salesforce" style="display: inline-block; max-width: 25px; max-height: 25px;">
                        <a-icon class="mx-2" size="16">arrow_forward</a-icon>
                        <img src="../../../assets/images/logo-salesforce.svg" v-if="!localCheckSourece(selectLogitem.source_service, selectLogitem.target_service)" alt="Salesforce" style="display: inline-block; max-width: 25px; max-height: 25px;">
                        <img src="../../../assets/images/success-imgs/icon-success.svg" v-else alt="Success" style="display: inline-block; max-width: 25px; max-height: 25px;">
                        <span class="px-2 grey--text text--darken-1">&bull;</span>
                        <s-log-status :label="selectLogitem.status" :status="selectLogitem.status"></s-log-status>
                    </div>
                </a-sheet>
                <a-sheet class="py-5 px-6" v-if="selectLogitem">
                    <p class="md-body-2 font-weight-bold text-uppercase mb-0 grey--text text--darken-2">{{ selectLogitem.payload_type === 'request_payload' ? 'Request Payload': 'Response Payload' }}:</p>
                    <div class="grey lighten-4 py-8 px-2" style="border: 1px solid #EEEEEE !important; border-radius: 4px; white-space: pre-wrap !important;" v-if=" selectLogitem.payload_type === 'request_payload'">
                        <pre style="white-space: pre-wrap !important;">{{ selectLogitem.source_payload }}</pre>
                    </div>
                    <div class="grey lighten-4 pa-5" style="border: 1px solid #EEEEEE !important; border-radius: 4px;" v-if="selectLogitem.payload_type === 'responce_payload'">
                        <pre style="white-space: pre-wrap !important;">{{ selectLogitem.target_payload }}</pre>
                    </div>
                </a-sheet>
            </a-card>
        </a-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { SLogStatus } from '@/config/config-shared-components'

export default {
    components: {
        SLogStatus
    },
    props: {
        logList: {
            type: Array
        },
    },
    data() {
        return {
            accordionLog: [],
            dialogPayloadInformation: false,
            selectLogitem: null,
        }
    },
    computed: {
        localSalesforceBaseUrl () {
            return this.user_self && this.user_self.organization && this.user_self.organization.salesforce_instance_url
        },
        ...mapState('MicroServiceWorkflow', {
            microservice_workflow_list: 'list',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        localCheckSourece (source_service, target_service = null) {
            return (source_service === 'salesforce' &&  target_service === 'success')
        },

        localGetWorkflowTitle(workflow_id) {
            const workflow = this.microservice_workflow_list.find(item => item.workflow_id === workflow_id)
            return workflow ? workflow.label : ''
        },

        localSelectLogItem (item, type) {
            this.selectLogitem = item
            this.selectLogitem.payload_type = type
            this.dialogPayloadInformation = true
        },

        localShowLogEntriesInformation(item) {
            this.$emit('action', item)
        },

        localAccordionLogStatus (index) {
            if (this.accordionLog[index])return this.accordionLog[index]
            this.accordionLog[index] = false
            return this.accordionLog[index]
        },

        localAccordionLogToggle (index) {
            if (this.accordionLog[index] === undefined || this.accordionLog[index] === null) {
                this.$set(this.accordionLog, index, true);
            } else {
                this.$set(this.accordionLog, index, !this.accordionLog[index]);
            }
        },
    },
}
</script>


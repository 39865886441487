<template>
    <g-project-page parentClass="pa-0">
        <div class="u-flex-center-y d-inline-flex u-cursor-pointer mb-4" @click="local_switch_router()">
            <a-icon size="16" color="grey darken-1">arrow_back</a-icon>
            <span class="md-body-2 ml-2 grey--text text--darken-1 font-weight-medium">Back to Templates</span>
        </div>

        <!-- Usecase section -->
        <div
            class="u-relative"
            @dragenter="!localAttachmentsUpdating ? localHandleDragEnter() : ''"
            @drop="localHandleDrop"
        >
            <SDragOverlay
                v-if="filesDraggedOver"
                pageType="internal"
                @leave="localHandleLeave()"
                @enter="hoveredOnInfo=false"
                @enterInfo="hoveredOnInfo=true"
            />
            <div>

            </div>
            <a-layout>
                <a-flex xs12>
                    <!-- <loader-usecase-view class="mb-4" v-if="local_loading"></loader-usecase-view> -->
                    <!-- <template v-else> -->
                        <s-usecase-view
                            :item="template_usecase_item"
                            :can-update="permission_usecase('update')"
                            :can-delete="permission_usecase('destroy')"
                            :response="template_usecase_response"
                            :title-length="1000"
                            :attachmentLoading="localAttachmentsUpdating"
                            @update="local_update_usecase"
                            @toggleVisibility="local_visibility_toggle"
                            @delete="local_usecase_delete"
                        >
                            <template #assignee></template>
                            <template #linked-master-template>
                                <a-spacer></a-spacer>
                                <div shrink v-if="local_master_template_id">
                                    <a-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="md-body-2 mt-2 pa-0 mr-5 blue--text">
                                                <a-icon size="20" class="u-icon-nudge blue--text pr-1" style="top: 0px;">link</a-icon>
                                                Linked with master template
                                            </div>
                                        </template>
                                        <span>Any changes done here will reflect in master template</span>
                                    </a-tooltip>
                                </div>
                            </template>
                            <template #tags>
                                <div class="pa-0 d-inline-flex flex-wrap">
                                    <template v-if="template_usecase_item.tags && template_usecase_item.tags.length">
                                        <template v-for="tag in template_usecase_item.tags">
                                            <g-tags :can-update="permission_usecase('update')" :tag="tag" type="Usecase" @destroy="local_association_destroy(tag, 'TemplateUsecase', template_usecase_item.id)" :key="tag.id"></g-tags>
                                        </template>
                                    </template>
                                    <a-menu bottom offset-y v-model="show_usecase_tag_add_input" :disabled="localAttachmentsUpdating" v-if="permission_usecase('update')" :close-on-content-click="false" ref="refAddTagPopup" transition="slide-y-transition" nudge-bottom="8">
                                        <template v-slot:activator="{ on }">
                                            <g-add-btn icon-size="14" v-on="on">
                                                <span class="md-caption body--text text--lighten-1 ml-1">Add Tag</span>
                                            </g-add-btn>
                                        </template>
                                        <a-sheet class="pb-1" max-width="240">
                                            <s-tags-inline-form
                                                :item="template_usecase_item"
                                                :can-update="$can('tags.store')"
                                                :list="local_get_filtered_tags(template_usecase_item.tags)"
                                                :is-menu-open="show_usecase_tag_add_input"
                                                source="TemplateUsecase"
                                                type="usecase_tag"
                                                @after-store="() => show_usecase_tag_add_input = false"
                                            ></s-tags-inline-form>
                                        </a-sheet>
                                    </a-menu>
                                </div>
                            </template>
                            <template #attachment-section>
                                <SAttachmentSection
                                    :list="dialog_detail_view ? localUsecaseAttachmentList : attachment_local_list"
                                    :customMetaItem="localUsecaseAttachmentMeta"
                                    :dropTransferList="localDroppedFilesList"
                                    :sourceId="local_template_usecase_id"
                                    :updateLoading="localAttachmentsUpdating"
                                    :loading="mixinIsLoading('attachment')"
                                    :canUpdate="permission_usecase('update')"
                                    sourceType="Usecase"
                                    @dropTransfer="localUpdateAttachmentTransfer"
                                    @loadMore="localLoadMoreAttachments()"
                                    @remove="localRemoveAttachmentItem"
                                    @delete="localDeleteAttachmentItem"
                                    autoUpdate
                                >
                                </SAttachmentSection>
                            </template>
                        </s-usecase-view>
                    <!-- </template> -->
                </a-flex>
            </a-layout>

            <!-- Success Criteria section -->
            <a-layout class="mt-10">
                <a-flex xs12 py-0>
                    <div class="u-flex align-center">
                        <div class="d-inline-flex align-center">
                            <h2 class="secondary--text text-h6">Success Criteria</h2>
                            <span class="u-rounded-corners-full grey darken-2 white--text font-weight-medium md-caption ml-2 u-flex-center" style="width: 22px; height: 22px;">{{ local_scs_length }}</span>
                        </div>
                    </div>

                    <div class="mt-6" v-if="local_loading">
                        <a-sheet class="mb-4 px-6 py-8 u-shadow u-rounded-corners" v-for="i in 3" :key="i">
                            <div class="skeleton skeleton--text"></div>
                            <div class="u-flex-center-y">
                                <a-sheet width="120" class="skeleton mr-2 skeleton--text"></a-sheet>
                                <a-sheet width="240" class="skeleton mr-2 skeleton--text"></a-sheet>
                                <a-sheet width="200" class="skeleton mr-2 skeleton--text"></a-sheet>
                                <a-sheet width="120" class="skeleton mr-2"></a-sheet>
                            </div>
                        </a-sheet>
                    </div>

                    <div v-else>
                        <draggable v-model="template_success_criterion_list" handle=".drag_handle_icon">
                            <template v-for="(success_criterion) in template_success_criterion_list">
                                <s-sc-tc-card
                                    @click="local_show_edit_pane(success_criterion, 'TemplateSuccessCriterion')"
                                    :item="success_criterion"
                                    :can-update="permission_usecase('update')"
                                    handle="drag_handle_icon"
                                    :is-loading="local_loading"
                                    :is-public="local_is_external"
                                    :class="['mt-6 u-cursor-pointer']"
                                    :key="success_criterion.id"
                                    module="TemplateSuccessCriterion"
                                    hide-meta-info
                                >
                                    <template #status></template>
                                    <template #assignee></template>
                                    <template #attachment-count>
                                        <g-attachment-count-info :count="success_criterion.attachments_count" fontSize="14"/>
                                    </template>
                                </s-sc-tc-card>
                            </template>
                        </draggable>
                    </div>

                    <template v-if="permission_usecase('update')">
                        <a-text-field
                            v-model="success_criterion_title"
                            placeholder="Add new Success Criteria"
                            :class="['u-rounded-corners mt-6 u-shadow']"
                            :disabled="local_is_loading('sc_store') || localAttachmentsUpdating"
                            v-if="!local_loading"
                            solo flat hide-details
                            @keydown.enter="local_template_success_criterion_store"
                        >
                            <template #prepend-inner>
                                <a-progress-circular width="3" size="20" class="mr-1" indeterminate color="orange darken-2" v-if="local_is_loading('sc_store')"></a-progress-circular>
                                <a-icon size="24" color="body lighten-4" v-else>add</a-icon>
                            </template>
                            <template #append>
                                <span @click="local_is_loading('sc_store') ? '' : local_template_success_criterion_store()" v-ripple class="u-cursor-pointer d-block px-1 u-rounded-corners">
                                    <span class="md-caption text-uppercase body--text text--lighten-3 mr-2">Enter</span>
                                    <a-icon size="24" color="body lighten-4">keyboard_return</a-icon>
                                </span>
                            </template>
                        </a-text-field>
                    </template>
                </a-flex>
            </a-layout>

            <a-divider class="grey lighten-2 mt-10 mb-8"></a-divider>

            <!-- Testcase section -->
            <a-layout>
                <a-flex xs12 py-0>
                    <div class="u-flex align-center">
                        <div class="d-inline-flex align-center">
                            <h2 class="secondary--text text-h6">Test Cases</h2>
                            <span class="u-rounded-corners-full grey darken-2 white--text font-weight-medium md-caption ml-2 u-flex-center" style="width: 22px; height: 22px;">{{ local_tcs_length }}</span>
                        </div>
                    </div>

                    <div class="mt-6" v-if="local_loading">
                        <a-sheet class="mb-4 px-6 py-8 u-shadow u-rounded-corners" v-for="i in 3" :key="i">
                            <div class="skeleton skeleton--text"></div>
                            <div class="u-flex-center-y">
                                <a-sheet width="120" class="skeleton mr-2 skeleton--text"></a-sheet>
                                <a-sheet width="240" class="skeleton mr-2 skeleton--text"></a-sheet>
                                <a-sheet width="200" class="skeleton mr-2 skeleton--text"></a-sheet>
                                <a-sheet width="120" class="skeleton mr-2"></a-sheet>
                            </div>
                        </a-sheet>
                    </div>

                    <div v-else>
                        <draggable v-model="template_testcase_list" handle=".drag_handle_icon">
                            <template v-for="(testcase) in template_testcase_list">
                                <s-sc-tc-card
                                    @click="local_show_edit_pane(testcase, 'TemplateTestcase')"
                                    :item="testcase"
                                    :class="['mt-6 u-cursor-pointer']"
                                    :can-update="permission_usecase('update')"
                                    :is-loading="local_loading"
                                    handle="drag_handle_icon"
                                    :is-public="local_is_external"
                                    :key="testcase.id"
                                    module="TemplateTestcase"
                                    hide-meta-info
                                >
                                    <template #status></template>
                                    <template #assignee></template>
                                    <template #attachment-count>
                                        <g-attachment-count-info :count="testcase.attachments_count" fontSize="14"/>
                                    </template>
                                </s-sc-tc-card>
                            </template>
                        </draggable>
                    </div>

                    <template v-if="permission_usecase('update')">
                        <a-text-field
                            v-model="testcase_title"
                            placeholder="Add new Test Case"
                            :class="['u-rounded-corners mt-6 u-shadow']"
                            :disabled="local_is_loading('tc_store') || localAttachmentsUpdating"
                            v-if="!local_loading"
                            solo flat hide-details
                            @keydown.enter="local_template_testcase_store"
                        >
                            <template #prepend-inner>
                                <a-progress-circular width="3" size="20" class="mr-1" indeterminate color="orange darken-2" v-if="local_is_loading('tc_store')"></a-progress-circular>
                                <a-icon size="24" color="body lighten-4" v-else>add</a-icon>
                            </template>
                            <template #append>
                                <span @click="local_is_loading('tc_store') ? '' : local_template_testcase_store()" v-ripple class="u-cursor-pointer d-block px-1 u-rounded-corners">
                                    <span class="md-caption text-uppercase body--text text--lighten-3 mr-2">Enter</span>
                                    <a-icon size="24" color="body lighten-4">keyboard_return</a-icon>
                                </span>
                            </template>
                        </a-text-field>
                    </template>
                </a-flex>
            </a-layout>
        </div>

        <!-- Detail View -->
        <a-dialog v-model="dialog_detail_view" width="700" :persistent="localAttachmentLoading">
            <s-sc-tc-item-view
                :edit-item="local_edit_pane_item"
                :module="edit_pane_module"
                :loaders="loaders"
                :is-open="dialog_detail_view"
                parent-type="TemplateUsecase"
                :parent-item="template_usecase_item"
                :testcase-list="template_testcase_list"
                :success-criteria-list="template_success_criterion_list"
                :can-update="permission_usecase('update')"
                :can-delete="permission_usecase('destroy')"
                :editor-height="300"
                @close="local_clear_edit_pane_view"
                @update="local_update_pane_item"
                @attachmentLoading="localSetLoadingStatus"
                @destroy="local_destroy_pane_item"
                hide-activity hide-metas hide-status solo-delete
            >
                <template #status></template>
                <template #assignee></template>
            </s-sc-tc-item-view>
        </a-dialog>

        <!-- Dialog leave page -->
        <a-dialog v-model="dialogLeavePage" max-width="420">
            <div class="white pa-6 pb-4">
                <div class="mb-3 text-center">
                    <div class="md-subtitle-1 font-weight-medium grey--text text--darken-4">
                        The attachments are currently being uploaded.
                    </div>
                    <div class="mt-2 md-body-2 grey--text text--darken-2">Please wait...</div>
                </div>
                <div class="text-center">
                    <LoaderDotAnimation customSize="10"></LoaderDotAnimation>
                </div>
            </div>
        </a-dialog>
    </g-project-page>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {
    SUsecaseView,
    STagsInlineForm,
    SScTcCard,
    SScTcItemView,
    SAttachmentSection
} from '@/config/config-shared-components'
import mixinLoader from '@/mixins/mixin-module-loading-setup'
import SDragOverlay from '@/components/Attachments/SharedDragOverlay.vue'
import mixinAttachmentsInternal from '@/mixins/mixin-internal-attachment'
import LoaderDotAnimation from '@/components/Loader/LoaderDotAnimation.vue'


export default {
    mixins: [ mixinLoader, mixinAttachmentsInternal ],

    components: {
        SUsecaseView,
        STagsInlineForm,
        SScTcCard,
        SScTcItemView,
        SAttachmentSection,
        SDragOverlay,
        LoaderDotAnimation
    },

    data() {
        return {
            loaders: {
                sidebar_tags_loading: false,
                sidebar_time_est_loading: false,
                sidebar_comments_loading: false,
                content_autosave_response: false,
                sc_store: false,
                tc_store: false,
            },
            local_loading: true,
            show_usecase_tag_add_input: false,
            success_criterion_title: '',
            testcase_title: '',
            edit_pane_module: '',
            local_edit_pane_item: { tags: [], comments: [] },
            confirm_delete: false,
            content_autosave_timeout: false,
            dialog_detail_view: false,
            local_tag_fields: { 'filter[type]': 'usecase_tag' },
            template_usecase_include: {
                'include': 'tags,created_by,updated_by,attachmentsCount',
                'fields[tags]': 'tags.id,tags.label,tags.color',
                'fields[template_usecases]': 'id,title,description_json,description_json,template_group_id,visibility,created_at,updated_at,updated_by_id,created_by_id',
            },
            local_template_testcase_fields: {
                'include' : 'tags,attachmentsCount',
                'fields[tags]': 'tags.id,tags.label,tags.color',
                'fields[template_testcases]': 'id,title,description_json,template_usecase_id,order',
            },
            local_template_success_criterion_fields: {
                'include' : 'tags,attachmentsCount',
                'fields[tags]': 'tags.id,tags.label,tags.color',
                'fields[template_success_criteria]': 'id,title,description_json,template_usecase_id,order',
            },
            local_autosave_status: false,

            //Attachments
            filesDraggedOver: false,
            hoveredOnInfo: false,
            FILTER: {
                parent_id: 'filter[parent_id]',
                parent_type: 'filter[parent_type]',
                source_id: 'filter[source_id]',
                source_type: 'filter[source_type]',
                fields: 'fields[attachments]',
                page: 'page'
            },
            attachment_fields: 'id,name,size,extension,order,source_id,source_type',
            localDroppedFilesList: [],
            localAttachmentFilters: {},
            backupAttachmentList: [],
            localAttachmentTimeout: null,
            localAttachmentLoading: false,
            localUsecaseAttachmentList: [],
            localUsecaseAttachmentMeta: {},
            dialogLeavePage: false
        }
    },

    watch: {
        dialog_detail_view (val) {
            if (!val) this.local_clear_edit_pane_view()
        },

        async show_usecase_tag_add_input (val) {
            if (val) {
                await this.tag_clear()
                this.tag_index(this.local_tag_fields)
            }
        },

        localAttachmentsUpdating (val) {
            if (!val) this.dialogLeavePage = false
        }
    },

    mounted() {
        this.local_index()
    },

    beforeDestroy () {
        this.localRemoveListeners()
    },

    beforeRouteLeave (toRoute, fromRoute, nextRoute) {
        if (!this.localAttachmentsUpdating) return nextRoute()
        this.dialogLeavePage = true
        nextRoute(false)
        this.pageToLeave = nextRoute
    },

    computed: {
        local_template_usecase_id () {
            return this.$route.params.system_usecase_id
        },
        local_master_template_id() {
            return this.$route.params.master_template_id
        },
        template_testcase_list: {
            get() {
                return this.$store.state.TemplateTestcase.list
            },
            set(list) {
                this.local_template_testcase_reorder(list)
            }
        },

        template_success_criterion_list: {
            get() {
                return this.$store.state.TemplateSuccessCriterion.list
            },
            set(list) {
                this.local_template_success_criterion_reorder(list)
            }
        },

        local_scs_length () {
            return this.template_success_criterion_list && this.template_success_criterion_list.length
        },

        local_tcs_length () {
            return this.template_testcase_list && this.template_testcase_list.length
        },

        local_is_external () {
            return this.template_usecase_item && this.template_usecase_item.visibility === 'external'
        },

        local_pane_response () {
            return this.edit_pane_module === 'TemplateTestcase' ? this.template_testcase_response : this.template_success_criterion_response
        },

        localAttachmentsUpdating () {
            return this.mixinIsLoading('attachment-update') || this.mixinIsLoading('attachment-delete')
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('TemplateGroup', {
            template_group_item: 'item',
        }),

        ...mapState('TemplateUsecase', {
            template_usecase_item: 'item',
            template_usecase_response: 'response',
            template_usecase_form_mode: 'form_mode',
        }),

        ...mapState('TemplateTestcase', {
            template_testcase_item: 'item',
            template_testcase_filters: 'filters',
            template_testcase_response: 'response',
            template_testcase_form_mode: 'form_mode',
        }),

        ...mapState('TemplateSuccessCriterion', {
            template_success_criterion_item: 'item',
            template_success_criterion_filters: 'filters',
            template_success_criterion_response: 'response',
            template_success_criterion_form_mode: 'form_mode',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
            tag_item: 'item',
            tag_filters: 'filters',
        }),

        ...mapState('Association', {
            association_response: 'response',
        }),

        ...mapState('Attachment', {
            attachment_list: 'list',
            attachment_local_list: 'local_list',
            attachment_delete_list: 'delete_list',
            attachment_upload_list: 'upload_list',
            attachment_meta: 'meta',
            attachment_response: 'response',
            attachment_delete_response: 'delete_response',
        }),
    },

    methods: {
        async local_index() {
            if (this.$can('templates.index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.template_usecase_clear_item()
            await this.template_testcase_clear()
            await this.template_success_criterion_clear()

            await this.template_usecase_select({ id: this.$route.params.system_usecase_id })
            await this.local_load_usecase_child_data()
            this.local_loading = false
            await this.local_load_usecase_item()
            this.localLoadUsecaseAttachments()
            if (this.permission_usecase('update')) this.localAddListeners()
        },

        async localLoadUsecaseAttachments () {
            this.mixinSetLoading('attachment')
            this.attachment_clear_response()
            this.attachment_clear_upload_list()
            this.localSetAttachmentFilters()
            await this.attachment_index({ params: this.localAttachmentFilters })
            this.localSetAttachmentParams()
            this.mixinResetLoading('attachment')
            this.mixinUpdateLocalAttachmentList({ list: this.attachment_list })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localSetAttachmentFilters () {
            this.localAttachmentFilters[this.FILTER.parent_id] = this.template_usecase_item.template_group_id,
            this.localAttachmentFilters[this.FILTER.parent_type] = 'TemplateGroup'
            this.localAttachmentFilters[this.FILTER.source_id] = this.local_template_usecase_id
            this.localAttachmentFilters[this.FILTER.source_type] = 'TemplateUsecase'
            this.localAttachmentFilters[this.FILTER.fields] = this.attachment_fields
            this.localAttachmentFilters[this.FILTER.page] = 1
            this.localAttachmentFilters.count = 5
        },

        async local_load_usecase_child_data () {
            await this.local_load_template_success_criteria()
            await this.local_load_template_tetscases()
        },

        async local_load_template_success_criteria () {
            await this.template_success_criterion_index({
                'filter[template_usecase_id]': this.local_template_usecase_id,
                'include': 'tags,attachmentsCount',
                'fields[tags]': 'tags.id,tags.label,tags.color',
                'fields[template_success_criteria]': 'id,title,description_json,template_usecase_id,order',
                'sort': 'order'
            })
        },

        async local_load_template_tetscases () {
            await this.template_testcase_index({
                'filter[template_usecase_id]': this.local_template_usecase_id,
                'include': 'tags,attachmentsCount',
                'fields[tags]': 'tags.id,tags.label,tags.color',
                'fields[template_testcases]': 'id,title,description_json,template_usecase_id,order',
                'sort': 'order'
            })
        },

        async local_load_usecase_item() {
            await this.template_usecase_show({id: this.local_template_usecase_id, params : this.template_usecase_include })
        },

        async local_update_usecase (evt) {
            await this.template_usecase_update({
                id: this.local_template_usecase_id,
                template_group_id: this.template_usecase_item.template_group_id,
                title: this.template_usecase_item.title,
                description_json: this.template_usecase_item.description_json,
            })
            if (this.template_usecase_response.status !== 'success') return
            this.local_load_usecase_item()
        },

        async local_visibility_toggle (id, state) {
            const state_item = {}
            state_item.id = id
            state_item.state = state === 'external' ? 'internal' : 'external'
            await this.template_usecase_visibility(state_item)
            if (this.template_usecase_response.status !== 'success') return
            this.template_usecase_item.visibility = state_item.state
        },

        local_usecase_delete (id) {
            this.template_usecase_delete({ id })
            this.$router.push({ name: 'master-templates-system-list-usecases' })
        },

        // Testcase CRUD
        async local_template_testcase_store() {
            if (!this.testcase_title || (this.testcase_title && !this.testcase_title.trim())) return

            this.local_set_loading('tc_store', true)
            await this.template_testcase_clear_item()

            this.template_testcase_item.template_usecase_id = this.local_template_usecase_id
            this.template_testcase_item.title = this.testcase_title
            await this.template_testcase_store({ ...this.template_testcase_item })

            if (this.template_testcase_response.status !== 'success') return this.local_set_loading('tc_store', true)
            this.testcase_title = ''
            this.local_set_loading('tc_store', false)
        },

        async local_testcase_update () {
            await this.template_testcase_update(this.local_edit_pane_item)
            if (this.template_testcase_response && this.template_testcase_response.status !== 'success') {
                this.loaders.content_autosave_response = false
                return this.$notify('error', 'Something went wrong, please re-check the fields.')
            }
        },

        local_show_autosave_response () {
            this.loaders.content_autosave_response = true
            setTimeout(() => this.loaders.content_autosave_response = false, 2000)
        },

        async local_testcase_destroy (id) {
            await this.template_testcase_destroy({ id })
            if (this.template_testcase_response && this.template_testcase_response.status !== 'success') return
            this.$notify('success', 'Test Case deleted successfully!')
        },

        async local_template_testcase_reorder (list) {
            this.template_testcase_reorder({list: list})
        },
        // Testcase CRUD --- END

        // Success Criteria CRUD
        async local_template_success_criterion_store() {
            if (!this.success_criterion_title || (this.success_criterion_title && !this.success_criterion_title.trim())) return

            this.local_set_loading('sc_store', true)
            await this.template_success_criterion_clear_item()

            this.template_success_criterion_item.template_usecase_id = this.local_template_usecase_id
            this.template_success_criterion_item.title = this.success_criterion_title
            await this.template_success_criterion_store({ ...this.template_success_criterion_item })

            if (this.template_success_criterion_response.status !== 'success') return this.local_set_loading('sc_store', false)
            this.success_criterion_title = ''
            this.local_set_loading('sc_store', false)
        },

        async local_success_criterion_update() {
            await this.template_success_criterion_update(this.local_edit_pane_item)
            if (this.template_success_criterion_response && this.template_success_criterion_response.status !== 'success') {
                this.loaders.content_autosave_response = false
                return this.$notify('error', 'Something went wrong, please re-check the fields.')
            }
        },

        async local_success_criterion_destroy(id) {
            await this.template_success_criterion_destroy({id: id})
            if (this.template_success_criterion_response && this.template_success_criterion_response.status !== 'success') return
            this.$notify('success', 'Success Criteria deleted successfully!')
        },

        async local_template_success_criterion_reorder (list) {
            this.template_success_criterion_reorder({list: list})
        },
        // Success Criteria CRUD --- END

        // Sidebar Pane CRUD
        async local_show_edit_pane (edit_item, module) {
            if (this.localAttachmentsUpdating) return
            this.local_clear_pane_store_items(module)
            if (this.local_edit_pane_item.id && (this.local_edit_pane_item.id !== edit_item.id)) {
                this.local_update_list_item(_.cloneDeep(this.local_edit_pane_item), this.edit_pane_module)
            }
            if (this.local_edit_pane_item && this.local_edit_pane_item.id === edit_item.id) return

            await this.local_reset_edit_pane()
            this.edit_pane_module = module
            this.localSetAttachmentParams()
            if (!this.dialog_detail_view) this.dialog_detail_view = true
            this.local_edit_pane_item = _.cloneDeep(edit_item)
            this.local_load_tags()
            await this.local_setup_pane(edit_item)
        },

        async local_load_tags () {
            await this.tag_clear()
            this.tag_index(this.local_tag_fields)
        },

        local_clear_pane_store_items () {
            if (module === 'TemplateTestcase') return this.template_testcase_clear_item()
            this.template_success_criterion_clear_item()
        },

        local_update_list_item (item, type) {
            const hasError = this.local_pane_response && this.local_pane_response.status !== 'success'
            if (hasError) {
                const oldItem = (type === 'TemplateTestcase' ? this.template_testcase_list : this.template_success_criterion_list).find(testcase => testcase.id === item.id)
                if (type === 'TemplateTestcase') return this.template_testcase_update_list(oldItem)
                return this.template_success_criterion_update_list(oldItem)
            }
            if (type === 'TemplateTestcase') return this.template_testcase_update_list(item)
            this.template_success_criterion_update_list(item)
        },

        local_update_pane_item (evt) {
            if (this.edit_pane_module === 'TemplateTestcase') this.local_testcase_update(evt)
            else this.local_success_criterion_update(evt)
        },

        local_destroy_pane_item (evt) {
            if (this.edit_pane_module === 'TemplateTestcase') this.local_testcase_destroy(evt)
            else this.local_success_criterion_destroy(evt)
            this.local_clear_edit_pane_view()
            this.local_reset_edit_pane()
        },

        local_clear_edit_pane_view () {
            this.dialog_detail_view = false
            this.local_edit_pane_item = {}
            this.attachment_update_list(this.localUsecaseAttachmentList)
            this.attachment_update_local_meta(this.localUsecaseAttachmentMeta)
            this.mixinUpdateLocalAttachmentList({ list: this.localUsecaseAttachmentList })
            this.template_testcase_clear_item()
            this.template_success_criterion_clear_item()
        },

        async local_reset_edit_pane () {
            this.loaders.content_autosave_response = false
            this.local_edit_pane_item = { tags: [], comments: [], status_id: null, status: {} }
        },

        async local_setup_pane (edit_item) {
            this.local_load_time_est_tags(edit_item)
            this.local_load_static_fields(edit_item)
        },

        async local_load_time_est_tags (model) {
            this.loaders.sidebar_time_est_loading = true
            const params = { include: 'tags', 'fields[tags]': 'tags.id,label,tags.type,color' }
            const testcase_params = { 'fields[template_testcases]': 'id' }
            const success_criteria_params = { 'fields[template_success_criteria]': 'id' }

            if (this.edit_pane_module === 'TemplateTestcase') {
                await this.local_testcase_show_item(model.id, { ...params, ...testcase_params }, 'update-item')
                this.local_edit_pane_item.tags = this.template_testcase_item.tags
            } else {
                await this.local_success_criterion_show_item(model.id, { ...params, ...success_criteria_params }, 'update-item')
                this.local_edit_pane_item.tags = this.template_success_criterion_item.tags
            }
            this.loaders.sidebar_time_est_loading = false
        },

        async local_load_static_fields (item) {
            if (this.edit_pane_module === 'TemplateTestcase') {
                await this.local_testcase_show_item(item.id, { 'fields[template_testcases]': 'id,title,description_json' }, 'update-item')
            } else {
                await this.local_success_criterion_show_item(item.id, { 'fields[template_success_criteria]': 'id,title,description_json' }, 'update-item')
            }
        },

        async local_testcase_show_item (id, params, type) {
            await this.template_testcase_show_item({ id, params: { ...params }, type })
        },

        async local_success_criterion_show_item (id, params, type) {
            await this.template_success_criterion_show_item({ id, params: { ...params }, type })
        },
        // Sidebar Pane CRUD --- END

        // Extras
        permission_usecase (type = 'update') {
            return this.$can(`templates.${type}`)
        },

        async local_association_destroy (data, type = null, target_id = null) {
            await this.association_destroy(data.association)
            if (this.association_response.status !== 'success') return
            const index = this.template_usecase_item.tags.findIndex(item => item.id === data.id)
            if (index !== -1) this.template_usecase_item.tags.splice(index, 1)
        },

        local_get_filtered_tags(item_list) {
            return _.differenceBy(this.tag_list, item_list, 'id')
        },

         local_set_loading (type, value) {
            this.loaders[type] = value
        },

        local_is_loading (type) {
            return this.loaders[type]
        },

        async local_destroy(id) {
            await this.template_usecase_destroy({id: id})
            if(this.template_usecase_response.status == 'success') {
                this.$router.push({name: 'master-templates-system-list-usecases'})
            }
        },

        local_switch_router() {
            if(this.local_master_template_id) {
                this.$router.push({name: 'master-templates-usecases', params:  { id : this.local_master_template_id }})
            }else{
                this.$router.push({name: 'master-templates-system-list-usecases'})
            }
        },

        // Attachments - CRUD

        localAddListeners () {
            window.removeEventListener('beforeunload', this.localBeforeUnloadEvent)
            this.mixinAttachmentListeners('add')
        },

        localRemoveListeners () {
            window.removeEventListener('beforeunload', this.localBeforeUnloadEvent)
            this.mixinAttachmentListeners('remove')
        },

        localBeforeUnloadEvent () {
            if (this.localAttachmentsUpdating) {
                return e.returnValue = 'You have unsaved changes. Are you sure you want to leave?'
            }
        },

        localHandleDragEnter (e) {
            this.filesDraggedOver = true
        },

        localHandleLeave (e) {
            if (this.hoveredOnInfo) return
            this.filesDraggedOver = false
        },

        localHandleDrop (e) {
            if (this.localAttachmentsUpdating || this.local_loading) return
            const droppedFiles = Array.from(e.dataTransfer.files)
            if (_.size(droppedFiles) > 5) return this.localValidateAttachmentCount()
            this.localDroppedFilesList = _.cloneDeep(droppedFiles)
            this.filesDraggedOver = false
        },

        localValidateAttachmentCount () {
            this.$notify('warning', 'Sorry, you can only upload up to 5 files at a time')
            this.filesDraggedOver = false
        },

        localRemoveAttachmentItem (id) {
            this.mixinRemoveAttachmentItem(id)
        },

        localDeleteAttachmentItem (id) {
            this.mixinDeleteAttachmentItem(id)
            this.localUpdateUsecaseAttachment()
        },

        async localUpdateUsecaseAttachment () {
            this.attachment_clear_response()
            this.mixinRemoveInvalidFiles()
            await this.localBulkDeleteAttachment()
            await this.localUploadAttachment()
            this.mixinResetLoading('attachment-update')
        },

        async localUploadAttachment () {
            if (!_.size(this.attachment_upload_list)) return
            const formData = new FormData();
            formData.append('parent_id', this.template_usecase_item.template_group_id)
            formData.append('parent_type', 'TemplateGroup')
            formData.append('source_id', this.local_template_usecase_id)
            formData.append('source_type', 'TemplateUsecase')
            formData.append('order', -1)
            this.attachment_upload_list.forEach(file => {
                formData.append('files[]', file)
                formData.append('ids[]', file.id)
            })
            await this.attachment_upload(formData)

            if (!this.$status(this.attachment_response)) return this.mixinHandleAttachmentErrors()
            this.localShowUsecaseAttachments()
            this.attachment_clear_upload_list()
        },

        async localBulkDeleteAttachment () {
            if (!_.size(this.attachment_delete_list)) return
            this.mixinSetLoading('attachment-delete')
            await this.attachment_bulk_destroy({ids: this.attachment_delete_list})
            if (this.$status(this.attachment_delete_response)) {
                if (!_.size(this.attachment_upload_list)) this.localShowUsecaseAttachments()
                if (!_.size(this.attachment_local_list) && !_.size(this.attachment_upload_list)) this.localLoadUsecaseAttachments()
                this.attachment_clear_delete_list()
            }
            this.mixinResetLoading('attachment-delete')
        },

        async localShowUsecaseAttachments () {
            this.localAttachmentFilters.count = _.size(this.attachment_local_list)
            this.localAttachmentFilters.page = 1
            await this.attachment_index({ mode: 'show', params: this.localAttachmentFilters})
            this.localSetAttachmentParams()
            this.mixinUpdateLocalAttachmentList({ list: this.attachment_list})
            this.backupAttachmentList = _.cloneDeep(this.attachment_list)
        },

        localSetAttachmentParams () {
            this.localUsecaseAttachmentList = _.cloneDeep(this.attachment_list)
            this.localUsecaseAttachmentMeta = _.cloneDeep(this.attachment_meta)
            this.localUsecaseAttachmentMeta.attachment_list_count = _.size(this.localUsecaseAttachmentList)
        },

        localUpdateAttachmentTransfer (params) {
            clearTimeout(this.localAttachmentTimeout)
            this.attachment_clear_response()
            const { list, transferFiles } = params || {}
            const totalUploadList = !!_.size(this.attachment_upload_list) ? [...transferFiles, ...this.attachment_upload_list] : [...transferFiles]
            this.mixinPreserveListChanges({ list: list, type: 'drop_transfer' })
            this.attachment_update_upload_list(totalUploadList)
            this.mixinSetLoading('attachment-update')
            this.localAttachmentTimeout = setTimeout(() => this.localUpdateUsecaseAttachment(), 2000)
        },

        async localLoadMoreAttachments () {
            if (this.attachment_meta.total === _.size(this.attachment_list)) return

            this.mixinSetLoading('attachment')

            const defaultCount = 5
            const isListShortOfItem = _.size(this.attachment_list)%5 === 0
            const loadType = isListShortOfItem ? 'load_more' : 'index'
            this.localAttachmentFilters.count = isListShortOfItem ? defaultCount : this.attachment_meta.current_page*defaultCount
            this.localAttachmentFilters[this.FILTER.page] = isListShortOfItem ? this.attachment_meta.current_page+1 : 1
            await this.attachment_index({ mode: loadType, params: this.localAttachmentFilters })
            this.localSetAttachmentParams()
            this.mixinResetLoading('attachment')
            this.mixinPreserveListChanges({ list: this.attachment_list, type: 'load_more' })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localClearAttachmentUpdate () {
            this.mixinUpdateLocalAttachmentList({ list: this.backupAttachmentList })
            this.mixinClearAttachmentUpdate()
        },

        localSetLoadingStatus (e) {
            this.localAttachmentLoading = e
        },

        ...mapActions('TemplateUsecase', {
            template_usecase_show: 'show',
            template_usecase_update: 'update',
            template_usecase_visibility: 'visibility',
            template_usecase_open: 'open',
            template_usecase_close: 'close',
            template_usecase_delete: 'destroy',
            template_usecase_client_visible: 'client_visible',
            template_usecase_client_invisible: 'client_invisible',
            template_usecase_select: 'select',
            template_usecase_destroy: 'destroy',
            template_usecase_clear_item: 'clear_item',
        }),

        ...mapActions('TemplateTestcase', {
            template_testcase_index: 'index',
            template_testcase_update_list: 'update_list',
            template_testcase_show: 'show',
            template_testcase_show_item: 'show_item',
            template_testcase_store: 'store',
            template_testcase_update: 'update',
            template_testcase_status: 'status',
            template_testcase_reorder: 'reorder',
            template_testcase_destroy: 'destroy',
            template_testcase_select: 'select',
            template_testcase_clear: 'clear',
            template_testcase_clear_item: 'clear_item',
        }),

        ...mapActions('TemplateSuccessCriterion', {
            template_success_criterion_index: 'index',
            template_success_criterion_update_list: 'update_list',
            template_success_criterion_show: 'show',
            template_success_criterion_show_item: 'show_item',
            template_success_criterion_store: 'store',
            template_success_criterion_update: 'update',
            template_success_criterion_status: 'status',
            template_success_criterion_reorder: 'reorder',
            template_success_criterion_destroy: 'destroy',
            template_success_criterion_select: 'select',
            template_success_criterion_clear: 'clear',
            template_success_criterion_clear_item: 'clear_item',
        }),

        ...mapActions('Association', {
            association_store: 'store',
            association_destroy: 'destroy',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_select: 'select',
            tag_clear: 'clear',
            tag_clear_item: 'clear_item',
        }),

        ...mapActions('Attachment', {
            attachment_index: 'index',
            attachment_upload: 'upload',
            attachment_update_list: 'update_list',
            attachment_update_local_meta: 'update_meta',
            attachment_update_upload_list: 'update_upload_list',
            attachment_remove_upload_item: 'remove_upload_list_item',
            attachment_bulk_destroy: 'bulk_destroy',
            attachment_clear_upload_list: 'clear_upload_list',
            attachment_clear_delete_list: 'clear_delete_list',
            attachment_clear_response: 'clear_response',
        }),
    },
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"u-flex white py-6 u-sticky u-z10",class:[{ 'c-dash-card-sticky': _vm.pageScrolled && _vm.localRouteIsDashColumns, 'u-wrap': _vm.configuration_main_navbar }],style:({ top: '73px', rowGap: _vm.configuration_main_navbar ? '10px' : '0' })},[_c('div',{staticClass:"u-flex u-border u-rounded-corners u-flex-center px-1",staticStyle:{"height":"36px"}},[_c('a-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('a-card',_vm._g({directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'light-blue--text' }),expression:"{ class: 'light-blue--text' }"}],staticClass:"u-rounded-corners u-relative u-overflow-hidden u-cursor-pointer u-flex-center py-0",style:([{
                            border: _vm.$router.route.isDashboardColumns() ?
                                '1px solid #BBDEFB !important' :
                                '1px solid transparent !important'
                        }]),attrs:{"to":{ name: 'dashboard-columns' },"width":"36","height":"28","flat":""}},on),[(_vm.$route.name === 'dashboard-columns')?_c('a-sheet',{staticClass:"u-absolute u-rounded-corners-sm u-wfull u-hfull",staticStyle:{"top":"0px","left":"0px"},attrs:{"color":"#e3f1fd"}}):_vm._e(),_c('a-card-text',{staticClass:"py-1 px-2 text-center"},[_c('a-icon',{staticClass:"ma-0",attrs:{"size":"20","color":_vm.$route.name === 'dashboard-columns' ? 'blue' : 'grey darken-1'}},[_vm._v("view_week")])],1)],1)]}}])},[_c('span',[_vm._v("Column View")])]),_c('a-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('a-card',_vm._g({directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'light-blue--text' }),expression:"{ class: 'light-blue--text' }"}],staticClass:"u-rounded-corners u-relative u-overflow-hidden u-cursor-pointer u-flex-center py-0",style:([{
                            border: _vm.$router.route.isDashboardTimeline() ?
                                '1px solid #BBDEFB !important' :
                                '1px solid transparent !important'
                        }]),attrs:{"to":{ name: 'dashboard-timeline' },"width":"36","height":"28","flat":""}},on),[(_vm.$route.name === 'dashboard-timeline')?_c('a-sheet',{staticClass:"u-absolute u-rounded-corners-sm u-wfull u-hfull",staticStyle:{"top":"0px","left":"0px"},attrs:{"color":"#e3f1fd"}}):_vm._e(),_c('a-card-text',{staticClass:"py-1 px-2 text-center"},[_c('a-icon',{staticClass:"ma-0",attrs:{"size":"20","color":_vm.$route.name === 'dashboard-timeline' ? 'blue' : 'grey darken-1'}},[_vm._v("power_input")])],1)],1)]}}])},[_c('span',[_vm._v("Timeline View")])]),(_vm.$can('insights_dashboard.index') && _vm.local_org_insights_enabled)?_c('a-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('a-card',_vm._g({directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'light-blue--text' }),expression:"{ class: 'light-blue--text' }"}],staticClass:"u-rounded-corners u-relative u-overflow-hidden u-cursor-pointer u-flex-center py-0",style:([{
                            border: _vm.local_route_is_insights ?
                                '1px solid #BBDEFB !important' :
                                '1px solid transparent !important'
                        }]),attrs:{"to":{ name: 'dashboard-insights' },"flat":"","width":"36","height":"28"}},on),[(_vm.local_route_is_insights)?_c('a-sheet',{staticClass:"u-absolute u-rounded-corners-sm u-wfull u-hfull",staticStyle:{"top":"0px","left":"0px"},attrs:{"color":"#e3f1fd"}}):_vm._e(),_c('a-card-text',{staticClass:"py-1 px-2 text-center"},[_c('a-icon',{staticClass:"ma-0",attrs:{"size":"20","color":_vm.local_route_is_insights ? 'blue' : 'grey darken-1'}},[_vm._v("bar_chart")])],1)],1)]}}],null,false,51101085)},[_c('span',[_vm._v("Insights View")])]):_vm._e()],1),_vm._t("insights-dropdown"),_vm._t("status-filter"),_vm._t("team-filter"),_vm._t("territory"),_c('a-spacer'),_vm._t("sort"),_vm._t("zoom"),_vm._t("insights"),_vm._t("insights-board-more"),_vm._t("arrows"),_vm._t("loading")],2),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from 'axios'

export default function generateMicroservicesBaseUrl (baseUrl, authToken = null, credentials = true) {
    const Axios = axios.create({
        baseURL: `${baseUrl}`,
        withCredentials: credentials,
        headers: {
            Accept: 'application/json',
            Authorization: authToken, 
            'Content-Type': 'application/json'
        }
    })
    Axios.CancelToken = axios.CancelToken
    Axios.isCancel = axios.isCancel

    return Axios
}

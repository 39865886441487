<template>
    <v-slider v-model="showModel" v-on="$listeners" v-bind="$attrs">
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
            <template v-if="scopedSlotName === 'default'">
                <slot></slot>
            </template>
            <slot v-else :name="scopedSlotName" v-bind="slotData" />
        </template>
    </v-slider>
</template>

<script>
import { VSlider } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VSlider },
    props: ['value'],

    data () {
        return {
            ref: null
        }
    },

    mounted () {
        this.ref = this.$el
    },

    computed: {
        showModel: {
            get () {
                return this.value
            },
            set (val) {
                this.$emit('update:value', val)
            }
        }
    }
}
</script>

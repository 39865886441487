<template>
    <div class="c-log-status" :class="['c-log-'+status]">{{ label }}</div>
</template>

<script>

export default {
    props: {
        label: {
            type: String,
            required: true
        },
        status: {
            type: String,
            required: true
        },
    },
}
</script>
<style scoped>
.c-log-status {
    width: 90px !important;
    height: 20px !important;
    border-radius: 4px !important;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.26px;
    text-transform: uppercase;
    padding: 2px 0px;
}
.c-log-ignored {
    background: #cdefce !important;
    color: #84cd87;
}
.c-log-success {
    background: #C8E6C9 !important;
    color: #43A047;
}
.c-log-completed {
    background: #C8E6C9 !important;
    color: #43A047;
}
.c-log-failed {
    background: #FFCDD2 !important;
    color: #E53935;
}
.c-log-error {
    background: #FFCDD2 !important;
    color: #E53935;
}
.c-log-received {
    background: #BDBDBD !important;
    color: #424242;
}
.c-log-queued {
    background: #E0E0E0 !important;
    color: #616161;
}
.c-log-invalid {
    background: #E0E0E0 !important;
    color: #616161;
}
.c-log-incomplete {
    background: #FFECB3 !important;
    color: #FF8F00;
}
</style>

<template>
	<a-main>
        <a-container grid-list-xl style="margin-top: 6rem;">
            <a-layout wrap align-center>
                <a-flex xs3>
                    <img src="../../assets/images/drawkit-support-woman-monochrome.svg" alt="">
                </a-flex>
                <a-flex xs9>
                    <h2 class="md-heading-4 primary--text">Having trouble using Success?</h2>
                    <p class="md-subtitle-1 grey--text text--darken-2 mb-4">Get in touch with our support team and we're glad to assist you.</p>
                    <a-layout>
                        <a-flex xs6>
                            <h3 class="md-subtitle-1 font-weight-bold primary--text mb-2">Knowledge Base</h3>
                            <p class="md-body-2 grey--text text--darken-2 mb-2">Browse through our knowledge for more information on using Success.</p>
                            <a-btn :href="kbLink" target="_blank" rel="noopener" color="white" small class="primary elevation-0 ml-0">Browse KB</a-btn>
                        </a-flex>
                        <a-flex xs6>
                            <h3 class="md-subtitle-1 font-weight-bold primary--text mb-2">Email</h3>
                            <p class="md-body-2 grey--text text--darken-2 mb-2">Send us an email about the issue, our support team will get back to you.</p>
                            <a-btn href="mailto:support@success.app" color="white" small class="primary elevation-0 ml-0">Send Email</a-btn>
                        </a-flex>
                        <!-- <a-flex xs4>
                            <h3 class="md-subtitle-1 font-weight-bold primary--text mb-2">Chat</h3>
                            <p class="md-body-2 grey--text text--darken-2 mb-2">You can initiate a chat on this page by clicking on help button at bottom right.</p>
                            <a-btn color="white" small class="primary elevation-0 ml-0" @click="local_zendesk_widget_open()">Initiate Chat</a-btn>
                        </a-flex> -->
                    </a-layout>
                </a-flex>
            </a-layout>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            local_loading: true,
            kbLink: 'https://help.success.app/en/'
        }
    },
    computed: {
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Release', {
            release_item: 'item',
        }),
    },
    methods: {
        local_zendesk_widget_open() {
            zE('webWidget', 'open');
        },
    },
}
</script>

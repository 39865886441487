<template>
    <v-progress-circular v-on="$listeners" v-bind="$attrs">
        <slot></slot>
    </v-progress-circular>
</template>

<script>
import { VProgressCircular } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VProgressCircular },

    data () {
        return {
            ref: null
        }
    },

    mounted () {
        this.ref = this.$el
    }
}
</script>

<template>
    <a-layout align-start>
        <a-flex pt-0>
            <a-tabs class="my-0" background-color="transparent" slider-color="primary">
                <!-- <a-tab ripple class="mx-2" :to="{name: 'master-templates-overview', params: { id: $route.params.id }}">
                    <a-icon size="16" class="mr-2">info</a-icon> <span class="md-body-2">Overview</span>
                </a-tab> -->
                <a-tab ripple class="mx-2" :to="{name: 'master-templates-usecases', params: { id: $route.params.id }}">
                    <a-icon size="16" class="mr-2">assignment</a-icon> <span class="md-body-2">Scope</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'master-templates-tasks', params: { id: $route.params.id }}">
                    <a-icon size="16" class="mr-2">check_circle</a-icon> <span class="md-body-2">Tasks</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'master-templates-notes', params: { id: $route.params.id }}">
                    <a-icon size="16" class="mr-2">notes</a-icon> <span class="md-body-2">Notes</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'master-templates-documents', params: { id: $route.params.id }}">
                    <a-icon size="16" class="mr-2">description</a-icon> <span class="md-body-2">Docs</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'master-templates-links', params: { id: $route.params.id }}">
                    <a-icon size="16" class="mr-2">link</a-icon> <span class="md-body-2">Links</span>
                </a-tab>
            </a-tabs>
            <a-divider class="grey lighten-2"></a-divider>
        </a-flex>
    </a-layout>
</template>

<script>
export default {

}
</script>

const api_endpoint = '/layout-designer';

export default {
    namespaced: true,
    state: {
        list: [],
        response: []
    },

    mutations: {
        UPDATE_LIST (state, payload) {
            state.list = payload
        },

        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },

    actions: {
        async index ({commit}, payload) {
            try {
                const { data: { data } } = await axios.get(api_endpoint, { params: payload } )
                commit('UPDATE_LIST', data)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            }
            catch (error) {
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },

        async store ({commit}, payload) {
            try {
                await axios.post(api_endpoint, payload)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            }
            catch (error) {
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        }
    }
}

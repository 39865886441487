<template>
    <a-main>
        <g-settings-breadcrumb previousRoute="settings" class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="py-6 pt-2 px-0 mb-10">
            <g-page-header
                title="Inventory Settings"
                subtitle="Customize categories associated with inventory."
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                icon="memory"
            >
            </g-page-header>
            <tab-inventory-meta></tab-inventory-meta>
            <a-layout>
                <a-flex xs12>
                    <draggable v-model="meta_list" :options="{handle: '.js-drag-handle'}">
                        <a-card v-for="meta in meta_list" :key="meta.id">
                            <a-card-text class="pa-0">
                                <a-container container--fluid grid-list-xl class="pa-4 px-6">
                                    <a-layout wrap align-center>
                                        <a-flex shrink class="px-0" v-if="user_self.role && user_self.role.scope === 'internal' && $can('metas.update')">
                                            <a-icon class="grey--text text--lighten-1 u-cursor-pointer js-drag-handle">drag_indicator</a-icon>
                                        </a-flex>
                                        <a-flex>
                                            <h2 class="md-subtitle-1 font-weight-medium mb-0">{{ meta.value }}</h2>
                                        </a-flex>
                                        <a-flex shrink v-if="$can('metas.update')">
                                            <a-btn color="grey darken-2" @click="local_edit(meta.id)" text icon small>
                                                <a-icon small>edit</a-icon>
                                            </a-btn>
                                        </a-flex>
                                        <a-flex shrink v-if="$can('metas.destroy')">
                                            <a-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <a-btn color="grey darken-2" @click="local_destroy(meta.id)" text icon small :disabled="meta.category_count > 0">
                                                            <a-icon small>delete</a-icon>
                                                        </a-btn>
                                                    </div>
                                                </template>
                                                <span class="text-center body-2" v-if="meta.category_count > 0">
                                                    You have {{ meta.category_count }}
                                                    {{(meta.category_count > 1) ? 'inventories': 'inventory'}} under this category.<br>Please move them to different category to delete.
                                                </span>
                                                <span class="text-center" v-else>Delete category</span>
                                            </a-tooltip>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-card-text>
                        </a-card>
                    </draggable>
                    <a-card class="u-elevation-custom-1" v-if="$can('metas.store')">
                        <a-card-text class="pa-0">
                            <a-form @submit.prevent="() => false">
                            <a-container container--fluid grid-list-xl class="py-2">
                                <a-layout wrap align-center>
                                    <a-flex shrink class="pr-0">
                                        <a-icon color="grey" size="20">add</a-icon>
                                    </a-flex>
                                    <a-flex class="pl-0 py-1">
                                        <a-text-field
                                            v-model="meta_item_value"
                                            placeholder="Type category value and hit enter"
                                            solo flat hide-details
                                            @keyup.enter="local_store()"
                                        >
                                        </a-text-field>
                                     </a-flex>
                                    <a-flex shrink class="pr-4">
                                        <a-btn color="grey darken-2" text icon small @click="local_store()">
                                            <a-icon small>keyboard_return</a-icon>
                                        </a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            </a-form>
                        </a-card-text>
                    </a-card>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="meta_response.server && meta_response.server.errors && meta_response.server.errors.value && meta_form_mode == 'add'">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ meta_response.server.errors.value[0] }}
                    </span>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="meta_response.server && meta_response.server.errors && meta_response.server.errors.category_id">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ meta_response.server.errors.category_id[0] }}
                    </span>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog v-model="dialog_meta_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>category</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (meta_form_mode == 'add') ? 'Create Category' : 'Edit Category' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (meta_form_mode == 'add') ? 'You can add new category picklist value to the system.' : 'You can edit existing category picklist value.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Label</label>
                                    <a-text-field
                                        v-model="meta_item.value"
                                        placeholder="Enter Label"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="meta_response.server && meta_response.server.errors && meta_response.server.errors.value  && meta_form_mode == 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ meta_response.server.errors.value[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Enter the label to be selected.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_update()" :loading="loading" :disabled="loading">Save Value</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_meta_form = !dialog_meta_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { TabInventoryMeta } from '@/config/config-tab-components'

export default {
    components: {
        TabInventoryMeta
    },
    data() {
        return {
            dialog_meta_form: false,
            meta_item_value: '',
            previousRoute: null
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.previousRoute = from
        })
    },
    mounted() {
        this.local_index()
    },
    watch:{
        dialog_meta_form(newValue, oldValue) {
            if (newValue === false) {
                this.meta_clear_item()
            }
        },
    },
    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Types & Statuses', to: '/settings/metas', exact: true})
            return breadcrumbs
        },
        meta_list: {
            get() {
                return this.$store.state.Meta.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },
        ...mapState('Meta', {
            meta_item: 'item',
            meta_filters: 'filters',
            meta_form_mode: 'form_mode',
            meta_response: 'response',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('metas.index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.meta_clear()
            this.meta_filters.type = 'Inventory Category'
            this.meta_filters.active= 1
            this.meta_filters.count_relationship='category'
            await this.meta_index({
                'include': 'categoryCount',
                'filter[type]': 'inventory_category',
                'sort': 'order'
            })
        },
        async local_edit(id) {
            await this.meta_select({id: id})
            this.dialog_meta_form = true
        },
        async local_create() {
            await this.meta_clear_item()
            this.dialog_meta_form = true
        },
        async local_store() {
            this.meta_item.type = 'inventory_category'
            this.meta_item.value = this.meta_item_value
            await this.meta_store({ ...this.meta_item })
            if (this.meta_response.status == 'success') {
                this.meta_item_value = ''
                await this.meta_clear_item()
            }
        },
        async local_update(){
            this.meta_filters.count_relationship = 'category'
            await this.meta_update(this.meta_item)
            if (this.meta_response.status == 'success') {
                this.dialog_meta_form = false
                await this.meta_clear_item()
            }
        },
        async local_destroy(id) {
            await this.meta_destroy({id: id})
        },
        async local_reorder(list) {
            this.meta_reorder({list: list})
        },
        ...mapActions('Meta', {
            meta_index: 'index',
            meta_store: 'store',
            meta_update: 'update',
            meta_select: 'select',
            meta_destroy: 'destroy',
            meta_reorder: 'reorder',
            meta_clear: 'clear',
            meta_clear_item: 'clear_item',
        }),
    },
}
</script>

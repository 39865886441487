import { mapActions, mapState } from 'vuex'

export default {
    inject: ['modalState'],

    data () {
        return {
            mixLoading: false,
            mixParentState: {}
        }
    },

    watch: {
        modalState: {
            handler (parentModalState) {
                if (!parentModalState.isOpen) return

                this.mixParentState = parentModalState
                this.localIndex()
            },
            immediate: true,
            deep: true
        }
    },

    computed: {
        mixGetSavedItem () {
            return this.project_saved_items.find(i => i.id === this.mixParentState.projectId)
        },

        mixGetSavedSummary () {
            const project = this.project_saved_items.find(i => i.id === this.mixParentState.projectId)
            return !project ? {} : project.summary
        },

        ...mapState('ProjectView', {
            project_saved_items: 'saved_items',
        }),
    },

    methods: {
        mixOpenInNewWindow (pageName, projectId = this.mixParentState.projectId) {
            const route = this.$router.resolve({
                name: pageName,
                params: { id: projectId }
            });
            window.open(route.href)
        },

        mixSaveProject (props) {
            if (!this.mixGetSavedItem) {
                return this.project_save_item({
                    id: this.mixParentState.projectId,
                    createdAt: new Date(),
                    summary: props
                })
            }

            const timeDiff = moment(new Date()).diff(moment(this.mixGetSavedItem.createdAt), 'minutes')
            this.mixGetSavedItem.summary = { ...this.mixGetSavedItem.summary, ...props }
            this.project_save_item({
                id: this.mixParentState.projectId,
                createdAt: timeDiff > 5 ? new Date() : this.mixGetSavedItem.createdAt,
                summary: this.mixGetSavedItem.summary
            })
        },

        mixLatestSyncTimeDiff ({ diffType = 'seconds' } = {}) {
            const now = moment(new Date())
            const dateToCompare = moment(this.mixGetSavedItem.createdAt)

            return moment(now).diff(moment(dateToCompare), diffType)
        },

        mixHasPermission (module = null) {
            if (!module) return this.$can('projects.index')

            return this.$can('projects.index') && this.$can(`${module}`)
        },

        mixSetLoading () {
            this.mixLoading = true
        },

        mixClearLoading () {
            this.mixLoading = false
        },

        ...mapActions('ProjectView', {
            project_save_item: 'save_item',
        }),
    },
}

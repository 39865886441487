<template>
    <a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid px-0 mx-0 py-8 py-6>
            <a-layout fill-height>
                <a-flex xs3 xl2>
                    <div style="position: sticky; top: 88px;">
                        <div class="mb-4">
                            <p style="letter-spacing: 0.2px;" class="md-body-2 font-weight-medium text-uppercase grey--text text--darken-1 mb-0 ml-2">System Tags</p>
                            <a-list class="u-list-condensed transparent d-block">
                                <a-list-item v-for="item in system_tags" v-ripple="false" :key="item.id" class="px-0 py-0 pl-2" active-class="grey lighten-2" :to="{name: 'settings-tags-list', params: { id: item.type && item.type === 'system' ? item.slug : item.id }}">
                                    <a-list-item-content style="min-height: 38px;">
                                        <div class="u-flex align-center">
                                            <span class="md-body-2 text-capitalize grey--text text--darken-4 font-weight-medium py-0 ml-2">{{ item.label }}</span>
                                        </div>
                                    </a-list-item-content>
                                </a-list-item>
                            </a-list>
                        </div>
                        <div class="mb-4">
                            <p style="letter-spacing: 0.2px;" class="md-body-2 font-weight-medium text-uppercase grey--text text--darken-1 mb-2 ml-2">Custom Tags</p>
                            <draggable tag="div" style="max-height: 532px; overflow-y: auto;" class="c-scrollbar c-tag-section-list u-list-condensed py-0 transparent d-block" v-model="tag_sections_list" :options="{handle: '.js-drag-handle'}">
                                <div v-for="item in tag_sections_list" :key="item.id" class="c-tag-section-item px-0 py-0 pl-2" :class="[local_current_route_id === item.id ? 'grey lighten-2' : '']">
                                    <div class="u-flex align-center pr-2">
                                        <div class="u-flex align-center u-cursor-pointer u-wfull">
                                            <div @click="local_switch_route(item)" class="u-cursor-pointer u-wfull py-2 ml-2" style="min-height: 38px;">
                                                <span class="md-body-2 grey--text text--darken-4 font-weight-medium text-truncate d-block" style="max-width: 200px;">{{ item.name }}</span>
                                            </div>
                                        </div>
                                        <a-spacer></a-spacer>
                                        <div class="u-flex align-center">
                                            <a-icon size="14" :color="item.is_active ? 'green darken-1' : 'grey lighten-1'">fiber_manual_record</a-icon>
                                        </div>
                                        <div class="c-tag-section-item__action u-flex align-center">
                                            <template v-if="check_user_tag_permission('update')">
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on" @click="local_tag_section_toggle_active(item.id, !item.is_active)" v-ripple style="border-radius: 0px 0px 0px 6px;" class="c-tag-section-item__action-item u-cursor-pointer u-rounded-corners-full">
                                                            <a-icon size="14" :color="item.is_active ? 'green darken-1' : 'grey lighten-1'">fiber_manual_record</a-icon>
                                                        </div>
                                                    </template>
                                                    <span>{{ item.is_active ? 'Enabled' : 'Disabled' }}</span>
                                                </a-tooltip>
                                                <a-divider vertical></a-divider>
                                                <div @click="local_tag_section_edit(item)" v-ripple class="c-tag-section-item__action-item u-cursor-pointer u-rounded-corners-full">
                                                    <!-- <a-icon size="14" color="grey darken-1">edit</a-icon> -->
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-pencil grey--text text--darken-1" viewBox="0 0 16 16">
                                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                                                    </svg>
                                                </div>
                                            </template>
                                            <a-divider vertical v-if="check_user_tag_permission('destroy') && check_user_tag_permission('update')"></a-divider>
                                            <template v-if="check_user_tag_permission('destroy')">
                                                <div v-if="local_remove_id !== item.id" @click="local_set_destroy_id(item.id)" v-ripple class="c-tag-section-item__action-item u-cursor-pointer u-rounded-corners-full">
                                                    <!-- <a-icon size="14" color="grey darken-1">delete</a-icon> -->
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-trash grey--text text--darken-1" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                    </svg>
                                                </div>
                                                <a-tooltip bottom v-else>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on" @click="local_tag_section_destroy(item)" v-ripple class="c-tag-section-item__action-item u-cursor-pointer u-rounded-corners-full">
                                                            <!-- <a-icon size="14" color="red darken-2">delete</a-icon> -->
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-trash red--text text--darken-2" viewBox="0 0 16 16">
                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                            </svg>
                                                        </div>
                                                    </template>
                                                    <span>Confirm ?</span>
                                                </a-tooltip>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </draggable>
                            <a-divider class="grey lighten-2 mt-3"></a-divider>
                            <div class="u-flex align-center pa-1 mt-3 pl-2 u-cursor-pointer" v-ripple @click="local_tag_section_create" v-if="check_user_tag_permission('store')">
                                <a-icon v-ripple size="18" color="#a3a3a3" class="d-inline-block u-cursor-pointer u-rounded-corners">add</a-icon>
                                <span class="md-body-2 ml-2" style="color: #a3a3a3">Create new custom section</span>
                            </div>
                        </div>
                    </div>
                </a-flex>
                <a-flex xs9 xl10>
                    <router-view></router-view>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog v-model="dialog_tag_section_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>label</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (section_form_mode === 'add') ? 'Create New Custom Tag Section' : 'Edit Existing Custom Tag Section' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (section_form_mode === 'add') ? 'You can add new custom tag section to the system.' : 'You can edit existing custom tag section.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name <span class="red--text text--darken-2">*</span></label>
                                    <a-text-field
                                        v-model="section_name_input.name"
                                        ref="refCreateTagInput"
                                        placeholder="Enter the name"
                                        background-color="neutral"
                                        solo flat hide-details autofocus
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="tag_section_response.server && tag_section_response.server.errors && tag_section_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">info</a-icon>
                                        {{ tag_section_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the field.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_tag_section_store()" :loading="loading" :disabled="loading">{{ section_form_mode === 'add' ? 'Create new section' : 'Update existing section'}}</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click.prevent="local_close_dialog()" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                                <a-spacer></a-spacer>
                                <!-- <a-flex shrink v-if="section_form_mode === 'edit' && check_user_tag_permission('destroy')">
                                    <a-btn class="ma-0 elevation-0 red px-3 lighten-4 red--text text--darken-2" v-if="local_remove_id !== tag_section_item.id" @click="local_set_destroy_id(tag_section_item.id)" :loading="loading">Delete Tag</a-btn>
                                    <a-btn class="ma-0 elevation-0 red px-3 lighten-4 red--text text--darken-2" v-else @click="local_tag_section_destroy(tag_section_item)" :loading="loading">Confirm ?</a-btn>
                                </a-flex> -->
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import TagPermission from '@/mixins/mixin-tag-permission'

export default {
    mixins: [TagPermission],

    data() {
        return {
           system_tags: [
              { id: 1, label: 'labels', slug: 'label', type: 'system'},
              { id: 2, label: 'products', slug: 'product', type: 'system'},
              { id: 3, label: 'platforms',slug: 'platform', type: 'system'},
              { id: 4, label: 'use cases', slug: 'usecase_tag', type: 'system'},
              { id: 5, label: 'tickets', slug: 'ticket_tag', type: 'system'},
          ],
           dialog_tag_section_form: false,
           local_loading: true,
           local_remove_id: null,
           section_name_input: {},
           section_form_mode: 'add'
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Custom Tags', to: '/settings/tags', exact: true})
            return breadcrumbs
        },

        tag_sections_list: {
            get() {
                return this.$store.state.TagSection.list
            },
            set(list) {
                this.local_tag_section_reorder(list)
            }
        },

        local_current_route_id() {
            return this.$route.params.id
        },

        ...mapGetters('TagSection', {
            tag_section_item: 'item',
            tag_section_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index() {
            await this.tag_section_clear()
            await this.tag_section_index()
            this.local_loading = false
        },

        async local_tag_section_edit({ id, type, name }) {
            await this.tag_section_clear_response()
            this.section_form_mode = 'edit'
            this.section_name_input = { id, type, name }
            this.local_remove_id = null
            this.dialog_tag_section_form = true
        },

        async local_tag_section_create() {
            await this.tag_section_clear_response()
            this.section_name_input = {}
            this.local_remove_id = null
            this.dialog_tag_section_form = true
        },

        async local_tag_section_store() {
            if (this.section_form_mode === 'add') {
                this.section_name_input.name = this.section_name_input.name ? this.section_name_input.name.trim() : null
                await this.tag_section_store({ ...this.section_name_input })
                if (this.tag_section_response && this.tag_section_response.status === 'success') this.$notify('success', 'Created successfully!')
            } else {
                this.section_name_input.name = this.section_name_input.name ? this.section_name_input.name.trim() : null
                await this.tag_section_update({ ...this.section_name_input })
                if (this.tag_section_response && this.tag_section_response.status === 'success') this.$notify('success', 'Updated successfully!')
            }
            if (this.tag_section_response.status == 'success') this.local_close_dialog()
        },

        async local_tag_section_toggle_active(id, is_active) {
            await this.tag_section_toggle_active({ id: id, state: is_active ? 1 : 0 })
            if (this.tag_section_response && this.tag_section_response.status === 'success') {
                this.$notify('success', 'Updated successfully!')
            }
        },

        async local_tag_section_destroy({id}) {
            await this.tag_section_destroy({id: id})
            if (id === this.$route.params.id) this.$router.replace({ name: 'settings-tags-list', params: { id: 'label' }})
            if (this.tag_section_response.status === 'success') this.local_close_dialog()
        },

        async local_tag_section_reorder(list) {
            this.tag_section_reorder({list: list})
        },

        // Extras
        local_switch_route({ id, slug, type }) {
            if (this.$route.params.id === id) return
            this.$route.meta.slug = { name: slug, type: type }
            this.$router.push({name: 'settings-tags-list', params: { id: type && type === 'system' ? slug : id }})
        },

        async local_close_dialog() {
            this.dialog_tag_section_form = false
            this.local_remove_id = null
            this.local_reset_form_mode()
        },

        local_reset_form_mode () {
            this.section_form_mode = 'add'
        },

        local_set_destroy_id(id) {
            this.local_remove_id = id
            setTimeout(() => this.local_remove_id = null, 3000)
        },

        ...mapActions('TagSection', {
            tag_section_index: 'index',
            tag_section_store: 'store',
            tag_section_show: 'show',
            tag_section_update: 'update',
            tag_section_select: 'select',
            tag_section_toggle_active: 'toggle_active',
            tag_section_destroy: 'destroy',
            tag_section_reorder: 'reorder',
            tag_section_clear: 'clear',
            tag_section_clear_response: 'clear_response',
            tag_section_clear_item: 'clear_item',
        })
    },
}
</script>

<style lang="scss" scoped>
    .c-tag-section-item {
        position: relative;
        overflow-x: hidden;
        &__action {
            visibility: hidden;
            position: absolute;
            right: -1000px;
            top: 0px;
            background: #fff;
            border-radius: 0px 0px 0px 6px;
            box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 16%);
            // transition: 0.1s ease-in-out;
        }
        &__action-item {
            display: flex;
            align-items: center;
            padding: 5px 10px;
        }
        &:hover {
            .c-tag-section-item__action {
                visibility: visible;
                right: 0px;
                // transition: 0.1s ease-in-out;
            }
        }
    }
    // .action_visible {
    //     visibility: visible;
    //     right: 0px;
    //     transition: 0.2s ease-in-out;
    // }
</style>

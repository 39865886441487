<template>
    <a-main>
       <template v-if="isLoading">
            <div class="u-flex pa-6">
                <div class="u-wfull c-workspace-icon-view">
                    <div class="align-center justify-center u-flex c-workspace-menu-icon">
                        <loader-template height="48px" width="48px" class="u-rounded-corners-lg"></loader-template>
                    </div>
                </div>
                <div class="ml-3">
                    <loader-template height="25" width="400px" class="u-rounded-corners-lg"></loader-template>
                    <loader-template height="18" width="480px" class="u-rounded-corners-lg mt-2"></loader-template>
                </div>
                <a-spacer></a-spacer>
                <div>
                    <loader-template height="44px" width="350px" class="u-rounded-corners-lg"></loader-template>
                </div>
            </div>
       </template>
        <template v-if="!isLoading">
            <div class="u-flex pa-6">
                <div class="c-workspace-icon-view">
                    <div class="align-center justify-center u-flex c-workspace-menu-icon blue-grey darken-2" style="border: 2px solid #90A4AE !important">
                        <a-icon color="white" size="20">apartment</a-icon>
                    </div>
                </div>
                <div class="ml-3">
                    <p class="md-subtitle-1 text-uppercase indigo--text text--darken-1 mb-0 font-weight-bold">Where: Workspace</p>
                    <p class="md-body-2 grey--text text--darken-1 mb-0">Choose the Workspace for the validation to trigger.</p>
                </div>
                <a-spacer></a-spacer>
                <div style="width: 350px;" class="u-relative">
                    <v-autocomplete
                        v-model="item.workspace_id"
                        :items="workspaceList"
                        item-text="title"
                        item-value="id"
                        :placeholder="isHiddenWorkspace ? 'Hidden Workspace' : null"
                        background-color="grey lighten-5"
                        class="pa-0 u-rounded-corners-lg u-border c-custom-automation-placeholder"
                        :disabled="!canUpdate"
                        @change="workspaceUpdate"
                        item-color="grey darken-1"
                        solo flat hide-details
                    >
                        <template #item="{ item }">
                            <div class="u-full">
                                <span :title="item.title">{{ item.title | truncateText(26) }}</span>
                            </div>
                            <a-spacer></a-spacer>
                            <a-icon size="20" v-if="item.is_default" color="orange">star</a-icon>
                        </template>
                        <template #selection="{ item }">
                            <div class="u-full">
                                <span :title="item.title">{{ item.title | truncateText(26) }}</span>
                            </div>
                        </template>
                    </v-autocomplete>
                    <div class="c-workspace-info">
                        <a-tooltip bottom v-if="isHiddenWorkspace && !isDefaultWorkspace()">
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <a-icon size="22" color="grey darken-1" class="u-icon-nudge-xs mr-1">help</a-icon>
                                </div>
                            </template>
                            <div class="pa-2 md-body-2" style="width: 266px;">This workspace is hidden because you may not have permission to it.</div>
                        </a-tooltip>
                        <a-tooltip bottom v-if="!isHiddenWorkspace && isDefaultWorkspace()">
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <a-icon size="22" color="orange" class="u-icon-nudge-xs mr-1">star</a-icon>
                                </div>
                            </template>
                            <div class="pa-2 md-body-2">Default workspace.</div>
                        </a-tooltip>
                    </div>
                </div>
            </div>
        </template>
    </a-main>
</template>

<script>

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        workspaceList: {
            type: Array,
            default: [],
        },
        isLoading:{
            type: Boolean,
            default: true
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        workspaceUpdate: {
            type: Function,
            default: {},
        },
        isHiddenWorkspace: {
            type: Boolean,
            default: false
        },
        isDefaultWorkspace: {
            type: Function,
            default: {},
        }
    },
}
</script>

<style scoped>
.c-workspace-icon-view {
    width: 48px !important;
}
.c-workspace-menu-icon {
    box-sizing: border-box;
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 8px !important;
}
.c-default-workspace{
    position: relative;
    left: 84px;
    top: -1px;
}
.c-workspace-info{
    position: absolute;
    right: 48px;
    top: 12px;
}
</style>


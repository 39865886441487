<template>
    <div class="c-summary-tile u-relative u-hfull u-flex flex-column">
        <div class="pa-5 u-flex align-start">
            <div>
                <div class="u-flex-center-y">
                    <a-icon size="16" color="grey" class="u-icon-nudge-xs" style="top: -1px;" outlined>list_alt</a-icon>
                    <span class="md-body-2 ml-1 grey--text text--darken-1 font-weight-medium">Project Result</span>
                </div>

                <div class="mt-2">
                    <a-tooltip bottom left content-class="pa-2 px-3 c-tooltip-pointer c-tooltip-pointer--right">
                        <template v-slot:activator="{ on }">
                            <h2 v-on="on" class="u-font-24 u-font-weight-semibold u-tracking-tight">
                                {{ localCompletionSmartText }}
                            </h2>
                        </template>
                        <span class="md-caption white--text">
                            {{ localShortDate(localExactCompletedAt, { custom: true, format: 'MMM D, YYYY' }) }}
                        </span>
                    </a-tooltip>

                    <div
                        v-if="localProject('estimated_start_date') && localProject('estimated_end_date') && localProject('start_date') && localProject('due_date')"
                        class="u-flex-center-y mt-3"
                        style="column-gap: 6px;"
                    >
                        <a-tooltip bottom right content-class="pa-2 px-3 c-tooltip-pointer c-tooltip-pointer--right">
                            <template v-slot:activator="{ on }">
                                <a-icon size="16" v-on="on" color="grey">event</a-icon>
                            </template>
                            <div class="md-caption white--text">
                                <span class="u-tracking">Estimated Date</span>
                                <span class="mx-1 u-tracking u-font-weight-semibold">vs</span>
                                <span class="u-tracking">Actual Date</span>
                            </div>
                        </a-tooltip>
                        <a-tooltip bottom content-class="pa-2 px-3 c-tooltip-pointer c-tooltip-pointer--right">
                            <template v-slot:activator="{ on }">
                                <div
                                    v-on="on"
                                    class="grey--text text--darken-1 text-truncate"
                                    style="max-width: 300px;"
                                >
                                    <span class="u-tracking" v-if="localProject('estimated_start_date')">{{ localShortDate(localProject('estimated_start_date')) }}</span>
                                    <span class="mx-1">-></span>
                                    <span class="u-tracking" v-if="localProject('estimated_end_date')">{{ localShortDate(localProject('estimated_end_date')) }}</span>
                                    <span class="mx-1 u-tracking">vs</span>
                                    <span class="u-tracking" v-if="localProject('start_date')">{{ localShortDate(localProject('start_date')) }}</span>
                                    <span class="mx-1">-></span>
                                    <span class="u-tracking" v-if="localProject('due_date')">{{ localShortDate(localProject('due_date')) }}</span>
                                </div>
                            </template>
                            <span class="u-tracking" v-if="localProject('estimated_start_date')">{{ localShortDate(localProject('estimated_start_date')) }}</span>
                            <span class="mx-1">-></span>
                            <span class="u-tracking" v-if="localProject('estimated_end_date')">{{ localShortDate(localProject('estimated_end_date')) }}</span>
                            <span class="mx-1 u-tracking">vs</span>
                            <span class="u-tracking" v-if="localProject('start_date')">{{ localShortDate(localProject('start_date')) }}</span>
                            <span class="mx-1">-></span>
                            <span class="u-tracking" v-if="localProject('due_date')">{{ localShortDate(localProject('due_date')) }}</span>
                        </a-tooltip>
                    </div>

                    <div v-else class="u-flex-center-y mt-3">
                        <a-tooltip bottom right content-class="pa-2 px-3 c-tooltip-pointer c-tooltip-pointer--right">
                            <template v-slot:activator="{ on }">
                                <a-icon size="16" v-on="on" color="grey">event</a-icon>
                            </template>
                            <div class="md-caption white--text">
                                <span class="u-tracking">Estimated Date</span>
                                <span class="mx-1 u-tracking u-font-weight-semibold">vs</span>
                                <span class="u-tracking">Actual Date</span>
                            </div>
                        </a-tooltip>
                        <div class="u-font-14 ml-1 grey--text text--darken-1">
                            Dates are missing
                        </div>
                    </div>
                </div>
            </div>
            <a-spacer></a-spacer>
            <div v-if="!chartLoading">
                <PieChart
                    :chart-data="chartData"
                    :chart-options="chartOptions"
                    :width="180"
                    :height="80"
                />
                <a-sheet height="8" style="margin-top: 9px;" class="c-chart-color u-wfull px-2 u-rounded-corners-lg u-flex-center-y">
                    <template v-for="health in healthChartData">
                        <a-tooltip bottom :key="health.id" content-class="pa-2 px-3 u-rounded-corners-xl">
                            <template v-slot:activator="{ on }">
                                <a-sheet
                                    v-on="on"
                                    :color="health.color"
                                    :width="health.percentage + '%'"
                                    :style="[{ borderRadius: `${health.radius.tLeft}px ${health.radius.tRight}px ${health.radius.bRight}px ${health.radius.bLeft}px` }]"
                                    height="100%"
                                    class="c-chart-color__child u-cursor-pointer"
                                />
                            </template>
                            <div class="u-flex-center-y" style="column-gap: 6px;">
                                <a-sheet
                                    :color="health.color"
                                    :style="[{ border: '2px solid ' + health.borderColor + '!important' }]"
                                    width="12"
                                    height="12"
                                    class="u-rounded-corners"
                                />
                                <span class="u-font-12 u-tracking white--text">{{ health.label }} - {{ health.daysCount }} days</span>
                            </div>
                        </a-tooltip>
                    </template>
                </a-sheet>
            </div>
        </div>

        <a-spacer></a-spacer>
        <a-divider></a-divider>

        <template v-if="mixLoading">
            <a-sheet class="u-flex-center-y pa-5 justify-space-between" height="53" style="border-radius: 0px 0px 25px 25px;">
                <div class="u-flex-center-y">
                    <loader-template width="100" height="16" class="u-rounded-corners-full"></loader-template>
                    <loader-template width="320" height="16" class="u-rounded-corners-full ml-3"></loader-template>
                </div>
                <loader-template width="16" height="16" class="u-rounded-corners-full"></loader-template>
            </a-sheet>
        </template>
        <template v-else>
            <div
                v-if="localResultValue"
                :style="[{ background: localResultValue.gradient }]"
                ref="refCompletedReasons"
                class="u-flex-center-y u-wfull"
                style="border-radius: 0px 0px 8px 8px; padding: 20px 20px 18px 20px;"
            >
                <div class="u-flex-center-y">
                    <a-icon size="16" :color="localResultValue.iconColor">{{ localResultValue.icon }}</a-icon>
                    <div
                        v-if="localResultValue.value"
                        :title="localResultValue.value"
                        :class="[localResultValue.textColor]"
                        class="md-body-2 u-wfull u-font-weight-semibold ml-2"
                    >
                        {{ localResultValue.value | truncateText(24) }}
                    </div>
                </div>

                <template v-if="localFirstReason && localFirstReason.id">
                    <a-icon size="6" color="grey lighten-1" class="mx-2">fiber_manual_record</a-icon>
                    <div class="u-font-14 u-tracking grey--text text--darken-2 font-weight-medium">
                        {{ localFirstReason.title }}
                    </div>
                </template>

                <a-spacer></a-spacer>
                <div v-if="localReasonsLength" class="u-border u-rounded-corners-full grey--text text--darken-2 font-weight-medium md-caption px-1">
                    +{{ localReasonsLength > 1 ? localReasonsLength - 1 : localReasonsLength }}
                </div>
            </div>
        </template>

        <partial-project-summary-actions
            v-if="!mixLoading"
            :showExpand="localReasonsLength > 1"
            @expand="$emit('expand')"
            @open="mixOpenInNewWindow('projects-overview')"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { diffSimple } from '@/helpers/helper-date-filter'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'
import { Pie as PieChart } from 'vue-chartjs/legacy'
import PartialProjectSummaryActions from './PartialProjectSummaryActions.vue'
import MixinModalStateInjection from '../Mixins/MixinModalStateInjection'
import LoaderProjectCompletedActivitySummary from '../Loaders/LoaderProjectCompletedActivitySummary.vue'
import moment from 'moment'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
	components: {
        PieChart,
        PartialProjectSummaryActions,
        LoaderProjectCompletedActivitySummary
    },

    mixins: [MixinModalStateInjection],

    data () {
        return {
            chartLoading: false,
            stageTotalDays: 0,
            healthTotalDays: 0,
            healthChartData: [],
            reasonsList: [],
            totalStageHistory: [],
            totalHealthHistory: [],
            healthHistoryList: [],
            stageHistoryList: [],
            resultGradients: {
                Positive: 'linear-gradient(107deg, #F4FAF4 36.11%, rgba(255, 255, 255, 0.00) 61.62%)',
                Negative: 'linear-gradient(107deg, #FDF2F1 36.11%, rgba(255, 255, 255, 0.00) 61.62%)',
                Neutral: 'linear-gradient(107deg, #F4F5FB 36.11%, rgba(255, 255, 255, 0.00) 61.62%)',
            },
            stageIndexParams: {
                include: 'stageTo,stageFrom,createdBy,updatedBy,project.result',
                'fields[project_stage_updates]': 'id,stage_from_id,project_id,stage_to_id,status_from,status_to,days,created_at,updated_at,created_by_id,updated_by_id',
                'fields[stage_to]': 'id,name,workspace_id',
                'fields[stage_from]': 'id,name',
                'fields[updated_by]': 'id,email,name',
                'fields[project]': 'id,result_id',
                'fields[project.result]': 'id,status,value,color',
                count: 1000
            },
            healthIndexParams: {
                count: 1000,
                include: 'type,updatedBy',
                'fields[updated_by]': 'id,email,name',
                'fields[type]': 'id,value',
                'fields[status_updates]': 'id,type_id,updated_by_id,health,visibility,created_at,days',
                'sort': 'created_at',
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: { legend: false }
            },
            chartData: {
                labels: [],
                datasets: [{
                    backgroundColor: [],
                    data: [],
                    hoverOffset: 0,
                    circumference: 180,
                    rotation: 270,
                    borderWidth: 0,
                    borderRadius: 0,
                }]
            },
        }
    },

    computed: {
        localExactCompletedAt () {
            const latestStage = this.totalStageHistory.slice(-1)[0]
            return latestStage?.createdAt || null
        },

        localReasonsLength () {
            return _.size(this.reasonsList)
        },

        localFirstReason () {
            if (!this.localReasonsLength) return {}

            return this.reasonsList[0]
        },

        localResultValue () {
            const { status, value } = this.mixParentState?.project?.result || {}
            let valueObj = { status: status || '', value: value || '', textColor: 'grey--text text--darken-1', iconColor: 'grey darken-1', icon: 'thumb_down', gradient: '' }
            if (!status) return valueObj

            if (status === 'Positive') valueObj = { ...valueObj, textColor: 'green--text text--darken-1', iconColor: 'green darken-1', icon: 'thumb_up' }
            if (status === 'Negative') valueObj = { ...valueObj, textColor: 'red--text text--darken-1', iconColor: 'red darken-1', icon: 'thumb_down' }
            if (status === 'Neutral') valueObj = { ...valueObj, textColor: 'indigo--text text--darken-1', iconColor: 'indigo darken-1', icon: 'linear_scale' }
            valueObj['gradient'] = this.resultGradients[status]

            return valueObj
        },

        localCompletionSmartText () {
            const endDate = this.localProject('estimated_end_date')
            if (!endDate) return 'Completed'

            const completionDate = this.localExactCompletedAt ? moment(this.localExactCompletedAt).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
            const daysCount = moment(moment(endDate).format('YYYY-MM-DD')).diff(completionDate, 'days')
            const monthDiff = moment(moment(endDate).format('YYYY-MM-DD')).diff(completionDate, 'months')
            const yearDiff = moment(moment(endDate).format('YYYY-MM-DD')).diff(completionDate, 'years')

            if (daysCount === 0) return 'Completed Ontime'

            if (daysCount >= 1 && daysCount <= 90) return 'Completed ' + daysCount + (daysCount === 1 ? ' day' : ' days') + ' before due'
            if (daysCount <= -1 && daysCount >= -90) return 'Completed ' + Math.abs(daysCount) + (Math.abs(daysCount) === 1 ? ' day' : ' days') + ' after due'

            if (daysCount > 90 && yearDiff === 0) return 'Completed ' + monthDiff + (monthDiff === 1 ? ' month' : ' months') + ' before due'
            if (daysCount < -90 && yearDiff === 0) return 'Completed ' + Math.abs(monthDiff) + (Math.abs(monthDiff) === 1 ? ' month' : ' months') + ' after due'

            if (yearDiff >= 1 || yearDiff <= -1) {
                const exactYear = Math.floor(Math.abs(monthDiff) / 12) + (Math.abs(monthDiff) % 12 !== 0 ? ('.' + (Math.abs(monthDiff) % 12)) : '')
                if (yearDiff >= 1) return 'Completed ' + exactYear + (exactYear === 1 ? ' year ' : ' years ') + 'before due'
                if (yearDiff >= -1) return 'Completed ' + exactYear + (exactYear === 1 ? ' year ' : ' years ') + 'after due'
            }
        },

        ...mapState('Stage', {
            stage_list: 'list'
        }),

        ...mapState('ProjectView', {
            project_stage_history_list: 'stage_history',
            project_stage_history_meta: 'stage_history_meta'
        }),

        ...mapState('StatusUpdate', {
            status_update_list: 'list',
            status_update_meta: 'meta',
        }),
    },

    methods: {
        async localIndex () {
            this.chartLoading = true
            this.localResetChart()
            if (this.mixLatestSyncTimeDiff() >= 5) {
                this.localNoFetchSetup()
                setTimeout(() => this.chartLoading = false, 500)
                return
            }

            this.mixSetLoading()
            await this.localFetchReasons()
            await this.localFetchStageHistory()
            await this.localFetchHealthHistory()
            this.localSetupChartStats()
            this.mixClearLoading()
            this.chartLoading = false
        },

        localNoFetchSetup () {
            this.reasonsList = this.mixGetSavedSummary.projectReasons || []

            this.totalStageHistory = this.mixGetSavedSummary.completedStageHistory.totalList
            this.stageTotalDays = this.mixGetSavedSummary.completedStageHistory.totalDays
            this.stageHistoryList = this.mixGetSavedSummary.completedStageHistory.list

            this.totalHealthHistory = this.mixGetSavedSummary.completedHealthHistory.totalList
            this.healthTotalDays = this.mixGetSavedSummary.completedHealthHistory.totalDays
            this.healthHistoryList = this.mixGetSavedSummary.completedHealthHistory.list

            this.localSetupChartStats()
            this.localSetupHealthChart()
        },

        async localFetchReasons () {
            const params = { include: 'resultReasons', 'fields[projects]': 'id' }
            const { data: { result_reasons }, status } = await this.project_show({ id: this.mixParentState.projectId, stateless: true, params })
            this.reasonsList = status !== 'success' ? [] : _.cloneDeep(result_reasons)
            this.mixSaveProject({ projectReasons: this.reasonsList })
        },

        async localFetchStageHistory () {
            await this.project_stage_history({
                id: this.mixParentState.projectId,
                params: this.stageIndexParams
            })

            this.stageTotalDays = this.project_stage_history_meta.total_days
            this.totalStageHistory = _.cloneDeep(this.project_stage_history_list.map(history => this.localConstructStageObj(history)))
            this.stageHistoryList = this.totalStageHistory.filter(i => i.days !== 0)
            this.mixSaveProject({ completedStageHistory: { list: this.stageHistoryList, totalList: this.totalStageHistory, totalDays: this.stageTotalDays } })
        },

        localConstructStageObj (history) {
            const daysPercentage = ((history.days / this.stageTotalDays) * 100).toFixed(2)
            return {
                id: history.id,
                days: history.days,
                daysPercent: parseFloat(daysPercentage),
                statusTo: history.status_to,
                stageTo: history.stage_to ? history.stage_to.name : null,
                stageToId: history.stage_to ? history.stage_to.id : null,
                createdAt: history.created_at,
                updatedAt: history.updated_at,
                updatedBy: history.updated_by,
                result: history.project?.result || null
            }
        },

        async localFetchHealthHistory () {
            await this.status_update_index({
                'filter[project_id]': this.mixParentState.projectId,
                ...this.healthIndexParams
            })

            this.healthTotalDays = this.status_update_meta.total_days
            this.totalHealthHistory = _.cloneDeep(this.status_update_list.map(history => this.localConstructHealthObj(history)))
            this.healthHistoryList = this.totalHealthHistory.filter(i => i.days !== 0)
            this.mixSaveProject({ completedHealthHistory: { list: this.healthHistoryList, totalDays: this.healthTotalDays, totalList: this.totalHealthHistory } })
            this.localSetupHealthChart()
        },

        localConstructHealthObj (history) {
            const daysPercentage = ((history.days / this.healthTotalDays) * 100).toFixed(2)
            return {
                id: history.id,
                days: history.days,
                daysPercent: parseFloat(daysPercentage),
                healthColor: history.health.charAt(0).toUpperCase() + history.health.slice(1),
                type: history.type?.value || '',
                createdAt: history.created_at,
                updatedBy: history.updated_by,
            }
        },

        localSetupHealthChart () {
            if (!_.size(this.healthHistoryList)) return this.healthChartData = []
            const healthColors = {
                Green: { color: '#43A047', label: 'On-Track', border: '#C8E6C9' },
                Red: { color: '#E53935', label: 'Off-Track', border: '#FFAB91' },
                Yellow: { color: '#FF8F00', label: 'At-Risk', border: '#FFE082' }
            }

            const resultList = _.entries(_.groupBy(this.healthHistoryList, 'healthColor')).map(item => {
                return {
                    id: item[0],
                    color: healthColors[item[0]].color,
                    label: healthColors[item[0]].label,
                    borderColor: healthColors[item[0]].border,
                    daysCount: item[1].reduce((acc, c) => acc = acc + c.days, 0),
                    percentage: item[1].reduce((acc, c) => acc = acc + c.daysPercent, 0),
                    radius: { tLeft: 0, tRight: 0, bRight: 0, bLeft: 0 }
                }
            })

            const valuesWithPercent = resultList.filter(i => i.percentage > 0)
            if (_.size(valuesWithPercent) === 1) valuesWithPercent[0].radius = { tLeft: 8, tRight: 8, bRight: 8, bLeft: 8 }
            if (_.size(valuesWithPercent) > 1) {
                valuesWithPercent[0].radius = { tLeft: 8, tRight: 0, bRight: 0, bLeft: 8 }
                valuesWithPercent[_.size(valuesWithPercent) - 1].radius = { tLeft: 0, tRight: 8, bRight: 8, bLeft: 0 }
            }
            this.healthChartData = valuesWithPercent
        },

        localSetupChartStats () {
            const stageList = _.orderBy(this.stageHistoryList.filter(i => ['queue', 'active'].includes(i.statusTo)), 'statusTo', 'desc')
            const totalActiveDays = this.stageHistoryList.filter(i => i.statusTo === 'active').reduce((acc, c) => acc = acc + c.days, 0)
            const totalQueueDays = this.stageHistoryList.filter(i => i.statusTo === 'queue').reduce((acc, c) => acc = acc + c.days, 0)
            const colors = ['#9FA8DA', '#7986CB']

            this.chartData.datasets[0].data.push(...stageList.map(i => i.days))
            stageList.forEach(stage => {
                this.chartData.labels.push({ totalActiveDays, totalQueueDays, stageTo: stage })
                this.chartData.datasets[0].backgroundColor.push(stage.statusTo === 'queue' ? colors[0] : colors[1])
            })

            // Custom tooltip configuration
            this.localSetupChartTooltip()
        },

        localSetupChartTooltip () {
            this.chartOptions.plugins.tooltip = {
                displayColors: false,
                padding: (context) => context.tooltip?.title.includes('Queue - ') ? 10 : 8,
                titleMarginBottom: (context) => context.tooltip?.title.includes('Queue - ') ? 0 : 8,
                bodySpacing: 4,
                position: 'nearest',
                callbacks: {
                    title: (context) => {
                        const tooltip = context[0].label
                        if (tooltip.stageTo.statusTo === 'queue') {
                            return `Queue - ${tooltip.totalQueueDays} ${tooltip.totalQueueDays > 1 ? ' Days' : ' Day'}`
                        }

                        if (tooltip.stageTo.statusTo === 'active') {
                            return `Active - ${tooltip.totalActiveDays} ${tooltip.totalActiveDays > 1 ? ' Days' : ' Day'}`
                        }
                    },
                    label: (context) => {
                        const tooltip = context.label
                        return `${tooltip.stageTo.stageTo} - ${tooltip.stageTo.days} ${tooltip.stageTo.days > 1 ? ' Days' : ' Day'}`
                    }
                }
            }
        },

        localResetChart () {
            this.chartData.labels = []
            this.chartData.datasets[0].data = []
        },

        localShortDate (date, { custom = false, format = 'MMM DD, YYYY [at] hh:mm A' } = {}) {
            if (!custom) return diffSimple(date)

            return moment(date).format(format)
        },

        localProject (field) {
            return this.mixParentState.project && this.mixParentState.project[field] || ''
        },

        ...mapActions('ProjectView', {
            project_stage_history: 'stage_history'
        }),

        ...mapActions('StatusUpdate', {
            status_update_index: 'index'
        }),

        ...mapActions('ProjectView', {
            project_show: 'show',
        }),
    }
}
</script>

<style lang="scss" scoped>
.c-chart-color {
    &__child {
        transition: 0.25s border ease-in-out, 0.25s border-radius ease-in-out, 0.25s box-shadow ease-in-out;
        &:hover, &__child::before {
            border: 2px solid #fff !important;
            border-radius: 8px !important;
            box-shadow: 0px 1px 2px 2px #b8b8b8 !important;
            transition: 0.25s border ease-in-out, 0.25s border-radius ease-in-out, 0.25s box-shadow ease-in-out;
        }
    }
}
</style>

const api_endpoint =  '/confidence-score'

export default {
    namespaced: true,
    state: {
        list: [],
        aggregate: {},
        meta: {},
        response: []
    },

    mutations: {
        UPDATE_LIST (state, payload) {
            state.list = payload
        },

        UPDATE_AGGREGATE (state, payload) {
            state.aggregate = payload
        },

        UPDATE_META (state, payload) {
            state.meta = payload
        },

        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },

    actions: {
        async index ({commit}, payload) {
            try {
                const { data } = await axios.get(api_endpoint, { params: payload } )
                commit('UPDATE_LIST', data.data)
                commit('UPDATE_META', data.meta)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            }
            catch (error) {
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },

        async aggregate_show ({commit}, payload) {
            try {
                const { data } = await axios.get(`${api_endpoint}/aggregate`, { params: payload } )
                commit('UPDATE_AGGREGATE', data)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            }
            catch (error) {
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },

        async store ({commit}, payload) {
            try {
                await axios.post(api_endpoint, payload)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            }
            catch (error) {
                commit('THROW_RESPONSE', { text: error.response.data, status: 'error', server: error.response });
            }
        },

        async validate_identifier ({commit}, payload) {
            try {
                await axios.post(api_endpoint + '/validate-identifier', payload)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            }
            catch (error) {
                commit('THROW_RESPONSE', { text: error.response.data.errors.name[0], status: 'error', server: error.response.data });
            }
        }
    }
}

<template>
    <div
        class="c-summary-tile px-5 pt-5 u-relative u-hfull"
        :style="[{ background: localGradient }]"
    >
        <div class="u-flex-center-y mb-2">
            <a-icon size="16" :color="localResultValue.iconColor">{{ localResultValue.icon }}</a-icon>
            <div
                v-if="localResultValue.value"
                :title="localResultValue.value"
                :class="[localResultValue.textColor]"
                class="md-body-2 u-wfull u-font-weight-semibold ml-2"
            >
                {{ localResultValue.value | truncateText(24) }}
            </div>
        </div>

        <div class="u-overflow-hidden" style="max-height: calc(200px - 65px)">
            <div ref="refReasonsListContainer">
                <div
                    v-for="(reason, index) in localReasonsList"
                    :key="reason.id"
                    :class="[{ 'mt-2': index !== 0 }]"
                    class="pa-0 ma-0 u-font-14 font-weight-medium u-tracking grey--text text--darken-2"
                >
                    {{ reason.title }}
                </div>
            </div>
        </div>

        <partial-bottom-gradient v-if="showExpand" :height="48" flat />

        <partial-project-summary-actions
            :showExpand="false"
            @open="mixOpenInNewWindow('projects-overview')"
        />
    </div>
</template>

<script>
import MixinModalStateInjection from '../Mixins/MixinModalStateInjection'
import PartialBottomGradient from './PartialBottomGradient.vue'
import PartialProjectSummaryActions from './PartialProjectSummaryActions.vue'

export default {
    components: {
        PartialBottomGradient,
        PartialProjectSummaryActions
    },

    mixins: [MixinModalStateInjection],

    data () {
        return {
            showExpand: false,
            resultGradients: {
                Positive: 'linear-gradient(107deg, #F4FAF4 36.11%, rgba(255, 255, 255, 0.00) 61.62%)',
                Negative: 'linear-gradient(107deg, #FDF2F1 36.11%, rgba(255, 255, 255, 0.00) 61.62%)',
                Neutral: 'linear-gradient(107deg, #F4F5FB 36.11%, rgba(255, 255, 255, 0.00) 61.62%)',
            },
        }
    },

    computed: {
        localReasonsList () {
            return this.mixGetSavedSummary.projectReasons || []
        },

        localGradient () {
            const { status } = this.mixParentState?.project?.result || {}
            if (!status) return ''

            return this.resultGradients[status]
        },

        localResultValue () {
            const { status, value } = this.mixParentState?.project?.result || {}
            let valueObj = { status: status || '', value: value || '', textColor: 'grey--text text--darken-1', iconColor: 'grey darken-1', icon: 'thumb_down', gradient: '' }
            if (!status) return valueObj

            if (status === 'Positive') valueObj = { ...valueObj, textColor: 'green--text text--darken-1', iconColor: 'green darken-1', icon: 'thumb_up' }
            if (status === 'Negative') valueObj = { ...valueObj, textColor: 'red--text text--darken-1', iconColor: 'red darken-1', icon: 'thumb_down' }
            if (status === 'Neutral') valueObj = { ...valueObj, textColor: 'indigo--text text--darken-1', iconColor: 'indigo darken-1', icon: 'linear_scale' }
            valueObj['gradient'] = this.resultGradients[status]

            return valueObj
        },
    },

    methods: {
        localIndex () {
            this.$nextTick(() => {
                this.showExpand = this.$refs.refReasonsListContainer.clientHeight >= (200 - 65)
            })
        }
    },
}
</script>

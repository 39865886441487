<template>
    <div>
        <div :class="handle">
            <router-link tag="div" class="c-project-card__wrapper" :to="{name: 'projects-overview', params: {id: project.id}}">
                <!-- Health color -->
                <div class="u-absolute" style="top: -18px; right: -18px;">
                    <template v-if="stage.status === 'completed'">
                        <a-spacer></a-spacer>
                        <a-tooltip bottom left>
                            <template v-slot:activator="{ on }">
                                <template v-if="project.result">
                                    <a-avatar v-on="on" size="18" color="green" v-if="project.result.status === 'Positive'">
                                        <a-icon size="12" color="white">thumb_up</a-icon>
                                    </a-avatar>
                                    <a-avatar v-on="on" size="18" color="red" v-if="project.result.status === 'Negative'">
                                        <a-icon size="12" color="white">thumb_down</a-icon>
                                    </a-avatar>
                                    <a-avatar v-on="on" size="18" color="indigo darken-2" v-if="project.result.status === 'Neutral'">
                                        <a-icon size="12" color="white">linear_scale</a-icon>
                                    </a-avatar>
                                </template>
                                <a-avatar v-on="on" size="18" color="orange darken-3" v-else>
                                    <a-icon size="12" color="white">priority_high</a-icon>
                                </a-avatar>
                            </template>
                            <span v-if="project.result">{{ project.result.value }}</span>
                            <span v-else>No Result Data</span>
                        </a-tooltip>
                    </template>
                    <template v-if="(!localCanShowQueueHealth && stage.status === 'active') || (localCanShowQueueHealth && stage.status !== 'completed')">
                        <a-spacer></a-spacer>
                        <div class="u-flex-center-y" style="column-gap: 4px;">
                            <a-avatar size="40" :color="localGetHealthColor !== 'grey' ? localGetHealthColor + ' lighten-5' : localGetHealthColor + ' lighten-2'">
                                <a-avatar size="28" :color="localGetHealthColor !== 'grey' ? localGetHealthColor + ' lighten-4' : localGetHealthColor + ' lighten-2'">
                                    <a-icon size="24" :color="localGetHealthColor + ' darken-2'">fiber_manual_record</a-icon>
                                </a-avatar>
                            </a-avatar>
                        </div>
                    </template>
                </div>

                <!-- Title, deal amount, collaborators and customer name -->
                <div class="c-project-card__title px-3 py-3">
                    <p class="md-body-2 mb-0 grey--text text--darken-2 font-weight-medium mb-1 text-truncate" :title="project.customer_name">
                        {{ project.customer_name }}
                    </p>
                    <h2
                        :class="[{ 'mb-1': project.title && project.title.length < 32 }]"
                        class="md-subtitle-1 font-weight-bold u-line-clamp-2"
                        :style="[project.title && project.title.length >= 32 ? { marginBottom: '6px' } : {}]"
                        style="line-height: 20px;"
                    >
                        {{ project.title }}
                    </h2>
                    <div class="u-flex align-center mt-2">
                        <p
                            class="md-body-2 mb-0 grey--text text--darken-1 u-flex align-center"
                            style="column-gap: 6px;"
                        >
                            <g-currency tag="span" class="blue-grey--text font-weight-medium" :value="project.deal_amount"></g-currency>
                        </p>
                        <a-spacer></a-spacer>
                        <!-- Completed stage status -->
                        <div class="mr-2" v-if="stage.status === 'completed'">
                            <a-tooltip bottom left>
                                <template v-slot:activator="{ on }">
                                    <template v-if="project.result">
                                        <a-avatar v-on="on" size="24" color="green lighten-3" v-if="project.result.status === 'Positive'">
                                            <a-avatar size="20" color="green">
                                                <a-icon size="10" color="white">thumb_up</a-icon>
                                            </a-avatar>
                                        </a-avatar>
                                        <a-avatar v-on="on" size="24" color="red lighten-3" v-if="project.result.status === 'Negative'">
                                            <a-avatar size="20" color="red">
                                                <a-icon size="10" color="white">thumb_down</a-icon>
                                            </a-avatar>
                                        </a-avatar>
                                        <a-avatar v-on="on" size="24" color="indigo lighten-3" v-if="project.result.status === 'Neutral'">
                                            <a-avatar size="20" color="indigo darken-2">
                                                <a-icon size="10" color="white">linear_scale</a-icon>
                                            </a-avatar>
                                        </a-avatar>
                                    </template>
                                    <a-avatar v-on="on" size="24" color="orange lighten-3" v-else>
                                        <a-avatar size="20" color="orange darken-3">
                                            <a-icon size="10" color="white">priority_high</a-icon>
                                        </a-avatar>
                                    </a-avatar>
                                </template>
                                <span v-if="project.result">{{ project.result.value }}</span>
                                <span v-else>No Result Data</span>
                            </a-tooltip>
                        </div>
                        <!-- Collaborators -->
                        <div>
                            <template v-if="localHasCollaborators">
                                <s-users-deck
                                    :list="project.aggr_collaborators"
                                    :count="3"
                                    :avatar-size="24"
                                    font-size="8"
                                    hide-list directRole
                                ></s-users-deck>
                            </template>
                            <a-tooltip v-else bottom>
                                <template v-slot:activator="{ on }">
                                    <a-btn v-on="on" height="24" width="24" icon small class="ma-0 pa-0 grey lighten-1" :to="{name: 'projects-collaborators', params: {id: project.id}}">
                                        <a-icon size="14" color="white">person</a-icon>
                                    </a-btn>
                                </template>
                                <span>No Team Members Added</span>
                            </a-tooltip>
                        </div>
                    </div>
                </div>
                <!-- End: Title, deal amount and customer name -->

                <a-divider></a-divider>
                <div class="u-flex-center-y u-wfull" style="height: 38px;">
                    <!-- Start date / Days count -->
                    <div class="px-3">
                        <span class="caption grey--text text--darken-3" v-if="stage.status === 'queue'">
                            <a-tooltip bottom v-if="project.start_date">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on" class="u-flex-center-y">
                                        <a-icon size="14" class="mr-1" outlined>event</a-icon>
                                        <g-moment tag="span" class="md-caption" :value="project.start_date" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY"></g-moment>
                                    </span>
                                </template>
                                <span>Start date</span>
                            </a-tooltip>
                            <span v-else>
                                <a-icon size="14" class="u-icon-nudge mr-1" outlined>event</a-icon>
                                <span>No Start date</span>
                            </span>
                        </span>
                        <span class="caption grey--text text--darken-3" v-else-if="stage.status === 'completed'">
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on" class="u-flex-center-y">
                                        <a-icon size="14" class="mr-1" outlined>event</a-icon>
                                        <span class="md-caption">{{ project.active_days || 0 }} days</span>
                                    </span>
                                </template>
                                <span>Total number of days in active stages</span>
                            </a-tooltip>
                        </span>
                        <span class="caption grey--text text--darken-3" v-else>
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on" class="u-flex-center-y">
                                        <a-icon size="14" class="mr-1" outlined>event</a-icon>
                                        <template v-if="stage && stage.name">
                                            <span class="md-caption">{{ project.stage_active_days >= 0 ? ((project.stage_active_days || 0) + '/') : 0 }}{{ project.active_days || 0 }} days</span>
                                        </template>
                                    </span>
                                </template>
                                <span class="md-body-2 d-block text-center" style="max-width: 210px;">In {{ stage.name }} stage for {{ project.stage_active_days >= 0 ? project.stage_active_days : 0 }} days out of {{ project.active_days }} days in active stages</span>
                            </a-tooltip>
                        </span>
                    </div>
                    <a-spacer></a-spacer>
                    <!-- Usecase Task Count Bar -->
                    <div v-if="(localGetWidgetPref('progress') === 'Scope' && $can('usecases.index') )">
                        <div class="c-project-card__progress u-wfull px-3 py-2">
                            <s-module-progress :closed="project.total_completed_success_criteria" :total="project.total_success_criteria" flex></s-module-progress>
                        </div>
                    </div>
                    <div v-if="(localGetWidgetPref('progress') === 'Task' && $can('tasks.index') )">
                        <div class="c-project-card__progress u-wfull px-3 py-2">
                            <s-module-progress :closed="project.total_completed_tasks" :total="project.total_tasks" flex task></s-module-progress>
                        </div>
                    </div>
                    <div v-if="(localGetWidgetPref('progress') === 'All' && $can('tasks.index') && $can('usecases.index') )">
                        <div class="u-flex-center-y px-3 py-2" style="column-gap: 4px">
                            <s-module-progress :closed="project.total_completed_success_criteria" :total="project.total_success_criteria" flex animate></s-module-progress>
                            <s-module-progress :closed="project.total_completed_tasks" :total="project.total_tasks" flex task class="ml-2" animate></s-module-progress>
                        </div>
                    </div>
                    <div v-if="(localGetWidgetPref('progress') === 'All' && !$can('tasks.index') && $can('usecases.index') )">
                        <div class="c-project-card__progress u-wfull px-3 py-2">
                            <s-module-progress :closed="project.total_completed_success_criteria" :total="project.total_success_criteria" flex></s-module-progress>
                        </div>
                    </div>
                    <div v-if="(localGetWidgetPref('progress') === 'All' && $can('tasks.index') && !$can('usecases.index') )">
                        <div class="c-project-card__progress u-wfull px-3 py-2">
                            <s-module-progress :closed="project.total_completed_tasks" :total="project.total_tasks" flex task></s-module-progress>
                        </div>
                    </div>
                    <!-- End: Usecase Task Count Bar -->
                </div>

                <!-- Confidence score -->
                <template v-if="localConfidenceScoreVisibility()">
                    <a-divider></a-divider>
                    <div class="px-3 py-2 u-flex-center-y" style="height: 38px;">
                        <!-- Confidence score -->
                        <div class="u-flex-center-y" v-if="project.confidence_score" style="column-gap: 6px;">
                            <PartialTileScoreCount
                                :value="project.confidence_score.percentage || 100"
                                :color="localGetScoreColor"
                                :is-zero="!project.confidence_score.current_score"
                            />
                            <span v-if="project.confidence_score.percentage" class="md-caption grey--text text--darken-3 font-weight-medium">Score: {{ project.confidence_score.percentage + '%' }}</span>
                            <span v-else class="md-caption grey--text text--darken-2 font-weight-medium">No Score</span>
                        </div>
                        <div class="u-flex-center-y" v-else style="column-gap: 6px;">
                            <PartialTileScoreCount
                                :value="100"
                                :color="localGetScoreColor"
                                is-zero
                            />
                            <span class="md-caption grey--text text--darken-2 font-weight-medium">No Score</span>
                        </div>
                        <a-spacer></a-spacer>
                        <!-- Conf score - current / total -->
                        <span v-if="confidence_score_aggregate && confidence_score_aggregate.fields_configured" class="u-cursor-pointer grey--text text--darken-2 md-caption font-weight-medium d-inline-block pa-1">
                            <span>{{ project.confidence_score && project.confidence_score.current_score ? project.confidence_score.current_score : '-' }} / </span>
                            <span>{{ confidence_score_aggregate.total }}</span>
                        </span>
                    </div>
                    <!-- End: Confidence score and view summary button -->
                </template>

                <template v-if="localHasTerritories">
                    <a-divider></a-divider>
                    <!-- Territories -->
                    <div class="px-3 py-2 u-flex-center-y flex-wrap" style="min-height: 38px;">
                        <template v-if="project.aggr_territories">
                            <template v-for="territory in localGetTerritories">
                                <g-tags
                                    :tag="territory"
                                    :key="territory.id"
                                    :textRange="30"
                                    hide-clear-icon
                                />
                            </template>
                        </template>
                    </div>
                </template>
            </router-link>
        </div>

        <!-- Hover bottom menu -->
        <div class="c-project-card__menu" style="z-index: 1">
            <a-divider class="grey lighten-3"></a-divider>
            <div class="u-relative grey lighten-4 u-flex">
                <template v-for="(menuItem, index) in localGetHoverMenuItems">
                    <a-tooltip :key="index" bottom>
                        <template v-slot:activator="{ on }">
                            <a-btn
                                v-on="on"
                                :min-width="localGetMenuItemWidth"
                                @click.prevent="menuItem.iconAction()"
                                class="ma-0 pa-0 py-2 u-rounded-none"
                                depressed icon
                            >
                                <a-icon size="16" :color="menuItem.icon.color" outlined>
                                    {{ menuItem.icon.name }}
                                </a-icon>
                            </a-btn>
                        </template>
                        <span>{{ menuItem.tooltipText }}</span>
                    </a-tooltip>
                    <a-divider v-if="menuItem.divider" :key="index + '-divider'" class="grey lighten-3" vertical></a-divider>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { diffSimple } from '@/helpers/helper-date-filter'
import PartialTileScoreCount from '@/pages/Dashboard/Partials/PartialTileScoreCount.vue'
import {
    SModuleProgress,
    SDashboardLayout,
    SUsersDeck,
} from '@/config/config-shared-components'

export default {
    components: { SModuleProgress, SDashboardLayout, SUsersDeck, PartialTileScoreCount },

    props: {
        project: {
            type: Object,
            required: true
        },
        stage: {
            type: Object,
            required: true
        },
        handle: {
            type: String,
        },
        archiveLoading: {
            type: Boolean | Object | String,
            default: null
        },
        metaLoading: {
            type: Boolean,
            default: false
        },
        configMeta: {
            type: Array,
            required: true
        },
    },

    computed: {
        localCanShowQueueHealth () {
            return this.localGetWidgetPref('queue_health_color') === 'Yes'
        },

        localHasCollaborators () {
            return _.size(this.project.aggr_collaborators)
        },

        localHasTerritories () {
            return _.size(this.project.aggr_territories)
        },

        localGetTerritories () {
            if (!_.size(this.project.aggr_territories)) return []

            const territoryIds = this.project.aggr_territories.map(i => i.id)
            return this.territory_list.filter(i => territoryIds.includes(i.id))
        },

        localGetHealthColor () {
            return this.project && this.project.health_color
        },

        localGetMenuItemWidth () {
            const cardWidth = 324
            const dividersCount = _.size(this.localGetHoverMenuItems) - 1
            return ((cardWidth + dividersCount) / _.size(this.localGetHoverMenuItems)) + 'px'
        },

        localGetScoreColor () {
            let color = '#E0E0E0'
            if (!this.project.confidence_score || !this.project.confidence_score.percentage) return color

            const { percentage: cfPercentage } = this.project?.confidence_score || {}
            const metaList = _.orderBy(this.configMeta, 'percentage', 'asc')

            if (cfPercentage > 0 && _.size(metaList)) {
                const list = metaList.filter(i => cfPercentage > i.percentage)
                color = metaList[_.size(list)].color
            }

            return this.$color(color, 'color_bg')
        },

        localGetHoverMenuItems () {
            const menuList = [
                {
                    icon: { name: 'donut_large', color: 'grey darken-1' },
                    iconAction: () => this.$emit('update-status', { id: this.project.id, title: this.project.title }),
                    tooltipText: 'Post Status Update',
                    permission: this.$can('projects.update-only') && this.$can('status_updates.store'),
                    divider: true,
                },
                {
                    icon: { name: 'check_circle', color: 'grey darken-1' },
                    iconAction: () => this.$emit('create-task', this.project.id, this.project.title, this.stage.id),
                    tooltipText: 'Create Task',
                    permission: this.$can('projects.update-only') && this.$can('tasks.show') && this.$can('tasks.store'),
                    divider: true,
                },
                {
                    icon: { name: 'notes', color: 'grey darken-1' },
                    iconAction: () => this.$emit('create-note', this.project.id, this.project.title),
                    tooltipText: 'Create Note',
                    permission: this.$can('projects.update-only') && this.$can('notes.show') && this.$can('notes.store'),
                    divider: true,
                },
                {
                    icon: { name: 'archive', color: 'grey darken-1' },
                    iconAction: () => this.$emit('archive', null, this.project.id, this.project.title, this.stage.id, 'archived', this.stage.meta),
                    tooltipText: 'Archive Project',
                    permission: this.$can('projects.update-only'),
                    divider: true,
                },
                {
                    icon: { name: 'launch', color: 'grey darken-1' },
                    iconAction: () => {
                        const { href } = this.$router.resolve({ name: 'projects-overview', params: { id: this.project.id } })
                        window.open(href, '_blank')
                    },
                    tooltipText: 'Open in New Tab',
                    permission: true,
                    divider: true,
                },
                {
                    icon: { name: 'aspect_ratio', color: 'blue darken-1' },
                    iconAction: () => this.$emit('open-summary'),
                    tooltipText: 'View Summary',
                    permission: true,
                    divider: false,
                },
            ]

            return menuList.filter(i => i.permission).map((item, index, list) => {
                if (_.size(list) - 1 !== index) return { ...item, divider: true }
                return { ...item, divider: false }
            })
        },

        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key',
        }),

        ...mapState('Preference', {
            preference_list: 'list',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Territory', {
            territory_list: 'list',
        }),

        ...mapState('ConfidenceScore', {
            confidence_score_aggregate: 'aggregate',
        }),
    },

    methods: {
        localGetWidgetPref (type, field = 'value') {
            if (type === 'progress') {
                return this.preference_get_property_by_key('organization.project.progress_resource', field)
            }
            if (type === 'widgets') {
                return this.preference_get_property_by_key(`organization.projects.widgets.${type}`, field)
            }
            if (type === 'confidence_score') {
                return this.preference_get_property_by_key(`organization.${type}`, field)
            }
            if (type === 'queue_health_color') {
                return this.preference_get_property_by_key(`organization.projects.${type}`, field)
            }
        },

        localOrgHasConfScoreFlag (type) {
            return this.user_self.organization[`flag_${type}`] === 1
        },

        localConfidenceScoreVisibility () {
            if (!this.localOrgHasConfScoreFlag('confidence_score')) return false
            return (this.localGetWidgetPref('confidence_score') !== 'No' && this.$can('poc_confidence_score.index'))
        },
    }
}
</script>

<template>
	<a-main>
        <a-container container--fluid grid-list-xl class="pa-0 mx-0">
            <g-page-header
                title="Project Reports"
                subtitle="Review all Projects."
                icon="assignment"
            />
            <a-layout wrap align-center class="mb-3">
                <a-flex pt-0>
                    <a-layout wrap align-center justify-space-between>
                        <a-flex shrink>
                            <div v-if="show_edit_filter_input && (report_preset_item && report_preset_item.id)" class="d-flex align-center">
                                <a-icon size="24" class="mr-2">filter_list</a-icon>
                                <a-card class="px-0 py-1 u-rounded-corners u-border c-filter-edit" flat min-width="300" max-width="400">
                                    <form @submit.prevent="local_report_preset_update()">
                                        <a-text-field
                                            v-model="filter_name"
                                            height="15"
                                            @keyup.enter="show_edit_filter_input = false"
                                            solo hide-details dense flat autofocus
                                        ></a-text-field>
                                    </form>
                                </a-card>
                                <span class="grey--text text--darken-1 caption ml-2 mt-1">Press enter to update</span>
                            </div>
                            <div v-else>
                                <a-card class="transparent pa-0 u-overflow-hidden" elevation="0" max-width="500">
                                    <h3 class="md-heading-6" @click="local_show_filter_edit()">
                                        <template v-if="(report_preset_item && report_preset_item.id) && !show_edit_filter_input">
                                            <span class="md-heading-6 d-flex align-center u-cursor-pointer">
                                                <a-icon size="24" class="mr-2">filter_list</a-icon>
                                                {{ report_preset_item.name }}
                                                <a-icon size="18" class="ml-2 mt-1" color="grey darken-1">edit</a-icon>
                                            </span>
                                        </template>
                                        <template v-else>
                                            <a-icon size="24" class="mr-2">filter_list</a-icon> Filters
                                        </template>
                                    </h3>
                                </a-card>
                            </div>
                        </a-flex>
                        <a-spacer></a-spacer>
                        <a-flex shrink class="d-inline-flex align-center pl-0 ml-1">
                            <a-card class="u-border u-rounded-corners u-overflow-hidden mr-1" flat>
                                <a-card-text class="pa-0">
                                    <a-menu bottom offset-y v-model="search_report" :close-on-content-click="report_mode" transition="slide-y-transition" class="d-block" @click="local_search_report = ''" min-width="230" max-width="230" max-height="400">
                                        <template v-slot:activator="{ on }">
                                            <a-card flat class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 pl-2 d-flex" v-on="on">
                                                <a-card-text class="pa-1 d-flex align-center justify-space-between">
                                                    <div>
                                                        <span class="body-2 grey--text text--darken-2">
                                                            Select Report
                                                        </span>
                                                    </div>
                                                    <div class="text-xs-right ml-1 mr-1">
                                                        <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                                    </div>
                                                </a-card-text>
                                            </a-card>
                                        </template>
                                        <a-divider></a-divider>
                                        <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden pb-2">
                                            <a-list class="u-list-condensed py-0" v-if="report_preset_list && report_preset_list.length > 5">
                                                <a-list-item class="c-input-wrap my-2 px-2">
                                                    <a-text-field
                                                        v-model="local_search_report"
                                                        placeholder="Search Reports"
                                                        background-color="neutral"
                                                        class="body-2 font-weight-normal"
                                                        solo flat hide-details autofocus
                                                        @click="report_mode = false"
                                                    >
                                                    </a-text-field>
                                                </a-list-item>
                                            </a-list>
                                            <a-list style="max-height: 270px;" class="overflow-y-auto u-list-condensed py-0" :class="[local_filtered_report_list && local_filtered_report_list.length <= 5 ? 'mt-2' : '']">
                                                <template v-for="(report_preset, index) in local_filtered_report_list">
                                                    <a-list-item :key="'report_key_' + report_preset.id" v-if="report_preset_item && report_preset_item.id !== report_preset.id" @click="local_report_preset_select(report_preset.id)">
                                                        <a-list-item-title :title="report_preset.name">
                                                            <span class="md-body-2" :title="report_preset.name">{{ local_text_truncate(report_preset.name, '45', '...') }}</span>
                                                        </a-list-item-title>
                                                    </a-list-item>
                                                    <a-list-item :key="'report_key_' + report_preset.id" class="grey lighten-3" v-else>
                                                        <a-list-item-title :title="report_preset.name">
                                                            <span class="md-body-2" :title="report_preset.name">{{ local_text_truncate(report_preset.name, '45', '...') }}</span>
                                                        </a-list-item-title>
                                                    </a-list-item>
                                                    <a-divider class="grey lighten-3" v-if="local_filtered_report_list.length - 1 !== index" :key="report_preset.id + 'divider'"></a-divider>
                                                </template>
                                                <a-list-item v-if="!local_filtered_report_list.length" disabled>
                                                    <a-list-item-content>
                                                        <a-list-item-title v-if="loading">
                                                            <span class="md-subtitle-1 mb-0 text-center">Loading...</span>
                                                        </a-list-item-title>
                                                        <a-list-item-title v-else>
                                                            <span class="md-subtitle-1 mb-0 text-center">No reports found</span>
                                                        </a-list-item-title>
                                                    </a-list-item-content>
                                                </a-list-item>
                                            </a-list>
                                        </a-card>
                                    </a-menu>
                                </a-card-text>
                            </a-card>
                            <a-card class="u-border u-rounded-corners u-overflow-hidden mx-1" flat min-height="32" v-if="report_preset_item && !report_preset_item.id && !local_equals(report_preset_item.filters, project_filters) && button_filter_clear && local_check_filter_applied">
                                <a-card-text class="pa-0 d-flex">
                                    <a-btn small text color="green darken-1" class="green lighten-5 ma-0" style="width: 100%; height: 32px" @click="local_open_create_preset()">
                                        <a-icon size="18" class="mr-2">save_alt</a-icon> Save Report
                                    </a-btn>
                                </a-card-text>
                            </a-card>
                            <a-card class="u-border u-rounded-corners u-overflow-hidden mx-1" flat min-height="32" v-if="report_preset_item && report_preset_item.id && !local_equals(report_preset_item.filters, project_filters)">
                                <a-card-text class="pa-0 d-flex">
                                    <a-btn small text color="orange darken-1" class="orange lighten-5 ma-0" style="width: 100%; height: 32px" @click="local_report_preset_update()">
                                        <a-icon size="18" class="mr-2">edit</a-icon> Update Report
                                    </a-btn>
                                </a-card-text>
                            </a-card>
                            <a-card class="u-border u-rounded-corners u-overflow-hidden ml-1 mr-0 d-flex align-center" flat min-height="32" max-width="50" v-if="button_filter_clear || local_check_filter_applied || report_preset_item">
                                <a-card-text class="pa-0">
                                    <a-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="text-center px-1">
                                                <a-progress-circular indeterminate color="orange" size="20" width="3" v-if="local_loadings.value && local_loadings.type === 'clear'"></a-progress-circular>
                                                <div @click="local_report_preset_start_over()" v-else>
                                                    <a-icon color="grey darken-3" size="20" class="u-cursor-pointer px-1">clear_all</a-icon>
                                                </div>
                                            </div>
                                        </template>
                                        <span>Reset Report</span>
                                    </a-tooltip>
                                </a-card-text>
                            </a-card>
                            <a-card class="u-border u-rounded-corners u-overflow-hidden ml-1 d-flex align-center" flat min-height="32" max-width="50" v-if="report_preset_item && report_preset_item.id">
                                <a-card-text class="pa-0">
                                    <a-menu bottom left offset-y :close-on-content-click="false" max-width="320">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="text-center px-1 pb-1 u-cursor-pointer">
                                                <a-icon color="red darken-1" size="18" class="px-1">delete</a-icon>
                                            </div>
                                        </template>
                                        <a-card>
                                            <a-card-text class="pa-4">
                                                <h2 class="md-heading-6 mb-2">Are you sure to delete this report preset?</h2>
                                                <p class="md-body-2 mb-4">This will delete the preset permanently and cannot be undone.</p>
                                                <a-btn class="ma-0 elevation-0" color="red" @click="local_report_preset_destroy(report_preset_item && report_preset_item.id ? report_preset_item.id : '')" :loading="loading" dark small>
                                                    <a-icon size="16" left class="mr-2">delete</a-icon> Delete
                                                </a-btn>
                                            </a-card-text>
                                        </a-card>
                                    </a-menu>
                                </a-card-text>
                            </a-card>
                        </a-flex>
                    </a-layout>
                    <!-- <a-divider class="mt-2 mb-4 grey lighten-2"></a-divider> -->

                    <!-- #Timeline -->
                    <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_timeline = !expansion_panel_timeline">
                                <a-layout align-center>
                                    <a-flex>
                                        <h4 class="md-subtitle-1">
                                            <a-icon size="20" class="mr-2">access_time</a-icon>
                                            Timeline
                                            <span class="md-caption grey--text text--darken-1 ml-2">
                                                <span class="mr-1" v-if="this.date_field == 'EST_START'">Estimated Start Date</span>
                                                <span class="mr-1" v-if="this.date_field == 'EST_END'">Estimated End Date</span>
                                                <span class="mr-1" v-if="this.date_field == 'START_DATE'">Started</span>
                                                <span class="mr-1" v-if="this.date_field == 'DUE_DATE'">End</span>
                                                <span class="mr-1" v-if="this.date_condition">{{ this.date_condition }}</span>
                                                <g-moment tag="span" class="mr-1" :value="date_value_1" input-format="YYYY-MM-DD" output-format="MMMM DD, YYYY" v-if="date_value_1"></g-moment>
                                                <span v-if="date_value_2">and <g-moment tag="span" :value="date_value_2" input-format="YYYY-MM-DD" output-format="MMMM DD, YYYY"></g-moment></span>
                                            </span>
                                            <span class="mx-2 caption red--text text--darken-1" v-if="show_date_field_error">Please fill all the respective date fields.</span>
                                        </h4>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon v-if="expansion_panel_timeline">expand_less</a-icon>
                                        <a-icon v-else>expand_more</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_timeline">
                                <a-layout align-start>
                                    <!-- #Timeline -->
                                    <a-flex>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Date Field</h4>
                                        <a-autocomplete
                                            v-model="date_field"
                                            :items="timeline_date_field_list"
                                            placeholder="Select Date Field"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details clearable
                                            @input="local_filter('timeline')"
                                        >
                                        </a-autocomplete>
                                    </a-flex>
                                    <a-flex>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Condition</h4>
                                        <a-autocomplete
                                            v-model="date_condition"
                                            :items="[{text: 'On', value: 'on'}, {text: 'Before', value: 'before'}, {text: 'After', value: 'after'}, {text: 'Between', value: 'between'}]"
                                            placeholder="Select Condition"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="local_check_timeline_filters('date_condition')"
                                            solo flat hide-details clearable
                                            @input="local_filter('timeline', 'timeline_condition')"
                                        >
                                        </a-autocomplete>
                                    </a-flex>
                                    <a-flex>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2" v-if="date_condition === 'between'">From</h4>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2" v-else>Date Value</h4>
                                        <s-date-picker
                                            :dateCondition="date_condition === 'between' ? {condition: 'between', start_date: null, end_date: local_readable_date_2} : {condition: null, start_date: null, end_date: null}"
                                            :disabled="local_check_timeline_filters('date_value_1')"
                                            noDueDate startDateTitle="Date" showFooter
                                            :start-date-max-range="localCheckMaxDateRange(timeline_end.start_date)"
                                            :item="timeline"
                                            @update="local_filter('timeline')"
                                        >
                                            <a-sheet width="100%">
                                                <template>
                                                    <a-text-field
                                                        v-model="local_readable_date_1"
                                                        placeholder="Select Date"
                                                        append-icon="event"
                                                        background-color="neutral"
                                                        clear-icon="backspace"
                                                        readonly solo flat hide-details clearable
                                                        @input="local_filter('timeline')"
                                                    ></a-text-field>
                                                </template>
                                            </a-sheet>
                                        </s-date-picker>
                                    </a-flex>
                                    <a-flex v-if="date_condition === 'between'">
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">To</h4>
                                        <s-date-picker
                                            :dateCondition="{condition: 'between', start_date: local_readable_date_1, end_date: null}"
                                            :disabled="local_check_timeline_filters('date_value_2')"
                                            noDueDate startDateTitle="Date" showFooter
                                            :start-date-min-range="localCheckMinDateRange(timeline.start_date)"
                                            :item="timeline_end"
                                            @update="local_filter('timeline')"
                                        >
                                            <a-sheet width="100%">
                                                <template>
                                                    <a-text-field
                                                        v-model="local_readable_date_2"
                                                        placeholder="Select Date"
                                                        append-icon="event"
                                                        background-color="neutral"
                                                        clear-icon="backspace"
                                                        readonly solo flat hide-details clearable
                                                        @input="local_filter('timeline')"
                                                    ></a-text-field>
                                                </template>
                                            </a-sheet>
                                        </s-date-picker>
                                        <!-- <a-menu
                                            v-model="datepicker_menu_2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            min-width="290px"
                                            :disabled="local_check_timeline_filters('date_value_2')"
                                            lazy offset-y full-width
                                        >
                                            <template v-slot:activator="{ on }">
                                                <a-text-field
                                                    v-on="on"
                                                    v-model="local_readable_date_2"
                                                    placeholder="Select Date"
                                                    append-icon="event"
                                                    background-color="neutral"
                                                    clear-icon="backspace"
                                                    readonly solo flat hide-details clearable
                                                    :disabled="local_check_timeline_filters('date_value_2')"
                                                    @input="local_filter('timeline')"
                                                ></a-text-field>
                                            </template>
                                            <a-date-picker v-model="date_value_2" @input="() => {datepicker_menu_2 = false; local_filter('timeline')}"></a-date-picker>
                                        </a-menu> -->
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>

                    <!-- #Accounts -->
                    <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_accounts = !expansion_panel_accounts">
                                <a-layout wrap align-start>
                                    <a-flex>
                                        <h4 class="md-subtitle-1" :class="{ 'pb-1': expansion_panel_accounts }">
                                            <a-icon size="20" class="mr-2">account_box</a-icon>
                                            Accounts
                                            <span class="md-caption grey--text text--darken-1">
                                                <span class="mx-2" v-if="report_project_filters[filter.CUSTOMER] && report_project_filters[filter.CUSTOMER].length">
                                                    <strong class="mr-1">Projects for</strong>
                                                    <template v-for="(item, index) in local_get_field_value(customer_list_filtered, 'id', report_project_filters[filter.CUSTOMER])">
                                                        <span :key="index">
                                                            <template v-if="index <= 2"><span v-if="index !== 0">, </span>{{ item.name }}</template>
                                                        </span>
                                                    </template>
                                                    <template v-if="local_get_field_value(customer_list_filtered, 'id', report_project_filters[filter.CUSTOMER]).length > 3"> and {{ local_get_field_value(customer_list_filtered, 'id', report_project_filters[filter.CUSTOMER]).length - 3 }} more</template>
                                                </span>
                                            </span>
                                        </h4>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon v-if="expansion_panel_accounts">expand_less</a-icon>
                                        <a-icon v-else>expand_more</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_accounts">
                                <a-layout wrap align-start>
                                    <a-flex xs12>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Account</h4>
                                        <a-autocomplete
                                            v-model="report_project_filters[filter.CUSTOMER]"
                                            :items="customer_list_filtered"
                                            item-text="name"
                                            item-value="id"
                                            placeholder="All Accounts"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details multiple small-chips hide-selected clearable deletable-chips
                                            @input="local_filter(filter.CUSTOMER)"
                                        >
                                        </a-autocomplete>
                                    </a-flex>

                                    <!-- #Account Executive -->
                                    <!-- <a-flex xs4>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Account Executive</h4>
                                        <a-autocomplete
                                            v-model="report_project_filters[filter.ACCNT_EXEC]"
                                            :items="local_filtered_users_list"
                                            item-text="name"
                                            item-value="id"
                                            :placeholder="'All Account Executives'"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details multiple small-chips hide-selected clearable deletable-chips
                                            @input="local_filter(filter.ACCNT_EXEC)"
                                        >
                                            <template v-slot:no-data>
                                                <div class="px-4 py-2">
                                                    <span class="md-body-1 grey--text">No User found</span>
                                                </div>
                                            </template>
                                            <template v-slot:item="{ item }" class="pa-3">
                                                <a-layout align-center class="py-2">
                                                    <g-avatar :item="item" :size="32"></g-avatar>
                                                    <g-profile-info :item="item" set-max-width type-user></g-profile-info>
                                                </a-layout>
                                            </template>
                                        </a-autocomplete>
                                    </a-flex>

                                    <a-flex xs4>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Sales Engineer</h4>
                                        <a-autocomplete
                                            v-model="report_project_filters[filter.SALES_ENGG]"
                                            :items="local_filtered_users_list"
                                            item-text="name"
                                            item-value="id"
                                            :placeholder="'All Sales Engineers'"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details multiple small-chips hide-selected clearable deletable-chips
                                            @input="local_filter(filter.SALES_ENGG)"
                                        >
                                            <template v-slot:no-data>
                                                <div class="px-4 py-2">
                                                    <span class="md-body-1 grey--text">No User found</span>
                                                </div>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <a-layout align-center class="py-2">
                                                    <g-avatar :item="item" :size="32"></g-avatar>
                                                    <g-profile-info :item="item" set-max-width type-user></g-profile-info>
                                                </a-layout>
                                            </template>
                                        </a-autocomplete>
                                    </a-flex> -->
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>

                    <!-- #Collaborators -->
                    <a-card class="u-border u-rounded-corners mt-2 pa-2" flat>
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_custom_collabs = !expansion_panel_custom_collabs">
                                <a-layout wrap align-start>
                                    <a-flex>
                                        <h4 class="md-subtitle-1" :class="{ 'pb-1': expansion_panel_custom_collabs }">
                                            <a-icon size="22" class="mr-2">groups</a-icon>
                                            Collaborators
                                            <span class="md-caption grey--text text--darken-1 ml-2" v-if="custom_collaborators && Object.keys(custom_collaborators).length > 0">
                                                <strong>&nbsp; Selected </strong> {{ local_count_custom_collabs(custom_collaborators) }} collaborator{{ local_count_custom_collabs(custom_collaborators) > 1 ? 's' : '' }}
                                            </span>
                                        </h4>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon v-if="expansion_panel_custom_collabs">expand_less</a-icon>
                                        <a-icon v-else>expand_more</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_custom_collabs">
                                <a-layout wrap align-start>
                                     <template v-for="collab_type in collaborator_type_list">
                                        <a-flex xs4 :key="collab_type.id">
                                            <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">{{ collab_type.value }}</h4>
                                            <SharedSearchableInput
                                                :items="collabsUsersList"
                                                :selectedItems="selectedUsersList[collab_type.id]"
                                                :attachId="'id-' + collab_type.id"
                                                :placeholder="`All ${ collab_type.value }`"
                                                @search="localSearchCollabs"
                                                @add="localAddCollab($event, collab_type.id)"
                                                @remove="localRemoveCollabs($event, collab_type.id)"
                                                chips removable multiple
                                            >
                                                <template #listItem="{ item }">
                                                    <div class="u-flex-center-y">
                                                        <g-avatar :item="item" :size="32"></g-avatar>
                                                        <g-profile-info :item="item" set-max-width type-user></g-profile-info>
                                                    </div>
                                                </template>
                                            </SharedSearchableInput>
                                        </a-flex>
                                     </template>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>

                    <!-- #Team -->
                    <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_team = !expansion_panel_team">
                                <a-layout wrap align-start>
                                    <a-flex>
                                        <h4 class="md-subtitle-1" :class="{ 'pb-1': expansion_panel_team }">
                                            <a-icon size="20" class="mr-2">supervisor_account</a-icon>
                                            Team
                                            <span class="md-caption grey--text text--darken-1 ml-2" v-if="report_project_filters[filter.HIERARCHY] && report_project_filters[filter.HIERARCHY].length">
                                                <strong>&nbsp; Selected </strong>
                                                <span class="ml-1">{{ local_get_filter_label(report_project_filters[filter.HIERARCHY]) }}</span>
                                            </span>
                                        </h4>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon v-if="expansion_panel_team">expand_less</a-icon>
                                        <a-icon v-else>expand_more</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_team">
                                <a-layout wrap align-start>
                                    <a-flex>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Team</h4>
                                        <a-autocomplete
                                            v-model="report_project_filters[filter.HIERARCHY]"
                                            :items="local_team_filter_list"
                                            item-text="label"
                                            item-value="value"
                                            placeholder="Select Hierarchy"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details clearable hide-selected
                                            @input="local_filter(filter.HIERARCHY)"
                                        >
                                            <template v-slot:item="{ item }">
                                                <span class="md-body-2 d-block py-1 px-2 u-rounded-corners text-truncate" style="max-width: 520px;">{{ item.label }}</span>
                                            </template>
                                        </a-autocomplete>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>

                    <!-- #Status -->
                    <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_status = !expansion_panel_status">
                                <a-layout wrap align-start>
                                    <a-flex>
                                        <h4 class="md-subtitle-1" :class="{ 'pb-1': expansion_panel_status }">
                                            <a-icon size="20" class="mr-2">toggle_on</a-icon>
                                            Status
                                            <span class="md-caption grey--text text--darken-1">
                                                <span class="mx-2" v-if="report_project_filters[filter.STATUS] && report_project_filters[filter.STATUS].length">
                                                    <strong class="mr-1">Only</strong>
                                                    <template v-for="(item, index) in local_get_field_value(status_list_filtered, 'status', report_project_filters[filter.STATUS])">
                                                        <span :key="index" v-if="item">
                                                            <template v-if="index <= 2"><span v-if="index !== 0">, </span>{{ item.status }}</template>
                                                        </span>
                                                    </template>
                                                    <template v-if="local_get_field_value(status_list_filtered, 'status', report_project_filters[filter.STATUS]).length > 3"> and {{ local_get_field_value(status_list_filtered, 'status', report_project_filters[filter.STATUS]).length - 3 }} more</template>
                                                </span>
                                                <span class="mx-2" v-if="report_project_filters[filter.STAGE] && report_project_filters[filter.STAGE].length">
                                                    <strong class="mr-1">Under</strong>
                                                    <template v-for="(item, index) in local_get_field_value(stage_list_filtered, 'id', report_project_filters[filter.STAGE])">
                                                        <span :key="index">
                                                            <template v-if="index <= 2 && item"><span v-if="index !== 0">, </span>{{ item.name }}</template>
                                                        </span>
                                                    </template>
                                                    <template v-if="local_get_field_value(stage_list_filtered, 'id', report_project_filters[filter.STAGE]).length > 3"> and {{ local_get_field_value(stage_list_filtered, 'id', report_project_filters[filter.STAGE]).length - 3 }} more</template>
                                                </span>
                                                <span class="mx-2" v-if="report_project_filters[filter.HEALTH_COLOR] && report_project_filters[filter.HEALTH_COLOR].length">
                                                    <strong class="mr-1">Health as</strong>
                                                    <template v-for="(item, index) in local_get_field_value(color_list_filtered, 'value', report_project_filters[filter.HEALTH_COLOR])">
                                                        <span :key="index" v-if="item">
                                                            <template v-if="index <= 2"><span v-if="index !== 0">, </span>{{ item.status }}</template>
                                                        </span>
                                                    </template>
                                                    <template v-if="local_get_field_value(color_list_filtered, 'value', report_project_filters[filter.HEALTH_COLOR]).length > 3"> and {{ local_get_field_value(color_list_filtered, 'value', report_project_filters[filter.HEALTH_COLOR]).length - 3 }} more</template>
                                                </span>
                                                <span class="mx-2" v-if="report_project_filters[filter.RESULT] && report_project_filters[filter.RESULT].length && report_project_filters[filter.RESULT] === 'none'">{{ local_get_field_value(result_list_filtered, 'result.id', report_project_filters[filter.RESULT], 'result.value') }}</span>
                                                <span class="mx-2" v-if="report_project_filters[filter.RESULT] && report_project_filters[filter.RESULT].length && report_project_filters[filter.RESULT] !== 'none'">
                                                    <strong class="mr-1">Result as</strong>
                                                    <template v-for="(item, index) in local_get_field_value(result_list_filtered, 'id', report_project_filters[filter.RESULT])">
                                                        <span :key="index" v-if="item">
                                                            <template v-if="index <= 2"><span v-if="index !== 0">, </span>{{ item.id === 'none' ? 'None' : item.value }}</template>
                                                        </span>
                                                    </template>
                                                    <template v-if="local_get_field_value(result_list_filtered, 'id', report_project_filters[filter.RESULT]).length > 3"> and {{ local_get_field_value(result_list_filtered, 'id', report_project_filters[filter.RESULT]).length - 3 }} more</template>
                                                </span>
                                            </span>
                                        </h4>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon v-if="expansion_panel_status">expand_less</a-icon>
                                        <a-icon v-else>expand_more</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_status">
                                <a-layout wrap align-start>
                                    <!-- #Status -->
                                    <a-flex xs6 xl3>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Status</h4>
                                        <a-autocomplete
                                            v-model="report_project_filters[filter.STATUS]"
                                            :items="status_list_filtered"
                                            item-text="status"
                                            item-value="status"
                                            placeholder="All Status"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details multiple small-chips hide-selected clearable hide-no-data
                                            @input="local_filter(filter.STATUS)"
                                            @click:clear="local_status_tags_filter_clear(filter.STATUS)"
                                        >
                                            <template v-slot:selection="{ item }">
                                                <a-chip v-if="item && item === Object(item)" small class="u-rounded-corners" close @click:close="local_status_filter_remove(filter.STATUS, item.status)">{{ item.status }}</a-chip>
                                                <span v-else class="d-inline-block py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <span class="d-inline-block py-1 px-2">{{ item.status }}</span>
                                            </template>
                                        </a-autocomplete>
                                    </a-flex>

                                    <!-- #Stage -->
                                    <a-flex xs6 xl3>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Stage</h4>
                                        <a-autocomplete
                                            v-model="report_project_filters[filter.STAGE]"
                                            :items="stage_list_filtered"
                                            item-text="name"
                                            item-value="id"
                                            placeholder="All Stages"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details multiple small-chips hide-selected clearable hide-no-data
                                            @input="local_filter(filter.STAGE)"
                                            @click:clear="local_status_tags_filter_clear(filter.STAGE)"
                                        >
                                            <template v-slot:selection="{ item }">
                                                <a-chip v-if="item === Object(item) && item" small class="u-rounded-corners" :title="item.name" close @click:close="local_status_filter_remove(filter.STAGE, item.id)">{{ local_text_truncate(item.name, 14) }}</a-chip>
                                                <span v-else class="d-inline-block py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <a-layout align-center class="py-0" v-if="item">
                                                    <a-flex class="u-flex align-center">
                                                        <span class="d-inline-block py-1 px-2" :title="item.name">{{ local_text_truncate(item.name, 22) }}</span>
                                                        <a-spacer></a-spacer>
                                                        <span class="d-inline-block py-1 px-2 grey--text text--darken-1 caption">{{ item.workspace ? local_text_truncate(item.workspace.title, 22) : 'Workspace' }}</span>
                                                        <!-- <a-chip small class="ml-3 my-0 grey lighten-3 grey--text text--darken-2" v-if="item.workspace">{{ item.workspace.title }}</a-chip> -->
                                                    </a-flex>
                                                </a-layout>
                                            </template>
                                        </a-autocomplete>
                                    </a-flex>

                                    <!-- #Health -->
                                    <a-flex xs6 xl3>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Health</h4>
                                        <a-autocomplete
                                            v-model="report_project_filters[filter.HEALTH_COLOR]"
                                            :items="color_list_filtered"
                                            item-text="status"
                                            item-value="value"
                                            placeholder="All Health"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details multiple small-chips hide-selected clearable hide-no-data
                                            @input="local_filter(filter.HEALTH_COLOR)"
                                            @click:clear="local_status_tags_filter_clear(filter.HEALTH_COLOR)"
                                        >
                                            <template v-slot:selection="{ item }">
                                                <a-chip v-if="item && item === Object(item)" small class="u-rounded-corners" close @click:close="local_status_filter_remove(filter.HEALTH_COLOR, item.value)">{{ item.status }}</a-chip>
                                                <span v-else class="d-inline-block py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <span class="d-inline-block py-1 px-2">{{ item.status }}</span>
                                            </template>
                                        </a-autocomplete>
                                    </a-flex>

                                    <!-- #Result -->
                                    <a-flex xs6 xl3>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Result</h4>
                                        <a-autocomplete
                                            v-model="report_project_filters[filter.RESULT]"
                                            :items="result_list_filtered"
                                            item-text="value"
                                            item-value="id"
                                            placeholder="All Result"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details multiple small-chips hide-selected clearable hide-no-data
                                            @input="local_filter(filter.RESULT)"
                                            @click:clear="local_status_tags_filter_clear(filter.RESULT)"
                                        >
                                            <template v-slot:selection="{ item }">
                                                <a-chip v-if="item === Object(item) && item" small class="u-rounded-corners" close @click:close="local_status_filter_remove(filter.RESULT, item.id)">{{ item.value }}</a-chip>
                                                <span v-else class="d-inline-block py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <span class="d-inline-block py-1 px-2">{{ item.value }}</span>
                                            </template>
                                        </a-autocomplete>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>

                    <!-- #Custom Fields-->
                    <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_customfields = !expansion_panel_customfields">
                                <a-layout align-center>
                                    <a-flex>
                                        <h4 class="md-subtitle-1">
                                            <a-icon size="20" class="mr-2">ballot</a-icon>
                                            Custom Fields
                                            <span class="md-caption grey--text text--darken-1" v-if="custom_field || field_condition || custom_field_dropdown || custom_field_value">
                                                <strong class="mr-1">&nbsp; Selected</strong>
                                                <span class="mr-1" v-if="custom_field">{{ local_get_value(custom_field_list_filtered, custom_field) }}</span>
                                                <span class="mr-1" v-if="field_condition">{{ local_get_condition(field_condition) }}</span>
                                                <span class="mr-1" v-if="custom_field_value">{{ custom_field_value }}</span>
                                                <span v-if="custom_field_dropdown">{{ local_get_dropdown_label (filtered_dropdown_label, custom_field_dropdown) }}</span>
                                                <g-moment tag="span" class="mr-1" :value="customfield_date_value1" input-format="YYYY-MM-DD" output-format="MMMM DD, YYYY" v-if="customfield_date_value1"></g-moment>
                                                <span v-if="customfield_date_value2">and <g-moment tag="span" :value="customfield_date_value2" input-format="YYYY-MM-DD" output-format="MMMM DD, YYYY"></g-moment></span>
                                            </span>
                                            <span class="mx-2 caption red--text text--darken-1" v-if="show_custom_field_error">Please fill all the respective custom fields.</span>
                                        </h4>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon v-if="expansion_panel_customfields">expand_less</a-icon>
                                        <a-icon v-else>expand_more</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_customfields">
                                <a-layout align-start>
                                    <!-- #Custom Fields -->
                                    <a-flex>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Field</h4>
                                        <a-autocomplete
                                            v-model="custom_field"
                                            :items="custom_field_list_filtered"
                                            item-text="label"
                                            item-value="id"
                                            placeholder="Select Custom Field"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details clearable auto-select-first
                                            @input="local_filter('custom_fields')"
                                        >
                                            <template v-slot:item="{ item }">
                                                <div class="u-flex-center-y u-wfull" :text="item.name">
                                                    <span class="md-body-2 d-block py-1 px-2 u-rounded-corners text-truncate" style="max-width: 300px;">{{ item.label }}</span>
                                                    <template v-if="item.is_active === 0">
                                                        <a-spacer></a-spacer>
                                                        <span class="md-caption grey lighten-3 grey--text text--darken-2 px-2 u-rounded-corners-full">Disabled</span>
                                                    </template>
                                                </div>
                                            </template>
                                        </a-autocomplete>
                                    </a-flex>
                                    <a-flex>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Condition</h4>
                                        <a-autocomplete
                                            v-model="field_condition"
                                            :items="local_customfield_item_load"
                                            placeholder="Select Condition"
                                            item-text="text"
                                            item-value="value"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="local_check_customfield_filters('field_condition')"
                                            solo flat hide-details clearable
                                            @input="local_filter('custom_fields', 'customfield_condition')"
                                        >
                                        </a-autocomplete>
                                    </a-flex>
                                    <a-flex v-if="local_customfield_text_multiline">
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Value</h4>
                                            <template>
                                                <a-text-field
                                                    v-model="custom_field_value"
                                                    placeholder="Enter value"
                                                    background-color="neutral"
                                                    clear-icon="backspace"
                                                    :disabled="local_check_customfield_filters('custom_field_value')"
                                                    solo flat hide-details clearable
                                                    @input="local_filter('custom_fields')"
                                                ></a-text-field>
                                            </template>
                                    </a-flex>
                                    <a-flex v-if="local_customfield_date">
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2" v-if="field_condition === 'between'">From</h4>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2" v-else>Value</h4>
                                        <s-date-picker
                                            :dateCondition="date_condition === 'between' ? {condition: 'between', start_date: null, end_date: custom_field_date2} : {condition: null, start_date: null, end_date: null}"
                                            :disabled="local_check_customfield_filters('customfield_date_value1')"
                                            noDueDate startDateTitle="Date" showFooter
                                            :start-date-max-range="localCheckMaxDateRange(customfield_end.start_date)"
                                            :item="customfield"
                                            @update="local_filter('custom_fields')"
                                        >
                                            <a-sheet width="100%">
                                                <template>
                                                    <a-text-field
                                                        v-model="custom_field_date1"
                                                        placeholder="Select Date"
                                                        append-icon="event"
                                                        background-color="neutral"
                                                        clear-icon="backspace"
                                                        readonly solo flat hide-details clearable
                                                        @input="local_filter('custom_fields')"
                                                    ></a-text-field>
                                                </template>
                                            </a-sheet>
                                        </s-date-picker>
                                    </a-flex>
                                    <a-flex v-if="['between'].includes(field_condition)">
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">To</h4>
                                        <template v-if="filtered_field_type === 'number'">
                                            <a-text-field
                                                v-model="customfield_number_value_at"
                                                placeholder="Enter value"
                                                background-color="neutral"
                                                clear-icon="backspace"
                                                :disabled="local_check_customfield_filters('custom_field_value')"
                                                solo flat hide-details clearable
                                                @input="local_filter('custom_fields')"
                                            ></a-text-field>
                                        </template>
                                        <template v-else>
                                            <s-date-picker
                                                :dateCondition="{condition: 'between', start_date: custom_field_date1, end_date: null}"
                                                :disabled="local_check_customfield_filters('customfield_date_value2')"
                                                noDueDate startDateTitle="Date" showFooter
                                                :start-date-min-range="localCheckMinDateRange(customfield.start_date)"
                                                :item="customfield_end"
                                                @update="local_filter('custom_fields')"
                                            >
                                                <a-sheet width="100%">
                                                    <template>
                                                        <a-text-field
                                                            v-model="custom_field_date2"
                                                            placeholder="Select Date"
                                                            append-icon="event"
                                                            background-color="neutral"
                                                            clear-icon="backspace"
                                                            readonly solo flat hide-details clearable
                                                            @input="local_filter('custom_fields')"
                                                        ></a-text-field>
                                                    </template>
                                                </a-sheet>
                                            </s-date-picker>
                                        </template>
                                    </a-flex>
                                    <a-flex v-if="filtered_field_type === 'dropdown' && !['empty', 'not-empty'].includes(field_condition)">
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Value</h4>
                                        <a-autocomplete
                                            v-model="custom_field_dropdown"
                                            :items="filtered_dropdown_label"
                                            item-text="label"
                                            item-value="value"
                                            placeholder="Select value"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="local_check_customfield_filters('custom_field_dropdown')"
                                            solo flat hide-details clearable auto-select-first
                                            @input="local_filter('custom_fields')"
                                        >
                                        </a-autocomplete>
                                    </a-flex>
                                    <a-flex v-if="['empty', 'not-empty'].includes(field_condition)" class="text-left px-2" style="min-width: 486px; max-width: 486px">
                                       <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Value</h4>
                                       <a-sheet class="grey lighten-4 body--text text--lighten-3 font-italic px-3 u-flex-center-y u-rounded-corners-lg" height="48">
                                            No value required
                                       </a-sheet>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>

                    <!-- #System Tags -->
                    <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                        <a-card-text class="pa-0">
                            <!-- #System Tags -->
                            <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_tags = !expansion_panel_tags">
                                <a-layout wrap align-start>
                                    <a-flex>
                                        <h4 class="md-subtitle-1" :class="{ 'pb-1': expansion_panel_tags }">
                                            <a-icon size="20" class="mr-2">label</a-icon>
                                            System Tags
                                            <span class="md-caption grey--text text--darken-1">
                                                <span class="mx-2" v-if="report_project_filters[filter.TAGS] && report_project_filters[filter.TAGS].length > 0">
                                                    <template v-if="label_filter_tags.length">
                                                        <strong class="mr-1">&nbsp; Selected</strong>
                                                        <template v-for="(item, index) in local_get_field_value(tag_list_filtered, 'id', label_filter_tags, 'label')">
                                                            <span :key="item.id" v-if="item">
                                                                <template v-if="index <= 2"><span v-if="index !== 0">, </span>{{ item.label }}</template>
                                                            </span>
                                                        </template>
                                                        <template v-if="local_get_field_value(tag_list_filtered, 'id', label_filter_tags).length > 3"> and
                                                            {{ local_get_field_value(tag_list_filtered, 'id', label_filter_tags).length - 3 }}
                                                            {{ local_get_field_value(tag_list_filtered, 'id', label_filter_tags).length - 3 > 1 ? 'more labels' : 'more label' }}
                                                        </template>
                                                    </template>
                                                    <template v-if="product_filter_tags.length">
                                                        <strong class="mr-1">&nbsp; Selected</strong>
                                                        <template v-for="(item, index) in local_get_field_value(tag_list_filtered, 'id', product_filter_tags, 'product')">
                                                            <span :key="item.id" v-if="item">
                                                                <template v-if="index <= 2"><span v-if="index !== 0">, </span>{{ item.label }}</template>
                                                            </span>
                                                        </template>
                                                        <template v-if="local_get_field_value(tag_list_filtered, 'id', product_filter_tags).length > 3"> and
                                                            {{ local_get_field_value(tag_list_filtered, 'id', product_filter_tags).length - 3 }}
                                                            {{ local_get_field_value(tag_list_filtered, 'id', product_filter_tags).length - 3 > 1 ? 'more products' : 'more product' }}
                                                        </template>
                                                    </template>
                                                    <template v-if="platform_filter_tags.length">
                                                        <strong class="mr-1">&nbsp; Selected</strong>
                                                        <template v-for="(item, index) in local_get_field_value(tag_list_filtered, 'id', platform_filter_tags, 'platform')">
                                                            <span :key="item.id" v-if="item">
                                                                <template v-if="index <= 2"><span v-if="index !== 0">, </span>{{ item.label }}</template>
                                                            </span>
                                                        </template>
                                                        <template v-if="local_get_field_value(tag_list_filtered, 'id', platform_filter_tags).length > 3"> and
                                                            {{ local_get_field_value(tag_list_filtered, 'id', platform_filter_tags).length - 3 }}
                                                            {{ local_get_field_value(tag_list_filtered, 'id', platform_filter_tags).length - 3 > 1 ? 'more platforms' : 'more platform' }}</template>
                                                    </template>
                                                </span>
                                            </span>
                                        </h4>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon v-if="expansion_panel_tags">expand_less</a-icon>
                                        <a-icon v-else>expand_more</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_tags">
                                <a-layout wrap align-start>
                                    <!-- #Labels -->
                                    <a-flex xs4>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Labels</h4>
                                        <a-autocomplete
                                            v-model="label_filter_tags"
                                            :items="local_tag_filter(tag_list_filtered, {type: 'label'})"
                                            item-text="label"
                                            item-value="id"
                                            placeholder="All Labels"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details clearable multiple deletable-chips small-chips auto-select-first hide-selected
                                            @input="local_filter()"
                                            @click:clear="local_status_tags_filter_clear('label')"
                                        >
                                            <template v-slot:selection="{ item }">
                                                <a-chip v-if="item === Object(item) && item" small class="md-body-2 u-rounded-corners" close :color="$color(item.color, 'color_bg')" :text-color="$color(item.color, 'color_text')" @click:close="local_tag_filter_remove('label', item.id)">{{ item.label }}</a-chip>
                                                <span v-else class="md-body-2 d-inline-block py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <span class="md-body-2 d-inline-block py-1 px-2 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ item.label }}</span>
                                            </template>
                                        </a-autocomplete>
                                    </a-flex>

                                    <!-- #Products -->
                                    <a-flex xs4>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Products</h4>
                                        <a-autocomplete
                                            v-model="product_filter_tags"
                                            :items="local_tag_filter(tag_list_filtered, {type: 'product'})"
                                            item-text="label"
                                            item-value="id"
                                            placeholder="All Products"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details clearable multiple deletable-chips small-chips auto-select-first hide-selected
                                            @input="local_filter()"
                                            @click:clear="local_status_tags_filter_clear('product')"
                                        >
                                            <template v-slot:selection="{ item }">
                                                <a-chip v-if="item === Object(item) && item" small class="md-body-2 u-rounded-corners" close :color="$color(item.color, 'color_bg')" :text-color="$color(item.color, 'color_text')" @click:close="local_tag_filter_remove('product', item.id)">{{ item.label }}</a-chip>
                                                <span v-else class="md-body-2 d-inline-block py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <span class="md-body-2 d-inline-block py-1 px-2 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ item.label }}</span>
                                            </template>
                                        </a-autocomplete>
                                    </a-flex>

                                    <!-- #Platforms -->
                                    <a-flex xs4>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Platforms</h4>
                                        <a-autocomplete
                                            v-model="platform_filter_tags"
                                            :items="local_tag_filter(tag_list_filtered, {type: 'platform'})"
                                            item-text="label"
                                            item-value="id"
                                            placeholder="All Platforms"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details clearable multiple deletable-chips small-chips auto-select-first hide-selected
                                            @input="local_filter()"
                                            @click:clear="local_status_tags_filter_clear('platform')"
                                        >
                                            <template v-slot:selection="{ item }">
                                                <a-chip v-if="item === Object(item) && item" small class="md-body-2 u-rounded-corners" close :color="$color(item.color, 'color_bg')" :text-color="$color(item.color, 'color_text')" @click:close="local_tag_filter_remove('platform', item.id)">{{ item.label }}</a-chip>
                                                <span v-else class="md-body-2 d-inline-block py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <span class="md-body-2 d-inline-block py-1 px-2 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ item.label }}</span>
                                            </template>
                                        </a-autocomplete>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>

                    <!-- #Custom Tags -->
                     <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_custom_tags = !expansion_panel_custom_tags">
                                <a-layout wrap align-start>
                                    <a-flex>
                                        <h4 class="md-subtitle-1" :class="{ 'pb-1': expansion_panel_custom_tags }">
                                            <a-icon size="20" class="mr-2">label</a-icon>
                                            Custom Tags
                                            <span class="md-caption grey--text text--darken-1 ml-2" v-if="custom_tags && custom_tags.length">
                                                <strong>&nbsp; Selected </strong>
                                                <template v-for="(item, index) in local_get_field_value(tag_list_filtered, 'id', custom_tags)">
                                                    <span :key="item.id" v-if="item">
                                                        <template v-if="index <= 2"><span v-if="index !== 0">, </span>{{ item.label }}</template>
                                                    </span>
                                                </template>
                                                <template v-if="local_get_field_value(tag_list_filtered, 'id', custom_tags).length > 3"> and
                                                    {{ local_get_field_value(tag_list_filtered, 'id', custom_tags).length - 3 }}
                                                    {{ local_get_field_value(tag_list_filtered, 'id', custom_tags).length - 3 > 1 ? 'more custom tags' : 'more custom tag' }}
                                                </template>
                                            </span>
                                        </h4>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon v-if="expansion_panel_custom_tags">expand_less</a-icon>
                                        <a-icon v-else>expand_more</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_custom_tags">
                                <a-layout wrap align-start>
                                     <template v-for="tag_section in tag_section_list" >
                                        <a-flex xs4 :key="tag_section.id">
                                            <a-tooltip :disabled="!!(tag_section.is_active)" right content-class="c-tooltip-pointer c-tooltip-pointer--left">
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on" class="d-inline-flex align-items-center mb-2">
                                                        <h4 class="md-subtitle-2 font-weight-bold grey--text">{{ tag_section.name | truncateText(40) }}</h4>
                                                        <a-icon v-if="!tag_section.is_active" size="14" color="red darken-4" class="ml-1">block</a-icon>
                                                    </div>
                                                </template>
                                                <span>Disabled</span>
                                            </a-tooltip>
                                            <a-autocomplete
                                                v-model="custom_tags"
                                                :items="local_tag_filter(tag_list_filtered, {type: tag_section.slug })"
                                                item-text="label"
                                                item-value="id"
                                                :placeholder="'All '+tag_section.name"
                                                class="elevation-0"
                                                background-color="neutral"
                                                clear-icon="backspace"
                                                :disabled="loading"
                                                solo flat hide-details multiple deletable-chips small-chips auto-select-first hide-selected
                                                @input="local_filter()"
                                                @click:clear="local_status_tags_filter_clear(tag_section.slug, 'custom')"
                                            >
                                                <template v-slot:selection="{ item }">
                                                    <a-chip v-if="item === Object(item) && item" small class="md-body-2 u-rounded-corners" close :color="$color(item.color, 'color_bg')" :text-color="$color(item.color, 'color_text')" @click:close="local_tag_filter_remove(tag_section.sulg, item.id, 'custom')">{{ item.label }}</a-chip>
                                                    <span v-else class="md-body-2 d-inline-block py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <span class="md-body-2 d-inline-block py-1 px-2 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ item.label }}</span>
                                                </template>
                                            </a-autocomplete>
                                        </a-flex>
                                     </template>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>

                    <!-- #Territories -->
                    <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_territories = !expansion_panel_territories">
                                <a-layout wrap align-start>
                                    <a-flex>
                                        <h4 class="md-subtitle-1" :class="{ 'pb-1': expansion_panel_territories }">
                                            <a-icon size="20" class="mr-2">terrain</a-icon>
                                            Territories
                                            <span class="md-caption grey--text text--darken-1 ml-2" v-if="report_project_filters[filter.TERRITORIES] && report_project_filters[filter.TERRITORIES].length">
                                                <strong>&nbsp; Selected </strong>
                                                <template v-for="(item, index) in local_get_field_value(territory_list_filtered, 'id', report_project_filters[filter.TERRITORIES])">
                                                    <span :key="item.id" v-if="item">
                                                        <template v-if="index <= 2"><span v-if="index !== 0">, </span>{{ item.name }}</template>
                                                    </span>
                                                </template>
                                                <template v-if="local_get_field_value(territory_list_filtered, 'id', report_project_filters[filter.TERRITORIES]).length > 3"> and
                                                    {{ local_get_field_value(territory_list_filtered, 'id', report_project_filters[filter.TERRITORIES]).length - 3 }}
                                                    {{ local_get_field_value(territory_list_filtered, 'id', report_project_filters[filter.TERRITORIES]).length - 3 > 1 ? 'more territories' : 'more territory' }}
                                                </template>
                                            </span>
                                        </h4>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon v-if="expansion_panel_territories">expand_less</a-icon>
                                        <a-icon v-else>expand_more</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_territories">
                                <a-layout wrap align-start>
                                    <a-flex>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Territories</h4>
                                        <a-autocomplete
                                            v-model="report_project_filters[filter.TERRITORIES]"
                                            :items="territory_list_filtered"
                                            item-text="name"
                                            item-value="id"
                                            placeholder="All Territories"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details clearable multiple deletable-chips small-chips auto-select-first hide-selected
                                            @input="local_filter(filter.TERRITORIES)"
                                        >
                                            <template v-slot:selection="{ item }">
                                                <a-chip v-if="item === Object(item) && item" small class="md-body-2 u-rounded-corners" close :color="$color(item.color, 'color_bg')" :text-color="$color(item.color, 'color_text')" @click:close="local_territory_filter_remove(item.id)">{{ item.name }}</a-chip>
                                                <span v-else class="md-body-2 d-inline-block py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <span class="md-body-2 d-inline-block py-1 px-2 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ item.name }}</span>
                                            </template>
                                        </a-autocomplete>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>

                    <!-- #Competitors -->
                     <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_custom_competitors = !expansion_panel_custom_competitors">
                                <a-layout wrap align-start>
                                    <a-flex>
                                        <h4 class="md-subtitle-1 u-relative d-inline-block" :class="{ 'pb-1': expansion_panel_custom_competitors }">
                                            <a-icon size="20" class="mr-2">work</a-icon>
                                            Competitors
                                            <span class="md-caption grey--text text--darken-1 ml-2 u-absolute" style="right: -150px; top: 1px;" v-if="custom_competitors && Object.keys(custom_competitors).length > 0">
                                                <strong>&nbsp; Selected </strong> {{ local_count_custom_comps(custom_competitors) }} competitor{{ local_count_custom_comps(custom_competitors) > 1 ? 's' : '' }}
                                            </span>
                                        </h4>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon v-if="expansion_panel_custom_competitors">expand_less</a-icon>
                                        <a-icon v-else>expand_more</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_custom_competitors">
                                <a-layout wrap align-start>
                                     <template v-for="competitor_type in competitor_type_list">
                                        <a-flex xs4 :key="competitor_type.id">
                                            <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">{{ competitor_type.value }}</h4>
                                            <a-autocomplete
                                                v-model="custom_competitors[competitor_type.id]"
                                                :items="competitor_list"
                                                item-text="name"
                                                item-value="id"
                                                :placeholder="'All '+competitor_type.value"
                                                class="elevation-0"
                                                background-color="neutral"
                                                clear-icon="backspace"
                                                :disabled="loading"
                                                solo flat hide-details multiple deletable-chips small-chips auto-select-first hide-selected clearable
                                                @input="local_filter('custom_competitors')"
                                                @click:clear="local_filter('custom_competitors')"
                                            >
                                                <!--@click:clear="local_status_tags_filter_clear(competitor_type.type, 'custom')"-->
                                                <template v-slot:item="{ item }">
                                                    <span class="md-body-2 d-block py-1 px-2 u-rounded-corners text-truncate" style="max-width: 520px;">{{ item.name }}</span>
                                                </template>
                                            </a-autocomplete>
                                        </a-flex>
                                     </template>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>

                    <!-- #Partners -->
                    <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_partners = !expansion_panel_partners">
                                <a-layout wrap align-start>
                                    <a-flex>
                                        <h4 class="md-subtitle-1" :class="{ 'pb-1': expansion_panel_partners }">
                                            <a-icon size="20" class="mr-2">contacts</a-icon>
                                            Partners
                                            <span class="md-caption grey--text text--darken-1 ml-2" v-if="report_project_filters[filter.PARTNERS] && report_project_filters[filter.PARTNERS].length">
                                                <strong>&nbsp; Selected </strong>
                                                <template v-for="(item, index) in local_get_field_value(partner_list_filtered, 'id', report_project_filters[filter.PARTNERS])">
                                                    <span :key="item.id" v-if="item">
                                                        <template v-if="index <= 2"><span v-if="index !== 0">, </span>{{ item.name }}</template>
                                                    </span>
                                                </template>
                                                <template v-if="local_get_field_value(partner_list_filtered, 'id', report_project_filters[filter.PARTNERS]).length > 3"> and
                                                    {{ local_get_field_value(partner_list_filtered, 'id', report_project_filters[filter.PARTNERS]).length - 3 }}
                                                    {{ local_get_field_value(partner_list_filtered, 'id', report_project_filters[filter.PARTNERS]).length - 3 > 1 ? 'more partners' : 'more partner' }}
                                                </template>
                                            </span>
                                        </h4>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon v-if="expansion_panel_partners">expand_less</a-icon>
                                        <a-icon v-else>expand_more</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_partners">
                                <a-layout wrap align-start>
                                    <a-flex>
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Partners</h4>
                                        <a-autocomplete
                                            v-model="report_project_filters[filter.PARTNERS]"
                                            :items="partner_list_filtered"
                                            item-text="name"
                                            item-value="id"
                                            placeholder="All Partners"
                                            class="elevation-0"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details clearable multiple deletable-chips small-chips auto-select-first hide-selected
                                            @input="local_filter(filter.PARTNERS)"
                                        >
                                        </a-autocomplete>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- #Generate Report Button -->
        <a-container container--fluid grid-list-xl pa-6 pb-1 px-0>
            <a-layout>
                <a-flex class="pa-0">
                    <a-card class="u-overflow-hidden mt-1 transparent elevation-0 mt-2 px-0">
                        <a-card-text>
                            <a-container container--fluid grid-list-xl class="py-1 pr-0">
                                <a-layout wrap align-center class="py-1">
                                    <a-flex shrink class="pa-0">
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text text--darken-1"><span class="red--text text--darken-1">* </span>Please use the above filters to generate a report.</h4>
                                    </a-flex>
                                    <a-spacer></a-spacer>
                                    <a-flex shrink class="text-xs-right pt-2">
                                        <a-btn color="primary" medium class="u-rounded-corners u-elevation-custom-1" :disabled="loading || !button_filter_clear || !local_check_filter_applied" :loading="loading && button_filter_clear" @click="generate_report()">
                                            <a-icon class="mr-2" size="18">timeline</a-icon>
                                            Generate Report
                                        </a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- #Download Buttons -->
        <a-container container--fluid grid-list-xl pa-6 px-0 pb-12 mb-12 v-if="report_projects">
            <a-layout align-center v-if="report_projects.length">
                <a-flex>
                    <p class="md-body-2 mb-0 grey--text text--darken-1">Showing <strong>{{ report_projects.length }}</strong> {{ (report_projects.length === 1) ? ' project' : ' projects' }}</p>
                </a-flex>
                <a-flex shrink v-if="report_projects.length">
                    <a-btn small text color="grey darken-1 u-rounded-corners" class="ma-0" @click="show_field_preference_list = true">
                        <a-icon left size="16" class="mr-2">tune</a-icon> Fields
                    </a-btn>
                </a-flex>
                <a-flex shrink v-if="$can('projects.download') && $can('reports.download') && report_projects.length">
                    <a-menu offset-y left bottom>
                        <template v-slot:activator="{ on }">
                            <a-btn small text color="grey darken-1 u-rounded-corners" class="ma-0" v-on="on">
                                <a-icon left size="16" class="mr-2">save_alt</a-icon> Download <a-icon right size="18" class="ml-1">arrow_drop_down</a-icon>
                            </a-btn>
                        </template>
                        <a-list>
                            <a-list-item @click="local_download_csv()">
                                <a-list-item-title class="md-body-2">Download as CSV</a-list-item-title>
                            </a-list-item>
                            <a-list-item @click="local_download_excel()">
                                <a-list-item-title class="md-body-2">Download as Excel</a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
            </a-layout>

            <!-- Reports Table -->
            <a-layout align-start v-if="report_projects.length && local_check_filter_applied">
                <a-flex xs12>
                    <a-card flat class="u-border pa-0" ref="reports_table">
                        <a-data-table
                            :headers="local_selected_headers"
                            :items="report_projects"
                            :server-items-length="total_project_count"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :must-sort="true"
                            class="c-reports-table"
                            hide-default-footer fixed-header hide-default-header
                        >
                            <template v-slot:header="{ props }">
                                <thead>
                                    <tr style="height: 70px">
                                        <template v-for="head in props.headers">
                                            <th :style="[{ minWidth: head.width + 'px', maxWidth: head.width + 'px' }]" :key="head.value" class="u-cursor-pointer" @click="localSortTable(head)">
                                                <div class="u-flex-center-y">
                                                    <a-tooltip left v-if="localShowBlockStatus(head)" content-class="c-tooltip-pointer c-tooltip-pointer--top">
                                                        <template v-slot:activator="{ on }">
                                                            <a-icon v-on="on" class="mr-1" size="14" color="red darken-4">block</a-icon>
                                                        </template>
                                                        <span>Disabled</span>
                                                    </a-tooltip>
                                                    <div class="u-flex flex-column">
                                                        <span class="d-block text-truncate" :title="head.text_full ? head.text_full : head.text">{{ head.text }}</span>
                                                        <span class="d-block pt-1 grey--text" style="fontSize: 10px" v-if="localHeaderTypeIsConfidenceScore(head.type)">
                                                            <template v-if="head.slug === 'accumulated_confidence_score'">Confidence Score</template>
                                                            <template v-else>Confidence Score - Section</template>
                                                        </span>
                                                    </div>
                                                    <template v-if="head.sortable">
                                                        <a-icon v-if="sortBy === head.value" class="ml-1" size="16" color="grey darken-3">
                                                            arrow_{{ sortDesc ? 'downward' : 'upward' }}
                                                        </a-icon>
                                                        <a-icon v-else class="ml-1" size="16" color="grey lighten-1">
                                                            arrow_upward
                                                        </a-icon>
                                                    </template>
                                                </div>
                                            </th>
                                        </template>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td v-for="header in local_selected_headers" :key="header.value" class="text-truncate">
                                        <template v-if="header.slug === 'health_color' && header.show">
                                            <div class="u-flex align-center">
                                                <a-icon v-if="!item.health_color || item.health_color == 'none'" class="" color="grey lighten-2" size="16">fiber_manual_record</a-icon>
                                                <a-icon v-if="item.health_color == 'green'" class="" color="green" size="16">fiber_manual_record</a-icon>
                                                <a-icon v-if="item.health_color == 'yellow'" class="" color="orange" size="16">fiber_manual_record</a-icon>
                                                <a-icon v-if="item.health_color == 'red'" class="" color="red" size="16">fiber_manual_record</a-icon>
                                                <span class="md-caption grey--text text--darken-1 ml-2 text-capitalize">{{ item.health_color }}</span>
                                            </div>
                                        </template>
                                        <template v-if="header.slug === 'last_status_update' && header.show">
                                            <a-menu v-if="item.latest_status_update" :close-on-content-click="false" open-on-hover content-class="u-elevation-custom-1 c-border" min-width="300" max-width="400" max-height="400" nudge-left="20" offset-x top>
                                                <template v-slot:activator="{ on }">
                                                    <span class="blue--text text--darken-1 md-caption u-rounded-corners" v-on="on">View Status Update</span>
                                                </template>
                                                <a-card class="px-4 pb-2 u-rounded-corners">
                                                    <div class="pt-4 white" style="position: sticky; top: 0">
                                                        <div class="u-flex align-center">
                                                            <h2 class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase">Status Update</h2>
                                                            <a-spacer></a-spacer>
                                                            <router-link tag="a" class="blue--text text--darken-2 text-uppercase font-weight-bold u-flex align-center md-caption" target="_blank"  :to="{name: 'projects-overview', params: {id: item.id}}">
                                                                view
                                                                <a-icon size="15" color="blue" class="ml-1">launch</a-icon>
                                                            </router-link>
                                                        </div>
                                                        <a-divider class="grey lighten-2 mt-2 mb-3"></a-divider>
                                                    </div>
                                                    <div class="md-body-2" style="white-space: pre-wrap;" >{{ item.latest_status_update.description }}</div>
                                                </a-card>
                                            </a-menu>
                                            <p class="grey--text text--darken-1" v-else> - </p>
                                        </template>
                                        <template v-if="header.slug === 'last_status_update_type' && header.show">
                                            {{ (item.latest_status_update && item.latest_status_update.type) ? item.latest_status_update.type.value : '–' }}
                                        </template>
                                        <template v-if="header.slug === 'last_status_update_by' && header.show">
                                            <template v-if="item.latest_status_update && item.latest_status_update.created_by">
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <g-avatar :size="30" :item="item.latest_status_update.created_by"></g-avatar>
                                                        </div>
                                                    </template>
                                                    <g-profile-info :item="item.latest_status_update.created_by" dark type-user text-center hide-block></g-profile-info>
                                                </a-tooltip>
                                            </template>
                                            <template v-else> - </template>
                                        </template>
                                        <template v-if="header.slug === 'last_status_update_at' && header.show">
                                            <template v-if="item.latest_status_update">
                                                <g-moment tag="span" :value="item.latest_status_update.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM D, YYYY h:mma" convert-local></g-moment>
                                            </template>
                                            <template v-else> - </template>
                                        </template>
                                        <template v-if="header.slug === 'workspace' && header.show">
                                            {{ (item.workspace) ? item.workspace.title : '–' }}
                                        </template>
                                        <template v-if="header.slug === 'title' && header.show">
                                            <router-link target="_blank" :to="{name: 'projects-overview', params: {id: item.id}}" class="blue--text text--darken-3 u-text-no-underline">{{ item.title }}</router-link>
                                        </template>
                                        <template v-if="header.slug === 'deal_amount' && header.show">
                                            <g-currency tag="span" v-if="item.deal_amount" :value="item.deal_amount"></g-currency>
                                        </template>
                                        <template v-if="header.slug === 'customer' && header.show">
                                            {{ (item.customer) ? item.customer.name : '–' }}
                                        </template>
                                        <template v-if="header.slug === 'next_step' && header.show">
                                            <a-menu v-if="item.next_step_json" :close-on-content-click="false" open-on-hover content-class="u-elevation-custom-1 c-border" min-width="300" max-width="600" max-height="400" nudge-left="20" offset-x top>
                                                <template v-slot:activator="{ on }">
                                                    <span class="blue--text text--darken-1 md-caption u-rounded-corners" v-on="on">View Next Step</span>
                                                </template>
                                                <a-card class="px-4 pb-4 u-rounded-corners">
                                                    <div class="pt-4 white" style="position: sticky; top: 0">
                                                        <div class="u-flex align-center">
                                                            <h2 class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase">Next Step</h2>
                                                            <a-spacer></a-spacer>
                                                            <router-link tag="a" class="blue--text text--darken-2 text-uppercase font-weight-bold u-flex align-center md-caption" v-if="$can('projects.update-only')" :to="{name: 'projects-overview', params: {id: item.id}}">Edit</router-link>
                                                        </div>
                                                        <a-divider class="grey lighten-2 mt-2 mb-3"></a-divider>
                                                    </div>
                                                    <g-editor-box
                                                        v-if="item.next_step_json"
                                                        v-model="item.next_step_json"
                                                        :can-update="false"
                                                        :model-is-open="false"
                                                        :editor-min-height="150"
                                                        :editor-height="150"
                                                        dense hideFooter hideFocus hideBorder hideCloseBtn hideDivider
                                                    ></g-editor-box>
                                                </a-card>
                                            </a-menu>
                                            <p class="grey--text text--darken-1" v-else> - </p>
                                        </template>
                                        <template v-if="header.slug === 'system_tags' && header.show">
                                            <span v-if="item.all_tags && !item.all_tags.length">–</span>
                                            <g-tags v-else v-for="tag in local_filter_tag_type(item.all_tags, 'system')" :tag="tag" :key="tag.id" hide-clear-icon></g-tags>
                                        </template>
                                        <template v-if="header.slug === 'custom_tags' && header.show">
                                            <span v-if="item.all_tags && !item.all_tags.length">–</span>
                                            <g-tags v-else v-for="tag in local_filter_tag_type(item.all_tags, 'custom')" :tag="tag" :key="tag.id" hide-clear-icon></g-tags>
                                        </template>
                                        <template v-if="header.slug === 'territories' && header.show">
                                            <span v-if="item.territories && !item.territories.length">–</span>
                                            <g-tags v-else v-for="territory in item.territories" :key="territory.id" :tag="territory" hide-clear-icon></g-tags>
                                        </template>
                                        <template v-if="header.slug === 'stage' && header.show">
                                            {{ item.stage ? item.stage.name : '-' }}
                                        </template>
                                        <template v-if="header.slug === 'status' && header.show">
                                            <span class="text-capitalize">{{ item.status}}</span>
                                        </template>
                                        <template v-if="header.slug === 'result' && header.show">
                                            {{ (item.result) ? item.result.value : '–' }}
                                        </template>
                                        <template v-if="header.type === 'competitors' && header.show">
                                            <template v-if="item.competitors && item.competitors.length">
                                                <span v-if="!local_get_comp_value(header.id, item.competitors).count">–</span>
                                                <span class="d-body-2 d-inline-block blue white--text py-1 px-2 mr-2 u-rounded-corners" v-for="competitor in local_get_comp_value(header.id, item.competitors).list" :key="competitor.id">{{ competitor.name }}</span>
                                            </template>
                                            <template v-else><span class="ml-2"> - </span></template>
                                        </template>
                                        <template v-if="header.slug === 'partner' && header.show">
                                            <span v-if="!item.partner">–</span>
                                            <span v-else>{{ item.partner.name }}</span>
                                        </template>
                                        <template v-if="header.slug === 'start_date' && header.show">
                                            <g-moment v-if="item.start_date" tag="span" :value="item.start_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                            <span v-else>-</span>
                                        </template>
                                        <template v-if="header.slug === 'due_date' && header.show">
                                            <g-moment v-if="item.due_date" tag="span" :value="item.due_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                            <span v-else>-</span>
                                        </template>
                                        <template v-if="header.slug === 'estimated_start_date' && header.show">
                                            <g-moment v-if="item.estimated_start_date" tag="span" :value="item.estimated_start_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                            <span v-else>-</span>
                                        </template>
                                        <template v-if="header.slug === 'estimated_end_date' && header.show">
                                            <g-moment v-if="item.estimated_end_date" tag="span" :value="item.estimated_end_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                            <span v-else>-</span>
                                        </template>
                                        <template v-if="header.slug === 'salesforce_id' && header.show">
                                            <span v-if="local_bridge_value(item.bridges)">
                                                {{ local_bridge_value(item.bridges) }}
                                            </span>
                                            <span v-else>-</span>
                                        </template>
                                        <template v-if="header.slug === 'salesforce_link' && header.show">
                                            <span v-if="local_check_bridge(item.bridges) && check_salesforce_instance_url">
                                                <a-tooltip bottom left>
                                                    <template v-slot:activator="{ on }">
                                                        <a :href="user_self.organization.salesforce_instance_url + '/' + local_bridge_value(item.bridges)" target="_blank" rel="noopener" v-on="on">
                                                            <img src="../../assets/images/icon-salesforce.svg" height="12" alt="Salesforce">
                                                        </a>
                                                    </template>
                                                    <span class="text-center">Imported from Salesforce.<br>Click to open Opportunity.</span>
                                                </a-tooltip>
                                            </span>
                                            <span v-else>-</span>
                                        </template>
                                        <template v-if="header.slug === 'hubspot_id' && header.show">
                                            <span v-if="local_check_hubspot_bridge(item.bridges)">
                                                {{ local_hubspot_bridge_value(item.bridges) }}
                                            </span>
                                            <span v-else>-</span>
                                        </template>
                                        <template v-if="header.slug === 'hubspot_link' && header.show">
                                            <span v-if="local_check_hubspot_bridge(item.bridges) && localHubspotInstanceUrl && localHubspotPortalId">
                                                <a-tooltip bottom left>
                                                    <template v-slot:activator="{ on }">
                                                        <a :href="localHubspotInstanceUrl+'/contacts/'+localHubspotPortalId+'/deal/'+local_hubspot_bridge_value(item.bridges)" target="_blank" rel="noopener" v-on="on">
                                                            <img :src="require('../../assets/images/apps/hubspot/logo.svg')" height="20" alt="Hubspot">
                                                        </a>
                                                    </template>
                                                    <span class="text-center">Imported from Hubspot.<br>Click to open Opportunity.</span>
                                                </a-tooltip>
                                            </span>
                                            <span v-else>-</span>
                                        </template>
                                        <template v-if="header.type === 'collaborators' && header.show">
                                            <template v-if="item.collaborators && item.collaborators.length">
                                                <s-users-deck v-if="local_get_collab_value(header.id, item.collaborators).length" class="justify-end" :list="local_get_collab_value(header.id, item.collaborators)" :count="4" hide-role hide-list></s-users-deck>
                                                <template v-else><span class="ml-2"> - </span></template>
                                            </template>
                                            <template v-else><span class="ml-2"> - </span></template>
                                        </template>
                                        <template v-if="header.type === 'custom_fields' && header.show">
                                            <div v-if="local_customfield_value(item.custom_field_values, header.id) && !['multiline'].includes(local_customfield_value_assign(item.custom_field_values, header.id))">
                                                <a
                                                    v-if="local_customfield_value_assign(item.custom_field_values, header.id) === 'url'"
                                                    :href="local_customfield_value(item.custom_field_values, header.id)"
                                                    target="_blank" rel="noopener noreferrer"
                                                    :title="local_customfield_value(item.custom_field_values, header.id)"
                                                    class="md-body-2 mb-0 blue--text text--darken-2 u-no-underline d-block text-no-wrap text-truncate"
                                                    style="max-width: 600px;"
                                                >
                                                    {{ local_customfield_value(item.custom_field_values, header.id) }}
                                                </a>
                                                <span class="mr-1" v-else>{{ local_customfield_value(item.custom_field_values, header.id) }}</span>
                                            </div>
                                            <div v-else-if="local_customfield_value(item.custom_field_values, header.id) && ['multiline'].includes(local_customfield_value_assign(item.custom_field_values, header.id))">
                                                <a-menu
                                                    :close-on-content-click="false"
                                                    open-on-hover content-class="u-elevation-custom-1 c-border"
                                                    min-width="300" max-width="600" max-height="400" nudge-left="20" offset-x top
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <span class="blue--text text--darken-1 md-caption u-rounded-corners" v-on="on">View Multiline Field</span>
                                                    </template>
                                                    <a-card class="px-4 pb-4 u-rounded-corners">
                                                        <div class="pt-4 white" style="position: sticky; top: 0">
                                                            <div class="u-flex align-center">
                                                                <h2 class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase">Multiline Field</h2>
                                                                <a-spacer></a-spacer>
                                                                <router-link tag="a" class="blue--text text--darken-2 text-uppercase font-weight-bold u-flex align-center md-caption" target="_blank"  :to="{name: 'projects-fields-tags', params: {id: item.id}}">
                                                                    view
                                                                    <a-icon size="15" color="blue" class="ml-1">launch</a-icon>
                                                                </router-link>
                                                            </div>
                                                            <a-divider class="grey lighten-2 mt-2 mb-3"></a-divider>
                                                        </div>
                                                        <div class="mr-1">{{ local_customfield_value(item.custom_field_values, header.id) }}</div>
                                                    </a-card>
                                                </a-menu>
                                            </div>
                                            <p class="grey--text text--darken-1" v-else> - </p>
                                        </template>
                                        <template v-if="header.slug === 'accumulated_confidence_score'">
                                            <div class="grey--text text--darken-3 font-weight-medium">
                                                <span>{{ item.confidence_score && item.confidence_score.percentage !== 0 ? `(${item.confidence_score.percentage}%)` : '–' }}</span>
                                                <template v-if="item.confidence_score && item.confidence_score.current_score">
                                                    <span class="ml-2" v-if="item && item.confidence_score">{{ item.confidence_score.current_score }}/{{ item.confidence_score.total }}</span>
                                                </template>
                                            </div>
                                        </template>
                                        <template v-if="header.type === 'confidence_score_section'">
                                            <div class="grey--text text--darken-3 font-weight-medium">
                                                <span>{{ localGetConfidenceScoreSectionRecord ({ project: item, slug: header.slug, type: 'percentage' }) || '–' }}</span>
                                                <span class="ml-2">{{ localGetConfidenceScoreSectionRecord ({ project: item, slug: header.slug, type: 'fractional' }) }}</span>
                                            </div>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </a-data-table>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- #Field Preference List Dialog -->
        <a-dialog max-width="800" scrollable persistent v-model="show_field_preference_list">
            <a-card>
                <a-card-title class="pa-0">
                    <div style="width: 100%;">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <div class="u-flex align-start">
                                    <a-flex shrink class="pr-1 py-1">
                                        <a-avatar class="primary darken-1" size="45">
                                            <a-icon dark>tune</a-icon>
                                        </a-avatar>
                                    </a-flex>
                                    <a-flex class="py-1">
                                        <h2 class="md-heading-6 font-weight-bold primary--text text--darken-1">Fields</h2>
                                        <p class="md-body-2 mb-0 mt-n2">Manage fields displayed in generated report.</p>
                                    </a-flex>
                                </div>
                                <a-spacer></a-spacer>
                                <a-btn icon small @click="show_field_preference_list = false"><a-icon size="18">cancel</a-icon></a-btn>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                    </div>
                </a-card-title>
                <a-card-text class="pa-0">
                    <draggable v-model="table_headers" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                        <div v-for="(item, index) in table_headers" :key="item.value" class="js-draggable-file-list-item white">
                            <a-divider v-if="index !== 0"></a-divider>
                            <a-container container--fluid grid-list-xl class="px-6 py-4 u-cursor-pointer js-drag-handle">
                                <a-layout wrap align-center>
                                    <a-flex shrink>
                                        <a-icon class="grey--text text--lighten-1">drag_indicator</a-icon>
                                    </a-flex>
                                    <a-flex class="pr-0">
                                        <h2 class="md-body-1 primary--text text--darken-1" :title="!item.text_full ? item.text : item.text_full">
                                            <template v-if="!item.text_full">{{ item.text !== ' ' ? item.text : 'Health' }}</template>
                                            <template v-else>{{ item.text_full | truncateText(50) }}</template>
                                        </h2>
                                        <div class="mt-1" v-if="localHeaderTypeIsConfidenceScore(item.type)">{{ localGetHeaderCategory(item) }}</div>
                                    </a-flex>
                                    <a-spacer></a-spacer>
                                    <a-flex shrink>
                                        <a-sheet @click="local_select_field(item, !item.show, index)">
                                            <a-checkbox color="blue" :input-value="item.show" class="ma-0 pa-0" hide-details></a-checkbox>
                                        </a-sheet>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </div>
                    </draggable>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!-- #Preset Save Dialog -->
        <a-dialog v-model="dialog_report_preset_save" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="40">
                                    <a-icon dark>assignment</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1">Save as Report</h2>
                                <p class="md-subtitle-2 mb-0">You can save the filters as report shortcuts.</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6" v-if="report_preset_item">
                        <a-layout wrap align-center>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                <a-text-field
                                    v-model="report_preset_item.name"
                                    placeholder="Enter Report Name"
                                    background-color="neutral"
                                    :autofocus="true"
                                    solo flat hide-details
                                >
                                </a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="report_preset_response.server && report_preset_response.server.errors && report_preset_response.server.errors.name">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ report_preset_response.server.errors.name[0] }}
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16">info</a-icon>
                                    The name will be helpful to identify the report filters.
                                </span>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink>
                                <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_report_preset_store()" :loading="loading" :disabled="loading">Save Report</a-btn>
                                <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_close_save_report()" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!-- #Download Link Dialog -->
        <a-dialog max-width="480px" v-model="dialog_download_link" persistent>
            <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                <a-progress-linear class="my-0" height="3" color="orange darken-3" :indeterminate="true" v-if="loading"></a-progress-linear>
                <a-card-text class="pa-2">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout align-start>
                            <a-flex shrink>
                                <a-icon size="72" color="grey lighten-1">cloud_download</a-icon>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 mb-2">
                                    <span v-if="loading">Preparing file...</span>
                                    <span v-else>Ready to Download</span>
                                </h2>
                                <p class="md-body-2 mb-0 grey--text text--darken-1">
                                    <span v-if="loading">Success is preparing your report for download. It may take few seconds to get ready.</span>
                                    <span v-else>Your report is ready to download. Click on the "Save File" button to download the report.</span>
                                </p>
                                <a-btn :href="project_links" small class="elevation-0 px-3 ma-0 mt-3" color="primary" :disabled="loading" @click="local_download_save()">
                                    <a-icon size="14" class="mr-2">save</a-icon>
                                    Save File
                                </a-btn>
                                <a-btn class="elevation-0 px-3 ma-0 mt-3 ml-2" color="grey" small text @click="dialog_download_link = !dialog_download_link" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import marked from 'marked'
import loadWorkspace from '@/mixins/mixin-workspace'
import { SUsersDeck, SDatePicker } from '@/config/config-shared-components'
import SharedSearchableInput from '@/components/Shared/SharedSearchableInput.vue'
import checkScopePermission from '@/mixins/mixin-checkscope-permission'

export default {
    mixins: [loadWorkspace, checkScopePermission],
    components: {
        SUsersDeck,
        SharedSearchableInput,
        SDatePicker
    },
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            // pagination: {
            //     sortDesc: true,
            //     page: 1,
            //     itemsPerPage: -1, // -1 for All",
            //     sortBy: 'title',
            // },
            sortBy: 'title',
            sortDesc: true,
            paginate_number_block: {
                current: 1,
                total: 0
            },
            headers: [
                { text: 'Health Color', align: 'left', sortable: true, value: 'health_color', slug: 'health_color', order: 0, default_order: 0, show: true, width: 130 },
                { text: 'Workspace', align: 'left', sortable: false, value: 'workspace.title', slug: 'workspace', order: 1, default_order: 1, show: true, width: 165 },
                { text: 'Project Title', align: 'left', sortable: true, value: 'title', slug: 'title', order: 2, default_order: 2, show: true, width: 165 },
                { text: 'Deal Amount', align: 'left', sortable: true, value: 'deal_amount', slug: 'deal_amount', order: 3, default_order: 3, show: true, width: 165 },
                { text: 'Account', align: 'left', sortable: true, value: 'customer.name', slug: 'customer', order: 4, default_order: 4, show: true, width: 165 },
                { text: 'Salesforce ID', align: 'left', sortable: false, value: 'salesforce_id', slug: 'salesforce_id', order: 5, default_order: 5, show: true, width: 190 },
                { text: 'Salesforce Link', align: 'left', sortable: false, value: 'salesforce_link', slug: 'salesforce_link', order: 6, default_order: 6, show: true, width: 180 },
                { text: 'HubSpot ID', align: 'left', sortable: false, value: 'hubspot_id', slug: 'hubspot_id', order: 7, default_order: 7, show: true, width: 190 },
                { text: 'HubSpot Link', align: 'left', sortable: false, value: 'hubspot_link', slug: 'hubspot_link', order: 8, default_order: 8, show: true, width: 180 },
                { text: 'Next Step', align: 'left', sortable: false, value: 'next_step', slug: 'next_step', order: 9, default_order: 9, show: true, width: 165 },
                { text: 'System Tags', align: 'left', sortable: false, value: 'system_tags', slug: 'system_tags', order: 10, default_order: 10, show: true, width: 165 },
                { text: 'Custom Tags', align: 'left', sortable: false, value: 'custom_tags', slug: 'custom_tags', order: 11, default_order: 11, show: true, width: 165 },
                { text: 'Territories', align: 'left', sortable: false, value: 'territories', slug: 'territories', order: 12, default_order: 12, show: true, width: 165 },
                { text: 'Status', align: 'left', sortable: true, value: 'status_value', slug: 'status', order: 13, default_order: 13, show: true, width: 165 },
                { text: 'Stage', align: 'left', sortable: true, value: 'stage.order', slug: 'stage', order: 14, default_order: 14, show: true, width: 165 },
                { text: 'Result', align: 'left', sortable: true, value: 'result.order', slug: 'result', order: 15, default_order: 15, show: true, width: 165 },
                { text: 'Partner', align: 'left', sortable: true, value: 'partner.name', slug: 'partner', order: 16, default_order: 16, show: true, width: 165 },
                { text: 'Start Date', align: 'left', sortable: true, value: 'start_date', slug: 'start_date', order: 17, default_order: 17, show: true, width: 165 },
                { text: 'End Date', align: 'left', sortable: true, value: 'due_date', slug: 'due_date', order: 18, default_order: 18, show: true, width: 165 },
                { text: 'Estimated Start Date', align: 'left', sortable: true, value: 'estimated_start_date', slug: 'estimated_start_date', order: 19, default_order: 19, show: true, width: 184 },
                { text: 'Estimated End Date', align: 'left', sortable: true, value: 'estimated_end_date', slug: 'estimated_end_date', order: 20, default_order: 20, show: true, width: 165 },
                { text: 'Last Status Update', align: 'left', sortable: false, value: 'last_status_update', slug: 'last_status_update', order: 21, default_order: 21, show: true, width: 165 },
                { text: 'Last Status Update Type', align: 'left', sortable: false, value: 'last_status_update_type', slug: 'last_status_update_type', order: 22, default_order: 22, show: true, width: 200 },
                { text: 'Last Status Update By', align: 'left', sortable: false, value: 'last_status_update_by', slug: 'last_status_update_by', order: 23, default_order: 23, show: true, width: 200 },
                { text: 'Last Status Update Date & Time', align: 'left', sortable: false, value: 'last_status_update_at', slug: 'last_status_update_at', order: 24, default_order: 24, show: true, width: 230 },
                // { text: 'Account Executives', align: 'left', sortable: false, value: 'account_executive.user.name', slug: 'account_executives', order: 16, default_order: 16, show: true, width: 165 },
                // { text: 'Sales Engineers', align: 'left', sortable: false, value: 'sales_engineer.user.name', slug: 'sales_engineers', order: 17, default_order: 17, show: true, width: 165 },
            ],
            timeline_date_field_list: [
                { text: 'Start Date', value: 'START_DATE' },
                { text: 'End Date', value: 'DUE_DATE' },
                { text: 'Estimated Start Date', value: 'EST_START' },
                { text: 'Estimated End Date', value: 'EST_END' }
            ],
            selectedUsersList: {},
            result_list: [],
            collabsUsersList: [],
            collabSearchTimeout: null,
            collaborator_type_list: [],
            competitor_type_list: [],
            show_field_preference_list: false,
            total_project_count: 0,
            dialog_user_form: false,
            dialog_download_link: false,
            datepicker_menu_2: false,
            expansion_panel_timeline: false,
            expansion_panel_accounts: false,
            expansion_panel_status: false,
            expansion_panel_tags: false,
            expansion_panel_territories: false,
            expansion_panel_custom_tags: false,
            expansion_panel_custom_competitors: false,
            expansion_panel_custom_collabs: false,
            expansion_panel_competitors: false,
            expansion_panel_partners: false,
            expansion_panel_team: false,
            custom_collaborators: {},
            button_filter_clear: false,
            label_filter_tags: [],
            product_filter_tags: [],
            platform_filter_tags: [],
            custom_tags: [],
            custom_competitors: {},
            local_custom_tag_list: [],
            show_date_field_error: false,
            dialog_report_preset_save: false,
            filter_name: null,
            show_edit_filter_input: false,
            generate_click: true,
            preference_edit: false,
            local_loadings: {
                value: false,
                type: null
            },
            report_projects: [],
            report_project_filters: {},
            search_report: false,
            local_search_report: '',
            local_filter_workspace_label: null,
            workspace_mode: false,
            report_mode: false,
            show_archived_pocs: false,
            local_workspace_id: null,
            custom_fields: {},
            temporary_workspace_id: '8938a2bc-34f3-4d1e-b23e-0a45ee107adc',
            local_proj_filter: {},
            date_value_1: null,
            date_value_2: null,
            date_condition: null,
            date_field: null,
            timeline: {
                start_date: null,
            },
            timeline_end: {
                start_date: null
            },
            fields: {
                project: 'id,title,health_color,deal_amount,next_step_json,status,start_date,due_date,estimated_start_date,estimated_end_date,created_at,updated_at,workspace_id,stage_id,result_id,customer_id,partner_id,archived_at,updated_by_id',
                customer: 'id,name,domain',
                partner: 'id,name,domain',
                stage: 'id,name,status',
                workspace: 'id,title,is_active',
                result: 'id,status,value',
                status_update: 'id,description,type_id,created_by_id,created_at',
                status_update_type: 'id,value',
                status_update_by: 'id,name,initial,color,avatar',
                confidence_score_section_record: 'id,project_id,section_id,total_score,current_score,percentage'
                // collaborators: 'id,project_id,user_id,scope,type_id',
                // collaborators_user: 'id,name,initial,color,email',
                // collaborators_type: 'id,type,value,status',
            },
            project_fields: {
                PROJECT: 'fields[projects]',
                CUSTOMER: 'fields[customer]',
                PARTNER: 'fields[partner]',
                STAGE: 'fields[stage]',
                WORKSPACE: 'fields[workspace]',
                RESULT: 'fields[result]',
                STATUS_UPDATE: 'fields[status_update]',
                STATUS_UPDATE_TYPE: 'fields[latest_status_update.type]',
                STATUS_UPDATE_BY: 'fields[latest_status_update.created_by]',
                // COLLABORATORS: 'fields[keyCollaborators]',
                // COLLABORATOR_USERS: 'fields[keyCollaborators.user]',
                // COLLABORATOR_TYPES: 'fields[keyCollaborators.type]',
            },
            filter: {
                START_DATE: 'filter[start_date]',
                DUE_DATE: 'filter[due_date]',
                EST_START: 'filter[estimated_start_date]',
                EST_END: 'filter[estimated_end_date]',
                CUSTOMER: 'filter[customer_id]',
                ACCNT_EXEC: 'filter[account_executive_id]',
                SALES_ENGG: 'filter[sales_engineer_id]',
                COLLABORATORS: 'filter[collaborators_group]',
                RESULT: 'filter[result_id]',
                WORKSPACE: 'filter[workspace_id]',
                STAGE: 'filter[stage_id]',
                STATUS: 'filter[status]',
                COMPETITORS: 'filter[competitor_group]',
                PARTNERS: 'filter[partner_id]',
                // REPORTS_STATUS: 'reports_status',
                // INVOLVMENT: 'filter[involvement]',
                TAGS: 'filter[tag_group]',
                TERRITORIES: 'filter[territory]',
                TIMELINE: 'filter[timeline]',
                CUSTOM_FIELD: 'filter[custom_field]',
                HEALTH_COLOR: 'filter[health_color]',
                PAGE: 'page',
                COUNT: 'count',
                SORT: 'sort',
                DOWNLOAD: 'download',
                FIELDS: 'fields[project]',
                // ATTRS: 'attributes',
                RELATIONSHIPS: 'include',
                // COUNTS: 'filter[counts]',
                HIERARCHY: 'filter[hierarchy_report]',
                CONFIDENCE_SCORE: 'confidence_score_records',
                CONFIDENCE_SCORE_SECTION_RECORD: 'fields[confidence_score_section_records]'
            },

            //Territory filter fieds
            territory_filter: {},
            territory_count: 999,
            FILTERS: {
                FIEDS: 'fields[territories]',
                PAGE: 'page',
                COUNT: 'count',
            },
            filter_list_manager: [
                {'label': 'All Projects', 'value': 'all'},
                {'label': 'Assigned to me', 'value': 'self'},
                {'label': 'Direct Reports', 'value': 'direct_reports'},
                {'label': 'All Reports', 'value': 'all_reports'}
            ],
            filter_list_team: [
                {'label': 'All Projects', 'value': 'all'},
                {'label': 'Assigned to me', 'value': 'self'}
            ],
            local_team_filter_list: [],

            // Custom_fields
            custom_field: null,
            show_custom_field_error: false,
            expansion_panel_customfields: false,
            custom_fields_condition_1: [
                {text: 'is', value: 'equals'},
                {text: 'is not', value: 'not-equals'},
                {text: 'contains', value: 'contains'},
                {text: 'does not contain', value: 'not-contains'},
                {text: 'starts with', value: 'starts-with'},
                {text: 'ends with', value: 'ends-with'},
                {text: 'is empty', value: 'empty'},
                {text: 'is not empty', value: 'not-empty'}
            ],
            custom_fields_condition_2: [
                {text: 'is', value: 'equals'},
                {text: 'is not', value: 'not-equals'},
                {text: 'is empty', value: 'empty'},
                {text: 'is not empty', value: 'not-empty'}
            ],
            custom_fields_condition_3: [
                {text: 'is', value: 'equals'},
                {text: 'is not', value: 'not-equals'},
                {text: 'is within', value: 'between'},
                {text: 'is after', value: 'greater-than'},
                {text: 'is on or after', value: 'greater-than-or-equal'},
                {text: 'is before', value: 'lesser-than'},
                {text: 'is on or before', value: 'lesser-than-or-equal'},
                {text: 'is empty', value: 'empty'},
                {text: 'is not empty', value: 'not-empty'}
            ],
            custom_fields_condition_4: [
                {text: 'is', value: 'equals'},
                {text: 'is not', value: 'not-equals'},
                {text: 'between', value: 'between'},
                {text: 'greater than', value: 'greater-than'},
                {text: 'greater than or equal', value: 'greater-than-or-equal'},
                {text: 'lesser than', value: 'lesser-than'},
                {text: 'lesser than or equal', value: 'lesser-than-or-equal'},
                {text: 'is empty', value: 'empty'},
                {text: 'is not empty', value: 'not-empty'}
            ],
            field_condition: null,
            filtered_field_type: '',
            filtered_dropdown_label: [],
            custom_field_value: null,
            custom_field_dropdown: null,
            filtered_field_label: '',
            customfield_date_value1: null,
            customfield_date_value2: null,
            customfield_number_value_at: null,
            customfield: {
                start_date: null
            },
            customfield_end: {
                start_date: null
            },
            custom_field_params: {},
            customfield_type_list: [],
            customfield_preset_data: null,
            projectFiltersCloned: {}
        }
    },
    watch: {
        sortBy: {
            handler (newData) {
                const filters = this.project_filters
                const dataSortOrder = !this.sortDesc ? '-' : ''

                if (this.loading) return
                filters[this.filter.SORT] = newData
                filters[this.filter.SORT] = (filters[this.filter.SORT] && filters[this.filter.SORT].charAt(0) && filters[this.filter.SORT].charAt(0) === '-') ? filters[this.filter.SORT].substring(1) : filters[this.filter.SORT]
                filters[this.filter.SORT] = `${dataSortOrder}${filters[this.filter.SORT]}`
                this.local_sort_project_list()
            },
            deep: true
        },
        sortDesc: {
            handler (data) {
                const filters = this.project_filters
                const dataSortOrder = !data ? '-' : ''

                if (this.loading) return
                filters[this.filter.SORT] = this.sortBy
                filters[this.filter.SORT] = filters[this.filter.SORT].charAt(0) === '-' ? filters[this.filter.SORT].substring(1) : filters[this.filter.SORT]
                filters[this.filter.SORT] = `${dataSortOrder}${filters[this.filter.SORT]}`
                this.local_sort_project_list()
            },
            deep: true
        },
        // pagination: {
        //     handler (data) {
        //         console.log(data)
        //         const { sortBy, sortDesc } = data
        //         const filters = this.project_filters
        //         const dataSortOrder = sortDesc ? 'asc' : 'desc'

        //         if (this.loading) return

        //         if (sortBy !== filters.sort_field || dataSortOrder !== filters.sort_direction) {
        //             filters.sort_field = data.sortBy
        //             filters.sort_direction = dataSortOrder
        //             this.local_sort_project_list()
        //         }
        //     },
        //     deep: true
        // },
        local_workspace_id(val) {
            this.local_load_workspace_stages(val)
        },
        workspace_current(oldValue, newValue) {
            this.local_load_workspace_stages()
            this.local_filtered_users_list
            this.local_current_workspace
            if (this.local_current_workspace.is_global !== 1) {
                this.local_load_workspace_members()
            }
            this.local_stage_account_sales_users_clear()
            this.local_report_preset_start_over()
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        local_readable_date_1: {
            get () {
                if (!this.date_value_1) return ''
                return moment(this.date_value_1).format('MMM DD, YYYY')
            },
            set (val) {
                if (!val) {
                    this.timeline = {}
                    this.timeline.start_date = null
                }
            }
        },
        local_readable_date_2: {
            get () {
                if (!this.date_value_2) return ''
                return moment(this.date_value_2).format('MMM DD, YYYY')
            },
            set (val) {
                if (!val) {
                    this.timeline_end = {}
                    this.timeline_end.start_date = null
                }
            }
        },
        custom_field_date1: {
            get () {
                if (!this.customfield_date_value1) return ''
                return moment(this.customfield_date_value1).format('MMM DD, YYYY')
            },
            set (val) {
                if (!val) {
                    this.customfield = {}
                    this.customfield.start_date = null
                }
            }
        },
        custom_field_date2: {
            get () {
                if (!this.customfield_date_value2) return ''
                return moment(this.customfield_date_value2).format('MMM DD, YYYY')
            },
            set (val) {
                if (!val) {
                    this.customfield_end = {}
                    this.customfield_end.start_date = null
                }
            }
        },
        table_headers: {
            get () {
                return _.sortBy(this.headers, ['order'])
            },
            set (list) {
                this.local_table_field_reorder(list)
            }
        },
        localHubspotPortalId () {
            return this.user_self && this.user_self.organization && this.user_self.organization.hubspot_portal_id
        },

        localHubspotInstanceUrl () {
            return this.user_self && this.user_self.organization && this.user_self.organization.hubspot_instance_url
        },

        customer_list_filtered() {
            return _.reduce(_.map(this.customer_list, (item) => {
                return item ? _.pick(item, ['name', 'id']) : false
            }), (result, value, key) => {
                if (value !== false) result.push(value)
                return result
            }, [])
        },
        check_salesforce_instance_url() {
            return this.user_self && this.user_self.organization && this.user_self.organization.salesforce_instance_url
        },
        status_list_filtered() {
            return [
                { status: 'Active' },
                { status: 'Queue' },
                { status: 'Archived' },
                { status: 'Completed' },
            ]
        },
        stage_list_filtered() {
            const stages = _.cloneDeep(this.stage_list)
            const queue_stage = _.pick(stages.splice(_.findIndex(stages, { status: 'queue' }), 1)[0], ['name', 'status', 'id', 'workspace'])
            const completed_stage = _.pick(stages.splice(_.findIndex(stages, { status: 'completed' }), 1)[0], ['name', 'status', 'id', 'workspace'])

            if (stages.length) {
                return _.reduce(_.map(stages, (item) => {
                    return item && item.name ? _.pick(item, ['name', 'status', 'id', 'workspace']) : false
                }), (result, value, key) => {
                    if (value !== false) {
                        if (status !== 'queue' && status !== 'completed') result.push(value)
                        if (result.length === stages.length) {
                            result.unshift(queue_stage)
                            result.push(completed_stage)
                        }
                    }
                    return result
                }, [])
            } else {
                return [queue_stage, completed_stage]
            }
        },
        color_list_filtered() {
            return [{ status: 'Green', value: 'green' }, { status: 'Red', value: 'red' }, { status: 'Yellow', value: 'yellow' }]
            // let results = _.reduce(_.map(this.comment_list, (item) => {
            //     return item.status ? _.pick(item, ['status']) : false
            // }), (result, value, key) => {
            //     if (value !== false) result.push(value)
            //     return result
            // }, [])

            // return results
        },
        result_list_filtered() {
            let results = _.reduce(_.map(this.result_list, (item) => {
                return item && item.value ? _.pick(item, ['value', 'id']) : false
            }), (result, value, key) => {
                if (value !== false) result.push(value)
                return result
            }, [])

            results.unshift({ value: "Without Result Data", id: "none" })
            return results
        },
        tag_list_filtered() {
            return _.reduce(_.map(this.tag_list, (item) => {
                return item.id ? _.pick(item, ['id', 'label', 'type', 'color']) : false
            }), (result, value, key) => {
                if (value !== false) result.push(value)
                return result
            }, [])
        },
        custom_tag_list_filtered() {
            return _.reduce(_.map(this.local_custom_tag_list, (item) => {
                return item.id ? _.pick(item, ['id', 'label', 'type', 'color', 'tag_section']) : false
            }), (result, value, key) => {
                if (value !== false) result.push(value)
                return result
            }, [])
        },
        territory_list_filtered() {
            return _.reduce(_.map(this.territory_list, (item) => {
                return item.id ? _.pick(item, ['id', 'name', 'color']) : false
            }), (result, value, key) => {
                if (value != false) result.push(value)
                return result
            }, [])
        },
        competitor_list_filtered() {
            return _.reduce(_.map(this.competitor_list, (item) => {
                return item.id ? _.pick(item, ['id', 'name']) : false
            }), (result, value, key) => {
                if (value != false) result.push(value)
                return result
            }, [])
        },
        partner_list_filtered() {
            return _.reduce(_.map(this.partner_list, (item) => {
                return item.id ? _.pick(item, ['id', 'name']) : false
            }), (result, value, key) => {
                if (value != false) result.push(value)
                return result
            }, [])
        },
        custom_field_list_filtered() {
            return _.reduce(_.map(this.custom_fields_list, (item) => {
                return item.id ? _.pick(item, ['id', 'label', 'options', 'type', 'is_active', 'resource_type']) : false
            }), (result, value, key) => {
                if (value !== false) result.push(value)
                return result.filter((el) => el.type !== 'mask' && el.resource_type === 'Project')
            }, [])
        },
        loading: {
            get() {
                return this.$store.state.Interface.loader
            },
            set(loading) {
                this.$store.state.Interface.loader = loading
            }
        },
        local_check_filter_applied() {
            const collabs = this.project_filters[this.filter.COLLABORATORS] ? Object.keys(this.project_filters[this.filter.COLLABORATORS]) : []
            const comps = this.project_filters[this.filter.COMPETITORS] ? Object.keys(this.project_filters[this.filter.COMPETITORS]) : []
            const hasFilterEvent = _.size(this.project_filters[this.filter.CUSTOMER]) !== 0 || _.size(this.project_filters[this.filter.ACCNT_EXEC]) !== 0 || _.size(this.project_filters[this.filter.SALES_ENGG]) !== 0 ||
                                _.size(this.project_filters[this.filter.TERRITORIES]) !== 0 || _.size(this.project_filters[this.filter.STATUS]) !== 0 || _.size(this.project_filters[this.filter.STAGE]) !== 0 ||
                                _.size(this.project_filters[this.filter.HEALTH_COLOR]) !== 0 || _.size(this.project_filters[this.filter.RESULT]) !== 0 || this.date_field ||
                                _.size(this.custom_tags) !== 0 || _.size(this.label_filter_tags) !== 0 || _.size(this.product_filter_tags) !== 0 || _.size(this.platform_filter_tags) !== 0 ||
                                // _.size(this.project_filters[this.filter.PARTNERS]) !== 0 || this.project_filters[this.filter.DATE_COND] || this.project_filters[this.filter.DATE_VALUE_1] || this.project_filters[this.filter.DATE_VALUE_2]
                                _.size(this.project_filters[this.filter.HIERARCHY]) !== 0 ||
                                _.size(this.project_filters[this.filter.PARTNERS]) !== 0 || _.size(collabs) !== 0 || _.size(comps) !== 0  || this.local_check_date_field() || this.date_condition || this.date_value_1 || this.date_value_2 || this.custom_field
                                || this.field_condition || this.custom_field_dropdown || this.custom_field_value || this.customfield_date_value1 || this.customfield_date_value2 ? true : false
            !hasFilterEvent ? this.local_project_list_clear() : null
            return hasFilterEvent
        },
        local_check_archived_status() {
            _.size(this.project_filters[this.filter.STATUS]) === 0 ?
                this.show_archived_pocs = true : _.includes(this.project_filters[this.filter.STATUS], 'Archived') ?
                this.show_archived_pocs = true : this.show_archived_pocs = false
        },
        local_clear_date_filter() {
            if (!this.project_filters[this.filter.DATE_FIELD] || !this.date_condition) {
                this.date_field = null
                this.date_condition = null
                this.date_value_1 = null
                this.date_value_2 = null
            }
        },
        local_selected_headers() {
            return _.sortBy(_.filter(this.table_headers, 'show'), ['order'])
        },
        local_reset_project_sort_filters() {
            this.project_filters[this.filter.SORT] = 'title'
            this.report_preset_item.filters[this.filter.SORT] = 'title'
            this.sortBy = 'title'
            this.sortDesc = true
        },
        local_preset_table_fields() {
            let table_fields = this.report_preset_item.fields && this.report_preset_item.fields.length ? this.report_preset_item.fields : null

            if (!table_fields || !table_fields.length) {
                this.local_set_preset_table_fields()
                return
            }

            const updatedFields = this.headers.filter(item => {
                const field = table_fields.find(i => i.slug === item.slug)
                if (field) {
                    item.show = field.show
                    item.order = field.order
                    return item
                }
                return item
            })
            return this.headers = _.sortBy(updatedFields, ['order'])
        },
        local_reset_table_headers() {
            const updatedFields = this.headers.filter(item => {
                item.show = true
                item.order = item.default_order
                return item
            })
            this.headers = _.sortBy(updatedFields, ['order'])
        },
        project_filters: {
            get () {
                return this.$store.state.Project.filters
            },
            set (data) {
                this.$store.state.Project.filters = data
            }
        },
        local_filtered_report_list() {
            return this.report_preset_list.filter(item => item.name.toLowerCase().includes(this.local_search_report.toLowerCase()))
        },
        local_selected_workspace: {
            get() {
                const workspace = this.workspace_list.find(i => i.id === this.local_workspace_id)
                return workspace && workspace.title ? workspace.title : null
            },
            set(id) {
                this.project_filters[this.filter.WORKSPACE] = id
            }
        },
        local_filtered_users_list() {
            if (this.local_current_workspace) {
                if (this.local_current_workspace.is_global === 1) return this.user_list
                return this.workspace_member_list.filter(user => user.workspace_id === this.local_current_workspace.id).map(item => item.user)
            }
        },
        local_filtered_competitors_list() {
            return this.competitor_list.filter(user => user.workspace_id === this.local_current_workspace.id).map(item => item.user)
        },
        local_current_workspace() {
            const current_workspace = this.current_workspace_id_mixin
            return this.workspace_list.find(item => item.id === current_workspace)
        },
        local_customfield_item_load() {
            if (['text','multiline','url'].includes(this.filtered_field_type)) {
                this.customfield_preset_data ? this.field_condition : this.field_condition = null
                return this.custom_fields_condition_1
            }
            if (['dropdown'].includes(this.filtered_field_type)) {
                this.customfield_preset_data ? this.field_condition : this.field_condition = null
                return this.custom_fields_condition_2
            }
            if (this.filtered_field_type === 'number') {
                this.customfield_preset_data ? this.field_condition : this.field_condition = null
                return this.custom_fields_condition_4
            }
            if (['date'].includes(this.filtered_field_type)) {
                this.customfield_preset_data ? this.field_condition : this.field_condition = null
                return this.custom_fields_condition_3
            }
        },
        local_customfield_text_multiline() {
            return (!this.filtered_field_type || ['text','multiline', 'number', 'url'].includes(this.filtered_field_type) || !this.filtered_field_type === 'dropdown') && !['empty', 'not-empty'].includes(this.field_condition)
        },
        local_customfield_date() {
            return (this.filtered_field_type === 'date') && !['empty', 'not-empty'].includes(this.field_condition)
        },
        ...mapState('User', {
            user_self: 'self',
            user_list: 'list',
        }),
        ...mapState('Project', {
            project_list: 'list',
            project_meta: 'meta',
            project_links: 'links',
        }),
        ...mapState('Customer', {
            customer_list: 'list',
        }),
        ...mapState('Partner', {
            partner_list: 'list',
        }),
        ...mapState('Competitor', {
            competitor_list: 'list',
        }),
        ...mapState('Comment', {
            comment_list: 'list',
        }),
        ...mapState('Stage', {
            stage_list: 'list',
            stage_filters: 'filters',
        }),
        ...mapState('Meta', {
            meta_filters: 'filters',
            meta_list: 'list',
        }),
        ...mapState('Tag', {
            tag_list: 'list',
            tag_filters: 'filters',
        }),
        ...mapState('Territory', {
            territory_list: 'list',
            territory_filters: 'filters',
        }),
        ...mapState('WorkspaceMember', {
            workspace_member_list: 'list',
            workspace_member_filters: 'filters',
        }),
        ...mapState('Workspace', {
            workspace_list: 'list',
            workspace_filters: 'filters'
        }),
        ...mapState('ReportPreset', {
            report_preset_list: 'list',
            report_preset_item: 'item',
            report_preset_response: 'response',
        }),
        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key',
        }),
        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),
        ...mapState('TagSection', {
            tag_section_list: 'list',
            tag_section_item: 'item',
            tag_section_form_mode: 'form_mode',
            tag_section_response: 'response',
        }),
        ...mapState('Applet', {
            applet_list: 'list',
        }),
        ...mapState('CustomField', {
            custom_fields_list: 'list',
        }),
        ...mapState('ConfidenceScore', {
            confidence_layout_list: 'list',
        }),
        ...mapState('Preference', {
            preference_list: 'list'
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('reports.index') == false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.loading = true
            this.local_filter_clear()
            this.projectFiltersCloned = _.cloneDeep(this.project_filters)
            await this.applet_index({ include: 'installation.accounts' });
            this.local_check_applet('salesforce')
            this.local_check_hubspot_applet('hubspot')
            this.report_preset_clear_item()
            await this.local_refresh_project_list()
            await this.workspace_clear()
            this.workspace_filters.is_active = 1
            await this.workspace_index()

            this.local_project_checkscope()

            await this.local_load_customers_partners()
            this.local_load_stages()
            this.competitor_index()
            this.load_tag_list()
            this.load_tag_territory()
            this.load_poc_result_meta()
            this.local_set_project_filter_objects()
            await this.local_load_competitor_types()
            this.local_add_competitors_header()
            await this.local_load_collaborator_types()
            this.local_add_collabs_header()
            if (this.user_self && this.user_self.id) await this.user_show({id: this.user_self.id, mode: 'self'})
            this.local_set_hierarchy_list()
            await this.custom_field_index()
            this.local_add_customfields_header()

            if (this.localHasConfidenceScorePermission()) {
                await this.confidence_layout_index()
                this.local_add_confidencescore_header()
            }

            await this.report_preset_index({ 'filter[type]': 'project' })
            this.$set(this.report_preset_item, 'filters', _.cloneDeep(this.project_filters))

            this.local_filtered_users_list
            this.button_filter_clear = false
            this.preference_edit = false
            this.loading = false
            this.generate_click = false
        },

        async local_load_workspace_members (params = {}) {
            await this.workspace_member_index({
                'include': 'user.role',
                'fields[user]': 'id,name,avatar,initial,role_id,scope,is_active',
                'fields[user.role]': 'id,name,scope',
                'filter[workspace_id]': this.current_workspace_id_mixin,
                count: 20,
                page: 1,
                ...params
            })
        },

        async local_load_customers_partners() {
            this.customer_index({ 'sort': 'name' })
            this.partner_index({ 'sort': 'name' })
        },
        async local_load_stages() {
            this.stage_index({
                'include': 'workspace',
                'fields[workspace]': 'id,title',
                'filter[workspace_id]': this.current_workspace_id_mixin,
                'sort': 'order'
            })
        },
        async load_tag_list() {
            await this.tag_section_index()
            let custom_type = this.pluck(this.tag_section_list, 'slug')
            let system_type = ['label','product','platform']
            await this.tag_index({ 'filter[type]': [...custom_type, ...system_type], count: 999 })
        },
        pluck(objs, name) {
            var data = [];
            for(var i in objs){
                if(objs[i].hasOwnProperty(name)){
                    data.push(objs[i][name]);
                }
            }
            return data;
        },
        async load_tag_territory() {
            await this.territory_clear()
            this.territory_filter[this.FILTERS.PAGE] = 1
            this.territory_filter[this.FILTERS.COUNT] = this.territory_count
            this.territory_filter[this.FILTERS.FIEDS] = 'id,name,color'
            await this.territory_index(this.territory_filter)
        },
        async load_poc_result_meta() {
            await this.meta_index({
                'filter[type]': 'project_result',
                'sort': 'order'
            })
            this.result_list = _.cloneDeep(this.meta_list)
        },
        async local_load_collaborator_types() {
            await this.meta_index({
                'filter[type]': 'collaborator_type',
                'fields[metas]': 'id,type,value,status,is_active',
                'sort': 'order'
            })
            this.collaborator_type_list = _.cloneDeep(this.meta_list)
            // await this.meta_clear()
        },
        async local_load_competitor_types() {
            await this.meta_index({
                'filter[type]': 'competitor_type',
                'fields[metas]': 'id,type,value,status,is_active',
                'sort': 'order'
            })
            this.competitor_type_list = _.cloneDeep(this.meta_list)
        },
        local_add_collabs_header () {
            let order = this.headers.length
            for (let i = 0; i < this.collaborator_type_list.length; i++) {
                const collab = this.collaborator_type_list[i];
                const collab_item = { text: collab.value, align: 'left', sortable: false, value: 'collaborators' + collab.id, slug: 'collaborators_' + collab.id, order: order, default_order: order, show: true, id: collab.id, type: 'collaborators', width: 165 }
                collab_item.text_full = collab_item.text
                collab_item.text = _.size(collab_item.text) > 20 ? collab_item.text.slice(0, 20) + '...' : collab_item.text
                this.headers.push(collab_item)
                order = order + 1
            }
            // { text: 'Sales Engineers', align: 'left', sortable: false, value: 'sales_engineer.user.name', slug: 'sales_engineers', order: 17, default_order: 17, show: true, width: 165 },
        },
        local_add_customfields_header () {
            let order = this.headers.length
            for (let i = 0; i < this.custom_field_list_filtered.length; i++) {
                let custom_field = this.custom_field_list_filtered[i]
                const custom_field_item = { text: custom_field.label, align: 'left', sortable: false, value: 'custom_fields' + custom_field.id, slug: 'customfields_' + custom_field.id, order: order, default_order: order, show: true, id: custom_field.id, type: 'custom_fields', width: 165 }
                custom_field_item.text_full = custom_field_item.text
                custom_field_item.text = _.size(custom_field_item.text) > 20 ? custom_field_item.text.slice(0, 20) + '...' : custom_field_item.text
                this.headers.push(custom_field_item)
                order = order + 1
            }
        },
        local_add_confidencescore_header () {
            let order = this.headers.length
            const confidenceScoreItem = {
                text: 'Accum. Confidence Score',
                text_full: 'Accum. Confidence Score',
                align: 'left',
                sortable: false,
                value: 'accumulated_confidence_score',
                slug: 'accumulated_confidence_score',
                order: order,
                default_order: order,
                type: 'accumulated_confidence_score',
                show: true,
                width: 190
            }
            this.headers.push(confidenceScoreItem)
            for (let i = 0; i < this.confidence_layout_list.length; i++) {
                const confidence_score_section = this.confidence_layout_list[i];
                const confidenceScoreItem = { text: confidence_score_section.name, align: 'left', sortable: false, value: 'confidence_score_section' + confidence_score_section.id, slug: 'confidence_score_section_' + confidence_score_section.id, order: order, default_order: order, show: true, id: confidence_score_section.id, type: 'confidence_score_section', width: 165 }
                confidenceScoreItem.text_full = confidenceScoreItem.text
                confidenceScoreItem.text = _.size(confidenceScoreItem.text) > 20 ? confidenceScoreItem.text.slice(0, 20) + '...' : confidenceScoreItem.text
                this.headers.push(confidenceScoreItem)
                order = order + 1
            }
        },
        local_add_competitors_header () {
            let order = this.headers.length
            for (let i = 0; i < this.competitor_type_list.length; i++) {
                const comp = this.competitor_type_list[i];
                const compItem = { text: comp.value, align: 'left', sortable: false, value: 'competitors' + comp.id, slug: 'competitors_' + comp.id, order: order, default_order: order, show: true, id: comp.id, type: 'competitors', width: 165 }
                compItem.text_full = compItem.text
                compItem.text = _.size(compItem.text) > 20 ? compItem.text.slice(0, 20) + '...' : compItem.text
                this.headers.push(compItem)
                order = order + 1
            }
        },
        local_set_project_filter_objects() {
            // this.project_filters[this.filter.RELATIONSHIPS] = ['tags', 'territories', 'customer', 'result', 'stage', 'partner', 'workspace', 'competitors', 'keyCollaborators.user', 'keyCollaborators.type'].join();
            this.project_filters[this.filter.RELATIONSHIPS] = ['customFieldValues', 'customFieldValues.customField', 'allTags', 'territories', 'customer', 'result', 'stage', 'partner', 'workspace', 'competitors', 'collaborators.user', 'collaborators.type', 'bridges', 'latestStatusUpdate.type', 'latestStatusUpdate.createdBy'].join();
            this.project_filters[this.filter.COUNT] = 999;
            this.project_filters[this.filter.DOWNLOAD] = false;
            if (!this.local_workspace_id) this.project_filters[this.filter.WORKSPACE] = this.workspace_current ? this.workspace_current : this.current_workspace_id_mixin
            this.project_filters[this.filter.PAGE] = 1
            this.local_select_response_fields()
        },
        local_select_response_fields() {
            this.project_filters[this.project_fields.PROJECT] = this.fields.project
            if (this.localHasConfidenceScorePermission()) this.local_add_confidence_score_project_filter()
            this.project_filters[this.project_fields.CUSTOMER] = this.fields.customer
            this.project_filters[this.project_fields.PARTNER] = this.fields.partner
            this.project_filters[this.project_fields.STAGE] = this.fields.stage
            this.project_filters[this.project_fields.WORKSPACE] = this.fields.workspace
            this.project_filters[this.project_fields.RESULT] = this.fields.result
            this.project_filters[this.project_fields.STATUS_UPDATE] = this.fields.status_update
            this.project_filters[this.project_fields.STATUS_UPDATE_TYPE] = this.fields.status_update_type
            this.project_filters[this.project_fields.STATUS_UPDATE_BY] = this.fields.status_update_by
            // this.project_filters[this.project_fields.COLLABORATORS] = this.fields.collaborators
            // this.project_filters[this.project_fields.COLLABORATOR_USERS] = this.fields.collaborators_user
            // this.project_filters[this.project_fields.COLLABORATOR_TYPES] = this.fields.collaborators_type
        },
        local_add_confidence_score_project_filter () {
            if (this.project_filters[this.project_fields.PROJECT].includes('confidence_score')) return

            this.project_filters[this.project_fields.PROJECT] = this.project_filters[this.project_fields.PROJECT]+',confidence_score'
            // this.project_filters[this.filter.CONFIDENCE_SCORE] = 1
            this.project_filters[this.filter.RELATIONSHIPS] = this.project_filters[this.filter.RELATIONSHIPS]+',confidenceScoreSectionRecords'
            this.project_filters[this.filter.CONFIDENCE_SCORE_SECTION_RECORD] = this.fields['confidence_score_section_record']
        },
        async generate_report() {
            this.generate_click = true
            this.local_refresh_project_list()
            this.local_project_list_clear()
            if (!this.local_check_filter_applied) return
            if (!this.local_check_date_condition()) return
            if (!this.local_check_customfield_condition()) return

            this.local_set_project_filter_objects()
            !this.report_preset_item.id || !this.report_preset_item.fields ? this.local_reset_project_sort_filters : null
            // this.local_workspace_id ? this.project_filters[this.filter.WORKSPACE] = this.local_workspace_id : this.project_filters[this.filter.WORKSPACE] = null
            // this.local_add_archived_pocs(this.show_archived_pocs)
            this.loading = true
            await this.project_index({ ...this.project_filters })
            this.loading = false
            this.local_populate_project_list()
            this.total_project_count = this.project_list.length
            this.generate_click = false

            if (this.project_list.length <= 0 && (_.size(this.all_list) === 0 || _.size(this.all_list) === 2)) {
                this.$notify('error', 'No projects found for the selected filter.')
            }
            if (this.project_list.length <= 0 && this.filtered_list.length >= 1 ) {
                this.$notify('error', 'There are no projects available to display. The view may affected due to role based access control. Please check your configuration or contact your admin.')
            }
            this.report_preset_item && this.report_preset_item.fields && !this.report_preset_item.fields.length ? this.local_set_preset_table_fields() : null
        },
        local_add_archived_pocs(value = null) {
            if (value) {
                this.project_filters[this.filter.STATUS].push('archived')
                this.report_project_filters[this.filter.STATUS].push('archived')
            }
        },
        async local_set_preset_table_fields() {
            const data = this.headers.map(item => ({ text: item.text, slug: item.slug, order: item.order, show: item.show }))
            this.report_preset_item.fields = data
        },
        async local_sort_project_list() {
            this.loading = true
            await this.project_index({ ...this.project_filters })
            this.loading = false

            this.total_project_count = this.project_list.length
            this.local_set_project_filter_objects()
            this.local_populate_project_list()
        },
        local_populate_project_list() {
            this.report_projects = this.project_list
        },
        local_load_workspace_stages(val = null) {
            this.project_filters[this.filter.STAGE] = null
            this.stage_filters.workspace_id = val ? val : this.workspace_current ? this.workspace_current : this.current_workspace_id_mixin
            this.local_selected_workspace = val ? val : this.workspace_current ? this.workspace_current : this.current_workspace_id_mixin
            this.local_load_stages()
        },
        async local_filter(type, field) {
            if (type && type === 'custom_competitors') this.local_add_custom_competitors()
            if (type && type === 'custom_collaborators') this.local_add_custom_collabs()
            if (type && type === 'timeline') this.local_add_timeline_values(field)
            if (type && type !== 'timeline' && type !== 'custom_collaborators') this.project_filters[type] = this.report_project_filters[type]
            if (type && type === 'custom_fields') this.local_add_customfield_values(field)
            this.local_add_tags_filter()

            !this.button_filter_clear ? this.button_filter_clear = true : null
        },
        local_add_customfield_values (field) {
            if (!this.custom_field) {
                this.field_condition = null
            }
            if (field) {
                this.customfield = {}
                this.customfield_end = {}
            }
            if (!this.field_condition || field) {
                this.custom_field_value = null
                this.custom_field_dropdown = null
                this.customfield.start_date = null
                this.customfield_end.start_date = null
                this.customfield_number_value_at = null
            }
            this.customfield_date_value1 = this.customfield.start_date
            this.customfield_date_value2 = this.customfield_end.start_date
            if (!this.custom_field && !this.field_condition && !this.custom_field_value && !this.custom_field_dropdown && !this.customfield_date_value1 && !this.customfield_date_value2) {
                this.local_reset_customfields()
            }
            else {
                this.local_customfield_params()
                this.project_filters[this.filter.CUSTOM_FIELD] = this.custom_field_params
            }
        },
        local_customfield_params() {
            let custom_field = this.custom_field
            let field_condition = this.field_condition
            this.custom_field_params = {
                custom_field_id: custom_field,
                operator: field_condition,
                value: this.custom_field_value || this.custom_field_dropdown || this.customfield_date_value1,
                value_alt: this.customfield_date_value2 || this.customfield_number_value_at
            }
        },
        local_reset_customfields() {
            delete this.project_filters[this.filter.CUSTOM_FIELD]
        },
        local_add_custom_competitors () {
            const list = Object.entries(this.custom_competitors)
            for (const [key, value] of list) {
                if (!value.length) delete this.custom_competitors[key]
            }
            this.project_filters[this.filter.COMPETITORS] = _.cloneDeep(this.custom_competitors)
            this.custom_competitors = _.cloneDeep(this.custom_competitors)
        },
        local_add_custom_collabs () {
            const list = Object.entries(this.custom_collaborators)
            for (const [key, value] of list) {
                if (!value.length) delete this.custom_collaborators[key]
            }
            this.project_filters[this.filter.COLLABORATORS] = this.custom_collaborators
            this.local_set_selected_collabs(this.project_filters[this.filter.COLLABORATORS])
        },
        local_get_collab_value (type_id, collabs) {
            return collabs.filter(item => item.type_id === type_id)
        },
        local_get_comp_value (type_id, competitors) {
            const list = competitors.filter(item => item.category_id === type_id)
            return { list, count: _.size(list) }
        },
        local_filter_tag_type (list, type) {
            return list.filter(item => type === 'custom' ? item.type.includes('custom') : !item.type.includes('custom'))
        },
        local_add_timeline_values(field) {
            if (field) {
                this.timeline = {}
                this.timeline_end = {}
            }
            if (!this.date_condition || field) {
                this.timeline.start_date = null
                this.timeline_end.start_date = null
            }
            this.date_value_1 = this.timeline.start_date
            this.date_value_2 = this.timeline_end.start_date
            const date_field = this.filter[this.date_field]
            const condition = this.date_condition
            if (!this.date_field && !this.date_condition && !this.date_value_1 && !this.date_value_2) {
                this.local_reset_timeline()
            } else {
                this.project_filters[date_field] = `${condition}${this.date_value_1 ? ',' + this.date_value_1 : ''}${this.date_value_2 ? ',' + this.date_value_2 : ''}`
            }
        },
        local_reset_timeline() {
            delete this.project_filters[this.filter.START_DATE]
            delete this.project_filters[this.filter.DUE_DATE]
            delete this.project_filters[this.filter.EST_START]
            delete this.project_filters[this.filter.EST_END]
        },
        local_add_tags_filter() {
            this.report_project_filters[this.filter.TAGS] = [...this.label_filter_tags, ...this.product_filter_tags, ...this.platform_filter_tags, ...this.custom_tags]
            this.project_filters[this.filter.TAGS] = this.report_project_filters[this.filter.TAGS]
        },
        local_stage_account_sales_users_clear() {
            this.project_filters[this.filter.STAGE] = []
            this.project_filters[this.filter.ACCNT_EXEC] = []
            this.project_filters[this.filter.SALES_ENGG] = []
            this.report_project_filters[this.filter.STAGE] = []
            this.report_project_filters[this.filter.ACCNT_EXEC] = []
            this.report_project_filters[this.filter.SALES_ENGG] = []
        },
        async local_filter_clear() {
            this.local_tag_clear()
            this.local_reset_collaborators()
            this.selectedUsersList = {}
            this.custom_collaborators = {}
            await this.project_clear()
            this.button_filter_clear = false
        },
        local_reset_collaborators () {
            this.custom_collaborators = {}
        },
        local_tag_filter_remove(type = null, id, custom = null) {
            custom ? this.custom_tags = this.custom_tags.filter(item => item !== id) : ''
            type === 'label' ? this.label_filter_tags = this.label_filter_tags.filter(item => item !== id) : ''
            type === 'product' ? this.product_filter_tags = this.product_filter_tags.filter(item => item !== id) : ''
            type === 'platform' ? this.platform_filter_tags = this.platform_filter_tags.filter(item => item !== id) : ''

            this.project_filters[this.filter.TAGS] = _.remove(this.project_filters[this.filter.TAGS], (data) => data !== id)
            this.local_filter()
        },
        local_status_filter_remove(type = null, item) {
            this.report_project_filters[type] = _.remove(this.report_project_filters[type], (data) => data !== item)
            this.project_filters[type] = this.report_project_filters[type]
        },
        local_territory_filter_remove(id) {
            this.project_filters[this.filter.TERRITORIES].splice(this.project_filters[this.filter.TERRITORIES].indexOf(id), 1)
            this.project_filters[this.filter.TERRITORIES] = [...this.project_filters[this.filter.TERRITORIES]]
            this.report_project_filters[this.filter.TERRITORIES] = this.project_filters[this.filter.TERRITORIES]
        },
        local_competitor_filter_remove(id) {
            this.project_filters[this.filter.COMPETITORS].splice(this.project_filters[this.filter.COMPETITORS].indexOf(id), 1)
            this.project_filters[this.filter.COMPETITORS] = [...this.project_filters[this.filter.COMPETITORS]]
            this.report_project_filters[this.filter.COMPETITORS] = this.project_filters[this.filter.COMPETITORS]
        },
        local_partner_filter_remove(id) {
            this.project_filters[this.filter.PARTNERS].splice(this.project_filters[this.filter.PARTNERS].indexOf(id), 1)
            this.project_filters[this.filter.PARTNERS] = [...this.project_filters[this.filter.PARTNERS]]

            this.local_filter()
        },
        local_status_tags_filter_clear(type, custom = null) {
            custom ? this.custom_tags = [] : ''
            type === 'label' ? this.label_filter_tags = [] : ''
            type === 'product' ? this.product_filter_tags = [] : ''
            type === 'platform' ? this.platform_filter_tags = [] : ''
            type === this.filter.STATUS ? this.project_filters[this.filter.STATUS] = [] : ''
            type === this.filter.STAGE ? this.project_filters[this.filter.STAGE] = [] : ''
            type === this.filter.HEALTH_COLOR ? this.project_filters[this.filter.HEALTH_COLOR] = [] : ''
            type === this.filter.RESULT ? this.project_filters[this.filter.RESULT] = [] : ''

            this.local_filter()
        },
        local_tag_filter(data, filter) {
            return _.filter(data, filter)
        },
        async local_tag_clear() {
            this.label_filter_tags = []
            this.product_filter_tags = []
            this.platform_filter_tags = []
            this.custom_tags = []
        },
        async local_download_csv() {
            await this.local_trigger_download('csv')
            await this.local_set_custom_headers()
            this.project_filters.download_format = 'csv'
            this.report_preset_item.filters.download_format = 'csv'
            this.project_download({ ...this.custom_fields, filters: {...this.project_filters } })
        },
        async local_download_excel() {
            await this.local_trigger_download('xlsx')
            await this.local_set_custom_headers()
            this.project_filters.download_format = 'xlsx'
            this.report_preset_item.filters.download_format = 'xlsx'
            this.project_download({ ...this.custom_fields, filters: {...this.project_filters } })
        },
        async local_trigger_download(format) {
            this.dialog_download_link = true
            // this.project_filters[this.filter.DOWNLOAD] = true;
            // this.project_filters[this.filter.DOWNLOAD_FORMAT] = format;
            // this.report_preset_item.filters[this.filter.DOWNLOAD] = true
            // this.report_preset_item.filters[this.filter.DOWNLOAD_FORMAT] = format;
        },
        async local_set_custom_headers() {
            const headers = [], slugs = []
            this.headers.filter(item => {
                if (item.show) {
                    headers.push(item.text_full === ' ' ? 'Health' : this.localHeaderTypeIsConfidenceScore(item.type) ? this.localGetHeaderCategoryAdded(item) : item.text)
                    slugs.push(item.slug)
                }
            })
            this.custom_fields = { headers, slugs }
        },
        local_download_save() {
            this.dialog_download_link = false
        },
        async local_filter_timeline(value) {
            this.$store.commit('Project/FILTER_TIMELINE', value)
            await this.project_index()
        },
        local_get_field_value(data, key, id, get_key = null) {
            let object = []
            _.forEach(id, item => object.push(_.find(data, [key, item])))
            return get_key === null ? object : _.filter(object, { type: get_key })
        },
        local_get_value (list, value) {
            let filtered = list.find(item => item.id === value)
            if (filtered && value) {
                this.filtered_field_label = filtered.label
                this.filtered_field_type = filtered.type
                this.filtered_dropdown_label =  filtered.options
            }
            return this.filtered_field_label
        },
        local_get_condition (cond) {
            let filteredList = []
            let combinedList = [...this.custom_fields_condition_1, ...this.custom_fields_condition_2, ...this.custom_fields_condition_3, ...this.custom_fields_condition_4]
            let list = combinedList.filter(item => filteredList.push(item.text))
            let filtered = list.find((item) => item.value === cond)
            return filtered.text
        },
        local_get_dropdown_label (list, value) {
            let filtered = list.find(item => item.value === value )
            return filtered.label
        },
        local_count_custom_collabs (collabs) {
            let count = 0
            for (const [_, value] of Object.entries(collabs)) {
                count = count + value.length
            }
            if (count === 0) {
                this.local_reset_collaborators()
                this.local_filter('custom_collaborators')
            }
            return count
        },
        local_count_custom_comps (comps) {
            let count = 0
            for (const [_, value] of Object.entries(comps)) {
                count = count + value.length
            }
            if (count === 0) {
                this.custom_competitors = {}
                this.local_filter('custom_competitors')
            }
            return count
        },
        local_open_create_preset() {
            this.report_preset_clear_item()
            this.dialog_report_preset_save = true
        },
        async local_report_preset_store() {
            this.local_set_project_filter_objects()
            await this.local_set_preset_table_fields()
            await this.report_preset_store({
                type: 'project',
                name: this.report_preset_item.name,
                filters: { ...this.project_filters, ...{ 'filter[collaborators_group]': this.custom_collaborators } },
                fields: this.report_preset_item.fields
            })

            if (this.report_preset_response.status === 'error') return
            setTimeout(async () => {
                await this.local_report_preset_select(this.report_preset_item.id, 'store')
                this.dialog_report_preset_save = false
            }, 100)
        },
        async local_report_preset_duplicate() {
            await this.report_preset_store({
                name: this.report_preset_item.name + ' Copy',
                filters: this.project_filters
            })
            await this.local_report_preset_select(this.report_preset_item.id)
        },
        async local_report_preset_update() {
            if (!this.local_check_filter_applied) {
                this.$notify('error', 'No filters provided to update the requested shortcut.')
                this.filter_name = this.report_preset_item.name
                return
            }
            if (!this.local_validate_filter_name()) {
                this.filter_name = this.report_preset_item.name
                return
            }
            if (!this.local_check_date_condition()) return
            if (!this.local_check_customfield_condition()) return
            this.report_preset_item.name = this.filter_name
            this.report_preset_item.filters = this.project_filters
            this.local_check_missing_account_fields()
            this.filter_name = null
            await this.local_set_preset_table_fields()
            await this.report_preset_update({id: this.report_preset_item.id})
            setTimeout(async () => {
                await this.local_report_preset_select(this.report_preset_item.id, 'store')
            }, 100);
        },
        local_check_missing_account_fields() {
            if (!this.report_preset_item.filters.hasOwnProperty(this.filter.CUSTOMER)) this.report_preset_item.filters[this.filter.CUSTOMER] = []
            if (!this.report_preset_item.filters.hasOwnProperty(this.filter.ACCNT_EXEC)) this.report_preset_item.filters[this.filter.ACCNT_EXEC] = []
            if (!this.report_preset_item.filters.hasOwnProperty(this.filter.SALES_ENGG)) this.report_preset_item.filters[this.filter.SALES_ENGG] = []
        },
        local_validate_filter_name() {
            if (!this.filter_name && (!this.report_preset_item || (this.report_preset_item && !this.report_preset_item.name))) {
                this.$notify('error', 'Report name is required.')
                return false
            }
            if (this.filter_name && this.filter_name.length > 255) {
                this.$notify('error', 'Report title is too long.')
                return false
            }
            return true
        },
        async local_report_preset_destroy(id) {
            await this.report_preset_destroy({id: id})
            await this.report_preset_clear_item()

            this.local_filter_clear()
            this.local_clear_date_vars()
            this.local_clear_customfield_vars()
            this.report_preset_item.filters = _.cloneDeep(this.project_filters)
            this.report_project_filters = _.cloneDeep(this.project_filters)
            this.local_workspace_id = null
            this.local_selected_workspace = null
            this.expanded_rows = []
            this.local_show_selected_filter_row()
        },
        async local_report_preset_select(id, type = null) {
            this.loading = true
            this.expanded_rows = []
            // this.local_clear_date_filter
            !type || type !== 'store' ? this.local_report_preset_start_over() : null
            this.report_mode = true

            setTimeout(async () => {
                await this.report_preset_select({id: id})
                await this.$nextTick()
                await this.local_report_preset_apply()
                this.local_set_competitors()
                this.filter_name = this.report_preset_item.name
                await this.local_report_tags_to_project_filter()
                this.local_show_selected_filter_row()
                this.local_preset_table_fields

                await this.local_set_selected_collabs()
                this.button_filter_clear = true
                this.loading = false
                this.generate_report()
            }, 0)
        },
        async local_set_selected_collabs (filter = this.report_preset_item.filters[this.filter.COLLABORATORS]) {
            this.custom_collaborators = {}
            let collabUserIds = Object.values(filter)
            if (collabUserIds && !collabUserIds.length) return
            await this.localUserIndex({ 'filter[user_ids]': collabUserIds.flat() })
            const userEntries = Object.entries(filter)
            userEntries.forEach(userEntry => {
                let collabValue = this.collaborator_type_list.find(i => i.id === userEntry[0])
                if (collabValue) collabValue = collabValue.value
                userEntry[1].forEach(userId => {
                    const user = this.user_list.find(i => i.id === userId)
                    if (user) {
                        const userObj = { ...user, collab_id: userEntry[0], collab_value: collabValue }
                        if (this.selectedUsersList[userEntry[0]] && Array.isArray(this.selectedUsersList[userEntry[0]])) {
                            const index = this.selectedUsersList[userEntry[0]].findIndex(i => i.id === userId)
                            if (index === -1) this.selectedUsersList[userEntry[0]].push(userObj)
                        } else this.$set(this.selectedUsersList, userEntry[0], [userObj])
                    } else this.$set(this.selectedUsersList, userEntry[0], [])
                })
            })
            const entriesList = Object.entries(this.selectedUsersList)
            for (const [key, value] of entriesList) {
                this.custom_collaborators[key] = value.map(i => i.id)
            }
        },
        local_set_competitors () {
            const comps = this.report_preset_item.filters[this.filter.COMPETITORS]
            this.custom_competitors = _.cloneDeep(_.size(Object.keys(comps)) > 0 ? comps : {})
        },
        async local_clean_presets() {
            await this.report_preset_clear_item()
            await this.project_clear()
            this.report_projects = []
            this.local_tag_clear()
        },
        async local_report_preset_apply() {
            if (this.report_preset_item && !this.report_preset_item.id || !this.report_preset_item.fields) {
                this.local_reset_project_sort_filters
                this.report_project_filters = _.cloneDeep(this.report_preset_item.filters)
                this.$store.commit('Project/UPDATE_FILTERS', _.cloneDeep(this.report_preset_item.filters))
                return
            }

            this.sortBy = this.report_preset_item.filters[this.filter.SORT] ? this.report_preset_item.filters[this.filter.SORT] : 'title'
            this.report_project_filters = _.cloneDeep(this.report_preset_item.filters)
            this.project_filters = _.cloneDeep(this.report_preset_item.filters)
            await this.local_set_timeline_vars()
            await this.local_set_customfield_vars()
        },
        local_set_customfield_vars() {
            this.customfield_preset_data = this.project_filters[this.filter.CUSTOM_FIELD]
            if (this.customfield_preset_data) {
                const obj = JSON.parse(this.customfield_preset_data);
                let id = obj.custom_field_id
                let cond = obj.operator
                let value_1 = obj.value
                let value_2 = obj.value_alt
                this.local_set_custom_fields(cond, value_1, value_2, id)
            }
        },
        local_set_custom_fields(cond, val_1, val_2, id) {
            this.custom_field = id
            let combinedList = [...this.custom_fields_condition_1, ...this.custom_fields_condition_2, ...this.custom_fields_condition_3, ...this.custom_fields_condition_4]
            let filteredList = []
            let list = combinedList.filter(item => filteredList.push(item.text))
            let filtered = list.find((item) => item.value === cond)
            if (filtered && filtered.value === cond) this.field_condition = filtered.value
            this.local_set_customfield_vals(val_1, val_2, id)
        },
        local_set_customfield_vals(val_1, val_2, id) {
            let filtered = this.custom_field_list_filtered.find((item) => item.id === id)
            if (['number','text','multiline'].includes(filtered.type)) this.custom_field_value = val_1
            if (filtered.type === 'dropdown') this.custom_field_dropdown = val_1
            if (filtered.type === 'date') {
                this.customfield_date_value1 = val_1
                this.customfield_date_value2 = val_2
            }
        },
        local_set_timeline_vars() {
            const start_date = this.project_filters[this.filter.START_DATE]
            const due_date = this.project_filters[this.filter.DUE_DATE]
            const est_start = this.project_filters[this.filter.EST_START]
            const est_end = this.project_filters[this.filter.EST_END]

            if (start_date) {
                const [cond, date_1, date_2] = start_date.split(',')
                this.local_set_date_fields('START_DATE', cond, date_1, date_2)
            }
            if (due_date) {
                const [cond, date_1, date_2] = due_date.split(',')
                this.local_set_date_fields('DUE_DATE', cond, date_1, date_2)
            }
            if (est_start) {
                const [cond, date_1, date_2] = est_start.split(',')
                this.local_set_date_fields('EST_START', cond, date_1, date_2)
            }
            if (est_end) {
                const [cond, date_1, date_2] = est_end.split(',')
                this.local_set_date_fields('EST_END', cond, date_1, date_2)
            }
        },
        local_set_date_fields(field, cond, date_1, date_2) {
            this.date_field = field
            this.date_condition = cond
            this.date_value_1 = date_1
            this.date_value_2 = date_2
        },
        async local_report_tags_to_project_filter() {
            const presetTags = this.report_preset_item.filters[this.filter.TAGS]
            if ((this.report_preset_item && this.report_preset_item.name) && _.size(presetTags)) {
                await this.local_tag_clear()
                this.tag_list_filtered.forEach((item) => {
                    let hasTag = _.includes(presetTags, item.id)
                    item.type === 'label' && hasTag ? this.label_filter_tags.push(item.id) : null
                    item.type === 'product' && hasTag ? this.product_filter_tags.push(item.id) : null
                    item.type === 'platform' && hasTag ? this.platform_filter_tags.push(item.id) : null
                    let custom_tag = _.includes(this.custom_tags, item.id)
                    item.type.includes('custom_tag') && !custom_tag && hasTag ? this.custom_tags.push(item.id) : null
                })
            }
        },
        local_show_selected_filter_row() {
            this.local_check_date_field() || this.date_condition || this.date_value_1 || this.date_value_2 ? this.expanded_rows.push('timeline') : null
            _.size(this.project_filters[this.filter.CUSTOMER]) || _.size(this.project_filters[this.filter.ACCNT_EXEC]) || _.size(this.project_filters[this.filter.SALES_ENGG]) ? this.expanded_rows.push('accounts') : null
            _.size(this.project_filters[this.filter.STATUS]) || _.size(this.project_filters[this.filter.STAGE]) || _.size(this.project_filters[this.filter.HEALTH_COLOR]) || _.size(this.project_filters[this.filter.RESULT]) ? this.expanded_rows.push('status') : null
            _.size(this.project_filters[this.filter.TAGS]) ? this.expanded_rows.push('tags') : null
            _.size(this.project_filters[this.filter.TERRITORIES]) ? this.expanded_rows.push('territories') : null
            //_.size(this.project_filters[this.filter.COMPETITORS]) ? this.expanded_rows.push('competitors') : null
            _.size(this.project_filters[this.filter.COMPETITORS]) ? this.expanded_rows.push('custom_competitors') : null
            _.size(this.project_filters[this.filter.PARTNERS]) ? this.expanded_rows.push('partners') : null
            _.size(this.project_filters[this.filter.COLLABORATORS]) ? this.expanded_rows.push('collaborators') : null
            _.size(this.custom_tags) ? this.expanded_rows.push('custom_tags') : null
            _.size(this.project_filters[this.filter.HIERARCHY]) ? this.expanded_rows.push('hierarchy') : null
            _.size(this.project_filters[this.filter.CUSTOM_FIELD]) ? this.expanded_rows.push('custom_fields') : null

            this.local_row_has_filters()
        },
        local_row_has_filters() {
            this.expansion_panel_timeline = _.includes(this.expanded_rows, 'timeline')
            this.expansion_panel_accounts = _.includes(this.expanded_rows, 'accounts')
            this.expansion_panel_status = _.includes(this.expanded_rows, 'status')
            this.expansion_panel_customfields = _.includes(this.expanded_rows, 'custom_fields')
            this.expansion_panel_tags = _.includes(this.expanded_rows, 'tags')
            this.expansion_panel_territories = _.includes(this.expanded_rows, 'territories')
            this.expansion_panel_custom_tags = _.includes(this.expanded_rows, 'custom_tags')
            this.expansion_panel_custom_competitors = _.includes(this.expanded_rows, 'custom_competitors')
            this.expansion_panel_partners = _.includes(this.expanded_rows, 'partners')
            this.expansion_panel_custom_collabs = _.includes(this.expanded_rows, 'collaborators')
            this.expansion_panel_team = _.includes(this.expanded_rows, 'hierarchy')
        },
        local_show_filter_edit() {
            if (this.report_preset_item && this.report_preset_item.name) {
                this.show_edit_filter_input = true
                this.filter_name = this.report_preset_item.name
            }
        },
        async local_report_preset_start_over() {
            this.local_loadings = { value: true, type: 'clear' }
            this.report_project_filters = {}
            this.local_reset_project_sort_filters
            this.local_reset_table_headers
            this.report_projects = []
            this.custom_tags = []
            this.custom_competitors = {}
            this.local_reset_collaborators()

            await this.report_preset_clear_item()
            this.local_filter_clear()
            this.local_clear_date_vars()
            this.local_clear_customfield_vars()
            this.local_set_project_filter_objects()

            this.report_preset_item.filters = _.cloneDeep(this.projectFiltersCloned)
            this.button_filter_clear = false
            this.expanded_rows = []
            this.local_show_selected_filter_row()
            this.local_loadings = { value: false, type: null }
        },
        local_clear_date_vars() {
            this.date_field = null
            this.date_condition = null
            this.date_value_1 = null
            this.date_value_2 = null
        },
        local_text_truncate(text, length, separator) {
            return _.truncate(text, {length: length, separator: separator});
        },
        local_equals(reportFilters, projectFilters) {
            const filteredPresetCollabs = this.local_filter_collab_ids(this.report_preset_item)
            const filteredProjectCollabs = this.local_filter_collab_ids({ filters: projectFilters }, 'project')
            reportFilters = { ...reportFilters, ...filteredPresetCollabs }
            projectFilters = { ...projectFilters, ...filteredProjectCollabs }
            delete reportFilters['filter[workspace_id]']
            delete projectFilters['filter[workspace_id]']

            return _.isEqual(reportFilters, projectFilters)
        },
        local_filter_collab_ids (item, type) {
            if (!item || (item && !item.filters)) return {}

            let data = _.cloneDeep(item.filters[this.filter.COLLABORATORS])
            if (!data) return {}

            data = Object.entries(data)
            const newData = {}
            for (let [key, value] of data) {
                newData[key] = value.map(item => (item && item.id) ?? item)
            }
            return { 'filter[collaborators_group]': newData }
        },
        local_check_date_condition() {
            if (this.date_field || this.date_condition || this.date_value_1 || this.date_value_2) {
                if (!this.date_condition) return this.local_show_timeline_error()
                else if (this.date_condition === 'between' && (!this.date_value_1 || !this.date_value_2)) return this.local_show_timeline_error()
                else if (this.date_condition && !this.date_value_1) return this.local_show_timeline_error()
                else return true
            }
            return true
        },
        local_show_timeline_error () {
            this.$notify('error', 'Please fill all the respective timeline date fields.')
            this.triggert_date_field_error()
            return false
        },
        triggert_date_field_error() {
            this.show_date_field_error = true
            setTimeout(() => {
                this.show_date_field_error = false
            }, 6000);
        },
        local_check_date_field() {
            return this.project_filters[this.filter.START_DATE] || this.project_filters[this.filter.DUE_DATE] ||
                this.project_filters[this.filter.EST_START] || this.project_filters[this.filter.EST_END]
        },
        local_check_timeline_filters(type = null) {
            let result = false
            if (type === 'date_condition') {
                result = !this.local_check_date_field() || !this.date_field || this.loading ? this.date_condition ? false : true : false
            }
            if (type === 'date_value_1') {
                result = !this.local_check_date_field() || !this.date_condition || this.loading ? this.date_value_1 ? false : true : false
            }
            if (type === 'date_value_2' && this.date_condition === 'between') {
                result = !this.local_check_date_field() || !this.date_condition || !this.date_value_1 || this.loading ? this.date_value_2 ? false : true : false
            }
            if (this.date_condition !== 'between') {
                this.date_value_2 = null
            }
            return result
        },
        local_clear_customfield_vars () {
            this.custom_field = null
            this.field_condition = null
            this.custom_field_value = null
            this.custom_field_dropdown = null
            this.customfield_date_value1 = null
            this.customfield_date_value2 = null
            this.filtered_dropdown_label = null
        },
        local_check_customfield_condition() {
            if (!this.custom_field && !this.field_condition && !this.custom_field_value && !this.custom_field_dropdown && !this.custom_field_date1 && !this.custom_field_date2)  return true
            if (this.custom_field && ['empty','not-empty'].includes(this.field_condition)) return true
            if (!this.field_condition) return this.local_show_customfield_error()
            switch(this.filtered_field_type) {
                case 'url':
                case 'number':
                case 'text':
                case 'multiline':
                    if (!this.custom_field_value) return this.local_show_customfield_error()
                    break
                case 'date':
                    if (this.field_condition === 'between' && (!this.custom_field_date1 || !this.custom_field_date2)) return this.local_show_customfield_error()
                    if (this.field_condition !== 'between' && !this.custom_field_date1) return this.local_show_customfield_error()
                    break
                case 'dropdown':
                    if (!this.custom_field_dropdown) return this.local_show_customfield_error()
                    break
            }
            return true
        },
        local_trigger_custom_field_error() {
            this.show_custom_field_error = true
            setTimeout(() => {
                this.show_custom_field_error = false
            }, 6000);
        },
        local_show_customfield_error () {
            this.$notify('error', 'Please fill all the respective custom fields.')
            this.local_trigger_custom_field_error()
            return false
        },
        local_check_customfield_filters(type = null) {
            let result = false
            if (type === 'field_condition') {
                result = !this.custom_field || this.loading ? this.field_condition ? false : true : false
            }
            if (type === 'custom_field_value') {
                result = !this.custom_field || !this.field_condition || this.loading ? this.custom_field_value ? false : true : false
            }
            if (type === 'custom_field_dropdown') {
                result = !this.custom_field || !this.field_condition || this.loading ? this.custom_field_dropdown ? false : true : false
            }
            if (type === 'customfield_date_value1') {
                result = !this.custom_field || !this.field_condition || this.loading ? this.customfield_date_value1 ? false : true : false
            }
            if (type === 'customfield_date_value2') {
                result = !this.custom_field || !this.field_condition || !this.customfield_date_value1 || this.loading ? this.customfield_date_value2 ? false : true : false
            }
            if (this.field_condition !== 'between') {
                this.customfield_date_value2 = null
            }
            return result
        },
        async local_project_list_clear() {
            this.report_projects = []
            await this.$store.commit('Project/UPDATE_LIST', [])
        },
        async local_refresh_project_list() {
            await this.$store.commit('Project/REFRESH_LIST', [])
        },
        async local_close_save_report() {
            await this.report_preset_clear_response()
            if (this.report_preset_item && this.report_preset_item.name) this.report_preset_item.name = null
            this.dialog_report_preset_save = false
        },
        local_select_field(item, visible, index) {
            let report_item = this.report_preset_item.fields.find(i => i.slug === item.slug)
            report_item ? report_item.show = visible : null
            item.show = visible
            this.report_preset_item && this.report_preset_item.id ? this.local_field_preference_update() : null
        },
        async local_table_field_reorder(list) {
            this.headers = list.filter((item, index) => {
                item.order = index
                return item
            })
            this.report_preset_item.id ? this.local_field_preference_update() : null
        },
        async local_field_preference_update() {
            await this.local_set_preset_table_fields()
            this.report_preset_update({id: this.report_preset_item.id})
        },

        local_bridge_value(bridges) {
            if (bridges) {
                const keys = ['opportunity.salesforce_id']
                const salesforce = bridges.find(item => item.service === 'salesforce' && keys.includes(item.key))

                if (salesforce && salesforce.value) return salesforce.value
                return null
            }
        },

        local_customfield_value(customFieldValues, customFieldId, field = null) {
            const item = customFieldValues.find(i => i.custom_field_id === customFieldId)
            if (!item) return null
            if (!field) return item.value

            const [firstSlug, secondSlug] = field.split('.')
            if (secondSlug) return item[firstSlug][secondSlug]

            return item[firstSlug]
        },

        local_customfield_value_assign(val, id) {
            let fieldValue = []
            val.forEach(item => {(item.custom_field_id === id) ? fieldValue.push(item.custom_field.type) : null})
            return fieldValue.length ? fieldValue[0] : null
        },

        local_hubspot_bridge_value(bridges) {
            if (bridges) {
                const keys = ['hubspot.project.create']
                const hubspot = bridges.find(item => item.service === 'hubspot' && keys.includes(item.key))

                if (hubspot && hubspot.value) return hubspot.value
                return null
            }
        },

        local_check_bridge(bridges) {
            const keys = ['opportunity.salesforce_id']
            return bridges && bridges.find(item => {
                return item.service === 'salesforce' && keys.includes(item.key)
            })
        },

        local_check_hubspot_bridge(bridges) {
            const keys = ['hubspot.project.create']
            return bridges && bridges.find(item => {
                return item.service === 'hubspot' && keys.includes(item.key)
            })
        },

        local_check_applet(slug) {
            const applet_saleforce_item = this.applet_list.find(item => item.slug === slug)
            if(!applet_saleforce_item.installation || applet_saleforce_item.installation && applet_saleforce_item.installation.accounts && !applet_saleforce_item.installation.accounts.length) {
                const saleforce_url_header_index =  this.headers.findIndex(item => item.slug === 'salesforce_link')
                const saleforce_id_header_index =  this.headers.findIndex(item => item.slug === 'salesforce_id')
                this.headers.splice(saleforce_url_header_index, 1)
                this.headers.splice(saleforce_id_header_index, 1)
            }
        },

        local_check_hubspot_applet(slug) {
            const applet_hubspot_item = this.applet_list.find(item => item.slug === slug)
            if(!applet_hubspot_item || !applet_hubspot_item.installation || applet_hubspot_item.installation && applet_hubspot_item.installation.accounts && !applet_hubspot_item.installation.accounts.length) {
                const hubspot_url_header_index =  this.headers.findIndex(item => item.slug === 'hubspot_link')
                const hubspot_id_header_index =  this.headers.findIndex(item => item.slug === 'hubspot_id')
                this.headers.splice(hubspot_url_header_index, 1)
                this.headers.splice(hubspot_id_header_index, 1)
            }
        },

        local_get_html(content) {
            return marked(content, { sanitize: true })
        },

        /* Collaborators */
        async localSearchCollabs (props) {
            const { value, clearLoading } = props || {}
            clearTimeout(this.collabSearchTimeout)

            this.collabSearchTimeout = setTimeout(async () => {
                if (this.local_current_workspace && this.local_current_workspace.is_global === 0) {
                    await this.local_load_workspace_members({ 'filter[member_search]': value, count: _.trim(value) ? 100 : 20 })
                    this.collabsUsersList = _.cloneDeep(this.workspace_member_list.filter(user => user.workspace_id === this.local_current_workspace.id).map(item => item.user))
                    clearLoading()
                    return clearTimeout(this.collabSearchTimeout)
                }

                await this.localUserIndex({ 'filter[user_search]': value, count: _.trim(value) ? 100 : 20, sort: '-created_at' })
                this.collabsUsersList = _.cloneDeep(this.user_list)
                clearLoading()
                clearTimeout(this.collabSearchTimeout)
            }, _.trim(value) ? 500 : 200)
        },

        localAddCollab (item, collabTypeId) {
            if (this.custom_collaborators && !Array.isArray(this.custom_collaborators[collabTypeId])) {
                this.$set(this.custom_collaborators, collabTypeId, [item.id])
                this.local_filter('custom_collaborators')
                return true
            }

            const index = this.custom_collaborators[collabTypeId].indexOf(item.id)
            if (index === -1) this.custom_collaborators[collabTypeId].push(item.id)
            this.local_filter('custom_collaborators')
        },

        localRemoveCollabs (evt, collabTypeId) {
            this.$set(this.custom_collaborators, collabTypeId, [...evt.map(i => i.id)])
            const selectedEntries = Object.entries(this.selectedUsersList)
            for (const [key, value] of selectedEntries) {
                if (this.selectedUsersList[key] && this.selectedUsersList[key].length) {
                    this.selectedUsersList[key] = this.selectedUsersList[key].filter(item => evt.find(i => i.id === item.id))
                }
            }
            this.local_filter('custom_collaborators')
        },

        /* Extras */
        async localUserIndex (params = {}) {
            await this.user_index({
                include: 'role',
                count: 20,
                page: 1,
                'fields[role]': 'id,name,scope',
                'fields[users]': 'id,name,initial,scope,avatar,color,role_id,is_active',
                ...params
            })
        },

        local_set_hierarchy_list () {
            if (this.user_self && this.user_self.is_manager) return this.local_team_filter_list = this.filter_list_manager
            return this.local_team_filter_list = this.filter_list_team
        },

        local_get_filter_label (value) {
            if (!value) return
            const item = _.filter(this.local_team_filter_list, {value: value})
            return item.length ? item[0].label : this.local_reset_hierarchy_preset()
        },

        local_reset_hierarchy_preset () {
            this.report_project_filters[this.filter.HIERARCHY] = 'all'
            this.local_filter(this.filter.HIERARCHY)
        },

        localCheckMinDateRange(start_date) {
            if(!start_date) return null
            return moment(start_date).add(0, 'days').format("YYYY-MM-DD")

        },

        localCheckMaxDateRange(due_date) {
            if(!due_date) return null
            return moment(due_date).add(0, 'days').format("YYYY-MM-DD")
        },

        localSortTable (head) {
            if (!head.sortable) return

            this.sortDesc = !this.sortDesc
            this.sortBy = head.value
        },

        localShowBlockStatus (head) {
            if (!head.hasOwnProperty('type')) return false

            if (head.type === 'collaborators') {
                const collabId = head.slug.split('_')[1]
                const item = this.collaborator_type_list.find(i => i.id === collabId)
                return !(item ? item.is_active : true)
            }

            if (head.type === 'competitors') {
                const compId = head.slug.split('_')[1]
                const item = this.competitor_type_list.find(i => i.id === compId)
                return !(item ? item.is_active : true)
            }

            if (head.type === 'custom_fields') {
                const customFieldId = head.slug.split('_')[1]
                const item = this.custom_field_list_filtered.find(i => i.id === customFieldId)
                return !(item ? item.is_active : true)
            }

            return false
        },

        localHasConfidenceScorePermission () {
            if (!this.localIsConfidenceScoreFlagged()) return false
            return this.localHasConfidenceScorePreference() && this.$can('poc_confidence_score.index')
        },

        localIsConfidenceScoreFlagged () {
            const { flag_confidence_score } = this.user_self && this.user_self.organization
            return flag_confidence_score === 1
        },

        localHasConfidenceScorePreference (key = 'value') {
            const projectPreset = 'organization.confidence_score'
            const result = this.preference_list.find(i => i.key === `${projectPreset}`)

            if (!result) return false

            if (key === 'value') return result[key] === 'Yes'
            return result
        },

        localGetConfidenceScoreSectionRecord (params) {
            const { project, slug, type } = params
            if (project.confidence_score_section_records && !project.confidence_score_section_records.length) return
            const slug_id = slug.split('confidence_score_section_').pop()
            const section_record = project.confidence_score_section_records.find(item => item.section_id === slug_id)
            if (section_record && section_record.current_score) {
                return type === 'percentage' ? `(${Math.round(section_record.percentage)}%)` : `${section_record.current_score}/${section_record.total_score}`
            }
        },

        localHeaderTypeIsConfidenceScore (type) {
            return type === 'accumulated_confidence_score' || type === 'confidence_score_section'
        },

        localGetHeaderCategoryAdded (item) {
            switch (item.type) {
                case 'accumulated_confidence_score': return item.text_full + ' ' + '(Confidence Score)'
                case 'confidence_score_section': return item.text_full + ' ' + '(Confidence Score - Section)'
                default: return ' '
            }
        },

        localGetHeaderCategory (item) {
            switch (item.type) {
                case 'accumulated_confidence_score': return 'Confidence Score'
                case 'confidence_score_section': return 'Confidence Score - Section'
                default: return ' '
            }
        },

        ...mapActions('Project', {
            project_index: 'index',
            project_download: 'download',
            project_clear: 'clear',
        }),
        ...mapActions('User', {
            user_index: 'index',
            user_show: 'show',
            user_clear: 'clear',
        }),
        ...mapActions('Customer', {
            customer_index: 'index',
            customer_clear: 'clear',
        }),
        ...mapActions('Partner', {
            partner_index: 'index',
            partner_clear: 'clear',
        }),
        ...mapActions('Collaborator', {
            collaborator_index: 'index',
            collaborator_clear: 'clear',
        }),
        ...mapActions('Competitor', {
            competitor_index: 'index',
            competitor_clear: 'clear',
        }),
        ...mapActions('Comment', {
            comment_status_list: 'status_list',
        }),
        ...mapActions('Stage', {
            stage_index: 'index',
            stage_clear: 'clear',
        }),
        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),
        ...mapActions('Tag', {
            tag_index: 'index',
            tag_clear: 'clear',
        }),
        ...mapActions('Territory', {
            territory_index: 'index',
            territory_clear: 'clear',
        }),
        ...mapActions('Workspace', {
            workspace_index: 'index',
            workspace_clear: 'clear',
        }),
        ...mapActions('WorkspaceMember', {
            workspace_member_index: 'index',
        }),
        ...mapActions('ReportPreset', {
            report_preset_index: 'index',
            report_preset_store: 'store',
            report_preset_update: 'update',
            report_preset_destroy: 'destroy',
            report_preset_select: 'select',
            report_preset_clear: 'clear',
            report_preset_clear_response: 'clear_response',
            report_preset_clear_item: 'clear_item',
        }),
        ...mapActions('TagSection', {
            tag_section_index: 'index',
            tag_section_store: 'store',
            tag_section_update: 'update',
            tag_section_select: 'select',
            tag_section_toggle_active: 'toggle_active',
            tag_section_destroy: 'destroy',
            tag_section_reorder: 'reorder',
            tag_section_clear: 'clear',
            tag_section_clear_item: 'clear_item',
        }),

        ...mapActions('Applet', {
            applet_index: 'index',
            applet_clear: 'clear',
        }),
        ...mapActions('CustomField', {
            custom_field_index: 'index',
        }),
        ...mapActions('ConfidenceScore', {
            confidence_layout_index: 'index',
        }),
    },
}
</script>

<style style="scss" scoped>
    .column-users {
        max-width: 110px;
        text-align: right;
    }
    .u-alert-no-border {
        border-top: 0 !important;
    }
    .c-border {
        border: 1px solid #eaeaea !important;
    }
</style>

<template>
	<a-main>
        <a-container grid-list-xl container--fluid class="pa-0 mx-0 pb-16 mb-16" tabindex="0">
            <a-layout wrap align-center class="mb-3 mt-2">
                <a-flex shrink>
                    <a-icon size="48" color="neutral darken-2">work</a-icon>
                </a-flex>
                <a-flex>
                    <h2 class="md-heading-5 primary--text">Projects</h2>
                    <p class="md-subtitle-1 grey--text text--darken-2 mb-0">Review all Projects.</p>
                </a-flex>
            </a-layout>
             <a-layout align-center class="my-0 mb-2">
                <a-flex class="py-0">
                    <a-card flat class="c-search-inventory px-3 d-flex align-center u-border u-rounded-corners mb-1">
                        <a-icon size="24" color="grey" style="max-width: 20px;">search</a-icon>
                        <a-text-field solo flat hide-details dense placeholder="Search by project title - Press [enter] to search" class="" v-model="search_term" @keyup.enter="local_fetch(null, 'replace')"></a-text-field>
                    </a-card>
                </a-flex>
                <a-flex shrink class="pl-1">
                    <a-progress-circular color="orange darken-2" size="24" width="2" :indeterminate="loading"></a-progress-circular>
                </a-flex>
            </a-layout>
            <a-layout wrap align-center>
                <a-flex shrink>
                    <a-menu bottom offset-y  transition="slide-y-transition" class="d-block" max-width="300">
                        <template v-slot:activator="{ on }">
                            <a-card flat class="u-rounded-corners u-overflow-hidden u-border d-flex" max-height="35" v-on="on" @click="local_search_workspace = ''">
                                <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                    <div>
                                        <a-icon size="20" class="ma-0 u-icon-nudge">filter_list</a-icon>
                                        <span class="grey--text text--darken-2 mx-1">
                                            Project Status:
                                            <span class="font-weight-medium" style="max-width: 300px">
                                                {{ local_filter_status }}
                                            </span>
                                        </span>
                                    </div>
                                    <div class="text-xs-right">
                                        <a-icon size="20" class="ma-0 u-icon-nudge">arrow_drop_down</a-icon>
                                    </div>
                                </a-card-text>
                            </a-card>
                        </template>
                        <a-divider></a-divider>
                        <a-card flat class="c-search-inputs-inventory py-1 u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                            <a-list style="max-height: 270px;" class="overflow-y-auto u-list-condensed py-0">
                                <template v-for="(item, index) in local_project_status">
                                    <a-list-item :key="'filter' + index" @click="local_select_project_status(item)" v-if="local_filter_status !== item">
                                        <a-list-item-title class="md-body-2 font-weight-normal" :title="item.type">
                                            {{ item.type }}
                                        </a-list-item-title>
                                    </a-list-item>
                                    <a-list-item :key="'filter' + index" v-else class="grey lighten-4">
                                        <a-list-item-title class="md-body-2 font-weight-normal" :title="item.type">
                                            {{ item.type }}
                                        </a-list-item-title>
                                    </a-list-item>
                                </template>
                            </a-list>
                        </a-card>
                    </a-menu>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink v-if="project_meta">
                    <p class="md-body-2 mb-0 grey--text text--darken-2" v-if="project_meta.total == 0">No matching projects</p>
                    <p class="md-body-2 mb-0 grey--text text--darken-2" v-if="project_meta.total == 1">Showing 1 matching project</p>
                    <p class="md-body-2 mb-0 grey--text text--darken-2" v-if="project_meta.total > 1 && project_meta.total == project_list_local.length">Showing {{ project_list_local.length }} matching projects</p>
                    <p class="md-body-2 mb-0 grey--text text--darken-2" v-if="project_meta.total > 1 && project_meta.total > project_list_local.length">Showing {{ project_list_local.length }} matching projects out of {{ project_meta.total }} projects</p>
                </a-flex>
            </a-layout>
            <a-layout wrap align-center class="mt-2 ml-0 u-wfull u-overflow-x" v-if="!local_loading && project_list && project_list.length">
                <!-- containerMaxWidth => added width of all the columns -->
                <div :style="[{ width: containerMaxWidth + 'px' }]">
                    <a-container container--fluid grid-list-xl class="py-3 bg-gray u-border-t u-rounded-corners-lg u-border-b pa-0">
                        <div class="u-flex justify-space-between px-3 pl-0">
                            <div @click="local_sort('title')" class="u-cursor-pointer c-poc-table-title pl-10">
                                <h2 class="md-subtitle-2 font-weight-bold grey--text text--darken-1">
                                    <a-icon size="16" v-if="local_project_filters[project_filter.SORT] === 'title' && sort_direction === ''">arrow_downward</a-icon>
                                    <a-icon size="16" v-if="local_project_filters[project_filter.SORT] === '-title' && sort_direction === '-'">arrow_upward</a-icon>
                                    <a-icon size="16" v-if="local_project_filters[project_filter.SORT] !== 'title' && local_project_filters[project_filter.SORT] !== '-title'" class="neutral--text text--darken-1">arrow_downward</a-icon>
                                    Project & Account
                                </h2>
                            </div>
                            <div v-if="$can('usecases.index')" class="u-cursor-pointer c-project-detail-column">
                                <h2 class="md-subtitle-2 font-weight-bold grey--text text--darken-1">Scope</h2>
                            </div>
                            <div v-if="$can('tasks.index')" @click="local_sort('progress_tasks')" class="u-cursor-pointer pl-0 c-project-detail-column">
                                <h2 class="md-subtitle-2 font-weight-bold grey--text text--darken-1">
                                    <a-icon size="16" v-if="local_project_filters[project_filter.SORT] === 'progress_tasks' && sort_direction === ''">arrow_downward</a-icon>
                                    <a-icon size="16" v-if="local_project_filters[project_filter.SORT] === '-progress_tasks' && sort_direction === '-'">arrow_upward</a-icon>
                                    <a-icon size="16" v-if="local_project_filters[project_filter.SORT] !== 'progress_tasks' && local_project_filters[project_filter.SORT] !== '-progress_tasks'" class="neutral--text text--darken-1">arrow_downward</a-icon>
                                    Tasks
                                </h2>
                            </div>
                            <div @click="local_sort('stage.name')" class="u-cursor-pointer pl-0 c-project-detail-column">
                                <h2 class="md-subtitle-2 font-weight-bold grey--text text--darken-1">
                                    <a-icon size="16" v-if="local_project_filters[project_filter.SORT] === 'stage.name' && sort_direction === ''">arrow_downward</a-icon>
                                    <a-icon size="16" v-if="local_project_filters[project_filter.SORT] === '-stage.name' && sort_direction === '-'">arrow_upward</a-icon>
                                    <a-icon size="16" v-if="local_project_filters[project_filter.SORT] !== 'stage.name' && local_project_filters[project_filter.SORT] !== '-stage.name'" class="neutral--text text--darken-1">arrow_downward</a-icon>
                                    Stage
                                </h2>
                            </div>
                            <div @click="local_sort('start_date')" class="u-cursor-pointer c-project-detail-column">
                                <h2 class="md-subtitle-2 font-weight-bold grey--text text--darken-1">
                                    <a-icon size="16" v-if="local_project_filters[project_filter.SORT] === 'start_date' && sort_direction === ''">arrow_downward</a-icon>
                                    <a-icon size="16" v-if="local_project_filters[project_filter.SORT] === '-start_date' && sort_direction === '-'">arrow_upward</a-icon>
                                    <a-icon size="16" v-if="local_project_filters[project_filter.SORT] !== 'start_date' && local_project_filters[project_filter.SORT] !== '-start_date'" class="neutral--text text--darken-1">arrow_downward</a-icon>
                                    Started
                                </h2>
                            </div>
                            <div class="c-project-detail-column">
                                <h2 class="md-subtitle-2 font-weight-bold grey--text text--darken-1 text-left">
                                    Members
                                </h2>
                            </div>
                            <div v-if="local_is_confidence_score_enabled()" class="c-project-detail-column ">
                                <h2 class="md-subtitle-2 font-weight-bold grey--text text--darken-1 text-right">
                                    Accum. Confidence Score
                                </h2>
                            </div>
                        </div>
                    </a-container>
                    <a-card flat class="my-3 u-border-b u-rounded-corners" v-for="project in project_list_local" :key="project.id" :to="{name: 'projects-overview', params: {id: project.id}}">
                        <a-card-text class="pa-3">
                            <a-container container--fluid grid-list-xl>
                                <div class="u-flex justify-space-between align-center">
                                    <div class="c-poc-table-title u-flex align-center pr-4">
                                        <div class="text-center mr-6">
                                            <a-icon v-if="project.health_color == 'none'" class="u-icon-nudge" color="grey lighten-2" size="18">fiber_manual_record</a-icon>
                                            <a-icon v-if="project.health_color == 'green'" class="u-icon-nudge" color="green" size="18">fiber_manual_record</a-icon>
                                            <a-icon v-if="project.health_color == 'yellow'" class="u-icon-nudge" color="orange" size="18">fiber_manual_record</a-icon>
                                            <a-icon v-if="project.health_color == 'red'" class="u-icon-nudge" color="red" size="18">fiber_manual_record</a-icon>
                                        </div>
                                        <div class="u-wfull pr-14">
                                            <h2 class="md-subtitle-1 text-truncate grey--text text--darken-3 mb-1" :title="project.title">{{ project.title }}</h2>
                                            <h3 class="md-subtitle-2 text-truncate grey--text text--darken-2" v-if="project.customer" :title="project.customer.name">{{ project.customer.name }}</h3>
                                        </div>
                                    </div>
                                    <div v-if="$can('usecases.index')" class="c-project-detail-column">
                                        <div class="u-flex align-center">
                                            <div>
                                                {{ project.total_completed_success_criteria}}/{{ project.total_success_criteria }}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="$can('tasks.index')" class="c-project-detail-column">
                                        <div class="u-flex align-center">
                                            <div>
                                                {{ project.total_completed_tasks}}/{{ project.total_tasks }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-project-detail-column">
                                        <span v-if="project.stage" :title="project.stage.name" class="md-body-2 grey--text text--darken-1 text-capitalize">{{ (project.stage) ? local_truncate_text(project.stage.name, 20) :  project.status ? local_truncate_text(project.status, 20) : 'Archived'  }}</span>
                                    </div>
                                    <div class="c-project-detail-column">
                                        <span v-if="project.start_date" tag="p" class="md-body-2 grey--text text--darken-1">{{ local_date_output(project.start_date) }}</span>
                                        <span v-else class="d-block text-xs-left ml-8"> - </span>
                                    </div>
                                    <div class="c-project-detail-column">
                                        <template v-if="project.key_collaborators && project.key_collaborators.length">
                                            <div class="u-flex justify-start">
                                                <s-users-deck :list="project.key_collaborators" :count="2"></s-users-deck>
                                            </div>
                                        </template>
                                        <template v-else><span class="d-block text-xs-left ml-8"> - </span></template>
                                    </div>
                                    <div v-if="local_is_confidence_score_enabled()" class="c-project-detail-column c-project-detail-column__last text-right">
                                        <a-menu left nudge-left="50" open-on-hover open-delay="300" min-width="280">
                                            <template v-slot:activator="{ on }">
                                                <a-progress-circular
                                                    v-on="on"
                                                    width="3"
                                                    :value="project.confidence_score && project.confidence_score.percentage ? project.confidence_score.percentage : 0" :rotate="-90" size="40"
                                                    :color="!local_get_progress_color(project) ? 'grey lighten-2' : local_get_progress_color(project)"
                                                >
                                                    <div class="text-center">
                                                        <div class="grey--text text--darken-4 md-caption font-weight-medium" style="font-weight: 600">
                                                            {{ project.confidence_score && project.confidence_score.percentage ? `${project.confidence_score.percentage}%` : '--' }}
                                                        </div>
                                                    </div>
                                                </a-progress-circular>
                                            </template>
                                            <div class="pa-0 grey darken-4 white--text">
                                                <div class="pa-4">
                                                    <div class="md-subtitle-1 font-weight-medium">Confidence Score</div>
                                                    <template v-if="!metaLoading">
                                                        <div class="md-body-2">
                                                            <div class="mt-3" v-if="!project.confidence_score && local_confidence_score_configured">
                                                                Confidence score is not updated yet.
                                                            </div>
                                                            <div class="mt-3" v-else-if="!local_confidence_score_configured">
                                                                No fields are configured.
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <div v-else class="grey--text text--darken-2 md-caption pt-1 mt-3">
                                                        <loader-template height="6" width="200" class="u-rounded-corners-full"></loader-template>
                                                    </div>
                                                </div>
                                                <template v-if="project.confidence_score && project.confidence_score.current_score">
                                                    <a-divider class=""></a-divider>
                                                    <div class="pa-4 md-body-2">
                                                        <template v-if="project.confidence_score && project.confidence_score.percentage && !metaLoading">
                                                            Score:
                                                            <span class="md-subtitle-1 font-weight-medium">{{ project.confidence_score.current_score ? project.confidence_score.current_score : '--' }}</span>
                                                            <span>/</span>
                                                            <span>{{ project.confidence_score.total || '--' }}</span>
                                                            <span class="md-subtitle-1 ml-2 font-weight-medium">({{ project.confidence_score.percentage ? project.confidence_score.percentage : '--'}}%)</span>
                                                        </template>
                                                        <template v-else>
                                                            <div class="grey--text text--darken-2 md-caption u-flex-center-y py-1" style="column-gap: 4px">
                                                                <loader-template height="6" width="200" class="u-rounded-corners-full"></loader-template>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <a-divider></a-divider>
                                                    <div class="pa-4 md-body-2">
                                                        <template v-if="!metaLoading">
                                                            No confidence score
                                                        </template>
                                                        <template v-else>
                                                            <div class="grey--text text--darken-2 md-caption u-flex-center-y py-1" style="column-gap: 4px">
                                                                <loader-template height="6" width="200" class="u-rounded-corners-full"></loader-template>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </template>
                                            </div>
                                        </a-menu>
                                    </div>
                                </div>
                            </a-container>
                        </a-card-text>
                    </a-card>
                    <a-btn
                        v-if="project_meta && project_meta.current_page < project_meta.last_page"
                        @click="local_fetch({page: project_meta.current_page + 1})"
                        class="mx-0 mt-6 elevation-0"
                        color="primary"
                        small
                    >
                        Load more
                    </a-btn>
                </div>
            </a-layout>
            <a-responsive class="my-16" v-if="local_loading">
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Projects..</h3>
            </a-responsive>
            <a-responsive class="my-16" v-if="!local_loading && project_list && !project_list.length && local_scope_message">
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.<br>Try narrowing your filter or add new entries.</p>
            </a-responsive>
             <a-responsive class="my-16" v-if="!local_loading && !project_list.length && checkscope_message">
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">There are no projects available to display.</h3>
                <p class="md-body-2 text-center grey--text mb-0">The view may affected due to role based access control. Please check your configuration or contact your admin.</p>
            </a-responsive>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import loadWorkspace from '@/mixins/mixin-workspace'
import checkScopePermission from '@/mixins/mixin-checkscope-permission'
import { SUsersDeck } from '@/config/config-shared-components'
import { diffSimple } from '@/helpers/helper-date-filter.js'

export default {
    mixins: [loadWorkspace, checkScopePermission],

    components: {
        SUsersDeck
    },

    data() {
        return {
            search_term: null,
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            containerMaxWidth: 1980,
            local_project_status: [
                { value: 'all', type: 'All Status' },
                { value: 'queue', type: 'Queue' },
                { value: 'active', type: 'Active' },
                { value: 'completed', type: 'Completed' },
                { value: 'archived', type: 'Archived' }
            ],
            dialog_user_form: false,
            local_loading: true,
            local_workspace_id: '',
            search_workspace: false,
            local_filter_workspace_label: '',
            local_search_workspace: '',
            local_filter_status: 'All Status',
            workspace_mode: false,
            status_filter: [],
            project_list_local: [],
            sort_direction: '',
            project_filter: {
                FIEDS: 'fields[type]',
                STATUS: 'filter[status]',
                TITLE: 'filter[title]',
                WORKSPACE: 'filter[workspace_id]',
                SORT: 'sort',
                PAGE: 'page',
            },
            local_project_filters: {},
            local_score_hover: false,
            local_score_timeout: null,
            current_show_id: null,
            local_score_loaded_projects: [],
            metaLoading: false,
            configuration_meta: [],
            meta_filters: {
                'filter[type]': 'confidence_score',
                'fields[metas]': 'id,type,status,selection_type,percentage,color',
                'sort': 'order',
            },
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        local_confidence_score_flag () {
            return this.user_self.organization && this.user_self.organization.flag_confidence_score && this.user_self.organization.flag_confidence_score
        },

        local_confidence_score_configured () {
            return this.confidence_score_aggregate && this.confidence_score_aggregate.fields_configured === 1
        },

        local_org_preference_confidence_score () {
            return this.preference_get_property_by_key('organization.confidence_score', 'value') === 'Yes'
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Project', {
            project_list: 'list',
            project_meta: 'meta',
            project_filters: 'filters',
            project_checkscope_list: 'checkscope_list',
            project_response: 'response',
        }),

        ...mapState('Workspace', {
            workspace_list: 'list',
            workspace_item: 'item',
            workspace_form_mode: 'form_mode',
            workspace_response: 'response',
            workspace_filters: 'filters',
        }),

        ...mapState('Stage', {
            stage_list: 'list',
            state_filters: 'filters',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key',
        }),

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),

        ...mapState('ConfidenceScore', {
            confidence_score_aggregate: 'aggregate',
        }),
    },

    watch: {
        search_term(newValue, oldValue) {
            if (newValue == '' && newValue != oldValue || newValue == null && newValue != oldValue) {
                this.project_filters.involvement = null
                this.local_fetch(null, 'replace')
            }
        },
    },

    methods: {
        async local_index() {
            await this.loading_stop()
            await this.preference_index()
            await this.project_clear()
            this.local_project_checkscope()
            this.project_list_local = []
            this.local_set_project_filters()
            if (this.local_is_confidence_score_enabled()) this.local_load_confidence_score_params()
            this.local_filter_status = 'All Status'
            await this.local_project_workspace_filter()
        },

        local_is_confidence_score_enabled() {
            return this.local_org_preference_confidence_score && this.local_confidence_score_flag && this.$can('poc_confidence_score.index')
        },

        local_truncate_text(text, range, separator = '...') {
            if (!text) return
            return text.length > range ? text.slice(0, range) + separator : text
        },

        local_load_confidence_score_params () {
            this.confidence_score_aggregate_show()
            this.local_set_confidence_score_filters()
            this.local_load_configuration_meta()
        },

        local_set_project_filters() {
            this.local_set_basic_attributes()
        },

        async local_set_confidence_score_filters () {
            this.local_project_filters['fields[projects]'] = this.local_project_filters['fields[projects]'] + ',confidence_score'
        },

        async local_load_configuration_meta () {
            await this.meta_index({...this.meta_filters})
            this.configuration_meta = _.cloneDeep(this.meta_list)
        },

        local_get_progress_color (project) {
            if (project && !project.confidence_score) return
            if (this.configuration_meta && !this.configuration_meta.length) return

            const confidence_score = project.confidence_score.percentage
            if (confidence_score <= this.local_get_range_values('red-dark', 'percentage')) return this.local_get_range_values('red-dark', 'color')
            if ((confidence_score <= this.local_get_range_values('yellow-dark', 'percentage')) && (confidence_score > this.local_get_range_values('red-dark', 'percentage'))) return this.local_get_range_values('yellow-dark', 'color')
            if (confidence_score > this.local_get_range_values('yellow-dark', 'percentage')) return this.local_get_range_values('green-dark', 'color')
        },

        local_get_range_values (color, value) {
            const result = this.configuration_meta.find(item => item.color === color)
            if (value === 'percentage') return result[value]
            return this.$color(color ?? 'teal-dark', 'color_bg')
        },

        async local_project_workspace_filter() {
            this.local_project_filters[this.project_filter.WORKSPACE] = this.current_workspace_id_mixin
            this.local_project_filters[this.project_filter.SORT] = 'title'
          //  this.project_filters.workspace_id = workspace_id
            await this.local_fetch_projects()

            this.project_list_local = this.project_list
            setTimeout(() => this.local_loading = false, 100)
        },

        capitalize_first_letter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        async local_select_project_status({ type, value}) {
           this.local_filter_status = type
            this.local_project_filters[this.project_filter.STATUS] = value
            await this.local_fetch_projects()
            this.project_list_local = [ ...this.project_list ]
        },

        async local_fetch(payload, type = null) {
            await this.loading_stop()
            this.local_project_filters[this.project_filter.TITLE] = this.search_term
            this.local_project_filters.page = (payload && payload.page >= 1) ? payload.page : 1;
            this.local_project_filters.list = true
            payload ? this.local_project_filters.list = true  : this.local_project_filters.list = null
            await this.local_fetch_projects()

            if (type === 'replace') this.project_list_local = this.project_list
            else this.project_list_local = this.project_list
        },

        async local_sort(field) {
            this.local_clear_loaded_project_data()
            await this.loading_stop()
            if (this.local_project_filters[this.project_filter.SORT] === field) {
                if (this.sort_direction === '') this.sort_direction = '-'
                else this.sort_direction = ''
                this.local_project_filters[this.project_filter.SORT] = `${this.sort_direction}${this.local_project_filters[this.project_filter.SORT]}`
            } else {
                this.local_project_filters[this.project_filter.SORT] = field;
                this.sort_direction = ''
                this.local_project_filters[this.project_filter.SORT] = `${this.sort_direction}${this.local_project_filters[this.project_filter.SORT]}`
            }
            this.local_project_filters[this.project_filter.PAGE] = 1;
            await this.local_fetch_projects()
            this.project_list_local = this.project_list
        },

        local_clear_loaded_project_data () {
            this.local_score_loaded_projects = []
        },

        async local_fetch_projects() {
            await this.local_set_basic_attributes()
            if (this.local_is_confidence_score_enabled()) await this.local_set_confidence_score_filters()
            await this.project_index(this.local_project_filters)
        },

        async local_set_basic_attributes() {
            this.local_project_filters.page = this.local_project_filters.page >= 1 ? this.local_project_filters.page : 1
            this.local_project_filters.include = 'keyCollaborators.type,keyCollaborators.user,stage,customer'
            this.local_project_filters['fields[projects]'] = 'id,title,stage_id,start_date,health_color,customer_id,total_completed_success_criteria,total_success_criteria,total_completed_tasks,total_tasks'
            this.local_project_filters['fields[customer]'] = 'id,name'
        },

        local_text_truncate(text, length, separator) {
            return _.truncate(text, {length: length, separator: separator});
        },

        local_date_output (date) {
            return diffSimple(date, {type: 'includeYear'})
        },

        local_get_score_details (projectId) {
            this.local_score_hover = true
            if (this.current_show_id === projectId) return
            if (this.local_score_loaded_projects.indexOf(projectId) !== -1) return
            this.metaLoading = true

            this.current_show_id = projectId
            if (!this.local_score_hover) return
            this.local_score_timeout = setTimeout(() => {
                this.local_fetch_project_meta(projectId)
            }, 1200)

        },

        async local_fetch_project_meta (projectId) {
            const params = {'filter[confidence_score]': 1, 'fields[projects]': 'id'}
            await this.project_show({
                id: projectId,
                params: params,
                mode: 'update-list-item'
            })
            if (this.project_response && this.project_response.status === 'success') {
                this.local_score_loaded_projects.push(projectId)
                this.metaLoading = false
            }
        },

        local_score_hover_leave () {
            this.local_score_hover = false
            this.current_show_id = null
            clearTimeout(this.local_score_timeout)
        },

        ...mapActions('Project', {
            project_index: 'index',
            project_show: 'show',
            project_clear_list: 'clear_list',
            project_clear: 'clear',
        }),

        ...mapActions('Workspace', {
            workspace_index: 'index',
            workspace_clear: 'clear',
        }),

        ...mapActions('Stage', {
            stage_index: 'index',
            stage_clear: 'clear',
        }),

        ...mapActions('Interface', {
            loading_stop: 'loader_stop',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
        }),

        ...mapActions('Preference', {
            preference_index: 'index',
        }),

        ...mapActions('ConfidenceScore', {
            confidence_score_aggregate_show: 'aggregate_show'
        }),
    }
}
</script>

<style lang="scss" scoped>
    .column-users {
        max-width: 100px;
        text-align: right;
    }

    .column-title {
        max-width: 311px;
    }

    .text-truncate {
        line-height: 1.2 !important;
    }

    .c-poc-table-title {
        @media only screen and (min-width: 1280px) {
            min-width: 300px !important;
            max-width: 300px !important;
        }
        @media only screen and (min-width: 1366px) {
            min-width: 400px !important;
            max-width: 400px !important;
        }
    }

    .c-project-detail-column {
        width: 200px;
        &__last {
            width: 180px;
        }
    }
</style>

<template>
	<a-main>
        <g-settings-breadcrumb class="mt-5" previousRoute="inventory"></g-settings-breadcrumb>
        <a-container container--fluid grid-list-xl px-0 mb-16 mt-3>
            <a-layout alitn-center justify-center>
                <a-flex xs12>
                    <a-card>
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-start>
                                    <a-flex shrink class="pr-1">
                                        <a-avatar class="primary darken-1" size="40">
                                            <a-icon dark>memory</a-icon>
                                        </a-avatar>
                                    </a-flex>
                                    <a-flex>
                                        <h2 class="md-heading-6 primary--text text--darken-1 mb-1">Add Inventory</h2>
                                        <p class="md-subtitle-2 mb-0">You can add Inventory</p>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <!-- Step 1 -->
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-center>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Inventory Name <span class="red--text font-weight-bold">*</span></label>
                                        <a-text-field
                                            v-model="local_inventory_item.name"
                                            placeholder="Enter Inventory Name"
                                            background-color="neutral"
                                            solo flat hide-details autofocus
                                        >
                                        </a-text-field>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.name">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.name[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            This can be name of the Inventory.
                                        </span>
                                    </a-flex>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Description (Optional)</label>
                                        <a-textarea
                                            v-model="local_inventory_item.description"
                                            placeholder="Enter Description"
                                            background-color="neutral"
                                            rows= "3"
                                            solo flat hide-details no-resize
                                        >
                                        </a-textarea>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.description">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.description[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            You can summarize and describe the Inventory.
                                        </span>
                                    </a-flex>
                                    <a-flex xs6 @click="current_search_module = 'oem'">
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">OEM (Optional)</label>
                                        <a-combobox
                                            v-model="local_inventory_item.oem"
                                            :items="inventory_autosuggest_list"
                                            item-text="oem"
                                            item-value="oem"
                                            placeholder="Enter OEM"
                                            background-color="neutral"
                                            :loading="autosuggest_loading === 'oem'"
                                            :append-icon="null"
                                            :search-input.sync="autosuggest_search['oem']"
                                            @input="local_select_autosuggest(local_inventory_item, 'oem')"
                                            @click="current_search_module = 'oem'"
                                            @clear="local_autosuggest_search_clear(autosuggest_search, 'oem')"
                                            solo flat hide-details clearable hide-no-data
                                        ></a-combobox>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.oem">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.oem[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Enter OEM of the inventory
                                        </span>
                                    </a-flex>
                                    <a-flex xs6 @click="current_search_module = 'model_no'">
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Model Number (Optional)</label>
                                        <a-combobox
                                            v-model="local_inventory_item.model_no"
                                            :items="inventory_autosuggest_list"
                                            item-text="model_no"
                                            item-value="model_no"
                                            placeholder="Enter Model Number"
                                            background-color="neutral"
                                            :loading="autosuggest_loading === 'model_no'"
                                            :append-icon="null"
                                            :search-input.sync="autosuggest_search['model_no']"
                                            @input="local_select_autosuggest(local_inventory_item, 'model_no')"
                                            @click="current_search_module = 'model_no'"
                                            @clear="local_autosuggest_search_clear(autosuggest_search, 'model_no')"
                                            solo flat hide-details clearable hide-no-data
                                        ></a-combobox>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.model_no">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.model_no[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Enter model number of the inventory
                                        </span>
                                    </a-flex>
                                    <a-flex xs6 @click="current_search_module = 'part_no'">
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Part Number (Optional)</label>
                                        <a-combobox
                                            v-model="local_inventory_item.part_no"
                                            :items="inventory_autosuggest_list"
                                            item-text="part_no"
                                            item-value="part_no"
                                            placeholder="Enter Part Number"
                                            background-color="neutral"
                                            :loading="autosuggest_loading === 'part_no'"
                                            :append-icon="null"
                                            :search-input.sync="autosuggest_search['part_no']"
                                            @input="local_select_autosuggest(local_inventory_item, 'part_no')"
                                            @click="current_search_module = 'part_no'"
                                            @clear="local_autosuggest_search_clear(autosuggest_search, 'part_no')"
                                            solo flat hide-details clearable hide-no-data
                                        ></a-combobox>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.part_no">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.part_no[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Enter part number of the inventory.
                                        </span>
                                    </a-flex>
                                    <a-flex xs6 @click="current_search_module = 'serial_no'">
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Serial Number (Optional)</label>
                                        <a-combobox
                                            v-model="local_inventory_item.serial_no"
                                            :items="inventory_autosuggest_list"
                                            item-text="serial_no"
                                            item-value="serial_no"
                                            placeholder="Enter Serial Number"
                                            background-color="neutral"
                                            :loading="autosuggest_loading === 'serial_no'"
                                            :append-icon="null"
                                            :search-input.sync="autosuggest_search['serial_no']"
                                            @input="local_select_autosuggest(local_inventory_item, 'serial_no')"
                                            @click="current_search_module = 'serial_no'"
                                            @clear="local_autosuggest_search_clear(autosuggest_search, 'serial_no')"
                                            solo flat hide-details clearable hide-no-data
                                        ></a-combobox>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.serial_no">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.serial_no[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Enter serial number of the inventory.
                                        </span>
                                    </a-flex>
                                    <a-flex xs6 @click="current_search_module = 'hardware_version'">
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Hardware Version (Optional)</label>
                                        <a-combobox
                                            v-model="local_inventory_item.hardware_version"
                                            :items="inventory_autosuggest_list"
                                            item-text="hardware_version"
                                            item-value="hardware_version"
                                            placeholder="Enter Part Number"
                                            background-color="neutral"
                                            :loading="autosuggest_loading === 'hardware_version'"
                                            :append-icon="null"
                                            :search-input.sync="autosuggest_search['hardware_version']"
                                            @input="local_select_autosuggest(local_inventory_item, 'hardware_version')"
                                            @click="current_search_module = 'hardware_version'"
                                            @clear="local_autosuggest_search_clear(autosuggest_search, 'hardware_version')"
                                            solo flat hide-details clearable hide-no-data
                                        ></a-combobox>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.hardware_version">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.hardware_version[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Enter hardware version of the inventory.
                                        </span>
                                    </a-flex>
                                    <a-flex xs6 @click="current_search_module = 'software_version'">
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Software Version (Optional)</label>
                                        <a-combobox
                                            v-model="local_inventory_item.software_version"
                                            :items="inventory_autosuggest_list"
                                            item-text="software_version"
                                            item-value="software_version"
                                            placeholder="Enter Part Number"
                                            background-color="neutral"
                                            :loading="autosuggest_loading === 'software_version'"
                                            :append-icon="null"
                                            :search-input.sync="autosuggest_search['software_version']"
                                            @input="local_select_autosuggest(local_inventory_item, 'software_version')"
                                            @click="current_search_module = 'software_version'"
                                            @clear="local_autosuggest_search_clear(autosuggest_search, 'software_version')"
                                            solo flat hide-details clearable hide-no-data
                                        ></a-combobox>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.software_version">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.software_version[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Enter software version of the inventory.
                                        </span>
                                    </a-flex>
                                    <a-flex xs6>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Original Value (Optional)</label>
                                        <a-text-field
                                            v-model="local_inventory_item.original_amount"
                                            placeholder="Enter Amount"
                                            background-color="neutral"
                                            solo flat hide-details
                                        >
                                        </a-text-field>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.original_amount">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.original_amount[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Enter original value of the inventory.
                                        </span>
                                    </a-flex>
                                    <a-flex xs6>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Current Value (Optional)</label>
                                        <a-text-field
                                            v-model="local_inventory_item.current_amount"
                                            placeholder="Enter Amount"
                                            background-color="neutral"
                                            solo flat hide-details
                                        >
                                        </a-text-field>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.current_amount">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.current_amount[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Enter current value of the inventory.
                                        </span>
                                    </a-flex>
                                    <a-flex xs6>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Owner (Optional)</label>
                                        <GUsersSelectDropdown
                                            v-model="local_inventory_item.owner_id"
                                            :list="user_list"
                                            itemValue="id"
                                            :loading="mixinSearchLoading"
                                            :disabled="mixinSearchLoading"
                                            :inputStyle="{ minHeight: '30px', fontSize: '16px' }"
                                            @search="localSearchUsers"
                                            noDataText="No User found"
                                            placeholder="Select User"
                                            class="pr-5 neutral"
                                            dropdownClass="c-tiny-scroll py-2"
                                            returnObject clearable
                                        >
                                            <template #item="{ props: { item } }">
                                                <div class="u-flex-center-y">
                                                    <g-avatar :item="item"></g-avatar>
                                                    <g-profile-info :item="item" type-user set-max-width></g-profile-info>
                                                </div>
                                            </template>
                                        </GUsersSelectDropdown>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                            <a-icon size="16">info</a-icon>
                                            Select Member to assign.
                                        </span>
                                    </a-flex>
                                    <a-flex xs6>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Category (Optional)</label>
                                        <component
                                            :is="$can('metas.store') ? 'a-combobox' : 'a-autocomplete'"
                                            v-model="local_category_item"
                                            ref="category_dropdown"
                                            :items="meta_list"
                                            item-text="value"
                                            item-value="id"
                                            :error="category_exist_message"
                                            :placeholder="`Select${$can('metas.store') ? ' or create ' : ' '}category`"
                                            background-color="neutral"
                                            :search-input.sync="inventory_category_search"
                                            solo flat hide-details hide-selected clearable
                                            :readonly="local_category_item ? true : false"
                                            @click:clear="local_inventory_category_clear()"
                                            @keydown.enter="$can('metas.store') ? local_inventory_category_store() : local_inventory_category_select(local_category_item)"
                                        >
                                            <template v-slot:no-data>
                                                <div class="px-4 py-2">
                                                    <span class="md-body-1" v-if="inventory_category_search !== null && inventory_category_search !== '' && $can('metas.store')">Create <strong>{{ inventory_category_search }}</strong> as Category. Hit<span style="font-family: monospace;" class="mx-1 font-weight-bold">Enter</span>to create.</span>
                                                    <span class="md-body-1 grey--text" v-else>No categories found. <template v-if="$can('metas.store')">Enter category and hit enter to create new one.</template></span>
                                                </div>
                                            </template>
                                            <template v-slot:selection="{ item }">
                                                <div v-if="item === Object(item)" class="md-body-2 d-flex align-center py-1 u-rounded-corners" style="width: 100%; min-height: 48px;">{{ item.value }}</div>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <a-flex>
                                                    <div class="md-body-2 d-block py-1 u-rounded-corners" style="width: 100%" @click="local_move_category_focus()">{{ item.value }}</div>
                                                </a-flex>
                                            </template>
                                        </component>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.category_id">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.category_id[0] }}
                                        </span>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="meta_response.server && meta_response.server.errors && meta_response.server.errors.category_id">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ meta_response.server.errors.category_id[0] }}
                                        </span>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="meta_response.server && meta_response.server.errors && meta_response.server.errors.value">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ meta_response.server.errors.value[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Select category to add to the inventory.
                                        </span>
                                    </a-flex>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Location (Optional)</label>
                                        <a-text-field
                                            v-model="local_inventory_item.location"
                                            placeholder="Enter Location"
                                            background-color="neutral"
                                            solo flat hide-details
                                        >
                                        </a-text-field>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.location">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.location[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Enter location of the inventory.
                                        </span>
                                    </a-flex>
                                    <a-flex xs12>
                                        <div>
                                            <label class="md-body-2 d-inline-block mb-2 mt-0 font-weight-medium">Territory (Optional)</label>
                                            <transition name="fade">
                                                <span v-if="territory_exist_message" class="body-1 red--text text--lighten-1 ml-2 d-inline-block">Territory already exist. Please create a unique territory</span>
                                            </transition>
                                        </div>
                                        <component
                                            :is="$can('territories.store') ? 'a-combobox' : 'a-autocomplete'"
                                            v-model="inventory_territories"
                                            :items="territory_list"
                                            item-text="name"
                                            item-value="id"
                                            :error="territory_exist_message"
                                            :placeholder="`Select${$can('territories.store') ? ' or create ' : ' '}territory`"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :search-input.sync="inventory_territory_search"
                                            solo flat hide-details multiple deletable-chips small-chips auto-select-first hide-selected clearable
                                            @keydown.enter="$can('territories.store') ? local_inventory_territory_store() : ''"
                                        >
                                            <!-- @input="$can('territories.update') ? local_inventory_territory_store() : ''" -->
                                            <template v-slot:no-data>
                                                <div class="px-4 py-2">
                                                    <span v-if="inventory_territory_search != null && inventory_territory_search != '' && $can('territories.store')">
                                                        Create <strong>{{ inventory_territory_search }}</strong> as territory. Hit<span style="font-family: monospace;" class="mx-1 font-weight-bold">Enter</span>to create.
                                                    </span>
                                                    <span class="grey--text" v-else>
                                                        No territories found. <template v-if="$can('territories.store')">Enter label and hit enter to create new one.</template>
                                                    </span>
                                                </div>
                                            </template>
                                            <template v-slot:selection="{ item }">
                                                <g-tags :tag="item" @destroy="local_remove_chips(inventory_territories, 'territory', item, $event)"></g-tags>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <g-tags :tag="item" dense hide-clear-icon></g-tags>
                                            </template>
                                        </component>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.territory_id">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.territory_id[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Select territories to add to the inventory
                                        </span>
                                    </a-flex>
                                    <a-flex xs12>
                                        <div>
                                            <label class="md-body-2 d-inline-block mb-2 mt-0 font-weight-medium">Tags (Optional)</label>
                                            <transition name="fade">
                                                <span v-if="tag_exist_message" class="body-1 red--text text--lighten-1 ml-2 d-inline-block">Tag already exist. Please create a unique tag</span>
                                            </transition>
                                        </div>
                                        <component
                                            :is="$can('tags.store') ? 'a-combobox' : 'a-autocomplete'"
                                            v-model="inventory_tags"
                                            :items="tag_list"
                                            item-text="label"
                                            item-value="id"
                                            :error="tag_exist_message"
                                            :placeholder="`Select${$can('tags.store') ? ' or create ' : ' '}tag`"
                                            background-color="neutral"
                                            clear-icon="backspace"
                                            :search-input.sync="inventory_tag_search"
                                            solo flat hide-details multiple deletable-chips small-chips auto-select-first hide-selected clearable
                                            @keydown.enter="$can('tags.store') ? local_inventory_tag_store() : ''"
                                        >
                                            <!-- @input="$can('tags.store') ? local_inventory_tag_store() : ''" -->
                                            <template v-slot:no-data>
                                                <div class="px-4 py-2">
                                                    <span v-if="inventory_tag_search != null && inventory_tag_search != '' && $can('tags.store')">
                                                        Create <strong>{{ inventory_tag_search }}</strong> as tag. Hit<span style="font-family: monospace;" class="mx-1 font-weight-bold">Enter</span>to create.
                                                    </span>
                                                    <span class="grey--text" v-else>
                                                        No tags found. <template v-if="$can('tags.store')">Enter label and hit enter to create new one.</template>
                                                    </span>
                                                </div>
                                            </template>
                                            <template v-slot:selection="{ item }">
                                                <g-tags :tag="item" @destroy="local_remove_chips(inventory_tags, 'tag', item, $event)"></g-tags>
                                                <!-- <span v-if="item === Object(item)" class="md-body-2 d-inline-flex align-center py-1 px-2 mx-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">
                                                    <span>{{ item.label }}</span>
                                                    <a-icon @click="local_remove_chips(inventory_tags, 'tag', item)" class="ml-1" size="16" :color="$color(item.color, 'color_text')">clear</a-icon>
                                                </span> -->
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <g-tags :tag="item" dense hide-clear-icon></g-tags>
                                            </template>
                                        </component>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.tag_id">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.tag_id[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Select tags to add to the inventory
                                        </span>
                                    </a-flex>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Notes (Optional)</label>
                                        <a-textarea
                                            v-model="local_inventory_item.notes"
                                            placeholder="Enter Notes"
                                            background-color="neutral"
                                            rows= "3"
                                            solo flat hide-details no-resize
                                        >
                                        </a-textarea>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.notes">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ inventory_response.server.errors.notes[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            You can summarize and notes the Inventory.
                                        </span>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-center>
                                    <a-flex shrink>
                                        <a-btn color="grey" class="ma-0 elevation-0 pl-0" text :to="{name: 'inventory'}">
                                            <a-icon left class="mx-2">keyboard_arrow_left</a-icon>
                                            Cancel
                                        </a-btn>
                                    </a-flex>
                                    <a-spacer></a-spacer>
                                    <a-flex shrink>
                                        <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_inventory_store()" :loading="loading" :disabled="loading">Save Inventory</a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import mixinSearchUsers from '@/mixins/mixin-search-users'

export default {
    mixins: [mixinSearchUsers],

    data () {
        return {
            inventory_category_search: null,
            category_exist_message: false,
            local_status_list : [],
            local_inventory_item: {
                name: '',
                description: '',
                oem: '',
                model_no: '',
                part_no: '',
                serial_no: '',
                software_version: '',
                hardware_version: '',
                original_amount: '',
                current_amount: '',
                owner_id: '',
                category_id: '',
                location: '',
                territories: [],
                tags: [],
                notes: ''
            },
            inventory_tags: [],
            inventory_territories: [],
            local_category_item: null,
            inventory_territory_search: null,
            territory_exist_message: null,
            inventory_tag_search: null,
            tag_exist_message: null,
            autosuggest_timeout: null,
            autosuggest_loading: null,
            autosuggest_search: {},
            current_search_module: null,
        }
    },

    mounted () {
        this.local_index()
    },

    watch: {
        autosuggest_search: {
            handler (val) {
                if (this.current_search_module) {
                    const value = val[this.current_search_module]
                    this.local_inventory_item[this.current_search_module] = value
                    this.local_autosuggest(value, this.current_search_module)
                }
            },
            deep: true
        },
        current_search_module (newVal, oldVal) {
            if (newVal && (newVal !== oldVal)) this.inventory_clear()
            // if (oldVal) this.autosuggest_search = {}
        },
    },

    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Inventorys', to: '/inventories', exact: true})
            breadcrumbs.push({text: 'add new inventories', to: '/inventories/add', exact: true})
            return breadcrumbs
        },
        ...mapGetters('Inventory', {
            inventory_autosuggest_list: 'autosuggest_list',
            inventory_item: 'item',
            inventory_form_mode: 'form_mode',
            inventory_response: 'response',
        }),
        ...mapState('Meta', {
            meta_list: 'list',
            meta_item: 'item',
            meta_filters: 'filters',
            meta_form_mode: 'form_mode',
            meta_response: 'response',
        }),
        ...mapState('User', {
            user_self: 'self',
            user_list: 'list',
            user_filters: 'filters',
        }),
        ...mapState('Territory', {
            territory_list: 'list',
            territory_item: 'item',
            territory_filters: 'filters',
            territory_response: 'response',
        }),
        ...mapState('Tag', {
            tag_list: 'list',
            tag_item: 'item',
            tag_filters: 'filters',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_index() {
            if (this.$can('inventories.store') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.inventory_clear()
            await this.meta_clear()

            this.tag_clear()
            await this.tag_index({ 'filter[type]': 'inventory_tag' })

            this.territory_clear()
            this.territory_index()
            await this.meta_index({ 'filter[type]': 'inventory_category', sort: 'order' })
        },
        async local_inventory_store() {
            if(this.local_category_item) this.local_inventory_item.category_id  = this.local_category_item.id ?? this.local_category_item
            if(this.inventory_tags && this.inventory_tags.length) {
                this.local_inventory_item.tags = this.inventory_tags.map(item => item.id ?? item)
            }

            this.local_inventory_item.territories = this.inventory_territories.map(item => item.id ?? item)
            if (this.local_inventory_item.owner_id) this.local_inventory_item.owner_id = this.local_inventory_item.owner_id.id
            await this.inventory_store({ ...this.local_inventory_item })

            if (this.inventory_response.status === 'success') {
                this.$router.push({name: 'inventory'})
            }
        },
        async local_inventory_category_store() {
            this.local_move_category_focus()
            if(this.inventory_category_search != null) {
                this.meta_item.type = 'inventory_category'
                this.meta_item.value = this.inventory_category_search
                await this.meta_store({ ...this.meta_item })
                if (this.meta_response.status === 'success') {
                    this.local_category_item = this.meta_response.data
                    await this.meta_clear_item()
                } else setTimeout(() => this.local_category_item = null, 200)
            }
        },
        async local_inventory_category_select(id) {
            this.local_move_category_focus()
            this.local_category_item = id
        },
        async local_inventory_territory_store() {
            if (this.inventory_territory_search === null) return
            this.territory_item.name = this.inventory_territory_search
            this.territory_item.type =  'Label'
            await this.territory_store({...this.territory_item, mode: 'add_update' })
            setTimeout(async () => {
                this.inventory_territories.push(this.territory_item)
                this.inventory_territory_search = null
                await this.territory_clear_item()
            }, 200)
        },
        async local_inventory_tag_store() {
            if (this.inventory_tag_search === null) return
            this.tag_item.label = this.inventory_tag_search
            this.tag_item.type = 'inventory_tag'
            await this.tag_store({ ...this.tag_item, mode: 'add_update' })
            setTimeout(async () => {
                this.inventory_tags.push(this.tag_item)
                this.inventory_tag_search = null
                await this.tag_clear_item()
            }, 200)
        },
        local_remove_chips(list, type, item) {
            const id = item.id ?? item
            const index = list.findIndex(chip => (chip === id) || (chip.id === id))
            if(index !== -1) list.splice(index, 1)
        },
        local_move_category_focus() {
            this.$refs.category_dropdown.ref.blur()
        },
        local_inventory_category_clear() {
            this.meta_clear_item()
            this.local_category_item = null
        },
        local_tag_item(id) {
            return this.tag_list.find(tag => tag.id === id)
        },
        local_autosuggest_search_clear (suggestion_text, field) {
            suggestion_text[field] = null
            this.local_inventory_item[field] = null
        },
        local_select_autosuggest (item, field) {
            if (item[field] && (typeof item[field] === 'object')) {
                this.local_inventory_item[field] = item[field][field]
            }
            this.current_search_module = null
        },
        async local_autosuggest (search, field) {
            if ((search && (!search.trim() || !search.length)) || !search) return
            clearTimeout(this.autosuggest_timeout)
            this.autosuggest_timeout = setTimeout(async () => {
                this.autosuggest_loading = field
                await this.inventory_autosuggest({
                    'fields[inventories]': 'id,' + field,
                    'filter[auto_suggest][search]': search,
                    'filter[auto_suggest][field]': field,
                    'count': 999,
                    'page': 1
                })
                this.autosuggest_loading = null
            }, 400)
        },

        localSearchUsers (value) {
            const args = { 'include': 'role', 'sort': 'name', 'filter[is_active]': 1, count: 20, page: 1 }
            this.mixinSearchUsers(value, args)
        },

        ...mapActions('Inventory', {
            inventory_autosuggest: 'autosuggest',
            inventory_store: 'store',
            inventory_update: 'update',
            inventory_clear: 'clear',
            inventory_clear_item: 'clear_item',
        }),
        ...mapActions('User', {
            user_index: 'index',
            user_clear: 'clear',
        }),
        ...mapActions('Meta', {
            meta_index: 'index',
            meta_store: 'store',
            meta_clear: 'clear',
            meta_clear_item: 'clear_item',
        }),
        ...mapActions('Territory', {
            territory_index: 'index',
            territory_store: 'store',
            territory_clear: 'clear',
            territory_clear_item: 'clear_item',
        }),
        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_clear: 'clear',
            tag_clear_item: 'clear_item',
        }),
    },
}
</script>

<template>
    <div class="text-truncate">
        <h4
            :title="localGetProject('title')"
            class="primary--text u-font-16 font-weight-medium u-leading-20 u-tracking text-truncate"
        >
            {{ localGetProject('title') }}
        </h4>
        <div class="u-flex-center-y mt-2" style="column-gap: 8px;">
            <shared-health-color :healthType="localGetProject('health_color')" />
            <a-icon size="5" color="grey lighten-1">fiber_manual_record</a-icon>
            <h5
                :title="localGetProject('customer_name')"
                class="u-font-14 grey--text text--darken-1 u-leading-20 u-tracking font-weight-medium text-truncate"
                style="max-width: 130px;"
            >
                {{ localGetProject('customer_name') }}
            </h5>
            <a-icon size="5" color="grey lighten-1">fiber_manual_record</a-icon>
            <h5
                class="u-font-14 blue-grey--text u-leading-20 u-tracking font-weight-medium text-truncate"
                style="max-width: 130px;"
            >
                <g-currency tag="span" :value="localGetProject('deal_amount')"></g-currency>
            </h5>
            <template v-if="stage">
                <a-icon size="5" color="grey lighten-1">fiber_manual_record</a-icon>
                <div class="d-inline-flex align-center">
                    <a-icon size="18" class="mr-1" color="grey">
                        {{ stage.icon }}
                    </a-icon>
                    <h5
                        :title="stage.name"
                        class="u-font-14 grey--text text--darken-1 u-leading-20 u-tracking font-weight-medium text-truncate"
                        style="max-width: 130px;"
                    >
                        {{ stage.name }}
                    </h5>
                </div>
            </template>
            <template v-if="localShowConfScore && localConfidenceScoreVisibility">
                <a-icon size="5" color="grey lighten-1">fiber_manual_record</a-icon>
                <div class="d-inline-flex align-center">
                    <partial-tile-score-count
                        :value="localGetProject('confidence_score.percentage') || 100"
                        :color="localGetProject('confidence_score.color')"
                        :is-zero="!localGetProject('confidence_score.current_score')"
                    />
                    <h5 class="u-font-12 grey--text text--darken-1 ml-1 u-leading-16 u-tracking font-weight-medium">
                        {{ localGetProject('confidence_score.percentage') ? localGetProject('confidence_score.percentage') + '%' : '-' }}
                    </h5>
                </div>
            </template>
            <template v-if="localGetProject('result_id') && result">
                <a-icon size="5" color="grey lighten-1">fiber_manual_record</a-icon>
                <h5
                    :class="[
                        { 'green--text text--darken-2': result.status === 'Positive' },
                        { 'red--text text--darken-2': result.status === 'Negative' },
                        { 'indigo--text text--darken-2': result.status === 'Neutral' },
                    ]"
                    :title="result.value"
                    class="u-font-14 ml-1 u-leading-20 u-tracking font-weight-medium text-truncate"
                    style="max-width: 130px;"
                >
                    {{ result.value }}
                </h5>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ScoreColor } from '../../helpers/helper-confidence-score-color'
import { hasConfidenceScoreFlag } from '../../helpers/helper-get-valid-routes'
import PartialTileScoreCount from '../../pages/Dashboard/Partials/PartialTileScoreCount.vue'
import SharedHealthColor from './SharedHealthColor.vue'

export default {
	components: { SharedHealthColor, PartialTileScoreCount },

    props: {
        project: {
            type: Object
        },
        stage: {
            type: Object | null
        },
        result: {
            type: Object | null
        },
        confScoreMeta: {
            type: Array | Object
        },
    },

    computed: {
        localShowConfScore () {
            return this.localGetProject('confidence_score.percentage') &&
                ['active', 'queue'].includes(this.project?.status)
        },

        localConfidenceScoreVisibility () {
            if (!hasConfidenceScoreFlag()) return false
            if (!this.localHasConfScorePref) return false
            if (!this.$can('poc_confidence_score.index')) return false

            return true
        },

        localHasConfScorePref () {
            return this.preference_get_property_by_key('organization.confidence_score', 'value') !== 'No'
        },

        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key',
        }),
    },

    methods: {
        localGetProject (field) {
            if (field === 'confidence_score.percentage') {
                return _.get(this.project, 'confidence_score.percentage') || '-'
            }

            if (field === 'confidence_score.color') {
                return this.localGetConfScoreColor(_.get(this.project, 'confidence_score.percentage'))
            }

            return _.get(this.project, field)
        },

        localGetConfScoreColor (percentage) {
            return ScoreColor(percentage, this.confScoreMeta).get()
        },
    }
}
</script>

<template>
	<a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="pa-0 mx-0 pt-2 pb-16">
            <g-page-header title="Authentication Logs" subtitle="View all current and expired sessions of the user accounts." icon="summarize"></g-page-header>

            <!-- Filters -->
            <a-layout align-center mt-2 mb-3>
                <a-flex shrink pr-1>
                    <a-menu bottom offset-y v-model="search_user" :close-on-content-click="filter_mode" min-width="352" max-width="352" max-height="500" transition="slide-y-transition" class="d-block">
                        <template v-slot:activator="{ on }">
                            <a-card class="u-rounded-corners u-overflow-hidden c-border-c7 u-elevation-custom-1" min-width="100%" v-on="on" @click="local_search_user = ''">
                                <a-card-text class="px-2 py-2 d-flex align-center">
                                    <div>
                                        <a-icon size="20" class="ma-0" :color="select_user_name ? 'blue' : 'grey darken-1'">filter_list</a-icon>
                                        <span class="md-body-2 grey--text text--darken-2 mx-1">
                                            <span class="font-weight-medium">User:</span> {{ select_user_name ? select_user_name : 'All Users' }}
                                        </span>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <a-divider></a-divider>
                        <a-card class="c-search-inputs-inventory u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                            <a-sheet class="pa-3">
                                <a-text-field
                                    v-model="local_search_user"
                                    placeholder="Search Users"
                                    background-color="grey lighten-4"
                                    ref="search_user_input"
                                    class="md-body-2 u-border"
                                    solo flat hide-details autofocus
                                    @click="filter_mode = false"
                                    @input="localSearchUser"
                                ></a-text-field>
                            </a-sheet>
                            <a-divider class="grey lighten-5"></a-divider>
                            <a-list style="max-height: 400px;" class="overflow-y-auto pt-0">
                                <a-list-item class="py-1" v-if="local_user_loading" disabled>
                                    <a-list-item-content>
                                        <a-list-item-title class="md-subtitle-1 text-center">Loading Users...</a-list-item-title>
                                    </a-list-item-content>
                                </a-list-item>
                                <template v-else>
                                    <a-list-item @click="local_filter_tokens(filters.USER, null)">
                                        <div class="u-flex align-center py-2">
                                            <a-avatar size="38" color="white" class="c-shadow--light">
                                                <a-avatar size="36" color="grey lighten-3">
                                                    <a-icon size="18">people</a-icon>
                                                </a-avatar>
                                            </a-avatar>
                                            <h5 class="md-subtitle-1 grey--text text--darken-2 ml-4">All Users</h5>
                                        </div>
                                    </a-list-item>
                                    <a-list-item class="py-0" v-for="(item, index) in user_list" :key="'user' + index" @click="local_filter_tokens(filters.USER, item.id, item.name)">
                                        <a-list-item-content class="py-2" :title="(item.user && !item.user.is_active) ? 'Blocked' : ''">
                                            <div class="u-flex align-center">
                                                <g-avatar :item="item"></g-avatar>
                                                <g-profile-info :item="item" set-max-width type-user></g-profile-info>
                                            </div>
                                        </a-list-item-content>
                                    </a-list-item>
                                    <a-list-item class="py-1" v-if="user_list && user_list.length === 0" disabled>
                                        <a-list-item-content>
                                            <a-list-item-title class="md-subtitle-1 text-center">
                                                {{ local_user_loading ? 'Loading Users...' : 'No users found' }}
                                            </a-list-item-title>
                                        </a-list-item-content>
                                    </a-list-item>
                                </template>
                            </a-list>
                        </a-card>
                    </a-menu>
                </a-flex>
                <a-flex shrink pr-1>
                    <a-menu bottom offset-y :close-on-content-click="true" min-width="232" max-width="300" max-height="300" transition="slide-y-transition" class="d-block">
                        <template v-slot:activator="{ on }">
                            <a-card class="u-rounded-corners u-overflow-hidden c-border-c7 u-elevation-custom-1" min-width="100%" v-on="on" >
                                <a-card-text class="px-2 py-2 d-flex align-center">
                                    <div>
                                        <a-icon size="20" class="ma-0" :color="(select_session_status && select_session_status !== session_status_list[0].label) ? 'blue' : 'grey darken-1'">filter_list</a-icon>
                                        <span class="md-body-2 grey--text text--darken-2 mx-1">
                                            <span class="font-weight-medium">Session:</span> {{ (select_session_status && select_session_status !== session_status_list[0].label) ? select_session_status : session_status_list[0].label }}
                                        </span>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <a-divider></a-divider>
                        <a-card class="c-search-inputs-inventory u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                            <h2 class="md-caption text-uppercase font-weight-medium grey--text text--darken-2 px-4 pt-3 pb-2" style="letter-spacing: 0.8px;">Session Status</h2>
                            <a-list class="u-list-condensed overflow-y-auto pt-0">
                                <a-list-item
                                    v-for="item in session_status_list"
                                    :key="'status' + item.id"
                                    class="py-0"
                                    :class="{ 'grey lighten-4': select_session_status === item.label }"
                                    @click="local_filter_tokens(filters.SESSION_STATUS, item.value, item.label)"
                                >
                                    <a-list-item-content class="py-2">
                                        <a-list-item-title class="grey--text text--darken-3">{{ item.label }}</a-list-item-title>
                                    </a-list-item-content>
                                </a-list-item>
                            </a-list>
                        </a-card>
                    </a-menu>
                </a-flex>
                <a-flex shrink px-1 v-if="select_user_name || select_session_status">
                    <a-tooltip right>
                        <template v-slot:activator="{ on }">
                            <a-card v-on="on" @click="local_token_filter_clear()" flat class="u-rounded-corners-full u-overflow-hidden transparent u-flex align-center justify-center" width="22" height="22">
                                <a-icon color="grey darken-3" size="20" class="u-cursor-pointer">clear</a-icon>
                            </a-card>
                        </template>
                        <span>Clear filter</span>
                    </a-tooltip>
                </a-flex>
                <a-divider class="grey lighten-2 mx-3 ml-4"></a-divider>
                <a-flex shrink>
                    <a-card class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 u-cursor-pointer px-2 pl-3 py-1 u-flex align-center" height="38" @click="local_filter_tokens(filters.SORT, (token_filter[filters.SORT] === '-created_at') ? 'created_at' : '-created_at', select_user_name)">
                        <span class="md-body-2 grey--text text--darken-2"><span class="font-weight-medium">Attempted:</span> {{ (token_filter[filters.SORT] === 'created_at') ? 'Ascending' : 'Descending' }}</span>
                        <a-icon size="18" class="ml-1 u-icon-nudge" :style="{ 'top': token_filter[filters.SORT] === 'created_at' ? '0.5px' : '-1px'}">{{ (token_filter[filters.SORT] === 'created_at') ? 'arrow_downward' : 'arrow_upward' }}</a-icon>
                    </a-card>
                </a-flex>
            </a-layout>

            <!-- Loading and No data message -->
            <a-responsive class="my-4" v-if="local_token_loading">
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Logs..</h3>
            </a-responsive>
            <a-responsive class="my-4" v-if="!local_token_loading && token_list.length === 0">
                <a-divider class="grey lighten-2 mb-4"></a-divider>
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
            </a-responsive>

            <!-- List Table -->
            <a-layout v-if="token_list && token_list.length">
                <a-flex xs12 pt-0>
                    <div class="" style="border-radius: 4px">
                        <table class="c-table u-wfull u-relative">
                            <tr class="c-table__header-row grey lighten-5 u-elevation-custom-1 u-rounded-corners" style="border-bottom: 1px solid #ececec !important;">
                                <th class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-4 pr-4 pl-7" :style="[table_style.user]" style="letter-spacing: 1px; border-top-left-radius: 4px; border-bottom-left-radius: 4px;">
                                    <div class="u-flex align-center">
                                        <a-sheet width="40" height="8" class="transparent u-rounded-corners-full"></a-sheet>
                                        <span class="ml-2">User</span>
                                    </div>
                                </th>
                                <th class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-4 px-4" v-if="!$vuetify.breakpoint.lgAndDown" :style="[table_style.role]" style="letter-spacing: 1px;">Role</th>
                                <th class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-4 px-4" :style="[table_style.attempted]" style="letter-spacing: 1px;">Attempted</th>
                                <th class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-4 px-4" :style="[table_style.verified]" style="letter-spacing: 1px;">Verified</th>
                                <th class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-4 px-4 pl-5" :style="[table_style.expires]" style="letter-spacing: 1px;">Expires</th>
                                <th class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-right py-4 px-4 pr-6" :style="[table_style.session]" style="letter-spacing: 1px; border-top-right-radius: 4px; border-bottom-right-radius: 4px;">Session</th>
                            </tr>
                            <tr class="c-table__content-row py-2 u-rounded-corners u-elevation-custom-1 white"  style="border-bottom: 1px solid #ececec !important;" v-for="(item) in token_list" :key="item.id">
                                <td class="md-caption grey--text text--darken-2 text-left py-3 px-4 pl-5" style="border-top-left-radius: 4px; border-bottom-left-radius: 4px;" :title="(item.user && !item.user.is_active) ? 'Blocked' : ''" :class="{'pl-2': $vuetify.breakpoint.xlOnly}" :style="[table_style.user]">
                                    <div class="u-flex" :class="[$vuetify.breakpoint.lgAndDown ? 'align-start' : 'align-center']">
                                        <g-avatar :item="item" :right-space="false" :size="40"></g-avatar>
                                        <div class="ml-3">
                                            <g-profile-info :item="item.user" type-user hide-role></g-profile-info>
                                            <p class="md-caption grey--text text--darken-2 mb-0">{{ item.user.email }}</p>
                                            <p class="md-caption grey--text text--darken-1 mt-1 mb-0" v-if="item.user.role && $vuetify.breakpoint.lgAndDown">{{ item.user.role.name }}</p>
                                        </div>
                                    </div>
                                </td>
                                <td class="md-caption grey--text text--darken-2 text-left text-truncate py-3 px-4" style="max-width: 1px;" v-if="!$vuetify.breakpoint.lgAndDown" :style="[table_style.role]">
                                    {{ item.user.role ? item.user.role.name : '-' }}
                                </td>
                                <td class="md-caption grey--text text--darken-2 text-left py-3 px-4" :style="[table_style.attempted]">
                                    <g-moment :value="item.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM D, YYYY h:mma" convert-local></g-moment>
                                </td>
                                <td class="md-caption grey--text text--darken-2 text-left py-3 px-4 pl-5" :style="[table_style.verified]">
                                    <g-moment v-if="item.verified_at" :value="item.verified_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM D, YYYY h:mma" convert-local></g-moment>
                                   <template v-else> - </template>
                                </td>
                                <td class="md-caption grey--text text--darken-2 text-left py-3 px-4 pl-5" :style="[table_style.expires]">
                                    <g-moment v-if="item.expires_at" :value="item.expires_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM D, YYYY h:mma" convert-local></g-moment>
                                   <template v-else> - </template>
                                </td>
                                <td class="md-caption grey--text text--darken-2 text-right py-3 px-4 pr-6" style="border-top-right-radius: 4px; border-bottom-right-radius: 4px;" :style="[table_style.session]">
                                    <a-sheet v-if="item.verified_at && local_check_expires_status(item.expires_at)" width="80" color="#1dad47" class="py-1 px-2 font-weight-medium text-center d-inline-block u-rounded-corners-full white--text">Active</a-sheet>
                                    <a-sheet v-if="item.verified_at && !local_check_expires_status(item.expires_at)" width="80" color="#c1c1c1" class="py-1 px-2 font-weight-medium text-center d-inline-block u-rounded-corners-full white--text">Expired</a-sheet>
                                    <a-sheet v-if="!item.verified_at && local_check_expires_status(item.expires_at)" width="80" color="#f38d00" class="py-1 px-2 font-weight-medium text-center d-inline-block u-rounded-corners-full white--text">Attempted</a-sheet>
                                    <a-sheet v-if="!item.verified_at && !local_check_expires_status(item.expires_at)" width="80" color="#717171" class="py-1 px-2 font-weight-medium text-center d-inline-block u-rounded-corners-full white--text">Unverified</a-sheet>
                                </td>
                            </tr>
                        </table>
                    </div>
                </a-flex>
            </a-layout>

            <!-- Load More -->
            <a-layout wrap align-start v-if="!local_token_loading">
                <a-flex xs12 class="u-flex align-center">
                    <a-btn v-if="token_meta && (token_meta.current_page < token_meta.last_page)" :loading="loading" :disabled="loading" @click="local_token_paginate(token_meta.current_page + 1)" small text class="primary" dark>
                        Load More
                    </a-btn>
                    <a-spacer></a-spacer>
                    <span class="md-body-2 grey--text text--darken-1" v-if="token_meta.total">Showing <strong>{{ token_list.length }}</strong> out of <strong>{{ token_meta.total }}</strong> logs</span>
                </a-flex>
            </a-layout>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            local_search_user: '',
            search_user: false,
            select_user_name : null,
            token_filter: {},
            token_count: 100,
            filters: {
                SORT: 'sort',
                PAGE: 'page',
                TOKEN_FIELDS: 'fields[tokens]',
                USER_FIELDS: 'fields[user]',
                USER_ROLE_FIELDS: 'fields[user.role]',
                INCLUDE: 'include',
                COUNT: 'count',
                USER: 'filter[user_id]',
                SESSION_STATUS: 'filter[session_status]',
                BEARER: 'filter[type]',
            },
            filter_mode: true,
            searchTimeout: null,
            local_filter: false,
            local_user_loading: false,
            local_token_loading: true,
            session_status_list : [
                { id: 1, label: 'All Session Status', value: 'all'},
                { id: 2, label: 'Attempted', value: 'attempted'},
                { id: 3, label: 'Unverified', value: 'unverified'},
                { id: 4, label: 'Active', value: 'active'},
                { id: 5, label: 'Expired', value: 'expired'},
            ],
            select_session_status: null,
        }
    },

    watch: {
        search_user (val, oldVal) {
            if (val) this.local_load_users()
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        table_style () {
            return {
                user: { width: (this.$vuetify.breakpoint.lgAndDown ?  320 + 'px' : 'auto') },
                role: { width: (this.$vuetify.breakpoint.lgAndDown ?  0 : 230 ) + 'px' },
                attempted: { width: (this.$vuetify.breakpoint.lgAndDown ?  180 : 250) + 'px' },
                verified: { width: (this.$vuetify.breakpoint.lgAndDown ?  180 : 250) + 'px' },
                expires: { width: (this.$vuetify.breakpoint.lgAndDown ?  160 : 250) + 'px' },
                session: { width: (this.$vuetify.breakpoint.lgAndDown ?  80 : 120) + 'px' },
            }
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
            user_list: 'list',
        }),

        ...mapState('Token', {
            token_list: 'list',
            token_meta: 'meta',
        }),
    },

    methods: {
        async local_index () {
            if (this.user_self && this.user_self.scope === 'external') {
                this.$router.replace({name: 'dashboard'})
            }
            await this.token_clear()
            this.local_load_token()
        },

        async local_load_token () {
            this.token_filter[this.filters.PAGE] = 1
            this.token_filter[this.filters.SESSION_STATUS] = 'all'
            this.token_filter[this.filters.TOKEN_FIELDS] = 'id,verified_at,created_at,expires_at,user_id'
            this.token_filter[this.filters.COUNT] = this.token_count
            this.token_filter[this.filters.INCLUDE] = 'user,user.role'
            this.token_filter[this.filters.USER_FIELDS] = 'id,name,email,avatar,initial,color,scope,role_id,resource_type,is_active'
            this.token_filter[this.filters.USER_ROLE_FIELDS] = 'id,name'
            this.token_filter[this.filters.SORT] = '-created_at'
            this.token_filter[this.filters.USER] = null
            this.token_filter[this.filters.BEARER] = 'session'
            await this.token_index({...this.token_filter})
            this.local_token_loading = false
        },

        localSearchUser (value) {
            this.local_user_loading = true
            clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(() => {
                this.local_load_users(value)
            }, 500)
        },

        async local_load_users (value) {
            const params = {
                'include': 'role',
                'fields[users]':'id,name,email,avatar,initial,color,scope,role_id,resource_type,is_active',
                'fields[role]':'id,name',
                'filter[name]': value || '',
                'filter[scope]': 'internal,external',
                'sort': 'name', 'count': 20,
            }
            this.local_user_loading = true
            await this.user_index(params)
            this.local_user_loading = false
        },

        async local_token_filter_clear () {
            if (this.local_filter) {
                this.local_load_token()
                this.local_filter = false
                this.select_user_name = null
                this.select_session_status = null
            }
        },

        async local_filter_tokens (type, value, name = null) {
            if (type === 'filter[user_id]' || type === 'sort') {
                this.token_filter[this.filters.PAGE] = 1
                this.token_filter[this.filters.COUNT] = this.token_count
                this.select_user_name = name
            }
            if (type === 'filter[session_status]') {
                this.select_session_status = name
            }
            this.filter_mode = true
            this.token_filter[type] = value
            await this.token_index({...this.token_filter})
            this.local_filter = true
        },

        async local_token_paginate (value) {
            this.token_filter[this.filters.PAGE] = value
            this.token_filter.load = 'append'
            await this.token_index({...this.token_filter})
            this.token_filter.load = null
        },

        local_check_expires_status (date) {
            return moment().diff(date) < 0
        },

        ...mapActions('Token', {
            token_index: 'index',
            token_clear: 'clear',
            token_clear_item: 'clear_item',
        }),

        ...mapActions('User', {
            user_index: 'index',
            user_clear: 'clear',
            user_clear_item: 'clear_item',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .c-table {
        display: table !important;
        border-collapse: separate;
        border-spacing: 0px 8px;
        &__view-more { color: #757575 !important; }
        &__content-row {
            &:hover {
                background-color: #fafafa !important;
                .c-table__view-more { color: #2d99ff !important; }
            }
        }
        &__cell {
            display: table-cell !important;
            vertical-align: middle !important;
            white-space: nowrap !important;
        }
    }
</style>

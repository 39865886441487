export const storage = {
    save: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
    remove: (key) => localStorage.removeItem(key),
    removeAll: () => localStorage.clear(),
    get: (key) => {
        const item = localStorage.getItem(key)
        return item ? JSON.parse(item) : null
    },
    removeOrgKeys: () => {
        const keys = [
        'validWorkspaces',
        'plansList',
        'orgPlan',
        'mePreference',
        'orgPreference',
        'recentProjectSearch',
        'recentRouteSearch'
    ]
        keys.forEach(key => storage.remove(key))
    }
}

<template>
	<a-main class="px-6">
        <!--<a-container grid-list-xl container--fluid class="pa-0 ma-0 my-2 mb-10" v-if="local_loading">
            <a-layout align-center class="mt-6">
                <a-flex>
                    <loader-template height="32" width="150" class="u-rounded-corners"></loader-template>
                    <loader-template height="16" width="300" class="u-rounded-corners mt-3"></loader-template>
                </a-flex>
                <a-flex shrink v-if="$can('applets.update')">
                    <loader-template height="40" width="250" class="u-rounded-corners"></loader-template>
                </a-flex>
            </a-layout>
        </a-container>-->

        <a-container grid-list-xl container--fluid class="pa-0 ma-0 mb-2 mb-10">
            <a-layout align-center>
                <a-flex>
                    <h2 class="md-heading-5 font-weight-semibold">Workflows</h2>
                    <p class="md-body-2 mb-0 grey--text text--darken-2">Create automated workflows to run in the background.</p>
                </a-flex>
                <a-flex shrink>
                    <loader-template v-if="local_loading" height="40" width="250" class="u-rounded-corners"></loader-template>
                    <a-btn v-if="!local_loading && $can('applets.update') && (workflow_list && workflow_list.length > 0)" depressed color="primary" class="mr-0" @click="local_workflow_store()">
                        <a-icon class="mr-1">add</a-icon> Create Workflow
                    </a-btn>
                </a-flex>
            </a-layout>

            <a-layout align-center justify-center v-if="!loading && workflow_list && workflow_list.length === 0">
                <a-flex xs4>
                    <div class="text-center"><a-icon size="160" color="grey lighten-1">device_hub</a-icon></div>
                    <h2 class="md-heading-5 mb-1 grey--text text--darken-3 text-center">No Workflows</h2>
                    <p class="md-body-2 mb-2 grey--text text--darken-1 text-center">Setup the workflow to automate data sharing between Salesforce and Success.</p>
                    <div class="text-center" v-if="$can('applets.update')"><a-btn dark color="blue darken-1" @click="local_workflow_store()">Create Workflow</a-btn></div>
                </a-flex>
            </a-layout>

            <a-layout align-center>
                <a-flex>
                    <div v-if="local_loading" class="white pa-4 u-rounded-corners u-shadow">
                        <template v-for="i in 5">
                            <a-sheet :key="i" class="u-flex-center-y" style="column-gap: 16px;" :class="[{ 'mt-4': i !== 1 }]">
                                <loader-template height="40" width="100%" class="u-rounded-corners"></loader-template>
                                <loader-template height="40" width="40" class="u-rounded-corners"></loader-template>
                                <loader-template height="40" width="40" class="u-rounded-corners"></loader-template>
                            </a-sheet>
                        </template>
                    </div>

                    <template v-if="!local_loading && (workflow_list && workflow_list.length > 0)">
                        <template v-if="mixinGetOrgPlan({ field: 'subscription_plan.level' }) > 1">
                            <template v-for="workflow in workflow_list">
                                <PartialWorkflowListItem
                                    :item="workflow"
                                    :installationItem="installation_item"
                                    :key="workflow.id"
                                    @clone="local_saleforce_clone_create(workflow.id)"
                                    @destroy="local_workflow_destroy(workflow.id)"
                                />
                            </template>
                        </template>

                        <template v-if="mixinGetOrgPlan({ field: 'subscription_plan.level' }) === 1">
                            <!-- Allowed workflows for current plan -->
                            <template v-for="workflow in localAllowedWorkflows">
                                <PartialWorkflowListItem
                                    :item="workflow"
                                    :installationItem="installation_item"
                                    :key="workflow.id"
                                    @clone="local_saleforce_clone_create(workflow.id)"
                                    @destroy="local_workflow_destroy(workflow.id)"
                                />
                            </template>

                            <!-- Disabled workflows for current plan -->
                            <div class="mt-10" v-if="localDisabledWorkflows && localDisabledWorkflows.length">
                                <div class="u-flex-center-y mb-4">
                                    <h3 class="md-subtitle-1 mr-2 font-weight-medium grey--text text--darken-2">Disabled Workflows </h3>
                                    <span class="md-body-2 grey--text text--darken-2 mr-4">(Upgrade to <span class="blue--text text--darken-2">Professional</span> to access these workflows)</span>
                                    <a-divider class="grey lighten-2"></a-divider>
                                </div>
                                <template v-for="workflow in localDisabledWorkflows">
                                    <PartialWorkflowListItem
                                        :item="workflow"
                                        :installationItem="installation_item"
                                        :key="workflow.id"
                                        disabled
                                    />
                                </template>
                            </div>
                        </template>
                    </template>
                </a-flex>
            </a-layout>
        </a-container>

        <a-dialog v-model="dialog_saleforce_clone_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="40">
                                    <a-icon dark>content_copy</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1">Clone Workflow</h2>
                                <p class="md-subtitle-2 mb-0">Create a copy of this workflow</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title</label>
                                <a-text-field
                                    v-model="workflow_item.title"
                                    placeholder="Enter the title"
                                    background-color="neutral"
                                    solo flat hide-details>
                                </a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(workflow_response,'title')">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ $response(workflow_response,'title') }}
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16">info</a-icon>
                                    This will help you identify the workflow
                                </span>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink>
                                <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_workflow_clone()" :loading="loading" :disabled="loading" >Clone Workflow</a-btn>
                                <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_workflow_clone_cancel()">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
       </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PartialWorkflowListItem from './Partials/PartialWorkflowListItem.vue'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'

export default {
    mixins: [mixinGetOrganizationPlan],

    components: {
        PartialWorkflowListItem
    },

    data () {
        return {
            local_loading: true,
            dialog_saleforce_clone_form: false,
            workflow_types: [
                {
                    slug: 'pull-projects',
                    direction: 'Incoming',
                    title: 'Import Project from Salesforce Opportunity',
                    subtitle: 'Setup workflow for creating project in Success from Salesforce opportunity.',
                },
                {
                    slug: 'push-status-updates',
                    direction: 'Outgoing',
                    title: 'Create New Entry in Salesforce on Project Status Updates',
                    subtitle: 'Push data to Salesforce when status update is posted in Success.',
                },
                {
                    slug: 'push-stage-changes',
                    direction: 'Outgoing',
                    title: 'Create New Entry in Salesforce on Project Stage Changes',
                    subtitle: 'Push data to Salesforce when project stage is changed in Success.',
                },
                {
                    slug: 'push-stage-changes-update',
                    direction: 'Outgoing',
                    title: 'Update Opportunity in Salesforce on Project Stage Changes',
                    subtitle: 'Update Salesforce opportunity when project stage is changed in Success.',
                }
            ]
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        localAllowedWorkflows () {
            return this.workflow_list.filter(item => item.trigger ? (this.mixinGetOrgPlan({ field: 'subscription_plan.level' }) >= item.trigger.plan_level) : item)
        },

        localDisabledWorkflows () {
            return this.workflow_list.filter(item => item.trigger ? (item.trigger.plan_level < this.mixinGetOrgPlan({ field: 'subscription_plan.level' })) : null)
        },

        ...mapState('Installation', {
            installation_item: 'item',
        }),

        ...mapState('InstallationAccount', {
            installation_account_list: 'list',
            installation_account_filters: 'filters',
        }),

        ...mapState('Workflow', {
            workflow_item: 'item',
            workflow_list: 'list',
            workflow_filters: 'filters',
            workflow_response:'response'
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index () {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.local_loading = false
                return this.$router.replace({name: 'errors-unauthorized'})
            }

            if (!this.mixinGetOrgPlan()) this.mixinFetchOrgPlanDetails()

            await this.workflow_index({ 'filter[installation_id]': this.$route.params.id, include: 'trigger', 'fields[trigger]': 'id,slug,plan_level' })
            this.local_loading = false
        },

        async local_installation_account_destroy (id) {
            await this.installation_account_destroy({id: id});
            await this.local_index()
        },

        async local_workflow_store () {
            await this.workflow_clear_item()
            await this.workflow_store({ installation_id: this.installation_item.id, 'order': -1 })
            this.$router.push({name: `appstore-salesforce-workflows-create`, params: { id: this.installation_item.id, workflow_id: this.workflow_item.id }})
        },

        async local_workflow_destroy (id) {
            await this.workflow_destroy({id: id})
        },

        local_workflow_type_attribute (slug, property) {
            return _.get(_.find(this.workflow_types, {slug: slug}), property)
        },

        async local_saleforce_clone_create (id){
            await this.workflow_select({id: id})
            this.workflow_item.title = 'Copy of ' + this.workflow_item.title
            this.dialog_saleforce_clone_form = true
        },

        async local_workflow_clone () {
            await this.workflow_clone({title:this.workflow_item.title,workflow_id:this.workflow_item.id})
            if (!this.$status(this.workflow_response)) return
            this.dialog_saleforce_clone_form = false
            this.$notify('success', 'Workflow cloned successfully!')
        },

        async local_workflow_clone_cancel () {
            await this.workflow_clear_item()
            this.dialog_saleforce_clone_form = false
        },

        ...mapActions('Installation', {
            installation_show: 'show',
            installation_clear: 'clear',
            installation_clear_item: 'clear_item',
        }),

        ...mapActions('InstallationAccount', {
            installation_account_index: 'index',
            installation_account_destroy: 'destroy',
            installation_account_clear: 'clear',
        }),

        ...mapActions('Workflow', {
            workflow_index: 'index',
            workflow_store: 'store',
            workflow_select:'select',
            workflow_clone: 'clone_store',
            workflow_destroy: 'destroy',
            workflow_clear: 'clear',
            workflow_clear_item: 'clear_item',
        }),
    },
}
</script>

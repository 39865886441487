<template>
    <a-dialog v-model="dialogAutomationErrorPopup" persistent scrollable max-width="760">
        <a-card>
            <a-sheet class="pa-6">
                <div class="u-flex u-wfull">
                    <div class="u-flex">
                        <a-icon color="amber darken-3" class="u-icon-nudge" size="32" style="top: 1px;">error</a-icon>
                        <p class="md-subtitle-1 amber--text text--darken-3 mb-0 mt-0 px-2" style="font-weight: 600 !important; font-size: 24px !important;">
                            Validation Failed - 
                            <span v-if="automation_workflow_hook_response && automation_workflow_hook_response.trigger && automation_workflow_hook_response.trigger.slug && ['project-stage-changed', 'project-completed'].includes(automation_workflow_hook_response.trigger.slug)">Stage change</span>
                            <span v-else>Archive project</span>
                        </p>
                    </div>
                    <a-spacer></a-spacer>
                </div>
            </a-sheet>
            <a-divider class="grey lighten-3"></a-divider>
            <a-card-text class="pa-0 ma-0" style="max-height: 700px;">
                <a-sheet class="pa-0">
                    <div class="u-flex pa-6">
                        <p class="pink--text mb-0 font-weight-bold text-uppercase" style="font-size: 16px;">
                            <a-icon color="pink" class="mr-1" size="24">fact_check</a-icon> Validation Rules
                        </p>
                        <a-spacer></a-spacer>
                        <p v-if="localHasValidationPassed" class="u-cursor-pointer grey--text text--darken-3 mb-0 font-weight-bold text-uppercase" style="font-size: 14px;" @click="localUpdateErrorStatus()">
                            <a-icon :color="localErrorStatus ? 'green darken-1' : 'grey darken-1'" class="u-icon-nudge mr-2" size="28">{{ localErrorStatus ? 'toggle_on' : 'toggle_off' }}</a-icon>
                            Show passed
                        </p>
                    </div>
                    <div class="u-flex px-6">
                        <p class="md-subtitle-1 grey--text text--darken-1 font-weight-medium mb-0" style="font-size: 16px;" v-if="automation_workflow_hook_response && automation_workflow_hook_response.trigger && ['project-stage-changed', 'project-completed'].includes(automation_workflow_hook_response.trigger.slug)">
                            <strong>{{ automation_workflow_hook_response.stage_from }}</strong>  
                            <a-icon size="16" color="grey darken-1" class="mx-1">arrow_forward</a-icon>
                            <strong>{{ automation_workflow_hook_response.stage_to }}</strong> stage change action could not be performed due to the following validation errors,
                        </p>
                        <p class="md-subtitle-1 ml-2 grey--text text--darken-1 font-weight-medium mb-0" v-else>
                            You cannot <strong>Archive</strong> this project from <strong>{{ automation_workflow_hook_response.stage_from }}</strong> due to the following validation errors,
                        </p>
                    </div>
                    <div class="px-6 pb-6 mt-6">
                        <div class="c-rule-card-view" v-for="(workflow_group, workflow_index) in localValidationWorkflowGroupList" :key="workflow_group.workflow_id" :class="[workflow_index !== 0 ? 'mt-7': '']">
                            <div class="u-flex pa-6">
                                <p :title="workflow_group.workflow" class="mb-0 font-weight-medium indigo--text text--darken-1" style="font-size: 16px;">
                                    <a-icon color="indigo darken-1" class="u-icon-nudge mr-1" size="20">device_hub</a-icon>
                                    {{ workflow_group.workflow | truncateText(70) }}
                                </p>
                                <a-spacer></a-spacer>
                                <a-icon :color="localGetWorkflowFailedCount(workflow_group.workflow_id) ? 'green darken-1' : 'red darken-1'" size="24">{{ localGetWorkflowFailedCount(workflow_group.workflow_id) ? 'check_circle' : 'dangerous' }}</a-icon>
                            </div>
                            <a-divider class="grey lighten-3"></a-divider>
                            <div v-for="(validation_group, group_index) in localValidationGroupList(workflow_group.workflow_validations)" :key="group_index">
                                <div class="px-6">
                                    <a-sheet v-if="group_index !== 0" height="1" class="u-wfull u-relative c-validation-or-shreet">
                                        <span class="u-absolute md-body-2 font-weight-medium body--text text--lighten-2 d-inline-block c-validation-or-card">OR</span>
                                    </a-sheet>
                                </div>
                                <div class="px-6 py-5" :key="group_index" @click="localAccordionValidationToggle(validation_group.group_id)">
                                    <div class="u-flex u-cursor-pointer u-relative">
                                        <div class="u-relative c-error-view" v-if="localGetValidationHasFailed(validation_group.group_id)">
                                            <div class="c-error-view-child"></div>
                                        </div>
                                        <div class="u-relative c-validation-view" v-else>
                                            <div class="c-validation-success-view-child"></div>
                                        </div>
                                        <p class="ml-2 grey--text text--darken-3 mb-0 font-weight-bold text-uppercase" style="font-size: 16px;">Rule set {{ group_index +1 }}</p>
                                        <a-spacer></a-spacer>
                                        <div class="u-flex u-absolute c-logs-status-count">
                                            <div class="c-error-count-view py-1 px-2 ml-4" v-if="localGetValidationHasFailed(validation_group.group_id)">
                                                <p class="text-center red--text text--darken-1 mb-0 text-uppercase" style="font-size: 12px;"> {{ localGetValidationFailedCount(validation_group.group_id) }} Failed</p>
                                            </div>
                                            <div class="c-validation-success-count-view py-1 px-2" v-else>
                                                <p class="text-center green--text text--darken-1 mb-0 text-uppercase" style="font-size: 12px;"> {{ localGetValidationPassedCount(validation_group.group_id) }} Passed</p>
                                            </div>
                                        </div>
                                        <a-btn class="grey darken-1" small icon width="24" height="24">
                                            <a-icon color="white" v-if="localAccordionValidationIsOpen(validation_group.group_id)" size="18">expand_less</a-icon>
                                            <a-icon v-else color="white" size="18">expand_more</a-icon>
                                        </a-btn>
                                    </div>
                                </div>
                                <div class="px-3 mb-7" v-if="localAccordionValidationIsOpen(validation_group.group_id)">
                                    <div class="grey lighten-5 u-rounded-corners-2xl py-4">
                                        <ul class="c-validation-logs px-3 mt-2">
                                            <li v-for="(item, index) in validation_group.validations" :key="index">
                                                <div class="c-validation-log-icon">
                                                    <a-icon :color="item.is_valid ? 'green': 'red darken-1'" size="20">{{ item.is_valid ? 'check_circle' : 'dangerous' }}</a-icon>
                                                </div>
                                                <div class="c-validation-log-text">
                                                    <p class="md-subtitle-2 grey--text text--darken-3 mb-0 font-weight-medium mr-4">
                                                        {{ item.message }}
                                                    </p>
                                                    <span class="md-subtitle-2 mr-1 grey--text text--darken-1">Current value:</span>
                                                    <template v-if="item.field_type === 'date'">
                                                        <g-moment tag="p" class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium" :value="item.data[0]" v-if="item.data[0]" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY" convert-local></g-moment>
                                                        <p class="md-subtitle-2 grey--text mb-0 d-inline-block font-weight-medium" v-else>Field is empty</p>
                                                    </template>
                                                    <template v-else-if="item.field_type === 'multi-select'">
                                                        <a-tooltip bottom :disabled="!item.data.length" max-width="660">
                                                            <template v-slot:activator="{ on }">
                                                                <p v-on="on" class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium" :class="[item.data.length ? 'u-underline' : '']">{{ item.data.length }} {{ item.field_name }}</p>
                                                            </template>
                                                            <div class="pa-1 md-body-2" v-if="item.data.length">
                                                                <span v-for="(data, index) in item.data" :key="index" class="mb-1 pr-1">{{ data }}
                                                                    <span v-if="item.data[item.data.length - 1] !== data">,</span>
                                                                </span>
                                                            </div>
                                                        </a-tooltip>
                                                    </template>
                                                    <p v-else-if="item.field_type === 'select'" class="d-inline-block md-subtitle-2 grey--text mb-0 font-weight-medium mr-4"> {{ item.data[0] ? item.data[0] : 'Field is empty' }}</p>
                                                    <template v-else>
                                                        <p class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium mr-4" v-if="item.data[0]">{{ item.data[0] }}</p>
                                                        <p class="md-subtitle-2 d-inline-block grey--text mb-0 font-weight-medium" v-else>Field is empty</p>
                                                    </template>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-sheet>
            </a-card-text>
            <a-divider class="grey lighten-3"></a-divider>
            <div class="u-flex pa-6">
                <template v-if="$route.name !== 'projects-overview' && $route.name !== 'projects-settings'">
                    <a-btn class="indigo darken-1 white--text u-rounded-corners elevation-0" @click="localGoTo()" >Go To Project</a-btn>
                    <a-btn  depressed text class="ml-4 grey lighten-3 grey--text text--darken-1 u-rounded-corners" @click="localClose()">
                        Close
                    </a-btn>
                </template>
                <template v-else>
                    <a-btn class="indigo darken-1 white--text u-rounded-corners elevation-0" @click="localClose()">Close</a-btn>
                </template>
            </div>
        </a-card>
    </a-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    inheritAttrs: false,

    mounted () {
        this.$getAutomationWarning()
    },

    data () {
        return {
            dialogAutomationErrorPopup: false,
            localErrorGroupIndex: 0,
            localErrorStatus: false,
            triggers : [
                { type: "project-created", icon: 'add_box'},
                { type: "project-updated", icon: 'border_color'},
                { type: "project-status-updated", icon: 'donut_large'},
                { type: "project-upsert", icon: 'add_box'},
                { type: "project-stage-changed", icon: 'label_important'},
                { type: "project-completed", icon: 'task_alt'},
                { type: "project-archived", icon: 'archive'},
            ],
            localValidationGroupData: [],
            accordionValidation: [],
        }
    },


    watch: {
        dialogAutomationErrorPopup (newValue) {
           if (newValue) {
                this.accordionValidation = []
                this.localCheckWorkflowFaild()
           }
        }
    },

    computed: {
        localValidationFailedList () {
            if (this.automation_workflow_hook_response && !this.automation_workflow_hook_response.data) return []

            if (this.localErrorStatus === false) {
                return this.automation_workflow_hook_response.data.filter(item => item.is_valid === 0 || item.is_valid === false)
            }
            return this.automation_workflow_hook_response.data
        },

        localHasValidationPassed () {
            if (this.automation_workflow_hook_response && !this.automation_workflow_hook_response.data) return false

            const passed = this.automation_workflow_hook_response.data.filter(item => item.is_valid === 1 || item.is_valid === true)
            return passed.length ? true : false
        },

        localValidationWorkflowGroupList () {
            if (this.automation_workflow_hook_response && !this.automation_workflow_hook_response.data) return this.localValidationGroupData

            this.localValidationGroupData = this.localValidationFailedList.reduce((prev, current) => {
                const isAvail = prev.find(item => item.workflow_id === current.workflow_id)
                if (isAvail) {
                    current['workflow_id'] = current.workflow_id
                    current['workflow'] = current.workflow
                    isAvail.workflow_validations.push(_.cloneDeep(current))
                } else {
                    current['workflow_id'] = current.workflow_id
                    current['workflow'] = current.workflow
                    prev.push({
                        workflow_id: current.workflow_id,
                        workflow: current.workflow,
                        workflow_validations: [_.cloneDeep(current)]
                    })
                }
                return prev
            }, [])
            return this.localValidationGroupData
        },

        localValidationPassedList () {
            if (this.automation_workflow_hook_response && this.automation_workflow_hook_response.data) {
                return this.automation_workflow_hook_response.data.filter(list => list.is_valid === true || list.is_valid === 1)
            }
        },

        ...mapState('AutomationWorkflow', {
            automation_workflow_hook_response: 'hook_response',
        }),

    },

    methods: {
        localClose () {
            this.dialogAutomationErrorPopup = false
        },

        localGetValidationFailedCount (group_id) {
            if (this.automation_workflow_hook_response && !this.automation_workflow_hook_response.data) return 0
            
            const group = this.automation_workflow_hook_response.data.filter(item => item.group === group_id && (item.is_valid === 0 || !item.is_valid))
            return group.length
        },

        localGetValidationPassedCount (group_id) {
            if (this.automation_workflow_hook_response && !this.automation_workflow_hook_response.data) return 0 

            const group = this.automation_workflow_hook_response.data.filter(item => item.group === group_id && (item.is_valid === 1 || item.is_valid))
            return group.length
        },

        localGetValidationHasFailed (group_id) {
            if (this.automation_workflow_hook_response && !this.automation_workflow_hook_response.data) return false

            const group = this.automation_workflow_hook_response.data.filter(item => item.group === group_id && (item.is_valid === 0 || !item.is_valid))
            return group.length ? true : false
        },

        localGetWorkflowFailedCount (workflow_id) {
            if (this.automation_workflow_hook_response && !this.automation_workflow_hook_response.data) return

            const workflows = this.automation_workflow_hook_response.data.filter(item => item.workflow_id === workflow_id)
            var status = false
            workflows.forEach(workflow => {
                if (this.localGetValidationFailedCount(workflow.group) === 0) {
                    status = true
                }
            });
            return status
        },

        localCheckWorkflowFaild () {
            if (this.automation_workflow_hook_response && !this.automation_workflow_hook_response.data) return

            this.automation_workflow_hook_response.data.every(workflow => {
                if (this.localGetValidationFailedCount(workflow.group) !== 0) {
                    this.accordionValidation.push(workflow.group)
                    return false
                }
            });
        },

        localValidationGroupList (list) {
            const data = list.reduce((prev, current) => {
            const isAvail = prev.find(item => item.group_id === current.group)
                if (isAvail) {
                    current['group_id'] = current.group
                    isAvail.validations.push(_.cloneDeep(current))
                } else {
                    current['group_id'] = current.group
                    prev.push({
                        group_id: current.group,
                        validations: [_.cloneDeep(current)]
                    })
                }
                return prev
            }, [])
            return data
        },

        localUpdateErrorStatus () {
            this.localErrorStatus = !this.localErrorStatus
        },

        localSelectValidationGroupId (group_id) {
            this.localErrorGroupIndex = group_id
        },

        localGoTo () {
            if (this.automation_workflow_hook_response && this.automation_workflow_hook_response.source) {
                let route = this.$router.resolve({name: 'projects-overview', params: {id: this.automation_workflow_hook_response.source.id} });
                window.open(route.href);
            } 
        },

        localAccordionValidationToggle(group_id){
            const index = this.accordionValidation.findIndex(item => item === group_id)

            if (index !== -1) {
                this.accordionValidation.splice(index, 1)
                return
            }

            this.accordionValidation.push(group_id)
        },

        localAccordionValidationIsOpen(group_id){
            if (!this.accordionValidation.length) return false

            const index = this.accordionValidation.findIndex(item => item === group_id)
            return index >= 0 ? true : false
        },

        localGetTriggerIcon (type) {
            const trigger = this.triggers.find(item => item.type && item.type === type)
            return trigger ? trigger.icon : null
        },

        ...mapActions('AutomationWorkflow', {
            automation_workflow_hook_clear: 'hook_clear'
        }),
    },
}
</script>

<style lang="scss" scoped>
.c-validation-logs {
  margin: 0px;
  padding: 0;
  list-style: none;
  position: relative;
}
.c-validation-logs li {
  list-style: none;
  margin: auto;
  border-left: 2px solid #E0E0E0;
  min-height: 38px;
  padding-bottom: 16px;
  position: relative;
  left: 9px;
}
.c-validation-logs li:last-child {
    border: 0 !important;
    top: 0px;
    left: 11px;
    padding: 0px !important;
}
.c-validation-logs > li .c-validation-log-text {
    position: relative;
    top: -4px;
    left: 17px;
}
.c-validation-logs > li .c-validation-log-icon {
    top: -6px;
    position: absolute;
    left: -11px;
    margin: 0px;
}
.c-rule-card-view {
    border-radius: 12px !important;
    border: 1px solid #EEE !important;
}
.c-error-view {
    background-color: #FFEBEE !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    margin-top: 1px;
    margin-left: 0px;
}
.c-error-view-child {
    position: absolute;
    background: #E53935;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 4px;
    left: 4px;
}
.c-validation-view {
    background-color: #E8F5E9 !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    margin-top: 1px;
}
.c-validation-success-view-child {
    position: absolute;
    background: #43A047;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 4px;
    left: 4px;
}
.c-error-count-view {
    border-radius: 8px;
    border: 1px solid #FFCDD2;
    background: #FFEBEE;
    font-weight: 600;
    right: 47px;
    top: -4px;
}
.c-validation-success-count-view {
    border-radius: 8px;
    border: 1px solid #C8E6C9;
    background: #E8F5E9;
    position: relative;
    font-weight: 600;
}
.c-logs-status-count {
    right: 47px;
    top: -4px;
}
.c-validation-or-card {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 4px;
    background: #FFF;
    border: 1px solid #BDBDBD;
    font-size: 12px;
    font-weight: 600;
    color: #616161;
    width: 33px;
    height: 24px;
    padding: 0px 7px;
}
.c-validation-or-shreet {
    border-radius: 1px;
    background: radial-gradient(50% 50.00% at 50% 50.00%, #616161 0%, rgba(97, 97, 97, 0.08) 100%);
}
</style>

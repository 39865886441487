<template>
    <v-card-text v-bind="$attrs" v-on="$listeners" class="c-card-text">
        <slot></slot>
    </v-card-text>
</template>

<script>
import { VCardText } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VCardText },

    data () {
        return {
            ref: null
        }
    },

    mounted () {
        this.ref = this.$el
    },
}
</script>

<template>
	<a-main class="px-3 mb-16">
        <a-container grid-list-xl container--fluid class="mt-5 pa-0 ma-0">
            <a-layout align-center>
                <a-flex>
                    <h2 class="md-heading-5 font-weight-semibold">Settings</h2>
                    <p class="mb-0 md-body-2 grey--text text--darken-2">Manage workspace and library to sync calls.</p>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Loading State -->
        <PartialGongSettingsLoader v-if="localIsLoading" class="mt-6" />

        <template v-if="!localIsLoading">
            <div class="mt-6 u-flex-center-y" style="column-gap: 16px" v-if="localIsWorkspaceSelected">
                <!-- Refresh directory (workspace & it's subfolders) -->
                <div class="u-flex-center-y" style="column-gap: 6px">
                    <a-tooltip right content-class="c-tooltip-pointer c-tooltip-pointer--left">
                        <template v-slot:activator="{ on }">
                            <a-btn
                                v-on="on"
                                :loading="mixinIsLoading('refreshing') || mixinIsLoading('workspace-syncing')"
                                @click="localRefreshDirectory()"
                                height="36"
                                :disabled="!$can('applets.update') || mixinIsLoading('sync')"
                                class="px-3 py-2 white"
                                :class="[{ 'u-shadow': $can('applets.update') }]"
                                depressed
                            >
                                <div class="u-flex-center-y" style="column-gap: 4px;">
                                    <a-icon size="20" color="grey darken-2">refresh</a-icon>
                                    <span class="md-body-2 font-weight-medium grey--text text--darken-2">Refresh</span>
                                </div>
                            </a-btn>
                        </template>
                        <a-sheet class="transparent md-body-2 white--text" max-width="240">
                            Fetches latest workspace, folders and it's subfolders
                        </a-sheet>
                    </a-tooltip>
                    <span v-if="mixinIsLoading('refreshing') || mixinIsLoading('workspace-syncing')" class="ml-1 md-body-2 grey--text text--darken-1">
                        Refreshing...
                    </span>
                </div>

                <a-spacer></a-spacer>

                <!-- Last synced info -->
                <a-sheet v-if="meeting_info_list" class="px-3 py-2 u-flex-center-y transparent" height="36" style="column-gap: 8px; width: max-content">
                    <template v-if="meeting_info_list.gong_last_synced_at">
                        <div class="u-flex-center-y" style="column-gap: 4px;">
                            <a-icon size="20" color="grey darken-2">history</a-icon>
                            <h4 class="md-body-2 font-weight-medium grey--text text--darken-2">Last synced {{ meeting_info_list.gong_last_synced_at }}</h4>
                        </div>
                        <a-icon size="6" color="grey lighten-1">fiber_manual_record</a-icon>
                    </template>
                    <h4 class="md-body-2 font-weight-medium grey--text text--darken-2">{{ meeting_info_list.total_gong_calls }} Calls</h4>
                </a-sheet>
            </div>

            <!-- Select Workspaces -->
            <a-sheet class="px-6 py-4 mt-6 u-flex-center-y u-rounded-corners u-shadow">
                <div>
                    <h3 class="mb-1 md-subtitle-1 font-weight-medium grey--text text--darken-3">Workspace</h3>
                    <p class="mb-0 md-body-2 grey--text text--darken-2">Choose your preferred workspace to import calls.</p>
                </div>
                <a-spacer></a-spacer>
                <a-menu v-model="workspaceModel" :disabled="mixinIsLoading('sync') || !$can('applets.update')" bottom offset-y left>
                    <template v-slot:activator="{ on }">
                        <div
                            v-on="on"
                            v-ripple="$can('applets.update')"
                            class="px-2 py-1 u-rounded-corners grey lighten-4 white u-flex align-center u-cursor-pointer"
                            :class="[{ 'u-opacity-50': mixinIsLoading('sync') }]"
                            style="height: 40px; width: 30%;"
                        >
                            <h3 class="mx-1 md-body-2 grey--text text--darken-3 text-truncate">
                                <template v-if="selectedWorkspace && selectedWorkspace.id">
                                    {{ selectedWorkspace.name }}
                                </template>
                                <template v-else>Select the Workspace</template>
                            </h3>
                            <a-spacer></a-spacer>
                            <a-progress-circular v-if="localIsLoading === 'role'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                            <template v-else>
                                <a-icon v-if="$can('applets.update')" size="20" class="ma-0">arrow_drop_{{ workspaceModel ? 'up' : 'down' }}</a-icon>
                            </template>
                        </div>
                    </template>
                    <a-list class="u-list-condensed">
                        <a-sheet class="px-2 c-input-wrap">
                            <a-text-field
                                v-model="searchWorkspaceModel"
                                placeholder="Search Workspace"
                                background-color="neutral"
                                class="md-body-2 font-weight-normal"
                                solo flat hide-details autofocus
                            >
                            </a-text-field>
                        </a-sheet>
                    </a-list>
                    <a-list class="px-2 pt-0 u-list-condensed u-overflow-y white" style="max-height: 300px;">
                        <a-list-item
                            v-for="workspace in localFilteredWorkspaces"
                            :key="workspace.id"
                            :class="{ 'indigo lighten-5': selectedWorkspace && selectedWorkspace.id === workspace.id }"
                            :disabled="localIsLoading"
                            @click="localSelectWorkspace(workspace)"
                            class="px-2 py-1 u-rounded-corners"
                        >
                            <a-list-item-title>
                                <h3 class="md-body-2" :class="[selectedWorkspace && selectedWorkspace.id === workspace.id ? 'indigo--text text--darken-4' : 'grey--text text--darken-3']">
                                    {{ workspace.name }}
                                </h3>
                            </a-list-item-title>
                        </a-list-item>
                        <a-list-item v-if="searchWorkspaceModel && (localFilteredWorkspaces && !localFilteredWorkspaces.length)">
                            <a-list-item-title>
                                <span class="ml-1 md-body-2 grey--text text--darken-3">No Workspace Found</span>
                            </a-list-item-title>
                        </a-list-item>
                    </a-list>
                </a-menu>
            </a-sheet>
        </template>

        <div
            class="u-relative"
            :class="[{ 'u-no-select': mixinIsLoading('workspace-change') }]"
            :style="[mixinIsLoading('workspace-change') ? { backgroundColor: 'rgba(255, 255, 255, 0.8)' } : {}]"
        >
            <div
                v-if="mixinIsLoading('workspace-change')"
                class="u-absolute u-wfull u-hfull u-flex-center u-rounded-corners u-no-select u-z16"
                style="background-color: rgba(255, 255, 255, 0.8)"
            >
                <h3 class="md-body-1 grey--text text--darken-3">Switching Workspace...</h3>
            </div>
            <v-slide-y-transition leave-absolute hide-on-leave>
                <div v-if="!localIsLoading" class="mt-6 u-rounded-corners white u-shadow">
                    <!-- Select from "Folders" or "All Calls" -->
                    <a-sheet class="px-6 py-4 u-flex u-rounded-corners">
                        <div>
                            <h3 class="mb-1 md-subtitle-1 font-weight-medium grey--text text--darken-3">Sync Calls</h3>
                            <p class="mb-0 md-body-2 grey--text text--darken-2">Choose the source of your calls.</p>
                        </div>
                        <a-spacer></a-spacer>
                        <a-tooltip bottom :disabled="!(!localIsWorkspaceSelected && $can('applets.update')) || mixinIsLoading('sync')">
                            <template v-slot:activator="{ on }">
                                <div
                                    v-on="on"
                                    :class="[{ 'u-opacity-50': mixinIsLoading('sync') || !localIsWorkspaceSelected || !$can('applets.update') }]"
                                    class="u-flex"
                                    style="columnGap: 16px;"
                                >
                                    <div
                                        v-on="on"
                                        class="px-4 py-3 u-rounded-corners u-flex align-start"
                                        @click="localSelectSource('folders')"
                                        :class="[
                                            localIsCallSourceFolders && localIsWorkspaceSelected && $can('applets.update') ? 'indigo lighten-5' : 'grey lighten-4',
                                            { 'u-cursor-pointer': !mixinIsLoading('sync') && localIsWorkspaceSelected && $can('applets.update') }
                                        ]"
                                        :style="[
                                            { border: localIsCallSourceFolders && localIsWorkspaceSelected ? '1px solid #3949AB !important' : '1px solid #f0f0f0 !important', maxWidth: '240px', columnGap: '8px' }
                                        ]"
                                    >
                                        <a-icon size="20" class="u-icon-nudge" style="top: 2px;" :color="localIsCallSourceFolders && localIsWorkspaceSelected ? 'indigo darken-3' : 'grey darken-1'">
                                            {{ localIsCallSourceFolders && localIsWorkspaceSelected ? 'check_circle' : 'radio_button_unchecked' }}
                                        </a-icon>
                                        <div>
                                            <h4 class="md-body-2 font-weight-medium indigo--text text--darken-2">From selected folders</h4>
                                            <p class="mb-0 md-body-2 grey--text text--darken-2">Manually select the required folders you need.</p>
                                        </div>
                                    </div>
                                    <div
                                        v-on="on"
                                        class="px-4 py-3 u-rounded-corners u-flex align-start"
                                        @click="localSelectSource('all')"
                                        :class="[
                                            localIsCallSourceAll && localIsWorkspaceSelected && $can('applets.update') ? 'indigo lighten-5' : 'grey lighten-4',
                                            { 'u-cursor-pointer': !mixinIsLoading('sync') && localIsWorkspaceSelected && $can('applets.update') }
                                        ]"
                                        :style="[
                                            { border: localIsCallSourceAll && localIsWorkspaceSelected && $can('applets.update') ? '1px solid #3949AB !important' : '1px solid #f0f0f0 !important', maxWidth: '240px', columnGap: '8px' }
                                        ]"
                                    >
                                        <a-icon size="20" class="u-icon-nudge" style="top: 2px;" :color="localIsCallSourceAll && localIsWorkspaceSelected ? 'indigo darken-3' : 'grey darken-1'">
                                            {{ localIsCallSourceAll && localIsWorkspaceSelected ? 'check_circle' : 'radio_button_unchecked' }}
                                        </a-icon>
                                        <div>
                                            <h4 class="md-body-2 font-weight-medium indigo--text text--darken-2">All Calls</h4>
                                            <h4 class="md-body-2 font-weight-medium indigo--text text--darken-2">(including archive)</h4>
                                            <p class="mb-0 md-body-2 grey--text text--darken-2">Select all the calls from the chosen workspace.</p>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <span>Select a workspace to proceed further</span>
                        </a-tooltip>
                    </a-sheet>

                    <div v-if="localIsCallSourceFolders && localIsWorkspaceSelected">
                        <a-divider></a-divider>
                        <!-- Select Library Folders -->
                        <a-tooltip v-if="!localIsLoading" bottom :disabled="!!(selectedWorkspace && selectedWorkspace.id)">
                            <template v-slot:activator="{ on }">
                                <div
                                    v-on="on"
                                    class="px-6 py-4 u-flex-center-y"
                                    :class="[(selectedWorkspace && !selectedWorkspace.id) || mixinIsLoading('sync') ? 'u-opacity-50' : '']"
                                >
                                    <div>
                                        <h3 class="mb-1 md-subtitle-1 font-weight-medium grey--text text--darken-3">Library</h3>
                                        <p class="mb-0 md-body-2 grey--text text--darken-2">Choose the folders and sub-folders you need to fetch from Gong.</p>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <a-btn
                                        v-if="$can('applets.update')"
                                        :class="[{ 'u-opacity-40': (selectedWorkspace && !selectedWorkspace.id) || mixinIsLoading('sync') }]"
                                        @click="(selectedWorkspace && !selectedWorkspace.id) || mixinIsLoading('sync') || mixinIsLoading('workspace-change') ? {} : dialogFolderSelection = true"
                                        height="40"
                                        class="px-3 indigo darken-3 u-hfull"
                                        dark depressed
                                    >
                                        Add Folder
                                    </a-btn>
                                </div>
                            </template>
                            <span>Select a workspace to select respective libraries.</span>
                        </a-tooltip>

                        <!-- Selected folders list -->
                        <v-slide-y-transition leave-absolute hide-on-leave>
                            <div v-if="!localIsLoading && selectedFolders.length">
                                <div v-for="(selectedFolder, index) in selectedFolders" :key="index">
                                    <a-divider></a-divider>
                                    <div
                                        :class="[
                                            { 'u-opacity-50': mixinIsLoading('sync') },
                                            $can('applets.update') ? 'py-2' : 'py-3'
                                        ]"
                                        class="px-6 py-2 u-flex-center-y"
                                        style="column-gap: 20px;"
                                    >
                                        <div class="u-flex-center-y" style="column-gap: 8px;">
                                            <a-icon size="24" color="grey darken-2">folder_open</a-icon>
                                            <div class="md-body-2 grey--text text--darken-3 text-truncate">
                                                {{ localGetFoldersBreadcrumb(selectedFolder) }}
                                                <template v-if="localIsFolderRecursive(selectedFolder)">
                                                    <span>/**/*</span>
                                                </template>
                                            </div>
                                        </div>
                                        <a-spacer></a-spacer>
                                        <div v-if="$can('applets.update')" class="u-flex-center-y" style="column-gap: 12px;">
                                            <a-sheet
                                                v-ripple="!mixinIsLoading('sync')"
                                                class="pr-1 u-flex-center-y u-rounded-corners u-cursor-pointer"
                                                style="column-gap: 3px;"
                                                @click="localToggleRecursive(selectedFolder, index)"
                                            >
                                                <a-checkbox color="blue" :disabled="mixinIsLoading('sync')" :input-value="localIsFolderRecursive(selectedFolder)" dense class="ma-0 pa-0" hide-details :ripple="false"></a-checkbox>
                                                <h3 class="md-body-2 font-weight-medium grey--text text--darken-1 ml-n2">Include Subfolders</h3>
                                            </a-sheet>
                                            <a-tooltip bottom left>
                                                <template v-slot:activator="{ on }">
                                                    <a-btn
                                                        v-on="on"
                                                        :color="confirmRemoveFolder !== index ? 'grey darken-2' : 'red darken-2'"
                                                        @click="confirmRemoveFolder === index ? localRemoveFolder(index) : localConfirmRemoveFolder(index)"
                                                        :disabled="mixinIsLoading('sync')"
                                                        icon depressed
                                                    >
                                                        <a-icon size="24">{{ confirmRemoveFolder === index ? 'remove_circle' : 'remove_circle_outline' }}</a-icon>
                                                    </a-btn>
                                                </template>
                                                <span>Remove</span>
                                            </a-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!selectedFolders.length">
                                <a-divider></a-divider>
                                <div class="px-5 py-3 u-flex-center-y">
                                    <a-icon size="18" color="red darken-2" class="mr-2">info</a-icon>
                                    <span class="md-body-2 grey--text text--darken-2">Atleast one folder should be selected.</span>
                                </div>
                            </div>
                        </v-slide-y-transition>
                    </div>
                </div>
            </v-slide-y-transition>

            <v-slide-y-transition leave-absolute hide-on-leave>
                <div
                    v-if="localShowSaveAndSyncSection && $can('applets.update')"
                    class="mt-6 c-sticky-paginate-parent u-shadow u-rounded-corners pa-3"
                >
                    <div v-if="localHasChanges" class="u-flex-center-y">
                        <a-btn
                            :loading="saveAndSyncLoading"
                            @click="localSaveAndSync()"
                            height="38"
                            class="px-4 py-1 mr-3"
                            :class="[(localIsCallSourceFolders && localHasFoldersSelected) || localIsCallSourceAll ? 'indigo darken-3' : 'u-opacity-50 indigo lighten-1']"
                            :disabled="(localIsCallSourceFolders && !localHasFoldersSelected)"
                            dark text depressed
                        >
                            Save & Sync
                        </a-btn>
                        <a-btn
                            @click="localResetChanges()"
                            height="38"
                            class="px-3 py-1 grey--text text--darken-1 grey lighten-3"
                            text depressed
                        >
                            Cancel
                        </a-btn>
                    </div>

                    <!-- Manual Sync Button -->
                    <div v-if="!localHasChanges" class="u-flex-center-y" style="column-gap: 12px">
                        <div class="u-border d-inline-flex u-rounded-corners">
                            <a-btn
                                :class="[(localIsCallSourceFolders && localHasFoldersSelected) || localIsCallSourceAll ? 'secondary' : 'u-opacity-50 indigo lighten-1']"
                                :disabled="(localIsCallSourceFolders && !localHasFoldersSelected)"
                                @click="mixinIsLoading('sync') || mixinIsLoading('workspace-change') ? {} : localManualSync()"
                                height="36"
                                class="px-3 py-2"
                                depressed dark text
                            >
                                <div class="u-flex-center-y" style="column-gap: 4px;">
                                    <a-icon v-if="!mixinIsLoading('sync')" size="20">sync</a-icon>
                                    <a-icon v-if="mixinIsLoading('sync')" size="24" color="white">$ripple_loading</a-icon>
                                    <span class="md-body-2 font-weight-medium">{{ mixinIsLoading('sync') ? 'Syncing...' : 'Sync' }}</span>
                                </div>
                            </a-btn>
                        </div>
                        <div class="u-flex-center-y" style="column-gap: 6px">
                            <a-icon size="16" color="grey" outlined>info</a-icon>
                            <span v-if="!mixinIsLoading('sync')" class="md-body-2 grey--text text--darken-1">Manual Sync brings in latest calls and may take some time to compete the sync.</span>
                            <span v-else class="md-body-2 grey--text text--darken-1">Syncing in progress. Please check after sometime...</span>
                        </div>
                    </div>
                </div>
            </v-slide-y-transition>
        </div>

        <a-dialog v-model="dialogFolderSelection" width="1086" persistent>
            <ModalGongFolderSelection
                :isOpen="dialogFolderSelection"
                :workspace="selectedWorkspace"
                @save="localSaveFolderSelection"
                @close="localCloseFolderSelection"
            />
        </a-dialog>

        <a-dialog v-model="dialogWorkspaceChangeConfirm" width="400" persistent>
            <a-card class="pa-6">
                <div class="mb-3 md-subtitle-1 font-weight-meidum grey--text text--darken-3">Are you sure you want to switch the workspace?</div>
                <div class="md-body-2 grey--text text--darken-2">Please note that this action will remove all the folders selected from previous workspace. Do you want to proceed?</div>
                <div class="mt-6">
                    <a-btn @click="localConfirmWorkspaceChange()" small text class="px-4 py-2 mr-2 indigo darken-3 white--text u-rounded-corners" dark>Switch Workspace</a-btn>
                    <a-btn @click="localCancelWorkspaceChange()" small text class="px-4 py-2 grey lighten-4 grey--text u-rounded-corners" dark>Cancel</a-btn>
                </div>
            </a-card>
        </a-dialog>

        <!--Unsaved changes alert on page leave -->
        <a-dialog v-model="dialogUnsavedChangesAlert" persistent max-width="400">
            <SCancelChanges
                @success="localClearChanges()"
                @close="dialogUnsavedChangesAlert = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ModalGongFolderSelection from './Modals/ModalGongFolderSelection.vue'
import PartialGongSettingsLoader from './Partials/PartialGongSettingsLoader.vue'
import { findParentFolders } from '@/helpers/helper-get-parent-folder'
import { SCancelChanges } from '@/config/config-shared-components'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import { diffSimple } from '@/helpers/helper-date-filter';

const CALL_SOURCE = { FOLDERS: 'folders', ALL: 'all' }

export default {
    components: {
        ModalGongFolderSelection,
        PartialGongSettingsLoader,
        SCancelChanges
    },

    mixins: [mixinLoading],

    data () {
        return {
            isEdited: false,
            workspaceModel: false,
            pageLoading: true,
            workspaceFetchLoading: true,
            workspaceHitCount: 0,
            saveAndSyncLoading: false,
            dialogFolderSelection: false,
            workspaceChangeConfirm: false,
            dialogUnsavedChangesAlert: false,
            dialogWorkspaceChangeConfirm: false,

            workspacesList: [],
            selectedFolders: [],
            selectedFolderIds: [],
            removedFolders: [],
            clonedData: { callSource: CALL_SOURCE.FOLDERS, selectedFolders: [], selectedFolderIds: [], selectedWorkspace: {} },

            searchWorkspaceModel: '',
            callSource: CALL_SOURCE.FOLDERS,

            dbSyncTimeoutCount: 0,
            dbSyncTimeoutInterval: null,
            nextPageRoute: null,
            confirmRemoveFolder: null,
            confirmRemoveFolderTimeout: null,
            selectedWorkspace: {},
            tempWorkspaceSelection: {},
        }
    },

    mounted () {
        this.localIndex()
        window.addEventListener('beforeunload', this.localAlertUnsavedChanges)
    },

    beforeDestroy () {
        window.removeEventListener('beforeunload', this.localAlertUnsavedChanges)
    },

    beforeRouteLeave (toPage, fromPage, next) {
        if (!this.localHasChanges) return next()

        next(false)
        this.dialogUnsavedChangesAlert = true
        this.nextPageRoute = next
    },

    computed: {
        localFilteredWorkspaces () {
            const searchQuery = _.trim(this.searchWorkspaceModel).toLowerCase()

            return this.workspacesList
                .sort((a, b) => b.isChecked - a.isChecked)
                .filter(workspace => workspace.name.toLowerCase().includes(searchQuery))
        },

        localIsCallSourceAll () {
            return this.callSource === CALL_SOURCE.ALL
        },

        localIsCallSourceFolders () {
            return this.callSource === CALL_SOURCE.FOLDERS
        },

        localIsWorkspaceSelected () {
            return !!(this.selectedWorkspace && this.selectedWorkspace.id)
        },

        localHasChanges () {
            return !_.isEqual(this.clonedData.selectedFolders, this.selectedFolders) ||
                !_.isEqual(this.clonedData.selectedFolderIds, this.selectedFolderIds) ||
                !_.isEqual(this.clonedData.callSource, this.callSource)
        },

        localIsLoading () {
            return this.pageLoading || this.workspaceFetchLoading
        },

        localShowSaveAndSyncSection () {
            return !this.localIsLoading &&
                this.localIsWorkspaceSelected &&
                !this.mixinIsLoading('workspace-change')
        },

        localHasFoldersSelected () {
            return _.size(this.selectedFolders)
        },

        ...mapState('Gong', {
            gong_folders: 'folders',
            gong_workspaces: 'workspaces',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Meeting', {
            meeting_info_list: 'info',
        }),
    },

    methods: {
        async localIndex () {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            this.mixinSetLoading('sync')
            this.mixinSetLoading('workspace-syncing')
            await this.meeting_info_index()
            if (!this.meeting_info_list.is_gong_call_manual_syncing) this.mixinResetLoading('sync')
            if (!this.meeting_info_list.is_gong_workspace_syncing) this.mixinResetLoading('workspace-syncing')

            await this.localFetchWorkspaceAndFolders()
            this.pageLoading = false
        },

        async localFetchWorkspaceAndFolders () {
            await this.localFetchWorkspaces()
        },

        async localFetchWorkspaces () {
            this.workspaceFetchLoading = true

            await this.gong_workspaces_index()
            if (!_.size(this.gong_workspaces) && this.workspaceHitCount < 15) {
                this.workspaceHitCount++
                setTimeout(() => this.localFetchWorkspaces(), 1000)
                return true
            }

            this.workspaceFetchLoading = false
            await this.gong_folders_index({ include: 'gongWorkspace' })
            await this.localSetupWorkspaceAndFolders()
            this.workspaceHitCount = 0
        },

        async localSetupWorkspaceAndFolders () {
            this.workspacesList = _.cloneDeep(this.gong_workspaces).map(i => ({ ...i, isChecked: false }))
            this.selectedWorkspace = this.gong_workspaces.find(i => i.is_selected === 1)
            if (!this.selectedWorkspace || (this.selectedWorkspace && !this.selectedWorkspace.id)) return
            this.callSource = this.selectedWorkspace.is_all_calls === 1 ? CALL_SOURCE.ALL : CALL_SOURCE.FOLDERS

            this.localSetupFolderSelection()
            this.clonedData.selectedWorkspace = _.cloneDeep(this.selectedWorkspace)
            this.clonedData.callSource = _.cloneDeep(this.callSource)
        },

        async localRefreshDirectory () {
            if (this.mixinIsLoading('refreshing') || this.mixinIsLoading('workspace-syncing')) return

            this.mixinSetLoading('refreshing')
            this.dbSyncTimeoutCount = 0
            await this.gong_sync_workspaces()

            await this.localCheckDbSync()
            this.dbSyncTimeoutInterval = setInterval(async () => {
                if (this.dbSyncTimeoutCount === 10) {
                    clearInterval(this.dbSyncTimeoutInterval)
                    this.dbSyncTimeoutCount = 0
                    await this.localFetchWorkspaceAndFolders()
                    await this.localSetupWorkspaceAndFolders()
                    return true
                }

                await this.localCheckDbSync()
                if (!this.meeting_info_list.is_gong_workspace_syncing) {
                    this.mixinResetLoading('refreshing')
                    clearInterval(this.dbSyncTimeoutInterval)
                    this.dbSyncTimeoutCount = 0
                    await this.localFetchWorkspaceAndFolders()
                    await this.localSetupWorkspaceAndFolders()
                }
                this.dbSyncTimeoutCount++
            }, 2000)
        },

        async localCheckDbSync () {
            await this.meeting_info_index()
        },

        async localSetupFolderSelection () {
            const subFolders = this.gong_folders.filter(i => i.gong_parent_folder_id && i.is_selected === 1)
            const folders = []

            for (let index = 0; index < subFolders.length; index++) {
                const folder = subFolders[index]
                const { list, clearList } = findParentFolders(folder, this.gong_folders, index)
                folders.push([...list.reverse(), folder])
                clearList()
            }

            this.clonedData.selectedFolders = _.cloneDeep(folders)
            this.clonedData.selectedFolderIds = []
            this.selectedFolders = _.cloneDeep(folders)
            this.selectedFolderIds = []
            folders.forEach(f => {
                const lastFolder = f.slice(-1)[0]
                this.clonedData.selectedFolderIds.push({ id: lastFolder.id, is_recursive: lastFolder.is_recursive })
                this.selectedFolderIds.push({ id: lastFolder.id, is_recursive: lastFolder.is_recursive })
            })
        },

        localSaveFolderSelection (selectedFolders) {
            this.isEdited = true
            const lastFolderId = selectedFolders.slice(-1)[0]['id']
            const removedIndex = this.removedFolders.findIndex(i => i.id === lastFolderId)
            if (removedIndex !== -1) this.removedFolders.splice(removedIndex, 1)

            this.selectedFolders.push(selectedFolders)

            const index = this.selectedFolderIds.findIndex(i => i.id === lastFolderId)
            if (index !== -1) this.selectedFolderIds.push({ ...this.selectedFolderIds[index], is_new: true })
            else this.selectedFolderIds.push({ id: lastFolderId, is_recursive: 0, is_new: true })
            // this.selectedFolderIds.push({ id: lastFolderId, is_new: true })
        },

        localCloseFolderSelection () {
            this.dialogFolderSelection = false
        },

        localCancelWorkspaceChange () {
            this.tempWorkspaceSelection = {}
            this.workspaceChangeConfirm = false
            this.dialogWorkspaceChangeConfirm = false
        },

        localConfirmWorkspaceChange () {
            this.workspaceChangeConfirm = true
            this.localSelectWorkspace(this.tempWorkspaceSelection)
        },

        async localSelectWorkspace (workspace) {
            const clonedWorkspaceId = this.clonedData.selectedWorkspace ? this.clonedData.selectedWorkspace.id : null
            if (!this.workspaceChangeConfirm && (clonedWorkspaceId && (clonedWorkspaceId !== workspace.id))) {
                this.tempWorkspaceSelection = workspace
                return this.dialogWorkspaceChangeConfirm = true
            }

            if (this.dialogWorkspaceChangeConfirm) this.localCancelWorkspaceChange()

            this.mixinSetLoading('workspace-change')
            this.selectedWorkspace = workspace
            await this.gong_workspace_select({ id: workspace.id })

            this.removedFolders = _.cloneDeep(this.selectedFolderIds)
            await this.localUpdateDeSelectedFolders()

            this.callSource = CALL_SOURCE.FOLDERS
            this.localUpdateCallSource()

            this.localResetFolderSelection()
            await this.localFetchWorkspaceAndFolders()
            await this.localSetupWorkspaceAndFolders()
            this.mixinResetLoading('workspace-change')
        },

        localResetFolderSelection () {
            this.clonedData.selectedFolders = []
            this.clonedData.selectedFolderIds = []
            this.clonedData.callSource = CALL_SOURCE.FOLDERS
            this.selectedFolders = []
            this.selectedFolderIds = []
        },

        localClearAll () {
            this.selectedFolders = []
            this.selectedFolderIds = []
        },

        localGetWorkspace (workspace, { field = 'name' } = {}) {
            return workspace[field] || ''
        },

        localGetFoldersBreadcrumb (folder) {
            return folder
                .map(folder => folder.name)
                .join(' / ')
        },

        localToggleRecursive (folder) {
            if (this.mixinIsLoading('sync') || this.mixinIsLoading('workspace-change')) return

            const lastFolderId = folder.slice(-1)[0]['id']
            const index = this.selectedFolderIds.findIndex(i => i.id === lastFolderId)
            const is_recursive = this.selectedFolderIds[index].is_recursive === 1 ? 0 : 1

            this.$set(this.selectedFolderIds, index, { ...this.selectedFolderIds[index], is_recursive })
        },

        localIsFolderRecursive (folder) {
            const lastFolderId = folder.slice(-1)[0]['id']
            const index = this.selectedFolderIds.findIndex(i => i.id === lastFolderId)
            if (index === -1) return false

            return this.selectedFolderIds[index].is_recursive === 1
        },

        localConfirmRemoveFolder (index) {
            if (this.mixinIsLoading('sync') || this.mixinIsLoading('workspace-change')) return

            clearTimeout(this.confirmRemoveFolderTimeout)

            this.confirmRemoveFolder = index
            this.confirmRemoveFolderTimeout = setTimeout(() => {
                this.confirmRemoveFolder = null
            }, 3000)
        },

        localRemoveFolder (index) {
            if (this.mixinIsLoading('workspace-change')) return

            this.removedFolders.push(this.selectedFolderIds[index])
            this.selectedFolderIds.splice(index, 1)
            this.selectedFolders.splice(index, 1)
            this.confirmRemoveFolder = null
            clearTimeout(this.confirmRemoveFolderTimeout)
        },

        async localSaveAndSync () {
            if (this.mixinIsLoading('workspace-change')) return

            this.isEdited = false
            this.saveAndSyncLoading = true
            const newFolders = this.selectedFolderIds.filter(i => i.hasOwnProperty('is_new') && i.is_new)
            if (_.size(newFolders)) await this.gong_folders_add({ data: newFolders })

            await this.localUpdateDeSelectedFolders()
            await this.localUpdateRecursiveFolders()
            await this.localUpdateCallSource()
            await this.localFetchWorkspaceAndFolders()
            await this.localSetupWorkspaceAndFolders()

            this.mixinSetLoading('sync')
            await this.localCheckDbSync()
            if (!this.meeting_info_list.is_gong_call_manual_syncing) {
                this.mixinResetLoading('sync')
            }
            this.saveAndSyncLoading = false

            this.clonedData.selectedFolders = _.cloneDeep(this.selectedFolders)
            this.clonedData.selectedFolderIds = _.cloneDeep(this.selectedFolderIds)
            this.clonedData.callSource = _.cloneDeep(this.callSource)
            this.removedFolders = _.cloneDeep([])
        },

        async localUpdateDeSelectedFolders () {
            if (!_.size(this.removedFolders)) return

            const folders = this.removedFolders.map(i => ({ ...i, is_selected: 0, is_recursive: 0 }))
            if (_.size(folders)) await this.gong_folders_update({ data: folders })
        },

        async localUpdateRecursiveFolders () {
            const folders = this.selectedFolderIds.filter(i => {
                const index = this.clonedData.selectedFolderIds.findIndex(c => c.id === i.id)
                if (index === -1) return null

                const oldData = this.clonedData.selectedFolderIds[index]
                if (oldData.is_recursive !== i.is_recursive) return i
                return null
            })

            if (_.size(folders)) await this.gong_folders_update({ data: folders })
        },

        async localUpdateCallSource () {
            if (this.callSource === this.clonedData.callSource) return
            await this.gong_workspace_select({ id: this.selectedWorkspace.id, is_all_calls: this.callSource === CALL_SOURCE.ALL ? 1 : 0 })
        },

        localSelectSource (source) {
            if (
                this.mixinIsLoading('sync') ||
                !this.localIsWorkspaceSelected ||
                this.mixinIsLoading('workspace-change') ||
                !this.$can('applets.update')
            ) return

            this.callSource = source
        },

        localResetChanges () {
            if (this.mixinIsLoading('workspace-change')) return

            this.selectedFolders = _.cloneDeep(this.clonedData.selectedFolders)
            this.selectedFolderIds = _.cloneDeep(this.clonedData.selectedFolderIds)
            this.callSource = _.cloneDeep(this.clonedData.callSource)
            this.removedFolders = []
        },

        localAlertUnsavedChanges (evt) {
            if (this.localHasChanges) {
                evt.returnValue = 'Are you sure to close the tab?'
            }
        },

        localClearChanges () {
            this.localResetChanges()
            this.nextPageRoute()
        },

        async localManualSync () {
            this.mixinSetLoading('sync')
            await this.gong_sync_calls()
            await this.localCheckDbSync()
            if (!this.meeting_info_list.is_gong_call_manual_syncing) {
                this.mixinResetLoading('sync')
            }
        },

        localFormatDate (date) {
            return date ? diffSimple(date, { format: 'MMM D, YYYY [at] hh:mm A' }) : null
        },

        ...mapActions('Meeting', {
            meeting_info_index: 'info_index',
        }),

        ...mapActions('Gong', {
            gong_workspaces_index: 'workspaces_index',
            gong_workspace_select: 'workspace_select',
            gong_folders_index: 'folders_index',
            gong_folders_add: 'folders_add',
            gong_folders_update: 'folders_update',
            gong_sync_workspaces: 'sync_workspaces',
            gong_sync_calls: 'sync_calls',
        }),
    },
}
</script>

<style lang="scss" scoped>
.c-table {
    display: table !important;
    border-collapse: separate;
    border-spacing: 0 15px;
    &__cell {
        display: table-cell !important;
        white-space: nowrap !important;
        background: #fff !important;
        &:first-child {
            border-radius: 4px 0px 0px 4px;
        }
        &:last-child {
            border-radius: 0px 4px 4px 0px;
        }
    }
}

.c-table-desc {
    display: table;
    border-collapse: collapse !important;
    &__cell {
        display: table-cell !important;
        padding: 8px;
        vertical-align: top !important;
        white-space: normal !important;
        border: 1px solid #e4e4e4;
        &:first-child {
            border-radius: 4px 0px 0px 4px;
        }
        &:last-child {
            border-radius: 0px 4px 4px 0px;
        }
    }
}

.c-folder-view {
    //&::-webkit-scrollbar { display: none; }
    &::-webkit-scrollbar-track { background-color: #f0f0f0; }
}
</style>

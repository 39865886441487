<template>
    <v-stepper-header v-on="$listeners" v-bind="$attrs">
        <slot></slot>
    </v-stepper-header>
</template>

<script>
import { VStepperHeader } from 'vuetify/lib/components/VStepper'

export default {
    inheritAttrs: false,
    components: { VStepperHeader },

    data () {
        return {
            ref: null
        }
    },

    mounted () {
        this.ref = this.$el
    }
}
</script>

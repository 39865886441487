import store from '../models'

async function guardInternal(to, from, next) {
    if (!store.state.User.self.id) {
        await store.dispatch('User/me')
    }

    const { scope } = store.state.User.self
    return scope === 'internal' ? next() : next('/page-not-found')
}

export default guardInternal

<template>
    <a-sheet min-height="180" v-bind="$attrs" class="u-wfull" :class="{'pt-3': hideDivider, 'u-absolute': sticky}" :style="[sticky ? {bottom: '0px', left: '0px'} : '']">
        <a-divider v-if="!hideDivider" class="grey lighten-2 mb-4"></a-divider>
        <div class="u-flex" :class="[inline ? 'px-0' : 'px-6']">
            <g-avatar v-if="!hideUser" :item="user ? user : user_self" :size="32"></g-avatar>
            <div class="u-wfull">
                <a-sheet height="96" max-height="126" @click="$refs.refCommentBox.ref.focus()" style="cursor: text; border: 1px solid #ececec !important;" class="pb-2 pt-0 px-0 u-wfull white u-rounded-corners-lg u-overflow-y">
                    <a-textarea
                        v-model="dataComment"
                        placeholder="Post your comment..."
                        rows="1"
                        :autofocus="autofocus"
                        ref="refCommentBox"
                        solo flat auto-grow no-resize hide-details
                        @keyup.ctrl.enter="methodStoreComment"
                    ></a-textarea>
                </a-sheet>
                <a-sheet class="mt-4 transparent u-flex align-center" :max-width="inline ? 'auto' : 500">
                    <a-btn small text :disabled="dataLoading" @click="methodClear" class="md-caption u-rounded-corners ma-0" style="border: 1px solid #dadada" v-if="isEdit">Cancel</a-btn>
                    <a-btn small text :disabled="dataLoading" @click="methodStoreComment" :class="{ 'ml-2': isEdit }" class="md-caption u-rounded-corners ma-0 blue darken-1 white--text">{{ isEdit ? 'Save Changes' : 'Comment' }}</a-btn>
                    <a-spacer></a-spacer>
                    <span class="md-caption red--text text--darken-1" v-if="$response(error, 'content')">
                        {{ $response(error, 'content') }}
                    </span>
                    <span v-else class="md-caption grey--text text--darken-1">Press <span class="grey darken-1 md-caption white--text u-rounded-corners" style="padding: 2px 6px 2px 6px; font-family: monospace !important">Ctrl</span> + <span class="grey darken-1 md-caption white--text u-rounded-corners" style="padding: 2px 6px 2px 6px; font-family: monospace !important">Enter</span> to comment</span>
                </a-sheet>
            </div>
        </div>
    </a-sheet>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    inheritAttrs: false,
    props: {
        item: {
            type: Object
        },
        type: {
            type: String
        },
        user: {
            type: Object,
            default: null
        },
        error: {
            type: Object | Array,
            default: null
        },
        content: {
            type: String,
            default: null
        },
        hideUser: {
            type: Boolean,
            default: false
        },
        hideDivider: {
            type: Boolean,
            default: false
        },
        sticky: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        resetComment: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        resetComment (val) {
            if (val) {
                this.dataComment = null
                this.comment_clear_item()
            }
        }
    },

    data () {
        return {
            dataComment: this.content,
            dataLoading: false,
        }
    },

    computed: {
        // dataComment: {
        //     get () {
        //         return this.comment
        //     },
        //     set (val) {
        //         this.comment = this.content
        //         // if (this.content) this.content = val
        //         // else this.comment = val
        //     },
        // },

        ...mapState('User', {
            user_self: 'self'
        })
    },

    methods: {
        methodStoreComment () {
            if (!this.dataComment || (this.dataComment && !this.dataComment.trim())) return
            this.methodLoading(true)
            this.$emit('store', this.dataComment, this.type, this.item.id)
            this.methodLoading(false)
            setTimeout(() => {
                if ((this.error && !this.error.length) || !this.error || this.error && this.error.status === 'success') this.dataComment = null
            }, 300)
        },

        methodClear () {
            this.dataComment = null
            this.$emit('clear')
            this.comment_clear_item()
        },

        methodLoading (val) {
            this.dataLoading = val
        },

        ...mapActions('Comment', {
            comment_clear_item: 'clear_item',
        }),
    }
}
</script>

<style>

</style>

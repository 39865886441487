<template>
    <a-sheet max-height="200" class="scroll-light items u-overflow-y">
        <button v-for="(item, index) in items" @click="local_select_item(index)" class="item" :key="index" :class="{ 'is-selected': index === selectedIndex }">
            {{ item ? item.native : '' }} {{ item ? item.colons : '' }}
        </button>
    </a-sheet>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        command: {
            type: Function,
            required: true
        }
    },

    data() {
        return {
            selectedIndex: 0
        }
    },

    watch: {
        items () {
            this.selectedIndex = 0
        }
    },

    methods: {
        onKeyDown ({ event }) {
            switch (event.key) {
                case 'ArrowUp': {
                    this.local_up_handler()
                    return true
                }
                case 'ArrowDown': {
                    this.local_down_handler()
                    return true
                }
                case 'Enter': {
                    this.local_enter_handler()
                    return true
                }
                default: return false
            }
        },

        local_up_handler () {
            this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length
        },

        local_down_handler () {
            this.selectedIndex = (this.selectedIndex + 1) % this.items.length
        },

        local_enter_handler () {
            this.local_select_item(this.selectedIndex)
        },

        local_select_item (index) {
            const item = this.items[index]
            if (item) this.command({ id: item.colons, label: item.native })
        }
    }
}
</script>

<style lang="scss" scoped>
.items {
    position: relative;
    border-radius: 0.25rem;
    background: white;
    color: rgba(black, 0.8);
    overflow: hidden;
    font-size: 0.9rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.item {
    display: block;
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    padding: 0.2rem 0.5rem;

    &.is-selected,
    &:hover {
        color: #a975ff;
        background: rgba(#a975ff, 0.1);
    }
}
</style>

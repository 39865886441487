const api_endpoint = '/automation-validations';

export default {
    namespaced: true,

    state: {
        list: [],
        response: {},
        meta: {},
        status: {},
        defaults: {
            list: [],
            response: {},
            meta: {},
            status: {},
        },
    },

    mutations: {
        ADD_ITEM(state, item) {
            state.list.push(item);
        },
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        UPDATE_STATUS(state, status) {
            state.status = status;
        },
        THROW_RESPONSE (state, payload) {
            state.response = payload
        },
    },

    actions: {
        async index ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                const { data: { data }, meta } = await axios.get(api_endpoint, { params: payload })
                commit('UPDATE_LIST', data)
                commit('UPDATE_META', meta)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async result_status ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            dispatch('clear_status')
            try {
                const { data: { data }, meta } = await axios.get(`${api_endpoint}/result-status`, { params: payload })
                commit('UPDATE_STATUS', data)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async add_item ({ commit }, payload) {
            commit('ADD_ITEM', payload)
        },

        async upsert ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.patch(`${api_endpoint}/upsert`, payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async throw_success ({ commit }) {
            commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            this.dispatch('Interface/loader_stop')
        },

        async throw_error ({ commit }, error) {
            this.dispatch('Interface/loader_stop')
            commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
        },

        async loader_start () {
            this.dispatch('Interface/loader_start')
        },

        async clear_list ({ commit }) {
            commit('UPDATE_LIST', [])
        },

        async clear_status ({ commit }) {
            commit('UPDATE_STATUS', [])
        },

        async clear_response ({ commit }) {
            commit('THROW_RESPONSE', [])
        },
    },
}

<template>
    <a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="pa-6 pt-2 px-0 mb-12">
            <g-page-header
                title="Territories"
                subtitle="Manage territories to group users and project for reporting."
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                icon="terrain"
            ></g-page-header>
            <!-- <territory-tabs></territory-tabs> -->
            <a-layout v-if="page_loading">
                <a-flex xs12>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching territories..</h3>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <a-layout v-else>
                <a-flex xs12 pt-0>
                    <draggable v-model="territory_list" :options="{handle: '.js-drag-handle'}">
                        <a-card v-for="territory in territory_list" :key="territory.id" class="u-elevation-custom-1">
                            <a-card-text class="pa-0">
                                <a-container container--fluid grid-list-xl class="pa-4 px-6">
                                    <a-layout wrap align-center>
                                        <a-flex shrink class="pl-0 pr-1" v-if="loader_item == territory.id">
                                            <a-progress-circular color="blue lighten-2" size="20" width="2" indeterminate></a-progress-circular>
                                        </a-flex>
                                        <template v-else>
                                            <a-flex shrink class="px-0" v-if="$can('territories.update')">
                                                <a-icon class="grey--text text--lighten-1 u-cursor-pointer js-drag-handle">drag_indicator</a-icon>
                                            </a-flex>
                                        </template>
                                        <a-flex>
                                            <g-tags :tag="territory" :text-range="100" dense hide-clear-icon></g-tags>
                                        </a-flex>
                                        <a-flex shrink v-if="$can('territories.update')">
                                            <s-color-palette v-model="territory.color" @input="color => local_update_debounced(color, territory)"></s-color-palette>
                                        </a-flex>
                                        <a-flex shrink v-if="$can('territories.update') || $can('territories.destroy')">
                                            <a-tooltip bottom v-if="$can('territories.update')">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn color="grey darken-2" @click="local_edit(territory.id)" v-on="on" text icon small>
                                                        <a-icon small>edit</a-icon>
                                                    </a-btn>
                                                </template>
                                                <span>Edit Territory</span>
                                            </a-tooltip>
                                            <a-menu bottom left offset-y :close-on-content-click="false" max-width="320" v-if="$can('territories.destroy')">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn icon text small color="grey darken-2" class="ml-2" v-on="on">
                                                        <a-icon size="16">delete</a-icon>
                                                    </a-btn>
                                                </template>
                                                <a-card>
                                                    <a-card-text class="pa-4">
                                                        <h2 class="md-heading-6 mb-2">Are you sure to delete this territory?</h2>
                                                        <p class="body-2 mb-4">This will delete just the territory. All projects and users tagged with this territory will remain undeleted.</p>
                                                        <a-btn class="ma-0 elevation-0" color="red" @click="local_destroy(territory.id)" :loading="loading" dark small>
                                                            <a-icon size="16" left class="mr-2">delete</a-icon> Delete
                                                        </a-btn>
                                                    </a-card-text>
                                                </a-card>
                                            </a-menu>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-card-text>
                        </a-card>
                    </draggable>
                    <a-card v-if="$can('territories.store')" class="u-elevation-custom-1">
                        <a-card-text class="pa-0 mb-2">
                            <a-form @submit.prevent="local_store()">
                                <a-container container--fluid grid-list-xl class="py-2">
                                    <a-layout wrap align-center>
                                        <a-flex shrink class="pr-0">
                                            <a-icon color="grey" size="20">add</a-icon>
                                        </a-flex>
                                        <a-flex class="pl-0">
                                            <a-text-field
                                                v-model="territory_name"
                                                :loading="loading"
                                                placeholder="Add a territory - Hit enter to save"
                                                solo flat hide-details
                                            >
                                            </a-text-field>
                                        </a-flex>
                                        <a-flex shrink class="pr-4">
                                            <a-icon color="grey" size="16">keyboard_return</a-icon>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-form>
                        </a-card-text>
                    </a-card>
                    <span class="md-caption red--text text--darken-2 d-block" v-if="territory_response.server && territory_response.server.errors && territory_response.server.errors.name && territory_form_mode == 'add'">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ territory_response.server.errors.name[0] }}
                    </span>
                    <span class="md-caption red--text text--darken-2 d-block" v-if="territory_exist_message">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        Territory already exist. Please create a unique territory
                    </span>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog v-model="dialog_territory_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>terrain</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (territory_form_mode == 'add') ? 'Create Territory' : 'Edit Existing Territory' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (territory_form_mode == 'add') ? 'You can add new territory to the system.' : 'You can edit existing territory.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                    <a-text-field
                                        v-model="territory_item.name"
                                        placeholder="Enter Territory Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="territory_response.server && territory_response.server.errors && territory_response.server.errors.name && territory_form_mode == 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ territory_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Enter the name for the territory.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_update()" :loading="loading" :disabled="loading">Save Territory</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_territory_form = !dialog_territory_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { SColorPalette } from '@/config/config-shared-components'

export default {
    data() {
        return {
            territory_name: '',
            dialog_territory_form: false,
            timer_update_debounce: null,
            loader_item: null,
            territory_type: 'territory',
            territory_exist_message: false,
            territory_filter: {},
            territory_count: 999,
            FILTERS: {
                FIEDS: 'fields[territories]',
                PAGE: 'page',
                COUNT: 'count',
            },
            page_loading: false,
        }
    },
    components: {
        SColorPalette,
    },
    mounted() {
        this.local_index()
    },
    watch:{
        dialog_territory_form(newValue, oldValue) {
            if (newValue === false) {
                this.territory_clear_item()
            }
        },
    },
    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Types & Statuses', to: '/settings/territories', exact: true})
            return breadcrumbs
        },
        territory_list: {
            get() {
                return this.$store.state.Territory.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },
        ...mapState('Territory', {
            territory_item: 'item',
            territory_filters: 'filters',
            territory_form_mode: 'form_mode',
            territory_response: 'response',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            this.page_loading = true
            await this.territory_clear()
            this.territory_filter[this.FILTERS.PAGE] = 1
            this.territory_filter[this.FILTERS.COUNT] = this.territory_count
            this.territory_filter[this.FILTERS.FIEDS] = 'id,name,color,order'
            await this.localLoadTerritoryList()
            this.page_loading = false
        },
        async localLoadTerritoryList() {
            await this.territory_index(this.territory_filter)
        },
        async local_edit(id) {
            await this.territory_select({id: id})
            this.dialog_territory_form = true
        },
        async local_store() {
            this.territory_item.name = this.territory_name
            this.territory_item.type = this.territory_type
            await this.territory_store({
                name: this.territory_item.name,
                type: this.territory_item.type,
            })
            if (this.territory_response.status === 'success') {
                await this.territory_clear_item()
                this.territory_name = ''
                this.dialog_territory_form = false
            }
        },
        async local_update(id = null) {
            if (id !== null) await this.territory_select({id: id})
            await this.territory_update({
                id: this.territory_item.id,
                name: this.territory_item.name,
                color: this.territory_item.color,
            })
            if (this.territory_response.status === 'success') {
                await this.territory_clear_item()
                await this.local_clear_debounce_timer()
                this.dialog_territory_form = false
            }
        },
        async local_destroy(id) {
            await this.territory_destroy({id: id})
        },
        async local_reorder(list) {
            this.territory_reorder({list: list})
        },
        async local_update_debounced(color, territory) {
            this.local_clear_debounce_timer()
            if (color) territory.color = color
            this.loader_item = territory.id
            this.timer_update_debounce = setTimeout(this.local_update, 200, territory.id)
        },
        async local_clear_debounce_timer() {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                this.loader_item = null
            }
        },
        local_territory_exist_error() {
            this.territory_name = ''
            this.territory_exist_message = !this.territory_exist_message
            setTimeout(() => {
                this.territory_exist_message = false
            }, 3000);
        },
        ...mapActions('Territory', {
            territory_index: 'index',
            territory_store: 'store',
            territory_update: 'update',
            territory_select: 'select',
            territory_destroy: 'destroy',
            territory_reorder: 'reorder',
            territory_clear: 'clear',
            territory_clear_item: 'clear_item',
        }),

    },
}
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>

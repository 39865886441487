import Vue from 'vue'

const componentTypes = { G: 'Global', A: 'Alpha' }

export default function register(componentName, prefix) {
    Vue.component(componentName, (resolve) => {
        const folderName = componentTypes[prefix]
        if (prefix === 'A') {
            resolve(require(`@/components/${folderName}/${componentName}.vue`).default)
            // import(`@/components/${folderName}/${componentName}.vue`).then(asynComponent => {
            //     resolve(asynComponent.default)
            // })
        } else {
            const compName = folderName + componentName.substring(1)
            resolve(require(`@/components/${folderName}/${compName}.vue`).default)
        }
    })
}

<template>
    <div v-bind="$attrs" v-on="$listeners">
        <a-tooltip bottom nudge-right="40">
            <template v-slot:activator="{ on }">
                <div v-on="on" class="u-flex align-center">
                    <div class="u-rotate-45" style="margin-top: -2px">
                        <a-icon :size="iconSize" class="font-weight-medium" :class="customColor">attach_file</a-icon>
                    </div>
                    <span class="u-font-weight-semibold ml-1" :class="customColor" :style="{ fontSize: fontSize + 'px' }">{{ count }}</span>
                </div>
            </template>
            <span>Attachments</span>
        </a-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        count: {
            type: String | Number,
            default: 0
        },

        fontSize: {
            type: String | Number,
            default: '16'
        },

        iconSize: {
            type: String | Number,
            default: '16'
        },

        customColor: {
            type: String,
            default: 'grey--text text--darken-1'
        }
    }
}
</script>

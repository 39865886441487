<template>
    <span v-if="confirm_delete && confirm_delete === contentId" class="u-rounded-corners-lg u-cursor-pointer d-inline-flex align-center justify-center" v-ripple style="width: 24px;height: 24px;" @click="$emit('destroy', contentId)">
        <a-tooltip bottom>
            <template v-slot:activator="{ on }">
                <a-icon v-on="on" :size="iconSize" color="red darken-1">delete</a-icon>
            </template>
            <span class="md-body-2">Confirm delete?</span>
        </a-tooltip>
    </span>
    <span v-else class="u-rounded-corners-lg u-cursor-pointer d-inline-flex align-center justify-center" v-ripple style="width: 24px; height: 24px;" @click="local_confirm_delete">
        <svg xmlns="http://www.w3.org/2000/svg" :width="iconSize" :height="iconSize" fill="currentColor" class="bi bi-trash grey--text text--darken-1" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
    </span>
</template>

<script>
export default {
    props: {
        contentId: {
            type: String,
        },
        iconSize: {
            type: Number | String,
            default: 16
        }
    },

    data () {
        return {
            confirm_delete: null
        }
    },

    beforeDestroy () {
        this.confirm_delete = null
    },

    methods: {
        local_confirm_delete () {
            this.confirm_delete = this.contentId
            setTimeout(() => this.confirm_delete = null, 3000)
        }
    }
}
</script>

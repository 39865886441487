<template>
    <div>
        <div class="mb-4">Activities</div>
        <div class="u-flex mb-6">
            <div v-if="local_has_permission()" class="mr-4" style="width: 240px">
                <SharedDropdownWrapper
                    :isActive="local_type_filter.value !== 'all'"
                    :list="local_activiy_type_list"
                    item-value="value"
                    item-text="label"
                    @action="e => local_type_filter_set(e.label, e.value)"
                    flat
                >
                    <span class="md-body-2 mx-1" v-if="!mixinIsLoading('root')">
                        <template>Type:</template>
                        {{ local_type_filter.label }}
                    </span>
                    <span v-else>
                        <loader-template height="24" width="140px" class="u-rounded-corners ml-2"></loader-template>
                    </span>
                </SharedDropdownWrapper>
            </div>
        </div>
        <div v-if="!mixinIsLoading('root')" class="my-8">
            <template v-if="local_list_length">
                <div v-for="(history, index) in local_render" :key="history.id" :class="{ 'mt-6': index !== 0 }" class="c-activity u-wfull u-flex-center-y" style="letter-spacing: -0.26px">
                    <div class="c-activity__icon mr-5">
                        <a-avatar :color="localIfLogIsConfidenceScore(history) ? 'deep-purple lighten-5' : 'green lighten-5'" size="40" class="u-flex-center">
                            <a-icon size="24" :color="localIfLogIsConfidenceScore(history) ? 'deep-purple' : 'green darken-2'">{{ localIfLogIsConfidenceScore(history) ? 'scoreboard' : 'wifi_protected_setup' }}</a-icon>
                        </a-avatar>
                    </div>
                    <div style="height: 64px" class="u-border u-flex-center-y justify-space-between u-rounded-corners-xl px-6 u-wfull">
                        <div class="u-flex">
                            <div class="grey--text text--darken-1 font-weight-medium">
                                <template  v-if="localIfLogIsConfidenceScore(history)">
                                    <span>{{ local_date_output(history.updated_at) }}</span>
                                </template>
                                <template  v-else>
                                    <span>{{ local_date_output(history.created_at) }}</span>
                                </template>
                            </div>
                            <div class="grey--text text--darken-1">
                                <!-- Confidence score log -->
                                <template v-if="localIfLogIsConfidenceScore(history)">
                                    <span v-if="history.logs && history.logs.length && history.logs[0].action === 'project_update'">
                                        <template>
                                            <a-icon size="8" class="px-3" color="grey lighten-1">fiber_manual_record</a-icon>
                                            <template v-if="history.updated_by && history.updated_by.name">
                                                <span>Updated by</span>
                                                <span :title="history.updated_by.name" class="font-weight-medium"> {{ history.updated_by.name | truncateText(local_is_lgdown ? 14 : 50) }}</span>
                                            </template>
                                        </template>
                                    </span>
                                    <div v-else class="u-flex align-center font-weight-medium">
                                        <a-icon size="8" class="px-3" color="grey lighten-1">fiber_manual_record</a-icon>
                                        <a-icon size="20" color="deep-purple">auto_mode</a-icon>
                                        <span class="ml-2">Configuration Update</span>
                                    </div>
                                </template>
                                <!-- Stage change log -->
                                <template v-else>
                                    <a-icon size="8" class="px-3" color="grey lighten-1">fiber_manual_record</a-icon>
                                    <span>Updated by</span>
                                    <template v-if="history && history.created_by && history.created_by.name">
                                        <span class="font-weight-medium" :title="history.created_by.name"> {{ history.created_by.name | truncateText(local_is_lgdown ? 14 : 50) }}</span>
                                    </template>
                                    <template v-else>
                                        <span class="font-weight-medium text-capitalize"> {{ history.created_by_type }}</span>
                                    </template>
                                </template>
                            </div>
                        </div>
                        <!-- Stage change log -->
                        <template v-if="!localIfLogIsConfidenceScore(history)">
                            <div class="grey--text text--darken-3 u-flex align-center">
                                <div class="u-flex align-center">
                                    <template v-if="history.stage_to && history.stage_to.id && history.stage_to.workspace">
                                        <span :title="history.stage_to.workspace.title">{{ history.stage_to.workspace.title | truncateText(local_is_lgdown ? 8 : 30) }}</span>
                                        <a-icon size="8" class="px-3" color="grey lighten-1">fiber_manual_record</a-icon>
                                    </template>
                                    <template v-else-if="project_item.workspace">
                                        <span :title="project_item.workspace.title">{{ project_item.workspace.title | truncateText(local_is_lgdown ? 8 : 5) }}</span>
                                        <a-icon size="8" class="px-3" color="grey lighten-1">fiber_manual_record</a-icon>
                                    </template>
                                    <span class="u-flex align-center mr-2 text-capitalize"
                                    >
                                        <template v-if="history.stage_to">
                                            <span :title="history.stage_to.name">
                                                {{ history.stage_to.name | truncateText(local_is_lgdown ? 8 : 30) }}
                                            </span>
                                        </template>
                                        <template v-if="!history.stage_to && history.status_to">
                                            <span>{{ history.status_to }}</span>
                                        </template>
                                    </span>
                                </div>
                                <div class="ml-8">
                                    <a-btn color="blue darken-2" @click="local_show_history_logs(history, 'stage_change')" text>View changes</a-btn>
                                </div>
                            </div>
                        </template>
                        <!-- Confidence score log -->
                        <template v-else>
                            <div class="u-flex align-center" v-if="history && history.new_values">
                                <div class="grey--text text--darken-3 u-flex align-center">
                                    <a-icon size="20" color="grey darken-1" class="mr-2" outlined>scoreboard</a-icon>
                                    <span
                                        :title="history.new_values.current_score"
                                    >
                                        {{ history.new_values.current_score ? `${history.new_values.current_score}` : '-' | truncateText(local_is_lgdown ? 5 : 20) }} /
                                        <span :title="history.new_values.total">
                                            {{ `${history.new_values.total}` | truncateText(local_is_lgdown ? 5 : 20) }}
                                        </span>
                                    </span>
                                </div>
                                <div v-if="history.new_values.current_score"  class="px-2">- <span style="font-weight: 600">{{ Math.round((history.new_values.current_score/history.new_values.total)* 100) }}%</span></div>
                                <div class="ml-8">
                                    <a-btn color="blue darken-2" style="letter-spacing: 0.5px !important" @click="local_show_history_logs(history, 'confidence_score')" text>View changes</a-btn>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <template v-if="!mixinIsLoading('root') && local_render && !local_render.length">
                <div class="u-flex flex-column align-center mt-16">
                    <div class="grey lighten-3 u-flex justify-center mb-6" style="width: 80px; height: 80px; border-radius: 50%">
                        <a-icon color="grey" size="48">history</a-icon>
                    </div>
                    <div class="text-uppercase font-weight-medium grey--text text--darken-4 mb-1">No Activities found</div>
                    <div class="grey--text">
                        <p>The activities related to {{ local_type_filter.value === 'all' ? 'confidence score and stage change are' : local_type_filter.value === 'stage_change' ? 'stage change is' : 'confidence score is'}} not yet available</p>
                    </div>
                </div>
            </template>
        </div>
        <div v-else class="text-center mt-10 grey--text text--darken-1">Fetching Activities...</div>
        <div v-if="local_list_length && !mixinIsLoading('root') && local_type_filter.value !== 'all'" class="u-flex-center-y mt-8 u-sticky u-wfull" style="bottom: 0px; left: 0px;">
            <a-avatar color="transparent" size="40" class="u-flex-center mr-5 u-opacity-70">
                <a-avatar color="transparent" size="36" class="u-flex-center">
                    <!-- <a-icon size="24" color="white">tag</a-icon> -->
                </a-avatar>
            </a-avatar>
            <SPagination
                :pagination="local_type_filter.value === 'stage_change' ? project_stage_history_meta : project_confidence_score_log_meta"
                :hide="!local_show_paginate_section"
                :loading="mixinIsLoading('root') || paginate_loading"
                :class="{ 'pa-4': !local_show_paginate_section }"
                @paginate="local_paginate"
            />
        </div>
        <a-sheet v-else class="transparent" height="54"></a-sheet>

        <!-- View changes dialog -->
        <a-dialog v-model="dialog_activity_details" max-width="884">
            <PartialActivityDetails
                :item="local_history_item"
                :activity-type="local_activity_type"
                :loading="mixinIsLoading('detail_view')"
                @close="dialog_activity_details = false"
            >
            </PartialActivityDetails>
        </a-dialog>
    </div>
</template>

<script>
import SharedDropdownWrapper from "../../components/Shared/SharedDropdownWrapper.vue"
import PartialActivityDetails from "./Partials/PartialActiviyDetails.vue"
import { mapState, mapActions } from 'vuex'
import { diffSimple } from '@/helpers/helper-date-filter.js'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import mixinGetPreference from '@/mixins/mixin-get-preference'
import { SPagination } from '@/config/config-shared-components'

export default {
    mixins: [mixinLoading, mixinGetPreference],

    components: { SharedDropdownWrapper, PartialActivityDetails, SPagination },

    data () {
        return {
            local_activity_type: null,
            local_type_filter: { label: 'All activities', value: 'all'},
            local_activiy_type_list: [
                { label: 'All Activities', value: 'all' },
                { label: 'Confidence Score', value: 'confidence_score' },
                { label: 'Stage Change', value: 'stage_change' },
            ],
            backup: [],
            local_render: [],
            dialog_activity_details: false,
            local_history_item: {},
            score_log_filters: {
                'sort': '-order',
                'include': 'updatedBy,logs',
                'fields[confidence_score_logs]': 'id,updated_at,created_at,updated_by_id,project_id,old_values,new_values,order',
                'fields[logs]': 'id,log_id,log_type,action',
                'fields[updated_by]': 'id,name,email'
            },
            logs_filters: {
                'include': 'updatedBy,logs',
                'fields[logs]': 'id,log_id,log_type,action,section,field_name,field_type,old_values,new_values,order'
            },
            project_filters: {
                'include': 'workspace,salesforceBridge,hubspotBridge',
                'fields[workspace]': 'id,title',
                'fields[projects]': 'id,title,workspace_id'
            },
            activities_count: 100,
            paginate_loading: false,
            loaded_log_details_ids: []
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        local_is_lgdown () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        local_list_length () {
            return this.local_render && !!this.local_render.length
        },

        local_show_paginate_section () {
            if (this.local_type_filter && this.local_type_filter.value === 'stage_change') return this.project_stage_history_meta && (this.project_stage_history_meta.current_page >= 1 && this.project_stage_history_meta.last_page !== 1 && this.project_stage_history_meta.total > this.activities_count)
            return this.project_confidence_score_log_meta && (this.project_confidence_score_log_meta.current_page >= 1 && this.project_confidence_score_log_meta.last_page !== 1 && this.project_confidence_score_log_meta.total > this.activities_count)
        },

        local_get_service_name () {
            if (this.project_item && this.project_item.salesforce_bridge) return this.project_item.salesforce_bridge.service
            return this.project_item.hubspot_bridge.service
        },

        ...mapState('ProjectView', {
            project_item: 'item',
            project_stage_history: 'stage_history',
            project_stage_history_meta: 'stage_history_meta',
            project_confidence_score_history: 'confidence_score_log',
            project_confidence_score_log_meta: 'confidence_score_log_meta',
        }),

        ...mapState('ProjectActivities', {
            activity_item: 'item',
        }),

        ...mapState('User', {
            user_self: 'self'
        }),

        ...mapState('Preference', {
            preference_list: 'list'
        })
    },

    methods: {
        async local_index () {
            this.mixinSetLoading('root')
            await this.preference_index()
            await this.local_get_all_activities()
            await this.project_show({ id: this.$route.params.id, params: { ...this.project_filters } })
            this.mixinResetLoading('root')
        },

        async local_get_all_activities () {
            if (this.local_has_permission()) await this.local_get_score_activity()
            await this.local_get_stage_change_activity()
            this.local_set_activity_list()
        },

        local_set_activity_list () {
            if (this.local_type_filter.value === 'all') {
                const list_params = this.local_has_permission() ? [...this.project_confidence_score_history, ...this.project_stage_history] : [...this.project_stage_history]
                const grouped_list = _.orderBy(list_params, 'created_at')
                grouped_list.reverse()
                this.local_render = _.cloneDeep(grouped_list)
            }
        },

        local_get_activity_list (params) {
            params = params ?? { page: 1, count: this.activities_count }
            if (params.type === 'stage_change') return this.local_get_stage_change_activity(params)
            this.local_get_score_activity(params)
            this.mixinResetLoading('root')
        },

        async local_get_score_activity (params) {
            params = params ?? { page: 1, count: this.activities_count }
            await this.project_confidence_score_log_index({
                project_id: this.$route.params.id,
                params: {...params, ...this.score_log_filters}
            })
            this.local_render = _.cloneDeep(this.project_confidence_score_history)
        },

        async local_get_stage_change_activity (params) {
            params = params ?? { page: 1, count: this.activities_count }
            await this.project_stage_history_index({
                id: this.$route.params.id,
                params: {
                    ...params,
                    'include': 'stageTo,stageFrom,stageTo.workspace,stageFrom.workspace,createdBy',
                    'fields[stage_to]': 'id,name,workspace_id',
                    'fields[stage_from]': 'id,name,workspace_id',
                    'fields[created_by]': 'id,email,name',
                    'fields[stage_to.workspace]': 'id,title',
                    'fields[stage_from.workspace]': 'id,title',
                    'sort': '-created_at'
                }
            })
            this.local_render = _.cloneDeep(this.project_stage_history)
        },

        local_type_filter_set (label, value) {
            if (this.local_type_filter.value === value) return
            this.local_type_filter.label = label
            this.local_type_filter.value = value
            this.local_filter_list()
        },

        async local_filter_list () {
            this.mixinSetLoading('root')
            this.loaded_log_details_ids = []
            if (this.local_type_filter.value === 'all') this.local_get_all_activities()
            if (this.local_type_filter.value === 'confidence_score') await this.local_get_score_activity()
            if (this.local_type_filter.value === 'stage_change') await this.local_get_stage_change_activity()
            this.mixinResetLoading('root')
        },

        async local_show_history_logs (item, type) {
            this.mixinSetLoading('detail_view')
            this.local_activity_type = type
            this.dialog_activity_details = true
            if (type === 'stage_change') {
                this.local_history_item = _.cloneDeep(item)
                this.mixinResetLoading('detail_view')
                return
            }
            if (!!_.size(item.logs)) this.local_activity_type = item.logs[0].action
            if (this.loaded_log_details_ids.indexOf(item.id) !== -1) return this.local_show_history_details(item)
            this.local_get_activity_details(item.id)
        },

        async local_get_activity_details (id) {
            await this.activity_show({ id: id, params: { ...this.logs_filters }})
            this.local_history_item = _.cloneDeep(this.activity_item)

            const group_fields = _.groupBy(this.local_history_item.logs, 'section.id')
            this.local_history_item.modification_logs = _.cloneDeep(group_fields)

            this.mixinResetLoading('detail_view')
            this.project_log_details_update(this.local_history_item)

            if (this.loaded_log_details_ids.indexOf(this.local_history_item.id) === -1) this.loaded_log_details_ids.push(this.local_history_item.id)
        },

        local_show_history_details (item) {
            const detail_item_log = this.project_confidence_score_history.find(history => history.id === item.id)
            this.local_history_item = _.cloneDeep(detail_item_log)
            this.mixinResetLoading('detail_view')
        },

        local_date_output (date) {
            return diffSimple(date, {type: 'includeYear'})
        },

        async local_paginate () {
            this.paginate_loading = true
            const page_params = this.local_type_filter.value === 'stage_change' ? this.project_stage_history_meta.current_page : this.project_confidence_score_log_meta.current_page
            await this.local_get_activity_list({
                type: this.local_type_filter.value,
                page: page_params,
                count: this.activities_count
            })
            this.paginate_loading = false
        },

        local_has_org_preference () {
            if (!_.size(this.preference_list)) return
            const preferenceItem = this.preference_list.find(item => item.key === 'organization.confidence_score')
            return preferenceItem.value === 'Yes'
        },

        local_has_permission () {
            const orgHasConfidenceScore = this.user_self && this.user_self.organization && this.user_self.organization.flag_confidence_score === 1
            return orgHasConfidenceScore && this.local_has_org_preference() && this.$can('poc_confidence_score.index')
        },

        localIfLogIsConfidenceScore (history) {
            return (history && !history.stage_to && history.status_to !== 'archived')
        },

        ...mapActions('ProjectView', {
            project_show: 'show',
            project_stage_history_index: 'stage_history',
            project_confidence_score_log_index: 'confidence_score_log_index',
            project_log_details_update: 'log_details_update'
        }),

        ...mapActions('ProjectActivities', {
            activity_show: 'show',
        }),

        ...mapActions('Preference', {
            preference_index: 'index',
        })
    }
}
</script>

<style lang="scss" scoped>
.c-activity {
    &__icon {
        position: relative;
        &::before, &::after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 1px;
            background-image: linear-gradient(90deg, #E0E0E0, #E0E0E0);
            height: 100%;
            top: -60%;
        }

        &::after {
            top: auto;
            bottom: -100%;
        }
    }

    &:first-child {
        .c-activity__icon {
            &::before {
                height: 60%;
                background-image: linear-gradient(10deg, #E0E0E0, #E0E0E0);
            }
        }
    }

    &:last-child {
        .c-activity__icon {
            &::after {
                content: none;
            }
        }
    }
}
</style>

<template>
    <div>
        <div class="mb-4 u-flex-center-y">
            <h3 class="text-h6 grey--text text--darken-3 font-weight-medium">Links</h3>
            <a-spacer></a-spacer>
            <div class="mb-1" v-if="link_list && !link_list.length">
                <PLinksMoreOption
                    v-if="$can('templates.store') || (permission_link('store') && $can('templates.index') && $can('projects.update-only'))"
                    :linksCount="link_list && link_list.length"
                    @import="local_template_import_form()"
                    @export="local_template_export_form()"
                />
            </div>
        </div>

        <a-responsive class="my-4" v-if="page_loading">
            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Links..</h3>
        </a-responsive>

        <template v-if="!page_loading">
            <div class="u-flex-center-y" v-if="link_list && link_list.length > 0">
                <h4 class="md-subtitle-2 grey--text text--darken-1 text-uppercase">{{ link_list.length }} Link{{ link_list.length > 1 ? 's' : '' }} added</h4>
                <a-spacer></a-spacer>
                <PLinksMoreOption
                    v-if="$can('templates.store') || (permission_link('store') && $can('templates.index') && $can('projects.update-only'))"
                    :linksCount="link_list && link_list.length"
                    @import="local_template_import_form()"
                    @export="local_template_export_form()"
                />
            </div>

            <div class="mt-4 mb-4" v-if="permission_link('store')">
                <v-text-field
                    v-model="linkUrl"
                    @input="e => !e ? mixinClearError('store') : ''"
                    @keydown.enter="local_store_link"
                    @paste="{}"
                    ref="refLinkCreateInput"
                    placeholder="Add New Link & Hit Enter"
                    class="md-subtitle-1 u-border u-rounded-corners-lg"
                    :class="[{ 'c-wiggle-short u-border-error': mixinHasError('store') }]"
                    solo flat hide-details
                >
                    <template #prepend-inner>
                        <div class="mr-1">
                            <a-icon size="24" color="grey darken-1">add_circle_outline</a-icon>
                        </div>
                    </template>
                    <template #append v-if="mixinHasError('store')">
                        <a-tooltip bottom offset-y>
                            <template v-slot:activator="{ on }">
                                <a-icon color="red darken-1" size="20" v-on="on">info</a-icon>
                            </template>
                            <span>{{ mixinGetError('store') }}</span>
                        </a-tooltip>
                    </template>
                </v-text-field>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="link_response.server && link_response.server.errors && link_response.server.errors.url && link_form_mode == 'add'">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ link_response.server.errors.url[0] }}
                </span>
            </div>

            <a-responsive class="my-4 mt-12" v-if="!link_list.length">
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1">No Links Found</h3>
                <p class="md-body-2 text-center grey--text mb-0">Add your project specific links to share within your team or with customer.</p>
            </a-responsive>

            <div v-if="(link_list && link_list.length > 0) && $can('links.index')">
                <draggable v-model="link_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                    <div v-for="link in link_list" :key="link.id" :class="[{ 'c-loading-focus': mixinIsLoading(link.id) }, { 'js-draggable-file-list-item': permission_link('update') }]">
                        <a-card :class="{'yellow lighten-4': link.visibility === 'external'}" flat class="u-border u-cursor-pointer my-3">
                            <a-card-text class="pa-0">
                                <a-container container--fluid grid-list-xl class="py-3 px-6">
                                    <a-layout align-center>
                                        <a-flex shrink class="px-0" v-if="user_self && user_self.scope === 'internal'" :class="{'u-opacity-30': !permission_link('update')}">
                                            <a-icon class="grey--text text--lighten-1 js-drag-handle">drag_indicator</a-icon>
                                        </a-flex>
                                        <a-flex class="text-truncate">
                                            <h2 class="md-subtitle-1 primary--text text-truncate mb-1" :title="link.title">
                                                {{ link.title }}
                                            </h2>
                                            <a :href="link.url" target="_blank" rel="noopener noreferrer" :title="link.url" class="md-body-2 mb-0 blue--text text--darken-2 u-no-underline d-block text-no-wrap text-truncate" style="max-width: 600px;">{{ link.url }}</a>
                                        </a-flex>
                                        <a-spacer></a-spacer>
                                        <a-flex shrink class="u-flex-center-y" style="column-gap: 4px;">
                                            <g-confirmation class="d-inline-block" v-if="link.visibility == 'internal'" :can-update="permission_link('update')" :disabled="mixinIsLoading(link.id)" type="link" @action="permission_link('update') ? local_visibility_toggle(link.id, link.visibility) : ''">
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <a-btn text icon color="grey" class="mx-0" v-on="on" :disabled="mixinIsLoading(link.id) || loading" :loading="local_loading_get(link.id, 'visibility')">
                                                            <a-icon size="16">lock</a-icon>
                                                        </a-btn>
                                                    </template>
                                                    <span>Not Visible to Client</span>
                                                </a-tooltip>
                                            </g-confirmation>
                                            <a-tooltip bottom v-else>
                                                <template v-slot:activator="{ on }">
                                                    <a-btn text icon color="grey" class="mx-0" v-on="on" v-if="!permission_link('update')">
                                                        <a-icon size="16">lock_open</a-icon>
                                                    </a-btn>
                                                    <a-btn text icon color="grey" class="mx-0" v-on="on" v-if="permission_link('update')" :loading="local_loading_get(link.id, 'visibility')" @click="permission_link('update') ? local_visibility_toggle(link.id, link.visibility) : ''">
                                                        <a-icon size="16">lock_open</a-icon>
                                                    </a-btn>
                                                </template>
                                                <span>Visible to Client</span>
                                            </a-tooltip>
                                            <!-- <template> -->
                                                <!-- <a-tooltip bottom v-if="link.visibility === 'external'">
                                                    <template v-slot:activator="{ on }">
                                                        <a-btn text icon color="grey" class="mx-0" v-on="on" :disabled="mixinIsLoading(link.id) || loading" @click="local_visibility_toggle(link.id, link.visibility)" :loading="local_loading_get(link.id, 'visibility')">
                                                            <a-icon size="16">lock</a-icon>
                                                        </a-btn>
                                                    </template>
                                                    <span>Visible to Client</span>
                                                </a-tooltip>
                                                <a-tooltip bottom v-else>
                                                    <template v-slot:activator="{ on }">
                                                        <a-btn text icon color="grey" class="mx-0" v-on="on" :disabled="mixinIsLoading(link.id) || loading" :loading="local_loading_get(link.id, 'visibility')" @click="local_visibility_toggle(link.id, link.visibility)">
                                                            <a-icon size="16">lock_open</a-icon>
                                                        </a-btn>
                                                    </template>
                                                    <span>Not Visible to Client</span>
                                                </a-tooltip> -->
                                            <!-- </template> -->
                                            <a-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <a-btn icon text small color="grey" class="mx-0" v-on="on" :disabled="mixinIsLoading(link.id) || loading" @click="local_edit(link.id)" v-if="permission_link('update')">
                                                        <a-icon size="16">edit</a-icon>
                                                    </a-btn>
                                                </template>
                                                <span>Edit</span>
                                            </a-tooltip>
                                            <template v-if="permission_link('destroy')">
                                                <a-btn icon text small color="grey" v-if="!local_is_selected(link.id)" class="mx-0" @click="local_add_item_to_delete(link.id)">
                                                    <a-icon size="16">delete</a-icon>
                                                </a-btn>
                                                <a-tooltip bottom v-else>
                                                    <template v-slot:activator="{ on }">
                                                        <a-btn icon text small color="red" class="mx-0" v-on="on" :disabled="mixinIsLoading(link.id) || loading" :loading="local_loading_get(link.id, 'destroy')" @click="local_destroy(link.id)">
                                                            <a-icon size="16">delete</a-icon>
                                                        </a-btn>
                                                    </template>
                                                    <span>Confirm?</span>
                                                </a-tooltip>
                                            </template>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-card-text>
                        </a-card>
                    </div>
                </draggable>
            </div>
        </template>

        <a-dialog v-model="dialog_link_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="local_store()">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>link</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex v-if="link_form_mode == 'add'">
                                    <h2 class="md-heading-6 primary--text text--darken-1">Add New Link</h2>
                                    <!-- <p class="md-subtitle-2 mb-0">Add new link.</p> -->
                                </a-flex>
                                <a-flex v-if="link_form_mode == 'edit'">
                                    <h2 class="md-heading-6 primary--text text--darken-1">Edit Link Information</h2>
                                    <p class="md-subtitle-2 mb-0">Edit information related to link.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title (Optional)</label>
                                    <a-text-field
                                        v-model="link_item.title"
                                        placeholder="Enter Link Title"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="link_response.server && link_response.server.errors && link_response.server.errors.title">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ link_response.server.errors.title[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        This will help you identify the link.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">URL</label>
                                    <a-text-field
                                        v-model="link_item.url"
                                        placeholder="Enter URL"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="link_response.server && link_response.server.errors && link_response.server.errors.url">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ link_response.server.errors.url[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Enter the URL to store.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_store()" :loading="loading" :disabled="loading">Save Link</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_link_form_cancel()" :disabled="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-dialog v-model="dialog_import_form" max-width="800">
            <a-card>
                <a-container container--fluid grid-list-xl class="pa-6">
                    <a-layout wrap align-center>
                        <a-flex shrink class="pr-1">
                            <a-avatar class="primary darken-1" size="40">
                                <a-icon size="18" dark>system_update_alt</a-icon>
                            </a-avatar>
                        </a-flex>
                        <a-flex>
                            <h2 class="md-heading-6 grey--text text--darken-3">Import Links from Template</h2>
                            <p class="md-subtitle-2 grey--text text--darken-1 mb-0">You can import multiple link into projects.</p>
                        </a-flex>
                        <a-flex shrink>
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a-btn small text icon color="grey"  @click="dialog_import_form = !dialog_import_form" class="mx-0 mt-0 mr-0 mb-0 pa-0 px-3 right" v-on="on">
                                        <a-icon size="24">clear</a-icon>
                                    </a-btn>
                                </template>
                                <span>Close</span>
                            </a-tooltip>
                        </a-flex>
                    </a-layout>
                </a-container>
                <a-divider></a-divider>
                <a-card-text class="pa-0">
                    <template v-if="!local_import_template_group">
                        <a-responsive class="py-4" v-if="!loading">
                            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Template Found</h3>
                            <p class="md-body-2 text-center grey--text mb-0">There is no documents template present in your Organization<br></p>
                            <p class="md-body-2 text-center grey--text mb-0"><a-icon size="22" color="orange">emoji_objects</a-icon>You can create new template manually under Settings or export documents from existing project.</p>
                        </a-responsive>
                    </template>

                    <template v-else>
                        <template v-if="$can('links.index')">
                            <div class="u-rounded-corners" v-if="local_import_template_group.links && local_import_template_group.links.length">
                                <a-sheet class="u-rounded-corners" v-for="(group, index) in local_import_template_group.links" :key="group.id">
                                    <a-divider v-if="index !== 0"></a-divider>
                                    <div class="u-flex-center-y pa-4 px-6">
                                        <h3 class="md-body-1">
                                            {{ group.name }} <span class="md-body-2 grey--text text--darken-1">{{ group.template_links_count === 1 ? `${group.template_links_count} Link` : `${group.template_links_count} Links` }}</span>
                                        </h3>
                                        <a-spacer></a-spacer>
                                        <template v-if="permission_link('update')">
                                            <a-btn color="primary lighten-1" class="elevation-0" v-if="group.type === 'links'" @click="local_template_import(group.id)" small :loading="currentImportId === group.id" :disabled="currentImportId || group.template_links_count == 0 || loading">Import</a-btn>
                                        </template>
                                    </div>
                                </a-sheet>
                            </div>
                            <template v-else>
                                <a-responsive class="py-1" v-if="!loading">
                                    <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1">No Link Templates Found</h3>
                                    <p class="md-body-2 text-center grey--text mb-0">There is no links template present in your Organization<br></p>
                                    <p class="md-body-2 text-center grey--text mb-0"><a-icon size="22" color="orange">emoji_objects</a-icon>You can create new template manually under Settings or export links from existing projects.</p>
                                </a-responsive>
                            </template>
                        </template>
                    </template>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-dialog v-model="dialog_export_link_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>link</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Export Links as Template</h2>
                                    <p class="md-subtitle-2 mb-0">You can save list of links to import them in new projects.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <v-radio-group v-model="export_mode" row hide-details class="mt-0" @change="local_export_mode_change">
                                        <v-radio label="New Template" value="new"></v-radio>
                                        <v-radio label="Existing Template" value="existing" :disabled="!$can('templates.update') || !$can('templates.index')"></v-radio>
                                    </v-radio-group>
                                </a-flex>
                                <a-flex xs12 v-if="export_mode == 'new'">
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Group Name</label>
                                    <a-text-field
                                        v-model="template_link_item.name"
                                        placeholder="Enter Template Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_group_response.server && template_group_response.server.errors && template_group_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_group_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the user.
                                    </span>
                                </a-flex>
                                <a-flex xs12 v-if="export_mode == 'existing'">
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Group</label>
                                    <a-autocomplete
                                        v-model="template_link_item.id"
                                        :items="template_group_list"
                                        item-text="name"
                                        item-value="id"
                                        placeholder="Select Template"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-autocomplete>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_link_response.server && template_link_response.server.errors && template_link_response.server.errors.template_group_id">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_link_response.server.errors.template_group_id[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the user.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-4">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_link_template_export()" :loading="loading" :disabled="loading">Save Template</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_export_link_form = !dialog_export_link_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-snackbar v-model="snackbar_status" :timeout="5000" bottom center>
            {{snackbar_text}} Imported Successfully!
        </a-snackbar>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TemplateImportStatus from '@/mixins/mixin-template-import-status'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import mixinErrors from '@/mixins/mixin-module-error-setup'
import { PLinksMoreOption } from './Partials'

export default {
    mixins: [
        TemplateImportStatus,
        mixinLoading,
        mixinErrors
    ],

    components: { PLinksMoreOption },

    data () {
        return {
            linkUrl: '',
            currentImportId: null,
            export_mode: 'new',
            dialog_link_form: false,
            dialog_import_form: false,
            dialog_export_link_form: false,
            dialog_download_link: false,
            loading_item: null,
            loading_action: null,
            data_template_group_list: [],
            delete_items_list: [],
            snackbar_status: false,
            snackbar_text: '',
            page_loading: true,
            form_add_mode: 'upload',
            loading_file: false,
            local_link_filters: { 'filter[project_id]': this.$route.params.id, 'sort': 'order' },
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        link_list: {
            get() {
                return this.$store.state.Link.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },
        local_import_template_group() {
            let group = _.groupBy(this.data_template_group_list, 'type')
            return _.isEmpty(group) ? false : group
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('TemplateGroup', {
            template_group_list: 'list',
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),
        ...mapState('TemplateLink', {
            template_link_item: 'item',
            template_link_response: 'response',
        }),
        ...mapState('Link', {
            link_item: 'item',
            link_filters: 'filters',
            link_response: 'response',
            link_form_mode: 'form_mode',
        }),
        ...mapState('ProjectView', {
            project_item: 'item',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_index() {
            if (!this.$can('links.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.$vuetify.goTo(0)
            await this.link_clear()
            await this.local_fetch_link()
            this.page_loading = false

            if(!this.project_item) await this.project_show({id: this.$route.params.id})
        },

        async local_fetch_link() {
            await this.link_index(this.local_link_filters)
        },
        async dialog_link_form_open() {
            await this.link_clear_item()
            this.dialog_link_form = true
        },
        async local_edit(id) {
            this.dialog_link_form = true
            await this.link_select({id: id})
        },

        async local_store_link () {
            const params = this.$appendId({ url: this.linkUrl, project_id: this.$route.params.id, order: -1 })
            this.mixinSetLoading(params.id)
            this.mixinClearError('store')
            await this.link_store(params)
            if (this.link_response && this.link_response.status === 'success') {
                this.link_add_item({ ...params, title: 'Untitled Link' })
                this.linkUrl = ''
                await this.link_show({ id: params.id })
            }
            this.mixinResetLoading(params.id)
        },

        local_validate_url (url, slug) {
            this.url_error = null
            const regex = new RegExp('^(https?:\\/\\/)?'+'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+'((\\d{1,3}\\.){3}\\d{1,3}))'+'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+'(\\?[;&a-z\\d%_.~+=-]*)?'+'(\\#[-a-z\\d_]*)?$','i');
            const isValid = regex.test(url)
            const maxLengthBreach = url && url.length > 2048

            if (!isValid) this.mixinSetError({ slug, message: 'Not a valid URL' })
            if (maxLengthBreach) this.mixinSetError({ slug, message: 'Maximum of 2048 characters' })
            return isValid && !maxLengthBreach
        },
        async local_store() {
            if (this.link_form_mode === 'add') {
                this.link_item.project_id = this.$route.params.id
                this.link_item.order = -1
                await this.link_store(this.link_item)
            }
            if (this.link_form_mode === 'edit') {
                await this.link_update({ ...this.link_item, mode: 'show' })
            }
            if (this.link_response && this.link_response.status === 'success') {
                this.dialog_link_form = false
                await this.link_clear_item()
            }
        },
        async local_link_form_cancel() {
            this.dialog_link_form = false
            await this.link_clear_item()
        },
        async local_destroy(id) {
            await this.local_loading_set(id, 'destroy')
            await this.link_destroy({id: id})
            await this.local_loading_clear()
        },
        local_add_item_to_delete (id) {
            this.delete_items_list.push(id)
            setTimeout(() => this.local_remove_delete_item(id), 3000)
        },
        local_remove_delete_item (id) {
            this.delete_items_list.splice(this.delete_items_list.indexOf(id))
        },
        local_is_selected (id) {
            return this.delete_items_list.includes(id)
        },
        async local_visibility_toggle(id, status) {
            await this.local_loading_set(id, 'visibility')
            await this.link_select({id: id})
            this.link_item.visibility = status === 'external' ? 'internal' : 'external'
            await this.link_visibility(this.link_item)
            await this.local_loading_clear()
            if (this.template_link_response.status === 'success') {
                await this.link_clear_item()
            }
        },
        async local_reorder(list) {
            await this.link_reorder({list: list})
        },
        async local_template_import_form() {
            if (this.$can('templates.index') == true) {
                this.data_template_group_list = [];
                await this.template_group_clear()
                await this.template_group_index( { 'include': 'templateLinksCount', 'filter[type]': 'links', 'sort': 'order'})
                this.data_template_group_list = _.cloneDeep(this.template_group_list)
                this.local_import_template_group
            }
            this.dialog_import_form = !this.dialog_import_form
        },
        async local_template_export_form() {
            if (this.$can('templates.index') == true) {
                await this.template_group_clear_item()
                await this.template_link_clear_item()
                await this.template_group_index( { 'filter[type]': 'links', 'sort': 'order'} )
            }
            this.dialog_export_link_form = !this.dialog_export_link_form
            this.template_link_item.name = null
            this.template_link_item.id = null
            this.export_mode = 'new'
        },
        async local_template_import(id) {
            this.currentImportId = id
            const import_item = {}
            import_item.project_id = this.$route.params.id
            import_item.template_group_id = id
            await this.link_template_import(import_item)
            if (this.link_response.status !== 'success') return
            this.link_index(this.local_link_filters)
            this.currentImportId = null
            this.$notify('success', 'Links imported successfully!')
        },
        local_export_mode_change () {
            this.template_group_clear_item()
            if (this.dialog_export_document_form) return this.template_document_clear_item()
            this.template_link_clear_item()
        },
         async local_link_template_export() {
            if (this.export_mode === 'new') return this.local_link_template_group_store()
            this.local_link_template_export_action(this.template_link_item.id)
        },
        async local_link_template_group_store() {
            await this.template_group_store({ name: this.template_link_item.name, type: 'links' })
            if (!this.$status(this.template_group_response))  return
            this.local_link_template_export_action(this.template_group_item.id)
        },
        async local_link_template_export_action(group_id) {
            const link_ids = this.link_list.map(item => item.id);
            const link_template_export_tem = {}
            link_template_export_tem.data = link_ids
            link_template_export_tem.template_group_id = group_id
            await this.template_link_export(link_template_export_tem)
            if (!this.$status(this.template_link_response)) return
            this.dialog_export_link_form = false
            this.$notify('success', 'Links exported successfully!')
        },
        async local_loading_set(id, action) {
            this.loading_item = id
            this.loading_action = action
        },
        async local_loading_clear() {
            this.loading_item = null
            this.loading_action = null
        },
        local_loading_get(id, action) {
            return this.loading && this.loading_item == id && this.loading_action == action
        },
        permission_link(type) {
            return this.$can(`links.${type}`) && this.$can('projects.update-only')
        },
        ...mapActions('ProjectView', {
            project_show: 'show',
        }),
        ...mapActions('Link', {
            link_index: 'index',
            link_show: 'show',
            link_store: 'store',
            link_add_item: 'add_item',
            link_visibility: 'visibility',
            link_update: 'update',
            link_select: 'select',
            link_destroy: 'destroy',
            link_reorder: 'reorder',
            link_template_import: 'template_import',
            link_clear: 'clear',
            link_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_store: 'store',
            template_group_clear: 'clear',
            template_group_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateLink', {
            template_link_export: 'template_export',
            template_link_clear: 'clear',
            template_link_clear_item: 'clear_item',
        }),
    }
}
</script>

<style>
.dropzone-custom-content {
    padding: 2rem;
    border: 2px dashed #c1c1c1 !important;
    border-radius: .25rem;
    cursor: pointer;
}
.dz-preview {
    background-color: #fff;
    border-radius: .25rem;

    margin-top: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.06), 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.06) !important;
}
.dz-preview .dz-details {
    padding: 1rem;
}
.dz-preview .dz-details .dz-size,
.dz-preview .dz-details .dz-filename {
    display: inline-block;
    font-size: .825rem;
}
.dz-preview .dz-image,
.dz-preview .dz-success-mark,
.dz-preview .dz-error-mark {
    display: none;
}
.dz-preview .dz-progress .dz-upload {
    display: block;
    background-color: #43a047;
    height: 2px;
}
.dz-preview.dz-success {
    display: none;
}
.dz-preview.dz-error .dz-progress {
    display: none;
}
.dz-preview.dz-error .dz-error-message {
    padding: 1rem;
    padding-top: 0;
    font-size: .825rem;
    color: red;
}
.dz-preview.dz-error .dz-details {
    padding-bottom: 0;
}
</style>

<template>
    <div>
        <div @dragleave="$emit('leave')" @dragenter="$emit('enter')" class="c-drag-overlay"></div>
        <div
            class="c-add-file-info u-flex flex-column align-center"
            :class="[pageType === 'global' ? 'c-add-file-info__global' : `c-add-file-info__project-${pageType}`]"
            @dragenter="$emit('enterInfo')"
            @dragend="$emit('enter')"
        >
            <div class="mt-4">
                <a-img :src="require('../../assets/images/icon-drop-attachment.svg')" width="40" height="40"></a-img>
            </div>
            <div class="mt-3 mb-6 md-heading-6">Drop file to attach</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pageType: {
            type: String,
            required: true
        }
    }
}
</script>

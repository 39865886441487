<template>
    <a-menu bottom offset-y>
        <template v-slot:activator="{ on, value }">
            <a-card v-bind="$attrs" :height="height" :class="[flat ? 'u-border' : 'u-elevation-custom-1' ,'px-2 py-2 u-rounded-corners u-overflow-hidden u-flex-center-y justify-space-between']" v-on="on" :flat="flat">
                <div class="d-flex align-center">
                    <a-icon size="20" class="ma-0" :color="isActive ? 'blue' : 'grey lighten-1'">filter_list</a-icon>
                    <slot></slot>
                </div>
                <a-icon size="20" class="ma-0">arrow_drop_{{ value ? 'up' : 'down' }}</a-icon>
            </a-card>
        </template>
        <a-list class="u-list-condensed">
            <a-list-item v-for="(item, index) in list" :key="index + '_' + item[itemValue]" @click="$emit('action', item)">
                    <a-list-item-title>
                        <span class="md-body-2">{{ item[itemText] }}</span>
                    </a-list-item-title>
            </a-list-item>
        </a-list>
    </a-menu>
</template>

<script>
export default {
    props: {
        list: {
            type: Array
        },
        itemText: {
            type: String
        },
        itemValue: {
            type: String
        },
        isActive: {
            type: Boolean
        },
        height: {
            type: String | Number,
            default: 40
        },
        flat: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<template>
    <a-card class="px-6 pb-7">
        <div class="u-flex-center-y pt-6">
            <a-avatar color="red" size="36">
                <a-icon color="white" size="20">delete</a-icon>
            </a-avatar>
            <h2 class="md-heading-5 ml-3 grey--text text--darken-3">Delete Project</h2>
        </div>

        <div class="py-6">
            <p class="md-body-1 grey--text text--darken-3 mb-4">You are about to delete the project titled as "<span class="font-weight-bold">{{ project.title }}</span>" and this action cannot be undone.</p>
            <p class="md-body-1 grey--text text--darken-3 mb-0">If you would like to proceed deleting the project, please enter <span class="c-mono-font md-body-2 font-weight-bold">DELETE</span> in the following field.</p>
        </div>

        <div>
            <a-text-field
                v-model="deleteConfirmText"
                placeholder="Enter DELETE (all in uppercase)"
                background-color="grey lighten-5"
                class="u-border"
                solo flat hide-details autofocus
                v-test-input.text
            ></a-text-field>
            <div class="mt-5">
                <a-btn v-test-btn.delete class="ma-0 elevation-0 font-weight-medium deep-orange lighten-4 deep-orange--text text--darken-2" @click="localDeleteProject(project.id)" :disabled="deleteConfirmText !== 'DELETE'" :loading="deleteLoading">Delete Project</a-btn>
                <a-btn v-test-btn.cancel color="grey" class="ma-0 ml-3" text @click="$emit('close')">Cancel</a-btn>
            </div>
        </div>
    </a-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: 'projectDelete',
    props: {
        project: {
            type: Object
        },
        isOpen: {
            type: Boolean
        },
    },

    data () {
        return {
            deleteLoading: false,
            deleteConfirmText: '',
        }
    },

    watch: {
        isOpen: {
            handler (val) {
                this.deleteConfirmText = ''
            },
            immediate: true
        },
    },

    computed: {
        ...mapState('ProjectView', {
            project_response: 'response'
        })
    },

     methods: {
        async localDeleteProject (id) {
            this.deleteLoading = true
            await this.project_destroy({ id })
            if (this.project_response.status === 'success') {
                this.$notify('success', 'Deleted successfully! Redirecting to dashboard.')
                setTimeout(() => {
                    this.deleteLoading = false
                    this.$router.push({name: 'dashboard'})
                }, 1000)
            } else this.deleteLoading = false
        },

        ...mapActions('ProjectView', {
            project_destroy: 'destroy',
        })
    }
}
</script>

<style lang="scss" scoped></style>

<template>
	<div class="c-line-chart u-relative u-elevation-custom-1 u-rounded-corners-lg white">
        <div class="c-line-chart__column u-relative">
            <VueApexCharts
                ref="lineChart"
                :options="chart_options"
                :series="series"
                width="536"
                height="195"
            ></VueApexCharts>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
	name: 'LineVerticalChart',

    components: { VueApexCharts },

    data: function() {
        return {
            series: [
                {
                    name: "Desktops",
                    data: [75, 100, 125, 100, 175, 200, 300, 250, 75, 50, 125, 100]
                }
            ],
            chart_options: {
                chart: {
                    type: 'area',
                    width: 536,
                    height: 195,
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    padding: {
                        top: -16,
                        bottom: -8,
                        left: -6,
                        right: 0
                    }
                },
                legend: {
                    show: false,
                },
                colors: ['#9C27B0'],
                fill: {
                    colors: ['#e9b9f3'],
                    type: 'gradient',
                    gradient: {
                        type: 'vertical',
                        shadeIntensity: 0,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100]
                    }
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    labels: {
                        style: {
                            fontWeight: 700,
                        },
                    }
                },
                yaxis: {
                    labels: {
                        offsetX: -14
                    },
                },
                tooltip: {
                    y: {
                        title: {}
                    },
                    z: {}
                }
            },
        }
    }
}
</script>

<style scoped>
.c-line-chart {
    height: 227px;
    width: 568px;
}

.c-line-chart__column {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.c-line-chart__column > div {
    min-height: auto!important;
}
</style>

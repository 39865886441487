import store from '../models'
import router from '../router'

async function guardAuthenticate(to, from, next) {
    if (store.state.User.self.id) {
        return router.replace('/dashboard')
    } else return next()
}

export default guardAuthenticate

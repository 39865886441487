<template>
    <v-divider v-bind="$attrs" v-on="$listeners" :class="color" class="c-divider"></v-divider>
</template>

<script>
import { VDivider } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VDivider },
    props: {
        color: {
            type: String,
            default: 'u-divider'
        }
    }
}
</script>

import store from '../models'

async function guardGong(to, from, next) {
    if (!store.state.User.self.id) {
        await store.dispatch('User/me')
    }

    let list = store.state.Integration.list
    if (!list || !list.hasOwnProperty('gong')) {
        await store.dispatch('Integration/statuses')
    }

    const { scope, organization: { flag_gong_integration } } = store.state.User.self
    if (scope === 'internal') {
        return (flag_gong_integration === 1 && store.state.Integration.list.gong === true)
            ? next()
            : next('/page-not-found')
    } else {
        next('/page-not-found')
    }
}

export default guardGong

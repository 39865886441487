<template>
    <div>
        <h2 class="text-h5 mb-12">{{ currentView.label }}</h2>
        <draggable
            v-model="grid_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item"
            style="
                display: grid;
                grid-template-rows: masonry;
                grid-template-columns: repeat(3, 1fr);
                transition: 0.4s all;
                gap: 10px;
            "
        >
            <div
                :style="[calculateGcGr(grid)]"
                v-for="grid in grid_list"
                :key="grid.index"
                class="js-draggable-file-list-item js-drag-handle u-cursor-pointer"
            >{{ grid.index }}</div>
        </draggable>
        <!-- <a-btn @click="local_change_pos()" depressed text dark class="mb-4 primary lighten-1">Change 6 Position</a-btn> -->
        <!-- <div style="width: 632px; margin: auto">
            <draggable tag="div" class="u-flex flex-wrap" v-model="flex_list" :options="{handle: '.js-drag-handle'}" @end="local_reorder(flex_list)" draggable=".js-draggable-file-list-item">
                <template v-for="(list_item, index) in flex_list">
                    <div :key="index" :style="calculateWidthHeight(list_item.size)" :class="['js-draggable-file-list-item u-flex align-center justify-center js-drag-handle u-cursor-pointer u-rounded-corners mb-4 u-elevation-custom-1 white']">{{ list_item.label }}</div>
                </template>
            </draggable>
        </div> -->
        <!-- <div class="grey lighten-1" style="height: 300px"> -->
        <!-- <div class="grey lighten-1"> -->
            <!-- <div class="u-flex align-center flex-wrap">

            </div> -->
            <!-- <div style="display: grid; grid-template-columns: repeat(3, minmax(1fr, 100px)); grid-template-rows: 1fr auto; gap: 10px; height: 300px"> -->
            <!-- <div style="display: grid; grid-template-columns: repeat(3, 1fr); grid-template-rows: repeat(auto-fill, 1fr); gap: 10px; height: 300px">
                <div style="color: #fff; background-color: #f00">1</div>
                <div style="color: #fff; background-color: #f00">2</div>
                <div style="color: #fff; background-color: #f00">3</div>
                <div style="color: #fff; background-color: #f00">4</div>
                <div style="color: #fff; background-color: #f00">5</div>
                <div style="color: #fff; background-color: #f00">6</div>
                <div style="color: #fff; background-color: #f00">7</div>
                <div style="color: #fff; background-color: #f00">8</div>
                <div style="color: #fff; background-color: #f00">9</div>
            </div> -->
            <!-- <draggable :style="[local_grid]" v-model="local_list" :options="{handle: '.js-drag-handle'}" @end="local_reorder(local_list)" draggable=".js-draggable-file-list-item">
                <div class="js-draggable-file-list-item js-drag-handle" v-for="list_item in local_list" :style="[list_item.size === 2 ? { width: '100%' } : '', local_grid_item]" @click="local_change_pos(list_item)">{{ list_item.label }}</div>
            </draggable> -->
            <!-- <draggable :style="[local_grid]" v-model="local_list" :options="{handle: '.js-drag-handle'}" @end="local_reorder(local_list)" draggable=".js-draggable-file-list-item">
                <div class="js-draggable-file-list-item js-drag-handle" v-for="list_item in local_list" :style="[...list_item.styles]" @click="local_change_pos(list_item)">{{ list_item.label }}</div>
            </draggable> -->
            <!-- <draggable :style="[local_grid]" v-model="local_list" :options="{handle: '.js-drag-handle'}" @end="local_reorder(local_list)" draggable=".js-draggable-file-list-item">
                <div class="js-draggable-file-list-item js-drag-handle" v-for="i in 9" :style="[local_grid_item, local_grid_item + '_' + (i + 1)]" :class="[local_grid_item + '_' + (i + 1)]" @click="local_change_pos(i)">{{ 'local_grid_item' + '_' + (i + 1) }}</div>
            </draggable> -->
            <!-- <div :style="[local_grid_item, local_grid_item_1]" class="grid-item grid-item-1">1</div>
            <div :style="[local_grid_item, local_grid_item_2]" class="grid-item grid-item-2">2</div>
            <div :style="[local_grid_item, local_grid_item_3]" class="grid-item grid-item-3">3</div>
            <div :style="[local_grid_item, local_grid_item_4]" class="grid-item grid-item-4">4</div>
            <div :style="[local_grid_item, local_grid_item_5]" class="grid-item grid-item-5">5</div>
            <div :style="[local_grid_item, local_grid_item_6]" class="grid-item grid-item-6" @click="local_change_pos">6</div>
            <div :style="[local_grid_item, local_grid_item_7]" class="grid-item grid-item-7">7</div>
            <div :style="[local_grid_item, local_grid_item_8]" class="grid-item grid-item-8">8</div>
            <div :style="[local_grid_item, local_grid_item_9]" class="grid-item grid-item-9">9</div> -->
        <!-- </div> -->
    </div>
</template>

<script>
export default {
    name: 'DashboardInsightsType',

    props: {
        currentView: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            list: Array(9).fill(null),
            flex_list: [
                { label: 1, size: 1 },
                { label: 2, size: 1 },
                { label: 3, size: 1 },
                { label: 4, size: 1 },
                { label: 5, size: 2 },
                { label: 6, size: 1 },
            ],
            grid_list: []
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        local_list: {
            get () {
                // const itemStyle = ['local_grid_item', 'local_grid_item']
                let resultArr = []
                Array(9).fill(null).forEach((i, index) => {
                    const style2 = this['local_grid_item_' + (index + 1)]
                    resultArr.push({
                        label: index + 1,
                        size: (index + 1) === 5 ? 2 : 1
                        // styles: [this.local_grid_item, style2]
                    })
                })
                // const aa = 'local_grid_item_' + 6
                // console.log({ resultArr }, this[aa], aa)
                this.list = resultArr
                return this.list
            },
            set (arr) {
                this.list = arr
            }
        },

        local_grid () {
            return {
                display: 'grid',
                // gridTemplateColumns: 'repeat(auto-fill, 184px)',
                gridTemplateColumns: 'minmax(1fr, auto)',
                // gridTemplateRows: 'repeat(3, 150px)',
                gridTemplateRows: '1fr auto',
                columnCount: '3',
                columnGap: '1rem',
                // gridGap: '1rem',
                justifyContent: 'center',
                // gap: '1rem',
                width: '1400px',
                transition: '0.4s all'
            }
        },

        local_grid_item () {
            return {
                backgroundColor: 'red',
                display: 'grid',
                placeItems: 'center',
                color: '#fff',
                fontSize: '32px',
                transition: '0.4s all'
            }
        },

        local_grid_item_1 () {},
        local_grid_item_2 () {},
        local_grid_item_3 () {},
        local_grid_item_4 () {},
        local_grid_item_5 () {},

        local_grid_item_6 () {
            return {
                gridRow: '2/3',
                gridColumn: '2/4'
                // gridRow: '2 / 3',
                // gridColumn: '2 / 3',
                // gridRowStart: 2,
                // gridRowEnd: 3,
                // gridColumnStart: 2,
                // gridColumnEnd: 3,
            }
        },

        local_grid_item_7 () {},
        local_grid_item_8 () {},
        local_grid_item_9 () {},
    },

    methods: {
        local_index () {
            this.grid_list = []
            let index = 1
            for (let row = 0; row < 4; row++) {
                const rowIndex = row + 1
                for (let col = 0; col < 3; col++) {
                    const colIndex = col + 1
                    if (index === 5) {
                        // this.grid_list.push({ index, gc: `${colIndex}/${colIndex + 2}`, gr: `${rowIndex}/${rowIndex + 1}` })
                        this.grid_list.push({ index, rowSize: 1, colSize: 2 })
                    } else if (index === 4) {
                        // this.grid_list.push({ index, gc: `${colIndex}/${colIndex + 2}`, gr: `${rowIndex}/${rowIndex + 1}` })
                        this.grid_list.push({ index, rowSize: 2, colSize: 1 })
                    } else {
                        // this.grid_list.push({ index, gc: `${colIndex}/${colIndex + 1}`, gr: `${rowIndex}/${rowIndex + 1}` })
                        this.grid_list.push({ index, rowSize: 1, colSize: 1 })
                    }
                    index++
                }
            }
        },

        calculateWidthHeight (size = 1) {
            const constWH = 632 / 3
            if (size !== 2) {
                return {
                    width: constWH + 'px',
                    height: constWH + 'px',
                }
            }
            return { width: constWH * 2 + 'px', height: constWH * 2 + 'px' }
        },

        local_change_pos (evt) {
            const [_, second] = this.list[evt.label - 1].styles
            // const { target: { style } } = evt
            if (second) {
                second.gridRow = '1/2'
                second.gridColumn = '1/3'
            } else {
                this.$set(this.list[evt.label - 1].styles, 1, {
                    gridRow: '1/2',
                    gridColumn: '1/2'
                })
            }
        },

        calculateGcGr (grid) {
            const obj = {
                display: 'grid',
                placeItems: 'center',
                backgroundColor: grid.index === 4 || grid.index === 5 ? '#a0a0a0' : '#ccc',
                // gridColumn: grid.gc,
                // gridRow: grid.gr,
                minHeight: '100px'
            }
            if (grid.colSize === 2) obj['gridColumnEnd'] = 'span 2'
            if (grid.rowSize === 2) obj['gridRowEnd'] = 'span 2'
            return obj
        },
    }
}
</script>

<style lang="scss" scoped>

</style>

<template>
    <a-sheet class="transparent" max-width="656">
        <div v-if="isLoading">
            <loader-template width="100" height="16" class="mb-3 u-rounded-corners-sm"></loader-template>
            <loader-template width="350" height="40" class="u-rounded-corners-xl"></loader-template>
        </div>
        <div v-else>
            <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">Workspace</span>
            <a-sheet class="transparent" max-width="500">
                <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('workspace_id')">
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <v-autocomplete
                                v-model="moduleItem.original.workspace_id"
                                :items="workspace_search_list"
                                item-text="title"
                                item-value="id"
                                placeholder="Ex: Sales Engineering"
                                class="pa-0 u-shadow u-border-transparent u-rounded-corners-lg"
                                :class="[{ 'c-wiggle-short u-border-error': local_get_error('workspace_id') }]"
                                item-color="indigo darken-2"
                                background-color="white"
                                :disabled="isLoading || !canUpdate"
                                :menu-props="{ rounded: 'lg', nudgeBottom: '4px' }"
                                no-data-text="No data found"
                                @click:clear="local_clear_workspace_field('workspace_id')"
                                @input="local_update()"
                                solo flat hide-details clearable
                            >
                                <template v-slot:selection="{ item }">
                                    <span class="md-subtitle-1 ml-1 grey--text text--darken-3">{{ item.title | truncateText(50) }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <span class="md-subtitle-1">{{ item.title | truncateText(50) }}</span>
                                </template>
                                <template #append>
                                    <a-progress-circular v-if="isLoading" width="2" size="20" color="orange darken-1" class="mr-1" indeterminate></a-progress-circular>
                                    <a-icon v-else size="24" color="grey darken-1">arrow_drop_down</a-icon>
                                </template>
                            </v-autocomplete>
                        </div>
                    </template>
                    <span>{{ local_get_error('workspace_id', 'message') }}</span>
                </a-tooltip>
            </a-sheet>
        </div>
    </a-sheet>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        moduleItem: {
            type: Object,
            required: true
        },
        configErrors: {
            type: Array,
        },
        canUpdate: {
            type: Boolean | Number,
            default: true
        },
        isLoading: {
            type: Boolean | Number,
            default: false
        },
    },

    computed: {
        ...mapState('Workspace', {
            workspace_search_list: 'search_list',
        }),
    },

    methods: {
        local_update () {
            this.$emit('update', 'Workspace', 'workspace_id', this.moduleItem.original.workspace_id)
        },

        local_clear_workspace_field (field) {
            this.moduleItem.original[field] = ''
            this.local_update()
            this.$emit('remove-config', field)
        },

        local_get_error (slug, field = 'message') {
            if (!slug) return null
            const item = this.configErrors.find(item => item.slug === slug)
            if (!slug.includes('filters')) return (item && item[field]) ?? null

            return (item && item[field] && item[field][field]) ?? null
        },
    }
}
</script>

<style>

</style>

<template>
    <a-card class="u-elevation-custom-1 u-rounded-corners my-2" :class="{ 'js-draggable-file-list-item': canUpdate }">
        <a-card-text class="pa-0">
            <a-container container--fluid grid-list-xl class="py-7 px-6">
                <a-layout align-start>
                    <a-flex shrink class="pa-0 u-cursor-pointer" :class="{'u-opacity-30': !canUpdate}">
                        <a-icon size="20" class="grey--text text--lighten-1 js-drag-handle u-icon-nudge">drag_indicator</a-icon>
                    </a-flex>
                    <a-flex xs12 py-0 class="text-truncate">
                        <router-link tag="div" exact class="u-cursor-pointer" :to="noteViewPath">
                            <h2 class="md-subtitle-1 grey--text text--darken-3 text-truncate" :title="note.title">{{ note.title }}</h2>
                        </router-link>
                        <div class="u-flex align-center mt-1 u-cursor-pointer" @click="$router.push(noteViewPath)">
                            <div class="mr-4" style="max-width: 250px;">
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on">
                                            <a-icon size="14" color="grey" class="mr-1">person</a-icon>
                                            <span class="md-caption grey--text text--darken-2" v-if="note && note.updated_by">{{ note.updated_by.name | truncateText(30) }}</span>
                                            <template v-else>
                                                <span class="md-body-2 grey--text text--darken-2" v-if="local_bridge_value(note, 'salesforce', 'opportunity.salesforce_id')">Salesforce</span>
                                            </template>
                                        </div>
                                    </template>
                                    <span>Updated User</span>
                                </a-tooltip>
                            </div>
                            <div class="mr-4" style="max-width: 200px;">
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on">
                                            <a-icon size="14" color="grey" class="mr-1">date_range</a-icon>
                                            <g-moment class="md-caption grey--text text--darken-2" :value="note.updated_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD [at] hh:mma" convert-local></g-moment>
                                        </div>
                                    </template>
                                    <span>Updated Date and Time</span>
                                </a-tooltip>
                            </div>
                            <div class="mr-4" style="max-width: 200px;">
                                <g-attachment-count-info
                                    :count="note.attachments_count"
                                    fontSize="12"
                                    iconSize="14"
                                />
                            </div>
                        </div>
                    </a-flex>
                    <a-flex shrink pa-0>
                        <a-btn text icon small color="grey" class="ma-0" v-if="expanded" @click="$emit('expand')">
                            <a-icon size="16">unfold_less</a-icon>
                        </a-btn>
                        <a-btn text icon small color="grey" class="ma-0" v-else @click="$emit('expand')">
                            <a-icon size="16">unfold_more</a-icon>
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-container>
            <a-divider v-if="expanded"></a-divider>
            <a-container container--fluid grid-list-xl v-if="expanded">
                <a-layout align-center>
                    <a-flex shrink class="pb-0 py-4 px-6">
                        <p class="md-caption text-uppercase font-weight-bold mb-1">Description</p>
                        <div v-if="note.content != null && note.content != '' && note.content != ' '" class="u-richtext" v-html="local_get_html(note.content)"></div>
                        <p v-else class="md-body font-italic grey--text">No description</p>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-card-text>
    </a-card>
</template>

<script>
import marked from 'marked'

export default {
    props: {
        note: {
            type: Object
        },
        noteViewPath: {
            type: Object
        },
        canUpdate: {
            type: Boolean
        },
        expanded: {
            type: Boolean
        },
    },

    methods: {
        local_bridge_value(note, service, key) {
            if (note.bridges) {
                const result = note.bridges.find(item => item.service === service && item.key === key)
                return (result && result.value) ?? ''
            }
        },

        local_get_html(description) {
            return marked(description, { sanitize: true })
        },
    }
}
</script>

<style>

</style>

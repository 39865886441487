<template>
    <a-card class="u-border u-rounded-corner pt-3" flat>
        <a-layout wrap class="px-2">
            <a-flex xs4 class="py-2 px-0">
                <div class="u-flex align-center pl-6">
                    <div class="mr-3">
                        <g-avatar :item="user" :size="48" :right-space="false"></g-avatar>
                    </div>
                    <div>
                        <h2 class="md-heading-6 font-weight-medium grey--text text--darken-3" :title="user.name && user.name.length > ($vuetify.breakpoint.lgAndDown ? 26 : 40) ? user.name : ''" v-if="user">{{ user.name | truncateText($vuetify.breakpoint.lgAndDown ? 26 : 40) }}</h2>
                        <p class="md-subtitle-2 font-weight-medium grey--text text--darken-2 mb-0" :title="user.email && user.email.length > ($vuetify.breakpoint.lgAndDown ? 26 : 40) ? user.email : ''" v-if="user">{{ user.email | truncateText($vuetify.breakpoint.lgAndDown ? 26 : 40) }}</p>
                    </div>
                </div>
            </a-flex>
            <a-spacer></a-spacer>
            <a-flex xs8 class="px-0 pr-5 py-2 text-right u-flex align-center justify-end" style="flex-wrap: wrap">
                <a-chip color="grey darken-2" class="mr-3" dark>
                    {{ user.resource_type === 'Customer' ? 'Client' : user.resource_type }}
                </a-chip>
                <a-sheet width="148" class="mr-3 text-right">
                    <p class="md-caption font-weight-bold grey--text text--darken-2 mb-0 text-uppercase" style="letter-spacing: 0.4px">Last login </p>
                    <p class="md-caption mt-n1 grey--text text--darken-1 mb-0" v-if="user && user.last_login_at">{{ localFormatDate(user.last_login_at) }}</p>
                    <p class="md-caption mt-n1 grey--text text--darken-1 mb-0" v-else>(Never Logged In)</p>
                </a-sheet>
                <a-sheet width="86" class="mr-4 text-right" v-if="user.created_at">
                    <p class="md-caption font-weight-bold grey--text text--darken-2 mb-0 text-uppercase" style="letter-spacing: 0.4px">Joined </p>
                    <p class="md-caption mt-n1 grey--text text--darken-1 mb-0">{{ localFormatDate(user.created_at, 'D MMM YYYY') }}</p>
                </a-sheet>
                <a-sheet class="text-right u-flex align-center justify-center">
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <span v-on="on">
                                <a-btn text icon class="pa-1" @click="user.id === user_self.id ? '' : $emit('toggle-block-user', user, user.is_active ? 0 : 1)">
                                    <a-icon v-if="user.is_active" size="16" color="green darken-1">fiber_manual_record</a-icon>
                                    <a-icon v-else size="18" color="red darken-3">block</a-icon>
                                </a-btn>
                            </span>
                        </template>
                        <div>
                            <div class="u-flex align-center justify-center">
                                <a-icon v-if="user.is_active" size="16" color="green darken-1">fiber_manual_record</a-icon>
                                <a-icon v-else size="18" color="red darken-1">block</a-icon>
                                <span class="white--text d-inline-block ml-2">Currently {{ user.is_active ? 'Active' : 'Blocked' }}</span>
                            </div>
                            <div class="white--text d-inline-block ml-2" v-if="user.id === user_self.id">You cannot block your own account</div>
                            <div class="white--text d-inline-block ml-2" v-else>Click to {{ user.is_active ? 'block the user' : 'unblock the user' }}</div>
                        </div>
                    </a-tooltip>
                </a-sheet>
                <a-btn text icon @click="$emit('edit-user', user.id)">
                    <a-icon size="20" color="grey darken-1">edit</a-icon>
                </a-btn>
            </a-flex>
        </a-layout>
        <div class="u-wfull" v-if="user && user.linked_projects">
            <div v-for="(project, index) in user.linked_projects" :key="project.id">
                <a-divider></a-divider>
                <v-hover v-slot="{ hover }">
                    <div class="u-flex">
                        <div class="u-flex align-center pl-6 px-2 my-3">
                            <a-chip class="ml-0 mr-2" label small v-if="project && project.customer_name" :title="project.customer_name && project.customer_name.length >= 20 ? project.customer_name : ''">{{ project.customer_name | truncateText(20) }}</a-chip>
                            <h2 class="md-subtitle-1 grey--text text--darken-3" :title="project.title">
                                {{ project.title | truncateText(90) }}
                            </h2>
                        </div>
                        <div class="text-center" style="position: relative; top: 10px;" title="Open in New Window" v-if="hover" >
                            <a-btn icon small color="blue" target="_blank" :to="{ name: 'projects-overview', params: { id: project.id } }" :exact="true">
                                <a-icon size="16">open_in_new</a-icon>
                            </a-btn>
                        </div>
                        <a-spacer></a-spacer>
                        <div class="u-flex-center-y pr-6" style="column-gap: 12px;">
                            <a-sheet :color="localGetStageColor('bgColor', project)" class="u-rounded-corners-xl d-inline-flex items-center" style="padding: 3px 8px 3px 6px; flex: 0 0 auto">
                                <a-icon size="16" color="white">{{ localGetStageColor('icon', project) }}</a-icon>
                                <span class="md-caption white--text font-weight-medium ml-1 d-inline-block text-truncate u-wfull" style="max-width: 120px;">
                                    {{ localGetStageColor('stageName', project) }}
                                </span>
                            </a-sheet>
                        </div>
                    </div>
                </v-hover>
            </div>
        </div>
    </a-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        user: {
            type: Object,
            required: true
        },
    },

    computed: {
        ...mapState('User', {
            user_self: 'self',
            user_list: 'list',
            user_item: 'item',
        }),
    },

    methods: {
        localFormatDate (date, format = 'MMM D, YYYY [at] hh:mm A') {
            return date ? moment(date).format(format) : null
        },

        localGetStageColor (field, project) {
            let value = {}
            if (project.status === 'active') value = { stageName: project.stage?.name, bgColor: 'grey darken-3', icon: 'bolt' }
            if (project.status === 'queue') value = { stageName: project.stage?.name, bgColor: 'grey darken-3', icon: 'web_stories' }
            if (project.status === 'archived') value = { stageName: 'Archived', bgColor: 'grey darken-3', icon: 'archive' }
            if (project.status === 'completed') {
                if (project.result) {
                    if (project.result?.status ===  'Positive') value = { stageName: 'Completed', bgColor: 'green darken-1', icon: 'thumb_up' }
                    if (project.result?.status ===  'Neutral') value = { stageName: 'Completed', bgColor: 'indigo darken-1', icon: 'linear_scale' }
                    if (project.result?.status ===  'Negative') value = { stageName: 'Completed', bgColor: 'red darken-1', icon: 'thumb_down' }
                }
            }
            return !field ? value : value[field]
        },
    }
}
</script>

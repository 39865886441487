<template>
	<a-main>
        <a-container container--fluid grid-list-xl>
            <a-layout align-center class="mt-2">
                <a-flex>
                    <h2 class="md-heading-6 indigo--text text--darken-1 font-weight-semibold"><a-icon size="24" color="indigo" class="mr-1 u-icon-nudge">vpn_key</a-icon>Accounts</h2>
                    <p class="md-body-2 mb-0 grey--text text--darken-2">Jira accounts connected with Success to run workflows.</p>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid grid-list-xl v-if="localPageLoading">
            <a-layout align-center>
                <a-flex>
                    <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden mb-3 px-3">
                        <a-layout align-center class="ma-0 py-0">
                            <a-flex shrink xs2 pl-0>
                                <div class="d-flex align-center">
                                    <loader-template height="20" width="50%" class="u-rounded-corners-lg mr-2"></loader-template>
                                </div>
                            </a-flex>
                            <a-flex shrink xs6>
                                <loader-template height="24" width="70%" class="u-rounded-corners-lg"></loader-template>
                                <loader-template height="16" width="40%" class="u-rounded-corners-lg mt-2"></loader-template>
                            </a-flex>
                            <a-spacer></a-spacer>
                            <a-flex shrink xs2 pr-0>
                                <loader-template height="24" width="20px" class="float-right ml-6"></loader-template>
                                <loader-template height="24" width="100px" class="float-right"></loader-template>
                            </a-flex>
                        </a-layout>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container grid-list-xl container--fluid class="my-4" v-if="!localPageLoading && installation_item && installation_item.applet && installation_account_list && installation_account_list.length === 0">
            <a-layout align-center justify-center>
                <a-flex xs3>
                    <div class="text-center"><a-icon size="160" color="grey lighten-1">cloud_off</a-icon></div>
                    <h2 class="md-heading-5 mb-1 grey--text text--darken-3 text-center">No Acccount Connected</h2>
                    <template v-if="$can('applets.update')">
                        <p class="md-body-2 mb-2 grey--text text--darken-1 text-center">Connect your Jira organization as Admin with Success to create workflows and setup automation.</p>
                        <div class="text-center">
                            <div class="text-center d-inline-block">
                                <a-btn dark color="blue darken-1" :href="localJiraConnectUrl + '&app_env=developer'" class="d-inline-flex align-center justify-space-between">
                                    <span>Connect to Jira Cloud</span>
                                </a-btn>
                            </div>
                        </div>
                    </template>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid grid-list-xl class="my-2 mb-10" v-if="!localPageLoading && installation_account_list && installation_account_list.length > 0">
            <a-layout align-center>
                <a-flex>
                    <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden mb-3" v-for="account in installation_account_list" :key="account.id">
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="pa-5">
                                <a-layout align-center>
                                    <a-flex shrink>
                                        <div class="d-flex">
                                            <a-sheet width="48" height="48" class="u-border-light u-flex-center u-rounded-corners">
                                                <img src="../../assets/images/apps/jira/logo.svg" alt="Jira" style="display: inline-block; max-width: 32px; max-height: 32px;">
                                            </a-sheet>
                                        </div>
                                    </a-flex>
                                    <a-flex pl-0>
                                        <p class="md-caption mb-0 font-weight-bold grey--text text--darken-2" style="font-size: 16px !important;">{{ account.label }}</p>
                                        <p class="md-caption mb-0" style="font-size: 12px !important;">Connected on <g-moment :value="account.created_at" input-format="YYYY-MM-DD" output-format="MMMM D, YYYY"></g-moment></p>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-chip class="indigo lighten-5 md-body-2 font-weight-bold indigo--text text--darken-1">Default Account</a-chip>
                                    </a-flex>
                                    <a-flex shrink v-if="$can('applets.destroy')">
                                        <a-menu bottom left offset-y max-width="300" min-width="300">
                                            <template v-slot:activator="{ on }">
                                                <a-btn icon text small color="grey darken-1" class="mx-0" v-on="on">
                                                    <a-icon size="20">cancel</a-icon>
                                                </a-btn>
                                            </template>
                                            <a-card flat>
                                                <a-card-text class="pa-0">
                                                    <div class="pa-3">
                                                        <p class="md-body-2 mb-0 text-center grey--text text--darken-3">Account will be disconnected and all workflows will be disabled. However, the workflows will not be removed.</p>
                                                    </div>
                                                    <a-btn color="red darken-1" style="border-radius: revert;"  class="elevation-0 ma-0" block dark small @click="localInstallationAccountDestroy(account.id)">Confirm Delete</a-btn>
                                                </a-card-text>
                                            </a-card>
                                        </a-menu>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                        <a-divider></a-divider>
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="pa-3 u-cursor-pointer" @click="localAccountSection = !localAccountSection">
                                <p class="body-2 mb-0 font-weight-medium text-uppercase indigo--text text--darken-1" style="font-size: 14px;">
                                    <a-icon size="20" color="indigo darken-1" class="u-icon-nudge">{{ localAccountSection ? 'expand_more' : 'chevron_right' }}</a-icon>
                                    setup instructions
                                </p>
                            </a-container>
                        </a-card-text>
                        <a-divider></a-divider>
                        <a-card-text class="pa-0" v-if="localAccountSection">
                            <a-container container--fluid grid-list-xl class="pa-5">
                                <p class="md-body-2 mb-0 blue-grey--text text--darken-4">Follow the instructions given in this <a href="https://help.success.app/en/articles/6225823-how-to-configure-jira-integration" target="_blank" rel="noopener noreferrer" class="font-weight-bold blue--text" style="text-decoration: underline;">article</a> to configure Webhook for incoming workflows. Use the following URL during incoming webhook setup in JIRA organization account.</p>
                                <div class="mt-3">
                                    <template v-for="(item, index) in localWebhookUrlList">
                                        <p class="body-2 mb-0 mt-5 font-weight-bold grey--text text--darken-2" :key="index">
                                            <a-icon size="18" color="grey--text text--darken-2" class="mr-1 u-icon-nudge">{{ item.icon }}</a-icon>
                                            {{ item.title }}
                                        </p>
                                        <div v-ripple="{ class: 'blue-grey--text' }" class="u-flex pa-1 px-2 mt-3 u-rounded-corners u-cursor-pointer" v-clipboard:copy="item.url" v-clipboard:success="localLinkCopy" style="width: 95%;border: 1px solid #EEEEEE;background: #FAFAFA;">
                                            <code class="md-caption blue-grey--text text--darken-4 pa-0" style="font-size: 14px;">{{ item.url }}</code>
                                            <a-spacer></a-spacer>
                                            <a-icon size="18" color="grey darken-1" class="ml-3">content_copy</a-icon>
                                        </div>
                                    </template>
                                </div>
                            </a-container>
                        </a-card-text>
                    </a-card>
                    <!-- <div class="blue-grey lighten-4 pa-4 u-rounded-corners-lg u-flex align-start mt-6">
                        <a-icon size="32" color="blue-grey darken-2">info</a-icon>
                        <div class="ml-3">
                            <p class="md-body-2 mb-2 blue-grey--text text--darken-4">Follow the instructions given in this <a href="https://successapp.zendesk.com/hc/en-us/articles/4419465351447" target="_blank" rel="noopener noreferrer" class="font-weight-bold" style="text-decoration: underline;">link</a> to configure Webhook for incoming workflows. Use the following URL during incoming webhook setup in JIRA organization account.</p>
                            <div v-ripple="{ class: 'blue-grey--text' }" class="pa-1 px-2 grey lighten-2 u-rounded-corners u-cursor-pointer d-inline-block" v-clipboard:copy="jiraConnectUrl" v-clipboard:success="localLinkCopy">
                                <code class="md-caption blue-grey--text text--darken-4 pa-0">{{ jiraConnectUrl }}</code>
                                <a-icon size="18" color="grey darken-1" class="ml-3">content_copy</a-icon>
                            </div>
                        </div>
                    </div> -->
                </a-flex>
            </a-layout>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            jiraConnectUrl: process.env.VUE_APP_CORE_API_URL + '/' + window.location.hostname.split('.')[0] + '/internal/installations/'+this.$route.params.id+'/run',
            localPageLoading: false,
            localAccountSection: true,
            localWebhookUrlList: [
                { title: ' Create Webhook', icon: 'add_circle', subtitle: 'Some content explaining this webhook', url: process.env.VUE_APP_CORE_API_URL + '/' + window.location.hostname.split('.')[0] + '/internal/installations/'+this.$route.params.id+'/run?type=issue&eventType=create&issueId=${issue.id}' },
                { title: 'Update Webhook', icon: 'drive_file_rename_outline', subtitle: 'Some content explaining this webhook',  url: process.env.VUE_APP_CORE_API_URL + '/' + window.location.hostname.split('.')[0] + '/internal/installations/'+this.$route.params.id+'/run?type=issue&eventType=update&issueId=${issue.id}'},
                { title: 'Create / Update Webhook', icon: 'sync_alt', subtitle: 'Some content explaining this webhook',  url: process.env.VUE_APP_CORE_API_URL + '/' + window.location.hostname.split('.')[0] + '/internal/installations/'+this.$route.params.id+'/run?type=issue&eventType=saved&issueId=${issue.id}'}
            ]
        }
    },
    mounted() {
        this.localIndex()
    },
    computed: {
        localJiraConnectUrl() {
            return process.env.VUE_APP_CORE_API_URL + '/' + window.location.hostname.split('.')[0] + '/internal/installation-accounts/auth?applet_slug=' + this.localAppletSlug
        },

        localAppletSlug() {
            return (this.installation_item && this.installation_item.applet && this.installation_item.applet.slug) ?? ''
        },

        ...mapState('Installation', {
            installation_item: 'item',
        }),

        ...mapState('InstallationAccount', {
            installation_account_list: 'list',
            installation_account_filters: 'filters',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async localIndex() {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.localPageLoading = true
            await this.installation_account_index({
                'filter[installation_id]': this.$route.params.id,
                applet_slug: 'jira'
            })
            this.localPageLoading = false
        },

        async localInstallationAccountDestroy(id) {
            await this.installation_account_destroy({
                id,
                params: { installation_id: this.$route.params.id }
            });
            await this.installation_show({id: this.$route.params.id})
        },

        localLinkCopy() {
            this.$notify('success', 'Link Copied!')
        },

        ...mapActions('Installation', {
            installation_show: 'show',
            installation_clear_item: 'clear_item',
        }),

        ...mapActions('InstallationAccount', {
            installation_account_index: 'index',
            installation_account_destroy: 'destroy',
            installation_account_clear: 'clear',
        }),
    },
}
</script>

<style scoped>
.c-default-account-section {
    border-radius: 20px !important;
    background: #E8EAF6 !important;
}
</style>

<template>
	<div>
        <a-container container--fluid grid-list-xl>
            <tab-channel></tab-channel>
            <a-layout wrap align-start>
                <a-flex xs12 class="pb-0">
                    <a-btn color="grey darken-2" class="ma-0 pl-0" small flat :to="{name: 'projects-overview'}" :exact="true">
                        <a-icon left class="mr-2" size="20">arrow_back</a-icon>
                        Back to project view
                    </a-btn>
                    <h2 class="md-heading-5 mb-2">Notification Channels</h2>
                </a-flex>
            </a-layout>
            <a-layout wrap align-center>
                <a-flex>
                    <pre>{{ project_item.uuid }}</pre>
                </a-flex>
            </a-layout>
        </a-container>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { TabChannel } from '@/config/config-tab-components'

export default {
    components: {
        TabChannel
    },
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Project', {
            project_item: 'item',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can(this.user_self, 'projects.update-only') == false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
        },
    },
}
</script>

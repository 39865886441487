<template>
    <div class="pt-4">
        <template v-if="$route.name !== 'appstore-salesforce-microservices-workflows-edit'">
            <SalesforceMicroServicesBreadcrumb />
            <div class="mt-5">
                <h2 class="md-heading-5 font-weight-semibold">Salesforce</h2>
                <p class="md-body-2 mb-0 grey--text text--darken-2">Add Salesforce integration and build workflows to exchange project data between Salesforce and Success.</p>
            </div>
            <div class="mt-3">
                <SalesforceMicroServicesTabs/>
            </div>
        </template>
        <div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import SalesforceMicroServicesTabs from './Partials/SalesforceMicroServicesTabs'
import SalesforceMicroServicesBreadcrumb from './Partials/SalesforceMicroServicesBreadcrumb'

export default {
    components: {
        SalesforceMicroServicesTabs,
        SalesforceMicroServicesBreadcrumb,
    },

    mounted() {
        this.localIndex()
    },

    computed: {
        ...mapState('Installation', {
            installation_item: 'item',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async localIndex() {
            if (this.user_self && this.user_self.organization && this.user_self.organization.flag_salesforce_microservice === 0 || this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                return this.$router.replace({name: 'errors-unauthorized'})
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>

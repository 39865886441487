const api_endpoint = '/workspaces-members';

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        filters: {
            // status: 'all',
            // page: 1,
            // count: 10,
            // sort_field: 'company_name',
            // sort_direction: 'asc',
            workspace_id: null
        },
        response: [],
        defaults: {
            list: [],
            item: {},
            meta: {},
            filters: {
                // status: 'all',
                // page: 1,
                // count: 10,
                // sort_field: 'company_name',
                // sort_direction: 'asc',
                workspace_id: null
            },
            response: [],
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload.item;
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        ADD_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            let query_filters = _.pickBy(context.state.filters, function (item) {
                return item != '';
            });
            return axios.get(api_endpoint, { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    // if (query_filters.page == 1) {
                    // }
                    // else {
                    //     context.commit('APPEND_LIST', response.data.data);
                    // }
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, { user_id: payload.users, workspace_id: payload.workspace_id })
                .then((response) => {
                    // context.commit('ADD_ITEM', response.data.data);
                    // context.commit('UPDATE_ITEM', response.data.data);
                    // context.commit('APPEND_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.put(api_endpoint + '/' + context.state.item.id, context.state.item)
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },
}

import { mapActions } from 'vuex'

export default {
    data () {
        return {
            mixinSearchCollabTimeout: null,
            mixinSearchCollabLoading: false,
        }
    },

    computed: {
        mixinIsExternalView () {
            return this.$route.meta.view === 'external' || (this.user_self && this.user_self.scope === 'external')
        },
    },

    methods: {
        mixinSearchCollabs (val, args = null) {
            //this.mixinSearchCollabLoading = true
            //clearTimeout(this.mixinSearchCollabTimeout)

            //this.mixinSearchCollabTimeout = setTimeout(async () => {
            //    await this.mixinFetchCollabs(val, args)
            //    this.mixinSearchCollabLoading = false
            //    clearTimeout(this.mixinSearchCollabTimeout)
            //}, 500)
        },

        async mixinFetchCollabs (searchValue = null, args = null) {
            //const params = args ?? { include: 'user', 'filter[project_id]': this.$route.params.id, count: 8, page: 1 }

            //if (searchValue) params['filter[user]'] = searchValue
            //if (!this.mixinIsExternalView) return await this.collaborator_index({ ...params, include: 'user.role,type,contact' })
            //await this.collaborator_external_index({ ...params, include: 'user.role,type' })
        },

        async mixinGetCollabs (args) {
            const { project_id, count = 1000, page = 1 } = args || {}
            const params = { 'filter[project_id]': this.$route.params.id || project_id, count, page }

            if (!this.mixinIsExternalView) return await this.collaborator_index({ ...params, include: 'user.role,type,contact' })
            await this.collaborator_external_index({ ...params, include: 'user.role,type' })
        },

        ...mapActions('Collaborator', {
            collaborator_index: 'index'
        }),

        ...mapActions('CollaboratorExternal', {
            collaborator_external_index: 'index',
        }),
    }
}

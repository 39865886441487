<template>
	<a-main>
        <a-responsive class="grey darken-4">
            <a-container grid-list-xl container--fluid class="py-2 px-5">
                <a-layout align-start>
                    <a-flex shrink class="px-0">
                        <a-btn color="white" text :to="{name: 'appstore-webhook-workflows'}" exact>
                            <a-icon class="mr-1">arrow_back</a-icon> Back to Workflows
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive>

        <a-responsive class="grey darken-3">
            <a-container grid-list-xl class="pa-6 my-4" v-if="workflow_item">
                <a-layout align-center>
                    <a-flex>
                        <div class="editor-title">
                            <a-text-field
                                v-model="workflow_title"
                                placeholder="Enter Workflow Title"
                                color="white"
                                background-color="transparent"
                                :disabled="!$can('applets.update')"
                                @input="local_workflow_timeout_reset('title', 2000)"
                                @blur="local_workflow_timeout_reset('title', 100)"
                                solo flat hide-details dark
                            >
                                {{ workflow_item.title }}
                            </a-text-field>
                        </div>
                    </a-flex>
                    <a-flex shrink>
                        <a-btn color="green darken-2" dark @click="local_workflow_toggle_state(workflow_item.id, '1')" v-if="workflow_item.is_active === 0" :disabled="workflow_item.is_complete === 0 || !$can('applets.update')">Enable Workflow</a-btn>
                        <a-btn color="grey darken-2" dark @click="local_workflow_toggle_state(workflow_item.id, '0')" v-if="workflow_item.is_active === 1" :disabled="workflow_item.is_complete === 0 || !$can('applets.update')">Disable Workflow</a-btn>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive>

        <a-responsive :class="{'grey lighten-1': workflow_item.is_active === 0, 'light-green darken-1': workflow_item.is_active === 1}">
            <a-container grid-list-xl class="py-4" v-if="workflow_item">
                <a-layout align-center v-if="workflow_item.is_active === 0">
                    <a-flex shrink class="pr-0">
                        <a-icon>info</a-icon>
                    </a-flex>
                    <a-flex>
                        <p class="md-subtitle-1 mb-0" v-if="workflow_item.is_complete === 1">This workflow is currently inactive. You can activate by clicking on "Enable Workflow" button.</p>
                        <p class="md-subtitle-1 mb-0" v-if="workflow_item.is_complete === 0">This workflow is currently inactive. Please make sure all conditions and mappings are valid.</p>
                    </a-flex>
                </a-layout>
                <a-layout align-center v-if="workflow_item.is_active === 1">
                    <a-flex shrink class="pr-0">
                        <a-icon>check_circle</a-icon>
                    </a-flex>
                    <a-flex>
                        <p class="md-subtitle-1 mb-0">This workflow is active and running. Check "Logs" tab for more details.</p>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive>

        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink style="height: 72px;">
                    <a-divider vertical class="ma-0 d-block"></a-divider><a-divider vertical class="ma-0 d-block"></a-divider>
                </a-flex>
            </a-layout>
        </a-container>

        <a-container container--fluid class="pa-0" style="margin-bottom: -2rem; z-index: 2; position: relative;">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink>
                    <a-btn fab color="white" class="mt-0 mb-2 elevation-2" size="64" @click="local_workflow_meta_fetch('source', true)" :loading="loading && loading_block === 'workflow_meta_fetch'">
                        <img src="../../assets/images/logo-outgoing-webhooks.svg" alt="Outgoing Webhooks" v-if="workflow_item.trigger && workflow_item.trigger.service && workflow_item.trigger.service.slug === 'webhook'" style="display: block; width: auto !important; height: auto !important; max-width: 32px; max-height: 32px; border-radius: 0 !important;">
                        <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" v-if="workflow_item.trigger && workflow_item.trigger.service && workflow_item.trigger.service.slug === 'success'" style="display: block; width: auto !important; height: auto !important; max-width: 32px; max-height: 32px; border-radius: 0 !important;">
                    </a-btn>
                </a-flex>
            </a-layout>
        </a-container>

        <a-container grid-list-xl class="py-0">
            <a-layout align-start wrap>
                <a-flex xs12>
                    <a-card flat class="neutral darken-1 u-overflow-hidden u-rounded-corners">
                        <a-card-text class="pa-0">
                            <a-container grid-list-xl class="pa-6">
                                <a-layout align-center justify-center>
                                    <a-flex shrink>
                                        <h2 class="md-subtitle-2 font-weight-bold text-center text-uppercase grey--text text--darken-3 mb-1">Trigger</h2>
                                        <h3 class="md-heading-6 font-weight-bold text-center grey--text text--darken-4">{{ (workflow_item.trigger) ? workflow_item.trigger.label : '' }}<span class="md-heading-6 font-weight-bold" v-if="workflow_condition_list.length > 0"> and matches following conditions</span></h3>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <a-container grid-list-xl pa-6 v-if="workflow_condition_list.length === 0 && $can('applets.update')">
                                <a-layout align-center justify-center class="pb-0">
                                    <a-flex shrink>
                                        <span class="d-block md-subtitle-1 grey--text text--darken-2 text-center u-cursor-pointer" @click="local_workflow_condition_store()">
                                            <a-icon size="18" class="mr-1">add_circle</a-icon>
                                            Create new condition
                                        </span>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-container grid-list-xl v-if="workflow_condition_list.length" pa-6>
                                <a-layout align-center>
                                    <a-flex class="pb-0">
                                        <span class="d-block md-body-2 grey--text text--darken-2 mb-2">Conditions</span>
                                    </a-flex>
                                </a-layout>
                                <a-layout align-center v-for="(condition, index) in workflow_condition_list" :key="index">
                                    <a-flex shrink class="pr-0">
                                        <a-tooltip bottom v-if="condition && condition.is_valid === 0">
                                            <template v-slot:activator="{ on }">
                                                <a-icon color="orange" size="24" v-on="on">error</a-icon>
                                            </template>
                                            <span>Invalid Condition</span>
                                        </a-tooltip>
                                        <a-tooltip bottom v-if="condition && condition.is_valid === 1">
                                            <template v-slot:activator="{ on }">
                                                <a-icon color="light-green darken-2" size="24" v-on="on">check_circle</a-icon>
                                            </template>
                                            <span>Valid Condition</span>
                                        </a-tooltip>
                                        <!-- <a-icon color="grey lighten-1">drag_indicator</a-icon> -->
                                    </a-flex>
                                    <a-flex xs4>
                                        <a-autocomplete
                                            v-model="condition.field_name"
                                            :items="source_field_fetch('all', [], { workflowFilterable: true })"
                                            item-text="label"
                                            item-value="name"
                                            :disabled="!$can('applets.update')"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details
                                            @input="local_workflow_condition_timeout_reset('field_name', condition.id, 100)"
                                        >
                                            <template slot="prepend-inner" v-if="workflow_item.source_service === 'webhook'">
                                                <img src="../../assets/images/logo-outgoing-webhooks.svg" alt="Outgoing Webhooks" style="display: block; max-width: 24px; max-height: 24px; margin-right: 8px;">
                                            </template>
                                            <template slot="prepend-inner" v-if="workflow_item.source_service === 'success'">
                                                <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" style="display: block; max-width: 24px; max-height: 24px; margin-right: 8px;">
                                            </template>
                                        </a-autocomplete>
                                    </a-flex>
                                    <a-flex xs3>
                                        <a-select
                                            v-model="condition.operator"
                                            :items="source_type_filters(condition.field_type)"
                                            item-text="label"
                                            item-value="name"
                                            :disabled="!$can('applets.update')"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details
                                            @input="local_workflow_condition_timeout_reset('operator', condition.id, 100)"
                                        >
                                        </a-select>
                                    </a-flex>
                                    <a-flex>
                                        <a-autocomplete
                                            v-if="condition.field_type === 'picklist'"
                                            v-model="condition.value"
                                            :items="source_field_property(condition.field_name, 'picklistValues')"
                                            item-text="label"
                                            item-value="name"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details
                                            @input="local_workflow_condition_timeout_reset('value', condition.id, 2000)"
                                            @blur="local_workflow_condition_timeout_reset('value', condition.id, 100)"
                                            :disabled="!source_filter_item_property(condition.field_type, condition.operator, 'allow_input') || !$can('applets.update')"
                                        >
                                        </a-autocomplete>
                                        <a-text-field
                                            v-else
                                            v-model="condition.value"
                                            :placeholder="(source_filter_item_property(condition.field_type, condition.operator, 'allow_alt_input')) ? 'Start' : 'Value'"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details
                                            @input="local_workflow_condition_timeout_reset('value', condition.id, 2000)"
                                            @blur="local_workflow_condition_timeout_reset('value', condition.id, 100)"
                                            :disabled="!source_filter_item_property(condition.field_type, condition.operator, 'allow_input') || !$can('applets.update')"
                                        >
                                        </a-text-field>
                                    </a-flex>
                                    <a-flex v-if="source_filter_item_property(condition.field_type, condition.operator, 'allow_alt_input')">
                                        <a-text-field
                                            v-model="condition.value_alt"
                                            placeholder="End"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details
                                            :disabled="!$can('applets.update')"
                                            @blur="local_workflow_condition_timeout_reset('value_alt', condition.id, 100)"
                                            @input="local_workflow_condition_timeout_reset('value_alt', condition.id, 2000)"
                                        >
                                        </a-text-field>
                                    </a-flex>
                                    <a-flex shrink class="px-0" v-if="$can('applets.update')">
                                        <a-tooltip bottom left v-if="loading_item_id !== condition.id">
                                            <template v-slot:activator="{ on }">
                                                <a-btn icon text color="grey" @click="loading_item_id = condition.id" v-on="on">
                                                    <a-icon color="grey darken-3" size="22">remove_circle_outline</a-icon>
                                                </a-btn>
                                            </template>
                                            <span>Remove Condition</span>
                                        </a-tooltip>
                                        <a-tooltip bottom left v-if="loading_item_id === condition.id">
                                            <template v-slot:activator="{ on }">
                                                <a-btn icon text color="red" @click="local_workflow_condition_destroy(condition.id)" v-on="on">
                                                    <a-icon color="red" size="22">remove_circle</a-icon>
                                                </a-btn>
                                            </template>
                                            <span>Confirm?</span>
                                        </a-tooltip>
                                    </a-flex>
                                </a-layout>
                                <a-layout wrap align-center v-if="$can('applets.update')">
                                    <a-flex shrink class="pr-0">
                                        <a-icon color="neutral darken-1">drag_indicator</a-icon>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-btn tag="a" small color="white" class="u-elevation-custom-1 u-rounded-corners u-overlow-hidden ma-0 px-3 py-4" @click="local_workflow_condition_store()" :loading="loading && loading_block === 'workflow_condition_store'">
                                            <a-icon left class="ml-0 mr-1">add</a-icon>
                                            New Condition
                                        </a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>

        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink style="height: 72px;">
                    <a-divider vertical class="ma-0 d-block"></a-divider><a-divider vertical class="ma-0 d-block"></a-divider>
                </a-flex>
            </a-layout>
        </a-container>

        <a-container container--fluid class="pa-0" style="margin-bottom: -2rem; z-index: 2; position: relative;">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink>
                    <a-btn fab color="white" class="mt-0 mb-2 elevation-2" size="64" :loading="loading || loading_block === 'workflow_settings_fetch'">
                        <i aria-hidden="true" class="a-icon material-icons theme--light grey--text text--darken-2" style="font-size: 32px;">settings</i>
                    </a-btn>
                </a-flex>
            </a-layout>
        </a-container>

        <a-container grid-list-xl class="py-0">
            <a-layout align-start wrap>
                <a-flex xs12>
                    <a-card flat class="neutral darken-1 u-overflow-hidden u-rounded-corners">
                        <a-card-text class="pa-0">
                            <a-container grid-list-xl class="pa-6">
                                <a-layout align-center justify-center>
                                    <a-flex>
                                        <h2 class="md-subtitle-2 font-weight-bold text-center text-uppercase grey--text text--darken-3 mb-1">Custom Settings</h2>
                                        <h3 class="md-heading-6 font-weight-bold text-center grey--text text--darken-4">Custom header to be sent with the data</h3>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <a-container grid-list-xl pa-6 v-if="headers && headers.length === 0 && $can('applets.update')">
                                <a-layout align-center justify-center>
                                    <a-flex shrink>
                                        <span class="d-block md-subtitle-1 grey--text text--darken-2 text-center u-cursor-pointer" @click="local_add_headers()">
                                            <a-icon size="18" class="mr-1">add_circle</a-icon>
                                            Create new header
                                        </span>
                                    </a-flex>
                                </a-layout>
                                <!-- <a-layout align-center justify-center class="pb-0">
                                    <a-flex shrink>
                                        <span class="d-block md-subtitle-1 grey--text text--darken-2 text-center u-cursor-pointer" @click="local_store_headers()">
                                            <a-icon size="18" class="mr-1">add_circle</a-icon>
                                            Save headers
                                        </span>
                                    </a-flex>
                                </a-layout> -->
                            </a-container>
                            <!-- <a-container grid-list-xl v-if="workflow_condition_list.length === 0">
                                <a-layout align-center justify-center class="pb-0">
                                    <a-flex shrink>
                                        <span class="d-block md-subtitle-1 grey--text text--darken-2 text-center u-cursor-pointer" @click="local_workflow_condition_store()">
                                            <a-icon size="18" class="mr-1">add_circle</a-icon>
                                            Create new condition
                                        </span>
                                    </a-flex>
                                </a-layout>
                            </a-container> -->
                            <a-container grid-list-xl pa-6 v-if="headers.length">
                                <a-layout align-center>
                                    <a-flex class="pb-0">
                                        <span class="d-block md-body-2 grey--text text--darken-2 mb-2">Headers</span>
                                    </a-flex>
                                </a-layout>
                                <a-layout align-center v-for="(header, index) in headers" :key="index">
                                    <a-flex shrink class="pr-0">
                                        <a-tooltip bottom v-if="!local_header_has_value(header.value, header.type)">
                                            <template v-slot:activator="{ on }">
                                                <a-icon color="orange" size="24" v-on="on">error</a-icon>
                                            </template>
                                            <span>Invalid Header</span>
                                        </a-tooltip>
                                        <a-tooltip bottom v-else>
                                            <template v-slot:activator="{ on }">
                                                <a-icon color="light-green darken-2" size="24" v-on="on">check_circle</a-icon>
                                            </template>
                                            <span>Valid Header</span>
                                        </a-tooltip>
                                    </a-flex>
                                    <a-flex xs6>
                                        <input
                                            v-model="header.key"
                                            v-alphanumeric
                                            placeholder="Key"
                                            :disabled="!$can('applets.update')"
                                            class="u-elevation-custom-1 u-wfull px-3 md-subtitle-1 py-2 u-rounded-corners white u-overflow-hidden"
                                            style="min-height: 48px; outline: none"
                                            @input="local_alpha_numeric(header.key, header, 'key')"
                                            @blur="local_store_headers('key', header)"
                                            :autofocus="(!header.key && !header.value)"
                                        >
                                    </a-flex>
                                    <a-flex xs6 class="u-flex-center-y">
                                        <a-sheet class="trasnsparent mr-4 u-rounded-corners" width="200">
                                            <v-autocomplete
                                                v-model="header.type"
                                                :items="[{ text: 'Static', value: 'static' }, { text: 'Dynamic', value: 'dynamic' }]"
                                                placeholder="Type"
                                                background-color="white"
                                                class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                                @input="local_store_headers('type', header)"
                                                :disabled="!(header.key) || !$can('applets.update')"
                                                flat solo hide-details
                                            >
                                            </v-autocomplete>
                                        </a-sheet>
                                        <a-text-field
                                            v-if="(!header.type || header.type === 'static')"
                                            v-model="header.value"
                                            placeholder="Value"
                                            background-color="white"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            @input="local_store_header_value('value', header)"
                                            :disabled="!(header.key) || !$can('applets.update')"
                                            flat solo hide-details
                                        >
                                        </a-text-field>
                                        <v-autocomplete
                                            v-else
                                            v-model="header.value"
                                            :items="headerValueList"
                                            placeholder="Value"
                                            background-color="white"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            @input="local_store_header_value('value', header)"
                                            :disabled="!(header.key) || !$can('applets.update')"
                                            flat solo hide-details
                                        >
                                        </v-autocomplete>
                                    </a-flex>
                                    <a-flex shrink class="px-0" v-if="$can('applets.update')">
                                        <a-tooltip bottom left v-if="loading_item_id !== index">
                                            <template v-slot:activator="{ on }">
                                                <a-btn icon text color="grey" @click="loading_item_id = index" v-on="on">
                                                    <a-icon color="grey darken-3" size="22">remove_circle_outline</a-icon>
                                                </a-btn>
                                            </template>
                                            <span>Remove Header</span>
                                        </a-tooltip>
                                        <a-tooltip bottom left v-if="loading_item_id === index">
                                            <template v-slot:activator="{ on }">
                                                <a-btn icon text color="red" @click="local_update_headers('remove', index)" v-on="on">
                                                    <a-icon color="red" size="22">remove_circle</a-icon>
                                                </a-btn>
                                            </template>
                                            <span>Confirm?</span>
                                        </a-tooltip>
                                    </a-flex>
                                </a-layout>
                                <a-layout wrap align-center v-if="$can('applets.update')">
                                    <a-flex shrink class="pr-0">
                                        <a-icon color="neutral darken-1">drag_indicator</a-icon>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-btn tag="a" small color="white" class="u-elevation-custom-1 u-rounded-corners u-overlow-hidden ma-0 px-3 py-4" @click="local_add_headers()" :loading="loading && loading_block === 'workflow_header_store'">
                                            <a-icon left class="ml-0 mr-1">add</a-icon>
                                            New Condition
                                        </a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>

        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink style="height: 72px;">
                    <a-divider vertical class="ma-0 d-block"></a-divider><a-divider vertical class="ma-0 d-block"></a-divider>
                </a-flex>
            </a-layout>
        </a-container>

        <a-container container--fluid class="pa-0" style="margin-bottom: -2rem; z-index: 2; position: relative;">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink>
                    <a-btn fab color="white" class="mt-0 mb-2 elevation-2" size="64" @click="local_workflow_meta_fetch('destination', true)" :loading="loading && loading_block === 'workflow_meta_fetch'">
                        <img src="../../assets/images/logo-outgoing-webhooks.svg" alt="Outgoing Webhooks" v-if="workflow_item.action && workflow_item.action.service && workflow_item.action.service.slug === 'webhook'" style="display: block; width: auto !important; height: auto !important; max-width: 32px; max-height: 32px; border-radius: 0 !important;">
                        <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" v-if="workflow_item.action && workflow_item.action.service && workflow_item.action.service.slug === 'success'" style="display: block; width: auto !important; height: auto !important; max-width: 32px; max-height: 32px; border-radius: 0 !important;">
                    </a-btn>
                </a-flex>
            </a-layout>
        </a-container>

        <a-container grid-list-xl class="py-0">
            <a-layout align-start wrap>
                <a-flex xs12>
                    <a-card flat class="neutral darken-1 u-overflow-hidden u-rounded-corners">
                        <a-card-text class="pa-0">
                            <a-container grid-list-xl class="pa-6">
                                <a-layout align-center justify-center>
                                    <a-flex>
                                        <h2 class="md-subtitle-2 font-weight-bold text-center text-uppercase grey--text text--darken-3 mb-1">Action</h2>
                                        <h3 class="md-heading-6 font-weight-bold text-center grey--text text--darken-4" v-if="workflow_item.action">{{ workflow_item.action.label }}</h3>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <a-container grid-list-xl pa-6>
                                <a-layout align-center>
                                    <a-flex class="pb-0">
                                        <span class="d-block md-body-2 grey--text text--darken-2 mb-2">Destination URL</span>
                                    </a-flex>
                                </a-layout>
                                <a-layout>
                                    <a-flex shrink class="pr-0">
                                        <a-tooltip bottom v-if="local_check_destination_url && $response(workflow_response, 'action_resource_url')">
                                            <template v-slot:activator="{ on }">
                                                <a-icon color="red" class="mt-3" size="24" v-on="on">error</a-icon>
                                            </template>
                                            <span>{{ $response(workflow_response, 'action_resource_url') }}</span>
                                        </a-tooltip>
                                        <a-tooltip bottom v-else>
                                            <template v-slot:activator="{ on }">
                                                <a-icon color="light-green darken-2" class="mt-3" size="24" v-on="on">check_circle</a-icon>
                                            </template>
                                            <span>Valid Destination URL</span>
                                        </a-tooltip>
                                    </a-flex>
                                    <a-flex xs12>
                                        <input
                                            v-model="workflow_item.action_resource_url"
                                            placeholder="Destination URL"
                                            :disabled="!$can('applets.update')"
                                            class="u-elevation-custom-1 u-wfull px-3 md-subtitle-1 py-2 u-rounded-corners white u-overflow-hidden"
                                            style="min-height: 48px; outline: none"
                                        >
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <a-icon color="orange" class="mt-3" size="24" v-on="on">star</a-icon>
                                            </template>
                                            <span>Required</span>
                                        </a-tooltip>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider v-if="$can('applets.update')"></a-divider>
                            <a-container grid-list-xl pa-0 v-if="$can('applets.update')">
                                <div class="pa-6 u-flex align-stretch">
                                    <a-btn height="32" depressed small :loading="local_destination_loading" :disabled="!local_check_destination_url" @click="local_update_destination()" class="ma-0 ml-7 px-2 py-3 indigo darken-1 white--text u-rounded-corners">
                                        Save Destination URL
                                    </a-btn>
                                    <a-btn height="32" small color="white" :disabled="!(local_check_destination_url || local_destination_loading)" @click="local_clear_destination()" class="u-rounded-corners u-overlow-hidden ml-4 px-2 py-3">
                                        Cancel Changes
                                    </a-btn>
                                </div>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>

        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink style="height: 72px;">
                </a-flex>
            </a-layout>
        </a-container>

	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AppSalesforce from '@/plugins/plugin-app-salesforce'
import AppSuccess from '@/plugins/plugin-app-success'

export default {
    directives: {
        alphanumeric: {
            bind: (el) => {
                el.value = el.value.replace(/[^aA-zZ0-9-]+/ig, '').replace(/\^/g, '').replace(/\_/g, '').replace(/[\[\]']+/g, '')
            },
            update: (el) => {
                el.value = el.value.replace(/[^aA-zZ0-9-]+/ig, '').replace(/\^/g, '').replace(/\_/g, '').replace(/[\[\]']+/g, '')
            },
            componentUpdated: (el) => {
                el.value = el.value.replace(/[^aA-zZ0-9-]+/ig, '').replace(/\^/g, '').replace(/\_/g, '').replace(/[\[\]']+/g, '')
            }
        }
    },

    data() {
        return {
            loading_block: null,
            loading_item_id: null,
            workflow_title: null,
            workflow_debounce: null,
            workflow_condition_debounce: null,
            local_action_resource_url: null,
            local_destination_loading: false,
            workflow_mapping_debounce: null,
            header_value_timeout: null,
            source_fields: {},
            destination_fields: {},
            headers: [],
            headerValueList: []
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        local_check_destination_url() {
            return (this.workflow_item && this.local_action_resource_url !== this.workflow_item.action_resource_url)
        },
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Apps', to: '/apps', exact: true})
            breadcrumbs.push({text: 'Configure Workflow', to: '/apps/installed', exact: true})
            return breadcrumbs
        },
        app_salesforce() {
            return AppSalesforce;
        },
        app_success() {
            return AppSuccess;
        },
        ...mapState('CustomField', {
            custom_field_list: 'list',
        }),
        ...mapState('Workflow', {
            workflow_item: 'item',
            workflow_response: 'response',
        }),
        ...mapState('WorkflowMeta', {
            workflow_meta_item: 'item',
            workflow_meta_response: 'response',
        }),
        ...mapState('WorkflowCondition', {
            workflow_condition_list: 'list',
            workflow_condition_item: 'item',
            workflow_condition_filters: 'filters',
            workflow_condition_response: 'response',
        }),
        ...mapState('WorkflowMapping', {
            workflow_mapping_list: 'list',
            workflow_mapping_item: 'item',
            workflow_mapping_filters: 'filters',
            workflow_mapping_response: 'response',
        }),
        ...mapState('WorkflowSetting', {
            workflow_setting_list: 'list',
            workflow_mapping_item: 'item',
            workflow_setting_filters: 'filters',
            workflow_setting_response: 'response',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.workflow_clear_item()
            await this.workflow_condition_clear()
            await this.workflow_mapping_clear()

            await this.workflow_show({id: this.$route.params.workflow_id})
            this.local_action_resource_url = this.workflow_item.action_resource_url

            await this.local_workflow_meta_fetch('source')
            await this.local_workflow_meta_fetch('destination')

            // this.workflow_condition_filters.workflow_id = this.workflow_item.id
            await this.workflow_condition_index({ 'filter[workflow_id]': this.workflow_item.id })

            // this.workflow_mapping_filters.workflow_id = this.workflow_item.id
            // await this.workflow_mapping_index({ 'workflow_id': this.workflow_item.id })

            this.workflow_title = _.clone(this.workflow_item.title)

            this.loading_block = 'workflow_settings_fetch'
            // this.workflow_setting_filters.workflow_id = this.workflow_item.id
            await this.workflow_setting_index({id: this.$route.params.workflow_id, 'filter[workflow_id]': this.workflow_item.id })
            this.local_set_headers()
            this.local_set_dynamic_header_values()
            this.loading_block = null
        },
        local_set_headers () {
            if (this.workflow_setting_list && !this.workflow_setting_list[0]) return this.headers = []
            this.headers = this.workflow_setting_list[0].headers.map(i => !i.type ? { ...i, type: 'static' } : i)
        },
        async local_set_dynamic_header_values () {
            await this.local_get_custom_fields()
            this.headerValueList = this.custom_field_list.map(i => ({ value: i.id, text: i.label }))
        },
        async local_get_custom_fields () {
            const isTicketResource = (this.workflow_item && this.workflow_item.trigger_resource_name === 'Ticket')
            const params = { 'fields[custom_fields]': 'id,name,label', 'filter[is_active]': 1, sort: 'order' }
            if (!isTicketResource) params['filter[resource_type]'] = 'Project'

            await this.custom_field_index(params)
        },
        async local_workflow_patch(field) {
            if (field === 'title') {
                await this.workflow_patch({data: {title: this.workflow_title}})
            }
        },
        async local_update_destination() {
            this.local_destination_loading = true
            const destination_item =  {}
            destination_item.title = this.workflow_item.title
            destination_item.id = this.workflow_item.id
            destination_item.action_resource_url = this.workflow_item.action_resource_url
            await this.workflow_patch({data: destination_item })
            this.local_destination_loading = false
            if (!this.$status(this.workflow_response)) return
            this.local_action_resource_url = this.workflow_item.action_resource_url
        },
        local_clear_destination() {
            this.local_destination_loading = false
            this.workflow_item.action_resource_url = this.local_action_resource_url
        },
        async local_workflow_toggle_state(id, state) {
            await this.workflow_toggle_state({id: id, state: state})
        },
        local_workflow_timeout_reset(field, timeout = 2000) {
            window.clearTimeout(this.workflow_debounce)
            this.workflow_debounce = window.setTimeout(async () => {
                await this.local_workflow_patch(field)
                window.clearTimeout(this.workflow_debounce)
            }, timeout);
        },
        async local_workflow_meta_fetch(base, force = false) {
            this.loading_block = 'workflow_meta_fetch'
            await this.workflow_meta_clear_item()

            if (base === 'source') {
                await this.workflow_meta_fetch({params: { service: this.workflow_item.trigger.service.slug, workflow_id: this.workflow_item.id, resource_type: this.workflow_item.trigger_resource_name, force_server_fetch: force}})
                this.source_fields = _.cloneDeep(this.workflow_meta_item)
            }
            // if (base === 'destination') {
            //     await this.workflow_meta_fetch({params: { service: this.workflow_item.action.service.slug, workflow_id: this.workflow_item.id, resource_type: this.workflow_item.action_resource_name, force_server_fetch: force}})
            //     this.destination_fields = _.cloneDeep(this.workflow_meta_item)
            // }

            await this.workflow_meta_clear_item()
            this.loading_block = null
        },
        source_field_excludes(keep = null) {
            let field_list = _.map(this.workflow_condition_list, 'field_name')
            _.remove(field_list, field => field === keep)
            _.remove(field_list, field => field === null)
            return field_list
        },
        source_field_fetch(field_types = null, excludes = [], filters) {
            let local_field_types = _.clone(field_types);
            if (this.workflow_item.trigger && this.workflow_item.trigger.service.slug === 'salesforce') {
                if (field_types === 'all') {
                    local_field_types = _.cloneDeep(this.app_salesforce.get_field_types_all())
                }
            }
            if (this.workflow_item.trigger && this.workflow_item.trigger.service.slug === 'success') {
                if (field_types === 'all') {
                    local_field_types = _.cloneDeep(this.app_success.get_field_types_all())
                }
            }
            return _.filter(
                        _.map(_.get(this.source_fields, 'result.fields'), (item) => {
                            if (local_field_types === null) {
                                return {
                                    name: item.name,
                                    label: item.label,
                                    picklistValues: item.picklistValues,
                                    type: item.type,
                                    workflowFilterable: item.workflowFilterable,
                                    workflowCreateable: item.workflowCreateable,
                                    workflowUpdateable: item.workflowUpdateable,
                                    workflowRequired: item.workflowRequired,
                                    include: _.indexOf(excludes, item.name) < 0
                                }
                            }
                            else if (_.indexOf(local_field_types, item.type) >= 0) {
                                return {
                                    name: item.name,
                                    label: item.label,
                                    picklistValues: item.picklistValues,
                                    required: !item.nillable,
                                    type: item.type,
                                    workflowFilterable: item.workflowFilterable,
                                    workflowCreateable: item.workflowCreateable,
                                    workflowUpdateable: item.workflowUpdateable,
                                    workflowRequired: item.workflowRequired,
                                    include: _.indexOf(excludes, item.name) < 0
                                }
                            }
                            else {
                                return {
                                    include: false
                                }
                            }
                        }),
                        { include: true, ...filters }
                    );
        },
        source_field_property(field_name, property) {
            return _.get(
                        _.find(
                            _.get(this.source_fields, 'result.fields'),
                            { name: field_name }
                        ),
                        property
                    )
        },
        source_type_filters(type) {
            if (this.workflow_item.trigger && this.workflow_item.trigger.service.slug === 'success') {
                return this.app_success.get_filters_by_type(type)
            }
        },
        source_filter_item_property(field_type, operator, property) {
            if (this.workflow_item.trigger && this.workflow_item.trigger.service.slug === 'salesforce') {
                return this.app_salesforce.get_filters_item_by_field(field_type, operator, property)
            }
            if (this.workflow_item.trigger && this.workflow_item.trigger.service.slug === 'success') {
                return this.app_success.get_filters_item_by_field(field_type, operator, property)
            }
        },
        destination_field_excludes(keep = null) {
            let field_list = _.map(this.workflow_mapping_list, 'field_name')
            _.remove(field_list, field => field === keep)
            _.remove(field_list, field => field === null)
            return field_list
        },
        destination_field_fetch(field_types = null, excludes = []) {
            let filters = {};
            if (this.workflow_item.action && this.workflow_item.action.intent === 'create') {
                filters = { workflowCreateable: true }
            }
            if (this.workflow_item.action && this.workflow_item.action.intent === 'update') {
                filters = { workflowUpdateable: true }
            }
            return _.filter(
                        _.map(_.get(this.destination_fields, 'result.fields'), (item) => {
                            if (field_types === null) {
                                return { name: item.name, label: item.label, picklistValues: item.picklistValues, required: item.required, type: item.type, workflowFilterable: item.workflowFilterable, workflowCreateable: item.workflowCreateable, workflowUpdateable: item.workflowUpdateable, workflowRequired: item.workflowRequired, include: _.indexOf(excludes, item.name) < 0 }
                            }
                            else if (_.indexOf(field_types, item.type) >= 0) {
                                return { name: item.name, label: item.label, picklistValues: item.picklistValues, required: item.required, type: item.type, workflowFilterable: item.workflowFilterable, workflowCreateable: item.workflowCreateable, workflowUpdateable: item.workflowUpdateable, workflowRequired: item.workflowRequired, include: _.indexOf(excludes, item.name) < 0 }
                            }
                            else {
                                return { include: false }
                            }
                        }),
                        { include: true, ...filters }
                    );
        },
        destination_field_property(field_name, property) {
            return _.get(
                        _.find(
                            _.get(this.destination_fields, 'result.fields'),
                            { name: field_name }
                        ),
                        property
                    )
        },
        async local_workflow_condition_store() {
            this.loading_block = 'workflow_condition_store'
            let workflow_condition_item = {}
            workflow_condition_item.service = this.workflow_item.trigger.service.slug
            workflow_condition_item.logic = 'and'
            workflow_condition_item.order = 1
            workflow_condition_item.workflow_id = this.workflow_item.id
            await this.workflow_condition_store(workflow_condition_item)
            // await this.workflow_show({id: this.workflow_item.id})
            this.loading_block = null
        },
        async local_workflow_condition_destroy(id) {
            this.loading_block = 'workflow_condition_destroy'
            await this.workflow_condition_destroy({id: id})
            await this.workflow_show({id: this.workflow_item.id})
            this.loading_item_id = null
            this.loading_block = null
        },
        async local_workflow_condition_patch(field) {
            if (field === 'field_name') {
                await this.workflow_condition_patch({data: { field_name: this.workflow_condition_item.field_name, field_type: this.workflow_condition_item.field_type }})
            }
            if (field === 'operator') {
                await this.workflow_condition_patch({data: { operator: this.workflow_condition_item.operator }})
            }
            if (field === 'value') {
                await this.workflow_condition_patch({data: { value: this.workflow_condition_item.value }})
            }
            if (field === 'value_alt') {
                await this.workflow_condition_patch({data: { value_alt: this.workflow_condition_item.value_alt }})
            }
        },
        async local_workflow_condition_timeout_reset(field, id, timeout = 2000) {
            window.clearTimeout(this.workflow_condition_debounce)
            this.workflow_condition_debounce = window.setTimeout(async () => {
                await this.workflow_condition_select({id: id})
                setTimeout(async () => {
                    if (field === 'field_name') {
                        let source_selected_field = _.find(this.source_fields.result.fields, {name: this.workflow_condition_item.field_name})
                        this.workflow_condition_item.field_type = source_selected_field.type
                    }
                    await this.local_workflow_condition_patch(field)
                    // await this.workflow_condition_clear_item()
                    await this.workflow_condition_show({id: id, mode: 'update'})
                    // await this.workflow_show({id: this.workflow_item.id})
                    window.clearTimeout(this.workflow_condition_debounce)
                }, 100)
            }, timeout);
        },
        async local_workflow_mapping_store() {
            this.loading_block = 'workflow_mapping_store'
            await this.workflow_mapping_clear_item()
            this.workflow_mapping_item.service = this.workflow_item.action.service.slug
            this.workflow_mapping_item.order = 'append'
            this.workflow_mapping_item.workflow_id = this.workflow_item.id
            await this.workflow_mapping_store()
            await this.workflow_show({id: this.workflow_item.id})
            this.loading_block = null
        },
        async local_workflow_mapping_destroy(id) {
            this.loading_block = 'workflow_mapping_destroy'
            await this.workflow_mapping_destroy({id: id})
            await this.workflow_show({id: this.workflow_item.id})
            this.loading_item_id = null
            this.loading_block = null
        },
        async local_workflow_mapping_patch(field) {
            if (field === 'field_name') {
                await this.workflow_mapping_patch({data: { field_name: this.workflow_mapping_item.field_name }})
            }
            if (field === 'value') {
                await this.workflow_mapping_patch({data: { value: this.workflow_mapping_item.value }})
            }
        },
        async local_workflow_mapping_timeout_reset(field, id, timeout = 2000) {
            window.clearTimeout(this.workflow_mapping_debounce)
            this.workflow_mapping_debounce = window.setTimeout(async () => {
                await this.workflow_mapping_select({id: id})
                await this.local_workflow_mapping_patch(field)
                await this.workflow_mapping_clear_item()
                await this.workflow_show({id: this.workflow_item.id})
                window.clearTimeout(this.workflow_mapping_debounce)
            }, timeout);
        },
        local_add_headers() {
            this.headers.push({ key: '', type: 'static', value: '', is_custom_field: 1 })
        },
        local_store_header_value (slug, item) {
            clearTimeout(this.header_value_timeout)
            this.header_value_timeout = setTimeout(async () => {
                this.local_store_headers(slug, item)
                clearTimeout(this.header_value_timeout)
            }, 300)
        },
        local_store_headers (slug, item) {
            if (!item.value || !item.key) return
            if (slug === 'type') return item.value = ''

            item.is_custom_field = 1
            this.local_update_headers()
        },
        async local_update_headers(type = null, index) {
            this.loading_block = 'workflow_header_store'
            if (type === 'remove') {
                this.headers.splice(index, 1)
                this.loading_item_id = null
            }
            await this.workflow_setting_patch({workflow_id: this.workflow_item.id, headers: this.headers});
            this.loading_block = null
        },
        local_alpha_numeric (val, item, field) {
            if (!val) return
            const result = val.replace(/[^aA-zZ0-9-]+/ig, '').replace(/\^/g, '').replace(/\_/g, '').replace(/[\[\]']+/g, '')
            if (item) return item[field] = result
            return result
        },
        local_header_has_value (value, type, list = this.headerValueList) {
            if (type === 'static') return !!value
            if (!list || (list && !list.length)) return false

            const index = list.findIndex(item => item.value === value)
            return index !== -1
        },
        ...mapActions('CustomField', {
            custom_field_index: 'index',
        }),
        ...mapActions('Workflow', {
            workflow_show: 'show',
            workflow_patch: 'patch',
            workflow_toggle_state: 'toggle_state',
            workflow_clear_item: 'clear_item',
        }),
        ...mapActions('WorkflowMeta', {
            workflow_meta_fetch: 'fetch',
            workflow_meta_clear_item: 'clear_item',
        }),
        ...mapActions('WorkflowCondition', {
            workflow_condition_index: 'index',
            workflow_condition_store: 'store',
            workflow_condition_show: 'show',
            workflow_condition_patch: 'patch',
            workflow_condition_destroy: 'destroy',
            workflow_condition_select: 'select',
            workflow_condition_merge: 'merge',
            workflow_condition_clear: 'clear',
            workflow_condition_clear_item: 'clear_item',
        }),
        ...mapActions('WorkflowMapping', {
            workflow_mapping_index: 'index',
            workflow_mapping_store: 'store',
            workflow_mapping_patch: 'patch',
            workflow_mapping_destroy: 'destroy',
            workflow_mapping_select: 'select',
            workflow_mapping_merge: 'merge',
            workflow_mapping_clear: 'clear',
            workflow_mapping_clear_item: 'clear_item',
        }),
        ...mapActions('WorkflowSetting', {
            workflow_setting_index: 'index',
            workflow_setting_clear: 'clear',
            workflow_setting_patch: 'patch',
        }),
        ...mapActions('AppletSalesforce', {
            applet_salesforce_object_index: 'object_index',
            applet_salesforce_object_describe: 'object_describe',
            applet_salesforce_clear: 'clear',
            applet_salesforce_clear_item: 'clear_item',
        }),
    },
}
</script>

<style>
    .editor-title .v-input__slot {
        padding: 0 !important;
    }
    .editor-title .v-input__control {
        min-height: 51px !important;
    }
    .editor-title input {
        font-family: "Inter", sans-serif !important;
        font-size: 2.125rem !important;
        max-height: 40px !important;
        line-height: 40px !important;
        letter-spacing: 0.0073529412px !important;
    }
</style>

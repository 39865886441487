
<template>
    <a-card>
        <a-sheet class="pa-6">
            <div class="u-flex u-wfull">
                <div class="u-flex">
                    <a-icon color="amber darken-3" class="u-icon-nudge" size="32" style="top: 1px;">error</a-icon>
                    <p class="md-subtitle-1 amber--text text--darken-3 mb-0 mt-0 px-2" style="font-weight: 600 !important; font-size: 24px !important;">
                        Validation Failed - Stage change
                    </p>
                </div>
                <a-spacer></a-spacer>
            </div>
        </a-sheet>
        <a-divider class="grey lighten-3"></a-divider>
        <a-card-text class="pa-0 ma-0" style="max-height: 700px;">
            <a-sheet class="pa-6">
                <div class="u-flex">
                    <p class="pink--text mb-0 ml-1 font-weight-bold text-uppercase" style="font-size: 16px;">
                        <a-icon color="pink" class="mr-1" size="24">fact_check</a-icon> Validation Rules
                    </p>
                </div>
                <div class="mt-6 px-0 u-flex">
                    <p class="md-subtitle-1 ml-2 grey--text text--darken-1 font-weight-medium mb-0">
                        <strong>From Stage</strong>  
                        <a-icon size="16" color="grey darken-1" class="mx-1">arrow_forward</a-icon>
                        <strong>To Stage</strong> stage change action could not be performed due to the following validation errors,
                    </p>
                </div>
            </a-sheet>
            <a-sheet class="px-6 pb-6">
                <div class="c-rule-card-view">
                    <div class="u-flex px-6 py-5">
                        <p :title="item.title" class="mb-0 font-weight-medium indigo--text text--darken-1" style="font-size: 16px;">
                            <a-icon color="indigo darken-1" class="u-icon-nudge mr-1" size="20">device_hub</a-icon>
                            {{ item.title | truncateText(64) }}
                        </p>
                        <a-spacer></a-spacer>
                        <a-icon color="red darken-1" size="24">dangerous</a-icon>
                    </div>
                    <a-divider class="grey lighten-3"></a-divider>
                    <div v-for="(validation_group, group_index) in list" :key="group_index">
                        <div class="px-6" v-if="group_index !== 0">
                            <a-sheet height="1" class="u-wfull u-relative c-validation-or-shreet">
                                <span class="u-absolute md-body-2 font-weight-medium body--text text--lighten-2 d-inline-block c-validation-or-card">OR</span>
                            </a-sheet>
                        </div>
                        <div @click="localAccordionPreviewToggle(validation_group.group_id)">
                            <div class="u-flex u-cursor-pointer u-relative px-6 py-5">
                                <div class="u-relative c-error-view">
                                    <div class="c-error-view-child"></div>
                                </div>
                                <p class="ml-2 grey--text text--darken-3 mb-0 font-weight-bold text-uppercase" style="font-size: 16px;">Rule set {{ group_index +1 }}</p>
                                <a-spacer></a-spacer>
                                <div class="c-error-count-view py-1 px-2 u-absolute">
                                    <p class="text-center red--text text--darken-1 mb-0 text-uppercase" style="font-size: 12px;">{{ validation_group.validations.length }} Failed</p>
                                </div>
                                <a-btn small icon class="ml-6 grey darken-1" width="24" height="24">
                                    <a-icon color="white" v-if="localAccordionPreviewIsOpen(validation_group.group_id)" size="18">expand_less</a-icon>
                                    <a-icon v-else color="white" size="18">expand_more</a-icon>
                                </a-btn>
                            </div>
                            <div class="px-3 mb-7" v-if="localAccordionPreviewIsOpen(validation_group.group_id)">
                                <div class="grey lighten-5 u-rounded-corners-2xl px-3 py-4">
                                    <ul class="c-validation-logs mt-2">
                                        <li v-for="(item, index) in validation_group.validations" :key="index">
                                            <div class="c-validation-log-icon">
                                                <a-icon color="red darken-1" size="20">dangerous</a-icon>
                                            </div>
                                            <div class="c-validation-log-text">
                                                <p class="md-subtitle-2 grey--text text--darken-3 mb-0 font-weight-medium"  v-if="item.field_name">
                                                    {{ message(item) }}
                                                </p>
                                                <p class="md-subtitle-2 grey--text text--darken-3 mb-0 font-weight-medium" v-else>-</p>
                                                <p class="md-subtitle-2 grey--text mb-0 font-weight-medium mt-1">Existing / Current value will be shown here</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </a-sheet>
        </a-card-text>
        <a-divider class="grey lighten-3"></a-divider>
        <div class="u-flex pa-6">
            <a-btn @click="$emit('preview')" depressed text class="indigo darken-1 white--text u-rounded-corners elevation-0">
                Edit Rule Messages
            </a-btn>
            <a-btn depressed text class="ml-4 grey lighten-3 grey--text text--darken-1 u-rounded-corners" @click="$emit('close')">
                Close
            </a-btn>
        </div>
    </a-card>
</template>

<script>
export default {
    props: {
        message: {
            type: Function,
            default: {},
        },
        item: {
            type: Object,
            default: {},
            required: true
        },
        icon: {
            type: Function,
            default: {},
        },
        list: {
            type: Array,
            default: [],
        },
        validationErrorPreview:{
            type: Boolean,
            default: false
        },       
    },

    data() {
        return {   
            accordionPreview : []
        }
    },

    watch: {
        validationErrorPreview (newValue) {
            if (newValue) {
                this.accordionPreview = []
                if (this.list.length) this.accordionPreview.push(this.list[0].group_id)
            }
        }
    },

    methods: {
        localAccordionPreviewToggle(group_id){
            const index = this.accordionPreview.findIndex(item => item === group_id)

            if (index !== -1) {
                this.accordionPreview.splice(index, 1)
                return
            }

            this.accordionPreview.push(group_id)
        },

        localAccordionPreviewIsOpen(group_id){
            if (!this.accordionPreview.length) return false

            const index = this.accordionPreview.findIndex(item => item === group_id)
            return index >= 0 ? true : false
        },
    },
}
</script>

<style scoped>
.c-validation {
    top: 0;
    left: 36px;
}
.c-validation-logs {
  margin: 0px;
  padding: 0;
  list-style: none;
  position: relative;
}
.c-validation-logs li {
  list-style: none;
  margin: auto;
  border-left: 2px solid #E0E0E0;
  position: relative;
  left: 10px;
  min-height: 38px;
  padding-bottom: 15px;
}
.c-validation-logs li:last-child {
    border: 0 !important;
    top: 0px;
    left: 12px;
    padding: 0px !important;
}

.c-validation-logs > li .c-validation-log-text {
    position: relative;
    top: -4px;
    left: 17px;
}
.c-validation-logs > li .c-validation-log-icon {
    top: -6px;
    position: absolute;
    left: -11px;
    margin: 0px;
}
.c-validation-error-card {
    border-radius: 40px !important;
}
.c-validation-error-info {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
}
.c-validation-error-close {
    width: 56px !important;
    height: 56px !important;
    border-radius: 50% !important;
}
.c-rule-card-view {
    border-radius: 12px !important;
    border: 1px solid #EEE !important;
}
.c-validation-or-shreet {
    border-radius: 1px;
    background: radial-gradient(50% 50.00% at 50% 50.00%, #616161 0%, rgba(97, 97, 97, 0.08) 100%);
}
.c-error-view {
    background-color: #FFEBEE !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    margin-top: 1px;
}
.c-error-view-child {
    position: absolute;
    background: #E53935;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 4px;
    left: 4px;
}
.c-validation-or-card {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 4px;
    background: #FFF;
    border: 1px solid #BDBDBD;
    font-size: 12px;
    font-weight: 600;
    color: #616161;
    width: 33px;
    height: 24px;
    padding: 0px 7px;
}
.c-error-count-view {
    border-radius: 8px;
    border: 1px solid #FFCDD2;
    background: #FFEBEE;
    position: relative;
    font-weight: 600;
    right: 68px;
    top: 16px;
}
</style>
<template>
	<a-main>
        <a-container grid-list-xl container--fluid mb-10 pa-0 pt-4>
            <a-layout wrap align-center mb-4>
                <a-flex class="py-0">
                    <a-card class="u-rounded-corners px-4 u-border" flat>
                        <a-text-field
                            v-model="searchUser"
                            placeholder="Search user by name or email"
                            background-color="white"
                            prepend-icon="search"
                            :loading="search_loading"
                            solo flat hide-details autofocus
                            @input="localSearchUsers()"
                        ></a-text-field>
                    </a-card>
                </a-flex>
            </a-layout>
            <a-layout wrap>
                <a-flex shrink pr-1>
                    <a-menu bottom offset-y v-model="role_dropdown" max-width="280">
                        <template v-slot:activator="{ on }">
                            <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-border py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="264" v-on="on">
                                <span class="md-body-2 grey--text text--darken-2 mx-1">
                                    <span class="md-body-2 grey--text text--darken-2 mx-1">
                                        <span class="font-weight-medium">Role: 
                                    </span>
                                    <span class="grey--text text--darken-1 font-weight-normal">{{ selected_role.name }}</span>
                                </span>
                                </span>
                                <a-spacer></a-spacer>
                                <a-progress-circular v-if="filter_loading === 'role'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                <a-icon v-else size="20" class="ma-0">arrow_drop_{{ role_dropdown ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                            <a-list-item v-for="role in localFilteredRoleList" class="pr-5" :class="{ 'grey lighten-4': selected_role.value === role.value }" :disabled="local_loading || pagination_loading" :key="role.value" @click="localRoleFilter(role)">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">{{ role.name }}</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink pr-1>
                    <a-menu bottom offset-y v-model="user_status_dropdown" max-width="224">
                        <template v-slot:activator="{ on }">
                            <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-border py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="224" v-on="on">
                                <span class="md-body-2 grey--text text--darken-2 mx-1">
                                    <span class="font-weight-medium">Status: </span>
                                    <span class="grey--text text--darken-1 font-weight-normal">{{ selected_status.label }}</span>
                                </span>
                                <a-spacer></a-spacer>
                                <a-progress-circular v-if="filter_loading === 'status'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                <a-icon v-else size="20" class="ma-0">arrow_drop_{{ user_status_dropdown ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                            <a-list-item v-for="user_status in user_statuses" class="pr-5" :class="{ 'grey lighten-4': selected_status.value === user_status.value }" :disabled="local_loading || pagination_loading" :key="user_status.value" @click="localUserStatusFilter(user_status)">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">{{ user_status.label }}</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-flex shrink pr-1>
                    <a-menu bottom offset-y v-model="sort_dropdown" max-width="200">
                        <template v-slot:activator="{ on }">
                            <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-border py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="200" v-on="on">
                                <span class="md-body-2 grey--text text--darken-2 mx-1">
                                    <span class="font-weight-medium">Sort By: </span>
                                    <span class="grey--text text--darken-1 font-weight-normal">{{ selected_sort.label }}</span>
                                </span>
                                <a-spacer></a-spacer>
                                <a-progress-circular v-if="filter_loading === 'sort'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                <a-icon v-else size="20" class="ma-0">arrow_drop_{{ sort_dropdown ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                            <a-list-item v-for="sort_item in sort_fields" class="pr-5" :class="{ 'grey lighten-4': selected_sort.value === sort_item.value }" :disabled="local_loading || pagination_loading" :key="sort_item.value" @click="localApplySorting(sort_item, sort_direction)">
                                <a-list-item-title>
                                    <span class="md-body-2 grey--text text--darken-3 ml-1">{{ sort_item.label }}</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-flex shrink>
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-card class="u-rounded-corners u-overflow-hidden u-border u-cursor-pointer u-flex align-center justify-center" flat @click="localApplySorting(selected_sort, sort_direction === '-' ? '' : '-')" v-on="on" height="40" width="40">
                                <a-progress-circular v-if="filter_loading === 'sort_direction'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                <a-icon v-else size="20" class="ma-0">{{ (sort_direction === '') ? 'arrow_downward' : 'arrow_upward' }}</a-icon>
                            </a-card>
                        </template>
                        <span v-if="sort_direction === ''">Sorted Ascending</span>
                        <span v-if="sort_direction === '-'">Sorted Descending</span>
                    </a-tooltip>
                </a-flex>
            </a-layout>

            <a-layout wrap align-center mt-4>
                <a-flex xs12 v-if="local_loading">
                    <h2 class="title grey--text text--darken-2 text-center font-italic">Loading...</h2>
                </a-flex>
                <a-flex xs12 v-if="!local_loading && (user_list && !user_list.length)">
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                        <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
                    </a-responsive>
                </a-flex>
                <template v-if="!local_loading">
                    <a-flex xs12 v-for="(user) in localUserEnabledList" :key="user.id">
                        <PExternalUserList
                            :user="user"
                            @toggle-admin="localUpgrade"
                            @toggle-block-user="localToggleDisable"
                            @edit-user="localEdit"
                        />
                    </a-flex>
                </template>
            </a-layout>

            <a-layout class="mx-0 c-sticky-paginate-parent u-border mt-3 u-rounded-corners" v-if="localUserEnabledList && localUserEnabledList.length" :class="{ 'pa-4': !localShowPaginateSection }">
                <a-flex class="u-flex align-center py-0">
                    <div class="md-body-2 grey--text text--darken-2 font-weight-medium mr-2 d-inline-block">Showing {{ user_meta.from }} - {{ user_meta.to }} out of {{ user_meta.total }} user{{ user_meta.total > 1 ? 's' : '' }}</div>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex class="mr-16 pr-16 py-0 u-flex align-center justify-end" v-if="localShowPaginateSection">
                    <a-progress-circular v-if="pagination_loading" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                    <div class="text-right">
                        <a-pagination
                            v-model="user_meta.current_page"
                            :length="user_meta.last_page"
                            :total-visible="10"
                            class="c-inventory-pagination py-1"
                            @input="localPaginate()"
                        ></a-pagination>
                    </div>
                </a-flex>
            </a-layout>
        </a-container>

        <a-dialog v-model="dialog_user_form" max-width="600" scrollable persistent>
            <g-dialog-box hide-close-icon icon="account_box" icon-size="24">
                <template #title>
                    Edit Existing External User
                </template>
                <template #subtitle>
                    You can edit existing external member information.
                </template>
                <template #body>
                    <PExternalUserEditBody/>
                </template>
                <template #footer>
                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="localStore()" :loading="loading" :disabled="loading">Save User</a-btn>
                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="localCloseUserForm()" :disabled="loading">Cancel</a-btn>
                </template>
            </g-dialog-box>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { PExternalUserList, PExternalUserEditBody } from './Partials'

export default {
    components: { PExternalUserList, PExternalUserEditBody },

    data () {
        return {
            local_loading: true,
            search_loading: false,
            filter_loading: null,
            pagination_loading: false,
            selected_roles: [],
            role_dropdown: false,
            selected_territories: [],
            local_search_territory: '',
            territory_dropdown: false,
            sort_direction: '-',
            sort_dropdown: false,
            selected_sort: { label: 'Joined Date', value: 'created_at' },
            sort_fields: [
                { label: 'Name', value: 'name' },
                { label: 'Email', value: 'email' },
                { label: 'Joined Date', value: 'created_at' },
                { label: 'Last Login', value: 'last_login_at' },
            ],
            user_status_dropdown: false,
            selected_status: { label: 'Active Users', value: 1 },
            selected_role: { name: 'All Roles', value: 'all' },
            user_statuses: [
                { label: 'All Users', value: null },
                { label: 'Active Users', value: 1 },
                { label: 'Blocked Users', value: 0 },
            ],
            dialog_user_form: false,
            user_filters: {},
            user_count: 25,
            USER_FILTERS: {
                PAGE: 'page',
                SCOPE: 'filter[scope]',
                SORT: 'sort',
                COUNT: 'count',
                LINKEDPROJECT: 'linked_project',
                FIELDS: 'fields[users]',
                SEARCH: 'filter[user_search]',
                ROLE: 'filter[role_id]',
                RESOURCE_TYPE_FIELDS: 'filter[resource_type]',
                TERRITORY_FIELDS: 'fields[territories]',
                ROLE_FIELDS: 'fields[role]',
                MANAGER_FIELDS: 'fields[manager]',
                INCLUDE: 'include',
                IS_ACTIVE: 'filter[is_active]',
                USER_ROLE_FILTER: 'filter[role_id]',
                USER_ADMIN: 'filter[is_admin]',
                USER_BILLING: 'filter[is_billing]',
            },
            role_filters: {},
            ROLE_FILTERS: {
                SORT: 'sort',
                COUNT: 'count',
                FIELDS: 'fields[roles]',
                SCOPE: 'filter[scope]',
            },
            territory_filters: {},
            TERRITORY_FILTERS: {
                COUNT: 'count',
                FIELDS: 'fields[territories]',
            },
            local_page_count: null,
            searchUser: null,
            search_timeout: null,
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localUserEnabledList() {
            return [...this.user_list ]
        },

        localFilteredRoleList() {
            return [
                {
                    name : 'Client',
                    value : 'Customer'
                },
                {
                    name : 'Partner',
                    value : 'Partner'
                },
                {
                    name : 'All Roles',
                    value : 'all'
                }
            ]
        },

        localShowPaginateSection () {
            return this.user_meta.current_page >= 1 && this.user_meta.last_page !== 1 && this.user_meta.total > 25
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
            user_list: 'list',
            user_total_list: 'total_list',
            user_item: 'item',
            user_meta: 'meta',
            user_action: 'action',
            user_form_mode: 'form_mode',
            user_response: 'response',
        }),
    },

    watch: {
        searchUser(new_value, old_value){
            if (!new_value) {
                this.user_filters[this.USER_FILTERS.SEARCH] = null
                this.localLoadUsers()
            }
        }
    },

    methods: {
        async localIndex() {
            await this.user_clear()
            await this.localLoadUsers()
            this.local_page_count = this.user_meta.current_page
            this.local_loading = false
        },

        async localSearchUsers() {
            clearTimeout(this.search_timeout)
            this.search_timeout = window.setTimeout(async () => {
                if (!this.searchUser.trim()) return
                if (!this.searchUser || this.searchUser.trim().length <= 3) {
                    this.$notify('error', 'Try more than 3 characters to search.')
                    return
                }
                this.search_loading = true
                this.user_filters[this.USER_FILTERS.SEARCH] = this.searchUser
                this.user_filters[this.USER_FILTERS.COUNT] = this.user_count
                this.user_filters[this.USER_FILTERS.PAGE] = 1
                await this.localFetchUsers()
                this.search_loading = false
                this.local_search_mode = true
            }, 1000)
        },

        async localLoadUsers (sort_by, is_active) {
            sort_by = (this.selected_sort && this.selected_sort.value) ?? null
            is_active = (this.selected_status && this.selected_status.value) ?? null

            this.user_filters[this.USER_FILTERS.PAGE] = 1
            this.user_filters[this.USER_FILTERS.LINKEDPROJECT] = 'is_linked_projects'
            this.user_filters[this.USER_FILTERS.SCOPE] = 'external'
            this.user_filters[this.USER_FILTERS.COUNT] = this.user_count
            this.user_filters[this.USER_FILTERS.IS_ACTIVE] = is_active ?? 'all'
            this.user_filters[this.USER_FILTERS.FIELDS] = 'id,name,scope,email,resource_type,last_login_at,job_title,avatar,color,initial,is_active,created_at'
            this.user_filters[this.USER_FILTERS.SORT] = this.sort_direction + (sort_by ?? 'created_at')
            await this.localFetchUsers()
        },

        async localRoleFilter (role) {
            this.filter_loading = 'role'
            this.selected_role = role
            this.user_filters[this.USER_FILTERS.RESOURCE_TYPE_FIELDS] = role.value
            await this.localLoadUsers()
            this.filter_loading = null
        },

        async localApplySorting (sort_item, direction) {
            this.filter_loading = (direction !== this.sort_direction) ? 'sort_direction' : 'sort'
            this.selected_sort = sort_item
            this.sort_direction = direction
            await this.localLoadUsers()
            this.filter_loading = null
        },

        async localUserStatusFilter (status) {
            this.filter_loading = 'status'
            this.selected_status = status
            await this.localLoadUsers()
            this.filter_loading = null
        },

        async localFetchUsers() {
            await this.user_index({...this.user_filters})
        },

        async localEdit(id) {
            await this.user_select({ id })
            this.dialog_user_form = true
        },

        localCloseUserForm () {
            this.dialog_user_form = false
            this.user_clear_item()
        },

        async localStore () {
            let managerId = null
            if (this.user_item.manager_id) managerId = this.user_item.manager_id
            if (this.user_item.manager) managerId = this.user_item.manager.id
            if (!this.user_item.manager) managerId = null

            await this.user_update({
                id: this.user_item.id,
                name: this.user_item.name,
                email: this.user_item.email,
                job_title: this.user_item.job_title,
                role_id: this.user_item.role_id,
                manager_id: managerId,
            })

            if (this.user_response.status !== 'success') return this.$notify('error', 'Something went wrong! Please re-check the fields and try again.')

            const userId = (this.user_item && this.user_item.id) ?? null
            this.localUserShow(userId, this.user_form_mode, { 'include': 'role,manager,territories', 'fields[territories]': 'territories.id,color,name', 'fields[users]': 'id,name,initial,email,avatar,job_title,color,is_active,is_billing,is_admin,role_id,manager_id,created_at', 'fields[role]': 'id,name' })
            this.$notify('success', 'User updated successfully!')
            this.localCloseUserForm()
            if (this.user_item.id === this.user_self.id) this.user_me()
        },

        async localUserShow(id, mode, include = null) {
            if (id === this.user_self.id) mode = 'self'
            await this.user_show({ id , mode, params: include })
        },

        async localUpgrade(id, state) {
            const upgrade_item = {}
            upgrade_item.id = id
            upgrade_item.state = state ? 0 : 1
            await this.user_upgrade(upgrade_item)
            if (this.user_response.status === 'success') {
                await this.localUserShow(id, 'show', {  'fields': 'users.id,users.is_admin' })
                await this.user_clear_item()
                this.$notify('success', `${ state === 1 ? 'Disabled' : 'Enabled' } admin privilege`)
            }
        },

        async localToggleDisable({ id, name }, state) {
            await this.user_disable({id: id, state : state })
            if (this.user_response.status === 'success') {
                this.$notify('success', `${this.$options.filters.truncateText(name, 30)} has been ${ state === 0 ? 'blocked' : 'unblocked' }`)
                this.localUserShow(id, 'update', { 'include': 'role,manager,territories', 'fields[territories]': 'territories.id,color,name', 'fields[users]': 'id,name,email,avatar,color,is_active,is_billing,is_admin,role_id,manager_id,created_at', 'fields[role]': 'id,name' })
            }
        },

        async localPaginate () {
            if(this.local_page_count == this.user_meta.current_page) return
            this.pagination_loading = true
            await this.$vuetify.goTo(0)
            this.user_filters[this.USER_FILTERS.PAGE] = this.user_meta.current_page
            await this.localFetchUsers()
            this.local_page_count = this.user_meta.current_page
            this.pagination_loading = false
        },

        ...mapActions('User', {
            user_index: 'index',
            user_enabled: 'enabled',
            user_me: 'me',
            user_show: 'show',
            user_store: 'store',
            user_update: 'update',
            user_update_meta: 'update_meta',
            user_select: 'select',
            user_upgrade: 'upgrade',
            user_downgrade: 'downgrade',
            user_billing_toggle: 'billing_toggle',
            user_enable: 'enable',
            user_disable: 'disable',
            user_territory_store: 'territory_store',
            user_territory_destroy: 'territory_destroy',
            user_clear: 'clear',
            user_clear_item: 'clear_item',
        }),
    },
}
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>

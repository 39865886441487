<template>
    <div
        @click="localOpenSearchDialog()"
        v-ripple="{ class: 'grey--text text--lighten-2' }"
        style="width: 160px"
        class="u-wfull u-hfull mr-5 u-flex-center-y u-rounded-corners-xl u-cursor-pointer px-3 py-2 u-font-14 u-border"
    >
        <div class="u-flex-center-y">
            <a-icon color="gre darken-1" size="20">search</a-icon>
            <span class="grey--text text--lighten-1 ml-1 u-font-14 font-weight-medium u-leading-20 u-tracking">
                Search
            </span>
        </div>
        <a-spacer></a-spacer>
        <div class="u-flex-center-y">
            <svg v-if="localIsMacOs" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M9.66667 0C8.38 0 7.33333 1.04667 7.33333 2.33333V3.33333H4.66667V2.33333C4.66667 1.04667 3.62 0 2.33333 0C1.04667 0 0 1.04667 0 2.33333C0 3.62 1.04667 4.66667 2.33333 4.66667H3.33333V7.33333H2.33333C1.04667 7.33333 0 8.38 0 9.66667C0 10.9533 1.04667 12 2.33333 12C3.62 12 4.66667 10.9533 4.66667 9.66667V8.66667H7.33333V9.66667C7.33333 10.9533 8.38 12 9.66667 12C10.9533 12 12 10.9533 12 9.66667C12 8.38 10.9533 7.33333 9.66667 7.33333H8.66667V4.66667H9.66667C10.9533 4.66667 12 3.62 12 2.33333C12 1.04667 10.9533 0 9.66667 0ZM8.66667 3.33333V2.33333C8.66667 1.78 9.11333 1.33333 9.66667 1.33333C10.22 1.33333 10.6667 1.78 10.6667 2.33333C10.6667 2.88667 10.22 3.33333 9.66667 3.33333H8.66667ZM2.33333 3.33333C1.78 3.33333 1.33333 2.88667 1.33333 2.33333C1.33333 1.78 1.78 1.33333 2.33333 1.33333C2.88667 1.33333 3.33333 1.78 3.33333 2.33333V3.33333H2.33333ZM4.66667 7.33333V4.66667H7.33333V7.33333H4.66667ZM9.66667 10.6667C9.11333 10.6667 8.66667 10.22 8.66667 9.66667V8.66667H9.66667C10.22 8.66667 10.6667 9.11333 10.6667 9.66667C10.6667 10.22 10.22 10.6667 9.66667 10.6667ZM2.33333 10.6667C1.78 10.6667 1.33333 10.22 1.33333 9.66667C1.33333 9.11333 1.78 8.66667 2.33333 8.66667H3.33333V9.66667C3.33333 10.22 2.88667 10.6667 2.33333 10.6667Z" fill="url(#paint0_linear_97_1149)"/>
                <defs>
                    <linearGradient id="paint0_linear_97_1149" x1="6" y1="0" x2="6" y2="12" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#7CB7E9"/>
                        <stop offset="1" stop-color="#967AEB"/>
                    </linearGradient>
                </defs>
            </svg>
            <span
                v-else
                class="u-font-14 ml-1 u-font-weight-semibold u-leading-16 u-tracking"
                style="
                    background: linear-gradient(180deg, #7CB7E9 0%, #967AEB 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                "
            >
                Ctrl
            </span>
            <span
                class="u-font-14 ml-1 u-font-weight-semibold u-leading-16 u-tracking"
                style="
                    background: linear-gradient(180deg, #7CB7E9 0%, #967AEB 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                "
            >
                K
            </span>
        </div>
    </div>
</template>

<script>
import EventBus from '@/config/config-eventbus'
import { isUserOs } from '../../helpers/helper-detect-user-os'

export default {
    data () {
        return {
            isOpen: false,
        }
    },

    mounted () {
        this.localIndex()
    },

    beforeDestroy () {
        this.localRemoveListeners()
    },

    computed: {
        localIsMacOs () {
            return isUserOs() === 'mac'
        },
    },

    methods: {
        localIndex () {
            EventBus.$on('global-search-dialog', this.localOverlayListener)
            this.localAddListeners()
        },

        localOverlayListener (val) {
            this.isOpen = val
        },

        localOpenSearchDialog () {
            EventBus.$emit('global-search-dialog', true)
        },

        localCloseSearchDialog () {
            EventBus.$emit('global-search-dialog', false)
        },

        localHandleKeydown (evt) {
            if (evt.code === 'KeyK' && (evt.metaKey || evt.ctrlKey)) {
                evt.preventDefault()
                evt.stopPropagation()

                !this.isOpen ?
                    this.localOpenSearchDialog() :
                    this.localCloseSearchDialog()
            }
        },

        localAddListeners () {
            document.addEventListener('keydown', this.localHandleKeydown)
        },

        localRemoveListeners () {
            document.removeEventListener('keydown', this.localHandleKeydown)
        },
    }
}
</script>

<template>
    <a-layout align-start>
        <a-flex pt-0>
            <a-tabs class="mb-0" background-color="transparent" slider-color="primary">
                <a-tab ripple class="mx-2" :to="{name: 'settings-metas-inventory-categories'}">
                    <span class="md-body-2">Categories</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-metas-inventory-tags'}">
                    <span class="md-body-2">Tags</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-metas-inventory-stages'}">
                    <span class="md-body-2">Stages</span>
                </a-tab>
                <!-- <a-tab ripple class="mx-2" :to="{name: 'settings-metas-inventory-statuses'}">
                    <span class="md-body-2">Statuses</span>
                </a-tab> -->
            </a-tabs>
            <a-divider class="grey lighten-2"></a-divider>
        </a-flex>
    </a-layout>
</template>

<script>
export default {

}
</script>

const api_endpoint = '/assignees';

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        response: [],
        defaults: {
            list: [],
            item: {},
            response: [],
        },
    },
    mutations: {
        UPDATE_ITEM(state, payload) {
            state.item = payload
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.post(api_endpoint, payload)
                .then((response) => {
                    context.commit('UPDATE_ITEM', payload)
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success'});
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear(context, payload) {
            context.commit('UPDATE_ITEM', context.state.defaults.item)
            context.commit('THROW_RESPONSE', context.state.defaults.response)
        }
    },
}

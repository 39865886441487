<template>
    <a-sheet class="u-shadow u-rounded-corners-lg">
        <div class="pa-6 u-flex-center-y">
            <div class="u-flex-center-y">
                <a-sheet width="48" height="48" class="u-border-light u-flex-center u-rounded-corners">
                    <img class="d-block" style="width: 32px; height: 32px; object-fit: contain;" src="../../assets/images/logo-salesforce.svg" alt="Salesforce" v-if="workflow_item.trigger && workflow_item.trigger.service && workflow_item.trigger.service.slug === 'salesforce'">
                    <img class="d-block" style="width: 32px; height: 32px; object-fit: contain;" src="../../assets/images/success-imgs/icon-success.svg" alt="Success" v-if="workflow_item.trigger && workflow_item.trigger.service && workflow_item.trigger.service.slug === 'success'">
                    <img class="d-block" style="width: 32px; height: 32px; object-fit: contain;" src="../../assets/images/apps/hubspot/logo.svg" alt="Hubspot" v-if="workflow_item.trigger && workflow_item.trigger.service && workflow_item.trigger.service.slug === 'hubspot'">
                    <img class="d-block" style="width: 32px; height: 32px; object-fit: contain;" src="../../assets/images/apps/jira/logo.svg" alt="Hubspot" v-if="workflow_item.trigger && workflow_item.trigger.service && workflow_item.trigger.service.slug === 'jira'">
                </a-sheet>
                <div class="ml-5">
                    <h3 class="md-subtitle-1 secondary--text font-weight-bold text-uppercase">Trigger</h3>
                    <h4 class="md-subtitle-1 grey--text text--darken-3 font-weight-medium">{{ workflow_item.trigger ? workflow_item.trigger.label : '' }}</h4>
                </div>
            </div>
            <a-spacer></a-spacer>
            <a-btn v-if="!localConditionsLength && $can('applets.update')" depressed class="u-rounded-corners-lg primary font-weight-medium" @click="localAddCondition()">
                <a-icon color="white" size="20" class="mr-1">add</a-icon>
                Add Condition
            </a-btn>
        </div>

        <template>
            <a-divider></a-divider>
            <div v-if="localConditionsLength" class="pa-6 pt-5 pb-6">
                <h3 class="md-body-2 text-uppercase grey--text text--darken-3 font-weight-medium mb-5">Conditions</h3>
                <div class="u-flex-center-y" :class="[{ 'mt-6': index !== 0 }]" style="column-gap: 24px" v-for="(condition, index) in conditionsList" :key="index">
                    <div style="width: 50px; height: 50px; flex: 0 0 auto;">
                        <a-tooltip bottom v-if="localGetConditionStatus(condition)">
                            <template v-slot:activator="{ on }">
                                <div v-on="on" :style="{ backgroundColor: localGetConditionStatus(condition, 'iconBg') }" class="u-flex-center u-rounded-corners" style="width: 50px; height: 50px;">
                                    <a-icon :color="localGetConditionStatus(condition, 'iconColor')" size="20">{{ localGetConditionStatus(condition, 'icon') }}</a-icon>
                                </div>
                            </template>
                            <span>{{ localGetConditionStatus(condition, 'message') }}</span>
                        </a-tooltip>
                    </div>

                    <div style="flex: 1 0 200px;">
                        <a-tooltip bottom offset-overflow :disabled="!localGetValidationError(condition, 'field_name')">
                            <template v-slot:activator="{ on }">
                                <div v-on="on" class="u-rounded-corners-lg">
                                    <a-autocomplete
                                        v-model="condition.field_name"
                                        :items="keyItems"
                                        item-text="label"
                                        item-value="name"
                                        placeholder="Select Field"
                                        :disabled="localFieldIsDisabled || localGetConditionStatus(condition, 'label') === 'deleted'"
                                        class="u-rounded-corners-lg"
                                        :class="[localGetValidationError(condition, 'field_name') ? 'c-wiggle-short u-border-error' : 'u-border-light']"
                                        @change="localUpdateCondition(condition, 'field_name', $event)"
                                        flat solo hide-details
                                    ></a-autocomplete>
                                </div>
                            </template>
                            <span>{{ localGetValidationError(condition, 'field_name') }}</span>
                        </a-tooltip>
                    </div>
                    <div style="flex: 1 0 200px;">
                        <a-tooltip bottom offset-overflow :disabled="!localGetValidationError(condition, 'operator')">
                            <template v-slot:activator="{ on }">
                                <div v-on="on" :class="['u-rounded-corners-lg', localGetValidationError(condition, 'operator') ? 'c-wiggle-short u-border-error' : 'u-border-light']">
                                    <a-select
                                        v-model="condition.operator"
                                        :items="operators(condition.field_type)"
                                        item-text="label"
                                        item-value="name"
                                        placeholder="Select Operator"
                                        :disabled="localFieldIsDisabled || localGetConditionStatus(condition, 'label') === 'deleted'"
                                        class="u-rounded-corners-lg"
                                        @change="localUpdateCondition(condition, 'operator', $event, index)"
                                        flat solo hide-details
                                    ></a-select>
                                </div>
                            </template>
                            <span>{{ localGetValidationError(condition, 'operator') }}</span>
                        </a-tooltip>
                    </div>
                    <div class="u-flex-center-y" style="flex: 1 0 200px; column-gap: 24px;">
                        <div class="u-wfull">
                            <a-tooltip v-if="condition.field_type === 'enumeration'" bottom offset-overflow :disabled="!localGetValidationError(condition, 'value') && !localGetValueError(condition, 'value')">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" class="u-rounded-corners-lg">
                                        <a-autocomplete
                                            v-model="condition.value"
                                            :items="picklists(condition.field_name)"
                                            item-text="label"
                                            item-value="value"
                                            placeholder="Value"
                                            class="u-rounded-corners-lg"
                                            :class="[localGetValidationError(condition, 'value') || localGetValueError(condition, 'value') ? 'c-wiggle-short u-border-error' : 'u-border-light']"
                                            flat solo hide-details
                                            :disabled="localFieldIsDisabled || localGetConditionStatus(condition, 'label') === 'deleted'"
                                            @input="localUpdateCondition(condition, 'value', $event)"
                                        ></a-autocomplete>
                                    </div>
                                </template>
                                <span>{{ localGetValidationError(condition, 'value') || localGetValueError(condition, 'value') }}</span>
                            </a-tooltip>
                            <a-tooltip v-else-if="localCheckConditionType && (condition.field_type === 'datetime' || condition.field_type === 'date')" bottom offset-overflow :disabled="!localGetValidationError(condition, 'value') && !localGetValueError(condition, 'value')">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" class="u-rounded-corners-lg">
                                        <!-- <a-menu
                                            v-model="accordionStartDate[index]"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            min-width="290px"
                                            full-width offset-y lazy
                                        >   
                                            <template v-slot:activator="{ on }">
                                                <a-text-field
                                                    v-model="condition.value"
                                                    :value="localFormatDate(condition.value)"
                                                    :placeholder="(sourceFilter(condition.field_type, condition.operator, 'allow_alt_input')) ? 'Start' : 'Value'"
                                                    class="u-cursor-pointer u-rounded-corners-lg u-overflow-hidden"
                                                    :class="[localGetValidationError(condition, 'value') || localGetValueError(condition, 'value') ? 'c-wiggle-short u-border-error' : 'u-border-light']"
                                                    ref="refConditionTextField"
                                                    :maxlength="valueFieldMaxLength"
                                                    flat solo hide-details readonly
                                                    v-on="on"
                                                    append-icon="event"
                                                    @click:append="localAccordionDateToggle(index, 'start_date')"
                                                    :disabled="localFieldIsDisabled || localGetConditionStatus(condition, 'label') === 'deleted'"
                                                    @input="localUpdateCondition(condition, 'value', $event)"
                                                ></a-text-field>
                                            </template>
                                            <a-date-picker v-model="condition.value" @input="localAccordionDateToggle(index, 'start_date')" no-title scrollable>
                                            </a-date-picker>
                                        </a-menu> -->
                                        <vc-date-picker 
                                            v-model="condition.value" 
                                            class="d-block u-wfull"
                                            :max-date="condition.value_alt ? condition.value_alt : null"
                                            @input="localAccordionDateToggle(index, 'start_date')"  
                                            :model-config="modelConfig"
                                        >
                                            <template v-slot="{togglePopover}">
                                                <a-sheet width="100%" @click="localCanUpdate(condition) ? '' : togglePopover({ placement: 'bottom-start' })">
                                                    <template>
                                                        <a-text-field
                                                            v-model="condition.value"
                                                            :value="localFormatDate(condition.value)"
                                                            :placeholder="(sourceFilter(condition.field_type, condition.operator, 'allow_alt_input')) ? 'Start' : 'Value'"
                                                            class="u-cursor-pointer u-rounded-corners-lg u-overflow-hidden"
                                                            :class="[localGetValidationError(condition, 'value') || localGetValueError(condition, 'value') ? 'c-wiggle-short u-border-error' : 'u-border-light']"
                                                            ref="refConditionTextField"
                                                            :maxlength="valueFieldMaxLength"
                                                            flat solo hide-details readonly
                                                            v-on="on"
                                                            append-icon="event"
                                                            @click:append="localAccordionDateToggle(index, 'start_date')"
                                                            :disabled="localFieldIsDisabled || localGetConditionStatus(condition, 'label') === 'deleted'"
                                                            @input="localUpdateCondition(condition, 'value', $event)"
                                                    ></a-text-field>
                                                    </template>
                                                </a-sheet>
                                            </template>
                                        </vc-date-picker>
                                    </div>
                                </template>
                                <span>{{ localGetValidationError(condition, 'value') || localGetValueError(condition, 'value') }}</span>
                            </a-tooltip>
                            <template v-else>
                                <a-tooltip v-if="condition.field_type === 'picklist'" bottom offset-overflow :disabled="!localGetValidationError(condition, 'value') && !localGetValueError(condition, 'value')">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="u-rounded-corners-lg">
                                            <a-autocomplete
                                                v-model="condition.value"
                                                :items="picklists(condition.field_name, 'picklistValues')"
                                                item-text="label"
                                                item-value="name"
                                                :placeholder="localGetPlaceholder(condition)"
                                                class="u-rounded-corners-lg"
                                                :class="[localCheckError(condition, 'value') ? 'c-wiggle-short u-border-error' : 'u-border-light']"
                                                flat solo hide-details
                                                :disabled="localFieldIsDisabled || localGetConditionStatus(condition, 'label') === 'deleted'"
                                                @input="localUpdateCondition(condition, 'value', $event)"
                                            ></a-autocomplete>
                                        </div>
                                    </template>
                                    <span>{{ localGetValidationError(condition, 'value') || localGetValueError(condition, 'value') }}</span>
                                </a-tooltip>
                                <a-tooltip v-else bottom offset-overflow :disabled="!localGetValidationError(condition, 'value') && !localGetValueError(condition, 'value')">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="u-rounded-corners-lg">
                                            <a-text-field
                                                v-model="condition.value"
                                                :placeholder="(sourceFilter(condition.field_type, condition.operator, 'allow_alt_input')) ? 'Start' : 'Value'"
                                                class="u-rounded-corners-lg u-overflow-hidden"
                                                :class="[(localGetValidationError(condition, 'value') || localGetValueError(condition, 'value')) ? 'c-wiggle-short u-border-error' : 'u-border-light']"
                                                ref="refConditionTextField"
                                                :maxlength="valueFieldMaxLength"
                                                flat solo hide-details
                                                :disabled="localFieldIsDisabled || localGetConditionStatus(condition, 'label') === 'deleted' || ((condition.operator === 'null' || condition.operator === 'not_null') && localGetConditionStatus(condition, 'custom') === 'nullable')"
                                                @input="localUpdateCondition(condition, 'value', $event)"
                                            ></a-text-field>
                                        </div>
                                    </template>
                                    <span>{{ localGetValidationError(condition, 'value') || localGetValueError(condition, 'value') }}</span>
                                </a-tooltip>
                            </template>
                        </div>

                        <div style="flex: 0 1 100%;" v-if="sourceFilter(condition.field_type, condition.operator, 'allow_alt_input')">
                            <a-tooltip bottom offset-overflow :disabled="!localGetValidationError(condition, 'value_alt') && !localGetValueError(condition, 'value_alt')">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" class="u-rounded-corners-lg">
                                        <template v-if="localCheckConditionType &&  (condition.field_type === 'datetime' || condition.field_type === 'date')">
                                            <!-- <a-menu
                                                v-model="accordionEndDate[index]"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                min-width="290px"
                                                full-width offset-y lazy
                                            >                                      
                                                <template v-slot:activator="{ on }">
                                                    <a-text-field
                                                        v-model="condition.value_alt"
                                                        :value="localFormatDate(condition.value_alt)"
                                                        placeholder="End"
                                                        class="u-cursor-pointer u-rounded-corners-lg u-overflow-hidden"
                                                        :class="[localGetValidationError(condition, 'value') || localGetValueError(condition, 'value') ? 'c-wiggle-short u-border-error' : 'u-border-light']"
                                                        ref="refConditionTextField"
                                                        :maxlength="valueFieldMaxLength"
                                                        flat solo hide-details readonly
                                                        v-on="on"
                                                        append-icon="event"
                                                        @click:append="localAccordionDateToggle(index, 'end_date')"
                                                        :disabled="localFieldIsDisabled || localGetConditionStatus(condition, 'label') === 'deleted'"
                                                        @input="localUpdateCondition(condition, 'value_alt', $event)"
                                                    ></a-text-field>
                                                </template>
                                                <a-date-picker v-model="condition.value_alt" @input="localAccordionDateToggle(index, 'end_date')" no-title scrollable>
                                                </a-date-picker>
                                            </a-menu> -->
                                            <vc-date-picker 
                                                v-model="condition.value_alt" 
                                                class="d-block u-wfull"
                                                :min-date="localFormatDate(condition.value)"
                                                @input="localAccordionDateToggle(index, 'end_date')"                                                     
                                                :model-config="modelConfig"
                                            >
                                                <template v-slot="{togglePopover}">
                                                    <a-sheet width="100%" @click="localCanUpdate(condition) ? '' : togglePopover({ placement: 'bottom-start' })">
                                                        <template>
                                                            <a-text-field
                                                                v-model="condition.value_alt"
                                                                :value="localFormatDate(condition.value_alt)"
                                                                placeholder="End"
                                                                class="u-cursor-pointer u-rounded-corners-lg u-overflow-hidden"
                                                                :class="[localGetValidationError(condition, 'value') || localGetValueError(condition, 'value') ? 'c-wiggle-short u-border-error' : 'u-border-light']"
                                                                ref="refConditionTextField"
                                                                :maxlength="valueFieldMaxLength"
                                                                flat solo hide-details readonly
                                                                v-on="on"
                                                                append-icon="event"
                                                                @click:append="localAccordionDateToggle(index, 'end_date')"
                                                                :disabled="localFieldIsDisabled || localGetConditionStatus(condition, 'label') === 'deleted'"
                                                                @input="localUpdateCondition(condition, 'value_alt', $event)"
                                                            ></a-text-field>
                                                        </template>
                                                    </a-sheet>
                                                </template>
                                            </vc-date-picker>
                                        </template>
                                        <template v-else>
                                            <a-text-field
                                                v-model="condition.value_alt"
                                                placeholder="End"
                                                class="u-rounded-corners-lg u-overflow-hidden"
                                                :class="[localGetValidationError(condition, 'value_alt') || localGetValueError(condition, 'value_alt') ? 'c-wiggle-short u-border-error' : 'u-border-light']"
                                                :maxlength="valueAltFieldMaxLength"
                                                flat solo hide-details
                                                :disabled="localFieldIsDisabled || localGetConditionStatus(condition, 'label') === 'deleted'"
                                                @input="localUpdateCondition(condition, 'value_alt', $event)"
                                            ></a-text-field>
                                        </template>
                                    </div>
                                </template>
                                <span>{{ localGetValidationError(condition, 'value_alt') || localGetValueError(condition, 'value_alt') }}</span>
                            </a-tooltip>
                        </div>
                    </div>

                    <div v-if="canUpdate" style="width: 50px; height: 50px; flex: 0 0 auto;">
                        <a-tooltip bottom left v-if="!localIsConditionDeleted(condition)" :disabled="mixinIsLoading('update-conditions')">
                            <template v-slot:activator="{ on }">
                                <div v-on="on" @click="!mixinIsLoading('update-conditions') ? localRemoveCondition(condition) : ''" class="u-border-light u-rounded-corners u-flex-center" style="height: 50px; width: 50px; cursor: pointer">
                                    <a-icon color="grey" size="20">remove_circle_outline</a-icon>
                                </div>
                            </template>
                            <span>Remove</span>
                        </a-tooltip>
                        <a-tooltip bottom left v-else :disabled="mixinIsLoading('update-conditions')">
                            <template v-slot:activator="{ on }">
                                <div v-on="on" @click="!mixinIsLoading('update-conditions') ? localUndoDelete(condition) : ''" class="u-border-light u-rounded-corners u-flex-center grey lighten-4" style="height: 50px; width: 50px; cursor: pointer">
                                    <a-icon color="grey darken-3" size="20">undo</a-icon>
                                </div>
                            </template>
                            <span>Revert</span>
                        </a-tooltip>
                    </div>
                </div>
            </div>
            <div class="pa-6" :class="[{'pt-1': localConditionsLength !== 0}]" v-if="deletedField.is_condition_source_deleted === 1 && showDeleted" >
                <div class="amber lighten-5 rounded-lg" style="padding: 22px 74px">
                    <div class="d-flex justify-space-between">
                        <div class="amber--text text--darken-4 font-weight-medium">
                            <a-icon color="#FB8C00" size="19" class="mr-1 pl-1">warning</a-icon> Invalid conditions
                        </div>
                        <div style="cursor:pointer" class="u-rounded-corners-lg font-weight-medium grey--text text--darken-2" @click="localClearDeletedFields()">
                            <a-icon color="#616161" size="20" class=" pl-1">cancel</a-icon>                  
                            clear
                        </div>  
                    </div>   
                    <p class="grey--text text--darken-1 font-weight-medium pt-3">The specified conditions are unavailable since the associated custom fields no longer exist. This reference is temporary and it will be removed after updating the workflow.</p>     
                    <div class="">
                        <div class="u-flex-center-y grey--text text--darken-1 font-weight-medium" :class="[{'mt-5': i !== 0}]" style="column-gap: 24px"  v-for="(item, i) in deletedField.recent_deleted_sources" :key="i">
                            <div style="flex: 1 0 200px;">
                                <a-text-field                            
                                    :placeholder="item.field_name"
                                    disabled
                                    class="u-rounded-corners-lg u-border-light"                                       
                                    flat solo hide-details
                                ></a-text-field>
                            </div>
                            <div style="flex: 1 0 200px;">
                                <a-text-field                                                                              
                                    :placeholder="item.operator"
                                    disabled
                                    class="u-rounded-corners-lg u-border-light"                                       
                                    flat solo hide-details
                                ></a-text-field>
                            </div>
                            <div style="flex: 1 0 200px;">
                                <a-text-field                                                                 
                                    :placeholder="item.field_type === 'dropdown' ? item.field_label : item.field_value"
                                    disabled
                                    class="u-rounded-corners-lg u-border-light"                                 
                                    flat solo hide-details
                                ></a-text-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a-divider v-if="canUpdate"></a-divider>
            <div class="pa-6 u-flex align-stretch" v-if="canUpdate && localConditionsLength">
                <a-btn height="32" depressed small @click="localSaveCondition()" :loading="mixinIsLoading('update-conditions')" :disabled="!localHasAnyChanges || baseLoading" class="ma-0 px-2 py-3 indigo darken-1 white--text u-rounded-corners">
                    Save Conditions
                </a-btn>
                <a-btn height="32" depressed small @click="localTriggerCancelChanges()" :disabled="!localHasAnyChanges || mixinIsLoading('update-conditions') || baseLoading" class="ml-4 px-2 py-3 grey--text u-rounded-corners">
                    Cancel Changes
                </a-btn>
                <a-sheet v-if="localHasInvalidCondition" class="px-4 u-rounded-corners d-flex align-center orange lighten-5 ml-4">
                    <a-icon size="16px" color="orange darken-1">info</a-icon>
                    <p class="ml-2 font-weight-medium mb-0 orange--text text--darken-1">One or more conditions are invalid</p>
                </a-sheet>
                <a-spacer></a-spacer>
                <a-btn height="32" depressed small :disabled="mixinIsLoading('update-conditions') || baseLoading" @click="localAddCondition()" class="ma-0 px-2 py-3 primary u-rounded-corners">
                    <a-icon size="16" class="mr-1">add</a-icon>
                    Add Condition
                </a-btn>
            </div>
        </template>
    </a-sheet>
</template>

<script>
import { mapState } from 'vuex'
import mixinLoading from '@/mixins/mixin-module-loading-setup'

export default {
    props: {
        list: {
            type: Array,
            required: true,
        },
        keyItems: {
            type: Array,
            default: () => [],
        },
        sourceFields: {
            type: Object,
        },
        operators: {
            type: Function,
        },
        picklists: {
            type: Function,
        },
        sourceFilter: {
            type: Function,
        },
        baseLoading: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean,
            default: false
        },
        conditionType: {
            type: String,
            default: null
        },
        deletedField: {
            type: Object
        }
    },

    watch: {
        list: {
            handler (val) {
                this.conditionsList = _.cloneDeep(val)
                this.conditionsListCopy = _.cloneDeep(val)
            },
            immediate: true,
            deep: true
        }
    },

    mixins: [mixinLoading],

    data () {
        return {
            datepicker_date: false,
            valueFieldMaxLength: -1,
            valueAltFieldMaxLength: -1,
            valueFieldsError: [],
            removedConditions: [],
            accordionEndDate: [],
            accordionStartDate: [],
            conditionsListCopy: _.cloneDeep(this.list),
            conditionsList: [],
            conditionParams: {
                order: 1,
                logic: 'and',
                field_name: null,
                field_type: null,
                operator: null,
                value: null,
                value_alt: null,
                type: 'new'
            },
            showDeleted: true,
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
        }
    },

    mounted () {
        this.conditionsList = _.cloneDeep(this.list)
        this.conditionsListCopy = _.cloneDeep(this.list)
    },

    computed: {
        localWorkflowId () {
            return (this.workflow_item && this.workflow_item.id) || this.$route.params.workflow_id
        },

        localConditionsLength () {
            return this.conditionsList.length
        },

        localHasInvalidCondition () {
            const index = this.conditionsList.findIndex(c => (!c.type && !c.is_valid) || c.is_value_deleted)
            return index !== -1
        },

        localCheckConditionType () {
            return ['hubspot_conditions', 'jira_conditions', 'salesforce_conditions'].includes(this.conditionType)
        },

        localInputTypes () {
            return {
                new: { label: 'new', icon: 'add_circle_outline', iconBg: '#E8EAF6', iconColor: '#3949AB', message: 'New', custom: 'nullable' },
                modified: { label: 'modified', icon: 'drive_file_rename_outline', iconBg: '#E8EAF6', iconColor: '#3949AB', message: 'Modified', custom: 'nullable' },
                deleted: { label: 'deleted', icon: 'delete', iconBg: '#FFEBEE', iconColor: '#E53935', message: 'Deleted' },
                valid: { label: 'valid', icon: 'check_circle', iconBg: '#E8F5E9', iconColor: '#43A047', message: 'Valid' },
                invalid: { label: 'invalid', icon: 'error', iconBg: '#FFF3E0', iconColor: '#FB8C00', message: 'Invalid' },
                isValueDeleted: { label: 'invalid', icon: 'error', iconBg: '#FFF3E0', iconColor: '#FB8C00', message: 'Selected value is deleted' },
                isnullable: {label: 'valid', icon: 'check_circle', iconBg: '#E8F5E9', iconColor: '#43A047', message: 'valid', custom: 'nullable'}
            }
        },

        localHasAnyChanges () {
            if (this.removedConditions.length) return true

            let hasChanges = false
            this.conditionsList.forEach(condition => {
                const label = this.localGetConditionStatus(condition, 'label')
                if (!['valid', 'invalid'].includes(label)) hasChanges = true
            })

            return hasChanges
        },

        localFieldIsDisabled () {
            return !this.canUpdate || this.mixinIsLoading('update-conditions') || this.baseLoading
        },

        ...mapState('Workflow', {
            workflow_item: 'item',
        }),
    },

    methods: {
        localAddCondition () {
            const { trigger } = this.workflow_item || {}
            const params = this.$appendId({
                service: trigger && trigger.service && trigger.service.slug,
                workflow_id: this.localWorkflowId,
                type: 'new',
                ...this.conditionParams
            })
            this.conditionsList.push(params)
            this.localTriggerConditionChanges()
        },

        localTriggerConditionChanges () {
            this.$emit('changes', { changes: this.localHasAnyChanges, clearChanges: this.localCancelChanges })
        },

        async localUpdateCondition (condition, field, value, index) {
            if (condition.operator !== "between" && condition.value_alt) condition.value_alt = ''
            if (condition.operator === "null" || condition.operator === "not_null") {
                this.conditionsList[index].value = ''
            }
            if (field === 'value' || field === 'value_alt') await this.localValidateValueFields(condition, field)
            if (field === 'field_name') {
                const { type } = _.find(this.sourceFields.result.fields, { name: value })
                Object.assign(condition, { field_type: type, operator: null, value: null, value_alt: null })
                this.valueFieldsError = []
            }
            this.localTriggerConditionChanges()
        },

        async localValidateValueFields (condition, field) {
            const { id, value, value_alt } = condition || {}
            const params = { id, field, message: 'Maximum of 255 characters allowed. Value will be truncated to 255 characters on "Save".' }
            const index = this.valueFieldsError.findIndex(item => item.id === condition.id && item.field === field)

            if (field === 'value') this.localAddOrClearValueError(value, 'value', index, params)
            if (field === 'value_alt') this.localAddOrClearValueError(value_alt, 'value_alt', index, params)
        },

        localAddOrClearValueError (value, field, index, params) {
            if (value && value.length > 255) {
                if (index === -1) this.valueFieldsError.push(params)
                return this[field === 'value' ? 'valueFieldMaxLength' : 'valueAltFieldMaxLength'] = 255
            }

            if (index !== -1) this.valueFieldsError.splice(index, 1)
            this[field === 'value' ? 'valueFieldMaxLength' : 'valueAltFieldMaxLength'] = -1
        },

        localRemoveCondition (condition) {
            const { field_name, operator, value, value_alt } = condition
            if (field_name || operator || (_.trim(value) || (operator === 'between' && _.trim(value_alt)))) {
                this.removedConditions.push(condition)
                return this.localTriggerConditionChanges()
            }

            const index = this.conditionsList.indexOf(cond => cond.id === condition.id)
            this.conditionsList.splice(index, 1)
            this.localTriggerConditionChanges()
        },

        async localSaveCondition () {
            this.mixinSetLoading('update-conditions')
            await this.localTruncateValuesWithinLimit()
            this.localRemoveEmptyConditions()

            const list = this.localFilterOurValidConditions()
            await this.localBulkRemoveConditions()
            await this.localBulkAddConditions(list)
            this.conditionsListCopy = _.cloneDeep(this.conditionsList)
            this.removedConditions = []
            this.valueFieldsError = []
        },

        async localTruncateValuesWithinLimit () {
            this.conditionsList.forEach(condition => {
                condition.value = condition.value && condition.value.length > 255 ? condition.value.substring(0, 255) : condition.value
                condition.value_alt = condition.value_alt && condition.value_alt.length > 255 ? condition.value_alt.substring(0, 255) : condition.value_alt
            })
        },

        localRemoveEmptyConditions () {
            const emptyConditionIds = []
            this.conditionsList.forEach(item => {
                const { field_name, operator, value, value_alt } = item || {}
                if (item.type === 'new' && (!field_name && !operator && (!_.trim(value) || (!_.trim(value) && (operator === 'between' && !_.trim(value_alt)))))) {
                    emptyConditionIds.push(item.id)
                }
            })
            emptyConditionIds.forEach(id =>
                this.conditionsList.splice(this.conditionsList.findIndex(i => i.id === id), 1)
            )
        },

        localFilterOurValidConditions () {
            return this.conditionsList.filter(item => {
                const index = this.removedConditions.findIndex(c => c.id === item.id)
                return (index === -1 && this.localGetConditionStatus(item) !== 'valid') ? item : null
            })
        },

        async localBulkRemoveConditions () {
            const newConditionsRemoved = this.removedConditions.filter(cond => (cond.type && cond.type === 'new'))
            if (newConditionsRemoved.length) this.localRemoveNewConditions(newConditionsRemoved)

            const removedCondIds = this.removedConditions.filter(cond => (!cond.type || cond.type !== 'new')).map(cond => cond.id)
            if (!removedCondIds.length) return

            this.localRemoveConditionsFromList(removedCondIds)
            this.$emit('destroy', { ids: removedCondIds })
        },

        localRemoveNewConditions (newConditionsRemoved) {
            this.localRemoveConditionsFromList(_.map(newConditionsRemoved, 'id'))
            this.$emit('updateLists', this.conditionsList)
        },

        async localBulkAddConditions (list) {
            if (!list.length) return this.localEmitSaveLoadingReset()

            const filteredList = list.filter(item => this.localGetConditionStatus(item, 'label') !== 'valid')
            if (!filteredList.length) return this.localEmitSaveLoadingReset()

            this.$emit('upsert', { data: filteredList, workflow_id: this.localWorkflowId, resetLoading: this.localResetSaveLoading })
        },

        async localClearDeletedFeilds(){
            this.$emit('clearField')
        },

        localGetConditionStatus (condition, field = 'icon') {
            const isNullable = (condition.operator === 'null' || condition.operator === 'not_null')
            const isDeleted = this.localIndexOfDeletedCond(condition) > -1
            const isModified = this.localCheckConditionModified(condition)
            const statusTypes = this.localInputTypes
            let status = {}
            if (condition.type === 'new' && !isDeleted) status = statusTypes['new']
            else if (isModified && !isDeleted) status = statusTypes['modified']
            else if (isDeleted) status = statusTypes['deleted']
            else if (isNullable) status = statusTypes['isnullable']
            else if (condition.is_value_deleted) status = statusTypes['isValueDeleted']
            else if (condition.is_valid) status = statusTypes['valid']
            else if (condition.hasOwnProperty('is_valid') && !condition.is_valid) {
                status = { ...statusTypes['invalid'], message: (condition.error && condition.error.message) ? condition.error.message : 'Not Valid!' }
            }
            return status[field]
        },

        localCheckConditionModified (condition) {
            const { field_name, operator, value, value_alt } = condition || {}
            const oldCond = this.conditionsListCopy.find(c => c.id === condition.id)
            if (!oldCond) return false

            if (oldCond.field_name !== field_name) return true
            if (oldCond.operator !== operator) return true
            if (oldCond.value !== value) return true
            if (oldCond.operator === 'between' && oldCond.value_alt !== value_alt) return true
            return false
        },

        /* Extras */
        localGetValueError (condition, field) {
            const index = this.valueFieldsError.findIndex(item => item.id === condition.id && item.field === field)
            return index !== -1 ? this.valueFieldsError[index].message : null
        },

        localResetSaveLoading () {
            this.mixinResetLoading('update-conditions')
        },

        localEmitSaveLoadingReset () {
            this.$emit('resetLoading', this.localResetSaveLoading)
        },

        localFormatDate(date) {
            return date ? moment(date).format('MMM D, YYYY') : null
        },

        localRemoveConditionsFromList (removedCondIds) {
            removedCondIds.forEach(id => {
                const index = this.conditionsList.findIndex(c => c.id === id)
                if (index !== -1) this.conditionsList.splice(index, 1)
            })
        },

        localTriggerCancelChanges () {
            this.$emit('cancelChanges', { clearChanges: this.localCancelChanges })
        },

        localCancelChanges () {
            this.valueFieldsError = []
            this.removedConditions = []
            this.conditionsList = _.cloneDeep(this.conditionsListCopy)
        },

        localUndoDelete (condition) {
            const index = this.localIndexOfDeletedCond(condition)
            this.removedConditions.splice(index, 1)
        },

        localIsConditionDeleted (condition) {
            const index = this.localIndexOfDeletedCond(condition)
            return index !== -1
        },

        localGetValidationError (item, field) {
            if (!item.error || !field) return null
            if (item.error[field] && item.error[field][0] === 'Required field.' && (item.operator === 'null' || item.operator === 'not_null')) {
                return null
            }
            return item.error[field] && item.error[field][0]
        },

        localIndexOfDeletedCond (condition) {
            const index = this.removedConditions.findIndex(c => c.id === condition.id)
            return index
        },

        localAccordionDateToggle (index, type = null) {
            if(type === 'start_date') {
                if (this.accordionStartDate[index] === undefined || this.accordionStartDate[index] === null) this.$set(this.accordionStartDate, index, true);
                else this.$set(this.accordionStartDate, index, !this.accordionStartDate[index]);
            }
            if(type === 'end_date') {
                if (this.accordionEndDate[index] === undefined || this.accordionEndDate[index] === null) this.$set(this.accordionEndDate, index, true);
                else this.$set(this.accordionEndDate, index, !this.accordionEndDate[index]);
            }
        },

        localCanUpdate(condition) {
            return this.localFieldIsDisabled || this.localGetConditionStatus(condition, 'label') === 'deleted'
        },

        localClearDeletedFields() {
            this.$emit('clearFields')
            this.showDeleted = !this.showDeleted
        },

        localCheckDeletedError(condition) {
            return this.localGetPlaceholder(condition) !== 'Value' ? true : false
        },

        localGetPlaceholder(condition) {
            const str = condition.field_name.split('.')
            let ind = 0, deletedValue = ''
            if (str[0] === 'custom_field' && condition.field_type === 'picklist' && condition.is_value_deleted === 1){
                const lists = this.picklists(condition.field_name, 'picklistValues')
                deletedValue = condition.recently_deleted_label
                ind = lists.findIndex(item => item.name === condition.value)
            }
            const conditionModified = this.localCheckConditionModified(condition)
            return ind === -1 && !conditionModified? `${deletedValue} (Deleted)` : 'Value'
        },

        localCheckError(condition, field) {
            return this.localGetValidationError(condition, 'value') || this.localGetValueError(condition, 'value') || this.localCheckDeletedError(condition)
        }
    }
}
</script>

<style lang="scss" scoped></style>

<template>
    <div>
        <div class="justify-center mt-3 text-center md-body-2 u-flex align-center" v-if="permission_document('store')">
            <a-btn small text color="primary lighten-1" @click="local_template_import_form()" v-if="$can('templates.index') && $can('projects.update-only')">
                <a-icon size="20" left class="mr-2">move_to_inbox</a-icon>
                Import from template
            </a-btn>
            <div class="ml-1" v-if="permission_document('store')">
                <a-btn small text color="primary lighten-1" @click="local_initiate()" :disabled="loading_file">
                    <a-icon size="20" left class="mr-2">cloud_upload</a-icon> Upload Document
                </a-btn>
                <input type="file" ref="doc_upload" name="doc_upload" class="d-none" multiple @change="local_doc_upload()" :accept="local_file_types">
            </div>
        </div>

        <a-responsive class="my-4" v-if="page_loading">
            <h3 class="mb-1 text-center md-subtitle-1 font-weight-bold grey--text text--darken-1 text-capitalize">Fetching Documents..</h3>
        </a-responsive>


        <template v-if="!page_loading">
            <a-responsive class="my-4" v-if="!document_list.length && (!uploadDocsList.length)">
                <h3 class="mb-1 text-center md-subtitle-1 font-weight-bold grey--text text--darken-1">No Documents Found</h3>
                <p class="mb-0 text-center md-body-2 grey--text">Add your project specific documents to share within your team or with customer.</p>
            </a-responsive>
            <div v-if="uploadDocsList.length" class="px-0 mb-8">
                <h2 class="mb-3 md-subtitle-1 grey--text text--darken-1">Uploading {{ uploadDocsList.length }} {{ uploadDocsList.length > 1 ? 'files' : 'file' }}</h2>
                <a-card v-for="doc in uploadDocsList" flat class="mb-2 u-rounded-corners u-border" :key="doc.last_modified">
                    <a-container container--fluid grid-list-xl class="px-2 py-3">
                        <a-layout align-center py-2>
                            <a-flex py-0 shrink>
                                <img src="../../assets/images/filetype-icons/unknown.svg" width="48" alt="Unkown Filetype" style="display: block;">
                                <!-- <img v-if="doc.type" :src="require('../../assets/images/filetype-icons/'+(doc.type.split('/')[1].length > 5 ? 'xlsx' : doc.type.split('/')[1])+'.svg')" width="48" :alt="doc.type.split('/')[1]" style="display: block;"> -->
                            </a-flex>
                            <a-flex py-0>
                                <h2 class="md-subtitle-1 primary--text d-block text-no-wrap text-truncate" style="max-width: 360px; line-height: 1.5 !important;">{{ doc.name }}</h2>
                                <p class="mb-0 md-body-2 grey--text text--darken-2">Size: {{ doc.size | sizer }}</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-progress-linear class="u-rounded-corners" indeterminate height="1" color="teal"></a-progress-linear>
                </a-card>
            </div>
            <div v-if="document_list && document_list.length > 0 && $can('documents.index')">
                <div class="u-flex-center-y">
                    <h4 class="md-subtitle-1 grey--text text--darken-1">Files ({{ document_list.length }})</h4>
                    <a-spacer></a-spacer>
                    <a-btn small text color="primary lighten-1" class="ma-0" @click="local_template_document_export_form()" v-if="$can('templates.store')">
                        <a-icon size="20" left class="mr-2">save</a-icon>
                        Export as Template
                    </a-btn>
                </div>
                <draggable v-model="document_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                    <div v-for="document in document_list" :key="document.id" :class="{ 'js-draggable-file-list-item': permission_document('update')}">
                        <a-card :class="{'yellow lighten-4': document.visibility === 'external'}" flat class="my-3 u-cursor-pointer u-border">
                            <a-card-text class="pa-0">
                                <a-container container--fluid grid-list-xl class="px-6 py-3">
                                    <a-layout align-center>
                                        <a-flex shrink class="px-0" v-if="user_self && user_self.scope === 'internal'" :class="{'u-opacity-30': !permission_document('update')}">
                                            <a-icon class="grey--text text--lighten-1 js-drag-handle">drag_indicator</a-icon>
                                        </a-flex>
                                        <a-flex shrink class="px-0">
                                            <img src="../../assets/images/filetype-icons/unknown.svg" width="48" alt="Unkown Filetype" style="display: block;" v-if="document.extension === null">
                                            <img :src="require('../../assets/images/filetype-icons/'+document.extension+'.svg')" width="48" :alt="document.extension" style="display: block;" v-else>
                                        </a-flex>
                                        <a-flex class="text-truncate">
                                            <h2
                                                v-if="document.title"
                                                :title="`${document.title} (${document.name})`"
                                                class="md-subtitle-1 primary--text text-no-wrap text-truncate"
                                                style="line-height: 1.5 !important;"
                                            >
                                                {{ document.title }}
                                                <span class="md-body-2 grey--text text--darken-2">({{ document.name }})</span>
                                            </h2>
                                            <h2 class="mb-2 md-subtitle-1 primary--text d-block text-no-wrap text-truncate" :title="document.name" style="line-height: 1.5 !important; max-width: 600px;" v-else>{{ document.name }}</h2>
                                            <p class="mb-0 md-body-2 grey--text text--darken-2">Size: {{ document.size | sizer }} <span class="mx-2" v-if="user_self && user_self.scope === 'internal'">&bull;</span> <span v-if="document.created_by && user_self && user_self.scope === 'internal'">Uploaded by {{ document.created_by.name }}</span></p>
                                        </a-flex>
                                        <a-flex shrink class="u-flex-center-y" style="column-gap: 4px;">
                                            <a-tooltip bottom v-if="$can('documents.show')">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn text icon color="grey" class="mx-0" v-on="on" :disabled="loading" :loading="local_loading_get(document.id, 'download')" @click="local_document_download(document.id)">
                                                        <a-icon size="16">cloud_download</a-icon>
                                                    </a-btn>
                                                </template>
                                                <span>Download</span>
                                            </a-tooltip>
                                            <g-confirmation class="d-inline-block" v-if="document.visibility == 'internal'" :can-update="permission_document('update')" type="document" @action="permission_document('update') ? local_document_visibility_toggle(document.id, document.visibility) : ''">
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <a-btn text icon color="grey" class="mx-0" v-on="on" :disabled="loading" :loading="local_loading_get(document.id, 'visibility')">
                                                            <a-icon size="16">lock</a-icon>
                                                        </a-btn>
                                                    </template>
                                                    <span>Not Visible to Client</span>
                                                </a-tooltip>
                                            </g-confirmation>
                                            <a-tooltip bottom v-else>
                                                <template v-slot:activator="{ on }">
                                                    <a-btn text icon color="grey" class="mx-0" v-on="on" v-if="!permission_document('update')">
                                                        <a-icon size="16">lock_open</a-icon>
                                                    </a-btn>
                                                    <a-btn text icon color="grey" class="mx-0" v-on="on" v-if="permission_document('update')" :disabled="loading" :loading="local_loading_get(document.id, 'visibility')" @click="permission_document('update') ? local_document_visibility_toggle(document.id, document.visibility) : ''">
                                                        <a-icon size="16">lock_open</a-icon>
                                                    </a-btn>
                                                </template>
                                                <span>Visible to Client</span>
                                            </a-tooltip>
                                            <a-tooltip bottom v-if="permission_document('update')">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn icon text small color="grey" class="mx-0" v-on="on" :disabled="loading" @click="local_document_edit(document.id)">
                                                        <a-icon size="16">edit</a-icon>
                                                    </a-btn>
                                                </template>
                                                <span>Edit</span>
                                            </a-tooltip>
                                            <template v-if="permission_document('destroy')">
                                                <a-btn icon text small color="grey" v-if="!local_is_selected(document.id)" class="mx-0" @click="local_add_item_to_delete(document.id)">
                                                    <a-icon size="16">delete</a-icon>
                                                </a-btn>
                                                <a-tooltip bottom v-else>
                                                    <template v-slot:activator="{ on }">
                                                        <a-btn icon text small color="red" class="mx-0" v-on="on" :disabled="loading" :loading="local_loading_get(document.id, 'destroy')" @click="local_document_destroy(document.id)">
                                                            <a-icon size="16">delete</a-icon>
                                                        </a-btn>
                                                    </template>
                                                    <span>Confirm?</span>
                                                </a-tooltip>
                                            </template>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-card-text>
                        </a-card>
                    </div>
                </draggable>
            </div>
        </template>

        <a-dialog v-model="dialog_document_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>description</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Edit Document Information</h2>
                                    <p class="mb-0 md-subtitle-2">Edit information related to document.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Filename</label>
                                    <a-text-field
                                        v-model="document_item.name"
                                        placeholder="Document Filename"
                                        background-color="neutral"
                                        solo flat hide-details readonly
                                    >
                                    </a-text-field>
                                    <span class="mt-2 md-caption red--text text--darken-2 d-block" v-if="document_response.server && document_response.server.errors && document_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ document_response.server.errors.name[0] }}
                                    </span>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block" v-else>
                                        <a-icon size="16">info</a-icon>
                                        This is the original filename of the document.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Title (Optional)</label>
                                    <a-text-field
                                        v-model="document_item.title"
                                        placeholder="Enter Document Title"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="mt-2 md-caption red--text text--darken-2 d-block" v-if="document_response.server && document_response.server.errors && document_response.server.errors.title">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ document_response.server.errors.title[0] }}
                                    </span>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block" v-else>
                                        <a-icon size="16">info</a-icon>
                                        This will help you identify the document.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="px-3 ma-0 elevation-0" @click="local_document_update()" :loading="loading" :disabled="loading">Save Document</a-btn>
                                    <a-btn class="mt-0 mb-0 ml-4 mr-0" color="grey" text @click="local_document_form_cancel()" :disabled="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog v-model="dialog_import_form" max-width="800">
            <a-card>
                <a-container container--fluid grid-list-xl class="pa-6">
                    <a-layout wrap align-center>
                        <a-flex shrink class="pr-1">
                            <a-avatar class="primary darken-1" size="40">
                                <a-icon size="18" dark>system_update_alt</a-icon>
                            </a-avatar>
                        </a-flex>
                        <a-flex>
                            <h2 class="md-heading-6 grey--text text--darken-3">Import Documents from Template</h2>
                            <p class="mb-0 md-subtitle-2 grey--text text--darken-1">You can import multiple document into project.</p>
                        </a-flex>
                        <a-flex shrink>
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a-btn small text icon color="grey"  @click="dialog_import_form = !dialog_import_form" class="px-3 mx-0 mt-0 mb-0 mr-0 pa-0 right" v-on="on">
                                        <a-icon size="24">clear</a-icon>
                                    </a-btn>
                                </template>
                                <span>Close</span>
                            </a-tooltip>
                        </a-flex>
                    </a-layout>
                </a-container>
                <a-divider></a-divider>
                <a-card-text class="pa-0">
                    <template v-if="!local_import_template_group">
                        <a-responsive class="py-4" v-if="!loading">
                            <h3 class="mb-1 text-center md-subtitle-1 font-weight-bold grey--text text--darken-1 text-uppercase">No Template Found</h3>
                            <p class="mb-0 text-center md-body-2 grey--text">There are no document templates present in your Organization<br></p>
                            <p class="mb-0 text-center md-body-2 grey--text"><a-icon size="22" color="orange">emoji_objects</a-icon>You can create new template manually under Settings or export documents from existing project.</p>
                        </a-responsive>
                    </template>
                    <template v-else>
                        <template v-if="$can('documents.index')">
                            <div class="u-rounded-corners" v-if="local_import_template_group.documents && local_import_template_group.documents.length">
                                <a-sheet class="u-rounded-corners" flat v-for="(group, index) in local_import_template_group.documents" :key="group.id">
                                    <a-divider v-if="index !== 0"></a-divider>
                                    <div class="px-6 u-flex-center-y pa-4">
                                        <h3 class="md-body-2">
                                            {{ group.name }} <span class="md-body-2 grey--text text--darken-1">{{ group.template_documents_count === 1 ? `${group.template_documents_count} Document` : `${group.template_documents_count} Documents` }}</span>
                                        </h3>
                                        <a-spacer></a-spacer>
                                        <template v-if="$can('documents.update')">
                                            <a-btn color="primary lighten-1" class="elevation-0" @click="local_template_document_import(group.id)" :disabled="!!currentImportId || group.template_documents_count == 0 || loading" :loading="currentImportId === group.id" small>Import</a-btn>
                                        </template>
                                    </div>
                                </a-sheet>
                            </div>
                            <template v-else>
                                <a-responsive class="py-1" v-if="!loading">
                                    <h3 class="text-center md-subtitle-1 font-weight-bold grey--text text--darken-1">No Document Templates Found</h3>
                                    <p class="mb-0 text-center md-body-2 grey--text">There is no documents template present in your Organization<br></p>
                                    <p class="mb-0 text-center md-body-2 grey--text"><a-icon size="22" color="orange">emoji_objects</a-icon>You can create new template manually under Settings or export documents from existing projects.</p>
                                </a-responsive>
                            </template>
                        </template>
                    </template>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-dialog v-model="dialog_export_document_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>insert_drive_file</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Export Documents as Template</h2>
                                    <p class="mb-0 md-subtitle-2">You can save list of documents to import them in new projects.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <v-radio-group v-model="export_mode" row class="mt-0" hide-details @change="local_export_mode_change">
                                        <v-radio label="New Template" value="new"></v-radio>
                                        <v-radio label="Existing Template" value="existing" :disabled="!$can('templates.update') || !$can('templates.index')"></v-radio>
                                    </v-radio-group>
                                </a-flex>
                                <a-flex xs12 v-if="export_mode === 'new'">
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Group Name</label>
                                    <a-text-field
                                        v-model="template_document_item.name"
                                        placeholder="Template Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="mt-2 md-caption red--text text--darken-2 d-block" v-if="template_group_response.server && template_group_response.server.errors && template_group_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_group_response.server.errors.name[0] }}
                                    </span>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the user.
                                    </span>
                                </a-flex>
                                <a-flex xs12 v-if="export_mode === 'existing'">
                                    <label class="mt-0 mb-2 md-body-2 d-block font-weight-medium">Group</label>
                                    <a-autocomplete
                                        v-model="template_document_item.id"
                                        :items="template_group_list"
                                        item-text="name"
                                        item-value="id"
                                        placeholder="Template Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-autocomplete>
                                    <span class="mt-2 md-caption red--text text--darken-2 d-block" v-if="template_document_response.server && template_document_response.server.errors && template_document_response.server.errors.template_group_id">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_document_response.server.errors.template_group_id[0] }}
                                    </span>
                                    <span class="mt-2 md-caption grey--text text--darken-2 d-block" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the user.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-4">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="px-3 ma-0 elevation-0" @click="local_template_document_export()" :loading="loading" :disabled="loading">Save Template</a-btn>
                                    <a-btn class="mt-0 mb-0 ml-4 mr-0" color="grey" text @click="dialog_export_document_form = !dialog_export_document_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog max-width="480px" v-model="dialog_download_link" persistent>
            <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden" flat>
                <a-progress-linear class="my-0" height="3" color="orange darken-3" :indeterminate="true" v-if="loading"></a-progress-linear>
                <a-card-text class="pa-2">
                    <a-container container--fluid grid-list-xl class="pa-4">
                        <a-layout align-start>
                            <a-flex shrink>
                                <a-icon size="72" color="grey lighten-1">cloud_download</a-icon>
                            </a-flex>
                            <a-flex>
                                <h2 class="mb-2 md-heading-6">
                                    <span v-if="loading">Preparing file...</span>
                                    <span v-else>Ready to Download</span>
                                </h2>
                                <p class="mb-0 md-body-2 grey--text text--darken-1">
                                    <span v-if="loading">Success is preparing your file for download. It may take few seconds to get ready.</span>
                                    <span v-else>Your file is ready to download. Click on the "Save File" button to download the report.</span>
                                </p>
                                <a-btn :href="document_links.download" target="_blank" small class="px-3 mt-3 elevation-0 ma-0" color="primary" :disabled="loading" @click="local_document_download_save()" download>
                                    <a-icon size="14" class="mr-2">save</a-icon>
                                    Save File
                                </a-btn>
                                <a-btn class="px-3 mt-3 elevation-0 ma-0" color="grey" text @click="dialog_download_link = !dialog_download_link" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-snackbar v-model="snackbar_status" :timeout="5000" bottom center>
            {{snackbar_text}} Imported Successfully!
        </a-snackbar>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import TemplateImportStatus from '@/mixins/mixin-template-import-status'

export default {
    mixins: [TemplateImportStatus],
    data () {
        return {
            currentImportId: null,
            export_mode: 'new',
            dialog_document_form: false,
            dialog_import_form: false,
            dialog_export_document_form: false,
            dialog_download_link: false,
            loading_item: null,
            loading_action: null,
            data_template_group_list: [],
            delete_items_list: [],
            snackbar_status: false,
            snackbar_text: '',
            page_loading: true,
            form_add_mode: 'upload',
            loading_file: false,
            uploadDocsList: [],
            local_doc_filters: { 'include': 'createdBy', 'filter[project_id]': this.$route.params.id, 'sort': 'order' },
            local_file_types: '.jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx,.csv,.ppt,.pptx,.txt,.zip',
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        document_list: {
            get() {
                return this.$store.state.Document.list
            },
            set(list) {
                this.local_document_reorder(list)
            }
        },
        local_import_template_group() {
            let group = _.groupBy(this.data_template_group_list, 'type')
            return _.isEmpty(group) ? false : group
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('TemplateGroup', {
            template_group_list: 'list',
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),
        ...mapState('TemplateDocument', {
            template_document_item: 'item',
            template_document_response: 'response',
        }),
        ...mapState('Document', {
            document_item: 'item',
            document_filters: 'filters',
            document_links: 'links',
            document_response: 'response',
            document_form_mode: 'form_mode',
        }),
        ...mapState('ProjectView', {
            project_item: 'item',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_index() {
            if (!this.$can('documents.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.$vuetify.goTo(0)
            await this.document_clear()

            if (this.$can('documents.index')) await this.local_fetch_document()
            this.page_loading = false

            if(!this.project_item) await this.project_show({id: this.$route.params.id})
        },
        async local_fetch_document() {
            await this.document_index(this.local_doc_filters)
        },
        async local_document_fetch() {
            await this.document_clear()
            this.document_filters.page = 0;
            this.document_filters.project_id = this.$route.params.id
            this.document_item.project_id = this.$route.params.id
            await this.document_index(this.local_doc_filters)
        },
        async local_edit(id) {
            this.dialog_link_form = true
            await this.link_select({id: id})
        },
        async local_document_edit(id) {
            this.dialog_document_form = true
            await this.document_select({id: id})
        },
        async local_document_update() {
            await this.document_update(this.document_item)
            if (this.document_response.status === 'success') {
                this.dialog_document_form = false
                await this.document_clear_item()
            }
        },
        async local_document_form_cancel() {
            this.dialog_document_form = false
            await this.document_clear_item()
        },
        async local_destroy(id) {
            await this.local_loading_set(id, 'destroy')
            await this.link_destroy({id: id})
            await this.local_loading_clear()
        },
        local_add_item_to_delete (id) {
            this.delete_items_list.push(id)
            setTimeout(() => this.local_remove_delete_item(id), 3000)
        },
        local_remove_delete_item (id) {
            this.delete_items_list.splice(this.delete_items_list.indexOf(id))
        },
        local_is_selected (id) {
            return this.delete_items_list.includes(id)
        },
        async local_document_destroy(id) {
            await this.local_loading_set(id, 'destroy')
            this.local_remove_delete_item(id)
            await this.document_destroy({id: id})
            await this.local_loading_clear()
        },
        async local_document_visibility_toggle(id, status) {
            await this.local_loading_set(id, 'visibility')
            await this.document_select({id: id})
            this.document_item.visibility = status === 'external' ? 'internal' : 'external'
            await this.document_visibility(this.document_item)
            await this.local_loading_clear()
            if (this.template_document_response.status === 'success') {
                this.document_clear_item()
            }
        },
        async local_document_reorder(list) {
            await this.document_reorder({list: list})
        },
        async local_document_download(id) {
            this.dialog_download_link = true
            await this.document_download({id: id})
        },
        local_document_download_save() {
            this.dialog_download_link = false
        },
        local_initiate() {
            this.$refs.doc_upload.value = null
            this.$refs.doc_upload.click()
        },
        async local_doc_upload() {
            const fileList = Array.from(this.$refs.doc_upload.files)
            if (fileList && fileList.length > 0) {
                this.loading_file = true
                this.uploadDocsList = [...fileList]
                fileList.forEach(async item => {
                    await this.document_store(this.local_upload_action(item))
                    if (this.document_response.status == 'success') this.uploadDocsList.splice(this.uploadDocsList.findIndex(file => file.name === item.name), 1)
                    if (!this.uploadDocsList.length) this.loading_file = false
                })
            }
        },
        local_upload_action(item) {
            const form_data = new FormData()
            form_data.append('file', item)
            form_data.append('project_id', this.$route.params.id)
            form_data.append('order', -1)
            form_data.append('id', uuidv4())
            return form_data
        },
        async local_template_import_form() {
            if (this.$can('templates.index') == true) {
                await this.template_group_clear()
                await this.template_group_index( { 'include': 'templateDocumentsCount', 'filter[type]': 'documents', 'sort': 'order'})
                this.data_template_group_list = _.cloneDeep(this.template_group_list)
                this.local_import_template_group
            }
            this.dialog_import_form = !this.dialog_import_form
        },
        local_export_mode_change () {
            this.template_group_clear_item()
            if (this.dialog_export_document_form) return this.template_document_clear_item()
            this.template_link_clear_item()
        },
        async local_template_document_export_form() {
            if (this.$can('templates.index') === true) {
                await this.template_group_clear()
                await this.template_document_clear()
                this.template_group_index( { 'filter[type]': 'documents', 'sort': 'order'} )
            }
            this.dialog_export_document_form = !this.dialog_export_document_form
            this.template_document_item.name = null
            this.template_document_item.id = null
            this.export_mode = 'new'
        },
        async local_template_document_export() {
            if(this.export_mode === 'new')return this.local_document_template_group_store()
            this.local_document_template_export_action(this.template_document_item.id)
        },
        async local_document_template_group_store() {
            await this.template_group_store({ name: this.template_document_item.name, type: 'documents' })
            if (!this.$status(this.template_group_response))  return
            this.local_document_template_export_action(this.template_group_item.id)
        },
        async local_document_template_export_action(group_id) {
            const document_ids = this.document_list.map(item => item.id);
            const document_template_export_tem = {}
            document_template_export_tem.data = document_ids
            document_template_export_tem.template_group_id = group_id
            await this.template_document_export(document_template_export_tem)
            if (!this.$status(this.template_document_response))  return
            this.dialog_export_document_form = false
            this.$notify('success', 'Documents exported successfully!')
        },
        async local_template_document_import(id) {
            this.currentImportId = id
            await this.document_template_import({project_id: this.$route.params.id, template_group_id: id})
            if (this.document_response && this.document_response.status === 'success') {
                // await this.document_clear()
                this.document_filters.page = 0;
                this.document_filters.project_id = this.$route.params.id
                await this.document_clear()
                await this.document_index(this.local_doc_filters)
                this.currentImportId = null
                this.$notify('success', 'Documents imported successfully!')
            }
        },
        async local_loading_set(id, action) {
            this.loading_item = id
            this.loading_action = action
        },
        async local_loading_clear() {
            this.loading_item = null
            this.loading_action = null
        },
        local_loading_get(id, action) {
            return this.loading && this.loading_item == id && this.loading_action == action
        },
        permission_document(type, client_allowed = false) {
            if (this.user_self.scope === 'external') {
                return client_allowed
            }
            return this.$can('projects.update-only') && this.$can(`documents.${type}`) && this.user_self.scope === 'internal'
        },

        ...mapActions('ProjectView', {
            project_show: 'show',
        }),
        ...mapActions('Document', {
            document_index: 'index',
            document_show: 'show',
            document_store: 'doc_upload',
            document_visibility: 'visibility',
            document_update: 'update',
            document_select: 'select',
            document_download: 'download',
            document_destroy: 'destroy',
            document_reorder: 'reorder',
            document_template_import: 'template_import',
            document_clear: 'clear',
            document_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_store: 'store',
            template_group_clear: 'clear',
            template_group_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateDocument', {
            template_document_export: 'template_export',
            template_document_clear: 'clear',
            template_document_clear_item: 'clear_item',
        }),
    }
}
</script>

<style>
.dropzone-custom-content {
    padding: 2rem;
    border: 2px dashed #c1c1c1 !important;
    border-radius: .25rem;
    cursor: pointer;
}
.dz-preview {
    background-color: #fff;
    border-radius: .25rem;

    margin-top: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.06), 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.06) !important;
}
.dz-preview .dz-details {
    padding: 1rem;
}
.dz-preview .dz-details .dz-size,
.dz-preview .dz-details .dz-filename {
    display: inline-block;
    font-size: .825rem;
}
.dz-preview .dz-image,
.dz-preview .dz-success-mark,
.dz-preview .dz-error-mark {
    display: none;
}
.dz-preview .dz-progress .dz-upload {
    display: block;
    background-color: #43a047;
    height: 2px;
}
.dz-preview.dz-success {
    display: none;
}
.dz-preview.dz-error .dz-progress {
    display: none;
}
.dz-preview.dz-error .dz-error-message {
    padding: 1rem;
    padding-top: 0;
    font-size: .825rem;
    color: red;
}
.dz-preview.dz-error .dz-details {
    padding-bottom: 0;
}
</style>

<template>
    <div :id="task.id" :class="{'js-draggable-file-list-item': canUpdate}">
        <template v-if="showCompleted || !showCompleted &&  (task && task.status && task.status.status !== 'Completed')">
            <a-card :class="{ 'light-blue lighten-4': isSelected, 'u-item-disabled': task && task.completed_at }" class="u-elevation-custom-1 pa-1 px-2 u-cursor-pointer" flat>
                <a-card-text class="pa-0" v-if="task">
                    <a-container container--fluid grid-list-xl class="py-2 px-5">
                        <a-layout align-center>
                            <!-- <a-flex shrink class="px-0" :class="{'u-opacity-30': !canUpdate}">
                                <a-icon class="grey--text text--lighten-1">drag_indicator</a-icon>
                            </a-flex> -->
                            <a-flex shrink class="px-0">
                                <slot name="status"></slot>
                            </a-flex>
                            <!-- <a-flex shrink class="px-0">
                                <a-btn icon class="ma-0" :disabled="itemLoading || !canUpdate" :loading="itemLoading" v-if="!task.completed_at" @click="$emit('toggle-complete', task.id, task.completed_at, task.milestone_id)">
                                    <a-icon size="24" color="grey darken-3">radio_button_unchecked</a-icon>
                                </a-btn>
                                <a-btn icon class="ma-0" :disabled="itemLoading || !canUpdate" :loading="itemLoading" v-else @click="$emit('toggle-complete', task.id, task.completed_at, task.milestone_id)">
                                    <a-icon size="24" color="teal lighten-1">check_circle</a-icon>
                                </a-btn>
                            </a-flex> -->
                            <a-flex shrink :class="[canUpdate ? dragClass : '', 'text-truncate u-wfull']" @click="$emit('select', task)">
                                <h2 class="md-subtitle-1 text-truncate" :title="task.title" :class="{ 'grey--text text--darken-2': !isSelected, 'primary--text text--darken-2': isSelected }">
                                    {{ task.title }}
                                </h2>
                            </a-flex>
                            <a-spacer></a-spacer>
                            <a-flex grow :class="[canUpdate ? dragClass : '']" v-if="task.start_date || task.due_date" @click="$emit('select', task)">
                                <div class="u-flex align-center">
                                    <template v-if="task.start_date">
                                        <g-moment class="md-caption grey--text text--darken-1" :value="task.start_date" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                        <span class="mx-1"> - </span>
                                    </template>
                                    <span class="mx-1 md-caption grey--text text--darken-1" v-if="!task.start_date && task.due_date"> Due on </span>
                                    <g-moment v-if="task.due_date" class="md-caption" :class="[local_due_date_passed(task.due_date, task) ? 'red--text text--darken-2': 'grey--text text--darken-1']" :value="task.due_date" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                    <span v-else class="md-caption grey--text text--darken-1">No Due Date</span>
                                </div>
                            </a-flex>
                            <a-flex :class="[canUpdate ? dragClass : '']" grow v-if="task.priority && task.priority !== null" @click="$emit('select', task)">
                                <a-chip
                                    small
                                    :class="{
                                        'red lighten-4 red--text text--darken-2': task.priority === 'high',
                                        'amber lighten-4 amber--text text--darken-4': task.priority === 'medium',
                                        'blue lighten-4 blue--text text--darken-2': task.priority === 'low' }"
                                >
                                    {{ (task.priority === 'high') ? 'High' : '' }}
                                    {{ (task.priority === 'medium') ? 'Medium' : '' }}
                                    {{ (task.priority === 'low') ? 'Low' : '' }}
                                </a-chip>
                            </a-flex>
                            <a-flex grow >
                                <g-attachment-count-info
                                    :count="task.attachments_count"
                                    fontSize="12"
                                    iconSize="14"
                                />
                            </a-flex>
                            <a-flex grow :class="[canUpdate ? dragClass : '']">
                                <slot name="assignees"></slot>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        task: {
            type: Object,
            required: true
        },
        canUpdate: {
            type: Boolean
        },
        showCompleted: {
            type: Boolean
        },
        isSelected: {
            type: Boolean
        },
        itemLoading: {
            type: Boolean
        },
        dragClass: {
            type: String,
            default: 'js-drag-handle'
        },
    },

    methods: {
        local_due_date_passed(date, task) {
            return moment(date).isBefore() && (task.status && task.status.status !== 'Completed')
        },
    }
}
</script>

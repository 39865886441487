<template>
    <div class="c-summary-tile u-relative u-hfull u-rounded-corners-xl">
        <loader-sc-task-summary v-if="mixLoading" />

        <template v-else>
            <template v-if="localIsEmpty || !mixHasPermission('usecases.index')">
                <partial-empty-state-summary
                    :canView="mixHasPermission('usecases.index')"
                    title="Scope"
                    icon="check_box"
                    modelSlug="success criteria"
                    iconSize="26"
                    class="pb-4"
                    iconOutlined
                />
            </template>

            <div v-else class="u-relative pa-5">
                <h3 class="u-font-14 u-font-weight-semibold u-tracking grey--text text--darken-1">Success Criteria</h3>
                <div class="u-flex-center-y mt-2">
                    <h2 class="u-font-20 u-font-weight-semibold u-tracking-tight grey--text text--darken-4">
                        {{ localGetPercentage }}% Resolved
                    </h2>
                    <a-spacer></a-spacer>
                    <span class="u-tracking u-font-14 grey--text text--darken-1 font-weight-medium">({{ localResolvedCount }} / {{ count.total }})</span>
                </div>

                <a-sheet height="8" class="c-chart-color u-rounded-corners-lg my-4 u-flex-center-y">
                    <a-sheet
                        v-for="unit in localGetChartProgress" :key="unit.slug"
                        :color="unit.color"
                        :width="unit.percentage + '%'"
                        :style="[{ borderRadius: `${unit.radius.tLeft}px ${unit.radius.tRight}px ${unit.radius.bRight}px ${unit.radius.bLeft}px` }]"
                        @mouseenter="localHoverProgress(unit)"
                        @mouseleave="hoveredProgressText = ''"
                        height="100%"
                        class="c-chart-color__child u-cursor-pointer"
                    />
                </a-sheet>

                <div class="u-flex-center-y" style="column-gap: 6px;">
                    <template v-if="hoveredProgressText === ''">
                        <a-icon size="16" color="grey">ads_click</a-icon>
                        <span class="u-font-14 font-italic u-tracking grey--text font-weight-medium">
                            Hover the chart for more details
                        </span>
                    </template>
                    <v-fade-transition leave-absolute hide-on-leave>
                        <span v-if="hoveredProgressText !== ''" class="u-font-14 font-italic u-tracking grey--text text--darken-2 font-weight-medium">
                            {{ hoveredProgressText }}
                        </span>
                    </v-fade-transition>
                </div>
            </div>
        </template>

        <partial-project-summary-actions
            v-if="mixHasPermission('usecases.index') && !mixLoading"
            :showExpand="false"
            @open="mixOpenInNewWindow('projects-usecases')"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import MixinModalStateInjection from '../Mixins/MixinModalStateInjection'
import PartialProjectSummaryActions from './PartialProjectSummaryActions.vue'
import PartialEmptyStateSummary from './PartialEmptyStateSummary.vue'
import LoaderScTaskSummary from '../Loaders/LoaderScTaskSummary.vue'

export default {
	components: {
        PartialProjectSummaryActions,
        PartialEmptyStateSummary,
        LoaderScTaskSummary
    },

    mixins: [MixinModalStateInjection],

    props: {
        noFetch: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            count: { total: 0, passed: 0, invalid: 0, failed: 0, open: 0 },
            colorPalette: { passed: '#43A047', failed: '#E53935', invalid: '#616161', open: '#BDBDBD' },
            hoveredProgressText: ''
        }
    },

    computed: {
        localIsEmpty () {
            const { total } = this.count || {}
            return total === 0
        },

        localResolvedCount () {
            const counts = _.cloneDeep(this.count)
            delete counts.total
            delete counts.open
            return _.values(counts).reduce((acc, c) => acc = acc + c, 0)
        },

        localGetPercentage () {
            const { total, passed, failed, invalid } = this.count
            if (total === 0 || !total) return 0

            return Number(((Number(passed + failed + invalid) / Number(total)) * 100).toFixed(1))
        },

        localChartPoints () {
            return [
                { slug: 'passed', percentage: (this.count.passed / this.count.total) * 100, count: this.count.passed, color: this.colorPalette.passed, radius: { tLeft: 0, tRight: 0, bRight: 0, bLeft: 0 } },
                { slug: 'failed', percentage: (this.count.failed / this.count.total) * 100, count: this.count.failed, color: this.colorPalette.failed, radius: { tLeft: 0, tRight: 0, bRight: 0, bLeft: 0 } },
                { slug: 'invalid', percentage: (this.count.invalid / this.count.total) * 100, count: this.count.invalid, color: this.colorPalette.invalid, radius: { tLeft: 0, tRight: 0, bRight: 0, bLeft: 0 } },
                { slug: 'open', percentage: (this.count.open / this.count.total) * 100, count: this.count.open, color: this.colorPalette.open, radius: { tLeft: 0, tRight: 0, bRight: 0, bLeft: 0 } },
            ]
        },

        localGetChartProgress () {
            const valuesWithPercent = this.localChartPoints.filter(i => i.percentage > 0)
            if (_.size(valuesWithPercent) === 1) valuesWithPercent[0].radius = { tLeft: 8, tRight: 8, bRight: 8, bLeft: 8 }
            if (_.size(valuesWithPercent) > 1) {
                valuesWithPercent[0].radius = { tLeft: 8, tRight: 0, bRight: 0, bLeft: 8 }
                valuesWithPercent[_.size(valuesWithPercent) - 1].radius = { tLeft: 0, tRight: 8, bRight: 8, bLeft: 0 }
            }

            return valuesWithPercent
        },
    },

    methods: {
        async localIndex () {
            if (!this.mixHasPermission('usecases.index')) return
            if (this.noFetch || this.mixLatestSyncTimeDiff() >= 5) return this.localNoFetchSetup()

            this.mixSetLoading()
            await this.localGetScStats()
            this.mixClearLoading()
        },

        localNoFetchSetup () {
            this.count = this.mixGetSavedSummary.successCriteriaStats
        },

        async localGetScStats () {
            const params = {
                include: 'successCriteriaOpenCount,successCriteriaPassedCount,successCriteriaInvalidCount,successCriteriaPassedCount,successCriteriaFailedCount',
                'fields[projects]': 'id,total_success_criteria,total_completed_success_criteria'
            }
            const { data, status } = await this.project_show({
                id: this.mixParentState.projectId,
                stateless: true,
                params
            })

            if (status !== 'success') return
            this.count = {
                total: data.total_success_criteria,
                passed: data.success_criteria_passed_count,
                invalid: data.success_criteria_invalid_count,
                open: data.success_criteria_open_count,
                failed: data.success_criteria_failed_count
            }
            this.mixSaveProject({ successCriteriaStats: this.count })
        },

        localHoverProgress (unit) {
            const status = unit.slug.charAt(0).toUpperCase() + unit.slug.slice(1)
            this.hoveredProgressText = `${Number((unit.percentage).toFixed(1))}% (${unit.count} of ${this.count.total}) marked as ${status}.`
        },

        ...mapActions('ProjectView', {
            project_show: 'show',
        }),
    }
}
</script>

<style lang="scss" scoped>
.c-chart-color {
    &__child {
        transition: 0.25s border ease-in-out, 0.25s border-radius ease-in-out, 0.25s box-shadow ease-in-out;
        &:hover, &__child::before {
            border: 2px solid #fff !important;
            border-radius: 8px !important;
            box-shadow: 0px 1px 2px 2px #b8b8b8 !important;
            transition: 0.25s border ease-in-out, 0.25s border-radius ease-in-out, 0.25s box-shadow ease-in-out;
        }
    }

}
</style>

<template>
    <div class="c-heading u-relative u-flex align-center" style="min-height: 40px">
        <!-- Block List Menu -->
        <block-list-menu v-if="$can('document_generator.update')" @addBlock="methodStoreReportBlock"></block-list-menu>

        <!-- Formatting Menu -->
        <div class="u-absolute" style="top: 50%; transform: translateY(-50%); left: -40px">
            <a-menu offset-x left :nudge-top="50" v-model="dataSideMenu" transition="slide-x-transition" :close-on-content-click="false" min-width="350" max-width="350" style="max-width: 24px !important;" content-class="u-border c-radius u-elevation-custom-1">
                <template v-slot:activator="{ on }">
                    <a v-on="on" v-ripple="{ class: 'info--text' }" style="padding: 0 4px 0 4px;" class="c-component__menu u-flex align-center u-rounded-corners list-group-item js-drag-handle" :class="[ dataSideMenu ? 'is-active' : '' ]"><a-icon class="c-component__drag" size="26">drag_indicator</a-icon></a>
                </template>
                <div class="c-radius u-elevation-custom-1 py-3 px-4 white" style="max-width: 350px;">
                    <div class="u-flex align-center">
                        <h2 class="grey--text text--darken-3 font-weight-medium title font-weight-bold" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.title }}</h2>
                        <a-spacer></a-spacer>
                        <a-btn small icon text color="red darken-1 ma-0" class="ma-0" v-if="$can('document_generator.destroy')" @click="methodRemoveComponent">
                            <svg width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-trash red--text text--darken-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a-btn>
                    </div>
                    <p class="grey--text text--darken-1 mb-0 body-2 font-weight-medium" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.description }}</p>
                    <template v-if="$can('document_generator.update')">
                        <a-divider class="grey lighten-3 mt-3 mb-2"></a-divider>
                        <div class="mt-4 mb-2">
                            <template v-for="defBlockAttr in dataGetBlockInfo.attributes">
                                <div :key="defBlockAttr.id">
                                    <template v-if="defBlockAttr.title === 'Heading Level'">
                                        <div class="grey--text text--darken-1 text-uppercase body-2 mb-2 mt-2 font-weight-bold" style="letter-spacing: 1px;">{{ defBlockAttr.title }}</div>
                                        <a-menu class="d-block" offset-y bottom transition="slide-y-transition" content-class="c-radius u-border u-elevation-custom-1" :class="{ 'active': methodBlockAttrs(clonedHeading.id, defBlockAttr.id, defBlockAttr.title) }">
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on" class="pa-2 c-radius grey lighten-3 u-flex align-center u-wfull u-cursor-pointer">
                                                    <span class="u-flex align-center u-rounded-corners" v-if="currentHeadingLevel && currentHeadingLevel.value">
                                                        <span class="text-uppercase font-weight-bold grey--text text--darken-3 mr-2 body-1 u-rounded-corners grey lighten-2 pa-1 px-2">{{ currentHeadingLevel.value.value }}</span>
                                                        <a-spacer></a-spacer>
                                                        {{ currentHeadingLevel.value.label }}
                                                    </span>
                                                    <a-spacer></a-spacer>
                                                    <a-icon color="grey darken-2" size="24">arrow_drop_down</a-icon>
                                                </div>
                                            </template>
                                            <a-list class="pa-0 py-3">
                                                <a-list-item v-for="(menu, index) in defBlockAttr.options" :key="menu.level" class="u-wfull px-4" :class="[defBlockAttr.options.length - 1 !== index ? 'mb-2' : '' ]" @click="methodFormatText(menu, false, defBlockAttr.id, methodBlockAttrs(clonedHeading.id, defBlockAttr.id).id)">
                                                    <a-list-item-action class="c-heading-levels-menu ma-0 mr-3 py-1 u-flex align-center justify-center grey lighten-3 c-radius" style="min-width: 50px !important; min-height: 38px !important;">
                                                        <component :is="menu.value" class="grey--text text--darken-3 text-uppercase">{{ menu.value }}</component>
                                                    </a-list-item-action>
                                                    <a-list-item-content>
                                                        <a-list-item-title class="grey--text text--darken-2">{{ menu.label }}</a-list-item-title>
                                                    </a-list-item-content>
                                                </a-list-item>
                                            </a-list>
                                        </a-menu>
                                    </template>
                                    <template v-if="defBlockAttr.title === 'Alignment'">
                                        <div class="grey--text text--darken-1 text-uppercase body-2 mb-2 mt-4 font-weight-bold" style="letter-spacing: 1px;">{{ defBlockAttr.title }}</div>
                                        <a-layout align-center wrap>
                                            <template v-for="(menu) in defBlockAttr.options">
                                                <a-flex xs3 class="pa-0 mb-2" v-if="menu.value" :key="menu.id">
                                                    <a-btn small text :class="[methodBlockAttrs(clonedHeading.id, defBlockAttr.id, defBlockAttr.title) && methodBlockAttrs(clonedHeading.id, defBlockAttr.id, defBlockAttr.title).value.value === menu.value ? 'grey lighten-2' : '']" @click="methodFormatText(menu, true, defBlockAttr.id, methodBlockAttrs(clonedHeading.id, defBlockAttr.id).id)" class="u-border c-radius ma-0 mx-auto" style="min-height: 40px !important; min-width: 60px !important; max-width: 60px !important;">
                                                        <a-icon size="24" color="grey darken-2">{{ 'format_align_' + menu.value }}</a-icon>
                                                    </a-btn>
                                                </a-flex>
                                            </template>
                                        </a-layout>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </a-menu>
        </div>

        <!-- Block -->
        <div class="c-heading-editor-wrapper u-wfull" :style="{ 'textAlign': currentAlignment }">
            <h1 v-if="clonedHeading && currentHeadingLevel && currentHeadingLevel.value && currentHeadingLevel.value.value === 'h1'" ref="editorRef" placeholder="Heading H1" class="c-heading-editor c-xspacing py-2 u-wfull" :id="clonedHeading.id" :contenteditable="$can('document_generator.update')" @blur="methodUpdateOnBlur" @keyup="methodKeyUp" @keydown="methodKeyDown" @keydown.enter.prevent="methodCatchEnterKey" @input="methodUpdateOnInput">{{ content_local }}</h1>

            <h2 v-if="clonedHeading && currentHeadingLevel && currentHeadingLevel.value && currentHeadingLevel.value.value === 'h2'" ref="editorRef" placeholder="Heading H2" class="c-heading-editor c-xspacing py-2 u-wfull" :id="clonedHeading.id" :contenteditable="$can('document_generator.update')" @blur="methodUpdateOnBlur" @keyup="methodKeyUp" @keydown="methodKeyDown" @keydown.enter.prevent="methodCatchEnterKey" @input="methodUpdateOnInput">{{ content_local }}</h2>

            <h3 v-if="clonedHeading && currentHeadingLevel && currentHeadingLevel.value && currentHeadingLevel.value.value === 'h3'" ref="editorRef" placeholder="Heading H3" class="c-heading-editor c-xspacing py-2 u-wfull" :id="clonedHeading.id" :contenteditable="$can('document_generator.update')" @blur="methodUpdateOnBlur" @keyup="methodKeyUp" @keydown="methodKeyDown" @keydown.enter.prevent="methodCatchEnterKey" @input="methodUpdateOnInput">{{ content_local }}</h3>

            <h4 v-if="clonedHeading && currentHeadingLevel && currentHeadingLevel.value && currentHeadingLevel.value.value === 'h4'" ref="editorRef" placeholder="Heading H4" class="c-heading-editor c-xspacing py-2 u-wfull" :id="clonedHeading.id" :contenteditable="$can('document_generator.update')" @blur="methodUpdateOnBlur" @keyup="methodKeyUp" @keydown="methodKeyDown" @keydown.enter.prevent="methodCatchEnterKey" @input="methodUpdateOnInput">{{ content_local }}</h4>

            <h5 v-if="clonedHeading && currentHeadingLevel && currentHeadingLevel.value && currentHeadingLevel.value.value === 'h5'" ref="editorRef" placeholder="Heading H5" class="c-heading-editor c-xspacing py-2 u-wfull" :id="clonedHeading.id" :contenteditable="$can('document_generator.update')" @blur="methodUpdateOnBlur" @keyup="methodKeyUp" @keydown="methodKeyDown" @keydown.enter.prevent="methodCatchEnterKey" @input="methodUpdateOnInput">{{ content_local }}</h5>

            <h6 v-if="clonedHeading && currentHeadingLevel && currentHeadingLevel.value && currentHeadingLevel.value.value === 'h6'" ref="editorRef" placeholder="Heading H6" class="c-heading-editor c-xspacing py-2 u-wfull" :id="clonedHeading.id" :contenteditable="$can('document_generator.update')" @blur="methodUpdateOnBlur" @keyup="methodKeyUp" @keydown="methodKeyDown" @keydown.enter.prevent="methodCatchEnterKey" @input="methodUpdateOnInput">{{ content_local }}</h6>
        </div>
        <!-- <template v-else>
            <div @click="methodFocusEditor" class="u-cursor-pointer">
                <input type="text" placeholder="Heading" disabled v-once class="c-text-editor u-cursor-pointer c-xspacing u-wfull grey--text text--darken-2">
            </div>
        </template> -->
    </div>
</template>

<script>
import TurndownService from 'turndown'
import marked from 'marked'
import { mapState, mapActions, mapGetters } from 'vuex'
import EventBus from '@/config/config-eventbus'
import BlockListMenu from '../BlockListMenu.vue'


export default {
    components: { BlockListMenu },

    props: ['item', 'index', 'lastIndex', 'list'],

    data () {
        return {
            clonedHeading: this.item,
            content_local: '',
            currentLevel: 'h1',
            currentHeadingLevel: '',
            currentMenu: '',
            currentAlignment: 'left',
            currentMenuValue: '',
            renderComponent: true,
            dataSideMenu: false,
            dataBlockListMenu: false,
            componentKey: 0,
            currentFormatting: '',
            isEnter: false,
            isSpace: false,
            dataCaretLastLocation: null,
            dataAutoSaveTimeout: null,
            showEditor: false,
            focusInput: false,
            lengthToDelete: 0,
            format: {
                center: false,
                right: false,
                left: false,
            }
        }
    },

    watch: {
        lastIndex (value) {
            if (value === this.index) {
                // this.$refs.editorRef.focus()
                // this.focusInput = true
            }
        }
    },

    mounted () {
        this.methodIndex()
    },

    computed: {
        dataGetBlockInfo () {
            return this.getBlockContent(this.clonedHeading.report_block_id)
        },

        dataHeadingLevelDefault () {
            const block = this.methodDefaultAttrByTitle('Heading Level')
            this.methodBlockAttrs(this.clonedHeading.id, block.id, block.title)
        },

        dataAlignmentDefault () {
            const block = this.methodDefaultAttrByTitle('Alignment')
            this.methodBlockAttrs(this.clonedHeading.id, block.id, block.title)
        },

        dataGetBlockInfo () {
            return this.getBlockContent(this.clonedHeading.report_block_id)
        },

        ...mapGetters('ReportBlock', [
            'getDefaultBlockAttr',
            'getBlockContent'
        ]),

        ...mapGetters('ReportTemplateBlock', [
            'getBlockAttrs'
        ]),

        ...mapGetters('ReportTemplate', [
            'getBlock',
        ]),
    },

    methods: {
        dataGetBlockElement () {
            return document.getElementById(this.clonedHeading.id)
        },

        async methodIndex () {
            // this.methodSetCaret(this.dataGetBlockElement, false)
            this.$nextTick(() => {
                // const htmlContent = this.methodCompiledMarkdown(this.clonedHeading.content)
                const htmlContent = this.clonedHeading.content
                this.content_local = htmlContent
            })
            this.dataHeadingLevelDefault
            this.dataAlignmentDefault
        },

        methodFocusEditor () {
            this.showEditor = true
            setTimeout(() => {
                this.$refs.editorRef.focus()
            }, 0);
        },

        methodBlockAttrs (blk_id, attr_id, type = null) {
            const attr = this.getBlockAttrs(blk_id, attr_id)
            if (type === 'Heading Level') {
                this.currentHeadingLevel = attr
            } else {
                this.currentAlignment = attr && attr.value ? attr.value.value : 'left'
            }
            return attr
        },

        methodUpdateOnInput (evt) {
            const { id, innerText: text, outerHTML: outHTML, innerHTML: html, children: childEls } = evt.target

            if (evt.inputType === 'insertFromPaste') this.methodBlurAndFocus()
            this.methodLiveUpdate(text, html)
        },

        methodBlurAndFocus () {
            const element = this.dataGetBlockElement()
            this.$nextTick(() => element.blur())
            setTimeout(() => {
                element.focus()
                this.methodSetCaretPosition(element, element.innerText.length)
            }, 500);
        },

        methodUpdateOnBlur (evt) {
            if (!this.$can('document_generator.update')) return
            const { id, innerText: text, outerHTML: outHTML, innerHTML: html } = evt.target
            const element = this.dataGetBlockElement()
            const striped = html.replace(/<img.*?src="(.*?)"[^\>]+>/ig, '')
            const markDown = marked(striped, { sanitize: true })
            const serializedContent = this.methodSerializeHtml(markDown)

            evt.preventDefault()
            this.clonedHeading.content = text
            // this.clonedHeading.content_html = serializedContent
            this.content_local = text
            element.innerHTML = this.methodStripSplChars(text.trim())

            EventBus.$emit('editBlock', this.clonedHeading)
            // if (!this.clonedHeading.content.length) return
            this.methodUpdateBlock()
        },

        methodStripSplChars (content) {
            const filtered = content.replace(/(<.*?)/ig, '&lt;')
            return filtered.replace(/(>.*?)/ig, '&gt;')
        },

        methodLiveUpdate (text, html) {
            if (!this.$can('document_generator.update')) return
            clearTimeout(this.dataAutoSaveTimeout)
            const element = this.dataGetBlockElement()
            const striped = html.replace(/<img.*?src="(.*?)"[^\>]+>/ig, '')
            const markDown = marked(striped, { sanitize: true })
            const serializedContent = this.methodSerializeHtml(markDown)

            this.clonedHeading.content = text
            // this.clonedHeading.content_html = serializedContent
            EventBus.$emit('editBlock', this.clonedHeading)

            if (!this.clonedHeading.content.length) return
            this.dataAutoSaveTimeout = setTimeout(async () => {
                this.methodUpdateBlock()
                clearTimeout(this.dataAutoSaveTimeout)
            }, 500);
        },

        methodSetCaretPosition (element, caretLocation) {
            if (element.firstChild) {
                const range = document.createRange();
                const selection = window.getSelection();
                range.setStart(element.firstChild, caretLocation);
                range.setEnd(element.firstChild, caretLocation);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        },

        methodCatchEnterKey (evt) {
            const isEnter = evt.key === 'Enter'
            const hasShift = evt.shiftKey

            if (isEnter && !hasShift) {
                EventBus.$emit('createBlockOnEnter', this.index + 1)
            }
        },

        methodSerializeHtml (html) {
            let serializedHtml = html.replace(/(<([^>]+)>)/ig, '') // Remove all tags
            serializedHtml = serializedHtml.replace(/(<[^>]+) style=".*?"/ig, '$1') // Remove style attribute
            serializedHtml = serializedHtml.replace(/\&nbsp;/g, '') // Remove &nbsp; character
            serializedHtml = serializedHtml.replace(/<img.*?src="(.*?)"[^\>]+>/ig, '') // Remove <img> tag

            if (serializedHtml.children && serializedHtml.children.length) {
                Array.from(serializedHtml.children).forEach((element, index) => {
                    for (let prop in element.dataset) delete element[index].dataset[prop]
                })
            }

            return serializedHtml
        },

        methodKeyUp (evt) {
            const element = this.dataGetBlockElement()
            if (evt.target.innerText.length === 0) {
                element.blur()
                setTimeout(() => element.focus(), 100)
            }
        },

        methodKeyDown (evt) {
            const element = this.dataGetBlockElement()
            if (evt.key === 'Backspace' || evt.key === 'Delete') {
                if (evt.target.innerText.length === 0) {
                    this.lengthToDelete = this.lengthToDelete + 1
                    setTimeout(() => {
                        element.blur()
                        setTimeout(() => element.focus(), 0)
                    }, 200)
                }
                if (evt.target.innerText.length === 0 && this.lengthToDelete === 1) return this.methodRemoveComponent()
            }
        },

        methodFormatText (menu, format = false, attrDefId, attrId) {
            clearTimeout(this.dataAutoSaveTimeout)
            this.currentMenuValue = menu
            this.currentMenu = { default_id: attrDefId, menu_attr_id: attrId }
            this.$nextTick(() => {
                if (format) {
                    this.currentFormatting = 'alignment'
                    // this.dataHeadingAlign = menu.value
                } else {
                    this.currentFormatting = 'level'
                    this.currentLevel = menu.value
                    // this.dataHeadingLevel = menu.value
                }

                if (this.currentFormatting === 'alignment') {
                    this.dataGetBlockElement().style.textAlign = menu
                    this.clonedHeading.content = this.dataGetBlockElement().innerText
                    // this.clonedHeading.content_html = this.dataGetBlockElement().outerHTML
                } else {
                    const headingEl = document.createElement(menu.value)
                    headingEl.innerText = this.dataGetBlockElement().innerText
                    this.clonedHeading.content = this.dataGetBlockElement().innerText
                    // this.clonedHeading.content_html = headingEl.outerHTML
                    // this.methodUpdateBlock()
                }

                this.dataAutoSaveTimeout = setTimeout(async () => {
                    await this.methodUpdateBlockAttr()
                    clearTimeout(this.dataAutoSaveTimeout)
                }, 500);
                this.checkHasFormatType()
            })
        },

        checkHasFormatType () {
            const variables = ['center', 'right', 'left']
            variables.forEach(item => {
                if (this.methodHasCommand(item)) this.format[item] = true
                else this.format[item] = false
            })
        },

        async methodUpdateBlock () {
            this.actionTemplateBlockEdit({
                id: this.clonedHeading.id,
                report_block_id: this.clonedHeading.report_block_id,
                report_template_id: this.$route.params.template_id,
                content: this.clonedHeading.content,
                // content_html: this.clonedHeading.content_html,
            })
            EventBus.$emit('editBlock', this.clonedHeading)
        },

        async methodUpdateBlockAttr () {
            const params = {
                rt_block_id: this.clonedHeading.id,
                rb_attribute_id: this.currentMenu.default_id,
                value: JSON.stringify(this.currentMenuValue)
            }
            await this.actionTemplateBlockAttrEdit({ id: this.currentMenu.menu_attr_id ? this.currentMenu.menu_attr_id : null, ...params })
            EventBus.$emit('editBlock', this.clonedHeading)
            this.actionTemplateBlockShow({ id: this.clonedHeading.id, index: this.index })
        },

        methodDefaultAttrByTitle (title) {
            return this.dataGetBlockInfo.attributes.find(item => item.title === title)
        },

        methodHasCommand (type) {
            return document.queryCommandState(type)
        },

        methodCompiledMarkdown (content) {
            return turndownService.turndown(content)
        },

        async methodStoreReportBlock (component) {
            await this.actionTemplateBlockStore({
                index: this.index + 1,
                data: { report_template_id: this.$route.params.template_id, report_block_id: component.id },
                params: { include: 'reportBlock' }
            })
            this.actionTemplateBlockReorder({ list: this.list })
            // EventBus.$emit('reorderBlock', { index: this.index + 1 })
        },

        methodRemoveComponent() {
            this.actionFindAndRemoveBlock(this.clonedHeading)
            this.actionTemplateBlockDelete({ id: this.clonedHeading.id })
            // EventBus.$emit('removeComponent', this.clonedHeading)
        },

        ...mapActions('ReportTemplateBlock', [
            'actionTemplateBlockStore',
            'actionTemplateBlockEdit',
            'actionTemplateBlockShow',
            'actionTemplateBlockReorder',
            'actionTemplateBlockAttrsStore',
            'actionTemplateBlockAttrEdit',
            'actionFindAndRemoveBlock',
            'actionTemplateBlockDelete'
        ]),
    }
}
</script>

<style lang="scss" scoped>
    .c-heading-editor {
        transition: 0.4s all;
        position: relative;
        font-family: Arial !important;
        &:focus {
            // background: #f5f5f5 !important;
            background: #fbfbfb !important;
            transition: 0.4s all;
        }
        h1, h2, h3, h4, h5, h6 {
            width: 100%;
        }
    }

    .c-heading-editor-wrapper {
        h1 { font-size: 40px !important; }
        h2 { font-size: 32px !important; }
        h3 { font-size: 27px !important; }
        h4 { font-size: 24px !important; }
    }

    .c-heading-levels-menu {
        h1 { font-size: 30px !important; }
        h2 { font-size: 26px !important; }
        h3 { font-size: 22px !important; }
        h4 { font-size: 18px !important; }
        h5 { font-size: 14px !important; }
        h6 { font-size: 10px !important; }
    }

    [contenteditable]:empty:before {
        content: attr(placeholder);
        display: block;
        color: rgba(99, 98, 98, 0.25);
    }
</style>

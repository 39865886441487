<template>
	<div>
        <h3 class="text-h6 grey--text text--darken-3 font-weight-medium">Import from Master Templates</h3>
        <a-responsive class="my-4" v-if="pageLoading">
            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Template Groups..</h3>
        </a-responsive>
        <a-responsive class="my-4" v-if="!pageLoading && template_group_list && (localTemplateFilterData && !localTemplateFilterData.length) && (localTemplateFilterEmptyData && !localTemplateFilterEmptyData.length)">
            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
            <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
        </a-responsive>
        <a-sheet color="orange lighten-5" class="mb-6 u-rounded-corners-lg u-flex-center-y" v-if="!pageLoading && !checkImportModelPermission">
            <div class="pa-3">
                <a-icon size="20" color="orange darken-2">info</a-icon>
                <span class="md-subtitle-1 ml-2 font-weight-medium orange--text text--darken-2">You don’t have “create” permission to one or more modules to import master template.</span>
            </div>
        </a-sheet>
        <div v-if="localTemplateFilterData && localTemplateFilterData.length" class="mt-8">
            <h3 class="md-subtitle-2 font-weight-medium indigo--text text--darken-1 text-uppercase">Templates With Data</h3>
            <template v-for="(template, index) in localTemplateFilterData">
                <a-sheet class="u-shadow u-rounded-corners mt-5" :key="template.id">
                    <div class="py-4 px-5 u-flex-center-y">
                        <div>
                            <h3 class="md-subtitle-1 font-weight-medium grey--text text--darken-2">
                                {{ template.name }}
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <span class="ml-1 u-icon-nudge u-cursor-pointer" v-on="on" @click="localSwitchRoute(template.id)"> 
                                            <a-icon size="16" color="blue">open_in_new</a-icon>
                                        </span>
                                    </template>
                                    <span>Open master template</span>
                                </a-tooltip>
                            </h3>
                            <div class="u-flex-center-y md-body-2 mt-1">
                                <span class="mr-2 grey--text text--darken-1">Use Cases: <strong>{{ template.master_template_all_usecases_count }}</strong></span>
                                <span class="mr-2 grey--text text--darken-1">Tasks: <strong>{{ template.master_template_all_tasks_count }}</strong></span>
                                <span class="mr-2 grey--text text--darken-1">Notes: <strong>{{ template.master_template_all_notes_count }}</strong></span>
                                <span class="mr-2 grey--text text--darken-1">Documents: <strong>{{ template.master_template_all_documents_count }}</strong></span>
                                <span class="mr-2 grey--text text--darken-1">Links: <strong>{{ template.master_template_all_links_count }}</strong></span>
                            </div>
                        </div>
                        <a-spacer></a-spacer>
                        <a-sheet
                            @click="checkImportModelPermission && !mixinIsLoading('import-loading') ? localSelectTemplate(template) : ''"
                            width="140"
                            :class="[!checkImportModelPermission || mixinIsLoading('import-loading') ? 'u-opacity-50' : 'u-cursor-pointer']"
                            class="indigo darken-1 white--text text--darken-2 text-center u-border text-uppercase md-caption font-weight-medium u-rounded-corners-lg px-3 py-2">
                            Import Template
                        </a-sheet>
                    </div>
                </a-sheet>
            </template>
        </div>
        <div v-if="localTemplateFilterEmptyData && localTemplateFilterEmptyData.length" class="mt-10">
            <h3 class="md-subtitle-2 font-weight-medium indigo--text text--darken-1 text-uppercase">Templates Without Data</h3>
            <template v-for="(template, index) in localTemplateFilterEmptyData">
                <a-sheet :key="template.id" class="u-shadow u-rounded-corners mt-5">
                    <div class="py-4 px-5 u-flex-center-y">
                        <div>
                            <h3 class="md-subtitle-1 font-weight-medium grey--text text--darken-2">
                                {{ template.name }}
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <span class="ml-1 u-icon-nudge u-cursor-pointer" v-on="on" @click="localSwitchRoute(template.id)"> 
                                            <a-icon size="16" color="blue">open_in_new</a-icon>
                                        </span>
                                    </template>
                                    <span>Open master template</span>
                                </a-tooltip>
                            </h3>
                            <div class="u-flex-center-y md-body-2 mt-1">
                                <span class="mr-2 grey--text text--darken-1">Use Cases: <strong>{{ template.master_template_all_usecases_count }}</strong></span>
                                <span class="mr-2 grey--text text--darken-1">Tasks: <strong>{{ template.master_template_all_tasks_count }}</strong></span>
                                <span class="mr-2 grey--text text--darken-1">Notes: <strong>{{ template.master_template_all_notes_count }}</strong></span>
                                <span class="mr-2 grey--text text--darken-1">Documents: <strong>{{ template.master_template_all_documents_count }}</strong></span>
                                <span class="mr-2 grey--text text--darken-1">Links: <strong>{{ template.master_template_all_links_count }}</strong></span>
                            </div>
                        </div>
                        <a-spacer></a-spacer>
                        <a-sheet width="140" class="indigo darken-1 u-opacity-50 white--text text--darken-2 text-center u-border text-uppercase md-caption font-weight-medium u-rounded-corners-lg px-3 py-2">
                            <a-tooltip bottom v-if="checkImportModelPermission">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">Import Template</span>
                                </template>
                                <span>No data to import</span>
                            </a-tooltip>
                            <span v-else>Import Template</span>
                        </a-sheet>
                    </div>
                </a-sheet>
            </template>
        </div>
            
        <a-dialog v-model="dialogExistsTemplateImport" persistent max-width="600">
            <a-card>
                <a-sheet class="py-6 px-7">
                    <p class="text-h6 amber--text text--darken-3 font-weight-medium ma-0 pa-0">
                        <span class="mr-1"><a-icon size="26" color="amber darken-3">info</a-icon></span>
                        We found some data in the project
                    </p>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <a-sheet class="py-6 px-7">
                    <p class="md-subtitle-2 grey--text text--darken-1 font-weight-medium ma-0 pa-0">
                        The following data already exists in this project:
                    </p>
                    <div class="pa-4 my-5 c-count-border c-count-background">
                        <p class="md-subtitle-2 py-1 grey--text text--darken-1 font-weight-medium ma-0 pa-0" v-if="project_item.usecases_count">
                            <span class="mr-1 u-icon-nudge"><a-icon size="20" color="grey" outlined>check_box</a-icon></span>
                            Use Cases:<strong class="ml-1">{{ project_item.usecases_count }}</strong>
                        </p>
                        <p class="md-subtitle-2 py-1 grey--text text--darken-1 font-weight-medium ma-0 pa-0" v-if="project_item.tasks_count">
                            <span class="mr-1 u-icon-nudge"><a-icon size="20" color="grey" outlined>task_alt</a-icon></span>
                            Tasks:<strong class="ml-1">{{ project_item.tasks_count }}</strong>
                        </p>
                        <p class="md-subtitle-2 py-1 grey--text text--darken-1 font-weight-medium ma-0 pa-0" v-if="project_item.notes_count">
                            <span class="mr-1 u-icon-nudge"><a-icon size="20" color="grey" outlined>description</a-icon></span>
                            Notes:<strong class="ml-1">{{ project_item.notes_count }}</strong>
                        </p>   
                        <p class="md-subtitle-2 py-1 grey--text text--darken-1 font-weight-medium ma-0 pa-0" v-if="project_item.documents_count">
                            <span class="mr-1 u-icon-nudge"><a-icon size="20" color="grey" outlined>source</a-icon></span>
                            Documents: <strong class="ml-1">{{ project_item.documents_count }}</strong>
                        </p>
                        <p class="md-subtitle-2 py-1 grey--text text--darken-1 font-weight-medium ma-0 pa-0" v-if="project_item.links_count">
                            <span class="mr-1 u-icon-nudge"><a-icon size="20" color="grey" outlined>link</a-icon></span>
                            Links:<strong class="ml-1">{{ project_item.links_count }}</strong>
                        </p>
                    </div>
                    <p class="md-subtitle-2 grey--text text--darken-1 font-weight-medium ma-0 pa-0">
                        Would you like to:
                    </p>
                    <div class="my-5 c-count-border">
                        <v-radio-group v-model="importStatus" row class="pa-0 ma-0" hide-details>
                            <div class="u-wfull px-4 ma-0" :class="[importStatus === 'append' ? 'pt-2' : 'py-3']">
                                <v-radio
                                    color="indigo darken-1"
                                    class="u-wfull"
                                    :style="[importStatus === 'without_duplicate' ? { opacity: 0.6} : '']"
                                    value="append"
                                >
                                    <template v-slot:label>
                                        <p class="md-subtitle-2 grey--text text--darken-1 font-weight-medium ma-0 pa-0">
                                            Keep the existing data and import from selected Master Template
                                        </p>
                                    </template>
                                </v-radio>
                                <div class="d-inline-flex pb-2" v-if="importStatus === 'append'">
                                    <p class="text-sm-body-2 u-cursor-pointer grey--text text--darken-2 ml-11 mb-0 mt-1" @click="importIgnoreStatus = !importIgnoreStatus">
                                        <span class="u-icon-nudge-xs"><a-icon size="18" :color="!importIgnoreStatus ? 'grey' : 'indigo darken-1'" :outlined="!importIgnoreStatus">{{ !importIgnoreStatus ? 'check_box_outline_blank' : 'check_box' }}</a-icon></span>
                                        Ignore the duplicate data, if exists in the project
                                    </p>
                                </div>
                            </div>
                            <a-divider></a-divider>
                            <div class="u-wfull px-4 py-3" style="border-top: 1px solid #E0E0E0 !important;">
                                <v-radio
                                    v-if="checkImportStatusPermission"
                                    color="indigo darken-1"
                                    class="u-wfull"
                                    value="without_duplicate"
                                    :style="[importStatus === 'append' ? { opacity: 0.6} : '']"
                                    :disabled="!checkImportStatusPermission"
                                >
                                    <template v-slot:label>
                                        <p class="md-subtitle-2 grey--text text--darken-1 font-weight-medium ma-0 pa-0">
                                            Remove all existing data and import from selected Master Template
                                        </p>
                                    </template>
                                </v-radio>
                                <a-tooltip bottom v-else>
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on">
                                            <v-radio
                                                color="indigo darken-1"
                                                class="ma-0 u-wfull"
                                                value="without_duplicate"
                                                disabled
                                            >
                                                <template v-slot:label>
                                                    <p class="md-subtitle-2 grey--text text--darken-1 font-weight-medium ma-0 pa-0">
                                                        Remove all existing data and import from selected Master Template
                                                    </p>
                                                </template>
                                            </v-radio>
                                        </div>
                                    </template>
                                    <span>You don’t have "delete" permission to one or more modules to remove items in project.</span>
                                </a-tooltip>
                            </div>
                        </v-radio-group>
                    </div>
                    <div>
                        <a-btn depressed class="indigo darken-1 white--text u-rounded-corners-lg" @click="localImportTemplate()" :loading="localImportLoading">
                            Confirm Import
                        </a-btn>
                        <a-btn depressed @click="dialogExistsTemplateImport = false" class="ml-4 grey lighten-3 grey--text text--darken-1 u-rounded-corners-lg">
                            Cancel
                        </a-btn>
                    </div>
                </a-sheet>
            </a-card>
        </a-dialog>
        <a-dialog v-model="dialogTemplateImport" persistent max-width="600">
            <a-card>
                <a-sheet class="py-5 px-6">
                    <p class="text-h6 indigo--text text--darken-1 font-weight-medium ma-0 pa-0" v-if="project_template_import_item  && project_template_import_item.template_import_percentage === 100">
                        <a-icon size="26" class="mr-1" color="indigo darken-1">check_circle</a-icon>
                        Imported Successfully
                    </p>
                    <p class="text-h6 indigo--text text--darken-1 font-weight-medium ma-0 pa-0" v-else>
                        <a-icon size="26" class="mr-1" color="indigo darken-1"> {{ mixinIsLoading('import-loading') ? 'content_copy' : 'get_app' }} </a-icon>
                        {{ mixinIsLoading('import-loading') ? 'Importing...' : 'Import Master Template' }}
                    </p>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <a-sheet class="pa-6" v-if="localSelectTemplateItem">
                    <h3 class="md-subtitle-1 font-weight-medium grey--text text--darken-1 ma-0 pa-0">{{ localSelectTemplateItem.name }}</h3>
                    <div class="u-flex-center-y md-body-2 mt-2">
                        <span class="mr-2 grey--text text--darken-1">Use Cases: <strong>{{ localSelectTemplateItem.master_template_all_usecases_count }}</strong></span>
                        <span class="mr-2 grey--text text--darken-1">Tasks: <strong>{{ localSelectTemplateItem.master_template_all_tasks_count }}</strong></span>
                        <span class="mr-2 grey--text text--darken-1">Notes: <strong>{{ localSelectTemplateItem.master_template_all_notes_count }}</strong></span>
                        <span class="mr-2 grey--text text--darken-1">Documents: <strong>{{ localSelectTemplateItem.master_template_all_documents_count }}</strong></span>
                        <span class="mr-2 grey--text text--darken-1">Links: <strong>{{ localSelectTemplateItem.master_template_all_links_count }}</strong></span>
                    </div>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <a-sheet class="py-5 px-6" v-if="localSelectTemplateItem">
                    <div class="u-wfull">
                        <template v-if="project_template_import_item  && project_template_import_item.template_import_percentage === 100">
                            <a-btn depressed class="indigo darken-1 white--text u-rounded-corners-lg" :to="{ name: 'projects-overview' }" :exact="true">
                                Go to Overview
                            </a-btn>
                            <a-btn depressed class="primary white--text u-rounded-corners-lg ml-4" @click="localImportTemplateMore()">
                                Import More Templates
                            </a-btn>
                        </template>
                        <template v-else>
                            <div v-if="!mixinIsLoading('import-loading')">
                                <a-btn depressed class="indigo darken-1 white--text u-rounded-corners-lg" @click="localImportTemplate()" :loading="localImportLoading">
                                    Confirm Import
                                </a-btn>
                                <a-btn depressed @click="dialogTemplateImport = false" class="ml-4 grey lighten-3 grey--text text--darken-1 u-rounded-corners-lg">
                                    Cancel
                                </a-btn>
                            </div>
                            <div v-if="mixinIsLoading('import-loading')" class="u-flex-center-y u-wfull">
                                <PProgressLinear :percent="project_template_import_item.template_import_percentage" :height="16" :color="'#80CBC4'"/>
                                <h3 class="md-subtitle-1 ml-7 font-weight-medium teal--text">{{project_template_import_item.template_import_percentage ? project_template_import_item.template_import_percentage : 0 }}%</h3>
                            </div>
                        </template>
                    </div>
                </a-sheet>
            </a-card>
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import {
    PProgressLinear,
} from './Partials'

export default {
    mixins: [
        mixinLoading,
    ],

    components: {
        PProgressLinear,
    },

    data () {
        return {
           selectedTemplateId: null,
           importStatus: 'append',
           localSelectTemplateId: null,
           dialogExistsTemplateImport: false,
           importIgnoreStatus: false,
           importTimeout: null,
           pageLoading: false,
           localImportLoading: false,
           dialogTemplateImport: false,
           localSelectTemplateItem: null,
           
        }
    },

    watch: {
        workspace_current (oldValue, newValue) {
            this.local_index()
        },
        importStatus (oldValue, newValue) {
            if(newValue === 'without_duplicate') {
                this.importIgnoreStatus = false
            }
        },
    },

    beforeDestroy () {
        clearInterval(this.importTimeout)
    },

    mounted () {
        this.local_index()
    },

    computed: {
        localProjectId () {
            return this.$route.params.id
        },

        checkImportStatusPermission() {
            return this.$can('usecases.destroy') && this.$can('tasks.destroy') && this.$can('links.destroy')
                   && this.$can('documents.destroy') && this.$can('notes.destroy')
        },
        
        checkImportModelPermission() {
            return this.$can('usecases.index') && this.$can('tasks.index') && this.$can('links.index')
                   && this.$can('documents.index') && this.$can('notes.index') && this.$can('usecases.update') && this.$can('tasks.update') && this.$can('links.update')
                   && this.$can('documents.update') && this.$can('notes.update')
        },

        checkProjectDataCount() {
            return this.project_item.usecases_count !== 0 ||this.project_item.tasks_count !== 0 || this.project_item.notes_count !== 0
                   || this.project_item.documents_count !== 0 || this.project_item.links_count !== 0
        },

        localTemplateFilterData() {
           return this.template_group_list.filter(list => list.master_template_all_usecases_count || list.master_template_all_tasks_count || list.master_template_all_notes_count || list.master_template_all_documents_count || list.master_template_all_links_count)
        },

        localTemplateFilterEmptyData() {
           return this.template_group_list.filter(list => !list.master_template_all_usecases_count && !list.master_template_all_tasks_count && !list.master_template_all_notes_count && !list.master_template_all_documents_count && !list.master_template_all_links_count)
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('ProjectView', {
            project_item: 'item',
            project_response: 'response'
        }),

        ...mapState('TemplateGroup', {
            template_group_list: 'list',
            template_group_item: 'item',
        }),

        ...mapState('ProjectTemplateImport', {
            project_template_import_item: 'item',
        }),

        ...mapState('Workspace', {
            workspace_board_list: 'board_list',
        }),

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_index() {
            if (this.$can('projects.update-only') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            this.pageLoading = true
            this.localCheckTemplateImport()
            await this.template_group_clear()
            this.localProjectShow()
            await this.localGetMasteTemplates()
            this.pageLoading = false
        },

        async localCheckTemplateImport() {
            await this.project_template_import_show({ id: this.localProjectId, params: { 'fields[project_template_imports]': 'id,project_id,template_group_id', } })
            if(this.project_template_import_item && this.project_template_import_item.template_import_percentage && this.project_template_import_item.template_import_percentage !== 100) {
                await this.localMasteTemplateShow(this.project_template_import_item.template_group_id)
                this.localSelectTemplateItem = this.template_group_item
                this.dialogTemplateImport = true
                this.localCheckImportPercentage()
            }
        },

       localSelectTemplate(template) {
            this.localSelectTemplateItem = null
            this.mixinResetLoading('import-loading')
            this.project_template_import_clear_item()
            clearInterval(this.importTimeout)
            this.localSelectTemplateItem = template
            this.importStatus = 'append'
            this.importIgnoreStatus = false
            if(!this.checkProjectDataCount) this.dialogTemplateImport = true
            else this.dialogExistsTemplateImport = true
        },

        localImportTemplateMore() {
            this.dialogTemplateImport = false
        },

        localSwitchRoute(id) {
            const route = this.$router.resolve({name: 'master-templates-usecases', params:  { id: id }})
            window.open(route.href);
        },

        async localProjectShow () {
            const params = { include: 'usecasesCount,tasksCount,documentsCount,notesCount,linksCount', 'fields[projects]': 'id,template_group_id' }
            await this.project_show({
                id: this.localProjectId,
                params: params
            })
        },

        async localMasteTemplateShow (id) {
            await this.template_group_show({
                'fields[template_groups]': 'id,name',
                'include': 'masterTemplateAllUsecasesCount,masterTemplateAllTasksCount,masterTemplateAllNotesCount,masterTemplateAllLinksCount,masterTemplateAllDocumentsCount',
                'filter[type]': 'master_template',
                'sort': 'order',
                'id': id
            })
        },

        async localGetMasteTemplates () {
            await this.template_group_index({
                'fields[template_groups]': 'id,name',
                'include': 'masterTemplateAllUsecasesCount,masterTemplateAllTasksCount,masterTemplateAllNotesCount,masterTemplateAllLinksCount,masterTemplateAllDocumentsCount',
                'filter[type]': 'master_template',
                'filter[is_active]': 1,
                'count': 999,
                'sort': 'order',
            })
        },

        async localImportTemplate () {
            const template_group_id = this.localSelectTemplateItem.id
            this.localImportLoading = true
            await this.project_master_template_import({ project_id: this.localProjectId, template_group_id, import_status: this.checkImportStatus() })
            if(!this.$status(this.project_response)) return
            this.dialogExistsTemplateImport = false
            this.dialogTemplateImport = true
            this.localImportLoading = false
            this.localCheckImportPercentage()
        },

        async localCheckImportPercentage() {
            this.mixinSetLoading('import-loading')
            this.importTimeout = setInterval(async () => {
                await this.project_template_import_show({ id: this.localProjectId, params: { 'fields[project_template_imports]': 'id,project_id'}})
                if (this.project_template_import_item.template_import_percentage === 100) {
                    clearInterval(this.importTimeout)
                    await this.localProjectShow()
                    this.mixinResetLoading('import-loading')
                }
            }, 2000)
        },
        
        checkImportStatus() {
            if(this.importStatus === 'append' && this.importIgnoreStatus) return 'skip_duplicate'
            return this.importStatus
        },

        ...mapActions('ProjectView', {
            project_show: 'show',
            project_update_item: 'update_item',
            project_master_template_import: 'master_template_import',
            project_clear_response: 'clear_response',
        }),

        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_show: 'show',
            template_group_clear: 'clear',
        }),

        ...mapActions('ProjectTemplateImport', {
            project_template_import_show: 'show',
            project_template_import_clear_item: 'clear_item'
        }),
    },
}
</script>

<style lang="scss" scoped>
.c-count-border {
    border: 1px solid #E0E0E0 !important;
    border-radius: 4px;
}
.c-count-background {
    background: #fafafa !important;
}
</style>

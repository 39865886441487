<template>
    <a-card>
        <div class="px-7 py-6 u-flex-center-y">
            <div class="u-flex-center-y">
                <a-avatar size="40" color="indigo darken-2">
                    <a-icon size="24" color="white">
                        <slot name="icon-name">domain_verification</slot>
                    </a-icon>
                </a-avatar>
                <h3 class="text-h6 indigo--text text--darken-3 font-weight-medium ml-3">
                    <slot name="title">Update Stage</slot>
                </h3>
            </div>
            <a-spacer></a-spacer>
            <a-icon size="20" color="grey darken-2" @click="localClose()">clear</a-icon>
        </div>

        <a-divider></a-divider>

        <div class="u-flex" :class="[currentTab === 'stageUpdate' ? 'c-slide-0' : 'c-slide-50']">
            <div style="min-width: 640px;">
                <a-sheet class="pa-6 px-7 pt-0 u-overflow-y" max-height="600">
                    <div class="pt-5" v-if="(!localCurrentStageIsCompleted || !localSelectedStageIsCompleted) && (selectedStage && selectedStage.id)">
                        <p class="mb-3 md-subtitle-1 font-weight-medium grey--text text--darken-2">Are you sure you want to update project stage?</p>
                        <div class="u-flex-center-y" v-if="currentStage || (project_item && project_item.status === 'archived')">
                            <span class="md-subtitle-1 font-weight-medium grey--text text--darken-3">{{ (project_item && project_item.status === 'archived') ? 'Archived' : currentStage.name | truncateText(32) }}</span>
                            <a-icon size="32" color="grey darken-1" class="mx-3">trending_flat</a-icon>
                            <span class="md-subtitle-1 font-weight-medium indigo--text text--darken-2">{{ selectedStage.name | truncateText(32) }}</span>
                        </div>
                    </div>

                    <a-sheet :min-height="isResultEdit ? 240 : 'auto'" v-if="localLoading && (selectedStage && selectedStage.status === 'completed')" class="pt-6 pb-4">
                        <loader-template width="100%" :height="isResultEdit ? 32 : 50" class="u-rounded-corners-lg mb-6"></loader-template>
                        <template v-if="isResultEdit">
                            <loader-template width="100%" height="80" class="u-rounded-corners-lg mb-6"></loader-template>
                            <loader-template width="100%" height="24" class="u-rounded-corners-lg mb-6"></loader-template>
                            <loader-template width="100%" height="80" class="u-rounded-corners-lg mb-6"></loader-template>
                            <loader-template width="100%" height="24" class="u-rounded-corners-lg"></loader-template>
                        </template>
                    </a-sheet>

                    <!-- Result for completed stage -->
                    <keep-alive>
                        <PResultUpdate
                            name="resultUpdate"
                            v-if="showResultSection && currentTab === 'stageUpdate'"
                            :stage="selectedStage"
                            :currentResult="currentResult"
                            :isOpen="isOpen"
                            :loading="loading"
                            :isResultEdit="isResultEdit"
                            @update="localResultUpdate"
                            class="mt-5"
                        ></PResultUpdate>
                    </keep-alive>
                </a-sheet>
            </div>

            <a-sheet class="pa-6 px-7 pt-5 u-overflow-y" min-width="640" max-height="600" v-if="showCompSection && showResultSection && currentTab === 'competitors'">
                <slot name="competitors" :count="localProjectCompetitors.length" :list="localProjectCompetitors"></slot>
            </a-sheet>
        </div>

        <div class="u-flex-center-y pa-6 px-7" style="borderTop: 1px solid #f2f2f2;">
            <a-btn 
                v-if="showCompSection && showResultSection && currentTab === 'stageUpdate' && !isResultEdit" 
                depressed 
                class="indigo darken-2 white--text u-rounded-corners" 
                :disabled="localDisableConditions" 
                :loading="loading"
                @click="showCompSection ? localSwitchTab('competitors', null) : ''"
            >
                Next
            </a-btn>
            <template v-else>
                <a-btn depressed class="indigo darken-2 white--text u-rounded-corners" :disabled="localDisableConditions" :loading="loading" @click="localUpdate()">
                    <slot name="action-label">Update Project</slot>
                </a-btn>
                <a-btn 
                    v-if="showCompSection && showResultSection && !isResultEdit" 
                    depressed 
                    class="ml-3 grey--text text--darken-1 u-rounded-corners" 
                    @click="localSwitchTab('stageUpdate','is_back')"
                >
                    Back
                </a-btn>
            </template>
            <a-btn depressed class="ml-3 grey--text text--darken-1 u-rounded-corners" @click="localClose()">Cancel</a-btn>
            <a-spacer></a-spacer>
            <a-btn v-if="!isResultEdit && !hideWorkspaceBtn && !$isConfidentialMode()" text class="ml-3 blue--text text--darken-2 u-rounded-corners" @click="$emit('updateWorkspace', currentStage)">
                <span class="md-subtitle-1 font-weight-medium">Update Workspace?</span>
            </a-btn>
        </div>
    </a-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { PResultUpdate } from '../Partials'
import { SCompetitorTypes } from '@/config/config-shared-components'

export default {
    props: {
        selectedStage: {
            type: Object
        },
        currentStage: {
            type: Object
        },
        currentResult: {
            type: Object
        },
        showCompSection: {
            type: Boolean,
            default: false
        },
        isOpen: {
            type: Boolean
        },
        isResultEdit: {
            type: Boolean,
            default: false
        },
        hideWorkspaceBtn: {
            type: Boolean,
            default: false
        },
        automationPreHook: {
            type: Function,
            default: {},
        },
        hookResponse: {
            type: Object,
            default: {},
        },
    },

    components: {
        PResultUpdate,
        SCompetitorTypes
    },

    data () {
        return {
            selectedResult: {},
            resultItem: {},
            currentTab: 'stageUpdate',
            showResultSection: false,
            loading: false,
            localLoading: true,
            reasonLoading: false
        }
    },

    watch: {
        isOpen: {
            handler (val) {
                if (val) this.localIndex()
            },
            immediate: true
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localCurrentStageIsCompleted () {
            return this.currentStage && this.currentStage.status === 'completed'
        },

        localSelectedStageIsCompleted () {
            return this.selectedStage && this.selectedStage.status === 'completed'
        },

        localProjIsArchived () {
            return this.project_item && this.project_item.status === 'archived'
        },

        localDisableConditions () {
            const checkConditions = this.localProjIsArchived && (this.selectedStage && !this.selectedStage.id)
            const commentError = !!(this.resultItem && this.resultItem.commentError)
            return checkConditions || (this.localSelectedStageIsCompleted && (this.resultItem && !this.resultItem.result)) || commentError
        },

        localProjectCompetitors () {
            return (this.project_item && this.project_item.competitors) || []
        },

        ...mapState('Meta', {
            meta_list: 'list'
        }),

        ...mapState('ProjectView', {
            project_item: 'item'
        }),

        ...mapState('MetaResultReason', {
            meta_result_reason_list: 'list',
        })
    },

     methods: {
         localIndex () {
            this.localLoading = true
            this.showResultSection = false
            this.localReset()
            this.localSetDefaultResultItem()
            setTimeout(() => {
                if ((this.localCurrentStageIsCompleted && this.localSelectedStageIsCompleted) || this.localSelectedStageIsCompleted) {
                    this.showResultSection = true
                }
                this.localLoading = false
            }, 300)
         },

        localResultUpdate (resultItem) {
            this.resultItem = resultItem
        },

        localSetDefaultResultItem () {
            this.resultItem.reasons = {}
            this.resultItem.result = null
            this.resultItem.comment = null
        },

        async localGetResultReason (result) {
            this.reasonLoading = true
            await this.meta_result_reason_clear()
            await this.meta_result_reason_index({
                'filter[result_id]': result.id,
                'fields[project_result_reasons]': 'id,title',
                'sort': 'order'
            })
            this.reasonLoading = false
         },

        async localSwitchTab (tabName, back) {
            if (back === 'is_back') return this.currentTab = tabName
            
            let { result, comment, reasons } = this.resultItem || {}
            const reasonIem = {}
            reasonIem.project_reasons = reasons.updateReasons
            reasonIem.result = result ? result.id : null
            reasonIem.comment = comment
            await this.automationPreHook('project-result', null, reasonIem)
            if (!this.$status(this.hookResponse)) return;
            
            this.currentTab = tabName
         },

        async localUpdate () {
            this.loading = true
            await this.$emit('update', { ...this.resultItem, selectedStage: this.selectedStage, currentStage: this.currentStage }, this.localReset)
            this.loading = false
         },

         localClose () {
             this.$emit('close', this.localReset)
         },

        localReset () {
            this.currentTab = 'stageUpdate'
            this.selectedResult = {}
            this.resultItem = {}
            this.loading = false
            this.reasonLoading = false
         },

         ...mapActions('MetaResultReason', {
            meta_result_reason_index: 'index',
            meta_result_reason_clear: 'clear',
        }),
    }
}
</script>

<style lang="scss" scoped>
.c-slide-0 {
    transform: translateX(0px);
}
.c-slide-50 {
    transform: translateX(-100%);
}
</style>

<template>
    <v-tooltip v-bind="$attrs" v-on="$listeners" transition="fade-transition">
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
            <template v-if="scopedSlotName === 'default'">
                <slot :name="scopedSlotName" v-bind="slotData"></slot>
            </template>
            <slot v-else :name="scopedSlotName" v-bind="slotData"></slot>
        </template>
    </v-tooltip>
</template>

<script>
import { VTooltip } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VTooltip },
    props: {
        contentWidth: {
            type: String,
            default: '250'
        },
        textAlign: {
            type: String,
            default: 'center'
        },
        textColor: {
            type: String,
            default: 'grey--text text--lighten-1'
        },
        background: {
            type: String,
            default: ''
        },
    }
}
</script>

<style lang="scss" scoped>
    .space-top {
        top: 55px !important;
    }
</style>

<template>
        <a-card>
            <a-sheet class="py-5 px-6">
                <p class="text-h6 amber--text text--darken-3 font-weight-medium ma-0 pa-0">
                    <span class="mr-1"><a-icon size="26" class="u-icon-nudge" color="amber darken-3">info</a-icon></span>
                    Custom field has been updated
                </p>
            </a-sheet>
            <a-divider class="grey lighten-3"></a-divider>
            <a-sheet class="py-5 px-6">
                <p class="md-body-3 pa-0 ma-0 grey--text text--darken-1">It seems like <strong class="font-italic mr-2">{{ item.label }}</strong>field has been updated recently as <strong class="font-italic">read-only.</strong></p>
                <p class="md-body-3 pa-0 mb-0 mt-5 grey--text text--darken-1" v-if="$can('custom_fields.index')">If you wish, you can update it<router-link target="_blank" tag="a" :to="{ name : 'settings-custom-fields-list', params: { id: 'project' } }" class="md-body-2 mb-0 blue--text text--darken-1 ml-1 text-decoration-underline">here</router-link>.</p>
                <p class="md-body-3 pa-0 mb-0 mt-5 grey--text text--darken-1" v-else>Please contact your administrator</p>
            </a-sheet>
            <a-divider class="grey lighten-3"></a-divider>
            <a-sheet class="py-5 px-6">
                <a-btn depressed class="indigo darken-1 white--text u-rounded-corners-lg" @click="$emit('close')">
                    Close
                </a-btn>
            </a-sheet>
        </a-card>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
    },
}
</script>

const api_endpoint = '/meetings'

export default {
    namespaced: true,

    state: {
        list: [],
        info: {},
        meta: {},
        defaults: {
            info: {},
            list: [],
            meta: {},
        }
    },

    mutations: {
        UPDATE_LIST (state, items) {
            state.list = items;
        },

        UPDATE_META (state, meta) {
            state.meta = meta
        },

        UPDATE_INFO (state, info) {
            state.info = info
        },

        THROW_RESPONSE (state, payload) {
            state.response = payload;
        },
    },

    actions: {
        async index ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data, meta } } = await axios.get(api_endpoint, { params: { ...payload } })
                commit('UPDATE_LIST', data)
                commit('UPDATE_META', meta)
                this.dispatch('Interface/loader_stop')
                 commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },

        async info_index ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data } = await axios.get(api_endpoint + '/info')
                commit('UPDATE_INFO', data)
                this.dispatch('Interface/loader_stop')
                 commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },
    },
}

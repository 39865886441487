<template>
    <a-card class="my-2 u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
        <a-card-text class="pa-0">
            <a-container container--fluid grid-list-xl class="px-6 py-0">
                <a-layout align-center>
                    <a-flex md7 shrink py-0 class="u-cursor-pointer" @click="$emit('edit-view', true)">
                        <div class="u-flex align-center py-7" :class="check_permission('update') ? handle : ''">
                            <h2 class="md-subtitle-2 font-weight-regular grey--text text--darken-2">{{ success_criterion.title | truncateText(140) }} <span class="grey--text md-caption" v-if="success_criterion.code">({{ success_criterion.code }})</span></h2>
                        </div>
                    </a-flex>
                    <a-flex md5 shrink py-0>
                        <div class="u-flex align-center justify-end">
                            <slot name="assignees"></slot>
                            <slot name="status"></slot>
                            <div v-if="check_permission('destroy')" class="pl-2">
                                <a-btn v-if="local_destroy_item_id !== success_criterion.id" icon text small color="grey" @click="local_destroy_item_id = success_criterion.id" class="ma-0">
                                    <a-icon size="14">delete</a-icon>
                                </a-btn>
                                <a-tooltip bottom right v-if="local_destroy_item_id === success_criterion.id">
                                    <template v-slot:activator="{ on }">
                                        <a-btn icon text small color="red" class="ma-0" @click="local_success_criterion_destroy(success_criterion.id)" v-on="on">
                                            <a-icon color="red" size="14">delete</a-icon>
                                        </a-btn>
                                        </template>
                                    <span>Confirm?</span>
                                </a-tooltip>
                            </div>
                        </div>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-card-text>
    </a-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        success_criterion: {
            type: Object
        },
        index: {
            type: Number
        },
        handle: {
            type: String,
            default: 'drag_handle_icon'
        }
    },

    data () {
        return {
            local_destroy_item_id: null,
        }
    },

    computed: {
        ...mapState('SuccessCriterion', {
            success_criterion_item: 'item',
            success_criterion_response: 'response',
            success_criterion_form_mode: 'form_mode',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_success_criterion_destroy (id) {
            await this.success_criterion_destroy({id: id})
            if (this.success_criterion_response && this.success_criterion_response.status !== 'success') return
            this.$notify('success', 'Success Criterion deleted successfully!')
        },

        // Extras
        check_permission (type) {
            return this.$can(`usecases.${type}`) && this.$can('projects.update-only')
        },

        ...mapActions('SuccessCriterion', {
            success_criterion_show: 'show',
            success_criterion_destroy: 'destroy',
        }),
    }
}
</script>

<style>

</style>

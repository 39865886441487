import Vue from 'vue'
import VueRouter from 'vue-router'

import lazyLoad from '@/helpers/helper-lazyroute'
import guardAuthenticate from './middleware/middleware-authenticate'
import guardUserAuthenticated from './middleware/middleware-user-authenticated'
import guardAdmin from './middleware/middleware-admin'
import guardInternal from './middleware/middleware-internal'
import guardMeeting from './middleware/middleware-meeting'
import guardInventory from './middleware/middleware-inventory'
import guardInsightsBoard from './middleware/middleware-insights-board'
import guardAutomation from './middleware/middleware-automation'
import guardConfidenceScore from './middleware/middleware-confidence-score'
import guardGong from './middleware/middleware-gong-integration'
import { setupAppRouteFns } from './helpers/helper-setup-app-route-fns'

Vue.use(VueRouter);

const routes = [
    //  { path: '/playground', name: 'playground', meta: { label: 'Playground' }, component: lazyLoad('/Playground/Playground.vue') },
    { path: '/dashboard/', name: 'dashboard', component: lazyLoad('/Dashboard/Dashboard.vue'),
        children: [
            { path: 'columns', name: 'dashboard-columns', meta: { label: 'Dashboard' }, component: lazyLoad('/Dashboard/DashboardColumns.vue'), beforeEnter: guardInternal },
            { path: 'timeline', name: 'dashboard-timeline', meta: { label: 'Timeline', parent: { label: 'Dashboard', name: 'dashboard-columns' } }, component: lazyLoad('/Dashboard/DashboardTimeline.vue'), beforeEnter: guardInternal },
            { path: 'insights', name: 'dashboard-insights', meta: { label: 'Insights', parent: { label: 'Dashboard', name: 'dashboard-columns' }, showWorkspace: false }, component: lazyLoad('/Dashboard/DashboardInsights.vue'), beforeEnter: guardInsightsBoard,
                children: [
                    { path: '', component: lazyLoad('/Dashboard/DashboardInsights.vue') },
                    { path: ':view_id/view', name: 'dashboard-insights-type', meta: { label: 'Insights', parent: { label: 'Dashboard', name: 'dashboard-columns' }, showWorkspace: false }, component: lazyLoad('/Dashboard/DashboardInsightsType.vue'), beforeEnter: guardInsightsBoard }
                ]
            },
            { path: ':view_id/view/:tile_id/tile', name: 'dashboard-insights-tile-view', meta: { label: 'Tile View', parent: { label: 'Insights', name: 'dashboard-insights-type' }, showWorkspace: false }, component: lazyLoad('/Dashboard/DashboardInsightsTileView.vue'), beforeEnter: guardInsightsBoard },
        ]
    },
    {
        path: '/teams', name: 'teams', component: lazyLoad('/Teams/Teams.vue'), beforeEnter: guardAdmin,
        children: [
            { path: '', name: 'teams-enabled', meta: { label: 'Team Members', showWorkspace: false }, component: lazyLoad('/Teams/TeamsEnabled.vue'), beforeEnter: guardAdmin },
        ]
    },
    {
        path: '/external-members', name: 'external-members', component: lazyLoad('/ExternalMembers/ExternalMembers.vue'), beforeEnter: guardAdmin,
        children: [
            { path: '', name: 'external-members-enabled', meta: { label: 'External Members', showWorkspace: false }, component: lazyLoad('/ExternalMembers/ExternalMembersEnabled.vue'), beforeEnter: guardAdmin },
        ]
    },
    { path: '/customers', name: 'customers', meta: { label: 'Accounts', parent: { name: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Customers/CustomersList.vue'), beforeEnter: guardInternal },
    { path: '/customers/:id/contacts', name: 'customers-contacts', meta: { label: 'Contacts', parent: { label: 'Accounts', name: 'customers' }, showWorkspace: false }, component: lazyLoad('/Customers/CustomersContacts.vue'), beforeEnter: guardInternal },
    { path: '/customers/:id/notes', name: 'customers-notes', meta: { label: 'Notes', parent: { label: 'Accounts', name: 'customers' }, showWorkspace: false }, component: lazyLoad('/Customers/CustomersNotes.vue'), beforeEnter: guardInternal },
    { path: '/customers/:id/notes/:note_id', name: 'customers-notes-view', meta: { label: 'Note View', parent: { label: 'Notes', name: 'customers-notes' }, showWorkspace: false }, component: lazyLoad('/Customers/CustomersNotesView.vue'), beforeEnter: guardInternal },
    { path: '/partners', name: 'partners', meta: { label: 'Partners', parent: { name: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Partners/PartnersList.vue'), beforeEnter: guardInternal },
    { path: '/partners/:id/contacts', name: 'partners-contacts', meta: { label: 'Contacts', parent: { label: 'Partners', name: 'partners' }, showWorkspace: false }, component: lazyLoad('/Partners/PartnersContacts.vue'), beforeEnter: guardInternal },
    { path: '/projects', name: 'projects', meta: { label: 'Projects' }, component: lazyLoad('/Projects/ProjectsList.vue'), beforeEnter: guardInternal },
    { path: '/projects/add', name: 'projects-add', meta: { label: 'Create Project' }, component: lazyLoad('/Projects/ProjectsForm.vue'), beforeEnter: guardInternal },
    {
        path: '/projects/:id', component: lazyLoad('/ProjectsGlobal/ProjectsGlobal.vue'),
        children: [
            { path: '', name: 'project-view', redirect: 'overview', meta: { parent: 'projects' } },
            { path: 'overview', name: 'projects-overview', meta: { parent: 'projects', label: 'Overview', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalOverview.vue') },
            { path: 'status', name: 'projects-status', meta: { parent: 'projects', label: 'Status', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalStatus.vue') },
            { path: 'confidence-score', name: 'projects-confidence-score', meta: { parent: 'projects', label: 'Confidence Score' }, component: lazyLoad('/ProjectsConfidenceScore/ProjectConfidenceScore.vue'), beforeEnter: guardConfidenceScore},
            { path: 'fields-tags', name: 'projects-fields-tags', meta: { parent: 'projects', label: 'Fields & Tags', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalFieldsTags.vue') },
            { path: 'import-template', name: 'projects-import-template', meta: { parent: 'projects', label: 'Import Template', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalImportTemplate.vue') },
            { path: 'documents', name: 'projects-documents', meta: { parent: 'projects', label: 'Documents', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalDocs.vue') },
            { path: 'activities', name: 'projects-activities', meta: { parent: 'projects', label: 'Activities', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalActivities.vue') },
            { path: 'settings', name: 'projects-settings', meta: { parent: 'projects', label: 'Settings', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalSettings.vue') },
            { path: 'tasks', name: 'projects-tasks', meta: { parent: 'projects', label: 'Tasks', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalTasks.vue') },
            { path: 'tasks/import', name: 'projects-tasks-import', meta: { parent: 'projects', label: 'Task Import', }, component: lazyLoad('/Projects/ProjectsTasksImport.vue'), beforeEnter: guardInternal },
            { path: 'inventories', name: 'projects-inventories', meta: { parent: 'projects', label: 'Inventory', }, component: lazyLoad('/Projects/ProjectsInventories.vue'), beforeEnter: guardInventory },
            { path: 'tickets', name: 'projects-tickets', meta: { parent: 'projects', label: 'Tickets', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalTickets.vue') },
            { path: 'tickets/:ticket_id', name: 'projects-tickets-view', meta: { parent: 'projects', label: 'Ticket View', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalTicketsView.vue') },
            { path: 'usecases', name: 'projects-usecases', meta: { parent: 'projects', label: 'Scope', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalUsecases.vue') },
            { path: 'usecases/import', name: 'projects-usecases-import', meta: { parent: 'projects', label: 'Scope Import', }, component: lazyLoad('/ProjectsUsecases/ProjectsUsecasesImport.vue'), beforeEnter: guardInternal },
            { path: 'usecases/template/import', name: 'projects-usecases-template-import', meta: { parent: 'projects', label: 'Scopes Template Import', }, component: lazyLoad('/ProjectsUsecases/ProjectsTemplatesUsecasesImport.vue'), beforeEnter: guardInternal },
            { path: 'usecases/:usecase_id', name: 'projects-usecases-view', meta: { parent: 'projects', label: 'Scope View', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalUsecasesView.vue') },
            { path: 'meetings', name: 'projects-meetings', meta: { parent: 'projects', label: 'Meetings', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalMeetings.vue'), beforeEnter: guardMeeting },
            { path: 'notes', name: 'projects-notes', meta: { parent: 'projects', label: 'Notes', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalNotes.vue') },
            { path: 'notes/:note_id', name: 'projects-notes-view', meta: { parent: 'projects', label: 'Note View', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalNotesView.vue') },
            { path: 'links', name: 'projects-links', meta: { parent: 'projects', label: 'Links', }, component: lazyLoad('/ProjectsGlobal/ProjectsGlobalLinks.vue') },
            { path: 'strategies', name: 'projects-strategies', meta: { parent: 'projects', label: 'Startegy', }, component: lazyLoad('/Projects/ProjectsStrategies.vue'), beforeEnter: guardInternal },
            { path: 'collaborators', name: 'projects-collaborators', meta: { parent: 'projects', label: 'Collaborators', }, component: lazyLoad('/Projects/ProjectsCollaborators.vue'), beforeEnter: guardInternal },
            { path: 'document-generator', name: 'projects-document-generator', meta: { parent: 'projects', label: 'Reports', }, component: lazyLoad('/Projects/ProjectsReportTemplates.vue'), beforeEnter: guardInternal },
            {
                path: ':view', component: lazyLoad('/External/ExternalProjectsView/ExternalProjectsView.vue'),
                children: [
                    { path: '', name: 'external-projects-view', redirect: 'overview', meta: { parent: 'projects', view: 'external' } },
                    { path: 'overview', name: 'external-projects-overview', meta: { parent: 'projects', view: 'external' }, component: lazyLoad('/External/ExternalProjectsView/ExternalProjectsViewOverview.vue') },
                    { path: 'tasks', name: 'external-projects-tasks', meta: { parent: 'projects', view: 'external' }, component: lazyLoad('/External/ExternalProjectsView/ExternalProjectsViewTasks.vue') },
                    { path: 'tickets', name: 'external-projects-tickets', meta: { parent: 'projects', view: 'external' }, component: lazyLoad('/External/ExternalProjectsView/ExternalProjectsViewTickets.vue') },
                    { path: 'tickets/:ticket_id', name: 'external-projects-tickets-view', meta: { parent: 'projects', view: 'external' }, component: lazyLoad('/External/ExternalProjectsView/ExternalProjectsViewTicketsView.vue') },
                    { path: 'usecases', name: 'external-projects-usecases', meta: { parent: 'projects', view: 'external' }, component: lazyLoad('/External/ExternalProjectsView/ExternalProjectsViewUsecases.vue') },
                    { path: 'usecases/:usecase_id', name: 'external-projects-usecases-view', meta: { parent: 'projects', view: 'external' }, component: lazyLoad('/External/ExternalProjectsView/ExternalProjectsViewUsecasesView.vue') },
                    { path: 'notes', name: 'external-projects-notes', meta: { parent: 'projects', view: 'external' }, component: lazyLoad('/External/ExternalProjectsView/ExternalProjectsViewNotes.vue') },
                    { path: 'notes/:note_id', name: 'external-projects-notes-view', meta: { parent: 'projects', view: 'external' }, component: lazyLoad('/External/ExternalProjectsView/ExternalProjectsViewNotesView.vue') },
                    { path: 'documents', name: 'external-projects-documents', meta: { parent: 'projects', view: 'external' }, component: lazyLoad('/External/ExternalProjectsView/ExternalProjectsViewDocuments.vue') },
                    { path: 'links', name: 'external-projects-links', meta: { parent: 'projects', view: 'external' }, component: lazyLoad('/External/ExternalProjectsView/ExternalProjectsViewLinks.vue') },
                ]
            },
        ]
    },
    { path: '/tasks', name: 'tasks', meta: { label: 'Tasks' }, component: lazyLoad('/Tasks/TasksList.vue'), beforeEnter: guardInternal },
    { path: '/meetings', name: 'meetings', meta: { label: 'Meetings' }, component: lazyLoad('/Meetings/Meetings.vue'), beforeEnter: guardMeeting },
    { path: '/notes', name: 'notes', meta: { label: 'Notes' }, component: lazyLoad('/Notes/NotesList.vue'), beforeEnter: guardInternal },
    { path: '/reports', name: 'reports', meta: { label: 'Project Reports' }, component: lazyLoad('/Reports/Reports.vue'), beforeEnter: guardInternal },
    { path: '/resource-planning', name: 'resource-planning', meta: { label: 'Resource Planning' }, component: lazyLoad('/Reports/ResourcePlanning.vue'), beforeEnter: guardInternal },
    { path: '/reports-usecases', name: 'reports-usecases', meta: { label: 'Use Case Reports' }, component: lazyLoad('/Reports/ReportsUsecases.vue'), beforeEnter: guardInternal },
    { path: '/insights', name: 'insights', meta: { label: 'Insights' }, component: lazyLoad('/Reports/ReportsInsights.vue'), beforeEnter: guardInternal },
    { path: '/tickets', name: 'tickets', meta: { label: 'Tickets' }, component: lazyLoad('/Tickets/Tickets.vue'), beforeEnter: guardInternal },
    { path: '/tickets/:id', name: 'tickets-view', meta: { label: 'Ticket View', parent: { label: 'Tickets', name: 'tickets' } }, component: lazyLoad('/Tickets/TicketsView.vue'), beforeEnter: guardInternal },
    { path: '/inventory', name: 'inventory', meta: { label: 'Inventory' }, component: lazyLoad('/Inventories/Inventories.vue'), beforeEnter: guardInventory },
    { path: '/inventory/add', name: 'inventory-add', meta: { label: 'Add', parent: { label: 'Inventory', name: 'inventory' } }, component: lazyLoad('/Inventories/InventoriesForm.vue'), beforeEnter: guardInventory },
    { path: '/inventory/book/:id?', name: 'inventory-book', meta: { label: 'Book', parent: { label: 'Inventory', name: 'inventory' } }, component: lazyLoad('/Inventories/InventoriesBook.vue'), beforeEnter: guardInventory },
    { path: '/inventory/bookings', name: 'booked-inventory', meta: { label: 'Booking Status', parent: { label: 'Inventory', name: 'inventory' } }, component: lazyLoad('/Inventories/InventoriesBooked.vue'), beforeEnter: guardInventory },
    { path: '/inventory/booking/:id/extend', name: 'booking-extend', meta: { label: 'Manage Bookings', parent: { label: 'Inventory', name: 'inventory' } }, component: lazyLoad('/Inventories/InventoriesExtend.vue'), beforeEnter: guardInventory },
    { path: '/inventory/trash', name: 'inventory-trash', meta: { label: 'Trash', parent: { label: 'Inventory', name: 'inventory' } }, component: lazyLoad('/Inventories/InventoriesTrash.vue'), beforeEnter: guardInventory },
    { path: '/inventory/imports', name: 'inventory-import', meta: { label: 'Import', parent: { label: 'Inventory', name: 'inventory' } }, component: lazyLoad('/Inventories/InventoriesImport.vue'), beforeEnter: guardInventory },
    { path: '/appstore', name: 'appstore', meta: { label: 'App Store', showWorkspace: false }, component: lazyLoad('/Appstore/Appstore.vue'), beforeEnter: guardInternal },
    {
        path: '/appstore/webhook/:id', component: lazyLoad('/Webhooks/Webhooks.vue'), beforeEnter: guardInternal,
        children: [
            { path: '', name: 'appstore-webhook', redirect: 'workflows' },
            { path: 'workflows', name: 'appstore-webhook-workflows', meta: { label: 'Webhook Workflows', parent: { label: 'Appstore', name: 'appstore' }, showWorkspace: false }, component: lazyLoad('/Webhooks/WebhooksWorkflows.vue'), beforeEnter: guardInternal },
            { path: 'logs', name: 'appstore-webhook-logs', meta: { label: 'Logs', parent: { label: 'Webhook Workflows', name: 'appstore-webhook-workflows' }, showWorkspace: false }, component: lazyLoad('/Webhooks/WebhooksLogs.vue'), beforeEnter: guardInternal },
            { path: 'settings', name: 'appstore-webhook-settings', meta: { label: 'Settings', parent: { label: 'Webhook Workflows', name: 'appstore-webhook-workflows' }, showWorkspace: false }, component: lazyLoad('/Webhooks/WebhooksSettings.vue'), beforeEnter: guardInternal },
            { path: 'workflows/:workflow_id/create', name: 'appstore-webhook-workflows-create', component: lazyLoad('/Webhooks/WebhooksWorkflowsCreate.vue'), meta: { label: 'Create', parent: { label: 'Webhook Workflows', name: 'appstore-webhook-workflows' }, showWorkspace: false }, beforeEnter: guardInternal },
            { path: 'workflows/:workflow_id/edit', name: 'appstore-webhook-workflows-edit', component: lazyLoad('/Webhooks/WebhooksWorkflowsEdit.vue'), meta: { label: 'Edit', parent: { label: 'Webhook Workflows', name: 'appstore-webhook-workflows' }, showWorkspace: false }, beforeEnter: guardInternal },
        ]
    },
    {
        path: '/appstore/salesforce/:id', component: lazyLoad('/Salesforce/Salesforce.vue'), beforeEnter: guardInternal,
        children: [
            { path: '', name: 'appstore-salesforce', redirect: 'workflows' },
            { path: 'workflows', name: 'appstore-salesforce-workflows', meta: { label: 'Salesforce Workflows', parent: { label: 'Appstore', name: 'appstore' }, showWorkspace: false }, component: lazyLoad('/Salesforce/SalesforceWorkflows.vue'), beforeEnter: guardInternal },
            { path: 'workflows/:workflow_id/create', name: 'appstore-salesforce-workflows-create', component: lazyLoad('/Salesforce/SalesforceWorkflowsCreate.vue'), meta: { label: 'Create', parent: { label: 'Salesforce Workflows', name: 'appstore-salesforce-workflows' }, showWorkspace: false }, beforeEnter: guardInternal },
            { path: 'workflows/:workflow_id/edit', name: 'appstore-salesforce-workflows-edit', component: lazyLoad('/Salesforce/SalesforceWorkflowsEdit.vue'), meta: { label: 'Edit', parent: { label: 'Salesforce Workflows', name: 'appstore-salesforce-workflows' }, showWorkspace: false }, beforeEnter: guardInternal },
            { path: 'logs', name: 'appstore-salesforce-logs', meta: { label: 'Logs', parent: { label: 'Salesforce Workflows', name: 'appstore-salesforce-workflows' }, showWorkspace: false }, component: lazyLoad('/Salesforce/SalesforceLogs.vue'), beforeEnter: guardInternal },
            { path: 'accounts', name: 'appstore-salesforce-accounts', meta: { label: 'Accounts', parent: { label: 'Salesforce Workflows', name: 'appstore-salesforce-workflows' }, showWorkspace: false }, component: lazyLoad('/Salesforce/SalesforceAccounts.vue'), beforeEnter: guardInternal },
        ]
    },
    {
        path: '/appstore/gong', component: lazyLoad('/Gong/Gong.vue'), beforeEnter: guardGong,
        children: [
            { path: '', name: 'appstore-gong', redirect: 'settings' },
            { path: 'settings', name: 'appstore-gong-settings', meta: { label: 'Gong Settings', parent: { label: 'Appstore', name: 'appstore' }, showWorkspace: false }, component: lazyLoad('/Gong/GongSettings.vue'), beforeEnter: guardGong },
            { path: 'accounts', name: 'appstore-gong-accounts', meta: { label: 'Gong Accounts', parent: { label: 'Appstore', name: 'appstore' }, showWorkspace: false }, component: lazyLoad('/Gong/GongAccounts.vue'), beforeEnter: guardGong },
        ]
    },
    {
        path: '/appstore/salesforce-micro-services', component: lazyLoad('/SalesforceMicroServices/SalesforceMicroServices.vue'), beforeEnter: guardInternal,
        children: [
            { path: '', name: 'appstore-salesforce-microservices', redirect: 'workflows' },
            { path: 'workflows', name: 'appstore-salesforce-microservices-workflows', meta: { label: 'Salesforce Micro Service Workflows', parent: { label: 'Appstore', name: 'appstore' }, showWorkspace: false }, component: lazyLoad('/SalesforceMicroServices/SalesforceMicroServicesWorkflow.vue'), beforeEnter: guardInternal },
            { path: 'workflows/:workflow_id/show', name: 'appstore-salesforce-microservices-workflows-edit', meta: { label: 'Edit', parent: { label: 'Salesforce Micro Service Workflows', name: 'appstore-salesforce-microservices-workflows' }, showWorkspace: false }, component: lazyLoad('/SalesforceMicroServices/SalesforceMicroServicesView.vue'), beforeEnter: guardInternal },
            { path: 'logs', name: 'appstore-salesforce-microservices-logs', meta: { label: 'Logs', parent: { label: 'Salesforce Micro Service Workflows', name: 'appstore-salesforce-microservices-workflows' }, showWorkspace: false }, component: lazyLoad('/SalesforceMicroServices/SalesforceMicroServicesLog.vue'), beforeEnter: guardInternal },
            { path: 'accounts', name: 'appstore-salesforce-microservices-accounts', meta: { label: 'Account', parent: { label: 'Salesforce Micro Service Workflows', name: 'appstore-salesforce-microservices-workflows' }, showWorkspace: false }, component: lazyLoad('/SalesforceMicroServices/SalesforceMicroServicesAccounts.vue'), beforeEnter: guardInternal },
        ]
    },
    {
        path: '/appstore/hubspot/:id', component: lazyLoad('/Hubspot/Hubspot.vue'), beforeEnter: guardInternal,
        children: [
            { path: '', name: 'appstore-hubspot', redirect: 'workflows' },
            { path: 'workflows', name: 'appstore-hubspot-workflows', meta: { label: 'Hubspot Workflows', parent: { label: 'Appstore', name: 'appstore' }, showWorkspace: false }, component: lazyLoad('/Hubspot/HubspotWorkflows.vue'), beforeEnter: guardInternal },
            { path: 'workflows/:workflow_id/create', name: 'appstore-hubspot-workflows-create', component: lazyLoad('/Hubspot/HubspotWorkflowsCreate.vue'), meta: { label: 'Create', parent: { label: 'Hubspot Workflows', name: 'appstore-hubspot-workflows' }, showWorkspace: false }, beforeEnter: guardInternal },
            { path: 'workflows/:workflow_id/edit', name: 'appstore-hubspot-workflows-edit', component: lazyLoad('/Hubspot/HubspotWorkflowsEdit.vue'), meta: { label: 'Edit', parent: { label: 'Hubspot Workflows', name: 'appstore-hubspot-workflows' }, showWorkspace: false }, beforeEnter: guardInternal },
            { path: 'logs', name: 'appstore-hubspot-logs', meta: { label: 'Logs', parent: { label: 'Hubspot Workflows', name: 'appstore-hubspot-workflows' }, showWorkspace: false }, component: lazyLoad('/Hubspot/HubspotLogs.vue'), beforeEnter: guardInternal },
            { path: 'accounts', name: 'appstore-hubspot-accounts', meta: { label: 'Accounts', parent: { label: 'Hubspot Workflows', name: 'appstore-hubspot-workflows' }, showWorkspace: false }, component: lazyLoad('/Hubspot/HubspotAccounts.vue'), beforeEnter: guardInternal },
        ]
    },
    { path: '/appstore/jira/:id', name: 'appstore-jira', component: lazyLoad('/Jira/Jira.vue'), beforeEnter: guardInternal,
        children: [
            { path: '', redirect: 'workflows' },
            { path: 'workflows', name: 'appstore-jira-workflows', meta: { label: 'Jira Workflows', parent: { label: 'Appstore', name: 'appstore' }, showWorkspace: false }, component: lazyLoad('/Jira/JiraWorkflows.vue'), beforeEnter: guardInternal },
            { path: 'workflows/:workflow_id/create', name: 'appstore-jira-workflows-create', component: lazyLoad('/Jira/JiraWorkflowsCreate.vue'), meta: { label: 'Create', parent: { label: 'Jira Workflows', name: 'appstore-jira-workflows' }, showWorkspace: false }, beforeEnter: guardInternal },
            { path: 'workflows/:workflow_id/edit', name: 'appstore-jira-workflows-edit', component: lazyLoad('/Jira/JiraWorkflowsEdit.vue'), meta: { label: 'Edit', parent: { label: 'Jira Workflows', name: 'appstore-jira-workflows' }, showWorkspace: false }, beforeEnter: guardInternal },
            { path: 'logs', name: 'appstore-jira-logs', meta: { label: 'Logs', parent: { label: 'Jira Workflows', name: 'appstore-jira-workflows' }, showWorkspace: false }, component: lazyLoad('/Jira/JiraLogs.vue'), beforeEnter: guardInternal },
            { path: 'accounts', name: 'appstore-jira-accounts', meta: { label: 'Accounts', parent: { label: 'Jira Workflows', name: 'appstore-jira-workflows' }, showWorkspace: false }, component: lazyLoad('/Jira/JiraAccounts.vue'), beforeEnter: guardInternal },
        ]
    },
    { path: '/settings', name: 'settings', meta: { label: 'Settings', showWorkspace: false }, component: lazyLoad('/Settings/Settings.vue'), beforeEnter: guardInternal },
    { path: '/settings/authentication-log', name: 'settings-authentication-log', meta: { label: 'Authentication Logs', parent: { label: 'Settings', 'name': 'settings' }, showWorkspace: false }, component: lazyLoad('/AuthLogs/AuthLogs.vue'), beforeEnter: guardAdmin },
    { path: '/settings/organization', name: 'settings-organization', meta: { label: 'Organization', parent: { label: 'Settings', 'name': 'settings' }, showWorkspace: false }, component: lazyLoad('/Organizations/OrganizationsForm.vue'), beforeEnter: guardAdmin },
    { path: '/settings/automation-validations/:type?/logs',  name:'settings-automation-validations-logs', meta: { label: 'Logs', parent: { label: ':type?', name: ':type?' }, showWorkspace: false }, component: lazyLoad('/AutomationValidationLog/AutomationValidationLogs.vue'), beforeEnter: guardAutomation},
    {
        path: '/settings/automations', meta: { showWorkspace: false }, component: lazyLoad('/Automation/AutomationView.vue'), beforeEnter: guardAutomation,
        children: [
            { path: '', name: 'settings-automations', meta: { label: 'Automation', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Automation/Automation.vue') },
        ]
    },
    {
        path: '/settings/validations', component: lazyLoad('/Validation/ValidationView.vue'), beforeEnter: guardAutomation,
        children: [
            { path: '', name: 'settings-validations', meta: { label: 'Validation', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Validation/Validations.vue') },
        ]
    },
    { path: '/settings/automations/:id/edit', name: 'settings-automations-edit', meta: { label: 'Edit', parent: { label: 'Automation', name: 'settings-automations' }, showWorkspace: false }, component: lazyLoad('/Automation/AutomationEdit.vue'), beforeEnter: guardAutomation,},
    { path: '/settings/validations/:id/edit', name: 'settings-validations-edit', meta: { label: 'Edit', parent: { label: 'Validation', name: 'settings-validations' }, showWorkspace: false }, component: lazyLoad('/Validation/ValidationEdit.vue'), beforeEnter: guardAutomation,},
    { path: '/settings/profile', name: 'settings-profile', meta: { label: 'Profile', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Profiles/ProfilesForm.vue') },
    { path: '/settings/preferences', name: 'settings-preferences',  meta: { label: 'Preferences', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Preferences/Preferences.vue'), beforeEnter: guardAdmin },
    { path: '/settings/notification-preferences', name: 'settings-notification-preferences',  meta: { label: 'Notification Preferences', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/NotificationPreferences/NotificationPreferences.vue'), beforeEnter: guardAdmin },
    { path: '/settings/roles', name: 'settings-roles', meta: { label: 'Roles', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Roles/Roles.vue'), beforeEnter: guardAdmin },
    { path: '/settings/roles/:id', name: 'settings-roles-view', meta: { label: 'View', parent: { label: 'Roles', name: 'settings-roles' }, showWorkspace: false }, component: lazyLoad('/Roles/RolesView.vue'), beforeEnter: guardAdmin },
    { path: '/settings/workspace', name: 'settings-workspace', meta: { label: 'Workspaces', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Workspace/Workspace.vue'), beforeEnter: guardInternal },
    {
        path: '/settings/workspace/:id', component: lazyLoad('/Workspace/WorkspaceView.vue'), beforeEnter: guardInternal,
        children: [
            { path: '', name: 'settings-workspace-view', redirect: 'stages' },
            { path: 'stages', name: 'settings-workspace-stages', meta: { label: 'Stages', parent: { label: 'Workspaces', name: 'settings-workspace' }, showWorkspace: false }, component: lazyLoad('/Workspace/WorkspaceStages.vue'), beforeEnter: guardInternal },
            { path: 'members', name: 'settings-workspace-members', meta: { label: 'Members', parent: { label: 'Workspaces', name: 'settings-workspace' }, showWorkspace: false }, component: lazyLoad('/Workspace/WorkspaceMembers.vue'), beforeEnter: guardInternal },
        ]
    },
    { path: '/settings/competitors', name: 'settings-competitors', meta: { label: 'Competitors', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Competitors/CompetitorsList.vue'), beforeEnter: guardInternal },
    {
        path: '/settings/custom-fields/:id', component: lazyLoad('/CustomFields/CustomFields.vue'), beforeEnter: guardInternal,
        children: [
            { path: '', name: 'settings-custom-fields', redirect: '/CustomFields/CustomFields.vue' },
            { path: 'list', name: 'settings-custom-fields-list',  meta: { label: 'Custom Fields', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/CustomFields/CustomFieldsList.vue'), beforeEnter: guardInternal },
        ],
    },
    {
        path: '/settings/metas', name: 'settings-metas', meta: { label: 'Pick List', parent: { label: 'Settings', name: 'settings' } }, component: lazyLoad('/Metas/Metas.vue'), beforeEnter: guardInternal,
        children: [
            // { path: '', name: 'settings-metas', redirect: 'settings-metas-testcases', meta: { label: 'Picklist', parent: { label: 'Settings', name: 'settings' } } },
            { path: '/settings/metas/testcases', name: 'settings-metas-testcases', meta: { label: 'Test Case Statuses', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Metas/MetasTestcases.vue') },
            { path: '/settings/metas/goals', name: 'settings-metas-goals', meta: { label: 'Success Criteria Statuses', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Metas/MetasGoals.vue') },
            { path: '/settings/metas/results', name: 'settings-metas-results', meta: { label: 'Project Results', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Metas/MetasResults.vue') },
            { path: '/settings/metas/comments', name: 'settings-metas-comments', meta: { label: 'Comment Types', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Metas/MetasComments.vue') },
            { path: '/settings/metas/notes', name: 'settings-metas-notes', meta: { label: 'Note Types', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Metas/MetasNotes.vue') },
            { path: '/settings/metas/collaborators', name: 'settings-metas-collaborators', meta: { label: 'Collaborator Types', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Metas/MetasCollaborators.vue') },
            { path: '/settings/metas/ticket/type', name: 'settings-metas-ticket-type', meta: { label: 'Ticket Types', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Metas/MetaTicketType.vue') },
            { path: '/settings/metas/ticket/status', name: 'settings-metas-ticket-status', meta: { label: 'Ticket Statuses', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Metas/MetaTicketStatus.vue') },
            { path: '/settings/metas/ticket/priority', name: 'settings-metas-ticket-priority', meta: { label: 'Ticket Priority', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Metas/MetaTicketPriority.vue') },
            { path: '/settings/metas/tasks', name: 'settings-metas-tasks', meta: { label: 'Task Statuses', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Metas/MetasTask.vue') },
            { path: '/settings/metas/competitor/type', name: 'settings-metas-competitor-type', meta: { label: 'Competitor Types', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Metas/MetasCompetitors.vue') },
        ]
    },
    { path: '/settings/billing', component: lazyLoad('/Billing/Billing.vue'),
        children: [
            { path: '', name: 'settings-billing', meta: { label: 'Billing', parent: { label: 'Settings', name: 'settings' } }, redirect: '/settings/billing/overview' },
            { path: '/settings/billing/overview', name: 'settings-billing-overview', meta: { label: 'Billing - Overview', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Billing/BillingOverview.vue') },
            { path: '/settings/billing/usage', name: 'settings-billing-usage', meta: { label: 'Billing - Usage', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Billing/BillingUsage.vue') },
            { path: '/settings/billing/plans', name: 'settings-billing-plans', meta: { label: 'Billing - Plans', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Billing/BillingPlans.vue') },
        ]
    },
    { path: '/settings/sso', name: 'settings-sso', meta: { label: 'SSO', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/SSO/SSO.vue'), beforeEnter: guardAdmin },
    { path: '/settings/sso/connect', name: 'settings-sso-connect', meta: { label: 'Connect SSO', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/SSO/SSOConnect.vue'), beforeEnter: guardAdmin },
    { path: '/settings/metas/:id/results/reasons', name: 'settings-metas-result-reasons', meta: { label: 'Reasons', parent: { label: 'Project Results', name: 'settings-metas-results' }, showWorkspace: false }, component: lazyLoad('/Metas/MetasResultsReasons.vue'), beforeEnter: guardInternal },
    { path: '/settings/inventory', name: 'settings-inventory', meta: { label: 'Inventory', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Inventories/InventorySettings.vue'), beforeEnter: guardInventory },
    { path: '/settings/metas/inventory-categories', name: 'settings-metas-inventory-categories', meta: { label: 'Inventory Settings', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Metas/MetasInventoryCategories.vue'), beforeEnter: guardInventory },
    { path: '/settings/metas/inventory-statuses', name: 'settings-metas-inventory-statuses', meta: { label: 'Inventory Statuses', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Metas/MetasInventoryStatuses.vue'), beforeEnter: guardInventory },
    { path: '/settings/metas/inventory-tags', name: 'settings-metas-inventory-tags', meta: { label: 'Inventory Tags', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Inventories/InventoriesTags.vue'), beforeEnter: guardInventory },
    { path: '/settings/metas/inventory-stages', name: 'settings-metas-inventory-stages', meta: { label: 'Inventory Stages', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Inventories/InventoriesStages.vue'), beforeEnter: guardInventory },
    { path: '/settings/tokens', name: 'settings-tokens', meta: { label: 'Developer API', breadcrumbLabel: 'Tokens', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Tokens/Tokens.vue'), beforeEnter: guardInternal },
    { path: '/settings/tokens/documentation', name: 'settings-tokens-documentation', meta: { label: 'Documentation', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Tokens/TokensDocumentation.vue'), beforeEnter: guardInternal },
    { path: '/settings/document-generator', name: 'settings-document-generator', meta: { label: 'Document Generator', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/ReportBuilder/ReportBuilderList.vue'), beforeEnter: guardInternal },
    { path: '/settings/document-generator/:template_id', name: 'settings-document-generator-view', meta: { label: 'Document View', parent: { label: 'Document Generator', name: 'settings-document-generator' }, showWorkspace: false }, component: lazyLoad('/ReportBuilder/ReportBuilderView.vue'), beforeEnter: guardInternal },
    {
        path: '/settings/tags/:id', component: lazyLoad('/Tags/Tags.vue'), beforeEnter: guardInternal,
        children: [
            { path: '', name: 'settings-tags', redirect: '/tags/Tags.vue' },
            { path: 'list', name: 'settings-tags-list', meta: { label: 'Tags', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Tags/TagsList.vue'), beforeEnter: guardInternal },
        ],
        meta: { slug: 'tags' }
    },
    {
        path: '/templates/:id?', name: 'master-templates', meta: { label: 'Templates', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/MasterTemplates/MasterTemplates.vue'),
        children: [
            { path: '', redirect: '/MasterTemplates/MasterTemplates.vue' },
            { path: 'system-list', name: 'master-templates-system-list', meta: { label: 'Templates', showWorkspace: false }, component: lazyLoad('/SystemTemplates/SystemTemplatesList.vue'),
                children: [
                    { path: 'usecases', name: 'master-templates-system-list-usecases', meta: { label: 'Templates', breadcrumbLabel: 'Templates - Scope', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/SystemTemplates/SystemTemplatesUsecases.vue') },
                    { path: 'usecases/:system_usecase_id/:master_template_id?', name: 'master-templates-system-list-usecases-view', meta: { label: 'Detail View', parent: { label: 'Templates - Scope', name: 'master-templates-system-list-usecases' }, showWorkspace: false }, component: lazyLoad('/SystemTemplates/SystemTemplatesUsecasesView.vue'), beforeEnter: guardInternal },
                    { path: 'tasks/:system_group_id/:master_template_id?', name: 'master-templates-system-list-task-view', meta: { label: 'Detail View', parent: { label: 'Templates - Tasks', name: 'master-templates-system-list-tasks' }, showWorkspace: false }, component: lazyLoad('/SystemTemplates/SystemTemplatesTaskView.vue'), beforeEnter: guardInternal },
                    { path: 'tasks', name: 'master-templates-system-list-tasks', meta: { label: 'Templates - Tasks', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/SystemTemplates/SystemTemplatesTasks.vue') },
                    { path: 'links/:group_id?', name: 'master-templates-system-list-links', meta: { label: 'Templates - Links', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/SystemTemplates/SystemTemplatesLinks.vue') },
                    { path: 'documents/:group_id?', name: 'master-templates-system-list-documents', meta: { label: 'Templates - Documents', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/SystemTemplates/SystemTemplatesDocuments.vue') },
                    { path: 'notes', name: 'master-templates-system-list-notes', meta: { label: 'Templates - Notes', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/SystemTemplates/SystemTemplatesNotes.vue') },
                    { path: 'notes/:system_note_id/:master_template_id?', name: 'master-templates-system-list-notes-view', meta: { label: 'Detail View', parent: { label: 'Templates - Notes', name: 'master-templates-system-list-notes' }, showWorkspace: false }, component: lazyLoad('/SystemTemplates/SystemTemplatesNotesView.vue'), beforeEnter: guardInternal },
                ]
            },
            { path: 'list', name: 'master-templates-list', meta: { showWorkspace: false }, component: lazyLoad('/MasterTemplates/MasterTemplatesList.vue'),
                children: [
                    { path: 'usecases', name: 'master-templates-usecases', meta: { label: 'Master Templates - Scope', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/MasterTemplates/MasterTemplatesUsecases.vue') },
                    { path: 'tasks', name: 'master-templates-tasks', meta: { label: 'Master Templates - Tasks', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/MasterTemplates/MasterTemplatesTasks.vue') },
                    { path: 'links', name: 'master-templates-links', meta: { label: 'Master Templates - Links', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/MasterTemplates/MasterTemplatesLinks.vue') },
                    { path: 'documents', name: 'master-templates-documents', meta: { label: 'Master Templates - Documents', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/MasterTemplates/MasterTemplatesDocuments.vue') },
                    { path: 'notes', name: 'master-templates-notes', meta: { label: 'Master Templates - Notes', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/MasterTemplates/MasterTemplatesNotes.vue') },
                ]
            },
        ]
    },
    { path: '/territories', name: 'territories', meta: { label: 'Territories', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/Territories/Territories.vue'), beforeEnter: guardInternal },
    { path: '/settings/layout-designer', name: 'layout-designer', meta: { label: 'Layout Designer', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/LayoutDesigner/LayoutDesigner.vue'), beforeEnter: guardInternal },
    { path: '/settings/confidence-score', name: 'settings-confidence-score', meta: { label: 'Confidence Score', parent: { label: 'Settings', name: 'settings' }, showWorkspace: false }, component: lazyLoad('/ConfidenceScore/ConfidenceScore.vue'), beforeEnter: guardConfidenceScore },
    { path: '/privacy', name: 'privacy', component: lazyLoad('/Privacy/Privacy.vue'), beforeEnter: guardInternal },
    { path: '/support', name: 'support', meta: { label: 'Support', showWorkspace: false }, component: lazyLoad('/Support/Support.vue') },
    { path: '/login', meta: { public: true }, name: 'login', component: lazyLoad('/Login/Login.vue'), beforeEnter: guardAuthenticate },
    { path: '/login/validate-error', meta: { public: true }, name: 'login-validate-error', component: lazyLoad('/LoginValidate/LoginValidate.vue') },
    { path: '/logout', name: 'logout', component: lazyLoad('/Logout/Logout.vue'), beforeEnter: guardUserAuthenticated },
    { path: '/errors/unauthorized', name: 'errors-unauthorized', meta: { label: 'Unauthorized' }, component: lazyLoad('/Errors/ErrorsUnauthorized.vue') },
    { path: '/page-not-found', meta: { label: 'Page Not Found', public: true }, name: '404', component: lazyLoad('/Errors/Errors404.vue') },
    { path: '*', redirect: '/dashboard' }
]

const appRouter = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const router = setupAppRouteFns(appRouter)

export default router

<template>
    <a-main>
        <g-settings-breadcrumb class="mt-5" goto="settings-workspace" route-name="workspace"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="py-6 pt-2 px-0 mb-6">
            <a-layout wrap align-center class="mb-0" style="min-height: 100px">
                <a-flex shrink py-0>
                    <a-icon size="48" class="mb-4" color="neutral darken-2">apartment</a-icon>
                </a-flex>
                <a-flex shrink py-0 v-if="settings_workspace_mixin">
                    <h2 class="md-heading-5 primary--text">Workspace: {{ settings_workspace_mixin.title }} <a-chip small class="ml-1 my-0 grey lighten-2 font-weight-medium grey--text text--darken-2" v-if="settings_workspace_mixin.is_default === 1">Default</a-chip></h2>
                    <p class="body-2 grey--text text--darken-2 mt-3 mb-0 u-flex align-center">
                        <a-chip small outlined class="ma-0 font-weight-medium grey--text text--darken-2 u-flex align-center" v-if="settings_workspace_mixin.is_global === 1"><a-icon size="16" class="mr-1" color="orange lighten-1">lock_open</a-icon> Public</a-chip>
                        <a-chip small outlined class="ma-0 font-weight-medium grey--text text--darken-2 u-flex align-center" v-else><a-icon size="16" class="mr-1" color="grey darken-1">lock</a-icon> Private</a-chip>
                        <a-chip small outlined class="ml-1 my-0 font-weight-medium grey--text text--darken-2 u-flex align-center ml-2" v-if="settings_workspace_mixin.is_active === 1"><a-icon size="12" class="mr-1" color="green darken-1">fiber_manual_record</a-icon> Active</a-chip>
                        <a-chip small outlined class="ml-1 my-0 font-weight-medium grey--text text--darken-2 u-flex align-center ml-2" v-else><a-icon size="16" class="mr-1" color="grey darken-1">block</a-icon> Disabled</a-chip>
                    </p>
                </a-flex>
            </a-layout>
            <tab-workspace class="mb-0"></tab-workspace>
            <router-view></router-view>
        </a-container>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { TabWorkspace } from '@/config/config-tab-components'
import loadWorkspace from '@/mixins/mixin-workspace'

export default {
    mixins: [loadWorkspace],

    components: { TabWorkspace },

    mounted () {
        this.local_index()
    },

    methods: {
        async local_index () {
            await this.workspace_clear()
            await this.workspace_index()
        },

        ...mapActions('Workspace', {
            workspace_clear: 'clear',
            workspace_index: 'index',
            workspace_board: 'board',
        }),
    }
}
</script>

<style lang="scss" scoped>

</style>

import { storage } from '../../helpers/helper-local-storage';

const api_endpoint = '/workspaces';

export default {
    namespaced: true,
    state: {
        list: [],
        board_list: storage.get('validWorkspaces') || [],
        search_list: [],
        available_list: [],
        item: {},
        meta: {},
        current: null,
        filters: {
            is_active: null,
        },
        response: [],
        defaults: {
            list: [],
            search_list: [],
            available_list: [],
            item: {},
            meta: {},
            filters: {
                is_active: null,
            },
            response: [],
        },
        form_mode: 'add',
        update_workspace_routes: false,
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_BOARD_LIST(state, items) {
            state.board_list = items;
        },
        UPDATE_SEARCH_LIST(state, items) {
            state.search_list = items;
        },
        UPDATE_AVAILABLE_LIST(state, items) {
            state.available_list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload.item;
        },
        UPDATE_ITEM_ACTIVE(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index].is_active = payload.state;
        },
        UPDATE_ITEM_DEFAULT(state, payload) {
            let old_default_index = _.findIndex(state.list, { 'is_default': 1 });
            state.list[old_default_index].is_default = 0;

            let new_default_index = _.findIndex(state.list, { 'id': payload.id });
            state.list[new_default_index].is_default = 1;
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        PREPEND_ITEM(state, item) {
            state.list.unshift(item);
        },
        ADD_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REMOVE_STAGE_ITEM(state, item) {
           let workspace_index = _.findIndex(state.list, { 'id': item.workspace_id });
           let old_workspace_stage = state.list[workspace_index].stage
           let stage_index = _.findIndex(old_workspace_stage, { 'id': item.stage_id });
           state.list[workspace_index].stage.splice(stage_index, 1);
        },
        ADD_STAGE_ITEM(state, item) {
            let workspace_index = _.findIndex(state.list, { 'id': item.id });
            state.list[workspace_index].stage.push(item.data);
        },
        UPDATE_STAGE_ITEM(state, item) {
            state.item = item;
        },
        STAGE_REFRESH_LIST(state, item) {
            let workspace_index = _.findIndex(state.list, { 'id': item.id });
            state.list[workspace_index].stage = item.data
        },
        UPDATE_STAGE_LIST_ITEM(state, item) {
            let workspace_index = _.findIndex(state.list, { 'id': item.id });
            let old_workspace_stage = state.list[workspace_index].stage
            let stage_index = _.findIndex(old_workspace_stage, { 'id': item.data.id });
            state.list[workspace_index].stage[stage_index] = Object.assign({}, item.data)
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        REFRESH_ITEM(state) {
            let item_temp = _.cloneDeep(state.item);
            state.item = [];
            state.item = item_temp;
        },
        UPDATE_CURRENT_WORKSPACE(state, payload) {
            state.current = payload
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
        UPDATE_WORKSPACE_ROUTE(state, payload) {
            state.update_workspace_routes = payload;
        }
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            let query_filters = _.pickBy(context.state.filters, function (item) {
                return item != '';
            });
            return axios.get(api_endpoint, { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        board(context, payload) {
            this.dispatch('Interface/loader_start')
            const params = (payload && payload.params) ?? {}
            params['filter[board]'] = true
            return axios.get(api_endpoint, { params })
                .then((response) => {
                    if (payload && payload.mode === 'only-search') context.commit('UPDATE_SEARCH_LIST', response.data.data)
                    else if (payload && payload.mode === 'only-available') context.commit('UPDATE_AVAILABLE_LIST', response.data.data)
                    else {
                        context.commit('UPDATE_BOARD_LIST', response.data.data)
                        storage.save('validWorkspaces', response.data.data)
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id, { params: { 'include': 'workspaceMembersCount' } })
                .then((response) => {
                    if (payload.new) {
                        context.commit('PREPEND_ITEM', response.data.data);
                    } else {
                        context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                        context.commit('UPDATE_ITEM', response.data.data);
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        active(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/states/is-active', payload)
                .then((response) => {
                    context.commit('UPDATE_ITEM_ACTIVE', payload);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        default(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/states/is-default', payload)
                .then((response) => {
                    context.commit('UPDATE_ITEM_DEFAULT', payload);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        disable(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/disable')
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, payload)
                .then((response) => {
                    context.dispatch('show', { ...payload, new: true })
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + context.state.item.id, context.state.item)
                .then((response) => {
                    context.dispatch('show', { id: context.state.item.id })
                    // context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    // context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        visibility(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/states/is-global', payload)
                .then((response) => {
                    // context.commit('UPDATE_LIST_ITEM', payload);
                    context.dispatch('show', { id: payload.id })
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        switch_workspace(context, payload) {
            context.commit('UPDATE_CURRENT_WORKSPACE', payload.id)
            // localStorage.setItem('workspace_id', payload.id)
            // this.dispatch('Interface/loader_start')
            // return axios.patch(api_endpoint + '/' + payload.id + '/default')
            //     .then((response) => {
            //         this.dispatch('Interface/loader_stop')
            //         context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            //     })
            //     .catch((error) => {
            //         this.dispatch('Interface/loader_stop')
            //         context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            //     });
        },
        stage_destroy(context, payload) {
            context.commit('REMOVE_STAGE_ITEM', payload);
        },
        stage_store(context, payload) {
            context.commit('ADD_STAGE_ITEM',payload)
        },
        stage_select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let workspace_index = _.findIndex(context.state.list, { 'id': payload.workspace_id });
                let workspace_stages = context.state.list[workspace_index].stage
                let stage_index = _.findIndex(workspace_stages, { 'id': payload.stage_id });
                context.commit('UPDATE_STAGE_ITEM', _.cloneDeep(workspace_stages[stage_index]));
                resolve('Selected');
            });
        },
        stage_update(context, payload) {
            context.commit('UPDATE_STAGE_LIST_ITEM', payload);
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        stage_reorder(context, payload) {
           context.commit('STAGE_REFRESH_LIST', payload);
        },
        clear_current_workspace(context) {
            context.commit('UPDATE_CURRENT_WORKSPACE', null)
        },
        clear_search_list(context) {
            context.commit('UPDATE_SEARCH_LIST', [])
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        update_route(context, payload) {
            context.commit('UPDATE_WORKSPACE_ROUTE', payload);
        }
    },
    getters: {
        list: (state) => {
            const data = [...state.list]
            return data
        },
        board_list: (state) => {
            const data = Array.isArray(state.board_list) ? [...state.board_list] : []
            return data
        },
        item: (state) => {
            return { ...state.item }
        },
        links: (state) => {
            return { ...state.links }
        },
        current: (state) => {
            return state.current
        },
        meta: (state) => {
            return { ...state.meta }
        },
        query: (state) => {
            return { ...state.query }
        },
        filters: (state) => {
            const data = { ...state.filters }
            return data
        },
        query_filters: (state) => {
            return { ...state.query_filters }
        },
        response: (state) => {
            return { ...state.response }
        },
        defaults: (state) => {
            return { ...state.defaults }
        },
        form_mode: (state) => state.form_mode,
        update_route: (state) => state.update_workspace_routes
    }
}

<template>
    <div class="u-flex-center-y">
        <slot name="fromItem" :item="modalState.get(notification.value, 'data.from')">
            <h4
                class="u-font-14 font-weight-medium u-tracking u-leading-20 grey--text text-truncate"
                style="max-width: 160px"
            >
                {{ fromValue }}
            </h4>
        </slot>
        <span v-if="showIcon" class="u-font-14 font-weight-medium u-leading-20 grey--text text--darken-1 mx-1">
            ->
        </span>
        <slot name="toItem" :item="modalState.get(notification.value, 'data.to')">
            <h4
                class="u-font-14 font-weight-medium u-tracking u-leading-20 grey--text text--darken-1 text-truncate"
                style="max-width: 160px"
            >
                {{ toValue }}
            </h4>
        </slot>
    </div>
</template>

<script>
export default {
    inject: ['notification', 'modalState'],

    props: {
        fromValue: {
            type: String || undefined
        },
        toValue: {
            type: String || undefined
        },
        showIcon: {
            type: Boolean,
            default: true
        },
    }
}
</script>

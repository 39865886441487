<template>
    <a-sheet
        height="86"
        class="u-rounded-corners u-flex-center-y px-6 py-4 mb-4 u-border-b"
    >
        <div class="flex flex-column" style="row-gap: 6px; max-width: 600px;">
            <loader-template width="250" height="14" class="u-rounded-corners-lg" />
            <div class="u-flex-center-y mt-4" style="column-gap: 6px;">
                <loader-template width="80" height="10" class="u-rounded-corners-lg" />
                <loader-template width="80" height="10" class="u-rounded-corners-lg" />
            </div>
        </div>
        <div class="flex flex-column" style="row-gap: 6px;">
            <loader-template width="250" height="14" class="u-rounded-corners-lg" />
            <div class="u-flex-center-y mt-4" style="column-gap: 6px;">
                <loader-template width="80" height="10" class="u-rounded-corners-lg" />
                <loader-template width="80" height="10" class="u-rounded-corners-lg" />
            </div>
        </div>
        <a-spacer></a-spacer>
        <loader-template width="120" height="10" class="u-rounded-corners-lg" />
    </a-sheet>
</template>

<script>
export default {
    props: {
        moduleType: {
            type: String,
            default: 'global'
        }
    }
}
</script>

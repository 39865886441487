<template>
    <v-menu v-model="showModel" v-bind="$attrs" :ref="ref">
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
    </v-menu>
</template>

<script>
import { VMenu } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VMenu },
    props: ['value'],

    data () {
        return {
            ref: null
        }
    },

    mounted () {
        this.ref = this.$parent.$children && this.$parent.$children[0] &&
                        this.$parent.$children[0].$children && this.$parent.$children[0].$children[0] ?
                        this.$parent.$children[0].$children[0] : null
    },

    computed: {
        showModel: {
            get () {
                return this.value
            },
            set (val) {
                this.$emit('input', val)
            }
        }
    }
}
</script>

<template>
    <a-card>
        <a-sheet class="u-flex py-6 px-8">
            <h2 class="body-4 font-weight-medium indigo--text text--darken-1">
                <a-icon class="mr-1 u-icon-nudge" color="indigo darken-1" size="35" style="top: -3px;">fact_check</a-icon>
                Create Validation
            </h2>
        </a-sheet>
        <a-divider class="grey lighten-3"></a-divider>
        <a-card-text class="pa-0" style="max-height: 700px;">
            <a-sheet class="py-6 px-8">
                <p class="grey--text text--darken-4 md-subtitle-1 mb-0 font-weight-medium">Create from Scratch...</p>
                <div class="u-wfull u-rounded-corners pa-6 mt-6 c-default-select">
                    <div v-if="createMode === null" @click="localCreateNewWorkflow('new')" class="u-cursor-pointer">
                        <div class="align-center justify-center u-flex">
                            <div class="c-create-icon align-center justify-center u-flex grey lighten-2">
                                <a-icon color="grey'" size="16">add_circle</a-icon>
                            </div>
                        </div>
                        <div>
                            <p class="grey--text text--darken-1 align-center justify-center u-flex md-subtitle-1 mb-0 u-font-semibold mt-3">Create from Scratch</p>
                            <p class="align-center justify-center u-flex md-caption grey--text mb-0 mt-3 font-weight-medium" style="font-size: 14px;">Choose a trigger, configure your conditions and setup the validations from scratch.</p>
                        </div>
                    </div>
                    <div v-else>
                        <div class="align-center justify-center u-flex">
                            <div class="c-create-icon align-center justify-center u-flex grey lighten-2">
                                <loader-template height="40px" width="40px" class="u-rounded-corners-full"></loader-template>
                            </div>
                        </div>
                        <div class="align-center justify-center u-flex mt-2">
                            <loader-template height="18px" width="300px"></loader-template>
                        </div>
                        <div class="align-center justify-center u-flex mt-2">
                            <loader-template height="18px" width="600px"></loader-template>
                        </div>
                    </div>
                </div>
                <p class="grey--text text--darken-4 md-subtitle-1 mb-0 font-weight-medium mt-6">Choose from Templates...</p>
                <div class="u-wfull mt-6" v-if="templateList && templateList.length">
                    <template v-for="(template) in templateList">
                        <div :key="template.id" v-if="template.id === templateId" class="u-wfull u-rounded-corners pa-5 px-4 u-flex mt-4 c-default-select">
                            <div class="u-flex-center-y u-rounded-corners">
                                <loader-template height="48px" width="48px" class="u-rounded-corners-lg"></loader-template>
                            </div>
                            <div class="ml-3 u-wfull">
                                <loader-template height="20" width="400px" class="u-rounded-corners-lg"></loader-template>
                                <loader-template height="16" width="520px" class="u-rounded-corners-lg mt-2"></loader-template>
                                <loader-template height="16" width="350px" class="u-rounded-corners-lg mt-2"></loader-template>
                            </div>
                        </div>
                        <div v-else :key="template.id" @click="localSelectTemplate(template.id)" class="u-cursor-pointer u-wfull u-rounded-corners pa-5 px-4 u-flex mt-4 c-default-select">
                            <div class="c-tigger-template-icon u-flex-center-y u-wfull u-rounded-corners px-3 grey lighten-2">
                                <a-icon color="grey" size="22">{{ triggerIcon(template.slug) }}</a-icon>
                            </div>
                            <div class="ml-3">
                                <p class="grey--text text--darken-1 md-subtitle-1 mb-0 u-font-semibold">{{ template.name }}</p>
                                <p class="md-caption grey--text mb-0 font-weight-medium mt-1" style="font-size: 14px;" :title="template.description">{{ template.description }}</p>
                            </div>
                        </div>
                    </template>
                </div>
            </a-sheet>
        </a-card-text>               
    </a-card>
</template>

<script>
export default {
    props: {
        templateId: {
            type: String,
            default: null,
        },
        templateList: {
            type: Array,
            default: [],
        },
        createMode: {
            type: String,
            default: null,
        },
        page: {
            type: String,
            default: null,
        },
        triggerIcon: {
            type: Function,
            default: {},
        },
    },

    methods: {
        localSelectTemplate (id) {
            this.$emit('import-template', id)
        },

        localCreateNewWorkflow (mode) {
            this.$emit('create-workflow', mode)
        },
    }
}
</script>

<style scoped>
.c-create-icon {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
}
.c-tigger-template-icon {
    width: 48px !important;
    height: 48px !important;
}
.c-active-select {
    border: 1px solid #3949AB !important;
    background: #FFFFFF !important;
}
.c-default-select {
    border: 1px solid #EEEEEE !important;
    background: #FAFAFA !important;
}
</style>
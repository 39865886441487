<template>
    <div>
        <a-card class="u-elevation-custom-1 u-rounded-corners">
            <a-card-title class="px-6">
                <a-layout align-center>
                    <a-flex shrink>
                        <h3 class="md-caption grey--text text--darken-1 text-uppercase font-weight-bold">Description</h3>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink>
                        <a-chip v-if="dataDebounceTimer" color="white" text-color="grey" class="my-0" small>Editing</a-chip>
                        <a-chip v-if="!dataDebounceTimer && dataAutoSaveAt" color="white" text-color="green" class="my-0 mr-2" small><g-moment :value="dataAutoSaveAt" input-format="YYYY-MM-DD HH:mm:ss" output-format="[Autosaved at] h:mma"></g-moment></a-chip>
                    </a-flex>
                    <a-flex shrink v-if="canUpdate">
                        <p class="md-caption grey--text mb-0 d-flex align-center">
                            <em class="mr-2">Select text for formating options</em>
                            <a-icon size="14" color="grey" class="ml-1">format_bold</a-icon>
                            <a-icon size="14" color="grey" class="ml-1">format_italic</a-icon>
                            <!-- <a-icon size="14" color="grey" class="ml-1">format_underlined</a-icon> -->
                        </p>
                    </a-flex>
                    <a-flex shrink class="pl-0">
                        <a-btn small icon text color="grey lighten-1" class="ma-0" @click="dataAccordExpand = !dataAccordExpand">
                            <a-icon size="16" v-if="dataAccordExpand">unfold_less</a-icon>
                            <a-icon size="16" v-else>unfold_more</a-icon>
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-card-title>
            <a-divider></a-divider>
            <a-card-text class="px-6 pt-4 pb-2" v-if="dataAccordExpand">
                <p class="grey--text text--darken-1" v-if="!content || content && content.replace(/<[^>]*>?/gm, '') === null || content && content.replace(/<[^>]*>?/gm, '') == ''" @click="() => { content = '<p> </p>'; dataEditorContent.focus() }">Click to edit the description</p>
                <editor-content v-once v-else :editor="dataEditorContent"></editor-content>
                <editor-menu-bubble v-if="canUpdate" :editor="dataEditorContent" :keep-in-bounds="false" v-slot="{ commands, isActive, menu }">
                    <div class="menububble" v-if="canUpdate" :class="{ 'is-active': menu.isActive }" :style="`left: ${menu.left - 16}px; bottom: ${menu.bottom + 8}px;`">
                        <a-btn icon text small class="mx-0" :color="(isActive.bold()) ? 'blue' : 'grey'" @click="commands.bold">
                            <a-icon size="16">format_bold</a-icon>
                        </a-btn>
                        <a-btn icon text small class="mx-0" :color="(isActive.italic()) ? 'blue' : 'grey'" @click="commands.italic">
                            <a-icon size="16">format_italic</a-icon>
                        </a-btn>
                        <!-- <a-btn icon text small class="mx-0" :color="(isActive.underline()) ? 'blue' : 'grey'" @click="commands.underline">
                            <a-icon size="16">format_underlined</a-icon>
                        </a-btn>
                        <a-btn icon text small class="mx-0" :color="(isActive.strike()) ? 'blue' : 'grey'" @click="commands.strike">
                            <a-icon size="16">strikethrough_s</a-icon>
                        </a-btn> -->
                        <a-divider vertical dark></a-divider>
                        <a-btn icon text small class="mx-0" :color="(isActive.bullet_list()) ? 'blue' : 'grey'" @click="commands.bullet_list">
                            <a-icon size="16">format_list_bulleted</a-icon>
                        </a-btn>
                        <a-btn icon text small class="mx-0" :color="(isActive.ordered_list()) ? 'blue' : 'grey'" @click="commands.ordered_list">
                            <a-icon size="16">format_list_numbered</a-icon>
                        </a-btn>
                    </div>
                </editor-menu-bubble>
            </a-card-text>
        </a-card>
        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="error">
            <a-icon size="16" color="red darken-2">warning</a-icon>
            {{ error }}
        </span>
    </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
    Bold,
    Italic,
    Underline,
    Strike,
    OrderedList,
    BulletList,
    ListItem,
    History,
} from 'tiptap-extensions'
import marked from 'marked'

export default {
    components: { EditorContent, EditorMenuBubble },

    props: {
        canUpdate: {
            type: Boolean,
            required: true
        },
        content: {
            type: String,
            required: true
        },
        error: {
            type: String | Array,
            default: ''
        },
    },

    mounted () {
        this.methodIndex()
    },

    beforeDestroy () {
        if (this.dataEditorContent) {
            this.dataEditorContent.destroy()
        }
    },

    watch: {
        content: {
            handler (val) {
                this.methodIndex()
            },
            deep: true
        }
    },

    data () {
        return {
            dataAccordExpand: true,
            dataEditorContent: null,
            dataAutoSaveAt: null,
            dataDebounceTimer: null,
            description: this.content
        }
    },

    methods: {
        methodIndex () {
            if (this.dataEditorContent) {
                this.dataEditorContent.destroy()
            }

            const content = this.description ?? ''
            this.dataEditorContent = new Editor({
                extensions: [
                    new Bold(),
                    new Italic(),
                    new Underline(),
                    new Strike(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new History(),
                ],
                content: marked(content, { sanitize: true }),
                onUpdate: this.methodUpdateDebounced,
                editable: this.canUpdate
            })
        },

        async methodUpdateDebounced (state) {
            this.methodClearDebounceTimer()
            this.description = turndownService.turndown(state.getHTML())
            this.dataDebounceTimer = setTimeout(() => {
               this.$emit('update', { description: this.description, clear_timer: this.methodClearDebounceTimer })
            }, 2000)
        },

        async methodClearDebounceTimer (saved = false) {
            if (this.dataDebounceTimer) {
                // clearTimeout(this.dataDebounceTimer)
                // this.dataDebounceTimer = null
                if (saved) this.dataAutoSaveAt = moment().format('YYYY-MM-DD HH:mm:ss')
            }
        },
    }
}
</script>

<style>

</style>

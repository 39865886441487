<template>
	<a-main class="mb-12">
        <div class="mb-6">
            <h3 class="text-h6 grey--text text--darken-3 font-weight-medium">Settings</h3>
        </div>

        <!-- Notification settings -->
        <div>
            <h3 class="mb-3 text-h6 indigo--text text--darken-2 font-weight-medium">Notifications</h3>
            <a-sheet class="u-rounded-corners-lg u-border">
                <div class="pa-6 u-flex-center-y">
                    <a-sheet width="40" height="40" class="u-rounded-corners-lg u-flex-center u-border">
                        <a-img :src="require('../../assets/images/slack-logo.svg')" width="24" height="24" contain></a-img>
                    </a-sheet>
                    <div class="ml-4">
                        <h3 class="md-subtitle-1 font-weight-medium grey--text text--darken-2">Slack</h3>
                        <p class="mb-0 md-body-2 grey--text text--darken-1">
                            Integrate and get all updates right in your Slack channels.
                            <span class="d-inline-flex align-center">
                                <a rel="noopener noreferrer" :href="slackIntegrationLink" target="_blank" class="blue--text text--darken-1">Learn more</a>
                                <a-icon size="14" color="blue darken-1">launch</a-icon>
                            </span>
                        </p>
                    </div>
                    <a-spacer></a-spacer>
                    <template v-if="!loading">
                        <a-btn v-if="slack_status.slack_connection_status" depressed text class="px-6 py-1 indigo darken-2 u-rounded-corners-lg u-border" width="176">
                            <span class="md-subtitle-1 white--text font-weight-medium">{{ localSlackChannels && localSlackChannels.length ? 'Enabled' : 'Installed' }}</span>
                        </a-btn>
                        <template v-else>
                            <a-btn v-if="$can('applets.index')" depressed text class="px-6 py-1 grey lighten-4 u-border" width="176" :to="{ name: 'appstore' }">
                                <a-icon size="18" color="grey darken-1" class="mr-2">login</a-icon>
                                <span class="md-subtitle-1 grey--text text--darken-1 font-weight-medium">Go to App Store</span>
                            </a-btn>
                        </template>
                    </template>
                </div>

                <v-slide-y-transition hide-on-leave leave-absolute>
                    <div v-if="slack_status.slack_connection_status">
                        <a-divider></a-divider>
                        <div class="px-6 md-body-2 grey--text text--darken-1 u-flex-center-y" :class="{ 'grey lighten-5': localIsExpanded('slack') }" v-ripple="{ class: 'grey--text text--lighten-2' }" @click="localToggleExpand('slack')">
                            <div class="py-4 u-flex-center-y u-wfull u-cursor-pointer">
                                <a-icon size="22" color="grey darken-2" class="mr-1">keyboard_arrow_{{ localIsExpanded('slack') ? 'down' : 'right' }}</a-icon>
                                <span class="md-subtitle-2 grey--text text--darken-2 text-uppercase">Subscribed Channels</span>
                            </div>
                            <a-spacer></a-spacer>
                            <a-sheet class="px-2 py-4 u-flex-center-y u-cursor-pointer transparent" v-ripple="{ class: 'info--text' }" @click.stop="localOpenDialogInstructions('slack')">
                                <a-icon size="16" color="blue darken-1" class="mr-1">tune</a-icon>
                                <span class="md-subtitle-2 blue--text text--darken-1">Setup</span>
                            </a-sheet>
                        </div>
                        <template v-if="localIsExpanded('slack')">
                            <a-divider></a-divider>
                            <a-sheet class="u-rounded-corners-lg">
                                <div class="px-6 py-4 md-subtitle-1 grey--text text--darken-1" v-if="localSlackChannels && !localSlackChannels.length">You have not subscribed to any channels.</div>
                                <template v-else>
                                    <template v-for="(channel, index) in localSlackChannels">
                                        <a-divider v-if="index !== 0" :key="channel.id"></a-divider>
                                        <div :key="channel.id" class="px-6 py-4 u-flex-center-y">
                                            <a-avatar color="green lighten-4" class="u-flex-center" size="16">
                                                <a-icon size="12" color="green darken-2">fiber_manual_record</a-icon>
                                            </a-avatar>
                                            <p class="mb-0 ml-3 md-subtitle-1 grey--text text--darken-1">
                                                <strong>#{{ channel.slack_recipient_username }}</strong>
                                                channel subscribed by
                                                <strong>@{{ channel.slack_creator_username }}</strong>
                                            </p>
                                            <a-spacer></a-spacer>
                                            <g-moment class="md-subtitle-2 grey--text text--darken-1" :value="channel.created_at" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                        </div>
                                    </template>
                                </template>
                            </a-sheet>
                        </template>
                    </div>
                </v-slide-y-transition>
            </a-sheet>

           <a-sheet class="mt-4 u-rounded-corners-lg u-border">
                <div class="pa-6 u-flex-center-y">
                    <a-sheet width="40" height="40" class="u-rounded-corners-lg u-flex-center u-border">
                        <a-img :src="require('../../assets/images/apps/ms-teams/microsoft-teams-logo.svg')" width="24" height="24" contain></a-img>
                    </a-sheet>
                    <div class="ml-4">
                        <h3 class="md-subtitle-1 font-weight-medium grey--text text--darken-2">Microsoft Teams</h3>
                        <p class="mb-0 md-body-2 grey--text text--darken-1">
                            With Teams integration get all updates right in your channels
                            <span class="d-inline-flex align-center">
                                <a :href="teamsIntegrationLink" target="_blank" class="blue--text text--darken-1">Learn more</a>
                                <a-icon size="14" color="blue darken-1">launch</a-icon>
                            </span>
                        </p>
                    </div>
                    <a-spacer></a-spacer>
                    <template v-if="!loading">
                        <a-btn v-if="microsoft_team_status.microsoft_team_connection_status" depressed text class="px-6 py-1 indigo darken-2 u-rounded-corners-lg u-border" width="176">
                            <span class="md-subtitle-1 white--text font-weight-medium">{{ localMicrosoftTeamChannels && localMicrosoftTeamChannels.length ? 'Enabled' : 'Installed' }}</span>
                        </a-btn>
                        <template v-else>
                            <a-btn v-if="$can('applets.index')" depressed text class="px-6 py-1 grey lighten-4 u-border" width="176" :to="{ name: 'appstore' }">
                                <a-icon size="18" color="grey darken-1" class="mr-2">login</a-icon>
                                <span class="md-subtitle-1 grey--text text--darken-1 font-weight-medium">Go to App Store</span>
                            </a-btn>
                        </template>
                    </template>
                </div>
                <!-- <a-divider></a-divider> -->
                <v-slide-y-transition hide-on-leave leave-absolute>
                    <div v-if="microsoft_team_status.microsoft_team_connection_status">
                        <a-divider></a-divider>
                        <div class="px-6 md-body-2 grey--text text--darken-1 u-flex-center-y" :class="{ 'grey lighten-5': localIsExpanded('microsoft_team') }" v-ripple="{ class: 'grey--text text--lighten-2' }" @click="localToggleExpand('microsoft_team')">
                            <div class="py-4 u-flex-center-y u-wfull u-cursor-pointer">
                                <a-icon size="22" color="grey darken-2" class="mr-1">keyboard_arrow_{{ localIsExpanded('microsoft_team') ? 'down' : 'right' }}</a-icon>
                                <span class="md-subtitle-2 grey--text text--darken-2 text-uppercase">Subscribed Channels</span>
                            </div>
                            <a-spacer></a-spacer>
                            <a-sheet class="px-2 py-4 u-flex-center-y u-cursor-pointer transparent" v-ripple="{ class: 'info--text' }" @click.stop="localOpenDialogInstructions('microsoft_team')">
                                <a-icon size="16" color="blue darken-1" class="mr-1">tune</a-icon>
                                <span class="md-subtitle-2 blue--text text--darken-1">Setup</span>
                            </a-sheet>
                        </div>
                        <template v-if="localIsExpanded('microsoft_team')">
                            <a-divider></a-divider>
                            <a-sheet class="u-rounded-corners-lg">
                                <div class="px-6 py-4 md-subtitle-1 grey--text text--darken-1" v-if="localMicrosoftTeamChannels && !localMicrosoftTeamChannels.length">You have not subscribed to any channels.</div>
                                <template v-else>
                                    <template v-for="(channel, index) in localMicrosoftTeamChannels">
                                        <a-divider v-if="index !== 0" :key="channel.id"></a-divider>
                                        <div :key="channel.id" class="px-6 py-4 u-flex-center-y">
                                            <a-avatar color="green lighten-4" class="u-flex-center" size="16">
                                                <a-icon size="12" color="green darken-2">fiber_manual_record</a-icon>
                                            </a-avatar>
                                            <p class="mb-0 ml-3 md-subtitle-1 grey--text text--darken-1">
                                                <strong>#{{ channel.channel_name }} ({{ channel.team_name }})</strong>
                                                channel subscribed by
                                                <strong>@{{ channel.creator_username }}</strong>
                                            </p>
                                            <a-spacer></a-spacer>
                                            <g-moment class="md-subtitle-2 grey--text text--darken-1" :value="channel.created_at" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                        </div>
                                    </template>
                                </template>
                            </a-sheet>
                        </template>
                    </div>
                </v-slide-y-transition>
            </a-sheet>
        </div>

        <!-- Project settings -->
        <div class="mt-6" v-if="$can('projects.download') || $can('projects.update-only') || $can('projects.destroy')">
            <h3 class="mb-3 text-h6 indigo--text text--darken-2 font-weight-medium">Project</h3>
            <a-sheet class="u-rounded-corners-lg u-border">
                <template v-if="$can('projects.download')">
                    <a-sheet class="u-flex-center-y pa-6 u-rounded-corners-lg">
                        <a-sheet width="40" height="40" class="u-rounded-corners-lg u-flex-center u-border">
                            <a-icon size="32" color="grey darken-1">save_alt</a-icon>
                        </a-sheet>
                        <div class="ml-4">
                            <h3 class="md-subtitle-1 font-weight-medium grey--text text--darken-2">Export Project as Excel</h3>
                            <span class="md-body-2 grey--text text--darken-1">Export your project data into excel</span>
                        </div>
                        <a-spacer></a-spacer>
                        <a-btn depressed text class="px-6 py-1 grey lighten-4 u-border" width="176" @click="local_export(project_item.id)">
                            <span class="md-subtitle-1 grey--text text--darken-1 font-weight-medium">Export as Excel</span>
                        </a-btn>
                    </a-sheet>
                </template>
                <a-divider></a-divider>
                <template v-if="$can('projects.update-only')">
                    <a-sheet class="u-flex-center-y pa-6 u-rounded-corners-lg">
                        <a-sheet width="40" height="40" class="u-rounded-corners-lg u-flex-center u-border">
                            <a-icon size="32" color="grey darken-1" outlined>archive</a-icon>
                        </a-sheet>
                        <div class="ml-4">
                            <h3 class="md-subtitle-1 font-weight-medium grey--text text--darken-2">Archive Project</h3>
                            <span class="md-body-2 grey--text text--darken-1">Archive your project and preserve your data</span>
                        </div>
                        <a-spacer></a-spacer>
                        <a-sheet v-if="project_item.status === 'archived'" class="px-5 py-1 grey lighten-2 u-rounded-corners-lg u-border" width="176">
                            <span class="md-subtitle-1 grey--text font-weight-medium">Project Archived</span>
                        </a-sheet>
                        <a-btn v-else depressed text class="px-6 py-1 grey lighten-4 u-border" width="176" @click="localConfirmArchive()">
                            <span class="md-subtitle-1 grey--text text--darken-1 font-weight-medium">Archive Project</span>
                        </a-btn>
                    </a-sheet>
                </template>

                <template v-if="$can('projects.destroy')">
                    <a-divider></a-divider>
                    <a-sheet class="u-flex-center-y pa-6 u-rounded-corners-lg">
                        <a-sheet width="40" height="40" class="u-rounded-corners-lg u-flex-center u-border">
                            <a-icon size="32" color="deep-orange darken-2">delete</a-icon>
                        </a-sheet>
                        <div class="ml-4">
                            <h3 class="md-subtitle-1 font-weight-medium deep-orange--text text--darken-2">Delete Project</h3>
                            <span class="md-body-2 grey--text text--darken-1">Delete your project permanently from the system</span>
                        </div>
                        <a-spacer></a-spacer>
                        <a-btn v-test-btn.delete depressed text class="px-6 py-1 deep-orange lighten-5 u-border" @click="dialogProjectDelete = true" width="176">
                            <span class="md-subtitle-1 deep-orange--text text--darken-2 font-weight-medium">Delete Project</span>
                        </a-btn>
                    </a-sheet>
                </template>
            </a-sheet>
        </div>

        <a-dialog max-width="480" v-model="dialogProjectDelete">
            <ModalProjectDelete
                :isOpen="dialogProjectDelete"
                :project="project_item"
                @close="dialogProjectDelete = false"
            />
        </a-dialog>

        <a-dialog max-width="400" v-model="dialogProjectArchive">
            <a-card class="py-8 text-center pa-6 u-rounded-corners-lg">
                <div class="mb-4">
                    <a-icon size="48" color="grey darken-2" class="mb-2" outlined>archive</a-icon>
                    <h3 class="mb-1 md-heading-6 font-weight-medium grey--text text--darken-2">Are you sure to archive the project?</h3>
                    <span class="md-subtitle-1 grey--text text--darken-1">This action cannot be undone.</span>
                </div>
                <div>
                    <a-btn class="ma-0 elevation-0 font-weight-medium green lighten-4 green--text text--darken-2" :loading="archiveLoading" @click="localArchiveProject(project_item.id)">Archive Project</a-btn>
                    <a-btn color="grey" class="ml-3 ma-0" text @click="dialogProjectArchive =  false">Cancel</a-btn>
                </div>
            </a-card>
        </a-dialog>

        <a-dialog max-width="480px" v-model="dialogDownloadLink" persistent>
            <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden" flat>
                <a-progress-linear class="my-0" height="3" color="orange darken-3" :indeterminate="true" v-if="mixinIsLoading('download-link')"></a-progress-linear>
                <a-card-text class="pa-2">
                    <a-container container--fluid grid-list-xl class="pa-4">
                        <a-layout align-start>
                            <a-flex shrink>
                                <a-icon size="72" color="grey lighten-1">cloud_download</a-icon>
                            </a-flex>
                            <a-flex>
                                <h2 class="mb-2 md-heading-6">
                                    <span v-if="mixinIsLoading('download-link')">Preparing file...</span>
                                    <span v-else>Ready to Download</span>
                                </h2>
                                <p class="mb-0 md-body-2 grey--text text--darken-1">
                                    <span v-if="mixinIsLoading('download-link')">Success is preparing your report for download. It may take few seconds to get ready.</span>
                                    <span v-else>Your report is ready to download. Click on the "Save File" button to download the report.</span>
                                </p>
                                <a-btn :href="project_links" target="_blank" small class="px-3 mt-3 elevation-0 ma-0" color="primary" :disabled="mixinIsLoading('download-link')" @click="dialogDownloadLink = false">
                                    <a-icon size="14" class="mr-2">save</a-icon>
                                    Save File
                                </a-btn>
                                <a-btn class="px-3 mt-3 elevation-0 ma-0" color="grey" small text @click="dialogDownloadLink = !dialogDownloadLink" :disabled="mixinIsLoading('download-link')">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-dialog v-model="dialogInstructions" max-width="600">
            <a-card>
                <div class="pa-6 u-flex-center-y">
                    <a-avatar size="36" color="primary lighten-1">
                        <a-icon size="20" color="white">tune</a-icon>
                    </a-avatar>
                    <h3 class="ml-3 text-h6 grey--text text--darken-2">Setup Instructions</h3>
                    <a-spacer></a-spacer>
                    <a-icon size="18" color="grey darken-1" @click="dialogInstructions = false">clear</a-icon>
                </div>
                <a-divider></a-divider>
                <div class="pt-5 pa-6 pb-7">
                    <template v-if="localServiceType === 'slack'">
                        <PSubscribeChannel
                            :command="slack_slash_command + ' sub ' + (user_self && user_self.organization ? user_self.organization.slug : '') + ' '  + project_item.id"
                            @copySuccess="localCopyCommand"
                        ></PSubscribeChannel>
                        <div class="mt-6">
                            <PUnSubscribeChannel
                                :command="slack_slash_command + ' unsub ' + (user_self && user_self.organization ? user_self.organization.slug : '') + ' '  + project_item.id"
                                @copySuccess="localCopyCommand"
                            ></PUnSubscribeChannel>
                        </div>
                    </template>
                    <template v-if="localServiceType === 'microsoft_team'">
                        <PSubscribeChannel
                            :command="microsoftTeamAtCommand + ' sub ' + (user_self && user_self.organization ? user_self.organization.slug : '') + ' '  + project_item.id"
                            @copySuccess="localCopyCommand"
                        >
                            <template #content>Open a Microsoft Teams channel and paste the command,</template>
                        </PSubscribeChannel>
                        <div class="mt-6">
                             <PUnSubscribeChannel
                                :command="microsoftTeamAtCommand + ' unsub ' + (user_self && user_self.organization ? user_self.organization.slug : '') + ' '  + project_item.id"
                                @copySuccess="localCopyCommand"
                            >
                                <template #content>Paste the below command on the same channel,</template>
                            </PUnSubscribeChannel>
                        </div>
                    </template>
                </div>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ModalProjectDelete } from './Modals'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import { PSubscribeChannel, PUnSubscribeChannel } from './Partials'
import automationHook from '@/mixins/mixin-automation-hook'

export default {
    name: 'ProjectSettings',
    mixins: [
        mixinLoading,
        automationHook,
    ],

    components: {
        ModalProjectDelete,
        PUnSubscribeChannel,
        PSubscribeChannel,
    },

    data () {
        return {
            loading: false,
            archiveLoading: false,
            expandedModules: [],
            confirmArchive: false,
            dialogInstructions: false,
            dialogProjectArchive: false,
            dialogDownloadLink: false,
            dialogProjectDelete: false,
            localServiceType: null,
            slackIntegrationLink: 'https://help.success.app/en/articles/6225851-how-to-configure-slack-integration',
            teamsIntegrationLink: 'https://help.success.app/en/articles/7165454-how-to-configure-microsoft-teams-integration',
            slack_slash_command: process.env.VUE_APP_SLACK_SLASH_COMMAND,
            microsoftTeamAtCommand: process.env.VUE_APP_MICROSOFT_TEAM_AT_COMMAND,
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localSlackChannels () {
            return _.filter(this.project_item.notification_channels, {service: 'slack'})
        },

        localMicrosoftTeamChannels () {
            return this.project_item.microsoft_team_notification_channels
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('MicrosoftTeam', {
            microsoft_team_status: 'status',
        }),

        ...mapState('Slack', {
            slack_status: 'status',
        }),

        ...mapState('ProjectView', {
            project_item: 'item',
            project_links: 'links',
            project_stage_history: 'stage_history',
        }),
    },

    methods: {
        async localIndex () {
            this.$vuetify.goTo(0)
            this.loading = true
            this.slack_status_show()
            this.microsoft_team_status_show()
            await this.project_show({
                id: this.$route.params.id,
                params: {
                    'include': 'notification_channels,microsoftTeamNotificationChannels',
                    'fields[projects]': 'id',
                    'fields[microsoft_team_notification_channels]': 'id,service,resource_type,resource_id,team_name,channel_name,creator_username,created_at'
                }
            })
            this.loading = false
        },

        localCopyCommand () {
            this.$notify('success', 'Link Copied!')
        },

        localToggleExpand (type) {
            const index = this.expandedModules.indexOf(type)
            if (index !== -1) this.expandedModules.splice(index, 1)
            else this.expandedModules.push(type)
        },

        localIsExpanded (type) {
            return this.expandedModules.includes(type)
        },

        async localConfirmArchive () {
            this.dialogProjectArchive = true
        },

        async localArchiveProject () {
            this.archiveLoading = true
            await this.automation_workflow_hook({ id : this.$route.params.id, type: 'project-archived'})
            if (!this.$status(this.automation_workflow_hook_response)) {
                this.$automation()
                this.archiveLoading = false
                this.dialogProjectArchive = false
                return false
            }
            const project = _.cloneDeep(this.project_item)
            Object.assign(project, { stage_id: null, stage: null, status: 'archived' })
            this.project_update_item(project)

            await this.project_stage_archive({ project_id: this.$route.params.id, stage_id: 'archived' })
            this.$notify('success', 'Archived successfully!')
            this.dialogProjectArchive = false
            this.archiveLoading = false
        },

        async local_export(id) {
            this.mixinSetLoading('download-link')
            this.dialogDownloadLink = true
            await this.project_export({ id })
            this.mixinResetLoading('download-link')
        },

        localOpenDialogInstructions(type) {
            this.localServiceType = type
            this.dialogInstructions = true
        },

        ...mapActions('Slack', {
            slack_status_show: 'status',
        }),

        ...mapActions('MicrosoftTeam', {
            microsoft_team_status_show: 'status',
        }),

        ...mapActions('ProjectView', {
            project_show: 'show',
            project_export: 'export',
            project_update_item: 'update_item',
            project_stage_archive: 'archive',
            project_stage_history_index: 'stage_history',
        })
    },
}
</script>

<style lang="scss" scoped>
.c-activity {
    &__icon {
        position: relative;
        &::before, &::after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 2px;
            background-image: linear-gradient(90deg, rgba(196, 196, 196, 1), rgba(196, 196, 196, 1));
            height: 100%;
            top: -100%;
        }

        &::after {
            top: auto;
            bottom: -100%;
        }
    }

    &:first-child {
        .c-activity__icon {
            &::before {
                background-image: linear-gradient(10deg, rgba(196, 196, 196, 1), rgba(238, 238, 238, 0.92));
            }
        }
    }

    &:last-child {
        .c-activity__icon {
            &::after {
                content: none;
            }
        }
    }
}
</style>

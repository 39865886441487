<template>
    <a-main>
        <a-container container--fluid grid-list-xl class="pa-0 mx-0 pb-16 mb-16">
            <g-page-header
                title="Notes"
                subtitle="All notes from the projects that you are collaborating can be managed."
                :breadcrumb-items="breadcrumb_items"
                icon="notes"
            >
                <template v-slot:action v-if="permission_note_store()">
                    <a-btn color="primary" class="elevation-0 mx-0" @click="local_note_create()">
                        <a-icon class="mr-2" color="white" size="18">add</a-icon>
                        Create New Note
                    </a-btn>
                </template>
            </g-page-header>
            <a-container container--fluid grid-list-xl class="pa-0 mt-4">
                <a-layout align-center>
                    <a-flex shrink>
                        <a-card
                            @click="filters_pane = true"
                            :disabled="index_loading || page_loading"
                            class="md-body-2 u-border u-rounded-corners xs-mt-1 xl-mt-0 d-flex align-center pr-3 pl-2 u-cursor-pointer"
                            min-height="40"
                            flat
                        >
                            <a-icon size="18" :class="[local_check_no_filters ? 'grey--text text--lighten-1' : 'blue--text']" class="mr-1">filter_alt</a-icon>
                            <span class="md-body-2 font-weight-medium grey--text text--darken-2">Filters</span>
                        </a-card>
                    </a-flex>
                    <a-flex shrink class="pl-1" style="width: 240px">
                        <SharedDropdownWrapper
                            :isActive="local_team_filter.value !== 'all'"
                            :list="local_team_filter_list"
                            :disabled="index_loading || page_loading"
                            item-value="value"
                            item-text="label"
                            @action="e => local_team_filter_set(e.label, e.value)"
                            flat
                        >
                            <span class="md-body-2 mx-1" v-if="!index_loading">
                                <template v-if="user_self && user_self.is_manager">Team:</template>
                                {{ local_team_filter.label }}
                            </span>
                            <span v-else>
                                <loader-template height="24" width="140px" class="u-rounded-corners ml-2"></loader-template>
                            </span>
                        </SharedDropdownWrapper>
                    </a-flex>
                    <a-flex>
                        <a-spacer></a-spacer>
                    </a-flex>
                    <a-flex shrink class="pl-1">
                        <a-menu bottom offset-y :disabled="page_loading || index_loading">
                            <template v-slot:activator="{ on }">
                                <a-card flat class="u-rounded-corners u-overflow-hidden u-border d-flex align-center" v-on="on" min-height="40">
                                    <a-card-text class="px-2 py-1">
                                        <a-icon size="20" class="ma-0" color="grey lighten-1">sort</a-icon>
                                        <span class="md-body-2 mx-1">Sort by: {{ local_filter_value_get(filter_list_sort_field, local_note_filters[note_filter.SORT], 'label') }}</span>
                                        <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-list class="u-list-condensed">
                                <a-list-item v-for="item in filter_list_sort_field" :key="'status_key_' + item.value" @click="local_filter_value_set(note_filter.SORT, item.value)">
                                    <a-list-item-title>
                                        <span class="md-body-2">Sort by: {{ item.label }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink class="pl-1">
                        <a-tooltip bottom :disabled="page_loading || index_loading">
                            <template v-slot:activator="{ on }">
                                <a-card :disabled="page_loading || index_loading" flat class="u-rounded-corners u-overflow-hidden u-border u-cursor-pointer d-flex align-center" @click="local_filter_value_set('sort_direction', (sort_direction === '-') ? '' : '-' )" v-on="on" min-height="40">
                                    <a-card-text class="px-2 py-1">
                                        <a-icon size="20" class="ma-0">{{ (sort_direction === '') ? 'arrow_downward' : 'arrow_upward' }}</a-icon>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <span v-if="sort_direction === ''">Sort Descending</span>
                            <span v-if="sort_direction === '-'">Sort Ascending</span>
                        </a-tooltip>
                    </a-flex>
                    <a-flex shrink class="pl-1">
                        <a-progress-circular color="orange darken-2" size="24" width="2" :indeterminate="loading"></a-progress-circular>
                    </a-flex>
                </a-layout>
            </a-container>
            <a-layout v-if="page_loading">
                <a-flex>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Notes..</h3>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <a-layout v-if="!page_loading && !note_list.length">
                <a-flex>
                    <a-responsive class="my-16" v-show="local_scope_message">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                        <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.<br>Try narrowing your filter or add new note.</p>
                    </a-responsive>
                     <a-responsive class="my-16" v-show="checkscope_message">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">There are no projects available to display.</h3>
                        <p class="md-body-2 text-center grey--text mb-0">The view may affected due to role based access control. Please check your configuration or contact your admin.</p>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <a-layout wrap align-start v-if="!page_loading">
                <a-flex xs12 v-for="(notes, parent) in local_group_by(note_list, 'parent_id')" :key="parent.id">
                    <a-container container--fluid grid-list-xl class="mx-0 my-3 pa-0">
                        <a-layout align-center>
                            <a-flex shrink class="text-truncate" v-if="notes.length && notes[0] && notes[0].parent">
                                <router-link
                                    tag="h2"
                                    :to="{
                                        name: (notes[0].parent_type === 'Customer') ? 'customers-notes' : 'projects-notes',
                                        params: { id: notes && notes[0] && notes[0].parent ? notes[0].parent.id : ''}
                                    }"
                                    :title="(notes[0].parent.name) ? 'Account: ' + notes[0].parent.name : 'Project: ' + notes[0].parent.title"
                                    class="md-heading-6 blue--text text--darken-2 u-cursor-pointer text-truncate"
                                >
                                    {{ (notes[0].parent.name) ? 'Account: ' + notes[0].parent.name : 'Project: ' + notes[0].parent.title }}
                                </router-link>
                            </a-flex>
                            <a-spacer></a-spacer>
                            <!-- <a-flex>
                                <a-divider></a-divider>
                            </a-flex> -->
                            <a-flex shrink v-if="notes[0].parent && notes[0].parent_type === 'Project'" class="text-truncate">
                                <p class="md-body-2 mb-0 text-truncate">
                                    <span :title="(notes[0].parent && notes[0].parent.customer) ? notes[0].parent.customer.name : '–' | truncateText(30)">
                                        Account: {{ (notes[0].parent && notes[0].parent.customer) ? notes[0].parent.customer.name : '–' | truncateText(30) }}
                                    </span> •
                                    <span :title="(notes[0].parent && notes[0].parent.stage) ? notes[0].parent.stage.name : capitalize_first_letter(notes[0].parent.status) | truncateText(30)">
                                        Stage: {{ (notes[0].parent && notes[0].parent.stage) ? notes[0].parent.stage.name : capitalize_first_letter(notes[0].parent.status) | truncateText(30) }}
                                    </span>
                                </p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <router-link
                        tag="div"
                        class="u-border u-cursor-pointer u-rounded-corners my-2 pa-1"
                        :class="{ 'yellow lighten-4': note.visibility === 'external', }"
                        v-for="note in notes"
                        :key="note.id"
                        :to="{name: (note.parent_type === 'Project') ? 'projects-notes-view' : 'customers-notes-view', params: {id: note.parent_id, note_id: note.id}}"
                    >
                        <div class="pa-3">
                            <a-container container--fluid grid-list-xl>
                                <a-layout align-center wrap>
                                    <a-flex shrink pa-0 class="text-truncate u-wfull">
                                        <h2 class="md-body-1 text-truncate mb-2" :title="note.title">
                                            {{ note.title }}
                                        </h2>
                                    </a-flex>
                                    <a-spacer></a-spacer>
                                    <a-flex shrink pa-0 mr-3 style="max-width: 50px" v-if="local_bridge_value(note, 'salesforce', 'opportunity.salesforce_id', 'account.salesforce_id')">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on">
                                                    <img src="../../assets/images/icon-salesforce.svg" height="12" alt="Salesforce" class="d-block">
                                                </div>
                                            </template>
                                            <span>Salesforce Synced</span>
                                        </a-tooltip>
                                    </a-flex>
                                    <a-flex shrink pa-0 mr-3 style="max-width: 180px">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on" class="u-flex-center-y">
                                                    <a-icon size="18" color="grey darken-2" class="mr-1">post_add</a-icon>
                                                    <h3 v-if="note.type" style="max-width: 150px;" class="md-body-2 grey--text text--darken-2 text-truncate">
                                                        {{ note.type.value }}
                                                    </h3>
                                                    <span class="md-body-2 grey--text text--darken-2" v-else>No Category</span>
                                                </div>
                                            </template>
                                            <span>Category</span>
                                        </a-tooltip>
                                    </a-flex>
                                    <a-flex shrink pa-0 mr-3>
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on" class="u-flex align-center">
                                                    <a-icon size="18" color="grey darken-2" class="mr-1">person</a-icon>
                                                    <div>
                                                        <span class="md-body-2 grey--text text--darken-2 d-block text-truncate" v-if="note && note.created_by">{{ note.created_by.name }}</span>
                                                        <template v-else>
                                                            <span class="md-body-2 grey--text text--darken-2" v-if="local_bridge_value(note, 'salesforce', 'opportunity.salesforce_id', 'account.salesforce_id')">Salesforce</span>
                                                        </template>
                                                    </div>
                                                </div>
                                            </template>
                                            <span>Created by</span>
                                        </a-tooltip>
                                    </a-flex>
                                    <a-flex shrink pa-0 mr-3>
                                        <div class="u-flex align-center">
                                            <div class="md-body-2 grey--text text--darken-2 pr-1">Updated By</div>
                                            <span class="md-body-2 grey--text text--darken-2 d-block text-truncate" v-if="note && note.updated_by">{{ note.updated_by.name }}</span>
                                            <template v-else>
                                                <span class="md-body-2 grey--text text--darken-2" v-if="local_bridge_value(note, 'salesforce', 'opportunity.salesforce_id', 'account.salesforce_id')">Salesforce</span>
                                            </template>
                                            <a-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <a-icon size="6" color="grey lighten-2" class="px-2">fiber_manual_record</a-icon>
                                                        <g-moment class="md-body-2 grey--text text--darken-2" :value="note.updated_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                                                    </div>
                                                </template>
                                                <span>Updated Date and Time</span>
                                            </a-tooltip>
                                        </div>
                                    </a-flex>
                                    <a-flex shrink pa-0 class="u-flex align-center">
                                        <a-icon size="6" color="grey lighten-2">fiber_manual_record</a-icon>
                                        <g-attachment-count-info
                                            class="ml-3"
                                            :count="note.attachments_count"
                                            fontSize="14"
                                        />
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </div>
                    </router-link>
                </a-flex>
            </a-layout>
        </a-container>
         <a-dialog v-model="dialog_note_form" max-width="800" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="45">
                                        <a-icon dark>notes</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">Create New Note</h2>
                                    <p class="md-subtitle-2 mb-0">You can create new note under the project</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title</label>
                                    <a-text-field
                                        v-model="note_item.title"
                                        placeholder="Enter new note title"
                                        background-color="neutral"
                                        solo flat hide-details
                                    ></a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="note_response.server && note_response.server.errors && note_response.server.errors.title">
                                        <a-icon size="16" class="u-icon-nudge-xs" color="red darken-2">warning</a-icon>
                                        {{ note_response.server.errors.title[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                        Enter the title of the note.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Description (Optional)</label>
                                    <div class="neutral pa-3">
                                        <p class="grey--text text--darken-1" v-if="editor_note_description  === null" @click="() => { note_item.content = '<p> </p>'; editor_note_description.focus() }">Click to edit the description</p>
                                        <editor-content v-else :editor="editor_note_description"></editor-content>
                                        <editor-menu-bubble :editor="editor_note_description" :keep-in-bounds="false" v-slot="{ commands, isActive, menu }">
                                            <div
                                                class="menububble"
                                                :class="{ 'is-active': menu.isActive }"
                                                :style="`left: ${menu.left - 16}px; bottom: ${menu.bottom + 8}px;`"
                                            >
                                                <a-btn icon text small class="mx-0" :color="(isActive.bold()) ? 'blue' : 'grey'" @click="commands.bold">
                                                    <a-icon size="16">format_bold</a-icon>
                                                </a-btn>
                                                <a-btn icon text small class="mx-0" :color="(isActive.italic()) ? 'blue' : 'grey'" @click="commands.italic">
                                                    <a-icon size="16">format_italic</a-icon>
                                                </a-btn>
                                                <!-- <a-btn icon text small class="mx-0" :color="(isActive.underline()) ? 'blue' : 'grey'" @click="commands.underline">
                                                    <a-icon size="16">format_underlined</a-icon>
                                                </a-btn> -->
                                                <!-- <a-btn icon text small class="mx-0" :color="(isActive.strike()) ? 'blue' : 'grey'" @click="commands.strike">
                                                    <a-icon size="16">strikethrough_s</a-icon>
                                                </a-btn> -->
                                                <a-divider vertical dark></a-divider>
                                                <a-btn icon text small class="mx-0" :color="(isActive.bullet_list()) ? 'blue' : 'grey'" @click="commands.bullet_list">
                                                    <a-icon size="16">format_list_bulleted</a-icon>
                                                </a-btn>
                                                <a-btn icon text small class="mx-0" :color="(isActive.ordered_list()) ? 'blue' : 'grey'" @click="commands.ordered_list">
                                                    <a-icon size="16">format_list_numbered</a-icon>
                                                </a-btn>
                                            </div>
                                        </editor-menu-bubble>
                                    </div>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                        <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                        You can summarize and describe the note.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Category (Optional)</label>
                                     <a-autocomplete
                                        v-model="note_item.type_id"
                                        :items="type_list"
                                        item-text="value"
                                        item-value="id"
                                        placeholder="Select Category"
                                        background-color="neutral"
                                        solo flat hide-details clearable
                                    >
                                        <template v-slot:no-data>
                                            <div class="px-4 py-2">
                                                <span class="md-body-2 grey--text">No Category found</span>
                                            </div>
                                        </template>
                                    </a-autocomplete>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                        <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                        Select the category of the note.
                                    </span>
                                </a-flex>
                                <a-flex xs6>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Visibility</label>
                                    <a-autocomplete
                                        v-model="note_item.visibility"
                                        :items="[{name: 'Private', value: 'internal'}, {name: 'Public', value: 'external'}]"
                                        item-text="name"
                                        item-value="value"
                                        placeholder="Visibility"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-autocomplete>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                        <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                        Set the visibility of the note.
                                    </span>
                                </a-flex>
                                <a-flex xs6>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Link To</label>
                                    <a-select
                                        v-model="note_item.parent_type"
                                        :items="[{name: 'Project', value: 'Project'}, {name: 'Account', value: 'Customer'}]"
                                        item-text="name"
                                        item-value="value"
                                        placeholder="Linked to"
                                        background-color="neutral"
                                        solo flat hide-details
                                        @input="local_reset_note_parent_id()"
                                    >
                                    </a-select>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                        <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                        Select type to link
                                    </span>
                                </a-flex>
                                <a-flex xs12 v-if="note_item.parent_type === 'Project'">
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Project</label>
                                    <a-autocomplete
                                        v-model="note_item.parent_id"
                                        :items="project_list"
                                        item-text="title"
                                        item-value="id"
                                        placeholder="Select Project"
                                        background-color="neutral"
                                        solo flat hide-details clearable
                                    >
                                        <template v-slot:no-data>
                                            <div class="px-4 py-2">
                                                <span class="md-body-2" v-if="project_search != null && project_search != ''"> <strong>{{ project_search }}</strong></span>
                                                <span class="md-body-1 grey--text" v-else>No Projects found</span>
                                            </div>
                                        </template>
                                        <template v-slot:selection="{ item }">
                                            <span v-if="item === Object(item)" class="md-body-2 d-block py-1 u-rounded-corners text-truncate" style="max-width: 400px" :title="item.title">{{ item.title }} </span>
                                            <span v-else class="md-body-2 d-block text-truncate py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <a-layout align-center class="u-flex py-1">
                                                <a-flex shrink style="max-width: 400px">
                                                    <h5 class="md-subtitle-1 text-truncate" :title="item.title">{{ item.title | truncateText($vuetify.breakpoint.lg ? 20 : 50) }}</h5>
                                                    <p class="md-caption d-block grey--text text-truncate text--darken-1 mt-1 mb-0" v-if="item.customer">{{ item.customer.name }}</p>
                                                </a-flex>
                                                <a-spacer></a-spacer>
                                                <a-flex shrink class="text-right">
                                                    <span class="md-body-2 grey--text" v-if="item.stage">{{ item.stage.name }}</span>
                                                    <span class="md-body-2 grey--text" v-else>Archived</span>
                                                </a-flex>
                                            </a-layout>
                                        </template>
                                    </a-autocomplete>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="note_response.server && note_response.server.errors && note_response.server.errors.parent_id">
                                        <a-icon size="16" class="u-icon-nudge-xs" color="red darken-2">warning</a-icon>
                                        {{ note_response.server.errors.parent_id[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                        Select in which project the note should be linked with.
                                    </span>
                                </a-flex>
                                <a-flex xs12 v-if="note_item.parent_type === 'Customer'">
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Account</label>
                                    <a-autocomplete
                                        v-model="note_item.parent_id"
                                        :items="customer_list"
                                        item-text="name"
                                        item-value="id"
                                        placeholder="Select Account"
                                        background-color="neutral"
                                        solo flat hide-details clearable
                                    >
                                        <template v-slot:no-data>
                                            <div class="px-4 py-2">
                                                <span class="md-body-1" v-if="customer_search != null && customer_search != ''"> <strong>{{ customer_search }}</strong></span>
                                                <span class="md-body-1 grey--text" v-else>No Accounts found</span>
                                            </div>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <h5 class="md-subtitle-1 text-truncate" :title="item.name">{{ item.name | truncateText(60) }}</h5>
                                        </template>
                                    </a-autocomplete>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="note_response.server && note_response.server.errors && note_response.server.errors.parent_id">
                                        <a-icon size="16" class="u-icon-nudge-xs" color="red darken-2">warning</a-icon>
                                        {{ note_response.server.errors.parent_id[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                        Select in which Account the note should be linked with.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_note_store()" :disabled="local_project_loading">Create Note</a-btn>
                                    <a-btn color="grey" class="ml-2" text @click="local_note_close()" :disabled="local_project_loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!-- Filters Pane -->
        <partial-notes-filter-pane
            :isOpen="filters_pane"
            :preferenceItem="user_filter_item"
            @update="local_apply_filters"
            @revert="local_revert_filter()"
            >
        </partial-notes-filter-pane>

        <a-snackbar v-model="snackbar_status" :timeout="3000" bottom center>
            {{snackbar_text}}
        </a-snackbar>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
    Bold,
    Italic,
    // Underline,
    Strike,
    OrderedList,
    BulletList,
    ListItem,
    History,
} from 'tiptap-extensions'
import marked from 'marked'

import loadWorkspace from '@/mixins/mixin-workspace'
import checkScopePermission from '@/mixins/mixin-checkscope-permission'
import PartialNotesFilterPane from './Partials/PartialNotesFilterPane.vue'
import SharedDropdownWrapper from '@/components/Shared/SharedDropdownWrapper.vue'

export default {
    mixins: [loadWorkspace, checkScopePermission],
    components: {
        EditorContent,
        EditorMenuBubble,
        PartialNotesFilterPane,
        SharedDropdownWrapper
    },
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            accordion_notes: [],
            accordion_notes_all: false,
            editor_note_description: null,
            dialog_note_form: false,
            index_loading: true,
            local_project_loading: false,
            type_list: [],
            note_salesforce_target_resource: null,
            snackbar_status: false,
            snackbar_text: '',
            project_search: '',
            customer_search: '',
            note_group_by: 'project_id',
            filter_list_parent_type: [
                {'label': 'Project Notes', 'value': 'Project'},
                {'label': 'Account Notes', 'value': 'Customer'},
            ],
            filter_list_status: [
                {'label': 'Active Projects', 'value': 'active'},
                {'label': 'All Projects', 'value': 'all'},
            ],
            filter_list_creator_id: [
                {'label': 'All Notes', 'value': 'all'},
                {'label': 'Imported from Salesforce', 'value': 'salesforce'},
                {'label': 'Created by Me', 'value': 'self'},
            ],
            filter_list_group_by: [
                {'label': 'Grouped by Project', 'value': 'project_id'},
                {'label': 'Grouped by creator', 'value': 'creator_id'},
                {'label': 'Grouped by updated date', 'value': 'updated_at'},
                {'label': 'No grouping', 'value': 'ungrouped'},
            ],
            filter_list_sort_field: [
                {'label': 'Title', 'value': 'title'},
                {'label': 'Updated Date', 'value': 'updated_at'},
            ],
            page_loading: true,
            local_project_note_fields: { 'include': 'type,parent.customer,updatedBy,createdBy,attachmentsCount', 'fields[type]': 'id,type,value', 'sort': 'order', 'filter[parent_type]': 'Project'},
            local_customer_note_fields: { 'include': 'type,parent,updatedBy,createdBy,attachmentsCount','fields[type]': 'id,type,value', 'sort': 'order', 'filter[parent_type]': 'Customer' },
            sort_direction: '',
            note_filter: {
                INCLUDE: 'include',
                FIEDS: 'fields[type]',
                PARENT_TYPE: 'filter[parent_type]',
                PARENT_ID: 'filter[parent_id]',
                TYPE: 'filter[type]',
                TYPE_ID: 'filter[type_id]',
                CREATOR_ID: 'filter[creator_id]',
                STATUS: 'filter[status]',
                WORKSPACE: 'filter[workspace_id]',
                SORT: 'sort',
                VISIBILITY: 'filter[visibility]',
                HIERARCHY_REPORT: 'filter[hierarchy_report]'
            },
            project_filter: {
                FIEDS: 'fields[type]',
                STATUS: 'filter[status]',
                WORKSPACE: 'filter[workspace_id]',
            },
            local_note_filters : {},
            local_project_filters : {},
            filters_pane: false,
            user_filter_item: {},
            filter_preference: {},

            // filter variables
            local_project_type: 'active',
            local_note_type: 'Project',
            selected_projects: [],
            selected_customers: [],
            local_filter_visibility: 'all',
            local_note_source: 'all',
            local_note_categories: [],
            local_category_type: 'all',
            local_team_filter: {
                label: 'All Notes',
                value: 'all'
            },
            local_team_filter_list: [],
            filter_list_manager: [
                {'label': 'All Notes', 'value': 'all'},
                {'label': 'Direct Reports', 'value': 'direct_reports'},
                {'label': 'All Reports', 'value': 'all_reports'},
                {'label': 'Created by me', 'value': 'self'},
            ],
            filter_list_team: [
                {'label': 'All Notes', 'value': 'all'},
                {'label': 'Created by me', 'value': 'self'}
            ],
            user_filters_pref: {}
        }
    },
    mounted() {
        this.local_index()
    },

    watch: {
        filters_pane (val) {
            if (val) return
            this.local_load_project_list('active')
            this.local_load_customer_list()
            this.local_load_meta_list()
        }
    },

    computed: {
        local_screen_lg() {
            return this.$vuetify.breakpoint.lgAndDown
        },

        localScreenLgAndUp() {
            return this.$vuetify.breakpoint.lgAndUp
        },

        filter_list_type_id() {
            let types = this.type_list.map(item => {
                return {'label': item.value, 'value': item.id}
            })
            return [
                {'label': 'All Categories', 'value': 'all'},
                ...types,
                {'label': 'No Category', 'value': 'none'},
            ]
        },

        local_set_project_filters() {
            this.local_project_filters[this.project_filter.STATUS] = 'active'
            this.local_project_filters[this.project_filter.WORKSPACE] = this.current_workspace_id_mixin
        },

        local_check_no_filters() {
            if (this.local_project_type === 'active' && this.local_note_type === 'Project' && !_.size(this.selected_projects) && !_.size(this.selected_customers) && this.local_filter_visibility === 'all' && this.local_note_source === 'all' && this.local_category_type === 'all' && !_.size(this.local_note_categories)) return true
            return false
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Note', {
            note_list: 'list',
            note_item: 'item',
            note_filters: 'filters',
            note_response: 'response',
        }),

        ...mapState('Project', {
            project_list: 'list',
            project_item: 'item',
            project_filters: 'filters',
        }),

        ...mapState('Customer', {
            customer_list: 'list',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
            meta_filters: 'filters',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),

        ...mapState('Preference', {
            preference_list: 'list',
        })
    },
    methods: {
        async local_index() {
            if (this.$can('notes.index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.local_fetch_preference()
            this.local_load_project_list('active')
            this.local_project_checkscope()
            if (this.user_self && this.user_self.id) await this.user_show({id: this.user_self.id, mode: 'self'})
            this.local_load_note_list()
            this.local_load_customer_list()
            this.local_load_meta_list()
            this.local_set_hierarchy_list()
            this.index_loading = false
        },

        async local_load_project_list(active = null) {
            this.local_project_loading = true
            await this.project_clear()
            await this.project_clear_item()
            this.local_set_project_filters
            this.local_project_filters[this.project_filter.STATUS] = active
            await this.project_index({ 'include': 'customer,stage', 'fields[customer]': 'id,name', ...this.local_project_filters })
            this.local_project_loading = false
        },

        async local_load_note_list() {
            this.page_loading = true
            await this.note_clear()
            this.local_set_filter_pref()
            await this.local_set_filter_values()
            await this.note_index({
                ...this.local_note_filters
            })
            this.page_loading = false
        },

        async local_load_customer_list() {
            await this.customer_clear()
            await this.customer_index({ 'sort': 'name' })
        },

        async local_load_meta_list() {
            await this.meta_index({ 'filter[type]': 'note_type', 'sort': 'order'})
            this.type_list = _.cloneDeep(this.meta_list)
        },

        capitalize_first_letter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        local_filter_value_get(list, value, key) {
            if (value && typeof value === 'string' && value.charAt(0) === '-') return _.get(_.find(list, {value: value.substring(1)}), key)
            return _.get(_.find(list, {value: value}), key)
        },

        async local_filter_value_set(filter, value) {
            if (filter === 'sort_direction') {
                this.sort_direction = value
                this.local_note_filters[this.note_filter.SORT] = this.local_note_filters[this.note_filter.SORT].charAt(0) === '-' ? this.local_note_filters[this.note_filter.SORT].substring(1) : this.local_note_filters[this.note_filter.SORT]
                this.local_note_filters[this.note_filter.SORT] = `${value}${this.local_note_filters[this.note_filter.SORT]}`
            } else if (filter === 'sort') {
                this.local_note_filters[filter] = value
                this.local_note_filters[filter] = this.local_note_filters[filter].charAt(0) === '-' ? this.local_note_filters[filter].substring(1) : this.local_note_filters[filter]
                this.local_note_filters[filter] = `${this.sort_direction}${this.local_note_filters[filter]}`
            } else {
                this.local_note_filters[filter] = value
            }
            const include = this.local_note_filters[this.note_filter.PARENT_TYPE] === 'Project' ? 'type,parent.customer,parent.stage,updatedBy,createdBy,bridges' : 'type,parent,createdBy,updatedBy,bridges'

            await this.note_index({ 'include': include, 'fields[type]': 'id,type,value', ...this.local_note_filters })
            if(filter === 'filter[status]') this.local_load_project_list(value)
        },

        local_note_create() {
            this.local_load_form_data()
            const content = this.note_item.content ? this.note_item.content : ' '
            this.editor_note_description = new Editor({
                extensions: [
                    new Bold(),
                    new Italic(),
                    // new Underline(),
                    new Strike(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new History(),
                ],
                content: marked(content, { sanitize: true }),
                onUpdate: state => this.note_item.content = turndownService.turndown(state.getHTML()),
            })
            this.$set(this.note_item, 'visibility', 'internal')
            this.$set(this.note_item, 'parent_type', 'Project')
            this.dialog_note_form = true
            this.snackbar_text = ''
        },

        async local_load_form_data() {
            this.project_clear_item()
            this.note_clear_item()
        },

        local_reset_note_parent_id() {
            this.$set(this.note_item, 'parent_id', 0)
        },

        async local_note_store() {
            this.note_item.mode = 'add'
            await this.note_store(this.note_item)
            if (this.note_response.status === 'success') {
                await this.local_note_show(this.note_item.id, this.note_item.parent_type)
                await this.note_clear_item()
                this.editor_note_description = null
                this.dialog_note_form = false
                this.snackbar_status = true
                this.snackbar_text = 'Note created successfully!'
            }
        },

         async local_note_show(id, type) {
            if(this.local_note_filters[this.note_filter.PARENT_TYPE] !== type) return
            const note_type = type === 'Project' ?  this.local_project_note_fields : this.local_customer_note_fields
            await this.note_show({id: id, mode: 'add', params: note_type })
        },

        async local_note_close() {
            await this.note_clear_item()
            this.editor_note_description = null
            this.dialog_note_form = false
        },

        local_group_by(data, condition) {
            return _.groupBy(data, condition)
        },

        local_accordion_note_status(index) {
            if (this.accordion_notes[index]) {
                return this.accordion_notes[index]
            } else {
                this.accordion_notes[index] = false
                return this.accordion_notes[index]
            }
        },

        local_accordion_note_toggle(index) {
            if (this.accordion_notes[index] === undefined || this.accordion_notes[index] === null) {
                this.$set(this.accordion_notes, index, true);
            } else {
                this.$set(this.accordion_notes, index, !this.accordion_notes[index]);
            }
        },

        local_bridge_value(note, service, salesforce_key, salesforce_opp_key) {
            if (note.bridges) {
                const salesforce = note.bridges.find(item => item.service === service && item.key === salesforce_key)
                const salesforce_opp = note.bridges.find(item => item.service === service && item.key === salesforce_opp_key)

                if (salesforce && salesforce.value) return salesforce.value
                if (salesforce_opp && salesforce_opp.value) return salesforce_opp.value
                return ''
            }
        },

        permission_note_store(client_allowed = false) {
            if (this.user_self.role.scope === 'external') {
                return client_allowed
            }
            return this.$can('notes.store') && this.$can('projects.update-only') && this.user_self.role.scope === 'internal'
        },

        async local_fetch_preference(type = null) {
            await this.preference_index()
            this.filter_preference = this.preference_list.find(item => item.key === 'user.notes.filters')
            this.local_set_filter_pref()
        },

        local_set_filter_pref() {
            if (!this.filter_preference) return
            const local_filter_value = JSON.parse(this.filter_preference.value)
            this.user_filters_pref = {...local_filter_value}
            for (let filtervalue in local_filter_value) {
                switch (filtervalue) {
                    case 'project_type':
                        this.local_project_type = local_filter_value[filtervalue]
                        break;
                    case 'note_type':
                        this.local_note_type = local_filter_value[filtervalue]
                        break;
                    case 'projects':
                        this.selected_projects = local_filter_value[filtervalue]
                        break;
                    case 'customers':
                        this.selected_customers = local_filter_value[filtervalue]
                        break;
                    case 'visibility':
                        this.local_filter_visibility = local_filter_value[filtervalue]
                        break;
                    case 'note_source':
                        this.local_note_source = local_filter_value[filtervalue]
                        break;
                    case 'category_type':
                        this.local_category_type = local_filter_value[filtervalue]
                        break;
                    case 'category':
                        this.local_note_categories = local_filter_value[filtervalue]
                        break;
                    case 'hierarchy_report':
                        this.local_team_filter.value = local_filter_value[filtervalue].value
                        this.local_team_filter.label = local_filter_value[filtervalue].label
                        break;
                    default: break;
                }
            }
            this.local_set_filter_values()
            this.user_filter_item = Object.assign({}, this.filter_preference)
        },

        local_set_filter_values () {
            if (this.user_self && !this.user_self.is_manager && this.local_team_filter && (this.local_team_filter.value !== 'self')) {
                this.local_team_filter['label'] = 'All Notes'
                this.local_team_filter['value'] = 'all'
            }
            const include = 'type,updatedBy,createdBy,bridges,attachmentsCount'
            const parent_variable = this.local_note_type === 'Project' ? include + ',parent.customer,parent.stage' : include + ',parent'
            this.local_note_filters[this.note_filter.INCLUDE] = parent_variable
            this.local_note_filters[this.note_filter.FIEDS] = 'id,type,value'

            this.local_note_filters[this.note_filter.PARENT_TYPE] = this.local_note_type
            this.local_note_filters[this.note_filter.PARENT_ID] =  this.local_note_type === 'Project' ? _.map(this.selected_projects, 'id') : _.map(this.selected_customers, 'id')

            this.local_note_filters[this.note_filter.TYPE_ID] = this.local_category_type !== 'custom' ? this.local_category_type : _.map(this.local_note_categories, 'id')
            this.local_note_filters[this.note_filter.STATUS] = this.local_project_type
            this.local_note_filters[this.note_filter.HIERARCHY_REPORT] = this.local_team_filter && this.local_team_filter.value !== 'all' ? this.local_team_filter.value : null
            this.local_note_filters[this.note_filter.CREATOR_ID] = this.local_team_filter && this.local_team_filter.value !== 'self' ? this.local_note_source : 'self'
            this.local_note_filters[this.note_filter.VISIBILITY] = this.local_filter_visibility

            this.local_note_filters[this.note_filter.WORKSPACE] =  this.current_workspace_id_mixin
            this.local_note_filters[this.note_filter.SORT] =  '-updated_at'
            this.local_note_filters.count =  999
            this.sort_direction = '-'
        },

        local_revert_filter() {
            this.filters_pane = false
            this.user_filter_item = Object.assign({}, this.filter_preference)
        },

        async local_apply_filters (type, user_pref = null) {
            const filter_team_param = {
                hierarchy_report: {
                    label: this.local_team_filter.label,
                    value: this.local_team_filter.value
                }
            }
            this.user_filters_pref = (type === 'team') ? { ...this.user_filters_pref, ...filter_team_param } : { ...user_pref, ...filter_team_param }
            this.filters_pane = false
            const params = {
                resource_id: this.user_self.id,
                resource_type: 'User',
                value: JSON.stringify(this.user_filters_pref),
                label: 'Notes Filter',
                key: 'user.notes.filters',
                is_listable: 0,
            }
            if (this.filter_preference) await this.preference_filters_update({ id: this.filter_preference.id, ...params })
            else await this.preference_filters_store({ ...params })
            await this.local_fetch_preference()
            this.note_clear()
            this.local_load_note_list()
        },

        local_set_hierarchy_list () {
            if (this.user_self && this.user_self.is_manager) return this.local_team_filter_list = this.filter_list_manager
            return this.local_team_filter_list = this.filter_list_team
        },

        local_team_filter_set(label, value) {
            this.local_team_filter.label = label
            this.local_team_filter.value = value
            this.local_apply_filters('team')
        },

        ...mapActions('Note', {
            note_index: 'index',
            note_store: 'store',
            note_show: 'show',
            note_salesforce_push: 'salesforce_push',
            note_clear: 'clear',
            note_clear_item: 'clear_item',
        }),
        ...mapActions('Project', {
            project_index: 'index',
            project_clear: 'clear',
            project_clear_item: 'clear_item',
        }),
        ...mapActions('Customer', {
            customer_index: 'index',
            customer_clear: 'clear',
        }),
        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),
        ...mapActions('Preference', {
            preference_index: 'index',
            preference_filters_store : 'store',
            preference_filters_update : 'update',
            preference_clear_user_pref : 'clear_user_types',
        }),
        ...mapActions('User', {
            user_index: 'index',
            user_show: 'show',
            user_clear: 'clear'
        }),
    },
}
</script>

<template>
    <div class="white u-relative u-hfull">
        <div class="u-sticky" style="top: 0; z-index: 10">
            <div class="pa-6 u-flex align-center white" v-if="fieldType">
                <a-icon color="indigo" size="32" class="mr-4">{{ local_get_field_icon }}</a-icon>
                <p class="mb-0 md-heading-5 font-weight-medium indigo--text text--darken-1">{{ mode === 'create' ? 'Create' : 'Edit' }} {{ local_get_field_label }} field</p>
            </div>
            <a-divider></a-divider>
        </div>
        <div ref="formContainer" class="px-8 py-6 c-custom-field-input u-overflow-y c-tiny-scroll" style="height: 500px">
            <div>
                <label class="mb-3 grey--text text--darken-2">Label <span class="red--text text--darken-2">*</span></label>
                <a-text-field
                    v-model="item.label"
                    class="u-border mt-3"
                    background-color="grey lighten-5"
                    placeholder="Enter label"
                    @input="local_generate_name()"
                    autofocus
                    solo flat hide-details>
                </a-text-field>
                <div class="md-caption red--text text--darken-1 d-block mt-2" v-if="error_response && error_response.label">
                    <a-icon size="16" color="red darken-2" class="u-icon-nudge">info</a-icon>
                    {{ error_response.label }}
                </div>
                <div v-else class="md-caption grey--text text--darken-1 mt-2">
                    <a-icon color="grey" size="16" class="u-icon-nudge">info</a-icon>
                    The label will be helpful to identify the field
                </div>
            </div>
            <div class="mt-4">
                <label class="grey--text text--darken-2">Identifier <span class="red--text text--darken-2">*</span></label>
                <a-text-field
                    v-model="item.name"
                    placeholder="Eg: salesforce_id, new_task_id"
                    background-color="grey lighten-5"
                    class="u-border mt-3"
                    @input="local_validate_identifier_backend()"
                    solo flat hide-details
                >
                </a-text-field>
                <div class="md-caption red--text text--darken-1 d-block mt-2" v-if="error_response && (error_response.name || error_response.name_backend)">
                    <a-icon size="16" color="red darken-2" class="u-icon-nudge">info</a-icon>
                    {{ error_response.name ? error_response.name : error_response.name_backend }}
                </div>
                <div v-else class="md-caption grey--text text--darken-1 mt-2">
                    <a-icon color="grey" size="16" class="u-icon-nudge">info</a-icon>
                    The identifier can be edited here. In future, it can be obtained through public API
                </div>
            </div>

            <!-- Score field -->
            <div class="mt-4" v-if="fieldType && fieldType === 'score'">
                <label class="mb-4 grey--text text--darken-2">Score range <span class="red--text">*</span></label>
                <div class="u-flex justify-space-between mt-3">
                    <div class="u-wfull">
                        <div class="mb-3">Minimum score</div>
                        <a-tooltip bottom :disabled="!(error_response && error_response.minimum_value)">
                            <template v-slot:activator="{ on }">
                                <a-text-field
                                    v-model="item.minimum_value"
                                    v-on="on"
                                    type="number"
                                    min="0"
                                    step="1"
                                    title=""
                                    :class="[(error_response && error_response.minimum_value) ? 'c-wiggle-short u-border-error': 'u-border']"
                                    background-color="grey lighten-5"
                                    placeholder="Enter score"
                                    @keydown="local_prevent_non_numerics"
                                    @input="local_trigger_changes('minimum_value', item.minimum_value)"
                                    solo flat hide-details>
                                </a-text-field>
                            </template>
                            <span v-if="error_response && error_response.minimum_value">{{ error_response.minimum_value }}</span>
                        </a-tooltip>
                    </div>
                    <div class="ml-4 u-wfull">
                        <div class="mb-3">Maximum score</div>
                        <a-tooltip bottom :disabled="!(error_response && error_response.maximum_value)">
                            <template v-slot:activator="{ on }">
                                <a-text-field
                                    v-model="item.maximum_value"
                                    v-on="on"
                                    type="number"
                                    min="0"
                                    step="1"
                                    title=""
                                    :class="[(error_response && error_response.maximum_value) ? 'c-wiggle-short u-border-error': 'u-border']"
                                    background-color="grey lighten-5"
                                    placeholder="Enter score"
                                    @keydown="local_prevent_non_numerics"
                                    @input="local_trigger_changes('maximum_value', item.maximum_value)"
                                    solo flat hide-details>
                                </a-text-field>
                            </template>
                            <span v-if="error_response && error_response.maximum_value">{{ error_response.maximum_value }}</span>
                        </a-tooltip>
                    </div>
                </div>
                <div class="md-caption grey--text text--darken-1 mt-2">
                    <a-icon color="grey" size="16" class="u-icon-nudge">info</a-icon>
                    The score range sets the minimum and maximum limits for scoring
                </div>
            </div>

             <!-- Singleselect and Multiselect -->
            <div class="mt-4" v-if="fieldType && (fieldType !== 'score')">
                <label class="mb-4 grey--text text--darken-2">Options <span class="red--text">*</span></label>
                <draggable
                    v-model="item.options"
                    :options="{handle: '.js-drag-handle', animation: 200}"
                >
                    <div class="u-flex align-center mb-4 mt-3" v-for="(option, index) in item.options" :key="index">
                        <a-icon class="js-drag-handle u-cursor-pointer">drag_indicator</a-icon>
                        <a-tooltip bottom :disabled="!(error_response && error_response.options && error_response.options.label[option.id])">
                            <template v-slot:activator="{ on }">
                                <a-text-field
                                    v-model="option.label"
                                    v-on="on"
                                    class="mx-2"
                                    :class="[(error_response && error_response.options && error_response.options.label[option.id]) ? 'c-wiggle-short u-border-error': 'u-border']"
                                    placeholder="Enter label"
                                    background-color="grey lighten-5"
                                    solo flat hide-details>
                                </a-text-field>
                            </template>
                            <span v-if="error_response && error_response.options">{{ error_response.options.label[option.id] }}</span>
                        </a-tooltip>
                        <a-tooltip bottom :disabled="!(error_response && error_response.options && error_response.options.value[option.id])">
                            <template v-slot:activator="{ on }">
                                <a-text-field
                                    v-model="option.value"
                                    v-on="on"
                                    type="number"
                                    min="0"
                                    step="1"
                                    title=""
                                    class="mx-2"
                                    background-color="grey lighten-5"
                                    placeholder="Enter score"
                                    :class="[(error_response && error_response.options && error_response.options.value[option.id]) ? 'c-wiggle-short u-border-error': 'u-border']"
                                    @keydown="local_prevent_non_numerics"
                                    @input="local_trigger_changes('option', option)"
                                    solo flat hide-details>
                                </a-text-field>
                            </template>
                            <span v-if="error_response && error_response.options">{{ error_response.options.value[option.id] }}</span>
                        </a-tooltip>
                        <div v-if="fieldType == 'multi_select'" class="u-border pa-2 mx-2 u-rounded-corners">
                            <s-color-palette v-model="option.color" :maxWidth="320"></s-color-palette>
                        </div>
                        <a-tooltip bottom v-if="loading_item !== option.id">
                            <template v-slot:activator="{ on }">
                                <div class="u-border pa-2 ml-2 u-flex u-rounded-corners u-cursor-pointer" v-on="on" @click="local_select_remove_option(option.id)">
                                    <a-icon color="grey" size="22">remove_circle_outline</a-icon>
                                </div>
                            </template>
                            <span>
                                <template v-if="(index === 0) && (item.options && item.options.length === 1)">
                                    Minimum one option is required
                                </template>
                                <template v-else>
                                    Remove data
                                </template>
                            </span>
                        </a-tooltip>
                        <a-tooltip bottom v-else>
                            <template v-slot:activator="{ on }">
                                <div v-on="on" class="u-border pa-2 ml-2 u-flex u-rounded-corners red lighten-5 u-cursor-pointer" @click="local_remove_option(option.id, option)">
                                    <a-icon color="red" size="22">remove_circle_outline</a-icon>
                                </div>
                            </template>
                            <span>
                                Confirm?
                            </span>
                        </a-tooltip>
                    </div>
                </draggable>
                <div class="ml-8 mt-4">
                    <a-btn @click="local_add_option_item()" color="grey lighten-3" class="py-4" small rounded depressed outlined>
                        <a-icon color="indigo" size="20">add</a-icon>
                        <span class="indigo--text md-subtitle-2 text-uppercase" style="font-weight: 600">Add option</span>
                    </a-btn>
                </div>
            </div>
            <div class="mt-4">
                <label class="d-block mb-3 mt-0 font-weight-medium grey--text text--darken-1">Description</label>
                <a-textarea
                    v-model="item.description"
                    placeholder="Enter the description"
                    background-color="grey lighten-5"
                    class="u-border"
                    rows="2"
                    solo flat hide-details multiple chips small-chips no-resize
                >
                </a-textarea>
                <div class="md-caption red--text text--darken-1 d-block mt-2" v-if="error_response && error_response.description">
                    <a-icon size="16" color="red darken-2" class="u-icon-nudge">info</a-icon>
                    {{ error_response.description }}
                </div>
                <div v-else class="u-flex u-wfull">
                    <span class="md-caption grey--text text--darken-1 d-block mt-2">
                        <a-icon color="grey" size="16" class="u-icon-nudge">info</a-icon>
                        This description will be shown as tooltip
                    </span>
                    <a-spacer></a-spacer>
                    <span class="md-caption grey--text mt-2 text-right">(Optional)</span>
                </div>
            </div>
            <div class="mt-4">
                <label class="d-block mb-3 mt-0 font-weight-medium grey--text text--darken-1">Field Width</label>
                <div class="c-field-width-toggle-container u-flex align-center u-rounded-corners pa-1">
                    <a-tooltip bottom :disabled="fieldType !== 'multi_select'" nudge-right="105" content-class="c-tooltip-pointer">
                        <template v-slot:activator="{ on }">
                            <div
                                v-on="on"
                                :class="[ item.width === '50%' ? 'c-field-width-toggle__active' : 'grey--text', 'mr-2 c-field-width-toggle u-flex align-center' ]"
                                @click="fieldType === 'multi_select' ? {} : local_change_width(item, '50%')"
                            >
                                <span class="md-body-2 font-weight-medium px-1">50%</span>
                            </div>
                        </template>
                        <div>
                            <p class="mb-0">Multi selection field does not support 50% width</p>
                        </div>
                    </a-tooltip>
                    <div
                        :class="[ item.width === '100%' ? 'c-field-width-toggle__active' : 'grey--text', 'c-field-width-toggle u-flex align-center' ]"
                        @click="local_change_width(item, '100%')"
                    >
                        <span class="md-body-2 font-weight-medium px-1">100%</span>
                    </div>
                </div>
                <div class="u-flex u-wfull">
                    <span class="md-caption grey--text text--darken-1 d-block mt-2">
                        <a-icon color="grey" size="16" class="u-icon-nudge">info</a-icon>
                        Select the width of the field
                    </span>
                </div>
            </div>
        </div>
        <div class="pb-6 white">
            <a-divider class="mb-6"></a-divider>
            <div v-if="affecting_change" class="amber lighten-5 md-body-2 pa-4 mx-8 mb-6 u-rounded-corners">
                <div class="u-flex align-center orange--text text--darken-3 mb-3">
                    <a-icon size="16" color="orange darken-3">warning</a-icon>
                    <span class="font-weight-medium ml-1">The changes impacted the following:</span>
                </div>
                <div class="grey--text text--darken-2 mb-4">
                    <p class="mb-2">Modifying {{ fieldType !== 'score' ? 'or deleting a field option' : 'the score range'}} will globally reflect the selected values in projects. If it's modified here, the confidence score will be recalculated.</p>
                </div>
                <div class="u-flex align-center">
                    <a-checkbox id="confirmCheck" v-model="confirmChanges" class="pa-0 ma-0" dense hide-details></a-checkbox>
                    <label for="confirmCheck" class="text-uppercase u-cursor-pointer font-weight-medium">Confirm changes</label>
                </div>
            </div>
            <div class="px-8">
                <a-btn class="c-save-btn mr-4 white--text indigo darken-1" :disabled="(affecting_change && !confirmChanges) || local_loading" :loading="local_loading" @click="local_modify_field()" depressed>{{ mode === 'create' ? 'Add' : 'Update'}} field</a-btn>
                <a-btn class="grey--text" @click="$emit('close')" depressed>Cancel</a-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { number } from '@/helpers/helper-number-filter'
import { Validate } from '@/helpers/helper-validations'
import { SColorPalette } from '@/config/config-shared-components'
import { mapActions, mapState } from 'vuex'

export default {
    components: { SColorPalette },

    props: {
        list: {
            type: Array,
            required: true
        },
        item: {
            type: Object,
            required: true
        },
        backupItem: {
            type: Object,
        },
        mode: {
            type: String,
            required: true
        },
        fieldType: {
            type: String,
            required: true
        },
        isOpen: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            local_field_type_list: [
                { type: 'score', label: 'Score', icon: 'pin' },
                { type: 'dropdown', label: 'Selection (Single)', icon: 'arrow_drop_down_circle' },
                { type: 'multi_select', label: 'Selection (Multiple)', icon: 'check_box' },
            ],
            error_response_item: { minimum_value: null, maximum_value: null, options: {label: {}, value: {}}, name: null, name_backend: null, label: null, description: null },
            error_response: {},
            loading_item: null,
            option_remove_timer: null,
            duplicates: [],
            confirmChanges: false,
            backup_field: {},
            affecting_change: false,
            modified_item: {},
            identifier_validation_timeout: null,
            local_loading: false,
            modified_color_option_ids: []
        }
    },

    watch: {
        isOpen: {
            immediate: true,
            handler (newVal, oldVal) {
                if (newVal) this.local_index()
                else this.local_loading = false
            }
        }
    },

    computed: {
        local_get_field_icon () {
            const field_item = this.local_field_type_list.find(item => item.type === this.fieldType)
            if (field_item) return field_item.icon
        },

        local_get_field_label () {
            const field_item = this.local_field_type_list.find(item => item.type === this.fieldType)
            if (field_item) return field_item.label
        },

        ...mapState('ConfidenceScore', {
            confidence_section_list: 'list',
            error_response_data: 'response'
        })
    },

    methods: {
        local_index () {
            this.backup_field = _.cloneDeep(this.backupItem)
            this.modified_item = {}
            this.affecting_change = false
            this.confirmChanges = false
            this.error_response = _.cloneDeep(this.error_response_item)
            this.$nextTick(() => {
                this.$refs.formContainer.scrollTop = 0
            })
        },

        local_add_option_item () {
            const optionItem = this.$appendId({ color: this.$randomColor(), label: null, value: null })
            this.item.options.push(optionItem)
            if (this.mode === 'create') return
            if (this.item.options.length !== this.backup_field.options.length) this.affecting_change = true
        },

        local_change_width (item, width) {
            this.item.width = width
        },

        local_generate_name() {
            if (this.mode !== 'create') return
            this.item.name = _.snakeCase(this.item.label)
        },

        async local_modify_field () {
            this.local_loading = true
            this.error_response = _.cloneDeep(this.error_response_item)
            this.local_validate_inputs()
            this.local_trigger_changes()

            if (this.item.name) await this.confidence_validate_identifier({id: this.item.id, name: this.item.name})
            else {
                this.local_loading = false
                return this.error_response.name_backend = 'Required field'
            }
            this.error_response.name_backend = (this.error_response_data && this.error_response_data.status !== 'success') ? this.error_response_data.text : null

            if ((this.error_response && this.error_response.name) || (this.error_response && this.error_response.name_backend) || (this.error_response && this.error_response.label) || (this.error_response && this.error_response.description) || (this.local_check_invalid_options() || this.local_check_invalid_score())) return this.local_loading = false

            const editItem = _.cloneDeep(this.item)
            if (this.mode === 'create') this.$emit('create', this.item)
            else  this.$emit('update', { arg: { ...editItem, type: this.fieldType,  module: 'field' }, modified_item: {...this.modified_item}, color_modified_option_ids: this.modified_color_option_ids })
        },

        local_validate_inputs () {
            this.local_validate_label()
            this.local_validate_identifier()
            this.local_validate_description()
            if (this.fieldType !== 'score') this.local_validate_options()
            else this.local_validate_score()
        },

        local_validate_label () {
            if (this.item && !this.item.label) this.error_response.label = 'Required field'
            else {
                const { message } = new Validate(this.item.label, { silent: true }).length(1, 255).run()
                this.error_response.label = message ? message : null
            }
        },

        local_validate_identifier () {
            if (this.item && !this.item.name) return this.error_response.name = 'Required field'
            else {
                const { message } = new Validate(this.item.name, { silent: true }).length(1, 255).run()
                this.error_response.name = message ? message : null
                if (!message) this.local_validate_duplicate_identifier()
            }
        },

        local_validate_duplicate_identifier () {
            const fields = this.list.flatMap(section => {
                return section.fields.map(({id, name}) => ({id, name}));
            })
            const filteredList = fields.filter(field => field.id !== this.item.id)
            const field_name_list = filteredList.map(field => field.name)
            const duplicate = field_name_list.indexOf(this.item.name) !== -1
            this.error_response.name =  duplicate ? 'Field with this identifier already exists. Please enter a unique identifier string.' : null
        },

        local_validate_description () {
            const { message } = new Validate(this.item.description, { silent: true }).length(0, 1000).run()
            this.error_response.description = message ? message : null
        },

        local_validate_options () {
            this.local_validate_option_label()
            this.local_validate_option_value()
            this.local_set_error_response()
        },

        local_check_invalid_options () {
            const errors = []
            for(let key in this.error_response.options.label) {
                const value = this.error_response.options.label[key]
                if (value) errors.push(value)
            }
            for(let key in this.error_response.options.value) {
                const value = this.error_response.options.value[key]
                if (value) errors.push(value)
            }
            return !!(errors && errors.length)
        },

        local_check_invalid_score () {
            if ((this.item.type && this.item.type !== 'score') || this.fieldType !== 'score') return
            return (this.error_response.minimum_value || this.error_response.maximum_value)
        },

        local_validate_option_label () {
            const labels = this.item.options.map(({ id, label }) => ({ id, label }))

            labels.forEach((item, index) => {
                if (item && !item.label) this.error_response.options.label[item.id] = 'Required field'
                else {
                    const { message } = new Validate(item.label, { silent: true }).length(1, 255).run()
                    this.error_response.options.label[item.id] = message ? message : null
                }
            })

            const uniqueItems = []
            this.duplicates = []
            labels.forEach((item, index) => {
                if (item && !item.label) return
                const currentItem = item.label
                if(uniqueItems.indexOf(currentItem) === -1) uniqueItems.push(currentItem)
                else if (item && item.label && this.duplicates.indexOf(item.id) === -1) this.duplicates.push(item.id)
            });
        },

        local_validate_option_value () {
            const scores = this.item.options.map(({ id, value }) => ({ id, value }))
            if (this.fieldType === 'multi_select') {
                let total = 0
                scores.forEach(item => {
                    total = parseInt(total) + parseInt(item.value)
                })

                scores.forEach((score, index) => {
                    if (score && !score.value) this.error_response.options.value[score.id] = 'Required field'
                    else if (total > 10000) this.error_response.options.value[score.id] = 'Total score limit of all options should not exceed 10000'
                })
                return
            }

            scores.forEach((score, index) => {
                if (score && !score.value) this.error_response.options.value[score.id] = 'Required field'
                else if (score && score.value > 10000) this.error_response.options.value[score.id] = 'Score limit should not exceed 10000'
            })
        },

        local_set_error_response() {
            if (this.duplicates && this.duplicates.length) {
                this.duplicates.forEach(item => {
                    this.error_response.options.label[item] = "The option label has a duplicate value"
                })
            }
        },

        local_select_remove_option (id) {
            clearTimeout(this.option_remove_timer)
            if (this.item.options && this.item.options.length === 1) return
            this.loading_item = id
            this.option_remove_timer = setTimeout(() => {
                this.loading_item = null
            }, 3000);
        },

        local_remove_option (id, option) {
            const index = _.findIndex(this.item.options, {id: id})
            if (index !== -1) this.item.options.splice(index, 1)

            if (this.mode === 'edit' && !this.item.new) {
                this.affecting_change = this.local_check_saved_option(option) || (this.item.options.length !== this.backup_field.options.length) || this.local_check_option_change('onRemove')

            }
            this.loading_item = null
        },

        local_check_saved_option (value) {
            const saved_options = this.backup_field.options.map(item => item.id)
            if (saved_options.indexOf(value.id) !== -1) return true
            else return false
        },

        local_prevent_non_numerics (e) {
            if (!number(e)) e.preventDefault()
        },

        local_validate_score () {
            this.error_response.minimum_value = !this.item.minimum_value && this.item.minimum_value !== 0 ? 'Required field' : parseInt(this.item.minimum_value) > 10000 ? 'Score limit should not exceed 10000' : null
            if (!this.item.maximum_value && this.item.maximum_value !== 0) {
                this.error_response.maximum_value = !this.item.maximum_value && this.item.maximum_value !== 0 ? 'Required field' : parseInt(this.item.maximum_value > 10000) ? 'Score limit should not exceed 10000' : null
            }
            else this.error_response.maximum_value = parseInt(this.item.maximum_value) > 10000 ? 'Score limit should not exceed 10000' : parseInt(this.item.minimum_value) >= parseInt(this.item.maximum_value) ? 'Maximum score must be greater than Minimum score' : null
        },

        local_trigger_changes (fieldType = null, value) {
            if (fieldType === 'identifier' && fieldType && this.item.name) this.local_validate_identifier_backend()

            if (this.mode === 'create' || this.item.new) return

            if (!fieldType) return this.local_check_modifications()
            if (fieldType === 'identifier') return this.affecting_change = (this.backup_field.name !== value.trim())
            if (this.item.type === 'score') {
                this.affecting_change = (this.backup_field[fieldType] !== parseInt(value))
            }
            if (this.item.type !== 'score') {
                const changes = this.backup_field.options.filter(option => {
                    return (option.id === value.id && option.value !== value.value)
                })
                this.affecting_change = !!(changes && changes.length) || (this.item.options.length !== this.backup_field.options.length) || this.local_check_option_change('onRemove') || this.local_check_option_added()
            }
        },

        async local_validate_identifier_backend (type) {
            clearTimeout(this.identifier_validation_timeout)
            this.identifier_validation_timeout = setTimeout(async () => {
                await this.confidence_validate_identifier({id: this.item.id, name: this.item.name})
                this.error_response.name = (this.error_response_data && this.error_response_data.status !== 'success') ? this.error_response_data.text : null
            }, 1000);
        },

        local_check_modifications () {
            if (this.item.new) return
            this.modified_item = {options: []}
            this.modified_item.id = this.item.id
            this.modified_item.title = this.item.label
            this.modified_item.module = 'field'
            this.modified_item.type = this.item.type
            this.local_check_label_change()
            this.local_check_identifier_change()
            this.local_check_width_change()
            this.local_check_description_change()
            if (this.item.type !== 'score') this.local_check_option_change()
            else this.local_check_range_change()
            if (this.item.type === 'multi_select') this.local_check_color_change()
        },

        local_check_label_change () {
            if (this.backup_field.label !== this.item.label.trim()) this.modified_item.label = this.item.label.trim()
        },

        local_check_identifier_change () {
            if (this.backup_field.name !== this.item.name.trim()) this.modified_item.name = this.item.name.trim()
        },

        local_check_width_change () {
            if (this.backup_field.width !== this.item.width) this.modified_item.width = this.item.width
        },

        local_check_description_change () {
            if (this.backup_field.description !== this.item.description) this.modified_item.description = this.item.description
        },

        local_check_range_change () {
            if (this.backup_field.minimum_value !== parseInt(this.item.minimum_value)) this.modified_item.minimum_value = this.item.minimum_value
            if (this.backup_field.maximum_value !== parseInt(this.item.maximum_value)) this.modified_item.maximum_value = this.item.maximum_value
        },

        local_check_option_change (action = null) {
            const modified_option = this.item.options.filter((option, itemIndex) => {
                const index = this.backup_field.options.findIndex(item => item.id === option.id)
                if (index !== -1) return (action === 'onRemove') ? (this.backup_field.options[index].value !== option.value) : (this.backup_field.options[index].label !== option.label) || (this.backup_field.options[index].value !== option.value) || (index !== itemIndex)
            })
            if (action === 'onRemove') return !!(modified_option && modified_option.length)
            if (!!_.size(modified_option) || this.local_check_option_added() || this.local_check_option_deleted()) this.modified_item.options = [...this.item.options]
        },

        local_check_color_change () {
            this.item.options.forEach(option => {
                const index = this.backup_field.options.findIndex(item => item.id === option.id)
                if (this.modified_color_option_ids.indexOf(option.id) !== -1) this.modified_color_option_ids.splice(this.modified_color_option_ids.indexOf(option.id), 1)
                if (index !== -1 && this.backup_field.options[index].color !== option.color) this.modified_color_option_ids.push(option.id)
            })
        },

        local_check_option_added () {
            const saved_option_ids = this.backup_field.options.map(option => option.id)
            const added_option = this.item.options.filter(option => {
                return saved_option_ids.indexOf(option.id) === -1
            })

            return !!(added_option && added_option.length)
        },

        local_check_option_deleted () {
            const saved_option_ids = this.backup_field.options.map(option => option.id)
            const current_option_ids = this.item.options.map(option => option.id)
            const deleted_option = saved_option_ids.filter(item => {
                return current_option_ids.indexOf(item) === -1
            })

            return !!(deleted_option && deleted_option.length)
        },

        ...mapActions('ConfidenceScore', {
            confidence_validate_identifier: 'validate_identifier'
        })
    }
}
</script>

<style scoped>
    .c-field-width-toggle-container {
        border: 1px solid #EEEEEE !important;
        background-color: white;
        width: 288px;
        height: 40px;
    }
    .c-field-width-toggle {
        width: 144px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .c-field-width-toggle__active {
        border: 0px solid transparent !important;
        background-color: #E8EAF6;
        color: #3949AB;
        border-radius: 4px;
    }
    .c-save-btn.v-btn.v-btn--disabled {
        opacity: 0.5;
        background-color: #3949AB !important;
        color: #fff !important;
    }

</style>

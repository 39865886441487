<template>
    <div v-on="$listeners" :class="['u-shadow', { 'c-public-border': isPublic }]">
        <a-sheet :class="['u-rounded-corners pa-6', canUpdate ? handle : '']">
            <!-- <h3 class="c-title-color md-subtitle-1 font-weight-medium">Successfully block at least 85% of unintended orders from spam buyers e-commerce via process-centric "outside the box" thinking</h3> -->
            <h3 class="c-title-color md-subtitle-1 font-weight-medium">{{ item.title }}</h3>
            <div class="u-flex mt-4">
                <slot name="attachment-count"></slot>
            </div>
            <div class="u-flex-center-y mt-4" v-if="!hideMetaInfo">
                <slot name="status"></slot>

                <slot name="assignee"></slot>

                <slot name="dates">
                    <div class="u-flex align-center u-cursor-pointer mr-5" @click="$emit('testcase-edit', true)" v-if="local_is_testcase && (item.start_date || item.due_date)">
                        <template v-if="item.start_date && item.due_date">
                            <a-icon size="18" color="body lighten-3" class="mr-2">date_range</a-icon>
                            <g-moment class="md-body-2 body--text text--lighten-2" :value="item.start_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(item, 'range')"></g-moment>
                            <span class="mx-1 body--text text--lighten-2"> - </span>
                            <g-moment class="md-body-2" :class="[local_check_due_date(item) ? 'red--text text--darken-2' : 'body--text text--lighten-2']" :value="item.due_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(item, 'range')"></g-moment>
                        </template>
                        <template v-if="item.start_date && !item.due_date">
                            <a-icon size="18" color="body lighten-3" class="mr-2">today</a-icon>
                            <span class="md-body-2 body--text text--lighten-2 mr-1">Start Date: </span>
                            <g-moment class="md-body-2" :class="[local_check_due_date(item) ? 'red--text text--darken-2' : 'body--text text--lighten-2']" :value="item.start_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(item.start_date)"></g-moment>
                        </template>
                        <template v-if="!item.start_date && item.due_date">
                            <a-icon size="18" color="body lighten-3" class="mr-2">event</a-icon>
                            <span class="md-body-2 body--text text--lighten-2 mr-1">Due Date: </span>
                            <g-moment class="md-body-2" :class="[local_check_due_date(item) ? 'red--text text--darken-2' : 'body--text text--lighten-2']" :value="item.due_date" input-format="YYYY-MM-DD" :output-format="local_date_output_format(item.due_date)"></g-moment>
                        </template>
                    </div>
                </slot>

                <g-attachment-count-info
                    :count="item.attachments_count"
                    class="mr-4"
                    fontSize="14"
                />

                <slot name="comments">
                    <div class="u-flex-center-y mr-6" v-if="item.comments_count">
                        <a-icon size="18" color="body lighten-3">chat_bubble</a-icon>
                        <span class="md-body-2 body--text text--lighten-2 ml-2">{{ item.comments_count }}</span>
                    </div>
                </slot>

                <slot name="time-tracking" v-if="local_is_testcase && local_has_time_track && !hideTimeTrack">
                    <div class="u-cursor-pointer" @click.stop="local_open_time_track(item)">
                        <a-tooltip bottom :disabled="!local_tracked_is_greater">
                            <template v-slot:activator="{ on }">
                                <div v-on="on" class="u-flex-center-y">
                                    <a-icon :color="local_tracked_is_greater ? 'orange darken-2' : 'body lighten-3'" size="18" class="mr-2">timer</a-icon>
                                    <span class="md-body-2 body--text text--lighten-2">
                                        {{ item.time_records_sum_duration_minutes ? local_get_time_text(item.time_records_sum_duration_minutes) : '0m' }}
                                        <template v-if="item.estimated_duration_text">
                                            / {{ item.estimated_duration_text }}
                                        </template>
                                    </span>
                                </div>
                            </template>
                            <div class="u-flex-center-y">
                                <a-icon size="18" color="orange darken-2" class="mr-1">info</a-icon>
                                <span>Over Estimation</span>
                            </div>
                        </a-tooltip>
                    </div>
                </slot>
            </div>
        </a-sheet>
    </div>
</template>

<script>
import { calculateByMins } from '@/helpers/helper-time-tracker'
import { diffFormat } from '@/helpers/helper-date-filter'

export default {
    inheritAttrs: false,

    props: {
        item: {
            type: Object,
            required: true
        },
        canUpdate: {
            type: Boolean,
        },
        hideMetaInfo: {
            type: Boolean,
            default: false
        },
        handle: {
            type: String
        },
        module: {
            type: String
        },
        isPublic: {
            type: Boolean,
        },
        hideTimeTrack: {
            type: Boolean,
        },
    },

    computed: {
        local_is_testcase () {
            return this.module === 'Testcase'
        },

        local_has_time_track () {
            const { estimated_duration_minutes, estimated_duration_text, time_records_sum_duration_minutes } = this.item
            return !!(estimated_duration_minutes || estimated_duration_text || time_records_sum_duration_minutes)
        },

        local_tracked_is_greater () {
            return this.item && this.item.estimated_duration_minutes && (Number(this.item.time_records_sum_duration_minutes) > this.item.estimated_duration_minutes)
        },
    },

    methods: {
        local_check_due_date ({ due_date, status }) {
            if (status) return moment(due_date).isBefore() && status.status === 'Open'
        },

        local_get_time_text (value) {
            const { durationText } = calculateByMins(value)
            return durationText
        },

        local_open_time_track (item) {
            this.$emit('open-timetracker', item)
        },

        local_date_output_format (date, type = null) {
            return diffFormat(date, type)
        }
    }
}
</script>

<template>
	<a-main>
        <a-responsive class="grey darken-4 mt-5 u-rounded-corners-lg">
            <a-container grid-list-xl class="py-3 mx-0">
                <a-layout align-start>
                    <a-flex shrink class="px-1 py-1">
                        <a-btn class="ma-1" color="white" text :to="{name: 'settings-roles'}" exact>
                            <a-icon class="mr-1">arrow_back</a-icon> Back to roles
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive>
        <a-container grid-list-xl container--fluid class="pa-6 pt-2 px-0 mb-8">
            <g-page-header
                :title="role_item && role_item.name ? 'Role Management: ' + role_item.name : 'Loading...'"
                subtitle="Manage permission of the role selected."
                icon="verified_user"
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
            ></g-page-header>
            <a-layout class="pb-5" v-if="local_loading">
                <a-flex xs12>
                    <a-divider class="mb-5 mt-2"></a-divider>
                    <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching permissions...</h3>
                </a-flex>
            </a-layout>
            <template v-else>
                <a-layout wrap class="mb-8" v-if="role_item && role_item.scope === 'internal'">
                    <a-flex xs12>
                        <div class="u-flex align-center">
                            <h2 class="md-body-2 grey--text text--darken-1 font-weight-medium text-uppercase">Project Visibility Scope</h2>
                            <a-divider class="mb-1 ml-4"></a-divider>
                        </div>
                    </a-flex>
                    <a-flex xs12>
                        <a-card class="u-elevation-custom-1 u-rounded-corners mt-4" v-for="(scope) in poc_visibility_scopes" :key="scope.key">
                            <a-container container--fluid grid-list-xl pa-0 px-2>
                                <a-layout wrap align-center>
                                    <a-flex xs8>
                                        <div class="u-flex align-center py-2 px-4">
                                            <h2 class="text-subtitle-1 grey--text text--darken-3 mr-3">{{ scope.title }}</h2>
                                            <span class="md-body-2 grey--text text--darken-1 d-inline-block">{{ scope.description }}</span>
                                        </div>
                                    </a-flex>
                                    <a-spacer></a-spacer>
                                    <a-flex shrink>
                                        <a-menu bottom offset-y transition="slide-y-transition">
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on" class="u-cursor-pointer d-inline-flex align-center grey lighten-4 pl-4 pr-2 py-1 u-rounded-corners" style="min-width: 180px;">
                                                    <span class="md-body-2 grey--text text--darken-4 text-capitalize">{{ scope.default }}</span>
                                                    <a-spacer></a-spacer>
                                                    <a-icon size="22" class="ml-1" color="grey darken-4">arrow_drop_down</a-icon>
                                                </div>
                                            </template>
                                            <a-list class="u-list-condensed">
                                                <template v-for="option in scope.values">
                                                    <a-list-item :key="option.label" @click="local_update_scope(option.value, scope.key)">
                                                        <a-list-item-title class="grey--text text--darken-2">{{ option.label }}</a-list-item-title>
                                                    </a-list-item>
                                                </template>
                                            </a-list>
                                        </a-menu>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card>
                    </a-flex>
                </a-layout>
                <a-layout wrap>
                    <a-flex xs12 v-if="role_item && role_item.scope === 'internal'">
                        <div class="u-flex align-center">
                            <h2 class="md-body-2 grey--text text--darken-1 font-weight-medium text-uppercase">Permissions</h2>
                            <a-divider class="mb-1 ml-4"></a-divider>
                        </div>
                    </a-flex>
                    <a-flex xs12>
                        <a-card class="mb-10 mt-4 u-elevation-custom-1 u-rounded-corners" v-for="(permissions, module) in local_group_by('name')" :key="'module_'+module">
                            <a-card-text class="pa-0">
                                <a-container container--fluid grid-list-xl pa-0 px-6>
                                    <a-layout wrap align-center>
                                        <a-flex xs12 pa-0 class="u-flex align-center justify-space-between">
                                            <div class="py-4 px-4 pb-6">
                                                <h2 class="subtitle-1 grey--text text--darken-4 mt-0">{{ module }}</h2>
                                            </div>
                                            <div class="pb-2">
                                                <a-menu bottom offset-y transition="slide-y-transition">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on" class="u-cursor-pointer d-inline-flex align-center grey lighten-4 pl-4 pr-2 py-1 u-rounded-corners" style="min-width: 180px;">
                                                            <span class="md-body-2 grey--text text--darken-4" v-if="permissions[0]">{{ local_get_current_permission(permissions[0].values, permissions[0].value, 'label') }}</span>
                                                            <a-spacer></a-spacer>
                                                            <a-icon size="22" class="ml-1" color="grey darken-4">arrow_drop_down</a-icon>
                                                        </div>
                                                    </template>
                                                    <a-list class="u-list-condensed" v-if="permissions[0]">
                                                        <template v-for="(value, index) in permissions[0].values">
                                                            <a-list-item
                                                                v-if="(
                                                                    permission_scope === 'external' && value.scope === 'external') ||
                                                                    (permission_scope === 'internal' && (value.scope === 'external' || value.scope === 'internal')
                                                                )"
                                                                :key="value.name + '-' + index"
                                                                @click="local_permission_change(value, permissions[0].id, permissions[0].value_id, permissions[0].value)">
                                                                <a-list-item-title class="grey--text text--darken-2">{{ value.label }}</a-list-item-title>
                                                            </a-list-item>
                                                        </template>
                                                    </a-list>
                                                </a-menu>
                                            </div>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                                <a-divider></a-divider>
                                <a-container container--fluid grid-list-xl class="pa-6 pt-4 pb-2" v-if="permissions[0] && permissions[0].slug === 'projects' || permissions[0].slug === 'documents'">
                                    <span class="md-body-2 grey--text text--darken-1 d-block mb-2">With the defined permission, users in this role can:</span>
                                    <div class="d-inline-flex align-center mr-4 mb-4" v-for="(action, index) in permissions[0].actions" :key="index">
                                        <a-icon v-if="(local_get_current_permission(permissions[0].values, permissions[0].value, 'value') === 2 && action.value === 4) || local_get_current_permission(permissions[0].values, permissions[0].value, 'value') >= action.value" size="16" color="green darken-1" class="mr-2">done</a-icon>
                                        <a-icon v-else size="16" color="red darken-1" class="mr-2">close</a-icon>
                                        <span class="md-body-2 grey--text text--darken-3">{{ action.label }}</span>
                                    </div>
                                </a-container>
                                <a-container container--fluid grid-list-xl class="pa-6 pt-4 pb-2" v-else>
                                    <span class="md-body-2 grey--text text--darken-1 d-block mb-2">With the defined permission, users in this role can:</span>
                                    <div class="d-inline-flex align-center mr-4 mb-4" v-for="(action, index) in permissions[0].actions" :key="index">
                                        <a-icon v-if="local_get_current_permission(permissions[0].values, permissions[0].value, 'value') >= action.value" size="16" color="green darken-1" class="mr-2">done</a-icon>
                                        <a-icon v-else size="16" color="red darken-1" class="mr-2">close</a-icon>
                                        <span class="md-body-2 grey--text text--darken-3">{{ action.label }}</span>
                                    </div>
                                </a-container>
                            </a-card-text>
                        </a-card>
                    </a-flex>
                </a-layout>
            </template>
        </a-container>
        <a-snackbar v-model="snackbar_status" :timeout="4000" bottom center :color="snackbar_response_status === 'error' ? 'red' : 'green'">
            {{ snackbar_text }}
        </a-snackbar>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mixinGetPreference from '@/mixins/mixin-get-preference'
import { ValidRoutes } from '../../helpers/helper-get-valid-routes'

export default {
    mixins: [mixinGetPreference],

    data() {
        return {
            local_loading: true,
            permission_scope: '',
            snackbar_status: false,
            snackbar_text: '',
            snackbar_response_status: '',
            scope_value: {},
            poc_visibility_scopes: [
                {
                    title: 'Territory',
                    description: 'Territory scopes',
                    key: 'territory',
                    default: 'all',
                    values: [{ label: 'All', value: 'all' }, { label: 'Only Assigned', value: 'assigned' }]
                },
                {
                    title: 'Collaboration Status',
                    description: 'Collaboration scopes',
                    key: 'collaborator',
                    default: 'all',
                    values: [{ label: 'All', value: 'all' }, { label: 'Only Assigned', value: 'assigned' }, { label: 'Direct Reports', value: 'team-direct' }, { label: 'All Reports', value: 'team-all' }]
                },
            ]
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Roles', to: '/settings/roles', exact: true})
            breadcrumbs.push({text: 'Permissions', to: `/settings/roles/${this.$route.params.id}`, exact: true})
            return breadcrumbs
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Scope', {
            scope_list: 'list',
            scope_response: 'response',
        }),
        ...mapState('Role', {
            role_item: 'item',
            role_form_mode: 'form_mode',
            role_response: 'response',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
    methods: {
        async local_index() {
            await this.role_clear()
            await this.role_show({ id: this.$route.params.id, 'include': 'source.permissions' })
            await this.preference_index()
            this.permission_scope = this.role_item.scope
            if (this.role_item && this.role_item.scope === 'internal') {
                await this.local_scope_index()
            }
            this.local_loading = false
        },
        async local_set_scope() {
            if (this.scope_list && this.scope_list.length) {
                this.scope_list.filter(item => {
                    const index = this.poc_visibility_scopes.findIndex(local => local.key === item.key)
                    const scope_item = this.poc_visibility_scopes[index].values.find(scope => scope.value === item.value)
                    if (index !== -1) this.poc_visibility_scopes[index].default = scope_item.label
                })
            }
        },
        async local_update_scope(value, key) {
            this.snackbar_status = false
            await this.scope_update({
                key, value,
                resource_id: this.$route.params.id,
                resource_type: 'Role',
                type: 'project',
            })
            if (this.scope_response && this.scope_response.status === 'success') {
                this.local_show_success('Project visibility scope updated successfully')
            }
            await this.local_scope_index()
        },
        async local_scope_index() {
            await this.scope_index({
                'filter[resource_id]': this.$route.params.id,
                'filter[resource_type]': 'Role',
                'filter[type]': 'project',
            })
            this.local_set_scope()
            ValidRoutes().configure()
        },
        async local_role_edit(id) {
            await this.role_select({id: id})
            this.dialog_role_form = true
        },
        async local_role_create() {
            await this.role_clear_item()
            this.dialog_role_form = true
        },
        async local_role_store() {
            this.dialog_role_form = false
            if (this.role_form_mode == 'add') {
                await this.role_store()
            } else {
                await this.role_update()
            }
        },
        async local_role_destroy(id) {
            await this.role_destroy({id: id})
        },
        async local_role_reorder(list) {
            this.role_reorder({list: list})
        },
        // async local_permission_change(id, permission, default_value, overwritten) {
        //     let index = _.findIndex(this.role_item.permissions, { 'id': id });
        //     this.role_item.permissions[index].current_value = permission;
        //     if (permission == default_value) {
        //         if (overwritten) {
        //             await this.role_permission_destroy({id: this.role_item.id, permission_id: id})
        //             this.role_item.permissions[index].overwritten = false;
        //         }
        //     } else {
        //         await this.role_permission_update({id: this.role_item.id, permission_id: id, value: permission})
        //         this.role_item.permissions[index].overwritten = true;
        //     }
        //     this.user_me()
        // },
        async local_permission_change(selectedValue, permission_id, value_id, current_value) {
            if (selectedValue.value === current_value) return
            this.snackbar_status = false
            await this.role_permission_update({ value: selectedValue.value, permission_id, role_id: this.$route.params.id, value_id })
            if (this.role_response.status === 'success') {
                this.local_show_success('Permission has been updated successfully')
                await this.auth_user_permission_index()
                ValidRoutes().configure()
            }
        },
        local_show_success(text, message = 'success') {
            this.snackbar_text = text
            this.snackbar_response_status = message
            this.snackbar_status = true
        },
        local_group_by(field) {
            const data = this.role_item && this.role_item.permissions ? this.role_item.permissions : ''
            const { flag_inventory, flag_insights_dashboard, flag_automation, flag_confidence_score } = this.user_self && this.user_self.organization
            if (data) {
                const filteredPermissions = data.filter(item => {
                    // to do remove applets slug later
                    if (item.slug === 'inventories' && flag_inventory === 0) return
                    if (item.slug === 'insights_dashboard' && flag_insights_dashboard === 0) return
                    if (item.slug === 'automation-workflows' && flag_automation === 0) return
                    if (item.slug === 'confidence_score' && (flag_confidence_score === 0 || this.mixinGetPreference('organization.confidence_score') !== 'Yes')) return
                    if (item.slug === 'poc_confidence_score' && (flag_confidence_score === 0 || this.mixinGetPreference('organization.confidence_score') !== 'Yes')) return
                    return item
                })
                return _.groupBy(filteredPermissions, field)
            }
        },
        local_get_current_permission(list, minValue, field) {
            const item = list.find(item => item && item.value === minValue)
            if (item) return item[field]
        },
        local_filter_keyvalue(data, criteria) {
            return _.filter(data, criteria)[0]
        },
        ...mapActions('Role', {
            role_show: 'show',
            role_store: 'store',
            role_update: 'update',
            role_select: 'select',
            role_destroy: 'destroy',
            role_reorder: 'reorder',
            role_permission_update: 'permission_update',
            role_permission_destroy: 'permission_destroy',
            role_clear: 'clear',
            role_clear_item: 'clear_item',
        }),
       ...mapActions('Scope', {
            scope_index: 'index',
            scope_update: 'update',
        }),
       ...mapActions('User', {
            user_me: 'me',
        }),
       ...mapActions('Auth', {
            auth_user_permission_index: 'userPermissions',
        }),
        ...mapActions('Preference', {
            preference_index: 'index',
        })
    },
}
</script>

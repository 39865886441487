<template>
    <div>
        <template v-if="localCheckValidateMultipleField > 1">
            <a-menu center open-on-hover offset-y transition="slide-y-transition" content-class="u-shadow-md u-border u-rounded-corners" max-width="584" min-width="584">
                <template v-slot:activator="{ on }">
                    <span v-on="on" class="md-caption red--text text--darken-2 mt-2 font-weight-medium u-underline">
                        <a-icon size="16" color="red darken-2">dangerous</a-icon>
                        {{ localCheckValidateMultipleField  }} Validations failed
                    </span>
                </template>
                <a-list class="u-list-condensed u-overflow-y pa-0">
                    <div class="pa-3">
                       <div v-for="(workflow_group, workflow_index) in localValidationWorkflowGroupList" :key="workflow_index+'_logs'+identifier" >
                            <div :class="[workflow_index !== 0 ? 'mt-4': '']">
                                <p :title="workflow_group.workflow" class="mb-0 font-weight-medium indigo--text text--darken-1" style="font-size: 14px;">
                                    <a-icon color="indigo darken-1" class="u-icon-nudge mr-1" size="20">device_hub</a-icon>
                                    {{ workflow_group.workflow | truncateText(70) }}
                                </p>
                            </div>
                            <div v-for="(validation) in workflow_group.workflow_validations" :key="validation.id">
                                <p class="md-caption grey--text text--darken-2 ma-0 mt-3 font-weight-medium" style="font-size: 14px;">
                                    <a-icon size="16" color="red darken-2">dangerous</a-icon>
                                    {{ validation.message }}
                                </p>
                            </div>
                       </div>
                    </div>
                </a-list>
            </a-menu>
        </template>
        <template v-else>
            <span class="md-caption red--text text--darken-2 mt-2 font-weight-medium" v-if="localGetValidateResultError">
                <a-icon size="16" color="red darken-2">dangerous</a-icon>
                {{ localGetValidateResultError }}
            </span>
        </template>
    </div>
</template>

<script>
import mixinAutomationHook from '@/mixins/mixin-automation-hook'

export default {
    mixins: [
        mixinAutomationHook,
    ],
    props: {
        identifier: {
            type: String,
            default: null
        }
    },

    data () {
        return {
            localValidationGroupData: [],
        }
    },

    computed: {
        localGetValidateResultError () {
            if (_.size(this.automation_workflow_hook_response.current_logs)) {
                const logItem = this.automation_workflow_hook_response.current_logs.find(item => item.field_id === this.identifier && item.is_valid === false)
                return logItem?.message || null
            }
            return null;
        },

        localCheckValidateMultipleField () {
            if (_.size(this.automation_workflow_hook_response.current_logs)) {
                const logList = this.automation_workflow_hook_response.current_logs.filter(item => item.field_id === this.identifier && item.is_valid === false)
                return _.size(logList)
            }
            return 0;
        },

        localValidationWorkflowGroupList () {
            if (!_.get(this.automation_workflow_hook_response, 'current_logs')) return this.localValidationGroupData

            const logList = this.automation_workflow_hook_response.current_logs.filter(item => item.field_id === this.identifier && item.is_valid === false)

            this.localValidationGroupData = logList.reduce((prev, current) => {
                const isAvail = prev.find(item => item.workflow_id === current.workflow_id)
                if (isAvail) {
                    current['workflow_id'] = current.workflow_id
                    current['workflow'] = current.workflow
                    isAvail.workflow_validations.push(_.cloneDeep(current))
                } else {
                    current['workflow_id'] = current.workflow_id
                    current['workflow'] = current.workflow
                    prev.push({
                        workflow_id: current.workflow_id,
                        workflow: current.workflow,
                        workflow_validations: [_.cloneDeep(current)]
                    })
                }
                return prev
            }, [])
            return this.localValidationGroupData
        },
    },
}
</script>
<template>
    <v-card-actions v-bind="$attrs" v-on="$listeners" class="c-card-actions">
        <slot></slot>
    </v-card-actions>
</template>

<script>
import { VCardActions } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VCardActions }
}
</script>

<template>
    <div class="u-wfull">
        <a-sheet key="progress-bar" :height="height" :class="{ 'u-rounded-corners-full': rounded }" class="u-flex align-center u-wfull grey lighten-2">
            <template v-if="dataProgress.total === 0 && (dataProgress.sum && dataProgress.est)">
                <a-sheet class="green lighten-2 u-hfull" :width="dataProgress.sum" style="border-radius: 8px 0px 0px 8px;"></a-sheet>
                <a-sheet class="orange lighten-2 u-hfull" :width="dataProgress.est" style="border-radius: 0px 8px 8px 0px; "></a-sheet>
            </template>
            <a-sheet v-else class="green lighten-2 u-hfull" :width="dataProgress.total" style="border-radius: 8px 0px 0px 8px;"></a-sheet>
        </a-sheet>
        <a-card-actions key="actions" class="d-block pa-6 pb-2 pt-3">
            <div class="u-flex align-center justify-space-between mt-1">
                <div class="md-body-2 font-weight-medium text-center grey--text text--darken-2">
                    <template v-if="!allowEstEdit">
                        <div>{{ estText ? estText : '0m' }}</div>
                        <div class="mr-1 text-uppercase font-weight-medium md-caption u-flex align-center justify-center" style="color: #9a9a9a; letter-spacing: 0.8px">Estimated</div>
                    </template>
                    <template v-else>
                        <v-slide-y-transition leave-absolute hide-on-leave>
                            <div v-if="!showEstimateEditForm" :class="{'u-cursor-pointer': canUpdate}" @click="canUpdate ? methodShowEstEdit() : {}">
                                <div>{{ estText ? estText : '0m' }}</div>
                                <div class="mr-1 text-uppercase font-weight-medium md-caption u-flex align-center justify-center" style="color: #9a9a9a; letter-spacing: 0.8px">
                                    <span>Estimated</span>
                                    <a-icon size="12" class="ml-1" color="grey darken-1" v-if="canUpdate">edit</a-icon>
                                </div>
                            </div>
                            <a-form v-else class="u-relative" @submit.prevent="methodUpdateEst">
                                <a-sheet width="220" height="24" class="u-flex align-center">
                                    <input type="text" v-model="localEstText" ref="refEstEdit" style="outline: none" class="u-rounded-corners u-wfull u-border pa-1 px-2 mr-2">
                                    <a-icon size="18" color="green darken-1" v-ripple @click="methodUpdateEst" class="d-inline-block u-cursor-pointer u-rounded-corners pa-1 mr-1">check</a-icon>
                                    <a-icon size="18" color="grey darken-1" v-ripple @click="methodCloseEstEdit" class="d-inline-block u-cursor-pointer u-rounded-corners pa-1">clear</a-icon>
                                </a-sheet>
                                <span class="md-caption mt-2 red--text text--darken-2 d-block text-left" v-if="validationError">
                                    {{ validationError }} <span class="grey--text text--darken-2">(Ex: 4w 5d 6h 20m)</span>
                                </span>
                            </a-form>
                        </v-slide-y-transition>
                    </template>
                </div>
                <div class="md-body-2 font-weight-medium text-center grey--text text--darken-2">
                    <div>{{ methodGetTime('logged') ? methodGetTime('logged') : '0m' }}</div>
                    <div class="mr-1 text-uppercase font-weight-medium md-caption" style="color: #9a9a9a; letter-spacing: 0.8px">{{ methodGetTime('logged') ? 'Logged' : 'No Time Logged'}}</div>
                </div>
                <div class="md-body-2 font-weight-medium text-center grey--text text--darken-2">
                    <template v-if="overOriginal">
                        <div>{{ overOriginal }}</div>
                        <div class="mr-1 text-uppercase font-weight-medium md-caption" style="color: #9a9a9a; letter-spacing: 0.8px">Over Original</div>
                    </template>
                    <template v-else>
                        <div>{{ methodRemainingTime() ? methodRemainingTime() : '0m' }}</div>
                        <div class="mr-1 text-uppercase font-weight-medium md-caption" style="color: #9a9a9a; letter-spacing: 0.8px">Remaining</div>
                    </template>
                </div>
            </div>
        </a-card-actions>
    </div>
</template>

<script>
import { calculateByMins, calculateByText } from '@/helpers/helper-time-tracker.js'

export default {
    props: {
        limit: {
            type: Number,
            required: true
        },
        rounded: {
            type: Boolean,
            default: false
        },
        height: {
            type: String | Number,
            default: 2
        },
        item: {
            type: Object,
        },
        sumMins: {
            type: String | Number,
            required: true
        },
        estMins: {
            type: String | Number,
            required: true
        },
        estText: {
            type: String,
        },
        allowEstEdit: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean,
        },
        // canUpdateProgress: {
        //     type: Boolean | String,
        //     default: null
        // },
    },

    mounted () {
        this.showEstimateEditForm = false
    },

    data () {
        return {
            showEstimateEditForm: false,
            overOriginal: null,
            validationError: null
        }
    },

    watch: {
        showEstimateEditForm (val) {
            if (val) setTimeout(() => this.$refs.refEstEdit.focus(), 100)
        }
    },

    computed: {
        dataProgress () {
            const sum = parseInt(this.sumMins ?? 0)
            const est = parseInt(this.estMins ?? 0)
            const sumAndEst = sum + est
            if (est && (sum > est)) {
                const { durationText } = calculateByMins((sum - est))
                const getSum = (sum / sumAndEst) * this.limit
                const getEst = ((sum - est) / sumAndEst) * this.limit
                this.overOriginal = durationText
                return { total: 0, sum: (getSum * 100), est: (getEst * 100) }
            } else if (est === 0 && sum === 0) {
                this.methodClearOverOriginal()
                return { total: 0, sum: 0, est: 0 }
            } else if (sum && est === 0) {
                this.methodClearOverOriginal()
                return { total: 100 + '%', sum: 0, est: 0 }
            } else {
                this.methodClearOverOriginal()
                const percent = (sum / est) * this.limit
                return { total: percent, sum: (sum / this.limit), est: (est / this.limit) }
            }
        },
    },

    methods: {
        methodUpdateEst () {
            const { error, durationMins, durationText } = calculateByText(this.localEstText)

            if (error) return this.validationError = error
            // if (error && durationText && durationMins >= 0) {
            //     return this.validationError = error
            // }

            this.validationError = null
            this.item.estimated_duration_minutes = durationMins === 0 ? null : durationMins
            this.item.estimated_duration_text = durationText
            this.$emit('est-update', this.item)
            this.showEstimateEditForm = false
        },

        methodRemainingTime () {
            if (this.estMins) {
                const differenceBtw = parseInt((this.estMins ?? 0) - (this.sumMins ?? 0))
                const { durationText } = calculateByMins(differenceBtw)
                return durationText
            }
        },

        methodGetTime (type) {
            if (type === 'logged') {
                const { durationText } = calculateByMins(parseInt(this.sumMins))
                return durationText
            }
        },

        methodClearOverOriginal () {
            if (this.overOriginal) this.overOriginal = null
        },

        methodShowEstEdit () {
            this.localEstText = this.estText
            this.showEstimateEditForm = true
        },

        methodCloseEstEdit () {
            this.validationError = null
            this.localEstText = null
            this.showEstimateEditForm = false
        }
    }
}
</script>

<style scoped>
.c-table {
    display: table;
    border-collapse: collapse;
    width: 100%;
}
</style>

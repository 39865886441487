<template>
    <div>
        <g-settings-breadcrumb previousRoute="tickets" class="mt-5"></g-settings-breadcrumb>
        <template v-if="pageLoading">
            <STicketViewLoader/>
        </template>
        <!-- Ticket Detail -->
        <a-container v-else container--fluid grid-list-xl pb-12 pt-6 px-0 class="u-relative">
            <a-layout
                @dragenter="$can('tickets.update') && !localAttachmentsUpdating ? localHandleDragEnter() : ''"
                @drop="localHandleDrop"
                class="u-relative"
                align-center
            >
                <SDragOverlay
                    v-if="filesDraggedOver"
                    pageType="global"
                    @leave="localHandleLeave()"
                    @enter="hoveredOnInfo=false"
                    @enterInfo="hoveredOnInfo=true"
                >
                </SDragOverlay>
                <a-flex xs12>
                    <a-sheet class="transparent">
                        <a-container container--fluid grid-list-xl pb-12 px-0>
                            <a-layout v-if="pageLoading">
                                <a-flex xs12>
                                    <a-responsive class="my-4">
                                        <h3 class="text-h5 font-weight-medium text-center grey--text text--darken-1 mb-1 text-capitalize">Loading...</h3>
                                    </a-responsive>
                                </a-flex>
                            </a-layout>
                            <a-layout v-if="!pageLoading">
                                <a-flex xs9 xl10>
                                    <div class="u-flex align-center u-rounded-corners u-border">
                                        <a-text-field
                                            v-model="local_ticket_item.title"
                                            ref="ref_ticket_title"
                                            class="text-h5 font-weight-medium grey--text text--darken-3 u-wfull"
                                            solo flat hide-details
                                            @input="localTicketValidationCheck()"
                                            v-if="localCheckTicketPermission"
                                        ></a-text-field>
                                        <a-sheet v-else class="text-h5 font-weight-medium grey--text py-2 px-3 u-rounded-corners text--darken-3 u-wfull">{{ local_ticket_item.title }}</a-sheet>
                                    </div>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.title">
                                         <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ ticket_response.server.errors.title[0] }}
                                    </span>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="localGetTicketErrorMessage('title')">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ localGetTicketErrorMessage('title') }}
                                    </span>
                                    <div class="mt-4">
                                        <a-card flat class="pa-0 u-border u-rounded-corners">
                                            <div class="u-flex align-center px-6 py-3">
                                                <div class="u-flex align-center">
                                                    <h3 class="c-letter-space text-uppercase md-caption grey--text text--darken-1 font-weight-medium">Description</h3>
                                                </div>
                                                <a-spacer></a-spacer>
                                                <div class="u-flex align-center" v-if="localCheckTicketPermission">
                                                    <p class="md-caption grey--text mb-0 d-flex align-center">
                                                        <em class="mr-2">Select text for formating options</em>
                                                        <a-icon size="14" color="grey" class="ml-1">format_bold</a-icon>
                                                        <a-icon size="14" color="grey" class="ml-1">format_italic</a-icon>
                                                    </p>
                                                </div>
                                            </div>
                                            <a-divider class="u-divider"></a-divider>
                                            <div class="px-6 pt-3 pb-1">
                                                <p class="md-body-2 grey--text text--darken-1" v-if="localCheckTicketPermission && (!local_ticket_item.description || local_ticket_item.description && local_ticket_item.description.replace(/<[^>]*>?/gm, '') === null || local_ticket_item.description && local_ticket_item.description.replace(/<[^>]*>?/gm, '') == '')" @click="localFocusTicketEditor">Click to edit the description</p>
                                                <editor-content v-else class="c-editor md-body-2 u-hfull" :editor="editor_ticket_description"></editor-content>
                                                <editor-menu-bubble v-if="localCheckTicketPermission" :editor="editor_ticket_description" :keep-in-bounds="false" v-slot="{ commands, isActive, menu }">
                                                    <div
                                                        class="menububble"
                                                        :class="{ 'is-active': menu.isActive }"
                                                        :style="`left: ${menu.left - 16}px; bottom: ${menu.bottom + 8}px;`"
                                                    >
                                                        <a-btn icon text small class="mx-0" :color="(isActive.bold()) ? 'blue' : 'grey'" @click="commands.bold">
                                                            <a-icon size="16">format_bold</a-icon>
                                                        </a-btn>
                                                        <a-btn icon text small class="mx-0" :color="(isActive.italic()) ? 'blue' : 'grey'" @click="commands.italic">
                                                            <a-icon size="16">format_italic</a-icon>
                                                        </a-btn>
                                                        <a-divider vertical dark></a-divider>
                                                        <a-btn icon text small class="mx-0" :color="(isActive.bullet_list()) ? 'blue' : 'grey'" @click="commands.bullet_list">
                                                            <a-icon size="16">format_list_bulleted</a-icon>
                                                        </a-btn>
                                                        <a-btn icon text small class="mx-0" :color="(isActive.ordered_list()) ? 'blue' : 'grey'" @click="commands.ordered_list">
                                                            <a-icon size="16">format_list_numbered</a-icon>
                                                        </a-btn>
                                                    </div>
                                                </editor-menu-bubble>
                                            </div>
                                        </a-card>
                                    </div>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.description">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ ticket_response.server.errors.description[0] }}
                                    </span>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="localGetTicketErrorMessage('description')">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ localGetTicketErrorMessage('description') }}
                                    </span>
                                    <div class="mt-6">
                                        <span class="c-letter-space text-uppercase md-caption d-block c-meta-title grey--text text--darken-2 font-weight-medium mb-2">Tags</span>
                                        <div class="d-inline-flex align-center">
                                            <div class="pa-0 d-inline-flex flex-wrap">
                                                <template v-if="local_ticket_item.tags && local_ticket_item.tags.length">
                                                    <template v-for="tag in local_ticket_item.tags">
                                                        <g-tags :can-update="$can('tags.store')" :hide-clear-icon="!$can('tickets.update') || isTicketUpdateLoading" :tag="tag" type="Ticket" @destroy="localticketAssociateTagDestroy(tag, local_ticket_item)" :key="tag.id"></g-tags>
                                                    </template>
                                                </template>
                                                <a-menu bottom offset-y v-model="localShowTicketTagAddInput" v-if="$can('tickets.update') && !isTicketUpdateLoading" :close-on-content-click="false" ref="refAddTagPopup" transition="slide-y-transition" nudge-bottom="8">
                                                    <template v-slot:activator="{ on }">
                                                        <g-add-btn v-on="on" iconSize="14" dark></g-add-btn>
                                                    </template>
                                                    <a-sheet class="pb-1" max-width="240">
                                                        <s-tags-inline-form
                                                            :item="local_ticket_item"
                                                            :can-update="$can('tags.store')"
                                                            :list="localFilteredTags()"
                                                            :is-menu-open="localShowTicketTagAddInput"
                                                            source="Ticket"
                                                            type="ticket_tag"
                                                            update-type="manual_save"
                                                            @get-tag-update="localGetFieldUpdate"
                                                            @after-store="() => localShowTicketTagAddInput = false"
                                                            @get-selected-tag="localGetSelectedTag"
                                                        ></s-tags-inline-form>
                                                    </a-sheet>
                                                </a-menu>
                                            </div>
                                        </div>
                                    </div>
                                    <a-divider class="grey lighten-2 mt-6"></a-divider>

                                    <!-- Attachment section -->
                                    <div class="mt-8">
                                        <SAttachmentSection
                                            :list="attachment_local_list"
                                            :dropTransferList="localDroppedFilesList"
                                            :sourceId="local_ticket_item.id"
                                            :updateLoading="localAttachmentsUpdating"
                                            :loading="mixinIsLoading('attachment')"
                                            :canUpdate="$can('tickets.update')"
                                            sourceType="Ticket"
                                            @dropTransfer="localUpdateAttachmentTransfer"
                                            @loadMore="localLoadMoreAttachments()"
                                            @remove="localRemoveAttachmentItem"
                                            @delete="localDeleteAttachmentItem"
                                        >
                                        </SAttachmentSection>
                                    </div>
                                    <a-divider v-if="attachment_meta && (attachment_meta.total == attachment_list.length)" class="grey lighten-2 mt-12"></a-divider>
                                    <template>
                                        <div class="mt-8">
                                            <div class="u-flex align-center">
                                                <div class="u-flex align-center">
                                                    <h3 class="c-letter-space text-uppercase md-caption font-weight-medium grey--text text--darken-2">
                                                        Linked Projects
                                                        <span class="ml-1 primary lighten-2 u-rounded-corners-full white--text pa-1 px-2">{{ local_ticket_item.project_ticket_linked_count || 0}}</span>
                                                        <a-tooltip right v-if="localHasHiddenProjects()" content-class="c-tooltip-pointer c-tooltip-pointer--left">
                                                            <template v-slot:activator="{ on }">
                                                                <span v-on="on" class="md-caption grey--text text--darken-2 ml-4 font-weight-medium d-inline-flex align-center" style="letter-spacing: 0.2px">
                                                                    <span class="mr-1">{{ localHasHiddenProjects() }} Hidden Project{{ localHasHiddenProjects() > 1 ? 's' : '' }}</span>
                                                                    <a-icon size="16" class="u-icon-nudge" style="top: -1px;" color="grey darken-1">help</a-icon>
                                                                </span>
                                                            </template>
                                                            <span class="md-body-2">You may not have proper permission or the project(s) belongs to different workspace.</span>
                                                        </a-tooltip>
                                                    </h3>
                                                </div>
                                                <a-spacer></a-spacer>
                                                <h3 class="md-body-2 grey--text text--darken-2">
                                                    <span class="c-letter-space text-uppercase md-caption font-weight-medium">Total Deal Value: </span>
                                                    <g-currency tag="span" class="md-subtitle-1 green--text text--darken-1 mr-2" :value="local_ticket_item.deal_value"></g-currency>
                                                </h3>
                                            </div>
                                            <p-linked-ticket-card
                                                v-if="local_ticket_item && local_ticket_item.projects && !local_loading"
                                                :ticket-list="local_ticket_item"
                                                :status-list="statusList"
                                                :priority-list="priorityList"
                                                :current-edit-id="currentEditId"
                                                :loading-meta-item="loadingLinkedMeta"
                                                :backup-edit-item="linkedTicketEditItem"
                                                :can-update="localCheckProjectTicketPermission()"
                                                @inlineUpdate="localTicketInlineUpdate"
                                                @updateMeta="localTicketMetaUpdate"
                                                @inputFocus="localTitleEditFocus"
                                                @unlink-project="localProjectTicketUnlink"
                                            >
                                            </p-linked-ticket-card>
                                        </div>
                                    </template>
                                    <div :class="[ ticket_item.links_workspace && ticket_item.links_workspace.length ? 'mt-12' : 'mt-8']">
                                        <!-- Design page -->
                                        <div class="mt-4">
                                            <a-layout align-center class="mt-4 mb-2">
                                                <a-flex class="u-flex align-center">
                                                    <div class="c-letter-space text-uppercase md-caption font-weight-medium grey--text text--darken-2">Fields</div>
                                                </a-flex>
                                            </a-layout>
                                            <template v-if="localLayoutTicketDesignList && !localLayoutTicketDesignList.length">
                                                <a-sheet class="u-shadow">
                                                    <div class="py-8 px-6 text-center u-flex flex-column align-center">
                                                        <div class="rounded-circle grey lighten-3 u-flex justify-center mb-4" style="width: 64px; height: 64px">
                                                            <a-icon size="40" color="grey lighten-1">sell</a-icon>
                                                        </div>
                                                        <div class="mt-4 text-center">
                                                            <div class="grey--text text--darken-3 text-uppercase" style="font-weight: 600">No fields are added!</div>
                                                            <p class="grey--text text--darken-1 mb-0 mt-3 font-weight-medium mx-auto" style="max-width: 600px">
                                                                <template v-if="$can('layout_designer.index')">
                                                                    You can configure existing fields using Layout Designer.
                                                                </template>
                                                                <template v-else>
                                                                    There are no custom fields attached to this ticket.
                                                                </template>
                                                            </p>
                                                            <div class="u-flex justify-center font-weight-medium mt-4">
                                                                <a-btn v-if="$can('layout_designer.index')" depressed color="grey darken-4" :to="{ name: 'layout-designer'}">
                                                                    <a-icon size="18" color="white" class="pr-1">login</a-icon>
                                                                    <span class="white--text">Go to Layout Designer</span>
                                                                </a-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a-sheet>
                                            </template>
                                            <template v-else>
                                                <div v-for="(section, index) in localClonelayoutDesignList" :key="section.id">
                                                    <a-sheet
                                                        class="mb-8 u-border u-wfull u-rounded-corners"
                                                        v-if="section.is_active"
                                                    >
                                                        <div>
                                                            <div class="pa-5">
                                                                <a-layout align-center>
                                                                    <a-flex class="u-flex align-center">
                                                                        <div class="u-wfull">
                                                                            <h2 class="md-subtitle-1 indigo--text text--darken-1 font-weight-medium" :title="section.name">
                                                                                {{ section.name | truncateText(local_screen_lg ? 40 : 120) }}
                                                                            </h2>
                                                                        </div>
                                                                    </a-flex>
                                                                </a-layout>
                                                            </div>
                                                            <a-divider v-if="local_show_divider(section)"></a-divider>
                                                            <div v-if="section.fields && !section.fields.length" class="text-center">
                                                                <div class="py-8 px-6 text-center">
                                                                    <div class="grey--text text--darken-3" style="font-weight: 600">No fields are added to this section!</div>
                                                                    <p v-if="$can('layout_designer.index')" class="grey--text text--darken-1 mb-0 mt-3 font-weight-medium mx-auto" style="max-width: 600px">You can configure existing fields using Layout Designer.</p>
                                                                    <div class="u-flex justify-center font-weight-medium mt-4">
                                                                        <router-link v-if="$can('layout_designer.index')" class="mx-4 indigo--text text--darken-1" :to="{ name: 'layout-designer'}">
                                                                            <span>Go to Layout Designer</span>
                                                                            <a-icon size="18" color="indigo darken-1" class="pl-1">arrow_forward</a-icon>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <a-sheet v-else class="u-wfull c-section-container">
                                                                <div v-for="(field, fieldIndex) in section.fields" :key="field.id" :class="[field.width === '50%' ? 'c-field-card__half--preview' : 'c-field-card__full']">
                                                                    <a-sheet
                                                                        flat
                                                                        :class="['u-wfull mb-5']"
                                                                    >
                                                                        <a-divider v-if="local_show_item_divider(section, field, fieldIndex)"></a-divider>
                                                                        <div class="px-5 pb-2 pt-5 u-wfull">
                                                                            <div v-if="field && field.parent_type === 'CustomField'" class="u-flex align-center px-0 mb-3">
                                                                                <a-icon
                                                                                    size="20"
                                                                                    color="grey lighten-1"
                                                                                    class="mr-2"
                                                                                >
                                                                                    {{ localGetCustomFieldTypeItem(field) }}
                                                                                </a-icon>
                                                                                <h3 class="md-subtitle-1 grey--text text--darken-3 font-weight-medium" >{{ field.parent.label }}</h3>
                                                                                <a-tooltip right v-if="field.parent.description">
                                                                                    <template v-slot:activator="{ on }">
                                                                                        <a-icon v-on="on" size="16" color="grey darken-1" class="u-cursor-pointer ml-2 u-flex">info</a-icon>
                                                                                    </template>
                                                                                    <span class="py-3">{{ field.parent.description }}</span>
                                                                                </a-tooltip>
                                                                            </div>
                                                                            <div class="u-wfull">
                                                                                <template v-if="field && field.parent.type === 'url'">
                                                                                    <template v-if="field.parent.custom_field_value">
                                                                                        <a-tooltip bottom offset-y :disabled="!localGetTicketErrorMessage(field.parent.name, 'customField') && field.parent.user_writable === 1">
                                                                                            <template v-slot:activator="{ on }">
                                                                                                <div v-on="on">
                                                                                                    <a-text-field
                                                                                                        v-model="field.parent.custom_field_value.value"
                                                                                                        :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                                        color="grey darken-1"
                                                                                                        background-color="grey lighten-4"
                                                                                                        class="u-rounded-corners"
                                                                                                        :class="[localGetTicketErrorMessage(field.parent.name, 'customField') ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                                        :disabled="!(localCheckTicketPermission) || field.parent.user_writable === 0"
                                                                                                        @input="localTicketCustomFieldUpdate(field.parent, field.parent.custom_field_value && field.parent.custom_field_value.value ? field.parent.custom_field_value.value : null)"
                                                                                                        :append-icon="!localCheckUrlIcon(field) ? 'open_in_new' : ''"
                                                                                                        @click:append="field.parent.custom_field_value.value ? localLinkOpenNewTab(field.parent.custom_field_value.value) : {}"
                                                                                                        solo flat hide-details
                                                                                                    ></a-text-field>
                                                                                                </div>
                                                                                            </template>
                                                                                            <span class="py-3">
                                                                                                {{
                                                                                                    localGetTicketErrorMessage(field.parent.name, 'customField') ?
                                                                                                        localGetTicketErrorMessage(field.parent.name, 'customField') :
                                                                                                        'This is a read-only field.'
                                                                                                }}
                                                                                            </span>
                                                                                        </a-tooltip>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-if="field && ['text','number'].includes(field.parent.type)">
                                                                                    <template v-if="field.parent.custom_field_value">
                                                                                        <a-tooltip bottom offset-y :disabled="!localGetTicketErrorMessage(field.parent.name, 'customField') && field.parent.user_writable === 1">
                                                                                            <template v-slot:activator="{ on }">
                                                                                                <div v-on="on">
                                                                                                    <a-text-field
                                                                                                        v-model="field.parent.custom_field_value.value"
                                                                                                        :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                                        color="grey darken-1"
                                                                                                        background-color="grey lighten-4"
                                                                                                        class="u-rounded-corners"
                                                                                                        :class="[localGetTicketErrorMessage(field.parent.name, 'customField') ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                                        :disabled="!(localCheckTicketPermission) || field.parent.user_writable === 0"
                                                                                                        @input="localTicketCustomFieldUpdate(field.parent, field.parent.custom_field_value ? field.parent.custom_field_value.value : '')"
                                                                                                        @keypress="localIsNumber($event, field.parent.type)"
                                                                                                        solo flat hide-details
                                                                                                    ></a-text-field>
                                                                                                </div>
                                                                                            </template>
                                                                                            <span class="py-3">
                                                                                                {{
                                                                                                    localGetTicketErrorMessage(field.parent.name, 'customField') ?
                                                                                                        localGetTicketErrorMessage(field.parent.name, 'customField') :
                                                                                                        'This is a read-only field.'
                                                                                                }}
                                                                                            </span>
                                                                                        </a-tooltip>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-if="field && field.parent.type === 'multiline'">
                                                                                    <template v-if="field.parent.custom_field_value">
                                                                                    <a-tooltip bottom offset-y :disabled="!localGetTicketErrorMessage(field.parent.name, 'customField') && field.parent.user_writable === 1">
                                                                                        <template v-slot:activator="{ on }">
                                                                                            <div style="overflow-y: auto; max-height: 15rem" class="grey lighten-4 rounded" v-on="on">
                                                                                                <a-textarea
                                                                                                    v-model="field.parent.custom_field_value.value"
                                                                                                    :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                                    color="grey darken-1"
                                                                                                    background-color="grey lighten-4"
                                                                                                    class="u-rounded-corners"
                                                                                                    :class="[localGetTicketErrorMessage(field.parent.name, 'customField') ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                                    :disabled="!(localCheckTicketPermission) || field.parent.user_writable === 0"
                                                                                                    @input="localTicketCustomFieldUpdate(field.parent, field.parent.custom_field_value ? field.parent.custom_field_value.value : '')"
                                                                                                    solo flat hide-details no-resize auto-grow
                                                                                                    rows="3"
                                                                                                >
                                                                                                </a-textarea>
                                                                                            </div>
                                                                                        </template>
                                                                                        <span class="py-3">
                                                                                            {{
                                                                                                localGetTicketErrorMessage(field.parent.name, 'customField') ?
                                                                                                    localGetTicketErrorMessage(field.parent.name, 'customField') :
                                                                                                    'This is a read-only field.'
                                                                                            }}
                                                                                        </span>
                                                                                    </a-tooltip>
                                                                                </template>
                                                                                </template>
                                                                                <template v-if="field && field.parent.type === 'mask'">
                                                                                    <template v-if="field.parent.custom_field_value">
                                                                                        <a-tooltip bottom offset-y :disabled="!localGetTicketErrorMessage(field.parent.name, 'customField') && field.parent.user_writable === 1">
                                                                                            <template v-slot:activator="{ on }">
                                                                                                <div v-on="on">
                                                                                                    <a-text-field
                                                                                                        v-model="field.parent.custom_field_value.mask_value"
                                                                                                        :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                                        color="grey darken-1"
                                                                                                        background-color="grey lighten-4"
                                                                                                        class="u-rounded-corners"
                                                                                                        :class="[localGetTicketErrorMessage(field.parent.name, 'customField') ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                                        :disabled="!(localCheckTicketPermission) || field.parent.user_writable === 0"
                                                                                                        solo flat hide-details
                                                                                                        :append-icon="localCheckTicketPermission && field.parent.user_writable === 1 ? 'drive_file_rename_outline' : ''"
                                                                                                        readonly
                                                                                                        @click:append="localSelectCustomFieldItem(section.id, field.id, field.parent)"
                                                                                                    ></a-text-field>
                                                                                                </div>
                                                                                            </template>
                                                                                            <span class="py-3">
                                                                                                {{
                                                                                                    localGetTicketErrorMessage(field.parent.name, 'customField') ?
                                                                                                        localGetTicketErrorMessage(field.parent.name, 'customField') :
                                                                                                        'This is a read-only field.'
                                                                                                }}
                                                                                            </span>
                                                                                        </a-tooltip>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-if="field && field.parent.type === 'dropdown'">
                                                                                    <template v-if="field.parent.custom_field_value">
                                                                                        <a-tooltip bottom offset-y :disabled="field.parent.user_writable === 1">
                                                                                            <template v-slot:activator="{ on }">
                                                                                                <div v-on="on">
                                                                                                    <a-autocomplete
                                                                                                            v-model="field.parent.custom_field_value.value"
                                                                                                            :items="field.parent.options"
                                                                                                            item-text="label"
                                                                                                            item-value="value"
                                                                                                            :placeholder="localCheckTicketPermission ? (field.parent.placeholder ? field.parent.placeholder : 'Select a value') : ''"
                                                                                                            class="u-rounded-corners"
                                                                                                            :class="[localGetTicketErrorMessage(field.parent.name, 'customField')  ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                                            color="grey darken-1"
                                                                                                            background-color="grey lighten-4"
                                                                                                            :key="field.parent.id"
                                                                                                            :append-icon="localCheckTicketPermission ? 'arrow_drop_down' : null"
                                                                                                            :disabled="!(localCheckTicketPermission) || field.parent.user_writable === 0"
                                                                                                            @input="localSelectTicketCustomField(field.parent, field.parent.custom_field_value ? field.parent.custom_field_value.value : null)"
                                                                                                            solo flat hide-details
                                                                                                        >
                                                                                                        <template v-slot:no-data>
                                                                                                            <div class="px-4 py-2">
                                                                                                                <span class="md-body-1 grey--text">No Data Found</span>
                                                                                                            </div>
                                                                                                        </template>
                                                                                                        <template v-slot:selection="{ item }">
                                                                                                            <span v-if="item === Object(item)" class="md-body-3 d-block py-1 u-rounded-corners text-truncate" style="max-width: 630px" :title="item.label">{{ item.label }} </span>
                                                                                                        </template>
                                                                                                        <template v-slot:item="{ item }">
                                                                                                            <a-layout align-center class="py-0">
                                                                                                                <a-flex style="max-width: 630px">
                                                                                                                    <h5 class="md-subtitle-1 text-truncate" :title="item.label">{{ item.label }}</h5>
                                                                                                                </a-flex>
                                                                                                            </a-layout>
                                                                                                        </template>
                                                                                                    </a-autocomplete>
                                                                                                </div>
                                                                                            </template>
                                                                                            <span class="py-3">This is a read-only field</span>
                                                                                        </a-tooltip>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-if="field && field.parent.type === 'date'">
                                                                                    <template v-if="field.parent.custom_field_value">
                                                                                        <a-tooltip bottom offset-y :disabled="!localGetTicketErrorMessage(field.parent.name, 'customField') && field.parent.user_writable === 1">
                                                                                            <template v-slot:activator="{ on }">
                                                                                                <div v-on="on">
                                                                                                    <!-- <a-menu
                                                                                                        v-model="datepickerMenu[field.parent.id]"
                                                                                                        :close-on-content-click="false"
                                                                                                        :nudge-right="60"
                                                                                                        transition="scale-transition"
                                                                                                        min-width="290px"
                                                                                                        offset-y
                                                                                                    >   <template v-slot:activator="{ on, attrs }">
                                                                                                            <a-text-field
                                                                                                                v-model="datepickerDateValue[field.parent.id]"
                                                                                                                :value="localFormatDate(field.parent.custom_field_value.value)"
                                                                                                                placeholder="Select Date"
                                                                                                                background-color="neutral"
                                                                                                                :class="[localGetTicketErrorMessage(field.parent.name, 'customField') ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                                                :disabled="!(localCheckTicketPermission) || field.parent.user_writable === 0"
                                                                                                                @click:clear="localTicketCustomFieldDateClear(section.id, field, field.parent.custom_field_value ? field.parent.custom_field_value.value : '')"
                                                                                                                solo flat hide-details readonly clearable
                                                                                                                v-bind="attrs"
                                                                                                                v-on="on"
                                                                                                            >
                                                                                                            </a-text-field>
                                                                                                        </template>
                                                                                                        <a-date-picker
                                                                                                            v-model="field.parent.custom_field_value.value"
                                                                                                            @input="localSelectTicketdatepicker(field.parent, index)"
                                                                                                            no-title scrollable
                                                                                                        >
                                                                                                        </a-date-picker>
                                                                                                    </a-menu> -->
                                                                                                    <vc-date-picker
                                                                                                        v-model="field.parent.custom_field_value.value"
                                                                                                        @input="localSelectTicketdatepicker(field.parent, index)"
                                                                                                        class="d-block u-wfull"
                                                                                                        :model-config="modelConfig"
                                                                                                    >
                                                                                                        <template v-slot="{ togglePopover, hidePopover }">
                                                                                                            <a-sheet width="100%" @click="local_can_update(field) ? '' : togglePopover({ placement: 'bottom-start' })">
                                                                                                                <a-text-field
                                                                                                                    v-model="datepickerDateValue[field.parent.id]"
                                                                                                                    :value="localFormatDate(field.parent.custom_field_value.value)"
                                                                                                                    placeholder="Select Date"
                                                                                                                    background-color="neutral"
                                                                                                                    :class="[localGetTicketErrorMessage(field.parent.name, 'customField') ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                                                    :disabled="!(localCheckTicketPermission) || field.parent.user_writable === 0"
                                                                                                                    @click:clear="localTicketCustomFieldDateClear(section.id, field, field.parent.custom_field_value ? field.parent.custom_field_value.value : '', hidePopover)"
                                                                                                                    solo flat hide-details readonly clearable
                                                                                                                    v-on="on"
                                                                                                                >
                                                                                                                </a-text-field>
                                                                                                            </a-sheet>
                                                                                                        </template>
                                                                                                    </vc-date-picker>
                                                                                                </div>
                                                                                            </template>
                                                                                            <span class="py-3">
                                                                                                {{
                                                                                                    localGetTicketErrorMessage(field.parent.name, 'customField') ?
                                                                                                        localGetTicketErrorMessage(field.parent.name, 'customField') :
                                                                                                        'This is a read-only field.'
                                                                                                }}
                                                                                            </span>
                                                                                        </a-tooltip>
                                                                                    </template>
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </a-sheet>
                                                                    <!-- <a-divider v-if="field.width === '100%'"></a-divider> -->
                                                                </div>
                                                            </a-sheet>
                                                        </div>
                                                    </a-sheet>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="c-update-container u-rounded-corners py-3 px-5 mt-6 white u-border" v-if="$can('tickets.update') || $can('tickets.destroy')">
                                        <div class="u-flex">
                                            <div class="u-flex" v-if="$can('tickets.update')">
                                                <a-btn
                                                    @click="localTicketUpdate()"
                                                    :loading="isTicketUpdateLoading || mixinIsLoading('attachment-update')"
                                                    :disabled="!localCheckTicketUpdate"
                                                    height="32"
                                                    color="#3949AB"
                                                    class="c-save-btn ma-0 mr-4 px-2 py-3 align-center white--text text--darken-2 u-rounded-corners"
                                                    small
                                                >
                                                    Save
                                                </a-btn>
                                                <a-btn
                                                    :disabled="!localCheckTicketUpdate || isTicketUpdateLoading || localAttachmentsUpdating"
                                                    @click="localTicketUpdateCancel()"
                                                    height="32"
                                                    color="#EEEEEE"
                                                    depressed tag="a"
                                                    class="ma-0 px-2 py-3 align-center text--darken-2 u-rounded-corners"
                                                    small
                                                >
                                                    <span style="color: #9E9E9E">Cancel</span>
                                                </a-btn>
                                                <div class="px-4 d-flex align-center ml-4" v-if="mixinIsLoading('attachment-update') || isTicketUpdateLoading">
                                                    <template v-if="mixinIsLoading('attachment-update') && (attachment_upload_list && attachment_upload_list.length)">
                                                        <span class="text-uppercase blue--text md-body-2">
                                                            Uploading Attachments
                                                        </span>
                                                    </template>
                                                    <template v-else-if="isTicketUpdateLoading && attachment_upload_list && !attachment_upload_list.length">
                                                        <span class="text-uppercase blue--text md-body-2">
                                                            Saving changes
                                                        </span>
                                                    </template>
                                                </div>
                                                <a-sheet v-if="localTicketUpdateErrors.length" class="px-4 u-rounded-corners d-flex align-center red lighten-5 ml-4">
                                                    <a-icon size="16px" color="red">info</a-icon>
                                                    <p class="ml-2 font-weight-medium mb-0 red--text text--darken-1">One or more fields are invalid</p>
                                                </a-sheet>
                                            </div>
                                            <a-spacer></a-spacer>
                                            <div v-if="$can('tickets.destroy')">
                                                <a-menu bottom left offset-y :close-on-content-click="false" max-width="250" :disabled="pageLoading || isTicketUpdateLoading">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <a-btn small text dark class="red lighten-5 u-rounded-corners elevation-0 ma-0 u-wfull red--text text--darken-1 u-font-weight-semibold" height="32">
                                                                Delete Ticket
                                                            </a-btn>
                                                        </div>
                                                    </template>
                                                    <a-card>
                                                        <a-card-text class="pa-4">
                                                            <h2 class="md-heading-6 mb-2">Are you sure to delete this ticket?</h2>
                                                            <p class="body-2 mb-4">This action will delete the ticket and cannot be undone.</p>
                                                            <a-btn class="ma-0 elevation-0" color="red" @click="localTicketDestroy(ticket_item)" :loading="loading" dark small>
                                                                <a-icon size="16" left class="mr-2">delete</a-icon> Delete
                                                            </a-btn>
                                                        </a-card-text>
                                                    </a-card>
                                                </a-menu>
                                            </div>
                                        </div>
                                    </div>
                                </a-flex>
                                <a-flex xs3 xl2 ml-8>
                                    <a-sheet class="u-rounded-corners transparent pa-5 pt-0 pb-4">
                                        <a-card class="c-dropdown mb-4 elevation-0 pa-0 u-rounded-corners" style="max-width: 200px;" target="_blank" :href="local_ticket_item.bridges[0].meta ? local_ticket_item.bridges[0].meta.link : '#/'" v-if="local_ticket_item.bridges && local_ticket_item.bridges.length && local_ticket_item.bridges[0]">
                                            <div class="u-flex align-center u-wfull py-2 px-3" style="border: 1px solid #e9e9e9">
                                                <a-avatar size="22">
                                                    <img src="../../assets/images/jira-logo.svg" contain alt="Synced with Jira">
                                                </a-avatar>
                                                <span class="md-body-2 mb-0 ml-2 font-weight-medium" style="color: #2b3549; letter-spacing: 0.2px">Synced with Jira</span>
                                                <a-spacer></a-spacer>
                                                <a-icon size="12" class="ml-3" color="grey lighten-1">launch</a-icon>
                                            </div>
                                        </a-card>
                                        <div class="c-dropdown mb-5">
                                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Ticket Id</span>
                                            <h3 class="md-body-2 font-weight-medium grey--text text--darken-2 grey lighten-2 d-inline-block u-rounded-corners px-1" style="font-family: monospace !important;">{{ local_ticket_item.code }}</h3>
                                        </div>
                                        <div class="c-dropdown mb-5">
                                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Due Date</span>
                                            <!-- <a-menu offset-y v-model="datepicker_due_date_edit" :disabled="!localCheckTicketPermission">
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on" class="md-body-2 u-cursor-pointer grey--text text--darken-3 font-weight-medium d-inline-flex align-center">
                                                        <a-icon size="16" color="grey darken-1" class="mr-1 u-icon-nudge" style="top: -1px;">date_range</a-icon>
                                                        <g-moment v-if="local_ticket_item.due_date" :value="local_ticket_item.due_date" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY" convert-local></g-moment>
                                                        <template v-else>No Due Date</template>
                                                        <template v-if="localCheckTicketPermission">
                                                            <a-icon size="12" color="grey darken-1" v-if="!local_ticket_item.due_date" class="ml-2">edit</a-icon>
                                                            <a-icon size="16" color="grey darken-1" v-if="local_ticket_item.due_date" @click="localClearTicketDate" class="ml-2 u-cursor-pointer u-rounded-corners-full">clear</a-icon>
                                                        </template>
                                                    </div>
                                                </template>
                                                <a-date-picker v-model="local_ticket_item.due_date" @input="datepicker_due_date_edit = false" no-title scrollable @change="localGetFieldUpdate()"></a-date-picker>
                                            </a-menu> -->
                                            <a-menu offset-y v-model="datepicker_due_date_edit" :disabled="!localCheckTicketPermission">
                                                <template v-slot:activator="{ on }">
                                                    <vc-date-picker
                                                        v-on="on"
                                                        v-model="local_ticket_item.due_date"
                                                        @input="localGetFieldUpdate()"
                                                        class="d-block u-wfull"
                                                        :model-config="modelConfig"
                                                    >
                                                        <template v-slot="{ togglePopover }">
                                                            <div
                                                                class="u-wfull u-flex align-stretch u-cursor-pointer u-rounded-corners-lg md-body-2 u-cursor-pointer grey--text text--darken-3 font-weight-medium mb-1"
                                                                @click="localCheckTicketPermission ? togglePopover({ placement: 'bottom-start' }) : ''"
                                                            >
                                                                <a-icon size="16" color="grey darken-1" class="mr-1 u-icon-nudge" style="top: -1px;">date_range</a-icon>
                                                                <g-moment v-if="local_ticket_item.due_date" :value="local_ticket_item.due_date" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY" convert-local></g-moment>
                                                                <template v-else>No Due Date</template>
                                                                <template v-if="localCheckTicketPermission">
                                                                    <a-icon size="12" color="grey darken-1" v-if="!local_ticket_item.due_date" class="ml-2">edit</a-icon>
                                                                    <a-icon size="16" color="grey darken-1" v-if="local_ticket_item.due_date" @click.stop="localClearTicketDate" class="ml-2 u-cursor-pointer u-rounded-corners-full">clear</a-icon>
                                                                </template>
                                                            </div>
                                                        </template>
                                                    </vc-date-picker>
                                                </template>
                                            </a-menu>
                                        </div>
                                        <div class="c-dropdown mb-6">
                                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Type</span>
                                            <s-meta-ticket-dropdown v-if="!local_loading" max-width="270" :can-update="localCheckTicketPermission" text-length="20" :meta-item="local_ticket_item.type" :meta-list="typeList" icon-size="24" inline @updateMeta="localUpdateTicketMeta(...arguments, 'type')">
                                                <template #icon>arrow_drop_down</template>
                                            </s-meta-ticket-dropdown>
                                        </div>
                                        <div class="c-dropdown mb-6">
                                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Priority</span>
                                            <s-meta-ticket-dropdown v-if="!local_loading" max-width="270" :can-update="localCheckTicketPermission" text-length="20" :meta-item="local_ticket_item.priority" :meta-list="priorityList" icon-size="24" inline @updateMeta="localUpdateTicketMeta(...arguments, 'priority')">
                                                <template #icon>arrow_drop_down</template>
                                            </s-meta-ticket-dropdown>
                                        </div>
                                        <div class="c-dropdown mb-6">
                                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Status</span>
                                            <s-meta-ticket-dropdown v-if="!local_loading" max-width="270" :can-update="localCheckTicketPermission" text-length="20" :meta-item="local_ticket_item.status" :meta-list="statusList" icon-size="24" inline @updateMeta="localUpdateTicketMeta(...arguments, 'status')">
                                                <template #icon>arrow_drop_down</template>
                                            </s-meta-ticket-dropdown>
                                        </div>
                                        <div class="c-dropdown mb-6" v-if="$can('projects.update-only') && localCheckTicketPermission && $can('poc_tickets.update')">
                                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Link Project</span>
                                            <a-menu v-model="searchProject" :close-on-content-click="project_search_mode" max-width="350">
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on" class="u-flex align-center u-cursor-pointer">
                                                        <span class="md-body-2 grey--text text--darken-1 font-weight-medium">Select project</span>
                                                        <a-icon size="24" color="grey darken-1" class="ml-2">arrow_drop_down</a-icon>
                                                    </div>
                                                </template>
                                                <a-list class="u-list-condensed py-0">
                                                    <a-list-item class="c-input-wrap px-2 py-2 white u-wfull">
                                                        <a-text-field
                                                            v-model="localSearchProject"
                                                            placeholder="Search Project"
                                                            background-color="neutral"
                                                            class="md-body-2 font-weight-normal"
                                                            solo flat hide-details autofocus
                                                            @click="project_search_mode = false"
                                                        ></a-text-field>
                                                    </a-list-item>
                                                </a-list>
                                                <a-list class="u-list-condensed pb-0 pt-0 mt-0" style="max-height: 300px; overflow-y: auto">
                                                    <template v-for="(project) in filteredProjects">
                                                        <a-divider :key="'divider-' + project.id"></a-divider>
                                                        <a-list-item v-bind:key="project.id" class="py-1" @click="localProjectTicketLink(project)">
                                                            <a-list-item-content>
                                                                <a-list-item-title class="md-subtitle-1 mb-1" :title="project.title && project.title.length > 36 ? project.title : ''">{{ project.title | truncateText(36) }}</a-list-item-title>
                                                                <a-list-item-subtitle>
                                                                    <div class="u-flex align-center">
                                                                        <span class="md-body-2 grey--text text--darken-1">{{ project.customer_name }}</span>
                                                                        <span class="mx-2 grey--text text--darken-1">•</span>
                                                                        <g-currency tag="span" class="md-body-2 font-weight-medium mr-2 green--text text--darken-1" :value="project.deal_amount"></g-currency>
                                                                    </div>
                                                                </a-list-item-subtitle>
                                                            </a-list-item-content>
                                                        </a-list-item>
                                                    </template>
                                                    <template v-if="filteredProjects && !filteredProjects.length">
                                                        <template v-if="loading || project_loading">
                                                            <div v-for="(i, index) in 6" :key="index">
                                                                <a-divider :key="'divider-' + index"></a-divider>
                                                                <a-list-item v-bind:key="index" class="py-1" disabled>
                                                                    <a-list-item-content>
                                                                        <a-list-item-title>
                                                                                <loader-template height="18" width="75%" class="u-rounded-corners-lg"></loader-template>
                                                                        </a-list-item-title>
                                                                        <a-list-item-subtitle>
                                                                            <div class="u-flex align-center mt-1">
                                                                                <loader-template height="18" width="45%" class="u-rounded-corners-lg"></loader-template>
                                                                                <loader-template height="18" width="30%" class="u-rounded-corners-lg ml-2"></loader-template>
                                                                            </div>
                                                                        </a-list-item-subtitle>
                                                                    </a-list-item-content>
                                                                </a-list-item>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <a-divider></a-divider>
                                                            <a-list-item class="py-1" disabled>
                                                                <a-list-item-content>
                                                                    <a-list-item-title>
                                                                        <span class="md-subtitle-1 mb-0 text-center">No projects found</span>
                                                                    </a-list-item-title>
                                                                </a-list-item-content>
                                                            </a-list-item>
                                                        </template>
                                                    </template>
                                                </a-list>
                                            </a-menu>
                                        </div>
                                        <div class="c-dropdown mb-6">
                                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Assignee</span>
                                            <SManualSaveAssigneeDropdown
                                                :item="local_ticket_item"
                                                :users-list="local_ticket_item.assignees"
                                                :can-update="localCheckTicketPermission"
                                                :size="36"
                                                @click.stop="{}"
                                                @assignee-store="localStoreAssignee"
                                                @unassigned="localUnassignedUser"
                                                @menuOpen="localFetchUsers()"
                                                module-type="Ticket"
                                                class="u-flex align-center mr-2"
                                                hideExternalConfirmation typeOrgUser enableSearch
                                            >
                                                <template #subtitle>You can assign to organization members.</template>
                                            </SManualSaveAssigneeDropdown>
                                        </div>
                                        <div class="c-dropdown mb-5">
                                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">First Reported by</span>
                                            <h3 class="md-body-2 font-weight-medium u-flex align-center grey--text text--darken-2 u-rounded-corners" style="font-family: monospace !important;">
                                                <span v-if="!ticket_item.created_by && ticket_item.bridges && ticket_item.bridges.length" class="font-weight-medium">Jira</span>
                                                <a-tooltip bottom v-else>
                                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on" class="font-weight-medium mr-1">
                                                            {{ ticket_item.created_by ? ticket_item.created_by.name: '' | truncateText(20) }}
                                                        </span>
                                                    </template>
                                                    <div class="u-flex align-center">
                                                        <g-avatar :item="ticket_item.created_by" no-border :size="28" class="mr-2" dark-shadow :right-space="false"></g-avatar>
                                                        <g-profile-info dark :item="ticket_item.created_by"></g-profile-info>
                                                    </div>
                                                </a-tooltip>
                                                <a-menu bottom open-on-hover offset-y>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <g-moment :value="ticket_item.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="[•] MMM DD" convert-local></g-moment>
                                                        </div>
                                                    </template>
                                                    <a-sheet class="md-body-2 u-elevation-custom-1 u-rounded-corners primary darken-1 grey--text text--lighten-1 pa-2 px-3">
                                                        <g-moment :value="ticket_item.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mm A" convert-local></g-moment>
                                                    </a-sheet>
                                                </a-menu>
                                            </h3>
                                        </div>
                                        <!-- <div class="ml-2 mt-4" :class="[timer_update_debounce ? 'text-right pr-2' : 'text-center']" :style="{ minWidth: timer_update_debounce ? 'auto' : '160px', maxWidth: timer_update_debounce ? 'auto' : '160px' }"> -->
                                        <div class="mt-4">
                                            <h3 v-if="timer_update_debounce" class="md-body-2 white orange--text text--darken-1 text-center font-weight-medium pa-2 u-rounded-corners">Autosaving</h3>
                                            <h3 class="md-body-2 white green--text text-center font-weight-medium white pa-2 u-rounded-corners" v-if="!timer_update_debounce && update_autosaved_at">
                                                <g-moment :value="update_autosaved_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="[Autosaved at] hh:mma"></g-moment>
                                            </h3>
                                        </div>
                                    </a-sheet>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-sheet>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Assignee Form -->
        <a-dialog max-width="800" v-model="dialog_add_users_form" persistent>
            <a-card v-if="local_user_edit_item">
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="40">
                                    <a-icon dark>group</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1"><span class="md-heading-6 text-capitalize">Ticket</span> Assignees</h2>
                                <p class="md-subtitle-2 mb-0">Manage users under <strong class="grey--text text--darken-2">{{ localTruncateText(local_user_edit_item.title, 55) }}</strong></p>
                            </a-flex>
                            <a-flex shrink v-if="autosave_user_add_status !== 'none'">
                                <a-chip class="right" small color="grey" text-color="white" v-if="autosave_user_add_status === 'loading'">Saving...</a-chip>
                                <a-chip class="right" small color="green" text-color="white" v-if="autosave_user_add_status === 'done'">Saved</a-chip>
                            </a-flex>
                            <a-flex shrink>
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a-btn small text icon color="grey" :disabled="autosave_user_add_status === 'loading'" @click="dialog_add_users_form = false" class="mx-0 mt-0 mr-0 mb-0 pa-0 px-3 right" v-on="on">
                                            <a-icon size="26">cancel</a-icon>
                                        </a-btn>
                                    </template>
                                    <span>Close</span>
                                </a-tooltip>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start style="min-height: 66px;">
                            <a-flex class="u-flex align-center u-wrap">
                                <span class="md-subtitle-1 d-inline-block py-1 px-2 mr-2 my-1 u-rounded-corners u-cursor-pointer" v-if="local_user_edit_item.assignees && local_user_edit_item.assignees.length === 0">
                                    No Users Assigned
                                </span>
                                <template v-else>
                                    <span class="md-subtitle-1 u-flex align-center py-1 px-2 mr-2 my-1 u-cursor-pointer grey lighten-2" style="border-radius: 50px;" v-for="assignee in local_user_edit_item.assignees" :key="assignee.id">
                                        <div class="member-avatar mr-2">
                                            <g-avatar :item="assignee" :size="30" :right-space="false" no-border></g-avatar>
                                        </div>
                                        <div class="member-label u-cursor-pointer">
                                            <template v-if="assignee && assignee.name">
                                                <h2 class="md-subtitle-1 font-weigh-medium grey--text text--darken-3">{{ assignee.name }}</h2>
                                            </template>
                                            <template v-else>
                                                <h2 class="md-subtitle-1 font-weigh-medium grey--text text--darken-3">{{ assignee.assignee.name }}</h2>
                                            </template>
                                        </div>
                                        <a-icon @click="local_assign(local_user_edit_item, assignee.assignee.id, true)" class="ml-2" size="18">clear</a-icon>
                                    </span>
                                </template>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Select Users</label>
                                <a-autocomplete
                                    v-model="local_selected_user_add_item"
                                    :items="localTicketMemberList(local_user_edit_item.assignees)"
                                    item-text="user.name"
                                    item-value="user_id"
                                    label="Select Users"
                                    class="elevation-0"
                                    background-color="neutral"
                                    clear-icon="backspace"
                                    :menu-props="{ closeOnContentClick: autosave_user_add_status === 'loading' }"
                                    :disabled="loading"
                                    @change="local_assign(local_user_edit_item, local_selected_user_add_item[0] ? local_selected_user_add_item[0].id : '')"
                                    solo flat hide-details multiple small-chips hide-selected clearable deletable-chips autofocus
                                >
                                    <template v-slot:no-data>
                                        <div class="px-4 py-2">
                                            <span class="md-body-1 grey--text">No Users found</span>
                                        </div>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <a-layout align-center class="py-2">
                                            <g-avatar :item="item"></g-avatar>
                                            <g-profile-info :item="item" set-max-width></g-profile-info>
                                        </a-layout>
                                    </template>
                                </a-autocomplete>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-dialog v-model="dialogMaskUpdateForm" persistent max-width="500">
            <SUpdateMaskedFieldPopup
                :item="selectCustomFieldItem"
                :is-loading="maskCustomFieldLoading"
                :is-modal-open="dialogMaskUpdateForm"
                @action="(mask_value) => localUpdateMaskCustomField(mask_value)"
                @close="localUpdateMaskCustomFieldClose()"
            />
        </a-dialog>
        <a-dialog v-model="dialogMaskUpdateError" persistent max-width="400">
            <SUpdateMaskedFieldErrorPopup
                :item="selectCustomFieldItem"
                @close="localUpdateMaskCustomFieldBack()"
            />
        </a-dialog>

        <a-dialog v-model="dialogCancelPageModal" persistent max-width="400">
            <SCancelChanges
                @success="localTicketChangesClear"
                @close="dialogCancelPageModal = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to cancel?</template>
                <template #successButtonLabel>Yes, cancel</template>
                <template #cancelButtonLabel>No</template>
            </SCancelChanges>
        </a-dialog>
        <a-dialog v-model="dialogLeavePageModal" persistent max-width="400">
            <SCancelChanges
                @success="localDialogSuccess"
                @close="localDialogLeavePage()"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
    Bold,
    Italic,
    Strike,
    OrderedList,
    BulletList,
    ListItem,
    History,
} from 'tiptap-extensions'
import marked from 'marked'

import loadWorkspace from '@/mixins/mixin-workspace'
import { SMetaTicketDropdown, SManualSaveAssigneeDropdown, STagsInlineForm, SUpdateMaskedFieldPopup, SUpdateMaskedFieldErrorPopup, SCancelChanges, STicketViewLoader, SAttachmentSection } from '@/config/config-shared-components'
import SDragOverlay from '../../components/Attachments/SharedDragOverlay.vue'
import { VCombobox, VAutocomplete } from 'vuetify/lib'
import { Base64 } from 'js-base64';
import PLinkedTicketCard from "./Partials/PartialLinkedTicketCard.vue"
import mixinLoader from '@/mixins/mixin-module-loading-setup'
import mixinAttachmentsInternal from '@/mixins/mixin-internal-attachment'
import EventBus from '@/config/config-eventbus'

export default {
    mixins: [loadWorkspace, mixinLoader, mixinAttachmentsInternal],

    data () {
        return {
            autosave_user_add_status: 'none',
            local_user_edit_item: {},
            loading_ticket: {},
            searchProject: false,
            project_search_mode: true,
            localSearchProject: '',
            dialog_add_users_form: false,
            dialog_ticket_form: false,
            localShowTicketTagAddInput: false,
            local_selected_user_add_item: null,
            editor_ticket_description: null,
            timer_update_debounce: null,
            update_autosaved_at: null,
            ticket_title: null,
            datepicker_due_date: null,
            datepicker_due_date_edit: null,
            ticket_tag_input: null,
            local_tag_search: null,
            statusList: [],
            priorityList: [],
            typeList: [],
            local_ticket_item: {},
            pageLoading: true,
            custom_fields_visibility: true,
            autosave_custom_field_timeout: null,
            autosave_custom_field: { id: null, status: 'none' },
            local_custom_value_search: null,
            local_project_fields: {
               'fields[projects]': 'id,title,deal_amount,customer_id,customer_name,status',
            },
            local_ticket_fields: { 'include': 'status,priority,type,createdBy,bridges,assignees.role,tags,customFieldValues,links.parent,linksCount,linksWorkspace.priority,linksWorkspace.parent.keyCollaborators.type,linksWorkspace.parent.keyCollaborators.user,linksWorkspace.parent.customer,linksWorkspaceCount', 'filter[layout_designer]': 1 },
            dialogMaskUpdateForm: false,
            dialogMaskUpdateError: false,
            maskCustomFieldLoading: false,
            dialogCancelPageModal: false,
            dialogLeavePageModal: false,
            isTicketUpdateLoading: false,
            selectCustomFieldItem: {},
            local_custom_field_list: [],
            datepickerDateValue: {},
            // datepickerMenu: [],
            currentEditId: null,
            currentParent: null,
            local_linked_ticket_fields: { 'include': 'links.parent,linksCount,linksWorkspace.priority,linksWorkspace.parent.keyCollaborators.type,linksWorkspace.parent.keyCollaborators.user,linksWorkspace.parent.customer,linksWorkspaceCount' },
            linkedTicketEditItem: {},
            loadingLinkedMeta: {},
            unsaved_changes: false,
            local_loading: false,
            custom_field_types: [
                { type: 'text', icon: 'text_fields' },
                { type: 'multiline', icon: 'subject' },
                { type: 'dropdown', icon: 'arrow_drop_down_circle' },
                { type: 'mask', icon: 'password' },
                { type: 'date', icon: 'calendar_month' },
                { type: 'tags', icon: 'sell' },
                { type: 'url', icon: 'link' },
                { type: 'number', icon: 'pin' },
            ],
            localLayoutTicketDesignList: [],
            localClonelayoutDesignList: [],
            localTicketSelectedTags: [],
            localTicketRemovedTags: [],
            localTicketSelectedAssignees: [],
            localTicketRemovedAssignees: [],
            localTicketLikendProjects: [],
            localUnLikendTicktes: [],
            localCustomFieldValues: [],
            localTicketUpdateErrors : [],
            islocalTicketFieldUpdate: null,
            islocalCustomFieldValueUpdate: null,
            islocalTagsUpdate: null,
            pageToLeave: null,
            field_counter: 0,
            filteredProjects: [],
            project_loading: false,
            search_project_timeout: null,

            //Attachments
            filesDraggedOver: false,
            hoveredOnInfo: false,
            FILTER: {
                source_id: 'filter[source_id]',
                source_type: 'filter[source_type]',
                fields: 'fields[attachments]',
                page: 'page'
            },
            attachment_fields: 'id,name,size,extension,order,source_id,source_type',
            localDroppedFilesList: [],
            localAttachmentFilters: {},
            backupAttachmentList: [],

            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
            local_workspace_switch_id: '',
        }
    },

    components: {
        EditorContent,
        EditorMenuBubble,
        SManualSaveAssigneeDropdown,
        SCancelChanges,
        SMetaTicketDropdown,
        VCombobox,
        VAutocomplete,
        STagsInlineForm,
        SUpdateMaskedFieldPopup,
        SUpdateMaskedFieldErrorPopup,
        PLinkedTicketCard,
        STicketViewLoader,
        SAttachmentSection,
        SDragOverlay
    },

    watch: {
        searchProject (newVal, oldVal) {
            if (newVal && newVal !== oldVal) this.localSearchProject = null
        },

        localSearchProject() {
            this.localFetchSearchProject()
        }
    },

    mounted () {
        this.localIndex()
        if (this.$can('tickets.update')) this.localAddListeners()
    },

    beforeDestroy () {
        this.localRemoveListeners()
    },

    beforeRouteLeave (toPage, fromPage, nextRoute) {
        EventBus.$emit('updateUnsavedState', false)
        if (!this.localCheckTicketUpdate) {
            return nextRoute()
        }
        nextRoute(false)
        this.dialogLeavePageModal = true
        this.pageToLeave = nextRoute
    },

    created () {
        EventBus.$on('workspaceState', this.localCheckWorkspaceState)
    },

    computed: {
        local_screen_lg() {
            return this.$vuetify.breakpoint.lgAndDown
        },

        localCheckTicketUpdate () {
            const checkTicketUpdate = (this.islocalTicketFieldUpdate === false || this.islocalCustomFieldValueUpdate === false || this.localCheckAttachmentUpdate)
            EventBus.$emit('updateUnsavedState', checkTicketUpdate)
            return checkTicketUpdate
        },

        localCheckSystemFieldsUpdate () {
            return !(this.local_ticket_item && this.local_ticket_item.title !== this.ticket_item.title ||
                    this.local_ticket_item.description !== this.ticket_item.description ||
                    this.local_ticket_item.due_date !== this.ticket_item.due_date ||
                    this.local_ticket_item.status_id !== this.ticket_item.status_id ||
                    this.local_ticket_item.priority_id !== this.ticket_item.priority_id ||
                    this.local_ticket_item.type_id !== this.ticket_item.type_id)
        },

        localCheckTicketPermission () {
            return (this.$can('tickets.update') && !this.isTicketUpdateLoading)
        },

        localCheckAttachmentUpdate () {
            return !!_.size(this.attachment_upload_list) || !!_.size(this.attachment_delete_list)
        },

        localAttachmentsUpdating () {
            return this.mixinIsLoading('attachment-update') || this.mixinIsLoading('attachment-delete')
        },

        ...mapState('Project', {
            project_list: 'list',

        }),

        ...mapState('User', {
            user_list: 'list',
            user_self: 'self',
        }),

        ...mapState('Ticket', {
            ticket_item: 'item',
            ticket_filters: 'filters',
            ticket_response: 'response',
            ticket_link_response: 'linked_item_response'
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
            tag_item: 'item',
            tag_response: 'response'
        }),

        ...mapState('Assignee', {
            assignee_response: 'response',
        }),

        ...mapState('CustomField', {
            custom_field_list: 'list',
            custom_field_item: 'item',
            custom_field_form_mode: 'form_mode',
            custom_field_response: 'response',
        }),

        ...mapState('CustomFieldValue', {
            custom_field_value_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('Attachment', {
            attachment_list: 'list',
            attachment_local_list: 'local_list',
            attachment_delete_list: 'delete_list',
            attachment_upload_list: 'upload_list',
            attachment_meta: 'meta',
            attachment_response: 'response',
            attachment_delete_response: 'delete_response',
        }),

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),

    },

    methods: {
        async localIndex () {
            await this.ticket_clear_item()
            await this.localShowTicket(this.$route.params.id)
            if (this.ticket_response && this.ticket_response.status !== 'success') {
                if (this.ticket_response.code === 404) return this.$router.replace({ name: '404' })
            }
            this.localLoadTicketAttachments()
            this.localSetTicketdescription()
            this.localLoadTicketMetas()
            this.localFetchUsers()
            this.localLoadTicketTags()
            this.localLoadProjectList()
            this.localLoadLayoutCustomFieldValues()
            this.pageLoading = false
        },

        async localLoadTicketAttachments () {
            this.mixinSetLoading('attachment')
            this.attachment_clear_response()
            this.attachment_clear_upload_list()
            this.localSetAttachmentFilters()
            await this.attachment_index({ params: this.localAttachmentFilters })
            this.mixinResetLoading('attachment')
            this.mixinUpdateLocalAttachmentList({ list: this.attachment_list })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localSetAttachmentFilters () {
            this.localAttachmentFilters[this.FILTER.source_id] = this.$route.params.id
            this.localAttachmentFilters[this.FILTER.source_type] = 'Ticket'
            this.localAttachmentFilters[this.FILTER.fields] = this.attachment_fields
            this.localAttachmentFilters.count = 5
        },

        localCheckWorkspaceState (payload) {
            const { workspaceState, workspaceId } = payload
            if (workspaceState && workspaceId && this.localCheckTicketUpdate) {
                this.local_workspace_switch_id = workspaceId
                this.dialogLeavePageModal = workspaceState
            }
        },

        localDialogLeavePage () {
            this.dialogLeavePageModal = false
            EventBus.$emit('updateUnsavedState', true)
            this.pageToLeave = null
        },

        async localDialogSuccess () {
            if (this.pageToLeave) return this.pageToLeave()
            await this.localTicketChangesClear()
            EventBus.$emit('workspaceUpdate', {
                dialogLeavePageModal: this.dialogLeavePageModal,
                workspace_switch_id: this.local_workspace_switch_id
            })
            this.dialogLeavePageModal = false
        },

        async localLoadLayoutCustomFieldValues () {
            await this.localLoadTicketCustomFields()
            await this.localSetTicketCustomFieldValues()
            await this.localSetTicketInsertableFields()
            this.localClonelayoutDesignList = _.cloneDeep(this.localLayoutTicketDesignList)
        },

        async localLoadProjectList () {
            await this.project_index({ ...this.local_project_fields, 'filter[workspace_id]': this.current_workspace_id_mixin, 'filter[status]': 'active' })
            await this.localFilteredProjects()
            await this.localUnselectedProjects()
        },

        async localLoadTicketTags () {
            await this.tag_index(
                {
                    'filter[type]': 'ticket_tag' ,
                    'fields[tags]': 'id,label,color,type'
                }
            )
        },

        async localLoadTicketMetas () {
            await this.localLoadTicketStatus()
            await this.localLoadTicketPriority()
            await this.localLoadTicketType()
        },

        async localLoadTicketCustomFields () {
            await this.custom_field_value_clear_item()
            this.custom_field_index({
                'fields[custom_fields]': 'id,name,label,is_active,order,placeholder,type,options,description,user_writable',
                'filter[is_active]': 1,
                'filter[resource_type]': 'Ticket',
            })
        },

        async localLoadTicketStatus () {
            await this.meta_index({
                'filter[type]': 'ticket_status',
                'sort': 'order'
            })
            this.statusList = this.meta_list
        },

        async localLoadTicketPriority () {
            await this.meta_index({
                'filter[type]': 'ticket_priority',
                'sort': 'order'
            })
            this.priorityList = this.meta_list
        },

        async localLoadTicketType () {
            await this.meta_index({
                'filter[type]': 'ticket_type',
                'sort': 'order'
            })
            this.typeList = this.meta_list
        },

        async local_show_add_users_dialog (item) {
            this.autosave_user_add_status = 'none'
            this.local_user_edit_item = item
            this.dialog_add_users_form = true
        },

        async local_assign (ticket, user_id, unassign) {
            const assignee_param = { resource_type: 'Ticket', resource_id: ticket.id, user_id  }
            this.autosave_user_add_status = 'none'
            this.autosave_user_add_status = 'loading'

            if (unassign) await this.assignee_destroy({ id: user_id })
            else await this.assignee_store(assignee_param)
            if (this.assignee_response.status === 'success') {
                await this.localShowTicket(ticket.id)
                this.local_reset_assignee_variables(ticket.id)
            }
        },

        local_reset_assignee_variables (ticket_id) {
            this.local_selected_user_add_item = null
            this.autosave_user_add_status = 'done'
            Object.assign(this.local_user_edit_item, this.ticket_item)
        },

        localFocusTicketEditor () {
            this.local_ticket_item.description = ' '
            this.localSetTicketdescription()
            this.editor_ticket_description.focus()
        },

        localSetTicketdescription () {
            if (this.editor_ticket_description) this.editor_ticket_description.destroy()
            const content = this.local_ticket_item.description ? this.local_ticket_item.description : ''
            this.editor_ticket_description = new Editor({
                extensions: [
                    new Bold(),
                    new Italic(),
                    new Strike(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new History(),
                ],
                content: marked(content, { sanitize: true }),
                onUpdate: this.localTicketUpdateDebounced,
                editable: this.localCheckTicketPermission && !this.localAttachmentsUpdating,
            })
            this.editor_ticket_description.on('update', () => {
                this.localGetFieldUpdate()
            })
        },

        localSetTicketCustomFieldValues () {
            if (this.ticket_item) {
                const field_values = _.cloneDeep(this.ticket_item.custom_field_values)
                this.localLayoutTicketDesignList.forEach(section => {
                    section.fields.forEach(field => {
                        const hasFieldValue = field_values.find(value => value.custom_field_id === field.parent.id)
                        if (hasFieldValue) {
                            hasFieldValue.mask_value = field.parent.type === 'mask' ? hasFieldValue.value : ''
                            field.parent = Object.assign(field.parent, { custom_field_value: hasFieldValue })
                        }
                        else field.parent = Object.assign(field.parent, { custom_field_value: { value: '', label: '', mask_value: '' } })
                    })
                })

                this.localLayoutTicketDesignList.forEach((section, sectionIndex) => {
                    section.fields.forEach((field, index) => {
                        if (field.parent.type === 'date') {
                            let date_value = field.parent.custom_field_value.value
                            this.datepickerDateValue[field.parent.id] = date_value ? this.localFormatDate(date_value) : ''
                        }
                    })
                })
            }
        },

        async localTicketCustomFieldDateClear (section_id, custom_field, value, hide) {
            this.datepickerDateValue[custom_field.id] = ''
            hide()
            const index = this.localClonelayoutDesignList.findIndex(item => item.id === section_id)
            if (index !== -1) {
                const layoutFields = this.localClonelayoutDesignList[index].fields
                const fieldIndex = layoutFields.findIndex(item => item.id === custom_field.id)
                if (fieldIndex !== -1) {
                    const fieldparent = this.localClonelayoutDesignList[index].fields[fieldIndex].parent
                    if (fieldparent) {
                        this.localClonelayoutDesignList[index].fields[fieldIndex].parent.custom_field_value.value = ''
                    }
                }
            }
            this.localTicketCustomFieldValueUpdate(custom_field.parent, value)
            this.localGetCustomFieldValueUpdate()
        },

        local_can_update(field) {
            return !(this.localCheckTicketPermission) || field.parent.user_writable === 0
        },

        async localTicketCustomFieldUpdate(custom_field, value) {
            await this.localTicketCustomFieldValueUpdate(custom_field, value)
            this.localGetCustomFieldValueUpdate()
        },

        localTicketCustomFieldValueUpdate (custom_field, value) {
            const index = this.localCustomFieldValues.findIndex(item => item.name === custom_field.name)
            if (index === -1) {
                this.localCustomFieldValues.push(_.cloneDeep(custom_field))
            }else {
                this.localCustomFieldValues[index].custom_field_value.value = value
            }
        },

        async localSelectTicketCustomField(custom_field, value) {
            this.localTicketCustomFieldValueUpdate(custom_field, value)
            this.localGetCustomFieldValueUpdate()
        },

        async localSelectCustomFieldItem(section_id, field_id, custom_field_item) {
            this.selectCustomFieldItem = {}
            this.selectCustomFieldItem = custom_field_item
            this.selectCustomFieldItem.section_id = section_id
            this.selectCustomFieldItem.field_id = field_id
            this.maskCustomFieldLoading = false
            this.dialogMaskUpdateForm = true
        },

        async localUpdateMaskCustomField (value) {
            const index = this.localClonelayoutDesignList.findIndex(item => item.id === this.selectCustomFieldItem.section_id)
            if (index !== -1) {
                const layoutFields = this.localClonelayoutDesignList[index].fields
                const fieldIndex = layoutFields.findIndex(item => item.id === this.selectCustomFieldItem.field_id)
                if (fieldIndex !== -1) {
                    const fieldparent = this.localClonelayoutDesignList[index].fields[fieldIndex].parent
                    if (fieldparent) {
                        this.localClonelayoutDesignList[index].fields[fieldIndex].parent.custom_field_value.value = value
                        this.localClonelayoutDesignList[index].fields[fieldIndex].parent.custom_field_value.mask_value = this.localCustomFieldMaskedString(value, '*')
                        this.localTicketCustomFieldValueUpdate(this.localClonelayoutDesignList[index].fields[fieldIndex].parent, value)
                    }
                }
            }
            this.localGetCustomFieldValueUpdate()
            this.dialogMaskUpdateForm = false
        },

        localCustomFieldMaskedString (value, maskChar) {
            if (value && value.trim()) {
                let maskedString = '';
                for (let i = 0; i < value.length; i++) {
                    maskedString += maskChar;
                }
                return maskedString;
            }
        },

        localUpdateMaskCustomFieldClose () {
            this.dialogMaskUpdateForm = false
        },

        async localCheckUpdateMaskCustomField() {
            this.maskCustomFieldLoading = false
            if (this.custom_field_value_response.status === 'success') {
                await this.localShowUpdateCustomField()
                this.dialogMaskUpdateForm = false
                return
            }
            if(this.custom_field_value_response.server && this.custom_field_value_response.server.errors && this.custom_field_value_response.server.errors.user_writable) {
                this.dialogMaskUpdateForm = false
                this.dialogMaskUpdateError = true
            }
        },

        async localUpdateMaskCustomFieldBack() {
            this.selectCustomFieldItem.user_writable = 0
            this.custom_field_value_update_item(this.selectCustomFieldItem)
            this.dialogMaskUpdateError = false
        },

        async localShowUpdateCustomField () {
            await this.localShowTicket(this.$route.params.id)
            this.localSetTicketCustomFieldValues()
        },

        async localTicketUpdateDebounced (state = null) {
            this.localTicketClearDebounceTimer()
            if (state) this.local_ticket_item.description = turndownService.turndown(state.getHTML())
        },

        async localTicketClearDebounceTimer (saved = false) {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                if (saved) {
                    this.update_autosaved_at = moment().format('YYYY-MM-DD HH:mm:ss')
                    setTimeout(() => this.update_autosaved_at = null, 5000)
                }
            }
        },

        localClearTicketDate () {
            this.local_ticket_item.due_date = null
            this.localGetFieldUpdate()
        },

        localSelectTicketdatepicker(custom_field, index) {
            let date_value = custom_field.custom_field_value.value
            this.datepickerDateValue[custom_field.id] = this.localFormatDate(date_value)
            this.localTicketCustomFieldUpdate(custom_field, date_value, index)
        },

        // Metas Update
        async localUpdateTicketMeta ({ meta_id }, field, clear = false) {
            if (clear) return this.local_ticket_item[field + '_id'] = null
            if (this.local_ticket_item[field + '_id'] === meta_id) return
            const list = this.localGetMetaList(field)
            const item = list.find(item => item.id === meta_id)
            this.local_ticket_item[field + '_id'] = meta_id
            this.local_ticket_item[field] = item
            this.localGetFieldUpdate()
        },

        localGetMetaList (field) {
            if (field === 'type') return this.typeList
            if (field === 'priority') return this.priorityList
            if (field === 'status') return this.statusList
        },

        async localTicketDestroy (ticket) {
            await this.ticket_destroy(ticket)
            if (this.ticket_response && this.ticket_response.status !== 'success') return
            this.$router.replace({ name: 'tickets' })
        },

        async localShowTicket (id) {
            await this.ticket_show({
                id: id ?? this.ticket_item.id,
                params: {
                    ...this.local_ticket_fields,
                    workspace_id: this.current_workspace_id_mixin
                }
            })
            this.local_ticket_item = _.cloneDeep(this.ticket_item)
            this.localLayoutTicketDesignList = _.cloneDeep(this.ticket_item.layoutDesigner)
        },

        localFilteredTags () {
            if (this.local_ticket_item && this.local_ticket_item.tags && !this.local_ticket_item.tags) return this.tag_list
            return this.tag_list.filter(tag => !_.find(this.local_ticket_item.tags, { id: tag.id }))
        },

        localTicketMemberList (list) {
            return this.user_list.filter(item => !(_.find(list, { id: item.id })))
        },

        // Project Link
        async localProjectTicketUnlink (ticket) {
            const index = this.local_ticket_item.projects.findIndex(list => list.id === ticket.parent_id)
            if (index !== -1) {
                const project = this.local_ticket_item.projects.find(list => list.id === ticket.parent_id)
                this.local_ticket_item.project_ticket_linked_count = this.local_ticket_item.project_ticket_linked_count - 1
                this.local_ticket_item.deal_value = parseInt(this.local_ticket_item.deal_value) - parseInt(project.deal_amount ?? 0)
                this.local_ticket_item.projects.splice(index, 1)
            }

            const unlinkItem = this.localUnLikendTicktes.find(list => list.id === ticket.id)
            if (!unlinkItem) this.localUnLikendTicktes.push(_.cloneDeep(ticket))
            this.localGetFieldUpdate()
        },

        async localProjectTicketLink (project) {
            project['tickets'] = []
            const ticket = this.$appendId(
                {
                    title : this.local_ticket_item.title,
                    priority : this.local_ticket_item.priority,
                    priority_id : this.local_ticket_item.priority_id,
                    status : this.local_ticket_item.status,
                    status_id : this.local_ticket_item.status_id,
                    type : this.local_ticket_item.type,
                    type_id : this.local_ticket_item.type_id,
                    parent_id : project.id
                }
            )
            project['tickets'].push(ticket)
            this.local_ticket_item.links.push(ticket)
            this.local_ticket_item.projects.push(project)
            this.local_ticket_item.links_workspace.unshift(ticket)
            this.local_ticket_item.project_ticket_linked_count = this.local_ticket_item.project_ticket_linked_count + 1
            this.local_ticket_item.deal_value = parseInt(this.local_ticket_item.deal_value) + parseInt(project.deal_amount ?? 0)
            const index = this.localTicketLikendProjects.findIndex(list => list.id === project.id)
            if (index === -1) {
                this.localTicketLikendProjects.push(_.cloneDeep(project))
            }
            this.localGetFieldUpdate()
            this.localFilteredProjects()
        },

        localFilteredProjects () {
            if (!this.localSearchProject || (this.localSearchProject && !this.localSearchProject.trim())) return this.localUnselectedProjects()
            return this.localUnselectedProjects().filter(item => item.title && item.title.toLowerCase().includes(this.localSearchProject.toLowerCase()))
        },

        localUnselectedProjects () {
            this.filteredProjects = []
            this.project_list.filter(project => {
                if (this.local_ticket_item && this.local_ticket_item.links_workspace) {
                    const selected_project = this.local_ticket_item.links_workspace.find(item => item.parent_id === project.id)
                    if (!selected_project) this.filteredProjects.push(project)
                }
            })
        },

        async localFetchSearchProject() {
            this.filteredProjects = []
            this.project_loading = true
            if (this.search_project_timeout) clearTimeout(this.search_project_timeout)
            if (!this.localSearchProject) {
                await this.project_index({ ...this.local_project_fields, 'filter[workspace_id]': this.current_workspace_id_mixin, 'filter[status]': 'active' })
                this.localUnselectedProjects()
                this.project_loading = false
                return
            }
            this.search_project_timeout = setTimeout(async () => {
                await this.project_index({
                    ...this.local_project_fields,
                    'filter[title]': this.localSearchProject,
                    'filter[workspace_id]': this.current_workspace_id_mixin,
                    'filter[status]': 'active'
                })

                this.localUnselectedProjects()
                this.project_loading = false
            }, 500);
        },
        // Project Link ----- End

        // Tags CRUD
        async localticketAssociateTagDestroy (data, item) {
            this.localRemoveTicketTag(data, item)
            this.localRemoveSelectedTag(data)
            this.localGetFieldUpdate()
        },

        async localRemoveTicketTag (tag, item) {
            const index = item.tags.findIndex(item => item.id === tag.id)
            if (index !== -1) item.tags.splice(index, 1)
            if (tag.association) {
                const remove_tag_index = this.localTicketRemovedTags.findIndex(item => item.id === tag.id)
                if (remove_tag_index === -1) {
                    this.localTicketRemovedTags.push(_.cloneDeep(tag))
                }
            }
        },
        // Tags CRUD ----- END

        // Extras
        async localFetchUsers () {
            const userIds = _.size(this.local_ticket_item.assignees) ? _.map(this.local_ticket_item.assignees, 'id') : null
            this.user_index({
                'filter[is_active]': 1,
                'filter[negate_user_ids]': userIds,
                include: 'role',
                'fields[users]': 'id,name,email,initial,role_id,avatar,scope,color',
                'fields[role]': 'id,name,scope',
                count: 100,
                page: 1
            })
        },

        localFormatDate (date) {
            return date !== null ? moment(date).format('MMM D, YYYY') : null
        },

        localTruncateText (text, range, separator = '...') {
            if (!text) return
            return text.length > range ? text.slice(0, range) + separator : text
        },

        localHasHiddenProjects () {
            if (this.local_ticket_item && this.local_ticket_item.projects) return Math.abs((this.local_ticket_item.project_ticket_linked_count ?? 0) - this.local_ticket_item.projects.length)
            return 0
        },

        localTitleEditFocus (id) {
            this.currentEditId = id
        },

        async localTicketInlineUpdate (ticket, type) {
            if (type === 'blur' && this.currentEditId === ticket.id) this.currentEditId = null
            if (type === 'clear') return this.localResetLinkedTicketTitle(ticket)

            this.localUpdateLinkedTicket(ticket, false, 'title')
            this.localGetFieldUpdate()
        },

        async localTicketUpdateOnBlur (prevTicket) {
            this.localUpdateLinkedTicket(prevTicket, false, 'title')
            if (this.currentEditId === prevTicket.id) this.currentEditId = null
        },

        async localUpdateLinkedTicket (ticket, blur, type) {
            this.localClonedticketLinkUpdate(ticket, 'title')
            if (!blur) this.currentEditId = null
        },

        localClonedticketLinkUpdate (ticket, type, field_type = null, field = null, meta_id = null) {
            const projectIndex = this.localTicketLikendProjects.findIndex(item => item.id === ticket.parent_id)
            if (projectIndex !== -1) {
                const ticketIndex = this.localTicketLikendProjects[projectIndex].tickets.findIndex(item => item.id === ticket.id)
                if (ticketIndex !== -1) {
                    if (field_type === 'meta_update') {
                        const list = this.localGetMetaList(field)
                        const item = list.find(item => item.id === meta_id)
                        this.localTicketLikendProjects[projectIndex].tickets[ticketIndex][field + '_id'] = meta_id
                        this.localTicketLikendProjects[projectIndex].tickets[ticketIndex][field] = _.cloneDeep(item)
                    } else {
                        this.localTicketLikendProjects[projectIndex].tickets[ticketIndex][type] = ticket[type]
                    }
                }
                return
            }
            const projectItem = this.local_ticket_item.projects.find(item => item.id === ticket.parent_id)
            if (projectItem) {
                this.localTicketLikendProjects.push(_.cloneDeep(projectItem))
            }
        },

        async localTicketMetaUpdate ({ meta_id }, field, ticket, itemId) {
            if (meta_id === itemId) return

            const list = this.localGetMetaList(field)
            const item = list.find(item => item.id === meta_id)
            const projectIndex = this.local_ticket_item.projects.findIndex(item => item.id === ticket.parent_id)
            if (projectIndex !== -1) {
                const ticketIndex = this.local_ticket_item.projects[projectIndex].tickets.findIndex(item => item.id === ticket.id)
                if (ticketIndex !== -1) {
                    this.local_ticket_item.projects[projectIndex].tickets[ticketIndex][field + '_id'] = meta_id
                    this.local_ticket_item.projects[projectIndex].tickets[ticketIndex][field] = _.cloneDeep(item)
                }
            }
            this.localClonedticketLinkUpdate(ticket, null, 'meta_update', field,  meta_id)
            this.localGetFieldUpdate()
        },

        localResetLinkedTicketTitle (ticket) {
            this.localResetTicketTitle(ticket, 'title')
            this.currentEditId = null
            this.unsaved_changes = false
            this.localGetFieldUpdate()
        },

        localResetTicketTitle (ticket, type) {
            const projectIndex = this.ticket_item.projects.findIndex(item => item.id === ticket.parent_id)
            if (projectIndex !== -1) {
                const ticketIndex = this.ticket_item.projects[projectIndex].tickets.findIndex(item => item.id === ticket.id)
                if (ticketIndex !== -1) {
                    const olditicketItem = this.ticket_item.projects[projectIndex].tickets[ticketIndex]
                    if (olditicketItem) {
                        const newProjectIndex = this.local_ticket_item.projects.findIndex(item => item.id === ticket.parent_id)
                        if (newProjectIndex !== -1) {
                            const newTicketIndex = this.local_ticket_item.projects[newProjectIndex].tickets.findIndex(item => item.id === ticket.id)
                            if (newTicketIndex !== -1) {
                                this.local_ticket_item.projects[newProjectIndex].tickets[newTicketIndex][type] = olditicketItem[type]
                            }
                        }
                    }
                }
            }
        },

        localCheckProjectTicketPermission () {
            return (this.localCheckTicketPermission && this.$can('projects.update-only') && this.$can('poc_tickets.update') && this.$can('tickets.update'))
        },

        local_handler_close (e) {
            e.returnValue = 'Are you sure to close the tab?';
        },

        localGetCustomFieldTypeItem(field) {
            if(field && field.parent_type === 'TagSection' || field.type === 'Tag') return 'sell'
            const type = field && field.parent && field.parent.type ? field.parent.type : field.type
            const custom_field_item = this.custom_field_types.find(item => item.type === type)
            if (custom_field_item) return custom_field_item.icon
        },

        localSetTicketInsertableFields () {
            this.field_counter = 0
            this.localLayoutTicketDesignList.forEach((section ,index) => {
                section.fields.forEach((item, fieldIndex) => {
                    item.solo_field = false
                    if (item.width === '50%') {
                        ++this.field_counter
                        if(this.field_counter % 2 !== 0 && (fieldIndex+1 < section.fields.length) && (section.fields[fieldIndex+1].width === '100%')) {
                            item.solo_field = true
                            this.field_counter = 0
                        } else if (this.field_counter % 2 === 0) {
                            item.solo_field = false
                            this.field_counter = 0
                        }
                    }
                    if (item.width === '100%') {
                        item.solo_field = false
                        this.field_counter = 0
                    }
                })
            })
        },

        local_show_divider (section) {
            if (section.fields.length === 1 && section.fields[0].width === '50%') return true
            if (section.fields.length > 1 && section.fields[0].width === '50%' && section.fields[1].width !== '50%') return true
        },

        local_show_item_divider (section, field, index) {
            if (index === 0 && section.fields.length === 1 && field.width === '50%') return false
            if (index === 0 && section.fields.length > 1 && field.width === '50%' && section.fields[1].width !== '50%') return false
            return !field.solo_field
        },

        async localGetFieldUpdate () {
            this.islocalTicketFieldUpdate = _.isEqual(this.local_ticket_item, this.ticket_item)
        },

        async localGetCustomFieldValueUpdate () {
            this.islocalCustomFieldValueUpdate = _.isEqual(this.localLayoutTicketDesignList, this.localClonelayoutDesignList)
        },

        localCheckTicketPageUpdate () {
            this.localGetFieldUpdate()
            this.localGetCustomFieldValueUpdate()
        },

        localGetSelectedTag (tag) {
            this.localTicketSelectedTags.push(_.cloneDeep(tag))
        },

        localRemoveSelectedTag (tag) {
            const index = this.localTicketSelectedTags.findIndex(item => item.id === tag.id)
            if (index !== -1) this.localTicketSelectedTags.splice(index, 1)
        },

        localStoreAssignee (user) {
            const item =  Object.assign(user, { assignee: { user_id: user.id } })
            this.local_ticket_item.assignees.push(_.cloneDeep(item))
            const index = this.localTicketSelectedAssignees.findIndex(list => list.id === user.id)
            if (index === -1) {
                this.localTicketSelectedAssignees.push(_.cloneDeep(item))
            }
            this.localGetFieldUpdate()
        },

        localUnassignedUser (user) {
            const user_id  = user.id
            const assignee = user.assignee
            const index = this.local_ticket_item.assignees.findIndex(item => item.id === user_id)
            if (index !== -1) this.local_ticket_item.assignees.splice(index, 1)
            const select_assignee_index = this.localTicketSelectedAssignees.findIndex(item => item.id === user_id)
            if (select_assignee_index !== -1) this.localTicketSelectedAssignees.splice(index, 1)

            if (assignee && assignee.user_id && assignee.resource_id && assignee.id) {
                const index = this.localTicketRemovedAssignees.findIndex(list => list.id === user.id)
                if (index === -1) {
                    this.localTicketRemovedAssignees.push(_.cloneDeep(user))
                }
            }
            this.localGetFieldUpdate()
        },

        async localBeforeUnloadEvent (e) {
            await this.localCheckTicketPageUpdate()
            if (this.localCheckTicketUpdate) {
                return e.returnValue = 'You have unsaved changes. Are you sure you want to leave?'
            }
        },

        localTicketUpdateCancel () {
            this.dialogCancelPageModal = true
        },

        async localTicketChangesClear () {
            this.local_ticket_item = _.cloneDeep(this.ticket_item)
            this.localSetTicketdescription()
            await this.localSetTicketCustomFieldValues()
            this.localClonelayoutDesignList = _.cloneDeep(this.localLayoutTicketDesignList)
            this.localCheckTicketPageUpdate()
            this.localClearAttachmentUpdate()
            this.localTicketSelectedAssignees = []
            this.localTicketRemovedAssignees = []
            this.localTicketSelectedTags = []
            this.localTicketRemovedTags = []
            this.localTicketLikendProjects = []
            this.localUnLikendTicktes = []
            this.localCustomFieldValues = []
            this.localTicketUpdateErrors = []
            this.dialogCancelPageModal = false
        },

        localTicketValidationCheck () {
            this.localGetFieldUpdate()
            this.localTicketUpdateErrors = []
        },

        async localTicketUpdate () {
            this.localShowTicketTagAddInput = false
            this.localTicketUpdateErrors = []
            this.isTicketUpdateLoading = true
            const data = await this.localCheckTicketUpdateErros()
            if (data) {
                this.$notify('error', 'One or more fields are invalid. Ticket could not be saved. Please update and try again.')
                this.isTicketUpdateLoading = false
                return
            }
            if(this.localCheckSystemFieldsUpdate === false) await this.localUpdateSystemFieldUpdate()
            await this.localUpdateTicketAttachment()
            await this.localAddTicketAssignees()
            await this.localTicketAssigneesRemoved()
            await this.localAddTicketTags()
            await this.localTicketTagsRemoved()
            await this.localTicketUnlinkProjectes()
            await this.localTicketCustomFieldValuesUpdate()
            await this.localTicketlinkedProjectes()
            await this.localShowTicket(this.$route.params.id)
            await this.localSetTicketCustomFieldLayoutValues()
            this.islocalTicketFieldUpdate = true
            this.islocalCustomFieldValueUpdate = true
            this.isTicketUpdateLoading = false
            this.localSetTicketdescription()
            if (!this.localCheckTicketPageUpdate()) this.$notify('success', 'Ticket Updated Successfully')
        },

        async localSetTicketCustomFieldLayoutValues () {
            await this.localSetTicketCustomFieldValues()
            await this.localSetTicketInsertableFields()
            this.localClonelayoutDesignList = _.cloneDeep(this.localLayoutTicketDesignList)
        },

        localCheckTicketUpdateErros () {
            if (this.local_ticket_item) {
                if (!this.local_ticket_item.title.trim()) {
                    const erroritem = {}
                    erroritem.name = 'title'
                    erroritem.message = 'Required field.'
                    this.localTicketErrorMessageUpdate(erroritem)
                }
                if (this.local_ticket_item.title && this.local_ticket_item.title.trim().length > 1000) {
                    const erroritem = {}
                    erroritem.name = 'title'
                    erroritem.message = 'Only maximum of 1000 characters allowed.'
                    this.localTicketErrorMessageUpdate(erroritem)
                }
                if (this.local_ticket_item.description && this.local_ticket_item.description.trim().length > 50000) {
                    const erroritem = {}
                    erroritem.name = 'description'
                    erroritem.message = 'Only maximum of 50000 characters allowed.'
                    this.localTicketErrorMessageUpdate(erroritem)
                }
            }
            this.localCustomFieldValues.forEach(item => {
                const length = item.type === 'multiline' ? 50000 : 255
                if (!['date','url','number'].includes(item.type) && item.custom_field_value.value && item.custom_field_value.value.trim().length > length) {
                    const customFieldErroritem = {}
                    customFieldErroritem.name = item.name
                    customFieldErroritem.message = `Only maximum of ${length} characters allowed.`
                    this.localTicketErrorMessageUpdate(customFieldErroritem)
                }
                if (item.type === 'url') this.localCheckTicketUpdateUrlErrors(item)
                if (item.type === 'number') this.localTicketNumberValidation(item)
            });
            if (this.localTicketUpdateErrors.length) return true
            return false
        },

        localTicketNumberValidation (item) {
            const value = item.custom_field_value ? item.custom_field_value.value : null
            if (!value) return
            if (isNaN(value) === true) {
                const customFieldErroritem = {}
                customFieldErroritem.name = item.name
                customFieldErroritem.message = `The ${item.label} must be number. `
                this.localTicketErrorMessageUpdate(customFieldErroritem)
                return
            }
            const decimal = /^(?:\d*\.\d{1,2}|\d+)$/
            if (decimal.test(value) === false) {
                const customFieldErroritem = {}
                customFieldErroritem.name = item.name
                customFieldErroritem.message = `Only numbers with up to two decimal places are allowed.`
                this.localTicketErrorMessageUpdate(customFieldErroritem)
            }
            const maxNumber = 1000000000.00
            if (value < 0.00 || value > maxNumber) {
                const customFieldErroritem = {}
                customFieldErroritem.name = item.name
                customFieldErroritem.message = `The ${item.label} must be between 0.00 and ${maxNumber}.`
                this.localTicketErrorMessageUpdate(customFieldErroritem)
            }
        },

        localIsNumber (evt, type) {
            if (type !== 'number') return
            const charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            }
        },

        localCheckTicketUpdateUrlErrors (item) {
            const value = item.custom_field_value.value.trim()
            if (!value) return
            const customFieldErroritem = {}
            customFieldErroritem.name = item.name
            const isValid = this.localIsUrlValid(value)
            const urlLength = 2048
            const maxLengthUrl = value.length > urlLength
            if (!isValid) {
                customFieldErroritem.message = `The url format is invalid.`
                this.localTicketErrorMessageUpdate(customFieldErroritem)
                return
            }
            if (maxLengthUrl) {
                customFieldErroritem.message = `The url maximum of ${urlLength} characters.`
                this.localTicketErrorMessageUpdate(customFieldErroritem)
                return
            }
        },

        localIsUrlValid (value) {
            try {
                const newUrl = new URL(value);
                return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
            } catch (err) {
                return false;
            }
        },

        localGetTicketErrorMessage (name, customField = null) {
            const item = this.localTicketUpdateErrors.find(list => list.name === name)
            if (item) return item.message
            if (customField && this.$response(this.custom_field_value_response, 'value'))  return this.$response(this.custom_field_value_response, 'value')
            return null
        },

        localTicketErrorMessageUpdate (item) {
            const index = this.localTicketUpdateErrors.findIndex(list => list.id === item.name)
            if (index === -1) this.localTicketUpdateErrors.push(_.cloneDeep(item))
            else this.localTicketUpdateErrors[index].message = item.message
        },

        async localAddTicketAssignees () {
            if (this.localTicketSelectedAssignees.length) {
                const assigneesitem = {}
                assigneesitem.resource_type = 'Ticket'
                assigneesitem.resource_ids = []
                assigneesitem.resource_ids.push(this.local_ticket_item.id)
                assigneesitem.user_ids = []

                this.localTicketSelectedAssignees.forEach(item => {
                    assigneesitem.user_ids.push(item.id)
                });
                await this.assignee_bulk(assigneesitem)
                this.localTicketSelectedAssignees = []
            }
        },

        async localTicketAssigneesRemoved () {
            if (this.localTicketRemovedAssignees.length) {
                const unassigneditem = {}
                unassigneditem.resource_type = 'Ticket'
                unassigneditem.resource_ids = []
                unassigneditem.resource_ids.push(this.local_ticket_item.id)
                unassigneditem.user_ids = []

                this.localTicketRemovedAssignees.forEach(item => {
                    unassigneditem.user_ids.push(item.id)
                });

                await this.assignee_bulk_remove(unassigneditem)
                this.localTicketRemovedAssignees = []
            }
        },

        async localAddTicketTags () {
            if (this.localTicketSelectedTags.length) {
                const tagitem = {}
                tagitem.data = []
                this.localTicketSelectedTags.forEach(item => {
                    tagitem.data.push(this.$appendId({target_id : item.id }))
                });
                tagitem.source_id = this.local_ticket_item.id
                tagitem.source_type = 'Ticket'
                tagitem.target_type = 'Tag'
                tagitem.type = 'ticket_tag'
                await this.association_bulk_store(tagitem)
                this.localTicketSelectedTags = []
            }
        },

        async localTicketTagsRemoved () {
            if (this.localTicketRemovedTags.length) {
                const removedtagitem = {}
                removedtagitem.ids = []
                this.localTicketRemovedTags.forEach(item => {
                    if (item.association) {
                        removedtagitem.ids.push(item.association.id)
                    }
                });
                removedtagitem.source_id = this.local_ticket_item.id
                removedtagitem.source_type = 'Ticket'
                await this.association_bulk_destroy(removedtagitem)
                this.localTicketRemovedTags = []
            }
        },

        async localTicketlinkedProjectes () {
            if (this.localTicketLikendProjects.length) {
                const linkeditem = {}
                linkeditem.ticket_id = this.local_ticket_item.id
                linkeditem.data = []

                this.localTicketLikendProjects.forEach(item => {
                    if (item.tickets.length) {
                        item.tickets.forEach(ticket => {
                            linkeditem.data.push(ticket)
                        });
                    }
                });
                await this.ticket_bulk_clone(linkeditem)
                this.localTicketLikendProjects = []
            }
        },

        async localTicketUnlinkProjectes () {
            if (this.localUnLikendTicktes.length) {
                const unlinkeditem = {}
                unlinkeditem.ticket_id = this.local_ticket_item.id
                unlinkeditem.data = []
                this.localUnLikendTicktes.forEach(item => {
                    unlinkeditem.data.push(item.id)
                });
                await this.ticket_bulk_unlink(unlinkeditem)
                this.localUnLikendTicktes = []
            }
        },

        async localTicketCustomFieldValuesUpdate () {
            if (this.localCustomFieldValues.length) {
                const customFieldValueItem = {}
                customFieldValueItem.is_global = 1
                customFieldValueItem.resource_type = 'Ticket'
                customFieldValueItem.resource_id = this.local_ticket_item.id
                customFieldValueItem.data = []

                this.localCustomFieldValues.forEach(item => {
                    if (item.custom_field_value) {
                        const valueItem = item.custom_field_value.id ? item.custom_field_value : this.$appendId(item.custom_field_value)
                        if (item.type === 'multiline') valueItem.value = Base64.encode(valueItem.value)
                        valueItem.custom_field_id = item.id
                        valueItem.custom_field_option_id = item.type === 'dropdown' ? this.localGetCustomFieldOptionId(valueItem.value,  item.options) : null
                        valueItem.type = item.type
                        customFieldValueItem.data.push(valueItem)
                    }
                });
               await this.custom_field_value_bulk_store(customFieldValueItem)
               this.localCustomFieldValues = []
            }
        },

        localGetCustomFieldOptionId (value, options) {
            if (options.length) {
                const customFieldOption = options.find(item => item.value === value)
                return customFieldOption ? customFieldOption.id : null
            }
            return null
        },

        async localUpdateSystemFieldUpdate () {
            await this.ticket_update({
                item: this.local_ticket_item,
                id: this.local_ticket_item.id,
            })
            this.localGetFieldUpdate()
        },

        // Attachment CRUD

        localAddListeners () {
            window.addEventListener('beforeunload', this.localBeforeUnloadEvent)
            this.mixinAttachmentListeners('add')
        },

        localRemoveListeners () {
            window.removeEventListener('beforeunload', this.localBeforeUnloadEvent)
            this.mixinAttachmentListeners('remove')
        },

        localHandleDragEnter (e) {
            this.filesDraggedOver = true
        },

        localHandleLeave (e) {
            if (this.hoveredOnInfo) return
            this.filesDraggedOver = false
        },

        localHandleDrop (e) {
            if (this.localAttachmentsUpdating || this.pageLoading) return
            const droppedFiles = Array.from(e.dataTransfer.files)
            if (_.size(droppedFiles) > 5) return this.localValidateAttachmentCount()
            this.localDroppedFilesList = _.cloneDeep(droppedFiles)
            this.filesDraggedOver = false
        },

        localValidateAttachmentCount () {
            this.$notify('warning', 'Sorry, you can only upload up to 5 files at a time')
            this.filesDraggedOver = false
        },

        localRemoveAttachmentItem (id) {
            this.mixinRemoveAttachmentItem(id)
        },

        localDeleteAttachmentItem (id) {
            this.mixinDeleteAttachmentItem(id)
        },

        async localUpdateTicketAttachment () {
            this.mixinSetLoading('attachment-update')
            this.localSetTicketdescription()
            this.attachment_clear_response()
            this.mixinRemoveInvalidFiles()
            await this.localBulkDeleteAttachment()
            await this.localUploadAttachment()
            this.mixinResetLoading('attachment-update')
        },

        async localUploadAttachment () {
            if (!_.size(this.attachment_upload_list)) return
            const formData = new FormData();
            formData.append('source_id', this.$route.params.id)
            formData.append('source_type', 'Ticket')
            formData.append('order', -1)
            this.attachment_upload_list.forEach(file => {
                formData.append('files[]', file)
                formData.append('ids[]', file.id)
            })
            await this.attachment_upload(formData)

            if (!this.$status(this.attachment_response)) return this.mixinHandleAttachmentErrors()
            this.localShowTicketAttachments()
            this.attachment_clear_upload_list()
        },

        async localBulkDeleteAttachment () {
            if (!_.size(this.attachment_delete_list)) return
            this.mixinSetLoading('attachment-delete')
            await this.attachment_bulk_destroy({ids: this.attachment_delete_list})
            if (this.$status(this.attachment_delete_response)) {
                if (!_.size(this.attachment_upload_list)) this.localShowTicketAttachments()
                if (!_.size(this.attachment_local_list) && !_.size(this.attachment_upload_list)) this.localLoadTicketAttachments()
                this.attachment_clear_delete_list()
            }
            this.mixinResetLoading('attachment-delete')
        },

        async localShowTicketAttachments () {
            this.localAttachmentFilters.count = _.size(this.attachment_local_list)
            this.localAttachmentFilters.page = 1
            await this.attachment_index({ mode: 'show', params: this.localAttachmentFilters})
            this.mixinUpdateLocalAttachmentList({ list: this.attachment_list})
            this.backupAttachmentList = _.cloneDeep(this.attachment_list)
        },

        localUpdateAttachmentTransfer (params) {
            this.attachment_clear_response()
            const { list, transferFiles } = params || {}
            const totalUploadList = !!_.size(this.attachment_upload_list) ? [...transferFiles, ...this.attachment_upload_list] : [...transferFiles]
            this.mixinPreserveListChanges({ list: list, type: 'drop_transfer' })
            this.attachment_update_upload_list(totalUploadList)
        },

        async localLoadMoreAttachments () {
            if (this.attachment_meta.total === _.size(this.attachment_list)) return

            this.mixinSetLoading('attachment')

            const defaultCount = 5
            const isListShortOfItem = _.size(this.attachment_list)%5 === 0
            const loadType = isListShortOfItem ? 'load_more' : 'index'
            this.localAttachmentFilters.count = isListShortOfItem ? defaultCount : this.attachment_meta.current_page*defaultCount
            this.localAttachmentFilters[this.FILTER.page] = isListShortOfItem ? this.attachment_meta.current_page+1 : 1
            await this.attachment_index({ mode: loadType, params: this.localAttachmentFilters })

            this.mixinResetLoading('attachment')
            this.mixinPreserveListChanges({ list: this.attachment_list, type: 'load_more' })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localClearAttachmentUpdate () {
            this.mixinUpdateLocalAttachmentList({ list: this.backupAttachmentList })
            this.mixinClearAttachmentUpdate()
        },

        localLinkOpenNewTab (link) {
            window.open(link, '_blank');
        },

        localCheckUrlIcon (field) {
            return !(
                field.parent.custom_field_value.value &&
                this.localIsUrlValid(field.parent.custom_field_value.value) &&
                !this.localGetTicketErrorMessage(field.parent.name) &&
                this.localCheckTicketPermission &&
                field.parent.user_writable === 1
            )
        },

        ...mapActions('User', {
            user_index: 'index',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
        }),

        ...mapActions('Project', {
            project_index: 'index'
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_clear_item: 'clear_item'
        }),

        ...mapActions('Assignee', {
            assignee_store: 'store',
            assignee_bulk: 'bulk_assign',
            assignee_bulk_remove : 'bulk_remove',
            assignee_destroy: 'destroy',
        }),

        ...mapActions('Association', {
            association_bulk_store: 'bulk_store',
            association_bulk_destroy: 'bulk_destroy',
            association_store: 'store',
            association_destroy: 'destroy',
        }),

        ...mapActions('CustomField', {
            custom_field_index: 'index',
            custom_field_enabled: 'enabled',
            custom_field_destroy: 'destroy',
            custom_field_reorder: 'reorder',
            custom_field_clear: 'clear',
            custom_field_clear_item: 'clear_item',
        }),

        ...mapActions('CustomFieldValue', {
            custom_field_value_store: 'store',
            custom_field_value_bulk_store : 'bulk_store',
            custom_field_value_update_item: 'update_item',
            custom_field_value_update: 'update',
            custom_field_value_clear_item: 'clear_item',
        }),

        ...mapActions('Ticket', {
            ticket_update: 'update',
            ticket_link_update: 'linked_ticket_update',
            ticket_show: 'show',
            ticket_select: 'select',
            ticket_bulk_clone: 'bulk_clone',
            ticket_bulk_unlink: 'bulk_unlink',
            ticket_clone_store: 'clone_store',
            ticket_destroy: 'destroy',
            ticket_clear_item: 'clear_item',
            ticket_clear_response: 'clear_response',
        }),

        ...mapActions('Attachment', {
            attachment_index: 'index',
            attachment_upload: 'upload',
            attachment_update_upload_list: 'update_upload_list',
            attachment_remove_upload_item: 'remove_upload_list_item',
            attachment_bulk_destroy: 'bulk_destroy',
            attachment_clear_upload_list: 'clear_upload_list',
            attachment_clear_delete_list: 'clear_delete_list',
            attachment_clear_response: 'clear_response',
        }),
    }
}
</script>

<style lang="scss" scoped>
    .ProseMirror {
        outline: none !important;
    }

    .c-update-container {
        position: sticky !important;
        bottom: 0px;
        border: 1px solid #EEE;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
        width: 100%;
    }

    .c-save-btn.v-btn.v-btn--disabled {
        opacity: 0.5;
        background-color: #3949AB !important;
        color: #fff !important;
    }

    .c-meta-title {
        color: rgba(0,0,0,0.4);
    }

    .c-letter-space {
        letter-spacing: 0.8px;
    }

    .c-cursor-auto {
        cursor: auto !important;
    }

    .c-section-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .c-field-card__half--preview {
        width: 50% !important;
    }

    .c-field-card__full{
        width: 100% !important;
    }

</style>

import Vue from 'vue'
const api_endpoint = '/tickets';

export default {
    namespaced: true,
    state: {
        list: [],
        global_list: [],
        search_list: [],
        global_link_item: {},
        item: {},
        meta: {},
        filters: {
            status: 'all',
            parent_type: 'Project',
            parent_id: 'all',
            creator_id: 'all',
            type_id: 'all',
            workspace_id: null,
            page: 1,
            count: 10,
            sort_field: 'created_at',
            sort_direction: 'desc'
        },
        response: {},
        linked_item_response: {},
        defaults: {
            list: [],
            global_list: [],
            search_list: [],
            global_link_item: {},
            item: {},
            meta: {},
            filters: {
                status: 'all',
                parent_type: 'Project',
                parent_id: 'all',
                creator_id: 'all',
                type_id: 'all',
                workspace_id: null,
                page: 1,
                count: 10,
                sort_field: 'created_at',
                sort_direction: 'desc'
            },
            response: {},
            linked_item_response: {},
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_GLOBAL_TICKET_LIST(state, items) {
            state.global_list = items;
        },
        UPDATE_SEARCH_LIST(state, items) {
            state.search_list = items;
        },
        UPDATE_ITEM(state, item) {
            if (item.force) state.item = item
            else state.item = Object.assign({}, { ...state.item, ...item })
        },
        LINK_PROJECT_TO_ITEM(state, payload) {
            state.item.links_workspace.unshift(payload)
            state.item.links_count = state.item.links_count + 1
            state.item.deal_value = parseInt(state.item.deal_value) + parseInt(payload.deal_amount ?? 0)
        },
        UPDATE_LINKED_TICKET(state, item) {
            if (item.model && item.model === 'list') {
                let index = _.findIndex(state.list, { 'id': item.id });
                return state.list[index].ticket_id = item.ticket_id;
            }
            state.item.ticket_id = item.ticket_id
        },
        REMOVE_LINKED_TICKET(state, item) {
            state.item.ticket_id = null
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload.item;
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        PREPEND_ITEM(state, item) {
            state.list.unshift(item);
        },
        APPEND_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        CLEAR_ITEM(state, item) {
            state.item = item;
        },
        CLEAR_RESPONSE(state) {
            state.response = {};
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
        THROW_RESPONSE_LINKED_TICKET(state, payload) {
            state.linked_item_response = payload;
        },
        LINKED_TICKET_UPDATE(state, payload) {
            let index = _.findIndex(state.item.links, { 'id': payload.id })
            state.item.links[index] = Object.assign({}, { ...payload.data })
        },
        UPDATE_TICKET_ITEM(state, payload) {
            const ticket = payload.data
            const index = state.item.projects.findIndex(item => item.id === ticket.parent_id)
            const ticketIndex = state.item.projects[index].tickets.findIndex(item => item.id === ticket.id)
            state.item.projects[index].tickets[ticketIndex] = {...ticket}
        }
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint, { params: payload })
                .then((response) => {
                    if (payload && payload.mode === 'search') {
                        context.commit('UPDATE_SEARCH_LIST', response.data.data);
                    } else {
                        if (payload && payload.type === 'pagination') context.commit('APPEND_LIST', response.data.data);
                        else context.commit('UPDATE_LIST', response.data.data);
                        context.commit('UPDATE_META', response.data.meta);
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        global_index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/search', { params: payload })
                .then((response) => {
                    context.commit('UPDATE_GLOBAL_TICKET_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        poc_ticket_search(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/search', { params: payload })
                .then((response) => {
                    context.commit('UPDATE_SEARCH_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            payload.params = payload.params ?? { 'include': 'status,priority,type,createdBy,bridges,assignees.role,tags,linksCount,linksWorkspace.status,linksWorkspace.parent.keyCollaborators.type,linksWorkspace.parent.keyCollaborators.user,linksWorkspace.parent.customer' }
            return axios.get(api_endpoint + '/' + payload.id, { ...payload })
                .then(async (response) => {
                    if (payload && payload.mode === 'add') {
                        context.commit('PREPEND_ITEM', response.data.data)
                    } else {
                        context.commit('UPDATE_LIST_ITEM', { id: response.data.data.id, item: response.data.data });
                    }
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', code: error.response.status });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, payload)
                .then(async (response) => {
                    await context.commit('UPDATE_ITEM', payload)
                    // if (payload.mode && payload.mode === 'ticket_create') await context.commit('UPDATE_ITEM', payload)
                    // else await context.dispatch('show', { ...payload })
                    // this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: response.data.data });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        bulk_unlink(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/bulk-unlink', payload)
                .then(async (response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: response.data.data });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        bulk_clone(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/bulk-clone', payload)
                .then(async (response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: response.data.data });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        clone_store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/clone', { id: payload.id, ...{ ...payload.data ?? payload } })
                .then(async (response) => {
                    if (payload && payload.link_project) {
                        context.commit('LINK_PROJECT_TO_ITEM', payload)
                    } else await context.commit('UPDATE_ITEM', payload)
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: response.data.data });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        project_link(context, payload) {
            context.commit('LINK_PROJECT_TO_ITEM', payload)
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id, { ...payload.item })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    if (payload.params) context.dispatch('show', { ...payload })
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        visibility(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/states/visibility', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        link_global(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + payload.id + '/link', payload)
                .then((response) => {
                    context.commit('UPDATE_LINKED_TICKET', payload);
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        unlink_global(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + payload.id + '/unlink', payload)
                .then((response) => {
                    context.commit('REMOVE_LINKED_TICKET');
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.data)
            return axios.patch(api_endpoint + '/reorder', { data: payload.data })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        async linked_ticket_update (context, payload) {
            try {
                await axios.patch(api_endpoint + '/' + payload.id, { ...payload.data })
                context.commit('LINKED_TICKET_UPDATE', payload)
                context.commit('UPDATE_TICKET_ITEM', payload)
                context.commit('THROW_RESPONSE_LINKED_TICKET', { id: payload.id, text: 'Done', status: 'success' });
            }
            catch (error) {
                context.commit('THROW_RESPONSE_LINKED_TICKET', { id: payload.id, text: error.response.data.message, status: 'error'})
            }
        },
        clear_search_list(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_SEARCH_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                resolve('Cleared');
            });
        },
        clear_response(context) {
            return new Promise((resolve, reject) => {
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('THROW_RESPONSE_LINKED_TICKET', _.cloneDeep(context.state.defaults.linked_item_response));
                resolve('Cleared');
            });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('CLEAR_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('UPDATE_SEARCH_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('THROW_RESPONSE_LINKED_TICKET', _.cloneDeep(context.state.defaults.linked_item_response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('CLEAR_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('THROW_RESPONSE_LINKED_TICKET', _.cloneDeep(context.state.defaults.linked_item_response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },
}

export default {
    install(Vue) {
        const Bus = new Vue()
        Vue.prototype.$automation = () => {
            Bus.$emit('automation')
        }

        Vue.prototype.$getAutomationWarning = function () {
            const instance = this
            Bus.$on('automation', () => {
                instance.dialogAutomationErrorPopup = true
                instance.localErrorStatus = false
                instance.localErrorGroupIndex = 0
            })
        }
    }
}

<template>
    <g-dialog-box icon="comment" icon-size="24" hide-close-icon>
        <template #title>Post Project Status Update {{ projectTitle ? 'for' : '' }} {{ projectTitle | truncateText(80) }}</template>
        <template #subtitle>You can update current status of project</template>
        <template #body>
            <a-container container--fluid grid-list-xl class="pa-6 pt-8">
                <a-layout wrap align-center>
                    <a-flex xs4 pb-0>
                        <a-autocomplete
                            v-model="dataStatusUpdateItem.health"
                            :items="local_health_colors"
                            item-text="status"
                            item-value="value"
                            placeholder="Select Status"
                            background-color="neutral"
                            solo flat hide-details
                        >
                        </a-autocomplete>
                        <span class="md-caption red--text text--darken-2 d-block mt-2" style="min-height: 16px;">
                            <template v-if="response.server && response.server.errors && response.server.errors.health">
                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                {{ response.server.errors.health[0] }}
                            </template>
                        </span>
                    </a-flex>
                    <a-flex xs4 pb-0>
                        <a-autocomplete
                            v-model="dataStatusUpdateItem.type_id"
                            :items="status_type_list"
                            item-text="value"
                            item-value="id"
                            placeholder="Select Type"
                            background-color="neutral"
                            solo flat hide-details
                        >
                        </a-autocomplete>
                        <span class="md-caption red--text text--darken-2 d-block mt-2" style="min-height: 16px;">
                            <template v-if="response.server && response.server.errors && response.server.errors.type_id">
                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                {{ response.server.errors.type_id[0] }}
                            </template>
                        </span>
                    </a-flex>
                    <a-flex xs4 pb-0>
                        <a-autocomplete
                            v-model="dataStatusUpdateItem.visibility"
                            :items="[{text: 'Internal Team Only', value: 'internal'}, {text: 'Visible to Client', value: 'external'}]"
                            placeholder="Select Visibility"
                            background-color="neutral"
                            solo flat hide-details
                        ></a-autocomplete>
                        <span class="md-caption red--text text--darken-2 d-block mt-2" style="min-height: 16px;">
                            <template v-if="response.server && response.server.errors && response.server.errors.visibility">
                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                {{ response.server.errors.visibility[0] }}
                            </template>
                        </span>
                    </a-flex>
                    <a-flex xs12 pb-0>
                        <a-textarea
                            v-model="dataStatusUpdateItem.description"
                            placeholder="Enter Comment/Notes"
                            background-color="neutral"
                            solo flat hide-details multiple chips small-chips no-resize
                        ></a-textarea>
                        <span class="md-caption red--text text--darken-2 d-block mt-2" style="min-height: 16px;">
                            <template v-if="response.server && response.server.errors && response.server.errors.description">
                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                {{ response.server.errors.description[0] }}
                            </template>
                        </span>
                    </a-flex>
                </a-layout>
            </a-container>
        </template>
        <template #footer>
            <a-btn class="ma-0 elevation-0" color="primary" @click="$emit('store', dataStatusUpdateItem)" :loading="loading" :disabled="loading">Post Status Update</a-btn>
            <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="$emit('close', true)" :disabled ="loading">Cancel</a-btn>
        </template>
    </g-dialog-box>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    props: {
        item: {
            type: Object
        },
        projectTitle: {
            type: String | Number
        },
        response: {
            type: String | Object | Array
        },
        loading: {
            type: Boolean
        },
    },

    data () {
        return {
            status_type_list: [],
            local_health_colors: [{ status: 'Green', value: 'green' }, { status: 'Yellow', value: 'yellow' }, { status: 'Red', value: 'red' }],
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        dataStatusUpdateItem () {
            return this.item
        },

        ...mapState('Meta', {
            meta_list: 'list',
        }),
    },

    methods: {
        async local_index () {
            await this.meta_index({
                'filter[type]': 'status_update_type',
                'fields[metas]': 'id,value,status,type',
                'sort': 'order'
            })
            this.status_type_list = _.cloneDeep(this.meta_list)
        },

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),
    }
}
</script>

<template>
    <div class="c-heading u-relative u-flex align-center" style="min-height: 40px">
        <!-- Block List Menu -->
        <block-list-menu v-if="$can('document_generator.update')" @addBlock="methodStoreReportBlock"></block-list-menu>

        <!-- Formatting Menu -->
        <div class="u-absolute" style="top: 50%; transform: translateY(-50%); left: -40px">
            <a-menu offset-x left :nudge-top="50" v-model="dataSideMenu" transition="slide-x-transition" :close-on-content-click="false" min-width="350" max-width="350" style="max-width: 24px !important;" content-class="u-border c-radius u-elevation-custom-1">
                <template v-slot:activator="{ on }">
                    <a v-on="on" v-ripple="{ class: 'info--text' }" style="padding: 0 4px 0 4px;" class="c-component__menu u-flex align-center u-rounded-corners list-group-item js-drag-handle" :class="[ dataSideMenu ? 'is-active' : '' ]"><a-icon class="c-component__drag" size="26">drag_indicator</a-icon></a>
                </template>
                <div class="c-radius u-elevation-custom-1 py-3 white" style="max-width: 350px;">
                    <div class="px-4">
                        <div class="u-flex align-center">
                            <h2 class="grey--text text--darken-3 font-weight-bold title" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.title }}</h2>
                            <a-spacer></a-spacer>
                            <a-btn small icon text color="red darken-1 ma-0" class="ma-0" v-if="$can('document_generator.destroy')" @click="methodRemoveComponent">
                                <svg width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-trash red--text text--darken-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>
                            </a-btn>
                        </div>
                        <p class="grey--text text--darken-1 mb-0 body-2 font-weight-medium" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.description }}</p>
                        <a-divider class="grey lighten-3 mt-3 mb-2" v-if="$can('document_generator.update')"></a-divider>
                    </div>
                    <template v-if="$can('document_generator.update')">
                        <div class="px-4 mt-4 mb-3">
                            <h2 class="title grey--text text--darken-2 title font-weight-medium">Use Case</h2>
                        </div>
                        <div class="c-settings-report-block mb-4 u-flex align-center">
                            <div class="pl-4 mr-4 u-wfull" v-if="dataUsecaseVisibilityDefault">
                                <div class="grey--text text--darken-1 text-uppercase caption font-weight-bold mb-2" style="letter-spacing: 1px;">{{ dataUsecaseVisibilityDefault.title }}</div>
                                <a-menu class="d-block" offset-y bottom transition="slide-y-transition" content-class="c-radius u-border u-elevation-custom-1">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="pa-2 u-cursor-pointer c-radius grey lighten-3 u-flex align-center u-wfull">
                                            <span class="u-flex align-center u-rounded-corners">
                                                {{ methodBlockAttrs(clonedUsecaseDetail.id, dataUsecaseVisibilityDefault.id, 'visibility').value.label }}
                                            </span>
                                            <a-spacer></a-spacer>
                                            <a-icon color="grey darken-2" size="24">arrow_drop_down</a-icon>
                                        </div>
                                    </template>
                                    <a-list class="pa-0 py-3 u-list-condensed">
                                        <a-list-item v-for="(menu, index) in dataUsecaseVisibilityDefault.options" :key="menu.level" class="u-wfull px-4" :class="[dataUsecaseVisibilityDefault.options.length - 1 !== index ? 'mb-2' : '' ]" @click="methodUpdateVisibility(menu, dataUsecaseVisibilityDefault.id)">
                                            <a-list-item-content>
                                                <a-list-item-title class="grey--text text--darken-2">{{ menu.label }}</a-list-item-title>
                                            </a-list-item-content>
                                        </a-list-item>
                                    </a-list>
                                </a-menu>
                            </div>
                            <!-- <div class="pr-4 u-wfull" v-if="dataUsecaseStatusDefault">
                                <div class="grey--text text--darken-1 text-uppercase caption font-weight-bold mb-2" style="letter-spacing: 1px;">{{ dataUsecaseStatusDefault.title }}</div>
                                <a-menu class="d-block" offset-y bottom transition="slide-y-transition" content-class="c-radius u-border u-elevation-custom-1">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="pa-2 u-cursor-pointer c-radius grey lighten-3 u-flex align-center u-wfull">
                                            <span class="u-flex align-center u-rounded-corners">
                                                {{ methodBlockAttrs(clonedUsecaseDetail.id, dataUsecaseStatusDefault.id, 'status').value.label }}
                                            </span>
                                            <a-spacer></a-spacer>
                                            <a-icon color="grey darken-2" size="24">arrow_drop_down</a-icon>
                                        </div>
                                    </template>
                                    <a-list class="pa-0 py-3 u-list-condensed">
                                        <a-list-item v-for="(menu, index) in dataUsecaseStatusDefault.options" :key="menu.level" class="u-wfull px-4" :class="[dataUsecaseStatusDefault.options.length - 1 !== index ? 'mb-2' : '' ]" @click="methodUpdateStatus(menu, dataUsecaseStatusDefault.id)">
                                            <a-list-item-content>
                                                <a-list-item-title class="grey--text text--darken-2">{{ menu.label }}</a-list-item-title>
                                            </a-list-item-content>
                                        </a-list-item>
                                    </a-list>
                                </a-menu>
                            </div> -->
                        </div>
                        <div class="c-settings-report-block px-4" v-if="dataUsecaseTitleLevelDefault && dataUsecaseTitleLevelDefault.options">
                            <div class="grey--text text--darken-1 text-uppercase caption font-weight-bold mb-2" style="letter-spacing: 1px;">{{ dataUsecaseTitleLevelDefault.title }}</div>
                            <a-menu class="d-block" offset-y bottom transition="slide-y-transition" content-class="c-radius u-border u-elevation-custom-1">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" class="pa-2 c-radius u-cursor-pointer grey lighten-3 u-flex align-center u-wfull">
                                        <span class="u-flex align-center u-rounded-corners" v-if="currentTitleLevel">
                                            <span class="text-uppercase font-weight-bold grey--text text--darken-3 mr-2 body-1 u-rounded-corners grey lighten-2 pa-1 px-2">{{ currentTitleLevel.value }}</span>
                                            <a-spacer></a-spacer>
                                            {{ currentTitleLevel.label }}
                                        </span>
                                        <a-spacer></a-spacer>
                                        <a-icon color="grey darken-2" size="24">arrow_drop_down</a-icon>
                                    </div>
                                </template>
                                <a-list class="pa-0 py-3">
                                    <a-list-item v-for="(menu, index) in dataUsecaseTitleLevelDefault.options" :key="menu.level" class="u-wfull px-4" :class="[dataUsecaseTitleLevelDefault.options.length - 1 !== index ? 'mb-2' : '' ]" @click="methodFormatText(menu, dataUsecaseTitleLevelDefault.id, methodBlockAttrs(clonedUsecaseDetail.id, dataUsecaseTitleLevelDefault.id).id)">
                                        <a-list-item-action class="c-heading-levels-menu ma-0 mr-3 py-1 u-flex align-center justify-center grey lighten-3 c-radius" style="min-width: 50px !important; min-height: 38px !important;">
                                            <component :is="menu.value" class="grey--text text--darken-3 text-uppercase">{{ menu.value }}</component>
                                        </a-list-item-action>
                                        <a-list-item-content>
                                            <a-list-item-title class="grey--text text--darken-2">{{ menu.label }}</a-list-item-title>
                                        </a-list-item-content>
                                    </a-list-item>
                                </a-list>
                            </a-menu>
                            <a-divider class="grey lighten-3 mt-6 mb-3"></a-divider>
                        </div>
                        <div class="px-4">
                            <div class="u-flex align-center">
                                <a-btn icon small class="ma-0 mr-1" @click="methodToggleIncludes({ label: 'Test Case', value: 'testcase' })">
                                    <svg v-if="methodHasInSelectedIncludes('testcase')" width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-check-circle-fill blue--text text--darken-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                    </svg>
                                    <svg v-else width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-circle grey--text text--darken-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    </svg>
                                </a-btn>
                                <div class="u-flex align-center u-wfull u-cursor-pointer" @click="methodToggleAccordion('testcase')">
                                    <h2 class="title grey--text text--darken-3 font-weight-medium">Test Case</h2>
                                    <a-spacer></a-spacer>
                                    <a-btn icon small class="ma-0">
                                        <a-icon size="16" color="grey darken-1">{{ dataAccordionOpen === 'testcase' ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</a-icon>
                                    </a-btn>
                                </div>
                            </div>
                            <a-divider class="mx-2 grey lighten-3 my-3"></a-divider>
                            <testcase-block v-if="dataAccordionOpen === 'testcase'" :index="index" :clonedUsecaseDetail="clonedUsecaseDetail"></testcase-block>
                        </div>
                        <div class="px-4">
                            <div class="u-flex align-center">
                                <a-btn icon small class="ma-0 mr-1" @click="methodToggleIncludes({ label: 'Success Criteria', value: 'success-criteria' })">
                                    <svg v-if="methodHasInSelectedIncludes('success-criteria')" width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-check-circle-fill blue--text text--darken-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                    </svg>
                                    <svg v-else width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-circle grey--text text--darken-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    </svg>
                                </a-btn>
                                <div class="u-flex align-center u-wfull u-cursor-pointer" @click="methodToggleAccordion('success-criteria')">
                                    <h2 class="title grey--text text--darken-3 font-weight-medium">Success Criteria</h2>
                                    <a-spacer></a-spacer>
                                    <a-btn icon small class="ma-0">
                                        <a-icon size="16" color="grey darken-1">{{ dataAccordionOpen === 'success-criteria' ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</a-icon>
                                    </a-btn>
                                </div>
                            </div>
                            <a-divider class="mx-2 grey lighten-3 my-3"></a-divider>
                            <success-criteria-block v-if="dataAccordionOpen === 'success-criteria'" :index="index" :clonedUsecaseDetail="clonedUsecaseDetail"></success-criteria-block>
                        </div>
                    </template>
                </div>
            </a-menu>
        </div>

        <!-- Block -->
        <div class="c-radius my-3 u-wfull c-xspacing" style="border: 2px dashed #e8e8e8 !important">
            <a-sheet class="u-wfull grey lighten-5 pa-4 c-radius">
                <div class="text-center">
                    <a-icon size="32" color="grey darken-2">backup_table</a-icon>
                    <div class="mt-1 text-center">
                        <h2 class="title grey--text text--darken-3">{{ clonedUsecaseDetail.report_block.title }}</h2>
                        <h3 class="ml-2 mt-3 grey--text text--darken-1 d-inline-block ml-auto" style="width: 550px; font-size: 16px; font-weight: 400 !important">
                            Visibility: <span class="font-weight-medium" style="color: #737373 !important">{{ selectedVisibility.label }}</span> &bull; Includes:
                            <template v-if="selectedIncludes.length > 1">
                                <span class="font-weight-medium" style="color: #737373 !important">Test Case & Success Criteria</span>.
                            </template>
                            <template v-else>
                                <span class="font-weight-medium" style="color: #737373 !important">{{ selectedIncludes[0] ? selectedIncludes[0].label : 'None' }}</span>
                            </template>
                        </h3>
                        <!-- <h3 class="ml-2 mt-2 grey--text text--darken-1 body-1 d-inline-block ml-auto" style="width: 550px;">
                            <span>
                                <strong>Testcase</strong> with options status as <strong>{{ selectedStatus.label }}</strong>, title level as <strong>{{ currentTitleLevel.label }}</strong>
                                <template v-if="selectedIncludes.length">
                                    and includes
                                    <template v-if="selectedIncludes.length > 1">
                                        both <strong>Testcase</strong> and <strong>Success Criteria</strong>.
                                    </template>
                                    <template v-else>
                                        only <strong>{{ selectedIncludes[0].label }}</strong>
                                    </template>
                                </template>
                            </span>
                        </h3> -->
                    </div>
                </div>
            </a-sheet>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import EventBus from '@/config/config-eventbus'
import BlockListMenu from '../BlockListMenu.vue'
import TestcaseBlock from './testcase.vue'
import SuccessCriteriaBlock from './success_criteria.vue'

export default {
    components: { BlockListMenu, TestcaseBlock, SuccessCriteriaBlock },

    props: ['item', 'index', 'list'],

    data () {
        return {
            clonedUsecaseDetail: this.item,
            currentOption: '',
            currentAlignment: '',
            currentMenuValue: '',
            selectedColumns: [],
            selectedVisibility: { label: 'All', value: 'all' },
            selectedStatus: { label: 'All', value: 'all' },
            currentTitleLevel: { label: 'Heading 3', value: 'h3' },
            selectedIncludes: [{ label: 'Test Case', value: 'testcase' }, { label: 'Success Criteria', value: 'success-criteria' }],
            dataSideMenu: false,
            dataAccordionOpen: null,
            currentFormatting: null,
            format: {
                center: false,
                right: false,
                left: false,
            }
        }
    },

    mounted () {
        this.methodIndex()
    },

    computed: {
        dataUsecaseVisibilityDefault () {
            const info = this.methodDefaultAttrByTitle('Visibility')
            const attr = this.getBlockAttrs(this.clonedUsecaseDetail.id, info.id)
            this.selectedVisibility = attr && attr.value ? attr.value : ''
            return info
        },

        // dataUsecaseStatusDefault () {
        //     const info = this.methodDefaultAttrByTitle('Status')
        //     const attr = this.getBlockAttrs(this.clonedUsecaseDetail.id, info.id)
        //     this.selectedStatus = attr && attr.value ? attr.value : ''
        //     return info
        // },

        dataUsecaseTitleLevelDefault () {
            const info = this.methodDefaultAttrByTitle('Title Level')
            const attr = this.getBlockAttrs(this.clonedUsecaseDetail.id, info.id)
            this.currentTitleLevel = attr && attr.value ? attr.value : ''
            return info
        },

        dataUsecaseIncludesDefault () {
            const info = this.methodDefaultAttrByTitle('Include/Exclude Use Case Test Case & Success Criteria')
            const attr = this.getBlockAttrs(this.clonedUsecaseDetail.id, info.id)
            this.selectedIncludes = attr && attr.value ? attr.value : ''
            return info
        },

        dataGetBlockInfo () {
            return this.getBlockContent(this.clonedUsecaseDetail.report_block_id)
        },

        ...mapGetters('ReportBlock', [
            'getDefaultBlockAttr',
            'getBlockContent'
        ]),

        ...mapGetters('ReportTemplateBlock', [
            'getBlockAttrs'
        ]),

        ...mapGetters('ReportTemplate', [
            'getBlock',
        ]),
    },

    methods: {
        async methodIndex () {
            this.dataUsecaseIncludesDefault
            this.dataUsecaseTitleLevelDefault
            this.dataUsecaseVisibilityDefault
            // this.dataUsecaseStatusDefault
        },

        methodUpdateVisibility (menu, defaultAttrId) {
            this.methodSetCurrentOption(defaultAttrId)
            this.selectedVisibility = menu
            this.currentMenuValue = menu
            this.methodUpdateBlockAttr()
        },

        methodUpdateStatus (menu, defaultAttrId) {
            this.methodSetCurrentOption(defaultAttrId)
            this.selectedStatus = menu
            this.currentMenuValue = menu
            this.methodUpdateBlockAttr()
        },

        methodFormatText (menu, defaultAttrId) {
            this.methodSetCurrentOption(defaultAttrId)
            this.currentTitleLevel = menu
            this.currentMenuValue = menu
            this.methodUpdateBlockAttr()
        },

        methodToggleIncludes (menu) {
            const index = this.selectedIncludes.findIndex(item => item.value === menu.value)
            this.methodSetCurrentOption(this.dataUsecaseIncludesDefault.id)

            if (index !== -1) this.selectedIncludes.splice(index, 1)
            else this.selectedIncludes.push(menu)

            this.currentMenuValue = this.selectedIncludes
            this.methodUpdateBlockAttr()
        },

        methodHasInSelectedIncludes (value) {
            const index = this.selectedIncludes.findIndex(item => item.value === value)
            return index !== -1
        },

        methodUpdateColumns (menu, defaultAttrId) {
            const index = this.selectedColumns.findIndex(item => item.value === menu.value)
            this.methodSetCurrentOption(defaultAttrId)

            if (index !== -1) this.selectedColumns.splice(index, 1)
            else this.selectedColumns.push(menu)

            this.methodUpdateBlockAttr()
        },

        methodSetCurrentOption (defaultAttrId) {
            this.currentOption = {
                defaultAttrId: defaultAttrId,
                menuAttrId: this.methodBlockAttrs(this.clonedUsecaseDetail.id, defaultAttrId).id
            }
        },

        methodToggleAccordion (type) {
            if (this.dataAccordionOpen === type) return this.dataAccordionOpen = null
            this.dataAccordionOpen = type
        },

        methodBlockAttrs (blk_id, defaultAttrId, type = null) {
            const attr = this.getBlockAttrs(blk_id, defaultAttrId)
            if (attr) {
                if (type === 'visibility' || type === 'status') {
                    type === 'visibility' ? this.selectedVisibility = attr.value : this.selectedStatus = attr.value
                }
                if (type === 'Title Level') this.currentHeadingLevel = attr
            }
            return attr
        },

        methodBlockHasField (value) {
            const index = this.selectedColumns.findIndex(item => item.value === value)
            return index !== -1
        },

        async methodUpdateBlockAttr () {
            const params = {
                rt_block_id: this.clonedUsecaseDetail.id,
                rb_attribute_id: this.currentOption.defaultAttrId,
                value: JSON.stringify(this.currentMenuValue)
            }

            await this.actionTemplateBlockAttrEdit({ id: this.currentOption.menuAttrId ? this.currentOption.menuAttrId : null, ...params })
            EventBus.$emit('editBlock', this.clonedUsecaseDetail)
            this.actionTemplateBlockShow({ id: this.clonedUsecaseDetail.id, index: this.index })
        },

        methodDefaultAttrByTitle (title) {
            return this.dataGetBlockInfo.attributes.find(item => item.title === title)
        },

        async methodStoreReportBlock (component) {
            await this.actionTemplateBlockStore({
                index: this.index + 1,
                data: { report_template_id: this.$route.params.template_id, report_block_id: component.id },
                params: { include: 'reportBlock' }
            })
            this.actionTemplateBlockReorder({ list: this.list })
            // EventBus.$emit('reorderBlock', { index: this.index + 1 })
            // EventBus.$emit('storeBlock', { index: this.index + 1, component })
        },

        methodRemoveComponent() {
            this.actionFindAndRemoveBlock(this.clonedUsecaseDetail)
            this.actionTemplateBlockDelete({ id: this.clonedUsecaseDetail.id })
            // EventBus.$emit('removeComponent', this.clonedUsecaseDetail)
        },

        ...mapActions('ReportTemplateBlock', [
            'actionTemplateBlockStore',
            'actionTemplateBlockEdit',
            'actionTemplateBlockReorder',
            'actionTemplateBlockAttrsStore',
            'actionTemplateBlockAttrEdit',
            'actionTemplateBlockShow',
            'actionFindAndRemoveBlock',
            'actionTemplateBlockDelete'
        ]),
    }
}
</script>

<style lang="scss" scoped>
    .c-heading-levels-menu {
        h1 { font-size: 30px !important; }
        h2 { font-size: 26px !important; }
        h3 { font-size: 22px !important; }
        h4 { font-size: 18px !important; }
        h5 { font-size: 14px !important; }
        h6 { font-size: 10px !important; }
    }
</style>

const api_endpoint = '/widgets'

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        response: [],
        defaults: {
            list: [],
            item: {},
            meta: {},
            response: []
        }
    },

    mutations: {
        UPDATE_LIST (state, list) {
            state.list = list
        },
        UPDATE_LIST_ITEM (state, payload) {
            const index = state.list.findIndex(item => item.id === payload.id)
            state.list[index] = payload
        },
        APPEND_LIST_ITEM (state, payload) {
            state.list.push(payload)
        },
        PREPEND_LIST_ITEM (state, payload) {
            state.list.unshift(payload)
        },
        UPDATE_ITEM (state, item) {
            state.item = item
        },
        UPDATE_META (state, meta) {
            state.meta = meta
        },
        THROW_RESPONSE (state, response) {
            state.response = response
        },
    },

    actions: {
        async index ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                const { data: { data }, meta } = await sys_axios.get(api_endpoint, { params: payload })
                commit('UPDATE_LIST', data)
                commit('UPDATE_META', meta)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async show ({ dispatch }, payload) {
            dispatch('loader_start')
            try {
                const { data: { data } } = await sys_axios.get(`${api_endpoint}/${payload.id}`, { params: payload.params })
                dispatch('show_ops', { payload, data })
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async select ({ commit, state }, payload) {
            const item = state.list.find(item => item.id === payload.id)
            commit('UPDATE_ITEM', { ...item })
        },

        async show_ops ({ commit }, { payload, data }) {
            if (payload.mode === 'show-update') commit('UPDATE_LIST_ITEM', data)
            if (payload.mode === 'show-add-end') commit('APPEND_LIST_ITEM', data)
            if (payload.mode === 'show-add-start') commit('PREPEND_LIST_ITEM', data)
            if (payload.mode === 'show') commit('UPDATE_ITEM', data)
            if (payload.mode === 'show-all-add-start') {
                commit('PREPEND_LIST_ITEM', data ?? payload)
                commit('UPDATE_ITEM', data ?? payload)
            }
            if (payload.mode === 'show-all-add-end') {
                commit('APPEND_LIST_ITEM', data ?? payload)
                commit('UPDATE_ITEM', data ?? payload)
            }
        },

        async throw_success ({ commit }) {
            commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            this.dispatch('Interface/loader_stop')
        },

        async throw_error ({ commit }, error) {
            this.dispatch('Interface/loader_stop')
            commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
        },

        async loader_start () {
            this.dispatch('Interface/loader_start')
        },

        async clear ({ commit }) {
            commit('UPDATE_LIST', [])
            commit('THROW_RESPONSE', [])
        },

        async clear_item ({ commit }) {
            commit('UPDATE_ITEM', {})
            commit('THROW_RESPONSE', [])
        },

        async clear_response ({ commit }) {
            commit('THROW_RESPONSE', [])
        },
    },

    getters: {
        list: state => state.list,
        item: state => state.item,
        response: state => state.response,
    }
}

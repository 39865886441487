<template>
    <a-card>
        <div class="u-flex-center-y pa-6">
            <h2 class="md-heading-6 secondary--text">Keyboard Shortcuts</h2>
            <a-spacer></a-spacer>
            <a-btn @click="$emit('close')" icon small width="24" height="24" class="u-rounded-corners-lg">
                <a-icon size="20" color="grey darken-1">close</a-icon>
            </a-btn>
        </div>
        <a-divider></a-divider>
        <a-card-text class="pa-0 ma-0 c-tiny-scroll" style="max-height: 600px;">
            <div>
                <template v-for="(cmd, index) in localCommands">
                    <a-divider v-if="index !== 0" :key="'index-' + index"></a-divider>
                    <div class="px-6 py-4 u-flex-center-y" :key="'command-' + index">
                        <h4 class="blue-grey--text text--darken-2 md-subtitle-1">{{ cmd.label }}</h4>
                        <a-spacer></a-spacer>
                        <div class="u-flex" style="column-gap: 4px;">
                            <template v-if="cmd.command_1">
                                <template v-if="cmd.command_1.type === 'icon'">
                                    <a-sheet width="24" height="24" class="blue-grey lighten-5 px-1 u-flex-center u-rounded-corners">
                                        <a-icon size="20" color="blue-grey darken-1" outlined>{{ cmd.command_1.value }}</a-icon>
                                    </a-sheet>
                                </template>
                                <template v-if="cmd.command_1.type === 'text'">
                                    <a-sheet min-width="24" height="24" class="blue-grey lighten-5 px-1 blue-grey--text text--darken-1 md-body-2 u-rounded-corners text-capitalize font-weight-medium u-flex-center">
                                        {{ cmd.command_1.value }}
                                    </a-sheet>
                                </template>
                            </template>
                            <template v-if="cmd.command_2">
                                <template v-if="cmd.command_2.type === 'icon'">
                                    <a-sheet width="24" height="24" class="blue-grey lighten-5 px-1 u-flex-center u-rounded-corners">
                                        <a-icon size="20" color="blue-grey darken-1" outlined>{{ cmd.command_2.value }}</a-icon>
                                    </a-sheet>
                                </template>
                                <template v-if="cmd.command_2.type === 'text'">
                                    <a-sheet min-width="24" height="24" class="blue-grey lighten-5 px-1 blue-grey--text text--darken-1 md-body-2 u-rounded-corners text-capitalize font-weight-medium u-flex-center">
                                        {{ cmd.command_2.value }}
                                    </a-sheet>
                                </template>
                            </template>
                            <template v-if="cmd.command_3">
                                <template v-if="cmd.command_3.type === 'icon'">
                                    <a-sheet width="24" height="24" class="blue-grey lighten-5 u-flex-center px-1 u-rounded-corners">
                                        <a-icon size="20" color="blue-grey darken-1" outlined>{{ cmd.command_3.value }}</a-icon>
                                    </a-sheet>
                                </template>
                                <template v-if="cmd.command_3.type === 'text'">
                                    <a-sheet min-width="24" height="24" class="blue-grey lighten-5 blue-grey--text text--darken-1 u-rounded-corners md-body-2 text-capitalize font-weight-medium u-flex-center px-1">
                                        {{ cmd.command_3.value }}
                                    </a-sheet>
                                </template>
                            </template>
                            <template v-if="cmd.command_4">
                                <template v-if="cmd.command_4.type === 'icon'">
                                    <a-sheet width="24" height="24" class="blue-grey lighten-5 u-flex-center px-1 u-rounded-corners">
                                        <a-icon size="20" color="blue-grey darken-1" outlined>{{ cmd.command_4.value }}</a-icon>
                                    </a-sheet>
                                </template>
                                <template v-if="cmd.command_4.type === 'text'">
                                    <a-sheet min-width="24" height="24" class="blue-grey lighten-5 blue-grey--text text--darken-1 md-body-2 text-capitalize font-weight-medium u-flex-center px-1 u-rounded-corners">
                                        {{ cmd.command_4.value }}
                                    </a-sheet>
                                </template>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </a-card-text>
    </a-card>
</template>

<script>
import { isUserOs } from '@/helpers/helper-detect-user-os'

export default {
    computed: {
        localOsIsMac () {
            return isUserOs() === 'mac'
        },

        localCommands () {
            return [
                { label: 'Switch to Tasks view', command_1: null, command_2: { type: 'text', value: 'T', outlined: false } },
                { label: 'Switch to Milestones view', command_1: null, command_2: { type: 'text', value: 'M', outlined: false } },
                { label: 'Show only Completed tasks in tasks view', command_1: null, command_2: { type: 'text', value: 'C', outlined: false } },
                { label: 'Show only Incomplete tasks in tasks view', command_1: null, command_2: { type: 'text', value: 'I', outlined: false } },
                { label: 'Show all of the Tasks in tasks view', command_1: null, command_2: { type: 'text', value: 'A', outlined: false } },
                { label: 'Open detail view, when a task row is focused', command_1: null, command_2: { type: 'text', value: 'O', outlined: false } },
                { label: 'Go to next Task from tasks detail view', command_1: null, command_2: { type: 'text', value: 'K', outlined: false } },
                { label: 'Go to previous Task from tasks detail view', command_1: null, command_2: { type: 'text', value: 'J', outlined: false } },
                { label: 'Exit out from any selection', command_1: { type: 'text', value: 'escape', outlined: false }, command_2: null },
                { label: 'Navigate between cells in Tasks/Milestones', command_1: { type: 'text', value: 'Arrow Keys', outlined: false }, command_2: null },
                { label: 'Edit the selected cell', command_1: { type: 'text', value: this.localOsIsMac ? 'Return' : 'Enter', outlined: false }, command_2: null },
                { label: 'Select all the Tasks/Milestones', command_1: { type: this.localOsIsMac ? 'icon' : 'text', value: this.localOsIsMac ? 'keyboard_command_key' : 'Ctrl', outlined: false } , command_2: { type: 'text', value: 'A', outlined: false } },
                { label: 'Expand/Collapse all the Tasks', command_1: { type: this.localOsIsMac ? 'icon' : 'text', value: this.localOsIsMac ? 'keyboard_command_key' : 'Ctrl', outlined: false }, command_2: { type: 'text', value: 'E', outlined: false } },
                { label: 'Select tasks individually for bulk update', command_1: { type: this.localOsIsMac ? 'icon' : 'text', value: this.localOsIsMac ? 'keyboard_command_key' : 'Ctrl', outlined: false }, command_2: { type: 'text', value: 'Click', outlined: false } },
                {
                    label: 'Hold and drag for bulk selection',
                    command_1: { type: this.localOsIsMac ? 'icon' : 'text', value: this.localOsIsMac ? 'keyboard_command_key' : 'Ctrl', outlined: false },
                    command_2: { type: 'text', value: 'shift', outlined: false },
                },
                {
                    label: 'Delete Tasks/Milestones during bulk selection',
                    command_1: { type: this.localOsIsMac ? 'icon' : 'text', value: this.localOsIsMac ? 'keyboard_command_key' : 'Ctrl', outlined: false },
                    command_2: { type: this.localOsIsMac ? 'text' : 'icon', value: this.localOsIsMac ? 'delete' : 'backspace', outlined: !this.localOsIsMac },
                    command_4: { type: this.localOsIsMac ? 'text' : 'icon', value: this.localOsIsMac ? 'delete' : 'backspace', outlined: !this.localOsIsMac }
                },
                { label: 'Select all the tasks upto a range during bulk selection', command_1: { type: 'text', value: 'Shift', outlined: false }, command_2: { type: 'text', value: 'Click', outlined: false } },
                { label: 'Select tasks below during bulk selection', command_1: { type: 'text', value: 'shift', outlined: false }, command_2: { type: 'icon', value: 'arrow_downward', outlined: false } },
                { label: 'Select tasks above during bulk selection', command_1: { type: 'text', value: 'shift', outlined: false }, command_2: { type: 'icon', value: 'arrow_upward', outlined: false } },
            ]
        }
    }
}
</script>

<template>
    <div class="white">
        <div class="">
            <div class="px-7 py-6">
                <div class="md-heading-6 mb-2">Progress Bar Configuration</div>
                <div class="grey--text text--darken-1">Enter the percentile range for the designated colors</div>
            </div>
            <a-divider></a-divider>
            <div class="px-7 py-10">
                <div class="u-flex u-border u-rounded-corners" style="height: 20px">
                    <div class="red darken-1 c-left-div" :style="[ { width: (this.error_response && !this.error_response.range_1) ? `${local_get_color_width('range_1')}%` : '30%' } ]"></div>
                    <div class="amber darken-1" :style="[ this.error_response && !this.error_response.range_2 ? {width: `${local_get_color_width('range_2')}%`} : 'width: 60%' ]"></div>
                    <div class="green darken-1 c-right-div" :style="[ this.error_response && !this.error_response.range_2 && !this.error_response.range_1 ? {width: `${local_get_color_width('field3')}%`} : 'width: 100%' ]"></div>
                </div>
                <div class="mb-10">
                    <div class="u-flex justify-space-between">
                        <div v-for="(item, numberIndex) in 11" :key="item" class="u-flex flex-column u-relative align-center grey--text text--darken-1 md-caption">
                            <div class="grey lighten-2 my-1" style="width: 2px; height: 12px"></div>
                            <div class="u-absolute" style="top: 20px">
                                <div v-if="numberIndex === 0">0</div>
                                <div :class="[{ 'mr-4': item === 11 }]" v-else>{{ (item*10)-10 }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="u-flex justify-space-around">
                    <div class="u-flex justify-space-between align-center mr-4">
                        <div class="c-color-indicator red darken-1"></div>
                        <div class="mx-3">Upto</div>
                        <a-tooltip bottom :disabled="!(error_response && error_response.range_1)">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-on="on"
                                    v-model="range_1.percentage"
                                    :class="[(error_response && error_response.range_1) ? 'c-wiggle-short u-border-error': 'u-border']"
                                    style="width: 80px"
                                    @keydown="local_prevent_non_numerics"
                                    @input="local_validate_input()"
                                    dense autofocus
                                    solo flat hide-details
                                >
                                    <template v-slot:append>
                                        <a-icon size="18" color="grey darken-1">percent</a-icon>
                                    </template>
                                </v-text-field>
                            </template>
                            <span v-if="!!(error_response && error_response.range_1)">{{ error_response.range_1 }}</span>
                        </a-tooltip>
                    </div>
                    <div class="u-flex justify-space-between align-center mr-4">
                        <div class="c-color-indicator amber darken-1"></div>
                        <div class="mx-3">Upto</div>
                        <a-tooltip bottom :disabled="!(error_response && error_response.range_2)">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-on="on"
                                    v-model="range_2.percentage"
                                    :class="[(error_response && error_response.range_2) ? 'c-wiggle-short u-border-error': 'u-border']"
                                    style="width: 80px"
                                    @keydown="local_prevent_non_numerics"
                                    @input="local_validate_input()"
                                    dense
                                    solo flat hide-details
                                >
                                    <template v-slot:append>
                                        <a-icon size="18" color="grey darken-1">percent</a-icon>
                                    </template>
                                </v-text-field>
                            </template>
                            <span v-if="!!(error_response && error_response.range_2)">{{ error_response.range_2 }}</span>
                        </a-tooltip>
                    </div>
                    <div class="u-flex justify-space-between align-center mr-4">
                        <div class="c-color-indicator green darken-1"></div>
                        <div class="mx-3">Upto</div>
                        <v-text-field
                            value="100"
                            class="u-border"
                            style="width: 80px"
                            dense
                            disabled
                            solo flat hide-details
                        >
                            <template v-slot:append>
                                <a-icon size="18" color="grey darken-1">percent</a-icon>
                            </template>
                        </v-text-field>
                    </div>
                </div>
            </div>
        </div>
        <a-divider></a-divider>
        <div class="px-7 py-6">
            <a-btn height="32" color="indigo darken-1" class="white--text c-save-btn" :disabled="!unsaved_changes || local_has_errors()" :loading="loading" @click="local_config_update()" depressed>save</a-btn>
            <a-btn height="32" class="grey--text ml-4" @click="$emit('close')" depressed>cancel</a-btn>
        </div>
    </div>
</template>

<script>
import { number } from '@/helpers/helper-number-filter'

export default {
    props: {
        configData: {
            type: Array,
            required: true
        },

        isOpen: {
            type: Boolean,
            required: true
        },

        loading: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            range_1: null,
            range_2: null,
            error_response: {},
            validateTimer: null,
            unsaved_changes: false,
            backup_data: {}
        }
    },

    watch: {
        isOpen: {
            immediate: true,
            handler (newVal, oldVal) {
                if (newVal) this.local_index()
            }
        }
    },

    methods: {
        local_index () {
            this.unsaved_changes = false
            this.error_response = {}
            this.local_set_config_data()
            this.$emit('config-status-change', !this.unsaved_changes && !this.local_has_errors() ? 'no-change' : 'in-progress')
        },

        local_set_config_data () {
            this.configData.forEach(item => {
                if (item.color === 'red-dark') this.range_1 = _.cloneDeep(item)
                if (item.color === 'yellow-dark') this.range_2 = _.cloneDeep(item)
            })
            this.backup_data = _.cloneDeep({ min_range: this.range_1, max_range: this.range_2 })
        },

        local_prevent_non_numerics (e) {
            if (!number(e)) e.preventDefault()
        },

        local_validate_input () {
            clearTimeout(this.validateTimer)
            const min_value = Number(this.range_1.percentage)
            const max_value = Number(this.range_2.percentage)
            this.validateTimer = setTimeout(() => {
                this.error_response = {}
                this.error_response.range_1 = !this.range_1.percentage && this.range_1.percentage !== 0 ? 'Required field' : min_value > 99 ? 'Range limit should be less than 100' : (min_value >= max_value) ? 'Range limit should be lesser than middle range' : ''
                this.error_response.range_2 = !this.range_2.percentage && this.range_2.percentage !== 0 ? 'Required field' : max_value > 99 ? 'Range limit should be less than 100' : (max_value <= min_value) ? 'Range limit should be greater than initial range' : ''
                this.local_check_modifications()
            }, 500)
        },

        local_check_modifications () {
            this.unsaved_changes = (Number(this.backup_data.min_range.percentage) !== Number(this.range_1.percentage)) || (Number(this.backup_data.max_range.percentage) !== Number(this.range_2.percentage))
            this.$emit('config-status-change', !this.unsaved_changes && !this.local_has_errors() ? 'no-change' : 'in-progress')
        },

        local_get_color_width (type) {
            return type === 'range_1' ? this.range_1.percentage : type === 'range_2' ? (Number(this.range_2.percentage) - Number(this.range_1.percentage)) : (100-(Number(this.range_2.percentage)))
        },

        local_config_update () {
            const params = [this.range_1, this.range_2]
            this.$emit('update', params)
        },

        local_has_errors () {
            return !!(this.error_response && this.error_response.range_1) || !!(this.error_response && this.error_response.range_2)
        }
    }
}
</script>

<style scoped>
.c-left-div {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.c-right-div {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.c-color-indicator {
    border: 1px solid transparent;
    height: 20px;
    width: 20px;
    border-radius: 2px;
}
</style>

<template>
    <a-main>
        <g-settings-breadcrumb></g-settings-breadcrumb>
        <a-container grid-list-xl>
            <g-page-header
                title="Privacy"
                subtitle="Manage and download data that belongs to your organization."
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                icon="security"
            ></g-page-header>
            <a-layout wrap align-start>
                <a-flex xs12>
                    <a-card class="u-elevation-custom-1" v-if="user_self.is_admin === 1">
                        <a-card-text class="pa-6">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-icon size="24" color="primary">cloud_download</a-icon>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 font-weight-medium primary--text">Download Project Data <span class="md-subtitle-1 grey--text text--darken-2 ml-2 mb-0">Download all your Projects as Excel spreadsheets.</span></h2>
                                    <a-tooltip bottom v-if="determine_archive_progress || determine_archive_download">
                                        <span slot="activator">
                                            <a-icon size="18" color="grey darken-2" class="u-icon-nudge">person</a-icon>
                                            <span class="md-body-2 grey--text text--darken-2">{{ download_request_item.initiator.name }}</span>
                                        </span>
                                        <span>Initiated By</span>
                                    </a-tooltip>
                                    <a-tooltip bottom v-if="determine_archive_download">
                                        <span class="ml-2" slot="activator">
                                            <a-icon size="18" color="grey darken-2" class="u-icon-nudge">archive</a-icon>
                                            <span class="md-body-2 grey--text text--darken-2"><g-moment v-if="download_request_item.updated_at" :value="download_request_item.updated_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment></span>
                                        </span>
                                        <span>Archive Generated Date and Time</span>
                                    </a-tooltip>
                                    <a-tooltip bottom v-if="determine_archive_download">
                                        <span class="ml-2" slot="activator">
                                            <a-icon size="18" color="grey darken-2" class="u-icon-nudge">timer</a-icon>
                                            <span class="md-body-2 grey--text text--darken-2"><g-moment v-if="download_request_item.expires_at" :value="download_request_item.expires_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment></span>
                                        </span>
                                        <span>Archive Expiry Date and Time</span>
                                    </a-tooltip>
                                </a-flex>
                                <a-flex shrink>
                                    <a-btn v-if="determine_archive_generate" small class="elevation-0 px-3 ma-0" color="primary" :disabled="loading" @click="local_project_archive()">
                                        <a-icon size="14" class="mr-2">save_alt</a-icon>
                                        Generate Archive
                                    </a-btn>
                                    <a-btn v-if="determine_archive_download"  class="ml-2 mt-0 mb-0 mr-0" color="grey" small flat @click="local_project_archive()" :disabled="loading">Regenerate Archive</a-btn>
                                    <a-btn target="_blank" rel="noopener noreferrer" :href="download_request_item.url" v-if="determine_archive_download" small dark class="elevation-0 pr-3 ma-0" color="green" :disabled="loading">
                                        <a-icon size="14" class="mr-2">save_alt</a-icon>
                                        Download Archive
                                    </a-btn>
                                    <a-progress-circular
                                        v-if="determine_archive_progress"
                                        :indeterminate="true"
                                        :size="40"
                                        :width="2"
                                        color="light-blue"
                                    >
                                        <span class="md-caption font-weight-medium">{{ download_request_item.completed_percentage }}%</span>
                                    </a-progress-circular>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
    </a-main>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        data() {
            return {
                breadcrumb_items: [
                    {
                        text: 'Privacy',
                        to: '/privacy',
                        exact: true,
                    }
                ],
                project_export_status_interval : null,
                indeterminate: true,
            }
        },
        mounted() {
            this.local_index()
        },
        computed: {
            ...mapState('Interface', {
                loading: 'loader',
            }),
            ...mapState('DownloadRequest', {
                download_request_item: 'item',
                download_request_response: 'response',
            }),
            ...mapState('User', {
                user_self: 'self',
            }),
            determine_archive_generate() {
                return (!this.download_request_item)
            },
            determine_archive_progress() {
                return (this.download_request_item && this.download_request_item.completed_percentage < 100)
            },
            determine_archive_download() {
                if (this.download_request_item && this.download_request_item.completed_percentage === 100) {
                    clearInterval(this.project_export_status_interval);
                    return true;
                }
            },
        },
        methods: {
            async local_index() {
                await this.download_request_status();
            },
            async local_project_archive() {
                await this.download_request_clear();
                await this.download_request_export();
                this.project_export_status_interval = setInterval(() => this.download_request_status(), 2000);
            },
            ...mapActions('DownloadRequest', {
                download_request_status: 'status',
                download_request_export: 'export',
                download_request_clear: 'clear_item'
            }),
        },
    }
</script>

<style scoped>

</style>

<template>
	<a-main>
        <a-container container--fluid grid-list-xl class="my-2 mb-10">
            <a-layout align-center>
                <a-flex>
                    <h2 class="md-heading-6 indigo--text text--darken-1 font-weight-semibold"><a-icon size="24" color="indigo" class="mr-1 u-icon-nudge">vpn_key</a-icon>Accounts</h2>
                    <p class="md-body-2 mb-0 grey--text text--darken-2">Salesforce accounts connected with Success to run workflows.</p>
                </a-flex>
            </a-layout>
            <a-container container--fluid grid-list-xl class="my-4" v-if="installation_account_list && installation_account_list.length === 0">
                <a-layout align-center justify-center>
                    <a-flex xs4>
                        <div class="text-center"><a-icon size="160" color="grey lighten-1">vpn_key</a-icon></div>
                        <h2 class="md-heading-5 mb-1 grey--text text--darken-3 text-center">No accounts</h2>
                        <p class="md-body-2 mb-2 grey--text text--darken-1 text-center">Salesforce accounts connected with Success to run workflows.</p>
                    </a-flex>
                </a-layout>
            </a-container>
            <a-layout align-center v-if="installation_account_list && installation_account_list.length > 0">
                <a-flex>
                    <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden mb-3" v-for="account in installation_account_list" :key="account.id">
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="py-3">
                                <a-layout align-center>
                                    <a-flex shrink>
                                        <div class="d-flex">
                                            <img src="../../assets/images/logo-salesforce.svg" alt="Salesforce" style="display: inline-block; max-width: 32px; max-height: 32px;">
                                        </div>
                                    </a-flex>
                                    <a-flex>
                                        <p class="body-2 mb-0 font-weight-bold">{{ account.label }}</p>
                                        <p class="md-caption mb-0">Connected by {{ (account.authenticator) ? account.authenticator.name : '' }} on <g-moment :value="account.created_at" input-format="YYYY-MM-DD" output-format="MMMM D, YYYY"></g-moment></p>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
           
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        ...mapState('InstallationAccount', {
            installation_account_list: 'list',
            installation_account_filters: 'filters',
            installation_account_response: 'response',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            if (this.user_self && this.user_self.organization && this.user_self.organization.flag_salesforce_microservice === 0 || this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                return this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.installation_account_clear()
        },
        ...mapActions('InstallationAccount', {
            installation_account_index: 'index',
            installation_account_update: 'update',
            installation_account_destroy: 'destroy',
            installation_account_clear: 'clear',
        }),
    },
}
</script>

<template>
    <a-sheet v-bind="$attrs" v-on="$listeners" :width="width" :height="height" class="loader-animate u-rounded-corners-lg"></a-sheet>
</template>

<script>
export default {
    props: {
        width: {
            type: String | Number,
            default: 400
        },
        height: {
            type: String | Number,
            default: 400
        }
    }
}
</script>

<template>
    <a-container container--fluid grid-list-xl pa-6 mt-4>
        <a-layout wrap align-start v-if="!local_loading">
            <template v-if="local_has_any_permission">
                <a-flex xs12 md6 xl4 v-if="$can('usecases.index')">
                    <a-sheet class="u-flex align-center u-rounded-corners pa-6 u-shadow">
                        <div class="u-flex align-center">
                            <h2 class="text-h6 font-weight-medium grey--text text--darken-1 mr-3" style="line-height: 1.1">Scope</h2>
                            <h3 class="md-body-2 grey--text text--darken-1" v-if="project_item.usecases_count > 0">section contains {{ project_item.usecases_count }} scope{{ project_item.usecases_count > 1 ? 's' : '' }}</h3>
                            <h3 class="md-body-2 grey--text text--darken-1" v-else>No Use Cases</h3>
                        </div>
                    </a-sheet>
                </a-flex>
                <a-flex xs12 md6 xl4 v-if="$can('tasks.index')">
                    <a-sheet class="u-flex align-center u-rounded-corners pa-6 u-shadow">
                        <div class="u-flex align-center">
                            <h2 class="text-h6 font-weight-medium grey--text text--darken-1 mr-3" style="line-height: 1.1">Task</h2>
                            <h3 class="md-body-2 grey--text text--darken-1">{{ project_item.tasks_count - project_item.tasks_pending_count }} completed out of {{ project_item.tasks_count }} task{{ project_item.tasks_count > 1 ? 's' : '' }}</h3>
                        </div>
                    </a-sheet>
                </a-flex>
                <a-flex xs12 md6 xl4 v-if="$can('links.index')">
                    <a-sheet class="u-flex align-center u-rounded-corners pa-6 u-shadow">
                        <div class="u-flex align-center">
                            <h2 class="text-h6 font-weight-medium grey--text text--darken-1 mr-3" style="line-height: 1.1">Link</h2>
                            <h3 class="md-body-2 grey--text text--darken-1">section contains {{ project_item.links_count }} link{{ project_item.links_count > 1 ? 's' : '' }}</h3>
                        </div>
                    </a-sheet>
                </a-flex>
                <a-flex xs12 md6 xl4 v-if="$can('documents.index')">
                    <a-sheet class="u-flex align-center u-rounded-corners pa-6 u-shadow">
                        <div class="u-flex align-center">
                            <h2 class="text-h6 font-weight-medium grey--text text--darken-1 mr-3" style="line-height: 1.1">Document</h2>
                            <h3 class="md-body-2 grey--text text--darken-1">section contains {{ project_item.documents_count }} document{{ project_item.documents_count > 1 ? 's' : '' }}</h3>
                        </div>
                    </a-sheet>
                </a-flex>
                <a-flex xs12 md6 xl4 v-if="$can('notes.index')">
                    <a-sheet class="u-flex align-center u-rounded-corners pa-6 u-shadow">
                        <div class="u-flex align-center">
                            <h2 class="text-h6 font-weight-medium grey--text text--darken-1 mr-3" style="line-height: 1.1">Note</h2>
                            <h3 class="md-body-2 grey--text text--darken-1">section contains {{ project_item.notes_count }} note{{ project_item.notes_count > 1 ? 's' : '' }}</h3>
                        </div>
                    </a-sheet>
                </a-flex>
            </template>
            <template v-if="!local_has_any_permission">
                <a-flex xs12 class="u-flex align-center">
                    <a-icon class="u-icon-nudge mr-2" color="yellow darken-4">warning</a-icon>
                    <h2 class="text-subtitle-1 grey--text text--darken-1">No data to show. You don't have sufficient permission to view the project.</h2>
                </a-flex>
            </template>
        </a-layout>
        <a-layout wrap align-start v-if="local_loading">
            <a-flex xs12>
                <h2 class="text-subtitle-1 text-center grey--text text--darken-1">Loading...</h2>
            </a-flex>
        </a-layout>
        <template v-if="filteredSection && filteredSection.length">
            <h4 class="grey--text text--darken-1 font-weight-medium md-subtitle-1 px-4 my-4">Tags</h4>
            <template v-for="section in filteredSection">
                <a-sheet class="white u-shadow u-rounded-corners mb-4" :key="section.id" v-if="section && section.fields && section.fields.length">
                    <h4 class="indigo--text text--darken-1 font-weight-medium px-6 py-5 md-subtitle-1 u-wfull">{{ section.name }}</h4>
                    <a-divider></a-divider>
                    <div v-for="(field, index) in section.fields" :key="field.id">
                        <div v-if="field" class="u-flex align-center">
                            <template>
                                <h2 style="min-width: 184px; max-width: 184px" class="md-subtitle-1 font-weight-medium primary--text text--lighten-2 text-truncate pa-6">
                                    <template v-if="field.parent_type === 'SystemTagSection'">{{ field.parent.value }}</template>
                                    <template v-else>{{ field.parent.name }}</template>
                                </h2>
                                <div class="pa-6">
                                    <template v-for="tag in localProjectFilteredTags(local_get_tag_slug(field))">
                                        <g-tags :can-update="false" :tag="tag" type="Project" :key="tag.id"></g-tags>
                                    </template>
                                    <h3 v-if="localProjectFilteredTags(local_get_tag_slug(field)).length === 0" class="md-body-2 grey--text text--darken-1">No tags</h3>
                                </div>
                            </template>
                        </div>
                        <a-divider v-if="index !== section.fields && section.fields.length"></a-divider>
                    </div>
                </a-sheet>
            </template>
        </template>
    </a-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    data () {
        return {
            project_filters: {},
            local_loading: true
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        local_has_any_permission() {
            // const isExternal = this.user_self && this.user_self.scope === 'external'
            const permissions = ['usecases.index', 'tasks.index', 'links.index', 'documents.index', 'notes.index']
            // if (!isExternal) return permissions.some(item => this.can_external_mixin(item))
            return permissions.some(item => this.$can(item) && this.$can('projects.index'))
        },

        filteredSection () {
            if (!this.project_item) return
            return this.project_item.layoutDesigner
        },

        ...mapState('User', {
            'user_self': 'self'
        }),

        ...mapState('ProjectExternal', {
            project_item: 'item',
            project_response: 'response'
        }),

        ...mapState('TagSectionExternal', {
            tag_section_list: 'list',
        }),

        ...mapGetters('VisibilityExternal', {
            visibility_get_visibility_item: 'get_visibility_item',
        }),
    },

    methods: {
        async local_index() {
            this.project_filters.include = 'usecasesClosed,usecasesOpenCount,usecasesClosedCount,usecasesCount,tasksPendingCount,tasksCount,linksCount,documentsCount,notesCount'
            this.project_filters = {...this.project_filters, 'filter[layout_designer]':  1}
            await this.project_show({ id: this.$route.params.id, params: this.project_filters })
            this.localFetchVisibility()
            this.local_loading = false
        },

         /* Visibility - Start */
        localHasVisibility (field, value) {
            const data = this.visibility_get_visibility_item(field, value)
            if(!data) return null
            return data.visibility
        },
        /* Visibility - End */

        localProjectFilteredTags (type) {
            if (type) return _.filter(this.project_item.tags, { type: type.toLowerCase() })
        },

        async localFetchVisibility () {
            this.visibility_index({
                'project_id': this.project_item.id,
                'fields[visibilities]': 'id,key,visibility',
            })
        },

        local_get_tag_slug (tagSection) {
            if (tagSection.parent && tagSection.parent.slug) return tagSection.parent.slug
            return tagSection.parent.status
        },

        ...mapActions('ProjectExternal', {
            project_show: 'show',
        }),

        ...mapActions('TagSectionExternal', {
            tag_section_index: 'index',
            tag_section_clear: 'clear',
        }),

        ...mapActions('VisibilityExternal', {
            visibility_index: 'index',
            visibility_upsert: 'upsert',
            visibility_clear: 'clear',
        }),
    },

}
</script>

import { numberFormatter } from "./helper-number";

export const formatCurrency = ({...options}) => {
	const { value, currencyValues, restrictDigit } = options;

	if(restrictDigit) {
		return currencyValues.symbol + numberFormatter(value)
	} else {
		return currency(value, {
			formatWithSymbol: true,
			symbol: currencyValues.symbol,
			useVedic: (currencyValues.code == 'INR') ? true : false,
			precision: 0
		}).format();
	}
}
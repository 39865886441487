import PInsightsType from '../DashboardInsightsType.vue'
import PTileWrapper from './PartialTileWrapper.vue'
import PTileToolbar from './PartialTileToolbar.vue'
import PTileConfig from './PartialTileConfig.vue'
import PBarMultipleVerticalChart from './PartialBarMultipleVerticalChart.vue'
import PBarVerticalChart from './PartialBarVerticalChart.vue'
import PDonutChart from './PartialDonutChart.vue'
import PRadialBarChart from './PartialRadialBarChart.vue'
import PBarStackedVerticalChart from './PartialBarStackedVerticalChart.vue'
import PLineMultipleVerticalChart from './PartialLineMultipleVerticalChart.vue'
import PLineChart from './PartialLineChart.vue'
import PNumberChart from './PartialNumberChart.vue'
import PTileStatus from './PartialTileStatus.vue'
import PChangesTable from './PartialChangesTable.vue'
import PSummaryModule from './PartialSummaryModule.vue'
import PWorkspaceModule from './PartialWorkspaceModule.vue'
import PTimelineModule from './PartialTimelineModule.vue'
import PDataPointModule from './PartialDataPointModule.vue'
import PFilterModule from './PartialFilterModule.vue'

export {
    PFilterModule,
    PDataPointModule,
    PTimelineModule,
    PWorkspaceModule,
    PSummaryModule,
    PChangesTable,
    PTileStatus,
    PInsightsType,
    PTileWrapper,
    PTileConfig,
    PTileToolbar,
    PBarMultipleVerticalChart,
    PBarVerticalChart,
    PDonutChart,
    PLineChart,
    PRadialBarChart,
    PBarStackedVerticalChart,
    PLineMultipleVerticalChart,
    PNumberChart
}

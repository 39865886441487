<template>
    <g-dialog-box-simple hide-close-icon>
        <template #title><slot name="title">Update Test Case</slot></template>
        <template #body>
            <div class="mt-4">
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title</label>
                <slot name="root-title">
                    <a-text-field
                        v-model="item.title"
                        placeholder="Enter Title"
                        class="u-border grey--text text--darken-2"
                        solo flat hide-details
                    >
                    </a-text-field>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="error.server && error.server.errors && error.server.errors.title">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ error.server.errors.title[0] }}
                    </span>
                </slot>
            </div>
            <div class="mt-5">
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Description</label>
                <slot name="description-body">
                    <div class="pa-3 u-border u-rounded-corners" style="max-height: 300px; overflow-y: auto">
                        <slot name="editor"></slot>
                    </div>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="error.server && error.server.errors && error.server.errors.description">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ error.server.errors.description[0] }}
                    </span>
                </slot>
            </div>
            <div class="mt-6">
                <slot name="action">
                    <a-btn color="primary" dark class="my-0 mr-2 elevation-0" @click="$emit('update', item)" :disabled="loading">Save</a-btn>
                    <a-btn color="grey" class="my-0" text @click="$emit('close', true)" :disabled="loading">Cancel</a-btn>
                </slot>
            </div>
        </template>
    </g-dialog-box-simple>
</template>

<script>
export default {
    props: {
        error: {
            type: Object | Array | String
        },
        item: {
            type: Object
        },
        loading: {
            type: Boolean
        },
    }
}
</script>

<style>

</style>

export default {
    methods: {
        check_user_tag_permission(type, client_allowed = false) {
            if (this.user_self.role.scope === 'external') {
                return client_allowed
            }
            return this.$can(`tags.${type}`) && this.user_self.role.scope === 'internal'
        }
    }
}

const headers = {
    'accept': 'application/json',
    'Accept-Language': 'en-US',
    'Content-Type': 'multipart/form-data',
}
const api_endpoint = '/attachments'
export default {
    namespaced: true,
    state: {
        list: [],
        local_list: [],
        delete_list: [],
        upload_list: [],
        item: {},
        meta: {},
        download_link: {},
        preview_link: {},
        response: [],
        delete_response: [],
    },

    mutations: {
        UPDATE_LIST (state, payload) {
            state.list = payload
        },

        UPDATE_LOCAL_LIST (state, payload) {
            state.local_list = payload
        },

        MERGE_LIST (state, payload) {
            const merge_list = [...state.list, ...payload]
            state.list = _.cloneDeep(merge_list)
        },

        UPDATE_UPLOAD_LIST (state, payload) {
            state.upload_list = payload
        },

        UPDATE_ITEM (state, payload) {
            state.item = payload
        },

        UPDATE_META (state, payload) {
            state.meta = payload
        },

        UPDATE_DOWNLOAD_LINK (state, payload) {
            state.download_link = payload
        },

        UPDATE_PREVIEW_LINK (state, payload) {
            state.preview_link = payload
        },

        UPDATE_DELETE_LIST (state, payload) {
            if (payload.mode === 'clear') return state.delete_list = []
            state.delete_list.push(payload)
        },

        REMOVE_UPLOAD_LIST_ITEM (state, payload) {
            const index = _.findIndex(state.upload_list, { 'id': payload })
            if (index !== -1) state.upload_list.splice(index, 1)
        },

        THROW_RESPONSE (state, payload) {
            state.response = payload;
        },

        THROW_DELETE_RESPONSE (state, payload) {
            state.delete_response = payload;
        },
    },
    actions: {
        async index (context, payload) {
            try {
                const { data: { data }, data: { meta } } = await axios.get(api_endpoint, { params: payload.params })
                if (payload.mode === 'load_more') context.commit('MERGE_LIST', data)
                else context.commit('UPDATE_LIST', data)
                context.commit('UPDATE_META', meta)
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success'});
            }
            catch (error) {
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },

        update_list (context, payload) {
            context.commit('UPDATE_LIST', payload)
            context.commit('THROW_RESPONSE', { text: 'Done', status: 'success'});
        },

        update_local_list (context, payload) {
            context.commit('UPDATE_LOCAL_LIST', payload)
            context.commit('THROW_RESPONSE', { text: 'Done', status: 'success'});
        },

        update_upload_list (context, payload) {
            context.commit('UPDATE_UPLOAD_LIST', payload)
        },

        async upload (context, payload) {
            try {
                await axios.post(api_endpoint, payload, { headers: headers })
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success'});
            }
            catch (error) {
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },

        async download (context, payload) {
            try {
                const { data: { links } } = await axios.get(api_endpoint + '/' + payload.id + '/download', { params: payload.params })
                context.commit('UPDATE_DOWNLOAD_LINK', links);
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            }
            catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },

        async preview (context, payload) {
            try {
                const { data: { links } } = await axios.get(api_endpoint + '/' + payload.id + '/preview', { params: payload.params })
                context.commit('UPDATE_PREVIEW_LINK', links);
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            }
            catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },

        async bulk_destroy (context, payload) {
            try {
                await axios.delete(`${api_endpoint}/bulk-destroy`, { params: payload })
                context.commit('THROW_DELETE_RESPONSE', { text: 'Done', status: 'success'});
            }
            catch (error) {
                context.commit('THROW_DELETE_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },

        update_delete_list (context, payload) {
            context.commit('UPDATE_DELETE_LIST', payload)
        },

        update_meta (context, payload) {
            context.commit('UPDATE_META', payload)
        },

        remove_upload_list_item (context, payload) {
            context.commit('REMOVE_UPLOAD_LIST_ITEM', payload)
        },

        clear_upload_list (context) {
            context.commit('UPDATE_UPLOAD_LIST', [])
        },

        clear_delete_list (context) {
            context.commit('UPDATE_DELETE_LIST', { mode: 'clear' })
        },

        clear_response (context) {
            context.commit('THROW_RESPONSE', [])
            context.commit('THROW_DELETE_RESPONSE', [])
        },

        clear (context) {
            context.commit('UPDATE_DOWNLOAD_LINK', {})
            context.commit('UPDATE_PREVIEW_LINK', {})
        }
    }
}

<template>
    <a-main>
        <!-- <g-settings-breadcrumb previousRoute="inventory"></g-settings-breadcrumb> -->
        <a-responsive class="grey darken-4 mt-5 u-rounded-corners-lg">
            <a-container container--fluid grid-list-xl class="py-3 mx-0">
                <a-layout align-start>
                    <a-flex shrink class="px-1 py-1">
                        <a-btn color="white" class="ma-1" text @click="$router.back()" exact>
                            <a-icon class="mr-1">arrow_back</a-icon> Back
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive>
        <a-container grid-list-xl container--fluid mb-12 pb-12 px-0>
            <g-page-header title="Manage Bookings" subtitle="Manage the state and time of bookings." :breadcrumb-items="breadcrumb_items" icon="memory"></g-page-header>
            <!-- {{ inventory_booking_list[0] }} -->
            <template v-if="inventory_booking_list && !local_loading">
                <!-- Booking Details - Project info, Assignee, Requester, Location -->
                <a-container grid-list-xl container--fluid class="pa-0 pt-2 mt-0">
                    <div v-if="booking_detail" class="u-flex align-start">
                        <div class="u-flex align-start">
                            <!-- <div>
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a-avatar v-on="on" :size="$vuetify.breakpoint.lgAndDown ? 102 : 128" color="grey" v-if="booking_detail.assignee && booking_detail.assignee.profile && booking_detail.assignee.profile.avatar">
                                            <a-avatar :size="$vuetify.breakpoint.lgAndDown ? 100 : 126" color="white">
                                                <a-img :src="booking_detail.assignee.profile.avatar" contain></a-img>
                                            </a-avatar>
                                        </a-avatar>
                                        <a-avatar v-else v-on="on" :size="$vuetify.breakpoint.lgAndDown ? 102 : 128" color="indigo">
                                            <span class="display-1 font-weight-bold white--text">{{ booking_detail.assignee ? booking_detail.assignee.initial : '' }}</span>
                                        </a-avatar>
                                    </template>
                                    <span>Assigned to <strong>{{ booking_detail.assignee ? booking_detail.assignee.name : '' }}</strong></span>
                                </a-tooltip>
                            </div> -->
                            <div class="mt-3">
                                <h4 class="headline grey--text text--darken-2 font-weight-bold text-truncate" :title="booking_detail[0].project.title" v-if="booking_detail[0] && booking_detail[0].project" style="max-width: 400px">{{ booking_detail[0].project.title }}</h4>
                                <a-chip class="ml-0 mb-2 md-subtitle-2 grey--text text--darken-1 font-weight-medium mt-1 text-truncate" label small style="max-width: 300px" :title="booking_detail[0].project.customer.name" v-if="booking_detail[0] && booking_detail[0].project && booking_detail[0].project.customer">{{ local_truncate_text(booking_detail[0].project.customer.name, 40) }}</a-chip>
                                <!-- <h4 class="title grey--text text--darken-1 font-weight-medium text-truncate" style="max-width: 300px" :title="booking_detail[0].project.customer.name" v-if="booking_detail[0] && booking_detail[0].project && booking_detail[0].project.customer">{{ booking_detail[0].project.customer.name }}</h4> -->
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <h4 v-on="on" class="body-2 grey--text text--darken-1 font-weight-medium text-truncate mt-2" style="max-width: 300px" v-if="booking_detail[0].project && booking_detail[0].assignee[0]">Assigned to <strong>{{ booking_detail[0].assignee[0] ? booking_detail[0].assignee[0].name : '' }}</strong></h4>
                                    </template>
                                    <div class="u-flex align-center">
                                        <g-avatar :item="booking_detail[0].assignee[0]" no-border></g-avatar>
                                        <g-profile-info :item="booking_detail[0].assignee[0]" type-user dark></g-profile-info>
                                    </div>
                                </a-tooltip>
                                <div class="">
                                    <a-menu offset-y bottom ref="bulk_extend_ref" class="mr-2" transition="slide-y-transition" :close-on-content-click="false" content-class="u-rounded-corners" :disabled="!(extended_inventories.booking_ids && extended_inventories.booking_ids.length)">
                                        <template v-slot:activator="{ on }">
                                            <a-btn v-on="on" style="letter-spacing: 1px" @click="local_add_prev_date_range(local_is_booking_from_before_or_same ? null : 'Booked')" :disabled="!(extended_inventories.booking_ids && extended_inventories.booking_ids.length)" class="blue darken-2 white--text body-2 px-2 mt-4 ml-0 mr-4 mb-0 u-rounded-corners elevation-0">
                                                <a-icon size="20" class="white--text mr-2">more_time</a-icon>
                                                Bulk Extend
                                            </a-btn>
                                        </template>
                                        <a-sheet class="u-rounded-corners" style="position: relative">
                                            <vc-date-picker :attributes="date_extend_attrs" class="u-rounded-corners elevation-0" v-model="bulk_extended_date" :min-date="min_date_to_disable" title-position="left" is-inline></vc-date-picker>
                                            <a-btn small class="u-wfull ma-0 blue white--text d-block" v-if="!extended_text" @click="local_inventory_bulk_extend()">Extend</a-btn>
                                            <a-btn small class="u-wfull ma-0 green white--text d-block" v-else><a-icon size="16" class="white--text mr-1">check</a-icon> Extended Successfully</a-btn>
                                        </a-sheet>
                                    </a-menu>
                                    <template v-if="local_is_booking_from_before_or_same">
                                        <a-menu offset-y bottom ref="bulk_extend_ref" transition="slide-y-transition" max-width="200" content-class="u-rounded-corners" :disabled="!(extended_inventories.booking_ids && extended_inventories.booking_ids.length)">
                                            <template v-slot:activator="{ on }">
                                                <a-btn v-on="on" style="letter-spacing: 1px" :disabled="!(extended_inventories.booking_ids && extended_inventories.booking_ids.length)" class="orange darken-3 white--text body-2 px-2 mt-4 mx-0 mb-0 u-rounded-corners elevation-0">
                                                    <a-icon size="20" class="white--text mr-2">autorenew</a-icon>
                                                    Bulk Return
                                                </a-btn>
                                            </template>
                                            <div class="pa-3 white text-center">
                                                <h3 class="body-2 grey--text text--darken-1">Are you sure you want to continue the bulk return? <br> <strong>This cannot be undone.</strong></h3>
                                            </div>
                                            <a-btn small text dark class="orange darken-3 ma-0 u-wfull" @click="local_bulk_return_booking()">Ok, Continue</a-btn>
                                        </a-menu>
                                    </template>
                                    <a-menu v-else offset-y bottom ref="bulk_extend_ref" transition="slide-y-transition" max-width="200" content-class="u-rounded-corners" :disabled="!(extended_inventories.booking_ids && extended_inventories.booking_ids.length)">
                                        <template v-slot:activator="{ on }">
                                            <a-btn v-on="on" style="letter-spacing: 1px" :disabled="!(extended_inventories.booking_ids && extended_inventories.booking_ids.length)" class="red darken-1 white--text body-2 px-2 mt-4 mx-0 mb-0 u-rounded-corners elevation-0">
                                                <a-icon size="20" class="white--text mr-2">autorenew</a-icon>
                                                Bulk Cancel
                                            </a-btn>
                                        </template>
                                        <div class="pa-3 white text-center">
                                            <h3 class="body-2 grey--text text--darken-1">Are you sure you want to continue the bulk cancel? <br> <strong>This cannot be undone.</strong></h3>
                                        </div>
                                        <a-btn small text dark class="orange darken-3 ma-0 u-wfull" @click="local_bulk_cancel_booking()">Ok, Continue</a-btn>
                                    </a-menu>
                                </div>
                            </div>
                        </div>
                        <a-spacer></a-spacer>
                        <div v-if="booking_detail[0]">
                            <table class="c-poc-info-table">
                                <tr>
                                    <td class="subheading grey--text text--darken-1 text-truncate text-xs-right">Date of Request</td>
                                    <td class="subheading grey--text text--darken-2 font-weight-medium text-truncate text-xs-right0" style="max-width: 300px;">{{ local_format_date(booking_detail[0].booking_from) }}</td>
                                </tr>
                                <tr>
                                    <td class="subheading grey--text text--darken-1 text-xs-right">Location</td>
                                    <td class="subheading grey--text text--darken-2 font-weight-medium text-xs-right" :title="booking_detail[0].location" style="max-width: 300px;">{{ local_truncate_text(booking_detail[0].location, 60) }}</td>
                                </tr>
                                <tr>
                                    <td class="subheading grey--text text--darken-1 text-truncate text-xs-right">Requester</td>
                                    <td style="max-width: 300px;">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <h4 v-on="on" class="subheading grey--text text--darken-2 font-weight-medium text-truncate text-xs-right" style="max-width: 300px;">{{ booking_detail[0].requester.name }}</h4>
                                            </template>
                                            <div class="u-flex align-center">
                                                <g-avatar :item="booking_detail[0].requester" no-border></g-avatar>
                                                <g-profile-info :item="booking_detail[0].requester" type-user dark></g-profile-info>
                                            </div>
                                        </a-tooltip>
                                    </td>
                                </tr>
                            </table>
                            <!-- <h4 class="subheading grey--text text--darken-1 mt-2 text-truncate text-xs-right">
                                Date of Request <strong>{{ local_format_date(booking_detail.booking_from) }}</strong>
                            </h4>
                            <h4 class="subheading grey--text text--darken-1 mt-2 text-truncate text-xs-right">
                                Requested by <strong>{{ booking_detail.location }}</strong>
                            </h4>
                            <div class="mt-3">
                                <h4 v-if="booking_detail.requester" class="subheading grey--text text--darken-1 mt-2 text-truncate text-xs-right">
                                    Requested by
                                    <a-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <strong v-on="on">{{ booking_detail.requester.name }}</strong>
                                        </template>
                                        <div class="u-flex align-center">
                                            <div class="mr-2">
                                                <a-avatar size="32" color="grey" v-if="booking_detail.requester.profile && booking_detail.requester.profile.avatar">
                                                    <a-avatar size="30" color="white">
                                                        <a-img :src="booking_detail.requester.profile.avatar" contain></a-img>
                                                    </a-avatar>
                                                </a-avatar>
                                                <a-avatar v-else size="32" color="indigo">
                                                    <span class="body-2 font-weight-bold white--text">{{ booking_detail.requester ? booking_detail.requester.initial : '' }}</span>
                                                </a-avatar>
                                            </div>
                                            <div>
                                                <div><strong>{{ booking_detail.requester.name }}</strong></div>
                                                <div class="caption">{{ booking_detail.requester.role ? booking_detail.requester.role.name : '' }}</div>
                                            </div>
                                        </div>
                                    </a-tooltip>
                                </h4>
                            </div> -->
                        </div>
                    </div>
                </a-container>

                <!-- Booking Lists -->
                <template v-if="local_inventory_list && local_inventory_list.length">
                    <a-divider class="my-4 grey lighten-2"></a-divider>
                    <a-container grid-list-xl container--fluid class="pa-0 pt-4 pb-3">
                        <a-layout>
                            <a-flex xs12>
                                <h2 style="letter-spacing: 1px" class="body-2 grey--text text--darken-2 mb-3 py-1 u-rounded-corners text-uppercase font-weight-bold d-inline-block">Original Bookings</h2>
                                <div class="u-overflow-x">
                                    <table ref="refTableInventories" class="u-wfull u-rounded-corners u-elevation-custom-1 mb-2 relative" style="position: relative; border-collapse: collapse">
                                        <tr class="grey lighten-5 u-rounded-corners mb-2" style="border-bottom: 1px solid #ececec !important">
                                            <th class="caption u-rounded-corners font-weight-bold grey--text text--darken-2 text-uppercase text-left py-3 pl-3 pr-3" style="min-width: 50px !important; max-width: 50px !important;">
                                                <a-checkbox v-if="local_is_booking_from_before_or_same" color="blue darken-2" :disabled="!!(this.local_inventory_list && this.local_inventory_list.length <= 1)" :input-value="add_all_inventory" @change="local_add_all_inventory(add_all_inventory = !add_all_inventory)" hide-details dense class="ma-0 pt-0"></a-checkbox>
                                                <a-checkbox v-else color="blue darken-2" :disabled="!!(this.local_inventory_list && this.local_inventory_list.length <= 1)" :input-value="add_all_inventory" @change="local_add_all_booked_inventory(add_all_inventory = !add_all_inventory)" hide-details dense class="ma-0 pt-0"></a-checkbox>
                                            </th>
                                            <th class="caption u-rounded-corners font-weight-bold grey--text text--darken-2 text-uppercase text-left py-3 px-3" style="letter-spacing: 1px; min-width: 100px !important; max-width: 100px !important">Category</th>
                                            <th class="caption u-rounded-corners font-weight-bold grey--text text--darken-2 text-uppercase text-left py-3 px-3" style="letter-spacing: 1px; min-width: 250px !important; max-width: 250px !important;">Name</th>
                                            <th class="caption u-rounded-corners font-weight-bold grey--text text--darken-2 text-uppercase text-left py-3 px-3" style="letter-spacing: 1px; min-width: 150px !important; max-width: 150px !important;">Serial #</th>
                                            <th class="caption u-rounded-corners font-weight-bold grey--text text--darken-2 text-uppercase text-left py-3 px-3" :style="[$vuetify.breakpoint.lgAndDown ? { 'min-width': '120px !important', 'max-width': '120px !important' } : { 'min-width': '150px !important', 'max-width': '150px !important' }]" style="letter-spacing: 1px;">Part #</th>
                                            <!-- <th class="caption u-rounded-corners font-weight-bold grey--text text--darken-2 text-uppercase text-center py-3 px-3" style="letter-spacing: 1px; min-width: 150px !important; max-width: 150px !important;">Action</th> -->
                                            <th class="caption u-rounded-corners font-weight-bold grey--text text--darken-2 text-uppercase py-3 px-3" style="letter-spacing: 1px; min-width: 200px !important; max-width: 200px !important;">Booking Range</th>
                                            <th class="caption u-rounded-corners font-weight-bold grey--text text--darken-2 text-uppercase py-3 px-3" :style="[$vuetify.breakpoint.lgAndDown ? { 'min-width': '110px !important', 'max-width': '110px !important' } : { 'min-width': '80px !important', 'max-width': '80px !important' }]" style="letter-spacing: 1px;">Status</th>
                                            <th class="caption u-rounded-corners font-weight-bold grey--text text--darken-2 text-uppercase text-center py-3 px-3" style="letter-spacing: 1px; min-width: 150px !important; max-width: 150px !important;">Action</th>
                                        </tr>
                                    </table>
                                    <div :style="[{ width: tableWidth + 'px' }]" class="mb-2" v-for="(inventory_booking, index) in local_inventory_list" :key="inventory_booking.id">
                                        <a-card class="py-2 u-rounded-corners u-elevation-custom-1">
                                            <table class="u-wfull u-rounded-corners" style="border-collapse: collapse">
                                                <tr class="u-wfull u-rounded-corners">
                                                    <td class="body-2 py-2 px-3 text-truncate grey--text text--darken-3" style="min-width: 50px !important; max-width: 50px !important;">
                                                        <a-tooltip bottom v-if="inventory_booking.status === 'Cancelled'">
                                                            <template v-slot:activator="{ on }">
                                                                <a-checkbox v-on="on" class="ma-0 pt-0" hide-details dense disabled></a-checkbox>
                                                            </template>
                                                            <span>This inventory has been cancelled</span>
                                                        </a-tooltip>
                                                        <template v-else-if="inventory_booking.status === 'Returned'">
                                                            <a-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <a-checkbox v-on="on" color="blue darken-2" class="ma-0 pt-0" hide-details dense disabled></a-checkbox>
                                                                </template>
                                                                <span>This inventory has been returned</span>
                                                            </a-tooltip>
                                                        </template>
                                                        <template v-else>
                                                            <a-checkbox
                                                                color="blue darken-2"
                                                                :input-value="local_is_added_to_extend(inventory_booking.id)"
                                                                @change="local_add_inventory_to_extend(inventory_booking.id, inventory_booking.booking_from, inventory_booking.booking_to, 'in_use')"
                                                                hide-details dense
                                                                class="ma-0 pt-0"
                                                            ></a-checkbox>
                                                        </template>
                                                    </td>
                                                    <template v-if="inventory_booking.inventory_trashed">
                                                        <td class="body-2 py-2 px-3 grey--text text--darken-3 text-truncate" :title="inventory_booking.inventory_trashed && inventory_booking.inventory_trashed.category ? inventory_booking.inventory_trashed.category.value : 'No Category'" style="min-width: 100px !important; max-width: 100px !important">{{ inventory_booking.inventory_trashed && inventory_booking.inventory_trashed.category ? inventory_booking.inventory_trashed.category.value : 'No Category' }}</td>
                                                        <td class="body-2 py-2 px-3 grey--text text--darken-3 text-truncate" :title="inventory_booking.inventory_trashed.name" style="min-width: 250px !important; max-width: 250px !important">
                                                            <div class="u-flex align-center">
                                                                <h4 class="body-2 text-truncate" :title="inventory_booking.inventory_trashed.name">{{ inventory_booking.inventory_trashed.name }}</h4>
                                                                <span class="caption red--text text--darken-1 d-inline-block px-2 ml-1" style="border-radius: 50px; border: 1px solid #ef5350;">
                                                                    Del<span class="hidden-lg-and-down">eted</span>
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td class="body-2 py-2 px-3 grey--text text--darken-3 text-truncate" :title="inventory_booking.inventory_trashed.serial_no" style="min-width: 150px !important; max-width: 150px !important">{{ inventory_booking.inventory_trashed.serial_no ? inventory_booking.inventory_trashed.serial_no : '-' }}</td>
                                                        <td class="body-2 py-2 px-3 grey--text text--darken-3 text-truncate" :title="inventory_booking.inventory_trashed.part_no" :style="[$vuetify.breakpoint.lgAndDown ? { 'min-width': '120px !important', 'max-width': '130px !important' } : { 'min-width': '150px !important', 'max-width': '150px !important' }]">{{ inventory_booking.inventory_trashed.part_no ? inventory_booking.inventory_trashed.part_no : '-' }}</td>
                                                    </template>
                                                    <template v-else>
                                                        <td class="body-2 py-2 px-3 grey--text text--darken-3 text-truncate" :title="inventory_booking.inventory && inventory_booking.inventory.category ? inventory_booking.inventory.category.value : 'No Category'" style="min-width: 100px !important; max-width: 100px !important">{{ inventory_booking.inventory && inventory_booking.inventory.category ? inventory_booking.inventory.category.value : 'No Category' }}</td>
                                                        <td class="body-2 py-2 px-3 grey--text text--darken-3 text-truncate" :title="inventory_booking.inventory.name" style="min-width: 250px !important; max-width: 250px !important">
                                                            {{ inventory_booking.inventory.name ? inventory_booking.inventory.name : '-' }}
                                                        </td>
                                                        <td class="body-2 py-2 px-3 grey--text text--darken-3 text-truncate" :title="inventory_booking.inventory.serial_no" style="min-width: 150px !important; max-width: 150px !important">{{ inventory_booking.inventory.serial_no ? inventory_booking.inventory.serial_no : '-' }}</td>
                                                        <td class="body-2 py-2 px-3 grey--text text--darken-3 text-truncate" :title="inventory_booking.inventory.part_no" :style="[$vuetify.breakpoint.lgAndDown ? { 'min-width': '120px !important', 'max-width': '130px !important' } : { 'min-width': '150px !important', 'max-width': '150px !important' }]">{{ inventory_booking.inventory.part_no ? inventory_booking.inventory.part_no : '-' }}</td>
                                                    </template>
                                                    <td class="body-2 py-2 px-3 text-truncate grey--text text-center text--darken-3 mx-auto" style="min-width: 200px !important; max-width: 200px !important;">
                                                        <div class="u-flex align-center justify-center">
                                                            <span>{{ local_format_date(inventory_booking.booking_from) }} - {{ local_format_date(inventory_booking.actual_booking_to) }}</span>
                                                        </div>
                                                    </td>
                                                    <td class="body-2 py-2 px-3 text-truncate grey--text text-center text--darken-3 mx-auto" :style="[$vuetify.breakpoint.lgAndDown ? { 'min-width': '110px !important', 'max-width': '110px !important' } : { 'min-width': '80px !important', 'max-width': '80px !important' }]">
                                                        <a-chip label small class="ma-0 u-rounded-corners u-wfull u-flex align-center justify-center orange darken-1" v-if="inventory_booking.status === 'In Use'">
                                                            <div class="d-flex align-center">
                                                                <h4 class="body-2 white--text">{{ inventory_booking.status }}</h4>
                                                                <span v-if="inventory_booking.force_extended === 1">
                                                                    <a-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <a-icon v-on="on" size="14" class="px-2 py-1 pr-0" color="white darken-5">info</a-icon>
                                                                        </template>
                                                                        <span>In Use and Overdue</span>
                                                                    </a-tooltip>
                                                                </span>
                                                            </div>
                                                        </a-chip>
                                                        <a-chip label small class="ma-0 u-rounded-corners u-wfull u-flex align-center justify-center grey lighten-2" v-if="inventory_booking.status === 'Returned'">
                                                            <div class="d-flex align-center">
                                                                <h4 class="body-2 grey--text text--darken-1">{{ inventory_booking.status }}</h4>
                                                            </div>
                                                        </a-chip>
                                                        <a-chip label small class="ma-0 u-rounded-corners u-wfull u-flex align-center justify-center light-green lighten-3" v-if="inventory_booking.status === 'Booked'">
                                                            <div class="d-flex align-center">
                                                                <h4 class="body-2 green--text text--darken-2">{{ inventory_booking.status }}</h4>
                                                            </div>
                                                        </a-chip>
                                                        <a-chip label small class="ma-0 u-rounded-corners u-wfull u-flex align-center justify-center red lighten-4" v-if="inventory_booking.status === 'Cancelled'">
                                                            <div class="d-flex align-center">
                                                                <h4 class="body-2 red--text text--lighten-1">{{ inventory_booking.status }}</h4>
                                                            </div>
                                                        </a-chip>
                                                    </td>
                                                    <td class="body-2 py-2 px-3 grey--text text--darken-3 text-center u-flex align-center justify-center mx-auto" style="min-width: 150px !important; max-width: 150px !important;">
                                                        <div class="mr-2">
                                                            <template v-if="inventory_booking.status === 'Cancelled'">
                                                                <a-tooltip bottom>
                                                                    <template v-slot:activator="{ on }">
                                                                        <a-btn v-on="on" icon small text disabled class="ma-0">
                                                                            <a-icon color="grey darken-2">more_time</a-icon>
                                                                        </a-btn>
                                                                    </template>
                                                                    <span>This inventory has been cancelled</span>
                                                                </a-tooltip>
                                                            </template>
                                                            <template v-else-if="inventory_booking.status === 'Returned'">
                                                                <a-tooltip bottom>
                                                                    <template v-slot:activator="{ on }">
                                                                        <a-btn icon small text v-on="on" disabled class="ma-0">
                                                                            <a-icon color="grey darken-2">more_time</a-icon>
                                                                        </a-btn>
                                                                    </template>
                                                                    <span>This inventory has been returned</span>
                                                                </a-tooltip>
                                                            </template>
                                                            <a-menu v-else offset-y bottom right transition="slide-x-transition" :close-on-content-click="false" content-class="u-rounded-corners" :nudge-top="local_inventory_list.length - 1 !== index ? 180 : 230">
                                                                <template v-slot:activator="{ on }">
                                                                    <a-btn icon small v-on="on" @click="local_add_prev_date_range(inventory_booking.status, inventory_booking.booking_from)" class="ma-0">
                                                                        <a-icon color="grey darken-2">more_time</a-icon>
                                                                    </a-btn>
                                                                </template>
                                                                <a-sheet class="u-rounded-corners" style="position: relative">
                                                                    <vc-date-picker :attributes="date_extend_attrs" class="u-rounded-corners elevation-0" v-model="extended_date" :min-date="min_date_to_disable" title-position="left" is-inline></vc-date-picker>
                                                                    <a-btn small class="u-wfull d-block ma-0 blue white--text" v-if="!extended_text" @click="local_inventory_single_extend(inventory_booking.id, 'extend')">Extend</a-btn>
                                                                    <a-btn small class="u-wfull d-block ma-0 green white--text" v-else><a-icon size="16" class="white--text mr-1">check</a-icon> Extended Successfully</a-btn>
                                                                </a-sheet>
                                                            </a-menu>
                                                        </div>
                                                        <div>
                                                            <template v-if="inventory_booking.status === 'In Use'">
                                                                <a-menu offset-y bottom max-width="200" content-class="u-rounded-corners" :nudge-top="local_inventory_list.length - 1 !== index ? 0 : 65">
                                                                    <template v-slot:activator="{ on }">
                                                                        <a-btn v-on="on" icon small text class="ma-0" :loading="local_book_info_loading.type === 'return-booking' && local_book_info_loading.value && local_book_info_loading.id === inventory_booking.id">
                                                                            <a-icon color="grey darken-2">autorenew</a-icon>
                                                                        </a-btn>
                                                                    </template>
                                                                    <div class="pa-3 white text-center">
                                                                        <h3 class="body-2 grey--text text--darken-1">Are you sure you want to return this inventory? <br> <strong>This cannot be undone.</strong></h3>
                                                                    </div>
                                                                    <a-btn small text dark class="orange darken-3 ma-0 u-wfull" :loading="local_book_info_loading.type === 'return-booking' && local_book_info_loading.value && local_book_info_loading.id === inventory_booking.id" @click="local_return_booking(inventory_booking)">Ok, Return</a-btn>
                                                                </a-menu>
                                                            </template>
                                                            <template v-if="inventory_booking.status === 'Booked'">
                                                                <a-menu offset-y bottom max-width="200" content-class="u-rounded-corners" :nudge-top="local_inventory_list.length - 1 !== index ? 0 : 65">
                                                                    <template v-slot:activator="{ on }">
                                                                        <a-btn v-on="on" icon small text class="ma-0" :loading="local_book_info_loading.type === 'cancel-booking' && local_book_info_loading.value && local_book_info_loading.id === inventory_booking.id">
                                                                            <a-icon color="grey darken-2">autorenew</a-icon>
                                                                        </a-btn>
                                                                    </template>
                                                                    <div class="pa-3 white text-center">
                                                                        <h3 class="body-2 grey--text text--darken-1">Are you sure you want to cancel this inventory? <br> <strong>This cannot be undone.</strong></h3>
                                                                    </div>
                                                                    <a-btn small text dark class="orange darken-3 ma-0 u-wfull" :loading="local_book_info_loading.type === 'cancel-booking' && local_book_info_loading.value && local_book_info_loading.id === inventory_booking.id" @click="local_cancel_booking(inventory_booking)">Ok, Cancel</a-btn>
                                                                </a-menu>
                                                            </template>
                                                            <a-tooltip bottom left v-if="inventory_booking.status === 'Returned'">
                                                                <template v-slot:activator="{ on }">
                                                                    <a-btn v-on="on" icon small text class="ma-0" disabled>
                                                                        <a-icon color="grey darken-2">autorenew</a-icon>
                                                                    </a-btn>
                                                                </template>
                                                                <span>This inventory has been returned.</span>
                                                            </a-tooltip>
                                                            <a-tooltip bottom v-if="inventory_booking.status === 'Cancelled'">
                                                                <template v-slot:activator="{ on }">
                                                                    <a-btn v-on="on" icon small text class="ma-0" disabled>
                                                                        <a-icon color="grey darken-2">autorenew</a-icon>
                                                                    </a-btn>
                                                                </template>
                                                                <span>This inventory has been cancelled</span>
                                                            </a-tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </a-card>
                                    </div>
                                </div>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </template>
            </template>

            <!-- Loading Text | No data message -->
            <a-layout wrap align-start v-if="local_loading">
                <a-flex xs12>
                    <a-responsive>
                        <a-divider class="grey lighten-2 mb-4"></a-divider>
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Bookings..</h3>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <a-layout wrap align-start v-if="!local_loading && !inventory_booking_list">
                <a-flex xs12>
                    <a-responsive class="text-center">
                        <a-divider class="grey lighten-2 mb-4"></a-divider>
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Bookings Found</h3>
                        <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now. <br> Try booking new inventory</p>
                        <a-btn small dark class="blue darken-2 caption ma-0 px-2 py-1 mt-2 u-rounded-corners u-elevation-custom-1" :to="{ name: 'inventory-book' }" :exact="true">
                            <a-icon dark size="24" class="mr-1">add</a-icon>
                            Book Inventory
                        </a-btn>
                    </a-responsive>
                </a-flex>
            </a-layout>
        </a-container>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            breadcrumb_items: [ { text: 'Dashboard', to: '/dashboard', exact: true } ],
            booking_detail: null,
            extended_inventories: {
                booking_range: { start: null, end: null },
                booking_ids: []
            },
            date_range_picker: { start: null, end: null },
            extended_date: null,
            bulk_extended_date: null,
            min_date_to_disable: null,
            date_extend_attrs: [],
            extending_type: null,
            local_book_info_loading: { type: null, value: false, id: null },
            local_loading: true,
            extended_text: null,
            add_all_inventory: false,
            dialog_return_error: false,
            tableWidth: 0
        }
    },

    mounted() {
        this.local_index()
        window.addEventListeners('resize', this.localCalculateTableWidth)
    },

    beforeDestroy () {
        window.removeEventListeners('resize', this.localCalculateTableWidth)
    },

    watch: {
        configuration_main_navbar (val) {
            this.localCalculateTableWidth()
        },
    },

    computed: {
        local_date_range_filter: {
            get () {
                return this.date_range_picker
            },
            set (range) {
                this.date_range_picker = range
            }
        },
        local_is_booking_from_before_or_same() {
            if (this.local_inventory_list) {
                const from_date = this.local_inventory_list[0].booking_from
                return moment(from_date).isSameOrBefore(new Date())
            }
            return false
        },
        local_inventory_list() {
            // return this.inventory_booking_list && this.inventory_booking_list.items ? this.inventory_booking_list.items : null
            return this.inventory_booking_list
        },
        local_least_booking_to() {
            return _.sortBy(this.extended_inventories.booking_ids, ['booking_to'])[0]
        },
        local_get_selected_ids() {
            return this.extended_inventories.booking_ids.map(item => item.id)
        },
        ...mapGetters('InventoryBooking', {
            inventory_booking_list: 'batch',
            inventory_booking_response: 'response',
        }),
        ...mapState('Configuration',{
            configuration_main_navbar: 'mainNavbar',
        }),
    },

    methods: {
        async local_index () {
            if (!this.$can('inventories.update')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.inventory_booking_clear()
            await this.local_fetch_batch()

            this.booking_detail = this.inventory_booking_list
            this.local_loading = false
            this.localCalculateTableWidth()
        },
        localCalculateTableWidth () {
            this.$nextTick(() => {
                this.tableWidth = this.$refs.refTableInventories.clientWidth
            })
        },
        async local_fetch_batch() {
            await this.inventory_booking_batch({
                id: this.$route.params.id,
                params: {
                    include: 'project.customer,inventory.category,inventoryTrashed,assignee.role,requester.role'
                }
            })
        },
        local_add_inventory_to_extend(id, from, to, type) {
            this.extending_type = type
            const index = this.extended_inventories.booking_ids.findIndex(item => item.id === id)

            if (index !== -1) return this.local_remove_existing_inventory(index)
            this.extended_inventories.booking_ids.push({ id, booking_to: to })
            this.extended_inventories.booking_range = { start: from, end: this.local_least_booking_to.booking_to }

            this.disable_dates_before_start_date()
        },
        local_remove_existing_inventory(index) {
            this.extended_inventories.booking_ids.splice(index, 1)
            if (!this.extended_inventories.booking_ids.length) {
                this.extending_type = null
                this.extended_inventories.booking_range = { start: null, end: null }
            }
        },
        disable_dates_before_start_date() {
            // const inventory = this.inventory_booking_list.items.find(item => item.id === this.extended_inventories.booking_ids[0].id)
            const inventory = this.inventory_booking_list.find(item => item.id === this.extended_inventories.booking_ids[0].id)
            this.local_set_min_date_extend(inventory.booking_from, inventory.booking_to)
        },
        local_set_min_date_extend(from, to) {
            const date = new Date()
            this.min_date_to_disable = date.setDate(date.getDate())
            this.date_extend_attrs = [
                { key: 'start', highlight: 'gray', dates: date },
            ]
        },
        local_is_added_to_extend(id) {
            return this.extended_inventories.booking_ids.find(item => item.id === id)
        },
        local_add_all_inventory(range, type) {
            if (!range) return this.extended_inventories = { booking_range: { start: null, end: null }, booking_ids: [] }
            this.extended_inventories.booking_ids = this.local_inventory_list.filter(item => item.status !== 'Returned').map(item => ({ id: item.id, booking_to: item.booking_to }))
            this.disable_dates_before_start_date()
        },
        local_add_all_booked_inventory(range, type) {
            if (!range) return this.extended_inventories = { booking_range: { start: null, end: null }, booking_ids: [] }
            this.extended_inventories.booking_ids = this.local_inventory_list.filter(item => item.status !== 'Cancelled').map(item => ({ id: item.id, booking_to: item.booking_to }))
            this.disable_dates_before_start_date()
        },
        local_add_prev_date_range(status, from = this.local_inventory_list[0].booking_from) {
            this.extended_date = null
            this.bulk_extended_date = null
            this.extended_text = null

            if (status === 'Booked') this.min_date_to_disable = new Date(from)
            else this.local_set_min_date_extend()
        },
        async local_inventory_bulk_extend() {
            this.extended_text = null
            const params = { date: this.local_format_date(this.bulk_extended_date, true),  inventory_bookings: this.local_get_selected_ids }

            await this.inventory_booking_extend({ ...params })
            if (this.inventory_booking_response.status === 'success') {
                // await this.local_fetch_batch()
                this.extended_inventories = { booking_range: { start: null, end: null }, booking_ids: [] }
                this.add_all_inventory = false
                this.bulk_extended_date = null
            }
        },
        async local_inventory_single_extend(inventory_id, type) {
            const params = { date: this.local_format_date(this.extended_date, true),  inventory_bookings: [inventory_id]  }

            await this.inventory_booking_extend({ ...params })
            if (this.inventory_booking_response.status === 'success') {
                // await this.local_fetch_batch()
                this.extended_text = 'success'
                this.add_all_inventory = false
                setTimeout(() => this.local_reset_extending_type_text(), 1000);
            }
        },
        local_reset_extending_type_text() {
            this.extended_text = null
            this.extending_type = null
        },
        async local_return_booking(booking) {
            const data = { inventory_ids: [booking.id], today: moment().format('YYYY-MM-DD') }
             await this.inventory_booking_bulk_return({ ...data })
        },
        async local_cancel_booking(booking) {
            const data = { inventory_ids: [booking.id], today: moment().format('YYYY-MM-DD') }
             await this.inventory_booking_bulk_cancel({ ...data })
        },
        async local_bulk_return_booking() {
            this.local_book_info_loading = { type: 'bulk-return', value: true, id: null }

            const data = { inventory_ids: this.local_get_selected_ids, today: moment().format('YYYY-MM-DD') }
            await this.inventory_booking_bulk_return({ ...data })

            if (this.inventory_booking_response.status === 'success') {
                this.add_all_inventory = false
                this.local_book_info_loading = { type: 'bulk-return', value: false, id: null }
            }
        },
        async local_bulk_cancel_booking() {
            this.local_book_info_loading = { type: 'bulk-cancel', value: true, id: null }

            const data = { inventory_ids: this.local_get_selected_ids, today: moment().format('YYYY-MM-DD') }
             await this.inventory_booking_bulk_cancel({ ...data })

            if (this.inventory_booking_response.status === 'success') {
                this.add_all_inventory = false
                this.local_book_info_loading = { type: 'bulk-cancel', value: false, id: null }
            }
        },
        async local_close_return_error_dialog () {
            this.dialog_return_error = false
            await this.inventory_booking_batch({ id: this.$route.params.id })
            this.booking_detail = this.inventory_booking_list
        },
        local_check_same_date_range(from, to) {
            const { booking_range: { start, end } } = this.extended_inventories

            if (!start && !end) return false
            return from !== start || to !== end
        },
        local_date_comparison(start_date) {
            return moment(moment().format('YYYY-MM-DD')).isSame(start_date)
        },
        local_has_extenstion(inventory_id) {
            if (this.inventory_booking_list.extension && this.inventory_booking_list.extension.items) {
                const data = this.inventory_booking_list.extension.items.find(item => item.inventory_id === inventory_id)
                return data && data.status === 'Booked' ? true : false
            }
            return false
        },
        local_format_date(date, range = false, with_time = false, simple = false) {
            if (range) return moment(date).format('YYYY-MM-DD')
            if (simple) return moment(date).format('MMM D')
            if (with_time) return moment(date).format('MMM D, YYYY [at] hh:mm A')

            return date ? moment(date).format('MMM D, YYYY') : null
        },
        local_truncate_text(text, range) {
            if (!text) return

            return text.length > range ? text.slice(0, range) + '...' : text
        },
        ...mapActions('InventoryBooking', {
            inventory_booking_batch: 'batch',
            inventory_booking_extend: 'extend',
            inventory_booking_return: 'booking_return',
            inventory_booking_bulk_return: 'booking_bulk_return',
            inventory_booking_bulk_cancel: 'booking_bulk_cancel',
            inventory_booking_cancel: 'booking_cancel',
            inventory_booking_clear: 'clear',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .c-bookings-header {
        position: relative;
        &::before {
            content: '';
            height: 130px;
            width: 2px;
            background: #d4d4d4;
            position: absolute;
            top: -25px;
            left: 25px;
        }
    }

    .c-extended-title {
        position: relative;
        &::before {
            content: '';
            height: 2px;
            width: 25px;
            background: #d4d4d4;
            position: absolute;
            left: -22px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .c-poc-info-table {
        border-collapse: collapse;
        width: 100%;
        td, th {
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            text-align: left;
            padding: 10px;
            vertical-align: top;
            word-break: break-all;
        }
    }
</style>

<template>
    <a-main>
        <a-responsive class="grey darken-4 mt-5 px-2 u-rounded-corners-lg">
            <a-container grid-list-xl container--fluid class="py-2">
                <a-layout align-start>
                    <a-flex shrink class="px-0">
                        <a-btn v-test-link.meta_result_reasons color="white" text :to="{name: 'settings-metas-results'}" exact>
                            <a-icon class="mr-1">arrow_back</a-icon> Back to project results
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive>
        <a-container container--fluid grid-list-xl class="pa-6 px-0 pt-2 pb-16 mb-16">
            <g-page-header
                :title="(local_meta_label) ? local_meta_label+'\'s Result Reasons' : 'Loading...'"
                subtitle="Create result reasons for the projects."
                icon="list"
                :loading="loading"
                class="pr-1"
            >
            </g-page-header>
            <a-layout>
                <a-flex xs12 pt-0>
                    <a-card class="u-relative u-elevation-custom-1 u-rounded-corners mb-4 pa-0" v-if="check_user_meta_result_reason_permission('update')">
                        <a-form @submit.prevent="local_store()">
                            <a-text-field
                                v-model="local_meta_result_reason"
                                ref="refMetaResultReasonLabel"
                                prepend-inner-icon="add"
                                append-icon="keyboard_return"
                                :loading="page_loading"
                                :placeholder="`Type and hit enter to create result reason`"
                                class="u-rounded-corners"
                                v-test-input.meta_result_reason
                                solo flat hide-details autofocus
                            ></a-text-field>
                        </a-form>
                        <span style="z-index: 2; margin-left: 32px;" :class="[meta_result_reason_response.server.errors.title[0] ? 'c-error-strip__show' : '']" class="c-error-strip u-elevation-custom-1 u-absolute grey lighten-3 px-3 py-1 md-caption red--text text--darken-2 d-block" v-if="meta_result_reason_response.server && meta_result_reason_response.server.errors && meta_result_reason_response.server.errors.title && meta_result_reason_form_mode == 'add'">
                            <a-icon size="16" color="red darken-2" class="u-icon-nudge">error</a-icon>
                            {{ meta_result_reason_response.server.errors.title[0] }}
                        </span>
                    </a-card>
                    <template v-if="!page_loading">
                        <draggable v-model="meta_result_reason_list" :options="{handle: '.js-drag-handle'}">
                            <a-card v-for="meta_result_reason in meta_result_reason_list" class="u-elevation-custom-1 mb-1" :class="[check_user_meta_result_reason_permission('store') || check_user_meta_result_reason_permission('update') || check_user_meta_result_reason_permission('destroy') ? '' : 'py-2' ]" :key="meta_result_reason.id">
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl :class="[check_user_meta_result_reason_permission('update') ? 'py-4 px-6' : 'py-2 px-4']">
                                        <a-layout wrap align-center>
                                            <a-flex shrink class="pl-0 pr-1" v-if="loader_item == meta_result_reason.id">
                                                <a-progress-circular color="blue lighten-2" size="20" width="2" indeterminate></a-progress-circular>
                                            </a-flex>
                                            <template v-else>
                                                <a-flex shrink class="px-0" v-if="check_user_meta_result_reason_permission('update')">
                                                    <a-icon class="grey--text text--lighten-1 u-cursor-pointer js-drag-handle">drag_indicator</a-icon>
                                                </a-flex>
                                            </template>
                                            <a-flex xs10>
                                                <p class="md-subtitle-2 mb-0">{{ meta_result_reason.title }}</p>
                                            </a-flex>
                                            <a-spacer></a-spacer>
                                            <a-flex shrink v-if="check_user_meta_result_reason_permission('update')">
                                                <a-btn v-test-btn.edit v-test-id="meta_result_reason.id" color="grey darken-2" @click="local_edit(meta_result_reason.id)" text icon small class="ml-2">
                                                    <a-icon small>edit</a-icon>
                                                </a-btn>
                                            </a-flex>
                                            <a-flex shrink pl-0 v-if="check_user_meta_result_reason_permission('destroy')">
                                                <a-tooltip bottom v-if="meta_result_reason.project_result_reason_count > 0">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <a-btn color="grey darken-2" text icon small disabled>
                                                                <a-icon small>delete</a-icon>
                                                            </a-btn>
                                                        </div>
                                                    </template>
                                                    <div class="text-center" style="max-width: 280px;">You cannot delete this value, it is associated with a project</div>
                                                </a-tooltip>
                                                <a-btn v-else color="grey darken-2" @click="local_destroy(meta_result_reason.id)" text icon small>
                                                    <a-icon small>delete</a-icon>
                                                </a-btn>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-card-text>
                            </a-card>
                        </draggable>
                    </template>
                </a-flex>
            </a-layout>
            <a-layout v-if="page_loading">
                <a-flex xs12>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching result reasons..</h3>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <a-layout v-if="!page_loading && (meta_result_reason_list && !meta_result_reason_list.length)">
                <a-flex xs12>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                        <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
                    </a-responsive>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Meta MetaResultReason Form -->
        <a-dialog v-model="dialog_meta_result_reason_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>label</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (meta_result_reason_form_mode == 'add') ? 'Create Result Reason' : 'Edit Result Reason' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (meta_result_reason_form_mode == 'add') ? 'You can add new result reason to the system.' : 'You can edit the existing result reasons.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Reason</label>
                                    <a-text-field
                                        v-model="meta_result_reason_item.title"
                                        placeholder="Enter the reason for the result"
                                        background-color="neutral"
                                        solo flat hide-details autofocus
                                        v-test-input.meta_result_reason
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="meta_result_reason_response.server && meta_result_reason_response.server.errors && meta_result_reason_response.server.errors.title && meta_result_reason_form_mode == 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ meta_result_reason_response.server.errors.title[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Enter the result reason.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn v-test-btn.save color="primary" class="ma-0 elevation-0 px-3" @click="local_update()" :loading="loading" :disabled="loading">Save Result Reason</a-btn>
                                    <a-btn v-test-btn.close class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_close()" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { SColorPalette } from '@/config/config-shared-components'

export default {
    name: "MetaResultReasons",
    data() {
        return {
            local_meta_label: '',
            dialog_meta_result_reason_form: false,
            timer_update_debounce: null,
            loader_item: null,
            local_meta_result_reason: null,
            page_loading: true,
        }
    },

    components: { SColorPalette },

    mounted() {
        this.local_index()
    },

    computed: {
        meta_result_reason_list: {
            get() {
                return this.$store.state.MetaResultReason.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },

        ...mapState('Meta', {
            meta_item: 'item',
            meta_form_mode: 'form_mode',
            meta_response: 'response',
        }),

        ...mapState('MetaResultReason', {
            meta_result_reason_item: 'item',
            meta_result_reason_form_mode: 'form_mode',
            meta_result_reason_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            this.page_loading = true
            this.local_show_meta()
            await this.local_load_meta_result_reason_list()
            this.page_loading = false
        },

        async local_load_meta_result_reason_list() {
            await this.meta_result_reason_clear()
            await this.meta_result_reason_index({
                'filter[result_id]': this.$route.params.id,
                'fields[project_result_reasons]': 'id,title,order',
                'include': 'projectResultReasonCount',
            })
        },

        async local_show_meta() {
            await this.meta_clear()
            await this.meta_clear_item()
            await this.meta_show({
                id: this.$route.params.id,
                mode: 'add_update',
                params: {
                    'fields[metas]': 'id,value',
                }
            })
            this.local_meta_label = this.meta_item.value
        },

        async local_meta_result_reason_index () {
            await this.meta_result_reason_index({ 'sort': 'order' })
        },

        // Metas Project Result Reason CRUD
        async local_create() {
            await this.meta_result_reason_clear_item()
            this.dialog_meta_result_reason_form = true
        },

        async local_store() {
            if (!this.local_meta_result_reason || (this.local_meta_result_reason && !this.local_meta_result_reason.trim())) return this.meta_result_reason_clear_item()
            this.meta_result_reason_item.title = this.local_meta_result_reason.trim()
            this.meta_result_reason_item.result_id = this.$route.params.id
            await this.meta_result_reason_store({ ...this.meta_result_reason_item, order: -1, mode: 'only-store' })
            if (this.meta_result_reason_response.status === 'success') {
                await this.meta_result_reason_clear_item()
                this.local_meta_result_reason = ''
            }
        },

        async local_close() {
            await this.meta_result_reason_clear_item()
            this.dialog_meta_result_reason_form = false
        },

        async local_edit(id) {
            await this.meta_result_reason_select({id: id})
            this.dialog_meta_result_reason_form = true
        },

        async local_update(id = null) {
            if (id !== null) await this.meta_result_reason_select({id: id})
            await this.meta_result_reason_update({
                id: this.meta_result_reason_item.id,
                result_id: this.$route.params.id,
                title: this.meta_result_reason_item.title.trim() ?? null,
            })
            if (this.meta_result_reason_response.status === 'success') {
                await this.meta_result_reason_clear_item()
                await this.local_clear_debounce_timer()
                this.dialog_meta_result_reason_form = false
            }
        },

        async local_destroy(id) {
            await this.meta_result_reason_destroy({id: id})
        },

        async local_reorder(list) {
            this.meta_result_reason_reorder({list: list})
        },

        async local_update_debounced(color, meta_result_reason) {
            this.local_clear_debounce_timer()
            if (color) meta_result_reason.color = color
            this.loader_item = meta_result_reason.id
            this.timer_update_debounce = setTimeout(this.local_update, 200, meta_result_reason.id)
        },

        async local_clear_debounce_timer() {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                this.loader_item = null
            }
        },

        async local_close_dialog () {
            this.dialog_meta_result_reason_section_form = false
        },

        // Extras
        local_set_meta_result_reason_input () {
            this.meta_result_reason_label = ''
            setTimeout(() => this.$refs.refMetaResultReasonLabel.ref.focus(), 400);
        },

        check_user_meta_result_reason_permission(type, client_allowed = false) {
            if (this.user_self.role.scope === 'external') {
                return client_allowed
            }
            return this.$can(`metas.${type}`) && this.user_self.role.scope === 'internal'
        },

        ...mapActions('Meta', {
            meta_show: 'show',
            meta_clear: 'clear',
            meta_clear_item: 'clear_item',
        }),

        ...mapActions('MetaResultReason', {
            meta_result_reason_index: 'index',
            meta_result_reason_store: 'store',
            meta_result_reason_update: 'update',
            meta_result_reason_select: 'select',
            meta_result_reason_destroy: 'destroy',
            meta_result_reason_reorder: 'reorder',
            meta_result_reason_clear: 'clear',
            meta_result_reason_clear_item: 'clear_item',
        }),
    },
}
</script>

<style lang="scss" scoped>
.c-error-strip {
    visibility: hidden;
    margin-top: -4px !important;
    transition: visibility 0.4s all;
    border-radius: 0px 0px 25px 25px !important;
    &__show {
        visibility: visible;
        margin-top: 0px !important;
        transition: visibility 0.4s all;
    }
}
</style>

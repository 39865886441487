<template>
    <a-sheet v-bind="$attrs" v-on="$listeners" class="u-flex-center-y px-6 pl-5 blue-grey lighten-4" height="56">
        <a-icon size="20" color="blue-grey darken-1" class="u-icon-nudge" style="top: 1px;">info</a-icon>
        <div class="blue-grey--text text--darken-1 font-weight-medium md-body-2 ml-2">
            <slot name="content">{{ module }} with greater than 1000 characters were not created</slot>
        </div>
        <a-spacer></a-spacer>
        <a-btn small depressed class="transparent blue-grey--text text--darken-3 u-rounded-corners" @click="$emit('cancel')">
            <span class="md-body-2 font-weight-medium">Cancel</span>
        </a-btn>
        <a-btn small depressed class="blue-grey lighten-3 blue-grey--text text--darken-3 ml-2 u-rounded-corners" @click="$emit('update', module)">
            <span class="md-body-2 font-weight-medium">Review & Update</span>
        </a-btn>
    </a-sheet>
</template>

<script>
export default {
    props: {
        module: {
            type: String,
            default: 'Use Cases'
        }
    }
}
</script>

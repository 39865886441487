<template>
    <div class="c-summary-tile pa-5 u-relative u-hfull">
        <loader-latest-note-summary v-if="mixLoading" />

        <template v-else>
            <template v-if="localIsEmpty || !mixHasPermission('notes.index')">
                <partial-empty-state-summary
                    :canView="mixHasPermission('notes.index')"
                    title="Notes"
                    icon="notes"
                    modelSlug="notes"
                    class="pb-4"
                    iconSize="26"
                    iconOutlined
                />
            </template>

            <div v-else class="u-flex flex-column justify-space-between u-hfull u-overflow-hidden">
                <div>
                    <div class="u-flex-center-y">
                        <div class="u-flex-center-y">
                            <a-icon size="20" color="grey darken-2">notes</a-icon>
                            <span class="md-body-2 font-weight-medium grey--text text--darken-2 ml-2">{{ noteItem.parent_type === 'Customer' ? 'Account' : 'Project' }} Notes</span>
                        </div>
                        <template v-if="noteItem.visibility === 'external'">
                            <a-spacer></a-spacer>
                            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right">
                                <template v-slot:activator="{ on }">
                                    <a-icon v-on="on" size="18" color="amber darken-3" outlined>lock_open</a-icon>
                                </template>
                                <span>Visible to external</span>
                            </a-tooltip>
                        </template>
                    </div>

                    <div class="u-overflow-hidden mt-2" ref="refContent">
                        <div class="mb-0 md-body-2 grey--text text--darken-2" style="line-height: 24px;">
                            {{ noteItem.title }}
                        </div>
                    </div>
                </div>

                <partial-bottom-gradient
                    v-if="showExpand"
                    :height="noFetch ? 48 : 120"
                    flat
                />

                <div class="u-flex-center-y mt-2 u-absolute u-wfull px-5" style="bottom: 16px; left: 0px; background-image: linear-gradient(to top, #fff, rgba(255, 255, 255, 0))">
                    <div class="u-flex-center-y">
                        <g-avatar v-if="noteItem.updated_by" :item="noteItem.updated_by" :size="28" no-shadow :rightSpace="false"></g-avatar>
                        <div v-else class="u-border u-rounded-corners-full u-flex-center" style="width: 28px; height: 28px;">
                            <a-icon size="14" color="grey darken-1">person</a-icon>
                        </div>

                        <span
                            :title="noteItem && noteItem.updated_by ? noteItem.updated_by.name : 'Salesforce'"
                            class="md-body-2 grey--text text--darken-2 ml-2 u-font-weight-semibold"
                        >
                            {{ noteItem && noteItem.updated_by ? noteItem.updated_by.name : 'Salesforce' | truncateText(13) }}
                        </span>
                    </div>

                    <div class="u-flex-center-y">
                        <a-icon size="5" color="grey lighten-1" class="mx-2">fiber_manual_record</a-icon>
                        <g-smart-time
                            :timestamp="noteItem.updated_at"
                            contentClass="md-caption grey--text text--darken-1 font-weight-medium"
                        />
                    </div>
                </div>
            </div>
        </template>

        <partial-project-summary-actions
            v-if="mixHasPermission('notes.index') && !mixLoading"
            :showExpand="noFetch ? false : showExpand"
            @expand="$emit('expand')"
            @open="mixOpenInNewWindow('projects-notes')"
            :showActions="noFetch"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { diffHuman, diffYear } from '@/helpers/helper-date-filter'
import MixinModalStateInjection from '../Mixins/MixinModalStateInjection'
import PartialProjectSummaryActions from './PartialProjectSummaryActions'
import PartialBottomGradient from './PartialBottomGradient'
import PartialEmptyStateSummary from './PartialEmptyStateSummary.vue'
import LoaderLatestNoteSummary from '../Loaders/LoaderLatestNoteSummary.vue'

export default {
	components: {
        PartialProjectSummaryActions,
        PartialBottomGradient,
        PartialEmptyStateSummary,
        LoaderLatestNoteSummary
    },

    mixins: [MixinModalStateInjection],

    props: {
        noFetch: {
            type: Boolean,
            default: false
        },
    },

    data () {
        return {
            loading: true,
            showExpand: false,
            noteItem: {}
        }
    },

    computed: {
        localIsEmpty () {
            return !_.size(Object.keys(this.noteItem))
        },

        localDateDiff () {
            return diffYear(this.noteItem.updated_at) > 0
        },

        ...mapState('Note', {
            note_list: 'list'
        }),
    },

    methods: {
        async localIndex () {
            if (!this.mixHasPermission('notes.index')) return
            if (this.noFetch || this.mixLatestSyncTimeDiff() >= 5) {
                return this.localNoFetchSetup()
            }

            this.mixSetLoading()
            await this.localFetchNote()
            this.noteItem = (this.note_list && this.note_list[0]) || {}

            this.mixSaveProject({ note: this.noteItem })
            this.mixClearLoading()
            this.localSetupAction()
        },

        localNoFetchSetup () {
            this.noteItem = this.mixGetSavedSummary.note || {}
            this.localSetupAction()
        },

        localSetupAction () {
            if (this.localIsEmpty || this.mixLoading) return

            this.$nextTick(() => {
                const contentHeight = this.$refs.refContent.clientHeight
                this.showExpand = !!(contentHeight > 48)
            })
        },

        async localFetchNote () {
            await this.note_index({
                include: 'updated_by',
                'fields[type]': 'id,value',
                'filter[project_id]': this.mixParentState.projectId,
                'fields[notes]': 'id,title,updated_at,updated_by_id,parent_type,visibility',
                'fields[updated_by]': 'id,name,avatar,email,scope,is_active,initial,color',
                'filter[latest_notes]': [this.mixParentState.projectId, this.mixParentState?.project?.customer_id],
                sort: '-updated_at',
                count: 1,
            })
        },

        localUpdatedAt (args) {
            const updatedAt = this.noteItem.updated_at
            const { type = null, format = 'MMM D [at] h:mm A', yearFormat = 'MMM D, YYYY [at] h:mm A' } = args || {}
            if (!type) return diffHuman(updatedAt, { format, withYearFormat: yearFormat })

            const diffInDays = moment().diff(moment(updatedAt), 'days')
            const diffInMonths = moment().diff(moment(updatedAt), 'months')
            const diffInYears = moment().diff(moment(updatedAt), 'years')

            if (diffInYears >= 1) return diffInYears + (diffInYears === 1 ? ' year' : ' years')
            if (diffInDays > 90) return diffInMonths + ' months'
            if (diffInDays >= 1 && diffInDays <= 90) return diffInDays + (diffInDays > 1 ? ' days' : ' day')

            const momentObj = _.cloneDeep(moment)
            momentObj.updateLocale('en', {
                relativeTime: {
                    past: '%s',
                    s : '%d secs',
                    ss: '%d secs',
                    m:  "a min",
                    mm: "%d mins",
                    h:  "1 hr",
                    hh: "%d hrs",
                }
            })

            return momentObj.utc(updatedAt).local().fromNow()
        },

        ...mapActions('Note', {
            note_index: 'index'
        }),
    }
}
</script>

<style lang="scss" scoped>

</style>

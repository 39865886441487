const api_endpoint = '/tasks';

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        filters: {
            status: 'active',
            assignee_id: 'all',
            project_id: null,
            milestone_id: null,
            workspace_id: null,
            is_complete: '0',
            page: 1,
            count: 10,
            sort_field: 'order',
            sort_direction: 'asc',
            fields: [],
            attributes: [],
            relationships: [],
            counts: [],
        },
        response: {},
        defaults: {
            list: [],
            item: {},
            meta: {},
            filters: {
                status: 'active',
                assignee_id: 'all',
                project_id: null,
                milestone_id: null,
                workspace_id: null,
                is_complete: '0',
                page: 1,
                count: 10,
                sort_field: 'order',
                sort_direction: 'asc',
                fields: [],
                attributes: [],
                relationships: [],
                counts: [],
            },
            response: {},
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, payload) {
            if (payload.mode === 'clear') return state.item = {...payload.item}
            state.item = { ...state.item, ...payload }
        },
        UPDATE_TASK(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload;
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload.item;
            state.list = [...state.list]
        },
        UPDATE_VISIBILITY(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index].visibility = payload.item.state;
            state.item = state.list[index]
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        PREPEND_ITEM(state, item) {
            state.list.unshift(item);
        },
        APPEND_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            if (index !== -1) state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        CLEAR_RESPONSE(state) {
            state.response = {};
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            let query_filters = _.pickBy(context.state.filters, function (item) {
                return item != '';
            });
            return ext_axios.get(api_endpoint,  { params: payload })
                .then((response) => {
                    if (query_filters.page == 1) {
                        context.commit('UPDATE_LIST', response.data.data);
                    }
                    else {
                        context.commit('APPEND_LIST', response.data.data);
                    }
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        enabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint + '/enabled')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        disabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint + '/disabled')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint + '/' + payload.id, { params: payload.params })
                .then((response) => {
                    if (payload && payload.module === 'Milestone') {
                        context.commit('UPDATE_ITEM', response.data.data);
                    } else {
                        if (payload.mode === 'add') context.commit('APPEND_ITEM', response.data.data);
                        context.commit('UPDATE_ITEM', response.data.data);
                        context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: response.data.data });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.post(api_endpoint, payload)
                .then(async (response) => {
                    if (payload && (payload.milestone_id === null || payload.force_include)) {
                        if (payload.id) await context.dispatch('show', { ...payload, mode: 'add' })
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: response.data.data });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id, payload)
                .then((response) => {
                    context.commit('UPDATE_ITEM', payload);
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: payload });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        assign(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id + '/assign', payload.data)
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    if (payload.destroy_force || payload.milestone_id === null) {
                        context.commit('REMOVE_ITEM', payload.id);
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        complete(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id + '/states/completed', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        state_update(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id + '/states/status', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        task_update(context, payload) {
            context.commit('UPDATE_TASK', payload)
        },

        async new_update ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await ext_axios.patch(api_endpoint + '/' + payload.id, payload)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error?.response?.data?.message, status: 'error', server: error?.response?.data });
            }
        },
        // uncomplete(context, payload) {
        //     this.dispatch('Interface/loader_start')
        //     return ext_axios.patch(api_endpoint + '/' + payload.id + '/uncomplete', {})
        //         .then((response) => {
        //             context.commit('UPDATE_ITEM', response.data.data);
        //             context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('REFRESH_LIST');
        //             context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
        //         })
        //         .catch((error) => {
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
        //         });
        // },
        visibility(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id + '/states/visibility', payload)
                .then((response) => {
                    if(!payload.milestone_id) {
                        context.commit('UPDATE_VISIBILITY', { id: payload.id, item: payload });
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        // client_invisible(context, payload) {
        //     this.dispatch('Interface/loader_start')
        //     return ext_axios.patch(api_endpoint + '/' + payload.id + '/client_invisible', {})
        //         .then((response) => {
        //             context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('REFRESH_LIST');
        //             context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
        //         })
        //         .catch((error) => {
        //             this.dispatch('Interface/loader_stop')
        //             context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
        //         });
        // },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return ext_axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        template_import(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.post(api_endpoint + '/import', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },

        remove ({ commit }, payload) {
            commit('REMOVE_ITEM', payload)
        },

        add ({ commit }, payload) {
            commit('APPEND_ITEM', payload)
        },

        clear_response(context) {
            context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', { item: _.cloneDeep(context.state.defaults.item), mode: 'clear'});
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },
}

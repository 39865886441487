<template>
	<a-main class="">
        <div class="grey darken-4 pa-2 u-rounded-corners-lg">
            <a-btn color="white" text :to="{name: 'appstore-salesforce-workflows'}" exact>
                <a-icon class="mr-1">arrow_back</a-icon> Back to Workflows
            </a-btn>
        </div>

        <div v-if="pageLoading" class="mb-12 mt-8">
            <div class="u-flex-center-y">
                <loader-template height="50" width="100%" class="u-rounded-corners-lg"></loader-template>
                <loader-template height="32" width="60" class="ml-4 u-rounded-corners-full"></loader-template>
            </div>

            <div class="u-flex-center-y mt-8">
                <loader-template height="40" width="120" class="u-rounded-corners-lg"></loader-template>
                <a-spacer></a-spacer>
                <div class="u-flex-center-y">
                    <loader-template height="40" width="140" class="u-rounded-corners-lg"></loader-template>
                    <loader-template height="40" width="140" class="ml-5 u-rounded-corners-lg"></loader-template>
                </div>
            </div>

            <!-- Conditions Loader -->
            <a-sheet v-for="i in 2" :key="i" class="u-shadow u-rounded-corners-lg mt-8">
                <div class="u-flex-center-y px-6 py-5">
                    <loader-template height="50" width="50" class="u-rounded-corners-full"></loader-template>
                    <div class="ml-4">
                        <loader-template height="16" width="140" class="mb-2 u-rounded-corners-lg"></loader-template>
                        <loader-template height="16" :width="Math.floor(Math.random() * 300) + 200" class="u-rounded-corners-lg"></loader-template>
                    </div>
                </div>
                <a-divider></a-divider>
                <div class="px-6 py-5">
                    <div class="u-flex-center-y" style="columnGap: 24px;" v-for="m in 3" :key="m" :class="[{ 'mt-6': m !== 1 }]">
                        <loader-template height="40" style="flex: 0 0 50px;" class="u-rounded-corners-lg"></loader-template>
                        <loader-template height="40" style="flex: 1 0 200px;" class="u-rounded-corners-lg"></loader-template>
                        <loader-template height="40" v-if="i === 1" style="flex: 1 0 200px;" class="u-rounded-corners-lg"></loader-template>
                        <loader-template height="40" style="flex: 1 0 200px;" class="u-rounded-corners-lg"></loader-template>
                        <loader-template height="40" style="flex: 0 0 50px;" class="u-rounded-corners-lg"></loader-template>
                    </div>
                </div>
                <a-divider></a-divider>
                <div class="u-flex-center-y px-6 py-5">
                    <div class="u-flex-center-y">
                        <loader-template height="32" width="140" class="u-rounded-corners-lg"></loader-template>
                        <loader-template height="32" width="140" class="ml-5 u-rounded-corners-lg"></loader-template>
                    </div>
                    <a-spacer></a-spacer>
                    <loader-template height="32" width="100" class="u-rounded-corners-lg"></loader-template>
                </div>
            </a-sheet>
        </div>

        <div v-if="!pageLoading">
            <div class="u-flex-center-y py-8" :style="{ cursor: $can('applets.update') ? 'text' : 'pointer' }" @click.stop="localWorkflowTitleFocus()">
                <div class="u-relative u-flex-center-y" :style="{ minWidth: 'auto', maxWidth: '80%' }">
                    <input
                        v-model="workflowTitle"
                        placeholder="Enter Workflow Title"
                        background-color="transparent"
                        ref="refWorkflowTitle"
                        class="c-workflow-title md-heading-5 grey--text text--darken-3 u-wfull u-absolute"
                        :disabled="!$can('applets.update')"
                        style="left: 0px; transform: translateY(-50%); top: 50%; outline: none;"
                        @input="localUpdateWorkflowTitle('title', 1000)"
                        @click.stop="$can('applets.update') ? showTitleEditIcon = false : {}"
                        @blur.stop="$can('applets.update') ? showTitleEditIcon = true : {}"
                    />
                    <span class="md-heading-5 u-wfull" style="color: transparent; visibility: hidden; white-space: pre; pointer-events: none;">{{ workflowTitle || 'Enter Workflow Title' }}</span>
                    <v-slide-x-transition leave-absolute>
                        <a-icon v-if="showTitleEditIcon && $can('applets.update')" size="20" color="grey darken-3" class="u-absolute" style="right: -32px">drive_file_rename_outline</a-icon>
                    </v-slide-x-transition>
                </div>
                <a-spacer></a-spacer>
                <div style="flex-shrink: 1;">
                    <a-tooltip :disabled="mixinIsLoading('toggle-workflow-state')" bottom v-if="localCheckInvalidConditions">
                        <template v-slot:activator="{ on }">
                            <g-toggle
                                v-on="on"
                                @click="!localAllowedToEnable && $can('applets.update') ? localUpgradePlan() : {}"
                                :loading="mixinIsLoading('toggle-workflow-state')"
                                disabledColor="#d0d0d0"
                                color="green darken-1"
                            />
                        </template>
                        <div class="pa-2 md-body-2" :style="[{ maxWidth: user_self && user_self.is_admin ? '500px' : '600px' }]" v-if="!localAllowedToEnable || localHasInvalidMapping">
                            <div class="u-flex align-start" v-if="!localAllowedToEnable">
                                <GUpgradeIcon size="24" class="mr-3 mt-1" />
                                <span v-if="user_self && user_self.is_admin">Click and upgrade to <strong>Professional</strong> to enable <br> more than one workflow</span>
                                <span v-else>This action is not supported. <br> Click to view more</span>
                            </div>
                            <template v-if="localHasInvalidMapping && localAllowedToEnable">
                                One or more mappings are invalid. <br> Please fix them to toggle workflow status.
                            </template>
                        </div>
                    </a-tooltip>

                    <template v-else>
                        <g-toggle
                            v-model="workflow_item.is_active"
                            @change="localToggleWorkflowState(workflow_item.id, workflow_item.is_active ? '1' : '0')"
                            :disabled="loading || !$can('applets.update')"
                            :loading="mixinIsLoading('toggle-workflow-state')"
                            disabledColor="#d0d0d0"
                            color="green darken-1"
                        ></g-toggle>
                    </template>
                </div>
            </div>

            <div class="px-5 py-4 u-rounded-corners-lg" :class="[!workflow_item.is_active ? 'orange lighten-4' : 'green lighten-4']">
                <div class="u-flex-center-y" v-if="!workflow_item.is_active">
                    <a-icon color="brown darken-2" size="20">info</a-icon>
                    <h3 class="md-subtitle-1 font-weight-medium brown--text text--darken-2 ml-2">This workflow is currently inactive.</h3>
                </div>
                <div class="u-flex-center-y" v-else>
                    <a-icon color="green darken-1" size="20">check_circle</a-icon>
                    <h3 class="md-subtitle-1 font-weight-medium ml-2 green--text text--darken-3">
                        This workflow is currently active and running.
                    </h3>
                </div>
            </div>

            <div class="u-flex-center-y mt-7">
                <a-btn text class="u-flex-center-y u-cursor-pointer u-shadow px-4 py-2 white u-rounded-corners" @click="$router.push({ name: 'appstore-salesforce-logs' })">
                    <a-icon size="20" color="grey darken-1" class="u-icon-nudge" style="top: 1px;">menu_open</a-icon>
                    <span class="md-body-2 grey--text text--darken-1 d-inline-block ml-2 text-uppercase font-weight-medium">View Logs</span>
                </a-btn>
                <a-spacer></a-spacer>
                <div class="u-flex-center-y" v-if="$can('applets.update')">
                    <a-btn text class="u-flex-center-y u-cursor-pointer u-shadow px-4 py-2 white u-rounded-corners mr-5" :disabled="localIsBaseMetaLoading('source')" @click="localFetchWorkflowMeta('source')">
                        <img class="d-block" style="width: 24px; height: 24px; object-fit: contain;" v-if="localGetMappingSource === 'success'" src="../../assets/images/success-imgs/icon-success.svg" alt="Success">
                        <img class="d-block" style="width: 24px; height: 24px; object-fit: contain;" v-else src="../../assets/images/logo-salesforce.svg" alt="Salesforce">
                        <a-icon size="20" color="grey darken-1" class="ml-2" v-if="!localIsBaseMetaLoading('source')">sync</a-icon>
                        <a-progress-circular size="20" width="3" class="ml-2" indeterminate color="orange darken-2" v-else></a-progress-circular>
                        <span class="md-body-2 ml-2 grey--text text--darken-1 d-inline-block text-uppercase font-weight-medium">Refresh{{ localIsBaseMetaLoading('source') ? 'ing' : '' }}</span>
                    </a-btn>
                    <a-btn text class="u-flex-center-y u-cursor-pointer u-shadow px-4 py-2 white u-rounded-corners" :disabled="localIsBaseMetaLoading('destination')" @click="localFetchWorkflowMeta('destination')">
                        <img class="d-block" style="width: 24px; height: 24px; object-fit: contain;" v-if="localGetMappingSource !== 'salesforce'" src="../../assets/images/logo-salesforce.svg" alt="Salesforce">
                        <img class="d-block" style="width: 24px; height: 24px; object-fit: contain;" v-else src="../../assets/images/success-imgs/icon-success.svg" alt="Success">
                        <a-icon size="20" color="grey darken-1" class="ml-2" v-if="!localIsBaseMetaLoading('destination')">sync</a-icon>
                        <a-progress-circular size="20" width="3" class="ml-2" indeterminate color="orange darken-2" v-else></a-progress-circular>
                        <span class="md-body-2 ml-2 grey--text text--darken-1 d-inline-block text-uppercase font-weight-medium">Refresh{{ localIsBaseMetaLoading('destination') ? 'ing' : '' }}</span>
                    </a-btn>
                </div>
            </div>

            <!-- Conditions -->
            <SWorkflowConditionsLayout
                :list="conditionsList"
                :keyItems="localConditionSourceFields"
                :operators="localSourceTypeFilters"
                :picklists="localSourceFieldProperty"
                :sourceFilter="localSourceFilterItemProperty"
                :canUpdate="$can('applets.update')"
                :sourceFields="sourceFields"
                :conditionType="'salesforce_conditions'"
                :baseLoading="localIsBaseMetaLoading('source')"
                :deletedField="deletedField"
                @resetLoading="reset => reset()"
                @upsert="localUpsertConditions"
                @destroy="localBulkDestroyConditions"
                @changes="localCatchConditionChanges"
                @clearFields="localClearDeletedFields"
                @cancelChanges="localShowCancelChangesDialog('conditions')"
                class="mt-8"
            ></SWorkflowConditionsLayout>

            <!-- Mappings -->
            <a-sheet class="u-shadow u-rounded-corners-lg my-16">
                <div class="pa-6 u-flex-center-y">
                    <div class="u-flex-center-y">
                        <a-sheet width="48" height="48" class="u-border-light u-flex-center u-rounded-corners">
                            <img class="d-block" style="width: 32px; height: 32px; object-fit: contain;" src="../../assets/images/logo-salesforce.svg" alt="Salesforce" v-if="workflow_item.action && workflow_item.action.service && workflow_item.action.service.slug === 'salesforce'">
                            <img class="d-block" style="width: 32px; height: 32px; object-fit: contain;" src="../../assets/images/success-imgs/icon-success.svg" alt="Success" v-if="workflow_item.action && workflow_item.action.service && workflow_item.action.service.slug === 'success'">
                        </a-sheet>
                        <div class="ml-5">
                            <h3 class="md-subtitle-1 secondary--text font-weight-bold text-uppercase">Action</h3>
                            <h4 class="md-subtitle-1 grey--text text--darken-3 font-weight-medium" v-if="workflow_item.action">{{ workflow_item.action.label }}</h4>
                        </div>
                    </div>
                    <a-spacer></a-spacer>
                    <a-btn width="180" v-if="!localMappingsLength && $can('applets.update')" depressed class="u-rounded-corners-lg primary font-weight-medium" @click="localAddMapping()">
                        <a-icon color="white" size="20" class="mr-1">add</a-icon>
                        New mapping
                    </a-btn>
                </div>

                <template v-if="localMappingHasRequiredField || (mappingsList && mappingsList.length)">
                    <a-divider></a-divider>
                    <div class="pa-6 pt-5 pb-6">
                        <h3 class="md-body-2 text-uppercase grey--text text--darken-3 font-weight-medium mb-5">Mappings</h3>
                        <div class="u-flex-center-y" :class="[{ 'mt-6': index !== 0 }]" v-for="(mapping, index) in mappingsList" :key="mapping.id">
                            <div class="mr-6" style="width: 50px; height: 50px; flex: 0 0 auto;">
                                <a-tooltip bottom v-if="localGetMappingStatus(mapping)">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" :style="{ backgroundColor: localGetMappingStatus(mapping, 'iconBg') }" class="u-flex-center u-rounded-corners" style="width: 50px; height: 50px;">
                                            <a-icon :color="localGetMappingStatus(mapping, 'iconColor')" size="20">{{ localGetMappingStatus(mapping, 'icon') }}</a-icon>
                                        </div>
                                    </template>
                                    <span>{{ localGetMappingStatus(mapping, 'message') }}</span>
                                </a-tooltip>
                            </div>

                            <div class="white" style="flex: 1 0 200px; zIndex: 2;">
                                <a-tooltip bottom offset-overflow :disabled="!localGetValidationError(mapping, 'value')">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" :class="[localGetValidationError(mapping, 'value') ? 'c-wiggle-short u-border-error' : 'u-border-light']" class="u-flex-center-y u-rounded-corners-lg">
                                            <div v-if="!localFieldIsPicklist(mapping)" class="u-flex-center pl-1" style="width: 44px; height: 40px;">
                                                <template v-if="workflow_item.trigger && workflow_item.trigger.service && workflow_item.trigger.service.slug === 'salesforce'">
                                                    <img src="../../assets/images/logo-salesforce.svg" alt="Salesforce" :class="[{ 'u-opacity-40': localGetMappingStatus(mapping, 'label') === 'deleted' }]" style="display: block; max-width: 24px; max-height: 24px;">
                                                </template>
                                                <template v-if="workflow_item.trigger && workflow_item.trigger.service && workflow_item.trigger.service.slug === 'success'">
                                                    <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" :class="[{ 'u-opacity-40': localGetMappingStatus(mapping, 'label') === 'deleted' }]" style="display: block; max-width: 24px; max-height: 24px;">
                                                </template>
                                            </div>
                                            <a-sheet max-width="84" class="pl-1" style="borderRadius: 6px 0 0 6px;" v-if="localFieldIsPicklist(mapping)">
                                                <a-select
                                                    v-model="mapping.field_source"
                                                    :items="[{ text: 'Success', value: 'success' }, { text: 'Salesforce', value: 'salesforce' }]"
                                                    item-text="text"
                                                    item-value="value"
                                                    class="u-rounded-corners-lg px-0"
                                                    :disabled="localMappingFieldsDisabled || mapping.is_readonly === 1 || localGetMappingStatus(mapping, 'label') === 'deleted'"
                                                    @change="localUpdateMapping(mapping, 'field_source', $event)"
                                                    flat solo hide-details
                                                >
                                                    <template #selection="{ item }">
                                                        <template v-if="item.value === 'salesforce'">
                                                            <img src="../../assets/images/logo-salesforce.svg" alt="Salesforce" :class="[{ 'u-opacity-40': localGetMappingStatus(mapping, 'label') === 'deleted' }]" style="display: block; max-width: 24px; max-height: 24px;">
                                                        </template>
                                                        <template v-if="item.value === 'success'">
                                                            <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" :class="[{ 'u-opacity-40': localGetMappingStatus(mapping, 'label') === 'deleted' }]" style="display: block; max-width: 24px; max-height: 24px;">
                                                        </template>
                                                    </template>
                                                </a-select>
                                            </a-sheet>
                                            <a-divider class="grey lighten-2 mx-1" vertical></a-divider>
                                            <a-autocomplete
                                                v-model="mapping.value"
                                                :items="!localFieldIsPicklist(mapping) ? localMappingSourceFields : localGetSourceValueFields(mapping)"
                                                item-text="label"
                                                item-value="name"
                                                placeholder="Value"
                                                class="u-rounded-corners-lg"
                                                :disabled="localMappingFieldsDisabled || mapping.is_readonly === 1 || localGetMappingStatus(mapping, 'label') === 'deleted'"
                                                @change="localUpdateMapping(mapping, 'value', $event)"
                                                flat solo hide-details
                                            ></a-autocomplete>
                                        </div>
                                    </template>
                                    <span>{{ localGetValidationError(mapping, 'value') }}</span>
                                </a-tooltip>
                            </div>

                            <div class="c-connector u-relative u-flex-center-y grey lighten-1" style="width: 72px; height: 4px;"></div>

                            <div class="white" style="flex: 1 0 200px; zIndex: 2;">
                                <a-tooltip bottom offset-overflow :disabled="!localGetValidationError(mapping, 'field_name')">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" :class="[localGetValidationError(mapping, 'field_name') ? 'c-wiggle-short u-border-error' : 'u-border-light']" class="u-flex-center-y u-rounded-corners-lg">
                                            <div class="u-flex-center pl-1" style="width: 44px; height: 40px;">
                                                <template v-if="workflow_item.action && workflow_item.action.service && workflow_item.action.service.slug === 'salesforce'">
                                                    <img src="../../assets/images/logo-salesforce.svg" alt="Salesforce" :class="[{ 'u-opacity-40': localGetMappingStatus(mapping, 'label') === 'deleted' }]" style="display: block; max-width: 24px; max-height: 24px;">
                                                </template>
                                                <template v-if="workflow_item.action && workflow_item.action.service && workflow_item.action.service.slug === 'success'">
                                                    <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" :class="[{ 'u-opacity-40': localGetMappingStatus(mapping, 'label') === 'deleted' }]" style="display: block; max-width: 24px; max-height: 24px;">
                                                </template>
                                            </div>
                                            <a-divider class="grey lighten-2 mx-1" vertical></a-divider>
                                            <a-autocomplete
                                                v-model="mapping.field_name"
                                                :items="localDestinationFieldFetch(null, localDestinationFieldExcludes(mapping.field_name))"
                                                item-text="label"
                                                item-value="name"
                                                placeholder="Field Name"
                                                class="u-rounded-corners-lg"
                                                :disabled="localMappingFieldsDisabled || mapping.is_readonly === 1 || mapping.is_required === 1 || localGetMappingStatus(mapping, 'label') === 'deleted'"
                                                @change="localUpdateMapping(mapping, 'field_name', $event)"
                                                flat solo hide-details
                                            ></a-autocomplete>
                                        </div>
                                    </template>
                                    <span>{{ localGetValidationError(mapping, 'field_name') }}</span>
                                </a-tooltip>
                            </div>

                            <div class="ml-6" style="width: 50px; height: 50px; flex: 0 0 auto;">
                                <a-tooltip bottom left v-if="mapping.is_required === 1">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="u-border-light u-rounded-corners u-flex-center orange lighten-4" style="height: 50px; width: 50px;">
                                            <a-icon color="orange darken-2" size="22">star</a-icon>
                                        </div>
                                    </template>
                                    <span>Required Field</span>
                                </a-tooltip>
                                <template v-else>
                                    <template v-if="$can('applets.update')">
                                        <a-tooltip bottom left v-if="!localIsMappingDeleted(mapping)" :disabled="mixinIsLoading('update-mappings')">
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on" @click="!mixinIsLoading('update-mappings') ? localRemoveMapping(mapping) : ''" class="u-border-light u-rounded-corners u-flex-center" style="height: 50px; width: 50px; cursor: pointer">
                                                    <a-icon color="grey" size="20">remove_circle_outline</a-icon>
                                                </div>
                                            </template>
                                            <span>Remove</span>
                                        </a-tooltip>
                                        <a-tooltip bottom left v-else :disabled="mixinIsLoading('update-mappings')">
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on" @click="!mixinIsLoading('update-mappings') ? localUndoMappingDelete(mapping) : ''" class="u-border-light u-rounded-corners u-flex-center grey lighten-4" style="height: 50px; width: 50px; cursor: pointer">
                                                    <a-icon color="grey darken-3" size="20">undo</a-icon>
                                                </div>
                                            </template>
                                            <span>Revert</span>
                                        </a-tooltip>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>

                    <a-divider v-if="$can('applets.update')"></a-divider>
                    <div class="pa-6 u-flex align-stretch" v-if="$can('applets.update')">
                        <a-btn height="32" depressed small @click="localSaveMapping()" :loading="mixinIsLoading('update-mappings')" :disabled="!localHasMappingAnyChanges || localIsBaseMetaLoading('source')" class="ma-0 px-2 py-3 indigo darken-1 white--text u-rounded-corners">
                            Save Mappings
                        </a-btn>
                        <a-btn height="32" depressed small @click="localTriggerCancelMappings()" :disabled="!localHasMappingAnyChanges || localIsBaseMetaLoading('source') || mixinIsLoading('update-mappings')" class="ml-4 px-2 py-3 grey--text u-rounded-corners">
                            Cancel Changes
                        </a-btn>
                        <a-sheet v-if="localHasInvalidMapping" class="px-4 u-rounded-corners d-flex align-center orange lighten-5 ml-4">
                            <a-icon size="16px" color="orange darken-1">info</a-icon>
                            <p class="ml-2 font-weight-medium mb-0 orange--text text--darken-1">One or more mappings are invalid</p>
                        </a-sheet>
                        <a-spacer></a-spacer>
                        <a-btn height="32" depressed small :disabled="mixinIsLoading('update-mappings') || localIsBaseMetaLoading('source')" @click="localAddMapping()" class="ma-0 px-2 py-3 primary u-rounded-corners">
                            <a-icon size="16" class="mr-1">add</a-icon>
                            Add Mapping
                        </a-btn>
                    </div>
                </template>
            </a-sheet>
        </div>

        <!-- Cancel Changes Confirmation -->
        <a-dialog v-model="dialogCancelChanges" persistent max-width="400">
            <SCancelChanges
                @success="localCancelUnSavedChanges()"
                @close="dialogCancelChanges = false"
                class="pa-6"
            />
        </a-dialog>

        <!-- Page Leave Confirmation -->
        <a-dialog v-model="dialogLeavePageModal" persistent max-width="400">
            <SCancelChanges
                @success="pageToLeave ? pageToLeave() : {}"
                @close="dialogLeavePageModal = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>

        <!-- User Upgrade Popup -->
        <a-dialog max-width="400" v-model="dialog_user_upgrade_modal">
            <SUserUpgradeModal @close="dialog_user_upgrade_modal = false" />
        </a-dialog>

        <!-- Admin Upgrade Popup -->
        <a-dialog max-width="600" v-model="dialog_admin_upgrade_modal">
            <SAdminUpgradeModal
                :isOpen="dialog_admin_upgrade_modal"
                @upgrade="{}"
                @close="dialog_admin_upgrade_modal = false"
            >
                <template #plan-info-text>
                    <span class="px-3">
                        Your current <span class="secondary--text font-weight-bold">Starter</span> plan doesn’t support enabling more than one workflow at a time.
                    </span>
                </template>
            </SAdminUpgradeModal>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AppSalesforce from '@/plugins/plugin-app-salesforce'
import AppSuccess from '@/plugins/plugin-app-success'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'
import { SUserUpgradeModal, SAdminUpgradeModal, SWorkflowConditionsLayout, SCancelChanges } from '@/config/config-shared-components'

export default {
    mixins: [
        mixinLoading,
        mixinGetOrganizationPlan
    ],

    components: {
        SCancelChanges,
        SUserUpgradeModal,
        SAdminUpgradeModal,
        SWorkflowConditionsLayout
    },

    data () {
        return {
            pageLoading: false,
            dialogCancelChanges: false,
            dialogLeavePageModal: false,
            showTitleEditIcon: true,
            dialog_user_upgrade_modal: false,
            dialog_admin_upgrade_modal: false,
            workflowTitle: null,
            workflowUpdateDebounce: null,
            cancelModuleType: null,
            pageToLeave: null,
            sourceFields: {},
            destinationFields: {},
            metaRefreshModule: [],

            /* Mappings */
            removedMappings: [],
            mappingsListCopy: [],
            mappingsList: [],
            mappingParams: {
                order: 1,
                field_name: null,
                field_source: null,
                value: null,
                type: 'new'
            },

            /* Conditions */
            conditionsListCopy: [],
            conditionsList: [],
            conditionChanges: false,
            clearConditionChangesTrigger: null,
            deletedField: {}
        }
    },

    mounted () {
        this.local_index()
        window.addEventListener('beforeunload', this.localBeforeUnloadEvent)
    },

    beforeDestroy () {
        window.removeEventListener('beforeunload', this.localBeforeUnloadEvent)
    },

    beforeRouteLeave (toRoute, fromRoute, nextRoute) {
        if (!this.conditionChanges && !this.localHasMappingAnyChanges) return nextRoute()

        nextRoute(false)
        this.dialogLeavePageModal = true
        this.pageToLeave = nextRoute
    },

    computed: {
        localWorkflowId () {
            return (this.workflow_item && this.workflow_item.id) || this.$route.params.workflow_id
        },

        localInputTypes () {
            return {
                new: { label: 'new', icon: 'add_circle_outline', iconBg: '#E8EAF6', iconColor: '#3949AB', message: 'New' },
                modified: { label: 'modified', icon: 'drive_file_rename_outline', iconBg: '#E8EAF6', iconColor: '#3949AB', message: 'Modified' },
                deleted: { label: 'deleted', icon: 'delete', iconBg: '#FFEBEE', iconColor: '#E53935', message: 'Deleted' },
                valid: { label: 'valid', icon: 'check_circle', iconBg: '#E8F5E9', iconColor: '#43A047', message: 'Valid' },
                invalid: { label: 'invalid', icon: 'error', iconBg: '#FFF3E0', iconColor: '#FB8C00', message: 'Invalid' },
            }
        },

        localHasMappingAnyChanges () {
            if (this.removedMappings.length) return true

            let hasChanges = false
            this.mappingsList.forEach(mapping => {
                const label = this.localGetMappingStatus(mapping, 'label')
                if (!['valid', 'invalid'].includes(label)) hasChanges = true
            })

            return hasChanges
        },

        localHasInvalidMapping () {
            const index = this.mappingsList.findIndex(c => !c.type && !c.is_valid)
            return index !== -1
        },

        localAllowedToEnable () {
            const planLevel = this.mixinGetOrgPlan({ field: 'subscription_plan.level' })
            const value = (planLevel > 1) || (planLevel === 1 && _.size(this.workflow_list) === 0)

            return !!(value)
        },

        localGetMappingSource () {
            const { trigger } = this.workflow_item || {}
            return (trigger && trigger.service && trigger.service.slug) ?? null
        },

        localGetMappingService () {
            const { action } = this.workflow_item || {}
            return (action && action.service && action.service.slug) ?? null
        },

        localMappingsLength () {
            return this.mappingsList.length
        },

        localMappingFieldsDisabled () {
            return this.mixinIsLoading('update-mappings') || !this.$can('applets.update') || this.localIsBaseMetaLoading('destination')
        },

        localConditionSourceFields () {
            return this.localFetchSourceFields('all', [], { workflowFilterable: true })
        },

        localMappingSourceFields () {
            return this.localFetchSourceFields()
        },

        localMappingHasRequiredField () {
            const index = this.mappingsList.findIndex(i => i.is_required === 1)
            return index !== -1
        },

        localCheckInvalidConditions () {
            return (!this.localAllowedToEnable || this.localHasInvalidMapping || !this.$can('applets.update')) && (this.workflow_item && !this.workflow_item.is_active)
        },

        app_salesforce() {
            return AppSalesforce;
        },

        app_success() {
            return AppSuccess;
        },

        ...mapState('Workflow', {
            workflow_list: 'list',
            workflow_item: 'item',
            workflow_response: 'response',
        }),

        ...mapState('WorkflowMeta', {
            workflow_meta_item: 'item',
            workflow_meta_response: 'response',
        }),

        ...mapState('WorkflowCondition', {
            workflow_condition_list: 'list',
            workflow_condition_item: 'item',
            workflow_condition_filters: 'filters',
            workflow_condition_response: 'response',
        }),

        ...mapState('WorkflowMapping', {
            workflow_mapping_list: 'list',
            workflow_mapping_item: 'item',
            workflow_mapping_filters: 'filters',
            workflow_mapping_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index() {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.$router.replace({ name: 'errors-unauthorized' })
            }

            this.pageLoading = true
            await this.workflow_clear_item()
            await this.workflow_condition_clear()
            await this.workflow_mapping_clear()

            await this.localGetWorkflow()
            await this.localCheckForActiveWorkflows()
            await this.localFetchWorkflowMeta()
            await this.localFetchConditions()
            await this.localFetchMappings()

            this.workflowTitle = _.clone(this.workflow_item.title ?? 'Untitled Workflow')
            this.pageLoading = false
        },

        async localCheckForActiveWorkflows () {
            await this.workflow_index({ 'fields[workflows]': 'id,is_active', 'filter[installation_id]': this.$route.params.id, 'filter[is_active]': 1 })
            const workflowItem = _.pick(this.workflow_item, 'is_condition_source_deleted', 'recent_deleted_sources')
            const recentDeletedItems = workflowItem.recent_deleted_sources
            if (recentDeletedItems !== null) workflowItem.recent_deleted_sources = JSON.parse(recentDeletedItems)
            this.deletedField = _.cloneDeep(workflowItem)
        },

        async localFetchWorkflowMeta (base = null) {
            this.mixinSetLoading('fetch-workflow')
            this.localSetMetaLoading(base)

            if (!base || (base === 'source')) {
                await this.workflow_meta_fetch({ params: { service: this.workflow_item.trigger.service.slug, workflow_id: this.workflow_item.id, resource_type: this.workflow_item.trigger_resource_name, force_server_fetch: !!base }})
                this.sourceFields = _.cloneDeep(this.workflow_meta_item)
            }
            if (!base || (base === 'destination')) {
                await this.workflow_meta_fetch({ params: { service: this.workflow_item.action.service.slug, workflow_id: this.workflow_item.id, resource_type: this.workflow_item.action_resource_name, force_server_fetch: !!base }})
                this.destinationFields = _.cloneDeep(this.workflow_meta_item)
            }

            this.mixinResetLoading('fetch-workflow')
            this.localSetMetaLoading(base)
        },

        localSetMetaLoading (base) {
            const index = this.metaRefreshModule.indexOf(base)
            if (index !== -1) return this.metaRefreshModule.splice(index, 1)
            this.metaRefreshModule.push(base)
        },

        localIsBaseMetaLoading (base) {
            return this.metaRefreshModule.includes(base)
        },

        async localFetchConditions () {
            await this.workflow_condition_index({ 'filter[workflow_id]': this.localWorkflowId })
            this.conditionsList = _.cloneDeep(this.workflow_condition_list)
            this.conditionsListCopy = _.cloneDeep(this.workflow_condition_list)
        },

        async localFetchMappings () {
            await this.workflow_mapping_index({ 'filter[workflow_id]': this.localWorkflowId })
            this.mappingsList = _.cloneDeep(this.workflow_mapping_list)
            this.mappingsListCopy = _.cloneDeep(this.workflow_mapping_list)
        },

        localWorkflowTitleFocus () {
            if (this.$can('applets.update')) this.showTitleEditIcon = false
            this.$refs.refWorkflowTitle.focus()
        },

        async localToggleWorkflowState(id, state) {
            this.mixinSetLoading('toggle-workflow-state')
            await this.workflow_toggle_state({id: id, state: state})
            if (!this.$status(this.workflow_response)) {
                this.workflow_item.is_active = 0
                this.$notify('error', this.$response(this.workflow_response, 'workflow'))
            }
            await this.localCheckForActiveWorkflows()
            this.mixinResetLoading('toggle-workflow-state')
        },

        async localUpdateWorkflow(field) {
            if (field === 'title') {
                await this.workflow_patch({data: {title: this.workflowTitle}})
                if (this.workflow_response.status === 'success') return
                if(this.workflow_response.server && this.workflow_response.server.errors && this.workflow_response.server.errors.title) {
                    this.$notify('error', this.workflow_response.server.errors.title[0])
                }
            }
        },

        localUpdateWorkflowTitle (field, timeout = 2000) {
            window.clearTimeout(this.workflowUpdateDebounce)
            this.workflowUpdateDebounce = window.setTimeout(async () => {
                await this.localUpdateWorkflow(field)
                await this.localGetWorkflow()
                window.clearTimeout(this.workflowUpdateDebounce)
            }, timeout)
        },

        async localClearDeletedFields() {
           await this.workflow_patch({data: {title: this.workflowTitle, recent_deleted_sources: null, is_condition_source_deleted: 0}})
        },

        /* Conditions Crud -- Start */
        async localUpsertConditions (props) {
            await this.workflow_condition_upsert(props)
            await this.localFetchConditions()
            props.resetLoading()
            this.conditionChanges = false
            this.localUpdateWorkflowCondition()
        },

        localUpdateWorkflowCondition() {
            this.deletedField.is_condition_source_deleted = 0
            this.deletedField.recent_deleted_sources = null
            this.workflow_clear_deleted_fields(workflowItem)
        },

        async localBulkDestroyConditions (props) {
            await this.workflow_condition_bulk_destroy(props)
            if (this.conditionChanges) this.conditionChanges = false
        },
        /* Conditions Crud -- End */

        /* Mappings Crud -- Start */
        localAddMapping () {
            const { action } = this.workflow_item || {}
            const params = this.$appendId({
                service: action && action.service && action.service.slug,
                workflow_id: this.localWorkflowId,
                type: 'new',
                ...this.mappingParams
            })
            this.mappingsList.push(params)
        },

        localUpdateMapping (mapping, field, value) {
            let fieldSource = null
            if (field === 'field_source' && !mapping.field_source) fieldSource = value
            if (mapping.field_source) fieldSource = mapping.field_source
            if (field !== 'field_source' && !mapping.field_source) fieldSource = this.localGetMappingSource

            Object.assign(mapping, { field_source: fieldSource })
        },

        localRemoveMapping (mapping) {
            const mappingStatus = this.localGetMappingStatus(mapping, 'label')
            const { field_name, value } = mapping || {}

            if (mappingStatus === 'invalid' || (field_name || _.trim(value))) {
                return this.removedMappings.push(mapping)
            }

            const index = this.mappingsList.indexOf(mapping)
            this.mappingsList.splice(index, 1)
        },

        async localSaveMapping () {
            this.mixinSetLoading('update-mappings')
            this.localRemoveEmptyMappings()

            const list = this.localFilterOurValidMappings()
            await this.localBulkRemoveMappings()
            await this.localBulkAddMappings(list)
            await this.localFetchMappings()
            this.mixinResetLoading('update-mappings')
            this.mappingsListCopy = _.cloneDeep(this.mappingsList)
            this.removedMappings = []
        },

        localRemoveEmptyMappings () {
            const emptyMappingIds = []
            this.mappingsList.forEach(item => {
                if (item.type === 'new' && (!item.field_name && (!_.trim(item.value)))) {
                    emptyMappingIds.push(item.id)
                }
            })
            emptyMappingIds.forEach(id =>
                this.mappingsList.splice(this.mappingsList.findIndex(i => i.id === id), 1)
            )
        },

        localFilterOurValidMappings () {
            return this.mappingsList.filter(item => {
                const index = this.removedMappings.findIndex(c => c.id === item.id)
                return (index === -1 && this.localGetMappingStatus(item) !== 'valid') ? item : null
            })
        },

        async localBulkRemoveMappings () {
            const newMappingsRemoved = this.removedMappings.filter(cond => (cond.type && cond.type === 'new'))
            if (newMappingsRemoved.length) this.localRemoveNewMappings(newMappingsRemoved)

            const removedMappingIds = this.removedMappings.filter(cond => (!cond.type || cond.type !== 'new')).map(cond => cond.id)
            if (!removedMappingIds.length) return

            this.localRemoveMappingsFromList(removedMappingIds)
            await this.workflow_mapping_bulk_destroy({ ids: removedMappingIds, workflow_id: this.localWorkflowId })
        },

        localRemoveNewMappings (newMappingsRemoved) {
            this.localRemoveMappingsFromList(_.map(newMappingsRemoved, 'id'))
        },

        async localBulkAddMappings (list) {
            if (!list.length) return

            const filteredList = list.filter(item => this.localGetMappingStatus(item, 'label') !== 'valid')
            if (!filteredList.length) return

            await this.workflow_mapping_upsert({ data: filteredList, workflow_id: this.localWorkflowId })
        },

        localGetMappingStatus (mapping, field = 'icon') {
            const isDeleted = this.localIndexOfDeletedMapping(mapping) > -1
            const isModified = this.localCheckMappingModified(mapping)
            const statusTypes = this.localInputTypes
            let status = {}

            if (mapping.type === 'new') status = statusTypes['new']
            else if (isModified && !isDeleted) status = statusTypes['modified']
            else if (isDeleted) status = statusTypes['deleted']
            else if (mapping.is_valid) status = statusTypes['valid']
            else if (mapping.hasOwnProperty('is_valid') && !mapping.is_valid) {
                status = { ...statusTypes['invalid'], message: (mapping.error && mapping.error.message) ? mapping.error.message : 'Not Valid' }
            }
            return status[field]
        },

        localCheckMappingModified (mapping) {
            const { field_name, field_source, value } = mapping || {}
            const oldCond = this.mappingsListCopy.find(c => c.id === mapping.id)
            if (!oldCond) return false

            if (oldCond.field_name !== field_name) return true
            if (oldCond.field_source !== field_source) return true
            if (oldCond.value !== value) return true
            return false
        },

        localRemoveMappingsFromList (removedMappingIds) {
            removedMappingIds.forEach(id => {
                const index = this.mappingsList.findIndex(c => c.id === id)
                if (index !== -1) this.mappingsList.splice(index, 1)
            })
        },

        localUndoMappingDelete (mapping) {
            const index = this.localIndexOfDeletedMapping(mapping)
            this.removedMappings.splice(index, 1)
        },

        localIsMappingDeleted (mapping) {
            const index = this.localIndexOfDeletedMapping(mapping)
            return index !== -1
        },

        localIndexOfDeletedMapping (mapping) {
            const index = this.removedMappings.findIndex(c => c.id === mapping.id)
            return index
        },
        /* Mappings Crud -- End */

        /* Cancel Changes -- Start */
        localTriggerCancelMappings () {
            this.cancelModuleType = 'mappings'
            this.dialogCancelChanges = true
        },

        localCancelMappingChanges () {
            this.removedMappings = []
            this.mappingsList = _.cloneDeep(this.mappingsListCopy)
            if (this.dialogCancelChanges) this.dialogCancelChanges = false
        },

        localCatchConditionChanges ({ changes, clearChanges }) {
            this.conditionChanges = !!changes
            this.clearConditionChangesTrigger = clearChanges
        },

        localCancelUnSavedChanges () {
            if (this.cancelModuleType !== 'conditions') return this.localCancelMappingChanges()

            this.clearConditionChangesTrigger()
            this.dialogCancelChanges = false
            this.conditionChanges = false
        },

        localShowCancelChangesDialog (type) {
            this.cancelModuleType = type
            this.dialogCancelChanges = true
        },
        /* Cancel Changes -- End */

        /* Extras -- Start */
        localBeforeUnloadEvent (e) {
            if (this.conditionChanges || this.localHasMappingAnyChanges) {
                return e.returnValue = 'You have unsaved changes. Are you sure you want to leave?'
            }
        },

        localGetValidationError (item, field) {
            if (!item.error || !field) return null
            return item.error[field] && item.error[field][0]
        },

        localFetchSourceFields (field_types = null, excludes = [], filters) {
            const { trigger } = this.workflow_item || {}
            const triggerSlug = trigger && trigger.service && trigger.service.slug
            let local_field_types = _.clone(field_types);

            if (triggerSlug === 'salesforce') {
                if (field_types === 'all') {
                    local_field_types = _.cloneDeep(this.app_salesforce.get_field_types_all())
                }
            }

            if (triggerSlug === 'success') {
                if (field_types === 'all') {
                    local_field_types = _.cloneDeep(this.app_success.get_field_types_all())
                }
            }

            return _.filter(
                        _.map(_.get(this.sourceFields, 'result.fields'), (item) => {
                            const params = {
                                name: item.name,
                                label: item.label,
                                type: item.type,
                                picklistValues: item.picklistValues,
                                workflowRequired: item.workflowRequired,
                                workflowFilterable: item.workflowFilterable,
                                workflowCreateable: item.workflowCreateable,
                                workflowUpdateable: item.workflowUpdateable,
                                include: _.indexOf(excludes, item.name) < 0,
                            }
                            if (local_field_types === null) return { ...params }
                            else if (_.indexOf(local_field_types, item.type) >= 0) {
                                return { ...params, required: !item.nillable }
                            }
                            else return { include: false }
                        }),
                        { include: true, ...filters }
                    );
        },

        localSourceFieldProperty (field_name, property) {
            return _.get(
                        _.find(
                            _.get(this.sourceFields, 'result.fields'),
                            { name: field_name }
                        ),
                        property
                    )
        },

        localSourceTypeFilters (type) {
            if (this.workflow_item.trigger && this.workflow_item.trigger.service.slug === 'salesforce') {
                return this.app_salesforce.get_filters_by_type(type)
            }
            if (this.workflow_item.trigger && this.workflow_item.trigger.service.slug === 'success') {
                return this.app_success.get_filters_by_type(type)
            }
        },

        localSourceFilterItemProperty (field_type, operator, property) {
            if (this.workflow_item.trigger && this.workflow_item.trigger.service.slug === 'salesforce') {
                return this.app_salesforce.get_filters_item_by_field(field_type, operator, property)
            }
            if (this.workflow_item.trigger && this.workflow_item.trigger.service.slug === 'success') {
                return this.app_success.get_filters_item_by_field(field_type, operator, property)
            }
        },

        localDestinationFieldExcludes(keep = null) {
            let field_list = _.map(this.workflow_mapping_list, 'field_name')
            _.remove(field_list, field => field === keep)
            _.remove(field_list, field => field === null)
            return field_list
        },

        localDestinationFieldFetch (field_types = null, excludes = []) {
            let filters = {};
            if (this.workflow_item.action && this.workflow_item.action.intent === 'create') {
                filters = { workflowCreateable: true }
            }
            if (this.workflow_item.action && this.workflow_item.action.intent === 'update') {
                filters = { workflowUpdateable: true }
            }
            return _.filter(
                        _.map(_.get(this.destinationFields, 'result.fields'), (item) => {
                            if (field_types === null) {
                                return { name: item.name, label: item.label, picklistValues: item.picklistValues, required: item.required, type: item.type, workflowFilterable: item.workflowFilterable, workflowCreateable: item.workflowCreateable, workflowUpdateable: item.workflowUpdateable, workflowRequired: item.workflowRequired, include: _.indexOf(excludes, item.name) < 0 }
                            }
                            else if (_.indexOf(field_types, item.type) >= 0) {
                                return { name: item.name, label: item.label, picklistValues: item.picklistValues, required: item.required, type: item.type, workflowFilterable: item.workflowFilterable, workflowCreateable: item.workflowCreateable, workflowUpdateable: item.workflowUpdateable, workflowRequired: item.workflowRequired, include: _.indexOf(excludes, item.name) < 0 }
                            }
                            else {
                                return { include: false }
                            }
                        }),
                        { include: true, ...filters }
                    );
        },

        localFieldIsPicklist ({ field_name }) {
            if (!field_name) return false

            const { action } = this.workflow_item
            const actionSlug = action && action.service && action.service.slug

            if (actionSlug === 'success') return this.localCheckFieldType(field_name, 'type', 'picklist')
            if (actionSlug === 'salesforce') {
                return this.localCheckFieldType(field_name, 'type', 'picklist') &&
                    !this.localCheckFieldType(field_name, 'soapType', 'xsd:boolean')
            }
        },

        localCheckFieldType (field_name, property, slug = null) {
            const resultObj = _.find(_.get(this.destinationFields, 'result.fields'), { name: field_name })
            const value = resultObj ? resultObj[property || 'type'] : null

            if (!slug) return value
            return value === slug
        },

        localGetSourceValueFields (mapping) {
            const { field_name, field_source } = mapping || {}
            const { action } = this.workflow_item
            const actionSlug = action && action.service && action.service.slug

            if (actionSlug === 'salesforce' && field_source === 'salesforce' ||
                actionSlug === 'success' && field_source === 'success'
            ) {
                return this.localCheckFieldType(field_name, 'picklistValues')
            }

            return _.get(this.sourceFields, 'result.fields')
        },

        async localGetWorkflow (params) {
            params = params ?? null
            await this.workflow_show({
                id: (this.workflow_item && this.workflow_item.id) ?? this.$route.params.workflow_id,
            })
        },

        localUpgradePlan (module) {
            const { is_admin } = this.user_self || {}

            this.dialog_admin_upgrade_modal = false
            this.dialog_user_upgrade_modal = false

            if (is_admin === 1) this.dialog_admin_upgrade_modal = true
            else this.dialog_user_upgrade_modal = true
        },
        /* Extras -- End */

        ...mapActions('Workflow', {
            workflow_index: 'index',
            workflow_show: 'show',
            workflow_patch: 'patch',
            workflow_toggle_state: 'toggle_state',
            workflow_enable: 'enable',
            workflow_disable: 'disable',
            workflow_clear_item: 'clear_item',
            workflow_clear_deleted_fields: 'clear_deleted_fields'
        }),
        ...mapActions('WorkflowMeta', {
            workflow_meta_fetch: 'fetch',
            workflow_meta_clear_item: 'clear_item',
        }),
        ...mapActions('WorkflowCondition', {
            workflow_condition_index: 'index',
            workflow_condition_store: 'store',
            workflow_condition_patch: 'patch',
            workflow_condition_upsert: 'upsert',
            workflow_condition_bulk_destroy: 'bulk_destroy',
            workflow_condition_show: 'show',
            workflow_condition_destroy: 'destroy',
            workflow_condition_select: 'select',
            workflow_condition_merge: 'merge',
            workflow_condition_clear: 'clear',
            workflow_condition_clear_item: 'clear_item',
        }),
        ...mapActions('WorkflowMapping', {
            workflow_mapping_index: 'index',
            workflow_mapping_store: 'store',
            workflow_mapping_upsert: 'upsert',
            workflow_mapping_show: 'show',
            workflow_mapping_patch: 'patch',
            workflow_mapping_destroy: 'destroy',
            workflow_mapping_bulk_destroy: 'bulk_destroy',
            workflow_mapping_select: 'select',
            workflow_mapping_merge: 'merge',
            workflow_mapping_clear: 'clear',
            workflow_mapping_clear_item: 'clear_item',
        }),
        ...mapActions('AppletSalesforce', {
            applet_salesforce_object_index: 'object_index',
            applet_salesforce_object_describe: 'object_describe',
            applet_salesforce_clear: 'clear',
            applet_salesforce_clear_item: 'clear_item',
        }),
    },
}
</script>

<style scoped lang="scss">
    .editor-title .v-input__slot {
        padding: 0 !important;
    }
    .editor-title .v-input__control {
        min-height: 51px !important;
    }
    .editor-title input {
        font-family: "Inter", sans-serif !important;
        font-size: 2.125rem !important;
        max-height: 40px !important;
        line-height: 40px !important;
        letter-spacing: 0.0073529412px !important;
    }
    .c-connector {
        &::before {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: #bdbdbd;
            border-radius: 100%;
            left: -6px;
        }
        &::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: #bdbdbd;
            border-radius: 100%;
            right: -6px;
        }
    }
</style>

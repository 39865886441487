<template>
	<div class="c-pie-chart u-relative u-elevation-custom-1 u-rounded-corners-lg white">
        <div class="c-pie-chart__column">
            <VueApexCharts
                ref="pieChart"
                width="100%"
                height="100%"
                :options="chart_options"
                :series="series"
            ></VueApexCharts>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
	name: "PieChart",

    components: { VueApexCharts },

	data: function() {
		return {
            series: [20, 40, 9, 25, 6],
			chart_options: {
                chart: {
                    type: 'pie',
                    toolbar: {
                        show: false,
                    },
                    offsetY: -3,
                },
                grid: {
                    padding: {
                        bottom: 0,
                        right: 0,
                        top: 6,
                        left: 0,
                    }
                },
                states: {
                    active: {
                        filter: {
                            type: "none",
                        },
                    },
                },
                tooltip: {
                    y: {
                        formatter: (value) => {
                            return value.toFixed()
                        },
                    },
                },
                selection: {
                    enabled: false,
                },
                labels: [],
                stroke: {
                    width: 0,
                },
                dataLabels: {
                    style: {
                        fontFamily: "Inter, sans-serif",
                        fontSize: "10px",
                        fontWeight: "700",
                    },
                    dropShadow: {
                        enabled: false,
                    },
                },
                legend: {
                    show: false,
                },
				labels: ["Apple", "Amazon", "Google", "Tesla", "IBM"],
                colors: ["#9C27B0", "#E91E63", "#FF7043", "#42A5F5", "78909C"],
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        donut: {}
                    },
                },
            },
		}
	}
}
</script>

<style scoped>
.c-pie-chart {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 227px;
    width: 272px;
}

.c-pie-chart__column {
    width: 211px;
    height: 211px;
}
</style>
<template>
    <div :style="[{maxHeight: competitor ? `${local_component_height + 10}px` : `${local_component_height}px`, overflow: 'hidden'}]" style="display: flex; flex-wrap: wrap;" class="tag-list" :class="[reference]" :ref="reference">
        <template v-for="(tag, i) in tag_list">                             
            <template v-if="tag.hiddenTags && hidden_tags_list && hidden_tags_list.length" >
                <span class="align-center show-hidden-tags" style="display: flex;" :key="i">
                    <a-menu :close-on-content-click="false" open-on-hover content-class="u-elevation-custom-1 c-border" max-width="420" max-height="400" nudge-left="20" offset-x top >
                        <template v-slot:activator="{ on }">
                            <span 
                                class="grey--text text--darken-1 u-cursor-pointer" 
                                :class="[competitor ? 'u-rounded-corners' : 'u-rounded-corners-full']"
                                style="border: 1px solid #BDBDBD; fontSize: 12px" 
                                :style="[competitor ? {padding: '4px 8px'} : { padding: '1.1px 9px'}]"
                                v-on="on"
                            >
                                +{{ tag.hiddenTagsCount }}
                            </span>
                        </template>
                        <a-card class="u-rounded-corners">
                            <div class="pa-4 white" style="position: sticky; top: 0; white-space: pre-wrap;" >
                                <template v-for="tag in hidden_tags_list" >
                                    <span
                                        :key="tag.id"
                                        :title="local_get_label(tag)"
                                        class="d-inline-flex align-center  u-cursor-pointer my-1"
                                        :class="[competitor ? 'u-rounded-corners' : 'u-rounded-corners-full']"
                                        style="font-size: 12px;max-width: 150px; text-align: center; margin-right: 8px"
                                        :style="[competitor ? {backgroundColor: '#2196f3', color: '#fff', padding: '4px 8px'} : {backgroundColor: $color(tag.color, 'color_bg'), color: $color(tag.color, 'color_text'), padding: '1.3px 8px'}]"
                                    >
                                        <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ local_get_label(tag) }}</span>
                                    </span> 
                                </template>
                            </div>
                        </a-card>
                    </a-menu>
                </span>
                <span :key="i+'empty'" style="flex-basis: 250px; height:22px;"></span>
            </template> 
            <span
                v-else
                :key="tag.id"
                :title="local_get_label(tag)"
                class="d-inline-flex align-center  u-cursor-pointer my-1"
                :class="[competitor ? 'u-rounded-corners' : 'u-rounded-corners-full']"
                style="font-size: 12px;max-width: 150px; text-align: center; margin-right: 8px"
                :style="[competitor ? {backgroundColor: '#2196f3', color: '#fff', padding: '4px 8px'} : {backgroundColor: $color(tag.color, 'color_bg'), color: $color(tag.color, 'color_text'), padding: '1.2px 8px'}]"
            >
                <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ local_get_label(tag) }}</span>
            </span>    
        </template>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: []
        },
        rows: {
            type: String | Number,
            default: "2"
        },
        reference: {
            type: String,
            default: null
        },
        competitor: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            tag_list: [],
            hidden_tags_list: [],
            observer: null,
            TAGS_HEIGHT: 24,
            TAGS_SPACE: 8
        }
    },

    computed: {
        local_component_height() {
            return (this.TAGS_HEIGHT * Number(this.rows)) + (this.TAGS_SPACE * Number(this.rows))
        }
    },

    mounted() {
        this.insert_add_more_button()
        this.initObserver()
    },

    beforeDestroy() {
      if (this.observer) this.observer.unobserve(this.$refs[this.reference])
    },

    methods: {
        onResize(entries) {
            // this.tag_list = _.cloneDeep(this.list)
            this.insert_add_more_button()
        },

        initObserver() {
            const observer = new ResizeObserver(this.onResize)
            const ref = this.reference
            observer.observe(this.$refs[ref])
            this.observer = observer
        },

        insert_add_more_button() {
            // const tagList = document.querySelector('.tag-list')?.children;
            const tagList = this.$refs[this.reference]?.children
            this.tag_list = _.cloneDeep(this.list)
            let i = 0;
            let wrapLocation = null;
            let hiddenItems = [];

            for(let item of tagList) {
                if(item.offsetTop > 60) {
                    if(!wrapLocation) {
                        wrapLocation = i - 1;
                    }
                    hiddenItems.push(item);
                }
                i++;
            }
            if (wrapLocation === this.tag_list.length) return
            this.hidden_tags_list = this.tag_list.slice(wrapLocation)
            
            // if(wrapLocation) this.tag_list.splice(wrapLocation, 0, { name: hiddenItems.length, componentType: 'plus' });
            if(wrapLocation) {
                const first_half = this.tag_list.slice(0,wrapLocation)
                const second_half = this.tag_list.slice(wrapLocation)
                this.tag_list = [...first_half, { hiddenTagsCount: this.hidden_tags_list.length, hiddenTags: true }, ...second_half]
            }
        },

        local_get_label (tag) {
            if (tag && tag.label) return tag.label
            if (tag && tag.name) return tag.name
            if (tag && tag.value) return tag.value
            return tag
        }
    }
}
</script>

<style></style>

<template>
    <v-btn v-bind="$attrs" v-on="$listeners">
        <template v-slot:loader>
            <slot name="loader"></slot>
        </template>
        <slot></slot>
    </v-btn>
</template>

<script>
import { VBtn } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VBtn },
}
</script>

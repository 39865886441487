<template>
    <div
        @click="localClose()"
        class="c-global-search-overlay u-hfull u-wfull u-cursor-pointer"
        style="z-index: 204 !important"
    />
</template>

<script>
import EventBus from '@/config/config-eventbus'

export default {
    mounted () {
        document.addEventListener('keydown', this.localKeyDown)
    },

    beforeDestroy () {
        document.addEventListener('keydown', this.localKeyDown)
    },

    methods: {
        localClose () {
            EventBus.$emit('global-search-dialog', false)
        },

        localKeyDown (evt) {
            if (evt.key === 'Escape') this.localClose()
        }
    }
}
</script>

<template>
    <div class="mb-14">
        <h3 class="mb-6 text-h6 grey--text text--darken-3 font-weight-medium">Meetings</h3>

        <template v-if="localIsGongCallsSyncing">
            <div class="u-flex-center" style="height: calc(100vh - 300px)">
                <div class="u-flex flex-column align-center" style="row-gap: 8px;">
                    <a-icon size="80" color="purple">$ripple_loading</a-icon>
                    <div class="text-center">
                        <h3 class="md-heading-5 grey--text text--darken-2">Syncing</h3>
                        <h3 class="md-body-2 mt-1 grey--text text--darken-2">Fetching calls from Gong, please visit after sometime.</h3>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <div v-if="!isGongIntegrated" class="u-flex-center" style="min-height: calc(100vh - 300px)">
                <div>
                    <img :src="require('../../assets/images/list-empty.svg')" alt="No Meetings Found" class="mx-auto mb-4 u-wfull d-block" style="width: 180px; height: 120px;">
                    <h2 class="mt-4 mb-0 text-center md-heading-6 font-weight-medium grey--text text--darken-4 text-uppercase">No Meetings Found!</h2>
                    <template v-if="$can('applets.index')">
                        <h3 class="mt-1 text-center md-body-2 font-weight-medium grey--text text--darken-2">To get started, please connect with any application below.</h3>
                        <div class="mt-6 u-flex-center" style="column-gap: 16px">
                            <div class="u-border u-rounded-corners">
                                <a-btn tag="a" depressed class="px-3 py-2 transparent" :to="{ name: 'appstore' }">
                                    <img src="../../assets/images/apps/gong/logo.svg" alt="Gong Integration" style="width: 20px; height: 20px;">
                                    <span class="ml-1 d-block md-body-2 text-uppercase font-weight-medium">Connect With Gong</span>
                                </a-btn>
                            </div>
                            <div class="px-3 py-2 u-border u-flex-center-y u-no-select u-rounded-corners u-opacity-60" style="height: 38px;" :to="{ name: 'appstore' }">
                                <img src="../../assets/images/apps/zoom/logo.svg" alt="Gong Integration" style="width: 60px; height: 60px;">
                                <h3 class="ml-1 md-body-2 text-uppercase font-weight-medium">(Coming Soon)</h3>
                            </div>
                        </div>
                    </template>
                    <h3 v-else class="mt-1 text-center md-body-2 font-weight-medium grey--text text--darken-2">
                        Please contact your administrator for more information.
                    </h3>
                </div>
            </div>

            <template v-if="isGongIntegrated">
                <template v-if="pageLoading">
                    <PartialMeetingsLoader moduleType="project" v-for="i in 5" :key="i" />
                </template>

                <div
                    v-if="!pageLoading && (meeting_info_list && !meeting_info_list.gong_config_status)"
                    class="u-flex-center"
                    style="min-height: calc(100vh - 300px)"
                >
                    <div>
                        <img :src="require('../../assets/images/list-empty.svg')" alt="No Meetings Found" class="mx-auto mb-4 u-wfull d-block" style="width: 180px; height: 120px;">
                        <h2 class="mt-4 mb-0 text-center md-heading-6 font-weight-medium grey--text text--darken-4 text-uppercase">No Meetings Found!</h2>
                        <template v-if="$can('applets.index')">
                            <h3 class="mt-1 text-center md-body-2 font-weight-medium grey--text text--darken-2">Looks like you haven’t configured your meeting settings yet.</h3>
                            <div class="mt-6 u-flex-center">
                                <a-btn tag="a" class="px-3 py-2 primary" :to="{ name: 'appstore' }" depressed dark>
                                    <a-icon size="20">login</a-icon>
                                    <span class="ml-2 d-block md-body-2 text-uppercase font-weight-medium">Go to App Store</span>
                                </a-btn>
                            </div>
                        </template>
                        <h3 v-else class="mt-1 text-center md-body-2 font-weight-medium grey--text text--darken-2">
                            Please contact your administrator for more information.
                        </h3>
                    </div>
                </div>

                <template v-else>
                    <template v-if="!pageLoading">
                        <div class="mb-6 u-flex-center-y" style="column-gap: 16px">
                            <!-- Filter open button -->
                            <a-sheet @click="filterModal = true" height="36" v-ripple="true" class="px-3 py-2 u-border u-cursor-pointer u-rounded-corners">
                                <div class="u-flex-center-y" style="column-gap: 4px;">
                                    <a-icon size="20" color="grey darken-2">filter_alt</a-icon>
                                    <span class="md-body-2 font-weight-medium grey--text text--darken-2">Filters</span>
                                </div>
                            </a-sheet>

                            <a-spacer></a-spacer>

                            <!-- Last synced info -->
                            <a-sheet v-if="meeting_info_list && meeting_info_list.gong_last_synced_at" class="py-2 u-flex-center-y u-rounded-corners" height="36" style="column-gap: 8px; width: max-content">
                                <div class="u-flex-center-y" style="column-gap: 4px;">
                                    <a-icon size="20" color="grey darken-2">history</a-icon>
                                    <h4 class="md-body-2 font-weight-medium grey--text text--darken-2">Last synced {{ meeting_info_list.gong_last_synced_at }}</h4>
                                </div>
                            </a-sheet>
                        </div>
                    </template>

                    <!-- No Data Found Message -->
                    <template v-if="!pageLoading && (meeting_list && !meeting_list.length)">
                        <a-responsive class="my-4">
                            <h3 class="mb-1 text-center md-subtitle-1 font-weight-medium grey--text text--darken-1 text-uppercase">No Data Found</h3>
                            <p class="mb-0 text-center md-body-2 grey--text">There is no data to fetch right now.<br>Try narrowing your search.</p>
                        </a-responsive>
                    </template>

                    <template v-if="!pageLoading">
                        <!-- Meetings list -->
                        <div v-if="meeting_list && meeting_list.length" class="u-flex flex-column" style="row-gap: 16px;">
                            <SMeetingCard
                                v-for="(meeting) in meeting_list"
                                :key="meeting.id"
                                :meeting="meeting"
                                :gong-base-url="gongBaseUrl"
                                @openKeywords="localOpenMeetingKeywords(meeting)"
                                moduleType="project"
                                class="px-6 py-4 pl-4 u-rounded-corners u-border u-flex-center-y justify-space-between"
                                style="column-gap: 16px;"
                            />

                            <!-- <div v-if="meeting_meta && meeting_meta.total > 20" class="pl-6 pr-3 u-flex-center-y c-sticky-paginate-parent u-shadow u-rounded-corners"> -->
                            <div class="pl-6 pr-3 u-flex-center-y c-sticky-paginate-parent u-border u-rounded-corners">
                                <div class="mr-2 md-body-2 grey--text text--darken-2 font-weight-medium d-inline-block">
                                    Showing {{ meeting_meta.from }} - {{ meeting_meta.to }} out of {{ meeting_meta.total }} meeting{{ meeting_meta.total > 1 ? 's' : '' }}
                                </div>
                                <a-spacer></a-spacer>
                                <div class="u-flex-center-y" style="column-gap: 16px;">
                                    <a-progress-circular
                                        v-if="mixinIsLoading('pagination')"
                                        width="2"
                                        size="16"
                                        color="orange darken-2"
                                        indeterminate
                                    />
                                    <a-pagination
                                        v-model="meeting_meta.current_page"
                                        :length="meeting_meta.last_page"
                                        :total-visible="10"
                                        class="py-1 c-inventory-pagination"
                                        @input="localPaginate()"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>

                    <PartialMeetingFilterPane
                        moduleType="project"
                        :isOpen="filterModal"
                        :isLoading="mixinIsLoading('filter-data')"
                        @filter="localApplyFilter"
                        @close="filterModal = false"
                        @clear="localFetchMeetings()"
                    />

                    <a-dialog v-model="dialogKeywordsList" max-width="920">
                        <ModalKeywordsDisplay
                            :meeting="selectedMeeting"
                            :isOpen="dialogKeywordsList"
                            @close="localCloseKeywords"
                        />
                    </a-dialog>
                </template>
            </template>
        </template>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { SMeetingCard } from '@/config/config-shared-components'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import loadWorkspace from '@/mixins/mixin-workspace'
import PartialMeetingFilterPane from '../Meetings/Partials/PartialMeetingFilterPane.vue'
import PartialMeetingsLoader from '../Meetings/Partials/PartialMeetingsLoader.vue'
import ModalKeywordsDisplay from '../Meetings/Modals/ModalKeywordsDisplay.vue'
import { diffSimple } from '@/helpers/helper-date-filter';

export default {
    components: { SMeetingCard, PartialMeetingFilterPane, ModalKeywordsDisplay, PartialMeetingsLoader },

    mixins: [loadWorkspace, mixinLoading],

    data () {
        return {
            pageLoading: true,
            isGongIntegrated: true,
            filterModal: false,
            dialogKeywordsList: false,
            currentPageNumber: null,
            gongBaseUrl: '',

            selectedMeeting: {},
            meetingFilters: {},
            meetingPerPageCount: 20,
            MEETING_FILTERS: {
                PAGE: 'page',
                COUNT: 'count',
                PROJECT_ID: 'filter[project_id]',
                WORKSPACE_ID: 'filter[workspace_id]',
                ENDED_AT: 'filter[ended_at]',
                TRACKERS_KEYWORDS: 'filter[trackers_keywords]',
                COLLABORATORS: 'filter[collaborators]',
            },
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localGetProjectId () {
            return this.$route.params.id
        },

        localIsGongCallsSyncing () {
            return this.meeting_info_list && this.meeting_info_list.is_gong_call_manual_syncing
        },

        localHasMeetingFlag () {
            return this.user_self.organization && this.user_self.organization.flag_meetings && this.user_self.organization.flag_meetings === 1
        },

        ...mapState('Meeting', {
            meeting_list: 'list',
            meeting_meta: 'meta',
            meeting_info_list: 'info',
        }),

        ...mapState('Integration', {
            integration_statuses: 'list',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async localIndex () {
            if (!this.localHasMeetingFlag) {
                this.$router.replace({ name: '404' })
            }

            if (!this.$can('project_meetings.index') || !this.$plan('meetings:full-access')) {
                this.$router.replace({ name: 'errors-unauthorized' })
            }

            await this.integration_status_index()
            if (!this.localIntegrationHasAccess('gong')) {
                this.isGongIntegrated = false
                this.pageLoading = false
                return true
            }

            await this.meeting_info_index()
            this.gongBaseUrl = this.meeting_info_list.gong_base_url
            if (this.meeting_info_list && !this.meeting_info_list.gong_config_status) {
                return this.pageLoading = false
            }

            await this.localFetchMeetings()
            this.localFetchFilterData()

            this.pageLoading = false
        },

        localIntegrationHasAccess (slug) {
            return !!this.integration_statuses[slug]
        },

        async localFetchFilterData () {
            this.mixinSetLoading('filter-data')
            await this.gong_users_index()
            await this.gong_trackers_index()
            await this.gong_keywords_index()
            this.mixinResetLoading('filter-data')
        },

        async localFetchMeetings (props) {
            const {
                page = 1,
                count = this.meetingPerPageCount,
                filters = null
            } = props || {}

            this.meetingFilters = { include: 'meetingTrackers.meetingKeywords,meetingParticipants' }
            if (filters) this.localSetupMeetingFilters(filters)

            this.meetingFilters[this.MEETING_FILTERS.PAGE] = page
            this.meetingFilters[this.MEETING_FILTERS.COUNT] = count
            this.meetingFilters[this.MEETING_FILTERS.PROJECT_ID] = this.localGetProjectId
            this.meetingFilters[this.MEETING_FILTERS.WORKSPACE_ID] = this.current_workspace_id_mixin
            await this.meeting_index({ ...this.meetingFilters, module_type: 'project_meetings' })

            this.currentPageNumber = this.meeting_meta.current_page
        },

        localSetupMeetingFilters (filters) {
            const { trackers, keywords, date, collaborators } = filters || {}

            if (_.size(collaborators)) {
                this.meetingFilters[this.MEETING_FILTERS.COLLABORATORS] = collaborators.map(i => i.email).join(',')
            }

            if (date.operator) {
                const dateConstruct = []
                dateConstruct.push(date.operator.value)
                dateConstruct.push(date.from)
                dateConstruct.push(date.to)
                this.meetingFilters[this.MEETING_FILTERS.ENDED_AT] = dateConstruct.filter(Boolean).join(',')
            }

            const list = []
            if (_.size(trackers)) list.push(trackers.map(i => i.name).join(','))
            if (_.size(keywords)) list.push(keywords.map(i => i.name).join(','))
            if (_.size(list)) {
                this.meetingFilters[this.MEETING_FILTERS.TRACKERS_KEYWORDS] = list.join(',')
            }
        },

        async localPaginate () {
            if (this.currentPageNumber === this.meeting_meta.current_page) return
            this.mixinSetLoading('pagination')

            await this.$vuetify.goTo(0)
            await this.localFetchMeetings({ page: this.meeting_meta.current_page })
            this.currentPageNumber = this.meeting_meta.current_page

            this.mixinResetLoading('pagination')
        },

        localOpenMeetingKeywords (meeting) {
            this.selectedMeeting = _.cloneDeep(meeting)
            this.dialogKeywordsList = true
        },

        localCloseKeywords () {
            this.dialogKeywordsList = false
            this.selectedMeeting = {}
        },

        localApplyFilter (filters) {
            this.localFetchMeetings({ filters })
        },

        localFormatDate (date) {
            return date ? diffSimple(date, { format: 'MMM D, YYYY [at] hh:mm A' }) : null
        },

        ...mapActions('Meeting', {
            meeting_index: 'index',
            meeting_info_index: 'info_index',
        }),

         ...mapActions('Integration', {
            integration_status_index: 'statuses',
        }),

        ...mapActions('Gong', {
            gong_users_index: 'users_index',
            gong_trackers_index: 'trackers_index',
            gong_keywords_index: 'keywords_index',
        }),
    }
}
</script>

const api_endpoint = '/tasks';

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        filters: {
            status: 'active',
            assignee_id: 'all',
            project_id: null,
            milestone_id: null,
            workspace_id: null,
            is_complete: '0',
            page: 1,
            count: 10,
            sort_field: 'order',
            sort_direction: 'asc',
            fields: [],
            attributes: [],
            relationships: [],
            counts: [],
        },
        response: {},
        defaults: {
            list: [],
            item: {},
            meta: {},
            filters: {
                status: 'active',
                assignee_id: 'all',
                project_id: null,
                milestone_id: null,
                workspace_id: null,
                is_complete: '0',
                page: 1,
                count: 10,
                sort_field: 'order',
                sort_direction: 'asc',
                fields: [],
                attributes: [],
                relationships: [],
                counts: [],
            },
            response: {},
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, payload) {
            if (payload.mode === 'clear') return state.item = {...payload.item}
            state.item = { ...state.item, ...payload }
        },
        UPDATE_TASK(state, payload) {
            const index = _.findIndex(state.list, { 'id': payload.id });
            if (index === -1) return;

            Object.assign(state.list[index], payload);
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            let list = _.cloneDeep(state.list)

            if (index !== -1) {
                list[index] =  {...list[index], ...payload.item}
                state.list = list
            }
        },
        UPDATE_PROJECT_TASK_LIST(state, payload) {
            payload['taskIds'].forEach(id => {
                let index = _.findIndex(state.list, { 'id': id });
                if (index !== -1) state.list[index]['project'] = payload['projectItem'];
            })
            if (state.item && state.item.id) state.item.project = payload['projectItem']
        },
        UPDATE_VISIBILITY(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index].visibility = payload.item.state;
            state.item = state.list[index]
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        PREPEND_LIST(state, items) {
            state.list = _.union(items, state.list);
        },
        PREPEND_ITEM(state, item) {
            state.list.unshift(item);
        },
        APPEND_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            if (index !== -1) state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        CLEAR_RESPONSE(state) {
            state.response = {};
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        // Project task actions - Start
        async index ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data, meta } } = await axios.get(api_endpoint, { params: payload })
                commit('UPDATE_LIST', data)
                commit('UPDATE_META', meta)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error?.response?.data?.message, status: 'error' })
            }
        },

        async list_update ({ commit }, payload) {
            commit('UPDATE_LIST', payload)
        },

        async show ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data } } = await axios.get(api_endpoint + '/' + payload.id, { params: payload.params })
                if (payload && payload.module === 'Milestone') {
                    commit('UPDATE_ITEM', data);
                } else {
                    if (payload.mode === 'add') {
                        commit('APPEND_ITEM', data);
                        commit('UPDATE_ITEM', data);
                    }
                    if (payload.mode === 'update-list') commit('UPDATE_LIST_ITEM', { id: payload.id, item: data });
                    if (!payload.mode || !['add', 'update-list'].includes(payload.mode)) {
                        commit('UPDATE_ITEM', data);
                        commit('UPDATE_LIST_ITEM', { id: payload.id, item: data });
                    }
                }
                commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: data });
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error?.response?.data?.message, status: 'error' })
            }
        },

        async actionTaskStore () {
            try {

            } catch (error) {

            }
        },

        async update ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.patch(api_endpoint + '/' + payload.id, payload)
                commit('UPDATE_ITEM', payload)
                commit('UPDATE_LIST_ITEM', { id: payload.id, item: payload })
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error?.response?.data?.message, status: 'error', server: error?.response?.data });
            }
        },

        async new_update ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.patch(api_endpoint + '/' + payload.id, payload)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error?.response?.data?.message, status: 'error', server: error?.response?.data });
            }
        },

        async bulk_create ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.post(api_endpoint + '/bulk-store', payload)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error?.response?.data?.message, status: 'error', server: error?.response?.data });
            }
        },

        async bulk_clone ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.post(api_endpoint + '/clone', payload)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error?.response?.data?.message, status: 'error', server: error?.response?.data });
            }
        },

        async bulk_update ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.patch(api_endpoint + '/bulk-update', payload)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error?.response?.data?.message, status: 'error', server: error?.response?.data });
            }
        },

        async bulk_destroy ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.delete(api_endpoint + '/bulk-destroy', { params: payload })
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error?.response?.data?.message, status: 'error', server: error?.response?.data });
            }
        },

        async bulk_visibility ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.patch(api_endpoint + '/bulk-update/states/visibility', payload)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error?.response?.data?.message, status: 'error', server: error?.response?.data });
            }
        },

        async add ({ commit }, payload) {
            commit('APPEND_ITEM', payload)
        },

        add_list ({ commit }, payload) {
            commit('PREPEND_LIST', payload)
        },

        prepend_item ({ commit }, payload) {
            commit('PREPEND_ITEM', payload)
        },

        remove ({ commit }, payload) {
            commit('REMOVE_ITEM', payload)
        },
        // Project task actions - End

        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, payload)
                .then(async (response) => {
                    if (!payload.page || (payload.page && payload.page !== 'dashboard_task')) {
                        if (payload && (payload.milestone_id === null || payload.force_include)) {
                            if (payload.id) await context.dispatch('show', { ...payload, mode: 'add' })
                        }
                    }
                    if (payload.page === 'dashboard_task') context.commit('UPDATE_ITEM', payload);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: response.data.data });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },

        assign(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/assign', payload.data)
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },

        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    if (payload.destroy_force || payload.milestone_id === null) {
                        context.commit('REMOVE_ITEM', payload.id);
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },

        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },

        complete(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/states/completed', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },

        state_update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/bulk-update/states/status', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    // context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },

        task_update(context, payload) {
            context.commit('UPDATE_TASK', payload)
        },

        visibility(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/states/visibility', payload)
                .then((response) => {
                    if(!payload.milestone_id) {
                        context.commit('UPDATE_VISIBILITY', { id: payload.id, item: payload });
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },

        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            if (!payload.type || (payload.type && payload.type !== 'no-local-reorder')) {
                context.commit('UPDATE_LIST', payload.list)
            }
            return axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },

        template_import(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/import', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        task_project_update (context, payload) {
            context.commit('UPDATE_PROJECT_TASK_LIST', payload)
        },
        clear_response(context) {
            context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
        },

        refresh_list(context) {
            context.commit('REFRESH_LIST')
        },

        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },

        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', { item: _.cloneDeep(context.state.defaults.item), mode: 'clear'});
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },
}

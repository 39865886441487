<template>
    <a-sheet class="mt-6 u-flex-center-y yellow darken-4 pa-4 u-rounded-corners">
        <div class="u-flex-center-y">
            <a-icon size="20" color="white">info</a-icon>
            <h3 class="ml-2 white--text md-body-2 font-weight-bold">
                <slot></slot>
            </h3>
        </div>
        <a-spacer></a-spacer>
        <slot name="action">
            <div
                @click="localGotoManageBilling()"
                class="text-white md-body-1 u-flex-center-y c-plan-action u-cursor-pointer"
                style="column-gap: 4px;"
            >
                <span class="md-body-2 font-weight-bold white--text">
                    <slot name="action-text">Manage Billing</slot>
                </span>
                <a-icon size="20" color="white">arrow_forward</a-icon>
            </div>
        </slot>
    </a-sheet>
</template>

<script>
import { ApiUrl } from '@/helpers/helper-api-url-constructor'

export default {
    methods: {
        localGotoManageBilling () {
            window.location.href = ApiUrl('/internal/stripe/configure')
        }
    }
}
</script>

<template>
	<a-main>
        <a-container container--fluid grid-list-xl class="pa-6 px-0 pr-6 mb-12">
            <a-layout align-center justify-center>
                <a-flex xs12>
                    <a-card class="u-border u-rounded-corners" flat>
                        <a-card-text class="pa-0">
                            <a-form>
                                <a-container container--fluid grid-list-xl class="pa-6">
                                    <a-layout wrap align-start>
                                        <a-flex shrink class="pr-1">
                                            <a-avatar class="primary darken-1" size="40">
                                                <a-icon dark>folder</a-icon>
                                            </a-avatar>
                                        </a-flex>
                                        <a-flex>
                                            <h2 class="md-heading-6 primary--text text--darken-1 mb-1">Create New Project</h2>
                                            <p class="md-subtitle-2 mb-0">You can add new project to the pipeline.</p>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                                <a-divider></a-divider>
                                <a-container container--fluid grid-list-xl class="pa-6">
                                    <a-layout wrap align-center>
                                        <a-flex xs12 class="pb-0" v-if="local_active_workspaces && local_active_workspaces.length > 1">
                                            <label class="md-body-2 d-inline-block mb-2 mt-0 font-weight-medium">Workspace <span class="red--text font-weight-bold">*</span></label>
                                            <a-autocomplete
                                                v-test-input.workspace
                                                v-model="project_item.workspace_id"
                                                :items="local_active_workspaces"
                                                item-text="title"
                                                item-value="id"
                                                placeholder="Select Workspace Title"
                                                background-color="neutral"
                                                solo flat hide-details clearable
                                            >
                                            <template v-slot:no-data>
                                                <div class="px-4 py-2">
                                                    <span class="md-body-1" v-if="project_workspace_search != null && project_workspace_search != ''"> <strong>{{ project_workspace_search }}</strong></span>
                                                    <span class="md-body-1 grey--text" v-else>Workspace not found</span>
                                                </div>
                                            </template>
                                            <template v-slot:selection="{ item }">
                                                <span v-if="item === Object(item)" class="md-body-2 d-block py-1 u-rounded-corners text-truncate" >{{ local_truncate(item.title, 120) }}</span>
                                                <span v-else class="md-body-2 d-block text-truncate py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <a-layout align-center class="py-0">
                                                    <a-flex>
                                                        <h5 class="md-subtitle-1" :title="item.title">{{ local_truncate(item.title, 120) }}</h5>
                                                    </a-flex>
                                                </a-layout>
                                            </template>
                                            </a-autocomplete>
                                            <span class="md-caption red--text text--darken-2 d-block mt-2" v-test-label.workspace.error v-if="project_response.server && project_response.server.errors && project_response.server.errors.workspace_id">
                                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                                {{ project_response.server.errors.workspace_id[0] }}
                                            </span>
                                            <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                <a-icon size="16">info</a-icon>
                                                Select workspace from the available list.
                                            </span>
                                        </a-flex>
                                        <a-flex xs12>
                                            <a-layout align-end>
                                                <a-flex class="pb-0">
                                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Account Name <span class="red--text font-weight-bold">*</span></label>
                                                </a-flex>
                                                <a-flex shrink class="pt-0" v-if="$can('customers.store') && (user_self && user_self.role && user_self.role.scope === 'internal')">
                                                    <a-btn v-test-btn.create color="primary lighten-1" class="mr-0" small text @click="local_open_customer_form()">
                                                        <a-icon class="mr-2" left size="16">add</a-icon>Create New Account
                                                    </a-btn>
                                                </a-flex>
                                            </a-layout>
                                            <a-autocomplete
                                                v-test-input.account
                                                v-model="project_item.customer_id"
                                                :items="customer_list"
                                                item-text="name"
                                                item-value="id"
                                                :search-input.sync="customer_search"
                                                placeholder="Select Account by name"
                                                background-color="neutral"
                                                solo flat hide-details clearable
                                                :loading="loading_customer_search"
                                            >
                                                <template v-slot:no-data>
                                                    <div class="px-4 py-2">
                                                        <span class="md-body-1 grey--text">Customer not found</span>
                                                    </div>
                                                </template>
                                            </a-autocomplete>
                                            <span class="md-caption red--text text--darken-2 d-block mt-2" v-test-label.customer.error v-if="project_response.server && project_response.server.errors && project_response.server.errors.customer_id">
                                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                                {{ project_response.server.errors.customer_id[0] }}
                                            </span>
                                            <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                <a-icon size="16">info</a-icon>
                                                Type to select Account by name.
                                            </span>
                                        </a-flex>
                                        <a-flex xs12>
                                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Project Title <span class="red--text font-weight-bold">*</span></label>
                                            <a-text-field
                                                v-test-input.title
                                                v-model="project_item.title"
                                                placeholder="Enter Project Title"
                                                background-color="neutral"
                                                solo flat hide-details
                                            >
                                            </a-text-field>
                                            <span class="md-caption red--text text--darken-2 d-block mt-2" v-test-label.title.error v-if="project_response.server && project_response.server.errors && project_response.server.errors.title">
                                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                                {{ project_response.server.errors.title[0] }}
                                            </span>
                                            <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                <a-icon size="16">info</a-icon>
                                                This can be name of the project.
                                            </span>
                                        </a-flex>
                                        <a-flex xs12>
                                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Description (Optional)</label>
                                            <div class="grey lighten-4 u-border-d u-rounded-corners">
                                                <g-editor-box
                                                    v-test-input.description
                                                    v-model="project_item.description_json"
                                                    :can-update="true"
                                                    :editorClass="['px-4 py-3']"
                                                    :customHeight="220"
                                                    key="editor"
                                                    hideFocus hideBorder hideCloseBtn
                                                ></g-editor-box>
                                            </div>
                                            <template>
                                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-test-label.description.error v-if="local_description_content_error">
                                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                                    {{ local_description_content_error }}
                                                </span>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                    <a-icon size="16">info</a-icon>
                                                    You can summarize and describe the project.
                                                </span>
                                            </template>
                                        </a-flex>
                                        <a-flex xs12>
                                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Deal Amount (Optional)</label>
                                            <a-text-field
                                                v-test-input.dealamount
                                                v-model="project_item.deal_amount"
                                                placeholder="Enter Deal Amount"
                                                background-color="neutral"
                                                solo flat hide-details
                                            >
                                            </a-text-field>
                                            <span class="md-caption red--text text--darken-2 d-block mt-2" v-test-label.dealamount.error v-if="project_response.server && project_response.server.errors && project_response.server.errors.deal_amount">
                                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                                {{ project_response.server.errors.deal_amount[0] }}
                                            </span>
                                            <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                <a-icon size="16">info</a-icon>
                                                You can enter an estimated value to start with.
                                            </span>
                                        </a-flex>
                                        <a-flex xs6>
                                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Estimated Start Date (Optional)</label>
                                            <!-- <a-menu
                                                v-model="datepicker_estimated_start_date"
                                                :close-on-content-click="true"
                                                :nudge-right="40"
                                                min-width="290px"
                                                full-width offset-y lazy
                                            >   <template v-slot:activator="{ on }">
                                                    <a-text-field
                                                        v-model="local_set_date"
                                                        :value="local_format_date(project_item.estimated_start_date)"
                                                        placeholder="Select Date"
                                                        background-color="neutral"
                                                        v-on="on"
                                                        solo flat hide-details clearable readonly
                                                    >
                                                    </a-text-field>
                                                </template>
                                                <a-date-picker v-model="project_item.estimated_start_date" @input="datepicker_estimated_start_date = false" no-title scrollable>
                                                </a-date-picker>
                                            </a-menu> -->
                                            <vc-date-picker
                                                v-test-input.eststartdate
                                                v-model="local_project_dates.estimated_start_date"
                                                class="d-block u-wfull"
                                                :model-config="modelConfig"
                                                @input="local_set_date('estimated_start_date')"
                                            >
                                                <template v-slot="{ togglePopover }">
                                                    <div @click="$can('projects-estimated-start-end-dates.update-only') ? togglePopover({ placement: 'bottom-start' }) : ''">
                                                        <a-tooltip bottom :disabled="$can('projects-estimated-start-end-dates.update-only')">
                                                            <template v-slot:activator="{ on }">
                                                                <a-text-field
                                                                    v-on="on"
                                                                    v-model="local_project_dates.estimated_start_date"
                                                                    :value="local_format_date(project_item.estimated_start_date)"
                                                                    placeholder="Select Date"
                                                                    background-color="neutral"
                                                                    :disabled="!$can('projects-estimated-start-end-dates.update-only')"
                                                                    solo flat hide-details readonly clearable
                                                                >
                                                                </a-text-field>
                                                            </template>
                                                            <span>You don't have sufficient permission to edit this field. Please contact your administrator.</span>
                                                        </a-tooltip>
                                                    </div>
                                                </template>
                                            </vc-date-picker>
                                            <span class="md-caption red--text text--darken-2 d-block mt-2" v-test-label.eststartdate.error v-if="project_response.server && project_response.server.errors && project_response.server.errors.estimated_start_date">
                                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                                {{ project_response.server.errors.estimated_start_date[0] }}
                                            </span>
                                            <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                <a-icon size="16">info</a-icon>
                                                You can select estimated start date of the project.
                                            </span>
                                        </a-flex>
                                        <a-flex xs6>
                                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Estimated End Date (Optional)</label>
                                            <!-- <a-menu
                                                v-model="datepicker_estimated_end_date"
                                                :close-on-content-click="true"
                                                :nudge-right="40"
                                                min-width="290px"
                                                full-width offset-y lazy
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <a-text-field
                                                        v-model="project_item.estimated_end_date"
                                                        :value="local_format_date(project_item.estimated_end_date)"
                                                        placeholder="Select Date"
                                                        background-color="neutral"
                                                        v-on="on"
                                                        solo flat hide-details clearable readonly
                                                    >
                                                    </a-text-field>
                                                </template>
                                                <a-date-picker v-model="project_item.estimated_end_date" @input="datepicker_estimated_end_date = false" no-title scrollable>
                                                </a-date-picker>
                                            </a-menu> -->
                                            <vc-date-picker
                                                v-test-input.estenddate
                                                v-model="local_project_dates.estimated_end_date"
                                                class="d-block u-wfull"
                                                :model-config="modelConfig"
                                                @input="local_set_date('estimated_end_date')"
                                            >
                                                <template v-slot="{ togglePopover }">
                                                    <div @click="$can('projects-estimated-start-end-dates.update-only') ? togglePopover({ placement: 'bottom-start' }) : ''">
                                                        <a-tooltip bottom :disabled="$can('projects-estimated-start-end-dates.update-only')">
                                                            <template v-slot:activator="{ on }">
                                                                <a-text-field
                                                                    v-on="on"
                                                                    v-model="local_project_dates.estimated_end_date"
                                                                    :value="local_format_date(project_item.estimated_end_date)"
                                                                    placeholder="Select Date"
                                                                    background-color="neutral"
                                                                    :disabled="!$can('projects-estimated-start-end-dates.update-only')"
                                                                    solo flat hide-details clearable readonly
                                                                >
                                                                </a-text-field>
                                                            </template>
                                                            <span>You don't have sufficient permission to edit this field. Please contact your administrator.</span>
                                                        </a-tooltip>
                                                    </div>
                                                </template>
                                            </vc-date-picker>
                                            <span class="md-caption red--text text--darken-2 d-block mt-2" v-test-label.estenddate.error v-if="project_response.server && project_response.server.errors && project_response.server.errors.estimated_end_date">
                                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                                {{ project_response.server.errors.estimated_end_date[0] }}
                                            </span>
                                            <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                <a-icon size="16">info</a-icon>
                                                You can select estimated end date of the project.
                                            </span>
                                        </a-flex>
                                        <a-flex xs6>
                                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Start Date (Optional)</label>
                                            <!-- <a-menu
                                                v-model="datepicker_start_date"
                                                :close-on-content-click="true"
                                                :nudge-right="40"
                                                min-width="290px"
                                                full-width offset-y lazy
                                            >   <template v-slot:activator="{ on }">
                                                    <a-text-field
                                                        v-model="project_item.start_date"
                                                        :value="local_format_date(project_item.start_date)"
                                                        placeholder="Select Date"
                                                        background-color="neutral"
                                                        v-on="on"
                                                        solo flat hide-details readonly clearable
                                                    >
                                                    </a-text-field>
                                                </template>
                                                <a-date-picker v-model="project_item.start_date" @input="datepicker_start_date = false" no-title scrollable>
                                                </a-date-picker>
                                            </a-menu> -->
                                            <vc-date-picker
                                                v-test-input.startdate
                                                v-model="local_project_dates.start_date"
                                                class="d-block u-wfull"
                                                :model-config="modelConfig"
                                                @input="local_set_date('start_date')"
                                            >
                                                <template v-slot="{ togglePopover }">
                                                    <div @click="$can('projects-start-end-dates.update-only') ? togglePopover({ placement: 'bottom-start' }) : ''">
                                                        <a-tooltip bottom :disabled="$can('projects-start-end-dates.update-only')">
                                                            <template v-slot:activator="{ on }">
                                                                <a-text-field
                                                                    v-on="on"
                                                                    v-model="local_project_dates.start_date"
                                                                    :value="local_format_date(project_item.start_date)"
                                                                    placeholder="Select Date"
                                                                    background-color="neutral"
                                                                    :disabled="!$can('projects-start-end-dates.update-only')"
                                                                    solo flat hide-details clearable readonly
                                                                >
                                                                </a-text-field>
                                                            </template>
                                                            <span>You don't have sufficient permission to edit this field. Please contact your administrator.</span>
                                                        </a-tooltip>
                                                    </div>
                                                </template>
                                            </vc-date-picker>
                                            <span class="md-caption red--text text--darken-2 d-block mt-2" v-test-label.startdate.error v-if="project_response.server && project_response.server.errors && project_response.server.errors.start_date">
                                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                                {{ project_response.server.errors.start_date[0] }}
                                            </span>
                                            <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                <a-icon size="16">info</a-icon>
                                                You can select start date of the project.
                                            </span>
                                        </a-flex>
                                        <a-flex xs6>
                                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">End Date (Optional)</label>
                                            <!-- <a-menu
                                                v-model="datepicker_due_date"
                                                :close-on-content-click="true"
                                                :nudge-right="40"
                                                min-width="290px"
                                                full-width offset-y lazy
                                            >   <template v-slot:activator="{ on }">
                                                    <a-text-field
                                                        v-model="project_item.due_date"
                                                        :value="local_format_date(project_item.due_date)"
                                                        placeholder="Select Date"
                                                        background-color="neutral"
                                                        v-on="on"
                                                        solo flat hide-details clearable readonly
                                                    >
                                                    </a-text-field>
                                                </template>
                                                <a-date-picker v-model="project_item.due_date" @input="datepicker_due_date = false" no-title scrollable>
                                                </a-date-picker>
                                            </a-menu> -->
                                            <vc-date-picker
                                                v-test-input.enddate
                                                v-model="local_project_dates.due_date"
                                                class="d-block u-wfull"
                                                :model-config="modelConfig"
                                                @input="local_set_date('due_date')"
                                            >
                                                <template v-slot="{ togglePopover }">
                                                    <div @click="$can('projects-start-end-dates.update-only') ? togglePopover({ placement: 'bottom-start' }) : ''">
                                                        <a-tooltip bottom :disabled="$can('projects-start-end-dates.update-only')">
                                                            <template v-slot:activator="{ on }">
                                                                <a-text-field
                                                                    v-on="on"
                                                                    v-model="local_project_dates.due_date"
                                                                    :value="local_format_date(project_item.due_date)"
                                                                    placeholder="Select Date"
                                                                    background-color="neutral"
                                                                    :disabled="!$can('projects-start-end-dates.update-only')"
                                                                    solo flat hide-details clearable readonly
                                                                >
                                                                </a-text-field>
                                                            </template>
                                                            <span>You don't have sufficient permission to edit this field. Please contact your administrator.</span>
                                                        </a-tooltip>
                                                    </div>
                                                </template>
                                            </vc-date-picker>
                                            <span class="md-caption red--text text--darken-2 d-block mt-2" v-test-label.enddate.error v-if="project_response.server && project_response.server.errors && project_response.server.errors.due_date">
                                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                                {{ project_response.server.errors.due_date[0] }}
                                            </span>
                                            <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                <a-icon size="16">info</a-icon>
                                                You can select end date of the project.
                                            </span>
                                        </a-flex>
                                        <a-flex xs12>
                                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Partner Account (Optional)</label>
                                            <a-autocomplete
                                                v-test-input.partner
                                                v-model="project_item.partner_id"
                                                :items="partner_list"
                                                :search-input.sync="local_partner_search"
                                                item-text="name"
                                                item-value="id"
                                                placeholder="Select Partner by name"
                                                background-color="neutral"
                                                :loading="loading_partner_search"
                                                solo flat hide-details clearable
                                            >
                                                <template v-slot:no-data>
                                                    <div class="px-4 py-2">
                                                        <span class="md-body-1 grey--text">Partner not found</span>
                                                    </div>
                                                </template>
                                            </a-autocomplete>
                                            <span class="md-caption red--text text--darken-2 d-block mt-2" v-test-label.partner.error v-if="project_response.server && project_response.server.errors && project_response.server.errors.partner_id">
                                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                                {{ project_response.server.errors.partner_id[0] }}
                                            </span>
                                            <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                <a-icon size="16">info</a-icon>
                                                Type to select Partner by name.
                                            </span>
                                        </a-flex>
                                        <a-flex xs12 v-if="$can('collaborators.store') && (meta_list && meta_list.length)">
                                            <a-checkbox v-test-input.collaborator v-model="add_as_collaborator" hide-details class="pa-0 ma-0 d-inline-block">
                                                <template #label>Add yourself as collaborator</template>
                                            </a-checkbox>
                                            <div v-if="!local_has_project_scope && !add_as_collaborator" class="md-body-2 px-4 py-2 u-rounded-corners mt-4 brown--text text--darken-1 font-weight-medium orange lighten-3">
                                                NOTE: You don't have sufficient permission to view the created project. Check "Add yourself as collaborator" to view the created project.
                                            </div>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                                <a-divider></a-divider>
                                <a-container container--fluid grid-list-xl class="pa-4">
                                    <a-layout wrap align-center>
                                        <a-flex>
                                            <a-btn v-test-btn.cancel color="grey" class="ma-0 elevation-0 pl-0" text :to="{name: 'dashboard'}">
                                                <a-icon left class="mx-2">keyboard_arrow_left</a-icon>
                                                Cancel
                                            </a-btn>
                                        </a-flex>
                                        <a-flex shrink>
                                            <span class="md-caption grey--text text--darken-2 d-block text-xs-right">
                                                You can invite team members to the project in next step.
                                            </span>
                                        </a-flex>
                                        <a-flex shrink>
                                            <a-menu :nudge-bottom="40" v-if="(!local_has_project_scope && !add_as_collaborator && $can('collaborators.store')) || (meta_list && !meta_list.length)">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn v-on="on" color="primary" class="ma-0 elevation-0 px-3" :loading="loading" :disabled="loading">Save Project</a-btn>
                                                </template>
                                                <a-sheet max-width="410" class="pa-5">
                                                    <span style="letter-spacing: 0.6px" class="u-flex align-center md-caption font-weight-medium ml-n1 mb-1 text-uppercase orange--text text--darken-3">
                                                        <a-icon size="18" class="mr-1" color="orange darken-3">info</a-icon>
                                                        Note
                                                    </span>
                                                    <h4 class="md-subtitle-1 grey--text text--darken-3">
                                                        <template v-if="meta_list && meta_list.length">
                                                            Since you have not added yourself as a collaborator, you will be redirected to dashboard after the project is created.
                                                        </template>
                                                        <template v-else>
                                                            We couldn't add you as a collaborator in this project, hence it will not be visible to you.
                                                            <div class="mt-2 md-caption font-weight-medium grey--text text--darken-1">You will be redirected to dashboard after the project is created.</div>
                                                        </template>
                                                    </h4>
                                                    <a-btn small color="primary" class="ma-0 mt-3 elevation-0 px-3" @click="local_project_store()">Save Project</a-btn>
                                                </a-sheet>
                                            </a-menu>
                                            <a-btn v-else v-test-btn.save color="primary" class="ma-0 elevation-0 px-3" @click="local_project_store()" :loading="loading" :disabled="loading">Save Project</a-btn>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-form>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog v-model="dialog_customer_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>work</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (customer_form_mode == 'add') ? 'Create New Account' : 'Edit Existing Account' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (customer_form_mode == 'add') ? 'You can add new account to manage projects.' : 'You can edit existing account information.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="py-2 px-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                    <a-text-field
                                        v-model="customer_item.name"
                                        placeholder="Enter Account Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                        v-test-input.name
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="customer_response.server && customer_response.server.errors && customer_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ customer_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the account.
                                    </span>
                                </a-flex>
                            </a-layout>
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Domain</label>
                                    <a-text-field
                                        v-model="customer_item.domain"
                                        placeholder="Enter the Domain"
                                        background-color="neutral"
                                        solo flat hide-details
                                        v-test-input.domain
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="customer_response.server && customer_response.server.errors && customer_response.server.errors.domain">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ customer_response.server.errors.domain[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The domain will be helpful to identify the account.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn v-test-btn:accounts.save color="primary" class="ma-0 elevation-0 px-3" @click="local_customer_store()" :loading="loading" :disabled="loading">Save Account</a-btn>
                                    <a-btn v-test-btn:accounts.cancel class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_customer_form = !dialog_customer_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import loadWorkspace from '@/mixins/mixin-workspace'
import TagPermission from '@/mixins/mixin-tag-permission'

export default {
    name: 'ProjectForm',
    mixins: [loadWorkspace, TagPermission],
    data() {
        return {
            dialog_project_form: false,
            datepicker_start_date: false,
            datepicker_due_date: false,
            datepicker_estimated_start_date: false,
            datepicker_estimated_end_date: false,
            dialog_customer_form: false,
            add_as_collaborator: true,
            project_label_search: null,
            project_product_search: null,
            project_platform_search: null,
            project_territory_search: null,
            project_workspace_search: null,
            territory_exist_message: false,
            local_default_workspace_item: '',
            local_load: false,
            customer_search: null,
            loading_customer_search: false,
            customer_filter: {
                FIELDS: 'fields[customers]',
                NAME: 'filter[name]',
                SORT: 'sort',
                COUNT: 'count',
            },
            local_customer_filters: {},
            local_project_description: '',
               partner_filter: {
                FIELDS: 'fields[partners]',
                NAME: 'filter[name]',
                SORT: 'sort',
                COUNT: 'count',
            },
            loading_partner_search: false,
            local_partner_filters: {},
            local_partner_search: null,
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
            local_project_dates: {
                start_date: null,
                due_date: null,
                estimated_start_date: null,
                estimated_end_date: null
            }
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        local_description_content_error() {
            if (this.project_response && this.project_response.status !== 'error') return null

            let response = this.$response(this.project_response, 'description_json')
            if (response) return response
            if (
                !this.$response(this.project_response, 'title') &&
                !this.$response(this.project_response, 'workspace_id') &&
                !this.$response(this.project_response, 'customer_id') &&
                !this.$response(this.project_response, 'deal_amount') &&
                !this.$response(this.project_response, 'estimated_end_date') &&
                !this.$response(this.project_response, 'due_date')
            ) {
                return 'Description update failed.'
            }
        },
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Projects', to: '/projects', exact: true})
            breadcrumbs.push({text: 'Add New Project', to: '/projects/add', exact: true})
            return breadcrumbs
        },
        local_active_workspaces() {
            return this.workspace_board_list.filter(item => item.is_active)
        },
        local_has_project_scope () {
            const scope = this.scope_list.find(scope => scope.key === 'collaborator')
            return scope ? scope.value === 'all' : true
        },
        ...mapState('Project', {
            project_item: 'item',
            project_form_mode: 'form_mode',
            project_response: 'response',
        }),
        ...mapState('Customer', {
            customer_list: 'list',
            customer_item: 'item',
            customer_form_mode: 'form_mode',
            customer_response: 'response',
        }),
        ...mapState('Territory', {
            territory_list: 'list',
            territory_item: 'item',
            territory_filters: 'filters',
            territory_response: 'response',
        }),
        ...mapState('Partner', {
            partner_list: 'list',
        }),
        ...mapState('Tag', {
            tag_list: 'list',
            tag_item: 'item',
            tag_filters: 'filters',
        }),
        ...mapState('Workspace', {
            workspace_board_list: 'board_list',
            workspace_item: 'item',
            workspace_response: 'response',
            workspace_filters: 'filters',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Scope', {
            scope_list: 'list',
        }),
        ...mapState('Meta', {
            meta_list: 'list',
        }),
        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        })
    },
    watch: {
        workspace_current(oldValue, newValue) {
            this.local_index()
        }
    },
    methods: {
        async local_index() {
            if (this.$can('projects.store') === false) {
                this.$router.push({name: 'errors-unauthorized'})
            }
            this.meta_index({ 'filter[type]': 'collaborator_type', 'filter[status]': 'Team Member' })
            await this.project_clear_item()
            await this.project_clear_related_item()
            this.project_item.workspace_id = this.current_workspace_id_mixin
            this.local_load_form_data()
            this.local_scope_index()
        },
        async local_load_form_data() {
            this.load_load_workspace()
            this.local_load_customer_list()
            this.local_load_partner_list()
            this.local_load_tag_list()
           // this.local_load_territories_list()
        },
        async local_load_customer_list() {
            await this.customer_clear()
            this.local_customer_filters[this.customer_filter.FIELDS] = 'id,name'
            this.local_customer_filters[this.customer_filter.SORT] = 'name'
            this.local_customer_filters[this.customer_filter.COUNT] = 9999
            await this.customer_index(this.local_customer_filters)
        },
        async local_search_customer_list() {
            if (!this.customer_search || !this.customer_search.trim()) {
                await this.customer_clear()
                return
            }
            this.loading_customer_search = true
            this.local_customer_filters[this.customer_filter.NAME] = this.customer_search
            this.local_customer_filters[this.customer_filter.FIELDS] = 'id,name'
            this.local_customer_filters[this.customer_filter.SORT] = 'name'
            this.local_customer_filters[this.customer_filter.COUNT] =  99
            this.customer_search ? await this.customer_search_index(this.local_customer_filters) : await this.customer_clear()
            this.loading_customer_search = false
        },
        async local_load_tag_list() {
            await this.tag_clear()
            await this.tag_index({
                'filter[type]' : 'label,product,platform,territory'
            })
        },
        async local_scope_index() {
            this.scope_index({
                'filter[resource_id]': this.user_self.role_id,
                'filter[resource_type]': 'Role',
                'filter[type]': 'project',
            })
        },
        async local_load_territories_list() {
            await this.territory_clear()
            await this.territory_index()
        },
        async local_load_partner_list() {
            await this.partner_clear()
            this.local_partner_filters[this.partner_filter.FIELDS] = 'id,name'
            this.local_partner_filters[this.partner_filter.SORT] = 'name'
            this.local_partner_filters[this.partner_filter.COUNT] = 9999
            await this.partner_index(this.local_partner_filters)
        },
        async local_search_partner_list() {
            if (!this.local_partner_search || !this.local_partner_search.trim()) {
                await this.partner_clear()
                return
            }
            this.loading_partner_search = true
            this.local_partner_filters[this.partner_filter.NAME] = this.local_partner_search
            this.local_partner_filters[this.partner_filter.FIELDS] = 'id,name'
            this.local_partner_filters[this.partner_filter.SORT] = 'name'
            this.local_partner_filters[this.partner_filter.COUNT] = this.local_partner_search ? 99 : 20
            this.local_partner_search ? await this.partner_search_index(this.local_partner_filters) : await this.partner_clear()
            this.loading_partner_search = false
        },
        async load_load_workspace() {
            this.workspace_filters.is_active = 1
            await this.workspace_board()
        },
        async local_project_store() {
            this.local_add_as_collab_project()
            await this.project_store(this.project_item)
            if (this.project_response.status === 'success' && this.project_item.workspace_id === this.current_workspace_id_mixin) {
                if (this.local_has_project_scope || (!this.local_has_project_scope && this.add_as_collaborator)) return this.$router.push({name: 'projects-overview', params: {id: this.project_item.id} })
                else return this.$router.push({ name: 'dashboard' })
            }
            if (this.project_response.status !== 'success') return this.$notify('error', 'You have some error, please re-check the fields.')
            this.$router.push({ name: 'dashboard' })
        },
        local_add_as_collab_project () {
            if (this.$can('collaborators.store')) this.project_item.add_as_collaborator = this.add_as_collaborator ? 1 : 0
            else this.add_as_collaborator = false
        },
        async local_customer_store() {
            await this.customer_store({ type: 'add', ...this.customer_item })
            if (this.customer_response.status === 'success') {
                this.dialog_customer_form = false
                this.project_item.customer_id = this.customer_list[0].id
            }
        },
        local_open_customer_form() {
            this.dialog_customer_form = !this.dialog_customer_form
            this.customer_clear_item()
        },
        async local_project_label_store(search_value, labels, permission = true) {
            const params = { type: 'Label', search_value, key: 'labels' }

            await this.local_check_permission_store_tag(params, permission, labels)
            if (search_value !== null) this.project_label_search = null
        },
         async local_project_product_store(search_value, products, permission = true) {
             const params = { type: 'Product', search_value, key: 'products' }

            await this.local_check_permission_store_tag(params, permission, products)
            if (search_value !== null) this.project_product_search = null
         },
        async local_project_platform_store(search_value, platforms, permission = true) {
            const params = { type: 'Platform', search_value, key: 'platforms' }

            await this.local_check_permission_store_tag(params, permission, platforms)
            if (search_value !== null) this.project_platform_search = null
        },
        async local_check_permission_store_tag(params, permission, tags) {
            if (permission) await this.local_store_tags(params)
            else await this.local_add_tags_to_project(params, tags)
        },
        async local_store_tags(params, permission = true, tags) {
            if (params.search_value && params.search_value.trim()) {
                await this.local_create_tag(params.type, params.search_value)
                this.project_item[params.key].push(this.tag_item)
            }
            this.project_item[params.key] = this.project_item[params.key].filter(item => typeof item === 'object')
            this.tag_clear_item();
        },
        local_add_tags_to_project(params, tags) {
            tags.forEach(id => this.project_item[params.key].push(this.local_fetch_tag(this.tag_list, id)))
            this.project_item[params.key] = this.project_item[params.key].filter(item => typeof item === 'object')
            this.tag_clear_item();
        },
        async local_create_tag(type, search_value) {
            this.tag_item.label = search_value
            this.tag_item.type = type
            await this.tag_store();
        },
        local_fetch_tag(list, id) {
            return list.find(i => i.id === id)
        },
        async local_project_territory_store() {
            if (this.project_territory_search === null) return

            let territory_exist = this.territory_list.filter((item) => item.name.split(' ').join('-').toLowerCase() === this.project_territory_search.split(' ').join('-').toLowerCase())

            if (territory_exist.length) {
                this.project_territory_search = null
                this.territory_exist_message = !this.territory_exist_message
                setTimeout(() => {
                    this.territory_exist_message = false
                }, 5000);
                return
            }

            this.territory_item.name = this.project_territory_search
            this.territory_item.type =  'Label'
            await this.territory_store({ ...this.territory_item });
            this.project_item.territories.push(this.territory_item)
            this.project_item.territories = this.project_item.territories.filter(item => typeof item === 'object')
            this.project_territory_search = null
            await this.territory_clear_item();
        },
        local_set_date(key) {
            this.project_item[key] = this.local_project_dates[key]
            const formatedDate = this.local_format_date(this.project_item[key])
            this.local_project_dates[key] = formatedDate
        },
        local_format_date(date) {
            return date ? moment(date).format('MMM DD, YYYY') : null
        },
        local_tag_filter(data, filter) {
            return _.filter(data, filter)
        },
        local_truncate(text, length = 100, omission = '...') {
            return _.truncate(text, {length: length, omission: omission})
        },
        ...mapActions('Project', {
            project_show: 'show',
            project_store: 'store',
            project_update: 'update',
            project_invite_team: 'invite_team',
            project_invite_client: 'invite_client',
            project_clear_item: 'clear_item',
            project_clear_related_item: 'clear_related_item',
        }),
        ...mapActions('Customer', {
            customer_index: 'index',
            customer_search_index: 'search_index',
            customer_store: 'store',
            customer_clear: 'clear',
            customer_clear_item: 'clear_item',
        }),
        ...mapActions('Partner', {
            partner_index: 'index',
            partner_search_index: 'search_index',
            partner_clear: 'clear',
        }),
        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_clear: 'clear',
            tag_clear_item: 'clear_item',
        }),
        ...mapActions('Territory', {
            territory_index: 'index',
            territory_store: 'store',
            territory_clear: 'clear',
            territory_clear_item: 'clear_item',
        }),
        ...mapActions('Scope', {
            scope_index: 'index',
        }),
        ...mapActions('Meta', {
            meta_index: 'index',
        }),
        ...mapActions('Workspace', {
            workspace_board: 'board',
            workspace_clear: 'clear',
            workspace_clear_item: 'clear_item',
        }),
    },
}
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>

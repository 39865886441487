const api_endpoint = '/resource-plan'

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        response: [],
        defaults: {
            list: [],
            item: {},
            meta: {},
            response: []
        }
    },

    mutations: {
        UPDATE_LIST (state, list) {
            state.list = list
        },
        UPDATE_ITEM (state, item) {
            state.item = item
        },
        UPDATE_META (state, meta) {
            state.meta = meta
        },
        THROW_RESPONSE (state, response) {
            state.response = response
        },
    },

    actions: {
        async index ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data }, meta } = await axios.get(api_endpoint, { params: payload })
                commit('UPDATE_LIST', data)
                commit('UPDATE_META', meta)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },

        async show ({ commit }, payload) {
            this.dispatch('Interface/loader_start', { root: true })
            try {
                await axios.get(`${api_endpoint}/${payload.id}`, { ...payload })
                commit('UPDATE_ITEM', data)
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },

        async clear ({ commit }) {
            commit('UPDATE_LIST', [])
            commit('THROW_RESPONSE', [])
        },

        async clearItem ({ commit }) {
            commit('UPDATE_ITEM', [])
            commit('THROW_RESPONSE', [])
        },

        async clearResponse ({ commit }) {
            commit('UPDATE_RESPONSE', [])
        },
    },

    getters: {
        list: state => state.list,
        item: state => state.item,
        response: state => state.response,
    }
}

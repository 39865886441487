<template>
    <div class="c-summary-tile py-5 pb-0 u-relative u-hfull">
        <loader-latest-status-summary v-if="mixLoading" />

        <template v-else>
            <div class="u-overflow-hidden u-hfull" ref="refContentParent">
                <div class="u-flex-center-y px-5">
                    <template v-if="mixParentState.project && mixParentState.project.result">
                        <a-avatar size="28" color="green lighten-3" v-if="mixParentState.project.result.status === 'Positive'">
                            <a-avatar size="24" color="green">
                                <a-icon size="16" color="white">thumb_up</a-icon>
                            </a-avatar>
                        </a-avatar>
                        <a-avatar size="28" color="red lighten-3" v-if="mixParentState.project.result.status === 'Negative'">
                            <a-avatar size="24" color="red">
                                <a-icon size="16" color="white">thumb_down</a-icon>
                            </a-avatar>
                        </a-avatar>
                        <a-avatar size="28" color="indigo lighten-3" v-if="mixParentState.project.result.status === 'Neutral'">
                            <a-avatar size="24" color="indigo darken-2">
                                <a-icon size="16" color="white">linear_scale</a-icon>
                            </a-avatar>
                        </a-avatar>

                        <div
                            v-if="mixParentState.project.result.value"
                            :title="mixParentState.project.result.value"
                            class="md-body-2 grey--text text--darken-3 u-wfull u-font-weight-semibold ml-2"
                        >
                            {{ mixParentState.project.result.value | truncateText(24) }}
                        </div>
                    </template>
                </div>

                <div class="px-5 mt-2" v-if="!localReasonsLength">
                    <div class="md-body-2 grey--text text--darken-3 font-weight-medium mb-1">
                        No reasons are selected
                    </div>
                    <div class="md-body-2 font-weight-medium grey--text text--darken-1">
                        You can choose to create or select a reason for this result.
                    </div>
                </div>

                <div class="px-5 mt-1" v-if="localReasonsLength">
                    <div :class="[localReasonTextColor, 'md-body-2 font-weight-medium mb-1']">
                        {{ localReasonsLength }} Reasons
                    </div>
                    <div ref="refContent">
                        <template v-for="reason in reasonsList">
                            <div :key="reason.id" class="md-body-2 font-weight-medium grey--text text--darken-2">
                                - {{ reason.title }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <partial-bottom-gradient
                v-if="showExpand && localReasonsLength"
                height="42"
            />
        </template>

        <partial-project-summary-actions
            v-if="mixHasPermission('status_updates.index') && !mixLoading"
            :showExpand="noFetch || !localReasonsLength ? false : showExpand"
            @expand="$emit('expand')"
            @open="mixOpenInNewWindow('projects-overview')"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import MixinModalStateInjection from '../Mixins/MixinModalStateInjection'
import PartialProjectSummaryActions from './PartialProjectSummaryActions.vue'
import PartialBottomGradient from './PartialBottomGradient'
import PartialEmptyStateSummary from './PartialEmptyStateSummary.vue'
import LoaderLatestStatusSummary from '../Loaders/LoaderLatestStatusSummary.vue'

export default {
	components: {
        PartialProjectSummaryActions,
        PartialBottomGradient,
        PartialEmptyStateSummary,
        LoaderLatestStatusSummary
    },

    mixins: [MixinModalStateInjection],

    props: {
        noFetch: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            showExpand: false,
            reasonsList: []
        }
    },

    computed: {
        localReasonsLength () {
            return _.size(this.reasonsList)
        },

        localReasonTextColor () {
            const status = this.mixParentState?.project?.result?.status || {}
            switch (status) {
                case 'Positive': return 'green--text text--darken-1'
                case 'Neutral': return 'indigo--text text--darken-1'
                case 'Negative': return 'red--text text--darken-1'
            }
        },
    },

    methods: {
        async localIndex () {
            if (this.noFetch) return this.localNoFetchSetup()
            if (this.mixLatestSyncTimeDiff() >= 5) return this.localNoFetchSetup()

            this.mixSetLoading()
            await this.localFetchReasons()
            this.mixSaveProject({ projectReasons: this.reasonsList })
            this.mixClearLoading()
            this.localSetupAction()
        },

        localNoFetchSetup () {
            this.reasonsList = this.mixGetSavedSummary.projectReasons || []
            this.localSetupAction()
        },

        async localFetchReasons () {
            const params = { include: 'resultReasons', 'fields[projects]': 'id' }
            const { data: { result_reasons }, status } = await this.project_show({ id: this.mixParentState.projectId, stateless: true, params })
            this.reasonsList = status !== 'success' ? [] : _.cloneDeep(result_reasons)
        },

        localSetupAction () {
            if (!this.localReasonsLength || this.mixLoading) return

            this.$nextTick(() => {
                const parentHeight = this.$refs.refContentParent.parentElement.clientHeight
                const contentHeight = this.$refs.refContent.clientHeight + 93
                this.showExpand = !!(contentHeight > parentHeight)
            })
        },

        ...mapActions('ProjectView', {
            project_show: 'show',
        }),
    }
}
</script>

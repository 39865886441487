<template>
    <div>
        <template v-if="!can_internal_mixin('tasks.index')">
            <a-sheet class="transparent text-center mx-auto mt-8" max-width="230">
                <a-icon size="48" color="orange darken-4">warning</a-icon>
                <h2 class="md-subtitle-1 mt-1 grey--text text--darken-1">You do not have sufficient permission to view this page.</h2>
            </a-sheet>
        </template>
        <template v-else>
            <a-container container--fluid grid-list-xl pa-6 :class="{ 'pt-12': !check_permission('update') }">
                <a-layout align-start mb-3 v-if="((milestone_list && milestone_list.length) && (task_list && task_list.length)) || check_permission('store')">
                    <a-flex shrink pb-0 v-if="check_permission('store')">
                        <a-btn small dark color="blue" class="ma-0 elevation-0" @click="local_new_field_focus()">Create New Task</a-btn>
                    </a-flex>
                    <a-flex shrink pb-0 class="pl-0" v-if="check_permission('store')">
                        <a-btn small dark color="blue" class="ma-0 elevation-0" @click="local_milestone_create()">Create New Milestone</a-btn>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink class="pb-0 pl-0" v-if="local_task_milestone_count">
                        <a-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a-btn small icon class="ma-0 blue elevation-0" @click="flag_show_completed = !flag_show_completed" v-if="flag_show_completed" v-on="on">
                                    <a-icon size="18" color="white">done_all</a-icon>
                                </a-btn>
                                <a-btn small icon color="neutral" class="ma-0 elevation-0" @click="flag_show_completed = !flag_show_completed" v-else v-on="on">
                                    <a-icon size="18" color="neutral darken-4">done_all</a-icon>
                                </a-btn>
                            </template>
                            <span>Show/Hide Completed Tasks</span>
                        </a-tooltip>
                    </a-flex>
                    <a-flex shrink class="pl-0 pb-0" v-if="milestone_list && milestone_list.length > 0">
                        <a-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a-btn small icon text color="neutral" class="ma-0 right" @click="local_accordion_milestone_toggle_all(0)" v-if="accordion_milestone_all" v-on="on" :disabled="milestone_list && milestone_list.length === 0">
                                    <a-icon size="18" color="neutral darken-4">unfold_less</a-icon>
                                </a-btn>
                                <a-btn small icon text color="neutral" class="ma-0 right" @click="local_accordion_milestone_toggle_all(1)" v-else v-on="on" :disabled="milestone_list && milestone_list.length === 0">
                                    <a-icon size="18" color="neutral darken-4">unfold_more</a-icon>
                                </a-btn>
                            </template>
                            <span>Expand/Collapse Milestones</span>
                        </a-tooltip>
                    </a-flex>
                </a-layout>

                <template v-if="!page_loading">
                    <!-- Milestone tasks -->
                    <a-layout align-start wrap v-if="milestone_list && milestone_list.length > 0">
                        <a-flex xs12 class="pb-0">
                            <draggable v-model="milestone_list" handle=".js-drag-handle" style="width: 100%;" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                                <template v-for="(milestone, index) in milestone_list">
                                    <p-external-milestone-card
                                        :milestone="milestone"
                                        :milestone-index="index"
                                        :loading="loading"
                                        :expanded="local_accordion_milestone_status(index)"
                                        :can-update="check_permission('update')"
                                        :can-delete="check_permission('destroy')"
                                        @expand="local_accordion_milestone_toggle(index)"
                                        @edit="local_milestone_edit"
                                        @delete="local_milestone_destroy"
                                        :key="milestone.id"
                                        :class="{ 'mt-4': index !== 0}"
                                    >
                                        <template #milestone-assignees>
                                            <s-assignee-dropdown
                                                :item="milestone"
                                                :users-list="local_member_list(milestone.assignees)"
                                                :project-id="$route.params.id"
                                                :local_loading="local_loading"
                                                :can-update="check_permission('update')"
                                                :size="32"
                                                @menuOpen="mixinGetCollabs"
                                                module-type="Milestone"
                                                class="u-flex align-center justify-end mr-3"
                                                hide-empty-text
                                            ></s-assignee-dropdown>
                                        </template>
                                        <template #tasks>
                                            <draggable v-model="milestone.tasks" :group="'milestones'" :id="milestone.id" @end="local_move" handle=".js-drag-handle" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                                                <template v-for="task in milestone.tasks">
                                                    <p-external-task-card
                                                        :task="task"
                                                        :show-completed="flag_show_completed"
                                                        :is-selected="task_item && task_item.id === task.id"
                                                        :item-loading="local_loading(task.id)"
                                                        :can-update="check_permission('update')"
                                                        @select="local_select"
                                                        @toggle-complete="local_complete_toggle"
                                                        :key="task.id"
                                                    >
                                                        <template #status>
                                                            <GNonActiveInfo :disabled="!check_permission('update') || (check_permission('update') && project_item && project_item.status === 'active')">
                                                                <s-task-status-meter
                                                                    :list="local_task_meta_list"
                                                                    :item="task"
                                                                    :status="task.status"
                                                                    :can-update="check_permission('update')"
                                                                    :isProjectActive="project_item && project_item.status === 'active'"
                                                                    @update-item="local_status_update"
                                                                ></s-task-status-meter>
                                                            </GNonActiveInfo>
                                                        </template>
                                                        <template #assignees>
                                                            <s-assignee-dropdown
                                                                :item="task"
                                                                :users-list="local_member_list(task.assignees)"
                                                                :project-id="$route.params.id"
                                                                :local_loading="local_loading"
                                                                :can-update="check_permission('update')"
                                                                :size="32"
                                                                @menuOpen="mixinGetCollabs"
                                                                module-type="Task"
                                                                class="u-flex align-center justify-end mr-3"
                                                                hide-empty-text
                                                            ></s-assignee-dropdown>
                                                        </template>
                                                    </p-external-task-card>
                                                </template>
                                            </draggable>
                                        </template>
                                        <template #add-task>
                                            <a-layout align-center v-if="check_permission('store')">
                                                <a-flex pt-0>
                                                    <a-text-field
                                                        v-model="task_grouped_title[milestone.id]"
                                                        placeholder="Create new task"
                                                        prepend-inner-icon="add"
                                                        class="u-elevation-custom-1"
                                                        @keypress.enter="local_milestone_task_store(task_grouped_title[milestone.id], milestone.id)"
                                                        solo flat hide-details
                                                    ></a-text-field>
                                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="task_response.server && task_response.server.errors && task_response.server.errors.title && task_item.milestone_id === milestone.id">
                                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                                        {{ task_response.server.errors.title[0] }}
                                                    </span>
                                                </a-flex>
                                            </a-layout>
                                        </template>
                                    </p-external-milestone-card>
                                </template>
                            </draggable>
                        </a-flex>
                    </a-layout>

                    <!-- No Milestone -->
                    <a-layout align-start :class="{ 'mt-5': check_permission('store') || ((milestone_list.length > 0 && task_list.length > 0) && !page_loading) }">
                        <a-flex pt-0>
                            <template v-if="!page_loading">
                                <div v-if="milestone_list.length > 0 && task_list.length > 0">
                                    <a-card class="u-elevation-custom-1 u-cursor-pointer">
                                        <a-card-text class="pa-0">
                                            <a-container container--fluid grid-list-xl class="py-2 px-6">
                                                <a-layout align-center>
                                                    <a-flex shrink class="px-0">
                                                        <a-icon class="grey--text text--lighten-1 js-drag-handle u-opacity-30">drag_indicator</a-icon>
                                                    </a-flex>
                                                    <a-flex @click="local_ungrouped_expanded()">
                                                        <h3 class="md-subtitle-1 font-weight-bold primary--text text--darken-2">
                                                            No Milestone
                                                            <a-icon size="20" v-if="!flag_ungrouped_expanded">arrow_drop_down</a-icon>
                                                            <a-icon size="20" v-if="flag_ungrouped_expanded">arrow_drop_up</a-icon>
                                                        </h3>
                                                    </a-flex>
                                                </a-layout>
                                            </a-container>
                                        </a-card-text>
                                    </a-card>
                                </div>
                                <div>
                                    <draggable v-model="task_list" :group="'milestones'" id="ungrouped" @end="local_move" handle=".js-drag-handle" v-if="task_list && flag_ungrouped_expanded" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                                        <template v-for="task in task_list">
                                            <p-external-task-card
                                                :task="task"
                                                :show-completed="flag_show_completed"
                                                :is-selected="task_item && task_item.id === task.id"
                                                :item-loading="local_loading(task.id)"
                                                :can-update="check_permission('update')"
                                                @select="local_select"
                                                @toggle-complete="local_complete_toggle"
                                                :key="task.id"
                                            >
                                                <template #status>
                                                    <GNonActiveInfo :disabled="!check_permission('update') || (check_permission('update') && project_item && project_item.status === 'active')">
                                                        <s-task-status-meter
                                                            :list="local_task_meta_list"
                                                            :item="task"
                                                            :status="task.status"
                                                            :can-update="check_permission('update')"
                                                            :isProjectActive="project_item && project_item.status === 'active'"
                                                            @update-item="local_status_update"
                                                        ></s-task-status-meter>
                                                    </GNonActiveInfo>
                                                </template>
                                                <template #assignees>
                                                    <s-assignee-dropdown
                                                        :item="task"
                                                        :users-list="local_member_list(task.assignees)"
                                                        :project-id="$route.params.id"
                                                        :local_loading="local_loading"
                                                        :can-update="check_permission('update')"
                                                        :size="32"
                                                        @menuOpen="mixinGetCollabs"
                                                        module-type="Task"
                                                        class="u-flex align-center justify-end mr-3"
                                                        hide-empty-text
                                                    ></s-assignee-dropdown>
                                                </template>
                                            </p-external-task-card>
                                        </template>
                                    </draggable>
                                </div>
                            </template>
                            <a-layout align-center mb-4 v-if="check_permission('store') && flag_ungrouped_expanded">
                                <a-flex pt-0>
                                    <a-text-field
                                        v-model="task_title"
                                        placeholder="Create new task"
                                        prepend-inner-icon="add"
                                        class="u-elevation-custom-1"
                                        ref="task_new_field"
                                        @keypress.enter="local_no_milestone_task_store(task_title)"
                                        solo flat hide-details
                                    ></a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(task_response, 'title') && task_item.milestone_id === null">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ $response(task_response, 'title') }}
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-flex>
                    </a-layout>
                </template>

                <a-responsive class="my-4" v-if="page_loading">
                    <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Tasks..</h3>
                </a-responsive>
                <a-responsive v-if="!page_loading && (milestone_list && !milestone_list.length) && (task_list && !task_list.length)">
                    <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                    <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
                </a-responsive>
                <!-- <a-responsive class="py-4 my-4" v-if="!loading && milestone_list.length == 0 && task_list.length == 0">
                    <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Task</h3>
                    <p class="md-body-2 text-center grey--text mb-0">There is no task to fetch right now.<br>Try narrowing your filter or add new task.</p>
                </a-responsive> -->
            </a-container>

            <a-dialog v-model="dialogTaskEdit" max-width="1080" :persistent="localAttachmentLoading">
                <ModalTaskEdit
                    :item="local_task_item"
                    :milestones="localMilestoneMenuList"
                    :collaborators="collaborator_list"
                    :isOpen="dialogTaskEdit"
                    :canUpdate="check_permission('update')"
                    :canDelete="check_permission('destroy')"
                    :canComment="$can('tasks.comment') && permission_mixin('tasks.comment')"
                    :previousTaskId="previousTaskId"
                    :nextTaskId="nextTaskId"
                    :project="project_item"
                    :taskMetaList="local_task_meta_list"
                    @attachmentLoading="localSetLoadingStatus"
                    @navigate="localNavigateFromDetailView"
                    @select="localUpdateItem"
                    @descriptionUpdate="localUpdateItemDescription"
                    @delete="localDeleteTask"
                    @close="evt => dialogTaskEdit = false"
                    isExternal
                />
            </a-dialog>

            <a-dialog v-model="dialog_milestone_form" max-width="700" persistent>
                <a-card>
                    <a-card-text class="pa-0">
                        <a-form @submit.prevent="local_user_store()">
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-center>
                                    <a-flex shrink class="pr-1">
                                        <a-avatar class="primary darken-1" size="40">
                                            <a-icon dark>assistant_photo</a-icon>
                                        </a-avatar>
                                    </a-flex>
                                    <a-flex>
                                        <h2 class="md-heading-6 primary--text text--darken-1">{{ milestone_form_mode === 'add' ? 'Create New Milestone' : 'Edit Milestone' }}</h2>
                                        <p class="md-subtitle-2 mb-0">{{ milestone_form_mode === 'add' ? 'Create new milestone to group tasks.' : 'Edit existing milestone and group tasks.'}}</p>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-center>
                                    <a-flex xs12>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Milestone Title</label>
                                        <a-text-field
                                            v-model="milestone_item.title"
                                            placeholder="Enter Title"
                                            background-color="neutral"
                                            solo flat hide-details
                                        >
                                        </a-text-field>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="milestone_response.server && milestone_response.server.errors && milestone_response.server.errors.title">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ milestone_response.server.errors.title[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Enter the title of the milestone.
                                        </span>
                                    </a-flex>
                                    <a-flex xs6>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Start Date (Optional)</label>
                                        <a-menu
                                            v-model="datepicket_milestone_start_date"
                                            :close-on-content-click="true"
                                            :nudge-right="40"
                                            min-width="290px"
                                            full-width offset-y lazy
                                        >   <template v-slot:activator="{ on }">
                                                <a-text-field
                                                    :value="local_format_date(milestone_item.start_date)"
                                                    placeholder="Select Start Date"
                                                    background-color="neutral"
                                                    v-on="on"
                                                    @click:clear="local_milestone_start_date_clear()"
                                                    solo flat hide-details readonly clearable
                                                >
                                                </a-text-field>
                                            </template>
                                            <a-date-picker v-model="milestone_item.start_date" @input="datepicket_milestone_start_date = false" no-title scrollable></a-date-picker>
                                        </a-menu>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="milestone_response.server && milestone_response.server.errors && milestone_response.server.errors.start_date">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ milestone_response.server.errors.start_date[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Select the start date of the milestone.
                                        </span>
                                    </a-flex>
                                    <a-flex xs6>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Due Date (Optional)</label>
                                        <a-menu
                                            v-model="datepicket_milestone_due_date"
                                            :close-on-content-click="true"
                                            :nudge-right="40"
                                            min-width="290px"
                                            full-width offset-y lazy
                                        >   <template v-slot:activator="{ on }">
                                                <a-text-field
                                                    :value="local_format_date(milestone_item.due_date)"
                                                    placeholder="Select Due Date"
                                                    background-color="neutral"
                                                    v-on="on"
                                                    @click:clear="local_milestone_due_date_clear()"
                                                    solo flat hide-details readonly clearable
                                                >
                                                </a-text-field>
                                            </template>
                                            <a-date-picker v-model="milestone_item.due_date" @input="datepicket_milestone_due_date = false" no-title scrollable></a-date-picker>
                                        </a-menu>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="milestone_response.server && milestone_response.server.errors && milestone_response.server.errors.due_date">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ milestone_response.server.errors.due_date[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Select the due date of the milestone.
                                        </span>
                                    </a-flex>
                                    <a-flex xs12 v-if="milestone_form_mode === 'add'">
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Assignee (Optional)</label>
                                        <GUsersSelectDropdown
                                            v-model="selected_assignees"
                                            :list="collaborator_list"
                                            :loading="mixinSearchCollabLoading"
                                            dataisExternalView
                                            :inputStyle="{ minHeight: '30px' }"
                                            itemText="user.name"
                                            itemValue="user.id"
                                            @search="mixinSearchCollabs"
                                            placeholder="Select Assignees"
                                            class="pr-5 grey lighten-4"
                                            dropdownClass="c-tiny-scroll py-2"
                                            returnObject multiple removable
                                        />
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="milestone_response.server && milestone_response.server.errors && milestone_response.server.errors.assignee">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ milestone_response.server.errors.assignee[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            Select the assignee of the milestone.
                                        </span>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider></a-divider>
                            <a-container container--fluid grid-list-xl class="pa-6">
                                <a-layout wrap align-center>
                                    <a-flex shrink>
                                        <a-btn color="primary" class="ma-0 elevation-0 px-3" :loading="loading" :disabled="loading" @click="local_milestone_store()">Save Milestone</a-btn>
                                        <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_milestone_form = !dialog_milestone_form" :disabled ="loading">Cancel</a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-form>
                    </a-card-text>
                </a-card>
            </a-dialog>

            <a-dialog max-width="800" v-model="dialog_add_users_form" persistent>
                <a-card v-if="local_user_edit_item">
                    <a-card-text class="pa-0">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>group</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1"><span class="md-heading-6 text-capitalize">{{ local_user_edit_type }}</span> Assignees</h2>
                                    <p class="md-subtitle-2 mb-0">Manage users under <strong class="grey--text text--darken-2">{{ local_truncate_text(local_user_edit_item.title, 55) }}</strong></p>
                                </a-flex>
                                <a-flex shrink v-if="autosave_user_add_status !== 'none'">
                                    <a-chip class="right" small color="grey" text-color="white" v-if="autosave_user_add_status === 'loading'">Saving...</a-chip>
                                    <a-chip class="right" small color="green" text-color="white" v-if="autosave_user_add_status === 'done'">Saved</a-chip>
                                </a-flex>
                                <a-flex shrink>
                                    <a-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <a-btn small text icon color="grey" :disabled="autosave_user_add_status === 'loading'" @click="dialog_add_users_form = false" class="mx-0 mt-0 mr-0 mb-0 pa-0 px-3 right" v-on="on">
                                                <a-icon size="18">cancel</a-icon>
                                            </a-btn>
                                        </template>
                                        <span>Close</span>
                                    </a-tooltip>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start style="min-height: 66px;">
                                <a-flex class="u-flex align-center u-wrap">
                                    <span class="md-subtitle-1 d-inline-block py-1 px-2 mr-2 my-1 u-rounded-corners u-cursor-pointer" v-if="local_user_edit_item && local_user_edit_item.assignees && local_user_edit_item.assignees.length === 0">
                                        No Users Assigned
                                    </span>
                                    <template v-else>
                                        <span class="md-subtitle-1 u-flex align-center py-1 px-2 mr-2 my-1 u-rounded-corners u-cursor-pointer grey lighten-2" v-for="assignee in local_user_edit_item.assignees" :key="assignee.id">
                                            <div class="member-avatar">
                                                <g-avatar :item="assignee" :size="30"></g-avatar>
                                            </div>
                                            <div class="member-label u-cursor-pointer">
                                                <template v-if="assignee && assignee.name">
                                                    <h2 class="md-subtitle-1 font-weigh-medium grey--text text--darken-3">{{ assignee.name }}</h2>
                                                </template>
                                                <template v-else>
                                                    <h2 class="md-subtitle-1 font-weigh-medium grey--text text--darken-3">{{ assignee.assignee.name }}</h2>
                                                </template>
                                                <!-- <h5 class="md-subtitle-1 text-truncate" style="max-width: 500px">{{ assignee.assignee.name }}</h5> -->
                                                <!-- <p class="md-caption grey--text text--darken-1 mb-0">{{ assignee.type }}</p> -->
                                            </div>
                                            <a-icon @click="local_remove_assignee(local_user_edit_item.id, assignee.assignee.id, local_user_edit_item)" class="ml-2" size="18">clear</a-icon>
                                        </span>
                                    </template>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Select Users</label>
                                    <a-autocomplete
                                        v-model="local_selected_user_add_item"
                                        :items="local_member_list(local_user_edit_item.assignees)"
                                        item-text="user.name"
                                        item-value="user_id"
                                        label="Select Users"
                                        class="elevation-0"
                                        background-color="neutral"
                                        clear-icon="backspace"
                                        :menu-props="{ closeOnContentClick: autosave_user_add_status === 'loading' }"
                                        :disabled="loading"
                                        @change="local_assign(local_user_edit_item.id, local_selected_user_add_item[0], local_user_edit_item)"
                                        solo flat hide-details multiple small-chips hide-selected clearable deletable-chips autofocus
                                    >
                                        <template v-slot:no-data>
                                            <div class="px-4 py-2">
                                                <span class="md-body-1 grey--text">No Users found</span>
                                            </div>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <a-layout align-center class="py-2">
                                                <g-avatar :item="item"></g-avatar>
                                                <g-profile-info :item="item" set-max-width></g-profile-info>
                                            </a-layout>
                                        </template>
                                    </a-autocomplete>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-card-text>
                </a-card>
            </a-dialog>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { SUserListPopup, SAssigneeForm, STaskStatusMeter } from '@/config/config-shared-components'
import { PExternalTaskCard, PExternalMilestoneCard, PExternalTaskEditCard } from '../_Partials'
import ExternalPermissionMixin from '@/mixins/mixin-external-view-permission'
import { SAssigneeDropdown } from '@/config/config-shared-components'
import mixinSearchCollaborators from '@/mixins/mixin-search-collaborators'
import ModalTaskEdit from '../../Projects/Modals/ModalTaskEdit.vue'
import { Base64 } from 'js-base64'
import { v4 as uuidv4 } from 'uuid'

export default {
    mixins: [ExternalPermissionMixin, mixinSearchCollaborators],

    components: {
        SUserListPopup, SAssigneeForm, STaskStatusMeter, SAssigneeDropdown,
        PExternalTaskCard, PExternalMilestoneCard, PExternalTaskEditCard,
        ModalTaskEdit
    },

    data() {
        return {
            task_title: '',
            task_due_date: null,
            task_priority: null,
            datepicket_milestone_start_date: false,
            datepicket_milestone_due_date: false,
            // task_due_date: false,
            export_mode: 'new',
            previousTaskId: null,
            nextTaskId: null,
            dialogTaskEdit: false,
            dialog_user_form: false,
            dialog_milestone_form: false,
            dialog_import_form: false,
            dialog_export_form: false,
            loading_item: null,
            flag_show_completed: false,
            flag_expanded: true,
            flag_ungrouped_expanded: true,
            local_collaborator_list: [],
            local_task_meta_list: [],
            assignee_mode: false,
            search_collaborator: '',
            local_task_permission: null,
            page_loading: true,
            snackbar_task_update: false,
            local_milestone_fields: {
                'include': 'tasks.status,tasks.assignees,tasksCount,tasksCompletedCount,assignees',
                'fields[tasks.status]': 'id,status,value,color,percentage'
            },
            local_status_fields: {
                'fields[status]': 'id,status,value,color,percentage',
            },
            accordion_milestone: [],
            accordion_milestone_all: false,
            accordion_no_milestone_all: false,
            local_user_edit_item: {},
            local_selected_user_add_item: [],
            local_user_edit_type: '',
            autosave_user_add_status: 'none',
            dialog_add_users_form: false,
            selected_assignees: [],
            error_fields: false,
            revert_task_item: {},
            storedItem: {},
            local_task_item: {},
            milestoneEditItem: {},
            local_task_edit: {},
            taskMetaList: [],
            localAttachmentLoading: false
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        task_show_additional: () => this.task_title != '',
        task_due_date_formatted() {
            if (this.task_due_date != null) {
                return moment(this.task_due_date, 'YYYY-MM-DD').format('MMMM DD, YYYY')
            }
        },
        task_ungrouped_list: () => [],
        task_grouped_list: () => [],
        task_grouped_title: () => [],
        task_list: {
            get() {
                return this.$store.state.TaskExternal.list
            },
            async set(list) {
                await this.task_reorder({list: list})
            }
        },
        milestone_list: {
            get() {
                return this.$store.state.MilestoneExternal.list
            },
            async set(list) {
                await this.milestone_reorder({list: list})
            }
        },
        local_collaborator_filtered() {
            let search = this.search_collaborator
            return this.local_collaborator_list.filter(list => {
                return list.user.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
            });
        },
        task_complete_status() {
            return _.filter(this.task_list, task => task.completed_at);
        },
        milestone_task_complete_status() {
            return _.filter(this.milestone_list, milestone => milestone.tasks_completed_count);
        },
        local_task_milestone_count() {
            return (this.task_list && this.task_list.length > 0) ||
                (this.milestone_list && this.milestone_list.length > 0) ||
                (this.task_complete_status && this.task_complete_status.length > 0) ||
                (this.milestone_task_complete_status && this.milestone_task_complete_status.length > 0)
        },
        localAllTasks () {
            const list = this.milestone_list.map(milestone => milestone.tasks)
                .reduce((prev, current) => {
                    prev = [...prev, ...current];
                    return prev
                }, [])

            return [...list, ...this.task_list]
        },
        localMilestoneMenuList () {
            const list = this.milestone_list.map(i => ({ id: i.id, title: i.title }))
            list.push({ id: 'no-milestone', title: 'No Milestone' })
            return list
        },

        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Project', {
            project_item: 'item',
        }),
        ...mapState('TaskExternal', {
            task_item: 'item',
            task_filters: 'filters',
            task_response: 'response',
            task_form_mode: 'form_mode',
        }),
        ...mapState('MilestoneExternal', {
            milestone_item: 'item',
            milestone_query: 'query',
            milestone_response: 'response',
            milestone_form_mode: 'form_mode',
        }),
        ...mapState('CommentExternal', {
            comment_item: 'item',
            comment_list: 'task_comments',
            comment_filters: 'filters',
            comment_response: 'response',
        }),
        ...mapState('CollaboratorExternal', {
            collaborator_list: 'list',
            collaborator_filters: 'filters',
        }),
        ...mapState('MetaExternal', {
            meta_list: 'list',
        }),
        ...mapState('AssigneeExternal', {
            assignee_response: 'response',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    watch: {
        async dialog_milestone_form(new_value, old_value) {
            if (new_value === null) {
                await this.milestone_clear_item()
            }
        },
    },

    methods: {
        async local_index() {
            if (!this.can_internal_mixin('tasks.index')) return this.page_loading = false
            if (!this.can_external_mixin('tasks.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.load_task_meta()
            await this.task_clear()
            await this.milestone_clear()

            await this.local_milestone_index()
            await this.local_fetch()

            // this.collaborator_filters.project_id = this.$route.params.id;
            await this.collaborator_index({
                'filter[project_id]': this.$route.params.id,
                'include': 'user,type',
                count: 200, page: 1
            });
            this.collaborator_filter_list()
            this.page_loading = false
        },
        async local_fetch() {
            await this.task_index({
                'include': 'assignees,status,attachmentsCount',
                'filter[milestone_id]': 'none',
                'filter[project_id]': this.$route.params.id,
                ...this.local_status_fields,
            })
        },
        async load_task_meta () {
            await this.meta_index({ 'filter[type]': 'task_status', 'fields[metas]': 'id,status,value,color,percentage', sort: 'order' })
            this.local_task_meta_list = _.cloneDeep(this.meta_list)
        },
        async local_move(event) {
            await this.local_task_show(event.item.id)
            this.task_item.milestone_id = (event.to.id === 'ungrouped') ? null : event.to.id
            delete this.task_item.order;
            await this.task_update(this.task_item)

            if (event.from.id !== 'ungrouped') {
                let milestone = this.milestone_list.find(item => item.id === event.from.id)
                await this.milestone_tasks_reorder({list: milestone.tasks})
                await this.local_milestone_show(event.from.id)
            }

            if (event.to.id !== 'ungrouped') {
                let milestone = this.milestone_list.find(item => item.id === event.to.id)
                await this.milestone_tasks_reorder({list: milestone.tasks})
                await this.local_milestone_show(event.to.id)
            }
            await this.local_task_show(event.item.id)
            await this.local_fetch()
        },
        async local_task_show(id) {
            await this.task_show({id: id, mode: 'show', params: {
                'include': 'milestone,assignees,status',
                ...this.local_status_fields
            }})
            this.local_task_item = { ...this.task_item }
        },
        async local_milestone_show(id) {
            await this.milestone_show({ id, params: this.local_milestone_fields})
        },
        async local_task_milestone_update(id) {
            this.task_item.milestone_id = id
            this.task_item.task_milestone_update = 1
            await this.task_update(this.task_item)
            await this.local_task_show(this.task_item.id)
            await this.local_milestone_index()
            this.local_fetch()
        },
        async local_no_milestone_task_store(task_title = null) {
            await this.task_clear_item()

            this.local_set_task_variables(task_title)
            this.task_item.milestone_id = null
            await this.task_store(this.task_item)

            if (this.task_response.status !== 'success')  return
            this.local_reset_task_variables()
        },
        async local_milestone_task_store(task_title = null, milestone_id = null) {
            await this.task_clear_item()

            this.local_set_task_variables(task_title)
            this.task_item.milestone_id = milestone_id
            this.task_item.module = 'Milestone'
            await this.task_store(this.task_item)

            if (this.task_response.status !== 'success')  return
            this.local_set_milestone_percentage(milestone_id)
            this.local_reset_task_variables()
            if (this.task_item.milestone_id === null) return
            this.task_grouped_title[milestone_id] = ''
            await this.milestone_task_store({ milestone_id: milestone_id, data: this.task_item })
        },
        async local_set_milestone_percentage (milestone_id, percentage = null) {
            if (!milestone_id) return
            const milestone_index = this.milestone_list.findIndex(item => item.id === milestone_id)
            if (milestone_index === -1) return
            this.milestone_list[milestone_index].external_completion_percentage = percentage ?? this.task_item.milestone.external_completion_percentage
        },
        local_set_task_variables(task_title) {
            this.task_item.project_id = this.$route.params.id
            this.task_item.title = task_title
            if (this.user_self.scope === 'external') {
                this.task_item.visibility = 'external'
            }
            this.task_item.force_include = true
            this.task_item.attachments_count = 0

            this.task_item.params = { 'include': 'assignees,milestone,status,attachmentsCount', ...this.local_status_fields }
        },
        local_reset_task_variables() {
            this.task_title = ''
            this.task_due_date = null
            this.task_priority = null
        },
        async local_update(task_field) {
            this.loading_item = this.task_item.id
            if (!task_field) await this.task_update(this.task_item)
            else {
                const taskItem = { id: this.task_item.id, milestone_id: this.task_item.milestone_id, ...task_field }
                let field = task_field.title ? 'title' : 'description'
                await this.task_update(taskItem)
                this.loading_item = null
                if(this.task_response.status !== 'success') return
                this.$notify('success', 'Task updated successfully!')
                if (task_field.title) await this.task_show({ id: taskItem.id, params: { 'fields[tasks]': `id,${field}`}})
                if (taskItem.milestone_id) this.local_milestone_task_update(taskItem.milestone_id, taskItem)
                else this.local_fetch()
                return
            }
            this.loading_item = null
            if(this.task_response.status !== 'success')  return
            this.$notify('success', 'Task updated successfully!')

            if (this.task_item.milestone_id) this.local_milestone_task_update(this.task_item.milestone_id, this.task_item)
            else this.local_task_show(this.task_item.id)
        },
        async local_load_task_item() {
            if (this.task_item.milestone_id === null) return
            await this.local_task_show(this.task_item.id)
            let task_item = _.clone(this.task_item)
            await this.local_milestone_task_update(task_item.milestone_id, task_item)
        },
        local_milestone_due_date_clear() {
            this.milestone_item.due_date = null
        },
        local_milestone_start_date_clear() {
            this.milestone_item.start_date = null
        },
        async local_milestone_task_update(milestone_id, task_item) {
            await this.milestone_task_update({ milestone_id: milestone_id, data: task_item })
        },
        async local_complete_toggle(id, state, milestone_id = null) {
            this.loading_item = id
            const task_item = {}
            task_item.id = id
            task_item.state = state ? 'incomplete' : 'complete'
            await this.task_complete(task_item)
            await this.local_task_show(id)
            this.loading_item = null
            if (milestone_id === null) return
            await this.local_milestone_task_update(this.task_item.milestone_id, this.task_item)
            this.local_milestone_show(milestone_id)
        },
        async local_status_update(task, meta) {
            this.loading_item = task.id
            this.task_item.id = task.id
            await this.task_state_update({ id: task.id, state: meta.id })
            await this.local_task_show(task.id)
            // this.task_item = _.cloneDeep(this.task_item)
            this.loading_item = null
            if (task.milestone_id === null) return
            await this.local_milestone_task_update(task.milestone_id, this.task_item)
            this.local_set_milestone_percentage(task.milestone_id)
            // this.local_milestone_show(task.milestone_id, task)
        },
        async local_visibility_toggle(id, state, milestone_id = null) {
            if (this.check_permission('update')) {
                const task_item = {}
                task_item.id = id
                task_item.state = state === 'external' ? 'internal' : 'external'
                task_item.milestone_id = milestone_id
                await this.task_visibility(task_item)
                if (this.task_response.status !== 'success') return
                if (milestone_id === null) return
                this.milestone_task_visibility(task_item)
                this.local_milestone_show(milestone_id)
                this.task_item.visibility = task_item.state
            }
        },
        async local_update_list_item (task) {
            await this.local_task_show(task.id, 'show')
            const { milestone_id } = task || {}
            if (!milestone_id) return this.task_update_list(this.task_item)
            this.local_milestone_task_update(milestone_id, this.task_item)
        },
        async local_comment_visibility_toggle(id, visibility) {
            const comment_item = {}
            comment_item.id = id
            comment_item.visibility = visibility === 'external' ? 'internal' : 'external'
            comment_item.type = 'Task'
            await this.comment_visible(comment_item)
        },
        async local_milestone_assign(id, user_id) {
            this.assignee_mode = true
            await this.milestone_show({id: id})
            this.milestone_item.assignee_id = user_id
            await this.milestone_update({id: this.milestone_item.id})
            await this.milestone_clear_item()
            this.search_collaborator = ''
            this.assignee_mode = false
        },
        local_member_list(list) {
            return this.collaborator_list.filter(item => !(_.find(list, { id: item.user_id })))
        },
        local_reset_assignee_variables(list, item, has_milestone, type) {
            this.local_selected_user_add_item = null
            this.search_collaborator = ''
            this.autosave_user_add_status = 'done'
            this.local_user_edit_item = Object.assign(this.local_user_edit_item, item)

            if (type === 'Task' && has_milestone) this.local_milestone_task_update(this.local_user_edit_item.milestone_id, this.local_user_edit_item)
            else this.task_update_list(this.local_user_edit_item)
            if (type === 'Milestone') this.milestone_update_list(this.local_user_edit_item)
        },
        async local_show_add_users_dialog(item, type) {
            this.autosave_user_add_status = 'none'
            this.local_user_edit_type = type
            this.local_user_edit_item = item
            this.dialog_add_users_form = true
        },
        async local_assign(model_id, user_id, model_item) {
            this.autosave_user_add_status = 'none'
            this.autosave_user_add_status = 'loading'
            this.assignee_mode = true
            this.loading_item = model_id
            await this.local_assignee_store({ model_id, assignee_id: user_id }, model_item)
            this.edit_mode = false
            this.assignee_mode = false
            this.loading_item = null
        },
        async local_remove_assignee(model_id, user_id, model_item) {
            this.loading_item = model_id
            await this.assignee_destroy({ id: user_id })
            if (this.assignee_response.status === 'success') {
                if (this.local_user_edit_type === 'Task') await this.task_show({ id: model_id, mode: 'show', params: { 'include': 'assignees,milestone' } })
                else await this.local_milestone_show(model_id)

                const has_milestone = model_item.milestone_id
                const list = this.local_user_edit_type === 'Task' ? this.task_list : this.milestone_list
                const item = this.local_user_edit_type === 'Task' ? this.task_item : this.milestone_item
                this.local_reset_assignee_variables(list, item, has_milestone, this.local_user_edit_type)
                this.loading_item = null
            } else this.loading_item = null
        },
        async local_select(task) {
            this.loading_item = task.id
            this.localEditTask(task)
            this.local_task_edit = _.cloneDeep(task)
            this.local_task_item = _.cloneDeep(task)
            this.local_task_item = { edited: false, ...this.local_task_item }
            this.loading_item = null
        },
        async local_touch() {
            this.task_item.edited = true
        },
        async local_close() {
            await this.task_clear_item()
        },
        local_filter(data, filter) {
            return _.filter(data, filter)
        },
        local_format_date(date) {
            if (date != null) {
                return moment(date).format('MMMM DD, YYYY');
            }
            else {
                return null;
            }
        },
        async local_priority_update(priority) {
            this.loading_item = this.task_item.id
            this.task_item.priority = priority
            await this.task_update(this.task_item)
            await this.local_task_show(this.task_item.id)
            this.local_milestone_task_update(this.task_item.milestone_id, this.task_item)
            if (this.task_item.milestone_id != null) {
                await this.local_load_task_item()
                this.loading_item = null
            } else this.loading_item = null
        },
        async local_comment_store() {
            this.comment_item.commentable_type = 'Task'
            this.comment_item.commentable_id = this.task_item.id
            this.comment_item.visibility = 'internal'
            await this.comment_store(this.comment_item)
            if (this.comment_response.status !== 'success') return
            await this.comment_show({id : this.comment_item.id, type: 'Task', params : { 'include': 'createdBy',}})
            await this.comment_clear_item()
        },
        local_due_date_passed(date, completed_at) {
            return moment(date).isBefore() && !completed_at
        },
        local_update_input(event) {
            this.$emit('update', event.target.innerText);
        },
        local_clear_due() {
            this.task_item.due_date = null
            this.local_update()
        },
        async local_destroy(id, milestone_id) {
            await this.task_destroy({id: id, milestone_id: milestone_id})
            await this.task_clear_item();
            this.task_clear_item()
            if (milestone_id === null) return
            this.milestone_task_destroy({milestone_id: milestone_id, task_id: id})
            this.local_milestone_show(milestone_id)
        },
        local_loading(id) {
            // return this.loading && this.loading_item === id
            return this.loading_item && this.loading_item === id
        },
        local_new_field_focus() {
            this.flag_ungrouped_expanded = true
            this.$nextTick(() => {
                this.$refs.task_new_field.ref.focus();
            })
        },
        collaborator_filter_list() {
            this.local_collaborator_list = this.collaborator_list
        },
        async local_milestone_index() {
            await this.milestone_index({
                'include': 'tasks.status,tasks.assignees,tasksCount,tasksCompletedCount,assignees',
                'fields[tasks.status]': 'id,status,value,percentage,color',
                'filter[project_id]': this.$route.params.id,
                withTaskAttachmentsCount: 1,
            })
            this.milestone_list.map(milestone => {
                this.task_grouped_title[milestone.id] = '';
            })
        },
        async local_milestone_create() {
            this.mixinGetCollabs()
            await this.milestone_clear_item()
            this.dialog_milestone_form = true
            this.selected_assignees = []
        },
        async local_milestone_edit(id) {
            await this.milestone_clear_item()
            await this.milestone_select({id: id})
            this.dialog_milestone_form = true
        },
        async local_milestone_store() {
            this.milestone_item.project_id = this.$route.params.id
            if (this.milestone_form_mode === 'add') {
                await this.milestone_store(this.milestone_item)
            }
            if (this.milestone_form_mode === 'edit') {
                await this.milestone_update(this.milestone_item)
            }
            if (this.milestone_response.status === 'success') {
                this.dialog_milestone_form = false
                await this.local_add_users(this.milestone_item)
                setTimeout(async () => {
                    await this.local_milestone_show(this.milestone_item && this.milestone_item.id ? this.milestone_item.id : '')
                    await this.milestone_clear_item()
                    this.selected_assignees = []
                }, 300)
            }
        },
        async local_add_users(model) {
            _.map(this.selected_assignees, 'user_id').forEach(async user_id => {
                await this.assignee_store({ resource_type: 'Milestone', resource_id: model.id, user_id, project_id: model.project_id })
            })
        },
        local_remove_selected_user(item, list) {
            list.splice(list.indexOf(item.user_id), 1)
        },
        async local_milestone_destroy(id) {
            await this.milestone_destroy({id: id})
        },
        async local_milestone_expand_toggle(id) {
            await this.milestone_select({id: id})
            if (this.milestone_item.is_expanded == 0) {
                await this.milestone_expand()
            } else {
                await this.milestone_collapse()
            }
            await this.milestone_clear_item()
        },
        local_ungrouped_expanded() {
            this.flag_ungrouped_expanded = !this.flag_ungrouped_expanded
        },
        async local_milestone_expand_all_toggle() {
            if (this.flag_expanded == 0) {
                await this.milestone_expand_all({data: {project_id: this.$route.params.id}})
                this.flag_ungrouped_expanded = true
                await this.local_milestone_index()
            }
            else {
                await this.milestone_collapse_all({data: {project_id: this.$route.params.id}})
                this.flag_ungrouped_expanded = false
                await this.local_milestone_index()
            }
            this.flag_expanded = !this.flag_expanded
        },
        async local_assignee_store({ model_id, assignee_id }, model_item) {
            const data = { resource_type: this.local_user_edit_type, resource_id: model_id, user_id: assignee_id, project_id : model_item.project_id }
            await this.assignee_store(data)
            if (this.assignee_response.status === 'success') {
                if (this.local_user_edit_type === 'Task') await this.task_show({ id: model_id, mode: 'show', params: { 'include': 'assignees' } })
                else await this.local_milestone_show(model_id)

                const has_milestone = model_item.milestone_id
                const list = this.local_user_edit_type === 'Task' ? this.task_list : this.milestone_list
                const item = this.local_user_edit_type === 'Task' ? this.task_item : this.milestone_item
                this.local_reset_assignee_variables(list, item, has_milestone, this.local_user_edit_type)
                if(has_milestone) this.local_milestone_show(has_milestone)
            }
        },
        local_accordion_milestone_status(index) {
            if (this.accordion_milestone[index]) {
                return this.accordion_milestone[index]
            } else {
                this.accordion_milestone[index] = false
                return this.accordion_milestone[index]
            }
        },
        local_accordion_milestone_toggle(index) {
            if (this.accordion_milestone[index] === undefined || this.accordion_milestone[index] === null) {
                this.$set(this.accordion_milestone, index, true);
            } else {
                this.$set(this.accordion_milestone, index, !this.accordion_milestone[index]);
            }
        },
        local_accordion_milestone_toggle_all(status = null) {
            this.accordion_milestone_all = !this.accordion_milestone_all
            this.accordion_milestone = this.accordion_milestone.map(x => this.accordion_milestone_all)
            this.flag_ungrouped_expanded = !this.flag_ungrouped_expanded
        },

        // Task edit popup - Start
        localEditTask (task) {
            this.dialogTaskEdit = true
            this.localNavigateFromDetailView(task.id)
        },

        localNavigateFromDetailView (taskId) {
            const allTasks = this.localAllTasks
            const count = _.size(allTasks)
            const currentIndex = allTasks.findIndex(i => i.id === taskId)

            if (currentIndex >= 0 && currentIndex <= count) {
                this.local_task_item = _.cloneDeep(allTasks[currentIndex])
                if (currentIndex === 0) this.previousTaskId = null
                else this.previousTaskId = allTasks[currentIndex - 1].id

                if ((currentIndex + 1) === count) this.nextTaskId = null
                else this.nextTaskId = allTasks[currentIndex + 1].id
            }
        },

        localUpdateItemDescription (task, props) {
            const { encodedData, value, isEmpty } = props || {}
            const item = _.cloneDeep(task)
            item['description_json'] = encodedData

            this.localTaskFieldsUpdate({ ...task, description_json: value })
            this.localTaskUpdate(item, 'description_json', { isEmpty })
        },

        async localTaskFieldsUpdate (task) {
            const { milestone_id } = task || {}
            if (!milestone_id) this.task_update_list(task)
            else this.local_milestone_task_update(milestone_id, task)

            const isNoMilestone = !milestone_id
            this.localUpdateModalTaskItem(task, isNoMilestone)
        },

        async localTaskUpdate (task, field = null, additionProps = {}) {
            const { isEmpty = false } = additionProps || {}
            const clonedTask = _.cloneDeep(task)

            if (clonedTask.hasOwnProperty('description_json') && field !== 'description_json') {
                const jsonDesc = (clonedTask.description_json && (typeof clonedTask.description_json !== 'string')) ? JSON.stringify(clonedTask.description_json) : clonedTask.description_json
                clonedTask['description_json'] = isEmpty || !clonedTask.description_json ? null : Base64.encode(jsonDesc)
            }

            await this.task_new_update(clonedTask)
        },

        localUpdateModalTaskItem (item, isNoMilestone) {
            if (!this.dialogTaskEdit) return
            if (!isNoMilestone) {
                const milestoneIndex = this.milestone_list.findIndex(milestone => milestone.id === item.milestone_id)
                const milestoneTasks = Object.values(this.milestone_list[milestoneIndex].tasks).flat()
                this.milestoneEditItem = this.milestone_list[milestoneIndex]
                const taskIndex = milestoneTasks.findIndex(i => i.id === item.id)
                this.local_task_item = _.cloneDeep(milestoneTasks[taskIndex])
                return
            }

            const taskIndex = this.task_list.findIndex(i => i.id === item.id)
            this.local_task_item = _.cloneDeep(this.task_list[taskIndex])
        },

        async localUpdateItem (props, task, colSlug, isNoMilestone) {
            const { item, value } = props || {}
            this.singleTaskEdit = task

            const milestoneIndex = this.milestone_list.findIndex(milestone => milestone.id === task.milestone_id)
            this.milestoneEditItem = this.milestone_list[milestoneIndex]
            if (colSlug === 'title') {
                this.localUpdateTaskTitle(colSlug, value, isNoMilestone)
            }

            if (colSlug === 'visibility') {
                this.localTaskFieldsUpdate({ ...task, visibility: item.visibility })
                this.task_visibility({ id: this.singleTaskEdit.id, state: item.visibility })
            }

            if (colSlug === 'status_id') {
                this.localTaskFieldsUpdate({ ...task, status: item, status_id: item.id })
                await this.task_state_update({ id: task.id, state: item.id })
                this.local_milestone_show(task.milestone_id)
            }

            if (colSlug === 'priority') {
                this.localTaskFieldsUpdate({ ...task, priority: item.value })
                this.localTaskUpdate({...task, priority: item.value})
            }

            if (colSlug === 'milestone_id') {
                this.localUpdateTasks(item, value, colSlug, task, isNoMilestone)
                await this.localTaskUpdate({...task})
                if (task.milestone_id && item.id !== 'no-milestone') await this.local_milestone_show(task.milestone_id)
                if (this.local_task_edit.milestone_id) await this.local_milestone_show(this.local_task_edit.milestone_id)
            }

            if (colSlug === 'start_date') {
                const startDateValue = value === 'Invalid date' ? null : value
                this.localTaskFieldsUpdate({ ...task, start_date: startDateValue })
                this.localTaskUpdate({...task, start_date: startDateValue })
            }

            if (colSlug === 'due_date') {
                const dueDateValue = value === 'Invalid date' ? null : value
                this.localTaskFieldsUpdate({ ...task, due_date: dueDateValue })
                this.localTaskUpdate({...task, due_date: dueDateValue })
            }

            if (colSlug === 'assignees') {
                this.localUpdateTaskAssignee(colSlug, item, isNoMilestone)
            }

            if (colSlug === 'attachments_count') {
                this.localTaskFieldsUpdate({...task, attachments_count: value})
            }
        },

        async localUpdateTaskAssignee (colSlug, item, isNoMilestone) {
            if (colSlug !== 'assignees') return

            const isAssigned = this.localIsAssigneeAdded(item, this.singleTaskEdit)
            isAssigned ? this.localRemoveAssignee(isAssigned, this.singleTaskEdit, isNoMilestone) : this.localAddAssignee(item, this.singleTaskEdit, isNoMilestone)
        },

        localAddAssignee (collab, item, isNoMilestone) {
            const { item: taskItem, assigneeProps } = this.localAddAssigneeSetup(collab, item)

            this.localTaskFieldsUpdate(taskItem, isNoMilestone)
            this.assignee_store({ ...assigneeProps, project_id: taskItem.project_id, resource_type: 'Task' })
        },

        localAddAssigneeSetup (collab, item) {
            const assigneeId = uuidv4()
            const collabItem = _.cloneDeep(collab)
            const assigneeProps = { id: assigneeId, resource_id: item.id, user_id: collab.user_id }
            const assignee = { ...collab.user, assignee: assigneeProps, collaborator: collabItem }

            if (!item.hasOwnProperty('assignees')) item['assignees'] = [assignee]
            else item.assignees.push(assignee)

            return { item, assigneeProps }
        },

        localIsAssigneeAdded (collab, item) {
            if (!item.assignees || _.size(item.assignees) === 0) return false

            const hasCollab = item.assignees.find(item => item.assignee.user_id === collab.user_id)
            return hasCollab || false
        },

        localRemoveAssignee (isAssigned, taskItem, isNoMilestone) {
            const index = taskItem.assignees.findIndex(i => i.assignee.id === isAssigned.assignee.id)
            if (index === -1) return true

            taskItem.assignees.splice(index, 1)
            this.localTaskFieldsUpdate(taskItem, isNoMilestone)
            this.assignee_destroy({ id: isAssigned.assignee.id })
        },

        async localUpdateTasks (item, value, colSlug, task, isNoMilestone) {
            this.localUpdateTaskMilestone(colSlug, item, isNoMilestone)
            // if (!isNoMilestone) this.milestone_update(this.milestoneEditItem)

        },

        async localUpdateTaskMilestone (colSlug, item) {
            const clonedTaskItem = _.cloneDeep(this.singleTaskEdit)
            if (colSlug !== 'milestone_id' || clonedTaskItem.milestone_id === item.id) return

            this.singleTaskEdit['milestone_id'] = item.id !== 'no-milestone' ? item.id : null
            const milestoneId = item.id === 'no-milestone' ? null : item.id

            if (!clonedTaskItem.milestone_id) {
                this.task_remove(clonedTaskItem.id)
                this.milestone_task_store({ milestone_id: milestoneId, data: this.singleTaskEdit })
                this.localUpdateModalTaskItem(this.singleTaskEdit, !milestoneId)
                return true
            }

            if (item.id === 'no-milestone') {
                this.milestone_task_remove({ ...clonedTaskItem, milestone_id: clonedTaskItem.milestone_id })
                this.task_add({ ...this.singleTaskEdit, milestone: null, milestone_id: null })
                this.localUpdateModalTaskItem({ ...this.singleTaskEdit, milestone: null, milestone_id: null }, !milestoneId)
                return true
            }

            this.milestone_task_remove({ ...this.singleTaskEdit, milestone_id: clonedTaskItem.milestone_id })
            this.milestone_task_store({ milestone_id: milestoneId, data: this.singleTaskEdit })
            this.localUpdateModalTaskItem({ ...this.singleTaskEdit, milestone_id: milestoneId })
        },

        localUpdateTaskTitle (colSlug, value, isNoMilestone) {
            this.singleTaskEdit[colSlug] = _.trim(value)
            this.localTaskFieldsUpdate(this.singleTaskEdit)
            this.localTaskUpdate({ id: this.singleTaskEdit.id, title: this.singleTaskEdit.title, milestone_id: isNoMilestone ? null : this.singleTaskEdit.milestone_id  })
        },

        localDeleteTask (item) {
            !item.milestone_id ? this.task_remove(item.id) : this.milestone_task_remove(item)
            this.task_destroy(item)
        },
        // Task edit popup - End

        // Extras
        local_truncate_text(text, range, separator = '...') {
            if (!text) return
            return text.length > range ? text.slice(0, range) + separator : text
        },
        local_get_initial (name) {
            const [first, last] = _.split(name, ' ', 2)
            return last ? (first[0] ?? '') + (last[0] ?? '') : (first[0] ?? '')
        },
        check_permission(type) {
            return this.$can('projects.update-only') && this.permission_mixin('tasks.' + type)
        },
        local_error (status) {
            this.error_fields = status
        },
        local_revert_changes (item) {
            this.revert_task_item = {}
            this.revert_task_item = {...item}
        },

        localSetLoadingStatus (e) {
            this.localAttachmentLoading = e
        },

        ...mapActions('TaskExternal', {
            task_index: 'index',
            task_show: 'show',
            task_store: 'store',
            task_add: 'add',
            task_assign: 'assign',
            task_complete: 'complete',
            task_state_update: 'state_update',
            task_visibility: 'visibility',
            task_client_invisible: 'client_invisible',
            task_update: 'update',
            task_update_list: 'task_update',
            task_select: 'select',
            task_upgrade: 'upgrade',
            task_downgrade: 'downgrade',
            task_template_import: 'template_import',
            task_remove: 'remove',
            task_destroy: 'destroy',
            task_reorder: 'reorder',
            task_clear: 'clear',
            task_clear_item: 'clear_item',
            task_new_update: 'new_update',
        }),
        ...mapActions('MilestoneExternal', {
            milestone_index: 'index',
            milestone_store: 'store',
            milestone_show: 'show',
            milestone_task_store: 'task_store',
            milestone_task_update: 'task_update',
            milestone_task_visibility: 'task_visibility',
            milestone_task_destroy: 'task_destroy',
            milestone_expand: 'expand',
            milestone_collapse: 'collapse',
            milestone_expand_all: 'expand_all',
            milestone_collapse_all: 'collapse_all',
            milestone_update: 'update',
            milestone_update_list: 'milestone_update',
            milestone_select: 'select',
            milestone_destroy: 'destroy',
            milestone_task_remove: 'task_remove',
            milestone_reorder: 'reorder',
            milestone_tasks_reorder: 'tasks_reorder',
            milestone_clear: 'clear',
            milestone_clear_item: 'clear_item',
        }),
        ...mapActions('CommentExternal', {
            comment_index: 'index',
            comment_store: 'store',
            comment_show: 'show',
            comment_visible: 'visible',
            comment_clear: 'task_comment_clear',
            comment_clear_item: 'clear_item',
        }),
        ...mapActions('MetaExternal', {
            meta_index: 'index',
        }),
        ...mapActions('CollaboratorExternal', {
            collaborator_index: 'index',
            collaborator_clear: 'clear',
        }),
        ...mapActions('AssigneeExternal', {
            assignee_store: 'store',
            assignee_destroy: 'destroy',
        }),
    },
}
</script>

<style scoped>
.task-detail-pane {
    transition: 0.3s all;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    height: 100vh;
    width: 480px;
    overflow-y: scroll;
    transform: translateX(480px);
}
.task-detail-pane.visible {
    transform: translateX(0);
}
.v-textarea textarea {
    font-weight: 400;
    font-size: 24px;
}
.text-wrap-pre {
    white-space: pre-wrap;
}
</style>

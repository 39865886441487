<template>
    <div
        :style="[calculateGcGr(item), isHightlighted ? { border: '2px solid transparent' } : '']"
        :class="[isWiggle ? (itemIndex % 2 !== 0 ? 'c-wiggle-odd' : 'c-wiggle-even') : '', { 'js-draggable-file-list-item': isRearrange }, isHightlighted ? 'scale-animate u-rounded-corners-lg' : '']"
        class="c-grid-item js-drag-handle"
    >
        <div class="u-cursor-pointer u-relative u-rounded-corners-lg u-wfull u-border">
            <div :key="item.title + '-' + itemIndex" class="u-relative u-wfull u-flex flex-column justify-space-between" @mouseover="local_handle_mouseover" @mouseleave="local_handle_mouseleave">
                <div>
                    <slot name="toolbar" :isHover="is_hover"></slot>
                </div>
                <slot name="content"></slot>
            </div>
            <a-sheet height="32" style="bottom: 0px; border-radius: 0px 0px 8px 8px;" @click="is_link_enabled ? local_go_to_tile_view(item.id) : {}" class="c-tile-footer u-flex-center-y u-wfull grey lighten-5 px-3">
                <g-moment tag="span" class="md-caption c-tile-footer--time body--text text--lighten-3 align-center justify-end transparent u-wfull" :value="item.statistics.timestamp" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY HH:mm:ss" v-if="(item && item.statistics) && (isBoardExport || isExported)"></g-moment>
                <div class="u-wfull remove-when-downloading" style="display: flex; align-items: center;">
                    <a-sheet class="md-caption c-tile-footer--time body--text text--lighten-3 d-inline-flex align-center justify-end transparent" v-if="!isBoardExport && !isExported">{{ local_get_date(item) }}</a-sheet>
                    <a-spacer></a-spacer>
                    <div class="remove-when-downloading">
                        <a-sheet v-if="is_link_enabled" class="c-tile-footer--link transparent u-flex-center-y body--text text--lighten-2 u-cursor-pointer" style="font-size: 12px;">
                            <span class="body--text text--lighten-3">View Data</span>
                            <a-icon size="18">keyboard_arrow_right</a-icon>
                        </a-sheet>
                    </div>
                </div>
            </a-sheet>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { diffHuman } from '@/helpers/helper-date-filter'

export default {
    data: function () {
        return {
            loading_statuses: ['loading', 'update-loading', 'new-loading', 'force-loading'],
            is_hover: false,
        }
    },

    props: {
        item: {
            type: Object,
            required: true,
        },
        isBoardExport: {
            type: Boolean,
            default: false,
        },
        isExported: {
            type: Boolean,
            default: false,
        },
        isHightlighted: {
            type: Boolean,
            default: false,
        },
        isDrag: {
            type: Boolean,
            default: false,
        },
        isWiggle: {
            type: Boolean,
            default: false,
        },
        itemIndex: {
            type: String | Number,
        },
        isRearrange: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        is_link_enabled() {
            if(!this.isRearrange && !this.loading_statuses.includes(this.item.status) && !this.tile_faux_loader.length) {
                return true
            } else {
                return false
            }
        },
        ...mapState('Tile', {
            tile_item: 'item',
            tile_response: 'response',
            tile_faux_loader: 'faux_loader'
        }),
    },

    methods: {
        async local_index () {
            await this.tile_show_stats({ id: this.item.id });
        },

        local_go_to_tile_view (tile_id) {
            if (!this.$can('insights_dashboard.index')) return
            const board_id = this.$route.params.view_id
            this.$router.push({ name: 'dashboard-insights-tile-view', params: { view_id: board_id, tile_id } })
        },

        local_get_date (item) {
            if (item && item.statistics) {
                return diffHuman(item.statistics.timestamp)
            }
        },

        calculateGcGr (item) {
            const span = item && item.source ? (item.source.size === '2x1' ? 2 : 1) : 1
            const obj = {
                // height: '304px'
                // height: '272px'
            }
            if (span === 2) {
                obj['gridColumnEnd'] = 'span 2'
                obj['width'] = '568px'
            } else {
                obj['gridColumnEnd'] = 'span 1'
                obj['width'] = '272px'
            }
            return obj
        },

        local_handle_mouseover () {
            this.is_hover = true;
        },

        local_handle_mouseleave () {
            this.is_hover = false;
        },

        ...mapActions('Tile', {
            tile_show_stats: 'show_stats',
        }),
    },
}
</script>

<style lang="scss" scoped>
// .flip-list-move {
//     transition: transform 0.5s;
// }

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.scale-animate {
    transform: scale(1);
    transition: transform 1s all;
    animation: scale-loading 0.5s ease-out infinite alternate;
}

@keyframes scale-loading {
    0% {
        transform: scale(1);
        border: 2px solid transparent;
        opacity: 1;
    }
    25% {
        transform: scale(1.01);
        border: 2px solid #C5CAE9;
        opacity: 0.8;
    }
    50% {
        transform: scale(1.02);
        border: 2px solid #7986CB;
        opacity: 0.6;
    }
    100% {
        transform: scale(1.03);
        border: 2px solid #3949AB;
        opacity: 0.4;
    }
}

.c-tile-footer {
    transition: background-color 0.25s all, color 0.25s all;
    &--link { span, i { color: #bdbdbd !important; } }
    &--time { color: #7e7e7e !important; }
    &:hover {
        background-color: #f5f5f5 !important;
        transition: background-color 0.25s all, color 0.25s all;
        .c-tile-footer--link {
            span, i { color: #7e7e7e !important; font-weight: 500 !important; }
        }
        .c-tile-footer--time { color: #bdbdbd !important; }
    }
}
</style>

import { mapState, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState('AttachmentExternal', {
            attachment_external_local_list: 'local_list',
            attachment_external_delete_list: 'delete_list',
            attachment_external_upload_list: 'upload_list',
        })
    },

    methods: {
        mixinExternalAttachmentListeners (type) {
            if (type === 'add') {
                window.addEventListener("dragover", this.localPreventDragDrop);
                window.addEventListener("drop", this.localPreventDragDrop);
                return
            }
            window.removeEventListener("dragover", this.localPreventDragDrop);
            window.removeEventListener("drop", this.localPreventDragDrop);
        },

        localPreventDragDrop (e) {
            e.preventDefault();
        },

        mixinExternalRemoveAttachmentItem (id) {
            this.attachment_external_clear_response()
            const list = _.cloneDeep(this.attachment_external_local_list)
            const index = _.findIndex(list, { 'id': id })
            if (index !== -1) list.splice(index, 1)
            this.attachment_external_update_local_list(list)
            this.attachment_external_remove_upload_item(id)
        },

        async mixinExternalDeleteAttachmentItem (id) {
            this.attachment_external_clear_response()
            this.attachment_external_update_delete_list(id)
            const list = _.cloneDeep(this.attachment_external_local_list)
            const index = _.findIndex(list, { 'id': id })
            if (index !== -1) list.splice(index, 1)
            this.attachment_external_update_local_list(list)
        },

        mixinExternalRemoveInvalidFiles () {
            const list = _.cloneDeep(this.attachment_external_local_list)
            list.forEach((file, index) => {
                if(file.error) this.attachment_external_remove_upload_item(file.id)
            })
            const updatedList = list.filter(file => !file.error)
            this.attachment_external_update_local_list(updatedList)

            if (_.size(updatedList) === _.size(list)) return
            this.$notify('error', 'One or more files could not be uploaded due to their size (>100M) or unsupported format', { maxWidth: 400 })
        },

        mixinExternalClearAttachmentUpdate () {
            this.attachment_external_clear_delete_list()
            this.attachment_external_clear_upload_list()
            this.attachment_external_clear_response()
        },

        mixinExternalUpdateLocalAttachmentList (params) {
            const { list } = params || {}
            this.attachment_external_update_local_list(list)
        },

        mixinExternalPreserveListChanges (params) {
            const { list, type } = params || {}
            const preservedList = list.filter(file => {
                return !this.attachment_external_delete_list.includes(file.id)
            })

            const updateList = type === 'load_more' ? [...this.attachment_external_upload_list, ...preservedList] : [...preservedList]
            this.mixinExternalUpdateLocalAttachmentList({ list: updateList })
        },

        mixinExternalHandleAttachmentErrors () {
            this.$notify('error', 'Error occured while updating attachments')
        },

        ...mapActions('AttachmentExternal', {
            attachment_external_update_local_list: 'update_local_list',
            attachment_external_update_delete_list: 'update_delete_list',
            attachment_external_remove_upload_item: 'remove_upload_list_item',
            attachment_external_clear_upload_list: 'clear_upload_list',
            attachment_external_clear_delete_list: 'clear_delete_list',
            attachment_external_clear_response: 'clear_response',
        }),
    }
}

<template>
    <div class="text-center">
        <div class="text-center mb-2" v-if="logo">
            <!-- <a-avatar size="32">
            </a-avatar> -->
            <img src="../../assets/images/success-imgs/logo-success-full.svg" style="max-width: 256px; max-height: 20px">
        </div>
        <span class="body-2" style="color: #A6A7B8 !important; font-size: 14px !important;">
            By logging in, you agree to the
            <a href="https://www.success.app/policy/pudding-app-user-terms-of-service/" style="color: #a3a3a3 !important;" class="grey--text text--darken-1 text-decoration-underline"> terms of service</a>,
            <a href="https://www.success.app/policy/privacy-policy/" style="color: #a3a3a3 !important;" class="grey--text text--darken-1 text-decoration-underline"> privacy policy</a> and
            <a href="https://www.success.app/terms/" style="color: #a3a3a3 !important;" class="grey--text text--darken-1 text-decoration-underline"> other terms and policies</a>.
        </span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: ['logo'],

    computed: {
        ...mapGetters('Auth',{
            auth_organization: 'organization',
        })
    },
}
</script>

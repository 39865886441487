<template>
	<a-main>
        <a-container grid-list-xl container--fluid class="px-0 pt-2">
            <g-page-header
                :title="`${local_route_name ? local_route_name + ' Fields' : 'Custom Fields'}`"
                :subtitle="`Manage ${local_route_name ? local_route_name.toLowerCase() : 'custom'} fields under your organization.`"
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                icon="ballot"
                class="px-0 mb-1"
            >
                <template v-slot:action>
                    <a-btn v-if="check_custom_field_permission('store')" class="ma-0 elevation-0 px-4 primary" @click="local_custom_field_create()">
                        <a-icon left class="mr-1">add</a-icon>Create New Field
                    </a-btn>
                </template>
            </g-page-header>
        </a-container>
        <a-container v-if="local_loading" grid-list-xl container--fluid class="pa-6 px-0 pr-8 mb-12 pt-2">
            <a-layout>
                <a-flex>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Custom Fields..</h3>
                    </a-responsive>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container v-if="!local_loading && (custom_fields_list && !custom_fields_list.length)" grid-list-xl class="px-0 mb-12 pt-2 pb-0 mb-10">
            <a-layout>
                <a-flex>
                    <a-responsive class="my-4">
                        <a-divider class="grey lighten-2 mb-4"></a-divider>
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                        <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
                    </a-responsive>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container v-if="!local_loading && (custom_fields_list && custom_fields_list.length)" grid-list-xl class="px-0 mb-12 pt-2 pb-0 mb-10">
            <a-sheet v-if="$can('layout_designer.index')" color="deep-purple lighten-5" height="44" class="pr-3 mb-5 u-rounded-corners u-flex-center-y u-cursor-pointer" @click="$router.push({ name: 'layout-designer' })">
                <a-sheet color="deep-purple" class="px-3 u-flex-center u-hfull" style="borderRadius: 4px 0px 0px 4px;">
                    <a-icon size="18" color="white">space_dashboard</a-icon>
                </a-sheet>
                <h3 class="ml-3 md-subtitle-1 deep-purple--text text--lighten-1 font-weight-medium u-flex align-center">
                    Check out our new Layout Designer to customize the "{{local_route_name}}
                    <a-icon size="16" color="deep-purple lighten-1" class="mx-1">arrow_forward</a-icon>
                    <span>{{ local_route_name === 'Project' ? 'Fields & Tags' : 'Fields' }}</span>
                    " layout.
                </h3>
                <a-spacer></a-spacer>
                <a-icon size="20" color="deep-purple lighten-1">arrow_forward</a-icon>
            </a-sheet>
            <draggable v-model="custom_fields_list" :options="{handle: '.js-drag-handle'}">
                <a-card v-for="(field) in custom_fields_list" class="u-elevation-custom-1 mb-1" :key="field.id">
                    <a-card-text class="pa-0">
                        <a-container container--fluid grid-list-xl class="py-4 px-6">
                            <a-layout wrap align-center>
                                <!-- <a-flex shrink class="px-0" v-if="check_custom_field_permission('update')">
                                    <a-icon class="grey--text text--lighten-1 u-cursor-pointer js-drag-handle">drag_indicator</a-icon>
                                </a-flex> -->
                                <a-flex shrink v-if="field">
                                    <a-icon size="24" color="grey lighten-1">{{ local_get_custom_field_type_item(field.type) }}</a-icon>
                                </a-flex>
                                <a-flex shrink class="pl-0">
                                    <h2 class="md-subtitle-1 font-weight-medium mb-0">
                                        {{ field.label }}
                                        <span class="ml-2 md-body-2 font-weight-medium grey--text">{{ field.name }}</span>
                                    </h2>
                                </a-flex>
                                <a-spacer></a-spacer>
                                <a-flex shrink v-if="check_custom_field_permission('update')">
                                    <a-btn icon text class="ma-0 pa-1" v-if="field.is_active" v-ripple @click="local_custom_field_toggle_active(field.id, !field.is_active)">
                                        <svg width="24px" height="24px" viewBox="0 0 16 16" class="bi bi-toggle2-on green--text text--darken-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 5H3a3 3 0 0 0 0 6h4a4.995 4.995 0 0 1-.584-1H3a2 2 0 1 1 0-4h3.416c.156-.357.352-.692.584-1z"/>
                                            <path d="M16 8A5 5 0 1 1 6 8a5 5 0 0 1 10 0z"/>
                                        </svg>
                                    </a-btn>
                                    <a-btn icon text class="ma-0 pa-1" v-else v-ripple @click="local_custom_field_toggle_active(field.id, !field.is_active)">
                                        <svg width="24px" height="24px" viewBox="0 0 16 16" class="bi bi-toggle2-off grey--text text--darken-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 11c.628-.836 1-1.874 1-3a4.978 4.978 0 0 0-1-3h4a3 3 0 1 1 0 6H9z"/>
                                            <path fill-rule="evenodd" d="M5 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 1A5 5 0 1 0 5 3a5 5 0 0 0 0 10z"/>
                                        </svg>
                                    </a-btn>
                                </a-flex>
                                <a-flex shrink class="pl-0" v-if="check_custom_field_permission('update') || check_custom_field_permission('destroy')">
                                    <a-tooltip bottom v-if="check_custom_field_permission('update')">
                                        <template v-slot:activator="{ on }">
                                            <a-btn color="grey darken-2" class="mx-2" @click="local_open_clone_custom_field(field.id)" v-on="on" text icon small>
                                                <a-icon small>content_copy</a-icon>
                                            </a-btn>
                                        </template>
                                        <span>Duplicate Custom Field</span>
                                    </a-tooltip>
                                    <a-tooltip bottom v-if="check_custom_field_permission('update')">
                                        <template v-slot:activator="{ on }">
                                            <a-btn color="grey darken-2" class="mx-2" @click="local_custom_field_edit(field.id)" v-on="on" text icon small>
                                                <a-icon small>edit</a-icon>
                                            </a-btn>
                                        </template>
                                        <span>Edit Custom Field</span>
                                    </a-tooltip>
                                    <a-menu v-if="check_custom_field_permission('destroy')" bottom left offset-y :close-on-content-click="false" content-class="u-elevation-custom-1 u-rounded-corners u-border" max-width="250">
                                        <template v-slot:activator="{ on }">
                                            <a-btn icon text color="grey darken-2" v-on="on">
                                                <a-icon size="16">delete</a-icon>
                                            </a-btn>
                                        </template>
                                        <a-card class="u-rounded-corners">
                                            <a-card-text class="pa-3">
                                                <p class="md-body-2 mb-2 grey--text text--darken-1">This will delete all the contents of the field and cannot be undone.</p>
                                                <a-btn class="ma-0 elevation-0 u-rounded-corners" color="red" @click="local_custom_field_destroy(field.id)" :loading="loading" dark small>
                                                    Confirm Delete
                                                </a-btn>
                                            </a-card-text>
                                        </a-card>
                                    </a-menu>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-card-text>
                </a-card>
            </draggable>
        </a-container>

        <!-- Custom field dialog -->
        <a-dialog v-model="dialog_custom_field_form" max-width="640" scrollable persistent>
            <a-card>
                <a-sheet class="u-flex py-6 px-8">
                    <h2 class="body-4 font-weight-medium indigo--text text--darken-1">
                        <a-icon class="mr-1 u-icon-nudge" color="indigo darken-1" size="32" style="top: -3px;">ballot</a-icon>
                        {{ custom_field_form_mode !== 'edit' ? `${custom_field_form_mode === 'clone' ? 'Clone' : 'Create'}` : 'Edit'}} Custom Field
                    </h2>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <a-card-text class="pa-0" ref="refCustomFieldForm" style="max-height: 700px;">
                    <a-sheet class="py-6 px-8 c-custom-field-input">
                        <div v-if="clone_mode" class="mb-6">
                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium grey--text text--darken-1">Select custom field type <span class="red--text text--darken-2">*</span></label>
                            <a-select
                                v-model="custom_field_item.resource_type"
                                :items="local_custom_field_list_global"
                                return-object
                                placeholder="Select type"
                                background-color="grey lighten-5"
                                class="u-border"
                                solo flat hide-details
                            ></a-select>
                            <span class="md-caption grey--text text--darken-1 d-block mt-2">
                                <a-icon size="16" class="u-icon-nudge">info</a-icon>
                                Select field type to clone from.
                            </span>
                        </div>
                        <div>
                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium grey--text text--darken-1">Label<span class="red--text text--darken-2">*</span></label>
                            <a-text-field
                                v-model="custom_field_item.label"
                                placeholder="Eg: Salesforce ID, New Task ID"
                                background-color="grey lighten-5"
                                class="u-border"
                                solo flat hide-details autofocus
                                @input="local_generate_name()"
                            >
                            </a-text-field>
                            <span class="md-caption red--text text--darken-1 d-block mt-2" v-if="custom_field_item && $response(custom_field_response, 'label')">
                                <a-icon size="16" color="red darken-2" class="u-icon-nudge">info</a-icon>
                                {{ custom_field_response.server.errors.label[0] }}
                            </span>
                            <span class="md-caption grey--text text--darken-1 d-block mt-2" v-else>
                                <a-icon size="16" class="u-icon-nudge">info</a-icon>
                                The label will be helpful to identify the field.
                            </span>
                        </div>
                        <div class="mt-6" v-if="!custom_field_item.id || clone_mode">
                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium grey--text text--darken-1">Identifier<span class="red--text text--darken-2">*</span></label>
                            <a-text-field
                                v-model="custom_field_item.name"
                                placeholder="Eg: salesforce_id, new_task_id"
                                background-color="grey lighten-5"
                                class="u-border"
                                solo flat hide-details
                            >
                            </a-text-field>
                            <span class="md-caption red--text text--darken-1 d-block mt-2" v-if="custom_field_item && $response(custom_field_response, 'name')">
                                <a-icon size="16" color="red darken-2" class="u-icon-nudge">info</a-icon>
                                {{ custom_field_response.server.errors.name[0] }}
                            </span>
                            <span class="md-caption grey--text text--darken-1 d-block mt-2" v-else>
                                <a-icon size="16" class="u-icon-nudge">info</a-icon>
                                The identifier is used when interacting with Success via public API.
                            </span>
                        </div>
                        <div class="mt-6">
                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium grey--text text--darken-1">Type<span class="red--text text--darken-2">*</span></label>
                            <a-autocomplete
                                v-model="custom_field_item.type"
                                :items="local_custom_field_types"
                                item-text="name"
                                item-value="value"
                                placeholder="Choose a field type"
                                class="u-border"
                                background-color="grey lighten-5"
                                solo flat hide-details
                                :disabled="custom_field_form_mode === 'edit'"
                                @input="local_add_optional_list()"
                            >
                                <template v-slot:selection="{ item }">
                                    <span class="grey--text text--darken-3" style="font-size: 16px;">
                                        <a-icon size="20" color="grey" class="mr-1 u-icon-nudge">{{ local_get_custom_field_type_item(item.value) }}</a-icon>
                                        {{ item.name }}
                                    </span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <span class="grey--text text--darken-3" style="font-size: 16px;">
                                        <a-icon size="20" color="grey" class="mr-1 u-icon-nudge">{{ local_get_custom_field_type_item(item.value) }}</a-icon>
                                        {{ item.name }}
                                    </span>
                                </template>
                            </a-autocomplete>
                            <span class="md-caption red--text text--darken-1 d-block mt-2" v-if="custom_field_item && !custom_field_item.type && custom_field_response.server && custom_field_response.server.errors && custom_field_response.server.errors.type">
                                <a-icon size="16" color="red darken-2" class="u-icon-nudge">info</a-icon>
                                {{ custom_field_response.server.errors.type[0] }}
                            </span>
                            <span class="md-caption grey--text text--darken-1 d-block mt-2" v-else>
                                <a-icon size="16" class="u-icon-nudge">info</a-icon>
                                This will be helpful to identify the type of custom field input.
                            </span>
                            <template v-if="custom_field_item.type === 'dropdown'">
                                <label class="mt-5 md-body-2 d-block mb-2 mt-0 font-weight-medium grey--text text--darken-1">
                                    Label & Value
                                    <span class="red--text text--darken-2">*</span>
                                </label>
                                <draggable v-model="local_optional_list" @end="local_move" tag="div" class="" :options="{handle: '.js-drag-handle'}">
                                    <div class="js-draggable-file-list-item u-flex align-center mb-4" v-for="(option, index) in local_optional_list" :key="index">
                                        <div class="u-wfull">
                                            <div class="u-flex align-center">
                                                <a-sheet min-height="48" class="js-drag-handle u-flex align-center justify-center pr-2">
                                                    <a-icon class="grey--text text--lighten-1 u-cursor-pointer">drag_indicator</a-icon>
                                                </a-sheet>
                                                <a-tooltip bottom offset-y :disabled="!(custom_field_response.server && custom_field_response.server.errors && custom_field_response.server.errors[`options.${index}.label`])">
                                                    <template v-slot:activator="{ on }">
                                                        <a-text-field
                                                            v-on="on"
                                                            v-model="option.label"
                                                            placeholder="Enter the label"
                                                            background-color="grey lighten-5"
                                                            :class="[custom_field_response.server && custom_field_response.server.errors && custom_field_response.server.errors[`options.${index}.label`] ? 'c-wiggle-short u-border-error': 'u-border']"
                                                            solo flat hide-details
                                                        >
                                                        </a-text-field>
                                                    </template>
                                                    <span class="py-3" v-if="custom_field_response.server && custom_field_response.server.errors && custom_field_response.server.errors[`options.${index}.label`]">{{ custom_field_response.server.errors[`options.${index}.label`][0] }}</span>
                                                </a-tooltip>
                                            </div>
                                        </div>
                                        <div class="u-wfull ml-3">
                                            <a-tooltip bottom offset-y :disabled="!(custom_field_response.server && custom_field_response.server.errors && custom_field_response.server.errors[`options.${index}.value`])">
                                                <template v-slot:activator="{ on }">
                                                    <a-text-field
                                                        v-on="on"
                                                        v-model="option.value"
                                                        placeholder="Enter the value"
                                                        background-color="grey lighten-5"
                                                        :class="[custom_field_response.server && custom_field_response.server.errors && custom_field_response.server.errors[`options.${index}.value`] ? 'c-wiggle-short u-border-error': 'u-border']"
                                                        solo flat hide-details
                                                    >
                                                    </a-text-field>
                                                </template>
                                                <span class="py-3" v-if="custom_field_response.server && custom_field_response.server.errors && custom_field_response.server.errors[`options.${index}.value`]">{{ custom_field_response.server.errors[`options.${index}.value`][0] }}</span>
                                            </a-tooltip>
                                        </div>
                                        <div class="u-flex u-border align-center justify-center mt-0 ml-3 u-rounded-corners" :class="[loading_item_index === index ? 'red lighten-5' : '']" style="min-height: 40px !important;">
                                            <a-tooltip bottom v-if="optional_list && optional_list.length === 1">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn icon text color="grey" v-on="on">
                                                        <a-icon color="grey" size="22">remove_circle_outline</a-icon>
                                                    </a-btn>
                                                </template>
                                                <span>Minimum one value required</span>
                                            </a-tooltip>
                                            <a-tooltip bottom v-else-if="loading_item_index !== index">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn icon text color="grey" @click="loading_item_index = index" v-on="on">
                                                        <a-icon color="grey" size="22">remove_circle_outline</a-icon>
                                                    </a-btn>
                                                </template>
                                                <span v-if="optional_list && optional_list.length === 1">Minimum one value required</span>
                                                <span v-else>Remove Data</span>
                                            </a-tooltip>
                                            <a-tooltip bottom v-if="loading_item_index === index">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn icon text color="red" @click="local_remove_optional_list(index)" v-on="on">
                                                        <a-icon color="red" size="22">remove_circle</a-icon>
                                                    </a-btn>
                                                </template>
                                                <span>Confirm?</span>
                                            </a-tooltip>
                                        </div>
                                    </div>
                                </draggable>
                                <div class="mt-4">
                                    <a-sheet
                                        @click="local_add_optional_list(true)"
                                        width="122"
                                        height="36"
                                        class="u-border grey lighten-5 grey--text text--darken-1 u-cursor-pointer ml-8 text-uppercase md-caption font-weight-medium u-rounded-corners py-2"
                                        style="border: 1px solid #eeeeee !important;"
                                    >
                                        <a-icon size="16" class="ml-2 mr-1">add</a-icon>
                                        Add Option
                                    </a-sheet>
                                </div>
                            </template>
                        </div>
                        <div class="mt-6">
                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium grey--text text--darken-1">Description</label>
                            <a-textarea
                                v-model="custom_field_item.description"
                                placeholder="Enter the description here..."
                                background-color="grey lighten-5"
                                class="u-border"
                                rows="2"
                                solo flat hide-details multiple chips small-chips no-resize
                            >
                            </a-textarea>
                            <div class="u-flex u-wfull">
                                <span class="md-caption red--text text--darken-1 d-block mt-2" v-if="custom_field_response.server && custom_field_response.server.errors && custom_field_response.server.errors.description">
                                    <a-icon size="16" color="red darken-2" class="u-icon-nudge">info</a-icon>
                                    {{ custom_field_response.server.errors.description[0] }}
                                </span>
                                <span class="md-caption grey--text text--darken-1 d-block mt-2" v-else>
                                    <a-icon size="16" class="u-icon-nudge">info</a-icon>
                                    Describe more about the field here
                                </span>
                                <a-spacer></a-spacer>
                                <span class="md-caption grey--text mt-2 text-right">(Optional)</span>
                            </div>
                        </div>
                        <div class="mt-6">
                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium grey--text text--darken-1">Placeholder</label>
                            <a-text-field
                                v-model="custom_field_item.placeholder"
                                placeholder="Enter the placeholder"
                                background-color="grey lighten-5"
                                class="u-border"
                                solo flat hide-details
                            >
                            </a-text-field>
                            <div class="u-flex u-wfull">
                                <span class="md-caption red--text text--darken-1 d-block mt-2" v-if="custom_field_response.server && custom_field_response.server.errors && custom_field_response.server.errors.placeholder">
                                    <a-icon size="16" color="red darken-2" class="u-icon-nudge-small">info</a-icon>
                                    {{ custom_field_response.server.errors.placeholder[0] }}
                                </span>
                                <span class="md-caption grey--text text--darken-1 d-block mt-2" v-else>
                                    <a-icon size="16" class="u-icon-nudge">info</a-icon>
                                    This placeholder text will be visible inside label field.
                                </span>
                                <a-spacer></a-spacer>
                                <span class="md-caption grey--text mt-2 text-right">(Optional)</span>
                            </div>
                        </div>
                        <div class="mt-6">
                            <p class="text-sm-body-1 u-cursor-pointer d-inline-block grey--text text--darken-1 pa-0 ma-0" @click="custom_field_ready_only = !custom_field_ready_only">
                                <span class="u-icon-nudge"><a-icon size="18" :color="!custom_field_ready_only ? 'grey darken-1' : 'blue'" :outlined="!custom_field_ready_only">{{ !custom_field_ready_only ? 'check_box_outline_blank' : 'check_box' }}</a-icon></span>
                                Read-only
                            </p>
                            <div class="blue lighten-5 mt-2 u-rounded-corners-lg u-flex-center-y py-2 px-4">
                                <a-icon size="24" color="blue darken-2">info</a-icon>
                                <span class="body-2 font-weight-normal ml-2 blue--text text--darken-2">The read-only fields can only be updated via Public API and Workflows.</span>
                            </div>
                        </div>
                    </a-sheet>
                </a-card-text>
                <a-divider class="grey lighten-3"></a-divider>
                <a-sheet class="py-6 px-8">
                    <div class="u-flex">
                        <a-btn class="indigo darken-1 white--text u-rounded-corners elevation-0" @click="local_custom_field_store()" :loading="loading" :disabled="(custom_field_response.server && custom_field_response.server.errors && local_custom_field_form_validation) || loading">{{ custom_field_form_mode === 'add' ? 'Save' : (custom_field_form_mode === 'edit' ? 'Update' : 'Clone')}} Field</a-btn>
                        <a-btn depressed  text @click.prevent="local_close_dialog()" :disabled ="loading" class="ml-4 grey lighten-3 grey--text text--darken-1 u-rounded-corners">
                            Cancel
                        </a-btn>
                        <div class="ml-4 red--text text--darken-1 py-1 px-3 red lighten-5 u-rounded-corners" v-if="custom_field_response.server && custom_field_response.server.errors">
                            <p class="md-caption-3 ma-0 pa-0">
                                <a-icon size="16" color="red darken-1" class="u-icon-nudge">info</a-icon>
                                One or more fields are invalid
                            </p>
                        </div>
                    </div>
                </a-sheet>
            </a-card>
        </a-dialog>

	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

export default {
    data() {
        return {
            dialog_custom_field_form: false,
            dialog_custom_new_field_form: false,
            clone_mode: false,
            local_loading: true,
            optional_list: [],
            loading_item_index: null,
            local_optional_status: false,
            custom_field_ready_only: false,
            custom_field_types: [
                { type: 'text', icon: 'text_fields' },
                { type: 'multiline', icon: 'segment' },
                { type: 'dropdown', icon: 'arrow_drop_down_circle' },
                { type: 'mask', icon: 'password' },
                { type: 'date', icon: 'calendar_month' },
                { type: 'tags', icon: 'sell' },
                { type: 'number', icon: 'pin' },
                { type: 'url', icon: 'link' },
            ],
        }
    },
    watch: {
        '$route.params.id': {
            handler ()  {
                this.local_index()
            },
            deep: true,
        },
    },
    mounted() {
        this.local_index()
    },
    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Custom Fields', to: '/settings/custom-fields', exact: true})
            return breadcrumbs
        },
        custom_fields_list: {
            get() {
                return this.$store.state.CustomField.list
            },
            set(list) {
                this.local_custom_field_reorder(list)
            }
        },
        local_optional_list: {
            get() {
                return this.optional_list
            },
            set(list) {
                this.optional_list = _.cloneDeep(list)
            }
        },
        local_custom_field_types() {
            return [
                {name: 'Text', value: 'text'}, 
                {name: 'Text (Multi-line)', value: 'multiline'}, 
                {name: 'Mask', value: 'mask'}, 
                {name: 'Dropdown', value: 'dropdown'}, 
                { name: 'Date', value: 'date' },
                { name: 'URL', value: 'url' },
                { name: 'Number', value: 'number' }
            ]
        },
        local_custom_field_form_validation() {
            const { label, name, type } = this.custom_field_item
            return !(_.trim(label) && _.trim(name) && type)
        },
        local_route_name () {
            const name = this.$route.params.id
            return name.charAt(0).toUpperCase() + name.slice(1)
        },
        local_custom_field_list_global () {
            return ['Project', 'Ticket']
        },
        ...mapState('CustomField', {
            custom_field_item: 'item',
            custom_field_form_mode: 'form_mode',
            custom_field_response: 'response',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            this.dialog_custom_new_field_form = true
            this.local_loading = true
            await this.custom_field_clear()
            await this.custom_field_index({
                'fields[custom_fields]': 'id,name,label,is_active,order,resource_type,placeholder,type,options,user_writable,description',
                'filter[resource_type]': this.local_route_name
            })
            this.local_loading = false
        },
        local_add_optional_list(add = false) {
            if (this.custom_field_item.type === 'multiline') {
                this.custom_field_item.description = `This is a ${this.custom_field_item.type} text field.`
            } else {
                this.custom_field_item.description = `This is a ${this.custom_field_item.type} field.`
            }
            // this.custom_field_item.description = `This is a ${this.custom_field_item.type} field.`
            if(this.custom_field_item.type === 'dropdown' && !add) {
                if(this.optional_list && this.optional_list.length === 0) {
                    this.optional_list.push({id: uuidv4(), label: '', value: '' })
                }
            }
            if(this.custom_field_item.type === 'dropdown' && add) {
                this.optional_list.push({id: uuidv4(), label: '', value: '' })
            }
            if(this.custom_field_item.type === 'text' || this.custom_field_item.type === 'mask') {
                this.optional_list = []
            }
            setTimeout(() => this.$refs.refCustomFieldForm.ref.scrollTop = this.$refs.refCustomFieldForm.ref.scrollHeight, 0);
        },

        local_remove_optional_list(index) {
            this.optional_list.splice(index, 1)
            this.loading_item_index = null
        },

        async local_open_clone_custom_field (field_id) {
            await this.custom_field_select({id: field_id})
            this.custom_field_toggle_mode('clone')
            if (this.custom_field_item.options && this.custom_field_item.options.length) {
                this.optional_list = [...this.custom_field_item.options ]
                this.local_optional_status === true
            } else delete this.custom_field_item.options
            this.custom_field_item.custom_field_id = field_id
            this.custom_field_item.label = this.custom_field_item.label + ' copy'
            this.custom_field_ready_only = this.custom_field_item.user_writable ? false : true
            this.clone_mode = true
            this.local_generate_name()
            this.dialog_custom_field_form = true
        },

        local_get_custom_field_type_item(type) {
            if(!type) return null
            const custom_field_item = this.custom_field_types.find(item => item.type === type)
            if (custom_field_item) return custom_field_item.icon
        },

        async local_custom_field_edit(id) {
            this.loading_item_index = null
            this.local_optional_status === false
            this.optional_list = []
            await this.custom_field_clear_item()
            await this.custom_field_select({id: id})
            this.custom_field_ready_only = this.custom_field_item.user_writable ? false : true
            if(this.custom_field_item.options && this.custom_field_item.options.length) {
                this.optional_list = [...this.custom_field_item.options ]
                this.local_optional_status === true
            }else{
                delete this.custom_field_item.options
            }
            this.dialog_custom_field_form = true
        },

        async local_custom_field_create() {
            this.loading_item_index = null
            this.custom_field_ready_only = false
            this.local_optional_status = false
            this.clone_mode = false
            this.optional_list = []
            await this.custom_field_clear_item()
            this.dialog_custom_field_form = true
        },

        async local_custom_field_store() {
            if (this.custom_field_form_mode === 'add') await this.local_store_custom_field()
            if (this.custom_field_form_mode === 'edit') await this.local_update_custom_field()
            if (this.custom_field_form_mode === 'clone') await this.local_clone_custom_field()
            if (this.custom_field_response.status === 'success') this.local_close_dialog()
        },

        async local_store_custom_field () {
            this.custom_field_item.label = this.custom_field_item.label ? this.custom_field_item.label.trim() : null
            this.custom_field_item.order = -1
            this.custom_field_item.resource_type = this.local_route_name
            this.custom_field_item.user_writable = this.custom_field_ready_only ? 0 : 1
            this.custom_field_item.options = this.optional_list
            await this.custom_field_store({ ...this.custom_field_item })
        },

        async local_clone_custom_field () {
            this.custom_field_item.label = this.custom_field_item.label ? this.custom_field_item.label.trim() : null
            this.custom_field_item.order = -1
            this.custom_field_item.options = this.optional_list
            delete this.custom_field_item.id
            await this.custom_field_clone({ ...this.custom_field_item, currentType: this.local_route_name })
        },

        async local_update_custom_field () {
            if (this.optional_list && this.optional_list.length) this.custom_field_item.options = [...this.optional_list]
            this.custom_field_item.user_writable = this.custom_field_ready_only ? 0 : 1
            this.custom_field_item.label = this.custom_field_item.label ? this.custom_field_item.label.trim() : null
            await this.custom_field_update()
        },

        local_move() {
            this.loading_item_index = null
        },

        async local_custom_field_toggle_active(id, is_active) {
            await this.custom_field_toggle_active({ id: id, state: is_active ? 1 : 0 })
        },

        local_close_dialog() {
            this.custom_field_toggle_mode('add')
            this.dialog_custom_field_form = false
            this.clone_mode = false
        },

        async local_custom_field_destroy(id) {
            await this.custom_field_destroy({id: id})
        },

        async local_custom_field_reorder(list) {
            this.custom_field_reorder({list: list})
        },

        async local_generate_name() {
            if ((this.custom_field_item && !this.custom_field_item.id) || this.clone_mode) {
                this.custom_field_item.name = _.snakeCase(this.custom_field_item.label)
            }
        },

        check_custom_field_permission(type, client_allowed = false) {
            if (this.user_self.role.scope === 'external') {
                return client_allowed
            }
            return this.$can(`custom_fields.${type}`) && this.user_self.role.scope === 'internal'
        },

        ...mapActions('CustomField', {
            custom_field_index: 'index',
            custom_field_store: 'store',
            custom_field_clone: 'clone_store',
            custom_field_update: 'update',
            custom_field_select: 'select',
            custom_field_toggle_mode: 'toggle_form_mode',
            custom_field_toggle_active: 'toggle_active',
            custom_field_destroy: 'destroy',
            custom_field_reorder: 'reorder',
            custom_field_clear: 'clear',
            custom_field_clear_item: 'clear_item',
        }),
    },
}
</script>



<template>
	<a-main>
        <a-responsive class="mt-4 px-0 u-rounded-corners-lg px-4">
            <a-container grid-list-xl container--fluid class="py-2 pb-0">
                <a-layout align-start class="pb-2">
                    <a-flex shrink class="px-0">
                        <span class="px-0 u-cursor-pointer grey--text text--darken-1 u-font-14 text-uppercase u-leading-16" @click="$router.push({name: 'customers-notes'})">
                            <a-icon class="mr-1" size="20">arrow_back</a-icon> Back to Account Notes
                        </span>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive>
        <a-container
            @dragenter="$can('notes.update') && !localAttachmentsUpdating ? localHandleDragEnter() : ''"
            @drop="localHandleDrop"
            class="pb-6 pt-0 px-0 mb-12 px-4"
            grid-list-xl container--fluid
        >
            <SDragOverlay
                v-if="filesDraggedOver"
                pageType="global"
                @leave="localHandleLeave()"
                @enter="hoveredOnInfo=false"
                @enterInfo="hoveredOnInfo=true"
            >
            </SDragOverlay>
            <a-layout wrap align-start>
                <a-flex xs12>
                    <a-card flat class="u-border u-rounded-corners u-overflow-hidden">
                        <a-card-text class="px-2 py-0">
                            <a-text-field
                                v-model="note_item.title"
                                placeholder="Enter Note Title"
                                solo flat hide-details
                                height="52px"
                                style="font-size: 20px;"
                                :readonly="!$can('notes.update')"
                                @input="local_check_notes_modified() || mixinIsLoading('notes-update')"
                            >
                            </a-text-field>
                        </a-card-text>
                    </a-card>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="note_response.server && note_response.server.errors && note_response.server.errors.title">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ note_response.server.errors.title[0] }}
                    </span>
                </a-flex>
            </a-layout>
            <a-layout wrap align-center>
                <a-flex shrink>
                    <a-menu bottom offset-y max-width="320" :disabled="!$can('notes.update') || mixinIsLoading('notes-update')">
                        <template v-slot:activator="{ on }">
                            <a-card height="40" max-width="200" min-width="200" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 u-flex-center-y" v-on="on">
                                <div class="pr-2 pl-3 u-flex-center-y text-truncate u-wfull">
                                    <h4 class="md-body-2 mr-1 text-truncate">{{ (note_item.type) ? note_item.type.value : 'No Category' }}</h4>
                                    <a-spacer></a-spacer>
                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                </div>
                            </a-card>
                        </template>
                        <a-list class="u-list-condensed">
                            <a-list-item v-for="type in type_list" :key="'status_key_' + type.value" @click="local_type_update(type.id)">
                                <a-list-item-title>
                                    <span class="md-body-2">{{ type.value }}</span>
                                </a-list-item-title>
                            </a-list-item>
                            <a-list-item :key="'status_key_none'" @click="local_type_update(null)">
                                <a-list-item-title>
                                    <span class="md-body-2">No Category</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-flex shrink class="pl-0">
                    <div class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 u-cursor-pointer">
                        <div class="u-rounded-corners u-cursor-pointer">
                            <a-menu :disabled="!$can('notes.update') || mixinIsLoading('notes-update')" offset-y min-width="308" width="308" nudge-bottom="4">
                                <template v-slot:activator="{ on }">
                                    <PartialVisibilityDisplay
                                        v-on="on"
                                        :visibility="note_item.visibility"
                                        :class="{ 'u-cursor-pointer': $can('notes.update') }"
                                        class="c-task-edit-view__meta-item--trigger u-hfull px-3 white"
                                        style="min-height: 40px;"
                                    />
                                </template>
                                <PartialVisibilityDropdown class="white">
                                    <PartialVisibilityToggleSection @change="local_visibility_toggle" :item="note_item" />
                                </PartialVisibilityDropdown>
                            </a-menu>
                        </div>
                    </div>
                </a-flex>
                <a-flex shrink class="pl-0" v-if="local_bridge_value(note_item, 'salesforce', 'account.salesforce_id')">
                    <template v-if="local_org_has_salesforce_url">
                        <a :href="user_self.organization.salesforce_instance_url + '/' + local_bridge_value(note_item, 'salesforce', 'account.salesforce_id')" target="_blank">
                            <a-card flat class="u-rounded-corners u-overflow-hidden u-border u-cursor-pointer">
                                <a-card-text class="px-3 py-1">
                                    <img src="../../assets/images/icon-salesforce.svg" height="16" alt="Salesforce" class="d-block my-1">
                                </a-card-text>
                            </a-card>
                        </a>
                    </template>
                    <span v-else>
                        <a-card flat class="u-rounded-corners u-overflow-hidden u-border u-cursor-pointer">
                            <a-card-text class="px-3 py-1">
                                <img src="../../assets/images/icon-salesforce.svg" height="16" alt="Salesforce" class="d-block my-1">
                            </a-card-text>
                        </a-card>
                    </span>
                </a-flex>
                <a-flex shrink>
                    <p class="md-caption grey--text text--darken-2 mb-0 text-xs-right" v-if="note_item.updated_by">{{ note_item.updated_by.name }} • <g-moment v-if="note_item.updated_at" :value="note_item.updated_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM D [at] hh:mma" convert-local></g-moment></p>
                    <template v-else>
                        <p class="md-caption grey--text text--darken-2 mb-0 text-xs-right" v-if="local_check_bridge(note_item, 'salesforce', 'account.salesforce_id')">Salesforce • <g-moment v-if="note_item.updated_at" :value="note_item.updated_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM D [at] hh:mma" convert-local></g-moment></p>
                    </template>
                </a-flex>
            </a-layout>
            <a-layout wrap align-center>
                <a-flex xs12>
                    <a-card flat class="u-border u-rounded-corners">
                        <a-card-title class="px-4 py-3">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <h3 class="md-caption grey--text text--darken-1 text-uppercase font-weight-bold">Content</h3>
                                </a-flex>
                                <a-spacer></a-spacer>
                                <a-flex shrink v-if="$can('notes.update')">
                                    <p class="md-caption grey--text mb-0 d-flex align-center">
                                        <em class="mr-2">Select text for formating options</em>
                                        <a-icon size="14" color="grey" class="ml-1">format_bold</a-icon>
                                        <a-icon size="14" color="grey" class="ml-1">format_italic</a-icon>
                                    </p>
                                </a-flex>
                                 <a-flex shrink class="pl-0">
                                    <a-btn small icon text color="grey lighten-1" class="ma-0" @click="accordion_note_content_expanded = !accordion_note_content_expanded">
                                        <a-icon size="16" v-if="accordion_note_content_expanded">unfold_less</a-icon>
                                        <a-icon size="16" v-else>unfold_more</a-icon>
                                    </a-btn>
                                </a-flex>
                            </a-layout>
                        </a-card-title>
                        <a-divider></a-divider>
                        <a-card-text class="px-4 pt-4 pb-2" v-if="!local_loading && !accordion_note_content_expanded">
                            <p class="grey--text text--darken-1" v-if="(!note_item.content || note_item.content && note_item.content.replace(/<[^>]*>?/gm, '') === null || note_item.content && note_item.content.replace(/<[^>]*>?/gm, '') == '') && $can('notes.update')" @click="() => { note_item.content = ' '; editor_content.focus() }">Click to edit the description</p>
                            <editor-content v-else :editor="editor_content"></editor-content>
                            <editor-menu-bubble :editor="editor_content" :keep-in-bounds="false" v-slot="{ commands, isActive, menu }">
                                <div
                                    class="menububble"
                                    v-if="$can('notes.update')"
                                    :class="{ 'is-active': menu.isActive }"
                                    :style="`left: ${menu.left - 16}px; bottom: ${menu.bottom + 8}px;`"
                                >
                                    <a-btn icon text small class="mx-0" :color="(isActive.bold()) ? 'blue' : 'grey'" @click="commands.bold">
                                        <a-icon size="16">format_bold</a-icon>
                                    </a-btn>
                                    <a-btn icon text small class="mx-0" :color="(isActive.italic()) ? 'blue' : 'grey'" @click="commands.italic">
                                        <a-icon size="16">format_italic</a-icon>
                                    </a-btn>
                                    <a-divider vertical dark></a-divider>
                                    <a-btn icon text small class="mx-0" :color="(isActive.bullet_list()) ? 'blue' : 'grey'" @click="commands.bullet_list">
                                        <a-icon size="16">format_list_bulleted</a-icon>
                                    </a-btn>
                                    <a-btn icon text small class="mx-0" :color="(isActive.ordered_list()) ? 'blue' : 'grey'" @click="commands.ordered_list">
                                        <a-icon size="16">format_list_numbered</a-icon>
                                    </a-btn>
                                </div>
                            </editor-menu-bubble>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
            <!-- Attachment section -->
            <div class="mt-8">
                <SAttachmentSection
                    :list="attachment_local_list"
                    :dropTransferList="localDroppedFilesList"
                    :sourceId="$route.params.note_id"
                    :updateLoading="localAttachmentsUpdating"
                    :loading="mixinIsLoading('attachment')"
                    :canUpdate="$can('notes.update')"
                    sourceType="Note"
                    @dropTransfer="localUpdateAttachmentTransfer"
                    @loadMore="localLoadMoreAttachments()"
                    @remove="localRemoveAttachmentItem"
                    @delete="localDeleteAttachmentItem"
                >
                </SAttachmentSection>
            </div>
            <a-layout align-center>
                <a-flex shrink class="py-0" v-if="user_self && user_self.organization && user_self.organization.salesforce_connected && note_item.project && note_item.project.salesforce_id != null">
                    <a-tooltip bottom center v-if="note_item.salesforce_id === null">
                        <template v-slot:activator="{ on }">
                            <a-btn small icon class="elevation-0 white" @click="local_salesforce_add()" :disabled="loading || timer_update_debounce" v-on="on">
                                <span class="d-block">
                                    <img src="../../assets/images/icon-salesforce.svg" class="d-block" height="12" alt="Salesforce">
                                </span>
                            </a-btn>
                        </template>
                        <span>Add to Salesforce</span>
                    </a-tooltip>
                    <a-tooltip bottom center v-else>
                        <template v-slot:activator="{ on }">
                            <a-btn small icon class="elevation-0 white" @click="local_salesforce_push(note_item.id, note_item.salesforce_target_resource)" :disabled="loading || timer_update_debounce" v-on="on">
                                <span class="d-block">
                                    <img src="../../assets/images/icon-salesforce.svg" class="d-block" height="12" alt="Salesforce">
                                </span>
                            </a-btn>
                        </template>
                        <span>Sync with Salesforce {{ note_item.salesforce_target_resource }}</span>
                    </a-tooltip>
                    <a-tooltip bottom center v-if="note_item.salesforce_id !== null">
                        <template v-slot:activator="{ on }">
                            <a-btn small icon class="elevation-0 white" @click="local_salesforce_add()" :disabled="loading || timer_update_debounce" v-on="on">
                                <a-icon size="16">
                                    shuffle
                                </a-icon>
                            </a-btn>
                        </template>
                        <span>Change Salesforce Destination</span>
                    </a-tooltip>
                    <a-tooltip bottom center v-if="note_item.salesforce_id && user_self && user_self.organization && user_self.organization.salesforce_instance_url">
                        <template v-slot:activator="{ on }">
                            <a-btn
                                :href="user_self.organization.salesforce_instance_url + '/' + note_item.salesforce_id"
                                class="elevation-0 white"
                                target="_blank"
                                rel="noopener"
                                v-on="on"
                                small icon
                                :disabled="loading || timer_update_debounce"
                            >
                                <a-icon size="16">open_in_browser</a-icon>
                            </a-btn>
                        </template>
                        <span>Open Note in Salesforce</span>
                    </a-tooltip>
                </a-flex>
            </a-layout>
            <div class="c-update-container u-rounded-corners py-3 px-5 mt-6" v-if="$can('notes.update')">
                <div class="u-flex justify-space-between">
                    <div class="u-flex">
                        <a-btn
                            height="32"
                            color="#3949AB"
                            tag="a"
                            :loading="loading || mixinIsLoading('attachment-update') || mixinIsLoading('notes-update')"
                            :disabled="!notes_modified"
                            @click="local_update(note_item)"
                            class="c-save-btn mr-4 ma-0 px-2 py-3 u-flex align-center white--text text--darken-2 u-rounded-corners"
                            small depressed
                        >
                            Save
                        </a-btn>
                        <a-btn
                            height="32"
                            color="#EEEEEE"
                            tag="a"
                            :disabled="!notes_modified || loading || mixinIsLoading('attachment-update') || mixinIsLoading('notes-update')"
                            @click="dialog_cancel_changes = true"
                            class="ma-0 px-2 py-3 u-flex align-center text--darken-2 u-rounded-corners"
                            small depressed
                        >
                            <span style="color: #9E9E9E">Cancel</span>
                        </a-btn>
                        <div class="px-4 d-flex align-center ml-4" v-if="mixinIsLoading('attachment-update') || mixinIsLoading('notes-update')">
                            <template v-if="mixinIsLoading('attachment-update') && (attachment_upload_list && attachment_upload_list.length)">
                                <span class="text-uppercase blue--text md-body-2">
                                    Uploading Attachments
                                </span>
                            </template>
                            <template v-else-if="mixinIsLoading('notes-update') && attachment_upload_list && !attachment_upload_list.length">
                                <span class="text-uppercase blue--text md-body-2">
                                    Saving changes
                                </span>
                            </template>
                        </div>
                    </div>
                    <div v-if="$can('notes.destroy')">
                        <a-btn
                            class="ma-0 red--text text--darken-1 u-font-weight-semibold"
                            height="32"
                            color="red lighten-5"
                            @click="!mixinIsLoading('notes-update') ? dialog_confirm_delete = true : ''"
                            :loading="loading"
                            dark small depressed
                        >
                            <span>Delete Note</span>
                        </a-btn>
                    </div>
                </div>
            </div>
        </a-container>

        <a-snackbar v-model="snackbar_status" :timeout="4000" bottom center color="green">
            <div class="text-center">
                <a-icon class="white--text pr-4">task_alt</a-icon>
                <span>{{ snackbar_text }}</span>
            </div>
        </a-snackbar>

        <!--Cancel change alert-->
        <a-dialog v-model="dialog_cancel_changes" persistent max-width="400">
            <SCancelChanges
                @success="local_cancel_changes()"
                @close="dialog_cancel_changes = false"
                class="pa-6"
            />
        </a-dialog>

        <!--Leaving page without save alert -->
        <a-dialog v-model="dialog_leave_page_alert" persistent max-width="400">
            <SCancelChanges
                @success="local_leave_page()"
                @close="dialog_leave_page_alert = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>

        <!--Delete dialog-->
        <a-dialog v-model="dialog_confirm_delete" persistent max-width="400">
            <a-card class="pa-6">
                <div class="mb-6">
                    <span class="md-heading-6 font-weight-medium grey--text text--darken-4">
                        Are you sure you want to delete this note?
                    </span>
                    <p class="mb-0 mt-3 md-subtitle-1 grey--text text--darken-2">
                        This action cannot be undone.
                    </p>
                </div>
                <div class="u-flex-center-y">
                    <a-btn color="red darken-1" height="28" depressed dark class="u-rounded-corners text-uppercase" :loading="localDeleteLoading" @click="local_destroy(note_item.id)">
                        <span class="md-caption text-uppercase white--text">
                            Yes, Delete
                        </span>
                    </a-btn>
                    <a-btn height="28" class="ml-4 u-rounded-corners text-uppercase" :disabled="localDeleteLoading" depressed @click="dialog_confirm_delete = false">
                        <span class="md-caption text-uppercase">
                            No, Cancel
                        </span>
                    </a-btn>
                </div>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
    Bold,
    Italic,
    Strike,
    OrderedList,
    BulletList,
    ListItem,
    History,
} from 'tiptap-extensions'
import marked from 'marked'
import PartialVisibilityToggleSection from '@/pages/Projects/Partials/PartialVisibilityToggleSection.vue'
import PartialVisibilityDropdown from '@/pages/Projects/Partials/PartialVisibilityDropdown.vue'
import PartialVisibilityDisplay from '@/pages/Projects/Partials/PartialVisibilityDisplay.vue'
import mixinLoader from '@/mixins/mixin-module-loading-setup'
import mixinAttachmentsInternal from '@/mixins/mixin-internal-attachment'
import SDragOverlay from '@/components/Attachments/SharedDragOverlay.vue'
import { SCancelChanges, SAttachmentSection } from '@/config/config-shared-components'

export default {
    mixins: [mixinLoader, mixinAttachmentsInternal],

    components: {
        EditorContent,
        EditorMenuBubble,
        SCancelChanges,
        PartialVisibilityToggleSection,
        PartialVisibilityDropdown,
        PartialVisibilityDisplay,
        SDragOverlay,
        SAttachmentSection
    },

    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            edit_mode: false,
            editor_content: null,
            timer_update_debounce: null,
            update_autosaved_at: null,
            salesforce_sync_status: true,
            dialog_salesforce_source_form: false,
            snackbar_status: false,
            snackbar_text: null,
            type_list: [],
            note_type: '',
            local_note_title: '',
            local_note_content: '',
            local_loading: true,
            accordion_note_content_expanded: false,
            local_customer_note_fields: { 'include': 'type,updatedBy,bridges', 'fields[type]': 'id,type,value', 'sort': 'order'},
            notes_modified: false,
            local_visibility_note_item: {},
            stored_note: {},
            dialog_cancel_changes: false,
            dialog_leave_page_alert: false,
            to_page_route: null,
            dialog_confirm_delete: false,

            //Attachments
            filesDraggedOver: false,
            hoveredOnInfo: false,
            FILTER: {
                parent_id: 'filter[parent_id]',
                parent_type: 'filter[parent_type]',
                source_id: 'filter[source_id]',
                source_type: 'filter[source_type]',
                fields: 'fields[attachments]',
                page: 'page'
            },
            attachment_fields: 'id,name,size,extension,order,source_id,source_type,parent_id,parent_type',
            localDroppedFilesList: [],
            localAttachmentFilters: {},
            backupAttachmentList: [],
            localDeleteLoading: false
        }
    },

    mounted () {
        this.local_index()
        if (this.$can('notes.update')) this.localAddListeners()
    },

    async beforeDestroy () {
        if (this.editor_content != null) {
            this.editor_content.destroy()
        }
        this.localRemoveListeners()
    },

    beforeRouteLeave (toPage, fromPage, next) {
        if (!this.notes_modified) return next()
        next(false)
        this.dialog_leave_page_alert = true
        this.to_page_route = next
    },

    computed: {
        local_org_has_salesforce_url() {
            return this.user_self && this.user_self.organization && this.user_self.organization.salesforce_instance_url
        },

        localCheckAttachmentUpdate () {
            return !!_.size(this.attachment_upload_list) || !!_.size(this.attachment_delete_list)
        },

        localAttachmentsUpdating () {
            return this.mixinIsLoading('attachment-update') || this.mixinIsLoading('attachment-delete')
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Note', {
            note_item: 'item',
            note_response: 'response',
            note_form_mode: 'form_mode',
        }),

        ...mapState('Project', {
            project_item: 'item',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
            meta_filters: 'filters',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('Attachment', {
            attachment_list: 'list',
            attachment_local_list: 'local_list',
            attachment_delete_list: 'delete_list',
            attachment_upload_list: 'upload_list',
            attachment_meta: 'meta',
            attachment_response: 'response',
            attachment_delete_response: 'delete_response',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('notes.show') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.note_clear_item()
            await this.meta_clear()
            await this.local_note_show()

            this.local_note_title = this.note_item.title
            this.local_note_content = this.note_item.content

            this.localLoadNoteAttachments()

            await this.meta_index({ 'filter[type]': 'note_type', 'sort': 'order'})
            this.type_list = _.cloneDeep(this.meta_list)

            this.local_note_set_type()
            this.local_note_set_content()

            this.edit_mode = false
            this.local_loading = false
        },

        async localLoadNoteAttachments () {
            this.mixinSetLoading('attachment')
            this.attachment_clear_response()
            this.attachment_clear_upload_list()
            this.localSetAttachmentFilters()
            await this.attachment_index({ params: this.localAttachmentFilters })
            this.mixinResetLoading('attachment')
            this.mixinUpdateLocalAttachmentList({ list: this.attachment_list })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localSetAttachmentFilters () {
            this.localAttachmentFilters[this.FILTER.parent_id] = this.$route.params.id
            this.localAttachmentFilters[this.FILTER.parent_type] = 'Customer'
            this.localAttachmentFilters[this.FILTER.source_id] = this.$route.params.note_id
            this.localAttachmentFilters[this.FILTER.source_type] = 'Note'
            this.localAttachmentFilters[this.FILTER.fields] = this.attachment_fields
            this.localAttachmentFilters.count = 5
        },

        local_note_set_content() {
            if (this.editor_content != null) this.editor_content.destroy()

            const content = this.note_item.content ? this.note_item.content : ' '
            this.editor_content = new Editor({
                extensions: [
                    new Bold(),
                    new Italic(),
                    new Strike(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new History(),
                ],
                content: marked(content, { sanitize: true }),
                onUpdate: this.local_content_update_debounced,
                editable: this.$can('notes.update'),
            })
        },

        async local_update() {
            this.mixinSetLoading('notes-update')
            if (this.local_visibility_note_item.id) await this.note_visibility(this.local_visibility_note_item)
            await this.localUpdateNoteAttachment()
            await this.note_update(this.note_item)
            this.mixinResetLoading('notes-update')

            if (this.note_response.status !== 'success') return

            this.snackbar_text = 'Notes updated successfully!'
            this.snackbar_status = true
            this.local_note_show()
            this.local_update_user_updated_time()
            this.edit_mode = false
            this.salesforce_sync_status = true
            this.local_note_title = this.note_item.title
            this.local_note_content = this.note_item.content
            this.notes_modified = false
        },

        local_page_height () {
            if (document.body.scrollHeight > 945) return true
            else false
        },

        async local_note_show() {
            await this.note_show({id: this.$route.params.note_id, params: this.local_customer_note_fields })
            this.stored_note = _.cloneDeep(this.note_item)
        },

        async local_type_update(id) {
            this.note_item.type_id = id
            this.local_check_notes_modified()
            this.local_note_set_type()
        },

        async local_note_clear() {
            await this.note_show({id: this.$route.params.note_id})
            if (this.note_response.status === 'success') {
                this.edit_mode = false
                this.local_note_show()
            }
        },

        async local_content_update_debounced(state) {
            this.note_item.content = turndownService.turndown(state.getHTML())
            this.local_check_notes_modified()
        },
        async local_clear_debounce_timer(saved = false) {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                if (saved) {
                    this.update_autosaved_at = moment().format('YYYY-MM-DD HH:mm:ss')
                }
            }
        },

        local_check_notes_modified () {
            this.notes_modified = !_.isEqual(this.stored_note, this.note_item) || this.localCheckAttachmentUpdate
        },

        local_handler_close (e) {
            if (this.notes_modified) {
                e.returnValue = 'Are you sure to close the tab?';
            }
        },

        async local_cancel_changes () {
            await this.note_clear_item()
            await this.meta_clear()
            this.localClearAttachmentUpdate()
            this.note_item.visibility = this.stored_note.visibility
            await this.local_note_show()
            this.local_note_set_content()
            this.local_note_content = this.note_item.content
            this.notes_modified = false
            this.dialog_cancel_changes = false
        },

        local_leave_page () {
            this.notes_modified = false
            this.to_page_route()
        },

        async local_destroy(id) {
            this.localDeleteLoading = true
            this.notes_modified = false
            await this.note_destroy({id: id})
            if (this.note_response.status == 'success') {
                this.localDeleteLoading = false
                this.$router.push({name: 'customers-notes'})
            }
        },

        async local_visibility_toggle(item) {
            if (!this.$can('notes.update')) return
            this.local_visibility_note_item.id = item.id
            this.local_visibility_note_item.state = item.visibility
            this.note_item.visibility = this.local_visibility_note_item.state
            this.local_check_notes_modified()
        },

        local_note_set_type() {
            if (this.note_item && this.note_item.type_id) {
                this.note_item.type = this.type_list.find(item => item.id === this.note_item.type_id)
            } else this.note_item.type = null
        },

        async local_salesforce_add() {
            this.dialog_salesforce_source_form = true
        },

        async local_salesforce_push(id, salesforce_target_resource) {
            await this.note_salesforce_push({id: id, salesforce_target_resource: salesforce_target_resource});
            if (this.note_response.status == 'success') {
                this.salesforce_sync_status = false
                this.dialog_salesforce_source_form = false
                this.snackbar_status = true
                this.snackbar_text = 'Note synced with Salesforce.'
            }
        },

        async local_salesforce_pull(id) {
            await this.note_salesforce_pull({id: id});
            if (this.note_item.salesforce_id == null) {
                this.salesforce_sync_status = true
            }
        },

        local_bridge_value(note, service, key) {
            if (note.bridges) {
                const result = note.bridges.find(item => item.service === service && item.key === key)
                return (result && result.value) ?? ''
            }
        },

        local_check_bridge(note, service, key) {
            return note.bridges && note.bridges.find(item => {
                return item.service === service && item.key === key
            })
        },

        local_update_user_updated_time() {
            this.note_item.updated_at = new Date()
            this.note_item.updated_by.name = this.user_self.name
        },

        local_get_initials(name) {
            let initials_array = _.split(name, ' ', 2)
            let initial_first = _.truncate(initials_array[0], {length: 1, omission: ''})
            let initial_second = _.truncate(initials_array[1], {length: 1, omission: ''})
            return initial_first + initial_second
        },

        // Attachment CRUD

        localAddListeners () {
            window.addEventListener('beforeunload', this.local_handler_close)
            this.mixinAttachmentListeners('add')
        },

        localRemoveListeners () {
            window.removeEventListener('beforeunload', this.local_handler_close)
            this.mixinAttachmentListeners('remove')
        },

        localHandleDragEnter (e) {
            this.filesDraggedOver = true
        },

        localHandleLeave (e) {
            if (this.hoveredOnInfo) return
            this.filesDraggedOver = false
        },

        localHandleDrop (e) {
            if (this.localAttachmentsUpdating || this.pageLoading) return
            const droppedFiles = Array.from(e.dataTransfer.files)
            if (_.size(droppedFiles) > 5) return this.localValidateAttachmentCount()
            this.localDroppedFilesList = _.cloneDeep(droppedFiles)
            this.filesDraggedOver = false
        },

        localValidateAttachmentCount () {
            this.$notify('warning', 'Sorry, you can only upload up to 5 files at a time')
            this.filesDraggedOver = false
        },

        localRemoveAttachmentItem (id) {
            this.mixinRemoveAttachmentItem(id)
            this.local_check_notes_modified()
        },

        localDeleteAttachmentItem (id) {
            this.mixinDeleteAttachmentItem(id)
            this.local_check_notes_modified()
        },

        async localUpdateNoteAttachment () {
            this.mixinSetLoading('attachment-update')
            this.attachment_clear_response()
            this.mixinRemoveInvalidFiles()
            await this.localBulkDeleteAttachment()
            await this.localUploadAttachment()
            this.mixinResetLoading('attachment-update')
        },

        async localUploadAttachment () {
            if (!_.size(this.attachment_upload_list)) return
            const formData = new FormData();
            formData.append('parent_id', this.$route.params.id)
            formData.append('parent_type', 'Customer')
            formData.append('source_id', this.$route.params.note_id)
            formData.append('source_type', 'Note')
            formData.append('order', -1)
            this.attachment_upload_list.forEach(file => {
                formData.append('files[]', file)
                formData.append('ids[]', file.id)
            })
            await this.attachment_upload(formData)

            if (!this.$status(this.attachment_response)) return this.mixinHandleAttachmentErrors()
            this.localShowNoteAttachments()
            this.attachment_clear_upload_list()
        },

        async localBulkDeleteAttachment () {
            if (!_.size(this.attachment_delete_list)) return
            this.mixinSetLoading('attachment-delete')
            await this.attachment_bulk_destroy({ids: this.attachment_delete_list})
            if (this.$status(this.attachment_delete_response)) {
                if (!_.size(this.attachment_upload_list)) this.localShowNoteAttachments()
                if (!_.size(this.attachment_local_list) && !_.size(this.attachment_upload_list)) this.localLoadNoteAttachments()
                this.attachment_clear_delete_list()
            }
            this.mixinResetLoading('attachment-delete')
        },

        async localShowNoteAttachments () {
            this.localAttachmentFilters.count = _.size(this.attachment_local_list)
            this.localAttachmentFilters.page = 1
            await this.attachment_index({ mode: 'show', params: this.localAttachmentFilters})
            this.mixinUpdateLocalAttachmentList({ list: this.attachment_list})
            this.backupAttachmentList = _.cloneDeep(this.attachment_list)
        },

        localUpdateAttachmentTransfer (params) {
            this.attachment_clear_response()
            const { list, transferFiles } = params || {}
            const totalUploadList = !!_.size(this.attachment_upload_list) ? [...transferFiles, ...this.attachment_upload_list] : [...transferFiles]
            this.mixinPreserveListChanges({ list: list, type: 'drop_transfer' })
            this.attachment_update_upload_list(totalUploadList)
            this.local_check_notes_modified()
        },

        async localLoadMoreAttachments () {
            if (this.attachment_meta.total === _.size(this.attachment_list)) return

            this.mixinSetLoading('attachment')
            const defaultCount = 5
            const isListShortOfItem = _.size(this.attachment_list)%5 === 0
            const loadType = isListShortOfItem ? 'load_more' : 'index'
            this.localAttachmentFilters.count = isListShortOfItem ? defaultCount : this.attachment_meta.current_page*defaultCount
            this.localAttachmentFilters[this.FILTER.page] = isListShortOfItem ? this.attachment_meta.current_page+1 : 1
            await this.attachment_index({ mode: loadType, params: this.localAttachmentFilters })

            this.mixinResetLoading('attachment')
            this.mixinPreserveListChanges({ list: this.attachment_list, type: 'load_more' })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localClearAttachmentUpdate () {
            this.mixinUpdateLocalAttachmentList({ list: this.backupAttachmentList })
            this.mixinClearAttachmentUpdate()
        },

        ...mapActions('Note', {
            note_show: 'show',
            note_update: 'update',
            note_salesforce_push: 'salesforce_push',
            note_salesforce_pull: 'salesforce_pull',
            note_visibility: 'visibility',
            note_destroy: 'destroy',
            note_select: 'select',
            note_clear_item: 'clear_item',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),

        ...mapActions('Attachment', {
            attachment_index: 'index',
            attachment_upload: 'upload',
            attachment_update_upload_list: 'update_upload_list',
            attachment_remove_upload_item: 'remove_upload_list_item',
            attachment_bulk_destroy: 'bulk_destroy',
            attachment_clear_upload_list: 'clear_upload_list',
            attachment_clear_delete_list: 'clear_delete_list',
            attachment_clear_response: 'clear_response',
        }),
    },
}
</script>

<style scoped>
    .ProseMirror {
        outline: none;
    }

    .menububble {
        transition: all 0.3s;
        position: absolute;
        z-index: 99999;
        background-color: #222;
        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-radius: 0.25rem;
        visibility: hidden;
        opacity: 0;
    }

    .menububble.is-active {
        visibility: visible;
        opacity: 1;
    }

    .c-save-btn.v-btn.v-btn--disabled {
        opacity: 0.5;
        background-color: #3949AB !important;
        color: #fff !important;
    }

    .c-update-container {
        position: sticky !important;
        bottom: 0px;
        border: 1px solid #EEE;
        background: #FFF;
        width: 100%;
    }
</style>

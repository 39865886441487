import Vue from 'vue'
import { pascalToKebab } from '@/helpers/helper-case-convertor'

Vue.directive('testInput', {
    inserted: function (el, binding, vnode) {
        const componentName = pascalToKebab(vnode)
        const { arg, value } = binding
        const type = 'input'
        const fieldName = Object.keys(binding.modifiers)[0]

        el.dataset['testElement'] = type + (componentName ? '-' + componentName : '') + (arg ? '-' + arg : '') + (fieldName ? '-' + fieldName : '') + (value ? '-' + value : '') // input-competitors-name
    }
})

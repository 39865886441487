<template>
    <div :class="[avatarClass, rightSpace ? 'mr-3' : '']">
        <a-avatar :class="[{ 'u-rounded-corners': tile }, dataGetShadow]" :size="size" :color="dataBorderColor" left v-if="item && ((item.avatar) || (item.user && item.user.avatar))">
            <a-avatar :class="[{ 'u-rounded-corners': tile }, 'white']" :size="dataGetProfileSize">
                <a-avatar :class="{ 'u-rounded-corners': tile }" :size="dataExternalProfileSize">
                    <a-img :src="item.avatar || item.user.avatar" :contain="contain">
                        <a-icon slot="placeholder" color="grey darken-1" size="14">person</a-icon>
                    </a-img>
                </a-avatar>
            </a-avatar>
        </a-avatar>
        <template v-else>
            <a-avatar :size="size" :class="[dataGetShadow, { 'u-rounded-corners': tile }]" :color="dataBorderColor" v-if="item && item.user">
                <a-avatar :class="[{ 'u-rounded-corners': tile }, 'white']" :size="dataGetProfileSize">
                    <a-avatar :class="{ 'u-rounded-corners': tile }" :size="dataExternalProfileSize" :color="$color(item.user.color, 'color_bg')">
                        <span class="font-weight-bold md-caption d-block text-center" v-if="item && item.user" :style="{fontSize: fontSize + 'px', color: $color(item.user.color, 'color_text')}">{{ item.user.initial }}</span>
                    </a-avatar>
                </a-avatar>
            </a-avatar>
            <a-avatar :size="size" :class="[dataGetShadow, { 'u-rounded-corners': tile }]" :color="dataBorderColor" v-else-if="item && item.color">
                <a-avatar :class="[{ 'u-rounded-corners': tile }, 'white']" :size="dataGetProfileSize">
                    <a-avatar :class="{ 'u-rounded-corners': tile }" :size="dataExternalProfileSize" :color="$color(item.color, 'color_bg')">
                        <span class="font-weight-bold md-caption d-block text-center" v-if="item" :style="{fontSize: fontSize + 'px', color: $color(item.color, 'color_text')}">{{ item.initial }}</span>
                    </a-avatar>
                </a-avatar>
            </a-avatar>
            <a-avatar :size="size" :class="[dataGetShadow, { 'u-rounded-corners': tile }]" :color="dataBorderColor" v-else>
                <a-avatar :class="[{ 'u-rounded-corners': tile }, 'white']" :size="dataGetProfileSize">
                    <a-avatar :class="{ 'u-rounded-corners': tile }" :size="dataExternalProfileSize" :color="$color('no_color', 'color_bg')">
                        <span class="font-weight-bold md-caption" v-if="item" :style="{fontSize: fontSize + 'px', color: $color(item.color, 'color_text')}">{{ item.initial }}</span>
                    </a-avatar>
                </a-avatar>
            </a-avatar>
        </template>
        <slot></slot>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        size: {
            type: Number | String,
            default: 36
        },
        avatarClass: {
            type: String,
        },
        rightSpace: {
            type: Boolean,
            default: true
        },
        fontSize: {
            type: String,
            default: '12'
        },
        tile: {
            type: Boolean,
            default: false
        },
        noBorder: {
            type: Boolean,
            default: false
        },
        contain: {
            type: Boolean,
            default: false
        },
        darkShadow: {
            type: Boolean,
            default: false
        },
        noShadow: {
            type: Boolean,
            default: false
        },
        borderColor: {
            type: String,
            // default: '#ebebeb'
            default: '#f6f6f6'
        }
    },

    computed: {
        dataGetShadow () {
            return ''
            // if (this.noShadow) return ''
            // if (this.darkShadow) return 'c-shadow--dark'
            // return 'c-shadow--light'
        },

        dataGetProfileSize () {
            // return this.dataIsExternal && !this.dataUserIsExternal ? this.size - 4 : (this.noBorder ? this.size : (this.size - 3))
            return this.dataIsExternal && !this.dataUserIsExternal ? parseInt(this.size) - 4 : (this.noBorder ? parseInt(this.size) : (parseInt(this.size) - 4))
        },

        dataExternalProfileSize () {
            return this.dataIsExternal && !this.dataUserIsExternal ? this.dataGetProfileSize - 3 : this.dataGetProfileSize
        },

        dataIsExternal () {
            return this.item && this.item.scope === 'external'
        },

        dataUserIsExternal () {
            return this.user_self && this.user_self.scope === 'external'
        },

        dataBorderColor () {
            return this.dataIsExternal && !this.dataUserIsExternal ? 'orange darken-2' : this.borderColor
        },

        ...mapState('User', {
            user_self: 'self'
        })
    }
}
</script>

<template>
    <a-sheet class="u-flex align-stretch u-overflow-hidden">
        <a-sheet :min-height="boxHeight" :width="local_menu_width" class="pb-6" style="border-right: 1px solid #eee !important">
            <!-- Menu Header Section -->
            <slot name="menu-header-section">
                <h2 class="md-heading-5 body--text text--darken-3 pa-6 pb-4">
                    <!-- Menu Header -->
                    <slot name="menu-header"></slot>
                </h2>
            </slot>
            <!-- Menu Section -->
            <slot name="menu-section" :list="menus">
                <a-list :class="['py-0 u-list-condensed', { 'px-3': dense }]">
                    <template v-for="(item, index) in menus">
                        <!-- Menu Item -->
                        <slot name="menu-item" :item="{ item, index }">
                            <a-list-item :key="item.id" :class="[{ 'indigo lighten-5': selected_tile && selected_tile.id === item.id }, dense ? 'py-2 px-3 u-rounded-corners' : 'py-3 px-6 align-start', 'u-cursor-pointer']" @click="local_select_tile(item)">
                                <!-- Menu Icon Section -->
                                <slot name="menu-icon-section" :item="{ item, index }">
                                    <a-list-item-icon class="pa-0 mb-0 mx-0">
                                        <a-icon :color="selected_tile && selected_tile.id === item.id ? 'indigo darken-1' : 'body darken-1'" class="mr-3" :style="[{ marginTop: dense ? '0px' : '2px' }]" :outlined="item.icon_outlined">
                                            <!-- Menu Icon Name -->
                                            <slot name="menu-icon-name" :item="{ item, index }">
                                                {{ item.custom_icon ? '$' + item.icon : item.icon }}
                                            </slot>
                                        </a-icon>
                                    </a-list-item-icon>
                                </slot>
                                <v-list-item-content style="padding: 0px !important;">
                                    <div class="u-flex-center-y">
                                        <div>
                                            <!-- Menu title Section -->
                                            <slot name="menu-title-section" :item="{ item, index }">
                                                <a-list-item-title class="u-flex-center-y">
                                                    <!-- Menu Title -->
                                                    <slot name="menu-title" :item="{ item, index }">
                                                        <span :class="['md-subtitle-1 c-font-transition', selected_tile && selected_tile.id === item.id ? 'indigo--text text--darken-1 font-weight-medium' : 'body--text text--darken-1']">{{ item.title }}</span>
                                                        <!-- <template v-if="item && item.size">
                                                            <a-spacer></a-spacer>
                                                            <span :class="['md-caption', selected_tile && selected_tile.id === item.id ? 'indigo--text text--darken-1' : 'body--text text--darken-1']" style="letter-spacing: 1px;">{{ item.size }}</span>
                                                        </template> -->
                                                    </slot>
                                                </a-list-item-title>
                                            </slot>
                                            <!-- Menu sub-title Section -->
                                            <slot name="menu-subtitle-section" v-if="item && item.chart_type" :item="{ item, index }">
                                                <a-list-item-subtitle sub-class="u-flex-center-y">
                                                    <slot name="menu-title" :item="{ item, index }">
                                                        <span :class="['text-body-2 mt-1 d-inline-block c-font-transition', selected_tile && selected_tile.id === item.id ? 'indigo--text text--darken-1 font-weight-medium' : 'body--text text--lighten-2']">{{ item.chart_type }} Chart</span>
                                                    </slot>
                                                </a-list-item-subtitle>
                                            </slot>
                                        </div>
                                        <template v-if="item && item.size">
                                            <a-spacer></a-spacer>
                                            <span :class="['md-caption d-inline-block', selected_tile && selected_tile.id === item.id ? 'indigo--text text--darken-1' : 'body--text text--darken-1']" style="letter-spacing: 1px;">{{ item.size }}</span>
                                        </template>
                                    </div>
                                </v-list-item-content>
                            </a-list-item>
                        </slot>
                    </template>
                </a-list>
            </slot>
        </a-sheet>
        <a-sheet :min-height="boxHeight" :max-height="boxHeight" :width="local_main_width" color="#fafafa" class="u-relative">
            <slot name="close-icon">
                <a-icon size="16" color="" class="u-absolute" style="top: 24px; right: 24px;" @click="$emit('close', true)">clear</a-icon>
            </slot>
            <a-sheet class="u-overflow-y transparent scroll-transparent c-tiny-scroll" :min-height="`calc(${boxHeight + 'px'} - 84px)`" :max-height="`calc(${boxHeight + 'px'} - 84px)`">
                <!-- Main Section -->
                <slot name="main-section" :item="selected_tile" :slug="selected_tile && selected_tile.id"></slot>
            </a-sheet>
            <a-sheet class="u-sticky u-wfull grey lighten-5" style="bottom: 0px; left: 0px;">
                <a-divider class="grey lighten-3"></a-divider>
                <div class="pa-6 text-right">
                    <a-btn depressed class="indigo darken-2 white--text px-3" @click="$emit('done', { selected_tile })">
                        <span class="md-body-2 text-uppercase font-weight-bold" style="letter-spacing: 0px;">
                            <slot name="save-btn-text">Add & Configure Tile</slot>
                        </span>
                    </a-btn>
                </div>
            </a-sheet>
        </a-sheet>
    </a-sheet>
</template>

<script>
export default {
    props: {
        menus: {
            type: Array,
            default: [
                { title: 'Project: Numbers', slug: 'project_numbers', size: '1x1', icon: 'article', icon_outlined: true },
                { title: 'Project: Group', slug: 'project_group_1', size: '1x1', icon: 'pie_chart', icon_outlined: true },
                { title: 'Project: Group', slug: 'project_group_2', size: '1x1', icon: 'donut_large', icon_outlined: false },
                { title: 'Project: Timeline', slug: 'project_timeline_1', size: '2x1', icon: 'poll', icon_outlined: true },
                { title: 'Project: Timeline', slug: 'project_timeline_2', size: '2x1', icon: 'show_chart', icon_outlined: true },
                { title: 'Project: Timeline', slug: 'project_timeline_3', size: '2x1', icon: 'analytics', icon_outlined: true },
            ]
        },
        boxHeight: {
            type: Number | String,
            default: 648
        },
        menuWidth: {
            type: Number | String,
            default: 320
        },
        mainWidth: {
            type: Number | String,
            default: 640
        },
        modelOpen: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
    },

    data () {
        return {
            selected_tile: null
        }
    },

    watch: {
        modelOpen (val) {
            if (val) this.local_index()
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        local_menu_width () {
            return this.dense ? this.menuWidth - 64 : this.menuWidth
        },

        local_main_width () {
            return this.dense ? this.mainWidth + 64 : this.mainWidth
        },
    },

    methods: {
        local_index () {
            this.selected_tile = this.menus[0]
        },

        local_select_tile (tile) {
            this.selected_tile = tile
        },
    }
}
</script>

<style lang="scss" scoped>
.c-font-transition {
    transition: font-weight 0.1s all;
}
</style>

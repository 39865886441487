<template>
    <v-list-item-content v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </v-list-item-content>
</template>

<script>
import { VListItemContent } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VListItemContent }
}
</script>

const api_endpoint =  '/confidence-score-values'

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        form_mode: 'form_update',
        unsaved_changes: false,
        response: [],
        defaults: {
            item: {},
            form_mode: 'form_update'
        },
    },

    mutations: {
        UPDATE_LIST (state, payload) {
            state.list = _.cloneDeep(payload)
        },

        UPDATE_FORM_MODE (state, payload) {
            state.form_mode = payload
        },

        UPDATE_EDIT_ITEM (state, payload) {
            state.item = _.cloneDeep(payload)
        },

        UPDATE_UNSAVED_STATUS (state, payload) {
            state.unsaved_changes = payload
        },

        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },

    actions: {
        async store ({commit}, payload) {
            try {
                const response = await axios.post(api_endpoint, payload)
                commit('UPDATE_LIST', response.data);
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            }
            catch (error) {
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },

        update_form_mode ({commit}, payload) {
            commit('UPDATE_FORM_MODE', payload)
        },

        update_edit_item ({commit}, payload) {
            commit('UPDATE_EDIT_ITEM', payload)
        },

        update_unsaved_status ({commit}, payload) {
            commit('UPDATE_UNSAVED_STATUS', payload)
        },

        clear_item (context, payload) {
            context.commit('UPDATE_EDIT_ITEM', context.state.defaults.item)
            context.commit('UPDATE_FORM_MODE', context.state.defaults.form_mode)
        }
    }
}

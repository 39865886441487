import Vue from 'vue'
import Vuex from 'vuex'
import { v4 as uuidv4 } from 'uuid'

import SystemModules from './ModelsSystem'
import InternalModules from './ModelsInternal'
import ExternalModules from './ModelsExternal'

Vue.use(Vuex)

const store = new Vuex.Store({
    strict: false,
    modules: {
        ...SystemModules,
        ...InternalModules,
        ...ExternalModules
    }
})

store.subscribeAction({
    before: (action, _) => {
        const methodName = action.type.split('/')[1]
        if (methodName === 'store' || methodName.includes('Store') || methodName.includes('store')) {
            if (!action.payload.hasOwnProperty('id')) Object.assign(action.payload, { id: uuidv4() })
        }
    }
})

export default store

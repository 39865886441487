<template>
    <div v-bind="$attrs" v-on="$listeners">
        <div class="mb-2">
            <span class="md-md-body-2 grey--text text--darken-2 d-inline-block mb-3">Project Result</span>
            <a-autocomplete
                v-model="selectedResult"
                :items="meta_list"
                item-text="value"
                item-value="id"
                placeholder="Select Result"
                background-color="grey lighten-5"
                class="u-border u-rounded-corners-lg"
                :disabled="loading"
                :menu-props="{ rounded: 'lg', nudgeBottom: '4px' }"
                @input="localGetResultReason(selectedResult)"
                solo flat hide-details return-object
            ></a-autocomplete>
            <SharedValidationFormError
                identifier="result_id"
            />
        </div>

        <template v-if="selectedResult && selectedResult.id">
            <div class="mt-5">
                <div class="u-flex-center-y mb-3">
                    <span class="md-md-body-2 grey--text text--darken-2">Result Reason</span>
                    <a-spacer></a-spacer>
                    <span v-if="$can('metas.update')" class="md-md-body-2 u-flex-center-y u-cursor-pointer" @click="$router.push({ name: 'settings-metas-result-reasons', params: { id: selectedResult.id } })">
                        <a-icon size="20" color="blue darken-2">add</a-icon>
                        <span class="blue--text text--darken-2 ml-1">Create</span>
                    </span>
                </div>

                <!-- Reasons Comment -->
                <v-slide-y-transition leave-absolute hide-on-leave>
                    <div v-if="reasonLoading" class="u-border u-rounded-corners-lg u-flex-center-y" style="min-height: 78px">
                        <div class="grey lighten-5 u-border pa-4">
                            <div v-for="(count, index) in 2" class="u-flex-center-y" :class="{ 'mt-2': index !== 0 }" :key="count">
                                <loader-template :width="Math.floor(Math.random() * 300) + 200" height="12" class="u-rounded-corners-full"></loader-template>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <v-slide-y-transition leave-absolute hide-on-leave>
                            <!-- No Reasons Text -->
                            <div v-if="!localReasonseLength">
                                <div class="u-border u-rounded-corners-lg" >
                                    <a-sheet color="grey lighten-5" min-height="76" class="u-rounded-corners-lg u-flex-center px-3 py-2">
                                        <a-icon size="20" color="grey darken-4">info</a-icon>
                                        <p class="mb-0 md-body-2 grey--text text--darken-2 ml-2">There are no reasons available for <strong>{{ selectedResult.value }}.</strong></p>
                                    </a-sheet>
                                </div>
                                <span 
                                    v-if="automation_validation_status && !automation_validation_status.result_reason_status" 
                                    class="md-caption grey--text u-absolute" style="right: 0px; bottom: -24px;"
                                >
                                    (Optional)
                                </span>
                                <SharedValidationFormError
                                    identifier="result_reason"
                                />
                            </div>

                            <!-- List -->
                            <div v-else class="u-relative">
                                <a-sheet class="u-rounded-corners-lg u-border u-overflow-y c-tiny-scroll" max-height="300">
                                    <div class="grey lighten-5" v-for="(reason, index) in meta_result_reason_list" :key="reason.id">
                                        <a-divider v-if="index !== 0" :key="reason.id + '-divider'"></a-divider>
                                        <div class="u-flex align-start pa-4 py-3 u-cursor-pointer" @click="localToggleReason(reason.id)" :key="reason.id">
                                            <a-checkbox color="blue darken-2" :value="localIsSelected(reason.id)" @click.stop="localToggleReason(reason.id)" class="ma-0 pa-0" hide-details dense></a-checkbox>
                                            <p class="mb-0 md-body-2 grey--text text--darken-2 ml-2">{{ reason.title }}</p>
                                        </div>
                                    </div>
                                </a-sheet>
                                <span 
                                    v-if="automation_validation_status && !automation_validation_status.result_comment_status" 
                                    class="md-caption grey--text u-absolute" 
                                    style="right: 0px; bottom: -24px;"
                                >
                                    (Optional)
                                </span>
                                <SharedValidationFormError
                                    identifier="result_reason"
                                />
                            </div>
                        </v-slide-y-transition>
                    </div>
                </v-slide-y-transition>
            </div>

            <div class="mt-5 u-relative">
                <span class="md-md-body-2 grey--text text--darken-2 d-block mb-3">Comment</span>
                <a-sheet :class="[!commentError ? 'u-border' : 'c-wiggle-short u-border-error']"  class="u-rounded-corners-lg" height="104">
                    <a-tooltip bottom :disabled="!commentError">
                        <template v-slot:activator="{ on }">
                            <textarea
                                v-on="on"
                                v-model="comment"
                                @input="localAddComment"
                                placeholder="Post your comment"
                                style="resize: none; outline: none"
                                class="grey lighten-5 u-wfull u-hfull md-body-2 px-4 py-2 u-rounded-corners-lg c-tiny-scroll"
                            ></textarea>
                        </template>
                        <span>{{ commentError }}</span>
                    </a-tooltip>
                </a-sheet>
                <span 
                    v-if="automation_validation_status && !automation_validation_status.result_comment_status" 
                    class="md-caption grey--text u-absolute" 
                    style="right: 0px; bottom: -24px;"
                >
                    (Optional)
                </span>
                <SharedValidationFormError
                    identifier="comment"
                />
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { SReasonComment } from '@/config/config-shared-components'
import mixinAutomationHook from '@/mixins/mixin-automation-hook'
import SharedValidationFormError from '../../../components/Shared/SharedValidationFormError.vue'
import { Validate } from '@/helpers/helper-validations'

export default {
    mixins: [
        mixinAutomationHook,
    ],
    props: {
        isOpen: {
            type: Boolean
        },
        stage: {
            type: Object
        },
        loading: {
            type: Boolean
        },
        currentResult: {
            type: Object
        },
    },

    components: {
        SReasonComment,
        SharedValidationFormError,
    },

    data () {
        return {
            comment: '',
            commentError: '',
            selectedResult: {},
            selectedReasons: [],
            projectReasons: [],
            removedReasons: [],
            finalSelectedReasons: [],
            finalRemovedReasons: [],
            reasonLoading: false,
        }
    },

    watch: {
        isOpen: {
            handler (val) {
                if (val) this.localIndex()
            },
            immediate: true
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localReasonsList () {
            return (this.project_item && this.project_item.result_reasons && this.project_item.result_reasons.length) ? this.project_item.result_reasons : this.meta_result_reason_list
        },

        localReasonseLength () {
            return this.meta_result_reason_list && this.meta_result_reason_list.length
        },

        localProjIsArchived () {
            return this.project_item && this.project_item.status === 'archived'
        },

        ...mapState('AutomationValidation', {
            automation_validation_status: 'status',
        }),

        ...mapState('Meta', {
            meta_list: 'list'
        }),

        ...mapState('ProjectView', {
            project_item: 'item'
        }),

        ...mapState('MetaResultReason', {
            meta_result_reason_list: 'list',
        }),

        ...mapState('AutomationWorkflow', {
            automation_workflow_hook_response: 'hook_response',
        }),
    },

    methods: {
        async localIndex () {
            this.removedReasons = []
            this.finalSelectedReasons = []
            this.finalRemovedReasons = []
            this.commentError = ''
            this.projectReasons = this.project_item.result_reasons
            if (this.currentResult && this.currentResult.id && !this.localProjIsArchived) {
                this.selectedResult = _.cloneDeep(this.currentResult)
                await this.localGetResultReason(this.currentResult)
                if (this.meta_result_reason_list && this.meta_result_reason_list.length) {
                    this.selectedReasons = this.projectReasons.map(({ id }) => id)
                }

                this.comment = this.project_item.comment
                return true
            }
            this.selectedResult = {}
            this.selectedReasons = []
            this.comment = ''
        },

        localAddComment () {
            const { message } = new Validate(this.comment, { silent: true }).length(0, 50000).run()
            if (message) this.commentError = message
            else this.commentError = ''

            this.localUpdate()
        },

        localUpdate () {
            this.$emit('update', {
                reasons: { 
                    selectedReasons: this.finalSelectedReasons, 
                    removedReasons: this.finalRemovedReasons,
                    updateReasons : this.selectedReasons
                },
                result: this.selectedResult,
                comment: this.comment,
                commentError: this.commentError
            })
        },

        async localGetResultReason (result) {
            if (!result || (result && !result.id)) return this.localUpdate()
            this.reasonLoading = true
            await this.automation_workflow_hook_clear()
            await this.meta_result_reason_clear()
            await this.meta_result_reason_index({
                'filter[result_id]': result.id,
                'fields[project_result_reasons]': 'id,title',
                'sort': 'order'
            })
            if (this.currentResult && this.currentResult.id === result.id && !this.localProjIsArchived) {
                this.removedReasons = []
                this.finalSelectedReasons = []
                this.finalRemovedReasons = []
                this.selectedReasons = this.projectReasons.map(({ id }) => id)
                this.localFilterSelectedReasons()
                this.localFilterRemovedReasons()
            } else {
                this.removedReasons = []
                this.finalSelectedReasons = []
                this.finalRemovedReasons = []
                this.selectedReasons = []
            }
            this.localUpdate()
            this.reasonLoading = false
        },

        /* Reasons - Start */
        localToggleReason (id) {
            const index = this.selectedReasons.indexOf(id)
            if (index !== -1) {
                this.selectedReasons.splice(index, 1)
                this.localAddToRemovedReasons(id)
                return this.localUpdate()
            }
            this.selectedReasons.push(id)
            this.localRemoveReason(id)
            this.localUpdate()
        },

        localFilterSelectedReasons () {
            this.finalSelectedReasons = this.selectedReasons.filter(item => {
                const isPreSelectedIndex = this.projectReasons ? this.projectReasons.findIndex(reason => reason.id === item) : -1
                if (isPreSelectedIndex !== -1) return null
                return item
            })
        },

        localFilterRemovedReasons () {
            this.finalRemovedReasons = this.removedReasons.filter(item => {
                const isPreSelectedIndex = this.projectReasons ? this.projectReasons.findIndex(reason => reason.id === item) : -1
                if (isPreSelectedIndex === -1) return null
                return item
            })
        },

        localRemoveReason (id) {
            const index = this.removedReasons.indexOf(id)
            if (index !== -1) this.removedReasons.splice(index, 1)
            this.localFilterSelectedReasons()
            this.localFilterRemovedReasons()
        },

        localAddToRemovedReasons (id) {
            const isOldIndex = this.projectReasons ? this.projectReasons.findIndex(item => item.id === id) : -1
            if (isOldIndex === -1) return this.localRemoveReason(id)

            const index = this.removedReasons.indexOf(id)
            if (index !== -1) return this.localRemoveReason(id)

            this.removedReasons.push(id)
            this.localFilterSelectedReasons()
            this.localFilterRemovedReasons()
        },

        localIsSelected (id) {
            return this.selectedReasons.includes(id)
        },

        localGetValidateResultError (name) {
            if (!_.size(_.get(this.automation_workflow_hook_response, 'current_logs'))) return null;

            const logItem = this.automation_workflow_hook_response.current_logs.find(item => item.field_id === name && item.is_valid === false)
            return _.get(logItem, 'message') || null            
        },

        /* Reasons - End */

        ...mapActions('MetaResultReason', {
            meta_result_reason_index: 'index',
            meta_result_reason_clear: 'clear',
        }),
    }
}
</script>

<style lang="scss" scoped></style>

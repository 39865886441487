<template>
    <v-pagination v-model="showModel" v-on="$listeners" v-bind="$attrs"></v-pagination>
</template>

<script>
import { VPagination } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VPagination },
    props: ['value'],

    data () {
        return {
            ref: null
        }
    },

    mounted () {
        this.ref = this.$el
    },

    computed: {
        showModel: {
            get () {
                return this.value
            },
            set (val) {
                this.$emit('update:value', val)
            }
        }
    }
}
</script>

<template>
    <a-main>
        <PAutomationWorkflowBreadcrumb
            :page="'settings-automations'"
            :name="'Automation'"
        />
        <a-container grid-list-xl container--fluid pa-0 pb-16>
            <PAutomationWorkflowViewHeader
                :item="automation_workflow_item"
                :isLoading="localPageloading"
                :isChanges="localHasWorkflowUpdateChanges"
                :canUpdate="localAutomationWorkflowPermission('update')"
                :isWorkflowUpdate="mixinIsLoading('update-workflow-changes')"
                :actionCount="localActionCount"
                @cancel-changes="localWorkflowHasChanges"
                @update-workflow="localUpdateWorkflowChanges"
                @toggle-state="localAutomationWorkflowToggleState"
            />
            <PAutomationWorkflowStatusBar
                :item="automation_workflow_item"
                :pageLoading="localPageloading"
                :canUpdate="localAutomationWorkflowPermission('update')"
                :isLoading="mixinIsLoading('workflow-actions')"
                :list="localActionList"
                @update-config="localActionConfig"
            />
            <div class="mt-8 u-flex">
                <PAutomationMenu
                    :isLoading="localPageloading"
                    :page="localPage"
                    :activeMenu="localActiveMenu"
                    @action="localSelectMenu"
                    @page="localPageUpdate"
                />
                <div v-if="localPage === 'configurations'" class="mx-auto c-configuration-card">
                    <div class="white u-wfull" v-if="localActiveMenu === 'workflow-summary'">
                        <PAutomationSummary
                            :canUpdate="localAutomationWorkflowPermission('update')"
                            type="automation"
                            :item="localCloneAutomationWorkflowItem"
                            :titleError="localAutomationTitleError"
                            :response="automation_workflow_response"
                            :isLoading="localPageloading"
                            @change-value="localGetFieldUpdate"
                        />
                    </div>
                    <div v-if="localCheckSelectMenu()">
                        <div class="workflow white u-wfull u-relative" id="workflow-workspace" ref="workflow-workspace">
                            <PAutomationWorkspace
                                :item="localCloneAutomationWorkflowItem"
                                :isLoading="localPageloading"
                                :workspaceList="workspace_board_list"
                                :canUpdate="localAutomationWorkflowPermission('update')"
                                :workspaceUpdate="localSelectWorkspace"
                                :isHiddenWorkspace="localCheckHiddenWorkspace"
                                :isDefaultWorkspace="localCheckDefaultWorkspace"
                            />
                        </div>
                        <div style="height: 80px;">
                            <div class="c-ellipse-bottom">
                                <div class="c-ellipse-parent"></div>
                            </div>
                        </div>
                        <div class="c-ellipse-top">
                            <div class="c-ellipse-parent"></div>
                        </div>
                        <div class="workflow white u-wfull u-relative" id="workflow-trigger" ref="workflow-trigger">
                            <PAutomationTrigger
                                :triggersList="automation_trigger_list"
                                :editMode="localIsTriggerEditMode"
                                :triggerError="!localCloneAutomationWorkflowItem.automation_trigger_id && localAutomationTriggerError"
                                :selectedTiggerId="localSelectedTiggerId"
                                :item="localCloneAutomationWorkflowItem"
                                :canUpdate="localAutomationWorkflowPermission('update')"
                                :isLoading="localPageloading"
                                @select-trigger="localSelectTrigger"
                                @stage-update="localTriggerStageUpdate"
                                :triggerIcon="localGetTriggerIcon"
                                :stageFromlist="localTriggerStageFromFilterList"
                                :stageTolist="localTriggerStageToFilterList"
                                :workflowStageLoading="mixinIsLoading('workflow-stages')"
                                :isHiddenWorkspace="localCheckHiddenWorkspace"
                            />
                            <template v-if="automation_workflow_item.automation_trigger_id && localAutomationWorkflowPermission('update') && !localCheckHiddenWorkspace">
                                <template v-if="localTriggerEditMode">
                                    <a-tooltip bottom v-if="localIsTriggerEditMode">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="c-edit-btn u-cursor-pointer" @click="localTriggerCancel()">
                                                <div class="align-center justify-center u-flex" style="margin: 13px 0px;">
                                                    <a-icon color="grey" size="22">close</a-icon>
                                                </div>
                                            </div>
                                        </template>
                                        <span>Cancel</span>
                                    </a-tooltip>
                                    <a-tooltip bottom v-else>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="c-edit-btn u-cursor-pointer blue-grey lighten-4"  @click="localTriggerEditUpdate()">
                                                <div class="align-center justify-center u-flex" style="margin: 13px 0px;">
                                                    <a-icon color="blue-grey" size="22">drive_file_rename_outline</a-icon>
                                                </div>
                                            </div>
                                        </template>
                                        <span>Confirm</span>
                                    </a-tooltip>
                                </template>
                                <a-tooltip bottom v-else>
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="c-edit-btn u-cursor-pointer" @click="localTriggerEditModeUpdate()">
                                            <div class="align-center justify-center u-flex" style="margin: 13px 0px;">
                                                <a-icon color="grey" size="22">drive_file_rename_outline</a-icon>
                                            </div>
                                        </div>
                                    </template>
                                    <span>Edit trigger</span>
                                </a-tooltip>
                            </template>
                        </div>
                        <template v-if="!localPageloading">
                            <div style="height: 80px;">
                                <div class="c-ellipse-bottom">
                                    <div class="c-ellipse-parent"></div>
                                </div>
                            </div>
                            <div class="c-ellipse-top" v-if="localConditionsList && localConditionsList.length">
                                <div class="c-ellipse-parent"></div>
                            </div>
                            <div class="workflow white u-wfull u-relative" id="workflow-conditions" ref="workflow-conditions" v-if="localConditionsList && localConditionsList.length">
                                <PAutomationWorkflowConditions
                                    :conditionList="localConditionsList"
                                    :isError="!localCheckWorkflowConditionsErrors"
                                    :systemTagList="localSystemTagList"
                                    :customTagList="localCustomTagList"
                                    :isLoading="mixinIsLoading('workflow-conditions')"
                                    :canUpdate="localAutomationWorkflowPermission('update')"
                                    :conditionEntityList="automation_condition_entity_list"
                                    @update-source="localUpdateConditionSource"
                                    @remove-condition="localRemoveCondition"
                                    @add-group="localCreateConditionGroup"
                                    @change-value="localGetConditionChanges"
                                    @change-operator="localConditionChangeOperator"
                                    :hasCustomFieldTextInfoCheck="localConditionCustomFieldTextInfoCheck"
                                />
                                <a-tooltip bottom v-if="localAutomationWorkflowPermission('update')">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="c-edit-btn u-cursor-pointer" :class="[localConditionsRemoveFlag ? 'blue-grey lighten-4' : '']" @click="!localConditionsRemoveFlag ? localConditionsRemoveFlagUpdate() : localConditionsRemove()">
                                            <div class="align-center justify-center u-flex" style="margin: 13px 0px;">
                                                <a-icon :color="localConditionsRemoveFlag ? 'blue-grey' : 'grey'" size="22">close</a-icon>
                                            </div>
                                        </div>
                                    </template>
                                    <span>{{ localConditionsRemoveFlag ? 'Confirm?' : 'Remove conditions' }}</span>
                                </a-tooltip>
                            </div>
                            <div class="workflow c-add-new-conditions u-relative" id="workflow-conditions" ref="workflow-conditions" v-else>
                                <a-btn @click="localCreateConditionGroup('new')" depressed small rounded class="c-conditions-btn md-subtitle-1 pa-4 u-flex-center white blue--text text--accent-4 font-weight-bold text-uppercase"  :disabled="!localAutomationWorkflowPermission('update')" style="box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);">
                                    <a-icon size="20" color="blue accent-4" class="u-icon-nudge-xs mr-1" v-if="localAutomationWorkflowPermission('update')">add_circle</a-icon>
                                    <span>Add Condition</span>
                                </a-btn>   
                                <a-tooltip right v-if="localAutomationWorkflowPermission('update')">
                                    <template v-slot:activator="{ on }">
                                        <div class="c-add-new-conditions-info" v-on="on">
                                            <a-icon size="22" color="grey darken-1" class="u-icon-nudge-xs mr-1">help</a-icon>
                                        </div>
                                    </template>
                                    <div class="pa-2 md-body-2" style="width: 266px;">Conditions are filters that must be true to execute the action. Conditions are optional.</div>
                                </a-tooltip>
                            </div>
                            <div style="height: 80px;"  v-if="localConditionsList && localConditionsList.length">
                                <div class="c-ellipse-bottom">
                                    <div class="c-ellipse-parent"></div>
                                </div>
                            </div>
                            <div class="c-ellipse-top">
                                <div class="c-ellipse-parent"></div>
                            </div>
                            <div class="workflow white u-wfull" ref="workflow-actions" id="workflow-actions">
                                <PAutomationWorkflowAction
                                    :actionList="localActionList"
                                    :triggerList="triggerList"
                                    :isError="!localCheckWorkflowActionErrors"
                                    :systemTagList="localSystemTagList"
                                    :customTagList="localCustomTagList"
                                    :actionEntityList="automation_action_entity_list"
                                    :isLoading="mixinIsLoading('workflow-actions')"
                                    :canUpdate="localAutomationWorkflowPermission('update')"
                                    @remove-reorder="localActionReorder"
                                    @update-source="localUpdateActionSource"
                                    @update-field="localUpdateActionUpdateField"
                                    @update-is-action="localUpdateIsActionField"
                                    @update-action-type="localUpdateUpdateFieldActionType"
                                    @update-date-type="localUpdateActionDateType"
                                    @update-workspace="localIsUpdateWorkspace"
                                    @remove-action="localRemoveAction"
                                    @change-value="localCheckActionRelatedChanges"
                                    @add-action="localAddAction"
                                    @clear-date="localClearActionDate"
                                    @update-date-variable="localUpdateDateVariable"
                                />
                            </div>
                        </template>
                    </div>
                </div>
                <div v-if="localPage === 'logs'" class="mx-auto c-configuration-card">
                    <PAutomationWorkflowLogs
                        :list="automation_log_list"
                        :type="'action'"
                        :isLogLoading="mixinIsLoading('workflow-logs')"
                        :selectedStatusValues="selectedStatus"
                        :selectedTimeValue="selectedTime"
                        :filterLoadingType="filterLoading"
                        @filter-status="localAutomationLogStatusFilter"
                        @filter-time="localLogAutomationTimefilter"
                    />
                </div>
            </div>
        </a-container>
        <div style="height: 100px;"></div>
        <a-dialog max-width="420" v-model="dialogActiveWorkflow" persistent>
            <a-card>
                <a-sheet class="u-flex py-5 px-6">
                    <h2 class="body-3 font-weight-medium amber--text text--darken-3 u-font-semibold">
                        <a-icon class="mr-1" color="amber darken-3" size="28">flash_on</a-icon>
                        Activate Workflow
                    </h2>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <a-card-text class="pa-0">
                    <a-sheet class="pa-6">
                        <p class="md-subtitle-1 grey--text text--darken-2 mb-0 font-weight-medium">Workflow changes have been saved!</p>
                        <p class="md-subtitle-2 grey--text mb-0 font-weight-medium mt-2">Would you like to enable the workflow?</p>
                    </a-sheet>
                    <a-divider class="grey lighten-3"></a-divider>
                    <a-sheet class="py-5 px-6">
                        <div class="u-flex">
                            <a-btn @click="localUpdateWorkflowState(1)" :loading="mixinIsLoading('update-active')" class="indigo darken-1 white--text u-rounded-corners elevation-0">Yes Enable</a-btn>
                            <a-btn @click="localUpdateWorkflowState(0)" :disabled="mixinIsLoading('update-active')" text class="ml-4 grey lighten-3 grey--text text--darken-1 u-rounded-corners">
                                No Cancel
                            </a-btn>
                        </div>
                    </a-sheet>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog max-width="520" v-model="dialogUpdateWorkflowTrigger">
            <a-card>
                <a-sheet class="u-flex py-5 px-6">
                    <h2 class="body-3 font-weight-medium amber--text text--darken-3 u-font-semibold">
                        <a-icon class="mr-1" color="amber darken-3" size="28">flash_on</a-icon>
                        Trigger Update
                    </h2>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <a-card-text class="pa-0">
                    <a-sheet class="pa-6">
                        <p class="md-subtitle-1 grey--text text--darken-2 mb-0 font-weight-medium">Are you sure you want to update the Trigger?</p>
                        <p class="md-subtitle-2 grey--text mb-0 font-weight-medium mt-2">This will remove all the existing Actions configured for this workflow (if any) and it cannot be undone.</p>
                    </a-sheet>
                    <a-divider class="grey lighten-3"></a-divider>
                    <a-sheet class="py-5 px-6">
                        <div class="u-flex">
                            <a-btn @click="localUpdateWorkflowTrigger()" class="indigo darken-1 white--text u-rounded-corners elevation-0">Yes Update</a-btn>
                            <a-btn @click="localCloseWorkflowTrigger()" text class="ml-4 grey lighten-3 grey--text text--darken-1 u-rounded-corners">
                                No Cancel
                            </a-btn>
                        </div>
                    </a-sheet>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog max-width="520" v-model="dialogUpdateWorkflowWorkspace" persistent>
            <a-card>
                <a-sheet class="u-flex py-5 px-6">
                    <h2 class="body-3 font-weight-medium indigo--text text--darken-1 u-font-semibold">
                        <a-icon class="mr-1" color="indigo darken-1" size="28">apartment</a-icon>
                        Workspace Update
                    </h2>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <a-card-text class="pa-0">
                    <a-sheet class="pa-6">
                        <p class="md-subtitle-1 grey--text text--darken-2 mb-0 font-weight-medium">Are you sure you want to update the workspace?</p>
                        <p class="md-subtitle-2 grey--text mb-0 font-weight-medium mt-2">This will remove all the existing stages configured for this automation and it cannot be undone.</p>
                    </a-sheet>
                    <a-divider class="grey lighten-3"></a-divider>
                    <a-sheet class="py-5 px-6">
                        <div class="u-flex">
                            <a-btn class="indigo darken-1 white--text u-rounded-corners elevation-0" @click="localUpdateWorkspace()">Yes Update</a-btn>
                            <a-btn text class="ml-4 grey lighten-3 grey--text text--darken-1 u-rounded-corners"  @click="localCancelWorkspace()">
                                No Cancel
                            </a-btn>
                        </div>
                    </a-sheet>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog v-model="dialogCancelPageModal" persistent max-width="400">
            <SCancelChanges
                @success="localCancelWorkflowChanges"
                @close="dialogCancelPageModal = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to cancel?</template>
                <template #successButtonLabel>Yes, cancel</template>
                <template #cancelButtonLabel>No</template>
            </SCancelChanges>
        </a-dialog>
        <a-dialog v-model="dialogLeavePageModal" persistent max-width="400">
            <SCancelChanges
                @success="pageToLeave ? pageToLeave() : {}"
                @close="dialogLeavePageModal = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import loadWorkspace from '@/mixins/mixin-workspace'
import { v4 as uuidv4 } from 'uuid'
import PAutomationWorkflowStatusBar from './Partials/PartialAutomationWorkflowStatusBar'
import PAutomationWorkflowViewHeader from './Partials/PartialAutomationWorkflowViewHeader'
import PAutomationWorkflowLogs from './Partials/PartialAutomationWorkflowLogs'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import PAutomationWorkflowBreadcrumb from './Partials/PartialAutomationWorkflowBreadcrumb'
import { SCancelChanges } from '@/config/config-shared-components'
import PAutomationSummary from './Partials/PartialAutomationSummary'
import PAutomationMenu from './Partials/PartialAutomationMenu'
import PAutomationTrigger from './Partials/PartialAutomationTrigger'
import PAutomationWorkflowConditions from './Partials/PartialAutomationWorkflowConditions'
import PAutomationWorkflowAction from './Partials/PartialAutomationWorkflowAction'
import PAutomationWorkspace from './Partials/PartialAutomationWorkspace'

export default {
    mixins: [
        loadWorkspace,
        mixinLoading
    ],
    components: {
       SCancelChanges,
       PAutomationWorkflowViewHeader,
       PAutomationWorkflowStatusBar,
       PAutomationWorkflowLogs,
       PAutomationWorkflowBreadcrumb,
       PAutomationSummary,
       PAutomationMenu,
       PAutomationTrigger,
       PAutomationWorkflowConditions,
       PAutomationWorkflowAction,
       PAutomationWorkspace,
    },
    data() {
        return {
            configurationMenuSelected: [],
            triggers : [
                { type: "project-created", icon: 'add_box'},
                { type: "project-updated", icon: 'border_color'},
                { type: "project-status-updated", icon: 'donut_large'},
                { type: "project-upsert", icon: 'add_box'},
                { type: "project-stage-changed", icon: 'label_important'},
                { type: "project-completed", icon: 'task_alt'},
                { type: "project-archived", icon: 'archive'},
            ],
            triggerList: [
                { title: "Update Field", icon: 'edit_note', slug: 'project', description: 'Update certain project fields when a trigger is activated or the conditions are met.', subtitle: 'Update the existing field',},
                { title: "Update Collaborator", icon: 'people_alt', slug: 'collaborators', description: 'Update collaborator(s) to a project when a trigger is activated or the conditions are met.', subtitle: 'Update collaborators to the project team',},
            ],
            COMPETITOR_FILTERS: {
                FIEDS: 'fields[competitors]',
                TITLE: 'filter[name]',
                SORT: 'sort',
                COUNT: 'count',
            },
            competitor_filters: {},
            TAG_FILTERS: {
                FIEDS: 'fields[tags]',
                LABEL: 'filter[label]',
                TYPE: 'filter[type]',
                SORT: 'sort',
                COUNT: 'count',
            },
            tag_filters: {},
            STAGE_FILTERS: {
                FIEDS: 'fields[stages]',
                WORKSPACE: 'filter[workspace_id]',
                NAME: 'filter[name]',
                SORT: 'sort',
                COUNT: 'count',
            },
            stage_filters: {},
            PARTNER_FILTERS: {
                FIEDS: 'fields[partners]',
                NAME: 'filter[name]',
                SORT: 'sort',
                COUNT: 'count',
            },
            partner_filters: {},
            CUSTOMER_FILTERS: {
                FIEDS: 'fields[customers]',
                NAME: 'filter[name]',
                SORT: 'sort',
                COUNT: 'count',
            },
            customer_filters: {},
            TERRITORY_FILTERS: {
                FIEDS: 'fields[territories]',
                NAME: 'filter[name]',
                SORT: 'sort',
                COUNT: 'count',
            },
            territory_filters: {},

            automation_workflow_filters: {},
            AUTOMATION_TRIGGER_FILTERS: {
                TYPE: 'filter[type]',
                FIEDS: 'fields[automation_triggers]',
                SORT: 'sort',
                COUNT: 'count',
            },
            automation_trigger_filters: {},
            AUTOMATION_WORKFLOW_FILTERS: {
                FIEDS: 'fields[automation_workflows]',
                INCLUDE: 'include',
                TRIGGER_IDS: 'filter[automation_trigger_id]',
                CREATED_BY: 'fields[createdBy]',
                UPDATED_BY: 'fields[updatedBy]',
                TRIGGER: 'fields[automationTrigger]',
            },
            automation_condition_filters: {},
            AUTOMATION_CONDITION_FILTERS: {
                FIEDS: 'fields[automation_conditions]',
                WORKFLOW_ID: 'filter[automation_workflow_id]',
            },
            automation_action_filters: {},
            AUTOMATION_ACTION_FILTERS: {
                FIEDS: 'fields[automation_actions]',
                WORKFLOW_ID: 'filter[automation_workflow_id]',
            },
            user_filters: {},
            USER_FILTERS: {
                FIEDS: 'fields[users]',
                INCLUDE: 'include',
                ROLE: 'fields[role]',
                COUNT: 'count',
                IS_ACTIVE: 'filter[is_active]',
            },
            automation_log_filters: {},
            AUTOMATION_LOG_FILTERS: {
                FIEDS: 'fields[automation_logs]',
                INCLUDE: 'include',
                STATUS: 'filter[status]',
                CREATED_BY: 'fields[createdBy]',
                UPDATED_BY: 'fields[updatedBy]',
                TRIGGER: 'fields[automationTrigger]',
                WORKFLOW_ID: 'filter[automation_workflow_id]',
                WORKFLOW: 'fields[automationWorkflow]',
                TIME: 'filter[created_at]',
                SORT: 'sort',
                STATUS: 'filter[status]',
            },
            localCloneAutomationWorkflowItem: {},
            selectedTime: { label: 'Last 1 hour', value: 1 },

            // boolean
            localPageloading: false,
            dialogUpdateWorkflowTrigger: false,
            dialogActiveWorkflow: false,
            dialogUpdateWorkflowWorkspace: false,
            dialogLeavePageModal: false,
            dialogCancelPageModal: false,
            localConditionsRemoveFlag: false,
            localTriggerEditMode: false,
            localIsTriggerEditMode: false,
            localAutomationTriggerError: false,
            localAutomationTitleError: false,

            localActiveMenu: 'workflow-workspace',
            localPage:'configurations',
            defaultCount: 999,

            // array 
            localConditionsList: [],
            localTagSectionSlugs: [],
            localSystemTagList: [],
            localCustomTagList: [],
            localActionList: [],
            selectedStatus: [],

            // null
            localConditionStoreItem: null,
            localValidationStoreItem: null,
            localActionStoreItem: null,
            islocalConditionUpdate: null,
            islocalActionUpdate: null,
            islocalFieldUpdateUpdate: null,
            localTitleUpdateDebounce: null,
            localSelectedTriggerId: null,
            localCloneSelectedTriggerId: null,
            localSelectedTiggerId: null,
            pageToLeave: null,
            filterLoading: null,
        }
    },

    mounted () {
        this.localIndex()
        window.addEventListener('beforeunload', this.localBeforeUnloadEvent)
        window.addEventListener('scroll', this.localWindowScroll)
    },

    beforeDestroy () {
        window.removeEventListener('beforeunload', this.localBeforeUnloadEvent)
    },

    beforeRouteLeave (toRoute, fromRoute, nextRoute) {
        if (this.localPageloading || this.mixinIsLoading('workflow-conditions') || this.mixinIsLoading('workflow-actions') || this.mixinIsLoading('update-workflow-changes') || this.mixinIsLoading('workflow-toggle-state')) return nextRoute()
        
        this.localCheckWorkflowChanges()
        if (!this.localHasWorkflowUpdateChanges) return nextRoute()

        nextRoute(false)
        this.dialogLeavePageModal = true
        this.pageToLeave = nextRoute
    },

    computed: {
        localCheckWorkflowConditionsErrors () {
            const conditionError = this.automation_condition_list.filter(item => item.warning)
            return conditionError && conditionError.length === 0
        },

        localCheckWorkflowActionErrors () {
            const actionError = this.automation_action_list.filter(item => item.warning)
            return actionError && actionError.length === 0
        },

        localHasWorkflowUpdateChanges () {
            return (this.islocalFieldUpdateUpdate === false ||  this.islocalConditionUpdate === false || this.islocalActionUpdate === false)
        },

        localActionCount () {
            return !(this.localActionList.length && this.localActionErrorCheck.length) 
        },

        localActionErrorCheck () {
            return this.localActionList.filter(item => item.is_valid === 1)
        },

        localCheckHiddenWorkspace () {
            const workspaceList = this.workspace_board_list.filter(list => list.id === this.localCloneAutomationWorkflowItem.workspace_id)
            return workspaceList.length ? false : true
        },

        localNumberOperator () {
            return [
                'greater-than',
                'greater-than-or-equal',
                'less-than',
                'less-than-or-equal',
            ]
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_list: 'list',
        }),

        ...mapState('Competitor', {
            competitor_list: 'list',
            competitor_response: 'response',
        }),

        ...mapState('Stage', {
            stage_list: 'list',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Customer', {
            customer_list: 'list',
            customer_response: 'response',
        }),

        ...mapState('Territory', {
            territory_list: 'list',
        }),

        ...mapState('TagSection', {
            tag_section_list: 'list',
        }),

        ...mapState('CustomField', {
            custom_field_list: 'list',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
            tag_response: 'response',
        }),

        ...mapState('AutomationWorkflow', {
            automation_workflow_item: 'item',
            automation_workflow_response: 'response',
        }),
        
        ...mapState('AutomationConditionEntity', {
            automation_condition_entity_list: 'list',
        }),

        ...mapState('AutomationActionEntity', {
            automation_action_entity_list: 'list',
        }),

        ...mapState('AutomationTrigger', {
            automation_trigger_list: 'list',
        }),

        ...mapState('AutomationCondition', {
            automation_condition_list: 'list',
        }),

        ...mapState('AutomationValidation', {
            automation_validation_list: 'list',
        }),

        ...mapState('AutomationAction', {
            automation_action_list: 'list',
        }),

        ...mapState('AutomationLog', {
            automation_log_list: 'list',
        }),

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),

        ...mapState('Workspace', {
            workspace_board_list: 'board_list',
        }),
    },

    methods: {
        async localIndex () {
            if (this.user_self && this.user_self.is_admin === 0 || this.user_self.scope === 'external') {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.$vuetify.goTo(0)
            await this.automation_log_clear_list()
            this.localLoadRelatedList()
            this.localLoadWorkflowEntityList()
            this.localLoadAutomationLogList()
            this.localLoadAutomationTriggerList()

            await this.automation_workflow_clear_item()
            this.localPageloading = true
            await this.localShowAutomationWorkflow(this.$route.params.id)
            this.localPageloading = false
            
            this.localLoadRelatedWorkflowData()
            this.localCloneAutomationWorkflowItem = _.cloneDeep(this.automation_workflow_item)
            await this.localLoadStageList()
        },

        localWindowScroll () {
            const workflows = document.querySelectorAll('.workflow');
            workflows.forEach(sec => {
                const top = window.scrollY + 320;
                const offset = sec.offsetTop;
                const height = sec.offsetHeight;
                const id = sec.getAttribute('id');
                if (top >= offset && top < offset + height) {
                    const target = document.querySelector("#"+id);
                    this.localActiveMenu = target.id
                }
            })
        },

        async localPageUpdate (page) {
            this.localActiveMenu = null
            if (this.localPage === page) return
            this.localPage = page
            if(this.localPage === 'logs') {
                this.$vuetify.goTo(0)
                this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.STATUS] = null
                this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TIME] = 1
                this.selectedTime.value = 1
                this.selectedTime.label = 'Last 1 hour'
                this.selectedStatus = []
                this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TIME] = 1
                await this.localAutomationLogIndex()
            }
        },

        localLoadWorkflowEntityList () {
            this.automation_condition_entity_index()
            this.automation_action_entity_index()
        },

        localLoadRelatedWorkflowData () {
            this.localLoadAutomationConditionList()
            this.localLoadAutomationActionList()
        },

        localIsAutomationWorkflowRelativeCount () {
            if(this.automation_workflow_item) {
                this.configurationMenuSelected.push('workflow-summary')
                if (this.automation_workflow_item.automation_trigger_id) this.localIsValidateMenu('workflow-trigger')

                if (this.automation_workflow_item.automation_condition_count !== 0) this.localIsValidateMenu('workflow-conditions')
            
                if (this.automation_workflow_item.automation_action_count !== 0) this.localIsValidateMenu('workflow-actions')
            }
        },

        localIsValidateMenu (slug) {
            const index = this.configurationMenuSelected.findIndex(item => item === slug)
            if (index !== -1) return
            this.configurationMenuSelected.push(slug)
        },

        async localShowAutomationWorkflow (id) {
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.FIEDS] = 'id,title,description,is_active,order,workspace_id,stage_from,stage_to,automation_trigger_id,updated_by_id,created_by_id,created_at,updated_at'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.INCLUDE] = 'automationTrigger,createdBy,updatedBy,automationConditionCount,automationActionCount'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.CREATED_BY] = 'id,name,avatar,initial,color'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.UPDATED_BY] = 'id,name,avatar,initial,color'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.TRIGGER] = 'id,title,slug,description'
            await this.automation_workflow_show({id: id, params: this.automation_workflow_filters })
        },

        async localLoadAutomationTriggerList () {
            this.automation_trigger_filters[this.AUTOMATION_TRIGGER_FILTERS.TYPE] = 'action'
            this.automation_trigger_filters[this.AUTOMATION_TRIGGER_FILTERS.FIEDS] = 'id,title,slug,description'
            this.automation_trigger_filters[this.AUTOMATION_TRIGGER_FILTERS.COUNT] = 100
            await this.automation_trigger_index(this.automation_trigger_filters)
        },

        // action config
        localActionConfig () {
            this.localSelectMenu('workflow-actions')
        },

        async localSelectMenu (slug) {
            this.localPage = 'configurations'
            if (this.localActiveMenu === slug) return
            this.localActiveMenu = slug

            if (this.localActiveMenu === 'workflow-summary') this.$vuetify.goTo(0)

            if (this.localActiveMenu === 'workflow-conditions') this.automation_condition_entity_index()
            
            if (this.localActiveMenu === 'workflow-actions')this.automation_action_entity_index()

            setTimeout(async () => {
                this.localSeletedMenuActive(slug)
            }, 100)
        },

        localCheckSelectMenu () {
            return ['workflow-workspace','workflow-actions','workflow-trigger', 'workflow-conditions'].includes(this.localActiveMenu)
        },

        localSeletedMenuActive (slug) {
            const element = document.getElementById(slug);
            if (!element) return

            if (slug === 'workflow-conditions' && this.localConditionsList.length === 0) {
                window.scrollTo(0, element.offsetTop -170)
                return
            }
            if (slug === 'workflow-workspace') {
                window.scrollTo(0, 0)
                return
            }
            window.scrollTo(0, element.offsetTop - 18)
        },

        async localLoadRelatedList () {
            this.localLoadUserList()
            this.localLoadCompetitorList()
            // this.localLoadStageList()
            this.localLoadPartnerList()
            this.localLoadTerritoryList()
            this.localLoadCustomerList()
            await this.localLoadSystemTagList()
            await this.localLoadCustomTagSectionList()
            await this.localLoadCustomTagList()
            await this.tag_clear()
        },

        async localLoadSystemTagList () {
            await this.tag_clear()
            let system_type = ['label','product','platform']
            this.tag_filters[this.TAG_FILTERS.TYPE] =  system_type
            this.tag_filters[this.TAG_FILTERS.FIEDS] = 'id,type,label,color'
            this.tag_filters[this.TAG_FILTERS.SORT] = 'label'
            this.tag_filters[this.TAG_FILTERS.COUNT] = this.defaultCount
            await this.tag_index(this.tag_filters)
            this.localSystemTagList = _.cloneDeep(this.tag_list)
        },

        async localLoadCustomTagSectionList () {
            await this.tag_section_index({ 'filter[is_active]': 1, sort: 'order', 'fields[tag_sections]': 'id,slug', 'count': 200 })
            this.localTagSectionSlugs = this.pluckTagSectionSlugs(this.tag_section_list)
        },

        async localLoadCustomTagList () {
            this.tag_filters[this.TAG_FILTERS.TYPE] =  this.localTagSectionSlugs
            this.tag_filters[this.TAG_FILTERS.FIEDS] = 'id,type,label,color'
            this.tag_filters[this.TAG_FILTERS.SORT] = 'label'
            this.tag_filters[this.TAG_FILTERS.COUNT] = this.defaultCount
            await this.tag_index(this.tag_filters)
            this.localCustomTagList = _.cloneDeep(this.tag_list)
        },

        async localLoadCompetitorList () {
            this.competitor_filters[this.COMPETITOR_FILTERS.FIEDS] = 'id,name'
            this.competitor_filters[this.COMPETITOR_FILTERS.SORT] = 'name'
            this.competitor_filters[this.COMPETITOR_FILTERS.COUNT] = this.defaultCount
            await this.competitor_index(this.competitor_filters)
        },

        async localLoadCustomTagSectionList () {
            await this.tag_section_index({ 'filter[is_active]': 1, sort: 'order', 'fields[tag_sections]': 'id,slug', 'count': this.defaultCount })
            this.localTagSectionSlugs = this.pluckTagSectionSlugs(this.tag_section_list)
        },

        async localLoadCustomTagList () {
            await this.tag_clear()
            this.tag_filters[this.TAG_FILTERS.TYPE] =  this.localTagSectionSlugs
            this.tag_filters[this.TAG_FILTERS.FIEDS] = 'id,type,label,color'
            this.tag_filters[this.TAG_FILTERS.SORT] = 'label'
            this.tag_filters[this.TAG_FILTERS.COUNT] = this.defaultCount
            await this.tag_index(this.tag_filters)
            this.localCustomTagList = _.cloneDeep(this.tag_list)
        },

        async localLoadStageList (workspace_id = null) {
            this.stage_filters[this.STAGE_FILTERS.WORKSPACE] = workspace_id ? workspace_id : this.localCloneAutomationWorkflowItem.workspace_id
            this.stage_filters[this.STAGE_FILTERS.FIEDS] =  'id,name,status'
            this.stage_filters[this.STAGE_FILTERS.SORT] = 'order'
            this.stage_filters[this.STAGE_FILTERS.COUNT] = this.defaultCount
            await this.stage_index(this.stage_filters)
        },

        async localLoadUserList () {
            this.user_filters[this.USER_FILTERS.IS_ACTIVE] = 1
            this.user_filters[this.USER_FILTERS.FIEDS] = 'id,name,initial,scope,avatar,color,role_id,is_active'
            this.user_filters[this.USER_FILTERS.COUNT] = this.defaultCount
            this.user_filters[this.USER_FILTERS.INCLUDE] = 'role'
            this.user_filters[this.USER_FILTERS.ROLE] = 'id,name'
            await this.user_index(this.user_filters)
        },

        async localLoadPartnerList () {
            this.partner_filters[this.PARTNER_FILTERS.FIEDS] =  'id,name'
            this.partner_filters[this.PARTNER_FILTERS.SORT] = 'name'
            this.partner_filters[this.PARTNER_FILTERS.COUNT] = this.defaultCount
            await this.partner_index(this.partner_filters)
        },

        async localLoadCustomerList () {
            this.customer_filters[this.CUSTOMER_FILTERS.FIEDS] =  'id,name'
            this.customer_filters[this.CUSTOMER_FILTERS.SORT] = 'name'
            this.customer_filters[this.CUSTOMER_FILTERS.COUNT] = this.defaultCount
            await this.customer_index(this.customer_filters)
        },

        pluckTagSectionSlugs (objs, name) {
            var data = [];
            for(var i in objs){
                if(objs[i].hasOwnProperty(name)){
                    data.push(objs[i][name]);
                }
            }
            return data;
        },

        async localLoadTerritoryList () {
            await this.territory_clear()
            this.territory_filters[this.TERRITORY_FILTERS.FIEDS] =  'id,name,color'
            this.territory_filters[this.TERRITORY_FILTERS.SORT] = 'name'
            this.territory_filters[this.TERRITORY_FILTERS.COUNT] = this.defaultCount
            await this.territory_index(this.territory_filters)
        },

        async localAutomationWorkflowToggleState (state) {
            this.mixinSetLoading('workflow-toggle-state')
            await this.automation_workflow_toggle_state({id: this.automation_workflow_item.id, state: state})
            this.mixinResetLoading('workflow-toggle-state')
            if (!this.$status(this.automation_workflow_response)) return
            await this.localShowAutomationWorkflow(this.automation_workflow_item.id)
            this.automation_workflow_item.is_active = state
            this.localCloneAutomationWorkflowItem = _.cloneDeep(this.automation_workflow_item)
            this.$notify('success', state === 1 ? 'Automation Enabled Successfully!' : 'Automation Disabled Successfully!')
        },

        async localAutomationWorkflowUpdate() {
            const update_item = {}
            update_item.id = this.localCloneAutomationWorkflowItem.id
            update_item.title = this.localCloneAutomationWorkflowItem.title
            update_item.description = this.localCloneAutomationWorkflowItem.description
            update_item.stage_from = this.localCloneAutomationWorkflowItem.stage_from
            update_item.stage_to = this.localCloneAutomationWorkflowItem.stage_to
            update_item.automation_trigger_id = this.localCloneAutomationWorkflowItem.automation_trigger_id
            update_item.workspace_id = this.localCloneAutomationWorkflowItem.workspace_id

            await this.automation_workflow_update(update_item)
            if (this.$status(this.automation_workflow_response)) {
                await this.localShowAutomationWorkflow(this.localCloneAutomationWorkflowItem.id)
                this.localCloneAutomationWorkflowItem = _.cloneDeep(this.automation_workflow_item)

                // clear edit mode
                if (this.localTriggerEditMode === true) {
                    this.localIsTriggerEditMode = false
                    this.localTriggerEditMode = false
                    this.localSelectedTiggerId = null
                }
            }
        },

        // Permission
        localAutomationWorkflowPermission(type) {
            return this.$can(`automation-workflows.${type}`)
        },

        // Action related function---------start

        localAddAction (slug) {
            const item = this.$appendId({
                slug: slug,
                action_type: null,
                is_action: 0,
                field_name: slug === 'stage' ? 'stage_id' : '',
                field_type: slug === 'stage' ? 'select' : '',
                value: '',
                source_field: slug ==='stage' ? 'project_stage_id' : null,
                source_id: null,
                source_value: null,
                warning: '',
                meta: '',
                order: 1
            })
            this.localActionList.push(item)
            this.localGetActionChanges()
        },

        localCheckActionRelatedChanges (action = null) {
            if (action) {
                const action_index = this.localActionList.findIndex(item => item.id === action.id)
                const field_item  = this.automation_action_entity_list.find(item => item.name === action.field_name)
                if (field_item && field_item.type === 'select' && field_item.selection_type && field_item.selection_type === 'single') {
                    this.localActionList[action_index].source_value = action.source_id ? [action.source_id] : null
                }
            }
            this.localGetActionChanges()
        },

        localRemoveAction(id) {
            const index = this.localActionList.findIndex(item => item.id === id)
            if (index !== -1) this.localActionList.splice(index, 1)
            this.localGetActionChanges()
        },

        async localLoadAutomationActionList () {
            this.mixinSetLoading('workflow-actions')
            this.automation_action_filters[this.AUTOMATION_ACTION_FILTERS.FIEDS] = 'id,slug,order,field_type,field_name,value,source_field,source_id,source_value,warning,meta,is_valid,action_type,is_action'
            this.automation_action_filters[this.AUTOMATION_ACTION_FILTERS.WORKFLOW_ID] = this.$route.params.id
            await this.automation_action_index(this.automation_action_filters)
            this.localActionList = _.cloneDeep(this.automation_action_list)
            this.mixinResetLoading('workflow-actions')
        },

        localUpdateActionUpdateField (action, field_name) {
            const action_index = this.localActionList.findIndex(item => item.id === action.id)
            const field_item  = this.automation_action_entity_list.find(item => item.name === field_name)
            if (field_item) {
                this.localActionList[action_index].field_name = field_name
                this.localActionList[action_index].source_field = field_item.source_field
                this.localActionList[action_index].field_type = field_item.type
                this.localActionList[action_index].value = null
                this.localActionList[action_index].source_id = null
                this.localActionList[action_index].source_value = null
                this.localActionList[action_index].is_action = 0
            }
            this.localGetActionChanges()
        },

        localUpdateUpdateFieldActionType (action, action_type) {
            const action_index = this.localActionList.findIndex(item => item.id === action.id)
            if (action_index === -1) return
            this.localActionList[action_index].action_type = action_type
            if (action_type === 'modify' || action_type === 'add') this.localActionList[action_index].is_action = 0
            if (action_type === 'remove') {
                this.localActionList[action_index].value = null
                this.localActionList[action_index].source_id = null
                if (this.localActionList[action_index].field_name === 'title') {
                    this.localActionList[action_index].field_name = null
                }
            }
            this.localGetActionChanges()
        },

        localUpdateIsActionField (action) {
            const action_index = this.localActionList.findIndex(item => item.id === action.id)
            if (action_index === -1) return
            this.localActionList[action_index].is_action = action.is_action === 0 ? 1 : 0
            if (this.localActionList[action_index].is_action === 1) {
                this.localActionList[action_index].value = null
                this.localActionList[action_index].source_id = null
                this.localActionList[action_index].source_value = null
            }
            this.localGetActionChanges()
        },

        localUpdateActionSource (action) {
            const action_index = this.localActionList.findIndex(item => item.id === action.id)
            const field_item  = this.automation_action_entity_list.find(item => item.name === action.field_name)
            if (field_item) {
                this.localActionList[action_index].source_field = field_item.source_field
                this.localActionList[action_index].field_type = field_item.type
                this.localActionList[action_index].value = null
                this.localActionList[action_index].source_id = null
                this.localActionList[action_index].source_value = null
            }
            this.localGetActionChanges()
        },

        localUpdateDateVariable (action, variable) {
            const action_index = this.localActionList.findIndex(item => item.id === action.id)
            if (action_index === -1) return
            this.localActionList[action_index].value = variable
            this.localGetActionChanges()
        },

        localUpdateActionDateType (action, type) {
            const action_index = this.localActionList.findIndex(item => item.id === action.id)
            if (action_index === -1) return
            this.localActionList[action_index].value = ''
            this.localActionList[action_index].field_type = type
            this.localGetActionChanges()
        },

        localIsUpdateWorkspace (action, is_update) {
            const action_index = this.localActionList.findIndex(item => item.id === action.id)
            if (action_index !== -1) this.localActionList[action_index].meta = is_update
            this.localGetActionChanges()
        },

        localClearActionDate (action) {
            const action_index = this.localActionList.findIndex(item => item.id === action.id)
            if (action_index !== -1) this.localActionList[action_index].value = null
            this.localGetActionChanges()
        },

        localActionReorder (list) {
            this.localActionList = _.cloneDeep(list)
            this.islocalActionUpdate = false
        },

        async localGetActionUpdate() {
            this.localActionStoreItem = null
            const params = { automation_workflow_id: this.$route.params.id, data: this.localActionList }
            this.localActionStoreItem = params
            this.islocalActionUpdate = _.isEqual(this.localActionList, this.automation_action_list)
        },

        localGetActionChanges () {
            this.localGetActionUpdate()
        },

        // Action related function---------end

        //Condition related function-----------start
        localCreateConditionGroup (condition_group_id, group_index = null) {
            const groupId = uuidv4()
            const rowId = uuidv4()
            const row_item = {}
            row_item.id = rowId
            row_item.group = groupId
            row_item.field_name = ''
            row_item.field_type = ''
            row_item.operator = ''
            row_item.value = ''
            row_item.value_alt = ''
            row_item.related_type = null
            row_item.related_id = null,
            row_item.warning = ''
            row_item.is_valid = 1
            row_item.automation_workflow_id = this.$route.params.id
            const conditions_array = [];
            conditions_array.push(row_item)
            const newGroupObj = { group_id: groupId, conditions: conditions_array }
            if (condition_group_id === 'new' && group_index === null) {
                this.localConditionsList.push(newGroupObj)
                this.localGetConditionChanges()
                return true
            }

            if (condition_group_id === 'new' && group_index >= 0) {
                this.localConditionsList.splice(group_index + 1, 0, newGroupObj)
                this.localGetConditionChanges()
                return true
            }
            const condition_group_index = this.localConditionsList.findIndex(item => item.group_id === condition_group_id)
            row_item.group = condition_group_id
            this.localConditionsList[condition_group_index].conditions.push(row_item)
            this.localGetConditionChanges()
        },

        localRemoveCondition (condition, type = 'row') {
            if (type !== 'row') {
                const group_index = this.localConditionsList.findIndex(item => item.group_id === condition.group_id)
                this.localConditionsList.splice(group_index, 1)
                this.localGetConditionChanges()
                return true
            }
            const group_index = this.localConditionsList.findIndex(item => item.group_id === condition.group)
            const row_index = this.localConditionsList[group_index].conditions.findIndex(item => item.id === condition.id)
            this.localConditionsList[group_index].conditions.splice(row_index, 1)

            if (!this.localConditionsList[group_index].conditions.length) this.localConditionsList.splice(group_index, 1)
            this.localGetConditionChanges()
        },

        localUpdateConditionSource (condition) {
            const group_index = this.localConditionsList.findIndex(item => item.group_id === condition.group)
            const row_index = this.localConditionsList[group_index].conditions.findIndex(item => item.id === condition.id)
            const field_item  = this.automation_condition_entity_list.find(item => item.name === condition.field_name)

            if (field_item) {
                this.localConditionsList[group_index].conditions[row_index].value = ''
                this.localConditionsList[group_index].conditions[row_index].operator = ''
                this.localConditionsList[group_index].conditions[row_index].field_type = field_item.type
                this.localConditionsList[group_index].conditions[row_index].related_type = field_item.related_type
                this.localConditionsList[group_index].conditions[row_index].related_id = field_item.related_id

                if (['project_stage_id', 'customer_id', 'project_health', 'project_status_id'].includes(field_item.related_type)) {
                    this.localConditionsList[group_index].conditions[row_index].related_type = null
                    this.localConditionsList[group_index].conditions[row_index].related_id = null
                }
            }
            this.localGetConditionChanges()
        },

        localGetConditionChanges () {
            this.localGetConditionsUpdate()
        },

        localConditionChangeOperator (condition) {
            const group_index = this.localConditionsList.findIndex(item => item.group_id === condition.group)
            const row_index = this.localConditionsList[group_index].conditions.findIndex(item => item.id === condition.id)
            this.localConditionsList[group_index].conditions[row_index].value = ''
            this.localConditionsList[group_index].conditions[row_index].value_alt = ''
            this.localConditionsList[group_index].conditions[row_index].warning = null
            this.localGetConditionChanges()
        },

        async localLoadAutomationConditionList () {
            this.mixinSetLoading('workflow-conditions')
            this.automation_condition_filters[this.AUTOMATION_CONDITION_FILTERS.FIEDS] = 'id,group,field_name,field_type,operator,value,value_alt,related_type,related_id,warning,is_valid'
            this.automation_condition_filters[this.AUTOMATION_CONDITION_FILTERS.WORKFLOW_ID] =  this.$route.params.id
            await this.automation_condition_index(this.automation_condition_filters)
            await this.localSetAutomationConditionList()
            this.mixinResetLoading('workflow-conditions')
        },

        localGetConditionsUpdate () {
            this.localConditionStoreItem = null
            const params = { automation_workflow_id: this.$route.params.id, data: [] }
            for (let index = 0; index < this.localConditionsList.length; index++) {
                const item = this.localConditionsList[index];
                const groupModified = _.cloneDeep(item.conditions).map(conditions_item => {
                    conditions_item.group = _.cloneDeep(conditions_item.group)
                    conditions_item.group_id
                    return conditions_item
                })
                params.data.push(...groupModified)
            }
            this.localConditionStoreItem = params
            this.islocalConditionUpdate = _.isEqual(this.localConditionStoreItem.data, this.automation_condition_list)
        },

        async localSetAutomationConditionList () {
            this.localConditionsList = this.automation_condition_list.reduce((prev, current) => {
            const isAvail = prev.find(item => item.group_id === current.group)
                if (isAvail) {
                    current['group_id'] = current.group
                    isAvail.conditions.push(_.cloneDeep(current))
                } else {
                    current['group_id'] = current.group
                    prev.push({
                        group_id: current.group,
                        conditions: [_.cloneDeep(current)]
                    })
                }
                return prev
            }, [])
        },

        localConditionsRemoveFlagUpdate () {
            this.localConditionsRemoveFlag = true
            setTimeout(() => {
                this.localConditionsRemoveFlag = false
            }, 5000);
        },

        localTriggerEditModeUpdate () {
            this.localTriggerEditMode = true
        },

        localConditionsRemove () {
            this.localConditionsList = []
            this.localGetConditionChanges()
            this.localConditionsRemoveFlag = false
        },

        //Condition related function-----------end

        // update workflow changes
        async localGetFieldUpdate () {
            this.islocalFieldUpdateUpdate = _.isEqual(this.localCloneAutomationWorkflowItem, this.automation_workflow_item)
        },

        async localUpdateWorkflowChanges () {
            await this.localCheckWorkflowChanges()        
            this.mixinSetLoading('update-workflow-changes')
            await this.localUpdateAutomationRelatedDataChanges()
            await this.localAutomationFieldUpdate()
            const response = await this.localGetUpdateStatus()
            if(!response) {
                this.$notify('error', 'Automation Update Failed!')
                this.mixinResetLoading('update-workflow-changes')
                if (this.localActionCount === true) {
                    this.automation_workflow_item.is_active = 0
                }
                this.localCheckWorkflowChanges()
                return false
            }

            await this.localGetUpdateChanges()
            await this.localCheckWorkflowActiveStatus()
            this.mixinResetLoading('update-workflow-changes')
        },

        localGetUpdateStatus () {
            if (this.$response(this.automation_workflow_response, 'automation_trigger_id') || this.localCloneAutomationWorkflowItem.automation_trigger_id === null) {
                this.localActiveMenu = 'workflow-trigger'
                this.localAutomationTriggerError = true
                return false
            }else {
                this.localAutomationTriggerError = false
            }

            if (this.$response(this.automation_workflow_response, 'title')) {
                this.localActiveMenu = 'workflow-summary'
                return false
            }

            if (this.islocalFieldUpdateUpdate === false && !this.$status(this.automation_workflow_response)) return false
               
            const conditionErrors = this.automation_condition_list.filter(item => item.is_valid === 0)
            const actionErrors = this.automation_action_list.filter(item => item.is_valid === 0)

            if (actionErrors.length || conditionErrors.length) return false

            if (this.localCloneAutomationWorkflowItem.title === 'Untitled Automation') {
                this.localActiveMenu = 'workflow-summary'
                this.localAutomationTitleError = true
                return true
            }
            this.localAutomationTitleError = false
            return true
        },

        async localWorkflowHasChanges () {
            if (this.localHasWorkflowUpdateChanges === true) this.dialogCancelPageModal = true
        },

        async localCancelWorkflowChanges () {
            this.automation_workflow_clear_response()
            this.localConditionsList = _.cloneDeep(this.automation_condition_list)
            this.localSetAutomationConditionList()
            this.localCloneAutomationWorkflowItem = _.cloneDeep(this.automation_workflow_item)
            this.localActionList = _.cloneDeep(this.automation_action_list)
            this.localCheckWorkflowChanges()
            if (this.localIsTriggerEditMode === true) {
                this.localSelectedTiggerId = this.localCloneAutomationWorkflowItem.automation_trigger_id
            }
            this.localLoadWorkspaceStages()
            this.dialogCancelPageModal = false
        },

        localCheckWorkflowActiveStatus () {
            if (this.localActionList.length && this.localCloneAutomationWorkflowItem.is_active === 0) {
                this.dialogActiveWorkflow = true
            }
        },

        async localGetUpdateChanges () {
            if (this.localHasWorkflowUpdateChanges === true) {
                this.localStoreAutomationRevisionHistory()
                this.$notify('success', 'Automation Successfully Updated!!')
                await this.localShowAutomationWorkflow(this.localCloneAutomationWorkflowItem.id)
                this.localCloneAutomationWorkflowItem = _.cloneDeep(this.automation_workflow_item)
                this.configurationMenuSelected = []
                this.localIsAutomationWorkflowRelativeCount()
            }
            this.localCheckWorkflowChanges()
        },

        async localStoreAutomationRevisionHistory () {
            await this.automation_revision_history_store({ automation_workflow_id : this.localCloneAutomationWorkflowItem.id})
        },

        async localAutomationFieldUpdate () {
            this.automation_workflow_clear_response()
            if (this.islocalFieldUpdateUpdate === false) await this.localAutomationWorkflowUpdate()
        },

        async localUpdateAutomationRelatedDataChanges () {
            if (this.islocalConditionUpdate === false) await this.localConditionUpsert()

            if (this.islocalActionUpdate === false) await this.localActionUpsert()
        },

        async localConditionUpsert () {
            await this.automation_condition_upsert(this.localConditionStoreItem)
            await this.localLoadAutomationConditionList()
        },

        async localActionUpsert () {
            await this.automation_action_upsert(this.localActionStoreItem)
            await this.localLoadAutomationActionList()
        },

        async localUpdateWorkflowState (state) {
            if (state === 0) {
                this.dialogActiveWorkflow = false
                return
            }
            this.mixinSetLoading('update-active')
            await this.localAutomationWorkflowToggleState(state)
            this.mixinResetLoading('update-active')
            this.dialogActiveWorkflow = false
        },

        localCheckWorkflowChanges () {
            this.localGetConditionsUpdate()
            this.localGetFieldUpdate()
            this.localGetActionUpdate()
        },

        localBeforeUnloadEvent (e) {
            this.localCheckWorkflowChanges()
            if (this.localHasWorkflowUpdateChanges) {
                return e.returnValue = 'You have unsaved changes. Are you sure you want to leave?'
            }
        },

        async localSelectWorkflowTrigger (trigger_id) {
            this.localCloneSelectedTriggerId = trigger_id
            this.dialogUpdateWorkflowTrigger = true
        },

        async localUpdateWorkflowTrigger () {
            this.localSelectedTriggerId = this.localCloneSelectedTriggerId
            this.localCloneAutomationWorkflowItem.automation_workflow_id = this.localSelectedTriggerId
            this.dialogUpdateWorkflowTrigger = false
        },

        async localCloseWorkflowTrigger () {
            this.dialogUpdateWorkflowTrigger = false
        },

        // logs related function-----------start 

        async localAutomationLogStatusFilter (value) {
            this.filterLoading = 'status'
            if (!value) {
                this.selectedStatus = []
                this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.STATUS] = null
                await this.localAutomationLogIndex()
                this.triggerDropdown = false
                this.filterLoading = null
                return 
            } 

            if (this.selectedStatus && !this.selectedStatus.length) this.selectedStatus.push(value)
            else {
                const index = this.selectedStatus.findIndex(id => id === value)
                if (index !== -1) this.selectedStatus.splice(index, 1)
                else this.selectedStatus.push(value)
            }
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.STATUS] = this.selectedStatus
            await this.localAutomationLogIndex()
            this.filterLoading = null
        },

        async localLogAutomationTimefilter(time) {
            this.filterLoading = 'time'
            this.selectedTime.label = time.label
            this.selectedTime.value = time.value
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TIME] = time.value
            await this.localAutomationLogIndex()
            this.filterLoading = null
        },

        async localLoadAutomationLogList () {
            this.mixinSetLoading('workflow-logs')
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TIME] = 1
            await this.localAutomationLogIndex()
            this.mixinResetLoading('workflow-logs')
        },

        async localAutomationLogIndex () {
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.WORKFLOW_ID] = this.$route.params.id
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.SORT] = '-created_at'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.FIEDS] = 'id,resource_type,resource_id,status,automation_trigger_id,automation_workflow_id,updated_by_id,created_by_id,created_at,updated_at'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.INCLUDE] = 'automationTrigger,createdBy,updatedBy,automationWorkflow,automationLogEntries,automationActionCount,automationConditionCount,automationValidationCount,automationLogValidationFailedCount,automationLogConditionFailedCount'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.CREATED_BY] = 'id,name'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.UPDATED_BY] = 'id,name'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.WORKFLOW] = 'id,title'
            this.automation_log_filters[this.AUTOMATION_LOG_FILTERS.TRIGGER] = 'id,title,slug'
            await this.automation_log_index(this.automation_log_filters)
        },
        // logs related function-----------end 

        // trigger related function-----------start

        localSelectTrigger (id) {
            if (this.localSelectedTiggerId && id && this.localSelectedTiggerId == id) return

            this.localSelectedTiggerId = id
            this.localCloneAutomationWorkflowItem.automation_trigger_id = this.localSelectedTiggerId
            this.localIsTriggerEditMode = true
            this.localTriggerEditMode = true
            this.localCloneAutomationWorkflowItem.stage_from = null
            this.localCloneAutomationWorkflowItem.stage_to = null

            if (this.localSelectedTiggerId === this.automation_workflow_item.automation_trigger_id) {
                this.localCloneAutomationWorkflowItem.stage_from = this.automation_workflow_item.stage_from
                this.localCloneAutomationWorkflowItem.stage_to = this.automation_workflow_item.stage_to
            }
            this.localGetFieldUpdate()
        },

        localGetTriggerIcon (type) {
            const trigger = this.triggers.find(item => item.type && item.type === type)
            return trigger ? trigger.icon : null
        },

        localTriggerStageFromFilterList (slug) {
            if (!slug) return this.stage_list

            if (slug === "project-stage-changed") {
                const stageList = this.stage_list.filter(list => !['completed'].includes(list.status))
                if (this.localCloneAutomationWorkflowItem.stage_to) {
                    return stageList.filter(list => !this.localCloneAutomationWorkflowItem.stage_to.includes(list.id))
                }
                return stageList
            }

            if (slug === 'project-completed') {
                const stageList = this.stage_list.filter(list => !['completed'].includes(list.status))
                if (this.localCloneAutomationWorkflowItem.stage_to) {
                    return stageList.filter(list => !this.localCloneAutomationWorkflowItem.stage_to.includes(list.id))
                }
                return stageList
            }
            return this.stage_list
        },

        localTriggerStageToFilterList (slug) {
            if (!slug) return this.stage_list
            
            if (slug === "project-stage-changed") {
                const stageList = this.stage_list.filter(list => ['queue','active'].includes(list.status))
                if (this.localCloneAutomationWorkflowItem.stage_from) {
                    return stageList.filter(list => !this.localCloneAutomationWorkflowItem.stage_from.includes(list.id))
                }
                return stageList
            }
            return this.stage_list
        },

        localTriggerCancel () {
            this.localIsTriggerEditMode = false
            this.localTriggerEditMode = false
            this.localSelectedTiggerId = null
            this.localCloneAutomationWorkflowItem.automation_trigger_id = this.automation_workflow_item.automation_trigger_id
            this.localCloneAutomationWorkflowItem.stage_from = this.automation_workflow_item.stage_from
            this.localCloneAutomationWorkflowItem.stage_to = this.automation_workflow_item.stage_to
        },

        localTriggerEditUpdate () {
            this.localIsTriggerEditMode = true
            this.localSelectedTiggerId = this.localCloneAutomationWorkflowItem.automation_trigger_id
        },

        localTriggerStageUpdate () {
            this.localGetFieldUpdate()
        },

        // trigger related function-----------end

        // workspace related  function ----------start
        async localSelectWorkspace () {
            if (this.automation_workflow_item.workspace_id !== this.localCloneAutomationWorkflowItem.workspace_id) {
                this.localCloneAutomationWorkflowItem.stage_from = null
                this.localCloneAutomationWorkflowItem.stage_to = null
                this.dialogUpdateWorkflowWorkspace = true
                return
            }

            await this.localLoadWorkspaceStages()
            this.localCloneAutomationWorkflowItem.stage_from = this.automation_workflow_item.stage_from
            this.localCloneAutomationWorkflowItem.stage_to = this.automation_workflow_item.stage_to 
            this.localGetFieldUpdate()    
            this.dialogUpdateWorkflowWorkspace = false
        },

        localCheckDefaultWorkspace () {
            const defaultWorkspace = this.workspace_board_list.find(item => item.is_default === 1 && item.id === this.localCloneAutomationWorkflowItem.workspace_id)
            return defaultWorkspace ? true : false
        },

        async localLoadWorkspaceStages () {
            this.mixinSetLoading('workflow-stages')
            await this.stage_clear()
            await this.localLoadStageList(this.automation_workflow_item.workspace_id)
            this.mixinResetLoading('workflow-stages')
        },

        async localUpdateWorkspace () { 
            this.localCloneAutomationWorkflowItem.stage_from = null
            this.localCloneAutomationWorkflowItem.stage_to = null
            this.dialogUpdateWorkflowWorkspace = false
            this.mixinSetLoading('workflow-stages')
            await this.stage_clear()
            await this.localLoadStageList(this.localCloneAutomationWorkflowItem.workspace_id)
            this.localGetFieldUpdate()
            this.mixinResetLoading('workflow-stages')
        },

        async localCancelWorkspace () {
            this.dialogUpdateWorkflowWorkspace = false
            this.localCloneAutomationWorkflowItem.workspace_id = this.automation_workflow_item.workspace_id
            this.localCloneAutomationWorkflowItem.stage_from = this.automation_workflow_item.stage_from
            this.localCloneAutomationWorkflowItem.stage_to = this.automation_workflow_item.stage_to 
            this.localGetFieldUpdate()
        },
        // workspace related  function ----------end

        localConditionCustomFieldTextInfoCheck (condition) {
            const group_index = this.localConditionsList.findIndex(item => item.group_id === condition.group)
            const conditionGroupItem = this.localConditionsList[group_index]
            if (conditionGroupItem) {
                const conditionItem = conditionGroupItem.conditions.find(item => {
                    return this.localNumberOperator.includes(item.operator) && 
                        item.field_type === "string" && 
                        item.related_type === "custom_field" &&
                        item.id === condition.id
                })
                return conditionItem ? true : false
            }
            return false
        },
        
        ...mapActions('User', {
            user_index: 'index',
            user_clear: 'clear',
        }),

        ...mapActions('Partner', {
            partner_index: 'index',
            partner_clear: 'clear',
        }),

        ...mapActions('Territory', {
            territory_index: 'index',
            territory_clear: 'clear',
        }),

        ...mapActions('Competitor', {
            competitor_index: 'index',
            competitor_clear: 'clear',
        }),

        ...mapActions('Stage', {
            stage_index: 'index',
            stage_clear: 'clear',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),

        ...mapActions('Customer', {
            customer_index: 'index',
            customer_clear: 'clear',
        }),

        ...mapActions('TagSection', {
            tag_section_index: 'index',
            tag_section_clear: 'clear',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_clear: 'clear',
        }),

        ...mapActions('AutomationWorkflow', {
            automation_workflow_show: 'show',
            automation_workflow_reorder:'reorder',
            automation_workflow_update: 'update',
            automation_workflow_toggle_state: 'toggle_state',
            automation_workflow_clear_item: 'clear_item',
            automation_workflow_clear_response: 'clear_response',
        }),

        ...mapActions('AutomationTrigger', {
            automation_trigger_index: 'index',
            automation_trigger_clear_response: 'clear_response',
            automation_trigger_clear_list: 'clear_list',
        }),

        ...mapActions('AutomationConditionEntity', {
            automation_condition_entity_index: 'index',
        }),

        ...mapActions('AutomationCondition', {
            automation_condition_index: 'index',
            automation_condition_upsert: 'upsert',
            automation_condition_clear_list: 'clear_list',
        }),

        ...mapActions('AutomationActionEntity', {
            automation_action_entity_index: 'index',
        }),

        ...mapActions('AutomationAction', {
            automation_action_index: 'index',
            automation_action_upsert: 'upsert',
            automation_action_clear_list: 'clear_list',
        }),

        ...mapActions('AutomationRevisionHistory', {
            automation_revision_history_store: 'store',
        }),

        ...mapActions('AutomationLog', {
            automation_log_index: 'index',
            automation_log_clear_list: 'clear_list',
        }),
    },
}
</script>

<style scoped>
.c-add-new-conditions-info {
    position: absolute;
    top: 2px;
    right: -38px;
}
.c-configuration-card {
    max-width: 87% !important;
    min-width: 87% !important;
    display: block;
}
.c-ellipse-bottom {
    position: relative;
    width: 28px;
    height: 28px;
    left: 50%;
    background: #FFFFFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    bottom: 16px;
    z-index: 1;
}
.c-ellipse-top {
    position: relative;
    width: 28px;
    height: 28px;
    left: 50%;
    background: #FFFFFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    top: 16px;
    z-index: 1;
}
.c-ellipse-parent {
    width: 18px;
    height: 18px;
    border: 4px solid #7C4DFF;
    border-radius: 50%;
    position: relative;
    left: 5px;
    top: 5px;
}
.c-ellipse-top::before {
    position: absolute;
    content: " ";
    min-height: 42px;
    border-left: 2px solid #9E9E9E;
    left: 47%;
    bottom: 28px;
}
.c-ellipse-bottom::after {
    position: absolute;
    content: " ";
    min-height: 42px;
    border-left: 2px solid #9E9E9E;
    left: 47%;
    top: 28px;
}
.c-ellipse-line {
  left: 50%;
  min-height: 120px;
  border-left: 2px solid #9E9E9E;
  position: relative;
}
.c-add-new-conditions {
    position: relative;
    margin: 0px auto;
    width: 166px;
    top: -26px;
}
.c-edit-btn {
    background: #FFFFFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    width: 48px;
    height: 48px;
    position: absolute;
    right: -6%;
    top: 50%;
}
.c-conditions-btn.v-btn.v-btn--disabled {
    opacity: 0.5;
    background-color: #FFFFFF !important;
    color: #2962FF !important;
}
.c-automation-error {
    border: 1px solid #FFC400 !important;
    box-shadow: 0px 0px 4px 1px rgba(255, 229, 127, 0.48) !important;
}
</style>
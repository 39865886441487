<template>
    <div class="c-summary-tile u-relative u-hfull u-flex flex-column">
        <loader-project-activity-summary v-if="mixLoading" />

        <template v-else>
            <div class="pa-5">
                <div v-if="!localEmptyStagesList" class="u-flex-center-y mb-1 u-wfull" style="column-gap: 4px">
                    <template v-for="history in stageHistoryList">
                        <partial-stage-tile-summary
                            :key="history.id"
                            :history="history"
                            :status="history.statusTo"
                            :historyList="stageHistoryList"
                            :isHighlighted="localHasIdSimilar(history.stageToId)"
                            @hoveredStage="localUpdateHoverIds"
                        />
                    </template>
                </div>
                <div v-if="!localEmptyHealthList" class="u-flex-center-y mb-1 u-wfull" style="column-gap: 4px">
                    <template v-for="history in healthHistoryList">
                        <partial-health-progress-summary
                            :key="history.id"
                            :history="history"
                        />
                    </template>
                </div>
                <div class="u-flex-center-y justify-space-between mt-4">
                    <div class="md-body-2 grey--text text--darken-2 u-font-weight-semibold">
                        {{ localProjectStartDate() }}
                    </div>
                    <div v-if="localProjectTotalActiveDays > 0" class="md-body-2 grey--text text--darken-2">
                        Active for <span class="u-font-weight-semibold">{{ localProjectTotalActiveDays || 0 }} {{ localProjectTotalActiveDays === 1 ? 'day' : 'days' }}</span>
                        <template v-if="localProjectTotalActiveDays > 0">
                            since <span class="u-font-weight-semibold">{{ localFirstActiveStage ? localFormatDate({ date: localFirstActiveStage }) : '' }}</span>
                        </template>
                    </div>
                    <div class="md-body-2 grey--text text--darken-2 u-font-weight-semibold">
                        {{ localProjectHistoryEndDate }}
                    </div>
                </div>
            </div>

            <a-spacer></a-spacer>
            <a-divider></a-divider>

            <div class="pa-5 u-flex-center-y">
                <div class="md-body-2 grey--text text--darken-2 font-weight-medium">
                    Estimated:
                    <span class="u-font-weight-semibold">
                        <template v-if="localFormatDate({ t: '11', date: localProject('estimated_start_date') }) || localFormatDate({ t: '22', date: localProject('estimated_end_date') })">
                            <template v-if="!localFormatDate({ t: '55', date: localProject('estimated_start_date') })">
                                No Start Date -> {{ localFormatDate({ t: '66', date: localProject('estimated_end_date') }) || 'End Date' }}
                            </template>
                            <template v-else-if="!localFormatDate({ t: '77', date: localProject('estimated_end_date') })">
                                {{ localFormatDate({ t: '88', date: localProject('estimated_start_date') }) || 'Start Date' }} -> No End Date
                            </template>
                            <template v-else>
                                {{ localFormatDate({ date: localProject('estimated_start_date') }) }} -> {{ localFormatDate({ date: localProject('estimated_end_date') }) }}
                            </template>
                        </template>
                        <template v-else>
                            No Start Date & End Date
                        </template>
                    </span>
                </div>
                <a-spacer></a-spacer>
                <div class="md-body-2 grey--text text--darken-2 font-weight-medium">
                    Actual:
                    <span class="u-font-weight-semibold">
                        <template v-if="localFormatDate({ date: localProject('start_date') }) || localFormatDate({ date: localProject('due_date') })">
                            <template v-if="!localFormatDate({ date: localProject('start_date') })">
                                No Start Date -> {{ localFormatDate({ date: localProject('due_date') }) }}
                            </template>
                            <template v-else-if="!localFormatDate({ date: localProject('due_date') })">
                                {{ localFormatDate({ date: localProject('start_date') }) }} -> No End Date
                            </template>
                            <template v-else>
                                {{ localFormatDate({ date: localProject('start_date') }) }} -> {{ localFormatDate({ date: localProject('due_date') }) }}
                            </template>
                        </template>
                        <template v-else>
                            No Start Date & End Date
                        </template>
                    </span>
                </div>
            </div>
        </template>

        <partial-project-summary-actions
            v-if="!mixLoading"
            :showExpand="false"
            @open="mixOpenInNewWindow('projects-overview')"
        />
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { diffSimple } from '@/helpers/helper-date-filter'
import MixinModalStateInjection from '../Mixins/MixinModalStateInjection'
import PartialHealthProgressSummary from './PartialHealthProgressSummary.vue'
import PartialProjectSummaryActions from './PartialProjectSummaryActions.vue'
import PartialStageTileSummary from './PartialStageTileSummary.vue'
import LoaderProjectActivitySummary from '../Loaders/LoaderProjectActivitySummary.vue'
import { useId } from '@/helpers/helper-use-id'

export default {
	components: {
        PartialProjectSummaryActions,
        PartialHealthProgressSummary,
        PartialStageTileSummary,
        LoaderProjectActivitySummary
    },

    mixins: [MixinModalStateInjection],

    props: {
        noFetch: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            similarHoverIds: [],
            stageHistoryList: [],
            totalStageHistory: [],
            stageTotalDays: 0,
            healthHistoryList: [],
            totalHealthHistory: [],
            healthTotalDays: 0,
            stageIndexParams: {
                include: 'stageTo,stageFrom,createdBy,project.result',
                'fields[project_stage_updates]': 'id,stage_from_id,project_id,stage_to_id,status_from,status_to,days,created_at,updated_at,created_by_id',
                'fields[stage_to]': 'id,name,workspace_id',
                'fields[stage_from]': 'id,name',
                'fields[created_by]': 'id,email,name',
                'fields[project]': 'id,result_id',
                'fields[project.result]': 'id,status,value,color',
                count: 1000
            },
            healthIndexParams: {
                count: 1000,
                include: 'type,updatedBy',
                'fields[updated_by]': 'id,email,name',
                'fields[type]': 'id,value',
                'fields[status_updates]': 'id,type_id,updated_by_id,health,visibility,created_at,days',
                'sort': 'created_at',
            }
        }
    },

    computed: {
        localProjectTotalActiveDays () {
            return this.mixParentState?.project?.active_days || 0
        },

        localFirstActiveStage () {
            const latestActiveStage = [...this.totalStageHistory].find(i => i.statusTo === 'active')
            return latestActiveStage ? latestActiveStage.createdAt : ''
        },

        localEmptyStagesList () {
            return _.size(this.stageHistoryList) === 0
        },

        localEmptyHealthList () {
            return _.size(this.healthHistoryList) === 0
        },

        localProjectHistoryEndDate () {
            if (this.mixParentState?.project?.result_id) {
                return this.localFormatDate({ date: this.localCurrentTotalStage('updatedAt') })
            }

            return this.localFormatDate({
                date: this.localCurrentTotalStage('createdAt'),
                type: 'today'
            })
        },

        localIsQueue () {
            return this.mixParentState?.project?.status === 'queue'
        },

        localProjectCreatedAt () {
            return this.mixParentState?.project?.created_at
        },

        localQueueHealthColorPref () {
            return this.preference_get_property_by_key(`organization.projects.queue_health_color`, 'value') === 'Yes'
        },

        ...mapState('ProjectView', {
            project_stage_history_list: 'stage_history',
            project_stage_history_meta: 'stage_history_meta'
        }),

        ...mapState('StatusUpdate', {
            status_update_list: 'list',
            status_update_meta: 'meta',
        }),

        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key',
        }),
    },

    methods: {
        async localIndex () {
            if (this.noFetch || this.mixLatestSyncTimeDiff() >= 5) {
                return this.localNoFetchSetup()
            }

            this.mixSetLoading()
            await this.localFetchStageHistory()
            await this.localFetchHealthHistory()

            if (this.localEmptyStagesList) this.localSetStagePlaceholder()
            this.localSetHealthPlaceholder()

            this.mixClearLoading()
        },

        localNoFetchSetup () {
            this.totalStageHistory = this.mixGetSavedSummary.stageHistory.totalList
            this.stageTotalDays = this.mixGetSavedSummary.stageHistory.totalDay
            this.stageHistoryList = this.mixGetSavedSummary.stageHistory.list.filter(i => i.days !== 0)

            this.healthTotalDays = this.mixGetSavedSummary.healthHistory.total_days
            this.totalHealthHistory = this.mixGetSavedSummary.healthHistory.totalList
            this.healthHistoryList = this.mixGetSavedSummary.healthHistory.list.filter(i => i.days !== 0)

            if (this.localEmptyStagesList) this.localSetStagePlaceholder()
            this.localSetHealthPlaceholder()
        },

        async localFetchStageHistory () {
            await this.project_stage_history({
                id: this.mixParentState.projectId,
                params: this.stageIndexParams
            })

            this.stageTotalDays = this.project_stage_history_meta.total_days
            this.totalStageHistory = _.cloneDeep(this.project_stage_history_list.map(history => this.localConstructStageObj(history)))
            this.stageHistoryList = this.totalStageHistory.filter(i => i.days !== 0)
            this.localSaveStageHistory()
        },

        localConstructStageObj (history, totalDays = this.stageTotalDays) {
            const daysPercentage = history.days !== 0 ? ((history.days / totalDays) * 100).toFixed(2) : 0
            return {
                id: history.id,
                days: history.days,
                daysPercent: parseFloat(daysPercentage),
                statusTo: history.status_to,
                stageTo: history.stage_to ? history.stage_to.name : null,
                stageToId: history.stage_to ? history.stage_to.id : null,
                createdAt: history?.created_at || null,
                createdBy: history?.created_by || null,
                updatedAt: history?.updated_at || null,
                updatedBy: history?.updated_by || null,
                result: history?.project?.result || null
            }
        },

        async localFetchHealthHistory () {
            await this.status_update_index({
                'filter[project_id]': this.mixParentState.projectId,
                ...this.healthIndexParams
            })

            this.healthTotalDays = this.status_update_meta.total_days
            this.totalHealthHistory = _.cloneDeep(this.status_update_list.map(history => this.localConstructHealthObj(history)))
            this.healthHistoryList = this.totalHealthHistory.filter(i => i.days !== 0)
            this.localSaveHealthHistory()
        },

        localConstructHealthObj (history, totalDays = this.healthTotalDays) {
            const daysPercentage = history.days !== 0 ? ((history.days / totalDays) * 100).toFixed(2) : 0
            return {
                id: history.id,
                days: history.days,
                daysPercent: parseFloat(daysPercentage),
                healthColor: history.health.charAt(0).toUpperCase() + history.health.slice(1),
                type: history?.type?.value || null,
                createdAt: history?.created_at || null,
                updatedBy: history?.updated_by || null,
            }
        },

        localSetStagePlaceholder () {
            const stageDummy = this.localConstructStageObj({
                id: useId('stage-placeholder'),
                days: Math.abs(moment(this.localProjectCreatedAt, 'YYYY-MM-DD').diff(moment(), 'days')),
                status_to: 'queue',
                stage_to: { id: null, name: 'Queue' },
                created_at: this.localProjectCreatedAt
            }, 1)
            Object.assign(stageDummy, { _type: 'placeholder' })

            this.stageHistoryList.unshift(_.cloneDeep(stageDummy))
            if (!_.size(this.totalStageHistory)) this.totalStageHistory.unshift(_.cloneDeep(stageDummy))
            if (!this.stageTotalDays) this.stageTotalDays = 1
            this.localSaveStageHistory()
        },

        localSetHealthPlaceholder () {
            if (this.localEmptyHealthList) return this.localSetupEmptyHealth()

            const firstHealthIsPlaceholder = _.size(this.healthHistoryList) && this.healthHistoryList[0]?._type === 'placeholder'
            if (firstHealthIsPlaceholder) return true

            const firstStageIsPlaceholder = _.size(this.stageHistoryList) && this.stageHistoryList[0]?._type === 'placeholder'
            if (firstStageIsPlaceholder && !firstHealthIsPlaceholder) {
                const firstHealth = this.healthHistoryList[0]
                const projectCreatedAt = moment(this.localProjectCreatedAt).format('YYYY-MM-DD')
                const daysCount = Math.abs(moment(firstHealth.createdAt, 'YYYY-MM-DD').diff(projectCreatedAt, 'days'))
                if (daysCount <= 0) return true

                return this.localSetDummyHealthPlaceholder(firstHealth.id, daysCount)
            }

            if (!firstStageIsPlaceholder && !firstHealthIsPlaceholder) {
                const firstStage = this.stageHistoryList[0]
                const firstHealth = this.healthHistoryList[0]
                const firstHealthCreatedAt = moment(firstHealth.createdAt).format('YYYY-MM-DD')
                const firstStageCreatedAt = moment(firstStage.createdAt).format('YYYY-MM-DD')
                const daysCount = Math.abs(moment(firstHealthCreatedAt).diff(firstStageCreatedAt, 'days'))
                if (daysCount <= 0) return true

                return this.localSetDummyHealthPlaceholder(firstStage.id, daysCount)
            }
        },

        localSetDummyHealthPlaceholder (modelId, daysCount) {
            this.healthTotalDays = this.healthTotalDays + daysCount
            const healthDummy = this.localConstructHealthObj({
                id: 'health-placeholder-' + modelId,
                days: daysCount,
                health: this.localQueueHealthColorPref ? 'green' : 'grey',
                created_at: this.localProjectCreatedAt,
            })
            Object.assign(healthDummy, { _type: 'placeholder' })

            this.totalHealthHistory.unshift(_.cloneDeep(healthDummy))
            this.healthHistoryList.unshift(_.cloneDeep(healthDummy))
            this.localSaveHealthHistory()
        },

        localSetupEmptyHealth () {
            const healthDummy = this.localConstructHealthObj({
                id: useId('health-placeholder'),
                days: Math.abs(moment(this.localProjectCreatedAt, 'YYYY-MM-DD').diff(moment(), 'days')),
                health: this.localQueueHealthColorPref ? 'green' : 'grey',
                created_at: this.localProjectCreatedAt
            }, 1)
            Object.assign(healthDummy, { _type: 'placeholder' })

            this.healthHistoryList.unshift(_.cloneDeep(healthDummy))
            if (!_.size(this.totalHealthHistory)) this.totalHealthHistory.unshift(_.cloneDeep(healthDummy))
            if (!this.healthTotalDays) this.healthTotalDays = 1
            this.localSaveHealthHistory()
        },

        localSaveHealthHistory () {
            this.mixSaveProject({
                healthHistory: {
                    list: this.healthHistoryList,
                    totalDays: this.healthTotalDays,
                    totalList: this.totalHealthHistory
                }
            })
        },

        localSaveStageHistory () {
            this.mixSaveProject({
                stageHistory: {
                    list: this.stageHistoryList,
                    totalDays: this.stageTotalDays,
                    totalList: this.totalStageHistory
                }
            })
        },

        localCurrentTotalStage (field) {
            if (!_.size(this.totalStageHistory)) return null

            return this.totalStageHistory.slice(-1)[0][field]
        },

        localProjectStartDate () {
            const startDate = this.localProjectCreatedAt || null
            if (!startDate) return ''

            return this.localFormatDate({ date: startDate })
        },

        localFormatDate (props) {
            const { date = null, type = null } = props || {}
            if (type === 'today') return diffSimple(moment())
            if (!date) return ''

            return date ? diffSimple(date) : null
        },

        localProject (field) {
            return this.mixParentState.project && this.mixParentState.project[field]
        },

        localUpdateHoverIds (ids) {
            this.similarHoverIds = ids
        },

        localHasIdSimilar (stageToId) {
            if (!_.size(this.similarHoverIds)) return true

            return this.similarHoverIds.indexOf(stageToId) !== -1
        },

        ...mapActions('ProjectView', {
            project_stage_history: 'stage_history'
        }),

        ...mapActions('StatusUpdate', {
            status_update_index: 'index'
        }),
    }
}
</script>

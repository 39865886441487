<template>
    <a-card class="u-relative white u-rounded-corners-lg">
        <div class="py-5 px-6 u-flex-center-y">
            <h2 class="md-heading-6 grey--text text--darken-3">Bulk Status Update - {{ localGetModule }}</h2>
            <a-spacer></a-spacer>
            <a-icon size="20" color="grey darken-1" class="u-cursor-pointer" @click="$emit('close')">clear</a-icon>
        </div>
        <a-divider></a-divider>

        <h3 class="md-subtitle-1 body--text text--lighten-1 mt-6 px-6">{{ localGetCount }} selected</h3>
        <div class="u-flex-center-y px-6 py-6 pt-4 u-wfull">
            <template v-if="currentFilter && currentFilter.mode_slug && !['all', 'none'].includes(currentFilter.mode_slug)">
                <h3 :class="[status_text_color[currentFilter.status], 'md-subtitle-1 font-weight-medium']">{{ currentFilter.label }}</h3>
                <a-icon size="32" color="body lighten-3" class="mx-3">trending_flat</a-icon>
            </template>
            <a-menu offset-y dense>
                <template v-slot:activator="{ on, value }">
                    <div v-on.stop="on" class="">
                        <div :class="['c-no-propagate u-flex align-center u-wfull px-2 u-cursor-pointer u-no-select', (currentFilter && currentFilter.label ? 'py-1' : 'py-1'), local_get_status_color(status_classes[selectedStatus && selectedStatus.status ? selectedStatus.status : 'Open'], 'bg'), local_get_status_color(status_classes[selectedStatus && selectedStatus.status ? selectedStatus.status : 'Open'], 'text')]" style="min-width: 140px; padding-top: 0px; padding-bottom: 0px; border-radius: 4px; border: 1px solid rgba(0,0,0,0.08)">
                            <div small class="u-wfull ma-0 md-body-2 u-flex align-center font-weight-medium" style="border-radius: 4px;">
                                {{ selectedStatus && selectedStatus.value ? selectedStatus.value : 'Select Status' }}
                            </div>
                            <a-spacer></a-spacer>
                            <a-icon size="15" :color="local_get_status_color(status_classes[selectedStatus && selectedStatus.status ? selectedStatus.status : 'Open'], 'icon')">keyboard_arrow_{{ value ? 'up' : 'down'}}</a-icon>
                        </div>
                    </div>
                </template>
                <a-list class="u-list-condensed">
                    <a-list-item v-for="status in localGetStatusList" :key="status.id" @click="selectedStatus = status" :disabled="currentFilter && currentFilter.status_id === status.id">
                        <a-list-item-title class="md-body-2">{{ status.value }}</a-list-item-title>
                    </a-list-item>
                </a-list>
            </a-menu>
        </div>

        <v-slide-y-transition group hide-on-leave leave-absolute>
            <div key="commentBox" class="px-6 mb-6 u-relative" v-if="selectedStatus.status && selectedStatus.status !== 'Open'">
                <a-textarea
                    v-model="commentInput"
                    rows="4"
                    placeholder="Post your comment..."
                    background-color="grey lighten-4"
                    class="u-border"
                    solo flat hide-details no-resize
                ></a-textarea>
                <g-field-info :error="$response(comment_response, 'content') || commentError" :has-error="$response(comment_response, 'content') || commentError" show-icon-on-error></g-field-info>
                <span class="u-absolute md-caption grey--text text--darken-1" style="bottom: -24px; right: 24px;">(Optional)</span>
            </div>
            <a-divider key="divider" class="mb-6" v-else></a-divider>
        </v-slide-y-transition>

        <div class="u-flex-center-y pb-6 px-6">
            <a-btn class="primary darken-1 ma-0 u-rounded-corners" :disabled="!selectedStatus.value" :loading="status_loading" depressed @click="localUpdate">Update Status</a-btn>
            <a-btn class="grey--text text--darken-1 ma-0 ml-3 u-rounded-corners" text @click="$emit('close')">Cancel</a-btn>
            <!-- <a-spacer></a-spacer> -->
            <!-- <h4 v-if="selectedCollabList.length" class="indigo--text text--darken-2 md-body-2">{{ selectedCollabList.length }} selected</h4> -->
        </div>
    </a-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    props: {
        module: {
            type: String
        },
        commentError: {
            type: String
        },
        currentFilter: {
            type: Object
        },
        isOpen: {
            type: Boolean
        },
        tcStatuses: {
            type: Array
        },
        scStatuses: {
            type: Array
        },
        scCount: {
            type: Number
        },
        tcCount: {
            type: Number
        },
    },

    data () {
        return {
            status_loading: false,
            commentInput: '',
            selectedStatus: {},
            status_text_color: {
                'Open': 'grey--text text--darken-1',
                'Passed': 'green--text text--darken-1',
                'Failed': 'red--text text--darken-1',
                'Invalid': 'grey--text text--darken-4',
                'Closed': 'green--text text--darken-1',
            },
        }
    },

    watch: {
        isOpen (value) {
            if (!value) {
                this.local_reset_form()
                this.comment_clear_item()
            }
        }
    },

    computed: {
        localGetModule () {
            switch (this.module) {
                case 'all-uc': return 'Use Cases'
                case 'all-sc': return 'Success Criteria'
                case 'all-tc': return 'Test Cases'
            }
        },

        localGetCount () {
            switch (this.module) {
                case 'all-sc': return this.scCount + ' Success Criteria'
                case 'all-tc': return this.tcCount + ' Test Case' + (this.tcCount > 1 ? 's' : '')
            }
        },

        localGetStatusList () {
            switch (this.module) {
                case 'all-sc': return this.scStatuses
                case 'all-tc': return this.tcStatuses
            }
        },

        status_classes () {
            return {
                Open: { bg: 'grey lighten-3', text: 'grey--text text--darken-3', icon: 'grey darken-3' },
                Passed: { bg: 'green darken-1', text: 'white--text', icon: 'white' },
                Failed: { bg: 'deep-orange darken-', text: 'white--text', icon: 'white' },
                Invalid: { bg: 'grey darken-1', text: 'white--text', icon: 'white' },
                Closed: { bg: 'green darken-1', text: 'white--text', icon: 'white' },
            }
        },

        ...mapState('Comment', {
            comment_response: 'response',
        }),
    },

     methods: {
         local_reset_form () {
             this.selectedStatus = {}
             this.commentInput = ''
         },

         local_get_status_color (item, field) {
             return item && item[field]
        },

         localUpdate () {
             this.status_loading = true
             this.$emit('update', { status: this.selectedStatus, comment: this.commentInput }, this.localClearLoading)
         },

         localClearLoading () {
             this.status_loading = false
         },

        ...mapActions('Comment', {
            comment_clear_item: 'clear_item',
        })
    }
}
</script>

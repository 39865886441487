<template>
    <div class="u-flex u-wfull" style="column-gap: 12px;">
        <g-avatar
            :item="modalState.get(notification.data, 'actor')"
            :size="32"
            :right-space="false"
        />

        <div class="u-wfull">
            <div class="u-flex align-start">
                <partial-notifier-header moduleTitle="Project Status" />

                <a-spacer></a-spacer>

                <a-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <partial-read-icon v-if="notification.read_at" @click.stop="$emit('read', false)" />
                            <partial-unread-icon v-else @click.stop="$emit('read', true)" />
                        </div>
                    </template>
                    <span>Mark as {{ notification.read_at ? 'unread' : 'read' }}</span>
                </a-tooltip>
            </div>

            <div class="mt-2 u-flex-center-y text-truncate" style="max-width: 440px;">
                <a-icon size="18" color="blue-grey" outlined>folder_open</a-icon>
                <span
                    :title="modalState.get(notification.data, 'module.data.title')"
                    class="u-font-14 font-weight-medium ml-1 blue-grey--text text-truncate u-leading-20"
                >
                    {{ modalState.get(notification.data, 'module.data.title') }}
                </span>
            </div>

            <partial-notification-changes
                :fromValue="modalState.get(notification.data, 'from.type.value')"
                :toValue="modalState.get(notification.data, 'to.type.value')"
                :showIcon="localHasFromAndTo"
                class="mt-1"
            >
                <template #fromItem="{ item }">
                    <h4
                        :class="[localGetTypeColor(modalState.get(item, 'health')) + '--text text--darken-2']"
                        class="u-font-14 font-weight-medium u-tracking u-leading-20 text-truncate"
                        style="max-width: 160px"
                    >
                        {{ modalState.get(notification.data, 'from.type.value') }}
                    </h4>
                </template>
                <template #toItem="{ item }">
                    <h4
                        :class="[localGetTypeColor(modalState.get(item, 'health')) + '--text text--darken-2']"
                        class="u-font-14 font-weight-medium u-tracking u-leading-20 text-truncate"
                        style="max-width: 160px"
                    >
                        {{ modalState.get(notification.data, 'to.type.value') }}
                    </h4>
                </template>
            </partial-notification-changes>
        </div>
    </div>
</template>

<script>
import PartialNotificationChanges from './Partials/PartialNotificationChanges.vue'
import PartialNotifierHeader from './Partials/PartialNotifierHeader.vue'
import PartialReadIcon from './Partials/PartialReadIcon.vue'
import PartialUnreadIcon from './Partials/PartialUnreadIcon.vue'

export default {
    inject: ['modalState'],

    provide () {
        const notification = {}

        Object.defineProperty(notification, 'value', {
            enumerable: true,
            get: () => this.notification
        })

        return { notification }
    },

    components: {
        PartialUnreadIcon,
        PartialReadIcon,
        PartialNotifierHeader,
        PartialNotificationChanges
    },

    props: {
        notification: {
            type: Object
        }
    },

    computed: {
        localHasFromAndTo () {
            return !!(
                this.modalState.get(this.notification.data, 'from.type.value') &&
                this.modalState.get(this.notification.data, 'to.type.value')
            )
        },
    },

    methods: {
        localGetTypeColor (health) {
            switch (health) {
                case 'green': return 'green'
                case 'red': return 'red'
                case 'yellow': return 'orange'
                default: return 'grey'
            }
        },
    }
}
</script>

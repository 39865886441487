import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import generateBaseUrl from '@/config/config-api'

const axios = generateBaseUrl(process.env.VUE_APP_CORE_API_URL, '')

window.Pusher = Pusher
export const echo = {
    configure: (broadcastAppKey) => {
        // TODO: Implement once websockets is available in backend
        // const echo = new Echo({
        //     key: broadcastAppKey,
        //     broadcaster: process.env.VUE_APP_BROADCASTER,
        //     cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
        //     wsHost: process.env.VUE_APP_PUSHER_HOST,
        //     wsPort: process.env.VUE_APP_PUSHER_WS_PORT,
        //     wssPort: process.env.VUE_APP_PUSHER_WSS_PORT,
        //     disableStats: true,
        //     forceTLS: true,
        //     enabledTransports: ['ws', 'wss'],
        //     authorizer: (channel) => {
        //         return {
        //             authorize: async (socketId, callback) => {
        //                 const params = { socket_id: socketId, channel_name: channel.name }
        //                 try {
        //                     const response = await axios.post('/auth/broadcasting/auth', params)
        //                     callback(false, response.data)
        //                 } catch (error) {
        //                     callback(true, error)
        //                 }
        //             }
        //         }
        //     }
        // })

        // return echo
    }
}

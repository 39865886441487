<template>
    <v-list-item-group v-bind="$attrs" v-on="$listeners">
        <slot></slot>
        <!-- <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template> -->
    </v-list-item-group>
</template>

<script>
import { VListItemGroup } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VListItemGroup }
}
</script>

<template>
    <v-data-table v-on="$listeners" v-bind="$attrs">
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
    </v-data-table>
</template>

<script>
import { VDataTable } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VDataTable },

    data () {
        return {
            ref: null
        }
    },

    mounted () {
        this.ref = this.$el
    }
}
</script>

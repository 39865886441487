<template>
	<span>{{ output_string() }}</span>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { numberFormatter } from '@/helpers/helper-number.js'

export default {
    props: {
        value: { type: Number | String },
        symbol: { type: String },
        precision: { type: Number },
        restrict: { type: Boolean, default: false }
    },

    computed: {
        ...mapState('User', {
            user_self: 'self',
        })
    },
    methods: {
        output_string: function () {
            const symbol = (this.symbol) ? this.symbol : this.user_self.organization.currency.symbol
            if (this.restrict) return symbol + numberFormatter(this.value, 2)

            return currency(this.value, {
                formatWithSymbol: true,
                symbol: symbol,
                useVedic: (this.user_self.organization.currency.code == 'INR') ? true : false,
                precision: (this.precision) ? this.precision : 0
            }).format();
        }
    }
}
</script>

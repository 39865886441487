<template>
    <a-menu v-bind="$attrs" :disabled="!canUpdate || disabled" offset-y content-class="u-rounded-corners u-elevation-custom-1 white u-border" max-width="350" class="u-wfull">
        <template v-slot:activator="{ on }">
            <div v-on="on" :class="[inline ? 'd-inline-flex' : 'u-flex']" class="align-center u-rounded-corners pl-1 u-cursor-pointer pr-2 u-relative" :style="[{backgroundColor: $color(metaItem.color, 'color_bg'), width: menuWidth ? menuWidth + 'px' : 'auto'}]">
                <span :title="metaItem.value" class="md-body-2 pa-1 px-2 font-weight-medium u-rounded-corners u-wfull text-truncate" :class="[(hideIcon || !canUpdate) ? 'text-center' : '' ]" :style="[{ maxWidth: maxWidth, color: $color(metaItem.color, 'color_text'), backgroundColor: $color(metaItem.color, 'color_bg') }]">
                    {{ metaItem.value | truncateText(local_text_length, delimitter) }}
                </span>
                <a-spacer></a-spacer>
                <div :style="[{ minWidth: ((!hideIcon && canUpdate) || loading) ? '16px' : 'auto' }]">
                    <v-slide-x-reverse-transition leave-absolute hide-on-leave>
                        <div class="u-absolute" v-if="loading" style="top: 50%; transform: translateY(-50%); right: 8px;">
                            <a-progress-circular width="2" size="16" :color="$color(metaItem.color, 'color_text')" indeterminate></a-progress-circular>
                        </div>
                        <template v-else>
                            <a-icon :size="iconSize" v-if="!hideIcon && canUpdate" :style="[{ color: $color(metaItem.color, 'color_text') }]">
                                <slot name="icon">keyboard_arrow_down</slot>
                            </a-icon>
                        </template>
                    </v-slide-x-reverse-transition>
                </div>
            </div>
        </template>
        <template v-if="metaList && metaList.length">
            <a-list style="max-height: 300px;" class="overflow-y-auto py-1 u-list-condensed">
                <a-list-item class="md-body-2 grey--text text--darken-2" @click="$emit('updateMeta', { field: meta.type, meta_id: meta.id })" v-for="meta in metaList" :key="meta.id">
                    {{ meta.value }}
                </a-list-item>
            </a-list>
        </template>
        <template v-if="metaList && !metaList.length">
            <a-list class="u-list-condensed py-1">
                <a-list-item class="md-body-2 blue--text text--darken-2" @click="$route.push({ name: 'settings-metas-ticket-priority' })">
                    No Priority Found. Click to add.
                </a-list-item>
            </a-list>
        </template>
    </a-menu>
</template>

<script>
export default {
    props: {
        metaItem: {
            type: Object,
            required: true
        },
        metaList: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        hideIcon: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        textLength: {
            type: String,
            default: '12'
        },
        iconSize: {
            type: String,
            default: '14'
        },
        maxWidth: {
            type: String,
            default: '150'
        },
        delimitter: {
            type: String,
            default: '..'
        },
        menuWidth: {
            type: String | Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
        // modelId: {
        //     type: String,
        //     required: true
        // }
    },

    computed: {
        local_text_length () {
            return Number(this.textLength)
        }
    }
}
</script>

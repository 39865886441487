<template>
    <div class="c-date-picker">
        <a-menu v-model="model_visibility" :disabled="disabled" offset-y :close-on-content-click="false" max-width="100%" content-class="u-rounded-corners-lg">
            <template v-slot:activator="{ on }">
                <div v-on="on" class="u-flex-center-y">
                    <slot>Select Date</slot>
                </div>
            </template>
            <a-sheet class="c-date-picker u-flex-center-y" :color="popupColor">
                <slot name="start-date">
                    <vc-date-picker
                        ref="start_date"
                        v-model="start_date"
                        :max-date="localCheckMaxDateRange()"
                        :min-date="localCheckDateCondition()"
                        :model-config="modelConfig"
                        :select-attribute="attributes"
                        :is-dark="dark"
                        :color="color"
                        :title-position="titlePosition"
                        @input="localUpdateDate(startDateLabel, $event)"
                    >
                        <template v-slot:header="{ monthLabel, yearLabel }" v-if="hideTitle">
                            <h2 class="md-body-2 text-center font-weight-bold">{{ monthLabel }} sssss {{ yearLabel }}</h2>
                        </template>
                        <template v-slot:footer v-if="!showFooter">
                            <slot name="footer-start" :value="localFormatDate(start_date)" :field="startDateLabel" :clear="localClearDate" :label="localStartDateLabel">
                                <a-divider color="grey lighten-3"></a-divider>
                                <div class="u-wfull grey lighten-5">
                                    <div class="u-flex-center-y px-4" style="height: 42px;">
                                        <h2 :class="['md-body-2 u-wfull u-flex-center-y']">
                                            <span class="secondary--text font-weight-medium">{{ start_date ? 'Starts on' : `${localStartDateLabel}:` }}</span>
                                            <span :class="['ml-1', start_date ? 'grey--text text--darken-2' : 'grey--text']">{{ start_date ? localFormatDate(start_date) : 'Choose a date' }}</span>
                                        </h2>
                                        <a-spacer></a-spacer>
                                        <a-icon v-if="start_date" size="16" @click.stop="localClearDate(startDateLabel)" color="grey darken-2">cancel</a-icon>
                                    </div>
                                </div>
                            </slot>
                        </template>
                    </vc-date-picker>
                </slot>
                <template v-if="!noDueDate">
                    <div class="c-date-picker c-date-picker__divider"></div>
                    <slot name="due-date">
                        <vc-date-picker
                            ref="due_date"
                            v-model="due_date"
                            :min-date="localCheckMinDateRange()"
                            :model-config="modelConfig"
                            :select-attribute="attributes"
                            :is-dark="dark"
                            :color="color"
                            :title-position="titlePosition"
                            @input="localUpdateDate(dueDateLabel, $event)"
                        >
                            <template v-slot:header="{ monthLabel, yearLabel }" v-if="hideTitle">
                                <h2 class="md-body-2 text-center font-weight-bold">{{ monthLabel }} ss {{ yearLabel }}</h2>
                            </template>
                            <template v-slot:footer v-if="!showFooter">
                                <slot name="footer-start" :value="localFormatDate(due_date)" :field="dueDateLabel" :clear="localClearDate" :label="localEndDateLabel">
                                    <a-divider color="grey lighten-3"></a-divider>
                                    <div class="u-wfull grey lighten-5">
                                        <div class="u-flex-center-y px-4" style="height: 42px;">
                                            <h2 :class="['md-body-2 u-wfull u-flex-center-y']">
                                                <span class="secondary--text font-weight-medium">{{ due_date ? 'Ends on' : `${localEndDateLabel}:` }}</span>
                                                <span :class="['ml-1', due_date ? 'grey--text text--darken-2' : 'grey--text']">{{ due_date ? localFormatDate(due_date) : 'Choose a date' }}</span>
                                            </h2>
                                            <a-spacer></a-spacer>
                                            <a-icon v-if="due_date" size="16" @click.stop="localClearDate(dueDateLabel)" color="grey darken-2">cancel</a-icon>
                                        </div>
                                    </div>
                                </slot>
                            </template>
                        </vc-date-picker>
                    </slot>
                </template>
            </a-sheet>
        </a-menu>
    </div>
</template>

<script>
import { diffHuman } from '@/helpers/helper-date-filter'

export default {
    name: 'SharedDatePicker',

    props: {
        item: {
            type: Object,
            required: true
        },
        popupColor: {
            type: String,
            default: '#fff'
        },
        color: {
            type: String,
            default: 'indigo'
        },
        disabled: {
            type: Boolean,
        },
        dark: {
            type: Boolean,
            default: false
        },
        startDateLabel: {
            type: String,
            default: 'start_date'
        },
        startDateTitle: {
            type: String,
            default: 'Start Date'
        },
        dueDateTitle: {
            type: String,
            default: 'End Date'
        },
        dueDateLabel: {
            type: String,
            default: 'due_date'
        },
        noDueDate: {
            type: Boolean,
            default: false
        },
        startDateMinRange: {
            type: String,
            default: ''
        },
        hideTitle: {
            type: Boolean,
            defaul: false
        },
        showFooter: {
            type: Boolean,
            defaul: false
        },
        startDateMaxRange: {
            type: String,
            default: ''
        },
        dueDateMinRange: {
            type: String,
            default: ''
        },
        titlePosition: {
            type: String,
            default: 'left'
        },
        dateCondition: {
            type: Object,
            default: () => ({condition: null, start_date: null, end_date: null})
        }
    },

    data () {
        return {
            model_visibility: false,
            start_date: null,
            due_date: null,
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
            attributes: {
                popover: {
                    visibility: 'hover',
                    isInteractive: true,
                },
            },
        }
    },

    watch: {
        model_visibility (val) {
            if (val) {
                if (!this.item[this.startDateLabel]) this.localUpdateDateVars(this.startDateLabel, null)
                if (!this.item[this.dueDateLabel]) this.localUpdateDateVars(this.dueDateLabel, null)
                if (this.item[this.startDateLabel]) this.start_date = this.item[this.startDateLabel]
                if (this.item[this.dueDateLabel]) this.due_date = this.item[this.dueDateLabel]
            }
        },
    },

    computed: {
        localStartDateLabel () {
            return this.startDateTitle ? this.startDateTitle : this.startDateLabel.split('_').join(' ')
        },

        localEndDateLabel () {
            return this.dueDateTitle ? this.dueDateTitle : this.dueDateLabel.split('_').join(' ')
        },
    },

    methods: {
        localUpdateDate (type, value) {
            const isDueDate = Object.keys(this.$refs).includes('due_date')
            const setDateType = type === 'start_date' || type === 'estimated_start_date' ? 'due_date' : 'start_date'
            if (isDueDate && value) this.$refs[setDateType].focusDate(value)
            if (this.item[type] === value) return
            this.item[type] = value
            this.localUpdateDateVars(type, value)
            this.$emit('update', { type, value, item: this.item })
        },

        localClearDate (type) {
            if (this.item[type] === null) return
            this.item[type] = null
            this.localUpdateDateVars(type, null)
            this.$emit('update', { type, value: null, item: this.item })
        },

        localUpdateDateVars (type, value) {
            if (type === this.startDateLabel) this.start_date = value
            else this.due_date = value
        },

        localCheckMinDateRange() {
            if(!this.start_date) return null
            return moment(this.start_date).add(0, 'days').format("YYYY-MM-DD")
        },

        localCheckMaxDateRange() {
            if (this.dateCondition.condition === 'between') return this.dateCondition.end_date
            if(!this.due_date) return null
            return moment(this.due_date).add(0, 'days').format("YYYY-MM-DD")
        },

        localCheckDateCondition () {
            if (this.dateCondition.condition === "between") return this.dateCondition.start_date
            return null
        },

        localFormatDate (date, range = false, simple = false) {
            if (range) return moment(date).format('YYYY-MM-DD')
            if (simple) return moment(date).format('MMM D')
            return date ? moment(date).format('MMM D, YYYY') : null
        },
    }
}
</script>

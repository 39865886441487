<template>
    <div
        class="c-summary-tile u-relative u-hfull u-rounded-corners-xl"
        :style="[
            count.total && !mixLoading ? { background: localGetGradientColor } : {},
            mixLoading ? { background: 'linear-gradient(180deg, #EBEBEB 0%, #FFF 34.94%)' } : {},
        ]"
    >
        <loader-sc-task-summary v-if="mixLoading" />

        <template v-else>
            <template v-if="localIsEmpty || !mixHasPermission('usecases.index')">
                <partial-empty-state-summary
                    :canView="mixHasPermission('usecases.index')"
                    title="Scope"
                    icon="check_box"
                    modelSlug="success criteria"
                    iconSize="26"
                    class="pb-4"
                    iconOutlined
                />
            </template>

            <div v-else class="u-relative pt-5">
                <a-sheet width="200" height="128" class="mx-auto transparent u-relative" style="z-index: 1">
                    <DoughnutChart
                        :chart-data="chartData"
                        :chart-options="chartOptions"
                        :width="100"
                        :height="100"
                    />
                </a-sheet>

                <div
                    class="pa-5 pt-0 text-center u-flex-center flex-column u-hfull u-absolute"
                    style="
                        position: absolute;
                        transform: translateX(-50%);
                        bottom: -50px;
                        left: 50%;
                        width: 100%;
                    "
                >
                    <h3 class="grey lighten-2 u-rounded-corners-full py-1 px-2 md-caption u-font-weight-semibold grey--text text--darken-3">
                        {{ localGetPercentage }}%
                    </h3>
                    <h3 class="md-body-1 grey--text text--darken-3 mt-2 u-font-weight-semibold">
                        {{ count.passed + count.invalid }} / {{ count.total }}
                    </h3>
                    <h3 class="md-body-2 grey--text text--darken-2 u-font-weight-semibold mt-2">
                        Success Criteria Progress
                    </h3>
                </div>
            </div>
        </template>

        <partial-project-summary-actions
            v-if="mixHasPermission('usecases.index') && !mixLoading"
            :showExpand="false"
            @open="mixOpenInNewWindow('projects-usecases')"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'
import { Doughnut as DoughnutChart } from 'vue-chartjs/legacy'
import MixinModalStateInjection from '../Mixins/MixinModalStateInjection'
import PartialProjectSummaryActions from './PartialProjectSummaryActions.vue'
import PartialEmptyStateSummary from './PartialEmptyStateSummary.vue'
import LoaderScTaskSummary from '../Loaders/LoaderScTaskSummary.vue'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
	components: {
        DoughnutChart,
        PartialProjectSummaryActions,
        PartialEmptyStateSummary,
        LoaderScTaskSummary
    },

    mixins: [MixinModalStateInjection],

    props: {
        noFetch: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            loading: true,
            count: { total: 0, passed: 0, invalid: 0, faled: 0, open: 0 },
            gradients: {
                passed: 'linear-gradient(180deg, #EDFFEE 0%, #FFF 34.94%)',
                failed: 'linear-gradient(180deg, #FFEDED 0%, #FFF 34.94%)',
                open: 'linear-gradient(180deg, #E0ECFF 0%, #FFF 34.94%)',
                invalid: 'linear-gradient(180deg, #EBEBEB 0%, #FFF 34.94%)'
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: { legend: false }
            },
            chartData: {
                labels: [],
                datasets: [{
                    backgroundColor: [
                        '#43A047',
                        '#448AFF',
                        '#E64A19',
                        '#BDBDBD'
                    ],
                    data: [],
                    hoverOffset: 4,
                    cutout: '80%',
                    circumference: 180,
                    rotation: 270,
                    borderWidth: 1,
                    borderColor: '#fff',
                    borderRadius: 20,
                    borderWidth: 2
                }]
            },
        }
    },

    computed: {
        localIsEmpty () {
            const { total } = this.count || {}
            return total === 0
        },

        localGetPercentage () {
            const { total, passed, invalid } = this.count
            if (total === 0 || !total) return 0

            return Math.floor((Number(passed + invalid) / Number(total)) * 100)
        },

        localGetGradientColor () {
            const highest = Object.entries(this.count).reduce((acc, current) => {
                if (acc.value > current[1]) return acc
                if (current[0] === 'total') return acc

                acc = { key: current[0], value: current[1] }
                return acc
            }, { key: '', value: 0 })

            if (this.count.passed === highest.value) {
                highest.key = 'passed'
                highest.value = this.count.passed
            }

            return this.gradients[highest.key]
        },
    },

    methods: {
        async localIndex () {
            if (!this.mixHasPermission('usecases.index')) return

            this.mixSetLoading()
            this.localResetChart()
            if (this.noFetch || this.mixLatestSyncTimeDiff() >= 5) {
                this.localNoFetchSetup()
                setTimeout(() => this.mixClearLoading(), 500)
                return
            }

            await this.localGetScStats()
            this.mixClearLoading()
        },

        localNoFetchSetup () {
            this.count = this.mixGetSavedSummary.successCriteriaStats
            this.localSetupChartStats()
        },

        async localGetScStats () {
            const params = {
                include: 'successCriteriaOpenCount,successCriteriaPassedCount,successCriteriaInvalidCount,successCriteriaFailedCount',
                'fields[projects]': 'id,total_success_criteria,total_completed_success_criteria'
            }
            const { data, status } = await this.project_show({
                id: this.mixParentState.projectId,
                stateless: true,
                params
            })

            if (status !== 'success') return
            this.count = {
                total: data.total_success_criteria,
                passed: data.success_criteria_passed_count,
                invalid: data.success_criteria_invalid_count,
                open: data.success_criteria_open_count,
                failed: data.success_criteria_failed_count
            }
            this.localSetupChartStats()
            this.mixSaveProject({ successCriteriaStats: this.count })
        },

        localSetupChartStats () {
            const labels = ['passed', 'open', 'failed', 'invalid']
            this.chartData.labels = labels.map(i => ' ' + i.charAt(0).toUpperCase() + i.slice(1))
            labels.forEach(label => {
                this.chartData.datasets[0].data.push(this.count[label])
            })
        },

        localResetChart () {
            this.chartData.labels = []
            this.chartData.datasets[0].data = []
        },

        ...mapActions('ProjectView', {
            project_show: 'show',
        }),
    }
}
</script>

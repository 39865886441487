<template>
    <div>
        <p class="ma-0 u-font-14 u-leading-20 text-truncate u-flex-center-y" style="max-width: 440px;">
            <span class="grey--text text--darken-3 u-font-weight-semibold u-tracking d-inline-block text-truncate" style="max-width: 180px;">
                {{ modalState.get(notification.value, 'data.actor.name') }}
            </span>
            <span class="grey--text text--darken-1 font-weight-medium mx-1">updated</span>
            <span class="grey--text text--darken-3 u-font-weight-semibold u-tracking">
                {{ moduleTitle }}
            </span>
        </p>
        <div class="u-flex-center-y" style="margin-top: 2px;">
            <div class="d-inline-block u-font-12 grey--text u-tracking font-weight-medium u-leading-20">
                {{ modalState.dateFormatter(modalState.get(notification.value, 'data.created_at')) }}
            </div>
            <a-icon size="4" color="grey" class="mx-2">fiber_manual_record</a-icon>
            <div
                :title="modalState.getWorkspace(modalState.get(notification.value, 'data.workspace_id'))"
                class="u-font-12 grey--text font-weight-medium u-leading-20 text-truncate"
                style="max-width: 130px;"
            >
                {{ modalState.getWorkspace(modalState.get(notification.value, 'data.workspace_id')) }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ['notification', 'modalState'],

    props: {
        moduleTitle: {
            type: String,
        }
    }
}
</script>

<template>
	<a-main>
        <a-container grid-list-xl container--fluid class="py-6 px-0 mb-16 pb-16">
            <template v-if="!local_loading">
                <a-responsive class="pt-2 my-4 text-center" v-if="settings_workspace_mixin && settings_workspace_mixin.is_global">
                    <div class="mb-2"><a-icon size="40" class="mr-1" color="orange lighten-1">lock_open</a-icon></div>
                    <h3 class="md-subtitle-1 grey--text text--darken-1 mb-1"><strong>{{ settings_workspace_mixin ? settings_workspace_mixin.title : 'Current workspace' }}</strong> is public</h3>
                    <p class="md-body-2 grey--text mb-0">It is visible to every user under this organization</p>
                </a-responsive>
                <template v-else>
                    <a-layout v-if="workspace_member_list && workspace_member_list.length">
                        <a-flex xs12>
                            <draggable v-model="workspace_member_list" :options="{handle: '.js-drag-handle'}">
                                <template v-for="workspace_member in workspace_member_list">
                                    <div class="white u-elevation-custom-1 u-rounded-corners" :key="workspace_member.id">
                                        <a-card flat>
                                            <a-card-text class="pa-0">
                                                <a-container container--fluid grid-list-xl class="pa-4">
                                                    <a-layout align-center v-if="workspace_member.user">
                                                        <a-flex shrink>
                                                            <a-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <div class="u-flex align-start" v-on="on">
                                                                        <g-avatar :item="workspace_member" :size="40"></g-avatar>
                                                                        <div>
                                                                            <h2 class="md-subtitle-2 u-flex align-center grey--text text--darken-3">
                                                                                {{ workspace_member.user.name }}
                                                                                <span class="ml-1 my-0 md-caption font-weight-medium grey--text text--darken-2 u-flex align-center" v-if="workspace_member.user.is_active === 1"><a-icon size="10" class="mr-1" color="green darken-1">fiber_manual_record</a-icon> </span>
                                                                                <span class="ml-1 my-0 md-caption font-weight-medium grey--text text--darken-2 u-flex align-center" v-if="workspace_member.user.is_active === 0 && workspace_member.user.is_invited"><a-icon size="10" class="mr-1" color="orange darken-1">fiber_manual_record</a-icon> </span>
                                                                                <span class="ml-1 my-0 md-caption font-weight-medium grey--text text--darken-2 u-flex align-center" v-if="workspace_member.user.is_active === 0 && !workspace_member.user.is_invited"><a-icon size="12" class="mr-1" color="red darken-1">not_interested</a-icon> </span>
                                                                            </h2>
                                                                            <h3 class="md-body-2 d-block grey--text text--darken-2">{{ workspace_member.user.email }}</h3>
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                                <span v-if="workspace_member.user.is_active === 1">Active</span>
                                                                <span v-if="workspace_member.user.is_active === 0 && workspace_member.user.is_invited">Invited</span>
                                                                <span v-if="workspace_member.user.is_active === 0 && !workspace_member.user.is_invited">Blocked</span>
                                                            </a-tooltip>
                                                        </a-flex>
                                                        <a-spacer></a-spacer>
                                                        <a-flex shrink v-if="$can('workspaces.update')">
                                                            <a-menu offset-y left class="u-rounded-corners" content-class="u-elevation-custom-1 u-border" max-width="300">
                                                                <template v-slot:activator="{ on }">
                                                                    <a-btn color="grey darken-2" v-on="on" text icon small>
                                                                        <a-icon size="16">close</a-icon>
                                                                    </a-btn>
                                                                </template>
                                                                <div class="u-rounded-corners pa-4 white">
                                                                    <h2 class="md-subtitle-2 grey--text text--darken-2">Are you sure to remove this member?</h2>
                                                                    <h2 class="md-body-2 grey--text text--darken-1 mb-3">This cannot be undone.</h2>
                                                                    <a-btn dark small text @click="local_workspace_member_destroy(workspace_member.id)" class="red lighten-1 ml-0 u-rounded-corners">Remove</a-btn>
                                                                </div>
                                                            </a-menu>
                                                        </a-flex>
                                                    </a-layout>
                                                </a-container>
                                            </a-card-text>
                                        </a-card>
                                    </div>
                                    <a-divider class="grey lighten-3" :key="workspace_member.id + 'divider'"></a-divider>
                                </template>
                            </draggable>
                        </a-flex>
                    </a-layout>
                    <a-layout :class="[!workspace_member_list.length ? 'mt-4' : 'mt-1']" v-if="$can('workspaces.update')">
                        <a-flex xs12 class="py-0">
                            <a-card class="u-elevation-custom-1 u-rounded-corners">
                                <a-card-text class="pa-0">
                                    <a-layout wrap align-center px-3>
                                        <a-flex shrink class="pr-0 py-0">
                                            <a-progress-circular v-if="mixinIsLoading('removeUser') || mixinIsLoading('addUser')" width="3" size="24" indeterminate color="orange darken-2"></a-progress-circular>
                                            <a-icon color="grey" class="" size="24" v-else>add</a-icon>
                                        </a-flex>
                                        <a-flex class="pa-0">
                                            <GUsersSelectDropdown
                                                v-model="local_selected_members"
                                                :list="local_member_list"
                                                itemValue="id"
                                                :loading="mixinSearchLoading"
                                                :disabled="mixinIsLoading('removeUser') || mixinIsLoading('addUser')"
                                                :inputStyle="{ minHeight: '30px' }"
                                                @search="mixinSearchUsers"
                                                placeholder="Select Organization Members"
                                                class="pr-5"
                                                dropdownClass="c-tiny-scroll py-2"
                                                returnObject multiple removable
                                            >
                                                <template #item="{ props: { item } }">
                                                    <div class="u-flex-center-y">
                                                        <g-avatar :item="item"></g-avatar>
                                                        <g-profile-info :item="item" type-user set-max-width></g-profile-info>
                                                    </div>
                                                </template>
                                                <template #noData>
                                                    <div class="md-body-1 grey--text text--darken-1 px-2">No Member found</div>
                                                </template>
                                                <template #emptyData>
                                                    <div class="md-body-1 grey--text text--darken-1 px-2">No Member found</div>
                                                </template>
                                            </GUsersSelectDropdown>
                                        </a-flex>
                                        <a-flex shrink class="py-0">
                                            <a-btn class="u-rounded-corners grey lighten-2 ma-0 elevation-0 px-3" :disabled="!!((this.workspace_member_list && !this.workspace_member_list.length && !local_selected_members.length) || !local_selected_members.length)" @click="local_workspace_member_store()">
                                                Add Member{{ local_selected_members.length > 1 ? 's' : ''}}
                                            </a-btn>
                                        </a-flex>
                                    </a-layout>
                                </a-card-text>
                            </a-card>
                        </a-flex>
                    </a-layout>
                </template>
            </template>
            <template v-else>
                <a-responsive class="pt-2 my-4 text-center">
                    <h3 class="md-subtitle-1 grey--text text--darken-1">Loading..</h3>
                </a-responsive>
            </template>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import loadWorkspace from '@/mixins/mixin-workspace'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import mixinSearchUsers from '@/mixins/mixin-search-users'

export default {
    mixins: [loadWorkspace, mixinLoading, mixinSearchUsers],

    data () {
        return {
            local_selected_members: [],
            local_loading: true,
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        workspace_member_list: {
            get () {
                return this.$store.state.WorkspaceMember.list
            },
            set (list) {
                this.local_workspace_member_reorder(list)
            }
        },

        local_member_list () {
            if (this.workspace_member_list && this.workspace_member_list.length) {
                return this.user_list.filter(item => !(_.find(this.workspace_member_list, { user_id: item.id }))).filter(item => item.is_active)
            }
            return this.user_list.filter(item => item.is_active)
        },

        ...mapState('WorkspaceMember', {
            workspace_member_response: 'response',
        }),

        ...mapState('User', {
            user_list: 'list',
        })
    },

    methods: {
        async local_index () {
            this.user_clear()

            await this.workspace_index()
            await this.workspace_member_clear()
            await this.local_workspace_members_index()

            this.mixinFetchUsers()
            this.local_loading = false
        },

        async local_workspace_members_index () {
            await this.workspace_member_index({
                'include': 'user,user.role',
                'filter[workspace_id]': this.settings_workspace_id_mixin
            })
        },

        async local_workspace_member_store () {
            this.mixinSetLoading('addUser')
            await this.workspace_member_store({ users: _.map(this.local_selected_members, 'id'), workspace_id: this.settings_workspace_id_mixin })

            if (this.workspace_member_response.status !== 'success') return this.mixinResetLoading('addUser')

            this.workspace_board()
            this.local_workspace_members_index()
            this.local_selected_members = []
            this.mixinResetLoading('addUser')
        },

        async local_workspace_member_destroy (id) {
            this.mixinSetLoading('removeUser')
            await this.workspace_member_destroy({ id })

            if (!this.$status(this.workspace_member_response)) return this.mixinResetLoading('removeUser')

            this.workspace_board()
            this.mixinResetLoading('removeUser')
        },

        async local_workspace_member_reorder (list) {
            this.workspace_member_reorder({list: list})
        },

        ...mapActions('Workspace', {
            workspace_index: 'index',
            workspace_board: 'board',
        }),

        ...mapActions('WorkspaceMember', {
            workspace_member_index: 'index',
            workspace_member_store: 'store',
            workspace_member_destroy: 'destroy',
            workspace_member_reorder: 'reorder',
            workspace_member_clear: 'clear',
        }),

        ...mapActions('User', {
            user_index: 'index',
            user_update_list: 'update_list',
            user_clear: 'clear',
        }),
    },
}
</script>

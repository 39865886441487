<template>
    <div v-if="item">
        <!-- Code & Updated time + user -->
        <div v-if="item.code && !local_is_external" class="d-inline-flex align-center mb-2">
            <span class="u-flex align-center md-body-2 grey--text text--darken-1 mr-4 font-weight-medium">
                <a-icon size="20" color="grey darken-1" class="mr-1">tag</a-icon>
                <span>{{ item.code }}</span>
            </span>
        </div>

        <!-- Title -->
        <div class="c-textarea-editor u-relative">
            <a-textarea
                v-model="usecase_title"
                :disabled="!canUpdate || attachmentLoading"
                class="font-weight-regular u-border md-heading-4 font-weight-bold c-title-color"
                background-color="transparent"
                placeholder="Usecase title goes here.."
                multi-line auto-grow solo flat hide-details
                rows="1"
                @input="local_update_title()"
            >
                <template #append v-if="(!usecase_title || (usecase_title && !usecase_title.trim().length)) || (usecase_title && usecase_title.trim().length > titleLength)">
                    <div>
                        <a-icon color="red darken-1" size="20">info</a-icon>
                        <a-sheet class="c-tooltip-pointer c-tooltip-pointer--right-light u-absolute u-shadow px-3 py-1 md-caption u-rounded-corners red--text text--darken-1" style="right: -1px;">
                            <template v-if="usecase_title && usecase_title.trim().length > titleLength">({{ usecase_title && usecase_title.trim().length }} / {{ titleLength }})</template>
                            <template v-else>Required Field</template>
                        </a-sheet>
                    </div>
                </template>
            </a-textarea>
        </div>

        <!-- Assignee & Visibility & More -->
        <div class="u-flex align-stretch my-6">
            <slot name="assignee"></slot>

            <div class="u-flex-center-y mr-6 u-cursor-pointer u-rounded-corners u-no-select" v-ripple v-if="!local_has_tags && canUpdate && !local_is_external" @click="attachmentLoading ? {} : show_tag_section = !show_tag_section">
                <a-icon size="26" color="grey darkem-2">local_offer</a-icon>
                <span class="md-subtitle-2 grey--text text--darken-2 ml-2">Add tag</span>
            </div>

            <slot name="visibility" v-if="!local_is_external">
                <div class="mr-7">
                    <g-confirmation
                        :loading="loading"
                        :can-update="canUpdate"
                        :is-public="item && item.visibility === 'external'"
                        :disabled="attachmentLoading"
                        @action="canUpdate ? $emit('toggleVisibility', item.id, item.visibility) : ''"
                        type="Use Case"
                        class="d-inline-block"
                    ></g-confirmation>
                </div>
            </slot>

            <slot name="view-more">
                <a-menu v-if="canDelete" offset-y :close-on-content-click="false" :disabled="loading || attachmentLoading" min-width="200">
                    <template v-slot:activator="{ on }">
                        <a-sheet v-on="on" height="inherit" width="48" class="u-flex-center u-elevation-custom-1 mr-6 u-rounded-corners u-cursor-pointer">
                            <a-icon size="20" color="grey darken-1">more_horiz</a-icon>
                        </a-sheet>
                    </template>
                    <a-list class="u-list-std">
                        <a-list-item @click="confirm_delete ? local_delete_usecase() : local_get_delete_confirm()" :class="[{ 'c-delete-confirm': confirm_delete }]">
                            <a-list-item-icon>
                                <a-icon class="mr-3" size="20" color="grey darken-2">delete</a-icon>
                            </a-list-item-icon>
                            <a-list-item-content>{{ confirm_delete ? 'Confirm Delete?' : 'Delete Record' }}</a-list-item-content>
                        </a-list-item>
                    </a-list>
                </a-menu>
            </slot>

            <slot name="linked-master-template"></slot>

            <div class="d-inline-flex align-center md-body-2 grey--text text--darken-1 font-weight-medium">
                <template v-if="timer_update_debounce">
                    <a-icon size="20" color="grey darken-1" class="mr-1">pending</a-icon>
                    <span>Saving...</span>
                </template>
                <template v-if="!timer_update_debounce && update_autosaved_at">
                    <a-icon size="20" color="green darken-1" class="mr-1">cloud_done</a-icon>
                    <span class="green--text text--darken-1">Saved</span>
                </template>
            </div>
        </div>

        <slot name="tags" v-if="show_tag_section || local_has_tags"></slot>

        <!-- Usecase description -->
        <div :class="['mt-6', { 'mb-6': editor_focused }]">
            <h2 class="c-letter-space text-uppercase md-caption font-weight-medium grey--text text--darken-2 mb-3">Description</h2>
            <g-editor-box
                v-model="item.description_json"
                :can-update="canUpdate && !attachmentLoading"
                @update="local_update_description"
                @isFocused="e => editor_focused = e"
                v-if="show_desc_section"
                :editorClass="['px-1']"
                :editorHeight="120"
                :readOnly="attachmentLoading"
                :hideFocus="attachmentLoading"
                :refresh="refreshDesc"
                customMinHeight="100"
                customMaxHeight="200"
            ></g-editor-box>
        </div>

        <!-- <a-divider class="grey lighten-2 mt-7 mb-4" v-if="!editor_focused"></a-divider> -->

        <div class="mt-6">
            <slot name="attachment-section"></slot>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { diffHuman } from '@/helpers/helper-date-filter'

export default {
    props: {
        item: {
            type: Object,
        },
        response: {
            type: Object | Array,
        },
        canUpdate: {
            type: Boolean,
        },
        canDelete: {
            type: Boolean,
        },
        titleLength: {
            type: Number,
            default: 1000
        },
        loading: {
            type: Boolean,
            default: false
        },
        attachmentLoading: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            timer_update_debounce: null,
            confirm_delete: false,
            show_tag_section: false,
            show_desc_section: false,
            usecase_title: '',
            update_autosaved_at: null,
            editor_focused: false,
            refreshDesc: false
        }
    },

    watch: {
        'item.id': {
            handler (id) {
                this.local_index()
            }
        },

        attachmentLoading (val) {
            this.localRefreshDesc()
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        local_has_tags () {
            return this.item && this.item.tags && this.item.tags.length
        },

        local_is_external () {
            return this.$route.meta.view === 'external' || (this.user_self && this.user_self.scope === 'external')
        },

        ...mapState('User', {
            user_self: 'self'
        })
    },

    methods: {
        local_index () {
            this.show_desc_section = false
            setTimeout(() => {
                this.usecase_title = this.item ? this.item.title : ''
                this.show_desc_section = true
            }, 0)
        },

        local_update_title () {
            if (this.usecase_title && this.usecase_title.length > this.titleLength) return this.local_clear_debounce_timer()
            this.item.title = this.usecase_title
            this.local_update_debounced()
        },

        local_update_description (content) {
            this.item.description_json = content
            this.local_update_debounced()
        },

        async local_update_debounced () {
            this.local_clear_debounce_timer()
            this.timer_update_debounce = setTimeout(async () => this.local_item_update(), 1000)
        },

        local_item_update () {
            this.local_set_autosave()
            let clonedItem = _.cloneDeep(this.item)
            Object.assign(clonedItem, { is_autosave: 1 })
            this.$emit('update', clonedItem)
            if (this.$status(this.response)) {
                this.local_set_autosave()
                this.item.is_autosave = 0
                this.local_clear_debounce_timer()
            }
        },

        local_set_autosave () {
            this.update_autosaved_at = moment().format('YYYY-MM-DD HH:mm:ss')
            this.item.updated_at = moment()
            this.item.updated_by_id = this.user_self.id
            this.$set(this.item, 'updated_by', this.user_self)
            setTimeout(() => this.update_autosaved_at = null, 5000)
        },

        async local_clear_debounce_timer (saved = false) {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                this.item.is_autosave = 0
            }
        },

        local_get_delete_confirm () {
            this.confirm_delete = true
            setTimeout(() => this.confirm_delete = false, 3000)
        },

        local_delete_usecase () {
            this.$emit('delete', this.item.id)
        },

        local_get_updated_time (date) {
            return moment.utc(date).local().format('h:mm A')
        },

        local_get_updated_data (item, type) {
            if (!item) return
            const hasUpdated = item.updated_at ?? null
            if (hasUpdated) {
                if (type === 'type') return 'Updated'
                if (type === 'user' && item.updated_by) return item.updated_by.name
            }
            if (type === 'type') return 'Created'
            if (type === 'user' && item.created_by) return item.created_by.name
        },

        local_has_title_error (item) {
            if (!item || (item && !item.trim())) return false
            if (item && item.length > this.titleLength) return false
            return true
        },

        local_get_date (date) {
            return diffHuman(date)
        },

        local_utc (date) {
            return moment.utc(date ?? null).local()
        },

        localRefreshDesc () {
            this.refreshDesc = true
            setTimeout(() => {
                this.refreshDesc = false
            }, 100);
        }
    }
}
</script>

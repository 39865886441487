<template>
    <div class="c-summary-tile pt-5 u-relative u-hfull">
        <loader-latest-status-summary v-if="mixLoading" />

        <template v-else>
            <template v-if="localIsEmpty || !mixHasPermission('status_updates.index')">
                <partial-empty-state-summary
                    :canView="mixHasPermission('status_updates.index')"
                    title="Status"
                    icon="donut_large"
                    modelSlug="status updates"
                    class="pb-4"
                    iconSize="26"
                    iconOutlined
                />
            </template>

            <template v-else>
                <template v-if="mixParentState.project && (mixParentState.project.status !== 'queue' && mixParentState.project.status !== 'completed')">
                    <div class="u-absolute u-flex-center-y" style="column-gap: 4px; top: 0px; right: 0px;">
                        <svg v-if="statusItem.health === 'green'" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M0 0H13C16.866 0 20 3.13401 20 7V20C8.95431 20 0 11.0457 0 0Z" fill="#C8E6C9"/>
                            <path d="M4 0H13C16.866 0 20 3.13401 20 7V16C11.1634 16 4 8.83656 4 0Z" fill="#A5D6A7"/>
                            <path d="M8 0H13C16.866 0 20 3.13401 20 7V12C13.3726 12 8 6.62742 8 0Z" fill="#388E3C"/>
                        </svg>
                        <svg v-if="statusItem.health === 'red'" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M0 0H13C16.866 0 20 3.13401 20 7V20C8.95431 20 0 11.0457 0 0Z" fill="#FFCDD2"/>
                            <path d="M4 0H13C16.866 0 20 3.13401 20 7V16C11.1634 16 4 8.83656 4 0Z" fill="#EF9A9A"/>
                            <path d="M8 0H13C16.866 0 20 3.13401 20 7V12C13.3726 12 8 6.62742 8 0Z" fill="#D32F2F"/>
                        </svg>
                        <svg v-if="statusItem.health === 'yellow'" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M0 0H13C16.866 0 20 3.13401 20 7V20C8.95431 20 0 11.0457 0 0Z" fill="#FFECB3"/>
                            <path d="M4 0H13C16.866 0 20 3.13401 20 7V16C11.1634 16 4 8.83656 4 0Z" fill="#FFE082"/>
                            <path d="M8 0H13C16.866 0 20 3.13401 20 7V12C13.3726 12 8 6.62742 8 0Z" fill="#FFA000"/>
                        </svg>
                    </div>
                </template>

                <div class="u-overflow-hidden u-hfull" ref="refContentParent">
                    <div class="u-flex-center-y px-5">
                        <div class="u-flex-center-y">
                            <g-avatar v-if="statusItem.updated_by" :item="statusItem.updated_by" :size="28" no-shadow :rightSpace="false"></g-avatar>
                            <div v-else class="u-border u-rounded-corners-full u-flex-center" style="width: 28px; height: 28px;">
                                <a-icon size="14" color="grey darken-1">person</a-icon>
                            </div>

                            <span
                                :title="statusItem && statusItem.updated_by ? statusItem.updated_by.name : ''"
                                class="md-body-2 grey--text text--darken-2 ml-2 u-font-weight-semibold"
                            >
                                {{ statusItem && statusItem.updated_by ? statusItem.updated_by.name : '' | truncateText(13) }}
                            </span>
                        </div>

                        <div class="u-flex-center-y">
                            <a-icon size="5" color="grey lighten-1" class="mx-2">fiber_manual_record</a-icon>
                            <g-smart-time
                                :timestamp="statusItem.updated_at"
                                contentClass="md-caption grey--text text--darken-1 font-weight-medium"
                            />
                        </div>
                    </div>

                    <div class="px-5 mt-2">
                        <div class="u-flex-center-y">
                            <div
                                v-if="statusItem.type"
                                :class="[localGetTypeColor(statusItem.health) + '--text text--darken-2']"
                                class="md-body-2 font-weight-medium text-truncate"
                                :style="[
                                    { maxWidth: statusItem.visibility === 'external' ? '210px' : '100%' }
                                ]"
                            >
                                {{ statusItem.type.value }}
                            </div>
                            <a-spacer></a-spacer>
                            <a-tooltip bottom v-if="statusItem.visibility === 'external'">
                                <template v-slot:activator="{ on }">
                                    <a-icon v-on="on" size="16" color="orange darken-2">public</a-icon>
                                </template>
                                <span>Visible to external</span>
                            </a-tooltip>
                        </div>

                        <div ref="refContent" class="mb-0 md-body-2 grey--text text--darken-2 mt-1 u-pre-wrap" style="line-height: 24px;">{{ statusItem.description }}</div>
                    </div>
                </div>

                <partial-bottom-gradient v-if="showExpand" />
            </template>
        </template>

        <partial-project-summary-actions
            v-if="mixHasPermission('status_updates.index') && !mixLoading"
            :showExpand="noFetch || localIsEmpty ? false : showExpand"
            @expand="$emit('expand')"
            @open="mixOpenInNewWindow('projects-status')"
            :showActions="noFetch"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { diffHuman, diffYear } from '@/helpers/helper-date-filter'
import MixinModalStateInjection from '../Mixins/MixinModalStateInjection'
import PartialProjectSummaryActions from './PartialProjectSummaryActions.vue'
import PartialBottomGradient from './PartialBottomGradient'
import PartialEmptyStateSummary from './PartialEmptyStateSummary.vue'
import LoaderLatestStatusSummary from '../Loaders/LoaderLatestStatusSummary.vue'

export default {
	components: {
        PartialProjectSummaryActions,
        PartialBottomGradient,
        PartialEmptyStateSummary,
        LoaderLatestStatusSummary
    },

    mixins: [MixinModalStateInjection],

    props: {
        noFetch: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            showExpand: false,
            statusItem: {}
        }
    },

    computed: {
        localIsEmpty () {
            return !_.size(Object.keys(this.statusItem))
        },

        localDateDiff () {
            return diffYear(this.statusItem.updated_at) > 0
        },

        ...mapState('StatusUpdate', {
            status_update_list: 'list'
        }),
    },

    methods: {
        async localIndex () {
            if (!this.mixHasPermission('status_updates.index')) return
            if (this.noFetch || this.mixLatestSyncTimeDiff() >= 5) return this.localNoFetchSetup()

            this.mixSetLoading()
            await this.localFetchStatusUpdate()
            this.statusItem = (this.status_update_list && this.status_update_list[0]) || {}

            this.mixSaveProject({ statusUpdate: this.statusItem })
            this.mixClearLoading()
            this.localSetupAction()
        },

        localNoFetchSetup () {
            this.statusItem = this.mixGetSavedSummary.statusUpdate || {}
            this.localSetupAction()
        },

        async localFetchStatusUpdate () {
            await this.status_update_index({
                include: 'type,updated_by',
                'fields[type]': 'id,value',
                'filter[project_id]': this.mixParentState.projectId,
                'fields[status_updates]': 'id,description,health,updated_at,updated_by_id,type_id,visibility',
                'fields[updated_by]': 'id,name,avatar,email,scope,is_active,initial,color',
                sort: '-created_at',
                count: 1,
            })
        },

        localSetupAction () {
            if (this.localIsEmpty || this.mixLoading) return

            this.$nextTick(() => {
                const parentHeight = this.$refs.refContentParent.parentElement.clientHeight
                const contentHeight = this.$refs.refContent.clientHeight + 77
                this.showExpand = !!(contentHeight > parentHeight)
            })
        },

        localGetTypeColor (health) {
            switch (health) {
                case 'green': return 'green'
                case 'red': return 'red'
                case 'yellow': return 'orange'
                default: return 'grey'
            }
        },

        localUpdatedAt (args) {
            const updatedAt = this.statusItem.updated_at
            const { type = null, format = 'MMM D [at] h:mm A', yearFormat = 'MMM D, YYYY [at] h:mm A' } = args || {}
            if (!type) return diffHuman(updatedAt, { format, withYearFormat: yearFormat })

            const diffInDays = moment().diff(moment(updatedAt), 'days')
            const diffInMonths = moment().diff(moment(updatedAt), 'months')
            const diffInYears = moment().diff(moment(updatedAt), 'years')

            if (diffInYears >= 1) return diffInYears + (diffInYears === 1 ? ' year' : ' years')
            if (diffInDays > 90) return diffInMonths + ' months'
            if (diffInDays >= 1 && diffInDays <= 90) return diffInDays + (diffInDays > 1 ? ' days' : ' day')

            const momentObj = _.cloneDeep(moment)
            momentObj.updateLocale('en', {
                relativeTime: {
                    past: '%s',
                    s : '%d secs',
                    ss: '%d secs',
                    m:  "a min",
                    mm: "%d mins",
                    h:  "1 hr",
                    hh: "%d hrs",
                }
            })

            return momentObj.utc(updatedAt).local().fromNow()
        },

        ...mapActions('StatusUpdate', {
            status_update_index: 'index'
        }),
    }
}
</script>

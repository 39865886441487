<template>
    <a-container container--fluid grid-list-xl>
        <a-layout wrap align-start>
            <a-flex xs4 v-for="project in project_list" :key="project.id">
                <router-link tag="div" v-ripple class="c-project-card my-0 pa-0 u-cursor-pointer u-shadow" :to="{name: 'projects-overview', params: {id: project.id}}">
                    <a-container container--fluid grid-list-xl>
                        <a-layout align-start wrap>
                            <a-flex shrink xs12 px-6 pb-0 v-if="user_self.organization && user_self.organization.logo">
                                <img :src="user_self.organization.logo" :alt="user_self.organization.name" :title="user_self.organization.name" style="max-width: 120px; max-height: 50px">
                            </a-flex>
                            <a-flex xs12 px-6 :class="[user_self.organization && user_self.organization.logo ? 'pt-1' : 'pt-6']">
                                <a-chip small label class="mx-0 mb-1" v-if="user_self.organization && !user_self.organization.logo">{{ user_self.organization.name }}</a-chip>
                                <h2 class="md-heading-6 mb-1">{{ project.title }}</h2>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-container container--fluid grid-list-xl class="py-0 px-6" v-if="project.last_status_update && project.last_status_update.created_by">
                        <a-layout align-center>
                            <a-flex>
                                <p class="md-body-2 mb-2 grey--text text--darken-2">Update by {{ project.last_status_update.created_by.name }} on <g-moment :value="project.last_status_update.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="ddd, MMM D, YYYY"></g-moment></p>
                                <p class="md-body-1 grey--text text--darken-3" v-html="local_truncate(project.last_status_update.description, {length: 200})"></p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <!-- <template v-if="$can('usecases.index')">
                        <a-container container--fluid grid-list-xl class="py-0 px-6">
                            <a-layout align-center>
                                <a-flex>
                                    <p class="md-caption mb-2" v-if="project.usecases_count > 0">{{ project.usecases_closed_count }} closed out of {{ project.usecases_count }} use cases</p>
                                    <p class="md-caption mb-2" v-else>No Use Cases</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-progress-linear :value="(project.usecases_count == 0) ? 0 : (project.usecases_closed_count / project.usecases_count) * 100" height="2" class="my-0" color="green darken-2"></a-progress-linear>
                    </template> -->
                    <v-divider></v-divider>
                    <template v-if="local_has_any_permission">
                        <a-container container--fluid grid-list-xl pa-6 class="grey lighten-4">
                            <a-layout align-center>
                                <a-flex>
                                    <p class="md-caption mb-0">
                                        <span class="mr-4" v-if="$can('usecases.index')"><a-icon size="16" class="u-icon-nudge mr-1">assignment</a-icon> {{ project.usecases_count }} Scope</span>
                                        <span class="mr-4" v-if="$can('tasks.index')"><a-icon size="16" class="u-icon-nudge mr-1">check_circle</a-icon> {{ project.tasks_count - project.tasks_pending_count }}/{{ project.tasks_count }} Tasks</span>
                                        <span class="mr-4" v-if="$can('links.index')"><a-icon size="16" class="u-icon-nudge mr-1">link</a-icon> {{ project.links_count }} Links</span>
                                        <span class="mr-4" v-if="$can('documents.index')"><a-icon size="16" class="u-icon-nudge mr-1">insert_drive_file</a-icon> {{ project.documents_count }} Documents</span>
                                        <span class="mr-4" v-if="$can('notes.index')"><a-icon size="16" class="u-icon-nudge mr-1">notes</a-icon> {{ project.notes_count }} Notes</span>
                                    </p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </template>
                </router-link>
            </a-flex>
        </a-layout>
    </a-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    data () {
        return {
            project_filters: {},
            filter: {
                INCLUDE: 'include',
            }
        }
    },
    computed: {
        local_has_any_permission() {
            return this.$can('usecases.index') || this.$can('tasks.index') || this.$can('links.index') || this.$can('documents.index') || this.$can('notes.index')
        },
        ...mapState('ProjectExternal', {
            project_list: 'list',
            project_response: 'response',
            project_meta: 'meta',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
    mounted() {
        this.local_index()
    },
    methods: {
        async local_index() {
            await this.project_clear()
            // this.project_filters.page = 1
            // this.project_filters.count = 999
            // this.project_filters.fields = ['title', 'deal_amount', 'start_date'];
            // this.project_filters.attributes = ['none'];
            // this.project_filters[this.filter.INCLUDE] = ['stage', 'customer', 'partner', 'usecases', 'usecases_closed', 'tasks', 'tasks_completed', 'usecasesOpenCount', 'usecasesClosedCount', 'usecasesCount', 'tasksPendingCount', 'tasksCompletedCount', 'tasksCount'].join();
            this.project_filters[this.filter.INCLUDE] = ['usecasesClosed', 'usecasesOpenCount', 'usecasesClosedCount', 'usecasesCount', 'tasksPendingCount', 'tasksCount', 'linksCount', 'documentsCount', 'notesCount','lastStatusUpdate.createdBy'].join();
            // usecasesOpen, usecasesOpenCount, usecasesClosed, usecasesClosedCount, usecases, usecasesCount, tasks, tasksCount, tasksPending, tasksPendingCount, documents, documentsCount, notes, notesCount, links, linksCount
            await this.project_index({ ...this.project_filters })
        },
        local_truncate(text, options) {
            return _.truncate(text, options)
        },
        ...mapActions('ProjectExternal', {
            project_index: 'index',
            project_clear: 'clear',
        }),
    },
}
</script>

<template>
    <div v-bind="$attrs" class="u-flex-center-y c-sticky-paginate-parent u-rounded-corners-lg u-wfull pl-4" style="border: 1px solid #e9e9e9 !important">
        <div class="u-flex align-center py-0">
            <div class="md-body-2 grey--text text--darken-2 font-weight-medium mr-2 d-inline-block">{{ pagination.from ? pagination.from : 1 }} - {{ pagination.to }} of {{ pagination.total }}</div>
        </div>
        <a-spacer></a-spacer>
        <div class="mr-16 pr-16 py-0 u-flex align-center justify-end" v-if="!hide">
            <a-progress-circular v-if="loading" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
            <a-sheet class="u-flex-center-y" height="51" v-if="numbersLoading">
                <loader-template width="36" height="36" class="mr-2"></loader-template>
                <loader-template width="36" height="36" class="mr-2"></loader-template>
                <loader-template width="36" height="36"></loader-template>
            </a-sheet>
            <div class="text-right" v-else>
                <a-pagination
                    v-model="pagination.current_page"
                    :length="pagination.last_page"
                    :total-visible="10"
                    class="c-inventory-pagination py-1"
                    @input="$emit('paginate', pagination)"
                ></a-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pagination: {
            type: Object
        },
        hide: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        numbersLoading: {
            type: Boolean,
            default: false
        },
    }
}
</script>

export default {
    data () {
        return {
            loadingTypes: []
        }
    },

    methods: {
        mixinSetLoading (type) {
            const index = this.loadingTypes.indexOf(type)
            if (index === -1) this.loadingTypes.push(type)
        },

        mixinResetLoading (type) {
            const index = this.loadingTypes.indexOf(type)
            if (index !== -1) this.loadingTypes.splice(index, 1)
        },

        mixinIsLoading (type) {
            return this.loadingTypes.includes(type)
        }
    }
}

<template>
    <div v-bind="$attrs">
        <h4 class="md-body-2 font-weight-medium grey--text text--darken-1 mb-1">{{ compType.value }}</h4>
        <div class="u-flex-center-y flex-wrap">
            <template v-if="isLoading('project-competitors')">
                <loader-template height="14" class="u-rounded-corners-full"></loader-template>
            </template>
            <template v-else>
                <template v-if="localGetCompetitorOfType(compType.id, 'count')">
                    <SCompetitorTag @click.stop="$emit('edit', tag)" v-for="tag in localGetCompetitorOfType(compType.id, 'list')" :hideClearIcon="!canRemove" @destroy="(tag, type) => canRemove ? $emit('removeCompetitor', tag, type) : ''" :tag="tag" :key="tag.id"/>
                </template>
                <template v-else>
                    <span v-if="!$can('projects.update-only')" class="font-italic md-body-2 grey--text text--lighten-1">No Competitors</span>
                </template>
                <!--<a-menu v-model="competitorModel[compType.id]" bottom offset-y v-if="hasPermission('projects.update-only') && (!moduleStageChange || (moduleStageChange && !competitorsCount))" :close-on-content-click="false" transition="slide-y-transition">-->
                <a-menu v-model="competitorModel[compType.id]" bottom offset-y v-if="hasPermission('projects.update-only')" :close-on-content-click="false" transition="slide-y-transition">
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <a-sheet v-ripple="true" v-if="localGetCompetitorOfType(compType.id, 'count') && hasPermission('projects.update-only')" width="20" height="20" class="blue darken-3 u-cursor-pointer u-flex-center u-rounded-corners-full">
                                <a-icon size="16" color="white">add</a-icon>
                            </a-sheet>
                            <a-sheet v-else height="32" class="u-flex-center-y u-cursor-pointer">
                                <a-icon size="18" color="grey darken-1">add_circle</a-icon>
                                <span class="md-body-2 body--text text--lighten-2 ml-1 font-weight-medium">Add Competitors</span>
                            </a-sheet>
                        </div>
                    </template>

                    <slot name="dropdown" :modelOpen="competitorModel[compType.id]"></slot>
                </a-menu>
            </template>
        </div>
    </div>
</template>

<script>
import { SCompetitorTag } from '@/config/config-shared-components'

export default {
    props: {
        project: {
            type: Object
        },
        competitorModel: {
            type: Object
        },
        moduleStageChange: {
            type: Boolean,
            default: false
        },
        canRemove: {
            type: Boolean,
        },
        competitorsCount: {
            type: Number,
        },
        compType: {
            type: Object
        },
        isLoading: {
            type: Function | Object
        },
        hasPermission: {
            type: Function | Object
        },
    },

    components: {
        SCompetitorTag
    },

    mounted () {
        this.localIndex()
    },

     methods: {
         async localIndex () {},

         localGetCompetitorOfType (typeId, field) {
            const list = _.size(this.project.competitors) > 0 ? this.project.competitors.filter(i => i.category_id === typeId) : []
            const result = { list, count: list.length }
            return result[field]
        },
    }
}
</script>

<style lang="scss" scoped></style>

<template>
    <div class="u-flex-center-y">
        <a-icon
            size="16"
            :color="menu.isActive ? 'indigo darken-3' : 'grey'"
            :outlined="menu.outlined"
        >
            {{ menu.icon }}
        </a-icon>
        <span
            class="ml-2 u-font-14 font-weight-medium u-tracking u-relative"
            :class="[menu.isActive ? 'indigo--text text--darken-3' : 'grey--text text--darken-3']"
        >
            {{ menu.label }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        menu: {
            type: Object,
        }
    }
}
</script>

<template>
    <div class="project-child u-cursor-pointer u-relative px-3" style="z-index: 1">
        <div class="md-caption mb-0 text-truncate">
            <span class="md-subtitle-2 grey--text text--darken-3 d-inline-block font-weight-medium">{{ project.title | truncateText(60) }}</span>
            <div class="u-flex align-center">
                <a-icon v-if="project.status !== 'active'" color="grey darken-1" size="12" style="position: relative;">fiber_manual_record</a-icon>
                <a-icon v-if="project.status === 'active' && project.health_color === 'green'" color="green" size="12" style="position: relative;">fiber_manual_record</a-icon>
                <a-icon v-if="project.status === 'active' && project.health_color === 'yellow'" color="orange lighten-2" size="12" style="position: relative;">fiber_manual_record</a-icon>
                <a-icon v-if="project.status === 'active' && project.health_color === 'red'" color="red" size="12" style="position: relative;">fiber_manual_record</a-icon>
                <span class="md-body-2 d-block grey--text text--darken-1 ml-1">{{ project.customer_name | truncateText(16) }}</span>
                <a-icon size="6" color="grey darken-1" class="mx-1">fiber_manual_record</a-icon>
                <span class="md-body-2 d-block grey--text text--darken-1 ml-1">{{ project.status !== 'archived' ? project.stage_name : 'Archived' | truncateText(16) }}</span>
                <a-icon size="6" color="grey darken-1" class="mx-1">fiber_manual_record</a-icon>
                <div class="u-flex align-center">
                    <span class="md-body-2 d-block grey--text text--darken-1" v-if="project.start_date">{{ local_format_date(project.start_date, 'MMM D') }}</span>
                    <span class="md-body-2 d-block grey--text text--darken-1" v-else>No Start Date</span>
                    <span class="md-body-2 d-block grey--text text--darken-1 mx-1"> - </span>
                    <span class="md-body-2 d-block grey--text text--darken-1" v-if="project.due_date">{{ local_format_date(project.due_date, 'MMM D') }}</span>
                    <span class="md-body-2 d-block grey--text text--darken-1" v-else>No End Date</span>
                </div>
                <div class="ml-2" v-if="project.salesforce_bridge">
                    <span class="u-flex align-center">
                        <img src="../../../assets/images/icon-salesforce.svg" height="12" alt="Salesforce" class="d-inline-block">
                    </span>
                </div>
                <div class="ml-3" v-if="project.hubspot_bridge">
                    <img src="../../../assets/images/apps/hubspot/logo.svg" height="20" alt="Hubspot" class="d-inline-block">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        project: {
            type: Object,
            required: true
        },
    },

    methods: {
        local_format_date (date, format) {
            return moment(date).format(format)
        },
    }
}
</script>

<style>

</style>

export default {
    namespaced: true,
    state: {
        loader: false,
    },
    mutations: {
        LOADER_UPDATE(state, status) {
            state.loader = status;
        },
    },
    actions: {
        loader_start(context) {
            return new Promise((resolve, reject) => {
                context.commit('LOADER_UPDATE', true);
                resolve('Cleared');
            });
        },
        loader_stop(context) {
            return new Promise((resolve, reject) => {
                context.commit('LOADER_UPDATE', false);
                resolve('Cleared');
            });
        },
    },
}

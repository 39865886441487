<template>
    <div class="u-flex" style="column-gap: 16px;">
        <div class="u-flex u-rounded-corners u-border" style="height: 36px;">
            <a-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <div v-on="on" class="pa-2 px-2 u-flex-center-y">
                        <a-icon size="20" class="ma-0 mr-1 u-icon-nudge-xs" color="grey">web_stories</a-icon>
                        <span class="md-body-2">{{ insight_meta && insight_meta.queue_count ? insight_meta.queue_count : 0 }}</span>
                    </div>
                </template>
                <span>Queue</span>
            </a-tooltip>
            <a-divider vertical></a-divider>
            <a-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <div v-on="on" class="pa-2 px-2 u-flex-center-y">
                        <a-icon size="20" class="ma-0 mr-1 u-icon-nudge-xs" color="grey">bolt</a-icon>
                        <span class="md-body-2">{{ insight_meta && insight_meta.projects_count ? insight_meta.projects_count : 0 }}</span>
                    </div>
                </template>
                <span>Active</span>
            </a-tooltip>
            <a-divider vertical></a-divider>
            <a-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <div v-on="on" class="pa-2 px-2 u-flex-center-y">
                        <a-icon size="20" class="ma-0 mr-1 u-icon-nudge-xs" color="grey">monitor_heart</a-icon>
                        <span class="md-body-2">{{ insight_meta && insight_meta.lifetime_count ? insight_meta.lifetime_count : 0 }}d</span>
                    </div>
                </template>
                <span>Lifetime</span>
            </a-tooltip>
        </div>
        <div class="u-flex u-rounded-corners u-border" style="height: 36px;">
            <div class="pa-2 px-2 u-flex-center-y" style="column-gap: 4px;">
                <a-avatar size="16" color="green lighten-4">
                    <a-avatar size="13" color="green lighten-3">
                        <a-icon size="10" color="green darken-2">fiber_manual_record</a-icon>
                    </a-avatar>
                </a-avatar>
                <span class="md-body-2">{{ local_get_color_count('green') }}</span>
            </div>
            <a-divider vertical></a-divider>
            <div class="pa-2 px-2 u-flex-center-y" style="column-gap: 4px;">
                <a-avatar size="16" color="amber lighten-4">
                    <a-avatar size="13" color="amber lighten-3">
                        <a-icon size="10" color="amber darken-2">fiber_manual_record</a-icon>
                    </a-avatar>
                </a-avatar>
                <span class="md-body-2">{{ local_get_color_count('yellow') }}</span>
            </div>
            <a-divider vertical></a-divider>
            <div class="pa-2 px-2 u-flex-center-y" style="column-gap: 4px;">
                <a-avatar size="16" color="red lighten-4">
                    <a-avatar size="13" color="red lighten-3">
                        <a-icon size="10" color="red darken-2">fiber_manual_record</a-icon>
                    </a-avatar>
                </a-avatar>
                <span class="md-body-2">{{ local_get_color_count('red') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import loadWorkspace from '@/mixins/mixin-workspace'

export default {
    mixins: [loadWorkspace],
    computed: {
        local_queue_stage_count () {
            if (this.insight_meta !== {} && this.insight_meta.stages) {
                return this.insight_meta.stages.find(item => item.status === 'queue')
            }
        },
        ...mapState('Insight', {
            insight_meta: 'insights',
            insights_mini_stat: 'mini_stats',
        }),
        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),
    },
    mounted() {
        this.local_index()
    },
    watch: {
        workspace_current(oldValue, newValue) {
            this.local_index()
        }
    },
    methods: {
        async local_index() {},
        local_get_color_count(color) {
            const health = this.insights_mini_stat.find(item => item.health_color === color)
            return health ? health.count : 0
        },
        ...mapActions('Insight', {
            insight_mini: 'dashboard',
            insights_stages: 'stages',
            insights_projects: 'projects',
            insights_lifetime: 'lifetime',
            insights_stage_poc_count: 'stage_poc_count',
            insights_clear: 'clear',
        }),
    }
}
</script>

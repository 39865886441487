export default {
    methods: {
        local_get_validation_error (slug, field = 'message') {
            if (!slug) return null
            const item = this.error_types.find(item => item.slug === slug)
            if (!slug.includes('filters')) return (item && item[field]) ?? null

            return (item && item[field] && item[field][field]) ?? null
        },
    }
}

<template>
    <a-main>
        <a-container container--fluid grid-list-xl class="pa-0 mx-0">
            <g-page-header
                title="Resource Planning"
                subtitle="Review all projects under each user."
                icon="perm_contact_calendar"
            />
        </a-container>

        <a-container container--fluid grid-list-xl class="pa-0 mx-0 mb-8 mt-4">
            <a-layout wrap>
                <a-flex shrink pb-0 pr-1>
                    <a-card @click="local_show_filters_pane()" class="md-body-2 u-border u-rounded-corners xs-mt-1 xl-mt-0 d-flex align-center pr-3 pl-2 u-cursor-pointer" flat min-height="32">
                        <a-icon size="18" :class="[local_check_no_filters ? 'grey--text text--lighten-1' : 'blue--text']" class="mr-1">filter_alt</a-icon>
                        <span class="md-body-2 font-weight-medium grey--text text--darken-2">Filters</span>
                    </a-card>
                </a-flex>
                <a-flex shrink style="width: 240px">
                    <a-menu bottom offset-y >
                        <template v-slot:activator="{ on }">
                            <a-card class="u-rounded-corners u-overflow-hidden u-border d-flex" flat min-height="32" v-on="on">
                                <a-card-text class="px-2 py-0 d-flex align-center justify-space-between">
                                    <div class="d-flex align-center">
                                        <a-icon size="20" class="ma-0" :color="local_team_filter.value !== 'all' ? 'blue' : 'grey lighten-1'">filter_list</a-icon>
                                        <span class="md-body-2 mx-1" v-if="!page_loading">
                                            <span v-if="user_self && user_self.is_manager">
                                                Team:
                                            </span>
                                            {{ local_team_filter.label }}
                                        </span>
                                        <span v-else>
                                            <loader-template height="24" width="140px" class="u-rounded-corners ml-2"></loader-template>
                                        </span>
                                    </div>
                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <a-list class="u-list-condensed">
                            <a-list-item v-for="item in local_team_filter_list" :key="item.value" @click="local_team_filter_set(item.label, item.value)">
                                <a-list-item-title>
                                    <span class="md-body-2">{{ item.label }}</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink pb-0 pr-1>
                    <a-menu bottom offset-y v-model="timeline_dropdown">
                        <template v-slot:activator="{ on }">
                            <a-sheet class="u-rounded-corners u-cursor-pointer u-overflow-hidden u-border py-1 px-2 u-flex align-center" flat height="32" width="180" v-on="on">
                                <span class="md-body-2 grey--text text--darken-2 mx-1">
                                    <span class="font-weight-medium mr-1">Timeline:</span>
                                    <span>{{ default_timeline.label }}</span>
                                </span>
                                <a-spacer></a-spacer>
                                <a-icon size="20" class="ma-0">arrow_drop_{{ timeline_dropdown ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed">
                            <template v-for="timeline in timeline_list">
                                <a-list-item :class="{ 'grey lighten-4': timeline.key === default_timeline.key }" :key="'parent_type_key_' + timeline.key" @click="local_change_timeline(timeline)">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-2">{{ timeline.label }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </template>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-flex shrink pb-0 pr-1>
                    <a-menu bottom offset-y v-model="view_dropdown">
                        <template v-slot:activator="{ on }">
                            <a-sheet class="u-rounded-corners u-cursor-pointer u-overflow-hidden u-border py-1 px-2 u-flex align-center" flat height="32" width="150" v-on="on">
                                <span class="md-body-2 grey--text text--darken-2 mx-1">
                                    <span class="font-weight-medium mr-1">View:</span>
                                    <span>{{ default_view.label }}</span>
                                </span>
                                <a-spacer></a-spacer>
                                <a-icon size="20" class="ma-0">arrow_drop_{{ view_dropdown ? 'up' : 'down' }}</a-icon>
                            </a-sheet>
                        </template>
                        <a-list class="u-list-condensed">
                            <template v-for="view in view_types">
                                <a-list-item :class="{ 'grey lighten-4': view.key === default_view.key }" :key="'parent_type_key_' + view.key" @click="local_change_view(view)">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3">{{ view.label }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </template>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-flex shrink pb-0 pr-1>
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-card v-on="on" class="u-rounded-corners u-overflow-hidden u-border u-cursor-pointer" flat @click="local_get_previous_four(default_timeline)">
                                <a-card-text class="px-2 py-1">
                                    <a-icon size="20" class="ma-0">keyboard_arrow_left</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <span>Scroll Left</span>
                    </a-tooltip>
                </a-flex>
                <a-flex shrink pb-0>
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-card v-on="on" class="u-rounded-corners u-overflow-hidden u-border u-cursor-pointer" flat @click="local_get_next_four(default_timeline)">
                                <a-card-text class="px-2 py-1">
                                    <a-icon size="20" class="ma-0">keyboard_arrow_right</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <span>Scroll Right</span>
                    </a-tooltip>
                </a-flex>
            </a-layout>
        </a-container>

        <div class="u-wfull">
            <div class="u-flex u-sticky u-wfull">
                <a-sheet style="border-right: 1px solid #eee; z-index: 1; cursor: auto;" min-width="264" max-width="264">
                    <div style="border-bottom: 1px solid #eee;" class="u-flex py-3 align-center u-wfull">
                        <h2 class="md-subtitle-2 grey--text text--darken-3 text-uppercase u-wfull">Team Members</h2>
                    </div>
                </a-sheet>
                <a-sheet class="transparent u-overflow-hidden ml-auto" min-width="calc(100% - 264px)">
                    <div class="c-timeline ml-auto u-flex align-center u-wfull" style="overflow-x: hidden;">
                        <div class="c-timeline-headers u-wfull" :style="`width: ${total_timeline_width}px; position: sticky; top: 0; z-index: 2;`">
                            <div class="d-flex c-dates-wrap u-wfull">
                                <div v-for="index in timeline_months_count" :key="'month_'+index" class="c-dates-wrap__dates pl-2" :class="[local_timeline_class(timeline_start_date, index, local_selected_timeline === 'week' ? 'MMM D YYYY' : 'MMM YYYY')]" :style="`flex-grow: 0; flex-shrink: 0; min-width: ${local_timeline_width(index)}px; max-width: ${local_timeline_width(index)}px; text-align: left; border-bottom: 1px solid #eee; border-right: 1px solid #eee; padding-top: 12px; padding-bottom: 12px;`">
                                    <h2 class="md-subtitle-2 grey--text text--darken-2 u-opacity-70">{{ local_get_date(index).format(default_timeline.key === '4-week' ? 'D MMMM' : 'MMMM YYYY') }}</h2>
                                </div>
                            </div>
                            <!-- <div class="d-flex" v-if="local_interface_day_width > 8">
                                <div v-for="index in timeline_days_count" :key="'day_'+index" :style="`flex-grow: 0; flex-shrink: 0; min-width: ${local_interface_day_width}px; max-width: ${local_interface_day_width}px; text-align: center; border-bottom: 1px solid #eee; border-right: 1px solid #eee; padding-top: 8px; padding-bottom: 8px;`">
                                    <span class="md-caption">{{ local_moment_add(timeline_start_date, index-1, 'days').format('D') }}</span>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </a-sheet>
            </div>
        </div>

        <div class="scroll-transparent u-wfull u-overflow-y" style="height: calc(100vh - 348px); border-bottom: 1px solid #eee">
            <div class="u-flex">
                <a-sheet v-if="!page_loading" style="border-right: 1px solid #eee;" :style="[rp_list && rp_list.length ? { borderBottom: '1px solid #eee !important' } : '']" height="100%" min-width="264" max-width="264">
                    <div v-for="(user, user_index) in rp_list" :class="`white u-sticky`" style="top: 0px;" :style="[user_index !== 0 ? { borderTop: '1px solid #eee !important' } : '']" :key="'user_'+user.id">
                        <p-user-item @toggle-userload="local_toggle_userload" :user="user" :loaded-ids="load_all" :height="local_user_height(user)"></p-user-item>
                    </div>
                </a-sheet>

                <!-- Timeline -->
                <a-sheet class="c-timeline-wrapper u-relative transparent u-overflow-hidden ml-auto">
                    <!-- Timeline Loader -->
                    <v-fade-transition leave-absolute>
                        <div v-if="timeline_loading" class="u-wfull u-hfull u-absolute" style="right: 0px; top: 0px; background-color: rgba(255,255,255,0.3); backdrop-filter: blur(12px); z-index: 2;">
                            <div class="c-firefox-blur u-wfull u-hfull u-absolute" style="right: 0px; top: 0px;"></div>
                            <div class="u-wfull u-hfull u-absolute text-center transparent">
                                <a-img :src="require('../../assets/images/loader.svg')" alt="Loading Timeline..." width="48" height="48" contain class="d-inline-block mt-16"></a-img>
                            </div>
                        </div>
                    </v-fade-transition>

                    <!-- Projects List -->
                    <a-sheet class="transparent c-timeline-view">
                        <div v-for="(user) in rp_list" :key="user.id" class="u-relative u-wfull py-4 u-overflow-hidden" :style="`width: ${total_timeline_width}px; border-bottom: 1px solid #eee; min-height: ${ local_calc_detail_height(user) }px;`">
                            <div :class="[`d-flex align-center u-relative ${local_is_compact_view ? 'mb-2' : (proj_index !== 0 ? 'mt-4' : '')}`]" :style="`width: ${timeline_days_count * local_interface_day_width}px; position: relative; height: ${local_is_compact_view ? compact_height : detail_height}px; overflow-x: hidden; z-index: 1`" v-for="({ project }, proj_index) in (local_is_user_loaded(user.id) ? user.collaborators : user.collaborators.slice(0, 5))" :key="project.id">
                                <v-scroll-x-transition leave-absolute>
                                    <a-menu
                                        v-model="showProjectDetail"
                                        :close-on-content-click="false"
                                        :position-x="projectDetailXPos - 24"
                                        :position-y="projectDetailYPos"
                                        transition="slide-y-transition"
                                        max-width="100%"
                                        content-class="u-rounded-corners-lg u-shadow-md"
                                        class="d-block"
                                        offset-y absolute
                                        v-if="!local_is_compact_view"
                                        :disabled="hovered_project && hovered_project.id !== project.id"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" @mouseover.prevent="evt => local_hovered_project(evt, project)" :class="`c-timeline-bar u-flex align-center white u-rounded-corners-lg u-cursor-pointer ma-0 px-0 py-0 u-hfull project-${project.id}`" style="border: 1px solid #eee !important; z-index: 8; height: 64px;" :style="`min-width: ${local_project_duration(project) * local_interface_day_width}px; position: relative; left: ${local_project_start_offset(project, proj_index) * local_interface_day_width}px;`">
                                                <p-detailed-item :project="project"></p-detailed-item>
                                            </div>
                                        </template>
                                        <s-project-info :project="hovered_project" :loading="project_info_loading"></s-project-info>
                                    </a-menu>
                                </v-scroll-x-transition>
                                <v-scroll-x-transition leave-absolute>
                                    <a-menu
                                        v-model="showProjectDetail"
                                        :close-on-content-click="false"
                                        :position-x="projectDetailXPos - 12"
                                        :position-y="projectDetailYPos"
                                        transition="slide-y-transition"
                                        max-width="100%"
                                        content-class="u-rounded-corners-lg u-shadow-md"
                                        class="d-flex align-center"
                                        v-if="local_is_compact_view"
                                        :disabled="hovered_project && hovered_project.id !== project.id"
                                        offset-y absolute
                                    >
                                        <template v-slot:activator="{ on }">
                                            <a-card
                                                :color="(hovered_project_id === project.id) ? '#3D5AFE' : (hovered_project_id ? '#adb9ff' : '#3D5AFE')"
                                                :min-width="local_project_duration(project) * local_interface_day_width"
                                                class="c-compact-bar u-rounded-corners-full u-cursor-pointer u-flex align-center u-overflow-hidden u-rounded-corners-full"
                                                :style="`position: relative; z-index: 4; left: ${local_project_start_offset(project,proj_index) * local_interface_day_width}px;`"
                                                height="10"
                                                v-on="on"
                                                @mouseover.prevent="evt => local_hovered_project(evt, project)"
                                                flat
                                            ></a-card>
                                        </template>
                                        <s-project-info :project="hovered_project" :loading="project_info_loading"></s-project-info>
                                    </a-menu>
                                </v-scroll-x-transition>
                            </div>
                        </div>
                    </a-sheet>
                </a-sheet>
            </div>
        </div>

        <div class="u-flex align-center pt-7 u-rounded-corners">
            <a-icon size="20" class="mr-1 u-icon-nudge" style="top: 0px;" color="brown darken-1">note_alt</a-icon>
            <span class="md-caption brown--text text--darken-1 font-weight-medium">NOTE: Projects without start date are not included in the timeline</span>
        </div>

        <!-- Filters side pane -->
        <a-responsive class="c-filters-side-pane elevation-12" :class="{'visible': filters_pane}">
            <div style="position: relative" ref="rp_pane_filters">
                <div class="font-weight-medium white" style="position: sticky; top: 0; z-index: 200">
                    <a-layout align-center class="pa-6">
                        <a-flex>
                            <div class="d-flex">
                                <a-icon size="24" color="grey darken-4">filter_alt</a-icon>
                                <span class="pl-1" style="fontSize: 20px">Filters</span>
                            </div>
                        </a-flex>
                        <a-spacer></a-spacer>
                        <a-flex shrink>
                            <a-btn small icon @click="local_revert_filter()">
                                <a-icon size="20" color="grey">cancel</a-icon>
                            </a-btn>
                        </a-flex>
                    </a-layout>
                    <a-divider></a-divider>
                </div>
                <div class="c-filter-content">
                    <a-layout class="px-6" column>
                        <a-flex class="mt-5">
                            <div class="d-flex justify-space-between">
                                <label class="md-body-1 font-weight-medium grey--text text--darken-2">Role</label>
                                <div class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer" v-if="selected_roles && selected_roles.length" @click="selected_roles = []">Clear</div>
                            </div>
                            <a-autocomplete
                                v-model="selected_roles"
                                :items="local_role_list"
                                item-text="name"
                                item-value="id"
                                placeholder="Search Role"
                                class="pa-0 u-border mt-2"
                                :class="{'py-2 c-combo-border': selected_roles && selected_roles.length}"
                                background-color="grey lighten-5"
                                :append-icon="null"
                                solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first
                            >
                                <template v-slot:selection="{ parent, item }">
                                    <span class="d-inline-flex align-center u-rounded-corners-full grey lighten-2 pl-3 pr-2 py-2 mx-1 my-1">
                                        <template class="mr-2 md-body-2 grey--text text--darken-4" :title="item.name">{{ item.name | truncateText(20) }}</template>
                                        <a-icon size="16" @click.stop="parent.selectItem(item)" class="grey--text pl-2">cancel</a-icon>
                                    </span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <a-layout align-center class="py-2">
                                        <span>{{ item.name | truncateText(45)}}</span>
                                    </a-layout>
                                </template>
                                <template #no-data>
                                    <div style="height: 32px" class="px-4 u-flex-center-y">
                                        <span :class="['md-body-2 body--text text--lighten-1']">No Data Found</span>
                                    </div>
                                </template>
                            </a-autocomplete>
                        </a-flex>
                        <!-- <a-flex>
                            <div class="d-flex align-center mt-2">
                                <a-icon size="16" color="grey" class="mr-1">info</a-icon>
                                <p class="mb-0 md-caption grey--text font-weight-medium">Minimum one role should be selected.</p>
                            </div>
                        </a-flex> -->
                        <a-flex class="mt-4">
                            <div class="d-flex justify-space-between">
                                <label class="md-body-1 font-weight-medium grey--text text--darken-2">User Territory</label>
                                <div class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer" v-if="selected_territories && selected_territories.length" @click="selected_territories = []">Clear</div>
                            </div>
                            <a-autocomplete
                                v-model="selected_territories"
                                :items="local_territory_list"
                                item-text="name"
                                item-value="id"
                                placeholder="Search Territories"
                                class="pa-0 mt-2 u-border"
                                :class="{'py-2 c-combo-border': selected_territories && selected_territories.length}"
                                background-color="grey lighten-5"
                                :search-input.sync="local_search_territory"
                                :loading="local_loading_territories"
                                @blur="local_search_territory= ''"
                                :append-icon="null"
                                solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first
                            >
                                <template v-slot:selection="{ item, parent }">
                                    <a-chip :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}" class="chip--select-multi mx-1 my-1">
                                        <div class="d-inline-flex align-center">
                                            <span class="md-body-2" :style="{ color: $color(item.color, 'color_text') }">{{ item.name | truncateText(20) }}</span>
                                            <a-icon size="18" :color="$color(item.color, 'color_text')" class="u-cursor-pointer ml-1"  @click.stop="parent.selectItem(item)">cancel</a-icon>
                                        </div>
                                    </a-chip>
                                </template>
                                <template v-slot:item="{ item }">
                                    <div>
                                        <a-chip :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}" class="chip--select-multi mx-1 mb-0">
                                            <div class="d-inline-flex align-center">
                                                <span class="md-body-2" :style="{ color: $color(item.color, 'color_text') }">{{ item.name | truncateText(45) }}</span>
                                            </div>
                                        </a-chip>
                                    </div>
                                </template>
                                <template #no-data v-if="!mixinIsLoading('territory-search')">
                                    <div style="height: 32px" class="px-4 u-flex-center-y">
                                        <span :class="['md-body-2 body--text text--lighten-1']">No Data Found</span>
                                    </div>
                                </template>
                            </a-autocomplete>
                        </a-flex>
                        <a-flex class="mt-4">
                            <div class="d-flex justify-space-between">
                                <label class="md-body-1 font-weight-medium grey--text text--darken-2">Status</label>
                            </div>
                            <div class="mt-2 u-border rounded">
                                <a-menu bottom offset-y max-height="200">
                                    <template v-slot:activator="{ on }">
                                        <a-card height="48" elevation="0" class="u-rounded-corners u-overflow-hidden grey lighten-5" v-on="on">
                                            <a-card-text class="px-2 py-3 d-flex justify-space-between">
                                                <div>
                                                    <span class="mx-1 md-body-1 grey--text text--darken-3">{{ default_status.label }}</span>
                                                </div>
                                                <div>
                                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                                </div>
                                            </a-card-text>
                                        </a-card>
                                    </template>
                                    <a-list class="u-list-condensed">
                                        <a-list-item v-for="status in status_list" :key="status.label" @click="local_status_filter(status)">
                                            <a-list-item-title class="py-3">
                                                <span class="md-body-1 grey--text text--darken-1">{{ status.label }}</span>
                                            </a-list-item-title>
                                        </a-list-item>
                                    </a-list>
                                </a-menu>
                            </div>
                            <div class="mt-3 u-border rounded" v-if="default_status && default_status.key === 'completed'">
                                <a-menu bottom offset-y max-height="200">
                                    <template v-slot:activator="{ on }">
                                        <a-card height="48" elevation="0" class="u-rounded-corners u-overflow-hidden grey lighten-5" v-on="on">
                                            <a-card-text class="px-2 py-3 d-flex justify-space-between">
                                                <div>
                                                    <span class="mx-1 md-body-1 grey--text text--darken-3">{{ default_result && default_result.value ? default_result.value : 'All Results' }}</span>
                                                </div>
                                                <div>
                                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                                </div>
                                            </a-card-text>
                                        </a-card>
                                    </template>
                                    <a-list class="u-list-condensed">
                                        <a-list class="py-0">
                                            <a-list-item @click="local_clear_result('all')">
                                                <a-list-item-title class="py-3">
                                                    <span class="md-body-1 grey--text text--darken-1">All Results</span>
                                                </a-list-item-title>
                                            </a-list-item>
                                        </a-list>
                                        <a-divider></a-divider>
                                        <a-list-item v-for="result in result_list" :key="result.id" @click="local_result_filter(result)">
                                            <a-list-item-title class="py-3">
                                                <span class="md-body-1 grey--text text--darken-1">{{ result.value }}</span>
                                            </a-list-item-title>
                                        </a-list-item>
                                    </a-list>
                                </a-menu>
                            </div>
                        </a-flex>
                    </a-layout>
                </div>
                <div class="white" style="position: sticky; bottom: 0; z-index: 200">
                    <a-divider class="mt-16"></a-divider>
                    <a-layout class="px-6 pa-6">
                        <a-flex>
                            <a-btn depressed class="c-btn-filter white--text mr-4" color="indigo darken-1" @click="local_apply_filters()">Filter</a-btn>
                            <a-btn class="grey--text text--darken-3" depressed @click="local_revert_filter()">Cancel</a-btn>
                        </a-flex>
                        <a-spacer></a-spacer>
                        <a-flex shrink>
                            <a-btn class="c-btn-clearAll red--text text--darken-1" depressed @click="local_clear_all_filters()" color="red lighten-5">Clear all</a-btn>
                        </a-flex>
                    </a-layout>
                </div>
            </div>
        </a-responsive>
    </a-main>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { SProjectInfo } from '@/config/config-shared-components'
import { PDetailedItem, PUserItem } from './Partials'
import checkScopePermission from '@/mixins/mixin-checkscope-permission'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
// import loadWorkspace from '@/mixins/mixin-workspace'

export default {
    mixins: [checkScopePermission, mixinLoading],

    components: { SProjectInfo, PDetailedItem, PUserItem },

    data () {
        return {
            page_loading: true,
            project_info_loading: false,
            showProjectDetail: false,
            hoverTimeout: null,
            staticHoverTimeout: null,
            hovered_project_id: null,
            hovered_project: {},
            projectDetailXPos: 0,
            projectDetailYPos: 0,
            selected_date: { start_date: null, due_date: null },
            timeline_scroll: false,
            total_timeline_width: 0,
            single_column_width: 0,
            timeline_start_date: null,
            timeline_end_date: null,
            timeline_days_count: null,
            timeline_months_count: null,
            compact_height: 10,
            detail_height: 64,
            selected_territories: [],
            local_search_territory: '',
            territory_close_on_click: false,
            territory_dropdown: false,
            local_search_role: '',
            role_close_on_click: true,
            role_dropdown: false,
            status_dropdown: false,
            result_dropdown: false,
            default_result: {},
            result_default_slug: 'all',
            timeline_dropdown: false,
            view_dropdown: false,
            timeline_loading: false,
            load_all: [],
            selected_roles: [],
            result_list: [],
            filters: {},
            default_status: { key: 'active', label: 'Active' },
            status_list: [
                { key: 'all', label: 'All Projects' },
                { key: 'queue', label: 'Queue' },
                { key: 'active', label: 'Active' },
                { key: 'completed', label: 'Completed' },
            ],
            default_timeline: { key: '4-week', label: '4-Week' },
            timeline_list: [
                { key: '4-week', label: '4-Week' },
                { key: '3-month', label: '3-Month' },
            ],
            default_view: { key: 'detailed', label: 'Detailed' },
            view_types: [
                { key: 'compact', label: 'Compact' },
                { key: 'detailed', label: 'Detailed' },
            ],
            local_team_filter: {label: 'All Projects', value: 'all'},
            local_team_filter_list: [],
            filter_list_manager: [
                {'label': 'All Projects', 'value': 'all'},
                {'label': 'Direct Reports', 'value': 'direct_reports'},
                {'label': 'All Reports', 'value': 'all_reports'},
                {'label': 'Assigned to me', 'value': 'self'},
            ],
            filter_list_team: [
                {'label': 'All Projects', 'value': 'all'},
                {'label': 'Assigned to me', 'value': 'self'}
            ],
            filters_pane: false,
            rp_filters: {
                STATUS: 'status',
                RESULT: 'result_id',
                ROLE: 'filter[role_id]',
                TERRITORY: 'filter[territory_id]',
                HIERARCHY: 'filter[hierarchy_report]',
            },
            user_filters_pref: {},
            filter_preference: null,
            territory_filter: {
                NAME: 'filter[name]',
                COUNT: 'count',
            },
            search_territories_timeout: null,
            local_territories_filters: {},
            local_loading_territories: false,
            local_role_list: [],
            local_territory_list: []
        }
    },

    watch: {
        showProjectDetail (newVal) {
            if (!newVal) this.hovered_project_id = null
            if (newVal) this.local_show_project()
        },

        territory_dropdown (newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.territory_close_on_click = false
            }
        },

        async workspace_current (oldValue, newValue) {
            this.local_loading(true)
            await this.local_rp_index()
            setTimeout(() => {
                this.local_window_size()
                this.local_loading(false)
            }, 200)
        },

        local_search_territory (val) {
            if (!val || (val && !val.trim())) return
            this.local_territories_search_filter()
        }
    },

    mounted () {
        this.local_index()
    },

    beforeMount () {
        window.addEventListener('resize', this.local_window_size)
    },

    beforeDestroy () {
        window.removeEventListener('resize', this.local_window_size)
    },

    computed: {
        local_is_compact_view () {
            return this.default_view.key === 'compact'
        },

        local_interface_day_width () {
            return this.default_timeline.key === '4-week' ? (this.single_column_width / 7) : (this.single_column_width / 31)
        },

        local_selected_timeline () {
            return this.default_timeline.key === '4-week' ? 'week' : 'month'
        },

        local_salesforce_url () {
            return this.user_self && this.user_self.organization && this.user_self.organization.salesforce_instance_url
        },

        local_is_lgDown () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        local_check_no_filters() {
            return (this.default_status.key === 'active' && !this.selected_territories.length && !this.selected_roles.length  && !this.default_result.id)
        },

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),

        ...mapState('User', {
            user_self: 'self'
        }),

        ...mapState('Meta', {
            meta_list: 'list'
        }),

        ...mapState('Role', {
            role_list: 'list'
        }),

        ...mapState('Project', {
            project_item: 'item',
        }),

        ...mapGetters('ResourcePlanning', {
            rp_list: 'list',
            rp_response: 'response'
        }),

        ...mapState('Territory', {
            territory_list: 'list',
            territory_item: 'item',
        }),

        ...mapState('Preference', {
            preference_list: 'list',
        }),
    },

    methods: {
        async local_index() {
            this.local_project_checkscope()
            if (!this.$can('reports.index') || !this.$can('projects.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.local_loading(true)
            this.local_window_size()
            this.load_poc_result_meta()
            await this.territory_index({ 'fields[territories]': 'id,name,color', count: 5 })
            this.local_territory_list = _.cloneDeep(this.territory_list)
            await this.local_role_index()
            this.filters[this.rp_filters.STATUS] = 'active'
            if (this.user_self && this.user_self.id) await this.user_show({id: this.user_self.id, mode: 'self'})
            await this.local_fetch_preference()
            await this.local_timeline_setup_start_date()
            await this.local_timeline_setup_end_date()
            await this.local_rp_index()
            this.local_setup_calc_day_month_count()
            setTimeout(async () => {
                await this.local_set_current_month()
                this.local_loading(false)
                this.page_loading = false
            }, 300)
            this.local_set_hierarchy_list()
        },

        async local_fetch_preference(type = null) {
            await this.preference_index()
            this.filter_preference = this.preference_list.find(item => item.key === 'user.resource_planning.filters');
            this.local_set_filter_pref()
        },

        async local_set_filter_pref () {
            if (!this.filter_preference) return
            const local_filter_value = JSON.parse(this.filter_preference.value)
            for (let filtervalue in local_filter_value) {
                switch (filtervalue) {
                    case 'status':
                        this.default_status = local_filter_value[filtervalue]
                        break;
                    case 'result':
                        this.default_result = local_filter_value[filtervalue]
                        break;
                    case 'territories':
                        this.selected_territories = local_filter_value[filtervalue]
                        break;
                    case 'roles':
                        this.selected_roles = local_filter_value[filtervalue]
                        break;
                    case 'hierarchy_report':
                        this.local_team_filter = local_filter_value[filtervalue]
                        break;
                    case 'timeline':
                        this.default_timeline = local_filter_value[filtervalue]
                        this.local_setup()
                        break;
                    case 'view':
                        this.default_view = local_filter_value[filtervalue]
                        break;
                    default: break;
                }
            }
            this.local_set_filter_values()
        },

        local_set_filter_values () {
            if (this.user_self && !this.user_self.is_manager && this.local_team_filter && (this.local_team_filter.value !== 'self')) {
                this.local_team_filter['label'] = 'All Projects'
                this.local_team_filter['value'] = 'all'
            }
            this.filters[this.rp_filters.STATUS] = this.default_status.key
            this.filters[this.rp_filters.RESULT] = this.default_result && this.default_result.id ? this.default_result.id : null
            this.filters[this.rp_filters.TERRITORY] = _.size(this.selected_territories) ? _.map(this.selected_territories) : null
            this.filters[this.rp_filters.ROLE] = _.size(this.selected_roles) ? _.map(this.selected_roles) : null
            this.filters[this.rp_filters.HIERARCHY] = this.local_team_filter.value
        },

        local_window_size (evt) {
            const innerWidth = evt ? (evt.innerWidth ?? evt.target.innerWidth) : window.innerWidth
            const scrollSize = 16
            const sideNavSize = 88
            const teamMembersSize = 264
            const rightPaddingSize = 24
            this.total_timeline_width = innerWidth - (scrollSize + sideNavSize + teamMembersSize + rightPaddingSize)
            this.single_column_width = this.total_timeline_width / (this.local_selected_timeline === 'week' ? 4 : 3)
            setTimeout(() => this.local_get_child_position(), 300)
        },

        async local_timeline_setup_start_date () {
            this.timeline_start_date = this.local_selected_timeline === 'month' ? moment().startOf('quarter') : moment().startOf('isoweek')
            this.selected_date.start_date = this.timeline_start_date.format('YYYY-MM-DD')
        },

        async local_timeline_setup_end_date () {
            this.timeline_end_date = this.local_selected_timeline === 'month' ? moment().endOf('quarter') : moment().startOf('isoweek').add(4, 'week')
            this.selected_date.due_date = this.timeline_end_date.format('YYYY-MM-DD')
        },

        async local_rp_index () {
            const params = {
                'fields[collaborators.project]': 'id,title,start_date,due_date,status,health_color,deal_amount,customer_id,stage_id',
                'fields[role]': 'id,name',
                'filter[is_active]': 1,
                'filter[scope]': 'internal',
                // workspace_id: this.current_workspace_id_mixin,
                workspace_id: this.workspace_current,
                sort: 'name',
                include: 'role',
                columns: 'customer_name,salesforce_bridge,hubspot_bridge,stage_name',
                ...this.filters
            }
            await this.rp_index({ ...this.selected_date, ...params })
        },

        async local_setup_calc_day_month_count () {
            this.timeline_days_count = Math.ceil(this.timeline_end_date.diff(this.timeline_start_date, 'days', true))
            this.timeline_months_count = Math.ceil(this.timeline_end_date.diff(this.timeline_start_date, this.local_selected_timeline + 's', true))
        },

        async local_set_current_month () {
            const parent = document.querySelector(".c-timeline")
            const timeline_class = this.local_selected_timeline === 'week' ? this.local_timeline_class(this.timeline_start_date, 1, 'MMM D YYYY') : this.local_timeline_class(this.timeline_start_date)
            const content = document.querySelector(`.${ timeline_class }`)
            parent.scrollLeft = content.offsetLeft
            this.local_get_child_position()
        },

        local_get_child_position () {
            const children = document.getElementsByClassName('project-child')
            Array.from(children).forEach(childItem => {
                let parent = null
                if (childItem) {
                    parent = childItem.parentElement
                    const parentOffWidth = parent.offsetWidth
                    const parentOffLeft = parseInt(parent.style.left.split('px')[0])
                    const subOfLeftAndWidth = Math.abs(Math.abs(parentOffWidth) - Math.abs(parentOffLeft))

                   if (parentOffLeft <= 0) {
                       if (subOfLeftAndWidth < childItem.offsetWidth) childItem.style.left = (parentOffWidth) + 'px'
                       if (subOfLeftAndWidth > childItem.offsetWidth) childItem.style.left = Math.abs(parentOffLeft) + 'px'
                   }

                   if (parentOffLeft > 0) {
                       const timelineWidthLeft = this.total_timeline_width - parentOffLeft
                       if (timelineWidthLeft < childItem.offsetWidth) childItem.style.left = -(childItem.offsetWidth) + 'px'
                       else if (parentOffWidth < childItem.offsetWidth) childItem.style.left = -(childItem.offsetWidth) + 'px'
                       else childItem.style.left = '0px'
                   }
                }
            })
        },

        local_setup_start_end_date () {
            this.selected_date.start_date = this.timeline_start_date.format('YYYY-MM-DD')
            this.selected_date.due_date = this.timeline_end_date.format('YYYY-MM-DD')
        },

        async local_change_timeline (timeline) {
            this.local_loading(true)
            this.default_timeline = timeline
            await this.local_setup()
            this.local_apply_filters('on_select')
            // this.local_fetch_projects(700)
        },

        async local_change_view (view) {
            this.local_loading(true)
            this.default_view = view
            this.local_apply_filters('on_select')
            // this.local_fetch_projects(300)
        },

        async local_setup () {
            this.local_window_size()
            setTimeout(async () => {
                await this.local_timeline_setup_start_date()
                await this.local_timeline_setup_end_date()
                await this.local_setup_calc_day_month_count()
                await this.local_set_current_month()
            }, 500)
        },

        async local_get_previous_four (timeline) {
            this.local_start_timeline_load_scroll()
            const isWeek = this.local_selected_timeline === 'week'
            this.timeline_start_date = this.timeline_start_date.subtract(isWeek ? 4 : 3, this.local_selected_timeline + 's')
            this.timeline_end_date = this.timeline_end_date.subtract(isWeek ? 4 : 3, this.local_selected_timeline + 's')
            this.local_calc_date_after_scroll(timeline)
        },

        async local_get_next_four (timeline) {
            this.local_start_timeline_load_scroll()
            const isWeek = this.local_selected_timeline === 'week'
            this.timeline_start_date = this.timeline_start_date.add(isWeek ? 4 : 3, this.local_selected_timeline + 's')
            this.timeline_end_date = this.timeline_end_date.add(isWeek ? 4 : 3, this.local_selected_timeline + 's')
            this.local_setup_start_end_date()
            this.local_calc_date_after_scroll(timeline)
        },

        local_start_timeline_load_scroll () {
            this.timeline_scroll = true
            this.local_loading(true)
        },

        async local_calc_date_after_scroll (timeline) {
            this.local_setup_start_end_date()
            this.default_timeline = timeline
            this.timeline_scroll = false
            await this.local_setup_calc_day_month_count()
            await this.local_window_size()
            await this.local_rp_index()
            this.local_set_current_month()
            this.local_loading(false)
        },

        local_project_duration (project) {
            if (project.due_date !== null) return Math.floor(moment(project.due_date).diff(moment(project.start_date), 'days', true))
            const start_offset = this.local_project_start_offset(project)
            return (this.timeline_days_count - start_offset);
        },

        local_project_start_offset (project, index) {
            let count = Math.ceil(moment(project.start_date).diff(this.timeline_start_date, 'days', true))
            if (count < 0) { count++ }
            return count
        },

        async local_hovered_project (evt, project) {
            if (this.hovered_project && this.hovered_project.id !== project.id && this.showProjectDetail) return this.showProjectDetail = false
            this.hovered_project = project ?? {}
            if (this.showProjectDetail) return
            this.projectDetailXPos = evt.clientX
            this.projectDetailYPos = evt.clientY
        },

        async local_show_project (evt, project = this.hovered_project) {
            if (this.hoverTimeout) clearTimeout(this.hoverTimeout)
            if (this.staticHoverTimeout) clearTimeout(this.staticHoverTimeout)
            if (!(this.hovered_project.hasOwnProperty('active_days')) && !this.showProjectDetail) this.hovered_project = {}
            if (this.local_is_compact_view && this.hovered_project_id && !this.showProjectDetail) this.hovered_project_id = null
            else this.hovered_project_id = project.id
            this.hovered_project = project ?? {}
            this.project_info_loading = true
            this.hoverTimeout = setTimeout(async () => {
                if (!this.hovered_project.hasOwnProperty('id')) {
                    this.showProjectDetail = false
                    return clearTimeout(this.hoverTimeout)
                }
                await this.local_load_project_meta(project)
            }, 0)
        },

        async local_load_project_meta (project) {
            await this.project_show({ id: project.id, params: { include: 'salesforceBridge,hubspotBridge', 'fields[projects]': 'id,stage_id', 'fields[salesforce_bridge]': 'resource_id,value',  'fields[hubspot_bridge]': 'resource_id,value', } })
            await this.local_merge_proj_response(project)
            await this.project_show({
                id: project.id,
                params: {
                    include: 'keyCollaborators.user, successCriteriaCount, successCriteriaOpenCount, successCriteriaPassedCount, successCriteriaFailedCount,successCriteriaInvalidCount, salesforceBridge,hubspotBridge',
                    'fields[salesforce_bridge]': 'resource_id,value',
                    'fields[hubspot_bridge]': 'resource_id,value',
                    'fields[keyCollaborators]': 'id,project_id,user_id',
                    'fields[key_collaborators.user]': 'id,name,avatar,color,initial',
                    'fields[projects]': 'id',
                    columns: 'customer_name,salesforce_bridge,hubspot_bridge,stage_name',
                    disable_days_count: true
                }
            })
            await this.local_merge_proj_response(project)
            this.project_info_loading = false
        },

        async local_merge_proj_response (project) {
            const project_id = this.hovered_project.id
            if (project_id === this.project_item.id) {
                Object.assign(project, this.project_item)
                this.hovered_project = Object.assign(this.hovered_project, this.project_item)
                await this.project_clear_item()
            }
        },

        local_timeline_width (index) {
            // Weeks => 7 * 56 = 392px
            // Months => 31 * 16.86 = 522.66px
            return this.default_timeline.key === '4-week' ? this.single_column_width : (this.local_moment_add(this.timeline_start_date, index - 1, this.local_selected_timeline).daysInMonth() * this.local_interface_day_width)
        },

        local_get_date (index, type = null) {
            type = type === 'days' ? 'days' : (this.local_selected_timeline + 's')
            if (this.timeline_scroll) {
                return this.local_selected_timeline === 'week' ? this.local_moment_add(this.timeline_start_date, index - 1, type) : this.local_moment_add(this.timeline_start_date, index - 1, type)
            }
            return this.local_selected_timeline === 'week' ? this.local_moment_add(this.timeline_start_date, index - 1, type) : this.local_moment_add(this.timeline_start_date, index - 1, type)
        },

        local_user_height (user) {
            const isLoaded = this.local_is_user_loaded(user.id)
            const collabLength = (user.projects_count > 5 ? (isLoaded ? user.projects_count : 5) : user.projects_count) || 0
            const viewHeight = this.local_is_compact_view ? this.compact_height : this.detail_height
            if (!this.local_is_compact_view) {
                // viewHeight = 64
                // 32 -> padding-top and bottom of parent container of projects
                // 16 -> margin-top of detailed projects except first one -> (viewHeight + 16) - 16
                if (collabLength > 0 && collabLength === 1) return (collabLength * viewHeight)
                if (collabLength > 1) return ((collabLength * (viewHeight + 16)) + 32) - 16
            }

            if (this.local_is_compact_view) {
                if (collabLength > 0 && collabLength === 1) return collabLength * viewHeight
                // 8 -> margin-bottom
                // 32 -> padding-top and bottom of parent container of projects
                if (collabLength > 1) return ((collabLength * (viewHeight + 8)) + 32)
            }
        },

        local_calc_detail_height (user) {
            const isLoaded = this.local_is_user_loaded(user.id)
            const count = (user.projects_count > 5 ? (isLoaded ? user.projects_count : 5) : user.projects_count) || 1
            const isCompact = this.local_is_compact_view
            // 18 -> compact project height (10) + margin-bottom (8) -> 18
            // 80 -> detailed project height (64) + margin-top (32) -> except first project in list (16) => 80 + 32 - 16
            return user.projects_count >= 5 ? (count * (isCompact ? 18 : 80) + 32) - (isCompact ? 0 : 16) : 121
        },

        // Filters

        async local_status_filter (status) {
            if (status && status.key !== 'completed') {
                this.default_result = {}
                this.filters.result_id = null
                this.result_default_slug = null
            }
            this.result_default_slug = 'all'
            this.default_status = status
            this.filters[this.rp_filters.STATUS] = status.key
        },

        async local_result_filter (result) {
            this.result_default_slug = result ? null : this.result_default_slug
            this.default_result = result ?? {}
            this.filters.result_id = result ? result.id : null
        },

        local_clear_result (slug) {
            this.result_default_slug = slug
            this.local_result_filter(null)
        },

        local_role_is_selected (role_id) {
            return this.selected_roles.includes(role_id)
        },

        local_get_role_name (role_id) {
            const role = this.role_list.find(item => item.id === role_id)
            return role ? role.name : ''
        },
        // Filters ----- END

        // Load Metas
        async local_role_index () {
            await this.role_index({ include: 'source', 'filter[scope]': 'internal', 'sort': 'order' })
            this.local_role_list = _.cloneDeep(this.role_list)
            this.selected_roles = this.role_list.filter(item => item.source && item.source.slug === 'sales-engineer').map((role) => role)
            this.filters[this.rp_filters.ROLE] = this.selected_roles[0].id
        },

        async load_poc_result_meta () {
            await this.meta_index({ 'filter[type]': 'project_result', 'sort': 'order' })
            this.result_list = _.cloneDeep(this.meta_list)
        },
        // Load Metas ----- END

        // Extras
        local_fetch_projects (timeout) {
            setTimeout(async () => {
                this.local_loading(true)
                this.local_timeline_setup_start_date()
                this.local_timeline_setup_end_date()
                await this.local_rp_index()
                await this.local_set_current_month()
                await this.local_window_size()
                this.local_nodata_response()
                this.local_loading(false)
            }, timeout)
        },

        local_timeline_class (date, index, format = 'MMM YYYY') {
            return this.local_moment_add(date, index - 1, this.local_selected_timeline + 's')
                        .format(format)
                        .toLowerCase()
                        .split(' ')
                        .join('-')
        },

        local_toggle_userload (user_id) {
            this.local_window_size()
            const index = this.load_all.findIndex(id => id === user_id)
            if (index !== -1) return this.load_all.splice(index, 1)
            this.load_all.push(user_id)
        },

        local_is_user_loaded (user_id) {
            return this.load_all.includes(user_id)
        },

        local_territory_is_selected (territory_id) {
            return this.selected_territories.includes(territory_id)
        },

        local_get_role_territory (territory_id) {
            return this.territory_list.find(item => item.id === territory_id)
        },

        local_loading (value) {
            this.timeline_loading = value
        },

        local_moment_add (moment_object, count, unit, doo) {
            return moment(moment_object).add(count, unit)
        },

        local_nodata_response () {
            if (!this.$status(this.rp_response)) return this.$notify('error', 'Something went wrong, please re-check the filters and try again.')
            if (this.rp_list && this.rp_list.length === 0 && this.filtered_list.length < 1) return this.$notify('error', 'No data found for the selected filters')
            if (this.rp_list && this.rp_list.length === 0 && this.filtered_list.length >= 1 ) {
                this.$notify('error', 'There are no projects available to display.The view may affected due to role based access control. Please check your configuration or contact your admin.')
            }
        },

        local_set_hierarchy_list () {
            if (this.user_self && this.user_self.is_manager) return this.local_team_filter_list = this.filter_list_manager
            return this.local_team_filter_list = this.filter_list_team
        },

        local_team_filter_set(label, value) {
            this.local_loading(true)
            this.local_team_filter.label = label
            this.local_team_filter.value = value
            this.filters[this.rp_filters.HIERARCHY] = value
            // this.local_fetch_projects(700)
            this.local_apply_filters('on_select')
        },

        local_show_filters_pane() {
            if (this.timeline_loading) return
            if (this.filters_pane) this.filters_pane = false
            this.$refs.rp_pane_filters.scrollTop = 0
            this.filters_pane = true
        },

        local_revert_filter () {
            this.local_fetch_preference()
            this.filters_pane = false
        },

        async local_apply_filters (type) {
            if (type !== 'on_select') this.local_loading(true)
            this.local_set_filter_values()
            this.local_fetch_projects(700)
            this.local_set_user_pref()
            this.filters_pane = false
            const preference_params = {
                resource_id: this.user_self.id,
                resource_type: 'User',
                value: JSON.stringify(this.user_filters_pref),
                label: 'Resource Planning Filter',
                key: 'user.resource_planning.filters',
                is_listable: 0,
            }
            if (this.filter_preference) await this.preference_filters_update({ id: this.filter_preference.id, ...preference_params })
            else await this.preference_filters_store({ ...preference_params })
        },

        local_set_user_pref () {
            this.user_filters_pref = {
                status: this.default_status,
                result: this.default_result,
                territories: this.selected_territories,
                roles: this.selected_roles,
                hierarchy_report: this.local_team_filter,
                timeline: this.default_timeline,
                view: this.default_view
            }
        },

        local_clear_all_filters() {
            this.local_reset_filters()
            this.local_apply_filters()
        },

        local_reset_filters() {
            this.default_status.key = 'active'
            this.default_status.label = 'Active'
            this.selected_territories = []
            this.default_result = {}
            this.filters[this.rp_filters.RESULT] = null
            this.result_default_slug = null
            this.selected_roles = []
        },

        local_territories_search_filter() {
            if(!this.local_search_territory || this.local_search_territory && !this.local_search_territory.trim()) return
            this.mixinSetLoading('territory-search')
            clearTimeout(this.search_territories_timeout)
            this.search_territories_timeout = window.setTimeout(async () => {
                await this.local_load_territories_list()
            }, 500)
            this.mixinResetLoading('territory-search')
        },

        async local_load_territories_list () {
            this.local_loading_territories = true
            this.local_territories_filters[this.territory_filter.NAME] = this.local_search_territory
            this.local_territories_filters[this.territory_filter.COUNT] = 5
            await this.territory_index({'fields[territories]': 'id,name,color', ...this.local_territories_filters})
            this.local_territory_list = [...this.local_territory_list, ...this.territory_list]
            this.local_loading_territories = false
        },

        ...mapActions('Meta', {
            meta_index: 'index'
        }),

        ...mapActions('Role', {
            role_index: 'index'
        }),

        ...mapActions('Territory', {
            territory_index: 'index',
            territory_clear: 'clear'
        }),

        ...mapActions('Project', {
            project_show: 'show',
            project_clear_item: 'clear_item',
        }),

        ...mapActions('ResourcePlanning', {
            rp_index: 'index'
        }),

        ...mapActions('User', {
            user_show: 'show'
        }),

        ...mapActions('Preference', {
            preference_index: 'index',
            preference_filters_store : 'store',
            preference_filters_update : 'update',
            preference_clear_user_pref : 'clear_user_types',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear'
        }),
    }
}
</script>

<style lang="scss" scoped>
.c-compact-bar {
    .project-child {
        visibility: hidden !important;
    }
    &:hover {
        .project-child {
            visibility: visible !important;
        }
    }
}

.c-timeline-bar, .c-compact-bar {
    transition: 0.4s all;
}

.c-timeline-bar {
    .c-icon-show > .v-icon {
        color: #bdbdbd !important;
        transition: 0.2s all;
    }
    &:hover {
        .c-icon-show > .v-icon {
            color: #464646 !important;
            transition: 0.2s all;
        }
    }
}

.c-dates-wrap {
    transition: 0.5s all;
    &__dates {
        transition: 0.5s ease-in;
    }
}

.c-filters-side-pane.visible {
    transform: translateX(0);
}

.c-filters-side-pane {
    transition: 0.3s all;
    position: fixed;
    top: calc((100vh - 96vh)/2);
    left: 24px;
    height: 96vh;
    width: 500px;
    z-index: 200;
    background-color: white;
    transform: translateX(-528px);
    border-radius: 8px;
}

.c-filter-content {
    overflow-y: scroll;
    height: calc(96vh - 164px);
}

.c-btn-clearAll.v-btn.v-btn--disabled {
    opacity: 0.5;
    background-color: #FFEBEE !important;
    color: #E53935 !important;
}

.c-combo-border {
    border: 1px solid rgba(238, 238, 238, 1) !important;
    background-color: #fafafa !important;
}
</style>

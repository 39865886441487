<template>
    <div>
        <InternalDocs v-if="local_user_is_external" />
        <ExternalDocs v-else />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import InternalDocs from '@/pages/Projects/ProjectsDocuments.vue'
import ExternalDocs from '@/pages/External/ExternalProjects/ExternalProjectsDocuments.vue'


export default {
    components: {
        InternalDocs,
        ExternalDocs
    },

    computed: {
        local_user_is_external() {
            return this.user_self && this.user_self.scope === 'internal'
        },
        ...mapState('User', {
            user_self: 'self'
        })
    }

}
</script>

<template>
    <div class="u-wfull" v-bind="$attrs" :class="`tile_${item.id}`">
        <div class="u-flex-center-y pa-3 px-4">
            <div class="u-flex-center-y u-wfull">
                <slot name="title">
                    <h3 class="md-body-2 font-weight-medium grey--text text--darken-1 mr-1" :title="item.title">{{ item.title | truncateText(local_is_one_by_one_chart ? 16 : 60) }}</h3>
                </slot>
                <slot name="info">
                    <a-tooltip bottom v-if="item.description" content-class="c-tooltip-pointer c-tooltip-pointer--right">
                        <template v-slot:activator="{ on }">
                            <a-icon v-on="on" size="14" color="grey darken-1" style="padding: 2px;" class="remove-when-downloading">info</a-icon>
                        </template>
                        <a-sheet class="transparent white--text" max-width="264">{{ item.description | truncateText(96) }}</a-sheet>
                    </a-tooltip>
                </slot>
            </div>
            <a-spacer></a-spacer>
            <slot name="actions" v-if="local_user_can('update') || local_user_can('destroy') || local_user_can('download')">
                <div v-if="local_show_menu" class="u-flex-center-y">
                    <slot v-if="local_user_can('update')">
                        <a-icon size="16" :color="(loading_statuses.includes(item.status) || cloned_parent_id) ? 'grey lighten-1' : 'grey darken-1'" @click.stop="(loading_statuses.includes(item.status) || cloned_parent_id) ? '' : $emit('hard-refresh', item)" style="padding: 2px;" class="mr-3 remove-when-downloading">refresh</a-icon>
                    </slot>
                    <slot>
                        <a-menu v-model="settings_model" content-class="remove-when-downloading" :disabled="loading_statuses.includes(item.status) || !!(cloned_parent_id)" :attach="`.tile_${item.id}`" transition="slide-y-transition" min-width="160" max-width="160" bottom left offset-y>
                            <template v-slot:activator="{ on }">
                                <a-icon v-on.stop="on" size="16" :color="(loading_statuses.includes(item.status) || cloned_parent_id) ? 'grey lighten-1' : 'grey darken-1'" style="padding: 2px;" class="remove-when-downloading">settings</a-icon>
                            </template>
                            <a-list class="u-list-std">
                                <a-list-item @click.stop="local_edit_config()" v-if="local_user_can('update')">
                                    <a-list-item-content class="md-body-2 grey--text text--darken-2">Configuration</a-list-item-content>
                                </a-list-item>
                                <a-list-item @click.stop="local_clone_tile" v-if="local_user_can('update')">
                                    <a-list-item-content class="md-body-2 grey--text text--darken-2">Duplicate Tile</a-list-item-content>
                                </a-list-item>
                                <!-- <a-list-item @click.stop="!isRearrange ? local_go_to_tile_view(item.id) : {}">
                                    <a-list-item-content class="md-body-2 grey--text text--darken-2">Detailed View</a-list-item-content>
                                </a-list-item> -->
                                <a-list-item @click.stop="local_export" v-if="local_user_can('download')">
                                    <a-list-item-content class="md-body-2 grey--text text--darken-2">Export as PNG</a-list-item-content>
                                </a-list-item>
                                <a-list-item v-if="local_user_can('destroy')" @click.stop="confirm_delete ? local_delete_tile() : local_get_delete_confirm()" :class="[{ 'c-delete-confirm': confirm_delete }]">
                                    <a-list-item-content :class="{ 'md-body-2 accent--text': !confirm_delete }">{{ confirm_delete ? 'Confirm Delete?' : 'Delete Tile' }}</a-list-item-content>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </slot>
                </div>
                <!-- <a-sheet class="md-caption body--text text--lighten-2 font-weight-medium d-inline-flex align-center justify-end transparent" height="16" width="120" v-else>{{ local_get_date() }}</a-sheet> -->
            </slot>
        </div>

        <div class="hide-when-downloading">
            <div class="u-wfull" v-if="loading_actions">
                <a-progress-linear color="blue lighten-1" height="1" indeterminate></a-progress-linear>
            </div>
        </div>
        <div class="u-wfull">
            <a-divider></a-divider>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    inheritAttrs: false,

    name: 'PartialTileToolbar',

    data () {
        return {
            loading_statuses: ['loading', 'update-loading', 'new-loading', 'force-loading'],
            loading_actions: false,
            confirm_delete: false,
            settings_model: false,
            cloned_parent_id: null,
        }
    },

    props: {
        item: {
            type: Object,
            required: true
        },
        isHover: {
            type: Boolean,
            default: false,
        },
        isRearrange: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        isHover (val) {
            if (!val) this.settings_model = false
        }
    },

    computed: {
        local_show_menu () {
            if(this.isHover && !this.isRearrange && !this.tile_faux_loader.length) {
                if(!this.loading_statuses.includes(this.item.status)) {
                    return true
                }
            }
            else {
                return false
            }
        },

        local_is_one_by_one_chart () {
            const whitelist = ['Pie', 'Donut', 'Numbers']
            const type = this.item && this.item.source && this.item.source.chart_type
            return whitelist.includes(type)
        },

        ...mapState('Tile', {
           tile_faux_loader: 'faux_loader'
        }),
    },

    methods: {
        local_edit_config () {
            this.settings_model = false
            this.$emit('edit')
        },

        local_clone_tile () {
            this.settings_model = false
            this.loading_actions = true
            this.cloned_parent_id = this.item.id
            this.$emit('clone', this.item, this.local_stop_loading_action)
        },

        local_export () {
            this.settings_model = false
            this.loading_actions = true
            this.$emit('export', this.local_stop_loading_action)
        },

        local_get_delete_confirm () {
            this.confirm_delete = true
            setTimeout(() => this.confirm_delete = false, 3000)
        },

        local_delete_tile () {
            this.settings_model = false
            this.$emit('delete')
        },

        local_stop_loading_action () {
            this.loading_actions = false
            if (this.cloned_parent_id) this.cloned_parent_id = null
        },

        local_user_can (permission) {
            return this.$can(`insights_dashboard.${permission}`)
        },
    },
}
</script>

<style lang="scss" scoped>
.c-chart-toolbar {
    position: absolute;
    top: 1rem;
    padding: 0 1.5rem;
    margin-bottom: 1.5rem;
}
</style>

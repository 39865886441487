<template>
	<a-main>
        <a-responsive class="grey darken-4 u-rounded-corners-lg">
            <a-container grid-list-xl container--fluid class="py-2 px-5">
                <a-layout align-start>
                    <a-flex shrink class="px-0">
                        <a-btn color="white" text v-if="installation_item && installation_item.applet" :to="{name: `appstore-${installation_item.applet.slug}-workflows`, params: {id: installation_item.id}}" exact>
                            <a-icon class="mr-1">arrow_back</a-icon> Back to workflows
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive>
        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink style="height: 32px;">
                    <a-divider vertical class="ma-0 d-block"></a-divider><a-divider vertical class="ma-0 d-block"></a-divider>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink>
                    <a-avatar color="indigo" class="elevation-4" size="64">
                        <a-icon color="white">add</a-icon>
                    </a-avatar>
                </a-flex>
            </a-layout>
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink>
                    <h2 class="md-heading-3 font-weight-light text-center indigo--text text--darken-2">Create Workflow</h2>
                    <p class="md-body-2 mb-2 text-center">Let's get started by configuring basic information of your workflow.</p>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink style="height: 72px;">
                    <a-divider vertical class="ma-0 d-block"></a-divider><a-divider vertical class="ma-0 d-block"></a-divider>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid class="pa-0" style="margin-bottom: -2rem; z-index: 1; position: relative;">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink>
                    <a-avatar color="light-green darken-2" class="mb-2 elevation-2" size="48" v-if="workflow_item_info_complete === true">
                        <a-icon color="white">done</a-icon>
                    </a-avatar>
                    <a-avatar color="white" class="mb-2 elevation-2" size="48" v-else>
                        <a-icon color="orange darken-1">info</a-icon>
                    </a-avatar>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink>
                    <a-card flat class="neutral darken-1 u-rounded-corners u-overflow-hidden" min-width="640" max-width="640">
                        <a-card-text class="px-4 pb-4">
                            <a-container container--fluid grid-list-xl>
                                <a-layout wrap align-center>
                                    <a-flex xs12>
                                        <h2 class="md-subtitle-2 font-weight-bold text-center text-uppercase grey--text text--darken-3">Workflow Title</h2>
                                    </a-flex>
                                </a-layout>
                                <a-layout wrap align-center>
                                    <a-flex xs12>
                                        <a-text-field
                                            v-model="workflow_item.title"
                                            placeholder="Title"
                                            background-color="white"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details autofocus
                                            @input="local_trigger_field_reset('title')"
                                        >
                                        </a-text-field>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink style="height: 72px;">
                    <a-divider vertical class="ma-0 d-block"></a-divider><a-divider vertical class="ma-0 d-block"></a-divider>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid class="pa-0" style="margin-bottom: -2rem; z-index: 1; position: relative;">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink>
                    <a-avatar color="light-green darken-2" class="mb-2 elevation-2" size="48" v-if="workflow_item_trigger_complete === true">
                        <a-icon color="white">done</a-icon>
                    </a-avatar>
                    <a-avatar color="white" class="mb-2 elevation-2" size="48" v-else>
                        <a-icon color="orange darken-1">flash_on</a-icon>
                    </a-avatar>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink>
                    <a-card flat class="neutral darken-1 u-rounded-corners u-overflow-hidden" min-width="640" max-width="640">
                        <a-card-text class="px-4 pb-4">
                            <a-container container--fluid grid-list-xl>
                                <a-layout wrap align-center>
                                    <a-flex xs12>
                                        <h2 class="md-subtitle-2 font-weight-bold text-center text-uppercase grey--text text--darken-3">Trigger</h2>
                                    </a-flex>
                                </a-layout>
                                <a-layout wrap align-center>
                                    <a-flex xs12>
                                        <a-select
                                            v-model="workflow_item.source_service"
                                            :items="localServicesList"
                                            item-text="label"
                                            placeholder="Source"
                                            background-color="white"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details return-object
                                            @input="local_trigger_field_reset('source_service')"
                                        >
                                            <template v-slot:item="{ on, item }">
                                                <v-list-item v-on="on" class="u-flex-center-y" :disabled="item.isDisabled">
                                                    {{ item.label }}
                                                    <GUpgradeIcon class="ml-2" v-if="item.isDisabled" />
                                                </v-list-item>
                                            </template>
                                            <template slot="prepend-inner" v-if="workflow_item.source_service && workflow_item.source_service.slug === 'salesforce'">
                                                <img src="../../assets/images/logo-salesforce.svg" alt="Salesforce" style="display: block; max-width: 24px; max-height: 24px; margin-right: 8px;">
                                            </template>
                                            <template slot="prepend-inner" v-if="workflow_item.source_service && workflow_item.source_service.slug === 'success'">
                                                <img src="../../assets/images/success-imgs/icon-success.svg" class="u-rounded-corners-lg" alt="Success" style="display: block; max-width: 24px; max-height: 24px; margin-right: 8px;">
                                            </template>
                                        </a-select>
                                    </a-flex>
                                    <a-flex xs12 v-if="workflow_item.source_service && workflow_item.source_service.id && localTriggersList.length > 0">
                                        <a-select
                                            v-model="workflow_item.trigger"
                                            :items="localTriggersList"
                                            item-text="label"
                                            placeholder="Trigger"
                                            background-color="white"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details return-object
                                            @input="local_trigger_field_reset('trigger')"
                                        >
                                            <template v-slot:item="{ on, item }">
                                                <v-list-item v-on="on" class="u-flex-center-y" :disabled="item.isDisabled">
                                                    {{ item.label }}
                                                    <GUpgradeIcon class="ml-2" v-if="item.isDisabled" />
                                                </v-list-item>
                                            </template>
                                        </a-select>
                                    </a-flex>
                                    <a-flex xs12 v-if="workflow_item.trigger && workflow_item.trigger.is_custom_resource === 1">
                                        <a-text-field
                                            v-model="workflow_item.trigger_resource.name"
                                            placeholder="Custom Object Name"
                                            background-color="white"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details
                                        >
                                        </a-text-field>
                                    </a-flex>
                                    <a-flex xs12 v-if="workflow_item.trigger && workflow_item.trigger.is_custom_resource === 1">
                                        <a-text-field
                                            v-model="workflow_item.trigger_resource.label"
                                            placeholder="Custom Object Label"
                                            background-color="white"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details
                                        >
                                        </a-text-field>
                                    </a-flex>
                                    <a-flex xs12 v-if="workflow_item.trigger && workflow_item.trigger.is_custom_resource === 1">
                                        <a-text-field
                                            v-model="workflow_item.trigger_resource.field"
                                            placeholder="Custom Object Field Name"
                                            background-color="white"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details
                                        >
                                        </a-text-field>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink style="height: 72px;">
                    <a-divider vertical class="ma-0 d-block"></a-divider><a-divider vertical class="ma-0 d-block"></a-divider>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid class="pa-0" style="margin-bottom: -2rem; z-index: 1; position: relative;">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink>
                    <a-avatar color="light-green darken-2" class="mb-2 elevation-2" size="48" v-if="workflow_item_action_complete === true">
                        <a-icon color="white">done</a-icon>
                    </a-avatar>
                    <a-avatar color="white" class="mb-2 elevation-2" size="48" v-else>
                        <a-icon color="orange darken-1">assignment_turned_in</a-icon>
                    </a-avatar>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink>
                    <a-card flat class="neutral darken-1 u-rounded-corners u-overflow-hidden" min-width="640" max-width="640">
                        <a-card-text class="pa-4">
                            <a-container container--fluid grid-list-xl>
                                <a-layout wrap align-center v-if="workflow_item_trigger_complete === true">
                                    <a-flex xs12>
                                        <h2 class="md-subtitle-2 font-weight-bold text-center text-uppercase grey--text text--darken-3">Action</h2>
                                    </a-flex>
                                    <a-flex xs12>
                                        <a-select
                                            v-model="workflow_item.destination_service"
                                            :items="applet_service_list"
                                            item-text="label"
                                            placeholder="Service"
                                            background-color="white"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details return-object disabled
                                        >
                                            <template slot="prepend-inner" v-if="workflow_item.destination_service && workflow_item.destination_service.slug === 'salesforce'">
                                                <img src="../../assets/images/logo-salesforce.svg" alt="Salesforce" style="display: block; max-width: 24px; max-height: 24px; margin-right: 8px;">
                                            </template>
                                            <template slot="prepend-inner" v-if="workflow_item.destination_service && workflow_item.destination_service.slug === 'success'">
                                                <img src="../../assets/images/success-imgs/icon-success.svg" class="u-rounded-corners-lg" alt="Success" style="display: block; max-width: 24px; max-height: 24px; margin-right: 8px;">
                                            </template>
                                        </a-select>
                                    </a-flex>
                                    <a-flex xs12 v-if="workflow_item.destination_service && workflow_item.destination_service.slug">
                                        <a-select
                                            v-model="workflow_item.action"
                                            :items="localActionsList"
                                            item-text="label"
                                            placeholder="Action"
                                            background-color="white"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details return-object
                                            @input="local_trigger_field_reset('action')"
                                        >
                                            <template v-slot:item="{ on, item }">
                                                <v-list-item v-on="on" class="u-flex-center-y" :disabled="item.isDisabled">
                                                    {{ item.label }}
                                                    <GUpgradeIcon class="ml-2" v-if="item.isDisabled" />
                                                </v-list-item>
                                            </template>
                                        </a-select>
                                    </a-flex>
                                    <a-flex xs12 v-if="workflow_item.action && workflow_item.action.is_custom_resource === 1">
                                        <a-text-field
                                            v-model="workflow_item.action_resource.name"
                                            placeholder="Custom Object Name"
                                            background-color="white"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details
                                        >
                                        </a-text-field>
                                    </a-flex>
                                    <a-flex xs12 v-if="workflow_item.action && workflow_item.action.is_custom_resource === 1">
                                        <a-text-field
                                            v-model="workflow_item.action_resource.label"
                                            placeholder="Custom Object Label"
                                            background-color="white"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details
                                        >
                                        </a-text-field>
                                    </a-flex>
                                </a-layout>
                                <a-layout v-if="workflow_item.action && workflow_item.action.is_custom_resource === 1 && (workflow_item.trigger && workflow_item.trigger.slug === 'success-status_update-create') && (workflow_item.action && workflow_item.action.slug === 'salesforce-cobject-create')">
                                    <a-flex xs12>
                                        <a-text-field
                                            v-model="workflow_item.related_field_id"
                                            placeholder="Related Object Id Field"
                                            background-color="white"
                                            class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                            flat solo hide-details
                                        >
                                        </a-text-field>
                                    </a-flex>
                                </a-layout>
                                <a-layout wrap align-center v-else>
                                    <a-flex xs12>
                                        <p class="md-body-1 text-center grey--text mb-0">Define trigger options to configure action on the other application.</p>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink style="height: 72px;">
                    <a-divider vertical class="ma-0 d-block"></a-divider>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink>
                    <p class="text-center mb-0">
                        <a-btn
                            color="indigo"
                            class="ma-0"
                            size="64"
                            :disabled="!(workflow_item_info_complete === true && workflow_item_trigger_complete === true && workflow_item_action_complete === true)"
                            :dark="(workflow_item_info_complete === true && workflow_item_trigger_complete === true && workflow_item_action_complete === true)"
                            fab
                            :loading="loading"
                            @click="local_workflow_setup()"
                        >
                            <a-icon color="white">chevron_right</a-icon>
                        </a-btn>
                    </p>
                    <p class="md-subtitle-1 grey--text text--darken-2 text-center mt-3">Additional configurations will be provided in the next step.</p>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid class="pa-0">
            <a-layout align-center justify-center class="pa-0">
                <a-flex shrink style="height: 72px;">
                </a-flex>
            </a-layout>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'

export default {
    mixins: [mixinGetOrganizationPlan],

    data() {
        return {
            workflow_item_info_complete: false,
            workflow_item_trigger_complete: false,
            workflow_item_action_complete: false,
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        localServicesList () {
            const list = this.applet_service_list.map(item => ({ ...item, isDisabled: !this.$services(item.slug) }))
            return _.orderBy(list, 'plan_level', 'asc')
        },
        localTriggersList () {
            const list = this.applet_trigger_list.map(item => ({ ...item, isDisabled: !this.$triggers(item.slug) }))
            return _.orderBy(list, 'plan_level', 'asc')
        },
        localActionsList () {
            const list = this.applet_action_list.map(item => ({ ...item, isDisabled: !this.$actions(item.slug) }))
            return _.orderBy(list, 'plan_level', 'asc')
        },
        ...mapState('Installation', {
            installation_item: 'item',
        }),
        ...mapState('AppletService', {
            applet_service_list: 'list',
            applet_service_filters: 'filters',
        }),
        ...mapState('AppletTrigger', {
            applet_trigger_list: 'list',
            applet_trigger_filters: 'filters',
        }),
        ...mapState('AppletAction', {
            applet_action_list: 'list',
            applet_action_filters: 'filters',
        }),
        ...mapState('Workflow', {
            workflow_item: 'item',
            workflow_filters: 'filters',
            workflow_response: 'response'
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            // await this.installation_clear_item()
            // await this.workflow_clear_item()
            // await this.applet_service_clear()

            await this.installation_show({id: this.$route.params.id})

            await this.workflow_show({id: this.$route.params.workflow_id})
            if (this.workflow_item.is_created === 1) {
                this.$router.back()
            }
            if (this.workflow_item.title) {
                this.workflow_item_info_complete = true
            }

            await this.applet_service_index({ 'applet_id': this.installation_item.applet_id })
        },
        async local_workflow_setup() {
            await this.workflow_setup(this.workflow_item)
            if (this.workflow_response.status === 'success') {
                this.$router.push({name: 'appstore-salesforce-workflows-edit', params: { id: this.$route.params.id, workflow_id: this.$route.params.workflow_id }})
            }
            if(this.workflow_response.server && this.workflow_response.server.errors && this.workflow_response.server.errors.title) {
                this.$notify('error', this.workflow_response.server.errors.title[0])
            }
        },
        local_workflow_type_attribute(slug, property)
        {
            return _.get(_.find(this.workflow_types, {slug: slug}), property)
        },
        local_triggers_by_service(service)
        {
            return _.filter(this.triggers, {service: service})
        },
        local_actions_by_trigger(trigger)
        {
            return _.filter(this.actions, {trigger: trigger})
        },
        local_objects_by_trigger(trigger)
        {
            return _.filter(this.objects, {trigger: trigger})
        },
        local_objects_by_action(action)
        {
            return _.filter(this.objects, {action: action})
        },
        async local_trigger_field_reset(field) {
            if (field === 'title') {
                if (this.workflow_item.title === '') {
                    this.workflow_item_info_complete = false
                } else {
                    this.workflow_item_info_complete = true
                }
            }
            if (field === 'source_service') {
                this.workflow_item_trigger_complete = false
                this.workflow_item_action_complete = false

                delete this.workflow_item.trigger
                delete this.workflow_item.trigger_resource
                delete this.workflow_item.action
                delete this.workflow_item.action_resource
                if (this.workflow_item.source_service && this.workflow_item.source_service.slug === 'salesforce') {
                    this.workflow_item.destination_service = _.find(this.applet_service_list, {slug: 'success'})
                }
                if (this.workflow_item.source_service && this.workflow_item.source_service.slug === 'success') {
                    this.workflow_item.destination_service = _.find(this.applet_service_list, {slug: 'salesforce'})
                }
                if (this.workflow_item.source_service) {
                    await this.applet_trigger_clear()
                    await this.applet_trigger_index({ 'applet_service_id': this.workflow_item.source_service.id })
                }
            }
            if (field === 'trigger') {
                this.workflow_item_trigger_complete = false
                this.workflow_item_action_complete = false

                delete this.workflow_item.trigger_resource
                delete this.workflow_item.action
                delete this.workflow_item.action_resource

                if (this.workflow_item.trigger) {
                    await this.applet_action_clear()
                    await this.applet_action_index({ 'applet_trigger_id': this.workflow_item.trigger.id })
                    this.workflow_item_trigger_complete = true
                    this.workflow_item.trigger_resource = { name: this.workflow_item.trigger.resource_name, label: this.workflow_item.trigger.resource_label }
                }
            }
            if (field === 'action') {
                this.workflow_item_action_complete = false
                delete this.workflow_item.action_resource

                if (this.workflow_item.action) {
                    this.workflow_item_action_complete = true
                    this.workflow_item.action_resource = { name: this.workflow_item.action.resource_name, label: this.workflow_item.action.resource_label }
                }
            }
        },
        ...mapActions('AppletService', {
            applet_service_index: 'index',
            applet_service_clear: 'clear',
        }),
        ...mapActions('AppletTrigger', {
            applet_trigger_index: 'index',
            applet_trigger_clear: 'clear',
        }),
        ...mapActions('AppletAction', {
            applet_action_index: 'index',
            applet_action_clear: 'clear',
        }),
        ...mapActions('Installation', {
            installation_show: 'show',
            installation_clear_item: 'clear_item',
        }),
        ...mapActions('Workflow', {
            workflow_show: 'show',
            workflow_setup: 'setup',
            workflow_clear_item: 'clear_item',
        }),
    },
}
</script>

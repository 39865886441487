<template>
    <div>
        <template v-if="localPageLoading">
            <a-container container--fluid grid-list-xl pa-0 mt-6>
                <a-layout wrap align-center>
                    <a-flex shrink pr-1>
                        <a-sheet class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="264">
                            <loader-template height="24" width="100%" class="u-rounded-corners-lg"></loader-template>
                        </a-sheet>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink>
                        <a-sheet class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="250">
                            <loader-template height="24" width="100%" class="u-rounded-corners-lg"></loader-template>
                        </a-sheet>
                    </a-flex>
                </a-layout>
                <a-layout align-center class="u-rounded-corners mt-4">
                    <a-flex pt-0>
                        <a-sheet class="u-overflow-hidden">
                            <a-container container--fluid grid-list-xl pa-6>
                                <a-layout align-center>
                                    <a-flex shrink xs4 xl4 class="u-cursor-pointer">
                                        <loader-template height="20" width="60%" class="u-rounded-corners-lg"></loader-template>
                                    </a-flex>
                                    <a-flex shrink xs3 xl3>
                                        <loader-template height="20" width="60%" class="u-rounded-corners-lg"></loader-template>
                                    </a-flex>
                                    <a-flex shrink xs3 xl3>
                                        <loader-template height="20" width="60%" class="u-rounded-corners-lg"></loader-template>
                                    </a-flex>
                                    <a-flex shrink xs3 xl3>
                                        <loader-template height="20" width="60%" class="u-rounded-corners-lg"></loader-template>
                                    </a-flex>
                                    <a-flex shrink xs1 xl1>
                                        <loader-template height="20" width="80%" class="u-rounded-corners-lg"></loader-template>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-sheet>
                        <a-divider></a-divider>
                        <template v-for="(i, index) in 2">
                            <a-sheet :key="'sheet_'+index">
                                <a-container container--fluid grid-list-xl pa-6>
                                    <a-layout align-center>
                                        <a-flex shrink xs4 xl4 class="u-cursor-pointer">
                                            <div class="u-flex">
                                                <loader-template height="40" width="25" class="u-rounded-corners"></loader-template>
                                                <div class="ml-3" style="width: 80%">
                                                    <loader-template height="20" width="100%" class="u-rounded-corners-lg"></loader-template>
                                                    <loader-template height="20" width="60%" class="u-rounded-corners-lg mt-2"></loader-template>
                                                </div>
                                            </div>
                                        </a-flex>
                                        <a-flex shrink xs3 xl3>
                                            <loader-template height="20" width="80%" class="u-rounded-corners-lg"></loader-template>
                                            <loader-template height="20" width="40%" class="u-rounded-corners-lg mt-2"></loader-template>
                                        </a-flex>
                                        <a-flex shrink xs3 xl3>
                                            <div class="u-flex">
                                                <loader-template height="36" width="36" class="u-rounded-corners-full"></loader-template>
                                                <div class="ml-3" style="width: 80%">
                                                    <loader-template height="20" width="90%" class="u-rounded-corners-lg"></loader-template>
                                                    <loader-template height="16" width="60%" class="u-rounded-corners-lg mt-2"></loader-template>
                                                </div>
                                            </div>
                                        </a-flex>
                                        <a-flex shrink xs3 xl3>
                                            <div class="u-flex">
                                                <loader-template height="36" width="36" class="u-rounded-corners-full"></loader-template>
                                                <div class="ml-3" style="width: 80%">
                                                    <loader-template height="20" width="90%" class="u-rounded-corners-lg"></loader-template>
                                                    <loader-template height="16" width="60%" class="u-rounded-corners-lg mt-2"></loader-template>
                                                </div>
                                            </div>
                                        </a-flex>
                                        <a-flex shrink xs1 xl1>
                                            <loader-template height="20" width="80%" class="u-rounded-corners-lg"></loader-template>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-sheet>
                            <a-divider :key="index"></a-divider>
                        </template>
                    </a-flex>
                </a-layout>
            </a-container>
        </template>
        <template v-if="!localPageLoading">
            <div class="pb-6">
                <p class="md-subtitle-2 grey--text text--darken-2 mb-0">
                    <a-icon size="20" color="purple darken-1" class="u-icon-nudge-xs mr-1">settings_suggest</a-icon>
                    Automation lets you perform an action automatically when its triggered.
                </p>
            </div>
            <a-container container--fluid grid-list-xl pa-0>
                <a-layout wrap align-center>
                    <a-flex shrink>
                        <PAutomationWorkflowFiltertDropdown
                            :type="'triggers'"
                            :list="automation_trigger_list"
                            :selectedList="selectedTriggers"
                            :filterType="filterLoading"
                            :isDropdown="triggerDropdown"
                            :hasFilter="localIsAutomationWorkflowFilter"
                            @filter="localAutomationWorkflowFilter"
                        />
                    </a-flex>
                    <a-flex shrink>
                        <a-btn text style="height: 40px !important;" class="u-flex-center-y u-cursor-pointer u-shadow px-3 py-1 white u-rounded-corners" @click="$router.push({ name: 'settings-automation-validations-logs', params: { type: 'automation' } })">
                            <a-icon size="20" color="grey darken-1" class="u-icon-nudge" style="top: 1px;">menu_open</a-icon>
                            <span class="md-body-2 grey--text text--darken-1 d-inline-block ml-2 font-weight-medium">View Logs</span>
                        </a-btn>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink v-if="localAutomationWorkflowPermission('store') && (localAutomationWorkflowList && localAutomationWorkflowList.length)">
                        <a-btn color="primary" class="mr-0" @click="localAutomationWorkflowCreate()">
                            <a-icon class="mr-1">add</a-icon> Create New Automation
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-container>
            <a-container container--fluid grid-list-xl pa-0 mt-6 v-if="localAutomationWorkflowList && localAutomationWorkflowList.length" :class="[ filterLoading ? 'u-opacity-60' : '']">
                <a-layout align-center class="u-rounded-corners pb-16 mb-16">
                    <a-flex pt-0>
                        <a-sheet class="u-overflow-hidden">
                            <a-container container--fluid grid-list-xl py-4 px-5>
                                <a-layout align-center>
                                    <a-flex shrink xs4 xl4 class="u-cursor-pointer">
                                        <h2 class="md-subtitle-1 font-weight-bold indigo--text text--darken-1 ml-9">Title</h2>
                                    </a-flex>
                                    <a-flex shrink xs3 xl3>
                                        <h2 class="md-subtitle-1 font-weight-bold indigo--text text--darken-1">Trigger</h2>
                                    </a-flex>
                                    <a-flex shrink xs3 xl3>
                                        <h2 class="md-subtitle-1 font-weight-bold indigo--text text--darken-1">Created by</h2>
                                    </a-flex>
                                    <a-flex shrink xs3 xl3>
                                        <h2 class="md-subtitle-1 font-weight-bold indigo--text text--darken-1">Last modified by</h2>
                                    </a-flex>
                                    <a-flex shrink xs2 xl2>
                                        <h2 class="text-center md-subtitle-1 font-weight-bold indigo--text text--darken-1">Status</h2>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-sheet>
                        <a-divider></a-divider>
                        <draggable v-model="localAutomationWorkflowList" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                            <div :class="{ 'js-draggable-file-list-item': localAutomationWorkflowPermission('update') }"  v-for="(item, index) in localAutomationWorkflowList" :key="item.id">
                                <a-sheet class="u-overflow-hidden u-shadow">
                                    <a-container container--fluid grid-list-xl pa-5 @click="localViewAutomationWorkflow(item.id)">
                                        <a-layout align-center class="u-cursor-pointer">
                                            <a-flex shrink xs4 xl4>
                                                <div class="u-flex">
                                                    <div class="mr-4">
                                                        <a-icon size="22" @click.stop="{}" color="grey lighten-1" class="mt-1 js-drag-handle u-cursor-pointer u-icon-nudge" style="top: -3px;" :class="{'u-opacity-30': !localAutomationWorkflowPermission('update')}">drag_indicator</a-icon>
                                                    </div>
                                                    <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-2" :title="item.title">{{ !item.title ? '-': item.title | truncateText(localIsLargedown ? 51 : 91) }}</h2>
                                                </div>
                                            </a-flex>
                                            <a-flex shrink xs3 xl3>
                                                <template v-if="item.automation_trigger">
                                                    <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-3" :title="item.automation_trigger.title">{{ item.automation_trigger.title | truncateText(localIsLargedown ? 27 : 37) }}</h2>
                                                    <div class="u-flex mt-1">
                                                        <a-icon size="20" color="blue accent-4">tune</a-icon>
                                                        <span class="mx-1 md-subtitle-2 grey--text" style="font-weight: 700;">{{ item.automation_condition_count }} <a-icon size="16" color="grey" style="font-weight: 700;" class="u-icon-nudge-xs">arrow_forward</a-icon></span>
                                                        <a-icon size="20" color="purple darken-1">settings_suggest</a-icon>
                                                        <span class="mx-1 md-subtitle-2 grey--text" style="font-weight: 700;">{{ item.automation_action_count }}</span>
                                                    </div>
                                                </template>
                                                <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-3" v-else>-</h2>
                                            </a-flex>
                                            <a-flex shrink xs3 xl3>
                                                <div class="u-flex" v-if="item.created_by">
                                                    <g-avatar :item="item.created_by"></g-avatar>
                                                    <div>
                                                        <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-2" :title="item.created_by.name">{{ item.created_by.name | truncateText(localIsLargedown ? 18 : 25) }}</h2>
                                                        <g-moment tag="span" :value="item.created_at" class="md-subtitle-2 grey--text" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                                                    </div>
                                                </div>
                                            </a-flex>
                                            <a-flex shrink xs3 xl3>
                                                <div class="u-flex" v-if="item.updated_by">
                                                    <g-avatar :item="item.updated_by"></g-avatar>
                                                    <div>
                                                        <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-2" :title="item.updated_by.name">{{ item.updated_by.name | truncateText(localIsLargedown ? 18 : 25) }}</h2>
                                                        <g-moment tag="span" :value="item.updated_at" class="md-subtitle-2 grey--text" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                                                    </div>
                                                </div>
                                            </a-flex>
                                            <a-flex shrink xs2 xl2 class="u-relative" @click.stop="{}">
                                                <div class="align-center justify-center u-flex" :style="[!localAutomationWorkflowPermission('update') ? { cursor: 'auto' } : '']">
                                                    <a-tooltip bottom :disabled="!localAutomationWorkflowPermission('update') || !localCheckToggleStateUpdate(item)">
                                                        <template v-slot:activator="{ on }">
                                                            <g-toggle
                                                                v-on="on"
                                                                v-model="item.is_active"
                                                                custom-bg dense-xl hide-clear-icon 
                                                                :disabledColor="item.is_active ? '#4CAF50' : '#d0d0d0'"
                                                                @change="localAutomationWorkflowToggleState(item.id, item.is_active ? 1 : 0)"
                                                                :disabled="!localAutomationWorkflowPermission('update') || localCheckToggleStateUpdate(item)"
                                                                class="mt-1"
                                                                color="green darken-1"
                                                            ></g-toggle>
                                                        </template>
                                                        <div class="pa-2 md-body-2">
                                                            Configure an automation to activate this<br>automation.
                                                        </div>
                                                    </a-tooltip>
                                                </div>
                                                <div class="u-absolute u-cursor-pointer" style="right: 9px;top: 10px;" v-if="localAutomationWorkflowPermission('update') || localAutomationWorkflowPermission('destroy')">
                                                    <a-menu left offset-y max-width="110" min-width="110">
                                                        <template v-slot:activator="{ on }">
                                                            <a-icon size="24" color="grey darken-1" class=""  v-on="on">more_vert</a-icon>
                                                        </template>
                                                        <a-list class="u-list-condensed u-overflow-y pa-0" v-if="localAutomationWorkflowPermission('update')">
                                                            <a-list-item @click="localViewAutomationWorkflow(item.id, 'edit')" class="pl-1">
                                                                <a-list-item-title class="pa-2">
                                                                    <span class="md-subtitle-2 grey--text text--darken-2">Edit</span>
                                                                </a-list-item-title>
                                                            </a-list-item>
                                                        </a-list>
                                                        <a-list class="u-list-condensed u-overflow-y pa-0" v-if="localAutomationWorkflowPermission('update')">
                                                            <a-list-item @click="localCloneAutomationWorkflow(item)" class="pl-1">
                                                                <a-list-item-title class="pa-2">
                                                                    <span class="md-subtitle-2 grey--text text--darken-2">Clone</span>
                                                                </a-list-item-title>
                                                            </a-list-item>
                                                        </a-list>
                                                        <a-divider></a-divider>
                                                        <a-list class="u-list-condensed u-overflow-y pa-0" v-if="localAutomationWorkflowPermission('destroy')">
                                                            <a-list-item @click="localDeleteAutomationWorkflow(item.id)" class="pl-1">
                                                                <a-list-item-title class="pa-2">
                                                                    <span class="md-subtitle-2 red--text text--darken-1">Delete</span>
                                                                </a-list-item-title>
                                                            </a-list-item>
                                                        </a-list>
                                                    </a-menu>
                                                </div>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-sheet>
                                <a-divider></a-divider>
                            </div>
                        </draggable>
                    </a-flex>
                </a-layout>
                <a-layout class="mx-0 c-sticky-paginate-parent u-elevation-custom-1 u-rounded-corners" v-if="automation_workflow_meta"  :class="{ 'pa-4': !localShowPaginateSection }">
                    <a-flex class="u-flex align-center py-0">
                        <div class="md-body-2 grey--text text--darken-2 font-weight-medium mr-2 d-inline-block">Showing {{ automation_workflow_meta.from }} - {{ automation_workflow_meta.to }} out of {{ automation_workflow_meta.total }} Workflow{{ automation_workflow_meta.total > 1 ? 's' : '' }}</div>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex class="mr-16 pr-16 py-0 u-flex align-center justify-end" v-if="localShowPaginateSection">
                        <a-progress-circular v-if="pageLoading" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                        <div class="text-right">
                            <a-pagination
                                v-model="automation_workflow_meta.current_page"
                                :length="automation_workflow_meta.last_page"
                                :total-visible="10"
                                class="c-inventory-pagination py-1"
                                @input="localWorkflowFetchPaginate()"
                            ></a-pagination>
                        </div>
                    </a-flex>
                </a-layout>
            </a-container>
        </template>
        <template v-if="!localPageLoading && localAutomationWorkflowList && localAutomationWorkflowList.length === 0">
            <a-container container--fluid grid-list-xl class="my-4">
                <a-layout align-center justify-center>
                    <a-flex xs4>
                        <img :src="require('../../assets/images/automation/list-empty.svg')" alt="list-empty" class="u-wfull" style="width: 70%; margin: 0px auto; display: block;">
                        <template v-if="localIsAutomationWorkflowFilter">
                            <h2 class="md-heading-6 mb-0 mt-4 font-weight-medium grey--text text--darken-3 text-center text-uppercase">No Automation is setup!</h2>
                            <p class="md-body-2 mb-0 mt-2 grey--text text--darken-1 text-center">You can configure an automation to automatically update a project.</p>
                        </template>
                        <template v-else>
                            <h2 class="md-heading-5 mb-0 mt-4 grey--text text--darken-3 text-center">No data found</h2>
                            <p class="md-body-2 mb-0 mt-2 grey--text text--darken-1 text-center">There is no data to fetch right now.Try narrowing your filter or add new automation.</p>
                        </template>
                        <div class="text-center mt-4" v-if="localIsAutomationWorkflowFilter && localAutomationWorkflowPermission('store')">
                            <a-btn color="primary" class="mr-0" @click="localAutomationWorkflowCreate()">
                                <a-icon class="mr-1">add</a-icon> Create New Automation
                            </a-btn>
                        </div>
                    </a-flex>
                </a-layout>
            </a-container>
        </template>
        <a-dialog max-width="360" v-model="dialogDeleteWorkflow" persistent>
            <a-card class="pa-6">
                <div class="mb-6">
                    <span class="md-heading-6 font-weight-medium grey--text text--darken-4">
                        Are you sure you want to delete this automation?
                    </span>
                    <p class="mb-0 mt-3 md-subtitle-1 grey--text text--darken-2">
                        This action cannot be undone.
                    </p>
                </div>
                <div class="u-flex-center-y">
                    <a-btn height="28" depressed class="red darken-1 u-rounded-corners text-uppercase" :loading="loadDeleteWorkflow"  @click="localAutomationWorkflowDestroy()">
                        <span class="md-caption text-uppercase white--text">
                            Yes, Delete
                        </span>
                    </a-btn>
                    <a-btn height="28" class="ml-4 u-rounded-corners text-uppercase" depressed :disabled="loadDeleteWorkflow" @click="dialogDeleteWorkflow = false">
                        <span class="md-caption text-uppercase">
                            No, Cancel
                        </span>
                    </a-btn>
                </div>
            </a-card>
        </a-dialog>
        <a-dialog max-width="740" v-model="dialogCreateWorkflowForm" scrollable>
            <ModalAutomationCreateWorkflow
                :templateId="selectedTemplateId"
                :templateList="automation_template_list"
                :createMode="createMode"
                @import-template="localSelectTemplate"
                @create-workflow="localCreateNewWorkflow"
                :triggerIcon="localGetTriggerIcon"
            />
        </a-dialog>
        <a-dialog max-width="640" v-model="dialogCloneWorkflowForm">
            <a-card>
                <a-sheet class="u-flex py-6 px-8">
                    <h2 class="body-4 font-weight-medium indigo--text text--darken-1">
                        <a-icon class="mr-1 u-icon-nudge" color="indigo darken-1" size="35" style="top: -3px;">content_copy</a-icon>
                        Clone Workflow
                    </h2>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <a-card-text class="pa-0">
                    <a-sheet class="py-6 px-8 c-custom-field-input">
                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium grey--text text--darken-1">Title<span class="red--text text--darken-2">*</span></label>
                        <a-text-field
                            v-model="automation_workflow_item.title"
                            placeholder="Clone Workflow Title"
                            background-color="grey lighten-5"
                            class="u-border"
                            solo flat hide-details autofocus
                        >
                        </a-text-field>
                        <span class="md-caption red--text text--darken-1 d-block mt-2" v-if="!automation_workflow_item.title && automation_workflow_response.server && automation_workflow_response.server.errors && automation_workflow_response.server.errors.title">
                            <a-icon size="16" color="red darken-2" class="u-icon-nudge">info</a-icon>
                            {{ automation_workflow_response.server.errors.title[0] }}
                        </span>
                        <span class="md-caption grey--text text--darken-1 d-block mt-2" v-else>
                            <a-icon size="16" class="u-icon-nudge">info</a-icon>
                            This will help you identify the workflow.
                        </span>
                    </a-sheet>
                    <a-divider class="grey lighten-3"></a-divider>
                    <a-sheet class="py-6 px-8">
                        <div class="u-flex">
                            <a-btn :loading="loadCloneWorkflow" class="indigo darken-1 white--text u-rounded-corners elevation-0" @click="localAutomationWorkflowClone()">Clone Workflow</a-btn>
                            <a-btn depressed :disabled="loadCloneWorkflow"  text class="ml-4 grey lighten-3 grey--text text--darken-1 u-rounded-corners" @click="dialogCloneWorkflowForm = false">
                                Cancel
                            </a-btn>
                        </div>
                    </a-sheet>
                </a-card-text>
            </a-card>
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PAutomationWorkflowFiltertDropdown from './Partials/PartialAutomationWorkflowFiltertDropdown'
import PAutomationListLoder from './Partials/PartialAutomationListLoder'
import ModalAutomationCreateWorkflow from './Modals/ModalAutomationCreateWorkflow'

export default {
    components: {
        PAutomationWorkflowFiltertDropdown,
        ModalAutomationCreateWorkflow,
        PAutomationListLoder,
    },
    data() {
        return {
            triggerDropdown: false,
            localPageLoading: false,
            dialogDeleteWorkflow: false,
            loadDeleteWorkflow: false,
            loadCreateWorkflow: false,
            loadCloneWorkflow: false,
            dialogCreateWorkflowForm: false,
            dialogCloneWorkflowForm: false,
            pageLoading: false,

            filterLoading: null,
            selectedDeleteId: null,
            selectedTemplateId: null,
            createMode: null,
            pageCount: null,

            selectedTriggers: [],

            triggers : [
                { type: "project-created", icon: 'add_box'},
                { type: "project-updated", icon: 'border_color'},
                { type: "project-status-updated", icon: 'donut_large'},
                { type: "project-upsert", icon: 'add_box'},
                { type: "project-stage-changed", icon: 'label_important'},
                { type: "project-completed", icon: 'task_alt'},
                { type: "project-archived", icon: 'archive'},
            ],

            automation_workflow_filters: {},
            AUTOMATION_TRIGGER_FILTERS: {
                TYPE: 'filter[type]',
                FIELDS: 'fields[automation_triggers]',
                SORT: 'sort',
                COUNT: 'count',
            },
            automation_trigger_filters: {},
            AUTOMATION_WORKFLOW_FILTERS: {
                TYPE: 'filter[type]',
                FIELDS: 'fields[automation_workflows]',
                INCLUDE: 'include',
                TRIGGER_IDS: 'filter[automation_trigger_id]',
                CREATED_BY: 'fields[createdBy]',
                UPDATED_BY: 'fields[updatedBy]',
                TRIGGER: 'fields[automationTrigger]',
                SORT: 'sort',
                COUNT: 'count',
                PAGE: 'page',
            },
            automation_template_filters: {},
            AUTOMATION_TEMPLATE_FILTERS: {
                TYPE: 'filter[type]',
                FIELDS: 'fields[automation_templates]',
                COUNT: 'count',
            },
        }
    },

    mounted() {
        this.localIndex()
    },

    computed: {
        localIsLargedown () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        localCheckCreateWorkflow () {
            return !(this.automation_workflow_item && this.automation_workflow_item.title && this.automation_workflow_item.title.trim() &&  this.automation_workflow_item.automation_trigger_id || this.selectedTemplateId)
        },

        localIsAutomationWorkflowFilter () {
            return !(this.selectedTriggers && this.selectedTriggers.length)
        },

        localAutomationWorkflowList: {
            get() {
                return this.$store.state.AutomationWorkflow.list
            },
            set(list) {
                this.localAutomationWorkflowReorder(list)
            }
        },

        localShowPaginateSection () {
            return this.automation_workflow_meta && this.automation_workflow_meta.current_page >= 1 && this.automation_workflow_meta.last_page !== 1 && this.automation_workflow_meta.total >= 10
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('AutomationWorkflow', {
            automation_workflow_list: 'list',
            automation_workflow_item: 'item',
            automation_workflow_response: 'response',
            automation_workflow_meta: 'meta',
        }),

        ...mapState('AutomationTrigger', {
            automation_trigger_list: 'list',
        }),

        ...mapState('AutomationTemplate', {
            automation_template_list: 'list',
        }),
    },

    methods: {
        async localIndex () {
            this.localPageLoading = true
            await this.automation_workflow_clear_item()
            await this.automation_workflow_clear_list()
            await this.automation_trigger_clear_list()
            this.localLoadAutomationTriggerList()
            this.localLoadAutomationTemplateList()
            await this.localLoadAutomationWorkflowList()
            this.localPageLoading = false
        },

        localCheckToggleStateUpdate (item) {
            return (item.automation_trigger_id === null || item.automation_action_count === 0)
        },

        async localLoadAutomationWorkflowList () {
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.FIELDS] = 'id,title,is_active,order,automation_trigger_id,updated_by_id,created_by_id,created_at,updated_at'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.INCLUDE] = 'automationTrigger,createdBy,updatedBy,automationConditionCount,automationActionCount'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.CREATED_BY] = 'id,name,avatar,initial,color'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.UPDATED_BY] = 'id,name,avatar,initial,color'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.TRIGGER] = 'id,title'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.TYPE] = 'action'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.COUNT] =  100
            await this.automation_workflow_index(this.automation_workflow_filters)
        },

        localViewAutomationWorkflow (id,) {
            if (this.filterLoading) return
            
            this.$router.push({name: 'settings-automations-edit', params: {id: id} })
        },

        async localLoadAutomationTriggerList () {
            this.automation_trigger_filters[this.AUTOMATION_TRIGGER_FILTERS.FIELDS] = 'id,title,slug'
            this.automation_trigger_filters[this.AUTOMATION_TRIGGER_FILTERS.TYPE] = 'action'
            this.automation_trigger_filters[this.AUTOMATION_TRIGGER_FILTERS.COUNT] =  100
            await this.automation_trigger_index(this.automation_trigger_filters)
        },

        async localLoadAutomationTemplateList () {
            this.automation_template_filters[this.AUTOMATION_TEMPLATE_FILTERS.FIELDS] = 'id,type,name,description,slug'
            this.automation_template_filters[this.AUTOMATION_TEMPLATE_FILTERS.COUNT] =  100
            this.automation_template_filters[this.AUTOMATION_TEMPLATE_FILTERS.TYPE] =  'action'
            await this.automation_template_index(this.automation_template_filters)
        },

        async localAutomationWorkflowFilter (trigger_id = null) {
            await this.$vuetify.goTo(0)
            this.filterLoading = 'triggers'
            if (!trigger_id) {
                this.selectedTriggers = []
                this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.TRIGGER_IDS] = null
                this.localLoadAutomationWorkflowList()
                this.triggerDropdown = false
                this.filterLoading = null
                return 
            } 

            if (this.selectedTriggers && !this.selectedTriggers.length) this.selectedTriggers.push(trigger_id)
            else {
                const index = this.selectedTriggers.findIndex(id => id === trigger_id)
                if (index !== -1) this.selectedTriggers.splice(index, 1)
                else this.selectedTriggers.push(trigger_id)
            }
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.TRIGGER_IDS] =  this.selectedTriggers
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.PAGE] = 1
            await this.localLoadAutomationWorkflowList()
            this.filterLoading = null
        },

        localGetAutomationTriggerTitle (id) {
            const trigger = this.automation_trigger_list.find(item => item.id === id)
            return trigger ? trigger.title : ''
        },

        localAutomationTriggerIsSelected (id) {
            return this.selectedTriggers.includes(id)
        },

        async localAutomationWorkflowCreate () {
            await this.automation_workflow_clear_item()
            this.loadCreateWorkflow = false
            this.createMode = null
            this.selectedTemplateId = null
            this.dialogCreateWorkflowForm = true
        },

        async localAutomationWorkflowClose () {
            this.dialogCreateWorkflowForm = false
        },

        async localAutomationWorkflowStore () {
            this.loadCreateWorkflow = true
            if (this.createMode === 'create') {
                this.automation_workflow_item.type = 'action'
                this.automation_workflow_item.title = 'Untitled Automation'
                await this.automation_workflow_store(this.automation_workflow_item)
            }
            if (this.createMode === 'template') {
                await this.automation_workflow_template_store({automation_template_id: this.selectedTemplateId })
            }
            this.loadCreateWorkflow = false
            if (!this.$status(this.automation_workflow_response)) return
            this.dialogCreateWorkflowForm = false
            const workflow_id = this.automation_workflow_item.id
            await this.automation_workflow_clear_item()
            this.localViewAutomationWorkflow(workflow_id, 'edit')
        },

        async localCreateNewWorkflow (mode) {
            this.createMode = mode
            this.automation_workflow_item.type = 'action'
            this.automation_workflow_item.title = 'Untitled Automation'
            await this.automation_workflow_store(this.automation_workflow_item)
            this.createMode = null
            if (!this.$status(this.automation_workflow_response)) return
            this.dialogCreateWorkflowForm = false
            const workflow_id = this.automation_workflow_item.id
            await this.automation_workflow_clear_item()
            this.localViewAutomationWorkflow(workflow_id, 'edit')
        },

        async localSelectTemplate (id) {
            this.selectedTemplateId = id
            await this.automation_workflow_template_store({automation_template_id: this.selectedTemplateId })
            if (!this.$status(this.automation_workflow_response)) return
            this.dialogCreateWorkflowForm = false
            const workflow_id = this.automation_workflow_item.id
            await this.automation_workflow_clear_item()
            this.localViewAutomationWorkflow(workflow_id, 'edit')
            this.selectedTemplateId = null
        },

        async localCloneAutomationWorkflow (item) {
            await this.automation_workflow_clear_item()
            this.automation_workflow_item.title = 'Clone of '+item.title
            this.automation_workflow_item.automation_workflow_id = item.id
            this.dialogCloneWorkflowForm = true
        },

        async localAutomationWorkflowClone () {
            this.loadCloneWorkflow = true
            await this.automation_workflow_clone_store(this.automation_workflow_item)
            this.loadCloneWorkflow = false
            if (!this.$status(this.automation_workflow_response)) return
            await this.localShowAutomationWorkflow(this.automation_workflow_item.id, 'add')
            await this.localLoadAutomationWorkflowList()
            this.$notify('success', 'Automation Cloned Successfully!')
            this.dialogCloneWorkflowForm = false
        },

        localDeleteAutomationWorkflow (id) {
            this.selectedDeleteId = id
            this.dialogDeleteWorkflow = true
        },

        async localAutomationWorkflowDestroy () {
            this.loadDeleteWorkflow = true
            await this.automation_workflow_destroy({id: this.selectedDeleteId})
            if (_.size(this.local_automation_workflow_list) === 0) {
                this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.PAGE] = this.automation_workflow_meta.current_page - 1
                await this.localLoadAutomationWorkflowList()
            } 
            else this.localLoadAutomationWorkflowList()
            if (!this.$status(this.automation_workflow_response)) return
            this.dialogDeleteWorkflow = false
            this.loadDeleteWorkflow = false
        },

        async localAutomationWorkflowToggleState (id, state) {
            await this.automation_workflow_toggle_state({id: id, state: state})
            if (!this.$status(this.automation_workflow_response)) return
            await this.localShowAutomationWorkflow(id, 'show')
            this.automation_workflow_clear_item()
            this.$notify('success', state === 1 ? 'Automation Enabled Successfully!' : 'Automation Disabled Successfully!')
        },

        async localAutomationWorkflowReorder (list) {
            await this.automation_workflow_reorder(list)
        },

        async localShowAutomationWorkflow (id, mode) {
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.FIELDS] = 'id,title,description,is_active,order,automation_trigger_id,updated_by_id,created_by_id,created_at,updated_at'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.INCLUDE] = 'automationTrigger,createdBy,updatedBy,automationConditionCount,automationValidationCount,automationActionCount'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.CREATED_BY] = 'id,name,avatar,initial,color'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.UPDATED_BY] = 'id,name,avatar,initial,color'
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.TRIGGER] = 'id,title,slug'
            await this.automation_workflow_show({id: id, mode : mode, params: this.automation_workflow_filters })
        },

        localGetTriggerIcon (type) {
            const trigger = this.triggers.find(item => item.type && item.type === type)
            return trigger ? trigger.icon : null
        },

        localCheckSelectTemplate (id) {
            return (this.createMode = 'template' && this.selectedTemplateId == id)
        },

        // Permission
        localAutomationWorkflowPermission (type) {
            return this.$can(`automation-workflows.${type}`)
        },

        // Pagination
        async localWorkflowFetchPaginate () {
            if(this.pageCount == this.automation_workflow_meta.current_page) return
            this.pageLoading = true
            await this.$vuetify.goTo(0)
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.PAGE] = this.automation_workflow_meta.current_page
            this.automation_workflow_filters[this.AUTOMATION_WORKFLOW_FILTERS.COUNT] = this.automation_workflow_meta.per_page
            await this.localLoadAutomationWorkflowList()
            this.pageCount = this.automation_workflow_meta.current_page
            this.pageLoading = false
        },
        
        ...mapActions('AutomationWorkflow', {
            automation_workflow_index: 'index',
            automation_workflow_store: 'store',
            automation_workflow_show: 'show',
            automation_workflow_template_store: 'template_store',
            automation_workflow_clone_store: 'clone_store',
            automation_workflow_destroy: 'destroy',
            automation_workflow_reorder:'reorder',
            automation_workflow_update: 'update',
            automation_workflow_toggle_state: 'toggle_state',
            automation_workflow_clear_item: 'clear_item',
            automation_workflow_clear_response: 'clear_response',
            automation_workflow_clear_list: 'clear_list',
        }),

        ...mapActions('AutomationTemplate', {
            automation_template_index: 'index',
            automation_template_clear_list: 'clear_list',
        }),

        ...mapActions('AutomationTrigger', {
            automation_trigger_index: 'index',
            automation_trigger_clear_list: 'clear_list',
        }),
    },
}
</script>

<style scoped>
.c-create-btn.v-btn.v-btn--disabled {
    opacity: 0.5;
    background-color: #3949AB !important;
    color: #fff !important;
}
.c-create-icon {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
}
.c-tigger-template-icon {
    width: 48px !important;
    height: 48px !important;
}
.c-active-select {
    border: 1px solid #3949AB !important;
    background: #FFFFFF !important;
}
.c-default-select {
    border: 1px solid #EEEEEE !important;
    background: #FAFAFA !important;
}
.c-stage-btn.v-btn.v-btn--disabled {
    opacity: 0.6;
}
</style>


const api_endpoint = '/workflows';

export default {
    namespaced: true,
    state: {
        list: [],
        jira_project_list: [],
        jira_field_list: [],
        jira_invalid_field_list: [],
        item: {},
        meta: {},
        filters: {
            page: 1,
            count: 10,
            sort_field: 'order',
            sort_direction: 'desc'
        },
        response: [],
        defaults: {
            list: [],
            jira_project_list: [],
            jira_field_list: [],
            jira_invalid_field_list: [],
            item: {},
            meta: {},
            filters: {
                page: 1,
                count: 10,
                sort_field: 'order',
                sort_direction: 'desc'
            },
            response: [],
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_JIRA_PROJECT_LIST(state, items) {
            state.jira_project_list = items;
        },
        UPDATE_JIRA_FIELD_LIST(state, items) {
            state.jira_field_list = items;
        },
        UPDATE_JIRA_INVALID_FIELD_LIST(state, items) {
            state.jira_invalid_field_list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_WORKFLOW_STATE(state, item) {
            state.item.is_active = item.state;
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload.item;
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        ADD_ITEM(state, item) {
            state.list.push(item);
        },
        CLONE_ITEM(state,item){
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        REFRESH_ITEM(state) {
            let item_temp = _.cloneDeep(state.item);
            state.item = [];
            state.item = item_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint, { params: payload })
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        jira_project_index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/jira-projects/' + payload.id)
                .then((response) => {
                    context.commit('UPDATE_JIRA_PROJECT_LIST', response.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        jira_project_migrations_index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/jira-project-migrations/' + payload.id, { params: payload })
                .then((response) => {
                    context.dispatch('clear_jira_field');
                    context.commit('UPDATE_JIRA_FIELD_LIST', response.data.jira_field_list);
                    context.commit('UPDATE_JIRA_INVALID_FIELD_LIST', response.data.invalid_field_list);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            const params = (payload && payload.params) ?? { 'include': 'installation,trigger.service,action.service' }
            return axios.get(api_endpoint + '/' + payload.id, { params })
                .then((response) => {
                    if (payload && payload.mode === 'add') context.commit('ADD_ITEM', response.data.data)
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        toggle_state(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/states/is-active', { state: payload.state })
                .then((response) => {
                    context.commit('UPDATE_WORKFLOW_STATE', { state: parseInt(payload.state) });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, payload)
                .then(async (response) => {
                    await context.dispatch('show', { mode: 'add', id: payload.id })
                    // context.commit('ADD_ITEM', response.data.data)
                    // context.commit('UPDATE_ITEM', response.data.data)
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        setup(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/setup', payload)
                .then(async (response) => {
                    await context.dispatch('show', { id: payload.id })
                    // context.commit('UPDATE_ITEM', response.data.data)
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        patch(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + context.state.item.id, payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        clone_store(context,payload){
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/clone',payload)
                .then(async (response) => {
                    await context.dispatch('show', { mode: 'add', id: payload.id })
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        clear_jira_field(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_JIRA_FIELD_LIST', _.cloneDeep(context.state.defaults.jira_field_list));
                context.commit('UPDATE_JIRA_INVALID_FIELD_LIST', _.cloneDeep(context.state.defaults.jira_invalid_field_list));
                resolve('Cleared');
            });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_deleted_fields(context) {
            const workflowItem = _.cloneDeep(context.state.item)
            workflowItem.is_condition_source_deleted = 0
            workflowItem.recent_deleted_sources = null
            context.commit('UPDATE_ITEM', workflowItem)
        }
    },
}

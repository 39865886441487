import { render, staticRenderFns } from "./TabInventoryMeta.vue?vue&type=template&id=e0a1177e"
import script from "./TabInventoryMeta.vue?vue&type=script&lang=js"
export * from "./TabInventoryMeta.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
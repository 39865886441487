<template>
    <div class="c-summary-tile px-5 pt-5 u-relative u-hfull">
        <div v-if="mixLoading" class="u-overflow-hidden u-hfull mb-8">
            <loader-competitors-summary :mini="mixParentState.isConfScoreActive" />
        </div>

        <template v-else>
            <template v-if="localIsEmpty || !mixHasPermission('competitors.index')">
                <partial-empty-state-summary
                    :canView="mixHasPermission('competitors.index')"
                    title="Competitors"
                    icon="domain"
                    modelSlug="competitors"
                    class="pb-4"
                    iconSize="26"
                    iconOutlined
                />
            </template>

            <div v-else class="u-overflow-hidden u-hfull" ref="refParent">
                <div v-for="(compType, index) in competitorTypes" :key="compType.id" :class="[{ 'mt-2': index !== 0 }]">
                    <h4 class="md-body-2 font-weight-medium grey--text text--darken-1">{{ compType.value }}</h4>
                    <template v-if="!localGetCompFromType(compType.id, 'length')">
                        <span class="md-caption grey--text">No Competitors</span>
                    </template>
                    <template v-else>
                        <template v-for="competitor in localGetCompFromType(compType.id)">
                            <SCompetitorTag
                                :key="competitor.id + '-' + compType.id"
                                :tag="competitor"
                                :canUpdate="false"
                                :textRange="20"
                                height="24"
                                class="md-caption"
                                style="cursor: text"
                                hideClearIcon
                            />
                        </template>
                    </template>
                </div>
            </div>

            <partial-bottom-gradient
                v-if="showExpand"
                :height="noFetch ? 90 : 48"
                flat
            />
        </template>

        <partial-project-summary-actions
            v-if="mixHasPermission('competitors.index') && !mixLoading"
            :showExpand="noFetch ? false : showExpand"
            @expand="$emit('expand')"
            @open="mixOpenInNewWindow('projects-overview')"
            :showActions="noFetch"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MixinModalStateInjection from '../Mixins/MixinModalStateInjection'
import PartialProjectSummaryActions from './PartialProjectSummaryActions'
import { SCompetitorTag } from '@/config/config-shared-components'
import PartialBottomGradient from './PartialBottomGradient.vue'
import PartialEmptyStateSummary from './PartialEmptyStateSummary.vue'
import LoaderCompetitorsSummary from '../Loaders/LoaderCompetitorsSummary.vue'

export default {
    components: {
        PartialProjectSummaryActions,
        SCompetitorTag,
        PartialBottomGradient,
        PartialEmptyStateSummary,
        LoaderCompetitorsSummary
    },

    mixins: [MixinModalStateInjection],

    props: {
        noFetch: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            showExpand: false,
            competitorTypes: [],
            competitorsList: [],
        }
    },

    computed: {
        localIsEmpty () {
            return !_.size(this.competitorTypes)
        },

        ...mapState('Meta', {
            meta_list: 'list'
        }),
    },

    methods: {
        async localIndex () {
            if (!this.mixHasPermission('competitors.index')) return
            if (this.noFetch || this.mixLatestSyncTimeDiff() >= 5) {
                return this.localNoFetchSetup()
            }

            this.mixSetLoading()
            await this.localGetCompetitorTypes()
            await this.localGetCompetitors()
            this.mixSaveProject({ competitorTypes: this.competitorTypes, competitors: this.competitorsList })
            this.mixClearLoading()
            this.localSetupAction()
        },

        localNoFetchSetup () {
            this.competitorTypes = this.mixGetSavedSummary.competitorTypes
            this.competitorsList = this.mixGetSavedSummary.competitors
            this.localSetupAction()
        },

        localSetupAction () {
            if (this.localIsEmpty || this.mixLoading) return

            this.$nextTick(() => {
                const childLength = _.size(this.$refs.refParent.children) > 0 ? _.size(this.$refs.refParent.children) - 1 : 1
                const childContainerHeight = [...this.$refs.refParent.children].reduce((p, c) => p = p + c.clientHeight, 0)
                // 8 -> margin top for all child elements except first element
                this.showExpand = (childContainerHeight + (childLength * 8)) > this.$refs.refParent.parentElement.clientHeight - 16
            })
        },

        async localGetCompetitorTypes () {
            await this.meta_index({
                'filter[project_id]': this.mixParentState.projectId,
                'filter[type]': 'competitor_type',
                'fields[metas]': 'id,value,status,selection_type',
                'sort': 'order'
            })
            this.competitorTypes = _.cloneDeep(this.meta_list)
        },

        async localGetCompetitors () {
            const params = { include: 'competitors', 'fields[projects]': 'id' }
            const { data: { competitors }, status } = await this.project_show({ id: this.mixParentState.projectId, stateless: true, params })
            this.competitorsList = status !== 'success' ? [] : _.cloneDeep(competitors)
        },

        localGetCompFromType (compTypeId, field = null) {
            const list = this.competitorsList.filter(i => i.category_id === compTypeId)
            if (field === 'length') return _.size(list)

            return list
        },

        ...mapActions('Meta', {
            meta_index: 'index'
        }),

        ...mapActions('ProjectView', {
            project_show: 'show',
            project_clear_item: 'clear_item'
        }),

        ...mapActions('Competitor', {
            competitor_index: 'index',
        }),
    }
}
</script>

<style lang="scss" scoped>

</style>

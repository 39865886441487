<template>
    <a-main>
        <template v-if="isLoading">
           <PValidationWorkflowLoader/>
        </template>
        <template v-if="!isLoading">
            <div class="u-flex pa-6">
                <div class="c-validation-icon-view">
                    <div class="align-center justify-center u-flex c-validation-menu-icon pink" style="border: 2px solid #F8BBD0 !important;">
                        <a-icon color="white" size="20">fact_check</a-icon>
                    </div>
                </div>
                <div class="ml-3">
                    <p class="md-subtitle-1 text-uppercase indigo--text text--darken-1 mb-0 font-weight-bold">Then: Validate</p>
                    <p class="md-body-2 grey--text text--darken-1 mb-0">Validation verifies if all rules are passed.</p>
                </div>
            </div>
            <a-divider class="grey lighten-3"></a-divider>
            <template v-if="validationList && validationList.length">
                <div class="px-6">
                    <div class="mb-6 mt-6 u-flex">
                        <div v-if="validationMessageType === 'validation_rules'">
                            <p class="text-sm-body-1 font-weight-medium grey--text text--darken-4 pa-0 ma-0" style="font-weight: 600 !important;">Rules</p>
                            <p class="body-2 d-inline-block font-weight-medium grey--text text--darken-1 pa-0 ma-0">Rules are specific directives to determine an action.</p>
                        </div>
                        <div v-if="validationMessageType === 'validation_messages'">
                            <p class="text-sm-body-1 font-weight-medium grey--text text--darken-4 pa-0 ma-0" style="font-weight: 600 !important;">Messages</p>
                            <p class="body-2 d-inline-block font-weight-medium grey--text text--darken-1 pa-0 ma-0">Messages will be displayed when the validation fails.
                                <span class="indigo--text text--darken-1 u-cursor-pointer" @click="localSelectPreviewView()">Preview Validation Failed Pop-up</span>
                            </p>
                        </div>
                        <a-spacer></a-spacer>
                        <p class="text-sm-body-1 d-inline-block blue--text pa-0 ma-0 u-cursor-pointer" @click="validationMessageType !== 'validation_rules' ? $emit('update-message-type', 'validation_rules') : localSelectPreviewView()">
                            <span class="u-icon-nudge"><a-icon size="20" color="blue" class="mr-1">{{  validationMessageType === 'validation_rules'  ? 'visibility' : 'fact_check' }}</a-icon></span>
                            {{  validationMessageType === 'validation_rules'  ? 'Preview Validation Failed Pop-up' : 'Edit Rules' }}
                        </p>
                    </div>
                    <div v-if="localCheckErrorInfo" class="mb-6 mt-6">
                        <PValidationWorkflowWarningInfo
                            :errorMessage="errorMessage"
                        />
                    </div>
                    <div class="mt-6 u-wfull">
                        <template v-if="validationMessageType === 'validation_rules'">
                            <div v-for="(item, group_index) in validationList" :key="item.group_id">
                                <a-sheet v-if="group_index !== 0" height="1" class="u-wfull u-relative my-7 c-validation-or-shreet">
                                    <span class="u-absolute md-body-2 font-weight-medium body--text text--lighten-2 d-inline-block c-validation-or-card">OR</span>
                                </a-sheet>
                                <template v-for="(validation, index) in item.validations">
                                    <a-layout align-start :key="validation.id">
                                        <a-flex shrink>
                                            <div class="align-center justify-center u-flex c-validation-status" :class="localCheckValidationStatus(validation, 'class')">
                                                <a-icon :color="localCheckValidationStatus(validation, 'icon_color')"  size="18">{{ localCheckValidationStatus(validation, 'icon') }}</a-icon>
                                            </div>
                                        </a-flex>
                                        <a-flex shrink xs4 xl4 class="validation-editor-title">
                                            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(validation, 'field_name')">
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <v-autocomplete
                                                            v-model="validation.field_name"
                                                            :items="validationEntityList"
                                                            item-text="label"
                                                            item-value="name"
                                                            placeholder="Select Field"
                                                            :disabled="!canUpdate"
                                                            class="pa-0 u-rounded-corners-lg"
                                                            :class="[!validation.field_name && localIsCheckErrors(validation, 'field_name') ? 'c-wiggle-short u-border-error' : ' u-border']"
                                                            item-color="indigo darken-2"
                                                            background-color="grey lighten-5"
                                                            @change="$emit('update-related-source', validation)"
                                                            key="property-field"
                                                            solo flat hide-details
                                                        >
                                                            <template #item="{ item }">
                                                                <div class="u-flex-center-y">
                                                                    <span class="md-subtitle-2 grey--text text--darken-1">{{ item.label }}</span>
                                                                </div>
                                                            </template>
                                                        </v-autocomplete>
                                                    </div>
                                                </template>
                                                <span>{{ localGetMessageErrors(validation, 'field_name') }}</span>
                                            </a-tooltip>
                                        </a-flex>
                                        <a-flex shrink xs4 xl4 class="validation-editor-title">
                                            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(validation, 'operator') && !hasCustomFieldTextInfoCheck(validation)">
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <v-autocomplete
                                                            v-model="validation.operator"
                                                            :items="localSelectedRule(validation.field_name)"
                                                            item-text="label"
                                                            item-value="key"
                                                            placeholder="Choose the rule"
                                                            class="pa-0 u-rounded-corners-lg"
                                                            :disabled="!canUpdate"
                                                            :class="[
                                                                hasCustomFieldTextInfoCheck(validation) ? 'u-border-disable' : 'u-border',
                                                                { 'c-wiggle-short u-border-error': !validation.operator && localIsCheckErrors(validation, 'operator') }
                                                            ]"
                                                            item-color="indigo darken-2"
                                                            background-color="grey lighten-5"
                                                            key="property-field"
                                                            @change="$emit('auto-fill-message', validation)"
                                                            solo flat hide-details
                                                        >
                                                            <template #item="{ item }">
                                                                <div class="u-flex-center-y">
                                                                    <span class="md-subtitle-2 grey--text text--darken-1">{{ item.label }}</span>
                                                                </div>
                                                            </template>
                                                            <template #append>
                                                                <a-icon v-if="hasCustomFieldTextInfoCheck(validation)" size="20" color="amber darken-3" class="mr-2">info</a-icon>
                                                                <a-icon size="20" color="grey darken-2" class="mr-1" v-if="!validation.validation_opertor">arrow_drop_down</a-icon>
                                                                <a-icon size="18" color="grey darken-2" class="mr-1" v-else>clear</a-icon>
                                                            </template>
                                                        </v-autocomplete>
                                                    </div>
                                                </template>
                                                <span>{{ hasCustomFieldTextInfoCheck(validation) ? customFieldTextInfo :  localGetMessageErrors(validation, 'operator') }}</span>
                                            </a-tooltip>
                                        </a-flex>
                                        <a-flex shrink xs4 xl4 class="validation-editor-title">
                                            <template v-if="noRequiredRules.includes(validation.operator)">
                                                <a-sheet class="grey lighten-4 body--text text--lighten-3 px-3 u-flex-center-y u-rounded-corners-lg u-border" height="48">
                                                    No value required
                                                </a-sheet>
                                            </template>
                                            <template v-else>
                                                <template v-if="localSelectedFieldType(validation.field_name) === 'date'">
                                                    <a-layout align-start>
                                                        <a-flex shrink xs12 xl12>
                                                            <a-sheet class="u-wfull u-rounded-corners-lg u-flex-center-y u-relative pr-2 mr-2" :class="[localIsCheckErrors(validation, 'value') ? 'c-wiggle-short u-border-error' : 'u-border']" style="background: #FAFAFA;">
                                                                <vc-date-picker v-if="canUpdate" v-model="validation.value" :max-date="validation.value_alt" @input="$emit('change-value')" class="d-block u-wfull" :model-config="modelConfig" :is-required="!!(validation.value)">
                                                                    <template v-slot="{ togglePopover }">
                                                                        <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(validation, 'value')">
                                                                            <template v-slot:activator="{ on }">
                                                                                <div v-on="on" class="u-wfull u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="togglePopover({ placement: 'bottom-start' })">
                                                                                    <div class="md-body-2 grey--text pl-3 u-flex-center-y" :class="[validation.value ? 'text--darken-4' : 'text--darken-5']" style="font-size: 16px;min-height: 44px !important;">
                                                                                        <span>{{ validation.value ? localFormatDate(validation.value) : 'Value' }}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </template>
                                                                            <span>{{ localGetMessageErrors(validation, 'value') }}</span>
                                                                        </a-tooltip>
                                                                    </template>
                                                                </vc-date-picker>
                                                                <div v-else class="u-wfull u-flex align-stretch u-rounded-corners-lg grey--text text--darken-2" style="opacity: 0.5;">
                                                                    <div class="md-body-2 grey--text pl-3 u-flex-center-y" :class="[validation.value ? 'text--darken-4' : 'text--darken-5']" style="font-size: 16px; min-height: 48px !important;">
                                                                        <span>{{ validation.value ? localFormatDate(validation.value) : 'Value' }}</span>
                                                                    </div>
                                                                </div>
                                                            </a-sheet>
                                                        </a-flex>
                                                        <a-flex shrink xs12 xl12 v-if="validation.operator === 'between'">
                                                            <a-sheet class="u-wfull u-rounded-corners-lg u-flex-center-y u-relative pr-2 mr-2" :class="[localIsCheckErrors(validation, 'value_alt') ? 'c-wiggle-short u-border-error' : 'u-border']" style="background: #FAFAFA;">
                                                                <vc-date-picker v-if="canUpdate" v-model="validation.value_alt" :min-date="validation.value" @input="$emit('change-value')" class="d-block u-wfull" :model-config="modelConfig" :is-required="!!(validation.value_alt)">
                                                                    <template v-slot="{ togglePopover }">
                                                                        <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(validation, 'value_alt')">
                                                                            <template v-slot:activator="{ on }">
                                                                                <div v-on="on" class="u-wfull u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="togglePopover({ placement: 'bottom-start' })">
                                                                                    <div class="md-body-2 grey--text pl-3 u-flex-center-y" :class="[validation.value_alt ? 'text--darken-4' : 'text--darken-5']" style="font-size: 16px;min-height: 44px !important;">
                                                                                        <span>{{ validation.value_alt ? localFormatDate(validation.value_alt) : 'Value' }}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </template>
                                                                            <span>{{ localGetMessageErrors(validation, 'value_alt') }}</span>
                                                                        </a-tooltip>
                                                                    </template>
                                                                </vc-date-picker>
                                                                <div v-else class="u-wfull u-flex align-stretch u-rounded-corners-lg grey--text text--darken-2" style="opacity: 0.5;">
                                                                    <div class="md-body-2 grey--text pl-3 u-flex-center-y" :class="[validation.value_alt ? 'text--darken-4' : 'text--darken-5']" style="font-size: 16px; min-height: 48px !important;">
                                                                        <span>{{ validation.value_alt ? localFormatDate(validation.value_alt) : 'Value' }}</span>
                                                                    </div>
                                                                </div>
                                                            </a-sheet>
                                                        </a-flex>
                                                    </a-layout>
                                                </template>
                                                <template v-else-if="validation.operator && ['is','not-equals','includes','excludes'].includes(validation.operator) && (localSelectedFieldType(validation.field_name) === 'select' || localSelectedFieldType(validation.field_name) === 'multi-select')">
                                                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(validation, 'value')">
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on">
                                                                <v-autocomplete
                                                                    v-model="validation.value"
                                                                    :items="localGetValidationValueList(validation.field_name)"
                                                                    item-text="name"
                                                                    item-value="id"
                                                                    placeholder="Select Value"
                                                                    background-color="grey lighten-5"
                                                                    class="pa-0 u-rounded-corners-lg"
                                                                    :disabled="!canUpdate"
                                                                    :class="[!validation.value && localIsCheckErrors(validation, 'value') ? 'c-wiggle-short u-border-error' : ' u-border']"
                                                                    item-color="indigo darken-2"
                                                                    @input="$emit('change-value')"
                                                                    solo flat hide-details
                                                                >
                                                                    <template #item="{ item }">
                                                                        <template v-if="item.type === 'competitor'">
                                                                            <span class="md-caption d-inline-block py-1 px-2 mr-2 mt-1 mb-1 u-rounded-corners u-cursor-pointer" :style="{backgroundColor: '#2196f3', color: '#fff'}">
                                                                                {{ item.name | truncateText(25) }}
                                                                            </span>
                                                                        </template>
                                                                        <template v-else-if="item.initial && item.role_id">
                                                                            <div class="u-flex-center-y">
                                                                                <g-avatar :item="item" :size="32"></g-avatar>
                                                                                <g-profile-info :item="item" set-max-width type-user></g-profile-info>
                                                                            </div>
                                                                        </template>
                                                                        <template v-else-if="item.type === 'tags' || item.type === 'territory'">
                                                                            <g-tags :text-range="70" :tag="item" :key="item.id" hide-clear-icon></g-tags>
                                                                        </template>
                                                                        <span :title="item.name" v-else>{{ item.name | truncateText(70) }}</span>
                                                                    </template>
                                                                    <template #selection="{ item }">
                                                                        <template v-if="item.type === 'competitor'">
                                                                            <span :title="item.name" class="md-caption d-inline-block py-1 px-2 mr-2 mt-1 mb-1 u-rounded-corners u-cursor-pointer" :style="{backgroundColor: '#2196f3', color: '#fff'}">
                                                                                {{ item.name | truncateText(14) }}
                                                                            </span>
                                                                        </template>
                                                                        <template  v-else-if="item.initial && item.role_id">
                                                                            <span class="d-inline-flex align-center u-rounded-corners grey lighten-2 py-1 px-2 mr-2 mt-1 mb-1">
                                                                                <span class="mr-2 md-body-2 grey--text text--darken-4" :title="item.name">{{ item.name || truncateText(14) }}</span>
                                                                            </span>
                                                                        </template>
                                                                        <template v-else-if="item.type === 'tags' || item.type === 'territory'">
                                                                            <g-tags :text-range="25" :tag="item" :key="item.id" hide-clear-icon></g-tags>
                                                                        </template>
                                                                        <span :title="item.name" v-else>{{ item.name | truncateText(20) }}</span>
                                                                    </template>
                                                                </v-autocomplete>
                                                            </div>
                                                        </template>
                                                        <span>{{ localGetMessageErrors(validation, 'value') }}</span>
                                                    </a-tooltip>
                                                </template>
                                                <template v-else>
                                                    <a-layout align-start>
                                                        <a-flex shrink xs12 xl12>
                                                            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(validation, 'value')">
                                                                <template v-slot:activator="{ on }">
                                                                    <div v-on="on">
                                                                        <a-text-field
                                                                            v-model="validation.value"
                                                                            placeholder="Enter the value"
                                                                            :disabled="!canUpdate"
                                                                            @input="$emit('change-value')"
                                                                            :class="[localIsCheckErrors(validation, 'value') ? 'c-wiggle-short u-border-error' : ' u-border']"
                                                                            background-color="grey lighten-5"
                                                                            @keypress="localIsNumber($event, validation.operator, validation.field_name)"
                                                                            solo flat hide-details
                                                                        >
                                                                        </a-text-field>
                                                                    </div>
                                                                </template>
                                                                <span>{{ localGetMessageErrors(validation, 'value') }}</span>
                                                            </a-tooltip>
                                                        </a-flex>
                                                        <a-flex shrink xs12 xl12 v-if="validation.operator === 'between'">
                                                            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!localIsCheckErrors(validation, 'value_alt')">
                                                                <template v-slot:activator="{ on }">
                                                                    <div v-on="on">
                                                                        <a-text-field
                                                                            v-model="validation.value_alt"
                                                                            placeholder="Enter the value"
                                                                            :disabled="!canUpdate"
                                                                            @input="$emit('change-value')"
                                                                            :class="[localIsCheckErrors(validation, 'value_alt') ? 'c-wiggle-short u-border-error' : 'u-border']"
                                                                            background-color="grey lighten-5"
                                                                            solo flat hide-details
                                                                        >
                                                                        </a-text-field>
                                                                    </div>
                                                                </template>
                                                                <span>{{ localGetMessageErrors(validation, 'value_alt') }}</span>
                                                            </a-tooltip>
                                                        </a-flex>
                                                    </a-layout>
                                                </template>
                                            </template>
                                        </a-flex>
                                        <a-flex shrink class="align-center justify-center">
                                            <div v-if="canUpdate">
                                                <a-tooltip bottom v-if="validationRemoveId === validation.id">
                                                    <template v-slot:activator="{ on }" >
                                                        <div v-on="on" class="u-cursor-pointer u-border align-center justify-center deep-orange lighten-4 u-flex c-validation-status" @click="localRemoveValidation(validation, 'row')">
                                                            <a-icon color="red" size="17">delete</a-icon>
                                                        </div>
                                                    </template>
                                                    <span>Confirm Delete?</span>
                                                </a-tooltip>
                                                <div v-else class="u-border align-center justify-center u-flex c-validation-status" :class="[item.validations.length === 1 && index === 0 ? 'u-opacity-40' : 'u-cursor-pointer']" @click="item.validations.length === 1 && index === 0 ? null : localSelectRemoveValidation(validation.id)">
                                                    <a-icon color="grey" size="17">remove_circle_outline</a-icon>
                                                </div>
                                            </div>
                                        </a-flex>
                                    </a-layout>
                                </template>
                                <a-layout align-start class="ml-14 mt-2" v-if="canUpdate">
                                    <a-flex shrink>
                                        <a-btn depressed small rounded class="u-flex-center deep-purple darken-1 white--text" @click="$emit('add-validation', item.group_id)">
                                            <a-icon size="16" color="white" class="u-icon-nudge-xs mr-1">add</a-icon>
                                            <span>Add Rule</span>
                                        </a-btn>
                                    </a-flex>
                                    <a-spacer></a-spacer>
                                    <a-flex shrink>
                                        <a-btn depressed small rounded class="mr-4 u-flex-center primary lighten-1 white--text" @click="$emit('add-validation', 'new', group_index)">
                                            <a-icon size="16" color="white" class="u-icon-nudge-xs mr-1">add</a-icon>
                                            <span>Add Rule Set</span>
                                        </a-btn>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-tooltip bottom v-if="validationGroupRemoveId === item.group_id">
                                            <template v-slot:activator="{ on }">
                                                <a-icon size="16" color="red darken-1" class="ml-0 mr-4" v-on="on" @click="localRemoveValidation(item, 'group')">delete</a-icon>
                                            </template>
                                            <span>Confirm Delete?</span>
                                        </a-tooltip>
                                        <a-icon size="16" color="grey darken-1" v-else class="ml-0 mr-4" :class="[validationList.length === 1 && group_index === 0 ? 'u-opacity-40' : 'u-cursor-pointer']" @click="validationList.length === 1 && group_index === 0 ? null : localConfirmValidationRemove(item.group_id, 'group')">delete</a-icon>
                                    </a-flex>
                                </a-layout>
                            </div>
                        </template>
                        <template v-if="validationMessageType === 'validation_messages'">
                            <template v-for="(validations, group_index) in validationList">
                                <a-sheet v-if="group_index !== 0" height="1" class="u-wfull u-relative my-7 c-validation-or-shreet">
                                    <span class="u-absolute md-body-2 font-weight-medium body--text text--lighten-2 d-inline-block c-validation-or-card">OR</span>
                                </a-sheet>
                                <div v-for="(validation, index) in validations.validations" :key="validation.id" :class="[index !== 0 ? 'mt-6' : '']">
                                    <div class="py-3 px-0 u-flex">
                                        <p class="md-subtitle-1 indigo--text text--darken-1 pa-0 ma-0 font-weight-medium" style="font-size: 16px;">
                                            {{  localGetValidationEntityData('name', validation.field_name) }}
                                            <span class="font-italic u-font-weight-semibold">{{ localGetValidationEntityRules(validation.field_name, validation.operator) }}</span>
                                            {{  localGetValidationEntityValue(validation.field_name, validation.value) }}
                                            <span v-if="validation.value_alt">
                                                -
                                                {{  localGetValidationEntityValue(validation.field_name, validation.value_alt) }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="px-4 py-3 grey u-rounded-corners lighten-5 c-validation-messages-input u-flex">
                                        <a-text-field
                                            v-model="validation.warning_message"
                                            :disabled="!canUpdate"
                                            placeholder="Validation message goes here..."
                                            background-color="grey lighten-5"
                                            @input="$emit('change-value')"
                                            class="pa-0"
                                            solo flat hide-details
                                        >
                                        </a-text-field>
                                        <div class="u-flex pl-3" v-if="hasValidationMessageEdit(validation)">
                                            <span class="mt-1 grey--text text-uppercase pa-0 ma-0 font-weight-medium" style="font-size: 1rem !important; line-height: 20px;">Edited<span class="ml-2">&bull;</span></span>
                                            <div class="ml-2 u-cursor-pointer" @click="localSetDefaultValidation(validation)">
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }" >
                                                        <a-icon size="20" v-on="on" color="grey" class="u-icon-nudge-xs" style="top: 1px;">refresh</a-icon>
                                                    </template>
                                                    <span>Reset to default?</span>
                                                </a-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
                <a-divider class="mt-6"></a-divider>
            </template>
        </template>
        <a-dialog v-model="dialogValidationErrorPreview" persistent scrollable max-width="720">
           <ModalValidationErrorPreview
                :item="item"
                :icon="localGetTriggerIcon"
                :message="localGetWarningMessageReplace"
                :list="validationList"
                :validationErrorPreview="dialogValidationErrorPreview"
                @close="localClosePreviewView"
                @preview="localUpdatePreviewType"
           />
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState } from 'vuex'
import PValidationWorkflowWarningInfo from './PartialValidationWorkflowWarningInfo'
import PValidationWorkflowLoader from './PartialValidationWorkflowLoader'
import ModalValidationErrorPreview from '../Modals/ModalValidationErrorPreview'

export default {
    components: {
        PValidationWorkflowWarningInfo,
        PValidationWorkflowLoader,
        ModalValidationErrorPreview,
    },
    props: {
        item: {
            type: Object,
        },
        validationList: {
            type: Array,
            default: () => [],
        },
        validationEntityList: {
            type: Array,
            default: () => [],
        },
        customTagList: {
            type: Array,
            default: () => [],
        },
        systemTagList: {
            type: Array,
            default: () => [],
        },
        isLoading:{
            type: Boolean,
            default: true
        },
        isError: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        validationMessageType: {
            type: String,
            default: 'validatio_rules',
        },
        hasValidationMessageEdit: {
            type: Function,
            default: () => {}
        },
        hasCustomFieldTextInfoCheck: {
            type: Function,
            default: () => {}
        },
    },

    data() {
        return {
            valueFieldMaxLength: -1,
            valueAltFieldMaxLength: -1,
            validationRemoveId: null,
            validationGroupRemoveId: null,
            validationDefaultId: null,
            validationGroupRemoveTimeout: null,
            dialogValidationErrorPreview: false,
            errorMessage: 'There is an issue with the validation rules. Please reconfigure it.',
            customFieldTextInfo: 'This operator will be deprecated for use with text field. It is recommended to use with number field.',

            // Array
            validationStatus: [
                { status: "valid", class: "green lighten-5", icon: "check_circle", icon_color: "green darken-1"},
                { status: "new", class: "indigo lighten-5", icon: "add_circle_outline", icon_color: "indigo darken-1"},
                { status: "edit", class: "indigo lighten-5", icon: "drive_file_rename_outline", icon_color: "indigo darken-1"},
                { status: "invalid", class: "orange lighten-5", icon: "info", icon_color: "orange darken-1"},
            ],
            triggers : [
                { type: "project-created", icon: 'add_box'},
                { type: "project-updated", icon: 'border_color'},
                { type: "project-status-updated", icon: 'donut_large'},
                { type: "project-upsert", icon: 'add_box'},
                { type: "project-stage-changed", icon: 'label_important'},
                { type: "project-completed", icon: 'task_alt'},
                { type: "project-archived", icon: 'archive'},
            ],
            accordionValueDate: [],
            accordionValueAtDate: [],
            noRequiredRules: ['required','numeric','string','boolean','empty','not-empty','string','url','email'],
            numberRules: [
                'number',
                'greater-than',
                'greater-than-or-equal',
                'less-than',
                'less-than-or-equal',
                'maximum',
                'minimum',
                'maximum-characters',
                'minimum-characters',
            ],
            

            //Object
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
            attributes: {
                popover: {
                    visibility: 'hover',
                    isInteractive: true,
                },
            },
        }
    },

    computed: {
        localLabelTagList () {
            return this.systemTagList.filter(({ type }) => type === 'label')
        },

        localProductTagList () {
            return this.systemTagList.filter(({ type }) => type === 'product')
        },

        localPlatformTagList () {
            return this.systemTagList.filter(({ type }) => type === 'platform')
        },

        localFilterValidationList () {
            return this.validationList.filter(list => list.field_name && list.operator)
        },

        localCheckErrorInfo () {
            return (this.isError && this.validationMessageType === 'validation_rules')
        },

        ...mapState('User', {
            user_list: 'list',
        }),

        ...mapState('Competitor', {
            competitor_list: 'list',
            competitor_response: 'response',
        }),

        ...mapState('Stage', {
            stage_list: 'list',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Customer', {
            customer_list: 'list',
            customer_response: 'response',
        }),

        ...mapState('Partner', {
            partner_list: 'list',
            customer_response: 'response',
        }),

        ...mapState('Territory', {
            territory_list: 'list',
        }),

        ...mapState('TagSection', {
            tag_section_list: 'list',
        }),

        ...mapState('CustomField', {
            custom_field_list: 'list',
        }),

        ...mapState('AutomationValidation', {
            automation_validation_list: 'list',
        }),
    },

    methods: {
        localGetValidationValueList (field_name) {
            const related_type = this.localRelatedTypeFilter(field_name)

            switch (related_type) {
                case 'project_health': return this.localGetFieldvalues(field_name)
                case 'workspace_id' : return this.localGetFieldvalues(field_name)
                case 'result_id' : return this.localGetFieldvalues(field_name)
                case 'project_stage_id' : return this.localGetFieldvalues(field_name)
                case 'project_status_id' : return this.localGetFieldvalues(field_name)
                case 'custom_tag': return this.localCustomTagFilter(field_name).map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' }))
                case 'project_label': return this.localLabelTagList.map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' }))
                case 'project_product': return this.localProductTagList.map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' }))
                case 'project_platform': return this.localPlatformTagList.map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' }))
                case 'custom_field': return this.localGetFieldvalues(field_name)
                case 'competitors': return this.competitor_list.map(item => ({ id: item.id, name: item.name, type: 'competitor' }))
                case 'customer_id': return this.customer_list
                case 'partner_id': return this.partner_list
                case 'collaborators': return this.user_list
                case 'territories': return this.territory_list.map(item => ({ id: item.id, name: item.name, type: 'territory', color: item.color }))
                default: return []
            }
        },

        localGetFieldvalues(field_name) {
            const fields = this.validationEntityList.find(item => item.name === field_name)
            return fields && fields.picklistValues ? fields.picklistValues : []
        },

        localGetTriggerIcon (type) {
            const trigger = this.triggers.find(item => item.type && item.type === type)
            return trigger ? trigger.icon : null
        },

        localCustomTagFilter (tag_slug) {
            const tags = this.customTagList.filter(item => item.type === tag_slug)
            return tags
        },

        localRelatedTypeFilter (field_name) {
            const related = this.validationEntityList.find(item => item.name === field_name)
            return related && related.related_type ? related.related_type : null
        },

        localCheckValidationStatus (validation, type = null) {
            const cloneValidation =  _.cloneDeep(validation)
            const operator = this.noRequiredRules.includes(validation.operator)
            const filterItem = _.cloneDeep(this.automation_validation_list.find(list => list.id === validation.id))
            if (filterItem) {
                delete filterItem.warning_message
                delete cloneValidation.warning_message
                if (filterItem &&  _.isEqual(cloneValidation, filterItem) === false) return this.localStatusValidation('edit', type)
            }
            if ((!operator && !cloneValidation.warning) && !(cloneValidation.field_name && cloneValidation.operator && cloneValidation.value)) return this.localStatusValidation('new', type)
            if (!cloneValidation.warning) return this.localStatusValidation('valid', type)
            return this.localStatusValidation('invalid', type)
        },

        localCheckValidationMessageStatus (validation) {
            if (validation.warning_message && !validation.warning_message.trim()) return false
            const filterItem = _.cloneDeep(this.automation_validation_list.find(list => list.id === validation.id))
            if (!filterItem) {
                const field = this.validationEntityList.find(item => item.name === validation.field_name)
                if (field && validation.operator) {
                    const roules_item = field.rules.find(item => item.key === validation.operator)
                    if (roules_item.message !== validation.warning_message) return false
                }
            }
            if (filterItem && filterItem.warning_message !== validation.warning_message) return false

            return true
        },

        localCheckValidationExists (validation_id) {
            const filterItem = this.automation_validation_list.find(list => list.id === validation_id)
            if (filterItem) return true
            return false
        },

        localSelectRemoveValidation (id) {
            this.validationRemoveId = id
            setTimeout(() => {
                this.validationRemoveId = null
            }, 5000)
        },

        localSetDefaultValidation (validation) {
            this.$emit('set-default-message', validation)
            this.validationDefaultId = null
        },

        localCheckDefaultValidation (id) {
            this.validationDefaultId = id
            setTimeout(() => {
                this.validationDefaultId = null
            }, 5000)
        },

        localRemoveValidation (validation, type) {
            this.$emit('remove-validation', validation, type)
            this.conditionDeletedId = null
        },

        localConfirmValidationRemove (id) {
            clearTimeout(this.validationGroupRemoveTimeout)
            this.validationGroupRemoveId = id
            this.validationGroupRemoveTimeout = setTimeout(() => this.validationGroupRemoveId = null, 5000)
        },

        localStatusValidation (validate, type = null) {
            const item = this.validationStatus.find(item => item.status === validate)
            return type ? item[type] : item
        },

        localSelectedRule (field_name) {
            const rules = this.validationEntityList.find(item => item.name === field_name)
            return rules ? rules.rules : []
        },

        localSelectedFieldType (field_name) {
            const item = this.validationEntityList.find(item => item.name === field_name)
            return item ? item.type : null
        },

        localGetMessageErrors (validation, field_name) {
            if (!validation.warning || !field_name) return null
            return validation.warning[field_name] && validation.warning[field_name][0] ? validation.warning[field_name][0] : ''
        },

        localIsCheckErrors (validation, field_name) {
            if (!validation.warning || !field_name) return null
            return validation.warning[field_name] && validation.warning[field_name][0] ? true : false
        },

        localFormatDate (date) {
            return date ? moment(date).format('MMM D, YYYY') : null
        },

        localAccordionDateToggle (index, type = null) {
            if (type === 'value') {
                if (this.accordionValueDate[index] === undefined || this.accordionValueDate[index] === null) this.$set(this.accordionValueDate, index, true);
                else this.$set(this.accordionValueDate, index, !this.accordionValueDate[index]);
            }
            if (type === 'value_at') {
                if (this.accordionValueAtDate[index] === undefined || this.accordionValueAtDate[index] === null) this.$set(this.accordionValueAtDate, index, true);
                else this.$set(this.accordionValueAtDate, index, !this.accordionValueAtDate[index]);
            }
            this.$emit('change-value')
        },

        localGetValidationEntityData (field, value) {
            const fields = this.validationEntityList.find(item => item[field] === value)
            return fields ? fields.label : null
        },

        localGetValidationEntityRules (value, key) {
            const fields = this.validationEntityList.find(item => item.name === value)
            if (fields) {
                const rules = fields.rules.find(item => item.key === key)
                return rules ? rules.label : null
            }
        },

        localGetValidationEntityValue (field, value) {
            if (this.localSelectedFieldType(field) === 'date') {
                return this.localFormatDate(value)
            }
            const related_type = this.localRelatedTypeFilter(field)
            switch (related_type) {
                case 'project_health':
                    const health = this.localGetFieldvalues(field).find(item => item.id === value)
                    return health ? health.name : null
                case 'workspace_id' :
                    const workspace = this.localGetFieldvalues(field).find(item => item.id === value)
                    return workspace ? workspace.name : null
                case 'result_id' :
                    const result = this.localGetFieldvalues(field).find(item => item.id === value)
                    return result ? result.name : null
                case 'project_stage_id' :
                    const stage = this.localGetFieldvalues(field).find(item => item.id === value)
                    return stage ? stage.name : null
                case 'project_status_id' :
                    const status = this.localGetFieldvalues(field).find(item => item.id === value)
                    return status ? status.name : null
                case 'custom_tag':
                    const custom_tag = this.localCustomTagFilter(field).map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' })).find(item => item.id === value)
                    return custom_tag ? custom_tag.name : null
                case 'project_label':
                    const project_label = this.localLabelTagList.map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' })).find(item => item.id === value)
                    return project_label ? project_label.name : null
                case 'project_product':
                    const project_product = this.localProductTagList.map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' })).find(item => item.id === value)
                    return project_product ? project_product.name : null
                case 'project_platform':
                    const project_platform = this.localPlatformTagList.map(item => ({ id: item.id, name: item.label, label: item.label, color: item.color, type: 'tags' })).find(item => item.id === value)
                    return project_platform ? project_platform.name : null
                case 'custom_field':
                    const custom_field = this.localGetFieldvalues(field).find(item => item.id === value)
                    return custom_field ? custom_field.name : null
                case 'competitors':
                    const competitor =  this.competitor_list.map(item => ({ id: item.id, name: item.name, type: 'competitor' })).find(item => item.id === value)
                    return competitor ? competitor.name : null
                case 'customer_id':
                    const customer = this.customer_list.find(item => item.id === value)
                    return customer ? customer.name : null
                case 'partner_id':
                    const partner = this.partner_list.find(item => item.id === value)
                    return partner ? partner.name : null
                case 'collaborators':
                    const collaborators = this.user_list.find(item => item.id === value)
                    return collaborators ? collaborators.name : null
                case 'territories':
                    const territories = this.territory_list.map(item => ({ id: item.id, name: item.name, type: 'territory', color: item.color })).find(item => item.id === value)
                    return territories ? territories.name : null
                default: return value
            }
        },

        localRemoveValidationRow (id) {
            this.$emit('remove-validation-row',id)
            this.validationRemoveId = null
            this.validationDefaultId = null
        },

        localGetWarningMessageReplace (validation) {
            const value = validation.warning_message.replace('{{value}}', this.localGetValidationEntityValue(validation.field_name, validation.value))
            if (validation.value && validation.value_alt) {
                return value.replace('{{value_alt}}', this.localGetValidationEntityValue(validation.field_name, validation.value_alt))
            }
            return value
        },

        localSelectPreviewView () {
            this.dialogValidationErrorPreview = true
        },

        localClosePreviewView () {
            this.dialogValidationErrorPreview = false
        },

        localUpdatePreviewType () {
            this.$emit('update-message-type', 'validation_messages')
            this.localClosePreviewView()
        },

        localIsNumber (evt, type, field_name = null) {
            const fieldType = this.localSelectedFieldType(field_name) === 'number' ? 'number' : type;
            if (!this.numberRules.includes(fieldType)) return
            const charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.c-validation-status {
    width: 46px;
    height: 46px;
    border-radius: 4px;
}
.validation-editor-title .v-text-field.v-text-field--solo .v-input__control {
    min-height: 44px !important;
}
.c-validation-icon-view {
    width: 48px !important;
}
.c-validation-menu-icon {
    box-sizing: border-box;
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 8px !important;
}
.c-validation-messages {
    background: #FFFFFF;
    border: 1px solid #EEEEEE !important;
}
.c-validation-or-shreet {
    border-radius: 1px;
    background: radial-gradient(50% 50.00% at 50% 50.00%, #616161 0%, rgba(97, 97, 97, 0.08) 100%);
}
.c-validation-or-card {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 4px;
    background: #FFF;
    border: 1px solid #BDBDBD;
    font-size: 12px;
    font-weight: 600;
    color: #616161;
    width: 33px;
    height: 24px;
    padding: 2px 7px;
}
</style>


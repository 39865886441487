<template>
    <a-sheet>
        <a-menu transition="slide-y-transition" :close-on-content-click="false" offset-y left content-class="u-elevation-custom-1">
            <template v-slot:activator="{ on }">
                <div v-on="on">
                    <a-card class="u-cursor-pointer c-radius" :style="{ border: `1px solid ${$color(local_value, 'color_text')}` }" :color="$color(local_value, 'color_bg')" elevation="0" width="24" height="24"></a-card>
                </div>
            </template>
            <a-sheet class="white u-flex align-center flex-wrap" :max-width="maxWidth">
                <template v-for="(color) in color_list">
                    <a-sheet :key="color.color_bg" width="32" height="32" class="u-relative elevation-0 text-center d-flex align-center justify-center u-cursor-pointer" :color="color.color_bg" @click="$emit('input', color.name)">
                        <a-icon :color="color.color_text" size="16" v-if="color.name === local_value">done</a-icon>
                        <span v-else class="caption" :style="{ color: color.color_text }">A</span>
                    </a-sheet>
                </template>
            </a-sheet>
        </a-menu>
    </a-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
import Colors from '@/config/config-colors'

export default {
    props: {
        value: {
            type: String
        },
        maxWidth: {
            type: Number,
            default: 160
        }
    },

    data () {
        return {
            color_list: Colors
        }
    },

    computed: {
        local_value () {
            return this.value ?? this.color_list[this.local_get_color_index()]
        }
    },

    methods: {
        local_get_color_index () {
            return Math.floor(Math.random() * this.color_list.length)
        }
    }
}
</script>

<template>
    <a-card>
        <a-sheet class="u-flex py-5 px-6 align-center">
            <a-avatar color="indigo darken-1" size="38">
                <a-icon color="white" size="20">password</a-icon>
            </a-avatar>
            <h2 class="text-h6 indigo--text text--darken-3 font-weight-medium ml-3">Update Masked Field</h2>
        </a-sheet>
        <a-divider class="grey lighten-3"></a-divider>
        <a-sheet class="py-5 px-6" v-if="item">
            <label class="md-body-3 grey--text text--darken-1 d-block mb-2 mt-0 font-weight-medium">{{ item.label }}</label>
            <a-text-field
                v-model="value"
                :placeholder="`Enter the ${item.label}`"
                background-color="neutral"
                class="u-border"
                solo flat hide-details
            >
            </a-text-field>
            <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="custom_field_value_response.server && custom_field_value_response.server.errors && custom_field_value_response.server.errors.value">
                <a-icon size="16" color="red darken-2">warning</a-icon>
                {{ custom_field_value_response.server.errors.value[0] }}
            </span>
        </a-sheet>
        <a-divider class="grey lighten-3"></a-divider>
        <a-sheet class="py-5 px-6">
            <a-btn depressed class="indigo darken-1 white--text u-rounded-corners-lg" @click="$emit('action', value)" :loading="isLoading">
                Update
            </a-btn>
            <a-btn depressed @click="$emit('close')" class="ml-4 grey lighten-3 grey--text text--darken-1 u-rounded-corners-lg">
                Cancel
            </a-btn>
        </a-sheet>
    </a-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        isLoading: {
            type: Boolean
        },
        isModalOpen: {
            type: Boolean,
            default: false
        },
    },

    watch: {
        isModalOpen: {
            handler (newVal, oldVal) {
                if(newVal) this.value = null
            },
            immediate: true
        },
    },

    data () {
        return {
            value: null,
        }
    },

    computed: {
        ...mapState('CustomFieldValue', {
            custom_field_value_response: 'response'
        }),
    },
}
</script>

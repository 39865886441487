import Vue from 'vue';
const api_endpoint = '/inventories';

export default {
    namespaced: true,
    state: {
        list: [],
        import_list: [],
        trash_list: [],
        autosuggest_list: [],
        item: { territories: [],},
        links: {},
        meta: {},
        query: {
            filter: {},
            sort: 'order',
            include: [],
            fields: {},
            append: [],
        },
        filters: {
            'filter[status_id]': 'ALL',
            'filter[custom_status_id]': 'ALL',
            // 'filter[stage]': 'ALL',
            'filter[category_id]': 'ALL',
            'filter[assignee]': 'ALL',
            'filter[owner_id]': 'ALL',
            'filter[project]': 'ALL',
            'filter[territories]': 'ALL',
            'filter[tags]': 'ALL',
            // 'filter[sort_order]': 'asc',
            'sort': 'name',
            'booking_from': null,
            'booking_to': null,
            'filter[search]': null,
            // 'filter[group]': 'no_grouping',
            'paginate': 15,
            'page': 1,
            // 'filter[columns]': null,
        },
        response: {},
        defaults: {
            list: [],
            import_list: [],
            trash_list: [],
            autosuggest_list: [],
            item: { territories: [],},
            meta: {},
            query: {
                filter: {},
                sort: 'order',
                page: 1,
                include: [],
                fields: {},
                append: [],
            },
            filters: {
                'filter[status_id]': 'ALL',
                'filter[custom_status_id]': 'ALL',
                // 'filter[stage]': 'ALL',
                'filter[category_id]': 'ALL',
                'filter[assignee]': 'ALL',
                'filter[owner_id]': 'ALL',
                'filter[project]': 'ALL',
                'filter[territories]': 'ALL',
                'filter[tags]': 'ALL',
                // 'filter[sort_order]': 'asc',
                'sort': 'name',
                'booking_from': null,
                'booking_to': null,
                'filter[search]': null,
                // 'filter[group]': 'no_grouping',
                'paginate': 15,
                'page': 1,
                // 'filter[columns]': null,
            },
            response: {},
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_AUTOSUGGEST_LIST(state, items) {
            state.autosuggest_list = items;
        },
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_TRASH_LIST(state, items) {
            state.trash_list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_LIST_ITEM(state, payload) {
            const index = _.findIndex(state.list, { 'id': payload.id });
            Vue.set(state.list, index, payload.item)
        },
        UPDATE_INVENTORY_STAGE_TAG(state, payload) {
            const index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index].custom_status = payload.custom_status
        },
        CLEAR_INVENTORY_STAGE_TAG(state, payload) {
            const index = _.findIndex(state.list, { 'id': payload });
            state.list[index].custom_status = null
        },
        UPDATE_BOOKED_INVENTORY(state, payload) {
            const index = _.findIndex(state.list, { 'id': payload.id })
            index >= 0 ? Vue.set(state.list, index, payload) : null
        },
        UPDATE_QUERIES(state, filter) {
            state.filters = filter;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        APPEND_GROUP_LIST(state, items) {
            state.list = items
        },
        IMPORT_LIST(state, items) {
            state.import_list = _.union(state.list, items);
        },
        IMPORT_UPDATE_LIST(state, items) {
            state.import_list = items;
        },
        PREPEND_ITEM(state, item) {
            state.list.unshift(item);
        },
        UPDATE_LINKS(state, links) {
            state.links = links;
        },
        UPSERT_ITEM(state, payload) {
            const index = _.findIndex(state.list, { 'id': payload.id });
            if (index !== -1) state.list[index] = Object.assign(state.list[index], payload)
            else state.list.push(payload);
        },
        REMOVE_TRASH_ITEM(state, id) {
            const index = _.findIndex(state.trash_list, { 'id': id });
            state.trash_list.splice(index, 1);
        },
        REMOVE_ITEM(state, id) {
            const index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        CLEAR_RESPONSE(state) {
            state.response = {};
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            let query_filters = _.pickBy(context.state.filters, function (item) {
                return item != '';
            });
            return axios.get(api_endpoint, { params: { ...payload } })
                .then((response) => {
                    // if (query_filters.load && query_filters.load === 'append') {
                    if (payload.load && payload.load === 'append') {
                        context.commit('APPEND_LIST', response.data.data);
                    } else{
                        context.commit('UPDATE_LIST', response.data.data);
                    }
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        autosuggest(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint, { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_AUTOSUGGEST_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            const params = { include: 'tags,territories,customStatus,category,bookings.assignee.role,bookings.project.customer,currentBookedItems.project.customer,currentBookedItems.assignee.role,futureBookings,owner.role' }
            return axios.get(api_endpoint + '/' + payload.id, { params: { ...params } })
                .then((response) => {
                    if (payload.upsert) context.commit('UPSERT_ITEM', response.data.data)
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, payload)
                .then((response) => {
                    // (response.data.data.order > 0)
                    //     ? context.commit('UPSERT_ITEM', response.data.data)
                    //     : context.commit('PREPEND_ITEM', response.data.data)
                    // context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: response.data.data });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id, payload)
                .then((response) => {
                    context.dispatch('show', { id: payload.id, upsert: true })
                    // context.commit('UPDATE_ITEM', response.data.data);
                    // context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update_stage_tag(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/custom-status/' + payload.inventory_id + '/' + payload.tag_id)
                .then((response) => {
                    if (!payload.type) {
                        context.commit('UPDATE_INVENTORY_STAGE_TAG', response.data.data)
                        context.commit('REFRESH_LIST');
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        import(context, payload) {
            this.dispatch('Interface/loader_start')
            let form_data = new FormData()
            form_data.append('csv_file', payload.csv_file)
            return axios.post(api_endpoint + '/import', form_data, {
                    headers: {
                        'accept': 'application/json',
                        'Accept-Language': 'en-US',
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    let is_valid = response.data.data.filter(list => list.is_valid === 0)
                    if(is_valid.length === 0) {
                        context.commit('APPEND_LIST', response.data.data);
                    }else{
                        context.commit('IMPORT_LIST', response.data.data);
                    }
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        disabled_uploaded(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint +'/disabled_uploaded')
                .then((response) => {
                    context.commit('APPEND_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        import_index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint +'/import_index')
                .then((response) => {
                    context.commit('IMPORT_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        enable_uploaded(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint +'/enable_uploaded')
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        attach_custom_status(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + payload.inventory_id + '/tags/' + payload.tag_id)
                .then((response) => {
                    context.dispatch('show', { id: payload.inventory_id, upsert: true })
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear_custom_status(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.inventory_id + '/tags')
                .then((response) => {
                    if (!payload.type) {
                        context.commit('CLEAR_INVENTORY_STAGE_TAG', payload.inventory_id)
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        clear_uploaded(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/clear_uploaded')
                .then((response) => {
                    context.commit('UPDATE_LIST', []);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear_imported(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/clear_imported')
                .then((response) => {
                    context.commit('IMPORT_UPDATE_LIST', []);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        expand(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + context.state.item.id + '/expand', context.state.item)
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        collapse(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + context.state.item.id + '/collapse', context.state.item)
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        expand_all(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/expand_all', payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        collapse_all(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/collapse_all', payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success',  });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        trash_permanent(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/forces/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_TRASH_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success',  });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        trash_all(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/forces')
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success',  });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        delete_undo(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/restores/' + payload.id)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        trash_index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint, { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_TRASH_LIST', response.data.data);
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        update_booked_inventory(context, payload) {
            context.commit('UPDATE_ITEM', payload.data)
            context.commit('UPDATE_BOOKED_INVENTORY', payload.data)
        },
        export(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint+'/export', { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_LINKS', response.data.links);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        list_clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                resolve('Cleared');
            });
        },
        clear_autosuggest(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_AUTOSUGGEST_LIST', _.cloneDeep(context.state.defaults.autosuggest_list));
                resolve('Cleared');
            });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('UPDATE_AUTOSUGGEST_LIST', _.cloneDeep(context.state.defaults.autosuggest_list));
                context.commit('UPDATE_TRASH_LIST', _.cloneDeep(context.state.defaults.trash_list));
                context.commit('IMPORT_LIST', _.cloneDeep(context.state.defaults.import_list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_QUERIES', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },
    getters: {
        autosuggest_list: (state) => {
            const data = [...state.autosuggest_list]
            return data
        },
        list: (state) => {
            const data = [...state.list]
            return data
        },
        trash_list: (state) => {
            const data = [...state.trash_list]
            return data
        },
        item: (state) => {
            return { ...state.item }
        },
        links: (state) => {
            return { ...state.links }
        },
        meta: (state) => {
            return { ...state.meta }
        },
        query: (state) => {
            return { ...state.query }
        },
        filters: (state) => {
            const data = { ...state.filters }
            return data
        },
        query_filters: (state) => {
            return { ...state.query_filters }
        },
        response: (state) => {
            return { ...state.response }
        },
        defaults: (state) => {
            return { ...state.defaults }
        },
        form_mode: (state) => state.form_mode,
        import_list: state => {
            return state.import_list.filter(list => list.is_valid === 0)
        },
    }
}

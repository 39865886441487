<template>
    <div class="pt-5">
        <template v-if="localShowHeader">
            <outgoing-webhooks-breadcrumb></outgoing-webhooks-breadcrumb>
            <outgoing-webhooks-header :installation="installation_item"></outgoing-webhooks-header>
            <outgoing-webhooks-tabs :installation="installation_item"></outgoing-webhooks-tabs>
        </template>
        <div class="">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import OutgoingWebhooksBreadcrumb from './WebhooksBreadcrumb'
import OutgoingWebhooksHeader from './WebhooksHeader'
import OutgoingWebhooksTabs from './WebhooksTabs'

export default {
    components: {
        OutgoingWebhooksBreadcrumb,
        OutgoingWebhooksHeader,
        OutgoingWebhooksTabs,
    },

    mounted () {
        this.local_index()
    },

    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Apps', to: '/apps', exact: true})
            breadcrumbs.push({text: 'Configure Apps', to: '/apps/installed', exact: true})
            return breadcrumbs
        },

        localShowHeader () {
            const whiteList = ['appstore-webhook-workflows-create', 'appstore-webhook-workflows-edit']
            return !whiteList.includes(this.$route.name)
        },

        ...mapState('Installation', {
            installation_item: 'item',
        }),

        ...mapState('InstallationAccount', {
            installation_account_list: 'list',
            installation_account_filters: 'filters',
        }),

        ...mapState('Workflow', {
            workflow_item: 'item',
            workflow_list: 'list',
            workflow_filters: 'filters',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index () {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            // await this.installation_clear_item()
            // await this.installation_account_clear()
            await this.workflow_clear()

            await this.installation_show({id: this.$route.params.id})
            await this.installation_account_index({ 'filter[installation_id]': this.installation_item.id })

            await this.workflow_index({ 'filter[installation_id]': this.installation_item.id })
        },

        ...mapActions('Installation', {
            installation_show: 'show',
            installation_clear: 'clear',
            installation_clear_item: 'clear_item',
        }),

        ...mapActions('InstallationAccount', {
            installation_account_index: 'index',
            installation_account_destroy: 'destroy',
            installation_account_clear: 'clear',
        }),

        ...mapActions('Workflow', {
            workflow_index: 'index',
            workflow_store: 'store',
            workflow_destroy: 'destroy',
            workflow_clear: 'clear',
            workflow_clear_item: 'clear_item',
        }),
    }
}
</script>

<style lang="scss" scoped>

</style>

<template>
	<a-main>
        <a-container container--fluid grid-list-xl pa-0 mx-0>
            <g-page-header
                title="Use Cases"
                subtitle="Review all use cases, success criteria and test cases."
                icon="view_list"
            />

            <a-layout wrap align-center>
                <a-flex pt-0>
                    <a-layout wrap align-center justify-space-between>
                        <a-flex shrink>
                            <div v-if="show_edit_filter_input && (report_preset_item && report_preset_item.id)" class="d-flex align-center">
                                <a-icon size="24" class="mr-2">filter_list</a-icon>
                                <a-card class="u-border px-0 py-1 u-rounded-corners c-filter-edit" flat min-width="300" max-width="400">
                                    <form @submit.prevent="local_report_preset_update()">
                                        <a-text-field
                                            v-model="filter_name"
                                            height="15"
                                            solo hide-details dense flat autofocus
                                        ></a-text-field>
                                    </form>
                                </a-card>
                                <span class="red--text text--darken-1 caption ml-2 mt-1" v-if="this.$response(this.report_preset_response, 'name')">{{ this.$response(this.report_preset_response, 'name') }}</span>
                                <span class="grey--text text--darken-1 caption ml-2 mt-1" v-else>Press enter to update</span>
                            </div>
                            <div v-else>
                                <a-card class="transparent pa-0 u-overflow-hidden" elevation="0" max-width="500">
                                    <h3 class="md-heading-6 u-flex align-center" @click="local_show_filter_edit()">
                                        <template v-if="(report_preset_item && report_preset_item.id) && !show_edit_filter_input">
                                            <span class="md-heading-6 d-flex align-center u-cursor-pointer">
                                                <a-icon size="24" class="mr-2">filter_list</a-icon>
                                                {{ report_preset_item.name }}
                                                <a-icon size="18" class="ml-2 mt-1" color="grey darken-1">edit</a-icon>
                                            </span>
                                        </template>
                                        <template v-else>
                                            <a-icon size="24" class="mr-2">filter_list</a-icon>
                                            <span>Select Filters</span>
                                        </template>
                                    </h3>
                                </a-card>
                            </div>
                        </a-flex>
                        <a-spacer></a-spacer>
                        <a-flex shrink class="d-inline-flex align-center pl-0 ml-1">
                            <a-card class="u-border u-rounded-corners u-overflow-hidden mr-1" flat>
                                <a-menu bottom offset-y v-model="search_report" :close-on-content-click="report_mode" transition="slide-y-transition" class="d-block" @click="local_search_report = ''" min-width="230" max-width="230" max-height="400">
                                    <template v-slot:activator="{ on }">
                                        <a-card flat min-height="32" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 pl-2 d-flex" v-on="on">
                                            <a-card-text class="pa-1 d-flex align-center justify-space-between">
                                                <div class="body-2 grey--text text--darken-2">Select Report</div>
                                                <div class="text-xs-right ml-1 mr-1">
                                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                                </div>
                                            </a-card-text>
                                        </a-card>
                                    </template>
                                    <a-divider></a-divider>
                                    <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden pb-2">
                                        <a-list class="u-list-condensed py-0" v-if="report_preset_list && report_preset_list.length > 5">
                                            <a-list-item class="c-input-wrap my-2 px-2">
                                                <a-text-field
                                                    v-model="local_search_report"
                                                    placeholder="Search Reports"
                                                    background-color="grey lighten-5"
                                                    class="body-2 font-weight-normal u-border u-rounded-corners"
                                                    solo flat hide-details autofocus
                                                    @click="report_mode = false"
                                                >
                                                </a-text-field>
                                            </a-list-item>
                                        </a-list>
                                        <a-list style="max-height: 270px;" class="overflow-y-auto u-list-condensed py-0" :class="[local_filtered_report_list && local_filtered_report_list.length <= 5 ? 'mt-2' : '']">
                                            <template v-for="(report_preset, index) in local_filtered_report_list">
                                                <a-list-item :key="'report_key_' + report_preset.id" v-if="report_preset_item && report_preset_item.id !== report_preset.id" @click="local_report_preset_select(report_preset.id)">
                                                    <a-list-item-title :title="report_preset.name">
                                                        <span class="md-body-2" :title="report_preset.name">{{ report_preset.name | truncateText(45) }}</span>
                                                    </a-list-item-title>
                                                </a-list-item>
                                                <a-list-item :key="'report_key_' + report_preset.id" class="grey lighten-3" v-else>
                                                    <a-list-item-title :title="report_preset.name">
                                                        <span class="md-body-2" :title="report_preset.name">{{ report_preset.name | truncateText(45) }}</span>
                                                    </a-list-item-title>
                                                </a-list-item>
                                                <a-divider class="grey lighten-3" v-if="local_filtered_report_list.length - 1 !== index" :key="report_preset.id + 'divider'"></a-divider>
                                            </template>
                                            <a-list-item v-if="!local_filtered_report_list.length" disabled>
                                                <a-list-item-content>
                                                    <a-list-item-title v-if="loading">
                                                        <span class="md-subtitle-1 mb-0 text-center">Loading...</span>
                                                    </a-list-item-title>
                                                    <a-list-item-title v-else>
                                                        <span class="md-subtitle-1 mb-0 text-center">No reports found</span>
                                                    </a-list-item-title>
                                                </a-list-item-content>
                                            </a-list-item>
                                        </a-list>
                                    </a-card>
                                </a-menu>
                            </a-card>
                            <a-card class="u-border u-rounded-corners u-overflow-hidden mx-1" flat min-height="32" :disabled="loading" v-if="!report_preset_item || (report_preset_item && !report_preset_item.id) && local_check_filter_applied">
                                <a-card-text class="pa-0 d-flex">
                                    <a-btn small text color="green darken-1" class="green lighten-5 ma-0" style="width: 100%; height: 32px" @click="local_open_create_preset()">
                                        <a-icon size="18" class="mr-2">save_alt</a-icon> Save Report
                                    </a-btn>
                                </a-card-text>
                            </a-card>
                            <a-card class="u-border u-rounded-corners u-overflow-hidden mx-1" flat min-height="32" v-if="(report_preset_item && report_preset_item.id) && !local_equals(report_filters_cloned, report_filters)">
                                <a-card-text class="pa-0 d-flex">
                                    <a-btn small text color="orange darken-1" class="orange lighten-5 ma-0" style="width: 100%; height: 32px" :disabled="loading" @click="local_report_preset_update()">
                                        <a-icon size="18" class="mr-2">edit</a-icon> Update Report
                                    </a-btn>
                                </a-card-text>
                            </a-card>
                            <!-- <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden mx-1 d-flex align-center" min-height="32" max-width="50" v-if="local_check_filter_applied || (report_preset_item && report_preset_item.id)"> -->
                            <a-card class="u-border u-rounded-corners u-overflow-hidden mx-1 d-flex align-center" flat min-height="32" max-width="50">
                                <a-card-text class="pa-0">
                                    <a-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="text-center px-1">
                                                <a-progress-circular indeterminate color="orange" size="20" width="3" v-if="local_loadings.value && local_loadings.type === 'clear'"></a-progress-circular>
                                                <div @click="loading ? {} : local_report_preset_start_over()" v-else>
                                                    <a-icon color="grey darken-3" size="20" class="u-cursor-pointer px-1">clear_all</a-icon>
                                                </div>
                                            </div>
                                        </template>
                                        <span>Reset Report</span>
                                    </a-tooltip>
                                </a-card-text>
                            </a-card>
                            <a-card class="u-border u-rounded-corners u-overflow-hidden ml-1 d-flex align-center" flat min-height="32" max-width="50" v-if="report_preset_item && report_preset_item.id">
                                <a-card-text class="pa-0">
                                    <a-menu bottom right offset-y :close-on-content-click="false" max-width="320">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="text-center px-1 pb-1 u-cursor-pointer">
                                                <a-icon color="red darken-1" size="18" class="px-1">delete</a-icon>
                                            </div>
                                        </template>
                                        <a-card>
                                            <a-card-text class="pa-4">
                                                <h2 class="md-heading-6 mb-2">Are you sure to delete this report preset?</h2>
                                                <p class="md-body-2 mb-4">This will delete the preset permanently and cannot be undone.</p>
                                                <a-btn class="ma-0 elevation-0" color="red" @click="local_report_preset_destroy(report_preset_item && report_preset_item.id ? report_preset_item.id : '')" :loading="loading" dark small>
                                                    <a-icon size="16" left class="mr-2">delete</a-icon> Delete
                                                </a-btn>
                                            </a-card-text>
                                        </a-card>
                                    </a-menu>
                                </a-card-text>
                            </a-card>
                        </a-flex>
                    </a-layout>
                </a-flex>
            </a-layout>
        </a-container>

        <a-container container--fluid grid-list-xl pa-0 mx-0 mt-4>
            <a-layout wrap align-center class="mb-3">
                <!-- #Use Case Filters -->
                <a-flex xs12>
                    <div class="u-flex align-center">
                        <h2 class="md-body-2 grey--text text--darken-1 font-weight-medium text-uppercase" style="letter-spacing: 0.5px">Use Case Filters</h2>
                        <!-- <a-divider class="ml-3 grey lighten-2"></a-divider> -->
                    </div>

                    <!-- #Use Case Assignees -->
                    <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_uc_assignees = !expansion_panel_uc_assignees">
                                <a-layout wrap align-start>
                                    <a-flex>
                                        <h4 class="md-subtitle-1" :class="{ 'pb-1': expansion_panel_uc_assignees }">
                                            <a-icon size="20" class="mr-2">people</a-icon>
                                            Assignees
                                            <span class="md-caption grey--text text--darken-1">
                                                <span class="mx-2" v-if="selectedUcUsers && selectedUcUsers.length">
                                                    <strong class="mr-1">Selected </strong>
                                                    <template v-for="(item, index) in selectedUcUsers">
                                                        <span :key="index">
                                                            <template v-if="index <= 2"><span v-if="index !== 0">, </span>{{ item.name }}</template>
                                                        </span>
                                                    </template>
                                                    <template v-if="selectedUcUsers.length > 3"> and {{ selectedUcUsers.length - 3 }} more</template>
                                                </span>
                                            </span>
                                        </h4>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon v-if="expansion_panel_uc_assignees">expand_less</a-icon>
                                        <a-icon v-else>expand_more</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_uc_assignees">
                                <a-layout wrap align-start>
                                    <a-flex xs12>
                                        <div class="u-rounded-corners-lg u-border">
                                            <GUsersAutocomplete
                                                v-model="selectedUcUsers"
                                                :list="user_list"
                                                :loading="mixinSearchLoading"
                                                :disabled="loading"
                                                itemValue="id"
                                                @search="localSearchUsers"
                                                @input="local_set_usecase_filter(usecase_filter.ASSIGNEES, selectedUcUsers, 'usecase', 'assignees')"
                                                placeholder="Select Assignees"
                                                class="pr-5 grey lighten-5"
                                                :class="[selectedUcUsers && selectedUcUsers.length ? 'py-2' : 'py-3']"
                                                dropdownClass="c-tiny-scroll py-2"
                                                noDataText="No User Found"
                                                returnObject multiple removable
                                            >
                                                <template #selection="{ props: { item, removeChip } }">
                                                    <a-chip pill close @click:close="removeChip(item)">
                                                        <h5 class="md-body-2 text-truncate" style="max-width: 300px" :title="item ? item.name : ''">{{ item.name }}</h5>
                                                    </a-chip>
                                                </template>
                                                <template v-slot:item="{ props: { item } }">
                                                    <div class="u-flex-center-y">
                                                        <g-avatar :item="item"></g-avatar>
                                                        <g-profile-info :item="item" set-max-width type-user></g-profile-info>
                                                    </div>
                                                </template>
                                            </GUsersAutocomplete>
                                        </div>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>

                    <!-- #Use Case Tags -->
                    <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_uc_tags = !expansion_panel_uc_tags">
                                <a-layout wrap align-start>
                                    <a-flex>
                                        <h4 class="md-subtitle-1" :class="{ 'pb-1': expansion_panel_uc_tags }">
                                            <a-icon size="20" class="mr-2">label</a-icon>
                                            Tags
                                            <span class="md-caption grey--text text--darken-1">
                                                <span class="mx-2" v-if="usecase_report_filters[usecase_filter.TAGS] && usecase_report_filters[usecase_filter.TAGS].length">
                                                    <strong class="mr-1">Selected </strong>
                                                    <template v-for="(item, index) in local_get_field_value(tag_list, 'id', usecase_report_filters[usecase_filter.TAGS])">
                                                        <span :key="index">
                                                            <template v-if="index <= 2"><span v-if="index !== 0">, </span>{{ item.label }}</template>
                                                        </span>
                                                    </template>
                                                    <template v-if="local_get_field_value(tag_list, 'id', usecase_report_filters[usecase_filter.TAGS]).length > 3"> and {{ local_get_field_value(tag_list, 'id', usecase_report_filters[usecase_filter.TAGS]).length - 3 }} more</template>
                                                </span>
                                            </span>
                                        </h4>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-icon v-if="expansion_panel_uc_tags">expand_less</a-icon>
                                        <a-icon v-else>expand_more</a-icon>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_uc_tags">
                                <a-layout wrap align-start>
                                    <a-flex xs12>
                                        <a-autocomplete
                                            v-model="usecase_report_filters[usecase_filter.TAGS]"
                                            :items="tag_list"
                                            item-text="label"
                                            item-value="id"
                                            placeholder="Select tags"
                                            class="elevation-0 u-border u-rounded-corners"
                                            background-color="grey lighten-5"
                                            clear-icon="backspace"
                                            :disabled="loading"
                                            solo flat hide-details multiple small-chips hide-selected clearable deletable-chips
                                            @input="local_set_usecase_filter(usecase_filter.TAGS, usecase_report_filters[usecase_filter.TAGS], 'usecase')"
                                        >
                                            <template v-slot:selection="{ item }">
                                                <g-tags :tag="item" @destroy="local_tag_filter_remove(item.id, usecase_filter.TAGS, usecase_report_filters[usecase_filter.TAGS], 'usecase')"></g-tags>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <g-tags :tag="item" hide-clear-icon></g-tags>
                                            </template>
                                        </a-autocomplete>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>

                    <a-card max-width="50%" class="u-border u-rounded-corners u-overflow-hidden mt-2" flat>
                        <div>
                            <a-autocomplete
                                v-model="usecase_report_filters[usecase_filter.VISIBILITY]"
                                :items="local_usecase_visibility"
                                item-text="text"
                                item-value="value"
                                placeholder="Ex: Internal"
                                class="elevation-0 u-rounded-corners"
                                clear-icon="backspace"
                                :disabled="loading"
                                solo flat hide-details hide-selected clearable hide-no-data
                                @input="local_set_usecase_filter(usecase_filter.VISIBILITY, usecase_report_filters[usecase_filter.VISIBILITY], 'usecase')"
                            >
                                <template #prepend-inner>
                                    <div class="u-flex-center-y ml-n1 mr-2 pa-2 u-rounded-corners-lg">
                                        <a-icon size="20" color="grey darken-1">public</a-icon>
                                        <span class="md-subtitle-1 grey--text text--darken-2 ml-2">Visibility</span>
                                    </div>
                                </template>
                            </a-autocomplete>
                        </div>
                    </a-card>
                </a-flex>

                <!-- Success Criteria Filters -->
                <v-slide-y-transition leave-absolute hide-on-leave>
                    <a-flex xs12 mt-4 v-if="include_success_criteria">
                        <div class="u-flex align-center">
                            <h2 class="md-body-2 grey--text text--darken-1 font-weight-medium text-uppercase" style="letter-spacing: 0.5px">Success Criteria Filters</h2>
                            <!-- <a-divider class="ml-3 grey lighten-2"></a-divider> -->
                        </div>
                        <a-layout wrap align-center>
                            <!-- #Success Criteria Status -->
                            <a-flex xs6 pr-1 pb-0>
                                <a-container container--fluid grid-list-xl class="py-3 px-0">
                                    <a-layout wrap align-center>
                                        <a-flex xs12>
                                            <h4 class="md-subtitle-1">
                                                <a-icon size="20" class="mr-2">toggle_on</a-icon>
                                                Status
                                            </h4>
                                        </a-flex>
                                        <a-flex py-0>
                                            <a-card class="u-border u-rounded-corners u-overflow-hidden" flat>
                                                <a-autocomplete
                                                    v-model="success_criteria_report_filters[usecase_filter.SC_STATUS]"
                                                    :items="success_criterion_status_list"
                                                    item-text="value"
                                                    item-value="id"
                                                    placeholder="Select status"
                                                    class="elevation-0 u-rounded-corners"
                                                    clear-icon="backspace"
                                                    :disabled="loading"
                                                    solo flat hide-details multiple small-chips hide-selected clearable hide-no-data deletable-chips
                                                    @input="local_set_usecase_filter(usecase_filter.SC_STATUS, success_criteria_report_filters[usecase_filter.SC_STATUS], 'success_criteria')"
                                                ></a-autocomplete>
                                            </a-card>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-flex>

                            <!-- # Success Criteria Tags -->
                            <a-flex xs6 pl-1 pb-0>
                                <a-container container--fluid grid-list-xl class="py-3 px-0">
                                    <a-layout wrap align-center>
                                        <a-flex xs12>
                                            <h4 class="md-subtitle-1">
                                                <a-icon size="20" class="mr-2">label</a-icon>
                                                Tags
                                            </h4>
                                        </a-flex>
                                        <a-flex py-0>
                                            <a-card class="u-border u-rounded-corners u-overflow-hidden" flat>
                                                <a-autocomplete
                                                    v-model="success_criteria_report_filters[usecase_filter.SC_TAGS]"
                                                    :items="tag_list"
                                                    item-text="label"
                                                    item-value="id"
                                                    placeholder="Select tags"
                                                    class="elevation-0 u-rounded-corners"
                                                    clear-icon="backspace"
                                                    :disabled="loading"
                                                    solo flat hide-details multiple small-chips hide-selected clearable hide-no-data deletable-chips
                                                    @input="local_set_usecase_filter(usecase_filter.SC_TAGS, success_criteria_report_filters[usecase_filter.SC_TAGS], 'success_criteria')"
                                                >
                                                    <template v-slot:selection="{ item }">
                                                        <g-tags :tag="item" @destroy="local_tag_filter_remove(item.id, usecase_filter.SC_TAGS, success_criteria_report_filters[usecase_filter.SC_TAGS], 'success_criteria')"></g-tags>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <g-tags :tag="item" hide-clear-icon></g-tags>
                                                    </template>
                                                </a-autocomplete>
                                            </a-card>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-flex>
                        </a-layout>

                        <!-- #Success Criteria Assignees -->
                        <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                            <a-card-text class="pa-0">
                                <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_sc_assignees = !expansion_panel_sc_assignees">
                                    <a-layout wrap align-start>
                                        <a-flex>
                                            <h4 class="md-subtitle-1" :class="{ 'pb-1': expansion_panel_sc_assignees }">
                                                <a-icon size="20" class="mr-2">people</a-icon>
                                                Assignees
                                                <span class="md-caption grey--text text--darken-1">
                                                    <span class="mx-2" v-if="selectedScUsers && selectedScUsers.length">
                                                        <strong class="mr-1">Selected </strong>
                                                        <template v-for="(item, index) in selectedScUsers">
                                                            <span :key="index">
                                                                <template v-if="index <= 2"><span v-if="index !== 0">, </span>{{ item.name }}</template>
                                                            </span>
                                                        </template>
                                                        <template v-if="selectedScUsers.length > 3"> and {{ selectedScUsers.length - 3 }} more</template>
                                                    </span>
                                                </span>
                                            </h4>
                                        </a-flex>
                                        <a-flex shrink>
                                            <a-icon v-if="expansion_panel_sc_assignees">expand_less</a-icon>
                                            <a-icon v-else>expand_more</a-icon>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                                <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_sc_assignees">
                                    <a-layout wrap align-start>
                                        <a-flex xs12>
                                            <div class="u-rounded-corners-lg u-border">
                                                <GUsersAutocomplete
                                                    v-model="selectedScUsers"
                                                    :list="user_list"
                                                    :loading="mixinSearchLoading"
                                                    :disabled="loading"
                                                    itemValue="id"
                                                    @search="localSearchUsers"
                                                    @input="local_set_usecase_filter(usecase_filter.SC_ASSIGNEES, selectedScUsers, 'success_criteria', 'assignees')"
                                                    placeholder="Select Assignees"
                                                    class="pr-5 grey lighten-5"
                                                    :class="[selectedScUsers && selectedScUsers.length ? 'py-2' : 'py-3']"
                                                    dropdownClass="c-tiny-scroll py-2"
                                                    noDataText="No User Found"
                                                    returnObject multiple removable
                                                >
                                                    <template #selection="{ props: { item, removeChip } }">
                                                        <a-chip pill close @click:close="removeChip(item)">
                                                            <h5 class="md-body-2 text-truncate" style="max-width: 300px" :title="item ? item.name : ''">{{ item.name }}</h5>
                                                        </a-chip>
                                                    </template>
                                                    <template v-slot:item="{ props: { item } }">
                                                        <div class="u-flex-center-y">
                                                            <g-avatar :item="item"></g-avatar>
                                                            <g-profile-info :item="item" set-max-width type-user></g-profile-info>
                                                        </div>
                                                    </template>
                                                </GUsersAutocomplete>
                                            </div>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-card-text>
                        </a-card>
                    </a-flex>
                </v-slide-y-transition>

                <!-- Test Case Filters -->
                <v-slide-y-transition leave-absolute hide-on-leave>
                    <a-flex xs12 mt-4 v-if="include_testcase">
                        <div class="u-flex align-center">
                            <h2 class="md-body-2 grey--text text--darken-1 font-weight-medium text-uppercase" style="letter-spacing: 0.5px">Test Case Filters</h2>
                            <a-divider class="ml-3 grey lighten-2"></a-divider>
                        </div>
                        <a-layout wrap align-center>
                            <!-- #Test Case Status -->
                            <a-flex xs6 pr-1 pb-0>
                                <a-container container--fluid grid-list-xl class="py-3 px-0">
                                    <a-layout wrap align-center>
                                        <a-flex xs12>
                                            <h4 class="md-subtitle-1">
                                                <a-icon size="20" class="mr-2">toggle_on</a-icon>
                                                Status
                                            </h4>
                                        </a-flex>
                                        <a-flex py-0>
                                            <a-card class="u-border u-rounded-corners u-overflow-hidden" flat>
                                                <a-autocomplete
                                                    v-model="testcase_report_filters[usecase_filter.TESTCASE_STATUS]"
                                                    :items="testcase_status_list"
                                                    item-text="value"
                                                    item-value="id"
                                                    placeholder="Select status"
                                                    class="elevation-0 u-rounded-corners"
                                                    clear-icon="backspace"
                                                    :disabled="loading"
                                                    solo flat hide-details multiple small-chips hide-selected clearable hide-no-data deletable-chips
                                                    @input="local_set_usecase_filter(usecase_filter.TESTCASE_STATUS, testcase_report_filters[usecase_filter.TESTCASE_STATUS], 'testcase')"
                                                ></a-autocomplete>
                                            </a-card>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-flex>

                            <!-- #Test Case Tags -->
                            <a-flex xs6 pl-1 pb-0>
                                <a-container container--fluid grid-list-xl class="py-3 px-0">
                                    <a-layout wrap align-start>
                                        <a-flex xs12>
                                            <h4 class="md-subtitle-1">
                                                <a-icon size="20" class="mr-2">label</a-icon>
                                                Tags
                                            </h4>
                                        </a-flex>
                                        <a-flex xs12 py-0>
                                            <a-card class="u-border u-rounded-corners u-overflow-hidden" flat>
                                                <a-autocomplete
                                                    v-model="testcase_report_filters[usecase_filter.TESTCASE_TAGS]"
                                                    :items="tag_list"
                                                    item-text="label"
                                                    item-value="id"
                                                    placeholder="Select tags"
                                                    class="elevation-0 u-rounded-corners"
                                                    clear-icon="backspace"
                                                    :disabled="loading"
                                                    solo flat hide-details multiple small-chips hide-selected clearable deletable-chips
                                                    @input="local_set_usecase_filter(usecase_filter.TESTCASE_TAGS, testcase_report_filters[usecase_filter.TESTCASE_TAGS], 'testcase')"
                                                >
                                                    <template v-slot:selection="{ item }">
                                                        <g-tags :tag="item" @destroy="local_tag_filter_remove(item.id, usecase_filter.TESTCASE_TAGS, testcase_report_filters[usecase_filter.TESTCASE_TAGS], 'testcase')"></g-tags>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <g-tags :tag="item" hide-clear-icon></g-tags>
                                                    </template>
                                                </a-autocomplete>
                                            </a-card>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-flex>

                            <!-- #Timeline -->
                            <a-flex xs12 py-0>
                                <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                                    <a-card-text class="pa-0">
                                        <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_tc_dates = !expansion_panel_tc_dates">
                                            <a-layout align-center>
                                                <a-flex>
                                                    <h4 class="md-subtitle-1" :class="{ 'pb-1': expansion_panel_tc_dates }">
                                                        <a-icon size="20" class="mr-2">access_time</a-icon>
                                                        Start / Due date
                                                        <span class="md-caption grey--text text--darken-1 ml-2">
                                                            <span class="mr-1" v-if="date_field == 'START_DATE'">Started</span>
                                                            <span class="mr-1" v-if="date_field == 'DUE_DATE'">End</span>
                                                            <span class="mr-1" v-if="this.date_condition">{{ this.date_condition }}</span>
                                                            <g-moment tag="span" class="mr-1" :value="date_value_1" input-format="YYYY-MM-DD" output-format="MMMM DD, YYYY" v-if="date_value_1"></g-moment>
                                                            <span v-if="date_value_2">and <g-moment tag="span" :value="date_value_2" input-format="YYYY-MM-DD" output-format="MMMM DD, YYYY"></g-moment></span>
                                                        </span>
                                                        <span class="mx-2 caption red--text text--darken-1" v-if="show_date_field_error">Please fill all the respective date fields.</span>
                                                    </h4>
                                                </a-flex>
                                                <a-flex shrink>
                                                    <a-icon v-if="expansion_panel_tc_dates">expand_less</a-icon>
                                                    <a-icon v-else>expand_more</a-icon>
                                                </a-flex>
                                            </a-layout>
                                        </a-container>
                                        <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_tc_dates">
                                            <a-layout align-start>
                                                <!-- #Timeline -->
                                                <a-flex>
                                                    <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Date Field</h4>
                                                    <a-autocomplete
                                                        v-model="date_field"
                                                        :items="timeline_date_field_list"
                                                        item-value="value"
                                                        placeholder="Select Date Field"
                                                        class="elevation-0 u-border u-rounded-corners"
                                                        background-color="grey lighten-5"
                                                        clear-icon="backspace"
                                                        :disabled="loading"
                                                        solo flat hide-details clearable
                                                        @input="local_set_tc_date_filter"
                                                    >
                                                    </a-autocomplete>
                                                </a-flex>
                                                <a-flex>
                                                    <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">Condition</h4>
                                                    <a-autocomplete
                                                        v-model="date_condition"
                                                        :items="[{text: 'On', value: 'on'}, {text: 'Before', value: 'before'}, {text: 'After', value: 'after'}, {text: 'Between', value: 'between'}]"
                                                        placeholder="Select Condition"
                                                        class="elevation-0 u-border u-rounded-corners"
                                                        background-color="grey lighten-5"
                                                        clear-icon="backspace"
                                                        solo flat hide-details clearable
                                                        :disabled="local_check_timeline_filters('date_condition')"
                                                        @input="local_set_tc_date_filter"
                                                    >
                                                    </a-autocomplete>
                                                </a-flex>
                                                <a-flex>
                                                    <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2" v-if="date_condition === 'between'">From</h4>
                                                    <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2" v-else>Date Value</h4>
                                                    <a-menu
                                                        v-model="datepicker_menu_1"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        min-width="250px"
                                                        :disabled="local_check_timeline_filters('date_value_1')"
                                                        lazy offset-y full-width
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <a-text-field
                                                                v-on="on"
                                                                v-model="date_value_1"
                                                                placeholder="Select Date"
                                                                append-icon="event"
                                                                class="u-border u-rounded-corners"
                                                                background-color="grey lighten-5"
                                                                clear-icon="backspace"
                                                                readonly solo flat hide-details clearable
                                                                :disabled="local_check_timeline_filters('date_value_1')"
                                                                @input="local_set_tc_date_filter"
                                                            ></a-text-field>
                                                        </template>
                                                        <vc-date-picker
                                                            v-model="date_value_1"
                                                            class="d-block u-wfull"
                                                            :model-config="modelConfig"
                                                            :max-date="date_value_2 ? date_value_2 : null"
                                                            @input="() => {datepicker_menu_1 = false; local_set_tc_date_filter()}"
                                                        >
                                                        </vc-date-picker>
                                                    </a-menu>
                                                </a-flex>
                                                <a-flex v-if="date_condition === 'between'">
                                                    <h4 class="md-subtitle-2 font-weight-bold grey--text mb-2">To</h4>
                                                    <a-menu
                                                        v-model="datepicker_menu_2"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        min-width="250px"
                                                        :disabled="local_check_timeline_filters('date_value_2')"
                                                        lazy offset-y full-width
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <a-text-field
                                                                v-on="on"
                                                                v-model="date_value_2"
                                                                placeholder="Select Date"
                                                                append-icon="event"
                                                                class="u-border u-rounded-corners"
                                                                background-color="grey lighten-5"
                                                                clear-icon="backspace"
                                                                readonly solo flat hide-details clearable
                                                                :disabled="local_check_timeline_filters('date_value_2')"
                                                                @input="local_set_tc_date_filter"
                                                            ></a-text-field>
                                                        </template>
                                                        <vc-date-picker
                                                            v-model="date_value_2"
                                                            class="d-block u-wfull"
                                                            :model-config="modelConfig"
                                                            :min-date="date_value_1"
                                                            @input="() => {datepicker_menu_2 = false; local_set_tc_date_filter()}"
                                                        >
                                                        </vc-date-picker>
                                                    </a-menu>
                                                </a-flex>
                                            </a-layout>
                                        </a-container>
                                    </a-card-text>
                                </a-card>
                            </a-flex>
                        </a-layout>

                        <!-- #Test Case Assignees -->
                        <a-card class="u-border u-rounded-corners u-overflow-hidden mt-2 pa-2" flat>
                            <a-card-text class="pa-0">
                                <a-container container--fluid grid-list-xl class="u-cursor-pointer py-3" @click="expansion_panel_tc_assignees = !expansion_panel_tc_assignees">
                                    <a-layout wrap align-start>
                                        <a-flex>
                                            <h4 class="md-subtitle-1" :class="{ 'pb-1': expansion_panel_tc_assignees }">
                                                <a-icon size="20" class="mr-2">people</a-icon>
                                                Assignees
                                                <span class="md-caption grey--text text--darken-1">
                                                    <span class="mx-2" v-if="selectedTcUsers && selectedTcUsers.length">
                                                        <strong class="mr-1">Selected </strong>
                                                        <template v-for="(item, index) in selectedTcUsers">
                                                            <span :key="index">
                                                                <template v-if="index <= 2"><span v-if="index !== 0">, </span>{{ item.name }}</template>
                                                            </span>
                                                        </template>
                                                        <template v-if="selectedTcUsers.length > 3"> and {{ selectedTcUsers.length - 3 }} more</template>
                                                    </span>
                                                </span>
                                            </h4>
                                        </a-flex>
                                        <a-flex shrink>
                                            <a-icon v-if="expansion_panel_tc_assignees">expand_less</a-icon>
                                            <a-icon v-else>expand_more</a-icon>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                                <a-container container--fluid grid-list-xl class="pt-0" v-if="expansion_panel_tc_assignees">
                                    <a-layout wrap align-start>
                                        <a-flex xs12>
                                            <div class="u-rounded-corners-lg u-border">
                                                <GUsersAutocomplete
                                                    v-model="selectedTcUsers"
                                                    :list="user_list"
                                                    :loading="mixinSearchLoading"
                                                    :disabled="loading"
                                                    itemValue="id"
                                                    @search="localSearchUsers"
                                                    @input="local_set_usecase_filter(usecase_filter.SC_ASSIGNEES, selectedTcUsers, 'testcase', 'assignees')"
                                                    placeholder="Select Assignees"
                                                    class="pr-5 grey lighten-5"
                                                    :class="[selectedTcUsers && selectedTcUsers.length ? 'py-2' : 'py-3']"
                                                    dropdownClass="c-tiny-scroll py-2"
                                                    noDataText="No User Found"
                                                    returnObject multiple removable
                                                >
                                                    <template #selection="{ props: { item, removeChip } }">
                                                        <a-chip pill close @click:close="removeChip(item)">
                                                            <h5 class="md-body-2 text-truncate" style="max-width: 300px" :title="item ? item.name : ''">{{ item.name }}</h5>
                                                        </a-chip>
                                                    </template>
                                                    <template v-slot:item="{ props: { item } }">
                                                        <div class="u-flex-center-y">
                                                            <g-avatar :item="item"></g-avatar>
                                                            <g-profile-info :item="item" set-max-width type-user></g-profile-info>
                                                        </div>
                                                    </template>
                                                </GUsersAutocomplete>
                                            </div>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-card-text>
                        </a-card>
                    </a-flex>
                </v-slide-y-transition>
            </a-layout>
        </a-container>

        <!-- #Generate Report Button -->
        <a-container container--fluid grid-list-xl pa-0 py-6 mx-0 mb-5>
            <a-layout>
                <a-flex class="pa-0">
                    <a-card class="u-overflow-hidden mt-1 transparent elevation-0 mt-2 px-0">
                        <a-card-text>
                            <a-container container--fluid grid-list-xl class="py-1 pr-0">
                                <a-layout wrap align-center class="py-1">
                                    <a-flex shrink class="pa-0">
                                        <h4 class="md-subtitle-2 font-weight-bold grey--text text--darken-1"><span class="red--text text--darken-1">* </span>Please use the above filters to generate a report.</h4>
                                    </a-flex>
                                    <a-spacer></a-spacer>
                                    <a-flex py-0 shrink class="u-flex align-center">
                                        <span class="md-subtitle-1 mr-1 grey--text text--darken-3 mr-2">Include: </span>
                                        <a-sheet class="u-flex align-center px-3 u-rounded-corners mr-1" style="border: 1px solid #e4e4e4" height="36">
                                            <a-checkbox v-model="include_success_criteria" @change="local_select_tc_sc(include_success_criteria, 'success_criteria')" label="Success Criteria" hide-details dense class="ma-0 pa-0"></a-checkbox>
                                        </a-sheet>
                                        <a-sheet class="u-flex align-center px-3 u-rounded-corners ml-1" style="border: 1px solid #e4e4e4" height="36">
                                            <a-checkbox v-model="include_testcase" @change="local_select_tc_sc(include_testcase, 'testcase')" label="Test Case" hide-details dense class="ma-0 pa-0"></a-checkbox>
                                        </a-sheet>
                                    </a-flex>
                                    <a-divider vertical class="grey lighten-2"></a-divider>
                                    <a-flex shrink class="text-xs-right py-0">
                                        <a-btn color="primary" medium class="u-rounded-corners elevation-0" :disabled="loading || !local_check_filter_applied" :loading="loading" @click="local_generate_report()">
                                            <a-icon class="mr-2" size="18">timeline</a-icon>
                                            Generate Report
                                        </a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>

        <span style="height: 74px; visibility: hidden; margin-top: -74px; display: block;" class="c-data-table"></span>
        <!-- #Download Buttons and Reports Table -->
        <a-container container--fluid grid-list-xl pa-6 px-0 pb-12 mb-12 v-if="local_report_list">
            <a-layout align-center v-if="local_report_list.length" class="mb-4">
                <a-flex>
                    <p class="md-body-2 mb-0 grey--text text--darken-1">Showing <strong>{{ local_report_list.length }}</strong> {{ (local_report_list.length === 1) ? ' item' : ' items' }}</p>
                </a-flex>
                <a-flex shrink pr-2 v-if="local_report_list.length">
                    <a-btn small text color="grey darken-1" class="ma-0 u-rounded-corners u-border" @click="show_field_preference_list = true">
                        <a-icon left size="16" class="mr-2">tune</a-icon> Fields
                    </a-btn>
                </a-flex>
                <a-flex shrink pl-0 v-if="$can('reports.download') && $can('projects.download') && $can('usecases.update') && local_report_list.length">
                    <a-menu offset-y left bottom>
                        <template v-slot:activator="{ on }">
                            <a-btn small text color="grey darken-1" class="ma-0 u-rounded-corners u-border" v-on="on">
                                <a-icon left size="16" class="mr-2">save_alt</a-icon> Download <a-icon right size="18" class="ml-1">arrow_drop_down</a-icon>
                            </a-btn>
                        </template>
                        <a-list>
                            <a-list-item @click="local_download_csv()">
                                <a-list-item-title class="md-body-2">Download as CSV</a-list-item-title>
                            </a-list-item>
                            <a-list-item @click="local_download_excel()">
                                <a-list-item-title class="md-body-2">Download as Excel</a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
            </a-layout>

            <!-- Reports Table -->
            <a-layout align-start v-if="(local_report_list && local_report_list.length) && local_check_filter_applied">
                <a-flex pt-0 xs12>
                    <a-card class="u-border pa-0 u-rounded-corners" flat ref="reports_table">
                        <a-data-table
                            :headers="local_selected_headers"
                            :items="local_report_list"
                            :server-items-length="total_project_count"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :must-sort="true"
                            class="c-reports-table u-rounded-corners"
                            hide-default-footer fixed-header
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td v-for="header in local_selected_headers" :key="header.value" class="text-truncate">
                                        <template v-if="header.slug === 'workspace' && header.show && item.project">
                                            {{ (item.project.workspace) ? item.project.workspace.title : '–' }}
                                        </template>
                                        <template v-if="header.slug === 'stage' && header.show && item.project">
                                            {{ item.project.stage ? item.project.stage.name : '-' }}
                                        </template>
                                        <template v-if="header.slug === 'customer' && header.show && item.project">
                                            {{ (item.project.customer) ? item.project.customer.name : '–' }}
                                        </template>
                                        <template v-if="header.slug === 'project_title' && header.show">
                                            <router-link v-if="item.project" target="_blank" :to="{name: 'projects-overview', params: {id: item.project.id}}" class="blue--text text--darken-3" :title="item.project.title.length > 25 ? item.project.title : ''">{{ item.project.title | truncateText(25) }}</router-link>
                                        </template>
                                        <template v-if="header.slug === 'project_status' && header.show">
                                           <span class="text-capitalize"> {{ (item.project) ? item.project.status : '–' }} </span>
                                        </template>
                                        <template v-if="header.slug === 'health_color' && header.show && item.project">
                                            <div class="u-flex align-center">
                                                <a-icon v-if="!item.project.health_color || item.project.health_color == 'none'" class="" color="grey lighten-2" size="16">fiber_manual_record</a-icon>
                                                <a-icon v-if="item.project.health_color == 'green'" class="" color="green" size="16">fiber_manual_record</a-icon>
                                                <a-icon v-if="item.project.health_color == 'yellow'" class="" color="orange" size="16">fiber_manual_record</a-icon>
                                                <a-icon v-if="item.project.health_color == 'red'" class="" color="red" size="16">fiber_manual_record</a-icon>
                                                <span class="md-caption grey--text text--darken-1 ml-2 text-capitalize">{{ item.project.health_color }}</span>
                                            </div>
                                        </template>
                                        <template v-if="header.slug === 'deal_amount' && header.show && item.project">
                                            <g-currency tag="span" class="green--text text--darken-1 font-weight-medium" :value="item.project.deal_amount"></g-currency>
                                        </template>
                                        <template v-if="header.slug === 'project_result' && header.show && item.project">
                                            <template v-if="item.project && item.project.result">
                                                <div class="u-flex align-center mr-1 mb-1">
                                                <a-avatar size="20" color="green" v-if="item.project.result.status === 'Positive'" slot="activator">
                                                    <a-icon size="10" color="white">thumb_up</a-icon>
                                                </a-avatar>
                                                <a-avatar size="20" color="red" v-if="item.project.result.status === 'Negative'" slot="activator">
                                                    <a-icon size="10" color="white">thumb_down</a-icon>
                                                </a-avatar>
                                                <a-avatar size="20" color="indigo darken-2" v-if="item.project.result.status === 'Neutral'" slot="activator">
                                                    <a-icon size="10" color="white">linear_scale</a-icon>
                                                </a-avatar>
                                                <span class="ml-2">{{ item.project.result.value }}</span>
                                            </div>
                                            </template>
                                            <template v-else>
                                                -
                                            </template>
                                        </template>
                                        <template v-if="header.slug === 'usecase_title' && header.show && item.project">
                                            <template v-if="!item.module">
                                                <router-link target="_blank" :to="{ name: 'projects-usecases', params: { id: item.project.id } }" class="blue--text text--darken-3" :title="item.title.length > 50 ? item.title : ''">{{ item.title | truncateText(50) }}</router-link>
                                            </template>
                                            <router-link v-else target="_blank" :to="{ name: 'projects-usecases', params: { id: item.project_id } }" class="blue--text text--darken-3" :title="item.usecase_title.length > 50 ? item.usecase_title : ''">{{ item.usecase_title | truncateText(50) }}</router-link>
                                        </template>
                                        <template v-if="header.slug === 'resource_type' && header.show">
                                            <span class="text-capitalize">{{ item.module ? (item.module === 'testcase' ? 'Test Case' : item.module.split('_').join(' ')) : 'Use Case' }}</span>
                                        </template>
                                        <template v-if="header.slug === 'resource_title' && header.show">
                                            <span :title="item.title.length > 50 ? item.title : ''">{{ item.title | truncateText(50) }}</span>
                                        </template>
                                        <template v-if="header.slug === 'description' && header.show">
                                            <a-menu v-if="item.description_json" :close-on-content-click="false" open-on-hover content-class="u-elevation-custom-1 c-border" min-width="300" max-width="400" max-height="400" nudge-left="20" offset-x top>
                                                <template v-slot:activator="{ on }">
                                                    <span class="blue--text text--darken-1 md-caption u-rounded-corners" v-on="on">View Description</span>
                                                </template>
                                                <a-card class="px-4 pb-2 u-rounded-corners">
                                                    <div class="pt-4 white" style="position: sticky; top: 0">
                                                        <div class="u-flex align-center">
                                                            <h2 class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase">Description</h2>
                                                            <a-spacer></a-spacer>
                                                            <router-link tag="a" class="blue--text text--darken-2 text-uppercase font-weight-bold u-flex align-center md-caption" v-if="$can('projects.update-only') && $can('usecases.update')" :to="{name: 'projects-usecases-view', params: {id: item.project_id, usecase_id: (item.usecase_id ? item.usecase_id : item.id)}}">Edit</router-link>
                                                        </div>
                                                        <a-divider class="grey lighten-2 mt-2 mb-3"></a-divider>
                                                    </div>
                                                    <g-editor-box
                                                        v-model="item.description_json"
                                                        :can-update="false"
                                                        class="md-body-2"
                                                        hide-focus hide-footer
                                                    ></g-editor-box>
                                                </a-card>
                                            </a-menu>
                                            <p class="grey--text text--darken-1" v-else> - </p>
                                        </template>
                                        <template v-if="header.slug === 'status' && header.show">
                                            <!-- <span class="text-capitalize" v-if="!item.module">{{ (item.status) ? item.status : '–' }}</span> -->
                                            <span class="text-capitalize" v-if="!item.module"> - </span>
                                            <span class="text-capitalize" v-else>{{ (item.status) ? item.status.value : '–' }}</span>
                                        </template>
                                        <template v-if="header.slug === 'tags' && header.show">
                                            <span class="grey--text text--darken-1" v-if="item.tags && !item.tags.length"> - </span>
                                            <g-tags v-else v-for="tag in item.tags" :tag="tag" :key="tag.id" hide-clear-icon></g-tags>
                                        </template>
                                        <template v-if="header.slug === 'assignees' && header.show">
                                            <template v-if="item.assignees && item.assignees.length">
                                                <s-users-deck v-if="local_get_collab_value(header.id, item.assignees).length" class="justify-end" :list="local_get_collab_value(header.id, item.assignees)" :count="4" hide-role hide-list></s-users-deck>
                                                <template v-else><span class="ml-2 grey--text text--darken-1"> - </span></template>
                                            </template>
                                            <template v-else><span class="ml-2 grey--text text--darken-1"> - </span></template>
                                        </template>
                                        <template v-if="header.slug === 'start_date' && header.show">
                                            <g-moment v-if="item.start_date" tag="span" :value="item.start_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                            <span v-else class="grey--text text--darken-1"> - </span>
                                        </template>
                                        <template v-if="header.slug === 'due_date' && header.show">
                                            <g-moment v-if="item.due_date" tag="span" :value="item.due_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                            <span v-else class="grey--text text--darken-1"> - </span>
                                        </template>
                                        <template v-if="header.slug === 'time_est' && header.show">
                                            <template v-if="item.module && item.module === 'testcase'">
                                                {{ item.time_records_sum_duration_minutes ? local_get_time_text(item.time_records_sum_duration_minutes) : '0m' }}
                                                <template v-if="item.estimated_duration_text">
                                                    / {{ item.estimated_duration_text }}
                                                </template>
                                            </template>
                                            <span v-else class="grey--text text--darken-1"> - </span>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </a-data-table>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- #Field Preference List Dialog -->
        <a-dialog max-width="800" scrollable persistent v-model="show_field_preference_list">
            <a-card>
                <a-card-title class="pa-0">
                    <div style="width: 100%;">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <div class="u-flex align-start">
                                    <a-flex shrink class="pr-1 py-1">
                                        <a-avatar class="primary darken-1" size="45">
                                            <a-icon dark>tune</a-icon>
                                        </a-avatar>
                                    </a-flex>
                                    <a-flex class="py-1">
                                        <h2 class="md-heading-6 font-weight-bold primary--text text--darken-1">Fields</h2>
                                        <p class="md-body-2 mb-0 mt-n2">Manage fields displayed in generated report.</p>
                                    </a-flex>
                                </div>
                                <a-spacer></a-spacer>
                                <a-btn icon small @click="show_field_preference_list = false"><a-icon size="18">cancel</a-icon></a-btn>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                    </div>
                </a-card-title>
                <a-card-text class="pa-0">
                    <draggable v-model="table_headers" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                        <div v-for="(item, index) in table_headers" :key="item.value" class="js-draggable-file-list-item">
                            <a-divider v-if="index !== 0"></a-divider>
                            <a-container container--fluid grid-list-xl class="px-6 py-4">
                                <a-layout wrap align-center>
                                    <a-flex shrink class="js-drag-handle u-cursor-pointer">
                                        <a-icon class="grey--text text--lighten-1">drag_indicator</a-icon>
                                    </a-flex>
                                    <a-flex class="pr-0 js-drag-handle u-cursor-pointer">
                                        <h2 class="md-body-1 primary--text text--darken-1">{{ item.text }}</h2>
                                    </a-flex>
                                    <a-spacer></a-spacer>
                                    <a-flex shrink>
                                        <a-sheet class="u-cursor-pointer" @click="local_select_field(item, !item.show, index)">
                                            <a-checkbox color="blue" :input-value="item.show" class="ma-0 pa-0" hide-details></a-checkbox>
                                        </a-sheet>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </div>
                    </draggable>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!-- #Preset Save Dialog -->
        <a-dialog v-model="dialog_report_preset_save" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="40">
                                    <a-icon dark>assignment</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1">Save as Report</h2>
                                <p class="md-subtitle-2 mb-0">You can save the filters as report shortcuts.</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6" v-if="report_preset_item">
                        <a-layout wrap align-center>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                <a-text-field
                                    v-model="report_preset_item.name"
                                    placeholder="Enter Report Name"
                                    class="u-rounded-corners"
                                    background-color="neutral"
                                    :autofocus="true"
                                    solo flat hide-details
                                >
                                </a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="report_preset_response.server && report_preset_response.server.errors && report_preset_response.server.errors.name">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ report_preset_response.server.errors.name[0] }}
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16">info</a-icon>
                                    The name will be helpful to identify the report filters.
                                </span>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink>
                                <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_report_preset_store()" :loading="loading" :disabled="loading">Save Report</a-btn>
                                <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_close_save_report()" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!-- #Download Link Dialog -->
        <a-dialog max-width="480px" v-model="dialog_download_link" persistent>
            <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                <a-progress-linear class="my-0" height="3" color="orange darken-3" :indeterminate="true" v-if="loading"></a-progress-linear>
                <a-card-text class="pa-2">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout align-start>
                            <a-flex shrink>
                                <a-icon size="72" color="grey lighten-1">cloud_download</a-icon>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 mb-2">
                                    <span v-if="loading">Preparing file...</span>
                                    <span v-else>Ready to Download</span>
                                </h2>
                                <p class="md-body-2 mb-0 grey--text text--darken-1">
                                    <span v-if="loading">Success is preparing your report for download. It may take few seconds to get ready.</span>
                                    <span v-else>Your report is ready to download. Click on the "Save File" button to download the report.</span>
                                </p>
                                <a-btn :href="usecase_link" small class="elevation-0 px-3 ma-0 mt-3" color="primary" :disabled="loading" @click="local_download_save()">
                                    <a-icon size="14" class="mr-2">save</a-icon>
                                    Save File
                                </a-btn>
                                <a-btn class="elevation-0 px-3 ma-0 mt-3 ml-2" color="grey" small text @click="dialog_download_link = !dialog_download_link" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import marked from 'marked'
import loadWorkspace from '@/mixins/mixin-workspace'
import { SUsersDeck } from '@/config/config-shared-components'
import { calculateByMins } from '@/helpers/helper-time-tracker.js'
import mixinSearchUsers from '@/mixins/mixin-search-users'
import checkScopePermission from '@/mixins/mixin-checkscope-permission'

export default {
    mixins: [loadWorkspace, mixinSearchUsers, checkScopePermission],
    components: {
        SUsersDeck
    },
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            // pagination: {
            //     sortDesc: true,
            //     page: 1,
            //     itemsPerPage: -1, // -1 for All",
            //     sortBy: 'title',
            // },
            sortBy: 'title',
            sortDesc: true,
            headers: [
                { text: 'Workspace', align: 'left', sortable: false, value: 'workspace.title', slug: 'workspace', order: 0, default_order: 0, show: true, width: 165 },
                { text: 'Project Status', align: 'left', sortable: false, value: 'project_status', slug: 'project_status', order: 1, default_order: 1, show: true, width: 165 },
                { text: 'Project Stage', align: 'left', sortable: false, value: 'stage.order', slug: 'stage', order: 2, default_order: 2, show: true, width: 165 },
                { text: 'Project Result', align: 'left', sortable: false, value: 'project_result', slug: 'project_result', order: 3, default_order: 3, show: true, width: 150 },
                { text: 'Account', align: 'left', sortable: false, value: 'customer.name', slug: 'customer', order: 4, default_order: 4, show: true, width: 165 },
                { text: 'Project Title', align: 'left', sortable: false, value: 'project_title', slug: 'project_title', order: 5, default_order: 5, show: true, width: 165 },
                { text: 'Health Color', align: 'left', sortable: false, value: 'health_color', slug: 'health_color', order: 6, default_order: 6, show: true, width: 130 },
                { text: 'Deal Amount', align: 'left', sortable: false, value: 'deal_amount', slug: 'deal_amount', order: 7, default_order: 7, show: true, width: 150 },
                { text: 'Use Case Title', align: 'left', sortable: false, value: 'usecase_title', slug: 'usecase_title', order: 8, default_order: 8, show: true, width: 165 },
                { text: 'Resource Type', align: 'left', sortable: false, value: 'resource_type', slug: 'resource_type', order: 9, default_order: 9, show: true, width: 165 },
                { text: 'Resource Title', align: 'left', sortable: false, value: 'resource_title', slug: 'resource_title', order: 10, default_order: 10, show: true, width: 165 },
                { text: 'Description', align: 'left', sortable: false, value: 'description', slug: 'description', order: 11, default_order: 11, show: true, width: 120 },
                { text: 'Status', align: 'left', sortable: false, value: 'status', slug: 'status', order: 12, default_order: 12, show: true, width: 120 },
                { text: 'Tags', align: 'left', sortable: false, value: 'tags', slug: 'tags', order: 13, default_order: 13, show: true, width: 165 },
                { text: 'Assignees', align: 'left', sortable: false, value: 'assignees', slug: 'assignees', order: 14, default_order: 14, show: true, width: 165 },
                { text: 'Start Date', align: 'left', sortable: false, value: 'start_date', slug: 'start_date', order: 15, default_order: 15, show: true, width: 130 },
                { text: 'Due Date', align: 'left', sortable: false, value: 'due_date', slug: 'due_date', order: 16, default_order: 16, show: true, width: 130 },
                { text: 'Time Estimation', align: 'left', sortable: false, value: 'time_est', slug: 'time_est', order: 17, default_order: 17, show: true, width: 180 },
            ],
            selectedUcUsers: [],
            selectedScUsers: [],
            selectedTcUsers: [],
            include_testcase: true,
            include_success_criteria: true,
            show_field_preference_list: false,
            total_project_count: 0,
            dialog_download_link: false,
            expansion_panel_uc_assignees: false,
            expansion_panel_uc_status: false,
            expansion_panel_uc_visibility: false,
            expansion_panel_uc_tags: false,
            expansion_panel_tc_assignees: false,
            expansion_panel_tc_tags: false,
            expansion_panel_tc_dates: false,
            expansion_panel_sc_assignees: false,
            expansion_panel_sc_status: false,
            expansion_panel_sc_tags: false,
            show_date_field_error: false,
            datepicker_menu_1: null,
            datepicker_menu_2: null,
            date_value_1: null,
            date_value_2: null,
            date_condition: null,
            date_field: null,
            timeline_date_field_list: [
                { text: 'Start Date', value: 'START_DATE' },
                { text: 'Due Date', value: 'DUE_DATE' },
            ],
            custom_download_report_fields: {},
            dialog_report_preset_save: false,
            filter_name: null,
            show_edit_filter_input: false,
            generate_click: true,
            preference_edit: false,
            local_loadings: { value: false, type: null },
            report_filters: {},
            usecase_report_filters: {},
            testcase_report_filters: {},
            success_criteria_report_filters: {},
            report_filters_cloned: {},
            search_report: false,
            local_search_report: '',
            report_mode: true,
            testcase_status_list: [],
            success_criterion_status_list: [],
            datepicker_start_date: null,
            datepicker_due_date: null,
            meta_fields: {
                'fields[metas]': 'id,type,value,status',
                'sort': 'order'
            },
            local_tag_fields: {
                'filter[type]': 'usecase_tag',
                'fields[tags]': 'id,label,type,color'
            },
            usecase_filter: {
                STATUS: 'filter[status]',
                ASSIGNEES: 'filter[usecase_assignees]',
                VISIBILITY: 'filter[visibility]',
                TESTCASE_ASSIGNEES: 'filter[testcase_assignees]',
                TESTCASE_STATUS: 'filter[testcase_status]',
                TESTCASE_TAGS: 'filter[testcase_tags]',
                TESTCASE_START_DATE: 'filter[testcase_start_date]',
                TESTCASE_DUE_DATE: 'filter[testcase_due_date]',
                SC_ASSIGNEES: 'filter[success_criteria_assignees]',
                SC_STATUS: 'filter[success_criteria_status]',
                SC_TAGS: 'filter[success_criteria_tags]',
                TAGS: 'filter[usecase_tags]',
                DOWNLOAD: 'download',
                COUNT: 'count',
                PAGE: 1
            },
            usecase_includes: 'tags,assignees,project.customer,project.workspace,project.stage,project.result',
            testcase_includes: 'testcases.assignees,testcases.status,testcases.tags',
            success_criteria_includes: 'success_criteria.assignees,success_criteria.status,success_criteria.tags',
            testcase_params: {
                'fields[testcases]': 'id,title,description_json,status_id,usecase_id,start_date,due_date,estimated_duration_minutes,estimated_duration_text',
                'aggregate[testcases.time_records.duration_minutes]': 'sum',
                'fields[testcases.tags]': 'tags.id,tags.label,tags.type,tags.color',
                'fields[testcases.assignees]': 'assignees.id,name,color,initial,avatar,scope',
            },
            success_criteria_params: {
                'fields[successCriteria]': 'id,title,description_json,status_id,usecase_id',
                'fields[successCriteria.tags]': 'tags.id,tags.label,tags.type,tags.color',
                'fields[successCriteria.assignees]': 'assignees.id,name,color,initial,avatar,scope',
            },
            usecase_params: {
                include: 'tags,assignees,project.customer,project.workspace,project.stage,project.result',
                'fields[usecases]': 'id,title,description_json,status,visibility,project_id',
                'fields[tags]': 'tags.id,tags.label,tags.type,tags.color',
                'fields[assignees]': 'assignees.id,name,color,initial,avatar,scope',
                'fields[project]': 'id,title,health_color,deal_amount,customer_id,workspace_id,stage_id,result_id,status',
                'fields[project.stage]': 'id,name',
                'fields[project.result]': 'id,value,status',
                'fields[project.customer]': 'id,name',
                'fields[project.workspace]': 'id,title',
            },
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' }
        }
    },
    watch: {
        search_report (newValue) {
            if (newValue) this.local_search_report = null
        },

        sortBy: {
            handler (newData) {
                // const filters = this.project_filters
                // const dataSortOrder = !this.sortDesc ? '-' : ''

                // if (this.loading) return
                // filters[this.filter.SORT] = newData
                // filters[this.filter.SORT] = filters[this.filter.SORT].charAt(0) === '-' ? filters[this.filter.SORT].substring(1) : filters[this.filter.SORT]
                // filters[this.filter.SORT] = `${dataSortOrder}${filters[this.filter.SORT]}`
                // this.local_sort_project_list()
            },
            deep: true
        },
        sortDesc: {
            handler (data) {
                // const filters = this.project_filters
                // const dataSortOrder = !data ? '-' : ''

                // if (this.loading) return
                // filters[this.filter.SORT] = this.sortBy
                // filters[this.filter.SORT] = filters[this.filter.SORT].charAt(0) === '-' ? filters[this.filter.SORT].substring(1) : filters[this.filter.SORT]
                // filters[this.filter.SORT] = `${dataSortOrder}${filters[this.filter.SORT]}`
                // this.local_sort_project_list()
            },
            deep: true
        },
    },

    mounted() {
        this.local_index()
    },

    computed: {
        table_headers: {
            get () {
                return _.sortBy(this.headers, ['order'])
            },
            set (list) {
                this.local_table_field_reorder(list)
            }
        },

        local_usecase_status () {
            return [
                { text: 'Open', value: 'open' },
                { text: 'Closed', value: 'closed' },
            ]
        },

        local_usecase_visibility () {
            return [
                { text: 'All', value: 'all' },
                { text: 'Internal', value: 'internal' },
                { text: 'External', value: 'external' },
            ]
        },

        tag_list_filtered() {
            return _.reduce(_.map(this.tag_list, (item) => {
                return item.id ? _.pick(item, ['id', 'label', 'type', 'color']) : false
            }), (result, value, key) => {
                if (value !== false) result.push(value)
                return result
            }, [])
        },

        loading: {
            get() {
                return this.$store.state.Interface.loader
            },
            set(loading) {
                this.$store.state.Interface.loader = loading
            }
        },

        local_check_uc_filters_applied () {
            return _.size(this.usecase_report_filters[this.usecase_filter.ASSIGNEES]) !== 0 || _.size(this.usecase_report_filters[this.usecase_filter.STATUS]) !== 0 ||
                    _.size(this.usecase_report_filters[this.usecase_filter.TAGS]) !== 0 || this.usecase_report_filters[this.usecase_filter.VISIBILITY]
                    ? true : false
        },

        local_check_tc_filters_applied () {
            return this.include_testcase &&
                    (_.size(this.testcase_report_filters[this.usecase_filter.TESTCASE_ASSIGNEES]) !== 0 || _.size(this.testcase_report_filters[this.usecase_filter.TESTCASE_TAGS]) !== 0 ||
                    _.size(this.testcase_report_filters[this.usecase_filter.TESTCASE_STATUS]) !== 0 || this.local_check_date_field() || this.date_condition || this.date_value_1 || this.date_value_2)
                    ? true : false
        },

        local_check_sc_filters_applied () {
            return this.include_success_criteria &&
                    (_.size(this.success_criteria_report_filters[this.usecase_filter.SC_ASSIGNEES]) !== 0 || _.size(this.success_criteria_report_filters[this.usecase_filter.SC_TAGS]) !== 0 ||
                    _.size(this.success_criteria_report_filters[this.usecase_filter.SC_STATUS]) !== 0)
                    ? true : false
        },

        local_check_filter_applied () {
            return this.local_check_uc_filters_applied || this.local_check_tc_filters_applied || this.local_check_sc_filters_applied
        },

        local_selected_headers() {
            return _.sortBy(_.filter(this.table_headers, 'show'), ['order'])
        },

        local_preset_table_fields () {
            let table_fields = this.report_preset_item.fields && this.report_preset_item.fields.length ? this.report_preset_item.fields : null
            if (!table_fields || !table_fields.length) {
                this.local_set_preset_table_fields()
                return
            }

            const updatedFields = this.headers.filter(item => {
                const field = table_fields.find(i => i.slug === item.slug)
                if (field) {
                    item.show = field.show
                    item.order = field.order
                    return item
                }
                return item
            })
            return this.headers = _.sortBy(updatedFields, ['order'])
        },

        local_filtered_report_list () {
            if (!this.local_search_report || (this.local_search_report && !this.local_search_report.trim())) return this.report_preset_list
            return this.report_preset_list.filter(item => item.name.toLowerCase().includes(this.local_search_report.toLowerCase()))
        },

        local_report_list () {
            const usecase_flattened = []
            this.usecase_list.forEach(usecase => {
                const { testcases, success_criteria } = _.cloneDeep(usecase)
                const project = { project: usecase.project, project_id: usecase.project_id, usecase_title: usecase.title, usecase_id: usecase.id }
                delete usecase.success_criteria
                delete usecase.testcases
                usecase_flattened.push(usecase)
                if (success_criteria) {
                    success_criteria.forEach(success_criteria => usecase_flattened.push({ ...success_criteria, ...project, module: 'success_criteria' }))
                }
                if (testcases) {
                    testcases.forEach(testcase => usecase_flattened.push({ ...testcase, ...project, module: 'testcase' }))
                }
            })
            return usecase_flattened
        },

        ...mapState('User', {
            user_self: 'self',
            user_list: 'list',
        }),

        ...mapState('Usecase', {
            usecase_list: 'list',
            usecase_meta: 'meta',
            usecase_link: 'links',
        }),

        ...mapState('Meta', {
            meta_filters: 'filters',
            meta_list: 'list',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
            tag_filters: 'filters',
        }),

        ...mapState('Workspace', {
            workspace_list: 'list',
            workspace_filters: 'filters'
        }),

        ...mapState('ReportPreset', {
            report_preset_list: 'list',
            report_preset_item: 'item',
            report_preset_response: 'response',
        }),

        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key',
        }),

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),
    },

    methods: {
        async local_index () {
            if (!this.$can('reports.index') || !this.$can('usecases.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.local_project_checkscope()
            this.usecase_clear()
            this.local_report_preset_start_over()
            this.user_index({ include: 'role', count: 999, page: 1 })
            this.tag_index(this.local_tag_fields)
            this.local_load_meta()
            await this.report_preset_index({ 'filter[type]': 'usecase' })
            this.loading = false
        },

        async local_load_meta () {
            await this.local_get_testcase_status_list()
            this.local_get_success_criterion_status_list()
        },

        local_set_all_selected_users () {
            this.local_set_selected_users('uc')
            this.local_set_selected_users('sc')
            this.local_set_selected_users('tc')
        },

        local_set_usecase_filter (key, value, type, module = null) {
            if ((value && value.length === 0) || !value) {
                if (type === 'usecase') delete this.usecase_report_filters[key]
                if (type === 'testcase') delete this.testcase_report_filters[key]
                if (type === 'success_criteria') delete this.success_criteria_report_filters[key]
                delete this.report_filters[key]
            }
            if (module === 'assignees') {
                if (type === 'usecase') this.local_set_selected_users('uc')
                if (type === 'success_criteria') this.local_set_selected_users('sc')
                if (type === 'testcase') this.local_set_selected_users('tc')
            }
        },

        async local_set_selected_users (type) {
            let userIds = []
            if (type === 'uc') userIds = this.selectedUcUsers.map(i => i.id)
            if (type === 'sc') userIds = this.selectedScUsers.map(i => i.id)
            if (type === 'tc') userIds = this.selectedTcUsers.map(i => i.id)

            if (userIds && !userIds.length) {
                this.local_get_selected_filter_modules(true)
                return this.local_set_users(userIds, type)
            }

            await this.local_user_index(userIds)
            this.local_set_users(userIds, type)
        },

        async local_user_index (userIds) {
            await this.user_index({
                'filter[user_ids]': userIds,
                'fields[role]': 'id,name,scope',
                'fields[users]': 'id,name,initial,scope,avatar,color,role_id,is_active',
                include: 'role', count: 20, page: 1,
            })
        },

        local_set_users (userIds, type) {
            if (type === 'uc') this.$set(this.usecase_report_filters, this.usecase_filter.ASSIGNEES, userIds)
            if (type === 'sc') this.$set(this.success_criteria_report_filters, this.usecase_filter.SC_ASSIGNEES, userIds)
            if (type === 'tc') this.$set(this.testcase_report_filters, this.usecase_filter.TESTCASE_ASSIGNEES, userIds)
            this.local_get_selected_filter_modules(true)
        },

        async local_generate_report () {
            if (!this.local_check_date_condition()) return
            this.show_date_field_error = false
            await this.usecase_clear()
            const filters = await this.local_get_selected_filters_to_generate()
            if (!this.local_check_filter_applied) return this.$notify('error', 'No filters applied.')
            await this.usecase_report_index({
                ...filters,
                ...this.local_get_selected_includes(),
            })
            this.$vuetify.goTo('.c-data-table')
            this.total_project_count = this.local_report_list.length

            if (this.local_report_list && this.local_report_list.length <= 0 && this.all_list.length === 2) {
                this.$notify('error', 'No data found for the selected filters.')
            }
            if (this.local_report_list.length <= 0 && this.filtered_list.length >= 1 ) {
                this.$notify('error', 'There are no projects available to display.The view may affected due to role based access control. Please check your configuration or contact your admin.')
            }
        },

        async local_apply_preset_assignees () {
            const { usecase: ucFilter, success_criteria: scFilter, testcase: tcFilter } = this.report_preset_item.filters

            if (ucFilter && ucFilter[this.usecase_filter.ASSIGNEES] && ucFilter[this.usecase_filter.ASSIGNEES].length) {
                await this.local_user_index(ucFilter[this.usecase_filter.ASSIGNEES])
                this.local_filter_set_selected_users(this.selectedUcUsers)
                this.local_set_users(ucFilter[this.usecase_filter.ASSIGNEES], 'uc')
            }

            if (scFilter && scFilter[this.usecase_filter.SC_ASSIGNEES] && scFilter[this.usecase_filter.SC_ASSIGNEES].length) {
                await this.local_user_index(scFilter[this.usecase_filter.SC_ASSIGNEES])
                this.local_filter_set_selected_users(this.selectedScUsers)
                this.local_set_users(scFilter[this.usecase_filter.SC_ASSIGNEES], 'sc')
            }

            if (tcFilter && tcFilter[this.usecase_filter.TESTCASE_ASSIGNEES] && tcFilter[this.usecase_filter.TESTCASE_ASSIGNEES].length) {
                await this.local_user_index(tcFilter[this.usecase_filter.TESTCASE_ASSIGNEES])
                this.local_filter_set_selected_users(this.selectedTcUsers)
                this.local_set_users(tcFilter[this.usecase_filter.TESTCASE_ASSIGNEES], 'tc')
            }
        },

        local_filter_set_selected_users (selectedArr) {
            this.user_list.forEach(user => {
                const index = selectedArr.findIndex(i => i.id === user.id)
                if (index === -1) selectedArr.push(user)
            })
        },

        async local_get_selected_filters_to_generate () {
            const table_includes = { show_testcase: this.include_testcase, show_success_criteria: this.include_success_criteria }
            let filters = { usecase: { ...this.usecase_report_filters } }

            if (this.include_testcase) filters['testcase'] = { ...this.testcase_report_filters }
            if (this.include_success_criteria) filters['success_criteria'] = { ...this.success_criteria_report_filters }

            const resultFilters = { ...filters.usecase, ...filters.testcase, ...filters.success_criteria, ...table_includes }
            return resultFilters
        },

        local_get_selected_includes () {
            let params = { ...this.usecase_params, 'filter[workspace_id]': this.current_workspace_id_mixin }
            let includes = this.usecase_includes
            if (this.include_testcase) {
                includes = includes + ',' + this.testcase_includes
                params = { ...params, ...this.testcase_params }
            }
            if (this.include_success_criteria) {
                includes = includes + ',' + this.success_criteria_includes
                params = { ...params, ...this.success_criteria_params }
            }
            params['include'] = includes
            return params
        },

        // SC and TC select
        async local_select_tc_sc (value, type) {
            this.report_filters['show_' + type] = value
            if (this.report_preset_item && this.report_preset_item.id) {
                await this.usecase_clear()
                this.local_report_preset_update()
            }
            if (!this.include_testcase && !this.include_success_criteria && !this.local_check_uc_filters_applied) this.usecase_clear()
        },
        // SC and TC select ----- END

        // Filters
        local_remove_filter_item (type = null, item) {
            this.report_filters[type] = _.remove(this.report_filters[type], (data) => data !== item)
        },

        local_tag_filter_remove (id, key, tags_selected, type) {
            tags_selected.splice(tags_selected.indexOf(id), 1)
            this.local_set_usecase_filter(key, tags_selected, type)
        },

        local_set_tc_date_filter () {
            const date_field = this.usecase_filter['TESTCASE_' + this.date_field]
            const condition = this.date_condition
            if (!this.date_field && !this.date_condition && !this.date_value_1 && !this.date_value_2) {
                this.local_reset_testcase_dates()
            } else {
                this.local_reset_testcase_dates()
                this.testcase_report_filters[date_field] = `${condition}${this.date_value_1 ? ',' + this.date_value_1 : ''}${this.date_value_2 ? ',' + this.date_value_2 : ''}`
            }
            this.local_get_selected_filter_modules(true)
        },

        local_reset_testcase_dates () {
            delete this.testcase_report_filters[this.usecase_filter['TESTCASE_START_DATE']]
            delete this.testcase_report_filters[this.usecase_filter['TESTCASE_DUE_DATE']]
            delete this.report_filters[this.usecase_filter['TESTCASE_START_DATE']]
            delete this.report_filters[this.usecase_filter['TESTCASE_DUE_DATE']]
        },

        local_check_timeline_filters (type = null) {
            let result = false
            if (type === 'date_condition') {
                result = !this.local_check_date_field() || !this.date_field || this.loading ? (this.date_condition ? false : true) : false
            }
            if (type === 'date_value_1') {
                result = !this.local_check_date_field() || !this.date_condition || this.loading ? this.date_value_1 ? false : true : false
            }
            if (type === 'date_value_2' && this.date_condition === 'between') {
                result = !this.local_check_date_field() || !this.date_condition || !this.date_value_1 || this.loading ? this.date_value_2 ? false : true : false
            }
            if (this.date_condition !== 'between') {
                this.date_value_2 = null
            }
            return result
        },

        local_check_date_field () {
            return this.testcase_report_filters[this.usecase_filter.TESTCASE_START_DATE] || this.testcase_report_filters[this.usecase_filter.TESTCASE_DUE_DATE]
        },

        local_check_date_condition () {
            if (this.date_field || this.date_condition || this.date_value_1 || this.date_value_2) {
                if (!this.date_field && (this.date_condition || this.date_value_1 || this.date_value_2)) return this.local_show_timeline_error()
                if (!this.date_condition && (this.date_field || this.date_value_1 || this.date_value_2)) return this.local_show_timeline_error()
                if (!this.date_condition) return this.local_show_timeline_error()
                else if (this.date_condition === 'between' && (!this.date_value_1 || !this.date_value_2)) return this.local_show_timeline_error()
                else if (this.date_condition && !this.date_value_1) return this.local_show_timeline_error()
                else return true
            }
            return true
        },

        local_show_timeline_error () {
            this.show_date_field_error = true
            this.$notify('error', 'Please fill all the respective testcase date fields')
        },
        // Filters ----- END

        // Preset CRUD
        async local_report_preset_select(id, type = null) {
            this.loading = true
            this.expanded_rows = []
            this.local_report_preset_start_over()

            setTimeout(async () => {
                await this.report_preset_select({ id })
                await this.local_apply_preset_assignees()
                this.local_clone_filters(this.report_preset_item.filters)
                await this.$nextTick()
                await this.local_report_preset_apply()
                this.filter_name = this.report_preset_item.name
                this.local_show_selected_filter_row()
                this.local_preset_table_fields
                this.loading = false
                this.local_generate_report()
            }, 0)
        },

        async local_report_preset_apply () {
            if (this.report_preset_item && this.report_preset_item.id) {
                const { usecase, testcase, success_criteria, show_testcase, show_success_criteria } = _.cloneDeep(this.report_preset_item.filters)
                this.usecase_report_filters = usecase ?? this.usecase_report_filters
                this.testcase_report_filters = testcase ?? this.testcase_report_filters
                this.success_criteria_report_filters = success_criteria ?? this.success_criteria_report_filters
                this.include_testcase = show_testcase
                this.include_success_criteria = show_success_criteria
                this.local_set_timeline_vars()
            }
        },

        local_set_timeline_vars () {
            const start_date = this.testcase_report_filters[this.usecase_filter.TESTCASE_START_DATE]
            const due_date = this.testcase_report_filters[this.usecase_filter.TESTCASE_DUE_DATE]

            if (start_date) {
                const [cond, date_1, date_2] = start_date.split(',')
                this.local_set_date_fields('START_DATE', cond, date_1, date_2)
            }
            if (due_date) {
                const [cond, date_1, date_2] = due_date.split(',')
                this.local_set_date_fields('DUE_DATE', cond, date_1, date_2)
            }
        },

        local_set_date_fields (field, cond, date_1, date_2) {
            this.date_field = field
            this.date_condition = cond
            this.date_value_1 = date_1
            this.date_value_2 = date_2
        },

        local_open_create_preset () {
            this.report_preset_clear_item()
            this.report_filters_cloned = {}
            this.dialog_report_preset_save = true
        },

        async local_close_save_report() {
            await this.report_preset_clear_response()
            if (this.report_preset_item && this.report_preset_item.name) this.report_preset_item.name = null
            this.dialog_report_preset_save = false
        },

        async local_report_preset_store () {
            if (!this.local_check_date_condition()) return
            await this.local_set_preset_table_fields()
            const filters = await this.local_get_selected_filter_modules(false, 'merge-tc-sc')
            await this.report_preset_store({
                filters,
                type: 'usecase',
                name: this.report_preset_item.name,
                fields: this.report_preset_item.fields
            })

            if (this.report_preset_response.status === 'error') return
            setTimeout(async () => {
                this.local_clone_filters()
                await this.local_report_preset_select(this.report_preset_item.id, 'store')
                this.dialog_report_preset_save = false
            }, 10)
        },

        async local_get_selected_filter_modules (flatten = false, filtered = true) {
            const table_includes = { show_testcase: this.include_testcase, show_success_criteria: this.include_success_criteria }
            const filters = { usecase: { ...this.usecase_report_filters } }

            if (filtered === true) {
                if (this.include_testcase) filters['testcase'] = { ...this.testcase_report_filters }
                if (this.include_success_criteria) filters['success_criteria'] = { ...this.success_criteria_report_filters }
            } else {
                filters['testcase'] = { ...this.testcase_report_filters }
                filters['success_criteria'] = { ...this.success_criteria_report_filters }
            }

            if (!flatten) return { ...filters, ...table_includes }
            this.report_filters = { ...filters.usecase, ...filters.testcase, ...filters.success_criteria, ...table_includes }
            return this.report_filters
        },

        async local_set_preset_table_fields () {
            const data = this.headers.map(item => ({ text: item.text, slug: item.slug, order: item.order, show: item.show }))
            this.report_preset_item.fields = data
        },

        async local_report_preset_update (no_select = false) {
            if (!this.local_check_date_condition()) return
            this.report_preset_item.name = this.filter_name
            this.report_preset_item.filters = await this.local_get_selected_filter_modules(false, 'merge-tc-sc')
            this.local_clone_filters(this.report_preset_item.filters)
            this.filter_name = null
            await this.local_set_preset_table_fields()
            await this.report_preset_update({id: this.report_preset_item.id})
            if (this.$status(this.report_preset_response)) {
                if (!no_select) {
                    setTimeout(async () => {
                        await this.local_report_preset_select(this.report_preset_item.id, 'store')
                        this.show_edit_filter_input = false
                    }, 10)
                }
                return
            }
            this.$notify('error', 'Please fix the errors occurred and try again')
        },

        async local_report_preset_destroy (id) {
            await this.report_preset_destroy({ id })
            this.local_report_preset_start_over()
        },

        local_report_preset_start_over () {
            this.show_date_field_error = false
            this.expanded_rows = []
            this.filter_name = null
            this.report_filters = {}
            this.usecase_report_filters = {}
            this.testcase_report_filters = {}
            this.success_criteria_report_filters = {}
            this.report_filters_cloned = {}
            this.include_testcase = true
            this.include_success_criteria = true
            this.selectedUcUsers = []
            this.selectedTcUsers = []
            this.selectedScUsers = []
            this.local_reset_table_headers()
            this.local_show_selected_filter_row()
            this.local_set_date_fields(null, null, null, null)
            this.usecase_clear()
            this.report_preset_clear_item()
        },

        local_reset_table_headers () {
            const updatedFields = this.headers.filter(item => {
                item.show = true
                item.order = item.default_order
                return item
            })
            this.headers = _.sortBy(updatedFields, ['order'])
        },

        local_show_filter_edit () {
            if (this.report_preset_item && this.report_preset_item.name) {
                this.show_edit_filter_input = true
                this.filter_name = this.report_preset_item.name
            }
        },
        // Preset CRUD ----- END

        // Fields popup ops
        local_select_field (item, visible, index) {
            let report_item = this.report_preset_item.fields.find(i => i.slug === item.slug)
            report_item ? report_item.show = visible : null
            item.show = visible
            this.report_preset_item && this.report_preset_item.id ? this.local_field_preference_update() : null
        },

        async local_field_preference_update () {
            await this.local_set_preset_table_fields()
            this.report_preset_update({id: this.report_preset_item.id})
        },
        // Fields popup ops ----- END

        // Download report
        async local_download_csv () {
            this.dialog_download_link = true
            await this.local_set_custom_headers()
            this.report_filters.download_format = 'csv'
            this.report_preset_item.filters.download_format = 'csv'
            const includes = await this.local_get_selected_includes()
            this.usecase_download({ ...this.custom_download_report_fields, filters: {...this.report_filters }, includes })
        },

        async local_download_excel () {
            this.dialog_download_link = true
            await this.local_set_custom_headers()
            this.report_filters.download_format = 'xlsx'
            this.report_preset_item.filters.download_format = 'xlsx'
            const includes = await this.local_get_selected_includes()
            this.usecase_download({ ...this.custom_download_report_fields, filters: {...this.report_filters }, includes })
        },

        async local_set_custom_headers () {
            const headers = [], slugs = []
            this.headers.filter(item => {
                if (item.show) {
                    headers.push(item.text)
                    slugs.push(item.slug)
                }
            })
            this.custom_download_report_fields = { headers, slugs }
        },

        local_download_save () {
            this.dialog_download_link = false
        },
        // Download report ----- END

        // Extras
        async local_get_testcase_status_list() {
            await this.meta_index({ 'filter[type]': 'testcase_status', ...this.meta_fields })
            this.testcase_status_list = _.cloneDeep(this.meta_list)
        },

        async local_get_success_criterion_status_list() {
            await this.meta_index({ 'filter[type]': 'success_criterion_status', ...this.meta_fields })
            this.success_criterion_status_list = _.cloneDeep(this.meta_list)
        },

        local_clone_filters (filters = null) {
            const { usecase, testcase, success_criteria, show_testcase, show_success_criteria } = _.cloneDeep(filters ?? this.report_preset_item.filters)
            this.report_filters = _.cloneDeep({ ...usecase, ...testcase, ...success_criteria, show_testcase, show_success_criteria })
            this.report_filters_cloned = _.cloneDeep(this.report_filters)
        },

        local_equals (object_one, object_two) {
            return _.isEqual(object_one, object_two)
        },

        local_get_field_value (data, key, id) {
            const filteredArr = []
            _.forEach(id, item => filteredArr.push(_.find(data, { [key]: item })))
            return filteredArr
        },

        local_get_collab_value (type_id, collabs) {
            return collabs.filter(item => item.type_id === type_id)
        },

        local_get_html (content) {
            return marked(content, { sanitize: true })
        },

        local_get_time_text (value) {
            const { durationText } = calculateByMins(value)
            return durationText
        },

        async local_table_field_reorder(list) {
            this.headers = list.filter((item, index) => {
                item.order = index
                return item
            })
            this.report_preset_item.id ? this.local_field_preference_update() : null
        },

        local_show_selected_filter_row () {
            _.size(this.usecase_report_filters[this.usecase_filter.ASSIGNEES]) ? this.expanded_rows.push('usecase_assignees') : null
            _.size(this.usecase_report_filters[this.usecase_filter.STATUS]) ? this.expanded_rows.push('status') : null
            _.size(this.usecase_report_filters[this.usecase_filter.TAGS]) ? this.expanded_rows.push('usecase_tags') : null
            _.size(this.usecase_report_filters[this.usecase_filter.VISIBILITY]) ? this.expanded_rows.push('visibility') : null
            _.size(this.testcase_report_filters[this.usecase_filter.TESTCASE_ASSIGNEES]) ? this.expanded_rows.push('testcase_assignees') : null
            _.size(this.testcase_report_filters[this.usecase_filter.TESTCASE_STATUS]) ? this.expanded_rows.push('testcase_status') : null
            _.size(this.testcase_report_filters[this.usecase_filter.TESTCASE_TAGS]) ? this.expanded_rows.push('testcase_tags') : null
            _.size(this.testcase_report_filters[this.usecase_filter.TESTCASE_START_DATE]) ? this.expanded_rows.push('testcase_start_date') : null
            _.size(this.testcase_report_filters[this.usecase_filter.TESTCASE_DUE_DATE]) ? this.expanded_rows.push('testcase_due_date') : null
            _.size(this.success_criteria_report_filters[this.usecase_filter.SC_ASSIGNEES]) ? this.expanded_rows.push('success_criteria_assignees') : null
            _.size(this.success_criteria_report_filters[this.usecase_filter.SC_STATUS]) ? this.expanded_rows.push('success_criteria_status') : null
            _.size(this.success_criteria_report_filters[this.usecase_filter.SC_TAGS]) ? this.expanded_rows.push('success_criteria_tags') : null

            this.local_row_has_filters()
        },

        local_row_has_filters () {
            this.expansion_panel_uc_assignees = _.includes(this.expanded_rows, 'usecase_assignees')
            this.expansion_panel_uc_tags = _.includes(this.expanded_rows, 'usecase_tags')
            this.expansion_panel_uc_visibility = _.includes(this.expanded_rows, 'visibility')
            this.expansion_panel_uc_status = _.includes(this.expanded_rows, 'status')
            this.expansion_panel_tc_assignees = _.includes(this.expanded_rows, 'testcase_assignees')
            this.expansion_panel_tc_tags = _.includes(this.expanded_rows, 'testcase_tags')
            this.expansion_panel_tc_dates = _.includes(this.expanded_rows, 'testcase_start_date') || _.includes(this.expanded_rows, 'testcase_due_date')
            this.expansion_panel_sc_assignees = _.includes(this.expanded_rows, 'success_criteria_assignees')
            this.expansion_panel_sc_status = _.includes(this.expanded_rows, 'success_criteria_status')
            this.expansion_panel_sc_tags = _.includes(this.expanded_rows, 'success_criteria_tags')
        },

        async localSearchUsers (val) {
            const args = {
                'include': 'role',
                'fields[role]': 'id,name,scope',
                'fields[users]': 'id,name,initial,scope,avatar,color,role_id,is_active',
                'filter[is_active]': 1,
                'sort': 'name', count: 5, page: 1
            }
            await this.user_clear()
            await this.mixinSearchUsers(val, args)
        },

        ...mapActions('Usecase', {
            usecase_report_index: 'report_index',
            usecase_download: 'download',
            usecase_clear: 'clear',
        }),

        ...mapActions('Project', {
            project_index: 'index',
            project_download: 'download',
            project_clear: 'clear',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
        }),

        ...mapActions('User', {
            user_index: 'index',
            user_clear: 'clear',
        }),

        ...mapActions('Collaborator', {
            collaborator_index: 'index',
            collaborator_clear: 'clear',
        }),

        ...mapActions('ReportPreset', {
            report_preset_index: 'index',
            report_preset_store: 'store',
            report_preset_update: 'update',
            report_preset_destroy: 'destroy',
            report_preset_select: 'select',
            report_preset_clear: 'clear',
            report_preset_clear_response: 'clear_response',
            report_preset_clear_item: 'clear_item',
        }),
    }
}
</script>

<style style="scss" scoped>
    .column-users {
        max-width: 110px;
        text-align: right;
    }
    .u-alert-no-border {
        border-top: 0 !important;
    }
    .c-border {
        border: 1px solid #eaeaea !important;
    }
</style>

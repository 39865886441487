<template>
    <a-sheet v-bind="$attrs" class="transparent u-flex pt-3 pr-2" :min-height="120">
        <g-avatar :item="user" :size="48"></g-avatar>
        <div>
            <a-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <h2 class="text-subtitle-1 grey--text text--darken-3 font-weight-medium">{{ user.name | truncateText(16, '..') }}</h2>
                        <h4 class="md-body-2 grey--text text--darken-1" style="margin-top: -2px;">{{ user.role ? user.role.name : '' | truncateText(22, '..') }}</h4>
                    </div>
                </template>
                <g-profile-info :item="user" dark type-user text-center></g-profile-info>
            </a-tooltip>
            <div class="u-flex align-center" style="margin-top: 2px;">
                <h4 class="md-body-2 grey--text text--darken-2 font-weight-medium">
                    {{ user.projects_count }} Projects
                </h4>
                <span class="md-body-2 d-inline-block mx-1 grey--text text--darken-1">&bull;</span>
                <span class="md-body-2 font-weight-medium mr-2 green--text text--darken-1">{{ local_amount_formatter(user.deal_value) }}</span>
            </div>
            <div class="md-caption font-weight-medium mt-2 u-cursor-pointer" style="color: #7214ff" v-if="user.projects_count > 5" @click="$emit('toggle-userload', user.id)">
                {{ local_is_user_loaded(user.id) ? 'Show less' : 'Load more' }} projects
            </div>
        </div>
    </a-sheet>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        user: {
            type: Object,
            required: true
        },
        loadedIds: {
            type: Array
        }
    },

    computed: {
        ...mapState('User', {
            user_self: 'self'
        }),
    },

    methods: {
        local_amount_formatter (deal_value) {
            const currency_symbol = this.user_self.organization.currency.symbol
            let thousandSymbol = 'K'
            deal_value = parseInt(deal_value)

            if (deal_value > 999 && deal_value < 1000000) deal_value = (deal_value / 1000).toFixed(0)
            if (deal_value > 1000000) {
                deal_value = (deal_value / 1000000).toFixed(0)
                thousandSymbol = 'M'
            }

            deal_value = currency(deal_value, {
                formatWithSymbol: true,
                symbol: currency_symbol,
                useVedic: (this.user_self.organization.currency.code == 'INR') ? true : false,
                precision: 0
            }).format()
            return deal_value + thousandSymbol
        },

        local_is_user_loaded (user_id) {
            return this.loadedIds.includes(user_id)
        },
    }
}
</script>

<style>

</style>

<template>
    <a-flex class="py-0 mt-1">
        <a-card flat class="u-border pa-0 u-rounded-corners">
            <a-layout align-center class="px-0 grey lighten-4 mx-0 mt-0 mb-0 py-2 u-rounded-corners">
                <a-flex shrink xs4 xl5 class="pa-0 px-2">
                    <a-layout align-center class="ma-0 pa-0">
                        <!-- <a-flex shrink xs2 class="mx-0 py-0 px-2">
                            <h4 class="caption grey--text text--darken-3 font-weight-bold">Category</h4>
                        </a-flex> -->
                        <a-flex shrink xs5 class="mx-0 py-0 px-2">
                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold">Name</h4>
                        </a-flex>
                        <a-flex shrink xs4 class="mx-0 py-0 px-2">
                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-xs-left">Part #</h4>
                        </a-flex>
                        <a-flex shrink xs3 class="mx-0 py-0 px-2">
                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-xs-left">Serial #</h4>
                        </a-flex>
                    </a-layout>
                </a-flex>
                <a-flex shrink :class="[has_update_permission || has_delete_permission ? 'xs6 xl5' : 'xs6']" class="pa-0 px-2">
                    <a-layout align-center class="ma-0 pa-0">
                        <a-flex shrink xs4 class="mx-0 py-0 px-2">
                            <h4 class="caption text-uppercase grey--text text--darken-3 ml-3 font-weight-bold">Status</h4>
                        </a-flex>
                        <!-- <a-flex shrink xs2 class="mx-0 py-0 px-2">
                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-center">Stage</h4>
                        </a-flex> -->
                        <a-flex shrink xs3 class="mx-0 py-0 px-2">
                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-center">Next Available</h4>
                        </a-flex>
                        <a-flex shrink xs5 class="mx-0 py-0 px-2">
                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-xs-left">Project & Account</h4>
                        </a-flex>
                    </a-layout>
                </a-flex>
                <!-- <a-flex shrink xs2 class="pa-0 px-2 ml-auto" style="max-width: 200px !important"> -->
                <a-flex shrink :class="[has_update_permission || has_delete_permission ? 'xs2' : 'xs2 xl1 mr-2']" class="pa-0 px-2 ml-auto">
                    <a-layout align-center justify-end class="ma-0 pa-0">
                        <a-flex shrink :class="[has_update_permission || has_delete_permission ? 'xs5' : 'xs6']" class="mx-0 py-0 px-2" style="max-width: 100px !important">
                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-center">Assigned</h4>
                        </a-flex>
                        <a-flex shrink :class="[has_update_permission || has_delete_permission ? 'xs5' : 'xs6']" class="mx-0 py-0 px-2" style="max-width: 100px !important">
                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-center">Owner</h4>
                        </a-flex>
                        <a-flex shrink xs2 v-if="has_update_permission || has_delete_permission" class="pa-0 px-2 mr-1 text-xs-right">
                            &nbsp;
                        </a-flex>
                    </a-layout>
                </a-flex>
            </a-layout>
            <a-divider class="grey lighten-2"></a-divider>
            <a-card class="elevation-0" v-for="(inventory, group_index) in local_filtered_inventory_list" :key="inventory + '-'+ group_index">
                <a-layout align-center class="ma-0 pa-0 py-2" v-if="inventory">
                    <a-flex shrink xs4 xl5 class="pa-0 px-2">
                        <a-layout align-center class="ma-0 pa-0 u-cursor-pointer" @click="local_show_inventory_detail(inventory.id)">
                            <!-- <a-flex shrink xs2 class="pa-0 px-2">
                                <h4 class="caption  text-truncate" :title="inventory.category.value" v-if="inventory.category">{{ inventory.category.value }}</h4>
                                <h4 class="caption text-xs-left text-truncate" v-else>No Category</h4>
                            </a-flex> -->
                            <a-flex shrink xs5 class="pa-0 px-2">
                                <h4 class="md-caption text-truncate" :title="inventory.name">{{ inventory.name }}</h4>
                            </a-flex>
                            <a-flex shrink xs4 class="pa-0 px-2">
                                <h4 class="md-caption text-xs-left hidden-xl-only text-truncate" :title="inventory.part_no" v-if="inventory.part_no">{{ inventory.part_no }}</h4>
                                <h4 class="md-caption text-xs-left hidden-lg-and-down" :title="inventory.part_no" v-if="inventory.part_no">{{ local_truncate_text(inventory.part_no, 20) }}</h4>
                                <h4 class="md-caption text-xs-left text-truncate" v-else> - </h4>
                            </a-flex>
                            <a-flex shrink xs3 class="pa-0 px-2">
                                <h4 class="md-caption text-xs-left hidden-xl-only text-truncate" :title="inventory.serial_no" v-if="inventory.serial_no">{{ inventory.serial_no }}</h4>
                                <h4 class="md-caption text-xs-left hidden-lg-and-down" :title="inventory.serial_no" v-if="inventory.serial_no">{{ local_truncate_text(inventory.serial_no, 20) }}</h4>
                                <h4 class="md-caption text-xs-left text-truncate" v-else> - </h4>
                            </a-flex>
                        </a-layout>
                    </a-flex>
                    <a-flex shrink :class="[has_update_permission || has_delete_permission ? 'xs6 xl5' : 'xs6']" class="pa-0 px-2">
                        <a-layout align-center class="ma-0 pa-0">
                            <a-flex shrink xs4 class="pa-0 px-2 u-flex align-center u-cursor-pointer">
                                <div @click="local_show_inventory_detail(inventory.id)" class="u-relative">
                                    <div v-if="inventory.current_booked_items && inventory.current_booked_items.force_extended === 1" class="u-absolute" style="top: -10px; left: 18px; z-index: 1;">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <a-icon v-on="on" size="18" class="pa-1" color="orange darken-3">info</a-icon>
                                            </template>
                                            <span>Overdue</span>
                                        </a-tooltip>
                                    </div>
                                    <template v-if="inventory.current_booked_items">
                                        <a-tooltip bottom v-if="date_range">
                                            <template v-slot:activator="{ on }">
                                                <a-avatar v-on="on" size="30" color="orange darken-1">
                                                    <a-icon size="18" class="pa-1" color="white">block</a-icon>
                                                </a-avatar>
                                            </template>
                                            <span>{{ inventory.current_booked_items && inventory.current_booked_items.force_extended === 1 ? 'Unavailable and Overdue' : 'In Use' }}</span>
                                        </a-tooltip>
                                        <a-tooltip bottom v-else>
                                            <template v-slot:activator="{ on }">
                                                <a-avatar v-on="on" size="30" color="orange darken-1">
                                                    <a-icon size="18" class="pa-1" color="white">hourglass_empty</a-icon>
                                                </a-avatar>
                                            </template>
                                            <span>{{ inventory.current_booked_items && inventory.current_booked_items.force_extended === 1 ? 'In Use and Overdue' : 'In Use' }}</span>
                                        </a-tooltip>
                                    </template>
                                    <a-tooltip bottom v-if="inventory.is_available === 0 && !inventory.current_booked_items">
                                        <template v-slot:activator="{ on }">
                                            <a-avatar v-on="on" size="30" color="grey darken-1">
                                                <a-icon size="18" class="pa-1" color="white">block</a-icon>
                                            </a-avatar>
                                        </template>
                                        <span>Unavailable</span>
                                    </a-tooltip>
                                    <a-tooltip bottom v-if="inventory.is_available === 1 && !inventory.current_booked_items">
                                        <template v-slot:activator="{ on }">
                                            <a-avatar v-on="on" size="30" color="light-green lighten-3">
                                                <a-icon size="18" class="pa-1" color="green darken-2">check</a-icon>
                                            </a-avatar>
                                        </template>
                                        <span>Available</span>
                                    </a-tooltip>
                                </div>
                                <div class="ml-4 mt-1 u-wfull text-xs-left" style="min-height: 32px; max-height: 32px;">
                                    <template v-if="inventory.current_booked_items && !date_range">
                                        <a-menu offset-y content-class="u-rounded-corners u-elevation-custom-1 white u-border" max-width="350" :disabled="!$can('inventories.update')" class="u-wfull">
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on" class="u-flex align-center u-wfull px-2 u-border u-rounded-corners" style="padding-top: 4px !important; padding-bottom: 4px !important;" :style="{backgroundColor: (inventory.custom_status !== null ? $color(inventory.custom_status.color, 'color_bg') : '#eee')}">
                                                    <span v-if="inventory.custom_status" class="md-caption u-rounded-corners d-inline-block mx-auto" style="min-width: 50px;" :title="inventory.custom_status.label" :style="{backgroundColor: $color(inventory.custom_status.color, 'color_bg'), color: $color(inventory.custom_status.color, 'color_text')}">{{ local_truncate_text(inventory.custom_status.label, $vuetify.breakpoint.lgAndDown ? 12 : 22, '..') }}</span>
                                                    <span v-else class="u-rounded-corners md-caption grey--text text--darken-2 d-inline-block mx-auto" style="min-width: 50px;">Select Stage</span>
                                                    <a-spacer></a-spacer>
                                                    <a-icon size="14" v-if="$can('inventories.update')" :style="{ color: inventory && inventory.custom_status ? $color(inventory.custom_status.color, 'color_text') : '#333'}">keyboard_arrow_down</a-icon>
                                                </div>
                                            </template>
                                            <template v-if="local_tag_inventory_status_list && local_tag_inventory_status_list.length">
                                                <a-list style="max-height: 300px;" class="overflow-y-auto py-1 u-list-condensed">
                                                    <a-list-item class="md-caption grey--text text--darken-2" @click="local_add_inventory_stage_tag(stage.id, inventory.id)" v-for="stage in local_tag_inventory_status_list" :key="stage.id">
                                                        {{ stage.label }}
                                                    </a-list-item>
                                                </a-list>
                                                <a-divider></a-divider>
                                                <a-list class="u-list-condensed py-0">
                                                    <a-list-item class="md-caption" @click="local_clear_inventory_stage_tag(inventory.id)">
                                                        <a-list-item-content class="md-caption red--text text--darken-1">Remove Stage</a-list-item-content>
                                                    </a-list-item>
                                                </a-list>
                                            </template>
                                            <template v-if="local_tag_inventory_status_list && !local_tag_inventory_status_list.length">
                                                <a-list class="u-list-condensed py-1">
                                                    <a-list-item class="md-caption grey--text text--darken-2">
                                                        No Stage Found
                                                    </a-list-item>
                                                </a-list>
                                            </template>
                                        </a-menu>
                                    </template>
                                    <template v-else>
                                        <h4 class="md-caption px-2 mt-1"> - </h4>
                                    </template>
                                </div>
                            </a-flex>
                            <a-flex shrink xs3 class="pa-0 px-2 text-center u-cursor-pointer" @click="local_show_inventory_detail(inventory.id)">
                                <template v-if="!inventory.next_available">
                                     <a-tooltip bottom left>
                                        <template v-slot:activator="{ on }">
                                            <h4 class="md-caption text-center text-truncate" v-on="on">NA</h4>
                                        </template>
                                        <span>Unavailable</span>
                                     </a-tooltip>
                                </template>
                                <template v-else>
                                    <h4 class="md-caption text-center text-truncate" title="Now" v-if="!inventory.current_booked_items">Today</h4>
                                    <h4 class="md-caption text-center text-truncate" :title="inventory.current_booked_items ? local_format_date(inventory.next_available, false, true) : !inventory.current_booked_items ? 'Now' : 'Not Available'" v-else>{{ inventory.current_booked_items !== null ? local_format_date(inventory.next_available, false, true) : !inventory.current_booked_items === 'Available' ? 'Now' : 'Not Available' }}</h4>
                                </template>
                            </a-flex>
                            <a-flex shrink xs5 class="pa-0 px-2">
                                <a-layout align-center class="ma-0 pa-0">
                                    <a-flex class="pa-0" :class="{ 'xs8': inventory.current_booked_items && inventory.future_bookings && inventory.future_bookings.length}">
                                        <div class="u-flex align-center text-truncate" :class="{ 'c-poc-info-width': local_bridge_value(inventory, 'salesforce', 'opportunity.salesforce_id', 'opportunity.salesforce_opportunity_id') }" v-if="inventory.current_booked_items && inventory.current_booked_items.project" :title="`${inventory.current_booked_items.project.title} (${inventory.current_booked_items.project.customer.name})`">
                                            <router-link tag="h4" :to="{name: 'projects-inventories', params: {id: inventory.current_booked_items.project.id }}" class="md-caption u-cursor-pointer blue--text text--darken-2 text-xs-left d-block">{{ inventory.current_booked_items.project.title | truncateText(30) }}</router-link>
                                            <span class="md-caption ml-1 d-inline-block">({{ inventory.current_booked_items.project.customer.name | truncateText(15) }})</span>
                                        </div>
                                        <h4 class="md-caption text-xs-left text-truncate" v-else> - </h4>
                                    </a-flex>
                                    <a-flex shrink class="py-0" v-if="inventory.current_booked_items && inventory.future_bookings && inventory.future_bookings.length">
                                        <a-menu bottom right offset-y :close-on-content-click="false" :open-on-hover="true" max-width="300">
                                            <template v-slot:activator="{ on }">
                                                <a-card-text class="px-0 py-1 transparent elevation-0" v-on="on">
                                                    <span class="md-caption grey--text font-weight-normal">+{{ inventory.future_bookings.length }}</span>
                                                </a-card-text>
                                            </template>
                                            <a-card class="pa-3 u-elevation-custom-1 u-rounded-corners" style="border-top: 2px solid #888">
                                                <div>
                                                    <a-sheet v-for="(item, index) in local_filtered_future_bookings(inventory.current_booked_items, inventory.future_bookings).slice(0, 3)" class="c-future-booking-list" :key="index">
                                                        <div class="u-flex align-center" style="flex-wrap: wrap">
                                                            <h3 class="md-caption ma-0 grey--text text--darken-2 font-weight-medium d-inline-block mr-1" v-if="item.project">{{ item.project.title | truncateText(25) }}</h3>
                                                            <h3 class="md-caption ma-0 grey--text text--darken-2 font-weight-medium" v-if="item.project && item.project.customer">({{ item.project.customer.name | truncateText(15) }})</h3>
                                                        </div>
                                                        <span class="md-caption grey--text text--darken-2">{{ local_format_date(item.booking_from) }} - {{ local_format_date(item.booking_to) }}</span>
                                                    </a-sheet>
                                                </div>
                                                <router-link v-if="inventory.future_bookings.length > 3" tag="a" :to="{ 'name': 'booked-inventory' }" class="md-caption font-weight-medium mt-2 u-cursor-pointer blue--text text--darken-2 d-inline-block">View more ></router-link>
                                            </a-card>
                                        </a-menu>
                                    </a-flex>
                                    <a-flex shrink class="py-0">
                                        <span class="d-flex" v-if="local_bridge_value(inventory.current_booked_items, 'salesforce', 'opportunity.salesforce_id', 'opportunity.salesforce_opportunity_id')">
                                            <a-tooltip bottom left v-if="local_org_has_salesforce_url">
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on">
                                                        <a class="d-inline-block px-2" :href="user_self.organization.salesforce_instance_url + '/' + local_bridge_value(inventory.current_booked_items, 'salesforce', 'opportunity.salesforce_id', 'opportunity.salesforce_opportunity_id')" target="_blank" rel="noopener" v-on="on">
                                                            <a-img src="../../assets/images/icon-salesforce.svg" width="12px" contain alt="Salesforce"></a-img>
                                                        </a>
                                                    </span>
                                                </template>
                                                <span class="text-center">Imported from Salesforce.</span>
                                            </a-tooltip>
                                            <a-tooltip bottom left v-else>
                                                <template v-slot:activator="{ on }">
                                                    <span class="d-inline-block px-2" rel="noopener" v-on="on">
                                                        <a-img src="../../assets/images/icon-salesforce.svg" width="12px" contain alt="Salesforce"></a-img>
                                                    </span>
                                                </template>
                                                <span class="text-center">Imported from Salesforce.</span>
                                            </a-tooltip>
                                        </span>
                                    </a-flex>
                                </a-layout>
                            </a-flex>
                        </a-layout>
                    </a-flex>
                    <!-- <a-flex shrink xs2 class="pa-0 px-2 ml-auto" style="max-width: 200px !important"> -->
                    <a-flex shrink :class="[has_update_permission || has_delete_permission ? 'xs2' : 'xs2 xl1 mr-2']" class="pa-0 px-2 ml-auto">
                        <a-layout align-center justify-end class="ma-0 pa-0">
                            <a-flex shrink :class="[has_update_permission || has_delete_permission ? 'xs5' : 'xs6']" class="pa-0 px-2 text-center" style="max-width: 100px !important">
                                <template v-if="inventory.current_booked_items && inventory.current_booked_items.assignee && inventory.current_booked_items.assignee.length">
                                    <a-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <g-avatar :item="inventory.current_booked_items.assignee[0]"></g-avatar>
                                            </div>
                                        </template>
                                        <g-profile-info :item="inventory.current_booked_items.assignee[0]" dark type-user text-center></g-profile-info>
                                    </a-tooltip>
                                </template>
                                <template v-else>
                                    <div> - </div>
                                </template>
                            </a-flex>
                            <a-flex shrink :class="[has_update_permission || has_delete_permission ? 'xs5' : 'xs6']" class="pa-0 px-2 text-center" style="max-width: 100px !important">
                                <template v-if="inventory && inventory.owner">
                                    <a-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <g-avatar :item="inventory.owner"></g-avatar>
                                            </div>
                                        </template>
                                        <g-profile-info :item="inventory.owner" dark text-center type-user></g-profile-info>
                                    </a-tooltip>
                                </template>
                                <template v-else>
                                    <div> - </div>
                                </template>
                            </a-flex>
                            <a-flex shrink xs2 v-if="permission_inventory_update() || permission_inventory_delete()" class="pa-0 px-2 mr-1 text-xs-right">
                                <div class="d-inline-flex align-center">
                                    <a-menu offset-y bottom left transition="slide-y-transition" content-class="u-rounded-corners">
                                        <template v-slot:activator="{ on }">
                                            <a-btn class="ma-0 pa-0" small icon v-on="on">
                                                <a-icon class="grey--text">more_vert</a-icon>
                                            </a-btn>
                                        </template>
                                        <a-list class="u-list-condensed u-rounded-corners">
                                            <a-list-item v-if="inventory.current_booked_items && !date_range && permission_inventory_update()" extact :to="{ name: 'booking-extend', params: { id: inventory.current_booked_items.batch } }">
                                                <!-- <a-list-item-action>
                                                    <a-icon size="16" class="grey lighten-3 pa-1 u-rounded-corners" color="grey darken-1">memory</a-icon>
                                                </a-list-item-action> -->
                                                <a-list-item-content>
                                                    <a-list-item-title>Manage Inventory</a-list-item-title>
                                                </a-list-item-content>
                                            </a-list-item>
                                            <a-list-item v-if="permission_inventory_update()" @click="local_show_inventory_item(inventory.id)">
                                                <!-- <a-list-item-action>
                                                    <a-icon size="16" class="grey lighten-3 pa-1 u-rounded-corners" color="grey darken-1">edit</a-icon>
                                                </a-list-item-action> -->
                                                <a-list-item-content>
                                                    <a-list-item-title>Edit Inventory</a-list-item-title>
                                                </a-list-item-content>
                                            </a-list-item>
                                            <a-divider class="my-2" v-if="permission_inventory_update() && permission_inventory_delete()"></a-divider>
                                            <a-list-item v-if="permission_inventory_delete()" @click="local_inventory_destory(inventory.id, inventory.category)">
                                                <!-- <a-list-item-action style="min-width: 25px">
                                                    <a-icon size="16" class="red lighten-5 pa-1 u-rounded-corners" color="red darken-1">delete</a-icon>
                                                </a-list-item-action> -->
                                                <a-list-item-content>
                                                    <a-list-item-title class="red--text text--darken-1">Delete Inventory</a-list-item-title>
                                                </a-list-item-content>
                                            </a-list-item>
                                        </a-list>
                                    </a-menu>
                                </div>
                            </a-flex>
                        </a-layout>
                    </a-flex>
                </a-layout>
                <a-divider class="grey lighten-2" v-if="local_filtered_inventory_list.length - 1 !== group_index"></a-divider>
            </a-card>
        </a-card>
    </a-flex>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    props: {
        local_filtered_inventory_list: {
            type: Array
        },
        user_self: {
            type: Object
        },
        date_range: {
            type: Boolean
        }
    },

    data() {
        return {
            has_update_permission: false,
            has_delete_permission: false,
        }
    },

    computed: {
        local_tag_inventory_status_list() {
            return this.tag_list.filter(item => item.type === 'inventory_custom_status')
        },
        local_org_has_salesforce_url() {
            return this.user_self && this.user_self.organization && this.user_self.organization.salesforce_instance_url
        },
        ...mapState('Tag', {
            tag_list: 'list',
        }),
    },

    methods: {
        local_show_inventory_item (id) {
            this.$emit('local_show_inventory_item', id)
        },
        local_inventory_destory (id, category) {
            this.$emit('local_close_destroy_confirm', id)
        },
        local_show_inventory_detail (id) {
            this.$emit('local_show_inventory_detail', id)
        },
        async local_add_inventory_stage_tag(tag_id, inventory_id) {
            this.inventory_attach_custom_status({ tag_id, inventory_id })
        },
        local_clear_inventory_stage_tag(inventory_id) {
            this.inventory_clear_custom_status({ inventory_id })
        },
        local_filtered_future_bookings(current_booked_items, future_bookings)  {
            if (current_booked_items.id == future_bookings[0].id) {
                const index = _.findIndex(future_bookings, { 'id': current_booked_items.id });
                future_bookings.splice(index, 1);
                return future_bookings
            }else {
                return future_bookings
            }
        },
        local_bridge_value(inventory, service, salesforce_key, salesforce_opp_key) {
            if (inventory && inventory.project && inventory.project.bridges) {
                const salesforce = inventory.project.bridges.find(item => item.service === service && item.key === salesforce_key)
                const salesforce_opp = inventory.project.bridges.find(item => item.service === service && item.key === salesforce_opp_key)

                if (salesforce && salesforce.value) return salesforce.value
                if (salesforce_opp && salesforce_opp.value) return salesforce_opp.value
                return ''
            }
        },
        local_truncate_text(text, range, separator = '...') {
            if (!text) return

            return text.length > range ? text.slice(0, range) + separator : text
        },
        local_format_date(date, range = false, simple = false) {
            if (range) return moment(date).format('YYYY-MM-DD')
            if (simple) return moment(date).format('MMM D, YYYY')

            return date ? moment(date).format('MMM D, YYYY') : null
        },
        permission_inventory_update() {
            return this.has_update_permission = this.$can('inventories.update')
        },
        permission_inventory_delete() {
            return this.has_delete_permission = this.$can('inventories.destroy')
        },
        ...mapActions('Inventory', {
            // 'inventory_update_stage_tag': 'update_stage_tag',
            'inventory_attach_custom_status': 'attach_custom_status',
            'inventory_show': 'show',
            'inventory_clear_custom_status': 'clear_custom_status',
            inventory_destory: 'destroy',
        }),
        ...mapActions('Association', {
            association_store: 'store',
            association_destroy: 'destroy',
        }),
    }
}
</script>

<style lang="scss" scoped>
.c-future-booking-list {
    margin-bottom: 16px !important;
    &:last-child {
        margin-bottom: 0 !important;
    }
}
</style>

<template>
    <a-snackbar v-bind="$attrs" v-model="snackbar_status" :max-width="snackbar_content.extras.maxWidth" auto-height transition="scroll-y-transition" :color="snackbar_content.type" :timeout="snackbar_timeout" bottom center>
        <div class="u-flex align-start justify-center">
            <div :style="{ minWidth: '32px', marginTop: snackbar_content.extras.path ? '3px' : '0px' }">
                <a-icon size="20" color="white" class="mr-2">{{ local_get_icon }}</a-icon>
            </div>
            <div v-if="snackbar_content.extras.path" class="u-flex align-center">
                <div>{{ snackbar_content.text }}</div>
                <a-spacer></a-spacer>
                <a-btn :color="snackbar_content.type + ' lighten-4'" :class="['ml-2', `${snackbar_content.type} darken-1`]" small text @click="goTo(snackbar_content)">
                    {{ snackbar_content.extras.button_text }}
                </a-btn>
            </div>
            <div v-else>{{ snackbar_content.text }}</div>
        </div>
    </a-snackbar>
</template>

<script>
export default {
    inheritAttrs: false,

    mounted () {
        this.$getNotify()
    },

    data () {
        return {
            snackbar_status: false,
            snackbar_timeout: 3000,
            snackbar_content: { type: '', text: '', extras: {} },
        }
    },

    computed: {
        local_get_icon () {
            switch (this.snackbar_content.type) {
                case 'success': return 'task_alt'
                case 'error': return 'error_outline'
                case 'warning': return 'warning_amber'
                case 'info':
                default: return 'info'
            }
        }
    },

    methods: {
        goTo ({ extras: model }) {
            if (model.path_id) this.$router.push({ name: model.path, params: {id: model.path_id} })
            else this.$router.push({ name: model.path })
            this.snackbar_status = false
        }
    }
}
</script>

<template>
    <a-main>
        <g-settings-breadcrumb></g-settings-breadcrumb>

        <a-container grid-list-xl>
            <a-layout justify-center align-center>
                <a-flex xs9>
                    <h2 class="md-heading-4 font-weight-bold text-center">Let's setup Single Sign-on</h2>
                    <p class="md-subtitle-1 text-center grey--text text--darken-1">Single sign-on helps you simplify the authentication and new user onboarding process. Success uses WorkOS to connect with identity providers and supports Google, Okta, Microsoft ADFS, Azure AD and OneLogin.</p>
                </a-flex>
            </a-layout>

            <a-layout justify-center align-center>
                <a-flex xs12>
                    <a-stepper class="elevation-0 neutral" :value="current_step">
                        <a-stepper-header class="elevation-0">
                            <a-stepper-step :complete="current_step > 1" :step="1">
                                <span class="md-subtitle-2">Select Default Role</span>
                                <span class="md-caption grey--text text--lighten-1 mt-1" style="letter-spacing: 0.15px !important">For new user sign-up via SSO</span>
                            </a-stepper-step>

                            <a-divider></a-divider>

                            <a-stepper-step :complete="current_step > 2" :step="2">
                                <span class="md-subtitle-2">Connect Identity Provider</span>
                                <span class="md-caption grey--text text--lighten-1 mt-1" style="letter-spacing: 0.15px !important">Configure SAML settings and connect</span>
                            </a-stepper-step>

                            <a-divider></a-divider>

                            <a-stepper-step :step="3">
                                <span class="md-subtitle-2">Test Connection</span>
                                <span class="md-caption grey--text text--lighten-1 mt-1" style="letter-spacing: 0.15px !important">Logout and login using SSO</span>
                            </a-stepper-step>
                        </a-stepper-header>

                        <a-stepper-items>
                            <a-stepper-content :step="1">
                                <a-container container--fluid grid-list-xl>
                                    <a-layout wrap align-center>
                                        <a-flex xs8>
                                            <h2 class="md-heading-6 font-weight-bold">Select Default Role</h2>
                                            <p class="md-subtitle-1 grey--text text--darken-1">This default role will be an entry point for new users who automatically sign-up when logged in through single sign-on. You can also create a new role by navigating to <router-link :to="{name: 'settings-roles'}" class="md-subtitle-1 blue--text text--darken-2"> <a-icon size="16" class="u-icon-nudge" color="blue darken-2">settings</a-icon> Settings <a-icon size="16" class="u-icon-nudge" color="blue darken-2">arrow_forward</a-icon> Roles</router-link>.</p>
                                            <a-autocomplete
                                                v-model="role_entry_point"
                                                :items="role_list"
                                                item-text="name"
                                                item-value="uuid"
                                                class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden mb-3"
                                                solo flat hide-details
                                            ></a-autocomplete>
                                            <a-btn @click="local_setup_entry_point_role()" color="blue darken-2" class="elevation-0 u-rounded-corners ml-0" style="letter-spacing: 1px" :dark="role_entry_point !== null" :disabled="role_entry_point === null" :loading="loading">Continue</a-btn>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-stepper-content>

                            <a-stepper-content :step="2">
                                <a-container container--fluid grid-list-xl>
                                    <a-layout wrap align-center>
                                        <a-flex xs8>
                                            <h2 class="md-heading-6 font-weight-bold">Connect Identity Provider</h2>
                                            <p class="md-subtitle-1 grey--text text--darken-1">Success uses WorkOS to connect with identity providers and enable single sign-on. Click on the "Configure SSO" button to get started and follow the steps. You may need to enter technical information which is usually available with the IT Operations personnel.</p>
                                            <div
                                                class="workos-container"
                                                data-prop-app-name="Success"
                                                :data-prop-domain="user_self.organization.domain"
                                                :data-prop-name="user_self.organization.company_name"
                                                :data-prop-publishable-key="workos_publishable_key"
                                            ></div>
                                            <a-sheet class="red lighten-4 u-rounded-corners mt-4 py-2 px-3" v-if="sso_connection_confirm_status === 'error'">
                                                <span class="md-caption red--text text--darken-4">
                                                    <a-icon size="16" color="red darken-4" class="u-icon-nudge mr-1">error</a-icon>
                                                    We're unable to connect your identity provider. Please try again later or contact Success support for assistance.
                                                </span>
                                            </a-sheet>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-stepper-content>

                            <a-stepper-content :step="3">
                                <a-container container--fluid grid-list-xl>
                                    <a-layout wrap align-center>
                                        <a-flex xs8>
                                            <h2 class="md-heading-6 font-weight-bold">Connection Established</h2>
                                            <p class="md-subtitle-1 grey--text text--darken-1">Please logout and try logging in again with your single sign-on account.</p>
                                            <a-btn @click="local_user_me()" color="blue darken-2" class="elevation-0 u-rounded-corners ml-0" style="letter-spacing: 1px" dark>Done</a-btn>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-stepper-content>
                        </a-stepper-items>
                    </a-stepper>
                </a-flex>
            </a-layout>

            <script type="application/javascript" defer src="https://js.workos.com/v1"></script>
        </a-container>
    </a-main>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    let workos_publishable_key = process.env.MIX_WORKOS_PUBLISHABLE_KEY

    export default {
        data() {
            return {
                breadcrumb_items: [
                    {
                        text: 'Single Sign-on',
                        to: '/settings/sso/connect',
                        exact: true,
                    }
                ],
                current_step: 1,
                role_entry_point: null,
                sso_connection_confirm_status: null,
                sso_connection_confirm_response: null,
                workos_publishable_key: workos_publishable_key,
            }
        },
        mounted() {
            this.local_index()
            document.addEventListener('workos:providerLinked', (event) => {
                this.local_sso_connection_confirm(event)
                    .then((response) => {
                        this.sso_connection_confirm_status = 'success';
                        this.sso_connection_confirm_response = response.data;
                        this.current_step = 3
                    })
                    .catch((error) => {
                        this.sso_connection_confirm_status = 'error';
                        this.sso_connection_confirm_response = error.data;
                    });
            })
        },
        computed: {
            ...mapState('Interface', {
                loading: 'loader',
            }),
            ...mapState('User', {
                user_self: 'self',
            }),
            ...mapState('Role', {
                role_list: 'list',
                role_entry_point_item: 'entry_point_item',
            })
        },
        methods: {
            async local_index() {
                await this.local_validate_domain_entry()
                await this.local_validate_entry_point_role()
                await this.local_validate_sso_provider()

                await this.local_initiate_data()
            },
            async local_validate_domain_entry() {
                if (this.user_self && this.user_self.organization && this.user_self.organization.domain) {
                    return true;
                }

                this.$router.replace({name: 'settings-sso'})
            },
            async local_validate_entry_point_role() {
                await this.role_entry_point_show()
                if (this.role_entry_point_item && this.role_entry_point_item.uuid) {
                    this.current_step = 2;
                }
            },
            async local_validate_sso_provider() {
                if (this.user_self && this.user_self.organization && this.user_self.organization.sso_provider) {
                    this.current_step = 3;
                }

                return true;
            },
            async local_initiate_data() {
                if (this.current_step === 1) {
                    await this.role_index()
                }
            },
            async local_setup_entry_point_role() {
                await this.role_entry_point_store({uuid: this.role_entry_point})
                this.current_step = 2;
            },
            async local_sso_connection_confirm(event) {
                return axios.post('/sso/confirm', { token: event.detail.token })
            },
            async local_user_me() {
                await this.user_me()
                this.$router.replace({name: 'settings-sso'})
            },
            ...mapActions('User', {
                user_me: 'me',
            }),
            ...mapActions('Role', {
                role_index: 'index',
                role_entry_point_show: 'entry_point_show',
                role_entry_point_store: 'entry_point_store',
            }),
        },
    }
</script>

<style scoped>

</style>

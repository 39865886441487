<template>
    <div class="u-relative u-flex align-center my-2">
        <!-- Block List Menu -->
        <block-list-menu v-if="$can('document_generator.update')" @addBlock="methodStoreReportBlock"></block-list-menu>

        <!-- Block Information -->
        <div class="u-absolute" style="top: 50%; transform: translateY(-50%); left: -40px; z-index: 50">
            <a-menu offset-x left :nudge-top="50" v-model="dataSideMenu" transition="slide-x-transition" :close-on-content-click="false" min-width="350" max-width="350" style="max-width: 24px !important;" content-class="u-border c-radius u-elevation-custom-1">
                <template v-slot:activator="{ on }">
                    <a v-on="on" v-ripple="{ class: 'info--text' }" style="padding: 0 4px 0 4px;" class="c-component__menu u-flex align-center u-rounded-corners list-group-item js-drag-handle" :class="[ dataSideMenu ? 'is-active' : '' ]"><a-icon class="c-component__drag" size="26">drag_indicator</a-icon></a>
                </template>
                <div class="c-radius u-elevation-custom-1 py-3 px-4 white" style="max-width: 350px;">
                    <div class="u-flex align-center">
                        <h2 class="grey--text text--darken-3 font-weight-bold title" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.title }}</h2>
                        <a-spacer></a-spacer>
                        <a-btn small icon text color="red darken-1 ma-0" class="ma-0" v-if="$can('document_generator.destroy')" @click="methodRemoveComponent">
                            <svg width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-trash red--text text--darken-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a-btn>
                    </div>
                    <p class="grey--text text--darken-1 mb-0 body-2 font-weight-medium" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.description }}</p>
                    <template v-if="$can('document_generator.update')">
                        <a-divider class="grey lighten-3 mt-3 mb-2"></a-divider>
                        <div class="mt-4">
                            <template v-for="defBlockAttr in dataGetBlockInfo.attributes">
                                <div :key="defBlockAttr.id">
                                    <template v-if="defBlockAttr.title === 'Ratio'">
                                        <template v-for="option in defBlockAttr.options">
                                            <div :key="option.label" v-if="option.label === 'Width'">
                                                <div class="grey--text text--darken-1 text-uppercase body-2 font-weight-bold mb-1" style="letter-spacing: 1px;">{{ option.label }} - {{ currentWidth }}</div>
                                                <div>
                                                    <a-slider v-model="currentWidth" color="blue darken-1" step="8" prepend-icon="remove" append-icon="add" @change="methodAdjustRatio('width', defBlockAttr.id)" @click:append="methodIncrease('width')" @click:prepend="methodDecrease('width')" :max="512" :min="96"></a-slider>
                                                </div>
                                            </div>
                                            <div :key="option.label" v-if="option.label === 'Height'">
                                                <div class="grey--text text--darken-1 text-uppercase body-2 font-weight-bold mb-1" style="letter-spacing: 1px;">{{ option.label }} - {{ currentHeight }}</div>
                                                <div>
                                                    <a-slider v-model="currentHeight" color="blue darken-1" step="8" prepend-icon="remove" append-icon="add" @change="methodAdjustRatio('height', defBlockAttr.id)" @click:append="methodIncrease('height')" @click:prepend="methodDecrease('height')" :max="512" :min="96"></a-slider>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <div class="grey--text text--darken-1 text-uppercase body-2 mb-2 mt-0 font-weight-bold" style="letter-spacing: 1px;">{{ defBlockAttr.title }}</div>
                                        <a-layout align-center wrap mb-4>
                                            <template v-for="(menu, index) in defBlockAttr.options">
                                                <a-flex xs4 class="py-0" :class="[index === 0 ? 'px-0' : 'pl-2']" v-if="menu.value" :key="menu.id">
                                                    <a-btn small text :class="[methodBlockAttrs(clonedOrgLogo.id, defBlockAttr.id) && methodBlockAttrs(clonedOrgLogo.id, defBlockAttr.id).value.value === menu.value ? 'grey lighten-2' : '', index === 0 ? 'ml-0' : 'ml-0']" @click="methodAlignLogo(menu, defBlockAttr.id, methodBlockAttrs(clonedOrgLogo.id, defBlockAttr.id).id)" class="u-border u-wfull text-capitalize grey--text text--darken-1 body-2 font-weight-medium c-radius my-0 mr-0" style="min-height: 40px !important; min-width: 60px !important;">
                                                        {{ menu.value }}
                                                    </a-btn>
                                                </a-flex>
                                            </template>
                                        </a-layout>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </a-menu>
        </div>

        <!-- Block -->
        <div class="u-relative c-xspacing u-wfull my-2">
            <a-sheet class="transparent" :height="currentHeight" :class="[currentAlignment ? `text-${currentAlignment.value}` : 'text-center']">
                <template v-if="getOrgLogo">
                    <a-sheet :width="currentWidth" :height="currentHeight" class="transparent d-inline-flex align-center justify-center c-radius text-center" style="border: 1px solid #dfdfdf !important">
                        <img :src="getOrgLogo" :style="{ 'max-height': currentHeight + 'px', 'max-width': currentWidth + 'px' }" class="d-inline-block pa-2">
                    </a-sheet>
                </template>
                <!-- <template v-if="userSelf && userSelf.organization">
                    <a-sheet :width="currentWidth" :height="currentHeight" class="transparent d-inline-block c-radius" style="padding: 1px; border: 1px solid #dfdfdf !important">
                        <a-img :src="'//logo.clearbit.com/' + userSelf.organization.domain" width="100%" height="100%" contain></a-img>
                    </a-sheet>
                </template> -->
                <a-sheet v-if="getOrgLogo === null" class="d-inline-block" :height="currentHeight">
                    <a-sheet class="grey lighten-3 c-radius pa-2 u-flex align-center justify-center" :width="currentWidth" :height="currentHeight">
                        <svg width="50px" height="50px" viewBox="0 0 17 16" class="bi bi-image grey--text text--darken-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M14.002 2h-12a1 1 0 0 0-1 1v9l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094L15.002 9.5V3a1 1 0 0 0-1-1zm-12-1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm4 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                        </svg>
                    </a-sheet>
                </a-sheet>
            </a-sheet>
            <!-- <h2 style="z-index: 1; font-size: 18px; font-weight: 400 !important;" class="u-relative grey--text text--darken-2">Page Break</h2> -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import EventBus from '@/config/config-eventbus'
import BlockListMenu from '../BlockListMenu.vue'

export default {
    components: { BlockListMenu },

    props: ['item', 'index', 'list'],

    data () {
        return {
            clonedOrgLogo: this.item,
            dataSideMenu: false,
            currentRatio: '',
            currentAlignment: { label: 'Center', value: 'center' },
            currentFormatting: '',
            currentWidth: 100,
            currentHeight: 100,
            currentMenuValue: '',
            dataAutoSaveTimeout: null,
            format: {
                center: false,
                right: false,
                left: false,
            }
        }
    },

    mounted () {
        this.methodIndex()
    },

    watch: {
        currentWidth: {
            handler (value) {
                clearTimeout(this.dataAutoSaveTimeout)
                this.currentMenuValue = [{ label: 'Width', value: value }, { label: 'Height', value: this.currentHeight }]
                this.dataAutoSaveTimeout = setTimeout(() => {
                    this.methodUpdateBlockAttr()
                }, 500)
            },
            deep: true
        },

        currentHeight: {
            handler (value) {
                clearTimeout(this.dataAutoSaveTimeout)
                this.currentMenuValue = [{ label: 'Width', value: this.currentWidth }, { label: 'Height', value: value }]
                this.dataAutoSaveTimeout = setTimeout(() => {
                    this.methodUpdateBlockAttr()
                }, 500)
            },
            deep: true
        }
    },

    computed: {
        dataLogoRatio () {
            const defaultAttr = this.dataGetBlockInfo.attributes.find(item => item.title === 'Ratio')
            const ratioAttr = this.clonedOrgLogo.report_template_block_attributes.find(item => item.rb_attribute_id === defaultAttr.id)
            ratioAttr.value.forEach(item => item.label === 'Width' ? this.currentWidth = item.value : this.currentHeight = item.value)
        },

        dataLogoAlignment () {
            const defaultAttr = this.dataGetBlockInfo.attributes.find(item => item.title === 'Alignment')
            const ratioAttr = this.clonedOrgLogo.report_template_block_attributes.find(item => item.rb_attribute_id === defaultAttr.id)
            this.currentAlignment = ratioAttr.value
        },

        dataGetBlockInfo () {
            return this.getBlockContent(this.clonedOrgLogo.report_block_id)
        },

        ...mapGetters('ReportBlock', [
            'getDefaultBlockAttr',
            'getBlockContent'
        ]),

        ...mapGetters('ReportTemplateBlock', [
            'getBlockAttrs'
        ]),

        ...mapGetters('ReportTemplate', [
            'getBlock',
        ]),

        ...mapGetters('Organization', [
            'getOrgLogo'
        ]),

        ...mapGetters('User', {
            'userSelf': 'self'
        }),
    },

    methods: {
        async methodIndex () {
            this.dataLogoRatio
            this.dataLogoAlignment
            await this.organization_me()
        },

        methodBlockAttrs (blk_id, attr_id) {
            const attr = this.getBlockAttrs(blk_id, attr_id)
            this.currentAlignment = attr.value
            return attr
        },

        async methodAlignLogo (menu, attrDefId, attrId) {
            this.currentFormatting = { default_id: attrDefId, menu_attr_id: attrId }
            this.currentAlignment = menu.value
            this.currentMenuValue = menu
            await this.methodResetAlignment()
            await this.methodUpdateBlockAttr()
            setTimeout(() => {
                this.actionTemplateBlockShow({ id: this.clonedOrgLogo.id, index: this.index })
            }, 100);
        },

        async methodResetAlignment () {
            this.format.center = false
            this.format.left = false
            this.format.right = false
        },

        methodAdjustRatio (type, attr_id) {
            this.currentFormatting = {
                default_id: attr_id,
                menu_attr_id: this.methodBlockAttrs(this.clonedOrgLogo.id, attr_id).id
            }
        },

        methodIncrease (type) {
            if (type === 'width') return this.currentWidth = (this.currentWidth + 8) || 512
            this.currentHeight = (this.currentHeight + 8) || 512
        },

        methodDecrease (type) {
            if (type === 'width') return this.currentWidth = (this.currentWidth - 8) || 96
            this.currentHeight = (this.currentHeight - 8) || 96
        },

        async methodUpdateBlockAttr () {
            if(!this.$can('document_generator.update')) return
            const params = {
                rt_block_id: this.clonedOrgLogo.id,
                rb_attribute_id: this.currentFormatting.default_id,
                value: JSON.stringify(this.currentMenuValue)
            }
            if (params.rt_block_id && params.rb_attribute_id) {
                this.actionTemplateBlockAttrEdit({ id: this.currentFormatting.menu_attr_id ? this.currentFormatting.menu_attr_id : null, ...params })
                EventBus.$emit('editBlock', this.clonedOrgLogo)
            }
        },

        async methodStoreReportBlock (component) {
            await this.actionTemplateBlockStore({
                index: this.index + 1,
                data: { report_template_id: this.$route.params.template_id, report_block_id: component.id },
                params: { include: 'reportBlock' }
            })
            this.actionTemplateBlockReorder({ list: this.list })
            // EventBus.$emit('reorderBlock', { index: this.index + 1 })
            // EventBus.$emit('storeBlock', { index: this.index + 1, component })
        },

        methodRemoveComponent() {
            this.actionFindAndRemoveBlock(this.clonedOrgLogo)
            this.actionTemplateBlockDelete({ id: this.clonedOrgLogo.id })
            // EventBus.$emit('removeComponent', this.clonedOrgLogo)
        },

        ...mapActions('ReportTemplateBlock', [
            'actionTemplateBlockStore',
            'actionTemplateBlockAttrEdit',
            'actionTemplateBlockReorder',
            'actionTemplateBlockShow',
            'actionFindAndRemoveBlock',
            'actionTemplateBlockDelete'
        ]),

        ...mapActions('Organization', {
            organization_me: 'me',
        }),
    }
}
</script>

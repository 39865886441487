<template>
    <a-sheet
        v-on="$listeners"
        v-bind="$attrs"
        v-ripple="!disabled"
        class="c-action-btn u-shadow-light md-subtitle-1 font-weight-medium u-border u-rounded-corners-lg"
        :class="[{ 'u-cursor-pointer': !disabled }]"
    >
        <slot></slot>
    </a-sheet>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

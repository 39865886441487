const api_endpoint = '/report-timeline'

export default {
    namespaced: true,

    state: {
        list: [],
        response: [],
        defaults: {
            list: [],
            response: [],
        }
    },

    mutations: {
        UPDATE_LIST (state, payload) {
            state.list = payload
        },
        THROW_RESPONSE (state, payload) {
            state.response = payload
        }
    },

    actions: {
        async show ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                const { data } = await axios.get(api_endpoint, { params: payload })
                commit('UPDATE_LIST', data)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async throw_success ({ commit }) {
            commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            this.dispatch('Interface/loader_stop')
        },

        async throw_error ({ commit }, error) {
            this.dispatch('Interface/loader_stop')
            commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data })
        },

        async loader_start () {
            this.dispatch('Interface/loader_start')
        },

        async clear ({ commit }) {
            commit('UPDATE_LIST', [])
            commit('THROW_RESPONSE', [])
        },
    }
}

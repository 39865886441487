<template>
    <div
        class="u-flex-center-y u-cursor-pointer u-no-select px-3 py-2 mx-4"
        style="column-gap: 2px;"
        :class="[{ 'indigo darken-2 u-rounded-corners': isFolderOpen }]"
        @click="$emit('navigate', folder)"
    >
        <div
            class="u-flex-center-y u-cursor-pointer u-rounded-corners u-no-select u-wfull pl-1"
            style="column-gap: 6px;"
            :title="folder.name"
        >
            <v-fade-transition hide-on-leave leave-absolute>
                <a-icon v-if="isFolderOpen" size="20" color="white">$open_folder</a-icon>
                <a-icon v-else size="20" color="grey darken-1" outlined>folder</a-icon>
            </v-fade-transition>
            <h4 class="md-subtitle-1 text-truncate" :class="[!isFolderOpen ? 'grey--text text--darken-2' : 'white--text']">{{ folder.name }}</h4>
            <a-spacer></a-spacer>
            <a-icon size="20" :color="isFolderOpen ? 'white' : 'grey darken-1'">keyboard_arrow_right</a-icon>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        folder: {
            type: Object
        },
        isFolderOpen: {
            type: Boolean,
            default: false
        },
    }
}
</script>

<style>

</style>

<template>
    <div 
        v-bind="$attrs" 
        v-on="$listeners" 
        :id="usecase.id" 
        :data-child-count="local_scs_length + local_tcs_length" 
        class="c-usecase-item u-relative u-rounded-corners-lg c-focusable-parent" 
        :class="[{ 'c-create-focus' : usecase.loading_status === 'store' }, { 'js-draggable-file-list-item': local_can('update') && !bulkEditMode && !isExpanded }]"
    >
        <div 
            class="pa-6 c-action-section u-cursor-pointer" 
            @click="local_expand()" :class="[(!bulkEditMode && local_can('update') && !isExpanded) ? handle : '', { 'blue lighten-4': currentViewId === usecase.id }]"
        >
            <div class="u-flex u-relative">
                <div 
                    :id="'view-point-' + usecase.id" 
                    v-if="!onDrag && focusedElementId === usecase.id && !isExpanded" 
                    class="ignore-on-drag" 
                    tyle="visibility: hidden; marginTop: -96px; height: 96px; position: absolute; width: 100%;"
                ></div>
                <slot name="selectable" :loading="local_filter_loading" v-if="enableUcSelection"></slot>
                <div class="u-rounded-corners u-wfull text-truncate" :style="[(focusedElementId === usecase.id && !bulkEditMode && !usecase.loading_status) ? { border: '2px solid ' + (titleErrors.uc_title ? '#e53935' : '#1495bd') } : { cursor: 'text' }]">
                    <div v-if="currentEditId === usecase.id && !bulkEditMode && !usecase.loading_status" class="u-flex-center-y u-wfull">
                        <textarea
                            v-model="usecase.title"
                            rows="2"
                            class="u-wfull md-heading-6 d-block c-focusable-parent-input"
                            style="outline: none; resize: none;"
                            @click.stop="{}"
                            @blur="evt => localOnBlurTitle(evt, { item: {...usecase, title: usecase.title}, type: 'uc' })"
                            @keydown.esc="local_inline_update(usecase, 'uc', { clear: true })"
                            @keydown.enter.prevent="local_inline_update(usecase, 'uc')"
                        ></textarea>
                        <a-btn icon small depressed width="24" height="24" class="mr-2" @click.stop="local_inline_update(usecase, 'uc', { click: true })">
                            <a-icon color="green darken-1">check</a-icon>
                        </a-btn>
                        <a-btn icon small depressed width="24" height="24" @click.stop="local_inline_update(usecase, 'uc', { clear: true })">
                            <a-icon color="red darken-1">clear</a-icon>
                        </a-btn>
                    </div>
                    
                    <h3
                        v-else
                        @click.stop="(bulkEditMode || usecase.loading_status || !check_permission('update')) ? local_expand() : $emit('inputFocus', { id: usecase.id, type: 'parent' })"
                        class="c-module-title md-heading-6 font-weight-medium grey--text text--darken-4 text-truncate"
                        :title="usecase.title"
                    >
                        {{ usecase.title }}
                    </h3>
                </div>

                <div class="u-flex-center-y" v-if="bulkEditMode">
                    <slot name="attachments" v-if="!enableUcSelection && (enableScSelection || enableTcSelection)">
                        <g-attachment-count-info
                            :count="usecase.attachments_count"
                            class="ml-4"
                            fontSize="14"
                            iconSize="17"
                        />
                    </slot>
                    <template v-if="!enableUcSelection && (enableScSelection || enableTcSelection)">
                        <a-menu offset-y bottom v-if="usecase.tags" max-width="400">
                            <template v-slot:activator="{ on }">
                                <div class="u-flex-center-y ml-4 mr-4" v-on="usecase.tags.length ? on : {}">
                                    <a-icon size="18" color="body lighten-3">sell</a-icon>
                                    <span class="md-body-2 body--text text--lighten-2 ml-1">{{ usecase.tags.length }}</span>
                                </div>
                            </template>
                            <a-sheet>
                                <div class="u-flex-center-y pa-3">
                                    <a-icon size="24" color="grey darken-3">sell</a-icon>
                                    <h3 class="md-subtitle-1 ml-2 grey--text text--darken-3">Tags</h3>
                                </div>
                                <a-divider></a-divider>
                                <div class="pa-3">
                                    <template v-for="(tag) in usecase.tags">
                                        <g-tags :can-update="local_can('update')" :tag="tag" type="Usecase" hide-clear-icon :key="tag.id"></g-tags>
                                    </template>
                                </div>
                            </a-sheet>
                        </a-menu>
                    </template>
                    <div style="margin-top: -1px" v-if="!enableUcSelection && (enableScSelection || enableTcSelection)">
                        <slot name="assignees" v-if="!usecase.loading_status"></slot>
                        <template v-else>
                            <loader-template width="120" height="12" class="u-rounded-corners-full"></loader-template>
                        </template>
                    </div>
                </div>
                
                <template v-if="!usecase.loading_status">
                    <a-btn icon small class="ma-0 pa-0 grey ml-3" width="26" height="26" @click.stop="$emit('editUc', usecase)" v-if="!bulkEditMode">
                        <a-icon size="16" color="white">open_in_full</a-icon>
                    </a-btn>
                    <a-progress-circular width="3" size="26" class="ml-3" indeterminate color="orange darken-2" v-if="expandLoading === usecase.id"></a-progress-circular>
                    <template v-else>
                        <a-btn icon small class="ma-0 pa-0 grey ml-3" width="26" height="26" v-if="!enableUcSelection" @click.stop="local_expand()">
                            <a-icon size="20" color="white">keyboard_arrow_{{ isExpanded ? 'up' : 'down' }}</a-icon>
                        </a-btn>
                    </template>
                </template>
            </div>

            <template>
                <div class="u-flex align-center" :style="[usecase.loading_status ? { marginTop: '20px', marginBottom: '10px' } : { marginTop: '12px', marginBottom: '0px' } ]">
                    <template v-if="enableUcSelection || (!enableScSelection && !bulkEditMode)">
                        <slot name="success-criteria-stats" v-if="!usecase.loading_status"></slot>
                        <template v-else>
                            <loader-template width="220" height="12" class="u-rounded-corners-full mr-3"></loader-template>
                        </template>
                    </template>

                    <div v-if="enableUcSelection || (!enableScSelection && !bulkEditMode)">
                        <slot name="attachments" v-if="!usecase.loading_status">
                            <g-attachment-count-info
                                :count="usecase.attachments_count"
                                class="mx-6"
                                fontSize="14"
                                iconSize="17"
                            />
                        </slot>
                    </div>

                    <div v-if="enableUcSelection || (!enableScSelection && !bulkEditMode)">
                        <slot name="visibility" v-if="!usecase.loading_status">
                            <g-confirmation
                                :loading="loading"
                                :can-update="check_permission('update') && !bulkEditMode"
                                :is-public="usecase && usecase.visibility === 'external'"
                                @action="check_permission('update') ? local_visibility_toggle(usecase.visibility) : ''"
                                type="Use Case"
                                class="d-inline-block mr-7"
                                dense
                            ></g-confirmation>
                        </slot>
                        <template v-else>
                            <loader-template width="150" height="12" class="u-rounded-corners-full mr-3"></loader-template>
                        </template>
                    </div>

                    <div class="u-flex-center-y" v-if="enableUcSelection || (!enableScSelection && !bulkEditMode)">
                        <slot name="assignees" v-if="!usecase.loading_status"></slot>
                        <template v-else>
                            <loader-template width="120" height="12" class="u-rounded-corners-full"></loader-template>
                        </template>
                    </div>

                    <slot name="comments" v-if="!usecase.loading_status && !enableUcSelection && !enableScSelection && !enableTcSelection">
                        <div class="u-flex-center-y ml-4" v-if="usecase.aggregated_comments_count">
                            <a-icon size="18" color="body lighten-3">chat_bubble</a-icon>
                            <span class="md-body-2 body--text text--lighten-2 ml-2">{{ usecase.aggregated_comments_count }}</span>
                        </div>
                    </slot>

                    <template v-if="enableUcSelection && !usecase.loading_status">
                        <a-menu offset-y bottom v-if="usecase.tags" max-width="400">
                            <template v-slot:activator="{ on }">
                                <div class="u-flex-center-y ml-4" v-on="usecase.tags.length ? on : {}">
                                    <a-icon size="18" color="body lighten-3">sell</a-icon>
                                    <span class="md-body-2 body--text text--lighten-2 ml-1">{{ usecase.tags.length }}</span>
                                </div>
                            </template>
                            <a-sheet>
                                <div class="u-flex-center-y pa-3">
                                    <a-icon size="24" color="grey darken-3">sell</a-icon>
                                    <h3 class="md-subtitle-1 ml-2 grey--text text--darken-3">Tags</h3>
                                </div>
                                <a-divider></a-divider>
                                <div class="pa-3">
                                    <template v-for="(tag) in usecase.tags">
                                        <g-tags :can-update="local_can('update')" :tag="tag" type="Usecase" hide-clear-icon :key="tag.id"></g-tags>
                                    </template>
                                </div>
                            </a-sheet>
                        </a-menu>
                    </template>
                    <template v-if="!enableUcSelection && !enableScSelection && !enableTcSelection">
                        <!-- <div class="ml-6" v-if="usecase.tags && usecase.tags.length">
                            <template v-for="(tag) in usecase.tags.slice(0, 3)">
                                <g-tags :can-update="local_can('update')" :tag="tag" type="Usecase" hide-clear-icon :key="tag.id"></g-tags>
                            </template>
                        </div> -->
                        <template v-if="usecase.tags">
                            <a-menu offset-y bottom open-on-hover :close-on-content-click="false" max-width="400">
                                <template v-slot:activator="{ on }">
                                    <div v-on="usecase.tags.length ? on : {}" class="u-flex-center-y ml-6">
                                        <a-icon size="18" color="body lighten-3">sell</a-icon>
                                        <div class="md-body-2 body--text text--lighten-2 ml-1">{{ usecase.tags.length }}</div>
                                    </div>
                                </template>
                                <a-sheet>
                                    <div class="u-flex-center-y pa-3">
                                        <a-icon size="24" color="grey darken-3">sell</a-icon>
                                        <h3 class="md-subtitle-1 ml-2 grey--text text--darken-3">Tags</h3>
                                    </div>
                                    <a-divider></a-divider>
                                    <div class="pa-3">
                                        <template v-for="(tag) in usecase.tags">
                                            <g-tags :can-update="local_can('update')" :tag="tag" type="Usecase" hide-clear-icon :key="tag.id"></g-tags>
                                        </template>
                                    </div>
                                </a-sheet>
                            </a-menu>
                        </template>
                    </template>
                </div>
            </template>
        </div>

        <div v-if="isExpanded && !usecase.loading_status">
            <slot name="success-criteria" v-if="enableScSelection || (!enableUcSelection && !enableScSelection && !enableTcSelection)">
                <h2 v-if="!enableScSelection" class="md-subtitle-2 text-uppercase deep-purple--text text--accent-2 px-6 c-section-title u-flex-center-y" style="height: 56px; borderTop: 1px solid #E4E5EB">
                    {{ local_scs_length }} Success Criteria
                    <!-- <a-sheet class="deep-purple accent-2 white--text u-rounded-corners-full md-caption font-weight-medium mr-2 u-flex-center" width="20" height="20">{{ local_scs_length }}</a-sheet> -->
                </h2>
                <template v-if="scFilteredListData">
                    <draggable v-model="scFilteredListData" @end="local_sc_reorder" :options="{handle: '.js-drag-handle'}" tag="div" class="c-drag-parent" draggable=".js-draggable-file-list-item">
                        <template v-for="(sc_item, index) in scFilteredListData">
                            <div 
                                :key="sc_item.id" 
                                :id="sc_item.id" 
                                class="c-usecase-item__sc px-4 pr-6 u-flex-center-y u-wfull c-focusable-child" 
                                :class="[{ 'c-create-focus' : sc_item.loading_status === 'store' }, { 'blue lighten-4': currentViewId === sc_item.id }, { 'js-drag-handle js-draggable-file-list-item': !enableScSelection && !bulkEditMode && local_can('update') && focusedElementId !== sc_item.id }]" 
                                style="min-height: 56px; min-width: 460px" 
                                :style="[{ border: 'none', borderTop: '1px solid #E4E5EB' }, enableScSelection && (scFilteredListData.length - 1 === index) ? { borderRadius: '0px 0px 8px 8px' } : '' ]"
                            >
                                <div class="c-action-section u-wfull u-flex-center-y u-relative text-truncate" style="paddingRight: 80px;">
                                    <div :id="'view-point-' + sc_item.id" v-if="!onDrag && focusedElementId === sc_item.id" style="visibility: hidden; marginTop: -96px; height: 96px; position: absolute; width: 100%;"></div>
                                    <slot name="sc-selectable" :item="sc_item" :loading="local_filter_loading" v-if="enableScSelection"></slot>
                                    <div class="u-wfull u-rounded-corners mr-10" @click="(focusedElementId === sc_item.id || bulkEditMode || !check_permission('update')) ? '' : $emit('inputFocus', { id: sc_item.id, parentId: usecase.id, type: 'child' })" :style="[focusedElementId === sc_item.id ? { border: '2px solid #1495bd' } : { cursor: 'text' }]">
                                        <div class="u-rounded-corners px-2 u-flex-center-y u-wfull text-truncate" style="min-height: 54px">
                                            <div v-if="currentEditId === sc_item.id" class="u-flex-center-y u-wfull">
                                                    <!-- v-outside-click="{
                                                        handler: 'localOnBlurTitle',
                                                        props: { item: sc_item, type: 'sc' }
                                                    }" -->
                                                <textarea
                                                    v-model="sc_item.title"
                                                    rows="2"
                                                    class="u-wfull md-subtitle-1 d-block c-focusable-child-input"
                                                    style="outline: none; resize: none;"
                                                    @blur="evt => localOnBlurTitle(evt, { item: {...sc_item, title: sc_item.title}, type: 'sc' })"
                                                    @click.stop="{}"
                                                    @keydown.esc="local_inline_update(sc_item, 'sc', { clear: true })"
                                                    @keydown.enter.prevent="local_inline_update(sc_item, 'sc')"
                                                ></textarea>
                                                <a-btn icon small depressed width="24" height="24" class="mr-2" @click.stop="local_inline_update(sc_item, 'sc', { click: true })">
                                                    <a-icon color="green darken-1">check</a-icon>
                                                </a-btn>
                                                <a-btn icon small depressed width="24" height="24" @click.stop="local_inline_update(sc_item, 'sc', { clear: true })">
                                                    <a-icon color="red darken-1">clear</a-icon>
                                                </a-btn>
                                            </div>
                                            <h3
                                                v-else
                                                @click.stop="(bulkEditMode || !check_permission('update')) ? '' : $emit('inputFocus', { id: sc_item.id, parentId: usecase.id, type: 'child' })"
                                                class="c-module-title md-subtitle-1 grey--text text--darken-3 text-truncate pr-8"
                                                :title="sc_item.title"
                                            >{{ sc_item.title }}</h3>
                                        </div>
                                    </div>

                                    <!-- Actions -->
                                    <div class="c-action-section__action-icon u-flex align-center u-hfull mr-6" v-if="!bulkEditMode">
                                        <div v-ripple @click="$emit('edit', sc_item, 'SuccessCriterion', usecase)" class="c-action-section__action-item px-3 u-cursor-pointer u-rounded-corners-full blue darken-3 u-flex-center mr-2" style="width: 32px; height: 32px;">
                                            <a-icon size="18" color="white">open_in_full</a-icon>
                                        </div>
                                    </div>
                                </div>

                                <a-sheet class="u-flex-center-y justify-end transparent" max-width="304">
                                    <s-assignee-dropdown
                                        :item="sc_item"
                                        :users-list="sc_item.assignees"
                                        :project-id="$route.params.id"
                                        :can-update="check_permission('update') && !bulkEditMode"
                                        :size="32"
                                        :count="2"
                                        :isExternal="usecase.visibility === 'external'"
                                        @change-visibility="local_visibility_toggle"
                                        @assignee-store="local_get_success_criterion(sc_item.id)"
                                        module-type="SuccessCriterion"
                                        class="u-flex align-center justify-end mr-6"
                                        hide-empty-text
                                        :hideExternalConfirmation="usecase.visibility === 'external'"
                                    ></s-assignee-dropdown>
                                    <a-menu offset-y bottom open-on-hover :close-on-content-click="false" v-if="sc_item.tags">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="sc_item.tags.length ? on : {}" class="mr-5 u-flex-center-y">
                                                <a-icon size="18"  color="body lighten-3">sell</a-icon>
                                                <div class="md-body-2 body--text text--lighten-2 ml-1">{{ sc_item.tags.length }}</div>
                                            </div>
                                        </template>
                                        <a-sheet>
                                            <div class="u-flex-center-y pa-3">
                                                <a-icon size="24" color="grey darken-3">sell</a-icon>
                                                <h3 class="md-subtitle-1 ml-2 grey--text text--darken-3">Tags</h3>
                                            </div>
                                            <a-divider></a-divider>
                                            <div class="pa-3">
                                                <template v-for="(tag) in sc_item.tags">
                                                    <g-tags :can-update="local_can('update')" :tag="tag" type="Usecase" hide-clear-icon :key="tag.id"></g-tags>
                                                </template>
                                            </div>
                                        </a-sheet>
                                    </a-menu>
                                    <g-attachment-count-info
                                        :count="sc_item.attachments_count"
                                        class="mr-6"
                                        fontSize="14"
                                        iconSize="17"
                                    />
                                    <div class="u-flex-center-y mr-6" v-if="!enableScSelection">
                                        <a-icon size="16" color="body lighten-3">chat_bubble</a-icon>
                                        <span class="md-body-2 body--text text--lighten-2 ml-1">{{ sc_item.comments_count ? sc_item.comments_count : 0 }}</span>
                                    </div>
                                    <GNonActiveInfo
                                        :project="project_item"
                                        :stage="project_item && project_item.status"
                                        :disabled="!check_permission('update') || (check_permission('update') && project_item && project_item.status === 'active')"
                                        left
                                    >
                                        <s-status-menu
                                            :can-update="check_permission('update') && !bulkEditMode && (project_item && project_item.status === 'active')"
                                            :parent-list="scFilteredListData"
                                            :status-list="scStatusList"
                                            :item="sc_item"
                                            :status-item="local_get_sc_status_item(sc_item.status_id)"
                                            @statusCommentStore="local_add_status_comment"
                                            @update="local_after_add_status"
                                            module="SuccessCriterion"
                                            only-as-id
                                        ></s-status-menu>
                                        <template #move-stage="{ moveStage }">
                                            <span @click="localCheckPlanStageUpdate(moveStage)" class="d-inline-flex align-center u-cursor-pointer">
                                                <h3 class="blue--text md-body-2 font-weight-medium">Move to active stage</h3>
                                                <a-icon size="16" color="blue" class="ml-1">trending_flat</a-icon>
                                            </span>
                                        </template>
                                    </GNonActiveInfo>
                                </a-sheet>
                            </div>
                        </template>
                    </draggable>
                </template>
                <template v-if="check_permission('update') && !enableScSelection && !bulkEditMode">
                    <div class="c-add-input" style="borderTop: 1px solid #E4E5EB !important">
                        <v-slide-y-transition hide-on-leave leave-absolute>
                            <slot name="sc-review-update" :usecaseId="usecase.id" mode="sc"></slot>
                        </v-slide-y-transition>
                        <a-sheet height="56" class="transparent u-flex-center-y">
                            <v-text-field
                                v-model="success_criterion_title"
                                @input="e => !e ? sc_title_error = null : ''"
                                @keydown.enter="local_success_criterion_store"
                                @paste="local_bulk_sc_store"
                                :disabled="sc_bulk_store_loading"
                                :ref="'refScCreateInput-' + usecase.id"
                                placeholder="Add New Success Criteria & Hit Enter"
                                class="px-2 md-subtitle-1"
                                :class="[{ 'c-wiggle-short u-border-error': sc_title_error }]"
                                background-color="transparent"
                                solo flat hide-details
                            >
                                <template #prepend-inner>
                                    <div class="mr-2">
                                        <a-progress-circular width="3" size="20" class="mr-1" indeterminate color="deep-purple accent-2" v-if="sc_bulk_store_loading"></a-progress-circular>
                                        <a-icon size="24" color="deep-purple accent-2" v-else>add_circle</a-icon>
                                    </div>
                                </template>
                                <template #append v-if="sc_title_error">
                                    <a-tooltip bottom offset-y>
                                        <template v-slot:activator="{ on }">
                                            <a-icon color="red darken-1" size="20" v-on="on">info</a-icon>
                                        </template>
                                        <span>{{ sc_title_error }}</span>
                                    </a-tooltip>
                                </template>
                            </v-text-field>
                        </a-sheet>
                    </div>
                </template>
            </slot>

            <slot name="testcase" v-if="enableTcSelection || (!enableUcSelection && !enableScSelection && !enableTcSelection)">
                <h2 v-if="!enableTcSelection" class="md-subtitle-2 text-uppercase light-blue--text text--accent-4 px-6 py-3 c-section-title u-flex-center-y" style="height: 56px; borderTop: 1px solid #E4E5EB">
                    {{ local_tcs_length }} Test Cases
                    <!-- <a-sheet class="light-blue accent-4 white--text u-rounded-corners-full md-caption font-weight-medium ml-2 u-flex-center" width="20" height="20">{{ local_tcs_length }}</a-sheet> -->
                </h2>
                <template v-if="tcFilteredListData">
                    <draggable v-model="tcFilteredListData" @end="local_tc_reorder" :options="{handle: '.js-drag-handle'}" tag="div" class="c-drag-parent" draggable=".js-draggable-file-list-item">
                        <template v-for="(tc_item, index) in tcFilteredListData">
                            <div 
                                :key="tc_item.id" 
                                :id="tc_item.id" 
                                class="c-usecase-item__tc px-4 pr-6 u-flex-center-y u-wfull c-focusable-child" 
                                :class="[{ 'c-create-focus' : tc_item.loading_status === 'store' }, { 'blue lighten-4': currentViewId === tc_item.id }, { 'js-drag-handle js-draggable-file-list-item': !enableTcSelection && !bulkEditMode && local_can('update') && focusedElementId !== tc_item.id }]" 
                                style="min-height: 56px; min-width: 460px" 
                                :style="[{ border: 'none', borderTop: '1px solid #E4E5EB' }, enableTcSelection && (tcFilteredListData.length - 1 === index) ? { borderRadius: '0px 0px 8px 8px' } : '']"
                            >
                                <div class="c-action-section u-wfull u-flex-center-y u-relative text-truncate" style="paddingRight: 80px;">
                                    <div :id="'view-point-' + tc_item.id" v-if="!onDrag && focusedElementId === tc_item.id" style="visibility: hidden; marginTop: -96px; height: 96px; position: absolute; width: 100%;"></div>
                                    <slot name="tc-selectable" :item="tc_item" :loading="local_filter_loading" v-if="enableTcSelection"></slot>
                                    <div class="u-wfull mr-4 u-rounded-corners mr-10" @click="(focusedElementId === tc_item.id || bulkEditMode || !check_permission('update')) ? '' : $emit('inputFocus', { id: tc_item.id, parentId: usecase.id, type: 'child' })" :style="[focusedElementId === tc_item.id ? { border: '2px solid #1495bd' } : { cursor: 'text' }]">
                                        <div class="u-rounded-corners px-2 u-flex-center-y u-wfull text-truncate" style="min-height: 54px">
                                            <div v-if="currentEditId === tc_item.id && !bulkEditMode" class="u-flex-center-y u-wfull">
                                                    <!-- v-outside-click="{
                                                        handler: 'localOnBlurTitle',
                                                        props: { item: tc_item, type: 'tc' }
                                                    }" -->
                                                <textarea
                                                    v-model="tc_item.title"
                                                    rows="2"
                                                    class="u-wfull md-subtitle-1 d-block c-focusable-child-input"
                                                    style="outline: none; resize: none;"
                                                    @click.stop="{}"
                                                    @blur="evt => localOnBlurTitle(evt, { item: {...tc_item, title: tc_item.title}, type: 'tc' })"
                                                    @keydown.esc="local_inline_update(tc_item, 'tc', { clear: true })"
                                                    @keydown.enter.prevent="local_inline_update(tc_item, 'tc')"
                                                ></textarea>
                                                <a-btn icon small depressed width="24" height="24" class="mr-2" @click.stop="local_inline_update(tc_item, 'tc', { click: true })">
                                                    <a-icon color="green darken-1">check</a-icon>
                                                </a-btn>
                                                <a-btn icon small depressed width="24" height="24" @click.stop="local_inline_update(tc_item, 'tc', { clear: true })">
                                                    <a-icon color="red darken-1">clear</a-icon>
                                                </a-btn>
                                            </div>
                                            <h3
                                                v-else
                                                @click.stop="(bulkEditMode || !check_permission('update')) ? '' : $emit('inputFocus', { id: tc_item.id, parentId: usecase.id, type: 'child' })"
                                                class="c-module-title md-subtitle-1 grey--text text--darken-3 text-truncate pr-8"
                                                :title="tc_item.title"
                                            >{{ tc_item.title }}</h3>
                                        </div>
                                    </div>

                                    <!-- Actions -->
                                    <div class="c-action-section__action-icon u-flex align-center u-hfull mr-6" v-if="!bulkEditMode">
                                        <div v-ripple @click="$emit('edit', tc_item, 'Testcase', usecase)" class="c-action-section__action-item px-3 u-cursor-pointer u-rounded-corners-full blue darken-3 u-flex-center mr-2" style="width: 32px; height: 32px;">
                                            <a-icon size="18" color="white">open_in_full</a-icon>
                                        </div>
                                    </div>
                                </div>

                                <a-sheet class="u-flex-center-y justify-end transparent" width="304">
                                    <s-assignee-dropdown
                                        :item="tc_item"
                                        :users-list="tc_item.assignees"
                                        :project-id="$route.params.id"
                                        :can-update="check_permission('update') && !bulkEditMode"
                                        @change-visibility="local_visibility_toggle"
                                        @assignee-store="local_get_testcase(tc_item)"
                                        module-type="Testcase"
                                        :size="32"
                                        :count="2"
                                        class="u-flex align-center justify-end mr-6"
                                        :hideExternalConfirmation="usecase.visibility === 'external'"
                                        hide-empty-text
                                    ></s-assignee-dropdown>
                                    <a-menu offset-y bottom open-on-hover :close-on-content-click="false" v-if="tc_item.tags">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="tc_item.tags.length ? on : {}" class="mr-5 u-flex-center-y">
                                                <a-icon size="18"  color="body lighten-3">sell</a-icon>
                                                <div class="md-body-2 body--text text--lighten-2 ml-1">{{ tc_item.tags.length }}</div>
                                            </div>
                                        </template>
                                        <a-sheet>
                                            <div class="u-flex-center-y pa-3">
                                                <a-icon size="24" color="grey darken-3">sell</a-icon>
                                                <h3 class="md-subtitle-1 ml-2 grey--text text--darken-3">Tags</h3>
                                            </div>
                                            <a-divider></a-divider>
                                            <div class="pa-3">
                                                <template v-for="(tag) in tc_item.tags">
                                                    <g-tags :can-update="local_can('update')" :tag="tag" type="Usecase" hide-clear-icon :key="tag.id"></g-tags>
                                                </template>
                                            </div>
                                        </a-sheet>
                                    </a-menu>
                                    <g-attachment-count-info
                                        :count="tc_item.attachments_count"
                                        class="mr-6"
                                        fontSize="14"
                                        iconSize="17"
                                    />
                                    <div class="u-flex-center-y mr-6" v-if="!enableTcSelection">
                                        <a-icon size="16" color="body lighten-3">chat_bubble</a-icon>
                                        <span class="md-body-2 body--text text--lighten-2 ml-1">{{ tc_item.comments_count ? tc_item.comments_count : 0 }}</span>
                                    </div>
                                    <GNonActiveInfo
                                        :project="project_item"
                                        :stage="project_item && project_item.status"
                                        :disabled="!check_permission('update') || (check_permission('update') && project_item && project_item.status === 'active')"
                                        left
                                    >
                                        <s-status-menu
                                            :can-update="check_permission('update') && !bulkEditMode && (project_item && project_item.status === 'active')"
                                            :parent-list="tcFilteredListData"
                                            :status-list="tcStatusList"
                                            :item="tc_item"
                                            :status-item="local_get_tc_status_item(tc_item.status_id)"
                                            @statusCommentStore="local_add_status_comment"
                                            module="Testcase"
                                            only-as-id
                                        ></s-status-menu>
                                        <template #move-stage="{ moveStage }">
                                            <span @click="localCheckPlanStageUpdate(moveStage)" class="d-inline-flex align-center u-cursor-pointer">
                                                <h3 class="blue--text md-body-2 font-weight-medium">Move to active stage</h3>
                                                <a-icon size="16" color="blue" class="ml-1">trending_flat</a-icon>
                                            </span>
                                        </template>
                                    </GNonActiveInfo>
                                </a-sheet>
                            </div>
                        </template>
                    </draggable>
                </template>
                <template v-if="check_permission('update') && !enableTcSelection && !bulkEditMode">
                    <div class="c-add-input" style="borderTop: 1px solid #E4E5EB !important">
                        <v-slide-y-transition hide-on-leave leave-absolute>
                            <slot name="tc-review-update" :usecaseId="usecase.id" mode="tc"></slot>
                        </v-slide-y-transition>
                        <a-sheet height="56" class="transparent u-flex-center-y">
                            <v-text-field
                                v-model="testcase_title"
                                @input="e => !e ? tc_title_error = null : ''"
                                @keydown.enter="local_testcase_store"
                                @paste="local_bulk_tc_store"
                                :disabled="tc_bulk_store_loading"
                                :ref="'refTcCreateInput-' + usecase.id"
                                placeholder="Add New Test Case & Hit Enter"
                                class="px-2 md-subtitle-1"
                                :class="[{ 'c-wiggle-short u-border-error': tc_title_error }]"
                                background-color="transparent"
                                solo flat hide-details
                            >
                                <template #prepend-inner>
                                    <div class="mr-2">
                                        <a-progress-circular width="3" size="20" class="mr-1" indeterminate color="light-blue accent-4" v-if="tc_bulk_store_loading"></a-progress-circular>
                                        <a-icon v-else size="24" color="light-blue accent-4">add_circle</a-icon>
                                    </div>
                                </template>
                                <template #append v-if="tc_title_error">
                                    <a-tooltip bottom offset-y>
                                        <template v-slot:activator="{ on }">
                                            <a-icon color="red darken-1" size="20" v-on="on">info</a-icon>
                                        </template>
                                        <span>{{ tc_title_error }}</span>
                                    </a-tooltip>
                                </template>
                            </v-text-field>
                        </a-sheet>
                    </div>
                </template>
            </slot>
        </div>

        <!-- User Upgrade Popup -->
        <a-dialog max-width="400" v-model="dialog_user_upgrade_modal">
            <SUserUpgradeModal @close="dialog_user_upgrade_modal = false" />
        </a-dialog>

        <!-- Admin Upgrade Popup -->
        <a-dialog max-width="600" v-model="dialog_admin_upgrade_modal">
            <SAdminUpgradeModal
                :isOpen="dialog_admin_upgrade_modal"
                @upgrade="{}"
                @close="dialog_admin_upgrade_modal = false"
            >
                <template #plan-info-text>
                    Your current <span class="secondary--text font-weight-bold">Starter</span> plan is limited to {{ $plan('active_projects_limit') }} active projects.
                </template>
            </SAdminUpgradeModal>
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import marked from 'marked'
import { SStatusMenu, SAssigneeDropdown, SUserUpgradeModal, SAdminUpgradeModal } from '@/config/config-shared-components'
import { Validate } from '@/helpers/helper-validations'
import PReviewUpdateBar from './PartialReviewUpdateBar.vue'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'

export default {
    inheritAttrs: false,

    mixins: [mixinGetOrganizationPlan],

    props: {
        usecase: {
            type: Object,
            required: true
        },
        scStatusList: {
            type: Array
        },
        tcStatusList: {
            type: Array
        },
        scFilteredList: {
            type: Array
        },
        tcFilteredList: {
            type: Array
        },
        collabList: {
            type: Array
        },
        currentViewId: {
            type: String | Object
        },
        isExpanded: {
            type: Boolean
        },
        bulkEditMode: {
            type: Boolean,
            default: false
        },
        onDrag: {
            type: Boolean,
        },
        loadingType: {
            type: Object | String,
        },
        titleErrors: {
            type: Object,
        },
        handle: {
            type: String
        },
        parentIndex: {
            type: Number | String
        },
        currentEditId: {
            type: String
        },
        selectedStatusId: {
            type: String
        },
        focusedElementId: {
            type: String
        },
        selectAllState: {
            type: Boolean,
            default: false
        },
        enableUcSelection: {
            type: Boolean,
            default: false
        },
        enableScSelection: {
            type: Boolean,
            default: false
        },
        enableTcSelection: {
            type: Boolean,
            default: false
        },
        disableNavigation: {
            type: Boolean,
            default: false
        },
        expanded: {
            type: Boolean,
            default: false
        },
    },

    components: {
        SStatusMenu,
        SAssigneeDropdown,
        PReviewUpdateBar,
        SUserUpgradeModal,
        SAdminUpgradeModal
    },

    data () {
        return {
            success_criterion_title: '',
            testcase_title: '',
            sc_title_error: '',
            tc_title_error: '',
            expandLoading: null,
            sc_bulk_store_loading: false,
            tc_bulk_store_loading: false,
            scFilteredListData: [],
            tcFilteredListData: [],
            dialog_user_upgrade_modal: false,
            dialog_admin_upgrade_modal: false,
            loaders: {
                sidebar_comments_loading: false,
                content_autosave_response: false,
                sc_store: false,
                tc_store: false,
            }
        }
    },

    watch: {
        scFilteredList: {
            handler (val) {
                this.local_set_sc_list()
            },
            deep: true
        },

        tcFilteredList: {
            handler (val) {
                this.local_set_tc_list()
            },
            deep: true
        },
    },

    mounted () {
        this.local_index()
    },

    computed: {
        local_is_external () {
            return this.user_self && this.user_self.scope === 'external'
        },

        local_is_lgdown () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        local_scs_length () {
            return this.scFilteredListData.length ?? 0
            // return this.success_criterion_list.filter(item => item.usecase_id === this.usecase.id)
        },

        local_tcs_length () {
            return this.tcFilteredListData.length ?? 0
            // return this.testcase_list.filter(item => item.usecase_id === this.usecase.id)
        },

        local_bulk_mode_active () {
            return this.enableUcSelection || this.enableScSelection || this.enableTcSelection
        },

        local_filter_loading () {
            return this.local_is_loading('filter-selected')
        },

        ...mapState('User', {
            user_self: 'self'
        }),

        ...mapState('Usecase', {
            usecase_response: 'response',
        }),

        ...mapState('SuccessCriterion', {
            success_criterion_item: 'item',
            success_criterion_list: 'list',
            success_criterion_response: 'response',
        }),

        ...mapState('Testcase', {
            testcase_item: 'item',
            testcase_list: 'list',
            testcase_response: 'response',
        }),

        ...mapState('ProjectView', {
            project_item: 'item',
        }),

        ...mapState('Interface', {
            loading: 'loader'
        }),
    },

    methods: {
        local_index () {
            setTimeout(() => {
                this.local_set_sc_list()
                this.local_set_tc_list()
            }, 300)
        },

        local_set_sc_list () {
            this.scFilteredListData = []
            this.scFilteredListData = _.cloneDeep(this.scFilteredList.filter(item => item.usecase_id === this.usecase.id))
        },

        local_set_tc_list () {
            this.tcFilteredListData = []
            this.tcFilteredListData = this.tcFilteredList.filter(item => item.usecase_id === this.usecase.id)
        },

        async local_visibility_toggle (state) {
            const usecase_item = {}
            state = state === 'internal' ? 'external' : 'internal'
            usecase_item.id = this.usecase.id
            usecase_item.state = state
            await this.usecase_visibility(usecase_item)
            if (this.usecase_response.status !== 'success') return
            this.$set(this.usecase, 'visibility', state)
            this.$notify('success', 'Updated visibility successfully!')
        },

        async local_get_success_criterion (success_criterion_id) {
            await this.success_criterion_show({
                id: success_criterion_id,
                mode: 'upsert-item',
                params: {
                    project_id: this.$route.params.id,
                    include: 'assignees.collaborator.type',
                    'fields[success_criteria]': 'id,usecase_id',
                    'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                    'filter[collaborators]': true
                }
            })

            this.success_criterion_sc_upsert(_.cloneDeep(this.success_criterion_item))
        },

        async local_get_testcase (testcase) {
            await this.testcase_show({
                id: testcase.id,
                mode: 'upsert-item',
                params: {
                    project_id: this.$route.params.id,
                    include: 'assignees.collaborator.type',
                    'fields[testcases]': 'id,usecase_id',
                    'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                    'filter[collaborators]': true
                }
            })

            this.testcase_tc_upsert(this.testcase_item)
        },

        local_after_add_status () {
            this.local_get_usecase()
        },

        local_add_status_comment ({ comment, item_id, module }) {
            this.local_update_tc_sc_item_comment(module, item_id, comment)
        },

        local_update_tc_sc_item_comment (module, item_id) {
            const newItem = (module === 'Testcase' ? this.tcFilteredListData : this.scFilteredListData).find(data => data.id === item_id)
            this.$set(newItem, 'comments_count', newItem.comments_count ? newItem.comments_count + 1 : 1)
            Object.assign(this.usecase, { aggregated_comments_count: this.usecase.aggregated_comments_count ? this.usecase.aggregated_comments_count + 1 : 1 })

            if (module === 'Testcase') return this.testcase_update_list(newItem)
            this.success_criterion_update_list(newItem)
            return true
        },

        local_get_usecase () {
            this.usecase_show({
                id: this.usecase.id,
                mode: 'upsert-list-item',
                params: {
                    'include': 'successCriteriaCount,successCriteriaOpenCount,successCriteriaClosedCount,successCriteriaPassedCount,successCriteriaFailedCount,successCriteriaInvalidCount',
                    'fields[usecases]': 'id',
                    project_id: this.$route.params.id
                }
            })
        },

        local_expand () {
            if (this.usecase.loading_status) return
            if (!this.isExpanded) this.expandLoading = this.usecase.id
            this.$emit('toggleExpand', { id: this.usecase.id, is_expanded: this.isExpanded, clearLoading: this.local_clear_expand_loading })
        },

        local_clear_expand_loading () {
            setTimeout(() => {
                this.expandLoading = null
            }, 500);
        },

        // Success criteria --- Start
        async local_bulk_sc_store (event) {
            this.sc_bulk_store_loading = true
            let cursorStartPosition = event.target.selectionStart,
                cursorEndPosition = event.target.selectionEnd,
                inputValue =  event.target.value,
                clipboardData = event.clipboardData.getData('text'),
                clipboardDataLength = clipboardData.split('\n').length

            const scsTitle = (clipboardDataLength > 1 ? '' : inputValue.substring(0, cursorStartPosition)) + clipboardData + (clipboardDataLength > 1 ? '' : inputValue.substring(cursorEndPosition))
            const scsNew = scsTitle.split('\n')
            this.$emit('scBulkStore', scsNew, this.usecase.id, this.local_clear_sc_loading)
            setTimeout(() => {
                this.$refs['refScCreateInput-' + this.usecase.id].focus()
            }, 500)
        },

        local_clear_sc_loading () {
            this.sc_bulk_store_loading = false
            setTimeout(() => this.success_criterion_title = '', 0)
        },

        async local_success_criterion_store() {
            if (!this.success_criterion_title || (this.success_criterion_title && !this.success_criterion_title.trim())) {
                return this.success_criterion_clear_item()
            }

            const { message } = new Validate(this.success_criterion_title, { silent: true }).length(1, 1000).run()
            this.sc_title_error = message
            if (message) return

            const scItem = this.$appendId({
                title: _.cloneDeep(this.success_criterion_title),
                project_id: this.$route.params.id,
                usecase_id: this.usecase.id,
                assignees: [],
                tags: [],
                status_id: null,
                loading_status: 'store',
                attachments_count: 0
            })

            this.success_criterion_sc_upsert(scItem)
            this.success_criterion_title = ''
            await this.success_criterion_store(scItem)
            this.$notify('success', 'Success Criteria created successfully!')

            await this.success_criterion_show({ ...scItem, mode: 'upsert-list-item' })
            this.success_criterion_sc_upsert({ ...scItem, loading_status: null })
            this.$refs['refScCreateInput-' + this.usecase.id].focus()
            this.local_get_usecase()
        },

        async local_success_criterion_show(id, mode) {
            await this.success_criterion_show({
                id, mode,
                params: {
                    project_id: this.$route.params.id,
                    'filter[usecase_id]': this.usecase.id,
                    'include': 'assignees.collaborator.type,commentsCount',
                    'fields[success_criteria]': 'id,title,status_id,usecase_id',
                    'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                    'filter[collaborators]': true,
                }
            })
        },

        async local_sc_reorder () {
            this.success_criterion_reorder({ list: this.scFilteredListData })
        },
        // Success criteria --- End

        // Testcase --- Start
        async local_bulk_tc_store (event) {
            this.tc_bulk_store_loading = true
            let cursorStartPosition = event.target.selectionStart,
                cursorEndPosition = event.target.selectionEnd,
                inputValue =  event.target.value,
                clipboardData = event.clipboardData.getData('text'),
                clipboardDataLength = clipboardData.split('\n').length

            const tcsTitle = (clipboardDataLength > 1 ? '' : inputValue.substring(0, cursorStartPosition)) + clipboardData + (clipboardDataLength > 1 ? '' : inputValue.substring(cursorEndPosition))
            const tcsNew = tcsTitle.split('\n')
            this.$emit('tcBulkStore', tcsNew, this.usecase.id, this.local_clear_tc_loading)
            setTimeout(() => {
                this.$refs['refTcCreateInput-' + this.usecase.id].focus()
            }, 500)
        },

        local_clear_tc_loading () {
            this.tc_bulk_store_loading = false
            setTimeout(() => this.testcase_title = '', 0)
        },

        async local_testcase_store () {
            if (!this.testcase_title || (this.testcase_title && !this.testcase_title.trim())) {
                return this.testcase_clear_item()
            }

            const { message } = new Validate(this.testcase_title, { silent: true }).length(1, 1000).run()
            this.tc_title_error = message
            if (message) return

            const tcItem = this.$appendId({
                title: _.cloneDeep(this.testcase_title),
                project_id: this.$route.params.id,
                usecase_id: this.usecase.id,
                assignees: [],
                tags: [],
                status: null,
                loading_status: 'store',
                attachments_count: 0
            })

            this.testcase_tc_upsert(tcItem)
            this.testcase_title = ''
            await this.testcase_store(tcItem)
            this.$notify('success', 'Test Case created successfully!')

            await this.testcase_show({ ...tcItem, mode: 'upsert-list-item' })
            this.testcase_tc_upsert({ ...tcItem, loading_status: null })
            this.$refs['refTcCreateInput-' + this.usecase.id].focus()
        },

        async local_testcase_show (id, mode) {
            await this.testcase_show({
                id, mode,
                params: {
                    project_id: this.$route.params.id,
                    'filter[usecase_id]': this.usecase.id,
                    'include': 'assignees.collaborator.type,commentsCount',
                    'fields[testcases]': 'id,title,status_id,usecase_id',
                    'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                    'filter[collaborators]': true,
                }
            })
        },

        async local_tc_reorder () {
            this.testcase_reorder({ list: this.tcFilteredListData })
        },
        // Testcase --- End

        // Project --- Start
        localCheckPlanStageUpdate (stageToMove) {
            const { is_admin } = this.user_self || {}
            const { projects_active_count } = this.mixinGetOrgPlan() || {}

            if (this.$plan('active_projects_limit') === -1) return stageToMove()
            if (projects_active_count < this.$plan('active_projects_limit')) return stageToMove()

            if (is_admin) this.dialog_admin_upgrade_modal = true
            else this.dialog_user_upgrade_modal = true
        },
        // Project --- End

        // Extras
        local_inline_update (item, module, args) {
            const { clear = false, escape = true, click = false } = args || {}
            this.$emit('inlineUpdate', item, module, { clear, escape, click })
        },

        local_get_sc_status_item (status_id) {
            return this.scStatusList.find(status => status.id === status_id)
        },

        local_get_tc_status_item (status_id) {
            return this.tcStatusList.find(status => status.id === status_id)
        },

        local_is_loading (type) {
            return this.loadingType === type
        },

        local_converted_desc(description) {
            return marked(description, { sanitize: true })
        },

        local_can (type) {
            return this.$can('projects.update-only') && this.$can(`usecases.${type}`)
        },

        check_permission (type) {
            return this.$can(`usecases.${type}`) && this.$can('projects.update-only')
        },

        localOnBlurTitle (event, props) {
            const { item, type } = props || {}
            const { target } = event
            this.local_inline_update(item, type, { escape: false })
            // if (!target.classList.contains('c-module-title') && !target.classList.contains('c-focusable-parent-input')) {
            if (!target.classList.contains('c-module-title')) {
                this.$emit('clearKeyboardFocus')
            }
        },

        ...mapActions('Usecase', {
            usecase_show: 'show',
            usecase_visibility: 'visibility',
        }),

        ...mapActions('SuccessCriterion', {
            success_criterion_store: 'store',
            success_criterion_show: 'show',
            success_criterion_sc_upsert: 'sc_upsert',
            success_criterion_update_list: 'update_list',
            success_criterion_clear_item: 'clear_item',
            success_criterion_reorder: 'reorder',
        }),

        ...mapActions('Testcase', {
            testcase_store: 'store',
            testcase_reorder: 'reorder',
            testcase_tc_upsert: 'tc_upsert',
            testcase_show: 'show',
            testcase_update_list: 'update_list',
            testcase_clear_item: 'clear_item',
        })
    }
}
</script>

<style lang="scss" scoped>
.c-create-focus {
    transition: opacity 1s all;
    animation: focus-animate 0.5s ease-out infinite alternate;
}

@keyframes focus-animate {
    0% { opacity: 1; }
    25% { opacity: 0.8; }
    50% { opacity: 0.6; }
    100% { opacity: 0.4; }
}

.c-usecase-item {
    &__sc,
    &__tc {
        &:hover {
            // box-shadow: inset 0px 0px 0px #dadce0, inset 0px 0px 0px #dadce0, 0px 1px 0px rgb(60 64 67 / 30%), 0 3px 4px 0px rgb(60 64 67 / 15%);
            box-shadow: inset 0px 0px 0px #dadce0, inset 0px 0px 0px #dadce0, 0px 0px 0px 0px rgb(60 64 67 / 30%), 0 3px 4px 0px rgb(60 64 67 / 15%);
        }
    }
}

.c-action-section {
    position: relative;
    // overflow-x: hidden;
    &__action {
        visibility: hidden;
        position: absolute;
        right: -1000px;
        top: 0px;
        background: #fff;
        border-radius: 0px 0px 0px 6px;
        box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 16%);
        // transition: 0.1s ease-in-out;
    }
    &__action-icon {
        visibility: hidden;
        position: absolute;
        right: -1000px;
        top: 0px;
        background: #fff;
        // transition: 0.1s ease-in-out;
    }
    &__action-item {
        display: flex;
        align-items: center;
        padding: 5px 10px;
    }
    &:hover {
        .c-action-section__action,
        .c-action-section__action-icon {
            visibility: visible;
            right: 50px;
            // transition: 0.1s ease-in-out;
        }
    }
}

.c-focused {
    border: 2px solid #40afff !important;
    &--none {
        border: none !important;
    }
}

.c-dragger {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 0px;
    right: 0px;
    display: block;
    border-radius: 0px 8px 0px 4px !important;
    background: #f5f5f5;
    border-left: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
}

.c-bubble {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    border-radius: 6px 0px 24px 0px !important;
    background: #faa730 !important;
    &--private {
        background: #dcdcdc !important;
    }
}
</style>

<template>
	<a-main>
         <a-container grid-list-xl container--fluid class="pt-2 px-0">
            <a-layout wrap align-center>
                <a-flex shrink class="pb-0">
                    <a-btn color="grey darken-2" class="ma-0 pl-0 mb-2" small text :to="{name: 'inventory'}" :exact="true">
                        <a-icon left class="mr-2" size="20">arrow_back</a-icon>
                        Back to inventory
                    </a-btn>
                </a-flex>
            </a-layout>
            <a-layout wrap align-start mt-2>
                <a-flex xs12 class="pb-0">
                    <a-sheet max-width="1200" color="transparent">
                        <div class="u-flex align-center">
                            <h2 class="md-heading-5 mb-1 mr-3">Import inventory CSV</h2>
                            <a-spacer></a-spacer>
                            <a-btn color="grey darken-2" class="green--text text--darken-2 ma-0 mb-2" small text @click="local_format()">
                                <a-icon left size="16" class="mr-2">cloud_download</a-icon> Download Format
                            </a-btn>
                        </div>
                        <p class="md-body-2 grey--text text--darken-2 mb-0">You can import multiple inventories through a CSV file. Success will process the uploaded file and import the data into temporary queue. Please make sure you have data columns in the right order as specified in sample format. After the upload is complete, you can review the data that system parsed and import it into the inventory.</p>
                    </a-sheet>
                </a-flex>
                <a-flex shrink>
                    <a-btn depressed class="blue--text text--darken-2 mx-0 px-3 u-rounded-corners u-shadow" color="white" small @click="local_initiate()" :disabled="loading_file">
                        <a-icon left size="16" class="mr-2">arrow_upward</a-icon> Upload New File
                    </a-btn>
                    <input type="file" ref="csv_file" name="csv_file" class="d-none" @change="local_store()" accept=".csv">
                </a-flex>
                <a-flex shrink v-if="inventory_upload_list && inventory_upload_list.length > 0">
                    <a-btn color="grey" class="grey--text text--darken-2 mx-0 px-3" text small @click="local_destroy()">
                        <a-icon left size="16" class="mr-2">clear</a-icon> Clear Uploaded File
                    </a-btn>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink v-if="inventory_upload_list && inventory_upload_list.length > 0">
                    <a-menu bottom offset-y class="mr-2" :disabled="loading_save" v-if="inventory_upload_meta.acceptable_warning && inventory_upload_meta.acceptable_warning.serial > 0">
                        <template v-slot:activator="{ on }">
                            <a-btn v-on="on" color="green darken-2" class="white--text mx-0 elevation-1 px-3" small :disabled="loading_save" :loading="loading_save">
                                Save inventory
                                <a-icon left size="16" class="ma-0 pl-1">keyboard_arrow_down</a-icon>
                            </a-btn>
                        </template>
                        <a-list class="u-list-condensed">
                            <a-list-item @click="local_save()" :disabled="!!(inventory_upload_meta.new_valid <= 0)">
                                <a-list-item-title>
                                    <span class="md-body-2">Save Only New Records  ({{ inventory_upload_meta.new_valid }}) </span>
                                </a-list-item-title>
                            </a-list-item>
                            <a-list-item @click="local_save(true)">
                                <a-list-item-title>
                                    <span class="md-body-2">Save and Overwrite Records ({{ inventory_upload_meta.valid }})</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                    <a-btn depressed color="green darken-2" v-else class="white--text mx-0 elevation-1 px-3" small @click="local_save()" :disabled="loading_save" :loading="loading_save">
                        Save inventory
                    </a-btn>
                </a-flex>
            </a-layout>
            <a-layout align-start>
                <a-flex xs12>
                    <a-card flat class="blue darken-1 u-rounded-corners u-overflow-hidden mb-3" v-if="loading_file">
                        <a-card-text class="pa-3">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-progress-circular indeterminate color="white" size="20" width="2"></a-progress-circular>
                                </a-flex>
                                <a-flex class="pl-0">
                                    <span class="md-body-2 white--text">File upload is currently under progress.</span>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat class="red darken-2 u-rounded-corners u-overflow-hidden mb-3" v-if="inventory_upload_response.status === 'error'">
                        <a-card-text class="pa-3">
                            <a-layout align-center>
                                <a-flex>
                                    <a-icon color="white" class="u-icon-nudge mr-2" size="20">error</a-icon> <span class="md-body-2 white--text">{{ inventory_upload_response.text }}</span>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat class="u-border u-rounded-corners u-overflow-hidden mb-3 pa-2" v-if="inventory_upload_list && inventory_upload_list.length > 0">
                        <a-layout align-center class="pa-3">
                            <a-flex shrink class="u-cursor-pointer mr-2 u-rounded-corners-lg" :class="{'grey lighten-3': local_inventory_upload_filters.length === 0 }" @click="local_inventory_upload_fillter('Processed', true)">
                                <p class="md-caption-2 primary--text text--darken-3 mb-0">
                                    <span class="mr-1"><a-icon color="primary darken-1" class="u-icon-nudge" style="top: -2px;" size="18">check_circle</a-icon></span>
                                    {{ inventory_upload_meta.processed }} Processed
                                </p>
                            </a-flex>
                            <a-flex shrink class="u-cursor-pointer mr-2 u-rounded-corners-lg" :class="{'grey lighten-3': local_is_filterd_inventory_upload_list('valid-all') }" @click="local_inventory_upload_fillter('valid-all')">
                                <p class="md-caption-2 mb-0" :class="{'grey--text text--darken-2' : !local_is_filterd_inventory_upload_list('valid-all'), 'primary--text text--darken-3': local_is_filterd_inventory_upload_list('valid-all') }">
                                    <span class="mr-1"><a-icon color="green darken-2" class="u-icon-nudge" style="top: -2px;" size="18">done_all</a-icon></span>
                                    {{ inventory_upload_meta.valid }} Valid
                                </p>
                            </a-flex>
                            <a-flex shrink class="u-cursor-pointer mr-2 u-rounded-corners-lg" :class="{'grey lighten-3': local_is_filterd_inventory_upload_list('valid') }" @click="local_inventory_upload_fillter('valid')">
                                <p class="md-caption-2 mb-0" :class="{'grey--text text--darken-2' : !local_is_filterd_inventory_upload_list('valid'), 'primary--text text--darken-3': local_is_filterd_inventory_upload_list('valid') }">
                                    <span class="mr-1"><a-icon color="green darken-2" class="u-icon-nudge" style="top: -2px;" size="18">check_circle</a-icon></span>
                                    {{ inventory_upload_meta.new_valid }} New Records
                                </p>
                            </a-flex>
                            <a-flex shrink class="u-cursor-pointer mr-2 u-rounded-corners-lg" :class="{'grey lighten-3': local_is_filterd_inventory_upload_list('existing-valid') }" @click="local_inventory_upload_fillter('existing-valid')">
                                <p class="md-caption-2 mb-0" :class="{'grey--text text--darken-2' : !local_is_filterd_inventory_upload_list('existing-valid'), 'primary--text text--darken-3': local_is_filterd_inventory_upload_list('existing-valid') }">
                                    <span class="mr-1"><a-icon color="blue darken-1" class="u-icon-nudge" style="top: -2px;" size="18">check_circle</a-icon></span>
                                    {{ inventory_upload_meta.existing_valid }} Existing Records
                                </p>
                            </a-flex>
                            <a-flex shrink class="u-cursor-pointer mr-2 u-rounded-corners-lg" :class="{'grey lighten-3': local_is_filterd_inventory_upload_list('ignore') }" @click="local_inventory_upload_fillter('ignore')">
                                <p class="md-caption-2 mb-0" :class="{'grey--text text--darken-2' : !local_is_filterd_inventory_upload_list('ignore'), 'primary--text text--darken-3': local_is_filterd_inventory_upload_list('ignore') }">
                                    <span class="mr-1"><a-icon color="yellow darken-2" class="u-icon-nudge" style="top: -1px;" size="19">error</a-icon></span>
                                    {{ inventory_upload_meta.invalid }} Invalid
                                </p>
                            </a-flex>
                        </a-layout>
                    </a-card>

                    <a-card class="neutral pa-0 ma-0 u-elevation-custom-1 u-rounded-corners" v-if="local_render && local_render.length > 0" style="overflow: auto">
                        <table class="csv-table u-rounded-corners">
                            <thead>
                                <tr class="csv-table-row-header">
                                    <th>&nbsp;</th>
                                    <th class="csv-table-row-upload-status">&bull;</th>
                                    <th>A</th>
                                    <th>B</th>
                                    <th>C</th>
                                    <th>D</th>
                                    <th>E</th>
                                    <th>F</th>
                                    <th>G</th>
                                    <th>H</th>
                                    <th>I</th>
                                    <th>J</th>
                                    <th>K</th>
                                    <th>L</th>
                                    <th>M</th>
                                    <th>N</th>
                                    <th>O</th>
                                    <th>P</th>
                                </tr>
                                <tr class="csv-table-row-title">
                                    <th class="csv-table-cell-number" style="min-width: 48px;">1</th>
                                    <th class="text-center csv-table-row-upload-status"><a-icon color="grey darken-2" size="16">radio_button_unchecked</a-icon></th>
                                    <th style="min-width: 200px;">Inventory name</th>
                                    <th style="min-width: 200px;">Description</th>
                                    <th style="min-width: 200px;">Category</th>
                                    <th style="min-width: 200px;">OEM</th>
                                    <th style="min-width: 200px;">Model Number</th>
                                    <th style="min-width: 200px;">Part Number</th>
                                    <th style="min-width: 200px;">Serial Number</th>
                                    <th style="min-width: 150px;">Hardware Version</th>
                                    <th style="min-width: 150px;">Software Version</th>
                                    <th style="min-width: 150px;">Original Value</th>
                                    <th style="min-width: 150px;">Current Value</th>
                                    <th style="min-width: 200px;">Location</th>
                                    <th style="min-width: 200px;">Territory</th>
                                    <th style="min-width: 200px;">Tags</th>
                                    <th style="min-width: 200px;">Notes</th>
                                    <th style="min-width: 200px;">Owner Email</th>
                                </tr>
                            </thead>
                             <tbody>
                                <tr v-for="item in local_render" :key="item.id" :class="item.upload_status !== 'existing-valid' && !item.error && item.warning ? 'csv-table-row-data csv-table-row-status-warning' : 'csv-table-row-data ' + 'csv-table-row-status-' + item.upload_status">
                                    <td class="csv-table-cell-number" width="50px" style="text-align: center;">{{ item.line_no }}</td>
                                    <td class="text-center csv-table-row-upload-status" style="min-width: 48px;">
                                        <a-tooltip bottom left>
                                            <template v-slot:activator="{ on }">
                                                <a-icon v-on="on" v-if="item.upload_status === 'ignore'" color="yellow darken-2" class="u-cursor-pointer" size="16">error</a-icon>
                                                <a-icon v-on="on" v-if="item.upload_status === 'skipped'" color="yellow darken-2" class="u-cursor-pointer" size="16">warning</a-icon>
                                                <a-icon v-on="on" v-if="item.upload_status === 'warning' && item.warning['Serial Number'] && !item.warning['Serial Number'][1]" color="yellow darken-2" class="u-cursor-pointer" size="16">warning</a-icon>
                                                <a-icon v-on="on" v-if="item.upload_status === 'warning' && item.warning['Serial Number'] && item.warning['Serial Number'][1]" color="yellow darken-3" class="u-cursor-pointer" size="18">restore</a-icon>
                                                <a-icon v-on="on" v-if="item.upload_status === 'restore'" color="yellow darken-3" class="u-cursor-pointer" size="18">restore</a-icon>
                                                <a-icon v-on="on" v-if="item.upload_status === 'valid'" color="green darken-2" class="u-cursor-pointer" size="16">check_circle</a-icon>
                                                <a-icon v-on="on" v-if="item.upload_status === 'existing-valid'" color="blue darken-1" class="u-cursor-pointer" size="16">check_circle</a-icon>
                                            </template>
                                            <span v-if="item.upload_status === 'ignore'">
                                                This row of data has some issues. Mouse over on the highlighted cells for more information.
                                            </span>
                                            <span v-if="item.upload_status === 'skipped'">
                                                This row will be skipped due to lack of data.
                                            </span>
                                            <span v-if="item.upload_status === 'warning' && item.warning['Serial Number'] && !item.warning['Serial Number'][1]">
                                                This record already exist. Click save inventory button to overwrite the data
                                            </span>
                                            <span v-if="item.upload_status === 'restore'">
                                               This inventory is currently in trash and it will be restored
                                            </span>
                                            <span v-if="item.upload_status === 'valid' || item.upload_status === 'existing-valid'">
                                                All good.
                                            </span>
                                        </a-tooltip>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['Inventory Name']">
                                        <a-tooltip bottom v-if="item.name">
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.name, '25', '...') }} 1</p>
                                            </template>
                                            <span>{{ item.error['Inventory Name'][0] }}</span>
                                        </a-tooltip>
                                        <a-tooltip bottom v-else>
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on" class="u-full" style="height: 20px;">
                                                </div>
                                            </template>
                                            <span>{{ item.error['Inventory Name'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.name">{{ item.name }}</p>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['Description']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.description, '20', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Description'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.description">{{ item.description }}</p>
                                    </td>
                                     <td class="csv-table-cell-error" v-if="item.error && item.error['Category']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.category, '27', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Category'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td :class="{'csv-table-cell-error': item.ignored && !item.ignored['Category'], 'csv-table-cell-ignore': item.ignored && item.ignored['Category']}" v-else-if="item.warning && item.warning['Category']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.category, '27', '...') }}</p>
                                            </template>
                                            <span>{{ item.warning['Category'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.category">{{ item.category }}</p>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['OEM']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.oem, '25', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['OEM'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.oem">{{ item.oem }}</p>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['Model Number']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.model_no, '20', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Model Number'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.model_no">{{ item.model_no }}</p>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['Part Number']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.part_no, '20', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Part Number'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.part_no">{{ item.part_no }}</p>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['Serial Number']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.serial_no, '18', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Serial Number'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td class="csv-table-cell-existing-value" v-else-if="item.warning && item.warning['Serial Number']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.serial_no, '18', '...') }}</p>
                                            </template>
                                            <span>{{ item.warning['Serial Number'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.serial_no">{{ item.serial_no }}</p>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['Hardware Version']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.hardware_version, '20', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Hardware Version'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.hardware_version">{{ item.hardware_version }}</p>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['Software Version']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.software_version, '20', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Software Version'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.software_version">{{ item.software_version }}</p>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['Original Value']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.original_amount, '20', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Original Value'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.original_amount">{{ item.original_amount }}</p>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['Current Value']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.current_amount, '20', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Current Value'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.current_amount">{{ item.current_amount }}</p>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['Location']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.location, '27', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Location'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.location">{{ item.location }}</p>
                                    </td>
                                    <td :class="{'csv-table-cell-error': item.error && item.error['Territory'] || item.warning && item.warning['Territory'],'csv-table-cell-ignore': item.ignored && item.ignored['Territory'], }">
                                        <a-menu bottom offset-y v-if="item.territories && item.territory_availablity" transition="slide-y-transition" open-on-hover :close-on-content-click="false" min-width="400" max-width="400" max-height="600">
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.territories, '27', '...') }}</p>
                                            </template>
                                            <a-sheet class="u-elevation-custom-1 u-rounded-corners">
                                                <div class="py-4 px-5 u-flex align-center" style="position: sticky; top: 0; left: 0; z-index: 10">
                                                    <a-avatar size="24" class="mr-2 u-icon-nudge" style="top: 0;">
                                                        <a-icon color="primary darken-1" size="23">info</a-icon>
                                                    </a-avatar>
                                                    <h2 class="md-subtitle-1 font-weight-bold primary--text text--darken-2">
                                                        Territories
                                                    </h2>
                                                </div>
                                                <a-divider class="grey lighten-3"></a-divider>
                                                <div class="pa-5 align-center" v-if="item.territory_availablity && item.territory_availablity['Existing Territory']">
                                                    <div class="u-flex pb-4">
                                                        <a-avatar size="24" color="indigo lighten-5" class="mr-2 u-icon-nudge" style="top: -1px;">
                                                            <p class="indigo--text text--darken-2 ma-0 font-weight-bold md-caption">{{ item.territory_availablity['Existing Territory'].length }}</p>
                                                        </a-avatar>
                                                        <h6 class="md-subtitle-2 text-uppercase ml-2 font-weight-bold indigo--text text--darken-2">
                                                            Existing Territories
                                                        </h6>
                                                    </div>
                                                    <div class="align-center" v-if="item.territory_availablity['Existing Territory'].length !== 0">
                                                        <template v-for="(tag, index) in item.territory_availablity['Existing Territory']">
                                                            <g-tags :tag="tag" type="Inventory" hide-clear-icon :key="index"></g-tags>
                                                        </template>
                                                    </div>
                                                    <div class="align-center" v-if="item.territory_availablity['Existing Territory'].length === 0">
                                                        <h3 class="md-body-2 grey--text mb-0 ml-1 text-capitalize">No existing territories found</h3>
                                                    </div>
                                                </div>
                                                <a-divider class="grey lighten-3"></a-divider>
                                                <div class="pa-5 align-center" v-if="item.territory_availablity && item.territory_availablity['New Territory']">
                                                    <div class="u-flex pb-4">
                                                        <a-avatar size="24" color="indigo lighten-5" class="mr-2 u-icon-nudge" style="top: -1px;">
                                                            <p class="indigo--text text--darken-2 ma-0 font-weight-bold md-caption">{{ item.territory_availablity['New Territory'].length }}</p>
                                                        </a-avatar>
                                                        <h6 class="md-subtitle-2 font-weight-bold text-uppercase ml-2 indigo--text text--darken-2">
                                                            New Territories
                                                        </h6>
                                                    </div>
                                                    <div class="align-center" v-if="item.territory_availablity['New Territory'].length !== 0">
                                                        <template v-for="(tag, index) in item.territory_availablity['New Territory']">
                                                            <g-tags :tag="tag" type="Inventory" hide-clear-icon :key="index"></g-tags>
                                                        </template>
                                                    </div>
                                                    <div class="align-center" v-if="item.territory_availablity['New Territory'].length === 0">
                                                        <h3 class="md-body-2 grey--text mb-0 ml-1 text-capitalize">No new territories found</h3>
                                                    </div>
                                                </div>
                                                <div class="px-5 pb-5 align-center" v-if="item.warning && item.warning['Territory'] || (item.territory_availablity && item.territory_availablity['New Territory'] && item.territory_availablity['New Territory'].length !== 0)">
                                                    <a-alert v-if="item.warning && item.warning['Territory']" color="yellow lighten-5 u-alert-no-border" elevation="0" class="px-4 py-3 my-0">
                                                        <p class="md-subtitle-2 mb-0 orange--text text--darken-1 align-center">
                                                         {{ item.warning['Territory'][0] }}
                                                        </p>
                                                    </a-alert>
                                                    <a-alert v-if="item.warning && !item.warning['Territory'] && item.territory_availablity && item.territory_availablity['New Territory'] && item.territory_availablity['New Territory'].length !== 0" color="blue lighten-5 u-alert-no-border" elevation="0" class="px-4 py-3 my-0">
                                                        <p class="md-subtitle-2 mb-0 blue--text text--darken-1 align-center">
                                                            {{ item.territory_availablity['New Territory'].length }} new Territory will be created during the import
                                                        </p>
                                                    </a-alert>
                                                </div>
                                            </a-sheet>
                                        </a-menu>
                                    </td>
                                    <td :class="{'csv-table-cell-error': item.error && item.error['Tags'] || item.warning && item.warning['Tags'],'csv-table-cell-ignore': item.ignored && item.ignored['Tags']}">
                                        <a-menu bottom offset-y v-if="item.tags && item.tag_availablity" transition="slide-y-transition" open-on-hover :close-on-content-click="false" min-width="400" max-width="400" max-height="600">
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0 text-truncate">&nbsp;{{ local_text_truncate(item.tags, '27', '...') }}</p>
                                            </template>
                                            <a-sheet class="u-elevation-custom-1 u-rounded-corners">
                                                <div class="py-4 px-5 u-flex align-center" style="position: sticky; top: 0; left: 0; z-index: 10">
                                                    <a-avatar size="24" class="mr-2 u-icon-nudge" style="top: 0;">
                                                        <a-icon color="primary darken-1" size="23">info</a-icon>
                                                    </a-avatar>
                                                    <h2 class="md-subtitle-1 font-weight-bold primary--text text--darken-2">
                                                        Tags
                                                    </h2>
                                                </div>
                                                <a-divider class="grey lighten-3"></a-divider>
                                                <div class="pa-5 align-center" v-if="item.tag_availablity && item.tag_availablity['Existing Tags']">
                                                    <div class="u-flex pb-4">
                                                        <a-avatar size="24" color="indigo lighten-5" class="mr-2 u-icon-nudge" style="top: -1px;">
                                                            <p class="indigo--text text--darken-2 ma-0 font-weight-bold md-caption">{{ item.tag_availablity['Existing Tags'].length }}</p>
                                                        </a-avatar>
                                                        <h6 class="md-subtitle-2 text-uppercase ml-2 font-weight-bold indigo--text text--darken-2">
                                                            existing Tags
                                                        </h6>
                                                    </div>
                                                    <div class="align-center" v-if="item.tag_availablity['Existing Tags'].length !== 0">
                                                        <template v-for="(tag, index) in item.tag_availablity['Existing Tags']">
                                                            <g-tags :tag="tag" type="Inventory" hide-clear-icon :key="index"></g-tags>
                                                        </template>
                                                    </div>
                                                    <div class="align-center" v-if="item.tag_availablity['Existing Tags'].length === 0">
                                                        <h3 class="md-body-2 grey--text mb-0 ml-1 text-capitalize">No existing tags found</h3>
                                                    </div>
                                                </div>
                                                <a-divider class="grey lighten-3"></a-divider>
                                                <div class="pa-5 align-center" v-if="item.tag_availablity && item.tag_availablity['New Tags']">
                                                    <div class="u-flex pb-4">
                                                        <a-avatar size="24" color="indigo lighten-5" class="mr-2 u-icon-nudge" style="top: -1px;">
                                                            <p class="indigo--text text--darken-2 ma-0 font-weight-bold md-caption">{{ item.tag_availablity['New Tags'].length }}</p>
                                                        </a-avatar>
                                                        <h6 class="md-subtitle-2 font-weight-bold text-uppercase ml-2 indigo--text text--darken-2">
                                                            new Tags
                                                        </h6>
                                                    </div>
                                                    <div class="align-center" v-if="item.tag_availablity['New Tags'].length !== 0">
                                                        <template v-for="(tag, index) in item.tag_availablity['New Tags']">
                                                            <g-tags :tag="tag" type="Inventory" hide-clear-icon :key="index"></g-tags>
                                                        </template>
                                                    </div>
                                                    <div class="align-center" v-if="item.tag_availablity['New Tags'].length === 0">
                                                        <h3 class="md-body-2 grey--text mb-0 ml-1 text-capitalize">No new tags found</h3>
                                                    </div>
                                                </div>
                                                <div class="px-5 pb-5 align-center" v-if="item.warning && item.warning['Tags'] || (item.tag_availablity && item.tag_availablity['New Tags'] && item.tag_availablity['New Tags'].length !== 0)">
                                                    <a-alert v-if="item.warning && item.warning['Tags']" color="yellow lighten-5 u-alert-no-border" elevation="0" class="px-4 py-3 my-0">
                                                        <p class="md-subtitle-2 mb-0 orange--text text--darken-1 align-center">
                                                         {{ item.warning['Tags'][0] }}
                                                        </p>
                                                    </a-alert>
                                                    <a-alert v-if="item.warning && !item.warning['Tags'] && item.tag_availablity && item.tag_availablity['New Tags'] && item.tag_availablity['New Tags'].length !== 0" color="blue lighten-5 u-alert-no-border" elevation="0" class="px-4 py-3 my-0">
                                                        <p class="md-subtitle-2 mb-0 blue--text text--darken-1 align-center">
                                                            {{ item.tag_availablity['New Tags'].length }} new tags will be created during the import
                                                        </p>
                                                    </a-alert>
                                                </div>
                                            </a-sheet>
                                        </a-menu>
                                    </td>
                                    <td class="csv-table-cell-error" v-if="item.error && item.error['Notes']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.notes, '27', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Notes'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.notes">{{ item.notes }}</p>
                                    </td>
                                     <td class="csv-table-cell-error" v-if="item.error && item.error['Owner']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.owner_id, '27', '...') }}</p>
                                            </template>
                                            <span>{{ item.error['Owner'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td :class="{'csv-table-cell-error': item.ignored && !item.ignored['Owner'], 'csv-table-cell-ignore': item.ignored && item.ignored['Owner']}" v-else-if="item.warning && item.warning['Owner']">
                                        <a-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <p v-on="on" class="md-body-2 pa-0 ma-0" >&nbsp;{{ local_text_truncate(item.owner_id, '27', '...') }}</p>
                                            </template>
                                            <span>{{ item.warning['Owner'][0] }}</span>
                                        </a-tooltip>
                                    </td>
                                    <td v-else>
                                        <p class="md-body-2 pa-0 ma-0 text-truncate" :title="item.owner_id">{{ item.owner_id }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions, mapGetters} from 'vuex'

export default {
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            loading_file: false,
            loading_save: false,
            file_error_message: null,
            local_inventory_upload_filters: [],
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        local_render() {
            if (this.local_inventory_upload_filters.length) {
                return this.inventory_upload_list.filter(item => item.upload_status && this.local_inventory_upload_filters.includes(item.upload_status))
            }
            return this.inventory_upload_list;
        },

        local_check_valid_filter() {
            return this.local_is_filterd_inventory_upload_list('valid') && this.local_is_filterd_inventory_upload_list('existing-valid')
        },

        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapGetters('InventoryUpload', {
            inventory_upload_list: 'list',
            inventory_upload_response: 'response',
            inventory_upload_filters: 'filters',
            inventory_upload_meta: 'meta',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('inventories.store') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.local_fetch()
        },
        async local_fetch () {
            await this.inventory_upload_clear()
            await this.inventory_upload_index()
        },
        async local_store() {
            if (this.$refs.csv_file.files && this.$refs.csv_file.files.length > 0) {
                this.loading_file = true
                await this.inventory_upload_clear()
                await this.inventory_upload_store({project_id: this.$route.params.uuid, csv_file: this.$refs.csv_file.files[0]})
                if (this.inventory_upload_response.status == 'success') {
                    this.local_inventory_upload_filters = []
                    await this.local_fetch();
                }
                this.loading_file = false
            }
        },
        async local_destroy() {
            this.$refs.csv_file.value = null;
            await this.inventory_upload_destroy()
        },
        async local_save(overwrite = false) {
            this.loading_save = true;
            await this.inventory_upload_save({ type: overwrite ? 'overwrite' : 'ignore' })
            if (this.inventory_upload_response.status == 'success') {
                this.$router.push({name: 'inventory'})
            }
            this.loading_save = false;
        },
        async local_initiate() {
            this.$refs.csv_file.value = null
            this.$refs.csv_file.click()
        },
        async local_upload_clear() {
            this.$refs.csv_file.value = null;
            await this.inventory_upload_clear()
        },
        async local_import_clear() {
            this.$refs.csv_file.value = null;
            await this.inventory_clear_imported()
        },
        async local_format() {
            await this.inventory_upload_format()
        },
        local_text_truncate(text, length, separator) {
            return _.truncate(text, {length: length, separator: separator});
        },

        // inventory import changes

        async local_inventory_upload_fillter(status, clear = false) {
            if(clear === true) {
                this.local_inventory_upload_filters = []
                return
            }

            if(status === 'valid-all') {
                await this.local_check_valid_all_filter()
                return
            }

            const index = this.local_inventory_upload_filters.findIndex(item => item === status)
            if (index !== -1) {
                this.local_inventory_upload_filters.splice(index, 1)
                if(status === 'valid' || status === 'existing-valid') this.local_check_new_existing_filter()
                return
            }
            this.local_inventory_upload_filters.push(status)
            if(status === 'valid' || status === 'existing-valid') this.local_check_add_valid_filter()
        },

        local_check_add_valid_filter() {
            if(this.local_check_valid_filter) {
                const index = this.local_inventory_upload_filters.findIndex(item => item === 'valid-all')
                if (index !== -1) return
                this.local_inventory_upload_filters.push('valid-all')
            }
        },

        local_check_new_existing_filter() {
            const valid_index = this.local_inventory_upload_filters.findIndex(item => item === 'valid')
            const existing_valid_index = this.local_inventory_upload_filters.findIndex(item => item === 'existing-valid')
            if (valid_index === -1 && existing_valid_index === -1) {
                const valid_all_index = this.local_inventory_upload_filters.findIndex(item => item === 'valid-all')
                if (valid_all_index !== -1) this.local_inventory_upload_filters.splice(valid_all_index, 1)
            }
        },

        local_is_filterd_inventory_upload_list(status) {
            if (!this.local_inventory_upload_filters.length) return false

            const index = this.local_inventory_upload_filters.findIndex(item => item === status)
            return index !== -1
        },

        async local_check_valid_all_filter() {
            const index = this.local_inventory_upload_filters.findIndex(item => item === 'valid-all')
            if (index !== -1) {
                this.local_inventory_upload_filters.splice(index, 1)
                await this.local_remove_valid_records()
                return
            }

            this.local_inventory_upload_filters.push('valid-all')
            this.local_inventory_upload_filters.push('valid')
            this.local_inventory_upload_filters.push('existing-valid')
        },

        local_remove_valid_records() {
            const valid_index = this.local_inventory_upload_filters.findIndex(item => item === 'valid')
            if (valid_index !== -1) this.local_inventory_upload_filters.splice(valid_index, 1)

            const existing_valid_index = this.local_inventory_upload_filters.findIndex(item => item === 'existing-valid')
            if (existing_valid_index !== -1) this.local_inventory_upload_filters.splice(existing_valid_index, 1)
        },

        ...mapActions('InventoryUpload', {
            inventory_upload_index: 'index',
            inventory_upload_store: 'store',
            inventory_upload_save: 'save',
            inventory_upload_format: 'format',
            inventory_upload_destroy: 'destroy',
            inventory_upload_clear: 'clear',
            inventory_upload_clear_item: 'clear_item',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .csv-table {
        border-collapse: collapse !important;
        border-spacing: 5px;
        width: 100%;
    }

    .csv-table-row-header {
        th {
            background-color: #f8f9fb;
            border: 1px solid #ddd;
            color: #878384;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            padding: 4px;
            &:first-child {
                border-bottom: 4px solid #bcbcbc;
                border-right: 4px solid #bcbcbc;
                padding: 2px 5px !important;
                display: block;
            }
            &:first-child {
                position: sticky;
                left: 0;
                display: block;
            }
            // &:last-child {
            //     position: sticky;
            //     right: 0;
            // }
        }
    }

    .csv-table-row-title {
        th {
            &:not(.csv-table-cell-number) {
                border: 1px solid #ddd;
                background-color: #fff;
                color: #222;
                font-weight: 700;
                font-size: 14px;
                text-align: left;
                padding: 4px;
            }
            &:first-child {
                position: sticky;
                left: 0;
                border-left: 0;
            }
            // &:last-child {
            //     position: sticky;
            //     right: 0;
            // }
        }
    }

    .csv-table-cell-number {
        background-color: #f8f9fb;
        border: 1px solid #ddd;
        width: 48px;
        height: 32px;
        color: #878384;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }

    .csv-table-row-data {
        td {
            &:not(.csv-table-cell-number) {
                border: 1px solid #dddddd;
                background-color: #fff;
                color: #222;
                font-weight: 400;
                font-size: 14px;
                text-align: left;
                padding: 4px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 0;
            }
            &:first-child {
                position: sticky;
                left: 0;
                border-left: 0;
            }
            // &:last-child {
            //     position: sticky;
            //     right: 0;
            // }
        }
    }

    .csv-table-row-upload-status {
        position: sticky;
        left: 47px;
    }

    .csv-table-row-status-invalid td {
        background-color: #fff3e0 !important;
    }

    .csv-table-row-status-skipped td {
        background-color: #fff3e0 !important;
    }

    .csv-table-row-status-ignore td {
        background-color: #fff3e0 !important;
    }

    .csv-table-row-status-existing-valid td {
        background-color: #E3F2FD !important;
    }

    .csv-table-row-status-restore td {
        background-color: #E3F2FD !important;
    }

    .csv-table-column-identifier {
        width: 48px;
    }

    .csv-table-column-type {
        width: 48px;
    }

    .csv-table-column-title {
        width: calc(25% - 144px);
    }

    .csv-table-column-info {
        width: 48px;
    }

    .csv-table-row-status-invalid .csv-table-cell-error {
        border: 2px dashed orange !important;
    }

    .csv-table-row-status-existing-valid .csv-table-cell-error {
        border: 2px dashed orange !important;
    }

    .csv-table-row-status-restore .csv-table-cell-error {
        border: 2px dashed orange !important;
    }

    .csv-table-cell-existing-value {
        border: 2px dashed #41a0ee !important;
    }

    .csv-table-row-status-skipped .csv-table-cell-error,
    .csv-table-row-status-warning .csv-table-cell-error,
    .csv-table-row-status-ignore .csv-table-cell-error {
        border: 2px dashed orange !important;
    }

    .csv-table-row-status-warning .csv-table-cell-ignore,
    .csv-table-row-status-ignore .csv-table-cell-ignore,
    .csv-table-row-status-existing-valid .csv-table-cell-ignore {
        border: 2px dashed #a2a2a2 !important;
    }

    .csv-table-row-status-skipped .csv-table-cell-warning,
    .csv-table-row-status-warning .csv-table-cell-warning,
    .csv-table-row-status-existing-valid .csv-table-cell-warning {
        border: 2px dashed orange !important;
    }
</style>

<template>
	<a-main>
        <g-settings-breadcrumb v-if="!localUserIsExternal" previousRoute="settings" class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl pa-6 px-0 pt-2 :class="{ 'container--fluid': user_self && user_self.scope === 'internal' }">
            <g-page-header
                title="Profile"
                subtitle="Manage your user profile."
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                icon="person"
            ></g-page-header>
            <a-layout wrap align-start>
                <a-flex xs12>
                    <a-sheet class="u-elevation-custom-1 u-rounded-corners">
                        <a-container container--fluid grid-list-xl class="pa-4">
                            <a-layout wrap align-center class="px-3 mb-2">
                                <a-flex xs12>
                                    <div class="u-flex align-center">
                                        <div class="u-relative">
                                            <g-avatar :item="user_self" class="u-elevation-custom-1" style="border-radius: 50%" :size="128" font-size="32"></g-avatar>
                                        </div>
                                        <div class="ml-6 mt-2">
                                            <h2 class="text-h5 grey--text text--darken-3">{{ local_user_name }}</h2>
                                            <h3 class="text-subtitle-2 grey--text text--darken-1" v-if="user_self.role">{{ user_self.role.name }}</h3>
                                            <div class="mt-4">
                                                <input type="file" ref="profile_avatar" style="display: none;" @change="local_avatar_upload()">
                                                <a-btn small color="red darken-1" class="mr-2 red lighten-5" text v-if="user_self && user_self.avatar" @click="local_avatar_destroy()" :disabled="loading">
                                                    Remove Image
                                                </a-btn>
                                                <a-btn small class="elevation-0 u-border grey--text text--darken-1 mr-2" @click="local_avatar_select()" :disabled="loading">
                                                    <span v-if="user_self && user_self.avatar">Change</span>
                                                    <span v-else>Upload</span>
                                                    <span class="ml-1">Image</span>
                                                </a-btn>
                                                <span class="caption font-weight-normal grey--text text--darken-1"><strong>JPG/JPEG/PNG</strong> | Max <strong>2MB</strong> | Size <strong>256x256</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </a-flex>
                                <a-flex :class="{ 'xs6': profile_form_item && profile_form_item.scope === 'internal', 'xs12': profile_form_item && profile_form_item.scope === 'external'}">
                                    <label class="md-body-2 mb-2 d-inline-block mt-0 font-weight-medium">Your Name</label>
                                    <span class="md-caption red--text text--darken-2 mt-2" v-if="user_response.server && user_response.server.errors && user_response.server.errors && user_response.server.errors.name">
                                        <a-icon size="14" color="red darken-2">warning</a-icon>
                                        {{ user_response.server.errors.name[0] }}
                                    </span>
                                    <a-text-field
                                        v-model="profile_form_item.name"
                                        placeholder="Enter First Name"
                                        background-color="grey lighten-4"
                                        class="u-border"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                </a-flex>
                                <a-flex xs6 v-if="profile_form_item && profile_form_item.scope === 'internal'">
                                    <label class="md-body-2 mb-2 d-inline-block mt-0 font-weight-medium">Job Title (Optional)</label>
                                    <span class="md-caption red--text text--darken-2 mt-2" v-if="user_response.server && user_response.server.errors && user_response.server.errors && user_response.server.errors.job_title">
                                        <a-icon size="14" color="red darken-2">warning</a-icon>
                                        {{ user_response.server.errors.job_title[0] }}
                                    </span>
                                    <a-text-field
                                        v-model="profile_form_item.job_title"
                                        placeholder="Enter Job Title"
                                        background-color="grey lighten-4"
                                        class="u-border"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-sheet>
                    <a-container container--fluid grid-list-xl class="py-4 px-0 mt-2">
                        <a-layout wrap align-center>
                            <a-flex shrink>
                                <a-btn class="ma-0 grey lighten-2 elevation-0 pl-0" text @click="local_redirect()">
                                    <a-icon left class="mx-2">keyboard_arrow_left</a-icon>
                                    Cancel
                                </a-btn>
                            </a-flex>
                            <a-spacer></a-spacer>
                            <a-flex shrink>
                                <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_store()" :loading="loading" :disabled="loading">Save Profile</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-flex>
            </a-layout>
        </a-container>
        <a-snackbar v-model="snackbar_status" auto-height :timeout="snackbar_timeout" bottom left>
            <div class="u-flex">
                <span>{{ snackbar_text }}</span>
                <a-btn color="primary lighten-3" text @click="snackbar_status = false">Close</a-btn>
            </div>
        </a-snackbar>
	</a-main>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            profile_form_item: {},
            snackbar_status: false,
            snackbar_timeout: 5000,
            local_loading: true,
            snackbar_text: '',
            snackbar_response_status: '',
            dialog_stage_form: false,
            local_user_name: '',
            local_user_scope: ''
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        localUserIsExternal () {
            return _.get(this.user_self, 'scope') === 'external'
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
            user_item: 'item',
            user_response: 'response',
        }),
    },
    methods: {
        async local_index() {
            await this.user_clear_item()
            await this.user_me()
            this.local_update_user_name()
            this.$store.commit('User/UPDATE_ITEM', _.cloneDeep(this.user_self))
            this.profile_form_item = _.cloneDeep(this.user_self)
            this.local_loading = false
        },
        local_update_user_name() {
            this.local_user_scope = this.user_self.scope
            this.local_user_name = this.user_self.name
        },
        async local_store() {
            await this.user_profile_update({ ...this.profile_form_item, scope: this.local_user_scope })
            if (this.user_response.status === 'success') {
                this.snackbar_response_status = this.user_response.status
                this.snackbar_text = 'User profile updated successfully!'
                this.snackbar_status = true
                await this.user_me()
                this.profile_form_item = _.cloneDeep(this.user_self)
                this.local_update_user_name()
            }
        },
        local_avatar_select() {
            this.$refs.profile_avatar.value = ''
            this.$refs.profile_avatar.click()
        },
        async local_avatar_upload() {
            this.local_clear_snackbar()
            if (this.$refs.profile_avatar.files.length > 0) {
                await this.user_profile_avatar_update({avatar: this.$refs.profile_avatar.files[0], scope: this.local_user_scope})

                if (this.user_response.status === 'error') {
                    this.snackbar_response_status = this.user_response.status
                    this.snackbar_text = 'Unable to upload the profile picture. Please make sure the image meets the requirement outlined above.'
                    this.snackbar_status = true
                    return
                }

                await this.user_me()
                this.$store.commit('User/UPDATE_ITEM', _.cloneDeep(this.user_self))
            }
        },
        local_clear_snackbar() {
            this.snackbar_status = false
            this.snackbar_text = null
        },
        async local_avatar_destroy() {
            await this.user_profile_avatar_destroy({ scope: this.local_user_scope })
            await this.user_me()
            this.$store.commit('User/UPDATE_ITEM', _.cloneDeep(this.user_self))
        },

        local_redirect() {
            this.$router.push({ name: this.user_self.scope === 'external' ? 'dashboard' : 'settings' })
        },
        ...mapActions('User', {
            user_me: 'me',
            user_profile_update: 'profile_update',
            user_profile_avatar_update: 'profile_avatar_update',
            user_profile_avatar_destroy: 'profile_avatar_destroy',
            user_clear_item: 'clear_item',
        }),
    },
}
</script>

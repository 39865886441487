<template>
	<a-main>
        <a-container container--fluid grid-list-xl class="pa-0 mx-0 pb-16 mb-16">
            <g-page-header
                title="App Store"
                subtitle="Install applications, setup integrations and automate workflows."
                icon="apps"
                :loading="loading"
            ></g-page-header>
            <a-divider></a-divider>
            <a-layout v-if="local_page_loading">
                <a-flex>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-medium text-center grey--text text--darken-1 mb-1 text-capitalize">Loading Apps..</h3>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <template v-if="!local_page_loading && localFilteredAppletes.length > 0">
                <template v-for="applet in localFilteredAppletes">
                    <a-layout class="my-3" :key="applet.id" align-center v-if="applet.slug ==='salesforce' && user_self && user_self.organization && user_self.organization.flag_salesforce_microservice === 1">
                        <a-flex shrink>
                            <div class="u-border" style="display: flex; align-items: center; justify-content: center; width: 64px; height: 64px; border-radius: 0.75rem;">
                                <img :src="require('../../assets/images/apps/salesforce/logo.svg')" alt="salesforce" style="display: block; max-width: 32px; max-height: 32px;">
                            </div>
                        </a-flex>
                        <a-flex xs7 xl8>
                            <h2 class="md-heading-6">Salesforce [Custom Service]</h2>
                            <p class="md-subtitle-1 grey--text text--darken-1 mb-0">Add Salesforce integration and build workflows to exchange project data between Salesforce and Success.</p>
                        </a-flex>
                        <a-spacer></a-spacer>
                        <a-flex shrink class="text-right">
                            <p class="md-body-2 mb-0 green--text text--darken-2">
                                <a-icon size="22" class="u-icon-nudge" color="green">offline_bolt</a-icon> Connected
                            </p>
                        </a-flex>
                        <a-flex shrink>
                            <a-btn tag="a" color="grey darken-3" class="elevation-0 pa-3" dark small exact :to="{name: 'appstore-salesforce-microservices'}">
                                <a-icon class="mr-2" size="18" left>settings_input_antenna</a-icon> Configure
                            </a-btn>
                        </a-flex>
                    </a-layout>
                    <a-layout class="my-3" :key="applet.id" v-else>
                        <a-flex shrink>
                            <div class="u-border" style="display: flex; align-items: center; justify-content: center; width: 64px; height: 64px; border-radius: 0.75rem;">
                                <img :src="require('../../assets/images/apps/'+ applet.slug + '/logo.svg')" :alt="applet.title" style="display: block; max-width: 32px; max-height: 32px;">
                            </div>
                        </a-flex>
                        <a-flex xs7 xl8>
                            <h2 class="md-heading-6">{{ applet.title }}</h2>
                            <p class="md-subtitle-1 grey--text text--darken-1 mb-0">{{ applet.subtitle }}</p>
                        </a-flex>
                        <a-spacer></a-spacer>
                        <template v-if="applet.slug === 'jira'">
                            <template v-if="applet.installation && $plan('jira_integration:full-access')">
                                <a-flex shrink class="text-right">
                                    <p class="md-body-2 mb-0 orange--text text--darken-2" v-if="applet.installation.accounts && !applet.installation.accounts.length">
                                        <a-icon size="22" class="u-icon-nudge" color="orange">warning</a-icon> Connect Account
                                    </p>
                                    <p class="md-body-2 mb-0 green--text text--darken-2" v-if="(applet && applet.installation && applet.installation.is_active === 1) || (applet.installation && applet.installation.accounts && applet.installation.accounts.length)">
                                        <a-icon size="22" class="u-icon-nudge" color="green">offline_bolt</a-icon> Connected
                                    </p>
                                </a-flex>
                                <a-flex shrink class="text-right">
                                    <a-btn tag="a" color="grey darken-3" class="elevation-0 pa-3" dark small exact :to="{name: `appstore-${applet.slug}-workflows`, params: {id: applet.installation.id}}">
                                        <a-icon class="mr-2" size="18" left>settings_input_antenna</a-icon> Configure
                                    </a-btn>
                                </a-flex>
                            </template>
                            <template v-else>
                                <a-flex shrink v-if="$can('applets.update')">
                                    <a-btn v-if="localCurrentPlanLevel === 1" tag="a" color="yellow darken-3" class="elevation-0 pa-3 font-weight-medium" dark small @click="localOpenUpgradePopup()">
                                        <a-icon class="mr-1" size="16" left>flash_on</a-icon> Upgrade
                                    </a-btn>
                                    <a-btn v-else tag="a" color="blue darken-2" class="elevation-0 pa-3" dark small @click="localInstall(applet.id)">
                                        <a-icon class="mr-2" size="18" left>add</a-icon> Install
                                    </a-btn>
                                </a-flex>
                            </template>
                        </template>
                        <template v-else>
                            <template v-if="applet.installation">
                                <a-flex shrink class="text-right">
                                    <p class="md-body-2 mb-0 orange--text text--darken-2" v-if="applet.slug !== 'webhook' && applet.installation.accounts && !applet.installation.accounts.length">
                                        <a-icon size="22" class="u-icon-nudge" color="orange">warning</a-icon> Connect Account
                                    </p>
                                    <p class="md-body-2 mb-0 green--text text--darken-2" v-if="(applet.slug !== 'salesforce' && applet.installation.is_active === 1) || (applet.slug === 'salesforce' && (applet.installation && applet.installation.accounts && applet.installation.accounts.length))">
                                        <a-icon size="22" class="u-icon-nudge" color="green">offline_bolt</a-icon> Connected
                                    </p>
                                </a-flex>
                                <a-flex shrink class="text-right">
                                    <a-btn tag="a" color="grey darken-3" class="elevation-0 pa-3" dark small exact :to="{name: `appstore-${applet.slug}-workflows`, params: {id: applet.installation.id}}">
                                        <a-icon class="mr-2" size="18" left>settings_input_antenna</a-icon> Configure
                                    </a-btn>
                                </a-flex>
                            </template>
                            <template v-else>
                                <a-flex shrink v-if="$can('applets.update')">
                                    <a-btn tag="a" color="blue darken-2" class="elevation-0 pa-3" dark small @click="localInstall(applet.id)">
                                        <a-icon class="mr-2" size="18" left>add</a-icon> Install
                                    </a-btn>
                                </a-flex>
                            </template>
                        </template>
                    </a-layout>
                </template>

                <!-- Gong Integration -->
                <a-layout v-if="localCurrentPlanLevel === 3 && localGongHasAccess" class="my-3" align-center>
                    <a-flex shrink>
                        <div class="u-border" style="display: flex; align-items: center; justify-content: center; width: 64px; height: 64px; border-radius: 0.75rem;">
                            <img :src="require('../../assets/images/apps/gong/logo.svg')" alt="MicrosoftTeam" style="display: block; max-width: 32px; max-height: 32px;">
                        </div>
                    </a-flex>
                    <a-flex>
                        <div class="u-flex-center-y">
                            <h2 class="md-heading-6 mr-1">Gong</h2>
                            <GBetaFlag />
                        </div>
                        <p class="md-subtitle-1 grey--text text--darken-1 mb-0">Add Gong integration and sync all your meetings.</p>
                    </a-flex>
                    <a-flex shrink>
                        <p class="md-body-2 mb-0 green--text text--darken-2 text-center" v-if="localIntegrationHasAccess('gong')">
                            <a-icon size="22" color="green">offline_bolt</a-icon> Connected
                        </p>
                    </a-flex>
                    <a-flex shrink v-if="localIntegrationHasAccess('gong') && $can('applets.index')">
                        <a-btn tag="a" color="grey darken-3" class="elevation-0 pa-3" dark small exact :to="{name: 'appstore-gong'}">
                            <a-icon class="mr-2" size="18" left>settings_input_antenna</a-icon> Configure
                        </a-btn>
                    </a-flex>
                    <a-flex shrink v-else>
                        <a-btn v-if="$can('applets.update')" tag="a" color="blue darken-2" class="elevation-0 pa-3" dark small :href="gong_connect_url">
                            <a-icon class="mr-2" size="18" left>add</a-icon> Install
                        </a-btn>
                    </a-flex>
                </a-layout>

                <!-- Slack Integration -->
                <a-layout class="my-3" align-center>
                    <a-flex shrink>
                        <div class="u-border" style="display: flex; align-items: center; justify-content: center; width: 64px; height: 64px; border-radius: 0.75rem;">
                            <img src="../../assets/images/apps/slack/icon.svg" alt="Slack" style="display: block; max-width: 32px; max-height: 32px;">
                        </div>
                    </a-flex>
                    <a-flex>
                        <h2 class="md-heading-6">Slack</h2>
                        <p class="md-subtitle-1 grey--text text--darken-1 mb-0">Get latest project updates to your Slack channel.</p>
                    </a-flex>
                    <a-flex shrink>
                        <p class="md-body-2 mb-0 green--text text--darken-2 text-center" v-if="localIntegrationHasAccess('slack')">
                            <a-icon size="22" color="green">offline_bolt</a-icon> Connected
                        </p>
                    </a-flex>
                    <a-flex shrink v-if="localIntegrationHasAccess('slack') && $can('applets.destroy')">
                        <a-btn color="red darken-1" class="elevation-0 pa-3" dark small @click="localRevokeIntegrationAccess('slack')">
                            <a-icon class="mr-2" size="16" left>warning</a-icon> Revoke Access
                        </a-btn>
                    </a-flex>
                    <a-flex shrink v-else>
                        <a-btn v-if="$can('applets.update')" tag="a" color="blue darken-2" class="elevation-0 pa-3" dark small :href="slack_connect_url">
                            <a-icon class="mr-2" size="18" left>add</a-icon> Install
                        </a-btn>
                    </a-flex>
                </a-layout>

                <!-- Microsoft Teams Integration -->
                <a-layout class="my-3" align-center>
                    <a-flex shrink>
                        <div class="u-border" style="display: flex; align-items: center; justify-content: center; width: 64px; height: 64px; border-radius: 0.75rem;">
                            <img :src="require('../../assets/images/apps/ms-teams/microsoft-teams-logo.svg')" alt="MicrosoftTeam" style="display: block; max-width: 32px; max-height: 32px;">
                        </div>
                    </a-flex>
                    <a-flex>
                        <h2 class="md-heading-6">Microsoft Team</h2>
                        <p class="md-subtitle-1 grey--text text--darken-1 mb-0">Get latest project updates to your Microsoft Teams channel.</p>
                    </a-flex>
                    <a-flex shrink>
                        <p class="md-body-2 mb-0 green--text text--darken-2 text-center" v-if="localIntegrationHasAccess('microsoft_team')">
                            <a-icon size="22" color="green">offline_bolt</a-icon> Connected
                        </p>
                    </a-flex>
                    <a-flex shrink v-if="localIntegrationHasAccess('microsoft_team') && $can('applets.destroy')">
                        <a-btn color="red darken-1" class="elevation-0 pa-3" dark small @click="localRevokeIntegrationAccess('microsoft_team')">
                            <a-icon class="mr-2" size="16" left>warning</a-icon> Revoke Access
                        </a-btn>
                    </a-flex>
                    <a-flex shrink v-else>
                        <a-btn v-if="$can('applets.update')" tag="a" color="blue darken-2" class="elevation-0 pa-3" dark small :href="microsoft_team_connect_url">
                            <a-icon class="mr-2" size="18" left>add</a-icon> Install
                        </a-btn>
                    </a-flex>
                </a-layout>
            </template>
        </a-container>

        <!-- Admin Upgrade Popup -->
        <a-dialog max-width="600" v-model="dialog_admin_upgrade_modal">
            <SAdminUpgradeModal
                @upgrade="{}"
                @close="dialog_admin_upgrade_modal = false"
            >
                <template #title>
                    <h2 class="md-heading-6 font-weight-medium grey--text text--darken-4 mb-4">
                        Upgrade to Professional
                    </h2>
                </template>
                <template #plan-info>
                    <h3 class="mb-6 md-heading-6 grey--text text--darken-2 font-weight-medium text-center mx-auto">
                        Connect to Jira & bring your ticket details to Success.
                    </h3>
                </template>
            </SAdminUpgradeModal>
        </a-dialog>

        <!-- User Upgrade Popup -->
        <a-dialog max-width="400" v-model="dialog_user_upgrade_modal">
            <SUserUpgradeModal @close="dialog_user_upgrade_modal = false" />
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { SAdminUpgradeModal, SUserUpgradeModal } from '@/config/config-shared-components'

export default {
    components: { SAdminUpgradeModal, SUserUpgradeModal },

    data() {
        return {
            local_page_loading : false,
            expansion_panel: [true],
            loading_id: null,
            dialog_admin_upgrade_modal: false,
            dialog_user_upgrade_modal: false,
            org_self_fields: {
                'fields[organizations]': 'id,subscription_plan_id,name,logo,domain,projects_active_count',
                'fields[subscription_plan]': 'id,name,level'
            },
            gong_connect_url : process.env.VUE_APP_CORE_API_URL + '/' + window.location.hostname.split('.')[0] + '/internal/gong/authenticate',
            slack_connect_url : process.env.VUE_APP_CORE_API_URL + '/' + window.location.hostname.split('.')[0] + '/internal/slack/authenticate',
            microsoft_team_connect_url : process.env.VUE_APP_CORE_API_URL + '/' + window.location.hostname.split('.')[0] + '/internal/microsoft-teams/authenticate',
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localFilteredAppletes () {
            return this.applet_list
        },

        localCurrentPlanLevel () {
            return this.organization_item && this.organization_item.subscription_plan && this.organization_item.subscription_plan.level
        },

        localGongHasAccess () {
            return !!(this.user_self && this.user_self.organization && this.user_self.organization.flag_gong_integration && this.user_self.organization.flag_gong_integration === 1)
        },

        ...mapState('Applet', {
            applet_list: 'list',
        }),

        ...mapState('Installation', {
            installation_item: 'item',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Integration', {
            integration_statuses: 'list',
        }),

        ...mapState('Organization', {
            organization_item: 'item',
        }),
    },

    methods: {
        async localIndex () {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            this.local_page_loading = true
            await this.organization_me({ ...this.org_self_fields, include: 'subscriptionPlan' })
            await this.integration_status_index()

            await this.applet_clear()
            await this.applet_index({ include: 'installation.accounts' })
            this.local_page_loading = false
        },

        async localInstall (id) {
            this.loading_id = id
            await this.installation_clear()

            this.installation_item.applet_id = id
            await this.installation_store(this.installation_item)

            const applet = _.find(this.localFilteredAppletes, {id: id})
            applet.installation = _.cloneDeep(this.installation_item)
            await this.installation_clear_item()

            this.loading_id = null
        },

        localOpenUpgradePopup () {
            const { is_admin } = this.user_self || {}
            if (is_admin) return this.dialog_admin_upgrade_modal = true

            this.dialog_user_upgrade_modal = true
        },

        localIntegrationHasAccess (slug) {
            return !!this.integration_statuses[slug]
        },

        async localRevokeIntegrationAccess (slug) {
            await this.integration_revoke_access({ slug })
            this.integration_status_index()
        },

        ...mapActions('Applet', {
            applet_index: 'index',
            applet_clear: 'clear',
        }),

        ...mapActions('Installation', {
            installation_store: 'store',
            installation_clear: 'clear',
            installation_clear_item: 'clear_item',
        }),

        ...mapActions('Integration', {
            integration_status_index: 'statuses',
            integration_revoke_access: 'revoke_access',
        }),

        ...mapActions('Organization', {
            organization_me: 'me',
        }),
    },
}
</script>

import registerDynamic from '@/config/config-register-comp'

const UiComponents = [
    'AMain',
    'AContainer',
    'ALayout',
    'AFlex',
    'ACard',
    'ACardText',
    'ACardTitle',
    'ACardActions',
    'AAutocomplete',
    'ACombobox',
    'ASelect',
    'ATextField',
    'ABtn',
    'AMenu',
    'ASheet',
    'AAvatar',
    'AIcon',
    'ATooltip',
    'ADivider',
    'AImg',
    'AList',
    'AListItem',
    'AListItemGroup',
    'AListItemAction',
    'AListItemContent',
    'AListItemAvatar',
    'AListItemIcon',
    'AListItemSubtitle',
    'AListItemTitle',
    'AResponsive',
    'ASpacer',
    'ASlider',
    'AChip',
    'AProgressCircular',
    'AProgressLinear',
    'ATab',
    'ATabs',
    'ASnackbar',
    'ATextarea',
    'AForm',
    'ADialog',
    'ADataTable',
    'ADatePicker',
    // 'ARadio',
    // 'ARadioGroup',
    'ACheckbox',
    'AStepper',
    'AStepperContent',
    'AStepperHeader',
    'AStepperItems',
    'AStepperStep',
    'ABadge',
    'APagination',
    'AAlert'
]

UiComponents.forEach(comp => registerDynamic(comp, 'A', 'Alpha'))

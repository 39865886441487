<template>
	<div>
        <a-container container--fluid grid-list-xl pa-6 pt-0>
            <a-layout align-center>
                <a-flex shrink>
                    <a-btn color="grey darken-2" class="ma-0 pl-0" small text :to="{name: 'projects-overview'}" :exact="true">
                        <a-icon left class="mr-2" size="20">arrow_back</a-icon>
                        Back to overview
                    </a-btn>
                </a-flex>
            </a-layout>
            <g-page-header
                title="Notification Channels"
                subtitle="Manage project notification to your Slack channels."
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                icon="rss_feed"
            ></g-page-header>
            <channel-tabs></channel-tabs>
            <a-layout wrap align-center>
                <a-flex xs12>
                    <p class="body-2 mb-0" v-if="slack_status.slack_connection_status">Here are the commands to subscribe and unsubscribe project updates in slack channels. More information is available in <a class="blue--text text--darken-2" :href="slackIntegrationLink" target="_blank">KB article</a>.</p>
                    <p class="body-2 mb-0" v-else>Slack integration allows users to receive important Success updates in Slack channels. Go to App Store page and click on the Slack icon to get started. More information is available in <a class="blue--text text--darken-2" :href="teamsIntegrationLink" target="_blank">KB article</a>.</p>
                </a-flex>
            </a-layout>
            <a-layout wrap align-center v-if="slack_status.slack_connection_status">
                <a-flex xs12>
                    <h2 class="md-heading-6 mb-2">Instruction to Subscribe</h2>
                    <div>
                        <p class="body-2 mb-0">
                            Go to the Slack channel you wish to receive updates and copy &amp; paste the command for subscribing to project updates.
                        </p>
                        <div>
                            <code class="caption grey lighten-2 py-1">{{ slack_slash_command }} sub {{ user_self && user_self.organization ? user_self.organization.slug : '' }} {{ project_item.id }}</code>
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a-btn color="grey" class="ma-0" style="width: auto !important" text icon v-clipboard:copy="slack_slash_command + ' sub ' + (user_self && user_self.organization ? user_self.organization.slug : '') + ' ' + project_item.id" v-clipboard:success="local_command_copy" v-on="on">
                                        <a-icon size="21">description</a-icon>
                                    </a-btn>
                                </template>
                                <span>Copy Command</span>
                            </a-tooltip>
                        </div>
                    </div>
                </a-flex>
                <a-flex xs12>
                    <h2 class="md-heading-6 mb-2">Instruction to Unsubscribe</h2>
                    <div>
                        <p class="md-body-2 mb-0">
                            Go to the Slack channel you wish to unsubscribe then copy &amp; paste the command for unsubscribing from project updates.
                        </p>
                        <div>
                            <code class="caption grey lighten-2 py-1">{{ slack_slash_command }} unsub {{ user_self && user_self.organization ? user_self.organization.slug : '' }} {{ project_item.id }}</code>
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a-btn color="grey" class="ma-0" style="width: auto !important" text icon v-clipboard:copy="slack_slash_command + ' unsub ' + (user_self && user_self.organization ? user_self.organization.slug : '') + ' '  + project_item.id" v-clipboard:success="local_command_copy" v-on="on">
                                        <a-icon size="21">description</a-icon>
                                    </a-btn>
                                </template>
                                <span>Copy Command</span>
                            </a-tooltip>
                        </div>
                    </div>
                </a-flex>
                <a-flex xs12>
                    <h2 class="md-heading-6 mb-2">Subscribed Channels</h2>
                    <p class="md-body-2 mb-0" v-if="local_render().length > 0">Here's the list of Slack channels subscribed to receive feeds from this project.</p>
                    <p class="md-body-2 mb-0" v-else>No Slack channel has been subscribed to receive feeds from this project.</p>
                </a-flex>
                <a-flex xs12 v-if="local_render().length > 0" class="mb-3">
                    <a-card
                        v-for="channel in local_render()"
                        :key="channel.id"
                        class="u-elevation-custom-1 u-rounded-corners"
                    >
                        <a-card-text class="pl-3 pr-2 py-0">
                            <a-layout align-center class="py-0">
                                <a-flex class="py-3">
                                    <p class="md-body-2 mb-0"><strong>@{{ channel.slack_creator_username }}</strong> subscribed to receive feeds in <strong>#{{ channel.slack_recipient_username }}</strong> from <g-moment :value="channel.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY"></g-moment></p>
                                </a-flex>
                                <!-- <a-flex shrink class="py-2">
                                    <a-btn small icon flat color="red">
                                        <a-icon size="16">clear</a-icon>
                                    </a-btn>
                                </a-flex> -->
                            </a-layout>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
            <a-snackbar v-model="snackbar_status" :timeout="snackbar_timeout" bottom left>
                {{ snackbar_text }}
                <template v-slot:action>
                    <a-btn color="grey lighten-1 ma-0" text @click="snackbar_status = false">Close</a-btn>
                </template>
            </a-snackbar>
        </a-container>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { TabChannel } from '@/config/config-tab-components'

export default {
    components: {
        TabChannel
    },
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            snackbar_status: false,
            snackbar_timeout: 4000,
            snackbar_text: '',
            slackIntegrationLink: 'https://help.success.app/en/articles/6225851-how-to-configure-slack-integration',
            teamsIntegrationLink: 'https://help.success.app/en/articles/7165454-how-to-configure-microsoft-teams-integration',
            slack_slash_command: process.env.VUE_APP_SLACK_SLASH_COMMAND,
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Project', {
            project_item: 'item',
        }),
        ...mapState('Slack', {
            slack_status: 'status',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('projects.update-only') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            if (this.user_self.role.scope === 'external') {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            this.slack_status_show()

            await this.project_show({
                id: this.$route.params.id,
                params: { 'include': 'notification_channels' }
            })
        },
        local_command_copy() {
            this.snackbar_status = true
            this.snackbar_text = 'Command Copied!'
        },
        local_render() {
            return _.filter(this.project_item.notification_channels, {service: 'slack'})
        },
        ...mapActions('Project', {
            project_show: 'show',
        }),
        ...mapActions('Slack', {
            slack_status_show: 'status',
        }),
    }
}
</script>

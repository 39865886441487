<template>
    <div>
        <InternalActivities v-if="local_user_is_external" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import InternalActivities from '@/pages/Projects/ProjectsActivities.vue'

export default {
    components: {
        InternalActivities
    },

    computed: {
        local_user_is_external() {
            return this.user_self && this.user_self.scope === 'internal'
        },
        ...mapState('User', {
            user_self: 'self'
        })
    }

}
</script>

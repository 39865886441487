const api_endpoint = '/visibilities'

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        filters: {
        },
        response: [],
        defaults: {
            list: [],
            item: {},
            meta: {},
            filters: {

            },
            response: [],
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            Object.assign(state.list[index], payload.item)
        },
        UPDATE_LIST_VISIBILITY_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            if (index !== -1) {
                const projectVisibilityItem = {}
                projectVisibilityItem.id = payload.id
                projectVisibilityItem.key = payload.key
                projectVisibilityItem.visibility = payload.visibility
                state.list[index].project_visibility = projectVisibilityItem
            }
        },
        UPDATE_LIST_VISIBILITY_REMOVE_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            if (index !== -1) state.list[index].project_visibility = null
        },
        UPDATE_LIST_VISIBILITY_LIST_REPLACE(state, payload) {
            state.list = payload
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint, { params: { ...payload } })
                .then(async (response) => {
                    await context.commit('UPDATE_LIST', response.data.data)
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id, { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { id: response.data.data.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        list_update(context, payload) {        
            context.commit('UPDATE_LIST_VISIBILITY_ITEM', payload);
        },
        remove_item(context, payload) {
            context.commit('UPDATE_LIST_VISIBILITY_REMOVE_ITEM', payload);
        },
        list_replace(context, payload) {
            context.commit('UPDATE_LIST_VISIBILITY_LIST_REPLACE', payload);
        },
        upsert(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.put(api_endpoint, payload)
                .then((response) => {
                    context.commit('UPDATE_ITEM', payload);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.data });
                });
        },
        bulk_upsert(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.put(api_endpoint+'/bulk', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.data });
                });
        },
        bulk_destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/bulk-delete', { params: payload })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },

    getters: {
        get_list_item: (state) => (key, value) => {
            return state.list.find(item => item[key] === value)
        }
    }
}

<template>
    <a-main>
        <g-settings-breadcrumb previousRoute="settings" class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="py-6 pt-2 px-0 mb-10">
            <g-page-header
                title="Inventory Settings"
                subtitle="Customize stages associated with inventory."
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                icon="memory"
            >
            </g-page-header>
            <tab-inventory-meta></tab-inventory-meta>
            <a-layout>
                <a-flex xs12>
                    <draggable v-model="tag_list" :options="{handle: '.js-drag-handle'}">
                        <a-card v-for="tag in tag_list" :key="tag.id">
                            <a-card-text class="pa-0">
                                <a-container container--fluid grid-list-xl class="pa-4 px-6">
                                    <a-layout wrap align-center>
                                        <a-flex shrink class="pl-0 pr-1" v-if="loader_item === tag.id">
                                            <a-progress-circular color="blue lighten-2" size="20" width="2" indeterminate></a-progress-circular>
                                        </a-flex>
                                        <a-flex shrink class="px-0" v-if="loader_item !== tag.id && user_self.role && user_self.role.scope === 'internal' && $can('tags.update')">
                                            <a-icon class="grey--text text--lighten-1 u-cursor-pointer js-drag-handle">drag_indicator</a-icon>
                                        </a-flex>
                                        <a-flex>
                                            <span v-if="tag" class="md-body-2 d-inline-block py-1 px-2 u-rounded-corners" :style="{backgroundColor: $color(tag.color, 'color_bg'), color: $color(tag.color, 'color_text')}">{{ tag.label }}</span>
                                        </a-flex>
                                        <a-flex shrink v-if="$can('tags.update')">
                                            <s-color-palette v-model="tag.color" @input="color => local_update_debounced(color, tag)"></s-color-palette>
                                        </a-flex>
                                        <a-flex shrink v-if="$can('tags.update')">
                                            <a-btn color="grey darken-2" class="ma-0" @click="local_edit(tag.id)" text icon small>
                                                <a-icon small>edit</a-icon>
                                            </a-btn>
                                        </a-flex>
                                        <a-flex shrink v-if="$can('tags.destroy')">
                                            <a-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <a-btn color="grey darken-2" class="ma-0" :disabled="tag.inventory_stage_count > 0" @click="local_destroy(tag.id)" text icon small>
                                                            <a-icon small>delete</a-icon>
                                                        </a-btn>
                                                    </div>
                                                </template>
                                                <span class="text-center body-2" v-if="tag.inventory_stage_count === 0">Delete stage</span>
                                                <span class="text-center body-2" v-else>You have {{ tag.inventory_stage_count }} {{ tag.inventory_stage_count > 1 ? 'inventories' : 'inventory' }} under this stage. <br> Please move them to different stage to delete.</span>
                                            </a-tooltip>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-card-text>
                        </a-card>
                    </draggable>
                    <a-card class="u-elevation-custom-1" v-if="$can('tags.store')">
                        <a-card-text class="pa-0">
                            <a-form @submit.prevent="() => false">
                            <a-container container--fluid grid-list-xl class="py-2">
                                <a-layout wrap align-center>
                                    <a-flex shrink class="pr-0">
                                        <a-icon color="grey" size="20">add</a-icon>
                                    </a-flex>
                                    <a-flex class="pl-0 py-1">
                                        <a-text-field
                                            v-model="tag_item_value"
                                            placeholder="Type stage value and hit enter"
                                            solo flat hide-details
                                            @keyup.enter="local_store()"
                                        >
                                        </a-text-field>
                                     </a-flex>
                                    <a-flex shrink class="pr-4">
                                        <a-btn color="grey darken-2" text icon small @click="local_store()">
                                            <a-icon small>keyboard_return</a-icon>
                                        </a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            </a-form>
                        </a-card-text>
                    </a-card>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="tag_response.server && tag_response.server.errors && tag_response.server.errors.label && tag_form_mode == 'add'">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ tag_response.server.errors.label[0] }}
                    </span>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="tag_exist_message.type && tag_exist_message.type === 'add' && tag_exist_message.value">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        Stage already exists. Please create a unique stage.
                    </span>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="tag_exist_message.type && tag_exist_message.type === 'add_empty' && tag_exist_message.value">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ tag_exist_message.message }}
                    </span>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog v-model="dialog_tag_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>label</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (tag_form_mode == 'add') ? 'Create Tag' : 'Edit Stage' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (tag_form_mode == 'add') ? 'You can add new tag picklist value to the system.' : 'You can edit existing stage picklist value.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Label</label>
                                    <a-text-field
                                        v-model="tag_item.label"
                                        placeholder="Enter Label"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="tag_response.server && tag_response.server.errors && tag_response.server.errors.label  && tag_form_mode == 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ tag_response.server.errors.label[0] }}
                                    </span>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-else-if="tag_exist_message.type && tag_exist_message.type === 'edit' && tag_exist_message.value">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        Stage already exist.
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Enter the label to be selected.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_update()" :loading="loading" :disabled="loading">Save Value</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_close_edit_form()" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { TabInventoryMeta } from '@/config/config-tab-components'
import { SColorPalette } from '@/config/config-shared-components'

export default {
    data() {
        return {
            dialog_tag_form: false,
            tag_exist_message: { type: null, value: false, message: null },
            tag_item_value: '',
            previousRoute: null,
            timer_update_debounce: null,
            loader_item: null
        }
    },
    components: {
        SColorPalette,
        TabInventoryMeta
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.previousRoute = from
        })
    },
    mounted() {
        this.local_index()
    },
    watch:{
        dialog_tag_form(newValue, oldValue) {
            if (newValue === false) {
                this.tag_clear_item()
            }
        },
    },
    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Types & Statuses', to: '/settings/metas', exact: true})
            return breadcrumbs
        },
        tag_list: {
            get() {
                return this.$store.state.Tag.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },
        ...mapState('Tag', {
            tag_item: 'item',
            tag_form_mode: 'form_mode',
            tag_filters: 'filters',
            tag_response: 'response',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            if (!this.$can('tags.index')) {
                this.$router.replace({ name: 'errors-unauthorized' })
            }
            this.tag_clear()
            await this.tag_index({ 'include': 'inventoryStageCount', 'filter[type]': 'inventory_custom_status' })
        },
        async local_edit(id) {
            await this.tag_select({id: id})
            this.dialog_tag_form = true
        },
        async local_create() {
            await this.tag_clear_item()
            this.dialog_tag_form = true
        },
        async local_store() {
            await this.tag_clear_response()
            this.tag_item.type = 'inventory_custom_status'
            this.tag_item.label = this.tag_item_value
            this.tag_item.mode = 'store'
            this.tag_item.color = this.$randomColor()
            await this.tag_store({ ...this.tag_item })
            if (this.tag_response.status === 'success') {
                this.tag_item_value = ''
                await this.tag_clear_item()
            }
        },
        async local_update(id, color_bg){
            await this.tag_clear_response()
            if (id) await this.tag_select({id: id})
            await this.tag_update({
                id: this.tag_item.id,
                label: this.tag_item.label,
                color: this.tag_item.color,
                type: 'inventory_custom_status',
            })

            if (this.tag_response.status === 'success') {
                this.dialog_tag_form = false
                await this.tag_clear_item()
                this.loader_item = null
            }
        },
        async local_update_debounced(color, tag) {
            this.local_clear_debounce_timer()
            if (color) tag.color = color
            this.loader_item = tag.id
            this.timer_update_debounce = setTimeout(this.local_update, 200, tag.id)
        },
        async local_clear_debounce_timer() {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                this.loader_item = null
            }
        },
        async local_destroy(id) {
            await this.tag_destroy({id: id, type: 'inventory-tag'})
        },
        async local_reorder(list) {
            this.tag_reorder({list: list})
        },
        local_close_edit_form() {
            this.tag_exist_message = { type: null, value: false }
            this.tag_clear_response()
            this.dialog_tag_form = !this.dialog_tag_form
        },
        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_update: 'update',
            tag_select: 'select',
            tag_destroy: 'destroy',
            tag_reorder: 'reorder',
            tag_clear: 'clear',
            tag_clear_response: 'clear_response',
            tag_clear_item: 'clear_item',
        }),

    },
}
</script>

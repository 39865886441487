<template>
    <div v-on="$listeners" class="u-flex-center-y" style="column-gap: 8px;">
        <template v-if="!visibility || visibility === 'internal'">
            <a-icon size="20" color="grey darken-1">lock</a-icon>
            <span class="md-body-2 grey--text text--darken-3 font-weight-medium">Private</span>
        </template>
        <template v-if="visibility === 'external'">
            <a-icon size="20" color="amber darken-3">public</a-icon>
            <span class="md-body-2 amber--text text--darken-3 font-weight-medium">Public</span>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        visibility: {
            type: String,
            default: 'internal'
        }
    }
}
</script>

<template>
    <div class="spinner">
        <div :style="localCustomSize" class="dot1"></div>
        <div :style="localCustomSize" class="dot2"></div>
        <div :style="localCustomSize" class="dot3"></div>
    </div>
</template>

<script>
export default {
    props: {
        customSize: {
            type: Number | String,
            default: 15
        }
    },

    computed: {
        localCustomSize () {
            return { width: this.customSize + 'px', height: this.customSize + 'px' }
        }
    }
}
</script>

<style scoped>
.spinner {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dot1, .dot2, .dot3 {
    border: double;
    border-color: white;
    border-radius: 50%;
    margin: 10px;
}

.dot1 {
    animation: jump765 1.6s -0.32s linear infinite;
    background: #2495ff;
}

.dot2 {
    animation: jump765 1.6s -0.16s linear infinite;
    background: #2495ff;
}

.dot3 {
    animation: jump765 1.6s linear infinite;
    background: #2495ff;
}

@keyframes jump765 {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(2.0);
        transform: scale(2.0);
    }
}
</style>

<template>
    <g-project-page>
        <div class="u-flex-center-y d-inline-flex u-cursor-pointer mb-4" @click="$router.push({ name: 'projects-usecases' })">
            <a-icon size="16" color="grey darken-1">arrow_back</a-icon>
            <span class="md-body-2 ml-2 grey--text text--darken-1 font-weight-medium">Back to Scope</span>
        </div>

        <!-- Usecase section -->
        <a-layout>
            <a-flex xs12>
                <!-- <loader-usecase-view class="mb-4" v-if="local_loading"></loader-usecase-view> -->
                <s-usecase-view
                    :item="usecase_item"
                    :can-update="check_permission('update')"
                    :can-delete="check_permission('destroy')"
                    :response="usecase_response"
                    @update="usecase_update"
                    @delete="local_destroy"
                    @toggleVisibility="local_visibility_toggle"
                >
                    <template #assignee>
                        <div class="mr-6 u-flex-center-y">
                            <s-assignee-dropdown
                                :item="usecase_item"
                                :users-list="usecase_item.assignees"
                                :project-id="$route.params.id"
                                :can-update="check_permission('update')"
                                module-type="Usecase"
                                @assignee-store="local_get_usecase(usecase_item.id)"
                                @change-visibility="local_visibility_toggle(usecase_item.id, $event)"
                                class="u-flex align-center justify-end mr-3"
                                hide-external-confirmation
                            ></s-assignee-dropdown>
                        </div>
                    </template>
                    <template #tags>
                        <div class="pa-0 d-inline-flex flex-wrap">
                            <template v-if="usecase_item.tags && usecase_item.tags.length">
                                <template v-for="tag in usecase_item.tags">
                                    <g-tags :can-update="check_permission('update')" :tag="tag" type="Usecase" @destroy="local_associate_tag_destroy(tag, usecase_item)" :key="tag.id"></g-tags>
                                </template>
                            </template>
                            <a-menu bottom offset-y v-model="show_usecase_tag_add_input" v-if="check_permission('update')" :close-on-content-click="false" ref="refAddTagPopup" transition="slide-y-transition" nudge-bottom="8">
                                <template v-slot:activator="{ on }">
                                    <g-add-btn icon-size="14" v-on="on">
                                        <span class="md-caption body--text text--lighten-1 ml-1">Add Tag</span>
                                    </g-add-btn>
                                </template>
                                <a-sheet class="pb-1" max-width="240">
                                    <s-tags-inline-form
                                        :item="usecase_item"
                                        :can-update="$can('tags.store')"
                                        :list="local_get_filtered_tags(usecase_item)"
                                        :is-menu-open="show_usecase_tag_add_input"
                                        source="Usecase"
                                        type="usecase_tag"
                                        @store="local_load_tags_list"
                                        @after-store="() => show_usecase_tag_add_input = false"
                                    ></s-tags-inline-form>
                                </a-sheet>
                            </a-menu>
                        </div>
                    </template>
                </s-usecase-view>
            </a-flex>
        </a-layout>

        <!-- Success Criteria section -->
        <a-layout>
            <a-flex xs12 py-0>
                <div class="u-flex align-center">
                    <div class="d-inline-flex align-center">
                        <h2 class="secondary--text text-h6">Success Criteria</h2>
                        <span class="u-rounded-corners-full grey darken-2 white--text font-weight-medium md-caption ml-2 u-flex-center" style="width: 22px; height: 22px;">{{ local_scs_length }}</span>
                    </div>
                </div>

                <div class="mt-6" v-if="local_loading">
                    <a-sheet class="mb-4 px-6 py-8 u-shadow u-rounded-corners" v-for="i in 3" :key="i">
                        <div class="skeleton skeleton--text"></div>
                        <div class="u-flex-center-y">
                            <a-sheet width="120" class="skeleton mr-2 skeleton--text"></a-sheet>
                            <a-sheet width="240" class="skeleton mr-2 skeleton--text"></a-sheet>
                            <a-sheet width="200" class="skeleton mr-2 skeleton--text"></a-sheet>
                            <a-sheet width="120" class="skeleton mr-2"></a-sheet>
                        </div>
                    </a-sheet>
                </div>

                <div v-else>
                    <draggable v-model="success_criterion_list" :options="{handle: '.drag_handle_icon'}">
                        <template v-for="(success_criterion) in success_criterion_list">
                            <s-sc-tc-card
                                @click="local_show_edit_pane(success_criterion, 'SuccessCriterion')"
                                :is-public="local_is_external"
                                :item="success_criterion"
                                :can-update="check_permission('update')"
                                handle="drag_handle_icon"
                                :class="['mt-6 u-cursor-pointer']"
                                :is-loading="local_loading"
                                :key="success_criterion.id"
                                module="SuccessCriterion"
                            >
                                <template #status>
                                    <s-status-menu
                                        :can-update="check_permission('update')"
                                        :parent-list="success_criterion_list"
                                        :status-list="success_criterion_status_list"
                                        :item="success_criterion"
                                        @statusCommentStore="local_add_status_comment(true, $event)"
                                        module="SuccessCriterion"
                                        class="mr-6"
                                        outline
                                    ></s-status-menu>
                                </template>
                                <template #assignee>
                                    <div class="u-flex-center-y mr-6">
                                        <s-assignee-dropdown
                                            :item="success_criterion"
                                            :users-list="success_criterion.assignees"
                                            :project-id="$route.params.id"
                                            :can-update="check_permission('update')"
                                            @change-visibility="local_visibility_toggle(usecase_item.id, $event)"
                                            @assignee-store="local_get_success_criterion(success_criterion.id)"
                                            module-type="SuccessCriterion"
                                            :size="26"
                                            class="u-flex align-center justify-end"
                                            hide-empty-text
                                        ></s-assignee-dropdown>
                                    </div>
                                </template>
                            </s-sc-tc-card>
                        </template>
                    </draggable>
                </div>

                <template v-if="check_permission('update')">
                    <a-text-field
                        v-model="success_criterion_title"
                        placeholder="Add new Success Criteria"
                        :class="['u-rounded-corners mt-6 u-shadow']"
                        solo flat hide-details
                        :disabled="local_is_loading('sc_store')"
                        v-if="!local_loading"
                        @keydown.enter="local_success_criterion_store"
                    >
                        <template #prepend-inner>
                            <a-progress-circular width="3" size="20" class="mr-1" indeterminate color="orange darken-2" v-if="local_is_loading('sc_store')"></a-progress-circular>
                            <a-icon size="24" color="body lighten-4" v-else>add</a-icon>
                        </template>
                        <template #append>
                            <span @click="local_is_loading('sc_store') ? '' : local_success_criterion_store()" v-ripple class="u-cursor-pointer d-block px-1 u-rounded-corners">
                                <span class="md-caption text-uppercase body--text text--lighten-3 mr-2">Enter</span>
                                <a-icon size="24" color="body lighten-4">keyboard_return</a-icon>
                            </span>
                        </template>
                    </a-text-field>
                    <g-field-info show-icon-on-error :error="$response(success_criterion_response, 'title')" :has-error="$response(success_criterion_response, 'title')"></g-field-info>
                </template>
            </a-flex>
        </a-layout>

        <a-divider class="grey lighten-2 mt-10 mb-8"></a-divider>

        <!-- Testcase section -->
        <a-layout>
            <a-flex xs12 py-0>
                <div class="u-flex align-center">
                    <div class="d-inline-flex align-center">
                        <h2 class="secondary--text text-h6">Test Cases</h2>
                        <span class="u-rounded-corners-full grey darken-2 white--text font-weight-medium md-caption ml-2 u-flex-center" style="width: 22px; height: 22px;">{{ local_tcs_length }}</span>
                    </div>
                </div>

                <div class="mt-6" v-if="local_loading">
                    <a-sheet class="mb-4 px-6 py-8 u-shadow u-rounded-corners" v-for="i in 3" :key="i">
                        <div class="skeleton skeleton--text"></div>
                        <div class="u-flex-center-y">
                            <a-sheet width="120" class="skeleton mr-2 skeleton--text"></a-sheet>
                            <a-sheet width="240" class="skeleton mr-2 skeleton--text"></a-sheet>
                            <a-sheet width="200" class="skeleton mr-2 skeleton--text"></a-sheet>
                            <a-sheet width="120" class="skeleton mr-2"></a-sheet>
                        </div>
                    </a-sheet>
                </div>

                <div v-else>
                    <draggable v-model="testcase_list" :options="{handle: '.drag_handle_icon'}">
                        <template v-for="(testcase) in testcase_list">
                            <s-sc-tc-card
                                @click="local_show_edit_pane(testcase, 'Testcase')"
                                @open-timetracker="local_open_time_track"
                                :is-public="local_is_external"
                                :item="testcase"
                                :can-update="check_permission('update')"
                                handle="drag_handle_icon"
                                :class="['mt-6 u-cursor-pointer']"
                                :is-loading="local_loading"
                                :key="testcase.id"
                                module="Testcase"
                            >
                                <template #status>
                                    <s-status-menu
                                        :can-update="check_permission('update')"
                                        :parent-list="testcase_list"
                                        :status-list="testcase_status_list"
                                        :item="testcase"
                                        @statusCommentStore="local_add_status_comment(true, $event)"
                                        module="Testcase"
                                        class="mr-6"
                                        outline
                                    ></s-status-menu>
                                </template>
                                <template #assignee>
                                    <div class="u-flex-center-y mr-6">
                                        <s-assignee-dropdown
                                            :item="testcase"
                                            :users-list="testcase.assignees"
                                            :project-id="$route.params.id"
                                            :can-update="check_permission('update')"
                                            @assignee-store="local_get_testcase(testcase.id)"
                                            @change-visibility="local_visibility_toggle(usecase_item.id, $event)"
                                            module-type="Testcase"
                                            :size="26"
                                            class="u-flex align-center justify-end"
                                            hide-empty-text
                                        ></s-assignee-dropdown>
                                    </div>
                                </template>
                            </s-sc-tc-card>
                        </template>
                    </draggable>
                </div>

                <template v-if="check_permission('update')">
                    <a-text-field
                        v-model="testcase_title"
                        placeholder="Add new Test Case"
                        :class="['u-rounded-corners mt-6 u-shadow']"
                        solo flat hide-details
                        :disabled="local_is_loading('tc_store')"
                        v-if="!local_loading"
                        @keydown.enter="local_testcase_store"
                    >
                        <template #prepend-inner>
                            <a-progress-circular width="3" size="20" class="mr-1" indeterminate color="orange darken-2" v-if="local_is_loading('tc_store')"></a-progress-circular>
                            <a-icon size="24" color="body lighten-4" v-else>add</a-icon>
                        </template>
                        <template #append>
                            <span @click="local_is_loading('tc_store') ? '' : local_testcase_store()" v-ripple class="u-cursor-pointer d-block px-1 u-rounded-corners">
                                <span class="md-caption text-uppercase body--text text--lighten-3 mr-2">Enter</span>
                                <a-icon size="24" color="body lighten-4">keyboard_return</a-icon>
                            </span>
                        </template>
                    </a-text-field>
                    <g-field-info show-icon-on-error :error="$response(testcase_response, 'title')" :has-error="$response(testcase_response, 'title')"></g-field-info>
                </template>
            </a-flex>
        </a-layout>

        <!-- Detail View -->
        <a-dialog v-model="dialog_detail_view" :width="$vuetify.breakpoint.lgAndDown ? 1040 : 1080">
            <s-sc-tc-item-view
                :edit-item="local_edit_pane_item"
                :module="edit_pane_module"
                :loaders="loaders"
                :is-open="dialog_detail_view"
                tag-type="usecase_tag"
                :parent-item="usecase_item"
                :testcase-list="testcase_list"
                :success-criteria-list="success_criterion_list"
                :can-update="check_permission('update')"
                :can-delete="check_permission('destroy')"
                :can-comment="check_permission('update') || check_permission('comment')"
                @close="local_clear_edit_pane_view"
                @update="local_update_pane_item"
                @destroy="local_destroy_pane_item"
                @open-timetracker="local_open_time_track"
            >
                <template #status>
                    <s-status-menu
                        :can-update="check_permission('update')"
                        :parent-list="local_pane_model_parent_list"
                        :status-list="local_pane_status_list"
                        :item="local_edit_pane_item"
                        :module="edit_pane_module"
                        @statusCommentStore="local_add_status_comment(false, $event)"
                        input-class="py-1"
                        update-list-item
                    ></s-status-menu>
                </template>
                <template #assignee>
                    <s-assignee-dropdown
                        :item="local_edit_pane_item"
                        :users-list="local_edit_pane_item.assignees"
                        :project-id="$route.params.id"
                        :can-update="check_permission('update')"
                        :size="32"
                        :module-type="edit_pane_module"
                        @assignee-store="local_get_module(edit_pane_module, local_edit_pane_item.id)"
                        @change-visibility="local_visibility_toggle(usecase_item.id, $event)"
                        class="u-flex align-center justify-end mr-3"
                    ></s-assignee-dropdown>
                </template>
            </s-sc-tc-item-view>
        </a-dialog>

        <!-- Time tracking -->
        <a-dialog v-model="dialog_est_list" persistent scrollable max-width="700">
            <template v-if="time_track_testcase">
                <s-time-tracker
                    model="test case"
                    :title="time_track_testcase ? time_track_testcase.title : ''"
                    @add-entry="local_add_entry"
                    @delete-entry="local_delete_entry"
                    @close="local_close_time_track"
                    :item="time_track_testcase"
                    :loading="loading"
                    :records="time_record_list"
                    :can-update="check_permission('update')"
                >
                    <template v-slot:record-progress>
                        <s-time-progress
                            :limit="700"
                            :item="time_track_testcase"
                            :sum-mins="time_track_testcase.time_records_sum_duration_minutes"
                            :est-mins="time_track_testcase.estimated_duration_minutes"
                            :est-text="time_track_testcase.estimated_duration_text"
                            class="pb-2"
                            :can-update="check_permission('update')"
                            allow-est-edit
                            @est-update="local_est_update"
                        ></s-time-progress>
                    </template>
                </s-time-tracker>
            </template>
        </a-dialog>
    </g-project-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import {
    PTestcaseSection, PSuccessCriteriaSection, PStatusMenu, PEditPane,
    PTscToolbar, PUsecaseView, PScTcCard, PItemView
} from './Partials'

import {
    STimeTracker, STimeProgress,
    SAssigneeDropdown, SAssigneeForm,
    STagsInlineForm, SScTcItemView,
    SUsecaseView, SScTcCard, SStatusMenu } from '@/config/config-shared-components'
import { calculateByText } from '@/helpers/helper-time-tracker.js'

export default {
    components: {
        PTestcaseSection, PSuccessCriteriaSection, PStatusMenu, PEditPane,
        PTscToolbar, PUsecaseView, PScTcCard, PItemView,
        SAssigneeDropdown, STagsInlineForm, SAssigneeForm, STimeTracker, STimeProgress, SScTcItemView, SUsecaseView, SScTcCard, SStatusMenu
    },

    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            loaders: {
                sidebar_tags_loading: false,
                sidebar_time_est_loading: false,
                sidebar_comments_loading: false,
                content_autosave_response: false,
                sc_store: false,
                tc_store: false,
            },
            confirm_delete: false,
            content_autosave_timeout: false,
            edit_mode: false,
            testcase_title: '',
            success_criterion_title: '',
            usecase_title: '',
            usecase_description: '',
            testcase_status_list: [],
            edit_pane_module: '',
            local_edit_pane_item: { tags: [], comments: [] },
            dialog_detail_view: false,
            success_criterion_status_list: [],
            editor_description: null,
            timer_update_debounce: null,
            update_autosaved_at: null,
            local_collaborator_list: [],
            local_commentable_type: '',
            local_select_id: '',
            local_status_id: '',
            local_comment_text: null,
            comment_text: [],
            show_usecase_tag_add_input: false,
            accordion_usecase_description_expanded: true,
            local_loading: false,
            local_tag_fields: {
                'filter[type]': 'usecase_tag',
                'fields[tags]': 'id,label,type,color'
            },
            local_usecase_fields: {
                'include': 'tags,assignees.collaborator.type,updated_by,created_by',
                'fields[usecases]': 'id,title,description_json,code,project_id,status,visibility,updated_at,created_at,updated_by_id,created_by_id',
                'fields[updated_by]': 'id,name',
                'fields[created_by]': 'id,name',
                'fields[tags]': 'tags.id,tags.label,tags.type,tags.color',
                'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                'filter[collaborators]': true
            },
            local_testcase_fields: {
                'filter[usecase_id]': this.$route.params.usecase_id,
                'include': 'assignees.collaborator.type,status,tags,commentsCount,updated_by,created_by',
                'fields[testcases]': 'id,title,description_json,code,estimated_duration_minutes,usecase_id,estimated_duration_text,status_id,start_date,due_date,updated_at,created_at,updated_by_id,created_by_id',
                'fields[status]': 'id,type,value,status',
                'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                'aggregate[time_records.duration_minutes]': 'sum',
                'filter[collaborators]': true
            },
            local_success_criterion_fields: {
                'filter[usecase_id]': this.$route.params.usecase_id,
                'include': 'assignees.collaborator.type,status,commentsCount,updated_by,created_by',
                'fields[status]': 'id,type,value,status',
                'fields[success_criteria]': 'id,title,code,status_id,usecase_id,description_json,updated_at,created_at,updated_by_id,created_by_id',
                'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                'filter[collaborators]': true
            },
            local_testcase_status_fields: {
                'filter[type]': 'testcase_status',
                'fields[metas]': 'id,type,value,status',
                'sort': 'order'
            },
            local_success_criterion_status_fields: {
                'filter[type]': 'success_criterion_status',
                'fields[metas]': 'id,type,value,status',
                'sort': 'order'
            },
            time_record_params: {
                'include': 'createdBy',
                'fields[time_records]': 'id,duration_minutes,duration_text,description,created_at,start_date,resource_type,resource_id,created_by_id',
                'fields[created_by]': 'id,name,avatar,color,initial',
                'aggregate[duration_minutes]': 'count'
            },
            dialog_est_list: false,
            time_track_testcase: null,
            showEstimateEditForm: false,
            validationError: null,
        }
    },

    watch: {
        dialog_detail_view (val) {
            if (val) return
            this.local_clear_edit_pane_view()
            this.local_reset_edit_pane()
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        testcase_list: {
            get() {
                return this.$store.state.Testcase.list
            },
            set(list) {
                this.local_testcase_reorder(list)
            }
        },

        success_criterion_list: {
            get() {
                return this.$store.state.SuccessCriterion.list
            },
            set(list) {
                this.local_success_criterion_reorder(list)
            }
        },

        local_tcs_length () {
            return this.testcase_list && this.testcase_list.length
        },

        local_scs_length () {
            return this.success_criterion_list && this.success_criterion_list.length
        },

        local_is_external () {
            return this.usecase_item && this.usecase_item.visibility === 'external'
        },

        local_usecase_is_open () {
            return this.usecase_item && this.usecase_item.status === 'open'
        },

        local_pane_status_list () {
            if (this.edit_pane_module === 'Testcase') return this.testcase_status_list
            return this.success_criterion_status_list
        },

        local_pane_model_parent_list () {
            if (this.edit_pane_module === 'Testcase') return this.testcase_list
            return this.success_criterion_list
        },

        local_pane_response () {
            return this.edit_pane_module === 'Testcase' ? this.testcase_errors : this.success_criterion_errors
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Usecase', {
            usecase_item: 'item',
            usecase_response: 'response',
        }),

        ...mapState('Testcase', {
            testcase_item: 'item',
            testcase_response: 'response',
            testcase_form_mode: 'form_mode',
        }),

        ...mapGetters('Testcase', {
            testcase_errors: 'errors',
        }),

        ...mapGetters('SuccessCriterion', {
            success_criterion_errors: 'errors',
        }),

        ...mapState('SuccessCriterion', {
            success_criterion_item: 'item',
            success_criterion_response: 'response',
            success_criterion_form_mode: 'form_mode',
        }),

        ...mapState('Collaborator', {
            collaborator_list: 'list',
        }),

        ...mapState('TimeRecord', {
            time_record_list: 'list',
            time_record_response: 'response',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
        }),

        ...mapState('Comment', {
            comment_item: 'item',
            comment_response: 'response',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('Association', {
            association_response: 'response',
        }),

        ...mapState('Assignee', {
            assignee_item: 'item',
            assignee_response: 'response',
        }),
    },

    methods: {
        async local_index() {
            if (this.$can('usecases.index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.usecase_clear_item()
            await this.testcase_clear()
            await this.success_criterion_clear()

            await this.usecase_select({ id: this.$route.params.usecase_id })
            if (this.usecase_item) this.local_set_usecase_direct_fields()

            this.local_load_usecase_child_data()
            this.edit_mode = false
            this.local_set_async_usecase()
        },

        local_set_usecase_direct_fields () {
            this.usecase_title = this.usecase_item ? this.usecase_item.title : ''
            this.usecase_description = this.usecase_item ? this.usecase_item.description_json : ''
        },

        async local_set_async_usecase () {
            await this.usecase_show({id: this.$route.params.usecase_id, params: { ...this.local_usecase_fields, project_id: this.$route.params.id } })
            this.local_set_usecase_direct_fields()
        },

        async local_load_usecase_child_data() {
            this.local_loading = true
            await this.local_load_success_criterion_list()
            this.local_load_testcase_list()
            this.local_loading = false
            this.local_collaborator_filter_list()
            if (this.user_self.role && this.user_self.role.scope === 'internal') {
                this.local_load_testcase_status_list()
                this.local_load_success_criterion_status_list()
            }
        },

        async local_load_testcase_list() {
            await this.testcase_index({ params: { ...this.local_testcase_fields, project_id: this.$route.params.id } })
        },

        async local_load_success_criterion_list() {
            await this.success_criterion_index({ params: { ...this.local_success_criterion_fields, project_id: this.$route.params.id } })
        },

        local_collaborator_filter_list () {
            this.local_collaborator_list = this.collaborator_list
        },

        async local_load_testcase_status_list() {
            await this.meta_index(this.local_testcase_status_fields)
            this.testcase_status_list = _.cloneDeep(this.meta_list)
        },

        async local_load_success_criterion_status_list() {
            await this.meta_index(this.local_success_criterion_status_fields)
            this.success_criterion_status_list = _.cloneDeep(this.meta_list)
        },

        // Usecase CRUD
        async local_update () {
            this.update_autosaved_at = moment().format('YYYY-MM-DD HH:mm:ss')
            await this.usecase_update(this.usecase_item)
            if (this.usecase_response.status === 'success') {
                this.local_clear_debounce_timer(true)
                this.usecase_title = this.usecase_item.title
                this.usecase_description = this.usecase_item.description_json
                this.usecase_item.is_autosave = 0
            }
        },

        async local_update_debounced (state) {
            this.local_clear_debounce_timer()
            this.usecase_item.description_json = turndownService.turndown(state.getHTML())
            this.usecase_item.is_autosave = 1
            this.timer_update_debounce = setTimeout(this.local_update, 500)
        },

        async local_clear_debounce_timer (saved = false) {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                this.usecase_item.is_autosave = 0
            }
        },

        async local_usecase_clear () {
            this.usecase_title = this.usecase_item.title
            this.edit_mode = false
        },

        async local_toggle_usecase_status (id, state) {
            if (this.$can('usecases.update')) {
                await this.usecase_update_status({id: id, state })
                if (this.usecase_response.status !== 'success') return
                this.usecase_item.status = state
            }
        },

        async local_visibility_toggle (id, state) {
            this.local_update_visibility({ id, state: state === 'external' ? 'internal' : 'external' })
        },

        async local_update_visibility (item) {
            await this.usecase_visibility(item)
            if (this.usecase_response.status !== 'success') return
            this.$set(this.usecase_item, 'visibility', item.state)
        },

        async local_destroy (id) {
            await this.usecase_destroy({id: id})
            if (this.usecase_response.status == 'success') {
                this.$router.push({name: 'projects-usecases'})
            }
        },
        // Usecase CRUD ----- END

        // Sidebar Pane CRUD
        async local_show_edit_pane (edit_item, module) {
            this.local_clear_pane_store_items(module)
            if (this.local_edit_pane_item.id && (this.local_edit_pane_item.id !== edit_item.id)) {
                this.local_update_list_item(_.cloneDeep(this.local_edit_pane_item), this.edit_pane_module)
            }
            if (this.local_edit_pane_item && this.local_edit_pane_item.id === edit_item.id) return

            await this.local_reset_edit_pane()
            this.edit_pane_module = module
            this.local_edit_pane_item = _.cloneDeep(edit_item)
            if (!this.dialog_detail_view) this.dialog_detail_view = true
            await this.local_setup_pane(edit_item)
        },

        local_clear_pane_store_items () {
            if (module === 'Testcase') return this.testcase_clear_item()
            this.success_criterion_clear_item()
        },

        async local_setup_pane (edit_item) {
            this.local_load_status(edit_item)
            this.local_load_time_est_tags(edit_item)
            this.local_load_comments(edit_item)
            this.local_load_assignee_and_static_fields(edit_item)
        },

        async local_load_status (model) {
            this.loaders.sidebar_time_est_loading = true
            const params = { include: 'status', 'fields[status]': 'id,type,value,status' }
            const testcase_params = { 'fields[testcases]': 'id,status_id', }
            const success_criteria_params = { 'fields[success_criteria]': 'id,status_id' }

            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(model.id, { ...params, ...testcase_params }, 'update-item')
                this.local_edit_pane_item.status_id = this.testcase_item.status_id
                this.local_edit_pane_item.status = this.testcase_item.status
                // this.local_merge_pane_item(this.testcase_item)
            } else {
                await this.local_success_criterion_show_item(model.id, { ...params, ...success_criteria_params }, 'update-item')
                this.local_edit_pane_item.status_id = this.success_criterion_item.status_id
                this.local_edit_pane_item.status = this.success_criterion_item.status
                // this.local_merge_pane_item(this.success_criterion_item)
            }
            this.loaders.sidebar_time_est_loading = false
        },

        async local_load_time_est_tags (model) {
            this.loaders.sidebar_time_est_loading = true
            const params = { include: 'tags', 'fields[tags]': 'tags.id,label,tags.type,color' }
            const testcase_params = { 'fields[testcases]': 'id,estimated_duration_text,estimated_duration_minutes', 'aggregate[time_records.duration_minutes]': 'sum' }
            const success_criteria_params = { 'fields[success_criteria]': 'id' }

            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(model.id, { ...params, ...testcase_params }, 'update-item')
                this.local_edit_pane_item.tags = this.testcase_item.tags
                this.local_edit_pane_item.time_records_sum_duration_minutes = this.testcase_item.time_records_sum_duration_minutes
                this.local_edit_pane_item.estimated_duration_minutes = this.testcase_item.estimated_duration_minutes
                this.local_edit_pane_item.estimated_duration_text = this.testcase_item.estimated_duration_text
            } else {
                await this.local_success_criterion_show_item(model.id, { ...params, ...success_criteria_params }, 'update-item')
                this.local_edit_pane_item.tags = this.success_criterion_item.tags
            }
            this.loaders.sidebar_time_est_loading = false
        },

        async local_load_comments (item) {
            this.loaders.sidebar_comments_loading = true
            const params = {
                include: 'comments.createdBy',
                'fields[comments]': 'id,commentable_id,commentable_type,content,created_at,created_by_id,updated_at,visibility',
                'fields[comments.createdBy]': 'id,name,avatar,color,initial',
            }

            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(item.id, { ...params, 'fields[testcases]': 'id' }, 'update-item')
                this.local_edit_pane_item.comments = this.testcase_item.comments
            } else {
                await this.local_success_criterion_show_item(item.id, { ...params, 'fields[success_criteria]': 'id' }, 'update-item')
                this.local_edit_pane_item.comments = this.success_criterion_item.comments
            }
            this.loaders.sidebar_comments_loading = false
        },

        async local_load_assignee_and_static_fields (item) {
            const params = { include: 'assignees.collaborator.type', 'filter[collaborators]': true, 'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial', project_id: this.$route.params.id }
            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(item.id, { ...params, 'fields[testcases]': 'id,title,description_json,usecase_id' }, 'update-item')
                this.local_edit_pane_item.assignees = this.testcase_item.assignees
            } else {
                await this.local_success_criterion_show_item(item.id, { ...params, 'fields[success_criteria]': 'id,title,description_json,usecase_id' }, 'update-item')
                this.local_edit_pane_item.assignees = this.success_criterion_item.assignees
            }
        },

        local_merge_pane_item (item) {
            Object.assign(this.local_edit_pane_item, { ...item })
        },

        local_update_pane_item (evt) {
            if (this.edit_pane_module === 'Testcase') this.local_testcase_update(evt)
            else this.local_success_criterion_update(evt)
        },

        local_destroy_pane_item (evt) {
            if (this.edit_pane_module === 'Testcase') this.local_testcase_destroy(evt)
            else this.local_success_criterion_destroy(evt)
            this.local_clear_edit_pane_view()
            this.local_reset_edit_pane(true)
        },

        local_clear_edit_pane_view () {
            this.dialog_detail_view = false
            this.testcase_clear_item()
            this.success_criterion_clear_item()
            this.local_edit_pane_item = {}
        },

        async local_reset_edit_pane () {
            this.loaders.content_autosave_response = false
            this.local_edit_pane_item = { tags: [], comments: [], status_id: null, status: {} }
        },

        local_adjust_pane_height () {
            this.$refs.refNavDrawer.$el.firstChild.scrollTop = this.$refs.refNavDrawer.$el.firstChild.scrollHeight
        },

        local_get_filtered_tags (item) {
            return _.differenceBy(this.tag_list, item.tags, 'id')
        },
        // Sidebar Pane CURD ----- END

        // Testcase CRUD
        async local_testcase_store () {
            if (!this.testcase_title || (this.testcase_title && !this.testcase_title.trim())) {
                return this.testcase_clear_item()
            }
            this.local_set_loading('tc_store', true)
            await this.testcase_clear_item()
            this.testcase_item.usecase_id = this.$route.params.usecase_id
            this.testcase_item.title = this.testcase_title
            await this.testcase_store(this.testcase_item)
            if (this.testcase_response.status !== 'success') return this.local_set_loading('tc_store', false)
            await this.local_testcase_show(this.testcase_item.id, 'add')
            this.testcase_title = ''
            this.local_set_loading('tc_store', false)
        },

        async local_testcase_update (item) {
            await this.testcase_update(item)
            if (this.testcase_response && this.testcase_response.status !== 'success') {
                this.loaders.content_autosave_response = false
                return this.$notify('error', 'Something went wrong, please re-check the fields.')
            }
        },

        local_update_list_item (item, type) {
            const hasError = this.local_pane_response && this.local_pane_response.status !== 'success'
            if (hasError) {
                const oldItem = (type === 'Testcase' ? this.testcase_list : this.success_criterion_list).find(testcase => testcase.id === item.id)
                if (type === 'Testcase') return this.testcase_update_list(oldItem)
                return this.success_criterion_update_list(oldItem)
            }
            if (type === 'Testcase') return this.testcase_update_list(item)
            this.success_criterion_update_list(item)
        },

        async local_testcase_show (id, mode) {
            await this.testcase_show({ id: id, mode: mode ? mode : this.testcase_form_mode , params: { ...this.local_testcase_fields, project_id: this.$route.params.id } })
        },

        async local_testcase_reorder (list) {
            this.testcase_reorder({list: list})
        },

        async local_testcase_destroy (id) {
            await this.testcase_destroy({id: id})
            if (this.testcase_response && this.testcase_response.status !== 'success') return
            this.$notify('success', 'Test Case deleted successfully!')
        },
        // Testcase CRUD ----- END

        // SuccessCriterion CRUD
        async local_success_criterion_store() {
            if (!this.success_criterion_title || (this.success_criterion_title && !this.success_criterion_title.trim())) {
                return this.success_criterion_clear_item()
            }
            this.local_set_loading('sc_store', true)
            await this.success_criterion_clear_item()
            this.success_criterion_item.usecase_id = this.$route.params.usecase_id
            this.success_criterion_item.title = this.success_criterion_title
            await this.success_criterion_store(this.success_criterion_item)
            if (this.success_criterion_response.status !== 'success') return this.local_set_loading('sc_store', false)
            await this.local_success_criterion_show(this.success_criterion_item.id, 'add')
            this.success_criterion_title = ''
            this.local_set_loading('sc_store', false)
        },

        async local_success_criterion_show(id, mode) {
            await this.success_criterion_show({ id: id, mode: mode ? mode : this.success_criterion_form_mode, params: { ...this.local_success_criterion_fields, project_id: this.$route.params.id } })
        },

        async local_success_criterion_update(item) {
            await this.success_criterion_update(item)
            if (this.success_criterion_response && this.success_criterion_response.status !== 'success') {
                this.loaders.content_autosave_response = false
                return this.$notify('error', 'Something went wrong, please re-check the fields.')
            }
        },

        async local_success_criterion_reorder(list) {
            this.success_criterion_reorder({list: list})
        },

        async local_success_criterion_destroy(id) {
            await this.success_criterion_destroy({id: id})
            if (this.success_criterion_response && this.success_criterion_response.status !== 'success') return
            this.$notify('success', 'Success Criteria deleted successfully!')
        },
        // SuccessCriterion CRUD ----- END

        // Tags CRUD
        async local_associate_tag_destroy (data, item) {
            this.local_remove_tag(data, item)
            await this.association_destroy(data.association)
        },

        async local_remove_tag (tag, item) {
            const index = item.tags.findIndex(item => item.id === tag.id)
            if (index !== -1) item.tags.splice(index, 1)
        },

        local_load_tags_list () {
            this.tag_index({ 'filter[type]': 'usecase_tag', 'fields[tags]': 'id,label,color,type' })
        },
        // Tags CRUD ----- END

        // Comments CRUD
        async local_load_status_coment_item (type, id) {
            if (type === 'Testcase') {
                await this.local_testcase_show_item(id, { include: 'status', 'fields[testcases]': 'id,status_id', 'fields[status]': 'id,status,type,value' })
                Object.assign(this.local_edit_pane_item, _.find(this.testcase_list, {id: id}))
            }
            if (type === 'SuccessCriterion') {
                await this.local_success_criterion_show_item(id, { include: 'status', 'fields[success_criteria]': 'id,status_id', 'fields[status]': 'id,status,type,value' })
                Object.assign(this.local_edit_pane_item, _.find(this.success_criterion_list, {id: id}))
            }
        },

        async local_comment_store (comment, type, id) {
            await this.local_set_comment_vars(comment, type, id)
            await this.comment_store(this.comment_item)
            if (this.comment_response.status !== 'success') return
            this.local_edit_pane_item.comments.push(this.comment_item)
            this.$set(this.comment_text, id, '')
            setTimeout(() => {
                this.$refs.refNavDrawer.$el.firstChild.scrollTop = this.$refs.refNavDrawer.$el.firstChild.scrollHeight
                this.comment_clear_item()
            }, 0)
        },

        async local_set_comment_vars (comment, type, id) {
            this.comment_item.commentable_type = type
            this.comment_item.commentable_id = id
            this.comment_item.content = comment
            this.comment_item.created_by = this.user_self
            this.comment_item.created_by_id = this.user_self.id
            this.comment_item.created_at = new Date()
        },

        local_add_status_comment (direct_edit = false, { comment, item_id, module }) {
            if (direct_edit) return this.local_update_tc_sc_item_comment(module, item_id, comment)

            this.local_edit_pane_item.comments.push(comment)
            this.$set(this.local_edit_pane_item, 'comments_count', this.local_edit_pane_item.comments_count + 1)
            if (module === 'Testcase') {
                const index = this.testcase_list.findIndex(({ id }) => id === item_id)
                this.$set(this.testcase_list, index, this.local_edit_pane_item)
                return
            }
            const index = this.success_criterion_list.findIndex(({ id }) => id === item_id)
            this.$set(this.success_criterion_list, index, this.local_edit_pane_item)
        },

        local_update_tc_sc_item_comment (module, item_id) {
            const newItem = (module === 'Testcase' ? this.testcase_list : this.success_criterion_list).find(data => data.id === item_id)
            this.$set(newItem, 'comments_count', newItem.comments_count + 1)

            if (module === 'Testcase') return this.testcase_update_list(newItem)
            return this.success_criterion_update_list(newItem)
        },
        // Comments CRUD ----- END

        // Time tracker CRUD
        async local_time_record_index () {
            await this.time_record_index({
                ...this.time_record_params,
                'filter[resource_type]': 'Testcase',
                'filter[resource_id]': this.time_track_testcase.id,
                'sort': '-created_at'
            })
        },

        async local_open_time_track (testcase) {
            this.time_track_testcase = testcase
            this.dialog_est_list = true
            this.local_time_record_index()
        },

        local_close_time_track ({ resetFields }) {
            resetFields()
            this.time_record_clear()
            this.time_track_testcase = null
            this.dialog_est_list = false
            this.$emit('close-timetrack-form')
        },

        local_calculate_mins (evt) {
            const { totalMins } = calculateByText(evt)
            this.testcase_item.estimated_duration_minutes = totalMins
        },

        async local_add_entry ({ formData: record, resetFields, mode, record_id: id }) {
            const params = { ...record, mode: 'create', resource_type: 'Testcase', resource_id: this.time_track_testcase.id }
            if (mode && mode === 'edit') await this.time_record_update({ ...record, mode, id })
            else await this.time_record_store({ ...params })
            this.local_clear_time_record(resetFields)
        },

        async local_delete_entry ({ id, resetFields }) {
            await this.time_record_destroy({ id })
            this.local_clear_time_record(resetFields)
        },

        async local_est_update (evt) {
            await this.testcase_update(evt)
        },

        async local_clear_time_record (resetFields) {
            if (this.time_record_response && this.time_record_response.status === 'success') {
                await this.local_testcase_show(this.time_track_testcase.id, 'show')
                this.time_track_testcase.time_records_sum_duration_minutes = this.testcase_item.time_records_sum_duration_minutes
                this.time_track_testcase.estimated_duration_minutes = this.testcase_item.estimated_duration_minutes
                this.time_track_testcase.estimated_duration_text = this.testcase_item.estimated_duration_text
                resetFields()
            }
        },

        local_show_est_edit (durationText) {
            this.localEstText = durationText
            this.showEstimateEditForm = true
        },

        local_close_est_edit () {
            this.validationError = null
            this.localEstText = null
            this.showEstimateEditForm = false
        },
        // Time Tracker ----- END

        // Extras
        async local_usecase_show_item (params, type) {
            await this.usecase_show_item({ id: this.usecase_item.id, params: { ...params }, type })
        },

        async local_testcase_show_item (id, params, type) {
            await this.testcase_show_item({ id, params: { ...params }, type })
        },

        async local_success_criterion_show_item (id, params, type) {
            await this.success_criterion_show_item({ id, params: { ...params }, type })
        },

        local_show_autosave_response () {
            this.loaders.content_autosave_response = true
            setTimeout(() => this.loaders.content_autosave_response = false, 2000)
        },

        check_permission (type) {
            return this.$can(`usecases.${type}`) && this.$can('projects.update-only')
        },

        local_get_html (content) {
            return marked(content, { sanitize: true })
        },

        local_set_loading (type, value) {
            this.loaders[type] = value
        },

        local_is_loading (type) {
            return this.loaders[type]
        },

        // Usecase section
        local_get_delete_confirm () {
            this.confirm_delete = true
            setTimeout(() => this.confirm_delete = false, 3000)
        },

        local_delete_usecase () {},

        local_get_updated_time (date) {
            return moment(date).format('h:mm A')
        },

        local_get_updated_data (item, type) {
            if (!item) return
            const hasUpdated = item.updated_at ?? null
            if (hasUpdated) {
                if (type === 'type') return 'Updated'
                if (type === 'user' && item.updated_by) return item.updated_by.name
            }
            if (type === 'type') return 'Created'
            if (type === 'user' && item.created_by) return item.created_by.name
        },

        local_has_title_error (item) {
            if (!item || (item && !item.trim())) return false
            if (item && item.length > 255) return false
            return true
        },

        local_get_module (module, id) {
            if (module === 'SuccessCriterion') this.local_get_success_criterion(id, 'edit')
            if (module === 'Testcase') this.local_get_testcase(id, 'edit')
        },

        local_get_usecase (usecase_id) {
            this.usecase_show({
                id: usecase_id,
                mode: 'only-update-item',
                params: {
                    project_id: this.$route.params.id,
                    include: 'assignees.collaborator.type',
                    'fields[usecases]': 'id',
                    'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                    'filter[collaborators]': true
                }
            })
        },

        async local_get_success_criterion (success_criterion_id, type) {
            await this.success_criterion_show({
                id: success_criterion_id,
                params: {
                    project_id: this.$route.params.id,
                    include: 'assignees.collaborator.type',
                    'fields[success_criteria]': 'id,usecase_id',
                    'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                    'filter[collaborators]': true
                }
            })
            this.usecase_upsert_sc(this.success_criterion_item)
            if (type === 'edit') Object.assign(this.local_edit_pane_item, this.success_criterion_item)
        },

        async local_get_testcase (testcase_id, type) {
            await this.testcase_show({
                id: testcase_id,
                params: {
                    project_id: this.$route.params.id,
                    include: 'assignees.collaborator.type',
                    'fields[testcases]': 'id,usecase_id',
                    'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                    'filter[collaborators]': true
                }
            })
            this.usecase_upsert_tc(this.testcase_item)
            if (type === 'edit') Object.assign(this.local_edit_pane_item, this.testcase_item)
        },

        // NEW SECTION
        local_has_desc (item) {
            return item && item.description_json && (item.description_json.length && item.description_json.trim().length)
        },

        local_is_more (item) {
            if (item && item.description_json) {
                const getLength =  item.description_json.split('\n').length ?? null
                return (getLength && getLength > 5) || item.description_json.length > 966
            }
        },

        ...mapActions('Usecase', {
            usecase_show: 'show',
            usecase_update: 'update',
            usecase_select: 'select',
            usecase_show_item: 'show_item',
            usecase_upsert_sc: 'sc_upsert',
            usecase_upsert_tc: 'tc_upsert',
            usecase_update_status: 'update_status',
            usecase_visibility: 'visibility',
            usecase_destroy: 'destroy',
            usecase_clear_item: 'clear_item',
        }),

        ...mapActions('Testcase', {
            testcase_index: 'index',
            testcase_update_list: 'update_list',
            testcase_store: 'store',
            testcase_update: 'update',
            testcase_show: 'show',
            testcase_show_item: 'show_item',
            testcase_status: 'status',
            testcase_reorder: 'reorder',
            testcase_destroy: 'destroy',
            testcase_clear: 'clear',
            testcase_clear_item: 'clear_item',
        }),

        ...mapActions('SuccessCriterion', {
            success_criterion_index: 'index',
            success_criterion_update_list: 'update_list',
            success_criterion_store: 'store',
            success_criterion_update: 'update',
            success_criterion_show: 'show',
            success_criterion_show_item: 'show_item',
            success_criterion_status: 'status',
            success_criterion_reorder: 'reorder',
            success_criterion_destroy: 'destroy',
            success_criterion_clear: 'clear',
            success_criterion_clear_item: 'clear_item',
        }),

        ...mapActions('TimeRecord', {
            time_record_index: 'index',
            time_record_store: 'store',
            time_record_update: 'update',
            time_record_destroy: 'destroy',
            time_record_clear: 'clear',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_clear_list: 'clear_list',
        }),

        ...mapActions('Comment', {
            comment_store: 'store',
            comment_clear_item: 'clear_item',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
        }),

        ...mapActions('Association', {
            association_destroy: 'destroy',
        }),

        ...mapActions('Assignee', {
            assignee_store: 'store',
            assignee_clear: 'clear',
            assignee_destroy: 'destroy',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .c-testcase-pane {
        position: fixed;
        right: -1000px;
        top: 57px;
        height: 100vh;
        z-index: 2;
        transition: right 0.2s ease-in-out;
    }
    .c-show-pane {
        right: 0px;
        transition: right 0.2s ease-in-out;
    }
    .sortable-ghost {
        opacity: 0.8;
        box-shadow: 0px 2px 6px 4px rgb(173, 173, 173);
        border: 1px dashed rgb(116, 116, 116);
        background: #d2f1ff;
    }
    .sortable-chosen {
        opacity: 1 !important;
        box-shadow: 0px 2px 6px 4px rgb(173, 173, 173);
        border: none !important;
        border: 1px dashed rgb(116, 116, 116);
        background: #f5f5f5 !important;
    }
    .c-public-border {
        border-left: 2px solid #FF8F00;
    }
</style>

<template>
    <a-sheet class="u-rounded-corners-lg u-shadow u-flex flex-column justify-space-between" v-bind="$attrs" min-height="260">
        <div class="px-6 pt-5 u-flex-center-y justify-space-between">
            <div>
                <slot name="title">
                    <h4 class="mb-1 md-body-2 grey--text text--darken-2">Current subscription plan</h4>
                    <h3 style="font-size: 20px" class="grey--text text--darken-3 font-weight-bold">
                        <slot name="plan-name"></slot>
                    </h3>
                </slot>
            </div>
            <h3 v-if="planLevel === 1" class="md-heading-5 grey--text text--darken-3 font-weight-bold">Free</h3>
        </div>
        <div class="px-6 pt-4 pb-6">
            <slot>
                <slot name="progress-bar"></slot>
                <p class="mt-3 mb-0 md-body-2 font-weight-medium grey--text text--darken-1">Your current plan is limited to 10 active projects per month</p>
            </slot>
        </div>
        <div>
            <a-divider></a-divider>
            <div :class="[!hideAction ? 'py-5 u-cursor-pointer' : 'py-8']" class="px-6 c-plan-action u-wfull" @click="!hideAction ? $emit('action') : {}">
                <div class="u-flex-center-y" v-if="!hideAction">
                    <h4 class="mr-1 md-subtitle-1 font-weight-medium indigo--text text--darken-1">
                        <slot name="action-label">Compare plans</slot>
                    </h4>
                    <a-icon size="16" color="indigo darken-1">{{ actionIcon }}</a-icon>
                </div>
            </div>
        </div>
    </a-sheet>
</template>

<script>
export default {
    props: {
        planLevel: {
            type: Number,
            required: false,
            default: 1
        },
        hideAction: {
            type: Boolean,
            default: false
        },
        actionIcon: {
            type: String,
            default: 'arrow_forward'
        },
    }
}
</script>

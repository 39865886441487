<template>
    <div>
        <InternalUsecases v-if="local_user_is_external" />
        <ExternalUsecases v-else />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import InternalUsecases from '@/pages/ProjectsUsecases/ProjectsUsecases.vue'
import ExternalUsecases from '@/pages/External/ExternalProjects/ExternalProjectsUsecases.vue'

export default {
    components: {
        ExternalUsecases,
        InternalUsecases
    },

    computed: {
        local_user_is_external() {
            return this.user_self && this.user_self.scope === 'internal'
        },
        ...mapState('User', {
            user_self: 'self'
        })
    }

}
</script>

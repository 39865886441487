const api_endpoint = '/tickets';

export default {
    namespaced: true,
    state: {
        list: [],
        search_list: [],
        item: {},
        meta: {},
        filters: {
            status: 'all',
            parent_type: 'Project',
            parent_id: 'all',
            creator_id: 'all',
            type_id: 'all',
            workspace_id: null,
            page: 1,
            count: 10,
            sort_field: 'created_at',
            sort_direction: 'desc'
        },
        response: {},
        defaults: {
            list: [],
            search_list: [],
            item: {},
            meta: {},
            filters: {
                status: 'all',
                parent_type: 'Project',
                parent_id: 'all',
                creator_id: 'all',
                type_id: 'all',
                workspace_id: null,
                page: 1,
                count: 10,
                sort_field: 'created_at',
                sort_direction: 'desc'
            },
            response: {},
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_SEARCH_LIST(state, items) {
            state.search_list = items;
        },
        UPDATE_ITEM(state, item) {
            if (item.force) state.item = item
            else Object.assign(state.item, item)
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload.item;
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        PREPEND_ITEM(state, item) {
            state.list.unshift(item);
        },
        APPEND_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        CLEAR_ITEM(state, item) {
            state.item = item;
        },
        CLEAR_RESPONSE(state) {
            state.response = {};
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint, { params: payload })
                .then((response) => {
                    if (payload && payload.mode === 'search') {
                        context.commit('UPDATE_SEARCH_LIST', response.data.data);
                    } else {
                        if (payload && payload.type === 'pagination') context.commit('APPEND_LIST', response.data.data);
                        else context.commit('UPDATE_LIST', response.data.data);
                        context.commit('UPDATE_META', response.data.meta);
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            payload.params = payload.params ?? { 'include': 'status,priority,type,createdBy,assignees.role,tags,linksCount,linksWorkspace.status,linksWorkspace.parent.keyCollaborators.type,linksWorkspace.parent.keyCollaborators.user,linksWorkspace.parent.customer' }
            return ext_axios.get(api_endpoint + '/' + payload.id, { ...payload })
                .then(async (response) => {
                    if (payload && payload.mode === 'add') {
                        context.commit('PREPEND_ITEM', response.data.data)
                        context.commit('UPDATE_ITEM', response.data.data)
                    } else {
                        context.commit('UPDATE_ITEM', response.data.data);
                        context.commit('UPDATE_LIST_ITEM', { id: response.data.data.id, item: response.data.data });
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', code: error.response.status });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.post(api_endpoint, payload)
                .then(async (response) => {
                    if (payload.mode && payload.mode === 'ticket_create') await context.commit('UPDATE_ITEM', payload)
                    // else await context.dispatch('show', { ...payload })
                    // this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: response.data.data });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        clone_store(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.post(api_endpoint + '/clone', payload)
                .then((response) => {
                    context.dispatch('show', { ...payload })
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: response.data.data });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id, { ...payload.item })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.dispatch('show', { ...payload })
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        visibility(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id + '/states/visibility', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.data)
            return ext_axios.patch(api_endpoint + '/reorder', { data: payload.data })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear_search_list(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_SEARCH_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                resolve('Cleared');
            });
        },
        clear_response(context) {
            return new Promise((resolve, reject) => {
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                resolve('Cleared');
            });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('CLEAR_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('UPDATE_SEARCH_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('CLEAR_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },
}

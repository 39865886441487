<template>
    <a-responsive class="c-filters-side-pane elevation-12" :class="{'visible': isOpen}">
        <div style="position: relative">
            <div class="font-weight-medium white" style="position: sticky; top: 0; z-index: 200">
                <a-layout align-center class="pa-6">
                    <a-flex>
                        <div class="d-flex">
                            <a-icon size="24" color="grey darken-4">filter_alt</a-icon>
                            <span class="pl-1" style="fontSize: 20px">Filters</span>
                        </div>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink>
                        <a-btn small icon @click="local_revert_filter()">
                            <a-icon size="20" color="grey">cancel</a-icon>
                        </a-btn>
                    </a-flex>
                </a-layout>
                <a-divider></a-divider>
            </div>
            <div class="c-filter-content" ref="task_pane_filters">
                <div class="px-6 mt-5 pb-3">
                    <div class="md-body-1 font-weight-medium grey--text text--darken-2">Projects</div>
                    <v-radio-group v-model="local_project_filter" hide-details row class="mt-1">
                        <a-btn height="40" :class="[local_project_filter === 'all' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption mr-4 pl-3 pr-0" @click="local_project_filter='all'">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="all"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_project_filter === 'all' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']" style="width: 100%">All Projects</span>
                                </template>
                            </v-radio>
                        </a-btn>
                        <a-btn height="40" :class="[local_project_filter === 'active' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption pl-3 pr-0 mr-4" @click="local_project_filter='active'">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="active"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_project_filter === 'active' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']">Active Projects</span>
                                </template>
                            </v-radio>
                        </a-btn>
                    </v-radio-group>
                </div>
                <div class="px-6 mt-2 pb-2">
                    <div class="d-flex justify-space-between">
                        <div class="md-body-1 font-weight-medium grey--text text--darken-2">Type</div>
                        <div class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer" v-if="(selected_projects && selected_projects.length) || (selected_customers && selected_customers.length)" @click="local_clear_type_filter()">Clear</div>
                    </div>
                    <v-radio-group v-model="local_parent_filter" hide-details row class="mt-1">
                        <a-btn height="40" :class="[local_parent_filter === 'Project' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption mr-4 pl-3 pr-0" @click="local_set_parent_filter('Project')">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="Project"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_parent_filter === 'Project' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']" style="width: 100%">Project Notes</span>
                                </template>
                            </v-radio>
                        </a-btn>
                        <a-btn height="40" :class="[local_parent_filter === 'Customer' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption pl-3 pr-0 mr-4" @click="local_set_parent_filter('Customer')">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="Customer"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_parent_filter === 'Customer' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']">Account Notes</span>
                                </template>
                            </v-radio>
                        </a-btn>
                    </v-radio-group>
                </div>
                <div class="d-flex justify-space-between px-6 mb-5" v-if="local_parent_filter === 'Project'">
                    <a-autocomplete
                        v-model="selected_projects"
                        :items="local_project_list"
                        item-text="title"
                        item-value="id"
                        placeholder="All Projects"
                        class="pa-0 c-custom-placeholder u-border mt-2 "
                        :class="{'py-2 c-combo-border': selected_projects && selected_projects.length}"
                        background-color="grey lighten-5"
                        :loading="local_loading_projects"
                        :search-input.sync="local_projects_search"
                        @blur="local_projects_search = ''"
                        :append-icon="null"
                        solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object
                    >
                        <template v-slot:selection="{ item, parent }">
                            <a-chip class="chip--select-multi grey lighten-2 grey--text text--darken-3 mx-1 my-1">
                                <div class="d-inline-flex align-center">
                                    <span class="md-body-2">{{ item.title | truncateText(20) }}</span>
                                    <a-icon size="18" color="grey darken-1" class="u-cursor-pointer ml-1"  @click.stop="parent.selectItem(item)">cancel</a-icon>
                                </div>
                            </a-chip>
                        </template>
                        <template v-slot:item="{ item }">
                            <a-chip class="chip--select-multi grey lighten-2 grey--text text--darken-3 mx-1 mb-0">
                                <div class="d-inline-flex align-center">
                                    <span class="md-body-2">{{ item.title | truncateText(45) }}</span>
                                </div>
                            </a-chip>
                        </template>
                        <template #no-data v-if="!mixinIsLoading('project-search')">
                            <div style="height: 32px" class="px-4 u-flex-center-y">
                                <span :class="['md-body-2 body--text text--lighten-1']">No Data Found</span>
                            </div>
                        </template>
                    </a-autocomplete>
                </div>
                <div class="d-flex justify-space-between px-6 mb-5" v-else>
                    <a-autocomplete
                        v-model="selected_customers"
                        :items="local_customer_list"
                        item-text="name"
                        item-value="id"
                        placeholder="All Accounts"
                        class="pa-0 c-custom-placeholder u-border mt-2"
                        :class="{'py-2 c-combo-border': selected_customers && selected_customers.length}"
                        background-color="grey lighten-5"
                        :loading="local_loading_customers"
                        :search-input.sync="local_customers_search"
                        @blur="local_customers_search = ''"
                        :append-icon="null"
                        solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object
                    >
                        <template v-slot:selection="{ item, parent }">
                            <a-chip class="chip--select-multi grey lighten-2 grey--text text--darken-3 mx-1 my-1">
                                <div class="d-inline-flex align-center">
                                    <span class="md-body-2">{{ item.name | truncateText(20) }}</span>
                                    <a-icon size="18" color="grey darken-1" class="u-cursor-pointer ml-1"  @click.stop="parent.selectItem(item)">cancel</a-icon>
                                </div>
                            </a-chip>
                        </template>
                        <template v-slot:item="{ item }">
                            <a-chip class="chip--select-multi grey lighten-2 grey--text text--darken-3 mx-1 mb-0">
                                <div class="d-inline-flex align-center">
                                    <span class="md-body-2">{{ item.name | truncateText(45) }}</span>
                                </div>
                            </a-chip>
                        </template>
                        <template #no-data v-if="!mixinIsLoading('customer-search')">
                            <div style="height: 32px" class="px-4 u-flex-center-y">
                                <span :class="['md-body-2 body--text text--lighten-1']">No Data Found</span>
                            </div>
                        </template>
                    </a-autocomplete>
                </div>
                <div class="px-6 mt-3 pb-3">
                    <div class="md-body-1 font-weight-medium grey--text text--darken-2">Visibility</div>
                    <v-radio-group v-model="local_visibility_filter" hide-details row class="mt-1">
                        <a-btn height="40" :class="[local_visibility_filter === 'all' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption mr-4 pl-3 pr-0" @click="local_visibility_filter='all'">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="all"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_visibility_filter === 'all' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']" style="width: 100%">All</span>
                                </template>
                            </v-radio>
                        </a-btn>
                        <a-btn height="40" :class="[local_visibility_filter === 'internal' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption pl-3 pr-0 mr-4" @click="local_visibility_filter='internal'">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="internal"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_visibility_filter === 'internal' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']">Private</span>
                                </template>
                            </v-radio>
                        </a-btn>
                        <a-btn height="40" :class="[local_visibility_filter === 'external' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption pl-3 pr-0" @click="local_visibility_filter='external'">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="external"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_visibility_filter === 'external' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']">Public</span>
                                </template>
                            </v-radio>
                        </a-btn>
                    </v-radio-group>
                </div>
                <div class="px-6 mt-2 pb-3">
                    <div class="md-body-1 font-weight-medium grey--text text--darken-2">Notes</div>
                    <v-radio-group v-model="local_note_source_filter" hide-details row class="mt-1">
                        <a-btn height="40" :class="[local_note_source_filter === 'all' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption mr-4 pl-3 pr-0" @click="local_note_source_filter='all'">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="all"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_note_source_filter === 'all' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']" style="width: 100%">All</span>
                                </template>
                            </v-radio>
                        </a-btn>
                        <a-btn height="40" :class="[local_note_source_filter === 'salesforce' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption pl-3 pr-0 mr-4" @click="local_note_source_filter='salesforce'">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="salesforce"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_note_source_filter === 'salesforce' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']">Imported from Salesforce</span>
                                </template>
                            </v-radio>
                        </a-btn>
                    </v-radio-group>
                </div>
                <div class="px-6 mt-2 pb-3 mb-14">
                    <div class="d-flex justify-space-between">
                        <label class="md-body-1 font-weight-medium grey--text text--darken-2">Category</label>
                        <div class="grey--text text--darken-1 md-subtitle-2 u-cursor-pointer" v-if="selected_categories && selected_categories.length && local_category_type === 'custom'" @click="selected_categories = []">Clear</div>
                    </div>
                    <v-radio-group v-model="local_category_type" hide-details row class="mt-1">
                        <a-btn height="40" :class="[local_category_type === 'all' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption mr-4 pl-3 pr-0" @click="local_set_category_type('all')">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="all"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_category_type === 'all' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']" style="width: 100%">All</span>
                                </template>
                            </v-radio>
                        </a-btn>
                        <a-btn height="40" :class="[local_category_type === 'none' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption pl-3 pr-0 mr-4" @click="local_set_category_type('none')">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="none"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_category_type === 'none' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']">No Category</span>
                                </template>
                            </v-radio>
                        </a-btn>
                        <a-btn height="40" :class="[local_category_type === 'custom' ? 'c-radio-btn' : 'u-border']" depressed outlined class="md-caption pl-3 pr-0 mr-4" @click="local_set_category_type('custom')">
                            <v-radio
                                color="indigo darken-1"
                                class="grey--text text-darken-1"
                                value="custom"
                                :ripple="false"
                            >
                                <template v-slot:label>
                                    <span :class="[local_category_type === 'custom' ? 'indigo--text text-darken-1' : '', 'md-subtitle-2']">Custom</span>
                                </template>
                            </v-radio>
                        </a-btn>
                    </v-radio-group>
                    <div>
                        <a-autocomplete
                            v-if="local_category_type === 'custom'"
                            v-model="selected_categories"
                            :items="local_note_category_list"
                            item-text="text"
                            item-value="id"
                            placeholder="All Categories"
                            class="pa-0 u-border mt-4 c-custom-placeholder"
                            :class="{'py-2 c-combo-border': selected_categories && selected_categories.length}"
                            background-color="grey lighten-5"
                            :loading="local_loading_categories"
                            :search-input.sync="local_categories_search"
                            @blur="local_categories_search = ''"
                            :append-icon="null"
                            solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object
                        >
                            <template v-slot:selection="{ item, parent }">
                                <a-chip class="chip--select-multi grey lighten-2 grey--text text--darken-3 mx-1 my-1">
                                    <div class="d-inline-flex align-center">
                                        <span class="md-body-2">{{ item.text | truncateText(20) }}</span>
                                        <a-icon size="18" color="grey darken-1" class="u-cursor-pointer ml-1"  @click.stop="parent.selectItem(item)">cancel</a-icon>
                                    </div>
                                </a-chip>
                            </template>
                            <template v-slot:item="{ item }">
                                <a-chip class="chip--select-multi grey lighten-2 grey--text text--darken-3 mx-1 mb-0">
                                    <div class="d-inline-flex align-center">
                                        <span class="md-body-2">{{ item.text | truncateText(45) }}</span>
                                    </div>
                                </a-chip>
                            </template>
                            <template #no-data v-if="!mixinIsLoading('category-search')">
                                <div style="height: 32px" class="px-4 u-flex-center-y">
                                    <span :class="['md-body-2 body--text text--lighten-1']">No Data Found</span>
                                </div>
                            </template>
                        </a-autocomplete>
                    </div>
                </div>
            </div>
            <div class="white" style="position: sticky; bottom: 0; z-index: 200">
                <a-divider class="mt-16"></a-divider>
                <a-layout class="px-6 pa-6">
                    <a-flex>
                        <a-btn depressed class="c-btn-filter white--text mr-4" color="indigo darken-1" @click="local_apply_filters()">Filter</a-btn>
                        <a-btn class="grey--text text--darken-3" depressed @click="local_revert_filter()">Cancel</a-btn>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink>
                        <a-btn class="c-btn-clearAll red--text text--darken-1" depressed @click="local_clear_all_filters()" color="red lighten-5">Clear all</a-btn>
                    </a-flex>
                </a-layout>
            </div>
        </div>
    </a-responsive>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { SDatePicker } from '@/config/config-shared-components'
import loadWorkspace from '@/mixins/mixin-workspace'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
export default {
    props: {
        isOpen: {
            type: Boolean
        },
        preferenceItem: {
            type: Object,
            required: true
        }
    },

    components: {
        SDatePicker
    },

    mixins: [loadWorkspace, mixinLoading],

    data () {
        return {
            local_project_filter: 'active',
            local_visibility_filter: 'all',
            local_parent_filter: 'Project',
            local_note_source_filter: 'all',

            type_list: [],
            local_project_list: [],

            local_category_type: 'all',
            local_categories_search: null,
            local_loading_categories: false,
            selected_categories: [],
            search_categories_timeout: null,

            local_projects_search: '',
            local_loading_projects: false,
            selected_projects: [],
            search_projects_timeout: null,

            local_customers_search: '',
            local_loading_customers: false,
            selected_customers: [],
            customer_filter: {
                NAME: 'filter[name]',
            },
            local_customer_fields: {
                'fields[customers]': 'id,name',
                'sort': 'name',
                'count': 5
            },
            local_customer_list: [],
            search_customers_timeout: null,
            local_customer_filters: {},
            project_filter: {
                NAME: 'filter[title]',
            },
            local_categories_filters: {},
            category_filter: {
                NAME: 'filter[status_search]',
                COUNT: 'count'
            },
            local_category_list: [],

            local_project_fields: {
                'fields[projects]': 'id,title',
                'count': 5
            },
            local_filter_projects: {},
            user_filters_pref: {},
            local_note_category_list: []
        }
    },

     watch: {
        isOpen (val) {
            if (!val) return
            this.local_index()
        },

        preferenceItem (val) {
            if (val && !val.id) return
            this.local_set_filter_pref()
        },

        local_projects_search (val) {
            if (!val || (val && !val.trim())) return
            this.local_projects_search_filter()
        },

        local_customers_search (val) {
            if (!val || (val && !val.trim())) return
            this.local_customers_search_filter()
        },

        local_categories_search (val) {
            if (!val || (val && !val.trim())) return
            this.local_categories_search_filter()
        }

    },

    computed: {

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('User', {
            users_list: 'list',
            user_self: 'self'
        }),

        ...mapState('Territory', {
            territories_list: 'list',
        }),

        ...mapState('Preference', {
            preference_list: 'list',
            preference_user_types: 'user_types',
        }),

        ...mapState('Project', {
            project_list: 'list',
        }),

        ...mapState('Customer', {
            customer_list: 'list',
        }),

    },

    methods: {
        async local_index () {
            this.local_set_filter_options()
            this.$refs.task_pane_filters.scrollTop = 0
        },

        async local_set_filter_options () {
            const category_meta_list = _.map(this.meta_list, function (item) {
                return { id: item.id,  text: item.value }
            })
            this.local_note_category_list = [...this.selected_categories, ...category_meta_list]

            const project_meta_list = _.map(this.project_list, function (item) {
                return { id: item.id,  title: item.title }
            })
            this.local_project_list = [...this.selected_projects, ...project_meta_list]

            const customer_meta_list = _.map(this.customer_list, function (item) {
                return { id: item.id, name: item.name }
            })
            this.local_customer_list = [...this.selected_customers, ...customer_meta_list]
        },

        local_categories_search_filter () {
            this.mixinSetLoading('category-search')
            clearTimeout(this.search_categories_timeout)
            this.search_categories_timeout = setTimeout(async () => {
                if(!this.local_categories_search || this.local_categories_search && !this.local_categories_search.trim()) return
                await this.local_load_meta_list()
            }, 500)
            this.mixinResetLoading('category-search')
        },

        local_projects_search_filter () {
            this.mixinSetLoading('project-search')
            clearTimeout(this.search_projects_timeout)
            this.search_projects_timeout = setTimeout(async () => {
                if(!this.local_projects_search || this.local_projects_search && !this.local_projects_search.trim()) return
                await this.local_load_project_list()
            }, 500)
            this.mixinResetLoading('project-search')
        },

        local_customers_search_filter () {
            this.mixinSetLoading('customer-search')
            clearTimeout(this.search_customers_timeout)
            this.search_customers_timeout = setTimeout(async () => {
                if(!this.local_customers_search || this.local_customers_search && !this.local_customers_search.trim()) return
                await this.local_load_customer_list()
            }, 500)
            this.mixinResetLoading('customer-search')
        },

        async local_load_meta_list() {
            await this.meta_clear()
            this.local_loading_categories = true
            this.local_categories_filters[this.category_filter.NAME] = this.local_categories_search
            this.local_categories_filters[this.category_filter.COUNT] = 5
            await this.meta_index({ 'filter[type]': 'note_type', 'sort': 'order', ...this.local_categories_filters})
            this.local_note_category_list = [...this.local_note_category_list, ...this.meta_list]
            this.local_loading_categories = false
        },

        async local_load_project_list() {
            this.local_loading_projects = true
            this.local_filter_projects[this.project_filter.NAME] = this.local_projects_search
            await this.project_index({ ...this.local_project_fields, ...this.local_filter_projects})
            this.local_project_list = [...this.local_project_list, ...this.project_list]
            this.local_loading_projects = false
        },

        async local_load_customer_list() {
            this.local_loading_customers = true
            this.local_customer_filters[this.customer_filter.NAME] = this.local_customers_search
            await this.customer_index({...this.local_customer_fields, ...this.local_customer_filters})
            this.local_customer_list = [...this.local_customer_list, ...this.customer_list]
            this.local_loading_customers = false
        },

        local_set_filter_pref () {
            if (this.preferenceItem && !this.preferenceItem.value) return
            const local_filter_value = JSON.parse(this.preferenceItem.value)
            for (let filtervalue in local_filter_value) {
                switch (filtervalue) {
                    case 'project_type':
                        this.local_project_filter = local_filter_value[filtervalue]
                        break;
                    case 'note_type':
                        this.local_parent_filter = local_filter_value[filtervalue]
                        break;
                    case 'projects':
                        this.selected_projects = local_filter_value[filtervalue]
                        break;
                    case 'customers':
                        this.selected_customers = local_filter_value[filtervalue]
                        break;
                    case 'visibility':
                        this.local_visibility_filter = local_filter_value[filtervalue]
                        break;
                    case 'note_source':
                        this.local_note_source_filter = local_filter_value[filtervalue]
                        break;
                    case 'category_type':
                        this.local_category_type = local_filter_value[filtervalue]
                        break;
                    case 'category':
                        this.selected_categories = local_filter_value[filtervalue]
                        break;
                    default: break;
                }
            }
        },

        local_set_parent_filter (value) {
            this.selected_projects = []
            this.selected_customers = []
            this.local_parent_filter = value
        },

        local_set_category_type(type) {
            this.local_category_type = type
            if (type !== 'custom') this.selected_categories = []
        },

        local_apply_filters () {
            // if (this.local_filter_errors) return
            this.local_set_user_pref()
            this.$emit('update', 'filter', this.user_filters_pref)
        },


        local_set_user_pref () {
            this.user_filters_pref = {
                project_type: this.local_project_filter,
                note_type: this.local_parent_filter,
                projects: this.selected_projects,
                customers: this.selected_customers,
                note_source: this.local_note_source_filter,
                category_type: this.local_category_type,
                category: this.selected_categories,
                visibility: this.local_visibility_filter,
            }
        },

        local_clear_all_filters() {
            this.local_reset_filters()
            this.local_apply_filters()
        },

        local_reset_filters() {
            this.local_project_filter = 'active'
            this.local_parent_filter = 'Project'
            this.selected_projects = []
            this.selected_customers = []
            this.local_visibility_filter = 'all'
            this.local_note_source_filter = 'all'
            this.local_category_type = 'all'
            this.selected_categories = []
        },

        local_revert_filter () {
            this.$emit('revert')
        },

        local_clear_type_filter () {
            this.selected_projects = []
            this.selected_customers = []
        },

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear'
        }),

        ...mapActions('Project', {
            project_index: 'index'
        }),

        ...mapActions('Customer', {
            customer_index: 'index',
            customer_clear: 'clear',
        }),

        ...mapActions('User', {
            user_index: 'index',
            user_show: 'show',
            user_clear: 'clear'
        }),
    }
}
</script>

<style scoped>
.c-filter-content {
    overflow-y: scroll;
    height: calc(96vh - 164px);
}
.c-radio-btn {
    border: 1px solid #3949AB;
    background-color: #E8EAF6;
}
.c-btn-clearAll.v-btn.v-btn--disabled {
    opacity: 0.5;
    background-color: #FFEBEE !important;
    color: #E53935 !important;
}
.c-combo-border {
    border: 1px solid rgba(238, 238, 238, 1) !important;
    background-color: #fafafa !important;
}
</style>

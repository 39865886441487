import EntityOperators from '@/helpers/helper-insights-entity-operators'

export default {
    methods: {
        local_get_entity_key (id, field = 'key') {
            if (!id) return ''
            const entity_properties = this.board_entity_list.reduce((prev, current) => prev = [...prev, ...current.entity_properties], [])
            entity_properties.push(...this.local_get_custom_fields_and_tags())
            const item = entity_properties.find(item => item.id === id)
            return item[field]
        },

        local_tile_entity_operators (id) {
            if (!id) return []
            const data_type = this.local_get_entity_key(id, 'data_type')
            return EntityOperators[data_type]
        },

        local_get_operator_type (id, key, field = 'label') {
            if (!id) return null
            const opsArr = this.local_tile_entity_operators(id)
            const operator = opsArr.find(item => item.key === key)
            return (operator ? operator[field] : null) ?? null
        },

        local_get_custom_fields_and_tags () {
            let entity_properties = []
            this.custom_field_list.forEach((field) => {
                if (field.type !== 'mask'){
                    const entity_option = {
                        data_type: field.type === 'date' ? 'date' : field.type === 'dropdown' ? 'dropdown' : 'string',
                        id: field.id,
                        key: `custom_field.${field.name}`,
                        label: field.label,
                        options: null
                    }
                    entity_properties.push(entity_option)
                }
            })
            this.tag_section_list.forEach((tag) => {
                const entity_option = {
                    data_type: 'dropdown',
                    id: tag.id,
                    key: tag.slug,
                    label: tag.name,
                    options: null
                }
                entity_properties.push(entity_option)
            })
            return entity_properties
        }
    }
}

<template>
    <a-main class="mt-4 mb-16">
        <a-btn small text color="white" class="pa-4 mx-0 blue text--darken-2 mb-6" v-if="$can('templates.store')" @click="local_open_import_dialog()">
            <span class="md-body-2">
                <a-icon size="18" left class="u-icon-nudge mr-1" style="top: -2px;">cloud_download</a-icon> Import Task Template
            </span>
        </a-btn>
         <a-responsive class="py-4 my-4" v-if="local_template_master_item && local_template_master_item.master_template_tasks && !local_template_master_item.master_template_tasks.length && !local_loading">
            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
            <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
        </a-responsive>
        <template v-if="local_loading">
            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1">Loading..</h3>
        </template>


        <template v-if="local_template_master_item && local_template_master_item.master_template_tasks && local_template_master_item.master_template_tasks.length && !local_loading">
            <draggable v-model="local_template_master_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                <div class="my-2 mt-1" :class="{ 'js-draggable-file-list-item': $can('templates.update') }" v-for="(master_template, index) in local_template_master_list" :key="index">
                    <a-container container--fluid grid-list-xl class="pa-4 px-6 u-rounded-corners u-elevation-custom-1 white">
                        <a-layout wrap align-center>
                            <a-flex shrink class="u-cursor-pointer pr-0">
                                <a-icon class="grey--text text--darken-1 js-drag-handle u-icon-nudge" :class="{'u-opacity-30': !$can('templates.update')}">drag_indicator</a-icon>
                            </a-flex>
                            <a-flex xl9 xs7>
                                <h2 class="subtitle-1 u-word-wrap">{{ master_template.name }}</h2>
                                <p class="md-body-2 mb-0 grey--text text--darken-2 mt-2">
                                    <span>{{ master_template.template_milestones_count ? master_template.template_milestones_count : 0 }} Milestones<span class="mx-2"> </span></span>
                                    <span>{{ master_template.template_tasks_count ? master_template.template_tasks_count : 0 }} Tasks<span class="mx-2"> </span></span>
                                </p>
                            </a-flex>
                            <a-spacer></a-spacer>
                            <a-flex shrink>
                                <a-tooltip bottom offset-overflow>
                                    <template v-slot:activator="{ on }">
                                        <a-btn v-on="on" icon>
                                            <a-icon size="16" class="grey--text text--darken-1">info</a-icon>
                                        </a-btn>
                                    </template>

                                    <div class="py-1">
                                        <p v-if="master_template.created_by" class="text-body-2 grey--text text--lighten-2 mb-0">Created by
                                            <span class="text-subtitle-2 white--text">{{ master_template.created_by.name }}</span>
                                            <span class="ml-2 text-caption">{{ local_date_output_format(master_template.created_at) }}</span>
                                        </p>

                                        <p v-if="master_template.updated_by && master_template.created_at !== master_template.updated_at" class="text-body-2 grey--text text--lighten-2 mb-0">Last updated by
                                            <span class="text-subtitle-2 white--text">{{ master_template.updated_by.name }}</span>
                                            <span class="text-caption ml-2">{{ local_date_output_format(master_template.updated_at) }}</span>
                                        </p>
                                    </div>
                                </a-tooltip>
                                <a-menu bottom left offset-y :close-on-content-click="true" max-width="320" v-if="$can('templates.destroy')">
                                    <template v-slot:activator="{ on }">
                                        <v-chip v-on.stop="on" class="ma-2 red lighten-5 red--text text-uppercase" small>
                                            <a-icon size="18" class="red--text" left>link</a-icon>
                                            Unlink
                                        </v-chip>
                                    </template>
                                    <a-sheet class="pa-4">
                                        <h2 class="md-heading-6 mb-2">Are you sure you want to unlink this system template?</h2>
                                        <p class="md-body-2 mb-4">This action will remove all the tasks linked from system template.</p>
                                        <a-btn class="ma-0 elevation-0 mr-2 text-uppercase" color="red" dark small :loading="local_unlink_loading" @click="local_associate_destroy(master_template)">
                                            <a-icon size="18" class="mr-1">link_off</a-icon> Unlink
                                        </a-btn>
                                    </a-sheet>
                                </a-menu>
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a-btn color="grey darken-2 u-cursor-pointer" v-on="on" text icon small exact target="_blank"  :to="{ name: 'master-templates-system-list-task-view', params: {master_template_id : local_template_master_item.id, system_group_id: master_template.id } }">
                                            <a-icon small>open_in_new</a-icon>
                                        </a-btn>
                                    </template>
                                    <span>View System Template</span>
                                </a-tooltip>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </div>
            </draggable>
        </template>

        <!-- Import Popup -->
        <a-dialog v-model="dialog_import_template" max-width="1000" persistent>
            <ModalImportSystemTemplates
                :templateList="task_group_import_list"
                :loading="template_group_loading"
                :importLoading="import_loading"
                :importPopup="dialog_import_template"
                @import="local_import_template"
                :selectedIds="selected_group_ids"
                @search="local_search_template"
                @showSelected="local_toggle_selected"
                @close="dialog_import_template = false"
            >
                <template #title>Import Tasks to Master Template</template>
                <template #subtitle>Search and import task groups from system templates.</template>
                <template #item="{ item, index }">
                    <!-- List Item -->
                    <a-sheet class="u-flex mx-8 pa-4 u-rounded-corners u-shadow u-cursor-pointer" @click="local_select_group_to_import(item.id, index)" :class="{ 'mt-4': index !== 0 }">
                        <a-checkbox :value="local_is_group_selected(item.id)" color="blue darken-2" @click="local_select_group_to_import(item.id, index)" class="mr-2 mt-0 pa-0" v-ripple="false" @change="local_select_group_to_import(item.id, index)" hide-details dense small></a-checkbox>
                        <div>
                            <h2 class="md-subtitle-1 grey--text text--darken-3 mb-2">{{ item.name }}</h2>
                            <p class="md-body-2 mb-0 grey--text text--darken-2">
                                <span>{{ item.template_milestones_count }} Milestones<span class="mx-2"> </span></span>
                                <span>{{ item.template_task_count }} Tasks<span class="mx-2"> </span></span>
                            </p>
                        </div>
                    </a-sheet>
                </template>
            </ModalImportSystemTemplates>
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { diffHuman } from '../../helpers/helper-date-filter'
import ModalImportSystemTemplates from './Modals/ModalImportSystemTemplates.vue'

export default {
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            snackbar_status: false,
            snackbar_text: '',
            task_group_import_list: [],
            template_group_name: null,
            template_task_title: '',
            template_task_due_date: null,
            template_task_priority: null,
            datepicker_due_date_new: false,
            template_group_expanded: null,
            dialog_user_form: false,
            dialog_template_group_form: false,
            dialog_template_task_form: false,
            local_template_group_clone_item: {},
            dialog_template_group_clone_form: false,
            dialog_import_template: false,
            template_group_loading: false,
            local_loading: false,
            selected_group_ids: [],
            showSelected: false,
            import_loading: false,
            local_unlink_loading: false,
            local_template_master_item: null,
        }
    },

    components: { ModalImportSystemTemplates },

    mounted() {
        this.local_index()
    },

    watch: {
        '$route.params.id': {
            handler () {
                this.local_index()
            },
            deep: true
        },
        template_group_expanded(newValue, oldValue) {
            if (newValue != null) {
                this.local_list(this.template_group_list[newValue].id)
            }
        },
        dialog_template_group_form(newValue, oldValue) {
            if (newValue === false) {
                this.template_group_clear_item();
            }
        },
    },

    computed: {
        local_master_template_id() {
            return this.$route.params.id
        },

        template_task_show_additional() {
            return this.template_task_title != ''
        },
        template_task_due_date_formatted() {
            if (this.template_task_due_date != null) {
                return moment(this.template_task_due_date, 'YYYY-MM-DD').format('MMMM DD, YYYY')
            }
        },
        local_template_master_list: {
            get() {
                return this.local_template_master_item.master_template_tasks
            },
            set(list) {
                this.local_association_reorder(list)
            }
        },
        template_task_list: {
            get() {
                return this.$store.state.TemplateTask.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },
        template_task_item: {
            get () {
                return this.$store.state.TemplateTask.item
            },
            set (item) {
                this.$store.state.TemplateTask.item = item
            }
        },
        template_group_list: {
            get() {
                return this.$store.state.TemplateGroup.list
            },
            set(list) {
                this.local_group_reorder(list)
                this.template_group_expanded = null
            }
        },
        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('TemplateMaster', {
            template_master_item: 'item',
            template_master_response: 'response',
        }),

        ...mapState('TemplateGroup', {
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),
        ...mapState('TemplateTask', {
            template_task_filters: 'filters',
            template_task_response: 'response',
            template_task_form_mode: 'form_mode',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_index() {
            if (this.$can('templates.show') == false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.local_loading = true
            this.local_template_master_item = null
            await this.local_template_master_show()
            this.local_loading = false
        },
        async local_template_master_show() {
            await this.template_master_show({
                'fields[template_groups]': 'id,name,is_active',
                'fields[master_template_tasks]' : 'template_groups.id,template_groups.name,template_groups.updated_at,template_groups.created_at,template_groups.updated_by_id,template_groups.created_by_id',
                'filter[type]': 'master_template',
                'include': 'masterTemplateTasks,masterTemplateTasks.templateTask,masterTemplateTasks.created_by,masterTemplateTasks.updated_by',
                'id': this.local_master_template_id,
            })
            this.local_template_master_item = {...this.template_master_item }
        },
        async local_load_task_template_group() {
            await this.template_group_index({
                'filter[type]': 'tasks',
                'fields[template_groups]': 'id,name,order,type',
                'filter[exclude_linked]' : this.local_master_template_id,
                'include': 'templateTaskCount,templateMilestonesCount',
                'sort': 'order'
            })
        },
        async local_list(id) {
            await this.template_task_clear()
            this.template_task_filters.template_group_id = id;
            await this.template_task_index()
        },
        async local_store(id) {
            await this.template_task_clear_item()
            this.template_task_item.title = this.template_task_title
            this.template_task_item.priority = this.template_task_priority
            this.template_task_item.template_group_id = id
            await this.template_task_store({
                ...this.template_task_item,
                template_group_id: id,
            })
            if (this.template_task_response.status == 'success') {
                this.template_task_title = ''
                this.template_task_priority = null
                await this.template_task_clear_item()
            }
        },
        async local_update() {
            await this.template_task_update()
            if (this.template_task_response.status == 'success') {
                this.dialog_template_task_form = false
                await this.template_task_clear_item()
            }
        },
        async local_template_clone_create(group_id) {
            this.local_template_group_clone_item.name = ''
            this.local_template_group_clone_item.template_group_id = group_id
            this.dialog_template_group_clone_form = true
        },
        local_group_clear_item() {
            this.template_task_clear_item()
            this.local_template_group_clone_item.name = null
            this.local_template_group_clone_item.template_group_id = null
        },
        async local_group_clone_cancel() {
            this.local_group_clear_item()
            this.dialog_template_group_clone_form = false
            this.local_template_group_clone_item.name = null
        },
        async local_group_clone() {
            await this.template_task_group_clone(this.local_template_group_clone_item)
            if (this.template_task_response.status == 'success') {
                this.local_load_task_template_group()
                this.template_group_expanded = null
                this.local_group_clear_item()
                this.dialog_template_group_clone_form = false
                this.local_show_group_message('Task group duplicated successfully')
            }
        },
        async local_reorder(list) {
            this.template_task_reorder({list: list})
        },
        async local_group_reorder(list) {
            await this.template_group_reorder({list: list})
        },
        async local_clear_item() {
            await this.template_task_clear_item()
        },
        async local_priority_update(priority) {
            this.template_task_item.priority = priority
            await this.template_task_update()
        },
        async local_edit(id) {
            await this.template_task_select({id: id})
            this.dialog_template_task_form = true
        },
        async local_destroy(id) {
            await this.template_task_destroy({id: id})
            await this.template_task_clear_item();
            this.template_group_list[this.template_group_expanded].template_tasks_count = this.template_task_list.length
        },
        async local_group_store() {
            if (this.template_group_form_mode == 'add') {
                this.template_group_item.name = this.template_group_name;
                await this.template_group_store({
                    name: this.template_group_item.name,
                    type: 'tasks',
                    model: 'template',
                    'order': -1
                })
            }
            if (this.template_group_form_mode === 'edit') {
                await this.template_group_update({ ...this.template_group_item })
            }
            if (this.template_group_response.status === 'success') {
                this.template_group_expanded = null
                await this.template_group_clear_item()
                this.dialog_template_group_form = false
                this.template_group_name = ''
            }
        },
        async local_change_template_task_group(new_group_id, old_group_id, template_task) {
            this.snackbar_status = false
            template_task.template_group_id = new_group_id

            this.template_task_select_item({ data: template_task })
            await this.template_task_group_update({ template_task })
            if (this.template_task_response.status === 'success') this.local_show_group_message('Task moved successfully')
        },
        local_show_group_message(text) {
            this.snackbar_text = `${ text }`
            this.snackbar_status = !this.snackbar_status
        },
        async local_group_form_cancel() {
            this.dialog_template_group_form = false
            await this.template_group_clear_item()
        },
        async local_template_task_form_cancel() {
            this.dialog_template_task_form = false
            await this.template_task_clear_item()
        },
        local_group_edit(id) {
            this.template_group_select({id: id})
            this.dialog_template_group_form = true
        },
        async local_group_destroy(id) {
            this.template_group_destroy({id: id})
            this.template_group_expanded = null
        },
        local_group_toggle(index) {
            if (this.template_group_expanded === index) {
                this.template_group_expanded = null
            }
            else {
                this.template_group_expanded = index
            }
        },

        // Import logic --- Start
        local_open_import_dialog () {
            this.dialog_import_template = true
            this.selected_group_ids = []
            this.local_get_template_group_list()
        },

        async local_get_template_group_list () {
            this.dialog_import_template = true
            this.template_group_loading = true
            this.task_group_import_list = []
            await this.template_group_clear()
            await this.template_group_index({
                'filter[type]': 'tasks',
                'fields[template_groups]': 'id,name',
                'filter[exclude_linked]' : this.local_master_template_id,
                'include': 'templateTaskCount,templateMilestonesCount',
                'sort': 'order'
            })
            this.task_group_import_list = [...this.template_group_list]
            this.template_group_loading = false
        },

        local_search_template ({ value, clearCb }) {
            if (!value && (value && !value.trim())) {
                this.task_group_import_list = this.template_group_list
                return clearCb()
            }

            const searctText = value ? value.trim().toLowerCase() : ''
            this.task_group_import_list = this.template_group_list.filter(item => {
                const hasIndex = this.selected_group_ids.indexOf(item.id)
                const hasName = item.name && item.name.toLowerCase().includes(searctText)
                if (!hasName) return null
                if (hasName && (!this.showSelected || (this.showSelected && hasIndex !== -1))) return item
                return null
            })
            clearCb()
        },

        local_toggle_selected ({ value: isSelected, searchText, clearCb }) {
            this.showSelected = isSelected
            if (!isSelected) return this.local_search_template({ value: searchText, clearCb })

            this.task_group_import_list = this.task_group_import_list.filter(item => {
                const index = this.selected_group_ids.indexOf(item.id)
                if (index !== -1) return item
                return null
            })
        },

        local_select_group_to_import (id, index) {
            const idIndex = this.selected_group_ids.findIndex(item => item === id)
            if (idIndex !== -1) this.selected_group_ids.splice(idIndex, 1)
            else this.selected_group_ids.push(id)
        },

        async local_import_template () {
            this.import_loading = true
            await this.local_group_import()
            this.close_dialog_import_form()
            this.local_template_master_item = null
            this.local_template_master_show()
            this.import_loading = false
            this.dialog_import_template = false
        },

        async local_group_import() {
            for (const group_id of this.selected_group_ids) {
                await this.association_store({
                    source_type: 'TemplateGroup',
                    source_id: this.local_master_template_id,
                    type: 'master_template',
                    target_type : 'TemplateGroup',
                    target_id : group_id
                })
            }
        },

        close_dialog_import_form() {
            this.dialog_import_template = false
            this.selected_group_ids = []
        },

        local_is_group_selected (id) {
            return this.selected_group_ids.indexOf(id) !== -1
        },

        async local_associate_destroy (data) {
            this.local_unlink_loading = true
            await this.association_destroy(data.association)
            this.local_template_master_show()
            this.template_group_expanded = null
            this.local_unlink_loading = false
        },

        local_date_output_format (date) {
            return diffHuman(date)
        },

        async local_association_reorder(list) {
            const reorderList = list.map(({ association }) => ({ id: association.id }))
            this.association_reorder({list: reorderList})
            this.local_template_master_item.master_template_tasks = [...list ]
        },

        // Import logic --- End

        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_store: 'store',
            template_group_update: 'update',
            template_group_reorder: 'reorder',
            template_group_select: 'select',
            template_group_destroy: 'destroy',
            template_group_clear: 'clear',
            template_group_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateTask', {
            template_task_index: 'index',
            template_task_show: 'show',
            template_task_store: 'store',
            template_task_group_update: 'group_task_update',
            template_task_group_clone: 'group_clone_store',
            template_task_select_item: 'select_item',
            template_task_complete: 'complete',
            template_task_uncomplete: 'uncomplete',
            template_task_client_visible: 'client_visible',
            template_task_client_invisible: 'client_invisible',
            template_task_update: 'update',
            template_task_select: 'select',
            template_task_upgrade: 'upgrade',
            template_task_downgrade: 'downgrade',
            template_task_destroy: 'destroy',
            template_task_reorder: 'reorder',
            template_task_clear: 'clear',
            template_task_clear_item: 'clear_item',
        }),

        ...mapActions('TemplateMaster', {
            template_master_show: 'show',
            template_master_clear_item: 'clear_item',
        }),

        ...mapActions('Association', {
            association_store: 'store',
            association_destroy: 'destroy',
            association_reorder: 'reorder',
        }),
    },
}
</script>

<style scoped>
.template_task-detail-pane {
    transition: 0.3s all;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    height: 100vh;
    width: 480px;
    overflow-y: scroll;
    transform: translateX(480px);
}
.template_task-detail-pane.visible {
    transform: translateX(0);
}
.v-textarea textarea {
    font-weight: 400;
    font-size: 24px;
}
.text-wrap-pre {
    white-space: pre-wrap;
}
</style>

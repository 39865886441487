<template>
    <div class="u-relative">
        <template v-if="showVisibility">
            <span :class="[reverseIcons ? 'c-bubble--right' : 'c-bubble--left']" class="c-bubble u-flex align-center justify-center" v-if="isPublic"></span>
            <span :class="[reverseIcons ? 'c-bubble--right' : 'c-bubble--left']" class="c-bubble c-bubble--private u-flex align-center justify-center" v-else>
                <a-icon size="14" class="mb-1 mr-1" color="black">lock</a-icon>
            </span>
        </template>
        <span class="c-dragger u-flex align-center justify-center" style="cursor: move; z-index: 1" :class="[(reverseIcons ? 'c-dragger--left' : 'c-dragger--right'), canDrag ? handle : '' ,{ 'u-opacity-30': !canDrag }]">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-move grey--text text--darken-4" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z"/>
            </svg>
        </span>
        <a-container container--fluid grid-list-xl :class="containerClass" class="u-relative">
            <slot>
            <!-- <slot name="content"></slot> -->
            <!-- <slot name="expand-buttons">
                <a-sheet class="c-expand u-absolute grey lighten-5 u-cursor-pointer u-wfull text-center" style="left: 0; bottom: 0px; border-radius: 0px 0px 6px 6px;" v-if="expanded">
                    <a-icon size="20" color="grey darken-1">expand_less</a-icon>
                </a-sheet>
                <a-sheet class="c-expand u-absolute grey lighten-5 u-cursor-pointer u-wfull text-center" style="left: 0; bottom: 0px; border-radius: 0px 0px 6px 6px;" v-else @click.native="$emit('expand')">
                    <a-icon size="20" color="grey darken-1">expand_more</a-icon>
                </a-sheet>
            </slot> -->
            </slot>
        </a-container>
    </div>
</template>

<script>
export default {
    props: {
        showVisibility: {
            type: Boolean,
            default: false
        },
        isPublic: {
            type: Boolean,
            default: false
        },
        canDrag: {
            type: Boolean,
            default: true
        },
        reverseIcons: {
            type: Boolean,
            default: false
        },
        expanded: {
            type: Boolean,
            default: false
        },
        handle: {
            type: String,
            default: 'js-drag-handle'
        },
        containerClass: {
            type: String,
            default: 'pa-6'
        },
    }
}
</script>

<style lang="scss" scoped>
    .c-dragger {
        width: 28px;
        height: 28px;
        position: absolute;
        top: 0px;
        display: block;
        background: #f5f5f5;
        border-left: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
        &--right {
            right: 0px;
            border-radius: 0px 6px 0px 4px !important;
        }
        &--left {
            left: 0px;
            border-radius: 6px 0px 4px 0px !important;
        }
    }

    .c-bubble {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 0px;
        display: block;
        background: #faa730 !important;
        &--private {
            background: #dcdcdc !important;
        }
        &--right {
            right: 0px;
            border-radius: 0px 6px 0px 32px !important;
        }
        &--left {
            left: 0px;
            border-radius: 6px 0px 32px 0px !important;
        }
    }
</style>

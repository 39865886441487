import Auth from './Internal/Auth';
import Color from './Internal/Color';
import Organization from './Internal/Organization';
import Stage from './Internal/Stage';
import Role from './Internal/Role';
import Scope from './Internal/Scope';
import SystemRole from './Internal/SystemRole';
import User from './Internal/User';
import Assignee from './Internal/Assignee';
import Project from './Internal/Project';
import ProjectView from './Internal/ProjectView';
import Task from './Internal/Task';
import TimeRecord from './Internal/TimeRecord';
import Milestone from './Internal/Milestone';
import Note from './Internal/Note';
import Customer from './Internal/Customer';
import Partner from './Internal/Partner';
import Contact from './Internal/Contact';
import Stakeholder from './Internal/Stakeholder';
import Competitor from './Internal/Competitor';
import Collaborator from './Internal/Collaborator';
import Comment from './Internal/Comment';
import Usecase from './Internal/Usecase';
import UsecaseUpload from './Internal/UsecaseUpload';
import Testcase from './Internal/Testcase';
import SuccessCriterion from './Internal/SuccessCriterion';
import Issue from './Internal/Issue';
import Link from './Internal/Link';
import Document from './Internal/Document';
import Report from './Internal/Report';
import Insight from './Internal/Insight';
import ReportPreset from './Internal/ReportPreset';
import ReportBuilder from './Internal/ReportBuilder';
import ReportBlock from './Internal/ReportBlock';
import ReportTemplate from './Internal/ReportTemplate';
import ReportTemplateBlock from './Internal/ReportTemplateBlock';
import Integration from './Internal/Integration';
import Applet from './Internal/Applet';
import AppletService from './Internal/AppletService';
import AppletTrigger from './Internal/AppletTrigger';
import AppletAction from './Internal/AppletAction';
import AppletSalesforce from './Internal/AppletSalesforce';
import Installation from './Internal/Installation';
import InstallationAccount from './Internal/InstallationAccount';
import Workflow from './Internal/Workflow';
import WorkflowMeta from './Internal/WorkflowMeta';
import WorkflowCondition from './Internal/WorkflowCondition';
import WorkflowMapping from './Internal/WorkflowMapping';
import WorkflowLog from './Internal/WorkflowLog';
import WorkflowSetting from "./Internal/WorkflowSetting";
import InstallationSetting from './Internal/InstallationSetting';
import Interface from './Internal/Interface';
import DownloadRequest from './Internal/DownloadRequest';
import Country from './Internal/Country';
import Currency from './Internal/Currency';
import Timezone from './Internal/Timezone';
import TemplateGroup from './Internal/TemplateGroup';
import TemplateProject from './Internal/TemplateProject';
import TemplateMaster from './Internal/TemplateMaster';
import TemplateUsecase from './Internal/TemplateUsecase';
import TemplateTestcase from './Internal/TemplateTestcase';
import TemplateSuccessCriterion from './Internal/TemplateSuccessCriterion';
import TemplateTask from './Internal/TemplateTask';
import TemplateMilestone from './Internal/TemplateMilestone';
import TemplateNote from './Internal/TemplateNote';
import TemplateLink from './Internal/TemplateLink';
import TemplateDocument from './Internal/TemplateDocument';
import Meta from './Internal/Meta';
import Tag from './Internal/Tag';
import CustomField from './Internal/CustomField';
import CustomFieldValue from './Internal/CustomFieldValue';
import Territory from './Internal/Territory';
import Preference from './Internal/Preference';
import BusinessDriver from './Internal/BusinessDriver';
import Workspace from './Internal/Workspace';
import WorkspaceMember from './Internal/WorkspaceMember';
import Inventory from './Internal/Inventory';
import InventoryBooking from './Internal/InventoryBooking';
import Booking from './Internal/Booking';
import InventoryUpload from './Internal/InventoryUpload';
import InventoryTag from './Internal/InventoryTag';
import Release from './Internal/Release';
import Association from './Internal/Association';
import StatusUpdate from './Internal/StatusUpdate'
import Token from './Internal/Token'
import SSO from './Internal/SSO'
import APIDocumentation from './Internal/APIDocumentation'
import Slack from './Internal/Slack'
import MicrosoftTeam from './Internal/MicrosoftTeam'
import TagSection from './Internal/TagSection'
import Ticket from './Internal/Ticket'
import ResourcePlanning from './Internal/ResourcePlanning'
import ProjectTemplateImport from './Internal/ProjectTemplateImport'
import MetaResultReason from './Internal/MetaResultReason'
import Board from './Internal/Board'
import BoardEntity from './Internal/BoardEntity'
import Tile from './Internal/Tile'
import TileOption from './Internal/TileOption'
import TileFilter from './Internal/TileFilter'
import NotificationPreference from './Internal/NotificationPreference'
import FiscalTimeline from './Internal/FiscalTimeline'
import Visibility from './Internal/Visibility'
import MicroServiceWorkflowMapping from './Internal/MicroServiceWorkflowMapping'
import MicroServiceWorkflowLog from './Internal/MicroServiceWorkflowLog'
import MicroServiceWorkflow from './Internal/MicroServiceWorkflow'
import MicroServiceLogEntry from './Internal/MicroServiceLogEntry'
import TaskUpload from './Internal/TaskUpload'
import SubscriptionPlan from './Internal/SubscriptionPlan'
import AutomationWorkflow from './Internal/AutomationWorkflow'
import AutomationTrigger from './Internal/AutomationTrigger'
import AutomationCondition from './Internal/AutomationCondition'
import AutomationConditionEntity from './Internal/AutomationConditionEntity'
import AutomationValidationEntity from './Internal/AutomationValidationEntity'
import AutomationValidation from './Internal/AutomationValidation'
import AutomationAction from './Internal/AutomationAction'
import AutomationActionEntity from './Internal/AutomationActionEntity'
import AutomationTemplate from './Internal/AutomationTemplate'
import AutomationRevisionHistory from './Internal/AutomationRevisionHistory'
import AutomationLog from './Internal/AutomationLog'
import LayoutDesigner from './Internal/LayoutDesigner'
import ConfidenceScore from './Internal/ConfidenceScore'
import ConfidenceScoreValues from './Internal/ConfidenceScoreValues'
import Gong from './Internal/Gong'
import Meeting from './Internal/Meeting'
import ProjectActivities from './Internal/ProjectActivities'
import Attachment from './Internal/Attachment'
import Notifications from './Internal/Notifications'

export default {
    Notifications,
    Attachment,
    ProjectActivities,
    Meeting,
    Gong,
    ConfidenceScoreValues,
    ConfidenceScore,
    LayoutDesigner,
    SubscriptionPlan,
    TaskUpload,
    FiscalTimeline,
    TileFilter,
    TileOption,
    Tile,
    BoardEntity,
    Board,
    Auth,
    Color,
    Organization,
    Stage,
    Role,
    Scope,
    SystemRole,
    User,
    Assignee,
    Project,
    ProjectView,
    Task,
    TimeRecord,
    Milestone,
    Note,
    Customer,
    Partner,
    Contact,
    Stakeholder,
    Competitor,
    Collaborator,
    Comment,
    Usecase,
    UsecaseUpload,
    Testcase,
    SuccessCriterion,
    Issue,
    Link,
    Document,
    Report,
    Insight,
    ReportPreset,
    ReportBuilder,
    ReportBlock,
    ReportTemplate,
    ReportTemplateBlock,
    Integration,
    Applet,
    AppletService,
    AppletTrigger,
    AppletAction,
    AppletSalesforce,
    Installation,
    InstallationSetting,
    InstallationAccount,
    Workflow,
    WorkflowMeta,
    WorkflowCondition,
    WorkflowMapping,
    WorkflowLog,
    WorkflowSetting,
    Country,
    Currency,
    Timezone,
    TemplateGroup,
    TemplateMaster,
    TemplateProject,
    TemplateUsecase,
    TemplateTestcase,
    TemplateSuccessCriterion,
    TemplateTask,
    TemplateMilestone,
    TemplateNote,
    TemplateLink,
    TemplateDocument,
    Meta,
    MetaResultReason,
    Tag,
    CustomField,
    CustomFieldValue,
    Territory,
    Preference,
    Interface,
    DownloadRequest,
    BusinessDriver,
    Workspace,
    WorkspaceMember,
    Inventory,
    InventoryBooking,
    Booking,
    InventoryUpload,
    InventoryTag,
    Release,
    Association,
    StatusUpdate,
    Token,
    SSO,
    APIDocumentation,
    Slack,
    MicrosoftTeam,
    TagSection,
    Ticket,
    ResourcePlanning,
    ProjectTemplateImport,
    NotificationPreference,
    Visibility,
    MicroServiceWorkflowMapping,
    MicroServiceWorkflowLog,
    MicroServiceWorkflow,
    MicroServiceLogEntry,
    AutomationWorkflow,
    AutomationTrigger,
    AutomationConditionEntity,
    AutomationCondition,
    AutomationValidation,
    AutomationValidationEntity,
    AutomationAction,
    AutomationActionEntity,
    AutomationTemplate,
    AutomationRevisionHistory,
    AutomationLog,
}

const api_endpoint = '/automation-workflows';
const api_hook_endpoint = '/automation/pre-hook';

export default {
    namespaced: true,

    state: {
        list: [],
        item: {},
        response: {},
        hook_response: {},
        meta: {},
        loader: false,
        defaults: {
            list: [],
            item: {},
            response: {},
            hook_response: {},
            meta: {},
            loader: false,
        },
    },

    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        ADD_ITEM(state, item) {
            state.list.push(item);
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        UPDATE_ITEM(state, item) {
            state.item = item
        },
        UPDATE_LIST_ITEM (state, payload) {
            const index = state.list.findIndex(item => item.id === payload.id)
            if (index !== -1) Object.assign(state.list[index], payload)
        },
        CLEAR_ITEM(state, item) {
            state.item = item
        },
        REMOVE_LIST_ITEM (state, payload) {
            const index = state.list.findIndex(item => item.id === payload.id)
            if (index !== -1) state.list.splice(index, 1)
        },
        REPLACE_ITEM(state, item) {
            state.item = item
        },
        CLEAR_ITEM(state, item) {
            state.item = item
        },
        THROW_RESPONSE (state, payload) {
            state.response = payload
        },
        HOOK_THROW_RESPONSE (state, payload) {
            state.hook_response = payload
        },
        UPDATE_SECTION_FIELD(state, { id, is_active, field }) {
            const index = _.findIndex(state.list, { id });
            if (index !== -1) state.list[index][field] = is_active
        },
        REFRESH_LIST(state) {
            let list_clone = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_clone;
        },
        HOOK_LOADER_UPDATE(state, status) {
            state.loader = status;
        },
    },

    actions: {
        async index ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                const { data: { data, meta }, } = await axios.get(api_endpoint, { params: payload })
                commit('UPDATE_LIST', data)
                commit('UPDATE_META', meta)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async store ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.post(api_endpoint, payload)
                commit('UPDATE_ITEM', payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async hook ({ dispatch }, payload) {
            dispatch('hook_clear')
            dispatch('hook_loader_start')
            try {
                const { data: { logs_count } } = await axios.post(api_hook_endpoint, payload)
                dispatch('hook_throw_success', logs_count)
            } catch (error) {
                dispatch('hook_throw_error', error)
            }
        },

        async clone_store ({ dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.post(`${api_endpoint}/clone`, payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async template_store ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.post(`${api_endpoint}/import`, payload)
                commit('UPDATE_ITEM', payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async show ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                const { data: { data } } = await axios.get(`${api_endpoint}/${payload.id}`, { params: payload.params })
                if (payload && payload.mode === 'add') {
                    commit('ADD_ITEM', data)
                }
                commit('UPDATE_ITEM', data)
                commit('UPDATE_LIST_ITEM', data)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },
        
        async update ({ dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.patch(`${api_endpoint}/${payload.id}`, payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async toggle_state ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.patch(`${api_endpoint}/${payload.id}/states/is-active`, payload)
                commit('UPDATE_SECTION_FIELD', { id: payload.id, is_active: payload.state, field: 'is_active' })
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async reorder ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            commit('UPDATE_LIST', payload)
            try {
                await axios.patch(api_endpoint + '/reorder', { data: payload })
                commit('REFRESH_LIST')
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async destroy ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.delete(`${api_endpoint}/${payload.id}`)
                commit('REMOVE_LIST_ITEM', payload);
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        hook_loader_start({ commit }) {
            commit('HOOK_LOADER_UPDATE', true)
        },
        
        hook_loader_stop({ commit }) {
            commit('HOOK_LOADER_UPDATE', false)
        },

        async update_item ({ commit }, payload) {
            commit('UPDATE_ITEM', payload)
        },

        async replace_item ({ commit }, payload) {
            commit('REPLACE_ITEM', payload)
        },

        async throw_success ({ commit }) {
            commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            this.dispatch('Interface/loader_stop')
        },

        async throw_error ({ commit }, error) {
            this.dispatch('Interface/loader_stop')
            commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
        },

        async hook_throw_success ({ dispatch, commit }, logs_count) {
            dispatch('hook_loader_stop')
            commit('HOOK_THROW_RESPONSE', { text: 'Done', status: 'success', count: logs_count });
        },

        async hook_throw_error ({ dispatch, commit }, error) {
            dispatch('hook_loader_stop')
            const errorItem = {}
            errorItem.error = 'error'
            errorItem.stage_from = error.response.data.data.stage_from
            errorItem.stage_to = error.response.data.data.stage_to
            errorItem.source = error.response.data.data.source
            errorItem.source = error.response.data.data.source
            errorItem.data = error.response.data.data.logs
            errorItem.current_logs = error.response.data.data.current_logs
            errorItem.trigger = error.response.data.data.trigger
            commit('HOOK_THROW_RESPONSE', errorItem);
        },

        async loader_start () {
            this.dispatch('Interface/loader_start')
        },

        async clear_list ({ commit }) {
            commit('UPDATE_LIST', [])
        },

        async clear_item ({ commit }) {
            commit('CLEAR_ITEM', {})
            commit('THROW_RESPONSE', [])
        },

        async clear_response ({ commit }) {
            commit('THROW_RESPONSE', [])
        },

        async hook_clear ({ commit }) {
            commit('HOOK_THROW_RESPONSE', {})
        }
    },
}

<template>
    <a-card class="mx-auto pa-0" max-width="700">
        <a-card-title class="d-block pa-0 grey lighten-4">
            <div class="u-flex px-6 pt-5 pb-3">
                <a-avatar size="48" color="primary"><a-icon color="white" size="20">hourglass_top</a-icon></a-avatar>
                <div class="ml-2">
                    <h2 class="md-heading-6 grey--text text--darken-2 font-weight-medium">Time Tracking</h2>
                    <p class="md-caption grey--text text--darken-2 mb-0 mt-n2"><span class="md-caption text-uppercase font-weight-medium">{{ model.toUpperCase() }}: </span>{{ title | truncateText(80) }}</p>
                </div>
                <!-- <div class="md-body-2 grey--text text--darken-3 font-weight-medium" v-if="weeks > 0 || days > 0 || hours > 0 || mins > 0">
                    <span class="mr-1" v-if="weeks > 0">{{ weeks }}w</span>
                    <span class="mr-1" v-if="days > 0">{{ days }}d</span>
                    <span class="mr-1" v-if="hours > 0">{{ hours }}h</span>
                    <span class="mr-1" v-if="mins > 0">{{ mins }}m</span>
                </div> -->
                <a-spacer></a-spacer>
                <a-btn small icon @click="$emit('close', { resetFields: methodResetFields })">
                    <a-icon size="16" color="grey darken-1">clear</a-icon>
                </a-btn>
            </div>
            <a-divider class="grey lighten-3"></a-divider>
            <div class="px-6" :class="[mode === 'edit' ? 'pb-4 pt-4' : 'pb-5 pt-3']" v-if="canUpdate">
                <div class="u-flex align-center">
                    <a-sheet class="mr-4 transparent" width="400">
                        <div class="md-caption grey--text text--darken-2 font-weight-medium text-uppercase" style="letter-spacing: 0.8px">Start Date</div>
                        <!-- <a-menu v-model="log_date_picker" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <a-text-field
                                    :value="methodFormatDate(formData.start_date)"
                                    placeholder="May 21, 2021"
                                    background-color="white"
                                    class="u-rounded-corners md-body-2 u-border"
                                    v-bind="attrs"
                                    v-on="on"
                                    readonly flat hide-details solo
                                ></a-text-field>
                            </template>
                            <a-date-picker color="primary" v-model="formData.start_date" no-title @input="log_date_picker = false"></a-date-picker>
                        </a-menu> -->
                        <vc-date-picker v-model="formData.start_date" @input="log_date_picker = false" class="d-block u-wfull u-relative" :model-config="modelConfig">
                            <template v-slot="{ togglePopover }">
                                <a-text-field
                                    :value="methodFormatDate(formData.start_date)"
                                    placeholder="May 21, 2021"
                                    background-color="white"
                                    class="u-rounded-corners md-body-2 u-border"
                                    @click="togglePopover({ placement: 'bottom-start' })"
                                    readonly flat hide-details solo
                                ></a-text-field>                     
                            </template>
                        </vc-date-picker>                        
                        <span class="md-caption red--text text--darken-2 d-block" v-if="time_record_response.server && time_record_response.server.errors && time_record_response.server.errors.start_date">
                            <a-icon size="15" color="red darken-2">info</a-icon>
                            {{ time_record_response.server.errors.start_date[0] }}
                        </span>
                        <span class="md-caption grey--text text--darken-2 u-flex align-center" v-else>
                            <a-icon size="15" class="mr-1">info</a-icon>
                            <span>Select start date</span>
                        </span>
                    </a-sheet>
                    <a-sheet class="transparent u-wfull">
                        <div class="md-caption grey--text text--darken-2 font-weight-medium text-uppercase" style="letter-spacing: 0.8px">Duration</div>
                        <a-text-field
                            v-model="loggedTime"
                            @input="methodCalculate"
                            placeholder="Ex: 4w 5d 10h 20m"
                            background-color="white"
                            class="u-rounded-corners md-body-2 u-border"
                            flat hide-details solo
                        ></a-text-field>
                        <span class="md-caption red--text text--darken-2 u-flex align-center" v-if="(time_record_response.server && time_record_response.server.errors && time_record_response.server.errors.duration_text) || (validationError && showValidationError)">
                            <a-icon size="15" color="red darken-2" class="mr-1">info</a-icon>
                            {{ validationError ? validationError : time_record_response.server.errors.duration_text[0] }}
                            <span class="grey--text text--darken-2 ml-1" v-if="validationError">(Ex: 4w 5d 6h 20m)</span>
                        </span>
                        <span class="md-caption grey--text text--darken-2 u-flex align-center" v-else>
                            <a-icon size="15" class="mr-1">info</a-icon>
                            <span>Duration format 4w 5d 6h 20m</span>
                        </span>
                    </a-sheet>
                </div>
                <div class="mt-2 u-relative">
                    <a-textarea 
                        v-model="formData.description" 
                        :rows="mode === 'edit' ? 5 : 3"
                        row-height="24" 
                        background-color="white"
                        class="u-border md-body-2" 
                        placeholder="Enter description (optional)"
                        hide-details solo flat no-resize 
                    ></a-textarea>
                    <span class="u-absolute md-caption" style="bottom: -32px;" :style="[mode === 'edit' ? { left: '0px' } : { right: '0px' }]">
                        <span class="md-caption red--text text--darken-2 d-block" v-if="time_record_response.server && time_record_response.server.errors && time_record_response.server.errors.description">
                            <a-icon size="15" color="red darken-2">info</a-icon>
                            {{ time_record_response.server.errors.description[0] }}
                        </span>
                        <span v-else class="md-caption grey--text text--darken-2">
                            <a-icon size="15" class="mr-1">info</a-icon>
                            <span>Max 4000 chars.</span>
                        </span>
                    </span>
                </div>
                <div v-if="mode === 'edit'" class="u-flex align-center md-body-2 grey--text text--darken-1 mt-9 pb-1">
                    <a-btn small text @click="methodAddRecord" :loading="localLoading" :disabled="localLoading" dark class="primary">Update Log</a-btn>
                    <a-btn small text @click="methodResetFields" class="grey lighten-3 ml-2">Cancel</a-btn>
                    <a-spacer></a-spacer>
                    <span>Press <span class="grey darken-1 md-caption white--text u-rounded-corners" style="padding: 2px 6px 2px 6px; font-family: monospace !important">Esc</span> key to cancel edit</span>
                </div>
                <div v-else class="mt-4">
                    <a-btn small text @click="methodAddRecord" :loading="localLoading" :disabled="localLoading" dark class="primary">Add Log</a-btn>
                    <a-btn small text @click="methodResetFields" class="grey lighten-3 ml-2">Clear</a-btn>
                </div>
            </div>
        </a-card-title>
        <template v-if="mode !== 'edit'">
            <a-divider key="divider" class="u-divider"></a-divider>
            <div key="title" v-if="records && records.length" class="u-flex align-center py-3 pt-3 px-6 white u-wfull" style="z-index: 1; border-bottom: 1px solid #f5f5f5 !important;">
                <div class="u-flex align-center">
                    <a-icon size="16" color="grey darken-1">event_available</a-icon>
                    <span class="md-caption ml-2 grey--text text--darken-1 font-weight-medium text-uppercase" style="letter-spacing: 0.8px">Time Log History</span>
                </div>
                <a-spacer></a-spacer>
                <a-progress-circular :width="2" :size="16" color="green darken-1" v-if="destroyLoading" indeterminate></a-progress-circular>
                <div v-if="status && status.status === 'Completed'" class="green darken-1 px-3 u-rounded-corners ml-2 white--text md-body-2 text-right" style="padding-top: 2px; padding-bottom: 2px; letter-spacing: 0.8px">{{ status.status }}</div>
            </div>
            <!-- <a-divider key="divider" class="u-divider"></a-divider> -->
            <a-card-text key="list" class="pa-6 pb-4 pt-4 c-scrollbar" style="max-height: 640px; overflow-y: auto;  overflow-x: hidden">
                <div>
                    <!-- <div class="u-flex align-center mb-4 py-2 pt-3 u-sticky white u-wfull" style="top: 0; left: 0; z-index: 1; border-bottom: 1px solid #f5f5f5 !important">
                        <div class="u-flex align-center">
                            <a-icon size="16" color="grey darken-1">event_available</a-icon>
                            <span class="md-caption ml-2 grey--text text--darken-1 font-weight-medium text-uppercase" style="letter-spacing: 0.8px">Time Log History</span>
                        </div>
                        <a-spacer></a-spacer>
                        <a-progress-circular :width="2" :size="16" color="green darken-1" v-if="loading" indeterminate></a-progress-circular>
                        <div v-if="status" class="green darken-1 px-3 u-rounded-corners ml-2 white--text md-body-2 text-right" style="padding-top: 2px; padding-bottom: 2px; letter-spacing: 0.8px">{{ status }}</div>
                    </div> -->
                    <template v-if="!indexLoading">
                        <template v-if="records && records.length">
                            <table class="c-table u-wfull">
                                <tr class="c-time-est mb-2" v-for="(record, index) in records" :key="record.id">
                                    <td :class="{ 'pb-4': index !== (records.length - 1) }" style="vertical-align: top; width: 36px;">
                                        <g-avatar :item="record.created_by ? record.created_by : {}" :right-space="false"></g-avatar>
                                    </td>
                                    <td :class="{ 'pb-4': index !== (records.length - 1) }" class="pl-3" style="vertical-align: top;">
                                        <div>
                                            <div class="u-flex align-center">
                                                <div class="md-body-2 font-weight-medium grey--text text--darken-2" v-if="record && record.created_by">{{ record.created_by.name }}</div>
                                                <div class="md-caption font-weight-medium ml-1 grey--text text--darken-1">on {{ methodFormatDate(record.start_date) }}</div>
                                            </div>
                                            <div v-if="record.description" class="md-caption grey--text text--darken-1 u-flex flex-wrap" style="max-width: 460px;">
                                                <p class="mb-0" style="max-width: 460px;">
                                                    <template v-if="methodIsViewedMore(record.id)">{{ record.description }}</template>
                                                    <template v-if="!methodIsViewedMore(record.id)">{{ record.description | truncateText(145) }}</template>
                                                    <span class="grey--text text--darken-3 md-caption font-weight-medium u-cursor-pointer ml-2" v-if="record.description && record.description.length > 145" style="letter-spacing: 0.6px" @click="methodAddToViewMore(record.id)">{{ methodIsViewedMore(record.id) ? 'Show Less' : 'View More' }}</span>
                                                </p>
                                            </div>
                                            <div v-else class="md-caption grey--text text--lighten-1 font-italic">No Description</div>
                                        </div>
                                    </td>
                                    <td :class="{ 'pb-4': index !== (records.length - 1) }" style="vertical-align: middle;" class="text-right">
                                        <div class="u-relative">
                                            <div class="u-flex justify-end">
                                                <span class="mr-1 md-body-2 font-weight-medium grey--text text--darken-2">{{ record.duration_text ? record.duration_text : methodTimeToText(record.duration_minutes, 'text') }}</span>
                                            </div>
                                            <div class="c-time-est__action u-flex align-center" v-if="canUpdate">
                                                <div @click="methodEditRecord(record)" v-ripple class="c-time-est__action-item u-cursor-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-pencil grey--text text--darken-1" viewBox="0 0 16 16">
                                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                                                    </svg>
                                                </div>
                                                <a-divider vertical></a-divider>
                                                <div v-if="removeRecordId !== record.id" @click="removeRecordId = record.id" v-ripple class="c-time-est__action-item u-cursor-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-trash grey--text text--darken-1" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                    </svg>
                                                </div>
                                                <a-tooltip bottom v-else>
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on" @click="!destroyLoading ? methodDeleteRecord(record.id) : {}" v-ripple class="c-time-est__action-item u-cursor-pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-trash red--text text--darken-2" viewBox="0 0 16 16">
                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                            </svg>
                                                        </div>
                                                    </template>
                                                    <span>Confirm ?</span>
                                                </a-tooltip>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </template>
                        <template v-if="records && !records.length">
                            <div class="text-center py-2">
                                <span class="md-caption text-uppercase font-weight-medium grey--text text--darken-3" style="letter-spacing: 0.8px;">No logs found for this {{ model }}</span>
                                <span class="md-body-2 d-block grey--text text--darken-1" style="letter-spacing: 0.1px;">Start time tracking by adding some entries.</span>
                            </div>
                        </template>
                    </template>
                    <template v-if="indexLoading">
                        <div class="text-center py-2">
                            <a-progress-circular :width="2" :size="20" color="green darken-1" indeterminate></a-progress-circular>
                        </div>
                    </template>
                </div>
            </a-card-text>
            <slot name="record-progress"></slot>
        </template>
    </a-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { calculateByMins, calculateByText } from '@/helpers/helper-time-tracker.js'

export default {
    props: {
        title: {
            type: String
        },
        model: {
            type: String,
            default: 'task'
        },
        status: {
            type: Object,
            default: null
        },
        totalDuration: {
            type: Number,
        },
        currentDuration: {
            type: Number,
        },
        item: {
            type: Object,
        },
        records: {
            type: Array,
        },
        records: {
            type: Array,
        },
        canUpdate: {
            type: Boolean,
        },
        loading: {
            type: Boolean,
        },
        indexLoading: {
            type: Boolean,
            default: false
        },
    },

    data: () => ({
        formData: {
            start_date: null,
            duration_text: null,
            duration_minutes: null,
            description: null,
        },
        mode: null,
        editedRecordId: null,
        removeRecordId: null,
        log_date_picker: false,
        loggedTime: null,
        weeks: 0,
        days: 0,
        hours: 0,
        mins: 0,
        validationError: null,
        validationTimeout: null,
        showValidationError: false,
        localLoading: false,
        viewMoreRecords: [],
        destroyLoading: false,
        modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
    }),

    mounted () {
        this.methodResetFields()
        this.methodSetTodayDate()
    },

    watch: {
        loggedTime () {
            this.showValidationError = false
        },
        time_record_response (val) {
            if (val && val.status === 'error') this.methodSetLoading(false)
        }
    },

    computed: {
        ...mapState('TimeRecord', {
            'time_record_response': 'response'
        }),
    },

    methods: {
        methodCalculate (evt) {
            this.time_record_clear_response()
             clearTimeout(this.validationTimeout)
            // this.methodValidateTime(this.loggedTime)
            this.methodGetTimes()
        },

        // methodValidateTime (time) {
        //     this.validationTimeout = setTimeout(() => {
        //         let value = time.replace(/\s/g, '')
        //         value = value.replace(/(w)/g, '$1 ')
        //         value = value.replace(/(d)/g, '$1 ')
        //         value = value.replace(/(h)/g, '$1 ')
        //         value = value.replace(/(m)/g, '$1 ')
        //         this.loggedTime = value.trim()
        //         this.methodGetTimes()
        //     }, 1000);
        // },

        methodGetTimes () {
            const { error, durationMins } = calculateByText(this.loggedTime)
            this.validationError = error
            if (error) return

            this.formData.duration_minutes = durationMins
            // this.weeks = w
            // this.days = d
            // this.hours = h
            // this.mins = m
        },

        methodTimeToText (value, type) {
            const { durationText, durationMins } = calculateByMins(value)
            return type === 'text' ? durationText : durationMins
        },

        methodTextToTime (value, type) {
            const { durationText, durationMins } = calculateByText(value)
            return type === 'text' ? durationText : durationMins
        },

        methodAddRecord () {
            this.methodSetLoading(true)
            if (this.validationError) {
                this.methodSetLoading(false)
                return this.showValidationError = true
            }
            this.methodResetValidation()
            this.formData.duration_text = this.methodTextToTime(this.loggedTime, 'text')
            // this.formData.start_date = this.methodFormatDate(this.formData.start_date)
            this.$emit('add-entry', { formData: this.formData, resetFields: this.methodResetFields, mode: this.mode, record_id: this.editedRecordId })
        },

        methodEditRecord (record) {
            this.time_record_clear_response()
            this.mode = 'edit'
            this.editedRecordId = record.id
            this.methodSetFormData(record)
            const { durationMins } = calculateByText(record.duration_text)
            this.formData.duration_minutes = durationMins
            window.addEventListener('keydown', this.methodCloseEdit)
        },

        methodSetFormData ({ start_date, duration_text, description, duration_minutes }) {
            this.formData.start_date = start_date
            this.formData.description = description
            this.loggedTime = duration_text ? duration_text : this.methodTimeToText(duration_minutes, 'text')
        },

        methodDeleteRecord (id) {
            this.destroyLoading = true
            this.$emit('delete-entry', { id, resetFields: this.methodResetFields })
        },

        methodResetValidation () {
            this.showValidationError = false
            this.validationError = null
        },

        methodSetLoading (value) {
            this.localLoading = value
        },

        methodAddToViewMore (id) {
            const hasId = this.viewMoreRecords.indexOf(id)
            if (hasId !== -1) return this.viewMoreRecords.splice(hasId, 1)
            this.viewMoreRecords.push(id)
        },

        methodIsViewedMore (id) {
            return this.viewMoreRecords.includes(id)
        },

        methodCloseEdit (evt) {
            if (evt.keyCode === 27) {
                this.methodResetFields()
                window.removeEventListener('keydown', this.methodCloseEdit)
            }
        },

        methodResetFields () {
            this.time_record_clear_response()
            this.formData = { start_date: null, duration_text: null, duration_minutes: null, description: null }
            this.loggedTime = null
            this.mode = null
            this.editedRecordId = null
            this.removeRecordId = null
            this.validationError = null
            this.destroyLoading = false
            this.methodSetLoading(false)
            this.methodSetTodayDate()
            // this.weeks = 0
            // this.days = 0
            // this.hours = 0
            // this.mins = 0
        },

        methodSetTodayDate () {
            this.formData.start_date = this.methodFormatDate(new Date(), 'date')
        },

        methodFormatDate (date, type = null) {
            if (type) return moment(new Date()).format('YYYY-MM-DD')
            return date ? moment(date).format('MMM D, YYYY') : null
        },

        ...mapActions('TimeRecord', {
            'time_record_clear_response': 'clear_response'
        })
    }
}
</script>

<style lang="scss" scoped>
.c-time-est {
    &__action {
        color: #e0e0e0 !important;
    }
    &:hover {
        .c-time-est__action {
            color: #888 !important;
        }
    }
}
.c-time-est {
    position: relative;
    overflow-x: hidden;
    &__action {
        visibility: hidden;
        position: absolute;
        right: -1000px;
        top: 0px;
        background: #fff;
        border-radius: 0px 0px 0px 6px;
        box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 16%);
        // transition: 0.1s ease-in-out;
    }
    &__action-item {
        display: flex;
        align-items: center;
        padding: 5px 10px;
    }
    &:hover {
        .c-time-est__action {
            visibility: visible;
            right: 4px;
            // transition: 0.1s ease-in-out;
        }
    }
}
.c-table {
    display: table;
    border-collapse: collapse;
    width: 100%;
}
</style>

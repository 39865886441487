<template>
    <GUsersAutocomplete v-bind="$attrs" v-on="$listeners">
        <template #item="{ props }">
            <slot name="item" :props="props">
                <div class="u-flex-center-y">
                    <g-avatar :item="props.item"></g-avatar>
                    <g-profile-info :item="props.item" set-max-width></g-profile-info>
                </div>
            </slot>
        </template>
        <template #selection="{ props }">
            <slot name="selection" :props="props">
                <a-chip pill close @click:close="props.removeChip(props.item)">
                    <h5 class="md-subtitle-2 text-truncate" style="max-width: 300px" v-if="props.item && props.item.user" :title="props.item.user.name">{{ props.item.user.name }}</h5>
                    <h5 class="md-subtitle-2 text-truncate" style="max-width: 300px" v-else :title="props.item ? props.item.name : ''">{{ props.item.name }}</h5>
                </a-chip>
            </slot>
        </template>
        <template #clearIcon="{ clear }">
            <slot name="clearIcon" :clear="clear">
                <div class="c-clearable" @click="clear()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>
            </slot>
        </template>
        <template #noData>
            <slot name="noData">
                <div class="md-body-1 grey--text text--darken-1 px-2">{{ $attrs.noDataText ? $attrs.noDataText : 'No Data Found' }}</div>
            </slot>
        </template>
        <template #loader>
            <slot name="loader">
                <div class="md-body-1 grey--text text--darken-3 font-weight-medium px-2">Loading...</div>
            </slot>
        </template>
        <template #emptyData>
            <slot name="emptyData">
                <div class="md-body-1 grey--text text--darken-1 font-weight-medium px-2">{{ $attrs.noDataText ? $attrs.noDataText : 'No Data Found' }}</div>
            </slot>
        </template>
    </GUsersAutocomplete>
</template>

<script>
export default { inheritAttrs: false }
</script>

<template>
    <a-sheet class="c-usecase-sidebar u-hfull u-flex flex-column justify-space-between">
        <template v-if="editItem">
            <a-sheet height="calc(100% - 180px)" class="u-overflow-hidden u-overflow-y">
                <a-sheet class="grey lighten-4 px-6 py-3 u-flex align-center mb-6">
                    <a-sheet class="transparent u-flex align-center">
                        <slot name="status"></slot>
                        <loader-autosave :status="loaders.content_autosave_response" class="ml-3"></loader-autosave>
                    </a-sheet>
                    <a-spacer></a-spacer>
                    <div class="u-flex align-center">
                        <!-- <g-delete-confirm-simple v-if="showView" :content_id="editItem.id" @destroy="local_testcase_destroy"></g-delete-confirm-simple> -->
                        <g-delete-confirm-simple v-if="showView && canDelete" :content-id="editItem.id" @destroy="evt => $emit('destroy', evt)"></g-delete-confirm-simple>
                        <a-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <!-- <span v-on="on" class="u-rounded-corners-lg u-cursor-pointer d-inline-flex align-center justify-center ml-2" v-ripple style="width: 24px;height: 24px;" @click="dataPaneStatus === 'error' ? '' : $emit('close', true)">
                                    <a-icon size="18" color="grey darken-1">login</a-icon>
                                </span> -->
                                <span v-on="on" class="u-rounded-corners-lg u-cursor-pointer d-inline-flex align-center justify-center ml-2" v-ripple style="width: 24px;height: 24px;" @click="$emit('close', true)">
                                    <a-icon size="18" color="grey darken-1">login</a-icon>
                                </span>
                            </template>
                            <span class="white--text d-block text-center" v-if="dataPaneStatus === 'error'" style="width: 200px;">There are some errors, are you sure to close.?</span>
                            <span v-else class="grey--text text--lighten-1"><span class="font-weight-bold white--text" style="font-family: monospace !important">Esc</span> to close</span>
                        </a-tooltip>
                    </div>
                </a-sheet>
                <div class="u-relative">
                    <a-sheet max-height="120" min-height="34" class="py-1 mx-6 mt-3 u-border u-rounded-corners u-overflow-y">
                        <!-- <a-textarea v-model="editItem.title" rows="1" @input="local_testcase_update" solo flat auto-grow no-resize hide-details></a-textarea> -->
                        <a-textarea v-model="editItem.title" rows="1" @input="methodUpdate" :disabled="!canUpdate" solo flat auto-grow no-resize hide-details></a-textarea>
                    </a-sheet>
                    <span class="u-absolute md-caption" style="bottom: -24px; right: 24px" v-if="canUpdate">
                        <span class="md-caption red--text text--darken-2 d-block" v-if="$response(error('update'), 'title')">
                            <a-icon size="15" color="red darken-2">info</a-icon>
                            {{ $response(error('update'), 'title') }}
                        </span>
                        <span v-else class="md-caption grey--text text--darken-2">
                            <a-icon size="15" class="mr-1">info</a-icon>
                            <span>Required Field</span>
                        </span>
                    </span>
                </div>
                <a-sheet class="my-5 px-6" v-if="!showDates">
                    <slot name="assignees"></slot>
                </a-sheet>
                <div class="px-6">
                    <div class="u-flex align-center mb-3" :class="{ 'mt-10': showDates }">
                        <h3 class="md-caption grey--text text--darken-1 text-uppercase font-weight-bold">Description</h3>
                        <a-spacer></a-spacer>
                        <p class="md-caption grey--text mb-0 d-flex align-center" v-if="canUpdate">
                            <span v-if="$response(error('update'), 'description')">
                                <a-icon size="16" class="mr-1 u-icon-nudge" style="top: -1px;" color="red darken-2">info</a-icon>
                                <span class="md-caption red--text text--darken-2">{{ $response(error('update'), 'description') }}</span>
                            </span>
                            <template v-else>
                                <em class="mr-2">Select text for formating options</em>
                                <a-icon size="14" color="grey" class="ml-1">format_bold</a-icon>
                                <a-icon size="14" color="grey" class="ml-1">format_italic</a-icon>
                            </template>
                        </p>
                    </div>
                    <a-sheet class="u-border u-rounded-corners pa-3 u-overflow-y" height="120" max-height="120" style="cursor: text;" @click="methodFocusDesc">
                        <p class="grey--text text--darken-1" v-if="!editItem.description || editItem.description && editItem.description.replace(/<[^>]*>?/gm, '') === null || editItem.description && editItem.description.replace(/<[^>]*>?/gm, '') == ''" @click="methodFocusDesc">Click to edit the description</p>
                        <editor-content v-else :editor="dataEditorDesc"></editor-content>
                        <s-editor-menu :content="dataEditorDesc" :can-update="canUpdate"></s-editor-menu>
                    </a-sheet>
                    <a-sheet class="mt-6 u-flex" v-if="showDates">
                        <slot name="assignees"></slot>
                        <a-spacer></a-spacer>
                        <div>
                            <div class="u-flex align-center">
                                <div class="c-date-picker u-flex align-center u-rounded-corners u-border mr-2 u-relative">
                                    <a-sheet class="grey lighten-4 d-block" max-width="160">
                                        <vc-date-picker v-model="editItem.start_date" :max-date="editItem.due_date" class="d-block" @input="methodDateUpdate" :model-config="modelConfig" :is-required="!!(editItem.start_date)">
                                            <template v-slot:day-popover="{ format }">
                                                <div>{{ format(editItem.start_date, 'MMM D') }}</div>
                                            </template>
                                            <template v-slot="{ togglePopover }">
                                                <a-card flat class="pb-1 u-wfull u-cursor-pointer px-2 grey--text text--darken-2" @click="canUpdate ? togglePopover({ placement: 'auto-start' }) : false" v-if="editItem.start_date">
                                                    <span class="md-caption grey--text text--darken-1">Start Date</span>
                                                    <div class="md-body-2 grey--text text--darken-3">{{ local_format_date(editItem.start_date) }}</div>
                                                </a-card>
                                                <a-card flat class="pb-1 u-wfull u-cursor-pointer px-2 grey--text text--darken-2" @click="canUpdate ? togglePopover({ placement: 'auto-start' }) : false" v-else>
                                                    <span class="md-caption grey--text text--darken-1">Start Date</span>
                                                    <div class="md-body-2 grey--text text--darken-3">No Start Date</div>
                                                </a-card>
                                            </template>
                                        </vc-date-picker>
                                    </a-sheet>
                                    <a-icon v-if="editItem.start_date && canUpdate" @click="methodClearDate('start_date')" class="c-date-picker--clear u-absolute" size="16" style="top: -8px; right: -8px;">cancel</a-icon>
                                </div>
                                <div class="c-date-picker u-flex align-center u-rounded-corners u-border u-relative">
                                    <a-sheet class="grey lighten-4 d-block" max-width="160">
                                        <vc-date-picker v-model="editItem.due_date" :min-date="editItem.start_date" class="d-block" @input="methodDateUpdate" :model-config="modelConfig" :is-required="!!(editItem.due_date)">
                                            <template v-slot:day-popover="{ format }">
                                                <div>{{ format(editItem.due_date, 'MMM D') }}</div>
                                            </template>
                                            <template v-slot="{ togglePopover }">
                                                <a-card flat class="pb-1 u-wfull u-cursor-pointer px-2 grey--text text--darken-2" @click="canUpdate ? togglePopover({ placement: 'auto-start' }) : false" v-if="editItem.due_date">
                                                    <span class="md-caption grey--text text--darken-1">Due Date</span>
                                                    <div class="md-body-2 grey--text text--darken-3">{{ local_format_date(editItem.due_date) }}</div>
                                                </a-card>
                                                <a-card flat class="pb-1 u-wfull u-cursor-pointer px-2 grey--text text--darken-2" @click="canUpdate ? togglePopover({ placement: 'auto-start' }) : false" v-else>
                                                    <span class="md-caption grey--text text--darken-1">Due Date</span>
                                                    <div class="md-body-2 grey--text text--darken-3">No Due Date</div>
                                                </a-card>
                                            </template>
                                        </vc-date-picker>
                                    </a-sheet>
                                    <a-icon v-if="editItem.due_date && canUpdate" @click="methodClearDate('due_date')" class="c-date-picker--clear u-absolute" size="16" style="top: -8px; right: -8px;">cancel</a-icon>
                                </div>
                            </div>
                            <div class="text-right md-caption red--text text--darken-2 mt-2" v-if="$response(error('update'), 'due_date')">
                                <a-icon size="15" color="red darken-2">info</a-icon>
                                {{ $response(error('update'), 'due_date') }}
                            </div>
                        </div>
                    </a-sheet>
                    <div class="mt-5" :class="{ 'mb-8': hideTimeProgress }">
                        <loader-tags v-if="loaders.sidebar_time_est_loading && (editItem.tags && !editItem.tags.length)"></loader-tags>
                        <template v-else>
                            <div class="d-inline-flex align-center">
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a-icon size="16" class="u-icon-nudge" v-on="on">lock</a-icon>
                                    </template>
                                    <span>Only Visible To Team</span>
                                </a-tooltip>
                                <span class="md-caption grey--text text--darken-1 ml-2 d-inline-block text-uppercase font-weight-bold">Tags</span>
                            </div>
                            <div class="u-flex align-center flex-wrap">
                                <template v-if="editItem.tags && editItem.tags.length">
                                    <g-tags v-for="tag in editItem.tags" :text-range="20" :hide-clear-icon="!canUpdate" @destroy="(evt) => local_associate_tag_destroy(tag, editItem)" :tag="tag" :key="tag.id"></g-tags>
                                </template>
                                <a-menu bottom offset-y v-model="dataShowTagInput" v-if="canUpdate" :close-on-content-click="false" ref="refAddTagPopup" transition="slide-y-transition" nudge-bottom="8">
                                    <template v-slot:activator="{ on }">
                                        <g-add-btn v-on="on"></g-add-btn>
                                    </template>
                                    <a-sheet class="pb-1" max-width="240">
                                        <slot name="tag-form" :toggleMenu="methodToggleMenu" :isMenuOpen="dataShowTagInput"></slot>
                                    </a-sheet>
                                </a-menu>
                            </div>
                        </template>
                    </div>
                </div>
                <a-card flat class="mt-8 pb-2" @click="$emit('open-timetracker', editItem)" v-if="!hideTimeProgress">
                    <loader-time-est v-if="loaders.sidebar_time_est_loading && (editItem && !editItem.time_records_sum_duration_minutes)"></loader-time-est>
                    <s-time-progress
                        v-else
                        :limit="480"
                        :sum-mins="editItem.time_records_sum_duration_minutes"
                        :est-mins="editItem.estimated_duration_minutes"
                        :est-text="editItem.estimated_duration_text"
                        :can-update="$can('usecases.update')"
                        rounded
                    ></s-time-progress>
                </a-card>
                <div class="px-6" v-if="loaders.sidebar_comments_loading && (editItem && (!editItem.comments || !editItem.comments.length))">
                    <loader-comments></loader-comments>
                </div>
                <template v-else>
                    <div class="grey lighten-4" :style="[!canComment ? { marginBottom: '64px' } : '']" v-if="editItem && editItem.comments && editItem.comments.length">
                        <a-sheet v-if="editItem.comments.length > dataCommentsLimit" class="transparent px-6 py-3 u-cursor-pointer blue--text text--darken-1 md-subtitle-2 font-weight-regular" @click="methodShowAllComments" height="48">{{ dataShowAllComments ? 'Show lesser comments' : 'Show ' + (editItem.comments.length - dataCommentsLimit) + ' more comment' + (editItem.comments.length - dataCommentsLimit === 1 ? '' : 's') }}</a-sheet>
                        <template v-for="(comment, index) in dataShowAllComments ? editItem.comments : editItem.comments.slice(Math.max(editItem.comments.length - dataCommentsLimit, 0))">
                            <div class="c-comment-item py-2 px-6 u-relative" :class="{ 'grey lighten-5': index % 2 === 0 }" :key="comment.id">
                                <a-sheet v-if="dataEditComment.id === comment.id" class="transparent">
                                    <g-comment-box class="transparent" autofocus inline hide-divider is-edit :error="comment_response" :user="dataEditComment.created_by" :type="commentModuleType" :content="dataEditComment.content" :item="dataEditComment" @store="methodUpdateComment" @clear="methodCancelEdit(comment)"></g-comment-box>
                                </a-sheet>
                                <a-sheet v-else class="transparent" max-width="648">
                                    <div class="u-flex transparent">
                                        <g-avatar :item="comment.created_by" :size="32"></g-avatar>
                                        <div class="u-relative mt-1 u-wfull">
                                            <div class="u-flex align-center">
                                                <span class="md-body-2 grey--text text--darken-2" v-if="comment.created_by">{{ comment.created_by.name }}</span>
                                                <g-moment tag="span" class="md-caption grey--text text--darken-1 ml-2" :value="comment.updated_at ? comment.updated_at : comment.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD [at] hh:mma" convert-local></g-moment>
                                                <a-sheet class="blue--text text--darken-2 md-caption ml-2 transparent" v-if="comment.updated_at && methodGetDateDiff(comment.created_at, comment.updated_at)">
                                                    <a-icon size="5" color="grey lighten-1">fiber_manual_records</a-icon>
                                                    Updated
                                                </a-sheet>
                                                <div class="ml-2 u-flex align-center" v-if="comment.visibility === 'external'">
                                                    <a-icon size="5" color="grey lighten-1">fiber_manual_records</a-icon>
                                                    <a-tooltip right>
                                                        <template v-slot:activator="{ on }">
                                                            <a-sheet v-on="on" class="orange darken-1 transparent ml-1 u-flex align-center justify-center u-rounded-corners-full" width="16" height="16">
                                                                <a-icon size="10" color="white">lock_open</a-icon>
                                                            </a-sheet>
                                                        </template>
                                                        <span>External</span>
                                                    </a-tooltip>
                                                </div>
                                            </div>
                                            <p class="md-body-2 u-wfull mb-0 grey--text text--darken-2" style="white-space: pre-wrap;">{{ comment.content }}</p>
                                            <div class="c-comment-item__toolbar" v-if="canComment && (comment.created_by_id === user_self.id)">
                                                <s-mini-action @edit="methodEditComment(comment)" @delete="methodDeleteComment(comment)"></s-mini-action>
                                            </div>
                                        </div>
                                    </div>
                                </a-sheet>
                            </div>
                        </template>
                    </div>
                </template>
            </a-sheet>
            <slot name="comment-area" v-if="canComment">
                <g-comment-box class="grey lighten-4" sticky :error="dataEditComment.id ? null : comment_response" :type="commentModuleType" :reset-comment="dataItemChange" :item="editItem" @store="methodStoreComment"></g-comment-box>
            </slot>
        </template>
    </a-sheet>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Editor, EditorContent } from 'tiptap'
import { Bold, Italic, OrderedList, BulletList, ListItem, History } from 'tiptap-extensions'
import marked from 'marked'

import { STimeProgress, SEditorMenu, SMiniAction } from '@/config/config-shared-components'

export default {
    props: {
        editItem: {
            type: Object
        },
        hideTimeProgress: {
            type: Boolean,
            default: false
        },
        showDates: {
            type: Boolean,
            default: false
        },
        loaders: {
            type: Object
        },
        commentModuleType: {
            type: String
        },
        showView: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        canDelete: {
            type: Boolean,
            default: true
        },
        canComment: {
            type: Boolean,
            default: true
        },
        error: {
            type: Object | Array | Function,
            default: {}
        },
    },

    components: { EditorContent, SEditorMenu, STimeProgress, SMiniAction },

    data () {
        return {
            dataEditorDesc: null,
            dataShowTagInput: false,
            dataShowAllComments: false,
            dataItemChange: false,
            dataEditComment: {},
            dataRequiredFields: ['title'],
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
            // dataItemBackup: {}
        }
    },

    beforeDestroy () {
        if (this.dataEditorDesc) this.dataEditorDesc.destroy()
    },

    watch: {
        showView (val) {
            if (val) {
                document.addEventListener('keyup', this.methodEscToClosePane)
                // this.methodBackup()
                this.methodSetDescription()
                if (!this.editItem.comments || this.editItem.comments && this.editItem.comments.length <= 3) {
                    this.dataShowAllComments = true
                }
            } else document.removeEventListener('keyup', this.methodEscToClosePane)
        },

        'editItem.id': {
            async handler (val) {
                this.editItem.is_autosave = 0
                this.methodSetDescription()
                // this.methodBackup()
                this.dataItemChange = true
                await this.comment_clear_item()
                this.dataShowAllComments = false
                this.dataItemChange = false
                if (!this.editItem.comments || this.editItem.comments && this.editItem.comments.length <= 3) {
                    this.dataShowAllComments = true
                }
            }
        }
    },

    computed: {
        dataPaneStatus () {
            const { status } = this.error('update')
            return status
        },

        dataCommentsLimit () {
            return this.commentModuleType === 'Testcase' ? (!this.canComment ? 6 : 3) : (!this.canComment ? 8 : 4)
        },

        ...mapState('Comment', {
            comment_item: 'item',
            comment_response: 'response',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Association', {
            association_response: 'response',
        }),
    },

    methods: {
        methodUpdate () {
            this.editItem.is_autosave = 1
            this.$emit('update', this.editItem)
        },

        methodSetDescription () {
            if (this.dataEditorDesc) this.dataEditorDesc.destroy()
            const content = this.editItem.description ? this.editItem.description : ' '
            this.dataEditorDesc = new Editor({
                extensions: [
                    new Bold(),
                    new Italic(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new History(),
                ],
                content: marked(content, { sanitize: true }),
                editable: this.canUpdate,
                onUpdate: state => {
                    this.editItem.description = turndownService.turndown(state.getHTML())
                    this.methodUpdate()
                }
            })
        },

        // Tags Section
        async local_associate_tag_destroy (data, item) {
            this.local_remove_tag(data, item)
            await this.association_destroy(data.association)
            if (this.association_response.status !== 'success') return
        },

        async local_remove_tag (tag, item) {
            const index = item.tags.findIndex(item => item.id === tag.id)
            if (index !== -1) item.tags.splice(index, 1)
        },
        // Tags section ----- END

        // Comments section
        methodEditComment (comment) {
            this.dataEditComment = _.cloneDeep(comment)
            this.methodResetComment()
        },

        methodResetComment () {
            this.dataItemChange = true
            setTimeout(() => this.dataItemChange = false, 500)
        },

        async methodUpdateComment (comment, type, item_id) {
            const item = this.editItem.comments.find(data => data.id === item_id)
            const oldComment = _.cloneDeep(item.content)
            item.content = comment
            item.updated_at = new Date()
            await this.comment_update(item)
            if (this.comment_response.status !== 'success') return item.content = oldComment
            this.$emit('comment-update')
            this.methodCancelEdit()
        },

        async methodStoreComment (comment, type, item_id) {
            this.dataEditComment = {}
            await this.methodSetCommentVars(comment, type, item_id)
            await this.comment_store(this.comment_item)
            if (this.comment_response.status !== 'success') return
            this.methodAddCommentToItem(this.comment_item)
            setTimeout(() => {
                this.$emit('comment-store')
                this.comment_clear_item()
            }, 100)
        },

        methodAddCommentToItem (comment) {
            if (!this.editItem.comments) Object.assign(this.editItem, { comments: [] })
            this.editItem.comments.push(comment)
        },

        async methodSetCommentVars (comment, type, id) {
            this.comment_item.commentable_type = type
            this.comment_item.commentable_id = id
            this.comment_item.content = comment
            this.comment_item.created_by = this.user_self
            this.comment_item.created_by_id = this.user_self.id
            this.comment_item.created_at = new Date()
        },

        methodDeleteComment (comment) {
            const index = this.editItem.comments.findIndex(item => item.id === comment.id)
            if (index !== -1) this.editItem.comments.splice(index, 1)
            this.comment_destroy({ id: comment.id })
            if (this.comment_response && this.comment_response.status !== 'success')
            this.$notify('success', 'Comment deleted successfully!')
            this.$emit('comment-delete')
        },

        methodCancelEdit (comment) {
            if (comment) this.dataEditComment.content = comment.content
            this.dataEditComment = {}
        },

        methodShowAllComments () {
            this.dataShowAllComments = !this.dataShowAllComments
        },
        // Comments section ----- END

        // Date filter
        methodDateUpdate (evt) {
            if (!this.canUpdate) return
            this.editItem.is_autosave = 0
            this.$emit('update', this.editItem)
        },

        methodClearDate (field) {
            this.editItem[field] = null
            this.methodDateUpdate()
        },

        // Extras
        local_format_date (date, range = false, simple = false) {
            if (range) return moment(date).format('YYYY-MM-DD')
            if (simple) return moment(date).format('MMM D')

            return date ? moment(date).format('MMM D, YYYY') : null
        },

        methodToggleMenu (val) {
            this.dataShowTagInput = val
        },

        methodGetDateDiff (updated, created) {
            return moment(created).diff(moment(updated), 'seconds') > 5
        },

        // methodBackup () {
        //     this.dataItemBackup = {}
        //     const cloneObj = {}
        //     Object.keys(this.editItem).forEach(key => {
        //         if (this.dataRequiredFields.includes(key)) cloneObj[key] = this.editItem[key]
        //     })
        //     this.dataItemBackup = _.cloneDeep(cloneObj)
        // },

        methodEscToClosePane (evt) {
            if (this.loaders.content_autosave_response !== 'loading') {
                if (evt.keyCode === 27) {
                    this.dataPaneStatus === 'error' ? '' : this.$emit('close', true)
                }
            }
        },

        methodFocusDesc () {
            if (!this.editItem.description) this.editItem.description = ' '
            this.dataEditorDesc.focus()
        },

        ...mapActions('Association', {
            association_store: 'store',
            association_destroy: 'destroy',
        }),

        ...mapActions('Comment', {
            comment_store: 'store',
            comment_update: 'update',
            comment_destroy: 'destroy',
            comment_clear_item: 'clear_item',
        }),
    }
}
</script>

<style lang="scss" scoped>
    .c-comment-item {
        &__toolbar {
            visibility: hidden;
            position: absolute;
            right: -1000px;
            top: -10px;
        }
        &:hover {
            .c-comment-item__toolbar {
                visibility: visible;
                right: -48px;
            }
        }
    }

    .c-date-picker {
        &--clear { visibility: hidden !important; }
        &:hover {
            .c-date-picker--clear { visibility: visible !important; }
        }
    }

    // .c-show { visibility: visible !important; }
</style>

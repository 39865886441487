<template>
    <a-card>
        <a-sheet min-height="96" class="pa-6 pb-7 u-relative u-flex-center-y">
            <div class="u-flex-center-y">
                <a-avatar color="primary" size="48" class="mr-3">
                    <a-icon size="20" color="white">space_dashboard</a-icon>
                </a-avatar>
                <div>
                    <h2 class="md-heading-5 body--text text--darken-3">Tile Configuration</h2>
                    <p class="mb-0 md-body-2 body--text text--lighten-1 mt-n1">Summary about the new tile configurations are displayed here.</p>
                </div>
            </div>
            <a-spacer></a-spacer>

            <!-- Show only save button when creating a tile -->
            <template v-if="!tile_edit_mode">
                <a-tooltip bottom :disabled="!tile_has_errors" content-class="c-tooltip-pointer c-tooltip-pointer--right u-rounded-corners-lg">
                    <template v-slot:activator="{ on }">
                        <a-btn v-on="on" @click="local_create_tile_with_config(selected_tile)" :loading="local_check_loading('tile_create')" depressed :class="[tile_has_errors ? 'red darken-2' : 'indigo darken-1', 'px-3 white--text u-rounded-corners-lg md-body-2 text-uppercase font-weight-bold']" style="transition: background-color 0.3s ease-out">
                            <a-icon size="16" color="white" class="mr-2" v-if="tile_has_errors">error</a-icon>
                            <span>Save Changes</span>
                            <a-sheet class="md-caption font-weight-medium amber darken-3 white--text u-rounded-corners-full u-flex-center ml-2" width="20" height="20" style="border: 2px solid #fff !important;" v-if="local_tile_changes_length && !tile_has_errors">{{ local_tile_changes_length }}</a-sheet>
                        </a-btn>
                    </template>
                    <span class="d-block text-center py-3 mx-2">
                        <a-sheet class="ml-2 transparent white--text md-body-2" width="300">You have error(s) in your configuration, please fix to review and save your changes</a-sheet>
                    </span>
                </a-tooltip>
            </template>

            <template v-else>
                <a-tooltip bottom v-if="local_tile_changes_length && this.review_view" :disabled="!tile_has_errors" content-class="c-tooltip-pointer c-tooltip-pointer--right u-rounded-corners-lg">
                    <template v-slot:activator="{ on }">
                        <a-btn v-on="on" @click="tile_has_errors ? '' : local_save_tile_config(selected_tile)" :loading="local_check_loading('tile_create')" depressed :class="[tile_has_errors ? 'red darken-2' : 'indigo darken-1', 'px-3 white--text u-rounded-corners-lg md-body-2 text-uppercase font-weight-bold']" style="transition: background-color 0.3s ease-out">
                            <a-icon size="16" color="white" class="mr-2" v-if="tile_has_errors">error</a-icon>
                            <span>{{ tile_edit_mode ? 'Update' : 'Save' }} Changes</span>
                            <a-sheet class="md-caption font-weight-medium amber darken-3 white--text u-rounded-corners-full u-flex-center ml-2" width="20" height="20" style="border: 2px solid #fff !important;" v-if="local_tile_changes_length && !tile_has_errors">{{ local_tile_changes_length }}</a-sheet>
                        </a-btn>
                    </template>
                    <span class="d-block text-center py-3 mx-2">
                        <a-sheet class="ml-2 transparent white--text md-body-2" width="300">You have error(s) in your configuration, please fix to review and save your changes</a-sheet>
                    </span>
                </a-tooltip>
                <template v-else>
                    <a-tooltip bottom v-if="tile_has_errors" content-class="c-tooltip-pointer c-tooltip-pointer--right u-rounded-corners-lg">
                        <template v-slot:activator="{ on }">
                            <a-btn v-on="on" @click="local_go_to_review_view(selected_tile)"  depressed class="red darken-2 px-3 white--text u-rounded-corners-lg md-body-2 text-uppercase font-weight-bold" style="transition: background-color 0.3s ease-out">
                                <a-icon size="16" color="white" class="mr-2">error</a-icon>
                                <span>Review Changes</span>
                            </a-btn>
                        </template>
                        <span class="d-block text-center py-3 mx-2">
                            <!-- <a-icon size="22" color="orange lighten-1">info</a-icon> -->
                            <a-sheet class="ml-2 transparent white--text md-body-2" width="300">You have error(s) in your configuration, please fix to review and save your changes</a-sheet>
                        </span>
                    </a-tooltip>
                    <a-btn v-else @click="local_go_to_review_view(selected_tile)" :disabled="!local_tile_changes_length || edit_mode" depressed class="indigo darken-1 px-3 white--text u-rounded-corners-lg md-body-2 text-uppercase font-weight-bold" style="transition: background-color 0.3s ease-out">
                        <span>Review Changes</span>
                        <a-sheet class="md-caption font-weight-medium amber darken-3 white--text u-rounded-corners-full u-flex-center ml-2" width="20" height="20" style="border: 2px solid #fff !important;" v-if="local_tile_changes_length">{{ local_tile_changes_length }}</a-sheet>
                    </a-btn>
                </template>
            </template>
            <a-icon size="20" class="ml-8" color="body lighten-2" @click="local_close_tile_dialog()">clear</a-icon>
        </a-sheet>
        <a-divider></a-divider>
        <a-sheet class="u-overflow-hidden grey lighten-5">
            <v-scroll-x-transition hide-on-leave leave-absolute>
                <a-sheet v-if="review_view" height="52" class="u-flex-center-y u-rounded-corners-lg transparent u-relative mx-4 mr-6">
                    <a-sheet class="d-inline-flex align-center u-cursor-pointer u-rounded-corners-lg transparent px-1" height="32" v-ripple="{ class: 'indigo--text' }" @click="local_go_to_review_view(false)">
                        <a-icon size="24" color="indigo darkrn-2" class="mr-1">keyboard_backspace</a-icon>
                        <span class="md-body-2 font-weight-medium text-uppercase indigo--text text--darken-1" style="letter-spacing: 0.5px;">Go Back</span>
                    </a-sheet>
                </a-sheet>
                <a-sheet v-else height="52" class="u-flex-center-y u-rounded-corners-lg u-relative">
                    <a-sheet v-for="(menu, index) in local_config_tile_menu" @click="local_select_tile({ ...menu, index })" class="c-tile-module u-hfull px-6 u-flex-center u-cursor-pointer u-relative transparent" :class="[{ 'c-tile-module--active': selected_tile_module && selected_tile_module.slug === menu.slug }]" :width="menu.width" style="z-index: 1" :key="menu.slug">
                        <div class="u-relative u-flex-center-y">
                            <a-icon size="18" :color="selected_tile_module && selected_tile_module.slug === menu.slug ? 'indigo darken-1' : 'body darken-1'" class="mr-2" :outlined="menu.icon_outlined">
                                {{ menu.icon }}
                            </a-icon>
                            <span :class="['md-subtitle-1 c-font-transition u-relative font-weight-medium', selected_tile_module && selected_tile_module.slug === menu.slug ? 'indigo--text text--darken-1' : 'body--text text--darken-1']">{{ menu.title }}</span>
                            <a-icon size="16" color="red darken-1" class="u-absolute" style="top: 50%; transform: translateY(-50%); right: -20px;" v-if="local_has_validation_slug(menu.slug)">error</a-icon>
                            <template v-else>
                                <a-icon size="10" color="amber darken-3" class="u-absolute" style="top: 50%; transform: translateY(-50%); right: -14px;" v-if="local_section_has_changes(menu.title)">fiber_manual_record</a-icon>
                            </template>
                        </div>
                    </a-sheet>
                </a-sheet>
            </v-scroll-x-transition>
            <a-divider class="grey lighten-3"></a-divider>

            <!-- Main -->
            <a-sheet min-height="540" max-height="540" class="u-overflow-y transparent scroll-transparent c-tiny-scroll">
                <!-- Table Component -->
                <div class="pa-6 pt-4 px-7">
                    <a-sheet v-if="selected_tile_module.slug === 'summary'" class="transparent">
                        <div class="mb-6">
                            <span class="md-body-1 body--text text--lighten-1 mb-2 d-inline-block font-weight-medium">Title</span>
                            <v-text-field
                                v-model="tile_summary.original.title"
                                placeholder="Eg: Quarter One"
                                background-color="white"
                                class="pa-0 mb-2 u-shadow u-border-transparent u-rounded-corners-lg"
                                :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('summary_title') }]"
                                @input="local_update_summary_changes('Summary', 'title', tile_summary.original.title)"
                                solo flat hide-details autofocus
                            >
                                <template #append>
                                    <a-progress-circular v-if="local_check_loading('tile_show_load')" width="2" size="14" color="grey darken-1" indeterminate></a-progress-circular>
                                </template>
                            </v-text-field>
                            <g-field-text :has-error="local_get_validation_error('summary_title')">
                                <template #error-msg>{{ local_get_validation_error('summary_title', 'message') }}</template>
                                <template #info>Enter the title of the tile.</template>
                            </g-field-text>
                        </div>
                        <div>
                            <span class="md-body-1 body--text text--lighten-1 mb-2 d-inline-block font-weight-medium">Description (Optional)</span>
                            <a-textarea
                                v-model="tile_summary.original.description"
                                placeholder="More details about the tile goes here..."
                                background-color="white"
                                class="pa-0 mb-2 pb-2 u-shadow u-rounded-corners-lg"
                                :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('summary_description') }]"
                                rows="7"
                                @input="local_update_summary_changes('Summary', 'description', tile_summary.original.description)"
                                solo flat hide-details no-resize
                            >
                                <template #append>
                                    <a-progress-circular v-if="local_check_loading('tile_show_load')" width="2" size="14" color="grey darken-1" indeterminate></a-progress-circular>
                                </template>
                            </a-textarea>
                            <g-field-text :has-error="local_get_validation_error('summary_description')">
                                <template #error-msg>{{ local_get_validation_error('summary_description', 'message') }}</template>
                                <template #info>Enter the name of the tile.</template>
                            </g-field-text>
                        </div>
                    </a-sheet>
                    <a-sheet v-if="selected_tile_module.slug === 'workspace'" class="transparent" max-width="656">
                        <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">Workspace</span>
                        <a-sheet class="transparent" max-width="500">
                            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('workspace_id')">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">
                                        <v-autocomplete
                                            v-model="tile_workspace_id.original.workspace_id"
                                            :items="workspace_search_list"
                                            item-text="title"
                                            item-value="id"
                                            placeholder="Ex: Sales Engineering"
                                            class="pa-0 u-shadow u-border-transparent u-rounded-corners-lg"
                                            :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('workspace_id') }]"
                                            item-color="indigo darken-2"
                                            background-color="white"
                                            :disabled="local_check_loading('workspace_list') || local_check_loading('tile_workspace_load')"
                                            :menu-props="{ rounded: 'lg', nudgeBottom: '4px' }"
                                            no-data-text="No data found"
                                            @click:clear="local_clear_workspace_field('workspace_id')"
                                            @input="local_update_workspace_changes('Workspace', 'workspace_id', tile_workspace_id.original.workspace_id)"
                                            solo flat hide-details clearable
                                        >
                                            <template v-slot:selection="{ item }">
                                                <span class="md-subtitle-1 ml-1 grey--text text--darken-3">{{ item.title | truncateText(50) }}</span>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <span class="md-subtitle-1">{{ item.title | truncateText(50) }}</span>
                                            </template>
                                            <template #append>
                                                <a-progress-circular v-if="local_check_loading('workspace_list') || local_check_loading('tile_workspace_load')" width="2" size="20" color="orange darken-1" class="mr-1" indeterminate></a-progress-circular>
                                                <a-icon v-else size="24" color="grey darken-1">arrow_drop_down</a-icon>
                                            </template>
                                        </v-autocomplete>
                                    </div>
                                </template>
                                <span>{{ local_get_validation_error('workspace_id', 'message') }}</span>
                            </a-tooltip>
                        </a-sheet>
                    </a-sheet>
                    <div v-if="selected_tile_module.slug === 'timeline'">
                        <template v-if="local_check_loading('tile_timeline_load') || local_check_loading('tile_edit_show_load')">
                            <div class="my-6 u-flex-center">
                                <a-progress-circular width="2" size="14" color="grey darken-1" indeterminate></a-progress-circular>
                                <span class="md-subtitle-2 grey--text text--darken-1 ml-2">Loading..</span>
                            </div>
                        </template>
                        <template v-else>
                            <a-sheet class="transparent" max-width="656">
                                <div class="u-flex-center-y mb-6">
                                    <a-sheet class="mr-2 transparent u-relative" width="50%">
                                        <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">Field</span>
                                        <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('timeline_field')">
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on">
                                                    <a-select
                                                        v-model="tile_timeline.original.timeline_field"
                                                        :items="timeline_field_types"
                                                        placeholder="Select Field"
                                                        class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                                        :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('timeline_field') }]"
                                                        item-color="indigo darken-1"
                                                        background-color="white"
                                                        :append-icon="!tile_timeline.original.timeline_field ? 'arrow_drop_down' : ''"
                                                        :menu-props="{ rounded: 'lg', nudgeBottom: '4px', offsetY: true, closeOnContentClick: true, closeOnClick: true, }"
                                                        @input="local_update_timeline_changes('Timeline', 'timeline_field', tile_timeline.original.timeline_field)"
                                                        @click:clear="local_clear_timeline_fields('timeline_field')"
                                                        solo flat hide-details clearable
                                                    ></a-select>
                                                </div>
                                            </template>
                                            <span>{{ local_get_validation_error('timeline_field', 'message') }}</span>
                                        </a-tooltip>
                                    </a-sheet>
                                    <a-sheet class="ml-2 transparent u-relative" width="50%">
                                        <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">Timeline</span>
                                        <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('timeline_date_option')">
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on">
                                                    <a-select
                                                        v-model="tile_timeline.original.timeline_date_option"
                                                        :items="timeline_date_options"
                                                        placeholder="Select predefined date option"
                                                        class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                                        :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('timeline_date_option') }]"
                                                        item-color="indigo darken-1"
                                                        background-color="white"
                                                        :open-on-clear="false"
                                                        :append-icon="!tile_timeline.original.timeline_date_option ? 'arrow_drop_down' : ''"
                                                        :menu-props="{ rounded: 'lg', nudgeBottom: '4px', offsetY: true, closeOnContentClick: true, closeOnClick: true, }"
                                                        @input="local_update_timeline_changes('Timeline', 'timeline_date_option', tile_timeline.original.timeline_date_option)"
                                                        @click:clear="local_clear_timeline_fields('timeline_date_option')"
                                                        solo flat hide-details clearable
                                                    >
                                                        <template v-slot:selection="{ item }">
                                                            <span class="md-subtitle-1 indigo--text text--darken-1">{{ item.text | truncateText(50) }}</span>
                                                        </template>
                                                        <template v-slot:item="{ item }">
                                                            <span :class="['md-subtitle-1', { 'indigo--text text--darken-1': item.value === 'date_range' }]">{{ item.text | truncateText(50) }}</span>
                                                        </template>
                                                    </a-select>
                                                </div>
                                            </template>
                                            <span>{{ local_get_validation_error('timeline_date_option', 'message') }}</span>
                                        </a-tooltip>
                                    </a-sheet>
                                </div>
                                <a-sheet v-if="tile_timeline.original.timeline_date_option && tile_timeline.original.timeline_date_option !== 'date_range'" class="u-flex align-start blue-grey lighten-5 blue-grey--text text--lighten-1 md-body-2 u-rounded-corners-lg pa-3">
                                    <a-icon size="20" color="blue-grey lighten-1" class="mr-2" style="margin-top: 2px;">info</a-icon>
                                    <div>
                                        <span>This selection will show the data from <strong>{{ local_get_date_range() }}</strong></span>
                                        <div class="md-caption mt-1">Projects without <strong>Start Date</strong> will be ignored</div>
                                    </div>
                                </a-sheet>
                            </a-sheet>
                            <div v-if="tile_timeline.original.timeline_date_option && tile_timeline.original.timeline_date_option === 'date_range'">
                                <a-sheet class="u-flex align-center transparent" max-width="656">
                                    <a-sheet class="u-wfull transparent mr-2 u-relative" width="50%">
                                        <span class="md-body-2 body--text text--lighten-1 mb-2 d-inline-block font-weight-medium">Start Date</span>
                                        <a-sheet class="u-wfull u-rounded-corners-lg u-shadow u-border-transparent u-flex-center-y u-relative pr-3" :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('timeline_start_date') }]">
                                            <vc-date-picker v-model="tile_timeline.original.start_date" :max-date="tile_timeline.original.due_date" class="d-block u-wfull" @input="local_update_timeline_dates('Timeline', 'start_date', tile_timeline.original.start_date)" :model-config="modelConfig" :is-required="!!(tile_timeline.original.start_date)">
                                                <template v-slot="{ togglePopover }">
                                                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('timeline_start_date')">
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on" class="u-wfull white u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="() => { togglePopover({ placement: 'bottom-start' }); }">
                                                                <div class="u-flex-center grey lighten-4 u-rounded-corners-lg px-3" style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;">
                                                                    <a-icon size="22" color="grey darken-2">today</a-icon>
                                                                </div>
                                                                <div class="md-body-1 u-wfull pa-3" :class="[(tile_timeline.original.start_date ? 'grey--text text--darken-3' : 'body--text text--lighten-3')]">
                                                                    {{ tile_timeline.original.start_date ? local_format_date(tile_timeline.original.start_date) : 'Ex: Jan 1, 2021' }}
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <span>{{ local_get_validation_error('timeline_start_date', 'message') }}</span>
                                                    </a-tooltip>
                                                </template>
                                            </vc-date-picker>
                                            <a-spacer></a-spacer>
                                            <a-icon v-if="tile_timeline.original.start_date" @click="local_clear_timeline_fields('start_date')" class="c-date-picker--clear" size="24">clear</a-icon>
                                        </a-sheet>
                                    </a-sheet>
                                    <a-sheet class="u-wfull transparent ml-2 u-relative" width="50%">
                                        <span class="md-body-2 body--text text--lighten-1 mb-2 d-inline-block font-weight-medium">End Date</span>
                                        <a-sheet class="u-wfull u-rounded-corners-lg u-shadow u-border-transparent u-flex-center-y u-relative pr-3" :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('timeline_due_date') }]">
                                            <vc-date-picker v-model="tile_timeline.original.due_date" :max-date="tile_timeline.original.due_date" class="d-block u-wfull" @input="local_update_timeline_dates('Timeline', 'due_date', tile_timeline.original.due_date)" :model-config="modelConfig" :is-required="!!(tile_timeline.original.due_date)">
                                                <template v-slot="{ togglePopover }">
                                                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('timeline_due_date')">
                                                        <template v-slot:activator="{ on, value }">
                                                            <div v-on="on" class="u-wfull white u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="() => { togglePopover({ placement: 'bottom-start' }); }">
                                                                <div class="u-flex-center grey lighten-4 u-rounded-corners-lg px-3" style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;">
                                                                    <a-icon size="22" color="grey darken-2">today</a-icon>
                                                                </div>
                                                                <div class="md-body-1 u-wfull pa-3" :class="[(tile_timeline.original.due_date ? 'grey--text text--darken-3' : 'body--text text--lighten-3')]">
                                                                    {{ tile_timeline.original.due_date ? local_format_date(tile_timeline.original.due_date) : 'Ex: Jan 1, 2021' }}
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <span>{{ local_get_validation_error('timeline_due_date', 'message') }}</span>
                                                    </a-tooltip>
                                                </template>
                                            </vc-date-picker>
                                            <a-spacer></a-spacer>
                                            <a-icon v-if="tile_timeline.original.due_date" @click="local_clear_timeline_fields('due_date')" class="c-date-picker--clear" size="24">clear</a-icon>
                                        </a-sheet>
                                    </a-sheet>
                                </a-sheet>

                                <a-sheet max-width="656" class="u-flex align-start blue-grey lighten-5 blue-grey--text text--lighten-1 md-body-2 u-rounded-corners-lg pa-3 mt-7">
                                    <a-icon size="20" color="blue-grey lighten-1" class="mr-2" style="margin-top: 2px;">info</a-icon>
                                    <div class="">Projects without <strong>Start Date</strong> will be ignored</div>
                                </a-sheet>
                            </div>
                        </template>
                    </div>
                    <div v-if="selected_tile_module.slug === 'filters'">
                        <a-sheet class="transparent">
                            <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">Filters</span>
                            <p v-if="(tile_filters.original && !tile_filters.original.length) || local_check_loading('modules_load')" class="mb-0 md-body-2 body--text text--lighten-2">No filter conditions are applied to this tile. You can start configuring the tile by clicking on the Add Condition button.</p>
                        </a-sheet>
                        <template v-if="local_check_loading('modules_load')">
                            <div class="my-6 u-flex-center">
                                <a-progress-circular width="2" size="14" color="grey darken-1" indeterminate></a-progress-circular>
                                <span class="md-subtitle-2 grey--text text--darken-1 ml-2">Loading..</span>
                            </div>
                        </template>
                        <div v-else class="mt-2 mb-12">
                            <!-- Group loop -->
                            <table class="u-wfull" style="border-collapse: collapse;" v-for="(config_filter, group_index) in tile_filters.original">
                                <!-- OR divider -->
                                <tr v-if="group_index !== 0">
                                    <td colspan="100">
                                        <a-sheet color="grey lighten-2" height="1" class="u-wfull u-relative my-7">
                                            <span class="u-absolute md-body-2 font-weight-medium body--text text--lighten-2 d-inline-block pa-1 px-2 grey lighten-5" style="left: 50%; top: 50%; transform: translate(-50%, -50%); z-index: 1">OR</span>
                                        </a-sheet>
                                    </td>
                                </tr>

                                <!-- Single filter row -->
                                <tr class="mb-3" v-for="(filter, index) in config_filter.filters" :key="filter.id">
                                    <!-- IF / AND -->
                                    <td class="text-left pb-4" style="min-width: 36px;">
                                        <a-sheet :class="['transparent text-center font-weight-medium md-caption-2 indigo--text text--darken-1']">{{ index === 0 ? 'IF' : 'AND' }}</a-sheet>
                                    </td>

                                    <!-- Property -->
                                    <td class="text-left pb-4 px-2" style="min-width: 256px">
                                        <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('filters_entity_id_' + filter.id)">
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on">
                                                    <v-autocomplete
                                                        v-model="filter.entity_property_id"
                                                        :items="local_entity_list"
                                                        item-text="label"
                                                        item-value="id"
                                                        placeholder="Select Field"
                                                        class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                                        :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('filters_entity_id_' + filter.id) }]"
                                                        item-color="indigo darken-2"
                                                        background-color="white"
                                                        :menu-props="{ rounded: 'lg', nudgeBottom: '4px', closeOnClick: true }"
                                                        @input="local_tile_value_list(filter.entity_property_id, filter, group_index)"
                                                        key="property-field"
                                                        solo flat hide-details
                                                    >
                                                        <template #item="{ item }">
                                                            <div class="u-flex-center-y">
                                                                <a-icon size="18" color="grey darken-2" class="mr-3" :outlined="local_get_type_icon(item.key).outlined">{{ local_get_type_icon(item.key).text }}</a-icon>
                                                                <span>{{ item.label }}</span>
                                                            </div>
                                                        </template>
                                                        <template #prepend-inner>
                                                            <a-icon size="18" color="grey darken-2" class="mr-1" :outlined="local_get_icon(filter.id, 'outlined')">{{ local_get_icon(filter.id, 'text') }}</a-icon>
                                                        </template>
                                                        <template #append>
                                                            <a-icon size="20" color="grey darken-2" class="mr-1" v-if="!filter.entity_property_id">arrow_drop_down</a-icon>
                                                            <a-icon size="18" color="grey darken-2" class="mr-1" v-else @click="local_clear_property(filter, group_index)">clear</a-icon>
                                                        </template>
                                                    </v-autocomplete>
                                                </div>
                                            </template>
                                            <span>{{ local_get_validation_error('filters_entity_id_' + filter.id, 'message') }}</span>
                                        </a-tooltip>
                                    </td>

                                    <!-- Operator -->
                                    <td class="text-left pb-4 px-2" style="min-width: 256px;">
                                        <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('filters_operator_' + filter.id)">
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on">
                                                    <v-autocomplete
                                                        v-model="filter.operator"
                                                        :items="local_get_operator_list(filter.entity_property_id)"
                                                        item-text="label"
                                                        item-value="key"
                                                        placeholder="Select Operator"
                                                        class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                                        :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('filters_operator_' + filter.id) }]"
                                                        item-color="indigo darken-2"
                                                        background-color="white"
                                                        :menu-props="{ rounded: 'lg', nudgeBottom: '4px', closeOnClick: true }"
                                                        @input="local_change_operator(filter.operator, filter, group_index)"
                                                        key="operator-field"
                                                        solo flat hide-details
                                                    >
                                                        <template #append>
                                                            <a-icon size="20" color="grey darken-2" class="mr-1" v-if="!filter.operator">arrow_drop_down</a-icon>
                                                            <a-icon size="18" color="grey darken-2" class="mr-1" v-else @click="local_change_operator(filter.operator, filter, group_index, true)">clear</a-icon>
                                                        </template>
                                                    </v-autocomplete>
                                                </div>
                                            </template>
                                            <span>{{ local_get_validation_error('filters_operator_' + filter.id, 'message') }}</span>
                                        </a-tooltip>
                                    </td>

                                    <!-- Value -->
                                    <td v-if="['empty', 'not-empty'].includes(filter.operator)" class="text-left pb-4 px-2" style="min-width: 374px; max-width: 374px">
                                        <a-sheet class="grey lighten-4 body--text text--lighten-3 font-italic px-3 u-flex-center-y u-rounded-corners-lg" height="48">
                                            No value required
                                        </a-sheet>
                                    </td>

                                    <template v-else>
                                        <template v-if="local_get_data_type(filter.entity_property_id) === 'date'">
                                            <td v-if="local_operator_is_between(filter.operator, filter.entity_property_id)" class="text-left pb-4 px-2" style="min-width: 374px; max-width: 374px">
                                                <div class="u-flex-center-y u-relative" style="z-index: 10 !important">
                                                    <a-sheet width="187" class="u-wfull u-rounded-corners-lg u-border-transparent u-flex-center-y u-relative pr-2 mr-2 u-shadow" :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('filters_value_' + filter.id) }]">
                                                        <vc-date-picker v-model="filter.value" @input="local_change_filter_value_fields(filter, 'value')" class="d-block u-wfull u-relative" :max-date="filter.value_alt" :model-config="modelConfig" :is-required="!!(filter.value)">
                                                            <template v-slot="{ togglePopover }">
                                                                <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('filters_value_' + filter.id)">
                                                                    <template v-slot:activator="{ on }">
                                                                        <div v-on="on" class="u-wfull u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="['empty', 'not-empty'].includes(filter.operator) ? {} : togglePopover({ placement: 'bottom-start' })">
                                                                            <div class="md-body-2 grey--text text--darken-3 pa-3 pl-3 u-flex-center-y">
                                                                                <a-icon size="18" color="grey darken-1" class="mr-2">date_range</a-icon>
                                                                                <span>{{ filter.value ? local_format_date(filter.value) : 'Select Date' }}</span>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                    <span>{{ local_get_validation_error('filters_value_' + filter.id, 'message') }}</span>
                                                                </a-tooltip>
                                                            </template>
                                                        </vc-date-picker>
                                                        <a-spacer></a-spacer>
                                                        <a-icon v-if="filter.value" @click="local_change_filter_value_fields(filter, 'value', true)" class="c-date-picker--clear" size="18">clear</a-icon>
                                                    </a-sheet>
                                                    <a-sheet width="187" class="u-wfull u-rounded-corners-lg u-border-transparent u-flex-center-y u-relative pr-2 ml-2 u-shadow" :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('filters_value_alt_' + filter.id) }]">
                                                        <vc-date-picker v-model="filter.value_alt" @input="local_change_filter_value_fields(filter, 'value_alt')" class="d-block u-wfull u-relative" style="z-index: 2" :min-date="filter.value" :model-config="modelConfig" :is-required="!!(filter.value_alt)">
                                                            <template v-slot="{ togglePopover }">
                                                                <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" style="z-index: 10 !important" :disabled="!local_get_validation_error('filters_value_alt_' + filter.id)">
                                                                    <template v-slot:activator="{ on }">
                                                                        <div v-on="on" class="u-wfull u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="['empty', 'not-empty'].includes(filter.operator) ? {} : togglePopover({ placement: 'bottom-start' })">
                                                                            <div class="md-body-2 grey--text text--darken-3 pa-3 pl-3 u-flex-center-y">
                                                                                <a-icon size="18" color="grey darken-1" class="mr-2">date_range</a-icon>
                                                                                <span>{{ filter.value_alt ? local_format_date(filter.value_alt) : 'Select Date' }}</span>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                    <span>{{ local_get_validation_error('filters_value_alt_' + filter.id, 'message') }}</span>
                                                                </a-tooltip>
                                                            </template>
                                                        </vc-date-picker>
                                                        <a-spacer></a-spacer>
                                                        <a-icon v-if="filter.value_alt" @click="local_change_filter_value_fields(filter, 'value_alt', true)" class="c-date-picker--clear" size="18">clear</a-icon>
                                                    </a-sheet>
                                                </div>
                                            </td>
                                            <td v-else class="text-left pb-4 px-2" style="min-width: 374px; max-width: 374px">
                                                <a-sheet class="u-wfull u-rounded-corners-lg u-border-transparent u-flex-center-y u-relative pr-2 mr-2 u-shadow" :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('filters_value_' + filter.id) }]" style="z-index: 10 !important">
                                                    <vc-date-picker v-model="filter.value" @input="local_change_filter_value_fields(filter, 'value')" class="d-block u-wfull" :model-config="modelConfig" :is-required="!!(filter.value)">
                                                        <template v-slot="{ togglePopover }">
                                                            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('filters_value_' + filter.id)">
                                                                <template v-slot:activator="{ on }">
                                                                    <div v-on="on" class="u-wfull u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="['empty', 'not-empty'].includes(filter.operator) ? {} : togglePopover({ placement: 'bottom-start' })">
                                                                        <div class="md-body-2 grey--text text--darken-3 pa-3 pl-3 u-flex-center-y">
                                                                            <a-icon size="18" color="grey darken-1" class="mr-2">date_range</a-icon>
                                                                            <span>{{ filter.value ? local_format_date(filter.value) : 'Date' }}</span>
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                                <span>{{ local_get_validation_error('filters_value_' + filter.id, 'message') }}</span>
                                                            </a-tooltip>
                                                        </template>
                                                    </vc-date-picker>
                                                    <a-spacer></a-spacer>
                                                    <a-icon v-if="filter.value" @click="local_change_filter_value_fields(filter, 'value', true)" class="c-date-picker--clear" size="18">clear</a-icon>
                                                </a-sheet>
                                            </td>
                                        </template>

                                        <template v-else>
                                            <td class="text-left pb-4 px-2" style="min-width: 374px; max-width: 374px">
                                                <div v-if="local_operator_is_between(filter.operator, filter.entity_property_id)" class="u-flex-center-y">
                                                    <a-sheet width="187" class="transparent mr-2">
                                                        <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('filters_value_' + filter.id)">
                                                            <template v-slot:activator="{ on }">
                                                                <div v-on="on">
                                                                    <v-text-field
                                                                        v-model="filter.value"
                                                                        placeholder="Enter Value"
                                                                        class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg u-shadow"
                                                                        :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('filters_value_' + filter.id) }]"
                                                                        item-color="indigo darken-2"
                                                                        background-color="white"
                                                                        key="value-btw-text-field"
                                                                        @input="local_change_filter_value_fields(filter, 'value')"
                                                                        solo flat hide-details
                                                                    >
                                                                        <template #append>
                                                                            <a-icon size="18" color="grey darken-2" class="mr-1" v-if="filter.value" @click="local_change_filter_value_fields(filter, 'value', true)">clear</a-icon>
                                                                        </template>
                                                                        <template #prepend-inner>
                                                                            <span class="grey--text text--darken-1 md-subtitle-1 font-weight-medium" style="margin-left: 2px; margin-right: 6px;" v-if="local_get_entity_key(filter.entity_property_id) === 'deal_amount'">
                                                                                {{ local_currency_symbol }}
                                                                            </span>
                                                                            <a-icon size="18" color="grey darken-2" class="mr-1" v-else :outlined="local_get_icon(filter.id, 'outlined')">{{ local_get_icon(filter.id, 'text') }}</a-icon>
                                                                        </template>
                                                                    </v-text-field>
                                                                </div>
                                                            </template>
                                                            <span>{{ local_get_validation_error('filters_value_' + filter.id, 'message') }}</span>
                                                        </a-tooltip>
                                                    </a-sheet>
                                                    <a-sheet width="187" class="transparent ml-2">
                                                        <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('filters_value_alt_' + filter.id)">
                                                            <template v-slot:activator="{ on }">
                                                                <div v-on="on">
                                                                    <v-text-field
                                                                        v-model="filter.value_alt"
                                                                        placeholder="Enter Value"
                                                                        class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                                                        :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('filters_value_alt_' + filter.id) }]"
                                                                        item-color="indigo darken-2"
                                                                        background-color="white"
                                                                        key="value-alt-btw-text-field"
                                                                        @input="local_change_filter_value_fields(filter, 'value_alt')"
                                                                        solo flat hide-details
                                                                    >
                                                                        <template #append>
                                                                            <a-icon size="18" color="grey darken-2" class="mr-1" v-if="filter.value_alt" @click="local_change_filter_value_fields(filter, 'value_alt', true)">clear</a-icon>
                                                                        </template>
                                                                        <template #prepend-inner>
                                                                            <span class="grey--text text--darken-1 md-subtitle-1 font-weight-medium" style="margin-left: 2px; margin-right: 6px;" v-if="local_get_entity_key(filter.entity_property_id) === 'deal_amount'">
                                                                                {{ local_currency_symbol }}
                                                                            </span>
                                                                            <a-icon size="18" color="grey darken-2" class="mr-1" v-else :outlined="local_get_icon(filter.id, 'outlined')">{{ local_get_icon(filter.id, 'text') }}</a-icon>
                                                                        </template>
                                                                    </v-text-field>
                                                                </div>
                                                            </template>
                                                            <span>{{ local_get_validation_error('filters_value_alt_' + filter.id, 'message') }}</span>
                                                        </a-tooltip>
                                                    </a-sheet>
                                                </div>
                                                <template v-else>
                                                    <a-tooltip v-if="local_filter_input_type(filter.entity_property_id) === 'dropdown'" bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('filters_value_' + filter.id)">
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on">
                                                                <v-autocomplete
                                                                    v-model="filter.value"
                                                                    :items="local_filter_value_list(filter.entity_property_id)"
                                                                    item-text="name"
                                                                    item-value="id"
                                                                    placeholder="Select Value"
                                                                    class="pa-0 u-border-transparent u-rounded-corners-lg u-shadow"
                                                                    :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('filters_value_' + filter.id) }]"
                                                                    item-color="indigo darken-2"
                                                                    background-color="white"
                                                                    :menu-props="{ rounded: 'lg', nudgeBottom: '4px', closeOnClick: true }"
                                                                    key="value-dropdown-field"
                                                                    @input="local_change_filter_value_fields(filter, 'value')"
                                                                    solo flat hide-details
                                                                >
                                                                    <template #item="{ item }">
                                                                        <span :title="item.name">{{ item.name | truncateText(20) }}</span>
                                                                    </template>
                                                                    <template #prepend-inner>
                                                                        <a-icon size="18" color="grey darken-2" class="mr-1" :outlined="local_get_icon(filter.id, 'outlined')">{{ local_get_icon(filter.id, 'text') }}</a-icon>
                                                                    </template>
                                                                    <template #append>
                                                                        <a-icon size="20" color="grey darken-2" class="mr-1" v-if="!filter.value">arrow_drop_down</a-icon>
                                                                        <a-icon size="18" color="grey darken-2" class="mr-1" v-else @click="local_change_filter_value_fields(filter, 'value', true)">clear</a-icon>
                                                                    </template>
                                                                </v-autocomplete>
                                                            </div>
                                                        </template>
                                                        <span>{{ local_get_validation_error('filters_value_' + filter.id, 'message') }}</span>
                                                    </a-tooltip>
                                                    <a-tooltip v-else bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('filters_value_' + filter.id)">
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on">
                                                                <v-text-field
                                                                    v-model="filter.value"
                                                                    placeholder="Enter Value"
                                                                    class="pa-0 u-border-transparent u-rounded-corners-lg u-shadow"
                                                                    :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('filters_value_' + filter.id) }]"
                                                                    item-color="indigo darken-2"
                                                                    background-color="white'"
                                                                    key="value-text-field"
                                                                    @input="local_change_filter_value_fields(filter, 'value')"
                                                                    solo flat hide-details
                                                                >
                                                                    <template #prepend-inner>
                                                                        <span class="grey--text text--darken-1 md-subtitle-1 font-weight-medium" style="margin-left: 2px; margin-right: 6px;" v-if="local_get_entity_key(filter.entity_property_id) === 'deal_amount'">
                                                                            {{ local_currency_symbol }}
                                                                        </span>
                                                                        <a-icon size="18" color="grey darken-2" class="mr-1" v-else :outlined="local_get_icon(filter.id, 'outlined')">{{ local_get_icon(filter.id, 'text') }}</a-icon>
                                                                    </template>
                                                                    <template #append>
                                                                        <a-icon size="18" color="grey darken-2" class="mr-1" v-if="filter.value" @click="local_change_filter_value_fields(filter, 'value', true)">clear</a-icon>
                                                                    </template>
                                                                </v-text-field>
                                                            </div>
                                                        </template>
                                                        <span>{{ local_get_validation_error('filters_value_' + filter.id, 'message') }}</span>
                                                    </a-tooltip>
                                                </template>
                                            </td>
                                        </template>
                                    </template>

                                    <!-- Btn to remove row -->
                                    <td class="text-left pb-4" style=" width: 16px;">
                                        <a-tooltip bottom v-if="filter_delete_id === filter.id">
                                            <template v-slot:activator="{ on }">
                                                <a-icon size="16" color="red darken-1" class="ml-2" v-on="on" @click="local_remove_filter_row(filter)">cancel</a-icon>
                                            </template>
                                            <span>Confirm Delete?</span>
                                        </a-tooltip>
                                        <a-icon size="16" v-else color="grey darken-1" class="ml-2" @click="local_confirm_filter_remove(filter.id)">cancel</a-icon>
                                    </td>
                                </tr>

                                <!-- Add condition / group -->
                                <tr>
                                    <td colspan="100" class="text-left" style="">
                                        <div class="u-flex-center-y mt-2">
                                            <a-btn depressed small rounded class="u-flex-center deep-purple darken-1 white--text" @click="local_add_filter(config_filter.group_id)">
                                                <a-icon size="16" color="white" class="u-icon-nudge-xs mr-1">add</a-icon>
                                                <span>Add Condition</span>
                                            </a-btn>
                                            <a-spacer></a-spacer>
                                            <a-btn depressed small rounded class="u-flex-center primary lighten-1 white--text" @click="local_add_filter('new', group_index)">
                                                <a-icon size="16" color="white" class="u-icon-nudge-xs mr-1">add</a-icon>
                                                <span>Add Group</span>
                                            </a-btn>
                                            <a-tooltip bottom v-if="filter_delete_id === config_filter.group_id">
                                                <template v-slot:activator="{ on }">
                                                    <a-icon size="16" color="red darken-1" class="ml-3" v-on="on" @click="local_remove_filter_row(config_filter, 'group')">delete</a-icon>
                                                </template>
                                                <span>Confirm Delete?</span>
                                            </a-tooltip>
                                            <a-icon size="16" color="grey darken-1" v-else class="ml-3" @click="local_confirm_filter_remove(config_filter.group_id, 'group')">delete</a-icon>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <!-- Add condition -->
                            <a-btn depressed small rounded class="u-flex-center deep-purple darken-1 white--text mt-5" v-if="tile_filters.original && !tile_filters.original.length" @click="local_add_filter('new')">
                                <a-icon size="16" color="white" class="u-icon-nudge-xs mr-1">add</a-icon>
                                <span>Add Condition</span>
                            </a-btn>
                        </div>
                    </div>
                    <a-sheet v-if="selected_tile_module.slug === 'datapoints'" class="transparent" max-width="656">
                        <template v-if="local_check_loading('tile_datapoints_load') || local_check_loading('tile_edit_show_load')">
                            <div class="my-6 u-flex-center">
                                <a-progress-circular width="2" size="14" color="grey darken-1" indeterminate></a-progress-circular>
                                <span class="md-subtitle-2 grey--text text--darken-1 ml-2">Loading..</span>
                            </div>
                        </template>
                        <div v-else class="u-flex-center-y flex-wrap u-wfull mb-6">
                            <template v-if="!['Pie', 'Donut', 'Numbers'].includes(selected_tile.chart_type)">
                                <a-sheet class="mr-2 transparent" :width="!local_is_barline_charts ? '100%' : '314px'">
                                    <div class="md-body-1 body--text text--lighten-1 mb-3 font-weight-medium">X-Axis</div>
                                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('datapoints_xaxis')">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="d-inline-flex" style="width: 314px;">
                                                <a-select
                                                    v-model="tile_datapoints.original.xaxis"
                                                    :items="xaxis_list"
                                                    placeholder="Ex: X-Axis"
                                                    class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                                    :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('datapoints_xaxis') }]"
                                                    item-color="indigo darken-1"
                                                    background-color="white"
                                                    :append-icon="!tile_datapoints.original.xaxis ? 'arrow_drop_down' : ''"
                                                    :menu-props="{ rounded: 'lg', nudgeBottom: '4px', offsetY: true, closeOnContentClick: true, closeOnClick: true, }"
                                                    @input="local_update_datapoint_changes('Datapoints', 'xaxis', tile_datapoints.original.xaxis)"
                                                    @click:clear="local_clear_datapoint_fields('xaxis')"
                                                    solo flat hide-details clearable
                                                ></a-select>
                                            </div>
                                        </template>
                                        <span>{{ local_get_validation_error('datapoints_xaxis', 'message') }}</span>
                                    </a-tooltip>
                                </a-sheet>
                                <a-sheet :class="[{ 'mt-6': !local_is_barline_charts }, 'mr-2 transparent']" width="314px" v-if="!local_is_barline_charts">
                                    <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">Grouping (Y-Axis)</span>
                                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('datapoints_y_axis_group')">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <a-select
                                                    v-model="tile_datapoints.original.y_axis_group"
                                                    :items="dpl_primary_slot"
                                                    placeholder="Ex: Project > Health"
                                                    class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                                    :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('datapoints_y_axis_group') }]"
                                                    item-color="indigo darken-1"
                                                    background-color="white"
                                                    :append-icon="!tile_datapoints.original.y_axis_group ? 'arrow_drop_down' : ''"
                                                    :menu-props="{ rounded: 'lg', nudgeBottom: '4px', offsetY: true, closeOnContentClick: true, closeOnClick: true, }"
                                                    @input="local_update_datapoint_changes('Datapoints', 'y_axis_group', tile_datapoints.original.y_axis_group)"
                                                    @click:clear="local_clear_datapoint_fields('y_axis_group')"
                                                    solo flat hide-details clearable
                                                ></a-select>
                                            </div>
                                        </template>
                                        <span>{{ local_get_validation_error('datapoints_y_axis_group', 'message') }}</span>
                                    </a-tooltip>
                                </a-sheet>
                                <a-sheet :class="[{ 'mt-6': !local_is_barline_charts }, 'ml-2 transparent']" width="314px">
                                    <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">Value (Y-Axis)</span>
                                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('datapoints_y_axis_value')">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <a-select
                                                    v-model="tile_datapoints.original.y_axis_value"
                                                    :items="aggregate_datapoints_list"
                                                    placeholder="Ex: Count of Project"
                                                    class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                                    :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('datapoints_y_axis_value') }]"
                                                    item-color="indigo darken-1"
                                                    background-color="white"
                                                    :append-icon="!tile_datapoints.original.y_axis_value ? 'arrow_drop_down' : ''"
                                                    :menu-props="{ rounded: 'lg', nudgeBottom: '4px', offsetY: true, closeOnContentClick: true, closeOnClick: true, }"
                                                    @input="local_update_datapoint_changes('Datapoints', 'y_axis_value', tile_datapoints.original.y_axis_value)"
                                                    @click:clear="local_clear_datapoint_fields('y_axis_value')"
                                                    solo flat hide-details clearable
                                                ></a-select>
                                            </div>
                                        </template>
                                        <span>{{ local_get_validation_error('datapoints_y_axis_value', 'message') }}</span>
                                    </a-tooltip>
                                </a-sheet>
                            </template>
                            <template v-else>
                                <a-sheet class="mr-2 transparent" width="314">
                                    <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">
                                        {{ selected_tile.chart_type === 'Numbers' ? 'Primary Slot' : 'Category' }}
                                    </span>
                                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('datapoints_primary_slot')">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <a-select
                                                    v-model="tile_datapoints.original.primary_slot"
                                                    :items="local_get_primary_slot_list()"
                                                    :placeholder="`Ex: ${ selected_tile.chart_type === 'Numbers' ? 'Count of Project' : 'Project > Health'}`"
                                                    class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                                    :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('datapoints_primary_slot') }]"
                                                    item-color="indigo darken-1"
                                                    background-color="white"
                                                    :append-icon="!tile_datapoints.original.primary_slot ? 'arrow_drop_down' : ''"
                                                    :menu-props="{ rounded: 'lg', nudgeBottom: '4px', offsetY: true, closeOnContentClick: true, closeOnClick: true, }"
                                                    @input="local_update_datapoint_changes('Datapoints', 'primary_slot', tile_datapoints.original.primary_slot)"
                                                    @click:clear="local_clear_datapoint_fields('primary_slot')"
                                                    solo flat hide-details clearable
                                                ></a-select>
                                            </div>
                                        </template>
                                        <span>{{ local_get_validation_error('datapoints_primary_slot', 'message') }}</span>
                                    </a-tooltip>
                                </a-sheet>
                                <a-sheet class="ml-2 transparent" width="320">
                                    <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">
                                        {{ selected_tile.chart_type === 'Numbers' ? 'Secondary Slot' : 'Value' }}
                                    </span>
                                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_validation_error('datapoints_secondary_slot')">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <a-select
                                                    v-model="tile_datapoints.original.secondary_slot"
                                                    :items="local_get_secondary_slot_list()"
                                                    :placeholder="`Ex: ${ selected_tile.chart_type === 'Numbers' ? 'Sum of project deal amount' : 'Count of Project'}`"
                                                    class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                                    :class="[{ 'c-wiggle-short u-border-error': local_get_validation_error('datapoints_secondary_slot') }]"
                                                    item-color="indigo darken-1"
                                                    background-color="white"
                                                    :append-icon="!tile_datapoints.original.secondary_slot ? 'arrow_drop_down' : ''"
                                                    :menu-props="{ rounded: 'lg', nudgeBottom: '4px', offsetY: true, closeOnContentClick: true, closeOnClick: true, }"
                                                    @input="local_update_datapoint_changes('Datapoints', 'secondary_slot', tile_datapoints.original.secondary_slot)"
                                                    @click:clear="local_clear_datapoint_fields('secondary_slot')"
                                                    solo flat hide-details clearable
                                                ></a-select>
                                            </div>
                                        </template>
                                        <span>{{ local_get_validation_error('datapoints_secondary_slot', 'message') }}</span>
                                    </a-tooltip>
                                </a-sheet>
                            </template>
                        </div>
                    </a-sheet>
                </div>
            </a-sheet>
        </a-sheet>
    </a-card>
</template>

<script>
export default {

}
</script>

<style>

</style>

import { mapActions } from 'vuex'

export default {
    
    mounted() {
        this.local_template_import_status_mixin()
    },

    methods: {
        async local_template_import_status_mixin() {
            await this.project_template_import_show({
                id: this.$route.params.id,
                params: { 
                    'fields[project_template_imports]': 'id,project_id',
                }
            })
        },

        ...mapActions('ProjectTemplateImport', {
            project_template_import_show: 'show',
            project_template_import_clear_item: 'clear_item',
        }),
    }
}
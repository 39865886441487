<template>
    <div>
        <template v-if="!expandState">
            <div class="pa-5 pb-0">
                <loader-template height="24" width="24" class="u-rounded-corners-lg"></loader-template>
                <loader-template height="24" width="24" class="u-rounded-corners-lg mt-8"></loader-template>
                <loader-template height="24" width="24" class="u-rounded-corners-lg mt-4"></loader-template>
                <loader-template height="24" width="24" class="u-rounded-corners-lg mt-4"></loader-template>
                <loader-template height="24" width="24" class="u-rounded-corners-lg mt-4"></loader-template>
                <loader-template height="24" width="24" class="u-rounded-corners-lg mt-4"></loader-template>
                <loader-template height="24" width="24" class="u-rounded-corners-lg mt-4"></loader-template>
            </div>

            <loader-template height="1" width="100%" class="u-rounded-corners-lg mt-10 mb-3"></loader-template>
            <div class="pa-5 pt-0">
                <loader-template height="24" width="24" class="u-rounded-corners-lg mt-4"></loader-template>
                <loader-template height="24" width="24" class="u-rounded-corners-lg mt-4"></loader-template>
                <loader-template height="24" width="24" class="u-rounded-corners-lg mt-4"></loader-template>
                <loader-template height="24" width="24" class="u-rounded-corners-lg mt-4"></loader-template>
                <a-divider ></a-divider>
            </div>
        </template>

        <template v-if="expandState">
            <loader-template height="30" width="100%" class="u-rounded-corners-lg"></loader-template>
            <loader-template height="30" width="100%" class="u-rounded-corners-lg mt-8"></loader-template>
            <loader-template height="30" width="100%" class="u-rounded-corners-lg mt-4"></loader-template>
            <loader-template height="30" width="100%" class="u-rounded-corners-lg mt-4"></loader-template>
            <loader-template height="30" width="100%" class="u-rounded-corners-lg mt-4"></loader-template>
            <loader-template height="30" width="100%" class="u-rounded-corners-lg mt-4"></loader-template>
            <loader-template height="30" width="100%" class="u-rounded-corners-lg mt-4"></loader-template>

            <loader-template height="14" width="30%" class="u-rounded-corners-lg mt-10 mb-3"></loader-template>
            <loader-template height="30" width="100%" class="u-rounded-corners-lg mt-4"></loader-template>
            <loader-template height="30" width="100%" class="u-rounded-corners-lg mt-4"></loader-template>
            <loader-template height="30" width="100%" class="u-rounded-corners-lg mt-4"></loader-template>
            <loader-template height="30" width="100%" class="u-rounded-corners-lg mt-4"></loader-template>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        expandState: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<template>
	<div>
        <a-container container--fluid grid-list-xl class="pt-2 px-8">
            <a-layout wrap align-center>
                <a-flex shrink class="pb-0">
                    <div class="u-rounded-corners-lg u-border mb-2">
                        <a-btn color="grey darken-2" class="ma-0 pl-0" small text :to="{name: 'projects-usecases'}" :exact="true">
                            <a-icon left class="mx-2" size="20">arrow_back</a-icon>
                            Back to scope
                        </a-btn>
                    </div>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink class="pb-0">
                    <div class="u-rounded-corners-lg u-border mb-2">
                        <a-btn color="grey darken-2" class="green--text text--darken-2 ma-0" small text @click="local_format()">
                            <a-icon left size="16" class="mr-2">cloud_download</a-icon>
                            Download Format
                        </a-btn>
                    </div>
                </a-flex>
            </a-layout>
            <a-layout wrap align-start>
                <a-flex xs12 class="pb-0">
                    <h2 class="md-heading-5 mb-1">Import Use Cases, Test Cases and Success Criteria from CSV</h2>
                    <p class="md-body-2 grey--text text--darken-2 mb-0">You can import multiple use cases, test cases and success criteria through a CSV file. Success will process the uploaded file and import the data into temporary queue. Please make sure you have data columns in the right order as specified in sample format. After the upload is complete, you can review the data that system parsed and import into the project.</p>
                </a-flex>
                <a-flex shrink>
                    <div class="u-rounded-corners-lg u-border">
                        <a-btn depressed color="white" class="blue--text text--darken-2 mx-0 px-3" small @click="local_initiate()" :disabled="loading_file">
                            <a-icon left size="16" class="mr-2">arrow_upward</a-icon> Upload New File
                        </a-btn>
                    </div>
                    <input type="file" ref="csv_file" name="csv_file" class="d-none" @change="local_store()" accept=".csv">
                </a-flex>
                <a-flex shrink v-if="usecase_upload_list && usecase_upload_list.length > 0">
                    <div class="u-rounded-corners-lg u-border">
                        <a-btn color="grey" class="grey--text text--darken-2 mx-0 px-3" text small @click="local_destroy(usecase_upload_list[0].id)">
                            <a-icon left size="16" class="mr-2">clear</a-icon> Clear Uploaded File
                        </a-btn>
                    </div>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink v-if="usecase_upload_list && usecase_upload_list.length > 0 && usecase_upload_list[0].error_count === 0">
                    <div class="u-rounded-corners-lg u-border">
                        <a-btn depressed color="green darken-2" class="white--text mx-0 px-3" small @click="local_save(usecase_upload_list[0].id)" :disabled="loading_save" :loading="loading_save">
                            <a-icon left size="16" class="mr-1">done_all</a-icon> Save {{ usecase_upload_list[0].usecases_count }} Use cases
                        </a-btn>
                    </div>
                </a-flex>
            </a-layout>
            <a-layout align-start>
                <a-flex xs12>
                    <a-card flat class="blue darken-1 u-rounded-corners u-overflow-hidden mb-3" v-if="loading_file">
                        <a-card-text class="pa-3">
                            <a-layout align-center>
                                <a-flex shrink>
                                    <a-progress-circular indeterminate color="white" size="20" width="2"></a-progress-circular>
                                </a-flex>
                                <a-flex class="pl-0">
                                    <span class="md-body-2 white--text">File upload is currently under progress.</span>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                    <a-card flat class="red darken-2 u-rounded-corners u-overflow-hidden mb-3" v-if="usecase_upload_response.status === 'error'">
                        <a-card-text class="pa-3">
                            <a-layout align-center>
                                <a-flex>
                                    <a-icon color="white" class="u-icon-nudge mr-2" size="20">error</a-icon> <span class="md-body-2 white--text">{{ usecase_upload_response.text }}</span>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>

                    <a-card flat class="u-border u-rounded-corners u-overflow-hidden mb-3" v-if="usecase_upload_list && usecase_upload_list.length > 0">
                        <a-card-text class="pa-3">
                            <a-layout align-center>
                                <a-flex>
                                    <span>
                                        <a-icon color="grey darken-1" class="u-icon-nudge" style="top: 0px;" size="16">check_circle</a-icon> <span class="md-body-2">{{ usecase_upload_list[0].processed_count }} rows processed</span>
                                    </span>
                                    <span v-if="usecase_upload_list[0].error_count > 0">
                                        <a-icon color="red darken-2" class="ml-2 u-icon-nudge" style="top: -1px;" size="16">error</a-icon> <span class="md-body-2">{{ usecase_upload_list[0].error_count }} rows with error</span>
                                    </span>
                                    <span v-if="usecase_upload_list[0].skipped_count > 0">
                                        <a-icon color="yellow darken-2" class="ml-2 u-icon-nudge" style="top: -1px;" size="16">warning</a-icon> <span class="md-body-2">{{ usecase_upload_list[0].skipped_count }} rows will be skipped</span>
                                    </span>
                                </a-flex>
                                <a-flex shrink v-if="usecase_upload_list[0].error_count === 0">
                                    <span class="md-body-2 text-xs-right">{{ usecase_upload_list[0].usecases_count }} use cases with {{ usecase_upload_list[0].testcases_count }} test cases and {{ usecase_upload_list[0].goals_count }} success criteria</span>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>

                    <a-card flat text class="neutral pa-0 ma-0" v-if="usecase_upload_list && usecase_upload_list.length > 0">
                        <table class="csv-table">
                            <thead>
                                <tr class="csv-table-row-header">
                                    <th></th>
                                    <th class="csv-table-column-identifier">A</th>
                                    <th class="csv-table-column-type">B</th>
                                    <th class="csv-table-column-title">C</th>
                                    <th class="csv-table-column-description">D</th>
                                    <th class="csv-table-column-info">&bull;</th>
                                </tr>
                                <tr class="csv-table-row-title">
                                    <th class="csv-table-cell-number">1</th>
                                    <th class="text-center">#</th>
                                    <th class="text-center">Type</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th class="text-center"><a-icon color="grey darken-2" size="16">radio_button_unchecked</a-icon></th>
                                </tr>
                            </thead>
                            <tbody class="csv-table-body-group" v-for="(items, group) in local_group_by(usecase_upload_list[0].items, 'group')" :key="'table_group_'+group">
                                <tr v-for="usecase_upload_item in items" :key="'table_row_'+usecase_upload_item.id" :class="'csv-table-row-data ' + 'csv-table-row-status-' + usecase_upload_item.status">
                                    <td class="csv-table-cell-number">{{ usecase_upload_item.row }}</td>
                                    <td :class="{'csv-table-cell-error': local_get(usecase_upload_item, 'error.#') && local_get(usecase_upload_item, 'error.#').length > 0}" class="text-center">
                                        <a-tooltip bottom v-if="local_get(usecase_upload_item, 'error.#') && local_get(usecase_upload_item, 'error.#').length > 0">
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on" class="d-block" style="width: 100%; height: 24px;">
                                                    {{ usecase_upload_item.identifier }}
                                                </span>
                                            </template>
                                            <span>{{ local_get(usecase_upload_item, 'error.#')[0] }}</span>
                                        </a-tooltip>
                                        <template v-else>
                                            {{ usecase_upload_item.identifier }}
                                        </template>
                                    </td>
                                    <td :class="{'csv-table-cell-error': local_get(usecase_upload_item, 'error.Type') && local_get(usecase_upload_item, 'error.Type').length > 0}" class="text-center">
                                        <a-tooltip bottom v-if="local_get(usecase_upload_item, 'error.Type') && local_get(usecase_upload_item, 'error.Type').length > 0">
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on" class="d-block" style="width: 100%; height: 24px;">
                                                    {{ usecase_upload_item.type }}
                                                </span>
                                            </template>
                                            <span>{{ local_get(usecase_upload_item, 'error.Type')[0] }}</span>
                                        </a-tooltip>
                                        <template v-else>
                                            {{ usecase_upload_item.type }}
                                        </template>
                                    </td>
                                    <td :class="{'csv-table-cell-error': local_get(usecase_upload_item, 'error.Title') && local_get(usecase_upload_item, 'error.Title').length > 0}">
                                        <a-tooltip bottom v-if="local_get(usecase_upload_item, 'error.Title') && local_get(usecase_upload_item, 'error.Title').length > 0">
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on" class="d-block" style="width: 100%; height: 24px;">
                                                    {{ usecase_upload_item.title }}
                                                </span>
                                            </template>
                                            <span>{{ local_get(usecase_upload_item, 'error.Title')[0] }}</span>
                                        </a-tooltip>
                                        <template v-else>
                                            {{ usecase_upload_item.title }}
                                        </template>
                                    </td>
                                    <td :class="{'csv-table-cell-error': local_get(usecase_upload_item, 'error.Description') && local_get(usecase_upload_item, 'error.Description').length > 0}">
                                        <a-tooltip bottom v-if="local_get(usecase_upload_item, 'error.Description') && local_get(usecase_upload_item, 'error.Description').length > 0">
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on" class="d-block" style="width: 100%; height: 24px;">
                                                    {{ local_text_truncate(usecase_upload_item.description, '100', '...') }}
                                                </span>
                                            </template>
                                            <span>{{ local_get(usecase_upload_item, 'error.Description')[0] }}</span>
                                        </a-tooltip>
                                        <template v-else>
                                            {{ local_text_truncate(usecase_upload_item.description, '100', '...') }}
                                        </template>
                                    </td>
                                    <td class="text-center">
                                        <a-tooltip bottom left>
                                            <template v-slot:activator="{ on }">
                                                <a-icon v-on="on" v-if="usecase_upload_item.status === 'invalid'" color="red darken-2" class="u-cursor-pointer" size="16">error</a-icon>
                                                <a-icon v-on="on" v-if="usecase_upload_item.status === 'skipped'" color="yellow darken-2" class="u-cursor-pointer" size="16">warning</a-icon>
                                                <a-icon v-on="on" v-if="usecase_upload_item.status === 'valid'" color="green darken-2" class="u-cursor-pointer" size="16">check_circle</a-icon>
                                            </template>
                                            <span v-if="usecase_upload_item.status === 'invalid'">
                                                This row of data has some issues. Mouse over on the highlighted cells for more information.
                                            </span>
                                            <span v-if="usecase_upload_item.status === 'skipped'">
                                                This row will be skipped due to lack of data.
                                            </span>
                                            <span v-if="usecase_upload_item.status === 'valid'">
                                                All good.
                                            </span>
                                        </a-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container class="pt-4 mt-4"></a-container>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            loading_file: false,
            loading_save: false,
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('UsecaseUpload', {
            usecase_upload_list: 'list',
            usecase_upload_response: 'response',
            usecase_upload_filters: 'filters',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('usecases.store') == false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.usecase_upload_clear_item()
            await this.local_fetch();
        },
        async local_fetch() {
            await this.usecase_upload_clear()
            await this.usecase_upload_index({ 'filter[project_id]': this.$route.params.id, 'include': 'items' })
        },
        async local_initiate() {
            this.$refs.csv_file.value = null
            this.$refs.csv_file.click()
        },
        async local_store() {
            if (this.$refs.csv_file.files && this.$refs.csv_file.files.length > 0) {
                this.loading_file = true
                await this.usecase_upload_clear()
                await this.usecase_upload_store({project_id: this.$route.params.id, csv_file: this.$refs.csv_file.files[0]})
                if (this.usecase_upload_response.status == 'success') {
                    await this.local_fetch();
                }
                this.loading_file = false
            }
        },
        async local_destroy(id) {
            this.$refs.csv_file.value = null;
            await this.usecase_upload_destroy({id: id})
        },
        async local_save(id) {
            this.loading_save = true;
            await this.usecase_upload_save({id: id})
            if (this.usecase_upload_response.status == 'success') {
                this.$router.push({name: 'projects-usecases'})
            }
            this.loading_save = false;
        },
        async local_format() {
            await this.usecase_upload_format()
        },
        local_text_truncate(text, length, separator) {
            return _.truncate(text, {length: length, separator: separator});
        },
        local_group_by(collection, property) {
            return _.groupBy(collection, property)
        },
        local_get(item, property) {
            return _.get(item, property)
        },
        ...mapActions('UsecaseUpload', {
            usecase_upload_index: 'index',
            usecase_upload_store: 'store',
            usecase_upload_save: 'save',
            usecase_upload_format: 'format',
            usecase_upload_destroy: 'destroy',
            usecase_upload_clear: 'clear',
            usecase_upload_clear_item: 'clear_item',
        }),
    },
}
</script>

<style scoped>
.csv-table {
    border-collapse: collapse !important;
    border-spacing: 5px;
    width: 100%;
}

.csv-table-row-header th {
    background-color: #f8f9fb;
    border: 1px solid #ddd;
    color: #878384;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    padding: 4px;
}

.csv-table-row-title th:not(.csv-table-cell-number) {
    border: 1px solid #ddd;
    background-color: #fff;
    color: #222;
    font-weight: 700;
    font-size: 14px;
    text-align: left;
    padding: 4px;
}

.csv-table-cell-number {
    background-color: #f8f9fb;
    border: 1px solid #ddd;
    width: 48px;
    height: 32px;
    color: #878384;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.csv-table-row-data td:not(.csv-table-cell-number) {
    border: 1px solid #dddddd;
    background-color: #fff;
    color: #222;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    padding: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 0;
}

.csv-table-body-group::before {
    display: block;
    line-height: 24px;
    content: "\200C";
}

.csv-table-row-status-invalid td {
    background-color: #ffebee !important;
}

.csv-table-row-status-skipped td {
    background-color: #fff3e0 !important;
}

.csv-table-column-identifier {
    width: 48px;
}

.csv-table-column-type {
    width: 48px;
}

.csv-table-column-title {
    width: calc(25% - 144px);
}

.csv-table-column-info {
    width: 48px;
}

.csv-table-row-status-invalid .csv-table-cell-error {
    border: 2px dashed red !important;
}

.csv-table-row-status-skipped .csv-table-cell-error {
    border: 2px dashed orange !important;
}
</style>

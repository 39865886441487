import { storage } from '../helpers/helper-local-storage'
import { get } from 'lodash'

const PlanAuthorizer = {
    install(Vue, { store }) {
        Vue.prototype.$plan = (ability) => {
            const { features: permissions } = get(store.state.Organization, 'plan.subscription_plan') || get(storage.get('orgPlan'), 'subscription_plan')
            const [module, action] = ability.split(':') // module_name:full-access OR module_name:no-access

            if (permissions) {
                const { pivot: { permission: value } } = _.find(permissions, { slug: module }) || {}

                if (value && !isNaN(parseInt(value))) return parseInt(value)
                return value === action
            }
        }
    }
}

export default PlanAuthorizer

<template>
    <a-layout align-start mt-0>
        <a-flex px-0 pt-0>
            <a-tabs class="mb-0" background-color="transparent" slider-color="primary">
                <a-tab ripple class="mx-2" :to="{name: 'settings-tokens'}">
                    <span class="md-body-2">Tokens</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-tokens-documentation'}">
                    <span class="md-body-2">Documentation</span>
                </a-tab>
            </a-tabs>
            <a-divider class="mx-2 grey lighten-2"></a-divider>
        </a-flex>
    </a-layout>
</template>

<script>
export default {

}
</script>

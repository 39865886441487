<template>
    <a-main>
        <!-- Payment failed info -->
        <PartialPaymentStatus v-if="mixinGetOrgPlan({ field: 'subscription_payment_status' }) === 'failed'">
            Oops! We are unable to process your payment. Please check your credentials to avoid downgrade.
        </PartialPaymentStatus>

        <!-- Payment pending info -->
        <PartialPaymentStatus v-if="mixinGetOrgPlan({ field: 'subscription_pending' }) === 1" class="orange darken-4">
            Payment pending! Please pay your overdues to continue using Success.
        </PartialPaymentStatus>

        <div class="my-6 u-flex u-wfull" style="column-gap: 1.5rem;">
            <a-sheet style="flex: 1 1 100%;" class="u-rounded-corners-lg u-shadow">
                <a-sheet min-height="250" class="px-8 pt-6 pb-6 u-relative" style="border-radius: 6px 6px 0px 0px">
                    <div class="u-flex-center-y">
                        <a-sheet class="u-rounded-corners-full" style="box-shadow: 0px 0px 1px 2px rgba(0,0,0,0.3) !important;" width="14" height="14" color="grey darken-2"></a-sheet>
                        <h3 class="ml-2 md-heading-6 font-weight-bold grey--text text--darken-2">Starter</h3>
                    </div>
                    <span class="mt-1 md-body-2 grey--text text--darken-1 d-inline-block font-weight-medium">Perfect plan with all essentials.</span>
                    <h2 class="mt-3 md-heading-5 primary--text font-weight-bold">Free</h2>
                    <a-btn
                        v-if="currentPlanLevel === 1 && !localIsDowngradeInProgress"
                        height="52"
                        class="mt-6 u-wfull grey lighten-2 u-rounded-corners-lg"
                        disabled depressed
                    >
                        <span class="md-subtitle-1 grey--text text--darken-3 font-weight-medium">Current Plan</span>
                    </a-btn>
                    <template v-if="localShowDowngradeInfo">
                        <a-sheet height="52" class="px-4 mt-6 u-flex-center u-wfull blue-grey lighten-5 u-rounded-corners-lg">
                            <a-icon size="18" color="blue-grey darken-2" class="mr-2">info</a-icon>
                            <span class="md-subtitle-2 blue-grey--text text--darken-1 font-weight-medium">You will be downgraded to Starter on <strong>{{ localDowngradeEndDate }}</strong></span>
                        </a-sheet>
                        <div
                            v-ripple
                            class="justify-center px-2 py-1 d-inline-flex align-center u-rounded-corners-full u-cursor-pointer u-absolute u-no-select"
                            style="bottom: 10px; left: 50%; transform: translateX(-50%);"
                            @click="localOpenCancelPopup()"
                        >
                            <a-icon size="18" color="indigo darken-2" class="mr-1">cancel</a-icon>
                            <span class="md-body-2 indigo--text text--darken-2">Cancel Downgrade</span>
                        </div>
                    </template>
                    <a-btn
                        v-if="currentPlanLevel === 2 && !localIsDowngradeInProgress"
                        :disabled="localIsDowngradeInProgress"
                        @click="!localIsDowngradeInProgress ? localDowngradeConfirm() : {}"
                        height="52"
                        class="mt-6 u-wfull blue-grey lighten-4 u-rounded-corners-lg"
                        dark depressed
                    >
                        <span class="md-subtitle-1 font-weight-medium blue-grey--text text--darken-1">Downgrade</span>
                    </a-btn>
                </a-sheet>
                <a-divider></a-divider>
                <div class="pt-6 pa-8 u-flex flex-column" style="row-gap: 1.25rem">
                    <template v-for="(feature, index) in localStarterFeatures">
                        <div class="u-flex-center-y" :key="index">
                            <a-icon size="18" color="green">check_circle</a-icon>
                            <h4 class="ml-2 md-subtitle-2 grey--text text--darken-2 font-weight-medium">{{ feature }}</h4>
                        </div>
                    </template>
                </div>
                <a-divider></a-divider>
                <div class="pa-1">
                    <a-btn depressed height="48" class="u-flex-center transparent u-wfull">
                        <div class="u-flex-center-y">
                            <span class="mr-2 blue--text md-body-2 font-weight-medium">See all features</span>
                            <a-icon size="16" color="blue">arrow_forward</a-icon>
                        </div>
                    </a-btn>
                </div>
            </a-sheet>

            <a-sheet style="flex: 1 1 100%;" class="u-rounded-corners-lg u-shadow">
                <a-sheet min-height="250" class="px-8 pt-6 pb-6 u-relative" style="border-radius: 6px 6px 0px 0px">
                    <div
                        v-if="localShowDowngradeInfo"
                        class="px-3 py-1 d-inline-flex align-center justify-center grey darken-3 u-rounded-corners-full u-absolute u-no-select"
                        style="top: 40px; right: 24px;"
                    >
                        <span class="md-caption font-weight-bold white--text">Ends {{ localDowngradeEndDateHuman }}</span>
                    </div>
                    <div class="u-flex-center-y">
                        <a-sheet class="u-rounded-corners" style="box-shadow: 0px 0px 1px 2px rgba(48,63,159,0.3) !important;" width="14" height="14" color="indigo darken-2"></a-sheet>
                        <h3 class="ml-2 md-heading-6 font-weight-bold indigo--text text--darken-2">Professional</h3>
                    </div>
                    <span class="mt-1 md-body-2 grey--text text--darken-1 d-inline-block font-weight-medium">For pro teams who want to do more.</span>
                    <h2 class="mt-3 md-heading-5 primary--text font-weight-bold">
                        ${{ localGetPlan(2, 'base_amount') || '' }}
                        <sup class="md-caption font-weight-medium">USD per month</sup>
                    </h2>
                    <a-btn
                        v-if="currentPlanLevel === 1"
                        @click="localUpgrade()"
                        :disabled="mixinGetOrgPlan({ field: 'subscription_pending' }) === 1"
                        :dark="!mixinGetOrgPlan({ field: 'subscription_pending' })"
                        class="mt-6 u-wfull indigo darken-2 u-rounded-corners-lg"
                        height="52"
                        depressed
                    >
                        <span class="md-subtitle-1 font-weight-medium">Upgrade to Professional</span>
                    </a-btn>
                    <a-btn v-else height="52" class="mt-6 u-wfull grey lighten-2 u-rounded-corners-lg" disabled depressed>
                        <span class="md-subtitle-1 grey--text text--darken-3 font-weight-medium">Current Plan</span>
                    </a-btn>
                    <div class="text-center u-absolute" style="bottom: 16px; left: 50%; transform: translateX(-50%)">
                        <h4 class="text-center md-body-2 grey--text text--darken-1">
                            or <a href="https://calendly.com/pudding-app/sales" target="_blank" noreferrer noopener class="blue--text font-weight-medium">Contact sales</a> for
                            <span class="grey--text text--darken-4 font-weight-medium">Enterprise</span> pricing
                        </h4>
                    </div>
                </a-sheet>
                <a-divider></a-divider>
                <div class="pt-6 pa-8 u-flex flex-column" style="row-gap: 1.25rem">
                    <div class="u-flex-center-y">
                        <a-icon size="18" color="indigo">add_circle</a-icon>
                        <h4 class="ml-2 md-subtitle-2 grey--text text--darken-2 font-weight-medium">Everything in Starter</h4>
                    </div>
                    <template v-for="(feature, index) in localProfessionalFeatures">
                        <div class="u-flex-center-y" :key="index">
                            <a-icon size="18" color="green">check_circle</a-icon>
                            <h4 class="ml-2 md-subtitle-2 grey--text text--darken-2 font-weight-medium">{{ feature }}</h4>
                        </div>
                    </template>
                </div>
                <a-divider></a-divider>
                <div class="pa-1">
                    <a-btn depressed height="48" class="u-flex-center transparent u-wfull">
                        <div class="u-flex-center-y">
                            <span class="mr-2 blue--text md-body-2 font-weight-medium">See all features</span>
                            <a-icon size="16" color="blue">arrow_forward</a-icon>
                        </div>
                    </a-btn>
                </div>
            </a-sheet>
        </div>

        <a-dialog v-model="dialogConfirmDowngrade" max-width="450">
            <PartialDowngradeConfirm
                @close="dialogConfirmDowngrade = false"
            />
        </a-dialog>

        <a-dialog v-model="dialogCancelDowngrade" max-width="350">
            <PartialCancelDowngrade
                class="pa-6"
                :isLoading="cancelDowngradeLoading"
                @action="localCancelPlanDowngrade()"
                @close="dialogCancelDowngrade = false"
            />
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ApiUrl } from '@/helpers/helper-api-url-constructor'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'
import PartialDowngradeConfirm from './Partials/PartialDowngradeConfirm.vue'
import PartialCancelDowngrade from './Partials/PartialCancelDowngrade.vue'
import PartialPaymentStatus from './Partials/PartialPaymentStatus.vue'

export default {
    mixins: [mixinGetOrganizationPlan],

    components: {
        PartialPaymentStatus,
        PartialCancelDowngrade,
        PartialDowngradeConfirm,
    },

    data () {
        return {
            local_loading: true,
            currentPlanLevel: null,
            dialogCancelDowngrade: false,
            dialogConfirmDowngrade: false,
            cancelDowngradeLoading: false,
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localIsDowngradeInProgress () {
            return this.mixinGetOrgPlan({ field: 'subscription_schedule' }) === 'in_progress'
        },

        localDowngradeEndDateHuman () {
            const endDate = this.mixinGetOrgPlan({ field: 'subscription_plan_end_date' })
            return moment(endDate).fromNow()
        },

        localDowngradeEndDate () {
            const endDate = this.mixinGetOrgPlan({ field: 'subscription_plan_end_date' })
            return moment(endDate).format('MMM D, YYYY')
        },

        localShowDowngradeInfo () {
            return [1, 2].includes(this.currentPlanLevel) && this.localIsDowngradeInProgress
        },

        localStarterFeatures () {
            return [
                `Free ${this.localGetPlan(1, 'unit_amount') || ''} active POCs`,
                'Unlimited free users included',
                'Presales Collaboration & Management',
                'Reports & Insights',
                'Automation & Validation',
                'Platform Management',
                'Basic Integrations'
            ]
        },

        localProfessionalFeatures () {
            return [
                `Free 10 active POCs + $99 / POC / month thereafter`,
                'Success to Salesforce Data Sync',
                'Success to HubSpot Data Sync',
                'Jira Integration',
                'APIs & Webhooks',
                'Single Sign On'
            ]
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('SystemSubscriptionPlans', {
            subscription_plan_list: 'list',
        }),
    },

    methods: {
        async localIndex() {
            if (this.user_self && this.user_self.is_billing !== 1) {
                return this.$router.replace({name: 'errors-unauthorized'})
            }
            this.$vuetify.goTo(0)
            await this.localFetchSubscriptionPlans()
            if (!this.mixinGetOrgPlan({ field: 'id' })) await this.mixinFetchOrgPlanDetails()
            this.currentPlanLevel = await this.mixinGetOrgPlan({ field: 'subscription_plan.level' })
            this.local_loading = false
        },

        async localFetchSubscriptionPlans () {
            if (_.size(this.subscription_plan_list)) return true

            await this.subscription_plans_index({
                'fields[subscription_plans]': 'id,level,name,unit_amount,base_amount'
            })
        },

        localUpgrade () {
            if (this.mixinGetOrgPlan({ field: 'subscription_pending' }) === 1) return

            window.location.href = ApiUrl(`/internal/subscription-plans/2?redirect_url=${window.location.href}`)
        },

        localDowngradeConfirm () {
            this.dialogConfirmDowngrade = true
        },

        localGetPlan (level, key = null) {
            const item = this.subscription_plan_list.find(i => i.level === level)
            if (!item) return ''

            return key ? item[key] : item
        },

        async localOpenCancelPopup () {
            this.dialogCancelDowngrade = true
        },

        async localCancelPlanDowngrade () {
            this.cancelDowngradeLoading = true
            await this.actionCancelDowngrade()
            this.dialogCancelDowngrade = false
            this.cancelDowngradeLoading = false
            window.location.reload()
        },

        ...mapActions('User', {
            user_me: 'me',
        }),

        ...mapActions('SubscriptionPlan', [
            'actionCancelDowngrade'
        ]),

        ...mapActions('SystemSubscriptionPlans', {
            subscription_plans_index: 'index',
        }),
    },
}
</script>

<template>
    <v-date-picker v-model="showModel" v-on="$listeners" v-bind="$attrs">
        <slot></slot>
    </v-date-picker>
</template>

<script>
import { VDatePicker } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VDatePicker },
    props: ['value'],

    data () {
        return {
            ref: null
        }
    },

    mounted () {
        this.ref = this.$el
    },

    computed: {
        showModel: {
            get () {
                return this.value
            },
            set (val) {
                this.$emit('update:value', val)
            }
        }
    }}
</script>

<template>
    <a-card class="my-2 u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
        <a-card-text class="pa-0">
            <a-container container--fluid grid-list-xl class="px-6 py-0">
                <a-layout align-center>
                    <a-flex md6 shrink py-0 class="u-cursor-pointer" @click="$emit('testcase-edit', true)">
                        <div class="u-flex align-center py-7" :class="check_permission('update') ? handle : ''">
                            <h2 class="md-subtitle-2 grey--text font-weight-regular text--darken-2">{{ testcase.title | truncateText(140) }} <span class="grey--text md-caption" v-if="testcase.code">({{ testcase.code }})</span></h2>
                        </div>
                    </a-flex>
                    <a-flex md6 shrink py-0>
                        <div class="u-flex align-center justify-end">
                            <div class="u-flex align-center u-cursor-pointer mr-5" @click="$emit('testcase-edit', true)" v-if="testcase.start_date || testcase.due_date">
                                <template v-if="testcase.start_date">
                                    <g-moment class="md-caption grey--text text--darken-1" :value="testcase.start_date" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                    <span class="mx-1"> - </span>
                                </template>
                                <span class="mx-1 md-caption grey--text text--darken-1" v-if="!testcase.start_date && testcase.due_date"> Due on </span>
                                <g-moment v-if="testcase.due_date" class="md-caption" :class="[local_check_due_date(testcase) ? 'red--text text--darken-2': 'grey--text text--darken-1']" :value="testcase.due_date" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                <span v-else class="md-caption grey--text text--darken-1">No Due Date</span>
                            </div>
                            <slot name="assignees"></slot>
                            <slot name="status"></slot>
                            <div class="u-cursor-pointer mx-2 ml-4" @click="$emit('open-timetracker', testcase)">
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a-icon v-on="on" class="u-rounded-corners-full" size="16">hourglass_top</a-icon>
                                    </template>
                                    <span class="md-body-2 font-weight-medium">
                                        {{ testcase.time_records_sum_duration_minutes ? local_get_time_text(testcase.time_records_sum_duration_minutes) : '0m' }}
                                        <template v-if="testcase.estimated_duration_text">
                                            / {{ testcase.estimated_duration_text }}
                                        </template>
                                    </span>
                                </a-tooltip>
                            </div>
                            <div v-if="check_permission('destroy')">
                                <a-btn v-if="local_destroy_item_id !== testcase.id" icon text small color="grey" @click="local_destroy_item_id = testcase.id" class="ma-0">
                                    <a-icon size="14">delete</a-icon>
                                </a-btn>
                                <a-tooltip bottom right v-if="local_destroy_item_id === testcase.id">
                                    <template v-slot:activator="{ on }">
                                        <a-btn icon text small color="red" class="ma-0" @click="local_testcase_destroy(testcase.id)" v-on="on">
                                            <a-icon color="red" size="14">delete</a-icon>
                                        </a-btn>
                                        </template>
                                    <span>Confirm?</span>
                                </a-tooltip>
                            </div>
                        </div>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-card-text>
    </a-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { calculateByMins } from '@/helpers/helper-time-tracker.js'

export default {
    props: {
        testcase: {
            type: Object
        },
        index: {
            type: Number
        },
        handle: {
            type: String,
            default: 'drag_handle_icon'
        }
    },

    data () {
        return {
            local_destroy_item_id: null,
            local_testcase_fields: {
                'filter[usecase_id]': this.$route.params.usecase_id,
                'fields[testcases]': 'id,estimated_duration_minutes,estimated_duration_text',
                'aggregate[time_records.duration_minutes]': 'sum'
            },
        }
    },

    computed: {
        ...mapState('Testcase', {
            testcase_item: 'item',
            testcase_form_mode: 'form_mode',
            testcase_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_testcase_show (id, mode) {
            await this.testcase_show({ id: id, mode: mode ? mode : this.testcase_form_mode , params: this.local_testcase_fields })
        },

        async local_testcase_destroy (id) {
            await this.testcase_destroy({id: id})
            if (this.testcase_response && this.testcase_response.status !== 'success') return
            this.$notify('success', 'Test Case deleted successfully!')
        },

        // Extras
        local_check_due_date ({ due_date, status }) {
            if (status) return moment(due_date).isBefore() && status.status === 'Open'
        },

        local_get_time_text (value) {
            const { durationText } = calculateByMins(value)
            return durationText
        },

        check_permission (type) {
            return this.$can(`usecases.${type}`) && this.$can('projects.update-only')
        },

        ...mapActions('Testcase', {
            testcase_show: 'show',
            testcase_update: 'update',
            testcase_destroy: 'destroy',
        }),
    }
}
</script>

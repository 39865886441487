<template>
    <a-card>
        <div class="u-flex-center-y px-6 py-5">
            <a-avatar class="primary darken-1" size="40">
                <a-icon size="24" color="white">work</a-icon>
            </a-avatar>
            <h2 class="text-h6 primary--text text--darken-1 font-weight-medium ml-3">Create New Competitor</h2>
            <a-spacer></a-spacer>
            <a-icon size="20" color="grey darken-1" @click="$emit('close')">clear</a-icon>
        </div>

        <a-divider></a-divider>

        <a-sheet max-height="500" class="c-main-content u-overflow-y pa-6">
            <!-- Name -->
            <div>
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                <a-text-field
                    v-model="competitorItem.name"
                    placeholder="Enter the name"
                    background-color="grey lighten-4"
                    class="u-border u-rounded-corners-lg"
                    solo flat hide-details autofocus
                    v-test-input.name
                >
                </a-text-field>
                <span class="md-caption red--text text--darken-2 u-flex-center-y mt-2" v-if="$response(competitor_response, 'name')">
                    <a-icon size="16" color="red darken-2" class="mr-1">warning</a-icon>
                    {{ $response(competitor_response, 'name') }}
                </span>
                <span class="md-caption grey--text text--darken-2 u-flex-center-y mt-2" v-else>
                    <a-icon size="16" class="mr-1">info</a-icon>
                    Helpful to identify the competitor.
                </span>
            </div>
            <!-- Domain -->
            <div class="mt-6">
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Domain</label>
                <a-text-field
                    v-model="competitorItem.domain"
                    placeholder="Eg: google.company.success.co.org"
                    background-color="grey lighten-4"
                    class="u-border u-rounded-corners-lg"
                    solo flat hide-details autofocus
                    v-test-input.domain
                    @input="local_competitor_domain_validate(competitorItem.domain)"
                >
                </a-text-field>
                <div class="mt-2 u-flex-center-y">
                    <span class="md-caption red--text text--darken-2 u-flex-center-y" v-if="error_domain_response">
                        <a-icon size="16" color="red darken-2" class="mr-1">warning</a-icon>
                        {{ error_domain_response }}
                    </span>
                    <span class="md-caption red--text text--darken-2 u-flex-center-y" v-else-if="$response(competitor_response, 'domain')">
                        <a-icon size="16" color="red darken-2" class="mr-1">warning</a-icon>
                        {{ $response(competitor_response, 'domain') }}
                    </span>
                    <span class="md-caption grey--text text--darken-2 u-flex-center-y" v-else>
                        <a-icon size="16" class="mr-1">info</a-icon>
                        The domain will be helpful to identify the competitor..
                    </span>
                    <a-spacer></a-spacer>
                    <span class="md-caption grey--text text--darken-1">(Optional)</span>
                </div>
            </div>
            <!-- link -->
            <div class="mt-6">
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Link</label>
                <a-text-field
                    v-model="competitorItem.link"
                    placeholder="Add a link"
                    background-color="grey lighten-4"
                    class="u-border u-rounded-corners-lg"
                    solo flat hide-details autofocus
                    v-test-input.domain
                    @input="local_competitor_link_validate(competitorItem.link)"
                >
                </a-text-field>
                <div class="mt-2 u-flex-center-y">
                     <span class="md-caption red--text text--darken-2 u-flex-center-y" v-if="error_link_response">
                        <a-icon size="16" color="red darken-2" class="mr-1">warning</a-icon>
                        {{ error_link_response }}
                    </span>
                    <span class="md-caption red--text text--darken-2 u-flex-center-y" v-else-if="$response(competitor_response, 'link')">
                        <a-icon size="16" color="red darken-2" class="mr-1">warning</a-icon>
                        {{ $response(competitor_response, 'link') }}
                    </span>
                    <span class="md-caption grey--text text--darken-2 u-flex-center-y" v-else>
                        <a-icon size="16" class="mr-1">info</a-icon>
                        The link will be helpful to identify the competitor..
                    </span>
                    <a-spacer></a-spacer>
                    <span class="md-caption grey--text text--darken-1">(Optional)</span>
                </div>
            </div>


            <!-- Domain -->
            <!-- <div class="mt-6">
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Domain</label>
                <a-text-field
                    v-model="competitorItem.domain"
                    placeholder="Enter the domain"
                    background-color="grey lighten-4"
                    class="u-border u-rounded-corners-lg"
                    solo flat hide-details
                    v-test-input.name
                >
                </a-text-field>
                <div class="mt-2 u-flex-center-y">
                    <span class="md-caption red--text text--darken-2 u-flex-center-y" v-if="$response(competitor_response, 'domain')">
                        <a-icon size="16" color="red darken-2" class="mr-1">warning</a-icon>
                        {{ $response(competitor_response, 'domain') }}
                    </span>
                    <span class="md-caption grey--text text--darken-2 u-flex-center-y" v-else>
                        <a-icon size="16" class="mr-1">info</a-icon>
                        Useful to define the industry
                    </span>
                    <a-spacer></a-spacer>
                    <span class="md-caption grey--text text--darken-1">(Optional)</span>
                </div>
            </div> -->

            <!-- Description -->
            <div class="mt-6">
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Description</label>
                <a-sheet class="grey lighten-4 u-rounded-corners-lg">
                    <g-editor-box
                        v-model="competitorItem.description_json"
                        :can-update="$can('competitors.update')"
                        :editorClass="['px-3 grey lighten-4']"
                        :customHeight="200"
                        :modelIsOpen="isOpen"
                        dense
                        hideCloseBtn hideFocus
                    ></g-editor-box>
                </a-sheet>
                <div class="mt-2 u-flex-center-y">
                    <span class="md-caption grey--text text--darken-2 u-flex-center-y">
                        <a-icon size="16" class="mr-1">info</a-icon>
                        Description about the competitor.
                    </span>
                    <a-spacer></a-spacer>
                    <span class="md-caption grey--text text--darken-1">(Optional)</span>
                </div>
            </div>

            <!-- Stength -->
            <div class="mt-6">
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Strengths</label>
                <a-textarea
                    v-model="competitorItem.strengths"
                    placeholder="Enter strengths"
                    background-color="grey lighten-4"
                    class="u-border u-rounded-corners-lg"
                    solo flat hide-details no-resize
                    v-test-input.strength
                >
                </a-textarea>
                <div class="mt-2 u-flex-center-y">
                    <span class="md-caption red--text text--darken-2 u-flex-center-y" v-if="$response(competitor_response, 'strengths')">
                        <a-icon size="16" color="red darken-2" class="mr-1">warning</a-icon>
                        {{ $response(competitor_response, 'strengths') }}
                    </span>
                    <span class="md-caption grey--text text--darken-2 u-flex-center-y" v-else>
                        <a-icon size="16" class="mr-1">info</a-icon>
                        Competitor’s key selling points and advantages.
                    </span>
                    <a-spacer></a-spacer>
                    <span class="md-caption grey--text text--darken-1">(Optional)</span>
                </div>
            </div>

            <!-- Weakness -->
            <div class="mt-6">
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Weaknesses</label>
                <a-textarea
                    v-model="competitorItem.weaknesses"
                    placeholder="Enter weaknesses"
                    background-color="grey lighten-4"
                    class="u-border u-rounded-corners-lg"
                    solo flat hide-details no-resize
                    v-test-input.weaknesses
                >
                </a-textarea>
                <div class="mt-2 u-flex-center-y">
                    <span class="md-caption red--text text--darken-2 u-flex-center-y" v-if="$response(competitor_response, 'weaknesses')">
                        <a-icon size="16" color="red darken-2" class="mr-1">warning</a-icon>
                        {{ $response(competitor_response, 'weaknesses') }}
                    </span>
                    <span class="md-caption grey--text text--darken-2 u-flex-center-y" v-else>
                        <a-icon size="16" class="mr-1">info</a-icon>
                        Competitor’s key disadvantages.
                    </span>
                    <a-spacer></a-spacer>
                    <span class="md-caption grey--text text--darken-1">(Optional)</span>
                </div>
            </div>
        </a-sheet>

        <a-divider></a-divider>

        <!-- Save and Cancel -->
        <div class="u-flex-center-y pa-6">
            <a-btn v-test-btn.save color="primary" class="ma-0 elevation-0 px-3" @click="localStoreComp" :loading="loading" :disabled="loading || !!error_domain_response || !!error_link_response">Save Competitor</a-btn>
            <a-btn v-test-btn.cancel class="ml-3 mt-0 mb-0 mr-0" color="grey" text @click="$emit('close')" :disabled="loading">Cancel</a-btn>
        </div>
    </a-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Validate } from '@/helpers/helper-validations'

export default {
    name: 'ModalAddCompetitor',

    props: {
        createProps: {
            type: Object,
            default: {}
        },
        isOpen: {
            type: Boolean
        }
    },

    data () {
        return {
            competitorItem: {},
            loading: false,
            error_link_response: '',
            error_domain_response: ''
        }
    },

    watch: {
        isOpen (val) {
            if (val) this.localIndex()
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        ...mapState('Competitor', {
            competitor_response: 'response'
        })
    },

    methods: {
        async localIndex () {
            this.loading = false
            this.competitorItem = {}
            if (this.createProps.text) Object.assign(this.competitorItem, { ...this.competitorItem, name: this.createProps.text, category_id: this.createProps.category_id })
            this.error_link_response = '',
            this.error_domain_response = '',
            this.localResetScroll()
            this.competitor_clear()
        },

        localResetScroll () {
            const el = document.querySelector('.c-main-content')
            el.scrollTop = 0
        },

        async localStoreComp () {
            this.loading = true
            if (!this.error_link_response && !this.error_domain_response) {
                const item = this.$appendId(this.competitorItem)
                if (this.competitorItem.description_json && typeof(this.competitorItem.description_json) !== 'string') {
                    this.competitorItem.description_json = JSON.stringify(this.competitorItem.description_json)
                }
                await this.competitor_store(item)

                if (!this.$status(this.competitor_response)) return this.loading = false
                this.$emit('store', item)
                this.loading = false
            }
        },

        async local_competitor_link_validate(link) {
            if (!link) this.error_link_response = ''
            else {
                let { message } = new Validate(link, { silent: true }).isUrl(link).length(0, 2048).run()
                if (message) {
                    this.error_link_response = (!message) ? '' : this.error_link_response = message
                    return
                } else {
                    let { message } = new Validate(link, { silent: true }).urlDomainLength(link).run()
                    this.error_link_response = (!message) ? '' : this.error_link_response = message
                }
            }
        },

        async local_competitor_domain_validate(domain) {
            if (!domain) this.error_domain_response = ''
            else {
                let { message } = new Validate(domain, { silent: true }).isDomain(domain).run()
                if (message) {
                    this.error_domain_response = (!message) ? '' : this.error_domain_response = message
                    return
                } else {
                    let { message } = new Validate(domain, { silent: true }).domainLength(domain).run()
                    this.error_domain_response = (!message) ? '' : this.error_domain_response = message
                }
            }
        },

        ...mapActions('Competitor', {
            competitor_store: 'store',
            competitor_clear: 'clear',
        })
    }
}
</script>

<style lang="scss" scoped></style>

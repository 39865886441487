const api_endpoint = '/projects';

export default {
    namespaced: true,
    state: {
        list: [],
        item: { collaborators: [], organization: {}, stage_updates: [], customer: {}, sales_engineer: {}, strategies: [], competitors: [], tags: [], labels: [], products: [], platforms: [], account_executive: {}, stakeholders: [], partner: {}, territories: [], organization: {}, stage: {}  },
        slim_item: {},
        stage_history: [],
        related_item: { stage_updates: [], customer: {}, sales_engineer: {}, strategies: [], competitors: [], tags: [], labels: [], products: [], platforms: [], account_executive: {}, stakeholders: [], partner: {}, territories: [], organization: {}, stage: {} },
        links: {},
        meta: {},
        filters: {
            'filter[date_field]': null,
            'filter[date_condition]': null,
            'filter[date_value_1]': null,
            'filter[date_value_2]': null,
            'filter[customer_id]': [],
            'filter[account_executive_id]': [],
            'filter[sales_engineer_id]': [],
            'filter[result_id]': [],
            'filter[workspace_id]': null,
            'filter[stage_id]': [],
            'filter[archived]': null,
            'filter[status]': [],
            'filter[competitor]': [],
            'filter[partners]': [],
            'reports_status': [],
            'filter[involvement]': null,
            'filter[tag]': [],
            'filter[territory]': [],
            'filter[timeline]': null,
            'filter[health_color]': [],
            'page': 1,
            // 'count': 25,
            'sort': 'title',
            'download': false,
            'download_format': 'csv',
            'fields[projects]': [],
            'attributes': [],
            'include': '',
            'filter[counts]': [],
        },
        response: {},
        defaults: {
            list: [],
            item: { collaborators: [], organization: {}, stage_updates: [], customer: {}, sales_engineer: {}, strategies: [], competitors: [], tags: [], labels: [], products: [], platforms: [], account_executive: {}, stakeholders: [], partner: {}, territories: [], organization: {}, stage: {}  },
            related_item: { stage_updates: [], customer: {}, sales_engineer: {}, strategies: [], competitors: [], tags: [], labels: [], products: [], platforms: [], account_executive: {}, stakeholders: [], partner: {}, territories: [], organization: {}, stage: {} },
            links: {},
            meta: {},
            filters: {
                'filter[date_field]': null,
                'filter[date_condition]': null,
                'filter[date_value_1]': null,
                'filter[date_value_2]': null,
                'filter[customer_id]': [],
                'filter[account_executive_id]': [],
                'filter[sales_engineer_id]': [],
                'filter[result_id]': [],
                'filter[workspace_id]': null,
                'filter[stage_id]': [],
                'filter[archived]': null,
                'filter[status]': [],
                'filter[competitor]': [],
                'filter[partners]': [],
                'reports_status': [],
                'filter[involvement]': null,
                'filter[tag]': [],
                'filter[territory]': [],
                'filter[timeline]': null,
                'filter[health_color]': [],
                'page': 1,
                // 'count': 25,
                'sort': 'title',
                'download': false,
                'download_format': 'csv',
                'fields[projects]': [],
                'attributes': [],
                'include': '',
                'filter[counts]': [],
            },
            response: {},
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item
        },
        CLEAR_ITEM(state, item) {
            state.item = item
        },
        UPDATE_RELATED_ITEM_PROPERTY(state, payload) {
            state.related_item[payload.property] = payload.data;
        },
        UPDATE_STAGE_HISTORY(state, payload) {
            state.stage_history = payload
        },
        UPDATE_RELATED_ITEM_PROPERTY_CLEAR(state, item) {
            state.related_item = item;
        },
        UPDATE_SLIM_ITEM(state, item) {
            state.slim_item = item;
        },
        UPDATE_ITEM_TAGS(state, tags) {
            state.item.tags = tags;
        },
        UPDATE_ITEM_STAKEHOLDERS(state, stakeholders) {
            state.item.stakeholders = stakeholders;
        },
        UPDATE_ITEM_TERRITORIES(state, territories) {
            state.item.territories = territories;
        },
        UPDATE_ITEM_PROPERTY(state, payload) {
            state.item[payload.property] = payload.data;
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload.item;
        },
        UPDATE_ITEM_STAGE_RESULT(state, payload) {
            state.item.stage = payload.item.stage
            state.item.stage_id = payload.item.stage_id
            state.item.result = payload.item.result
            state.item.result_id = payload.item.result_id
            state.item.status = payload.item.status
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_LIST(state, items) {
            // state.list = _.union(state.list, items);
            state.list.push(...items)
        },
        ADD_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_LINKS(state, links) {
            state.links = links;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
        FILTER_TIMELINE(state, payload) {
            state.filters.timeline = payload;
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            let query_filters = _.pickBy(context.state.filters, function (item) {
                return item != '';
            });
            // const config = { method: 'get', url: api_endpoint, params: query_filters }

            // return endpoint.ext_axiosSmart(config)
            return ext_axios.get(api_endpoint, { params: { ...payload } })
                .then((response) => {
                    if (payload.page === 1 && !payload) {
                        context.commit('UPDATE_LIST', response.data.data);
                    } else {
                        payload && payload.list ?
                        context.commit('APPEND_LIST', response.data.data) :
                        context.commit('UPDATE_LIST', response.data.data)
                    }
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        index_slim(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint, { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint + '/' + payload.id, { params: { ...payload.params } })
            .then((response) => {
                context.commit('UPDATE_ITEM', response.data.data);
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            })
            .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.post(api_endpoint, payload)
                .then((response) => {
                    context.commit('ADD_ITEM', payload);
                    context.commit('UPDATE_ITEM', payload);
                    // context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'customer', data: response.data.data.customer});
                    // context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'stage_updates', data: response.data.data.stage_updates});
                    // context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'partner', data: response.data.data.partner});
                    // context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'product', data: response.data.data.product});
                    // context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'tags', data: response.data.data.tags});
                    // context.commit('UPDATE_RELATED_ITEM_PROPERTY', {property: 'territories', data: response.data.data.territories});
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id, payload)
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: payload });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' , server: error.response.data });
                });
        },
        complete(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.project_id + '/stage/move', { result_id: payload.result_id, stage_id: payload.stage_id })
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        archive(context, payload) {
            this.dispatch('Interface/loader_start')
            // return ext_axios.patch(api_endpoint + '/' + payload.project_id + '/stages/archived', { stage_id: payload.stage_id })
            return ext_axios.patch(api_endpoint + '/' + payload.project_id + '/stage/move', { stage: payload.stage_id })
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_SLIM_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        async clear_list(context) {
            context.commit('UPDATE_LIST', []);
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('CLEAR_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_LINKS', _.cloneDeep(context.state.defaults.links));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('CLEAR_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_LINKS', _.cloneDeep(context.state.defaults.links));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        response_clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                resolve('Cleared');
            });
        },
        clear_related_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_RELATED_ITEM_PROPERTY_CLEAR', _.cloneDeep(context.state.defaults.related_item));
                resolve('Cleared');
            });
        },
    },
}

<template>
    <div>
        <InternalUsecaseView v-if="local_user_is_external" />
        <ExternalUsecaseView @saved="localUpdateSaveStatus" v-else />

        <!-- Dialog leave page -->
        <a-dialog v-model="dialogLeavePage" max-width="420">
            <div class="white pa-6 pb-4">
                <div class="mb-3 text-center">
                    <div class="md-subtitle-1 font-weight-medium grey--text text--darken-4">
                        The attachments are currently being uploaded.
                    </div>
                    <div class="mt-2 md-body-2 grey--text text--darken-2">Please wait...</div>
                </div>
                <div class="text-center">
                    <LoaderDotAnimation customSize="10"></LoaderDotAnimation>
                </div>
            </div>
        </a-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import InternalUsecaseView from '@/pages/ProjectsUsecases/ProjectsUsecasesView.vue'
import ExternalUsecaseView from '@/pages/External/ExternalProjects/ExternalProjectsUsecasesView.vue'
import LoaderDotAnimation from '@/components/Loader/LoaderDotAnimation.vue'

export default {
    components: {
        ExternalUsecaseView,
        InternalUsecaseView,
        LoaderDotAnimation
    },

    data () {
        return {
            modificationSaveStatus: 'saved',
            dialogLeavePage: false
        }
    },

    beforeRouteLeave (toRoute, fromRoute, nextRoute) {
        if (this.modificationSaveStatus === 'saved') return nextRoute()
        nextRoute(false)
        this.dialogLeavePage = true
        this.pageToLeave = nextRoute
    },

    computed: {
        local_user_is_external() {
            return this.user_self && this.user_self.scope === 'internal'
        },

        ...mapState('User', {
            user_self: 'self'
        })
    },

    methods: {
        localUpdateSaveStatus (e) {
            this.modificationSaveStatus = e
            if (this.modificationSaveStatus === 'saved') this.dialogLeavePage = false
        }
    }

}
</script>

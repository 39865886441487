<template>
    <div>
        <div
            :class="[{ 'c-dash-card-sticky': pageScrolled && localRouteIsDashColumns, 'u-wrap': configuration_main_navbar }]"
            class="u-flex white py-6 u-sticky u-z10"
            :style="{ top: '73px', rowGap: configuration_main_navbar ? '10px' : '0' }"
        >
            <div class="u-flex u-border u-rounded-corners u-flex-center px-1" style="height: 36px;">
                <a-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <a-card
                            v-on="on"
                            :to="{ name: 'dashboard-columns' }"
                            :style="[{
                                border: $router.route.isDashboardColumns() ?
                                    '1px solid #BBDEFB !important' :
                                    '1px solid transparent !important'
                            }]"
                            v-ripple="{ class: 'light-blue--text' }"
                            class="u-rounded-corners u-relative u-overflow-hidden u-cursor-pointer u-flex-center py-0"
                            width="36"
                            height="28"
                            flat
                        >
                            <a-sheet v-if="$route.name === 'dashboard-columns'" color="#e3f1fd" class="u-absolute u-rounded-corners-sm u-wfull u-hfull" style="top: 0px; left: 0px" />
                            <a-card-text class="py-1 px-2 text-center">
                                <a-icon size="20" class="ma-0" :color="$route.name === 'dashboard-columns' ? 'blue' : 'grey darken-1'">view_week</a-icon>
                            </a-card-text>
                        </a-card>
                    </template>
                    <span>Column View</span>
                </a-tooltip>
                <a-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <a-card
                            v-on="on"
                            :to="{ name: 'dashboard-timeline' }"
                            :style="[{
                                border: $router.route.isDashboardTimeline() ?
                                    '1px solid #BBDEFB !important' :
                                    '1px solid transparent !important'
                            }]"
                            v-ripple="{ class: 'light-blue--text' }"
                            class="u-rounded-corners u-relative u-overflow-hidden u-cursor-pointer u-flex-center py-0"
                            width="36"
                            height="28"
                            flat
                        >
                            <a-sheet v-if="$route.name === 'dashboard-timeline'" color="#e3f1fd" class="u-absolute u-rounded-corners-sm u-wfull u-hfull" style="top: 0px; left: 0px" />
                            <a-card-text class="py-1 px-2 text-center">
                                <a-icon size="20" class="ma-0" :color="$route.name === 'dashboard-timeline' ? 'blue' : 'grey darken-1'">power_input</a-icon>
                            </a-card-text>
                        </a-card>
                    </template>
                    <span>Timeline View</span>
                </a-tooltip>
                <a-tooltip bottom v-if="$can('insights_dashboard.index') && local_org_insights_enabled">
                    <template v-slot:activator="{ on }">
                        <a-card
                            v-on="on"
                            :to="{ name: 'dashboard-insights' }"
                            :style="[{
                                border: local_route_is_insights ?
                                    '1px solid #BBDEFB !important' :
                                    '1px solid transparent !important'
                            }]"
                            v-ripple="{ class: 'light-blue--text' }"
                            class="u-rounded-corners u-relative u-overflow-hidden u-cursor-pointer u-flex-center py-0"
                            flat
                            width="36"
                            height="28"
                        >
                            <a-sheet v-if="local_route_is_insights" color="#e3f1fd" class="u-absolute u-rounded-corners-sm u-wfull u-hfull" style="top: 0px; left: 0px" />
                            <a-card-text class="py-1 px-2 text-center">
                                <a-icon size="20" class="ma-0" :color="local_route_is_insights ? 'blue' : 'grey darken-1'">bar_chart</a-icon>
                            </a-card-text>
                        </a-card>
                    </template>
                    <span>Insights View</span>
                </a-tooltip>
            </div>
            <slot name="insights-dropdown"></slot>
            <slot name="status-filter"></slot>
            <slot name="team-filter"></slot>
            <slot name="territory"></slot>
            <a-spacer></a-spacer>
            <slot name="sort"></slot>
            <slot name="zoom"></slot>
            <slot name="insights"></slot>
            <slot name="insights-board-more"></slot>
            <slot name="arrows"></slot>
            <slot name="loading"></slot>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data () {
        return {
            pageScrolled: false
        }
    },

    mounted () {
        window.addEventListener('scroll', this.localDetectScroll);
    },

    beforeDestroy () {
        window.removeEventListener('scroll', this.localDetectScroll);
    },

    computed: {
        local_org_insights_enabled () {
            return (this.user_self && this.user_self.organization) && (this.user_self.organization.flag_insights_dashboard === 1)
        },

        local_route_is_insights () {
            const insights_routes = ['dashboard-insights', 'dashboard-insights-type']
            return insights_routes.includes(this.$route.name)
        },

        localRouteIsDashColumns () {
            return this.$route.name === 'dashboard-columns'
        },

        ...mapState('User', {
            user_self: 'self'
        }),
        ...mapState('Configuration',{
            configuration_main_navbar: 'globalAsideNavbar',
        }),
    },

    methods: {
        localDetectScroll () {
            this.pageScrolled = window.scrollY > 0
        },
    }
}
</script>

<style lang="scss" scoped>
.c-dash-card-sticky {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        z-index: -1;
        box-shadow: 0 5px 10px -12px rgba(0, 0, 0, 0.5);
    }
}
</style>

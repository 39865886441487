<template>
    <a-main>
        <a-container grid-list-xl container--fluid mb-10 pa-0>
            <g-page-header
                title="External Members"
                subtitle="You can invite external stakeholders into the system by adding them as Client or Partner collaborator in the project."
                :breadcrumb-items="breadcrumbItems"
                :loading="loading"
                icon="account_box"
                class="pr-0"
                iconOutlined
            >
            </g-page-header>
            <router-view></router-view>
        </a-container>
    </a-main>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        breadcrumbItems() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Teams', to: '/teams', exact: true})
            return breadcrumbs
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
}
</script>
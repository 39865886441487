export default {
    data () {
        return {
            errorFields: []
        }
    },

    methods: {
        mixinSetError (args) {
            const index = this.errorFields.findIndex(item => item.slug === args.slug)
            if (index !== -1) return
            this.errorFields.push({ slug: args.slug, message: args.message })
        },

        mixinClearError (slug) {
            const slugArr = slug.split(',')
            slugArr.forEach(slugItem => {
                const index = this.errorFields.findIndex(item => item.slug === _.trim(slugItem))
                if (index !== -1) this.errorFields.splice(index, 1)
            })
        },

        mixinHasError (slug) {
            const index = this.errorFields.findIndex(item => item.slug === slug)
            return index !== -1
        },

        mixinGetError (slug, field = 'message') {
            const index = this.errorFields.findIndex(item => item.slug === slug)
            return index !== -1 ? this.errorFields[index][field] : null
        },
    }
}

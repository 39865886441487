<template>
    <a-card
        class="white u-relative u-rounded-corners-lg ma-0"
        min-height="800"
        @dragenter="!localAttachmentsUpdating ? localHandleDragEnter() : ''"
        @drop="localHandleDrop"
    >
        <SDragOverlay
            v-if="filesDraggedOver"
            pageType="internal--dialog"
            @leave="localHandleLeave()"
            @enter="hoveredOnInfo=false"
            @enterInfo="hoveredOnInfo=true"
        />
        <div class="pa-7">
            <!-- Code & Updated time + user -->
            <div class="d-inline-flex align-center mb-2" style="height: 20px">
                <span v-if="usecase.code && !local_is_external" class="u-flex align-center md-body-2 grey--text text--darken-1 mr-4 font-weight-medium">
                    <a-icon size="20" color="grey darken-1" class="mr-1">tag</a-icon>
                    <span>{{ usecase.code }}</span>
                </span>
                <div class="d-inline-flex align-center md-body-2 grey--text text--darken-1 font-weight-medium">
                    <template v-if="timer_update_debounce">
                        <a-icon size="20" color="grey darken-1" class="mr-1">pending</a-icon>
                        <span>Saving...</span>
                    </template>
                    <template v-if="!timer_update_debounce && update_autosaved_at">
                        <a-icon size="20" color="green darken-1" class="mr-1">cloud_done</a-icon>
                        <span class="green--text text--darken-1">Saved</span>
                    </template>
                </div>
            </div>

            <!-- Title -->
            <div class="c-textarea-editor u-relative">
                <v-textarea
                    v-model="usecase_title"
                    :disabled="!check_permission('update') || localAttachmentsUpdating"
                    class="font-weight-regular md-heading-4 font-weight-bold c-title-color"
                    background-color="transparent"
                    placeholder="Usecase title goes here.."
                    multi-line auto-grow solo flat hide-details
                    rows="1"
                    @input="local_update_title()"
                >
                    <template #append v-if="(!usecase_title || (usecase_title && !usecase_title.trim().length)) || (usecase_title && usecase_title.trim().length > titleLength)">
                        <div>
                            <a-icon color="red darken-1" size="20">info</a-icon>
                            <a-sheet class="c-tooltip-pointer c-tooltip-pointer--right-light u-absolute u-shadow px-3 py-1 md-caption u-rounded-corners red--text text--darken-1" style="right: -1px;">
                                <template v-if="usecase_title && usecase_title.trim().length > titleLength">({{ usecase_title && usecase_title.trim().length }} / {{ titleLength }})</template>
                                <template v-else>Required Field</template>
                            </a-sheet>
                        </div>
                    </template>
                </v-textarea>
            </div>

            <!-- Assignee & Visibility & More -->
            <div class="u-flex-center-y my-6">
                <slot name="assignee"></slot>

                <slot name="visibility" v-if="!local_is_external">
                    <g-confirmation
                        :can-update="check_permission('update')"
                        :disabled="localAttachmentsUpdating"
                        :is-public="usecase && usecase.visibility === 'external'"
                        @action="check_permission('update') ? local_update_visibility(usecase.id, usecase.visibility) : ''"
                        type="Use Case"
                        class="ml-6 u-flex-center-y"
                        dense
                    ></g-confirmation>
                </slot>

                <slot name="view-more">
                    <a-menu v-if="check_permission('destroy')" :disabled="localAttachmentsUpdating" offset-y :close-on-content-click="false" min-width="200">
                        <template v-slot:activator="{ on }">
                            <a-card v-on="on" height="30" width="32" flat class="u-flex-center ml-6 u-border u-rounded-corners u-cursor-pointer grey lighten-4">
                                <a-icon size="20" color="grey darken-1">more_horiz</a-icon>
                            </a-card>
                        </template>
                        <a-list class="u-list-std">
                            <a-list-item @click="confirm_delete ? local_delete_usecase() : local_get_delete_confirm()" :class="[{ 'c-delete-confirm': confirm_delete }]">
                                <a-list-item-icon>
                                    <a-icon class="mr-3" size="20" color="grey darken-2">delete</a-icon>
                                </a-list-item-icon>
                                <a-list-item-content>{{ confirm_delete ? 'Confirm Delete?' : 'Delete Record' }}</a-list-item-content>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </slot>
            </div>

            <div class="pa-0 d-inline-flex flex-wrap">
                <template v-if="usecase.tags && usecase.tags.length">
                    <template v-for="tag in usecase.tags">
                        <g-tags :can-update="check_permission('update')" :tag="tag" type="Usecase" @destroy="local_associate_tag_destroy(tag, usecase)" :key="tag.id"></g-tags>
                    </template>
                </template>
                <a-menu
                    v-if="check_permission('update')"
                    v-model="show_usecase_tag_add_input"
                    :close-on-content-click="false"
                    :disabled="localAttachmentsUpdating"
                    ref="refAddTagPopup"
                    transition="slide-y-transition"
                    nudge-bottom="8"
                    bottom offset-y
                >
                    <template v-slot:activator="{ on }">
                        <g-add-btn icon-size="14" v-on="on">
                            <span class="md-caption body--text text--lighten-1 ml-1">Add Tag</span>
                        </g-add-btn>
                    </template>
                    <a-sheet class="pb-1" max-width="240">
                        <s-tags-inline-form
                            :item="usecase"
                            :can-update="$can('tags.store') && !localAttachmentsUpdating"
                            :list="local_get_filtered_tags(usecase)"
                            :is-menu-open="show_usecase_tag_add_input"
                            source="Usecase"
                            type="usecase_tag"
                            @store="local_load_tags_list"
                            @after-store="local_after_tag_store"
                            @after-remove="local_upsert_uc"
                        ></s-tags-inline-form>
                    </a-sheet>
                </a-menu>
            </div>

            <!-- Usecase description -->
            <div :class="['mt-6', { 'mb-6': editor_focused }]" v-if="show_desc_box">
                <h2 class="c-letter-space text-uppercase md-caption font-weight-medium grey--text text--darken-2 mb-3">Description</h2>
                <g-editor-box
                    v-model="usecase.description_json"
                    :can-update="check_permission('update') && !localAttachmentsUpdating"
                    @update="local_update_description"
                    @isFocused="e => editor_focused = e"
                    editor-min-height="auto"
                    :editorClass="['px-1']"
                    :editorHeight="300"
                    :readOnly="localAttachmentsUpdating"
                    :hideFocus="localAttachmentsUpdating"
                    :refresh="refreshDesc"
                    customMinHeight="100"
                    customMaxHeight="200"
                    dense
                ></g-editor-box>
            </div>

            <!-- Attachment section -->
            <div class="mt-6" v-if="isOpen">
                <SAttachmentSection
                    :list="attachment_local_list"
                    :dropTransferList="localDroppedFilesList"
                    :sourceId="usecase.id"
                    :updateLoading="localAttachmentsUpdating"
                    :loading="mixinIsLoading('attachment')"
                    :canUpdate="check_permission('update')"
                    sourceType="Usecase"
                    @dropTransfer="localUpdateAttachmentTransfer"
                    @loadMore="localLoadMoreAttachments()"
                    @remove="localRemoveAttachmentItem"
                    @delete="localDeleteAttachmentItem"
                    flat autoUpdate
                >
                </SAttachmentSection>
            </div>

        </div>
    </a-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {
    STagsInlineForm,
    SAssigneeDropdown,
    SAttachmentSection
} from '@/config/config-shared-components'
import { diffHuman } from '@/helpers/helper-date-filter'
import SDragOverlay from '@/components/Attachments/SharedDragOverlay.vue'
import mixinAttachmentsInternal from '@/mixins/mixin-internal-attachment'
import mixinLoader from '@/mixins/mixin-module-loading-setup'

export default {
    mixins: [ mixinAttachmentsInternal, mixinLoader ],
    props: {
        usecase: {
            type: Object,
            required: true
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        titleLength: {
            type: Number,
            default: 1000
        },
    },

    components: {
        STagsInlineForm,
        SAssigneeDropdown,
        SAttachmentSection,
        SDragOverlay
    },

    data () {
        return {
            usecase_title: '',
            usecase_description: '',
            update_autosaved_at: null,
            editor_focused: false,
            show_desc_box: false,
            confirm_delete: false,
            timer_update_debounce: null,
            show_usecase_tag_add_input: false,

            //Attachments
            filesDraggedOver: false,
            hoveredOnInfo: false,
            FILTER: {
                parent_id: 'filter[parent_id]',
                parent_type: 'filter[parent_type]',
                source_id: 'filter[source_id]',
                source_type: 'filter[source_type]',
                fields: 'fields[attachments]',
                page: 'page'
            },
            attachment_fields: 'id,name,size,extension,order,source_id,source_type,parent_id,parent_type',
            localDroppedFilesList: [],
            localAttachmentFilters: {},
            backupAttachmentList: [],
            localAttachmentTimeout: null,
            refreshDesc: false
        }
    },

    watch: {
        isOpen: {
            handler (value) {
                this.show_desc_box = value
                if (!value) return
                this.usecase_title = this.usecase.title
                this.localIndex()
            },
            deep: true,
            immediate: true
        },
    },

    computed: {
        local_has_tags () {
            return this.usecase && this.usecase.tags && this.usecase.tags.length
        },

        local_is_external () {
            return this.$route.meta.view === 'external' || (this.user_self && this.user_self.scope === 'external')
        },

        localAttachmentsUpdating () {
            return this.mixinIsLoading('attachment-update') || this.mixinIsLoading('attachment-delete')
        },

        ...mapState('Usecase', {
            usecase_response: 'response',
        }),

        ...mapState('Collaborator', {
            collaborator_list: 'list',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Attachment', {
            attachment_list: 'list',
            attachment_local_list: 'local_list',
            attachment_delete_list: 'delete_list',
            attachment_upload_list: 'upload_list',
            attachment_meta: 'meta',
            attachment_response: 'response',
            attachment_delete_response: 'delete_response',
        }),
    },

     methods: {
        async localIndex () {
            this.mixinClearAttachmentUpdate()
            this.localAddListeners()
            this.localLoadUsecaseAttachments()
        },

        async localLoadUsecaseAttachments () {
            this.mixinSetLoading('attachment')
            this.attachment_clear_response()
            this.attachment_clear_upload_list()
            this.localSetAttachmentFilters()
            await this.attachment_index({ params: this.localAttachmentFilters })
            this.mixinResetLoading('attachment')
            this.mixinUpdateLocalAttachmentList({ list: this.attachment_list })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localSetAttachmentFilters () {
            this.localAttachmentFilters[this.FILTER.parent_id] = this.usecase.project_id
            this.localAttachmentFilters[this.FILTER.parent_type] = 'Project'
            this.localAttachmentFilters[this.FILTER.source_id] = this.usecase.id
            this.localAttachmentFilters[this.FILTER.source_type] = 'Usecase'
            this.localAttachmentFilters[this.FILTER.fields] = this.attachment_fields
            this.localAttachmentFilters[this.FILTER.page] = 1
            this.localAttachmentFilters.count = 5
        },

        local_update_title () {
            if (this.usecase_title && this.usecase_title.length > this.titleLength) return this.local_clear_debounce_timer()
            this.usecase.title = this.usecase_title
            this.local_update_debounced()
        },

        local_update_description (content) {
            this.usecase.description_json = content
            this.local_update_debounced()
        },

        async local_update_debounced () {
            this.local_clear_debounce_timer()
            this.timer_update_debounce = setTimeout(async () => this.local_item_update(), 1000)
        },

        async local_item_update () {
            this.local_set_autosave()
            let clonedItem = _.cloneDeep(this.usecase)
            Object.assign(clonedItem, { is_autosave: 1 })
            this.$emit('update', clonedItem)
            await this.usecase_update(clonedItem)
            if (this.$status(this.response)) {
                this.local_set_autosave()
                this.usecase.is_autosave = 0
                this.local_clear_debounce_timer()
            }
        },

        local_set_autosave () {
            this.update_autosaved_at = moment().format('YYYY-MM-DD HH:mm:ss')
            this.usecase.updated_at = moment()
            this.usecase.updated_by_id = this.user_self.id
            this.$set(this.usecase, 'updated_by', this.user_self)
            setTimeout(() => this.update_autosaved_at = null, 5000)
        },

        async local_clear_debounce_timer (saved = false) {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                this.usecase.is_autosave = 0
            }
        },

        local_get_delete_confirm () {
            this.confirm_delete = true
            setTimeout(() => this.confirm_delete = false, 3000)
        },

        local_delete_usecase () {
            this.$emit('delete', this.usecase.id)
        },

        local_get_updated_time (date) {
            return moment.utc(date).local().format('h:mm A')
        },

        local_get_updated_data (item, type) {
            if (!item) return
            const hasUpdated = item.updated_at ?? null
            if (hasUpdated) {
                if (type === 'type') return 'Updated'
                if (type === 'user' && item.updated_by) return item.updated_by.name
            }
            if (type === 'type') return 'Created'
            if (type === 'user' && item.created_by) return item.created_by.name
        },

        local_has_title_error (item) {
            if (!item || (item && !item.trim())) return false
            if (item && item.length > this.titleLength) return false
            return true
        },

        local_get_date (date) {
            return diffHuman(date)
        },

        local_utc (date) {
            return moment.utc(date ?? null).local()
        },

         // Usecase CRUD
        async local_update_visibility (id, state) {
            const visibility = state === 'external' ? 'internal' : 'external'
            await this.usecase_visibility({ id, state: visibility })
            if (this.usecase_response.status !== 'success') return
            this.$set(this.usecase, 'visibility', visibility)
            this.local_upsert_uc(this.usecase)
        },

        localUpdateAttachmentCount ({ value }) {
            this.$set(this.usecase, 'attachments_count', value)
            this.local_upsert_uc(this.usecase)
        },

        async local_destroy (id) {
            this.usecase_destroy({ id })
        },
        // Usecase CRUD ----- END

        // Tags CRUD
        async local_associate_tag_destroy (data, item) {
            this.local_remove_tag(data, item)
            await this.association_destroy(data.association)
            this.local_upsert_uc(item)
            // if (this.association_response.status !== 'success') return
        },

        async local_remove_tag (tag, item) {
            const index = item.tags.findIndex(item => item.id === tag.id)
            if (index !== -1) item.tags.splice(index, 1)
        },

        local_load_tags_list () {
            this.tag_index({ 'filter[type]': 'usecase_tag', 'fields[tags]': 'id,label,color,type' })
        },

        local_after_tag_store (item) {
            this.show_usecase_tag_add_input = false
            this.local_upsert_uc(item)
        },

        local_get_filtered_tags (item) {
            return _.differenceBy(this.tag_list, item.tags, 'id')
        },
        // Tags CRUD ----- END

        // Extras
        local_upsert_uc (item) {
            this.usecase_uc_upsert(item)
        },

        check_permission (type) {
            return this.$can(`usecases.${type}`) && this.$can('projects.update-only')
        },

        // Attachments - CRUD

        localAddListeners () {
            this.mixinAttachmentListeners('add')
        },

        localRemoveListeners () {
            this.mixinAttachmentListeners('remove')
        },

        localHandleDragEnter (e) {
            this.filesDraggedOver = true
        },

        localHandleLeave (e) {
            if (this.hoveredOnInfo) return
            this.filesDraggedOver = false
        },

        localHandleDrop (e) {
            if (this.localAttachmentsUpdating || this.pageLoading) return
            const droppedFiles = Array.from(e.dataTransfer.files)
            if (_.size(droppedFiles) > 5) return this.localValidateAttachmentCount()
            this.localDroppedFilesList = _.cloneDeep(droppedFiles)
            this.filesDraggedOver = false
        },

        localValidateAttachmentCount () {
            this.$notify('warning', 'Sorry, you can only upload up to 5 files at a time')
            this.filesDraggedOver = false
        },

        localRemoveAttachmentItem (id) {
            this.mixinRemoveAttachmentItem(id)
        },

        localDeleteAttachmentItem (id) {
            this.mixinDeleteAttachmentItem(id)
            this.localUpdateUsecaseAttachment()
        },

        async localUpdateUsecaseAttachment () {
            this.attachment_clear_response()
            this.mixinRemoveInvalidFiles()
            await this.localBulkDeleteAttachment()
            await this.localUploadAttachment()
            this.mixinResetLoading('attachment-update')
            this.localRefreshDesc()
            this.$emit('attachmentLoading', false)
        },

        async localUploadAttachment () {
            if (!_.size(this.attachment_upload_list)) return
            const formData = new FormData();
            formData.append('parent_id', this.usecase.project_id)
            formData.append('parent_type', 'Project')
            formData.append('source_id', this.usecase.id)
            formData.append('source_type', 'Usecase')
            formData.append('order', -1)
            this.attachment_upload_list.forEach(file => {
                formData.append('files[]', file)
                formData.append('ids[]', file.id)
            })
            await this.attachment_upload(formData)

            if (!this.$status(this.attachment_response)) return this.mixinHandleAttachmentErrors()
            this.localShowUsecaseAttachments()
            this.attachment_clear_upload_list()
        },

        async localBulkDeleteAttachment () {
            if (!_.size(this.attachment_delete_list)) return
            this.mixinSetLoading('attachment-delete')
            await this.attachment_bulk_destroy({ids: this.attachment_delete_list})
            if (this.$status(this.attachment_delete_response)) {
                if (!_.size(this.attachment_upload_list)) this.localShowUsecaseAttachments()
                if (!_.size(this.attachment_local_list) && !_.size(this.attachment_upload_list)) this.localLoadUsecaseAttachments()
                this.attachment_clear_delete_list()
            }
            this.mixinResetLoading('attachment-delete')
        },

        async localShowUsecaseAttachments () {
            this.localAttachmentFilters.count = _.size(this.attachment_local_list)
            this.localAttachmentFilters.page = 1
            await this.attachment_index({ mode: 'show', params: this.localAttachmentFilters})
            this.mixinUpdateLocalAttachmentList({ list: this.attachment_list})
            this.backupAttachmentList = _.cloneDeep(this.attachment_list)
            this.localUpdateAttachmentCount({ value: this.attachment_meta.total })
        },

        localUpdateAttachmentTransfer (params) {
            clearTimeout(this.localAttachmentTimeout)
            this.attachment_clear_response()
            const { list, transferFiles } = params || {}
            const totalUploadList = !!_.size(this.attachment_upload_list) ? [...transferFiles, ...this.attachment_upload_list] : [...transferFiles]
            this.mixinPreserveListChanges({ list: list, type: 'drop_transfer' })
            this.attachment_update_upload_list(totalUploadList)
            this.$emit('attachmentLoading', true)
            this.mixinSetLoading('attachment-update')
            this.localRefreshDesc()
            this.localAttachmentTimeout = setTimeout(() => this.localUpdateUsecaseAttachment(), 2000)
        },

        async localLoadMoreAttachments () {
            if (this.attachment_meta.total === _.size(this.attachment_list)) return

            this.mixinSetLoading('attachment')

            const defaultCount = 5
            const isListShortOfItem = _.size(this.attachment_list)%5 === 0
            const loadType = isListShortOfItem ? 'load_more' : 'index'
            this.localAttachmentFilters.count = isListShortOfItem ? defaultCount : this.attachment_meta.current_page*defaultCount
            this.localAttachmentFilters[this.FILTER.page] = isListShortOfItem ? this.attachment_meta.current_page+1 : 1
            await this.attachment_index({ mode: loadType, params: this.localAttachmentFilters })

            this.mixinResetLoading('attachment')
            this.mixinPreserveListChanges({ list: this.attachment_list, type: 'load_more' })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localClearAttachmentUpdate () {
            this.mixinUpdateLocalAttachmentList({ list: this.backupAttachmentList })
            this.mixinClearAttachmentUpdate()
        },

        localRefreshDesc () {
            this.refreshDesc = true
            setTimeout(() => {
                this.refreshDesc = false
            }, 100);
        },

        ...mapActions('Usecase', {
            usecase_show: 'show',
            usecase_update: 'update',
            usecase_uc_upsert: 'uc_upsert',
            usecase_select: 'select',
            usecase_show_item: 'show_item',
            usecase_upsert_sc: 'sc_upsert',
            usecase_upsert_tc: 'tc_upsert',
            usecase_update_status: 'update_status',
            usecase_visibility: 'visibility',
            usecase_destroy: 'destroy',
            usecase_clear_item: 'clear_item',
        }),

        ...mapActions('Attachment', {
            attachment_index: 'index',
            attachment_upload: 'upload',
            attachment_update_upload_list: 'update_upload_list',
            attachment_remove_upload_item: 'remove_upload_list_item',
            attachment_bulk_destroy: 'bulk_destroy',
            attachment_clear_upload_list: 'clear_upload_list',
            attachment_clear_delete_list: 'clear_delete_list',
            attachment_clear_response: 'clear_response',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
        }),

        ...mapActions('Association', {
            association_destroy: 'destroy',
        }),
    }
}
</script>

<style lang="scss" scoped></style>

<template>
    <a-card>
        <a-sheet class="u-flex py-6 px-8">
            <h2 class="body-4 font-weight-medium indigo--text text--darken-1">
                <a-icon class="mr-1 u-icon-nudge" color="indigo darken-1" size="35" style="top: -3px;">integration_instructions</a-icon>
                Dynamic Variables
            </h2>
            <a-spacer></a-spacer>
            <div class="u-cursor-pointer" @click="close">
                <a-icon size="18" color="grey lighten-1">cancel</a-icon>
            </div>
        </a-sheet>
        <a-divider class="grey lighten-3"></a-divider>
        <a-card-text class="py-6 px-8" style="height: 700px;">
            <h2 class="grey--text text--darken-3 title text-xs-left pb-3">Date Variables</h2>
            <div class="u-wfull" style="border: 1px solid #dddddd;">
                <template v-for="(item, index) in dateVariables">
                    <div :key="item.index" class="u-flex">
                        <div style="width: 52%;" class="px-3 py-4">
                            <div v-clipboard:copy="$can('automation-workflows.update') ? item.variable : ''" v-clipboard:success="(evt) => $can('automation-workflows.update') ? updateDateVariable(evt, item.variable + 'id') : ''"  class="u-flex align-center u-rounded-corners u-wfull md-subtitle-2 elevation-0 grey lighten-4 u-cursor-pointer"  style="border: 1px solid #dcdcdc !important;" >
                                <code :id="item.variable + 'id'" class="px-3 py-1 u-flex grey--text text--darken-2" style="font-family: monospace !important;">
                                    {{ item.variable }}
                                </code>
                                <a-spacer></a-spacer>
                                <div class="ml-2 px-4 py-1" style="border-left: 1px solid #dcdcdc !important;">
                                    <a-icon size="14" color="grey darken-1">content_copy</a-icon>
                                </div>
                            </div>
                        </div>
                        <a-divider vertical :key="'vertical_'+index" class="grey lighten-2"></a-divider>
                        <div style="width: 48%;" class="px-3 py-3">
                            <p class="md-subtitle-2 mb-0 mt-2 grey--text text--darken-3">{{ item.description }}</p>
                            <p class="md-subtitle-2 mb-0 grey--text mt-1" v-if="item.subtitle">{{ item.subtitle }}</p>
                        </div>
                    </div>
                    <a-divider :key="'divider_'+index" class="grey lighten-2"></a-divider>
                </template>
            </div>
        </a-card-text>
    </a-card>
</template>

<script>
export default {
    props: {
        dateVariables: {
            type: Array,
            default: [],
        },
        updateDateVariable: {
            type: Function,
            default: {},
        },
        close: {
            type: Function,
            default: {},
        },
    },
}
</script>
<template>
    <a-main>
        <a-container grid-list-xl class="pa-6 pt-4 px-0" v-if="local_loading">
            <a-layout>
                <a-flex xs12>
                    <h2 class="md-subtitle-2 grey--text text--darken-1 text-center">Loading overview templates...</h2>
                </a-flex>
            </a-layout>
        </a-container>

        <a-container grid-list-xl class="pa-0 mt-2 mb-10" v-else>
            <a-layout wrap align-start>
                <a-flex xs12 class="py-0">
                    <a-card class="mb-4 mt-4 u-rounded-corners u-overflow-hidden u-elevation-custom-1">
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="pa-6 px-4">
                                <a-layout wrap align-center>
                                    <a-flex px-5>
                                        <h2 class="md-subtitle-1 font-weight-bold primary--text">Description</h2>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-chip small color="white" text-color="white" v-if="template_autosave_description_status == 'none'"></a-chip>
                                        <a-chip small color="green" text-color="white" v-if="template_autosave_description_status == 'done'">Autosaved</a-chip>
                                        <a-chip small color="grey darken-1" text-color="white" v-if="template_autosave_description_status == 'loading'">Saving...</a-chip>
                                    </a-flex>
                                    <a-flex shrink class="pl-0">
                                        <a-btn small icon text color="grey lighten-1" class="ma-0" @click="accordion_template_project_description_expanded = !accordion_template_project_description_expanded">
                                            <a-icon size="16" v-if="accordion_template_project_description_expanded">unfold_less</a-icon>
                                            <a-icon size="16" v-else>unfold_more</a-icon>
                                        </a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider class="u-divider" v-if="accordion_template_project_description_expanded"></a-divider>
                            <a-container container--fluid grid-list-xl class="pa-4" v-if="accordion_template_project_description_expanded">
                                <a-layout wrap align-center>
                                    <a-flex xs12>
                                        <a-textarea
                                            v-model="template_project_item.description"
                                            flat solo hide-details auto-grow
                                            class="u-textarea-inline"
                                            :placeholder="$can('templates.update') ? 'Enter description' : 'No description'"
                                            :readonly="!$can('templates.update')"
                                            @input="local_template_project_update_autosave()"
                                        >
                                        </a-textarea>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
                <a-flex xs12 class="py-0">
                    <a-card class="mb-4 u-rounded-corners u-overflow-hidden u-elevation-custom-1">
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="pa-6 px-4">
                                <a-layout wrap align-center>
                                    <a-flex px-5>
                                        <h2 class="md-subtitle-1 font-weight-bold primary--text">Next Steps</h2>
                                    </a-flex>
                                    <a-flex shrink>
                                        <a-chip small color="white" text-color="white" v-if="template_autosave_next_step_status == 'none'"></a-chip>
                                        <a-chip small color="green" text-color="white" v-if="template_autosave_next_step_status == 'done'">Autosaved</a-chip>
                                        <a-chip small color="grey darken-1" text-color="white" v-if="template_autosave_next_step_status == 'loading'">Saving...</a-chip>
                                    </a-flex>
                                    <a-flex shrink v-if="$can('templates.update')">
                                        <p class="md-caption grey--text mb-0 d-flex align-right">
                                            <em class="mr-2">Select text for formating options</em>
                                            <a-icon size="14" color="grey" class="ml-1">format_bold</a-icon>
                                            <a-icon size="14" color="grey" class="ml-1">format_italic</a-icon>
                                            <!-- <a-icon size="14" color="grey" class="ml-1">format_underlined</a-icon> -->
                                        </p>
                                    </a-flex>
                                    <a-flex shrink class="pl-0">
                                        <a-btn small icon text color="grey lighten-1" class="ma-0" @click="accordion_template_project_next_step_expanded = !accordion_template_project_next_step_expanded">
                                            <a-icon size="16" v-if="accordion_template_project_next_step_expanded">unfold_less</a-icon>
                                            <a-icon size="16" v-else>unfold_more</a-icon>
                                        </a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                            <a-divider class="u-divider" v-if="accordion_template_project_next_step_expanded"></a-divider>
                            <a-container container--fluid grid-list-xl class="pa-4" v-if="accordion_template_project_next_step_expanded">
                                <a-layout wrap align-center>
                                    <a-card-text class="px-4 pt-2 pb-2" style="min-height: 120px">
                                        <p class="grey--text text--darken-1 px-1" v-if="template_project_item.next_step && template_project_item.next_step.replace(/<[^>]*>?/gm, '') === null || template_project_item.next_step && template_project_item.next_step.replace(/<[^>]*>?/gm, '') == ''" @click="() => { template_project_item.next_step = ' '; template_project_editor_next_step.focus() }">Enter next steps for project</p>
                                        <editor-content v-else class="px-1" :editor="template_project_editor_next_step"></editor-content>
                                        <editor-menu-bubble :editor="template_project_editor_next_step" style="left: 16px;" class="u-absolute" :keep-in-bounds="false" v-slot="{ commands, isActive, menu }">
                                            <div
                                                class="menububble"
                                                v-if="$can('templates.update')"
                                                :class="{ 'is-active': menu.isActive }"
                                                :style="`left: ${menu.left - 16}px; bottom: ${menu.bottom + 8}px;`"
                                            >
                                                <a-btn icon text small class="mx-0" :color="(isActive.bold()) ? 'blue' : 'grey'" @click="commands.bold">
                                                    <a-icon size="16">format_bold</a-icon>
                                                </a-btn>
                                                <a-btn icon text small class="mx-0" :color="(isActive.italic()) ? 'blue' : 'grey'" @click="commands.italic">
                                                    <a-icon size="16">format_italic</a-icon>
                                                </a-btn>
                                                <!-- <a-btn icon text small class="mx-0" :color="(isActive.underline()) ? 'blue' : 'grey'" @click="commands.underline">
                                                    <a-icon size="16">format_underlined</a-icon>
                                                </a-btn>
                                                <a-btn icon text small class="mx-0" :color="(isActive.strike()) ? 'blue' : 'grey'" @click="commands.strike">
                                                    <a-icon size="16">strikethrough_s</a-icon>
                                                </a-btn> -->
                                                <a-divider vertical dark></a-divider>
                                                <a-btn icon text small class="mx-0" :color="(isActive.bullet_list()) ? 'blue' : 'grey'" @click="commands.bullet_list">
                                                    <a-icon size="16">format_list_bulleted</a-icon>
                                                </a-btn>
                                                <a-btn icon text small class="mx-0" :color="(isActive.ordered_list()) ? 'blue' : 'grey'" @click="commands.ordered_list">
                                                    <a-icon size="16">format_list_numbered</a-icon>
                                                </a-btn>
                                            </div>
                                        </editor-menu-bubble>
                                    </a-card-text>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>

                  <!-- System Tags -->
                <a-flex xs12 class="py-0">
                    <div class="white py-3 px-2 u-rounded-corners mb-4 u-elevation-custom-1">
                        <h2 class="md-subtitle-1 px-5 mb-3 font-weight-medium primary--text text--lighten-2">System Tags</h2>
                        <template v-for="(type, index) in template_system_tags">
                            <a-card flat class="mb-4 u-rounded-corners u-border mx-4 u-overflow-hidden" :key="index">
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl class="pa-3 py-3">
                                        <a-layout wrap>
                                            <a-flex pt-3 pb-4 px-4>
                                                <h2 class="md-subtitle-1 font-weight-medium mb-1 primary--text text--lighten-2">{{ type.title }}</h2>
                                                <div class="u-flex align-center flex-wrap">
                                                    <template v-for="tag in local_template_project_tag_filter(type.name)">
                                                        <g-tags :can-update="$can('templates.update')" :tag="tag" type="TemplateProject" @destroy="local_association_destroy(tag)" :key="tag.id"></g-tags>
                                                    </template>
                                                    <g-add-btn v-if="$can('templates.update')" @click="local_template_project_tag_edit(type.name)"></g-add-btn>
                                                </div>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-card-text>
                            </a-card>
                        </template>
                    </div>
                </a-flex>

                <!-- Custom Tags -->

                <a-flex xs12 class="py-0">
                    <div class="white py-3 px-2 u-rounded-corners mb-4 u-elevation-custom-1">
                        <h2 class="md-subtitle-1 px-5 mb-3 font-weight-medium primary--text text--lighten-2">Custom Tags</h2>
                        <template v-for="(tag_section, index) in tag_section_list">
                            <a-card flat class="mb-4 mx-4 u-border u-rounded-corners u-overflow-hidden" :key="index">
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl class="pa-3 py-3">
                                        <a-layout wrap>
                                            <a-flex xs12 pt-3 pb-4 px-4>
                                                <h2 class="md-subtitle-1 font-weight-medium mb-1 primary--text text--lighten-2">{{ tag_section.name }}</h2>
                                                <div class="u-flex align-center flex-wrap">
                                                    <template v-for="tag in local_template_project_tag_filter(tag_section.slug)">
                                                        <g-tags :can-update="$can('templates.update')" :tag="tag" type="TemplateProject" @destroy="local_association_destroy(tag)" :key="tag.id"></g-tags>
                                                    </template>
                                                    <g-add-btn v-if="$can('templates.update')" @click="local_template_project_tag_edit(tag_section.slug, tag_section.name)"></g-add-btn>
                                                </div>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-card-text>
                            </a-card>
                        </template>
                    </div>
                </a-flex>
            </a-layout>
        </a-container>
         <a-dialog max-width="800" v-model="dialog_template_tags_form" persistent>
            <g-dialog-box-simple @close="dialog_template_tags_form_close">
                <template #title>
                    <span class="text-capitalize">{{ template_project_custom_tag_type ? template_project_custom_tag_type : template_project_tag_type }}</span> tags
                </template>
                <template #body>
                    <s-tags-form
                        title="Add new tag"
                        :item="template_project_item"
                        :list="local_tag_filter(template_project_tag_type, true)"
                        :type="template_project_custom_tag_type ? template_project_tag_type : (template_project_tag_type ? 'template_project_' + template_project_tag_type.toLowerCase() : '')"
                        :can-update="check_user_tag_permission('store')"
                        :model-update="$can('templates.update')"
                        source="TemplateProject"
                        :form-close="dialog_template_tags_form"
                        @after-store="local_load_tags_list"
                        filter-type
                    ></s-tags-form>
                </template>
            </g-dialog-box-simple>
        </a-dialog>
        <a-snackbar v-model="snackbar_status" color="red darken-3" :timeout="4000" bottom center>
            {{snackbar_text}}
        </a-snackbar>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import { STagsForm } from '@/config/config-shared-components'
import {
    Bold,
    Italic,
    Underline,
    Strike,
    OrderedList,
    BulletList,
    ListItem,
    History,
} from 'tiptap-extensions'
import marked from 'marked'

import TagPermission from '@/mixins/mixin-tag-permission'

export default {
    mixins: [TagPermission],
    components: {
        EditorContent,
        EditorMenuBubble,
        STagsForm,
    },
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            local_loading: true,
            template_autosave_description_timeout: null,
            template_autosave_description_status: 'none',
            accordion_template_project_description_expanded: true,
            template_autosave_next_step_timeout: null,
            template_autosave_next_step_status:  'none',
            accordion_template_project_next_step_expanded: true,
            template_project_editor_next_step: null,
            template_system_tags: [
                {name: 'Label', title: 'Labels', subtitle: 'Add/Edit Labels'},
                {name: 'Product', title: 'Products', subtitle: 'Add/Edit Product Tags'},
                {name: 'Platform', title: 'Platforms', subtitle: 'Add/Edit Platform Tags'}
            ],
            dialog_template_tags_form : false,
            template_project_tag_item : null,
            template_project_tag_type: null,
            template_project_custom_tag_type: null,
            template_project_tag_search: null,
            template_autosave_tags_timeout: null,
            template_autosave_tags_status: 'none',
            snackbar_status: false,
            snackbar_text: '',
        }
    },
    mounted() {
        this.local_index()
    },
    watch: {
        '$route.params.id': {
            handler () {
                this.local_index()
            },
            deep: true
        }
    },
    computed: {
        template_group_id () {
            return this.$route.params.id
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('TemplateGroup', {
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),
        ...mapState('TemplateProject', {
            template_project_list: 'list',
            template_project_item: 'item',
            template_project_filters: 'filters',
            template_project_response: 'response',
            template_project_form_mode: 'form_mode',
        }),
        ...mapState('TagSection', {
            tag_section_list: 'list',
            tag_section_item: 'item',
            tag_section_form_mode: 'form_mode',
            tag_section_response: 'response',
        }),
        ...mapState('Association', {
            association_response: 'response',
        }),
        ...mapState('Tag', {
            tag_list: 'list',
            tag_item: 'item',
            tag_filters: 'filters',
            tag_response: 'response',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
    methods: {
        async local_index() {
            await this.template_project_clear_item()
            await this.local_template_project_show()
            this.local_load_tags_list()
            this.local_set_template_project_editor_next_step()
            this.local_loading = false
        },

        async local_template_project_show() {
            await this.template_project_show(
                {
                    id : this.template_group_id,
                    params : {
                        'include': 'tags',
                        'fields[tags]': 'tags.id,tags.label,tags.color,tags.type',
                        'fields[template_projects]': 'id,title,next_step,description,template_group_id',
                    }
                }
            )
        },

        async local_load_tags_list() {
            await this.tag_section_clear()
            await this.tag_section_index({
                'filter[is_active]': 1,
                'fields[tag_sections]': 'id,slug,name',
            })
            let custom_type = this.pluck_tag_section_slug(this.tag_section_list, 'slug')
            let system_type = ['label','product','platform']
            await this.tag_clear()
            await this.tag_index({
                'filter[type]' : [...custom_type, ...system_type],
                'fields[tags]': 'id,color,label,type',
            })
        },

        pluck_tag_section_slug(objs, name) {
            var data = [];
            for(var i in objs){
                if(objs[i].hasOwnProperty(name)){
                    data.push(objs[i][name]);
                }
            }
            return data;
        },

        local_set_template_project_editor_next_step() {
            if (this.template_project_editor_next_step != null) {
                this.template_project_editor_next_step.destroy()
            }

            this.template_project_editor_next_step = new Editor({
                extensions: [
                    new Bold(),
                    new Italic(),
                    new Underline(),
                    new Strike(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new History(),
                ],
                content: marked(this.template_project_item.next_step ? this.template_project_item.next_step : ' ', { sanitize: true }),
                onUpdate: this.local_template_project_next_step_update_debounced,
                editable: this.$can('templates.update')
            })
        },

       async local_template_project_next_step_update_debounced(state) {
            clearTimeout(this.template_autosave_next_step_timeout)
            this.template_autosave_next_step_status = 'none'
            this.template_autosave_next_step_timeout = window.setTimeout(async () => {
                this.template_autosave_next_step_status = 'loading'
                this.template_project_item.next_step = turndownService.turndown(state.getHTML())
                if(this.template_project_item.next_step === '<p></p>') {
                    this.template_project_item.next_step = null
                }
                this.template_project_item.template_group_id = this.template_group_id
                await this.template_project_update(this.template_project_item)
                this.template_autosave_next_step_status = 'done'
                clearTimeout(this.template_autosave_next_step_timeout)
            }, 1000)
        },


        async local_template_project_update_autosave() {
            clearTimeout(this.template_autosave_description_timeout)
            this.template_autosave_description_status = 'none'
            this.template_autosave_description_timeout = window.setTimeout(async () => {
                this.template_autosave_description_status = 'loading'
                this.template_project_item.template_group_id = this.template_group_id
                await this.template_project_update(this.template_project_item)
                this.template_autosave_description_status = 'done'
                clearTimeout(this.template_autosave_description_timeout)
            }, 1000)
        },

        async local_template_project_tag_edit(type, custom_tag = null) {
            this.dialog_template_tags_form = true
            this.template_project_tag_item = null
            this.template_project_tag_type = type
            this.template_project_custom_tag_type = custom_tag
        },

        async dialog_template_tags_form_close() {
            this.template_project_tag_type = null
            this.dialog_template_tags_form = false
            this.$notify(false)
        },

        local_reset_error_snackbar() {
            this.snackbar_status = false
            this.snackbar_text = null
        },

        local_template_project_tag_filter(type) {
            if (type) {
               return this.template_project_item && this.template_project_item.tags ? this.template_project_item.tags.filter(item => item.type === type.toLowerCase()) : []
            }
        },

        local_tag_filter(type, unselected = false) {
            let filtered_tag_list = this.tag_list.filter(item => item.type === (type ? type.toLowerCase() : type))
            if (unselected) {
                filtered_tag_list = _.differenceBy(filtered_tag_list, this.template_project_item.tags, 'id')
            }
            return filtered_tag_list;
        },

        async local_template_project_select_tag(tag) {
            if ((typeof this.template_project_tag_item) !== 'object') {
                this.template_project_tag_search = null
                this.template_project_tag_item = null
                return
            }

            if (tag) {
                clearTimeout(this.template_autosave_tags_timeout)
                this.template_autosave_tags_status = 'loading'
                const data = { source_type: 'TemplateProject', source_id : this.$route.params.id, target_type: 'Tag', target_id: tag.id, type: tag.type }
                await this.local_association_store(data, 'tags')
                this.template_project_tag_item = null
                this.local_clear_tags_timeout()
            }
        },

        async local_template_project_tag_store() {
            if (this.template_project_tag_item) {
                this.local_reset_error_snackbar()
                this.local_tags_loading('loading')
                if ((typeof this.template_project_tag_item) === 'string' && this.check_user_tag_permission('store')) {
                    await this.tag_clear_item()
                    this.tag_item.label = this.template_project_tag_item
                    this.tag_item.type = this.template_project_tag_type.toLowerCase()
                    await this.tag_store({ ...this.tag_item, mode: 'add_update' })
                    if (this.tag_response && this.tag_response.status !== 'success') {
                        this.local_tags_loading('none')
                        this.snackbar_status = true
                        return this.snackbar_text = this.tag_response.server && this.tag_response.server.errors && this.tag_response.server.errors.label ? this.tag_response.server.errors.label[0] : 'Error adding the tag.'
                    }
                    this.template_project_tag_item = _.cloneDeep(this.tag_item)
                }
                this.project_tag_store_type = this.template_project_tag_item.type
                if(this.template_project_tag_item && this.template_project_tag_item.type === 'label') this.project_tag_store_type = 'template_project_label'
                if(this.template_project_tag_item && this.template_project_tag_item.type === 'platform') this.project_tag_store_type = 'template_project_platform'
                if(this.template_project_tag_item && this.template_project_tag_item.type === 'product') this.project_tag_store_type = 'template_project_product'
                const data = { source_type : 'TemplateProject', source_id : this.template_project_item.id, target_type: 'Tag', target_id : this.template_project_tag_item.id, type: this.project_tag_store_type }
                await this.local_association_store(data, 'tags')
                this.template_project_tag_item = null
                await this.tag_clear_item()
                this.local_clear_tags_timeout()
            }
        },

        local_clear_tags_timeout() {
            this.template_autosave_tags_status = 'done'
            this.template_autosave_tags_timeout = window.setTimeout(() => {
                this.template_autosave_tags_status = 'none'
                clearTimeout(this.template_autosave_tags_timeout)
            }, 1000)
        },
        local_tags_loading(type) {
            clearTimeout(this.template_autosave_tags_timeout)
            this.template_autosave_tags_status = type
        },

        async local_association_store(data, include) {
            await this.association_store(data)
            if (this.association_response.status === 'success') {
               this.local_template_project_show()
            }
        },
        async local_association_destroy(data, include) {
            await this.association_destroy(data.association)
            if (this.association_response.status === 'success') {
                this.local_template_project_show()
            }
        },

        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_store: 'store',
            template_group_update: 'update',
            template_group_reorder: 'reorder',
            template_group_select: 'select',
            template_group_destroy: 'destroy',
            template_group_clear: 'clear',
            template_group_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateProject', {
            template_project_index: 'index',
            template_project_update: 'update',
            template_project_show: 'show',
            template_project_select: 'select',
            template_project_visibility: 'visibility',
            template_project_store: 'store',
            template_project_group_move: 'group_usecase_move',
            template_project_group_copy: 'group_copy_store',
            template_project_group_clone: 'group_clone_store',
            template_project_reorder: 'reorder',
            template_project_destroy: 'destroy',
            template_project_clear: 'clear',
            template_project_clear_item: 'clear_item',
        }),
        ...mapActions('TagSection', {
            tag_section_index: 'index',
            tag_section_clear: 'clear',
            tag_section_clear_item: 'clear_item',
        }),
        ...mapActions('Association', {
            association_store: 'store',
            association_destroy: 'destroy',
        }),
        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_clear: 'clear',
            tag_clear_item: 'clear_item',
        }),
    }
}
</script>

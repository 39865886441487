import moment from 'moment'

moment.updateLocale('en', {
    relativeTime : {
        future: "in %s",
        past: "%s ago",
        s  : 'secs',
        ss : '%d secs',
        m:  "a min",
        mm: "%d mins",
        h:  "an hr",
        hh: "%d hrs",
        d:  "a day",
        dd: "%d days",
        w:  "a week",
        ww: "%d weeks",
        M:  "a month",
        MM: "%d months",
        y:  "a year",
        yy: "%d years"
    }
})

const diffHuman = (date, props) => {
    const { withYearFormat = '[on] MMM D, YYYY [at] h:mm A', format = '[on] MMM D [at] h:mm A' } = props || {}

    if (diffYear(date) > 0) return moment.utc(date).local().format(withYearFormat)
    if (diffHours(date) > 12) return moment.utc(date).local().format(format)

    return moment.utc(date).local().fromNow()
}

const diffHumanSimple = (date) => {
    return moment.utc(date).local().fromNow()
}

const diffHours = (date) => {
    return moment(date).diff(moment().format('YYYY-MM-DD'), 'hours')
}

const diffYear = (date) => {
    return moment(date).diff(moment().format('YYYY-MM-DD'), 'year')
}

const diffMonths = (date) => {
    return moment(date).diff(moment().format('YYYY-MM-DD'), 'months')
}

const diffSimple = (date, props) => {
    const { format = null, type = null } = props || {}
    const currentYear = moment().year()
    const dateYear = moment(date).year()
    const formatter = currentYear !== dateYear || type === 'includeYear' ? 'MMM D, YYYY' : 'MMM D'

    return moment.utc(date).format(format ?? formatter)
}

const diffFormat = (date, type = null) => {
    if (type === 'range') {
        const { start_date, due_date } = date
        const currentYear = moment().year()
        const startYear = moment(start_date).year()
        const dueYear = moment(due_date).year()
        return (currentYear !== startYear || currentYear !== dueYear) ? 'MMM D, YYYY' : 'MMM D'
    }
    return Math.abs(diffYear(date)) ? 'MMM D, YYYY' : 'MMM D'
}

const formattedTime = (duration) => {
    if (!duration) return

    const hours = JSON.stringify(duration.hours())
    const mins = JSON.stringify(duration.minutes())
    const seconds = JSON.stringify(duration.seconds())

    return `
        ${ _.size(hours) === 1 ? '0' + hours : hours }:${ _.size(mins) === 1 ? '0' + mins : mins }:${ _.size(seconds) === 1 ? '0' + seconds : seconds}
    `
}

export {
    diffHuman,
    diffHumanSimple,
    diffHours,
    diffYear,
    diffMonths,
    diffFormat,
    diffSimple,
    formattedTime
}

<template>
    <a-card v-bind="$attrs" class="u-relative u-rounded-corners">
        <div v-if="!hideVisibilityBar" class="c-visibility-bar"></div>
        <slot name="divider"></slot>
        <div class="pa-6">
            <div class="u-flex">
                <div class="u-wfull">
                    <h2 class="md-heading-6 font-weight-medium" :title="usecase.title && usecase.title.length >= (local_is_lgdown ? 185 : 248) ? usecase.title : ''">{{ usecase.title | truncateText(local_is_lgdown ? 185 : 248) }}</h2>
                    <div class="mt-2 u-flex align-center">
                        <div class="d-inline-flex align-center mr-4">
                            <slot name="success-criteria-stats"></slot>
                        </div>
                        <div class="d-inline-flex align-center mr-4">
                            <slot name="testcase-stats"></slot>
                        </div>
                    </div>
                    <slot name="tags">
                        <div class="u-flex-center-y flex-wrap mt-2" v-if="usecase.tags && usecase.tags.length">
                            <template v-for="(tag) in usecase.tags">
                                <g-tags :can-update="false" :tag="tag" type="Usecase" hide-clear-icon :key="tag.id"></g-tags>
                            </template>
                        </div>
                    </slot>
                </div>
                <slot name="actions"></slot>
            </div>
        </div>
    </a-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
    inheritAttrs: false,
    props: {
        usecase: {
            type: Object,
            required: true
        },
        expanded: {
            type: Boolean,
            default: false
        },
        hideVisibilityChange: {
            type: Boolean,
            default: false
        },
        hideVisibilityBar: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        local_is_lgdown () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        ...mapState('Interface', {
            loading: 'loader'
        }),
    },

    methods: {
    }
}
</script>


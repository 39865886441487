<template>
    <div>
        <!-- Customer name & Salesforce icon -->
        <div class="u-flex-center-y mb-3" style="column-gap: 12px;">
            <p class="md-body-2 mb-0 grey--text text--darken-2 font-weight-medium">
                {{ projectObj.customer_name | truncateText(100) }}
            </p>
            <loader-template height="18" width="30" class="u-rounded-corners-lg" v-if="loadingBridges"></loader-template>
            <template v-else>
                <template v-if="localCheckBridge('salesforce', 'opportunity.salesforce_id') || localCheckBridge('salesforce', 'opportunity.salesforce_opportunity_id')">
                    <a-icon size="6" color="grey lighten-1" class="">fiber_manual_record</a-icon>
                    <a
                        :href="localSalesforceInstanceUrl + '/' + localGetBridgeValue('salesforce', 'opportunity.salesforce_id', 'opportunity.salesforce_opportunity_id')"
                        target="_blank"
                        rel="noopener"
                        class="d-inline-flex items-center"
                        style="column-gap: 4px;"
                    >
                        <img :src="require('../../../assets/images/icon-salesforce.svg')" width="16" alt="Salesforce">
                        <span class="blue--text md-caption font-weight-medium">Salesforce</span>
                    </a>
                </template>
                <template v-if="localGetBridgeValue('hubspot', 'hubspot.project.create')">
                    <a-icon size="6" color="grey lighten-1" class="">fiber_manual_record</a-icon>
                    <a
                        :href="localHubspotInstanceUrl + '/contacts/' + localHubspotPortalId + '/deal/' + localGetBridgeValue('hubspot', 'hubspot.project.create', null)"
                        target="_blank"
                        rel="noopener"
                        class="d-inline-flex items-center"
                        style="column-gap: 4px;"
                    >
                        <img :src="require('../../../assets/images/apps/hubspot/logo.svg')" width="16" alt="Salesforce">
                        <span class="orange--text text--darken-3 md-caption font-weight-medium">HubSpot</span>
                    </a>
                </template>
            </template>
        </div>

        <!-- Title -->
        <h2 class="md-heading-6 mb-2 u-font-weight-semibold" style="line-height: 24px;">
            {{ projectObj.title }}
        </h2>

        <!-- Stage, Deal amount, Collaborators & Territories -->
        <div class="u-flex-center-y" style="column-gap: 12px;">
            <a-sheet :color="localGetStageColor({ field: 'bgColor' })" class="u-rounded-corners-xl d-inline-flex items-center" style="padding: 3px 8px 3px 6px; flex: 0 0 auto">
                <a-icon size="16" color="white">{{ localGetStageColor({ field: 'icon' }) }}</a-icon>
                <span class="md-caption white--text font-weight-medium ml-1 d-inline-block text-truncate u-wfull" style="max-width: 120px;">
                    {{ localGetStageColor({ field: 'stageName' }) }}
                </span>
            </a-sheet>

            <!-- Deal amount -->
            <a-icon size="6" color="grey lighten-1" class="">fiber_manual_record</a-icon>
            <g-currency tag="div" class="md-body-2 green--text text--darken-1 font-weight-medium" :value="projectObj.deal_amount"></g-currency>

            <!-- Collaborators -->
            <a-icon size="6" color="grey lighten-1" class="">fiber_manual_record</a-icon>
            <template v-if="localHasCollaborators">
                <s-users-deck
                    :list="projectObj.aggr_collaborators"
                    :count="3"
                    :avatar-size="24"
                    font-size="8"
                    hide-list directRole
                ></s-users-deck>
            </template>
            <a-tooltip v-else bottom>
                <template v-slot:activator="{ on }">
                    <a-btn v-on="on" height="24" width="24" icon small class="ma-0 pa-0 grey lighten-1" @click="mixOpenInNewWindow('projects-collaborators')">
                        <a-icon size="14" color="white">person</a-icon>
                    </a-btn>
                </template>
                <span>No Team Members Added</span>
            </a-tooltip>

            <!-- Territories -->
            <template v-if="projectObj.aggr_territories && projectObj.aggr_territories.length">
                <a-icon size="6" color="grey lighten-1" class="">fiber_manual_record</a-icon>
                <div ref="refTerritoryParent" class="u-overflow-hidden u-relative u-rounded-corners-lg" style="min-height: 32px; max-height: 32px; flex: 1 1 auto">
                    <a-sheet
                        v-if="territoryExpand"
                        @click="$emit('expandTerritory')"
                        v-ripple="true"
                        class="u-absolute u-z4 u-flex-center u-cursor-pointer grey lighten-5 u-shadow"
                        width="40"
                        height="32"
                        style="top: 0px; right: 0px; font-size: 20px; line-height: 20px"
                    >
                        <a-icon size="20" color="grey darken-2" outlined>chevron_right</a-icon>
                    </a-sheet>
                    <div ref="refTerritoryContainer" class="d-inline-flex align-center u-relative" style="min-height: 32px;">
                        <template v-if="projectObj.aggr_territories">
                            <template v-for="territory in localGetTerritories">
                                <g-tags :tag="territory" :key="territory.id" style="flex: 1 0 auto" hide-clear-icon></g-tags>
                            </template>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { SCompetitorTag, SUsersDeck } from '@/config/config-shared-components'
import MixinModalStateInjection from '../Mixins/MixinModalStateInjection'
import PartialProjectSummaryActions from './PartialProjectSummaryActions'
import PartialBottomGradient from './PartialBottomGradient.vue'

export default {
    components: {
        SUsersDeck,
        SCompetitorTag,
        PartialProjectSummaryActions,
        PartialBottomGradient
    },

    mixins: [MixinModalStateInjection],

    props: {
        noFetch: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            loading: true,
            loadingBridges: true,
            territoryExpand: false,
            projectObj: {},
            bridges: [],
        }
    },

    computed: {
        localSalesforceInstanceUrl () {
            return (this.user_self && this.user_self.organization && this.user_self.organization.salesforce_instance_url) || ''
        },

        localHubspotPortalId () {
            return this.user_self && this.user_self.organization && this.user_self.organization.hubspot_portal_id || ''
        },

        localHubspotInstanceUrl () {
            return this.user_self && this.user_self.organization && this.user_self.organization.hubspot_instance_url || ''
        },

        localHasCollaborators () {
            return _.size(this.projectObj.aggr_collaborators)
        },

        localGetTerritories () {
            if (!_.size(this.projectObj.aggr_territories)) return []

            const territoryIds = this.projectObj.aggr_territories.map(i => i.id)
            return this.territory_list.filter(i => territoryIds.includes(i.id))
        },

        ...mapState('User', {
            user_self: 'self'
        }),

        ...mapState('Territory', {
            territory_list: 'list'
        }),
    },

    methods: {
        async localIndex () {
            this.projectObj = this.mixGetSavedSummary.projectSummary
            this.bridges = this.mixGetSavedSummary.bridges
            this.territoryExpand = false
            this.localSetupActions()
            this.localFetchBridges()
        },

        async localFetchBridges () {
            this.bridges = []
            const params = { include: 'bridges', 'fields[projects]': 'id' }
            const { data: { bridges }, status } = await this.project_show({ id: this.mixParentState.projectId, stateless: true, params })

            this.bridges = status !== 'success' ? [] : _.cloneDeep(bridges)
            this.project_save_item({ id: this.projectObj.id, summary: { bridges, projectSummary: this.projectObj } })
            this.loadingBridges = false
        },

        localSetupActions () {
            if (!_.size(this.projectObj.aggr_territories) || this.mixLoading) return

            setTimeout(() => {
                const containerWidth = this.$refs.refTerritoryContainer.clientWidth
                const childrenTags = this.$refs.refTerritoryContainer.children
                const childrenWidth = Array.from(childrenTags).reduce((acc, c) => acc = c.clientWidth + acc, 0)
                const marginSize = _.size(childrenTags) * 8

                if ((childrenWidth + marginSize) > containerWidth) this.territoryExpand = true
            }, 150)
        },

        localGetStageColor ({ field = null }) {
            let value = {}
            if (this.projectObj.status === 'active') value = { stageName: this.projectObj.stage_name, bgColor: 'indigo lighten-2', icon: 'bolt' }
            if (this.projectObj.status === 'queue') value = { stageName: this.projectObj.stage_name, bgColor: 'indigo lighten-3', icon: 'web_stories' }
            if (this.projectObj.status === 'archived') value = { stageName: 'Archived', bgColor: 'grey darken-3', icon: 'archive' }
            if (this.projectObj.status === 'completed') {
                if (this.projectObj?.result?.status ===  'Positive') value = { stageName: 'Completed', bgColor: 'green darken-1', icon: 'thumb_up' }
                if (this.projectObj?.result?.status ===  'Neutral') value = { stageName: 'Completed', bgColor: 'indigo darken-1', icon: 'linear_scale' }
                if (this.projectObj?.result?.status ===  'Negative') value = { stageName: 'Completed', bgColor: 'red darken-1', icon: 'thumb_down' }
            }

            return !field ? value : value[field]
        },

        localGetBridgeValue (service, key) {
            if (!_.size(this.bridges)) return ''

            const integration = this.bridges.find(item => item.service === service && item.key === key)
            return (integration && integration.value) || ''
        },

        localCheckBridge (service, key) {
            return this.bridges.find(item => {
                return item.service === service && item.key === key
            })
        },

        ...mapActions('ProjectView', {
            project_show: 'show',
        })
    }
}
</script>

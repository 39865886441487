<template>
    <div>
        <template>
            <a-sheet class="transparent" max-width="656">
                <div class="u-flex-center-y mb-6">
                    <a-sheet class="mr-2 transparent u-relative" width="50%">
                        <div v-if="isLoading">
                            <loader-template width="100" height="16" class="mb-3 u-rounded-corners-sm"></loader-template>
                            <loader-template width="300" height="40" class="u-rounded-corners-xl"></loader-template>
                        </div>
                        <div v-else>
                            <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">Field</span>
                            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('timeline_field')">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">
                                        <a-select
                                            v-model="moduleItem.original.timeline_field"
                                            :items="timeline_field_types"
                                            placeholder="Select Field"
                                            class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                            :class="[{ 'c-wiggle-short u-border-error': local_get_error('timeline_field') }]"
                                            item-color="indigo darken-1"
                                            background-color="white"
                                            :append-icon="!moduleItem.original.timeline_field ? 'arrow_drop_down' : ''"
                                            :menu-props="{ rounded: 'lg', nudgeBottom: '4px', offsetY: true, closeOnContentClick: true, closeOnClick: true, }"
                                            @input="local_update('timeline_field', moduleItem.original.timeline_field)"
                                            @click:clear="local_clear_timeline_fields('timeline_field')"
                                            solo flat hide-details clearable
                                        ></a-select>
                                    </div>
                                </template>
                                <span>{{ local_get_error('timeline_field') }}</span>
                            </a-tooltip>
                        </div>
                    </a-sheet>
                    <a-sheet class="ml-2 transparent u-relative" width="50%">
                        <div v-if="isLoading">
                            <loader-template width="100" height="16" class="mb-3 u-rounded-corners-sm"></loader-template>
                            <loader-template width="300" height="40" class="u-rounded-corners-xl"></loader-template>
                        </div>
                        <div v-else>
                            <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">Timeline</span>
                            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('timeline_date_option')">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">
                                        <a-select
                                            v-model="date_option"
                                            :items="timeline_date_options"
                                            placeholder="Select predefined date option"
                                            class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                            :class="[{ 'c-wiggle-short u-border-error': local_get_error('timeline_date_option') }]"
                                            item-color="indigo darken-1"
                                            background-color="white"
                                            :open-on-clear="false"
                                            :append-icon="!moduleItem.original.timeline_date_option ? 'arrow_drop_down' : ''"
                                            :menu-props="{ rounded: 'lg', nudgeBottom: '4px', offsetY: true, closeOnContentClick: true, closeOnClick: true, }"
                                            @input="local_update('timeline_date_option', date_option)"
                                            @click:clear="local_clear_timeline_fields('timeline_date_option')"
                                            solo flat hide-details clearable
                                        >
                                            <template v-slot:selection="{ item }">
                                                <span class="md-subtitle-1 indigo--text text--darken-1">{{ item.text | truncateText(50) }}</span>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <span :class="['md-subtitle-1', { 'indigo--text text--darken-1': item.value === 'date_range' }]">{{ item.text | truncateText(50) }}</span>
                                            </template>
                                        </a-select>
                                    </div>
                                </template>
                                <span>{{ local_get_error('timeline_date_option') }}</span>
                            </a-tooltip>
                        </div>
                    </a-sheet>
                </div>
                <a-sheet v-if="moduleItem && moduleItem.original && moduleItem.original.timeline_field && date_option && date_option !== 'date_range' && date_option !== 'all_time' && !isLoading" class="u-flex align-start blue-grey lighten-5 blue-grey--text text--lighten-1 md-body-2 u-rounded-corners-lg pa-3">
                    <a-icon size="20" color="blue-grey lighten-1" class="mr-2" style="margin-top: 2px;">info</a-icon>
                    <div>
                        <span>This selection will show the data from <strong>{{ start_date }} - {{ end_date }}</strong></span>
                        <div class="md-caption mt-1">Projects without <strong>{{  local_field_type_label ? local_field_type_label : 'date' }}</strong> will be ignored</div>
                    </div>
                </a-sheet>
            </a-sheet>
            <div v-if="(date_option && date_option === 'all_time') && local_chart_types.includes(chartType)">
                <div class="mt-6" style="marginLeft:-2px">
                    <p class="md-body-1 body--text text--lighten-1 d-inline-block u-cursor-pointer pa-0 mb-0" @click="local_update('without_date', timeline_without_dates)">
                        <span class="u-icon-nudge"><a-icon size="20" :color="!timeline_without_dates ? 'grey darken-1' : 'blue'" :outlined="!timeline_without_dates">{{ !timeline_without_dates ? 'check_box_outline_blank' : 'check_box' }}</a-icon></span>
                        Include projects without<span class="font-weight-medium"> {{ local_field_type_label }}</span>
                    </p>
                </div>
            </div>
            <div v-if="date_option && date_option === 'date_range'">
                <a-sheet class="u-flex align-center transparent" max-width="656">
                    <a-sheet class="u-wfull transparent mr-2 u-relative" width="50%">
                        <span class="md-body-2 body--text text--lighten-1 mb-2 d-inline-block font-weight-medium">Start Date</span>
                        <a-sheet class="u-wfull u-rounded-corners-lg u-shadow u-border-transparent u-flex-center-y u-relative pr-3" style="z-index: 1" :class="[{ 'c-wiggle-short u-border-error': local_get_error('timeline_start_date') }]">
                            <vc-date-picker v-model="moduleItem.original.start_date" :max-date="moduleItem.original.due_date" class="d-block u-wfull" @input="$emit('update-dates', 'Timeline', 'start_date', moduleItem.original.start_date)" :model-config="modelConfig" :is-required="!!(moduleItem.original.start_date)">
                                <template v-slot="{ togglePopover }">
                                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('timeline_start_date')">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="u-wfull white u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="() => { togglePopover({ placement: 'bottom-start' }); }">
                                                <div class="u-flex-center grey lighten-4 u-rounded-corners-lg px-3" style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;">
                                                    <a-icon size="22" color="grey darken-2">today</a-icon>
                                                </div>
                                                <div class="md-body-1 u-wfull pa-3" :class="[(moduleItem.original.start_date ? 'grey--text text--darken-3' : 'body--text text--lighten-3')]">
                                                    {{ moduleItem.original.start_date ? local_format_date(moduleItem.original.start_date) : 'Ex: Jan 1, 2021' }}
                                                </div>
                                            </div>
                                        </template>
                                        <span>{{ local_get_error('timeline_start_date') }}</span>
                                    </a-tooltip>
                                </template>
                            </vc-date-picker>
                            <a-spacer></a-spacer>
                            <a-icon v-if="moduleItem.original.start_date" @click="local_clear_timeline_fields('start_date')" class="c-date-picker--clear" size="24">clear</a-icon>
                        </a-sheet>
                    </a-sheet>
                    <a-sheet class="u-wfull transparent ml-2 u-relative" width="50%">
                        <span class="md-body-2 body--text text--lighten-1 mb-2 d-inline-block font-weight-medium">End Date</span>
                        <a-sheet class="u-wfull u-rounded-corners-lg u-shadow u-border-transparent u-flex-center-y u-relative pr-3" style="z-index: 1" :class="[{ 'c-wiggle-short u-border-error': local_get_error('timeline_due_date') }]">
                            <vc-date-picker v-model="moduleItem.original.due_date" :min-date="moduleItem.original.start_date" class="d-block u-wfull" @input="$emit('update-dates', 'Timeline', 'due_date', moduleItem.original.due_date)" :model-config="modelConfig" :is-required="!!(moduleItem.original.due_date)">
                                <template v-slot="{ togglePopover }">
                                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('timeline_due_date')">
                                        <template v-slot:activator="{ on, value }">
                                            <div v-on="on" class="u-wfull white u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="() => { togglePopover({ placement: 'bottom-start' }); }">
                                                <div class="u-flex-center grey lighten-4 u-rounded-corners-lg px-3" style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;">
                                                    <a-icon size="22" color="grey darken-2">today</a-icon>
                                                </div>
                                                <div class="md-body-1 u-wfull pa-3" :class="[(moduleItem.original.due_date ? 'grey--text text--darken-3' : 'body--text text--lighten-3')]">
                                                    {{ moduleItem.original.due_date ? local_format_date(moduleItem.original.due_date) : 'Ex: Jan 1, 2021' }}
                                                </div>
                                            </div>
                                        </template>
                                        <span>{{ local_get_error('timeline_due_date') }}</span>
                                    </a-tooltip>
                                </template>
                            </vc-date-picker>
                            <a-spacer></a-spacer>
                            <a-icon v-if="moduleItem.original.due_date" @click="local_clear_timeline_fields('due_date')" class="c-date-picker--clear" size="24">clear</a-icon>
                        </a-sheet>
                    </a-sheet>
                </a-sheet>

                <a-sheet v-if="!isLoading" max-width="656" class="u-flex align-start blue-grey lighten-5 blue-grey--text text--lighten-1 md-body-2 u-rounded-corners-lg pa-3 mt-7">
                    <a-icon size="20" color="blue-grey lighten-1" class="mr-2" style="margin-top: 2px;">info</a-icon>
                    <div class="">Projects without <strong>{{ local_field_type_label }}</strong> will be ignored</div>
                </a-sheet>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        moduleItem: {
            type: Object,
            required: true
        },
        configErrors: {
            type: Array,
        },
        canUpdate: {
            type: Boolean | Number,
            default: true
        },
        isLoading: {
            type: Boolean | Number,
            default: false
        },
        chartType: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            date_option: null,
            start_date: '',
            end_date: '',
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
            timeline_field_types: [
                { text: 'Project -> Start Date', value: 'start_date' },
                { text: 'Project -> End Date', value: 'due_date' },
                { text: 'Project -> Estimated Start Date', value: 'estimated_start_date' },
                { text: 'Project -> Estimated End Date', value: 'estimated_end_date' },
            ],
            timeline_date_options: [
                { text: 'This Month', value: 'current_month' },
                { text: 'Last Month', value: 'last_month' },
                { text: 'This Quarter', value: 'current_quarter' },
                { text: 'Last Quarter', value: 'last_quarter' },
                { text: 'This Year', value: 'current_year' },
                { text: 'Last Year', value: 'last_year' },
                { text: 'Select a specific date range', value: 'date_range' },
            ],
            local_field_type_label: '',
            timeline_without_dates: false,
            local_chart_types: ['Numbers', 'Pie', 'Donut']
        }
    },

    mounted () {
        this.local_index()
    },

    watch: {
        isLoading(newVal, oldVal) {
            if(!newVal && oldVal) this.local_index()
        }
    },

    computed: {
        local_current_fiscal_month () {
            return this.user_self?.organization?.financial_month
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('FiscalTimeline', {
            fiscal_timeline_list: 'list',
        }),
    },

    methods: {
        async local_index () {
            await this.local_set_date_option()
            this.timeline_without_dates = this.moduleItem.original.without_date === 'included' ? true : false
            this.local_get_selection_range(this.moduleItem.original['timeline_date_option'])
            this.local_set_field_label(this.moduleItem.original.timeline_field)
        },

        local_set_date_option () {
            this.date_option = this.moduleItem.original.timeline_date_option
            if (!this.local_chart_types.includes(this.chartType)) {
                if (this.moduleItem &&  this.moduleItem.original && this.moduleItem.original.timeline_date_option === 'all_time') {
                    this.date_option = 'current_year'
                    this.local_update('timeline_date_option', 'current_year')
                }
                return
            }
            this.timeline_date_options.unshift({ text: 'All Time', value: 'all_time' })
        },

        local_update (field, value) {
            this.local_set_field_label(value)
            this.moduleItem.original[field] = value
            if (field === 'without_date'){
                this.timeline_without_dates = !value
                this.moduleItem.original[field] = this.timeline_without_dates ? 'included' : 'not_included'
            }
            if (field === 'timeline_date_option') this.local_get_selection_range(value)
            this.$emit('update', 'Timeline', field, this.moduleItem.original[field])
        },

        local_set_field_label (field) {
            switch (field) {
                case 'start_date': return this.local_field_type_label = 'Start Date'
                case 'due_date': return this.local_field_type_label = 'End Date'
                case 'estimated_start_date': return this.local_field_type_label = 'Estimated Start Date'
                case 'estimated_end_date': return this.local_field_type_label = 'Estimated End Date'
                default: return
            }
        },

        local_clear_timeline_fields (field) {
            this.moduleItem.original[field] = ''
            if (['start_date', 'due_date'].includes(field)) {
                this.local_update(field)
                return this.local_remove_config(field)
            }
            this.local_update(field)
            this.local_remove_config(field)
            if (field === 'timeline_date_option') {
                this.moduleItem.original.start_date = ''
                this.moduleItem.original.due_date = ''
                delete this.moduleItem.original.start_date
                delete this.moduleItem.original.due_date
                this.local_remove_config('start_date')
                this.local_remove_config('due_date')
            }
        },

        local_remove_config (slug) {
            this.$emit('remove-config', slug)
        },

        local_get_error (slug, field = 'message') {
            if (!slug) return null
            const item = this.configErrors.find(item => item.slug === slug)
            return (item && item[field])
        },

        local_format_date (date, range = false, simple = false) {
            if (range) return moment(date).format('YYYY-MM-DD')
            if (simple) return moment(date).format('MMM D')

            return date ? moment(date).format('MMM D, YYYY') : null
        },

        local_get_selection_range (date_option) {
            const format = 'MMM D, YYYY'
            const { start_date, end_date } = this.local_get_dates_for_range(date_option)
            this.start_date = start_date ? moment(start_date).format(format) : ''
            this.end_date = end_date ? moment(end_date).format(format) : ''
        },

        local_get_dates_for_range (range_slug) {
            if (!range_slug) return { start_date: '', end_date: '' }
            const item = this.fiscal_timeline_list.find(item => item[range_slug])
            return (item && item[range_slug]) ?? { start_date: '', end_date: '' }
        },

        ...mapActions('FiscalTimeline', {
            fiscal_timeline_show: 'show'
        })
    }
}
</script>

const PlanAuthorizer = {
    install(Vue, { store }) {
        Vue.prototype.$services = (slug) => {
            const { orgPlanLevel, services } = setUp(store)
            if (!orgPlanLevel) return logError('Organization plan level is null')
            if (_.size(services) === 0) return logError('Applet services returns empty array')

            const permission = _.find(services, { slug })
            if (!permission) return logError(`"${slug}" service not found`)

            return orgPlanLevel >= permission.plan_level
        }

        Vue.prototype.$triggers = (slug) => {
            const { orgPlanLevel, triggers } = setUp(store)
            if (!orgPlanLevel) return logError('Organization plan level is null')
            if (_.size(triggers) === 0) return logError('Applet triggers returns empty array')

            const permission = _.find(triggers, { slug })
            if (!permission) return logError(`"${slug}" trigger not found`)

            return orgPlanLevel >= permission.plan_level
        }

        Vue.prototype.$actions = (slug) => {
            const { orgPlanLevel, actions } = setUp(store)
            if (!orgPlanLevel) return logError('Organization plan level is null')
            if (_.size(actions) === 0) return logError('Applet actions returns empty array')

            const permission = _.find(actions, { slug })
            if (!permission) return logError(`"${slug}" trigger not found`)

            return orgPlanLevel >= permission.plan_level
        }
    }
}

const getPlanLevel = (store) => {
    const { level } = (store.state.Organization.plan && store.state.Organization.plan.subscription_plan) || {}
    return level
}

const logError = (message) => {
    console.error(message)
    return false
}

const setUp = (store) => {
    const setupData = { orgPlanLevel: null, services: [], triggers: [], actions: [] }
    setupData['orgPlanLevel'] = getPlanLevel(store) || {}
    setupData['services'] = store.state.AppletService.list || []
    setupData['triggers'] = store.state.AppletTrigger.list || []
    setupData['actions'] = store.state.AppletAction.list || []

    return setupData
}

export default PlanAuthorizer

<template>
    <a-container v-on="$listeners" container--fluid grid-list-xl class="pa-6">
        <a-layout wrap align-center>
            <a-flex xs12>
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                <a-text-field
                    v-model="user_item.name"
                    placeholder="Enter Name"
                    background-color="neutral"
                    solo flat hide-details
                >
                </a-text-field>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="user_response.server && user_response.server.errors && user_response.server.errors.name">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ user_response.server.errors.name[0] }}
                </span>
                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                    <a-icon size="16">info</a-icon>
                    The name will be helpful to identify the user.
                </span>
            </a-flex>
            <a-flex xs12>
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Email</label>
                <a-text-field
                    v-model="user_item.email"
                    placeholder="Enter Email Address"
                    background-color="neutral"
                    solo flat hide-details
                >
                </a-text-field>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="user_response.server && user_response.server.errors && user_response.server.errors.email">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ user_response.server.errors.email[0] }}
                </span>
                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                    <a-icon size="16">info</a-icon>
                    Email address will be used as username for login.
                </span>
            </a-flex>
            <a-flex xs12>
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Job Title (Optional)</label>
                <a-text-field
                    v-model="user_item.job_title"
                    placeholder="Enter Job Title"
                    background-color="neutral"
                    solo flat hide-details
                >
                </a-text-field>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="user_response.server && user_response.server.errors && user_response.server.errors.job_title">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ user_response.server.errors.job_title[0] }}
                </span>
                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                    <a-icon size="16">info</a-icon>
                    The job title will be helpful to identify the user.
                </span>
            </a-flex>
            <a-flex xs12>
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Role</label>
                <a-autocomplete
                    v-model="user_item.role_id"
                    :items="role_list"
                    item-text="name"
                    item-value="id"
                    placeholder="Select Role"
                    background-color="neutral"
                    solo flat hide-details
                >
                    <template v-slot:no-data>
                        <div class="px-4 py-2">
                            <span class="md-body-1 grey--text">Role not found</span>
                        </div>
                    </template>
                </a-autocomplete>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="user_response.server && user_response.server.errors && user_response.server.errors.role_id">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ user_response.server.errors.role_id[0] }}
                </span>
                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                    <a-icon size="16">info</a-icon>
                    Role of the user from the list.
                </span>
            </a-flex>
            <a-flex xs12>
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Manager (Optional)</label>
                <GUsersSelectDropdown
                    v-model="user_item.manager"
                    :list="managerList"
                    :loading="mixinIsLoading('search')"
                    @search="localSearchUsers"
                    placeholder="Select Manager"
                    class="grey lighten-4 px-4"
                    dropdownClass="c-tiny-scroll py-2"
                    clearable returnObject
                >
                    <template #item="{ props: { item } }">
                        <div class="u-flex-center-y">
                            <g-avatar :item="item"></g-avatar>
                            <g-profile-info :item="item" type-user set-max-width></g-profile-info>
                        </div>
                    </template>
                    <template #noData>
                        <div class="md-body-1 grey--text text--darken-1 px-2">No Member found</div>
                    </template>
                    <template #emptyData>
                        <div class="md-body-1 grey--text text--darken-1 px-2">No Member found</div>
                    </template>
                </GUsersSelectDropdown>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="user_response.server && user_response.server.errors && user_response.server.errors.manager_id">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ user_response.server.errors.manager_id[0] }}
                </span>
                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                    <a-icon size="16">info</a-icon>
                    Select the manager of this user.
                </span>
            </a-flex>
            <a-flex xs12 ml-1 pl-0 v-if="formMode === 'add'">
                <a-checkbox v-model="user_item.send_email_invite" class="mx-2 mt-1" color="primary" hide-details label="Send user invitation via email"></a-checkbox>
            </a-flex>
        </a-layout>
    </a-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mixinLoading from '@/mixins/mixin-module-loading-setup'

export default {
    props: {
        managerList: {
            type: Array
        },
        formMode: {
            type: String
        }
    },

    mixins: [mixinLoading],

    data () {
        return {
            local_manager_search: null,
            searchTimeout: null,
        }
    },

    computed: {
        ...mapState('Role', {
            role_list: 'list',
        }),

        ...mapState('User', {
            user_item: 'item',
            user_response: 'response'
        })
    },

    methods: {
        localSearchUsers (val) {
            this.mixinSetLoading('search')
            clearTimeout(this.searchTimeout)

            this.searchTimeout = setTimeout(async () => {
                await this.localFetchUsersFromSearch(val)
                this.mixinResetLoading('search')
                clearTimeout(this.searchTimeout)
            }, 500)
        },

        async localFetchUsersFromSearch (searchValue = null) {
            const params = { 'include': 'role', 'sort': 'name', count: 25, page: 1, 'filter[is_active]': 1 }

            if (searchValue) params['filter[name]'] = searchValue
            await this.users_total_index(params)
        },

        ...mapActions('User', {
            users_total_index: 'total_index',
        })
    }
}
</script>

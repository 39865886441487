<template>
    <a-main>
       <template v-if="isLoading || workflowStageLoading">
            <PValidationTriggerLoader/>
       </template>
        <template v-if="!isLoading && !workflowStageLoading">
            <div class="u-flex pa-6">
                <div class="c-trigger-icon-view">
                    <div class="align-center justify-center u-flex c-trigger-menu-icon amber accent-3" style="border: 2px solid #FFE57F !important">
                        <a-icon color="white" size="20">flash_on</a-icon>
                    </div>
                </div>
                <div class="ml-3">
                    <p class="md-subtitle-1 text-uppercase indigo--text text--darken-1 mb-0 font-weight-bold">When: Trigger</p>
                    <p class="md-body-2 grey--text text--darken-1 mb-0">Triggers initiate the validation when specific conditions are met.</p>
                </div>
            </div>
            <a-divider class="grey lighten-3"></a-divider>
            <div class="px-6 pt-6" v-if="triggerError">
                <PValidationWorkflowWarningInfo
                    :errorMessage="'Choose a trigger to proceed with the Validation.'"
                />
            </div>
            <div class="u-flex pa-6">
                <div class="u-wfull" v-if="triggersList && triggersList.length">
                    <template v-if="editMode === true || item.automation_trigger_id === null">
                        <template v-for="(trigger, index) in triggersList">
                            <div 
                                :key="trigger.id" v-if="selectedTiggerId !== trigger.id" 
                                class="u-wfull u-rounded-corners pa-5 px-4 u-flex c-default-tigger" 
                                :class="[ index !== 0 ? 'mt-6' : '', canUpdate ? 'u-cursor-pointer' : '']"
                                @click="canUpdate ? $emit('select-trigger', trigger) : null"
                            >
                                <div class="c-tigger-icon u-flex-center-y u-wfull u-rounded-corners px-3">
                                    <a-icon color="grey" size="22">{{ triggerIcon(trigger.slug) }}</a-icon>
                                </div>
                                <div class="ml-3">
                                    <p class="'grey--text text--darken-1 md-subtitle-1 mb-0 u-font-semibold">{{ trigger.title }}</p>
                                    <p class="md-caption grey--text mb-0 font-weight-medium mt-1" style="font-size: 14px;" :title="trigger.description">{{ trigger.description }}</p>
                                </div>
                            </div>
                            <div class="u-wfull u-rounded-corners pa-5 px-4 u-flex c-default-select" v-else  :class="[ index !== 0 ? 'mt-6' : '']">
                                <div class="u-relative">
                                    <div class="c-tigger-select-icon u-flex-center-y u-rounded-corners px-3">
                                        <a-icon color="indigo darken-1" size="22">{{ triggerIcon(trigger.slug) }}</a-icon>
                                    </div>
                                    <div v-if="!['project-result'].includes(trigger.slug)" class="c-slected-trigger-divider"></div>
                                </div>
                                <div class="ml-3 u-wfull" style="z-index: 5;">
                                    <p class="indigo--text text--darken-1 md-subtitle-1 mb-0 font-weight-bold">{{ trigger.title }}</p>
                                    <p class="md-caption grey--text mb-0 font-weight-medium mt-1" style="font-size: 14px;" :title="trigger.description">{{ trigger.description }}</p>
                                    <div class="mt-8 u-flex" v-if="!['project-result'].includes(trigger.slug)">
                                        <div class="c-stage-from mr-4">
                                            <p class="text--grey text--darken-3 mb-0 font-weight-medium mt-1" style="font-size: 16px; font-weight: 600;">From</p>
                                            <a-autocomplete
                                                v-model="item.stage_from"
                                                :items="stageFromlist(trigger.slug)"
                                                item-text="name"
                                                item-value="id"
                                                @change="$emit('stage-update')"
                                                class="pa-0 mt-3 u-rounded-corners-lg u-border c-custom-automation-placeholder"
                                                :placeholder="item.stage_from && item.stage_from.length ? null : 'Any Queue/Active Stage'"
                                                background-color="grey lighten-5"
                                                :disabled="!canUpdate"
                                                solo flat hide-details multiple deletable-chips small-chips auto-select-first hide-selected
                                            >   
                                                <template #item="{ item }">
                                                    <span :title="item.name">{{ item.name | truncateText(70) }}</span>
                                                </template>
                                                <template #selection="{ item, parent }">
                                                    <template>
                                                        <div class="c-slected-trigger-stage mr-2 my-1">
                                                            <span class="pa-2 d-inline-flex align-center u-rounded-corners grey--text text--darken-3">
                                                                <span class="mr-2 md-body-2 grey--text text--darken-3" :title="item.name">{{ item.name | truncateText(20) }}</span>
                                                                <a-icon v-if="canUpdate" size="16" @click="parent.selectItem(item)" class="u-cursor-pointer grey--text text--darken-1">cancel</a-icon>
                                                            </span>
                                                        </div>
                                                    </template>
                                                </template>
                                            </a-autocomplete>
                                            <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                                <a-icon size="16" color="grey" class="u-icon-nudge-xs">info</a-icon>
                                                You can select multiple stages.
                                            </span>
                                        </div>
                                        <div class="c-stage-to">
                                            <p class="text--grey text--darken-3 mb-0 font-weight-medium mt-1" style="font-size: 16px; font-weight: 600;">To</p>
                                            <template v-if="trigger.slug === 'project-stage-changed'">
                                                <a-autocomplete
                                                    v-model="item.stage_to"
                                                    :items="stageTolist(trigger.slug)"
                                                    item-text="name"
                                                    item-value="id"
                                                    @change="$emit('stage-update')"
                                                    class="pa-0 mt-3 u-rounded-corners-lg u-border c-custom-automation-placeholder"
                                                    :placeholder="item.stage_to && item.stage_to.length ? null : 'Any Queue/Active Stage'"
                                                    background-color="grey lighten-5"
                                                    :disabled="!canUpdate"
                                                    solo flat hide-details multiple deletable-chips small-chips auto-select-first hide-selected
                                                >   
                                                    <template #item="{ item }">
                                                        <span :title="item.name">{{ item.name | truncateText(70) }}</span>
                                                    </template>
                                                    <template #selection="{ item, parent }">
                                                        <template>
                                                            <div class="c-slected-trigger-stage mr-2 my-1">
                                                                <span class="pa-2 d-inline-flex align-center u-rounded-corners grey--text text--darken-3">
                                                                    <span class="mr-2 md-body-2 grey--text text--darken-3" :title="item.name">{{ item.name | truncateText(20) }}</span>
                                                                    <a-icon v-if="canUpdate" size="16" @click="parent.selectItem(item)" class="u-cursor-pointer grey--text text--darken-1">cancel</a-icon>
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </template>
                                                </a-autocomplete>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                                    <a-icon size="16" color="grey" class="u-icon-nudge-xs">info</a-icon>
                                                    You can select multiple stages.
                                                </span>
                                            </template>
                                            <template v-if="['project-completed','project-result'].includes(trigger.slug)">
                                                <div class="c-trigger-stage green lighten-4 mt-3 u-rounded-corners-lg">
                                                    <p class="text-center green--text text--darken-2 mb-0 font-weight-medium" style="padding: 12px 0px;">
                                                        <a-icon color="green darken-2" class="u-icon-nudge-xs mr-1" size="20">task_alt</a-icon>
                                                        Completed
                                                    </p>
                                                </div>
                                            </template>
                                            <template v-if="trigger.slug === 'project-archived'">
                                                <div class="c-trigger-stage blue-grey lighten-5 mt-3 u-rounded-corners-lg">
                                                    <p class="text-center blue-grey--text text--darken-1 mb-0 font-weight-medium" style="padding: 12px 0px;">
                                                        <a-icon color="blue-grey darken-1" class="u-icon-nudge-xs mr-1" size="20">archive</a-icon>
                                                        Archive
                                                    </p>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-if="item.automation_trigger_id && editMode === false && item.automation_trigger">
                        <div class="u-wfull u-rounded-corners pa-5 px-4 u-flex" style="border: 1px solid #E0E0E0;">
                            <div class="u-relative">
                                <div class="c-tigger-select-icon u-flex-center-y u-rounded-corners px-3">
                                    <a-icon color="indigo darken-1" size="22">{{ triggerIcon(item.automation_trigger.slug) }}</a-icon>
                                </div>
                                <div v-if="!['project-result'].includes(item.automation_trigger.slug)" class="c-slected-trigger-divider"></div>
                            </div>
                            <div class="ml-3 u-wfull" style="z-index: 5;">
                                <p class="indigo--text text--darken-1 md-subtitle-1 mb-0 font-weight-bold">{{ item.automation_trigger.title }}</p>
                                <p class="md-caption grey--text mb-0 font-weight-medium mt-1" style="font-size: 14px;" :title="item.automation_trigger.description">{{ item.automation_trigger.description }}</p>
                                <div class="mt-8 u-flex" v-if="isHiddenWorkspace">
                                    <div class="c-stage-from mr-4">
                                        <p class="text--grey text--darken-3 mb-0 font-weight-medium mt-1" style="font-size: 16px; font-weight: 600;">From</p>
                                        <div class="mt-3 u-rounded-corners-lg grey lighten-5 c-stage-hidde-input" style="border: 1px solid #EEEEEE !important;">
                                            <a-chip class="indigo-darken-1" style="margin: 7px 12px;">
                                                {{ item.stage_from ? item.stage_from.length === 1 ? item.stage_from.length+' Stage' : item.stage_from.length+' Stages' : '0 Stages'}} selected
                                            </a-chip>
                                        </div>
                                    </div>
                                    <div class="c-stage-to">
                                        <p class="text--grey text--darken-3 mb-0 font-weight-medium mt-1" style="font-size: 16px; font-weight: 600;">To</p>
                                        <div class="mt-3 u-rounded-corners-lg grey lighten-5 c-stage-hidde-input" style="border: 1px solid #EEEEEE !important;">
                                            <a-chip class="indigo-darken-1" style="margin: 7px 12px;">
                                                {{ item.stage_to ? item.stage_to.length === 1 ? item.stage_to.length+' Stage' : item.stage_to.length+' Stages' : '0 Stages'}} selected
                                            </a-chip>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-8 u-flex" v-if="!isHiddenWorkspace && !['project-result'].includes(item.automation_trigger.slug)">
                                    <div class="c-stage-from mr-4">
                                        <p class="text--grey text--darken-3 mb-0 font-weight-medium mt-1" style="font-size: 16px; font-weight: 600;">From</p>
                                        <a-autocomplete
                                            v-model="item.stage_from"
                                            :items="stageFromlist(item.automation_trigger.slug)"
                                            item-text="name"
                                            item-value="id"
                                            @change="$emit('stage-update')"
                                            class="pa-0 mt-3 u-rounded-corners-lg u-border c-custom-automation-placeholder"
                                            :placeholder="item.stage_from && item.stage_from.length ? null : 'Any Queue/Active Stage'"
                                            background-color="grey lighten-5"
                                            :disabled="!canUpdate"
                                            solo flat hide-details multiple deletable-chips small-chips auto-select-first hide-selected
                                        >   
                                            <template #item="{ item }">
                                                <span :title="item.name">{{ item.name | truncateText(70) }}</span>
                                            </template>
                                            <template #selection="{ item, parent }">
                                                <template>
                                                    <div class="c-slected-trigger-stage mr-2 my-1">
                                                        <span class="pa-2 d-inline-flex align-center u-rounded-corners grey--text text--darken-3">
                                                            <span class="mr-2 md-body-2 grey--text text--darken-3" :title="item.name">{{ item.name | truncateText(20) }}</span>
                                                            <a-icon v-if="canUpdate" size="16" @click="parent.selectItem(item)" class="u-cursor-pointer grey--text text--darken-1">cancel</a-icon>
                                                        </span>
                                                    </div>
                                                </template>
                                            </template>
                                        </a-autocomplete>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                            <a-icon size="16" color="grey" class="u-icon-nudge-xs">info</a-icon>
                                            You can select multiple stages.
                                        </span>
                                    </div>
                                    <div class="c-stage-to">
                                        <p class="text--grey text--darken-3 mb-0 font-weight-medium mt-1" style="font-size: 16px; font-weight: 600;">To</p>
                                        <template v-if="item.automation_trigger.slug === 'project-stage-changed'">
                                            <a-autocomplete
                                                v-model="item.stage_to"
                                                :items="stageTolist(item.automation_trigger.slug)"
                                                item-text="name"
                                                item-value="id"
                                                @change="$emit('stage-update')"
                                                class="pa-0 mt-3 u-rounded-corners-lg u-border c-custom-automation-placeholder"
                                                :placeholder="item.stage_to && item.stage_to.length ? null : 'Any Queue/Active Stage'"
                                                background-color="grey lighten-5"
                                                :disabled="!canUpdate"
                                                solo flat hide-details multiple deletable-chips small-chips auto-select-first hide-selected
                                            >   
                                            <template #item="{ item }">
                                                <span :title="item.name">{{ item.name | truncateText(70) }}</span>
                                            </template>
                                            <template #selection="{ item, parent }">
                                                <template>
                                                    <div class="c-slected-trigger-stage mr-2 my-1">
                                                        <span class="pa-2 d-inline-flex align-center u-rounded-corners grey--text text--darken-3">
                                                            <span class="mr-2 md-body-2 grey--text text--darken-3" :title="item.name">{{ item.name | truncateText(20) }}</span>
                                                            <a-icon v-if="canUpdate" size="16" @click="parent.selectItem(item)" class="u-cursor-pointer grey--text text--darken-1">cancel</a-icon>
                                                        </span>
                                                    </div>
                                                </template>
                                            </template>
                                            </a-autocomplete>
                                            <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                                <a-icon size="16" color="grey" class="u-icon-nudge-xs">info</a-icon>
                                                You can select multiple stages.
                                            </span>
                                        </template>
                                        <template v-if="['project-completed','project-result'].includes(item.automation_trigger.slug)">
                                            <div class="c-trigger-stage green lighten-4 mt-3 u-rounded-corners-lg">
                                                <p class="text-center green--text text--darken-2 mb-0 font-weight-medium" style="padding: 12px 0px;">
                                                    <a-icon color="green darken-2" class="u-icon-nudge-xs mr-1" size="20">task_alt</a-icon>
                                                    Completed
                                                </p>
                                            </div>
                                        </template>
                                        <template v-if="item.automation_trigger.slug === 'project-archived'">
                                            <div class="c-trigger-stage blue-grey lighten-5 mt-3 u-rounded-corners-lg">
                                                <p class="text-center blue-grey--text text--darken-1 mb-0 font-weight-medium" style="padding: 12px 0px;">
                                                    <a-icon color="blue-grey darken-1" class="u-icon-nudge-xs mr-1" size="20">archive</a-icon>
                                                    Archive
                                                </p>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </a-main>
</template>

<script>
import PValidationTriggerLoader from './PartialValidationTriggerLoader'
import PValidationWorkflowWarningInfo from './PartialValidationWorkflowWarningInfo'

export default {
    components: {
        PValidationTriggerLoader,
        PValidationWorkflowWarningInfo,
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        triggersList: {
            type: Array,
            default: [],
        },
        selectedTiggerId:{
            type: String,
            default: null
        },
        triggerIcon: {
            type: Function,
            default: {},
        },
        stageFromlist: {
            type: Function,
            default: {},
        },
        stageTolist: {
            type: Function,
            default: {},
        },
        isLoading:{
            type: Boolean,
            default: true
        },
        workflowStageLoading: {
            type: Boolean,
            default: true
        },
        editMode: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        triggerError: {
            type: Boolean,
            default: true
        },
        isHiddenWorkspace: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style scoped>
.c-trigger-icon-view {
    width: 48px !important;
}
.c-trigger-menu-icon {
    box-sizing: border-box;
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 8px !important;
}
.c-slected-trigger-divider {
    position: absolute;
    background: #EEEEEE;
    width: 2px;
    height: 105px;
    z-index: 99;
    left: 50%;
}
.c-stage-from, .c-stage-to, .v-menu__content {
    z-index: 13 !important;
}
.c-slected-trigger-divider::after{
    content: '';
    background: #3949AB;
    border: 2px solid #E8EAF6;
    width: 12px;
    position: absolute;
    bottom: 0px;
    height: 12px;
    border-radius: 50%;
    left: -5px;
}
.c-slected-trigger-load {
    position: absolute;
    background: #EEEEEE;
    width: 2px;
    height: 105px;
    z-index: 99;
    left: 50%;
}
.c-slected-trigger-load::after{
    content: '';
    background: #e1e1e1;
    border: 2px solid #E8EAF6;
    width: 12px;
    position: absolute;
    bottom: 0px;
    height: 12px;
    border-radius: 50%;
    left: -5px;
}
.c-slected-trigger-stage {
    background: #EEEEEE;
    border-radius: 16px;
}
.c-stage-from{
    width: 46% !important;
}
.c-stage-to{
    width: 46% !important;
}
.c-tigger-icon{
    width: 48px;
    height: 48px;
    background: #F5F5F5;
    border: 1px solid #EEEEEE !important;
}
.c-tigger-select-icon{
    width: 48px;
    height: 48px;
    background: #E8EAF6;
}
.c-active-tigger {
    border: 1px solid #3949AB !important;
    box-shadow: 0px 0px 8px rgba(57, 73, 171, 0.2);
    background: #FFFFFF !important;
}
.c-default-tigger {
    border: 1px solid #EEEEEE !important;
    background: #FAFAFA !important;
}
.c-tigger-icon {
    width: 48px !important;
    height: 48px !important;
}
.c-default-select {
    background: #FFFFFF !important;
    border: 1px solid #3949AB !important;
    box-shadow: 0px 0px 8px rgba(57, 73, 171, 0.2);
}
.c-trigger-stage {
    min-height: 48px !important;
}
.c-stage-hidde-input {
    height: 48px !important;
}
</style>


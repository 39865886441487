<template>
    <div>
        <a-sheet class="c-testcase-count-wrap u-wfull" :width="`100%`" color="grey lighten-2">
            <a-sheet v-if="passed" class="c-testcase-count-wrap--strip" color="#00F8B4" style="z-index: 4;" :width="methodParentWidth(passed)"></a-sheet>
            <a-sheet v-if="failed" class="c-testcase-count-wrap--strip" color="#FF234B" style="z-index: 3;" :width="methodParentWidth(failed)"></a-sheet>
            <a-sheet v-if="invalid" class="c-testcase-count-wrap--strip" color="#616161" style="z-index: 2;" :width="methodParentWidth(invalid)"></a-sheet>
            <a-sheet v-if="open" class="c-testcase-count-wrap--strip" color="transparent" style="z-index: 2;" :width="methodParentWidth(open)"></a-sheet>
        </a-sheet>
        <a-sheet class="transparent u-flex align-center justify-space-between">
            <div class="md-caption grey--text text--darken-3 font-weight-medium text-center">
                <span class="d-block">({{ methodGetPercent(passed) }})</span>
                <span class="d-block">{{ passed }} passed</span>
            </div>
            <div class="md-caption grey--text text--darken-3 font-weight-medium text-center">
                <span class="d-block">({{ methodGetPercent(failed) }})</span>
                <span class="d-block">{{ failed }} failed</span>
            </div>
            <div class="md-caption grey--text text--darken-3 font-weight-medium text-center">
                <span class="d-block">({{ methodGetPercent(invalid) }})</span>
                <span class="d-block">{{ invalid }} invalid</span>
            </div>
            <div class="md-caption grey--text text--darken-3 font-weight-medium text-center">
                <span class="d-block">({{ methodGetPercent(open) }})</span>
                <span class="d-block">{{ open }} open</span>
            </div>
        </a-sheet>
    </div>
</template>

<script>
export default {
    name: 'SharedSCStats',

    props: {
        project: {
            type: Object,
        },
        open: {
            type: String | Number,
            default: 0
        },
        passed: {
            type: String | Number,
            default: 0
        },
        failed: {
            type: String | Number,
            default: 0
        },
        invalid: {
            type: String | Number,
            default: 0
        },
    },

    computed: {
        local_sc_total_count () {
            const { success_criteria_passed_count, success_criteria_failed_count, success_criteria_invalid_count, success_criteria_open_count } = this.project
            return (success_criteria_passed_count ?? 0) + (success_criteria_failed_count ?? 0) + (success_criteria_invalid_count ?? 0) + (success_criteria_open_count ?? 0)
        },
    },

    methods: {
        methodGetPercent (value) {
            const total = this.local_sc_total_count
            if (value === 0 || (value === 0 && total === 0 || Number.isNaN(total))) return 0 + '%'
            const decimalValue = ((value / total) * 100)
            return (decimalValue.toFixed(2).split('.')[1] === '00' ? decimalValue : decimalValue.toFixed(2) )+ '%'
        },

        methodParentWidth (value) {
            let defaultWidth = 320
            if (this.$parent && this.$parent.$el && this.$parent.$el.offsetParent) {
                defaultWidth = this.$parent.$el.offsetParent.offsetWidth
            }
            return (value / this.local_sc_total_count).toFixed(2) * defaultWidth
        }
    }
}
</script>

<style lang="scss" scoped>
    .c-testcase-count-wrap {
        overflow: hidden;
        border-radius: 25px;
        display: flex;
        margin: 8px 0px;
        align-items: center;
        height: 6px;
        &--strip {
            display: inline-block;
            margin-left: -4px;
            border-radius: 0 25px 25px 0;
            height: 100%;
            transition: 0.3s all;
        }
    }
</style>

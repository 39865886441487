<template>
    <a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="px-0 mb-4 pb-4">
            <g-page-header
                title="Documentation"
                subtitle="A documentation on how to use Success API."
                :breadcrumb-items="breadcrumb_items"
                icon="description"
            >
                <template v-slot:version>
                    <a-chip label color="blue" text-color="white" class="ml-2 md-body-2 font-weight-medium" small>v{{ document_version }}</a-chip>
                </template>
                <template v-slot:action>
                    <a-menu offset-y bottom left transition="slide-y-transition" content-class="u-rounded-corners">
                        <template v-slot:activator="{ on }">
                            <a-btn v-on="on" small color="primary" class="elevation-0 px-4 pr-3" dark>
                                Download
                                <a-icon class="ml-2" color="white" size="18">keyboard_arrow_down</a-icon>
                            </a-btn>
                        </template>
                        <a-list class="u-list-condensed">
                            <template v-for="format in download_formats">
                                <a-divider v-if="format.type === 'divider'" :key="format.type" class="my-2 mx-3 grey lighten-3"></a-divider>
                                <a-list-item :key="format.type + format.version" v-else @click="local_download(format)">
                                    <a-list-item-icon class="mr-3 my-2">
                                        <a-img :src="require('../../assets/images/filetype-icons/json.png')" max-width="24" v-if="format.type === 'json'"></a-img>
                                        <a-img :src="require('../../assets/images/filetype-icons/yml.png')" max-width="24" v-else></a-img>
                                    </a-list-item-icon>
                                    <a-list-item-content>
                                        <a-list-item-title class="md-subtitle-1 grey--text text--darken-2">{{ format.label }}</a-list-item-title>
                                    </a-list-item-content>
                                </a-list-item>
                            </template>
                        </a-list>
                    </a-menu>
                </template>
            </g-page-header>
            <tab-token></tab-token>
            <a-layout>
                <a-flex xs12>
                    <a-card class="u-elevation-custom-1 u-rounded-corners pa-6 px-8 mb-4">
                        <div class="mb-4">
                            <h2 class="title grey--text text--darken-3 font-weight-medium mb-2"><span class="primary--text u-cursor-pointer body-2 d-none"># </span>API Reference</h2>
                            <p class="body-2 grey--text text--darken-2 mb-2">The Success API is organized around REST. Our API has predictable resource-oriented URLs, returns JSON-encoded responses, and uses standard HTTP response codes, authentication, and verbs.</p>
                        </div>
                        <div class="mb-8">
                            <h2 class="title grey--text text--darken-3 font-weight-medium mb-2"><span class="primary--text u-cursor-pointer body-2 d-none"># </span>Authentication</h2>
                            <p class="body-2 grey--text text--darken-2 mb-2">The Success API uses API tokens to authenticate requests. You can view and manage your API keys in the Success Settings Page.</p>
                        </div>
                        <div>
                            <p class="body-2 grey--text text--darken-2 mb-2">Authentication to the API is performed via bearer auth, for example: <br> <code class="c-code mt-2 d-inline-block grey lighten-4 pa-1 px-3 u-rounded-corners" style="border: 1px solid #e9e9e9;">Authorization: Bearer TG765AR_4eC39HqLyjWDarjtT1zdp7dc</code></p>
                        </div>
                        <div>
                            <p class="body-2 grey--text text--darken-2 mb-2">Base URL for the API: <br> <code class="c-code mt-2 d-inline-block grey lighten-4 pa-1 px-3 u-rounded-corners" style="border: 1px solid #e9e9e9;">{{ local_base_url + '/public' }}</code></p>
                        </div>
                    </a-card>
                    <a-card class="u-elevation-custom-1 u-rounded-corners px-4">
                        <div class="swagger" id="swagger_ui"></div>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>

        <a-dialog max-width="480px" v-model="dialog_download_link" persistent>
            <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden" flat>
                <a-progress-linear class="my-0" height="3" color="orange darken-3" :indeterminate="true" v-if="loading"></a-progress-linear>
                <a-card-text class="pa-2">
                    <a-container container--fluid grid-list-xl class="pa-4">
                        <a-layout align-start>
                            <a-flex shrink>
                                <a-icon size="72" color="grey lighten-1">cloud_download</a-icon>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 mb-2">
                                    <span v-if="loading">Preparing file...</span>
                                    <span v-else>Ready to Download</span>
                                </h2>
                                <p class="md-body-2 mb-0 grey--text text--darken-1">
                                    <span v-if="loading">Success is preparing your document for download. It may take few seconds to get ready.</span>
                                    <span v-else>Your document is ready to download. Click on the "Save File" button to download the document.</span>
                                </p>
                                <a-btn :href="api_document_link" small class="elevation-0 px-3 ma-0 mt-3" color="primary" :disabled="loading" @click="dialog_download_link = false">
                                    <a-icon size="14" class="mr-2">save</a-icon>
                                    Save File
                                </a-btn>
                                <a-btn class="elevation-0 px-3 ma-0 mt-3 ml-2" color="grey" small text @click="dialog_download_link = !dialog_download_link" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'

import { TabToken } from '@/config/config-tab-components'

export default {
    components: {
        TabToken
    },

    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Documentation',
                    to: '/tokens/documentation',
                    exact: true,
                }
            ],
            dialog_download_link: false,
            hostname: window.location.hostname.split('.')[0],
            document_version: process.env.VUE_APP_API_DOCUMENT_VERSION,
            api_url: process.env.VUE_APP_CORE_API_URL,
            download_formats: [
                { label: 'Swagger (v2) - JSON', type: 'json', version: '2' },
                { label: 'Swagger (v2) - YAML', type: 'yaml', version: '2' },
                { type: 'divider' },
                { label: 'OpenAPI (v3) - JSON', type: 'json', version: '3' },
                { label: 'OpenAPI (v3) - YAML', type: 'yaml', version: '3' },
            ]
        }
    },

    mounted(){
        const endpoints = require('../../config/public-api-documentation.json')
        const copied = _.cloneDeep(endpoints)
        SwaggerUI({
            spec: copied,
            dom_id: '#swagger_ui',
            docExpansion: 'none',
            layout: 'BaseLayout',
            defaultModelRendering: 'model',
            syntaxHighlight: { theme: 'arta' },
            deepLinking: false,
            defaultModelExpandDepth: 2,
            persistAuthorization: true,
            requestInterceptor: (request) => {
                request.headers.Authorization = "Bearer " + request.headers.Authorization;
                request.url = request.url.replace('https://{api-host}/v1', this.api_url)
                request.url = request.url.replace('{organization-slug}', this.hostname)
                return request;
            }
        })
        this.local_index()
    },

    computed: {
        local_base_url() {
            return this.api_url + '/' + this.hostname
        },

        ...mapState('APIDocumentation', {
            api_document_link: 'link',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        local_index() {
            this.api_document_clear()
        },

        local_download({ type, version }) {
            this.dialog_download_link = true
            this.api_document_download({ type, version, document_version: this.document_version })
        },

        ...mapActions('APIDocumentation', {
            api_document_download: 'download',
            api_document_clear: 'clear'
        })
    }
}
</script>

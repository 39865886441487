import { render, staticRenderFns } from "./ExternalProjectsTicketsView.vue?vue&type=template&id=5397a213&scoped=true"
import script from "./ExternalProjectsTicketsView.vue?vue&type=script&lang=js"
export * from "./ExternalProjectsTicketsView.vue?vue&type=script&lang=js"
import style0 from "./ExternalProjectsTicketsView.vue?vue&type=style&index=0&id=5397a213&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5397a213",
  null
  
)

export default component.exports
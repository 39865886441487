<template>
    <div class="mt-4">
        <a-container grid-list-xl container--fluid class="pa-0">
            <a-layout align-stretch wrap v-for="link in ticketList.projects" :key="link.id">
                <a-flex xs12 pb-0>
                    <a-card flat class="pa-0 u-hfull u-flex flex-column justify-space-between u-border" :class="{ 'c-cursor-auto': !$can('poc_tickets.index') }">
                        <div class="u-flex align-center pa-6 px-4">
                            <div class="u-flex align-center">
                                <a-chip class="ml-0 mr-2" label small v-if="link && link.customer_name" :title="link.customer_name && link.customer_name.length >= 20 ? link.customer_name : ''">{{ link.customer_name | truncateText(20) }}</a-chip>
                                <h2 class="md-subtitle-1 grey--text text--darken-3" :title="link.title">
                                    {{ link.title | truncateText(60) }}
                                </h2>
                            </div>
                            <a-spacer></a-spacer>
                            <div class="u-flex align-center">
                                <a-sheet min-width="120" class="text-right">
                                    <g-currency tag="span" class="md-subtitle-1 green--text text--darken-1" :value="link.deal_amount"></g-currency>
                                </a-sheet>
                            </div>
                        </div>
                        <a-divider></a-divider>
                    </a-card>
                </a-flex>
                <a-flex xs-12 pt-0 v-if="link && link.tickets">
                    <a-card flat v-for="ticket in link.tickets" :key="ticket.id" class="u-border">
                        <v-hover v-slot="{ hover }">
                            <div class="pa-0 u-hfull u-wfull" :id="ticket.id">
                                <div class="u-flex align-center pa-4 px-4 u-cursor-pointer">
                                    <div class="u-flex align-center grow">
                                    <div class="u-rounded-corners u-wfull" :style="[(currentEditId === ticket.id) ? { border: '2px solid #1495bd' } : { cursor: 'text' }, (currentEditId === ticket.id) && (title_errors && title_errors.ticket_title) ? {border: '2px solid red'}: {}]">
                                        <div v-if="currentEditId === ticket.id" class="u-flex-center-y u-wfull py-1">
                                            <textarea
                                                v-model="ticket.title"
                                                rows="2"
                                                class="u-wfull md-subtitle-1 d-block pl-1"
                                                style="outline: none; resize: none;"
                                                :ref="'title_input' + ticket.id"
                                                :disabled="!canUpdate"
                                                @click.stop="{}"
                                                @input="local_check_changes(ticket)"
                                                @blur="revert_mode ? {} : local_focus_title_input(ticket, 'blur')"
                                                @keydown.esc="local_inline_update(ticket, 'clear')"
                                                @keydown.enter.prevent="local_inline_update(ticket)"
                                                ></textarea>
                                                <a-btn icon small depressed width="24" height="24" class="mr-2" @click.stop="local_inline_update(ticket, 'click')">
                                                    <a-icon color="green darken-1">check</a-icon>
                                                </a-btn>
                                                <a-btn icon small depressed width="24" height="24" @mousedown="local_revert_changes()" @click.stop="local_inline_update(ticket, 'clear')">
                                                    <a-icon color="red darken-1">clear</a-icon>
                                                </a-btn>
                                            </div>
                                            <div class="u-wfull" @click="canUpdate && title_errors && !title_errors.ticket_title ? local_title_edit_focus(ticket.id) : {}" v-else>
                                                <h2 class="c-module-title md-subtitle-1 grey--text text--darken-3" :title="ticket.title">
                                                    {{ ticket.title | truncateText(local_is_lgdown ? 40 : 120) }}
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="shrink">
                                        <div class="u-flex align-center">
                                            <div class="text-center" style="width: 48px;" title="Unlink this project" v-if="hover && canUpdate">
                                                <a-btn icon small color="red" @click="$emit('unlink-project', ticket)">
                                                    <a-icon size="18">link_off</a-icon>
                                                </a-btn>
                                            </div>
                                            <div class="text-center" style="width: 48px;" title="Open in New Window" v-if="hover && $can('poc_tickets.index') && ticket.created_at" >
                                                <a-btn icon small color="blue" target="_blank" :to="{ name: 'projects-tickets-view', params: { id: link.id, ticket_id: ticket.id } }" :exact="true">
                                                    <a-icon size="16">open_in_new</a-icon>
                                                </a-btn>
                                            </div>
                                            <div class="mr-4">
                                                <s-meta-ticket-dropdown :loading="loading_meta_status('priority', ticket.id)" :can-update="canUpdate && title_errors && !title_errors.ticket_title" text-length="12" :meta-item="local_ticket_meta(ticket, 'priority')" :meta-list="priorityList" icon-size="24" inline @updateMeta="local_update_meta(...arguments, 'priority', ticket)" menu-width="132">
                                                    <template #icon>arrow_drop_down</template>
                                                </s-meta-ticket-dropdown>
                                            </div>
                                            <div class="" v-if="canUpdate">
                                                <GNonActiveInfo
                                                    :project="link"
                                                    :stage="link && link.status"
                                                    :disabled="(link && link.status === 'active')"
                                                    left
                                                >
                                                    <s-meta-ticket-dropdown
                                                        :can-update="(link && link.status === 'active')"
                                                        :meta-item="local_ticket_meta(ticket, 'status')"
                                                        :meta-list="statusList"
                                                        :loading="loading_meta_status('status', ticket.id)"
                                                        icon-size="24"
                                                        menu-width="132"
                                                        inline
                                                        @updateMeta="local_update_meta(...arguments, 'status', ticket)"
                                                    ></s-meta-ticket-dropdown>
                                                    <template #move-stage>
                                                        <span @click="local_go_to_project(link)" class="d-inline-flex align-center u-cursor-pointer">
                                                            <h3 class="blue--text md-body-2 font-weight-medium">Go to project and move to active stage</h3>
                                                            <a-icon size="16" color="blue" class="ml-1 mt-1">trending_flat</a-icon>
                                                        </span>
                                                    </template>
                                                </GNonActiveInfo>
                                            </div>
                                            <div v-else>
                                                <s-meta-ticket-dropdown
                                                    :can-update="canUpdate"
                                                    :meta-item="local_ticket_meta(ticket, 'status')"
                                                    :meta-list="statusList"
                                                    :loading="loading_meta_status('status', ticket.id)"
                                                    icon-size="24"
                                                    menu-width="132"
                                                    inline
                                                    @updateMeta="local_update_meta(...arguments, 'status', ticket)"
                                                ></s-meta-ticket-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a-divider></a-divider>
                            </div>
                        </v-hover>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
    </div>
</template>

<script>
import { SMetaTicketDropdown } from '@/config/config-shared-components'
export default {
    props: {
        ticketList: {
            type: Object,
            required: true
        },
        priorityList: {
            type: Array
        },
        statusList: {
            type: Array
        },
        currentEditId: {
            type: String
        },
        loadingMetaItem: {
            type: Object,
            required: true
        },
        backupEditItem: {
            type: Object,
        },
        canUpdate: {
            type: Boolean,
            required: true
        }
    },

    components: { SMetaTicketDropdown },

    data () {
        return {
            revert_mode: false,
            unsaved_changes: false,
            error_msgs: {
                required_msg: "Ticket's title is a required field.",
                max_length_exceed: 'Title should not be more than 1000 chars.',
            },
            title_errors: { ticket_title: false },
        }
    },

    computed: {
        local_is_lgdown () {
            return this.$vuetify.breakpoint.lgAndDown
        }
    },

    methods: {
        local_title_edit_focus (id) {
            this.local_on_enter('title_input' + id)
            this.$emit('inputFocus', id )
        },

        local_on_enter (input) {
            setTimeout(() => {
                this.$refs[input][0].focus()
            }, 200)
        },

        local_inline_update (ticket, type) {
            if (type === 'clear') {
                this.revert_mode = false
                this.title_errors.ticket_title = false
            } else {
                this.local_title_validate(ticket)
                if (this.title_errors && this.title_errors.ticket_title) return
            }

            this.$emit('inlineUpdate', ticket, type)
        },

        local_update_meta (arg, field, ticket) {
            this.$emit('updateMeta', arg, field, ticket, this.local_ticket_meta (ticket, field).id)
        },

        local_revert_changes () {
            this.revert_mode = true
        },

        local_focus_title_input (ticket, type) {
            this.local_title_validate(ticket)
            if (this.title_errors && this.title_errors.ticket_title) return

            setTimeout(()=> {
                this.$emit('inlineUpdate', ticket, 'blur')
            }, 200)
        },

        loading_meta_status (localField, ticketId) {
            return ((this.loadingMetaItem['field'] === localField) && (this.loadingMetaItem['ticket_id'] === ticketId))
        },

        local_ticket_meta (ticket, type) {
            const index = this.ticketList.projects.findIndex(item => item.id === ticket.parent_id)
            const ticketIndex = this.ticketList.projects[index].tickets.findIndex(item => item.id === ticket.id)
            return this.ticketList.projects[index].tickets[ticketIndex][type]
        },

        local_check_changes (ticket) {
            // this.unsaved_changes = this.backupEditItem.title !== ticket.title
            // if (this.unsaved_changes) this.$emit('pageLeave',  this.unsaved_changes)
        },

        local_title_validate (ticket) {
            if (!ticket.title || ticket.title && !ticket.title.trim()) {
                this.$notify('error', this.error_msgs.required_msg)
                this.title_errors.ticket_title = true
            }
            if (ticket.title && ticket.title.trim().length > 1000) {
                this.$notify('error', this.error_msgs.max_length_exceed)
                this.title_errors.ticket_title = true
            }
        },

        local_go_to_project (project) {
            if (project) {
                let route = this.$router.resolve({name: 'projects-overview', params: {id: project.id} });
                window.open(route.href);
            }
        },
    }
}

</script>

<template>
    <s-dashboard-layout class="mb-16">
        <template #insights-dropdown v-if="local_user_can('index') || local_user_can('store')">
            <a-menu v-model="insights_dropdown" nudge-bottom="2" offset-y bottom min-width="224" :close-on-content-click="insights_dropdown" max-width="360" transition="slide-y-transition" content-class="u-rounded-corners" :disabled="tile_loading">
                <template v-slot:activator="{ on, value }">
                    <a-card v-on="on" class="u-cursor-pointer u-rounded-corners px-4 pr-2 py-1 ml-4 u-border u-flex align-center" flat height="36" min-width="184">
                        <span class="text-body-2 grey--text text--darken-2" v-if="current_view && current_view.title" :title="current_view.title.length > 30 ? current_view.title : ''">{{ current_view.title | truncateText(30) }}</span>
                        <span class="text-body-2 body--text text--lighten-3" v-else-if="local_loading">Fetching boards...</span>
                        <span class="text-body-2 body--text text--lighten-3" v-else>No Board Selected</span>
                        <a-spacer></a-spacer>
                        <a-icon size="22" color="grey darken-1" class="ml-1">arrow_drop_{{ value ? 'up' : 'down' }}</a-icon>
                    </a-card>
                </template>
                <a-sheet>
                    <div class="">
                        <h2 style="letter-spacing: 0.6px" class="grey--text text--darken-2 font-weight-medium text-uppercase md-caption px-4 pt-3 pb-1" :class="{ 'mb-1': local_board_length <= 5 }">Insight Boards ({{ local_board_length }})</h2>
                        <a-list class="u-list-condensed py-0" v-if="local_board_length > 5">
                            <a-list-item class="c-input-wrap px-3 mt-1 pb-3">
                                <a-text-field
                                    v-model="search_board"
                                    placeholder="Search Board"
                                    background-color="grey lighten-4"
                                    ref="refDashboardSearch"
                                    class="text-body-2 font-weight-normal"
                                    solo flat hide-details autofocus
                                    @click.stop="insights_dropdown = true"></a-text-field>
                            </a-list-item>
                        </a-list>
                        <a-divider></a-divider>
                    </div>
                    <a-list style="max-height: 200px;" class="overflow-y-auto u-list-condensed c-tiny-scroll py-0">
                        <template v-if="local_filtered_board && local_filtered_board.length">
                            <template v-for="(view, index) in local_filtered_board">
                                <a-divider v-if="index !== 0" :key="'divider-'+view.id"></a-divider>
                                <a-list-item
                                    :key="view.id"
                                    :class="[{ 'grey lighten-5': current_view && current_view.id === view.id }]"
                                    class="py-1"
                                    @click="local_change_board_view(view)">
                                    <a-list-item-title class="text-body-2">
                                        {{ view.title }}
                                    </a-list-item-title>
                                    <a-list-item-icon class="u-flex align-center justify-end" v-if="current_view && current_view.id === view.id">
                                        <a-icon size="18" color="green darken-2">check</a-icon>
                                    </a-list-item-icon>
                                </a-list-item>
                            </template>
                        </template>
                        <template v-else>
                            <a-list-item class="py-2">
                                <a-list-item-title class="text-body-2 grey--text text--darken-1 text-center">No Boards Found</a-list-item-title>
                            </a-list-item>
                        </template>
                    </a-list>
                    <a-list class="u-list-condensed py-0" v-if="local_user_can('store')">
                        <a-divider></a-divider>
                        <a-list-item class="py-1" @click="local_open_board_create()">
                            <a-list-item-title class="u-flex align-center py-2">
                                <span class="text-body-2 blue--text text--darken-2">Create Board</span>
                                <a-spacer></a-spacer>
                                <a-icon size="18" color="blue darken-2">add</a-icon>
                            </a-list-item-title>
                        </a-list-item>
                    </a-list>
                </a-sheet>
            </a-menu>
            <a-tooltip v-if="current_view && current_view.description" right content-class="c-tooltip-pointer c-tooltip-pointer--left">
                <template v-slot:activator="{ on }">
                    <a-icon size="22" color="primary" v-on="on" class="ml-3">info</a-icon>
                </template>
                <a-sheet class="transparent white--text text-body-2" max-width="296">{{ current_view.description | truncateText(124) }}</a-sheet>
            </a-tooltip>
        </template>
        <template #insights-board-more v-if="local_board_length && local_user_can('update')">
            <a-spacer></a-spacer>
            <template v-if="enable_rearrange">
                <a-btn color="primary" height="36" small depressed :loading="local_check_loading('tile_rearrange')" class="mr-4" @click="local_save_rearrange()">Save Board</a-btn>
                <a-btn color="grey lighten-3" height="36" small depressed class="grey lighten-5 mr-4" @click="local_cancel_rearrange_dialog()">Cancel</a-btn>
            </template>
            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" v-if="tile_list && tile_list.length > 1">
                <template v-slot:activator="{ on }">
                    <a-card v-on="on" :disabled="enable_rearrange || local_loading || tile_loading" @click="enable_rearrange = true" v-ripple="{ class: 'secondary--text text--darken-2' }" height="36" width="36" flat class="u-flex-center u-cursor-pointer mr-4 u-border u-rounded-corners px-2">
                        <a-icon size="18" color="grey darken-1">pivot_table_chart</a-icon>
                    </a-card>
                </template>
                <span>Rearrange Tiles</span>
            </a-tooltip>
            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="enable_rearrange">
                <template v-slot:activator="{ on }">
                    <a-card v-on="on" :disabled="enable_rearrange || local_loading || tile_loading" @click="local_open_tile_dialog()" v-ripple="{ class: 'secondary--text text--darken-2' }" height="36" width="36" flat class="u-flex-center u-cursor-pointer mr-4 u-border u-rounded-corners px-2">
                        <a-icon size="18" color="grey darken-1">add</a-icon>
                    </a-card>
                </template>
                <span>Add New Tile</span>
            </a-tooltip>
            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="enable_rearrange">
                <template v-slot:activator="{ on }">
                    <a-card v-on="on" :disabled="enable_rearrange || local_loading || tile_loading" @click="local_refresh_tiles()" v-ripple="{ class: 'secondary--text text--darken-2' }" height="36" width="36" flat class="u-flex-center u-cursor-pointer mr-4 u-border u-rounded-corners px-2">
                        <a-icon size="18" color="grey darken-1">refresh</a-icon>
                    </a-card>
                </template>
                <span>Refresh All Tiles</span>
            </a-tooltip>
            <!-- <a-menu v-model="model_more_actions" min-width="160" nudge-bottom="2" :close-on-content-click="!model_more_actions" bottom offset-y left> -->
            <a-menu v-model="model_more_actions" :disabled="enable_rearrange || local_loading || tile_loading" min-width="160" nudge-bottom="2" bottom offset-y left>
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <a-card v-ripple="{ class: 'secondary--text text--darken-2' }" :disabled="enable_rearrange || loading_more_actions" flat height="36" min-width="36" class="u-flex-center-y u-cursor-pointer u-border u-rounded-corners px-2 pr-3">
                            <a-progress-circular
                                class="mx-auto"
                                v-if="loading_more_actions"
                                :size="18"
                                :width="2"
                                color="orange darken-3"
                                indeterminate></a-progress-circular>
                            <div v-else class="u-flex-center-y">
                                <a-icon size="18" color="grey darken-1">more_vert</a-icon>
                                <span class="text-body-2 ml-1 grey--text text--darken-2">More Actions</span>
                            </div>
                        </a-card>
                    </div>
                </template>
                <a-list class="u-list-std">
                    <a-list-item @click="local_board_edit()">
                        <a-list-item-content class="text-body-2 grey--text text--darken-2">Edit Board</a-list-item-content>
                    </a-list-item>
                    <a-list-item v-if="local_show_export_board" @click="local_board_export()">
                        <a-list-item-content class="text-body-2 grey--text text--darken-2">Export as PNG</a-list-item-content>
                    </a-list-item>
                    <a-list-item @click="dialog_board_delete = true">
                        <a-list-item-content class="text-body-2 accent--text">Delete Board</a-list-item-content>
                    </a-list-item>
                </a-list>
            </a-menu>
        </template>

        <v-slide-y-transition group hide-on-leave leave-absolute>
            <template v-if="local_loading">
                <!-- <div key="temp-loader" style="display: grid; gridTemplateRows: masonry; gridTemplateColumns: repeat(4, 1fr); transition: 0.4s all; gap: 24px; max-width: 1160px;">
                    <loader-template width="272" height="272" v-for="i in 7" :key="i"></loader-template>
                </div> -->
            </template>
            <template v-else>
                <template v-if="local_board_length">
                    <p-insights-type
                        key="insight-type"
                        ref="insightsBoard"
                        :parent-loading="local_loading"
                        :enable-rearrange="enable_rearrange"
                        :discard-rearrange="discard_rearrange"
                        :hightlight-id="cloned_tile_highlight"
                        :is-board-export="is_board_export"
                        @delete="local_delete_tile"
                        @edit="local_edit_tile_dialog"
                        @clone="local_clone_tile"
                        @create-tile="local_open_tile_dialog"></p-insights-type>
                </template>
                <div v-else key="no-board-list" class="u-flex-center" style="margin-top: 64px;">
                    <div class="text-center">
                        <img src="../../assets/images/board-placeholder.svg" class="d-inline-block mb-4" alt="Board Placeholder">
                        <a-sheet max-width="392" class="transparent mx-auto mb-5">
                            <h2 class="md-heading-5 grey--text text--darken-1">Visualize your data by configuring your Insights view.</h2>
                        </a-sheet>
                        <a-btn @click="local_open_board_create()" large dark class="primary u-rounded-corners pl-3 pr-4">
                            <a-icon size="22" class="mr-1">add</a-icon>
                            <span>Create Your First Board</span>
                        </a-btn>
                    </div>
                </div>
            </template>
        </v-slide-y-transition>

        <a-dialog v-model="dialog_board_create" max-width="544">
            <a-card class="pa-0">
                <!-- Header -->
                <div class="u-flex-center-y pa-8 pb-6">
                    <a-avatar size="48" color="primary" dark class="mr-3">
                        <a-icon size="24" color="white">bar_chart</a-icon>
                    </a-avatar>
                    <div>
                        <h3 class="md-heading-6 body--text text--darken-2">{{ edit_mode ? 'Edit' : 'Create New' }} Board</h3>
                        <p class="mb-0 text-body-2 body--text text--lighten-1">{{ edit_mode ? 'Edit and update' : 'Enter the new' }} board information.</p>
                    </div>
                </div>

                <!-- Body -->
                <div class="px-8">
                    <div class="mb-7">
                        <span class="md-body-1 body--text text--lighten-1 mb-2 d-inline-block font-weight-medium">Name</span>
                        <a-text-field
                            v-model="board_item.title"
                            placeholder="Eg: Quarter One"
                            background-color="grey lighten-4"
                            class="mb-2"
                            solo flat hide-details autofocus></a-text-field>
                        <g-field-text :has-error="local_has_error(board_response, 'title')">
                            <template #error-msg>{{ local_get_error(board_response, 'title') }}</template>
                            <template #info>Enter the name of this board.</template>
                        </g-field-text>
                    </div>
                    <div>
                        <span class="md-body-1 body--text text--lighten-1 mb-2 d-inline-block font-weight-medium">Description (Optional)</span>
                        <a-textarea
                            v-model="board_item.description"
                            placeholder="More details about the board goes here..."
                            background-color="grey lighten-4"
                            class="mb-2"
                            rows="3"
                            solo flat hide-details no-resize></a-textarea>
                        <g-field-text :has-error="local_has_error(board_response, 'description')">
                            <template #error-msg>{{ local_get_error(board_response, 'description') }}</template>
                            <template #info>You can describe and summarize the board.</template>
                        </g-field-text>
                    </div>
                </div>

                <!-- Footer -->
                <div class="u-flex-center-y pa-8">
                    <a-btn color="primary" depressed class="mr-4" @click="edit_mode ? local_board_update() : local_create_board()" :loading="local_check_loading('board_create') || local_check_loading('board_update')">{{ edit_mode? 'Update' : 'Save'}} Board</a-btn>
                    <a-btn color="grey" text class="grey lighten-4" @click="local_close_board_create()" :disabled="false">Cancel</a-btn>
                </div>
            </a-card>
        </a-dialog>

        <a-dialog max-width="360" v-model="dialog_board_delete">
            <a-card class="pa-6 px-7 pb-7">
                <div class="mb-5">
                    <p class="md-heading-6 grey--text text--darken-4 font-weight-medium mb-3">Are you sure you want to delete {{ current_view && current_view.title ? current_view.title : '"My Board"' | truncateText(20) }}?</p>
                    <p class="text-body-2 grey--text text--darken-2 mb-0">This action cannot be undone.</p>
                </div>
                <div>
                    <a-btn small class="ma-0 px-3 elevation-0 red lighten-5 accent--text" :loading="local_check_loading('board_delete')" @click="local_board_delete()">Delete Board</a-btn>
                    <a-btn small color="grey darken-2" class="ma-0 ml-2 grey lighten-4" text @click="dialog_board_delete = !dialog_board_delete">Cancel</a-btn>
                </div>
            </a-card>
        </a-dialog>

        <a-dialog max-width="360" v-model="dialog_cancel_rearrange">
            <a-card class="pa-6 px-7 pb-7">
                <div class="mb-5">
                    <p class="md-heading-6 grey--text text--darken-4 font-weight-medium mb-3">Are you sure you want to quit rearranging tiles?</p>
                    <p class="text-body-2 grey--text text--darken-2 mb-0">Any unsaved changes will be ignored.</p>
                </div>
                <div>
                    <a-btn small depressed color="primary" class="ma-0 px-3" :loading="false" @click="local_discard_rearrange()">Discard</a-btn>
                    <a-btn small color="grey darken-2" class="ma-0 ml-2 grey lighten-4" text @click="dialog_cancel_rearrange = !dialog_cancel_rearrange">Cancel</a-btn>
                </div>
            </a-card>
        </a-dialog>

        <a-dialog v-model="dialog_tile_create" max-width="960">
            <p-tile-config
                :menus="local_tile_types"
                :modelOpen="dialog_tile_create"
                @done="local_create_tile"
                @close="dialog_tile_create = false">
                <template #menu-header>All Available Tiles</template>
                <template #main-section="{ item }">
                    <div class="pa-8 pt-16" v-if="item">
                        <component :is="item.component" class="mx-auto"></component>
                        <!-- <a-sheet class="transparent u-border-d text-center u-relative mx-auto" :width="item.size === '1x1' ? 240 : 464"> -->
                            <!-- <span class="indigo lighten-5 indigo--text text--darken-1 u-rounded-corners-full d-inline-block px-3 u-absolute md-caption" style="right: -2rem; z-index: 99; top: 0px; padding-top: 2px; padding-bottom: 2px;">{{ item.size }}</span> -->
                            <!-- <a-img :src="require(`../../assets/images/insights-board/tile-overview.png`)" class="d-inline-block"></a-img> -->
                            <!-- <a-img :src="local_get_preview_img(item.component)" class="d-inline-block u-elevation-custom-1 u-rounded-corners-lg" :width="item.size === '1x1' ? 224 : 448" height="224"></a-img> -->
                        <!-- </a-sheet> -->
                    </div>
                    <a-divider class="grey lighten-3"></a-divider>
                    <div class="pa-6" v-if="item">
                        <h2 class="md-heading-6 indigo--text text--darken-2 font-weight-medium mb-3">{{ item.title }}</h2>
                        <p class="text-body-2 mb-0 grey--text text--darken-1" style="line-height: 22px;">{{ item.description }}</p>
                    </div>
                </template>
            </p-tile-config>
        </a-dialog>

        <a-dialog v-model="dialog_tile_config" max-width="1000" persistent content-class="u-overflow-y c-tiny-scroll">
            <a-card>
                <a-sheet min-height="96" class="pa-6 pb-7 u-relative u-flex-center-y">
                    <div class="u-flex-center-y">
                        <a-avatar color="primary" size="48" class="mr-3">
                            <a-icon size="20" color="white">space_dashboard</a-icon>
                        </a-avatar>
                        <div>
                            <h2 class="md-heading-5 body--text text--darken-3">Tile Configuration</h2>
                            <p class="mb-0 text-body-2 body--text text--lighten-1 mt-n1">Summary about the new tile configurations are displayed here.</p>
                        </div>
                    </div>
                    <a-spacer></a-spacer>

                    <!-- Show only save button when creating a tile -->
                    <template v-if="!tile_edit_mode">
                        <a-tooltip bottom :disabled="!tile_has_errors" content-class="c-tooltip-pointer c-tooltip-pointer--right u-rounded-corners-lg">
                            <template v-slot:activator="{ on }">
                                <a-btn v-on="on" @click="local_create_tile_with_config(selected_tile)" :loading="local_check_loading('tile_create')" depressed :class="[tile_has_errors ? 'red darken-2' : 'indigo darken-1', 'px-3 white--text u-rounded-corners-lg text-body-2 text-uppercase font-weight-bold']" style="transition: background-color 0.3s ease-out">
                                    <a-icon size="16" color="white" class="mr-2" v-if="tile_has_errors">error</a-icon>
                                    <span>Save Changes</span>
                                    <a-sheet class="md-caption font-weight-medium amber darken-3 white--text u-rounded-corners-full u-flex-center ml-2" width="20" height="20" style="border: 2px solid #fff !important;" v-if="local_tile_changes_length && !tile_has_errors">{{ local_tile_changes_length }}</a-sheet>
                                </a-btn>
                            </template>
                            <span class="d-block text-center py-3 mx-2">
                                <a-sheet class="ml-2 transparent white--text text-body-2" width="300">You have error(s) in your configuration, please fix to review and save your changes</a-sheet>
                            </span>
                        </a-tooltip>
                    </template>

                    <template v-else>
                        <a-tooltip bottom v-if="local_tile_changes_length && this.review_view" :disabled="!tile_has_errors" content-class="c-tooltip-pointer c-tooltip-pointer--right u-rounded-corners-lg">
                            <template v-slot:activator="{ on }">
                                <a-btn v-on="on" @click="tile_has_errors ? '' : local_save_tile_config(selected_tile)" :loading="local_check_loading('tile_create')" depressed :class="[tile_has_errors ? 'red darken-2' : 'indigo darken-1', 'px-3 white--text u-rounded-corners-lg text-body-2 text-uppercase font-weight-bold']" style="transition: background-color 0.3s ease-out">
                                    <a-icon size="16" color="white" class="mr-2" v-if="tile_has_errors">error</a-icon>
                                    <span>{{ tile_edit_mode ? 'Update' : 'Save' }} Changes</span>
                                    <a-sheet class="md-caption font-weight-medium amber darken-3 white--text u-rounded-corners-full u-flex-center ml-2" width="20" height="20" style="border: 2px solid #fff !important;" v-if="local_tile_changes_length && !tile_has_errors">{{ local_tile_changes_length }}</a-sheet>
                                </a-btn>
                            </template>
                            <span class="d-block text-center py-3 mx-2">
                                <a-sheet class="ml-2 transparent white--text text-body-2" width="300">You have error(s) in your configuration, please fix to review and save your changes</a-sheet>
                            </span>
                        </a-tooltip>
                        <template v-else>
                            <a-tooltip bottom v-if="tile_has_errors" content-class="c-tooltip-pointer c-tooltip-pointer--right u-rounded-corners-lg">
                                <template v-slot:activator="{ on }">
                                    <a-btn v-on="on" @click="local_go_to_review_view(selected_tile)"  depressed class="red darken-2 px-3 white--text u-rounded-corners-lg text-body-2 text-uppercase font-weight-bold" style="transition: background-color 0.3s ease-out">
                                        <a-icon size="16" color="white" class="mr-2">error</a-icon>
                                        <span>Review Changes</span>
                                    </a-btn>
                                </template>
                                <span class="d-block text-center py-3 mx-2">
                                    <a-sheet class="ml-2 transparent white--text text-body-2" width="300">You have error(s) in your configuration, please fix to review and save your changes</a-sheet>
                                </span>
                            </a-tooltip>
                            <a-btn v-else @click="local_go_to_review_view(selected_tile)" :disabled="!local_tile_changes_length || edit_mode" depressed class="indigo darken-1 px-3 white--text u-rounded-corners-lg text-body-2 text-uppercase font-weight-bold" style="transition: background-color 0.3s ease-out">
                                <span>Review Changes</span>
                                <a-sheet class="md-caption font-weight-medium amber darken-3 white--text u-rounded-corners-full u-flex-center ml-2" width="20" height="20" style="border: 2px solid #fff !important;" v-if="local_tile_changes_length">{{ local_tile_changes_length }}</a-sheet>
                            </a-btn>
                        </template>
                    </template>
                    <a-icon size="20" class="ml-8" color="body lighten-2" @click="local_close_tile_dialog()">clear</a-icon>
                </a-sheet>
                <a-divider></a-divider>
                <a-sheet class="u-overflow-hidden grey lighten-5">
                    <v-scroll-x-transition hide-on-leave leave-absolute>
                        <a-sheet v-if="review_view" height="52" class="u-flex-center-y u-rounded-corners-lg transparent u-relative mx-4 mr-6">
                            <a-sheet class="d-inline-flex align-center u-cursor-pointer u-rounded-corners-lg transparent px-1" height="32" v-ripple="{ class: 'indigo--text' }" @click="local_go_to_review_view(false)">
                                <a-icon size="24" color="indigo darken-2" class="mr-1">keyboard_backspace</a-icon>
                                <span class="text-body-2 font-weight-medium text-uppercase indigo--text text--darken-1" style="letter-spacing: 0.5px;">Go Back</span>
                            </a-sheet>
                        </a-sheet>
                        <a-sheet v-else height="52" class="u-flex-center-y u-rounded-corners-lg u-relative">
                            <a-sheet v-for="(menu, index) in local_config_tile_menu" @click="local_select_tile({ ...menu, index })" class="c-tile-module u-hfull px-6 u-flex-center u-cursor-pointer u-relative transparent" :class="[{ 'c-tile-module--active': selected_tile_module && selected_tile_module.slug === menu.slug }]" :width="menu.width" style="z-index: 1" :key="menu.slug">
                                <div class="u-relative u-flex-center-y">
                                    <a-icon size="18" :color="selected_tile_module && selected_tile_module.slug === menu.slug ? 'indigo darken-1' : 'body darken-1'" class="mr-2" :outlined="menu.icon_outlined">
                                        {{ menu.icon }}
                                    </a-icon>
                                    <span :class="['md-subtitle-1 c-font-transition u-relative font-weight-medium', selected_tile_module && selected_tile_module.slug === menu.slug ? 'indigo--text text--darken-1' : 'body--text text--darken-1']">{{ menu.title }}</span>
                                    <a-icon size="16" color="red darken-1" class="u-absolute" style="top: 50%; transform: translateY(-50%); right: -20px;" v-if="local_has_validation_slug(menu.slug) || (menu.title === 'Filters'? deleted_fields_state : false)">error</a-icon>
                                    <template v-else>
                                        <a-icon size="10" color="amber darken-3" class="u-absolute" style="top: 50%; transform: translateY(-50%); right: -14px;" v-if="local_section_has_changes(menu.title)">fiber_manual_record</a-icon>
                                    </template>
                                </div>
                            </a-sheet>
                        </a-sheet>
                    </v-scroll-x-transition>
                    <a-divider class="grey lighten-3"></a-divider>

                    <!-- Main -->
                    <a-sheet min-height="540" max-height="540" class="u-overflow-y transparent scroll-transparent c-tiny-scroll">
                        <p-changes-table
                            v-if="review_view"
                            :list="tile_config_changes"
                            :custom_tag_list="custom_tag_list"
                            :system_tag_list="system_tag_list"
                            @clear="local_clear_changes">
                        </p-changes-table>
                        <template v-else>
                            <div class="pa-6 pt-4 px-7">
                                <template v-if="selected_tile_module.slug === 'summary'">
                                    <p-summary-module
                                        :module-item="tile_summary"
                                        :config-errors="error_types"
                                        :is-loading="local_check_loading('tile_show_load') || local_check_loading('tile_edit_show_load')"
                                        @update="local_update_summary_changes"></p-summary-module>
                                </template>

                                <template v-if="selected_tile_module.slug === 'workspace'">
                                    <p-workspace-module
                                        :module-item="tile_workspace_id"
                                        :config-errors="error_types"
                                        :is-loading="local_check_loading('tile_show_load') || local_check_loading('tile_edit_show_load')"
                                        @update="local_update_workspace_changes"
                                        @remove-config="local_remove_config"></p-workspace-module>
                                </template>

                                <template v-if="selected_tile_module.slug === 'timeline'">
                                    <p-timeline-module
                                        :module-item="tile_timeline"
                                        :chart-type="local_chart_type"
                                        :config-errors="error_types"
                                        :is-loading="local_check_loading('tile_timeline_load') || local_check_loading('tile_edit_show_load')"
                                        @update="local_update_timeline_changes"
                                        @update-dates="local_update_timeline_dates"
                                        @remove-config="local_remove_config"></p-timeline-module>
                                </template>

                                <template v-if="selected_tile_module.slug === 'filters'">
                                    <p-filter-module
                                        :module-item="tile_filters"
                                        :config-errors="error_types"
                                        :selectedTile="selected_tile"
                                        :deleted_items="deletedItems"
                                        :workspace-id="tile_workspace_id.original.workspace_id ? tile_workspace_id.original.workspace_id : this.current_workspace_id_mixin"
                                        :is-loading="local_check_loading('modules_load') || local_check_loading('tile_edit_show_load')"
                                        :disabled_fields="disabled_fields"
                                        @add-filter="local_add_filter"
                                        @remove-filter="local_remove_filter_row"
                                        @update-filter-fields="local_update_filter_fields"
                                        @validate-config="local_validate_tile_config"
                                        @set-error="local_set_error"
                                        @clear_deleted_fields="local_clear_deleted_fields"></p-filter-module>
                                </template>

                                <template v-if="selected_tile_module.slug === 'datapoints'">
                                    <p-data-point-module
                                        :module-item="tile_datapoints"
                                        :config-errors="error_types"
                                        :selected-tile="selected_tile"
                                        :is-loading="local_check_loading('tile_datapoints_load') || local_check_loading('tile_edit_show_load')"
                                        @update="local_update_datapoint_changes"
                                        @remove-config="local_remove_config"></p-data-point-module>
                                </template>
                            </div>
                        </template>
                    </a-sheet>
                </a-sheet>
            </a-card>
        </a-dialog>
    </s-dashboard-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { SDashboardLayout } from '@/config/config-shared-components'
import { Validate } from '@/helpers/helper-validations'
import { v4 as uuidv4 } from 'uuid'
import loadWorkspace from '@/mixins/mixin-workspace'
import MixinTileReviewChange from './Mixins/MixinTileReviewChangeHelpers'
import MixinTileConfig from './Mixins/MixinTileConfigHelpers'
import { saveAsPng } from 'save-html-as-image'

import {
    PieChart,
    LineChart,
    DonutChart,
    NumberChart,
    BarVerticalChart,
    BarMultipleVerticalChart,
    BarStackedVerticalChart,
    LineMultipleVerticalChart
} from '@/components/Charts'

import {
    PInsightsType,
    PTileToolbar,
    PTileConfig,
    PChangesTable,
    PSummaryModule,
    PWorkspaceModule,
    PTimelineModule,
    PDataPointModule,
    PFilterModule
} from './Partials'

export default {
    mixins: [loadWorkspace, MixinTileReviewChange, MixinTileConfig],

    components: {
        SDashboardLayout,
        PInsightsType,
        PTileToolbar,
        PTileConfig,
        PChangesTable,
        PSummaryModule,
        PWorkspaceModule,
        PTimelineModule,
        PDataPointModule,
        PFilterModule,
        PieChart,
        LineChart,
        DonutChart,
        NumberChart,
        BarVerticalChart,
        BarMultipleVerticalChart,
        BarStackedVerticalChart,
        LineMultipleVerticalChart
    },

    data () {
        return {
            loading_statuses: ['loading', 'update-loading', 'new-loading', 'force-loading'],
            loading_more_actions: false,
            cloned_tile_highlight: null,
            dialog_tile_create: false,
            dialog_tile_config: false,
            dialog_board_create: false,
            dialog_board_delete: false,
            dialog_cancel_rearrange: false,
            is_board_export: false,
            enable_rearrange: false,
            discard_rearrange: false,
            search_board: '',
            current_view: {},
            review_view: false,
            tile_config_backup: {},
            tile_summary: { backup: {}, original: {} },
            tile_workspace_id: { backup: {}, original: {} },
            tile_timeline: { backup: {}, original: {} },
            tile_filters: { backup: [], original: [] },
            tile_datapoints: { backup: {}, original: {} },
            xaxis_list: [
                { text: 'Month over month', value: 'month' },
                { text: 'Quarter over quarter', value: 'quarter' },
            ],
            numbers_primary_slot: [
                { text: 'None', value: 'none' },
                { text: 'Count of Project', value: 'count(projects.id)' },
            ],
            numbers_secondary_slot: [
                { text: 'None', value: 'none' },
                { text: 'Average of Project -> Deal Amount', value: 'avg(projects.deal_amount)' },
                { text: 'Sum of Project -> Deal Amount', value: 'sum(projects.deal_amount)' },
                { text: 'Max of Project -> Deal Amount', value: 'max(projects.deal_amount)' },
                { text: 'Min of Project -> Deal Amount', value: 'min(projects.deal_amount)' }
            ],
            dpl_primary_slot: [
                { text: 'Project -> Health', value: 'projects.health_color' },
                { text: 'Project -> Status', value: 'projects.status' },
                { text: 'Project -> Stage -> Label', value: 'projects.stage_id' },
                { text: 'Project -> Stage -> Status', value: 'projects.stage.status' },
                { text: 'Project -> Result -> Label', value: 'projects.result_id' },
                { text: 'Project -> Result -> Status', value: 'projects.result.status' },
            ],
            aggregate_datapoints_list: [
                { text: 'Count of Project', value: 'count(projects.id)' },
                { text: 'Average of Project -> Deal Amount', value: 'avg(projects.deal_amount)' },
                { text: 'Sum of Project -> Deal Amount', value: 'sum(projects.deal_amount)' },
            ],
            tile_config_changes: [],
            tile_has_errors: false,
            selected_tile_module: {},
            selected_tile: {},
            error_types: [],
            timeline_field_types: [
                { text: 'Project -> Start Date', value: 'start_date' },
                { text: 'Project -> End Date', value: 'due_date' },
                { text: 'Project -> Estimated Start Date', value: 'estimated_start_date' },
                { text: 'Project -> Estimated End Date', value: 'estimated_end_date' },
            ],
            timeline_date_options: [
                { text: 'This Month', value: 'current_month' },
                { text: 'Last Month', value: 'last_month' },
                { text: 'This Quarter', value: 'current_quarter' },
                { text: 'Last Quarter', value: 'last_quarter' },
                { text: 'This Year', value: 'current_year' },
                { text: 'Last Year', value: 'last_year' },
                { text: 'Select a specific date range', value: 'date_range' },
            ],
            model_more_actions: false,
            edit_mode: null,
            tile_edit_mode: null,
            current_board_pref: null,
            insights_dropdown: false,
            error_options: [],
            loading_types: {},
            local_loading: true,
            board_params: { 'fields[boards]': 'id,title,description,is_active,created_at,updated_at', sort: 'order' },
            local_chart_type: null,
            custom_tag_list: [],
            system_tag_list: [],
            disabled_fields_and_tags_list: [],
            filterError: false,
            disableObj: [],
            inactive_custom_field_and_tag_list: [],
            deletedItems: [],
            disabled_fields: false,
            deleted_fields_state: false
        }
    },

    watch: {
        dialog_board_create (val) {
            if (!val) this.local_close_board_create()
        },

        insights_dropdown (val) {
            if (val) this.search_board = ''
        },
    },

    mounted () {
        this.local_index()
    },

    computed: {
        local_show_export_board() {
            if(this.tile_list.length > 0) {
                for(const item of this.tile_list) {
                    if(this.loading_statuses.includes(item.status)) {
                        return false
                    }
                }
                return true
            }
        },

        local_board_length () {
            return this.board_list && this.board_list.length
        },

        local_tile_changes_length () {
            return this.tile_config_changes && this.tile_config_changes.length
        },

        local_filtered_board () {
            const search_text = (this.search_board && this.search_board.toLowerCase()) ?? ''
            return this.board_list.filter(item => item.title && item.title.toLowerCase().includes(search_text))
        },

        local_is_route_board_view () {
            return this.$route.name === 'dashboard-insights-type'
        },

        local_tile_types () {
            const list = this.widget_list
            list.forEach(item => {
                const chartType = item.chart_type ? (item.chart_type.split(' ').join('_').toLowerCase() + '_chart') : ('pie_chart')
                item.custom_icon = true
                item.icon = chartType
            })
            return list
        },

        local_config_tile_menu () {
            return [
                { title: 'Summary', description: '', width: 'auto', slug: 'summary', custom_icon: false, icon: 'fact_check', icon_outlined: false },
                { title: 'Workspace', description: '', width: 'auto', slug: 'workspace', custom_icon: false, icon: 'apartment', icon_outlined: false },
                { title: 'Timeline', description: '', width: 'auto', slug: 'timeline', custom_icon: false, icon: 'watch_later', icon_outlined: false },
                { title: 'Filters', description: '', width: 'auto', slug: 'filters', custom_icon: false, icon: 'filter_alt', icon_outlined: false },
                { title: 'Datapoints', description: '', width: 'auto', slug: 'datapoints', custom_icon: false, icon: 'library_add', icon_outlined: false },
            ]
        },

        local_currency_format () {
            const currency = this.user_self?.organization?.currency
            if (currency.code === 'EUR') return 'en-EU'
            if (currency.code === 'INR') return 'en-IN'
            if (currency.code === 'GBP') return 'en-GB'
            if (currency.code === 'USD') return 'en-US'
            return 'en-US'
        },

        local_board_id () {
            return this.$route.params.view_id
        },

        local_is_multistack_charts () {
            return ['Multiple Bar', 'Stacked Bar', 'Multiple Line'].includes(this.selected_tile.chart_type)
        },

        tile_loading () {
            const loadedTiles = this.tile_list.filter(tile => tile.status === 'loaded' || tile.status === 'error')
            return (loadedTiles && (loadedTiles.length !== this.tile_list.length))
        },

        ...mapState('Board', {
            board_list: 'list',
            board_item: 'item',
            board_response: 'response',
        }),

        ...mapState('BoardEntity', {
            board_entity_list: 'list',
        }),

        ...mapState('Tile', {
            tile_list: 'list',
            tile_item: 'item',
            tile_response: 'response',
        }),

        ...mapState('TileOption', {
            tile_option_list: 'list',
            tile_option_item: 'item',
            tile_option_response: 'response',
        }),

        ...mapState('Widget', {
            widget_list: 'list',
        }),

        ...mapState('Customer', {
            customer_list: 'list',
        }),

        ...mapState('Partner', {
            partner_list: 'list',
        }),

        ...mapState('Stage', {
            stage_list: 'list',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Workspace', {
            workspace_search_list: 'search_list',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Preference', {
            preference_user_types: 'user_types',
        }),

        ...mapState('CustomField', {
            custom_field_list: 'list',
        }),

        ...mapState('TagSection', {
            tag_section_list: 'list',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
        }),
    },

    methods: {
        async local_index () {
            if (!this.local_user_can('index')) {
                return this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.tile_clear()
            await this.board_index(this.board_params)
            await this.local_fetch_board_preference()
            this.selected_tile_module = { ...this.local_config_tile_menu[0], index: 0 }
            this.board_entity_index({
                include: 'entityProperties',
                'fields[entities]': 'id',
                'fields[entity_properties]': 'entity_properties.entity_id,entity_properties.id,entity_properties.key,entity_properties.label,entity_properties.data_type,entity_properties.options'
            })
            this.widget_index()
            this.local_loading = false
        },

        async local_fetch_board_preference () {
            await this.preference_user_index()
            await this.local_switch_board_setup()
        },

        local_switch_board_setup () {
            this.current_board_pref = this.preference_user_types.find(item => item.key === 'user.insights_dashboard.board_view')
            const preference = this.current_board_pref ? _.find(this.board_list, { id: this.current_board_pref.value }) : this.board_list[0]
            const viewId = this.$route.params.view_id
            if (this.local_board_length && !this.local_is_route_board_view) {
                this.$router.push({ name: 'dashboard-insights-type', params: { view_id: viewId ?? preference?.id ?? this.board_list[0].id } })
            }
            // if (!this.local_board_length) this.$router.push({ name: 'dashboard-insights' })
            this.current_view = viewId ? _.find(this.board_list, { 'id': viewId }) : (preference ?? this.board_list[0])
        },

        async local_change_board_view (view) {
            this.current_view = view
            const view_id = this.$route.params.view_id
            if (view.id !== view_id || this.$route.name !== 'dashboard-insights-type') this.$router.push({ name: 'dashboard-insights-type', params: { view_id: view.id } })
            this.local_update_board_pref(view.id)
        },

        async local_update_board_pref (view_id) {
            const params = { resource_id: this.user_self.id, resource_type: 'User', value: view_id ?? null, label: 'Insights Dashboard', key: 'user.insights_dashboard.board_view', is_listable: 0 }
            if (this.current_board_pref) params['id'] = this.current_board_pref.id
            await this.preference_filters_update({ ...params })
        },

        // Board CRUD --- Start
        local_open_board_create () {
            this.dialog_board_create = true
        },

        local_close_board_create () {
            this.dialog_board_create = false
            this.error_options = []
            this.board_clear_item()
            setTimeout(() => {
                if (this.edit_mode) this.local_edit_mode(false)
            }, 200)
        },

        async local_create_board () {
            if (!await this.local_validate_options()) return
            this.local_set_loading('board_create', true)

            await this.board_store({ ...this.board_item, order: -1, mode: 'only-all-add-start' })
            if (!this.$status(this.board_response)) return this.local_op_failed_msg('board_create')

            this.current_view = { ...this.board_item }
            this.$notify('success', 'Board created successfully!')
            this.local_set_loading('board_create', false)
            this.$router.push({ name: 'dashboard-insights-type', params: { view_id: this.board_item.id } })

            await this.local_get_board_item(this.board_item.id)
            this.current_view = { ...this.board_item }
            this.local_change_board_view(this.board_item)
            this.local_close_board_create()
        },

        async local_board_edit () {
            await this.board_select({ id: this.current_view.id })
            this.local_edit_mode(true)
            this.local_open_board_create()
        },

        async local_board_update () {
            if (!await this.local_validate_options()) return
            this.local_set_loading('board_update', true)

            await this.board_update({ ...this.board_item, mode: '' })
            if (!this.$status(this.board_response)) return this.local_op_failed_msg('board_update')

            this.current_view = { ...this.board_item }
            this.$notify('success', 'Board updated successfully!')
            this.local_set_loading('board_update', false)
            this.local_close_board_create()
            this.local_get_board_item(this.current_view.id)
        },

        async local_board_delete () {
            this.local_set_loading('board_delete', true)

            await this.board_delete({ id: this.current_view.id })
            if (!this.$status(this.board_response)) return this.local_op_failed_msg('board_delete')

            this.local_set_loading('board_delete', false)
            this.$notify('success', 'Board Deleted Successfully!')
            this.dialog_board_delete = false
            if (!this.local_board_length) {
                this.current_view = {}
                this.local_update_board_pref()
                return this.$router.replace({ name: 'dashboard-insights' })
            }
            setTimeout(() => this.local_change_board_view(this.board_list[0]), 100)
        },

        async local_get_board_item (id, params, mode) {
            id = id ?? this.board_item.id
            params = params ?? this.board_params
            mode = mode ?? 'show-update'
            await this.board_show({ id, params, mode })
        },
        // Board CRUD --- End

        // Tile Crud --- Start
        local_open_tile_dialog (view_id) {
            this.dialog_tile_create = true
            this.local_tile_edit_mode(null)
            this.fiscal_timeline_show()
            this.deletedItems = []
            this.deleted_fields_state = false
        },

        async local_edit_tile_dialog (tile) {
            this.selected_tile_module = { ...this.local_config_tile_menu[0], index: 0 }
            this.error_types = []
            this.tile_has_errors = false
            this.tile_config_changes = []
            this.local_tile_edit_mode(tile.id)
            await this.local_set_tile_edit_config(tile)
            this.local_check_deleted_items(tile)
        },

        local_check_disable_item(key) {
            const custom_tag_item = this.tag_section_list.find(tag => key === tag.slug)
            if (custom_tag_item?.is_active === 0) this.disableObj.push(custom_tag_item)

            const custom_field_item = this.custom_field_list.find(field => key === `custom_field.${field.name}`)
            if (custom_field_item?.is_active === 0) this.disableObj.push(custom_field_item)

        },

        async local_set_tile_edit_config (tile) {
            await this.local_reset_tile_filters()
            this.local_set_tile_chart_type(tile)
            this.local_load_value_lists()
            this.selected_tile = { id: tile.id, chart_type: tile.source.chart_type }
            await this.local_set_summary(tile)
            this.dialog_tile_config = true
            await this.custom_field_index({
                'filter[resource_type]': 'Project',
            })
            await this.tag_section_index()
            this.inactive_custom_field_and_tag_list.length = 0
            const lists = [...this.custom_field_list, ...this.tag_section_list]
            lists.forEach(e => {
                if (e.is_active === 0) this.inactive_custom_field_and_tag_list.push(e)
            })

            this.local_set_loading('tile_edit_show_load', true)

            this.local_set_loading('tile_show_load', true)
            await this.tile_show({ id: tile.id, mode: 'show', params: { include: 'tile_options,tile_filters,source' } })
            this.local_set_loading('tile_show_load', false)

            this.local_set_loading('tile_workspace_load', true)
            await this.local_set_workspace()
            this.local_set_loading('tile_workspace_load', false)

            this.local_set_loading('tile_timeline_load', true)
            await this.local_set_timeline()
            this.local_set_loading('tile_timeline_load', false)

            this.local_set_loading('tile_filters_load', true)
            await this.local_set_filters()
            this.local_set_loading('tile_filters_load', false)

            this.local_set_loading('tile_datapoints_load', true)
            await this.local_set_datapoints()
            this.local_set_loading('tile_datapoints_load', false)
            this.disableObj.length = 0
            this.tile_item.tile_filters.forEach(item => {
                this.local_check_disable_item(item.key)
            })
            this.local_validate_config_modules()
            this.local_set_loading('tile_edit_show_load', false)
        },

        async local_load_value_lists () {
            this.local_set_loading('modules_load', true)
            await this.workspace_board_index({ mode: 'only-available', params: { 'sort': 'order', 'filters[is_active]': 1, 'fields[workspaces]': 'id,title' } })
            await this.customer_index({ 'sort': 'name', 'fields[customers]': 'id,name' })
            await this.partner_index({ 'sort': 'name', 'fields[partners]': 'id,name' })
            await this.meta_index({ 'sort': 'order', 'filter[type]': 'project_result', 'fields[metas]': 'id,value' })
            await this.stage_index({ 'sort': 'order', 'fields[stages]': 'id,name', 'filter[workspace_id]': this.current_workspace_id_mixin })
            await this.tag_index({ 'sort': 'order', 'filter[type]': 'label,product,platform', 'fields[tags]': 'id,type,label,color' })
            this.system_tag_list = this.tag_list
            await this.competitor_index({ 'sort': 'order', 'fields[competitors]': 'id,name' })
            await this.territory_index({ 'sort': 'order', 'fields[territories]': 'id,name,color', 'count': 999})
            this.local_set_loading('modules_load', false)
        },

        async local_set_summary (tile) {
            this.tile_summary.original = { title: tile.title, description: tile.description }
            this.tile_summary.backup = _.cloneDeep(this.tile_summary.original)
        },

        async local_set_workspace (tile) {
            const workspace = this.tile_item.tile_options.find(item => item.group === 'workspace')
            Object.assign(this.tile_workspace_id, { id: workspace.id })
            this.tile_workspace_id.original = _.cloneDeep({ workspace_id: workspace.value })
            this.tile_workspace_id.backup = _.cloneDeep(this.tile_workspace_id.original)
            await this.local_fetch_workspaces()
        },

        async local_set_timeline (tile) {
            const timeline_options = this.tile_item.tile_options.filter(item => item.group === 'timeline')
            timeline_options.forEach(item => {
                const value = item.value
                if (item.key === 'field') Object.assign(this.tile_timeline.original, { timeline_field: value })
                if (item.key === 'without_date') Object.assign(this.tile_timeline.original, { without_date: value })
                if (item.key === 'category') {
                    Object.assign(this.tile_timeline.original, { timeline_date_option: value })
                    this.fiscal_timeline_show()
                }
                if (item.key === 'start_date') Object.assign(this.tile_timeline.original, { start_date: value })
                if (item.key === 'due_date') Object.assign(this.tile_timeline.original, { due_date: value })
                Object.assign(this.tile_timeline.original, { id: item.id })
            })
            this.tile_timeline.backup = _.cloneDeep(this.tile_timeline.original)
        },

        async local_set_filters (tile) {
            let filters = _.cloneDeep(this.tile_item.tile_filters)
            filters = filters.reduce((prev, current) => {
                const isAvail = prev.find(item => item.group_id === current.group)
                const entity_properties = this.board_entity_list.reduce((prev, current) => prev = [...prev, ...current.entity_properties], [])
                entity_properties.push(...this.local_get_custom_fields_and_tags())
                const entity_item = entity_properties.find(item => item.key === current.key)
                if (current.id) current.id = uuidv4()
                delete current.order
                if (isAvail) {
                    current['entity_property_id'] = entity_item.id
                    current['row_id'] = current.id
                    current['group_id'] = current.group
                    if (this.local_get_entity_key(entity_item.id) === 'deal_amount') {
                        current.value = this.local_currency_formatter(current, 'value', 'return-value')
                        if (current.operator === 'between') {
                            current.value_alt = this.local_currency_formatter(current, 'value_alt', 'return-value')
                        }
                    }
                    isAvail.filters.push(_.cloneDeep(current))
                } else {
                    current['entity_property_id'] = entity_item.id
                    current['row_id'] = current.id
                    current['group_id'] = current.group
                    if (this.local_get_entity_key(entity_item.id) === 'deal_amount') {
                        current.value = this.local_currency_formatter(current, 'value', 'return-value')
                        if (current.operator === 'between') {
                            current.value_alt = this.local_currency_formatter(current, 'value_alt', 'return-value')
                        }
                    }
                    prev.push({
                        group_id: current.group,
                        filters: [_.cloneDeep(current)]
                    })
                }
                return prev
            }, [])
            this.tile_filters.original = _.cloneDeep(filters)
            this.tile_filters.backup = _.cloneDeep(this.tile_filters.original)
        },

        async local_set_datapoints () {
            const dataPointList = this.tile_item.tile_options.filter(item => item.group === 'data_point')
            for (let index = 0; index < dataPointList.length; index++) {
                const item = dataPointList[index]
                const key = item.key

                if (['category', 'primary_slot'].includes(key)) {
                    this.tile_datapoints.original['primary_slot'] = _.cloneDeep(item.value)
                    Object.assign(this.tile_datapoints.original, { id_primary_slot: item.id })
                }

                if (['value', 'secondary_slot'].includes(key)) {
                    this.tile_datapoints.original['secondary_slot'] = _.cloneDeep(item.value)
                    Object.assign(this.tile_datapoints.original, { id_secondary_slot: item.id })
                }

                if (['x-axis'].includes(key)) {
                    this.tile_datapoints.original['xaxis'] = _.cloneDeep(item.value)
                    Object.assign(this.tile_datapoints.original, { id_xaxis: item.id })
                }

                if (['y-axis-value'].includes(key)) {
                    this.tile_datapoints.original['y_axis_value'] = _.cloneDeep(item.value)
                    Object.assign(this.tile_datapoints.original, { id_y_axis_value: item.id })
                }

                if (['y-axis-group'].includes(key)) {
                    this.tile_datapoints.original['y_axis_group'] = _.cloneDeep(item.value)
                    Object.assign(this.tile_datapoints.original, { id_y_axis_group: item.id })
                }
            }
            this.tile_datapoints.backup = _.cloneDeep(this.tile_datapoints.original)
        },

        local_close_tile_dialog () {
            this.dialog_tile_create = false
            if (this.dialog_tile_config) {
                this.local_tile_edit_mode(null)
                this.review_view = false
                this.dialog_tile_config = false
                this.tile_config_changes = []
                this.local_reset_tile_filters()
                this.selected_tile_module = { ...this.local_config_tile_menu[0], index: 0 }
            }
        },

        async local_reset_tile_filters () {
            this.tile_summary = { backup: {}, original: {} }
            this.tile_workspace_id = { backup: {}, original: {} }
            this.tile_timeline = { backup: {}, original: {} }
            this.tile_filters = { backup: [], original: [] }
            this.tile_datapoints = { backup: {}, original: {} }
        },

        local_select_tile (tile) {
            this.selected_tile_module = tile
        },

        async local_create_tile ({ selected_tile }) {
            this.tile_config_changes = []
            this.selected_tile_module = { ...this.local_config_tile_menu[0], index: 0 }
            this.local_chart_type = selected_tile.chart_type
            await this.local_reset_tile_filters()
            this.error_types = []
            this.tile_has_errors = false
            this.local_close_tile_dialog()
            this.local_set_tile_modules(selected_tile)
            this.local_load_tile_assets()
            this.dialog_tile_config = true
        },

        local_set_tile_modules (tile) {
            this.selected_tile = tile
            this.tile_summary.backup = _.cloneDeep(this.tile_summary.original)
            this.tile_workspace_id.backup = _.cloneDeep(this.tile_workspace_id.original)
            this.tile_timeline.backup = _.cloneDeep(this.tile_timeline.original)
            this.tile_filters.backup = _.cloneDeep(this.tile_filters.original)
            this.tile_datapoints.backup = _.cloneDeep(this.tile_datapoints.original)
        },

        async local_load_tile_assets () {
            this.local_set_loading('workspace_list', true)
            await this.local_fetch_workspaces()
            this.local_set_loading('workspace_list', false)
        },

        async local_save_rearrange () {
            this.local_set_loading('tile_rearrange', true)
            await this.tile_list_reorder(this.tile_list)
            this.local_set_loading('tile_rearrange', false)
            this.enable_rearrange = false
        },

        local_cancel_rearrange_dialog () {
            this.discard_rearrange = false
            // this.enable_rearrange = true
            this.dialog_cancel_rearrange = true
        },

        local_discard_rearrange () {
            this.enable_rearrange = false
            this.discard_rearrange = true
            this.dialog_cancel_rearrange = false
        },

        // Tile config updates - Start
        async local_update_summary_changes (module, field, newValue) {
            const is_desc = (field === 'description')
            const error_obj = this.local_check_validation(newValue, is_desc ? 2 : 3, (is_desc ? 0 : 1), (is_desc ? 5000 : 255))

            if (error_obj !== true) {
                this.local_set_error('summary_' + field, error_obj.message)
                this.tile_has_errors = await this.local_validate_tile_config()
                return true
            }
            this.local_set_error('summary_' + field, null)
            this.tile_has_errors = await this.local_validate_tile_config()

            const oldValue = this.local_get_backup_value(this.tile_summary, field)
            const index = this.tile_config_changes.findIndex(item => item.key === field)
            if ((newValue || oldValue) && (newValue !== oldValue)) {
                const updatedObj = { key: field, newValue, oldValue, module, updatedAt: moment().format('h:mm a') }
                if (index === -1) this.tile_config_changes.push(updatedObj)
                else this.$set(this.tile_config_changes, index, updatedObj)
                return true
            }
            if (index !== -1) this.tile_config_changes.splice(index, 1)
        },

        async local_update_workspace_changes (module, field, newValue, from_validate = null) {
            const error_obj = this.local_check_validation(newValue, 1)
            if (error_obj !== true) {
                this.local_set_error(field, error_obj.message)
                this.tile_has_errors = await this.local_validate_tile_config()
                return true
            }
            this.local_set_error(field, null)
            this.tile_has_errors = await this.local_validate_tile_config()
            if (!from_validate) this.stage_index({ 'sort': 'order', 'fields[stages]': 'id,name', 'filter[workspace_id]': newValue ?? this.current_workspace_id_mixin })

            const newValueTitle = this.workspace_search_list.find(item => item.id === newValue)
            if (!newValueTitle) {
                const error_obj = this.local_check_validation(null, 1)
                this.local_set_error(field, error_obj.message)
                this.tile_has_errors = await this.local_validate_tile_config()
                return true
            }

            const oldValue = this.local_get_backup_value(this.tile_workspace_id, field)
            const index = this.tile_config_changes.findIndex(item => item.key === field)
            if ((newValue || oldValue) && (newValue !== oldValue)) {
                const oldValueItem = this.workspace_search_list.find(item => item.id === oldValue)
                const updatedObj = { key: field, newValue: newValueTitle.title ?? null, oldValue: (oldValueItem?.title) ?? null , module, updatedAt: moment().format('h:mm a') }
                if (index === -1) this.tile_config_changes.push(updatedObj)
                else this.$set(this.tile_config_changes, index, updatedObj)
                return true
            }
            if (index !== -1) this.tile_config_changes.splice(index, 1)
        },

        async local_update_timeline_changes (module, field, newValue) {
            if (field !== 'timeline_date_option') {
                this.local_set_error('timeline_start_date', null)
                this.local_set_error('timeline_due_date', null)
            }
            const item_lists = field !== 'timeline_date_option' ? this.timeline_field_types : this.timeline_date_options
            const error_obj = this.local_check_validation(newValue, 1)
            if (error_obj !== true) {
                this.local_set_error(field, error_obj.message)
                this.tile_has_errors = await this.local_validate_tile_config()
                return true
            }
            this.local_set_error(field, null)
            this.tile_has_errors = await this.local_validate_tile_config()

            const newValueLabel = item_lists.find(item => item.value === newValue)
            const oldValue = this.local_get_backup_value(this.tile_timeline, field)
            const index = this.tile_config_changes.findIndex(item => item.key === field)
            if ((newValue || oldValue) && (newValue !== oldValue)) {
                const oldValueLabel = item_lists.find(item => item.value === oldValue)
                const updatedObj = { key: field, newValue, oldValue, newValueLabel: (newValueLabel?.text ?? null), oldValueLabel: (oldValueLabel?.text ?? null), module, updatedAt: moment().format('h:mm a') }
                if (index === -1) this.tile_config_changes.push(updatedObj)
                else this.$set(this.tile_config_changes, index, updatedObj)
                return true
            }
            if (index !== -1) this.tile_config_changes.splice(index, 1)
        },

        async local_update_timeline_dates (module, field, newValue) {
            const error_obj = this.local_check_validation(newValue, 1)
            if (error_obj !== true) {
                this.local_set_error('timeline_' + field, error_obj.message)
                this.tile_has_errors = await this.local_validate_tile_config()
                return true
            }
            this.local_set_error('timeline_' + field, null)
            this.tile_has_errors = await this.local_validate_tile_config()

            const oldValue = this.local_get_backup_value(this.tile_timeline, field)
            const index = this.tile_config_changes.findIndex(item => item.key === field)
            if (newValue !== oldValue) {
                const valueLabels = { newValueLabel: field ? field.split('_').join(' ') : '', oldValueLabel: field ? field.split('_').join(' ') : '' }
                const updatedObj = { key: field, newValue, oldValue, ...valueLabels, module, updatedAt: moment().format('h:mm a') }
                if (index === -1) this.tile_config_changes.push(updatedObj)
                else this.$set(this.tile_config_changes, index, updatedObj)
                return true
            }
            if (index !== -1) this.tile_config_changes.splice(index, 1)
        },

        local_remove_config (field) {
            const index = this.tile_config_changes.findIndex(item => item.key === field)
            if (index !== -1) this.tile_config_changes.splice(index, 1)
        },

        async local_update_datapoint_changes (module, field, newValue) {
            const error_obj = this.local_check_validation(newValue, 1)
            if (error_obj !== true) {
                this.local_set_error('datapoints_' + field, error_obj.message)
                this.tile_has_errors = await this.local_validate_tile_config()
                return true
            }
            this.local_set_error('datapoints_' + field, null)
            this.tile_has_errors = await this.local_validate_tile_config()

            let fieldLabel = null
            if (field === 'primary_slot') fieldLabel = this.selected_tile.chart_type === 'Numbers' ? 'Primary Slot' : 'Category'
            if (field === 'secondary_slot') fieldLabel = 'Value'
            if (field === 'value') fieldLabel = 'Value'
            if (field === 'xaxis') fieldLabel = 'X-Axis'
            if (field === 'y_axis_group') fieldLabel = 'Group'
            if (field === 'y_axis_value') fieldLabel = 'Value'

            const oldValue = this.local_get_backup_value(this.tile_datapoints, field)
            const index = this.tile_config_changes.findIndex(item => item.key === field)
            if ((newValue || oldValue) && (newValue !== oldValue)) {
                let newValueLabel, oldValueLabel;
                if (field === 'primary_slot') {
                    newValueLabel = this.local_get_primary_slot_list().find(item => item.value === newValue)
                    oldValueLabel = this.local_get_primary_slot_list().find(item => item.value === oldValue)
                }

                if (field === 'secondary_slot') {
                    newValueLabel = this.local_get_secondary_slot_list().find(item => item.value === newValue)
                    oldValueLabel = this.local_get_secondary_slot_list().find(item => item.value === oldValue)
                    // newValueLabel = this.aggregate_datapoints_list.find(item => item.value === newValue)
                    // oldValueLabel = this.aggregate_datapoints_list.find(item => item.value === oldValue)
                }

                if (field === 'value') {
                    newValueLabel = this.aggregate_datapoints_list.find(item => item.value === newValue)
                    oldValueLabel = this.aggregate_datapoints_list.find(item => item.value === oldValue)
                }

                if (field === 'xaxis') {
                    newValueLabel = this.xaxis_list.find(item => item.value === newValue)
                    oldValueLabel = this.xaxis_list.find(item => item.value === oldValue)
                }

                if (field === 'y_axis_group') {
                    newValueLabel = this.dpl_primary_slot.find(item => item.value === newValue)
                    oldValueLabel = this.dpl_primary_slot.find(item => item.value === oldValue)
                }

                if (field === 'y_axis_value') {
                    newValueLabel = this.aggregate_datapoints_list.find(item => item.value === newValue)
                    oldValueLabel = this.aggregate_datapoints_list.find(item => item.value === oldValue)
                }

                const updatedObj = { key: field, newValue, oldValue, field_label: fieldLabel, newValueLabel: (newValueLabel?.text ?? null), oldValueLabel: (oldValueLabel?.text ?? null), module, updatedAt: moment().format('h:mm a') }
                if (index === -1) this.tile_config_changes.push(updatedObj)
                else this.$set(this.tile_config_changes, index, updatedObj)
                return true
            }
            if (index !== -1) this.tile_config_changes.splice(index, 1)
        },
        // Tile config updates - End

        local_get_backup_value (tile_object, field) {
            const config = tile_object['backup']
            const [firstSlug, secondSlug, thirdSlug] = field.split('.')
            if (thirdSlug) return config[firstSlug]?.secondSlug?.thirdSlug
            if (secondSlug) return config[firstSlug]?.secondSlug
            return config[firstSlug]
        },

        local_update_filter_fields (filter_item, cleared = 'clear') {
            const custom_tag_module = this.tag_section_list.find(item => item.id === filter_item.entity_property_id)
            if (custom_tag_module) this.local_load_custom_tag_list(custom_tag_module.id)
            const { group_id, id: row_id } = filter_item
            const backupIndex = this.tile_filters.backup.findIndex(item => item.group_id === group_id)
            const backupFilterIndex = (backupIndex !== -1 && row_id) ? this.tile_filters.backup[backupIndex].filters.findIndex(item => item.id === row_id) : -1
            const origIndex = this.tile_filters.original.findIndex(item => item.group_id === group_id)
            const origFilterIndex = (origIndex !== -1 && row_id) ? this.tile_filters.original[origIndex].filters.findIndex(item => item.id === row_id) : -1
            const configIndex = this.tile_config_changes.findIndex(item => item.row_id && (item.row_id === row_id))
            this.local_validate_disabled_filter()

            if (configIndex !== -1) {
                const origValue = this.tile_filters.original[origIndex].filters[origFilterIndex]
                const backupValue = (backupFilterIndex !== -1) ? this.tile_filters.backup[backupIndex].filters[backupFilterIndex] : -1
                if (
                    backupValue !== -1 &&
                    (
                        backupValue.entity_property_id === origValue.entity_property_id &&
                        backupValue.operator === origValue.operator &&
                        backupValue.value === origValue.value &&
                        backupValue.operator !== 'between' && origValue.operator !== 'between' ||
                        (
                            (backupValue.operator === 'between' && origValue.operator === 'between') &&
                            (backupValue.value_alt === origValue.value_alt)
                        )
                    )
                ) {
                    return this.tile_config_changes.splice(configIndex, 1)
                }

                const value = _.cloneDeep(this.tile_filters.original[origIndex].filters[origFilterIndex])
                if (this.tile_config_changes[configIndex].newValue.hasOwnProperty('value')) this.tile_config_changes[configIndex].newValue.value = value
                else this.tile_config_changes[configIndex].newValue = value
                return true
            }

            const commonKeys = { row_id, group_id, key: 'filter_updated', module: 'Filters', updatedAt: moment().format('h:mm a') }
            const tile_obj = {
                ...commonKeys,
                newValue: {
                    groups: this.tile_filters.original.length,
                    rows: this.tile_filters.original.reduce((prev, current) => prev + current.filters.length, 0),
                    value: this.tile_filters.original[origIndex].filters.find(item => item.id === row_id)
                },
                oldValue: {
                    groups: this.tile_filters.backup.length,
                    rows: this.tile_filters.backup.reduce((prev, current) => prev + current.filters.length, 0),
                    value: this.tile_filters.backup[backupIndex].filters.find(item => item.id === row_id)
                }
            }
            this.tile_config_changes.push(tile_obj)
        },

        local_validate_disabled_filter() {
            let is_disabled_present = false
            this.disableObj?.forEach(item => {
                this.tile_filters.original.forEach(e => {
                    e.filters.every(filter => {
                        if (item.id === filter.entity_property_id) {
                            is_disabled_present = true
                            return false
                        }
                        return true
                    })
                })
            })
            if (!is_disabled_present && this.disableObj.length) {
                this.error_types = this.error_types.filter(item => {
                    if (item.hasOwnProperty('disabled')) return false
                    else return true
                })
                this.disableObj.length = 0
                this.disabled_fields = false
            }
        },

        async local_load_custom_tag_list(tag_id) {
            await this.tag_index({ 'filter[tagSection.id]': tag_id, 'sort': 'order' })
            this.custom_tag_list = this.tag_list.map(item => ({ id: item.id, name: item.label, type: item.type, color: item.color }))
        },

        async local_clear_changes (key, module, filter_item) {
            const configIndex = this.tile_config_changes.findIndex(item => {
                return module === 'Filters' ?
                    (item.row_id ? (item.row_id === filter_item.row_id) : (item.group_id === filter_item.group_id)) :
                    (item.key === key)
            })

            if (module === 'Filters') {
                await this.local_remove_filter_row_changes(filter_item.group_id, filter_item.row_id)
                this.local_validate_filters()
            }

            if (configIndex !== -1) {
                const changes = this.tile_config_changes[configIndex]
                this.local_reset_old_config(module, { ...changes })
                this.tile_config_changes.splice(configIndex, 1)
            }
        },

        async local_remove_filter_row_changes (group_id, row_id) {
            const type = row_id ? 'row' : 'group'
            const backupIndex = this.tile_filters.backup.findIndex(item => item.group_id === group_id)
            const backupFilterIndex = (backupIndex !== -1 && row_id) ? this.tile_filters.backup[backupIndex].filters.findIndex(item => item.id === row_id) : -1
            const origIndex = this.tile_filters.original.findIndex(item => item.group_id === group_id)
            const origFilterIndex = (origIndex !== -1 && row_id) ? this.tile_filters.original[origIndex].filters.findIndex(item => item.id === row_id) : -1

            // type -> group, origIndex -> -1, backupIndex -> >=0
            if (type === 'group' && origIndex === -1 && backupIndex !== -1) {
                // console.log('hello 1 % -1 >=0')
                this.tile_filters.original.push(_.cloneDeep(this.tile_filters.backup[backupIndex]))
                return true
            }

            // type -> group, origIndex -> >=0, backupIndex -> -1
            if (type === 'group' && origIndex !== 1 && backupIndex === -1) {
                // console.log('hello 2 % >=0 | -1')
                this.tile_filters.original.splice(origIndex, 1)
                return true
            }

            // type -> group, origIndex -> -1, backupIndex -> -1 => Clear changes without doing any replace operation
            if (type === 'group' && origIndex === -1 && backupIndex === -1) {
                // console.log('hello 3 % -1 | -1')
                return true
            }

            // type -> group, origIndex -> >=0, backupIndex -> >=0 => Clear changes without doing any replace operation
            if (type === 'group' && origIndex !== -1 && backupIndex !== -1) {
                // console.log('hello 4 % >=0 | >=0')
                this.tile_filters.original[origIndex].filters.push(_.cloneDeep(this.tile_filters.backup[backupIndex].filters[backupFilterIndex]))
                return true
            }

            // type -> row, origFilterIndex -> -1, backupFilterIndex -> >=0
            if (type === 'row' && origFilterIndex === -1 && backupFilterIndex !== -1) {
                // console.log('hello 5 % -1 | >=0')
                if (origIndex === -1) {
                    const params = { group_id: this.tile_filters.backup[backupIndex].group_id, filters: [] }
                    params.filters.push(_.cloneDeep(this.tile_filters.backup[backupIndex].filters[backupFilterIndex]))
                    this.tile_filters.original.push(params)
                    return true
                }
                this.tile_filters.original[origIndex].filters.push(_.cloneDeep(this.tile_filters.backup[backupIndex].filters[backupFilterIndex]))
                return true
            }

            // type -> row, origFilterIndex -> >=0, backupFilterIndex -> -1
            if (type === 'row' && origFilterIndex !== -1 && backupFilterIndex === -1) {
                // console.log('hello 6 % >=0 | -1')
                this.tile_filters.original[origIndex].filters.splice(origFilterIndex, 1)
                if (_.size(this.tile_filters.original[origIndex].filters) === 0) {
                    this.tile_filters.original.splice(origIndex, 1)
                }
                return true
            }

            // type -> row, origFilterIndex -> -1, backupFilterIndex -> -1 => Clear changes without doing any replace operation
            if (type === 'row' && origFilterIndex === -1 && backupFilterIndex === -1) {
                // console.log('hello 7 % -1 | -1')
                const configIndex = this.tile_config_changes.findIndex(item => item.row_id === row_id)
                configIndex !== -1 ? this.tile_config_changes.splice(configIndex, 1) : ''
                return true
            }

            // type -> row, origFilterIndex -> >=0, backupFilterIndex -> >=0 => Clear changes without doing any replace operation
            if (type === 'row' && origFilterIndex !== -1 && backupFilterIndex !== -1) {
                // console.log('hello 8 % >=0 | >=0')
                this.tile_filters.original[origIndex].filters[origFilterIndex] = _.cloneDeep(this.tile_filters.backup[backupIndex].filters[backupFilterIndex])
                return true
            }
        },

        local_reset_old_config (module, { key, oldValue, newValue, row_id, group_id }) {
            switch (module) {
                case 'Summary': return this.tile_summary.original[key] = oldValue
                case 'Workspace': return this.tile_workspace_id.original[key] = oldValue
                case 'Timeline': return this.tile_timeline.original[key] = oldValue
                case 'Datapoints': return this.tile_datapoints.original[key] = oldValue
                default: return
            }
        },

        local_section_has_changes (section_title) {
            const section_changes = this.tile_config_changes.map(({ module }) => module)
            return section_changes.includes(section_title)
        },

        local_get_primary_slot_list () {
            const projectCompetitor = { text: 'Project -> Competitors', value: 'projects.competitors' }
            switch (this.selected_tile.chart_type) {
                case 'Numbers': return this.numbers_primary_slot
                case 'Donut':
                case 'Pie': {
                    this.dpl_primary_slot.splice(2, 0, projectCompetitor)
                    return this.dpl_primary_slot
                }
                case 'Bar':
                case 'Multiple Line':
                case 'Stacked Bar':
                case 'Multiple Bar':
                case 'Line': return this.dpl_primary_slot
            }
        },

        local_get_secondary_slot_list () {
            switch (this.selected_tile.chart_type) {
                case 'Numbers': return this.numbers_secondary_slot
                case 'Donut':
                case 'Pie':
                case 'Bar':
                case 'Multiple Line':
                case 'Stacked Bar':
                case 'Multiple Bar':
                case 'Line': return this.aggregate_datapoints_list
            }
        },

        local_delete_tile (id) {
            this.tile_destroy({ id })
        },
        // Tile Crud --- End

        // Tile Crud Validations --- Start
        local_set_error (slug, message = null) {
            const index = this.error_types.findIndex(item => item.slug === slug)
            if (!message) {
                if (index !== -1) this.error_types.splice(index, 1)
                return true
            }
            if (index !== -1) return this.$set(this.error_types, index, { slug, message })

            return this.error_types.push({ slug, message })
        },

        local_has_validation_slug (slug) {
            const index = this.error_types.findIndex(item => item.slug && item.slug.includes(slug))
            return index !== -1
        },
        // Tile Crud Validations --- End

        // Tile config --- Start
        async local_fetch_workspaces () {
            this.workspace_clear_search_list()
            const filters = {
                mode: 'only-search',
                params: {
                    'filters[workspaces]': 'id,name',
                    'filters[sort]': 'name',
                    'filters[is_active]': 1,
                }
            }
            await this.workspace_board_index(filters)
        },

        // Tile filters --- Start
        local_add_filter (parentGroupId = 'new', group_index = null) {
            const groupId = uuidv4()
            const rowId = uuidv4()
            const newGroupObj = { group_id: groupId, filters: [{ id: rowId, group_id: groupId, entity_property_id: '', operator: '', value: '', value_alt: '' }] }
            if (parentGroupId === 'new' && group_index === null) {
                this.tile_filters.original.push(newGroupObj)
                this.local_add_filter_row_to_changes('filter_added', groupId, rowId)
                return true
            }
            if (parentGroupId === 'new' && group_index >= 0) {
                this.tile_filters.original.splice(group_index + 1, 0, newGroupObj)
                this.local_add_filter_row_to_changes('filter_added', groupId, rowId)
                return true
            }
            const groupConfigIndex = this.tile_filters.original.findIndex(item => item.group_id === parentGroupId)
            this.tile_filters.original[groupConfigIndex].filters.push({ id: rowId, group_id: parentGroupId, entity_property_id: '', operator: '', value: '', value_alt: '' })
            this.local_add_filter_row_to_changes('filter_added', parentGroupId, rowId)
        },

        local_add_filter_row_to_changes (key, group_id, row_id) {
            const originalList = this.tile_filters.original
            const backupList = this.tile_filters.backup
            const newGroupIndex = originalList.findIndex(item => item.group_id === group_id)
            const backupGroupIndex = backupList.findIndex(item => item.group_id === group_id)
            const commonKeys = { row_id, group_id, key, module: 'Filters', updatedAt: moment().format('h:mm a') }
            const is_deleted = ['filter_deleted', 'group_filter_deleted'].includes(key)
            const tile_obj = {
                ...commonKeys,
                newValue: {
                    groups: this.tile_filters.original.length,
                    rows: this.tile_filters.original.reduce((prev, current) => prev + current.filters.length, 0),
                    value: (!is_deleted && this.tile_filters.original[newGroupIndex]) ?
                        this.tile_filters.original[newGroupIndex].filters.find(item => item.id === row_id) : {}
                },
                oldValue: {
                    groups: this.tile_filters.backup.length,
                    rows: this.tile_filters.backup.reduce((prev, current) => prev + current.filters.length, 0),
                    value: (!is_deleted && this.tile_filters.backup[backupGroupIndex]) ?
                        this.tile_filters.backup[backupGroupIndex].filters.find(item => item.id === row_id) : {}
                }
            }
            this.tile_config_changes.push(tile_obj)
        },

        local_get_data_type (prop_id) {
            if (!prop_id) return []
            return this.local_get_entity_key(prop_id, 'data_type')
        },

        local_remove_filter_row (row, type = 'row') {
            const group_index = this.tile_filters.original.findIndex(item => item.group_id === row.group_id)
            if (type !== 'row') {
                const clonedGroup = _.cloneDeep(this.tile_filters.original[group_index])
                this.tile_filters.original.splice(group_index, 1)
                this.local_remove_from_saved_changes(clonedGroup, 'group')
                this.local_validate_filters()
                return true
            }
            const row_index = this.tile_filters.original[group_index].filters.findIndex(item => item.id === row.id)
            const clonedRow = _.cloneDeep(this.tile_filters.original[group_index].filters[row_index])
            this.tile_filters.original[group_index].filters.splice(row_index, 1)
            if (!this.tile_filters.original[group_index].filters.length) this.tile_filters.original.splice(group_index, 1)
            this.local_remove_from_saved_changes(clonedRow)
            this.local_validate_filters()
            this.local_validate_disabled_filter()
        },

        local_remove_from_saved_changes (filter_item, type = 'row') {
            const group_id = filter_item.group_id
            const row_id = type !== 'row' ? null : filter_item.id
            const backupIndex = this.tile_filters.backup.findIndex(item => item.group_id === group_id)
            const backupFilterIndex = backupIndex !== -1 ? this.tile_filters.backup[backupIndex].filters.findIndex(item => item.id === row_id) : -1
            const changeIndex = this.tile_config_changes.findIndex(item => type !== 'row' ? (item.group_id === group_id) : (item.row_id === row_id))

            // new group with -> no backup
            // remove -> new group from changes
            if (backupIndex === -1) {
                this.tile_config_changes.splice(changeIndex, 1)
            }

            // new row with -> no backup
            // remove -> new row from changes
            if (backupFilterIndex === -1) {
                this.tile_config_changes.splice(changeIndex, 1)
            }

            // old group with -> backup
            // remove group from original and show "deleted" in changes new-value
            if (backupIndex !== -1 && type === 'group') {
                this.local_add_filter_row_to_changes('group_filter_deleted', group_id, row_id)
            }

            // old row with -> backup
            // remove row from original and show "deleted" in changes new-value
            if (backupFilterIndex !== -1 && type === 'row') {
                this.local_add_filter_row_to_changes('filter_deleted', group_id, row_id)
            }
        },
        // Tile filters --- End

        async local_validate_tile_config () {
            const menu_slugs = this.local_config_tile_menu.map(item => item.slug)
            const error_lists = menu_slugs.reduce((prev, current_slug) => {
                const hasError = this.local_has_validation_slug(current_slug)
                if (hasError) prev.push(current_slug)
                return prev
            }, [])
            return error_lists.length
        },

        local_check_validation (value, level, min = 0, max = 255) {
            switch (level) {
                case 1: return new Validate(value, { silent: true }).required().run()
                case 2: return new Validate(value, { silent: true }).length(min, max).run()
                case 3: return new Validate(value, { silent: true }).required().length(min, max).run()
                case 4: return new Validate(value, { silent: true }).required().length(min, max).isNumber().run()
            }
        },

        async local_clone_tile (tile, clear_loading, index) {
            this.cloned_tile_highlight = null
            await this.tile_clone_store({ tile_id: tile.id, mode: 'only-all-add-modify' })
            await this.tile_show({ id: this.tile_item.id, mode: 'show-add-end-index', params: { include: 'tileOptions,tileFilters,source' } })
            await this.tile_update_tile_status({ id: this.tile_item.id, status: 'new-loading'})
            clear_loading()
            this.$vuetify.goTo(document.body.scrollHeight)
            const selected_tile = this.tile_list.find(item => item.id === this.tile_item.id)
            this.cloned_tile_highlight = selected_tile.id
            setTimeout(() => this.cloned_tile_highlight = null, 5000)
            // this.local_set_tile_edit_config(selected_tile)
        },

        async local_save_tile_config (tile) {
            this.local_set_loading('tile_create', true)
            // this.tile_has_errors = await this.local_validate_tile_config()
            // if (this.tile_has_errors) return this.local_set_loading('tile_create', false)
            await this.local_save_tile_fields(tile)
            await this.local_save_workspace_fields(tile)
            await this.local_save_timeline_fields(tile)
            await this.local_save_filter_fields(tile)
            await this.local_save_datapoint_fields(tile)

            // await this.tile_show_stats({ id: this.tile_item.id })
            await this.tile_show({ id: this.tile_item.id, mode: !this.tile_edit_mode ? 'show-add-end' : 'show-update', params: { include: 'tileOptions,tileFilters,source' } })
            await this.tile_update_tile_status({ id: this.tile_item.id, status: !this.tile_edit_mode ? 'new-loading' : 'update-loading'})

            this.local_reset_changes_close_dialog()
            this.local_set_loading('tile_create', false)
        },

        async local_save_tile_fields (tile_source) {
            const params = { ...this.tile_summary.original, board_id: this.local_board_id, source_id: tile_source.id }
            if (this.tile_edit_mode) return await this.tile_update({ mode: 'only-add-modify', id: this.tile_edit_mode, ...params })
            await this.tile_store({ mode: 'only-all-add-modify', ...params })
        },

        async local_save_workspace_fields () {
            const params = { group: 'workspace', key: 'workspace_id', tile_id: this.tile_item.id, value: this.tile_workspace_id.original.workspace_id }
            if (this.tile_edit_mode) return await this.tile_option_update({ mode: 'only-add-modify', id: this.tile_workspace_id.id, ...params })
            await this.tile_option_store({ mode: 'only-all-add-start', ...params })
        },

        async local_save_timeline_fields () {
            const { timeline_field, timeline_date_option, without_date, start_date, due_date, id: timeline_id } = this.tile_timeline.original
            const params = { field: timeline_field, category: timeline_date_option, without_date: without_date ? without_date : 'not_included', start_date: start_date, due_date: due_date }
            const keys = ['field', 'category', 'without_date']
            if (params.category === 'date_range') keys.push(...['start_date', 'due_date'])

            if (this.tile_edit_mode) {
                const timelineFields = this.tile_item.tile_options.filter(item => item.group === 'timeline' && ['field', 'category', 'without_date'].includes(item.key))
                const timelineDates = this.tile_item.tile_options.filter(item => item.group === 'timeline' && ['start_date', 'due_date'].includes(item.key))
                timelineFields.forEach(item => this.tile_option_update({ mode: 'only-add-modify', group: 'timeline', id: item.id, key: item.key, value: params[item.key] }))
                if (timeline_date_option === 'date_range') {
                    if (timelineDates.length) {
                        timelineDates.forEach(item => this.tile_option_update({ mode: 'only-add-modify', group: 'timeline', id: item.id, key: item.key, value: params[item.key] }))
                    } else {
                        const rangeKeys = ['start_date', 'due_date']
                        this.local_store_timeline_data(rangeKeys, params)
                    }
                }
                const withoutDateField = timelineFields.some(item => item.key === 'without_date')
                if (timeline_date_option === 'all_time' && !withoutDateField) {
                    this.local_store_timeline_data(['without_date'], params)
                }
            } else this.local_store_timeline_data(keys, params)

            const backup_date_option = this.tile_timeline.backup.timeline_date_option
            const original_date_option = this.tile_timeline.original.timeline_date_option
            if (backup_date_option === 'date_range' && original_date_option !== 'date_range') {
                const dateFields = this.tile_item.tile_options.filter(item => ['start_date', 'due_date'].includes(item.key))
                dateFields.forEach(({ id }) => this.tile_option_delete({ id }))
            }
        },

        async local_store_timeline_data (rangeKeys, params) {
            for (let i = 0; i < rangeKeys.length; i++) {
                const key = rangeKeys[i]
                const reqParams = { key, value: params[key], group: 'timeline', tile_id: this.tile_item.id }
                await this.tile_option_store({ mode: 'only-all-add-start', ...reqParams })
            }
        },

        async local_save_filter_fields () {
            const params = { tile_id: this.tile_item.id, data: [] }
            for (let index = 0; index < this.tile_filters.original.length; index++) {
                const item = this.tile_filters.original[index];
                const groupModified = _.cloneDeep(item.filters).map(filter_item => {
                    filter_item.group = _.cloneDeep(filter_item.group_id)
                    delete filter_item.group_id
                    const prop_key = this.local_get_entity_key(filter_item.entity_property_id)
                    if (prop_key === 'deal_amount') {
                        filter_item['value'] = filter_item['value'].split(',').join('')
                        if (filter_item.operator === 'between') filter_item['value_alt'] = filter_item['value_alt'].split(',').join('')
                    }
                    return filter_item
                })
                params.data.push(...groupModified)
            }
            // if (params.data.length)
            await this.tile_filter_store({ mode: 'only-all-add-start', ...params })
        },

        async local_save_datapoint_fields () {
            const { primary_slot, secondary_slot, id_primary, id_secondary } = this.tile_datapoints.original
            const { xaxis, y_axis_group, y_axis_value } = this.tile_datapoints.original
            const { chart_type } = this.selected_tile
            let params = []
            if (chart_type === 'Numbers') {
                params.push({ key: 'primary_slot', value: primary_slot })
                params.push({ key: 'secondary_slot', value: secondary_slot })
            }
            else if (['Line', 'Bar'].includes(chart_type)) {
                params.push({ key: 'x-axis', value: xaxis })
                params.push({ key: 'y-axis-value', value: y_axis_value })
            }
            else if (['Multiple Bar', 'Stacked Bar', 'Multiple Line'].includes(chart_type)) {
                params.push({ key: 'x-axis', value: xaxis })
                params.push({ key: 'y-axis-group', value: y_axis_group })
                params.push({ key: 'y-axis-value', value: y_axis_value })
            }
            else {
                params.push({ key: 'category', value: primary_slot, id: id_primary })
                params.push({ key: 'value', value: secondary_slot, id: id_secondary })
            }

            if (this.tile_edit_mode) {
                let pFieldName, sFieldName, tFieldName;
                let pFieldValue, sFieldValue, tFieldValue;
                if (chart_type === 'Numbers') {
                    pFieldValue = primary_slot
                    sFieldValue = secondary_slot
                    pFieldName = 'primary_slot'
                    sFieldName = 'secondary_slot'
                }
                else if (['Line', 'Bar'].includes(chart_type)) {
                    pFieldValue = xaxis
                    sFieldValue = y_axis_value
                    pFieldName = 'x-axis'
                    sFieldName = 'y-axis-value'
                }
                else if (['Multiple Bar', 'Stacked Bar', 'Multiple Line'].includes(chart_type)) {
                    pFieldValue = xaxis
                    sFieldValue = y_axis_value
                    tFieldValue = y_axis_group
                    pFieldName = 'x-axis'
                    sFieldName = 'y-axis-value'
                    tFieldName = 'y-axis-group'
                }
                else {
                    pFieldValue = primary_slot
                    sFieldValue = secondary_slot
                    pFieldName = 'category'
                    sFieldName = 'value'
                }
                const primarySlotField = this.tile_item.tile_options.find(item => item.group === 'data_point' && item.key === pFieldName)
                const secondarySlotField = this.tile_item.tile_options.find(item => item.group === 'data_point' && item.key === sFieldName)
                const tertiarySlotField = tFieldName ?this.tile_item.tile_options.find(item => item.group === 'data_point' && item.key === tFieldName) : null

                if (primarySlotField) this.tile_option_update({ mode: 'only-add-modify', group: 'data_point', id: primarySlotField.id, key: pFieldName, value: pFieldValue })
                if (secondarySlotField) this.tile_option_update({ mode: 'only-add-modify', group: 'data_point', id: secondarySlotField.id, key: sFieldName, value: sFieldValue })
                if (tertiarySlotField) this.tile_option_update({ mode: 'only-add-modify', group: 'data_point', id: tertiarySlotField.id, key: tFieldName, value: tFieldValue })
            } else {
                for (let i = 0; i < params.length; i++) {
                    const paramItem = params[i]
                    const storeParams = { key: paramItem.key, value: paramItem.value, group: 'data_point', tile_id: this.tile_item.id }
                    await this.tile_option_store({ mode: 'only-all-add-start', ...storeParams })
                }
            }
        },

        async local_create_tile_with_config (tile) {
            this.local_set_loading('tile_create', true)
            await this.local_validate_config_modules()
            this.tile_has_errors = await this.local_validate_tile_config()
            if (this.tile_has_errors) return this.local_set_loading('tile_create', false)
            await this.local_save_tile_config(tile)
            this.local_set_loading('tile_create', false)
        },

        local_reset_changes_close_dialog () {
            this.tile_config_changes = []
            this.local_close_tile_dialog()
        },

        async local_go_to_review_view (value = true) {
            if (!value) return this.review_view = false
            await this.local_validate_config_modules()
            this.tile_has_errors = await this.local_validate_tile_config()
            if (this.tile_has_errors) this.local_set_loading('tile_create', false)
            this.review_view = value
        },

        async local_validate_config_modules () {
            this.local_update_summary_changes ('Summary', 'title', this.tile_summary.original.title)
            this.local_update_summary_changes ('Summary', 'description', this.tile_summary.original.description)
            this.local_update_workspace_changes('Workspace', 'workspace_id', this.tile_workspace_id.original.workspace_id, 'validation')
            this.local_update_timeline_changes('Timeline', 'timeline_field', this.tile_timeline.original.timeline_field)
            this.local_update_timeline_changes('Timeline', 'timeline_date_option', this.tile_timeline.original.timeline_date_option)
            this.local_validate_filters()
            if (['Pie', 'Donut', 'Numbers'].includes(this.selected_tile.chart_type)) {
                this.local_update_datapoint_changes('Datapoints', 'primary_slot', this.tile_datapoints.original.primary_slot)
                this.local_update_datapoint_changes('Datapoints', 'secondary_slot', this.tile_datapoints.original.secondary_slot)
            }
            if (!['Pie', 'Donut', 'Numbers'].includes(this.selected_tile.chart_type)) {
                this.local_update_datapoint_changes('Datapoints', 'xaxis', this.tile_datapoints.original.xaxis)
                this.local_update_datapoint_changes('Datapoints', 'y_axis_value', this.tile_datapoints.original.y_axis_value)
                if (this.local_is_multistack_charts) this.local_update_datapoint_changes('Datapoints', 'y_axis_group', this.tile_datapoints.original.y_axis_group)
            }
            if (this.tile_timeline.original.timeline_date_option === 'date_range') {
                this.local_update_timeline_dates('Timeline', 'start_date', this.tile_timeline.original.start_date)
                this.local_update_timeline_dates('Timeline', 'due_date', this.tile_timeline.original.due_date)
            }
        },

        async local_validate_filters () {
            const filters = this.tile_filters.original
            const result = this.tile_filters.original.reduce((prev, current) => {
                prev.push(...current.filters)
                return prev
            }, [])
            .reduce((prev, current) => {
                const { entity_property_id, operator, value, value_alt } = current
                if (this.local_get_data_type(entity_property_id) === 'number') {
                    if (operator !== 'between' && entity_property_id && value) {
                        const error_obj = this.local_check_validation(value, 4)
                        if (error_obj !== true) prev.push(current)
                        else return prev
                    }
                    if (operator === 'between' && entity_property_id && value && value_alt) {
                        const value_1 = this.local_check_validation(value, 4)
                        const value_2 = this.local_check_validation(value_alt, 4)
                        if (value_1 !== true || value_2 !== true) prev.push(current)
                        else return prev
                    }
                    prev.push(current)
                    return prev
                }
                if (operator !== 'between' && entity_property_id && value) {
                    return prev
                }
                if (operator === 'between' && entity_property_id && value && value_alt) {
                    return prev
                }
                prev.push(current)
                return prev
            }, [])

            if (!filters.length || !result.length) {
                this.error_types = this.error_types.filter(item => item.slug && !item.slug.includes('filters'))
                this.tile_has_errors = await this.local_validate_tile_config()
            }

            for (let index = 0; index < result.length; index++) {
                const item = result[index];
                if (!item.entity_property_id) {
                    this.local_set_error('filters_entity_id_' + item.id, { slug: 'filters_entity_id', id: item.id, group_id: item.group_id, message: 'Required Field' })
                    // this.tile_has_errors = await this.local_validate_tile_config()
                }
                if (!item.operator) {
                    this.local_set_error('filters_operator_' + item.id, { slug: 'filters_operator', id: item.id, group_id: item.group_id, message: 'Required Field' })
                    // this.tile_has_errors = await this.local_validate_tile_config()
                }
                if (!item.value && !['empty', 'not-empty'].includes(item.operator)) {
                    const error_obj = this.local_check_validation(item.value, item.operator === 'number' ? 4 : 3)
                    this.local_set_error('filters_value_' + item.id, { slug: 'filters_value', id: item.id, group_id: item.group_id, message: error_obj.message, entity_property_id: item.entity_property_id })
                    // this.tile_has_errors = await this.local_validate_tile_config()
                }
                if (item.operator === 'between' && !item.value_alt) {
                    const error_obj = this.local_check_validation(item.value_alt, item.operator === 'number' ? 4 : 3)
                    this.local_set_error('filters_value_alt_' + item.id, { slug: 'filters_value_alt', id: item.id, group_id: item.group_id, message: error_obj.message })
                    // this.tile_has_errors = await this.local_validate_tile_config()
                }
                if (this.local_get_data_type(item.entity_property_id) === 'number' && !['empty', 'not-empty'].includes(item.operator)) {
                    const error_obj = this.local_check_validation(item.value, 4)
                    if (error_obj !== true) this.local_set_error('filters_value_' + item.id, { slug: 'filters_value', id: item.id, group_id: item.group_id, message: error_obj.message })
                    // this.tile_has_errors = await this.local_validate_tile_config()
                    if (item.operator === 'between') {
                        const error_obj = this.local_check_validation(item.value_alt, 4)
                        if (error_obj !== true) this.local_set_error('filters_value_alt_' + item.id, { slug: 'filters_value_alt', id: item.id, group_id: item.group_id, message: error_obj.message })
                        // this.tile_has_errors = await this.local_validate_tile_config()
                    }
                }
                this.tile_has_errors = await this.local_validate_tile_config()
            }

            this.disabled_fields = false
            if (this.disableObj.length) {
                this.disableObj.forEach(item => {
                    this.error_types.push({slug: 'filters_entity_id_' + item.id, message: { slug: 'filters_entity_id', id: item.id, group_id: item.group_id, message: 'Required Field' }, disabled: true})
                })
                this.tile_has_errors = await this.local_validate_tile_config()
                this.disabled_fields = true
                return
            }
        },

        local_check_deleted_items(tile) {
            this.deletedItems = []
            this.deleted_fields_state = false
            if (this.tile_item.id === tile?.id && this.tile_item.is_filters_deleted === 1) this.deletedItems = JSON.parse(this.tile_item.recent_deleted_filters)
            if (this.deletedItems.length) this.deleted_fields_state = true
        },

        local_clear_deleted_fields() {
            this.deletedItems = []
            this.deleted_fields_state = false
            const params = {
                ...this.tile_summary.original,
                board_id: this.local_board_id,
                id: this.tile_edit_mode,
                is_filters_deleted : 0,
                recent_deleted_filters: null,
            }
            this.tile_update({ mode: 'only-add-modify', ...params })
        },
        // Tile config --- End

        // Extras
        local_get_preview_img (image) {
            return require(`../../assets/images/insights-board/tile-${image}.png`)
        },

        local_currency_formatter (filter, type, returnType = null) {
            const value = type === 'value' ? filter.value.replace(/,/g, '') : filter.value_alt.replace(/,/g, '')
            const formatted = Intl.NumberFormat(this.local_currency_format).format(value)
            if (type === 'value') filter.value = isNaN(value) ? value : ((!formatted || formatted === '0') ? '' : formatted)
            if (type === 'value_alt') filter.value_alt = isNaN(value) ? value : ((!formatted || formatted === '0') ? '' : formatted)
            if (returnType) return filter[type]
        },

        local_get_entity_key (id, field = 'key') {
            if (!id) return ''
            const entity_properties = this.board_entity_list.reduce((prev, current) => prev = [...prev, ...current.entity_properties], [])
            entity_properties.push(...this.local_get_custom_fields_and_tags())
            const item = entity_properties.find(item => item.id === id)
            return item[field]
        },

        async local_validate_options () {
            this.error_options = []
            const dashTitleValidate = new Validate(this.board_item.title, { silent: true }).required().length().run()
            if (dashTitleValidate !== true) this.error_options.push({ index: null, type: 'title', message: dashTitleValidate.message })

            const dashDescValidate = new Validate(this.board_item.description, { silent: true }).length(0, 5000).run()
            if (dashDescValidate !== true) this.error_options.push({ index: null, type: 'description', message: dashDescValidate.message })

            if (this.error_options.length) return false
            return true
        },

        local_has_error (error_object, error_type, error_index = null) {
            const result_index = this.error_options.findIndex(({ index, type }) => {
                if (error_index) return index ? (index === error_index && type === error_type) : (type === error_type)
                return type === error_type
            })
            const status_failed = this.$status(error_object, error_type)
            return result_index !== -1 ? (this.error_options[result_index] ?? status_failed) : false
        },

        local_get_error (error_object, field, index) {
            const { message } = this.local_has_error(error_object, field, index ?? null)
            const error_text = this.$response(error_object, field)
            return message ?? error_text
        },

        local_op_failed_msg (loading_type, message) {
            message = 'Operation failed, board deletion incomplete'
            this.$notify('error', message)
            this.local_set_loading(loading_type, false)
        },

        local_edit_mode (value) {
            this.edit_mode = value
        },

        local_tile_edit_mode (value) {
            this.tile_edit_mode = value
        },

        local_set_loading (type, value) {
            this.$set(this.loading_types, type, value)
        },

        local_check_loading (type) {
            return !!this.loading_types[type]
        },

        local_user_can (permission) {
            return this.$can(`insights_dashboard.${permission}`)
        },

        async local_board_export () {
            this.is_board_export = true
            this.loading_more_actions = true
            const el = this.$refs.insightsBoard.$children[0].$el

            await saveAsPng(el, { filename: this.current_view.title, printDate: true }, {
                backgroundColor: '#f1f1f1',
                width: el.clientWidth + 64,
                height: el.clientHeight + 64,
                style: {
                    padding: '32px',
                    height: '200px'
                }
            })

            this.loading_more_actions = false
            this.is_board_export = false
        },

        async local_refresh_tiles () {
            const tileIds = this.tile_list.map(item => item.id)
            this.tile_update_faux_load(tileIds)
            const tileBatch = this.local_split_tile_list(tileIds, 3)
            if (tileBatch && tileBatch.length === 1) {
                for(let index = 0; index < tileBatch[0].length; index++) {
                    const tile = tileBatch[0][index]
                    this.tile_update_tile_status({ id: tile, status: 'force-loading'})
                    this.local_check_tile_loading()
                }
            } else {
                for(let index = 0; index < tileBatch.length; index++) {
                    setTimeout(() => {
                        for (let child = 0; child < tileBatch[index].length; child++) {
                            const tile = tileBatch[index][child]
                            this.tile_update_tile_status({ id: tile, status: 'force-loading'})
                            this.local_check_tile_loading()
                        }
                    },
                    2000 * index)
                }
            }

        },

        local_split_tile_list (arr, chunkSize) {
            const result = [];
            for (let i = 0; i < arr.length; i += chunkSize) {
                const chunk = arr.slice(i, i + chunkSize);
                result.push(chunk);
            }
            return result;
        },

        local_check_tile_loading () {
            if (this.tile_loading) return
            this.tile_update_faux_load([])

        },

        local_set_tile_chart_type (tile) {
            if (tile && tile.source && !tile.source.chart_type) return
            this.local_chart_type = tile.source.chart_type
        },

        ...mapActions('Widget', {
            widget_index: 'index',
        }),

        ...mapActions('Board', {
            board_index: 'index',
            board_show: 'show',
            board_store: 'store',
            board_update: 'update',
            board_delete: 'destroy',
            board_select: 'select',
            board_clear_item: 'clear_item'
        }),

        ...mapActions('Tile', {
            tile_show: 'show',
            tile_show_stats: 'show_stats',
            tile_update_tile_status: 'update_tile_status',
            tile_clone_store: 'clone_store',
            tile_list_reorder: 'reorder',
            tile_store: 'store',
            tile_update: 'update',
            tile_destroy: 'destroy',
            tile_clear: 'clear',
            tile_update_faux_load: 'update_faux_loader',
            tile_remove_faux_item: 'remove_faux_item'
        }),

        ...mapActions('TileOption', {
            tile_option_store: 'store',
            tile_option_update: 'update',
            tile_option_delete: 'destroy',
        }),

        ...mapActions('TileFilter', {
            tile_filter_store: 'store',
        }),

        ...mapActions('BoardEntity', {
            board_entity_index: 'index',
            board_entity_clear: 'clear',
        }),

        ...mapActions('Customer', {
            customer_index: 'index',
            customer_clear: 'clear',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
        }),

        ...mapActions('Competitor', {
            competitor_index: 'index',
        }),

        ...mapActions('Territory', {
            territory_index: 'index',
        }),

        ...mapActions('Partner', {
            partner_index: 'index',
            partner_clear: 'clear',
        }),

        ...mapActions('Stage', {
            stage_index: 'index',
            stage_clear: 'clear',
        }),

        ...mapActions('Workspace', {
            workspace_board_index: 'board',
            workspace_clear_search_list: 'clear_search_list',
        }),

        ...mapActions('Preference', {
            preference_user_index: 'user_types',
            preference_filters_update : 'update',
        }),

        ...mapActions('FiscalTimeline', {
            fiscal_timeline_show: 'show'
        }),

        ...mapActions('CustomField', {
            custom_field_index: 'index',
            custom_field_clear: 'clear',
        }),

        ...mapActions('TagSection', {
            tag_section_index: 'index',
            tag_section_clear: 'clear',
        }),
    }
}
</script>

<style lang="scss" scoped>
.c-tile-module {
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
    &--active {
        border-color: #eee !important;
        border-left: 1px solid #eee !important;
        border-right: 1px solid #eee !important;
        background: #fafafa !important;
        &::after {
            content: ' ';
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: -1px;
            left: 0px;
            background: #fafafa;
        }
    }
}
</style>

const api_endpoint = '/visibilities'

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        response: [],
        defaults: {
            list: [],
            item: {},
            meta: {},
            response: [],
        },
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint, { params: { ...payload } })
                .then(async (response) => {
                    await context.commit('UPDATE_LIST', response.data.data)
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                resolve('Selected');
            });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                resolve('Cleared');
            });
        },
    },

    getters: {
        get_visibility_item: (state) => (key, value) => {
            return state.list.find(item => item[key] === value)
        }
    }
}

<template functional>
    <span v-if="props.status !== false" :class="data.staticClass" class="md-caption font-weight-medium" v-bind="data.attrs">
        <span v-if="props.status === 'loading'" class="orange--text text--darken-1">{{ props.holdText }}</span>
        <span v-if="props.status === 'error'" class="red--text text--darken-1">{{ props.errorText }}</span>
        <span v-if="props.status === true || props.status === 'done'" class="green--text text--darken-1">{{ props.savedText }}</span>
    </span>
</template>

<script>
export default {
    props: {
        status: {
            type: String | Boolean,
            default: false
        },
        holdText: {
            type: String,
            default: 'Editing..'
        },
        savedText: {
            type: String,
            default: 'Autosaved'
        },
        errorText: {
            type: String,
            default: 'Error'
        },
    }
}
</script>

<template>
    <div class="" :class="{'js-draggable-file-list-item': canUpdate}">
        <div>
            <a-card class="pa-1 px-2 u-elevation-custom-1 u-cursor-pointer">
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="py-2 px-4">
                        <a-layout align-center>
                            <a-flex shrink class="px-0" :class="{'u-opacity-30': !canUpdate}">
                                <a-icon class="grey--text text--lighten-1" :class="{'js-drag-handle': !loading}">drag_indicator</a-icon>
                            </a-flex>
                            <a-flex xs7 @click="$emit('expand')">
                                <h3 class="md-subtitle-1 font-weight-bold primary--text text--darken-2">
                                    {{ milestone.title | truncateText(50) }}
                                    <a-icon size="20" v-if="expanded">arrow_drop_down</a-icon>
                                    <a-icon size="20" v-else>arrow_drop_up</a-icon>
                                </h3>
                            </a-flex>
                            <a-spacer></a-spacer>
                            <a-flex shrink class="px-0 pr-2">
                                <p class="md-caption grey--text text--darken-1 mb-0 text-right" v-if="milestone.start_date !== null && milestone.due_date !== null">
                                    <g-moment class="md-caption" :value="milestone.start_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment> –
                                    <g-moment class="md-caption" :value="milestone.due_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                </p>
                                <p class="md-caption grey--text text--darken-1 mb-0 text-right" v-if="milestone.start_date !== null && milestone.due_date === null">
                                    <g-moment class="md-caption" :value="milestone.start_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment> –
                                    No Due Date
                                </p>
                                <p class="md-caption grey--text text--darken-1 mb-0 text-right" v-if="milestone.start_date === null && milestone.due_date !== null">
                                    Due on <g-moment class="md-caption" :value="milestone.due_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment>
                                </p>
                                <p class="md-caption grey--text text--darken-1 mb-0 text-right" v-if="milestone.start_date === null && milestone.due_date === null">
                                    <a-icon size="16" color="grey" class="mr-1 u-icon-nudge">event</a-icon>
                                    No Due Date
                                </p>
                                <!-- <p class="md-caption grey--text text--darken-1 mb-0 text-right" v-if="milestone.start_date !== null && milestone.due_date !== null"><a-icon size="16" color="grey" class="mr-1 u-icon-nudge">event</a-icon><g-moment class="md-caption" :value="milestone.start_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment> – <g-moment class="md-caption" :value="milestone.due_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment></p>
                                <p class="md-caption grey--text text--darken-1 mb-0 text-right" v-if="milestone.start_date !== null && milestone.due_date === null"><a-icon size="16" color="grey" class="mr-1 u-icon-nudge">event</a-icon><g-moment class="md-caption" :value="milestone.start_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment> – No Due Date</p>
                                <p class="md-caption grey--text text--darken-1 mb-0 text-right" v-if="milestone.start_date === null && milestone.due_date !== null"><a-icon size="16" color="grey" class="mr-1 u-icon-nudge">event</a-icon>Due on <g-moment class="md-caption" :value="milestone.due_date" input-format="YYYY-MM-DD" output-format="MMM DD, YYYY"></g-moment></p>
                                <p class="md-caption grey--text text--darken-1 mb-0 text-right" v-if="milestone.start_date === null && milestone.due_date === null"><a-icon size="16" color="grey" class="mr-1 u-icon-nudge">event</a-icon>No Due Date</p> -->
                            </a-flex>
                            <a-flex shrink pr-1>
                                <a-progress-circular color="green" :rotate="-90" size="38" width="2" :value="milestone.external_completion_percentage" class="u-rounded-corners u-overflow-hidden">
                                    <span class="md-caption grey--text text--darken-2 font-weight-medium">{{ milestone.external_completion_percentage }}%</span>
                                </a-progress-circular>
                            </a-flex>
                            <a-flex shrink pr-2>
                                <slot name="milestone-assignees"></slot>
                            </a-flex>
                            <a-flex shrink class="pl-2 pr-0" v-if="canUpdate || canDelete">
                                <a-menu offset-y transition="slide-y-transition">
                                    <template v-slot:activator="{ on }">
                                        <a-icon v-on="on" size="20" color="grey darken-2">more_vert</a-icon>
                                    </template>
                                    <a-list class="u-list-condensed">
                                        <a-list-item @click="$emit('edit', milestone.id)" v-if="canUpdate">
                                            <a-list-item-content>
                                                <a-list-item-title>Edit Milestone</a-list-item-title>
                                            </a-list-item-content>
                                        </a-list-item>
                                        <a-list-item @click="local_delete_milestone(milestone)" v-if="canDelete">
                                            <a-list-item-content>
                                                <a-list-item-title class="red--text text--darken-1">Delete Milestone</a-list-item-title>
                                            </a-list-item-content>
                                        </a-list-item>
                                    </a-list>
                                </a-menu>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </div>
        <div v-if="!expanded">
            <slot name="tasks"></slot>
            <slot name="add-task"></slot>
        </div>

        <a-dialog max-width="480" v-model="dialog_milestone_delete" persistent>
            <a-card class="pa-6 pb-7 px-8">
                <h2 class="md-heading-5 grey--text text--darken-3">Delete {{ milestone_to_delete ? milestone_to_delete.title : '' | truncateText(25) }}</h2>
                <div class="mt-2 mb-5">
                    <p class="md-subtitle-1 grey--text text--darken-3 mb-1">Are you sure to delete this milestone?</p>
                    <p class="md-subtitle-1 grey--text text--darken-1 mb-0">All tasks under the milestone will be deleted along with milestone.</p>
                </div>
                <div>
                    <a-btn small class="ma-0 px-3 elevation-0 red lighten-4 red--text text--darken-2" @click="$emit('delete', milestone.id)" :loading="loading">Delete Milestone</a-btn>
                    <a-btn small color="grey" class="ma-0 ml-4 grey lighten-4" text @click="dialog_milestone_delete = !dialog_milestone_delete">Cancel</a-btn>
                </div>
            </a-card>
        </a-dialog>
    </div>
</template>

<script>
export default {
    props: {
        milestone: {
            type: Object,
            required: true
        },
        milestoneIndex: {
            type: String | Number,
        },
        expanded: {
            type: Boolean
        },
        loading: {
            type: Boolean
        },
        canUpdate: {
            type: Boolean
        },
        canDelete: {
            type: Boolean
        },
    },

    data () {
        return {
            milestone_to_delete: null,
            dialog_milestone_delete: false
        }
    },

    methods: {
        local_delete_milestone (milestone) {
            this.milestone_to_delete = milestone
            this.dialog_milestone_delete = true
        },
    }
}
</script>

<style>

</style>

import store from '../models'

async function guardAdmin(to, from, next) {
    if (!store.state.User.self.id) {
        await store.dispatch('User/me')
    }

    const { is_admin, scope } = store.state.User.self
    if (scope === 'internal') {
        return is_admin === 1 ? next() : next('/errors/unauthorized')
    } else {
        next('/page-not-found')
    }
}

export default guardAdmin

<template>
    <div
        class="px-8 py-8 c-upgrade-gradient white u-rounded-corners-lg u-relative"
        style="background-image: linear-gradient(#e8eaf6 30%, #c5cae900 50%)"
    >
        <a-btn small depressed icon class="u-absolute u-cursor-pointer" @click="$emit('close')" style="top: 18px; right: 18px;">
            <a-icon size="18" color="secondary" class="u-opacity-70">cancel</a-icon>
        </a-btn>
        <slot name="plan-info">
            <h3 class="mx-auto mb-6 text-center md-heading-6 grey--text text--darken-3 font-weight-medium">
                <slot name="plan-info-text">
                    Your current <span class="secondary--text font-weight-bold">Starter</span> plan doesn’t support this action.
                </slot>
            </h3>
        </slot>
        <div
            class="px-4 pt-4 mx-auto u-flex flex-column align-center white u-rounded-corners-lg"
            style="max-width: 500px; padding-bottom: 112px; box-shadow: 0px -4px 8px rgb(57 73 171 / 8%);"
        >
            <slot name="title">
                <h2 class="mb-4 md-heading-6 font-weight-medium grey--text text--darken-4">
                    Upgrade your plan
                </h2>
            </slot>
            <img
                src="../../assets/images/admin-upgrade-banner.png"
                alt="Admin Upgrade Banner"
                style="object-fit: contain; width: 120px; height: 100%;"
            >
        </div>

        <div class="mx-auto" style="margin-top: -100px; max-width: 468px;" v-if="localPlanList && localPlanList.length">
            <div
                v-for="(plan, index) in localPlanList"
                :key="plan.level"
                :class="[{ 'mt-5': index !== 0 }, { 'u-opacity-60': (localPlanList && localPlanList.length > 1) && selectedPlanLevel !== plan.level }]"
                :style="[{
                    border: selectedPlanLevel === plan.level ? '1px solid #3949AB !important' : '1px solid #d9d8d8 !important',
                    'transition': selectedPlanLevel === plan.level ? '200ms box-shadow ease-out, 200ms opacity ease-out' : 'none'
                }]"
                @click="selectedPlanLevel = plan.level"
                class="pt-4 pb-5 u-border pa-3 u-rounded-corners-lg u-cursor-pointer u-no-select"
            >
                <div class="u-rounded-corners-lg white">
                    <h3 class="text-center md-subtitle-1 font-weight-bold grey--text text--darken-3">{{ plan.label }}</h3>
                    <h5 class="mt-3 text-center md-body-2 font-weight-medium grey--text text--darken-1">USD $990 includes first 10 Active POCs.</h5>
                    <h5 class="mt-1 text-center md-body-2 font-weight-medium grey--text text--darken-1">From 11th project onwards USD $99 / POC / month</h5>
                    <h5 class="mt-3 text-center md-body-2 font-weight-medium grey--text text--darken-4">$990 + $99 / POC / month</h5>
                </div>
            </div>
            <div class="mt-5 text-center">
                <router-link :to="{ name: 'settings-billing-plans' }" tag="a" class="d-inline-flex align-center blue--text md-subtitle-1 font-weight-medium">
                    <span>See pricing & feature comparison</span>
                    <a-icon size="18" color="blue" class="ml-1">launch</a-icon>
                </router-link>
                <a-btn class="px-4 py-2 mt-5 primary u-wfull" height="52" text medium @click="localUpgrade()">
                    <span class="md-heading-6 font-weight-medium white--text">Upgrade to Professional</span>
                </a-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
    },

    data () {
        return {
            selectedPlanLevel: 2,
        }
    },

    watch: {
        isOpen: {
            handler (val) {
                if (!val) this.selectedPlanLevel = 2
            },
            immediate: true
        }
    },

    computed: {
        localCurrencySymbol () {
            return this.user_self && this.user_self.organization && this.user_self.organization.currency && this.user_self.organization.currency.symbol
        },

        localPlanList () {
            const plans = [
                { level: 2, label: 'Professional', description: 'Full access for up to 30 active projects', cost: 99 },
            ]

            if (plans.length) this.selectedPlanLevel = plans[0].level
            return plans
        },

        ...mapState('User', {
            user_self: 'self'
        })
    },

    methods: {
        localUpgrade () {
            const url = `${process.env.VUE_APP_CORE_API_URL}/${window.location.hostname.split('.')[0]}/internal/subscription-plans/2?redirect_url=${window.location.href}`
            window.location.href = url
        },
    },
}
</script>

<style lang="scss" scoped></style>

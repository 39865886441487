import Vue from 'vue'

Vue.directive('testLink', {
    inserted: function (el, binding, vnode) {
        const type = 'link'
        const { arg, value } = binding
        const modifiers = Object.keys(binding.modifiers)[0]

        el.dataset['testElement'] = type + (modifiers ? '-' + modifiers : '') + (arg ? '-' + arg : '') + (value ? '-' + value : '') // link-competitors
    }
})

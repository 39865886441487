<template>
    <a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="px-0 mb-4 pb-4">
            <g-page-header
                title="Tokens"
                subtitle="Manage settings to use with Success API."
                :breadcrumb-items="breadcrumb_items"
                icon="extension"
            >
                <template v-slot:action>
                    <a-btn small class="u-rounded-corners pl-2 py-4 elevation-0 pr-3 ma-0 u-elevation-custom-1" @click="token_store_dialog = true" color="primary">
                        <a-icon size="18" class="mr-1">add</a-icon>
                        Generate New Token
                    </a-btn>
                </template>
            </g-page-header>
            <tab-token></tab-token>
            <a-layout wrap align-start>
                <a-flex>
                    <template v-if="local_loading">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1">Data Loading...</h3>
                    </template>
                    <template v-if="!local_loading">
                        <a-card v-if="local_unrevoked_tokens.length" class="u-elevation-custom-1 u-rounded-corners mb-4">
                            <a-card class="u-rounded-corners elevation-0" v-if="local_unrevoked_tokens.length">
                                <template v-for="(token, index) in local_unrevoked_tokens">
                                    <a-card class="d-flex align-center elevation-0 px-4 py-3" :key="index">
                                        <div><h2 class="grey--text text--darken-3 subtitle-1 mr-4 d-inline-block">{{ token.name }}</h2></div>
                                        <a-spacer></a-spacer>
                                        <span class="grey--text text--darken-1 caption d-block text-xs-right font-italic font-weight-medium">
                                            <g-moment v-if="token.last_used_at" :value="token.last_used_at" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                            <span v-else>Never Used</span>
                                            <a-menu offset-y left class="u-rounded-corners" content-class="u-elevation-custom-1 u-border" max-width="250">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn v-on="on" small text icon class="red--text text--lighten-1 ml-2"><a-icon size="16">delete</a-icon></a-btn>
                                                </template>
                                                <a-card class="u-rounded-corners pa-3">
                                                    <h2 class="grey--text text--darken-2 body-2 mb-2">Are you sure to delete the token? This cannot be undone.</h2>
                                                    <a-btn dark small text @click="local_token_destroy(token.id)" class="red lighten-1 ml-0 u-rounded-corners">Delete</a-btn>
                                                </a-card>
                                            </a-menu>
                                        </span>
                                    </a-card>
                                    <a-divider class="grey lighten-2" :key="'divider' + index"></a-divider>
                                </template>
                            </a-card>
                        </a-card>
                        <h3 v-else class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1">No Tokens Found</h3>
                    </template>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog v-model="token_store_dialog" max-width="800" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form key="form" v-if="!token_show" @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="px-6 py-4">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>extension</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">Create API Token</h2>
                                    <p class="md-subtitle-2 mb-0">Add new API token to access Success API</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                    <a-text-field
                                        v-model="access_token_name"
                                        placeholder="Enter a name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="token_response && token_response.server && token_response.server.errors && token_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ token_response.server.errors.name[0] }}
                                    </span>
                                    <!-- <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="error_bag">
                                        <a-icon size="16" color="red darken-2">info</a-icon>
                                        {{ error_bag }}
                                    </span> -->
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the token.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_save_token()" :loading="loading" :disabled="loading">Generate Token</a-btn>
                                    <a-btn class="elevation-0 px-3 ma-0 ml-3" color="grey" text @click="local_close_token_dialog()" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                    <div key="token" v-if="token_show">
                        <a-card flat class="u-cursor-pointer u-rounded-corners pa-6">
                            <div class="u-flex align-center mb-3">
                                <h2 class="title grey--text text--darken-3 font-weight-medium">Make sure to copy the access token now. It won't be accessible again!</h2>
                                <a-spacer></a-spacer>
                                <a-btn class="elevation-0 ma-0 ml-2" icon small color="grey" text @click="local_close_token_dialog()" :disabled ="loading"><a-icon size="16" color="grey darken-2">cancel</a-icon></a-btn>
                            </div>
                            <div class="u-flex align-start justify-space-between grey lighten-3 pa-3 u-rounded-corners" style="border: 1px solid #888">
                                <div class="grey--text text--darken-2 body-2 mr-4" style="word-break: break-all;" ref="text">
                                    {{ token_show.api_token }}
                                </div>
                                <div class="u-cursor-pointer blue--text text--darken-1 body-2 d-inline-flex align-center" v-clipboard:copy="token_show.api_token" v-clipboard:success="local_copy_token">
                                    <a-icon color="blue darken-1" class="mb-1" size="16">content_copy</a-icon>
                                    <span>{{ copy_confirm ? 'Copied' : 'Copy'}}</span>
                                </div>
                            </div>
                        </a-card>
                    </div>
                </a-card-text>
            </a-card>
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { TabToken } from '@/config/config-tab-components'

export default {
    components: {
        TabToken
    },
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Developer Settings',
                    to: '/tokens',
                    exact: true,
                }
            ],
            copy_confirm: false,
            token_store_dialog : false,
            access_token_name : null,
            token_show: false,
            error_bag: null,
            local_loading: false,
            token_filter: {},
            FILTERS: {
                SORT: 'sort',
                PAGE: 'page',
                INCLUDE: 'include',
                COUNT: 'count',
                USER: 'filter[user_id]',
                BEARER: 'filter[type]',
            },
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        local_unrevoked_tokens() {
            return this.token_list ?? []
        },
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapGetters('Token', {
            token_list: 'list',
            token_item: 'item',
            token_response: 'response'
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            this.local_loading = true
            await this.token_clear_item()
            this.token_show = null
            this.token_filter[this.FILTERS.PAGE] = 1
            this.token_filter[this.FILTERS.COUNT] = 9999
            this.token_filter[this.FILTERS.SORT] = 'created_at'
            this.token_filter[this.FILTERS.SORT] = '-created_at'
            this.token_filter[this.FILTERS.USER] = null
            this.token_filter[this.FILTERS.BEARER] = 'bearer'
            await this.token_index({...this.token_filter})
            this.local_loading = false
        },
        async local_save_token() {
            // if (!this.access_token_name) {
            //     return this.error_bag = 'Token name is required'
            // }
            this.token_show = null
            await this.token_store({ name: this.access_token_name })
            if (this.token_response.status === 'success') {
                this.token_show = this.token_item
                this.access_token_name = null
            }
        },
        local_token_destroy(id) {
            this.token_show = null
            this.token_destroy({ id })
        },
        local_close_token_dialog() {
            this.token_store_dialog = false
            this.error_bag = null
            this.access_token_name = null
            this.token_clear_item()
            setTimeout(() => {
                this.token_show = null
                this.copy_confirm = false
            }, 300);
        },
        local_copy_token(item) {
            this.copy_confirm = true
        },
        ...mapActions('Token', {
            token_index: 'index',
            token_store: 'store',
            token_destroy: 'destroy',
            token_clear_item: 'clear_item',
        }),
    },
}
</script>

<style scoped lang="scss">
    /* .c-link {
        color: #5d5d5d !important;
    }
    .c-current-route {
        color: #d64b4b !important;
    } */
    .c-link {
        color: #5d5d5d !important;
        .a-icon {
            color: #8f8f8f !important;
            // color: #bfbfbf !important;
            // visibility: hidden !important;
        }
    }
    .c-current-route {
        color: #0e0d0d !important;
        .a-icon {
            color: #ce0707 !important;
        }
    }
    .c-menu {
        height: 100%;
        /* background: #e0e0e0; */
        padding: 0 24px;
    }
</style>

let foldersList = []

export const findParentFolders = (folder, list) => {
    const parent = list.find(i => i.gong_folder_id === folder.gong_parent_folder_id)
    if (parent) {
        const index = foldersList.findIndex(i => i.id === parent.id)
        if (index === -1) foldersList.push(parent)
    }

    if (parent && parent.gong_parent_folder_id) {
        findParentFolders(parent, list)
    }

    return { list: foldersList, clearList: () => foldersList = [] }
}

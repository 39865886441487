<template>
    <a-card>
        <div class="py-5 u-flex-center-y pa-6" style="column-gap: 8px;">
            <a-icon size="24" color="indigo darken-2" outlined>folder</a-icon>
            <h2 class="indigo--text text--darken-2 md-subtitle-1 font-weight-bold">Choose Folder...</h2>
        </div>
        <a-divider></a-divider>
        <div ref="refScrollContainer" class="c-folder-view u-flex u-overflow-y" style="overflow-x: scroll;">
            <div
                class="u-relative"
                :style="[{ borderRight: '1px solid #eee' }]"
            >
                <h3 class="px-6 pb-1 md-body-2 u-absolute grey--text text--darken-3 font-weight-bold text-truncate" style="top: 12px;">
                    {{ workspace.name }}
                </h3>
                <a-sheet min-height="400" max-height="400" width="360" class="mt-10 u-overflow-y u-flex flex-column">
                    <PartialGongFolderList
                        v-for="(parentFolder) in parentFolders"
                        :key="parentFolder.id"
                        :folder="parentFolder"
                        :isFolderOpen="localIsFolderOpen(parentFolder.id)"
                        :style="[{ borderBottom: '1px solid #f0f0f0' }]"
                        @navigate="localNavigateFolder($event, { scrollIndex: 1 })"
                    />
                            <!-- index !== 0 ? { borderTop: '1px solid #f0f0f0' } : {},
                            parentFolders.length === 1 ? { borderBottom: '1px solid #f0f0f0' } : {}, -->
                </a-sheet>
            </div>
            <div
                v-for="(subFolder, index) in currentFolderIds" :key="subFolder.id"
                class="u-relative"
                :style="[(currentFolderIds.length - 1 !== index) || (index === 0) ? { borderRight: '1px solid #eee' } : {}]"
            >
                <h3 class="px-6 pb-1 md-body-2 u-absolute grey--text text--darken-3 font-weight-bold text-truncate" style="top: 12px;">
                    {{ localGetParent(subFolder) || 'Folder' }}
                </h3>
                <a-sheet min-height="400" max-height="400" width="360" class="mt-10 u-overflow-y u-flex flex-column">
                    <template v-if="!subFolder.isEmpty">
                        <PartialGongFolderList
                            v-for="(folder, index) in localGetSubFolders(subFolder.id)"
                            :key="folder.id"
                            :folder="folder"
                            :isFolderOpen="localIsFolderOpen(folder.id)"
                            :style="[{ borderBottom: '1px solid #f0f0f0' }]"
                            @navigate="localNavigateFolder($event, { scrollIndex: index + 2 })"
                        />
                                <!-- index !== 0 ? { borderTop: '1px solid #f0f0f0' } : {},
                                localGetSubFolders(subFolder.id, 'length') === 1 ? { borderBottom: '1px solid #f0f0f0' } : {}, -->
                    </template>
                    <h3 v-else class="px-6 py-2 md-body-1 font-weight-medium grey--text">No Sub Folders</h3>
                </a-sheet>
            </div>
        </div>
        <a-divider></a-divider>
        <div class="py-5 u-flex-center-y pa-6">
            <a-btn dark text depressed class="px-4 py-1 mr-3 indigo darken-3" @click="localSaveSelection()">Add Folder</a-btn>
            <a-btn text depressed class="px-3 py-1 grey--text text--darken-1 grey lighten-3" @click="localCloseDialog()">Cancel</a-btn>
        </div>
    </a-card>
</template>

<script>
import { mapState } from 'vuex'
import PartialGongFolderList from '../Partials/PartialGongFolderList.vue'

export default {
    components: { PartialGongFolderList },

    props: {
        workspace: {
            type: Object
        },
        isOpen: {
            type: Boolean
        }
    },

    data () {
        return {
            currentIndex: 0,
            parentFolders: [],
            currentFolderIds: [],
        }
    },

    watch: {
        isOpen: {
            handler (newValue) {
                if (newValue) this.localIndex()
            },
            immediate: true
        }
    },

    computed: {
        ...mapState('Gong', {
            gong_folders: 'folders'
        }),
    },

    methods: {
        localIndex () {
            this.parentFolders = this.gong_folders.filter(i => i.gong_workspace_id === this.workspace.gong_workspace_id && !i.gong_parent_folder_id)
        },

        localNavigateFolder (folder, { scrollIndex }) {
            this.currentIndex = scrollIndex
            const siblingIndex = this.currentFolderIds.findIndex(i => i.parent && i.parent === folder.gong_parent_folder_id)
            const hasChildren = this.gong_folders.some(i => i.gong_parent_folder_id === folder.gong_folder_id)

            if (siblingIndex !== -1) this.currentFolderIds.splice(siblingIndex)
            if (!hasChildren && folder.gong_parent_folder_id) {
                this.localAddFolderId(folder, { isEmpty: true })
                return this.localScrollContainer()
            }
            if (_.size(this.currentFolderIds) && !folder.gong_parent_folder_id) this.currentFolderIds = []

            const lastFolder = this.currentFolderIds.slice(-1)
            if (lastFolder.parent === folder.gong_parent_folder_id) this.currentFolderIds.splice(-1, 1)

            this.localAddFolderId(folder)
            this.localScrollContainer()
        },

        localAddFolderId (folder, { isEmpty = null } = {}) {
            const params = { id: folder.id, name: folder.name, parent: folder.gong_parent_folder_id, folder_id: folder.gong_folder_id }
            if (isEmpty) params['isEmpty'] = true

            this.currentFolderIds.push(params)
        },

        localScrollContainer () {
            if (_.size(this.currentFolderIds) < 2) return

            setTimeout(() => this.$refs.refScrollContainer.scrollLeft = (_.size(this.currentFolderIds) + 2) * 360, 0)
        },

        localGetFolder (folderId, key) {
            const folder = this.gong_folders.find(i => i.id === folderId)
            return folder[key] ?? ''
        },

        localGetSubFolders (folderId, field = null) {
            const folder = this.gong_folders.find(i => i.id === folderId)
            const list = this.gong_folders.filter(f => f.gong_parent_folder_id === folder.gong_folder_id)
            if (!field) return _.orderBy(list, 'name', 'asc')
            if (field === 'length') return list.length
        },

        localIsFolderOpen (id) {
            const index = this.currentFolderIds.filter(Boolean).findIndex(i => i.id === id)
            return index !== -1
        },

        localGetParent (subFolder, slug = 'name') {
            const folder = this.gong_folders.find(i => i.id === subFolder.id)
            return folder[slug] || ''
        },

        localSaveSelection () {
            this.$emit('save', this.currentFolderIds)
            this.localCloseDialog()
        },

        localCloseDialog () {
            this.currentFolderIds = []
            this.parentFolders = []
            this.$emit('close')
        }
    }
}
</script>

<style>

</style>

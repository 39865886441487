<template>
    <div>
        <a-container container--fluid grid-list-xl pa-6>
            <p-external-note-toolbar
                :project-notes-list="note_list_project"
                :customer-notes-list="note_list_customer"
                :can-create="permission_note('store')"
                :all-expanded="accordion_notes_all"
                @toggle-all="local_accordion_note_toggle_all()"
                @refresh="local_index()"
                @store="local_note_create('Project', project_item.id)"
            ></p-external-note-toolbar>

            <a-layout wrap align-start v-if="local_loading">
                <a-flex xs12>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Notes..</h3>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <template v-if="!local_loading">
                <a-layout wrap align-start v-if="note_list_project && note_list_project.length === 0 && note_list_customer && note_list_customer.length === 0">
                    <a-flex xs12>
                        <a-responsive class="my-4">
                            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                            <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
                        </a-responsive>
                    </a-flex>
                </a-layout>
                <a-layout wrap align-start v-if="note_list_project && note_list_project.length > 0">
                    <a-flex xs12>
                        <h4 class="md-subtitle-1 mb-3 grey--text text--darken-1" v-if="user_self">Project Notes ({{ note_list_project.length }})</h4>
                        <draggable v-model="note_list_project" :options="{handle: '.js-drag-handle'}" @end="local_reorder(note_list_project)" draggable=".js-draggable-file-list-item">
                            <template v-for="(note, index) in note_list_project">
                                <p-external-note-card
                                    :note="note"
                                    :can-update="permission_note('update')"
                                    :expanded="local_accordion_note_status('Project', index)"
                                    :note-view-path="{name: 'projects-notes-view', params: {note_id: note.id}}"
                                    @expand="local_accordion_note_toggle('Project', index)"
                                    :key="note.id"
                                ></p-external-note-card>
                            </template>
                        </draggable>
                    </a-flex>
                </a-layout>
                <a-layout wrap align-start v-if="note_list_customer.length > 0">
                    <a-flex xs12>
                        <h4 class="md-subtitle-1 mb-3 grey--text text--darken-1">Account Notes ({{ note_list_customer.length }})</h4>
                        <draggable v-model="note_list_customer" :options="{handle: '.js-drag-handle'}" @end="local_reorder(note_list_customer)" draggable=".js-draggable-file-list-item">
                        <template v-for="(note, index) in note_list_customer">
                            <p-external-note-card
                                :note="note"
                                :can-update="permission_note('update')"
                                :expanded="local_accordion_note_status('Project', index)"
                                :note-view-path="{name: 'projects-notes-view', params: {note_id: note.id}}"
                                @expand="local_accordion_note_toggle('Project', index)"
                                :key="note.id"
                            ></p-external-note-card>
                        </template>
                        </draggable>
                    </a-flex>
                </a-layout>
            </template>
        </a-container>

        <a-dialog max-width="800" v-model="dialog_note_form" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="40">
                                    <a-icon dark>notes</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex v-if="note_parent_type === 'Project'">
                                <h2 class="md-heading-6 primary--text text--darken-1 mb-1">Project Note</h2>
                                <p class="md-subtitle-2 mb-0">You can add new note to the project</p>
                            </a-flex>
                            <a-flex v-if="note_parent_type === 'Customer'">
                                <h2 class="md-heading-6 primary--text text--darken-1 mb-1">Account Note</h2>
                                <p class="md-subtitle-2 mb-0">You can add new note to the account</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6 pb-4">
                        <a-layout wrap align-start>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Note Title</label>
                                <a-text-field
                                    v-model="note_item.title"
                                    placeholder="Enter new note title"
                                    background-color="neutral"
                                    solo flat hide-details
                                >
                                </a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="note_response.server && note_response.server.errors && note_response.server.errors.title">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ note_response.server.errors.title[0] }}
                                </span>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink>
                                <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_note_store()" :loading="loading" :disabled="loading">Save</a-btn>
                                <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_note_form_close()" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-snackbar v-model="snackbar_status" :timeout="5000" bottom center>
            Note Imported Successfully!
        </a-snackbar>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import marked from 'marked'
import { PExternalNoteCard, PExternalNoteToolbar } from '../_Partials'

export default {
    components: { PExternalNoteCard, PExternalNoteToolbar },
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            export_mode: 'new',
            dialog_note_form: false,
            dialog_import_form: false,
            dialog_export_form: false,
            dialog_upload_form: false,
            template_groups_selected: [],
            accordion_notes: {
                'Project': [],
                'Customer': [],
            },
            accordion_notes_all: false,
            snackbar_status: false,
            note_list_project: [],
            note_list_customer: [],
            local_note_list: [],
            note_parent_type: '',
            note_parent_id: '',
            local_template_note_search: null,
            local_template_note_id: '',
            local_loading: true,
            local_template_fields: { 'include': 'templateGroup','sort': 'title'},
            local_note_fields: { 'include': 'updatedBy,type,attachmentsCount', 'fields[type]': 'id,type,value', 'sort': 'order'},
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('NoteExternal', {
            note_list: 'list',
            note_item: 'item',
            note_filters: 'filters',
            note_response: 'response',
            note_form_mode: 'form_mode',
        }),
        ...mapState('TemplateGroup', {
            template_group_list: 'list',
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),
        ...mapState('TemplateNote', {
            template_note_list: 'list',
            template_note_item: 'item',
            template_note_response: 'response',
            template_note_filters: 'filters',
        }),
        ...mapState('ProjectExternal', {
            project_item: 'item',
            project_related_item: 'related_item'
        }),
        ...mapState('CommentExternal', {
            comment_item: 'item',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('notes.index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.note_clear()

            await this.project_show({id: this.$route.params.id})
         //   this.local_fetch('Project', this.$route.params.id)
            await this.local_fetch(this.$route.params.id, this.project_item.customer_id)
            this.local_loading = false
        },
        async local_fetch(project_id, customer_id) {
            await this.note_index({ ...this.local_note_fields, 'filter[parent_id]': project_id })
            this.note_list_project = _.cloneDeep(this.note_list)
            await this.note_clear()

            await this.note_index({ ...this.local_note_fields, 'filter[parent_id]': customer_id, })
            this.note_list_customer = _.cloneDeep(this.note_list)
            await this.note_clear()
        },
        async local_select_template_note(id) {
            if(!id) {
                await this.note_clear_item()
                return
            }
            await this.template_note_select({id: id})
            const template_note_item = { ...this.template_note_item }
            this.note_item.title = template_note_item.title
            this.note_item.content = template_note_item.description
            this.note_item.type_id = template_note_item.type_id
            await this.template_note_clear_item()
        },
        async local_note_create(parent_type, parent_id) {
            await this.note_clear_item()
            this.note_parent_type = parent_type
            this.note_parent_id = parent_id
            this.local_template_note_id = ''
            this.dialog_note_form = true
        },
        async local_note_form_close() {
            await this.note_clear_item()
            this.local_template_note_id = ''
            this.dialog_note_form = false
        },
        async local_note_store() {
            this.note_item.parent_type = this.note_parent_type
            this.note_item.parent_id = this.note_parent_id
            if(!this.note_item.content) this.note_item.content = ' '
            this.note_item.order = -1
            this.note_item.list_addition = false
            this.note_item.parent_id = this.note_parent_id
            //if(this.user_self.scope === 'external') this.note_item.is_client_visible = 1

            await this.note_store(this.note_item)
            if (this.note_response.status === 'success') {
                this.$router.push({name: 'projects-notes-view', params: {note_id: this.note_item.id} })
            }
        },
        async local_update() {
            await this.note_update()
            await this.note_clear_item()
        },
        async local_assign(id, user_id) {
            await this.note_select({id: id});
            this.note_item.assignee_id = user_id
            await this.note_update()
            await this.note_clear_item()
        },
        async local_reorder(list) {
            let compact_list = list.map(item => {
                return {id: item.id}
            })
            await this.note_reorder({list: compact_list})
        },
        local_due_date_passed(date, status) {
            return moment(date).isBefore() && status == 'Closed'
        },
        local_get_initials(name) {
            let initials_array = _.split(name, ' ', 2)
            let initial_first = _.truncate(initials_array[0], {length: 1, omission: ''})
            let initial_second = _.truncate(initials_array[1], {length: 1, omission: ''})
            return initial_first + initial_second
        },
        local_accordion_note_status(list, index) {
            if (this.accordion_notes[list][index]) {
                return this.accordion_notes[list][index]
            } else {
                this.accordion_notes[list][index] = false
                return this.accordion_notes[list][index]
            }
        },
        local_accordion_note_toggle(list, index) {
            if (this.accordion_notes[list][index] === undefined || this.accordion_notes[list][index] === null) {
                this.$set(this.accordion_notes[list], index, true);
            } else {
                this.$set(this.accordion_notes[list], index, !this.accordion_notes[list][index]);
            }
        },
        local_accordion_note_toggle_all() {
            this.accordion_notes_all = !this.accordion_notes_all
            this.accordion_notes['Project'] = this.accordion_notes['Project'].map(x => this.accordion_notes_all)
            this.accordion_notes['Customer'] = this.accordion_notes['Customer'].map(x => this.accordion_notes_all)
        },
        local_bridge_value(note, service, key) {
            if (note.bridges) {
                const result = note.bridges.find(item => item.service === service && item.key === key)
                return (result && result.value) ?? ''
            }
        },
        permission_note(type) {
            return this.$can(`notes.${type}`) && this.$can('projects.update-only')
        },
        local_converted_desc(description) {
            return marked(description, { sanitize: true })
        },
        ...mapActions('NoteExternal', {
            note_index: 'index',
            note_show: 'show',
            note_store: 'store',
            note_template_store: 'template_store',
            note_update: 'update',
            note_reorder: 'reorder',
            note_template_import: 'template_import',
            note_template_account_import: 'template_account_import',
            note_select: 'select',
            note_clear: 'clear',
            note_clear_item: 'clear_item',
        }),
        ...mapActions('ProjectExternal', {
            project_show: 'show',
            project_customer: 'customer',
        }),
        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_store: 'store',
            template_group_clear: 'clear',
        }),
        ...mapActions('TemplateNote', {
            template_note_index: 'index',
            template_note_select: 'select',
            template_note_export: 'template_export',
            template_note_clear: 'clear',
            template_note_clear_item: 'clear_item',
        }),
        ...mapActions('CommentExternal', {
            comment_store: 'store',
            comment_clear_item: 'clear_item',
        }),
    },
}
</script>

import Vue from 'vue'
import VCalendar from 'v-calendar'
import VueClipboard from 'vue-clipboard2'
import Draggable from 'vuedraggable'

import App from './App.vue'
import router from '@/router'
import store from '@/models'

import Echo from '@/plugins/plugin-echo'
import Notification from '@/plugins/plugin-notification'
import PlanAuthorizer from '@/plugins/plugin-plan-authorizer'
import PlanApplet from '@/plugins/plugin-plan-applet'
import Authorizer from '@/plugins/plugin-authorizer'
import CheckPresentationView from '@/plugins/plugin-check-presentation-view'
import ResponseParser from '@/plugins/plugin-response-parser'
import Color from '@/plugins/plugin-color-fetcher'
import Notify from '@/plugins/plugin-notify'
import Automation from '@/plugins/plugin-automation'
import IdAppender from '@/plugins/plugin-id-appender'
import vuetify from '@/config/config-vuetify'

import '@/directives/directive-outside-click'
import '@/directives/directive-test-btn'
import '@/directives/directive-test-input'
import '@/directives/directive-test-nav'
import '@/directives/directive-test-label'
import '@/directives/directive-test-record-id'

import '@/config/config-echo'
import '@/config/config-alpha-components'
import '@/config/config-global-components'
import '@/config/config-bootstrap'
import '@/config/config-loader-components'

import '@/filters/filter-file-sizer'
import '@/filters/filter-truncate-text'
import '@/assets/stylesheets/app.scss'

Vue.use(Echo, { store })
Vue.use(Notification, { store })
Vue.use(PlanAuthorizer, { store })
Vue.use(PlanApplet, { store })
Vue.use(Authorizer, { store })
Vue.use(CheckPresentationView, { store })
Vue.use(ResponseParser)
Vue.use(Color)
Vue.use(VCalendar, { componentPrefix: 'vc' })
Vue.use(Notify)
Vue.use(Automation)
Vue.use(IdAppender)
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard)
Vue.component('draggable', Draggable);

// Disable development environment tip
Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')

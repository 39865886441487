import Vue from 'vue'
import { Base64 } from 'js-base64';
const api_endpoint = '/usecases';
export default {
    namespaced: true,
    state: {
        list: [],
        old_list: [],
        item: {},
        import_list: [],
        meta: {},
        links: {},
        filters: {
            title: null,
            status: 'all',
            assignee: 'all',
            importance: 'all',
            size: 'all',
            is_active: 1,
            project_id: null,
            page: 1,
            count: 25,
            sort_field: 'code',
            sort_direction: 'asc'
        },
        response: [],
        defaults: {
            list: [],
            item: {},
            meta: {},
            links: {},
            filters: {
                title: null,
                status: 'all',
                assignee: 'all',
                importance: 'all',
                size: 'all',
                is_active: 1,
                project_id: null,
                page: 1,
                count: 25,
                sort_field: 'code',
                sort_direction: 'asc'
            },
            response: [],
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        OLD_UPDATE_LIST(state, items) {
            state.old_list = items;
        },
        UPSERT_LIST_ITEM(state, payload) {
            const index = _.findIndex(state.list, { 'id': payload.id });
            const list_item = state.list[index]
            if (index !== -1) Object.assign(list_item, payload)
            else state.list.unshift(payload)
        },
        UPSERT_BULK_LIST_ITEM(state, payload) {
            if (payload && !payload.bulk_type) return state.list.unshift(...payload)
            payload.ids.forEach(id => {
                const index = _.findIndex(state.list, { id });
                if (index !== -1) Object.assign( state.list[index], { loading_status: payload.loading_status })
            })
        },
        UPSERT_ITEM_TC(state, payload) {
            const tc_index = _.findIndex(state.item.testcases, { id: payload.id })
            if (tc_index !== -1) Object.assign(state.item.testcases[tc_index], payload)
        },
        UPSERT_ITEM_SC(state, payload) {
            const tc_index = _.findIndex(state.item.success_criteria, { id: payload.id })
            if (tc_index !== -1) Object.assign(state.item.success_criteria[tc_index], payload)
        },
        UPSERT_ITEM(state, payload) {
            if (payload.payload_type) delete payload.payload_type
            Object.assign(state.item, payload)
        },
        UPDATE_ITEM(state, payload) {
            state.item = payload
        },
        UPDATE_SELECTED_LIST_ITEM(state, payload) {
            payload.forEach(item => {
                const index = _.findIndex(state.list, { 'id': item.id });
                if (index !== -1) Object.assign(state.list[index], item)
            })
        },
        UPDATE_LINKS (state, payload) {
            state.links = payload
        },
        UPDATE_LIST_CLIENT_VISIBILITY(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index].is_client_visible = payload.item.is_client_visible;
        },
        UPDATE_ITEM_CLIENT_VISIBILITY(state, payload) {
            state.item.is_client_visible = payload.item.is_client_visible;
        },
        UPDATE_ITEM_BUSINESS_DRIVERS(state, business_drivers) {
            state.item.business_drivers = business_drivers
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            // state.list[index] = payload.item
            if (index !== -1) Object.assign(state.list[index], payload.item)
        },
        UPDATE_LIST_ITEM_ASSIGNEE(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index].assignees = payload.item.assignees;
            state.list[index].is_client_visible = payload.item.is_client_visible;
        },
        UPDATE_ITEM_ASSIGNEE(state, payload) {
            state.item.assignees = payload.item.assignees;
            state.item.activities = payload.item.activities;
            state.item.is_client_visible = payload.item.is_client_visible;
        },
        UPDATE_ACTIVITIES(state, payload) {
            state.item.activities.unshift(payload.activities[0]);
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_SELECTED_ITEMS (state, items) {
            const newList = items.filter(uc => {
                const index = state.list.findIndex(i => i.id === uc.id)
                return index === -1 ? uc : null
            })

            state.list.push(...newList)
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        PREPEND_ITEM(state, item) {
            state.list.unshift(item);
        },
        APPEND_ITEM(state, item) {
            state.list.push(item);
        },
        BULK_DELETE (state, payload) {
            for (let index = 0; index < payload.ids.length; index++) {
                const usecase_index = state.list.findIndex(item => item.id === payload.ids[index])
                state.list.splice(usecase_index, 1)
            }
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REMOVE_SELECTED(state, ids) {
            ids.forEach(id => {
                const index = _.findIndex(state.list, { id })
                if (index !== -1) state.list.splice(index, 1)
            })
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        async index ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data, meta } } = await axios.get(api_endpoint, { params: payload.params })
                if (payload && payload.mode === 'update-list-selected-item') {
                    commit('UPDATE_SELECTED_LIST_ITEM', data)
                } else if (payload && payload.mode === 'select-index') {
                    commit('APPEND_SELECTED_ITEMS', data)
                    commit('UPDATE_META', meta)
                } else {
                    if (!payload?.stateless) commit('UPDATE_LIST', data)
                    commit('UPDATE_META', meta)
                }
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                this.dispatch('Interface/loader_stop')
                if (payload.stateless) return data
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },

        async remove_selected ({ commit }, payload) {
            commit('REMOVE_SELECTED', payload.usecase_ids)
        },

        old_index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint, { params: payload.params })
                .then((response) => {
                    if (payload && payload.params && payload.params.page === 1) context.commit('UPDATE_LIST', response.data.data)
                    else context.commit('APPEND_LIST', response.data.data)
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },

        report_index(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/reports', { params: payload })
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data)
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        download(context, payload) {
            this.dispatch('Interface/loader_start')
            const params = { download_format: payload.format, header_fields: payload.headers, slugs: payload.slugs, ...payload.filters, ...payload.includes }
            return axios.get(api_endpoint + '/reports/exports', { params })
                .then((response) => {
                    context.commit('UPDATE_LINKS', response.data.url);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        async clear_download_link(context) {
            context.commit('UPDATE_LINKS', _.cloneDeep(context.state.defaults.links));
        },
        enabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/enabled')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        disabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/disabled')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id, { params: payload.params })
                .then((response) => {
                    if (payload.mode && ['create-another', 'upsert-list-item'].includes(payload.mode)) context.commit('UPSERT_LIST_ITEM', response.data.data)
                    else if (payload.mode && ['only-update-item'].includes(payload.mode)) context.commit('UPSERT_ITEM', response.data.data)
                    else {
                        context.commit('UPDATE_ITEM', response.data.data);
                        context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show_item(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id, { params: payload.params })
                .then(async (response) => {
                    if (!payload.type || (payload.type && payload.type !== 'no-item')) await context.commit('UPSERT_ITEM', response.data.data);
                    if (!payload.type || (payload.type && payload.type !== 'no-list')) await context.commit('UPSERT_LIST_ITEM', response.data.data);
                    if (payload.type && payload.type === 'update-item') await context.commit('UPSERT_ITEM', { ...response.data.data, payload_type: payload.type });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        activities(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id + '/activities_index')
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, payload)
                .then((response) => {
                    if (payload.mode && payload.mode === 'create-another') context.dispatch('show', payload)
                    else context.commit('UPDATE_ITEM', payload)
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },

        async bulk_store ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.post(api_endpoint + '/bulk', payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async update({commit, dispatch}, payload) {
            this.dispatch('Interface/loader_start')
            const descEncoded = await dispatch('encode_content', payload)
            const descJson = payload.description_json ? payload.description_json : null
            if(descEncoded) payload.description_json = descEncoded
            return axios.patch(api_endpoint + '/' + payload.id, payload)
                .then((response) => {
                    if(descJson) payload.description_json = descJson
                    commit('UPDATE_ITEM', payload);
                    this.dispatch('Interface/loader_stop')
                    commit('REFRESH_LIST');
                    commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        assign(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/assign', payload.data)
                .then((response) => {
                    if (payload.type && payload.type === 'list') {
                        context.commit('UPDATE_LIST_ITEM_ASSIGNEE', { id: payload.id, item: response.data.data });
                    } else {
                        context.commit('UPDATE_ITEM_ASSIGNEE', { id: payload.id, item: response.data.data });
                    }

                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        unassign(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/unassign', payload.data)
                .then((response) => {
                    if (payload.type && payload.type === 'list') {
                        context.commit('UPDATE_LIST_ITEM_ASSIGNEE', { id: payload.id, item: response.data.data });
                    } else {
                        context.commit('UPDATE_ITEM_ASSIGNEE', { id: payload.id, item: response.data.data });
                    }

                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },

        async delete_selected ({ commit }, payload) {
            await commit('REMOVE_SELECTED', payload)
        },

        delete_all(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/delete_all/' + payload.project_id)
                .then((response) => {
                    context.commit('UPDATE_LIST', []);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },

        async bulk_delete({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.delete(api_endpoint + '/bulk-delete', { params: payload })
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },

        uc_upsert ({ commit }, payload) {
            commit('UPSERT_LIST_ITEM', payload)
        },

        bulk_upsert ({ commit }, payload) {
            commit('UPSERT_BULK_LIST_ITEM', payload)
        },

        tc_upsert ({ commit }, payload) {
            commit('UPSERT_ITEM_TC', payload)
        },

        sc_upsert ({ commit }, payload) {
            commit('UPSERT_ITEM_SC', payload)
        },

        update_list_item ({ commit }, payload) {
            commit('UPDATE_LIST_ITEM', payload)
        },

        async throw_success ({ commit }) {
            commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            this.dispatch('Interface/loader_stop')
        },

        async throw_error ({ commit }, error) {
            this.dispatch('Interface/loader_stop')
            commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
        },

        async loader_start () {
            this.dispatch('Interface/loader_start')
        },

        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        enable(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/enable', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        disable(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/disable', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update_status(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/states/status', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        visibility(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/states/visibility', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        business_driver_store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/' + payload.id + '/business_drivers/' + payload.business_driver_id, payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_ITEM_BUSINESS_DRIVERS', response.data.data)
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        business_driver_destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id + '/business_drivers/' + payload.business_driver_id)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('UPDATE_ITEM_BUSINESS_DRIVERS', response.data.data)
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return axios.patch(api_endpoint + '/reorder', { data: payload.list, project_id: payload.project_id })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        template_import(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/import', payload)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update_activities(context, payload) {
            context.commit('UPDATE_ACTIVITIES', { id: payload.id, activities: payload.activities })
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        encode_content ({commit}, payload) {
            if(!payload['description_json']) return
            const content = payload['description_json']
            return Base64.encode(JSON.stringify(content))
        }
    },
}

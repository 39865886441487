<template>
    <div>
        <!-- Ticket Detail -->
        <a-container grid-list-xl container--fluid pa-0 pb-12>
            <a-layout v-if="pageLoading">
                <a-flex xs12>
                    <STicketViewLoader/>
                </a-flex>
            </a-layout>
            <a-layout
                v-else
                @dragenter="check_permission('update') && !localAttachmentsUpdating ? localHandleDragEnter() : ''"
                @drop="localHandleDrop"
                class="u-relative"
            >
                <SDragOverlay
                    v-if="filesDraggedOver"
                    pageType="internal"
                    @leave="localHandleLeave()"
                    @enter="hoveredOnInfo=false"
                    @enterInfo="hoveredOnInfo=true"
                >
                </SDragOverlay>
                <a-flex xs9 xl10>
                    <a-btn color="grey darken-2" class="ma-0 pl-0 mb-3" small text :to="{name: 'projects-tickets'}" :exact="true">
                        <a-icon left class="mr-2" size="20">arrow_back</a-icon>
                        Back to tickets
                    </a-btn>
                    <div class="u-flex align-center">
                        <a-text-field
                            v-model="local_ticket_item.title"
                            ref="ref_ticket_title"
                            class="text-h5 font-weight-medium grey--text text--darken-3 u-wfull u-rounded-corners u-border"
                            solo flat hide-details autofocus
                            v-if="check_permission('update')"
                            @input="localTicketValidationCheck()"
                        ></a-text-field>

                        <a-sheet v-else class="text-h5 font-weight-medium grey--text py-2 px-3 u-border u-rounded-corners text--darken-3 u-wfull">{{ local_ticket_item.title }}</a-sheet>
                    </div>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.title">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ ticket_response.server.errors.title[0] }}
                    </span>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="localGetTicketErrorMessage('title')">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ localGetTicketErrorMessage('title') }}
                    </span>
                    <div class="mt-4">
                        <a-card flat class="pa-0 u-border u-rounded-corners">
                            <div class="u-flex align-center px-6 py-3">
                                <div class="u-flex align-center">
                                    <h3 class="c-letter-space text-uppercase md-caption grey--text text--darken-1 font-weight-medium">Description</h3>
                                </div>
                                <a-spacer></a-spacer>
                                <div class="u-flex align-center" v-if="check_permission('update')">
                                    <p class="md-caption grey--text mb-0 d-flex align-center">
                                        <em class="mr-2">Select text for formating options</em>
                                        <a-icon size="14" color="grey" class="ml-1">format_bold</a-icon>
                                        <a-icon size="14" color="grey" class="ml-1">format_italic</a-icon>
                                    </p>
                                </div>
                            </div>
                            <a-divider class="u-divider"></a-divider>
                            <div class="px-6 pt-3 pb-1">
                                <p class="md-body-2 grey--text text--darken-1" v-if="check_permission('update') && (!local_ticket_item.description || local_ticket_item.description && local_ticket_item.description.replace(/<[^>]*>?/gm, '') === null || local_ticket_item.description && local_ticket_item.description.replace(/<[^>]*>?/gm, '') == '')" @click="localFocusTicketEditor">Click to edit the description</p>
                                <editor-content v-else class="c-editor md-body-2 u-hfull" :editor="editor_ticket_description"></editor-content>
                                <editor-menu-bubble v-if="check_permission('update')" :editor="editor_ticket_description" :keep-in-bounds="false" v-slot="{ commands, isActive, menu }">
                                    <div
                                        class="menububble"
                                        :class="{ 'is-active': menu.isActive }"
                                        :style="`left: ${menu.left - 16}px; bottom: ${menu.bottom + 8}px;`"
                                    >
                                        <a-btn icon text small class="mx-0" :color="(isActive.bold()) ? 'blue' : 'grey'" @click="commands.bold">
                                            <a-icon size="16">format_bold</a-icon>
                                        </a-btn>
                                        <a-btn icon text small class="mx-0" :color="(isActive.italic()) ? 'blue' : 'grey'" @click="commands.italic">
                                            <a-icon size="16">format_italic</a-icon>
                                        </a-btn>
                                        <a-divider vertical dark></a-divider>
                                        <a-btn icon text small class="mx-0" :color="(isActive.bullet_list()) ? 'blue' : 'grey'" @click="commands.bullet_list">
                                            <a-icon size="16">format_list_bulleted</a-icon>
                                        </a-btn>
                                        <a-btn icon text small class="mx-0" :color="(isActive.ordered_list()) ? 'blue' : 'grey'" @click="commands.ordered_list">
                                            <a-icon size="16">format_list_numbered</a-icon>
                                        </a-btn>
                                    </div>
                                </editor-menu-bubble>
                            </div>
                        </a-card>
                    </div>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.description">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ ticket_response.server.errors.description[0] }}
                    </span>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="localGetTicketErrorMessage('description')">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ localGetTicketErrorMessage('description') }}
                    </span>
                    <div class="mt-6">
                        <div class="u-flex align-center">
                            <span class="c-letter-space text-uppercase md-caption d-block c-meta-title grey--text text--darken-2 font-weight-medium mb-2">Tags</span>
                            <a-spacer></a-spacer>
                            <h4 v-if="check_permission('update')" class="md-subtitle-2 font-weight-medium grey--text text--darken-1"><span class="red--text text--darken-1">* </span>Any update done on this ticket won't affect the parent ticket.</h4>
                        </div>
                        <div class="d-inline-flex align-center">
                            <div class="pa-0 d-inline-flex flex-wrap">
                                <template v-if="local_ticket_item.tags && local_ticket_item.tags.length">
                                    <template v-for="tag in local_ticket_item.tags">
                                        <g-tags :can-update="check_permission('update')" :hide-clear-icon="!$can('poc_tickets.update')" :tag="tag" type="Ticket" @destroy="localticketAssociateTagDestroy(tag, local_ticket_item)" :key="tag.id"></g-tags>
                                    </template>
                                </template>
                                <a-menu bottom offset-y v-model="localShowTicketTagAddInput" v-if="check_permission('update')" :close-on-content-click="false" ref="refAddTagPopup" transition="slide-y-transition" nudge-bottom="8">
                                    <template v-slot:activator="{ on }">
                                        <g-add-btn v-on="on" dark></g-add-btn>
                                    </template>
                                    <a-sheet class="pb-1" max-width="240">
                                        <s-tags-inline-form
                                            :item="local_ticket_item"
                                            :can-update="$can('tags.store')"
                                            :list="localFilteredTicketTags()"
                                            :is-menu-open="localShowTicketTagAddInput"
                                            source="Ticket"
                                            type="ticket_tag"
                                            @after-store="() => localShowTicketTagAddInput = false"
                                            update-type="manual_save"
                                            @get-tag-update="localGetFieldUpdate"
                                            @get-selected-tag="localGetSelectedTag"
                                        ></s-tags-inline-form>
                                    </a-sheet>
                                </a-menu>
                            </div>
                        </div>
                    </div>
                    <!-- Attachment section -->
                    <div class="mt-8">
                        <SAttachmentSection
                            :list="attachment_local_list"
                            :dropTransferList="localDroppedFilesList"
                            :sourceId="local_ticket_item.id"
                            :updateLoading="localAttachmentsUpdating"
                            :loading="mixinIsLoading('attachment')"
                            sourceType="Ticket"
                            :canUpdate="$can('poc_tickets.update') && $can('projects.update-only')"
                            @dropTransfer="localUpdateAttachmentTransfer"
                            @loadMore="localLoadMoreAttachments()"
                            @remove="localRemoveAttachmentItem"
                            @delete="localDeleteAttachmentItem"
                            flat
                        >
                        </SAttachmentSection>
                    </div>
                    <a-divider v-if="attachment_meta && (attachment_meta.total == attachment_list.length)" class="grey lighten-2 mt-12"></a-divider>
                    <div :class="[ ticket_item.links_workspace && ticket_item.links_workspace.length ? 'mt-12' : 'mt-8']">
                        <!-- Design page -->
                        <div class="mt-4">
                            <a-layout align-center class="mb-2 mt-4">
                                <a-flex class="u-flex align-center">
                                    <div class="c-letter-space text-uppercase md-caption font-weight-medium grey--text text--darken-2">Fields</div>
                                </a-flex>
                            </a-layout>
                            <template v-if="localClonelayoutDesignList && localClonelayoutDesignList.length">
                                <div v-for="(section, index) in localClonelayoutDesignList" :key="section.id">
                                    <a-sheet
                                        class="mb-8 u-border u-wfull u-rounded-corners c-section-card"
                                        v-if="section.is_active"
                                    >
                                        <div>
                                            <div class="pa-5">
                                                <a-layout align-center>
                                                    <a-flex class="u-flex align-center">
                                                        <div class="u-wfull">
                                                            <h2 class="md-subtitle-1 indigo--text text--darken-1 font-weight-medium" :title="section.name">
                                                                {{ section.name | truncateText(local_screen_lg ? 40 : 120) }}
                                                            </h2>
                                                        </div>
                                                    </a-flex>
                                                </a-layout>
                                            </div>
                                            <a-divider v-if="local_show_divider(section)"></a-divider>
                                            <template v-if="section.fields && !section.fields.length">
                                                <div class="py-8 px-6 text-center">
                                                    <p class="grey--text mb-0 text--darken-3" style="font-weight: 600">No fields are added to this section!</p>
                                                    <p v-if="$can('layout_designer.index')" class="grey--text text--darken-1 mb-0 mt-3 font-weight-medium mx-auto" style="max-width: 600px">You can configure existing fields using Layout Designer.</p>
                                                    <div class="u-flex justify-center font-weight-medium mt-4">
                                                        <router-link v-if="$can('layout_designer.index')" class="mx-4 indigo--text text--darken-1" :to="{ name: 'layout-designer'}">
                                                            <span>Go to Layout Designer</span>
                                                            <a-icon size="18" color="indigo darken-1" class="pl-1">arrow_forward</a-icon>
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </template>
                                            <a-sheet v-else class="u-wfull c-section-container">
                                                <div v-for="(field, fieldIndex) in section.fields" :key="field.id" :class="[field.width === '50%' ? 'c-field-card__half--preview' : 'c-field-card__full']">
                                                    <a-card
                                                        flat
                                                        :class="['u-wfull mb-5']"
                                                    >
                                                        <a-divider v-if="local_show_item_divider(section, field, fieldIndex)"></a-divider>
                                                        <div class="px-5 pb-2 pt-5 u-wfull">
                                                            <div v-if="field && field.parent_type === 'CustomField'" class="u-flex align-center px-0 mb-3">
                                                                <a-icon
                                                                    size="20"
                                                                    color="grey lighten-1"
                                                                    class="mr-2"
                                                                >
                                                                    {{ localGetCustomFieldTypeItem(field) }}
                                                                </a-icon>
                                                                <h3 class="md-subtitle-1 grey--text text--darken-3 font-weight-medium" >{{ field.parent.label }}</h3>
                                                                <a-tooltip right v-if="field.parent.description">
                                                                    <template v-slot:activator="{ on }">
                                                                        <a-icon v-on="on" size="16" color="grey darken-1" class="u-cursor-pointer ml-2 u-flex">info</a-icon>
                                                                    </template>
                                                                    <span class="py-3">{{ field.parent.description }}</span>
                                                                </a-tooltip>
                                                            </div>
                                                            <div class="u-wfull">
                                                                <template v-if="field && field.parent.type === 'url'">
                                                                    <template v-if="field.parent.custom_field_value">
                                                                        <a-tooltip bottom offset-y :disabled="!localGetTicketErrorMessage(field.parent.name, 'customField') && field.parent.user_writable === 1">
                                                                            <template v-slot:activator="{ on }">
                                                                                <div v-on="on">
                                                                                    <a-text-field
                                                                                        v-model="field.parent.custom_field_value.value"
                                                                                        :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                        color="grey darken-1"
                                                                                        background-color="grey lighten-4"
                                                                                        class="u-rounded-corners"
                                                                                        :class="[localGetTicketErrorMessage(field.parent.name, 'customField') ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                        :disabled="!(check_permission('update')) || field.parent.user_writable === 0"
                                                                                        @input="localTicketCustomFieldUpdate(field.parent, field.parent.custom_field_value ? field.parent.custom_field_value.value : '')"
                                                                                        :append-icon="!localCheckUrlIcon(field) ? 'open_in_new' : ''"
                                                                                        @click:append="field.parent.custom_field_value.value ? localLinkOpenNewTab(field.parent.custom_field_value.value) : {}"
                                                                                        solo flat hide-details
                                                                                    ></a-text-field>
                                                                                </div>
                                                                            </template>
                                                                            <span class="py-3">{{ localGetTicketErrorMessage(field.parent.name, 'customField') ? localGetTicketErrorMessage(field.parent.name, 'customField') : 'This is a read-only field.'}}</span>
                                                                        </a-tooltip>
                                                                    </template>
                                                                </template>
                                                                <template v-if="field && ['text','number'].includes(field.parent.type)">
                                                                    <template v-if="field.parent.custom_field_value">
                                                                        <a-tooltip bottom offset-y :disabled="!localGetTicketErrorMessage(field.parent.name, 'customField') && field.parent.user_writable === 1">
                                                                            <template v-slot:activator="{ on }">
                                                                                <div v-on="on">
                                                                                    <a-text-field
                                                                                        v-model="field.parent.custom_field_value.value"
                                                                                        :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                        color="grey darken-1"
                                                                                        background-color="grey lighten-4"
                                                                                        class="u-rounded-corners"
                                                                                        :class="[localGetTicketErrorMessage(field.parent.name, 'customField') ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                        :disabled="!(check_permission('update')) || field.parent.user_writable === 0"
                                                                                        @input="localTicketCustomFieldUpdate(field.parent, field.parent.custom_field_value ? field.parent.custom_field_value.value : '')"
                                                                                        @keypress="localIsNumber($event, field.parent.type)"
                                                                                        solo flat hide-details
                                                                                    ></a-text-field>
                                                                                </div>
                                                                            </template>
                                                                            <span class="py-3">{{ localGetTicketErrorMessage(field.parent.name, 'customField') ? localGetTicketErrorMessage(field.parent.name, 'customField') : 'This is a read-only field.'}}</span>
                                                                        </a-tooltip>
                                                                    </template>
                                                                </template>
                                                                <template v-if="field && field.parent.type === 'multiline'">
                                                                    <template v-if="field.parent.custom_field_value">
                                                                    <a-tooltip bottom offset-y :disabled="!localGetTicketErrorMessage(field.parent.name, 'customField') && field.parent.user_writable === 1">
                                                                        <template v-slot:activator="{ on }">
                                                                            <div style="overflow-y: auto; max-height: 15rem" class="grey lighten-4 rounded" v-on="on">
                                                                                <a-textarea
                                                                                    v-model="field.parent.custom_field_value.value"
                                                                                    :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                    color="grey darken-1"
                                                                                    background-color="grey lighten-4"
                                                                                    class="u-rounded-corners"
                                                                                    :class="[localGetTicketErrorMessage(field.parent.name, 'customField') ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                    :disabled="!(check_permission('update')) || field.parent.user_writable === 0"
                                                                                    @input="localTicketCustomFieldUpdate(field.parent, field.parent.custom_field_value ? field.parent.custom_field_value.value : '')"
                                                                                    solo flat hide-details no-resize auto-grow
                                                                                    rows="3"
                                                                                >
                                                                                </a-textarea>
                                                                            </div>
                                                                        </template>
                                                                        <span class="py-3">{{ localGetTicketErrorMessage(field.parent.name, 'customField') ? localGetTicketErrorMessage(field.parent.name, 'customField') : 'This is a read-only field.'}}</span>
                                                                    </a-tooltip>
                                                                </template>
                                                                </template>
                                                                <template v-if="field && field.parent.type === 'mask'">
                                                                    <template v-if="field.parent.custom_field_value">
                                                                        <a-tooltip bottom offset-y :disabled="!localGetTicketErrorMessage(field.parent.name, 'customField') && field.parent.user_writable === 1">
                                                                            <template v-slot:activator="{ on }">
                                                                                <div v-on="on">
                                                                                    <a-text-field
                                                                                        v-model="field.parent.custom_field_value.mask_value"
                                                                                        :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                        color="grey darken-1"
                                                                                        background-color="grey lighten-4"
                                                                                        class="u-rounded-corners"
                                                                                        :class="[localGetTicketErrorMessage(field.parent.name, 'customField') ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                        :disabled="!(check_permission('update')) || field.parent.user_writable === 0"
                                                                                        solo flat hide-details
                                                                                        :append-icon="check_permission('update') && field.parent.user_writable === 1 ? 'drive_file_rename_outline' : ''"
                                                                                        readonly
                                                                                        @click:append="localSelectCustomFieldItem(section.id, field.id, field.parent)"
                                                                                    ></a-text-field>
                                                                                </div>
                                                                            </template>
                                                                            <span class="py-3">{{ localGetTicketErrorMessage(field.parent.name, 'customField') ? localGetTicketErrorMessage(field.parent.name, 'customField') : 'This is a read-only field.'}}</span>
                                                                        </a-tooltip>
                                                                    </template>
                                                                </template>
                                                                <template v-if="field && field.parent.type === 'dropdown'">
                                                                    <template v-if="field.parent.custom_field_value">
                                                                        <a-tooltip bottom offset-y :disabled="field.parent.user_writable === 1">
                                                                            <template v-slot:activator="{ on }">
                                                                                <div v-on="on">
                                                                                    <a-autocomplete
                                                                                        v-model="field.parent.custom_field_value.value"
                                                                                        :items="field.parent.options"
                                                                                        item-text="label"
                                                                                        item-value="value"
                                                                                        :placeholder="check_permission('update') ? (field.parent.placeholder ? field.parent.placeholder : 'Select a value') : ''"
                                                                                        class="u-rounded-corners"
                                                                                        :class="[localGetTicketErrorMessage(field.parent.name, 'customField') ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                        color="grey darken-1"
                                                                                        background-color="grey lighten-4"
                                                                                        :key="field.parent.id"
                                                                                        :append-icon="check_permission('update') ? 'arrow_drop_down' : null"
                                                                                        :disabled="!(check_permission('update')) || field.parent.user_writable === 0"
                                                                                        @input="localSelectTicketCustomField(field.parent, field.parent.custom_field_value.value ? field.parent.custom_field_value.value : null)"
                                                                                        solo flat hide-details
                                                                                    >
                                                                                        <template v-slot:no-data>
                                                                                            <div class="px-4 py-2">
                                                                                                <span class="md-body-1 grey--text">No Data Found</span>
                                                                                            </div>
                                                                                        </template>
                                                                                        <template v-slot:selection="{ item }">
                                                                                            <span v-if="item === Object(item)" class="md-body-3 d-block py-1 u-rounded-corners text-truncate" style="max-width: 630px" :title="item.label">{{ item.label }} </span>
                                                                                        </template>
                                                                                        <template v-slot:item="{ item }">
                                                                                            <a-layout align-center class="py-0">
                                                                                                <a-flex style="max-width: 630px">
                                                                                                    <h5 class="md-subtitle-1 text-truncate" :title="item.label">{{ item.label }}</h5>
                                                                                                </a-flex>
                                                                                            </a-layout>
                                                                                        </template>
                                                                                    </a-autocomplete>
                                                                                </div>
                                                                            </template>
                                                                            <span class="py-3">This is a read-only field</span>
                                                                        </a-tooltip>
                                                                    </template>
                                                                </template>
                                                                <template v-if="field && field.parent.type === 'date'">
                                                                    <template v-if="field.parent.custom_field_value">
                                                                        <a-tooltip bottom offset-y :disabled="!localGetTicketErrorMessage(field.parent.name, 'customField') && field.parent.user_writable === 1">
                                                                            <template v-slot:activator="{ on }">
                                                                                <div v-on="on">
                                                                                    <!-- <a-menu
                                                                                        v-model="datepickerMenu[field.parent.id]"
                                                                                        :close-on-content-click="false"
                                                                                        :nudge-right="60"
                                                                                        transition="scale-transition"
                                                                                        min-width="290px"
                                                                                        offset-y
                                                                                    >   <template v-slot:activator="{ on, attrs }">
                                                                                            <a-text-field
                                                                                                v-model="datepickerDateValue[field.parent.id]"
                                                                                                :value="local_format_date(field.parent.custom_field_value.value)"
                                                                                                placeholder="Select Date"
                                                                                                background-color="neutral"
                                                                                                :class="[localGetTicketErrorMessage(field.parent.name, 'customField') ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                                :disabled="!(check_permission('update')) || field.parent.user_writable === 0"
                                                                                                @input="localTicketCustomFieldUpdate(field.parent, field.parent.custom_field_value ? field.parent.custom_field_value.value : '')"
                                                                                                @click:clear="field.parent.custom_field_value.value = null"
                                                                                                solo flat hide-details readonly clearable
                                                                                                v-bind="attrs"
                                                                                                v-on="on"
                                                                                            >
                                                                                            </a-text-field>
                                                                                        </template>
                                                                                        <a-date-picker
                                                                                            v-model="field.parent.custom_field_value.value"
                                                                                            @input="localSelectTicketdatepicker(field.parent, index)"
                                                                                            no-title scrollable
                                                                                        >
                                                                                        </a-date-picker>
                                                                                    </a-menu> -->
                                                                                    <vc-date-picker
                                                                                        v-model="field.parent.custom_field_value.value"
                                                                                        class="d-block u-wfull"
                                                                                        @input="localSelectTicketdatepicker(field.parent, index)"
                                                                                        :model-config="modelConfig"
                                                                                    >
                                                                                        <template v-slot="{togglePopover, hidePopover}">
                                                                                            <a-sheet width="100%" @click="local_can_update(field) ? '' : togglePopover({ placement: 'bottom-start' })">
                                                                                                <template>
                                                                                                    <a-text-field
                                                                                                        v-model="datepickerDateValue[field.parent.id]"
                                                                                                        :value="local_format_date(field.parent.custom_field_value.value)"
                                                                                                        placeholder="Select Date"
                                                                                                        background-color="neutral"
                                                                                                        :class="[localGetTicketErrorMessage(field.parent.name, 'customField') ? 'c-wiggle-short u-border-error': !field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                                        :disabled="!(check_permission('update')) || field.parent.user_writable === 0"
                                                                                                        @input="localTicketCustomFieldUpdate(field.parent, field.parent.custom_field_value ? field.parent.custom_field_value.value : '')"
                                                                                                        @click:clear="localClearTicketCustomField(field, hidePopover)"
                                                                                                        solo flat hide-details readonly clearable
                                                                                                        v-on="on"
                                                                                                    >
                                                                                                    </a-text-field>
                                                                                                </template>
                                                                                            </a-sheet>
                                                                                        </template>
                                                                                    </vc-date-picker>
                                                                                </div>
                                                                            </template>
                                                                            <span class="py-3">{{ localGetTicketErrorMessage(field.parent.name, 'customField') ? localGetTicketErrorMessage(field.parent.name, 'customField') : 'This is a read-only field.'}}</span>
                                                                        </a-tooltip>
                                                                    </template>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </a-card>
                                                </div>
                                            </a-sheet>
                                        </div>
                                    </a-sheet>
                                </div>
                            </template>
                            <template v-else>
                                <a-sheet class="u-shadow">
                                    <div class="py-8 px-6 text-center u-flex flex-column align-center">
                                        <div class="rounded-circle grey lighten-3 u-flex justify-center mb-4" style="width: 64px; height: 64px">
                                            <a-icon size="40" color="grey lighten-1">sell</a-icon>
                                        </div>
                                        <div class="mt-4 text-center">
                                            <div class="grey--text text--darken-3 text-uppercase" style="font-weight: 600">No fields are added!</div>
                                            <p class="grey--text text--darken-1 mb-0 mt-3 font-weight-medium mx-auto" style="max-width: 600px">
                                                <template v-if="$can('layout_designer.index')">
                                                    You can configure existing fields using Layout Designer.
                                                </template>
                                                <template v-else>
                                                    There are no custom fields attached to this ticket.
                                                </template>
                                            </p>
                                            <div class="u-flex justify-center font-weight-medium mt-4">
                                                <a-btn v-if="$can('layout_designer.index')" depressed color="grey darken-4" :to="{ name: 'layout-designer'}">
                                                    <a-icon size="18" color="white" class="pr-1">login</a-icon>
                                                    <span class="white--text">Go to Layout Designer</span>
                                                </a-btn>
                                            </div>
                                        </div>
                                    </div>
                                </a-sheet>
                            </template>
                        </div>
                    </div>
                    <template v-if="this.$can('projects.update-only') && ($can('poc_tickets.update') || $can('poc_tickets.destroy'))">
                        <div class="c-update-container u-border u-rounded-corners py-3 px-4 mt-6">
                            <div class="u-flex">
                                <div class="u-flex" v-if="$can('poc_tickets.update')">
                                    <a-btn
                                        @click="localTicketUpdate()"
                                        :loading="isTicketUpdateLoading || mixinIsLoading('attachment-update')"
                                        :disabled="!localCheckTicketUpdate"
                                        height="32"
                                        color="#3949AB"
                                        class="c-save-btn ma-0 mr-4 px-2 py-3 align-center white--text text--darken-2 u-rounded-corners"
                                        small
                                    >
                                        Save
                                    </a-btn>
                                    <a-btn
                                        :disabled="!localCheckTicketUpdate || isTicketUpdateLoading || localAttachmentsUpdating"
                                        @click="localTicketUpdateCancel()"
                                        height="32"
                                        color="#EEEEEE"
                                        tag="a"
                                        small depressed
                                        class="ma-0 px-2 py-3 align-center text--darken-2 u-rounded-corners"
                                    >
                                        <span style="color: #9E9E9E">Cancel</span>
                                    </a-btn>
                                    <div class="px-4 d-flex align-center ml-4" v-if="mixinIsLoading('attachment-update') || isTicketUpdateLoading">
                                        <template v-if="mixinIsLoading('attachment-update') && attachment_upload_list && attachment_upload_list.length">
                                            <span class="text-uppercase blue--text md-body-2">
                                                Uploading Attachments
                                            </span>
                                        </template>
                                        <template v-else-if="isTicketUpdateLoading && (attachment_upload_list && !attachment_upload_list.length)">
                                            <span class="text-uppercase blue--text md-body-2">
                                                Saving changes
                                            </span>
                                        </template>
                                    </div>
                                    <a-sheet v-if="localTicketUpdateErrors.length" class="px-4 u-rounded-corners d-flex align-center red lighten-5 ml-4">
                                        <a-icon size="16px" color="red">info</a-icon>
                                        <p class="ml-2 font-weight-medium mb-0 red--text text--darken-1">One or more fields are invalid</p>
                                    </a-sheet>
                                </div>
                                <a-spacer></a-spacer>
                                <div v-if="$can('poc_tickets.destroy')">
                                    <a-menu bottom left offset-y :close-on-content-click="false" max-width="250" :disabled="pageLoading || isTicketUpdateLoading">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <a-btn small text dark class="red lighten-5 u-rounded-corners elevation-0 ma-0 u-wfull red--text text--darken-1 u-font-weight-semibold" height="32">
                                                    Delete Ticket
                                                </a-btn>
                                            </div>
                                        </template>
                                        <a-card>
                                            <a-card-text class="pa-4">
                                                <h2 class="md-heading-6 mb-2">Are you sure to delete this ticket?</h2>
                                                <p class="body-2 mb-4">This action will delete the ticket and cannot be undone.</p>
                                                <a-btn class="ma-0 elevation-0" color="red" @click="localProjectTicketDestroy(local_ticket_item)" :loading="loading" :disabled="loading" dark small>
                                                    <a-icon size="16" left class="mr-2">delete</a-icon> Delete
                                                </a-btn>
                                            </a-card-text>
                                        </a-card>
                                    </a-menu>
                                </div>
                            </div>
                        </div>
                    </template>
                </a-flex>
                <a-flex shrink xs3 xl2 ml-0 pr-0>
                    <div :style="[{ maxWidth: local_screen_lg ? 'auto' : '270px' }]" class="u-rounded-corners pa-5 py-4 mt-5">
                        <div class="c-dropdown mb-5">
                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Ticket Id</span>
                            <h3 class="md-body-2 font-weight-medium grey--text text--darken-2 grey lighten-2 d-inline-block u-rounded-corners px-1" style="font-family: monospace !important;">{{ ticket_item.code }}</h3>
                        </div>
                        <div class="c-dropdown mb-5">
                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Due Date</span>
                            <!-- <a-menu offset-y v-model="datepicker_due_date_edit" :disabled="!check_permission('update')">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" class="md-body-2 u-cursor-pointer grey--text text--darken-3 font-weight-medium d-inline-flex align-center">
                                        <a-icon size="16" color="grey darken-1" class="mr-1 u-icon-nudge" style="top: -1px;">date_range</a-icon>
                                        <g-moment v-if="local_ticket_item.due_date" :value="local_ticket_item.due_date" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY" convert-local></g-moment>
                                        <template v-else>No Due Date</template>
                                        <template v-if="check_permission('update')">
                                            <a-icon size="12" color="grey darken-1" v-if="!local_ticket_item.due_date" class="ml-2">edit</a-icon>
                                            <a-icon size="16" color="grey darken-1" v-if="local_ticket_item.due_date" @click="localTicketDateClear" class="ml-2 u-cursor-pointer u-rounded-corners-full">clear</a-icon>
                                        </template> -->
                                        <!-- <a-spacer></a-spacer>
                                        <a-icon class="c-dropdown__trigger-arrow ml-2" size="24" color="grey darken-1">arrow_drop_down</a-icon> -->
                                    <!-- </div>
                                </template>
                                <a-date-picker v-model="local_ticket_item.due_date" @input="datepicker_due_date_edit = false" no-title scrollable @change="localGetFieldUpdate()"></a-date-picker> -->
                            <!-- </a-menu> -->
                            <a-menu offset-y v-model="datepicker_due_date_edit" :disabled="!check_permission('update')">
                                <template v-slot:activator="{ on }">
                                    <vc-date-picker
                                        v-on="on"
                                        v-model="local_ticket_item.due_date"
                                        @input="localGetFieldUpdate()"
                                        class="d-block u-wfull"
                                        :model-config="modelConfig"
                                    >
                                        <template v-slot="{ togglePopover }">
                                            <div
                                                class="u-wfull u-flex align-stretch u-cursor-pointer u-rounded-corners-lg md-body-2 u-cursor-pointer grey--text text--darken-3 font-weight-medium mb-1"
                                                @click="check_permission('update') ? togglePopover({ placement: 'bottom-start' }) : ''"
                                            >
                                                <a-icon size="16" color="grey darken-1" class="mr-1 u-icon-nudge" style="top: -1px;">date_range</a-icon>
                                                <g-moment v-if="local_ticket_item.due_date" :value="local_ticket_item.due_date" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY" convert-local></g-moment>
                                                <template v-else>No Due Date</template>
                                                <template v-if="check_permission('update')">
                                                    <a-icon size="12" color="grey darken-1" v-if="!local_ticket_item.due_date" class="ml-2">edit</a-icon>
                                                    <a-icon size="16" color="grey darken-1" v-if="local_ticket_item.due_date" @click.stop="localTicketDateClear" class="ml-2 u-cursor-pointer u-rounded-corners-full">clear</a-icon>
                                                </template>
                                            </div>
                                        </template>
                                    </vc-date-picker>
                                </template>
                            </a-menu>
                        </div>
                        <div class="c-dropdown mb-6">
                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Type</span>
                            <s-meta-ticket-dropdown max-width="270" :can-update="check_permission('update')" text-length="20" :meta-item="local_ticket_item.type" :meta-list="typeList" icon-size="24" inline  :disabled="loading_meta" @updateMeta="localUpdateTickectMeta(...arguments, 'type')">
                                <template #icon>arrow_drop_down</template>
                            </s-meta-ticket-dropdown>
                        </div>
                        <div class="c-dropdown mb-6">
                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Priority</span>
                            <s-meta-ticket-dropdown max-width="270" :can-update="check_permission('update')" text-length="20" :meta-item="local_ticket_item.priority" :meta-list="priorityList" icon-size="24" inline :disabled="loading_meta" @updateMeta="localUpdateTickectMeta(...arguments, 'priority')">
                                <template #icon>arrow_drop_down</template>
                            </s-meta-ticket-dropdown>
                        </div>
                        <div class="c-dropdown mb-6">
                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Status</span>
                            <GNonActiveInfo
                                :project="project_item"
                                :stage="project_item && project_item.status"
                                :disabled="!check_permission('update') || (check_permission('update') && project_item && project_item.status === 'active')"
                                left
                            >
                                <s-meta-ticket-dropdown
                                    :can-update="check_permission('update') && (project_item && project_item.status === 'active')"
                                    :meta-item="local_ticket_item.status"
                                    :meta-list="statusList"
                                    :loading="false"
                                    @updateMeta="localUpdateTickectMeta(...arguments, 'status')"
                                    max-width="270"
                                    text-length="20"
                                    icon-size="24"
                                    inline
                                ></s-meta-ticket-dropdown>
                                <template #move-stage="{ moveStage }">
                                    <span @click="localCheckPlanStageUpdate(moveStage)" class="d-inline-flex align-center u-cursor-pointer">
                                        <h3 class="blue--text md-body-2 font-weight-medium">Move to active stage</h3>
                                        <a-icon size="16" color="blue" class="ml-1">trending_flat</a-icon>
                                    </span>
                                </template>
                            </GNonActiveInfo>
                        </div>
                        <div class="c-dropdown mb-6" v-if="$can('tickets.index')">
                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Linked Ticket</span>
                            <div v-if="local_ticket_item.ticket_id" class="u-flex align-center mt-1">
                                <router-link tag="a" class="md-body-2 d-inline-flex align-center blue darken-1 white--text pa-1 px-2 font-weight-medium u-rounded-corners" :to="{ name: 'tickets-view', params: { id: local_ticket_item.ticket_id } }">
                                    <a-icon size="16" color="white" class="mr-1">account_tree</a-icon>
                                    View Linked Ticket
                                </router-link>
                                <template v-if="check_permission('update')">
                                    <a-icon v-if="!local_remove_link" size="16" color="grey darken-1" class="ml-1 u-rounded-corners-full pa-1 u-cursor-pointer" v-ripple @click="local_remove_confirm()">clear</a-icon>
                                    <a-tooltip bottom v-else>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" @click="localLinkProjectTicket(local_ticket_item.id, 'unlink')">
                                                <a-icon size="16" color="red darken-2" v-ripple class="ml-1 u-rounded-corners-full pa-1 u-cursor-pointer">delete</a-icon>
                                            </div>
                                        </template>
                                        <span>Confirm ?</span>
                                    </a-tooltip>
                                </template>
                            </div>
                            <div v-else>
                                <a-menu offset-y left :close-on-content-click="ticket_search_mode" max-width="200" :disabled="!check_permission('update')">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="u-flex align-center u-cursor-pointer">
                                            <span class="md-body-2 grey--text text--darken-1 font-weight-medium">No Linked Ticket Available</span>
                                            <a-icon size="24" color="grey darken-1" class="ml-2" v-if="check_permission('update')">arrow_drop_down</a-icon>
                                        </div>
                                    </template>
                                    <a-list class="u-list-condensed pt-0 mt-0">
                                        <a-sheet class="c-input-wrap px-2" :class="[ticket_global_list && ticket_global_list.length > 6 ? 'pt-2' : 'pt-1']">
                                            <a-text-field
                                                v-model="local_search_tickets"
                                                placeholder="Search Global Tickets"
                                                background-color="neutral"
                                                class="md-body-2 font-weight-normal"
                                                solo flat hide-details autofocus
                                                v-if="ticket_global_list && ticket_global_list.length > 6"
                                                @click="ticket_search_mode = false"
                                            >
                                            </a-text-field>
                                            <div class="md-caption font-weight-medium grey--text text--darken-1 px-2 pt-1 pb-2" style="border-bottom: 1px solid #f5f5f5" v-if="ticket_global_list && ticket_global_list.length <= 6">GLOBAL TICKETS</div>
                                        </a-sheet>
                                        <a-list class="u-list-condensed pb-0 mt-0" style="max-height: 300px; overflow-y: auto">
                                            <template v-for="(ticket) in local_filtered_tickets">
                                                <a-list-item :key="ticket.id" @click="localLinkProjectTicket(ticket.id)">
                                                    <a-list-item-content class="text-truncate">{{ ticket.title }}</a-list-item-content>
                                                </a-list-item>
                                            </template>
                                        </a-list>
                                    </a-list>
                                </a-menu>
                            </div>
                        </div>
                        <div class="c-dropdown mb-6">
                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-2">Visibility</span>
                            <a-btn small text v-if="local_ticket_item.visibility === 'external'" class="orange darken-2 elevation-0 u-rounded-corners px-2 py-4" @click="check_permission('update') ? localTicketVisibilityToggle('internal') : {}">
                                <a-icon left size="16" class="mr-1" color="white">lock_open</a-icon>
                                <span class="md-body-2 font-weight-medium white--text">Public</span>
                                <a-icon size="12" color="white" class="ml-2" v-if="check_permission('update')">edit</a-icon>
                            </a-btn>
                            <g-confirmation class="d-inline-block" :can-update="check_permission('update')" v-else type="ticket" @action="check_permission('update') ? localTicketVisibilityToggle('external') : {}">
                                <a-btn small v-ripple="check_permission('update')" class="px-2 py-4 elevation-0 white">
                                    <a-icon left class="mr-1" color="grey darken-1" size="16">lock</a-icon>
                                    <span class="md-body-2 font-weight-medium grey--text text--darken-1">Private</span>
                                    <a-icon size="12" color="grey darken-1" class="ml-2" v-if="check_permission('update')">edit</a-icon>
                                </a-btn>
                            </g-confirmation>
                        </div>
                        <div class="c-dropdown mb-6">
                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Assignee</span>
                            <SManualSaveAssigneeDropdown
                                :item="local_ticket_item"
                                :users-list="local_ticket_item.assignees"
                                :project-id="$route.params.id"
                                :can-update="check_permission('update')"
                                :size="36"
                                @click.stop="{}"
                                module-type="Ticket"
                                @assignee-store="localStoreAssignee"
                                @unassigned="localUnassignedUser"
                                @menuOpen="mixinGetCollabs"
                                class="u-flex align-center mr-2"
                                hideExternalConfirmation
                            ></SManualSaveAssigneeDropdown>
                        </div>
                        <div class="c-dropdown mb-5">
                            <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">First Reported by</span>
                            <h3 class="md-body-2 font-weight-medium u-flex align-center flex-wrap grey--text text--darken-2 u-rounded-corners" style="font-family: monospace !important;">
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="font-weight-medium mr-1">
                                            {{ ticket_item.created_by ? local_ticket_item.created_by.name: '' | truncateText(20) }}
                                        </span>
                                    </template>
                                    <div class="u-flex align-center">
                                        <g-avatar :item="local_ticket_item.created_by" :size="28" class="mr-2" :right-space="false" dark-shadow no-border></g-avatar>
                                        <g-profile-info dark :item="local_ticket_item.created_by"></g-profile-info>
                                    </div>
                                </a-tooltip>
                                <a-menu bottom open-on-hover offset-y>
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on">
                                            <g-moment :value="local_ticket_item.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="[•] MMM DD" convert-local></g-moment>
                                        </div>
                                    </template>
                                    <a-sheet class="md-body-2 u-elevation-custom-1 u-rounded-corners primary darken-1 grey--text text--lighten-1 pa-2 px-3">
                                        <g-moment :value="local_ticket_item.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mm A" convert-local></g-moment>
                                    </a-sheet>
                                </a-menu>
                            </h3>
                        </div>
                        <div class="mt-4">
                            <h3 v-if="timer_update_debounce" class="md-body-2 white orange--text text--darken-1 text-center font-weight-medium pa-2 u-rounded-corners">Autosaving</h3>
                            <h3 class="md-body-2 white green--text text-center font-weight-medium white pa-2 u-rounded-corners" v-if="!timer_update_debounce && update_autosaved_at">
                                <g-moment :value="update_autosaved_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="[Autosaved at] hh:mma"></g-moment>
                            </h3>
                        </div>
                    </div>
                </a-flex>
            </a-layout>
        </a-container>

        <a-dialog v-model="dialogCancelPageModal" persistent max-width="400">
            <SCancelChanges
                @success="localTicketChangesClear"
                @close="dialogCancelPageModal = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to cancel?</template>
                <template #successButtonLabel>Yes, cancel</template>
                <template #cancelButtonLabel>No</template>
            </SCancelChanges>
        </a-dialog>

        <a-dialog v-model="dialogMaskUpdateForm" persistent max-width="500">
            <SUpdateMaskedFieldPopup
                :item="selectCustomFieldItem"
                :is-loading="maskCustomFieldLoading"
                :is-modal-open="dialogMaskUpdateForm"
                @action="(mask_value) => localUpdateMaskCustomField(mask_value)"
                @close="localUpdateMaskCustomFieldClose()"
            />
        </a-dialog>

        <a-dialog v-model="dialogMaskUpdateError" persistent max-width="400">
            <SUpdateMaskedFieldErrorPopup
                :item="selectCustomFieldItem"
                @close="localUpdateMaskCustomFieldBack()"
            />
        </a-dialog>

        <!-- User Upgrade Popup -->
        <a-dialog max-width="400" v-model="dialog_user_upgrade_modal">
            <SUserUpgradeModal @close="dialog_user_upgrade_modal = false" />
        </a-dialog>

        <!-- Admin Upgrade Popup -->
        <a-dialog max-width="600" v-model="dialog_admin_upgrade_modal">
            <SAdminUpgradeModal
                :isOpen="dialog_admin_upgrade_modal"
                @upgrade="{}"
                @close="dialog_admin_upgrade_modal = false"
            >
                <template #plan-info-text>
                    Your current <span class="secondary--text font-weight-bold">Starter</span> plan is limited to {{ $plan('active_projects_limit') }} active projects.
                </template>
            </SAdminUpgradeModal>
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
    Bold,
    Italic,
    Strike,
    OrderedList,
    BulletList,
    ListItem,
    History,
} from 'tiptap-extensions'
import marked from 'marked'
import { VCombobox, VAutocomplete } from 'vuetify/lib'
import {
    STicketViewLoader,
    SManualSaveAssigneeDropdown,
    SMetaTicketDropdown,
    STagsInlineForm,
    SUpdateMaskedFieldPopup,
    SUpdateMaskedFieldErrorPopup,
    SUserUpgradeModal,
    SAdminUpgradeModal,
    SCancelChanges,
    SAttachmentSection
} from '@/config/config-shared-components'
import SDragOverlay from '../../components/Attachments/SharedDragOverlay.vue'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'
import mixinSearchCollaborators from '@/mixins/mixin-search-collaborators'
import { Base64 } from 'js-base64'
import mixinLoader from '@/mixins/mixin-module-loading-setup'
import mixinAttachmentsInternal from '@/mixins/mixin-internal-attachment'

export default {
    mixins: [mixinGetOrganizationPlan, mixinSearchCollaborators, mixinLoader, mixinAttachmentsInternal],

    data () {
        return {
            autosave_user_add_status: 'none',
            local_user_edit_item: {},
            loading_ticket: {},
            dialogCancelPageModal: false,
            dialog_user_upgrade_modal: false,
            dialog_admin_upgrade_modal: false,
            dialog_ticket_form: false,
            localShowTicketTagAddInput: false,
            local_selected_user_add_item: null,
            editor_ticket_description: null,
            timer_update_debounce: null,
            update_autosaved_at: null,
            ticket_title: null,
            datepicker_due_date: null,
            datepicker_due_date_edit: null,
            ticket_tag_input: null,
            local_tag_search: null,
            statusList: [],
            priorityList: [],
            typeList: [],
            local_ticket_item: {},
            pageLoading: true,
            snackbar_text: { type: '', content: '' },
            custom_fields_visibility: true,
            autosave_custom_field_timeout: null,
            autosave_custom_field: { id: null, status: 'none' },
            local_custom_value_search: null,
            local_ticket_fields: { 'include': 'status,priority,type,customFieldValues,createdBy,assignees.collaborator.type,tags', 'filter[layout_designer]': 1 },
            local_search_tickets: '',
            ticket_search_mode: true,
            local_remove_link: false,
            dialogMaskUpdateForm: false,
            dialogMaskUpdateError: false,
            maskCustomFieldLoading: false,
            selectCustomFieldItem: {},
            local_custom_field_list: [],
            loading_meta: false,
            datepickerDateValue: {},
            // datepickerMenu: [],
            custom_field_types: [
                { type: 'text', icon: 'text_fields' },
                { type: 'multiline', icon: 'subject' },
                { type: 'dropdown', icon: 'arrow_drop_down_circle' },
                { type: 'mask', icon: 'password' },
                { type: 'date', icon: 'calendar_month' },
                { type: 'tags', icon: 'sell' },
                { type: 'url', icon: 'link' },
                { type: 'number', icon: 'pin' },
            ],
            localLayoutTicketDesignList: [],
            localLayoutTicketDesignList: [],
            localTicketSelectedTags: [],
            localTicketRemovedTags: [],
            local_design_field_list: [],
            localTicketSelectedAssignees: [],
            localTicketRemovedAssignees: [],
            localTicketUpdateErrors: [],
            localCustomFieldValues: [],
            field_counter: 0,
            islocalTicketFieldUpdate: null,
            islocalCustomFieldValueUpdate: null,
            isTicketUpdateLoading: false,

            //Attachment
            filesDraggedOver: false,
            hoveredOnInfo: false,
            FILTER: {
                parent_id: 'filter[parent_id]',
                parent_type: 'filter[parent_type]',
                source_id: 'filter[source_id]',
                source_type: 'filter[source_type]',
                fields: 'fields[attachments]',
                page: 'page'
            },
            attachment_fields: 'id,name,size,extension,order,source_id,source_type,parent_id,parent_type',
            localDroppedFilesList: [],
            localAttachmentFilters: {},
            backupAttachmentList: [],

            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
        }
    },

    components: {
        EditorContent,
        EditorMenuBubble,
        SMetaTicketDropdown,
        SManualSaveAssigneeDropdown,
        VCombobox,
        VAutocomplete,
        STagsInlineForm,
        SUpdateMaskedFieldPopup,
        SUpdateMaskedFieldErrorPopup,
        SUserUpgradeModal,
        SAdminUpgradeModal,
        STicketViewLoader,
        SCancelChanges,
        SAttachmentSection,
        SDragOverlay
    },

    mounted () {
        this.localIndex()
        if (this.check_permission('update')) this.localAddListeners()
    },

    beforeDestroy () {
        this.localRemoveListeners()
    },

    watch: {
        localCheckTicketUpdate (val) {
            if (val) return this.$emit('unsaved')
            this.$emit('saved')
        }
    },

    computed: {
        local_screen_lg () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        local_project_id () {
            return this.$route.params.id
        },

        localCheckSystemFieldsUpdate () {
            return !(this.local_ticket_item && this.local_ticket_item.title !== this.ticket_item.title ||
                    this.local_ticket_item.description !== this.ticket_item.description ||
                    this.local_ticket_item.due_date !== this.ticket_item.due_date ||
                    this.local_ticket_item.status_id !== this.ticket_item.status_id ||
                    this.local_ticket_item.priority_id !== this.ticket_item.priority_id ||
                    this.local_ticket_item.type_id !== this.ticket_item.type_id)
        },

        localCheckTicketUpdate () {
            return (this.islocalTicketFieldUpdate === false || this.islocalCustomFieldValueUpdate === false || this.localCheckAttachmentUpdate)
        },

        local_filtered_tickets () {
            return this.ticket_global_list.filter(item => item.title.toLowerCase().includes(this.local_search_tickets.toLowerCase()))
        },

        localCheckAttachmentUpdate () {
            return !!_.size(this.attachment_upload_list) || !!_.size(this.attachment_delete_list)
        },

        localAttachmentsUpdating () {
            return this.mixinIsLoading('attachment-update') || this.mixinIsLoading('attachment-delete')
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Collaborator', {
            collaborator_list: 'list',
            collaborator_filters: 'filters',
        }),

        ...mapState('ProjectView', {
            project_item: 'item',
        }),

        ...mapState('Ticket', {
            ticket_global_list: 'global_list',
            ticket_item: 'item',
            ticket_filters: 'filters',
            ticket_response: 'response',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
            tag_item: 'item',
            tag_response: 'response'
        }),

        ...mapState('Assignee', {
            assignee_response: 'response',
        }),

        ...mapState('Association', {
            association_response: 'response',
        }),

        ...mapState('CustomField', {
            custom_field_list: 'list',
            custom_field_item: 'item',
            custom_field_form_mode: 'form_mode',
            custom_field_response: 'response',
        }),

        ...mapState('CustomFieldValue', {
            custom_field_value_response: 'response',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),

        ...mapState('Attachment', {
            attachment_list: 'list',
            attachment_local_list: 'local_list',
            attachment_delete_list: 'delete_list',
            attachment_upload_list: 'upload_list',
            attachment_meta: 'meta',
            attachment_response: 'response',
            attachment_delete_response: 'delete_response',
        }),

    },

    methods: {
        async localIndex () {
            this.$vuetify.goTo(0)
            await this.localTicketShow()
            if (this.ticket_response && this.ticket_response.status !== 'success') {
                if (this.ticket_response.code === 404 || this.ticket_response.code === 500) return this.$router.replace({ name: '404' })
            }
            if (this.local_ticket_item && !this.local_ticket_item.ticket_id) this.localGlobalIndex()

            this.localLoadTicketAttachments()
            this.localFetchPreferences()
            this.localFilterLayoutDesign()
            this.localLoadCustomFields()
            this.tag_index({ 'filter[type]': 'ticket_tag' })
            await this.localLoadMetaStatus()
            await this.localLoadMetaPriority()
            await this.localLoadMetaType()
            this.localSetTicketdescription()
            await this.localSetCustomFieldValues()
            await this.localSetInsertableFields()
            this.localClonelayoutDesignList = _.cloneDeep(this.localLayoutTicketDesignList)
            this.pageLoading = false
        },

        async localTicketShow () {
            await this.ticket_show({
                id: this.$route.params.ticket_id,
                params: this.local_ticket_fields
            })
            this.local_ticket_item = _.cloneDeep(this.ticket_item)
            this.localLayoutTicketDesignList = _.cloneDeep(this.ticket_item.layoutDesigner)
        },

        async localGlobalIndex () {
            await this.ticket_global_index({
                'fields[tickets]': 'id,title',
                'filter[is_global]': 1,
                'sort': 'order',
                'count': 9999
            })
        },

        async localLoadTicketAttachments () {
            this.mixinSetLoading('attachment')
            this.attachment_clear_response()
            this.attachment_clear_upload_list()
            this.localSetAttachmentFilters()
            await this.attachment_index({ params: this.localAttachmentFilters })
            this.mixinResetLoading('attachment')
            this.mixinUpdateLocalAttachmentList({ list: this.attachment_list })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localSetAttachmentFilters () {
            this.localAttachmentFilters[this.FILTER.parent_id] = this.local_ticket_item.parent_id
            this.localAttachmentFilters[this.FILTER.parent_type] = 'Project'
            this.localAttachmentFilters[this.FILTER.source_id] = this.$route.params.ticket_id
            this.localAttachmentFilters[this.FILTER.source_type] = 'Ticket'
            this.localAttachmentFilters[this.FILTER.fields] = this.attachment_fields
            this.localAttachmentFilters.count = 5
        },

        async localLoadCustomFields () {
            await this.custom_field_value_clear_item()
            this.custom_field_index({
                'fields[custom_fields]': 'id,name,label,is_active,order,placeholder,type,options,description,user_writable',
                'filter[is_active]': 1,
                'filter[resource_type]': 'Ticket',
            })
        },

        async localLoadMetaStatus () {
            await this.meta_index({
                'filter[type]': 'ticket_status',
                'sort': 'order'
            })
            this.statusList = this.meta_list
        },

        async localLoadMetaPriority () {
            await this.meta_index({
                'filter[type]': 'ticket_priority',
                'sort': 'order'
            })
            this.priorityList = this.meta_list
        },

        async localLoadMetaType () {
            await this.meta_index({
                'filter[type]': 'ticket_type',
                'sort': 'order'
            })
            this.typeList = this.meta_list
        },

        localFocusTicketEditor () {
            if (!this.check_permission('update')) return
            this.local_ticket_item.description = ' '
            this.localSetTicketdescription()
            this.editor_ticket_description.focus()
        },

        localSetTicketdescription () {
            if (this.editor_ticket_description) this.editor_ticket_description.destroy()
            const content = this.ticket_item.description ? this.ticket_item.description : ''
            this.editor_ticket_description = new Editor({
                extensions: [
                    new Bold(),
                    new Italic(),
                    new Strike(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new History(),
                ],
                content: marked(content, { sanitize: true }),
                onUpdate: this.localUpdateDebounced,
                editable: this.check_permission('update')
            })
        },

        localSetCustomFieldValues () {
            if (this.ticket_item) {
                const field_values = _.cloneDeep(this.ticket_item.custom_field_values)
                this.localLayoutTicketDesignList.forEach(section => {
                    section.fields.forEach(field => {
                        const hasFieldValue = field_values.find(value => value.custom_field_id === field.parent.id)
                        if (hasFieldValue) {
                            hasFieldValue.mask_value = field.parent.type === 'mask' ? hasFieldValue.value : ''
                            field.parent = Object.assign(field.parent, { custom_field_value: hasFieldValue })
                        }
                        else field.parent = Object.assign(field.parent, { custom_field_value: { value: '', label: '', mask_value: ''  } })
                    })
                })

                this.localLayoutTicketDesignList.forEach((section, sectionIndex) => {
                    section.fields.forEach((field, index) => {
                        if (field.parent.type === 'date') {
                            let date_value = field.parent.custom_field_value.value
                            this.datepickerDateValue[field.parent.id] = date_value ? this.local_format_date(date_value) : ''
                        }
                    })
                })
            }
        },

        async localTicketCustomFieldUpdate(custom_field, value) {
            await this.localTicketCustomFieldValueUpdate(custom_field, value)
            this.localGetCustomFieldValueUpdate()
        },

        localTicketCustomFieldValueUpdate (custom_field, value) {
            const index = this.localCustomFieldValues.findIndex(item => item.name === custom_field.name)
            if (index === -1) {
                this.localCustomFieldValues.push(_.cloneDeep(custom_field))
            }else {
                this.localCustomFieldValues[index].custom_field_value.value = value
            }
        },

        localSelectTicketdatepicker(custom_field, index) {
            let date_value = custom_field.custom_field_value.value
            this.datepickerDateValue[custom_field.id] = this.local_format_date(date_value)
            this.localTicketCustomFieldUpdate(custom_field, date_value)
        },

        localClearTicketCustomField (field, hide) {
            field.parent.custom_field_value.value = ''
            this.datepickerDateValue[field.parent.id] = ''
            hide()
        },

        local_can_update(field) {
            return !(this.check_permission('update')) || field.parent.user_writable === 0
        },

        async localSelectTicketCustomField(custom_field, value) {
            this.localTicketCustomFieldValueUpdate(custom_field, value)
            this.localGetCustomFieldValueUpdate()
        },

        async localSelectCustomFieldItem(section_id, field_id, custom_field_item) {
            this.selectCustomFieldItem = {}
            this.selectCustomFieldItem = custom_field_item
            this.selectCustomFieldItem.section_id = section_id
            this.selectCustomFieldItem.field_id = field_id
            this.maskCustomFieldLoading = false
            this.dialogMaskUpdateForm = true
        },

        async localUpdateMaskCustomField (value) {
            const index = this.localClonelayoutDesignList.findIndex(item => item.id === this.selectCustomFieldItem.section_id)
            if (index !== -1) {
                const layoutFields = this.localClonelayoutDesignList[index].fields
                const fieldIndex = layoutFields.findIndex(item => item.id === this.selectCustomFieldItem.field_id)
                if (fieldIndex !== -1) {
                    const fieldparent = this.localClonelayoutDesignList[index].fields[fieldIndex].parent
                    if (fieldparent) {
                        this.localClonelayoutDesignList[index].fields[fieldIndex].parent.custom_field_value.value = value
                        this.localClonelayoutDesignList[index].fields[fieldIndex].parent.custom_field_value.mask_value = this.localCustomFieldMaskedString(value, '*')
                        this.localTicketCustomFieldValueUpdate(this.localClonelayoutDesignList[index].fields[fieldIndex].parent, value)
                    }
                }
            }
            this.localGetCustomFieldValueUpdate()
            this.dialogMaskUpdateForm = false
        },

        localCustomFieldMaskedString (value, maskChar) {
            if (value && value.trim()) {
                let maskedString = '';
                for (let i = 0; i < value.length; i++) {
                    maskedString += maskChar;
                }
                return maskedString;
            }
        },

        localUpdateMaskCustomFieldClose () {
            this.dialogMaskUpdateForm = false
        },

        async localCheckUpdateMaskCustomField() {
            this.maskCustomFieldLoading = false
            if (this.custom_field_value_response.status === 'success') {
                await this.localShowUpdateCustomField()
                this.dialogMaskUpdateForm = false
                return
            }
            if(this.custom_field_value_response.server && this.custom_field_value_response.server.errors && this.custom_field_value_response.server.errors.user_writable) {
                this.dialogMaskUpdateForm = false
                this.dialogMaskUpdateError = true
            }
        },

        async localUpdateMaskCustomFieldBack() {
            this.selectCustomFieldItem.user_writable = 0
            this.custom_field_value_update_item(this.selectCustomFieldItem)
            this.dialogMaskUpdateError = false
        },

        async localShowUpdateCustomField () {
           await this.ticket_show({
                id: this.$route.params.ticket_id,
                params: this.local_ticket_fields
            })
            this.localSetCustomFieldValues()
        },

        async localUpdateDebounced (state = null) {
            this.local_clear_debounce_timer()
            if (state) this.local_ticket_item.description = turndownService.turndown(state.getHTML())
            this.localTicketValidationCheck()
        },

        async local_clear_debounce_timer (saved = false) {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                if (saved) {
                    this.update_autosaved_at = moment().format('YYYY-MM-DD HH:mm:ss')
                    setTimeout(() => this.update_autosaved_at = null, 5000)
                }
            }
        },

        async local_close_create_form () {
            this.dialog_ticket_form = false
            // if (this.editor_ticket_description) this.editor_ticket_description.destroy()
        },

        local_edit_title (title) {
            this.ticket_title = title
            setTimeout(() => this.$refs.ref_ticket_title.focus(), 0)
        },

        localTicketDateClear () {
            this.local_ticket_item.due_date = null
            this.localGetFieldUpdate()
        },

        // Link global ticket
        async localLinkProjectTicket (ticket_id, type = null) {
            if (!type) {
                this.local_ticket_item.ticket_id = ticket_id
                this.localGlobalIndex()
                this.localGetFieldUpdate()
                return
            }
            this.local_ticket_item.ticket_id = null
            this.localGetFieldUpdate()
            this.localGlobalIndex()
        },

        async localProjectTicketUnlink () {
            if (this.ticket_item.ticket_id && this.local_ticket_item.ticket_id === null) {
                await this.ticket_unlink_global({ id: this.local_ticket_item.id })
            }
        },

        async localProjectTicketlink () {
            if (this.local_ticket_item.ticket_id && this.ticket_item.ticket_id !== this.local_ticket_item.ticket_id) {
                const ticket_id = this.local_ticket_item.ticket_id
                await this.ticket_unlink_global({ id: this.local_ticket_item.id })
                await this.ticket_link_global({ id: this.local_ticket_item.id, ticket_id })
            }
        },

        local_remove_confirm () {
            this.local_remove_link = true
            setTimeout(() => this.local_remove_link = false, 5000);
        },

        // Metas Update
        async localUpdateTickectMeta ({ meta_id }, field, clear = false) {
            if (clear) return this.local_ticket_item[field + '_id'] = null
            if (this.local_ticket_item[field + '_id'] === meta_id) return
            const list = this.localGetMetaList(field)
            const item = list.find(item => item.id === meta_id)
            this.local_ticket_item[field + '_id'] = meta_id
            this.local_ticket_item[field] = item
            this.localGetFieldUpdate()
        },

        localGetMetaList (field) {
            if (field === 'type') return this.typeList
            if (field === 'priority') return this.priorityList
            if (field === 'status') return this.statusList
        },

        async localProjectTicketDestroy (ticket) {
            await this.ticket_destroy(ticket)
            if (this.ticket_response && this.ticket_response.status !== 'success') return
            this.$router.replace({ name: 'projects-tickets' })
        },

        async localTicketVisibilityToggle (state) {
            this.local_ticket_item.visibility = state
            this.localGetFieldUpdate()
        },

        async localTicketVisibilityUpdate () {
            if (this.local_ticket_item.visibility !== this.ticket_item.visibility) {
                await this.ticket_visibility({id: this.local_ticket_item.id, state : this.local_ticket_item.visibility })
            }
        },

        localFilteredTicketTags () {
            if (this.local_ticket_item && this.local_ticket_item.tags && !this.local_ticket_item.tags) return this.tag_list
            return this.tag_list.filter(tag => !_.find(this.local_ticket_item.tags, { id: tag.id }))
        },

        // Tags CRUD
        async localticketAssociateTagDestroy (data, item) {
            this.localRemoveTicketTag(data, item)
            this.localRemoveSelectedTag(data)
            this.localGetFieldUpdate()
        },

        localRemoveSelectedTag (tag) {
            const index = this.localTicketSelectedTags.findIndex(item => item.id === tag.id)
            if (index !== -1) this.localTicketSelectedTags.splice(index, 1)
        },

        async localRemoveTicketTag (tag, item) {
            const index = item.tags.findIndex(item => item.id === tag.id)
            if (index !== -1) item.tags.splice(index, 1)
            if (tag.association) {
                const remove_tag_index = this.localTicketRemovedTags.findIndex(item => item.id === tag.id)
                if (remove_tag_index === -1) {
                    this.localTicketRemovedTags.push(_.cloneDeep(tag))
                }
            }
        },

        async local_remove_tag (tag, item) {
            const index = item.tags.findIndex(item => item.id === tag.id)
            if (index !== -1) item.tags.splice(index, 1)
        },
        // Tags CRUD ----- END

        // Project --- Start
        localCheckPlanStageUpdate (stageToMove) {
            const { is_admin } = this.user_self || {}
            const { projects_active_count } = this.mixinGetOrgPlan() || {}

            if (this.$plan('active_projects_limit') === -1) return stageToMove()
            if (projects_active_count < this.$plan('active_projects_limit')) return stageToMove()

            if (is_admin) this.dialog_admin_upgrade_modal = true
            else this.dialog_user_upgrade_modal = true
        },
        // Project --- End

        // Extras
        local_set_ticket_loading (field) {
            this.loading_ticket[field + '_id'] = this.ticket_item[field + '_id']
        },

        loading_ticket_item (field, meta_id) {
            return this.loading_ticket[field] === meta_id
        },

        localTicketNotifyResponse (content, type) {
            this.$notify(type, content)
        },

        local_format_date (date) {
            return date !== null ? moment(date).format('MMM D, YYYY') : null
        },

        local_truncate_text (text, range, separator = '...') {
            if (!text) return
            return text.length > range ? text.slice(0, range) + separator : text
        },

        check_permission (type) {
            return this.$can(`poc_tickets.${type}`) && this.$can('projects.update-only') && !this.isTicketUpdateLoading
        },

        localFilterLayoutDesign () {
            this.localLayoutTicketDesignList.forEach((section, sectionIndex) => {
                section.fields.forEach((field, index) => {
                    if (field.parent && !field.parent.is_active) this.localLayoutTicketDesignList[sectionIndex].fields.splice(index, 1)
                })
            })
        },

        localGetCustomFieldTypeItem(field) {
            if(field && field.parent_type === 'TagSection' || field.type === 'Tag') return 'sell'
            const type = field && field.parent && field.parent.type ? field.parent.type : field.type
            const custom_field_item = this.custom_field_types.find(item => item.type === type)
            if (custom_field_item) return custom_field_item.icon
        },

        local_check_fields_empty () {
            const empty_fields = this.localLayoutTicketDesignList.filter((section, sectionIndex) => {
                return (section && section.fields && !section.fields.length)
            })
            return empty_fields && !empty_fields.length
        },

        localSetInsertableFields () {
            this.field_counter = 0
            this.localLayoutTicketDesignList.forEach((section ,index) => {
                section.fields.forEach((item, fieldIndex) => {
                    item.solo_field = false
                    if (item.width === '50%') {
                        ++this.field_counter
                        if(this.field_counter % 2 !== 0 && (fieldIndex+1 < section.fields.length) && (section.fields[fieldIndex+1].width === '100%')) {
                            item.solo_field = true
                            this.field_counter = 0
                        } else if (this.field_counter % 2 === 0) {
                            item.solo_field = false
                            this.field_counter = 0
                        }
                    }
                    if (item.width === '100%') {
                        item.solo_field = false
                        this.field_counter = 0
                    }
                })
            })
        },

        local_show_divider (section) {
            if (section.fields.length === 1 && section.fields[0].width === '50%') return true
            if (section.fields.length > 1 && section.fields[0].width === '50%' && section.fields[1].width !== '50%') return true
        },

        local_show_item_divider (section, field, index) {
            if (index === 0 && section.fields.length === 1 && field.width === '50%') return false
            if (index === 0 && section.fields.length > 1 && field.width === '50%' && section.fields[1].width !== '50%') return false
            return !field.solo_field
        },

        async localGetFieldUpdate () {
            this.islocalTicketFieldUpdate = _.isEqual(this.local_ticket_item, this.ticket_item)
            if (this.islocalTicketFieldUpdate === false) this.$emit('unsaved')
            else this.$emit('saved')
        },

        async localGetCustomFieldValueUpdate () {
            this.islocalCustomFieldValueUpdate = _.isEqual(this.localLayoutTicketDesignList, this.localClonelayoutDesignList)
            if (this.islocalCustomFieldValueUpdate === false) this.$emit('unsaved')
            else this.$emit('saved')
        },

        localStoreAssignee (collaborator) {
            if (collaborator.user) {
                const user = collaborator.user
                const item =  Object.assign(user, { assignee: { user_id: collaborator.user_id } })
                this.local_ticket_item.assignees.push(_.cloneDeep(item))
                const index = this.localTicketSelectedAssignees.findIndex(list => list.id === user.id)
                if (index === -1) {
                    this.localTicketSelectedAssignees.push(_.cloneDeep(item))
                }
                this.localGetFieldUpdate()
            }
        },

        localUnassignedUser (user) {
            const user_id  = user.id
            const assignee = user.assignee
            const index = this.local_ticket_item.assignees.findIndex(item => item.id === user_id)
            if (index !== -1) this.local_ticket_item.assignees.splice(index, 1)
            const select_assignee_index = this.localTicketSelectedAssignees.findIndex(item => item.id === user_id)
            if (select_assignee_index !== -1) this.localTicketSelectedAssignees.splice(index, 1)

            if (assignee && assignee.user_id && assignee.resource_id && assignee.id) {
                const index = this.localTicketRemovedAssignees.findIndex(list => list.id === user.id)
                if (index === -1) {
                    this.localTicketRemovedAssignees.push(_.cloneDeep(user))
                }
            }
            this.localGetFieldUpdate()
        },

        localGetSelectedTag (tag) {
            this.localTicketSelectedTags.push(_.cloneDeep(tag))
        },

        async localBeforeUnloadEvent (e) {
            await this.localCheckTicketPageUpdate()
            if (this.localCheckTicketUpdate) {
                return e.returnValue = 'You have unsaved changes. Are you sure you want to leave?'
            }
        },

        localCheckTicketPageUpdate () {
            this.localGetFieldUpdate()
            this.localGetCustomFieldValueUpdate()
        },

        localTicketUpdateCancel () {
            this.dialogCancelPageModal = true
        },

        localTicketValidationCheck () {
            this.localGetFieldUpdate()
            this.localTicketUpdateErrors = []
        },

        async localTicketUpdate () {
            this.localShowTicketTagAddInput = false
            this.localTicketUpdateErrors = []
            this.isTicketUpdateLoading = true
            const data = await this.localCheckTicketUpdateErros()
            if (data) {
               this.localTicketNotifyResponse('One or more fields are invalid. Ticket could not be saved. Please update and try again.', 'error')
                this.isTicketUpdateLoading = false
                return
            }
            if(this.localCheckSystemFieldsUpdate === false) await this.localUpdateSystemFieldUpdate()
            await this.localUpdateTicketAttachment()
            await this.localAddTicketAssignees()
            await this.localTicketAssigneesRemoved()
            await this.localAddTicketTags()
            await this.localTicketTagsRemoved()
            await this.localTicketCustomFieldValuesUpdate()
            await this.localProjectTicketlink()
            await this.localProjectTicketUnlink()
            await this.localTicketVisibilityUpdate()
            await this.localTicketShow()
            await this.localSetTicketCustomFieldLayoutValues()
            this.islocalTicketFieldUpdate = null
            this.islocalCustomFieldValueUpdate = null
            this.isTicketUpdateLoading = false
            this.localTicketNotifyResponse('Ticket Updated Successfully', 'success')
            this.localSetTicketdescription()
            this.$emit('saved')
        },

        async localSetTicketCustomFieldLayoutValues () {
            await this.localSetCustomFieldValues()
            await this.localSetInsertableFields()
            this.localClonelayoutDesignList = _.cloneDeep(this.localLayoutTicketDesignList)
        },

        async localTicketCustomFieldValuesUpdate () {
            if (this.localCustomFieldValues.length) {
                const customFieldValueItem = {}
                customFieldValueItem.is_global = 1
                customFieldValueItem.resource_type = 'Ticket'
                customFieldValueItem.resource_id = this.local_ticket_item.id
                customFieldValueItem.data = []

                this.localCustomFieldValues.forEach(item => {
                    if (item.custom_field_value) {
                        const valueItem = item.custom_field_value.id ? item.custom_field_value : this.$appendId(item.custom_field_value)
                        if (item.type === 'multiline') valueItem.value = Base64.encode(valueItem.value)
                        valueItem.custom_field_id = item.id
                        valueItem.custom_field_option_id = item.type === 'dropdown' ? this.localGetCustomFieldOptionId(valueItem.value,  item.options) : null
                        valueItem.type = item.type
                        customFieldValueItem.data.push(valueItem)
                    }
                });
               await this.custom_field_value_bulk_store(customFieldValueItem)
               this.localCustomFieldValues = []
            }
        },

        localGetCustomFieldOptionId (value, options) {
            if (options.length) {
                const customFieldOption = options.find(item => item.value === value)
                return customFieldOption ? customFieldOption.id : null
            }
            return null
        },

        localCheckTicketUpdateErros () {
            if (this.local_ticket_item) {
                if (!this.local_ticket_item.title.trim()) {
                    const erroritem = {}
                    erroritem.name = 'title'
                    erroritem.message = 'Required field.'
                    this.localTicketErrorMessageUpdate(erroritem)
                }
                if (this.local_ticket_item.title && this.local_ticket_item.title.trim().length > 1000) {
                    const erroritem = {}
                    erroritem.name = 'title'
                    erroritem.message = 'Only maximum of 1000 characters allowed.'
                    this.localTicketErrorMessageUpdate(erroritem)
                }
                if (this.local_ticket_item.description && this.local_ticket_item.description.trim().length > 50000) {
                    const erroritem = {}
                    erroritem.name = 'description'
                    erroritem.message = 'Only maximum of 50000 characters allowed.'
                    this.localTicketErrorMessageUpdate(erroritem)
                }
            }
            this.localCustomFieldValues.forEach(item => {
                const length = item.type === 'multiline' ? 50000 : 255
                if (!['date','url','number'].includes(item.type) && item.custom_field_value.value && item.custom_field_value.value.trim().length > length) {
                    const customFieldErroritem = {}
                    customFieldErroritem.name = item.name
                    customFieldErroritem.message = `Only maximum of ${length} characters allowed.`
                    this.localTicketErrorMessageUpdate(customFieldErroritem)
                }
                if (item.type === 'url') this.localCheckTicketUpdateUrlErrors(item)
                if (item.type === 'number') this.localTicketNumberValidation(item)
            });
            if (this.localTicketUpdateErrors.length) return true
            return false
        },

        localTicketNumberValidation (item) {
            const value = item.custom_field_value ? item.custom_field_value.value : null
            if (!value) return
            if (isNaN(value) === true) {
                const customFieldErroritem = {}
                customFieldErroritem.name = item.name
                customFieldErroritem.message = `The ${item.label} must be number. `
                this.localTicketErrorMessageUpdate(customFieldErroritem)
                return
            }
            const decimal = /^(?:\d*\.\d{1,2}|\d+)$/
            if (decimal.test(value) === false) {
                const customFieldErroritem = {}
                customFieldErroritem.name = item.name
                customFieldErroritem.message = `Only numbers with up to two decimal places are allowed.`
                this.localTicketErrorMessageUpdate(customFieldErroritem)
            }
            const maxNumber = 1000000000.00
            if (value < 0.00 || value > maxNumber) {
                const customFieldErroritem = {}
                customFieldErroritem.name = item.name
                customFieldErroritem.message = `The ${item.label} must be between 0.00 and ${maxNumber}. `
                this.localTicketErrorMessageUpdate(customFieldErroritem)
            }
        },

        localIsNumber (evt, type) {
            if (type !== 'number') return
            const charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            }
        },

        localCheckTicketUpdateUrlErrors (item) {
            const value = item.custom_field_value.value.trim()
            if (!value) return
            const customFieldErroritem = {}
            customFieldErroritem.name = item.name
            const isValid = this.localIsUrlValid(value)
            const urlLength = 2048
            const maxLengthUrl = value.length > urlLength
            if (!isValid) {
                customFieldErroritem.message = `The url format is invalid.`
                this.localTicketErrorMessageUpdate(customFieldErroritem)
                return
            }
            if (maxLengthUrl) {
                customFieldErroritem.message = `The url maximum of ${urlLength} characters.`
                this.localTicketErrorMessageUpdate(customFieldErroritem)
                return
            }
        },

        localIsUrlValid (value) {
            try {
                const newUrl = new URL(value);
                return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
            } catch (err) {
                return false;
            }
        },

        localGetTicketErrorMessage (name, customField = null) {
            const item = this.localTicketUpdateErrors.find(list => list.name === name)
            if (item) return item.message
            if (customField && this.$response(this.custom_field_value_response, 'value'))  return this.$response(this.custom_field_value_response, 'value')
            return null
        },

        localTicketErrorMessageUpdate (item) {
            const index = this.localTicketUpdateErrors.findIndex(list => list.id === item.name)
            if (index === -1) this.localTicketUpdateErrors.push(_.cloneDeep(item))
            else this.localTicketUpdateErrors[index].message = item.message
        },

        async localUpdateSystemFieldUpdate () {
            await this.ticket_update({
                item: this.local_ticket_item,
                id: this.local_ticket_item.id,
            })
            this.localGetFieldUpdate()
        },

        async localAddTicketTags () {
            if (this.localTicketSelectedTags.length) {
                const tagitem = {}
                tagitem.data = []
                this.localTicketSelectedTags.forEach(item => {
                    tagitem.data.push(this.$appendId({target_id : item.id }))
                });
                tagitem.source_id = this.local_ticket_item.id
                tagitem.source_type = 'Ticket'
                tagitem.target_type = 'Tag'
                tagitem.type = 'ticket_tag'
                await this.association_bulk_store(tagitem)
                this.localTicketSelectedTags = []
            }
        },

        async localTicketTagsRemoved () {
            if (this.localTicketRemovedTags.length) {
                const removedtagitem = {}
                removedtagitem.ids = []
                this.localTicketRemovedTags.forEach(item => {
                    if (item.association) {
                        removedtagitem.ids.push(item.association.id)
                    }
                });
                removedtagitem.source_id = this.local_ticket_item.id
                removedtagitem.source_type = 'Ticket'
                await this.association_bulk_destroy(removedtagitem)
                this.localTicketRemovedTags = []
            }
        },

        async localAddTicketAssignees () {
            if (this.localTicketSelectedAssignees.length) {
                const assigneesitem = {}
                assigneesitem.project_id = this.project_item.id
                assigneesitem.resource_type = 'Ticket'
                assigneesitem.resource_ids = []
                assigneesitem.resource_ids.push(this.local_ticket_item.id)
                assigneesitem.user_ids = []

                this.localTicketSelectedAssignees.forEach(item => {
                    assigneesitem.user_ids.push(item.id)
                });
                await this.assignee_bulk(assigneesitem)
                this.localTicketSelectedAssignees = []
            }
        },

        async localTicketAssigneesRemoved () {
            if (this.localTicketRemovedAssignees.length) {
                const unassigneditem = {}
                unassigneditem.resource_type = 'Ticket'
                unassigneditem.resource_ids = []
                unassigneditem.resource_ids.push(this.local_ticket_item.id)
                unassigneditem.user_ids = []

                this.localTicketRemovedAssignees.forEach(item => {
                    unassigneditem.user_ids.push(item.id)
                });

                await this.assignee_bulk_remove(unassigneditem)
                this.localTicketRemovedAssignees = []
            }
        },

        async localTicketChangesClear () {
            this.local_ticket_item = _.cloneDeep(this.ticket_item)
            this.localSetTicketdescription()
            await this.localSetCustomFieldValues()
            this.localClearAttachmentUpdate()
            this.localClonelayoutDesignList = _.cloneDeep(this.localLayoutTicketDesignList)
            this.localCheckTicketPageUpdate()
            this.localTicketSelectedAssignees = []
            this.localTicketRemovedAssignees = []
            this.localTicketSelectedTags = []
            this.localTicketRemovedTags = []
            this.localCustomFieldValues = []
            this.localTicketUpdateErrors = []
            this.$emit('saved')
            this.dialogCancelPageModal = false
        },

        async localFetchPreferences () {
            await this.preference_clear()
            await this.preference_index({
                'filter[is_listable]': 1,
                'fields[preferences]': 'id,key,label,description,is_active,value,values',
                'sort': 'order',
            })
        },

        // Attachment CRUD

        localAddListeners () {
            window.addEventListener('beforeunload', this.localBeforeUnloadEvent)
            this.mixinAttachmentListeners('add')
        },

        localRemoveListeners () {
            window.removeEventListener('beforeunload', this.localBeforeUnloadEvent)
            this.mixinAttachmentListeners('remove')
        },

        localHandleDragEnter (e) {
            this.filesDraggedOver = true
        },

        localHandleLeave (e) {
            if (this.hoveredOnInfo) return
            this.filesDraggedOver = false
        },

        localHandleDrop (e) {
            if (this.localAttachmentsUpdating || this.pageLoading) return
            const droppedFiles = Array.from(e.dataTransfer.files)
            if (_.size(droppedFiles) > 5) return this.localValidateAttachmentCount()
            this.localDroppedFilesList = _.cloneDeep(droppedFiles)
            this.filesDraggedOver = false
        },

        localValidateAttachmentCount () {
            this.$notify('warning', 'Sorry, you can only upload up to 5 files at a time')
            this.filesDraggedOver = false
        },

        localRemoveAttachmentItem (id) {
            this.mixinRemoveAttachmentItem(id)
        },

        localDeleteAttachmentItem (id) {
            this.mixinDeleteAttachmentItem(id)
        },

        async localUpdateTicketAttachment () {
            this.mixinSetLoading('attachment-update')
            this.attachment_clear_response()
            this.mixinRemoveInvalidFiles()
            await this.localBulkDeleteAttachment()
            await this.localUploadAttachment()
            this.mixinResetLoading('attachment-update')
        },

        async localUploadAttachment () {
            if (!_.size(this.attachment_upload_list)) return
            const formData = new FormData();
            formData.append('parent_id', this.local_ticket_item.parent_id)
            formData.append('parent_type', 'Project')
            formData.append('source_id', this.$route.params.ticket_id)
            formData.append('source_type', 'Ticket')
            formData.append('order', -1)
            this.attachment_upload_list.forEach(file => {
                formData.append('files[]', file)
                formData.append('ids[]', file.id)
            })
            await this.attachment_upload(formData)

            if (!this.$status(this.attachment_response)) return this.mixinHandleAttachmentErrors()
            this.localShowTicketAttachments()
            this.attachment_clear_upload_list()
        },

        async localBulkDeleteAttachment () {
            if (!_.size(this.attachment_delete_list)) return
            this.mixinSetLoading('attachment-delete')
            await this.attachment_bulk_destroy({ids: this.attachment_delete_list})
            if (this.$status(this.attachment_delete_response)) {
                if (!_.size(this.attachment_upload_list)) this.localShowTicketAttachments()
                if (!_.size(this.attachment_local_list) && !_.size(this.attachment_upload_list)) this.localLoadTicketAttachments()
                this.attachment_clear_delete_list()
            }
            this.mixinResetLoading('attachment-delete')
        },

        async localShowTicketAttachments () {
            this.localAttachmentFilters.count = _.size(this.attachment_local_list)
            this.localAttachmentFilters.page = 1
            await this.attachment_index({ mode: 'show', params: this.localAttachmentFilters})
            this.mixinUpdateLocalAttachmentList({ list: this.attachment_list})
            this.backupAttachmentList = _.cloneDeep(this.attachment_list)
        },

        localUpdateAttachmentTransfer (params) {
            this.attachment_clear_response()
            const { list, transferFiles } = params || {}
            const totalUploadList = !!_.size(this.attachment_upload_list) ? [...transferFiles, ...this.attachment_upload_list] : [...transferFiles]
            this.mixinPreserveListChanges({ list: list, type: 'drop_transfer' })
            this.attachment_update_upload_list(totalUploadList)
        },

        async localLoadMoreAttachments () {
            if (this.attachment_meta.total === _.size(this.attachment_list)) return

            this.mixinSetLoading('attachment')

            const defaultCount = 5
            const isListShortOfItem = _.size(this.attachment_list)%5 === 0
            const loadType = isListShortOfItem ? 'load_more' : 'index'
            this.localAttachmentFilters.count = isListShortOfItem ? defaultCount : this.attachment_meta.current_page*defaultCount
            this.localAttachmentFilters[this.FILTER.page] = isListShortOfItem ? this.attachment_meta.current_page+1 : 1
            await this.attachment_index({ mode: loadType, params: this.localAttachmentFilters })

            this.mixinResetLoading('attachment')
            this.mixinPreserveListChanges({ list: this.attachment_list, type: 'load_more' })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localClearAttachmentUpdate () {
            this.mixinUpdateLocalAttachmentList({ list: this.backupAttachmentList })
            this.mixinClearAttachmentUpdate()
        },

        localCheckUrlIcon (field) {
            return !(
                field.parent.custom_field_value.value &&
                this.localIsUrlValid(field.parent.custom_field_value.value) &&
                !this.localGetTicketErrorMessage(field.parent.name) &&
                this.check_permission('update') &&
                field.parent.user_writable === 1
            )
        },

        localLinkOpenNewTab (link) {
            window.open(link, '_blank');
        },

        ...mapActions('Preference', {
            preference_index: 'index',
            preference_clear: 'clear',
        }),

        ...mapActions('Collaborator', {
            collaborator_index: 'index',
            collaborator_clear: 'clear',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_clear_item: 'clear_item'
        }),

        ...mapActions('Assignee', {
            assignee_store: 'store',
            assignee_destroy: 'destroy',
            assignee_bulk: 'bulk_assign',
            assignee_bulk_remove : 'bulk_remove',
        }),

        ...mapActions('Association', {
            association_store: 'store',
            association_destroy: 'destroy',
            association_bulk_store: 'bulk_store',
            association_bulk_destroy: 'bulk_destroy',
        }),

        ...mapActions('CustomField', {
            custom_field_index: 'index',
            custom_field_enabled: 'enabled',
            custom_field_destroy: 'destroy',
            custom_field_reorder: 'reorder',
            custom_field_clear: 'clear',
            custom_field_clear_item: 'clear_item',
        }),

        ...mapActions('CustomFieldValue', {
            custom_field_value_store: 'store',
            custom_field_value_bulk_store : 'bulk_store',
            custom_field_value_update_item: 'update_item',
            custom_field_value_update: 'update',
            custom_field_value_clear_item: 'clear_item',
        }),

        ...mapActions('Ticket', {
            ticket_global_index: 'global_index',
            ticket_update: 'update',
            ticket_show: 'show',
            ticket_select: 'select',
            ticket_link_global: 'link_global',
            ticket_unlink_global: 'unlink_global',
            ticket_visibility: 'visibility',
            ticket_destroy: 'destroy',
            ticket_clear_item: 'clear_item',
            ticket_clear_response: 'clear_response',
        }),

        ...mapActions('Attachment', {
            attachment_index: 'index',
            attachment_upload: 'upload',
            attachment_update_upload_list: 'update_upload_list',
            attachment_remove_upload_item: 'remove_upload_list_item',
            attachment_bulk_destroy: 'bulk_destroy',
            attachment_clear_upload_list: 'clear_upload_list',
            attachment_clear_delete_list: 'clear_delete_list',
            attachment_clear_response: 'clear_response',
        }),
    }
}
</script>

<style lang="scss" scoped>
    .ProseMirror {
        outline: none !important;
    }

    .c-update-container {
        position: sticky !important;
        bottom: 0px;
        background: #FFF !important;
        width: 100%;
    }
    .c-meta-title {
        color: rgba(0,0,0,0.4);
    }

    .c-letter-space {
        letter-spacing: 0.8px;
    }

    .c-section-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .c-field-card__half--preview {
        width: 50% !important;
    }

    .c-field-card__full{
        width: 100% !important;
    }
</style>

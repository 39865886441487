<template>
    <a-main class="mb-16">
        <a-layout wrap align-start v-if="$can('templates.store')">
            <a-flex>
                <a-form @submit.prevent="local_group_store()">
                    <a-text-field
                        v-model="template_group_name"
                        placeholder="Add new note group"
                        prepend-inner-icon="add"
                        class="u-elevation-custom-1"
                        :loading="loading"
                        solo flat hide-details autofocus
                    ></a-text-field>
                </a-form>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_group_response.server && template_group_response.server.errors && template_group_response.server.errors.name && template_group_form_mode == 'add'">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ template_group_response.server.errors.name[0] }}
                </span>
            </a-flex>
        </a-layout>
         <a-responsive class="py-4 my-4" v-if="!loading && template_group_list.length == 0 && !local_loading">
            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
            <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
        </a-responsive>
        <template v-if="local_loading">
            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1">Loading..</h3>
        </template>
        <draggable v-model="template_group_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
            <div class="my-2 mt-1" :class="{ 'js-draggable-file-list-item': $can('templates.update') }" v-for="(template_group, index) in template_group_list" :key="template_group.id">
                <a-container container--fluid grid-list-xl class="pa-4 u-elevation-custom-1 white" :class="[template_group_expanded === index ? '' : 'u-rounded-corners']">
                    <a-layout wrap align-center>
                        <a-flex shrink class="u-cursor-pointer pr-0">
                            <a-icon class="grey--text text--darken-1 js-drag-handle u-icon-nudge" :class="{'u-opacity-30': !$can('templates.update')}">drag_indicator</a-icon>
                        </a-flex>
                        <a-flex xs7 xl9 class="u-cursor-pointer" @click="local_group_toggle(index)">
                            <h2 class="subtitle-1 u-word-wrap">{{ template_group.name }}</h2>
                            <p class="md-body-2 mb-0 grey--text text--darken-2 mt-2" v-if="template_group_expanded !== index">
                                <span>{{ template_group.template_note_count ? template_group.template_note_count : 0 }} Notes<span class="mx-2"></span></span>
                            </p>
                        </a-flex>
                        <a-spacer></a-spacer>
                        <a-flex shrink>
                            <a-tooltip bottom offset-overflow>
                                <template v-slot:activator="{ on }">
                                    <a-btn v-on="on" icon>
                                        <a-icon size="16" class="grey--text text--darken-1">info</a-icon>
                                    </a-btn>
                                </template>

                                <div class="py-1">
                                    <p v-if="template_group.created_by" class="text-body-2 grey--text text--lighten-2 mb-0">Created by
                                        <span class="text-subtitle-2 white--text">{{ template_group.created_by.name }}</span>
                                        <span class="ml-2 text-caption">{{ local_date_output_format(template_group.created_at) }}</span>
                                    </p>

                                    <p v-if="template_group.updated_by && template_group.created_at !== template_group.updated_at" class="text-body-2 grey--text text--lighten-2 mb-0">Last updated by
                                        <span class="text-subtitle-2 white--text">{{ template_group.updated_by.name }}</span>
                                        <span class="text-caption ml-2">{{ local_date_output_format(template_group.updated_at) }}</span>
                                    </p>
                                </div>
                            </a-tooltip>
                            <a-btn icon text color="grey darken-1" @click="local_template_clone_create(template_group.id, template_group)" v-if="$can('templates.store')">
                                <a-icon size="16">content_copy</a-icon>
                            </a-btn>
                            <a-btn icon text color="grey darken-1"  @click="local_group_edit(template_group.id)" v-if="$can('templates.update')">
                                <a-icon size="16">edit</a-icon>
                            </a-btn>
                            <a-menu bottom left offset-y :close-on-content-click="false" max-width="320" v-if="$can('templates.destroy')">
                                <template v-slot:activator="{ on }">
                                    <a-btn icon text color="grey darken-1" v-on="on">
                                        <a-icon size="16">delete</a-icon>
                                    </a-btn>
                                </template>
                                <a-card>
                                    <a-card-text class="pa-4">
                                        <h2 class="md-heading-6 mb-2">Are you sure to delete this note group?</h2>
                                        <p class="md-body-2 mb-4">This will delete all notes under this template and cannot be undone.</p>
                                        <a-btn class="ma-0 elevation-0" color="red" @click="local_group_destroy(template_group.id)" :loading="loading" dark small>
                                            <a-icon size="16" left class="mr-2">delete</a-icon> Delete
                                        </a-btn>
                                    </a-card-text>
                                </a-card>
                            </a-menu>
                            <a-btn icon text color="grey darken-1" @click="local_group_toggle(index)">
                                <a-icon v-if="template_group_expanded === index">arrow_drop_up</a-icon>
                                <a-icon v-else>arrow_drop_down</a-icon>
                            </a-btn>
                        </a-flex>
                    </a-layout>
                </a-container>
                <div v-if="template_group_expanded === index">
                    <a-divider></a-divider>
                    <a-form @submit.prevent="local_store(template_group.id)" v-if="$can('templates.update')">
                        <a-sheet class="grey lighten-4 px-1 u-elevation-custom-1">
                            <a-text-field
                                v-model="local_template_note_item.title"
                                placeholder="Add new note"
                                prepend-inner-icon="add"
                                background-color="grey lighten-4"
                                :loading="loading"
                                solo flat hide-details autofocus
                            ></a-text-field>
                            <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_note_response.server && template_note_response.server.errors && template_note_response.server.errors.title">
                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                {{ template_note_response.server.errors.title[0] }}
                            </span>
                        </a-sheet>
                    </a-form>
                    <a-divider></a-divider>
                    <draggable v-model="template_note_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                        <a-card tile v-for="(template_note, index) in template_note_list" :key="template_note.id" class="u-elevation-custom-1 u-cursor-pointer" :class="{ 'js-draggable-file-list-item': $can('templates.update'), }">
                            <div v-if="template_note.visibility === 'external'" class="c-visibility-bar"></div>
                            <a-divider v-if="index !== 0" class="grey lighten-3" :key="template_note.id + '-divider'"></a-divider>
                            <a-card-text class="pa-0">
                                <a-container container--fluid grid-list-xl class="py-4 px-6">
                                    <a-layout align-center>
                                        <a-flex shrink class="px-0">
                                            <a-icon class="grey--text text--lighten-1 js-drag-handle" :class="{'u-opacity-30': !$can('templates.update')}">drag_indicator</a-icon>
                                        </a-flex>
                                        <a-flex xs6 xl8>
                                            <router-link tag="div" :to="{name: 'master-templates-system-list-notes-view', params: { system_note_id: template_note.id}}">
                                                <h2 class="md-heading-6 mb-0">
                                                    {{ template_note.title }}
                                                </h2>
                                                <p class="md-body-1 mb-0" v-if="template_note.type">
                                                    {{ template_note.type.value }}
                                                </p>
                                            </router-link>
                                        </a-flex>
                                        <a-spacer></a-spacer>
                                        <a-flex shrink>
                                            <g-attachment-count-info
                                                :count="template_note.attachments_count"
                                                class=""
                                                fontSize="14"
                                            />
                                        </a-flex>
                                        <a-flex shrink v-if="$can('templates.update')">
                                            <a-menu bottom right offset-y transition="slide-y-transition" max-height="300" min-width="320" max-width="320">
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on" class="md-body-2 grey lighten-3 pl-2 u-rounded-corners grey--text text--darken-3 d-inline-block">Copy to <a-icon>arrow_drop_down</a-icon></span>
                                                </template>
                                                <a-list class="u-list-condensed">
                                                    <template v-for="note_group in template_group_list">
                                                        <a-list-item :key="note_group.id" @click="local_copy_template_note_group(note_group.id, template_group.id, template_note)">
                                                            <a-list-item-title class="md-body-2" :title="note_group.name">
                                                                {{ note_group.name }}
                                                            </a-list-item-title>
                                                        </a-list-item>
                                                    </template>
                                                </a-list>
                                            </a-menu>
                                        </a-flex>
                                        <a-flex shrink v-if="template_group_list.length > 1 && $can('templates.update')">
                                            <a-menu bottom left offset-y transition="slide-y-transition" max-height="300" min-width="320" max-width="320">
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on" class="md-body-2 grey lighten-3 pl-2 u-rounded-corners grey--text text--darken-3 d-inline-block">Move to <a-icon>arrow_drop_down</a-icon></span>
                                                </template>
                                                <a-list class="u-list-condensed">
                                                    <template v-for="note_group in template_group_list">
                                                        <a-list-item :key="note_group.id" v-if="template_note.template_group_id !== note_group.id" @click="local_change_template_note_group(note_group.id, template_group.id, template_note)">
                                                            <a-list-item-title class="md-body-2" :title="note_group.name">
                                                                {{ note_group.name }}
                                                            </a-list-item-title>
                                                        </a-list-item>
                                                    </template>
                                                </a-list>
                                            </a-menu>
                                        </a-flex>
                                        <a-flex shrink v-if="$can('templates.update')">
                                            <a-tooltip bottom  v-if="template_note.visibility === 'internal' || template_note.visibility === null">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn text icon color="grey" class="mx-0" v-on="on" :disabled="loading" :loading="local_loading_get(template_note.id, 'visibility')" @click="$can('templates.update') ? local_visibility_toggle(template_note.id, template_note.visibility) : ''">
                                                        <a-icon size="16">lock</a-icon>
                                                    </a-btn>
                                                </template>
                                                <span>Private</span>
                                            </a-tooltip>
                                            <a-tooltip bottom v-else>
                                            <template v-slot:activator="{ on }">
                                                <a-btn text icon color="orange darken-2" class="mx-0" v-on="on" v-if="!$can('templates.update')">
                                                    <a-icon size="16">lock_open</a-icon>
                                                </a-btn>
                                                <a-btn text icon color="orange darken-2" class="mx-0" v-on="on" v-if="$can('templates.update')" :loading="local_loading_get(template_note.id, 'visibility')" @click="$can('templates.update') ? local_visibility_toggle(template_note.id, template_note.visibility) : ''">
                                                    <a-icon size="16">lock_open</a-icon>
                                                </a-btn>
                                            </template>
                                            <span>Visible to external collaborators when imported into project.</span>
                                        </a-tooltip>
                                        </a-flex>
                                        <a-flex shrink v-if="$can('templates.destroy')">
                                            <a-btn icon text small color="grey" class="mx-0" @click="local_destroy(template_note)">
                                                <a-icon size="16">delete</a-icon>
                                            </a-btn>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-card-text>
                        </a-card>
                    </draggable>
                </div>

                <!-- <a-container container--fluid grid-list-xl class="pa-0 transparent" v-if="template_group_expanded === index">
                    <a-layout wrap align-center>
                        <a-flex mt-n1>
                        </a-flex>
                    </a-layout>
                </a-container> -->
            </div>
        </draggable>
        <a-dialog v-model="dialog_template_group_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>library_books</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Edit Note Group</h2>
                                    <p class="md-subtitle-2 mb-0">Edit information related to note group.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                    <a-text-field
                                        v-model="template_group_item.name"
                                        placeholder="Enter Group Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_group_response.server && template_group_response.server.errors && template_group_response.server.errors.name && template_group_form_mode == 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_group_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        This will help you identify the group.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_group_update()" :loading="loading" :disabled="loading">Save Group</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_group_form_cancel()" :disabled="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog v-model="dialog_template_group_clone_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>content_copy</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Duplicate Note Group</h2>
                                    <p class="md-subtitle-2 mb-0">Creates a duplicate of the note group.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                    <a-text-field
                                        v-model="local_template_group_clone_item.name"
                                        placeholder="Enter Group Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_note_response.server && template_note_response.server.errors && template_note_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_note_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        This will help you identify the group.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_group_clone()" :loading="loading" :disabled="loading">duplicate</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_group_clone_cancel()" :disabled="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-snackbar v-model="snackbar_status" color="green darken-3" class="text-center" :timeout="4000" bottom center>
            <div class="text-center" style="width: 100%">{{snackbar_text}}</div>
        </a-snackbar>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { diffHuman } from '../../helpers/helper-date-filter'

export default {
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            local_template_note_item: {
                title: null
            },
            snackbar_status: false,
            snackbar_text: '',
            template_group_name: null,
            template_group_expanded: null,
            dialog_template_note_form: false,
            dialog_template_group_form: false,
            dialog_template_group_clone_form: false,
            local_template_group_clone_item: {},
            local_loading: false,
            loading_item: null,
            loading_action: null,
            local_template_group_item: null,
        }
    },
    mounted() {
        this.local_index()
    },
    watch: {
        template_group_expanded(newValue, oldValue) {
            if (newValue != null) {
                this.local_list(this.template_group_list[newValue].id)
            }
        },
        dialog_template_group_form(newValue, oldValue) {
            if (newValue === false) {
                this.template_group_clear_item();
            }
        },
    },
    computed: {
        template_note_list: {
            get() {
                return this.$store.state.TemplateNote.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },
        template_note_item: {
            get () {
                return this.$store.state.TemplateNote.item
            },
            set (item) {
                this.$store.state.TemplateNote.item = item
            }
        },
        template_group_list: {
            get() {
                return this.$store.state.TemplateGroup.list
            },
            set(list) {
                this.local_group_reorder(list)
                this.template_group_expanded = null
            }
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('TemplateGroup', {
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),
        ...mapState('TemplateNote', {
            template_note_filters: 'filters',
            template_note_response: 'response',
            template_note_form_mode: 'form_mode',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('templates.show') == false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.local_loading = true
            await this.template_group_clear()
            await this.local_load_note_template_group()
            this.local_loading = false
        },
        async local_load_note_template_group() {
            await this.template_group_index({
                'filter[type]': 'notes',
                'fields[template_groups]': 'id,name,order,type,created_by_id,updated_by_id,created_at,updated_at',
                'include': 'templateNoteCount,created_by,updated_by',
                'sort': 'order'
            })
        },
        async local_note_template_group_show(id) {
            await this.template_group_show({
                'fields[template_groups]': 'id,name,order,type,created_by_id,updated_by_id,created_at,updated_at',
                'include': 'templateNoteCount,created_by,updated_by',
                'sort': 'order',
                'id': id,
            })
        },
        async local_list(id) {
            await this.template_note_clear()
            await this.template_note_index({
                'filter[template_group_id]': id,
                'fields[template_notes]': 'id,title,order,template_group_id,visibility',
                'sort': 'order',
                'include': 'attachmentsCount'
            })
        },
        async local_create() {
            await this.template_note_clear_item()
            this.dialog_template_note_form = true
        },
        async local_store(id) {
            this.template_note_item = this.local_template_note_item
            this.template_note_item.template_group_id = id
            this.template_note_item.visibility = 'internal'

            await this.template_note_store({
                ...this.template_note_item,
                template_group_id: id,
                'order': -1
            })

            if (this.template_note_response.status === 'success') {
                this.local_note_template_group_show(id)
                await this.template_note_clear_item()
                this.dialog_template_note_form = false
                this.local_template_note_item.title = null
            }
        },
        async local_template_clone_create(group_id, template_group) {
            this.local_template_group_item = template_group
            this.local_template_group_clone_item.name = ''
            this.local_template_group_clone_item.template_group_id = group_id
            this.dialog_template_group_clone_form = true
        },
        local_group_clone_cancel() {
            this.dialog_template_group_clone_form = false
            this.local_group_clear_item()
        },
        local_group_clear_item() {
            this.template_note_clear_item()
            this.local_template_group_clone_item.name = null
            this.local_template_group_clone_item.template_group_id = null
        },
        async local_group_clone() {
            this.local_template_group_clone_item.template_note_count = this.local_template_group_item.template_note_count
            this.local_template_group_clone_item.type = this.local_template_group_item.type
            await this.template_note_group_clone(this.$appendId(this.local_template_group_clone_item))
            if (this.template_note_response.status == 'success') {
                await this.local_group_clone_item_add()
                this.template_group_expanded = null
                this.dialog_template_group_clone_form = false
                this.local_group_clear_item()
                this.local_show_group_message('Note group duplicated successfully')
            }
        },
        async local_group_clone_item_add() {
            const group_item = {}
            group_item.id = this.local_template_group_clone_item.id
            group_item.name = this.local_template_group_clone_item.name
            group_item.template_note_count = this.local_template_group_clone_item.template_note_count
            group_item.type = this.local_template_group_clone_item.type
            await this.template_group_clone_add_item(group_item)
            await this.local_note_template_group_show(group_item.id)
            this.local_template_group_item = null
        },
        async local_update() {
            await this.template_note_update()
            await this.template_note_clear_item()
        },
        async local_destroy(note) {
            await this.template_note_destroy({id: note.id})
            await this.local_note_template_group_show(note.template_group_id)
            this.template_group_list[this.template_group_expanded].template_notes_count = this.template_note_list.length
        },
        async local_reorder(list) {
            await this.template_note_reorder({list: list})
            this.local_load_note_template_group()
        },
        async local_group_reorder(list) {
            await this.template_group_reorder({list: list})
            this.local_load_note_template_group()
        },
        async local_group_store() {
            this.template_group_item.name = this.template_group_name;
            await this.template_group_store({
                name: this.template_group_item.name,
                type: 'notes',
                model: 'template',
                'order': -1
            })

            if (this.template_group_response.status == 'success') {
                this.template_group_expanded = null
                await this.local_note_template_group_show(this.template_group_item.id)
                await this.template_group_clear_item()
                this.dialog_template_group_form = false
                this.template_group_name = ''
            }
        },
        async local_group_update() {
            await this.template_group_update({
                ...this.template_group_item
            })
            if (this.template_group_response.status === 'success') {
                this.dialog_template_group_form = false
                await this.local_note_template_group_show(this.template_group_item.id)
                this.template_group_clear_item()
            }
        },
        async local_change_template_note_group(new_group_id, old_group_id, template_note) {
            this.snackbar_status = false
            template_note.template_group_id = new_group_id

            this.template_note_select_item({ data: template_note })
            await this.template_note_group_move({ ...template_note })
            if (this.template_note_response.status === 'success') {
                await this.local_update_group_meta_counts({ group_id: new_group_id, increase: true })
                await this.local_update_group_meta_counts({ group_id: old_group_id, increase: false })
                this.local_note_template_group_show(new_group_id)
                this.local_note_template_group_show(old_group_id)
                this.local_show_group_message('Note moved successfully')
            }
        },
        async local_copy_template_note_group(new_group_id, old_group_id, template_note) {
            this.snackbar_status = false
            template_note = _.cloneDeep(template_note)
            template_note.template_group_id = new_group_id
            template_note.old_template_group_id = old_group_id

            await this.template_note_group_copy({
                template_note_id: template_note.id,
                template_group_id: new_group_id,
                old_template_group_id: old_group_id
            })
            if (this.template_note_response.status === 'success') {
                await this.local_update_group_meta_counts({ group_id: new_group_id, increase: true })
                this.local_note_template_group_show(new_group_id)
                this.local_show_group_message('Note copied successfully')
            }
        },
        local_show_group_message(text) {
            this.snackbar_text = `${ text }`
            this.snackbar_status = !this.snackbar_status
        },
        async local_group_form_cancel() {
            this.dialog_template_group_form = false
            this.template_group_clear_item()
        },
        local_group_edit(id) {
            this.template_group_select({id: id})
            this.dialog_template_group_form = true
        },
        async local_group_destroy(id) {
            this.template_group_destroy({id: id})
            this.template_group_expanded = null
        },
        local_group_toggle(index) {
            if (this.template_group_expanded === index) {
                this.template_group_expanded = null
            }
            else {
                this.template_group_expanded = index
            }
        },

        async local_visibility_toggle(id, status) {
            await this.local_loading_set(id, 'visibility')
            await this.template_note_select({id: id})
            this.template_note_item.visibility = status === 'external' ? 'internal' : 'external'
            this.template_note_item.state = status === 'external' ? 'internal' : 'external'
            await this.template_note_visibility(this.template_note_item)
            await this.local_loading_clear()
            if (this.template_note_response.status === 'success') {
                await this.local_note_template_group_show(this.template_note_item.template_group_id)
                this.template_note_clear_item()
            }
        },
        async local_loading_set(id, action) {
            this.loading_item = id
            this.loading_action = action
        },
        async local_loading_clear() {
            this.loading_item = null
            this.loading_action = null
        },
        local_loading_get(id, action) {
            return this.loading && this.loading_item == id && this.loading_action == action
        },

        async local_update_group_meta_counts ({ group_id, increase = false }) {
            const index = this.template_group_list.findIndex(item => item.id === group_id)
            if (index === -1) return

            const temp_group = this.template_group_list[index]
            temp_group.group_id = group_id
            if(!temp_group.template_note_count) temp_group.template_note_count = 0

            increase ? temp_group.template_note_count++ : temp_group.template_note_count--
            this.template_change_group_update(temp_group)
        },
        local_date_output_format (date) {
            return diffHuman(date)
        },

        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_show: 'show',
            template_change_group_update: 'change_group_update',
            template_group_clone_add_item: 'add_group_item',
            template_group_store: 'store',
            template_group_update: 'update',
            template_group_reorder: 'reorder',
            template_group_select: 'select',
            template_group_destroy: 'destroy',
            template_group_clear: 'clear',
            template_group_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateNote', {
            template_note_index: 'index',
            template_note_show: 'show',
            template_note_visibility: 'visibility',
            template_note_store: 'store',
            template_note_group_move: 'group_note_move',
            template_note_group_copy: 'group_copy_store',
            template_note_group_clone: 'group_clone_store',
            template_note_select_item: 'select_item',
            template_note_select: 'select',
            template_note_update: 'update',
            template_note_reorder: 'reorder',
            template_note_destroy: 'destroy',
            template_note_clear: 'clear',
            template_note_clear_item: 'clear_item',
        }),
    },
}
</script>

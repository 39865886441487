<template>
    <a-container container--fluid grid-list-xl class="pa-6 px-2 py-0" v-if="checkRoutesAvoided" style="max-width: 1200px; margin-left: auto; margin-right: auto;">
        <a-layout align-start>
            <a-flex xs12 class="c-poc-overview-tabs">
                <div class="u-flex align-center">
                    <a-tabs background-color="neutral" slider-color="primary" :show-arrows="false">
                        <a-tab :ripple="false" class="mx-2" :to="{name: (!(isExternalUser) ? 'external-' : '') + 'projects-overview'}">
                            <a-icon size="16" class="mr-2">info</a-icon> <span class="md-body-2">Overview</span>
                        </a-tab>
                        <a-tab :ripple="false" class="mx-2" :to="{name: ((isExternalView) ? 'external-' : '') + 'projects-usecases'}" v-if="!(isExternalUser) ? ($can('usecases.index') && can_external_mixin('usecases.index')) : $can('usecases.index')">
                            <a-icon size="16" class="mr-2">assignment</a-icon> <span class="md-body-2">Scope</span>
                        </a-tab>
                        <a-tab :ripple="false" class="mx-2" :to="{name: (!(isExternalUser) ? 'external-' : '') + 'projects-tasks'}" v-if="!(isExternalUser) ? ($can('tasks.index') && can_external_mixin('tasks.index')) : $can('tasks.index')">
                            <a-icon size="16" class="mr-2">check_circle</a-icon> <span class="md-body-2">Tasks</span>
                        </a-tab>
                        <a-tab :ripple="false" class="mx-2" :to="{name: (!(isExternalUser) ? 'external-' : '') + 'projects-documents'}" v-if="!(isExternalUser) ? ($can('documents.index') && can_external_mixin('documents.index')) : $can('documents.index')">
                            <a-icon size="16" class="mr-2">description</a-icon> <span class="md-body-2">Docs</span>
                        </a-tab>
                        <a-tab :ripple="false" class="mx-2" :to="{name: (!(isExternalUser) ? 'external-' : '') + 'projects-links'}" v-if="!(isExternalUser) ? ($can('links.index') && can_external_mixin('links.index')) : $can('links.index')">
                            <a-icon size="16" class="mr-2">link</a-icon> <span class="md-body-2">Links</span>
                        </a-tab>
                        <a-tab :ripple="false" class="mx-2" :to="{name: (!(isExternalUser) ? 'external-' : '') + 'projects-notes'}" v-if="!(isExternalUser) ? ($can('notes.index') && can_external_mixin('notes.index')): $can('notes.index')">
                            <a-icon size="16" class="mr-2">notes</a-icon> <span class="md-body-2">Notes</span>
                        </a-tab>
                        <a-tab :ripple="false" class="mx-2" :to="{name: (!(isExternalUser) ? 'external-' : '') + 'projects-tickets'}" v-if="!(isExternalUser) ? ($can('poc_tickets.index') && can_external_mixin('poc_tickets.index')) : $can('poc_tickets.index')">
                            <a-icon size="16" class="mr-2">account_tree</a-icon> <span class="md-body-2">Tickets</span>
                        </a-tab>
                    </a-tabs>
                </div>
                <a-divider class="mx-4 grey lighten-2"></a-divider>
            </a-flex>
        </a-layout>
    </a-container>
</template>

<script>
import ExternalPermissionMixin from '@/mixins/mixin-external-view-permission'
import { mapState } from 'vuex'

export default {
    mixins: [ExternalPermissionMixin],
    computed: {
        isExternalView () {
            return this.$route.meta.view === 'external'
        },
        isExternalUser () {
            return this.user_self && this.user_self.scope === 'external'
        },
        checkRoutesAvoided () {
            const { name } = this.$route
            const blacklist = ['projects-usecases-view', 'projects-notes-view', 'projects-tickets-view', 'external-projects-usecases-view', 'external-projects-notes-view', 'external-projects-tickets-view']
            return !blacklist.includes(name)
        },
        ...mapState('User',{
            user_self: 'self'
        })
    }
}
</script>

import Vue from 'vue'
import { orderBy, size } from 'lodash'

export const ScoreColor = (percentage, meta) => {
    return {
        get: () => {
            let color = '#BDBDBD'
            if (!percentage) return color

            const metaList = orderBy(meta, 'percentage', 'asc')
            if (percentage > 0 && size(metaList)) {
                const list = metaList.filter(i => percentage > i.percentage)
                color = metaList[size(list)].color
            }

            return Vue.prototype.$color(color, 'color_bg')
        }
    }
}

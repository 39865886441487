import Vue from 'vue'
import { pascalToKebab } from '@/helpers/helper-case-convertor'

Vue.directive('testBtn', {
    inserted: function (el, binding, vnode) {
        const actions = ['save', 'create', 'edit', 'delete', 'remove', 'cancel', 'close']
        const componentName = pascalToKebab(vnode)
        const { arg, value } = binding
        const type = 'btn'
        const actionType = actions.find(i => binding.modifiers[i]) ?? Object.keys(binding.modifiers)[0]

        if (actions.includes(actionType)) {
            return el.dataset['testElement'] = type + (componentName ? '-' + componentName : '') + (arg ? '-' + arg : '') + (actionType ? '-' + actionType : '') // btn-competitor-save
        }

        el.dataset['testElement'] = type + (componentName ? '-' + componentName : '') + (arg ? '-' + arg : '') + (actionType ? '-' + actionType : '') + (value ? '-' + value : '') // btn-competitors || btn-competitors-{modifiers}
    }
})

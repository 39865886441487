<template>
    <a-layout align-center>
        <a-flex shrink>
            <a-tabs class="mb-0" background-color="neutral" slider-color="primary">
                <a-tab ripple class="mx-2" :to="{name: 'projects-channels-slack'}">
                    <span class="md-body-2">Slack</span>
                </a-tab>
                <!-- <a-tab ripple class="mx-2" :to="{name: 'projects-channels-jira'}" disabled>
                    <span class="md-body-2">JIRA (Coming Soon!)</span>
                </a-tab> -->
            </a-tabs>
        </a-flex>
    </a-layout>
</template>

<script>
export default {

}
</script>

import generateBaseUrl from '@/config/config-api'
const axios = generateBaseUrl(process.env.VUE_APP_CORE_API_URL, 'system')
const api_endpoint = '/colors';

export default {
    namespaced: true,
    state: {
        list: [],
        response: [],
        defaults: {
            list: [],
            response: [],
        },
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        async index(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data: response } } = await axios.get(api_endpoint)
                this.dispatch('Interface/loader_stop')
                context.commit('UPDATE_LIST', response)
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        }
    },

    getters: {
        list: state => state.list
    }
}

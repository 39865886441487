export default {
    install(Vue) {
        Vue.prototype.$response = (item = null, field = null) => {
            if ((!item && !field) || (!item || !field)) return null
            if (item && item.server && item.server.errors && item.server.errors[field]) {
                return item.server.errors[field][0]
            }
            return null
        }
        Vue.prototype.$status = (item = null, field = null) => {
            if (!item) return new Error('Expects atleast 1 parameter')
            return field ? item[field] : item.status === 'success'
        }
    }
}

<template>
    <div
        :style="[
            currentResizeEl ? { userSelect: 'none' } : '',
            { height: `calc(100vh - ${parentHeightControl}px)` }
        ]"
        ref="refParent"
        class="u-no-select pb-16 u-relative"
        style="overflow: auto; scroll-behaviour: smooth"
    >
        <div :style="[{ minWidth: getFlexBasis }]" :class="['u-relative', 'c-magic-table']">
            <!-- Header -->
            <div class="c-magic-table__header-row white" :style="[
                { display: 'flex', position: 'sticky', left: '0px', top: '0px', zIndex: 5 },
                { borderBottom: '1px solid rgba(0,0,0,0.1) !important' },
                { borderTop: '1px solid rgba(0,0,0,0.1) !important' },
            ]">
                <!-- Header sticky cell -->
                <div
                    data-col-position="0"
                    class="white py-3 md-body-2 grey--text text--darken-1 c-magic-table__header-cell c-magic-table__header-cell--sticky"
                    :style="[
                        { position: 'sticky', left: '0px' },
                        { flexBasis: localGetFirstCol ? (localGetFirstCol.minWidth === 'full' ? '100%' : localGetFirstCol.minWidth + 'px') : '500px', zIndex: 3, flexShrink: 0 },
                        scrolledLeft ? { boxShadow: '0px 2px 15px 0px rgba(0,0,0,0.2)', clipPath: 'inset(0px -15px 0px -15px)' } : '',
                        localSelectedColumns.length > 1 ? { borderRight: '1px solid rgba(0,0,0,0.1) !important' } : '',
                        currentResizeEl ? { userSelect: 'none' } : ''
                    ]"
                >
                    <div class="u-flex-center-y">
                        <div class="" style="min-width: 40px; height: 100%"></div>
                        {{ localGetFirstCol ? localGetFirstCol.label : '' }}
                    </div>
                </div>

                <!-- Header non-sticky cell -->
                <template v-if="columns.length > 1">
                    <div class="c-magic-table__header-non-sticky" :style="[{ position: 'relative', flexBasis: getFlexBasis, display: 'flex' }]">
                        <div
                            v-for="(col, index) in localNonStickCols"
                            :data-col-position="index + 1"
                            class="white u-relative c-magic-table--header-drag c-magic-table__header-cell"
                            :key="col.slug + '-header'"
                            :style="[
                                localGetBorder(col, index),
                                { minWidth: col.minWidth === 'full' ? '100%' : (col.minWidth + 'px' || '200px'), cursor: 'grab' },
                                currentResizeEl ? { userSelect: 'none' } : ''
                            ]"
                        >
                            <template v-if="col.slug !== 'add-col'">
                                <div class="px-3 py-3 md-body-2 grey--text text--darken-1">{{ col.label }}</div>
                            </template>
                            <template v-else>
                                <template v-if="hideAddColumn">
                                    <a-card flat tile min-width="50" max-width="50" class="u-wfull text-left u-flex-center-y px-3 py-3 u-wfull" height="100%">
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </a-card>
                                </template>
                                <a-menu v-else bottom offset-y max-width="200" content-class="white">
                                    <template v-slot:activator="{ on }">
                                        <a-card v-on="on" flat tile class="u-wfull text-left u-flex-center-y px-3 py-3 u-wfull" height="100%">
                                            <a-icon size="20" color="grey darken-1">add</a-icon>
                                        </a-card>
                                    </template>
                                    <a-list class="u-list-std c-tiny-scroll" style="max-height: 250px">
                                        <a-list-item @click="localToggleColumn(colItem)" v-for="colItem in localColumnsSelectMenu" :key="colItem.slug">
                                            <div class="u-flex-center-y" style="row-gap: 12px;">
                                                <a-checkbox :input-value="colItem.selected" class="ma-0 pa-0" @click.stop="localToggleColumn(colItem)" :ripple="false" hide-details dense></a-checkbox>
                                                <span class="md-subtitle-2 grey--text text--darken-3">{{ colItem.label }}</span>
                                            </div>
                                        </a-list-item>
                                    </a-list>
                                </a-menu>
                            </template>
                        </div>
                    </div>
                </template>
            </div>

            <!-- Items -->
            <template v-for="(row, rowIndex) in listItems">
                <div
                    :key="row.id"
                    :ref="`refRow-${row._order}`"
                    :data-row-order="row._order"
                    :style="[
                        { display: 'flex', borderBottom: '1px solid rgba(0,0,0,0.1) !important' },
                        currentResizeEl ? { userSelect: 'none' } : ''
                    ]"
                    :class="[{ 'js-task-draggable-item': !bulkMode }]"
                    @dragstart="localListDragStart($event, row, rowIndex)"
                    @dragover.prevent="localListDragOver($event, row, rowIndex)"
                    @dragenter.prevent="localListDragEnter($event, row, rowIndex)"
                    @dragleave="localListDragLeave($event, row, rowIndex)"
                    @drop="localListDragDrop($event, row, rowIndex)"
                    class="u-wfull c-magic-table__row"
                >
                    <!-- Row item sticky cell -->
                    <div
                        data-cell-slug="title"
                        class="c-magic-table__cell c-magic-table__cell-sticky"
                        :class="[
                            { 'u-cursor-pointer': canUpdate },
                            { 'c-cell-row-hovered': localIsRowHovered(row._order) && !(localIsRowSelected(row.id) && selectedRows.length) },
                            { 'c-cell-keyed': (localIsRowSelected(row.id) && selectedRows.length) },
                        ]"
                        :ref="`refCell-${rowIndex}-0-id-${row.id}`"
                        @click="canUpdate ? localEditCell($event, row, 0) : ''"
                        :style="[
                            localIsCellHovered(row._order, 0) ?
                                (localIsRowSelected(row.id) && selectedRows.length) ?
                                    { backgroundColor: 'rgb(209, 234, 253) !important' } :
                                    { backgroundColor: 'rgb(229, 244, 255) !important' }
                                : '',
                            localIsCellEdited(row._order, 0) ? { backgroundColor: 'rgb(255, 255, 255) !important' } : '',
                            scrolledLeft ? { boxShadow: '0px 2px 15px 0px rgba(0,0,0,0.2)', clipPath: 'inset(0px -15px 0px -15px)' } : '',
                            { position: 'sticky', left: '0px' },
                            localSelectedColumns.length > 1 ? { borderRight: '1px solid rgba(0,0,0,0.1) !important' } : '',
                            { flexBasis: localGetFirstCol ? (localGetFirstCol.minWidth === 'full' ? '100%' : localGetFirstCol.minWidth + 'px') : '500px', zIndex: 3, flexShrink: 0 },
                        ]"
                    >
                        <div
                            class="u-flex-center-y u-no-select"
                            style="height: 56px;"
                            @click.stop="!avoidValueToggle.includes('title') && canUpdate ? localEditCell($event, row, 0, localGetFirstCol) : ''"
                        >
                            <div class="c-magic-table__row-drag-select u-flex-center" :class="[{ 'u-no-select': bulkMode }]" style="min-width: 40px; height: 100%" @click.stop="{}">
                                <slot name="itemIcon" v-if="!bulkMode" handle="js-task-drag-handle" :canUpdate="canUpdate" :item="row" :isHovered="row.id === itemHoverElementId"></slot>
                            </div>
                            <slot
                                v-if="localIsCellClicked(row, 0)"
                                name="cellItem"
                                colSlug="title"
                                :item="row"
                                :column="localGetFirstCol"
                                :closeCell="localRemoveCellSelection"
                                :isActive="localIsCellClicked(row, 0)"
                                @click.stop="!avoidValueToggle.includes('title') && canUpdate ? localEditCell($event, row, 0, localGetFirstCol) : ''"
                            >
                                {{ row.title || ' - ' }}
                            </slot>
                            <slot
                                v-else
                                name="cellDisplay"
                                colSlug="title"
                                :item="row"
                                :column="localGetFirstCol"
                                :closeCell="localRemoveCellSelection"
                                :switchDisplay="($event) => !avoidValueToggle.includes('title') && canUpdate ? localEditCell($event, row, 0, localGetFirstCol) : ''"
                            >
                                {{ row.title || ' - ' }}
                            </slot>
                        </div>
                    </div>

                    <!-- Row items non-sticky cell -->
                    <template v-if="columns.length > 1">
                        <div :style="[{ position: 'relative', flexBasis: getFlexBasis, display: 'flex' }]">
                            <div
                                v-for="(col, colIndex) in localNonStickCols"
                                class="c-magic-table__cell"
                                :class="[
                                    { 'u-cursor-pointer': canUpdate },
                                    { 'c-cell-row-hovered': localIsRowHovered(row._order) && !(localIsRowSelected(row.id) && selectedRows.length) },
                                    { 'c-cell-keyed': (localIsRowSelected(row.id) && selectedRows.length) },
                                ]"
                                :data-cell-slug="col.slug"
                                :ref="`refCell-${row._order}-${col._order}-id-${row.id}`"
                                :key="col.slug + '-cell'"
                                :style="[
                                    localIsCellHovered(row._order, colIndex + 1) ?
                                        (localIsRowSelected(row.id) && selectedRows.length) ?
                                            { backgroundColor: 'rgb(209, 234, 253) !important' } :
                                            { backgroundColor: 'rgb(229, 244, 255) !important' }
                                        : '',
                                    localIsCellEdited(row._order, colIndex + 1) ? { backgroundColor: 'rgb(255, 255, 255) !important' } : '',
                                    localGetBorder(col, colIndex),
                                    { minWidth: col.minWidth + 'px' || '200px' },
                                    { maxWidth: col.minWidth + 'px' || '200px' },
                                ]"
                            >
                                <div
                                    v-if="col.slug !== 'add-col'"
                                    class="u-no-select u-wfull u-flex align-stretch"
                                    :style="[{ height: '56px' }]"
                                >
                                    <slot
                                        v-if="localIsCellClicked(row, colIndex + 1)"
                                        name="cellItem"
                                        :item="row"
                                        :colSlug="col.slug"
                                        :column="col"
                                        :closeCell="localRemoveCellSelection"
                                        :isActive="localIsCellClicked(row, colIndex + 1)"
                                        @click.stop="col.slug !== 'add-col' && !avoidValueToggle.includes(col.slug) && canUpdate ? localEditCell($event, row, colIndex + 1, col) : ''"
                                    >
                                        {{ row[col.slug] || ' - ' }}
                                    </slot>
                                    <slot
                                        v-else
                                        name="cellDisplay"
                                        :item="row"
                                        :colSlug="col.slug"
                                        :column="col"
                                        :closeCell="localRemoveCellSelection"
                                        :switchDisplay="($event) => col.slug !== 'add-col' && !avoidValueToggle.includes(col.slug) && canUpdate ? localEditCell($event, row, colIndex + 1, col) : ''"
                                    >
                                        {{ row[col.slug] || ' - ' }}
                                    </slot>
                                </div>
                                <template v-else>&nbsp;&nbsp;</template>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </div>

        <!-- Add task input -->
        <div
            v-if="canUpdate"
            @click="localEmitCreate()"
            class="u-sticky u-flex"
            :style="[{
                borderBottom: '1px solid rgba(0,0,0,0.1)',
                height: '50px',
                left: '0px',
                cursor: 'text'
            }]"
        >
            <div class="white u-sticky u-hfull u-wfull u-flex-center-y grey--text px-10" :style="[{ left: '0px' }]">
                Add new milestone...
            </div>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { mapState } from 'vuex'

export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        columns: {
            type: Array,
            required: true
        },
        avoidValueToggle: {
            type: Array,
            default: () => []
        },
        hideAccordian: {
            type: Boolean,
            default: false
        },
        hideAddColumn: {
            type: Boolean,
            default: false
        },
        selectAll: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        hasDeleteConfirmation: {
            type: Boolean,
            default: false
        },
        viewMode: {
            type: String,
            default: ''
        },
        parentHeightControl: {
            type: Number | String,
            default: '236'
        },
    },

    data () {
        return {
            /* Props */
            scrolledLeft: false,
            addMilestoneTimeout: null,
            addedMilestoneIndex: null,
            currentEditCell: null,
            currentHoverCell: null,
            selectedRows: [],
            bulkMode: false,
            shiftStartSelect: null,
            shiftEndSelect: null,
            hasDragSelectStarted: false,
            currentScrollCellPos: null,
            addColumnStub: { label: 'add-col', slug: 'add-col', selected: false, minWidth: 100 },
            currentColumns: [],
            milestoneCount: [{ id: 'milestone-1', title: 'Milestone' }, { id: 'milestone-2', title: 'Milestone 2' }, { id: 'milestone-3', title: 'Milestone 3' }],
            currentResizeEl: null,
            dragStart: false,
            draggedElement: null,
            componentWidth: 'auto',
            keyDownEvt: null,
            rootElement: null,
            componentRefs: null,
            itemHoverElementId: null,
            currentFocusedRow: null,
        }
    },

    watch: {
        selectedRows: {
            handler (val) {
                this.$emit('bulkSelect', val, this.localClearAll)
                this.bulkMode = !!(val.length > 0)
            },
            deep: true,
            immediate: true
        },

        selectAll: {
            handler (value) {
                this.localSelectAllToggle(value)
            },
            immediate: true
        },

        items: {
            handler (val) {
                this.localFetchRefs()
                if (this.canUpdate) this.localAttachMouseHandlers()
                const index = this.listItems.findIndex(i => i._mode === 'create')
                if (index === -1) return

                setTimeout(() => this.localAddRowForEdit(this.listItems[index]), 80)
            },
            deep: true
        }
    },

    async mounted () {
        document.addEventListener('click', this.localMouseClick)
        document.addEventListener('keydown', this.localKeyDown)
        if (this.$el) {
            this.$el.addEventListener('scroll', this.localCheckScroll)
            this.$el.addEventListener('resize', this.localTrackComponentWidth)
        }
        await this.localIndex()
    },

    beforeDestroy () {
        document.removeEventListener('click', this.localMouseClick)
        document.removeEventListener('keydown', this.localKeyDown)
        if (this.$el) {
            this.$el.removeEventListener('scroll', this.localCheckScroll)
            this.$el.removeEventListener('resize', this.localTrackComponentWidth)
            const rowItems = [...this.$el.querySelectorAll('.c-magic-table__row')]
            rowItems.forEach(row => row.addEventListener('mousedown', this.localIntiateDragSelect))
        }
    },

    computed: {
        listItems () {
            return this.items.map((item, index) => {
                item['_order'] = index
                return item
            })
        },

        totalColumnsList () {
            return this.columns.map((item, index) => {
                if (!item.hasOwnProperty('minWidth')) item['minWidth'] = index === 0 ? 500 : 200
                if (!item.hasOwnProperty('selected')) item['selected'] = true
                item['_order'] = index
                return item
            })
        },

        getFlexBasis () {
            //return ((this.localSelectedColumns.length - 1) * 200) + 500 + 200 + 'px'
            // if (noTable) return '100%'
            return (this.localSelectedColumns.reduce((prev, current) => current.minWidth + prev, 0) + 500) + 'px'
        },

        localTotalSelectedCols () {
            return this.currentColumns.filter(i => i.selected)
        },

        localNonStickCols: {
            get () {
                return this.localSelectedColumns
            },
            set (list) {
                const firstCol = this.currentColumns[0]
                const orderedList = list.map((item, index) => {
                    item['_order'] = index + 1
                    return item
                })
                orderedList.unshift(firstCol)
                this.currentColumns = orderedList
                this.$emit('updateColumns', this.localSelectedColumns.filter(i => i.slug !== 'add-col'))
            }
        },

        localSelectedColumns () {
            return this.currentColumns.slice(1).filter(item => item.selected || item.slug === 'add-col')
        },

        localGetFirstCol () {
            return this.currentColumns && this.currentColumns[0]
        },

        localColumnsSelectMenu () {
            const list = _.cloneDeep(this.currentColumns)
            list.splice(-1)
            return list
        },

        localCellIsEdited () {
            return this.currentEditCell && _.size(Object.keys(this.currentEditCell))
        },

        localKeyIsCmdCtrlShift () {
            return this.configKeydown && ((this.configKeydown.ctrlKey || this.configKeydown.metaKey) && this.configKeydown.shiftKey)
        },

        ...mapState('Configuration', {
            configKeydown: 'keydown'
        }),
    },

    methods: {
        localIndex () {
            this.localFetchRefs()
            this.currentColumns = _.cloneDeep(this.totalColumnsList)
            this.currentColumns.push(this.addColumnStub)
            if (this.canUpdate) this.localAttachMouseHandlers()
            this.componentWidth = this.$el.clientWidth + 'px'
        },

        localFetchRefs () {
            if (this.$refs.refParent) {
                this.rootElement = this.$refs.refParent
            }
            this.componentRefs = this.$refs
        },

        localClickSelectRow (row) {
            const index = this.selectedRows.findIndex(i => i._order === row._order)
            index === -1 ? this.selectedRows.push(row) : this.selectedRows.splice(index, 1)

            if (_.size(this.selectedRows) === 1) {
                this.shiftStartSelect = this.selectedRows[0]
                this.currentHoverCell = this.selectedRows[0]
            }
        },

        localInitiateDnD (evt) {
            if (!this.canUpdate) return

            const dragEl = evt.target.closest('.c-magic-table__row-drag-select').closest('.js-task-draggable-item')
            dragEl.setAttribute('draggable', !!dragEl)
        },

        localAttachMouseHandlers () {
            if (!this.$el) return

            const rowItems = [...this.$el.querySelectorAll('.c-magic-table__row')]
            rowItems.forEach(row => row.addEventListener('mousedown', this.localIntiateDragSelect))
        },

        localIntiateDragSelect (evt) {
            if (evt.target.tagName === 'INPUT') return
            if (this.currentEditCell && this.currentEditCell.id) return

            const dragHandlerParent = evt.target.closest('.c-magic-table__row-drag-select')
            if (!this.localKeyIsCmdCtrlShift && !this.bulkMode && (dragHandlerParent && dragHandlerParent.contains(evt.target)) && this.canUpdate) {
                this.localInitiateDnD(evt)
                return
            }
            if (this.dragStart) return
            if (!this.localKeyIsCmdCtrlShift) return

            if (!this.hasDragSelectStarted) {
                this.hasDragSelectStarted = true
                if (evt.target.tagName === 'BODY') {
                    this.selectedRows = []
                    this.currentEditCell = {}
                }
            }

            if (this.hasDragSelectStarted) {
                document.addEventListener('mousemove', this.localDragSelectRows)
                document.addEventListener('mouseup', this.localReleaseDragSelect)
            }
        },

        localDragSelectRows (evt) {
            if (this.dragStart) return

            const rowElement = evt.target.closest('.c-magic-table__row')
            if (!rowElement) return true

            const rowOrder = parseInt(rowElement.dataset.rowOrder)
            const rowItem = this.listItems.find(i => i._order === rowOrder)
            if (!rowItem) return true

            this.currentHoverCell = {}
            this.currentEditCell = {}
            if (_.size(this.selectedRows) === 0) {
                this.selectedRows.push(rowItem)
                this.shiftStartSelect = { ...rowItem }
                return true
            }

            const hasIndex = this.selectedRows.findIndex(i => i._order === rowOrder)
            if (hasIndex === -1) {
                this.selectedRows.push(rowItem)
                return true
            }

            if (_.size(this.selectedRows) <= 1) return

            const lastSelectedOrder = this.selectedRows.slice(-1)[0]._order
            const lastBeforeSelectedOrder = this.selectedRows.slice(-2)[0]._order
            if (rowOrder === lastBeforeSelectedOrder) {
                const lastSelectedIndex = this.selectedRows.findIndex(i => i._order === lastSelectedOrder)
                this.selectedRows.splice(lastSelectedIndex, 1)
            }
        },

        localReleaseDragSelect (evt) {
            document.removeEventListener('mousemove', this.localDragSelectRows)
            document.removeEventListener('mouseup', this.localReleaseDragSelect)
            this.hasDragSelectStarted = false
        },

        localMouseClick (evt) {
            const taskAddBtn = document.querySelector('.c-add-task-btn')
            const magicIsland = document.querySelector('.c-magic-island')
            const selectAllBtn = document.querySelectorAll('.c-action-btn')
            // const hasTarget = [...selectAllBtn].map(i => {
            //     return !!(i.classList.contains('c-action-btn') || selectAllBtn.contains(i))
            // })

            if (
                (evt && evt.target.nodeName === 'INPUT') ||
                taskAddBtn && taskAddBtn.contains(evt.target) ||
                evt.target.closest('.c-add-input') ||
                magicIsland && magicIsland.contains(evt.target)
                // hasTarget.some(i => i === true)
            ) return

            setTimeout(() => this.localOnEscape(), 10)
        },

        localKeyDown (evt) {
            if (evt.code === 'Escape') return this.localOnEscape(evt)

            if (evt.target.tagName === 'INPUT') return
            if (evt.code === 'KeyA' && (evt.ctrlKey || evt.metaKey)) return

            if (!this.canUpdate) return

            if (evt.code === 'Enter') return this.localOnEnter(evt)
            if (this.currentEditCell && this.currentEditCell.id) return

            if (evt.target.tagName !== 'BODY' && (evt.target.parentElement && evt.target.parentElement.querySelector('.v-menu'))) return
            if (!_.size(this.selectedRows) && (!this.currentHoverCell || (this.currentHoverCell && !this.currentHoverCell.id))) return

            const row = this.listItems.find(i => i.id === this.currentHoverCell.id)
            if (evt.code === 'ArrowRight') this.localArrowRight(row)
            if (evt.code === 'ArrowLeft') this.localArrowLeft(row)
            if (evt.code === 'ArrowUp') {
                if (evt.shiftKey === true) {
                    evt.preventDefault()
                    this.localBulkSelectUp()
                }
                else this.localArrowUp()
            }
            if (evt.code === 'ArrowDown') {
                if (evt.shiftKey === true) {
                    evt.preventDefault()
                    this.localBulkSelectDown()
                }
                else this.localArrowDown()
            }
        },

        localOnEscape (evt) {
            const noKey = (!evt || (evt && evt.key !== 'Escape'))
            if (noKey || (this.hasDeleteConfirmation && noKey)) return

            if (this.bulkMode) {
                if (this.localCellIsEdited) return this.localRemoveCellSelection()

                this.$emit('clearSelectAll')
                this.selectedRows = []
                return true
            }

            this.localRemoveCellSelection()
        },

        localClearAll () {
            if (this.bulkMode && this.localCellIsEdited) return

            this.localOnEscape({ key: 'Escape' })
        },

        localClearAllSelection () {
            this.selectedRows = []
            this.currentEditCell = null
            this.currentHoverCell = null
            this.shiftStartSelect = null
            this.currentFocusedRow = null
        },

        localOnEnter (evt) {
            const isTargetBody = evt.target.tagName === 'BODY'
            if ((this.$refs.refParent && !this.$refs.refParent.contains(evt.target)) && !isTargetBody) return
            if (this.currentEditCell && this.currentEditCell.id) return
            if (this.currentCell && this.avoidValueToggle.includes(this.currentCell.slug)) return
            if (!this.currentHoverCell && (this.currentHoverCell && !this.currentHoverCell.id)) return

            this.localEditCell(evt, this.currentHoverCell, this.currentHoverCell.colIndex)
        },

        localArrowRight (row) {
            this.localRemoveCellSelection()
            const lastColIndex = this.currentColumns.slice(-2)[0]._order
            const currentColIndex = this.currentHoverCell.colIndex + 1
            if (currentColIndex > lastColIndex) return

            this.currentCell = this.currentColumns[currentColIndex]
            this.localScrollToColumn(row, currentColIndex, lastColIndex)
        },

        localArrowLeft (row) {
            this.localRemoveCellSelection()
            const currentColIndex = this.currentHoverCell.colIndex - 1
            if (currentColIndex < 0) return

            this.currentCell = this.currentColumns[currentColIndex]
            this.localScrollToColumn(row, currentColIndex)
        },

        localArrowUp () {
            const currentRowIndex = this.currentHoverCell._order - 1
            if (currentRowIndex < 0) return

            this.localScrollToRow(currentRowIndex)
        },

        localArrowDown () {
            if (!this.currentHoverCell) return

            const lastRowIndex = this.listItems.slice(-1)[0]._order
            const currentRowIndex = this.currentHoverCell._order + 1
            if (currentRowIndex > lastRowIndex) return

            this.localScrollToRow(currentRowIndex)
        },

        localScrollToRow (currentRowIndex) {
            const row = this.listItems.find(i => i._order === currentRowIndex)
            const element = this.$refs[`refRow-${row._order}`][0]
            element.scrollIntoView({ block: 'center' })
            if (!this.currentHoverCell) return

            const colIndex = isNaN(parseInt(this.currentHoverCell.colIndex)) ? this.shiftStartSelect.colIndex : this.currentHoverCell.colIndex
            this.currentHoverCell = { ...row, colIndex }
            this.shiftStartSelect = { ...this.currentHoverCell }
            // this.selectedRows = this.shiftStartSelect ? [this.shiftStartSelect] : []
            this.currentCell = this.currentColumns[colIndex]
        },

        localScrollToColumn (row, currentColIndex) {
            this.currentHoverCell = { ...row, colIndex: currentColIndex }
            setTimeout(() => this.rootElement.scrollLeft = this.currentHoverCell.colIndex <= 1 ? 0 : ((this.currentHoverCell.colIndex - 1) * 180), 0)
            this.localRemoveCellSelection()
        },

        localBulkSelectUp () {
            if (_.size(this.selectedRows) === 0) {
                this.selectedRows = this.shiftStartSelect ? [this.shiftStartSelect] : []
            }

            const lastSelectedOrder = this.selectedRows.slice(-1)[0]._order
            const order = lastSelectedOrder <= this.shiftStartSelect._order ? lastSelectedOrder - 1 : lastSelectedOrder
            if (order < 0) return

            this.localSelectRowWithShiftArrow(order)
        },

        localBulkSelectDown () {
            if (_.size(this.selectedRows) === 0) {
                this.selectedRows = this.shiftStartSelect ? [this.shiftStartSelect] : []
            }

            const lastSelectedOrder = this.selectedRows.slice(-1)[0]._order
            const order = lastSelectedOrder >= this.shiftStartSelect._order ? lastSelectedOrder + 1 : lastSelectedOrder
            if (order >= _.size(this.listItems)) return

            this.localSelectRowWithShiftArrow(order)
        },

        localSelectRowWithShiftArrow (order) {
            const hasIndex = this.selectedRows.findIndex(i => i._order === order)
            const rowItem = this.listItems.find(i => i._order === order)
            const element = this.componentRefs[`refRow-${rowItem._order >= 1 ? rowItem._order - 1 : 0 }`][0]
            element.scrollIntoView({ block: 'center' })

            if (hasIndex === -1) this.selectedRows.push(rowItem)
            if (hasIndex !== -1 && _.size(this.selectedRows) > 1) this.selectedRows.splice(hasIndex, 1)
        },

        localEditCell (evt, row, colIndex) {
            evt.preventDefault()
            evt.stopPropagation()
            if (!this.canUpdate) return

            if (evt.metaKey || evt.ctrlKey) return this.localClickSelectRow(row)
            if (evt.shiftKey === true) return this.localSelectRowHoldingShift(row)

            const bulkSelectHasRow = this.selectedRows.findIndex(i => i.id === row.id)
            if (bulkSelectHasRow === -1) this.localClearSelection()

            this.localAddRowForEdit(row, colIndex)
        },

        async localSelectRowHoldingShift (row) {
            if (!this.shiftStartSelect) {
                this.shiftStartSelect = { ...row }
                this.currentHoverCell = { ...row }
                this.selectedRows = this.shiftStartSelect ? [this.shiftStartSelect] : []
                return true
            }

            this.selectedRows = []
            this.shiftEndSelect = { ...row }

            const startIndex = this.shiftStartSelect._order
            const endIndex = this.shiftEndSelect._order
            if (startIndex <= endIndex) {
                for (let index = startIndex; index <= endIndex; index++) {
                    const rowItem = this.listItems[index]
                    const hasIdIndex = this.selectedRows.findIndex(i => i.id === rowItem.id)
                    if (hasIdIndex === -1) this.selectedRows.push(rowItem)
                }
            }

            if (startIndex >= endIndex) {
                for (let index = startIndex; index >= endIndex; index--) {
                    const rowItem = this.listItems[index]
                    const hasIdIndex = this.selectedRows.findIndex(i => i.id === rowItem.id)
                    if (hasIdIndex === -1) this.selectedRows.push(rowItem)
                }
            }
        },

        localClearSelection () {
            this.selectedRows = []
            this.currentEditCell = {}
        },

        localIsCellEdited (rOrder, cIndex) {
            const { colIndex, _order } = this.currentEditCell || {}
            return rOrder === _order && colIndex === cIndex
        },

        localIsRowSelected (id) {
            const index = this.selectedRows.findIndex(i => i.id === id)
            return index !== -1
        },

        localGetTasks (milestone_id, field = 'list') {
            const list = this.listItems.filter(i => i.milestone_id === milestone_id)
            const props = { list, length: list.length }

            return props[field]
        },

        localCheckScroll () {
            // if (!this.rootElement) return

            const left = this.rootElement.scrollLeft
            this.scrolledLeft = !!(left > 0)
            // if (!_.size(this.selectedRows)) {
            //     this.localOnEscape()
            // }
        },

        localToggleColumn (colItem) {
            const index = this.currentColumns.findIndex(i => i.slug === colItem.slug)
            this.$set(this.currentColumns[index], 'selected', !colItem.selected)
            this.$nextTick(() => this.rootElement.scrollLeft = this.rootElement.scrollWidth)
            //this.currentColumns[index].selected = !colItem.selected
        },

        localCalcLeft (index, prev, after, totalLength) {
            const unit = 'px'
            if (index === 0) return 0 + unit
            if (index === totalLength - 1) return ((totalLength - 1) * 200) + unit

            return (index * 200) + unit
        },

        localListDragStart (evt, item, itemIndex) {
            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'
            evt.dataTransfer.setData('item', JSON.stringify(item))
            evt.dataTransfer.setData('oldIndex', itemIndex)
            evt.dataTransfer.setData('itemId', item.id)
        },

        localListDragDrop (evt, newItem, newIndex) {
            evt.stopPropagation()
            const draggedItem = JSON.parse(evt.dataTransfer.getData('item'))
            const oldIndex = parseInt(evt.dataTransfer.getData('oldIndex'))
            const list = this.items

            list.splice(oldIndex, 1)
            list.splice(newIndex, 0, draggedItem)
            this.$emit('reorder', { type: 'milestone', list })

            this.dragStart = false
            this.localAddBgToCellOnDrag(evt)
        },

        localListDragOver (evt) {
            this.localAddBgToCellOnDrag(evt, true)
        },

        localListDragEnter (evt) {
            this.localAddBgToCellOnDrag(evt, true)
        },

        localListDragLeave (evt) {
            this.localAddBgToCellOnDrag(evt)
        },

        localAddBgToCellOnDrag (evt, color = false) {
            Array.from(evt.target.closest('.c-magic-table__row').querySelectorAll('.c-magic-table__cell'))
                .forEach(i => {
                    i.style.backgroundColor = !color ? '' : 'rgba(164, 236, 239, 1)'
                })
        },

        localGetBorder (col, index) {
            if (col.slug === 'add-col' || index === this.localSelectedColumns.length - 1) return { borderRight: '0px' }
            return { borderRight: '1px solid rgba(0,0,0,0.1) !important' }
        },

        localIsCellClicked (row, colIndex) {
            if (this.currentEditCell && !this.currentEditCell.id) return false
            return this.currentEditCell && this.currentEditCell.id === row.id && this.currentEditCell.colIndex === colIndex
        },

        localTrackComponentWidth () {
            setTimeout(() => {
                this.componentWidth = this.$el.clientWidth + 'px'
            }, 20);
        },

        localRemoveCellSelection () {
            this.currentEditCell = {}
        },

        localIsRowHovered (rOrder) {
            const {  _order } = this.currentHoverCell || {}
            return rOrder === _order
        },

        localIsCellHovered (rOrder, cIndex) {
            const { colIndex, _order } = this.currentHoverCell || {}
            return (rOrder === _order) && (colIndex === cIndex)
        },

        localMouseOverItem (evt, item) {
            this.itemHoverElementId = item.id
        },

        localMouseLeaveItem (evt) {
            this.itemHoverElementId = null
        },

        localSelectAllToggle (value) {
            this.selectedRows = value ? this.listItems : []
        },

        localEmitCreate () {
            const newMilestone = { id: uuidv4() }
            this.$emit('create', { item: newMilestone, order: 1, _module: 'milestone' })
        },

        localAddRowForEdit (item, colIndex = 0) {
            this.currentEditCell = { ...item, colIndex }
            this.currentHoverCell = { ...item, colIndex }
            this.shiftStartSelect = { ...this.currentEditCell }
            this.currentFocusedRow = { ...item, colIndex }

            setTimeout(() => {
                const input = this.$el.querySelector('.c-autofocus')
                if (input) input.focus()
            }, 50)
        }
    }
}
</script>

<style lang="scss" scoped>
.c-add-section {
    &__bar {
        visibility: hidden;
    }

    &:hover {
        .c-add-section__bar {
            visibility: visible !important;
        }
    }
}

.c-input-element {
    transition: 0.25s background-color ease-in-out;

    &:focus {
        background-color: rgba($color: #f6f7f9, $alpha: 1.0);
        transition: 0.25s background-color ease-in-out;
    }
}

.c-magic-table {
    &__row {
        &:hover {
            .c-magic-table__cell {
                background-color: #effaff;
            }
        }
    }

    &__cell {
        background-color: #fff;
    }

    .c-task-drag-handle {
        display: none !important;
    }

    &__cell-sticky {
        &:hover {
            .c-task-drag-handle {
                display: inline-flex !important;
            }
        }
    }
}

.c-cell-clicked {
    background-color: #d6f8ff !important;
}

.c-cell-keyed {
    background-color: #e1f5fe !important;
}

.c-cell-row-hovered {
    background-color: #f8f8f8 !important;
}
</style>

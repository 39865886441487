import TabAdminUser from '@/components/Tab/TabAdminUser.vue'
import TabApp from '@/components/Tab/TabApp.vue'
import TabChannel from '@/components/Tab/TabChannel.vue'
import TabInventoryMeta from '@/components/Tab/TabInventoryMeta.vue'
import TabMeta from '@/components/Tab/TabMeta.vue'
import TabTag from '@/components/Tab/TabTag.vue'
import TabTeam from '@/components/Tab/TabTeam.vue'
import TabToken from '@/components/Tab/TabToken.vue'
import TabWorkspace from '@/components/Tab/TabWorkspace.vue'
import TabMasterTemplate from '@/components/Tab/TabMasterTemplate.vue'

export {
    TabAdminUser,
    TabApp,
    TabChannel,
    TabInventoryMeta,
    TabMeta,
    TabTag,
    TabTeam,
    TabToken,
    TabWorkspace,
    TabMasterTemplate,
}

<template>
    <div v-bind="$attrs" v-on="$listeners" class="pa-6 pt-5 white u-relative u-rounded-corners-lg u-shadow" :class="{ 'js-draggable-file-list-item': canUpdate && !disableNavigation }">
        <router-link tag="div" class="u-cursor-pointer" :class="handle" :to="disableNavigation ? '' : itemPath">
            <div class="u-flex mb-1">
                <slot name="selectable"></slot>
                <h2 class="md-heading-6 font-weight-medium" :title="usecase.title && usecase.title.length >= (local_is_lgdown ? 185 : 248) ? usecase.title : ''">{{ usecase.title | truncateText(local_is_lgdown ? 185 : 248) }}</h2>
            </div>
            <div class="mb-0 mt-3 u-flex align-center">
                <div class="d-inline-flex align-center mr-4">
                    <slot name="success-criteria-stats"></slot>
                </div>
                <g-attachment-count-info
                    :count="usecase.attachments_count"
                    class="mr-4"
                    fontSize="12"
                    iconSize="14"
                />
                <div class="u-flex-center-y mr-4">
                    <slot name="assignees"></slot>
                </div>
                <slot name="comments">
                    <div class="u-flex-center-y" v-if="usecase.aggregated_comments_count">
                        <a-icon size="18" color="body lighten-3">chat_bubble</a-icon>
                        <span class="md-body-2 body--text text--lighten-2 ml-2">{{ usecase.aggregated_comments_count }}</span>
                    </div>
                </slot>
            </div>
        </router-link>
    </div>
</template>

<script>
import marked from 'marked'

export default {
    props: {
        usecase: {
            type: Object,
            required: true
        },
        handle: {
            type: String
        },
        itemPath: {
            type: Object,
            default: () => {
                return { name: itemPath, params: {usecase_id: this.usecase.id } }
            },
        },
        disableNavigation: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean
        }
    },

    computed: {
        local_is_lgdown () {
            return this.$vuetify.breakpoint.lgAndDown
        },
    },

    methods: {
        local_get_success_criteria_status_count(usecase) {
            let count = 0
            if (usecase.success_criteria_failed_count) count++
            if (usecase.success_criteria_invalid_count) count++
            if (usecase.success_criteria_open_count) count++
            if (usecase.success_criteria_passed_count) count++

            return count * 4
        },

        local_get_html(content) {
            return marked(content, { sanitize: true })
        },
    }
}
</script>

<style lang="scss" scoped>
.c-testcase-count-wrap {
    overflow: hidden;
    border-radius: 25px;
    display: inline-flex;
    align-items: center;
    height: 6px;
    &--strip {
        display: inline-block;
        margin-left: -4px;
        border-radius: 0 25px 25px 0;
        height: 100%;
    }
}
</style>

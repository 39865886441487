<template functional>
    <a-container container--fluid grid-list-xl class="pb-0 pt-4 pl-8" :class="data.staticClass">
        <a-layout wrap align-center>
            <a-flex shrink v-if="props.user.role && props.user.role.scope === 'internal'" class="pr-0">
                <a-tooltip bottom right>
                    <template v-slot:activator="{ on }">
                        <a-icon size="16" color="orange" v-on="on">lock_open</a-icon>
                        </template>
                    <span>Visible to Client</span>
                </a-tooltip>
            </a-flex>
            <a-flex>
                <h3 class="md-caption grey--text text--darken-1 d-inline-block text-uppercase font-weight-bold">{{ props.module }}</h3>
            </a-flex>
        </a-layout>
    </a-container>
</template>

<script>
export default {
    props: ['user', 'module']
}
</script>

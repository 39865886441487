<template>
    <a-layout align-start>
        <a-flex pt-0>
            <a-tabs class="mb-0" background-color="transparent" slider-color="primary">
                <a-tab ripple class="mx-2" :to="{name: 'teams-enabled'}">
                    <span class="md-body-2">Active Users</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'teams-disabled'}">
                    <span class="md-body-2">Blocked Users</span>
                </a-tab>
            </a-tabs>
            <a-divider class="grey lighten-2"></a-divider>
        </a-flex>
    </a-layout>
</template>

<script>
export default {

}
</script>

const api_endpoint = '/public-api/document';

export default {
    namespaced: true,
    state: {
        link: '',
        response: [],
        defaults: {
            link: '',
            response: [],
        },
    },
    mutations: {
        UPDATE_LINK(state, url) {
            state.link = url;
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        download(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/download', { params: payload })
                .then((response) => {
                    context.commit('UPDATE_LINK', response.data.url);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_LINK', _.cloneDeep(context.state.defaults.link));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                resolve('Cleared');
            });
        },
    },
}

<template>
    <div v-outside-click tabindex="0" ref="refParent" class="u-flex-center-y flex-wrap u-wfull" style="outline: none;">
        <div
            class="c-custom-dropdown-parent u-relative u-hfull u-wfull"
            :id="attachId"
            :class="{ 'mb-1': selectedList && selectedList.length }"
            style="flex-grow: 0;"
            :style="[{ flexBasis: minWidth + 'px', maxWidth: minWidth + 'px' }]"
        >
            <template v-if="!hideSearch">
                <slot name="input" :open="localOpenDropdown" :isOpen="modelDropdown">
                    <input
                        type="text"
                        v-model="modelCurrentValue"
                        @click.stop="localOpenDropdown()"
                        @paste="localOnPaste"
                        @input="localSearchTags(modelCurrentValue)"
                        :class="['md-subtitle-1 grey--text text--darken-2 u-wfull u-hfull white pl-3 pr-8']"
                        :placeholder="placeholderType"
                        style="outline: none;"
                        autocomplete="off"
                    >
                </slot>
            </template>
            <slot name="input-placeholder" v-if="!hidePlaceholder">
                <span class="d-inline-flex align-center text-center u-absolute" style="right: 0px; top: 50%; transform: translateY(-50%); pointerEvents: none;">
                    <!-- <span class="md-subtitle-1 d-inline-block text-capitalize" style="visibility: hidden">{{ value }}</span> -->
                    <template v-if="!hideArrow">
                        <a-spacer></a-spacer>
                        <a-icon color="grey darken-1" class="mr-1" size="20">keyboard_arrow_down</a-icon>
                    </template>
                </span>
            </slot>
                <!-- :close-on-content-click="!tagError || createLoading || closeOnClick" -->
            <a-menu
                v-model="modelDropdown"
                :attach="`#${attachId}`"
                :min-width="dropdownWidth"
                :max-width="dropdownWidth"
                :close-on-content-click="closeOnContentClick"
                :close-on-click="closeOnClick"
                :nudge-bottom="nudgeB"
                :nudge-left="nudgeL"
                :content-class="`white u-shadow-md ${ customZIndex ? 'u-z16' : '' }`"
                offset-y
            >
                <!-- :close-on-content-click="closeOnContentClick"
                :close-on-click="closeOnClick" -->
                <a-sheet @blur="menuModal = false" tabindex="0" style="outline: none;" class="c-dropdown-scroll-parent u-rounded-corners-lg u-wfull">
                    <div class="u-flex-center u-wfull px-4 py-2 white" style="min-height: 53px;" v-if="tagSearchLoading || initialLoading">
                        <a-progress-circular class="mr-2" width="2" size="16" color="grey darken-2" indeterminate></a-progress-circular>
                        <span class="md-subtitle-1 grey--text text--darken-2">Loading...</span>
                    </div>
                    <template v-else>
                        <template v-if="grouped">
                            <ul v-if="localCanShowNoData" tabindex="0" class="c-custom-dropdown u-hfull px-4 white u-flex-center-y" style="min-height: 53px;">
                                <li class="u-rounded-corners px-2 u-flex-center-y">
                                    <span class="md-subtitle-1 grey--text text--darken-2 text-truncate d-inline-block">No Data Found</span>
                                </li>
                            </ul>
                            <template v-else>
                                <ul tabindex="0" class="c-custom-dropdown u-hfull pa-2 c-tiny-scroll u-overflow-y" style="max-height: 250px;">
                                    <template v-for="(groupItem, groupIndex) in groupedList">
                                        <li :key="'group-' + groupIndex" :class="[groupIndex !== 0 ? 'my-2' : 'mb-2']" class="md-caption font-weight-medium grey--text text--darken-1 text-uppercase" style="letter-spacing: 0.2px;">
                                            {{ groupItem.type }}
                                        </li>
                                        <template v-for="(tag) in localGetListBy(groupItem.type, 'status')">
                                            <template v-if="tag.id === 'no-data'">
                                                <li class="u-rounded-corners px-2 u-flex-center-y" :key="tag.id">
                                                    <span class="md-subtitle-1 grey--text text--darken-2 text-truncate d-inline-block">No Data Found</span>
                                                </li>
                                            </template>
                                            <li
                                                :key="tag._index"
                                                v-if="tag.id !== 'create'"
                                                tabindex="0"
                                                @click.prevent.stop="!creatable && !['no-data', 'create'].includes(tag.id) ? localSelectTag(tag) : ''"
                                                :data-link-type="tag.id !== 'no-data' ? 'tag-item' : 'no-data'"
                                                class="pa-2 u-rounded-corners md-subtitle-1 u-cursor-pointer c-hover-state-light"
                                                :class="[
                                                    currentKeyIndex === tag._index && value === tag[itemValue]
                                                        ? 'indigo lighten-5' : currentKeyIndex === tag._index ? 'grey lighten-4' : '',
                                                    { 'mt-1': tag._index !== 0 }
                                                ]"
                                            >
                                                <slot name="item" :item="tag" :isSelected="value === tag[itemValue]" :index="tag._index">
                                                    <div class="u-flex-center-y">
                                                        <span
                                                            class="md-body-2 text-truncate d-inline-block"
                                                            :class="[value === tag[itemValue] ? 'indigo--text text--darken-3' : 'grey--text text--darken-1']"
                                                        >
                                                            {{ tag[itemText] }}
                                                        </span>
                                                    </div>
                                                </slot>
                                            </li>
                                            <template v-else>
                                                <li
                                                    v-if="searchText && searchText.trim()"
                                                    @click.stop="creatable ? localCreateTag() : ''"
                                                    data-link-type="create-tag"
                                                    tabindex="0"
                                                    class="u-rounded-corners md-subtitle-1 c-hover-state-light"
                                                    :key="tag._index"
                                                    :class="[
                                                        { 'mt-1': tag._index !== 0 },
                                                        { 'pa-2': !localTagError },
                                                        { 'u-cursor-pointer': creatable },
                                                        { 'grey lighten-4': (currentKeyIndex === tag._index) },
                                                    ]"
                                                >
                                                    <div :class="{ 'u-border-error c-wiggle-short u-rounded-corners pa-2': localTagError }">
                                                        <div class="u-flex-center-y">
                                                            <!-- <template v-if="canCreate"> -->
                                                                <a-progress-circular width="2" size="16" color="indigo darken-1" class="mr-3" v-if="createLoading" indeterminate></a-progress-circular>
                                                                <a-icon size="16" color="grey darken-2" class="mr-3" v-else>add_circle</a-icon>
                                                            <!-- </template> -->
                                                            <span class="md-body-2 grey--text text--darken-1 text-truncate d-inline-flex align-center">
                                                                <!-- <template v-if="!canCreate">No Data Found</template>
                                                                <template v-else> -->
                                                                    Create
                                                                    <strong class="d-inline-block text-truncate ml-1">{{ searchText }}</strong>
                                                                <!-- </template> -->
                                                            </span>
                                                        </div>
                                                        <span v-if="localTagError" class="md-caption red--text text--darken-1">{{ localTagError }}</span>
                                                    </div>
                                                </li>
                                            </template>
                                        </template>
                                    </template>
                                </ul>
                            </template>
                        </template>

                        <template v-if="!grouped">
                            <slot
                                name="menu-list"
                                :list="list"
                                :focusIndex="currentKeyIndex"
                                :focusOut="localOutsideClick"
                                :isOpen="modelDropdown"
                                :selectItem="localSelectTag"
                                :classList="['u-overflow-y c-tiny-scroll']"
                                :styleList="[{ maxHeight: '250px' }]"
                            >
                                <ul class="c-custom-dropdown u-hfull px-4 white u-flex-center-y" tabindex="0" style="min-height: 53px;" v-if="localCanShowNoData">
                                    <li class="u-rounded-corners px-2 u-flex-center-y">
                                        <span class="md-subtitle-1 grey--text text--darken-2 text-truncate d-inline-block">No Data Found</span>
                                    </li>
                                </ul>
                                <ul v-else class="c-custom-dropdown u-hfull pa-2 c-tiny-scroll u-overflow-y" style="max-height: 250px;" tabindex="0">
                                    <template v-for="(tag, i) in localFilteredList">
                                        <template v-if="tag.id === 'no-data'">
                                            <li class="u-rounded-corners px-2 u-flex-center-y" :key="tag.id">
                                                <span class="md-subtitle-1 grey--text text--darken-2 text-truncate d-inline-block">No Data Found</span>
                                            </li>
                                        </template>
                                        <li
                                            :key="i"
                                            tabindex="0"
                                            v-if="tag.id !== 'create'"
                                            @click.prevent.stop="!creatable && !['no-data', 'create'].includes(tag.id) ? localSelectTag(tag) : ''"
                                            :data-link-type="tag.id !== 'no-data' ? 'tag-item' : 'no-data'"
                                            class="pa-2 u-rounded-corners md-subtitle-1 u-cursor-pointer c-hover-state-light"
                                            :class="[
                                                currentKeyIndex === i && value === tag[itemValue]
                                                    ? 'indigo lighten-5' : currentKeyIndex === i ? 'grey lighten-4' : '',
                                                { 'mt-1': i !== 0 }
                                            ]"
                                        >
                                            <slot name="item" :item="tag" :isSelected="value === tag[itemValue]" :index="i">
                                                <div class="u-flex-center-y">
                                                    <span
                                                        class="md-body-2 text-truncate d-inline-block"
                                                        :class="[value === tag[itemValue] ? 'indigo--text text--darken-3' : 'grey--text text--darken-1']"
                                                    >
                                                        {{ tag[itemText] }}
                                                    </span>
                                                </div>
                                            </slot>
                                        </li>
                                        <template v-else>
                                            <li
                                                v-if="searchText && searchText.trim()"
                                                @click.stop="creatable ? localCreateTag() : ''"
                                                data-link-type="create-tag"
                                                tabindex="0"
                                                class="u-rounded-corners md-subtitle-1 c-hover-state-light"
                                                :key="i"
                                                :class="[
                                                    { 'mt-1': i !== 0 },
                                                    { 'pa-2': !localTagError },
                                                    { 'u-cursor-pointer': creatable },
                                                    { 'grey lighten-4': (currentKeyIndex === i) },
                                                ]"
                                            >
                                                <div :class="{ 'u-border-error c-wiggle-short u-rounded-corners pa-2': localTagError }">
                                                    <div class="u-flex-center-y">
                                                        <!-- <template v-if="canCreate"> -->
                                                            <a-progress-circular width="2" size="16" color="indigo darken-1" class="mr-3" v-if="createLoading" indeterminate></a-progress-circular>
                                                            <a-icon size="16" color="grey darken-2" class="mr-3" v-else>add_circle</a-icon>
                                                        <!-- </template> -->
                                                        <span class="md-body-2 grey--text text--darken-1 text-truncate d-inline-flex align-center">
                                                            <!-- <template v-if="!canCreate">No Data Found</template>
                                                            <template v-else> -->
                                                                Create
                                                                <strong class="d-inline-block text-truncate ml-1">{{ searchText }}</strong>
                                                            <!-- </template> -->
                                                        </span>
                                                    </div>
                                                    <span v-if="localTagError" class="md-caption red--text text--darken-1">{{ localTagError }}</span>
                                                </div>
                                            </li>
                                        </template>
                                    </template>
                                </ul>
                            </slot>
                        </template>
                    </template>
                </a-sheet>
            </a-menu>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
        },
        list: {
            type: Array,
            default: () => []
        },
        selectedList: {
            type: Array,
            default: () => []
        },
        groupedList: {
            type: Array,
            default: () => []
        },
        attachId: {
            type: String,
            default: 'menudropdown'
        },
        initialLoading: {
            type: Boolean,
            default: false
        },
        forceOpen: {
            type: Boolean,
            default: false
        },
        creatable: {
            type: Boolean,
            default: false
        },
        manualMode: {
            type: Boolean,
            default: false
        },
        closeOnClick: {
            type: Boolean,
            default: false
        },
        closeOnContentClick: {
            type: Boolean,
            default: false
        },
        grouped: {
            type: Boolean,
            default: true
        },
        hideSearch: {
            type: Boolean,
            default: false
        },
        hidePlaceholder: {
            type: Boolean,
            default: false
        },
        hideArrow: {
            type: Boolean,
            default: false
        },
        customZIndex: {
            type: Boolean,
            default: true
        },
        placeholderType: {
            type: String
        },
        itemText: {
            type: String,
            default: 'label'
        },
        itemValue: {
            type: String,
            default: 'value'
        },
        noLoading: {
            type: Boolean,
            default: false
        },
        nudgeB: {
            type: Number | String,
            default: 56,
        },
        nudgeL: {
            type: Number | String,
            default: 0,
        },
        minWidth: {
            type: Number,
            default: 260,
        },
        dropdownWidth: {
            type: Number | String,
            default: '100%'
        },
        dropdownHeight: {
            type: Number | String,
            default: 250
        },
    },

    data () {
        return {
            showMenu: false,
            currentKeyIndex: 0,
            modelDropdown: false,
            tagSearchLoading: false,
            tagError: null,
            createLoading: false,
            tagSearchTimeout: null,
            modelCurrentValue: '',
            searchText: '',
            currentSelectionIndex: null,
            tagConfirmRemoveIndex: null,
            currentTagToRemove: {},
            tagRemoveTimeout: null,
            tagDestroyTimeout: null,
            removedTags: [],
            bulkCompChunkCount: 50,
        }
    },

    watch: {
        // modelCurrentValue (val) {
        //     // this.modelDropdown = !!_.trim(val)
        //     // if (_.trim(val)) this.localSearchTags(val)
        // },

        value: {
            handler (newVal) {
                const val = this.list.find(i => i[this.itemValue] === newVal)[this.itemText]
                this.modelCurrentValue = val
            },
        },

        forceOpen: {
            handler (newVal, oldVal) {
                if (newVal) this.localOpenDropdown()
                if (newVal === false && oldVal === true) {
                    this.modelDropdown = false
                }
            },
            immediate: true,
        },

        modelDropdown: {
            handler (newVal, oldVal) {
                if (newVal === true) this.localOpenDropdown()
                if (oldVal === true && !newVal) {
                    this.localRestProps()
                    this.$emit('closeDropdown')
                }
            },
            immediate: true
        },
    },

    mounted () {
        this.$refs.refParent.addEventListener('keydown', this.localKeyDown)
        this.localIndex()
    },

    beforeDestroy () {
        if (this.$refs && this.$refs.refParent) {
            this.$refs.refParent.removeEventListener('keydown', this.localKeyDown)
        }
    },

    computed: {
        localFilteredList () {
            const searchValue = _.trim(this.searchText) ? this.searchText.toLowerCase() : ''
            const list = this.list.filter(item => {
                const value = item[this.itemText] && item[this.itemText].toLowerCase()
                if (value === searchValue || item.id === 'no-data' || item.id === 'create') return item
                return value ? (value.includes(searchValue)) : item
            })

            if (list.length === 0 && this.creatable) {
                const index = list.findIndex(i => i.id === 'create')
                if (index === -1) list.push({ id: 'create', [this.itemText]: this.searchText, [this.itemValue]: this.searchText })
            }

            return list
        },

        localFetchModifiedList () {
            const list = _.cloneDeep(this.list)
            if (list.length === 0) {
                if (!this.creatable) {
                    const index = list.findIndex(i => i.id === 'no-data')
                    if (index === -1) list.push({ id: 'no-data', [this.itemText]: 'No Data', [this.itemValue]: 'no-data' })
                } else {
                    const index = list.findIndex(i => i.id === 'create')
                    if (index === -1) list.push({ id: 'create', [this.itemText]: this.searchText, [this.itemValue]: this.searchText })
                }
            }

            return list
        },

        localTagError () {
            return this.tagError
        },

        localFirstItemIsCreate () {
            const list = this.list && this.list
            return !!(list.length === 1 && list[0].id === 'create')
        },

        localCanShowNoData () {
            const listLength = (this.localFilteredList && this.localFilteredList.length) || 0
            return !listLength
        },
    },

     methods: {
        localIndex () {
            if (this.value) this.localSetModelValue()

            this.localAddBlurEventToMenu()
            if (this.manualMode) this.localAddTabIndex()

            this.currentKeyIndex = this.list.findIndex(i => i[this.itemValue] === this.value)
            setTimeout(() => this.localFocusCurrentItem(), 200)
        },

        localSetModelValue (value) {
            const modelVal = value || this.value || null
            const val = this.list.find(i => i[this.itemValue] === modelVal)[this.itemText]
            this.modelCurrentValue = val
        },

        localAddBlurEventToMenu () {
            const uls = this.$el.querySelectorAll('ul');
            [...uls].forEach(ul => {
                ul.addEventListener('blur', () => this.localOutsideClick())
            })
        },

        localAddTabIndex () {
            const ul = this.$el.querySelector('ul')
            if (!ul) return

            if (ul.tabIndex === -1) ul.tabIndex = 0
            if (!_.size(ul.children)) return

            Array.from(ul.children).forEach(i => {
                if (i.tabIndex === -1) i.tabIndex = 0
            })
        },

         localOnPaste (value) {
            // if (this.tagSearchTimeout) clearTimeout(this.tagSearchTimeout)
            // this.modelCurrentValue = value
            // this.localSearchTags(this.modelCurrentValue)
        },

        async localSearchTags (value) {
            this.tagError = null
            // clearTimeout(this.tagSearchTimeout)

            this.searchText = value
            if (!this.modelDropdown) this.modelDropdown = true

            this.currentKeyIndex = 0
            if (!this.tagSearchLoading && !this.noLoading) this.tagSearchLoading = true
            // this.tagSearchTimeout = setTimeout(async () => {
            //     this.localEmitEvent('search', { value: this.searchText, clearLoading: this.localClearSearchTimeout, resolveError: this.localShowError })
            // }, 500)
        },

        localShowError (error) {
            this.createLoading = false
            this.modelDropdown = true
            if (!error) return this.tagSearchLoading = false

            this.tagError = error
            this.tagSearchLoading = false
            clearTimeout(this.tagSearchTimeout)
        },

        localClearSearchTimeout () {
            this.tagSearchLoading = false
            clearTimeout(this.tagSearchTimeout)
        },

        localOpenDropdown () {
            if (!this.noLoading) this.tagSearchLoading = true
            // if (this.modelDropdown) return this.localFocusInput()

            if (this.modelDropdown) {
                this.currentKeyIndex = 0
                this.localEmitEvent('openDropdown', { clearLoading: this.localClearSearchTimeout })
                return this.localFocusInput()
            }

            this.modelDropdown = true
            this.currentKeyIndex = 0
            this.localFocusInput()
            // this.localSelectInputText()
            this.localEmitEvent('openDropdown', { clearLoading: this.localClearSearchTimeout })
        },

        localKeyDown (evt) {
            this.localOnArrowDown(evt)
            this.localOnArrowUp(evt)
            this.localOnEnter(evt)
            this.localOnBackspace(evt)
            this.localOnEscape(evt)
            // this.localOnAlphabets(evt)
        },

        localOnAlphabets (evt) {
            // if (evt.keyCode < 65 || evt.keyCode > 90) return
            // if ((evt.metaKey || evt.ctrlKey) && evt.keyCode === 65) return this.localSelectInputText()
            // this.localFocusInput()
        },

        // localGlobalKeyDown (evt) {
        //     this.localOnEscape(evt)
        // },

        localOnEscape (evt) {
            if (evt.code !== 'Escape') return

            this.localResetTagIndices()
            this.modelDropdown = false
            this.searchText = null
        },

        localResetTagIndices () {
            this.currentSelectionIndex = null
            this.tagConfirmRemoveIndex = null
        },

        localOnArrowDown (evt) {
            if (evt.code !== 'ArrowDown') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (!this.modelDropdown || this.tagSearchLoading || this.createLoading) return

            evt.preventDefault()
            if (this.currentKeyIndex === this.list.length - 1) this.currentKeyIndex = 0
            else this.currentKeyIndex = this.currentKeyIndex + 1

            this.localFocusCurrentItem(evt, 'down')
            // this.localFocusInput()
        },

        localOnArrowUp (evt) {
            if (evt.code !== 'ArrowUp') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (!this.modelDropdown || this.tagSearchLoading || this.createLoading) return

            evt.preventDefault()
            if (this.currentKeyIndex === 0) this.currentKeyIndex = this.list.length - 1
            else this.currentKeyIndex = this.currentKeyIndex - 1

            this.localFocusCurrentItem(evt, 'up')
            // this.localFocusInput()
        },

        async localOnEnter (evt) {
            if (evt.code !== 'Enter') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (this.tagSearchLoading || this.createLoading) return
            if (!this.$el.parentElement) return

            let listChildren = this.$el.parentElement.querySelector('.c-custom-dropdown')
            listChildren = [...listChildren.children].filter(item => item.hasAttribute('data-link-type'))
            const tagDataSetType = evt.target.tagName === 'LI' ? evt.target.dataset.linkType : (listChildren[this.currentKeyIndex] && listChildren[this.currentKeyIndex].dataset.linkType)

            if (!tagDataSetType || tagDataSetType === 'no-data') return this.createLoading = false
            if (tagDataSetType === 'create-tag') {
                this.createLoading = false
                // if (!this.canCreate) return this.createLoading = false
                return this.localCreateTag()
            }

            const selectedTag = this.localFilteredList[this.currentKeyIndex]
            if (selectedTag && selectedTag.id !== 'create') this.localSelectTag(selectedTag)
            this.modelDropdown = false
            this.localRestProps()
            setTimeout(() => {
                this.localOutsideClick()
            }, 100);
        },

        localOutsideClick (evt) {
            // if (this.$refs.refParent.contains(evt.target)) return
            document.body.click()
        },

        localOnBackspace (evt) {
            // if (evt.code !== 'Backspace') return
            // if (evt.shiftKey || evt.ctrlKey) return
            // console.log(_.trim(this.modelCurrentValue))
            // if (_.trim(this.modelCurrentValue) || this.tagSearchLoading || this.createLoading || this.createLoading) return
            // if (!this.canUpdate) return

            // this.localBackspaceTraverse(evt)
        },

        localFocusCurrentItem (evt = null) {
            const listChildren = (evt && evt.target.tagName === 'LI') ?
                evt.target.parentElement :
                (this.$el.parentElement ? this.$el.parentElement.querySelector('.c-custom-dropdown') : null)

            if (listChildren) {
                const elArr = Array.from(listChildren.children).filter(item => item.dataset.linkType === 'tag-item')
                if (elArr && elArr[this.currentKeyIndex]) {
                    setTimeout(() => elArr[this.currentKeyIndex].scrollIntoView({ block: 'nearest' }), 0)
                }
            }
        },

        async localCreateTag () {
            this.createLoading = true
            this.localEmitEvent('select', {
                item: {
                    id: 'create',
                    mode: 'create',
                    [this.itemText]: 'Create',
                    value: this.modelCurrentValue
                },
                resolveError: this.localShowError,
                resolveSuccess: this.localTagCreateSuccess
            })
        },

        localSelectTag (selectedTag) {
            if (!this.manualMode && (selectedTag[this.itemValue] === this.value)) {
                return this.localOutsideClick()
            }

            this.localEmitEvent('select', {
                item: selectedTag,
                mode: 'select',
                resolveError: this.localShowError,
                resolveSuccess: this.localTagCreateSuccess
            })
            this.localOutsideClick()
            // this.localSetModelValue(selectedTag[this.itemValue])
            // this.localFocusInput()
        },

        localTagCreateSuccess () {
            this.modelCurrentValue = null
            this.createLoading = false
        },

        localFocusInput () {
            setTimeout(() => {
                const input = this.$el.querySelector('input') ? this.$el.querySelector('input') : this.$el.parentElement.querySelector('.v-menu__content').querySelector('input')
                if (input) setTimeout(() => input.focus(), 10)
                if (this.manualMode && this.$refs.refParent) this.$refs.refParent.focus()
            }, 0)
        },

        localSelectInputText () {
            const input = this.$el.querySelector('input')
            if (input) input.select()
        },

        localEmitEvent (label, props = null) {
            this.$emit(label, props)
        },

        localBackspaceTraverse (evt) {
            if (this.currentSelectionIndex === null) this.localTraverseLeft()
            if (this.tagConfirmRemoveIndex !== null) {
                this.tagRemoveType = this.tagSearchType
                return this.localTagRemove()
            }
            if (this.currentSelectionIndex !== null) return this.localGetTagRemoveConfirm()
        },

        localTraverseLeft () {
            // if (!this.canUpdate) return
            if (this.tagConfirmRemoveIndex) this.tagConfirmRemoveIndex = null

            const currentTypeTags = this.selectedList
            this.currentTagToRemove = currentTypeTags.slice(-1)[0]

            if (this.currentSelectionIndex === null) return this.currentSelectionIndex = currentTypeTags.length - 1
            if (this.currentSelectionIndex > 0) return this.currentSelectionIndex--
            if (this.currentSelectionIndex === 0 && currentTypeTags.length === 1) return this.currentSelectionIndex = currentTypeTags.length - 1
            if (this.currentSelectionIndex === 0) return this.currentSelectionIndex = currentTypeTags.length - 1
        },

        localGetTagRemoveConfirm () {
            if (this.tagRemoveTimeout) clearTimeout(this.tagRemoveTimeout)

            this.tagConfirmRemoveIndex = this.currentSelectionIndex
            this.tagRemoveTimeout = setTimeout(() => this.tagConfirmRemoveIndex = null, 2000)
        },

        async localTagRemove () {
            clearTimeout(this.tagDestroyTimeout)

            const lastTag = this.selectedList.splice(-1, 1)
            this.removedTags = [...this.removedTags, ...lastTag]
            await this.localDestoryTag()

            this.tagConfirmRemoveIndex = null
            this.currentSelectionIndex = null
            this.currentTagToRemove = {}
        },

        async localDestoryTag () {
            this.tagDestroyTimeout = setTimeout(async () => {
                const ceilCount = Math.ceil(this.removedTags.length / this.bulkCompChunkCount)
                for (let index = 0; index < ceilCount; index++) {
                    const chunkIds = this.removedTags.splice(0, this.bulkCompChunkCount)
                    if (!chunkIds || (chunkIds && !chunkIds.length)) continue
                    this.localEmitEvent('destroy', { ids: chunkIds })
                }
                clearTimeout(this.tagDestroyTimeout)
            }, 500)
        },

        localGetListBy (value, field) {
            return this.localFilteredList.filter(i => i[field] === value)
        },

        localRestProps () {
            const input = this.$el.querySelector('input')
            if (input) input.blur()

            this.currentKeyIndex = null
            this.searchText = null
            // this.modelCurrentValue = this.value
            this.localResetTagIndices()
        },
    }
}
</script>

<template>
    <a-card class="u-relative">
        <a-container container--fluid grid-list-xl class="px-6 py-6 grey lighten-5">
            <a-layout wrap :class="[!hideSubtitle ? 'align-start' : 'align-center']">
                <a-flex shrink class="pr-1" v-if="icon">
                    <a-avatar class="primary darken-1" size="40">
                        <slot name="icon">
                            <a-icon :size="iconSize" dark>{{ icon }}</a-icon>
                        </slot>
                    </a-avatar>
                </a-flex>
                <a-flex>
                    <slot name="root-title">
                        <h2 class="md-heading-6 primary--text text--darken-1" :class="{ 'mb-1': !hideSubtitle }">
                            <slot name="title"></slot>
                        </h2>
                    </slot>
                    <slot name="root-subtitle" v-if="!hideSubtitle">
                        <p class="md-body-2 mt-n1 mb-0">
                            <slot name="subtitle"></slot>
                        </p>
                    </slot>
                </a-flex>
                <div v-if="!hideCloseIcon" class="u-absolute u-cursor-pointer" @click="$emit('close', true)" style="top: 15px; right: 20px;">
                    <slot name="close-icon">
                        <a-icon size="16">clear</a-icon>
                    </slot>
                </div>
            </a-layout>
        </a-container>
        <slot name="title-action"></slot>
        <a-divider></a-divider>
        <a-card-text class="pa-0" ref="refFormBody" style="max-height: 700px; overflow-y: auto">
            <slot name="body" :bodyClass="bodyClass"></slot>
        </a-card-text>
        <template v-if="!hideFooter">
            <a-divider></a-divider>
            <a-card-actions class="grey lighten-5">
                <a-container container--fluid grid-list-xl class="px-5 py-6">
                    <a-layout wrap align-center>
                        <slot name="footer"></slot>
                    </a-layout>
                </a-container>
            </a-card-actions>
        </template>
    </a-card>

</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: '24'
        },
        iconSize: {
            type: String | Number,
            default: 18
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        hideCloseIcon: {
            type: Boolean,
            default: false
        },
        hideSubtitle: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            bodyClass: 'px-6 pb-7 pt-4'
        }
    },

    computed: {
        ref () {
            return this.$refs.refFormBody.ref
        }
    },
}
</script>

<style>

</style>

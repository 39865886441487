<template>
	<a-main>
        <a-container container--fluid grid-list-xl>
            <a-layout align-center class="mt-2">
                <a-flex>
                    <h2 class="md-heading-6 indigo--text text--darken-1 font-weight-semibold"><a-icon size="24" color="indigo" class="mr-1 u-icon-nudge">device_hub</a-icon>Workflows</h2>
                    <p class="md-body-2 mb-0 grey--text text--darken-2">Workflows to run in the background.</p>
                </a-flex>
                <a-flex shrink v-if="$can('applets.update') && workflow_list && workflow_list.length">
                    <a-btn color="primary" class="mr-0" :loading="localWorkflowStoreLoading" @click="localWorkflowStore()">
                        <a-icon class="mr-1">add</a-icon> Create Workflow
                    </a-btn>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid grid-list-xl class="my-4" v-if="!localPageLoading && workflow_list && workflow_list.length === 0">
            <a-layout align-center justify-center>
                <a-flex xs4>
                    <div class="text-center"><a-icon size="160" color="grey lighten-1">device_hub</a-icon></div>
                    <h2 class="md-heading-5 mb-1 grey--text text--darken-3 text-center">No Workflows</h2>
                    <p class="md-body-2 mb-2 grey--text text--darken-1 text-center">Setup the workflow to automate data sharing between Jira and Success.</p>
                    <div class="text-center" v-if="$can('applets.update')"><a-btn dark color="blue darken-1" :loading="localWorkflowStoreLoading" @click="localWorkflowStore()">Create Workflow</a-btn></div>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid grid-list-xl v-if="localPageLoading">
            <div class="white pa-4 u-rounded-corners u-shadow">
                <template v-for="i in 5">
                    <a-sheet :key="i" class="u-flex-center-y" style="column-gap: 16px;" :class="[{ 'mt-4': i !== 1 }]">
                        <loader-template height="40" width="100%" class="u-rounded-corners"></loader-template>
                        <loader-template height="40" width="40" class="u-rounded-corners"></loader-template>
                        <loader-template height="40" width="40" class="u-rounded-corners"></loader-template>
                    </a-sheet>
                </template>
            </div>
        </a-container>
        <template v-if="!localPageLoading">
            <a-container container--fluid grid-list-xl class="my-2 mb-10" v-if="workflow_list && workflow_list.length > 0">
                <a-layout align-center>
                    <a-flex>
                        <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden mb-3 px-3" v-for="workflow in workflow_list" :key="workflow.id">
                            <a-card-text class="pa-0">
                                <a-container container--fluid grid-list-xl class="py-3">
                                    <a-layout align-center>
                                        <a-flex shrink>
                                            <div class="d-flex" v-if="workflow.direction === null">
                                                <a-icon size="24">help</a-icon>
                                                <a-icon class="mx-2">arrow_right_alt</a-icon>
                                                <a-icon size="24">help</a-icon>
                                            </div>
                                            <div class="d-flex" v-if="workflow.direction === 'incoming'">
                                                <img src="../../assets/images/apps/jira/logo.svg" alt="Jira" style="display: inline-block; max-width: 32px; max-height: 32px;">
                                                <a-icon class="mx-2">arrow_right_alt</a-icon>
                                                <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" style="display: inline-block; max-width: 28px; max-height: 28px;">
                                            </div>
                                            <div class="d-flex" v-if="workflow.direction === 'outgoing'">
                                                <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" style="display: inline-block; max-width: 28px; max-height: 28px;">
                                                <a-icon class="mx-2">arrow_right_alt</a-icon>
                                                <img src="../../assets/images/apps/jira/logo.svg" alt="Jira" style="display: inline-block; max-width: 32px; max-height: 32px;">
                                            </div>
                                        </a-flex>
                                        <a-flex class="u-cursor-pointer">
                                            <router-link v-if="installation_item && installation_item.id && workflow.is_created === 1" :to="{name: `appstore-jira-workflows-edit`, params: { id: installation_item.id, workflow_id: workflow.id }}">
                                                <p class="md-subtitle-1 grey--text text--darken-4 mb-0" :title="workflow.title">{{ workflow.title | truncateText(100) }}</p>
                                            </router-link>
                                            <router-link v-if="installation_item && installation_item.id && workflow.is_created === 0" :to="{name: `appstore-jira-workflows-create`, params: { id: installation_item.id, workflow_id: workflow.id }}">
                                                <p class="md-body-1 mb-0" :title="workflow.title">{{ workflow.title | truncateText(100) }}</p>
                                            </router-link>
                                        </a-flex>
                                        <a-flex shrink>
                                            <p class="md-body-2 mb-0 orange--text text--darken-2 text-center" v-if="workflow.is_complete === 0">
                                                <a-icon size="22" class="mr-1 u-icon-nudge" color="orange">warning</a-icon> Invalid
                                            </p>
                                            <p class="md-body-2 mb-0 grey--text text--darken-2 text-center" v-if="workflow.is_complete === 1 && workflow.is_active === 0">
                                                <a-icon size="22" class="mr-1 u-icon-nudge" color="grey">cloud_off</a-icon> Inactive
                                            </p>
                                            <p class="md-body-2 mb-0 green--text text--darken-2 text-center" v-if="workflow.is_complete === 1 && workflow.is_active === 1">
                                                <a-icon size="22" class="mr-1 u-icon-nudge" color="green">check_circle</a-icon> Active
                                            </p>
                                        </a-flex>
                                        <a-flex shrink v-if="$can('applets.update')">
                                            <a-tooltip bottom v-if="$can('applets.update')">
                                                <template v-slot:activator="{ on }">
                                                   <a-btn icon text color="grey darken-1" v-on="on" @click="localJiraCloneCreate(workflow.id)">
                                                        <a-icon size="16">content_copy</a-icon>
                                                    </a-btn>
                                                </template>
                                                <span>Clone Workflow</span>
                                            </a-tooltip>
                                            <a-menu bottom left offset-y max-width="176">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn icon text small color="grey darken-1" class="mx-0" v-on="on">
                                                        <a-icon size="16">delete</a-icon>
                                                    </a-btn>
                                                </template>
                                                <a-card flat>
                                                    <a-card-text class="pa-0">
                                                        <div class="pa-3">
                                                            <p class="md-body-2 mb-0 text-center grey--text text--darken-3">Workflow will be deleted. This action cannot be reversed.</p>
                                                        </div>
                                                        <a-btn color="red darken-1" class="elevation-0 ma-0" block dark small @click="localWorkflowDestroy(workflow.id)">Confirm Delete</a-btn>
                                                    </a-card-text>
                                                </a-card>
                                            </a-menu>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-card-text>
                        </a-card>
                    </a-flex>
                </a-layout>
            </a-container>
        </template>

        <a-dialog v-model="dialogJiraCloneForm" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="40">
                                    <a-icon dark>content_copy</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1">Clone Workflow</h2>
                                <p class="md-subtitle-2 mb-0">Create a copy of this workflow</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title</label>
                                <a-text-field
                                    v-model="workflow_item.title"
                                    placeholder="Enter the title"
                                    background-color="neutral"
                                    solo flat hide-details>
                                </a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(workflow_response,'title')">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                      {{ $response(workflow_response,'title') }}
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16">info</a-icon>
                                   This will help you identify the workflow
                                </span>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink>
                                <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="localWorkflowClone()" :loading="loading" :disabled="loading" >Clone Workflow</a-btn>
                                <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="localWorkflowCloneCancel()">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
       </a-dialog>

	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            localPageLoading: true,
            dialogJiraCloneForm: false,
            localWorkflowStoreLoading: false,
        }
    },
    mounted() {
        this.localIndex()
    },
    computed: {
        ...mapState('Installation', {
            installation_item: 'item',
        }),

        ...mapState('InstallationAccount', {
            installation_account_list: 'list',
            installation_account_filters: 'filters',
        }),

        ...mapState('Workflow', {
            workflow_item: 'item',
            workflow_list: 'list',
            workflow_filters: 'filters',
            workflow_response:'response'
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async localIndex () {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.localPageLoading = false
                return this.$router.replace({name: 'errors-unauthorized'})
            }
            
            await this.workflow_clear()
            await this.workflow_index({ 'filter[installation_id]': this.$route.params.id })
            this.localPageLoading = false
        },

        async localWorkflowStore () {
            this.localWorkflowStoreLoading = true
            await this.workflow_clear_item()
            await this.workflow_store({ installation_id: this.installation_item.id, 'order': -1 })
            this.localWorkflowStoreLoading = false
            this.$router.push({name: `appstore-jira-workflows-create`, params: { id: this.installation_item.id, workflow_id: this.workflow_item.id }})
        },

        async localWorkflowDestroy (id) {
            await this.workflow_destroy({id: id})
        },

        async localJiraCloneCreate (id) {
            await this.workflow_select({id: id})
             this.workflow_item.title = 'Copy of ' + this.workflow_item.title
            this.dialogJiraCloneForm = true
        },

        async localWorkflowClone (){
            await this.workflow_clone({title:this.workflow_item.title,workflow_id:this.workflow_item.id})
            if (!this.$status(this.workflow_response)) return
            this.dialogJiraCloneForm = false
            this.$notify('success', 'Workflow cloned successfully!')
        },

        async localWorkflowCloneCancel () {
            await this.workflow_clear_item()
            this.dialogJiraCloneForm = false
        },

        ...mapActions('Installation', {
            installation_show: 'show',
            installation_clear: 'clear',
            installation_clear_item: 'clear_item',
        }),

        ...mapActions('InstallationAccount', {
            installation_account_index: 'index',
            installation_account_destroy: 'destroy',
            installation_account_clear: 'clear',
        }),

        ...mapActions('Workflow', {
            workflow_index: 'index',
            workflow_store: 'store',
            workflow_select:'select',
            workflow_clone: 'clone_store',
            workflow_destroy: 'destroy',
            workflow_clear: 'clear',
            workflow_clear_item: 'clear_item',
        }),
    },
}
</script>

<template>
    <div
        v-if="!light"
        class="u-absolute u-rounded-corners u-wfull u-hfull"
        style="
            z-index: 0;
            left: 0px; top: 0px;
            background: linear-gradient(91deg, #E8EAF6 0.77%, rgba(232, 234, 246, 0.00) 99.51%);
        "
    />

    <div
        v-else
        class="u-absolute u-rounded-corners u-wfull u-hfull"
        style="
            z-index: 0;
            left: 0px; top: 0px;
            background: #E8EAF6;
        "
    />
</template>

<script>
export default {
    props: {
        light: {
            type: Boolean,
            default: false
        }
    }
}
</script>

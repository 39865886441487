<template>
    <div>
        <div class="c-custom-editor u-rounded-corners-lg white u-shadow mb-8">
            <slot name="format-bar" v-bind:editorInstance="editor">
                <s-editor-format-bar :editor="editor" :icon-size="iconSize" :icon-color="iconColor"></s-editor-format-bar>
            </slot>
            <a-divider class="grey lighten-4" v-if="!hideDivider"></a-divider>
            <slot name="editor" v-bind:content="editor">
                <div class="pa-4">
                    <editor-content :editor="editor" />
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import { generateHTML } from '@tiptap/html'
import { SEditorFormatBar } from '@/config/config-shared-components'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Underline from '@tiptap/extension-underline'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import marked from 'marked'

export default {
    name: 'GlobalEditor',

    props: {
        value: {
            type: String | Object,
            required: true
        },
        returnType: {
            type: String,
            default: 'json'
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        hideDivider: {
            type: Boolean,
            default: false
        },
        iconSize: {
            type: String | Number,
            default: '24'
        },
        iconColor: {
            type: String,
            default: '#444'
        }
    },

    components: { EditorContent, SEditorFormatBar },

    data () {
        return {
            editor: null,
            show_editor: false,
        }
    },

    created () {
        this.editor = new Editor({
            extensions: [StarterKit, Document, Underline, ListItem, BulletList, OrderedList],
            content: this.editorContent ?? ' ',
            autofocus: false,
            editable: this.canUpdate,
            parseOptions: {
                preserveWhitespace: 'full'
            },
            onUpdate: (_) => this.local_update()
        })
        // setTimeout(() => {
        //     this.show_editor = true
        // }, 200);
    },

    beforeDestroy() {
        this.editor.destroy()
    },

    computed: {
        returnData () {
            return this.returnType === 'json' ? this.editor.getJSON() : this.editor.getHTML()
        },

        editorContent () {
            return generateHTML(JSON.parse(this.value), [StarterKit, Document, Underline, ListItem, BulletList, OrderedList])
            // if (this.value && this.value.slice(1, 13) === '"type":"doc"') return JSON.parse(this.value)
            // const html = marked(this.value ?? ' ')
            // return generateJSON(html, [StarterKit, Document, Underline, ListItem, BulletList, OrderedList])
            // setTimeout(() => {
            //     // console.log('VALUE: ', this.value, this.value.slice(1, 13), this.value.slice(1, 13) === '"type":"doc"')
            // }, 100)
        },
    },

    methods: {
        local_update () {
            this.$emit('input', this.returnData)
            this.$emit('update', this.returnData)
        },

    }
}
</script>

<style lang="scss" scoped>
// .ProseMirror {
//     outline: none !important;
//     > * + * { margin-top: 0.25em; }
//     p { margin-bottom: 0.25em !important; }
//     ul, ol { padding: 0 1rem; }
//     ul { @apply t-list-decimal; }
//     ol { @apply t-list-disc; }
//     a {
//         cursor: auto !important;
//         @apply t-text-blue-700;
//     }
// }
</style>

<template>
    <a-progress-circular class="c-meter" :value="100" :size="size ? size : 24" :width="2" :color="objectFill ? dataColor.line : dataColor" v-on="$listeners">
        <a-progress-circular :rotate="-90" :size="size ? (size - 6) : (fill ? 26 : 18)" :width="size ? (size / 2) : (fill ? 13 : 9)" :value="value || 100" :color="objectFill ? dataColor.bg : dataColor">
            <slot>
                <a-icon v-if="value === 100 && !empty" size="14" :color="$color(color, 'color_text')">done</a-icon>
            </slot>
        </a-progress-circular>
    </a-progress-circular>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            required: true
        },
        color: {
            type: String | Object
        },
        size: {
            type: Number,
            default: 0
        },
        empty: {
            type: Boolean,
            default: false
        },
        objectFill: {
            type: Boolean,
            default: false
        },
        fill: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        dataColor () {
            if (this.objectFill) return this.color
            if (this.color.startsWith('#')) return this.color
            return this.$color(this.color ?? 'teal-dark', 'color_bg')
        }
    }
}
</script>

import Vue from 'vue'

const api_endpoint = '/report-template-blocks'
const report_templates_blocks_attr = '/report-template-block-attributes'

export default {
    namespaced: true,
    state: {
        list: [],
        attributes: [],
        item: {},
        meta: {},
        filters: {
            status: 'all',
            include: '',
            page: 1,
            count: 10,
            sort_direction: 'asc'
        },
        response: [],
        defaults: {
            list: [],
            attributes: [],
            item: {},
            meta: {},
            filters: {
                status: 'all',
                include: '',
                page: 1,
                count: 10,
                sort_field: 'company_name',
                sort_direction: 'asc'
            },
            response: [],
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_BLOCK_ATTRS(state, payload) {
            const index = state.list.findIndex(item => item.id === payload.rt_block_id)
            if (index !== -1) state.list[index].report_template_block_attributes.push(payload)
        },
        ADD_ATTRIBUTES(state, payload) {
            state.attributes.push(payload)
        },
        ADD_BLOCK_TEMPLATE(state, payload) {
            state.block_template = payload
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        FIND_REMOVE_BLOCK(state, payload) {
            const index = _.findIndex(state.list, { id: payload })
            if (index !== -1) state.list.splice(index, 1)
        },
        MEMBER_ITEM(state, member_item) {
            state.member_item = member_item;
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload;
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        ADD_ITEM(state, item) {
            const index = _.findIndex(state.list, { 'id': item.response.id });
            if (index !== -1) Vue.set(state.list, index, item.response)
            else {
                if (item.index) state.list.splice(item.index, 0, item.response)
                else state.list.push(item.response);
            }
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        async actionTemplateBlockList (context, payload) {
            context.commit('UPDATE_LIST', payload ? payload : [])
        },
        async actionTemplateBlockStore (context, payload) {
            this.dispatch('Interface/loader_start')
            const data = Object.assign({ ...payload.data }, { id: payload.id })
            try {
                const { data: { data: response } } = await axios.post(api_endpoint, data, { params: { ...payload.params } })
                this.dispatch('Interface/loader_stop')
                await context.dispatch('actionTemplateBlockShow', { id: payload.id, index: payload.index })
                // context.commit('UPDATE_ITEM', response)
                // context.commit('ADD_ITEM', { response, index: payload.index })
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async actionTemplateBlockShow (context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const params = {
                    include: 'reportTemplateBlockAttributes,reportBlock,reportTemplateBlockAttributes.reportBlockAttribute',
                    'fields[report_template_blocks]': 'id,content,order,report_block_id,report_template_id',
                    'fields[report_template_block_attributes]': 'id,value,rb_attribute_id,rt_block_id',
                    'fields[report_template_block_attributes.report_block_attribute]': 'id,title',
                }
                const { data: { data: response } } = await axios.get(api_endpoint + '/' + payload.id, { params: { ...params } })
                this.dispatch('Interface/loader_stop')
                context.commit('UPDATE_ITEM', response)
                context.commit('ADD_ITEM', { response, index: payload.index })
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async actionTemplateBlockEdit (context, payload) {
            if (payload.content_text && (!payload.content_text.length || payload.content_text === '')) return
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data: response } } = await axios.patch(`${api_endpoint}/${payload.id}`, payload)
                this.dispatch('Interface/loader_stop')
                // console.log(response)
                // context.commit('UPDATE_LIST_ITEM', response)
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async actionTemplateBlockDelete (context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.delete(`${api_endpoint}/${payload.id}`)
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async actionTemplateBlockReorder (context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.patch(`${api_endpoint}/reorder`, { data: payload.list })
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        actionFindAndRemoveBlock (context, payload) {
            context.commit('FIND_REMOVE_BLOCK', payload.id)
        },

        // Template Block Attributes
        async actionTemplateBlockAttrsStore (context, payload) {
            try {
                const { data: { data: response } } = await axios.post(report_templates_blocks_attr, payload)
                // console.log(response)
                context.commit('ADD_ATTRIBUTES', response)
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async actionTemplateBlockAttrEdit (context, payload) {
            const id = payload.id ? payload.id : ''
            try {
                if (id === '') delete payload.id
                await axios.patch(report_templates_blocks_attr + '/' + id, payload)
                // context.commit('UPDATE_BLOCK_ATTRS', response)
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        // End Template Block Attributes

        actionTemplateBlockClear (context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        actionTemplateBlockClearItem (context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },

    getters: {
        getTemplateBlocks: (state) => {
            const list = [...state.list]
            return list
        },
        getBlockAttrs: (state) => (id, attr_id) => {
            const block = state.list.find(block => block.id === id)
            if (block) {
                if (block.report_template_block_attributes) {
                    const attr = block.report_template_block_attributes.find(item => item.rb_attribute_id === attr_id)
                    return attr
                }
            }
        },
        getTemplateBlockItem: (state) => state.item,
        getBlockAttribute: (state) => (id) => {
            return state.attributes.find(item => item.rt_block_id === id)
        }
    }
}

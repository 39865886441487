<template>
    <a-chip
        class="md-subtitle-1 ml-2 my-0 py-1 px-2 font-weight-bold text-uppercase"
        :class="[
            { 'u-shadow': shadow },
            invert
                ? 'indigo--text text--darken-1 white'
                : 'indigo darken-1 white--text'
        ]"
        style="font-size: 9px;"
        small label
    >
        Beta
    </a-chip>
</template>

<script>
export default {
    props: {
        invert: {
            type: Boolean,
            default: false
        },
        shadow: {
            type: Boolean,
            default: true
        },
    }
}
</script>

<template>
	<div class="c-usecase-item-view mb-16 mr-2">
        <a-container container--fluid grid-list-xl class="pl-8 pr-0">
            <a-layout wrap align-start>
                <a-flex xs12 class="pb-0">
                    <a-sheet max-height="120" min-height="34" class="py-1 mb-2 u-shadow u-border u-rounded-corners u-overflow-y">
                        <a-textarea v-model="usecase_title" rows="1" @input="local_update_title()" :disabled="!check_permission('update')" solo flat auto-grow no-resize hide-details>
                            <template v-slot:append v-if="usecase_item && usecase_item.code"><span class="grey--text text--darken-1 u-rounded-corners grey lighten-4 py-1 px-2">{{ usecase_item.code }}</span></template>
                        </a-textarea>
                    </a-sheet>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(usecase_response, 'title')">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ $response(usecase_response, 'title') }}
                   </span>
                </a-flex>
            </a-layout>

            <!-- Usecase Assignees -->
            <a-layout wrap align-center v-if="usecase_item">
                <a-flex shrink>
                    <a-card @click="$router.push({name: 'projects-usecases'})" width="36" height="36" class="u-rounded-corners-full u-overflow-hidden u-flex align-center justify-center u-elevation-custom-1 u-cursor-pointer">
                        <a-icon size="18" class="ma-0" color="grey darken-2">arrow_back</a-icon>
                    </a-card>
                </a-flex>
                <a-flex shrink pl-0 v-if="usecase_item && usecase_item.assignees">
                    <s-assignee-form
                        :users-list="local_member_list(usecase_item.assignees)"
                        :edit-item="usecase_item"
                        :project-id="$route.params.id"
                        :can-update="check_permission('update')"
                        @change-visibility="local_add_external_user"
                        module-type="Usecase"
                        hide-external-confirmation
                    >
                        <template #header-title>Use Case Assignees</template>
                        <template #default="{ showDialog }">
                            <s-user-list-popup
                                :item="usecase_item"
                                :count="10"
                                :show-empty-profile="true"
                                :local_loading="local_loading"
                                :show-add-button="usecase_item.assignees && usecase_item.assignees.length ? false : true"
                                :can_update="check_permission('update')"
                                @show_add_users_dialog="showDialog"
                                class="u-flex align-center justify-end mr-3" style="min-height: 32px;"
                            ></s-user-list-popup>
                        </template>
                    </s-assignee-form>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink class="u-flex align-center">
                    <a-chip v-if="timer_update_debounce" color="white" text-color="grey" class="my-0 mr-2" small>Editing</a-chip>
                    <a-chip v-if="!timer_update_debounce && update_autosaved_at" color="white" text-color="green" class="my-0 mr-2" small><g-moment :value="update_autosaved_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="[Autosaved at] h:mma"></g-moment></a-chip>
                    <template v-if="usecase_item && usecase_item.visibility">
                        <a-btn small v-if="usecase_item.visibility === 'external'" v-ripple="check_permission('update')" class="ml-0 mt-0 mb-0 mr-2 px-2 u-shadow orange darken-1 white--text" @click="check_permission('update') ? local_visibility_toggle(usecase_item.id, usecase_item.visibility) : ''">
                            <a-icon left class="mr-2" size="12">lock_open</a-icon>Public
                        </a-btn>
                        <g-confirmation class="d-inline-block" :can-update="check_permission('update')" v-else type="use case" @action="check_permission('update') ? local_visibility_toggle(usecase_item.id, usecase_item.visibility) : ''">
                            <a-btn small v-ripple="check_permission('update')" class="ml-0 mt-0 mb-0 mr-2 px-2 white u-shadow grey--text text--darken-1"><a-icon left class="mr-2" size="12">lock</a-icon>Private</a-btn>
                        </g-confirmation>
                    </template>
                    <a-btn v-if="usecase_item && usecase_item.status" small class="ml-0 mt-0 mb-0 mr-2 px-2 white--text u-shadow" :color="local_usecase_is_open ? '#007692' : '#00832e'" v-ripple="check_permission('update')" @click="check_permission('update') ? local_toggle_usecase_status(usecase_item.id, local_usecase_is_open ? 'closed' : 'open') : ''">
                        {{ usecase_item.status }} Use Case
                    </a-btn>
                    <a-menu bottom left offset-y :close-on-content-click="false" max-width="320" v-if="check_permission('destroy')">
                        <template v-slot:activator="{ on }">
                            <a-card class="u-elevation-custom-1 u-rounded-corners u-flex align-center pa-2" height="28" v-on="on">
                                <a-icon size="16" color="red darken-1">delete</a-icon>
                            </a-card>
                        </template>
                        <a-card>
                            <a-card-text class="pa-4" v-if="usecase_item">
                                <h2 class="md-heading-6 mb-2">Are you sure to delete this use case?</h2>
                                <p class="body-2 mb-4">This will delete all test cases and success criteria under this use case and cannot be undone.</p>
                                <a-btn class="ma-0 elevation-0" color="red" @click="local_destroy(usecase_item.id)" :loading="loading" dark small>
                                    <a-icon size="16" left class="mr-2">delete</a-icon> Delete
                                </a-btn>
                            </a-card-text>
                        </a-card>
                    </a-menu>
                </a-flex>
            </a-layout>
            <a-layout wrap align-center class="mx-0 my-3" v-else>
                <a-sheet class="u-wfull grey lighten-2 u-rounded-corners-full mr-2" width="42" height="42"></a-sheet>
                <a-sheet class="u-wfull grey lighten-2 u-rounded-corners-full" width="150" height="42"></a-sheet>
                <a-spacer></a-spacer>
                <a-sheet class="u-wfull grey lighten-2 u-rounded-corners mr-2" width="80" height="32"></a-sheet>
                <a-sheet class="u-wfull grey lighten-2 u-rounded-corners" width="100" height="32"></a-sheet>
            </a-layout>
        </a-container>

        <!-- Usecase description -->
        <a-container container--fluid grid-list-xl pt-0 pl-8 pr-0>
            <a-flex xs12>
                <p-editor-layout
                    :can-update="check_permission('update')"
                    :expanded="accordion_usecase_description_expanded"
                    @expand="(evt) => accordion_usecase_description_expanded = evt"
                >
                    <template #content>
                        <a-sheet class="px-6 pt-4 pb-2 u-overflow-y u-rounded-corners" max-height="400" v-if="accordion_usecase_description_expanded">
                            <p class="grey--text text--darken-1" v-if="usecase_item && (!usecase_item.description || usecase_item.description && usecase_item.description.replace(/<[^>]*>?/gm, '') === null || usecase_item.description && usecase_item.description.replace(/<[^>]*>?/gm, '') == '')" @click="() => { usecase_item.description = ' '; editor_description.focus() }">Click to edit the description</p>
                            <editor-content v-else :editor="editor_description"></editor-content>
                            <s-editor-menu v-if="check_permission('update')" style="z-index: 4" :content="editor_description" :can-update="check_permission('update')"></s-editor-menu>
                        </a-sheet>
                    </template>
                </p-editor-layout>
            </a-flex>
            <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="usecase_response.server && usecase_response.server.errors && usecase_response.server.errors.description">
                <a-icon size="16" color="red darken-2">warning</a-icon>
                {{ usecase_response.server.errors.description[0] }}
            </span>
        </a-container>
        <!-- <a-layout wrap align-center>
        </a-layout> -->

        <!-- Usecase tags -->
        <template v-if="(usecase_item && usecase_item.id) && user_self.role && user_self.role.scope === 'internal'">
            <a-container container--fluid grid-list-xl class="mb-2 mt-4 pt-0 pb-4 pl-8 pr-0">
                <a-layout wrap align-center>
                    <a-flex class="pt-0 pr-0" shrink v-if="user_self.role && user_self.role.scope === 'internal'">
                        <a-tooltip bottom right>
                            <template v-slot:activator="{ on }">
                                <a-icon size="16" v-on="on">lock</a-icon>
                             </template>
                            <span>Only Visible To Team</span>
                        </a-tooltip>
                    </a-flex>
                    <a-flex class="pt-0">
                        <h3 class="md-caption grey--text text--darken-1 d-inline-block text-uppercase font-weight-bold">Tags</h3>
                    </a-flex>
                </a-layout>

                <a-layout wrap align-center>
                    <a-flex xs12 class="py-0 px-2 mx-1">
                        <div class="d-inline-flex align-center">
                            <div class="pa-0 d-inline-flex flex-wrap">
                                <template v-if="usecase_item.tags && usecase_item.tags.length">
                                    <template v-for="tag in usecase_item.tags">
                                        <g-tags :can-update="check_permission('update')" :tag="tag" type="Usecase" @destroy="local_associate_tag_destroy(tag, usecase_item)" :key="tag.id"></g-tags>
                                    </template>
                                </template>
                                <a-menu bottom offset-y v-model="show_usecase_tag_add_input" v-if="check_permission('update')" :close-on-content-click="false" ref="refAddTagPopup" transition="slide-y-transition" nudge-bottom="8">
                                    <template v-slot:activator="{ on }">
                                        <g-add-btn v-on="on" dark></g-add-btn>
                                    </template>
                                    <a-sheet class="pb-1" max-width="240">
                                        <s-tags-inline-form
                                            :item="usecase_item"
                                            :can-update="$can('tags.store')"
                                            :list="local_get_filtered_tags(usecase_item)"
                                            :is-menu-open="show_usecase_tag_add_input"
                                            source="Usecase"
                                            type="usecase_tag"
                                            @store="local_load_tags_list"
                                            @after-store="() => show_usecase_tag_add_input = false"
                                        ></s-tags-inline-form>
                                    </a-sheet>
                                </a-menu>
                            </div>
                        </div>
                    </a-flex>
                </a-layout>
            </a-container>
        </template>
        <a-divider v-if="usecase_item && usecase_item.id"></a-divider>

        <!-- Sidbar Edit Pane -->
        <v-navigation-drawer ref="refNavDrawer" v-model="show_edit_pane_view" width="750" hide-overlay stateless fixed right temporary style="z-index: 100; margin-top: 3px;" :style="[check_permission('comment') ? {paddingBottom: '227px'} : {}]">
            <!-- <a-sheet width="750" v-click-outside="evt => local_hide_edit_pane_view(evt, local_edit_pane_item)" class="c-testcase-pane py-4 u-overflow-y u-elevation-custom-1" :class="{ 'c-show-pane': show_edit_pane_view }"> -->
            <p-edit-pane
                :edit-item="local_edit_pane_item"
                :loaders="loaders"
                :showView="show_edit_pane_view"
                :comment-module-type="edit_pane_module"
                :can-update="check_permission('update')"
                :can-comment="check_permission('comment')"
                :can-delete="check_permission('destroy')"
                :error="local_pane_response"
                :hide-time-progress="edit_pane_module === 'SuccessCriterion'"
                :show-dates="edit_pane_module !== 'SuccessCriterion'"
                @update="local_update_pane_item"
                @destroy="local_destroy_pane_item"
                @close="local_clear_edit_pane_view"
                @comment-store="local_adjust_pane_height"
                @open-timetracker="local_open_time_track"
                v-if="local_edit_pane_item"
            >
                <template #status>
                    <p-status-menu
                        :can-update="check_permission('update')"
                        :parent-list="local_pane_model_parent_list"
                        :status-list="local_pane_status_list"
                        :item="local_edit_pane_item"
                        :module="edit_pane_module"
                        @status-comment-store="local_add_status_comment"
                        input-class="py-1"
                        update-list-item
                    ></p-status-menu>
                </template>
                <template #assignees v-if="local_edit_pane_item && local_edit_pane_item.assignees">
                    <s-assignee-form
                        :users-list="local_member_list(local_edit_pane_item.assignees)"
                        :edit-item="local_edit_pane_item"
                        :project-id="$route.params.id"
                        :can-update="check_permission('update')"
                        :is-external="usecase_item.visibility === 'external'"
                        :module-type="edit_pane_module"
                        @change-visibility="local_add_external_user"
                        parent-type="Usecase"
                    >
                        <template #header-title>{{ edit_pane_module === 'Testcase' ? 'Test Case' : 'Success Criterion' }} Assignees</template>
                        <template #default="{ showDialog }">
                            <s-user-list-popup
                                :item="local_edit_pane_item"
                                :count="8"
                                :show-empty-profile="false"
                                :local_loading="local_loading"
                                :can_update="check_permission('update')"
                                @show_add_users_dialog="showDialog"
                                class="u-flex align-center mr-3" style="min-height: 32px;"
                            ></s-user-list-popup>
                        </template>
                    </s-assignee-form>
                </template>
                <template #tag-form="{ toggleMenu, isMenuOpen }">
                    <s-tags-inline-form
                        :item="local_edit_pane_item"
                        :can-update="$can('tags.store')"
                        :list="local_get_filtered_tags(local_edit_pane_item)"
                        :source="edit_pane_module"
                        :type="edit_pane_module === 'Testcase' ? 'testcase_tag' : 'success_criterion_tag'"
                        :is-menu-open="isMenuOpen"
                        parent-type="Usecase"
                        @store="local_load_tags_list"
                        @after-store="() => toggleMenu(false)"
                    ></s-tags-inline-form>
                </template>
                <!-- <template #comment-area>
                    <g-comment-box class="grey lighten-4" sticky :type="edit_pane_module" :item="local_edit_pane_item" @store="local_comment_store"></g-comment-box>
                </template> -->
            </p-edit-pane>
        </v-navigation-drawer>
        <!-- Sidbar Edit Pane --- END -->

        <template v-if="!local_loading">
            <!-- Testcase Toolbar -->
            <p-tsc-toolbar module="Test case" :user="user_self"></p-tsc-toolbar>
            <!-- Testcase Toolbar --- END -->

            <!-- Testcase Card -->
            <a-container v-if="usecase_item && usecase_item.id" container--fluid grid-list-xl class="pt-0 pl-8">
                <a-layout wrap align-start>
                    <a-flex xs12>
                        <draggable v-model="testcase_list" @start="local_drag_start" :options="{handle: '.drag_handle_icon'}">
                            <template v-for="(testcase, index) in testcase_list">
                                <p-testcase-section
                                    :testcase="testcase"
                                    :index="index"
                                    :key="testcase.id"
                                    :class="{ 'blue lighten-5': local_edit_pane_item.id === testcase.id }"
                                    @testcase-edit="local_show_edit_pane(testcase, 'Testcase')"
                                    @open-timetracker="local_open_time_track"
                                >
                                    <template #assignees v-if="testcase && testcase.assignees">
                                        <s-assignee-form
                                            :users-list="local_member_list(testcase.assignees)"
                                            :edit-item="testcase"
                                            :project-id="$route.params.id"
                                            :can-update="check_permission('update')"
                                            :is-external="usecase_item.visibility === 'external'"
                                            @change-visibility="local_add_external_user"
                                            parent-type="Usecase"
                                            module-type="Testcase"
                                        >
                                            <template #header-title>Test Case Assignees</template>
                                            <template #default="{ showDialog }">
                                                <s-user-list-popup
                                                    :count="2"
                                                    :item="testcase"
                                                    :can_update="check_permission('update')"
                                                    @show_add_users_dialog="showDialog"
                                                    class="mr-5"
                                                ></s-user-list-popup>
                                            </template>
                                        </s-assignee-form>
                                    </template>
                                    <template #status>
                                        <template v-if="testcase && testcase.status">
                                            <p-status-menu
                                                :can-update="check_permission('update')"
                                                :testcase-list="testcase_list"
                                                :status-list="testcase_status_list"
                                                :item="testcase"
                                            ></p-status-menu>
                                        </template>
                                        <a-sheet height="40" width="118" v-else>
                                            <a-sheet height="28" class="grey lighten-3 u-rounded-corners-full"></a-sheet>
                                        </a-sheet>
                                    </template>
                                </p-testcase-section>
                            </template>
                        </draggable>
                        <a-form @submit.prevent="local_testcase_store()" v-if="check_permission('update')">
                            <a-text-field
                                v-model="testcase_title"
                                prepend-inner-icon="add"
                                placeholder="Add New Test Case"
                                class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                solo flat hide-details
                            >
                            </a-text-field>
                        </a-form>
                        <template v-if="!show_edit_pane_view">
                            <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="testcase_response.server && testcase_response.server.errors && testcase_response.server.errors.title && testcase_form_mode =='add'">
                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                {{ testcase_response.server.errors.title[0] }}
                            </span>
                        </template>
                    </a-flex>
                </a-layout>
            </a-container>
            <!-- Testcase Card --- END -->

            <a-divider v-if="usecase_item && usecase_item.id" class="mt-3"></a-divider>

            <!-- Success Criteria Toolbar -->
            <p-tsc-toolbar module="Success Criteria" :user="user_self"></p-tsc-toolbar>
            <!-- Success Criteria Toolbar --- END -->

            <!-- Success Criteria Card -->
            <a-container v-if="usecase_item && usecase_item.id" container--fluid grid-list-xl class="pt-0 pl-8">
                <a-layout wrap align-center>
                    <a-flex xs12>
                        <draggable v-model="success_criterion_list" @start="local_drag_start" :options="{handle: '.drag_handle_icon'}">
                            <template v-for="(success_criterion, index) in success_criterion_list">
                                <p-success-criteria-section
                                    :success_criterion="success_criterion"
                                    :index="index"
                                    :key="success_criterion.id"
                                    :class="{ 'blue lighten-5': local_edit_pane_item.id === success_criterion.id }"
                                    @edit-view="local_show_edit_pane(success_criterion, 'SuccessCriterion')"
                                >
                                    <template #assignees v-if="success_criterion && success_criterion.assignees">
                                        <s-assignee-form
                                            :users-list="local_member_list(success_criterion.assignees)"
                                            :edit-item="success_criterion"
                                            :project-id="$route.params.id"
                                            :can-update="check_permission('update')"
                                            :is-external="usecase_item.visibility === 'external'"
                                            @change-visibility="local_add_external_user"
                                            parent-type="Usecase"
                                            module-type="SuccessCriterion"
                                        >
                                            <template #header-title>Success Criterion Assignees</template>
                                            <template #default="{ showDialog }">
                                                <s-user-list-popup
                                                    :count="2"
                                                    :item="success_criterion"
                                                    :can_update="check_permission('update')"
                                                    @show_add_users_dialog="showDialog"
                                                    class="mr-5"
                                                ></s-user-list-popup>
                                            </template>
                                        </s-assignee-form>
                                    </template>
                                    <template #status>
                                        <template v-if="success_criterion && success_criterion.status">
                                            <p-status-menu
                                                :can-update="check_permission('update')"
                                                :testcase-list="success_criterion_list"
                                                :status-list="success_criterion_status_list"
                                                :item="success_criterion"
                                                module="SuccessCriterion"
                                            ></p-status-menu>
                                        </template>
                                        <a-sheet height="40" width="118" v-else>
                                            <a-sheet height="28" class="grey lighten-3 u-rounded-corners-full"></a-sheet>
                                        </a-sheet>
                                    </template>
                                </p-success-criteria-section>
                            </template>
                        </draggable>
                        <a-form @submit.prevent="local_success_criterion_store()" v-if="check_permission('update')">
                            <a-text-field
                                v-model="success_criterion_title"
                                prepend-inner-icon="add"
                                placeholder="Add New Success Criteria"
                                class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden"
                                solo flat hide-details
                            >
                            </a-text-field>
                        </a-form>
                        <template v-if="!show_edit_pane_view">
                            <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="success_criterion_response.server && success_criterion_response.server.errors && success_criterion_response.server.errors.title && success_criterion_form_mode  =='add'">
                                <a-icon size="16" color="red darken-2">warning</a-icon>
                                {{ success_criterion_response.server.errors.title[0] }}
                            </span>
                        </template>
                    </a-flex>
                </a-layout>
            </a-container>
            <!-- Success Criteria Card --- END -->
        </template>

        <template v-if="local_loading">
            <a-divider class="transparent mt-4"></a-divider>
            <h2 class="md-subtitle-1 grey--text text--darken-1 text-center mt-4">Loading test case and success criteria</h2>
            <a-sheet class="transparent mx-auto" max-width="180">
                <a-progress-linear height="4" color="blue darken-1" indeterminate class="u-rounded-corners-full mt-3"></a-progress-linear>
            </a-sheet>
        </template>

        <a-dialog v-model="dialog_est_list" scrollable persistent max-width="700">
            <template v-if="time_track_testcase">
                <s-time-tracker
                    model="test case"
                    :title="time_track_testcase ? time_track_testcase.title : ''"
                    @add-entry="local_add_entry"
                    @delete-entry="local_delete_entry"
                    @close="local_close_time_track"
                    :item="time_track_testcase"
                    :loading="loading"
                    :records="time_record_list"
                    :can-update="check_permission('update')"
                >
                    <template v-slot:record-progress>
                        <s-time-progress
                            :limit="700"
                            :item="time_track_testcase"
                            :sum-mins="time_track_testcase.time_records_sum_duration_minutes"
                            :est-mins="time_track_testcase.estimated_duration_minutes"
                            :est-text="time_track_testcase.estimated_duration_text"
                            class="pb-2"
                            :can-update="check_permission('update')"
                            allow-est-edit
                            @est-update="local_est_update"
                        ></s-time-progress>
                    </template>
                </s-time-tracker>
            </template>
        </a-dialog>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { Editor, EditorContent } from 'tiptap'
import { Bold, Italic, OrderedList, BulletList, ListItem, History, } from 'tiptap-extensions'
import marked from 'marked'

import { PEditorLayout, PTestcaseSection, PSuccessCriteriaSection, PStatusMenu, PEditPane, PTscToolbar } from './Partials'
import { STimeTracker, STimeProgress, SUserListPopup, SAssigneeForm, STagsInlineForm, SEditorMenu } from '@/config/config-shared-components'
import { calculateByText } from '@/helpers/helper-time-tracker.js'

export default {
    components: {
        EditorContent,
        PEditorLayout, PEditorLayout, PTestcaseSection, PSuccessCriteriaSection, PStatusMenu, PEditPane, PTscToolbar,
        SUserListPopup, STagsInlineForm, SEditorMenu, SAssigneeForm, STimeTracker, STimeProgress
    },

    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            loaders: {
                sidebar_tags_loading: false,
                sidebar_time_est_loading: false,
                sidebar_comments_loading: false,
                content_autosave_response: false,
            },
            content_autosave_timeout: false,
            edit_mode: false,
            testcase_title: '',
            success_criterion_title: '',
            usecase_title: '',
            usecase_description: '',
            testcase_status_list: [],
            edit_pane_module: '',
            local_edit_pane_item: { tags: [], comments: [] },
            show_edit_pane_view: false,
            success_criterion_status_list: [],
            editor_description: null,
            timer_update_debounce: null,
            update_autosaved_at: null,
            local_collaborator_list: [],
            local_commentable_type: '',
            local_select_id: '',
            local_status_id: '',
            local_comment_text: null,
            comment_text: [],
            show_usecase_tag_add_input: false,
            accordion_usecase_description_expanded: true,
            local_loading: true,
            local_tag_fields: {
                'filter[type]': 'usecase_tag',
                'fields[tags]': 'id,label,type,color'
            },
            local_usecase_fields: {
                'include': 'tags,assignees',
                'fields[usecases]': 'id,title,description,code,project_id,status,visibility',
                'fields[tags]': 'tags.id,tags.label,tags.type,tags.color',
                'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
            },
            local_testcase_fields: {
                'filter[usecase_id]': this.$route.params.usecase_id,
                'include': 'assignees,status,tags,comments.createdBy',
                'fields[testcases]': 'id,title,description,code,estimated_duration_minutes,estimated_duration_text,status_id,start_date,due_date',
                'fields[status]': 'id,type,value,status',
                'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                'aggregate[time_records.duration_minutes]': 'sum'
            },
            local_success_criterion_fields: {
                'filter[usecase_id]': this.$route.params.usecase_id,
                'include': 'assignees,status',
                'fields[status]': 'id,type,value,status',
                'fields[success_criteria]': 'id,title,code,status_id,description',
                'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
            },
            local_testcase_status_fields: {
                'filter[type]': 'testcase_status',
                'fields[metas]': 'id,type,value,status',
                'sort': 'order'
            },
            local_success_criterion_status_fields: {
                'filter[type]': 'success_criterion_status',
                'fields[metas]': 'id,type,value,status',
                'sort': 'order'
            },
            time_record_params: {
                'include': 'createdBy',
                'fields[time_records]': 'id,duration_minutes,duration_text,description,created_at,start_date,resource_type,resource_id,created_by_id',
                'fields[created_by]': 'id,name,avatar,color,initial',
                'aggregate[duration_minutes]': 'count'
            },
            dialog_est_list: false,
            time_track_testcase: null,
            showEstimateEditForm: false,
            validationError: null,
        }
    },

    watch: {
        show_edit_pane_view (val) {
            if (!val) this.local_clear_edit_pane_view()
            // const html = document.querySelector('html')
            // if (val) {
            //     html.style.overflowY = 'hidden'
            //     html.style.marginRight = 'calc(100vw - calc(100% - 8px))'
            //     html.style.marginLeft = '0px'
            // } else  {
            //     html.style.overflowY = 'scroll'
            //     html.style.marginRight = 'auto'
            //     html.style.marginLeft = '0px'
            // }
        }
    },

    mounted() {
        this.local_index()
    },

    async beforeDestroy() {
        if(this.usecase_item && this.usecase_item.title !== this.usecase_title || this.usecase_item.description !== this.usecase_description) {
            this.usecase_item.title = this.usecase_title
            this.usecase_item.is_autosave = 1
            await this.usecase_update(this.usecase_item)
        }
        if (this.editor_description) this.editor_description.destroy()
        // const html = document.querySelector('html')
        // html.style.overflowY = 'scroll'
    },

    computed: {
        testcase_list: {
            get() {
                return this.$store.state.Testcase.list
            },
            set(list) {
                this.local_testcase_reorder(list)
            }
        },

        success_criterion_list: {
            get() {
                return this.$store.state.SuccessCriterion.list
            },
            set(list) {
                this.local_success_criterion_reorder(list)
            }
        },

        local_usecase_is_open () {
            return this.usecase_item && this.usecase_item.status === 'open'
        },

        local_pane_status_list () {
            if (this.edit_pane_module === 'Testcase') return this.testcase_status_list
            return this.success_criterion_status_list
        },

        local_pane_model_parent_list () {
            if (this.edit_pane_module === 'Testcase') return this.testcase_list
            return this.success_criterion_list
        },

        local_pane_response () {
            return this.edit_pane_module === 'Testcase' ? this.testcase_errors : this.success_criterion_errors
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Usecase', {
            usecase_item: 'item',
            usecase_response: 'response',
        }),

        ...mapState('Testcase', {
            testcase_item: 'item',
            testcase_response: 'response',
            testcase_form_mode: 'form_mode',
        }),

        ...mapGetters('Testcase', {
            testcase_errors: 'errors',
        }),

        ...mapGetters('SuccessCriterion', {
            success_criterion_errors: 'errors',
        }),

        ...mapState('SuccessCriterion', {
            success_criterion_item: 'item',
            success_criterion_response: 'response',
            success_criterion_form_mode: 'form_mode',
        }),

        ...mapState('Collaborator', {
            collaborator_list: 'list',
        }),

        ...mapState('TimeRecord', {
            time_record_list: 'list',
            time_record_response: 'response',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
        }),

        ...mapState('Comment', {
            comment_item: 'item',
            comment_response: 'response',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('Association', {
            association_response: 'response',
        }),

        ...mapState('Assignee', {
            assignee_item: 'item',
            assignee_response: 'response',
        }),
    },

    methods: {
        async local_index() {
            if (this.$can('usecases.index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.usecase_clear_item()
            await this.testcase_clear()
            await this.success_criterion_clear()

            await this.usecase_select({ id: this.$route.params.usecase_id })
            if (this.usecase_item) this.local_set_usecase_direct_fields()

            this.local_load_usecase_child_data()
            this.user_self.role && this.user_self.role.scope === 'internal' ? await this.tag_index(this.local_tag_fields) : ''

            this.edit_mode = false
            this.local_set_async_usecase()
        },

        local_set_usecase_direct_fields () {
            this.usecase_title = this.usecase_item ? this.usecase_item.title : ''
            this.usecase_description = this.usecase_item ? this.usecase_item.description : ''
            this.local_setup_usecase_description()
        },

        async local_set_async_usecase () {
            await this.usecase_show({id: this.$route.params.usecase_id, params: this.local_usecase_fields })
            this.local_set_usecase_direct_fields()
        },

        local_setup_usecase_description () {
            if (this.editor_description !== null) this.editor_description.destroy()
            const content = this.usecase_item.description ? this.usecase_item.description : ' '
            this.editor_description = new Editor({
                extensions: [
                    new Bold(),
                    new Italic(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new History(),
                ],
                content: marked(content, { sanitize: true }),
                onUpdate: this.local_update_debounced,
                editable: this.check_permission('update')
            })
        },

        async local_load_usecase_child_data() {
            this.local_load_testcase_list()
            await this.local_load_success_criterion_list()
            this.local_loading = false
            this.local_collaborator_filter_list()
            if (this.user_self.role && this.user_self.role.scope === 'internal') {
                this.local_load_testcase_status_list()
                this.local_load_success_criterion_status_list()
            }
        },

        async local_load_testcase_list() {
            await this.testcase_index({ params : this.local_testcase_fields })
        },

        async local_load_success_criterion_list() {
            await this.success_criterion_index({ params : this.local_success_criterion_fields })
        },

        local_collaborator_filter_list () {
            this.local_collaborator_list = this.collaborator_list
        },

        async local_load_testcase_status_list() {
            await this.meta_index(this.local_testcase_status_fields)
            this.testcase_status_list = _.cloneDeep(this.meta_list)
        },

        async local_load_success_criterion_status_list() {
            await this.meta_index(this.local_success_criterion_status_fields)
            this.success_criterion_status_list = _.cloneDeep(this.meta_list)
        },

        // Usecase CRUD
        async local_update_title () {
            this.timer_update_debounce = setTimeout(async () => {
                this.usecase_item.title = this.usecase_title
                this.update_autosaved_at = moment().format('YYYY-MM-DD HH:mm:ss')
                this.usecase_item.is_autosave = 1
                await this.usecase_update(this.usecase_item)
                if (this.usecase_response.status !== 'success') {
                    return this.local_clear_debounce_timer(true)
                }
                this.local_clear_debounce_timer(true)
            }, 500)
        },

        async local_update () {
            this.update_autosaved_at = moment().format('YYYY-MM-DD HH:mm:ss')
            await this.usecase_update(this.usecase_item)
            if (this.usecase_response.status === 'success') {
                this.local_clear_debounce_timer(true)
                this.usecase_title = this.usecase_item.title
                this.usecase_description = this.usecase_item.description
                this.usecase_item.is_autosave = 0
            }
        },

        async local_update_debounced (state) {
            this.local_clear_debounce_timer()
            this.usecase_item.description = turndownService.turndown(state.getHTML())
            this.usecase_item.is_autosave = 1
            this.timer_update_debounce = setTimeout(this.local_update, 500)
        },

        async local_clear_debounce_timer (saved = false) {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                this.usecase_item.is_autosave = 0
                // if (saved) {
                //     this.update_autosaved_at = moment().format('YYYY-MM-DD HH:mm:ss')
                // }
            }
        },

        async local_usecase_clear () {
            this.usecase_title = this.usecase_item.title
            this.edit_mode = false
        },

        async local_toggle_usecase_status (id, state) {
            if (this.$can('usecases.update')) {
                await this.usecase_update_status({id: id, state })
                if (this.usecase_response.status !== 'success') return
                this.usecase_item.status = state
            }
        },

        async local_visibility_toggle (id, state) {
            const usecase_item = {}
            usecase_item.id = id
            usecase_item.state = state === 'external' ? 'internal' : 'external'
            await this.usecase_visibility(usecase_item)
            if (this.usecase_response.status !== 'success') return
            this.usecase_item.visibility = usecase_item.state
        },

        async local_destroy (id) {
            await this.usecase_destroy({id: id})
            if (this.usecase_response.status == 'success') {
                this.$router.push({name: 'projects-usecases'})
            }
        },
        // Usecase CRUD ----- END

        // Sidebar Pane CRUD
        async local_show_edit_pane (edit_item, module) {
            this.local_clear_pane_store_items(module)
            if (this.local_edit_pane_item.id && (this.local_edit_pane_item.id !== edit_item.id)) {
                this.local_update_list_item(_.cloneDeep(this.local_edit_pane_item), this.edit_pane_module)
            }

            if (this.local_edit_pane_item && this.local_edit_pane_item.id === edit_item.id) return

            // this.local_set_shortcut()
            await this.local_reset_edit_pane()
            this.edit_pane_module = module
            if (!this.show_edit_pane_view) this.show_edit_pane_view = true
            this.local_edit_pane_item = _.cloneDeep(edit_item)
            await this.local_setup_pane(edit_item)
        },

        local_clear_pane_store_items () {
            if (module === 'Testcase') return this.testcase_clear_item()
            this.success_criterion_clear_item()
        },

        // local_set_shortcut () {
        //     document.addEventListener('keyup', this.local_esc_to_close_pane)
        // },

        async local_setup_pane (edit_item) {
            this.local_load_status(edit_item)
            this.local_load_time_est_tags(edit_item)
            this.local_load_comments(edit_item)
            this.local_load_assignee_and_static_fields(edit_item)
        },

        // local_esc_to_close_pane (evt) {
        //     if (evt.keyCode === 27) this.local_clear_edit_pane_view()
        // },

        async local_load_status (model) {
            this.loaders.sidebar_time_est_loading = true
            const params = { include: 'status', 'fields[status]': 'id,type,value,status' }
            const testcase_params = { 'fields[testcases]': 'id,status_id', }
            const success_criteria_params = { 'fields[success_criteria]': 'id,status_id' }

            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(model.id, { ...params, ...testcase_params }, 'update-item')
                this.local_edit_pane_item.status_id = this.testcase_item.status_id
                this.local_edit_pane_item.status = this.testcase_item.status
                // this.local_merge_pane_item(this.testcase_item)
            } else {
                await this.local_success_criterion_show_item(model.id, { ...params, ...success_criteria_params }, 'update-item')
                this.local_edit_pane_item.status_id = this.success_criterion_item.status_id
                this.local_edit_pane_item.status = this.success_criterion_item.status
                // this.local_merge_pane_item(this.success_criterion_item)
            }
            this.loaders.sidebar_time_est_loading = false
        },

        async local_load_time_est_tags (model) {
            this.loaders.sidebar_time_est_loading = true
            const params = { include: 'tags', 'fields[tags]': 'tags.id,label,tags.type,color' }
            const testcase_params = { 'fields[testcases]': 'id,estimated_duration_text,estimated_duration_minutes', 'aggregate[time_records.duration_minutes]': 'sum' }
            const success_criteria_params = { 'fields[success_criteria]': 'id' }

            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(model.id, { ...params, ...testcase_params }, 'update-item')
                this.local_edit_pane_item.tags = this.testcase_item.tags
                this.local_edit_pane_item.time_records_sum_duration_minutes = this.testcase_item.time_records_sum_duration_minutes
                this.local_edit_pane_item.estimated_duration_minutes = this.testcase_item.estimated_duration_minutes
                this.local_edit_pane_item.estimated_duration_text = this.testcase_item.estimated_duration_text
            } else {
                await this.local_success_criterion_show_item(model.id, { ...params, ...success_criteria_params }, 'update-item')
                this.local_edit_pane_item.tags = this.success_criterion_item.tags
            }
            this.loaders.sidebar_time_est_loading = false
        },

        async local_load_comments (item) {
            this.loaders.sidebar_comments_loading = true
            const params = {
                include: 'comments.createdBy',
                'fields[comments]': 'id,commentable_id,commentable_type,content,created_at,created_by_id,updated_at,visibility',
                'fields[comments.createdBy]': 'id,name,avatar,color,initial',
            }

            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(item.id, { ...params, 'fields[testcases]': 'id' }, 'update-item')
                this.local_edit_pane_item.comments = this.testcase_item.comments
                // this.local_merge_pane_item({ comments: this.testcase_item.comments })
            } else {
                await this.local_success_criterion_show_item(item.id, { ...params, 'fields[success_criteria]': 'id' }, 'update-item')
                this.local_edit_pane_item.comments = this.success_criterion_item.comments
                // this.local_merge_pane_item({ comments: this.success_criterion_item.comments })
            }
            this.loaders.sidebar_comments_loading = false
        },

        async local_load_assignee_and_static_fields (item) {
            const params = { include: 'assignees', 'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial' }
            if (this.edit_pane_module === 'Testcase') {
                await this.local_testcase_show_item(item.id, { ...params, 'fields[testcases]': 'id,title,description' }, 'update-item')
                this.local_edit_pane_item.assignees = this.testcase_item.assignees
                // this.local_edit_pane_item.title = this.testcase_item.title
                // this.local_edit_pane_item.description = this.testcase_item.description
            } else {
                await this.local_success_criterion_show_item(item.id, { ...params, 'fields[success_criteria]': 'id,title,description' }, 'update-item')
                this.local_edit_pane_item.assignees = this.success_criterion_item.assignees
                this.local_edit_pane_item.title = this.success_criterion_item.title
                this.local_edit_pane_item.description = this.success_criterion_item.description
            }
        },

        local_merge_pane_item (item) {
            Object.assign(this.local_edit_pane_item, { ...item })
        },

        local_update_pane_item (evt) {
            if (this.edit_pane_module === 'Testcase') this.local_testcase_update(evt)
            else this.local_success_criterion_update(evt)
        },

        local_destroy_pane_item (evt) {
            if (this.edit_pane_module === 'Testcase') this.local_testcase_destroy(evt)
            else this.local_success_criterion_destroy(evt)
            this.local_clear_edit_pane_view()
            this.local_reset_edit_pane(true)
        },

        local_clear_edit_pane_view () {
            // if (this.local_pane_response && this.local_pane_response.status !== 'success') return
            if (this.local_edit_pane_item.id) {
                this.local_update_list_item(_.cloneDeep(this.local_edit_pane_item), this.edit_pane_module)
            }
            this.show_edit_pane_view = false
            this.local_edit_pane_item = {}
            document.removeEventListener('keyup', this.local_esc_to_close_pane)
            this.testcase_clear_item()
            this.success_criterion_clear_item()
        },

        async local_reset_edit_pane () {
            this.loaders.content_autosave_response = false
            this.local_edit_pane_item = { tags: [], comments: [], status_id: null, status: {} }
        },

        local_drag_start () {
            if (this.show_edit_pane_view) {
                this.local_clear_edit_pane_view()
            }
        },

        // local_hide_edit_pane_view (evt) {
        //     if (this.show_edit_pane_view && this.checkParent(evt.target.firstChild, 'v-dialog__content') && this.checkParent(evt.target.firstChild, 'c-usecase-item-view')) {
        //         this.local_clear_edit_pane_view()
        //     }
        // },

        // checkParent (child, className) {
        //     let node = child.parentNode ?? null
        //     while (node) {
        //         if (node.classList && node.classList.contains(className)) return false
        //         node = node.parentNode
        //     }
        //     return true
        // },

        local_adjust_pane_height () {
            this.$refs.refNavDrawer.$el.firstChild.scrollTop = this.$refs.refNavDrawer.$el.firstChild.scrollHeight
        },

        local_get_filtered_tags (item) {
            return _.differenceBy(this.tag_list, item.tags, 'id')
        },
        // Sidebar Pane CURD ----- END

        // Testcase CRUD
        async local_testcase_store () {
            if (!this.testcase_title || (this.testcase_title && !this.testcase_title.trim())) return
            await this.testcase_clear_item()
            this.testcase_item.usecase_id = this.$route.params.usecase_id
            this.testcase_item.title = this.testcase_title
            this.testcase_title = ''
            await this.testcase_store(this.testcase_item)
            if (this.testcase_response.status === 'success') {
                await this.local_testcase_show(this.testcase_item.id, 'update')
                this.testcase_clear_item()
            }
        },

        async local_testcase_update () {
            clearTimeout(this.content_autosave_timeout)
            this.loaders.content_autosave_response = 'loading'
            this.content_autosave_timeout = setTimeout(async () => {
                await this.testcase_update(this.local_edit_pane_item)
                if (this.testcase_response && this.testcase_response.status !== 'success') {
                    this.loaders.content_autosave_response = false
                    return this.$notify('error', 'Something went wrong, please re-check the fields.')
                }
                this.local_show_autosave_response()
            }, 300)
        },

        local_update_list_item (item, type) {
            const hasError = this.local_pane_response && this.local_pane_response.status !== 'success'
            if (hasError) {
                const oldItem = (type === 'Testcase' ? this.testcase_list : this.success_criterion_list).find(testcase => testcase.id === item.id)
                if (type === 'Testcase') return this.testcase_update_list(oldItem)
                return this.success_criterion_update_list(oldItem)
            }
            if (type === 'Testcase') return this.testcase_update_list(item)
            this.success_criterion_update_list(item)
        },

        async local_testcase_show (id, mode) {
            await this.testcase_show({ id: id, mode: mode ? mode : this.testcase_form_mode , params: this.local_testcase_fields })
        },

        async local_testcase_reorder (list) {
            this.testcase_reorder({list: list})
        },

        async local_testcase_destroy (id) {
            await this.testcase_destroy({id: id})
            if (this.testcase_response && this.testcase_response.status !== 'success') return
            this.$notify('success', 'Test Case deleted successfully!')
        },
        // Testcase CRUD ----- END

        // SuccessCriterion CRUD
        async local_success_criterion_store() {
            if (!this.success_criterion_title || (this.success_criterion_title && !this.success_criterion_title.trim())) return
            await this.success_criterion_clear_item()
            this.success_criterion_item.usecase_id = this.$route.params.usecase_id
            this.success_criterion_item.title = this.success_criterion_title
            await this.success_criterion_store(this.success_criterion_item)
            if (this.success_criterion_response.status === 'success') {
                this.success_criterion_title = ''
                this.local_success_criterion_show(this.success_criterion_item.id, 'update')
            }
        },

        local_success_criterion_show(id, mode) {
            this.success_criterion_show({ id: id, mode: mode ? mode : this.success_criterion_form_mode, params: this.local_success_criterion_fields })
        },

        async local_success_criterion_update() {
            clearTimeout(this.content_autosave_timeout)
            this.loaders.content_autosave_response = 'loading'
            this.content_autosave_timeout = setTimeout(async () => {
                await this.success_criterion_update(this.local_edit_pane_item)
                if (this.success_criterion_response && this.success_criterion_response.status !== 'success') {
                    this.loaders.content_autosave_response = false
                    return this.$notify('error', 'Something went wrong, please re-check the fields.')
                }
                this.local_show_autosave_response()
            }, 300)
        },

        async local_success_criterion_reorder(list) {
            this.success_criterion_reorder({list: list})
        },

        async local_success_criterion_destroy(id) {
            await this.success_criterion_destroy({id: id})
            if (this.success_criterion_response && this.success_criterion_response.status !== 'success') return
            this.$notify('success', 'Success Criteria deleted successfully!')
        },
        // SuccessCriterion CRUD ----- END

        // Tags CRUD
        async local_associate_tag_destroy (data, item) {
            this.local_remove_tag(data, item)
            await this.association_destroy(data.association)
            // if (this.association_response.status !== 'success') return
        },

        async local_remove_tag (tag, item) {
            const index = item.tags.findIndex(item => item.id === tag.id)
            if (index !== -1) item.tags.splice(index, 1)
        },

        local_load_tags_list () {
            this.tag_index({ 'filter[type]': 'usecase_tag', 'fields[tags]': 'id,label,color,type' })
        },
        // Tags CRUD ----- END

        // Comments CRUD
        async local_load_status_coment_item (type, id) {
            if (type === 'Testcase') {
                await this.local_testcase_show_item(id, { include: 'status', 'fields[testcases]': 'id,status_id', 'fields[status]': 'id,status,type,value' })
                Object.assign(this.local_edit_pane_item, _.find(this.testcase_list, {id: id}))
            }
            if (type === 'SuccessCriterion') {
                await this.local_success_criterion_show_item(id, { include: 'status', 'fields[success_criteria]': 'id,status_id', 'fields[status]': 'id,status,type,value' })
                Object.assign(this.local_edit_pane_item, _.find(this.success_criterion_list, {id: id}))
            }
        },

        async local_comment_store (comment, type, id) {
            await this.local_set_comment_vars(comment, type, id)
            await this.comment_store(this.comment_item)
            if (this.comment_response.status !== 'success') return
            this.local_edit_pane_item.comments.push(this.comment_item)
            this.$set(this.comment_text, id, '')
            setTimeout(() => {
                this.$refs.refNavDrawer.$el.firstChild.scrollTop = this.$refs.refNavDrawer.$el.firstChild.scrollHeight
                this.comment_clear_item()
            }, 0)
        },

        async local_set_comment_vars (comment, type, id) {
            this.comment_item.commentable_type = type
            this.comment_item.commentable_id = id
            this.comment_item.content = comment
            this.comment_item.created_by = this.user_self
            this.comment_item.created_by_id = this.user_self.id
            this.comment_item.created_at = new Date()
        },

        local_add_status_comment (comment) {
            this.local_edit_pane_item.comments.push(comment)
            this.$nextTick(() => {
                this.$refs.refNavDrawer.$el.firstChild.scrollTop = this.$refs.refNavDrawer.$el.firstChild.scrollHeight
            })
        },
        // Comments CRUD ----- END

        // Time tracker CRUD
        async local_time_record_index () {
            await this.time_record_index({
                ...this.time_record_params,
                'filter[resource_type]': 'Testcase',
                'filter[resource_id]': this.time_track_testcase.id,
                'sort': '-created_at'
            })
        },

        async local_open_time_track (testcase) {
            this.time_track_testcase = testcase
            this.dialog_est_list = true
            this.local_time_record_index()
        },

        local_close_time_track ({ resetFields }) {
            resetFields()
            this.time_record_clear()
            this.time_track_testcase = null
            this.dialog_est_list = false
            this.$emit('close-timetrack-form')
        },

        local_calculate_mins (evt) {
            const { totalMins } = calculateByText(evt)
            this.testcase_item.estimated_duration_minutes = totalMins
        },

        async local_add_entry ({ formData: record, resetFields, mode, record_id: id }) {
            const params = { ...record, mode: 'create', resource_type: 'Testcase', resource_id: this.time_track_testcase.id }
            if (mode && mode === 'edit') await this.time_record_update({ ...record, mode, id })
            else await this.time_record_store({ ...params })
            this.local_clear_time_record(resetFields)
        },

        async local_delete_entry ({ id, resetFields }) {
            await this.time_record_destroy({ id })
            this.local_clear_time_record(resetFields)
        },

        async local_est_update (evt) {
            await this.testcase_update(evt)
        },

        async local_clear_time_record (resetFields) {
            if (this.time_record_response && this.time_record_response.status === 'success') {
                await this.local_testcase_show(this.time_track_testcase.id, 'show')
                // this.time_track_testcase = _.cloneDeep(this.testcase_item)
                this.time_track_testcase.time_records_sum_duration_minutes = this.testcase_item.time_records_sum_duration_minutes
                this.time_track_testcase.estimated_duration_minutes = this.testcase_item.estimated_duration_minutes
                this.time_track_testcase.estimated_duration_text = this.testcase_item.estimated_duration_text
                resetFields()
            }
        },

        local_show_est_edit (durationText) {
            this.localEstText = durationText
            this.showEstimateEditForm = true
        },

        local_close_est_edit () {
            this.validationError = null
            this.localEstText = null
            this.showEstimateEditForm = false
        },
        // Time Tracker ----- END

        // Extras
        async local_usecase_show_item (params, type) {
            await this.usecase_show_item({ id: this.usecase_item.id, params: { ...params }, type })
        },

        async local_testcase_show_item (id, params, type) {
            await this.testcase_show_item({ id, params: { ...params }, type })
        },

        async local_success_criterion_show_item (id, params, type) {
            await this.success_criterion_show_item({ id, params: { ...params }, type })
        },

        async local_add_external_user (user_id) {
            await this.local_visibility_toggle(this.usecase_item.id, this.usecase_item.visibility)
        },

        local_member_list(list) {
            return this.local_collaborator_list.filter(item => list.findIndex(user => user.assignee.user_id === item.user_id) === -1)
        },

        local_show_autosave_response () {
            this.loaders.content_autosave_response = true
            setTimeout(() => this.loaders.content_autosave_response = false, 2000)
        },

        check_permission (type) {
            return this.$can(`usecases.${type}`) && this.$can('projects.update-only')
        },

        local_get_html (content) {
            return marked(content, { sanitize: true })
        },

        ...mapActions('Usecase', {
            usecase_show: 'show',
            usecase_update: 'update',
            usecase_select: 'select',
            usecase_show_item: 'show_item',
            usecase_update_status: 'update_status',
            usecase_visibility: 'visibility',
            usecase_destroy: 'destroy',
            usecase_clear_item: 'clear_item',
        }),

        ...mapActions('Testcase', {
            testcase_index: 'index',
            testcase_update_list: 'update_list',
            testcase_store: 'store',
            testcase_update: 'update',
            testcase_show: 'show',
            testcase_show_item: 'show_item',
            testcase_status: 'status',
            testcase_reorder: 'reorder',
            testcase_destroy: 'destroy',
            testcase_clear: 'clear',
            testcase_clear_item: 'clear_item',
        }),

        ...mapActions('SuccessCriterion', {
            success_criterion_index: 'index',
            success_criterion_update_list: 'update_list',
            success_criterion_store: 'store',
            success_criterion_update: 'update',
            success_criterion_show: 'show',
            success_criterion_show_item: 'show_item',
            success_criterion_status: 'status',
            success_criterion_reorder: 'reorder',
            success_criterion_destroy: 'destroy',
            success_criterion_clear: 'clear',
            success_criterion_clear_item: 'clear_item',
        }),

        ...mapActions('TimeRecord', {
            time_record_index: 'index',
            time_record_store: 'store',
            time_record_update: 'update',
            time_record_destroy: 'destroy',
            time_record_clear: 'clear',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_clear_list: 'clear_list',
        }),

        ...mapActions('Comment', {
            comment_store: 'store',
            comment_clear_item: 'clear_item',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
        }),

        ...mapActions('Association', {
            association_destroy: 'destroy',
        }),

        ...mapActions('Assignee', {
            assignee_store: 'store',
            assignee_clear: 'clear',
            assignee_destroy: 'destroy',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .c-testcase-pane {
        position: fixed;
        right: -1000px;
        top: 57px;
        height: 100vh;
        z-index: 2;
        transition: right 0.2s ease-in-out;
    }
    .c-show-pane {
        right: 0px;
        transition: right 0.2s ease-in-out;
    }
    .sortable-ghost {
        opacity: 0.8;
        box-shadow: 0px 2px 6px 4px rgb(173, 173, 173);
        border: 1px dashed rgb(116, 116, 116);
        background: #d2f1ff;
    }
    .sortable-chosen {
        opacity: 1 !important;
        box-shadow: 0px 2px 6px 4px rgb(173, 173, 173);
        border: none !important;
        border: 1px dashed rgb(116, 116, 116);
        background: #f5f5f5 !important;
    }
</style>

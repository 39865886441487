<template>
    <div class="u-relative" :class="{ 'mb-3': tagsSelected && tagsSelected.length }" style="flex-grow: 1; height: 24px;">
        <input
            type="text"
            :ref="'refTagSearchInput' + tagType"
            :id="'refTagSearchInput' + tagType"
            v-model="modelSearchTag"
            @click.prevent.stop="localOpenDropdown()"
            @paste="localOnPaste(modelSearchTag)"
            @blur="localOnBlur()"
            @keyup.stop.prevent="localInputEvent"
            :class="['md-body-2 grey--text text--darken-1 u-wfull']"
            style="outline: none; minWidth: 200px; max-width: 240px;"
            autocomplete="off"
        >
        <span v-if="!modelSearchTag" class="d-inline-flex align-center u-absolute md-body-2 grey--text text--lighten-1" style="left: 0px; top: 50%; transform: translateY(-50%); pointerEvents: none;">
            <a-icon size="16" color="grey lighten-1" class="mr-1" v-if="canCreate">add</a-icon>
            <!--<span class="d-inline-block" style="marginBottom: 1px;">{{ canCreate ? 'Add Tag' : 'Select Existing Tag' }} <strong>(Press "/" to focus)</strong></span>-->
            <span class="d-inline-block" style="marginBottom: 1px;">{{ canCreate ? 'Add Tag' : 'Select Existing Tag' }}</span>
        </span>
        <!--<a-menu v-model="modelDropdown" :attach="`#${tagType}TagSearchInput`" :close-on-content-click="!tagError || createLoading" offset-y nudge-bottom="30" min-width="200" content-class="white u-shadow-md" transition="slide-y-transition">-->
        <a-menu
            v-model="modelDropdown"
            :attach="`#${tagType}TagSearchInput`"
            :close-on-content-click="false"
            :close-on-click="menuCloseOnOutsideClick"
            offset-y
            nudge-bottom="30"
            min-width="200"
            content-class="white u-shadow-md"
            transition="slide-y-transition"
        >
            <a-sheet class="u-rounded-corners-lg c-tiny-scroll u-wfull" max-height="240">
                <div class="u-flex-center u-wfull px-4 py-2 white" style="min-height: 53px;" v-if="tagSearchLoading || initialLoading">
                    <a-progress-circular class="mr-2" width="2" size="16" color="grey darken-2" indeterminate></a-progress-circular>
                    <span class="md-body-2 grey--text text--darken-2">Fetching tags...</span>
                </div>
                <template v-else>
                    <ul class="c-custom-dropdown u-hfull px-4 white u-flex-center-y" style="min-height: 53px;" v-if="localCanShowNoData">
                        <li class="u-rounded-corners px-2 u-flex-center-y">
                            <span class="md-body-2 grey--text text--darken-1 text-truncate d-inline-block">No Data Found</span>
                        </li>
                    </ul>
                    <!--<ul class="c-custom-dropdown u-hfull px-4 white u-flex-center-y" style="min-height: 53px;" v-else-if="localFilteredTagsList && localFilteredTagsList.length && localFilteredTagsList[0].id === 'create'">
                        <li class="u-rounded-corners px-2 u-flex-center-y">
                            <span class="md-body-2 grey--text text--darken-1 text-truncate d-inline-block">Type to search or add new tag</span>
                        </li>
                    </ul>-->
                    <ul v-else ref="refDropdownMenu" class="c-custom-dropdown u-hfull pa-1" tabindex="-1">
                        <template v-for="(tag, i) in localFilteredTagsList">
                            <v-hover v-if="tag.id !== 'create'" v-slot="{ hover }" :key="tag.id + '-hover'">
                                <li @click="localSelectTag(tag)" data-link-type="tag-item" tabindex="0" class="pa-2 px-4 u-rounded-corners u-cursor-pointer u-flex-center-y" :key="i" :class="[{ 'grey lighten-4': currentKeyIndex === i || hover }]">
                                    <div class="u-rounded-corners mr-2" :style="{ width: '14px', height: '14px', flexShrink: 0, backgroundColor: $color(tag.color, 'color_bg'), border: `1px solid ${$color(tag.color, 'color_text')}` }"></div>
                                    <span class="md-body-2 grey--text text--darken-1 text-truncate d-inline-block">{{ tag.label }}</span>
                                </li>
                            </v-hover>
                            <v-hover v-if="(tag.id === 'create') && (modelSearchTag && modelSearchTag.trim()) && canCreate" v-slot="{ hover }" :key="tag.id + '-hover'">
                                <li
                                    @click="canCreate ? localCreateTag(modelSearchTag) : ''"
                                    data-link-type="create-tag"
                                    tabindex="0"
                                    class="u-rounded-corners"
                                    :key="i"
                                    :class="[
                                        { 'mt-1': i !== 0 },
                                        { 'u-cursor-pointer': canCreate },
                                        { 'pa-2 px-4': !localTagError },
                                        (currentKeyIndex === i || hover) && canCreate ? 'grey lighten-4' : 'white',
                                    ]"
                                >
                                    <!--style="position: sticky; bottom: 0px; left: 0px; border-top: 1px solid #eee !important;"-->
                                    <div :class="{ 'u-border-error c-wiggle-short u-rounded-corners pa-2': localTagError }">
                                        <div class="u-flex-center-y">
                                            <template v-if="canCreate">
                                                <a-progress-circular width="2" size="16" color="indigo darken-1" class="mr-3" v-if="createLoading" indeterminate></a-progress-circular>
                                                <a-icon size="16" color="grey darken-2" class="mr-3" v-else>add_circle</a-icon>
                                            </template>
                                            <span class="md-body-2 grey--text text--darken-1 text-truncate d-inline-flex align-center">
                                                <template v-if="!canCreate">No Data Found</template>
                                                <template v-else>
                                                    Create
                                                    <strong class="d-inline-block text-truncate ml-1">{{ modelSearchTag }}</strong>
                                                </template>
                                            </span>
                                        </div>
                                        <span v-if="localTagError" class="md-caption red--text text--darken-1">{{ localTagError }}</span>
                                    </div>
                                </li>
                            </v-hover>
                        </template>
                    </ul>
                </template>
            </a-sheet>
        </a-menu>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    props: {
        tagType: {
            type: String
        },
        tagList: {
            type: Array
        },
        tagsSelected: {
            type: Array
        },
        tagSearchType: {
            type: String
        },
        tagsTotalCount: {
            type: Number
        },
        tagsCountLimit: {
            type: Number
        },
        initialLoading: {
            type: Boolean,
            default: false
        },
        canCreate: {
            type: Boolean,
            default: true
        }
    },

    watch: {
        modelSearchTag (val, oldVal) {
            if (_.trim(val)) this.localSearchTags(val)
            this.inputIsEmpty = !_.trim(val)
        },

        menuCloseOnOutsideClick: {
            handler (val) {
                if (val) this.modelDropdown = false
            },
            immediate: true
        },

        modelDropdown: {
            handler (val) {
                if (!val) {
                    this.currentKeyIndex = null
                }
            },
            immediate: true
        },
    },

    data () {
        return {
            currentKeyIndex: 0,
            modelDropdown: true,
            tagSearchLoading: false,
            tagError: null,
            inputIsEmpty: true,
            createLoading: false,
            menuCloseOnOutsideClick: false,
            tagSearchTimeout: null,
            modelSearchTag: '',
            tagDropdowns: {},
        }
    },

    mounted () {
        document.addEventListener('keydown', this.localKeyDown)
        document.addEventListener('click', this.localMouseClick)
        this.localOpenDropdown()
    },

    beforeDestroy () {
        document.removeEventListener('keydown', this.localKeyDown)
        document.removeEventListener('click', this.localMouseClick)
    },

    computed: {
        localTagError () {
            return this.tagError || this.$response(this.tag_response, 'label')
        },

        localFirstItemIsCreate () {
            const list = this.localFilteredTagsList && this.localFilteredTagsList
            return !!(list.length === 1 && list[0].id === 'create')
        },

        localCanShowNoData () {
            const listLength = (this.localFilteredTagsList && this.localFilteredTagsList.length) ?? 0
            //const result = !!(((!listLength || this.localFirstItemIsCreate) && !this.canCreate) || ((!listLength || this.localFirstItemIsCreate) && !_.trim(this.modelSearchTag)))
            if (!listLength || this.localFirstItemIsCreate) {
                if (!this.canCreate) return true
                if (!_.trim(this.modelSearchTag)) return true
            }
            return false
        },

        localFilteredTagsList () {
            const searchValue = _.trim(this.modelSearchTag) || ''
            return this.tagList.filter(tag => {
                if (tag.id === 'create') return tag
                return tag.label && tag.label.toLowerCase().includes(searchValue.toLowerCase())
            })
        },

        ...mapState('Tag', {
            tag_response: 'response'
        })
    },

    methods: {
        localOnPaste () {
            const oldVal = this.modelSearchTag
            setTimeout(() => {
                if (oldVal && this.modelSearchTag !== oldVal) this.localSearchTags(this.modelSearchTag)
            }, 800)
        },

        async localSearchTags (value) {
            this.tagError = null
            clearTimeout(this.tagSearchTimeout)
            this.tag_clear_response()

            if (!this.modelDropdown) this.modelDropdown = true

            this.currentKeyIndex = 0
            this.tagSearchLoading = true
            if (this.tagsTotalCount && this.tagsTotalCount <= this.tagsCountLimit) return this.localTriggerSearch(value)
            this.tagSearchTimeout = setTimeout(async () => this.localTriggerSearch(value), 200)
        },

        localTriggerSearch (value) {
            this.$emit('search', {
                type: this.tagSearchType,
                value,
                clearLoading: this.localClearSearchTimeout,
                showError: this.localShowError
            })
        },

        localShowError (error) {
            this.createLoading = false
            this.modelDropdown = true
            if (error) {
                this.tagError = error
                this.tagSearchLoading = false
                clearTimeout(this.tagSearchTimeout)
                this.localFocusInput()
            }
        },

        localClearSearchTimeout () {
            this.tagSearchLoading = false
            clearTimeout(this.tagSearchTimeout)
        },

        localOpenDropdown () {
            this.tag_clear_response()
            if (_.trim(this.modelSearchTag)) {
                if (this.modelDropdown) this.localFocusInput()
                else this.localSearchTags(this.modelSearchTag)
                return this.localResetMenuScroll()
            }

            if (this.modelDropdown) {
                this.localFocusInput()
                return this.localResetMenuScroll()
            }

            this.modelDropdown = true
            this.currentKeyIndex = 0
            this.localFocusInput()
            this.localResetMenuScroll()
            this.$emit('openDropdown')
        },

        localOnBlur () {
            this.$emit('reset')
            //if (this.modelDropdown) return
            //this.localOnEscape({ code: 'Escape' })
        },

        localMouseClick (evt) {
            const { target } = evt
            const parentElement = document.getElementById(`${this.tagType}-tags`)
            const isRemoveButton = target.dataset.tagType === 'remove-tag'
            const isMenuItem = target.tagName === 'LI' ? target.dataset.linkType === 'tag-item' : target.parentElement.dataset.linkType === 'tag-item'
            if (_.trim(this.modelSearchTag)) return
            this.localCloseMenuAndEsc(!(isMenuItem || isRemoveButton || parentElement.contains(target)))
        },

        localCloseMenuAndEsc (value) {
            this.menuCloseOnOutsideClick = value
            if (this.menuCloseOnOutsideClick) this.$emit('escape', { code: 'Escape', shiftKey: false, ctrlKey: false })
        },

        localInputEvent (e) {
            if (this.inputIsEmpty) {
                if (e && e.code === 'Escape') return this.localKeyDown({ code: 'Escape' })
                if (_.trim(this.modelSearchTag) || (e && e.code && e.code !== 'Backspace')) return
                if (!_.size(this.tagsSelected)) return
                this.localOnBackspace({ code: 'Backspace' })
            }
            if (!this.inputIsEmpty && !_.trim(this.modelSearchTag)) this.localSearchTags(this.modelSearchTag)
        },

        localKeyDown (evt) {
            if (!this.tagSearchType) return

            this.localOnEscape(evt)
            this.localOnArrowDown(evt)
            this.localOnArrowUp(evt)
            this.localOnEnter(evt)
            this.localOnSlash(evt)
        },

        localOnEscape (evt) {
            if (evt.code !== 'Escape') return

            this.$emit('escape', evt)
            this.localFocusInput()
            this.modelDropdown = false
        },

        localOnArrowDown (evt) {
            if (evt.code !== 'ArrowDown') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (!this.modelDropdown) return

            evt.preventDefault()
            this.localCalKeyIndexOnArrowDown()
            this.localFocusCurrentItem(evt, 'down')
            this.localFocusInput()
        },

        localCalKeyIndexOnArrowDown () {
            if (this.currentKeyIndex === this.localFilteredTagsList.length - 1) this.currentKeyIndex = 0
            else this.currentKeyIndex = this.currentKeyIndex + 1
        },

        localOnArrowUp (evt) {
            if (evt.code !== 'ArrowUp') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (!this.modelDropdown) return

            evt.preventDefault()
            if (this.currentKeyIndex === 0) this.currentKeyIndex = this.localFilteredTagsList.length - 1
            else this.currentKeyIndex = this.currentKeyIndex - 1

            this.localFocusCurrentItem(evt, 'up')
            this.localFocusInput()
        },

        async localOnEnter (evt) {
            if (evt.code !== 'Enter') return
            if (evt.shiftKey || evt.ctrlKey) return

            const listChildren = evt.target.parentElement.querySelector('.c-custom-dropdown')
            const isCreateTagLink = evt.target.tagName === 'LI' ? evt.target.dataset.linkType === 'create-tag' : (listChildren.children[this.currentKeyIndex] && listChildren.children[this.currentKeyIndex].dataset.linkType === 'create-tag')
            if (isCreateTagLink) {
                //const { message } = new Validate(this.modelSearchTag, { silent: true }).length(1, 255).run()
                //if (message) return this.tagError = message
                if (!this.canCreate) return
                this.localCreateTag()
                if (this.modelDropdown) this.localFocusInput()
                if (this.currentKeyIndex === this.localFilteredTagsList.length - 1) this.currentKeyIndex = 0
                return this.localFocusCurrentItem(evt, 'down')
            }

            const selectedTag = this.localFilteredTagsList[this.currentKeyIndex]
            if (selectedTag && selectedTag.id !== 'create') this.localSelectTag(selectedTag)
            //this.modelDropdown = false
            if (this.modelDropdown) this.localFocusInput()
            if (this.currentKeyIndex === this.localFilteredTagsList.length - 1) this.currentKeyIndex = 0
            this.localFocusCurrentItem(evt, 'down')
        },

        localOnSlash (evt) {
            //if (evt.code !== 'Slash' || !this.tagSearchType) return
            //if (evt.shiftKey || evt.ctrlKey) return
            //if (_.trim(this.modelSearchTag)) return
            //if (this.modelDropdown) return

            //evt.preventDefault()
            //this.localOpenDropdown()
            //this.localFocusInput()
            //if (_.trim(this.modelSearchTag)) this.localSearchTags(this.modelSearchTag)
        },

        localOnBackspace (evt) {
            if (!['Backspace', 'Delete'].includes(evt.code)) return
            if (evt.shiftKey || evt.ctrlKey) return
            if (_.trim(this.modelSearchTag)) return
            if (!this.$can('projects.update-only')) return

            this.$emit('backspace', evt)
        },

        localFocusCurrentItem (evt, type = 'down') {
            const listChildren = evt.target.tagName === 'LI' ? evt.target.parentElement : evt.target.parentElement.querySelector('.c-custom-dropdown')
            if (listChildren) {
                const elArr = Array.from(listChildren.children)
                if (elArr && elArr[this.currentKeyIndex]) {
                    this.$nextTick(() => {
                        elArr[this.currentKeyIndex].focus()
                    })
                }
            }
        },

        async localCreateTag () {
            this.createLoading = true
            //const { message } = new Validate(this.modelSearchTag, { silent: true }).length(1, 255).run()
            //if (message) return this.tagError = message
            this.$emit('create', { value: this.modelSearchTag, showError: this.localShowError, showSuccess: this.localTagCreateSuccess })
        },

        localTagCreateSuccess () {
            this.modelSearchTag = null
            this.createLoading = false
            if (this.modelDropdown) this.localFocusInput()
        },

        localSelectTag (selectedTag) {
            this.$emit('select', { tag: selectedTag, showSuccess: this.localTagCreateSuccess })
            this.localFocusInput()
        },

        localFocusInput () {
            setTimeout(() => {
                const input = this.$refs['refTagSearchInput' + this.tagSearchType]
                if (input) input.focus()
            }, 200)
        },

        localResetMenuScroll () {
            setTimeout(() => {
                if (this.$refs.refDropdownMenu && this.$refs.refDropdownMenu.offsetParent) {
                    this.$refs.refDropdownMenu.offsetParent.scrollTo({ top: 0 })
                }
            }, 100)
        },

        ...mapActions('Tag', {
            tag_clear_response: 'clear_response'
        })
    }
}
</script>

<template>
    <div class="c-filters-side-pane elevation-12" :class="{ 'visible': isOpen }">
        <div class="u-sticky white u-z16" style="top: 0px; border-radius: 8px 8px 0px 0px;">
            <div class="u-flex-center-y pa-6">
                <div class="u-flex">
                    <a-icon size="24" color="grey darken-4">filter_alt</a-icon>
                    <span class="pl-1" style="fontSize: 20px">Filters</span>
                </div>
                <a-spacer></a-spacer>
                <a-btn small icon @click="localCloseFilter()">
                    <a-icon size="20" color="grey">cancel</a-icon>
                </a-btn>
            </div>
            <a-divider></a-divider>
        </div>
        <div class="px-6 py-5 u-overflow-y" style="max-height: calc(100% - 164px)">
            <template v-if="moduleType === 'global'">
                <div class="md-body-1 font-weight-medium grey--text text--darken-2">Projects</div>
                <div class="mt-3 u-flex" style="columnGap: 16px;">
                    <div
                        class="px-4 py-3 u-rounded-corners u-flex-center-y u-cursor-pointer"
                        @click="localSetProjectFilterType('all')"
                        :class="[
                            localIsProjectFilter('all') ? 'indigo lighten-5' : 'grey lighten-4',
                            { 'u-opacity-50': isLoading }
                        ]"
                        :style="[{
                            border: localIsProjectFilter('all') ? '1px solid #3949AB !important' : '1px solid #f0f0f0 !important',
                            columnGap: '6px'
                        }]"
                    >
                        <a-icon size="20" :color="localIsProjectFilter('all') ? 'indigo darken-3' : 'grey darken-1'">
                            {{ localIsProjectFilter('all') ? 'radio_button_checked' : 'radio_button_unchecked' }}
                        </a-icon>
                        <h4
                            :class="[localIsProjectFilter('all') ? 'indigo--text text--darken-2' : 'grey--text text--darken-2']"
                            class="md-body-2 font-weight-medium"
                        >
                            All
                        </h4>
                    </div>
                    <div
                        class="px-4 py-3 u-rounded-corners u-flex-center-y u-cursor-pointer"
                        @click="localSetProjectFilterType('with')"
                        :class="[
                            localIsProjectFilter('with') ? 'indigo lighten-5' : 'grey lighten-4',
                            { 'u-opacity-50': isLoading }
                        ]"
                        :style="[{
                            border: localIsProjectFilter('with') ? '1px solid #3949AB !important' : '1px solid #f0f0f0 !important',
                            columnGap: '6px'
                        }]"
                    >
                        <a-icon size="20" :color="localIsProjectFilter('with') ? 'indigo darken-3' : 'grey darken-1'">
                            {{ localIsProjectFilter('with') ? 'radio_button_checked' : 'radio_button_unchecked' }}
                        </a-icon>
                        <h4
                            :class="[localIsProjectFilter('with') ? 'indigo--text text--darken-2' : 'grey--text text--darken-2']"
                            class="md-body-2 font-weight-medium"
                        >
                            With Projects
                        </h4>
                    </div>
                    <div
                        class="px-4 py-3 u-rounded-corners u-flex-center-y u-cursor-pointer"
                        @click="localSetProjectFilterType('without')"
                        :class="[
                            localIsProjectFilter('without') ? 'indigo lighten-5' : 'grey lighten-4',
                            { 'u-opacity-50': isLoading }
                        ]"
                        :style="[{
                            border: localIsProjectFilter('without') ? '1px solid #3949AB !important' : '1px solid #f0f0f0 !important',
                            columnGap: '6px'
                        }]"
                    >
                        <a-icon size="20" :color="localIsProjectFilter('without') ? 'indigo darken-3' : 'grey darken-1'">
                            {{ localIsProjectFilter('without') ? 'radio_button_checked' : 'radio_button_unchecked' }}
                        </a-icon>
                        <h4
                            :class="[localIsProjectFilter('without') ? 'indigo--text text--darken-2' : 'grey--text text--darken-2']"
                            class="md-body-2 font-weight-medium"
                        >
                            Without Projects
                        </h4>
                    </div>
                </div>

                <!-- Project search dropdown -->
                <div class="mt-4" :class="[{ 'u-opacity-40': localIsProjectFilter('without') }]">
                    <a-autocomplete
                        v-model="filters.projects"
                        :items="totalProjectsList"
                        item-text="title"
                        item-value="id"
                        placeholder="Search Projects"
                        class="pa-0 c-tiny-scroll"
                        :class="[projectFilterTypeError ? 'c-wiggle-short u-border-error' : 'u-border']"
                        background-color="grey lighten-5"
                        :append-icon="null"
                        :search-input.sync="searchProject"
                        :loading="mixinIsLoading('project-search')"
                        :disabled="localIsProjectFilter('without') || isLoading"
                        @blur="searchProject = ''"
                        solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object clearable
                    >
                        <template v-slot:selection="{ item, parent }">
                            <a-chip class="mx-1 my-1 chip--select-multi grey lighten-2 grey--text text--darken-3">
                                <div class="d-inline-flex align-center">
                                    <span class="md-body-2">{{ item.title | truncateText(20) }}</span>
                                    <a-icon size="18" color="grey darken-1" class="ml-1 u-cursor-pointer" @click.stop="parent.selectItem(item)">cancel</a-icon>
                                </div>
                            </a-chip>
                        </template>
                        <template v-slot:item="{ item }">
                            <h3
                                :title="item.title"
                                class="md-subtitle-1 text-truncate grey--text text--darken-4"
                                style="max-width: 400px;"
                            >
                                {{ item.title }}
                            </h3>
                        </template>
                        <template #no-data>
                            <div style="height: 32px" class="px-4 u-flex-center-y">
                                <span :class="['md-body-2 body--text text--lighten-1']">
                                    {{ mixinIsLoading('project-search') ? 'Fetching projects...' : 'No Data Found' }}
                                </span>
                            </div>
                        </template>
                    </a-autocomplete>
                </div>
            </template>

            <!-- Date Operator -->
            <div :class="[{ 'mt-5': moduleType === 'global' }]">
                <div class="mb-2 md-body-1 font-weight-medium grey--text text--darken-2">Date</div>
                <a-select
                    v-model="dateOperator"
                    :items="dateOperators"
                    @input="localSetDateOperator"
                    :disabled="isLoading"
                    item-text="label"
                    placeholder="Select Operator"
                    background-color="grey lighten-5"
                    class="u-border"
                    solo flat hide-details return-object clearable
                >
                </a-select>
            </div>

            <!-- Date Picker -->
            <div v-if="dateOperator && !['empty', 'not-empty'].includes(dateOperator.value)" class="mt-3 u-flex u-relative u-z4" style="column-gap: 12px;">
                <div
                    class="u-wfull u-rounded-corners-lg u-flex u-relative"
                    :class="[localDateFieldHasError('from') ? 'c-wiggle-short u-border-error' : 'u-border grey lighten-4']"
                >
                    <a-tooltip bottom :disabled="!localDateFieldHasError('from')" content-class="c-tooltip-pointer c-tooltip-pointer--right">
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="white u-wfull u-rounded-corners">
                                <vc-date-picker
                                    v-model="filters.date.from"
                                    :max-date="filters.date.to"
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
                                    class="d-block u-wfull white u-rounded-corners-lg"
                                >
                                    <template v-slot="{ togglePopover }">
                                        <div
                                            v-ripple="true"
                                            class="u-wfull u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2"
                                            @click="() => { togglePopover({ placement: 'bottom-start' }); }"
                                        >
                                            <div class="px-3 u-flex-center grey lighten-5 u-rounded-corners-lg" style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;">
                                                <a-icon size="22" color="grey darken-1">today</a-icon>
                                            </div>
                                            <a-divider vertical></a-divider>
                                            <div class="md-body-1 u-wfull pa-3" :class="[(filters.date.from ? 'grey--text text--darken-3' : 'body--text text--lighten-3')]">
                                                {{ filters.date.from ? localFormatDate(filters.date.from) : 'From' }}
                                            </div>
                                        </div>
                                    </template>
                                </vc-date-picker>
                                <a-spacer></a-spacer>
                                <a-icon
                                    v-if="filters.date.from"
                                    @click="localClearDate('from')"
                                    size="20"
                                    color="grey"
                                    class="u-absolute"
                                    style="top: 50%; transform: translateY(-50%); right: 8px;"
                                >
                                    cancel
                                </a-icon>
                            </div>
                        </template>
                        <span>Required Field</span>
                    </a-tooltip>
                </div>
                <div
                    v-if="dateOperator && dateOperator.value === 'between'"
                    class="u-wfull u-rounded-corners-lg u-flex-center-y u-relative"
                    :class="[localDateFieldHasError('to') ? 'c-wiggle-short u-border-error' : 'u-border grey lighten-4']"
                >
                    <a-tooltip bottom :disabled="!localDateFieldHasError('to')" content-class="c-tooltip-pointer c-tooltip-pointer--right">
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="white u-wfull u-rounded-corners">
                                <vc-date-picker
                                    v-model="filters.date.to"
                                    :min-date="filters.date.from"
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
                                    class="d-block u-wfull white u-rounded-corners-lg"
                                >
                                    <template v-slot="{ togglePopover }">
                                        <div
                                            v-ripple="!!filters.date.from"
                                            class="u-wfull u-flex align-stretch u-rounded-corners-lg grey--text text--darken-2"
                                            :class="[{ 'u-cursor-pointer': filters.date.from }]"
                                            @click="() => { filters.date.from ? togglePopover({ placement: 'bottom-start' }) : '' }"
                                        >
                                            <div class="px-3 u-flex-center grey lighten-5 u-rounded-corners-lg" style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;">
                                                <a-icon size="22" color="grey darken-1">event</a-icon>
                                            </div>
                                            <a-divider vertical></a-divider>
                                            <div class="md-body-1 u-wfull pa-3" :class="[(filters.date.to ? 'grey--text text--darken-3' : 'body--text text--lighten-3')]">
                                                {{ filters.date.to ? localFormatDate(filters.date.to) : 'To' }}
                                            </div>
                                        </div>
                                    </template>
                                </vc-date-picker>
                                <a-spacer></a-spacer>
                                <a-icon
                                    v-if="filters.date.to"
                                    @click="localClearDate('to')"
                                    size="20"
                                    color="grey"
                                    class="u-absolute"
                                    style="top: 50%; transform: translateY(-50%); right: 8px;"
                                >
                                    cancel
                                </a-icon>
                            </div>
                        </template>
                        <span>Required Field</span>
                    </a-tooltip>
                </div>
            </div>

            <!-- Collaborators -->
            <div class="mt-5">
                <div class="mb-2 md-body-1 font-weight-medium grey--text text--darken-2">Collaborators</div>
                <a-autocomplete
                    v-model="filters.collaborators"
                    :items="totalCollaboratorsList"
                    item-text="email"
                    item-value="id"
                    placeholder="Search Collaborator"
                    class="pa-0 u-border"
                    background-color="grey lighten-5"
                    :append-icon="null"
                    :search-input.sync="searchCollaborator"
                    :loading="mixinIsLoading('collaborators-search')"
                    :disabled="isLoading"
                    @blur="searchCollaborator = ''"
                    solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object clearable
                >
                    <template v-slot:selection="{ item, parent }">
                        <a-chip class="mx-1 my-1 chip--select-multi grey lighten-2 grey--text text--darken-3">
                            <div class="d-inline-flex align-center">
                                <span class="md-body-2">{{ item.email | truncateText(20) }}</span>
                                <a-icon size="18" color="grey darken-1" class="ml-1 u-cursor-pointer" @click.stop="parent.selectItem(item)">cancel</a-icon>
                            </div>
                        </a-chip>
                    </template>
                    <template #no-data>
                        <div style="height: 32px" class="px-4 u-flex-center-y">
                            {{ mixinIsLoading('collaborators-search') ? 'Fetching keywords...' : 'No Data Found' }}
                        </div>
                    </template>
                </a-autocomplete>
            </div>

            <a-divider class="mt-6"></a-divider>

            <!-- Trackers -->
            <div class="mt-4">
                <div class="mb-2 md-body-1 font-weight-medium grey--text text--darken-2">Trackers</div>
                <a-autocomplete
                    v-model="filters.trackers"
                    :items="totalTrackersList"
                    item-text="name"
                    item-value="id"
                    placeholder="Search Trackers"
                    class="pa-0 u-border"
                    background-color="grey lighten-5"
                    :append-icon="null"
                    :search-input.sync="searchTracker"
                    :loading="mixinIsLoading('tracker-search')"
                    :disabled="isLoading"
                    @blur="searchTracker = ''"
                    solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object clearable
                >
                    <template v-slot:selection="{ item, parent }">
                        <a-chip class="mx-1 my-1 chip--select-multi grey lighten-2 grey--text text--darken-3">
                            <div class="d-inline-flex align-center">
                                <span class="md-body-2">{{ item.name | truncateText(20) }}</span>
                                <a-icon size="18" color="grey darken-1" class="ml-1 u-cursor-pointer" @click.stop="parent.selectItem(item)">cancel</a-icon>
                            </div>
                        </a-chip>
                    </template>
                    <template #no-data>
                        <div style="height: 32px" class="px-4 u-flex-center-y">
                            {{ mixinIsLoading('tracker-search') ? 'Fetching trackers...' : 'No Data Found' }}
                        </div>
                    </template>
                </a-autocomplete>
            </div>

            <!-- Keywords -->
            <div class="mt-5">
                <div class="mb-2 md-body-1 font-weight-medium grey--text text--darken-2">Keywords</div>
                <a-autocomplete
                    v-model="filters.keywords"
                    :items="totalKeywordsList"
                    item-text="name"
                    item-value="id"
                    placeholder="Search Keywords"
                    class="pa-0 u-border"
                    background-color="grey lighten-5"
                    :append-icon="null"
                    :search-input.sync="searchKeyword"
                    :loading="mixinIsLoading('keyword-search')"
                    :disabled="isLoading"
                    @blur="searchKeyword = ''"
                    solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object clearable
                >
                    <template v-slot:selection="{ item, parent }">
                        <a-chip class="mx-1 my-1 chip--select-multi grey lighten-2 grey--text text--darken-3">
                            <div class="d-inline-flex align-center">
                                <span class="md-body-2">{{ item.name | truncateText(20) }}</span>
                                <a-icon size="18" color="grey darken-1" class="ml-1 u-cursor-pointer" @click.stop="parent.selectItem(item)">cancel</a-icon>
                            </div>
                        </a-chip>
                    </template>
                    <template #no-data>
                        <div style="height: 32px" class="px-4 u-flex-center-y">
                             {{ mixinIsLoading('keyword-search') ? 'Fetching keywords...' : 'No Data Found' }}
                        </div>
                    </template>
                </a-autocomplete>
            </div>
        </div>

        <div class="white u-absolute u-wfull" style="bottom: 0; border-radius: 0px 0px 8px 8px;">
            <a-divider></a-divider>
            <div class="px-6 pa-6 u-flex-center-y">
                <div class="u-flex-center-y">
                    <a-btn
                        :disabled="isLoading"
                        @click="localApplyFilter()"
                        color="indigo darken-1"
                        class="c-btn-filter white--text mr-4"
                        depressed
                    >
                        Filter
                    </a-btn>
                    <a-btn class="grey--text text--darken-3" depressed @click="localCloseFilter()">Cancel</a-btn>
                </div>
                <a-spacer></a-spacer>
                <a-btn
                    :disabled="isLoading"
                    @click="localClearFilter()"
                    color="red lighten-5"
                    class="c-btn-clearAll red--text text--darken-1"
                    depressed
                >
                    Clear all
                </a-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import loadWorkspace from '@/mixins/mixin-workspace'
import { diffSimple } from '@/helpers/helper-date-filter';

export default {
    mixins: [mixinLoading, loadWorkspace],

    props: {
        isOpen: {
            type: Boolean
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        moduleType: {
            type: String,
            default: 'global'
        }
    },

    data () {
        return {
            searchKeyword: '',
            searchTracker: '',
            searchProject: '',
            searchCollaborator: '',
            dateOperator: '',

            projectSearchTimeout: null,
            trackerSearchTimeout: null,
            keywordSearchTimeout: null,
            collaboratorSearchTimeout: null,

            totalKeywords: [],
            totalProjectsList: [],
            totalTrackersList: [],
            totalKeywordsList: [],
            totalCollaboratorsList: [],
            dateOperators: [
                { label: 'is', value: 'equals' },
                { label: 'is not', value: 'not-equals' },
                { label: 'is within', value: 'between' },
                { label: 'is after', value: 'greater-than' },
                { label: 'is on or after', value: 'greater-than-or-equal' },
                { label: 'is before', value: 'lesser-than' },
                { label: 'is on or before', value: 'lesser-than-or-equal' },
            ],

            dateFieldError: [],
            projectFilterTypeError: false,
            projectFilters: {
                count: 5,
                'fields[projects]': 'id,title',
            },
            projectFilterSlugs: {
                STATUS: 'filter[status]',
                WORKSPACE: 'filter[workspace_id]',
            },
            filters: {
                withProjects: 'all',
                projects: [],
                trackers: [],
                keywords: [],
                date: { operator: null, from: null, to: null },
                collaborators: []
            },
            defaultFilters: {
                withProjects: 'all',
                projects: [],
                trackers: [],
                keywords: [],
                date: { operator: null, from: null, to: null },
                collaborators: []
            },
        }
    },

    watch: {
        isOpen: {
            handler (val) {
                if (val) this.localIndex()
            },
            immediate: true
        },

        searchProject (val) {
            if (!val || (val && !val.trim())) return
            this.localFetchProjects(val)
        },

        searchTracker (val) {
            if (!val || (val && !val.trim())) return
            this.localFetchTrackers(val)
        },

        searchKeyword (val) {
            if (!val || (val && !val.trim())) return
            this.localFetchKeywords(val)
        },

        searchCollaborator (val) {
            if (!val || (val && !val.trim())) return
            this.localFetchCollaborators(val)
        },

        'filters.date': {
            handler (newDateVal, oldDateVal) {
                if (newDateVal.to && !newDateVal.from) {
                    this.$set(this.filters.date, 'to', null)
                }
            },
            deep: true
        }
    },

    computed: {
        ...mapState('Project', {
            project_list: 'list'
        }),

        ...mapState('Gong', {
            gong_users_list: 'users',
            gong_trackers_list: 'trackers',
            gong_keywords_list: 'keywords',
        }),
    },

    methods: {
        localIndex () {
            this.totalProjectsList = _.cloneDeep([...this.totalProjectsList, ...this.project_list])
            this.totalTrackersList = _.cloneDeep([...this.totalTrackersList, ...this.gong_trackers_list])
            this.totalKeywordsList = _.cloneDeep([...this.totalKeywordsList, ...this.gong_keywords_list])
            this.totalCollaboratorsList = _.cloneDeep([...this.totalCollaboratorsList, ...this.gong_users_list])
        },

        localSetProjectFilterType (value) {
            if (this.isLoading) return
            this.filters.withProjects = value
        },

        async localFetchProjects (val) {
            this.mixinSetLoading('project-search')
            clearTimeout(this.projectSearchTimeout)

            this.projectSearchTimeout = setTimeout(async () => {
                await this.project_index({
                    'filter[title]': val || '',
                    ...this.projectFilters
                })
                this.totalProjectsList = [..._.cloneDeep(this.totalProjectsList), ..._.cloneDeep(this.project_list)]
                this.mixinResetLoading('project-search')
            }, 1000);
        },

        async localFetchTrackers (val) {
            this.mixinSetLoading('tracker-search')
            clearTimeout(this.trackerSearchTimeout)

            this.trackerSearchTimeout = setTimeout(async () => {
                await this.gong_trackers_index({ 'filter[name]': val || '' })
                this.totalTrackersList = _.cloneDeep([...this.totalTrackersList, ...this.gong_trackers_list])
                this.mixinResetLoading('tracker-search')
            }, 1000);
        },

        async localFetchKeywords (val) {
            this.mixinSetLoading('keyword-search')
            clearTimeout(this.keywordSearchTimeout)

            this.keywordSearchTimeout = setTimeout(async () => {
                await this.gong_keywords_index({ 'filter[name]': val || '' })
                this.totalKeywordsList = _.cloneDeep([...this.totalKeywordsList, ...this.gong_keywords_list])
                this.mixinResetLoading('keyword-search')
            }, 1000);
        },

        async localFetchCollaborators (val) {
            this.mixinSetLoading('collaborators-search')
            clearTimeout(this.collaboratorSearchTimeout)

            this.collaboratorSearchTimeout = setTimeout(async () => {
                await this.gong_users_index({ 'filter[email]': val || '' })
                this.totalCollaboratorsList = _.cloneDeep([...this.totalCollaboratorsList, ...this.gong_users_list])
                this.mixinResetLoading('collaborators-search')
            }, 1000);
        },

        localSetProjectFilters () {
            this.projectFilters[this.projectFilterSlugs.STATUS] = 'active'
            this.projectFilters[this.projectFilterSlugs.WORKSPACE] = this.current_workspace_id_mixin
        },

        localSetDateOperator (operator) {
            if (!operator) return this.filters.date = { operator, from: null, to: null }

            if (
                (this.dateOperator && this.dateOperator.value === 'between' && operator.value !== 'between')
                || operator.value === 'between'
            ) {
                this.filters.date.to = null
            }

            this.dateOperator = _.cloneDeep(operator)
            this.filters.date.operator = _.cloneDeep(operator)
        },

        localClearDate (slug) {
            this.$set(this.filters.date, slug, null)
        },

        localFormatDate (date) {
            return date ? diffSimple(date) : null
        },

        async localApplyFilter () {
            const { dateValidated } = await this.localValidateDateFields()
            if (!dateValidated.ok) return

            this.$emit('filter', this.filters)
            this.localCloseFilter()
        },

        localCloseFilter () {
            this.$emit('close')
            this.dateFieldError = []
        },

        async localValidateDateFields () {
            const dateValidated = { ok: true }
            const { operator, from, to } = this.filters.date

            this.dateFieldError = []
            if (operator) {
                if (operator.value === 'between') {
                    if (!from) this.dateFieldError.push('from')
                    if (!to) this.dateFieldError.push('to')
                    if (!from || !to) dateValidated.ok = false
                }

                if (operator.value !== 'between' && !from) {
                    this.dateFieldError.push('from')
                    dateValidated.ok = false
                }
            }

            return { dateValidated }
        },

        localDateFieldHasError (slug) {
            return this.dateFieldError.indexOf(slug) !== -1
        },

        localIsProjectFilter (slug) {
            return this.filters.withProjects === slug
        },

        localClearFilter () {
            this.projectFilterTypeError = false
            this.filters = _.cloneDeep(this.defaultFilters)
            this.dateOperator = ''
            this.$emit('clear')
        },

        ...mapActions('Project', {
            project_index: 'index'
        }),

        ...mapActions('Gong', {
            gong_users_index: 'users_index',
            gong_trackers_index: 'trackers_index',
            gong_keywords_index: 'keywords_index',
        }),
    }
}
</script>

<style scoped lang="scss">
.c-radio-btn {
    border: 1px solid #3949AB;
    background-color: #E8EAF6;
}

.c-btn-clearAll.v-btn.v-btn--disabled {
    opacity: 0.5;
    background-color: #FFEBEE !important;
    color: #E53935 !important;
}
</style>

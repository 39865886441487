<template>
    <div class="c-settings-report-block mx-2 mt-4">
        <div v-if="dataTestcaseStatusDefault">
            <div class="grey--text text--darken-1 text-uppercase caption font-weight-bold mb-2" style="letter-spacing: 1px;">{{ dataTestcaseStatusDefault.title }}</div>
            <a-menu class="d-block" offset-y bottom transition="slide-y-transition" content-class="c-radius u-border u-elevation-custom-1">
                <template v-slot:activator="{ on }">
                    <div v-on="on" class="pa-2 c-radius u-cursor-pointer grey lighten-3 u-flex align-center u-wfull">
                        <span class="u-flex align-center u-rounded-corners">
                            {{ selectedStatus.label }}
                        </span>
                        <a-spacer></a-spacer>
                        <a-icon color="grey darken-2" size="24">arrow_drop_down</a-icon>
                    </div>
                </template>
                <a-list class="c-scrollbar pa-0 py-2 u-list-condensed" style="max-height: 320px;">
                    <a-list-item v-for="(menu, index) in dataTestcaseStatusDefault.options" :key="menu.level" class="u-wfull px-4" :class="[dataTestcaseStatusDefault.options.length - 1 !== index ? 'mb-2' : '' ]" @click="methodUpdateStatus(menu, dataTestcaseStatusDefault.id)">
                        <a-list-item-content>
                            <a-list-item-title class="grey--text text--darken-2">{{ menu.label }}</a-list-item-title>
                        </a-list-item-content>
                    </a-list-item>
                </a-list>
            </a-menu>
        </div>
        <div v-if="dataTestcaseColumnsDefault" class="u-flex align-center grey--text text--darken-1 text-uppercase caption font-weight-bold mb-2 mt-4" style="letter-spacing: 1px;">
            {{ dataTestcaseColumnsDefault.title }}
        </div>
        <a-list class="overflow-y-auto u-list-condensed mb-3" style="max-height: 320px; padding: 0px 0px !important;">
            <a-list-item v-for="(menu, index) in dataTestcaseColumnsDefault.options[0].value" :key="menu + index" class="u-wfull mb-2 px-0 c-radius" @click="methodUpdateColumns(menu, dataTestcaseColumnsDefault.id)">
                <a-list-item-action class="mr-2" v-if="menu">
                    <svg v-if="methodBlockHasField(menu.value)" width="16px" height="16px" viewBox="0 0 16 16" class="bi bi-check-circle-fill blue--text text--darken-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                    <svg v-if="!methodBlockHasField(menu.value)" width="16px" height="16px" viewBox="0 0 16 16" class="bi bi-circle grey--text text--darken-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    </svg>
                </a-list-item-action>
                <a-list-item-content>
                    <a-list-item-title class="grey--text text--darken-2 text-capitalize">{{ menu.label }}</a-list-item-title>
                </a-list-item-content>
            </a-list-item>
        </a-list>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import EventBus from '@/config/config-eventbus'

export default {
    props: ['clonedUsecaseDetail', 'index'],

    data () {
        return {
            currentOption: '',
            currentMenuValue: '',
            selectedColumns: [],
            selectedStatus: { label: 'All', value: 'all' },
        }
    },

    mounted () {
        this.methodIndex()
    },

    computed: {
        dataGetBlockInfo () {
            return this.getBlockContent(this.clonedUsecaseDetail.report_block_id)
        },

        dataTestcaseStatusDefault () {
            const info = this.methodDefaultAttrByTitle('Test Case Status')
            const attr = this.getBlockAttrs(this.clonedUsecaseDetail.id, info.id)
            this.selectedStatus = attr && attr.value ? attr.value : ''
            return info
        },

        dataTestcaseColumnsDefault () {
            const info = this.methodDefaultAttrByTitle('Test Case Columns')
            const attr = this.getBlockAttrs(this.clonedUsecaseDetail.id, info.id)
            this.selectedColumns = attr && attr.value ? attr.value : ''
            return info
        },

        ...mapGetters('ReportBlock', [
            'getDefaultBlockAttr',
            'getBlockContent'
        ]),

        ...mapGetters('ReportTemplateBlock', [
            'getBlockAttrs'
        ]),

        ...mapGetters('ReportTemplate', [
            'getBlock',
        ]),
    },

    methods: {
        async methodIndex () {
            this.dataTestcaseStatusDefault
        },

        methodUpdateStatus (menu, defaultAttrId) {
            this.methodSetCurrentOption(defaultAttrId)
            this.selectedStatus = menu
            this.currentMenuValue = menu
            this.methodUpdateBlockAttr()
        },

        methodUpdateColumns (menu, defaultAttrId) {
            const index = this.selectedColumns.value.findIndex(item => item.value === menu.value)
            this.methodSetCurrentOption(defaultAttrId)

            if (index !== -1) this.selectedColumns.value.splice(index, 1)
            else this.selectedColumns.value.push(menu)

            this.currentMenuValue = this.selectedColumns
            this.methodUpdateBlockAttr()
        },

        methodSetCurrentOption (defaultAttrId) {
            this.currentOption = {
                defaultAttrId: defaultAttrId,
                menuAttrId: this.getBlockAttrs(this.clonedUsecaseDetail.id, defaultAttrId).id
            }
        },

        methodBlockHasField (value) {
            const index = this.selectedColumns.value.findIndex(item => item.value === value)
            return index !== -1
        },

        async methodUpdateBlockAttr () {
            if (!this.$can('document_generator.update')) return
            const params = {
                rt_block_id: this.clonedUsecaseDetail.id,
                rb_attribute_id: this.currentOption.defaultAttrId,
                value: JSON.stringify(this.currentMenuValue)
            }

            await this.actionTemplateBlockAttrEdit({ id: this.currentOption.menuAttrId ? this.currentOption.menuAttrId : null, ...params })
            EventBus.$emit('editBlock', this.clonedUsecaseDetail)
            this.actionTemplateBlockShow({ id: this.clonedUsecaseDetail.id, index: this.index })
        },

        methodDefaultAttrByTitle (title) {
            return this.dataGetBlockInfo.attributes.find(item => item.title === title)
        },

        ...mapActions('ReportTemplateBlock', [
            'actionTemplateBlockEdit',
            'actionTemplateBlockAttrsStore',
            'actionTemplateBlockAttrEdit',
            'actionTemplateBlockShow'
        ]),
    }
}
</script>

<template>
    <a-card class="u-relative white u-rounded-corners-lg">
        <div class="py-5 px-6 u-flex-center-y">
            <div class="u-flex align-center">
                <div class="pa-2 mr-3 u-rounded-corners-full" style="background-color: #000">
                    <a-icon size="24" color="white darken-1">{{ localGetScopeItem('icon') }}</a-icon>
                </div>
                <div>
                    <h2 class="md-heading-6 indigo--text text--darken-3">Bulk {{ localGetScopeItem('title') }}</h2>
                    <h4 class="md-subtitle-2 grey--text text--darken-1">{{ localGetScopeItem('text') }}</h4>
                </div>
            </div>
            <a-spacer></a-spacer>
            <div class="md-caption py-1 pa-2 u-rounded-corners-sm" style="border: 1px solid #F5F5F5; font-weight: 500; color: #424242">
                <template v-if="ucBulkCount">{{ ucBulkCount }} Use Cases Selected</template>
                <template v-if="scBulkCount">{{ scBulkCount }} Success Criteria Selected</template>
                <template v-if="tcBulkCount">{{ tcBulkCount }} Test Cases Selected</template>
            </div>
        </div>
        <a-divider></a-divider>
        <a-sheet class="py-3 pt-0 px-6" min-height="400">
            <template v-if="!removeAssigneesAndTags">
                <a-flex class="mt-4">
                    <a-autocomplete
                        v-model="selectedList"
                        :items="local_load_list"
                        :item-text="localLoadItemText"
                        item-value="id"
                        :placeholder="!loading ? localGetPlaceholder : ''"
                        class="pa-0 pt-0 u-border"
                        background-color="grey lighten-5"
                        :loading="searchLoading"
                        :search-input.sync="local_searchby_type"
                        @blur="localBlurTagField"
                        @keyup.enter="localSetInput(local_searchby_type)"
                        :hide-no-data="$can('tags.store') && isAddTag && !isRemoveTag"
                        :disabled="loading"
                        solo flat hide-details hide-selected multiple chips deletable-chips auto-select-first return-object
                    >
                        <template v-slot:selection="{ parent, item }">
                            <span v-if="(isAddAssignee || isRemoveAssignee) && local_load_list.length" class="d-inline-flex align-center u-rounded-corners-full grey lighten-2 pl-3 pr-2 py-2 mx-1 my-1">
                                <span class="mr-2 md-body-2 grey--text text--darken-4" :title="item.user.name">{{ item.user.name | truncateText(20) }}</span>
                                <a-icon size="16" @click.stop="parent.selectItem(item)" class="u-cursor-pointer">cancel</a-icon>
                            </span>
                            <a-chip v-else :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}" class="chip--select-multi">
                                <div class="d-inline-flex align-center">
                                    <span class="md-body-2" :style="{ color: $color(item.color, 'color_text') }">{{ item.label | truncateText(20)}}</span>
                                    <a-icon size="18" :color="$color(item.color, 'color_text')" class="u-cursor-pointer ml-1"  @click.stop="parent.selectItem(item)">cancel</a-icon>
                                </div>
                            </a-chip>
                        </template>
                        <template v-slot:item="{ item }">
                            <a-layout v-if="(isAddAssignee || isRemoveAssignee) && local_load_list.length" align-center class="py-1 px-0">
                                <a-list-item :key="item.id" class="u-list-condensed py-0 px-0 u-rounded-corners">
                                    <a-list-item-icon>
                                        <g-avatar :item="item" :size="40" :right-space="false"></g-avatar>
                                    </a-list-item-icon>
                                    <a-list-item-content class="ml-3 u-flex-center-y">
                                        <a-list-item-title :title="item.user.name" class="md-subtitle-1 grey--text text--darken-3 font-weight-medium text-truncate mb-1" style="max-width: 350px">
                                            {{ item.user.name }}
                                        </a-list-item-title>
                                        <a-list-item-subtitle class="md-body-2 grey--text text--darken-1 mb-0">
                                            <template v-if="item.scope === 'internal'">
                                                <template v-if="item.type">{{ item.type.value }}</template>
                                            </template>
                                            <template v-else>
                                                <template v-if="item.user.resource_type === 'Customer'">Client</template>
                                                <template v-else>Partner</template>
                                                <span v-if="isAddAssignee" class="orange--text text--darken-1 md-caption font-weight-medium ml-1">(External Collaborator: Use Case will be made PUBLIC)</span>
                                            </template>
                                        </a-list-item-subtitle>
                                    </a-list-item-content>
                                </a-list-item>
                            </a-layout>
                            <div v-else-if="item && item.id === 'new'" class="mx-1 mb-0 u-wfull" @click="localSetTag(item)">
                                <div v-if="item && item.id === 'new' && $can('tags.store')" style="height: 32px" class="u-flex-center-y u-cursor-pointer">
                                    <a-icon size="20" color="indigo darken-1">add</a-icon>
                                    <span :class="['md-body-2 indigo--text text--darken-1 ml-1']">
                                        Create
                                        <span class="font-weight-bold">{{ item.label | truncateText(30) }}</span>
                                    </span>
                                </div>
                            </div>
                            <div v-else>
                                <template>
                                    <div v-if="item">
                                        <g-tags :tag="item" hide-clear-icon></g-tags>
                                    </div>
                                    <div v-else @click="localBlurTagField()" style="height: 32px" class="u-flex-center-y">
                                        No Data Found
                                    </div>
                                </template>
                            </div>
                        </template>
                        <template #no-data v-if="!mixinIsLoading('search-data')">
                            <div style="height: 32px" class="px-4 u-flex-center-y">
                                <span :class="['md-body-2 body--text text--lighten-1']">{{ searchLoading ? 'Loading ...' : 'No Data Found' }}</span>
                            </div>
                        </template>
                    </a-autocomplete>
                </a-flex>
            </template>

            <template v-else>
                <div class="u-flex-center-y align-center pa-3 mt-4 amber lighten-5" style="border: 3px solid #ffffff">
                    <a-icon size="18" style="margin-top: -2px" color="#FF6D00">warning</a-icon>
                    <span class="text-center orange--text text--accent-4 ml-2 md-subtitle-2">{{`Enabling this checkbox will remove all ${isRemoveAssignee ? 'assignees' : 'tags'} from ${localGetModule}`}}</span>
                </div>
            </template>

            <!-- Remove all -->
            <div v-if="isRemoveAssignee || isRemoveTag" class="u-flex mt-2">
                <a-checkbox :disabled="loading" v-model="removeAssigneesAndTags" color="blue darken-2" class="ml-1" style="margin-top: 2px" hide-details dense small></a-checkbox>
                <div class="mt-2">
                    <p class="md-subtitle-2 mb-0 grey--text" :class="[loading ? 'text--darken-1' : 'text--darken-3']">{{`Remove all ${isRemoveAssignee ? 'assignees' : 'tags'}`}}</p>
                </div>
            </div>
            <a-sheet class="mx-auto my-auto py-8" v-if="!(selectedList.length || local_searchby_type)" min-height="120" max-width="400">
                <div v-if="isAddAssignee && !removeAssigneesAndTags" class="d-flex flex-column align-center mt-12">
                    <img src="../../../assets/images/add-assignee.svg">
                    <h3 class="md-subtitle-1 text-center grey--text text--darken-2 mt-3">Streamline your workflow by using the <br> bulk add assignees to assign multiple users at once</h3>
                </div>
                <div v-if="isRemoveAssignee && !removeAssigneesAndTags" class="d-flex flex-column align-center mt-6">
                    <img src="../../../assets/images/remove-assignee.svg">
                    <h3 class="md-subtitle-1 text-center grey--text text--darken-2 mt-3">Use the bulk remove feature to quickly unassign users from multiple <span class="text-lowercase">{{`${localGetModule}`}}</span> at once</h3>
                </div>
                <div v-if="isAddTag && !removeAssigneesAndTags" class="d-flex flex-column align-center mt-12">
                    <img src="../../../assets/images/add-tags.svg" style="width: 100px">
                    <h3 class="md-subtitle-1 text-center grey--text text--darken-2 mt-3">Easily organize content! Use Bulk Add tags to <br> swiftly attach multiple tags at once</h3>
                </div>
                <div v-if="isRemoveTag && !removeAssigneesAndTags" class="d-flex flex-column align-center mt-8">
                    <img src="../../../assets/images/remove-tags.svg" style="width: 100px">
                    <h3 class="md-subtitle-1 text-center grey--text text--darken-2 mt-3">Easily declutter your data! Use Bulk Remove to swiftly eliminate multiple tags in one go</h3>
                </div>
            </a-sheet>
        </a-sheet>
        <a-divider></a-divider>
        <div class="u-flex-center-y pb-6 px-6 u-relative mt-4" >
            <div 
                v-if="localCheckActionChanges" 
                class="py-2 px-3 md-body-2 u-absolute u-rounded-corners blue lighten-5 blue--text text--darken-2 u-flex-center-y align-center" 
                style="top: -45px; z-index: 9"
            >
                <a-icon size="17" color="#1976D2">error</a-icon>
                <span class="text-center ml-2">
                    <template 
                        v-if="isAddAssignee || isAddTag"
                    >
                        This action will make changes to {{ isAddAssignee ? localGetAssignedScopes : isAddTag ? localGetTagScopes : '' }} {{ localGetModule }}.
                    </template>
                    <template 
                        v-if="isRemoveAssignee || isRemoveTag" 
                    >
                        This action will make changes to {{ isRemoveAssignee ? localGetAssignedScopes : isRemoveTag ? localGetTagScopes : '' }} {{ localGetModule }}.
                    </template>
                </span>
            </div>
            <div 
                v-if="errrorMessage"
                class="py-2 px-3 md-body-2 u-absolute u-rounded-corners red lighten-5 text--darken-2 u-flex-center-y align-center" 
                style="top: -45px; z-index: 9"
            >
                <a-icon size="17" color="red darken-1">error</a-icon>
                <span class="ml-2 red--text text--darken-1">{{ errrorMessage }}</span>
            </div>
            <a-btn v-if="(isAddAssignee || isRemoveAssignee || isAddTag || isRemoveTag)" class="primary darken-1 ma-0 u-rounded-corners" depressed :disabled="localCheckCollabs" :loading="loading" @click="localUpdate">{{ updateConfirm ? 'Confirm' : 'Update' }}</a-btn>
            <a-btn class="grey--text text--darken-1 ma-0 ml-3 u-rounded-corners" text @click="$emit('close')">Cancel</a-btn>
            <a-spacer></a-spacer>
            <a-btn class="red--text u-rounded-corners px-4 py-4" text depressed v-if="!updateConfirm && selectedList && selectedList.length" @click="selectedList = []">Clear All</a-btn>
        </div>
    </a-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import { Validate } from '@/helpers/helper-validations'
import {
    STagsInlineForm,
} from '@/config/config-shared-components'

export default {
    props: {
        module: {
            type: String
        },
        isOpen: {
            type: Boolean
        },
        loading: {
            type: Boolean
        },
        usecaseTags: {
            type: Array,
        },
        isAddAssignee: {
            type: Boolean,
            default: false
        },
        isAddTag: {
            type: Boolean,
            default: false
        },
        isRemoveAssignee: {
            type: Boolean,
            default: false
        },
        isRemoveTag: {
            type: Boolean,
            default: false
        },
        ucBulkCount: {
            type: Number
        },
        scBulkCount: {
            type: Number
        },
        tcBulkCount: {
            type: Number
        },
        ucFilteredList: {
            type: Array,
            default: []
        },        
        scFilteredList: {
            type: Array,
            default: []
        },        
        tcFilteredList: {
            type: Array,
            default: []
        }
    },

    components: {
        STagsInlineForm,
    },

    mixins: [mixinLoading],

    data () {
        return {
            searchList: [],
            selectedCollabList: [],
            searchLoading: false,
            searchTimeout: null,
            removeAssigneesAndTags: false,
            searchTags: '',
            selectedTagList: [],
            updateConfirm: false,
            local_searchby_type: '',
            selectedList: [],
            local_load_list: [],
            tagType: 'usecase_tag',
            errrorMessage: '',
            scope_items: [
                { slug:'isAddAssignee', title: 'Add Assignee', text: 'Assign multiple users at once',  icon: 'person_add_alt_1' },
                { slug:'isRemoveAssignee', title: 'Remove Assignee', text: 'Bulk remove assignees at once', icon: 'person_remove' },
                { slug:'isAddTag', title: 'Add Tags',text: 'Attach multiple tags at once', icon: '$scope_bulkadd_tagwhite' },
                { slug:'isRemoveTag', title: 'Remove Tags',text: 'Eliminate multiple tags at once', icon: '$scope_bulkremove_tagwhite' },
            ],
            validateCount: [],
            validateScopeCount: [],
            scopeTagIds: [],
            scopeAssigneeIds: [],
            removeAllCount: [],
        }
    },

    watch: {
        isOpen (value) {
            if (!value) return
            this.localResetVars()
            this.localSetFilterList()
            this.scopeTagIds = []
            this.scopeAssigneeIds = []
            this.validateScopeCount = []
            this.removeAllCount = []
        },

        selectedList(newList, oldList) {
            if (this.isAddAssignee || this.isRemoveAssignee) this.selectedCollabList = newList
            if (this.isAddTag || this.isRemoveTag) this.selectedTagList = newList
            if (newList.length != oldList.length) {
                this.scopeTagIds = []
                this.scopeAssigneeIds = []
                this.validateCount = []
                this.validateScopeCount = []
                this.removeAllCount = []
                this.updateConfirm = false
            }
        },

        removeAssigneesAndTags(state) {
            if (state) this.selectedList = []
            else this.updateConfirm = false
        },
        
        local_searchby_type (val) {
            if (!_.trim(val)) return this.errrorMessage = ''

            if (this.isAddTag) {
                const index = this.local_load_list.findIndex(item => item.id === 'new')
                if (index !== -1) this.local_load_list.splice(index, 1)
                this.errrorMessage = ''
                const { message } = new Validate(val, { silent: true }).length(1, 255).run()
                if (message) return this.errrorMessage = message
                this.errrorMessage = ''
            }
            this.searchLoading = true
            this.localCollabTagSearchFilter(val)
        },
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localGetModule () {
            switch (this.module) {
                case 'all-uc': return 'Use Cases'
                case 'all-sc': return 'Success Criteria'
                case 'all-tc': return 'Test Cases'
            }
        },

        localCheckCollabs() {
            if ((this.isRemoveAssignee || this.isRemoveTag) && this.removeAssigneesAndTags) return !this.removeAssigneesAndTags
            return !(this.selectedCollabList.length || this.selectedTagList.length)
        },

        localGetPlaceholder() {
            if (this.isAddAssignee) return 'Select or Search for collaborators to add'
            if (this.isRemoveAssignee) return 'Select or Search for collaborators to remove'
            if (this.isAddTag && this.$can('tags.store')) return 'Search, select, or create tags to add'
            if (this.isAddTag && !this.$can('tags.store')) return 'Select or search for tags to add'
            if (this.isRemoveTag) return 'Search or select for tags to remove'
        },

        localGetPrependIcon ()  {
            if (this.isAddAssignee || this.isRemoveAssignee) return 'person_search'
            if (this.isAddTag) return 'add'
            if (this.isRemoveTag) return "remove_circle_outline"
        },

        localGetScopeList () {
            let scopeList
            switch (this.module) {
                case 'all-uc':
                    scopeList = _.cloneDeep(this.ucFilteredList);
                    break;
                case 'all-sc':
                    scopeList = _.cloneDeep(this.scFilteredList);
                    break;
                case 'all-tc':
                    scopeList = _.cloneDeep(this.tcFilteredList);
                    break;
            }
            return scopeList
        },

        localGetAssignedScopes () {
            let scopeList = this.localGetScopeList
            let repeatedId = {}
            const deepFilter = []

            let filteredScope = scopeList.filter(item => item.assignees.length)
            this.removeAllCount.push(filteredScope.length)
            if (!this.removeAssigneesAndTags) {
                this.selectedCollabList.forEach(collab => {
                    filteredScope.forEach(item => {
                        let assignee = item.assignees.find(t => t.assignee.user_id === collab.user.id)
                        if (assignee) {
                            this.scopeAssigneeIds.push(item.id)
                            deepFilter.push(assignee.id)
                        } 
                    })
                });

                if (deepFilter.length) {
                    repeatedId = deepFilter.reduce((item, current) => {
                        item[current] = (item[current] || 0) + 1
                        return item
                    }, {})
                }
            } else filteredScope.forEach(item => this.scopeAssigneeIds.push(item.id))

            this.selectedCollabList.forEach(item => this.validateCount.push(repeatedId.hasOwnProperty(item.user_id)))
            const countState = this.validateCount.includes(false)

            if (this.isAddAssignee && !this.removeAssigneesAndTags) {
                Object.values(repeatedId).forEach( item => this.validateScopeCount.push(scopeList.length - item))
                if (!countState) return Math.max(...this.validateScopeCount)
                if (countState) return scopeList.length
                return 0
            }
            if (this.isRemoveAssignee && !this.removeAssigneesAndTags) {
                Object.values(repeatedId).forEach( item => this.validateScopeCount.push(item))
                if (this.validateScopeCount.length) return Math.max(...this.validateScopeCount)
                return 0
            }
            return Math.max(...this.removeAllCount)
        },

        localGetTagScopes () {
            let scopeList = this.localGetScopeList
            let repeatedId = {}
            const deepFilter = []

            let filteredScope = scopeList.filter(item => item.tags.length)
            this.removeAllCount.push(filteredScope.length)
            if (!this.removeAssigneesAndTags) {
                this.selectedTagList.forEach(tag => {
                    filteredScope.forEach(item => {
                        let tagItem = item.tags.find(t => t.id === tag.id)
                        if (tagItem) {
                            this.scopeTagIds.push(item.id)
                            deepFilter.push(tagItem.id)
                        }
                    })
                });

                if (deepFilter.length) {
                    repeatedId = deepFilter.reduce((item, current) => {
                        item[current] = (item[current] || 0) + 1
                        return item
                    }, {})
                }
            } else filteredScope.forEach(item => this.scopeTagIds.push(item.id))

            this.selectedTagList.forEach(item => this.validateCount.push(repeatedId.hasOwnProperty(item.id)))
            const countState = this.validateCount.includes(false)

            if (this.isAddTag && !this.removeAssigneesAndTags) {
                Object.values(repeatedId).forEach( item => this.validateScopeCount.push(scopeList.length - item))
                if (!countState) return Math.max(...this.validateScopeCount)
                if (countState) return scopeList.length
                return 0
            }
            if (this.isRemoveTag && !this.removeAssigneesAndTags) {
                Object.values(repeatedId).forEach( item => this.validateScopeCount.push(item))
                if (this.validateScopeCount.length) return Math.max(...this.validateScopeCount)
                return 0
            }
            return Math.max(...this.removeAllCount)
        },

        localLoadItemText () {
            if (this.isAddAssignee || this.isRemoveAssignee) return 'user.name'
            if (this.isAddTag || this.isRemoveTag) return 'label'
        },

        localCheckActionChanges () {
            return !this.errrorMessage && this.updateConfirm && (this.isAddAssignee || this.isRemoveAssignee || this.removeAssigneesAndTags || this.isAddTag || this.isRemoveTag)
        },

        ...mapState('Collaborator', {
            collaborator_list: 'list',
            collaborator_response: 'response',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
        }),
    },

    methods: {
        async localIndex () {
            this.localResetVars()
            this.localSetFilterList()
            this.errrorMessage = ''
        },

        async localSetFilterList () {
            this.errrorMessage = ''
            if (this.isAddAssignee || this.isRemoveAssignee) {
                await this.localGetCollaboratorList()
                this.local_load_list = [...this.collaborator_list, ...this.local_load_list]
            } 
            if (this.isAddTag || this.isRemoveTag) {
                await this.localGetUseCaseTags()
                const filteredList = this.local_load_list.filter(item => item.color)
                this.local_load_list = [...this.tag_list, ...filteredList]
            } 
        },

        localResetVars () {
            this.selectedCollabList = []
            this.searchList = []
            this.selectedList = []
            this.searchTimeout = null
            this.local_load_list = []
            this.selectedTagList = []
            this.removeAssigneesAndTags = false
            this.updateConfirm = false
        },

        async localCollabTagSearchFilter (value) {
            if(!_.trim(value)) return
            this.mixinSetLoading('search-data')
            clearTimeout(this.searchTimeout)
            if (this.isAddTag || this.isRemoveTag) await this.localResetTag()
            this.searchTimeout = window.setTimeout(async () => {
                await this.local_load_list_items(value)
            }, 500)
           this.mixinResetLoading('search-data')
        },

         async local_load_list_items(value) {
            this.searchLoading = true
            if (this.isAddAssignee || this.isRemoveAssignee) {
                await this.collaborator_clear()
                await this.localFetchCollabs(value)
                this.local_load_list = [...this.collaborator_list , ...this.local_load_list]
            }
            if (this.isAddTag && !this.isRemoveTag && this.$can('tags.store')) {
                const hasPermission = this.$can('tags.index')
                if (!hasPermission) return this.mixinResetLoading('search-data')
                await this.localFetchTags(value)
                this.local_load_list = [...this.tag_list, ...this.local_load_list]
                this.localSetTagList(hasPermission, value)
                clearTimeout(this.searchTimeout)
                this.mixinResetLoading('search-data')

            }
            if ((this.isRemoveTag || this.isAddTag ) && !this.$can('tags.store') || (this.isRemoveTag && !this.isAddTag && this.$can('tags.store'))) {
                await this.localFetchTags(value)
                this.local_load_list = [...this.tag_list, ...this.local_load_list]
            }
            this.searchLoading = false
        },

        localSetTagList (permission, value) {
            const noData = permission ? { id: 'new', label: value } : null
            let filteredList = this.tag_list.filter(item => {
                let index = this.local_load_list.findIndex(i => i.id === item.id)
                return (index !== -1) || (item.type !== this.tagType) ? item : null
            })
            const tagsList = filteredList.length ? _.cloneDeep(filteredList) : [noData]
            this.local_load_list = [...tagsList, ...this.local_load_list, ...this.tag_list]
        },

        async localResetTag () {
            await this.tag_clear()
            clearTimeout(this.searchTimeout)
        },

        async localFetchCollabs (value) {
            await this.collaborator_index({
                page: 1,
                include: 'user.role,type,contact',
                'filter[project_id]': this.$route.params.id,
                'filter[user]': value ? value.toLowerCase() : '',
            })
        },

        async localFetchTags (value) {
            await this.tag_index({ 
                page: 1,
                'filter[type]': 'usecase_tag', 
                'fields[tags]': 'id,label,color,type',
                'filter[label]':  value ? value.toLowerCase() : '',
            })
        },
        
        async localGetCollaboratorList() {
            await this.collaborator_index({
                include: 'user.role,type,contact',
                count: 5,
                'filter[project_id]': this.$route.params.id,
            }) 
        },

        async localGetUseCaseTags () {
            await this.tag_index({ count: 5, 'filter[type]': 'usecase_tag', 'fields[tags]': 'id,label,color,type' })
        },

        localUpdate () {
            if (this.updateConfirm && this.isAddAssignee) this.$emit('assignCollaborators', this.selectedCollabList, this.localGetAssignedScopes, this.scopeAssigneeIds, this.localGetModule)
            if (this.updateConfirm && this.isRemoveAssignee) this.$emit('removeCollaborators', this.selectedCollabList, this.localGetAssignedScopes, this.scopeAssigneeIds, this.localGetModule)
            if (this.updateConfirm && this.isAddTag) this.$emit('assignTags', this.selectedTagList, this.localGetTagScopes, this.scopeTagIds, this.localGetModule)
            if (this.updateConfirm && this.isRemoveTag) this.$emit('removeTags', this.selectedTagList, this.localGetTagScopes, this.scopeTagIds, this.localGetModule)
            this.updateConfirm = true
        },

        local_remove_all_assignees() {
            this.removeAssigneesAndTags = !this.removeAssigneesAndTags
        },

        localHasPermission (type) {
            if (!Array.isArray(type)) return this.$can(type)

            let resultPermission = false
            for (let index = 0; index < type.length; index++) {
                const slug = type[index]
                resultPermission = this.$can(slug)
            }
            return resultPermission
        },
        localBlurTagField () {
            this.local_searchby_type = ''
            this.mixinResetLoading('search-data')
        },

        async localSetTag (item) {
            if (!item) return
            this.searchLoading = true
            this.mixinSetLoading('search-data')
            this.local_searchby_type = ''

            let tagItem = this.$appendId({ color: this.$randomColor(), label: item.label, type: this.tagType }) 
            await this.tag_store(tagItem)

            const index = this.selectedList.findIndex(item => item.id === 'new')
            this.selectedList.splice(index, 1)
            const listindex = this.local_load_list.findIndex(item => item.id === 'new')
            this.local_load_list.splice(listindex, 1)
            this.local_load_list = [...this.tag_list, ...this.local_load_list]
            this.selectedList.push(this.tag_list.find(item => item.label === tagItem.label))
            this.mixinResetLoading('search-data')
            this.searchLoading = false
        },

        localGetScopeItem (mode) {
            if (!this.isOpen) return
            const filteredScope = this.scope_items.find(item => this[item.slug])
            return filteredScope[mode]
        },

        localSetInput (val) {
            const createNew = this.local_load_list.filter(item => item.id === 'new')
            if (!this.errrorMessage && !this.isAddAssignee && !this.isRemoveAssignee && !this.isRemoveTag && this.$can('tags.store') && createNew.length) this.localSetTag(createNew[0])
        },

        ...mapActions('Collaborator', {
            collaborator_index: 'index',
            collaborator_clear: 'clear',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_clear: 'clear',
            tag_store: 'store',
        }),
    }
}
</script>

<style lang="scss" scoped>
.c-tags-select {
    display: flex;
    align-items: center;
}
</style>

const api_endpoint = '/automation-revision-histories';

export default {
    namespaced: true,

    state: {
        list: [],
        item: {},
        response: {},
        hook_response: {},
        meta: {},
        defaults: {
            list: [],
            item: {},
            response: {},
            hook_response: {},
            meta: {},
        },
    },

    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        ADD_ITEM(state, item) {
            state.list.push(item);
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        UPDATE_ITEM(state, item) {
            state.item = item
        },
        CLEAR_ITEM(state, item) {
            state.item = item
        },
        REMOVE_LIST_ITEM (state, payload) {
            const index = state.list.findIndex(item => item.id === payload.id)
            if (index !== -1) state.list.splice(index, 1)
        },
        REPLACE_ITEM(state, item) {
            state.item = item
        },
        CLEAR_ITEM(state, item) {
            state.item = item
        },
        THROW_RESPONSE (state, payload) {
            state.response = payload
        },
        HOOK_THROW_RESPONSE (state, payload) {
            state.hook_response = payload
        },
        UPDATE_SECTION_FIELD(state, { id, is_active, field }) {
            const index = _.findIndex(state.list, { id });
            if (index !== -1) state.list[index][field] = is_active
        },
    },

    actions: {
        async index ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                const { data: { data }, meta } = await axios.get(api_endpoint, { params: payload })
                commit('UPDATE_LIST', data)
                commit('UPDATE_META', meta)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async store ({ commit, dispatch }, payload) {
            dispatch('loader_start')
            try {
                await axios.post(api_endpoint, payload)
                commit('UPDATE_ITEM', payload)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async throw_success ({ commit }) {
            commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            this.dispatch('Interface/loader_stop')
        },

        async throw_error ({ commit }, error) {
            this.dispatch('Interface/loader_stop')
            commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
        },

        async loader_start () {
            this.dispatch('Interface/loader_start')
        },

        async clear_list ({ commit }) {
            commit('UPDATE_LIST', [])
        },

        async clear_item ({ commit }) {
            commit('CLEAR_ITEM', {})
            commit('THROW_RESPONSE', [])
        },

        async clear_response ({ commit }) {
            commit('THROW_RESPONSE', [])
        },

        async hook_clear ({ commit }) {
            commit('HOOK_THROW_RESPONSE', [])
        }
    },
}

export default {
    methods: {
        local_title_edit_focus ({ id, type, parentId }) {
            const element = document.getElementById(id)
            if (type !== 'parent') {
                const parent = document.getElementById(parentId)
                this.local_set_parent_focus(parent, 'noFocus')
                this.local_set_child_focus(element)
            } else this.local_set_parent_focus(element)
            this.local_on_enter('onClick')
        },

        local_keyboad_navigate_list (event) {
            const { which: code, ctrlKey, shiftKey } = event
            const [firstSlug, lastSlug] = event.code.split('Key')
             const [firstDigitSlug, lastDigitSlug] = event.code.split('Digit')
            //  const [firstArrowSlug, lastArrowSlug] = code.split('Arrow')
             const keys = ['Shift', 'Control', 'Alt', 'Tab', 'CapsLock']
             const testRegex = /[a-zA-Z0-9-_ ]/gi
             if (((!testRegex.test(lastSlug) && !testRegex.test(lastDigitSlug)) || keys.includes(event.key))) {
                 if (!this.currentEditId) {
                     event.preventDefault()
                     event.stopPropagation()
                 }
             }

            if (code === 40) this.local_on_arrow_down() // arrow_down
            if (code === 38) this.local_on_arrow_up() // arrow_up
            if (code === 13 && !ctrlKey && !shiftKey) this.local_on_enter() // enter
            if (code === 27) this.local_on_escape() // escape
            if (code === 191) { // forward slash ("/")
                event.preventDefault()
                event.stopPropagation()
                this.local_focus_usecase_create()
            }
        },

        local_on_arrow_down () {
            if (this.currentEditId) this.currentEditId = null
            if (this.prevNavElement === null) return true

            if (this.parentChildrenCount > 0) {
                if (this.prevNavElement !== 'child') {
                    let currentItem = this.currentParent.querySelector('.c-focusable-child')
                    const isTitleElement = currentItem.classList.contains('c-section-title')
                    currentItem = isTitleElement ? currentItem.nextElementSibling : currentItem
                    return this.local_set_child_focus(currentItem)
                }

                let nextChildCheck = this.currentChild.nextElementSibling
                let nextChild = !nextChildCheck ? this.currentChild.parentElement : nextChildCheck

                if (nextChildCheck) {
                    let isDragElementCheck = (nextChildCheck && nextChild.classList.contains('c-drag-parent')) ?? null
                    nextChild = isDragElementCheck ? nextChild.firstElementChild : nextChild
                } else {
                    let isDragElement = nextChild ? nextChild.classList.contains('c-drag-parent') : null
                    nextChild = isDragElement ? nextChild.nextElementSibling : nextChild
                }

                let isInputField = nextChild ? nextChild.classList.contains('c-add-input') : null
                nextChild = isInputField ? nextChild.nextElementSibling : nextChild

                const isTitleElement = nextChild ? nextChild.classList.contains('c-section-title') : null
                nextChild = isTitleElement ? nextChild.nextElementSibling : nextChild

                let isDragElement = nextChild ? nextChild.classList.contains('c-drag-parent') : null
                nextChild = isDragElement ? nextChild.firstElementChild : nextChild
                if (nextChild) return this.local_set_child_focus(nextChild)

                isInputField = nextChild ? nextChild.classList.contains('c-add-input') : null
                nextChild = isInputField ? nextChild.nextElementSibling : nextChild
                if (nextChild) return this.local_set_child_focus(nextChild)

                const nextParent = this.currentParent.nextElementSibling
                if (nextParent) return this.local_set_parent_focus(nextParent)
            }

            if (this.parentChildrenCount === 0) {
                const nextParent = this.currentParent.nextElementSibling
                if (nextParent) return this.local_set_parent_focus(nextParent)
            }
        },

        local_on_arrow_up () {
            if (this.currentEditId) this.currentEditId = null
            if (this.prevNavElement === null) return true

            if (this.parentChildrenCount > 0) {
                if (this.prevNavElement !== 'child') {
                    const previousParentSibling = this.currentParent.previousElementSibling
                    if (!previousParentSibling) return true

                    this.parentChildrenCount = parseInt(previousParentSibling.dataset.childCount)
                    if (!this.parentChildrenCount) {
                        this.local_set_parent_focus(previousParentSibling, 'noFocus')
                        this.local_set_child_focus(null)
                        return true
                    }

                    const prevParentSiblingLastChild = Array.from(previousParentSibling.getElementsByClassName('c-focusable-child')).slice(-1)[0]
                    this.local_set_parent_focus(previousParentSibling, 'noFocus')
                    this.local_set_child_focus(prevParentSiblingLastChild)
                    return true
                }

                let prevChildSiblingCheck = this.currentChild.previousElementSibling
                let prevChildSibling = !prevChildSiblingCheck ? this.currentChild.parentElement : prevChildSiblingCheck
                if (prevChildSiblingCheck) {
                    let isDragElementCheck = prevChildSiblingCheck && prevChildSibling.classList.contains('c-drag-parent')
                    prevChildSibling = isDragElementCheck ? prevChildSibling.lastElementChild : prevChildSibling
                } else {
                    let isDragElement = prevChildSibling ? prevChildSibling.classList.contains('c-drag-parent') : null
                    prevChildSibling = isDragElement ? prevChildSibling.previousElementSibling : prevChildSibling
                }

                let isInputField = prevChildSibling ? prevChildSibling.classList.contains('c-add-input') : null
                prevChildSibling = isInputField ? prevChildSibling.previousElementSibling : prevChildSibling
                let isChild = prevChildSibling ? prevChildSibling.classList.contains('c-focusable-child') : null
                if (isChild) return this.local_set_child_focus(prevChildSibling)
                let isTitle = prevChildSibling ? prevChildSibling.classList.contains('c-section-title') : null
                if (isTitle) {
                    let prevChildOfTitle = prevChildSibling.previousElementSibling
                    let isInputField = prevChildOfTitle ? prevChildOfTitle.classList.contains('c-add-input') : null
                    prevChildOfTitle = isInputField ? prevChildOfTitle.previousElementSibling : prevChildOfTitle
                    let isDragElement = prevChildOfTitle ? prevChildOfTitle.classList.contains('c-drag-parent') : null
                    prevChildOfTitle = isDragElement ? prevChildOfTitle.lastElementChild : prevChildOfTitle
                    let isChild = prevChildOfTitle ? prevChildOfTitle.classList.contains('c-focusable-child') : null
                    if (isChild) return this.local_set_child_focus(prevChildOfTitle)

                    const childParent = prevChildSibling.parentElement.parentElement
                    return this.local_set_parent_focus(childParent)
                }

                const currentChildParent = this.currentChild.parentElement
                this.local_set_parent_focus(currentChildParent)
                this.local_set_child_focus(null)
                return true
            }

            if (this.parentChildrenCount === 0) {
                const prevParent = this.currentParent.previousElementSibling
                if (!prevParent) return true

                this.parentChildrenCount = parseInt(prevParent.dataset.childCount)
                if (!this.parentChildrenCount) return this.local_set_parent_focus(prevParent)

                const parentLastChild = Array.from(prevParent.getElementsByClassName('c-focusable-child')).slice(-1)[0]
                this.local_set_parent_focus(prevParent, 'noFocus')
                this.local_set_child_focus(parentLastChild)
                return true
            }
        },

        local_on_enter (type = null) {
            if (this.currentEditId && !type) return this.currentEditId = null
            this.currentEditId = this.focusedElementId
            if (this.prevNavElement !== 'child') {
                const currentParent = this.currentParent
                if (currentParent) {
                    setTimeout(() => {
                        const input = currentParent.querySelector('.c-focusable-parent-input')
                        input.focus()
                    }, 200)
                }
                return true
            }

            const currentChild = this.currentChild
            if (currentChild) {
                setTimeout(() => {
                    const input = currentChild.querySelector('.c-focusable-child-input')
                    input.focus()
                }, 200)
            }
        },

        local_on_escape () {
            this.currentEditId = null
            this.focusedElementId = null
        },

        local_focus_usecase_create () {
            this.$refs.refUsecaseCreateInput.focus()
        },

        local_set_child_focus (child, noFocus) {
            this.currentChild = child
            this.currentChildId = (child && child.id) ?? null
            if (child && !noFocus) {
                this.prevNavElement = 'child'
                this.focusedElementId = this.currentChildId
                // this.currentEditId = this.focusedElementId
                setTimeout(() => {
                    const viewPointElement = document.getElementById('view-point-' + child.id)
                    viewPointElement.scrollIntoView()
                }, 200)
            }
        },

        local_set_parent_focus (parent, noFocus) {
            this.currentParent = parent
            this.currentParentId = parent.id ?? null

            if (!this.expanded_ids.includes(parent.id)) this.local_toggle_expand({ id: parent.id, is_expanded: false })
            if (parent && !noFocus) {
                this.prevNavElement = 'parent'
                this.focusedElementId = this.currentParentId
                // this.currentEditId = this.focusedElementId
                this.parentChildrenCount = parseInt(parent.dataset.childCount)
                setTimeout(() => {
                    const viewPointElement = document.getElementById('view-point-' + parent.id)
                    viewPointElement.scrollIntoView()
                }, 200)
            }
        },
    }
}

<template>
    <a-responsive class="grey darken-3">
        <a-container grid-list-xl container--fluid class="pa-6 my-3">
            <a-layout align-center>
                <a-flex shrink>
                    <div class="u-elevation-custom-1 white" style="display: flex; align-items: center; justify-content: center; width: 104px; height: 104px; border-radius: 1.618rem;">
                        <!-- <loader-template width="64" height="64" class="u-rounded-corners-xl" v-if="localIsLoading"></loader-template> -->
                        <!-- <template v-else> -->
                            <img src="../../../assets/images/apps/gong/logo.svg" alt="Gong" style="display: block; width: 48px; height: 48px;">
                        <!-- </template> -->
                    </div>
                </a-flex>
                <a-flex>
                    <!-- <div v-if="localIsLoading">
                        <loader-template height="24" width="300" class="u-rounded-corners-full"></loader-template>
                        <loader-template height="16" width="600" class="u-rounded-corners-full mt-4"></loader-template>
                    </div>
                    <template v-else> -->
                        <div class="u-flex-center-y">
                            <h2 class="md-heading-4 white--text">Gong</h2>
                            <GBetaFlag />
                        </div>
                        <p class="md-subtitle-1 grey--text text--lighten-1 mb-0">Add Gong integration to sync all your meetings.</p>
                        <!-- <p class="md-body-2 grey--text mt-1 mb-0" v-if="installation.installer">Installed by {{ installation.installer.name }} on <g-moment :value="installation.created_at" input-format="YYYY-MM-DD" output-format="MMMM D, YYYY"></g-moment></p> -->
                    <!-- </template> -->
                </a-flex>
            </a-layout>
        </a-container>
    </a-responsive>
</template>

<script>
export default {
    props: {
        installation: {
            type: Object,
            default: {},
            required: true,
        }
    }
}
</script>

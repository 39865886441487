<template>
    <div class="c-donut-chart u-relative white">
        <div class="c-donut-chart__column u-relative" style="z-index: 2">
            <p-faux-loader :is_loading="tile_faux_loader && tile_faux_loader.includes(item.id)" img_alt="Loading Donut Chart.."></p-faux-loader>
            <p-tile-status
                :is_loading="item && (loading_statuses.includes(item.status) || chart_loading)"
                :has_error="item && item.status === 'error'"
                :has_stats="item && item.statistics && item.statistics.data  && item.statistics.data.length"
                @configure="$emit('configure', true)">
                <template #loader>
                    <div class="u-wfull u-hfull u-absolute text-center transparent u-opacity-40">
                        <a-img :src="require('../../../assets/images/loader.svg')" alt="Loading Donut Chart.." width="48" height="48" contain class="d-inline-block mt-16"></a-img>
                    </div>
                </template>
            </p-tile-status>
            <VueApexCharts
                ref="donutChart"
                width="100%"
                height="100%"
                :options="chart_options"
                :series="series"></VueApexCharts>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import VueApexCharts from "vue-apexcharts"
import PTileStatus from './PartialTileStatus.vue'
import PFauxLoader from './PartialFauxLoader.vue'
import { formatCurrency } from '@/helpers/helper-currency.js'
import { formatTileOptions } from "../Helpers/helper-format-tile-options";

export default {
    name: "DonutChart",

    components: { VueApexCharts, PTileStatus, PFauxLoader },

    props: {
        item: {
            type: Object,
            default: {}
        },
        isRearrange: {
            type: Boolean,
            default: false
        },
    },

    data () {
        return {
            loading_statuses: ['loading', 'update-loading', 'new-loading', 'force-loading'],
            local_chart: {},
            stats_interval: null,
            stats_interval_countdown: 0,
            local_tile_options: [],
            stats: [],
            stats_meta: {},
            chart_loading: true,
            series: [],
            local_default_colors: ["#9C27B0", "#E91E63", "#FF7043", "#42A5F5", "#78909C", '#795548', '#CFD8DC'],
            local_default_health_colors: {
                red: '#F44336',
                yellow: '#FFC107',
                green: '#4CAF50',
            },
            local_health_colors: [],
            chart_options: {
                chart: {
                    type: 'donut',
                    toolbar: {
                        show: false,
                    },
                    offsetY: -3,
                },
                grid: {
                    padding: {
                        bottom: 0,
                        right: 0,
                        top: 6,
                        left: 0,
                    }
                },
                states: {
                    active: {
                        filter: {
                            type: "none",
                        },
                    },
                },
                tooltip: {
                    y: {
                        formatter: (value) => {
                            return value.toFixed()
                        },
                    },
                },
                selection: {
                    enabled: false,
                },
                labels: [],
                stroke: {
                    width: 0,
                },
                dataLabels: {
                    style: {
                        fontFamily: "Inter, sans-serif",
                        fontSize: "10px",
                        fontWeight: "700",
                    },
                    // formatter: function (val, index) {
                    //     console.log(val);
                    //     return `${val}`;
                    // },
                    dropShadow: {
                        enabled: false,
                    },
                },
                legend: {
                    show: false,
                },
                colors: ["#9C27B0", "#E91E63", "#FF7043", "#42A5F5", "78909C"],
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontFamily: "Inter, sans-serif",
                                    color: "#505d6f",
                                    offsetY: -10,
                                    formatter: function () {
                                        return 'Total';
                                    },
                                },
                                value: {
                                    show: true,
                                    fontFamily: "Inter, sans-serif",
                                    offsetY: 0,
                                    fontSize: "18px",
                                    formatter: function () {
                                        return "Total Projects";
                                    },
                                },
                                total: {
                                    show: true,
                                    fontSize: "14px",
                                    color: "#9aa1a9",
                                    showAlways: true,
                                    formatter: function (w) {
                                        const total = w.globals.seriesTotals.reduce((a, b) => {
                                            return a + b
                                        }, 0)

                                        return total;
                                    },
                                },
                            },
                        },
                    },
                },
            },
        };
    },

    watch: {
        item: {
            handler (val) {
                this.local_watch_index()
            },
            deep: true
        },

        isRearrange (val) {
            this.local_set_animation(!val)
        }
    },

    async mounted () {
        if (this.item) {
            await this.local_get_stats()
            await this.local_index()
        }
    },

    beforeDestroy () {
        clearInterval(this.stats_interval)
    },

    computed: {
        ...mapState('Tile', {
            tile_response: 'response',
            tile_faux_loader: 'faux_loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index () {
            this.chart_loading = this.isRearrange ? false : true
            this.local_chart = _.cloneDeep(this.item)
            this.stats_interval_countdown = 0
            this.stats = []

            if (this.item && this.item.status === 'loaded') {
                this.stats = (this.item.statistics && this.item.statistics.data) || []
                this.local_clear_loading()
            }
            else {
                this.local_watch_index()
            }
        },

        async local_watch_index () {
            this.chart_loading = this.isRearrange ? false : true
            if (this.item && this.item.status !== 'loaded') {
                clearInterval(this.stats_interval)
                await this.local_get_stats()
                this.stats_interval = setInterval(async () => await this.local_get_stats_with_interval(), 3000)
                if (this.item && (this.item.status === 'error' || this.item.status === 'loaded')) this.local_set_error_state()
            }
        },

        local_clear_loading () {
            setTimeout(() => {
                this.chart_loading = false
            }, 1000)
        },

        async local_set_chart () {
            this.local_get_current_options()
            await this.local_set_values()
            await this.local_set_labels()

            this.local_get_colors()
            this.local_set_colors()
        },

        async local_update_chart() {
            if (this.$refs && this.$refs.donutChart) this.$refs.donutChart.updateOptions({ ...this.chart_options });
        },

        async local_get_stats () {
            this.local_chart = _.cloneDeep(this.item)
            const params = this.item.status === 'force-loading' ? {force: true} : null
            await this.tile_show_stats({ id: this.local_chart.id, params: params })
            this.local_chart = _.cloneDeep(this.item)

            this.stats = (this.item.statistics ? this.item.statistics.data : []) || []
            this.stats_meta = (this.item.statistics ? this.item.statistics.meta : []) || []
            await this.local_set_chart()
            await this.local_update_chart()
            clearInterval(this.stats_interval)
        },

        async local_get_stats_with_interval () {
            this.stats_interval_countdown = this.stats_interval_countdown + 1
            if ((this.item && this.item.status === 'error') || this.stats_interval_countdown >= 10) return this.local_set_error_state()
            if (this.item && (this.item.status === 'update-loading' || this.item.status === 'force-loading')) await this.local_get_stats()

            await this.local_fetch_tile_item()
            if (this.item && this.item.status === 'loaded') {
                this.stats_interval_countdown = 0
                this.tile_faux_remove_item(this.item.id)
                await this.local_get_stats()
                this.local_clear_loading()
                return clearInterval(this.stats_interval)
            }
            if ((this.item && this.item.status === 'error') || this.stats_interval_countdown >= 10) return this.local_set_error_state()
        },

        local_set_error_state () {
            clearInterval(this.stats_interval)
            this.tile_faux_remove_item(this.item.id)
            this.tile_update_tile_status({ id: this.local_chart.id, status: 'error' })
            this.stats_interval_countdown = 0
            this.local_clear_loading()
        },

        async local_set_values () {
            const labels = []
            const series = []

            for (let index = 0; index < this.stats.length; index++) {
                const item = this.stats[index]
                labels.push(item.key ? (item.key.charAt(0).toUpperCase() + item.key.slice(1)) : 'Archived')
                series.push(parseInt(item.value.toFixed()))
            }

            this.series = series
            this.chart_options.labels = labels;
        },

        async local_set_labels () {
            const currencyValues = this.user_self.organization.currency
            const labels = this.chart_options.plotOptions.pie.donut.labels
            const tooltip = this.chart_options.tooltip
            const stats_meta = _.cloneDeep(this.stats_meta)

            if(this.local_tile_options.value.isCurrency) {
                labels.total.formatter = function (value) {
                    let total = ''

                    if(!_.isEmpty(stats_meta)) {
                        total = stats_meta.total_projects
                    } else {
                        total = value.globals.seriesTotals.reduce((a, b) => {
                            return a + b
                        }, 0);
                    }

                    let formattedValue = formatCurrency({value: total, currencyValues});
                    return formattedValue;
                }

                labels.name.formatter = () => { return this.local_tile_options.value.label }

                tooltip.custom = function ({ series, seriesIndex, dataPointIndex, w }) {
                    let total = 0;

                    for (let x of series) {
                        total += x;
                    }
                    let selected = series[seriesIndex]
                    let formattedValue = formatCurrency({value: series[seriesIndex], currencyValues})

                    return `<div style="padding: 4px 8px; background-color: ${w.config.colors[seriesIndex]}; font-size: 12px;">${w.config.labels[seriesIndex]} : <strong>${formattedValue}</strong> (${(selected / total * 100).toFixed(1)}%)</div>`;
                }
            }
            else {
                if(!_.isEmpty(stats_meta)) {
                    labels.total.formatter = () => { return stats_meta.total_projects }
                } else {
                    labels.total.formatter = (value) => value.globals.seriesTotals.reduce((a, b) => { return a + b }, 0);
                }

                labels.name.formatter = () => { return this.local_tile_options.value.label }

                tooltip.custom = function ({ series, seriesIndex, dataPointIndex, w }) {
                    let total = 0;

                    for (let x of series) {
                        total += x;
                    }
                    let selected = series[seriesIndex];

                    return `<div style="padding: 4px 8px; background-color: ${w.config.colors[seriesIndex]}; font-size: 12px;">${w.config.labels[seriesIndex]} : <strong>${selected.toFixed()}</strong> (${(selected / total * 100).toFixed(1)}%)</div>`;
                }
            }
        },

        local_get_colors () {
            switch (this.local_tile_options.category.key) {
                case 'health' :
                    const health_colors = []

                    if(this.stats) {
                        for (const stat of this.stats) {
                            const color = this.local_default_health_colors[stat.key] ? this.local_default_health_colors[stat.key] : ''
                            health_colors.push(color)
                        }
                    }

                    this.local_health_colors = health_colors
                    break
            }
        },

        local_set_colors () {
            switch (this.local_tile_options.category.key) {
                case 'health' :
                    if(this.local_health_colors.length) {
                        this.chart_options.colors = [...this.local_health_colors]
                    } else {
                        this.chart_options.colors = [...this.local_default_colors]
                    }
                    break
                case 'stage' :
                    this.chart_options.colors = [...this.local_default_colors]
                    break
                default :
                    this.chart_options.colors = [...this.local_default_colors]
                    break
            }
        },


        async local_fetch_tile_item () {
            await this.tile_show({ id: this.local_chart.id, mode: 'show-modify', params: { include: 'tile_options,tile_filters,source' } })
        },

        local_set_animation (value) {
            if (!this.$refs || !this.$refs.donutChart) return
            this.$refs.donutChart.updateOptions({
                chart: {
                    animations: {
                        enabled: value,
                    }
                }
            })
        },

        local_get_current_options () {
            this.local_tile_options = formatTileOptions(this.local_chart.tile_options)
        },

        ...mapActions('Tile', {
            tile_show: 'show',
            tile_show_stats: 'show_stats',
            tile_update_tile_status: 'update_tile_status',
            tile_faux_remove_item: 'remove_faux_item',
        }),
    }
};
</script>

<style scoped>
.c-donut-chart {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 227px;
}

.c-donut-chart__column {
    width: 211px;
    height: 211px;
}
</style>

<template>
	<div>
        <div class="mb-4">
            <h3 class="text-h6 grey--text text--darken-3 font-weight-medium">Reports</h3>
        </div>

        <a-container container--fluid grid-list-xl class="pa-0">
            <!-- Loading text -->
            <a-container key="loader" v-if="localLoading">
                <a-layout>
                    <a-flex xs12 py-0>
                        <h2 key="loader" class="mt-4 text-center title grey--text text--darken-2 font-italic">Fetching Document Templates..</h2>
                    </a-flex>
                </a-layout>
            </a-container>

            <template v-else>
                <!-- No Data Found Message -->
                <a-container key="noData" v-if="getTemplateList && !getTemplateList.length">
                    <a-layout>
                        <a-flex xs12 py-0 class="text-center " v-if="getTemplateList && !getTemplateList.length">
                            <h2 class="mt-4 text-center title grey--text text--darken-2 font-italic">No Document Templates Found</h2>
                            <a-btn v-if="methodCheckDocGeneratePermission('store')" small text @click="methodTemplateCreate" class="mt-3 ma-0 c-radius blue darken-2 white--text">
                                <a-icon class="mr-2 white--text">add</a-icon>
                                Create New Template
                            </a-btn>
                        </a-flex>
                    </a-layout>
                </a-container>

                <!-- Templare List -->
                <template v-else>
                    <a-layout key="templateList">
                        <a-flex xs12>
                            <a-card flat class="mb-2 u-border u-rounded-corners pa-2" v-for="template in getTemplateList" :key="template.id">
                                <a-card-text class="pa-0 elevation-0 u-relative">
                                    <a-container container--fluid grid-list-xl pa-4>
                                        <a-layout wrap align-center>
                                            <a-flex class="u-cursor-pointer" :class="[methodCheckDocGeneratePermission('download') ? 'xs9' : 'xs12']" py-1 @click="methodCheckDocGeneratePermission('index') && $can('projects.update-only') ? $router.push({ name: 'settings-document-generator-view', params: { template_id: template.id } }) : ''">
                                                <h2 class="md-heading-6 font-weight-medium" style="color: #505050">{{ template.name }}</h2>
                                            </a-flex>
                                            <a-spacer></a-spacer>
                                            <a-flex xs3 py-1 class="text-right" v-if="methodCheckDocGeneratePermission('download') && $can('projects.download')">
                                                <a-menu offset-y bottom max-width="350" transition="slide-y-transition" content-class="u-elevation-custom-1 c-radius u-border">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on" class="d-inline-flex align-center grey lighten-4 u-rounded-corners" style="border: 1px solid #dcdcdc !important;">
                                                            <a-btn small text max-width="200" class="ma-0 u-rounded-corners font-weight-normal grey--text text--darken-1 body-2 text-capitalize">
                                                                Generate Document
                                                                <a-icon size="18" color="grey darken-1" class="ml-2">arrow_drop_down</a-icon>
                                                            </a-btn>
                                                        </div>
                                                    </template>
                                                    <a-list class="py-2">
                                                        <a-list-item class="px-3 u-wfull" @click="methodExportPoc(template.id, 'excel')">
                                                            <a-list-item-action class="mr-2" style="min-width: 22px !important;">
                                                                <a-img :src="require('../../assets/images/word-icon.svg')" contain max-width="22" max-height="22"></a-img>
                                                            </a-list-item-action>
                                                            <a-list-item-content>
                                                                <a-list-item-title class="grey--text text--darken-2">Microsoft Word (.docx)</a-list-item-title>
                                                            </a-list-item-content>
                                                        </a-list-item>
                                                        <!-- <a-list-item class="px-0 u-wfull" @click="methodExportPoc(template.id, 'pdf')">
                                                            <a-list-item-action class="mr-2" style="min-width: 22px !important;">
                                                                <a-img src="/images/pdf-icon.svg" contain max-width="22" max-height="22"></a-img>
                                                            </a-list-item-action>
                                                            <a-list-item-content>
                                                                <a-list-item-title class="grey--text text--darken-2">Portable Document Format (.pdf)</a-list-item-title>
                                                            </a-list-item-content>
                                                        </a-list-item> -->
                                                    </a-list>
                                                </a-menu>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-card-text>
                            </a-card>
                        </a-flex>
                    </a-layout>
                </template>
            </template>
        </a-container>

        <!-- Download Report Dialog -->
        <a-dialog max-width="480px" v-model="dataDialogDownloadLink" persistent>
            <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden" flat>
                <a-progress-linear class="my-0" height="3" color="orange darken-3" :indeterminate="true" v-if="loading"></a-progress-linear>
                <a-card-text class="pa-2">
                    <a-container container--fluid grid-list-xl class="pa-4">
                        <a-layout align-start>
                            <a-flex shrink>
                                <a-icon size="72" color="grey lighten-1">cloud_download</a-icon>
                            </a-flex>
                            <a-flex>
                                <h2 class="mb-2 md-heading-6">
                                    <span v-if="loading">Preparing file...</span>
                                    <span v-else>Ready to Download</span>
                                </h2>
                                <p class="mb-0 md-body-2 grey--text text--darken-1">
                                    <span v-if="loading">Success is preparing your report for download. It may take few seconds to get ready.</span>
                                    <span v-else>Your report is ready to download. Click on the "Save File" button to download the report.</span>
                                </p>
                                <a-btn :href="getDownloadLink" target="_blank" small class="px-3 mt-3 elevation-0 ma-0" color="primary" :disabled="loading" @click="local_download_save()">
                                    <a-icon size="14" class="mr-2">save</a-icon>
                                    Save File
                                </a-btn>
                                <a-btn class="px-3 mt-3 ml-2 elevation-0 ma-0" color="grey" small text @click="dataDialogDownloadLink = !dataDialogDownloadLink" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!-- Create Template Dialog -->
        <a-dialog v-model="dataCreateTemplateDialog" max-width="500" persistent>
            <a-card class="c-radius grey lighten-3">
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-4">
                            <a-layout wrap>
                                <a-flex xs12>
                                    <div class="u-relative">
                                        <a-sheet class="justify-center mx-auto grey lighten-4 u-flex align-center" width="250" height="250" style="border-radius: 50%;">
                                            <a-img :src="require('../../assets/images/report-template-list.svg')" max-width="200" max-height="200" contain></a-img>
                                        </a-sheet>
                                        <a-btn icon text small style="position: absolute; top: -12px; right: -12px" @click="dataCreateTemplateDialog = !dataCreateTemplateDialog">
                                            <a-icon size="18" color="grey lighten-1">cancel</a-icon>
                                        </a-btn>
                                    </div>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="mt-0 mb-2 mb-3 text-center title d-block grey--text text--darken-2 u-wfull font-weight-medium">Create New Template</label>
                                    <a-sheet class="mb-3 c-radius u-elevation-custom-1" style="padding: 1px;">
                                        <a-text-field
                                            v-model="dataEditTemplate.name"
                                            placeholder="Eg: Progress Report"
                                            ref="refCreateTemplateForm"
                                            background-color="white"
                                            solo flat hide-details
                                        >
                                        </a-text-field>
                                    </a-sheet>
                                    <a-btn color="primary" class="px-3 py-3 ma-0 u-wfull u-elevation-custom-1 c-radius body-2 font-weight-normal text-capitalize" @click="methodTemplateSave()">Create Template</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-snackbar color="red darken-2" auto-height v-model="snackbarStatus" :timeout="snackbarTimeout" bottom center>
            <span class="body-2 white--text">{{ snackbarText }}</span>
            <a-btn color="grey lighten-3"  text @click="snackbarStatus = false">Close</a-btn>
        </a-snackbar>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import TemplateImportStatus from '@/mixins/mixin-template-import-status'

export default {
    mixins: [TemplateImportStatus],

    data () {
        return {
            dataDialogDownloadLink: false,
            dataCreateTemplateDialog: false,
            dataEditTemplate: { name: null },
            snackbarStatus: false,
            snackbarTimeout: 4000,
            snackbarText: '',
            localLoading: true
        }
    },

    mounted () {
        this.local_index()
    },

    watch: {
        dataCreateTemplateDialog (val) {
            if (val) {
                setTimeout(() => this.$refs.refCreateTemplateForm.ref.focus(), 500)
            }
        },
    },

    computed: {
        breadcrumb_items () {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Report Templates', to: '/document-generator', exact: true})
            return breadcrumbs
        },

        ...mapGetters('ReportTemplate', [
            'getTemplateList',
            'getDownloadLink',
            'getTemplateResponse',
            'getTemplateItem'
        ]),

        ...mapGetters('User', {
            user_self: 'self',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_index () {
            if (!this.$can('document_generator.index')) {
                return this.$router.push({ name: 'dashboard' })
            }

            this.$vuetify.goTo(0)
            await this.actionTemplateClear()
            await this.actionPOCDocTemplateIndex({
                'fields[report_templates]': 'id,name,is_active',
                'filter[is_active]': 1
            })
            this.localLoading = false
        },

        async methodExportPoc (template_id, value) {
            this.dataDialogDownloadLink = true
            await this.actionTemplateLinkClear()
            await this.actionReportGenerate({ template_id, project_id: this.$route.params.id })
            if (this.getTemplateResponse.status !== 'success') {
                this.snackbarStatus = true
                this.snackbarText = 'Document download has failed. Please try again.'
            }
        },

        async methodTemplateCreate () {
            this.actionTemplateClearItem()
            this.dataEditTemplate.name = null
            this.dataCreateTemplateDialog = true
        },

        async methodTemplateSave () {
            if (!this.dataEditTemplate.name || this.dataEditTemplate.name === '') return
            await this.actionTemplateStore({ name: this.dataEditTemplate.name })
            if (this.getTemplateResponse.status === 'success') {
                await this.methodViewTemplate(this.getTemplateItem.id)
                this.dataCreateTemplateDialog = false
            } else {
                this.snackbarStatus = true
                this.snackbarText = this.getTemplateResponse.text
            }
        },

        methodCheckDocGeneratePermission (type) {
            return this.$can(`document_generator.${type}`) && this.user_self.role.scope === 'internal'
        },

        methodGetCalendarTime (date) {
            return moment.utc(date).local().calendar()
        },

        async methodViewTemplate (id) {
            this.$router.push({ name: 'settings-document-generator-view', params: { template_id: id } })
        },

        local_download_save () {
            this.dataDialogDownloadLink = false
        },

        ...mapActions('ReportTemplate', [
            'actionPOCDocTemplateIndex',
            'actionTemplateStore',
            'actionTemplateLinkClear',
            'actionTemplateClear',
            'actionReportGenerate',
            'actionTemplateClearItem'
        ])
    },
}
</script>

<template>
    <div class="u-full mt-6" v-if="pageLoading">
        <loader-template height="53" width="100%" class="u-rounded-corners-lg"></loader-template>
    </div>
    <div v-else class="u-full mt-6">
        <div v-if="item && list && list.length" class="px-5 py-4 u-rounded-corners-lg mt-6" :class="[!item.is_active ? 'orange lighten-4' : 'green lighten-4']">
            <div class="u-flex-center-y" v-if="(isLoading && !item.is_active) || !item.is_active">
                <a-icon color="brown darken-2" size="20">info</a-icon>
                <h3 class="md-subtitle-1 font-weight-medium brown--text text--darken-2 ml-2">
                    This automation is currently inactive.
                </h3>
            </div>
            <div class="u-flex-center-y" v-else>
                <a-icon color="green darken-1" size="20">check_circle</a-icon>
                <h3 class="md-subtitle-1 font-weight-medium ml-2 green--text text--darken-3">
                    This automation is currently active.
                </h3>
            </div>
        </div>

        <div v-if="!isLoading && canUpdate && item && list && list.length === 0" class="px-5 u-flex u-cursor-pointer py-4 u-rounded-corners-lg mt-6 orange lighten-4" @click="$emit('update-config')">
            <div class="u-flex u-wfull">
                <a-icon color="brown" size="20">settings_suggest</a-icon>
                <h3 class="md-subtitle-1 font-weight-medium brown--text ml-2">
                    Action not configured. One or more action should be defined to activate this automation.                
                </h3>
                <a-spacer></a-spacer>
                <h3 class="md-subtitle-1 u-font-semibold brown--text ml-2 text-right">Configure now 
                    <a-icon size="16" color="brown" class="u-icon-nudge-xs">arrow_forward</a-icon>
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
        },
        list: {
            type: Array,
            default: () => []
        },
        isLoading:{
            type: Boolean,
            default: true
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
        pageLoading:{
            type: Boolean,
            default: true
        },
    },
}
</script>

<style scoped>
.c-warning-info{
    width: 44px !important;
    height: 44px !important;
}
</style>
<template>
    <div>
        <a-menu
            v-model="modelNotifications"
            :disabled="disabled"
            :close-on-content-click="false"
            content-class="u-rounded-corners-lg"
            min-width="550"
            max-width="550"
            bottom offset-y left
        >
            <template v-slot:activator="{ on }">
                <div v-on="on" class="u-cursor-pointer u-rounded-corners-lg" v-ripple="true">
                    <slot :has-unread-count="hasUnreadCount"></slot>
                </div>
            </template>
            <div class="bg-gray c-notification-popover">
                <!-- Header -->
                <div
                    class="pa-5 u-flex-center-y white mb-1"
                    style="box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.03) !important;"
                >
                    <h2 class="u-font-20 u-tracking u-font-weight-semibold grey--text text--darken-4">Notifications</h2>
                    <a-spacer></a-spacer>
                    <div class="u-flex-center-y" style="column-gap: 20px;">
                        <g-toggle
                            v-model="showOnlyUnread"
                            :color="showOnlyUnread ? 'green darken-2' : 'grey'"
                            @change="localToggleUnread"
                            class="d-inline-flex align-center"
                            custom-bg dense-xl
                        >
                            <template #prepend-title>
                                <span :class="['grey--text text--darken-1 font-weight-medium u-font-14 u-tracking mr-2']">Only unread</span>
                            </template>
                        </g-toggle>
                        <a-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a-btn
                                    v-on="on"
                                    :disabled="hasUnreadCount === 0"
                                    @click="localReadAll()"
                                    class="transparent"
                                    icon small
                                >
                                    <a-icon :color="hasUnreadCount === 0 ? 'grey' : 'blue'" size="20">done_all</a-icon>
                                </a-btn>
                            </template>
                            <span>Mark all as read</span>
                        </a-tooltip>
                        <a-btn icon small class="transparent" @click.stop.prevent="dialogSettings = true">
                            <a-icon color="grey" size="20" outlined>settings</a-icon>
                        </a-btn>
                    </div>
                </div>

                <div
                    :class="[!localNotificationsLength && !localLoading ? 'u-flex-center' : 'u-flex flex-column']"
                    ref="refNotificationArea"
                    class="u-overflow-y"
                    style="max-height: 600px; min-height: 600px;"
                >
                    <template v-if="localLoading">
                        <template v-for="i in 5">
                            <a-divider v-if="i !== 1" :key="i + '-top-divider'" class="grey lighten-3"></a-divider>
                            <loader-user-notifications :key="i" />
                            <a-divider :key="i + '-bottom-divider'" class="grey lighten-3"></a-divider>
                        </template>
                    </template>

                    <template v-else>
                        <template v-if="!localNotificationsLength && !showOnlyUnread">
                            <div class="text-center" style="margin-bottom: 78px;">
                                <img
                                    src="../../assets/images/no-notifications.svg"
                                    alt="No notifications"
                                    style="width: 200px;"
                                >
                                <h3 class="primary--text u-font-20 font-weight-medium text-center u-leading-28 u-tracking mt-5">
                                    Breathe in, breathe out...
                                </h3>
                                <h4 class="grey--text u-font-16 font-weight-medium text-center u-leading-28 u-tracking">
                                    New notifications will appear here
                                </h4>
                            </div>
                        </template>

                        <template v-else>
                            <template v-if="!localNotificationsLength && showOnlyUnread">
                                <div class="text-center" style="margin-bottom: 78px;">
                                    <img
                                        src="../../assets/images/notification-all-read.svg"
                                        alt="No unread notifications"
                                        style="width: 200px;"
                                    >
                                    <h3 class="primary--text u-font-20 font-weight-medium text-center u-leading-28 u-tracking mt-3">
                                        Woohoo!
                                    </h3>
                                    <h4 class="grey--text u-font-16 font-weight-medium text-center u-leading-28 u-tracking">
                                        You have read all your notifications!
                                    </h4>
                                </div>
                            </template>
                            <template v-else>
                                <div v-for="(dayNotifications, dayIndex) in localNotifications" :key="dayIndex">
                                    <h3
                                        class="u-font-12 u-tracking px-5 py-3 bg-gray grey--text text-uppercase u-sticky"
                                        style="top: 0px; left: 0px; z-index: 1; border-bottom: 1px solid #eee !important;"
                                    >
                                        {{ localGetDayOrDate(dayIndex) || 'Today' }}
                                    </h3>
                                    <div>
                                        <template v-for="(notification, index) in dayNotifications">
                                            <a-divider v-if="index !== 0" class="grey lighten-3" :key="notification.id + '-divider'"></a-divider>
                                            <div
                                                :class="[!notification.read_at ? 'white' : 'transparent']"
                                                :key="notification.id"
                                                @click="localGoTo(notification)"
                                                class="px-5 py-3 u-cursor-pointer"
                                            >
                                                <notifier-project-stage-update
                                                    v-if="notification.type === 'project-stage-update'"
                                                    :notification="notification"
                                                    @read="localUpdateReadInfo(notification.id, $event)"
                                                />
                                                <notifier-project-status-update
                                                    v-if="notification.type === 'project-status-update'"
                                                    :notification="notification"
                                                    @read="localUpdateReadInfo(notification.id, $event)"
                                                />
                                            </div>
                                        </template>
                                        <a-divider v-if="localNotificationsLength" class="grey lighten-3"></a-divider>
                                    </div>
                                </div>

                                <div v-if="paginationLoading">
                                    <template v-for="i in 4">
                                        <loader-user-notifications :key="i + '-loader'" />
                                        <a-divider :key="i + '-divider'" class="grey lighten-3"></a-divider>
                                    </template>
                                </div>

                                <template v-if="!paginationLoading && localIsLastPage">
                                    <a-spacer></a-spacer>
                                    <a-sheet
                                        class="u-wfull u-rounded-corners-lg text-center py-5 mx-auto"
                                        style="
                                            background: linear-gradient(180deg, #FFFCF5 0%, #FFF 100%);
                                            margin-top: 100px;
                                            margin-bottom: 32px;
                                            width: calc(100% - 64px);
                                        "
                                    >
                                        <img
                                            src="../../assets/images/bell-end-notification.svg"
                                            alt="Reached the end of notification stack"
                                            style="width: 44px;"
                                        >
                                        <h3 class="primary--text u-font-14 font-weight-medium text-center u-leading-20 u-tracking">
                                            Woohoo, you have reached the end!
                                        </h3>
                                        <h4 class="grey--text u-font-14 font-weight-medium text-center u-leading-20 u-tracking mt-1">
                                            That's all your notifications from the last 30 days.
                                        </h4>
                                    </a-sheet>
                                </template>
                            </template>
                        </template>
                    </template>
                </div>
            </div>

            <!-- Settings Dialog -->
            <a-dialog
                v-model="dialogSettings"
                attach=".c-notification-popover"
                content-class="u-rounded-corners-xl"
                width="450"
                hide-overlay
            >
                <a-card class="bg-gray u-relative" width="100%">
                    <!-- Header -->
                    <div
                        class="pa-5 u-flex-center-y white"
                        style="box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08) !important;"
                    >
                        <h2 class="u-font-20 u-tracking u-font-weight-semibold grey--text text--darken-4">Settings</h2>
                        <a-spacer></a-spacer>
                        <a-btn icon small class="transparent" @click="dialogSettings = false">
                            <a-icon color="grey" size="20">highlight_off</a-icon>
                        </a-btn>
                    </div>

                    <template v-if="!hasPermission">
                        <div class="u-flex-center u-absolute u-wfull" style="margin-bottom: 78px; z-index: 2; height: calc(100% - 78px)">
                            <div class="text-center">
                                <img
                                    src="../../assets/images/enable-push-notification.svg"
                                    alt="No notifications"
                                    style="width: 300px;"
                                >
                                <h3 class="grey--text text--darken-3 u-font-16 u-font-weight-semibold text-center u-leading-20 u-tracking mt-2">
                                    Push notifications are disabled for Success App
                                </h3>
                                <h4 class="grey--text text--darken-1 u-font-14 font-weight-medium text-center u-leading-20 u-tracking mt-2">
                                    To enable, Click on the <strong class="grey--text text--darken-2">Lock</strong> icon in your browser’s <br>
                                    address bar and click “<strong class="grey--text text--darken-2">Allow</strong>” notifications.
                                </h4>
                            </div>
                        </div>
                    </template>

                    <div :class="['pa-5 u-relative', { 'u-opacity-60 u-no-select': !hasPermission }]">
                        <div
                            v-if="!hasPermission"
                            :class="['white u-no-select u-absolute']"
                            style="top: 0px; left: 0px; bottom: 0px; right: 0px; z-index: 1"
                        />

                        <!-- Enable/Disable Push Notification -->
                        <div class="u-flex align-start">
                            <div>
                                <h3 class="u-font-14 u-font-weight-semibold u-tracking u-leading-20 grey--text text--darken-3">
                                    Push Notifications
                                </h3>
                                <span class="u-font-12 font-weight-medium u-tracking u-leading-16 grey--text text--darken-1">
                                    If enabled, Success sends you desktop notifications
                                </span>
                            </div>
                            <a-spacer></a-spacer>
                            <g-toggle
                                v-model="pushEnabled"
                                :disabled="!hasPermission"
                                :color="pushEnabled ? 'green darken-2' : 'grey'"
                                @change="localTogglePushNotification"
                                class="mt-1"
                                custom-bg dense-xl
                            />
                        </div>

                        <!-- Select notification sound -->
                        <div class="mt-5" :class="[{ 'u-opacity-60': !pushEnabled && hasPermission }]">
                            <h3 class="u-font-14 u-font-weight-semibold u-tracking u-leading-20 grey--text text--darken-3">
                                Sound
                            </h3>
                            <span class="u-font-12 font-weight-medium u-tracking u-leading-16 grey--text text--darken-1">
                                Choose how you would like to listen
                            </span>
                            <div class="u-flex mt-3">
                                <a-menu
                                    :disabled="!hasPermission || !pushEnabled"
                                    nudge-bottom="2"
                                    content-class="u-rounded-corners-lg"
                                    offset-y bottom
                                >
                                    <template v-slot:activator="{ on, value }">
                                        <div
                                            v-on="on"
                                            :class="[
                                                { 'u-cursor-pointer': hasPermission && pushEnabled },
                                                { 'u-no-select': !hasPermission || !pushEnabled },
                                            ]"
                                            class="u-border u-rounded-corners-lg"
                                            style="width: 190px;"
                                        >
                                            <div class="white u-border u-rounded-corners-lg u-flex-center-y px-2 py-1">
                                                <span class="grey--text text--darken-2 u-font-14 font-weight-medium u-tracking u-leading-20">
                                                    {{ currentSound.name }}
                                                </span>
                                                <a-spacer></a-spacer>
                                                <a-icon size="20" color="grey darken-2">
                                                    {{ value ? 'expand_less' : 'expand_more' }}
                                                </a-icon>
                                            </div>
                                        </div>
                                    </template>
                                    <a-list class="u-list-condensed u-list-condensed--round-select pa-1 c-tiny-scroll u-overflow-y" style="max-height: 220px;">
                                        <a-list-item
                                            v-for="sound in soundsList"
                                            :key="'sound-' + sound.name"
                                            :class="[{ 'grey lighten-4': sound.value === currentSound.value }]"
                                            @click="localSetSound(sound)"
                                            class="c-hover-state-light u-rounded-corners-lg"
                                        >
                                            <a-list-item-title
                                                class="u-font-14 font-weight-medium u-tracking u-leading-20"
                                                :class="[!sound.value ? 'grey--text text--darken-4' : 'grey--text text--darken-2']"
                                            >
                                                {{ sound.name }}
                                            </a-list-item-title>
                                            <a-list-item-icon
                                                v-if="sound.value === currentSound.value"
                                                class="justify-end u-flex align-center"
                                            >
                                                <a-icon size="18" color="green darken-2">check</a-icon>
                                            </a-list-item-icon>
                                        </a-list-item>
                                    </a-list>
                                </a-menu>

                                <div
                                    :class="[
                                        { 'u-cursor-pointer': hasPermission && pushEnabled && currentSound.value },
                                        { 'u-no-select u-opacity-40': !hasPermission || !pushEnabled || !currentSound.value },
                                    ]"
                                    class="u-border u-rounded-corners-lg u-flex-center ml-2"
                                    v-ripple="hasPermission && pushEnabled && currentSound.value"
                                    @click="localPlaySound()"
                                >
                                    <div class="white u-rounded-corners-lg u-flex-center u-hfull px-2">
                                        <a-icon size="20" color="grey darken-2">
                                            music_note
                                        </a-icon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Select display type of push notification -->
                        <div class="mt-5" :class="[{ 'u-opacity-60': !pushEnabled && hasPermission }]">
                            <h3 class="u-font-14 u-font-weight-semibold u-tracking u-leading-20 grey--text text--darken-3">
                                Type
                            </h3>
                            <span class="u-font-12 font-weight-medium u-tracking u-leading-16 grey--text text--darken-1">
                                Choose how you would like to view
                            </span>
                            <div class="u-flex mt-3" style="column-gap: 20px">
                                <a-sheet
                                    v-for="type in displayTypes"
                                    @click="localSetNotificationType(type)"
                                    :key="type.key"
                                    :class="[
                                        'pa-3 u-rounded-corners-lg',
                                        currentDisplayType === type.key ? 'indigo lighten-5': 'u-border',
                                        { 'u-cursor-pointer': hasPermission && pushEnabled },
                                        { 'u-opacity-40': !hasPermission },
                                        { 'u-no-select': !hasPermission || !pushEnabled },
                                    ]"
                                    :style="[
                                        { flex: '1 1 50%' },
                                        currentDisplayType === type.key ? { border: '1px solid #020204 !important' } : {},
                                    ]"
                                >
                                    <h4
                                        :class="[currentDisplayType === type.key ? 'indigo--text text--darken-1' : 'grey--text text--darken-1']"
                                        class="u-font-14 font-weight-medium u-leading-16 u-tracking"
                                    >
                                        {{ type.name }}
                                    </h4>
                                    <h4
                                        :class="[currentDisplayType === type.key ? 'grey--text text--darken-2' : 'grey--text']"
                                        class="u-font-12 mt-1 font-weight-medium u-leading-16 u-tracking"
                                    >
                                        {{ type.description }}
                                    </h4>
                                </a-sheet>
                            </div>
                        </div>

                        <!-- Trigger to display live example of the notification -->
                        <div style="margin-top: 40px;">
                            <a-btn
                                :disabled="!hasPermission || !pushEnabled"
                                @click="localTriggerExample()"
                                class="white--text ma-0 px-3 py-2 u-rounded-corners-xl"
                                color="primary"
                                depressed
                            >
                                <a-icon size="16" color="white" :outlined="!hasPermission || !pushEnabled">notifications_active</a-icon>
                                <span class="white--text u-font-14 font-weight-medium u-leading-20 u-tracking ml-2 u-transform-none">
                                    See how you get notified
                                </span>
                            </a-btn>
                        </div>
                    </div>
                </a-card>
            </a-dialog>

            <!-- Overlay settings dialog -->
            <div
                v-if="dialogSettings"
                class="u-absolute u-wfull u-hfull"
                style="top: 0px; left: 0px; right: 0px; bottom: 0px; z-index: 2; background: rgba(0,0,0,0.2); transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s"
            />
        </a-menu>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import EventBus from '@/config/config-eventbus'
import { notifier } from '../../helpers/helper-notification'
import { diffSimple } from '../../helpers/helper-date-filter'
import loadWorkspace from '@/mixins/mixin-workspace'
import mixinGetOrganizationPlan from '../../mixins/mixin-get-organization-plan'
import NotifierProjectStageUpdate from '../Notifiers/NotifierProjectStageUpdate.vue'
import NotifierProjectStatusUpdate from '../Notifiers/NotifierProjectStatusUpdate.vue'
import LoaderUserNotifications from '../Loader/LoaderUserNotifications.vue'

export default {
	components: {
        NotifierProjectStageUpdate,
        NotifierProjectStatusUpdate,
        LoaderUserNotifications
    },

    mixins: [
        mixinGetOrganizationPlan,
        loadWorkspace,
    ],

    provide () {
        const modalState = {}

        // Observed object to keep the provider reactive
        // Refer: https://v2.vuejs.org/v2/api/#provide-inject
        Object.defineProperty(modalState, 'dateFormatter', {
            enumerable: true,
            get: () => this.localGetTimestamp
        })

        Object.defineProperty(modalState, 'getWorkspace', {
            enumerable: true,
            get: () => this.localGetWorkspace
        })

        Object.defineProperty(modalState, 'get', {
            enumerable: true,
            get: () => _.get
        })

        return { modalState }
    },

    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            currentSound: { name: 'Blink', value: 'blink' },
            soundsList: [
                { name: 'Blink', value: 'blink' },
                { name: 'Game Play', value: 'game-play' },
                // { name: 'Ringing Bell', value: 'ringing-bell' },
                { name: 'Lightsaber', value: 'lightsaber' },
                // { name: 'Soft Ding', value: 'soft-ding' },
                { name: 'Chirrup', value: 'chirrup' },
                { name: 'Flubber', value: 'flubber' },
                { name: 'Knock Tone', value: 'knock-tone' },
                { name: 'Double Tone', value: 'double-tone' },
                { name: 'No Sound', value: null },
            ],
            localLoading: true,
            paginationLoading: false,
            initialUnreadCount: 0,
            hasUnreadCount: 0,
            tabIsActive: true,
            modelNotifications: false,
            showOnlyUnread: false,
            pushEnabled: true,
            dialogSettings: false,
            hasPermission: false,
            currentUserConfig: null,
            notificationsList: [],
            pageNumber: 1,
            currentDisplayType: 'default',
            displayTypes: [
                { name: 'Default', key: 'default', description: 'Shows Title, Description' },
                { name: 'Private', key: 'private', description: 'Shows only Title' },
            ],
        }
    },

    watch: {
        dialogSettings (val) {
            if (val) this.localCheckPermission()
        },

        async modelNotifications (val) {
            if (!val) return this.localDestoryScrollListener()
            this.localCheckPermission()
            this.localAddScrollListener()
            this.localFetchUserConfig()
            this.localHandleOnlyUnread()
            // this.localFetchUnreadCount()

            if (this.initialUnreadCount === this.hasUnreadCount) return

            await this.localFetchNotifications()
            this.localFetchUnreadCount({ type: 'index' })
        },
    },

    mounted () {
        this.localEchoNotifications()
        this.localCheckTabIsActive()
        this.localIndex()
    },

    computed: {
        localNotifications () {
            const list = _.cloneDeep(this.notificationsList).map(item => {
                return { ...item, created_at_day: moment(item.created_at).format('YYYY-MM-DD') }
            })

            return _.groupBy(list, 'created_at_day')
        },

        localNotificationsLength () {
            return _.size(this.notificationsList)
        },

        localIsLastPage () {
            return this.pageNumber >= this.localGetLastPage
        },

        localGetLastPage () {
            return this.notifications_meta?.last_page
        },

        ...mapState('User', {
            user_self: 'self'
        }),

        ...mapState('Notifications', {
            notifications_list: 'list',
            notifications_meta: 'meta',
            notification_unread_count: 'unread_count'
        }),

        ...mapState('Preference', {
            preference_list: 'list',
        }),

        ...mapGetters('Workspace', {
            workspace_board_list: 'board_list',
        }),
    },

    methods: {
        async localIndex () {
            await this.localFetchUserConfig()
            await this.localFetchNotifications()
            this.localFetchUnreadCount({ type: 'index' })
            this.localLoading = false
        },

        async localFetchNotifications (args) {
            const { type = 'index', noLoading = false, params = {} } = args || {}

            if (!noLoading) this.localLoading = true
            await this.notifications_index({
                'filter[read_at]': this.showOnlyUnread ? 0 : 1,
                'filter[notification_type]': 'websocket',
                page: this.pageNumber,
                ...params
            })
            this.notificationsList = _.cloneDeep(this.notifications_list)
            this.localHandleOnlyUnread()

            const config = this.preference_list.find(i => i.key === 'user.config.notifications')
            if (type === null) this.localUpsertUserPref({ isNew: !config })

            this.localLoading = false
        },

        localHandleOnlyUnread () {
            if (!this.showOnlyUnread) return
            this.notificationsList = this.notificationsList.filter(i => !i.read_at)
        },

        async localFetchUnreadCount ({ type = null } = {}) {
            await this.notification_get_unread_count()
            this.hasUnreadCount = this.notification_unread_count
            if (type === 'index') this.initialUnreadCount = this.hasUnreadCount
        },

        localToggleUnread (val) {
            this.localFetchNotifications({ type: null, params: { page: 1 } })
        },

        async localUpdateReadInfo (id, value) {
            const index = this.notificationsList.findIndex(i => i.id === id)
            if (index === -1) return

            this.$set(this.notificationsList[index], 'read_at', value ? moment().format('YYYY-MM-DD hh:mm:ss') : null)
            !value ? await this.notifications_unread({ id }) : await this.notifications_read({ id })

            this.localHandleOnlyUnread()
            this.localFetchUnreadCount({ type: 'index' })
        },

        async localReadAll () {
            await this.notifications_read_all()
            this.notificationsList = _.cloneDeep(this.notificationsList).map(item => {
                item.read_at = moment().format('YYYY-MM-DD hh:mm:ss')
                return item
            })
            this.localFetchUnreadCount()
        },

        localEchoNotifications () {
            if (!this.user_self || (this.user_self && !this.user_self.id)) return

            this.$echo.private(`internal.user.${this.user_self.id}`)
                .listen('.internal.user', (event) => {
                    this.notificationsList.unshift(event)
                    EventBus.$emit('notification', event)
                    this.notifications_add(event)
                    if (!this.tabIsActive && this.pushEnabled) {
                        this.localPushDesktopNotification(event)
                    }

                    this.hasUnreadCount++
                    if (this.modelNotifications) {
                        this.initialUnreadCount = this.hasUnreadCount
                    }
                })
        },

        localPushDesktopNotification (evt) {
            if (!this.pushEnabled) return

            const { data } = evt || {}
            this.localNotify(
                this.localConstructMessageTitle(data),
                this.localConstructMessageBody(data),
            )
        },

        localNotify (title, body) {
            this.$notification
                .title(title)
                .body(body)
                .visibility(this.currentDisplayType)
                .sound({ mute: this.currentSound.value === null, track: this.currentSound.value })
                .send()
        },

        localConstructMessageTitle (data) {
            if (notifier.action(data.action).isProjectStageUpdate()) {
                return `${this.localGetValue(data.actor, 'name')} updated Project Stage`
            }

            if (notifier.action(data.action).isProjectStatusUpdate()) {
                return `${this.localGetValue(data.actor, 'name')} updated Project Status`
            }
        },

        localConstructMessageBody (data) {
            if (notifier.action(data.action).isProjectStageUpdate()) {
                return this.localStageActionBody(data)
            }

            if (notifier.action(data.action).isProjectStatusUpdate()) {
                return this.localStausUpdateActionBody(data)
            }
        },

        localStageActionBody (data) {
            if (this.localGetValue(data.from, 'name')) {
                return `Moved project stage from ${this.localGetValue(data.from, 'name')} to ${this.localGetValue(data.to, 'name')}`
            }

            return `Moved project stage to ${this.localGetValue(data.to, 'name')}`
        },

        localStausUpdateActionBody (data) {
            if (this.localGetValue(data.from, 'type.value')) {
                return `Updated project status from ${this.localGetValue(data.from, 'type.value')} to ${this.localGetValue(data.to, 'type.value')}`
            }

            return `Updated project status to ${this.localGetValue(data.to, 'type.value')}`
        },

        localPlaySound () {
            if (!this.currentSound.value) return
            this.$notification
                .sound({ mute: false, track: this.currentSound.value })
                .playSound()
        },

        localGoTo (notification) {
            this.modelNotifications = false
            if (!notification.read_at) this.localUpdateReadInfo(notification.id, true)

            const notificationWorkspaceId = _.get(notification, 'data.workspace_id')
            if (this.current_workspace_id_mixin !== notificationWorkspaceId) {
                this.localSwitchWorkspace(notificationWorkspaceId)
            }

            const actionType = notifier.action(notification.type)
            if (actionType.isProjectStageUpdate()) {
                if (this.$route.params?.id === _.get(notification, 'data.module.data.id')) return
                this.$router.push({ name: 'projects-overview', params: { id: _.get(notification, 'data.module.data.id') } })
            }

            if (actionType.isProjectStatusUpdate()) {
                if (this.$route.params?.id === _.get(notification, 'data.module.data.id')) return
                this.$router.push({ name: 'projects-status', params: { id: _.get(notification, 'data.module.data.id') } })
            }
        },

        localSwitchWorkspace (workspaceId) {
            this.workspace_switch({ id: workspaceId })
            this.preference_workspace_switch({
                resource_type: 'User',
                value: workspaceId,
                label: 'Workspace',
                key: 'user.workspace'
            })
        },

        localGetValue (obj, key) {
            return _.get(obj, key)
        },

        localCheckTabIsActive () {
            document.addEventListener('visibilitychange', () => {
                this.tabIsActive = !document.hidden
            })
        },

        localGetDayOrDate (dateVal) {
            const daysCount = Math.abs(moment(dateVal, 'YYYY-MM-DD').diff(moment().format('YYYY-MM-DD'), 'days'))
            if (daysCount === 0) return 'Today'
            if (daysCount === 1) return 'Yesterday'

            return diffSimple(dateVal)
        },

        localCheckPermission () {
            this.hasPermission = !this.$notification.hasDeniedPermission && this.$notification.hasPermission
        },

        // Settings
        localTogglePushNotification () {
            this.localUpsertUserPref()
        },

        localSetSound (sound) {
            if (!this.hasPermission || !this.pushEnabled) return

            this.currentSound = sound
            this.localPlaySound()
            this.localUpsertUserPref()
        },

        localSetNotificationType (type) {
            if (!this.hasPermission || !this.pushEnabled) return
            this.currentDisplayType = type.key
            this.localUpsertUserPref()
        },

        localTriggerExample () {
            const title = 'John Doe updated Project Stage'
            const body = this.currentDisplayType === 'private' ? '' : 'Test message from Success App'
            this.localNotify(title, body)
        },

        // Preference
         async localFetchUserConfig () {
            let config = this.preference_list.find(i => i.key === 'user.config.notifications')
            if (!_.size(this.preference_list)) {
                await this.preference_index()
                config = this.preference_list.find(i => i.key === 'user.config.notifications')
            }

            if (!config) {
                this.showOnlyUnread = false
                await this.localUpsertUserPref({ isNew: true })
                return true
            }

            this.currentUserConfig = _.cloneDeep(config)
            this.localSetupSettings(JSON.parse(config.value))
        },

        async localUpsertUserPref (args) {
            const {
                only_unred = this.showOnlyUnread,
                push_enabled = this.pushEnabled,
                display_type = this.currentDisplayType,
                sound = this.currentSound,
                isNew = false
            } = args || {}

            const params = {
                key: 'user.config.notifications',
                resource_id: this.user_self.id,
                resource_type: 'User',
                label: 'User Notification: Show only unread',
                value: JSON.stringify({ only_unred, push_enabled, sound, display_type })
            }
            if (isNew) this.currentUserConfig = { ...params, id: uuidv4(), }
            if (!isNew) this.currentUserConfig = { ...this.currentUserConfig, value: params.value }

            await this.preference_upsert(this.currentUserConfig)
            await this.preference_index()
        },

        localSetupSettings (config) {
            this.showOnlyUnread = config.only_unred
            this.pushEnabled = config.push_enabled
            this.currentDisplayType = config.display_type
            this.currentSound = config.sound
        },

        localAddScrollListener () {
            this.$nextTick(() => {
                const element = this.$refs.refNotificationArea
                element.addEventListener('scroll', this.localElementIsVisible)
            })
        },

        localDestoryScrollListener () {
            this.$nextTick(() => {
                const element = this.$refs.refNotificationArea
                element.removeEventListener('scroll', this.localElementIsVisible)
            })
        },

        async localElementIsVisible (evt) {
            const scrollTopHeight = (evt.target.scrollTop + evt.target.clientHeight) + 100

            if (scrollTopHeight > evt.target.scrollHeight) {
                this.paginationLoading = true
                if (this.pageNumber >= this.localGetLastPage) {
                    return this.paginationLoading = false
                }

                this.pageNumber++
                await this.localFetchNotifications({
                    noLoading: true,
                    params: { _type: 'prepend' }
                })
                this.paginationLoading = false
            }
        },

        localGetWorkspace (workspaceId) {
            const workspace = this.workspace_board_list.find(i => i.id === workspaceId)
            return _.get(workspace, 'title')
        },

        localGetTimestamp (date) {
            return moment.utc(date).local().format('hh:mm A')
        },

        ...mapActions('Notifications', {
            notifications_index: 'index',
            notifications_add: 'add',
            notifications_read: 'read',
            notifications_unread: 'unread',
            notifications_read_all: 'read_all',
            notification_get_unread_count: 'get_unread_count',
        }),

        ...mapActions('Preference', {
            preference_index: 'index',
            preference_upsert: 'upsert_store',
            preference_workspace_switch: 'switch_workspace',
        }),

         ...mapActions('Workspace', {
            workspace_switch: 'switch_workspace',
        })
    }
}
</script>

<template>
    <div class="u-flex-center-y">
        <div class="u-flex u-border u-rounded-corners">
            <a-menu bottom offset-y :disabled="disabled" min-width="222" nudge-bottom="4" content-class="u-rounded-corners-lg u-shadow-md">
                <template v-slot:activator="{ on }">
                    <div v-on="on" class="u-flex u-cursor-pointer" v-ripple style="height: 36px;">
                        <a-card class="u-flex-center-y" flat min-width="180" max-width="180">
                            <div class="px-2 u-flex align-center u-wfull">
                                <span class="md-body-2 mx-1">{{ localGetSortField }}</span>
                                <a-spacer></a-spacer>
                                <a-icon size="24" class="ma-0">arrow_drop_down</a-icon>
                            </div>
                        </a-card>
                    </div>
                </template>
                <a-sheet>
                    <h4 class="u-font-12 font-weight-medium grey--text pt-3 px-4">Sort By</h4>
                    <a-list class="u-list-condensed u-list-condensed--round-select pa-0 pb-1 u-overflow-y c-tiny-scroll" style="max-height: 250px;">
                        <a-list-item
                            v-for="item in sortFields"
                            :key="'status_key_' + item.value"
                            @click="localApplySort(item.value)"
                            :class="[{ 'grey lighten-4': sortField === item.value }]"
                            class="px-3 py-1 mx-1 u-rounded-corners-lg"
                        >
                            <a-list-item-title>
                                <span class="md-body-2">{{ item.label }}</span>
                            </a-list-item-title>
                        </a-list-item>
                    </a-list>
                    <a-divider class="grey lighten-3"></a-divider>
                    <div class="pa-1 u-rounded-corners-lg u-flex u-wfull white">
                        <a-card
                            class="pa-2 u-flex-center u-wfull"
                            height="32"
                            :class="[{ 'grey lighten-3 font-weight-medium': sortDirection !== '-' }]"
                            @click="localApplySortDirection('')"
                            flat
                        >
                            <a-icon size="16" class="ma-0 mr-2">$sort_asc</a-icon>
                            <span class="md-body-2 grey--text text--darken-2" :class="[{ 'font-weight-medium': sortDirection !== '-' }]">Asc</span>
                        </a-card>
                        <a-card
                            class="pa-2 u-flex-center u-wfull"
                            height="32"
                            :class="[{ 'grey lighten-3': sortDirection === '-' }]"
                            @click="localApplySortDirection('-')"
                            flat
                        >
                            <a-icon size="16" class="ma-0 mr-2">$sort_desc</a-icon>
                            <span class="md-body-2 grey--text text--darken-2" :class="[{ 'font-weight-medium': sortDirection === '-' }]">Desc</span>
                        </a-card>
                    </div>
                </a-sheet>
            </a-menu>
            <a-spacer></a-spacer>
            <a-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <div
                        v-on="on"
                        class="u-flex-center u-cursor-pointer"
                        style="width: 36px; border-left: 1px solid #eee;"
                        v-ripple="true"
                        @click="localApplySortDirection(sortDirection === '-' ? '' : '-')"
                    >
                        <a-icon size="16" class="ma-0">{{ sortDirection !== '-' ? '$sort_asc' : '$sort_desc' }}</a-icon>
                    </div>
                </template>
                <span>Sort by {{ sortDirection !== '-' ? 'Descending' : 'Ascending' }}</span>
            </a-tooltip>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    props: {
        sortField: {
            type: String,
            default: 'customer_name'
        },
        sortDirection: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },

    data () {
        return {
            sortFields: [
                {'label': 'Title', 'value': 'title'},
                {'label': 'Account', 'value': 'customer_name'},
                {'label': 'Deal Amount', 'value': 'deal_amount'},
                {'label': 'Start Date', 'value': 'start_date'},
                {'label': 'End Date', 'value': 'due_date'},
                {'label': 'Health', 'value': 'health_color'},
            ],
            defaultSortField: 'customer_name'
        }
    },

    computed: {
        localGetSortField () {
            let field
            if (!this.localIsConfidenceScoreEnabled && !this.sortField) field = this.sortFields.find(i => i.value === 'title')
            else field = this.sortFields.find(i => i.value === this.sortField)
            return field && field.label
        },

        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    mounted() {
        this.local_set_sort_fields()
    },

    methods: {
        localApplySort (field) {
            const { value } = this.localGetSortByObj(field)
            this.$emit('sort', value || this.defaultSortField, this.sortDirection || '')
        },

        localGetSortByObj (value) {
            return this.sortFields.find(i => i.value === value)
        },

        localApplySortDirection (value) {
            this.$emit('sort', this.sortField || this.defaultSortField, value)
        },

        local_set_sort_fields () {
            const confidence_score = {'label': 'Confidence Score', 'value': 'confidence_score'}
            const index = this.sortFields.findIndex(field => field.value === confidence_score.value)
            this.localIsConfidenceScoreEnabled() ? this.sortFields.push(confidence_score) : this.sortFields.splice(index, 1)
        },

        localIsConfidenceScoreEnabled () {
            return (this.localOrgPreferenceConfidenceScore() && this.localConfidenceScoreFlag() && this.$can('poc_confidence_score.index'))
        },

        localConfidenceScoreFlag () {
            const { flag_confidence_score } = this.user_self && this.user_self.organization
            return flag_confidence_score === 1
        },

        localOrgPreferenceConfidenceScore () {
            const value = this.preference_get_property_by_key('organization.confidence_score', 'value')
            return value === 'Yes'
        },
    }
}
</script>

import { storage } from '@/helpers/helper-local-storage'

const api_endpoint = 'subscription-plans'

export default {
    namespaced: true,

    state: {
        list: storage.get('plansList') || {},
        response: [],
        defaults: {
            list: {},
            response: [],
        },
    },

    mutations: {
        UPDATE_LIST (state, list) {
            state.list = list
        },

        THROW_RESPONSE (state, response) {
            state.response = response
        },
    },

    actions: {
        async index ({ dispatch, commit }, payload) {
            dispatch('loader_start')
            try {
                const { data: { data } } = await sys_axios.get(`${api_endpoint}`, { params: { ...payload } })
                commit('UPDATE_LIST', data)
                storage.save('plansList', data)
                dispatch('throw_success')
            } catch (error) {
                dispatch('throw_error', error)
            }
        },

        async throw_success ({ commit }) {
            commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            this.dispatch('Interface/loader_stop')
        },

        async throw_error ({ commit }, error) {
            this.dispatch('Interface/loader_stop')
            commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
        },

        async loader_start () {
            this.dispatch('Interface/loader_start')
        },

        async clear ({ commit }) {
            commit('UPDATE_LIST', [])
        },

        async clear_response ({ commit }) {
            commit('THROW_RESPONSE', [])
        },
    }
}

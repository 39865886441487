const api_endpoint = '/associations';

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        response: [],
        defaults: {
            list: [],
            item: {},
            response: [],
        },
    },
    mutations: {
        STORE(state, payload) {
            state.item = payload
        },
        UPDATE_ITEM(state, payload) {
            state.item = payload
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, payload)
                .then((response) => {
                    context.commit('UPDATE_ITEM', payload)
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success'});
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },

        async bulk_store(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.post(api_endpoint + '/bulk', payload)
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success'});
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },

        async bulk_store_scope(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.post(api_endpoint + '/bulk-scope', payload)
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success'});
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },

        async bulk_all_store(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.post(api_endpoint + '/bulk-all', payload)
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success'});
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },

        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },

        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },

        async bulk_destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.delete(api_endpoint + '/bulk', { data: payload })
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },

        async bulk_delete_scope(context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.delete(api_endpoint + '/bulk-scope', { data: payload })
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },

        clear(context, payload) {
            context.commit('STORE', context.state.defaults.item)
            context.commit('THROW_RESPONSE', context.state.defaults.response)
        }
    },
}

const api_endpoint = '/task-uploads';

export default {
    namespaced: true,
    state: {
        list: [],
        meta: {},
        response: [],
        defaults: {
            list: [],
            meta: {},
            response: [],
        },
    },

    mutations: {
        UPDATE_LIST (state, items) {
            state.list = items;
        },
        UPDATE_META (state, meta) {
            state.meta = meta;
        },
        THROW_RESPONSE (state, payload) {
            state.response = payload;
        },
    },

    actions: {
        async index (context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const response = await axios.get(api_endpoint, { params: payload })
                context.commit('UPDATE_LIST', response.data.data);
                context.commit('UPDATE_META', response.data.meta);
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async format (context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const response = await axios.get(api_endpoint + '/format')
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                const link = document.createElement('a');
                link.id = 'upload_format_file'
                link.href = response.data.data.file_url;
                link.target = '_blank'
                document.body.appendChild(link);
                const el = document.getElementById('upload_format_file')
                el.click()
                el.parentNode.removeChild(el)
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },
        async destroy (context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.delete(api_endpoint + '/' + payload)
                context.commit('UPDATE_LIST', []);
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async store (context, payload) {
            this.dispatch('Interface/loader_start')
            let form_data = new FormData()
            form_data.append('csv_file', payload.csv_file)
            try {
                await axios.post(api_endpoint + '/' + payload.project_id, form_data, {
                    headers: {
                        'accept': 'application/json',
                        'Accept-Language': 'en-US',
                        'Content-Type': 'multipart/form-data',
                    }
                })
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },
        async save (context, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.post(api_endpoint + '/save/' + payload.project_id, { type: payload.type })
                context.commit('UPDATE_LIST', []);
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                resolve('Cleared');
            });
        },
    },

    getters: {
        list: (state) => {
            const data = [...state.list]
            return data
        },
        meta: (state) => {
            return { ...state.meta }
        },
        response: (state) => {
            return { ...state.response }
        },
        defaults: (state) => {
            return { ...state.defaults }
        },
    }
}

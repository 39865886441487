import { mapState, mapActions } from 'vuex'

export default {
    data () {
        return {
            checkscope_message: false,
            local_scope_message: true,
            filtered_list: [],
            all_list: [],
        }
    },

    computed: {
        ...mapState('Scope', {
            scope_check_list: 'list',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_project_checkscope() {
            await this.local_scope_check()
            this.all_list = this.scope_check_list.filter(item => item.value  === 'all')
            this.filtered_list = this.scope_check_list.filter(item => item.value !== 'all')
            if (this.filtered_list.length >= 1) {
                this.checkscope_message = true
                this.local_scope_message = false
            } 
        },

        async local_scope_check() {
            await this.scope_checkstatus({
                'filter[resource_id]': this.user_self.role_id,
                'filter[resource_type]': 'Role',
                'filter[type]': 'project',
                'fields[scopes]': 'id,key,value'
            })
        },

        ...mapActions('Scope', {
            scope_checkstatus: 'index',
        }),
    }
}
<template>
    <a-tooltip bottom offset-y :disabled="!hasError('competitor-create') && !$response(competitor_response, 'name')">
        <template v-slot:activator="{ on }">
            <div v-on="on">
                <v-autocomplete
                    v-model="compSearch"
                    :items="list"
                    item-text="name"
                    item-value="id"
                    placeholder="Search competitor"
                    @blur="$emit('focusOut', typeId)"
                    @keyup.delete="{}"
                    @change="localAddCompetitor"
                    @keydown.backspace="localOnBackspace"
                    :search-input.sync="compSearchApi"
                    :append-icon="null"
                    :loading="isLoading('competitor-create') || isLoading('competitor-search')"
                    class="u-rounded-corners u-hfull md-body-2 u-overflow-hidden"
                    :class="[{ 'c-wiggle-short u-border-error': hasError('competitor-create') || $response(competitor_response, 'name') }]"
                    :hide-no-data="hasPermission('competitors.update') || (isLoading('competitor-create') || isLoading('competitor-search'))"
                    solo flat hide-details return-object auto-select-first dense autofocus hide-selected
                >
                    <template #selection="{ item }">
                        <SCompetitorTag hide-clear-icon :tag="item"/>
                    </template>
                    <template #no-data v-if="!isLoading('competitor-create') && !isLoading('competitor-search')">
                        <div style="height: 32px" class="u-flex-center-y u-cursor-pointer mx-4">
                            <span :class="['md-body-2 body--text text--lighten-1']">No Data Found</span>
                        </div>
                    </template>
                    <template #item="{ item }">
                        <div v-if="item && item.id === 'new'" style="height: 32px" class="u-flex-center-y u-cursor-pointer">
                            <a-icon size="20" color="indigo darken-1">add</a-icon>
                            <span :class="['md-body-2 indigo--text text--darken-1 ml-1']">Create <span class="font-weight-bold">{{ item.name | truncateText(30) }}</span></span>
                        </div>
                        <div v-else-if="item && item.id === 'no-data'" style="height: 32px" class="u-flex-center-y u-cursor-pointer">
                            <span :class="['md-body-2 body--text text--lighten-1']">{{ item.name }}</span>
                        </div>
                        <div @click.stop="localAddCompetitor(typeId, 'onSelect', item)" v-else>
                            <SCompetitorTag hide-clear-icon :tag="item"/>
                        </div>
                    </template>
                </v-autocomplete>
            </div>
        </template>
        <span>{{ getError('competitor-create') ? getError('competitor-create') : $response(competitor_response, 'name') }}</span>
    </a-tooltip>
</template>

<script>
import { SCompetitorTag } from '@/config/config-shared-components'
import { mapActions, mapState } from 'vuex'

export default {
    props: {
        list: {
            type: Array,
            required: true
        },
        isModelOpen: {
            type: Boolean
        },
        typeId: {
            type: String
        },
        isLoading: {
            type: Function
        },
        hasPermission: {
            type: Function
        },
        hasError: {
            type: Function
        },
        getError: {
            type: Function
        }
    },

    components: {
        SCompetitorTag
    },

    data () {
        return {
            compSearch: null,
            compSearchApi: null
        }
    },

    watch: {
        compSearchApi (val) {
            this.$emit('inputSync', val)
        },
        isModelOpen (val) {
            this.competitor_clear_item()
            if (!val) {
                this.compSearch = null
                this.compSearchApi = null
            }
        }
    },

    computed: {
        ...mapState('Competitor', {
            competitor_response: 'response'
        })
    },

    methods: {
        localAddCompetitor (evt, type, selectedTag) {
            let data = {}
            if (type === 'onSelect') data = selectedTag
            if (evt && evt.id && evt.id !== 'new') data = evt
            if (evt && evt.id && evt.id === 'new') data = evt
            this.$emit('create', data, this.typeId)
        },

        localOnBackspace () {
            if (this.compSearch === null) return
            if (_.trim(this.compSearch.name.length) > 255) {
                this.compSearchApi = null
            }
        },

        ...mapActions('Competitor', {
            'competitor_clear_item': 'clear_item'
        })
    }
}
</script>

<template>
    <div class="c-heading u-relative u-flex align-center my-2" style="min-height: 40px">
        <!-- Block List Menu -->
        <block-list-menu v-if="$can('document_generator.update')" style="z-index: 50" @addBlock="methodStoreReportBlock"></block-list-menu>

        <!-- Block Information -->
        <div class="u-absolute" style="top: 50%; transform: translateY(-50%); left: -40px; z-index: 50">
            <a-menu offset-x left :nudge-top="50" v-model="dataSideMenu" transition="slide-x-transition" :close-on-content-click="false" min-width="350" max-width="350" style="max-width: 24px !important;" content-class="u-border c-radius u-elevation-custom-1">
                <template v-slot:activator="{ on }">
                    <a v-on="on" v-ripple="{ class: 'info--text' }" style="padding: 0 4px 0 4px;" class="c-component__menu u-flex align-center u-rounded-corners list-group-item js-drag-handle" :class="[ dataSideMenu ? 'is-active' : '' ]"><a-icon class="c-component__drag" size="26">drag_indicator</a-icon></a>
                </template>
                <div class="c-radius u-elevation-custom-1 py-3 px-4 white" style="max-width: 350px;">
                    <div class="u-flex align-center">
                        <h2 class="grey--text text--darken-3 font-weight-bold title" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.title }}</h2>
                        <a-spacer></a-spacer>
                        <a-btn small icon text color="red darken-1 ma-0" class="ma-0" v-if="$can('document_generator.destroy')" @click="methodRemoveComponent">
                            <svg width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-trash red--text text--darken-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a-btn>
                    </div>
                    <p class="grey--text text--darken-1 mb-0 body-2 font-weight-medium" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.description }}</p>
                </div>
            </a-menu>
        </div>

        <!-- Block -->
        <div class="c-page-break u-relative u-flex align-center justify-center my-4 c-xspacing" style="width: 100%; height: 48px; background: #f2f2f2">
            <h2 style="z-index: 1; font-size: 18px; font-weight: 400 !important;" class="u-relative grey--text text--darken-2">Page Break</h2>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import EventBus from '@/config/config-eventbus'
import BlockListMenu from '../BlockListMenu.vue'

export default {
    components: { BlockListMenu },

    props: ['item', 'index', 'list'],

    data () {
        return {
            clonedPageBreak: this.item,
            dataSideMenu: false,
        }
    },
    computed: {
        dataGetBlockInfo () {
            return this.getBlockContent(this.clonedPageBreak.report_block_id)
        },

        ...mapGetters('ReportBlock', [
            'getBlockContent'
        ]),
    },

    methods: {
        async methodStoreReportBlock (component) {
            await this.actionTemplateBlockStore({
                index: this.index + 1,
                data: { report_template_id: this.$route.params.template_id, report_block_id: component.id },
                params: { include: 'reportBlock' }
            })
            this.actionTemplateBlockReorder({ list: this.list })
            // EventBus.$emit('reorderBlock', { index: this.index + 1 })
            // EventBus.$emit('storeBlock', { index: this.index + 1, component })
        },

        methodRemoveComponent() {
            this.actionFindAndRemoveBlock(this.clonedPageBreak)
            this.actionTemplateBlockDelete({ id: this.clonedPageBreak.id })
            // EventBus.$emit('removeComponent', this.clonedPageBreak)
        },

        ...mapActions('ReportTemplateBlock', [
            'actionTemplateBlockStore',
            'actionTemplateBlockReorder',
            'actionFindAndRemoveBlock',
            'actionTemplateBlockDelete'
        ]),
    }
}
</script>

<style lang="scss" scoped>
    .c-page-break {
        &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            left: -80px;
            height: 48px;
            width: 100px;
            background: #f2f2f2;
        }
        &::after {
            left: auto;
            right: -80px;
            width: 100px;
        }
    }
</style>

import { render, staticRenderFns } from "./ProjectsTicketsView.vue?vue&type=template&id=cd541892&scoped=true"
import script from "./ProjectsTicketsView.vue?vue&type=script&lang=js"
export * from "./ProjectsTicketsView.vue?vue&type=script&lang=js"
import style0 from "./ProjectsTicketsView.vue?vue&type=style&index=0&id=cd541892&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd541892",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})

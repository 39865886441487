<template>
    <div class="c-heading u-relative u-flex align-center" style="min-height: 40px">
        <!-- Block List Menu -->
        <block-list-menu v-if="$can('document_generator.update')" @addBlock="methodStoreReportBlock"></block-list-menu>

        <!-- Block Info and Menu -->
        <div class="u-absolute" style="top: 50%; transform: translateY(-50%); left: -40px">
            <a-menu offset-x left :nudge-top="50" v-model="dataSideMenu" transition="slide-x-transition" :close-on-content-click="false" min-width="350" max-width="350" style="max-width: 24px !important;" content-class="u-border c-radius u-elevation-custom-1">
                <template v-slot:activator="{ on }">
                    <a v-on="on" v-ripple="{ class: 'info--text' }" style="padding: 0 4px 0 4px;" class="c-component__menu u-flex align-center u-rounded-corners list-group-item js-drag-handle" :class="[ dataSideMenu ? 'is-active' : '' ]"><a-icon class="c-component__drag" size="26">drag_indicator</a-icon></a>
                </template>
                <div class="c-radius u-elevation-custom-1 py-3 px-4 white" style="max-width: 350px;">
                    <div class="u-flex align-center">
                        <h2 class="grey--text text--darken-3 title font-weight-bold" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.title }}</h2>
                        <a-spacer></a-spacer>
                        <a-btn small icon text color="red darken-1 ma-0" class="ma-0" v-if="$can('document_generator.destroy')" @click="methodRemoveComponent">
                            <svg width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-trash red--text text--darken-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </a-btn>
                    </div>
                    <p class="grey--text text--darken-1 mb-0 body-2 font-weight-medium" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.description }}</p>
                    <template v-if="$can('document_generator.update')">
                        <div class="grey--text text--darken-1 text-uppercase body-2 font-weight-bold mb-2 mt-4" style="letter-spacing: 1px;">Alignment</div>
                        <a-layout align-center wrap v-if="dataAlignmentListDefault">
                            <template v-for="(menu) in dataAlignmentListDefault.options">
                                <a-flex xs3 class="pa-0 mb-2" v-if="menu.value" :key="menu.id">
                                    <a-btn small text :class="[methodBlockAttrs(clonedCustomText.id, dataAlignmentListDefault.id) && methodBlockAttrs(clonedCustomText.id, dataAlignmentListDefault.id).value.value === menu.value  ? 'grey lighten-2' : '']" @click="methodAlignBlock(menu, dataAlignmentListDefault.id, methodBlockAttrs(clonedCustomText.id, dataAlignmentListDefault.id).id)" class="u-border c-radius ma-0 mx-auto" style="min-height: 40px !important; min-width: 60px !important; max-width: 60px !important;">
                                        <a-icon size="24" color="grey darken-2">{{ 'format_align_' + menu.value }}</a-icon>
                                    </a-btn>
                                </a-flex>
                            </template>
                        </a-layout>
                    </template>
                </div>
            </a-menu>
        </div>

        <!-- Block -->
        <div class="u-relative u-wfull c-xspacing" v-if="clonedCustomText" :style="[currentAlignment ? { textAlign: currentAlignment.value } : '']" @dblclick="methodOpenFormatter" @click="methodOpenFormatter" @contextmenu.prevent @mousedown.right="methodOpenFormatter">
            <div v-if="dataHtml" ref="editorRef" class="c-rich-text-editor u-wfull grey--text text--darken-2 my-2" v-html="dataHtml" v-once placeholder="Rich Text" :id="clonedCustomText.id" :contenteditable="$can('document_generator.update')" @keyup="methodKeyUp" @keydown="methodKeyDown" @blur="methodUpdateOnBlur" @input="methodUpdateOnInput"></div>
            <div v-if="dataHtml === ''" ref="editorRef" class="c-rich-text-editor u-wfull grey--text text--darken-2 my-2" v-html="dataHtml" v-once placeholder="Rich Text" :id="clonedCustomText.id" :contenteditable="$can('document_generator.update')" @keyup="methodKeyUp" @keydown="methodKeyDown" @blur="methodUpdateOnBlur" @input="methodUpdateOnInput"></div>
            <!-- <template v-else>
                <div @click="methodFocusEditor" class="u-cursor-pointer">
                    <input type="text" placeholder="Rich Text" disabled v-once class="c-text-editor u-cursor-pointer c-xspacing u-wfull grey--text text--darken-2">
                </div>
            </template> -->

            <!-- Formatting Menu -->
            <a-menu offset-y absolute :position-x="formatterXPos" :position-y="formatterYPos" :nudge-bottom="16" v-model="showFormatter" transition="slide-x-transition" class="d-block" :close-on-content-click="false" max-width="350" content-class="u-border c-radius u-elevation-custom-1">
                <div class="c-radius u-elevation-custom-1 white u-flex align-center">
                    <a-btn small text :class="[format.bold ? 'grey darken-3' : '']" @click="methodFormatText('bold')" class="ma-0" style="border-right: 1px solid #f0f0f0 !important; min-height: 40px !important; min-width: 60px !important; max-width: 60px !important;">
                        <a-icon size="24" :color="format.bold ? 'grey lighten-2' : 'grey darken-2'">format_bold</a-icon>
                    </a-btn>
                    <a-btn small text :class="[format.italic ? 'grey darken-3' : '']" @click="methodFormatText('italic')" class="ma-0" style="border-right: 1px solid #f0f0f0 !important; min-height: 40px !important; min-width: 60px !important; max-width: 60px !important;">
                        <a-icon size="24" :color="format.italic ? 'grey lighten-2' : 'grey darken-2'">format_italic</a-icon>
                    </a-btn>
                    <!-- <a-btn small text :class="[format.underline ? 'grey darken-3' : '']" @click="methodFormatText('underline')" class="ma-0" style="border-right: 1px solid #f0f0f0 !important; min-height: 40px !important; min-width: 60px !important; max-width: 60px !important;">
                        <a-icon size="24" :color="format.underline ? 'grey lighten-2' : 'grey darken-2'">format_underlined</a-icon>
                    </a-btn> -->
                    <!-- <a-btn small text :class="[format.insertUnOrderedList ? 'grey darken-3' : '']" @click="methodFormatText('insertUnorderedList')" class="ma-0" style="border-right: 1px solid #f0f0f0 !important; min-height: 40px !important; min-width: 60px !important; max-width: 60px !important;">
                        <a-icon size="24" :color="format.insertUnOrderedList ? 'grey lighten-2' : 'grey darken-2'">format_list_bulleted</a-icon>
                    </a-btn>
                    <a-btn small text :class="[format.insertOrderedList ? 'grey darken-3' : '']" @click="methodFormatText('insertOrderedList')" class="ma-0" style="min-height: 40px !important; min-width: 60px !important; max-width: 60px !important;">
                        <a-icon size="24" :color="format.insertOrderedList ? 'grey lighten-2' : 'grey darken-2'">format_list_numbered</a-icon>
                    </a-btn> -->
                </div>
            </a-menu>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import EventBus from '@/config/config-eventbus'
import BlockListMenu from '../BlockListMenu.vue'
import TurndownService from 'turndown'
import marked from 'marked'



export default {
    components: { BlockListMenu },

    props: ['item', 'index', 'lastIndex', 'list'],

    data () {
        return {
            clonedCustomText: this.item,
            showFormatter: false,
            dataAutoSaveTimeout: false,
            formatterXPos: 0,
            formatterYPos: 0,
            dataSideMenu: false,
            showEditor: false,
            dataHtml: '',
            lengthToDelete: 0,
            currentAlignment: { label: 'Left', value: 'left' },
            currentFormatting: '',
            format: {
                bold: false,
                italic: false,
                underline: false,
                center: false,
                right: false,
                left: false,
            },
        }
    },

    watch: {
        lastIndex (value) {
            if (value) {
                // this.$refs.editorRef.focus()
            }
        },
        dataHtml (value) {
            // if (value.length > 0) this.methodUpdateOnBlur()
        }
    },

    mounted () {
        this.methodIndex()
    },

    computed: {
        dataTextAlignment () {
            const defaultAttr = this.dataGetBlockInfo.attributes.find(item => item.title === 'Alignment')
            const ratioAttr = this.clonedCustomText.report_template_block_attributes.find(item => item.rb_attribute_id === defaultAttr.id)
            this.currentAlignment = ratioAttr.value
        },

        dataIsLastBlock () {
            return this.index === this.lastIndex
        },

        dataGetBlockInfo () {
            return this.getBlockContent(this.clonedCustomText.report_block_id)
        },

        dataAlignmentListDefault () {
            return this.dataGetBlockInfo.attributes.find(item => item.title === 'Alignment') // Base Block's Attribute
        },

        dataCheckAlignment () {
            ['center', 'right', 'left'].forEach(item => {
                if (this.currentAlignment.value === item) this.format[item] = true
                else this.format[item] = false
            })
        },

        ...mapGetters('ReportBlock', [
            'getDefaultBlockAttr',
            'getBlockContent'
        ]),

        ...mapGetters('ReportTemplateBlock', [
            'getBlockAttrs'
        ]),

        ...mapGetters('ReportTemplate', [
            'getBlock',
        ]),
    },

    methods: {
        methodIndex () {
            this.dataTextAlignment
            this.dataCheckAlignment
            // if (this.clonedCustomText.content_html) {
            if (this.clonedCustomText.content) {
                this.dataHtml = marked(this.methodStripSplChars(), { sanitize: true })
            }
            // setTimeout(() => {
            //     if (this.dataIsLastBlock) this.methodSetCaret()
            // }, 800);
        },

        methodBlockAttrs (blk_id, attr_id) {
            const attr = this.getBlockAttrs(blk_id, attr_id)
            this.currentAlignment = attr.value
            return attr
        },

        methodStripSplChars () {
            // const filtered = this.clonedCustomText.content_html.replace(/(<.*?)/ig, '&lt;')
            const filtered = this.clonedCustomText.content.replace(/(<.*?)/ig, '&lt;')
            return filtered.replace(/(>.*?)/ig, '&gt;')
        },

        methodFocusEditor () {
            this.showEditor = true
            setTimeout(() => this.$refs.editorRef.focus(), 0);
        },

        methodUpdateOnInput (evt) {
            const { id, innerText: text, outerHTML: outHTML, innerHTML: html } = evt.target

            if (evt.inputType === 'insertFromPaste') this.methodBlurAndFocus()
            this.methodLiveUpdate(evt, text, html)
        },

        methodBlurAndFocus () {
            const element = this.dataGetBlockElement()
            const lastEl = element.lastElementChild.innerText
            element.blur()
            setTimeout(() => {
                element.focus()
                this.methodSetCaretPosition(element.lastElementChild, lastEl.length)
            }, 0)
        },

        methodSetCaretPosition (element, caretLocation) {
            const range = document.createRange();
            const selection = window.getSelection();
            range.setStart(element.firstChild, caretLocation);
            range.setEnd(element.firstChild, caretLocation);
            selection.removeAllRanges();
            selection.addRange(range);
        },

        async methodUpdateOnBlur (evt) {
            if (!this.$can('document_generator.update')) return
            if (this.showFormatter) return
            if (evt) {
                const { id, innerText: text, outerHTML: outHTML, innerHTML: html } = evt.target
                const element = document.getElementById(id)
                const serializedContent = await this.methodSerializeHtml(html)

                evt.preventDefault()
                this.clonedCustomText.content = serializedContent.markdown
                // this.clonedCustomText.content = text
                // this.clonedCustomText.content_html = serializedContent.markdown
                this.dataHtml = serializedContent.serialized
                element.innerHTML = serializedContent.serialized

                EventBus.$emit('editBlock', this.clonedCustomText)
                // if (!this.clonedCustomText.content.length) return
                this.methodUpdateBlock()
            }
        },

        async methodLiveUpdate (evt, text, html) {
            if (!this.$can('document_generator.update')) return
            clearTimeout(this.dataAutoSaveTimeout)
            const { markdown: markdown } = await this.methodSerializeHtml(html)

            this.clonedCustomText.content = markdown
            // this.clonedCustomText.content = text.trim()
            // this.clonedCustomText.content_html = markdown
            EventBus.$emit('editBlock', this.clonedCustomText)

            // if (!this.clonedCustomText.content.length) return
            this.dataAutoSaveTimeout = setTimeout(async () => {
                this.methodUpdateBlock()
                clearTimeout(this.dataAutoSaveTimeout)
            }, 500);
        },

        async methodSerializeHtml (html) {
            const stripImage = html.replace(/<img.*?src="(.*?)"[^\>]+>/ig, '')
            const markdown = this.methodCompiledMarkdown(stripImage)
            const markdownToHtmlEditing = marked(markdown, { sanitize: true })
            let serializedHtml = markdownToHtmlEditing.replace(/(<\/?(?:p|b|strong|i|em|u|br)[^>]*>)|<[^>]+>/ig, '$1')
            serializedHtml = serializedHtml.replace(/<img.*?src="(.*?)"[^\>]+>/ig, '$1')
            serializedHtml = serializedHtml.replace(/(<[^>]+) style=".*?"/ig, '$1')

            if (serializedHtml.children && serializedHtml.children.length) {
                Array.from(serializedHtml.children).forEach((element, index) => {
                    for (let prop in element.dataset) delete element[index].dataset[prop]
                })
            }

            return { serialized: serializedHtml, markdown }
        },

        async methodAlignBlock (menu, attrDefId, attrId) {
            this.currentFormatting = { default_id: attrDefId, menu_attr_id: attrId }
            this.currentAlignment = menu
            this.dataCheckAlignment
            await this.methodUpdateBlockAttr()
            setTimeout(() => {
                this.actionTemplateBlockShow({ id: this.clonedCustomText.id, index: this.index })
            }, 100);
        },

        async methodUpdateBlock () {
            this.actionTemplateBlockEdit({
                id: this.clonedCustomText.id,
                report_block_id: this.clonedCustomText.report_block_id,
                report_template_id: this.$route.params.template_id,
                content: this.clonedCustomText.content,
                // content_html: this.clonedCustomText.content_html,
            })
            EventBus.$emit('editBlock', this.clonedCustomText)
        },

        async methodUpdateBlockAttr () {
            const attribute = this.getBlockAttrs(this.clonedCustomText.id, this.currentFormatting)
            const params = {
                rt_block_id: this.clonedCustomText.id,
                rb_attribute_id: this.currentFormatting.default_id,
                value: JSON.stringify(this.currentAlignment)
            }

            this.actionTemplateBlockAttrEdit({ id: this.currentFormatting.menu_attr_id ? this.currentFormatting.menu_attr_id : null, ...params })
            EventBus.$emit('editBlock', this.clonedCustomText)
        },

        methodFormatText (type, tag = null) {
            this.$nextTick(() => {
                document.execCommand(type)
                this.checkHasFormatType()
            })
        },

        checkHasFormatType () {
            const variables = ['bold', 'italic', 'underline', 'center', 'right', 'left']
            variables.forEach(item => {
                if (this.methodHasCommand(item)) this.format[item] = true
                else this.format[item] = false
            })
        },

        methodOpenFormatter(evt) {
            if (!this.$can('document_generator.update')) return
            if (this.showFormatter) return this.showFormatter = false
            if (document.getSelection().getRangeAt(0).collapsed) return this.showFormatter = false
            const isTextHighlighting = window.getSelection().toString().trim() !== ''

            if (isTextHighlighting) {
                this.formatterXPos = evt.clientX
                this.formatterYPos = evt.clientY
                this.checkHasFormatType()
                setTimeout(() => {
                    if (document.getSelection().getRangeAt(0).collapsed) return this.showFormatter = false
                    this.showFormatter = true
                }, 200);
            }
        },

        methodKeyUp (evt) { // Clear up parent component and it's children before keydown
            const element = this.dataGetBlockElement()
            if (evt.key === 'Backspace' || evt.key === 'Delete') {
                if (this.clonedCustomText.content.length === 0 && evt.target.childNodes.length) return element.innerHTML = ''
            }
        },

        methodKeyDown (evt) {
            const element = this.dataGetBlockElement()
            if (evt.key === 'Backspace' || evt.key === 'Delete') {
                if (this.clonedCustomText.content.length === 0) {
                    if (evt.target.childNodes.length) return element.innerHTML = '' // clear parent component and it's children
                    if (evt.target.childNodes.length === 0) this.lengthToDelete = this.lengthToDelete + 1
                    setTimeout(() => {
                        setTimeout(() => this.$refs.editorRef ? this.$refs.editorRef.focus() : '', 0)
                        if (this.lengthToDelete === 1) return this.methodRemoveComponent()
                    }, 0)
                }
            }
        },

        async methodStoreReportBlock (component) {
            await this.actionTemplateBlockStore({
                index: this.index + 1,
                data: { report_template_id: this.$route.params.template_id, report_block_id: component.id },
                params: { include: 'reportBlock' }
            })
            this.actionTemplateBlockReorder({ list: this.list })
            // EventBus.$emit('reorderBlock', { index: this.index + 1 })
            // EventBus.$emit('storeBlock', { index: this.index + 1, component })
        },

        methodHasCommand (type) {
            return document.queryCommandState(type)
        },

        methodCompiledMarkdown (content) {
            return turndownService.turndown(content)
        },

        dataGetBlockElement () {
            return document.getElementById(this.clonedCustomText.id)
        },

        methodRemoveComponent() {
            this.actionFindAndRemoveBlock(this.clonedCustomText)
            this.actionTemplateBlockDelete({ id: this.clonedCustomText.id })
            // EventBus.$emit('removeComponent', this.clonedCustomText)
        },

        // methodSetCaret (isStart = false) {
        //     const target = this.dataGetBlockElement
        //     const range = document.createRange()
        //     const sel = window.getSelection()
        //     if (isStart){
        //         const newText = document.createTextNode('')
        //         target.appendChild(newText)
        //         range.setStart(target.childNodes[0], 0)
        //     } else {
        //         range.selectNodeContents(target)
        //     }
        //     range.collapse(isStart)
        //     sel.removeAllRanges()
        //     sel.addRange(range)
        //     target.focus()
        // },

        ...mapActions('ReportTemplateBlock', [
            'actionTemplateBlockStore',
            'actionTemplateBlockEdit',
            'actionTemplateBlockAttrsStore',
            'actionTemplateBlockReorder',
            'actionTemplateBlockAttrEdit',
            'actionTemplateBlockShow',
            'actionFindAndRemoveBlock',
            'actionTemplateBlockDelete'
        ]),
    }
}
</script>

<style lang="scss" scoped>
    .c-rich-text-editor {
        transition: 0.4s all;
        font-family: Arial !important;
        &:focus {
            // background: #f5f5f5 !important;
            background: #fbfbfb !important;
            transition: 0.4s all;
        }
    }

    [contenteditable]:empty:before {
        content: attr(placeholder);
        display: block;
        color: rgba(99, 98, 98, 0.25);
    }
</style>

import generateBaseUrl from './config-api'

window._ = require('lodash')
window.moment = require('moment-timezone')
window.currency = require('currency.js')
window.qs = require('qs')

window.axios = generateBaseUrl(process.env.VUE_APP_CORE_API_URL) // Internal users enpoints baseURL
window.ext_axios = generateBaseUrl(process.env.VUE_APP_CORE_API_URL, 'external') // External users endpoints baseURL
window.sys_axios = generateBaseUrl(process.env.VUE_APP_CORE_API_URL, 'system') // System endpoints baseURL
window.axios.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets' })
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.headers.common['X-Timezone'] = moment.tz.guess(true)

import TurndownService from 'turndown'
window.turndownService = new TurndownService({ emDelimiter: '*' })

// Internal users axios interceptor
window.axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (window.axios.isCancel(error)) {
        console.log('Only one request allowed at a time. Cancelling the first.')
    }
    return error_redirect(error)
})

window.axios.interceptors.request.use(function (request) {
    request.headers['X-Socket-Id'] = window?.Echo?.socketId()
    return request
}, function (error) {
    return Promise.reject(error);
})

// External users axios interceptor
window.ext_axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (window.ext_axios.isCancel(error)) {
        console.log('Only one request allowed at a time. Cancelling the first.')
    }
    return error_redirect(error)
})

// System endpoints axios interceptor
window.sys_axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (window.sys_axios.isCancel(error)) {
        console.log('Only one request allowed at a time. Cancelling the first.')
    }
    return error_redirect(error)
})

const error_redirect = (error) => {
    console.log(error)
    if (error.response.status === 401) {
        if (window.location.pathname !== '/login') window.location.replace('/login')
    } else if (error.response.status === 403) {
        window.location.replace('/errors/unauthorized')
    } else if (error.response.status === 419) {
        window.location.replace('/login')
    } else if (error.response.status === 404) {
        // window.location.replace('/page-not-found')
        return Promise.reject(error)
    } else if (error.response.status === 429) {
        console.log(error)
        return Promise.reject(error)
    } else {
        console.log(error)
        return Promise.reject(error)
    }
}

<template>
    <a-menu v-model="modelDropdown" bottom offset-y :close-on-content-click="closeOnContentClick" v-bind="$attrs">
        <template v-slot:activator="{ on }">
            <div v-on="on" :class="[{ 'u-cursor-pointer': canUpdate }, 'u-flex-center-y px-3 transparent u-wfull u-hfull']">
                <div v-if="!item.assignees || !item.assignees.length" :class="['u-flex-center-y', triggerClass]" style="column-gap: 6px;">
                    <a-card flat color="grey" class="u-rounded-corners-full u-flex-center" :width="avatarSize" :height="avatarSize">
                        <a-icon color="white" :size="avatarSize / 2">{{ localHasPermissionTo('update') ? 'group_add' : 'people_alt' }}</a-icon>
                    </a-card>
                    <span v-if="showEmptyText" class="md-body-2 font-weight-medium grey--text">Unassigned</span>
                </div>
                <SUsersDeck
                    v-else
                    :fontSize="fontSize"
                    :list="item.assignees"
                    :hideList="isActive || modelDropdown"
                    :avatar-size="avatarSize"
                    :count="2"
                    :dark="dark"
                    :customZIndex="customZIndex"
                    :class="[triggerClass]"
                />
            </div>
        </template>
        <a-sheet>
            <div class="u-hfull">
                <div class="u-flex-center-y py-3 px-4">
                    <a-icon color="indigo darken-2" size="18">group_add</a-icon>
                    <h5 class="md-subtitle-2 indigo--text text--darken-2 ml-2">Assign to collaborators</h5>
                </div>
                <input
                    type="text"
                    ref="refInput"
                    placeholder="Search assignees"
                    class="u-wfull u-hfull px-4 py-3 md-body-2 grey lighten-4"
                    style="outline: none; border-top: 1px solid #EEEEEE !important; border-bottom: 1px solid #EEEEEE !important;"
                    :autofocus="isActive"
                    @input="evt => autoMode ? localSearchCollabs(evt) : searchFn(evt)"
                >
            </div>
            <a-list
                class="c-custom-dropdown white u-hfull px-2 u-overflow-y c-tiny-scroll"
                :class="[{ 'py-2': localOrderedList && localOrderedList.length }]"
                style="max-height: 250px;"
            >
                <template v-if="localOrderedList && !localOrderedList.length">
                    <a-list-item class="px-2 u-rounded-corners md-subtitle-1 grey--text text--darken-1 u-rounded-corners">
                        No Assignees Found
                    </a-list-item>
                </template>
                <template v-else>
                    <template v-for="(collab) in localOrderedList">
                        <a-list-item
                            :key="collab.user_id"
                            @click="localEmit(collab)"
                            class="px-2 py-1 u-rounded-corners u-cursor-pointer md-subtitle-1 c-hover-state-light u-rounded-corners"
                        >
                            <!-- active-class="grey lighten-4" -->
                            <div class="u-flex-center-y u-wfull">
                                <div class="u-flex">
                                    <g-avatar :item="collab" :size="32" fontSize="10" style="margin-top: 2px;"></g-avatar>
                                    <g-profile-info :item="collab" set-max-width></g-profile-info>
                                </div>
                                <template v-if="localIsAssigned(collab, item)">
                                    <a-spacer></a-spacer>
                                    <a-icon color="indigo darken-2" size="20">done</a-icon>
                                </template>
                            </div>
                        </a-list-item>
                    </template>
                </template>
            </a-list>
        </a-sheet>
    </a-menu>
</template>

<script>
import { SUsersDeck } from '@/config/config-shared-components'
import { mapActions, mapState } from 'vuex';

export default {
    inheritAttrs: false,

    props: {
        list: {
            type: Array
        },
        item: {
            type: Object
        },
        searchFn: {
            type: Function,
        },
        isAddedFn: {
            type: Function,
        },
        avatarSize: {
            type: Number | String,
            default: 32
        },
        fontSize: {
            type: Number | String,
            default: 12
        },
        projectId: {
            type: String,
            required: true
        },
        triggerClass: {
            type: String | Array
        },
        isActive: {
            type: Boolean,
            default: false
        },
        dark: {
            type: Boolean,
            default: false
        },
        customZIndex: {
            type: Boolean,
            default: true
        },
        autoMode: {
            type: Boolean,
            default: false
        },
        showEmptyText: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean,
            default: true
        },
    },

    components: { SUsersDeck },

    data () {
        return {
            closeOnContentClick: false,
            modelDropdown: false,
            searchTimeout: null,
            collabsList: [],
            searchValue: ''
        }
    },

    watch: {
        modelDropdown (val) {
            this.$emit('active', val)
            if (val) {
                if (this.$refs && this.$refs.refInput) this.$refs.refInput.value = ''
                this.searchValue = ''
                this.localSearchCollabs()
                setTimeout(() => {
                    this.$refs.refInput.focus()
                    document.querySelector('.c-custom-dropdown').children[0].classList.add('v-list-item--highlighted')
                }, 200);
            }
        }
    },

    computed: {
        localOrderedList () {
            const collabsList = _.trim(this.searchValue) ? this.collabsList : this.list
            const selected = collabsList.filter(i => this.localIsAssigned(i, this.item))
            const deSelected = collabsList.filter(i => !this.localIsAssigned(i, this.item))

            return [...selected, ...deSelected]
        },

        ...mapState('Collaborator', {
            collaborator_list: 'list'
        }),
    },

    methods: {
        localEmit (collab) {
            this.closeOnContentClick = true
            if (!this.canUpdate) return

            this.$emit('select', {
                mode: 'select',
                item: collab
            })
        },

        localSearchCollabs (evt) {
            clearTimeout(this.searchTimeout)
            this.searchValue = evt ? evt.target.value : ''
            if (_.trim(this.searchValue)) this.collabsList = _.cloneDeep(this.list)
            this.searchTimeout = setTimeout(async () => {
                const params = { 'filter[project_id]': this.projectId, 'filter[user]': this.searchValue, count: 1000, page: 1 }
                await this.collaborator_index({ ...params, include: 'user.role,type,contact' })
                this.collabsList = _.cloneDeep(this.collaborator_list)
                clearTimeout(this.searchTimeout)
            }, 300)
        },

        localIsAssigneeAdded (collab, item) {
            if (!item.assignees || _.size(item.assignees) === 0) return false

            const hasCollab = item.assignees.find(item => item.assignee.user_id === collab.user_id)
            return hasCollab || false
        },

        localIsAssigned (collab, item) {
            return !this.autoMode ? this.isAddedFn(collab, item) : this.localIsAssigneeAdded(collab, item)
        },

        localHasPermissionTo (type) {
            return this.$can('projects.update-only') && this.$can(`tasks.${type}`)
        },

        ...mapActions('Collaborator', {
            collaborator_index: 'index',
            collaborator_clear: 'clear',
        }),
    },
}
</script>

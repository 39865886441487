<template>
    <span class="md-body-2 u-flex-center-y" :class="[hasError ?  'red--text text--darken-2' : 'body--text text--lighten-1']">
        <slot name="icon" v-if="!hideInfo || hasError">
            <a-icon :size="iconSize" class="u-icon-nudge" style="top: 1px;" :color="hasError ? 'red darken-2' : 'grey darken-1'">{{ icon }}</a-icon>
        </slot>
        <span class="ml-1" v-if="hasError">
            <slot name="error-msg">{{ error }}</slot>
        </span>
        <span class="ml-1" v-if="!hasError && !hideInfo">
            <slot name="info"></slot>
        </span>
    </span>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: 'info'
        },
        iconSize: {
            type: String,
            default: '16'
        },
        error: {
            type: Object | Array
        },
        hideInfo: {
            type: Boolean,
            default: false
        },
        hasError: {
            type: String | Object | Boolean
        }
    }
}
</script>

<style>

</style>

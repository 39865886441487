<template>
    <div>
        <div :class="handle">
            <div v-if="stage.status === 'completed'" class="c-project-card__badge">
                <span class="c-project-card__badge-wrap">
                    <a-tooltip bottom left>
                        <template v-slot:activator="{ on }">
                            <template v-if="project.result">
                                <a-avatar v-on="on" size="24" color="green" class="c-project-card__badge-avatar" v-if="project.result.status === 'Positive'">
                                    <a-icon size="12" color="white">thumb_up</a-icon>
                                </a-avatar>
                                <a-avatar v-on="on" size="24" color="red" class="c-project-card__badge-avatar" v-if="project.result.status === 'Negative'">
                                    <a-icon size="12" color="white">thumb_down</a-icon>
                                </a-avatar>
                                <a-avatar v-on="on" size="24" color="indigo darken-2" class="c-project-card__badge-avatar" v-if="project.result.status === 'Neutral'">
                                    <a-icon size="12" color="white">linear_scale</a-icon>
                                </a-avatar>
                            </template>
                            <a-avatar v-on="on" size="24" color="orange darken-3" class="c-project-card__badge-avatar" v-else>
                                <a-icon size="12" color="white">priority_high</a-icon>
                            </a-avatar>
                        </template>
                        <span v-if="project.result">{{ project.result.value }}</span>
                        <span v-else>No Result Data</span>
                    </a-tooltip>
                </span>
            </div>
            <router-link tag="div" class="c-project-card__wrapper" :to="{name: 'projects-overview', params: {id: project.id}}">
                <div class="c-project-card__health" v-if="stage.status !== 'queue' && stage.status !== 'completed'" :class="{'c-project-card__health--green': project.health_color == 'green' || (!project.health_color || project.health_color == 'none'), 'c-project-card__health--yellow': project.health_color == 'yellow', 'c-project-card__health--red': project.health_color == 'red'}"></div>
                <div class="c-project-card__health c-project-card__health--gray" v-else></div>
                <div class="c-project-card__title px-3 py-3">
                    <h2 class="md-subtitle-1 font-weight-bold mb-1" style="word-break: break-all">{{ project.title | truncateText(55) }}</h2>
                    <div class="u-flex align-center flex-wrap">
                        <p class="md-body-2 mb-0 grey--text text--darken-1 u-flex align-center" :title="project.customer_name && project.customer_name.length >= 30 ? project.customer_name : ''">
                            <span>{{ project.customer_name | truncateText(30) }}</span>
                        </p>
                        <span v-if="project.customer_name && project.customer_name.length < 30" class="mx-1 md-body-2 grey--text text--darken-1"> &bull; </span>
                        <p class="md-body-2 mb-0 grey--text text--darken-1 u-flex align-center" :class="{ 'mt-1': project.customer_name && project.customer_name.length > 30 }">
                            <g-currency tag="span" class="green--text text--darken-1" :value="project.deal_amount"></g-currency>
                        </p>
                    </div>
                </div>

                <!-- Start Usecase Task Count Bar -->
                <div class="u-wfull" v-if="(get_preference('progress') === 'Scope' && $can('usecases.index') )">
                    <a-divider></a-divider>
                    <div class="c-project-card__progress u-wfull px-3 py-2">
                        <s-module-progress is-old :closed="project.total_completed_success_criteria" :total="project.total_success_criteria" flex></s-module-progress>
                    </div>
                </div>
                <div class="u-wfull" v-if="(get_preference('progress') === 'Task' && $can('tasks.index') )">
                    <a-divider></a-divider>
                    <div class="c-project-card__progress u-wfull px-3 py-2">
                        <s-module-progress is-old :closed="project.total_completed_tasks" :total="project.total_tasks" flex task></s-module-progress>
                    </div>
                </div>
                <div class="u-wfull" v-if="(get_preference('progress') === 'All' && $can('tasks.index') && $can('usecases.index') )">
                    <a-divider></a-divider>
                    <div class="d-flex u-wfull px-3 py-2">
                        <s-module-progress is-old :closed="project.total_completed_success_criteria" :total="project.total_success_criteria" full animate></s-module-progress>
                        <s-module-progress is-old :closed="project.total_completed_tasks" :total="project.total_tasks" full task class="ml-2" animate></s-module-progress>
                    </div>
                </div>
                <div class="u-wfull" v-if="(get_preference('progress') === 'All' && !$can('tasks.index') && $can('usecases.index') )">
                    <a-divider></a-divider>
                    <div class="c-project-card__progress u-wfull px-3 py-2">
                        <s-module-progress is-old :closed="project.total_completed_success_criteria" :total="project.total_success_criteria" flex></s-module-progress>
                    </div>
                </div>
                <div class="u-wfull" v-if="(get_preference('progress') === 'All' && $can('tasks.index') && !$can('usecases.index') )">
                    <a-divider></a-divider>
                    <div class="c-project-card__progress u-wfull px-3 py-2">
                        <s-module-progress is-old :closed="project.total_completed_tasks" :total="project.total_tasks" flex task></s-module-progress>
                    </div>
                </div>
                <!-- End Usecase Task Count Bar -->

                <a-divider class="my-0"></a-divider>
                <div class="c-project-card__tags">
                    <div class="ml-0 my-0 px-3" :class="[{ 'u-flex-center-y': metaLoading || !localHasTagsTerritories }]">
                        <div v-if="metaLoading" class="grey--text text--darken-2 md-caption u-flex-center-y py-1" style="column-gap: 4px">
                            <loader-template height="22" width="70" class="u-rounded-corners-full"></loader-template>
                            <loader-template height="22" width="80" class="u-rounded-corners-full"></loader-template>
                            <loader-template height="22" width="60" class="u-rounded-corners-full"></loader-template>
                        </div>
                        <template v-else>
                            <template v-if="project.tags && project.tags.length">
                                <g-tags v-for="tag in project.tags" :tag="tag" :key="tag.id" hide-clear-icon></g-tags>
                            </template>
                            <template v-if="project.territories && project.territories.length">
                                <g-tags v-for="territory in project.territories" :tag="territory" :key="territory.id" hide-clear-icon></g-tags>
                            </template>
                            <div v-if="!localHasTagsTerritories" class="u-flex-center-y" style="min-height: 30px; column-gap: 4px">
                                <a-icon size="16" outlined>sell</a-icon>
                                <span class="grey--text text--darken-2 md-caption">No Tags / Territories available</span>
                            </div>
                        </template>
                    </div>
                    <a-divider class="mt-2"></a-divider>
                </div>

                <div class="c-project-card__velocity justify-space-between align-center px-3 py-2">
                    <div>
                        <span class="caption grey--text text--darken-3" v-if="stage.status === 'queue'">
                            <a-tooltip bottom v-if="project.start_date">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        <a-icon size="14" class="u-icon-nudge mr-1">event</a-icon>
                                        <g-moment tag="span" class="md-caption" :value="project.start_date" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY"></g-moment>
                                    </span>
                                </template>
                                <span>Start date</span>
                            </a-tooltip>
                            <span v-else>
                                <a-icon size="14" class="u-icon-nudge mr-1">event</a-icon>
                                <span>No Start date</span>
                            </span>
                        </span>
                        <span class="caption grey--text text--darken-3" v-else-if="stage.status === 'completed'">
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        <a-icon size="14" class="u-icon-nudge mr-1">event</a-icon>
                                        <span class="md-caption">{{ project.active_days }} days</span>
                                    </span>
                                </template>
                                <span>Total number of days in active stages</span>
                            </a-tooltip>
                        </span>
                        <span class="caption grey--text text--darken-3" v-else>
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        <a-icon size="14" class="u-icon-nudge mr-1">event</a-icon>
                                        <template v-if="stage && stage.name">
                                            <span class="md-caption">{{ project.stage_active_days >= 0 ? (project.stage_active_days + '/') : '' }}{{ project.active_days }} days</span>
                                        </template>
                                    </span>
                                </template>
                                <span class="md-body-2 d-block text-center" style="max-width: 210px;">In {{ stage.name }} stage for {{ project.stage_active_days >= 0 ? project.stage_active_days : 0 }} days out of {{ project.active_days }} days in active stages</span>
                            </a-tooltip>
                        </span>
                    </div>
                    <div class="u-flex align-center">
                        <div>
                            <template v-if="project.key_collaborators && project.key_collaborators.length">
                                <s-users-deck :list="project.key_collaborators" :count="3" :avatar-size="28" font-size="10" hide-list></s-users-deck>
                            </template>
                            <a-tooltip bottom v-if="project.key_collaborators && !project.key_collaborators.length">
                                <template v-slot:activator="{ on }">
                                    <a-btn v-on="on" text icon small color="grey" class="ma-0 mr-1" :to="{name: 'projects-collaborators', params: {id: project.id}}">
                                        <a-icon size="14">person</a-icon>
                                    </a-btn>
                                </template>
                                <span>No Team Members Added</span>
                            </a-tooltip>
                        </div>
                        <!-- Confidence score -->
                        <div class="ml-4" v-if="localConfidenceScoreVisibility()">
                            <a-menu bottom nudge-bottom="40" open-on-hover open-delay="300" min-width="280" class="">
                                <template v-slot:activator="{ on }">
                                    <a-progress-circular
                                        v-on="on"
                                        width="3"
                                        :value="project.confidence_score && project.confidence_score.percentage ? project.confidence_score.percentage : 0"
                                        :rotate="-90"
                                        size="34"
                                        :color="!local_get_progress_color() ? 'grey lighten-2' : local_get_progress_color()"
                                    >
                                        <div class="text-center">
                                            <div class="grey--text text--darken-4 md-overline font-weight-medium" style="font-weight: 600">
                                                {{ project.confidence_score && project.confidence_score.percentage ? `${project.confidence_score.percentage}%` : '--' }}
                                            </div>
                                        </div>
                                    </a-progress-circular>
                                </template>
                                <div class="pa-0 grey darken-4 white--text">
                                    <div class="pa-4">
                                        <div class="md-subtitle-1 font-weight-medium">Confidence Score</div>
                                        <template v-if="!metaLoading">
                                            <div class="md-body-2">
                                                <div class="mt-3" v-if="project.confidence_score && confidence_score_aggregate && !confidence_score_aggregate.fields_configured">
                                                    Confidence score is not updated yet.
                                                </div>
                                                <div class="mt-3" v-if="confidence_score_aggregate && !confidence_score_aggregate.fields_configured">
                                                    No fields are configured.
                                                </div>
                                            </div>
                                        </template>
                                        <div v-else class="grey--text text--darken-2 md-caption pt-1 mt-3">
                                            <loader-template height="6" width="200" class="u-rounded-corners-full mb-3"></loader-template>
                                        </div>
                                    </div>
                                    <template v-if="project.confidence_score && project.confidence_score.current_score">
                                        <a-divider></a-divider>
                                        <div class="pa-4 md-body-2">
                                            <template v-if="project.confidence_score && project.confidence_score.percentage && !metaLoading">
                                                Score:
                                                <span class="md-subtitle-1 font-weight-medium">{{ project.confidence_score.current_score ? project.confidence_score.current_score : '--' }}</span>
                                                <span>/</span>
                                                <span>{{ project.confidence_score.total || '-' }}</span>
                                                <span class="md-subtitle-1 ml-2 font-weight-medium">({{ project.confidence_score.percentage ? project.confidence_score.percentage : '--'}}%)</span>
                                            </template>
                                            <template v-else>
                                                <div class="grey--text text--darken-2 md-caption u-flex-center-y py-1">
                                                    <loader-template height="6" width="200" class="u-rounded-corners-full"></loader-template>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <a-divider></a-divider>
                                        <div class="pa-4 md-body-2">
                                            <template v-if="!metaLoading">
                                                No confidence score
                                            </template>
                                            <template v-else>
                                                <div class="grey--text text--darken-2 md-caption u-flex-center-y py-1" style="column-gap: 4px">
                                                    <loader-template height="6" width="200" class="u-rounded-corners-full"></loader-template>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </a-menu>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="c-project-card__menu grey lighten-3 u-relative" style="z-index: 1">
            <div class="pa-2 u-flex align-center">
                <a-tooltip bottom v-if="$can('projects.update-only')">
                    <template v-slot:activator="{ on }">
                        <a-btn v-on="on" text icon small :loading="archiveLoading === project.id" color="grey darken-2" class="ma-0" @click="$emit('archive', null, project.id, project.title, stage.id, 'archived', stage.meta)">
                            <a-icon size="14">archive</a-icon>
                        </a-btn>
                    </template>
                    <span>Archive</span>
                </a-tooltip>
                <a-tooltip bottom v-if="stage.status != 'completed' && $can('projects.update-only') && $can('tasks.show') && $can('tasks.update')">
                    <template v-slot:activator="{ on }">
                        <a-btn v-on="on" text icon small color="grey darken-2" class="ma-0" @click="$emit('mark-complete', null, project.id, project.title, stage.id, 'completed', null, stage.meta)">
                            <a-icon size="14">done_all</a-icon>
                        </a-btn>
                    </template>
                    <span>Mark as Complete</span>
                </a-tooltip>
                <a-spacer></a-spacer>
                <a-tooltip bottom class="right" v-if="$can('projects.update-only') && $can('status_updates.store')">
                    <template v-slot:activator="{ on }">
                        <a-btn v-on="on" text icon small color="grey darken-2" class="ma-0" @click="$emit('update-status', { id: project.id, title: project.title })">
                            <a-icon size="14">comment</a-icon>
                        </a-btn>
                    </template>
                    <span>Post Status Update</span>
                </a-tooltip>
                <a-tooltip bottom class="right" v-if="$can('projects.update-only') && $can('notes.show') && $can('notes.store')">
                    <template v-slot:activator="{ on }">
                        <a-btn v-on="on" text icon small color="grey darken-2" class="ma-0" @click="$emit('create-note', project.id, project.title)">
                            <a-icon size="14">notes</a-icon>
                        </a-btn>
                    </template>
                    <span>Create Note</span>
                </a-tooltip>
                <a-tooltip bottom class="right"  v-if="$can('projects.update-only') && $can('tasks.show') && $can('tasks.store')">
                    <template v-slot:activator="{ on }">
                        <a-btn v-on="on" text icon small color="grey darken-2" class="ma-0" @click="$emit('create-task', project.id, project.title, stage.id)">
                            <a-icon size="14">check_circle</a-icon>
                        </a-btn>
                    </template>
                    <span>Create Task</span>
                </a-tooltip>
                <a-tooltip bottom class="right">
                    <template v-slot:activator="{ on }">
                        <a-btn v-on="on" text icon small color="grey darken-2" class="ma-0" target="_blank" :to="{name: 'projects-overview', params: {id: project.id}}" :exact="true">
                            <a-icon size="14">launch</a-icon>
                        </a-btn>
                    </template>
                    <span>Open in New Window</span>
                </a-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import {
    SModuleProgress,
    SDashboardLayout,
    SUsersDeck,
} from '@/config/config-shared-components'
import { diffSimple } from '@/helpers/helper-date-filter'

export default {
    props: {
        project: {
            type: Object,
            required: true
        },
        stage: {
            type: Object,
            required: true
        },
        handle: {
            type: String,
        },
        archiveLoading: {
            type: Boolean | Object | String,
            default: null
        },
        metaLoading: {
            type: Boolean,
            default: false
        },
        configMeta: {
            type: Array,
            required: true
        },
    },

    components: { SModuleProgress, SDashboardLayout, SUsersDeck },

    computed: {
        localHasTagsTerritories () {
            return _.size(this.project.tags) || _.size(this.project.territories)
        },

        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key',
        }),

        ...mapState('Preference', {
            preference_list: 'list',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('ConfidenceScore', {
            confidence_score_aggregate: 'aggregate',
        }),
    },

    methods: {
        get_preference(type, field = 'value') {
            if (type === 'progress') {
                return this.preference_get_property_by_key('organization.project.progress_resource', field)
            }
            if (type === 'widgets') {
                return this.preference_get_property_by_key(`organization.projects.widgets.${type}`, field)
            }
            if (type === 'confidence_score') {
                return this.preference_get_property_by_key(`organization.${type}`, field)
            }
        },

        local_org_has_flag (type) {
            return this.user_self.organization[`flag_${type}`] === 1
        },

        localConfidenceScoreVisibility () {
            if (!this.local_org_has_flag('confidence_score')) return false
            return (this.get_preference('confidence_score') !== 'No' && this.$can('poc_confidence_score.index'))
        },

        local_updated_date_output (date) {
            return diffSimple(date, { type: 'includeYear' })
        },

        local_get_progress_color () {
            if (this.project && !this.project.confidence_score) return
            if (this.configMeta && !this.configMeta.length) return

            const confidence_score = this.project.confidence_score.percentage
            if (confidence_score <= this.local_get_range_values('red-dark', 'percentage')) return this.local_get_range_values('red-dark', 'color')
            if ((confidence_score <= this.local_get_range_values('yellow-dark', 'percentage')) && (confidence_score > this.local_get_range_values('red-dark', 'percentage'))) return this.local_get_range_values('yellow-dark', 'color')
            if (confidence_score > this.local_get_range_values('yellow-dark', 'percentage')) return this.local_get_range_values('green-dark', 'color')
        },

        local_get_range_values (color, value) {
            const result = this.configMeta.find(item => item.color === color)
            if (value === 'percentage') return result[value]
            return this.$color(color ?? 'teal-dark', 'color_bg')
        }
    }
}
</script>

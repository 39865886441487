<template>
    <a-menu offset-y nudge-bottom="6" content-class="u-rounded-corners-xl">
        <template v-slot:activator="{ on, value }">
            <a-card
                v-on="on"
                :class="[value ? 'grey darken-2' : 'transparent']"
                class="u-flex-center-y justify-space-between u-no-select u-cursor-pointer u-rounded-corners-xl pa-1 px-2 pl-3"
                height="32"
                flat
            >
                <p class="ma-0 text-truncate white--text md-body-2" style="max-width: 200px; min-width: 70px;">{{ localGetCurrentModule }}</p>
                <a-spacer></a-spacer>
                <a-icon color="white" size="18">keyboard_arrow_{{ value ? 'up' : 'down' }}</a-icon>
            </a-card>
        </template>
        <a-list class="u-list-condensed u-list-condensed--round-select u-overflow-y grey darken-4 c-tiny-scroll pa-1" style="max-height: 224px;">
            <a-list-item
                v-for="item in islandModuleSwitch"
                :key="item.value"
                @click="$emit('change', { item, mode: 'select' })"
                class="white--text md-body-2 u-rounded-corners-lg"
            >
                {{ item.text }}
            </a-list-item>
        </a-list>
    </a-menu>
</template>

<script>
export default {
    props: {
        currentModule: {
            type: String,
            default: 'task'
        }
    },

    data () {
        return {
            islandModuleSwitch: [
                { text: 'Task', value: 'task' },
                { text: 'Milestone', value: 'milestone' },
            ]
        }
    },

    computed: {
        localGetCurrentModule () {
            return this.islandModuleSwitch.find(i => i.value === this.currentModule)['text']
        }
    }
}
</script>

import { render, staticRenderFns } from "./ExternalProjectsView.vue?vue&type=template&id=1365d98d&scoped=true"
import script from "./ExternalProjectsView.vue?vue&type=script&lang=js"
export * from "./ExternalProjectsView.vue?vue&type=script&lang=js"
import style0 from "./ExternalProjectsView.vue?vue&type=style&index=0&id=1365d98d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1365d98d",
  null
  
)

export default component.exports
<template>
    <a-main>
        <!-- Breadcrumbs -->
        <!-- <g-settings-breadcrumb :items="databreadcrumbItems" style="position: sticky; top: 54px; left: 0; z-index: 50"></g-settings-breadcrumb> -->

        <!-- Dynamic Variables -->
        <div class="white py-1 px-2 ml-auto u-elevation-custom-1" style="max-width: 140px; border-radius: 40px; position: fixed; top: 90px; text-align: right; display: flex; right: 20px; justify-content: flex-end; z-index: 10">
            <a-btn text small class="ma-0" style="border-radius: 20px;" @click="dataDynamicVarDialog = true">
                <!-- <svg width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-question-circle-fill grey--text text--darken-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z"/>
                </svg> -->
                <span class="body-2 font-weight-medium mr-2 text-capitalize">Variables</span>
                <svg width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-question-circle grey--text text--darken-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                </svg>
            </a-btn>
        </div>
        <a-container pt-4 pb-0>
            <a-layout>
                <a-flex xs12 py-0 class="text-center mx-auto u-flex align-center justify-center" style="min-height: 40px; max-width: 960px;">
                    <!-- <div v-for="item in breadcrumb_items" :key="item.text">
                        <template v-if="item.edit">
                            <a-sheet key="currentPage" style="cursor: initial" v-if="!showEditTitle" @click="methodShowEditTitle" class="transparent d-block text-truncate" max-width="200">
                                {{ item.text }}
                            </a-sheet>
                            <a-sheet key="editInput" class="c-title-edit transparent d-inline-flex align-center" min-width="600" max-width="600" style="transition: 0.4s all" v-else>
                                <span class="body-2 font-weight-medium grey--text text--darken-1 mr-2"><strong class="grey--text text--darken-3">Tip:</strong> Hit "Enter" to update</span>
                                <input type="text" class="u-rounded-corners pa-1 u-wfull" ref="refEditTitle" style="border: 2px solid #777 !important; outline: none; max-width: 400px;" v-model="item.text" autofocus @keydown.enter="methodUpdateTitle(item.text)">
                                <a-tooltip right>
                                    <a-btn slot="activator" icon small flat class="ma-0 ml-2" @click="methodUpdateTitle(item.text)"><a-icon color="grey darken-1" size="18">save</a-icon></a-btn>
                                    <span>Update Title</span>
                                </a-tooltip>
                            </a-sheet>
                        </template>
                        <router-link key="otherPage" v-if="!item.edit && !showEditTitle" :to="item.to">
                            {{ item.text }}
                            <span class="px-2" style="color: rgba(0,0,0,.38) !important">/</span>
                        </router-link>
                    </div> -->
                    <a-btn icon small text class="ma-0" @click="$router.back()">
                        <svg width="24px" height="24px" viewBox="0 0 16 16" class="bi bi-arrow-left-circle grey--text text--darken-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                        </svg>
                    </a-btn>
                    <a-spacer></a-spacer>
                    <input type="text" :disabled="!$can('document_generator.update')" class="c-edit-name-input u-rounded-corners pa-1 u-wfull px-2 ml-2 headline grey--text text--darken-2 font-weight-medium" style="outline: none;" v-model="getTemplateItem.name" @input="methodUpdateTitle(getTemplateItem.name)">
                    <!-- <div class="u-wfull u-relative">
                        <div v-if="dataTemplateTitleError" class="red--text text--darken-1 u-flex align-center u-absolute caption mt-2" style="bottom: -22px; left: 16px;">
                            <a-icon size="16" color="red darken-1" class="mr-2">error</a-icon>
                            {{ dataTemplateTitleError }}
                        </div>
                    </div> -->
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Configuration area -->
        <div class="d-flex align-items-stretch transparent u-hfull mt-4 mb-5">
            <page>
                <template v-if="!localLoading">
                    <draggable
                        class="list-group"
                        tag="div"
                        v-model="dataBlockList"
                        v-if="dataBlockList && dataBlockList.length"
                        v-bind="dragOptions"
                        @start="drag = true"
                        @end="drag = false"
                    >
                        <!-- <transition-group type="transition" :name="!drag ? 'flip-list' : null"> -->
                            <div v-for="(component, index) in dataBlockList" :key="component.id">
                                <!-- {{ component.report_block.file }} -->
                                <component class="c-component" :lastIndex="dataBlockList.length - 1" :is="component.report_block.file" :list="dataBlockList" :index="index" :item="component"></component>
                            </div>
                        <!-- </transition-group> -->
                    </draggable>

                    <div>
                        <!-- <rich-text :lastIndex="dataBlockList.length" :item="dataRichTextBlock"></rich-text> -->
                    </div>
                    <template v-if="$can('document_generator.update')">
                        <template v-for="(text, index) in dataEmptyTags">
                            <div :class="index === 0 ? 'mb-2' : index === dataEmptyTags.length - 1 ? 'mb-2' : 'my-2'" class="u-flex align-center c-xspacing u-relative" style="position: relative; min-height: 40px; max-height: 40px;" :key="text.id + index">
                                <div class="u-wfull" style="position: absolute; top: 50%; transform: translateY(-50%); left: -36px;">
                                    <a-menu offset-y bottom transition="slide-y-transition" v-model="searchBlock" :close-on-content-click="dataSearchMode" min-width="400" max-width="400" content-class="u-border c-radius u-elevation-custom-1">
                                        <template v-slot:activator="{ on }">
                                            <a v-on="on" style="padding: 4px;" v-ripple="{ class: 'info--text' }" @click.prevent="dataSearchBlock = ''" class="u-flex align-center u-wfull u-rounded-corners">
                                                <a-icon size="18">add</a-icon>
                                                <div class="u-wfull grey--text text--lighten-1 subheading ml-3" style="outline: none;">Click to add block</div>
                                            </a>
                                        </template>
                                        <a-card flat class="u-overflow-hidden">
                                            <a-list class="u-list-condensed py-0">
                                                <a-list-item class="c-input-wrap c-input-wrap--doc-generate py-4 px-4" :class="{ 'mb-2 pb-1': dataGetBlockList && dataGetBlockList.length }">
                                                    <a-text-field
                                                        v-model="dataSearchBlock"
                                                        label="Search Blocks"
                                                        color="grey darken-1"
                                                        background-color="neutral"
                                                        ref="refSearchBlock"
                                                        class="subheading"
                                                        solo flat hide-details
                                                        @click="dataSearchMode = false"
                                                    >
                                                    </a-text-field>
                                                </a-list-item>
                                            </a-list>
                                            <a-list ref="refBlockList" class="c-block-list-menu px-0 pt-0 overflow-y-auto" :class="[dataGetBlockList.length ? 'pb-3' : 'pb-0']" v-if="dataGetBlockList" style="max-height: 400px;">
                                                <template v-if="dataGetBlockList.length">
                                                    <template v-for="(component, index) in dataGetBlockList">
                                                        <a-divider class="grey lighten-4" :key="component.id + 'list-divider'" v-if="index !== 0"></a-divider>
                                                        <a-list-item @click="methodStoreReportBlock(component)" class="u-wfull px-4 py-1" :key="component.id">
                                                            <div class="u-flex align-start">
                                                                <a-list-item-action class="ma-0 mr-2">
                                                                    <a-avatar tile style="border-radius: 10px !important;" class="u-border grey lighten-2 mr-2">
                                                                        <a-img :src="require('../../assets/images/blocks/' + component.icon)" width="24" height="24" class="grey--text text--darken-1" contain></a-img>
                                                                    </a-avatar>
                                                                </a-list-item-action>
                                                                <a-list-item-content>
                                                                    <a-list-item-title>{{ component.title }}</a-list-item-title>
                                                                    <a-list-item-subtitle>{{ component.description }}</a-list-item-subtitle>
                                                                </a-list-item-content>
                                                            </div>
                                                        </a-list-item>
                                                    </template>
                                                </template>
                                                <a-list-item v-else class="u-wfull px-0">
                                                    <a-list-item-content>
                                                        <a-list-item-title class="grey--text text--darken-3">No blocks found..</a-list-item-title>
                                                    </a-list-item-content>
                                                </a-list-item>
                                            </a-list>
                                        </a-card>
                                    </a-menu>
                                </div>
                            </div>
                        </template>
                    </template>
                    <!-- <a-sheet min-height="100" class="grey lighten-4">
                        <p @input="handleInput" contenteditable class="pa-2">{{ content }}</p>
                    </a-sheet> -->
                </template>
                <template v-if="localLoading">
                    <div class="mt-5 title grey--text text--darken-2 font-italic text-center">Fetching data...</div>
                </template>
            </page>
            <!-- <a-btn dark class="primary u-rounded-corners ml-0" @click="methodGenerateReport">Generate Report</a-btn> -->
        </div>

        <!-- Dynamic Variables Popup -->
        <a-dialog v-model="dataDynamicVarDialog" scrollable max-width="800" persistent>
            <a-card class="u-relative py-4">
                <a-btn icon text small style="position: absolute; top: 12px; right: 12px" @click="dataDynamicVarDialog = !dataDynamicVarDialog">
                    <a-icon size="18" color="grey lighten-1">cancel</a-icon>
                </a-btn>
                <div class="u-flex align-start px-6 pb-4">
                    <a-avatar size="48" class="grey lighten-3 mr-3">
                        <a-icon size="32" color="primary">integration_instructions</a-icon>
                    </a-avatar>
                    <div>
                        <a-card-title class="title primary--text pa-0">
                            Dynamic Variables
                        </a-card-title>
                        <h2 class="body-2 grey--text text--darken-1 mb-1 d-block">Add these variables to add it's content in the generated report.</h2>
                    </div>
                </div>
                <a-divider></a-divider>
                <a-card-text class="pa-6" style="height: 700px;">
                    <table class="u-wfull">
                        <tr>
                            <h2 class="grey--text text--darken-3 title text-xs-left pb-3"><strong class="font-weight-medium">Project</strong> Variables</h2>
                        </tr>
                        <template v-for="item in dataProjectVariables">
                            <tr :key="item.variable">
                                <td class="pa-3">
                                    <div class="u-flex align-center u-wfull" :style="[$can('document_generator.update') ? { cursor: 'copy !important' } : '']" v-clipboard:copy="$can('document_generator.update') ? item.variable : ''" v-clipboard:success="(evt) => $can('document_generator.update') ? medhodCopySuccessMsg(evt, item.variable + 'id') : ''">
                                        <a-btn icon small text class="ma-0 mr-2" v-if="$can('document_generator.update')"><a-icon size="18" color="grey darken-1">content_copy</a-icon></a-btn>
                                        <code :id="item.variable + 'id'" style="padding: 2px; border: 1px solid #dcdcdc !important; font-family: monospace !important;" class="caption elevation-0 grey lighten-4 u-rounded-corners grey--text text--darken-2">{{ item.variable }}</code>
                                    </div>
                                </td>
                                <td class="pa-3 body-2 grey--text text--darken-3">{{ item.description }}</td>
                            </tr>
                        </template>
                        <template v-if="tag_section_list && tag_section_list.length">
                            <tr>
                                <h2 class="grey--text text--darken-3 title text-xs-left mt-3 py-3"><strong class="font-weight-medium">Project Custom Tag</strong> Variables</h2>
                            </tr>
                            <template v-for="item in tag_section_list">
                                <tr :key="item.id">
                                    <td class="pa-3">
                                        <div class="u-flex align-center u-wfull" :style="[$can('document_generator.update') ? { cursor: 'copy !important' } : '']" v-clipboard:copy="$can('document_generator.update') ? local_change_slug(item.slug) : ''" v-clipboard:success="(evt) => $can('document_generator.update') ? medhodCopySuccessMsg(evt, item.slug + 'id') : ''">
                                            <a-btn icon small text class="ma-0 mr-2" v-if="$can('document_generator.update')"><a-icon size="18" color="grey darken-1">content_copy</a-icon></a-btn>
                                            <code :id="item.slug + 'id'" style="padding: 2px; border: 1px solid #dcdcdc !important; font-family: monospace !important;" class="caption elevation-0 grey lighten-4 u-rounded-corners grey--text text--darken-2" :title="local_change_slug(item.slug)">{{ local_change_slug(item.slug) | truncateText(40) }}</code>
                                        </div>
                                    </td>
                                    <td class="pa-3 body-2 grey--text text--darken-3">Adds custom tag <strong>{{ item.name | truncateText(15) }}</strong> from project.</td>
                                </tr>
                            </template>
                        </template>
                        <template v-if="custom_field_list && custom_field_list.length">
                            <tr>
                                <h2 class="grey--text text--darken-3 title text-xs-left mt-3 py-3"><strong class="font-weight-medium">Project Custom Fields</strong> Variables</h2>
                            </tr>
                            <template v-for="item in dataGetCustomFieldsList">
                                <tr :key="item.id">
                                    <td class="pa-3">
                                        <div class="u-flex align-center u-wfull" :style="[$can('document_generator.update') ? { cursor: 'copy !important' } : '']" v-clipboard:copy="$can('document_generator.update') ? local_custom_field_slug(item.name, item.type) : ''" v-clipboard:success="(evt) => $can('document_generator.update') ? medhodCopySuccessMsg(evt, item.name + 'id') : ''">
                                            <a-btn icon small text class="ma-0 mr-2" v-if="$can('document_generator.update')"><a-icon size="18" color="grey darken-1">content_copy</a-icon></a-btn>
                                            <code :id="item.slug + 'id'" style="padding: 2px; border: 1px solid #DCDCDC !important; font-family: monospace !important;" class="caption elevation-0 grey lighten-4 u-rounded-corners grey--text text--darken-2" :title="local_custom_field_slug(item.name, item.type)">{{ local_custom_field_slug(item.name, item.type) | truncateText(40) }}</code>
                                        </div>
                                    </td>
                                    <td class="pa-3 body-2 grey--text text--darken-3">Adds custom field <strong>{{ item.label | truncateText(12)}}</strong> from project.</td>
                                </tr>
                            </template>
                        </template>
                        <tr>
                            <h2 class="grey--text text--darken-3 title text-xs-left mt-3 py-3"><strong class="font-weight-medium">Document</strong> Variables</h2>
                        </tr>
                        <template v-for="item in dataDocumentVariables">
                            <tr :key="item.variable">
                                <td class="pa-3">
                                    <div class="u-flex align-center u-wfull" :style="[$can('document_generator.update') ? { cursor: 'copy !important' } : '']" v-clipboard:copy="$can('document_generator.update') ? item.variable : ''" v-clipboard:success="(evt) => $can('document_generator.update') ? medhodCopySuccessMsg(evt, item.variable + 'id') : ''">
                                        <a-btn icon small text class="ma-0 mr-2" v-if="$can('document_generator.update')"><a-icon size="18" color="grey darken-1">content_copy</a-icon></a-btn>
                                        <code :id="item.variable + 'id'" style="padding: 2px; border: 1px solid #dcdcdc !important; font-family: monospace !important;" class="caption elevation-0 grey lighten-4 u-rounded-corners grey--text text--darken-2">{{ item.variable }}</code>
                                    </div>
                                </td>
                                <td class="pa-3 body-2 grey--text text--darken-3">{{ item.description }}</td>
                            </tr>
                        </template>
                        <!-- <tr>
                            <h2 class="grey--text text--darken-3 title text-xs-left mt-3 py-3"><strong class="font-weight-medium">Usecase</strong> Variables</h2>
                        </tr>
                        <template v-for="item in dataUsecaseVariables">
                            <tr :key="item.variable">
                                <td class="pa-3"><code style="padding: 2px; border: 1px solid #dcdcdc !important;" class="caption elevation-0 grey lighten-4 u-rounded-corners grey--text text--darken-2">{{ item.variable }}</code></td>
                                <td class="pa-3 body-2 grey--text text--darken-3">{{ item.description }}</td>
                            </tr>
                        </template>
                        <tr>
                            <h2 class="grey--text text--darken-3 title text-xs-left mt-3 py-3"><strong class="font-weight-medium">Task</strong> Variables</h2>
                        </tr>
                        <template v-for="item in dataTaskVariables">
                            <tr :key="item.variable">
                                <td class="pa-3"><code style="padding: 2px; border: 1px solid #dcdcdc !important;" class="caption elevation-0 grey lighten-4 u-rounded-corners grey--text text--darken-2">{{ item.variable }}</code></td>
                                <td class="pa-3 body-2 grey--text text--darken-3">{{ item.description }}</td>
                            </tr>
                        </template> -->
                    </table>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!-- Snackbar - Messages & Errors -->
        <template v-if="snackbarText && snackbarText.type === 'variable'">
            <a-snackbar v-model="snackbarStatus" :timeout="snackbarTimeout" bottom center>
                <span style="font-family: monospace !important;">{{ snackbarText.content }}</span>
                <a-btn color="primary lighten-3" text @click="snackbarStatus = false">Close</a-btn>
            </a-snackbar>
        </template>
        <template v-else>
            <a-snackbar color="red darken-2" auto-height v-model="snackbarStatus" :timeout="snackbarTimeout" bottom center>
                <span class="body-2 white--text">{{ snackbarText.content }}</span>
                <a-btn color="grey lighten-3" text @click="snackbarStatus = false">Close</a-btn>
            </a-snackbar>
        </template>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import EventBus from '@/config/config-eventbus'
import Page from '@/components/ReportBuilder/Page.vue'
import ReportBuilder from '@/config/config-doc-gen-components'
import RichText from '@/components/ReportBuilder/RichText/index.vue'
import { projectVariables, documentVariables } from './dynamic-variables'

export default {
    components: { draggable, Page, ...ReportBuilder, RichText },

    data() {
        return {
            dataEmptyTags: [
                { id: new Date().toISOString(), content: null }
            ],
            content: 'Hello',
            dataPlaygroundInputs: [],
            dataBlocks: [],
            dataShowBlock: false,
            drag: false,
            showEditTitle: false,
            isFocus: false,
            dataDynamicVarDialog: false,
            dataProjectVariables: projectVariables,
            dataDocumentVariables: documentVariables,
            // dataUsecaseVariables: usecaseVariables,
            // dataTaskVariables: taskVariables,
            dataAutoSaveTimeout: null,
            searchBlock: false,
            snackbarStatus: false,
            snackbarTimeout: 4000,
            snackbarText: '',
            dataSearchBlock: '',
            dataSearchMode: true,
            localLoading: true
        }
    },

    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },

    mounted() {
        this.methodIndex()
    },

    watch: {
        searchBlock (val) {
            if (val) {
                setTimeout(() => this.$refs.refBlockList[0].$el.scrollTop = 0, 100)
                setTimeout(() => this.$refs.refSearchBlock[0].ref.focus(), 500)
            }
        },
    },

    computed: {
        databreadcrumbItems () {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Settings', to: '/settings', edit: false, exact: true})
            breadcrumbs.push({text: 'Report Templates', to: '/settings/document-generator', edit: false, exact: true})
            breadcrumbs.push({text: 'Template Editor', to: `/settings/document-generator/${this.getTemplateItem.id}`, edit: true, exact: true})
            return breadcrumbs
        },

        dataBlockList: {
            get () {
                return this.getTemplateBlocks
            },
            set (list) {
                this.actionTemplateBlockList(list)
                this.actionTemplateBlockReorder({ list })
            }
        },

        dataGetBlockList () {
            const searchValue = this.dataSearchBlock.toLowerCase()
            const searchableFields = ['title', 'description']

            return this.getBlocksList.filter(item => {
                return searchableFields.some(field => item[field] && item[field].toLowerCase().includes(searchValue))
            })
        },

        dataRichTextBlock () {
            return this.getBlocksList.find(item => item.file === 'RichText')
        },

        dragOptions () {
            return {
                animation: 200,
                group: 'description',
                disabled: !(this.$can('document_generator.update')),
                ghostClass: 'ghost',
                handle: '.js-drag-handle'
            }
        },

        dataTemplateShowParams () {
            return {
                include: 'reportTemplateBlocks.reportBlock, reportTemplateBlocks.reportTemplateBlockAttributes,reportTemplateBlocks.reportTemplateBlockAttributes.reportBlockAttribute',
                'fields[report_templates]': 'id,name,order,is_active',
                'fields[report_template_blocks]': 'id,order,content,report_block_id,report_template_id',
                'fields[report_template_blocks.report_template_block_attributes]': 'id,value,rb_attribute_id,rt_block_id',
                'fields[report_template_blocks.report_template_block_attributes.report_block_attribute]': 'id,title',
            }
        },

        dataGetCustomFieldsList () {
            return this.custom_field_list.filter(field => field.type !== 'mask')
        },

        ...mapGetters('User', {
            'user_self': 'self'
        }),

        ...mapGetters('ReportBlock', [
            'getBlocksList',
        ]),

        ...mapGetters('ReportTemplate', [
            'getTemplateItem',
            'getTemplateResponse'
        ]),

        ...mapGetters('ReportTemplateBlock', [
            'getTemplateBlockItem',
            'getTemplateBlocks'
        ]),

        ...mapState('TagSection', {
            tag_section_list: 'list',
        }),

        ...mapState('CustomField', {
            custom_field_list: 'list',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        })
    },

    methods: {
        async methodIndex () {
            if (!this.$can('document_generator.show')) {
                return this.$router.push({ name: 'dashboard' })
            }

            this.load_custom_slug_list()

            await this.actionTemplateShow({
                report_template_id: this.$route.params.template_id,
                ...this.dataTemplateShowParams
            })

            await this.actionBlockIndex()
            await this.methodSeparateBlockAttrs()

            // if (!this.dataBlocks.length) this.methodStoreReportBlock(this.dataRichTextBlock)

            EventBus.$on('storeBlock', (data) => {
                this.methodStoreReportBlock(data.component, data.index)
            })

            EventBus.$on('editBlock', (data) => {
                this.methodEditComponent(data)
            })

            // EventBus.$on('reorderBlock', (_) => {
            //     this.actionTemplateBlockReorder({ list: this.dataBlockList })
            // })

            EventBus.$on('removeComponent', (data) => {
                this.methodRemoveComponent(data)
            })

            setTimeout(() => {
                this.localLoading = false
            }, 100)
        },

        handleInput(e) {
            const sel = document.getSelection();
            const offset = sel.anchorOffset;
            this.content = e.target.textContent;
            this.$nextTick(() => sel.collapse(sel.anchorNode, offset))
            // this.content = e.target.innerHTML.replace(/(?:^(?:&nbsp;)+)|(?:(?:&nbsp;)+$)/g, '');
        },

        async methodSeparateBlockAttrs () {
            this.dataBlockList
            this.dataBlocks = this.getTemplateBlocks
        },

        methodEditComponent (data) {
            const hasIndex = this.dataBlocks.findIndex(item => item.id === data.id)
            if (hasIndex !== -1) {
                this.dataBlocks[hasIndex] = Object.assign(this.dataBlocks[hasIndex], data)
            }
        },

        methodRemoveComponent (data) {
            // this.dataBlocks = this.dataBlocks.filter(item => item.id !== data.id )
            this.actionFindAndRemoveBlock(data)
            this.actionTemplateBlockDelete({ id: data.id })
        },

        async methodStoreReportBlock (component, index = null) {
            this.dataSearchMode = true
            await this.actionTemplateBlockStore({
                index,
                data: {
                    report_template_id: this.$route.params.template_id,
                    report_block_id: component.id,
                },
                params: { include: 'reportBlock' }
            })
            // setTimeout(() => {
            //     if (index !== null) this.actionTemplateBlockReorder({ list: this.dataBlockList })
            // }, 100)
        },

        async methodUpdateTitle (name) {
             if (!this.$can('document_generator.update')) return
            clearTimeout(this.dataAutoSaveTimeout)
            let editedName = name
            if (!editedName || editedName === '') editedName = 'Draft'
            this.dataAutoSaveTimeout = setTimeout(async () => {
                await this.actionTemplateUpdate({ id: this.$route.params.template_id, name: editedName, view: true })
                if (this.getTemplateResponse.status !== 'success') {
                    this.snackbarStatus = true
                    this.snackbarText = {
                        type: 'title-error',
                        content: this.getTemplateResponse.text
                    }
                }
                clearTimeout(this.dataAutoSaveTimeout)
            }, 1000);
        },

        async medhodCopySuccessMsg (evt, id) {
            // const element = await this.methodGetElement(id)
            // let range, selection
            // if (element) {
            //     if (document.selection) {
            //         range = document.body.createTextRange()
            //         range.moveToElementText(element)
            //         range.select()
            //         // document.execCommand('copy')
            //     } else if (window.getSelection) {
            //         range = document.createRange()
            //         range.selectNode(element)
            //         window.getSelection().removeAllRanges()
            //         window.getSelection().addRange(range)
            //         // document.execCommand('copy')
            //     }
            // }
            this.snackbarStatus = true
            this.snackbarText = {
                type: 'variable',
                content: `Variable ${evt.text} Copied!`
            }
            this.dataDynamicVarDialog = false
        },

        async methodGetElement (id) {
             return document.getElementById(id)
        },

        methodGenerateReport () {
        },

        local_change_slug(slug) {
            return `{{project.${slug}}}`
        },

        local_custom_field_slug(slug, type) {
            if (type === 'richtext') return `{{project.custom_field.rich_text.${slug}}}`
            return `{{project.custom_field.${slug}}}`
        },

        async load_custom_slug_list() {
            await this.tag_section_clear()
            await this.tag_section_index({
                'filter[is_active]': 1,
                'fields[tag_sections]': 'id,slug,name',
            })
            await this.custom_field_value_clear_item()
            await this.custom_field_index({
                'fields[custom_fields]': 'id,name,label,type',
                'filter[is_active]': 1,
                'filter[resource_type]': 'Project',
            })
        },

        ...mapActions('ReportBlock', [
            'actionBlockIndex',
        ]),

        ...mapActions('ReportTemplate', [
            'actionTemplateShow',
            'actionTemplateUpdate',
        ]),

        ...mapActions('ReportTemplateBlock', [
            'actionTemplateBlockStore',
            'actionTemplateBlockEdit',
            'actionTemplateBlockDelete',
            'actionTemplateBlockReorder',
            'actionTemplateBlockAttrsStore',
            'actionTemplateBlockList',
            'actionFindAndRemoveBlock'
        ]),

        ...mapActions('TagSection', {
            tag_section_index: 'index',
            tag_section_clear: 'clear',
        }),

        ...mapActions('CustomField', {
            custom_field_index: 'index',
            custom_field_value_clear_item: 'clear',
        }),
    }
}
</script>


<style lang="scss" scoped>
    /* .c-link {
        color: #5d5d5d !important;
    }
    .c-current-route {
        color: #d64b4b !important;
    } */
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
        flex-basis: 100% !important;
        max-width: 100% !important;
    }
    .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    }
    .list-group {
        min-height: 20px;
    }
    .list-group-item {
        cursor: move;
    }
    .list-group-item i {
        cursor: pointer;
    }
    .c-link {
        color: #5d5d5d !important;
        .a-icon {
            color: #8f8f8f !important;
            // color: #bfbfbf !important;
            // visibility: hidden !important;
        }
    }
    .c-current-route {
        color: #0e0d0d !important;
        .a-icon {
            color: #ce0707 !important;
        }
    }
    .c-menu {
        height: 100%;
        /* background: #e0e0e0; */
        padding: 0 24px;
    }
    .c-radius {
        border-radius: 8px !important;
    }
    .router-link-exact-active.router-link-active {
        color: grey;
        pointer-events: none
    }
    .c-edit-name-input {
        transition: background 0.4s linear;
        &:focus {
            background: rgba($color: #000000, $alpha: 0.03) !important;
            transition: background 0.4s linear;
        }
    }
    table {
        border-collapse: collapse;
    }
    td, th {
        border: 1px solid #dddddd;
    }
</style>

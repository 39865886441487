import axios from 'axios'

const getHostName = window.location.hostname.split('.')[0]

export default function generateBaseUrl (baseUrl, scope = 'internal', credentials = true, host = true) {
    const Axios = axios.create({
        baseURL: `${baseUrl}/${getHostName}/${scope}`,
        withCredentials: credentials,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    })
    Axios.CancelToken = axios.CancelToken
    Axios.isCancel = axios.isCancel

    return Axios
}

<template>
    <div class="px-6 py-5 u-flex-center-y" v-on="$listeners">
        <div>
            <template v-if="groupedBy === 'project_id'">
                <p class="md-body-2 mb-1 grey--text">
                    {{ (tasks[0].project && tasks[0].project.customer) ? tasks[0].project.customer.name : '–' | truncateText(30) }}
                    <span class="px-1">•</span>
                    <span v-if="tasks[0].project && tasks[0].project.stage">{{ tasks[0].project.stage.name | truncateText(30) }}</span>
                    <span v-else class="text-capitalize">{{ tasks[0].project.status | truncateText(30) }}</span>
                </p>
                <v-hover v-slot="{ hover }">
                    <span :class="[ hover ? 'blue--text text-darken-2': 'grey--text text--darken-3', 'md-heading-6 u-cursor-pointer']" @click.stop="localGoTo(tasks[0].project_id)">{{ (tasks[0].project) ? tasks[0].project.title : '–' | truncateText(80) }}</span>
                </v-hover>
            </template>

            <template v-if="!['project_id', 'ungrouped'].includes(groupedBy)">
                <div>
                    <p class="md-body-2 mb-2 grey--text">{{ groupedBy === 'due_date' ? 'Due' : 'Start' }} Date</p>
                    <g-moment tag="h2" v-if="header !== 'null'" class="md-heading-6" :value="header" input-format="YYYY-MM-DD" output-format="MMMM DD, YYYY"></g-moment>
                    <h2 class="md-heading-6" v-else>No {{ groupedBy === 'due_date' ? 'Due' : 'Start' }} Date</h2>
                </div>
            </template>

            <template v-if="groupedBy === 'ungrouped'">
                <h4 class="grey--text text--darken-3 md-heading-6">Showing all {{ tasksCount || 0 }} tasks</h4>
            </template>
        </div>

        <template v-if="groupedBy !== 'ungrouped'">
            <a-spacer></a-spacer>
            <div v-if="tasksCount" class="grey--text text--darken-3 md-heading-6 mr-4">
                {{ tasksCount }} Tasks
            </div>
            <div v-if="tasksCount">
                <a-btn small icon class="ma-0 grey lighten-1" width="24" height="24">
                    <a-icon v-if="isClosed" @click.stop="$emit('collapse')" size="18" color="white">expand_less</a-icon>
                    <a-icon v-else @click.stop="$emit('expand')" size="18" color="white">expand_more</a-icon>
                </a-btn>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        tasks: {
            type: Array
        },
        tasksCount: {
            type: Number
        },
        groupedBy: {
            type: String
        },
        header: {
            type: String
        },
        isClosed: {
            type: Boolean
        },
    },

    methods: {
        localGoTo (project_id) {
            const route = this.$router.resolve({ name: 'projects-tasks', params: { id: project_id } })
            window.open(route.href, '_blank')
        },
    }
}
</script>

<template>
            <!-- overlay ?
                { backgroundImage: 'linear-gradient(rgb(255, 255, 255), rgb(243 239 239), rgb(228 228 228), rgb(205 205 205), rgb(170 170 170))' } :
                flat ?
                { backgroundImage: 'linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(248, 248, 248, 1), rgb(243, 241, 241), rgb(225, 225, 225))' } :
                { backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7), rgba(248, 248, 248, 0.9), rgba(243, 241, 241, 1), rgb(225, 225, 225))' } -->
    <div
        class="u-absolute u-wfull u-rounded-corners-xl u-overflow-hidden"
        :style="[
            { height: height + 'px' },
            flat ?
            { backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.8), rgb(255, 255, 255, 1), rgb(255, 255, 255))' } :
            { backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.8), rgb(255, 255, 255, 0.9), rgb(255, 255, 255))' }
        ]"
        style="
            bottom: 0px;
            left: 0px;
        "
    />
            <!-- background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(248, 248, 248, 0.9), rgb(243, 241, 241), rgb(225, 225, 225)) -->
</template>

<script>
export default {
    props: {
        height: {
            type: String | Number,
            default: '48'
        },
        flat: {
            type: Boolean,
            default: false
        },
        overlay: {
            type: Boolean,
            default: false
        },
    }
}
</script>

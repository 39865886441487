<template>
	<a-main>
       <a-container grid-list-xl class="pa-0 pt-3 mb-5" v-if="$can('templates.update')">
            <a-layout align-start>
                <a-flex shrink>
                    <a-card class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 u-cursor-pointer" @click="local_switch_router()" exact>
                        <a-card class="px-3 py-0" min-height="28" style="padding-top: 2px !important">
                            <a-icon size="16" class="ma-0" color="grey darken-2">arrow_back</a-icon>
                        </a-card>
                    </a-card>
                </a-flex>
                <a-flex shrink pb-0 class="pl-0" v-if="$can('templates.update')">
                    <a-btn small dark color="blue" class="ma-0 u-rounded-corners u-elevation-custom-1" @click="local_new_field_focus()">Create New Task</a-btn>
                </a-flex>
                <a-flex shrink pb-0 class="pl-0" v-if="$can('templates.update')">
                    <a-btn small dark color="blue" class="ma-0 u-rounded-corners u-elevation-custom-1" @click="local_template_milestone_create()">Create New Milestone</a-btn>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink pb-0 class="pl-0" v-if="local_master_template_id">
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <span v-on="on" class="md-body-2 ma-0 pa-0 blue--text">
                                <a-icon size="20" class="u-icon-nudge blue--text pr-1" style="top: 0px;">link</a-icon>
                                Linked with master template
                            </span>
                        </template>
                        <span>Any changes done here will reflect in master template</span>
                    </a-tooltip>
                </a-flex>
                <a-flex shrink pb-0 class="pl-0">
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-btn small icon text color="neutral" class="ma-0 right" @click="local_accordion_milestone_toggle_all(0)" v-if="accordion_milestone_all" v-on="on" :disabled="template_milestone_list && template_milestone_list.length === 0">
                                <a-icon size="18" color="neutral darken-4">unfold_less</a-icon>
                            </a-btn>
                            <a-btn small icon text color="neutral" class="ma-0 right" @click="local_accordion_milestone_toggle_all(1)" v-else v-on="on" :disabled="template_milestone_list && template_milestone_list.length === 0">
                                <a-icon size="18" color="neutral darken-4">unfold_more</a-icon>
                            </a-btn>
                        </template>
                        <span>Expand/Collapse All</span>
                    </a-tooltip>
                </a-flex>
            </a-layout>
            <a-layout wrap align-start>
                <a-flex xs12 class="pb-0 mr-2">
                    <a-form @submit.prevent="local_task_group_update()">
                        <a-text-field
                            v-model="template_group_item.name"
                            v-if="template_group_item"
                            :loading="local_loading"
                            :disabled="!$can('templates.update')"
                            :readonly="!$can('templates.update')"
                            class="md-heading-5 u-elevation-custom-1 u-rounded-corners my-2"
                            solo flat hide-details clearable
                        >
                            <template v-slot:append>
                                <span class="md-body-2 grey--text text--darken-2 grey lighten-3 pa-2 u-rounded-corners u-cursor-pointer" @click="local_task_group_update()">Enter to Save</span>
                            </template>
                        </a-text-field>
                    </a-form>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_group_response.server && template_group_response.server.errors && template_group_response.server.errors.name">
                        <a-icon size="20" color="red darken-2">warning</a-icon>
                        {{ template_group_response.server.errors.name[0] }}
                    </span>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container grid-list-xl class="pa-6 pt-0 px-0">
            <a-layout align-start wrap v-if="template_milestone_list.length > 0">
                <draggable v-model="template_milestone_list" handle=".js-drag-handle" style="width: 100%;" :options="{handle: '.js-drag-handle'}"  draggable=".js-draggable-file-list-item">
                    <a-flex xs12 class="my-0" v-for="(milestone, index) in template_milestone_list" :key="milestone.id"  :class="{ 'js-draggable-file-list-item': $can('templates.update') }">
                        <div>
                            <a-card class="u-elevation-custom-1 u-cursor-pointer mb-2" :class="{ 'yellow lighten-4': milestone.visibility === 'external' }">
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl class="py-3 px-6">
                                        <a-layout align-center>
                                            <a-flex shrink class="px-0" :class="{'u-opacity-30': !$can('templates.update')}">
                                                <a-icon class="grey--text text--lighten-1" :class="{'js-drag-handle': !loading}">drag_indicator</a-icon>
                                            </a-flex>
                                            <a-flex xs7 xl9 @click="local_accordion_milestone_toggle(index)">
                                                <h3 class="md-subtitle-1 font-weight-bold primary--text text--darken-2 u-flex align-center">
                                                    {{ milestone.title }}
                                                    <a-icon class="ml-1" size="18" v-if="local_accordion_milestone_status(index)">keyboard_arrow_down</a-icon>
                                                    <a-icon class="ml-1" size="18" v-else>keyboard_arrow_up</a-icon>
                                                </h3>
                                            </a-flex>
                                            <a-spacer></a-spacer>
                                            <a-flex shrink v-if="$can('templates.update')">
                                                <a-menu bottom right offset-y transition="slide-y-transition" max-height="300" min-width="320" max-width="320">
                                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on" class="md-body-2 u-border pl-2 u-rounded-corners grey--text text--darken-3 d-inline-block">Copy to <a-icon>arrow_drop_down</a-icon></span>
                                                    </template>
                                                    <a-list class="u-list-condensed">
                                                        <template v-for="task_group in template_group_list">
                                                            <a-list-item :key="task_group.id"  @click="local_copy_template_milestone_group(task_group.id, milestone)">
                                                                <a-list-item-title class="md-body-2" :title="task_group.name">
                                                                    {{ task_group.name }}
                                                                </a-list-item-title>
                                                            </a-list-item>
                                                        </template>
                                                    </a-list>
                                                </a-menu>
                                            </a-flex>
                                            <a-flex shrink v-if="template_group_list.length > 1 && $can('templates.update')">
                                                <a-menu bottom left offset-y transition="slide-y-transition" max-height="300" min-width="320" max-width="320">
                                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on" class="md-body-2 u-border pl-2 u-rounded-corners grey--text text--darken-3 d-inline-block">Move to <a-icon>arrow_drop_down</a-icon></span>
                                                    </template>
                                                    <a-list class="u-list-condensed">
                                                        <template v-for="task_group in template_group_list">
                                                            <a-list-item :key="task_group.id" v-if="local_template_task_id !== task_group.id" @click="local_change_template_milestone_group(task_group.id, milestone)">
                                                                <a-list-item-title class="md-body-2" :title="task_group.name">
                                                                    {{ task_group.name }}
                                                                </a-list-item-title>
                                                            </a-list-item>
                                                        </template>
                                                    </a-list>
                                                </a-menu>
                                            </a-flex>
                                            <a-flex shrink px-0 pr-1 v-if="$can('templates.update')">
                                                <a-btn color="grey lighten-4" v-if="$can('templates.update')" class="ma-0" small icon @click="local_template_milestone_edit(milestone.id)">
                                                    <a-icon size="14" color="grey darken-1">edit</a-icon>
                                                </a-btn>
                                            </a-flex>
                                            <a-flex shrink px-0 pr-1 v-if="$can('templates.destroy')">
                                                <a-menu bottom left offset-y persistent max-width="160">
                                                    <template v-slot:activator="{ on }">
                                                        <a-btn color="grey lighten-4" class="ma-0" small icon v-on="on" :loading="local_has_loading(milestone.id)">
                                                            <a-icon size="14" color="grey darken-1">delete</a-icon>
                                                        </a-btn>
                                                    </template>
                                                    <a-card class="u-rounded-corners u-overflow-hidden u-elevation-custom-1">
                                                        <a-card-text>
                                                            <p class="md-body-2 text-center">All tasks under the milestone will be deleted along with milestone.</p>
                                                            <a-btn color="red darken-1" class="elevation-0" dark block small @click="local_template_milestone_destroy(milestone.id)">Delete</a-btn>
                                                        </a-card-text>
                                                    </a-card>
                                                </a-menu>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-card-text>
                            </a-card>
                        </div>
                        <!-- <a-divider class="grey lighten-5" v-if="!local_accordion_milestone_status(index)"></a-divider> -->
                        <div v-if="!local_accordion_milestone_status(index)">
                            <draggable v-model="milestone.template_tasks" :group="'milestones'" :id="milestone.id" @end="local_template_move" handle=".js-drag-handle" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                                <div v-for="(task) in milestone.template_tasks" :key="task.id" :id="task.id" :class="{ 'js-draggable-file-list-item': $can('templates.update') }">
                                    <a-card class="u-elevation-custom-1 u-cursor-pointer mb-1" style="border-radius: 0px !important;" :class="{ 'yellow lighten-4': task.visibility === 'external' }">
                                        <a-card-text class="pa-0">
                                            <a-container container--fluid grid-list-xl class="py-3 px-6">
                                                <a-layout align-center>
                                                    <a-flex shrink class="px-0" :class="{'u-opacity-30': !$can('templates.update')}">
                                                        <a-icon class="grey--text text--lighten-1 js-drag-handle">drag_indicator</a-icon>
                                                    </a-flex>
                                                    <a-flex :class="{ 'xs10 xl11': !$can('templates.update'), 'xs5 xl7': $can('templates.update') }">
                                                        <h2 class="md-subtitle-1">{{ task.title }}</h2>
                                                    </a-flex>
                                                    <a-spacer></a-spacer>
                                                    <a-flex shrink>
                                                        <g-attachment-count-info
                                                            :count="task.attachments_count"
                                                            fontSize="12"
                                                        />
                                                    </a-flex>
                                                    <a-flex shrink v-if="task.priority && task.priority !== null && task.priority !== '0'">
                                                        <a-chip
                                                            small
                                                            :class="{
                                                                'red lighten-4 red--text text--darken-2': task.priority === 'high',
                                                                'blue lighten-4 blue--text text--darken-2': task.priority === 'low',
                                                                'amber lighten-4 amber--text text--darken-4': task.priority === 'medium'
                                                            }"
                                                        >
                                                            {{ (task.priority === 'high') ? 'High' : '' }}
                                                            {{ (task.priority === 'low') ? 'Low' : '' }}
                                                            {{ (task.priority === 'medium') ? 'Medium' : '' }}
                                                        </a-chip>
                                                    </a-flex>
                                                    <a-flex shrink v-if="$can('templates.update')">
                                                        <a-menu bottom right offset-y transition="slide-y-transition" max-height="300" min-width="300" max-width="300">
                                                            <template v-slot:activator="{ on }">
                                                                <span v-on="on" class="md-body-2 grey lighten-3 pl-2 u-rounded-corners grey--text text--darken-3 d-inline-block">Copy to <a-icon>arrow_drop_down</a-icon></span>
                                                            </template>
                                                            <a-list class="u-list-condensed">
                                                                <template v-for="task_group in template_group_list">
                                                                    <a-list-item :key="task_group.id"  @click="local_copy_template_task_group(task_group.id, task)">
                                                                        <a-list-item-title class="md-body-2" :title="task_group.name">
                                                                            {{ task_group.name }}
                                                                        </a-list-item-title>
                                                                    </a-list-item>
                                                                </template>
                                                            </a-list>
                                                        </a-menu>
                                                    </a-flex>
                                                    <a-flex shrink v-if="template_group_list.length > 1 && $can('templates.update')">
                                                        <a-menu bottom left offset-y transition="slide-y-transition" max-height="300" min-width="300" max-width="300">
                                                            <template v-slot:activator="{ on }">
                                                                <span v-on="on" class="md-body-2 grey lighten-3 pl-2 u-rounded-corners grey--text text--darken-3 d-inline-block">Move to <a-icon>arrow_drop_down</a-icon></span>
                                                            </template>
                                                            <a-list class="u-list-condensed">
                                                                <template v-for="task_group in template_group_list">
                                                                    <a-list-item :key="task_group.id" v-if="local_template_task_id !== task_group.id" @click="local_change_template_task_group(task_group.id, task, milestone.id)">
                                                                        <a-list-item-title class="md-body-2" :title="task_group.name">
                                                                            {{ task_group.name }}
                                                                        </a-list-item-title>
                                                                    </a-list-item>
                                                                </template>
                                                            </a-list>
                                                        </a-menu>
                                                    </a-flex>
                                                    <a-flex shrink px-0 pr-1 v-if="$can('templates.update')">
                                                        <a-tooltip bottom  v-if="task.visibility === 'internal' || task.visibility === null">
                                                            <template v-slot:activator="{ on }">
                                                                <a-btn text icon small color="grey" class="mx-0" v-on="on" :disabled="loading" :loading="local_loading_get(task.id, 'visibility')" @click="$can('templates.update') ? local_visibility_toggle(task.id, task.visibility, milestone.id) : ''">
                                                                    <a-icon size="16">lock</a-icon>
                                                                </a-btn>
                                                            </template>
                                                            <span>Private</span>
                                                        </a-tooltip>
                                                        <a-tooltip bottom v-else>
                                                            <template v-slot:activator="{ on }">
                                                                <a-btn text icon small color="grey" class="mx-0" v-on="on" v-if="!$can('templates.update')">
                                                                    <a-icon size="16">lock_open</a-icon>
                                                                </a-btn>
                                                                <a-btn text icon small color="grey" class="mx-0" v-on="on" v-if="$can('templates.update')" :loading="local_loading_get(task.id, 'visibility')" @click="$can('templates.update') ? local_visibility_toggle(task.id, task.visibility, milestone.id) : ''">
                                                                    <a-icon size="16">lock_open</a-icon>
                                                                </a-btn>
                                                            </template>
                                                            <span>Visible to external collaborator when imported into Project.</span>
                                                        </a-tooltip>
                                                    </a-flex>
                                                    <a-flex shrink px-0 pr-1 v-if="$can('templates.update')">
                                                        <a-btn color="grey lighten-4" v-if="$can('templates.update')" class="ma-0" small icon @click="local_template_milestone_task_edit(task.id, milestone.id)">
                                                            <a-icon size="14" color="grey darken-1">edit</a-icon>
                                                        </a-btn>
                                                    </a-flex>
                                                    <a-flex shrink px-0 pr-1 v-if="$can('templates.destroy')">
                                                        <a-btn color="grey lighten-4" v-if="$can('templates.destroy')" class="ma-0" :loading="local_loading_get(task.id, 'destroy')" small icon @click="local_template_milestone_task_delete(task.id, milestone.id)">
                                                            <a-icon size="14" color="grey darken-1">delete</a-icon>
                                                        </a-btn>
                                                    </a-flex>
                                                </a-layout>
                                            </a-container>
                                        </a-card-text>
                                    </a-card>
                                </div>
                            </draggable>
                            <a-divider class="grey lighten-5"></a-divider>
                            <a-layout align-center v-if="$can('templates.update')">
                                <a-flex class="pt-0">
                                    <div>
                                        <a-text-field
                                            v-model="template_task_grouped_title[milestone.id]"
                                            placeholder="Create new task"
                                            prepend-inner-icon="add"
                                            class="u-elevation-custom-1"
                                            style="border-radius: 0px !important;"
                                            @keypress.enter="local_task_store(template_task_grouped_title[milestone.id], milestone.id)"
                                            solo flat hide-details
                                        ></a-text-field>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_task_response.server && template_task_response.server.errors && template_task_response.server.errors.title && local_template_task_form_mode === 'add' && template_task_item.template_milestone_id == milestone.id">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ template_task_response.server.errors.title[0] }}
                                        </span>
                                    </div>
                                </a-flex>
                            </a-layout>
                        </div>
                    </a-flex>
                </draggable>
            </a-layout>
            <a-layout align-start wrap v-if="(template_milestone_list.length > 0 || template_task_list.length > 0) || !accordion_no_milestone_all">
                <a-flex>
                    <div v-if="template_milestone_list.length > 0 || template_task_list.length > 0">
                        <a-card class="u-elevation-custom-1 u-cursor-pointer mb-2">
                            <a-card-text class="pa-0">
                                <a-container container--fluid grid-list-xl class="py-3 px-6">
                                    <a-layout align-center>
                                        <a-flex shrink class="px-0">
                                            <a-icon class="grey--text text--lighten-1 js-drag-handle u-opacity-30">drag_indicator</a-icon>
                                        </a-flex>
                                        <a-flex @click="accordion_no_milestone_all = !accordion_no_milestone_all">
                                            <h3 class="md-subtitle-1 font-weight-bold primary--text text--darken-2 u-flex align-center">
                                                No Milestone
                                                <a-icon class="ml-1" size="18" v-if="accordion_no_milestone_all">keyboard_arrow_down</a-icon>
                                                <a-icon class="ml-1" size="18" v-else>keyboard_arrow_up</a-icon>
                                            </h3>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-card-text>
                        </a-card>
                    </div>
                    <div v-if="!accordion_no_milestone_all">
                        <draggable v-model="template_task_list" :group="'milestones'" id="ungrouped" @end="local_template_move" handle=".js-drag-handle" :options="{handle: '.js-drag-handle'}"  draggable=".js-draggable-file-list-item">
                            <div v-for="(task) in template_task_list" :key="task.id" :id="task.id" :class="{ 'js-draggable-file-list-item': $can('templates.update') }">
                                <!-- <a-divider v-if="index !== 0" class="u-divider"></a-divider> -->
                                <a-card class="u-elevation-custom-1 u-cursor-pointer mb-1" style="border-radius: 0px !important;" :class="{ 'yellow lighten-4': task.visibility === 'external' }">
                                    <a-card-text class="pa-0">
                                        <a-container container--fluid grid-list-xl class="py-3 px-6">
                                            <a-layout align-center>
                                                <a-flex shrink class="px-0" :class="{'u-opacity-30': !$can('templates.update')}">
                                                    <a-icon class="grey--text" :class="{'js-drag-handle text--lighten-1': !loading, 'text--lighten-3': loading}">drag_indicator</a-icon>
                                                </a-flex>
                                                <a-flex :class="{ 'xs10 xl11': !$can('templates.update'), 'xs5 xl8': $can('templates.update') }">
                                                    <h2 class="md-subtitle-1">{{ task.title }}</h2>
                                                </a-flex>
                                                <a-spacer></a-spacer>
                                                <a-flex shrink>
                                                    <g-attachment-count-info
                                                        :count="task.attachments_count"
                                                        fontSize="12"
                                                    />
                                                </a-flex>
                                                <a-flex shrink v-if="task.priority && task.priority !== null && task.priority !== '0'">
                                                    <a-chip
                                                        small
                                                        :class="{
                                                            'red lighten-4 red--text text--darken-2': task.priority === 'high',
                                                            'blue lighten-4 blue--text text--darken-2': task.priority === 'low',
                                                            'amber lighten-4 amber--text text--darken-4': task.priority === 'medium'
                                                        }"
                                                    >
                                                        {{ (task.priority === 'high') ? 'High' : '' }}
                                                        {{ (task.priority === 'low') ? 'Low' : '' }}
                                                        {{ (task.priority === 'medium') ? 'Medium' : '' }}
                                                    </a-chip>
                                                </a-flex>
                                                <a-flex shrink v-if="$can('templates.update')">
                                                    <a-menu bottom right offset-y transition="slide-y-transition" max-height="300" min-width="320" max-width="320">
                                                        <template v-slot:activator="{ on }">
                                                            <span v-on="on" class="md-body-2 grey lighten-3 pl-2 u-rounded-corners grey--text text--darken-3 d-inline-block">Copy to <a-icon>arrow_drop_down</a-icon></span>
                                                        </template>
                                                        <a-list class="u-list-condensed">
                                                            <template v-for="task_group in template_group_list">
                                                                <a-list-item :key="task_group.id" @click="local_copy_template_task_group(task_group.id, task)">
                                                                    <a-list-item-title class="md-body-2" :title="task_group.name">
                                                                        {{ task_group.name }}
                                                                    </a-list-item-title>
                                                                </a-list-item>
                                                            </template>
                                                        </a-list>
                                                    </a-menu>
                                                </a-flex>
                                                <a-flex shrink v-if="template_group_list.length > 1 && $can('templates.update')">
                                                    <a-menu bottom left offset-y transition="slide-y-transition" max-height="300" min-width="320" max-width="320">
                                                        <template v-slot:activator="{ on }">
                                                            <span v-on="on" class="md-body-2 grey lighten-3 pl-2 u-rounded-corners grey--text text--darken-3 d-inline-block">Move to <a-icon>arrow_drop_down</a-icon></span>
                                                        </template>
                                                        <a-list class="u-list-condensed">
                                                            <template v-for="task_group in template_group_list">
                                                                <a-list-item :key="task_group.id" v-if="local_template_task_id !== task_group.id" @click="local_change_template_task_group(task_group.id, task)">
                                                                    <a-list-item-title class="md-body-2" :title="task_group.name">
                                                                        {{ task_group.name }}
                                                                    </a-list-item-title>
                                                                </a-list-item>
                                                            </template>
                                                        </a-list>
                                                    </a-menu>
                                                </a-flex>
                                                <a-flex shrink px-0 pr-1 v-if="$can('templates.update')">
                                                    <a-tooltip bottom  v-if="task.visibility === 'internal' || task.visibility === null">
                                                        <template v-slot:activator="{ on }">
                                                            <a-btn text small icon color="grey" class="mx-0" v-on="on" :disabled="loading" :loading="local_loading_get(task.id, 'visibility')" @click="$can('templates.update') ? local_visibility_toggle(task.id, task.visibility) : ''">
                                                                <a-icon size="16">lock</a-icon>
                                                            </a-btn>
                                                        </template>
                                                        <span>Private</span>
                                                    </a-tooltip>
                                                    <a-tooltip bottom v-else>
                                                        <template v-slot:activator="{ on }">
                                                            <a-btn text icon small color="grey" class="mx-0" v-on="on" v-if="!$can('templates.update')">
                                                                <a-icon size="16">lock_open</a-icon>
                                                            </a-btn>
                                                            <a-btn text icon small color="grey" class="mx-0" v-on="on" v-if="$can('templates.update')" :loading="local_loading_get(task.id, 'visibility')" @click="$can('templates.update') ? local_visibility_toggle(task.id, task.visibility) : ''">
                                                                <a-icon size="16">lock_open</a-icon>
                                                            </a-btn>
                                                        </template>
                                                        <span>Visible to external collaborators when imported into project.</span>
                                                    </a-tooltip>
                                                </a-flex>
                                                <a-flex shrink px-0 pr-1 v-if="$can('templates.update')">
                                                    <a-btn color="grey lighten-4" v-if="$can('templates.update')" class="ma-0" small icon @click="local_template_milestone_task_edit(task.id)">
                                                        <a-icon size="14" color="grey darken-1">edit</a-icon>
                                                    </a-btn>
                                                </a-flex>
                                                <a-flex shrink px-0 pr-1 v-if="$can('templates.destroy')">
                                                    <a-btn color="grey lighten-4" v-if="$can('templates.destroy')" :loading="local_loading_get(task.id, 'destroy')" class="ma-0" small icon @click="local_template_milestone_task_delete(task.id)">
                                                        <a-icon size="14" color="grey darken-1">delete</a-icon>
                                                    </a-btn>
                                                </a-flex>
                                            </a-layout>
                                        </a-container>
                                    </a-card-text>
                                </a-card>
                            </div>
                        </draggable>
                        <a-divider class="grey lighten-5"></a-divider>
                        <a-layout align-center v-if="$can('templates.update')">
                            <a-flex class="pt-0">
                                <div>
                                    <a-text-field
                                        v-model="template_task_title"
                                        placeholder="Create new task"
                                        prepend-inner-icon="add"
                                        class="u-elevation-custom-1"
                                        ref="template_task_new_field"
                                        @keypress.enter="local_task_store(template_task_title)"
                                        solo flat hide-details
                                    ></a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_task_response.server && template_task_response.server.errors && template_task_response.server.errors.title && !template_task_item.template_milestone_id">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_task_response.server.errors.title[0] }}
                                    </span>
                                </div>
                            </a-flex>
                        </a-layout>
                        <a-responsive class="my-4" v-if="!local_loading && (template_milestone_list && !template_milestone_list.length) && (template_task_list && !template_task_list.length)">
                            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                            <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
                        </a-responsive>
                    </div>
                </a-flex>
            </a-layout>
        </a-container>
         <a-dialog v-model="dialog_template_milestone_form" max-width="800" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="40">
                                    <a-icon dark>assistant_photo</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1">{{ template_milestone_form_mode === 'add' ? 'Create New Milestone' : 'Edit Milestone' }}</h2>
                                <p class="md-subtitle-2 mb-0">{{ template_milestone_form_mode === 'add' ? 'Create new milestone to group tasks.' : 'Edit existing milestone and group tasks.'}}</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Milestone Title</label>
                                <a-text-field
                                    v-model="template_milestone_item.title"
                                    placeholder="Enter Title"
                                    background-color="neutral"
                                    solo flat hide-details
                                >
                                </a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_milestone_response.server && template_milestone_response.server.errors && template_milestone_response.server.errors.title">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ template_milestone_response.server.errors.title[0] }}
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16">info</a-icon>
                                    Enter the title of the milestone.
                                </span>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink>
                                <a-btn color="primary" class="ma-0 elevation-0 px-3" :loading="loading" :disabled="loading" @click="local_template_milestone_store()">Save Milestone</a-btn>
                                <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_template_milestone_form = !dialog_template_milestone_form" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
         <a-dialog v-model="dialog_template_task_form" max-width="800" persistent scrollable>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="45">
                                    <a-icon dark >check_circle</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1">Edit existing</h2>
                                <p class="md-subtitle-2 mb-0">Edit existing milestone and group task</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6 u-relative"
                        @dragenter="$can('templates.update') && !localAttachmentsUpdating ? localHandleDragEnter() : ''"
                        @drop="localHandleDrop"
                    >
                        <SDragOverlay
                            v-if="filesDraggedOver"
                            pageType="internal--dialog"
                            @leave="localHandleLeave()"
                            @enter="hoveredOnInfo=false"
                            @enterInfo="hoveredOnInfo=true"
                        >
                        </SDragOverlay>
                        <a-flex xs12>
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title</label>
                                    <a-text-field
                                        v-model="template_task_item.title"
                                        placeholder="Add new task title"
                                        background-color="neutral"
                                        :disabled="localAttachmentsUpdating"
                                        solo flat hide-details
                                    ></a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_task_response.server && template_task_response.server.errors && template_task_response.server.errors.title && local_template_task_form_mode === 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_task_response.server.errors.title[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Enter the title of the task.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Priority</label>
                                    <a-autocomplete
                                        v-model="template_task_item.priority"
                                        :items="priorityList"
                                        item-text="name"
                                        item-value="value"
                                        placeholder="Select priority"
                                        background-color="neutral"
                                        :disabled="localAttachmentsUpdating"
                                        solo flat hide-details
                                    >
                                    </a-autocomplete>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_task_response.server && template_task_response.server.errors && template_task_response.server.errors.priority && local_template_task_form_mode === 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_task_response.server.errors.priority[0] }}
                                    </span>
                                    <span v-else class="md-caption grey--text text--darken-2 d-block mt-2">
                                        <a-icon size="16">info</a-icon>
                                        Enter the priority of the task.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Description (Optional)</label>
                                    <g-rich-text-editor
                                        v-model="template_task_item.description_json"
                                        placeholder="Add description..."
                                        :canUpdate="$can('templates.update')"
                                        :class="[{ 'grey lighten-4': !isEditorFocused }]"
                                        :refresh="refreshDesc"
                                        :hideFocus="localAttachmentsUpdating"
                                        :readOnly="localAttachmentsUpdating"
                                        @update="localUpdateDesc"
                                        @isFocused="!localAttachmentsUpdating ? evt => isEditorFocused = evt : ''"
                                        formatterStickyZero
                                    ></g-rich-text-editor>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                        <a-icon size="16">info</a-icon>
                                        Enter the description of the task.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <SAttachmentSection
                                        v-if="dialog_template_task_form"
                                        :list="attachment_local_list"
                                        :dropTransferList="localDroppedFilesList"
                                        :sourceId="template_task_item.id"
                                        :updateLoading="localAttachmentsUpdating"
                                        :loading="mixinIsLoading('attachment')"
                                        :canUpdate="$can('templates.update')"
                                        sourceType="Task"
                                        @dropTransfer="localUpdateAttachmentTransfer"
                                        @loadMore="localLoadMoreAttachments()"
                                        @remove="localRemoveAttachmentItem"
                                        @delete="localDeleteAttachmentItem"
                                        flat
                                    >
                                    </SAttachmentSection>
                                </a-flex>
                            </a-layout>
                        </a-flex>
                    </a-container>
                </a-card-text>
                <a-divider></a-divider>
                <a-card-actions class="pa-6">
                    <a-container container--fluid grid-list-xl>
                        <a-layout wrap align-center>
                            <a-flex xs12 pa-0>
                                <a-btn color="primary" class="ma-0 elevation-0 px-3" :loading="localAttachmentsUpdating" @click="local_template_task_update()">Save Task</a-btn>
                                <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_template_task_close()" :disabled ="loading || localAttachmentsUpdating">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-actions>
            </a-card>
        </a-dialog>
        <a-snackbar v-model="snackbar_status" color="green darken-3" class="text-center" :timeout="4000" bottom center>
            <div class="text-center" style="width: 100%">{{snackbar_text}}</div>
        </a-snackbar>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Base64 } from 'js-base64'
import SDragOverlay from '@/components/Attachments/SharedDragOverlay.vue'
import { SAttachmentSection } from '@/config/config-shared-components'
import mixinAttachmentsInternal from '@/mixins/mixin-internal-attachment'
import mixinLoader from '@/mixins/mixin-module-loading-setup'

export default {
    components: { SAttachmentSection, SDragOverlay },

    mixins: [ mixinLoader, mixinAttachmentsInternal ],

    data () {
        return {
            priorityList: [
                { name: 'No Priority', value: null},
                { name: 'High', value: 'high' },
                { name: 'Medium', value: 'medium' },
                { name: 'Low', value: 'low'},
            ],
            edit_mode: false,
            timer_update_debounce: null,
            update_autosaved_at: null,
            template_task_grouped_title: [],
            accordion_template_task_description_expanded: true,
            dialog_template_milestone_form: false,
            dialog_template_task_form: false,
            template_task_title: null,
            local_template_task_form_mode: 'add',
            accordion_milestone: [],
            accordion_milestone_all: false,
            accordion_no_milestone_all: false,
            snackbar_text: '',
            snackbar_status: false,
            local_loading: false,
            isEditorFocused: false,
            loading_item: [],
            loading_action: null,
            local_template_group_name: null,
            refreshDesc: false,

            //Attachments
            filesDraggedOver: false,
            hoveredOnInfo: false,
            FILTER: {
                parent_id: 'filter[parent_id]',
                parent_type: 'filter[parent_type]',
                source_id: 'filter[source_id]',
                source_type: 'filter[source_type]',
                fields: 'fields[attachments]',
                page: 'page'
            },
            attachment_fields: 'id,name,size,extension,order,source_id,source_type,parent_id,parent_type',
            localDroppedFilesList: [],
            localAttachmentFilters: {},
            backupAttachmentList: [],
        }
    },

    mounted() {
        this.local_index()
    },

    beforeDestroy() {
        this.localRemoveListeners()
    },

    watch: {
        dialog_template_task_form (val) {
            if (val) return this.localLoadTaskAttachmentData()
            this.loalUpdateTaskAttachmentCount()
            this.template_task_clear_item()
        }
    },

    computed: {
        template_milestone_list: {
            get() {
                return this.$store.state.TemplateMilestone.list
            },
            async set(list) {
                await this.template_milestone_reorder({list: list})
            }
        },

        template_task_list: {
            get() {
                return this.$store.state.TemplateTask.list
            },
            async set(list) {
                await this.template_task_reorder({list: list})
            }
        },

        local_current_task_group() {
            return this.template_group_list.find(group => group.id === this.local_template_task_id)
        },

        local_template_task_id() {
            return this.$route.params.system_group_id
        },

        local_master_template_id() {
            return this.$route.params.master_template_id
        },

        localCheckAttachmentUpdate () {
            return !!_.size(this.attachment_upload_list) || !!_.size(this.attachment_delete_list)
        },

        localAttachmentsUpdating () {
            return this.mixinIsLoading('attachment-update') || this.mixinIsLoading('attachment-delete')
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('TemplateTask', {
            template_task_item: 'item',
            template_task_filters: 'filters',
            template_task_response: 'response',
            template_task_form_mode: 'form_mode',
        }),

        ...mapState('TemplateMilestone', {
            template_milestone_item: 'item',
            template_milestone_filters: 'filters',
            template_milestone_response: 'response',
            template_milestone_form_mode: 'form_mode',
        }),

        ...mapState('TemplateGroup', {
            template_group_list: 'list',
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('Attachment', {
            attachment_list: 'list',
            attachment_local_list: 'local_list',
            attachment_delete_list: 'delete_list',
            attachment_upload_list: 'upload_list',
            attachment_meta: 'meta',
            attachment_response: 'response',
            attachment_delete_response: 'delete_response',
        }),
    },

    methods: {
        async local_index() {
            if (this.$can('templates.show') == false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.local_loading = true
            this.template_group_index({
                'filter[type]': 'tasks',
                'fields[template_groups]': 'id,name',
                'sort': 'order'
            })

            await this.template_group_show({
                id: this.local_template_task_id,
                'fields[template_groups]': 'id,name',
            })

            if (this.$can('templates.update')) this.localAddListeners()
            this.template_milestone_clear()
            this.template_task_clear()

            await this.local_load_task_view_list()
            this.local_loading = false
            this.edit_mode = false
        },

        async localLoadTemplateTaskAttachments () {
            this.mixinSetLoading('attachment')
            this.attachment_clear_response()
            this.attachment_clear_upload_list()
            this.localSetAttachmentFilters()
            await this.attachment_index({ params: this.localAttachmentFilters })
            this.mixinUpdateLocalAttachmentList({ list: this.attachment_list })
            this.mixinResetLoading('attachment')
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },
        localSetAttachmentFilters () {
            this.localAttachmentFilters[this.FILTER.parent_id] = this.template_task_item.template_group_id
            this.localAttachmentFilters[this.FILTER.parent_type] = 'TemplateGroup'
            this.localAttachmentFilters[this.FILTER.source_id] = this.template_task_item.id
            this.localAttachmentFilters[this.FILTER.source_type] = 'TemplateTask'
            this.localAttachmentFilters[this.FILTER.fields] = this.attachment_fields
            this.localAttachmentFilters.count = 5
        },

        async local_load_task_view_list() {
            this.template_milestone_filters.template_group_id = this.local_template_task_id
            await this.template_milestone_index({
                include: 'templateTasks',
                'filter[template_group_id]': this.local_template_task_id,
                'fields[template_milestones]': 'id,title,order,template_group_id,visibility',
                'fields[template_tasks]': 'id,title,description_json,order,template_milestone_id,visibility,template_group_id,priority',
                'sort': 'order',
                'withTemplateTaskAttachmentsCount': 1
            })
            await this.local_load_template_task_list()
        },

        async local_template_group_show(id) {
            await this.template_group_show({
                'fields[template_groups]': 'id,name,order,type,created_at,updated_at,created_by_id,updated_by_id',
                'include': 'templateUsecasesCount,templateSuccessCriteriaCount,templateTestcaseCount,created_by,updated_by',
                'sort': 'order',
                'id': id,
            })
        },

        async local_task_group_update() {
            if(this.$can('templates.update')) {
                await this.template_group_update({ ...this.template_group_item })
            }
        },

        local_switch_router() {
            if(this.local_master_template_id) {
                this.$router.push({name: 'master-templates-tasks', params:  { id : this.local_master_template_id }})
            }else{
                this.$router.push({name: 'master-templates-system-list-tasks'})
            }
        },

        local_load_template_task_list() {
            this.template_task_filters.template_group_id = this.local_template_task_id
            this.template_task_filters.template_milestone_id = null
            this.template_task_index({
                'filter[template_group_id]': this.local_template_task_id,
                'fields[template_tasks]': 'id,title,description_json,order,template_milestone_id,visibility,template_group_id,priority',
                'filter[template_milestone_id]': 'none',
                'sort': 'order',
                'include': 'attachmentsCount'
            })
        },

        local_new_field_focus() {
            this.accordion_no_milestone_all = false
            this.$nextTick(() => {
                this.$refs.template_task_new_field.ref.focus();
            })
        },

        async local_template_move(event) {
            await this.template_task_show({id: event.item.id, params: { 'include': 'attachmentsCount' }})
            this.template_task_item.template_milestone_id = (event.to.id === 'ungrouped') ? null : event.to.id
            delete this.template_task_item.order;
            await this.template_task_update(this.template_task_item)

            if (event.from.id !== 'ungrouped') {
                let milestone = _.find(this.template_milestone_list, {id: event.from.id})
                await this.template_milestone_task_reorder({list: milestone.template_tasks})
                await this.template_milestone_show({ id: event.from.id,params: { 'include': 'templateTasks', 'withTemplateTaskAttachmentsCount': 1 } })
            }

            if (event.to.id !== 'ungrouped') {
                let milestone = _.find(this.template_milestone_list, {id: event.to.id})
                await this.template_milestone_task_reorder({list: milestone.template_tasks})
                await this.template_milestone_show({id: event.to.id, params: { 'include': 'templateTasks', 'withTemplateTaskAttachmentsCount': 1 } })
            }
        },

        async local_template_milestone_task_edit(task_id = null, milestone_id = null) {
            this.template_task_clear_item()
            this.local_template_task_form_mode = 'edit'
            this.template_task_item.template_milestone_id = milestone_id
            if(!milestone_id) await this.template_task_select({id: task_id})
            if(milestone_id) await this.template_milestone_task_select({task_id: task_id, milestone_id: milestone_id})
            this.localRefreshDesc()
            this.dialog_template_task_form = true
        },

        async local_template_milestone_task_delete(task_id, milestone_id = null) {
            this.local_loading_set(task_id, 'destroy')
            await this.template_task_destroy({id: task_id})
            if(milestone_id) this.template_milestone_task_destroy({task_id: task_id, milestone_id: milestone_id})
        },

        localUpdateDesc (content) {
            this.template_task_item.description_json = content.value
        },

        async local_template_task_update() {
            await this.localUpdateTemplateTaskAttachment()
            await this.template_task_update({ ...this.template_task_item })
            if(this.template_task_response.status !== 'success') return
            const task_item = {...this.template_task_item}
            if(task_item.template_milestone_id) this.template_milestone_task_update(task_item)
            this.local_template_task_form_mode = 'add'
            if (!this.localAttachmentsUpdating) this.dialog_template_task_form = false
        },

        local_validate_json(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },

        local_template_task_close() {
            this.dialog_template_task_form = false
        },

        local_template_milestone_destroy(id) {
            this.template_milestone_destroy({id:id})
        },

        local_template_milestone_create() {
            this.template_milestone_clear_item()
            this.dialog_template_milestone_form = true
        },

        local_template_milestone_edit(id) {
            this.template_milestone_clear_item()
            this.template_milestone_select({id: id})
            this.dialog_template_milestone_form = true
        },

        async local_template_milestone_store() {
            this.template_milestone_item.template_group_id = this.local_template_task_id

            if(this.template_milestone_form_mode === 'add') {
                await this.template_milestone_store({
                    ...this.template_milestone_item
                })
            }
            if(this.template_milestone_form_mode === 'edit') {
                await this.template_milestone_update({
                    ...this.template_milestone_item
                })
            }

            if(this.template_milestone_response.status !== 'success') return
            this.dialog_template_milestone_form = false
            this.template_milestone_clear_item()
        },

        async local_task_store(task_title = null, milestone_id = null) {
            await this.template_task_clear_item()
            this.template_task_item.title = task_title
            this.template_task_item.priority = null
            this.template_task_item.template_milestone_id = milestone_id
            this.template_task_item.template_group_id = this.local_template_task_id
            this.template_task_item.visibility = 'internal'

            if (!milestone_id) delete this.template_task_item.template_milestone_id
            await this.template_task_store({
                ...this.template_task_item
            })

            if (this.template_task_response.status !== 'success') return
            if (milestone_id) {
                this.template_milestone_task_store({ ...this.template_task_item })
                this.template_milestone_show({
                    id : milestone_id,
                    params: { 'include': 'templateTasks', 'withTemplateTaskAttachmentsCount': 1 },
                    'filter[template_group_id]': this.local_template_task_id,
                })
            }
            else this.template_task_title = null
            this.template_task_grouped_title[milestone_id] = null
            this.template_task_clear_item()
        },

        async local_update() {
            await this.template_task_update()
            if (this.template_task_response.status == 'success') {
                this.edit_mode = false
                this.local_clear_debounce_timer(true)
            }
        },

        async local_change_template_task_group(new_group_id, template_task, milestone_id = null) {
            this.snackbar_status = false
            template_task.template_group_id = new_group_id

            this.template_task_select_item({ data: template_task })
            await this.template_task_group_move({ ...template_task })
            if (this.template_task_response.status === 'success') {
                this.local_template_group_show(new_group_id)
                this.local_show_group_message('Task moved successfully')
            }
            if(milestone_id) this.template_milestone_task_destroy({task_id: template_task.id, milestone_id: milestone_id})
        },

        async local_copy_template_task_group(new_group_id, template_task) {
            this.snackbar_status = false
            template_task = _.cloneDeep(template_task)
            template_task.template_group_id = new_group_id
            template_task.old_template_group_id = this.local_current_task_group.id

            await this.template_task_group_copy({
                template_task_id: template_task.id,
                template_group_id: new_group_id,
                old_template_group_id: this.local_current_task_group.id
            })
            if (this.template_task_response.status === 'success') {
                await this.local_template_group_show(new_group_id)
                this.local_show_group_message('Task copied successfully')
            }
        },

        async local_change_template_milestone_group(new_group_id, milestone) {
            this.snackbar_status = false
            milestone.template_group_id = new_group_id

            await this.template_milestone_group_move({ ...milestone })
            this.local_template_group_show(new_group_id)
            // if (this.template_milestone_response.status === 'success') this.local_show_group_message('Milestone moved successfully')
        },

        async local_copy_template_milestone_group(new_group_id, milestone) {
            this.snackbar_status = false
            milestone.template_group_id = new_group_id
            milestone.old_template_group_id = this.local_current_task_group.id

            await this.template_milestone_group_copy({
                template_milestone_id: milestone.id,
                template_group_id: new_group_id,
                old_template_group_id: this.local_current_task_group.id
            })
            if (this.template_milestone_response.status === 'success') {
                this.local_template_group_show(new_group_id)
                this.local_show_group_message('Milestone copied successfully')
            }
        },

        local_show_group_message(text) {
            this.snackbar_text = `${ text }`
            this.snackbar_status = !this.snackbar_status
        },

        async local_template_task_clear() {
            await this.template_task_show({id: this.local_template_task_id,  params: { 'include': 'attachmentsCount' }})
            this.edit_mode = false
        },

        async local_type_update(id) {
            this.template_task_item.type_id = id
            await this.template_task_update()
        },

        async local_update_debounced() {
            this.local_clear_debounce_timer()
            this.timer_update_debounce = setTimeout(this.local_update, 2000)
        },

        async local_clear_debounce_timer(saved = false) {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                if (saved) {
                    this.update_autosaved_at = moment().format('YYYY-MM-DD HH:mm:ss')
                }
            }
        },

        local_accordion_milestone_status(index) {
            if (this.accordion_milestone[index]) {
                return this.accordion_milestone[index]
            } else {
                this.accordion_milestone[index] = false
                return this.accordion_milestone[index]
            }
        },

        local_accordion_milestone_toggle(index) {
            if (this.accordion_milestone[index] === undefined || this.accordion_milestone[index] === null) {
                this.$set(this.accordion_milestone, index, true);
            } else {
                this.$set(this.accordion_milestone, index, !this.accordion_milestone[index]);
            }
        },

        local_accordion_milestone_toggle_all(status = null) {
            this.accordion_milestone_all = !this.accordion_milestone_all
            this.accordion_milestone = this.accordion_milestone.map(x => this.accordion_milestone_all)
            status ? this.accordion_no_milestone_all = true : this.accordion_no_milestone_all = false
        },

        async local_visibility_toggle(id, status,  milestone_id = null) {
            await this.local_loading_set(id, 'visibility')
            await this.template_task_clear_item()
            milestone_id ? await this.template_milestone_task_select({task_id: id, milestone_id: milestone_id}) : await this.template_task_select({id: id})
            this.template_task_item.visibility = status === 'external' ? 'internal' : 'external'
            this.template_task_item.state = status === 'external' ? 'internal' : 'external'
            await this.template_task_visibility(this.template_task_item)
            await this.local_loading_clear()
            if (this.template_task_response.status === 'success') {
               if(this.template_task_item.template_milestone_id) {
                   this.template_milestone_task_update(this.template_task_item)
                   this.template_milestone_show({ id : this.template_task_item.template_milestone_id, params: { 'include': 'templateTasks', 'withTemplateTaskAttachmentsCount': 1 }})
                }
                await this.template_task_clear_item()
            }
        },

        async local_loading_set(id, action) {
            this.loading_item.push(id)
            this.loading_action = action
        },

        async local_loading_clear(id) {
            this.loading_item.splice(this.loading_item.indexOf(id), 1)
            this.loading_action = null
        },

        local_has_loading (id) {
            return this.loading_item.includes(id)
        },

        local_loading_get(id, action) {
            return this.loading && this.loading_item == id && this.loading_action == action
        },

        localRefreshDesc () {
            this.refreshDesc = true
            setTimeout(() => {
                this.refreshDesc = false
            }, 100);
        },

        // Attachment CRUD

        localAddListeners () {
            window.addEventListener('beforeunload', this.local_handler_close)
            this.mixinAttachmentListeners('add')
        },

        localRemoveListeners () {
            window.removeEventListener('beforeunload', this.local_handler_close)
            this.mixinAttachmentListeners('remove')
        },

        local_handler_close (e) {
            if (this.localAttachmentsUpdating) {
                e.returnValue = 'Are you sure to close the tab?';
            }
        },

        localHandleDragEnter (e) {
            this.filesDraggedOver = true
        },

        localHandleLeave (e) {
            if (this.hoveredOnInfo) return
            this.filesDraggedOver = false
        },

        localHandleDrop (e) {
            if (this.localAttachmentsUpdating || this.pageLoading) return
            const droppedFiles = Array.from(e.dataTransfer.files)
            if (_.size(droppedFiles) > 5) return this.localValidateAttachmentCount()
            this.localDroppedFilesList = _.cloneDeep(droppedFiles)
            this.filesDraggedOver = false
        },

        localValidateAttachmentCount () {
            this.$notify('warning', 'Sorry, you can only upload up to 5 files at a time')
            this.filesDraggedOver = false
        },

        localRemoveAttachmentItem (id) {
            this.mixinRemoveAttachmentItem(id)
        },

        localDeleteAttachmentItem (id) {
            this.mixinDeleteAttachmentItem(id)
        },

        async localUpdateTemplateTaskAttachment () {
            this.mixinSetLoading('attachment-update')
            this.localRefreshDesc()
            this.attachment_clear_response()
            this.mixinRemoveInvalidFiles()
            await this.localBulkDeleteAttachment()
            await this.localUploadAttachment()
            this.mixinResetLoading('attachment-update')
        },

        async localUploadAttachment () {
            if (!_.size(this.attachment_upload_list)) return

            const parentId = this.template_task_item.template_group_id
            const formData = new FormData();
            formData.append('parent_id', parentId)
            formData.append('parent_type', 'TemplateGroup')
            formData.append('source_id', this.template_task_item.id)
            formData.append('source_type', 'TemplateTask')
            formData.append('order', -1)
            this.attachment_upload_list.forEach(file => {
                formData.append('files[]', file)
                formData.append('ids[]', file.id)
            })
            await this.attachment_upload(formData)

            if (!this.$status(this.attachment_response)) return this.mixinHandleAttachmentErrors()
            this.localShowTemplateTaskAttachments()
            this.attachment_clear_upload_list()
        },

        async localBulkDeleteAttachment () {
            if (!_.size(this.attachment_delete_list)) return
            this.mixinSetLoading('attachment-delete')
            await this.attachment_bulk_destroy({ids: this.attachment_delete_list})
            if (this.$status(this.attachment_delete_response)) {
                if (!_.size(this.attachment_upload_list)) this.localShowTemplateTaskAttachments()
                if (!_.size(this.attachment_local_list) && !_.size(this.attachment_upload_list)) this.localLoadTemplateTaskAttachments()
                this.attachment_clear_delete_list()
            }
            this.mixinResetLoading('attachment-delete')
        },

        async localShowTemplateTaskAttachments () {
            this.localAttachmentFilters.count = _.size(this.attachment_local_list)
            this.localAttachmentFilters.page = 1
            await this.attachment_index({ mode: 'show', params: this.localAttachmentFilters})
            this.mixinUpdateLocalAttachmentList({ list: this.attachment_list})
            this.backupAttachmentList = _.cloneDeep(this.attachment_list)
        },

        loalUpdateTaskAttachmentCount () {
            const task_item = _.cloneDeep(this.template_task_item)
            task_item.attachments_count = this.attachment_meta.total
            this.template_task_update_local(task_item)
            if (task_item.template_milestone_id) this.template_milestone_task_update(task_item)
        },

        localUpdateAttachmentTransfer (params) {
            this.attachment_clear_response()
            const { list, transferFiles } = params || {}
            const totalUploadList = !!_.size(this.attachment_upload_list) ? [...transferFiles, ...this.attachment_upload_list] : [...transferFiles]
            this.mixinPreserveListChanges({ list: list, type: 'drop_transfer' })
            this.attachment_update_upload_list(totalUploadList)
        },

        async localLoadMoreAttachments () {
            if (this.attachment_meta.total === _.size(this.attachment_list)) return

            this.mixinSetLoading('attachment')

            const defaultCount = 5
            const isListShortOfItem = _.size(this.attachment_list)%5 === 0
            const loadType = isListShortOfItem ? 'load_more' : 'index'
            this.localAttachmentFilters.count = isListShortOfItem ? defaultCount : this.attachment_meta.current_page*defaultCount
            this.localAttachmentFilters[this.FILTER.page] = isListShortOfItem ? this.attachment_meta.current_page+1 : 1
            await this.attachment_index({ mode: loadType, params: this.localAttachmentFilters })

            this.mixinResetLoading('attachment')
            this.mixinPreserveListChanges({ list: this.attachment_list, type: 'load_more' })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localClearAttachmentUpdate () {
            this.mixinUpdateLocalAttachmentList({ list: this.backupAttachmentList })
            this.mixinClearAttachmentUpdate()
        },

        localLoadTaskAttachmentData () {
            this.localLoadTemplateTaskAttachments()
        },

        ...mapActions('TemplateTask', {
            template_task_show: 'show',
            template_task_update: 'update',
            template_task_update_local: 'update_local_list',
            template_task_visibility: 'visibility',
            template_task_select: 'select',
            template_task_clear_item: 'clear_item',
        }),

        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_show: 'show',
            template_group_select: 'select',
            template_group_update: 'update',
            template_group_reorder: 'reorder',
            template_group_destroy: 'destroy',
            template_group_clear_item: 'clear_item',
            template_group_clear: 'clear',
        }),

        ...mapActions('TemplateMilestone', {
            template_milestone_index: 'index',
            template_milestone_show: 'show',
            template_milestone_store: 'store',
            template_milestone_update: 'update',
            template_milestone_update_local: 'update_local',
            template_milestone_group_move: 'group_milestone_move',
            template_milestone_group_copy: 'group_copy_store',
            template_milestone_select: 'select',
            template_milestone_task_store: 'task_store',
            template_milestone_task_reorder: 'tasks_reorder',
            template_milestone_task_select: 'task_select',
            template_milestone_task_update: 'task_update',
            template_milestone_task_destroy: 'task_destroy',
            template_milestone_reorder: 'reorder',
            template_milestone_destroy: 'destroy',
            template_milestone_clear_item: 'clear_item',
            template_milestone_clear : 'clear',
        }),

         ...mapActions('TemplateTask', {
            template_task_index: 'index',
            template_task_show: 'show',
            template_task_store: 'store',
            template_task_group_move: 'group_task_move',
            template_task_group_copy: 'group_copy_store',
            template_task_select_item: 'select_item',
            template_task_complete: 'complete',
            template_task_uncomplete: 'uncomplete',
            template_task_client_visible: 'client_visible',
            template_task_client_invisible: 'client_invisible',
            template_task_update: 'update',
            template_task_select: 'select',
            template_task_upgrade: 'upgrade',
            template_task_downgrade: 'downgrade',
            template_task_destroy: 'destroy',
            template_task_reorder: 'reorder',
            template_task_clear: 'clear',
            template_task_clear_item: 'clear_item',
        }),

        ...mapActions('Attachment', {
            attachment_index: 'index',
            attachment_upload: 'upload',
            attachment_update_list: 'update_list',
            attachment_update_local_meta: 'update_meta',
            attachment_update_upload_list: 'update_upload_list',
            attachment_remove_upload_item: 'remove_upload_list_item',
            attachment_bulk_destroy: 'bulk_destroy',
            attachment_clear_upload_list: 'clear_upload_list',
            attachment_clear_delete_list: 'clear_delete_list',
            attachment_clear_response: 'clear_response',
        }),
    },
}
</script>

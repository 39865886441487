<template>
    <a-main>
        <g-page-header
            title="Tags"
            :subtitle="`Customize ${page_loading ? 'tag' : local_current_section}s associated with ${local_current_section === 'ticket tag' ? 'tickets':'projects'}.`"
            :breadcrumb-items="breadcrumb_items"
            :loading="loading"
            icon="label"
            class="px-4"
        >
            <template v-slot:action>
                <div class="u-flex align-center">
                    <slot name="visibility" v-if="!page_loading && !local_is_project_system_type">
                        <s-tag-confirmation
                            :loading="localVisibilityLoading"
                            :tag-type="local_current_section_type"
                            :disabled="!check_user_tag_permission('update')"
                            :is-public="localHasVisibility('key', tag_section_item.slug) === 'external-ready-only'"
                            @action="(event) => localVisibilityUpdate(event, tag_section_item)"
                            type="Organization"
                            class="d-inline-block"
                            :class="{'px-3' : !local_is_system_type}"
                            dense
                        ></s-tag-confirmation>
                    </slot>
                    <div class="white u-rounded-corners-lg u-flex align-center u-elevation-custom-1" v-if="!local_is_system_type && tag_section_item">
                        <template v-if="check_user_tag_permission('update')">
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" @click="local_tag_section_toggle_active(tag_section_item.id, !tag_section_item.is_active)" v-ripple style="border-radius: 0px 0px 0px 6px;" class="px-3 py-1 u-cursor-pointer u-rounded-corners-full">
                                        <a-icon size="14" :color="tag_section_item.is_active ? 'green darken-1' : 'grey lighten-1'">fiber_manual_record</a-icon>
                                    </div>
                                </template>
                                <span>{{ tag_section_item.is_active ? 'Enabled' : 'Disabled' }}</span>
                            </a-tooltip>
                            <a-divider vertical></a-divider>
                            <div @click="local_tag_section_edit(tag_section_item.id)" v-ripple class="px-3 py-1 u-cursor-pointer u-rounded-corners-full">
                                <!-- <a-icon size="14" color="grey darken-1">edit</a-icon> -->
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-pencil grey--text text--darken-1" viewBox="0 0 16 16">
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                                </svg>
                            </div>
                        </template>
                        <a-divider vertical v-if="check_user_tag_permission('destroy') && check_user_tag_permission('update')"></a-divider>
                        <template v-if="check_user_tag_permission('destroy')">
                            <a-menu bottom left offset-y max-width="320" v-if="check_user_tag_permission('destroy')">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" v-ripple class="u-cursor-pointer u-rounded-corners-full px-3 py-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-trash grey--text text--darken-1" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                        </svg>
                                    </div>
                                </template>
                                <a-card>
                                    <a-card-text class="pa-4">
                                        <h2 class="md-heading-6 mb-2">Are you sure to delete this custom tag section?</h2>
                                        <p class="md-body-2 mb-4">This will delete the tag section and it's related tags. All projects tagged with this section's tag will remain undeleted.</p>
                                        <a-btn class="ma-0 elevation-0" color="red" @click="local_tag_section_destroy(tag_section_item)" :loading="loading" dark small>
                                            <a-icon size="16" left class="mr-2">delete</a-icon> Delete
                                        </a-btn>
                                    </a-card-text>
                                </a-card>
                            </a-menu>
                        </template>
                    </div>
                </div>
            </template>
        </g-page-header>
        <a-sheet v-if="!page_loading && $can('layout_designer.index') && (local_current_section !== 'ticket tag') && (local_current_section !== 'use case tag')" color="deep-purple lighten-5" height="44" class="pr-3 mx-4 mb-3 mt-1 u-rounded-corners u-flex-center-y u-cursor-pointer" @click="$router.push({ name: 'layout-designer' })">
            <a-sheet color="deep-purple" class="px-3 u-flex-center u-hfull" style="borderRadius: 4px 0px 0px 4px;">
                <a-icon size="18" color="white">space_dashboard</a-icon>
            </a-sheet>
            <h3 class="ml-3 md-subtitle-1 deep-purple--text text--lighten-1 font-weight-medium u-flex align-center">
                Check out our new Layout Designer to customize the "Project
                <a-icon size="16" color="deep-purple lighten-1" class="mx-1">arrow_forward</a-icon>
                Fields & Tags" layout.
            </h3>
            <a-spacer></a-spacer>
            <a-icon size="20" color="deep-purple lighten-1">arrow_forward</a-icon>
        </a-sheet>
        <a-container grid-list-xl container--fluid class="mb-10">
            <a-layout>
                <a-flex pt-0 px-4>
                    <a-card class="u-relative u-elevation-custom-1 u-rounded-corners mb-4 mt-4 pa-0" :class="{ 'mt-3': tag_list && tag_list.length }" v-if="check_user_tag_permission('store')">
                        <a-form @submit.prevent="local_store()">
                            <div>
                                <a-text-field
                                    v-model="tag_label"
                                    ref="refTagLabel"
                                    prepend-inner-icon="add"
                                    append-icon="keyboard_return"
                                    :loading="page_loading"
                                    :placeholder="`Type and hit enter to create tag`"
                                    class="u-rounded-corners"
                                    solo flat hide-details autofocus
                                ></a-text-field>
                                <!-- <a-icon color="grey" size="16">keyboard_return</a-icon> -->
                            </div>
                        </a-form>
                        <span style="z-index: 2; margin-left: 32px;" :class="[tag_response.server.errors.label[0] ? 'c-error-strip__show' : '']" class="c-error-strip u-elevation-custom-1 u-absolute grey lighten-3 px-3 py-1 md-caption red--text text--darken-2 d-block" v-if="tag_response.server && tag_response.server.errors && tag_response.server.errors.label && tag_form_mode == 'add'">
                            <a-icon size="16" color="red darken-2" class="u-icon-nudge">error</a-icon>
                            {{ tag_response.server.errors.label[0] }}
                        </span>
                    </a-card>
                    <template v-if="!page_loading">
                        <draggable v-model="tag_list" :options="{handle: '.js-drag-handle'}">
                            <a-card v-for="tag in tag_list" class="u-elevation-custom-1 mb-1" :class="[check_user_tag_permission('store') || check_user_tag_permission('update') || check_user_tag_permission('destroy') ? '' : 'py-2' ]" :key="tag.id">
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl :class="[check_user_tag_permission('update') ? 'py-4 px-6' : 'py-2 px-4']">
                                        <a-layout wrap align-center>
                                            <a-flex shrink class="pl-0 pr-1" v-if="loader_item == tag.id">
                                                <a-progress-circular color="blue lighten-2" size="20" width="2" indeterminate></a-progress-circular>
                                            </a-flex>
                                            <template v-else>
                                                <a-flex shrink class="px-0" v-if="check_user_tag_permission('update')">
                                                    <a-icon class="grey--text text--lighten-1 u-cursor-pointer js-drag-handle">drag_indicator</a-icon>
                                                </a-flex>
                                            </template>
                                            <a-flex>
                                                <g-tags :tag="tag" :text-range="100" dense hide-clear-icon></g-tags>
                                            </a-flex>
                                            <a-flex shrink v-if="check_user_tag_permission('update')">
                                                <s-color-palette v-model="tag.color" @input="color => local_update_debounced(color, tag)"></s-color-palette>
                                            </a-flex>
                                            <a-flex shrink v-if="check_user_tag_permission('update') || check_user_tag_permission('destroy')">
                                                <a-tooltip bottom v-if="check_user_tag_permission('update')">
                                                    <template v-slot:activator="{ on }">
                                                        <a-btn color="grey darken-2" @click="local_edit(tag.id)" v-on="on" text icon small>
                                                            <a-icon small>edit</a-icon>
                                                        </a-btn>
                                                    </template>
                                                    <span>Edit Tag</span>
                                                </a-tooltip>
                                                <a-menu bottom left offset-y :close-on-content-click="false" max-width="320" v-if="check_user_tag_permission('destroy')">
                                                    <template v-slot:activator="{ on }">
                                                        <a-btn icon text small color="grey darken-2" v-on="on" class="ml-2">
                                                            <a-icon size="16">delete</a-icon>
                                                        </a-btn>
                                                    </template>
                                                    <a-card>
                                                        <a-card-text class="pa-4">
                                                            <h2 class="md-heading-6 mb-2">Are you sure to delete this tag?</h2>
                                                            <p class="md-body-2 mb-4">This will delete just the tag. All {{ $route.params.id === 'ticket_tag' ? 'tickets': 'projects'}} tagged with this tag will remain undeleted.</p>
                                                            <a-btn class="ma-0 elevation-0" color="red" @click="local_destroy(tag.id)" :loading="loading" dark small>
                                                                <a-icon size="16" left class="mr-2">delete</a-icon> Delete
                                                            </a-btn>
                                                        </a-card-text>
                                                    </a-card>
                                                </a-menu>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-card-text>
                            </a-card>
                        </draggable>
                    </template>
                </a-flex>
            </a-layout>
            <a-layout v-if="page_loading">
                <a-flex xs12>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching tags..</h3>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <a-layout v-if="!page_loading && (tag_list && !tag_list.length)">
                <a-flex xs12>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                        <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
                    </a-responsive>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Tag Form -->
        <a-dialog v-model="dialog_tag_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>label</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (tag_form_mode == 'add') ? 'Create Tag' : 'Edit Existing Tag' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (tag_form_mode == 'add') ? 'You can add new tag to the system.' : 'You can edit existing tag.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Label</label>
                                    <a-text-field
                                        v-model="tag_item.label"
                                        placeholder="Enter Label"
                                        background-color="neutral"
                                        solo flat hide-details autofocus
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="tag_response.server && tag_response.server.errors && tag_response.server.errors.label && tag_form_mode == 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ tag_response.server.errors.label[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Enter the label for the tag.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_update()" :loading="loading" :disabled="loading">Save Tag</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_tag_form = !dialog_tag_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!-- Tag Section form -->
        <a-dialog v-model="dialog_tag_section_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>label</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">Edit Custom Tag</h2>
                                    <p class="md-subtitle-2 mb-0">You can edit existing custom tag.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name <span class="red--text text--darken-2">*</span></label>
                                    <a-text-field
                                        v-model="section_item.name"
                                        ref="refCreateTagInput"
                                        placeholder="Enter the name"
                                        background-color="neutral"
                                        solo flat hide-details autofocus
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="tag_section_response.server && tag_section_response.server.errors && tag_section_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">info</a-icon>
                                        {{ tag_section_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the field.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_tag_section_update()" :loading="loading" :disabled="loading">Update Tag</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click.prevent="local_close_dialog()" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                                <a-spacer></a-spacer>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import TagPermission from '@/mixins/mixin-tag-permission'
import { SColorPalette, STagConfirmation } from '@/config/config-shared-components'

export default {
    mixins: [TagPermission],

    data() {
        return {
            tag_label: '',
            dialog_tag_form: false,
            timer_update_debounce: null,
            loader_item: null,
            tag_type: '',
            local_tag_section_name: null,
            system_tag_types: ['label', 'product', 'platform', 'usecase_tag', 'ticket_tag'],
            section_item: {},
            dialog_tag_section_form: false,
            page_loading: true,
            visibilityItem: null,
            localVisibilityLoading: false,
        }
    },

    components: { SColorPalette, STagConfirmation },

    mounted() {
        this.local_index()
    },

    watch:{
        '$route.params.id': {
            handler ()  {
                this.local_index()
            },
            deep: true,
        },

        dialog_tag_form(newValue, oldValue) {
            if (newValue === false) {
                this.tag_clear_item()
            }
        },
    },

    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Types & Statuses', to: '/settings/tags', exact: true})
            return breadcrumbs
        },
        tag_list: {
            get() {
                return this.$store.state.Tag.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },

        local_current_section () {
            const index = this.system_tag_types.indexOf(this.$route.params.id)
            if (this.system_tag_types[index]) {
                const splitTagName = this.system_tag_types[index].split('_').join(' ')
                const isUsecaseTag = splitTagName === 'usecase tag'
                if (index !== -1) return this.system_tag_types[index] === 'usecase_tag' || this.system_tag_types[index] === 'ticket_tag' ? (isUsecaseTag ? 'use case tag' : splitTagName) : this.system_tag_types[index] + ' tag'
            }
            return this.tag_section_item ? this.tag_section_item.name + ' tag' : 'custom tag'
        },

        local_current_section_type () {
            const index = this.system_tag_types.indexOf(this.$route.params.id)
            if (this.system_tag_types[index]) {
                if (index !== -1) return this.system_tag_types[index]
            }
            return this.tag_section_item ? this.tag_section_item.name : 'custom'
        },

        local_is_system_type () {
            return this.system_tag_types.indexOf(this.$route.params.id) !== -1
        },

        local_is_project_system_type() {
            return ['usecase_tag', 'ticket_tag'].includes(this.$route.params.id)
        },

        ...mapGetters('TagSection', {
            tag_section_item: 'item',
            tag_section_response: 'response',
        }),

        ...mapState('Tag', {
            tag_item: 'item',
            tag_filters: 'filters',
            tag_form_mode: 'form_mode',
            tag_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapGetters('Visibility', {
            visibility_get_visibility_item: 'get_list_item',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            this.page_loading = true
            this.local_set_tag_input()
            await this.visibility_clear()
            await this.localFetchVisibility()
            await this.tag_clear()
            await this.local_tag_index()
            if (!this.system_tag_types.includes(this.$route.params.id)) {
                await this.tag_section_show({ id: this.$route.params.id, mode: 'show' })
                this.local_set_section_item()
            }
            await this.local_set_tag_section_name()
            this.page_loading = false
        },

        /* Visibility - Start */

        localHasVisibility (field, value) {
            if (this.system_tag_types.includes(this.$route.params.id)) value =  `project_${this.$route.params.id}`
            const data = this.visibility_get_visibility_item(field, value)
            return !data ? 'internal' : data.visibility
        },

        async localVisibilityUpdate(event,section) {
            this.localVisibilityLoading = true
            await this.localGetVisibilityItem(event,section)
            await this.visibility_upsert_store(this.visibilityItem)
            const data = this.visibility_get_visibility_item('key', this.visibilityItem.key)
            this.localShowVisibility(data.id)
            this.localVisibilityLoading = false
        },

        localGetVisibilityItem(event, section) {
            this.visibilityItem = {}
            this.visibilityItem.resource_type = 'Organization'
            this.visibilityItem.resource_id = this.user_self.organization.id
            this.visibilityItem.section_type = 'TagSection'
            if (this.system_tag_types.includes(this.$route.params.id)) {
                section.slug = null
                this.visibilityItem.key = `project_${this.$route.params.id}`
            }

            if(section.slug) {
                this.visibilityItem.key = section.slug
                this.visibilityItem.section_id = section.id
            }
            const data = this.visibility_get_visibility_item('key', this.visibilityItem.key)
            this.visibilityItem.visibility = event
        },

        async localShowVisibility (id) {
            this.visibility_show({
                'id': id,
                'filter[resource_type]': 'Organization',
                'fields[visibilities]': 'id,key,visibility',
            })
        },

        async localFetchVisibility () {
            this.visibility_index({
                'filter[resource_type]': 'Organization',
                'fields[visibilities]': 'id,key,visibility',
            })
        },

        /* Visibility - End */

        async local_tag_index () {
            if (!this.system_tag_types.includes(this.$route.params.id)) {
                return await this.tag_index({ 'filter[tagSection.id]': this.$route.params.id, 'sort': 'order' })
            }
            await this.tag_index({ 'filter[type]': this.$route.params.id, 'sort': 'order' })
        },

        // Tags CRUD
        async local_create() {
            await this.tag_clear_item()
            this.dialog_tag_form = true
        },

        async local_store() {
            if (!this.tag_label || (this.tag_label && !this.tag_label.trim())) return this.tag_clear_item()
            this.tag_item.label = this.tag_label.trim()
            this.tag_item.type = this.tag_type
            this.tag_item.color = this.$randomColor()
            await this.tag_store({ ...this.tag_item, order: -1, mode: 'only-store' })
            if (this.tag_response.status === 'success') {
                await this.tag_clear_item()
                this.tag_label = ''
                this.dialog_tag_form = false
            }
        },

        async local_edit(id) {
            await this.tag_select({id: id})
            this.dialog_tag_form = true
        },

        async local_update(id = null) {
            if (id !== null) await this.tag_select({id: id})
            await this.tag_update({
                id: this.tag_item.id,
                label: this.tag_item.label.trim() ?? null,
                color: this.tag_item.color,
                type: this.tag_item.type,
            })
            if (this.tag_response.status === 'success') {
                await this.tag_clear_item()
                await this.local_clear_debounce_timer()
                this.dialog_tag_form = false
            }
        },

        async local_destroy(id) {
            await this.tag_destroy({id: id})
        },

        async local_reorder(list) {
            this.tag_reorder({list: list})
        },

        async local_update_debounced(color, tag) {
            this.local_clear_debounce_timer()
            if (color) tag.color = color
            this.loader_item = tag.id
            this.timer_update_debounce = setTimeout(this.local_update, 200, tag.id)
        },

        async local_clear_debounce_timer() {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                this.loader_item = null
            }
        },

        // Tag Section
        async local_tag_section_toggle_active(id, is_active) {
            await this.tag_section_toggle_active({ id: id, state: is_active ? 1 : 0 })
            if (this.tag_section_response && this.tag_section_response.status === 'success') {
                this.$notify('success', 'Updated successfully!')
            }
        },

        async local_tag_section_edit (id) {
            await this.tag_section_clear_item()
            await this.tag_section_select({id: id})
            this.local_set_section_item()
            this.dialog_tag_section_form = true
        },

        async local_tag_section_update () {
            this.section_item.name = this.section_item.name ? this.section_item.name.trim() : null
            await this.tag_section_update({ ...this.section_item })
            if (this.tag_section_response && this.tag_section_response.status === 'success') {
                this.$notify('success', 'Updated successfully!')
                this.local_close_dialog()
            }
        },

        async local_tag_section_destroy ({id}) {
            await this.tag_section_destroy({id: id})
            if (id === this.$route.params.id) this.$router.replace({ name: 'settings-tags-list', params: { id: 'label' }})
            if (this.tag_section_response.status == 'success') this.local_close_dialog()
        },

        async local_close_dialog () {
            this.dialog_tag_section_form = false
        },

        // Extras
        local_set_tag_input () {
            this.tag_label = ''
            setTimeout(() => this.$refs.refTagLabel.ref.focus(), 400);
        },

        local_set_tag_section_name() {
            const meta_type = (this.$route.meta.slug && this.$route.meta.slug.name) ? this.$route.meta.slug.name : this.section_item.slug
            this.tag_type = this.system_tag_types.includes(this.$route.params.id) ? this.$route.params.id : meta_type

            if (this.system_tag_types.includes(this.tag_type)) this.local_tag_section_name = this.tag_type
            if (this.tag_type === 'usecase_tag') this.local_tag_section_name = 'usecase'
            if (!this.system_tag_types.includes(this.tag_type) && this.tag_type !== 'usecase_tag') this.local_tag_section_name = meta_type
        },

        async local_set_section_item () {
            this.section_item = { ...this.tag_section_item }
        },

        ...mapActions('TagSection', {
            tag_section_update: 'update',
            tag_section_show: 'show',
            tag_section_select: 'select',
            tag_section_destroy: 'destroy',
            tag_section_toggle_active: 'toggle_active',
            tag_section_clear_item: 'clear_item',
        }),

        ...mapActions('Visibility', {
            visibility_index: 'index',
            visibility_show: 'show',
            visibility_upsert_store: 'upsert',
            visibility_clear: 'clear',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_update: 'update',
            tag_select: 'select',
            tag_destroy: 'destroy',
            tag_reorder: 'reorder',
            tag_clear: 'clear',
            tag_clear_item: 'clear_item',
        }),
    },
}
</script>

<style lang="scss" scoped>
.c-error-strip {
    visibility: hidden;
    margin-top: -4px !important;
    transition: visibility 0.4s all;
    border-radius: 0px 0px 25px 25px !important;
    &__show {
        visibility: visible;
        margin-top: 0px !important;
        transition: visibility 0.4s all;
    }
}
</style>

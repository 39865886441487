<template>
    <div>
        <a-container container--fluid grid-list-xl pt-0 pl-8 pr-4>
            <!-- List -->
            <a-layout wrap align-start v-if="!local_loading">
                <a-flex xs12 class="u-rounded-corners-lg py-0 pr-2" :class="{ 'mb-6': usecase_list && usecase_list.length }">
                    <div class="u-flex align-center mb-5">
                        <div class="u-flex align-center">
                            <h2 class="md-heading-5 grey--text text--darken-1 mr-2" v-if="local_can('update')">Scope</h2>
                            <a-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <a-btn v-on="on" small icon dark v-if="local_can('store')" class="ma-0 blue" @click="local_usecase_create()"><a-icon size="20">add</a-icon></a-btn>
                                </template>
                                <span>Create Use Case</span>
                            </a-tooltip>
                        </div>
                        <a-spacer></a-spacer>
                        <div v-if="$can('templates.store') || $can('usecases.store') && $can('projects.update-only')">
                            <a-menu bottom left offset-y content-class="c-radius">
                                <template v-slot:activator="{ on }">
                                    <a-btn small depressed class="ma-0" color="#e0e0e0" v-on="on">
                                        <a-icon size="18" color="neutral darken-4">more_vert</a-icon>
                                        <span>More Options</span>
                                    </a-btn>
                                </template>
                                <a-list class="u-list-condensed">
                                    <a-list-item><span class="md-caption grey--text text--darken-1 font-weight-medium text-uppercase" style="letter-spacing: 1px;">Import</span></a-list-item>
                                    <!-- <template v-if="$can('templates.index') && local_can('update')">
                                        <a-list-item @click="local_template_import_form()" :disabled="show_bulk_delete">
                                            <a-list-item-content>
                                                <a-list-item-title>Import from Templates</a-list-item-title>
                                            </a-list-item-content>
                                        </a-list-item>
                                    </template> -->
                                    <a-list-item :to="{name: 'projects-usecases-template-import'}" :disabled="show_bulk_delete" v-if="local_can('update')">
                                        <a-list-item-content>
                                            <a-list-item-title>Import from Templates</a-list-item-title>
                                        </a-list-item-content>
                                    </a-list-item>
                                    <a-list-item :to="{name: 'projects-usecases-import'}" :disabled="show_bulk_delete" v-if="local_can('update')">
                                        <a-list-item-content>
                                            <a-list-item-title>Import from CSV</a-list-item-title>
                                        </a-list-item-content>
                                    </a-list-item>
                                    <template v-if="$can('templates.store') && (usecase_list && usecase_list.length)">
                                        <a-list-item><span class="md-caption grey--text text--darken-1 font-weight-medium text-uppercase" style="letter-spacing: 1px;">Export</span></a-list-item>
                                        <a-list-item @click="local_template_export_form()" :disabled="usecase_list.length === 0 || show_bulk_delete">
                                            <a-list-item-content>
                                                <a-list-item-title>Export as Template</a-list-item-title>
                                            </a-list-item-content>
                                        </a-list-item>
                                    </template>
                                    <!-- <a-list-item><a-divider></a-divider></a-list-item> -->
                                    <template v-if="local_can('destroy')">
                                        <a-divider class="my-2"></a-divider>
                                        <a-list-item :class="[{ 'grey lighten-4': show_bulk_delete }]" @click="show_bulk_delete = true" :disabled="usecase_list.length === 0 || loading">
                                            <a-list-item-content>
                                                <a-list-item-title>Delete Selected</a-list-item-title>
                                            </a-list-item-content>
                                        </a-list-item>
                                        <a-list-item @click="dialog_usecase_delete_all = !dialog_usecase_delete_all" :disabled="usecase_list.length === 0 || show_bulk_delete || loading">
                                            <a-list-item-content>
                                                <a-list-item-title :class="[(show_bulk_delete ? 'grey--text' : 'red--text text--darken-1')]">Delete all Use Cases</a-list-item-title>
                                            </a-list-item-content>
                                        </a-list-item>
                                    </template>
                                </a-list>
                            </a-menu>
                        </div>
                    </div>

                    <!-- Bulk delete -->
                    <div v-if="show_bulk_delete" class="u-flex align-center">
                        <a-sheet class="u-cursor-pointer d-inline-flex align-center pa-1 pl-2 pr-3 mr-2 u-rounded-corners u-border" @click="local_bulk_select_all_toggle">
                            <a-checkbox v-model="bulk_selected_all" @click="local_bulk_select_all_toggle" class="ma-0 pa-0" :disabled="bulk_delete_loading || !local_usecase_list_length" dense hide-details></a-checkbox>
                            <span class="md-subtitle-2 grey--text text--darken-2 ml-n1">Select All</span>
                        </a-sheet>
                        <a-menu bottom right offset-y max-width="360" v-if="local_usecase_list_length" :disabled="bulk_delete_loading || !local_usecase_list_length" content-class="u-rounded-corners">
                            <template v-slot:activator="{ on }">
                                <a-sheet v-on="on" class="u-cursor-pointer d-inline-flex align-center pa-1 pl-2 pr-3 mr-2 u-rounded-corners u-border" min-width="120" max-width="128">
                                    <a-sheet width="24" height="24" class="u-flex-center">
                                        <a-progress-circular width="3" size="20" indeterminate color="orange darken-2" v-if="bulk_delete_loading"></a-progress-circular>
                                        <a-icon color="red darken-2" size="24" v-else>delete_outline</a-icon>
                                    </a-sheet>
                                    <span class="md-subtitle-2 red--text text--darken-2 ml-1">Delete</span>
                                    <a-spacer></a-spacer>
                                    <span class="md-subtitle-2 red--text text--darken-2 ml-1">({{ bulk_delete_ids.length }})</span>
                                </a-sheet>
                            </template>
                            <a-sheet class="pa-5 ">
                                <div class="mb-4">
                                    <h3 class="md-heading-6 body--text text--darken-3 mb-1">Are you sure to continue?</h3>
                                    <p class="md-subtitle-1 body--text text--darken-1 mb-0">This will delete all the selected use cases and cannot be reverted.</p>
                                </div>
                                <a-sheet class="u-cursor-pointer d-inline-flex align-center pa-1 px-2 u-rounded-corners u-border red darken-2" @click="!bulk_delete_loading ? local_bulk_delete() : {}">
                                    <span class="md-subtitle-2 white--text text--darken-2 ml-1">Confirm Delete</span>
                                </a-sheet>
                            </a-sheet>
                        </a-menu>
                        <a-sheet class="u-cursor-pointer grey lighten-2 pa-1 px-2 u-rounded-corners u-border" @click="local_cancel_bulk_delete()">
                            <span class="md-subtitle-2 grey--text text--darken-1">Cancel</span>
                        </a-sheet>
                        <div>
                            <span class="grey--text text--darken-1 md-body-2 ml-1">or press</span>
                            <span class="c-mono-font grey--text text--darken-2 font-weight-bold md-caption ml-1">(Esc)</span>
                        </div>
                    </div>

                    <!-- Usecase list -->
                    <template v-if="!local_loading && usecase_list.length">
                        <draggable v-model="usecase_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                            <template v-for="(usecase) in usecase_list">
                                <p-usecase-card
                                    :usecase="usecase"
                                    handle="js-drag-handle"
                                    class="u-shadow"
                                    :class="['mt-4']"
                                    :key="usecase.id"
                                    :disable-navigation="bulk_delete_loading || show_bulk_delete"
                                    @click.stop="show_bulk_delete ? local_add_to_bulk_delete(usecase.id) : ''"
                                >
                                    <template #selectable>
                                        <v-slide-x-transition leave-absolute hide-on-leave>
                                            <a-checkbox
                                                v-if="show_bulk_delete"
                                                :value="local_is_bulk_selected(usecase.id)"
                                                :disabled="bulk_delete_loading"
                                                @click.stop="local_add_to_bulk_delete(usecase.id)"
                                                class="ma-0 pa-0"
                                                style="margin-top: 2px !important;"
                                                dense hide-details
                                            ></a-checkbox>
                                        </v-slide-x-transition>
                                    </template>
                                    <template #assignees>
                                        <s-assignee-dropdown
                                            :item="usecase"
                                            :users-list="local_member_list(usecase.assignees)"
                                            :project-id="$route.params.id"
                                            :local_loading="local_loading"
                                            :can-update="local_can('update')"
                                            :size="26"
                                            @assignee-store="local_get_usecase(usecase.id)"
                                            @change-visibility="local_visibility_toggle(usecase.id, $event)"
                                            module-type="Usecase"
                                            class="u-flex align-center justify-end mr-2"
                                            hide-empty-text
                                        ></s-assignee-dropdown>
                                    </template>
                                    <template #success-criteria-stats>
                                        <s-stats
                                            module="Success Criteria"
                                            :total="usecase.success_criteria_count"
                                            :open="usecase.success_criteria_open_count"
                                            :closed="usecase.success_criteria_closed_count"
                                            :passed="usecase.success_criteria_passed_count"
                                            :failed="usecase.success_criteria_failed_count"
                                            :invalid="usecase.success_criteria_invalid_count"
                                        ></s-stats>
                                    </template>
                                </p-usecase-card>
                            </template>
                        </draggable>
                    </template>
                </a-flex>
            </a-layout>

            <!-- Loading -->
            <a-responsive class="my-4" v-if="local_loading">
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Use Cases..</h3>
            </a-responsive>

            <!-- No data found message -->
            <a-responsive v-if="!local_loading && (usecase_list && usecase_list.length === 0) && !bulk_delete_loading">
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
            </a-responsive>

            <!-- Load More Button -->
            <a-layout wrap align-start v-if="!local_loading && (usecase_list && usecase_list.length)">
                <a-flex xs12 class="u-flex align-center">
                    <a-btn v-if="usecase_meta && (usecase_meta.current_page < usecase_meta.last_page)" :loading="loading" :disabled="loading" @click="local_paginate(usecase_meta.current_page + 1)" small text class="primary" dark>
                        Load More
                    </a-btn>
                    <a-spacer></a-spacer>
                    <span class="md-body-2 grey--text text--darken-1" v-if="usecase_meta.total">Showing <strong>{{ usecase_list.length }}</strong> out of <strong>{{ usecase_meta.total }}</strong> use cases</span>
                </a-flex>
            </a-layout>
        </a-container>

        <a-dialog v-model="dialog_import_form" max-width="860" persistent>
            <g-dialog-box-simple @close="dialog_import_form = false">
                <template #title>Import use cases into projects</template>
                <template #body>
                    <div class="mt-4">
                        <div class="text-center" v-if="loading_module === 'import-form'">
                            <a-progress-circular width="4" color="orange darken-1" indeterminate></a-progress-circular>
                        </div>
                        <a-responsive class="py-4" v-if="!loading && !loading_module && template_group_list.length == 0">
                            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                            <p class="md-body-2 text-center mb-0 grey--text text--darken-1">There is no data to fetch right now.</p>
                            <span class="d-block md-body-2 text-center grey--text text--darken-1" v-if="$can(`templates.update`)">
                                You might want to
                                <span class="u-cursor-pointer text-decoration-underline" @click="local_template_export_form">export use cases</span> or
                                <router-link class="grey--text text--darken-1 u-cursor-pointer text-decoration-underline" tag="a" :to="{ name: 'master-templates-list' }">create new template</router-link>
                            </span>
                        </a-responsive>
                        <div class="u-flex align-stretch flex-wrap" v-if="loading_module !== 'import-form' && (template_group_list && template_group_list.length)">
                            <a-card v-for="(template_group, index) in template_group_list" :key="template_group.id" class="u-border mb-2" :class="[(index !== 0 && index !== 3 && index % 3 === 0) ? 'mr-0' : 'mr-2']" flat>
                                <a-card-text class="pa-0 u-flex align-center">
                                    <a-sheet class="grey lighten-4 px-3 py-2" min-width="214" max-width="214">
                                        <p class="md-body-2 grey--text text--darken-3 mb-0" :title="template_group.name && template_group.name.length > 22 ? template_group.name : ''">{{ template_group.name | truncateText(22) }}</p>
                                        <div>
                                            <span class="md-caption grey--text text--darken-1 mr-1">Use Cases:</span>
                                            <span class="md-caption grey--text text--darken-2 font-weight-medium">{{ template_group.template_usecases_count }}</span>
                                        </div>
                                    </a-sheet>
                                    <a-btn icon text small class="ma-0 mx-2" @click="local_template_import(template_group.id)" :loading="loading_module === 'import-' + template_group.id" :disabled="template_group.template_usecases_count == 0 || loading">
                                        <a-icon size="16" color="grey darken-1">cloud_download</a-icon>
                                    </a-btn>
                                </a-card-text>
                            </a-card>
                        </div>
                    </div>
                </template>
            </g-dialog-box-simple>
        </a-dialog>

        <a-dialog v-model="dialog_export_form" max-width="600" persistent>
            <g-dialog-box icon="insert_drive_file" hide-close-icon>
                <template #title>Export Use Cases as Template</template>
                <template #subtitle>You can save list of use cases to import them in new projects.</template>
                <template #body="{ bodyClass }">
                    <a-form @submit.prevent="local_template_export">
                        <a-container :class="bodyClass">
                            <a-layout wrap align-center>
                                <a-flex xs12 pt-0>
                                    <v-radio-group class="ma-0 pa-0" v-model="export_mode" row @change="local_export_mode_change()">
                                        <v-radio label="New Template" value="new"></v-radio>
                                        <v-radio label="Existing Template" value="existing" :disabled="!$can('templates.update') || !$can('templates.index')"></v-radio>
                                    </v-radio-group>
                                </a-flex>
                                <a-flex xs12 v-if="export_mode === 'new'">
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Group Name</label>
                                    <a-text-field
                                        v-model="template_usecase_item.name"
                                        placeholder="Enter Template Name"
                                        background-color="neutral"
                                        solo flat hide-details autofocus
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_group_response.server && template_group_response.server.errors && template_group_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_group_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the user.
                                    </span>
                                </a-flex>
                                <a-flex xs12 v-if="export_mode === 'existing'">
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Group</label>
                                    <a-autocomplete
                                        v-model="template_usecase_item.id"
                                        :items="template_group_list"
                                        item-text="name"
                                        item-value="id"
                                        placeholder="Select Template"
                                        background-color="neutral"
                                        solo flat hide-details autofocus
                                    >
                                    </a-autocomplete>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_usecase_response.server && template_usecase_response.server.errors && template_usecase_response.server.errors.template_group_id">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_usecase_response.server.errors.template_group_id[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the user.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </template>
                <template #footer>
                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_template_export()" :loading="loading" :disabled="loading">Save Template</a-btn>
                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_close_template_export_form()" :disabled ="loading">Cancel</a-btn>
                </template>
            </g-dialog-box>
        </a-dialog>

        <a-dialog max-width="360" v-model="dialog_usecase_delete_all">
            <a-card class="pa-8">
                <div class="mb-5">
                    <p class="md-subtitle-1 grey--text text--darken-3 font-weight-medium mb-3">Are you sure you want to delete all the use cases in this project?</p>
                    <p class="md-body-2 grey--text text--darken-1 mb-0">This action cannot be undone.</p>
                </div>
                <div>
                    <a-btn small class="ma-0 px-3 elevation-0 red lighten-5 red--text text--darken-2" @click="local_usecase_delete_all()" :loading="loading">Delete all Use Cases</a-btn>
                    <a-btn small color="grey" class="ma-0 ml-4 grey lighten-4" text @click="dialog_usecase_delete_all = !dialog_usecase_delete_all">Cancel</a-btn>
                </div>
            </a-card>
        </a-dialog>

        <a-dialog v-model="dialog_upload_form" max-width="640">
            <a-card class="neutral">
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl>
                        <a-layout wrap align-center>
                            <a-flex>
                                <a-responsive class="py-4 my-4">
                                    <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-3 mb-1">Import from CSV File</h3>
                                    <p class="md-body-2 text-center grey--text text--darken-2 mb-2">You can bulk upload use case, test case and success criteria from CSV file. You can drag and drop here to upload the file or <a href="#">select the file from your computer</a>.</p>
                                    <p class="md-body-2 text-center grey--text text--darken-2 mb-0">Please make sure the file is in the format provided by Success.<br>Click here to <a href="#">download the sample format</a>.</p>
                                </a-responsive>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-dialog max-width="550" v-model="dialog_usecase_form" persistent>
            <g-dialog-box-simple hide-close-icon>
                <template #title>Create Use Case</template>
                <template #body>
                    <div class="mt-3 mb-6">
                        <label class="md-body-2 d-block mb-2 mt-0 grey--text text--darken-1 font-weight-medium">Use Case Title</label>
                        <a-text-field
                            v-model="usecase_item.title"
                            placeholder="Ex: Role management"
                            background-color="white"
                            class="u-border"
                            ref="refUsecaseStore"
                            solo flat hide-details autofocus
                        >
                        </a-text-field>
                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="usecase_response.server && usecase_response.server.errors && usecase_response.server.errors.title">
                            <a-icon size="16" color="red darken-2">warning</a-icon>
                            {{ usecase_response.server.errors.title[0] }}
                        </span>
                    </div>
                    <div class="u-flex align-center">
                        <a-btn small color="primary" class="ma-0 elevation-0 px-3" @click="local_usecase_store()" :loading="loading" :disabled="loading">Create Use Case</a-btn>
                        <a-btn small class="ml-4 mt-0 mb-0 mr-0 grey lighten-4" color="grey" text @click="local_usecase_form_close()" :disabled ="loading">Cancel</a-btn>
                        <a-spacer></a-spacer>
                        <a-checkbox hide-details dense class="ma-0 ml-2 pa-0" v-model="usecase_create_another">
                            <template v-slot:label><span class="ml-n1">Create another</span></template>
                        </a-checkbox>
                    </div>
                </template>
            </g-dialog-box-simple>
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { SUserListPopup, SAssigneeForm, SAssigneeDropdown, SStats } from '@/config/config-shared-components'
import { PUsecaseCard } from './Partials'
import TemplateImportStatus from '@/mixins/mixin-template-import-status'

export default {
    mixins: [TemplateImportStatus],
    components: {
        SUserListPopup, SAssigneeForm, SAssigneeDropdown, SStats,
        PUsecaseCard,
    },

    data() {
        return {
            export_mode: 'new',
            show_bulk_delete: false,
            bulk_selected_all: false,
            bulk_delete_ids: [],
            dialog_usecase_form: false,
            dialog_import_form: false,
            dialog_export_form: false,
            dialog_usecase_delete_all: false,
            dialog_upload_form: false,
            accordion_usecases: [],
            accordion_usecases_all: false,
            search_collaborator: '',
            usecase_create_another: true,
            local_user_edit_type: null,
            local_user_edit_item: null,
            local_selected_user_add_item: null,
            autosave_user_add_status: 'none',
            local_loading: true,
            bulk_delete_loading: false,
            loading_module: null,
            // local_usecase_fields: {
            //     'include': 'tags,assignees.collaborator.type,testcasesCount,testcasesClosedCount,testcasesPassedCount,testcasesOpenCount,testcasesFailedCount,successCriteriaCount,testcasesInvalidCount,successCriteriaOpenCount,successCriteriaClosedCount,successCriteriaPassedCount,successCriteriaFailedCount,successCriteriaInvalidCount'
            // },
            local_usecase_fields: {
                'include': 'tags,assignees.collaborator.type,successCriteriaCount,successCriteriaOpenCount,successCriteriaClosedCount,successCriteriaPassedCount,successCriteriaFailedCount,successCriteriaInvalidCount',
                'fields[usecases]': 'id,code,title,status,visibility,project_id,description_json,created_at,updated_at',
                'fields[tags]': 'tags.id,tags.label,tags.type,tags.color',
                'fields[assignees]': 'assignees.id,name,color,initial,avatar,scope',
                'filter[collaborators]': true,
                'count': 20,
                'page': 1
            }
        }
    },

    mounted() {
        this.local_index()
        document.addEventListener('keydown', this.local_escap_cancel_bulk_delete)
    },

    beforeDestroy () {
        document.removeEventListener('keydown', this.local_escap_cancel_bulk_delete)
    },

    computed: {
        usecase_list: {
            get() {
                return this.$store.state.Usecase.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },

        local_usecase_list_length () {
            return this.usecase_list && this.usecase_list.length
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Usecase', {
            usecase_item: 'item',
            usecase_meta: 'meta',
            usecase_response: 'response',
        }),

        ...mapState('TemplateGroup', {
            template_group_list: 'list',
            template_group_item: 'item',
            template_group_response: 'response',
        }),

        ...mapState('TemplateUsecase', {
            template_usecase_item: 'item',
            template_usecase_response: 'response',
        }),

        ...mapState('Project', {
            project_item: 'item',
        }),

        ...mapState('Collaborator', {
            collaborator_list: 'list',
        }),

        ...mapState('Assignee', {
            assignee_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('usecases.index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.usecase_clear()
            await this.local_usecase_index()
            this.local_loading = false
        },

        async local_paginate (value) {
            this.local_usecase_fields.page = value
            await this.local_usecase_index()
        },

        // Usecase CRUD -----
        async local_usecase_index () {
            await this.usecase_index({
                params: {
                    ...this.local_usecase_fields,
                    'filter[project_id]': this.$route.params.id,
                }
            })
            if (!this.local_usecase_list_length && this.show_bulk_delete) this.show_bulk_delete = false
        },

        async local_usecase_create() {
            this.usecase_create_another = true
            this.dialog_usecase_form = true
            this.usecase_clear_item()
        },

        async local_usecase_store() {
            this.usecase_item.project_id = this.$route.params.id
            await this.usecase_store({ ...this.usecase_item, mode: this.usecase_create_another ? 'create-another' : '', params: this.local_usecase_fields })
            if (this.usecase_response.status !== 'success') return
            if (!this.usecase_create_another) {
                return this.$router.push({name: 'projects-usecases-view', params: {usecase_id: this.usecase_item.id} })
            }
            this.$notify('success', 'Usecase created successfully!')
            this.usecase_clear_item()
            this.usecase_meta.total++
            setTimeout(() => this.$refs.refUsecaseStore.ref.focus(), 500)
        },

        async local_usecase_form_close() {
            this.dialog_usecase_form = false
            this.usecase_clear_item()
        },

        async local_reorder(list) {
            this.usecase_reorder({list: list, project_id: this.$route.params.id})
        },

        async local_usecase_delete_all() {
            await this.usecase_delete_all({project_id: this.project_item.id})
            if (this.usecase_response.status !== 'success') {
                return this.$notify('error', 'Use Case delete all operation failed.')
            }
            this.dialog_usecase_delete_all = false
            this.$notify('success', 'Deleted all the use cases')
        },

        async local_visibility_toggle (id, state) {
            const usecase_item = this.usecase_list.find(item => item.id === id)
            usecase_item.visibility = state
            await this.usecase_visibility(Object.assign({}, { id, state }))
            if (this.usecase_response.status !== 'success') this.$notify('error', 'Error updating usecase visibility.')
        },

        local_bulk_select_all_toggle () {
            if (this.bulk_delete_loading || !this.local_usecase_list_length) return
            this.bulk_selected_all = !this.bulk_selected_all
            if (this.bulk_selected_all) {
                this.bulk_delete_ids = this.usecase_list.map(({id}) => id)
                return true
            }
            this.bulk_delete_ids = []
        },

        local_add_to_bulk_delete (usecase_id) {
            const hasIdIndex = this.bulk_delete_ids.findIndex(id => id === usecase_id)
            if (hasIdIndex !== -1) this.bulk_delete_ids.splice(hasIdIndex, 1)
            else this.bulk_delete_ids.push(usecase_id)

            if (this.bulk_delete_ids.length === this.local_usecase_list_length) this.bulk_selected_all = true
            else this.bulk_selected_all = false
        },

        local_is_bulk_selected (usecase_id) {
            return this.bulk_delete_ids.includes(usecase_id)
        },

        local_escap_cancel_bulk_delete (evt) {
            if (evt.keyCode === 27 || evt.key === 'Escape') this.local_cancel_bulk_delete()
        },

        local_cancel_bulk_delete () {
            this.show_bulk_delete = false
            this.bulk_delete_loading = false
            this.bulk_selected_all = false
            this.bulk_delete_ids = []
        },

        async local_bulk_delete () {
            if (!this.bulk_delete_ids.length) return this.$notify('warning', 'Minimum 1 use case required to perform this operation.')
            this.bulk_delete_loading = true
            await this.usecase_bulk_delete({ project_id: this.project_item.id, id: this.bulk_delete_ids })
            if (!this.$status(this.usecase_response)) {
                this.bulk_delete_loading = false
                return this.$notify('error', 'Something went wrong, please try again later.')
            }
            await this.local_usecase_index()
            this.bulk_delete_loading = false
            this.$notify('success', `Deleted ${this.bulk_delete_ids.length} use case${this.bulk_delete_ids.length > 1 ? 's' : ''} successfully!`)
            this.bulk_selected_all = false
            this.bulk_delete_ids = []
        },
        // Usecase CRUD ----- END

        // Assign users to usecase -----
        local_member_list(list) {
            return this.collaborator_list.filter(item => list.findIndex(user => user.assignee.user_id === item.user_id) === -1)
            // return this.collaborator_list.filter(item => !(_.find(list, { id: item.user_id })))
        },
        // Assign users to usecase ----- END

        // Usecase templates CRUD -----
        local_export_mode_change () {
            this.template_group_clear_item()
            this.template_usecase_clear_item()
        },

        async local_close_template_export_form() {
            await this.template_usecase_clear_item()
            this.local_export_mode_change()
            this.dialog_export_form = false
        },

        async local_template_export_form() {
            this.template_group_clear()
            await this.template_usecase_clear_item()
            if (this.dialog_import_form) this.dialog_import_form = false
            this.dialog_export_form = true
            if (this.$can('templates.index') == true) {
                await this.template_group_index({
                    'fields[template_groups]': 'id,name',
                    'filter[type]': 'usecases'
                })
            }
            this.export_mode = 'new'
        },

        async local_template_import_form() {
            this.local_set_loading('import-form')
            this.dialog_import_form = !this.dialog_import_form
            if (this.$can('templates.index') == true) {
                await this.template_group_index({
                    include: 'templateUsecasesCount',
                    'fields[template_groups]': 'id,name',
                    'filter[type]': 'usecases'
                })
            }
            this.local_set_loading(null)
        },

        async local_template_import(id) {
            this.local_set_loading('import-' + id)
            const import_item = {}
            import_item.project_id = this.$route.params.id
            import_item.template_group_id = id
            await this.usecase_template_import(import_item)
            this.local_usecase_index()
            this.$notify('success', 'Use Case imported successfully!')
            this.local_set_loading(null)
        },

        async local_template_export() {
            if (this.export_mode === 'new') return this.local_template_group_store()
            this.local_template_group_export(this.template_usecase_item.id)
        },

        async local_template_group_store() {
            await this.template_group_store({ name: this.template_usecase_item.name, type: 'usecases' })
            if (this.template_group_response.status !== 'success') return
            await this.local_template_group_export(this.template_group_item.id)
        },

        async local_template_group_export(group_id) {
            const usecase_ids = this.usecase_list.map(item => item.id);
            const template_export_item = {}
            template_export_item.data = usecase_ids
            template_export_item.template_group_id = group_id
            await this.template_usecase_export(template_export_item);
            if (this.template_usecase_response.status !== 'success') return
            this.dialog_export_form = false
            this.$notify('success', 'Usecases exported successfully!')
        },
        // Usecase templates CRUD ----- END

        // Extras -----
        local_set_loading (value) {
            this.loading_module = value
        },

        local_can (type) {
            if (this.user_self.role.scope === 'external') return client_allowed
            return this.$can(`usecases.${type}`) && this.$can('projects.update-only')
        },

        local_get_usecase (usecase_id) {
            this.usecase_show({
                id: usecase_id,
                params: {
                    project_id: this.$route.params.id,
                    include: 'assignees.collaborator.type',
                    'fields[usecases]': 'id',
                    'fields[assignees]': 'assignees.id,avatar,color,name,scope,initial',
                    'filter[collaborators]': true
                }
            })
        },
        // Extras ----- END

        ...mapActions('Usecase', {
            usecase_index: 'index',
            usecase_show: 'show',
            usecase_show_item: 'show_item',
            usecase_store: 'store',
            usecase_update: 'update',
            usecase_visibility: 'visibility',
            usecase_reorder: 'reorder',
            usecase_template_import: 'template_import',
            usecase_bulk_delete: 'bulk_delete',
            usecase_delete_all: 'delete_all',
            usecase_clear: 'clear',
            usecase_clear_item: 'clear_item',
        }),

        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_store: 'store',
            template_group_clear: 'clear',
            template_group_clear_item: 'clear_item',
        }),

        ...mapActions('TemplateUsecase', {
            template_usecase_export: 'template_export',
            template_usecase_clear: 'clear',
            template_usecase_clear_item: 'clear_item',
        }),

        ...mapActions('Assignee', {
            assignee_store: 'store',
            assignee_destroy: 'destroy',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .sortable-ghost {
        opacity: 0.8;
        box-shadow: 0px 2px 6px 4px rgb(173, 173, 173);
        border: 1px dashed rgb(116, 116, 116);
        background: #d2f1ff;
    }
    .sortable-chosen {
        .c-expand { display: none; }
        opacity: 1 !important;
        box-shadow: 0px 2px 6px 4px rgb(173, 173, 173);
        border: none !important;
        border: 1px dashed rgb(116, 116, 116);
        background: #f5f5f5 !important;
    }
</style>

export default {
    install(Vue) {
        const Bus = new Vue()
        Vue.prototype.$notify = (type, text, additional = {}) => {
            Bus.$emit('notify', { type, text, extras: { ...additional } })
        }

        Vue.prototype.$getNotify = function (status_key = 'snackbar_status', content_key = 'snackbar_content', timeout_key = 'snackbar_timeout') {
            const instance = this
            Bus.$on('notify', (data) => {
                if (data.type === false) return
                if (instance[status_key] || instance[status_key] === false) instance[status_key] = false
                setTimeout(() => {
                    instance[status_key] = true
                    instance[timeout_key] = data.extras && data.extras.timeout ? data.extras.timeout : 3000
                    if (data && data.path) {
                        instance[content_key]['path'] = data.extras.path
                        instance[content_key]['path_id'] = data.extras.path_id
                        instance[content_key]['button_text'] = data.extras.button_text
                        instance[content_key] = { type: data.type, text: data.text }
                    } else instance[content_key] = data
                }, 500);
            })
        }
    }
}

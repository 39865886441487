<template>
    <a-container grid-list-xl container--fluid class="mt-1">
        <a-responsive :max-width="$vuetify.breakpoint.lgAndDown ? '940' : '1264'" class="mx-auto mb-8">
            <div class="blue-grey pa-4 u-shadow u-rounded-corners">
                <div class="u-flex">
                    <a-icon size="18" color="white">info</a-icon>
                    <span class="white--text md-subtitle-2 ml-2 font-weight-bold">You are viewing a preview of the Sections & Fields (excluding empty section)</span>
                </div>
            </div>
            <div class="u-flex justify-space-between my-6">
                <div class="md-heading-6 indigo--text text--darken-1 font-weight-medium mr-3">
                    Sections & Fields
                </div>
                <div>
                    <a-btn depressed small height="32" class="grey darken-4 white--text" @click="local_exit_preview()">Exit preview</a-btn>
                </div>
            </div>
            <div class="u-overflow-y c-tiny-scroll" :style="{ height: `${container_height}px` }">
                <template v-if="preview_list && preview_list.length">
                    <template v-for="(section, index) in preview_list">
                        <a-card
                            v-if="section && section.fields && section.fields.length"
                            :key="section.id"
                            color="white"
                            min-height="160"
                            :class="{'mt-8 ': index}"
                            class="u-shadow u-wfull u-rounded-corners c-section-card u-relative"
                        >
                            <div class="pa-6">
                                <a-layout align-center>
                                    <a-flex class="u-flex align-center">
                                        <div
                                            :class="['u-rounded-corners u-wfull']"
                                        >
                                            <div class="u-wfull u-flex">
                                                <div class="c-module-title indigo--text text--darken-1 font-weight-medium" :title="section.name">
                                                    {{ section.name | truncateText(local_is_lgdown ? 60 : 100) }}
                                                </div>
                                            </div>
                                        </div>
                                    </a-flex>
                                    <a-spacer></a-spacer>
                                    <div class="u-border grey lighten-4 px-4 py-2">
                                        <div class="md-subtitle-2 grey--text text--darken-3 text-center" style="width: 120px">{{ section.current_score ? section.current_score : '0' }}/{{ section.total_score }}</div>
                                    </div>
                                </a-layout>
                            </div>
                            <a-divider v-if="local_show_divider(section)"></a-divider>
                            <div>
                                <transition-group type="transition" name="flip-list" class="u-wfull c-section-container">
                                    <div
                                        v-for="(field, fieldIndex) in section.fields"
                                        :key="field.id"
                                        class="u-relative js-drag-handle c-field-card"
                                        :class="[field.width === '50%' ? 'c-field-card__half' : 'c-field-card__full']"
                                    >
                                        <a-divider v-if="local_show_item_divider(section, field, fieldIndex)"></a-divider>
                                        <PConfidenceFieldCard
                                            :item="field"
                                            module-type='preview'
                                            class="px-6 py-8"
                                        >
                                            <template #input>
                                                <template v-if="field.type === 'dropdown'">
                                                    <template v-if="field">
                                                        <div class="u-wfull mt-4 mb-6">
                                                            <a-autocomplete
                                                                v-model="field.value"
                                                                :items="field.options"
                                                                item-text="label"
                                                                item-value="id"
                                                                placeholder="Select an option"
                                                                class="u-rounded-corners u-border u-wfull"
                                                                color="grey darken-1"
                                                                background-color="grey lighten-5"
                                                                :key="field.id"
                                                                @input="local_add_value(field, section.id)"
                                                                append-icon="arrow_drop_down"
                                                                return-object
                                                                solo flat hide-details
                                                            >
                                                                <template v-slot:no-data>
                                                                    <div class="px-4 py-2">
                                                                        <span class="md-subtitle-2 py-2 grey--text text--darken-1 text-center">No Data Found</span>
                                                                    </div>
                                                                </template>
                                                                <template v-slot:selection="{ item }">
                                                                    <span v-if="item === Object(item)" class="md-body-3 d-block py-1 u-rounded-corners text-truncate" style="max-width: 630px" :title="item.label">{{ item.label }} </span>
                                                                </template>
                                                                <template v-slot:item="{ item }">
                                                                    <a-layout align-center class="py-0">
                                                                        <a-flex style="max-width: 630px">
                                                                            <h5 class="md-subtitle-1 text-truncate" :title="item.label">
                                                                                <span v-if="field.width === '100%'">{{ item.label | truncateText(150) }}</span>
                                                                                <span v-else-if="local_is_lgdown()">{{ item.label | truncateText(40) }}</span>
                                                                                <span v-else>{{ item.label | truncateText(60) }}</span>
                                                                            </h5>
                                                                        </a-flex>
                                                                    </a-layout>
                                                                </template>
                                                            </a-autocomplete>
                                                        </div>
                                                    </template>
                                                </template>
                                                <template v-if="field.type === 'score'">
                                                    <div class="u-wfull mt-4 mb-6 u-relative">
                                                        <a-text-field
                                                            v-model="field.value"
                                                            type="number"
                                                            title=""
                                                            min="0"
                                                            background-color="grey lighten-5"
                                                            placeholder="Enter the score"
                                                            class="u-rounded-corners u-border u-wfull"
                                                            @keydown="local_prevent_non_numerics"
                                                            @input="local_validate_field(field, section.id)"
                                                            @keyup.enter.prevent="local_validate_field(field, section.id)"
                                                            @focus="local_focus_input(field)"
                                                            solo flat hide-details>
                                                        </a-text-field>
                                                        <div class="md-caption mt-2 u-absolute" :class="[(error_response && local_get_score_error(field.id)) ? 'red--text text--darken-1': 'grey--text']">
                                                            <template v-if="error_response && local_get_score_error(field.id)">
                                                                <a-icon size="16" color="red darken-1" class="u-icon-nudge">info</a-icon>
                                                                Score must be between {{ field.minimum_value }} - {{ field.maximum_value }}
                                                            </template>
                                                            <template v-else>Enter the score between {{ field.minimum_value }} - {{ field.maximum_value }} </template>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-if="field.type === 'multi_select'">
                                                    <div class="mt-4">
                                                        <div class="d-inline-flex flex-wrap align-center" :class="[currentSelectionId === field.id ? 'u-border-transparent' : '', 'u-border-transparent']">
                                                            <template v-if="field.value && field.value.length">
                                                                <template v-for="item in local_get_selected_list(field)">
                                                                    <g-tags :hide-clear-icon="false" :height="24" :tag="item" type="Option" :key="item.id" @destroy="local_value_destroy(field, item.id, section.id)"></g-tags>
                                                                </template>
                                                            </template>
                                                            <a-menu
                                                                v-model="show_option_add_input[field.id]"
                                                                max-height="220"
                                                                nudge-bottom="24"
                                                                bottom
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <g-add-btn v-on="on">
                                                                        <span class="md-caption body--text text--lighten-1 ml-1">Select</span>
                                                                    </g-add-btn>
                                                                </template>
                                                                <a-text-field
                                                                    v-model="local_field_search[field.id]"
                                                                    class="c-field-search u-rounded-corners white u-relative"
                                                                    solo flat autofocus
                                                                    prepend-inner-icon="search"
                                                                    hide-details
                                                                    placeholder="Search"
                                                                >
                                                                </a-text-field>
                                                                <a-sheet class="pa-0 u-overflow-y c-tiny-scroll" style="maxHeight: 160px;">
                                                                    <template v-if="local_filtered_items(field) && local_filtered_items(field).length">
                                                                        <div v-for="option in local_filtered_items(field)" :key="option.id">
                                                                            <v-hover v-slot="{ hover }">
                                                                                <div :class="[ hover ? 'grey lighten-5' : '']" class="u-flex-center-y pa-4 u-cursor-pointer" @click="local_add_value(field, section.id, option)">
                                                                                    <div class="u-rounded-corners mr-2" :style="{ width: '16px', height: '16px', flexShrink: 0, backgroundColor: $color(option.color, 'color_bg', '#1565c0'), border: `1px solid ${$color(option.color, 'color_text', '#1565c0')}` }"></div>
                                                                                    <span class="md-body-2 grey--text text--darken-1 text-truncate d-inline-block">{{ option.label | truncateText(16) }}</span>
                                                                                </div>
                                                                            </v-hover>
                                                                            <a-divider></a-divider>
                                                                        </div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="u-flex align-center pa-4 justify-center">
                                                                            <div class="grey--text text-uppercase md-subtitle-2 text-center">
                                                                                <span>No data found</span>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </a-sheet>
                                                            </a-menu>
                                                        </div>
                                                    </div>
                                                </template>
                                            </template>
                                        </PConfidenceFieldCard>
                                    </div>
                                </transition-group>
                            </div>
                        </a-card>
                    </template>
                </template>
                <template v-else>
                    <div class="u-border u-flex flex-column align-center u-rounded-corners py-2">
                        <div class="mb-10">
                            <a-img :src="require('/src/assets/images/confidence-score/empty-sections.png')" contain width="300px" height="232px"></a-img>
                        </div>
                        <div class="mb-1 font-weight-medium grey--text text--darken-3 text-uppercase" style="font-size: 20px">No fields are added!</div>
                        <div class="text-center grey--text text--darken-2" style="max-width: 520px">
                            <template v-if="!$can('confidence_score.update')">
                                <div>It appears that the fields are not yet configured.</div>
                            </template>
                            <template v-else>
                                <div>The confidence score has not been configured yet.</div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
            <div
                v-if="preview_list && preview_list.length"
                class="white u-shadow-md u-rounded-corners pa-4 u-flex align-center"
                :style="[$vuetify.breakpoint.lgAndDown ? { minWidth: '940px'} : {minWidth: '1264px'}]"
                style="position: fixed; bottom: 36px"
            >
                <a-progress-linear
                    v-model="local_score_output"
                    height="16"
                    :class="['c-custom-progress-linear' + `__${local_progress_status}`, 'c-custom-progress-linear u-rounded-corners-full u-wfull']"
                >
                </a-progress-linear>
                <div class="mx-6 grey--text text--darken-3 u-flex align-end" style="fontSize: 20px; letter-spacing: -0.26px">
                    <div class="font-weight-medium mr-4">
                        <template v-if="local_get_score_percentage()">
                            <span class="indigo--text text--darken-1">({{ local_get_score_percentage() }}%)</span>
                        </template>
                        <template v-else>
                            <div class="u-flex align-center">
                                <span>(</span>
                                <div class="u-flex">
                                    <a-icon size="14">horizontal_rule</a-icon>
                                    <a-icon size="14">horizontal_rule</a-icon>
                                </div>
                                <span>%)</span>
                            </div>
                        </template>
                    </div>
                    <div>
                        <template v-if="current_score">
                            <div class="u-flex align-center">
                                <span class="font-weight-bold">{{ current_score }}</span>
                                <span class="md-subtitle-1">/{{ totalScore }}</span>
                            </div>
                        </template>
                        <template v-else>
                            <div class="u-flex align-center">
                                <a-icon size="14">horizontal_rule</a-icon>
                                <a-icon size="14">horizontal_rule</a-icon>
                                <span class="font-weight-medium md-subtitle-1">/{{ totalScore }}</span>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </a-responsive>
    </a-container>
</template>

<script>
import PConfidenceFieldCard from './PartialConfidenceFieldCard.vue'
import {STagsInlineForm,} from '@/config/config-shared-components'
import { number } from '@/helpers/helper-number-filter'

export default {
    components: { PConfidenceFieldCard, STagsInlineForm },

    props: {
        list: {
            type: Array,
            required: true
        },

        totalScore: {
            type: Number,
            required: true
        },

        configData: {
            type: Array,
            required: true
        },
    },

    watch: {
        list: {
            handler (val) {
                if (val) this.local_index()
            },
            immediate: true
        }
    },

    data () {
        return {
            preview_list: [],
            local_field_options: [],
            show_option_add_input: [],
            error_response: [],
            dataOptionSearch: null,
            currentSelectionId: null,
            error_item: {score: {}},
            validationTimeout: null,
            currentEditField: null,
            local_score_output: 0,
            current_score: 0,
            score_update_timeout: null,
            container_height: 0,
            color_range_1: {},
            color_range_2: {},
            color_range_3: {},
            local_field_search: {}
        }
    },

    computed: {
        local_progress_status () {
            if (!this.local_score_output) return
            if (this.local_score_output <= this.color_range_1.percentage) return this.color_range_1.color
            if ((this.local_score_output <= this.color_range_2.percentage) && (this.local_score_output > this.color_range_1.percentage)) return this.color_range_2.color
            if (this.local_score_output > this.color_range_2.percentage) return this.color_range_3.color
        },
    },

    methods: {
        local_index () {
            this.preview_list = _.cloneDeep(this.list)
            this.local_set_solo_fields()
            this.local_set_current_score()
            this.local_set_container_height()
            this.local_set_config_data()
        },

        local_set_config_data () {
            this.configData.forEach(item => {
                if (item.color === 'red-dark') this.color_range_1 = item
                if (item.color === 'yellow-dark') this.color_range_2 = item
                if (item.color === 'green-dark') this.color_range_3 = item
            })
        },

        local_set_container_height () {
            this.local_update_container_height()
            window.addEventListener('resize', this.local_update_container_height)
        },

        local_update_container_height () {
            this.container_height = window.innerHeight - 524
        },

        local_get_container_height () {
            const screenHeight = window.innerHeight
            return `${screenHeight} px`
        },

        local_is_lgdown () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        local_set_solo_fields () {
            this.field_counter = 0
            this.preview_list.forEach((section ,index) => {
                section.fields.forEach((item, fieldIndex) => {
                    item.solo_field = false
                    if (item.width === '50%') {
                        ++this.field_counter
                        if(this.field_counter % 2 !== 0 && (fieldIndex+1 < section.fields.length) && (section.fields[fieldIndex+1].width === '100%')) {
                            item.solo_field = true
                            this.field_counter = 0
                        } else if (this.field_counter % 2 === 0) {
                            item.solo_field = false
                            this.field_counter = 0
                        }
                    }
                    if (item.width === '100%') {
                        item.solo_field = false
                        this.field_counter = 0
                    }
                })
            })
        },

        local_prevent_non_numerics (e) {
            if (!number(e)) e.preventDefault()
        },

        local_focus_input (field) {
            this.currentEditField = field.id
        },

        local_filtered_items (field) {
            const selectedItemIds = field.value.map(item => item.id)
            const filtered = field.options.filter(item => {
                return selectedItemIds.indexOf(item.id) === -1
            })
            if (this.local_field_search[field.id]) return this.local_get_search_result(field)
            return filtered
        },

        local_get_search_result (field) {
            const searchResult = field.options.filter(option => {
                return option.label.toLowerCase().startsWith(this.local_field_search[field.id].toLowerCase())
            })
            return searchResult
        },

        local_add_value (item, section_id, option) {
            const value = item.type === 'multi_select' ? option : item.value
            const props = {item: item, section_id, value: value, action: 'add'}
            this.$emit('update', props)
            this.show_option_add_input[item.id] = false
            this.currentSelectionId = item.id
        },

        local_value_destroy (field, item_id, section_id) {
            const props = {item: field, section_id, value: item_id, action: 'destroy'}
            this.currentSelectionId = field.id
            this.$emit('update', props)
            this.show_option_add_input[field.id] = false
            this.currentSelectionId = null
        },

        local_get_selected_list (field, length = null) {
            if (length) return !!field.value.length
            if (field && !field.value) return
            return field.value
        },

        local_validate_field (field, section_id) {
            clearTimeout(this.score_update_timeout)
            const max_score = parseInt(field.maximum_value)
            const min_score = parseInt(field.minimum_value)
            const value = parseInt(field.value)
            if (field.value && (value < min_score) || (value > max_score)) {
                if (this.error_response.indexOf(field.id) !== -1) return
                this.error_response.push(field.id)
            } else {
                const fieldIndex = this.error_response.findIndex(item => item === field.id)
                this.error_response.splice(fieldIndex, 1)
            }

            const props = {item: field, section_id, value: field.value, action: 'add'}
            if (this.error_response && !this.error_response.length) {
                this.score_update_timeout = setTimeout(() => {
                    this.$emit('update', props)
                }, 1000)
            }
        },

        local_get_score_error (fieldId) {
            return this.error_response.indexOf(fieldId) !== -1
        },

        local_set_current_score () {
            let score = 0
            this.list.forEach(section => {
                if (section && section.current_score) score = parseInt(score) + parseInt(section.current_score)
                this.current_score = parseInt(score)
                const score_ratio = parseInt(this.current_score) / parseInt(this.totalScore)
                this.local_score_output =  Math.round(score_ratio*100)
            })
        },

        local_get_score_percentage () {
            const score_ratio = this.current_score/this.totalScore
            return Math.round(score_ratio*100)
        },

        local_show_divider (section) {
            if (section.fields.length === 1 && section.fields[0].width === '50%') return true
            if (section.fields.length > 1 && section.fields[0].width === '50%' && section.fields[1].width !== '50%') return true
        },

        local_show_item_divider (section, field, index) {
            if (index === 0 && section.fields.length === 1 && field.width === '50%') return false
            if (index === 0 && section.fields.length > 1 && field.width === '50%' && section.fields[1].width !== '50%') return false
            return !field.solo_field
        },

        local_exit_preview () {
            window.removeEventListener('resize', this.local_update_container_height)
            this.$emit('exit')
        },
    }
}
</script>

<style scoped>
    .c-section-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .c-field-card {
        width: 100%;
    }
    .c-field-card__half {
        width: 50% !important;
    }
    .c-field-card__half--preview {
        width: 50% !important;
    }
    .c-field-card__full{
        width: 100% !important;
    }
</style>

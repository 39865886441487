const api_endpoint = '/gong'

export default {
    namespaced: true,

    state: {
        list: [],
        folders: [],
        workspaces: [],
        users: [],
        trackers: [],
        keywords: [],
        defaults: {
            list: [],
            folders: [],
            workspaces: [],
            users: [],
            trackers: [],
            keywords: [],
        }
    },

    mutations: {
        UPDATE_LIST (state, items) {
            state.list = items;
        },

        UPDATE_WORKSPACE_LIST (state, items) {
            state.workspaces = items;
        },

        UPDATE_FOLDER_LIST (state, items) {
            state.folders = items;
        },

        UPDATE_USERS (state, items) {
            state.users = items;
        },

        UPDATE_KEYWORDS (state, items) {
            state.keywords = items;
        },

        UPDATE_TRACKERS (state, items) {
            state.trackers = items;
        },

        TOGGLE_SETTINGS_LOADING (state, payload) {
            state.loading.settings = payload;
        },

        THROW_RESPONSE (state, payload) {
            state.response = payload;
        },
    },

    actions: {
        async workspaces_index ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data } } = await axios.get(api_endpoint + '/workspaces')
                commit('UPDATE_WORKSPACE_LIST', data)
                this.dispatch('Interface/loader_stop')
                 commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },

        async workspace_select ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.post(api_endpoint + '/workspaces', payload)
                this.dispatch('Interface/loader_stop')
                 commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },

        async folders_index ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                const { data: { data } } = await axios.get(api_endpoint + '/folders', { params: { ...payload } })
                commit('UPDATE_FOLDER_LIST', data)
                this.dispatch('Interface/loader_stop')
                 commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },

        async folders_add ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.post(api_endpoint + '/folders', payload)
                this.dispatch('Interface/loader_stop')
                 commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },

        async folders_update ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.patch(api_endpoint + '/folders/folder-update', payload)
                this.dispatch('Interface/loader_stop')
                 commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },

        async users_index ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            const params = payload ? { params: { ...payload } } : {}
            try {
                const { data: { data } } = await axios.get(api_endpoint + '/users', params)
                commit('UPDATE_USERS', data)
                this.dispatch('Interface/loader_stop')
                 commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },

        async trackers_index ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            const params = payload ? { params: { ...payload } } : {}
            try {
                const { data: { data } } = await axios.get(api_endpoint + '/trackers', params)
                commit('UPDATE_TRACKERS', data)
                this.dispatch('Interface/loader_stop')
                 commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },

        async keywords_index ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            const params = payload ? { params: { ...payload } } : {}
            try {
                const { data: { data } } = await axios.get(api_endpoint + '/keywords', params)
                commit('UPDATE_KEYWORDS', data)
                this.dispatch('Interface/loader_stop')
                 commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },

        async revoke_access ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await await axios.delete(api_endpoint + '/revoke-access')
                this.dispatch('Interface/loader_stop')
                 commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },

        async sync_workspaces ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await await axios.get(api_endpoint + '/sync/workspaces')
                this.dispatch('Interface/loader_stop')
                 commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },

        async sync_calls ({ commit }, payload) {
            this.dispatch('Interface/loader_start')
            try {
                await await axios.get(api_endpoint + '/sync/calls')
                this.dispatch('Interface/loader_stop')
                 commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },
    },
}

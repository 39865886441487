<template>
    <div class="c-bar-vertical-chart u-relative">
        <div class="c-bar-vertical-chart__column u-relative" style="z-index: 2">
            <p-tile-status
                :is_loading="item && item.status === 'loading'"
                :has_error="item && item.status === 'error'"
                :has_stats="item && item.statistics && item.statistics.data  && item.statistics.data.length"
                @configure="$emit('configure', true)"
            >
                <template #loader v-if="item && item.status === 'loading'">
                    <div class="u-wfull u-hfull u-absolute text-center transparent u-opacity-40">
                        <a-img :src="require('../../../assets/images/loader.svg')" alt="Loading Line Chart.." width="48" height="48" contain class="d-inline-block mt-16"></a-img>
                    </div>
                </template>
            </p-tile-status>
            <VueApexCharts
                ref="barVerticalChart"
                :options="chartOptions"
                :series="series"
                height="100%"
                width="100%"
            ></VueApexCharts>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import VueApexCharts from "vue-apexcharts"
import PTileStatus from './PartialTileStatus.vue'
import { numberFormatter } from '@/helpers/helper-number.js'
import { formatCurrency } from '@/helpers/helper-currency.js'

export default {
    name: "BarVerticalChart",

    components: { VueApexCharts, PTileStatus },

    props: {
        isRearrange: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: {}
        }
    },

    watch: {
        item: {
            handler (val) {
                this.local_index()
            },
            deep: true
        },

        isRearrange (val) {
            this.local_set_animation(!val)
        }
    },

    data: function () {
        return {
            local_chart: {},
            local_options: {},
            stats_interval: null,
            stats_interval_countdown: 0,
            chart_loading: true,
            keys: [],
            values: [],
            series: [],
            chartOptions: {
                chart: {
                    type: "bar",
                    toolbar: {
                        show: false,
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 8,
                        dataLabels: {
                            position: "top", // top, center, bottom
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    padding: {
                        left: 0,
                    }
                },
                legend: {
                    show: false,
                },
                xaxis: {
                    categories: [],
                    labels: {
                        style: {
                            fontFamily: "Inter, sans-serif",
                            fontWeight: 700,
                        },
                        formatter: function (val) {
                            return [ val, '(MAR-JUN)'];
                        }
                    },
                    position: "bottom",
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        fill: {
                            type: "gradient",
                            gradient: {
                                colorFrom: "#D8E3F0",
                                colorTo: "#BED1E6",
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                },
                yaxis: {
                    show: true,
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: true,
                        style: {
                            fontFamily: "Inter, sans-serif",
                        },
                        offsetX: -8,
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
                fill: {
                    opacity: 1,
                    colors: ['#9C27B0']
                },
                tooltip: {
                    enabled: true,
                    y: {
                        formatter: function(val) {
                            return val;
                        }
                    }
                },
            },
        };
    },

    mounted() {
        this.local_index()
    },

    beforeDestroy () {
        clearInterval(this.stats_interval)
    },

    computed: {
        ...mapState('Tile', {
            tile_response: 'response',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index() {
            // this.local_disable_animation();

            await this.local_get_stats()
            // this.local_chart = _.clonedeep(this.item)
            this.stats_interval_countdown = 0
            this.stats = []
            this.local_options = _.clonedeep(this.chartoptions)
            // await this.tile_show_stats({ id: this.local_chart.id })
            // this.local_chart = _.clonedeep(this.item)

            if (this.item && this.item.status === 'loaded') this.stats = (this.item.statistics && this.item.statistics.data) || []
            else {
                clearinterval(this.stats_interval)
                this.stats_interval = setinterval(async () => await this.local_get_stats_with_interval(), 1000)
                if (this.local_set_error_state()) return true
            }

            this.local_set_chart_key_value()
            this.local_check_currency()
            this.local_update_labels()
            this.$refs.barverticalchart.updateoptions({ ...this.local_options });
        },

        async local_get_stats_with_interval () {
            if (this.local_set_error_state()) return true

            this.stats_interval_countdown++
            await this.local_fetch_tile_item()
            if (this.item && this.item.status === 'loaded') {
                await this.local_get_stats()
                this.stats_interval_countdown = 0
                return clearInterval(this.stats_interval)
            }
        },

        local_set_chart_key_value () {
            this.keys = []
            this.values = []

            for (const item of this.local_chart.statistics.data) {
                const { key, value } = item;
                this.keys.push(key.substring(0, 3));
                this.values.push(value.toFixed())
            }

            this.$refs.barVerticalChart.updateSeries([{ name: 'Project', data: this.values }])
        },

        local_check_currency () {
            const { tile_options } = this.local_chart
            const matches = tile_options.find(option => option.value.includes('count('))
            const currencyValues = this.user_self.organization.currency

            if(matches) {
                this.local_options.yaxis.labels.formatter = (value) => { return value }
                this.local_options.tooltip.y.formatter = (value) => { return value }
                this.local_options.dataLabels.formatter = (value) => { return value }
            } else {
                this.local_options.yaxis.labels.formatter = (value) => { return formatCurrency({ value, currencyValues, restrictDigit: true }) }
                this.local_options.tooltip.y.formatter = (value) => { return formatCurrency({ value, currencyValues }) }
            }
        },

        local_set_error_state () {
            if ((this.item && this.item.status !== 'loaded') && this.stats_interval_countdown >= 10) {
                clearInterval(this.stats_interval)
                this.local_update_item_status('error')
                this.chart_loading = false
                this.stats_interval_countdown = 0
                return true
            }
            return false
        },

        async local_update_item_status (status, type = null) {
            await this.tile_update_tile_status({ id: this.local_chart.id, status })
        },

        async local_fetch_tile_item () {
            await this.tile_show({ id: this.local_chart.id, mode: 'show-modify', params: { include: 'tile_options,tile_filters,source' } })
        },

        async local_get_stats () {
            this.local_chart = _.cloneDeep(this.item)
            await this.tile_show_stats({ id: this.local_chart.id })
            this.local_chart = _.cloneDeep(this.item)
        },

        local_update_labels () {
            this.local_options.xaxis.categories = this.keys;
        },

        local_disable_animation() {
            if(this.isRearrange) {
                this.$refs.barVerticalChart.updateOptions({
                    chart: {
                        animations: {
                            enabled: false,
                        }
                    }
                })
            }
        },

        ...mapActions('Tile', {
            tile_show: 'show',
            tile_show_stats: 'show_stats',
            tile_update_tile_status: 'update_tile_status',
        }),
    },
};
</script>

<style scoped>
.c-bar-vertical-chart {
    margin: 0 auto;
    height: 227px;
    width: 552px;
}

.c-bar-vertical-chart__column {
    width: 100%;
    height: 100%;
}
</style>

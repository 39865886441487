<template>
    <v-progress-linear v-on="$listeners" v-bind="$attrs">
        <slot></slot>
    </v-progress-linear>
</template>

<script>
import { VProgressLinear } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VProgressLinear },

    data () {
        return {
            ref: null
        }
    },

    mounted () {
        this.ref = this.$el
    }
}
</script>

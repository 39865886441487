const api_endpoint = '/project_template_imports';

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        filters: {
            status: 'all',
            page: 1,
            count: 10,
            sort_field: 'name',
            sort_direction: 'asc'
        },
        response: [],
        defaults: {
            list: [],
            item: {},
            meta: {},
            filters: {
                status: 'all',
                page: 1,
                count: 10,
                sort_field: 'name',
                sort_direction: 'asc'
            },
            response: [],
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id, { params: { ...payload.params } })
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                resolve('Cleared');
            });
        },
    },
}

<template>
    <div
        class="c-nob-autocomplete"
        style="display: flex; justify-content: space-between; align-items: center"
        :class="[{ 'c-nob-autocomplete__input-error c-wiggle-short': error }]"
    >
        <div
            class="c-nob-autocomplete__input"
            :class="[inputClass]"
            style="flex-wrap: wrap; gap: 8px;"
            :style="[
                { cursor: disabled ? 'auto' : 'text' },
                { userSelect: disabled ? 'none' : 'auto' },
                { pointerEvents: disabled ? 'none' : 'auto' },
                inputStyle,
            ]"
            data-hello="world"
            @click.stop="openDropdown()"
        >
            <template v-if="multiple">
                <template v-for="item in getSelectedValues">
                    <slot name="selection" :props="{ item, removeChip, disabled }">
                        <div v-if="item" class="c-autocomplete-chips__item" :key="item.id">
                            {{ getSpreadObjectValue(item) }}
                            <span
                                v-if="removable && !disabled"
                                class="c-autocomplete-chips__remove-icon"
                                style="color: inherit; width: 15px; height: 15px; border-radius: inherit;"
                                @click.stop="removeChip(item)"
                            >
                                &times;
                            </span>
                        </div>
                    </slot>
                </template>
            </template>

            <div style="position: relative; flex: 1 0 auto;">
                <input
                    v-model="modelValue"
                    type="text"
                    ref="refInputAutoComplete"
                    :placeholder="modelValue || getSelectedList('length') ? '' : placeholder"
                    autocomplete="off"
                    @click.stop="onShowDropdown()"
                    :class="[{ 'c-disabled': disabled }]"
                    style="width: 100%;"
                >
            </div>
        </div>

        <div class="c-nob-autocomplete__error" :class="[{ 'c-nob-autocomplete__error-show': error }]">
            <slot name="error">
                {{ errorMessage }}
            </slot>
        </div>

        <slot name="clearIcon" :clear="clearValues" v-if="!disabled && clearable && (selected.length || modelValue)">
            <div class="c-clearable" @click="clearValues()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
        </slot>

        <slot name="caret" :isOpen="showDropdown" v-if="!disabled">
            <div class="c-caret" :class="[{ 'c-caret-open': showDropdown }]">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
            </div>
        </slot>

        <!-- Dropdown list -->
        <ul
            class="c-nob-autocomplete__dropdown"
            :class="[
                showDropdown ? 'c-nob-autocomplete__dropdown-show': 'c-nob-autocomplete__dropdown-hide',
                dropdownClass,
            ]"
            style="max-width: inherit;"
            :style="[{ maxHeight: dropdownHeight }]"
        >
            <template v-if="filteredDropdownItems && !filteredDropdownItems.length && !loading">
                <li class="c-nob-autocomplete__dropdown-item c-nob-autocomplete__dropdown-item--no-data">
                    <slot name="emptyData">
                        {{ noDataText }}s
                    </slot>
                </li>
            </template>
            <template v-if="loading">
                <li class="c-nob-autocomplete__dropdown-item">
                    <slot name="loader" :loading="loading">
                        <div class="c-nob-autocomplete__dropdown-item-loading">
                            Loading...
                        </div>
                    </slot>
                </li>
            </template>
            <template v-else>
                <template v-for="(item, index) in filteredDropdownItems">
                    <li
                        v-if="item.id === 'new-item' || item.id === 'no-data'"
                        :data-link-type="item.id === 'new-item' ? 'create-item' : 'no-data'"
                        class="c-nob-autocomplete__dropdown-item c-nob-autocomplete__dropdown-item--no-data"
                        tabindex="0"
                        :key="item.id"
                        :class="[
                            { 'c-nob-autocomplete__dropdown-item--is_active': item.id !== 'no-data' && currentKeyIndex === index },
                        ]"
                    >
                        <slot name="newItem" :props="{ item, onCreate, value: modelValue }" v-if="item.id === 'new-item'">
                            <div @click="onCreate(item)" style="display: flex; align-items: center; color: #666; max-width: 420px;">
                                <span style="font-size: 24px; padding-bottom: 2px; height: 20px; display: flex; align-items: center; justify-content: center;">&plus;</span>
                                <span style="font-size: 16px; display: inline-flex; align-items: center; margin-left: 4px; max-width: inherit;">
                                    {{ getSpreadObjectValue(item) }}
                                    <span class="truncate-text" style="font-weight: 500; color: #111; margin-left: 4px; display: block; max-width: inherit;">{{ modelValue }}</span>
                                </span>
                            </div>
                        </slot>
                        <slot name="noData" :item="item" v-if="item.id === 'no-data'">
                            <span style="color: #666; font-size: 14px;">{{ getSpreadObjectValue(item) }}</span>
                        </slot>
                    </li>
                    <li
                        v-else
                        :key="item.id"
                        class="c-nob-autocomplete__dropdown-item"
                        tabindex="0"
                        :class="[
                            { 'c-nob-autocomplete__dropdown-item--is_active': currentKeyIndex === index || (getSelectedList('length') && (getSelectedList()[0]['id'] === item.id)) },
                        ]"
                        @click="onSelectItem(item)"
                    >
                        <slot name="item" :props="{ item, onSelect: onSelectItem }">
                            {{ getSpreadObjectValue(item) }}
                        </slot>
                    </li>
                </template>
            </template>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'GlobalUsersAutocomplete',

	props: {
		value: {
			type: Array | Object | String,
			required: true
		},
		list: {
			type: Array,
			required: true
		},
        itemText: {
			type: String,
			default: 'name'
		},
        itemValue: {
			type: String,
			default: 'value'
		},
        placeholder: {
            type: String,
            default: 'Select Value'
        },
        errorMessage: {
            type: String,
            default: ''
        },
		error: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		creatable: {
			type: Boolean,
			default: false
		},
		multiple: {
			type: Boolean,
			default: false
		},
		removable: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		returnObject: {
			type: Boolean,
			default: false
		},
		clearOnSelect: {
			type: Boolean,
			default: false
		},
		inputClass: {
			type: String | Object,
            default: ''
        },
		inputStyle: {
			type: String | Object,
            default: ''
        },
        dropdownHeight: {
            type: String,
            default: '400px'
        },
        dropdownClass: {
            type: String | Object,
            default: ''
        },
        noDataText: {
            type: String,
            default: 'No Data Found'
        },
	},

    data () {
        return {
            modelValue: null,
            searchOnOpen: false,
            selected: [],
            showDropdown: false,
            currentKeyIndex: 0,
            searchText: null,
            filteredItems: [],
        }
    },

    watch: {
        value: {
            handler (val, oldVal) {
                if (!val && !this.multiple) return this.clearValues()
                if (!val) return this.modelValue = null

                if (Array.isArray(val)) this.selected = [...val]
                else this.selected = [val]

                if (!this.multiple && (typeof val) === 'object') this.modelValue = this.getSpreadObjectValue(val)
            },
            immediate: true,
            deep: true
        },

        list: {
            handler (val, oldVal) {
                this.populateList()
            },
            deep: true
        },

        modelValue (val, oldVal) {
            if (val && (val !== oldVal)) this.onSearch(val)
            if (!val && !this.multiple) {
                this.selected = []
                this.updateValue()
            }
            this.populateList()
        },

        showDropdown (val, oldVal) {
            if (val) this.setDropdownPosition()
        },

        loading (val, oldVal) {
            if (!val && oldVal) this.setDropdownPosition()
        },
    },

    mounted () {
        //window.addEventListener('scroll', this.setDropdownPosition)
        window.addEventListener('resize', this.setDropdownPosition)
        this.$el.addEventListener('keydown', this.onKeyDown)
        document.addEventListener('keydown', this.onGlobalKeyDown)
        document.addEventListener('click', this.onClickOut)
    },

    beforeDestroy () {
        //window.removeEventListener('scroll', this.setDropdownPosition)
        window.removeEventListener('resize', this.setDropdownPosition)
        this.$el.removeEventListener('keydown', this.onKeyDown)
        document.removeEventListener('keydown', this.onGlobalKeyDown)
        document.removeEventListener('click', this.onClickOut)
    },

    computed: {
        createItem () {
            return { id: 'new-item', ...this.addValueToSpreadObj('Create') }
        },

        noDataItem () {
            return { id: 'no-data', ...this.addValueToSpreadObj(this.noDataText) }
        },

        filteredDropdownItems () {
            if (!this.multiple) return this.filteredItems

            const list = this.filteredItems.filter(item => {
                const index = this.getSelectedValues.findIndex(i =>
                    this.getSpreadObjectValue(i, this.itemValue) === this.getSpreadObjectValue(item, this.itemValue)
                )
                return index !== -1 ? null : item
            })

            if (list.length === 0) list[0] = this.noDataItem
            return list
        },

        getSelectedValues () {
            if (this.returnObject) return this.selected
            return this.filteredItems.filter(item => {
                const index = this.selected.findIndex(i => i === this.getSpreadObjectValue(item, this.itemValue))
                return index !== -1 ? item : null
            })
        },
    },

    methods: {
        onSearch (value) {
            if (this.disabled) return

            this.emit('search', value)
            if (!this.showDropdown) this.showDropdown = true
            this.setDropdownPosition()
            //this.populateList()
        },

        /* Crud items - Start */
        onCreate () {
            if (this.disabled) return

            const newItem = { id: this.getId(), ...this.addValueToSpreadObj(this.modelValue) }
            if (this.multiple) this.multipleCreate(newItem)
            else this.singleCreate(newItem)

            this.closeDropdown()
            this.focusInput()
        },

        singleCreate (newItem) {
            this.selected = [{ ...newItem }]
            this.modelValue = this.getSpreadObjectValue(newItem)
            this.updateValue()
			this.emit('create', { ...newItem, selected: this.getSelectedValues })
        },

        multipleCreate (newItem) {
            this.selected.push({ ...newItem })
            this.updateValue()
			this.emit('create', { ...newItem, selected: this.getSelectedValues })
            this.modelValue = null
        },

        removeChip (item) {
            const index = this.selected.findIndex(i =>
                this.returnObject ? this.getSpreadObjectValue(i, this.itemValue) === this.getSpreadObjectValue(item, this.itemValue) : i === this.getSpreadObjectValue(item, this.itemValue)
            )
            if (index === -1) return

			const removedItem = this.selected.splice(index, 1)
            this.updateValue()
			this.emit('remove', removedItem[0])
        },
        /* Crud items - End */

        /* On clicking outside of input context - Start */
        onClickOut (evt) {
            this.onOutsideClick(evt)
        },

        onOutsideClick (evt) {
            if (this.$el.contains(evt.target)) return
            this.closeDropdown()
            if (this.multiple) return

            this.modelValue = this.getSelectedList('length') && !this.multiple ? this.getSpreadObjectValue(this.getSelectedList()[0]) : null
        },
        /* On clicking outside of input context - End */

        /* Global keyboard events - Start */
        onGlobalKeyDown (evt) {
            this.onEscape(evt)
        },

        onEscape (evt) {
            if (evt.code !== 'Escape') return
            if (evt.shiftKey || evt.ctrlKey) return

            this.modelValue = this.getSelectedList('length') && !this.multiple ? this.getSpreadObjectValue(this.getSelectedList()[0]) : null
            this.closeDropdown()
        },
        /* Global keyboard events - End */

        /* Input keyboard controls and events - Start */
        onKeyDown (evt) {
            this.onKeyArrowUp(evt)
            this.onKeyArrowDown(evt)
            this.onKeyEnter(evt)
            this.onBackspace(evt)
        },

        onKeyArrowUp (evt) {
            if (evt.code !== 'ArrowUp') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (this.loading) return

            evt.preventDefault()
            if (this.currentKeyIndex === 0 || this.currentKeyIndex === null) this.currentKeyIndex = this.filteredDropdownItems.length - 1
            else this.currentKeyIndex = this.currentKeyIndex - 1

            this.scrollToCurrentListItem()
            setTimeout(() => this.focusInput(), 0)
        },

        onKeyArrowDown (evt) {
            if (evt.code !== 'ArrowDown') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (this.loading) return

            evt.preventDefault()
            if (this.currentKeyIndex === null) this.currentKeyIndex = 0
            else if (this.currentKeyIndex === this.filteredDropdownItems.length - 1) this.currentKeyIndex = 0
            else this.currentKeyIndex = this.currentKeyIndex + 1

            this.scrollToCurrentListItem()
            setTimeout(() => this.focusInput(), 0)
        },

        onKeyEnter (evt) {
            if (this.disabled) return
            if (evt.code !== 'Enter') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (this.loading) return

            evt.preventDefault()
            const currentSelected = this.filteredDropdownItems[this.currentKeyIndex]
            const value = currentSelected.id === 'new-item' ? this.modelValue : this.getSpreadObjectValue(currentSelected)
            if (!this.multiple) this.modelValue = value
            if (currentSelected.id === 'new-item') this.onCreate(value)
            else this.onSelectItem(currentSelected)
        },

        onBackspace (evt) {
            if (this.disabled || !this.multiple) return
            if (evt.code !== 'Backspace') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (this.loading) return
            if (this.modelValue) return

            const removedItem = this.selected.splice(-1)
            this.updateValue()
			this.emit('remove', removedItem[0])
            if (this.selected.length) this.setDropdownPosition()
        },

        scrollToCurrentListItem () {
            const listChildren = this.$el.querySelector('ul')
            if (!listChildren) return

            const childrenArr = Array.from(listChildren.children)
            if (childrenArr && childrenArr[this.currentKeyIndex]) {
                setTimeout(() => childrenArr[this.currentKeyIndex].focus(), 0)
            }
        },
        /* Input keyboard controls and events - End */

        /* Items selection - Start */
        onSelectItem (item) {
            if (this.disabled) return

            if (this.multiple) this.multipleSelect(item)
            else this.singleSelect(item)
            this.closeDropdown()
            this.focusInput()
        },

        singleSelect (item) {
            let value = null
            if (this.returnObject) value = item
            else value = this.getSpreadObjectValue(item, this.itemValue)

            this.selected = [value]
            if (this.clearOnSelect) this.modelValue = null
            else this.modelValue = this.getSpreadObjectValue(item)

            this.updateValue()
			this.emit('select', value, this.getSelectedValues)
            this.currentKeyIndex = null
        },

        multipleSelect (item) {
            let value = null
            if (this.returnObject) value = item
            else value = this.getSpreadObjectValue(item, this.itemValue)

            this.selected.push(value)
            this.updateValue()
			this.emit('select', value, this.getSelectedValues)
            this.modelValue = null
            this.currentKeyIndex = null
        },
        /* Items selection - End */

        /* Dropdown visibility - Start */
        onShowDropdown () {
            if (this.disabled || this.showDropdown) return

            if (this.getSelectedList('length')) this.currentKeyIndex = null
            this.filteredItems = [...this.list]
            this.showDropdown = true
            this.onSearch(this.modelValue)
        },

        closeDropdown () {
            setTimeout(() => {
                this.setDefaultDropdowPosition()
                this.showDropdown = false
            }, 0)
        },
        /* Dropdown visibility - End */

        /* Extras - Start */
        getSpreadObjectValue (field, prop = this.itemText) {
            const [firstSlug, secondSlug] = prop.split('.')
            if (secondSlug) {
                if (firstSlug === 'user' && !field[firstSlug]) return field['name']
                return field[firstSlug][secondSlug] ?? ''
            }
            return field[firstSlug] ?? ''
        },

        addValueToSpreadObj (value, prop = this.itemText) {
            const [firstSlug, secondSlug] = prop.split('.')
            const field = secondSlug ? { [firstSlug]: { [secondSlug]: value } } : { [firstSlug]: value }
            return field
        },

        clearValues () {
            this.selected = []
            this.modelValue = null
            this.focusInput()
        },

        setDropdownPosition () {
            setTimeout(() => this.positionDropdown(), 0)
        },

        populateList () {
            let list = this.filteredListItems(this.modelValue)
            if (this.modelValue && this.creatable) list.push(this.createItem)
            if (!this.creatable && !list.length) list.push(this.noDataItem)
            this.currentKeyIndex = 0
            this.filteredItems = list
        },

        filteredListItems (value) {
            if (!value) return this.list

            return this.list.filter(item => {
                return this.getSpreadObjectValue(item).toLowerCase().includes(value.toLowerCase())
            })
        },

        updateValue () {
            this.emit('input', this.multiple ? this.selected : this.selected[0])
        },

        getId () {
            return new Date().toISOString()
        },

        getSelectedList (field = 'items') {
            if (field === 'length') return this.selected && this.selected.length
            if (field === 'items') return this.selected
        },

        focusInput () {
            const input = this.$el && this.$el.querySelector('input')
            if (input) input.focus()
        },

        openDropdown () {
            this.focusInput()
            this.onShowDropdown()
        },

        positionDropdown () {
            const input = this.$el
            const dropdown = this.$el.querySelector('.c-nob-autocomplete__dropdown')
            //const body = document.body
            if (!dropdown || !input) return

            const inputRect = input.getBoundingClientRect()
            const dropdownRect = dropdown.getBoundingClientRect()
            //const bodyHeight = body.clientHeight
            const bodyHeight = window.innerHeight
            const top = inputRect.bottom
            const left = inputRect.left + window.scrollX
            const width = inputRect.width

            if ((bodyHeight - inputRect.bottom) < dropdownRect.height) {
                dropdown.style.cssText += `top: ${inputRect.top - dropdownRect.height}px !important; left: ${left}px !important; width: ${width}px !important;`
            } else {
                dropdown.style.cssText += `top: ${top}px !important; left: ${left}px !important; width: ${width}px !important;`
            }
        },

        setDefaultDropdowPosition () {
            const dropdown = this.$el.querySelector('.c-nob-autocomplete__dropdown')
            dropdown.style.cssText += 'top: 100% !important; bottom: auto !important;'
        },

		emit (label, args) {
			this.$emit(label, args)
		}
        /* Extras - End */
    }
}
</script>

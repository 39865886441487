<template>
    <a-card class="u-relative white u-rounded-corners-lg">
        <div class="py-5 px-6 u-flex-center-y">
            <h2 class="md-heading-6 grey--text text--darken-3">Review {{ module }} ({{ localListLength }})</h2>
            <a-spacer></a-spacer>
            <a-icon size="20" color="grey darken-1" class="u-cursor-pointer" @click="$emit('close')">clear</a-icon>
        </div>
        <a-divider></a-divider>


        <v-slide-y-transition hide-on-leave>
            <a-sheet v-if="!localListLength" min-height="250" max-height="600" class="u-overflow-y u-flex-center transparent pa-6 c-tiny-scroll">
                <div class="text-center">
                    <a-icon size="32" color="grey lighten-1">info</a-icon>
                    <h3 class="md-subtitle-1 grey--text text--darken-1 mt-1">No Data Found</h3>
                </div>
            </a-sheet>

            <a-sheet v-else min-height="250" max-height="600" class="u-overflow-y transparent pa-6 c-tiny-scroll">
                <a-list class="ma-0 pa-0">
                    <a-list-item class="pa-0" :class="{ 'mt-4': index !== 0 }" v-for="(listItem, index) in localList" :key="listItem.id">
                        <div class="u-flex align-start u-wfull">
                            <a-checkbox :value="localIsSelected(listItem.id)" @change="localSelectItem(listItem)" :disabled="localGetLength(listItem.title) > 1000" :ripple="false" color="blue" class="ma-0 pa-0 mr-2" hide-details dense></a-checkbox>
                            <div class="u-wfull">
                                <div class="u-flex align-start u-wfull">
                                    <textarea
                                        v-model="listItem.title"
                                        rows="4"
                                        placeholder="Add title here..."
                                        :class="[{ 'grey lighten-4': currentEditId === listItem.id }]"
                                        class="u-wfull md-body-2 body--text text--darken-1 c-tiny-scroll grey lighten-5 d-block u-rounded-corners-lg px-2 py-1"
                                        style="outline: none; resize: none;"
                                        @click="currentEditId = listItem.id"
                                        @input="localCheckTitle(listItem)"
                                    ></textarea>
                                    <!-- <a-spacer></a-spacer> -->
                                    <!-- <a-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <a-icon v-on="on" class="ml-3 u-cursor-pointer" v-ripple="true" size="18" color="body lighten-1">clear</a-icon>
                                        </template>
                                        <span>Remove?</span>
                                    </a-tooltip> -->
                                </div>
                                <div class="u-flex-center-y mt-1">
                                    <div :class="[localGetLength(listItem.title) > 1000 ? 'red--text text--darken-1' : 'grey--text text--darken-1', 'md-caption']">({{ localGetLength(listItem.title) }}/1000)</div>
                                    <span class="mx-2 d-inline-block grey--text text--lighten-1 mb-1">&bull;</span>
                                    <span :class="['md-caption u-cursor-pointer', confirmId !== listItem.id ? 'body--text text--lighten-2' : 'red--text text--lighten-2']" @click="confirmId !== listItem.id ? localConfirmRemove(listItem.id) : localRemove(listItem.id)">Remove</span>
                                </div>
                            </div>
                        </div>
                    </a-list-item>
                </a-list>
            </a-sheet>
        </v-slide-y-transition>

        <a-divider></a-divider>
        <div class="u-flex-center-y pa-6">
            <a-btn class="primary darken-1 ma-0 u-rounded-corners" depressed :disabled="!selectedIds.length" @click="localCreateUcs()">Create {{ module }} {{ selectedIds.length ? `(${selectedIds.length})` : '' }}</a-btn>
            <a-btn class="grey--text text--darken-1 ma-0 ml-3 u-rounded-corners" text @click="$emit('close')">Cancel</a-btn>
            <a-spacer></a-spacer>
            <h4 class="blue-grey--text text--darken-1 md-caption font-weight-medium"><span class="font-weight-bold">Note:</span> Empty title will be ignored</h4>
        </div>
    </a-card>
</template>

<script>
import { Validate } from '@/helpers/helper-validations'

export default {
    props: {
        list: {
            type: Array
        },
        module: {
            type: String
        },
        isOpen: {
            type: Boolean
        }
    },

    data () {
        return {
            localList: [],
            selectedIds: [],
            clearedItems: [],
            currentEditId: null,
            confirmId: null,
        }
    },

    watch: {
        isOpen: {
            handler (val) {
                if (!val) return
                this.localReset()
                this.localList = _.cloneDeep(this.list)
            },
            immediate: true
        }
    },

    computed: {
        localListLength () {
            return this.localList && this.localList.length
        }
    },

     methods: {
         localCreateUcs () {
             const clearedItems = _.cloneDeep([])
             for (let index = 0; index < this.clearedItems.length; index++) {
                 const item = this.clearedItems[index]
                 const hasError = this.localCheckTitle(item)
                 if (hasError) {
                     this.clearedItems.splice(index, 1)
                     continue
                 }
                 clearedItems.push(item)
             }
             this.$emit('create', clearedItems)
             this.$emit('close')
         },

         localConfirmRemove (id) {
             this.confirmId = id
             setTimeout(() => this.confirmId = null, 3000)
         },

         localRemove (id) {
             const index = this.localList.findIndex(item => item.id === id)
             if (index !== -1) this.localList.splice(index, 1)
             this.$emit('remove', this.localList, this.module)
         },

         localCheckTitle (item) {
            const { message } = new Validate(item.title, { silent: true }).length(1, 1000).run()
            const selectedIndex = this.clearedItems.findIndex(selectedItem => selectedItem.id === item.id)
            const idIndex = this.selectedIds.findIndex(id => id === item.id)
            if (!message) {
                if (selectedIndex === -1) this.clearedItems.push(item)
                if (idIndex === -1) this.selectedIds.push(item.id)
                return false
            }
            if (selectedIndex !== -1) this.clearedItems.splice(selectedIndex, 1)
            if (idIndex !== -1) this.selectedIds.splice(idIndex, 1)
            return true
         },

         localGetLength (text) {
             if (!text || (text && !text.trim())) return 0
             return text.trim().length
         },

         localSelectItem ({ id, title }) {
             const selectedIndex = this.clearedItems.findIndex(selectedItem => selectedItem.id === id)
             const index = this.selectedIds.findIndex(item => item === id)
             if (index !== -1) this.selectedIds.splice(index, 1)
             else this.selectedIds.push(id)

             if (index !== -1) this.clearedItems.splice(selectedIndex, 1)
         },

         localIsSelected (id) {
             return this.selectedIds.includes(id)
         },

         localReset () {
            this.selectedIds = []
            this.clearedItems = []
            this.localList = []
            this.currentEditId = null
            this.confirmId = null
         },
    }
}
</script>

<template>
    <div>
        <!-- <div class="px-2 py-4"> -->
        <a-container container--fluid grid-list-xl pa-0 my-10 mt-4>
            <a-layout wrap align-center>
                <a-flex shrink py-0 v-if="$can('inventories.update')">
                    <template v-if="project_item && (project_item.status === 'active' || project_item.status === 'queue') || project_item.stage && project_item.stage.name === 'Queue'">
                        <a-btn small dark height="36" color="blue" class="ma-0 px-4 elevation-0" :to="{ name: 'inventory-book', params: { id: $route.params.id } }">Book Inventory</a-btn>
                    </template>
                    <template v-else>
                        <a-btn small height="36" color="blue" class="ma-0 px-4 elevation-0" :disabled="true">Book Inventory</a-btn>
                    </template>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink class="py-0 u-flex align-center">
                    <div class="u-flex align-center u-rounded-corners-lg u-border">
                        <a-btn small :loading="local_loading && current_view === 'In Use'" :class="{ 'grey lighten-2 grey--text text--darken-2': current_view === 'In Use', 'white grey--text text--darken-1': current_view !== 'In Use' }" height="36" class="ma-0 elevation-0 u-rounded-corners-lg radius-right-none" @click="local_change_booking_views('In Use')">In Use</a-btn>
                        <a-btn small :loading="local_loading && current_view === 'Booked'" :class="{ 'grey lighten-2 grey--text text--darken-2': current_view === 'Booked', 'white grey--text text--darken-1': current_view !== 'Booked' }" height="36" class="ma-0 u-rounded-corners-lg elevation-0 radius-none" @click="local_change_booking_views('Booked')">Booked</a-btn>
                        <a-btn small :loading="local_loading && current_view === 'Returned'" :class="{ 'grey lighten-2 grey--text text--darken-2': current_view === 'Returned', 'white grey--text text--darken-1': current_view !== 'Returned' }" height="36" class="ma-0 u-rounded-corners-lg elevation-0 radius-left-none" @click="local_change_booking_views('Returned')">Returned</a-btn>
                    </div>
                </a-flex>
            </a-layout>
        </a-container>
        <!-- <div class="pa-2"> -->
        <a-container container--fluid grid-list-xl pa-0 v-if="inventory_list && inventory_list.length && !local_loading">
            <a-layout align-center class="mb-4" v-for="(inventories, group_index) in inventory_list" :key="inventories + '-'+ group_index">
                <a-flex class="py-0" v-if="inventories.value">
                    <div class="u-border u-rounded-corners">
                        <div class="elevation-0 white c-border-c7 pb-0 pt-0 mb-0">
                            <div class="grey lighten-5 px-6 py-4">
                                <a-sheet class="d-flex align-center u-wfull transparent">
                                    <a-sheet class="transparent" v-if="inventories.value[0]">
                                        <div class="d-inline-block">
                                            <span class="md-body-2 grey--text text--darken-1">Assigned to</span>
                                            <a-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <span class="md-body-2 grey--text text--darken-3 ml-1 font-weight-medium" v-if="inventories.value[0].assignee[0] && inventories.value[0].assignee[0].name" v-on="on">{{ local_truncate_text(inventories.value[0].assignee[0].name, 25) }}</span>
                                                </template>
                                                <div class="d-flex align-center">
                                                    <g-avatar :item="inventories.value[0].assignee[0]" no-border></g-avatar>
                                                    <g-profile-info :item="inventories.value[0].assignee[0]" dark type-user></g-profile-info>
                                                </div>
                                            </a-tooltip>
                                            <!-- <span class="md-body-2 grey--text text--darken-1 ml-1">on <span class="md-body-2 grey--text text--darken-1 font-weight-medium">{{ local_format_date(inventories.value[0].created_at) }}</span></span> -->
                                        </div>
                                        <a-icon size="6" color="grey darken-1" class="mx-2">fiber_manual_record</a-icon>
                                        <div class="d-inline-block">
                                            <span class="md-body-2 grey--text text--darken-1">Requested by</span>
                                            <a-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <span class="md-body-2 grey--text text--darken-3 ml-1 font-weight-medium" v-if="inventories.value[0].requester && inventories.value[0].requester.name" v-on="on">{{ local_truncate_text(inventories.value[0].requester.name, 25) }}</span>
                                                </template>
                                                <div class="d-flex align-center">
                                                    <g-avatar :item="inventories.value[0].requester" no-border></g-avatar>
                                                    <g-profile-info :item="inventories.value[0].requester" type-user dark></g-profile-info>
                                                </div>
                                            </a-tooltip>
                                            <span class="md-body-2 grey--text text--darken-1 ml-1">on <span class="md-body-2 grey--text text--darken-1 font-weight-medium">{{ local_format_date(inventories.value[0].created_at) }}</span></span>
                                        </div>
                                        <a-icon size="6" color="grey darken-1" class="mx-2">fiber_manual_record</a-icon>
                                        <a-sheet class="d-inline-flex transparent align-center justify-start pr-2" v-if="inventories.value[0]">
                                            <!-- <a-icon size="16" color="grey darken-1" class="mr-1">place</a-icon> -->
                                            <h4 class="md-body-2 font-weight-medium grey--text text--darken-1 text-truncate" :title="inventories.value[0].location" v-if="inventories.value[0].location" style="max-width: 400px">Location: {{ inventories.value[0].location }}</h4>
                                            <h4 class="md-body-2 font-weight-medium grey--text text--darken-1 text-truncate" v-else>No Location</h4>
                                        </a-sheet>
                                    </a-sheet>
                                    <a-spacer></a-spacer>
                                    <a-btn small v-if="$can('inventories.update')" :to="{ name: 'booking-extend', params: { id: inventories.value[0].batch } }" dark color="blue" class="ma-0 px-3 py-1 elevation-0">Manage</a-btn>
                                </a-sheet>
                            </div>
                        </div>
                        <a-card class="transparent elevation-0">
                            <div v-if="!local_accordion_inventory_status(group_index)">
                                <a-divider class="grey lighten-3"></a-divider>
                                <div class="px-3 grey lighten-5">
                                    <table class="u-wfull relative" style="position: relative; border-collapse: collapse">
                                        <tr class="mb-2">
                                            <th class="caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-2 px-3" style="letter-spacing: 1px; min-width: 128px !important; max-width: 128px !important">Category</th>
                                            <th class="caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-2 px-3" style="letter-spacing: 1px; min-width: 176px !important; max-width: 176px !important">Name</th>
                                            <th class="caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-2 px-3" style="letter-spacing: 1px; min-width: 160px !important; max-width: 160px !important">Part #</th>
                                            <th class="caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-2 px-3" style="letter-spacing: 1px; min-width: 160px !important; max-width: 160px !important">Serial #</th>
                                            <th class="caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-2 px-3" style="letter-spacing: 1px; min-width: 144px !important; max-width: 144px !important">Stage #</th>
                                            <th class="caption font-weight-bold grey--text text--darken-2 text-uppercase text-right py-2 px-3" style="letter-spacing: 1px; min-width: 112px !important; max-width: 112px !important">Booking Range</th>
                                        </tr>
                                    </table>
                                </div>
                                <a-divider class="grey lighten-3"></a-divider>
                                <div v-for="(inventory, index) in inventories.value" :key="inventory + '-'+ index">
                                    <a-divider v-if="index !== 0" class="u-divider"></a-divider>
                                    <div class="py-2 px-3 white elevation-0">
                                        <table class="u-wfull" style="border-collapse: collapse">
                                            <tr class="u-wfull">
                                                <template v-if="inventory.inventory_trashed">
                                                    <td class="body-2 py-1 px-3 text-truncate grey--text text--darken-3" style="min-width: 128px !important; max-width: 128px !important;">
                                                        <h4 class="body-2 text-truncate" :title="inventory.inventory_trashed.category.value" v-if="inventory.inventory_trashed && inventory.inventory_trashed.category">{{ inventory.inventory_trashed.category.value }}</h4>
                                                        <h4 class="body-2 text-left text-truncate" v-else>No Category</h4>
                                                    </td>
                                                    <td class="body-2 py-1 px-3 text-truncate grey--text text--darken-3" style="min-width: 176px !important; max-width: 176px !important;">
                                                        <div class="u-flex align-center">
                                                            <h4 class="body-2 text-truncate" :title="inventory.inventory_trashed.name">{{ inventory.inventory_trashed.name }}</h4>
                                                            <span class="caption red--text text--darken-1 d-inline-block px-2 ml-2" style="border-radius: 50px; border: 1px solid #ef5350;">
                                                                Del<span class="hidden-lg-and-down">eted</span>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td class="body-2 py-1 px-3 text-truncate grey--text text--darken-3" style="min-width: 160px !important; max-width: 160px !important;">
                                                        <h4 class="body-2 text-left text-truncate" :title="inventory.inventory_trashed.part_no" v-if="inventory.inventory_trashed.part_no">{{ inventory.inventory_trashed.part_no }}</h4>
                                                        <h4 class="body-2 text-left text-truncate" v-else> - </h4>
                                                    </td>
                                                    <td class="body-2 py-1 px-3 text-truncate grey--text text--darken-3" style="min-width: 160px !important; max-width: 160px !important;">
                                                        <h4 class="body-2 text-left text-truncate" :title="inventory.inventory_trashed.serial_no" v-if="inventory.inventory_trashed.serial_no">{{ inventory.inventory_trashed.serial_no }}</h4>
                                                        <h4 class="body-2 text-left text-truncate" v-else> - </h4>
                                                    </td>
                                                    <td class="body-2 py-1 px-3 text-truncate grey--text text--darken-3" style="min-width: 144px !important; max-width: 144px !important;">
                                                        <template v-if="current_view === 'In Use'">
                                                            <a-menu offset-y content-class="u-rounded-corners u-elevation-custom-1 white u-border" v-if="inventory.inventory_trashed" :disabled="!permission_inventory('update')" max-width="350" class="u-wfull">
                                                                <template v-slot:activator="{ on }">
                                                                    <div v-on="on" class="u-flex align-center mx-auto u-wfull px-2 u-border u-rounded-corners u-cursor-pointer" style="min-height: 30px; padding-top: 2px !important; padding-bottom: 2px !important; max-width: 160px !important;" :style="{backgroundColor: inventory.inventory_trashed.custom_status ? $color(inventory.inventory_trashed.custom_status.color, 'color_bg') : '#eee'}">
                                                                        <span v-if="inventory.inventory_trashed.custom_status" class="u-rounded-corners d-inline-block mx-auto md-body-2" style="font-size: 12px; min-width: 50px;" :title="inventory.inventory_trashed.custom_status.label" :style="{backgroundColor: $color(inventory.inventory_trashed.custom_status.color, 'color_bg'), color: $color(inventory.inventory_trashed.custom_status.color, 'color_text')}">{{ local_truncate_text(inventory.inventory_trashed.custom_status.label, $vuetify.breakpoint.lgAndDown ? 20 : 28, '..') }}</span>
                                                                        <span v-else class="u-rounded-corners md-body-2 grey--text text--darken-2 d-inline-block mx-auto text-truncate" style="font-size: 12px; min-width: 50px;">Select Stage</span>
                                                                        <a-spacer></a-spacer>
                                                                        <a-icon size="14" v-if="permission_inventory('update')" :style="{ color: inventory.inventory_trashed.custom_status ? $color(inventory.inventory_trashed.custom_status.color, 'color_text') : '#333' }">keyboard_arrow_down</a-icon>
                                                                    </div>
                                                                </template>
                                                                <a-list style="max-height: 300px;" class="overflow-y-auto c-scrollbar u-list-condensed">
                                                                    <a-list-item class="md-body-2 grey--text text--darken-2" @click="local_add_inventory_stage_tag(stage, inventory.inventory_trashed.id, inventories.key)" v-for="stage in tag_list" :key="stage.id">
                                                                        {{ stage.label }}
                                                                    </a-list-item>
                                                                </a-list>
                                                                <template v-if="tag_list.length">
                                                                    <a-divider></a-divider>
                                                                    <a-list class="u-list-condensed py-0">
                                                                        <a-list-item class="md-caption" @click="local_clear_inventory_stage_tag(inventory.inventory_trashed.id, inventories.key)">
                                                                            <a-list-item-content class="md-caption red--text text--darken-1">Remove Stage</a-list-item-content>
                                                                        </a-list-item>
                                                                    </a-list>
                                                                </template>
                                                            </a-menu>
                                                        </template>
                                                        <template v-else>
                                                            <h4 class="body-2 px-2"> - </h4>
                                                        </template>
                                                    </td>
                                                </template>
                                                <template v-else>
                                                    <td class="body-2 py-1 px-3 text-truncate grey--text text--darken-3" style="min-width: 128px !important; max-width: 128px !important;">
                                                        <h4 class="body-2 text-truncate" :title="inventory.inventory.category.value" v-if="inventory.inventory && inventory.inventory.category">{{ inventory.inventory.category.value }}</h4>
                                                        <h4 class="body-2 text-left text-truncate" v-else>No Category</h4>
                                                    </td>
                                                    <td class="body-2 py-1 px-3 text-truncate grey--text text--darken-3" style="min-width: 176px !important; max-width: 176px !important;">
                                                        <template v-if="inventory.inventory && inventory.inventory.inventory_trashed">
                                                            <div class="u-flex align-center">
                                                                <h4 class="body-2 text-truncate" :title="inventory.inventory.name">{{ inventory.inventory.name }}</h4>
                                                                <span class="caption red--text text--darken-1 d-inline-block px-2 ml-2" style="border-radius: 50px; border: 1px solid #ef5350;">
                                                                    Del<span class="hidden-lg-and-down">eted</span>
                                                                </span>
                                                            </div>
                                                        </template>
                                                        <h4 v-else class="body-2 text-truncate" :title="inventory.inventory.name">{{ inventory.inventory.name }}</h4>
                                                    </td>
                                                    <td class="body-2 py-1 px-3 text-truncate grey--text text--darken-3" style="min-width: 160px !important; max-width: 160px !important;">
                                                        <h4 class="body-2 text-left text-truncate" :title="inventory.inventory.part_no" v-if="inventory.inventory.part_no">{{ inventory.inventory.part_no }}</h4>
                                                        <h4 class="body-2 text-left text-truncate" v-else> - </h4>
                                                    </td>
                                                    <td class="body-2 py-1 px-3 text-truncate grey--text text--darken-3" style="min-width: 160px !important; max-width: 160px !important;">
                                                        <h4 class="body-2 text-left text-truncate" :title="inventory.inventory.serial_no" v-if="inventory.inventory.serial_no">{{ inventory.inventory.serial_no }}</h4>
                                                        <h4 class="body-2 text-left text-truncate" v-else> - </h4>
                                                    </td>
                                                    <td class="body-2 py-1 px-3 text-truncate grey--text text--darken-3" style="min-width: 144px !important; max-width: 144px !important;">
                                                        <template v-if="current_view === 'In Use'">
                                                            <a-menu offset-y content-class="u-rounded-corners u-elevation-custom-1 white u-border" v-if="inventory.inventory" :disabled="!permission_inventory('update')" max-width="350" class="u-wfull">
                                                                <template v-slot:activator="{ on }">
                                                                    <div v-on="on" class="u-flex align-center mx-auto u-wfull px-2 u-border u-rounded-corners u-cursor-pointer" style="min-height: 30px; padding-top: 2px !important; padding-bottom: 2px !important; max-width: 160px !important;" :style="{backgroundColor: inventory.inventory.custom_status ? $color(inventory.inventory.custom_status.color, 'color_bg') : '#eee'}">
                                                                        <span v-if="inventory.inventory.custom_status" class="u-rounded-corners d-inline-block mx-auto md-body-2" style="font-size: 12px; min-width: 50px;" :title="inventory.inventory.custom_status.label" :style="{backgroundColor: $color(inventory.inventory.custom_status.color, 'color_bg'), color: $color(inventory.inventory.custom_status.color, 'color_text')}">{{ local_truncate_text(inventory.inventory.custom_status.label, $vuetify.breakpoint.lgAndDown ? 20 : 28, '..') }}</span>
                                                                        <span v-else class="u-rounded-corners md-body-2 grey--text text--darken-2 d-inline-block mx-auto text-truncate" style="font-size: 12px; min-width: 50px;">Select Stage</span>
                                                                        <a-spacer></a-spacer>
                                                                        <a-icon size="14" v-if="permission_inventory('update')" :style="{ color: inventory.inventory.custom_status ? $color(inventory.inventory.custom_status.color, 'color_text') : '#333' }">keyboard_arrow_down</a-icon>
                                                                    </div>
                                                                </template>
                                                                <a-list style="max-height: 300px;" class="overflow-y-auto c-scrollbar u-list-condensed">
                                                                    <a-list-item class="md-body-2 grey--text text--darken-2" @click="local_add_inventory_stage_tag(stage, inventory.inventory.id, inventories.key)" v-for="stage in tag_list" :key="stage.id">
                                                                        {{ stage.label }}
                                                                    </a-list-item>
                                                                </a-list>
                                                                <template v-if="tag_list.length">
                                                                    <a-divider></a-divider>
                                                                    <a-list class="u-list-condensed py-0">
                                                                        <a-list-item class="md-caption" @click="local_clear_inventory_stage_tag(inventory.inventory.id, inventories.key)">
                                                                            <a-list-item-content class="md-caption red--text text--darken-1">Remove Stage</a-list-item-content>
                                                                        </a-list-item>
                                                                    </a-list>
                                                                </template>
                                                            </a-menu>
                                                        </template>
                                                        <template v-else>
                                                            <h4 class="body-2 px-2"> - </h4>
                                                        </template>
                                                    </td>
                                                </template>
                                                <td class="body-2 py-1 px-3 text-truncate text-right grey--text text--darken-3" style="min-width: 112px !important; max-width: 112px !important;">
                                                    <div class="u-relative d-inline-block">
                                                        <div v-if="inventory.force_extended === 1" class="u-absolute u-flex align-center" style="top: 50%; left: -18px; z-index: 1; transform: translateY(-50%);">
                                                            <a-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <a-icon v-on="on" size="16" color="orange darken-3">info</a-icon>
                                                                </template>
                                                                <span>Overdue</span>
                                                            </a-tooltip>
                                                        </div>
                                                        <h4 class="body-2 text-right text-truncate" :title="local_format_date(inventory.booking_from, null, true) + ' - ' + local_format_date(inventory.actual_booking_to, null, true)" v-if="inventory.booking_from && inventory.actual_booking_to">{{ local_format_date(inventory.booking_from, null, true) }} - {{ local_format_date(inventory.actual_booking_to, null, true) }}</h4>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </a-card>
                    </div>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid grid-list-xl pa-0 v-if="inventory_list && !inventory_list.length && !local_loading">
            <a-responsive class="mt-3 pt-1" >
                <a-divider class="grey lighten-2 mb-4"></a-divider>
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Bookings Found</h3>
                <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
            </a-responsive>
        </a-container>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import TemplateImportStatus from '@/mixins/mixin-template-import-status'

export default {
    mixins: [TemplateImportStatus],
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            select_date_range: false,
            date_range_from: null,
            date_range_to: null,
            datepicket_inventory_booking_from: false,
            datepicket_inventory_booking_to: false,
            dialog_request_inventory_form: false,
            selected_from_date: null,
            selected_to_date: null,
            show_date_picker: false,
            inventory_group_by: 'category',
            accordion_inventorys: [],
            accordion_inventorys_all: false,
            current_view: {},
            inventory_status_list: [],
            inventory_list: [],
            local_loading: false,
            filter: {
                STATUS: 'filter[status]',
                PROJECT: 'filter[project_id]',
                CATEGORY: 'filter[inventory_id]',
            }
        }
    },

    mounted() {
         this.local_index()
    },

    computed: {
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('InventoryBooking', {
            inventory_filters: 'filters',
        }),
        ...mapState('Meta', {
            meta_list: 'list',
            meta_filters: 'filters',
        }),
        ...mapState('Tag', {
            tag_list: 'list',
            tag_filters: 'filters',
        }),
        ...mapState('ProjectView', {
            project_item: 'item',
        }),
        ...mapGetters('InventoryBooking', {
            inventory_booking_list: 'list',
        }),
    },

    methods: {
        async local_index() {
            if (!this.$can('inventories.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            this.$vuetify.goTo(0)
            this.local_loading = true
            this.current_view = 'In Use'

            this.inventory_booking_clear()
            this.meta_clear()

            // await this.meta_index({ 'filter[type]': 'inventory_tag', 'sort': 'order' })
            // this.inventory_status_list =  _.cloneDeep(this.meta_list)

            await this.local_inventory_index()
            this.inventory_list = this.inventory_booking_list
            await this.local_load_inventory_custom_statuses()
            this.local_loading = false

            if(!this.project_item) await this.project_show({'id': this.$route.params.id, 'fields[projects]': 'id,status'})
        },
        local_load_inventory_custom_statuses() {
            this.tag_index({ 'filter[type]': 'inventory_custom_status'})
        },
        async local_inventory_index(include) {
            this.inventory_filters[this.filter.PROJECT] = this.$route.params.id
            this.inventory_filters[this.filter.STATUS] = this.current_view
            await this.inventory_booking_index({ ...this.inventory_filters, 'include': include ?? 'inventoryTrashed,inventory.category,inventory.customStatus,assignee.role,requester.role' })
        },
        async local_change_booking_views(view) {
            this.local_loading = true
            this.current_view = view
            await this.local_inventory_index()
            this.inventory_list = this.inventory_booking_list
            this.local_loading = false
        },
        local_format_date(date, range = false, simple = false) {
            if (range) return moment(date).format('YYYY-MM-DD')
            if (simple) return moment(date).format('MMM D')

            return date ? moment(date).format('MMM D, YYYY') : null
        },
        local_accordion_inventory_status(index) {
            if (this.accordion_inventorys[index]) {
                return this.accordion_inventorys[index]
            }

            this.accordion_inventorys[index] = false
            return this.accordion_inventorys[index]
        },
        local_accordion_inventory_toggle(index) {
            if (this.accordion_inventorys[index] === undefined || this.accordion_inventorys[index] === null) {
                return this.$set(this.accordion_inventorys, index, true);
            }

            return this.$set(this.accordion_inventorys, index, !this.accordion_inventorys[index]);
        },
        local_accordion_inventory_toggle_all() {
            this.accordion_inventorys_all = !this.accordion_inventorys_all
            this.accordion_inventorys = this.accordion_inventorys.map(x => this.accordion_inventorys_all)
        },
        local_get_availability(inventory, status, next_available) {
            if (status === 'In Use' || status === 'Booked' || status === 'Returned') {
                return inventory.inventory.next_available ? this.local_format_date(inventory.inventory.next_available) : '-'
            }
            if (status === 'Available') return 'Now'
            if (status === 'Not Available') return 'Not Available'
        },
        local_truncate_text(text, range, separator = '...') {
            if (!text) return

            return text.length > range ? text.slice(0, range) + '...' : text
        },
        async local_add_inventory_stage_tag(tag, inventory_id, group_index) {
            this.inventory_attach_custom_status({ tag_id: tag.id, inventory_id, type: 'booking' })
            // await this.inventory_update_stage_tag({ tag_id: tag.id, inventory_id, type: 'booking' })
            this.inventory_booking_stage_update({ tag, inventory_id, index: group_index })
        },
        local_clear_inventory_stage_tag(inventory_id, group_index) {
            this.inventory_clear_custom_status({ inventory_id, type: 'booking' })
            // this.inventory_clear_stage_tag({ inventory_id, type: 'booking' })
            this.inventory_booking_stage_clear({ inventory_id, index: group_index })
        },
        permission_inventory(type) {
            return this.$can(`inventories.${type}`) && this.$can('projects.update-only')
        },
        ...mapActions('Inventory', {
            'inventory_attach_custom_status': 'attach_custom_status',
            'inventory_clear_custom_status': 'clear_custom_status',
            inventory_update_stage_tag: 'update_stage_tag',
            'inventory_clear_stage_tag': 'clear_stage_tag',
        }),
        ...mapActions('InventoryBooking', {
            inventory_booking_index: 'index',
            inventory_booking_clear: 'clear',
            inventory_booking_stage_update: 'update_stage_tag',
            inventory_booking_stage_clear: 'clear_stage_tag'
        }),
        ...mapActions('Tag', {
            tag_index: 'index',
            tag_custom_status_clear: 'clear_custom_status'
        }),
        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),
        ...mapActions('ProjectView', {
            project_show: 'show',
        }),
    },
}
</script>

<style scoped>
.inventory-detail-pane {
    transition: 0.3s all;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    height: 100vh;
    width: 480px;
    overflow-y: scroll;
    transform: translateX(480px);
}

.inventory-detail-pane.visible {
    transform: translateX(0);
}

.v-textarea textarea {
    font-weight: 400;
    font-size: 24px;
}

.text-wrap-pre {
    white-space: pre-wrap;
}

.radius-none {
    border-radius: 0 !important;
}

.radius-right-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.radius-left-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
</style>

<template>
    <div>
        <h4 class="md-subtitle-2 grey--text text--darken-3 font-weight-medium mb-3">{{ activeCount + exceededCount }} of {{ totalCount }} Active Projects</h4>
        <a-sheet class="u-flex-center-y u-rounded-corners" :color="localProgressObj.trackColor" height="8" width="100%">
            <a-tooltip bottom offset-y :content-class="`white--text font-weight-bold ${ localProgressObj.thumbColor }`">
                <template v-slot:activator="{ on }">
                    <a-sheet
                        v-on="on"
                        class="u-hfull"
                        :color="localProgressObj.thumbColor"
                        :width="localProgressObj.width + '%'"
                        :style="{ borderRadius: localProgressObj.type !== 'mixed' ? '4px' : '4px 0px 0px 4px' }"
                    ></a-sheet>
                </template>
                <span><strong>{{ activeCount }}</strong> Projects</span>
            </a-tooltip>
            <a-tooltip v-if="localProgressObj.type === 'mixed'" bottom offset-y :content-class="`white--text font-weight-bold ${ localProgressObj.thumbColor1 }`">
                <template v-slot:activator="{ on }">
                    <a-sheet
                        v-on="on"
                        class="u-hfull"
                        :color="localProgressObj.thumbColor1"
                        :width="localProgressObj.width1 + '%'"
                        style="border-radius: 0px 4px 4px 0px;"
                    ></a-sheet>
                </template>
                <span><strong>{{ exceededCount }}</strong> Projects Exceeded</span>
            </a-tooltip>
        </a-sheet>
    </div>
</template>

<script>
export default {
    props: {
        totalCount: {
            type: Number
        },
        activeCount: {
            type: Number
        },
        exceededCount: {
            type: Number,
            default: 0
        },
    },

    computed: {
        localProgressObj () {
            if (this.totalCount === -1) return

            const successProps = { thumbColor: 'teal accent-4', trackColor: 'teal lighten-5' }
            const warningProps = { thumbColor: 'amber darken-1', trackColor: 'amber lighten-5' }
            const errorProps = { thumbColor: 'red darken-2', trackColor: 'red lighten-5' }
            const mixedStatusProps = { thumbColor: 'teal accent-4', thumbColor1: 'red darken-2', trackColor: 'grey lighten-3' }
            const percentile = (this.activeCount / this.totalCount) * 100

            if ((this.activeCount >= this.totalCount) && this.exceededCount === 0) return Object.assign({ type: 'error' }, { width: 100, ...errorProps })
            if (percentile >= 70 && percentile < 100) return Object.assign({ type: 'warning' }, { width: percentile, ...warningProps })
            if (this.activeCount < this.totalCount) return Object.assign({ type: 'success' }, { width: percentile, ...successProps })
            if (this.exceededCount !== 0) {
                const exceededPercent = this.exceededCount / this.totalCount * 100
                return Object.assign({ type: 'mixed' }, { width: 100 - exceededPercent, width1: exceededPercent, ...mixedStatusProps })
            }
        },
    },
}
</script>

<style>

</style>

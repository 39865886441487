<template>
    <div class="c-stripes-loader" :style="{ backgroundPosition: percent+'%', backgroundColor: color, height: height+'px' }"></div>
</template>

<script>
export default {
    props: {
        percent: {
            type: Number,
            default: 0
        },
        color: {
            type: String,
            default: ''
        },
        height: {
            type: Number,
            default: ''
        },
    },
}
</script>
<style lang="scss" scoped>
.c-stripes-loader {
    width: 100%;
    position: relative;
    background: linear-gradient(90deg, #eee 50%, transparent 50%);
    background-size: 200%;
    background-position: 0%;
    border-radius: 7px;
    transform: scale(-1);
    transition: 2s;
}
.c-stripes-loader:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    background: linear-gradient(
        -45deg,
        transparent 35%,
        rgba(238, 238, 238, 0.5) 35%,
        rgba(238, 238, 238, 0.5) 70%,
        transparent 70%
    );
    animation: move 3s linear infinite;
    background-size: 45px 100%;
}
@keyframes move {
  to {
    background-position: 45px 100%;
  }
}
</style>
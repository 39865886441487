<template>
    <div class="c-mini-action u-flex align-center">
        <slot name="prepend"></slot>
        <!-- <template v-if="check_user_tag_permission('update')">
            <a-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <div v-on="on" @click="local_tag_section_toggle_active(item.id, !item.is_active)" v-ripple style="border-radius: 0px 0px 0px 6px;" class="c-mini-action-item u-cursor-pointer u-rounded-corners-full">
                        <a-icon size="14" :color="item.is_active ? 'green darken-1' : 'grey lighten-1'">fiber_manual_record</a-icon>
                    </div>
                </template>
                <span>{{ item.is_active ? 'Enabled' : 'Disabled' }}</span>
            </a-tooltip>
            <a-divider vertical></a-divider>
        </template> -->
        <!-- <div @click="local_tag_section_edit(item)" v-ripple class="c-mini-action-item u-cursor-pointer u-rounded-corners-full"> -->
        <div @click="$emit('edit')" v-ripple class="c-mini-action-item u-cursor-pointer">
            <slot name="edit-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-pencil grey--text text--darken-1" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                </svg>
            </slot>
        </div>
        <a-divider vertical v-if="canDelete && canUpdate"></a-divider>
        <template v-if="canDelete">
            <div v-if="!dataShowDelete" @click="methodShowDelete" v-ripple class="c-mini-action-item u-cursor-pointer">
                <slot name="delete-confirm-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-trash grey--text text--darken-1" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                </slot>
            </div>
            <a-tooltip bottom v-else>
                <template v-slot:activator="{ on }">
                    <div v-on="on" @click="$emit('delete')" v-ripple class="c-mini-action-item u-cursor-pointer">
                        <slot name="delete-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-trash red--text text--darken-2" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                        </slot>
                    </div>
                </template>
                <span>Confirm Delete?</span>
            </a-tooltip>
        </template>
        <slot name="append"></slot>
    </div>
</template>

<script>
export default {
    props: {
        canUpdate: {
            type: Boolean,
            default: true
        },
        canDelete: {
            type: Boolean,
            default: true
        },
        confirmTimeout: {
            type: Number,
            default: 3000
        },
    },

    data () {
        return {
            dataShowDelete: null
        }
    },

    methods: {
        methodShowDelete () {
            this.dataShowDelete = true
            setTimeout(() => this.dataShowDelete = false, this.confirmTimeout)
        }
    }
}
</script>

<style lang="scss" scoped>
    .c-mini-action {
        // visibility: hidden;
        // position: absolute;
        // right: -1000px;
        // top: 0px;
        background: #fff;
        border-radius: 0px 0px 0px 6px;
        box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 16%);
    }
    .c-mini-action-item {
        display: flex;
        align-items: center;
        padding: 5px 10px;
    }
</style>

<template>
    <div v-bind="$attrs" v-on="$listeners">
        <div class="u-flex-center-y u-wfull" style="column-gap: 40px;">
            <div
                class="u-flex u-wfull text-truncate"
                :style="[
                    { columnGap: '16px' },
                    moduleType === 'global' ? { minWidth: '500px', maxWidth: '500px' } : {}
                ]"
            >
                <img v-if="meeting.service === 'gong'" src="../../assets/images/apps/gong/logo.svg" alt="Gong Integration" style="width: 32px; height: 32px;">

                <!-- Title and Members -->
                <!-- <div class="u-flex flex-column text-truncate u-wfull" style="row-gap: 12px; max-width: 500px;"> -->
                <div class="u-flex flex-column text-truncate u-wfull" style="row-gap: 12px;">
                    <a
                        :href="`${gongBaseUrl}/call?id=${meeting.meeting_id}`"
                        target="_blank"
                        style="max-width: max-content"
                        class="c-meeting-title md-subtitle-1 grey--text text--darken-4 text-truncate d-inline-block font-weight-medium"
                    >
                        {{ meeting.title }}
                    </a>
                    <div class="u-flex-center-y" style="column-gap: 8px;">
                        <a-menu
                            :close-on-content-click="false"
                            max-width="350"
                            bottom open-on-hover offset-y
                        >
                            <template v-slot:activator="{ on }">
                                <div v-on="on" class="u-flex-center-y u-cursor-pointer" style="column-gap: 6px;">
                                    <a-icon size="18" color="grey">people</a-icon>
                                    <h4 class="md-caption font-weight-medium grey--text text--darken-3 ma-0">{{ meeting.parties_count }} Members</h4>
                                </div>
                            </template>
                            <a-list style="max-height: 300px;" class="u-list-std u-overflow-y">
                                <a-list-item
                                    v-for="member in meeting.meeting_participants"
                                    :key="member.id"
                                    class="py-2"
                                >
                                    <div class="text-truncate">
                                        <h3 class="md-body-2 text-truncate font-weight-medium grey--text text--darken-3">{{ member.name }}</h3>
                                        <p v-if="member.email" class="mb-0 md-body-2 text-truncate grey--text text--darken-2">{{ member.email }}</p>
                                        <p v-else class="mb-0 md-caption grey--text text--darken-1">No Email</p>
                                    </div>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                        <a-icon size="6" color="grey lighten-1">fiber_manual_record</a-icon>
                        <div @click="$emit('openKeywords')" class="u-flex-center-y u-cursor-pointer" style="column-gap: 6px;">
                            <a-icon size="18" color="grey">format_shapes</a-icon>
                            <h4 class="md-caption font-weight-medium grey--text text--darken-3 ma-0">Keywords</h4>
                        </div>
                    </div>
                </div>
            </div>

            <a-spacer v-if="moduleType !== 'global'"></a-spacer>

            <!-- (Time, duration) and Date -->
            <div
                class="u-flex flex-column"
                :style="[{
                    rowGap: '12px',
                    minWidth: '230px',
                    maxWidth: '230px'
                }]"
            >
                <div class="u-flex-center-y" style="column-gap: 6px;">
                    <a-icon size="18" color="grey">schedule</a-icon>
                    <h4 class="md-caption font-weight-medium grey--text text--darken-3 ma-0">
                        {{ localGetTimeFromDate(meeting.started_at) }} - {{ localGetTimeFromDate(meeting.ended_at) }} | {{ localFormatTime(meeting.duration) }}
                    </h4>
                </div>
                <div class="u-flex-center-y" style="column-gap: 6px;">
                    <a-icon size="18" color="grey">date_range</a-icon>
                    <h4 class="md-caption font-weight-medium grey--text text--darken-3 ma-0">{{ localFormatDate(meeting.started_at) }}</h4>
                </div>
            </div>
        </div>

        <!-- Project linked -->
        <template v-if="moduleType === 'global'">
            <a-menu
                v-if="localGetMeetingProjects({ slug: 'length' }) || localHiddenProjectsCount"
                min-width="250"
                max-width="250"
                left bottom offset-y open-on-hover
            >
                <template v-slot:activator="{ on }">
                    <div v-on="on" class="u-cursor-pointer blue--text text--darken-2" style="min-width: max-content;">
                        <span class="md-body-2 font-weight-medium">{{ localGetMeetingProjects({ slug: 'length', status: 'all' }) || localHiddenProjectsCount }} Project(s)</span>
                    </div>
                </template>
                <a-list class="u-list-std">
                    <a-tooltip
                        v-if="localHiddenProjectsCount"
                        :disabled="!localGetMeetingProjects({ slug: 'length' })"
                        content-class="c-tooltip-pointer c-tooltip-pointer--top"
                        left
                    >
                        <template v-slot:activator="{ on }">
                            <a-list-item
                                v-on="on"
                                class="md-caption text-uppercase grey--text text--darken-2 u-relative py-0 font-weight-medium px-3 u-rounded-corners"
                            >
                                <div class="py-2">
                                    <div class="u-flex" :style="[{ columnGap: '4px' }]">
                                        <strong>{{ localHiddenProjectsCount }}</strong>
                                        <span>Hidden Project(s)</span>
                                    </div>
                                    <div v-if="!localGetMeetingProjects({ slug: 'length' })" class="md-caption mt-1" style="max-width: 250px;">
                                        You may not have proper permission or the project(s) belongs to different workspace.
                                    </div>
                                </div>
                            </a-list-item>
                        </template>
                        <div class="md-caption" style="max-width: 250px;">You may not have proper permission or the project(s) belongs to different workspace.</div>
                    </a-tooltip>
                    <template v-if="localGetMeetingProjects({ slug: 'length' })">
                        <a-list-item
                            v-for="(project) in localGetMeetingProjects()"
                            :key="project.id"
                            @click="localGoToProject(project)"
                            class="c-linked-project u-relative pa-2 px-3 u-rounded-corners"
                        >
                            <div class="text-truncate pr-6">
                                <h4 :title="project.title" class="c-linked-project__title text-truncate md-body-2 u-wfull font-weight-medium grey--text text--darken-3 text-truncate">{{ project.title }}</h4>
                                <div class="u-flex-center-y text-truncate" style="column-gap: 4px;" v-if="project.stage && project.customer">
                                    <p class="md-caption mb-0 text-truncate grey--text text--darken-3">{{ project.stage.name }}</p>
                                    <a-icon size="6" color="grey">fiber_manual_record</a-icon>
                                    <p class="md-caption mb-0 text-truncate grey--text text--darken-3">{{ project.customer.name }}</p>
                                </div>
                                <a-icon size="16" color="grey darken-1" class="c-linked-project__launch-icon u-absolute" style="right: 10px; top: 12px">launch</a-icon>
                            </div>
                        </a-list-item>
                    </template>
                </a-list>
            </a-menu>
            <div
                v-if="!localGetMeetingProjects({ slug: 'length' }) && !localHiddenProjectsCount"
                class="grey--text"
                style="min-width: max-content;"
            >
                <span class="md-body-2 font-weight-medium">No Project(s)</span>
            </div>
        </template>
    </div>
</template>

<script>
import { diffSimple, formattedTime } from '@/helpers/helper-date-filter';

export default {
    props: {
        meeting: {
            type: Object,
            required: true,
            default: () => {}
        },
        moduleType: {
            /* "global" for global pages and "project" for project pages */
            type: String,
            default: 'global'
        },
        gongBaseUrl: {
            type: String,
        },
    },

    computed: {
        localHiddenProjectsCount () {
            const list = this.meeting.meeting_bridge_projects.filter(bridge => bridge.project === null)
            return _.size(list)
        },
    },

    methods: {
        localGetTimeFromDate (date) {
            return moment(date).format('hh:mm A')
        },

        localFormatDate (date) {
            return date ? diffSimple(date, { format: 'MMM D, YYYY' }) : null
        },

        localFormatTime (seconds) {
            const hours = Math.floor(seconds / 3600)
            const minutes = Math.floor((seconds % 3600) / 60)
            const secs = Math.floor((seconds % 3600) % 60)

            return `${hours}h ${minutes}m ${secs}s`
        },

        localGetMeetingProjects ({ slug = null, status = 'projects' } = {}) {
            let list = []
            if (status === 'projects') list = this.meeting.meeting_bridge_projects.map(bridge => bridge.project).filter(Boolean)
            if (status === 'all') list = this.meeting.meeting_bridge_projects
            if (_.size(list) === 0) return slug === 'length' ? 0 : []

            return slug === 'length' ? _.size(list) : list
        },

        localGoToProject (project) {
            const pageSlug = this.$can('project_meetings.index') ? 'meetings' : 'overview'
            window.open(`${window.location.origin}/projects/${project.id}/${pageSlug}`, '_blank')
        },
    }
}
</script>

<style lang="scss" scoped>
.c-meeting-title {
    &:hover {
        text-decoration: underline;
        text-underline-offset: 4px;
    }
}

.c-linked-project {
    &__launch-icon { display: none; }
    &:hover {
        .c-linked-project__launch-icon { display: flex; }
    }
}
</style>

<template>
    <div></div>
</template>

<script>
export default {
    props: {
        options: {
            type: Object,
            default: () => ({
                root: null,
                threshold: 0
            })
        }
    },

    data () {
        return {
            observer: null
        }
    },

    mounted () {
        this.localIndex()
    },

    beforeDestroy () {
        this.observer.disconnect()
    },

    methods: {
        localIndex () {
            if (!window.IntersectionObserver) return

            this.observer = new IntersectionObserver(this.localCheckIntersectionEl, this.options)
            this.observer.observe(this.$el)
        },

        localCheckIntersectionEl (entries) {
            if (entries[0].isIntersecting) this.$emit('visible')
        }
    }
}
</script>

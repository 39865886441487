<template>
    <a-sheet
        class="u-rounded-corners-lg u-shadow u-relative"
        min-height="260"
        style="background: linear-gradient(100.21deg, #1A237E 0%, #3F51B5 100%);"
    >
        <div class="u-flex flex-column justify-space-between" style="min-height: 100%; background: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 32.6%, rgba(159, 168, 218, 0) 100%) !important;">
            <div>
                <div class="u-flex-center-y px-6 py-5" style="background: linear-gradient(100.21deg, #1A237E 0%, #3F51B5 100%); border-radius: 8px 8px 0px 0px;">
                    <h3 style="font-size: 20px" class="white--text font-weight-bold">Starter+</h3>
                    <span class="ml-2 indigo lighten-5 indigo--text text--darken-3 md-caption font-weight-medium d-inline-block px-2 text-uppercase u-rounded-corners" style="height: 20px; letter-spacing: -0.5px; line-height: 20px">Most Popular</span>
                </div>
                <div class="u-wfull" style="height: 2px; background: linear-gradient(20deg, rgba(95, 113, 219, 0.5) 20%, rgba(232, 234, 246, 0) 80%);"></div>
            </div>
            <div class="px-6 py-6 u-hfull" style="">
                <p class="white--text font-weight-medium md-body-2 mb-0" style="line-height: 24px;">Add-on Projects &bull; Two-way Salesforce Integration <br> Two-way HubSpot Integration &bull; Single Sign-On <br> Optional Inventory module</p>
            </div>
            <div class="" style="flex: 0 0 65px; background: linear-gradient(100.21deg, #1A237E 0%, #3F51B5 100%); border-radius: 0px 0px 8px 8px;">
                <div class="u-wfull" style="height: 2px; background: linear-gradient(20deg, rgba(95, 113, 219, 0.5) 20%, rgba(232, 234, 246, 0) 80%);"></div>
                <div class="c-plan-action u-cursor-pointer u-flex-center-y py-5 px-6">
                    <h4 class="md-subtitle-1 font-weight-bold mr-1 white--text">From $600 / month</h4>
                    <a-icon size="16" color="white">arrow_forward</a-icon>
                </div>
            </div>

            <!-- Feature Icons -->
            <div class="u-flex flex-column u-absolute" style="row-gap: 12px; top: 50%; transform: translateY(-50%); right: 8px;">
                <a-sheet class="white u-rounded-corners-full u-flex-center" width="40" height="40"><img style="object-fit: contain;" class="d-block" src="../../../assets/images/billing/new_folder.svg" alt="Add-on Projects"></a-sheet>
                <a-sheet class="white u-rounded-corners-full u-flex-center" width="40" height="40"><img style="object-fit: contain;" class="d-block" src="../../../assets/images/billing/google-logo.svg" alt="Single Sign On"></a-sheet>
                <a-sheet class="white u-rounded-corners-full u-flex-center" width="40" height="40"><img style="object-fit: contain;" class="d-block" src="../../../assets/images/billing/memory_black.svg" alt="Inventory Module"></a-sheet>
            </div>
            <div class="u-flex flex-column u-absolute" style="row-gap: 12px; top: 50%; transform: translateY(-50%); right: 54px;">
                <a-sheet class="white u-rounded-corners-full u-flex-center" width="40" height="40"><img style="object-fit: contain;" class="d-block" src="../../../assets/images/billing/salesforce-logo.svg" alt="Two-way Salesforce Integration"></a-sheet>
                <a-sheet class="white u-rounded-corners-full u-flex-center" width="40" height="40"><img style="object-fit: contain;" class="d-block" src="../../../assets/images/billing/hubspot-icon.svg" alt="Two-way Hubspot Integration"></a-sheet>
            </div>
        </div>
    </a-sheet>
</template>

<script>
export default {

}
</script>

<style>

</style>

<template>
	<div>
        <a-container container--fluid grid-list-xl class="my-2 mb-10">
            <a-layout align-center>
                <a-flex>
                    <h2 class="md-heading-5 font-weight-semibold">Logs</h2>
                    <p class="md-body-2 mb-0 grey--text text--darken-2">Success and error logs from workflow runs.</p>
                </a-flex>
            </a-layout>
            <a-layout align-center>
                <a-flex>
                    <a-card flat class="transparent u-rounded-corners u-overflow-hidden mb-3">
                        <a-card-text class="pa-0">
                            <table class="c-table u-wfull">
                                <tr class="c-table__row white u-elevation-custom-1 u-rounded-corners" v-for="(item, index) in workflow_log_list" :key="item.id">
                                    <td style="width: 120px" class="c-table__cell py-4 px-4 pl-5">
                                        <div class="d-flex align-center">
                                            <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" style="display: inline-block; max-width: 20px; max-height: 20px;">
                                            <a-icon class="mx-2 ml-4">arrow_right_alt</a-icon>
                                            <img src="../../assets/images/logo-outgoing-webhooks.svg" alt="Salesforce" style="display: inline-block; max-width: 20px; max-height: 20px;">
                                        </div>
                                    </td>
                                    <td style="width: 250px" class="c-table__cell py-4 text-truncate">
                                        <p style="max-width: 200px" class="md-body-2 px-4 text-truncate mb-0 grey--text text--darken-1" v-if="item.workflow" :title="item.workflow.title">
                                            {{ item.workflow.title }}
                                        </p>
                                    </td>
                                    <td style="width: 100px" class="c-table__cell py-4">
                                        <div>
                                            <p style="min-width: 100px" class="md-body-2 mb-0 text-left light-green--text text--darken-2" v-if="item.status === 'success'">
                                                <a-icon color="light-green darken-2" size="16" class="mr-1 u-icon-nudge-xs">check_circle</a-icon>
                                                Success
                                            </p>
                                            <p style="min-width: 100px" class="md-body-2 mb-0 text-left red--text text--darken-2" v-if="item.status === 'error'">
                                                <a-icon color="red darken-2" size="16" class="mr-1 u-icon-nudge-xs">error</a-icon>
                                                Error
                                            </p>
                                            <p style="min-width: 100px" class="md-body-2 mb-0 text-left grey--text text--darken-2" v-if="item.status === 'invalid'">
                                                <a-icon color="grey darken-2" size="16" class="mr-1 u-icon-nudge-xs">check_circle</a-icon>
                                                Invalid
                                            </p>
                                        </div>
                                    </td>
                                    <td class="c-table__cell py-4">
                                        <div>
                                            <div class="u-flex align-center u-cursor-pointer" @click="local_show_detail(item, index)">
                                                <a-icon size="14" color="grey darken-1" class="mr-2">launch</a-icon>
                                                <h2 class="md-body-2 grey--text text--darken-1" v-if="item.status === 'success'">Successfully connected.</h2>
                                                <h2 class="md-body-2 grey--text text--darken-1" v-if="item.status === 'error'">HTTP request returned status code. {{ item.code }}</h2>
                                                <h2 class="md-body-2 grey--text text--darken-1" v-if="item.status === 'invalid' && item.conditions.result === false">Conditions not satisfied</h2>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="width:200px" class="c-table__cell py-4 text-truncate">
                                        <p style="width:200px" class="md-body-2 pr-6 mb-0 text-truncate grey--text text-right">
                                            <g-moment :value="item.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog v-model="show_detail_view" max-width="800">
            <a-card class="u-elevation-custom-1 u-rounded-corners pa-0">
                <a-card-title class="px-8 pt-5 pb-4 grey lighten-4" style="line-height: normal !important">
                    <h2 class="md-subtitle-1 grey--text text--darken-2 mb-2 text-truncate" style="max-width: 700px;">{{ detail_item.title }}</h2>
                    <div class="u-flex align-center u-wfull mt-n1">
                        <div class="d-flex align-center">
                            <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" style="display: inline-block; max-width: 16px; max-height: 16px;">
                            <a-icon class="mx-2">arrow_right_alt</a-icon>
                            <img src="../../assets/images/logo-outgoing-webhooks.svg" alt="Salesforce" style="display: inline-block; max-width: 16px; max-height: 16px;">
                        </div>
                        <a-icon class="mx-3 ml-8" size="4" color="grey darken-1">fiber_manual_record</a-icon>
                        <div>
                            <div class="md-caption mb-0 light-green--text text--darken-2" v-if="detail_item.status === 'success'">
                                <a-icon color="light-green darken-2" size="16">check_circle</a-icon>
                                Success
                            </div>
                            <div class="md-caption mb-0 red--text text--darken-2" v-if="detail_item.status === 'error'">
                                <a-icon color="red darken-2" size="16">error</a-icon>
                                Error
                            </div>
                            <div class="md-caption mb-0 grey--text text--darken-2" v-if="detail_item.status === 'invalid'">
                                <a-icon color="grey darken-2" size="16">check_circle</a-icon>
                                Invalid
                            </div>
                        </div>
                        <a-icon class="mx-3" size="4" color="grey darken-1">fiber_manual_record</a-icon>
                        <div class="md-caption mb-0 text-truncate grey--text">
                            <g-moment :value="detail_item.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                        </div>
                    </div>
                </a-card-title>
                <a-divider class="grey lighten-3"></a-divider>
                <a-card-text class="px-8 pt-5 pb-8" style="max-height: 500px; overflow-y: auto">
                    <div class="mb-2">
                        <h2 class="md-caption grey--text text--darken-2 text-uppercase font-weight-medium mb-2" style="letter-spacing: 0.8px">Request</h2>
                        <table class="c-table-desc u-wfull" style="border-collapse: collapse; border: 1px solid #000">
                            <tr class="">
                                <td class="c-table-desc__cell" style="width: 160px;">
                                    <span class="md-body-2 font-weight-medium grey--text text--darken-3 mr-2">Request URL</span>
                                </td>
                                <td class="c-table-desc__cell">
                                    <div class="md-caption blue--text text--darken-1" style="font-family: monospace !important">
                                        {{ detail_item.request_url }}
                                    </div>
                                </td>
                            </tr>
                            <tr class="">
                                <td class="c-table-desc__cell" style="width: 160px;">
                                    <span class="md-body-2 font-weight-medium grey--text text--darken-3 mr-2">Request Method</span>
                                </td>
                                <td class="c-table-desc__cell">
                                    <div class="md-caption grey--text text--darken-1 text-uppercase" style="font-family: monospace !important">{{ detail_item.method }}</div>
                                </td>
                            </tr>
                            <tr class="">
                                <td class="c-table-desc__cell" style="width: 160px;">
                                    <span class="md-body-2 font-weight-medium grey--text text--darken-3 mr-2">Status Code</span>
                                </td>
                                <td class="c-table-desc__cell">
                                    <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important">{{ detail_item.code }}</div>
                                </td>
                            </tr>
                            <tr class="">
                                <td class="c-table-desc__cell" style="width: 160px;">
                                    <span class="md-body-2 font-weight-medium grey--text text--darken-3 mr-2">Message</span>
                                </td>
                                <td class="c-table-desc__cell">
                                    <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important" v-if="detail_item.status === 'success'">Successfully connected.</div>
                                    <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important" v-if="detail_item.status === 'error'">HTTP request returned status code. {{ detail_item.code }}</div>
                                    <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important" v-if="detail_item.status === 'invalid' && detail_item.conditions.result === false">Conditions not satisfied</div>
                                </td>
                            </tr>
                            <tr class="">
                                <td class="c-table-desc__cell" style="width: 160px;">
                                    <span class="md-body-2 font-weight-medium grey--text text--darken-3 mr-2">Payload</span>
                                </td>
                                <td class="c-table-desc__cell">
                                      <pre>{{ detail_item.request_payload }}</pre>
                                </td>
                            </tr>
                        </table>
                    </div>
                </a-card-text>
            </a-card>
        </a-dialog>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            show_detail_view: false,
            detail_item: {},
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Apps', to: '/appstore', exact: true})
            breadcrumbs.push({text: 'Configure Apps', to: '/appstore/installed', exact: true})
            return breadcrumbs
        },

        ...mapState('WorkflowLog', {
            workflow_log_list: 'list',
            workflow_log_filters: 'filters',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index () {
            await this.workflow_log_index(
                {
                    'filter[workflow_type]': 'webhook',
                    'fields[workflow_logs]' : 'id,status,code,conditions,method,request_payload,request_url,workflow_id,created_at',
                    'fields[workflow]' : 'id,title',
                    'include': 'workflow',
                    'sort': '-created_at'
                }
            )
        },

        local_show_detail (item, index) {
            this.detail_item = {...item }
            this.show_detail_view = true
        },

        ...mapActions('WorkflowLog', {
            workflow_log_index: 'index',
            workflow_log_clear: 'clear',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .c-table {
        display: table !important;
        border-collapse: separate;
        border-spacing: 0 15px;
        &__cell {
            display: table-cell !important;
            white-space: nowrap !important;
            background: #fff !important;
            &:first-child {
                border-radius: 4px 0px 0px 4px;
            }
            &:last-child {
                border-radius: 0px 4px 4px 0px;
            }
        }
    }
    .c-table-desc {
        display: table;
        border-collapse: collapse !important;
        &__cell {
            display: table-cell !important;
            padding: 8px;
            vertical-align: top !important;
            white-space: normal !important;
            border: 1px solid #e4e4e4;
            &:first-child {
                border-radius: 4px 0px 0px 4px;
            }
            &:last-child {
                border-radius: 0px 4px 4px 0px;
            }
        }
    }
</style>

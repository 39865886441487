export default {
    methods: {
        async local_usecase_index (onlyUcIndex = false, params, clearLoading) {
            this.show_pagination = !this.filter_selected
            const localParams = {
                params: {
                    ...this.local_usecase_fields,
                    count: !this.filter_selected ? this.usecases_count : 999,
                    'filter[project_id]': this.$route.params.id,
                }
            }

            await this.usecase_index(params ?? localParams)
            this.local_page_count = this.usecase_meta.current_page

            if (clearLoading) clearLoading()
            if (!onlyUcIndex) {
                await this.local_fetch_tc_sc_list()
                this.local_expand_all_upto(2)
            }
            this.local_set_originals()
        },

        async local_usecase_index_bulk_mode (onlyUcIndex = false, ids) {
            const params = {
                mode: 'update-list-selected-item',
                params: {
                    ...this.local_usecase_fields,
                    count: !this.filter_selected ? this.usecases_count : 999,
                    'filter[project_id]': this.$route.params.id,
                    'filter[id]': ids.join(','),
                }
            }

            await this.local_usecase_index(onlyUcIndex, params)
        },

        local_set_originals () {
            this.original_uc_list = _.cloneDeep(this.usecase_list)
            this.original_sc_list = _.cloneDeep(this.success_criterion_list)
            this.original_tc_list = _.cloneDeep(this.testcase_list)
        },

        async local_reorder(list) {
            this.usecase_reorder({list: list, project_id: this.$route.params.id})
        },

        async local_usecase_delete_all() {
            await this.usecase_delete_all({project_id: this.project_item.id})
            if (this.usecase_response.status !== 'success') {
                return this.$notify('error', 'Use Case delete all operation failed.')
            }
            this.dialog_usecase_delete_all = false
            this.$notify('success', 'Deleted all the use cases')
        },

        async local_visibility_toggle (id, state) {
            const usecase_item = this.usecase_list.find(item => item.id === id)
            usecase_item.visibility = state === 'internal' ? 'external' : 'internal'
            await this.usecase_visibility(Object.assign({}, { id, state: usecase_item.visibility }))
            if (this.usecase_response.status !== 'success') this.$notify('error', 'Error updating usecase visibility.')
            if (this.dialog_uc_detail_view) this.local_update_uc_detail_item(id)
        },

        local_bulk_select_all_toggle () {
            if (this.bulk_delete_loading || !this.local_usecase_list_length) return
            this.bulk_selected_all = !this.bulk_selected_all
            if (this.bulk_selected_all) {
                this.bulk_delete_ids = this.usecase_list.map(({id}) => id)
                return true
            }
            this.bulk_delete_ids = []
        },

        local_bulk_select_uc (usecase_id) {
            const hasIdIndex = this.uc_bulk_ids.findIndex(id => id === usecase_id)
            if (hasIdIndex !== -1) this.uc_bulk_ids.splice(hasIdIndex, 1)
            else this.uc_bulk_ids.push(usecase_id)

            if (!this.uc_bulk_ids.length && this.filter_checkbox_all) this.filter_checkbox_all = false
            if (this.uc_bulk_ids.length === this.local_filtered_ucs.length) this.filter_checkbox_all = true
        },

        local_bulk_select_sc (tc_id) {
            const hasIdIndex = this.sc_bulk_ids.findIndex(id => id === tc_id)
            if (hasIdIndex !== -1) this.sc_bulk_ids.splice(hasIdIndex, 1)
            else this.sc_bulk_ids.push(tc_id)

            if (!this.sc_bulk_ids.length && this.filter_checkbox_all) this.filter_checkbox_all = false
            if (this.sc_bulk_ids.length === this.local_filtered_scs.length) this.filter_checkbox_all = true
        },

        local_bulk_select_tc (sc_id) {
            const hasIdIndex = this.tc_bulk_ids.findIndex(id => id === sc_id)
            if (hasIdIndex !== -1) this.tc_bulk_ids.splice(hasIdIndex, 1)
            else this.tc_bulk_ids.push(sc_id)

            if (!this.tc_bulk_ids.length && this.filter_checkbox_all) this.filter_checkbox_all = false
            if (this.tc_bulk_ids.length === this.local_filtered_tcs.length) this.filter_checkbox_all = true
        },

        local_is_bulk_selected (id, type) {
            if (type === 'uc') return this.uc_bulk_ids.includes(id)
            if (type === 'sc') return this.sc_bulk_ids.includes(id)
            if (type === 'tc') return this.tc_bulk_ids.includes(id)
        },

        local_filter_select_all (value, slug = null) {
            if (!this.filter_selected) return

            if (this.filter_selected && this.filter_selected.value === 'all-uc') {
                if (!value) this.uc_bulk_ids = []
                else {
                    this.uc_bulk_ids = []
                    if (this.filter_mode_selected && this.filter_mode_selected.mode_slug === 'visibility') {
                        const filterdIds = this.usecase_list.filter(uc => uc.visibility === this.filter_mode_selected.label.toLowerCase()).map(({ id }) => id)
                        this.uc_bulk_ids = [...filterdIds]
                    } else this.uc_bulk_ids = [...this.usecase_list.map(({ id }) => id)]
                }
            }

            if (this.filter_selected && this.filter_selected.value === 'all-sc') {
                if (!value) this.sc_bulk_ids = []
                else {
                    if (this.selected_status_id) {
                        this.sc_bulk_ids = []
                        const filterdIds = this.local_filtered_scs.filter(sc => sc.status_id === this.selected_status_id).map(({ id }) => id)
                        this.sc_bulk_ids = [...filterdIds]
                    } else {
                        this.sc_bulk_ids = []
                        this.sc_bulk_ids = [...this.local_filtered_scs.map(({ id }) => id)]
                    }
                }
            }

            if (this.filter_selected && this.filter_selected.value === 'all-tc') {
                if (!value) this.tc_bulk_ids = []
                else {
                    if (this.selected_status_id) {
                        this.tc_bulk_ids = []
                        const filterdIds = this.local_filtered_tcs.filter(sc => sc.status_id === this.selected_status_id).map(({ id }) => id)
                        this.tc_bulk_ids = [...filterdIds]
                    } else {
                        this.tc_bulk_ids = []
                        this.tc_bulk_ids = [...this.local_filtered_tcs.map(({ id }) => id)]
                    }
                }
            }
        },

        async local_clear_bulk_selection (value, clearLoading) {
            this.filter_mode_selected = { mode_slug: '', label: '', status: '', status_id: '' }
            this.local_clear_bulk_ids()
            this.filter_selected = null
            this.local_usecase_fields.page = 1
            await this.local_usecase_index(false, null, clearLoading)
            this.selected_status_id = null
        },

        local_clear_bulk_ids () {
            this.uc_bulk_ids = []
            this.sc_bulk_ids = []
            this.tc_bulk_ids = []
        },

        local_filter_by_status (status_id, type) {
            this.selected_status_id = status_id
        },

        local_expand_all_upto (limit = 5, expandedAll, slug, clearLoading) {
            if (expandedAll === false && slug === 'custom') {
                if (clearLoading) clearLoading()
                return this.expanded_ids = []
            }
            const ids = this.usecase_list.map(({ id }) => id)
            this.expanded_ids = limit !== -1 ? ids.slice(0, limit) : ids
            if (clearLoading) clearLoading()
        },

        local_toggle_expand ({ id, is_expanded }) {
            if (is_expanded) {
                const index = this.expanded_ids.findIndex(item => item === id)
                if (index !== -1) this.expanded_ids.splice(index, 1)
                return true
            }
            this.expanded_ids.push(id)
        },

        local_check_expanded (usecase_id) {
            const index = this.expanded_ids.findIndex(id => id === usecase_id)
            return index !== -1
        },
    }
}

<template>
    <a-main>
        <g-settings-breadcrumb class="mt-5" previousRoute="inventory"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid px-0>
            <g-page-header title="Inventory Trash" subtitle="List of deleted inventory." :breadcrumb-items="breadcrumb_items" icon="memory">
                <template slot="action" v-if="$can('inventories.destroy')">
                    <a-menu left offset-y transition="slide-y-transition" :disabled="!(local_render.length)" max-width="340">
                        <template v-slot:activator="{ on }">
                            <a-btn v-on="on" :disabled="!(local_render.length) || local_loading" small :dark="!!(local_render.length)" class="px-2 py-2 red darken-2 u-rounded-corners u-elevation-custom-1">
                                <a-icon size="16" class="mr-2">delete</a-icon>
                                Clear All
                            </a-btn>
                        </template>
                        <a-card class="u-rounded-corners u-elevation-custom-1 pa-3" min-width="320" max-width="340">
                            <a-card-text class="pa-1">
                                <h2 class="body-1 font-weight-normal mb-1 grey--text text--darken-1"><strong>Are you sure you want to clear the trash?</strong> <br> This action cannot be undone.</h2>
                                <div class="u-flex align-center justify-space-between mt-2">
                                    <p class="caption grey--text text--darken-1 mb-0 font-weight-normal">Click "Delete All" to continue</p>
                                    <a-btn @click="local_delete_all()" small text class="red darken-1 u-rounded-corners ma-0 pa-0 px-3 ml-2" dark>Delete All</a-btn>
                                </div>
                            </a-card-text>
                        </a-card>
                    </a-menu>
                </template>
            </g-page-header>
            <a-container grid-list-xl container--fluid class="pa-1 mt-0 mb-4">
                <a-layout align-center class="my-0 mb-2">
                    <a-flex class="py-0">
                        <a-card class="c-search-inventory px-3 d-flex align-center u-elevation-custom-1 u-rounded-corners mb-1">
                            <a-icon size="24" color="grey" style="max-width: 20px;">search</a-icon>
                            <a-text-field solo flat hide-details dense placeholder="Search by hardware name, part number or serial number" v-model="local_search_inventory"></a-text-field>
                        </a-card>
                    </a-flex>
                    <a-flex shrink class="pl-1">
                        <a-progress-circular color="orange darken-2" size="24" width="2" :indeterminate="loading"></a-progress-circular>
                    </a-flex>
                </a-layout>
                <a-responsive class="py-4 mt-4" v-if="local_render && local_render.length === 0 && !local_loading">
                    <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Inventory Found</h3>
                    <p class="md-body-2 text-center grey--text mb-0">There is no inventory to fetch right now</p>
                </a-responsive>
                <a-layout align-center class="mt-4" v-if="local_render && local_render.length">
                    <a-flex class="py-0">
                        <a-card class="u-elevation-custom-1 pb-0 pt-0 mb-0 u-rounded-corners">
                            <a-divider></a-divider>
                            <a-layout align-center class="px-0 grey lighten-4 mx-0 py-2">
                                <a-flex shrink :class="[$can('inventories.destroy') ? 'xs5' : 'xs6']" class="pa-0 px-2">
                                    <a-layout align-center class="ma-0 pa-0">
                                        <a-flex shrink xs6 class="mx-0 py-0 px-2">
                                            <h4 class="caption grey--text text--darken-3 text-uppercase font-weight-bold">Name</h4>
                                        </a-flex>
                                        <a-flex shrink xs3 class="mx-0 py-0 pl-1">
                                            <h4 class="caption grey--text text--darken-3 text-uppercase font-weight-bold text-xs-left">OEM</h4>
                                        </a-flex>
                                        <a-flex shrink xs3 class="mx-0 py-0 pl-1">
                                            <h4 class="caption grey--text text--darken-3 text-uppercase font-weight-bold text-xs-left">Model #</h4>
                                        </a-flex>
                                    </a-layout>
                                </a-flex>
                                <a-flex shrink xs6 class="pa-0 px-2">
                                    <a-layout align-center class="ma-0 pa-0">
                                        <a-flex shrink xs3 class="mx-0 py-0 pl-0">
                                            <h4 class="caption grey--text text--darken-3 text-uppercase font-weight-bold text-xs-left">Part #</h4>
                                        </a-flex>
                                        <a-flex shrink xs3 class="mx-0 py-0 pl-0">
                                            <h4 class="caption grey--text text--darken-3 text-uppercase font-weight-bold text-xs-left">Serial #</h4>
                                        </a-flex>
                                        <a-flex shrink xs6 class="mx-0 py-0 pl-0">
                                            <h4 class="caption grey--text text--darken-3 text-uppercase font-weight-bold text-xs-left">Location</h4>
                                        </a-flex>
                                    </a-layout>
                                </a-flex>
                                <a-flex shrink xs1 v-if="$can('inventories.destroy')"  class="pa-0 mr-2 ml-auto" style="width: 40px; max-width: 40px;">
                                    &nbsp;
                                </a-flex>
                            </a-layout>
                            <a-divider></a-divider>
                            <template v-for="(inventory, index) in local_render">
                                <a-card class="white ma-0 pa-0 elevation-0" :key="inventory + '-'+ index" :class="[$can('inventories.destroy') ? 'py-1' : 'py-3']">
                                    <div class="pa-0 ma-0" :class="[$can('inventories.destroy') ? 'py-2': 'py-1']">
                                        <a-layout align-center class="ma-0 pa-0">
                                            <a-flex :class="[$can('inventories.destroy') ? 'xs5' : 'xs6']" class="pa-0 px-2">
                                                <a-layout align-center class="ma-0 pa-0">
                                                    <a-flex xs6 class="pa-0 px-2">
                                                        <h4 class="body-2 text-truncate" :title="inventory.name">{{ inventory.name }}</h4>
                                                    </a-flex>
                                                    <a-flex xs3 class="pa-0 px-1">
                                                        <h4 class="body-2 text-xs-left text-truncate" :title="inventory.oem">{{ inventory.oem ? inventory.oem : ' - ' }}</h4>
                                                    </a-flex>
                                                    <a-flex xs3 class="pa-0 px-1">
                                                        <h4 class="body-2 text-xs-left text-truncate" :title="inventory.model_no">{{ inventory.model_no ? inventory.model_no : ' - ' }}</h4>
                                                    </a-flex>
                                                </a-layout>
                                            </a-flex>
                                            <a-flex xs6 class="pa-0 px-1">
                                                <a-layout align-center class="ma-0 pa-0">
                                                    <a-flex xs3 class="pa-0 px-1">
                                                        <h4 class="body-2 text-xs-left text-truncate" :title="inventory.part_no">{{ inventory.part_no ? inventory.part_no : ' - ' }}</h4>
                                                    </a-flex>
                                                    <a-flex xs3 class="pa-0 pl-0">
                                                        <h4 class="body-2 text-xs-left text-truncate" :title="inventory.serial_no">{{ inventory.serial_no ? inventory.serial_no : ' - ' }}</h4>
                                                    </a-flex>
                                                    <a-flex xs6 class="pa-0 pl-0">
                                                        <h4 class="body-2 text-xs-left text-truncate" :title="inventory.location">{{ inventory.location ? inventory.location : ' - ' }}</h4>
                                                    </a-flex>
                                                </a-layout>
                                            </a-flex>
                                            <!-- <a-flex xs1 style="width: 80px; max-width: 80px;" class="pa-0 text-xs-right d-flex align-center ml-auto mr-2"> -->
                                            <a-flex xs1 v-if="$can('inventories.destroy')" class="pa-0 d-flex align-center justify-center">
                                                <template v-if="$can('inventories.destroy')">
                                                    <a-sheet max-width="50" class="text-center">
                                                        <a-menu left offset-y class="u-flex justify-center" transition="slide-y-transition" :disabled="!(local_render.length)" max-width="340">
                                                            <template v-slot:activator="{ on }">
                                                                <a-btn v-on="on" icon small class="ma-0">
                                                                    <a-icon size="18" class="grey--text">delete</a-icon>
                                                                </a-btn>
                                                            </template>
                                                            <a-card class="u-rounded-corners u-elevation-custom-1 pa-3" min-width="320" max-width="340">
                                                                <a-card-text class="pa-1">
                                                                    <h2 class="body-2 font-weight-normal mb-1 grey--text text--darken-1"><strong>Are you sure you want to delete the inventory?</strong> <br> This inventory and related booking data will be permanently deleted. This action cannot be undone.</h2>
                                                                    <div class="u-flex align-center justify-space-between mt-2">
                                                                        <p class="caption grey--text text--darken-1 mb-0 font-weight-normal">Click "Delete" to continue</p>
                                                                        <a-btn @click="inventory_delete_permamnent({ id: inventory.id })" small text class="red darken-1 u-rounded-corners ma-0 pa-0 px-3 ml-2" dark>Delete</a-btn>
                                                                    </div>
                                                                </a-card-text>
                                                            </a-card>
                                                        </a-menu>
                                                    </a-sheet>
                                                </template>
                                                <a-divider vertical v-if="$can('inventories.destroy')" class="grey lighten-1 my-2 mx-2"></a-divider>
                                                <a-sheet max-width="50" v-if="$can('inventories.destroy')" class="text-center">
                                                    <a-tooltip bottom class="u-flex justify-center">
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on">
                                                                <a-btn icon small class="ma-0" @click="local_undo_inventory(inventory.id)">
                                                                    <a-icon size="18" class="grey--text">restore</a-icon>
                                                                </a-btn>
                                                            </div>
                                                        </template>
                                                        <span>Restore</span>
                                                    </a-tooltip>
                                                </a-sheet>
                                            </a-flex>
                                        </a-layout>
                                    </div>
                                </a-card>
                                <a-divider :key="inventory.id + '-divider'"></a-divider>
                            </template>
                        </a-card>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-container>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            filter_status: [
                {name: 'All'},
                {name: 'Available'},
                {name: 'In Use'},
                {name: 'Not Available'},
            ],
            status: {name: 'All'},
            filter_assignee: [
                {name: 'All'},
                {name: 'Vikas'},
                {name: 'Hameed'},
                {name: 'Sujith'},
            ],
            assigned_to: {name: 'Assignee'},
            filter_category: [
                {name: 'All'},
                {name: 'Routers'},
                {name: 'Processor'},
                {name: 'Circuit Protection'},
                {name: 'No Category'},
            ],
            category: {name: 'All'},
            sort_inventory: [
                {name: 'Next Available Date'},
                {name: 'Name'},
            ],
            sorted_by: {name: 'Next Available Date'},
            group_inventory: [
                {name: 'Category', value: 'category.value'},
                {name: 'Part Number', value: 'part_no'},
                {name: 'No Grouping', value: 'ungroup'},
            ],
            grouped_by: {name: 'Category'},
            inventory_book_step: 1,
            select_date_range: false,
            date_range_from: null,
            date_range_to: null,
            selected_from_date: null,
            selected_to_date: null,
            show_date_picker: false,
            pane_inventory: false,
            inventory_detail: null,
            inventory_edit_item: null,
            inventory_request_list: [],
            inventory_status_list: [],
            inventory_category_list: [],
            local_select_id: null,
            local_select_status: null,
            status_update_item: {},
            show_edit_booking_from: false,
            show_edit_booking_to: false,
            show_edit_next_avail: null,
            show_send_request_form: false,
            datepicker_request_booking_from: false,
            datepicker_request_booking_to: false,
            inventory_group_by: "category.value",
            inventory_category_search: null,
            local_category_item: null,
            category_exist_message: null,
            local_search_inventory: '',
            local_project_item: {},
            local_inventory_filter_list: [],
            local_project_key_member_list: [],
            local_loading: false
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        local_filtered_inventory_category_list() {
            return this.inventory_category_list.filter(item => item.value)
        },
        local_render() {
            if (this.local_search_inventory) {
                const search_value = this.local_search_inventory.toLowerCase()
                const searchableFields = ['name', 'part_no', 'serial_no']
                return this.inventory_list.filter(item => {
                    return searchableFields.some(field => item[field] && item[field].toLowerCase().includes(search_value))
                })
            }
            return this.inventory_list;
        },
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapGetters('Inventory', {
            inventory_list: 'trash_list',
            inventory_response: 'response',
        }),
        ...mapGetters('InventoryBooking', {
            inventory_booking_list: 'list',
            inventory_booking_response: 'response',
        }),
        ...mapState('Project', {
            project_list: 'list',
            project_item: 'item',
            project_filters: 'filters',
        }),
        ...mapState('Meta', {
            meta_list: 'list',
            meta_item: 'item',
            meta_filters: 'filters',
            meta_response: 'response'
        }),
        ...mapState('User', {
            user_self: 'self',
            user_list: 'list',
            user_item: 'item',
            user_response: 'response',
        }),
        ...mapState('Inventory', {
            inventory_filters: 'filters',
        }),
    },
    methods: {
        async local_index () {
            if (!this.$can('inventories.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.local_loading = true
            await this.inventory_clear()
            await this.inventory_trash_index({
                'filter[trashed]': 'only'
            })
            this.local_loading = false
        },
        local_select_user_detail(id) {
            if(id) {
                // let index = _.findIndex(this.local_project_key_member_list, { 'id': id })
                let index = this.local_project_key_member_list.findIndex(item => item.id === id)
                this.local_project_item.assigee = this.local_project_key_member_list[index].name
            }
        },
        async local_inventory_booking() {
            this.request_form_data.inventory_id = []
            this.inventory_request_list.forEach((item, index) => {
                this.request_form_data.inventory_id.push(item.id)
            });

            await this.inventory_booking_store({data : this.request_form_data})
            if (this.inventory_booking_response.status === 'success') {
                this.$router.push({name: 'inventory'})
            }
        },
        local_group(value) {
            this.inventory_group_by = value
        },
        local_timeline_validation() {
            return this.request_form_data.project_id !=null && this.request_form_data.assignee_id != null && this.request_form_data.booking_from != null && this.request_form_data.booking_to != null && this.request_form_data.location != null
        },
        local_request_location_clear() {
            if(!this.request_form_data.location) {
                this.request_form_data.location = null
            }
        },
        local_check_for_requested_value (id) {
            return this.inventory_request_list.find(item => item.id === id) ? true : false
        },
        local_inventory_request_list (data) {
            const index = this.inventory_request_list.findIndex(item => item.id === data.id)

            if (index !== -1) {
                this.inventory_request_list.splice(index, 1)
                return
            }

            !data ? this.inventory_request_list = [] : this.inventory_request_list.push(data)
        },
        async local_undo_inventory(id) {
            await this.inventory_delete_undo({ id })
            await this.inventory_trash_index({ 'filter[trashed]': 'only' })
        },
        async local_delete_all() {
            await this.inventory_delete_all()
            this.inventory_trash_index({ 'filter[trashed]': 'only' })
        },
        ...mapActions('Inventory', {
            inventory_trash_index: 'trash_index',
            inventory_update: 'update',
            inventory_select: 'select',
            inventory_destory:'destroy',
            inventory_delete_permamnent: 'trash_permanent',
            inventory_delete_undo:'delete_undo',
            inventory_delete_all: 'trash_all',
            inventory_clear_item: 'clear_item',
            inventory_clear: 'clear',
        }),
        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
            meta_clear_item: 'clear_item',
        }),
        ...mapActions('Project', {
            project_index: 'index',
            project_clear: 'clear',
            project_clear_item: 'clear_item',
        }),
        ...mapActions('User', {
            user_index: 'index',
            user_enabled: 'enabled',
            user_clear: 'clear',
            user_select: 'select',
            user_clear_item: 'clear_item',
        }),
        ...mapActions('InventoryBooking', {
            inventory_booking_index: 'index',
            inventory_booking_store: 'store',
            inventory_booking_clear: 'clear',
            inventory_booking_select: 'select',
            inventory_booking_clear_item: 'clear_item',
        }),
    },
}
</script>

<style lang="scss" scoped>
.c-inventory-detail-pane {
    transition: 0.3s all;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    height: 100vh;
    width: 520px;
    overflow-y: auto;
    transform: translateX(520px);
}
.c-inventory-detail-pane.visible {
    transform: translateX(0);
}
.c-inventory-history::-webkit-scrollbar-track,
.c-inventory-detail-pane::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 8px;
	background-color: #F5F5F5;
}
.c-inventory-history::-webkit-scrollbar {
	// width: 8px;
	width: 2px;
	background-color: #F5F5F5;
}
.c-inventory-detail-pane::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}
.c-inventory-history::-webkit-scrollbar-thumb,
.c-inventory-detail-pane::-webkit-scrollbar-thumb {
	border-radius: 8px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #d2d2d2;
}
</style>

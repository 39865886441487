<template>
	<div class="mb-12">
        <!-- Title & More options -->
        <div class="u-flex-center-y">
            <div>
                <h3 class="text-h6 grey--text text--darken-3 font-weight-medium">Overview</h3>
            </div>
            <a-spacer></a-spacer>
            <a-menu bottom left offset-y content-class="u-rounded-corners-lg" nudge-bottom="3" min-width="220" max-width="400">
                <template v-slot:activator="{ on }">
                    <div v-on="on" style="height: 40px;" class="py-2 pl-2 pr-4 ma-0 u-flex-center-y u-cursor-pointer u-rounded-corners-lg white u-shadow" v-ripple="true">
                        <a-icon size="20" color="grey darken-1" class="mr-1">more_vert</a-icon>
                        <span class="md-subtitle-2 grey--text text--darken-3">More Options</span>
                    </div>
                </template>
                <a-list class="u-list-std">
                    <a-list-item :to="{ name: 'projects-import-template' }" v-if="$can('projects.update-only')">
                        <a-list-item-content class="md-body-2 grey--text text--darken-3">Import Template</a-list-item-content>
                    </a-list-item>
                    <a-list-item @click="localSwitchToExternalView('client')">
                        <a-list-item-content class="md-body-2 grey--text text--darken-3">View as Client</a-list-item-content>
                    </a-list-item>
                    <a-list-item @click="localSwitchToExternalView('partner')">
                        <a-list-item-content class="md-body-2 body--text text--darken-1">View as Partner</a-list-item-content>
                    </a-list-item>
                    <a-list-item @click="localTogglePresentationMode()">
                        <div class="u-flex-center-y">
                            <a-icon :size="project_is_friendly_view ? 18 : 16" weight="700" :color="project_is_friendly_view ? 'indigo darken-2' : 'grey darken-2'">{{ project_is_friendly_view ? 'desktop_access_disabled' : 'desktop_windows' }}</a-icon>
                            <span class="ml-2 md-body-2 d-inline-block" :class="[project_is_friendly_view ? 'indigo--text text--darken-3' : 'grey--text text--darken-3']">{{ project_is_friendly_view ? 'Exit' : '' }} Presentation Mode</span>
                        </div>
                    </a-list-item>
                    <a-list-item @click="localUpdateWorkspaceSetup(currentStage)" v-if="$can('projects.update-only') && !$isConfidentialMode()">
                        <div class="u-flex-center-y">
                            <a-icon size="16" color="blue darken-2">login</a-icon>
                            <span class="ml-2 md-body-2 blue--text text--darken-2 font-weight-medium">Move Workspace</span>
                        </div>
                    </a-list-item>
                </a-list>
            </a-menu>
        </div>

        <v-slide-y-transition leave-absolute hide-on-leave>
            <template v-if="!localHasAnyWidgets && !mixinIsLoading('root')">
                <a-sheet class="pt-4 pb-8 mt-8 u-flex-center u-rounded-corners-lg u-shadow" min-height="300">
                    <div class="text-center">
                        <img src="../../assets/images/overview-empty-state.svg" style="width: 250px; height:250px" alt="No Widgets To Show">
                        <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-1 u-opacity-70">There are no widgets to display</h2>
                    </div>
                </a-sheet>
            </template>
            <div v-else>
                <!-- Archived state -->
                <a-sheet v-if="project_item.status === 'archived'" height="44" class="pr-4 mt-6 u-rounded-corners-lg u-flex-center-y grey darken-3">
                    <a-sheet class="px-3 u-flex-center u-hfull transparent" style="borderRadius: 8px 0px 0px 8px;">
                        <a-icon size="18" color="white">archive</a-icon>
                    </a-sheet>
                    <a-divider vertical class="grey"></a-divider>
                    <h3 :class="['md-subtitle-1 ml-3 white--text']">This project has been archived. Assign a stage to unarchive it.</h3>
                </a-sheet>

                <!-- Stage change horz list -->
                <div class="mt-6" v-if="$presentationCheck({ slug: 'stage_change' })">
                    <v-slide-x-transition leave-absolute hide-on-leave>
                        <div v-if="mixinIsLoading('project-stages') || mixinIsLoading('root')" class="u-flex-center-y u-wfull">
                            <div v-for="(count) in 5" :key="count" style="min-width: 1rem; flex: 1;" class="px-2 c-stage-grid u-flex-center u-relative">
                                <div class="white--text u-flex-center">...</div>
                            </div>
                        </div>
                        <!-- <div v-if="!mixinIsLoading('project-stages')" class="u-flex-center-y u-wfull"> -->
                        <div v-else class="u-flex-center-y u-wfull">
                            <template v-for="(stage, index) in stageList">
                                <a-tooltip :key="stage.id" top nudge-top="6" content-class="c-tooltip-pointer c-tooltip-pointer--bottom">
                                    <template v-slot:activator="{ on }">
                                        <div
                                            v-on="on"
                                            :key="stage.id + 'content'"
                                            @click="(localHasPermission('projects.update-only') && currentStageIndex !== index) ? localCheckPlanOnStageMove(stage) : ''"
                                            style="min-width: 1rem; flex: 1;"
                                            :class="[{ 'u-cursor-pointer': localHasPermission('projects.update-only') }, { 'c-stage-grid--light': (currentStageIndex !== null && currentStageIndex < index) || project_item.status === 'archived' }]"
                                            class="px-4 c-stage-grid u-flex-center u-relative md-subtitle-1 white--text"
                                        >
                                            <div style="max-width: 100%;" :class="{ 'grey--text text--darken-3': (currentStageIndex !== null && currentStageIndex < index) || project_item.status === 'archived' }" class="ml-1 text-truncate font-weight-medium">
                                                {{ stage.name }}
                                            </div>
                                        </div>
                                    </template>
                                    <span>
                                        {{ stage.name }}
                                        <template v-if="currentStageIndex === index">(Current Stage)</template>
                                    </span>
                                </a-tooltip>
                            </template>
                        </div>
                    </v-slide-x-transition>
                </div>

                <!-- Status -->
                <template v-if="$presentationCheck({ slug: 'latest_status_update' })">
                    <loader-template v-if="mixinIsLoading('latest-status') || mixinIsLoading('root')" width="100%" height="44" class="mt-6 u-rounded-corners-lg"></loader-template>
                    <div v-else>
                        <a-sheet v-if="(status_update_list && !status_update_list.length)" color="deep-purple lighten-5" height="44" class="pr-3 mt-6 u-rounded-corners-lg u-flex-center-y u-cursor-pointer" @click="$router.push({ name: 'projects-status' })">
                            <a-sheet color="deep-purple" class="px-3 u-flex-center u-hfull" style="borderRadius: 8px 0px 0px 8px;">
                                <a-icon size="18" color="white">donut_large</a-icon>
                            </a-sheet>
                            <h3 class="ml-3 md-subtitle-1 deep-purple--text text--lighten-1">Check out our new & improved status update page here</h3>
                            <a-spacer></a-spacer>
                            <a-icon size="20" color="deep-purple lighten-1">arrow_forward</a-icon>
                        </a-sheet>
                        <a-sheet v-else height="44" class="pr-3 mt-6 u-rounded-corners-lg u-flex-center-y u-cursor-pointer u-shadow" style="flex: 1 1 0;" @click="$router.push({ name: 'projects-status' })">
                            <a-sheet class="px-3 u-flex-center u-hfull" style="borderRadius: 8px 0px 0px 8px;">
                                <a-icon size="18" :color="localGetTypeColor(localLatestStatus.health) + ' darken-2'">donut_large</a-icon>
                                <span :class="[localGetTypeColor(localLatestStatus.health) + '--text text--darken-2']" class="ml-2 md-subtitle-1 font-weight-medium d-block text-truncate" style="maxWidth: 200px;">{{ localLatestStatus.type ? localLatestStatus.type.value : '' }}</span>
                            </a-sheet>
                            <a-divider class="grey lighten-2" vertical></a-divider>
                            <h3 class="mx-3 md-subtitle-1 grey--text text--darken-2 text-truncate" style="minWidth: 12rem; flex: 1">"{{ localLatestStatus.description }}"</h3>
                            <div class="u-flex-center-y">
                                <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on">
                                            <g-avatar :item="localLatestStatus.created_by" :size="30" no-shadow :rightSpace="false"></g-avatar>
                                        </div>
                                    </template>
                                    <span>{{ localLatestStatus.created_by ? localLatestStatus.created_by.name : '' }}</span>
                                </a-tooltip>
                                <a-icon size="6" color="grey lighten-1" class="mx-2">fiber_manual_record</a-icon>
                                <g-moment class="md-caption font-weight-medium grey--text" convert-local :value="localLatestStatus.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD[,] h:mm A"></g-moment>
                            </div>
                        </a-sheet>
                    </div>
                </template>

                <template v-if="$presentationCheck({ slug: 'stage_change' })">
                    <a-sheet v-if="['completed', 'archived'].includes(project_item.status)" class="mt-6 u-rounded-corners-lg pa-4 u-shadow">
                        <div v-if="mixinIsLoading('project-reasons') || mixinIsLoading('root')" class="u-flex-center-y">
                            <loader-template width="45" height="45" class="mr-4 u-rounded-corners-full"></loader-template>
                            <div>
                                <loader-template width="300" height="12" class="mb-3 u-rounded-corners-full"></loader-template>
                                <loader-template width="500" height="8" class="u-rounded-corners-full"></loader-template>
                            </div>
                        </div>
                        <template v-else>
                            <div class="u-flex-center-y justify-space-between u-wfull">
                                <div class="u-flex-center-y u-wfull" v-if="!currentResult || (currentResult && !currentResult.id)">
                                    <div class="mr-3">
                                        <a-avatar color="orange lighten-4" size="44" class="u-flex-center">
                                            <a-avatar color="orange darken-2" size="40" class="u-flex-center">
                                                <a-icon size="20" color="white">info</a-icon>
                                            </a-avatar>
                                        </a-avatar>
                                    </div>
                                    <div class="u-wfull u-relative c-reasons-list">
                                        <h3 class="grey--text text--darken-2 md-subtitle-1 font-weight-medium font-italic">No Result Specified</h3>
                                        <h5 class="grey--text text--darken-1 md-body-2">Select a Project Result to choose its corresponding reason.</h5>
                                    </div>
                                </div>
                                <template v-else>
                                    <div v-if="localResultType" class="u-flex-center-y u-wfull">
                                        <div class="mr-3">
                                            <a-avatar :color="localResultType.color + ' lighten-4'" size="44" class="u-flex-center">
                                                <a-avatar :color="localResultType.color" size="40" class="u-flex-center">
                                                    <a-icon size="20" color="white">{{ localResultType.icon }}</a-icon>
                                                </a-avatar>
                                            </a-avatar>
                                        </div>
                                        <div class="u-wfull u-relative c-reasons-list">
                                            <h3 class="grey--text text--darken-2 md-subtitle-1 font-weight-medium">Project Result: {{ localResultType.value }}</h3>
                                            <div v-if="!localReasonsLength" class="u-flex align-center">
                                                <span class="mr-1 grey--text md-body-2 font-italic d-inline-block">No reason selected.</span>
                                                <div class="u-flex align-center u-relative u-cursor-pointer" @click="localEditResult()">
                                                    <a-sheet class="u-absolute u-wfull grey darken-1" style="left: 0; bottom: 0px; height: 1px;"></a-sheet>
                                                    <span class="grey--text text--darken-1 md-body-2">Select Reasons</span>
                                                    <a-icon color="grey darken-1" size="14" class="ml-1 ">arrow_forward</a-icon>
                                                </div>
                                            </div>
                                            <div v-else class="u-wfull">
                                                <h5 class="grey--text text--darken-1 md-body-2" v-if="currentResultReasons[0]">
                                                    <span :title="!showReasonModel ? currentResultReasons[0].title : ''">
                                                        <template v-if="localReasonsLength > 1">Reason: {{ currentResultReasons[0].title | truncateText(localLgAndDown ? 70 : 120) }}</template>
                                                        <template v-else>Reason: {{ currentResultReasons[0].title | truncateText(localLgAndDown ? 100 : 146) }}</template>
                                                    </span>
                                                    <span v-if="localReasonsLength > 1" @click="showReasonModel = !showReasonModel" :class="[showReasonModel ? 'secondary--text' : 'grey--text text--darken-2']" class="ml-2 u-relative md-body-2 text-decoration-underline u-cursor-pointer">
                                                        +{{ localReasonsLength - 1 }} more reason{{ (localReasonsLength - 1) > 1 ? 's' : '' }}
                                                    </span>
                                                </h5>
                                            </div>
                                            <a-menu v-model="showReasonModel" :close-on-content-click="false" attach=".c-reasons-list" content-class="c-reason-list-view u-shadow-md" nudge-bottom="50" nudge-left="10" offset-y transition="slide-y-transition">
                                                <a-sheet class="u-wfull u-rounded-corners">
                                                    <div class="pa-3">
                                                        <h3 class="text-uppercase secondary--text md-body-2 font-weight-bold track">Reasons</h3>
                                                    </div>
                                                    <a-divider></a-divider>
                                                    <div class="px-3 pb-4">
                                                        <div v-for="reason in currentResultReasons" :key="reason.id" class="mt-4 u-flex align-start">
                                                            <a-avatar size="16" color="indigo lighten-5 mr-2 mt-1">
                                                                <a-icon size="12" color="secondary">fiber_manual_record</a-icon>
                                                            </a-avatar>
                                                            <h3 class="grey--text text--darken-1 md-body-2">{{ reason.title }}</h3>
                                                        </div>
                                                    </div>
                                                </a-sheet>
                                            </a-menu>
                                        </div>
                                    </div>
                                </template>

                                <div class="u-flex-center-y">
                                    <a-tooltip bottom offset-overflow v-if="project_item && (project_item.comment && project_item.comment.trim())">
                                        <template v-slot:activator="{ on }">
                                            <a-icon v-on="on" color="grey darken-1" size="16" class="mr-8">comment</a-icon>
                                        </template>
                                        <a-sheet max-width="400" class="transparent u-rounded-corners-lg pa-4">
                                            <h3 class="mb-2 white--text md-body-2 font-weight-bold">Comment:</h3>
                                            <p class="mb-0 md-body-2 white--text u-pre-wrap">{{ project_item.comment }}</p>
                                        </a-sheet>
                                    </a-tooltip>
                                    <template v-if="localHasPermission('projects.update-only')">
                                        <a-btn v-if="currentResult && currentResult.id" icon class="grey lighten-3 u-rounded-corners-full" width="32" height="32" @click="localEditResult()">
                                            <a-icon color="grey darken-1" size="16">edit</a-icon>
                                        </a-btn>
                                        <a-btn v-else text class="px-2 grey lighten-3 u-rounded-corners u-border" height="32" @click="localEditResult()">
                                            <a-icon color="grey darken-1" size="14" class="mr-1">info</a-icon>
                                            <span class="md-body-2 grey--text text--darken-1">Select Result</span>
                                        </a-btn>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </a-sheet>
                </template>

                 <!-- Confidence score -->
                <div class="mt-6 u-flex align-start u-wfull" v-if="localHasConfidenceScoreWigetPermission()">
                    <a-sheet class="u-border u-rounded-corners c-container__half">
                        <div class="px-6 py-5 grey lighten-5">
                            <h2 class="secondary--text md-subtitle-1 font-weight-medium">Confidence Score</h2>
                        </div>
                        <a-divider></a-divider>
                        <div class="px-6 py-10 u-flex justify-space-between align-center">
                            <a-progress-circular
                                :rotate="-90"
                                :size="180"
                                :width="14"
                                :value="local_confidence_score_percent"
                                :color="mixinIsLoading('root') || mixinIsLoading('project') ? 'grey lighten-2' : local_progress_color"
                                :indeterminate="mixinIsLoading('root') || mixinIsLoading('project')"
                            >
                                <div class="text-center">
                                    <template v-if="mixinIsLoading('root') || mixinIsLoading('project')">
                                        <loader-template height="14" width="100" class="u-rounded-corners-full mb-4"></loader-template>
                                        <loader-template height="14" width="100" class="u-rounded-corners-full"></loader-template>
                                    </template>
                                    <template v-else-if="project_item && project_item.confidence_score_record">
                                        <template v-if="project_item.confidence_score">
                                            <div class="indigo--text text--darken-1 md-heading-5" style="font-weight: 600">
                                                {{ project_item.confidence_score && project_item.confidence_score.percentage ? `${project_item.confidence_score.percentage}%` : '' }}
                                            </div>
                                            <div v-if="project_item.confidence_score.current_score" class="font-weight-medium md-subtitle-2 u-flex align-center">
                                                <span class="md-heading-6 grey--text text--darken-2 font-weight-bold">
                                                    {{ project_item.confidence_score.current_score }}
                                                </span>
                                                <span class="grey--text text--darken-1 ml-1">/ {{ project_item.confidence_score.total }}</span>
                                            </div>
                                            <div v-else class="font-weight-medium md-subtitle-2 u-flex align-center">
                                                <a-icon size="12">horizontal_rule</a-icon>
                                                <span class="grey--text text--darken-1 ml-1">/ {{ project_item.confidence_score.total }}</span>
                                            </div>
                                        </template>
                                        <template v-else-if="!project_item.confidence_score && !mixinIsLoading('project')">
                                            <a-icon size="48" color="grey">assignment_late</a-icon>
                                        </template>
                                    </template>
                                </div>
                            </a-progress-circular>
                            <div v-if="project_item && project_item.confidence_score_record && !local_has_confidence_score_configured" class="c-confidence-score__icon grey lighten-4 u-flex justify-center">
                                <a-icon size="80" color="grey">scoreboard</a-icon>
                            </div>
                            <div class="ml-6 grey--text text--darken-2" style="min-width: 60%; max-width: 60%; letter-spacing: -0.26px">
                                <template v-if="mixinIsLoading('root') || mixinIsLoading('project')">
                                    <loader-template height="14" width="225" class="u-rounded-corners-full mb-4"></loader-template>
                                    <loader-template height="14" width="225" class="u-rounded-corners-full mb-8"></loader-template>
                                    <loader-template height="14" width="225" class="u-rounded-corners-full"></loader-template>
                                </template>
                                <template v-else-if="project_item && project_item.confidence_score_record">
                                    <template v-if="local_has_confidence_score_configured">
                                        <template v-if="project_item.confidence_score && project_item.confidence_score.updated_at">
                                            <template v-if="project_item.confidence_score && project_item.confidence_score.percentage !== 0">
                                                <div>This is the accumulated score of this project</div>
                                            </template>
                                            <template v-else>
                                                <div>No confidence score</div>
                                            </template>
                                            <div v-if="project_item.confidence_score && project_item.confidence_score.updated_at">Last updated on {{ localUpdatedDateOutput(project_item.confidence_score.updated_at) }}</div>
                                        </template>
                                        <template v-else>
                                            <div>Confidence score is not updated yet.</div>
                                        </template>
                                        <div v-if="$can('poc_confidence_score.index')" class="c-confidence-score-btn text-center mt-6">
                                            <a-btn
                                                color="blue darken-2"
                                                @click="localNavigateToConfidenceScore()"
                                                class="u-wfull"
                                                depressed text
                                            >
                                                <span class="blue--text text--darken-2">
                                                    <template v-if="$can('poc_confidence_score.update') && localHasPermission('projects.update-only')">Update score</template>
                                                    <template v-else>Go to Confidence Score</template>
                                                </span>
                                            </a-btn>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div>No fields are configured</div>
                                        <div v-if="$can('confidence_score.index')" class="c-confidence-score-btn text-center mt-6">
                                            <a-btn color="blue darken-2" :to="{ name: 'settings-confidence-score'}" class="u-wfull" depressed text>
                                                <span class="blue--text text--darken-2">Configure fields</span>
                                            </a-btn>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </a-sheet>
                </div>

                <!-- Deal amount & Competitors -->
                <div class="mt-6 u-flex align-start u-wfull" style="column-gap: 1.5rem;" v-if="$presentationCheck({ slug: 'deal_amount' }) || $presentationCheck({ slug: 'competitors' })">
                    <a-sheet class="u-border u-wfull u-rounded-corners" v-if="$presentationCheck({ slug: 'deal_amount' })">
                        <h4 class="px-6 py-5 indigo--text text--darken-1 font-weight-medium md-subtitle-1 grey lighten-5">Deal Amount and Others</h4>
                        <a-divider></a-divider>
                        <div :class="['py-4', { 'pb-5': !localHasPreference('partner') && !localHasPreference('territory') }]">
                            <a-sheet v-test-input.deal class="px-6 c-textarea-editor u-flex-center-y u-wfull" :max-width="!$presentationCheck({ slug: 'competitors' }) || !localHasPreference('competitors') ? '50%' : 'auto'" min-height="34">
                                <loader-template height="14" width="150" class="u-rounded-corners-full" v-if="mixinIsLoading('root') || mixinIsLoading('static-fields')"></loader-template>
                                <template v-else>
                                    <div @click="localHasPermission('projects.update-only') ? editDealAmount = true : ''" class="py-1 c-hover-state u-wfull u-rounded-corners-lg" v-if="!editDealAmount">
                                        <g-currency tag="div" class="md-subtitle-1 u-cursor-pointer font-weight-medium green--text text--darken-1" :symbol="localCurrencySymbol" :value="dealAmount"></g-currency>
                                    </div>
                                    <a-tooltip v-if="editDealAmount" bottom :disabled="!mixinHasError('deal-amount-update') && !$response(project_response, 'deal_amount')">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-on="on"
                                                v-model="dealAmount"
                                                placeholder="Enter Deal Amount"
                                                background-color="transparent"
                                                class="u-rounded-corners-lg c-hover-state pa-0"
                                                :class="[{ 'c-wiggle-short u-border-error': $response(project_response, 'deal_amount') || mixinHasError('deal-amount-update') }]"
                                                @input="localUpdateDealAmount(dealAmount)"
                                                @keyup.enter="localBlurDealAmountEdit()"
                                                @blur="localBlurDealAmountEdit()"
                                                solo flat hide-details autofocus
                                            >
                                                <template #prepend-inner>
                                                    <span>{{ localCurrencySymbol }}</span>
                                                </template>
                                            </v-text-field>
                                        </template>
                                        <span>{{ mixinGetError('deal-amount-update') ? mixinGetError('deal-amount-update') : $response(project_response, 'deal_amount') }}</span>
                                    </a-tooltip>
                                </template>
                            </a-sheet>

                            <!-- Start and End dates -->
                            <div class="px-4 mt-2 u-flex-center-y">
                                <div>
                                    <span class="px-2 mb-2 md-caption d-inline-block body--text text--lighten-3 font-weight-medium">Estimated Start / End Date</span>
                                    <div class="u-flex align-center u-rounded-corners px-2" :class="[ !permissionEstStartEndDate ? 'u-cursor-pointer c-hover-state' : '']">
                                        <loader-template height="14" width="150" class="u-rounded-corners-full" v-if="mixinIsLoading('root')"></loader-template>
                                        <s-date-picker
                                            v-test-input.eststartend 
                                            :disabled="!(localHasPermission('projects.update-only') && $can('projects-estimated-start-end-dates.destroy'))" 
                                            :start-date-max-range="localCheckMaxDateRange(project_item.estimated_end_date)"  
                                            :due-date-min-range="localCheckMinDateRange(project_item.estimated_start_date)" 
                                            startDateTitle="Estimated Start Date" 
                                            dueDateTitle="Estimated End Date" 
                                            startDateLabel="estimated_start_date" 
                                            dueDateLabel="estimated_end_date" 
                                            :item="project_item" 
                                            @update="localUpdateDates" 
                                            v-else
                                        >
                                            <a-sheet min-height="24" class="u-flex-center-y transparent">
                                                <template v-if="project_item.estimated_start_date && project_item.estimated_end_date">
                                                    <g-moment class="md-body-2 body--text text--lighten-1 font-weight-medium" :value="project_item.estimated_start_date" input-format="YYYY-MM-DD" :output-format="localDateOutputFormat(project_item)"></g-moment>
                                                    <span class="mx-1 body--text text--lighten-1 font-weight-medium"> - </span>
                                                    <g-moment class="md-body-2 body--text text--lighten-1 font-weight-medium" :class="[]" :value="project_item.estimated_end_date" input-format="YYYY-MM-DD" :output-format="localDateOutputFormat(project_item)"></g-moment>
                                                </template>
                                                <template v-if="project_item.estimated_start_date && !project_item.estimated_end_date">
                                                    <a-icon size="16" color="body lighten-3" class="mr-2">today</a-icon>
                                                    <span class="mr-1 md-body-2 body--text text--lighten-1 font-weight-medium">Est Start Date: </span>
                                                    <g-moment class="md-body-2 body--text text--lighten-1 font-weight-medium" :class="[]" :value="project_item.estimated_start_date" input-format="YYYY-MM-DD" :output-format="localDateOutputFormat({ start_date: project_item.estimated_start_date, due_date: project_item.estimated_end_date })"></g-moment>
                                                </template>
                                                <template v-if="!project_item.estimated_start_date && project_item.estimated_end_date">
                                                    <a-icon size="16" color="body lighten-3" class="mr-2">event</a-icon>
                                                    <span class="mr-1 md-body-2 body--text text--lighten-1 font-weight-medium">Est End Date: </span>
                                                    <g-moment class="md-body-2 body--text text--lighten-1 font-weight-medium" :class="[]" :value="project_item.estimated_end_date" input-format="YYYY-MM-DD" :output-format="localDateOutputFormat({ start_date: project_item.estimated_start_date, due_date: project_item.estimated_end_date })"></g-moment>
                                                </template>
                                                <template v-if="!project_item.estimated_start_date && !project_item.estimated_end_date && (!permissionEstStartEndDate)">
                                                    <a-icon size="16" color="body lighten-3" class="mr-2">calendar_today</a-icon>
                                                    <span class="mr-1 md-body-2 body--text text--lighten-1 font-weight-medium">Select Date</span>
                                                </template>
                                                <template v-if="!project_item.estimated_start_date && !project_item.estimated_end_date && permissionEstStartEndDate">
                                                    <span class="font-italic md-body-2 grey--text text--lighten-1">No date selected</span>
                                                </template>
                                            </a-sheet>
                                        </s-date-picker>
                                    </div>
                                </div>

                                <div :class="[localHasPreference('territory') ? 'ml-8' : '']">
                                    <span class="px-2 mb-2 md-caption d-inline-block body--text text--lighten-3 font-weight-medium">Start / End Date</span>
                                    <div class="u-flex align-center u-rounded-corners px-2" :class="[ !permissionStartEndDate ? 'u-cursor-pointer c-hover-state' : '']">
                                        <loader-template height="14" width="150" class="u-rounded-corners-full" v-if="mixinIsLoading('root')"></loader-template>
                                        <s-date-picker 
                                            v-test-input.startend 
                                            :disabled="!(localHasPermission('projects.update-only') && $can('projects-start-end-dates.destroy'))" 
                                            :start-date-max-range="localCheckMaxDateRange(project_item.due_date)" 
                                            :due-date-min-range="localCheckMinDateRange(project_item.start_date)" 
                                            :item="project_item" 
                                            @update="localUpdateDates" 
                                            v-else
                                        >
                                            <a-sheet min-height="24" class="u-flex-center-y transparent">
                                                <template v-if="project_item.start_date && project_item.due_date">
                                                    <g-moment class="md-body-2 body--text text--lighten-1 font-weight-medium" :value="project_item.start_date" input-format="YYYY-MM-DD" :output-format="localDateOutputFormat(project_item)"></g-moment>
                                                    <span class="mx-1 body--text text--lighten-1 font-weight-medium"> - </span>
                                                    <g-moment class="md-body-2 body--text text--lighten-1 font-weight-medium" :class="[]" :value="project_item.due_date" input-format="YYYY-MM-DD" :output-format="localDateOutputFormat(project_item)"></g-moment>
                                                </template>
                                                <template v-if="project_item.start_date && !project_item.due_date">
                                                    <a-icon size="16" color="body lighten-3" class="mr-2">today</a-icon>
                                                    <span class="mr-1 md-body-2 body--text text--lighten-1 font-weight-medium">Start Date: </span>
                                                    <g-moment class="md-body-2 body--text text--lighten-1 font-weight-medium" :class="[]" :value="project_item.start_date" input-format="YYYY-MM-DD" :output-format="localDateOutputFormat(project_item)"></g-moment>
                                                </template>
                                                <template v-if="!project_item.start_date && project_item.due_date">
                                                    <a-icon size="16" color="body lighten-3" class="mr-2">event</a-icon>
                                                    <span class="mr-1 md-body-2 body--text text--lighten-1 font-weight-medium">End Date: </span>
                                                    <g-moment class="md-body-2 body--text text--lighten-1 font-weight-medium" :class="[]" :value="project_item.due_date" input-format="YYYY-MM-DD" :output-format="localDateOutputFormat(project_item)"></g-moment>
                                                </template>
                                                <template v-if="!project_item.start_date && !project_item.due_date && !permissionStartEndDate">
                                                    <a-icon size="16" color="body lighten-3" class="mr-2">calendar_today</a-icon>
                                                    <span class="mr-1 md-body-2 body--text text--lighten-1 font-weight-medium">Select Date</span>
                                                </template>
                                                <template v-if="!project_item.start_date && !project_item.due_date && permissionStartEndDate">
                                                    <span class="font-italic md-body-2 grey--text text--lighten-1">No date selected</span>
                                                </template>
                                            </a-sheet>
                                        </s-date-picker>
                                    </div>
                                </div>
                            </div>

                            <!-- Partner -->
                            <a-sheet v-test-input.partner class="px-4 mt-3" v-if="localHasPreference('partner')" :max-width="!$presentationCheck({ slug: 'competitors' }) || !localHasPreference('competitors') ? '50%' : 'auto'">
                                <span class="px-2 md-caption body--text text--lighten-3 d-inline-block font-weight-medium">Partner</span>
                                <a-sheet v-if="mixinIsLoading('project-partner') || mixinIsLoading('root')" height="38" class="px-2 u-flex-center-y">
                                    <loader-template height="14" class="u-rounded-corners-full"></loader-template>
                                </a-sheet>
                                <template v-else>
                                    <div style="height: 38px" v-if="!searchPartner && !showPartnerInput" @click.stop="localHasPermission('projects.update-only') ? showPartnerInput = !showPartnerInput : ''" :class="[{ 'c-hover-state u-cursor-pointer': localHasPermission('projects.update-only')}]" class="px-2 py-2 u-flex-center-y u-rounded-corners">
                                        <div v-if="localHasPermission('projects.update-only')">
                                            <a-icon color="grey" size="16">add</a-icon>
                                            <span  class="ml-1 md-body-2 body--text text--lighten-4 font-weight-medium" @click.stop="localHasPermission('projects.update-only') ? showPartnerInput = !showPartnerInput : ''">Add Partner</span>
                                        </div>
                                        <span v-else class="font-italic md-body-2 grey--text text--lighten-1">No Partner</span>
                                    </div>
                                    <div style="height: 38px" v-if="searchPartner && searchPartner.id && !showPartnerInput" @click.stop="localHasPermission('projects.update-only') ? localSetPartnerInput({ showInput: true }) : ''" :class="[{ 'c-hover-state': localHasPermission('projects.update-only')}]" class="px-2 py-2 c-partner-text u-flex-center-y u-rounded-corners">
                                        <span class="md-body-2 body--text text--lighten-2 font-weight-medium" style="cursor: text">{{ searchPartner.name | truncateText(50) }}</span>
                                        <a-spacer></a-spacer>
                                        <a-icon size="20" color="grey lighten-1" class="c-partner-text__clear u-cursor-pointer" v-if="localHasPermission('projects.update-only')" @click.stop="localClearPartner()">clear</a-icon>
                                    </div>
                                    <template v-if="showPartnerInput">
                                        <a-tooltip bottom offset-y :disabled="!mixinHasError('partner-create')">
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on" :class="[{ 'px-2 grey lighten-4 u-rounded-corners': showPartnerInput }]">
                                                    <!--@keyup.enter="localSetPartner(searchPartnerApi, 'onEnter')"-->
                                                    <a-autocomplete
                                                        v-model="searchPartner"
                                                        :items="partnerList"
                                                        item-value="id"
                                                        item-text="name"
                                                        :menu-props="{ rounded: 'md', nudgeBottom: '4px' }"
                                                        :search-input.sync="searchPartnerApi"
                                                        @blur="localBlurPartnerField"
                                                        @keyup.delete="{}"
                                                        @change="localSetPartner($event, 'onSelect')"
                                                        :append-icon="null"
                                                        :clearable="localHasPermission('projects.update-only')"
                                                        :loading="mixinIsLoading('partner-create') || mixinIsLoading('partner-search')"
                                                        background-color="transparent"
                                                        :placeholder="`Search to add ${localHasPermission('partners.update') ? 'or create ' : '' }new partner`"
                                                        class="py-0 u-list-reset md-body-2 font-weight-medium u-rounded-corners u-hfull"
                                                        :class="[{ 'c-wiggle-short u-border-error': mixinHasError('partner-create') }]"
                                                        :hide-no-data="localHasPermission('partners.update') || mixinIsLoading('partner-create') || mixinIsLoading('partner-search')"
                                                        @clear="localClearPartner()"
                                                        solo flat hide-details return-object auto-select-first dense autofocus hide-selected
                                                    >
                                                        <template #selection="{ item }">
                                                            <h3 class="md-body-2 body--text text--lighten-1 font-weight-medium text-truncate" :style="[!localHasPreference('competitors') && localHasPreference('partner') ? { 'max-width': '100%' } : { 'max-width': '450px' }]">{{ item.name }}</h3>
                                                        </template>
                                                        <template #no-data v-if="!mixinIsLoading('partner-create') && !mixinIsLoading('partner-search')">
                                                            <div style="height: 32px" class="px-4 u-flex-center-y">
                                                                <span :class="['md-body-2 body--text text--lighten-1']">No Data Found</span>
                                                            </div>
                                                        </template>
                                                        <template #item="{ item }">
                                                            <div v-if="item && item.id === 'no-data'" style="height: 32px" class="u-flex-center-y u-cursor-pointer">
                                                                <span :class="['md-body-2 body--text text--lighten-1']">{{ item.name }}</span>
                                                            </div>
                                                            <div v-else style="height: 32px" class="u-flex-center-y u-cursor-pointer">
                                                                <a-icon v-if="item && item.id === 'new'" size="20" color="indigo darken-1">add</a-icon>
                                                                <span :class="['md-body-2', item && item.id !== 'new' ? 'body--text text--darken-1' : 'indigo--text text--darken-1 ml-1']">
                                                                    <template v-if="item && item.id === 'new'">Create <span class="font-weight-bold">{{ item.name | truncateText(30) }}</span></template>
                                                                    <template v-else>{{ item.name }}</template>
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </a-autocomplete>
                                                </div>
                                            </template>
                                            <span>{{ mixinGetError('partner-create') }}</span>
                                        </a-tooltip>
                                    </template>

                                </template>
                            </a-sheet>

                            <!-- Territories -->
                            <div class="px-4 mt-3 white u-wfull u-rounded-corners" v-if="$presentationCheck({ slug: 'projects-territories' }) && localHasPreference('territory')">
                                <span class="px-2 md-caption body--text text--lighten-3 d-inline-block font-weight-medium">Territories</span>
                                <div class="px-2 py-2">
                                    <div>
                                        <template v-if="mixinIsLoading('project-territories')">
                                            <loader-template height="14" class="u-rounded-corners-full"></loader-template>
                                        </template>
                                        <template v-else>
                                            <span v-if="(!$can('projects.update-only') || !$can('projects-territories.store')) && !localGetterritoryOfType('count')" class="font-italic md-body-2 grey--text text--lighten-1">No Territories</span>
                                            <SCombobox
                                                v-test-input.territory
                                                :list="localFilteredTerrs"
                                                :selectedList="localGetterritoryOfType('list')"
                                                :canCreate="$can('projects.update-only') && $can('territories.store') && $can('projects-territories.update')"
                                                :canUpdate="$can('projects.update-only') && $can('projects-territories.store')"
                                                :attachId="`territory`"
                                                :totalTerrPage="totalTerrPage"
                                                :currTerrPage="currTerrPage"
                                                :lazyLoadTerrState="lazyLoadTerrState"
                                                item-text="name"
                                                placeholderType="territory"
                                                @search="localSearchTerritory"
                                                @openDropdown="localShowTagSearchInput($event)"
                                                @create="localCreateTerrTag({...$event})"
                                                @lazyLoadTerritory="locallazyLoadTerritory($event)"
                                                @destroy="localDestoryTerrTag"
                                            >
                                                <template #selection="{ item, index, selectionIndex, confirmIndex }">
                                                    <g-tags
                                                        :tag="item"
                                                        :canUpdate="$can('projects.update-only') && $can('projects-territories.store')"
                                                        @destroy="localRemoveTerritory"
                                                        :hideClearIcon="!$can('projects.update-only') && !$can('projects-territories.store')"
                                                        class="mb-1"
                                                        :class="[
                                                            { 'c-ring-blue': selectionIndex === index },
                                                            { 'c-ring-red u-opacity-70': confirmIndex === index }
                                                        ]"
                                                    ></g-tags>
                                                </template>
                                            </SCombobox>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a-sheet>

                    <!-- Competitors -->
                    <a-sheet class="u-border u-wfull u-rounded-corners" v-if="$presentationCheck({ slug: 'competitors' }) && localHasPreference('competitors')">
                        <h4 class="px-6 py-5 indigo--text text--darken-1 font-weight-medium md-subtitle-1 grey lighten-5">Competitors</h4>
                        <a-divider></a-divider>
                        <div class="px-6 pb-5" v-test-input.competitors>
                            <div class="pt-4" v-for="(compType) in competitorTypes" :key="compType.id">
                                <h4 class="mb-1 md-body-2 font-weight-medium grey--text text--darken-1">{{ compType.value }}</h4>
                                <template v-if="mixinIsLoading('project-competitors') || mixinIsLoading('root')">
                                    <loader-template height="14" class="u-rounded-corners-full"></loader-template>
                                </template>
                                <template v-else>
                                    <span v-if="(!$can('projects-competitors.update') || !$can('projects.update-only')) && !localGetCompetitorOfType(compType.id, 'count')" class="font-italic md-body-2 grey--text text--lighten-1">No Competitors</span>
                                    <SCombobox
                                        :list="localFilteredComps(compType.id)"
                                        :selectedList="localGetCompetitorOfType(compType.id, 'list')"
                                        :canCreate="$can('projects.update-only') && $can('competitors.store') && $can('projects-competitors.update')"
                                        :canUpdate="$can('projects.update-only') && $can('projects-competitors.update')"
                                        :attachId="`competitor-${compType.id}`"
                                        :selectionId="compType.id"
                                        :selectionType="compType.selection_type && !localHasFlagCompetitor ? compType.selection_type: 'multiple'"
                                        :totalCompPage="totalCompPage"
                                        :currCompPage="currCompPage"
                                        :lazyLoadCompState="lazyLoadCompState"
                                        item-text="name"
                                        placeholderType="Competitor"
                                        @search="localSearchCompetitors($event, compType.id)"
                                        @openDropdown="localShowCompSearchInput(compType.id, $event)"
                                        @create="localCreateCompTag({ ...$event, category: compType })"
                                        @lazyLoadCompetitors="locallazyLoadCompetitors(compType.id, $event)"
                                        @destroy="localDestoryTag($event, compType.id)"
                                    >
                                        <template #selection="{ item, index, selectionIndex, confirmIndex }">
                                            <SCompetitorTag
                                                @destroy="localRemoveCompetitor($event, compType.id)"
                                                @click="localOpenEditCompDialog(item, compType)"
                                                :tag="item"
                                                :canUpdate="$can('projects.update-only') && $can('projects-competitors.update')"
                                                :hideClearIcon="!$can('projects.update-only')"
                                                class="mb-1"
                                                :class="[
                                                    { 'c-ring-blue': selectionIndex === index },
                                                    { 'c-ring-red u-opacity-70': confirmIndex === index }
                                                ]"
                                            />
                                        </template>
                                    </SCombobox>
                                </template>
                            </div>
                        </div>
                    </a-sheet>
                </div>

                <!-- Description -->
                <a-sheet class="mt-6 u-border u-rounded-corners" v-if="$presentationCheck({ slug: 'description' }) && project_item && project_item.description_json !== undefined">
                    <div class="u-flex-center-y pa-5 u-cursor-pointer grey lighten-5" @click="toggleDescription = !toggleDescription">
                        <div class="u-flex-center-y u-wfull">
                            <a-icon size="24" color="indigo darken-1">keyboard_arrow_{{ toggleDescription ? 'up' : 'down' }}</a-icon>
                            <h2 class="ml-2 secondary--text md-heading-6">Description</h2>
                        </div>
                    </div>
                    <a-sheet key="loaders" class="pa-6" height="220" v-if="mixinIsLoading('static-fields')">
                        <loader-template class="mb-3" height="14" :width="Math.floor(Math.random() * 1200) + 80"></loader-template>
                        <loader-template class="mb-3" height="14" :width="Math.floor(Math.random() * 1000) + 80"></loader-template>
                        <loader-template class="mb-3" height="14" :width="Math.floor(Math.random() * 500) + 80"></loader-template>
                    </a-sheet>
                    <v-slide-y-transition group leave-absolute hide-on-leave v-else>
                        <template v-if="toggleDescription">
                            <a-divider key="divider"></a-divider>
                            <g-editor-box
                                v-test-input.description
                                v-model="project_item.description_json"
                                :can-update="$can('projects.update-only')"
                                :editorClass="['px-4 py-3']"
                                :customHeight="220"
                                key="editor"
                                @update="localDescUpdate"
                                hideFocus hideBorder hideCloseBtn
                            ></g-editor-box>
                        </template>
                    </v-slide-y-transition>
                </a-sheet>

                <!-- Next Steps -->
                <a-sheet class="mt-6 u-border u-rounded-corners" v-if="$presentationCheck({ slug: 'next_steps' }) && project_item && project_item.next_step_json !== undefined && localHasPreference('next_steps')">
                    <div class="u-flex-center-y pa-5 u-cursor-pointer grey lighten-5" @click="toggleNextStep = !toggleNextStep">
                        <div class="u-flex-center-y u-wfull">
                            <a-icon size="24" color="indigo darken-1">keyboard_arrow_{{ toggleNextStep ? 'up' : 'down' }}</a-icon>
                            <h2 class="ml-2 secondary--text md-heading-6">Next Steps</h2>
                        </div>
                    </div>
                    <a-sheet key="loaders" class="pa-6" height="220" v-if="mixinIsLoading('static-fields')">
                        <loader-template class="mb-3" height="14" :width="Math.floor(Math.random() * 1200) + 80"></loader-template>
                        <loader-template class="mb-3" height="14" :width="Math.floor(Math.random() * 1000) + 80"></loader-template>
                        <loader-template class="mb-3" height="14" :width="Math.floor(Math.random() * 500) + 80"></loader-template>
                    </a-sheet>
                    <v-slide-y-transition group leave-absolute hide-on-leave v-else>
                        <template v-if="toggleNextStep">
                            <a-divider key="divider"></a-divider>
                            <g-editor-box
                                v-test-input.nextstep
                                v-model="project_item.next_step_json"
                                :can-update="$can('projects.update-only')"
                                :editorClass="['px-4 py-3 u-overflow-hidden']"
                                :customHeight="220"
                                key="editor"
                                @update="localNextStepUpdate"
                                hideFocus hideBorder hideCloseBtn
                            ></g-editor-box>
                        </template>
                    </v-slide-y-transition>
                </a-sheet>

                <!-- Task & Scope counts -->
                <div class="mt-6 u-flex align-start u-wfull" v-if="$can('usecases.index') && !mixinIsLoading('project-sc-count')">
                    <!-- Scope count -->
                    <a-sheet width="50%" class="u-border u-rounded-corners" v-if="$presentationCheck({ slug: 'scope_widget' })">
                        <div class="u-flex-center-y pa-5 u-cursor-pointer grey lighten-5" @click="toggleScopeCount = !toggleScopeCount">
                            <a-icon size="24" color="indigo darken-1">keyboard_arrow_{{ toggleScopeCount ? 'up' : 'down' }}</a-icon>
                            <h2 class="ml-2 secondary--text md-heading-6">Scope</h2>
                        </div>
                        <v-slide-y-transition group leave-absolute hide-on-leave>
                            <template v-if="toggleScopeCount">
                                <a-divider key="divider"></a-divider>
                                <div key="count" class="py-5 pa-6">
                                    <div class="u-flex-center-y">
                                        <loader-template width="120" height="12" v-if="mixinIsLoading('project-sc-count')"></loader-template>
                                        <template v-else>
                                            <span class="md-body-2 body--text text--lighten-1 font-weight-medium" v-if="project_item">{{ project_item.success_criteria_count }} Success Criteria</span>
                                        </template>
                                        <a-spacer></a-spacer>
                                        <router-link :to="{ name: 'projects-usecases' }" tag="a" class="blue--text text--darken-2 md-body-2 font-weight-medium">View all</router-link>
                                    </div>
                                    <div class="my-4" v-if="project_item">
                                        <loader-template width="100%" height="14" v-if="mixinIsLoading('project-sc-count')"></loader-template>
                                        <a-sheet v-else class="grey lighten-4 u-rounded-corners-full u-wfull" height="12">
                                            <a-sheet class="teal lighten-2 u-rounded-corners-full u-transition" v-if="(project_item.success_criteria_closed_count && project_item.success_criteria_count) && (project_item.success_criteria_closed_count === project_item.success_criteria_count)" width="100%" height="12"></a-sheet>
                                            <a-sheet class="teal lighten-2 u-rounded-corners-full u-transition" v-else-if="project_item.success_criteria_closed_count === 0" width="0" height="12"></a-sheet>
                                            <a-sheet class="teal lighten-2 u-rounded-corners-full u-transition" v-else :width="`calc(${(project_item.success_criteria_closed_count / project_item.success_criteria_count) * 100}%)`" height="12"></a-sheet>
                                        </a-sheet>
                                    </div>
                                    <div v-if="project_item">
                                        <span class="mb-1 md-caption body--text text--lighten-2 font-weight-medium d-inline-block">Completion Progress</span>
                                        <loader-template width="150" height="12" class="mt-2" v-if="mixinIsLoading('project-sc-count')"></loader-template>
                                        <div v-else class="md-body-2 body--text text--lighten-1 font-weight-medium">{{ project_item.success_criteria_closed_count }} / {{ project_item.success_criteria_count }} completed</div>
                                    </div>
                                </div>
                            </template>
                        </v-slide-y-transition>
                    </a-sheet>
                </div>

                <!-- Competitor Edit Dialog -->
                <a-dialog v-model="dialogEditCompetitor" max-width="656">
                    <SCompetitorView
                        :isOpen="dialogEditCompetitor"
                        :item="competitorEditItem"
                        :isLoading="mixinIsLoading('competitor-show')"
                        @delete="localRemoveCompetitor"
                        @close="dialogEditCompetitor = false"
                    />
                </a-dialog>
            </div>
        </v-slide-y-transition>

        <!-- Stage Change Dialog -->
        <a-dialog v-model="dialogStageChange" max-width="640" persistent>
            <ModalStageChange
                :currentStage="currentStage"
                :currentResult="currentResult"
                :selectedStage="stageToChange"
                :isOpen="dialogStageChange"
                @update="localUpdateStage"
                @close="localCancelStageUpdate"
                @updateWorkspace="localUpdateWorkspaceSetup"
                :showCompSection="localShowCompSectionOnComplete === 'Yes' && $can('projects-competitors.update')"
                :isResultEdit="resultEditDialog"
                :automationPreHook="localCheckAutomationPreHook"
                :hookResponse="automation_workflow_hook_response"
            >
                <template v-if="resultEditDialog" #icon-name>thumbs_up_down</template>
                <template v-if="resultEditDialog" #title>Project Result</template>
                <template v-if="resultEditDialog" #action-label>Update Result</template>
                <template #competitors>
                    <p class="mb-3 md-subtitle-1 font-weight-medium grey--text text--darken-2">Competitors</p>
                    <div v-for="(compType, index) in competitorTypes" :class="[{ 'mt-4': index !== 0 }]" :key="compType.id + '-stageChange'">
                        <h4 class="mb-1 md-body-2 font-weight-medium grey--text text--darken-1">{{ compType.value }}</h4>
                        <SCombobox
                            :list="localFilteredComps(compType.id)"
                            :selectedList="localGetCompetitorOfType(compType.id, 'list')"
                            :canCreate="$can('projects.update-only') && $can('competitors.store') && $can('projects-competitors.update')"
                            :canUpdate="$can('projects.update-only') && $can('projects-competitors.update')"
                            :attachId="`competitor-updateStage-${compType.id}`"
                            :selectionId="compType.id"
                            :selectionType="compType.selection_type && !localHasFlagCompetitor ? compType.selection_type: 'multiple'"
                            :totalCompPage="totalCompPage"
                            :currCompPage="currCompPage"
                            :lazyLoadCompState="lazyLoadCompState"
                            item-text="name"
                            placeholderType="Competitor"
                            @search="localSearchCompetitors($event, compType.id)"
                            @openDropdown="localShowCompSearchInput(compType.id, $event)"
                            @create="localCreateCompTag({ ...$event, category: compType })"
                            @lazyLoadCompetitors="locallazyLoadCompetitors(compType.id, $event)"
                            @destroy="localDestoryTag($event, compType.id)"
                        >
                            <template #selection="{ item, index, selectionIndex, confirmIndex }">
                                <SCompetitorTag
                                    @destroy="localRemoveCompetitor($event, compType.id)"
                                    @click="localOpenEditCompDialog(item, compType)"
                                    :tag="item"
                                    :canUpdate="$can('projects.update-only') && $can('projects-competitors.update')"
                                    :hideClearIcon="!$can('projects.update-only')"
                                    class="mb-1"
                                    :class="[
                                        { 'c-ring-blue': selectionIndex === index },
                                        { 'c-ring-red u-opacity-70': confirmIndex === index }
                                    ]"
                                />
                            </template>
                        </SCombobox>
                        <span class="md-caption red--text text--darken-2 mt-2 font-weight-medium" v-if="localGetValidateResultError(compType.id)">
                            <a-icon size="16" color="red darken-2">dangerous</a-icon>
                            {{ localGetValidateResultError(compType.id) }}
                        </span>
                    </div>
                </template>
            </ModalStageChange>
        </a-dialog>

        <!-- Workspace Change Dialog -->
        <a-dialog v-model="dialogWorkspaceChange" max-width="600">
            <ModalWorkspaceChange
                :currentStage="currentStage"
                :currentWorkspaceId="current_workspace_id_mixin"
                :workspaceList="workspace_board_list"
                :isOpen="dialogWorkspaceChange"
                @update="localUpdateWorkspace"
                @close="localCancelWorkspaceChange"
            />
        </a-dialog>

        <!-- User Upgrade Popup -->
        <a-dialog max-width="400" v-model="dialog_user_upgrade_modal">
            <SUserUpgradeModal @close="dialog_user_upgrade_modal = false" />
        </a-dialog>

        <!-- Admin Upgrade Popup -->
        <a-dialog max-width="600" v-model="dialog_admin_upgrade_modal">
            <SAdminUpgradeModal
                :isOpen="dialog_admin_upgrade_modal"
                @upgrade="{}"
                @close="dialog_admin_upgrade_modal = false"
            >
                <template #plan-info-text>
                    Your current <span class="secondary--text font-weight-bold">Starter</span> plan is limited to {{ $plan('active_projects_limit') }} active projects.
                </template>
            </SAdminUpgradeModal>
        </a-dialog>

        <!-- Presentation Confirmation Dialog -->
        <a-dialog v-model="dialogPresentationConfirm" max-width="440">
            <a-card class="px-8 py-6">
                <h2 class="md-heading-6 font-weight-medium grey--text text--darken-3">
                    You are entering Presentation Mode
                </h2>
                <h3 class="md-subtitle-2 grey--text text--darken-2 mt-1">This will hide confidential data from the Project</h3>
                <div class="mt-6 u-flex-center-y">
                    <a-btn @click="localSwitchToFriendlyView()" dark text depressed class="px-3 py-1 mr-3 indigo darken-3">Continue</a-btn>
                    <a-btn @click="dialogPresentationConfirm = false" text depressed class="px-3 py-1 grey--text text--darken-1 grey lighten-3">Cancel</a-btn>
                </div>
            </a-card>
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import {
    SCombobox,
    SDatePicker,
    SCompetitorTag,
    SCompetitorView,
    SCompetitorTypes,
    SUserUpgradeModal,
    SAdminUpgradeModal,
    SCompetitorAddDropdown,
} from '@/config/config-shared-components'
import {
    ModalAddCompetitor,
    ModalStageChange,
    ModalWorkspaceChange
} from './Modals'
import { diffFormat, diffSimple } from '@/helpers/helper-date-filter'
import { Validate } from '@/helpers/helper-validations'
import loadWorkspace from '@/mixins/mixin-workspace'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import mixinErrors from '@/mixins/mixin-module-error-setup'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'
import mixinAutomationHook from '@/mixins/mixin-automation-hook'
import EventBus from '@/config/config-eventbus';
import { notifier } from '../../helpers/helper-notification'

export default {
    name: 'ProjectOverview',
    mixins: [
        mixinLoading,
        mixinErrors,
        loadWorkspace,
        mixinAutomationHook,
        mixinGetOrganizationPlan,
    ],

    components: {
        SCombobox,
        SDatePicker,
        SCompetitorTag,
        SCompetitorView,
        SCompetitorTypes,
        ModalStageChange,
        SUserUpgradeModal,
        SAdminUpgradeModal,
        ModalAddCompetitor,
        ModalWorkspaceChange,
        SCompetitorAddDropdown,
    },

    data () {
        return {
            /* Numbers */
            bulkCompChunkCount: 50,
            terrCompChunkCount: 50,


            /* String || Null */
            dealAmount: '',
            tagSearchType: '',
            searchPartner: '',
            searchTerritory: '',
            searchPartnerApi: '',
            searchTerritoryApi: '',
            partnerSearchTimeout: null,
            territorySearchTimeout: null,
            compBulkAttachTimeout: null,
            terrBulkAttachTimeout: null,
            dealAmountTimeout: null,
            descriptionTimeout: null,
            nextStepTimeout: null,
            importTimeout: null,
            currentStageIndex: null,

            /* Boolean */
            showPartnerInput: false,
            toggleDescription: true,
            toggleNextStep: true,
            toggleScopeCount: true,
            editDealAmount: false,
            showReasonModel: false,
            resultEditDialog: false,
            isCompetitorUpdate: false,

            /* Array */
            stageList: [],
            partnerDefaultList: [],
            partnerList: [],
            territoryList: [],
            competitorList: [],
            currentResultReasons: [],
            competitorTypes: [],
            currentCompList: [],
            compBulkAttachIds: [],
            preferencesList: [],
            terrBulkAttachIds: [],
            configuration_meta: [],

            /* Dialogs */
            dialogPresentationConfirm: false,
            dialogEditCompetitor: false,
            dialogStageChange: false,
            dialogWorkspaceChange: false,
            dialogTemplateImport: false,
            dialog_user_upgrade_modal: false,
            dialog_admin_upgrade_modal: false,

            /* Objects */
            reasonsList: {},
            currentStage: {},
            stageToChange: {},
            currentResult: {},
            competitorEditItem: {},
            autoSaveText: { text: '', type: '' },
            createTerritoryItem: { id: 'create', name: 'Create', value: 'create' },
            createCompetitorItem: { id: 'create', name: 'Create', value: 'create' },
            project_filters: {
                'include': 'competitors,partner',
                'fields[projects]': 'id,deal_amount,stage_id,result_id,template_group_id,start_date,due_date,estimated_start_date,estimated_end_date,partner_id',
                'fields[partner]': 'id,domain,name',
            },
            org_self_fields: {
                'fields[organizations]': 'id,subscription_plan_id,name,logo,domain,projects_active_count',
                'fields[subscription_plan]': 'id,name,level'
            },
            fieldTypeModel: {},
            totalCompPage: 1,
            currCompPage: 1,
            compRemoveInd: [],
            lazyLoadCompState: false,
            meta_filters: {
                'filter[type]': 'confidence_score',
                'fields[metas]': 'id,type,status,selection_type,percentage,color',
                'sort': 'order',
            },
            range_1: {},
            range_2: {},
            range_3: {},
            totalTerrPage: 1,
            currTerrPage: 1,
            lazyLoadTerrState: false,
            terrRemoveInd: [],
            permissionStartEndDate: false,
            permissionEstStartEndDate: false,
        }
    },

    watch: {
        workspace_current (oldValue, newValue) {
            this.local_index()
        },

        searchPartner (val) {
            if (!val || (val && !val.name)) {
                //this.searchPartnerApi = ''
                this.localClearPartner()
                this.mixinClearError('partner-create')
                const index = this.partnerList.findIndex(item => item.id === 'new')
                if (index !== -1) this.partnerList.splice(index, 1)
            }
        },

        searchPartnerApi (val) {
            if (!val || (val && !val.trim())) {
                this.mixinClearError('partner-create')
                const index = this.partnerList.findIndex(item => item.id === 'new')
                if (index !== -1) this.partnerList.splice(index, 1)
            }

            if (this.project_item.partner && this.project_item.partner.name === val) return
            this.localTriggerPartnerSearch(val)
        },

        'project_item.deal_amount': {
            handler (value) {
                this.dealAmount = value
            }
        },

        $route (to, from) {
            if (to.params.id !== from.params.id) this.local_index()
        }
    },

    mounted () {
        this.local_index()
        EventBus.$on('notification', this.localOnNotification)
        document.addEventListener('keydown', this.localKeyDownEvent)
    },

    async beforeDestroy() {
        document.removeEventListener('keydown', this.localKeyDownEvent)
    },

    computed: {
        localFilteredTerrs () {
            return this.territoryList.filter(item => {
                const projectTerrIds = this.project_item.territories.map(terr => terr.id)
                return projectTerrIds.includes(item.id) ? null : item
            })
        },

        checkTerritoryPermissions() {
            return this.$can('projects.update-only') && this.$can('projects-territories.destroy')
        },

        localProjectId () {
            return this.$route.params.id
        },

        localCompetitorsLength () {
            return this.project_item && this.project_item.competitors && this.project_item.competitors.length
        },

        localLgAndDown () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        localResultType () {
            if (!this.currentResult || (this.currentResult && !this.currentResult.id)) return

            const resultTypes = {
                Positive: { text: 'Won', color: 'green', icon: 'thumb_up' },
                Negative: { text: 'Lost', color: 'red', icon: 'thumb_down' },
                Neutral: { text: 'Undecided', color: 'grey', icon: 'view_stream' },
            }

            return { ...resultTypes[this.currentResult.status], value: this.currentResult.value }
        },

        localReasonsLength () {
            return this.currentResultReasons && this.currentResultReasons.length
        },

        localCurrencySymbol () {
            return this.user_self && this.user_self.organization && this.user_self.organization.currency && this.user_self.organization.currency.symbol
        },

        localLatestStatus () {
            return this.status_update_list && this.status_update_list[0]
        },

        localCompletedStage () {
            return this.stage_list.find(item => item.status === 'completed')
        },

        localShowCompSectionOnComplete () {
            const item = this.preferencesList.find(i => i.key === 'organization.projects.widgets.competitor_stage_complete')
            return item ? item.value : false
        },

        local_org_preference_link_tag () {
            if (this.preferencesList && !this.preferencesList.length) return
            const index = this.preferencesList.findIndex(item => item.key === 'organization.link_competitor_product_tag')
            if (index === -1) return
            return this.preferencesList[index].value === 'Yes' ? true : false
        },

        localHasAnyWidgets () {
            if (!this.project_is_friendly_view) return true
            if (!_.size(this.preferencesList)) return false

            return this.preferencesList
                        .find(i => i.key === 'organization.project.confidential_mode')
                        .values
                        .map(i => i.value)
                        .some(i => i === 'Yes')
        },

        localHasFlagCompetitor () {
            return !(this.localHasCheckCompetitorKey() && this.user_self && this.user_self.organization && this.user_self.organization.flag_competitor_preferences === 1)
        },

        local_progress_color () {
            if (this.project_item && !this.project_item.confidence_score) return
            if (this.configuration_meta && !this.configuration_meta.length) return

            const confidence_score = this.project_item.confidence_score.percentage
            if (confidence_score <= this.range_1.percentage) return this.local_get_color_code(this.range_1.color)
            if ((confidence_score <= this.range_2.percentage) && (confidence_score > this.range_1.percentage)) return this.local_get_color_code(this.range_2.color)
            if (confidence_score > this.range_2.percentage) return this.local_get_color_code(this.range_3.color)
        },

        local_get_range_values (color, value) {
            const result = this.configMeta.find(item => item.color === color)
            if (value === 'percentage') return result[value]
            return this.$color(color ?? 'teal-dark', 'color_bg')
        },

        local_has_confidence_score_configured () {
            return this.project_item && this.project_item.confidence_score_record && this.project_item.confidence_score_record.fields_configured === 1
        },

        local_confidence_score_percent () {
            return this.project_item.confidence_score ? this.project_item.confidence_score.percentage : 0
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('ProjectView', {
            project_item: 'item',
            project_is_friendly_view: 'is_friendly_view',
            project_response: 'response'
        }),

        ...mapState('Stage', {
            stage_list: 'list',
        }),

        ...mapState('Competitor', {
            competitor_list: 'list',
            competitor_meta: 'meta',
            competitor_item: 'item',
            competitor_response: 'response',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Partner', {
            partner_list: 'list',
            partner_response: 'response',
        }),

        ...mapState('Territory', {
            territory_list: 'list',
            territory_meta: 'meta',
            territory_response: 'response',
        }),

        ...mapState('MetaResultReason', {
            meta_result_reason_list: 'list',
            meta_result_reason_item: 'item',
            meta_result_reason_form_mode: 'form_mode',
            meta_result_reason_response: 'response',
        }),

        ...mapState('Workspace', {
            workspace_board_list: 'board_list',
        }),

        ...mapState('StatusUpdate', {
            status_update_list: 'list',
        }),

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),

        ...mapState('Preference', {
            preference_list: 'list'
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),
    },

    methods: {
        async local_index() {
            this.mixinSetLoading('root')
            this.mixinSetLoading('project')
            this.$vuetify.goTo(0)
            this.automation_validation_result_status()
            await this.mixinFetchOrgPlanDetails()
            this.localResetBasicFields()
            this.localStartAllLoaders()
            await this.localFetchPreferences()
            this.localCheckDatePermission()
            this.local_load_configuration_meta()
            await this.localProjectShow()
            this.mixinResetLoading('project')
            this.workspace_board()
            this.localGetStaticFields()
            this.localGetCompetitorTypes()
            this.localGetRelationFields()
            this.mixinResetLoading('root')
        },

        async localOnNotification (args) {
            if (this.$router.isActive()) return

            await this.localProjectShow({ 'fields[projects]': 'id,stage_id,result_id,status' })
            if (notifier.action(args?.type).isProjectStageUpdate()) {
                const isArchived = _.get(args.data, 'to.status') === 'archived'
                if (isArchived) {
                    this.localArchiveProject()
                    return this.currentStageIndex = null
                }

                this.localGetStages('update')
                if (this.project_item?.status !== 'completed') return
                this.localGetProjectResult()
            }

            if (notifier.action(args?.type).isProjectStatusUpdate()) {
                this.localGetLatestStatus()
            }
        },

        localArchiveProject () {
            const project = _.cloneDeep(this.project_item)
            Object.assign(project, { stage_id: null, stage: null, status: 'archived' })
            this.project_update_item(project)
        },

        localResetBasicFields () {
            this.currentStageIndex = null
        },

        localStartAllLoaders () {
            this.mixinSetLoading('project-stages')
            this.mixinSetLoading('static-fields')
            this.mixinSetLoading('competitor-types')
            this.mixinSetLoading('project-results')
            this.mixinSetLoading('latest-status')
            this.mixinSetLoading('results-meta')
            this.mixinSetLoading('project-reasons')
            this.mixinSetLoading('project-partner')
            this.mixinSetLoading('project-competitors')
            this.mixinSetLoading('project-territories')
            this.mixinSetLoading('project-sc-count')
        },

        localCheckDatePermission () {
            if (!this.localHasPermission('projects-start-end-dates.update-only') || !this.localHasPermission('projects.update-only')) this.permissionStartEndDate = true
            if (!this.localHasPermission('projects-estimated-start-end-dates.update-only') || !this.localHasPermission('projects.update-only')) this.permissionEstStartEndDate = true
        },

        async localFetchPreferences () {
            await this.preference_index()
            this.preferencesList = _.cloneDeep(this.preference_list)
        },

        async local_load_configuration_meta () {
            await this.meta_index({...this.meta_filters})
            this.configuration_meta = _.cloneDeep(this.meta_list)
            this.local_set_config_data()
        },

        local_set_config_data () {
            this.configuration_meta.forEach(item => {
                if (item.color === 'red-dark') this.range_1 = item
                if (item.color === 'yellow-dark') this.range_2 = item
                if (item.color === 'green-dark') this.range_3 = item
            })
        },

        async localGetStaticFields () {
            const params = { 'fields[projects]': 'id,description_json,status,stage_id,comment,result_id,workspace_id,next_step_json' }
            if (this.localHasConfidenceScorePermission()) {
                params['fields[projects]'] = params['fields[projects]']+',confidence_score'
                params['filter[confidence_score]'] = 1
            }
            await this.localProjectShow(params)
            this.dealAmount = this.project_item.deal_amount
            this.mixinResetLoading('static-fields')
        },

        async localGetCompetitorTypes () {
            await this.meta_index({
                'filter[project_id]': this.project_item.id,
                'filter[type]': 'competitor_type',
                'fields[metas]': 'id,value,status,selection_type',
                'sort': 'order'
            })
            this.competitorTypes = _.cloneDeep(this.meta_list)
            this.mixinResetLoading('competitor-types')
        },

        async localGetRelationFields () {
            await this.localGetStages()
            this.localGetLatestStatus()
            this.localGetResults()
            this.localGetProjectResult()
            this.localGetPartner()
            this.localGetCompetitors()
            this.localGetTerritories()
            this.localGetScCount()
        },

        async localGetLatestStatus () {
            await this.status_update_index({
                 page: 1, count: 1,
                'include': 'type,createdBy',
                'filter[project_id]': this.localProjectId,
                'fields[status_updates]': 'id,type_id,created_by_id,description,health,project_id,created_at',
            })
            this.mixinResetLoading('latest-status')
        },

        async localGetStages (type = 'onload') {
            if (type !== 'update') {
                await this.stage_index({ 'filter[workspace_id]': this.current_workspace_id_mixin })
                this.stageList = _.cloneDeep(this.stage_list)
            }

            if (this.project_item && this.project_item.stage_id) {
                const { index } = await this.localFindStage(this.project_item.stage_id)
                this.currentStageIndex = index
            }
            this.mixinResetLoading('project-stages')
        },

        async localGetResults () {
            await this.meta_index({
                'filter[type]': 'project_result',
                'fields[metas]': 'id,value,status',
                'sort': 'order'
            })
            this.mixinResetLoading('results-meta')
        },

        async localGetProjectResult () {
            const params = { include: 'result', 'fields[result]': 'id,type,value,status', 'fields[projects]': 'id,result_id,status' }
            await this.localProjectShow(params)
            this.currentResult = this.project_item.result
            await this.localGetResultsReasons(this.project_item.result_id)
            this.mixinResetLoading('project-results')
        },

        async localGetResultsReasons (result_id) {
            this.reasonsList = []
            if (result_id) {
                await this.meta_result_reason_index({ 'filter[result_id]': result_id })
                this.reasonsList = _.cloneDeep(this.meta_result_reason_list)
                await this.localGetProjectsReasons()
            }
            this.mixinResetLoading('project-reasons')
        },

        async localGetProjectsReasons () {
            const params = { include: 'resultReasons', 'fields[projects]': 'id' }
            await this.localProjectShow(params)
            this.currentResultReasons = this.project_item.result_reasons
        },

        async localGetPartner () {
            const params = { include: 'partner', 'fields[partner]': 'id,name', 'fields[projects]': 'id,partner_id' }
            await this.localProjectShow(params)
            this.localSetPartnerInput()
            this.mixinResetLoading('project-partner')
        },

        localSetPartnerInput (args) {
            this.searchPartner = this.project_item.partner
            const { showInput = false } = args || {}
            if (this.project_item.partner) this.partnerList = [...this.partnerList, this.project_item.partner]
            if (showInput) this.showPartnerInput = true
        },

        async localGetTerritories () {
            const params = { include: 'territories', 'fields[territories]': 'territories.id,name,color', 'fields[projects]': 'id' }
            await this.localProjectShow(params)
            this.localSetProjectTerritory()
            this.mixinResetLoading('project-territories')
        },

        localSetProjectTerritory (args) {
            if (this.project_item.territories && this.project_item.territories.length) {
                this.territoryList = [...this.territoryList, this.project_item.territories]
            }
        },

        async localGetCompetitors () {
            const params = { include: 'competitors.category', 'fields[projects]': 'id' }
            await this.localProjectShow(params)
            this.localSetProjectCompetitors()
            this.mixinResetLoading('project-competitors')
        },

        localSetProjectCompetitors (args) {
            if (this.project_item.competitors && this.project_item.competitors.length) {
                this.competitorList = [...this.competitorList, this.project_item.competitors]
            }
        },

        async localGetScCount () {
            const params = { include: 'successCriteriaClosedCount,successCriteriaCount', 'fields[projects]': 'id' }
            await this.localProjectShow(params)
            this.mixinResetLoading('project-sc-count')
        },

        async localProjectShow (params = null) {
            await this.project_show({
                id: this.localProjectId,
                params: params ?? { ...this.project_filters }
            })
        },

        localCheckPlanOnStageMove (stage, type) {
            const fromStageStatus = this.project_item.status
            const toStageStatus = stage.status
            const { is_admin } = this.user_self || {}
            const { projects_active_count } = this.mixinGetOrgPlan() || {}

            if ((fromStageStatus && ['queue', 'archived', 'completed'].includes(fromStageStatus)) && (toStageStatus && toStageStatus === 'active')) {
                if (this.$plan('active_projects_limit') === -1) return this.localUpdateStageSetup(stage, type)
                if (projects_active_count < this.$plan('active_projects_limit')) return this.localUpdateStageSetup(stage, type)

                if (is_admin) this.dialog_admin_upgrade_modal = true
                else this.dialog_user_upgrade_modal = true
                return false
            }

            this.localUpdateStageSetup(stage, type)
        },

        async localUpdateStageSetup (stage, type = 'change') {
            this.resultEditDialog = !(type === 'change')
            const currentStage = this.stageList.find(item => item.id === this.project_item.stage_id)
            this.currentStage = currentStage
            this.stageToChange = stage ?? null
            
            const responce = await this.localStageSetupValidationCheck(stage.status)
            if (responce === false) return;

            if (stage.status === 'completed') this.localResetCurrentResult()
            this.isCompetitorUpdate = false
            this.dialogStageChange = true
        },

        async localStageSetupValidationCheck (status) {
            if (this.resultEditDialog === true) return true
            await this.localCheckAutomationPreHook(status, this.stageToChange.id)
            if (!this.$status(this.automation_workflow_hook_response)) {
                this.$automation()
                return false
            }
            return true
        },

        async localCheckAutomationPreHook (status, stage_to_id = null, reasonIem = null) {
            const hookItem = {}
            hookItem.id = this.project_item.id
            hookItem.type = status === 'completed' ? 'project-completed' : 'project-stage-changed'
            hookItem.stage_to_id = stage_to_id
            if (reasonIem) {
                hookItem.type = 'project-result'
                hookItem.result_reason = reasonIem.project_reasons
                hookItem.result_id = reasonIem.result
                hookItem.comment = reasonIem.comment
                hookItem.stage_to_id = null
            }
            
            await this.automation_workflow_hook(hookItem)
        },

        localGetValidateResultError (name) {
            if (!_.size(_.get(this.automation_workflow_hook_response, 'current_logs'))) return null;

            const logItem = this.automation_workflow_hook_response.current_logs.find(item => item.field_id === name && item.is_valid === false)
            return _.get(logItem, 'message') || null
        },

        localResetCurrentResult () {
            if (this.currentStage && (this.currentStage.id !== this.stageToChange.id)) {
                this.currentResult = null
            }
        },

        async localProjectReasonsValidation (status, item, clearLoading) {
            if (
                status === 'active' || 
                status === 'completed' && 
                this.resultEditDialog !== true && 
                this.localShowCompSectionOnComplete === 'Yes'
            ) return true

            let { result, comment, reasons, stage_id } = item || {}
            const reasonIem = {}
            reasonIem.project_reasons = reasons.updateReasons
            reasonIem.result = result ? result.id : null
            reasonIem.comment = comment
            await this.localCheckAutomationPreHook(status, stage_id, reasonIem)
            if (!this.$status(this.automation_workflow_hook_response)) {
                this.mixinResetLoading('project-reasons')
                clearLoading()
                return false
            }
            
            return true
        },

        async localProjectCompetitorValidation (status, stageTo, clearLoading) {
            if (status === 'completed' && this.resultEditDialog === false && this.isCompetitorUpdate === false) return true

            const hookItem = {}
            hookItem.id = this.project_item.id
            hookItem.type = 'project-completed'
            hookItem.stage_to_id = stageTo
            await this.automation_workflow_hook(hookItem)
            if (!this.$status(this.automation_workflow_hook_response)) {
                this.localAutomationWorkflowHookErrorsCheck(clearLoading)
                this.mixinResetLoading('project-reasons')
                return false
            } 
            
            return true
        },

        async localAutomationWorkflowHookErrorsCheck (clearLoading) {
            const validationErrors = this.automation_workflow_hook_response.data.filter(item => item.is_valid === false)
            const competitorErrors = this.automation_workflow_hook_response.data.filter(item => item.related_type === 'competitors' && item.is_valid === false)
            if (validationErrors.length == competitorErrors.length) return
            
            this.isCompetitorUpdate = false
            this.dialogStageChange = false
            this.$automation()
            clearLoading()
            this.currentResultReasons = []
            this.localResetCurrentResult()
        },

        async localUpdateStage (item, clearLoading) {
            this.mixinSetLoading('project-reasons')
            const project = _.cloneDeep(this.project_item)
            let { result, comment, reasons, workspace_id, stage_id } = item || {}

            workspace_id = workspace_id ?? project.workspace_id
            stage_id = stage_id ?? this.stageToChange.id
            const { stage: { status } } = await this.localFindStage(stage_id)

            const projectReason = await this.localProjectReasonsValidation(status, item, clearLoading)
            if (projectReason === false) return;

            const projectCompetitor = await this.localProjectCompetitorValidation(status, this.stageToChange.id, clearLoading)
            if (projectCompetitor === false) return;

            if (project.comment !== comment) await this.localUpdateProjectComment(project, item)

            this.localProjectStageUpdate(project, workspace_id, stage_id, item)
            await this.project_workspace_update({ workspace_id, stage_id, project_id: this.localProjectId, result_id: (result && result.id) ?? null })
            this.mixinFetchOrgPlanDetails()
            if (status !== 'completed') this.localProjectShow({ 'fields[projects]': 'id,status,result_id,comment' })

            await this.localRemoveAddReasons(reasons)
            this.localRedirectIfDiffWorkspace(workspace_id)
            this.dialogStageChange = false

            if (status && status === 'completed') {
                this.localGetProjectResult()
                this.localGetProjectsReasons()
            }
            if (status && status !== 'completed') {
                if (project.status === 'completed') await this.localGetProjectsReasons()
                this.mixinResetLoading('project-reasons')
            }

            clearLoading()
            this.isCompetitorUpdate = false
            this.currentResultReasons = []
            this.localResetCurrentResult()
        },

        async localRemoveAddReasons (reasons) {
            if (reasons) {
                const { selectedReasons, removedReasons } = reasons
                if (selectedReasons && selectedReasons.length) await this.localAddReasonsToAssociation(selectedReasons)
                if (removedReasons && removedReasons.length) await this.localRemoveReasonsAssociation(removedReasons)
            }
        },

        async localProjectStageUpdate (project, workspace_id, stage_id, item) {
            let { result } = item || {}
            Object.assign(project, { workspace_id, stage_id, result_id: (result && result.id) ?? null, result: result ?? null })

            const { stage } = await this.localFindStage(stage_id)
            if (project.status === 'archived' && stage_id) Object.assign(project, { status: stage.status })
            this.project_update_item(project)
            this.localGetStages('update')
        },

        localRedirectIfDiffWorkspace (workspace_id) {
            if (this.current_workspace_id_mixin !== workspace_id) {
                this.$router.replace({ name: 'dashboard-columns' })
            }
        },

        localUpdateProjectComment (project, item) {
            let { comment } = item || {}
            if (_.trim(project.comment) === _.trim(comment)) return
            Object.assign(project, { comment })
            this.localProjectUpdate({ comment, title: this.project_item.title })
        },

        localCancelStageUpdate (clearLoading) {
            this.dialogStageChange = false
            clearLoading()
        },

        async localAddReasonsToAssociation (selectedReasons) {
            for (let index = 0; index < selectedReasons.length; index++) {
                const reason_id = selectedReasons[index];
                const params = { source_type: 'Project', source_id: this.localProjectId, target_id: reason_id, target_type: 'ProjectResultReason', type: 'project_result_reason' }
                await this.association_store(params)
            }
        },

        async localRemoveReasonsAssociation (reasons) {
            for (let index = 0; index < reasons.length; index++) {
                const reason_id = reasons[index];
                const reason = this.project_item.result_reasons.find(item => item.id === reason_id)
                if (reason && reason.association) this.association_destroy({ id: reason.association.id })
            }
        },

        localUpdateWorkspaceSetup (stage) {
            this.currentStage = stage
            this.dialogWorkspaceChange = true
            this.dialogStageChange = false
        },

        localUpdateWorkspace (item) {
            const { result: selectedResult, stage, workspace_id } = item || {}
            const { result, comment, reasons } = selectedResult || {}

            if (this.current_workspace_id_mixin === workspace_id) return item.resetLoading()
            if (!workspace_id) return item.resetLoading()
            if (!stage || (stage && !stage.id)) return item.resetLoading()
            if ((stage && stage.status === 'completed') && (!result || (result && !result.id))) return item.resetLoading()

            const params = {
                workspace_id,
                selectedStage: stage,
                currentStage: this.currentStage,
                stage_id: stage.id,
                result: result ?? null,
                comment: comment ?? null,
                reasons: reasons ?? null,
            }
            this.localUpdateStage(params, item.resetLoading)
        },

        localCancelWorkspaceChange (resetLoading) {
            this.dialogWorkspaceChange = false
            resetLoading()
        },

        async localFindStage (id) {
            const index = this.stage_list.findIndex(item => item.id === id)
            return { index, stage: this.stage_list[index] }
        },

        /* Date updates - Start */
        async localUpdateDates (event) {
            const { item, type, value } = event || {}
            const payloadItem = this.localCheckUpdateDates(item, type, value)

            await this.project_replace_item(_.cloneDeep(item))
            this.localProjectUpdate(payloadItem)
            this.$notify('success', 'Updated Successfully!')
        },

        localCheckUpdateDates (item, type, value) {
            const payloadItem = {}
            payloadItem.title = item.title
            if(type === 'start_date') {
                payloadItem.is_date_permission = 1
                payloadItem.start_date = value
                payloadItem.due_date = item.due_date
            }
            if(type === 'due_date') {
                payloadItem.is_date_permission = 1
                payloadItem.start_date = item.start_date
                payloadItem.due_date = value
            }
            if(type === 'estimated_start_date') {
                payloadItem.is_estimated_date_permission = 1
                payloadItem.estimated_start_date = value
                payloadItem.estimated_end_date = item.estimated_end_date
            }
            if(type === 'estimated_end_date') {
                payloadItem.is_estimated_date_permission = 1
                payloadItem.estimated_start_date = item.estimated_start_date
                payloadItem.estimated_end_date = value
            }
            return payloadItem
        },
        /* Date updates - End */

        /* Desc and Next step update - Start */
        localDescUpdate (content) {
            clearTimeout(this.descriptionTimeout)
            this.descriptionTimeout = setTimeout(async () => {
                await this.project_update_description({  id: this.project_item.id, description_json: content })
                if (this.$status(this.project_response)) {
                    this.$notify('success', 'Updated Successfully!')
                } else {
                    const response = this.$response(this.project_response, 'description_json')
                    this.$notify('error', response ? response : 'Description Update Failed.')
                }

            }, 500)
        },

        localNextStepUpdate (content) {
            clearTimeout(this.nextStepTimeout)
            this.nextStepTimeout = setTimeout(async () => {
                await this.project_update_next_step({  id: this.project_item.id, next_step_json: content })
                if (this.$status(this.project_response)) {
                    this.$notify('success', 'Updated Successfully!')
                } else {
                    const response = this.$response(this.project_response, 'next_step_json')
                    this.$notify('error', response ? response : 'Next Steps Update Failed.')
                }

            }, 500)
        },
        /* Desc and Next step update - End */

        /* Deal Amount Update - Start */
        localUpdateDealAmount (dealAmount) {
            clearTimeout(this.dealAmountTimeout)
            this.mixinResetLoading('deal-amount-update')

            const { message } = new Validate(dealAmount, { silent: true }).numberRange().run()
            if (message) return this.mixinSetError({ slug: 'deal-amount-update', message })

            this.mixinClearError('deal-amount-update')
            this.mixinSetLoading('deal-amount-update')
            this.dealAmountTimeout = setTimeout(async () => {
                await this.localProjectUpdate({ deal_amount: dealAmount, title: this.project_item.title })
                clearTimeout(this.dealAmountTimeout)
                const hasErrors = this.$response(this.project_response, 'deal_amount')
                if (hasErrors) return this.mixinResetLoading('deal-amount-update')
                this.$notify('success', 'Updated Successfully!')
            }, 600)
        },

        localBlurDealAmountEdit () {
            if (this.editDealAmount && this.$response(this.project_response, 'deal_amount') !== null) return this.mixinResetLoading('deal-amount-update')
            //if (this.mixinIsLoading('deal-amount-update')) return

            this.mixinResetLoading('deal-amount-update')
            this.mixinClearError('deal-amount-update')
            this.project_clear_response()
            this.editDealAmount = false
        },
        /* Deal Amount Update - End */

        /* Partner --- Start */
        async localTriggerPartnerSearch (searchText) {
            this.mixinSetLoading('partner-search')
            await this.localResetPartner()
            if (!searchText || (searchText && !searchText.trim())) {
                return await this.localGetPartnersList()
            }
            this.partnerSearchTimeout = setTimeout(async () => this.localPartnerSearch(searchText), 500)
        },

        async localPartnerSearch (searchText) {
            const hasPermission = this.localHasPermission('projects.update-only') && this.localHasPermission('partners.index')
            if (!hasPermission) return this.mixinResetLoading('partner-search')

            await this.partner_index({ 'filter[name]': searchText ? searchText.toLowerCase() : '', 'fields[partners]': 'id,name', count: 100 })
            this.localSetPartnersList(hasPermission, searchText)

            this.mixinResetLoading('partner-search')
            clearTimeout(this.partnerSearchTimeout)
        },

        localSetPartnersList (permission, searchText) {
            const noData = permission && this.localHasPermission('partners.store') ? { id: 'new', name: searchText } : { id: 'no-data', name: 'No Data Found' }
            this.partnerList = this.partner_list.length ? _.cloneDeep(this.partner_list) : [noData]
        },

        async localResetPartner () {
            this.partnerList = []
            await this.partner_clear()
            clearTimeout(this.partnerSearchTimeout)
        },

        localClearPartner () {
            this.searchPartner = ''
            if (!this.project_item.partner_id) return
            this.$set(this.project_item, 'partner_id', null)
            this.$set(this.project_item, 'partner', null)
            if (!this.showPartnerInput) this.showPartnerInput = true
            this.localProjectUpdate({ partner_id: null, title: this.project_item.title })
            this.localTriggerPartnerSearch ()
        },

        localBlurPartnerField () {
            this.showPartnerInput = false
            this.mixinClearError('partner-create')
            this.mixinResetLoading('partner-create')
            if (this.searchPartner) return
            this.searchPartnerApi = ''
        },

        localSetPartnerParams (value, type) {
            if (['onNew', 'onSelect'].includes(type)) return value

            const id = this.searchPartner.id
            if (id !== 'new') return this.searchPartner
            return { id, name: value }
        },

        async localSetPartner (value, type) {
            const item = this.localSetPartnerParams(value, type)
            if (!item) return

            const { message } = new Validate(item.name, { silent: true }).length(1, 255).run()
            if (message) {
                this.searchPartner = ''
                return this.mixinSetError({ slug: 'partner-create', message })
            }
            this.mixinClearError('partner-create')

            this.mixinSetLoading('partner-create')
            if (item && item.id === 'new') await this.localCreatePartner(item)
            else await this.localProjectUpdate({ partner_id: item.id, title: this.project_item.title })

            this.$notify('success', 'Partner added successfully!')
            this.mixinResetLoading('partner-create')
            this.localProjectShow({ include: 'partner', 'fields[partner]': 'id,name', 'fields[projects]': 'id,partner_id' })
        },

        async localCreatePartner (item) {
            const hasPermission = this.localHasPermission('projects.update-only') && this.localHasPermission('partners.store')
            if (!hasPermission) return this.mixinResetLoading('partner-create')

            delete item.id
            item = this.$appendId(item)
            await this.partner_store(item)

            if (!this.$status(this.partner_response)) {
                this.mixinSetError({ slug: 'partner-create', message: this.$response(this.partner_response, 'name') })
                this.mixinResetLoading('partner-create')
                return true
            }

            this.localProjectUpdate({ partner_id: item.id, title: this.project_item.title })
        },

        async localGetPartnersList () {
            let params = {
                count: 5,
                'fields[partners]': 'id,name',
            }
            await this.partner_index(params)
            this.partnerList = _.cloneDeep(this.partner_list)
            this.mixinResetLoading('partner-search')
        },
        /* Partner --- End */

        /* Territory --- Start */

        async localShowTagSearchInput ({ clearLoading }) {
            this.mixinSetLoading('territory-search')
            this.territoryList = []
            this.terrRemoveInd = []
            await this.localGetTerritoryIndex()
            this.territoryList = _.cloneDeep(this.territory_list)
            this.totalTerrPage = this.territory_meta.last_page
            this.currTerrPage = this.territory_meta.current_page
            this.mixinResetLoading('territory-search')
            clearLoading()
        },

        async localGetTerritoryIndex () {
            let params = {
                count: 10,
                'filter[project_id]': this.localProjectId,
                sort: 'order',
                'fields[territories]': 'id,name,color',
            }
            await this.territory_index(params)
        },

        localGetterritoryOfType (field) {
            const list = _.size(this.project_item.territories) > 0 ? this.project_item.territories : []
            const result = { list, count: list.length }
            return result[field]
        },

        async localSearchLazyLoadTerr(value, page) {
           let params = {
                count: 20,
                sort: 'order',
                'filter[name]': value,
                'filter[project_id]': this.localProjectId,
                'fields[territories]': 'id,name,color',
                page: page
            }
            await this.territory_index(params)
            this.territoryList.push(...this.territory_list)
            this.currTerrPage = this.territory_meta.current_page
            this.lazyLoadTerrState = false
            return
        },

        async locallazyLoadTerritory({page, searchVal}) {
            this.lazyLoadTerrState = true
            if (searchVal.length) return await this.localSearchLazyLoadTerr(searchVal, page)
             let params = {
                count: 10,
                sort: 'order',
                'filter[name]': searchVal,
                'filter[project_id]': this.localProjectId,
                'fields[territories]': 'id,name,color',
                page: page
            }
            await this.territory_index(params)
            this.territoryList.push(...this.territory_list)
            this.currTerrPage = this.territory_meta.current_page
            this.lazyLoadTerrState = false
        },

        async localSearchTerritory (props) {
            const { value, clearLoading } = props || {}
            let params = {
                count: 20,
                sort: 'order',
                'filter[name]': value,
                'filter[project_id]': this.localProjectId,
                'fields[territories]': 'id,name,color',
            }
            await this.territory_index(params)
            this.territoryList = [..._.cloneDeep(this.territory_list)]
            this.currTerrPage = this.territory_meta.current_page
            this.totalTerrPage = this.territory_meta.last_page
            clearLoading()
        },

        async localCreateTerrTag (createProps) {
            const { tag, mode, resolveError, searchVal, resolveSuccess } = createProps || {}

            if (mode === 'select') return this.localAttachTerrToProject({ tag, resolveSuccess, searchVal })

            const hasPermission = this.localHasPermission(['projects.update-only', 'territories.store'])

            if (!hasPermission) return this.mixinResetLoading('territory-create')

            const terrItem = this.$appendId({ color: this.$randomColor(), name: tag.value })

            await this.territory_store(terrItem)
            if (!this.$status(this.territory_response)) {
                return resolveError(this.$response(this.territory_response, 'name'))
            }

            resolveSuccess()
            await this.localAddTerritoryToProject(terrItem)
            this.localAttachTerrTag(terrItem, 'new')
        },

        localAddTerritoryToProject (territory) {
            const project = _.cloneDeep(this.project_item)
            const projTerrs = (project && project.territories) ?? []
            this.$set(project, 'territories', [...projTerrs, territory])
            this.project_replace_item(project)
        },

        async localAttachTerrToProject (params) {
            const { tag, resolveSuccess, searchVal, mode = null } = params || {}
            clearTimeout(this.terrBulkAttachTimeout)
            await this.localAttachTerrTag(tag, mode)
            const index = this.territoryList.findIndex(i => i.id === tag.id)
            if (index !== -1) this.territoryList.splice(index, 1)
            this.terrRemoveInd.push(index)
            resolveSuccess()

            this.terrBulkAttachTimeout = setTimeout(async () => {
                const ceilCount = Math.ceil(this.terrBulkAttachIds.length / this.terrCompChunkCount)
                for (let index = 0; index < ceilCount; index++) {
                    const chunkIds = this.terrBulkAttachIds.splice(0, this.terrCompChunkCount)
                    await this.association_bulk_store({
                        data: chunkIds,
                        type: 'project_territory',
                        source_type: 'Project',
                        source_id: this.localProjectId,
                        target_id: tag.id,
                        target_type: 'Territory',
                    })
                }
                clearTimeout(this.terrBulkAttachTimeout)
            }, 500)
            if (this.territoryList.length === 9 && this.currTerrPage < this.totalTerrPage)  await this.locallazyLoadTerritory({page: this.currTerrPage + 1, searchVal})
        },

        localAttachTerritoryToProject (tag, association = {}) {
            const projectTerrs = (this.project_item && this.project_item.territories) ?? []
            this.$set(this.project_item, 'territories', [...projectTerrs, { ...tag, association }])
            this.project_replace_item(this.project_item)
        },

        async localAttachTerrTag (tag, mode = null) {
            const association = this.$appendId({
                source_type: 'Project',
                source_id: this.localProjectId,
                target_type: 'Territory',
                target_id: tag.id,
                type: 'project_territory'
            })

            if (mode === 'new') {
                await this.association_store(association)
                this.localAttachAssociation (tag.id, association)
                return true
            }
            this.terrBulkAttachIds.push({ id: association.id, target_id: tag.id })
            this.localAttachTerritoryToProject({...tag}, association)
        },

        localAttachAssociation (id, association) {
            const index = this.project_item.territories.findIndex(item => item.id === id)
            if (index === -1) return
            Object.assign(this.project_item.territories[index], { association })
        },

        async localRemoveTerritory (tag) {
            const index = this.project_item.territories.findIndex(item => item.id === tag.id)
            if (index === -1) return
            const territory = _.cloneDeep(this.project_item.territories[index])
            this.project_territory_remove(territory.id)
            this.association_destroy({ id: territory.association.id })
        },

        async localDestoryTerrTag (evt) {
            const { ids: chunkIds } = evt || {}
            const project = this.project_item
            chunkIds.forEach(item => {
                const index = project.territories.findIndex(terr => terr.id === item.id)
                project.territories.splice(index, 0)
                const recentRemovedTerrIndex = this.terrRemoveInd.pop()
                const removedTerritory = {id: item.id, name: item.name, color: item.color}
                if (recentRemovedTerrIndex) this.localAddRemovedTerrToList(removedTerritory, recentRemovedTerrIndex)
                else this.territoryList.splice(0, 0, removedTerritory)
            })
            this.project_replace_item(project)
            await this.association_bulk_destroy({
                ids: chunkIds.map(i => i.association.id),
                source_id: this.localProjectId,
                source_type: 'Project'
            })
        },
        localAddRemovedTerrToList(territory, index) {
            const territoryLength = this.territoryList.length
            if (index < territoryLength) this.territoryList.splice(index, 0, territory)
            else this.territoryList.push(territory)
        },
        /* Territory --- End */

        /* New Comps --- Start */
        async localOpenEditCompDialog (item, category) {
            const org_preference_link_tag = this.local_org_preference_link_tag
            this.dialogEditCompetitor = true
            this.competitorEditItem = _.cloneDeep(item)
            this.mixinSetLoading('competitor-show')
            await this.competitor_show({ id: item.id, params: { 'fields[competitors]': 'description_json,id,name,strengths,weaknesses,domain,link', 'include': 'products' } })
            this.competitorEditItem = _.cloneDeep({ ...this.competitor_item, category, org_preference_link_tag })
            this.mixinResetLoading('competitor-show')
            this.competitor_clear()
        },

        localGetCompetitorOfType (typeId, field) {
            const list = _.size(this.project_item.competitors) > 0 ? this.project_item.competitors.filter(i => i.category_id === typeId) : []
            const result = { list, count: list.length }
            return result[field]
        },

        async localShowCompSearchInput (type, { clearLoading }) {
            this.mixinSetLoading('competitor-search-' + type)
            this.currentCompList = []
            this.compRemoveInd = []
            this.tagSearchType = type
            await this.localOrgPreferenceCompetitorIndex(this.local_org_preference_link_tag, type)
            this.currentCompList = _.cloneDeep(this.competitor_list)
            this.totalCompPage = this.competitor_meta.last_page
            this.currCompPage = this.competitor_meta.current_page
            this.mixinResetLoading('competitor-search-' + type)
            clearLoading()
        },

        async localSearchLazyLoadComp(val, page) {
            let params = {
                count: 20,
                sort: 'order',
                'fields[competitors]': 'id,name',
                'filter[source_id]': `${this.localProjectId}, ${this.tagSearchType}`,
                'filter[project_id]': this.localProjectId,
                'filter[name_filter]': val,
                page: page
            }
            await this.competitor_index(params)
            this.currentCompList.push(...this.competitor_list)
            this.currCompPage = this.competitor_meta.current_page
            // this.localFilterCompTags(this.competitor_list)
            this.lazyLoadCompState = false
            return
        },

        async locallazyLoadCompetitors(typeId, {page, searchVal}) {
            this.lazyLoadCompState = true
            if (searchVal.length) return await this.localSearchLazyLoadComp(searchVal, page)

            let params = {
                count: 10,
                sort: 'order',
                'fields[competitors]': 'id,name',
                'filter[source_id]': `${this.localProjectId}, ${typeId}`,
                'filter[project_id]': this.localProjectId,
                page: page
            }
            await this.competitor_index(params)
            this.currentCompList.push(...this.competitor_list)
            this.currCompPage = this.competitor_meta.current_page
            this.lazyLoadCompState = false
        },

        localFilterCompTags(list) {
            const filteredList = list.filter(c => this.currentCompList.findIndex(e => e.id === c.id) === -1)
            this.currentCompList.push(...filteredList)
        },

        async localOrgPreferenceCompetitorIndex (preference, typeId) {
            let params = {
                count: 10,
                sort: 'order',
                'fields[competitors]': 'id,name',
                'filter[source_id]': `${this.localProjectId}, ${typeId}`,
            }
            if (preference) params['filter[project_id]'] = this.localProjectId
            await this.competitor_index(params)
        },

        async localSearchCompetitors (props, typeId) {
            const { value, clearLoading } = props || {}
            let params = {
                count: 20,
                sort: 'order',
                'filter[name_filter]': value,
                'fields[competitors]': 'id,name',
                'filter[source_id]': `${this.localProjectId}, ${typeId}`
            }
            if (this.local_org_preference_link_tag) params['filter[project_id]'] = this.localProjectId
            await this.competitor_index(params)
            this.currentCompList = [..._.cloneDeep(this.competitor_list)]
            this.currCompPage = this.competitor_meta.current_page
            this.totalCompPage = this.competitor_meta.last_page
            clearLoading()
        },

        async localCreateCompTag (createProps) {
            const { tag, mode, resolveError, resolveSuccess, searchVal, category = null } = createProps || {}

            if (mode === 'select') return this.localAttachCompsToProject({ tag, resolveSuccess, searchVal })

            const hasPermission = this.$can('projects.update-only') && this.$can('competitors.store')
            if (!hasPermission) return this.mixinResetLoading('competitor-create')

            const compItem = this.$appendId({ name: tag.value, category_id: category.id })
            await this.competitor_store(compItem)
            if (!this.$status(this.competitor_response)) {
                return resolveError(this.$response(this.competitor_response, 'name'))
            }

            resolveSuccess()
            await this.localAddTagToProject(compItem)
            await this.localAttachTag(compItem, 'new')
        },

        async localAttachCompsToProject (params) {
            const { tag, resolveSuccess, searchVal, mode = null } = params || {}

            clearTimeout(this.compBulkAttachTimeout)
            await this.localAttachTag(tag, mode)
            const index = this.currentCompList.findIndex(i => i.id === tag.id)
            if (index !== -1) this.currentCompList.splice(index, 1)
            this.compRemoveInd.push(index)
            resolveSuccess()

            this.compBulkAttachTimeout = setTimeout(async () => {
                const ceilCount = Math.ceil(this.compBulkAttachIds.length / this.bulkCompChunkCount)
                for (let index = 0; index < ceilCount; index++) {
                    const chunkIds = this.compBulkAttachIds.splice(0, this.bulkCompChunkCount)
                    await this.association_bulk_store({
                        data: chunkIds,
                        type: 'project_competitor',
                        source_type: 'Project',
                        source_id: this.localProjectId,
                        target_type: 'Competitor',
                        target_id: tag.id,
                        category_id: this.tagSearchType
                    })
                    this.isCompetitorUpdate = true
                }
                clearTimeout(this.compBulkAttachTimeout)
            }, 500)
            if (this.currentCompList.length === 9 && this.currCompPage < this.totalCompPage) await this.locallazyLoadCompetitors(this.tagSearchType, {page: this.currCompPage + 1, searchVal})
        },

        async localAddTagToProject (competitor) {
            const project = _.cloneDeep(this.project_item)
            const projComps = (project && project.competitors) ?? []

            this.$set(project, 'competitors', [...projComps, competitor])
            this.project_replace_item(project)
        },

        async localAttachTag (tag, mode = null) {
            const association = this.$appendId({
                type: 'project_competitor',
                source_type: 'Project',
                source_id: this.localProjectId,
                target_type: 'Competitor',
                target_id: tag.id,
                category_id: this.tagSearchType
            })

            if (mode === 'new') {
                await this.association_store(association)
                this.localAttachTagAssociation(tag.id, association)
                return true
            }
            this.compBulkAttachIds.push({ id: association.id, target_id: tag.id })
            this.localAttachTagToProject({ ...tag, category_id: this.tagSearchType }, association)
        },

        async localAttachTagAssociation (tag_id, association) {
            const index = this.project_item.competitors.findIndex(item => item.id === tag_id)
            if (index === -1) return

            Object.assign(this.project_item.competitors[index], { association })
        },

        localAttachTagToProject (tag, association = {}) {
            const projectComps = (this.project_item && this.project_item.competitors) ?? []
            this.$set(this.project_item, 'competitors', [...projectComps, { ...tag, association }])
            this.project_replace_item(this.project_item)
        },

        async localRemoveCompetitor (tag, typeId) {
            const index = this.project_item.competitors.findIndex(item => (item.id === tag.id && typeId === item.category_id))
            if (index === -1) return

            const comp = _.cloneDeep(this.project_item.competitors[index])
            this.project_competitor_remove({ id: comp.id, categoryId: typeId })
            this.association_destroy({ id: comp.association.id })
            this.isCompetitorUpdate = true
            this.$notify('success', 'Competitor removed successfully!')
        },

        async localDeleteCompetitor(evt, typeId) {
            const { ids: chunkIds } = evt || {}
            const item = chunkIds[0]
            await this.localRemoveCompetitor(item, typeId)
            const recentRemovedInd = this.compRemoveInd.pop()
            const removedComp = {id: item.id, name: item.name}
            if (recentRemovedInd) this.localInsertBack(removedComp, recentRemovedInd)
            else this.currentCompList.splice(0, 0, removedComp)
        },

        localAddRemovedCompToList(comp, i) {
            const len = this.currentCompList.length
            if (i < len) this.currentCompList.splice(i, 0, comp)
            else this.currentCompList.push(comp)
            // if (i === len - 1) this.currentCompList.splice(i - 1, 0, comp)
        },

        async localDestoryTag (evt, typeId) {
            const { ids: chunkIds } = evt || {}
            chunkIds.forEach(item => {
                const index = this.project_item.competitors.findIndex(i => (i.association.id === item.association.id && typeId === i.category_id))
                this.project_item.competitors.splice(index, 1)
                const recentRemovedInd = this.compRemoveInd.pop()
                const removedComp = {id: item.id, name: item.name}
                if (recentRemovedInd) this.localAddRemovedCompToList(removedComp, recentRemovedInd)
                else this.currentCompList.splice(0, 0, removedComp)
            })

            await this.association_bulk_destroy({
                ids: chunkIds.map(i => i.association.id),
                source_id: this.project_item.id,
                source_type: 'Project',
            })
            this.isCompetitorUpdate = true
            this.$notify('success', 'Competitors removed successfully!')
        },
        /* New Comps --- End */

        /* External view switch - Start */
        localSwitchToExternalView (type) {
            this.$router.push({ name: 'external-projects-overview', params: { id: this.localProjectId, view: type } })
        },
        /* External view switch - End */

        /* Preference - Start */
        localHasPreference (field, key = 'value') {
            const projectPreset = 'organization.projects.widgets'
            const result = this.preferencesList.find(i => i.key === `${projectPreset}.${field}`)
            if (!result) return false

            if (key === 'value') return result[key] === 'Yes'
            return result
        },

        localHasConfidenceScorePreference (key = 'value') {
            const projectPreset = 'organization.confidence_score'
            const result = this.preferencesList.find(i => i.key === `${projectPreset}`)

            if (!result) return false

            if (key === 'value') return result[key] === 'Yes'
            return result
        },

        localHasCheckCompetitorKey () {
            const result = this.preferencesList.find(i => i.key === 'organization.competitor_type_selection')
            if (result && result.value === 'Yes') return true
            return false
        },
        /* Preference - End */

        /* Extras */
        async localEditResult () {
            await this.automation_workflow_hook_clear()
            this.resultEditDialog = true
            this.localCheckPlanOnStageMove(this.localCompletedStage, 'edit')
        },

        async localProjectUpdate (fields) {
            await this.project_update({ id: this.localProjectId, ...fields })
        },

        localDateOutputFormat (date, type = 'range') {
            return diffFormat(date, type)
        },

        localUpdatedDateOutput (date) {
            return diffSimple(date, { type: 'includeYear' })
        },

        localKeyDownEvent (event) {
            if (event.code === 'Escape') {
                if (this.showPartnerInput) this.showPartnerInput = false
                this.showTerritorySection = false
            }
        },

        localGetTypeColor (health) {
            switch (health) {
                case 'green': return 'green'
                case 'red': return 'red'
                case 'yellow': return 'orange'
                default: return 'grey'
            }
        },

        localCheckMinDateRange(start_date) {
            if(!start_date) return null
            return moment(start_date).add(0, 'days').format("YYYY-MM-DD")
        },

        localCheckMaxDateRange(due_date) {
            if(!due_date) return null
            return moment(due_date).add(0, 'days').format("YYYY-MM-DD")
        },

        localHasPermission (type) {
            if (!Array.isArray(type)) return this.$can(type)

            let resultPermission = false
            for (let index = 0; index < type.length; index++) {
                const slug = type[index]
                resultPermission = this.$can(slug)
            }
            return resultPermission
        },

        localTogglePresentationMode () {
            if (!this.project_is_friendly_view) return this.dialogPresentationConfirm = true

            this.dialogPresentationConfirm = false
            return this.project_switch_friendly_view(false)
        },

        async localSwitchToFriendlyView () {
            await this.project_switch_friendly_view(true)
            this.dialogPresentationConfirm = false

            if (this.toggleDescription) {
                this.toggleDescription = false
                setTimeout(() => this.toggleDescription = true, 0)
            }

            if (this.toggleNextStep) {
                this.toggleNextStep = false
                setTimeout(() => this.toggleNextStep = true, 0)
            }
        },

        localFilteredComps (compTypeId) {
            return this.currentCompList.filter(item => {
                const projectComp = this.project_item.competitors.find(comp => comp.id === item.id)
                if (!projectComp) return item
                return projectComp.category_id !== compTypeId ? item : null
            })
        },

        localHasConfidenceScoreWigetPermission () {
            if (!this.localIsFeatureFlagged()) return false
            return this.localHasPreference('confidence_score') && this.localHasConfidenceScorePreference() && this.$presentationCheck({ slug: 'confidence_score' }) && this.$can('poc_confidence_score.index')
        },

        localHasConfidenceScorePermission () {
            if (!this.localIsFeatureFlagged()) return false
            return this.localHasConfidenceScorePreference() && this.$can('poc_confidence_score.index')
        },

        localIsFeatureFlagged () {
            const { flag_confidence_score } = this.user_self && this.user_self.organization
            return flag_confidence_score === 1
        },

        localNavigateToConfidenceScore () {
            this.$router.push({ name: 'projects-confidence-score'})
        },

        local_get_color_code (color) {
            return this.$color(color ?? 'teal-dark', 'color_bg')
        },

        ...mapActions('ProjectView', {
            project_show: 'show',
            project_update: 'update',
            project_switch_friendly_view: 'switch_friendly_view',
            project_update_description: 'update_description',
            project_update_next_step: 'update_next_step',
            project_update_item: 'update_item',
            project_replace_item: 'replace_item',
            project_workspace_update: 'workspace_update',
            project_competitor_remove: 'competitor_remove',
            project_territory_remove: 'territory_remove',
            project_master_template_import: 'master_template_import',
            project_clear_item: 'clear_item',
            project_clear_response: 'clear_response',
        }),

        ...mapActions('Partner', {
            partner_index: 'index',
            partner_store: 'store',
            partner_clear: 'clear',
        }),

        ...mapActions('Stage', {
            stage_index: 'index',
        }),

        ...mapActions('Territory', {
            territory_index: 'index',
            territory_store: 'store',
            territory_clear: 'clear',
        }),

        ...mapActions('Competitor', {
            competitor_index: 'index',
            competitor_store: 'store',
            competitor_show: 'show',
            competitor_clear: 'clear',
        }),

        ...mapActions('Workspace', {
            workspace_board: 'board',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
        }),

        ...mapActions('AutomationValidation', {
            automation_validation_result_status: 'result_status',
        }),

        ...mapActions('MetaResultReason', {
            meta_result_reason_index: 'index',
            meta_result_reason_clear: 'clear',
            meta_result_reason_clear_item: 'clear_item',
        }),

        ...mapActions('Association', {
            association_store: 'store',
            association_bulk_store: 'bulk_store',
            association_destroy: 'destroy',
            association_bulk_destroy: 'bulk_destroy',
        }),

        ...mapActions('StatusUpdate', {
            status_update_index: 'index',
        }),

        ...mapActions('Preference', {
            preference_index: 'index',
        }),

        ...mapActions('ConfidenceScoreValues', {
            confidence_update_form_mode: 'update_form_mode',
        }),
    },
}
</script>

<style lang="scss" scoped>
.c-partner-text {
    &__clear { display: none; }
    &:hover {
        .c-partner-text__clear { display: inline-flex !important; }
    }
}

.c-stage-grid {
    background: #3949AB;
    height: 40px;
    margin: 0 12px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        border: 20px solid #3949AB;
        border-left: 20px solid #0000;
        height: 100%;
        background: #0000;
        border-right: 0;
        left: -20px;
    }
    &::after {
        content: '';
        position: absolute;
        border: 20px solid #0000;
        border-left: 20px solid #3949AB;
        height: 100%;
        background: #0000;
        right: -40px;
    }
    &:first-child {
        border-radius: 8px 0px 0px 8px !important;
        margin-left: 0px;
        &::before {
            content: none;
        }
    }
    &:last-child {
        border-radius: 0px 8px 8px 0px !important;
        margin-right: 0px;
        &::after {
            content: none;
        }
    }
    &--light {
        background-color: #E8EAF7 !important;
        &::before {
            border: 20px solid #E8EAF7;
            border-left: 20px solid transparent !important;
        }
        &::after {
            border: 20px solid transparent !important;
            border-left: 20px solid #E8EAF7 !important;
        }
    }
}

.c-confidence-score-btn {
    border: 1px solid  #1976D2 !important;
    border-radius: 4px !important;
}

.c-container__half {
    width: calc(50% - 12px);
}

.c-confidence-score__icon {
    width: 130px;
    height: 130px;
    border-radius: 100%;
}
</style>

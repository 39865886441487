<template>
	<a-main>
        <!-- Breadcrumbs -->
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>

        <!-- Template List -->
        <a-container grid-list-xl container--fluid class="pa-6 pt-2 px-0 mb-10">
            <g-page-header
                title="Document Generator"
                subtitle="Create and manage document templates."
                icon="source"
                :breadcrumb-items="databreadcrumbItems"
                :loading="loading"
            >
                <template v-slot:action>
                    <a-btn color="primary" v-if="$can('document_generator.store')" class="elevation-0 mx-0 u-rounded-corners" dark @click="methodTemplateCreate">
                        <a-icon class="mr-2" color="white" size="18">add</a-icon>
                        Create New Template
                    </a-btn>
                </template>
            </g-page-header>
            <a-divider class="grey lighten-2"></a-divider>

            <v-slide-y-transition group leave-absolute hide-on-leave>
                <!-- No Data Found Message -->
                <a-container key="loader" v-if="localLoading">
                    <a-layout>
                        <a-flex xs12>
                            <h2 class="md-body-2 grey--text text--darken-2 text-center mt-4 font-italic">Fetching Document Templates..</h2>
                        </a-flex>
                    </a-layout>
                </a-container>
                <template v-else>
                    <a-container key="noData" v-if="dataTemplateList && !dataTemplateList.length">
                        <a-layout>
                            <a-flex xs12 class="text-center" v-if="dataTemplateList && !dataTemplateList.length">
                                <a-sheet width="200" height="200" class="u-flex align-center justify-center mx-auto" color="#f7f7f7" style="border-radius: 1000%">
                                    <a-img :src="require('../../assets/images/no-report-found.svg')" max-width="130" contain></a-img>
                                </a-sheet>
                                <h2 class="headline grey--text text--darken-2 text-center mt-4 font-italic">No Document Templates Found</h2>
                                <a-btn small text @click="methodTemplateCreate" v-if="$can('document_generator.store')" class="ma-0 c-radius blue darken-2 mt-3 white--text">
                                    <a-icon class="mr-2 white--text">add</a-icon>
                                    Create New Template
                                </a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </template>

                <!-- Template List -->
                <template v-if="!localLoading && dataTemplateList && dataTemplateList.length">
                    <a-layout key="draggable" class="mt-5">
                        <a-flex xs12>
                            <draggable tag="div" v-bind="dragOptions" v-model="dataTemplateList" @start="drag = true" @end="drag = false" class="list-group">
                                <v-slide-y-transition group leave-absolute hide-on-leave tag="div">
                                    <a-card class="pa-2 u-elevation-custom-1 u-rounded-corners mb-2 list-group-item" v-for="template in dataTemplateList" :key="template.id">
                                        <a-card-text class="pa-0 elevation-0 u-relative">
                                            <a-container container--fluid grid-list-xl pa-3>
                                                <a-layout align-center>
                                                    <a-flex shrink class="pr-0 py-0 drag-class js-drag-handle" v-if="$can('document_generator.update')">
                                                        <a-icon class="grey--text text--darken-1">drag_indicator</a-icon>
                                                    </a-flex>
                                                    <a-flex shrink class="u-wfull u-cursor-pointer" @click="methodViewTemplate(template.id)">
                                                        <h2 class="md-heading-6 font-weight-medium" style="color: #505050">{{ template.name }}</h2>
                                                    </a-flex>
                                                    <a-spacer></a-spacer>
                                                    <a-flex shrink py-1 v-if="$can('document_generator.update')">
                                                        <a-btn icon text class="ma-0 pa-1" v-if="template.is_active" v-ripple @click="actionToggleActive({ id: template.id, state: 0 })">
                                                            <svg width="24px" height="24px" viewBox="0 0 16 16" class="bi bi-toggle2-on green--text text--darken-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7 5H3a3 3 0 0 0 0 6h4a4.995 4.995 0 0 1-.584-1H3a2 2 0 1 1 0-4h3.416c.156-.357.352-.692.584-1z"/>
                                                                <path d="M16 8A5 5 0 1 1 6 8a5 5 0 0 1 10 0z"/>
                                                            </svg>
                                                        </a-btn>
                                                        <a-btn icon text class="ma-0 pa-1" v-else v-ripple @click="actionToggleActive({ id: template.id, state: 1 })">
                                                            <svg width="24px" height="24px" viewBox="0 0 16 16" class="bi bi-toggle2-off grey--text text--darken-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9 11c.628-.836 1-1.874 1-3a4.978 4.978 0 0 0-1-3h4a3 3 0 1 1 0 6H9z"/>
                                                                <path fill-rule="evenodd" d="M5 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 1A5 5 0 1 0 5 3a5 5 0 0 0 0 10z"/>
                                                            </svg>
                                                        </a-btn>
                                                    </a-flex>
                                                    <a-flex shrink py-1 class="text-xs-right" v-if="$can('document_generator.store')">
                                                        <a-btn icon text color="grey darken-1" class="ma-0" @click="methodCloneTemplate(template)">
                                                            <a-icon size="18" color="grey darken-2">content_copy</a-icon>
                                                        </a-btn>
                                                    </a-flex>
                                                    <a-flex shrink py-1 class="text-xs-right" v-if="$can('document_generator.destroy')">
                                                        <a-menu left offset-y transition="slide-y-transition" max-width="300">
                                                            <template v-slot:activator="{ on }">
                                                                <a-btn text icon v-on="on" color="grey darken-1" class="ma-0">
                                                                    <svg width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-trash  red--text text--darken-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                    </svg>
                                                                </a-btn>
                                                            </template>
                                                            <a-card class="u-rounded-corners u-elevation-custom-1 pa-3" max-width="300">
                                                                <a-card-text class="pa-1">
                                                                    <h2 class="body-2 font-weight-normal mb-1 grey--text text--darken-1"><strong>Are you sure to delete the template?</strong> <br> This action cannot be undone.</h2>
                                                                    <div class="u-flex align-center justify-space-between mt-2">
                                                                        <a-btn text small class="red darken-1 ma-0 white--text u-rounded-corners" @click="actionTemplateDelete({ id: template.id })">
                                                                            <!-- <a-icon size="16" class="mr-1" color="white">delete</a-icon> -->
                                                                            <svg width="16px" height="16px" viewBox="0 0 16 16" class="bi bi-trash white--text mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                            </svg>
                                                                            Delete
                                                                        </a-btn>
                                                                    </div>
                                                                </a-card-text>
                                                            </a-card>
                                                        </a-menu>
                                                    </a-flex>
                                                </a-layout>
                                            </a-container>
                                        </a-card-text>
                                    </a-card>
                                </v-slide-y-transition>
                            </draggable>
                        </a-flex>
                    </a-layout>
                </template>
            </v-slide-y-transition>
        </a-container>

        <!-- Create Template Dialog -->
        <a-dialog v-model="dataCreateTemplateDialog" max-width="500" persistent>
            <a-card class="c-radius grey lighten-3">
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="methodTemplateSave()">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap>
                                <a-flex xs12>
                                    <div class="u-relative">
                                        <a-sheet class="grey lighten-4 u-flex align-center justify-center mx-auto" width="250" height="250" style="border-radius: 50%;">
                                            <a-img :src="require('../../assets/images/report-template-list.svg')" max-width="200" max-height="200" contain></a-img>
                                        </a-sheet>
                                        <a-btn icon text small style="position: absolute; top: -12px; right: -12px" @click="dataCreateTemplateDialog = !dataCreateTemplateDialog">
                                            <a-icon size="18" color="grey lighten-1">cancel</a-icon>
                                        </a-btn>
                                    </div>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="title d-block mb-2 grey--text text--darken-2 text-center u-wfull mt-0 mb-3 font-weight-medium">Create New Template</label>
                                    <a-sheet class="c-radius u-elevation-custom-1 mb-3" style="padding: 1px;">
                                        <a-text-field
                                            v-model="dataCreateTemplate.name"
                                            placeholder="Eg: Progress Report"
                                            ref="refCreateTemplateForm"
                                            background-color="white"
                                            solo flat hide-details
                                        >
                                        </a-text-field>
                                    </a-sheet>
                                    <a-btn type="submit" color="primary" :loading="loading" class="ma-0 u-wfull py-3 u-elevation-custom-1 c-radius body-2 font-weight-normal text-capitalize px-3">Create Template</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!-- Clone Template Dialog -->
        <a-dialog v-model="dataCloneTemplateDialog" max-width="500" persistent>
            <a-card class="c-radius grey lighten-3">
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="methodTemplateSave('clone')">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap>
                                <a-flex xs12>
                                    <div class="u-relative">
                                        <a-sheet class="grey lighten-4 u-flex align-center justify-center mx-auto" width="150" height="150" style="border-radius: 50%;">
                                            <a-icon size="64" color="grey lighten-1">content_copy</a-icon>
                                        </a-sheet>
                                        <a-btn icon text small style="position: absolute; top: -12px; right: -12px" @click="dataCloneTemplateDialog = !dataCloneTemplateDialog">
                                            <a-icon size="18" color="grey lighten-1">cancel</a-icon>
                                        </a-btn>
                                    </div>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="title d-block mb-2 grey--text text--darken-2 text-center u-wfull mt-0 mb-3 font-weight-medium">Clone Existing Template</label>
                                    <a-sheet class="c-radius u-elevation-custom-1 mb-3" style="padding: 1px;">
                                        <a-text-field
                                            v-model="dataCreateTemplate.name"
                                            placeholder="Eg: Progress Report"
                                            ref="refCloneTemplateForm"
                                            background-color="white"
                                            solo flat hide-details
                                        >
                                        </a-text-field>
                                    </a-sheet>
                                    <a-btn type="submit" :loading="loading" color="primary" class="ma-0 u-wfull py-3 u-elevation-custom-1 c-radius body-2 font-weight-normal text-capitalize px-3">Clone Template</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-snackbar color="red darken-2" auto-height v-model="snackbarStatus" :timeout="snackbarTimeout" bottom center>
            <span class="body-2 white--text">{{ snackbarText }}</span>
            <a-btn color="grey lighten-3" text @click="snackbarStatus = false">Close</a-btn>
        </a-snackbar>
	</a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import draggable from 'vuedraggable'

export default {
    components: { draggable },

    data () {
        return {
            dataCreateTemplateDialog: false,
            dataCloneTemplateDialog: false,
            dataCreateTemplate: { name: null },
            dataPrevTemplateId: '',
            localLoading: true,
            snackbarStatus: false,
            snackbarTimeout: 4000,
            snackbarText: '',
            drag: false
        }
    },

    mounted () {
        this.methodIndex()
    },

    watch: {
        dataCreateTemplateDialog (val) {
            if (val) {
                setTimeout(() => this.$refs.refCreateTemplateForm.ref.focus(), 500)
            }
        },

        dataCloneTemplateDialog (val) {
            if (val) {
                setTimeout(() => this.$refs.refCloneTemplateForm.ref.focus(), 500)
            }
        },
    },

    computed: {
        databreadcrumbItems () {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Report Templates', to: '/settings/document-generator', exact: true})
            return breadcrumbs
        },

        dataTemplateList: {
            get() {
                return this.$store.getters['ReportTemplate/getTemplateList']
            },
            set(list) {
                this.actionTemplateReorder(list)
            }
        },

        dragOptions () {
            return {
                animation: 200,
                group: 'template-list',
                disabled: false,
                ghostClass: 'ghost',
                handle: '.js-drag-handle'
            }
        },

        ...mapGetters('ReportTemplate', [
            'getTemplateList',
            'getTemplateItem',
            'getFindTemplateItem',
            'getTemplateResponse'
        ]),

        ...mapGetters('User', {
            user_self: 'self',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async methodIndex () {
            if (!this.$can('document_generator.show')) {
                return this.$router.push({ name: 'dashboard' })
            }

            await this.actionTemplateClear()
            await this.actionTemplateIndex()
            this.localLoading = false
        },

        async methodTemplateCreate () {
            this.actionTemplateClearItem()
            this.dataCreateTemplate.name = null
            this.dataCreateTemplateDialog = true
        },

        async methodTemplateSave (type) {
            if (!this.dataCreateTemplate.name || this.dataCreateTemplate.name === '') return
            if (type === 'clone') {
                await this.actionStoreTemplateCloned({
                    parent_id: this.dataPrevTemplateId,
                    name: this.dataCreateTemplate.name
                })
            } else await this.actionTemplateStore({ name: this.dataCreateTemplate.name, order: -1 })

            if (this.getTemplateResponse.status === 'success') {
                await this.methodViewTemplate(this.getTemplateItem.id)
                // setTimeout(async () => , 500)
                this.dataCreateTemplateDialog = false
                this.dataCloneTemplateDialog = false
            } else {
                this.snackbarStatus = true
                this.snackbarText = this.getTemplateResponse.text
            }
        },

        methodCloneTemplate (template) {
            this.dataCreateTemplate.name = template.name
            this.dataPrevTemplateId = template.id
            setTimeout(() => this.$refs.refCloneTemplateForm.ref.$refs.input.select(), 100)
            this.dataCloneTemplateDialog = true
        },
        methodGetCalendarTime (date) {
            return moment.utc(date).local().calendar()
        },

        async methodViewTemplate (id) {
            this.$router.push({ name: 'settings-document-generator-view', params: { template_id: id } })
        },

        ...mapActions('ReportTemplate', [
            'actionTemplateIndex',
            'actionTemplateStore',
            'actionTemplateUpdate',
            'actionTemplateDelete',
            'actionStoreTemplateCloned',
            'actionToggleActive',
            'actionTemplateReorder',
            'actionTemplateClear',
            'actionTemplateClearItem'
        ])
    },
}
</script>

<style lang="scss" scoped>
    .router-link-exact-active.router-link-active {
        color: grey;
        pointer-events: none
    }

    .flip-list-move {
        transition: transform 0.5s;
    }

    .no-move {
        transition: transform 0s;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .list-group-item .drag-class {
        cursor: move;
    }
</style>

<template>
    <a-layout align-start>
        <a-flex>
            <a-tabs class="mb-0" color="neutral" slider-color="primary">
                <a-tab ripple class="mx-2" :to="{name: 'apps'}" exact>
                    <span class="md-body-2">Available Apps</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'apps-installed'}" exact>
                    <span class="md-body-2">Installed Apps</span>
                </a-tab>
            </a-tabs>
        </a-flex>
    </a-layout>
</template>

<script>
export default {

}
</script>

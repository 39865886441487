<template>
    <v-stepper-content v-on="$listeners" v-bind="$attrs">
        <slot></slot>
    </v-stepper-content>
</template>

<script>
import { VStepperContent } from 'vuetify/lib/components/VStepper'

export default {
    inheritAttrs: false,
    components: { VStepperContent },

    data () {
        return {
            ref: null
        }
    },

    mounted () {
        this.ref = this.$el
    }
}
</script>

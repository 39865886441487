import Vue from 'vue'

const LoaderComponents = [
    'LoaderComments',
    'LoaderTags',
    'LoaderTimeEst',
    'LoaderAutosave',
    'LoaderUsecaseView',
    'LoaderTemplate'
]

LoaderComponents.forEach(comp => {
    Vue.component(comp, (resolve) => {
        resolve(require(`@/components/Loader/${comp}.vue`).default)
    })
})

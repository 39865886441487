<template>
    <div class="u-absolute u-wfull u-hfull white u-flex-center" v-if="is_loading || has_error || !has_stats" style="top: 0px; left: 0px; z-index: 1;">
        <slot name="loader" v-if="is_loading"></slot>
        <div class="u-flex-center u-hfull" v-if="has_error && !is_loading">
            <div class="text-center mb-4">
                <a-sheet class="orange darken-1 u-rounded-corners-full d-inline-flex align-center justify-center" width="48" height="48">
                    <a-icon color="white" size="28">error</a-icon>
                </a-sheet>
                <a-sheet class="md-caption body--text text--lighten-1 mt-3 mb-4">An error has been encountered. Kindly reconfigure the Tile.</a-sheet>
                <span class="d-inline-flex align-center justify-center" @click="$emit('configure', true)">
                    <span class="md-caption text-uppercase orange--text text--darken-1 mr-1 font-weight-bold">Reconfigure Tile</span>
                    <a-icon size="16" color="orange darken-1">east</a-icon>
                </span>
            </div>
        </div>
        <div class="u-flex-center u-hfull" v-if="!has_stats && !is_loading">
            <div class="text-center mb-4">
                <a-sheet class="orange darken-1 u-rounded-corners-full d-inline-flex align-center justify-center" width="48" height="48">
                    <a-icon color="white" size="28">error</a-icon>
                </a-sheet>
                <a-sheet class="md-caption body--text text--lighten-1 mt-3 mb-4">We are unable to fetch data for the applied configuration.</a-sheet>
                <span class="d-inline-flex align-center justify-center" @click="$emit('configure', true)">
                    <span class="md-caption text-uppercase orange--text text--darken-1 mr-1 font-weight-bold">Edit Configuration</span>
                    <a-icon size="16" color="orange darken-1">east</a-icon>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        has_error: {
            type: Boolean,
            default: false
        },
        is_loading: {
            type: Boolean,
            default: false
        },
        has_stats: {
            type: Boolean | Number,
            default: true
        },
    }
}
</script>

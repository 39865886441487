<template>
	<a-main class="px-3 mb-16 mt-5">
        <h2 class="md-heading-5 font-weight-semibold">Accounts</h2>
        <p class="md-body-2 mb-0 grey--text text--darken-2">Gong account connected with Success.</p>

        <a-sheet class="u-shadow u-rounded-corners px-5 py-3 mt-6 u-flex-center-y">
            <div class="u-flex-center-y" style="column-gap: 12px;">
                <img src="../../assets/images/apps/gong/logo.svg" alt="Gong" style="display: inline-block; width: 40px; height: 40px;">
                <div>
                    <h3 class="md-body-1 font-weight-medium grey--text text--darken-3">Gong Account</h3>
                    <p class="mb-0 md-body-2 grey--text text--darken-3">Connected on {{ localGetConnectedDate }}</p>
                </div>
            </div>
            <a-spacer></a-spacer>
            <a-menu v-if="$can('applets.destroy')" bottom offset-y left content-class="white" max-width="300">
                <template v-slot:activator="{ on }">
                    <a-btn v-on="on" color="red darken-1" class="elevation-0 pa-3" dark small>
                        <a-icon class="mr-2" size="16" left>warning</a-icon> Revoke Access
                    </a-btn>
                </template>
                <a-sheet class="pa-3 u-rounded-corners">
                    <h3 class="md-subtitle-2 font-weight-medium grey--text text--darken-3 mb-2">Are you sure to revoke the access of Gong Integration?</h3>
                    <a-btn @click="localRevokeAccess()" color="red darken-1" class="pa-1 px-2" dark small depressed>
                        <span class="text-uppercase">Yes, Confirm</span>
                    </a-btn>
                </a-sheet>
            </a-menu>
        </a-sheet>
    </a-main>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { diffSimple } from '@/helpers/helper-date-filter';

export default {
    computed: {
        localGetConnectedDate () {
            return this.meeting_info_list && this.meeting_info_list.gong_connected_at ?
                diffSimple(
                    this.meeting_info_list.gong_connected_at,
                    { format: 'MMM D, YYYY' }
                ) : null
        },

        ...mapState('Meeting', {
            meeting_info_list: 'info',
        }),
    },

    methods: {
        localRevokeAccess () {
            this.gong_revoke_access()
            this.$router.replace({ name: 'appstore' })
        },

        ...mapActions('Gong', {
            gong_revoke_access: 'revoke_access'
        })
    }
}
</script>

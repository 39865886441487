import { mapActions } from 'vuex'

export default {
    data () {
        return {
            mixinSearchTimeout: null,
            mixinSearchLoading: false,
            mixinLoadingBag: [],
        }
    },

    methods: {
        async mixinSearchUsers (val, args = null, loadingType = null) {
            this.mixinSearchLoading = true
            if (loadingType) this.mixinSetLoadingType(loadingType)
            clearTimeout(this.mixinSearchTimeout)

            this.mixinSearchTimeout = setTimeout(async () => {
                await this.mixinFetchUsers(val, args, loadingType)
                clearTimeout(this.mixinSearchTimeout)
            }, 500)
        },

        async mixinFetchUsers (searchValue = null, args = null, loadingType = null) {
            const params = args ?? { 'include': 'role', 'sort': 'name', count: 20, page: 1 }

            if (searchValue) params['filter[name]'] = searchValue
            await this.user_index(params)
            this.mixinSearchLoading = false
            this.mixinResetLoadingType(loadingType)
        },

        mixinSetLoadingType (type) {
            const index = this.mixinLoadingBag.indexOf(type)
            if (index === -1) this.mixinLoadingBag.push(type)
        },

        mixinResetLoadingType (type) {
            const index = this.mixinLoadingBag.indexOf(type)
            if (index !== -1) this.mixinLoadingBag.splice(index, 1)
        },

        mixinIsTypeLoading (type) {
            return this.mixinLoadingBag.includes(type)
        },

        ...mapActions('User', {
            user_index: 'index'
        })
    }
}

<template>
    <v-list-item-title v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </v-list-item-title>
</template>

<script>
import { VListItemTitle } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VListItemTitle }
}
</script>

<template>
    <a-layout wrap align-center class="mb-3 mt-2">
        <a-flex shrink>
            <slot name="icon">
                <a-icon size="48" color="neutral darken-2" :outlined="iconOutlined">{{ icon }}</a-icon>
            </slot>
        </a-flex>
        <a-flex>
            <div class="u-flex align-center">
                <slot name="title">
                    <h2 class="md-heading-5 primary--text">{{ title }}</h2>
                </slot>
                <slot name="version"></slot>
            </div>
            <p class="md-subtitle-2 grey--text text--darken-1 mb-0">{{ subtitle }}</p>
        </a-flex>
        <a-flex shrink pr-2>
            <slot name="toggle"></slot>
        </a-flex>
        <a-flex shrink pr-3>
            <slot name="action"></slot>
        </a-flex>
    </a-layout>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Loading...',
            required: true
        },
        subtitle: {
            type: String,
            default: '',
            required: false
        },
        hideHeader: {
            type: Boolean,
            default: false,
            required: false
        },
        breadcrumbItems: {
            type: Array,
        },
        icon: {
            type: String,
            default: '',
        },
        iconOutlined: {
            type: Boolean,
            default: false,
        },
    }
}
</script>

<style>
    .v-breadcrumbs__item {
        color: #fff;
    }
</style>

export default {
    number: [
        {
            label: 'equals',
            key: 'equals',
            is_value_alt: false
        },
        {
            label: 'not equals',
            key: 'not-equals',
            is_value_alt: false
        },
        {
            label: 'between',
            key: 'between',
            is_value_alt: true
        },
        {
            label: 'greater than',
            key: 'greater-than',
            is_value_alt: false
        },
        {
            label: 'greater than or equal to',
            key: 'greater-than-or-equal',
            is_value_alt: false
        },
        {
            label: 'lesser than',
            key: 'lesser-than',
            is_value_alt: false
        },
        {
            label: 'lesser than or equal to',
            key: 'lesser-than-or-equal',
            is_value_alt: false
        },
        {
            label: 'is empty',
            key: 'empty',
            is_value_alt: false
        },
        {
            label: 'is not empty',
            key: 'not-empty',
            is_value_alt: false
        },
    ],
    url: [
        {
            label: 'is',
            key: 'equals',
            is_value_al: false
        },
        {
            label: 'is not',
            key: 'not-equals',
            is_value_alt: false
        },
        {
            label: 'contains',
            key: 'contains',
            is_value_alt: false
        },
        {
            label: 'does not contain',
            key: 'not-contains',
            is_value_alt: false
        },
        {
            label: 'starts with',
            key: 'starts-with',
            is_value_alt: false
        },
        {
            label: 'ends with',
            key: 'ends-with',
            is_value_alt: false
        },
        {
            label: 'is empty',
            key: 'empty',
            is_value_alt: false
        },
        {
            label: 'is not empty',
            key: 'not-empty',
            is_value_alt: false
        },
    ],
    string: [
        {
            label: 'is',
            key: 'equals',
            is_value_al: false
        },
        {
            label: 'is not',
            key: 'not-equals',
            is_value_alt: false
        },
        {
            label: 'contains',
            key: 'contains',
            is_value_alt: false
        },
        {
            label: 'does not contain',
            key: 'not-contains',
            is_value_alt: false
        },
        {
            label: 'starts with',
            key: 'starts-with',
            is_value_alt: false
        },
        {
            label: 'ends with',
            key: 'ends-with',
            is_value_alt: false
        },
        {
            label: 'is empty',
            key: 'empty',
            is_value_alt: false
        },
        {
            label: 'is not empty',
            key: 'not-empty',
            is_value_alt: false
        },
    ],
    dropdown: [
        {
            label: 'is',
            key: 'equals',
            is_value_al: false
        },
        {
            label: 'is not',
            key: 'not-equals',
            is_value_alt: false
        },
        {
            label: 'is empty',
            key: 'empty',
            is_value_alt: false
        },
        {
            label: 'is not empty',
            key: 'not-empty',
            is_value_alt: false
        }
    ],
    date: [
        {
            label: 'is',
            key: 'equals',
            is_value_alt: false
        },
        {
            label: 'is not',
            key: 'not-equals',
            is_value_alt: false
        },
        {
            label: 'is within',
            key: 'between',
            is_value_alt: true
        },
        {
            label: 'is after',
            key: 'greater-than',
            is_value_alt: false
        },
        {
            label: 'is on or after',
            key: 'greater-than-or-equal',
            is_value_alt: false
        },
        {
            label: 'is before',
            key: 'lesser-than',
            is_value_alt: false
        },
        {
            label: 'is on or before',
            key: 'lesser-than-or-equal',
            is_value_alt: false
        },
        {
            label: 'is empty',
            key: 'empty',
            is_value_alt: false
        },
        {
            label: 'is not empty',
            key: 'not-empty',
            is_value_alt: false
        }
    ]
}

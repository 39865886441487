<template>
    <div>
        <h4 class="md-subtitle-1 indigo--text text--darken-2 mb-1 font-weight-medium">
            <slot name="title">To Subscribe</slot>
        </h4>
        <span class="md-body-2 grey--text text--darken-1 mb-3 d-inline-block">
            <slot name="content">Open a Slack channel and paste the command,</slot>
        </span>
        <div class="grey lighten-4 u-rounded-corners-lg u-flex-center-y u-cursor-pointer" v-ripple="{ class: 'grey--text text--lighten-1' }" v-clipboard:copy="command" v-clipboard:success="evt => $emit('copySuccess', evt)" style="border: 1px solid #e9e9e9 !important;">
            <p class="md-body-2 grey--text text--darken-2 pa-3 mb-0 text-truncate">{{ command }}</p>
            <a-spacer></a-spacer>
            <a-divider vertical class="grey lighten-2"></a-divider>
            <div class="px-4 py-3 pt-2 u-cursor-pointer" v-clipboard:copy="command" v-clipboard:success="evt => $emit('copySuccess', evt)" v-ripple="{ class: 'grey--text text--lighten-1' }">
                <a-icon size="16" color="grey darken-1">content_copy</a-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        command: {
            type: String
        },
    }
}
</script>
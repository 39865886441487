<template>
    <a-card class="c-main-content">
        <div class="pa-7 py-6 u-relative">
            <a-icon class="u-absolute" style="top: 24px; right: 24px;" size="20" color="grey darken-1" @click="$emit('close')">clear</a-icon>
            <h3
                v-if="item.category"
                class="md-subtitle-2 text-uppercase font-weight-bold grey--text text--darken-3"
                :title="item.category ? item.category.value : ''"
                style="letter-spacing: 0.2px"
            >
                {{ item.category ? item.category.value : '' | truncateText(20) }}
            </h3>
            <h2 class="md-heading-4 font-weight-medium grey--text text--darken-3 mt-3" style="lineHeight: 32px;">
                {{ item.name | truncateText(50) }}
            </h2>
            <div class="u-flex-center-y mt-3" v-if="item.link || $can('competitors.update')">
                <a v-if="item.link" :href="item.link" target="_blank" rel="noopener noreferrer" class="d-inline-flex align-center u-cursor-pointer secondary--text text-uppercase md-body-2 font-weight-bold">
                    <a-icon color="secondary" size="20" class="mr-1">link</a-icon>
                    <span style="letter-spacing: 0.6px">View Link</span>
                </a>
                <a-icon v-if="item.link && $can('competitors.update')" color="grey lighten-2" size="8" class="mx-3">fiber_manual_record</a-icon>
                <div @click="localGoToCompetitors()" v-if="$can('competitors.update')" class="u-flex-center-y u-cursor-pointer grey--text text-uppercase md-body-2 font-weight-bold">
                    <a-icon color="grey" size="16" class="mr-1">edit</a-icon>
                    <span style="letter-spacing: 0.6px">Edit Competitor</span>
                </div>
            </div>
        </div>

        <a-divider></a-divider>

        <a-sheet class="pa-7 pt-6 pb-3">
            <span class="md-body-2 grey--text text--darken-3 text-uppercase font-weight-bold">Description</span>
            <template v-if="item.description_json">
                <a-sheet v-if="show_description" max-height="200" class="u-overflow-y c-tiny-scroll">
                    <g-editor-box
                        v-model="item.description_json"
                        customHeight="auto"
                        :modelIsOpen="isOpen"
                        :canUpdate="false"
                        textColor="grey--text text--darken-2"
                        readOnly dense hideFooter hideCloseBtn hideFocus
                    ></g-editor-box>
                </a-sheet>
            </template>
            <h4 v-else class="md-body-2 mt-2 grey--text text--darken-1 font-weight-medium font-italic">
                <template v-if="isLoading">
                    Loading...
                </template>
                <template v-else>
                    No description for this competitor.
                    <template v-if="$can('competitors.update')">
                        You can update it under <span class="u-underline u-cursor-pointer" @click="localGoToCompetitors()">Settings</span>.
                    </template>
                </template>
            </h4>
        </a-sheet>

        <a-divider></a-divider>

        <div class="pa-7 py-6 u-relative">
            <div>
                <div class="mb-3">
                    <span class="md-body-2 grey--text text--darken-3 text-uppercase font-weight-bold">Domain</span>
                    <a-progress-circular v-if="isLoading" size="16" width="2" color="orange darken-2" class="ml-2" indeterminate></a-progress-circular>
                </div>
                <p v-if="item.domain" class="grey--text text--darken-2 mb-0" style="white-space: pre-wrap">{{ item.domain }}</p>
                <h4 v-else class="md-body-2 mt-2 grey--text text--darken-1 font-weight-medium font-italic">
                    <template v-if="isLoading">
                        Loading...
                    </template>
                    <template v-else>
                        domain section is not updated for this competitor.
                        <template v-if="$can('competitors.update')">
                            You can update it under <span class="u-underline u-cursor-pointer" @click="localGoToCompetitors()">Settings</span>.
                        </template>
                    </template>
                </h4>
            </div>

            <div class="mt-6">
                <div class="mb-3">
                    <span class="md-body-2 grey--text text--darken-3 text-uppercase font-weight-bold">Strengths</span>
                    <a-progress-circular v-if="isLoading" size="16" width="2" color="orange darken-2" class="ml-2" indeterminate></a-progress-circular>
                </div>
                <p v-if="item.strengths" class="grey--text text--darken-2 mb-0" style="white-space: pre-wrap">{{ item.strengths }}</p>
                <h4 v-else class="md-body-2 mt-2 grey--text text--darken-1 font-weight-medium font-italic">
                    <template v-if="isLoading">
                        Loading...
                    </template>
                    <template v-else>
                        Strengths section is not updated for this competitor.
                        <template v-if="$can('competitors.update')">
                            You can update it under <span class="u-underline u-cursor-pointer" @click="localGoToCompetitors()">Settings</span>.
                        </template>
                    </template>
                </h4>
            </div>

            <div class="mt-6">
                <div class="mb-3">
                    <span class="md-body-2 grey--text text--darken-3 text-uppercase font-weight-bold">Weaknesses</span>
                    <a-progress-circular v-if="isLoading" size="16" width="2" color="orange darken-2" class="ml-2" indeterminate></a-progress-circular>
                </div>
                <p v-if="item.weaknesses" class="grey--text text--darken-2 mb-0" style="white-space: pre-wrap">{{ item.weaknesses }}</p>
                <h4 v-else class="md-body-2 mt-2 grey--text text--darken-1 font-weight-medium font-italic">
                    <template v-if="isLoading">
                        Loading...
                    </template>
                    <template v-else>
                        Weaknesses section is not updated for this competitor.
                        <template v-if="$can('competitors.update')">
                            You can update it under <span class="u-underline u-cursor-pointer" @click="localGoToCompetitors()">Settings</span>.
                        </template>
                    </template>
                </h4>
            </div>

            <div class="mt-6" v-if="item && item.org_preference_link_tag">
                <div class="mb-3">
                    <span class="md-body-2 grey--text text--darken-3 text-uppercase font-weight-bold">Product Tags</span>
                    <a-progress-circular v-if="isLoading" size="16" width="2" color="orange darken-2" class="ml-2" indeterminate></a-progress-circular>
                </div>
                <p v-if="item.products && item.products.length" class="grey--text text--darken-2 mb-0" style="white-space: pre-wrap">
                    <template v-for="tag in item.products">
                        <g-tags :tag="tag" type="tag" hideClearIcon textXl :key="tag.id"></g-tags>
                    </template>
                </p>
                <h4 v-else class="md-body-2 mt-2 grey--text text--darken-1 font-weight-medium font-italic">
                    <template v-if="isLoading">
                        Loading...
                    </template>
                    <template v-else>
                        Product tags section is not updated for this competitor.
                        <template v-if="$can('competitors.update')">
                            You can update it under <span class="u-underline u-cursor-pointer" @click="localGoToCompetitors()">Settings</span>.
                        </template>
                    </template>
                </h4>
            </div>
        </div>
    </a-card>
</template>

<script>
export default {
    name: 'ModalCompetitorView',

    props: {
        item: {
            type: Object,
        },
        isOpen: {
            type: Boolean
        },
        canUpdate: {
            type: Boolean
        },
        isLoading: {
            type: Boolean
        }
    },

    data () {
        return {
            currentInput: '',
            updateTimeout: null,
            show_description: false
        }
    },

    watch: {
        isOpen (val) {
            if (val) this.localIndex()
        }
    },

    mounted () {
        this.localIndex()
    },

    methods: {
        async localIndex () {
            this.show_description = false
            setTimeout(() => this.show_description = true, 500)
            this.localResetScroll()
        },

        localResetScroll () {
            const el = document.querySelector('.c-main-content')
            el.scrollTop = 0
        },

        localGoToCompetitors () {
            this.$router.push({ name: 'settings-competitors' })
        }
    }
}
</script>

<template>
    <a-main class="px-6">
        <template v-if="!local_avoided_routes">
            <g-page-header
                :title="$options.filters.truncateText(local_template_master_item ? local_template_master_item.name : 'Templates', 100)"
                subtitle="Manage use case, task, link, document, and note in master template."
                :breadcrumb-items="breadcrumb_items"
                icon="library_books"
            >
                <template v-slot:action>
                    <div class="white u-rounded-corners-lg u-elevation-custom-1 u-flex align-center">
                        <template v-if="$can('templates.update')">
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" @click="local_master_template_toggle_active(template_master_item.id, !template_master_item.is_active)" v-ripple style="border-radius: 0px 0px 0px 6px;" class="px-3 py-1 u-cursor-pointer">
                                        <a-icon size="14" :color="local_template_master_item.is_active ? 'green darken-1' : 'grey lighten-1'">fiber_manual_record</a-icon>
                                    </div>
                                </template>
                                <span>{{ local_template_master_item.is_active ? 'Enabled' : 'Disabled' }}</span>
                            </a-tooltip>
                            <a-divider vertical></a-divider>
                            <div @click="local_master_template_edit(template_master_item.id)" v-ripple class="px-3 py-1 u-cursor-pointer"  v-if="$can('templates.update')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-pencil grey--text text--darken-1" viewBox="0 0 16 16">
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                                </svg>
                            </div>
                        </template>
                        <a-divider vertical></a-divider>
                        <a-menu bottom left offset-y max-width="320" v-if="$can('templates.destroy')">
                            <template v-slot:activator="{ on }">
                                <div v-on="on" v-ripple class="u-cursor-pointer px-3 py-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-trash grey--text text--darken-1" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                    </svg>
                                </div>
                            </template>
                            <a-card>
                                <a-card-text class="pa-4">
                                    <h2 class="md-subtitle-2 mb-2">Are you sure to delete this master template?</h2>
                                    <a-btn class="ma-0 elevation-0" color="red" @click="local_master_template_destroy(template_master_item.id)" dark small>
                                        <a-icon size="16" left class="mr-2">delete</a-icon> Delete
                                    </a-btn>
                                </a-card-text>
                            </a-card>
                        </a-menu>
                    </div>
                </template>
            </g-page-header>
            <tab-master-template></tab-master-template>
        </template>
        <router-view></router-view>
        <a-dialog v-model="dialog_master_template_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>library_books</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (template_master_form_mode === 'add') ? 'Create New Master Template' : 'Edit Existing Master Template' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (template_master_form_mode === 'add') ? 'You can add new master template.' : 'You can edit existing master template.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name <span class="red--text text--darken-2">*</span></label>
                                    <a-text-field
                                        v-model="local_template_name"
                                        placeholder="Enter master template name"
                                        background-color="neutral"
                                        solo flat hide-details autofocus
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_master_response.server && template_master_response.server.errors && template_master_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">info</a-icon>
                                        {{ template_master_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the field.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_master_template_store()" :loading="loading" :disabled="loading">{{ template_master_form_mode === 'add' ? 'Create master template' : 'Update existing master template'}}</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click.prevent="local_close_dialog()" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { TabMasterTemplate } from '@/config/config-tab-components'

export default {
    components: { TabMasterTemplate },
    data() {
        return {
          dialog_master_template_form: false,
          local_remove_id: null,
          local_template_master_item: {},
          local_template_name: null,
        }
    },

    mounted () {
        this.local_index()
    },

    watch: {
        '$route.params.id': {
            handler (val) {
                this.local_index()
            },
            deep: true
        },
        'template_master_item.name'(new_value, old_value) {
            if (new_value && this.local_template_master_item.id == this.template_master_item.id) {
                this.clone_template_master_item()
            }
        },
        'template_master_item.is_active'(new_value, old_value) {
            if (new_value !== old_value && this.local_template_master_item.id === this.template_master_item.id) {
                this.clone_template_master_item()
            }
        }
    },

    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Types & Statuses', to: '/settings/tags', exact: true})
            return breadcrumbs
        },

        local_avoided_routes () {
            const blackList = ['master-templates-notes-view', 'master-templates-usecases-view']
            const { name } = this.$route
            return !!(blackList.includes(name))
        },

        local_current_template () {
            //const index = this.template_master_list.findIndex(item => item.id === this.$route.params.id)
            return  'Templates'
        },

        ...mapState('TemplateMaster', {
            template_master_list: 'list',
            template_master_form_mode: 'form_mode',
            template_master_item: 'item',
            template_master_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_index () {
            await this.template_master_select({ id : this.$route.params.id })
            if(!this.template_master_item) {
                await this.local_template_master_show(this.$route.params.id)
            }
            this.clone_template_master_item()
        },

        async clone_template_master_item() {
            this.local_template_master_item = {}
            this.local_template_master_item = this.template_master_item
        },

        async local_template_master_show(id) {
            await this.template_master_show({
                'fields[template_groups]': 'id,name,order,is_active,type',
                'id': id,
            })
            this.clone_template_master_item()
        },

        async local_master_template_toggle_active(id, is_active) {
            await this.template_master_active({ id: id, state: is_active ? 1 : 0 })
            await this.local_template_master_show(id)
        },

        async local_master_template_edit(id) {
            this.local_template_master_item = {}
            await this.template_master_select({id: id})
            this.local_template_name = this.template_master_item.name
            this.clone_template_master_item()
            this.dialog_master_template_form = true
        },

         async local_master_template_store() {
            this.template_master_item.type = 'master_template'
            this.template_master_item.order = -1
            this.template_master_item.name = this.local_template_name
            if(this.template_master_form_mode === 'add') await this.template_master_store(this.template_master_item)
            else await this.template_master_update(this.template_master_item)
            if (this.template_master_response.status === 'success') {
                await this.local_template_master_show(this.template_master_item.id)
                this.local_close_dialog()
            }
        },

        async local_close_dialog() {
            this.dialog_master_template_form = false
            this.local_template_name = null
        },

        async local_master_template_destroy(id) {
            await this.template_master_destroy({id:id})
            if (this.template_master_response.status === 'success') {
                this.$router.replace({ name: 'master-templates-system-list-usecases', params: { id: 'usecases' }})
            }
        },

        ...mapActions('TemplateMaster', {
            template_master_show: 'show',
            template_master_update: 'update',
            template_master_destroy: 'destroy',
            template_master_select: 'select',
            template_master_active: 'toggle_active',
        })
    }
}
</script>

<style lang="scss" scoped>
.c-error-strip {
    visibility: hidden;
    margin-top: -4px !important;
    transition: visibility 0.4s all;
    border-radius: 0px 0px 25px 25px !important;
    &__show {
        visibility: visible;
        margin-top: 0px !important;
        transition: visibility 0.4s all;
    }
}
</style>

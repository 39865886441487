import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import CustomIcons from '@/components/Icons/init'
// import ChartIcon from '../pages/Dashboard/chart-icon.vue'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                'bg-gray': '#F7F8F9',
                primary: '#222',
                secondary: '#3949AB',
                accent: '#E64A19',
                background: '#F1F0F6',
                neutral: '#f1f1f1',
                header: '#292929',
                body: '#525252',
            },
        }
    },
    icons: {
        iconfont: 'md',
        values: {
            ...CustomIcons
        }
    }
})

<template>
    <a-main>
        <g-settings-breadcrumb class="mt-5" previousRoute="inventory"></g-settings-breadcrumb>
        <a-container container--fluid grid-list-xl class="px-0">
            <g-page-header title="Book Inventory" subtitle="Follow the steps to book inventory." :breadcrumb-items="breadcrumb_items" icon="memory">
                <template slot="action">
                    <div class="d-flex align-center px-1">
                        <div v-if="inventory_book_step === 2 || inventory_book_step === 3">
                            <a-btn color="grey darken-1" class="grey lighten-2 ma-0 pl-0 mr-3 u-rounded-corners" text @click="() => inventory_book_step === 2 ? inventory_book_step = 1 : inventory_book_step = 2">
                                <a-icon left class="mx-2">keyboard_arrow_left</a-icon>
                                Back
                            </a-btn>
                        </div>
                        <a-spacer></a-spacer>
                        <div class="text-right" v-if="inventory_book_step === 1" >
                            <template v-if="inventory_request_list.length && !local_check_dates_equal">
                                <a-menu left offset-y transition="slide-y-transition" :close-on-content-click="false" max-width="300">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on">
                                            <a-btn color="primary" :disabled="!local_timeline_validation()" class="u-elevation-custom-1 u-rounded-corners">Continue</a-btn>
                                        </div>
                                    </template>
                                    <a-card class="u-rounded-corners u-elevation-custom-1 pa-3" max-width="300">
                                        <a-card-text class="pa-2">
                                            <!-- <h2 class="body-2 font-weight-normal mb-3 grey--text text--darken-1">Please revert the dates back<br><strong>({{ local_format_date(inventory_filters[this.filter.BOOKING_FROM]) }} - {{ local_format_date(inventory_filters.filter.BOOKING_TO]) }})</strong> to retain the selected hardwares list.</h2> -->
                                            <h2 class="body-2 font-weight-normal mb-3 grey--text text--darken-1">Changing the dates will clear the selected hardware list.</h2>
                                            <div class="u-flex align-center justify-space-between">
                                                <p class="caption grey--text text--darken-1 mb-0 font-weight-normal">Click "Okay" to continue</p>
                                                <a-btn @click="local_select_step(2)" small text class="blue u-rounded-corners ma-0 pa-0 ml-2" dark>Okay</a-btn>
                                            </div>
                                        </a-card-text>
                                    </a-card>
                                </a-menu>
                            </template>
                            <template v-else>
                                <a-btn color="primary" :disabled="!local_timeline_validation()" @click="local_select_step(2)" class="u-elevation-custom-1 u-rounded-corners">Continue</a-btn>
                            </template>
                        </div>
                        <div class="text-right" v-if="inventory_book_step === 2">
                            <a-btn color="primary" :disabled="inventory_request_list.length == 0 || request_form_data.booking_to == null || request_form_data.booking_from == null" @click="local_select_step(3)" class="u-elevation-custom-1 u-rounded-corners">Continue</a-btn>
                        </div>
                        <div class="text-right" v-if="inventory_book_step === 3">
                            <a-btn color="primary" class="u-elevation-custom-1 u-rounded-corners" @click="local_inventory_booking()">Book Inventory</a-btn>
                        </div>
                    </div>
                </template>
            </g-page-header>
            <a-stepper v-model="inventory_book_step" class="elevation-0 transparent">
                <a-stepper-header class="elevation-0 px-1">
                    <a-stepper-step :complete="inventory_book_step > 1" class="pl-0" step="1">Select Timeline</a-stepper-step>
                    <a-divider class="grey lighten-2"></a-divider>
                    <a-stepper-step :complete="inventory_book_step > 2" step="2">Select Available Hardware</a-stepper-step>
                    <a-divider class="grey lighten-2"></a-divider>
                    <a-stepper-step :complete="inventory_book_step > 3" class="pr-0" step="3">Review and Complete</a-stepper-step>
                </a-stepper-header>
                <a-stepper-items>
                    <a-stepper-content step="1" class="px-0">
                        <a-container container--fluid grid-list-xl class="pa-0">
                            <a-layout wrap fill-height class="ma-1">
                                <a-flex shrink xs9 class="pa-0">
                                    <a-card class="white u-elevation-custom-1 pa-3">
                                        <a-layout wrap align-center class="ma-0 pa-0">
                                            <a-flex xs6>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">
                                                    Project<span class="red--text text--darken-1 ml-1">*</span>
                                                    <span v-if="local_poc_loading" class="blue--text ml-2">Loading..</span>
                                                </label>
                                                <a-autocomplete
                                                    v-model="request_form_data.project_id"
                                                    :items="project_list"
                                                    item-text="title"
                                                    item-value="id"
                                                    placeholder="Select Project"
                                                    background-color="neutral"
                                                    clear-icon="backspace"
                                                    @change="local_select_project_detail(request_form_data.project_id)"
                                                    solo flat hide-details hide-selected clearable autofocus
                                                >
                                                    <template v-slot:no-data>
                                                        <div class="px-4 py-2">
                                                            <span class="md-body-2" v-if="local_project_search != null && local_project_search != ''"> <strong>{{ local_project_search }}</strong></span>
                                                            <span class="md-body-2 grey--text" v-else>No Projects found</span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{ item }">
                                                        <span v-if="item === Object(item)" class="md-body-2 d-block py-1 u-rounded-corners text-truncate" style="max-width: 400px" :title="item.title">{{ $vuetify.breakpoint.lg ? local_truncate_text(item.title, 35) : item.title }} </span>
                                                        <span v-else class="md-body-2 d-block text-truncate py-1 px-2 mx-1 u-rounded-corners grey darken-2 white--text">{{ item }}</span>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <a-layout align-center class="u-flex py-1">
                                                            <a-flex shrink style="max-width: 400px">
                                                                <h5 class="md-subtitle-1 text-truncate" :title="item.title">{{ $vuetify.breakpoint.lg ? local_truncate_text(item.title, 20) : item.title }}</h5>
                                                                <p class="md-caption d-block grey--text text-truncate text--darken-1 mt-1 mb-0">{{ item.customer.name | truncateText(30) }}</p>
                                                            </a-flex>
                                                            <a-spacer></a-spacer>
                                                            <a-flex shrink class="text-right">
                                                                <span class="md-body-2 grey--text" v-if="item.stage">{{ item.stage.name }}</span>
                                                                <span class="md-body-2 grey--text" v-else>Queue</span>
                                                            </a-flex>
                                                        </a-layout>
                                                    </template>
                                                </a-autocomplete>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                                    <a-icon size="16">info</a-icon>
                                                    Select project to assign.
                                                </span>
                                            </a-flex>
                                            <a-flex xs6>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Assignee<span class="red--text text--darken-1 ml-1">*</span></label>
                                                <GUsersSelectDropdown
                                                    v-model="request_form_data.assignee_id"
                                                    :list="user_list"
                                                    :loading="mixinSearchLoading"
                                                    @search="mixinSearchUsers"
                                                    @select="local_select_user_detail"
                                                    placeholder="Select User"
                                                    :inputStyle="{ height: '24px' }"
                                                    class="px-3 neutral"
                                                    :class="{ 'md-body-2': request_form_data.assignee_id }"
                                                    noDataText="No User found"
                                                    dropdownClass="c-tiny-scroll py-2"
                                                    clearable returnObject
                                                >
                                                    <template #item="{ props: { item } }">
                                                        <div class="u-flex-center-y">
                                                            <g-avatar :item="item"></g-avatar>
                                                            <g-profile-info :item="item" type-user set-max-width></g-profile-info>
                                                        </div>
                                                    </template>
                                                    <template #clearIcon="{ clear }">
                                                        <a-icon size="24" @click="clear()" class="mr-2 u-cursor-pointer" color="grey darken-1">backspace</a-icon>
                                                    </template>
                                                </GUsersSelectDropdown>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                                    <a-icon size="16">info</a-icon>
                                                    Select Member to assign.
                                                </span>
                                            </a-flex>
                                            <a-flex xs6>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Start Date<span class="red--text text--darken-1 ml-1">*</span></label>
                                                <a-menu
                                                    v-model="datepicker_request_booking_from"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    min-width="250px"
                                                    full-width offset-y lazy
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <a-text-field
                                                            :value="local_format_date(request_form_data.booking_from)"
                                                            placeholder="Select Start Date"
                                                            background-color="neutral"
                                                            v-on="on"
                                                            clear-icon="backspace"
                                                            @click:clear="local_request_form_clear('booking_from')"
                                                            solo flat hide-details readonly clearable
                                                        >
                                                        </a-text-field>
                                                    </template>
                                                    <!-- <a-date-picker v-model="request_form_data.booking_from" :min="local_format_date(null, true)" @input="datepicker_request_booking_from = false" no-title scrollable>
                                                    </a-date-picker> -->
                                                    <vc-date-picker
                                                        v-model="request_form_data.booking_from"
                                                        class="d-block u-wfull"
                                                        :model-config="modelConfig"
                                                        @input="datepicker_request_booking_from = false"
                                                        :min-date="local_format_date(null, true)"
                                                    ></vc-date-picker>
                                                </a-menu>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                                    <a-icon size="16">info</a-icon>
                                                    Add start date.
                                                </span>
                                            </a-flex>
                                            <a-flex xs6>
                                                <div class="mb-2">
                                                    <label class="md-body-2 d-inline-block mt-0 font-weight-medium">End Date<span class="red--text text--darken-1 ml-1">*</span></label>
                                                </div>
                                                <a-menu
                                                    v-model="datepicker_request_booking_to"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    min-width="250px"
                                                    full-width offset-y lazy
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <a-text-field
                                                            :value="local_format_date(request_form_data.booking_to)"
                                                            placeholder="Select End Date"
                                                            background-color="neutral"
                                                            v-on="on"
                                                            clear-icon="backspace"
                                                            @click:clear="local_request_form_clear('booking_to')"
                                                            solo flat hide-details readonly clearable
                                                        >
                                                        </a-text-field>
                                                    </template>
                                                    <!-- <a-date-picker v-model="request_form_data.booking_to" :show-current="request_form_data.booking_from ? request_form_data.booking_from : true" :min="local_format_date(null, true, request_form_data.booking_from)" @input="datepicker_request_booking_to = false" no-title scrollable>
                                                    </a-date-picker> -->
                                                     <vc-date-picker
                                                        v-model="request_form_data.booking_to"
                                                        class="d-block u-wfull"
                                                        @input="datepicker_request_booking_to = false"
                                                        :model-config="modelConfig"
                                                        :min-date="request_form_data.booking_from ? request_form_data.booking_from : local_format_date(null, true)"
                                                    ></vc-date-picker>
                                                </a-menu>
                                                <span class="md-caption d-block mt-2 red--text text--darken-1" v-if="error_bag.date">
                                                    <a-icon size="16" color="red darken-1">info</a-icon>
                                                    {{ error_bag.date }}
                                                </span>
                                                <span class="md-caption d-block mt-2 grey--text text--darken-2" v-else>
                                                    <a-icon size="16">info</a-icon>
                                                    Add end date.
                                                </span>
                                            </a-flex>
                                            <a-flex xs12>
                                                <div class="mb-2">
                                                    <label class="md-body-2 d-inline-block mt-0 font-weight-medium">Location (Optional)</label>
                                                </div>
                                                <a-text-field
                                                    v-model="request_form_data.location"
                                                    placeholder="Enter Location"
                                                    background-color="neutral"
                                                    clear-icon="backspace"
                                                    @keyup="local_request_form_clear('location')"
                                                    solo flat hide-details clearable
                                                >
                                                </a-text-field>
                                                <span class="md-caption d-block mt-2 red--text text--darken-1" v-if="error_bag.location">
                                                    <a-icon size="16" color="red darken-1">info</a-icon>
                                                    {{ error_bag.location }}
                                                </span>
                                                <span class="md-caption d-block mt-2 grey--text text--darken-2" v-else>
                                                    <a-icon size="16">info</a-icon>
                                                    Add inventory location
                                                </span>
                                            </a-flex>
                                        </a-layout>
                                    </a-card>
                                </a-flex>
                                <a-flex xs3 class="py-0 pr-0">
                                    <template v-if="local_poc_loading">
                                        <div class="grey--text text--darken-1 md-subtitle-2 text-center mt-3">Loading POC...</div>
                                    </template>
                                    <template v-else>
                                        <a-card class="transparent elevation-0" min-height="100%" max-height="410" style="overflow-y: auto;" v-if="local_project_item && request_form_data && request_form_data.project_id">
                                            <div class="pa-4">
                                                <a-chip small label class="ma-0 mb-2" v-if="local_project_item && local_project_item.customer" :title="local_project_item.customer.name && local_project_item.customer.name.length >= 30 ? local_project_item.customer.name : ''">{{ local_project_item.customer.name | truncateText(30) }}</a-chip>
                                                <h3 class="text-h5 ma-0 grey--text text--darken-1 text-truncate my-1" style="line-height: 1.2 !important; max-width: 340px;" :title="local_project_item.title">{{ local_project_item.title }}</h3>
                                                <div>
                                                    <div class="u-flex align-center mt-2">
                                                        <template v-if="local_project_item.deal_amount">
                                                            <g-currency tag="span" class="md-subtitle-1 green--text text--darken-1" :value="local_project_item.deal_amount"></g-currency>
                                                            <span class="grey--text text--darken-1 mx-2">&bull;</span>
                                                        </template>
                                                        <h3 class="md-body-2 ma-0 d-inline-block grey--text text--darken-2" v-if="local_project_item.stage">{{ local_project_item.stage.name }}</h3>
                                                    </div>
                                                    <div class="d-flex my-4">
                                                        <div>
                                                            <span class="md-caption font-weight-bold ma-0 d-block grey--text text--darken-1 text-uppercase">Start Date</span>
                                                            <h3 class="md-body-2 ma-0 d-inline-block font-weight-normal grey--text text--darken-1">
                                                                <template v-if="local_project_item.start_date"><g-moment :value="local_project_item.start_date" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment></template>
                                                                <template v-else><span class="grey--text text--darken-1"> No Start Date</span></template>
                                                            </h3>
                                                        </div>
                                                        <div class="ml-5">
                                                            <span class="md-caption font-weight-bold ma-0 d-block grey--text text--darken-1 text-uppercase">End Date</span>
                                                            <h3 class="md-body-2 ma-0 d-inline-block font-weight-normal grey--text text--darken-1">
                                                                <template v-if="local_project_item.due_date"><g-moment :value="local_project_item.due_date" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment></template>
                                                                <template v-else><span class="grey--text text--darken-1"> No Due Date</span></template>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div class="" v-if="local_project_item.territories && local_project_item.territories.length">
                                                        <span class="md-caption font-weight-bold ma-0 d-block grey--text text--darken-1 text-uppercase mb-2">Territories</span>
                                                        <div class="u-flex align-start mb-2 flex-wrap">
                                                            <span
                                                                class="caption d-inline-block py-1 px-2 ma-0 mr-2 mb-2 u-rounded-corners"
                                                                :style="{backgroundColor: $color(territory.color, 'color_bg'), color: $color(territory.color, 'color_text')}"
                                                                v-for="territory in local_project_item.territories"
                                                                :key="territory.id"
                                                            >
                                                                {{ territory.name }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="mb-4">
                                                        <span class="md-caption font-weight-bold ma-0 d-block grey--text text--darken-1 text-uppercase mb-1">Workspace</span>
                                                        <span class="grey--text text--darken-1" v-if="local_project_item.workspace">{{ local_project_item.workspace.title }}</span>
                                                    </div>
                                                    <div class="">
                                                        <span class="md-caption font-weight-bold ma-0 d-block grey--text text--darken-1 text-uppercase mb-1">Key Members</span>
                                                        <div v-if="local_project_item.key_collaborators && local_project_item.key_collaborators.length" class="text-center mt-1 d-inline-block">
                                                            <s-users-deck v-if="local_project_item.key_collaborators" :list="local_project_item.key_collaborators" class="justify-end" :avatar-size="40" :count="5"></s-users-deck>
                                                        </div>
                                                        <span v-else class="subheading ma-0 d-block font-weight-normal grey--text text--darken-1">No key members found</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a-card>
                                        <a-card class="white u-elevation-custom-1 d-flex align-center" min-height="100%" max-height="413" style="overflow-y: auto;" v-else>
                                            <div class="text-center u-wfull">
                                                <a-avatar size="96" color="grey lighten-3">
                                                    <a-icon size="64" color="grey darken-1">work</a-icon>
                                                </a-avatar>
                                                <h3 class="title ma-0 grey--text text--darken-1 mt-4">Select project to Review</h3>
                                            </div>
                                        </a-card>
                                    </template>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-stepper-content>
                    <a-stepper-content step="2" class="pa-0">
                        <a-container key="filters" grid-list-xl container--fluid class="pa-1 mb-5">
                            <a-layout class="align-center mb-2">
                                <a-flex xs12>
                                   <a-card class="u-elevation-custom-1 u-rounded-corners px-3 py-2" v-if="local_project_item && request_form_data && request_form_data.project_id">
                                        <a-layout class="align-center">
                                            <a-flex xs3 class="u-flex align-center justify-center text-center u-wrap" v-if="local_project_item && local_project_item.customer">
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 u-wfull">
                                                    <strong>Project</strong>
                                                </h3>
                                                <div class="u-flex align-center justify-center text-center">
                                                    <h3 class="subtitle-2 ma-0 grey--text text--darken-1" :title="local_project_item.title + '(' + local_project_item.customer.name + ')'">
                                                        {{ local_truncate_text(local_project_item.title, $vuetify.breakpoint.lg ? 10 : 18) }}</h3>
                                                    <h3 class="subtitle-2 ma-0 grey--text text--darken-1" :title="local_project_item.title + '(' + local_project_item.customer.name + ')'">
                                                        ({{ local_truncate_text(local_project_item.customer.name, $vuetify.breakpoint.lg ? 6 : 15) }})
                                                    </h3>
                                                </div>
                                            </a-flex>
                                            <a-divider vertical></a-divider>
                                            <a-flex xs3 class="text-center">
                                                <h3 class="subtitle-2 ma-0 mb-1 grey--text text--darken-1 u-wfull">
                                                    <strong>Date</strong>
                                                </h3>
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" v-if="local_project_item && local_project_item.customer" :title="local_project_item.customer.name">
                                                    <template v-if="request_form_data.booking_from || request_form_data.booking_to">
                                                        <span>{{ local_format_date(request_form_data.booking_from) }}</span> - <span>{{ local_format_date(request_form_data.booking_to) }}</span>
                                                    </template>
                                                </h3>
                                            </a-flex>
                                            <!-- <a-divider vertical></a-divider>
                                            <a-flex xs2 class="text-center">
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" v-if="local_project_item && local_project_item.customer" :title="local_project_item.customer.name"><strong>Account </strong>{{ local_project_item.customer.name }}</h3>
                                            </a-flex>
                                            <a-divider vertical></a-divider>
                                            <a-flex xs2 class="text-center">
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" v-if="local_project_item && local_project_item.stage" :title="local_project_item.stage.name"><strong>Stage </strong>{{ local_project_item.stage.name }}</h3>
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" v-else title="Queue"><strong>Stage </strong>Queue</h3>
                                            </a-flex> -->
                                            <a-divider vertical></a-divider>
                                            <a-flex xs3 class="text-center">
                                                <h3 class="subtitle-2 ma-0 mb-1 grey--text text--darken-1 u-wfull">
                                                    <strong>Assignee</strong>
                                                </h3>
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" v-if="local_project_item && local_project_item.assignee" :title="local_project_item.assignee">{{ local_project_item.assignee }}</h3>
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" v-else>-</h3>
                                            </a-flex>
                                            <a-divider vertical></a-divider>
                                            <a-flex xs3 class="text-center">
                                                <h3 class="subtitle-2 ma-0 mb-1 grey--text text--darken-1 u-wfull">
                                                    <strong>Location</strong>
                                                </h3>
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" :title="request_form_data.location" v-if="request_form_data.location">{{ request_form_data.location }}</h3>
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" :title="request_form_data.location" v-else>No Location</h3>
                                            </a-flex>
                                            <template v-if="local_project_item.territories && local_project_item.territories.length">
                                                <a-divider vertical></a-divider>
                                                <a-flex shrink class="text-center pb-1" style="min-width: 260px; max-width: 260px;">
                                                    <div class="u-flex align-start justify-center flex-wrap">
                                                        <span
                                                            class="caption d-inline-block py-1 px-2 ma-0 mr-2 mb-2 u-rounded-corners"
                                                            :style="{backgroundColor: $color(territory.color, 'color_bg'), color: $color(territory.color, 'color_text')}"
                                                            v-for="territory in local_project_item.territories"
                                                            :key="territory.id"
                                                        >
                                                            {{ territory.name }}
                                                        </span>
                                                    </div>
                                                </a-flex>
                                            </template>
                                        </a-layout>
                                    </a-card>
                                </a-flex>
                            </a-layout>
                            <a-layout align-center class="my-0 mb-2">
                                <a-flex class="py-0">
                                    <a-card class="c-search-inventory px-3 d-flex align-center u-elevation-custom-1 u-rounded-corners mb-1">
                                        <a-icon size="24" color="grey" style="max-width: 20px;">search</a-icon>
                                        <a-text-field solo flat hide-details dense placeholder="Search by hardware name, part number or serial number - hit enter to search" v-model="local_search_inventory" @input="local_search_filter(filter.SEARCH, local_search_inventory)"></a-text-field>
                                    </a-card>
                                </a-flex>
                                <a-flex shrink class="pl-1">
                                    <a-progress-circular color="orange darken-2" size="24" width="2" :indeterminate="loading"></a-progress-circular>
                                </a-flex>
                            </a-layout>
                            <a-layout class="align-center">
                                <!-- <a-flex shrink class="py-1">
                                    <a-card class="u-elevation-custom-1 u-rounded-corners pa-0 d-inline-flex align-center" min-height="40">
                                        <a-card flat class="u-rounded-corners u-overflow-hidden elevation-0">
                                            <a-card-text class="px-2 py-1 d-flex align-center">
                                                <a-icon size="18" class="ma-0 mt-0 mr-1 u-icon-nudge">date_range</a-icon>
                                                <span class="grey--text text--darken-2">Date:
                                                    <template v-if="request_form_data.booking_from || request_form_data.booking_to">
                                                        <span class="font-weight-medium">{{ local_format_date(request_form_data.booking_from) }}</span> - <span class="font-weight-medium">{{ local_format_date(request_form_data.booking_to) }}</span>
                                                    </template>
                                                </span>
                                            </a-card-text>
                                        </a-card>
                                    </a-card>
                                </a-flex> -->
                                <a-flex shrink class="py-1">
                                    <a-menu bottom offset-y v-model="search_category" :close-on-content-click="category_mode" max-width="300" max-height="500" transition="slide-y-transition" class="d-block">
                                        <template v-slot:activator="{ on }">
                                            <a-card class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 d-flex" min-width="300" max-width="300" v-on="on" @click="local_search_category = ''">
                                                <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                                    <div>
                                                        <a-icon size="20" class="ma-0">filter_list</a-icon>
                                                        <span class="grey--text text--darken-2 mx-1" :title="local_filter_category_label">
                                                            Category:
                                                            <span class="font-weight-medium" style="max-width: 300px"  v-if="local_filter_category_label">
                                                                {{ local_truncate_text(local_filter_category_label, 15) }}
                                                                <span class="md-body-2 grey--text" v-if="local_select_inventory_count >= 0">({{ local_select_inventory_count }})</span>
                                                            </span>
                                                            <span class="font-weight-medium" v-else>All</span>
                                                        </span>
                                                    </div>
                                                    <div class="text-right">
                                                        <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                                    </div>
                                                </a-card-text>
                                            </a-card>
                                        </template>
                                        <a-divider></a-divider>
                                        <a-card class="c-search-inputs-inventory u-elevation-custom-1 u-rounded-corners u-overflow-hidden" min-height="40">
                                            <a-text-field
                                                v-model="local_search_category"
                                                placeholder="Search Category"
                                                background-color="neutral"
                                                ref="search_category_input"
                                                class="body-2 font-weight-normal"
                                                solo flat hide-details
                                            >
                                            </a-text-field>
                                            <a-divider></a-divider>
                                            <a-list style="max-height: 384px;" class="overflow-y-auto py-0">
                                                <a-list-item  v-if="!local_search_category && local_filtered_inventory_category_list.length !== 0 && inventory_filters[filter.CATEGORY] === 'ALL'" class="grey lighten-4 py-0">
                                                    <a-list-item-title>
                                                        <a-layout align-center class="py-0">
                                                            <a-flex>
                                                                <h5 class="md-subtitle-1">All</h5>
                                                            </a-flex>
                                                            <a-flex class="text-right">
                                                                <span class="md-body-2 grey--text">{{ inventory_meta.total_available_count }}</span>
                                                            </a-flex>
                                                        </a-layout>
                                                    </a-list-item-title>
                                                </a-list-item>
                                                <a-list-item @click="local_category_filter(filter.CATEGORY,'ALL', 'All', inventory_meta.total_available_count)" v-if="!local_search_category && local_filtered_inventory_category_list.length !== 0 && inventory_filters[filter.CATEGORY] !== 'ALL'">
                                                    <a-list-item-title>
                                                        <a-layout align-center class="py-0">
                                                            <a-flex>
                                                                <h5 class="md-subtitle-1">All</h5>
                                                            </a-flex>
                                                            <a-flex class="text-right">
                                                                <span class="md-body-2 grey--text">{{ inventory_meta.total_available_count }}</span>
                                                            </a-flex>
                                                        </a-layout>
                                                    </a-list-item-title>
                                                </a-list-item>
                                                <template v-for="(item, index) in local_filtered_inventory_category_list" >
                                                    <a-list-item :key="'filter' + index" @click="local_category_filter(filter.CATEGORY, item.id, item.value, item.available_category_count)" v-if="inventory_filters[filter.CATEGORY] !== item.id">
                                                        <a-list-item-title>
                                                            <a-layout align-center class="py-0">
                                                                <a-flex>
                                                                    <h5 class="md-subtitle-1 text-truncate" style="max-width: 180px" :title="item.value">{{ item.value }}</h5>
                                                                </a-flex>
                                                                <a-flex class="text-right">
                                                                    <span class="md-body-2 grey--text">{{ item.available_category_count }}</span>
                                                                </a-flex>
                                                            </a-layout>
                                                        </a-list-item-title>
                                                    </a-list-item>
                                                    <a-list-item :key="'filter' + index" class="grey lighten-4 py-0" v-else>
                                                        <a-list-item-title>
                                                            <a-layout align-center class="u-flex py-0">
                                                                <a-flex shrink>
                                                                    <h5 class="md-subtitle-1 text-truncate" style="max-width: 180px" :title="item.value">{{ item.value }}</h5>
                                                                </a-flex>
                                                                <a-spacer></a-spacer>
                                                                <a-flex shrink class="text-right">
                                                                    <span class="md-body-2 grey--text">{{ item.available_category_count }}</span>
                                                                </a-flex>
                                                            </a-layout>
                                                        </a-list-item-title>
                                                    </a-list-item>
                                                </template>
                                                <a-list-item v-if="local_filtered_inventory_category_list.length === 0" disabled>
                                                    <a-list-item-content>
                                                        <a-list-item-title v-if="loading">
                                                            <h2 class="md-subtitle-1 pa-0 mb-0 text-center">Loading categories...</h2>
                                                        </a-list-item-title>
                                                        <a-list-item-title v-else>
                                                            <h2 class="md-subtitle-1 mb-0 text-center">No categories found</h2>
                                                        </a-list-item-title>
                                                    </a-list-item-content>
                                                </a-list-item>
                                            </a-list>
                                            <a-divider></a-divider>
                                            <a-list class="py-0">
                                                <!-- <a-list-item @click="local_category_filter(filter.CATEGORY,'NONE', 'No Category', inventory_meta.without_category)" v-if="!local_search_category && local_filtered_inventory_category_list.length !== 0 && inventory_filters[filter.CATEGORY] !== 'NONE'"> -->
                                                <a-list-item @click="local_category_filter(filter.CATEGORY,'NONE', 'No Category', inventory_meta.without_category_is_available)" v-if="inventory_filters[filter.CATEGORY] !== 'NONE'">
                                                    <a-list-item-title>
                                                        <a-layout align-center class="py-0">
                                                            <a-flex>
                                                                <h5 class="md-subtitle-1">No Category</h5>
                                                            </a-flex>
                                                            <a-flex class="text-right">
                                                                <span class="md-body-2 grey--text" :class="{'mr-3': local_filtered_inventory_category_list.length > 8}">{{ inventory_meta.without_category_is_available }}</span>
                                                            </a-flex>
                                                        </a-layout>
                                                    </a-list-item-title>
                                                </a-list-item>
                                                <a-list-item v-if="inventory_filters[filter.CATEGORY] === 'NONE'" class="grey lighten-4 py-0">
                                                    <a-list-item-title>
                                                        <a-layout align-center class="py-0">
                                                            <a-flex>
                                                                <h5 class="md-subtitle-1">No Category</h5>
                                                            </a-flex>
                                                            <a-flex class="text-right">
                                                                <span class="md-body-2 grey--text" :class="{'mr-3': local_filtered_inventory_category_list.length > 8}">{{ inventory_meta.without_category_is_available }}</span>
                                                            </a-flex>
                                                        </a-layout>
                                                    </a-list-item-title>
                                                </a-list-item>
                                            </a-list>
                                        </a-card>
                                    </a-menu>
                                </a-flex>
                                <a-flex shrink class="py-1">
                                    <a-menu bottom offset-y v-model="search_tag" :close-on-content-click="filter_mode" max-width="300" max-height="500" transition="slide-y-transition" class="d-block">
                                        <template v-slot:activator="{ on }">
                                            <a-card class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 d-flex" min-width="300" max-width="300" v-on="on" @click="local_search_tag = ''">
                                                <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                                    <div>
                                                        <a-icon size="20" class="ma-0">filter_list</a-icon>
                                                        <span class="grey--text text--darken-2 mx-1" v-if="local_tag_filter_item">
                                                            Tag:
                                                            <span class="caption d-inline-block px-2 ma-0 ml-1 u-rounded-corners" v-if="local_tag_filter_item !== 'No Tag' && local_tag_filter_item !== 'All'" style="padding-top: 2px; padding-bottom: 2px;" :style="{backgroundColor: $color(local_tag_filter_item.color, 'color_bg'), color: $color(local_tag_filter_item.color, 'color_text')}">
                                                                {{ local_tag_filter_item.label }}
                                                            </span>
                                                            <span class="font-weight-medium" v-else>
                                                                {{ local_tag_filter_item }}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div class="text-right">
                                                        <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                                    </div>
                                                </a-card-text>
                                            </a-card>
                                        </template>
                                        <a-divider></a-divider>
                                        <a-card class="c-search-inputs-inventory u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                                            <a-text-field
                                                v-model="local_search_tag"
                                                placeholder="Search tag"
                                                background-color="neutral"
                                                ref="search_tag_input"
                                                class="body-2 font-weight-normal"
                                                solo flat hide-details
                                                @click="filter_mode = false"
                                            >
                                            </a-text-field>
                                            <a-divider></a-divider>
                                            <a-list style="max-height: 384px;"  class="overflow-y-auto py-0">
                                                <a-list-item @click="local_tag_filter(filter.TAGS,'ALL', 'All')" v-if="!local_search_tag && local_filtered_tag_list.length !== 0 && inventory_filters.tags !== 'ALL'">
                                                    <a-layout align-center class="py-0">
                                                        <div class="member-label u-cursor-pointer">
                                                            <h5 class="md-subtitle-1">All</h5>
                                                        </div>
                                                    </a-layout>
                                                </a-list-item>
                                                <a-list-item v-else class="grey lighten-4 py-0">
                                                    <a-layout align-center class="py-0">
                                                        <div class="member-label u-cursor-pointer">
                                                            <h5 class="md-subtitle-1">All</h5>
                                                        </div>
                                                    </a-layout>
                                                </a-list-item>
                                                <a-list-item v-for="(item, index) in local_filtered_tag_list" :key="'territory' + index" @click="local_tag_filter(filter.TAGS, item.id, item)">
                                                    <a-layout align-center class="py-0">
                                                        <span class="md-subtitle-2 d-inline-block py-1 px-2 mr-2 my-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">
                                                            {{ item.label }}
                                                        </span>
                                                    </a-layout>
                                                </a-list-item>
                                                <a-list-item v-if="local_filtered_tag_list.length === 0" disabled>
                                                    <a-list-item-content>
                                                        <a-list-item-title v-if="loading">
                                                            <h2 class="md-subtitle-1 pa-0 mb-0 text-center">Loading tags...</h2>
                                                        </a-list-item-title>
                                                        <a-list-item-title v-else>
                                                            <h2 class="md-subtitle-1 mb-0 text-center">No Tags found</h2>
                                                        </a-list-item-title>
                                                    </a-list-item-content>
                                                </a-list-item>
                                            </a-list>
                                            <a-divider></a-divider>
                                            <a-list class="py-0" v-if="!local_search_tag && local_filtered_tag_list.length !== 0" @click="filter_mode = true">
                                                <a-list-item @click="local_tag_filter(filter.TAGS,'NONE', 'No Tag')">
                                                    <a-list-item-title>
                                                        No tag
                                                    </a-list-item-title>
                                                </a-list-item>
                                            </a-list>
                                        </a-card>
                                    </a-menu>
                                </a-flex>
                                <!-- <a-flex shrink class="py-1">
                                    <a-card flat class="c-search-inventory c-search-inventory--book u-rounded-corners d-inline-flex align-center u-elevation-custom-1 px-2" max-width="465" min-height="40">
                                        <a-icon>search</a-icon>
                                        <a-text-field solo flat hide-details dense placeholder="Search by hardware name, part number or serial number - hit enter to search" class="shrink" v-model="local_search_inventory" @keyup.enter="local_search_filter('search', local_search_inventory)"></a-text-field>
                                    </a-card>
                                </a-flex> -->
                                <a-spacer></a-spacer>
                                <a-flex shrink class="py-1">
                                    <a-menu bottom offset-y transition="slide-y-transition" :disabled="!inventory_request_list.length ? true : false" :close-on-content-click="false" min-width="500" max-width="500" max-height="600">
                                        <template v-slot:activator="{ on }">
                                            <a-btn v-on="on" small class="py-1 px-2 ma-0 white body-2 grey--text text--darken-1 text-capitalize u-elevation-custom-1 u-rounded-corners" style="min-height: 35px;">
                                                Selected Hardware{{ inventory_request_list.length > 1 ? 's' : null }}
                                                <a-sheet max-width="30" max-height="30" class="py-1 px-2 c-badge caption ml-2 blue white--text" v-if="inventory_request_list.length">{{ inventory_request_list.length }}</a-sheet>
                                                <a-sheet max-width="30" max-height="30" class="py-1 px-2 c-badge caption ml-2 grey darken-2 white--text" v-else>{{ inventory_request_list.length }}</a-sheet>
                                            </a-btn>
                                        </template>
                                        <a-card class="u-elevation-custom-1 u-rounded-corners">
                                            <div class="u-rounded-corners white primary--text u-elevation-custom-1 px-3 py-2 u-flex align-center" style="position: sticky; top: 0; left: 0; z-index: 10">
                                                <span>Selected Hardware{{ inventory_request_list.length > 1 ? 's' : null }} ({{ inventory_request_list.length }})</span>
                                                <a-spacer></a-spacer>
                                                <a-btn small text @click="local_clear_all_inventories()" class="ma-0 red--text text--lighten-1">
                                                    <a-icon size="16" class="mr-1">clear_all</a-icon>
                                                    Clear all
                                                </a-btn>
                                            </div>
                                            <div class="px-3 pt-3" :class="{ 'pb-3': index === inventory_request_list.length - 1 }" v-for="(item, index) in inventory_request_list" :key="item.id">
                                                <div class="u-flex align-start" style="width: 100%">
                                                    <a-btn icon @click="local_remove_inventory(item.id)" class="pa-1 ma-0 mr-2 red--text text--darken-1"><a-icon size="18">remove_circle</a-icon></a-btn>
                                                    <div v-if="item">
                                                        <h2 class="subtitle-2 grey--text text--darken-2 font-weight-bold">{{ item.name }}</h2>
                                                        <h3 class="body-2 grey--text text--darken-1 font-weight-normal" v-if="item.category">{{ item.category.value }}</h3>
                                                        <h3 class="body-2 grey--text text--darken-1 font-weight-normal" v-else>No Category</h3>
                                                    </div>
                                                </div>
                                                <a-divider v-if="index !== inventory_request_list.length - 1" class="grey lighten-3 mt-3"></a-divider>
                                            </div>
                                        </a-card>
                                    </a-menu>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-container grid-list-xl container--fluid class="pa-1 pt-7 mb-5 pb-4">
                            <a-responsive class="py-4 my-4" v-if="!inventory_list.length && show_inventory_list && !local_loading">
                                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                                <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.<br>Try narrowing your filter or add new entries.</p>
                            </a-responsive>
                            <a-responsive class="py-4 my-4" v-if="!show_inventory_list">
                                <h3 class="headline font-weight-bold text-center teal--text text--darken-1 mb-1">Select a category from the dropdown to see the list.</h3>
                            </a-responsive>
                            <template v-if="inventory_list.length">
                                <a-layout>
                                    <a-flex class="py-0">
                                        <a-card class="u-elevation-custom-1 pa-0 transparent u-rounded-corners">
                                            <a-layout align-center class="px-0 grey lighten-4 mx-0 py-2">
                                                <a-flex shrink xs1 style="max-width: 50px !important;" class="pa-0 px-2 ml-2">
                                                    <a-checkbox style="visibility:hidden;" class="ma-0" color="info" value="info" hide-details></a-checkbox>
                                                </a-flex>
                                                <a-flex shrink :class="{ 'xs5': $vuetify.breakpoint.xl, 'xs6': $vuetify.breakpoint.lgAndDown }" class="pa-0 px-2">
                                                    <a-layout align-center class="ma-0 pa-0">
                                                        <a-flex shrink xs2 class="mx-0 py-0 px-2">
                                                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold">Category</h4>
                                                        </a-flex>
                                                        <a-flex shrink xs4 class="mx-0 py-0 px-2">
                                                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold">Name</h4>
                                                        </a-flex>
                                                        <a-flex shrink xs3 class="mx-0 py-0 px-2">
                                                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-left">OEM</h4>
                                                        </a-flex>
                                                        <a-flex shrink xs3 class="mx-0 py-0 px-2">
                                                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-left">Model #</h4>
                                                        </a-flex>
                                                    </a-layout>
                                                </a-flex>
                                                <a-flex shrink xs3 class="pa-0 px-2">
                                                    <a-layout align-center class="ma-0 pa-0">
                                                        <a-flex shrink xs6 class="mx-0 py-0 px-2">
                                                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-left">Part #</h4>
                                                        </a-flex>
                                                        <a-flex shrink xs6 class="mx-0 py-0 px-2">
                                                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-left">Serial #</h4>
                                                        </a-flex>
                                                    </a-layout>
                                                </a-flex>
                                                <!-- <a-flex shrink xs3 class="pa-0 px-2"> -->
                                                <a-flex shrink :class="[$vuetify.breakpoint.lgAndDown ? 'xs2' : 'xs3']" class="pa-0 px-2">
                                                    <a-layout align-center class="ma-0 pa-0">
                                                        <!-- <a-flex shrink :class="[$vuetify.breakpoint.lgAndDown ? 'xs6' : 'xs3']" class="mx-0 py-0 px-2"> -->
                                                        <a-flex shrink :class="[$vuetify.breakpoint.lgAndDown ? 'xs6' : 'xs2']" class="text-center mx-0 py-0 px-2">
                                                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-center">Status</h4>
                                                        </a-flex>
                                                        <a-flex shrink xs8 class="mx-0 py-0 px-2" v-if="!$vuetify.breakpoint.lgAndDown">
                                                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-left">Location</h4>
                                                        </a-flex>
                                                        <a-flex shrink :class="[$vuetify.breakpoint.lgAndDown ? 'xs6' : 'xs2']" class="mx-0 text-center py-0 px-2">
                                                            <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-center">Owner</h4>
                                                        </a-flex>
                                                    </a-layout>
                                                </a-flex>
                                                <!-- <a-flex shrink v-if="!$vuetify.breakpoint.lgAndDown" xs1 class="pa-0 pl-2">
                                                    <h4 class="body-2 grey--text text--darken-3 font-weight-medium text-center">Owner</h4>
                                                </a-flex> -->
                                            </a-layout>
                                            <a-divider></a-divider>
                                            <a-card v-for="(inventory, index) in inventory_list" :key="inventory + '-'+ index" class="white elevation-0">
                                                <a-card class="white ma-0 pa-0 elevation-0 py-1">
                                                    <a-layout align-center class="pa-0 mx-0 py-3">
                                                        <a-flex shrink xs1 style="max-width: 50px !important;" class="pa-0 px-2 ml-2">
                                                            <template v-if="inventory.current_booked_items">
                                                                <a-tooltip right>
                                                                    <template v-slot:activator="{ on }">
                                                                        <a-btn icon v-on="on" :disabled="true" class="pa-1 ma-0 mr-2 grey--text text--lighten-2"><a-icon size="18">block</a-icon></a-btn>
                                                                    </template>
                                                                    <span>Hardware unavailable during selected range</span>
                                                                </a-tooltip>
                                                            </template>
                                                            <template v-else>
                                                                <a-tooltip right v-if="!local_is_inventory_added(inventory.id)">
                                                                    <template v-slot:activator="{ on }">
                                                                        <a-btn icon v-on="on" @click="local_inventory_request_list(inventory)" class="ma-0 pa-0 grey--text text--darken-2"><a-icon size="18">add_circle</a-icon></a-btn>
                                                                    </template>
                                                                    <span>Add hardware to the booking</span>
                                                                </a-tooltip>
                                                                <a-tooltip right v-else>
                                                                    <template v-slot:activator="{ on }">
                                                                        <a-btn icon v-on="on" @click="local_remove_inventory(inventory.id)" class="pa-1 ma-0 mr-2 red--text text--darken-1"><a-icon size="18">remove_circle</a-icon></a-btn>
                                                                    </template>
                                                                    <span>Remove hardware from the booking</span>
                                                                </a-tooltip>
                                                            </template>
                                                        </a-flex>
                                                        <a-flex shrink :class="{ 'xs5': $vuetify.breakpoint.xl, 'xs6': $vuetify.breakpoint.lgAndDown }" class="pa-0 px-2">
                                                            <a-layout align-center class="ma-0 pa-0">
                                                                <a-flex shrink xs2 class="pa-0 px-2">
                                                                    <h4 class="body-2 hidden-xl-only text-truncate" :title="inventory.category.value" v-if="inventory.category && inventory.category.value">{{ inventory.category.value }}</h4>
                                                                    <h4 class="body-2 hidden-lg-and-down" :title="inventory.category.value" v-if="inventory.category && inventory.category.value">{{ local_truncate_text(inventory.category.value, 12) }}</h4>
                                                                    <h4 class="body-2 text-left text-truncate" v-else>No Category</h4>
                                                                </a-flex>
                                                                <a-flex shrink xs4 class="pa-0 px-2">
                                                                    <h4 class="body-2 text-truncate" :title="inventory.name">{{ inventory.name }}</h4>
                                                                </a-flex>
                                                                <a-flex shrink xs3 class="pa-0 px-2">
                                                                    <h4 class="body-2 text-left text-truncate" v-if="inventory.oem" :title="inventory.oem">{{ inventory.oem }}</h4>
                                                                    <h4 class="body-2 text-left" v-else> - </h4>
                                                                </a-flex>
                                                                <a-flex shrink xs3 class="pa-0 px-2">
                                                                    <h4 class="body-2 text-left text-truncate" v-if="inventory.model_no" :title="inventory.model_no">{{ inventory.model_no }}</h4>
                                                                    <h4 class="body-2 text-left" v-else> - </h4>
                                                                </a-flex>
                                                            </a-layout>
                                                        </a-flex>
                                                        <a-flex shrink xs3 class="pa-0 px-2">
                                                            <a-layout align-center class="ma-0 pa-0">
                                                                <a-flex shrink xs6 class="pa-0 px-2">
                                                                    <h4 class="body-2 text-left text-truncate" v-if="inventory.part_no" :title="inventory.part_no">{{ inventory.part_no }}</h4>
                                                                    <h4 class="body-2 text-left" v-else> - </h4>
                                                                </a-flex>
                                                                <a-flex shrink xs6 class="pa-0 px-2">
                                                                    <h4 class="body-2 text-left text-truncate" v-if="inventory.serial_no" :title="inventory.serial_no">{{ inventory.serial_no }}</h4>
                                                                    <h4 class="body-2 text-left" v-else> - </h4>
                                                                </a-flex>
                                                            </a-layout>
                                                        </a-flex>
                                                        <!-- <a-flex shrink xs3 class="pa-0 px-2"> -->
                                                        <a-flex shrink :class="[$vuetify.breakpoint.lgAndDown ? 'xs2' : 'xs3']" class="pa-0 px-2 text-center">
                                                            <a-layout align-center class="ma-0 pa-0">
                                                                <!-- <a-flex shrink :class="[$vuetify.breakpoint.lgAndDown ? 'xs6' : 'xs3']" class="pa-0 px-2"> -->
                                                                <a-flex shrink :class="[$vuetify.breakpoint.lgAndDown ? 'xs6' : 'xs2']" class="pa-0 px-2 u-relative">
                                                                    <div v-if="inventory.current_booked_items && inventory.current_booked_items.force_extended === 1" class="u-absolute" style="top: -10px; left: 38px; z-index: 1;">
                                                                        <a-tooltip bottom>
                                                                            <template v-slot:activator="{ on }">
                                                                                <a-icon v-on="on" size="18" class="pa-1" color="orange darken-3">info</a-icon>
                                                                            </template>
                                                                            <span>Overdue</span>
                                                                        </a-tooltip>
                                                                    </div>
                                                                    <a-tooltip bottom v-if="inventory.current_booked_items">
                                                                        <template v-slot:activator="{ on }">
                                                                            <a-avatar v-on="on" size="30" color="orange darken-1">
                                                                                <a-icon size="18" class="pa-1" color="white">hourglass_empty</a-icon>
                                                                            </a-avatar>
                                                                        </template>
                                                                        <span>{{ inventory.current_booked_items && inventory.current_booked_items.force_extended === 1 ? 'In Use and Overdue' : 'In Use' }}</span>
                                                                    </a-tooltip>
                                                                    <a-tooltip bottom v-if="!inventory.current_booked_items">
                                                                        <template v-slot:activator="{ on }">
                                                                            <a-avatar v-on="on" size="30" color="light-green lighten-3">
                                                                                <a-icon size="18" class="pa-1" color="green darken-2">check</a-icon>
                                                                            </a-avatar>
                                                                        </template>
                                                                        <span>Available</span>
                                                                    </a-tooltip>
                                                                </a-flex>
                                                                <!-- <a-flex shrink xs6 class="pa-0 px-2" v-if="$vuetify.breakpoint.lgAndDown">
                                                                    <a-tooltip bottom v-if="inventory.owner">
                                                                        <a-avatar v-on="on" size="28" color="grey" v-if="inventory.owner.profile && inventory.owner.profile.avatar">
                                                                            <a-avatar size="26" style="padding: 2px;" color="white">
                                                                                <a-img :src="inventory.owner.profile.avatar" contain></a-img>
                                                                            </a-avatar>
                                                                        </a-avatar>
                                                                        <a-avatar v-on="on" size="28" color="indigo" v-else>
                                                                            <span class="body-2 font-weight-bold white--text d-block">{{ inventory.owner.initial }}</span>
                                                                        </a-avatar>
                                                                        <span class="d-block font-weight-bold">{{ inventory.owner.name }}</span>
                                                                    </a-tooltip>
                                                                    <h4 class="body-2 text-center" style="margin-left: 10px;" v-else> - </h4>
                                                                </a-flex> -->
                                                                <a-flex shrink xs8 class="pa-0 px-2" v-if="!$vuetify.breakpoint.lgAndDown">
                                                                    <h4 class="body-2 text-left text-truncate" v-if="inventory.location" :title="inventory.location">{{ inventory.location }}</h4>
                                                                    <h4 class="body-2 text-left" v-else> - </h4>
                                                                </a-flex>
                                                                <a-flex shrink class="text-center" :class="[$vuetify.breakpoint.lgAndDown ? 'xs6' : 'xs2']">
                                                                    <a-tooltip bottom v-if="inventory.owner">
                                                                        <template v-slot:activator="{ on }">
                                                                            <a-avatar v-on="on" size="28" color="grey" v-if="inventory.owner.profile && inventory.owner.profile.avatar">
                                                                                <a-avatar size="26" style="padding: 2px;" color="white">
                                                                                    <a-img :src="inventory.owner.profile.avatar" contain></a-img>
                                                                                </a-avatar>
                                                                            </a-avatar>
                                                                            <a-avatar v-on="on" size="28" color="indigo" v-else>
                                                                                <span class="body-2 font-weight-bold white--text d-block">{{ inventory.owner.initial }}</span>
                                                                            </a-avatar>
                                                                        </template>
                                                                        <span class="d-block font-weight-bold">{{ inventory.owner.name }}</span>
                                                                    </a-tooltip>
                                                                    <!-- <h4 class="body-2 text-center" style="margin-left: 10px;" v-else> - </h4> -->
                                                                    <h4 class="body-2 text-center" style="" v-else> - </h4>
                                                                </a-flex>
                                                            </a-layout>
                                                        </a-flex>
                                                        <!-- <a-flex shrink v-if="!$vuetify.breakpoint.lgAndDown" xs1 class="pa-0 pl-2 text-center">
                                                            <a-tooltip bottom v-if="inventory.owner">
                                                                <a-avatar v-on="on" size="28" color="grey" v-if="inventory.owner.profile && inventory.owner.profile.avatar">
                                                                    <a-avatar size="26" style="padding: 2px;" color="white">
                                                                        <a-img :src="inventory.owner.profile.avatar" contain></a-img>
                                                                    </a-avatar>
                                                                </a-avatar>
                                                                <a-avatar v-on="on" size="28" color="indigo" v-else>
                                                                    <span class="body-2 font-weight-bold white--text d-block">{{ inventory.owner.initial }}</span>
                                                                </a-avatar>
                                                                <span class="d-block font-weight-bold">{{ inventory.owner.name }}</span>
                                                            </a-tooltip>
                                                            <h4 class="body-2 text-center" style="margin-left: 10px;" v-else> - </h4>
                                                        </a-flex> -->
                                                    </a-layout>
                                                </a-card>
                                                <a-divider></a-divider>
                                            </a-card>
                                        </a-card>
                                    </a-flex>
                                </a-layout>
                                <div class="text-center mt-6">
                                    <a-btn  v-if="inventory_meta && inventory_meta.current_page < inventory_meta.last_page" color="primary" class="mx-0 elevation-0" small @click="local_load_more_inventory()">Load more</a-btn>
                                </div>
                            </template>
                        </a-container>
                    </a-stepper-content>
                    <a-stepper-content step="3" class="pa-0">
                        <a-container key="filters" container--fluid grid-list-xl class="pa-1">
                            <a-layout class="align-center mb-2">
                                <a-flex xs12>
                                   <a-card class="u-elevation-custom-1 u-rounded-corners px-3 py-2" v-if="local_project_item && request_form_data && request_form_data.project_id">
                                        <a-layout class="align-center">
                                            <a-flex xs3 class="u-flex align-center justify-center text-center flex-wrap" v-if="local_project_item && local_project_item.customer">
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 u-wfull">
                                                    <strong>Project</strong>
                                                </h3>
                                                <div class="u-flex align-center justify-center text-center">
                                                    <h3 class="subtitle-2 ma-0 grey--text text--darken-1" :title="local_project_item.title + '(' + local_project_item.customer.name + ')'">
                                                        {{ local_truncate_text(local_project_item.title, $vuetify.breakpoint.lg ? 10 : 18) }}</h3>
                                                    <h3 class="subtitle-2 ma-0 grey--text text--darken-1" :title="local_project_item.title + '(' + local_project_item.customer.name + ')'">
                                                        ({{ local_truncate_text(local_project_item.customer.name, $vuetify.breakpoint.lg ? 6 : 15) }})
                                                    </h3>
                                                </div>
                                            </a-flex>
                                            <a-divider vertical></a-divider>
                                            <a-flex xs3 class="text-center">
                                                <h3 class="subtitle-2 ma-0 mb-1 grey--text text--darken-1 u-wfull">
                                                    <strong>Date</strong>
                                                </h3>
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" v-if="local_project_item && local_project_item.customer" :title="local_project_item.customer.name">
                                                    <template v-if="request_form_data.booking_from || request_form_data.booking_to">
                                                        <span>{{ local_format_date(request_form_data.booking_from) }}</span> - <span>{{ local_format_date(request_form_data.booking_to) }}</span>
                                                    </template>
                                                </h3>
                                            </a-flex>
                                            <!-- <a-divider vertical></a-divider>
                                            <a-flex xs2 class="text-center">
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" v-if="local_project_item && local_project_item.customer" :title="local_project_item.customer.name"><strong>Account </strong>{{ local_project_item.customer.name }}</h3>
                                            </a-flex>
                                            <a-divider vertical></a-divider>
                                            <a-flex xs2 class="text-center">
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" v-if="local_project_item && local_project_item.stage" :title="local_project_item.stage.name"><strong>Stage </strong>{{ local_project_item.stage.name }}</h3>
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" v-else title="Queue"><strong>Stage </strong>Queue</h3>
                                            </a-flex> -->
                                            <a-divider vertical></a-divider>
                                            <a-flex xs3 class="text-center">
                                                <h3 class="subtitle-2 ma-0 mb-1 grey--text text--darken-1 u-wfull">
                                                    <strong>Assignee</strong>
                                                </h3>
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" v-if="local_project_item && local_project_item.assignee" :title="local_project_item.assignee">{{ local_project_item.assignee }}</h3>
                                            </a-flex>
                                            <a-divider vertical></a-divider>
                                            <a-flex xs3 class="text-center">
                                                <h3 class="subtitle-2 ma-0 mb-1 grey--text text--darken-1 u-wfull">
                                                    <strong>Location</strong>
                                                </h3>
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" :title="request_form_data.location" v-if="request_form_data.location">{{ request_form_data.location }}</h3>
                                                <h3 class="subtitle-2 ma-0 grey--text text--darken-1 text-truncate" v-else>No Location</h3>
                                            </a-flex>
                                            <template v-if="local_project_item.territories && local_project_item.territories.length">
                                                <a-divider vertical></a-divider>
                                                <a-flex shrink class="text-center pb-1" style="min-width: 260px; max-width: 260px;">
                                                    <div class="u-flex align-start justify-center flex-wrap">
                                                        <span
                                                            class="caption d-inline-block py-1 px-2 ma-0 mr-2 mb-2 u-rounded-corners"
                                                            :style="{backgroundColor: $color(territory.color, 'color_bg'), color: $color(territory.color, 'color_text')}"
                                                            v-for="territory in local_project_item.territories"
                                                            :key="territory.id"
                                                        >
                                                            {{ territory.name }}
                                                        </span>
                                                    </div>
                                                </a-flex>
                                            </template>
                                        </a-layout>
                                    </a-card>
                                </a-flex>
                                <!-- <a-flex xs12>
                                    <a-card class="u-elevation-custom-1 u-rounded-corners px-3 py-3" v-if="request_form_data && request_form_data.project_id">
                                        <a-layout class="align-center">
                                            <a-flex xs3 class="u-flex align-center justify-center"  v-if="local_project_item && local_project_item.customer">
                                                <h3 class="subheading ma-0 grey--text text--darken-1" :title="local_project_item.title + '(' + local_project_item.customer.name + ')'"><strong>Project </strong>{{ local_truncate_text(local_project_item.title, 15) }}</h3>
                                                <h3 class="subheading ma-0 grey--text text--darken-1" :title="local_project_item.title + '(' + local_project_item.customer.name + ')'">
                                                    ({{ local_truncate_text(local_project_item.customer.name, 15) }})
                                                </h3>
                                            </a-flex>
                                            <a-divider vertical></a-divider>
                                            <a-flex xs3 class="text-center">
                                                <h3 class="subheading ma-0 grey--text text--darken-1 text-truncate" v-if="local_project_item && local_project_item.customer" :title="local_project_item.customer.name"><strong>Date </strong>
                                                    <template v-if="request_form_data.booking_from || request_form_data.booking_to">
                                                        <span>{{ local_format_date(request_form_data.booking_from) }}</span> - <span>{{ local_format_date(request_form_data.booking_to) }}</span>
                                                    </template>
                                                </h3>
                                            </a-flex>
                                            <a-divider vertical></a-divider>
                                            <a-flex xs3 class="text-center">
                                                <h3 class="subheading ma-0 grey--text text--darken-1 text-truncate" v-if="local_project_item && local_project_item.assignee" :title="local_project_item.assignee"><strong>Assignee </strong> {{ local_project_item.assignee }}</h3>
                                            </a-flex>
                                            <a-divider vertical></a-divider>
                                            <a-flex xs3 class="text-center">
                                                <h3 class="subheading ma-0 grey--text text--darken-1 text-truncate" :title="request_form_data.location"><strong>Location </strong>{{ request_form_data.location }}</h3>
                                            </a-flex>
                                        </a-layout>
                                    </a-card>
                                </a-flex> -->
                            </a-layout>
                        </a-container>
                        <a-container grid-list-xl container--fluid class="pa-1">
                            <a-layout class="align-center mb-2">
                                <a-flex xs12>
                                    <a-card class="u-elevation-custom-1 u-rounded-corners px-3 py-1 d-inline-flex align-center">
                                        <span class="subtitle-2 ma-0 grey--text text--darken-2 text-truncate d-inline-block"><strong>Start Date </strong>
                                           <g-moment class="body-2 grey--text text--darken-2 ml-1" v-if="request_form_data.booking_from" :value="request_form_data.booking_from" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                        </span>
                                        <span class="mx-2"> - </span>
                                        <span class="subtitle-2 ma-0 grey--text text--darken-2 text-truncate d-inline-block"><strong>End Date </strong>
                                            <g-moment class="body-2 grey--text text--darken-2 ml-1" v-if="request_form_data.booking_from" :value="request_form_data.booking_to" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                        </span>
                                    </a-card>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-container grid-list-xl container--fluid class="pa-1 mt-0 mb-5 pb-4">
                            <a-card class="u-elevation-custom-1 pb-0 pt-0 mb-0 u-rounded-corners">
                                <a-divider></a-divider>
                                <a-layout align-center class="px-0 grey lighten-4 mx-0" style="padding: 10px 0;">
                                    <a-flex shrink xs7 class="pa-0 px-2">
                                        <a-layout align-center class="ma-0 pa-0">
                                            <a-flex shrink xs2 class="mx-0 py-0 px-2">
                                                <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-truncate">Category</h4>
                                            </a-flex>
                                            <a-flex shrink xs4 class="mx-0 py-0 px-2">
                                                <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-truncate">Name</h4>
                                            </a-flex>
                                            <a-flex shrink xs3 class="mx-0 py-0 px-2">
                                                <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-left text-truncate">OEM</h4>
                                            </a-flex>
                                            <a-flex shrink xs3 class="mx-0 py-0 px-2">
                                                <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-left text-truncate">Model #</h4>
                                            </a-flex>
                                        </a-layout>
                                    </a-flex>
                                    <a-flex shrink xs3 class="pa-0 px-2">
                                        <a-layout align-center class="ma-0 pa-0">
                                            <a-flex shrink xs6 class="mx-0 py-0 px-2">
                                                <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-left">Part #</h4>
                                            </a-flex>
                                            <a-flex shrink xs6 class="mx-0 py-0 px-2">
                                                <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-left">Serial #</h4>
                                            </a-flex>
                                        </a-layout>
                                    </a-flex>
                                    <a-flex shrink xs2 class="pa-0 px-2">
                                        <a-layout align-center class="ma-0 pa-0">
                                            <a-flex shrink xs12 class="mx-0 py-0 px-2">
                                                <h4 class="caption text-uppercase grey--text text--darken-3 font-weight-bold text-left">Location</h4>
                                            </a-flex>
                                        </a-layout>
                                    </a-flex>
                                </a-layout>
                                <a-divider></a-divider>
                                <a-layout>
                                    <a-flex class="py-0">
                                        <a-card v-for="(inventory, index) in inventory_request_list" :key="inventory + '-'+ index" class="white elevation-0">
                                            <a-card class="white ma-0 pa-0 elevation-0 py-3">
                                                <a-layout align-center class="ma-0 pa-0">
                                                    <a-flex shrink xs7 class="pa-0 px-2">
                                                        <a-layout align-center class="ma-0 pa-0">
                                                            <a-flex shrink xs2 class="pa-0 px-2">
                                                                <h4 class="body-2 text-truncate" v-if="inventory.category && inventory.category.value" :title="inventory.category.value">{{ inventory.category.value }}</h4>
                                                                <h4 class="body-2" v-else> No Category </h4>
                                                            </a-flex>
                                                            <a-flex shrink xs4 class="pa-0 px-2">
                                                                <h4 class="body-2 text-truncate" :title="inventory.name">{{ inventory.name }}</h4>
                                                            </a-flex>
                                                            <a-flex shrink xs3 class="pa-0 px-2">
                                                                <h4 class="body-2 text-left text-truncate" v-if="inventory.oem" :title="inventory.oem">{{ inventory.oem }}</h4>
                                                                <h4 class="body-2 text-left" v-else> - </h4>
                                                            </a-flex>
                                                            <a-flex shrink xs3 class="pa-0 px-2">
                                                                <h4 class="body-2 text-left text-truncate" v-if="inventory.model_no" :title="inventory.model_no">{{ inventory.model_no }}</h4>
                                                                <h4 class="body-2 text-left" v-else> - </h4>
                                                            </a-flex>
                                                        </a-layout>
                                                    </a-flex>
                                                    <a-flex shrink xs3 class="pa-0 px-2">
                                                        <a-layout align-center class="ma-0 pa-0">
                                                            <a-flex shrink xs6 class="pa-0 px-2">
                                                                <h4 class="body-2 text-left text-truncate" v-if="inventory.part_no" :title="inventory.part_no">{{ inventory.part_no }}</h4>
                                                                <h4 class="body-2 text-left" v-else> - </h4>
                                                            </a-flex>
                                                            <a-flex shrink xs6 class="pa-0 px-2">
                                                                <h4 class="body-2 text-left text-truncate" v-if="inventory.serial_no" :title="inventory.serial_no">{{ inventory.serial_no }}</h4>
                                                                <h4 class="body-2 text-left" v-else> - </h4>
                                                            </a-flex>
                                                        </a-layout>
                                                    </a-flex>
                                                    <a-flex shrink xs2 class="pa-0 px-2">
                                                        <a-layout align-center class="ma-0 pa-0">
                                                            <a-flex shrink xs12 class="pa-0 px-2">
                                                                <h4 class="body-2 text-left text-truncate" v-if="inventory.location" :title="inventory.location">{{ inventory.location }}</h4>
                                                                <h4 class="body-2 text-left" v-else> - </h4>
                                                            </a-flex>
                                                        </a-layout>
                                                    </a-flex>
                                                </a-layout>
                                            </a-card>
                                            <a-divider v-if="inventory_request_list.length - 1 !== index"></a-divider>
                                        </a-card>
                                    </a-flex>
                                </a-layout>
                            </a-card>
                        </a-container>
                    </a-stepper-content>
                </a-stepper-items>
            </a-stepper>
        </a-container>
        <a-snackbar v-model="snackbar_status" :timeout="4000" bottom center :color="snackbar_response_status === 'error' ? 'red' : ''">
            {{ snackbar_text }}
        </a-snackbar>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import loadWorkspace from '@/mixins/mixin-workspace'
import { SUsersDeck } from '@/config/config-shared-components'
import mixinSearchUsers from '@/mixins/mixin-search-users'

export default {
    mixins: [loadWorkspace, mixinSearchUsers],

    components: { SUsersDeck },

    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            inventory_book_step: 1,
            inventory_request_list: [],
            inventory_category_list: [],
            datepicker_request_booking_from: false,
            datepicker_request_booking_to: false,
            local_assignee_name: null,
            local_search_inventory: null,
            local_filter_category_label: null,
            request_form_data: {
                project_id: null,
                assignee_id: null,
                booking_from: null,
                booking_to: null,
                location: null,
                inventory_id: [],
            },
            local_project_item: {},
            request_form_data_default: {
                project_id: null,
                assignee_id: null,
                booking_from: null,
                booking_to: null,
                location: null,
                inventory_id: [],
            },
            datepicker_request_booking_from: false,
            datepicker_request_booking_to: false,
            local_project_search: null,
            local_user_search: null,
            category_mode: false,
            local_search_category: '',
            local_select_inventory_count: null,
            search_category: false,
            show_inventory_list: false,
            local_poc_loading: false,
            error_bag: {
                location: null,
                date: null
            },
            local_search_tag: '',
            search_tag: false,
            filter_mode: false,
            local_tag_filter_item: 'All',
            local_search_mode: false,
            snackbar_status: false,
            snackbar_text: '',
            snackbar_response_status: '',
            search_timeout: null,
            filter: {
                STATUS: 'filter[status_id]',
                CATEGORY: 'filter[category_id]',
                // ASSIGNEE: 'filter[assignee_id]',
                PROJECT: 'filter[project]',
                TAGS: 'filter[tags]',
                BOOKING_FROM: 'booking_from',
                BOOKING_TO: 'booking_to',
                SEARCH: 'filter[search]',
                COLUMNS: 'fields[inventories]',
                PAGINATE: 'paginate',
                PAGE: 'page',
                LOAD: 'load',
            },
            inventory_filters: {
                'filter[is_available]': 1,
                'filter[status_id]': 'ALL',
                'filter[category_id]': 'ALL',
                // 'filter[assignee_id]': 'ALL',
                'filter[project]': 'ALL',
                'filter[tags]': 'ALL',
                // 'filter[sort_order]': 'asc',
                'sort': 'name',
                'booking_from': null,
                'booking_to': null,
                'filter[search]': null,
                'fields[inventories]': null,
                // 'filter[group]': 'no_grouping',
                'paginate': 15,
                'page': 1,
                'load': null
                // 'filter[columns]': null,
            },
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
        }
    },

    mounted() {
        this.local_index()
    },

    watch: {
        search_category(val) {
            if (val) {
                setTimeout(() => this.$refs.search_category_input.ref.focus(), 500)
            }
        },
        search_tag(val) {
            if (val) {
                setTimeout(() => this.$refs.search_tag_input.ref.focus(), 500)
            }
        },
        local_search_inventory() {
            if(this.local_search_inventory && !this.local_search_inventory.length) {
                this.local_search_mode = false
            }
            if(!this.local_search_inventory && this.local_search_mode) {
                this.local_search_clear('search', null)
            }
        },
    },

    computed: {
        local_filtered_inventory_category_list() {
            const filteredArray = this.inventory_category_list.filter(list => {
                return list.value.toLowerCase().includes(this.local_search_category.toLowerCase())
            })
            return _.sortBy(filteredArray, ['value'])
        },
        local_compare_end_start_date() {
            return moment(this.request_form_data.booking_to).isBefore(this.request_form_data.booking_from)
        },
        local_check_dates_equal() {
            return this.inventory_filters[this.filter.BOOKING_FROM] !== this.request_form_data.booking_from || this.inventory_filters[this.filter.BOOKING_TO] !== this.request_form_data.booking_to ? false : true
        },
        local_filtered_tag_list() {
            return this.tag_list.filter(list => list.label.toLowerCase().includes(this.local_search_tag.toLowerCase()))
        },
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapGetters('Inventory', {
            inventory_list: 'list',
            inventory_meta: 'meta',
        }),
        ...mapGetters('InventoryBooking', {
            inventory_booking_response: 'response',
        }),
        ...mapState('Project', {
            project_list: 'list',
            project_item: 'item',
            project_filters: 'filters',
        }),
        ...mapState('Meta', {
            meta_list: 'list',
            meta_filters: 'filters',
        }),
        ...mapState('Tag', {
            tag_list: 'list',
            tag_item: 'item',
            tag_filters: 'filters',
        }),
        ...mapState('User', {
            user_list: 'list',
            user_filters: 'filters',
        }),
    },

    methods: {
        async local_index () {
            if (!this.$can('inventories.update')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.inventory_clear()

            this.local_load_user_list()
            this.local_load_meta_list()
            this.local_load_inventory_tags()

            this.project_clear()
            this.project_clear_item()
            this.project_filters.status = 'Active,Queue'
            await this.project_index({
                'filter[workspace_id]': this.current_workspace_id_mixin,
                'sort': 'title',
                'include': 'stage,customer',
                'filter[status]': 'active,queue',
                'fields[customer]': 'id,name',
                'fields[stage]': 'id,name',
                'fields[projects]': 'id,title,stage_id,customer_id',
                page: 1,
                count: 999
                // 'include': 'stage,customer,keyCollaborators.user,territories,workspace',
                // 'fields[workspace]': 'id,title',
            })

            if (!this.$route.params.id) return
            this.local_poc_loading = true
            this.request_form_data.project_id = this.$route.params.id
            this.local_select_project_detail(this.$route.params.id)
        },
        async local_load_user_list() {
            this.user_clear()
            this.user_filters.sort_field = 'name'
            this.user_index({
                include: 'role',
                'sort': 'name',
                count: 999,
                page: 1
            })
        },
        async local_load_meta_list () {
            this.meta_clear()
            this.meta_filters.active= 1
            this.meta_filters.count_relationship='category'
            await this.meta_index({
                include: 'availableCategoryCount',
                'filter[type]': 'inventory_category'
            })
            this.inventory_category_list =  _.cloneDeep(this.meta_list)
        },
        async local_select_step(step) {
            this.local_check_form_errors()
            if (this.error_bag.date) return

            this.inventory_book_step = step
            if(step === 3) return
            if (step === 2 && !this.local_check_dates_equal) {
                this.local_clear_step_filters()
            }

            if(this.request_form_data.location && !this.request_form_data.location.trim()) this.request_form_data.location = null
            if(this.inventory_filters[this.filter.BOOKING_FROM] == this.request_form_data.booking_from || this.inventory_filters[this.filter.BOOKING_TO] == this.request_form_data.booking_to) return
            this.inventory_filters[this.filter.BOOKING_FROM] = this.request_form_data.booking_from
            this.inventory_filters[this.filter.BOOKING_TO] = this.request_form_data.booking_to
            this.local_load_inventory_list()
        },
        async local_load_more_inventory() {
            this.inventory_filters[this.filter.PAGE] = this.inventory_meta.current_page + 1
            this.inventory_filters[this.filter.LOAD] = 'append'
            await this.inventory_index({
                include: 'tags,territories,category,bookings,currentBookedItems.project.customer,futureBookings',
                ...this.inventory_filters
            })
            this.inventory_filters[this.filter.LOAD] = null
        },
        async local_load_inventory_list() {
            this.local_loading = true
            this.category_mode = true
            this.show_inventory_list = true
            await this.inventory_list_clear()
            // this.inventory_filters[this.filter.GROUP] = 'no_grouping'
            this.inventory_filters[this.filter.COLUMNS] = ['id', 'name', 'part_no', 'serial_no', 'model_no', 'oem', 'location', 'category_id', 'owner_id'].join()
            this.inventory_filters[this.filter.PAGINATE] = 30
            await this.inventory_index({
                include: 'tags,territories,category,bookings,currentBookedItems.project.customer,futureBookings',
                ...this.inventory_filters
            })
            this.local_loading = false
            this.category_mode = false
        },
        local_load_inventory_tags() {
            this.tag_clear()
            this.tag_index({ 'filter[type]': 'inventory_tag' })
        },
        async local_tag_filter(filter, value, item = null) {
            this.filter_mode = true
            this.local_tag_filter_item = item
            this.inventory_filters[filter] = value
            this.inventory_filters[this.filter.PAGE] = 1
            this.inventory_filters[this.filter.PAGINATE] = 30
            this.local_load_inventory_list()
        },
        async local_search_filter(filter, value) {
            clearTimeout(this.search_timeout)
            this.inventory_total_list = []
            this.local_loading = true
            this.inventory_filters[this.filter.PAGE] = 1
            this.inventory_filters[this.filter.PAGINATE] = 30
            this.inventory_filters[filter] = value
            this.search_timeout = window.setTimeout(async () => {
                if (!this.local_search_inventory.trim()) return
                if (!this.local_search_inventory || this.local_search_inventory.trim().length <= 3) {
                    const search_error = 'Try more than 3 characters to search.'
                    this.local_snackbar_status(search_error, 'error')
                    return
                }
                await this.local_load_inventory_list()
                this.local_loading = false
                this.local_search_mode = true
            }, 1000)
        },
        async local_search_clear(filter, value) {
            this.local_loading = true
            this.inventory_filters[this.filter.PAGE] = 1
            this.inventory_filters[this.filter.PAGINATE] = 30
            this.inventory_filters[filter] = value
            await this.local_load_inventory_list()
            this.local_loading = false
            this.local_search_mode = false
        },
        local_snackbar_status(text, status) {
            this.snackbar_text = text
            this.snackbar_response_status = status
            this.snackbar_status = true
        },
        // async local_inventory_pagination() {
        //     if(this.local_page_count == this.inventory_meta.current_page) return
        //     this.local_loading = true
        //     this.inventory_filters[this.filter.PAGE] = this.inventory_meta.current_page
        //     await this.inventory_index()
        //     this.local_page_count = this.inventory_meta.current_page
        //     this.local_loading = false
        // },
        local_clear_step_filters() {
            // this.inventory_clear()
            this.inventory_request_list = []
            // this.show_inventory_list = false
            this.local_filter_category_label = null
            this.local_search_inventory = null
        },
        async local_check_form_errors() {
            if (this.request_form_data.location && this.request_form_data.location.length > 255) {
                this.error_bag.location = 'Location is too long. Maximum of 255 characters allowed.'
            } else this.error_bag.location = null

            if (this.request_form_data.booking_from === this.request_form_data.booking_to && !this.local_compare_end_start_date) {
                this.error_bag.date = 'Start & End date for Inventory Bookings cannot be same.'
            } else if (this.local_compare_end_start_date) {
                this.error_bag.date = 'Select an end date that occurs after start date'
            } else this.error_bag.date = null
        },
        async local_category_filter(filter, value, label, inventory_count) {
            this.show_inventory_list = true
            this.local_loading = true
            this.category_mode = true
            if(filter === this.filter.CATEGORY) this.local_filter_category_label = label
            this.local_select_inventory_count = inventory_count
            this.inventory_filters[this.filter.PAGINATE] = this.inventory_meta.total_count
            this.inventory_filters[this.filter.PAGE] = 1
            this.local_filter_category_label = label
            if(value === 'ALL' || value === 'NONE') this.inventory_filters[this.filter.PAGINATE] = 30
            this.inventory_filters[filter] = value
            await this.local_load_inventory_list()
            this.category_mode = false
            this.local_loading = false
        },
        async local_select_project_detail(id) {
            if(!id) return
            if (id && this.$route.params.id && id !== this.$route.params.id) {
                this.$route.params.id = null
                this.$router.push({ name: 'inventory-book' })
            }

            this.local_project_item = {}
            this.local_poc_loading = true
            await this.project_clear_item()
            await this.project_show({
                id,
                mode: 'update-item',
                params: {
                    'filter[workspace_id]': this.current_workspace_id_mixin,
                    'include': 'stage,customer,keyCollaborators.user,territories,workspace',
                    'fields[workspace]': 'id,title',
                    'fields[customer]': 'id,name',
                    'fields[stage]': 'id,name',
                    'fields[projects]': 'id,title,start_date,due_date,deal_amount,stage_id,customer_id,workspace_id',
                    'fields[territories]': 'territories.id,territories.name,territories.color',
                    'fields[keyCollaborators.user]': 'id,name,color,initial,avatar',
                }
            })
            // await this.project_select({id: id})
            this.local_project_item = this.project_item
            this.local_poc_loading ? this.local_poc_loading = false : null
            this.local_project_item.assignee = this.local_assignee_name
        },
        local_select_user_detail(user) {
            this.local_assignee_name = user.name
            this.local_project_item.assignee = this.local_assignee_name
        },
        async local_inventory_booking() {
            this.request_form_data.inventory_id = []
            this.inventory_request_list.forEach((item, index) => {
                this.request_form_data.inventory_id.push(item.id)
            });

            this.request_form_data.assignee_id = this.request_form_data.assignee_id.id
            await this.inventory_booking_store({ data : this.request_form_data })
            if (this.inventory_booking_response.status === 'success') {
                this.$router.push({name: 'inventory'})
            }
        },
        local_timeline_validation() {
            return this.request_form_data.project_id != null && this.request_form_data.assignee_id != null && this.request_form_data.booking_from != null && this.request_form_data.booking_to != null
        },
        local_inventory_request_list (data) {
            const index = this.inventory_request_list.findIndex(item => item.id === data.id)

            if (index !== -1) {
                this.inventory_request_list.splice(index, 1)
                return
            }

            !data ? this.inventory_request_list = [] : this.inventory_request_list.push(data)
        },
        local_request_form_clear(type) {
            if (type === 'location') {
                !this.request_form_data.location ? this.request_form_data.location = null : null
                return
            }
            this.$set(this.request_form_data, type, null)
        },
        local_remove_inventory(id) {
            const index = this.inventory_request_list.findIndex(item => item.id === id)
            this.inventory_request_list.splice(index, 1)
        },
        local_is_inventory_added(id) {
            if (!this.inventory_request_list.length) return false

            const index = this.inventory_request_list.findIndex(item => item.id === id)
            return index >= 0 ? true : false
        },
        local_clear_all_inventories() {
            this.inventory_request_list = []
        },
        local_format_date(date, range = false, from_date = null) {
            if (range && !from_date) return moment(new Date()).format('YYYY-MM-DD')
            if (range && from_date) return moment(from_date).format('YYYY-MM-DD')

            return date ? moment(date).format('MMM D, YYYY') : null
        },
        local_truncate_text(text, range) {
            if (!text) return
            return text.length > range ? text.slice(0, range) + '...' : text
        },
        ...mapActions('Inventory', {
            inventory_index: 'index',
            inventory_clear: 'clear',
            inventory_list_clear: 'list_clear',
        }),
        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
            meta_clear_item: 'clear_item',
        }),
        ...mapActions('Project', {
            project_index: 'index_slim',
            project_show: 'show',
            project_select: 'select',
            project_clear: 'clear',
            project_clear_item: 'clear_item',
        }),
        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_clear: 'clear',
            tag_clear_item: 'clear_item',
            tag_custom_status_clear: 'clear_custom_status'
        }),
        ...mapActions('User', {
            user_index: 'index',
            user_clear: 'clear',
        }),
        ...mapActions('InventoryBooking', {
            inventory_booking_store: 'store',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .c-badge {
        border-radius: 4px;
        display: inline-block;
    }
</style>

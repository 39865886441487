import store from '../models'

async function guardConfidenceScore(to, from, next) {
    if (!store.state.User.self.id) {
        await store.dispatch('User/me')
    }

    const { scope, organization: { flag_confidence_score } } = store.state.User.self
    if (scope === 'internal') {
        return flag_confidence_score === 1 ? next() : next('/page-not-found')
    } else {
        next('/page-not-found')
    }
}

export default guardConfidenceScore

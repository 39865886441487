<template>
    <!-- <h2 class="text-center pa-5">404 Not Found</h2> -->
    <a-main>
        <a-container grid-list-xl v-if="!local_loading">
            <a-layout align-center justify-center>
                <a-flex xs4>
                    <a-responsive class="py-4 my-4">
                        <p class="text-center"><a-icon size="128" color="grey lighten-1">error_outline</a-icon></p>
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-2 mb-2 text-uppercase">Page Not Found</h3>
                        <p class="text-center"><a-btn :to="{name: 'dashboard'}" small color="grey darken-1" class="elevation-0" dark>Go to Dashboard</a-btn></p>
                    </a-responsive>
                </a-flex>
            </a-layout>
        </a-container>
    </a-main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data () {
        return {
            local_loading: true
        }
    },

    mounted () {
        const { redirect } = this.$route.query
        if (!this.user_self || !this.user_self.id) {
            return this.$router.replace(`/login${ redirect? '?redirect=' + this.$route.query.redirect : '' }`)
        }
        this.local_loading = false
    },

    computed: {
        ...mapGetters('User', {
            user_self: 'self',
        }),
    }
}
</script>

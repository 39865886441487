import generateBaseUrl from '@/config/config-api'
const axios = generateBaseUrl(process.env.VUE_APP_CORE_API_URL, '')
const endpoint = '/auth'

export default {
    namespaced: true,
    state: {
        list: [],
        response: {},
        role: {},
        user: null,
        organization: null,
        defaults: {
            list: [],
            response: {},
        },
    },

    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ORG_DATA(state, payload) {
            state.organization = payload
        },
        UPDATE_ROLE_PERMISSIONS(state, payload) {
            state.role = payload
        },
        LOGGED_USER(state, payload) {
            state.user = payload
        },
        CLEAR_RESPONSE(state) {
            state.response = {};
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },

    actions: {
        async userPermissions(context, payload) {
            const params = { 'fields[roles]': 'id,name,description,source_id' }
            try {
                const { data: { data: response } } = await axios.get(endpoint + '/user/role', params)
                context.commit('UPDATE_ROLE_PERMISSIONS', response);
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                const status = error && error.response ? error.response.status : null
                context.commit('THROW_RESPONSE', { text: status, status: 'error' });
            }
        },

        async login(context, payload) {
            try {
                const { data: { data: response } } = await axios.post(endpoint + '/login', payload)
                // await axios.post(endpoint + '/validate?token=' + response.token)
                // await axios.get(endpoint + '/user')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                const status = error && error.response ? error.response.status : null
                context.commit('THROW_RESPONSE', { text: status, status: 'error', server: error.response.data });
            }
        },

        async verify(context, payload) {
            try {
                await axios.get(endpoint + '/verify')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                const status = error && error.response ? error.response.status : null
                context.commit('THROW_RESPONSE', { text: status, status: 'error' });
            }
        },

        async validate(context, payload) {
            try {
                await axios.post(endpoint + '/validate?token=' + payload)
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                const status = error && error.response ? error.response.status : null
                context.commit('THROW_RESPONSE', { text: status, status: 'error' });
            }
        },

        async getOrganization(context, payload) {
            const axios = generateBaseUrl(process.env.VUE_APP_CORE_API_URL, 'system')
            try {
                const { data: { data: response } } = await axios.get('/organizations/self')
                context.commit('UPDATE_ORG_DATA', response);
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                const status = error && error.response ? error.response.status : null
                context.commit('THROW_RESPONSE', { text: status, status: 'error' });
            }
        },

        async loggedUser(context, payload) {
            try {
                const { data: { data: response } } = await axios.get(endpoint + '/user')
                context.commit('LOGGED_USER', response);
                context.dispatch('User/update_user_self', response, { root: true })
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                const status = error && error.response ? error.response.status : null
                context.commit('THROW_RESPONSE', { text: status, status: 'error' });
            }
        },

        async logout(context, payload) {
            try {
                await axios.post(endpoint + '/logout')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                const status = error && error.response ? error.response.status : null
                context.commit('THROW_RESPONSE', { text: status, status: 'error' });
            }
        },

        async clear(context, payload) {
            context.commit('THROW_RESPONSE', {});
        }
    },

    getters: {
        response: (state) => state.response,
        organization: (state) => state.organization,
        user: (state) => state.user,
    }
}

// import RingingBell from '../assets/sounds/ringing-bell.mp3'
import Lightsaber from '../assets/sounds/lightsaber.mp3'
// import SoftDing from '../assets/sounds/soft-ding.mp3'
import BaseDing from '../assets/sounds/base-ding.mp3'
import Chirrup from '../assets/sounds/chirrup.mp3'
import Flubber from '../assets/sounds/flubber.mp3'
import KnockTone from '../assets/sounds/knock-tone.mp3'
import DoubleTone from '../assets/sounds/double-tone.mp3'
import Blink from '../assets/sounds/blink.mp3'
import GamePlay from '../assets/sounds/game-play.mp3'

const SOUNDS = {
    'blink': Blink,
    'game-play': GamePlay,
    // 'ringing-bell': RingingBell,
    'lightsaber': Lightsaber,
    // 'soft-ding': SoftDing,
    'base-ding': BaseDing,
    'chirrup': Chirrup,
    'flubber': Flubber,
    'knock-tone': KnockTone,
    'double-tone': DoubleTone,
}

/** Check whether users browser supports desktop notification */
class ConfigNotification {
    #defaultVisibility = 'default'
    #defaultTag = ''
    #defaultBody = ''
    #defaultTitle = 'John Doe update Project Stage'
    #defaultIcon = 'https://www.success.app/wp-content/uploads/2021/04/cropped-favicon-192x192.png'
    #defaultSound = null

    /** Check users browser supports desktop notification */
    get hasBrowserSupport () {
        return !!(window?.Notification)
    }

    /** Check user has granted permission to display desktop notification */
    get hasPermission () {
        if (!this.hasBrowserSupport) return false
        return Notification.permission === 'granted'
    }

    /** Check user has denied permission to display desktop notification */
    get hasDeniedPermission () {
        if (!this.hasBrowserSupport) return true
        return ['denied'].includes(Notification.permission)
    }

    /** Check user has denied permission to display desktop notification */
    get isIncognito () {
        if (!this.hasBrowserSupport) return true
        return ['default'].includes(Notification.permission)
    }

    /** Request permission to display desktop notification from users */
    requestPermission () {
        if (!this.hasBrowserSupport) return
        if (this.hasPermission || this.hasDeniedPermission) return

        return new Promise((resolve, reject) => {
            Notification.requestPermission()
                .then(permission => {
                    if (permission === 'granted') {
                        this.send()
                        return resolve(true)
                    }

                    console.log('User blocked notifications')
                    reject(false)
                })
                .catch(err => {
                    console.log('Notification Permission Error: ', err)
                    reject(false)
                })
        })
    }

    /**
        *  Trigger desktop notification
        *  @param {Object} notificationParams Message to send as notification
        *  @param {string} notificationParams.title Title of the notification
        *  @param {string} notificationParams.body Body of the notification
        *  @param {string} notificationParams.icon Icon of the notification
        *  @param {string} notificationParams.tag Tag to replace similar notification
        */
    send (notificationParams) {
        if (!this.hasPermission) return
        this.#setupParams(notificationParams)

        const notification = new Notification(this.#defaultTitle, {
            icon: this.#defaultIcon,
            body: this.#defaultVisibility === 'private' ? '' : (this.#defaultBody || ''),
            tag: this.#defaultTag || ''
        })

        if (this.#defaultSound) {
            const sound = new Audio(this.#defaultSound)
            notification.addEventListener('show', () => {
                sound.play()
                    .then(res => console.log('Sound Play: ', res))
                    .catch(err => console.log('Sound Error: ', err))
            })
        }
    }

    /**
        *  Construct message properties to display as desktop notification
        *  @param {Object} notificationParams Message to send as notification
        *  @param {string} notificationParams.title Title of the notification
        *  @param {string} notificationParams.body Body of the notification
        *  @param {string} notificationParams.icon Icon of the notification
        *  @param {string} notificationParams.tag Tag to replace similar notification
        *  @param {string} notificationParams.sound Sound of the notification
        *  @param {string} notificationParams.visibility Visibility of the notification
    */
    #setupParams (notificationParams) {
        if (!notificationParams) return

        this.#defaultTitle = notificationParams?.title || this.#defaultTitle
        this.#defaultBody = notificationParams?.body || this.#defaultBody
        this.#defaultIcon = notificationParams?.icon || this.#defaultIcon
        this.#defaultTag = notificationParams?.tag || this.#defaultTag
        this.#defaultSound = notificationParams?.sound || this.#defaultSound
        this.#defaultVisibility = notificationParams?.visibility || this.#defaultVisibility
    }

    /** Play notification sound */
    playSound () {
        if (!this.#defaultSound) return

        new Audio(this.#defaultSound)
            .play()
            .then(res => console.log('Audio - Sound Play: ', res))
            .catch(err => console.log('Audio - Sound Error: ', err))
    }

    /** Title to display in desktop notification */
    visibility (value) {
        this.#setupParams({ visibility: value || 'default' })
        return this
    }

    /** Title to display in desktop notification */
    title (title) {
        this.#setupParams({ title })
        return this
    }

    /** Body to display in desktop notification */
    body (body) {
        this.#setupParams({ body })
        return this
    }

    /** Icon to display in desktop notification */
    icon (icon) {
        this.#setupParams({ icon })
        return this
    }

    /** Tag to group similar desktop notifications */
    tag (tag) {
        this.#setupParams({ tag })
        return this
    }

    /**
        *  Sound of the desktop notification
        *  @param {Object} sound Message to send as notification
        *  @param {boolean} sound.mute Mute the notification sound
        *  @param {string} sound.track Sound track for the notification
    */
    sound (sound) {
        const track = sound?.track ? SOUNDS[sound?.track] : null
        this.#setupParams({ sound: sound.mute ? null : track  })
        return this
    }
}

export const notification = new ConfigNotification()

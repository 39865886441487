<template>
    <a-main>
        <div class="u-flex-center-y mb-4">
            <div>
                <h2 class="md-heading-5 primary--text">Import use case from templates</h2>
                <p class="md-subtitle-2 grey--text text--darken-1 mb-0">Search and import use cases from the templates</p>
            </div>
            <a-spacer></a-spacer>
            <a-btn depressed color="grey darken-2" class="grey lighten-2 u-rounded-corners" text :to="{name: 'projects-usecases'}" exact>
                Cancel
            </a-btn>
        </div>

        <div>
            <a-stepper v-model="usecase_group_import_step" class="elevation-0 transparent u-overflow-x u-overflow-y">
                <a-stepper-header class="elevation-0 px-1">
                    <a-stepper-step :complete="usecase_group_import_step > 1" class="pl-0" step="1">Select Template</a-stepper-step>
                    <a-divider class="grey lighten-2"></a-divider>
                    <a-stepper-step :complete="usecase_group_import_step > 2" step="2">Select Use Cases</a-stepper-step>
                    <a-divider class="grey lighten-2"></a-divider>
                    <a-stepper-step :complete="usecase_group_import_step > 3" class="pr-0" step="3">Import Use Cases</a-stepper-step>
                </a-stepper-header>
                <a-stepper-items>
                    <a-stepper-content step="1" class="pa-0">
                        <a-card flat class="c-search-inventory u-border u-rounded-corners mb-1" style="padding-bottom: 2px;">
                            <a-text-field
                                v-model="local_search_usecase_group"
                                @input="local_search_templates"
                                placeholder="Search by title or tag"
                                v-test-input.search
                                solo flat hide-details dense clearable
                            >
                                <template #prepend-inner>
                                    <a-sheet min-width="28">
                                        <a-progress-circular width="3" size="20" indeterminate color="orange darken-2" v-if="search_loading"></a-progress-circular>
                                        <a-icon size="24" color="grey" v-else>search</a-icon>
                                    </a-sheet>
                                </template>
                            </a-text-field>
                        </a-card>
                        <div class="u-flex-center-y my-5">
                            <div>
                                <p class="md-body-1 primary--text text--darken-2 mr-3 mb-0"  v-if="usecase_group_request_list && usecase_group_request_list.length">{{ usecase_group_request_list.length }} {{ usecase_group_request_list.length === 1 ? 'Template' : 'Templates' }} Selected</p>
                                <p class="md-body-1 mr-3 mb-0 grey--text text--darken-2 u-opacity-40" v-else>No Templates Selected</p>
                            </div>
                            <a-spacer></a-spacer>
                            <div class="mr-3">
                                <p class="md-body-1 primary--text text--darken-2 mb-0 u-cursor-pointer" v-if="usecase_group_request_list && usecase_group_request_list.length" @click="flag_show_group_selected = !flag_show_group_selected">
                                    <a-icon left class="mr-1" :color="flag_show_group_selected ? 'grey' : 'blue'" size="26">{{ flag_show_group_selected ? 'toggle_off' : 'toggle_on' }}</a-icon>
                                    Show Only Selected
                                </p>
                                <p class="md-body-1 mb-0 grey--text text--darken-2 u-opacity-40" v-else>
                                    <a-icon left class="mr-1" :color="flag_show_group_selected ? 'grey' : 'blue'" size="26">{{ flag_show_group_selected ? 'toggle_off' : 'toggle_on' }}</a-icon>
                                    Show Only Selected
                                </p>
                            </div>
                            <div>
                                <a-btn depressed color="primary" :disabled="!local_loading && usecase_group_request_list.length === 0" v-test-btn.continue class="u-rounded-corners" :loading="local_loading" @click="local_select_usecase_import_step(2)">Continue</a-btn>
                            </div>
                        </div>

                        <!-- Loading -->
                        <a-responsive class="my-4" v-if="local_loading">
                            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1">Fetching Template Groups..</h3>
                        </a-responsive>

                        <!-- No data found message -->
                        <a-responsive class="py-4" v-if="!local_loading && local_render_group_list.length === 0">
                            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                            <p class="md-body-2 text-center mb-0 grey--text text--darken-1">There is no data to fetch right now.</p>
                        </a-responsive>

                        <a-card v-for="(usecase_group, index) in local_render_group_list" :key="index" flat class="mb-6 u-relative u-rounded-corners-lg u-border">
                            <a-card-text class="pa-0 u-cursor-pointer" @click="local_usecase_group_request_list(usecase_group)">
                                <template v-if="flag_show_group_selected || !flag_show_group_selected && local_is_usecase_group_added(usecase_group.id)">
                                    <a-container container--fluid grid-list-xl>
                                        <a-layout align-center wrap>
                                            <a-flex xs12 shrink pa-0>
                                                <div class="pa-0 u-wfull">
                                                    <div class="u-flex pa-5">
                                                        <div class="mr-2">
                                                            <v-checkbox
                                                                color="blue darken-2"
                                                                class="ma-0 pa-0"
                                                                :input-value="local_is_usecase_group_added(usecase_group.id)"
                                                                v-test-input.usecase
                                                                v-test-id="usecase_group.id"
                                                                hide-details dense
                                                            >
                                                            </v-checkbox>
                                                        </div>
                                                        <div>
                                                            <h2 class="md-subtitle-1 primary--text text--darken-3 mb-2" style="margin-top: 1px; font-size: 1.25rem">
                                                                {{ usecase_group.name  }}
                                                            </h2>
                                                            <p class="md-body-2 mb-0 grey--text text--darken-2">
                                                                <span>{{ usecase_group.template_usecases_count  }} Use Cases<span class="mx-2"> </span></span>
                                                                <span>{{ usecase_group.template_success_criteria_count }} Success Criteria<span class="mx-2"> </span></span>
                                                                <span>{{ usecase_group.template_testcase_count  }} Test Cases<span class="mx-2"> </span></span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <template v-if="usecase_group.tags.length">
                                                        <a-divider class="grey lighten-3"></a-divider>
                                                        <div class="pa-5 pt-2">
                                                            <div class="mt-2 align-center">
                                                                <template v-for="(tag, index) in usecase_group.tags">
                                                                    <g-tags :tag="tag" type="Usecase" hide-clear-icon :key="index"></g-tags>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </template>
                            </a-card-text>
                        </a-card>
                    </a-stepper-content>
                    <a-stepper-content step="2" class="pa-0">
                        <a-card flat class="c-search-inventory u-border u-rounded-corners mb-1" style="padding-bottom: 2px;">
                            <a-text-field
                                v-model="local_selected_group_search"
                                @input="local_search_usecases"
                                placeholder="Search by title or tag"
                                solo flat hide-details dense clearable
                                v-test-input.search
                            >
                                <template #prepend-inner>
                                    <a-sheet min-width="28">
                                        <a-progress-circular width="3" size="20" indeterminate color="orange darken-2" v-if="search_loading"></a-progress-circular>
                                        <a-icon size="24" color="grey" v-else>search</a-icon>
                                    </a-sheet>
                                </template>
                            </a-text-field>
                        </a-card>
                        <div class="u-flex-center-y my-5">
                            <div class="u-flex">
                                <p class="md-body-1 primary--text text--darken-2 mr-3 mb-0" v-if="usecase_request_list && usecase_request_list.length">{{ usecase_request_list.length }} {{ usecase_request_list.length === 1 ? 'Use Case' : 'Use Cases' }} Selected</p>
                                <p class="md-body-1 mr-3 mb-0 grey--text text--darken-2 u-opacity-40" v-else>No Use Case Selected</p>
                            </div>
                            <a-spacer></a-spacer>
                            <div class="mr-3">
                                <p class="md-body-1 primary--text text--darken-2 mb-0 u-cursor-pointer" v-if="usecase_request_list && usecase_request_list.length" @click="flag_show_usecase_selected = !flag_show_usecase_selected">
                                    <a-icon left class="mr-1" :color="flag_show_usecase_selected ? 'grey' : 'blue'" size="26">{{ flag_show_usecase_selected ? 'toggle_off' : 'toggle_on' }}</a-icon>
                                    Show Only Selected
                                </p>
                                <p class="md-body-1 mb-0 grey--text text--darken-2 u-opacity-40" v-else>
                                    <a-icon left class="mr-1" :color="flag_show_usecase_selected ? 'grey' : 'blue'" size="26">{{ flag_show_usecase_selected ? 'toggle_off' : 'toggle_on' }}</a-icon>
                                    Show Only Selected
                                </p>
                            </div>
                            <div>
                                <a-btn color="primary" depressed class="u-rounded-corners mr-3" v-test-btn.back @click="local_select_usecase_import_step(1)">Go Back</a-btn>
                                <a-btn color="primary" depressed :disabled="!local_loading_selected_template_group && usecase_request_list.length === 0" class="u-rounded-corners" :loading="local_loading_selected_template_group" v-test-btn.continue @click="local_select_usecase_import_step(3)">Continue</a-btn>
                            </div>
                        </div>

                        <!-- Loading -->
                        <a-responsive class="my-4" v-if="local_loading_selected_template_group">
                            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1">Fetching Use Cases..</h3>
                        </a-responsive>

                        <a-responsive class="py-4" v-if="!local_loading_selected_template_group && local_render_selected_group_list.length === 0">
                            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                            <p class="md-body-2 text-center mb-0 grey--text text--darken-1">There is no data to fetch right now.</p>
                        </a-responsive>
                        <a-card flat v-for="(usecase_group_request,index) in local_render_selected_group_list" :key="usecase_group_request.id" class="mb-6 u-relative u-rounded-corners-lg u-border">
                            <a-card-text class="pa-0" v-if="flag_show_usecase_selected || !flag_show_usecase_selected && local_is_usecase_group_request_added(usecase_group_request.id)">
                                <a-container container--fluid grid-list-xl>
                                    <a-layout align-center wrap class="u-cursor-pointer">
                                        <a-flex xs12 shrink pa-0>
                                            <div class="pa-0 u-wfull">
                                                <div class="u-flex pa-5">
                                                    <div class="mr-2">
                                                        <a-checkbox
                                                            color="blue darken-2"
                                                            class="ma-0 pa-0 mr-1"
                                                            :input-value="local_is_usecase_group_request_added(usecase_group_request.id) && !local_accordion_request_usecase_group_status(index)"
                                                            @change="local_add_usecase_request_list(usecase_group_request,$event,index)"
                                                            v-test-input.usecase
                                                            v-test-id="usecase_group_request.id"
                                                            hide-details dense
                                                        >
                                                        </a-checkbox>
                                                    </div>
                                                    <div class="u-wfull" @click="local_accordion_request_usecase_toggle(index)">
                                                        <h2 class="md-subtitle-1 primary--text text--darken-3 mb-2" style="margin-top: 1px; font-size: 1.25rem">
                                                            {{ usecase_group_request.name  }}
                                                        </h2>
                                                        <p class="md-body-2 mb-0 grey--text text--darken-2">
                                                            <span>{{ usecase_group_request.template_usecases_count  }} Use Cases<span class="mx-2"> </span></span>
                                                            <span>{{ usecase_group_request.template_success_criteria_count }} Success Criteria<span class="mx-2"> </span></span>
                                                            <span>{{ usecase_group_request.template_testcase_count  }} Test Cases<span class="mx-2"> </span></span>
                                                        </p>
                                                    </div>
                                                    <div @click="local_accordion_request_usecase_toggle(index)">
                                                        <a-btn small icon class="ma-0 grey lighten-1" width="24" height="24">
                                                            <a-icon v-if="local_accordion_request_usecase_status(index, usecase_group_request.template_usecases_count)" size="18" color="white">expand_less</a-icon>
                                                            <a-icon v-else size="18" color="white">expand_more</a-icon>
                                                        </a-btn>
                                                    </div>
                                                </div>
                                                <template v-if="usecase_group_request.tags.length && !local_accordion_request_usecase_status(index, usecase_group_request.template_usecases_count)">
                                                    <a-divider class="grey lighten-3"></a-divider>
                                                    <div class="pa-5 pt-2">
                                                        <div class="mt-2 align-center">
                                                            <template v-for="(tag, index) in usecase_group_request.tags">
                                                                <g-tags :tag="tag" type="Usecase" hide-clear-icon :key="index"></g-tags>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                                <a-divider></a-divider>
                                <a-container container--fluid grid-list-xl class="pt-4 pb-1 px-0 grey lighten-5" v-if="usecase_group_request.template_usecases && local_accordion_request_usecase_status(index, usecase_group_request.template_usecases_count)">
                                    <a-responsive v-if="usecase_group_request.template_usecases.length === 0" class="px-5 pb-4">
                                        <p class="md-body-2 text-center grey--text text--darken-1 mb-0">
                                            There are no use cases added to this template. <br>
                                            <router-link tag="a" target="_blank" class="blue--text text--darken-2 grey lighten-3 d-inline-block px-2 py-1 u-rounded-corners mt-2" :to="{ name: 'master-templates-system-list-usecases' }">
                                                <a-icon size="18" class="u-icon-nudge-xs" color="blue darken-2">add</a-icon>
                                                Add Use Case
                                            </router-link>
                                        </p>
                                    </a-responsive>
                                    <template v-for="(usecase, index) in usecase_group_request.template_usecases">
                                        <template v-if="flag_show_usecase_selected || !flag_show_usecase_selected && local_is_usecase_added(usecase.id)">
                                            <a-divider class="grey lighten-3" :key="index" v-if="index !== 0"></a-divider>
                                            <a-layout class="u-cursor-pointer px-5" :class="[index !== 0 ? 'my-4' : 'mt-0 mb-4']" align-center :key="usecase.id" @click="local_usecase_request_list(usecase)">
                                            <!-- <a-layout class="u-cursor-pointer px-5 my-3" align-center :key="usecase.id" @click="local_usecase_request_list(usecase)"> -->
                                                <a-flex xs11 py-0>
                                                    <div class="u-flex pa-0">
                                                        <a-checkbox
                                                            color="blue darken-2"
                                                            class="ma-0 pa-0 mr-1"
                                                            :input-value="local_is_usecase_added(usecase.id)"
                                                            v-test-input.usecase
                                                            v-test-id="usecase.id"
                                                            hide-details dense
                                                        >
                                                        </a-checkbox>
                                                        <div class="u-wfull ml-3">
                                                            <h2 class="md-subtitle-1 primary--text text--darken-2 mb-2">
                                                                {{ usecase.title  }}
                                                            </h2>
                                                            <p class="md-body-2 mb-0 grey--text text--darken-2">
                                                                <span class="md-body-2 mb-0 orange--text text--darken-4" v-if="usecase.visibility === 'external'">
                                                                    <a-icon size="18" color="orange" class="mr-1 u-icon-nudge" style="top: -1px;">public</a-icon>Public<span class="mx-2"></span>
                                                                </span>
                                                                <span>{{ usecase.template_success_criteria_count }} Success Criteria<span class="mx-2"> </span></span>
                                                                <span>{{ usecase.template_testcase_count }} Test Cases<span class="mx-2"> </span></span>
                                                            </p>
                                                            <div class="mt-2 align-center" v-if="usecase.tags && usecase.tags.length">
                                                                <template v-for="(tag) in usecase.tags">
                                                                    <g-tags :tag="tag" type="Usecase" hide-clear-icon :key="tag.id"></g-tags>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a-flex>
                                            </a-layout>
                                        </template>
                                    </template>
                                </a-container>
                            </a-card-text>
                        </a-card>
                    </a-stepper-content>

                    <a-stepper-content step="3" class="pa-0">
                        <div class="u-flex align-center pt-1 pb-4 px-1">
                            <p v-if="template_usecase_list" class="md-body-1 primary--text text--darken-2 mr-3 mb-0">{{ template_usecase_list.length }} {{ template_usecase_list.length === 1 ? 'Use Case' : 'Use Cases' }} will be imported</p>
                            <a-spacer></a-spacer>
                            <div>
                                <a-btn color="primary" depressed class="u-rounded-corners mr-3" v-test-btn.back @click="local_select_usecase_import_step(2, true)">Go Back</a-btn>
                                <a-btn color="primary" depressed :disabled="!local_usecase_loading && template_usecase_list.length === 0" class="u-rounded-corners" :loading="local_usecase_loading || local_usecase_import_loading" v-test-btn.save @click="local_template_usecases_import()">Import</a-btn>
                            </div>
                        </div>
                        <a-responsive class="my-4" v-if="local_usecase_loading">
                            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Selected Use Cases..</h3>
                        </a-responsive>
                        <div class="px-1">
                            <a-card flat v-for="(usecase_request, index) in template_usecase_list" :key="index" class="my-3 mb-6 u-relative u-rounded-corners-lg u-border">
                                <div v-if="usecase_request.visibility === 'external'" class="c-usecase-card-visibility"></div>
                                <div>
                                    <a-container class="u-cursor-pointer" container--fluid grid-list-xl @click="usecase_request.template_success_criteria_count === 0 && usecase_request.template_testcase_count === 0 ? '' : local_accordion_selected_usecase_toggle(index)">
                                        <a-layout align-center wrap>
                                            <a-flex xs12 shrink pa-0>
                                                <div class="pa-0 u-wfull">
                                                    <div class="u-flex pa-5">
                                                        <div class="u-wfull">
                                                            <h2 class="md-subtitle-1 mr-2 mb-2 font-weight-medium primary--text text--darken-2">
                                                                {{ usecase_request.title  }}
                                                            </h2>
                                                            <p class="md-body-2 mb-0 grey--text text--darken-2">
                                                                <span>{{ usecase_request.template_success_criteria_count }} Success Criteria<span class="mx-2"> </span></span>
                                                                <span>{{ usecase_request.template_testcase_count  }} Test Cases<span class="mx-2"> </span></span>
                                                            </p>
                                                        </div>
                                                        <div v-if="usecase_request.template_success_criteria_count || usecase_request.template_testcase_count">
                                                            <a-btn small icon class="ma-0 grey lighten-1" width="24" height="24">
                                                                <a-icon v-if="local_accordion_selected_usecase_status(index)" size="18" color="white">expand_less</a-icon>
                                                                <a-icon v-else size="18" color="white">expand_more</a-icon>
                                                            </a-btn>
                                                        </div>
                                                    </div>
                                                    <template v-if="usecase_request.tags.length">
                                                        <a-divider class="grey lighten-3"></a-divider>
                                                        <div class="px-5 py-3">
                                                            <div class="align-center">
                                                                <template v-for="(tag, index) in usecase_request.tags">
                                                                    <g-tags :tag="tag" type="Usecase" hide-clear-icon :key="index"></g-tags>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                    <a-divider></a-divider>
                                    <a-container container--fluid grid-list-xl v-if="local_accordion_selected_usecase_status(index) && (usecase_request.template_success_criteria_count !== 0 || usecase_request.template_testcase_count !== 0)" class="grey lighten-5 pa-0 pb-0">
                                        <template v-if="usecase_request.template_success_criteria_count !== 0">
                                            <a-layout align-center class="px-5 mt-0">
                                                <a-flex xs12>
                                                    <p class="text-uppercase md-body-2 ma-0 font-weight-bold grey--text text--darken-1 u-flex align-center">
                                                        <span>Success criteria</span>
                                                        <a-avatar size="22" color="primary lighten-3" class="ml-3">
                                                            <p class="white--text text--darken-2 ma-0 font-weight-bold md-caption">{{ usecase_request.template_success_criteria_count }}</p>
                                                        </a-avatar>
                                                    </p>
                                                </a-flex>
                                            </a-layout>
                                            <a-divider></a-divider>
                                            <template v-for="success_criteria in usecase_request.template_success_criteria">
                                                <a-layout align-center :key="success_criteria.index" class="px-5">
                                                    <a-flex shrink>
                                                        <h2 class="body-2 primary--text text--darken-2">
                                                            {{ success_criteria.title }}
                                                        </h2>
                                                    </a-flex>
                                                </a-layout>
                                                <a-divider :key="success_criteria.index"></a-divider>
                                            </template>
                                        </template>
                                        <template v-if="usecase_request.template_testcase_count !== 0">
                                            <a-layout align-center class="px-5 mt-0">
                                                <a-flex xs12>
                                                    <p class="text-uppercase md-body-2 ma-0 font-weight-bold grey--text text--darken-1 u-flex align-center">
                                                        <span>Test Cases</span>
                                                        <a-avatar size="22" color="primary lighten-3" class="ml-3">
                                                            <p class="white--text text--darken-2 ma-0 font-weight-bold md-caption">{{ usecase_request.template_testcase_count }}</p>
                                                        </a-avatar>
                                                    </p>
                                                </a-flex>
                                            </a-layout>
                                            <a-divider></a-divider>
                                            <template v-for="testcase in usecase_request.template_testcase">
                                                <a-layout align-center :key="testcase.index" class="px-5">
                                                    <a-flex shrink>
                                                        <h2 class="body-2 primary--text text--darken-2">
                                                            {{ testcase.title }}
                                                        </h2>
                                                    </a-flex>
                                                </a-layout>
                                                <a-divider :key="testcase.index"></a-divider>
                                            </template>
                                        </template>
                                    </a-container>
                                </div>
                            </a-card>
                        </div>
                    </a-stepper-content>
                </a-stepper-items>
            </a-stepper>
        </div>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: "Usecases",
    data() {
        return {
            usecase_group_import_step: 1,
            local_search_usecase_group: '',
            local_selected_group_search: '',
            usecase_group_request_list: [],
            usecase_request_list: [],
            usecase_request_ids: [],
            accordion_selected_usecase_list: [],
            accordion_request_usecase_list: [],
            accordion_usecases_list: [],
            flag_show_group_selected: true,
            flag_show_usecase_selected: true,
            local_loading: false,
            local_loading_selected_template_group: false,
            local_usecase_loading: false,
            local_usecase_import_loading: false,
            local_template_group_list: [],
            local_selected_template_group_list: [],
            local_render_selected_group_list: [],
            local_render_group_list: [],
            search_timeout: null,
            search_loading: false,
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        ...mapState('TemplateGroup', {
            template_group_list: 'list',
        }),

        ...mapState('TemplateUsecase', {
            template_usecase_list: 'list',
        }),

        ...mapState('Usecase', {
            usecase_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self'
        }),
    },

    methods: {
        async local_index() {
            if(this.$can('templates.index') === false || this.local_can('update') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            this.local_loading = true
            await this.local_load_template_group_list()
            this.local_loading = false
        },

        async local_load_template_group_list() {
            await this.template_group_clear()
            await this.template_group_index({
                'fields[template_groups]': 'id,name',
                'include': 'templateUsecasesCount,templateSuccessCriteriaCount,templateTestcaseCount',
                'filter[type]': 'usecases'
            })
            this.local_template_group_list = [...this.template_group_list]
            this.local_render_group_list = [...this.template_group_list]
        },

        async local_get_usecase_list() {
            this.local_usecase_loading = true
            this.usecase_request_ids = this.usecase_request_list.map(item => item.id);
            await this.template_usecase_clear()
            await this.template_usecase_index({
                'filter[ids]': this.usecase_request_ids,
                'include': 'tags,templateTestcase,templateTestcaseCount,templateSuccessCriteria,templateSuccessCriteriaCount',
                'fields[tags]': 'tags.id,tags.label,tags.color',
                'fields[template_usecases]': 'id,title,visibility',
                'fields[template_testcases]': 'id,title,template_usecase_id',
                'fields[template_success_criteria]':'id,title,template_usecase_id',
                'sort': 'order',
            })
            this.local_usecase_loading = false
        },

        async local_select_usecase_import_step(step, go_back = false) {
            if(this.usecase_group_request_list.length === 0) return
            this.usecase_group_import_step = step
            if(step === 2 && !go_back) this.local_load_step_two()
            if(step === 3) this.local_load_step_three()
        },

        async local_load_selected_template_group_list() {
            const selected_template_group_ids = this.usecase_group_request_list.map(item => item.id);
            await this.template_group_clear()
            await this.template_group_index({
                'fields[template_groups]': 'id,name',
                'filter[id]': selected_template_group_ids,
                'include': 'templateUsecases.tags,templateUsecases,templateUsecasesCount,templateSuccessCriteriaCount,templateTestcaseCount',
                'fields[template_usecases]': 'id,title,visibility,template_group_id',
                'filter[type]': 'usecases',
            })
            this.local_selected_group_count = this.usecase_group_request_list.length
            this.local_selected_template_group_list = [...this.template_group_list]
            this.local_render_selected_group_list = [...this.template_group_list]
        },

        async local_load_step_two() {
            this.flag_show_usecase_selected = true
            this.accordion_usecases_list = []
            this.usecase_request_list = []
            this.accordion_request_usecase_list = []
            this.local_loading_selected_template_group = true
            this.local_selected_template_group_list = []
            this.local_render_selected_group_list = []
            await this.local_load_selected_template_group_list()
            await this.local_update_usecase_group_request()
            this.local_loading_selected_template_group = false
        },

        async local_load_step_three() {
            this.accordion_selected_usecase_list = []
            await this.local_get_usecase_list()
        },

        async local_template_usecases_import() {
            const import_item = {}
            import_item.project_id = this.$route.params.id
            import_item.template_usecase_id = this.usecase_request_ids
            this.local_usecase_import_loading = true
            await this.usecase_template_import(import_item)
            this.local_usecase_import_loading = false
            if (this.usecase_response.status === 'success') this.$router.push({ name: 'projects-usecases' })
        },

        async local_add_usecase_request_list(usecase_group, event, index = null) {
            this.local_accordion_request_usecase_group_toggle(index)
            if(!usecase_group.template_usecases || !usecase_group.template_usecases.length) return
            if(event === true) this.local_usecase_add_request_list(usecase_group.template_usecases)
            if(event === false) this.local_usecase_remove_request_list(usecase_group.template_usecases)
        },

        local_is_usecase_group_request_added(id){
            const usecase_request_filter = this.usecase_request_list.filter(item => item.template_group_id === id)
            return usecase_request_filter.length ? true : false
        },

        local_is_usecase_added(id){
            if (!this.usecase_request_list.length) return false

            const index = this.usecase_request_list.findIndex(item => item.id === id)
            return index >= 0 ? true : false
        },

        local_usecase_remove_request_list(data){
            data.forEach((usecase) => {
                const index = this.usecase_request_list.findIndex(item => item.id === usecase.id)
                if (index !== -1) this.usecase_request_list.splice(index, 1)
            })
        },

        local_usecase_add_request_list(data){
            data.forEach((usecase) => {
                const index = this.usecase_request_list.findIndex(item => item.id === usecase.id)
                if (index !== -1) return
                this.usecase_request_list.push(usecase)
            })
        },

        local_usecase_request_list(data){
            const index = this.usecase_request_list.findIndex(item => item.id === data.id)

            if (index !== -1) {
                this.usecase_request_list.splice(index, 1)
                return
            }

            this.usecase_request_list.push(data)
        },

        local_update_usecase_group_request() {
            this.local_selected_template_group_list.forEach((usecase_group) => {
                this.local_get_usecase_request_list(usecase_group)
            })
            this.local_render_selected_group_list = this.local_selected_template_group_list
        },

        async local_usecase_group_request_list(data){
            const index = this.usecase_group_request_list.findIndex(item => item.id === data.id)

            if (index !== -1) {
                this.usecase_group_request_list.splice(index, 1)
                await this.usecase_group_check()
                return
            }

            this.usecase_group_request_list.push(data)
        },

        async usecase_group_check(){
            if(this.flag_show_group_selected === false &&this.usecase_group_request_list.length === 0) {
                this.flag_show_group_selected = true
                this.local_load_template_group_list()
            }
        },

        async local_get_usecase_request_list(data){
            if(data && data.template_usecases && data.template_usecases.length) {
                data.template_usecases.forEach((usecase) => {
                    this.usecase_request_list.push(usecase)
                })
            }
        },

        async local_remove_usecase_request_list(data){
            if(data && data.template_usecases && data.template_usecases.length) {
                data.template_usecases.forEach((usecase) => {
                    const index = this.usecase_request_list.findIndex(item => item.id === usecase.id)
                    if(index !== -1){
                        this.usecase_request_list.splice(index, 1)
                    }
                })
            }
        },

        local_remove_usecase_group(id){
            const index = this.usecase_group_request_list.findIndex(item => item.id === id)
            this.usecase_group_request_list.splice(index, 1)
        },

        local_is_usecase_group_added(id){
            if(!this.usecase_group_request_list.length) return false

            const index = this.usecase_group_request_list.findIndex(item => item.id === id)
            return index >= 0 ? true : false
        },

        local_accordion_selected_usecase_status(index){
            if(this.accordion_selected_usecase_list[index] === undefined && this.usecase_request_ids.length < 10) {
                this.accordion_selected_usecase_list[index] = true
                return this.accordion_selected_usecase_list[index]
            }
            if(this.accordion_selected_usecase_list[index]) {
                return this.accordion_selected_usecase_list[index]
            }else{
                this.accordion_selected_usecase_list[index] = false
                return this.accordion_selected_usecase_list[index]
            }
        },

        local_accordion_selected_usecase_toggle(index){
            if (this.accordion_selected_usecase_list[index] === undefined) {
                this.$set(this.accordion_selected_usecase_list, index, true);
            }else{
                this.$set(this.accordion_selected_usecase_list, index, !this.accordion_selected_usecase_list[index]);
            }
        },

        local_accordion_request_usecase_group_status(index){
            if(this.accordion_request_usecase_list[index]) {
                return this.accordion_request_usecase_list[index]
            }else{
                this.accordion_request_usecase_list[index] = false
                return this.accordion_request_usecase_list[index]
            }
        },

        local_accordion_request_usecase_group_toggle(index){
            const hasIndex = (this.accordion_request_usecase_list[index] === undefined)
            this.$set(this.accordion_request_usecase_list, index, hasIndex)
        },

        local_accordion_request_usecase_status(index, usecase_count = 0){
           const hasIndex = this.accordion_usecases_list[index]
            if(hasIndex === undefined && usecase_count === 0) {
                this.accordion_usecases_list[index] = false
                return false
            }
            if (hasIndex === undefined && this.usecase_group_request_list.length < 10) {
                this.accordion_usecases_list[index] = true
                return true
            }
            if (hasIndex) return hasIndex

            this.accordion_usecases_list[index] = false
            return false
        },

        local_accordion_request_usecase_toggle(index){
            const hasIndex = !this.accordion_usecases_list[index]
            const typeOfIndex = (typeof this.accordion_usecases_list[index] !== 'boolean')
            this.$set(this.accordion_usecases_list, index, typeOfIndex || hasIndex)
        },

        local_can(type){
            return this.$can(`usecases.${type}`) && this.$can('projects.update-only')
        },

        // Step 1 templates & tags search
        local_search_templates (search_text) {
            clearTimeout(this.search_timeout)
            const cloned_group_list = _.cloneDeep(this.local_template_group_list)
            this.search_loading = true
            this.search_timeout = setTimeout(() => {
                if (search_text) {
                    const searchable_fields = ['name', 'tags']
                    this.local_render_group_list = cloned_group_list.filter(item => {
                        const tag_labels = item.tags.map(tag => tag.label)
                        return searchable_fields.some(field => {
                            if (field === 'name') return this.local_search_content(item[field], search_text)
                            return tag_labels.some(tag => this.local_search_content(tag, search_text))
                        })
                    })

                    this.search_loading = false
                    return true
                }

                this.local_render_group_list = cloned_group_list
                this.search_loading = false
            }, 200)

        },

        // Step 2 usecases & tags search
        local_search_usecases (search_text) {
            clearTimeout(this.search_timeout)
            const cloned_selected_list = _.cloneDeep(this.local_selected_template_group_list)
            this.search_loading = true
            this.search_timeout = setTimeout(() => {
                if (search_text) {
                    const searchable_fields = ['tags', 'template_usecases']
                    this.local_render_selected_group_list = cloned_selected_list.filter(item => {
                        const tag_labels = item.tags.map(tag => tag.label)
                        const usecase_titles = item.template_usecases.map(usecase => usecase.title)
                        return searchable_fields.some(field => {
                            if (field === 'tags') return tag_labels.some(tag => this.local_search_content(tag, search_text))

                            item[field] = item[field].filter(usecase => this.local_search_content(usecase.title, search_text))
                            return usecase_titles.some(title => this.local_search_content(title, search_text))
                        })
                    })

                    this.search_loading = false
                    return true
                }

                this.local_render_selected_group_list = cloned_selected_list
                this.search_loading = false
            }, 200)
        },

        local_search_content (result_text, search_text) {
            return result_text && result_text.toLowerCase().includes(search_text.toLowerCase())
        },

        ...mapActions('Usecase', {
            usecase_template_import: 'template_import',
        }),

        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_clear: 'clear',
            template_group_clear_item: 'clear_item',
        }),

        ...mapActions('TemplateUsecase', {
            template_usecase_index: 'index',
            template_usecase_clear: 'clear',
            template_usecase_clear_item: 'clear_item',
        })
    },
}
</script>

<style lang="scss" scoped>
    .c-usecase-card-visibility {
        position: absolute;
        background: orange;
        width: 2px;
        z-index: 100;
        height: 100%;
    }
</style>

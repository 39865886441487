<template>
    <div class="u-flex u-wfull" style="column-gap: 12px;">
        <g-avatar
            :item="modalState.get(notification.data, 'actor')"
            :size="32"
            :right-space="false"
        />

        <div class="u-wfull">
            <div class="u-flex align-start">
                <partial-notifier-header moduleTitle="Project Stage" />

                <a-spacer></a-spacer>

                <a-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <partial-read-icon v-if="notification.read_at" @click.stop="$emit('read', false)" />
                            <partial-unread-icon v-else @click.stop="$emit('read', true)" />
                        </div>
                    </template>
                    <span>Mark as {{ notification.read_at ? 'unread' : 'read' }}</span>
                </a-tooltip>
            </div>

            <div class="mt-2 u-flex-center-y text-truncate" style="max-width: 440px;">
                <a-icon size="18" color="blue-grey" outlined>folder_open</a-icon>
                <span
                    :title="modalState.get(notification.data, 'module.data.title')"
                    class="u-font-14 font-weight-medium ml-1 blue-grey--text text-truncate u-leading-20"
                >
                    {{ modalState.get(notification.data, 'module.data.title') }}
                </span>
            </div>

            <partial-notification-changes
                :fromValue="modalState.get(notification.data, 'from.name')"
                :toValue="modalState.get(notification.data, 'to.name')"
                class="mt-1"
            />
        </div>
    </div>
</template>

<script>
import PartialNotificationChanges from './Partials/PartialNotificationChanges.vue'
import PartialNotifierHeader from './Partials/PartialNotifierHeader.vue'
import PartialReadIcon from './Partials/PartialReadIcon.vue'
import PartialUnreadIcon from './Partials/PartialUnreadIcon.vue'

export default {
    inject: ['modalState'],

    provide () {
        const notification = {}

        Object.defineProperty(notification, 'value', {
            enumerable: true,
            get: () => this.notification
        })

        return { notification }
    },

	components: {
        PartialReadIcon,
        PartialUnreadIcon,
        PartialNotifierHeader,
        PartialNotificationChanges,
    },

    props: {
        notification: {
            type: Object
        }
    },
}
</script>

<template>
    <a-main>
        <a-container container--fluid grid-list-xl class="pa-0 mx-0 pb-16 mb-16">
            <g-page-header class="pr-2" title="Inventory" subtitle="Manage all inventory." icon="memory">
                <template v-slot:action>
                    <a-btn small text color="transparent" class="grey--text text--darken-1 elevation-0 ma-0 mr-2 u-rounded-corners" :to="{ name: 'inventory-trash' }">
                        <a-icon class="grey--text text--darken-1 mr-1" color="grey" size="16">delete</a-icon>
                        Trash
                    </a-btn>
                    <a-btn class="pa-0 ma-0 grey--text text--darken-1 mr-2 px-3 u-rounded-corners" text small :to="{ name: 'booked-inventory' }">
                        <a-icon size="18" class="mr-1">update</a-icon>
                        Booking Status
                    </a-btn>
                    <a-menu bottom offset-y v-if="$can('inventories.store')">
                        <template v-slot:activator="{ on }">
                            <a-btn small depressed color="white" class="ma-0 mr-2 u-rounded-corners grey--text text--darken-1" v-on="on">
                                Add Inventory <a-icon class="ml-1 mr-0">arrow_drop_down</a-icon>
                            </a-btn>
                        </template>
                        <a-list class="u-list-condensed">
                            <a-list-item :to="{name : 'inventory-add'}" :exact="true">
                                <a-list-item-title>
                                    <span class="md-body-2">Add Inventory</span>
                                </a-list-item-title>
                            </a-list-item>
                            <a-list-item :to="{name: 'inventory-import'}" :exact="true">
                                <a-list-item-title>
                                    <span class="md-body-2">Import from CSV</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                    <a-btn depressed class="pa-0 ma-0 blue white--text px-3 u-rounded-corners" v-if="$can('inventories.update')" small :to="{ name: 'inventory-book' }">Book Inventory</a-btn>
                </template>
            </g-page-header>
            <a-container container--fluid grid-list-xl class="pa-0 mt-4">
                <a-layout align-center class="my-0 mb-2">
                    <a-flex class="py-0">
                        <a-card flat class="c-search-inventory u-border px-3 d-flex align-center u-rounded-corners mb-1">
                            <a-icon size="24" color="grey" style="max-width: 20px;">search</a-icon>
                            <a-text-field solo flat hide-details dense placeholder="Search by hardware name, part number or serial number" class="" v-model="local_search_inventory" @input="evt => local_search_filter(filter.SEARCH, local_search_inventory, evt)"></a-text-field>
                        </a-card>
                    </a-flex>
                    <a-flex shrink class="pl-1">
                        <a-progress-circular color="orange darken-2" size="24" width="2" :indeterminate="loading"></a-progress-circular>
                    </a-flex>
                </a-layout>
                <a-layout align-start class="mt-1 mb-4">
                    <a-flex shrink class="py-0">
                        <a-layout class="ma-0 pa-0">
                            <a-flex shrink class="py-0 px-0 mt-3 mb-1 c-margin-right">
                                <a-menu bottom offset-y v-model="search_category" :close-on-content-click="category_mode" min-width="300" max-width="300" max-height="600" transition="slide-y-transition" class="d-block">
                                    <template v-slot:activator="{ on }">
                                        <a-card flat class="u-rounded-corners u-overflow-hidden u-border d-flex" min-width="300" max-width="300" max-height="36" v-on="on" @click="local_search_category = ''">
                                            <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                                <div>
                                                    <a-icon size="20" class="ma-0">filter_list</a-icon>
                                                    <span class="grey--text text--darken-2 mx-1">
                                                        Category:
                                                        <span class="font-weight-medium" style="max-width: 300px" :title="local_filter_category_label">
                                                            {{ local_truncate_text(local_filter_category_label, 15) }}
                                                            <span class="md-body-2 grey--text" v-if="local_select_inventory_count">
                                                                ({{ local_select_inventory_count }})
                                                            </span>
                                                            <span class="md-body-2 grey--text" v-else>(0)</span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="text-xs-right">
                                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                                </div>
                                            </a-card-text>
                                        </a-card>
                                    </template>
                                    <a-divider></a-divider>
                                    <a-card class="c-search-inputs-inventory u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                                        <a-text-field
                                            v-model="local_search_category"
                                            placeholder="Search Category"
                                            background-color="neutral"
                                            ref="search_category_input"
                                            class="body-2 font-weight-normal"
                                            solo flat hide-details
                                        >
                                        </a-text-field>
                                        <a-divider></a-divider>
                                        <a-list style="max-height: 384px;" class="overflow-y-auto py-0">
                                            <a-list-item  v-if="!local_search_category && local_filtered_inventory_category_list.length !== 0 && inventory_filters[filter.CATEGORY] === 'ALL'" class="grey lighten-4 py-0">
                                                <a-list-item-title>
                                                    <a-layout align-center justify-space-between class="py-0">
                                                        <a-flex shrink>
                                                            <h5 class="md-subtitle-1">All</h5>
                                                        </a-flex>
                                                        <a-flex shrink class="text-xs-right">
                                                            <span class="md-body-2 grey--text">{{ inventory_meta.total_count }}</span>
                                                        </a-flex>
                                                    </a-layout>
                                                </a-list-item-title>
                                            </a-list-item>
                                            <a-list-item @click="local_category_filter(filter.CATEGORY,'ALL', 'All', inventory_meta.total_count)" v-if="!local_search_category && local_filtered_inventory_category_list.length !== 0 && inventory_filters[filter.CATEGORY] !== 'ALL'">
                                                <a-list-item-title>
                                                    <a-layout align-center justify-space-between class="py-0">
                                                        <a-flex shrink>
                                                            <h5 class="md-subtitle-1">All</h5>
                                                        </a-flex>
                                                        <a-flex shrink class="text-xs-right">
                                                            <span class="md-body-2 grey--text">{{ inventory_meta.total_count }}</span>
                                                        </a-flex>
                                                    </a-layout>
                                                </a-list-item-title>
                                            </a-list-item>
                                            <template v-for="(item, index) in local_filtered_inventory_category_list" >
                                                <a-list-item :key="'filter' + index" @click="local_category_filter(filter.CATEGORY, item.id, item.value, item.category_count)" v-if="inventory_filters[filter.CATEGORY] !== item.id">
                                                    <a-list-item-title>
                                                        <a-layout align-center justify-space-between class="py-0">
                                                            <a-flex shrink>
                                                                <h5 class="md-subtitle-1 text-truncate" style="max-width: 180px" :title="item.value">{{ item.value }}</h5>
                                                            </a-flex>
                                                            <a-flex shrink class="text-xs-right">
                                                                <span class="md-body-2 grey--text">{{ item.category_count }}</span>
                                                            </a-flex>
                                                        </a-layout>
                                                    </a-list-item-title>
                                                </a-list-item>
                                                <a-list-item :key="'filter' + index" class="grey lighten-4 py-0" v-else>
                                                    <a-list-item-title>
                                                        <a-layout align-center justify-space-between class="py-0">
                                                            <a-flex shrink>
                                                                <h5 class="md-subtitle-1 text-truncate" style="max-width: 180px" :title="item.value">{{ item.value }}</h5>
                                                            </a-flex>
                                                            <a-flex shrink class="text-xs-right">
                                                                <span class="md-body-2 grey--text">{{ item.category_count }}</span>
                                                            </a-flex>
                                                        </a-layout>
                                                    </a-list-item-title>
                                                </a-list-item>
                                            </template>
                                            <a-list-item v-if="local_filtered_inventory_category_list.length === 0" disabled>
                                                <a-list-item-content>
                                                    <a-list-item-title v-if="loading">
                                                        <h2 class="md-subtitle-1 pa-0 mb-0 text-center">Loading categories...</h2>
                                                    </a-list-item-title>
                                                    <a-list-item-title v-else>
                                                        <h2 class="md-subtitle-1 mb-0 text-center">No categories found</h2>
                                                    </a-list-item-title>
                                                </a-list-item-content>
                                            </a-list-item>
                                        </a-list>
                                        <a-divider></a-divider>
                                        <a-list class="py-0">
                                            <a-list-item @click="local_category_filter(filter.CATEGORY,'NONE', 'No Category', inventory_meta.without_category)" v-if="inventory_filters[filter.CATEGORY] !== 'NONE'">
                                                <a-list-item-title>
                                                    <a-layout align-center justify-space-between class="py-0">
                                                        <a-flex shrink>
                                                            <h5 class="md-subtitle-1">No Category</h5>
                                                        </a-flex>
                                                        <a-flex shrink class="text-xs-right">
                                                            <span class="md-body-2 grey--text" :class="{'mr-3': local_filtered_inventory_category_list.length > 8}">{{ inventory_meta.without_category }}</span>
                                                        </a-flex>
                                                    </a-layout>
                                                </a-list-item-title>
                                            </a-list-item>
                                            <a-list-item v-if="inventory_filters[filter.CATEGORY] === 'NONE'" class="grey lighten-4 py-0">
                                                <a-list-item-title class="u-flex align-center">
                                                    <h5 class="md-subtitle-1">No Category</h5>
                                                    <a-spacer></a-spacer>
                                                    <span class="md-body-2 grey--text" :class="{'mr-3': local_filtered_inventory_category_list.length > 8}">{{ inventory_meta.without_category }}</span>
                                                </a-list-item-title>
                                            </a-list-item>
                                        </a-list>
                                    </a-card>
                                </a-menu>
                            </a-flex>
                            <a-flex shrink class="py-0 px-0 mt-3 mb-1" :class="{ 'c-margin-right': show_selected_filters }">
                                <a-card flat class="md-body-2 pa-0 u-border u-rounded-corners xs-mt-1 xl-mt-0 d-flex align-center grey--text text--darken-1 px-2 u-cursor-pointer" min-height="35" v-if="loading || local_loading">
                                    <a-icon size="18" color="grey darken-2" class="mr-1">filter_alt</a-icon>
                                    Filters / Sort
                                </a-card>
                                <a-card flat @click="local_show_filters_pane()" class="md-body-2 pa-0 u-border u-rounded-corners xs-mt-1 xl-mt-0 d-flex align-center grey--text text--darken-1 px-2 u-cursor-pointer" min-height="35" v-else>
                                    <a-icon size="18" color="grey darken-2" class="mr-1">filter_alt</a-icon>
                                    Filters / Sort
                                </a-card>
                            </a-flex>
                            <div v-if="show_selected_filters" class="mt-3">
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.STATUS] && inventory_filter_types[filter.STATUS] !== 'All' && inventory_filter_types && inventory_filter_types[filter.STATUS] !== 'ALL'" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Status:</strong> {{ inventory_filter_types[filter.STATUS] }}
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer" @click="local_clear_filters(filter.STATUS, 'ALL')" style="min-height: 35px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1 ml-2" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.UNAVAILABLE] && inventory_filter_types[filter.UNAVAILABLE] === 'Unavailable'" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Status:</strong> {{ inventory_filter_types[filter.UNAVAILABLE] }}
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer" @click="local_clear_filters(filter.STATUS, 'ALL')" style="min-height: 35px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1 ml-2" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.CATEGORY] && inventory_filter_types[filter.CATEGORY] !== 'All' && inventory_filter_types && inventory_filter_types[filter.CATEGORY] !== 'ALL'" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Category:</strong> {{ inventory_filter_types[filter.CATEGORY] }}
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer" @click="local_clear_filters(filter.CATEGORY, 'ALL')" style="min-height: 35px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1 ml-2" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.TERRITORY] && inventory_filter_types[filter.TERRITORY] !== 'All' && inventory_filter_types && inventory_filter_types[filter.TERRITORY] !== 'ALL' && local_territory_filter_item" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Territory:</strong>
                                        <span class="md-caption d-inline-block px-2 ma-0 ml-1 u-rounded-corners" v-if="local_territory_filter_item !== 'No Territory'" style="padding-bottom: 2px; padding-top: 4px;" :style="{backgroundColor: $color(local_territory_filter_item.color, 'color_bg'), color: $color(local_territory_filter_item.color, 'color_text')}">
                                            {{ local_territory_filter_item.name }}
                                        </span>
                                        <span class="d-inline-block ma-0 ml-1 u-rounded-corners" v-else>No Territory</span>
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer" @click="local_clear_filters(filter.TERRITORY, 'ALL', 'territory')" style="min-height: 35px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1 ml-2" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.TAGS] && inventory_filter_types[filter.TAGS] !== 'All' && inventory_filter_types && inventory_filter_types[filter.TAGS] !== 'ALL' && local_tag_filter_item" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Tag:</strong>
                                        <span class="md-caption d-inline-block px-2 ma-0 ml-1 u-rounded-corners" v-if="local_tag_filter_item !== 'No Tag'" style="padding-bottom: 2px; padding-top: 4px;" :style="{backgroundColor: $color(local_tag_filter_item.color, 'color_bg'), color: $color(local_tag_filter_item.color, 'color_text')}">
                                            {{ local_tag_filter_item.label }}
                                        </span>
                                        <span class="d-inline-block ma-0 ml-1 u-rounded-corners" v-else>No Tag</span>
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer" @click="local_clear_filters(filter.TAGS, 'ALL', 'tag')" style="min-height: 35px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1 ml-2" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.STAGE] && inventory_filter_types[filter.STAGE] !== 'All' && inventory_filter_types && inventory_filter_types[filter.STAGE] !== 'ALL' && local_stage_filter_item && local_stage_filter_item !== 'All'" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Stage:</strong>
                                        <span class="md-caption d-inline-block px-2 ma-0 ml-1 u-rounded-corners" v-if="local_stage_filter_item !== 'No Stage'" style="padding-bottom: 2px; padding-top: 4px;" :style="{backgroundColor: $color(local_stage_filter_item.color, 'color_bg'), color: $color(local_stage_filter_item.color, 'color_text')}">
                                            {{ local_stage_filter_item.label }}
                                        </span>
                                        <span class="d-inline-block ma-0 ml-1 u-rounded-corners" v-else>No Stage</span>
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer" @click="local_clear_filters(filter.STAGE, 'ALL', 'stage')" style="min-height: 35px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1 ml-2" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.ASSIGNEE] && inventory_filter_types[filter.ASSIGNEE] !== 'All' && inventory_filter_types[filter.ASSIGNEE] !== 'ALL'" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Assignee:</strong> {{ inventory_filter_types[filter.ASSIGNEE] }}
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer" @click="local_clear_filters(filter.ASSIGNEE, 'ALL')" style="min-height: 35px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1 ml-2" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.OWNER] && inventory_filter_types[filter.OWNER] !== 'All' && inventory_filter_types[filter.OWNER] !== 'ALL'" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Owner:</strong> {{ inventory_filter_types[filter.OWNER] }}
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer" @click="local_clear_filters(filter.OWNER, 'ALL')" style="min-height: 35px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1 ml-2" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="inventory_filter_types && inventory_filter_types[filter.PROJECT] && inventory_filter_types[filter.PROJECT] !== 'ALL' && inventory_filter_types[filter.PROJECT] && inventory_filter_types[filter.PROJECT] !== 'All'" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Project:</strong> {{ inventory_filter_types[filter.PROJECT] }}
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer" @click="local_clear_filters(filter.PROJECT, 'ALL')" style="min-height: 35px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1 ml-2" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="inventory_filter_types && inventory_filter_types.booking_from && inventory_filter_types.booking_to" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Availability:</strong> {{ local_format_date(inventory_filter_types.booking_from) }} - {{ local_format_date(inventory_filter_types.booking_to) }}
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer" @click="local_clear_filters('date', null)" style="min-height: 35px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1 ml-2" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                                <a-card v-if="((sort_by.toLowerCase() === 'name' || sort_by.toLowerCase() !== 'name') && sort_direction === '-') || (sort_by.toLowerCase() !== 'name' && sort_direction === '')" class="pa-0 white u-elevation-custom-1 pl-2 mr-1 mt-0 mb-1 u-rounded-corners d-inline-flex align-center" style="min-height: 35px;">
                                    <div class="text-truncate grey--text text--darken-1 d-inline-block">
                                        <strong>Sort by:</strong> {{ sort_by }} ({{ sort_direction === '-' ? 'Desc' : 'Asc' }})
                                    </div>
                                    <div class="elevation-0 ma-0 pa-0 text-center d-inline-flex u-cursor-pointer" @click="local_clear_filters('sort', 'name')" style="min-height: 35px; min-width: 30px; max-width: 30px;">
                                        <a-icon class="grey--text text--darken-1 ml-2" style="font-size: 14px;">clear</a-icon>
                                    </div>
                                </a-card>
                            </div>
                        </a-layout>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink class="py-0 pl-0 mt-3">
                        <a-menu offset-y left bottom :disabled="inventory_list.length === 0">
                            <template v-slot:activator="{ on }">
                                <a-btn depressed v-on="on" small color="grey darken-4" height="36" class="white--text ma-0 px-4 u-rounded-corners">
                                    <a-icon left size="16" class="mr-2">save_alt</a-icon> Download <a-icon right size="18" class="ml-2">arrow_drop_down</a-icon>
                                </a-btn>
                            </template>
                            <a-list class="u-list-condensed">
                                <a-list-item  @click="local_export('csv')">
                                    <a-list-item-title class="md-body-2">Download as CSV</a-list-item-title>
                                </a-list-item>
                                <a-list-item @click="local_export('xlsx')">
                                    <a-list-item-title class="md-body-2">Download as Excel</a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                </a-layout>
                <a-responsive class="py-4 my-4" v-if="local_loading">
                    <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1">Fetching Inventories..</h3>
                </a-responsive>
                <a-responsive class="py-4 my-4" v-if="local_filtered_inventory_list && local_filtered_inventory_list.length === 0 && !local_loading">
                    <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Inventory Found</h3>
                    <p class="md-body-2 text-center grey--text mb-0">There is no inventory to fetch right now<br>Narrow your search or Import/Add new inventory</p>
                </a-responsive>
                <template v-if="local_filtered_inventory_list && local_filtered_inventory_list.length && !local_loading">
                    <a-layout>
                        <s-inventory-list
                            :local_filtered_inventory_list="local_filtered_inventory_list"
                            :user_self="user_self"
                            :date_range="check_for_date_range"
                            @local_show_inventory_item="local_show_inventory_item"
                            @local_close_destroy_confirm="local_close_destroy_confirm"
                            @local_show_inventory_detail="local_show_inventory_detail"
                        ></s-inventory-list>
                    </a-layout>
                    <a-layout class="mx-0 mt-4 c-sticky-paginate-parent u-border u-rounded-corners" v-if="inventory_filters[filter.CATEGORY] === 'ALL' || inventory_filters[filter.CATEGORY] === 'NONE'">
                        <a-flex class="d-flex align-center">
                            <div class="body-2 grey--text text--darken-2 font-weight-medium mr-2 d-inline-block">Showing {{ inventory_meta.from }} - {{ inventory_meta.to }} out of {{ inventory_meta.total }} inventories <span class="blue--text text--darken-2 ml-2" v-if="local_loading">loading...</span></div>
                        </a-flex>
                        <a-spacer></a-spacer>
                        <a-flex class="mr-16 pr-16 py-0" v-if="inventory_meta.total > 15">
                            <div class="text-right">
                                <a-pagination
                                    v-model="inventory_meta.current_page"
                                    :length="inventory_meta.last_page"
                                    :total-visible="10"
                                    class="c-inventory-pagination py-1"
                                    @input="local_inventory_pagination()"
                                ></a-pagination>
                            </div>
                        </a-flex>
                    </a-layout>
                </template>
            </a-container>
        </a-container>
        <div class="grey lighten-3 c-inventory-detail-pane elevation-12 py-4 pb-0" ref="pane_inventory" :class="{'visible': pane_inventory}">
            <template v-if="!inventory_edit_item">
                <a-layout class="px-4 fill-height">
                    <a-flex>
                        <div class="fill-height d-flex align-center justify-center">
                            <a-sheet max-width="300" class="transparent u-rounded-corners elevation-0 mx-auto">
                                <a-progress-linear height="5" indeterminate color="primary" class="u-rounded-corners"></a-progress-linear>
                            </a-sheet>
                        </div>
                    </a-flex>
                </a-layout>
            </template>
            <template v-else>
                <a-layout align-center class="px-5 mb-4">
                    <a-flex shrink class="mr-2">
                        <a-avatar class="primary darken-1" size="45">
                            <a-icon dark>memory</a-icon>
                        </a-avatar>
                    </a-flex>
                    <a-flex>
                        <h2 class="md-heading-6 primary--text text--darken-1">Edit Inventory</h2>
                    </a-flex>
                </a-layout>
                <a-divider class="my-2"></a-divider>
                <a-layout class="px-5" wrap v-if="inventory_edit_item">
                    <a-flex xs12 class="mt-4 mb-0 c-edit-inventory-fields">
                        <div class="mb-2">
                            <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold d-inline-block">Inventory Name</h4>
                        </div>
                        <a-text-field solo flat hide-details autofocus placeholder="Edit/Add hardware name" v-model="inventory_edit_item.name" background-color="white" class="u-rounded-corners c-border-c7 mb-1"></a-text-field>
                        <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                            <template v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.name">
                                <a-icon size="16" color="red darken-2">error</a-icon>
                                {{ inventory_response.server.errors.name[0] }}
                            </template>
                        </a-sheet>
                    </a-flex>
                    <a-flex xs12 class="mb-0 c-edit-inventory-fields">
                        <div class="mb-2">
                            <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold d-inline-block">Description</h4>
                        </div>
                        <a-textarea
                            :value="inventory_edit_item.description"
                            @input="inventory_edit_item.description = $event !== '' ? $event : null"
                            rows="5"
                            placeholder="Edit/Add inventory description"
                            class="body-2 mb-1 u-rounded-corners c-border-c7 white"
                            solo flat hide-details no-resize
                        />
                        <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                            <template v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.description">
                                <a-icon size="16" color="red darken-2">error</a-icon>
                                {{ inventory_response.server.errors.description[0] }}
                            </template>
                        </a-sheet>
                    </a-flex>
                    <a-layout class="ma-0">
                        <a-flex xs6 class="c-edit-inventory-fields mr-3 mb-0">
                            <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-2">Category</h4>
                            <component
                                :is="$can('metas.store') ? 'a-combobox' : 'a-autocomplete'"
                                v-model="local_category_item"
                                ref="category_dropdown"
                                :items="inventory_category_list"
                                :error="category_exist_message"
                                :search-input.sync="inventory_category_search"
                                :readonly="local_category_item ? true : false"
                                item-text="value"
                                item-value="id"
                                :placeholder="`Select${$can('metas.store') ? ' or create ' : ' '}category`"
                                clear-icon="backspace"
                                class="c-border-c7 u-rounded-corners mb-1"
                                @click:clear="local_clear_category_input()"
                                @change="$can('metas.store') ? local_inventory_category_store() : local_inventory_category_select(local_category_item)"
                                solo flat hide-details hide-selected clearable
                            >
                                <template v-slot:no-data>
                                    <div class="px-4 py-2">
                                        <span class="md-body-2" v-if="inventory_category_search !== null && inventory_category_search !== '' && $can('metas.store')">Create <strong>{{ inventory_category_search }}</strong> as Category. Hit<span style="font-family: monospace;" class="mx-1 font-weight-bold">Enter</span>to create.</span>
                                        <span class="md-body-2 grey--text" v-else>No categories found. <template v-if="$can('metas.store')">Enter category and hit enter to create new one.</template></span>
                                    </div>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <div v-if="item === Object(item)" class="md-body-2 d-flex align-center py-1 u-rounded-corners" style="width: 100%; min-height: 48px;">{{ item.value }}</div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <a-flex>
                                        <div class="md-body-2 d-block py-1 u-rounded-corners" style="width: 100%" @click="local_move_category_focus()">{{ item.value }}</div>
                                    </a-flex>
                                </template>
                            </component>
                            <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                                <template v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.category_id">
                                    <a-icon size="16" color="red darken-2">error</a-icon>
                                    {{ inventory_response.server.errors.category_id[0] }}
                                </template>
                                <template v-if="meta_response.server && meta_response.server.errors && meta_response.server.errors.category_id">
                                    <a-icon size="16" color="red darken-2">error</a-icon>
                                    {{ meta_response.server.errors.category_id[0] }}
                                </template>
                                <template v-if="meta_response.server && meta_response.server.errors && meta_response.server.errors.value">
                                    <a-icon size="16" color="red darken-2">error</a-icon>
                                    {{ meta_response.server.errors.value[0] }}
                                </template>
                            </a-sheet>
                        </a-flex>
                        <a-flex xs6 class="c-edit-inventory-fields mb-0">
                            <label class="md-body-2 d-block mb-2 mt-0 font-weight-bold grey--text text--darken-3">Owner</label>
                            <GUsersSelectDropdown
                                v-model="local_owner"
                                :list="user_list"
                                :loading="mixinSearchLoading"
                                @search="localSearchUsers"
                                placeholder="Select User"
                                class="px-3 c-border-c7"
                                dropdownClass="c-tiny-scroll py-2"
                                noDataText="No User found"
                                clearable returnObject
                            >
                                <template #item="{ props: { item } }">
                                    <div class="u-flex-center-y">
                                        <g-avatar :item="item"></g-avatar>
                                        <g-profile-info :item="item" type-user set-max-width></g-profile-info>
                                    </div>
                                </template>
                            </GUsersSelectDropdown>
                        </a-flex>
                    </a-layout>
                    <a-layout class="ma-0">
                        <a-flex xs6 class="mr-3 c-edit-inventory-fields" @click="current_search_module = 'oem'">
                            <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-2">OEM</h4>
                            <a-combobox
                                v-model="inventory_edit_item.oem"
                                :items="inventory_autosuggest_list"
                                item-text="oem"
                                item-value="oem"
                                placeholder="Edit/Add OEM"
                                background-color="white"
                                :loading="autosuggest_loading === 'oem'"
                                :append-icon="null"
                                :search-input.sync="autosuggest_search['oem']"
                                @input="local_select_autosuggest(inventory_edit_item, 'oem')"
                                @click="current_search_module = 'oem'"
                                @clear="local_autosuggest_search_clear(autosuggest_search, 'oem')"
                                class="c-border-c7 mb-1 u-rounded-corners"
                                solo flat hide-details clearable hide-no-data
                            >
                                <template #item="{ item }">
                                    <div class="text-truncate py-1 u-rounded-corners" style="max-width: 330px">{{ item.oem }}</div>
                                </template>
                            </a-combobox>
                            <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                                <template v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.oem">
                                    <a-icon size="16" color="red darken-2">error</a-icon>
                                    {{ inventory_response.server.errors.oem[0] }}
                                </template>
                            </a-sheet>
                        </a-flex>
                        <a-flex xs6 class="c-edit-inventory-fields" @click="current_search_module = 'model_no'">
                            <h4 class="body-2 mr-2 mb-2 grey--text text--darken-3 font-weight-bold">Model Number</h4>
                            <a-combobox
                                v-model="inventory_edit_item.model_no"
                                :items="inventory_autosuggest_list"
                                item-text="model_no"
                                item-value="model_no"
                                placeholder="Edit/Add model number"
                                background-color="white"
                                :loading="autosuggest_loading === 'model_no'"
                                :append-icon="null"
                                :search-input.sync="autosuggest_search['model_no']"
                                @input="local_select_autosuggest(inventory_edit_item, 'model_no')"
                                @click="current_search_module = 'model_no'"
                                @clear="local_autosuggest_search_clear(autosuggest_search, 'model_no')"
                                class="c-border-c7 mb-1 u-rounded-corners"
                                solo flat hide-details clearable hide-no-data
                            ></a-combobox>
                            <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                                <template v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.model_no">
                                    <a-icon size="16" color="red darken-2">error</a-icon>
                                    {{ inventory_response.server.errors.model_no[0] }}
                                </template>
                            </a-sheet>
                        </a-flex>
                    </a-layout>
                    <a-layout class="ma-0">
                        <a-flex xs6 class="mr-3 c-edit-inventory-fields" @click="current_search_module = 'part_no'">
                            <h4 class="body-2 mb-2 mr-2 grey--text text--darken-3 font-weight-bold d-inline-block">Part Number</h4>
                            <a-combobox
                                v-model="inventory_edit_item.part_no"
                                :items="inventory_autosuggest_list"
                                item-text="part_no"
                                item-value="part_no"
                                placeholder="Edit/Add part number"
                                background-color="white"
                                :loading="autosuggest_loading === 'part_no'"
                                :append-icon="null"
                                :search-input.sync="autosuggest_search['part_no']"
                                @input="local_select_autosuggest(inventory_edit_item, 'part_no')"
                                @click="current_search_module = 'part_no'"
                                @clear="local_autosuggest_search_clear(autosuggest_search, 'part_no')"
                                class="c-border-c7 mb-1 u-rounded-corners"
                                solo flat hide-details clearable hide-no-data
                            ></a-combobox>
                            <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                                <template v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.part_no">
                                    <a-icon size="16" color="red darken-2">error</a-icon>
                                    {{ inventory_response.server.errors.part_no[0] }}
                                </template>
                            </a-sheet>
                        </a-flex>
                        <a-flex xs6 class="c-edit-inventory-fields" @click="current_search_module = 'serial_no'">
                            <h4 class="body-2 mr-2 mb-2 grey--text text--darken-3 font-weight-bold d-inline-block">Serial Number</h4>
                            <a-combobox
                                v-model="inventory_edit_item.serial_no"
                                :items="inventory_autosuggest_list"
                                item-text="serial_no"
                                item-value="serial_no"
                                placeholder="Edit/Add serial number"
                                background-color="white"
                                :loading="autosuggest_loading === 'serial_no'"
                                :append-icon="null"
                                :search-input.sync="autosuggest_search['serial_no']"
                                @input="local_select_autosuggest(inventory_edit_item, 'serial_no')"
                                @click="current_search_module = 'serial_no'"
                                @clear="local_autosuggest_search_clear(autosuggest_search, 'serial_no')"
                                class="c-border-c7 mb-1 u-rounded-corners"
                                solo flat hide-details clearable hide-no-data
                            ></a-combobox>
                            <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                                <template v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.serial_no">
                                    <a-icon size="16" color="red darken-2">error</a-icon>
                                    {{ inventory_response.server.errors.serial_no[0] }}
                                </template>
                            </a-sheet>
                        </a-flex>
                    </a-layout>
                    <a-layout class="ma-0">
                        <a-flex xs6 class="mr-3 c-edit-inventory-fields" @click="current_search_module = 'hardware_version'">
                            <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-2">Hardware Version</h4>
                            <a-combobox
                                v-model="inventory_edit_item.hardware_version"
                                :items="inventory_autosuggest_list"
                                item-text="hardware_version"
                                item-value="hardware_version"
                                placeholder="Edit/Add hardware version"
                                background-color="white"
                                :loading="autosuggest_loading === 'hardware_version'"
                                :append-icon="null"
                                :search-input.sync="autosuggest_search['hardware_version']"
                                @input="local_select_autosuggest(inventory_edit_item, 'hardware_version')"
                                @click="current_search_module = 'hardware_version'"
                                @clear="local_autosuggest_search_clear(autosuggest_search, 'hardware_version')"
                                class="c-border-c7 mb-1 u-rounded-corners"
                                solo flat hide-details clearable hide-no-data
                            ></a-combobox>
                            <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                                <template v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.hardware_version">
                                    <a-icon size="16" color="red darken-2">error</a-icon>
                                    {{ inventory_response.server.errors.hardware_version[0] }}
                                </template>
                            </a-sheet>
                        </a-flex>
                        <a-flex xs6 class="c-edit-inventory-fields" @click="current_search_module = 'software_version'">
                            <h4 class="body-2 mr-2 mb-2 grey--text text--darken-3 font-weight-bold">Software Version</h4>
                            <a-combobox
                                v-model="inventory_edit_item.software_version"
                                :items="inventory_autosuggest_list"
                                item-text="software_version"
                                item-value="software_version"
                                placeholder="Edit/Add software version"
                                background-color="white"
                                :loading="autosuggest_loading === 'software_version'"
                                :append-icon="null"
                                :search-input.sync="autosuggest_search['software_version']"
                                @input="local_select_autosuggest(inventory_edit_item, 'software_version')"
                                @click="current_search_module = 'software_version'"
                                @clear="local_autosuggest_search_clear(autosuggest_search, 'software_version')"
                                class="c-border-c7 mb-1 u-rounded-corners"
                                solo flat hide-details clearable hide-no-data
                            ></a-combobox>
                            <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                                <template v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.software_version">
                                    <a-icon size="16" color="red darken-2">error</a-icon>
                                    {{ inventory_response.server.errors.software_version[0] }}
                                </template>
                            </a-sheet>
                        </a-flex>
                    </a-layout>
                    <a-layout class="ma-0">
                        <a-flex xs6 class="mr-3 c-edit-inventory-fields">
                            <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-2">Original Value</h4>
                            <a-text-field solo flat hide-details placeholder="Edit/Add amount" v-model="inventory_edit_item.original_amount" background-color="white" class="c-border-c7 mb-1 u-rounded-corners"></a-text-field>
                            <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                                <template v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.original_amount">
                                    <a-icon size="16" color="red darken-2">error</a-icon>
                                    {{ inventory_response.server.errors.original_amount[0] }}
                                </template>
                            </a-sheet>
                        </a-flex>
                        <a-flex xs6 class="c-edit-inventory-fields">
                            <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-2">Current Value</h4>
                            <a-text-field solo flat hide-details placeholder="Edit/Add amount" v-model="inventory_edit_item.current_amount" background-color="white" class="c-border-c7 mb-1 u-rounded-corners"></a-text-field>
                            <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                                <template v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.current_amount">
                                    <a-icon size="16" color="red darken-2">error</a-icon>
                                    {{ inventory_response.server.errors.current_amount[0] }}
                                </template>
                            </a-sheet>
                        </a-flex>
                    </a-layout>
                    <a-flex xs12 class="c-edit-inventory-fields">
                        <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-2">Location</h4>
                        <a-text-field solo flat hide-details placeholder="Edit/Add location" v-model="inventory_edit_item.location" background-color="white" class="c-border-c7 mb-1 u-rounded-corners"></a-text-field>
                        <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                            <template v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.location">
                                <a-icon size="16" color="red darken-2">error</a-icon>
                                {{ inventory_response.server.errors.location[0] }}
                            </template>
                        </a-sheet>
                    </a-flex>
                    <a-flex xs12 class="c-edit-inventory-fields">
                        <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-2">Territory</h4>
                        <component
                            :is="'a-autocomplete'"
                            v-model="inventory_edit_item.territories"
                            :items="territory_list"
                            :error="territory_exist_message"
                            :search-input.sync="inventory_territory_search"
                            :disabled="!$can('inventories.update')"
                            item-text="name"
                            item-value="id"
                            :placeholder="`Select${$can('territories.store') ? ' or create ' : ' '}terrritory`"
                            background-color="white"
                            class="c-border-c7 mb-1 u-rounded-corners"
                            clear-icon="backspace"
                            @keydown.enter="$can('territories.store') ? local_inventory_territory_store() : ''"
                            solo flat hide-details multiple deletable-chips small-chips auto-select-first hide-selected clearable
                        >
                            <!-- @input="$can('inventories.update') ? local_inventory_territory_store() : ''" -->
                            <template v-slot:no-data>
                                <div class="px-4 py-2">
                                    <span v-if="inventory_territory_search != null && inventory_territory_search != '' && $can('territories.store')">
                                        Create <strong>{{ inventory_territory_search }}</strong> as territory. Hit<span style="font-family: monospace;" class="mx-1 font-weight-bold">Enter</span>to create.
                                    </span>
                                    <span class="grey--text" v-else>
                                        No territories found. <template v-if="$can('territories.store')">Enter label and hit enter to create new one.</template>
                                    </span>
                                </div>
                            </template>
                            <template v-slot:selection="{ item }">
                                <g-tags :tag="item" @destroy="local_remove_chips(inventory_edit_item.territories, 'territory', item, $event)"></g-tags>
                            </template>
                            <template v-slot:item="{ item }">
                                <g-tags :tag="item" hide-clear-icon></g-tags>
                            </template>
                        </component>
                        <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                            <template v-if="meta_errors.type === 'Territory'">
                                <a-icon size="16" color="red darken-2">error</a-icon>
                                {{ meta_errors.message }}
                            </template>
                            <template v-if="territory_response.server && territory_response.server.errors && territory_response.server.errors.name">
                                <a-icon size="16" color="red darken-2" class="mr-1">error</a-icon>
                                {{ territory_response.server.errors.name[0] }}
                            </template>
                        </a-sheet>
                    </a-flex>
                    <a-flex xs12 class="c-edit-inventory-fields">
                        <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-2">Tags</h4>
                        <component
                            :is="'a-autocomplete'"
                            v-model="inventory_edit_item.tags"
                            :items="local_filtered_tag_list"
                            :error="tag_exist_message"
                            :search-input.sync="inventory_tag_search"
                            :disabled="!$can('inventories.update')"
                            item-text="label"
                            item-value="id"
                            :placeholder="`Select${$can('tags.store') ? ' or create ' : ' '}tag`"
                            background-color="white"
                            class="c-border-c7 mb-1 u-rounded-corners"
                            clear-icon="backspace"
                            @keydown.enter="$can('tags.store') ? local_inventory_tag_store() : ''"
                            solo flat hide-details multiple deletable-chips small-chips auto-select-first hide-selected clearable
                        >
                            <!-- @input="$can('inventories.update') ? local_inventory_tag_store() : ''" -->
                            <template v-slot:no-data>
                                <div class="px-4 py-2">
                                    <span v-if="inventory_tag_search != null && inventory_tag_search != '' && $can('tags.store')">
                                        Create <strong>{{ inventory_tag_search }}</strong> as tag. Hit<span style="font-family: monospace;" class="mx-1 font-weight-bold">Enter</span>to create.
                                    </span>
                                    <span class="grey--text" v-else>
                                        No tags found. <template v-if="$can('tags.store')">Enter label and hit enter to create new one.</template>
                                    </span>
                                </div>
                            </template>
                            <template v-slot:selection="{ item }">
                                <g-tags :tag="item" @destroy="local_remove_chips(inventory_edit_item.tags, 'tag', item, $event)"></g-tags>
                            </template>
                            <template v-slot:item="{ item }">
                                <g-tags :tag="item" hide-clear-icon></g-tags>
                            </template>
                        </component>
                        <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                            <template v-if="meta_errors.type === 'Tag'">
                                <a-icon size="16" color="red darken-2">error</a-icon>
                                {{ meta_errors.message }}
                            </template>
                            <template v-if="tag_response.server && tag_response.server.errors && tag_response.server.errors.label">
                                <a-icon size="16" color="red darken-2" class="mr-1">error</a-icon>
                                {{ tag_response.server.errors.label[0] }}
                            </template>
                        </a-sheet>
                    </a-flex>
                    <a-flex xs12 class="c-edit-inventory-fields">
                        <h4 class="body-2 grey--text text--darken-3 font-weight-bold mb-2">Notes</h4>
                        <a-textarea
                            :value="inventory_edit_item.notes"
                            @input="inventory_edit_item.notes = $event !== '' ? $event : null"
                            rows="5"
                            placeholder="Edit/Add Notes"
                            class="body-1 white u-rounded-corners mb-1 c-border-c7"
                            solo flat hide-details no-resize
                        />
                        <a-sheet min-height="24" class="md-caption transparent red--text text--darken-2 u-flex align-center">
                            <template v-if="inventory_response.server && inventory_response.server.errors && inventory_response.server.errors.notes">
                                <a-icon size="16" color="red darken-2">error</a-icon>
                                {{ inventory_response.server.errors.notes[0] }}
                            </template>
                        </a-sheet>
                    </a-flex>
                </a-layout>
                <div class="u-wfull u-sticky grey lighten-4" style="bottom: 0px;">
                    <a-divider class="grey lighten-2"></a-divider>
                    <div class="pa-5 py-4 u-flex-center-y" style="column-gap: 12px;">
                        <a-btn
                            :class="[local_is_equal ? 'grey lighten-2' : 'primary white--text']"
                            :disabled="local_is_equal"
                            @click="local_inventory_update()"
                            class="ma-0 u-rounded-corners"
                            text
                        >
                            Save {{ local_is_equal ? '' : ' Changes' }}
                        </a-btn>
                        <a-btn color="grey darken-2" class="grey lighten-2 my-0 u-rounded-corners" text @click="local_inventory_pane_close()">Cancel</a-btn>
                    </div>
                </div>
            </template>
        </div>
        <a-responsive class="grey lighten-3 c-inventory-filters-pane elevation-12 pb-4" ref="pane_filters" :class="{'visible': pane_filters}">
            <div class="py-4">
                <a-layout align-center class="px-4 mb-4">
                    <a-flex shrink class="mr-2">
                        <a-avatar class="primary darken-1" size="45">
                            <a-icon dark>filter_list</a-icon>
                        </a-avatar>
                    </a-flex>
                    <a-flex>
                        <h2 class="md-heading-6 primary--text text--darken-1">Filter Inventory</h2>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink>
                        <a-btn icon small @click="pane_filters = false"><a-icon color="primary" size="18">cancel</a-icon></a-btn>
                    </a-flex>
                </a-layout>
                <a-divider class="my-2"></a-divider>
                <a-layout class="wrap px-4 mt-4">
                    <a-flex xs12 class="mb-4">
                        <a-menu bottom offset-y min-width="352" max-width="352" transition="slide-y-transition" :disabled="local_loading_type.type && local_loading_type.type === 'filters' && local_loading_type.value" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card class="u-rounded-corners u-overflow-hidden c-border-c7 elevation-0" min-width="100%" v-on="on">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div>
                                            <a-progress-circular v-if="local_loading_type.type && local_loading_type.type === 'filters' && local_loading_type.value" indeterminate :width="2" :size="16" class="mr-1" color="orange"></a-progress-circular>
                                            <a-icon v-else size="20" class="ma-0">filter_list</a-icon>
                                            <span class="grey--text text--darken-2 mx-1">Status:
                                            <span class="font-weight-medium" v-if="inventory_filter_types && inventory_filter_types[filter.STATUS] && !inventory_filter_types[filter.UNAVAILABLE]">{{ inventory_filter_types[filter.STATUS] === 'ALL' ? 'All' : inventory_filter_types[filter.STATUS] }}</span></span>
                                            <span class="font-weight-medium" v-if="inventory_filter_types && inventory_filter_types[filter.UNAVAILABLE]"><span>{{ local_filter_status_label }}</span></span>
                                        </div>
                                        <div class="text-xs-right" v-if="!local_loading_type.type && !local_loading_type.value">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-list class="">
                                <a-list-item @click="local_inventory_filter(filter.STATUS,'ALL', 'All')">
                                    <a-list-item-title>
                                        All
                                    </a-list-item-title>
                                </a-list-item>
                                <a-list-item v-for="(item, index) in inventory_status_list" :key="'filter' + index" @click="local_inventory_filter(filter.STATUS, item.id, item.value)">
                                    <a-list-item-title>
                                        {{ item.value }}
                                    </a-list-item-title>
                                </a-list-item>
                                <a-list-item @click="local_inventory_filter(filter.UNAVAILABLE, 0, 'Unavailable')">
                                    <a-list-item-title>
                                        Unavailable
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex xs12 class="mb-4">
                        <a-menu bottom offset-y v-model="search_stage" :close-on-content-click="filter_mode" :disabled="local_loading_type.type && local_loading_type.type === 'filters' && local_loading_type.value" min-width="352" max-width="352" max-height="500" transition="slide-y-transition" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card class="u-rounded-corners u-overflow-hidden c-border-c7 elevation-0" min-width="100%" v-on="on" @click="local_search_stage = ''">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div>
                                            <a-progress-circular v-if="local_loading_type.type && local_loading_type.type === 'filters' && local_loading_type.value" indeterminate :width="2" :size="16" class="mr-1" color="orange"></a-progress-circular>
                                            <a-icon v-else size="20" class="ma-0">filter_list</a-icon>
                                            <span class="grey--text text--darken-2 mx-1" v-if="local_stage_filter_item">Stage:
                                                <span class="md-caption d-inline-block px-2 ma-0 ml-1 u-rounded-corners" v-if="local_stage_filter_item !== 'No Stage' && local_stage_filter_item !== 'All'" style="padding-top: 2px; padding-bottom: 2px;" :style="{backgroundColor: $color(local_stage_filter_item.color, 'color_bg'), color: $color(local_stage_filter_item.color, 'color_text')}">
                                                    {{ local_stage_filter_item.label }}
                                                </span>
                                                <span class="font-weight-medium" v-else>
                                                    {{ local_stage_filter_item }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="text-xs-right" v-if="!local_loading_type.type && !local_loading_type.value">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-divider></a-divider>
                            <a-card class="c-search-inputs-inventory u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                                <a-text-field
                                    v-model="local_search_stage"
                                    placeholder="Search stage"
                                    background-color="neutral"
                                    ref="search_stage_input"
                                    class="body-2 font-weight-normal"
                                    solo flat hide-details
                                    @click="filter_mode = false"
                                >
                                </a-text-field>
                                <a-divider></a-divider>
                                <a-list style="max-height: 400px;" class="overflow-y-auto py-0">
                                    <a-list-item @click="local_stage_filter_set(filter.STAGE,'ALL', 'All')" v-if="!local_search_stage && local_filtered_stage_list.length !== 0">
                                        <a-layout align-center class="py-0">
                                            <div class="member-label u-cursor-pointer">
                                                <h5 class="md-subtitle-1">All</h5>
                                            </div>
                                        </a-layout>
                                    </a-list-item>
                                    <a-list-item v-for="(item, index) in local_filtered_stage_list" :key="'territory' + index" @click="local_stage_filter_set(filter.STAGE, item.id, item)">
                                        <a-layout align-center class="py-0">
                                            <span class="md-subtitle-2 d-inline-block py-1 px-2 mr-2 my-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">
                                                {{ item.label }}
                                            </span>
                                        </a-layout>
                                    </a-list-item>
                                    <a-list-item v-if="local_filtered_stage_list.length === 0" disabled>
                                        <a-list-item-content>
                                            <a-list-item-title v-if="loading">
                                                <h2 class="md-subtitle-1 pa-0 mb-0 text-center">Loading stages...</h2>
                                            </a-list-item-title>
                                            <a-list-item-title v-else>
                                                <h2 class="md-subtitle-1 mb-0 text-center">No Stages found</h2>
                                            </a-list-item-title>
                                        </a-list-item-content>
                                    </a-list-item>
                                </a-list>
                                <a-divider></a-divider>
                                <a-list class="py-0" v-if="!local_search_stage && local_filtered_stage_list.length !== 0" @click="filter_mode = true">
                                    <a-list-item @click="local_stage_filter_set(filter.STAGE,'NONE', 'No Stage')">
                                        <a-list-item-title>
                                            No stage
                                        </a-list-item-title>
                                    </a-list-item>
                                </a-list>
                            </a-card>
                        </a-menu>
                    </a-flex>
                    <a-flex xs12 class="mb-4">
                        <a-menu bottom offset-y v-model="search_user" :close-on-content-click="filter_mode" :disabled="local_loading_type.type && local_loading_type.type === 'filters' && local_loading_type.value" min-width="352" max-width="352" max-height="500" transition="slide-y-transition" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card class="u-rounded-corners u-overflow-hidden c-border-c7 elevation-0" min-width="100%" v-on="on" @click="local_search_user = ''">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div>
                                            <a-progress-circular v-if="local_loading_type.type && local_loading_type.type === 'filters' && local_loading_type.value" indeterminate :width="2" :size="16" class="mr-1" color="orange"></a-progress-circular>
                                            <a-icon v-else size="20" class="ma-0">filter_list</a-icon>
                                            <span class="grey--text text--darken-2 mx-1">Assignee: <span class="font-weight-medium" v-if="inventory_filter_types && inventory_filter_types[filter.ASSIGNEE]" :title="inventory_filter_types[filter.ASSIGNEE] === 'ALL' ? null : inventory_filter_types[filter.ASSIGNEE]">{{ inventory_filter_types && inventory_filter_types[filter.ASSIGNEE] === 'ALL' ? 'All' : local_truncate_text(inventory_filter_types[filter.ASSIGNEE], 20) }}</span></span>
                                        </div>
                                        <div class="text-xs-right" v-if="!local_loading_type.type && !local_loading_type.value">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-divider></a-divider>
                            <a-card class="c-search-inputs-inventory u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                                <a-text-field
                                    v-model="local_search_user"
                                    placeholder="Search Users"
                                    background-color="neutral"
                                    ref="search_user_input"
                                    class="body-2 font-weight-normal"
                                    @input="localSearchUsers(local_search_user, 'assignee')"
                                    @click="filter_mode = false"
                                    solo flat hide-details
                                >
                                </a-text-field>
                                <a-divider></a-divider>
                                <a-list class="py-0" v-if="mixinIsTypeLoading('assignee')">
                                    <a-list-item class="py-1" disabled>
                                        <h2 class="md-subtitle-1 pa-0 mb-0 text-center">Loading Users...</h2>
                                    </a-list-item>
                                </a-list>
                                <template v-else>
                                    <a-list style="max-height: 400px;" class="overflow-y-auto py-0">
                                        <a-list-item @click="local_inventory_filter(filter.ASSIGNEE,'ALL', 'All')" v-if="!local_search_user && user_list && user_list.length !== 0">
                                            <a-layout align-center class="py-0">
                                                <div class="member-avatar mr-2">
                                                    <a-avatar size="32" style="padding: 2px;" color="grey lighten-2">
                                                        <a-icon size="18">people</a-icon>
                                                    </a-avatar>
                                                </div>
                                                <div class="member-label u-cursor-pointer">
                                                    <h5 class="md-subtitle-1">All</h5>
                                                </div>
                                            </a-layout>
                                        </a-list-item>
                                        <a-list-item class="py-0" v-for="(item, index) in user_list" :key="'poc' + index" @click="local_inventory_filter(filter.ASSIGNEE, item.id, item.name)">
                                            <a-list-item-content class="py-2">
                                                <a-layout align-center class="py-0">
                                                    <g-avatar :item="item"></g-avatar>
                                                    <g-profile-info :item="item" set-max-width type-user></g-profile-info>
                                                </a-layout>
                                            </a-list-item-content>
                                        </a-list-item>
                                        <a-list-item class="py-1" v-if="user_list && user_list.length === 0" disabled>
                                            <a-list-item-content>
                                                <a-list-item-title>
                                                    <h2 class="md-subtitle-1 mb-0 text-center">No Users found</h2>
                                                </a-list-item-title>
                                            </a-list-item-content>
                                        </a-list-item>
                                    </a-list>
                                    <a-divider></a-divider>
                                    <a-list class="py-0" v-if="!local_search_user && user_list && user_list.length !== 0" @click="filter_mode = true">
                                        <a-list-item @click="local_inventory_filter(filter.ASSIGNEE,'NONE', 'No Assignee')">
                                            <a-list-item-title>
                                                No Assignee
                                            </a-list-item-title>
                                        </a-list-item>
                                    </a-list>
                                </template>
                            </a-card>
                        </a-menu>
                    </a-flex>
                    <a-flex xs12 class="mb-4">
                        <a-menu bottom offset-y v-model="search_owner" :close-on-content-click="filter_mode" :disabled="local_loading_type.type && local_loading_type.type === 'filters' && local_loading_type.value" min-width="352" max-width="352" max-height="500" transition="slide-y-transition" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card class="u-rounded-corners u-overflow-hidden c-border-c7 elevation-0" min-width="100%" v-on="on" @click="local_search_owner = ''">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div>
                                            <a-progress-circular v-if="local_loading_type.type && local_loading_type.type === 'filters' && local_loading_type.value" indeterminate :width="2" :size="16" class="mr-1" color="orange"></a-progress-circular>
                                            <a-icon v-else size="20" class="ma-0">filter_list</a-icon>
                                            <span class="grey--text text--darken-2 mx-1">Owner: <span class="font-weight-medium" v-if="inventory_filter_types && inventory_filter_types[filter.OWNER]" :title="inventory_filter_types[filter.OWNER] === 'ALL' ? null : inventory_filter_types[filter.OWNER]">{{ inventory_filter_types && inventory_filter_types[filter.OWNER] === 'ALL' ? 'All' : local_truncate_text(inventory_filter_types[filter.OWNER], 20) }}</span></span>
                                        </div>
                                        <div class="text-xs-right" v-if="!local_loading_type.type && !local_loading_type.value">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-divider></a-divider>
                            <a-card class="c-search-inputs-inventory u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                                <a-text-field
                                    v-model="local_search_owner"
                                    placeholder="Search Users"
                                    background-color="neutral"
                                    ref="search_owner_input"
                                    class="body-2 font-weight-normal"
                                    solo flat hide-details
                                    @input="localSearchUsers(local_search_owner, 'owner')"
                                    @click="filter_mode = false"
                                >
                                </a-text-field>
                                <a-divider></a-divider>
                                <a-list class="py-0" v-if="mixinIsTypeLoading('owner')">
                                    <a-list-item class="py-1" disabled>
                                        <h2 class="md-subtitle-1 pa-0 mb-0 text-center">Loading Users...</h2>
                                    </a-list-item>
                                </a-list>
                                <template v-else>
                                    <a-list style="max-height: 400px;" class="overflow-y-auto py-0">
                                        <a-list-item @click="local_inventory_filter(filter.OWNER,'ALL', 'All')" v-if="!local_search_owner && user_list && user_list.length !== 0">
                                            <a-layout align-center class="py-0">
                                                <div class="member-avatar mr-2">
                                                    <a-avatar size="32" style="padding: 2px;" color="grey lighten-2">
                                                        <a-icon size="18">people</a-icon>
                                                    </a-avatar>
                                                </div>
                                                <div class="member-label u-cursor-pointer">
                                                    <h5 class="md-subtitle-1">All</h5>
                                                </div>
                                            </a-layout>
                                        </a-list-item>
                                        <a-list-item class="py-0" v-for="(item, index) in user_list" :key="'poc' + index" @click="local_inventory_filter(filter.OWNER, item.id, item.name)">
                                            <a-list-item-content class="py-2">
                                                <a-layout align-center class="py-0">
                                                    <g-avatar :item="item"></g-avatar>
                                                    <g-profile-info :item="item" set-max-width type-user></g-profile-info>
                                                </a-layout>
                                            </a-list-item-content>
                                        </a-list-item>
                                        <a-list-item class="py-1" v-if="user_list && user_list.length === 0" disabled>
                                            <a-list-item-content>
                                                <a-list-item-title>
                                                    <h2 class="md-subtitle-1 mb-0 text-center">No Users found</h2>
                                                </a-list-item-title>
                                            </a-list-item-content>
                                        </a-list-item>
                                    </a-list>
                                    <a-divider></a-divider>
                                    <a-list class="py-0" v-if="!local_search_owner && user_list && user_list.length !== 0" @click="filter_mode = true">
                                        <a-list-item @click="local_inventory_filter(filter.OWNER,'NONE', 'No Owner')">
                                            <a-list-item-title>
                                                No Owner
                                            </a-list-item-title>
                                        </a-list-item>
                                    </a-list>
                                </template>
                            </a-card>
                        </a-menu>
                    </a-flex>
                    <a-flex xs12 class="mb-4">
                        <a-menu bottom offset-y v-model="search_territory" :close-on-content-click="filter_mode" :disabled="local_loading_type.type && local_loading_type.type === 'filters' && local_loading_type.value" min-width="352" max-width="352" max-height="500" transition="slide-y-transition" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card class="u-rounded-corners u-overflow-hidden c-border-c7 elevation-0" min-width="100%" v-on="on" @click="local_search_territory = ''">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div>
                                            <a-progress-circular v-if="local_loading_type.type && local_loading_type.type === 'filters' && local_loading_type.value" indeterminate :width="2" :size="16" class="mr-1" color="orange"></a-progress-circular>
                                            <a-icon v-else size="20" class="ma-0">filter_list</a-icon>
                                            <span class="grey--text text--darken-2 mx-1" v-if="local_territory_filter_item">Territory:
                                                <span v-if="local_territory_filter_item !== 'No Territory' && local_territory_filter_item !== 'All'">
                                                    <g-tags :tag="local_territory_filter_item" dense hide-clear-icon></g-tags>
                                                </span>
                                                <span v-else class="font-weight-medium">
                                                    {{ local_territory_filter_item }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="text-xs-right" v-if="!local_loading_type.type && !local_loading_type.value">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-divider></a-divider>
                            <a-card class="c-search-inputs-inventory u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                                <a-text-field
                                    v-model="local_search_territory"
                                    placeholder="Search territory"
                                    background-color="neutral"
                                    ref="search_territory_input"
                                    class="body-2 font-weight-normal"
                                    solo flat hide-details
                                    @click="filter_mode = false"
                                >
                                </a-text-field>
                                <a-divider></a-divider>
                                <a-list style="max-height: 400px;" class="overflow-y-auto py-0">
                                    <a-list-item @click="local_territory_filter_set(filter.TERRITORY,'ALL', 'All')" v-if="!local_search_territory && local_filtered_territory_list.length !== 0">
                                        <a-layout align-center class="py-0">
                                            <div class="member-label u-cursor-pointer">
                                                <h5 class="md-subtitle-1">All</h5>
                                            </div>
                                        </a-layout>
                                    </a-list-item>
                                    <a-list-item v-for="(item, index) in local_filtered_territory_list" :key="'territory' + index" @click="local_territory_filter_set(filter.TERRITORY, item.id, item)">
                                        <g-tags :tag="item" dense hide-clear-icon></g-tags>
                                    </a-list-item>
                                    <a-list-item v-if="local_filtered_territory_list.length === 0" disabled>
                                        <a-list-item-content>
                                            <a-list-item-title v-if="loading">
                                                <h2 class="md-subtitle-1 pa-0 mb-0 text-center">Loading territories...</h2>
                                            </a-list-item-title>
                                            <a-list-item-title v-else>
                                                <h2 class="md-subtitle-1 mb-0 text-center">No Territories found</h2>
                                            </a-list-item-title>
                                        </a-list-item-content>
                                    </a-list-item>
                                </a-list>
                                <a-divider></a-divider>
                                <a-list class="py-0" v-if="!local_search_territory && local_filtered_territory_list.length !== 0" @click="filter_mode = true">
                                    <a-list-item @click="local_territory_filter_set(filter.TERRITORY,'NONE', 'No Territory')">
                                        <a-list-item-title>
                                            No territory
                                        </a-list-item-title>
                                    </a-list-item>
                                </a-list>
                            </a-card>
                        </a-menu>
                    </a-flex>
                    <a-flex xs12 class="mb-4">
                        <a-menu bottom offset-y v-model="search_tag" :close-on-content-click="filter_mode" :disabled="local_loading_type.type && local_loading_type.type === 'filters' && local_loading_type.value" min-width="352" max-width="352" max-height="500" transition="slide-y-transition" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card class="u-rounded-corners u-overflow-hidden c-border-c7 elevation-0" min-width="100%" v-on="on" @click="local_search_tag = ''">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div>
                                            <a-progress-circular v-if="local_loading_type.type && local_loading_type.type === 'filters' && local_loading_type.value" indeterminate :width="2" :size="16" class="mr-1" color="orange"></a-progress-circular>
                                            <a-icon v-else size="20" class="ma-0">filter_list</a-icon>
                                            <span class="grey--text text--darken-2 mx-1" v-if="local_tag_filter_item">Tag:
                                                <template v-if="local_tag_filter_item !== 'No Tag' && local_tag_filter_item !== 'All'">
                                                    <g-tags :tag="local_tag_filter_item" dense hide-clear-icon></g-tags>
                                                </template>
                                                <span class="font-weight-medium" v-else>
                                                    {{ local_tag_filter_item }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="text-xs-right" v-if="!local_loading_type.type && !local_loading_type.value">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-divider></a-divider>
                            <a-card class="c-search-inputs-inventory u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                                <a-text-field
                                    v-model="local_search_tag"
                                    placeholder="Search tag"
                                    background-color="neutral"
                                    ref="search_tag_input"
                                    class="body-2 font-weight-normal"
                                    solo flat hide-details
                                    @click="filter_mode = false"
                                >
                                </a-text-field>
                                <a-divider></a-divider>
                                <a-list style="max-height: 400px;" class="overflow-y-auto py-0">
                                    <a-list-item @click="local_tag_filter_set(filter.TAGS,'ALL', 'All')" v-if="!local_search_tag && local_filtered_tag_list.length !== 0">
                                        <a-layout align-center class="py-0">
                                            <div class="member-label u-cursor-pointer">
                                                <h5 class="md-subtitle-1">All</h5>
                                            </div>
                                        </a-layout>
                                    </a-list-item>
                                    <a-list-item v-for="(item, index) in local_filtered_tag_list" :key="'territory' + index" @click="local_tag_filter_set(filter.TAGS, item.id, item)">
                                        <g-tags :tag="item" dense hide-clear-icon></g-tags>
                                    </a-list-item>
                                    <a-list-item v-if="local_filtered_tag_list.length === 0" disabled>
                                        <a-list-item-content>
                                            <a-list-item-title v-if="loading">
                                                <h2 class="md-subtitle-1 pa-0 mb-0 text-center">Loading tags...</h2>
                                            </a-list-item-title>
                                            <a-list-item-title v-else>
                                                <h2 class="md-subtitle-1 mb-0 text-center">No Tags found</h2>
                                            </a-list-item-title>
                                        </a-list-item-content>
                                    </a-list-item>
                                </a-list>
                                <a-divider></a-divider>
                                <a-list class="py-0" v-if="!local_search_tag && local_filtered_tag_list.length !== 0" @click="filter_mode = true">
                                    <a-list-item @click="local_tag_filter_set(filter.TAGS,'NONE', 'No Tag')">
                                        <a-list-item-title>
                                            No tag
                                        </a-list-item-title>
                                    </a-list-item>
                                </a-list>
                            </a-card>
                        </a-menu>
                    </a-flex>
                    <a-flex xs12 class="mb-4">
                        <a-menu bottom offset-y v-model="search_poc" :close-on-content-click="filter_mode" :disabled="local_loading_type.type && local_loading_type.type === 'filters' && local_loading_type.value" min-width="352" max-width="352" max-height="500" transition="slide-y-transition" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card class="u-rounded-corners u-overflow-hidden c-border-c7 elevation-0" min-width="100%" v-on="on" @click="local_search_poc = ''">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div>
                                            <a-progress-circular v-if="local_loading_type.type && local_loading_type.type === 'filters' && local_loading_type.value" indeterminate :width="2" :size="16" class="mr-1" color="orange"></a-progress-circular>
                                            <a-icon v-else size="20" class="ma-0">filter_list</a-icon>
                                            <span class="grey--text text--darken-2 mx-1">Project: <span class="font-weight-medium" v-if="inventory_filter_types" :title="inventory_filter_types[filter.PROJECT] === 'ALL' ? null : inventory_filter_types[filter.PROJECT]">{{ inventory_filter_types && inventory_filter_types[filter.PROJECT] ? inventory_filter_types[filter.PROJECT] === 'ALL' ? 'All' : local_truncate_text(inventory_filter_types[filter.PROJECT], 25) : 'All' }}</span></span>
                                        </div>
                                        <div class="text-xs-right" v-if="!local_loading_type.type && !local_loading_type.value">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-divider></a-divider>
                            <a-card class="c-search-inputs-inventory u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                                <a-text-field
                                    v-model="local_search_poc"
                                    placeholder="Search projects"
                                    background-color="neutral"
                                    ref="search_poc_input"
                                    class="body-2 font-weight-normal"
                                    solo flat hide-details
                                    @click="filter_mode = false"
                                >
                                </a-text-field>
                                <a-divider></a-divider>
                                <a-list style="max-height: 384px;" class="overflow-y-auto py-0">
                                    <a-list-item @click="local_inventory_filter(filter.PROJECT,'ALL', 'All')" v-if="!local_search_poc && local_filtered_poc_list.length !== 0">
                                        <a-list-item-title>
                                            All
                                        </a-list-item-title>
                                    </a-list-item>
                                    <a-list-item v-for="(item, index) in local_filtered_poc_list" :key="'poc' + index" @click="local_inventory_filter(filter.PROJECT, item.id, item.title)">
                                        <a-list-item-title :title="item.title">
                                            {{ item.title }}
                                        </a-list-item-title>
                                    </a-list-item>
                                    <a-list-item v-if="local_filtered_poc_list.length === 0" disabled>
                                        <a-list-item-content>
                                            <a-list-item-title v-if="loading">
                                                <h2 class="md-subtitle-1 pa-0 mb-0 text-center">Loading projects...</h2>
                                            </a-list-item-title>
                                            <a-list-item-title v-else>
                                                <h2 class="md-subtitle-1 mb-0 text-center">No Projects found</h2>
                                            </a-list-item-title>
                                        </a-list-item-content>
                                    </a-list-item>
                                </a-list>
                                <a-divider></a-divider>
                                <a-list class="py-0" v-if="!local_search_poc && local_filtered_poc_list.length !== 0">
                                    <a-list-item @click="local_inventory_filter(filter.PROJECT,'NONE', 'No poc')">
                                        <a-list-item-title>
                                            No Projects
                                        </a-list-item-title>
                                    </a-list-item>
                                </a-list>
                            </a-card>
                        </a-menu>
                    </a-flex>
                    <a-flex xs12 class="mb-3">
                        <a-card class="c-date-range u-rounded-corners c-border-c7 elevation-0 pa-0 py-1 d-flex align-center justify-space-between" min-width="352">
                            <vc-date-picker class="u-wfull" is-range @drag="dragValue = $event" :select-attribute="selectDragAttribute" :drag-attribute="selectDragAttribute" :model-config="modelConfig" @input="local_date_filter" v-model="date_range_picker">
                                <template v-slot:day-popover="{ format }">
                                    <div>
                                        {{ format(dragValue ? dragValue.start : date_range_picker.start, 'MMM D') }} - {{ format(dragValue ? dragValue.end : date_range_picker.end, 'MMM D') }}
                                    </div>
                                </template>
                                 <template v-slot="{ togglePopover }">
                                     <div class="d-flex align-center">
                                        <a-card flat class="pa-1 u-wfull u-cursor-pointer px-2 grey--text text--darken-2 d-inline-flex align-center" @click="togglePopover({ placement: 'auto-start' })" min-width="250" v-if="!current_day">
                                            <a-icon size="20" class="ma-0" style="max-width: 20px;">date_range</a-icon>
                                            <span class="ml-2"> {{ local_format_date(inventory_filter_types.booking_from) }} - {{ local_format_date(inventory_filter_types.booking_to) }}</span>
                                        </a-card>
                                        <a-card flat class="pa-1 u-wfull u-cursor-pointer px-2 grey--text text--darken-2 d-flex align-center" @click="togglePopover({ placement: 'auto-start' })" min-width="250" v-else>
                                            <a-icon size="20" class="ma-0" style="max-width: 20px;">date_range</a-icon>
                                            <span class="ml-2">Today</span>
                                        </a-card>
                                        <a-tooltip bottom v-if="!current_day" class="text-xs-right">
                                            <template v-slot:activator="{ on }">
                                                <a-btn icon small class="mx-2 my-0" v-on="on" style="max-height: 22px; max-width: 22px;" @click="local_clear_date_filter()">
                                                    <a-icon size="18" color="grey darken-1">cancel</a-icon>
                                                </a-btn>
                                            </template>
                                            <span>Clear Date</span>
                                        </a-tooltip>
                                     </div>
                                 </template>
                            </vc-date-picker>
                        </a-card>
                    </a-flex>
                    <a-flex xs12 class="my-3">
                        <a-divider class="grey lighten-2"></a-divider>
                    </a-flex>
                    <a-flex xs12 class="mb-2">
                        <div class="align-center" style="display: flex">
                            <h2 class="title grey--text text--darken-2 font-weight-medium mr-4">Sort</h2>
                        </div>
                    </a-flex>
                    <a-flex xs12 class="mb-4">
                        <a-menu bottom offset-y min-width="352" max-width="352" max-height="500" transition="slide-y-transition" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card class="u-overflow-hidden u-rounded-corners c-border-c7 elevation-0" min-width="100%" v-on="on">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div>
                                            <a-icon size="20" class="ma-0">sort</a-icon>
                                            <span class="grey--text text--darken-2 mx-1 text-capitalize">Sort by: <span class="font-weight-medium" v-if="inventory_filter_types && inventory_filter_types[filter.SORT]">{{ sort_by }}</span></span>
                                        </div>
                                        <div class="text-xs-right">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-list class="">
                                <a-list-item v-for="(item, index) in sort_inventory" :key="'sortt' + index" @click="local_inventory_filter('sort_by', item.value, item.name)">
                                    <a-list-item-title>
                                        Sort by: {{ item.name }}
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex xs12 class="mb-4">
                        <a-menu bottom offset-y min-width="352" max-width="352" max-height="500" transition="slide-y-transition" class="d-block">
                            <template v-slot:activator="{ on }">
                                <a-card class="u-overflow-hidden u-rounded-corners c-border-c7 elevation-0" min-width="100%" v-on="on">
                                    <a-card-text class="px-2 py-2 d-flex align-center justify-space-between">
                                        <div v-if="inventory_filter_types">
                                            <a-icon size="22" class="ma-0">{{ sort_direction === ''  ? 'arrow_downward' : 'arrow_upward' }}</a-icon>
                                            <!-- <span class="grey--text text--darken-2 mx-1">Sort: <span class="font-weight-medium">{{ inventory_filter_types[filter.SORT_ORDER] === 'Ascending' || inventory_filter_types[filter.SORT_ORDER] === 'asc' ? 'Ascending' : inventory_filter_types[filter.SORT_ORDER] }}</span></span> -->
                                            <span class="grey--text text--darken-2 mx-1">Sort: <span class="font-weight-medium">{{ sort_direction === '' ? 'Ascending' : 'Descending' }}</span></span>
                                        </div>
                                        <div class="text-xs-right">
                                            <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                        </div>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-list class="">
                                <a-list-item v-for="(item, index) in sort_order_inventory" :key="'sort_order' + index" @click="local_inventory_filter('sort_direction', item.value)">
                                    <a-list-item-title>
                                        {{ item.name }}
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                </a-layout>
                <a-layout class="ma-0 pa-0 px-4 mt-4">
                    <a-flex xs12>
                        <a-btn color="white" class="primary ma-0 u-rounded-corners" :loading="local_loading" text @click="local_fetch_inventory()">Filter</a-btn>
                        <a-btn color="grey" class="grey lighten-2 my-0 ml-4 u-rounded-corners" text @click="pane_filters = false">Cancel</a-btn>
                    </a-flex>
                </a-layout>
            </div>
        </a-responsive>
        <a-dialog v-model="inventory_detail_dialog" lazy scrollable transition="slide-y-transition" min-height="600" max-width="800">
            <a-card>
                <a-card-title class="px-0 pb-0">
                    <a-container container--fluid grid-list-xl class="pa-0 px-5 mb-3">
                        <a-layout wrap align-start>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="45">
                                    <a-icon dark>memory</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1">Inventory Detail</h2>
                                <p class="md-body-2 mb-0 mt-n1">View inventory detail and its booking information.</p>
                            </a-flex>
                            <a-spacer></a-spacer>
                            <a-flex shrink>
                                <a-btn icon small color="grey darken-3" @click="inventory_detail_dialog = false">
                                    <a-icon size="18">cancel</a-icon>
                                </a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-container container--fluid grid-list-xl class="pa-0">
                        <a-divider></a-divider>
                        <div class="grey lighten-3 px-4">
                            <div class="u-flex align-center">
                                <div class="d-inline-block mr-2" @click="local_change_detail_tab(inventory_detail, 'Inventory Detail')">
                                    <a-btn text class="text-capitalize grey--text text--darken-3 md-body-2 ma-0 py-1 px-2">Inventory Detail</a-btn>
                                    <a-divider v-if="detail_tab_active === 'Inventory Detail'" class="grey darken-2"></a-divider>
                                    <a-divider v-else class="grey lighten-3"></a-divider>
                                </div>
                                <div class="d-inline-block" @click="local_change_detail_tab(inventory_detail, 'Booking Information')">
                                    <a-btn text class="text-capitalize grey--text text--darken-3 md-body-2 ma-0 py-1 px-2">Booking Information</a-btn>
                                    <a-divider v-if="detail_tab_active === 'Booking Information'" class="grey darken-2"></a-divider>
                                    <a-divider v-else class="grey lighten-3"></a-divider>
                                </div>
                                <template v-if="permission_inventory_update()">
                                    <a-spacer></a-spacer>
                                    <a-btn small text class="ma-0 ml-2 px-2 primary u-rounded-corners" dark @click="switch_to_edit_inventory_pane()">Edit Inventory</a-btn>
                                </template>
                            </div>
                        </div>
                        <a-divider></a-divider>
                    </a-container>
                </a-card-title>
                <a-card-text class="pa-0">
                    <div>
                        <a-container key="detail" v-if="detail_tab_active === 'Inventory Detail'" container--fluid grid-list-xl class="px-3 py-4 u-overflow-hidden" style="min-height: 600px;">
                            <a-sheet class="d-flex align-center" min-height="500" v-if="local_book_info_loading.type === 'book-info' && local_book_info_loading.value">
                                <a-layout class="align-center justify-center ma-0 pa-0">
                                    <a-flex xs12>
                                        <a-card max-width="200" class="u-rounded-corners elevation-0" style="margin: 0 auto;">
                                            <a-progress-linear height="5" indeterminate color="orange"></a-progress-linear>
                                        </a-card>
                                    </a-flex>
                                </a-layout>
                            </a-sheet>
                            <template v-else>
                                <a-layout class="px-4 fill-height" v-if="inventory_detail">
                                    <a-flex xs12 class="py-0">
                                        <a-layout class="mb-4 mt-2 c-edit-input-inventory-wrap">
                                            <a-flex xs8 class="py-0">
                                                <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-1">Name</h4>
                                                <template>
                                                    <p class="md-body-2 grey--text text--darken-3 ma-0">{{ inventory_detail.name }}</p>
                                                </template>
                                            </a-flex>
                                        </a-layout>
                                        <div class="c-edit-input-inventory-wrap">
                                            <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-1">Description</h4>
                                            <template v-if="inventory_detail.description">
                                                <p class="md-body-2 grey--text text--darken-3 ma-0 mb-4" style="white-space: pre-line">{{ inventory_detail.description }}</p>
                                            </template>
                                            <template v-else>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0 mb-2"> - </p>
                                            </template>
                                        </div>
                                        <a-divider class="mb-4"></a-divider>
                                        <a-layout class="mb-4 c-edit-input-inventory-wrap">
                                            <a-flex xs8 class="py-0">
                                                <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-1">Status</h4>
                                                <a-menu bottom offset-y :close-on-content-click="true"  max-width="380" transition="slide-y-transition" class="u-wfull" v-if="permission_inventory_update()">
                                                    <template v-slot:activator="{ on, value }">
                                                        <v-chip v-on="on" class="ma-0 mt-1 white--text rounded-corners" label small :color="inventory_detail.is_available === 0 ? '#c1c1c1':'green'">
                                                            <span v-if="inventory_detail.is_available === 0">Unavailable</span>
                                                            <span v-else>Available</span>
                                                            <div class="text-xs-right ml-2">
                                                                <a-progress-circular v-if="local_loading_available" color="white darken-2" size="16" width="2" :indeterminate="true"></a-progress-circular>
                                                                <a-icon size="20" class="ma-0" v-else >arrow_drop_{{ value ? 'up' : 'down' }}</a-icon>
                                                            </div>
                                                        </v-chip>
                                                    </template>
                                                    <a-list class="py-0 u-list-condensed white">
                                                        <a-list-item @click="inventory_detail.is_available === 1 ? '' : local_update_available(1)">
                                                            <span class="md-body-2 grey--text text--darken-3">Available</span>
                                                        </a-list-item>
                                                        <a-list-item v-if="local_check_filter_bookings" @click="inventory_detail.is_available === 0 ? '' : local_update_available(0)">
                                                            <span class="md-body-2 grey--text text--darken-3">Unavailable</span>
                                                        </a-list-item>
                                                        <a-tooltip right v-else content-class="c-tooltip-pointer c-tooltip-pointer--left">
                                                            <template v-slot:activator="{ on }">
                                                                <a-list-item v-on="on" class="md-body-2">
                                                                    <span :class="{'grey--text text--lighten-1' : !local_check_filter_bookings }">Unavailable</span>
                                                                </a-list-item>
                                                            </template>
                                                            <span v-if="local_filter_inventory_booking.in_use.data && local_filter_inventory_booking.in_use.data.length && (!local_filter_inventory_booking.booked.data || !local_filter_inventory_booking.booked.data.length)">{{ local_check_inventory_booking(local_filter_inventory_booking.in_use.data, local_filter_inventory_booking.booked.data) }}</span>
                                                            <div style="max-width: 296px;" v-else>
                                                                {{ local_check_inventory_booking(local_filter_inventory_booking.in_use.data, local_filter_inventory_booking.booked.data) }}
                                                            </div>
                                                        </a-tooltip>
                                                    </a-list>
                                                </a-menu>
                                                <v-chip class="ma-0 mt-1 white--text rounded-corners" label small v-else :color="inventory_detail.is_available === 0 ? '#c1c1c1':'green'">
                                                    <span v-if="inventory_detail.is_available === 0">Unavailable</span>
                                                    <span v-else>Available</span>
                                                </v-chip>
                                            </a-flex>
                                            <a-flex xs4 class="py-0">
                                                <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-1">Part Number</h4>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-if="inventory_detail.part_no">{{ inventory_detail.part_no }}</p>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-else> - </p>
                                            </a-flex>
                                        </a-layout>
                                        <a-layout class="mb-4 c-edit-input-inventory-wrap">
                                            <a-flex xs8 class="py-0">
                                                <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-1">Serial Number</h4>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-if="inventory_detail.serial_no">{{ inventory_detail.serial_no }}</p>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-else> - </p>
                                            </a-flex>
                                            <a-flex xs4 class="py-0">
                                                <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-1">Model Number</h4>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-if="inventory_detail.model_no">{{ inventory_detail.model_no }}</p>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-else> - </p>
                                            </a-flex>
                                        </a-layout>
                                        <a-layout class="mb-4 c-edit-input-inventory-wrap">
                                            <a-flex xs8 class="py-0">
                                                <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-1">OEM</h4>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-if="inventory_detail.oem">{{ inventory_detail.oem }}</p>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-else> - </p>
                                            </a-flex>
                                            <a-flex xs4 class="py-0">
                                                <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-1">Hardware Version</h4>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-if="inventory_detail.hardware_version">{{ inventory_detail.hardware_version }}</p>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-else> - </p>
                                            </a-flex>
                                        </a-layout>
                                        <a-layout class="mb-4 c-edit-input-inventory-wrap">
                                            <a-flex xs8 class="py-0">
                                                <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-1">Software Version</h4>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-if="inventory_detail.software_version">{{ inventory_detail.software_version }}</p>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-else> - </p>
                                            </a-flex>
                                            <a-flex xs4 class="py-0">
                                                <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-1">Original Value</h4>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-if="inventory_detail.original_amount">{{ inventory_detail.original_amount }}</p>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-else> - </p>
                                            </a-flex>
                                        </a-layout>
                                        <a-layout class="mb-4 c-edit-input-inventory-wrap">
                                            <a-flex xs8 class="py-0">
                                                <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-1">Current Value</h4>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-if="inventory_detail.current_amount">{{ inventory_detail.current_amount }}</p>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-else> - </p>
                                            </a-flex>
                                            <a-flex xs4 class="py-0">
                                                <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-1">Location</h4>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-if="inventory_detail.location">{{ inventory_detail.location }}</p>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-else> - </p>
                                            </a-flex>
                                        </a-layout>
                                        <a-layout class="mb-4">
                                            <a-flex xs8 class="py-0 c-edit-input-inventory-wrap">
                                                <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-2">Territory</h4>
                                                <div class="u-flex align-start" style="flex-wrap: wrap" v-if="inventory_detail.territories && inventory_detail.territories.length">
                                                    <g-tags v-for="territory in inventory_detail.territories" :key="territory.id" :tag="territory" :text-range="20" hide-clear-icon></g-tags>
                                                </div>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-else> - </p>
                                            </a-flex>
                                            <a-flex xs4 class="py-0 c-edit-input-inventory-wrap">
                                                <h4 class="body-2 mr-2 mb-1 grey--text text--darken-3 font-weight-bold d-inline-flex align-center">Category</h4>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-if="inventory_detail.category && inventory_detail.category.value">{{ inventory_detail.category.value }}</p>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-else>No Category</p>
                                            </a-flex>
                                        </a-layout>
                                        <a-layout class="mb-4 c-edit-input-inventory-wrap">
                                            <a-flex xs8 class="py-0 c-edit-input-inventory-wrap">
                                                <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-2">Tags</h4>
                                                <div class="u-flex align-start" style="flex-wrap: wrap" v-if="inventory_detail.tags && inventory_detail.tags.length">
                                                    <g-tags v-for="tag in inventory_detail.tags" :text-range="30" :tag="tag" :key="tag.id" hide-clear-icon></g-tags>
                                                </div>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-else> - </p>
                                            </a-flex>
                                            <a-flex xs4 class="py-0">
                                                <h4 class="body-2 mr-2 mb-1 grey--text text--darken-3 font-weight-bold d-inline-flex align-center">Owner</h4>
                                                <div class="d-flex align-center" v-if="inventory_detail.owner">
                                                    <g-avatar :item="inventory_detail.owner" :size="24"></g-avatar>
                                                    <p class="md-body-2 grey--text text--darken-3 ma-0" v-if="inventory_detail.owner">{{ inventory_detail.owner.name }}</p>
                                                </div>
                                                <template v-else>
                                                    <p class="md-body-2 grey--text text--darken-3 ma-0"> - </p>
                                                </template>
                                            </a-flex>
                                        </a-layout>
                                        <a-layout class="mb-2 c-edit-input-inventory-wrap">
                                            <a-flex xs12 class="py-0">
                                                <h4 class="body-2 mr-2 grey--text text--darken-3 font-weight-bold mb-1">Notes</h4>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-if="inventory_detail.notes">{{ inventory_detail.notes }}</p>
                                                <p class="md-body-2 grey--text text--darken-3 ma-0" v-else> - </p>
                                            </a-flex>
                                        </a-layout>
                                    </a-flex>
                                </a-layout>
                            </template>
                        </a-container>
                        <a-container key="booking" v-if="detail_tab_active === 'Booking Information'" container--fluid grid-list-xl class="pa-0 u-overflow-hidden">
                            <a-layout class="ma-0 pa-0 fill-height">
                                <a-flex xs12 class="pa-0">
                                    <a-card class="elevation-0 grey lighten-4 px-5 pt-5">
                                        <div class="pb-5" v-if="local_filter_inventory_booking.in_use.status === 'In Use' && local_filter_inventory_booking.in_use.data && local_filter_inventory_booking.in_use.data.length">
                                            <a-card class="white u-elevation-custom-1 u-rounded-corners px-3 pt-3 pb-2">
                                                <a-layout align-center class="wrap ma-0 pa-0">
                                                    <a-flex class="pa-0" xs9>
                                                        <a-chip label small v-if="local_filter_inventory_booking.in_use.data[0].project && local_filter_inventory_booking.in_use.data[0].project.customer" :title="local_filter_inventory_booking.in_use.data[0].project.customer.name && local_filter_inventory_booking.in_use.data[0].project.customer.name.length >= 50 ? local_filter_inventory_booking.in_use.data[0].project.customer.name : ''" class="ma-0 body-2 font-weight-normal grey--text text--darken-1">{{ local_truncate_text(local_filter_inventory_booking.in_use.data[0].project.customer.name, 50) }}</a-chip>
                                                        <h3 v-if="local_filter_inventory_booking.in_use.data && local_filter_inventory_booking.in_use.data[0] && local_filter_inventory_booking.in_use.data[0].project" class="subheading ma-0 grey--text text--darken-2 font-weight-medium mt-2 mb-1" style="line-height: 1.5 !important">{{ local_filter_inventory_booking.in_use.data[0].project.title }}</h3>
                                                        <div class="d-inline-flex align-center">
                                                            <g-currency tag="span" class="md-subtitle-1 d-inline-block green--text text--lighten-1" v-if="local_filter_inventory_booking.in_use.data[0].project" :value="local_filter_inventory_booking.in_use.data[0].project.deal_amount"></g-currency>
                                                            <div class="d-inline-flex align-center mx-3">
                                                                <g-avatar :item="local_filter_inventory_booking.in_use.data[0].assignee[0]" :size="24" :right-space="false"></g-avatar>
                                                                <h4 class="body-2 ml-2 grey--text text--darken-2 font-weight-medium" v-if="local_filter_inventory_booking.in_use.data[0].assignee[0]">{{ local_filter_inventory_booking.in_use.data[0].assignee[0].name }}</h4>
                                                            </div>
                                                            <div class="d-inline-flex align-center ml-2">
                                                                <g-moment tag="span" class="body-2 grey--text font-weight-normal" :value="local_filter_inventory_booking.in_use.data[0].booking_from" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                                                <span class="mx-1 grey--text font-weight-normal"> - </span>
                                                                <g-moment tag="span" class="body-2 grey--text font-weight-normal" :value="local_filter_inventory_booking.in_use.data[0].actual_booking_to" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                                            </div>
                                                        </div>
                                                    </a-flex>
                                                    <a-flex class="pa-0" xs3>
                                                        <div class="u-flex align-center justify-end u-relative">
                                                            <a-chip label small class="my-0 ml-0 u-rounded-corners orange darken-1">
                                                                <h4 class="body-2 font-weight-medium white--text">{{ local_filter_inventory_booking.in_use.data[0].status }}</h4>
                                                                <span v-if="local_filter_inventory_booking.in_use.data[0].force_extended === 1">
                                                                    <a-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <a-icon v-on="on" size="14" class="px-2 py-1 pr-0" color="white darken-5">info</a-icon>
                                                                        </template>
                                                                        <span>In Use and Overdue</span>
                                                                    </a-tooltip>
                                                                </span>
                                                            </a-chip>
                                                            <router-link tag="a" style="height: 24px; padding: 1px 10px" v-if="$can('inventories.update')" class="body-2 white--text ma-0 ml-2 d-inline-block u-rounded-corners blue darken-2" :to="{ name: 'booking-extend', params: { id: local_filter_inventory_booking.in_use.data[0].batch } }">
                                                                Manage
                                                            </router-link>
                                                        </div>
                                                    </a-flex>
                                                    <a-flex xs12 class="pa-0 mt-2">
                                                        <a-divider class="grey lighten-3 mb-2"></a-divider>
                                                        <div class="d-inline-flex align-center">
                                                            <a-avatar color="grey lighten-2" size="16">
                                                                <a-icon size="12" color="grey darken-1">place</a-icon>
                                                            </a-avatar>
                                                            <h3 class="body-2 ma-0 grey--text text--darken-2 font-weight-normal ml-2" style="margin-top: 2px !important;" v-if="local_filter_inventory_booking.in_use.data[0].location">{{ local_filter_inventory_booking.in_use.data[0].location }}</h3>
                                                            <h3 class="body-2 ma-0 grey--text text--darken-2 font-weight-normal ml-2" style="margin-top: 2px !important;" v-else>No Location</h3>
                                                        </div>
                                                    </a-flex>
                                                </a-layout>
                                            </a-card>
                                        </div>
                                        <div class="pb-5 mb-1" v-if="local_filter_inventory_booking.booked.status === 'Booked' && local_filter_inventory_booking.booked.data && local_filter_inventory_booking.booked.data.length" :class="{ 'mt-2': local_filter_inventory_booking.in_use.data, 'mt-0': !local_filter_inventory_booking.in_use.data }">
                                            <h2 class="text-uppercase grey--text text--darken-1 body-2 font-weight-bold mb-3" style="letter-spacing: 0.08rem;">Upcoming</h2>
                                            <a-card v-for="booking_item in local_filter_inventory_booking.booked.data" :key="booking_item.id + 'item'" class="white u-elevation-custom-1 u-rounded-corners px-3 pt-3 pb-2 c-booking-cards--booked">
                                                <a-layout align-center class="wrap ma-0 pa-0">
                                                    <a-flex class="pa-0" xs9>
                                                        <a-chip label small class="ma-0 body-2 font-weight-normal grey--text text--darken-1" v-if="booking_item && booking_item.project && booking_item.project.customer">{{ local_truncate_text(booking_item.project.customer.name, 50) }}</a-chip>
                                                        <h3 class="subheading ma-0 grey--text text--darken-2 font-weight-medium mt-2 mb-1" style="line-height: 1.5 !important" v-if="booking_item.project">{{ booking_item.project.title }}</h3>
                                                        <div class="d-inline-flex align-center" v-if="booking_item && booking_item.project">
                                                            <g-currency tag="span" class="md-subtitle-1 d-inline-block green--text text--lighten-1" :value="booking_item.project.deal_amount"></g-currency>
                                                            <div class="d-inline-flex align-center mx-3" v-if="booking_item.assignee && booking_item.assignee.length">
                                                                <g-avatar :item="booking_item.assignee[0]" :size="24" :right-space="false"></g-avatar>
                                                                <h4 class="body-2 ml-2 grey--text text--darken-2 font-weight-medium" v-if="booking_item.assignee[0]">{{ booking_item.assignee[0].name }}</h4>
                                                            </div>
                                                            <div class="d-inline-flex align-center ml-2">
                                                                <g-moment tag="span" class="body-2 grey--text font-weight-normal" :value="booking_item.booking_from" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                                                <span class="mx-1 grey--text font-weight-normal"> - </span>
                                                                <g-moment tag="span" class="body-2 grey--text font-weight-normal" :value="booking_item.actual_booking_to" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                                            </div>
                                                        </div>
                                                    </a-flex>
                                                    <a-flex class="pa-0" xs3>
                                                        <div class="u-flex align-center justify-end">
                                                            <a-chip label small class="ma-0 u-rounded-corners light-green lighten-3 mr-1">
                                                                <h4 class="body-2 green--text text--darken-3 font-weight-medium">{{ booking_item.status }}</h4>
                                                            </a-chip>
                                                            <router-link tag="a" style="height: 24px; padding: 1px 10px" v-if="$can('inventories.update')" class="body-2 white--text ma-0 ml-2 d-inline-block u-rounded-corners blue darken-2" :to="{ name: 'booking-extend', params: { id: booking_item.batch } }">
                                                                Manage
                                                            </router-link>
                                                        </div>
                                                        <!-- <div class="u-flex align-center justify-end">
                                                            <a-menu offset-y class="ma-0" max-height="300" content-class="u-elevation-custom-1 u-border u-rounded-corners">
                                                                <a-chip label small v-on="on" class="ma-0 u-rounded-corners light-green lighten-3">
                                                                    <div class="d-flex align-center text-center" style="min-width: 65px;" v-if="local_book_info_loading.type === 'cancel-booking' && local_book_info_loading.value && local_book_info_loading.id === booking_item.id">
                                                                        <a-progress-circular indeterminate size="16" color="green darken-4"></a-progress-circular>
                                                                    </div>
                                                                    <div class="d-flex align-center" v-else>
                                                                        <h4 class="body-2 green--text text--darken-3 font-weight-medium">{{ booking_item.status }}</h4>
                                                                        <a-icon size="18" class="ml-2 green--text text--darken-3">arrow_drop_down</a-icon>
                                                                    </div>
                                                                </a-chip>
                                                                <a-list class="u-list-condensed u-cursor-pointer">
                                                                    <a-list-item v-for="(status, index) in ['Cancel Booking']" @click="local_cancel_booking(booking_item)" :key="'stat' + index">
                                                                        <a-list-item-title>
                                                                            <span class="md-body-2">{{ status }}</span>
                                                                        </a-list-item-title>
                                                                    </a-list-item>
                                                                </a-list>
                                                            </a-menu>
                                                            <router-link tag="a" style="height: 24px; padding: 1px 10px" class="body-2 white--text ma-0 d-inline-block u-rounded-corners blue darken-2" :to="{ name: 'booking-extend', params: { id: local_filter_inventory_booking.booked.data[0].batch } }">
                                                                Manage
                                                            </router-link>
                                                        </div> -->
                                                    </a-flex>
                                                    <a-flex xs12 class="pa-0 mt-2">
                                                        <a-divider class="grey lighten-3 mb-2"></a-divider>
                                                        <div class="d-inline-flex align-center">
                                                            <a-avatar color="grey lighten-2" size="16">
                                                                <a-icon size="12" color="grey darken-1">place</a-icon>
                                                            </a-avatar>
                                                            <h3 class="body-2 ma-0 grey--text text--darken-2 font-weight-normal ml-2" style="margin-top: 2px !important;" v-if="booking_item.location">{{ booking_item.location }}</h3>
                                                            <h3 class="body-2 ma-0 grey--text text--darken-2 font-weight-normal ml-2" style="margin-top: 2px !important;" v-else>No Location</h3>
                                                        </div>
                                                    </a-flex>
                                                </a-layout>
                                            </a-card>
                                        </div>
                                        <div class="pb-5 mb-1" v-if="local_filter_inventory_booking.returned.status === 'Returned' && local_filter_inventory_booking.returned.data && local_filter_inventory_booking.returned.data.length" :class="{ 'mt-2': local_filter_inventory_booking.booked.data, 'mt-0': !local_filter_inventory_booking.booked.data }">
                                            <h2 class="text-uppercase grey--text text--darken-1 font-weight-bold body-2 mb-3" style="letter-spacing: 0.08rem;">History</h2>
                                            <a-card v-for="booking_item in local_filter_inventory_booking.returned.data" :key="booking_item.id + 'item'" class="white u-elevation-custom-1 u-rounded-corners px-3 pt-3 pb-2 c-booking-cards--returned">
                                                <a-layout align-center class="wrap ma-0 pa-0">
                                                    <a-flex class="pa-0" xs9>
                                                        <a-chip label small class="ma-0 body-2 font-weight-normal grey--text text--darken-1" v-if="booking_item && booking_item.project && booking_item.project.customer">{{ local_truncate_text(booking_item.project.customer.name, 50) }}</a-chip>
                                                        <h3 class="subheading ma-0 grey--text text--darken-2 font-weight-medium mt-2 mb-1" style="line-height: 1.5 !important" v-if="booking_item.project">{{ booking_item.project.title }}</h3>
                                                        <div class="d-inline-flex align-center" v-if="booking_item && booking_item.project">
                                                            <g-currency tag="span" class="md-subtitle-1 d-inline-block green--text text--lighten-1" :value="booking_item.project.deal_amount"></g-currency>
                                                            <div class="d-inline-flex align-center mx-3" v-if="booking_item.assignee && booking_item.assignee.length">
                                                                <g-avatar :item="booking_item.assignee[0]" :size="24" :right-space="false"></g-avatar>
                                                                <h4 class="body-2 ml-2 grey--text text--darken-2 font-weight-medium" v-if="booking_item.assignee[0]">{{ booking_item.assignee[0].name }}</h4>
                                                            </div>
                                                            <div class="d-inline-flex align-center ml-2">
                                                                <g-moment tag="span" class="body-2 grey--text font-weight-normal" :value="booking_item.booking_from" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                                                <span class="mx-1 grey--text font-weight-normal"> - </span>
                                                                <g-moment tag="span" class="body-2 grey--text font-weight-normal" :value="booking_item.actual_booking_to" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                                            </div>
                                                        </div>
                                                    </a-flex>
                                                    <a-flex class="pa-0" xs3>
                                                        <!-- <a-chip label small v-on="on" class="ma-0 u-rounded-corners grey lighten-2">
                                                            <div class="d-flex align-center">
                                                                <h4 class="body-1 grey--text text--darken-1 font-weight-medium">{{ booking_item.status }}</h4>
                                                            </div>
                                                        </a-chip> -->
                                                        <div class="u-flex align-center justify-end">
                                                            <a-chip label small class="ma-0 u-rounded-corners grey lighten-2 mr-1">
                                                                <h4 class="body-2 grey--text text--darken-1 font-weight-medium">{{ booking_item.status }}</h4>
                                                            </a-chip>
                                                            <router-link tag="a" style="height: 24px; padding: 1px 10px" v-if="$can('inventories.update')" class="body-2 white--text ma-0 ml-2 d-inline-block u-rounded-corners blue darken-2" :to="{ name: 'booking-extend', params: { id: booking_item.batch } }">
                                                                Manage
                                                            </router-link>
                                                        </div>
                                                    </a-flex>
                                                    <a-flex xs12 class="pa-0 mt-2">
                                                        <a-divider class="grey lighten-3 mb-2"></a-divider>
                                                        <div class="d-inline-flex align-center">
                                                            <a-avatar color="grey lighten-2" size="16">
                                                                <a-icon size="12" color="grey darken-1">place</a-icon>
                                                            </a-avatar>
                                                            <h3 class="body-2 ma-0 grey--text text--darken-2 font-weight-normal ml-2" style="margin-top: 2px !important;" v-if="booking_item.location">{{ booking_item.location }}</h3>
                                                            <h3 class="body-2 ma-0 grey--text text--darken-2 font-weight-normal ml-2" style="margin-top: 2px !important;" v-else>No Location</h3>
                                                        </div>
                                                    </a-flex>
                                                </a-layout>
                                            </a-card>
                                        </div>
                                        <div class="pb-5 mb-1" v-if="local_filter_inventory_booking.cancelled.status === 'Cancelled' && local_filter_inventory_booking.cancelled.data && local_filter_inventory_booking.cancelled.data.length" :class="{ 'mt-2': local_filter_inventory_booking.booked.data, 'mt-0': !local_filter_inventory_booking.booked.data }">
                                            <a-card v-for="booking_item in local_filter_inventory_booking.cancelled.data" :key="booking_item.id + 'item'" class="white u-elevation-custom-1 u-rounded-corners px-3 pt-3 pb-2 c-booking-cards--returned">
                                                <a-layout align-center class="wrap ma-0 pa-0">
                                                    <a-flex class="pa-0" xs9>
                                                        <a-chip label small class="ma-0 body-2 font-weight-normal grey--text text--darken-1" v-if="booking_item && booking_item.project && booking_item.project.customer">{{ local_truncate_text(booking_item.project.customer.name, 50) }}</a-chip>
                                                        <h3 class="subheading ma-0 grey--text text--darken-2 font-weight-medium mt-2 mb-1" style="line-height: 1.5 !important" v-if="booking_item.project">{{ booking_item.project.title }}</h3>
                                                        <div class="d-inline-flex align-center" v-if="booking_item && booking_item.project">
                                                            <g-currency tag="span" class="md-subtitle-1 d-inline-block green--text text--lighten-1" :value="booking_item.project.deal_amount"></g-currency>
                                                            <div class="d-inline-flex align-center mx-3">
                                                                <g-avatar :item="booking_item.assignee[0]" :size="24" :right-space="false"></g-avatar>
                                                                <h4 class="body-2 ml-2 grey--text text--darken-2 font-weight-medium" v-if="booking_item.assignee[0]">{{ booking_item.assignee[0].name }}</h4>
                                                            </div>
                                                            <div class="d-inline-flex align-center ml-2">
                                                                <g-moment tag="span" class="body-2 grey--text font-weight-normal" :value="booking_item.booking_from" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                                                <span class="mx-1 grey--text font-weight-normal"> - </span>
                                                                <g-moment tag="span" class="body-2 grey--text font-weight-normal" :value="booking_item.actual_booking_to" input-format="YYYY-MM-DD" output-format="MMM D, YYYY"></g-moment>
                                                            </div>
                                                        </div>
                                                    </a-flex>
                                                    <a-flex class="pa-0" xs3>
                                                        <div class="u-flex align-center justify-end">
                                                            <a-chip label small class="ma-0 u-rounded-corners red lighten-4 mr-1">
                                                                <h4 class="body-2 red--text text--lighten-1 font-weight-medium">{{ booking_item.status }}</h4>
                                                            </a-chip>
                                                            <router-link tag="a" style="height: 24px; padding: 1px 10px" v-if="$can('inventories.update')" class="body-2 white--text ma-0 ml-2 d-inline-block u-rounded-corners blue darken-2" :to="{ name: 'booking-extend', params: { id: booking_item.batch } }">
                                                                Manage
                                                            </router-link>
                                                        </div>
                                                    </a-flex>
                                                    <a-flex xs12 class="pa-0 mt-2">
                                                        <a-divider class="grey lighten-3 mb-2"></a-divider>
                                                        <div class="d-inline-flex align-center">
                                                            <a-avatar color="grey lighten-2" size="16">
                                                                <a-icon size="12" color="grey darken-1">place</a-icon>
                                                            </a-avatar>
                                                            <h3 class="body-1 ma-0 grey--text text--darken-2 font-weight-normal ml-2" style="margin-top: 2px !important;" v-if="booking_item.location">{{ booking_item.location }}</h3>
                                                            <h3 class="body-1 ma-0 grey--text text--darken-2 font-weight-normal ml-2" style="margin-top: 2px !important;" v-else>No Location</h3>
                                                        </div>
                                                    </a-flex>
                                                </a-layout>
                                            </a-card>
                                        </div>
                                        <div class="pb-5" v-if="!local_filter_inventory_booking.in_use.data && !local_filter_inventory_booking.booked.data && !local_filter_inventory_booking.returned.data && !local_filter_inventory_booking.cancelled.data">
                                            <h2 class="title grey--text text-center py-3">No booking information available</h2>
                                        </div>
                                    </a-card>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </div>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog max-width="480px" v-model="dialog_download_link" persistent>
            <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                <a-progress-linear class="my-0" height="3" color="orange darken-3" :indeterminate="true" v-if="loading"></a-progress-linear>
                <a-card-text class="pa-2">
                    <a-container container--fluid grid-list-xl class="pa-4">
                        <a-layout align-start>
                            <a-flex shrink>
                                <a-icon size="72" color="grey lighten-1">cloud_download</a-icon>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 mb-2">
                                    <span v-if="loading">Preparing file...</span>
                                    <span v-else>Ready to Download</span>
                                </h2>
                                <p class="md-body-2 mb-0 grey--text text--darken-1">
                                    <span v-if="loading">Success is preparing your report for download. It may take few seconds to get ready.</span>
                                    <span v-else>Your report is ready to download. Click on the "Save File" button to download the report.</span>
                                </p>
                                <a-btn :href="inventory_links.download" small class="elevation-0 px-3 ma-0 mt-3" color="primary" :disabled="loading" @click="local_download_save()">
                                    <a-icon size="14" class="mr-2">save</a-icon>
                                    Save File
                                </a-btn>
                                <a-btn class="elevation-0 px-3 ma-0 mt-3" color="grey" small text @click="local_download_close()" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-snackbar v-model="snackbar_status" :timeout="4000" bottom center :color="snackbar_response_status === 'error' ? 'red' : ''">
            {{ snackbar_text }}
        </a-snackbar>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { SInventoryList } from '@/config/config-shared-components'
import mixinSearchUsers from '@/mixins/mixin-search-users'

export default {
    mixins: [mixinSearchUsers],

    components: { SInventoryList },

    data() {
        return {
            sort_inventory: [
                {name: 'Next Available Date', value: 'next_available'},
                {name: 'Inventory Name', value: 'name'},
                {name: 'Part Number', value: 'part_no'},
                {name: 'Serial Number', value: 'serial_no'},
            ],
            sort_order_inventory: [
                {name: 'Ascending', value: ''},
                {name: 'Descending', value: '-'},
            ],
            sort_direction: '',
            sort_by: 'Inventory Name',
            sorted_by: {name: 'Name', value: 'name'},
            meta_errors: {type: '', message: ''},
            group_inventory: [
                {name: 'Category', value: 'category'},
                {name: 'Part Number', value: 'part_no'},
                {name: 'PROJECT', value: 'poc'},
                {name: 'No Grouping', value: 'no_grouping'},
            ],
            in_use_inventory_options: [
                { name: 'Extend Inventory', value: 'extend' },
                { name: 'Return Inventory', value: 'return' },
            ],
            pane_inventory: false,
            pane_filters: false,
            inventory_detail_dialog: false,
            inventory_detail: null,
            inventory_edit_item: null,
            inventory_request_list: [],
            inventory_status_list: [],
            inventory_category_list: [],
            local_select_id: null,
            local_select_status: null,
            status_update_item: {},
            request_form_data: {
                start_date: null,
                end_date: null,
                poc: null,
                location: null,
            },
            request_form_data_default: {
                start_date: null,
                end_date: null,
                poc: null,
                location: null,
            },
            datepicker_request_start_date: false,
            datepicker_request_end_date: false,
            check_for_date_range: false,
            inventory_group_by: 'category',
            local_filter_poc_lable: 'All',
            local_filter_status_label: 'All',
            local_filter_category_label: 'All',
            local_filter_assignee_label: 'All',
            local_filter_owner_label: 'All',
            local_group_value: 'category',
            local_select_group: 'Category',
            inventory_category_search: null,
            local_category_item: null,
            local_owner: null,
            category_exist_message: null,
            local_search_inventory: null,
            detail_tab_active: 'Inventory Detail',
            local_loading: false,
            local_book_info_loading: {
                type: null,
                value: false,
                id: null
            },
            local_filter_category: "All",
            local_filter_status: "All",
            local_user_list: [],
            date_range_picker: {
                start: new Date(),
                end: new Date()
            },
            dragValue: null,
            current_day: true,
            accordion_inventorys: [],
            accordion_inventorys_all: false,
            snackbar_status: false,
            snackbar_text: '',
            snackbar_response_status: '',
            inventory_filter_types: {},
            show_selected_filters: false,
            dialog_download_link: false,
            inventory_total_list: [],
            local_search_category:  '',
            category_mode: false,
            local_search_poc: '',
            local_search_user: '',
            local_search_owner: '',
            local_search_territory: '',
            local_search_tag: '',
            local_search_stage: '',
            local_category_id: '',
            filter_mode: false,
            local_select_inventory_count: null,
            search_category: false,
            search_user: false,
            search_owner: false,
            search_poc: false,
            search_territory: false,
            search_tag: false,
            search_stage: false,
            local_territory_filter_item: 'All',
            local_tag_filter_item: 'All',
            local_stage_filter_item: 'All',
            territory_ids: [],
            local_page_count: null,
            local_loading_type: {
                type: null,
                value: false
            },
            local_search_mode: false,
            local_old_inventory_item: null,
            inventory_territory_search: null,
            territory_exist_message: null,
            inventory_tag_search: null,
            tag_exist_message: null,
            search_timeout: null,
            autosuggest_timeout: null,
            autosuggest_loading: null,
            autosuggest_search: {},
            clonedEditItem: {},
            current_search_module: null,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
            inventory_include: 'tags,territories,category,customStatus,bookings,currentBookedItems.assignee.role,currentBookedItems.project.customer,currentBookedItems.project.bridges,futureBookings.project.customer,owner.role',
            filter: {
                STATUS: 'filter[status_id]',
                UNAVAILABLE: 'filter[is_available]',
                // STATUS: 'filter[custom_status_id]',
                STAGE: 'filter[custom_status_id]',
                CATEGORY: 'filter[category_id]',
                ASSIGNEE: 'filter[assignee]',
                OWNER: 'filter[owner_id]',
                PROJECT: 'filter[project]',
                TERRITORY: 'filter[territories]',
                TAGS: 'filter[tags]',
                // SORT_ORDER: 'filter[sort_order]',
                SORT: 'sort',
                BOOKING_FROM: 'booking_from',
                BOOKING_TO: 'booking_to',
                SEARCH: 'filter[search]',
                // GROUP: 'filter[group]',
                PAGINATE: 'paginate',
                PAGE: 'page',
                // COLUMNS: 'filter[columns]'
            },
            local_loading_available : false,
            territory_filter: {},
            territory_count: 999,
            FILTERS: {
                FIEDS: 'fields[territories]',
                PAGE: 'page',
                COUNT: 'count',
            },
        }
    },

    mounted() {
        this.local_index()
    },

    watch: {
        local_search_inventory() {
            if(this.local_search_inventory && !this.local_search_inventory.length) {
                this.local_search_mode = false
            }
            if(!this.local_search_inventory && this.local_search_mode) {
                this.local_search_clear('search', null)
            }
        },
        search_category(val) {
            if (val) {
                setTimeout(() => this.$refs.search_category_input.ref.focus(), 500)
            }
        },
        search_user(val) {
            if (val) {
                this.localSearchUsers(null, 'assignee')
                setTimeout(() => this.$refs.search_user_input.ref.focus(), 500)
            }
        },
        search_owner(val) {
            if (val) {
                this.localSearchUsers(null, 'owner')
                setTimeout(() => this.$refs.search_owner_input.ref.focus(), 500)
            }
        },
        search_poc(val) {
            if (val) {
                setTimeout(() => this.$refs.search_poc_input.ref.focus(), 500)
            }
        },
        search_territory(val) {
            if (val) {
                setTimeout(() => this.$refs.search_territory_input.ref.focus(), 500)
            }
        },
        search_tag(val) {
            if (val) {
                setTimeout(() => this.$refs.search_tag_input.ref.focus(), 500)
            }
        },
        search_stage(val) {
            if (val) {
                setTimeout(() => this.$refs.search_stage_input.ref.focus(), 500)
            }
        },
        autosuggest_search: {
            handler (val) {
                if (this.current_search_module) {
                    const value = val[this.current_search_module]
                    this.inventory_edit_item[this.current_search_module] = value
                    this.local_autosuggest(value, this.current_search_module)
                }
            },
            deep: true
        },
        current_search_module (newVal, oldVal) {
            if (newVal && (newVal !== oldVal)) this.inventory_autosuggest_clear()
            // if (oldVal) this.autosuggest_search = {}
        },
    },

    computed: {
        selectDragAttribute() {
            return {
                popover: {
                    visibility: 'hover',
                    // isInteractive: false,
                },
            }
        },
        local_check_filter_bookings() {
            return this.local_filter_inventory_booking && !this.local_filter_inventory_booking.in_use.data && !this.local_filter_inventory_booking.booked.data
        },
        local_filtered_inventory_category_list() {
            return this.inventory_category_list.filter(list => list.value.toLowerCase().includes(this.local_search_category.toLowerCase()))
        },
        local_filtered_poc_list() {
            return this.project_list.filter(list => list.title.toLowerCase().includes(this.local_search_poc.toLowerCase()))
        },
        local_filtered_territory_list() {
            return this.territory_list.filter(list => list.name.toLowerCase().includes(this.local_search_territory.toLowerCase()))
        },
        local_filtered_tag_list() {
            return this.tag_list.filter(item => item.type === 'inventory_tag').filter(list => list.label.toLowerCase().includes(this.local_search_tag.toLowerCase()))
        },
        local_filtered_stage_list() {
            return this.tag_list.filter(item => item.type === 'inventory_custom_status').filter(list => list.label.toLowerCase().includes(this.local_search_stage.toLowerCase()))
        },
        local_filtered_inventory_list() {
            // return this.inventory_list.filter(list => this.inventory_filters[this.filter.CATEGORY] === 'ALL' || this.inventory_filters[this.filter.CATEGORY] === 'NONE' && !list.category || list.category && this.inventory_filters[this.filter.CATEGORY] == list.category.id)
            return this.inventory_list
        },
        local_filter_inventory_booking() {
            const grouped = _.groupBy(this.inventory_item.bookings, 'status')
            const groupedObject = {
                in_use: { status: 'In Use', data: grouped['In Use'] },
                booked: { status: 'Booked', data: grouped['Booked'] },
                returned: { status: 'Returned', data: grouped['Returned'] },
                cancelled: { status: 'Cancelled', data: grouped['Cancelled'] },
            }
            return groupedObject
        },
        local_has_inventory_filter() {
            return this.inventory_filter_types && this.inventory_filter_types[this.filter.STATUS] !== 'ALL' || this.inventory_filter_types && this.inventory_filter_types[this.filter.STAGE] !== 'ALL' ||
                    this.inventory_filter_types && this.inventory_filter_types[this.filter.CATEGORY] !== 'ALL' || this.inventory_filter_types && this.inventory_filter_types[this.filter.ASSIGNEE] !== 'ALL' ||
                    this.inventory_filter_types && this.inventory_filter_types[this.filter.OWNER] !== 'ALL' || this.inventory_filter_types && this.inventory_filter_types[this.filter.TERRITORY] !== 'ALL' ||
                    this.inventory_filter_types && this.inventory_filter_types[this.filter.TAGS] !== 'ALL' || this.inventory_filter_types && this.inventory_filter_types[this.filter.PROJECT] !== 'ALL' ||
                    this.inventory_filter_types && this.inventory_filter_types[this.filter.BOOKING_FROM] || this.inventory_filter_types && this.inventory_filter_types[this.filter.BOOKING_TO] ||
                    this.inventory_filter_types && this.inventory_filter_types[this.filter.SORT] !== 'name' || this.inventory_filter_types && this.inventory_filter_types[this.filter.SORT_ORDER] !== 'asc'
                    ? true : false
        },
        local_check_category_equality() {
            this.inventory_item.category_id = (this.inventory_item.category) ? this.inventory_item.category.id : null
            return this.local_category_id === this.inventory_item.category_id
        },
        local_is_equal () {
            return _.isEqual(this.clonedEditItem, this.inventory_edit_item)
        },
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('Inventory', {
            inventory_filters: 'filters',
        }),
        ...mapState('Meta', {
            meta_list: 'list',
            meta_item: 'item',
            meta_filters: 'filters',
            meta_response: 'response'
        }),
        ...mapState('User', {
            user_self: 'self',
            user_list: 'list',
            user_filters: 'filters',
            user_response: 'response',
        }),
        ...mapGetters('Inventory', {
            inventory_list: 'list',
            inventory_autosuggest_list: 'autosuggest_list',
            inventory_links: 'links',
            inventory_item: 'item',
            inventory_meta: 'meta',
            inventory_response: 'response',
        }),
        ...mapGetters('InventoryBooking', {
            inventory_booking_item: 'item',
            inventory_booking_response: 'response',
        }),
        ...mapState('Project', {
            project_list: 'list',
            project_item: 'item',
            project_filters: 'filters',
        }),
        ...mapState('Territory', {
            territory_list: 'list',
            territory_item: 'item',
            territory_filters: 'filters',
            territory_response: 'response',
        }),
        ...mapState('Tag', {
            tag_list: 'list',
            tag_item: 'item',
            tag_filters: 'filters',
            tag_response: 'response',
        }),
        ...mapState('Association', {
            association_response: 'response',
        }),
    },
    methods: {
        async local_index () {
            if (!this.$can('inventories.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.local_loading = true

            this.project_clear()
            this.project_clear_item()
            this.user_clear()
            this.inventory_clear()

            this.inventory_total_list = []
            // this.inventory_filters[this.filter.GROUP] = 'no_grouping'
            // this.inventory_filters[this.filter.COLUMNS] = 'slim'
            await this.inventory_index({
                include: this.inventory_include,
                ...this.inventory_filters
            })
            this.local_select_inventory_count = this.inventory_meta.total_count
            this.local_page_count = this.inventory_meta.current_page
            this.local_loading = false

            this.local_load_categories()
            this.inventory_filter_types = _.cloneDeep(this.inventory_filters)

            this.local_load_territory()
            this.local_load_tags()
        },
        local_load_tags() {
            this.tag_clear()
            this.tag_index({ 'filter[type]': 'inventory_tag,inventory_custom_status' })
        },
        // local_load_inventory_custom_statuses() {
        //     this.tag_custom_status_clear()
        //     this.tag_filters.type = 'inventory_custom_status'
        //     this.tag_index({ 'filter[type]': 'inventory_custom_status' })
        // },
        async local_load_territory() {
            await this.territory_clear()
            this.territory_filter[this.FILTERS.PAGE] = 1
            this.territory_filter[this.FILTERS.COUNT] = this.territory_count
            this.territory_filter[this.FILTERS.FIEDS] = 'id,name,color,order'
            await this.territory_index(this.territory_filter)
        },
        async local_load_projects() {
            this.project_index({
                'sort': 'title',
                'fields[projects]': 'id,title',
                'filter[status]': 'active,queue',
                count: 999,
                page: 1
            })
        },
        async local_load_users() {
            this.localSearchUsers()
        },
        async local_load_categories(type = null) {
            type ? null : await this.meta_clear()
            this.meta_filters.sort_field= 'value'
            this.meta_filters.count_relationship='category'
            await this.meta_index({
                'filter[type]': 'inventory_category',
                'sort': 'order',
                include: 'categoryCount'
            })
            this.inventory_category_list =  this.meta_list
        },
        async local_load_status() {
            await this.meta_clear()
            this.meta_filters.sort_field= 'order'
            this.meta_filters.type = 'Inventory Status'
            await this.meta_index({ 'filter[type]': 'inventory_status', sort: 'order' })
            this.inventory_status_list =  _.cloneDeep(this.meta_list)
        },
        async local_inventory_status_update(inventory_id, status_id) {
            this.status_update_item.status_id = status_id
            this.status_update_item.id = inventory_id
            await this.inventory_update({data: this.status_update_item})
        },
        async local_inventory_update() {
            let cloned_inventory_item = { ...this.inventory_edit_item }
            if (this.local_category_item) cloned_inventory_item.category_id = this.local_category_item && this.local_category_item.id ? this.local_category_item.id : this.local_category_item
            else cloned_inventory_item.category_id = null

            if (this.local_owner) cloned_inventory_item.owner_id = this.local_owner.id
            else cloned_inventory_item.owner_id = null

            cloned_inventory_item.status_id = this.local_select_id
            this.local_pass_inventory_booking_date()
            if (cloned_inventory_item.territories && cloned_inventory_item.territories.length) {
                cloned_inventory_item.territories = cloned_inventory_item.territories.map(item => item.id ?? item)
            }

            if (cloned_inventory_item.tags && cloned_inventory_item.tags.length) {
                cloned_inventory_item.tags = cloned_inventory_item.tags.map(item => item.id ?? item)
                // cloned_inventory_item.inventory_tags.forEach(id => this.inventory_attach_tags({ id: cloned_inventory_item.id, tag_id: id }))
                // this.$nextTick(() => delete cloned_inventory_item.tags)
            }
            await this.inventory_update({ ...cloned_inventory_item })

            if (this.inventory_response.status !== 'success') return
            this.pane_inventory = false

            // if (this.local_check_category_equality) return
            setTimeout(() => this.local_edit_category_counts(), 1000)
        },
        local_pass_inventory_booking_date() {
            if (this.date_range_picker && this.date_range_picker.start || this.date_range_picker && this.date_range_picker.end) {
                this.inventory_edit_item.booking_from =  this.date_range_picker.start
                this.inventory_edit_item.booking_to = this.date_range_picker.end
            }
        },
        async local_edit_category_counts() {
            // Check - old item has category && new item doesn't have category
            if(this.local_old_inventory_item && this.local_old_inventory_item.category && this.inventory_item  && !this.inventory_item.category) {
                this.inventory_meta.without_category = this.inventory_meta.without_category + 1
                this.meta_decrease_count(this.local_old_inventory_item.category)
                this.local_update_meta_info()
                this.local_edit_paginate_count()
            }

            // Check - old item doesn't have category && new item has category
            if(this.local_old_inventory_item && !this.local_old_inventory_item.category && this.inventory_item  && this.inventory_item.category) {
                this.inventory_meta.without_category = this.inventory_meta.without_category - 1
                this.meta_increase_count(this.inventory_item.category)
                this.local_update_meta_info()
                this.local_edit_paginate_count()
            }

            // Check - old item has category && new item has category
            if(this.local_old_inventory_item && this.local_old_inventory_item.category && this.inventory_item && this.inventory_item.category) {
                this.meta_decrease_count(this.local_old_inventory_item.category)
                this.meta_increase_count(this.inventory_item.category)
                this.local_update_meta_info()
                this.local_edit_paginate_count()
            }

            this.local_load_categories(true)
        },
        local_edit_paginate_count() {
            if (this.inventory_filters[this.filter.CATEGORY] === 'NONE' && this.inventory_filters[this.filter.CATEGORY] !== 'ALL') {
                this.inventory_meta.to = this.inventory_meta.to - 1
                this.inventory_meta.total = this.inventory_meta.total - 1
            }
        },
        local_update_meta_info() {
            this.inventory_category_list =  this.meta_list
            this.local_change_select_cat_count('decrement')
        },
        local_change_select_cat_count(type) {
            if (type !== 'decrement' && this.inventory_filters[this.filter.CATEGORY] !== 'ALL') {
               return this.local_select_inventory_count = this.local_select_inventory_count + 1
            } else if (type === 'decrement' && this.inventory_filters[this.filter.CATEGORY] !== 'ALL') {
                return this.local_select_inventory_count = this.local_select_inventory_count - 1
            }
        },
        local_snackbar_status(text, status) {
            this.snackbar_text = text
            this.snackbar_response_status = status
            this.snackbar_status = true
        },
        async local_search_filter(filter, value, evt = false) {
            clearTimeout(this.search_timeout)
            this.inventory_total_list = []
            this.inventory_filters[this.filter.PAGE] = 1
            this.inventory_filters[this.filter.PAGINATE] = 15
            this.inventory_filters[filter] = value
            this.search_timeout = window.setTimeout(async () => {
                if (!this.local_search_inventory.trim()) return
                if (!this.local_search_inventory || this.local_search_inventory.trim().length <= 3) {
                    const search_error = 'Try more than 3 characters to search.'
                    this.local_snackbar_status(search_error, 'error')
                    return
                }
                this.local_loading = true
                await this.inventory_list_clear()
                await this.inventory_index({
                    include: this.inventory_include,
                    ...this.inventory_filters
                })
                this.local_page_count = this.inventory_meta.current_page
                this.local_loading = false
                this.local_search_mode = true
            }, 1000)
        },
        async local_search_clear(filter, value) {
            this.local_loading = true
            this.inventory_filters[this.filter.PAGE] = 1
            this.inventory_filters[this.filter.PAGINATE] = 15
            this.inventory_filters[filter] = value
            await this.inventory_list_clear()
            await this.inventory_index({
                include: this.inventory_include,
                ...this.inventory_filters
            })
            this.local_loading = false
            this.local_search_mode = false
        },
        async local_show_filters_pane() {
            if (this.pane_filters) return

            this.$refs.pane_filters.$el.scrollTop = 0
            this.pane_filters = true

            this.local_loading_type = { type: 'filters', value: true }
            await this.load_filter_objects()
            this.local_loading_type = { type: null, value: false }
        },
        async load_filter_objects() {
            this.local_load_users()
            await this.local_load_status()
            await this.local_load_projects()
        },
        local_inventory_filter(filter, value, label = null) {
            this.filter_mode = true
            if(filter === this.filter.PROJECT) this.local_filter_poc_lable = label
            if(filter === this.filter.STATUS) {
                this.local_filter_status_label = label
                this.inventory_filters[this.filter.UNAVAILABLE] = null
                this.inventory_filter_types[this.filter.UNAVAILABLE] = null
            }
            if(filter === this.filter.UNAVAILABLE) {
                this.local_filter_status_label = label
                this.inventory_filters[this.filter.STATUS] = null
                this.inventory_filter_types[this.filter.STATUS] = null
            }
            if(filter === this.filter.CATEGORY) this.local_filter_category_label = label
            if(filter === this.filter.ASSIGNEE) this.local_filter_assignee_label = label
            if(filter === this.filter.OWNER) this.local_filter_owner_label = label
            if(filter === 'sort_direction') {
                this.sort_direction = value
                this.sort_by = this.sort_by === 'name' ? 'Name' : this.sort_by
                // this.inventory_filter_types[filter] = this.sort_by
                this.inventory_filters[this.filter.SORT] = this.inventory_filters[this.filter.SORT].charAt(0) === '-' ? this.inventory_filters[this.filter.SORT].substring(1) : this.inventory_filters[this.filter.SORT]
                this.inventory_filters[this.filter.SORT] = `${this.sort_direction}${this.inventory_filters[this.filter.SORT]}`
            }
            if (filter === 'sort_by') {
                this.sort_by = label
                this.inventory_filters[this.filter.SORT] = `${this.sort_direction}${value}`
            } else {
                this.inventory_filter_types[filter] = label
                // this.inventory_filters[this.filter.PAGINATE] = 50
                this.inventory_filters[filter] = value
            }
            this.local_has_inventory_filter
        },
        local_territory_filter_set(filter, value, item = null) {
            this.filter_mode = true
            this.local_territory_filter_item = item
            this.inventory_filter_types[filter] = value
            this.inventory_filters[filter] = value
            // this.inventory_filters[this.filter.PAGINATE] = 50
            this.local_has_inventory_filter
        },
        local_tag_filter_set(filter, value, item = null) {
            this.filter_mode = true
            this.local_tag_filter_item = item
            this.inventory_filter_types[filter] = value
            this.inventory_filters[filter] = value
            // this.inventory_filters[this.filter.PAGINATE] = 50
            this.local_has_inventory_filter
        },
        local_stage_filter_set(filter, value, item = null) {
            this.filter_mode = true
            this.local_stage_filter_item = item
            this.inventory_filter_types[filter] = value
            this.inventory_filters[filter] = value
            // this.inventory_filters[this.filter.PAGINATE] = 50
            this.local_has_inventory_filter
        },
        async local_category_filter(filter, value, label, inventory_count) {
            this.category_mode = true
            this.local_loading = true
            this.local_select_inventory_count = inventory_count
            this.inventory_filters[this.filter.PAGINATE] = this.inventory_meta.total_count
            this.inventory_filters[this.filter.PAGE] = 1
            this.local_page_count = this.inventory_filters[this.filter.PAGE]
            this.local_filter_category_label = label
            if(value === 'ALL' || value === 'NONE') this.inventory_filters[this.filter.PAGINATE] = 15
            this.inventory_filters[filter] = value
            await this.inventory_list_clear()
            await this.inventory_index({
                include: this.inventory_include,
                ...this.inventory_filters
            })
            this.category_mode = false
            this.local_loading = false
        },
        async local_inventory_pagination() {
            if(this.local_page_count == this.inventory_meta.current_page) return
            //await this.$vuetify.goTo(0)
            this.local_loading = true
            this.inventory_filters[this.filter.PAGE] = this.inventory_meta.current_page
            await this.inventory_index({
                include: this.inventory_include,
                ...this.inventory_filters
            })
            this.local_page_count = this.inventory_meta.current_page
            this.local_loading = false
        },
        local_inventory_date_filter() {
            this.accordion_inventorys = []
            if (this.date_range_picker && this.date_range_picker.start || this.date_range_picker && this.date_range_picker.end) {
                this.inventory_filters[this.filter.BOOKING_FROM] = this.date_range_picker.start
                this.inventory_filters[this.filter.BOOKING_TO] = this.date_range_picker.end
                this.inventory_filter_types = Object.assign({}, this.inventory_filter_types, { 'booking_from': this.date_range_picker.start, 'booking_to': this.date_range_picker.end})
                this.local_has_inventory_filter
            }
        },
        async local_inventory_groupy_filter(filter, value, name) {
            this.accordion_inventorys = []
            this.local_group_value =  value
            this.local_select_group = name
            this.inventory_filter_types[filter] = name
            this.inventory_filters[filter] = value
            this.local_has_inventory_filter
        },
        async local_clear_filters(type, defaultValue, module) {
            this.local_loading = true
            this.inventory_total_list = []

            if(type === this.filter.STATUS && defaultValue === 'ALL') {
                this.inventory_filters[this.filter.UNAVAILABLE] = null
                this.inventory_filter_types[this.filter.UNAVAILABLE] = null
            }

            if (type === 'date') {
                this.local_clear_date_filter()
                this.inventory_filter_types[this.filter.BOOKING_FROM] = null
                this.inventory_filter_types[this.filter.BOOKING_TO] = null
                this.check_for_date_range = false
            } else if (type === 'sort') {
                this.sort_direction = ''
                this.sort_by = 'name'
                this.inventory_filters[this.filter.SORT] = 'name'
                this.inventory_filter_types[this.filter.SORT] = 'name'
            } else {
                this.inventory_filters[type] = defaultValue
                this.inventory_filter_types[type] = defaultValue
            }
            this.inventory_filters[this.filter.PAGE] =  this.local_page_count
            await this.inventory_index({
                include: this.inventory_include,
                ...this.inventory_filters
            })
            this.local_loading = false

            if(module === 'territory') this.local_territory_filter_item = 'All'
            if(module === 'tag') this.local_tag_filter_item = 'All'
            if(module === 'stage') this.local_stage_filter_item = 'All'

            if (this.local_has_inventory_filter) {
                this.show_selected_filters = true
            } else {
                this.local_group_value = 'category'
                this.inventory_group_by = this.local_group_value
                this.show_selected_filters = false
            }
        },
        async local_fetch_inventory() {
            this.inventory_total_list = []
            this.local_loading = true
            this.pane_filters = false
            this.local_has_inventory_filter ? this.show_selected_filters = true : this.show_selected_filters = false
            this.inventory_group_by = this.local_group_value
            this.inventory_filters[this.filter.PAGE] = 1
            this.inventory_filters[this.filter.PAGINATE] = 15
            this.check_for_date_range = !!(this.inventory_filter_types && this.inventory_filter_types[this.filter.BOOKING_FROM] && this.inventory_filter_types[this.filter.BOOKING_TO])
            await this.inventory_list_clear()
            await this.inventory_index({
                include: this.inventory_include,
                ...this.inventory_filters
            })
            this.local_page_count = this.inventory_meta.current_page
            this.local_loading = false
        },
        local_group(value) {
            this.inventory_group_by = value
        },
        local_status_select(id , status) {
            this.local_select_id = id
            this.local_select_status = status
        },
        local_inventory_pane_close () {
            this.pane_inventory = false
            this.inventory_detail = null
            this.territory_clear_item()
            this.tag_clear_item()
        },
        async local_show_inventory_item (id) {
            this.inventory_clear_item()
            this.meta_clear_item()
            this.local_category_item = null
            this.inventory_edit_item = null
            //this.local_load_users()

            this.pane_inventory = true
            await this.inventory_show({ id: id })
            this.inventory_edit_item = this.inventory_item
            this.clonedEditItem = _.cloneDeep(this.inventory_edit_item)
            this.local_old_inventory_item = _.cloneDeep(this.inventory_item)
            this.local_owner = this.inventory_edit_item.owner ?? null

            if (this.inventory_edit_item.category) {
                this.local_category_item = this.inventory_item.category
                this.local_category_id = this.inventory_item.category.id
            } else {
                this.local_category_item = null
                this.local_category_id = null
            }

            this.local_select_status = this.inventory_item.status
            this.inventory_item.status ? this.local_select_id = this.inventory_item.status.id : ''
            this.$refs.pane_inventory.scrollTop = 0
        },
        async local_close_destroy_confirm(id) {
            // const index = _.findIndex(this.inventory_list, { 'id': id });
            const index = this.inventory_list.findIndex(item => item.id === id)
            const old_inventory = this.inventory_list[index]
            await this.inventory_destory({ id: id })
            if(this.inventory_response.status === 'success') {
                const snackbar_text = 'Inventory has been deleted, you can restore it from trash.'
                await this.local_snackbar_status(snackbar_text,'success')
                this.local_load_categories()
                this.local_load_meta_count(old_inventory)
                this.local_load_pagination()
            } else {
                const snackbar_text =  this.inventory_response.server.message
                this.local_snackbar_status(snackbar_text,'error')
            }
        },
        async local_load_pagination() {
            if(this.inventory_list.length) return
            this.local_loading = true
            this.inventory_filters[this.filter.PAGE] = this.inventory_meta.current_page-1
            await this.inventory_list_clear()
            await this.inventory_index({
                include: this.inventory_include,
                ...this.inventory_filters
            })
            this.local_loading = false
        },
        local_load_meta_count(old_inventory) {
            if(!old_inventory.category) this.inventory_meta.without_category = this.inventory_meta.without_category-1
            this.local_select_inventory_count = this.local_select_inventory_count-1
            this.inventory_meta.total = this.inventory_meta.total-1
            this.inventory_meta.to  =  this.inventory_meta.to-1
            this.inventory_meta.total_count = this.inventory_meta.total_count-1
        },
        local_inventory_category_clear(category) {
            // this.local_association_destroy(category.association)
        },
        async local_association_destroy(data) {
            await this.association_destroy(data)
            if (this.association_response.status === 'success') {
                this.local_category_item = null
            }
        },
        local_clear_category_input () {
            this.meta_clear_item()
            this.local_category_item = null
        },
        async local_inventory_category_store() {
            // this.local_move_category_focus()
            if (typeof this.local_category_item === 'undefined') return this.inventory_category_search = null
            if ((typeof this.local_category_item) === 'string') {
                this.meta_item.type = 'inventory_category'
                this.meta_item.value = this.inventory_category_search
                await this.meta_store({ ...this.meta_item, mode: 'add_update' })
                this.local_category_item = _.cloneDeep(this.meta_item)
                if (this.meta_response.status === 'success') {
                    this.local_load_categories()
                    await this.meta_clear_item()
                } else this.local_category_item = null
            }
        },
        async local_inventory_category_select(item_id) {
            if (!item_id) return
            if (typeof this.local_category_item === 'undefined') return this.inventory_category_search = null
            const data = { source_type : 'Inventory', source_id : this.inventory_edit_item.id, target_type: 'Meta', target_id : item_id, type: 'inventory_category' }
            await this.local_association_store(data)
            if (this.association_response.status === 'success') {
                this.local_category_item = item_id
                await this.meta_clear_item()
            }
        },
        async local_association_store(data) {
            await this.association_store(data)
        },
        local_move_category_focus() {
            this.$refs.category_dropdown.ref.blur()
        },
        async local_export(format) {
            this.inventory_filters[this.filter.PAGE] = 1
            this.inventory_filters[this.filter.PAGINATE] = this.inventory_meta.total_count
            this.inventory_filters.download_format = format
            this.inventory_filters.count = 999
            this.dialog_download_link = true
            await this.inventory_export({ ...this.inventory_filters, include: this.inventory_include })
        },
        local_download_save() {
            this.dialog_download_link = false
            // this.inventory_filters[this.filter.PAGINATE] = 50
        },
        local_download_close() {
            this.dialog_download_link = false
            // this.inventory_filters[this.filter.PAGINATE] = 50
        },
        async local_change_detail_tab(item, tab) {
            this.detail_tab_active = tab
        },
        async local_show_inventory_detail(id) {
            if(!id) return
            this.detail_tab_active = 'Inventory Detail'
            this.inventory_detail_dialog = true
            this.local_book_info_loading = { type: 'book-info', value: true, id: id }
            await this.inventory_show({id: id})
            this.inventory_detail = this.inventory_item
            this.inventory_item.category ? this.inventory_detail.category_id = this.inventory_item.category.id : null
            this.local_book_info_loading = { type: 'book-info', value: false, id: null }
        },
        async local_return_booking(booking) {
            this.local_book_info_loading = { type: 'return-booking', value: true, id: booking.id }
            const data = { id: booking.id, status: 'Returned' }
            if (this.date_range_picker && this.date_range_picker.start || this.date_range_picker && this.date_range_picker.end) {
                this.inventory_booking_item.booking_from =  this.date_range_picker.start
                this.inventory_booking_item.booking_to = this.date_range_picker.end
            }
            await this.inventory_booking_return({ data })
            if (this.inventory_booking_response.status === 'success') {
                await this.inventory_update_booked({ data: this.inventory_booking_item })
                this.local_book_info_loading = { type: 'return-booking', value: false, id: null }
            } else{
                this.local_book_info_loading = { type: 'return-booking', value: false, id: null }
            }
        },
        async local_cancel_booking(booking) {
            this.local_book_info_loading = { type: 'cancel-booking', value: true, id: booking.id }
            const data = { id: booking.id, status: 'Cancelled' }
            if (this.date_range_picker && this.date_range_picker.start || this.date_range_picker && this.date_range_picker.end) {
                data.booking_from =  this.date_range_picker.start
                data.booking_to = this.date_range_picker.end
            }
            await this.inventory_booking_cancel({ data })
            await this.inventory_update_booked({ data: this.inventory_booking_item })
            this.local_book_info_loading = { type: 'cancel-booking', value: false, id: null }
        },
        local_date_filter(value) {
            this.current_day = false
            this.date_range_picker = value
            this.local_inventory_date_filter()
            // if (this.date_range_picker && this.date_range_picker.start || this.date_range_picker && this.date_range_picker.end) {
            // }
        },
        async local_clear_date_filter() {
            this.date_range_picker = null
            this.current_day = true
            this.inventory_filters[this.filter.BOOKING_FROM] = null
            this.inventory_filters[this.filter.BOOKING_TO] = null
            this.inventory_filter_types.booking_from = null
            this.inventory_filter_types.booking_to = null
            await this.$nextTick()
            this.date_range_picker = { start: new Date(), end: new Date() }
        },
        local_format_date(date, range = false, simple = false) {
            if (range) return moment(date).format('YYYY-MM-DD')
            if (simple) return moment(date).format('MMM D')

            return date ? moment(date).format('MMM D, YYYY') : null
        },
        local_date_comparison(start_date) {
            return moment(moment().format('YYYY-MM-DD')).isSame(start_date)
        },
        local_accordion_inventory_status(index) {
            if (this.accordion_inventorys[index]) {
                return this.accordion_inventorys[index]
            } else {
                this.accordion_inventorys[index] = false
                return this.accordion_inventorys[index]
            }
        },
        local_accordion_inventory_toggle(index) {
            if (this.accordion_inventorys[index] === undefined || this.accordion_inventorys[index] === null) {
                this.$set(this.accordion_inventorys, index, true);
            } else {
                this.$set(this.accordion_inventorys, index, !this.accordion_inventorys[index]);
            }
        },
        local_accordion_inventory_toggle_all() {
            this.accordion_inventorys_all = !this.accordion_inventorys_all
            this.accordion_inventorys = this.accordion_inventorys.map(x => this.accordion_inventorys_all)
        },
        local_truncate_text(text, range) {
            if (!text) return
            return text.length > range ? text.slice(0, range) + '...' : text
        },
        permission_inventory_store(client_allowed = false) {
            return this.$can('inventories.store') && this.user_self.role.scope === 'internal'
        },
        permission_inventory_update() {
            return this.has_update_permission = this.$can('inventories.update')
        },
        async switch_to_edit_inventory_pane() {
            this.inventory_detail_dialog = false
            await this.local_show_inventory_item(this.inventory_detail.id)
            this.pane_inventory = true
        },
        async local_inventory_territory_store() {
            const hasDuplicate = this.local_check_for_duplicates(this.territory_list, this.inventory_territory_search, 'name')
            if (!this.$can('territories.update')) return
            if (this.inventory_territory_search === null) return

            if (!hasDuplicate) {
                this.territory_item.name = this.inventory_territory_search
                this.territory_item.type = 'Label'
                await this.territory_store({ ...this.territory_item, mode: 'add_update' })
                if (!this.$status(this.territory_response)) return this.inventory_territory_search = null

                return setTimeout(async () => {
                    this.inventory_edit_item.territories.push(this.territory_item)
                    this.inventory_edit_item.territories = this.inventory_edit_item.territories.filter(item => typeof item === 'object')
                    if (this.territory_response && this.territory_response.status !== 'success') return
                    this.inventory_territory_search = null
                    await this.territory_clear_item()
                }, 100)
            }
            const isInventoryTerritory = this.inventory_edit_item.territories.find(item => _.lowerCase(item.name) === _.lowerCase(this.inventory_territory_search))
            if (isInventoryTerritory) this.local_meta_errors('Territory', 'Territory already exists')
        },
        async local_inventory_tag_store() {
            const hasDuplicate = this.local_check_for_duplicates(this.tag_list, this.inventory_tag_search)
            if (!this.$can('tags.update')) return
            if (this.inventory_tag_search === null) return

            if (!hasDuplicate) {
                this.tag_item.label = this.inventory_tag_search
                this.tag_item.type = 'inventory_tag'
                await this.tag_store({ ...this.tag_item, mode: 'add_update' })
                if (!this.$status(this.tag_response)) return this.inventory_territory_search = null

                return setTimeout(async () => {
                    this.inventory_edit_item.tags.push(this.tag_item)
                    this.inventory_edit_item.tags = this.inventory_edit_item.tags.filter(item => typeof item === 'object')
                    if (this.tag_response && this.tag_response.status !== 'success') return
                    this.inventory_tag_search = null
                    await this.tag_clear_item()
                }, 100)
            }
            const isInventoryTag = this.inventory_edit_item.tags.find(item => item.label === this.inventory_tag_search)
            if (isInventoryTag) this.local_meta_errors('Tag', 'Tag already exists')
        },
        local_meta_errors (type, message) {
            if (type !== 'clear') {
                this.meta_errors.type = type
                this.meta_errors.message = message
                return true
            }
            return this.meta_errors = { type: '', message: '' }
        },
        local_check_for_duplicates(list, search_item, field = 'label') {
            return !!(list.find(item => item[field] === search_item))
        },
        local_remove_chips(list, type, item) {
            const id = item.id ?? item
            const index = list.findIndex(chip => (chip === id) || (chip.id === id))
            if(index !== -1) list.splice(index, 1)
        },
        local_tag_item(item) {
            if (!item) return
            if (item.id) return this.tag_list.find(tag => tag.id === item.id)

            return this.tag_list.find(tag => tag.id === item)
        },
        local_in_use_options_trigger(inventory, status) {
            if (status === 'return') return this.local_return_booking(this.local_filter_inventory_booking.in_use.data[0])
            this.$router.push({ name: 'booking-extend', params: { id: inventory.batch } })
        },
        local_autosuggest_search_clear (suggestion_text, field) {
            suggestion_text[field] = null
            this.inventory_edit_item[field] = null
        },
        local_select_autosuggest (item, field) {
            if (item[field] && (typeof item[field] === 'object')) {
                this.inventory_edit_item[field] = item[field][field]
            }
            this.current_search_module = null
        },
        async local_autosuggest (search, field) {
            if ((search && (!search.trim() || !search.length)) || !search) return
            clearTimeout(this.autosuggest_timeout)
            this.autosuggest_timeout = setTimeout(async () => {
                this.autosuggest_loading = field
                await this.inventory_autosuggest({
                    'fields[inventories]': 'id,' + field,
                    'filter[auto_suggest][search]': search,
                    'filter[auto_suggest][field]': field,
                    'count': 999,
                    'page': 1
                })
                this.autosuggest_loading = null
            }, 400)
        },

        // Inventory Update Available
        async local_update_available (available) {
            this.local_loading_available = true
            this.inventory_detail.is_available = available
            const available_item = {}
            available_item.id = this.inventory_detail.id
            available_item.is_available = available
            await this.inventory_update({ ...available_item })

            if (this.inventory_response.status !== 'success') return
            this.local_loading_available = false
            this.local_load_categories()
        },

        local_check_inventory_booking(in_use, booked) {
           if((in_use && booked) && (in_use.length && booked.length)) return 'This inventory contains "In Use" and "Future Bookings", return and cancel to continue.'
           if(in_use && in_use.length) return 'Return the inventory to mark it as unavailable'
           if(booked && booked.length) return 'This inventory has future bookings. Cancel future bookings to mark inventory as unavailable'
        },

        /* Search users */
        async localSearchUsers (val, type = null) {
            const args = { 'include': 'role', 'sort': 'name', 'filter[is_active]': 1, count: 20, page: 1 }
            await this.user_clear()
            await this.mixinSearchUsers(val, args, type)
        },

        ...mapActions('Inventory', {
            inventory_index: 'index',
            inventory_autosuggest: 'autosuggest',
            inventory_show: 'show',
            inventory_update: 'update',
            inventory_select: 'select',
            inventory_export: 'export',
            inventory_destory:'destroy',
            inventory_update_booked: 'update_booked_inventory',
            inventory_clear_item: 'clear_item',
            inventory_list_clear: 'list_clear',
            inventory_autosuggest_clear: 'clear_autosuggest',
            inventory_clear: 'clear',
        }),
        ...mapActions('InventoryBooking', {
            inventory_booking_return: 'booking_return',
            inventory_booking_cancel: 'booking_cancel',
        }),
        ...mapActions('Meta', {
            meta_index: 'index',
            meta_select : 'select',
            meta_store: 'store',
            meta_increase_count: 'increase_meta_count',
            meta_decrease_count: 'decrease_meta_count',
            meta_clear: 'clear',
            meta_clear_item: 'clear_item',
        }),
        ...mapActions('Project', {
            project_index: 'index_slim',
            project_clear: 'clear',
            project_clear_item: 'clear_item',
        }),
        ...mapActions('Territory', {
            territory_index: 'index',
            territory_store: 'store',
            territory_clear: 'clear',
            territory_clear_item: 'clear_item',
        }),
        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_show: 'show',
            tag_clear: 'clear',
            tag_clear_item: 'clear_item',
            tag_custom_status_clear: 'clear_custom_status'
        }),
        ...mapActions('InventoryTag', {
            inventory_tag_index: 'index',
            inventory_tag_store: 'store',
            inventory_tag_clear: 'clear',
            inventory_tag_clear_item: 'clear_item',
        }),
        ...mapActions('Association', {
            association_store: 'store',
            association_destroy: 'destroy',
        }),
        ...mapActions('User', {
            user_index: 'index',
            user_clear: 'clear',
            user_clear_item: 'clear_item',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .c-inventory-detail-pane {
        transition: 0.2s right ease-in;
        position: fixed;
        top: 0;
        height: 100vh;
        width: 800px;
        overflow-y: auto;
        z-index: 200;
        right: -800px;
    }

    .c-inventory-detail-pane.visible {
        right: 0px !important;
        transition: 0.2s right ease-out;
    }

    .c-inventory-filters-pane {
        transition: 0.2s left ease-out;
        position: fixed;
        top: 0;
        height: 100vh;
        width: 400px;
        overflow-y: auto;
        z-index: 200;
        left: -400px;
    }

    .c-inventory-filters-pane.visible {
        transition: 0.2s left ease-out;
        left: 0px !important;
    }

    .c-inventory-history::-webkit-scrollbar-track,
    .c-inventory-detail-pane::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 8px;
        background-color: #F5F5F5;
    }

    .c-inventory-history::-webkit-scrollbar {
        width: 2px;
        background-color: #F5F5F5;
    }

    .c-inventory-detail-pane::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }

    .c-inventory-history::-webkit-scrollbar-thumb,
    .c-inventory-detail-pane::-webkit-scrollbar-thumb {
        border-radius: 8px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #d2d2d2;
    }

    .c-border-c7 {
        border: 1px solid #000;
        border-color: #c7c7c7 !important
    }

    .c-margin-right {
        margin-right: 20px !important;
    }

    .c-poc-info-width {
        max-width: 250px !important;
    }

    .c-booking-cards {
        &--booked, &--returned {
            margin-bottom: 24px !important;
            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
</style>

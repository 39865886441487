<template>
    <div>
        <InternalOverview v-if="local_user_is_external" />
        <ExternalOverview v-else />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import InternalOverview from '@/pages/Projects/ProjectsOverview.vue'
import ExternalOverview from '@/pages/External/ExternalProjects/ExternalProjectsOverview.vue'

export default {
    components: {
        ExternalOverview,
        InternalOverview
    },

    computed: {
        local_user_is_external() {
            return this.user_self && this.user_self.scope === 'internal'
        },
        ...mapState('User', {
            user_self: 'self'
        })
    }

}
</script>

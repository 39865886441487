<template>
    <a-main>
        <!-- <outgoing-webhooks-breadcrumb></outgoing-webhooks-breadcrumb>
        <outgoing-webhooks-header :installation="installation_item"></outgoing-webhooks-header>
        <outgoing-webhooks-tabs :installation="installation_item"></outgoing-webhooks-tabs> -->
        <a-container container--fluid grid-list-xl class="my-4" v-if="!loading && workflow_list && workflow_list.length === 0">
            <a-layout align-center justify-center>
                <a-flex xs4>
                    <div class="text-center"><a-icon size="160" color="grey lighten-1">device_hub</a-icon></div>
                    <h2 class="md-heading-5 mb-1 grey--text text--darken-3 text-center">No Workflows</h2>
                    <template v-if="$can('applets.update')">
                        <p class="md-body-2 mb-2 grey--text text--darken-1 text-center">Setup the workflow to automate data sharing from Success.</p>
                        <div class="text-center"><a-btn dark color="blue darken-1" @click="local_workflow_store()">Create Workflow</a-btn></div>
                    </template>
                </a-flex>
            </a-layout>
        </a-container>
        <a-container container--fluid grid-list-xl class="my-2 mb-10" v-if="workflow_list && workflow_list.length > 0">
            <a-layout align-center>
                <a-flex>
                    <h2 class="md-heading-5 font-weight-semibold">Workflows</h2>
                    <p class="md-body-2 mb-0 grey--text text--darken-2">Create automated workflows to run in the background.</p>
                </a-flex>
                <a-flex shrink v-if="$can('applets.update')">
                    <a-btn color="primary" class="mr-0" :disabled="loading" @click="local_workflow_store()">
                        <a-icon class="mr-1">add</a-icon> Create Workflow
                    </a-btn>
                </a-flex>
            </a-layout>
            <a-layout align-center>
                <a-flex>
                    <a-card class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden mb-3 px-3" v-for="workflow in workflow_list" :key="workflow.id">
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl class="py-3">
                                <a-layout align-center>
                                    <a-flex shrink style="min-width: 120px;">
                                        <div class="d-flex" v-if="workflow.direction === null">
                                            <a-icon size="24">help</a-icon>
                                            <a-icon class="mx-2">arrow_right_alt</a-icon>
                                            <a-icon size="24">help</a-icon>
                                        </div>
                                        <div class="d-flex" v-if="workflow.direction === 'incoming'">
                                            <img src="../../assets/images/logo-outgoing-webhooks.svg" alt="Outgoing Webhooks" style="display: inline-block; max-width: 32px; max-height: 32px;">
                                            <a-icon class="mx-2">arrow_right_alt</a-icon>
                                            <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" style="display: inline-block; min-width: 28px; max-width: 28px; max-height: 28px;">
                                        </div>
                                        <div class="d-flex" v-if="workflow.direction === 'outgoing'">
                                            <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" style="display: inline-block; max-width: 28px; max-height: 28px;">
                                            <a-icon class="mx-2">arrow_right_alt</a-icon>
                                            <img src="../../assets/images/logo-outgoing-webhooks.svg" alt="Outgoing Webhooks" style="display: inline-block; min-width: 28px; max-width: 32px; max-height: 32px;">
                                        </div>
                                    </a-flex>
                                    <a-flex class="u-cursor-pointer">
                                        <router-link v-if="installation_item && installation_item.id && workflow.is_created === 1" :to="{name: `appstore-webhook-workflows-edit`, params: { id: installation_item.id, workflow_id: workflow.id }}">
                                            <p class="md-subtitle-1 grey--text text--darken-4 mb-0">{{ workflow.title }}</p>
                                            <!-- <p class="md-body-2 grey--text text--darken-2 mb-0" v-if="workflow.trigger && workflow.action">
                                                <span v-if="workflow.trigger">{{ workflow.trigger.label }}</span>
                                                <a-icon class="mx-1">arrow_right_alt</a-icon>
                                                <span v-if="workflow.action">{{ workflow.action.label }}</span>
                                            </p> -->
                                        </router-link>
                                        <router-link v-if="installation_item && installation_item.id && workflow.is_created === 0" :to="{name: `appstore-webhook-workflows-create`, params: { id: installation_item.id, workflow_id: workflow.id }}">
                                            <p class="md-body-1 mb-0">{{ workflow.title }}</p>
                                        </router-link>
                                    </a-flex>
                                    <a-flex shrink>
                                        <p class="md-body-2 mb-0 orange--text text--darken-2 text-center" v-if="workflow.is_complete === 0">
                                            <a-icon size="22" class="mr-1 u-icon-nudge" color="orange">warning</a-icon> Invalid
                                        </p>
                                        <p class="md-body-2 mb-0 grey--text text--darken-2 text-center" v-if="workflow.is_complete === 1 && workflow.is_active === 0">
                                            <a-icon size="22" class="mr-1 u-icon-nudge" color="grey">cloud_off</a-icon> Inactive
                                        </p>
                                        <p class="md-body-2 mb-0 green--text text--darken-2 text-center" v-if="workflow.is_complete === 1 && workflow.is_active === 1">
                                            <a-icon size="22" class="mr-1 u-icon-nudge" color="green">check_circle</a-icon> Active
                                        </p>
                                        <!-- <a-icon v-if="workflow.is_active === 0" size="22">radio_button_unchecked</a-icon>
                                        <a-icon v-if="workflow.is_active === 1" size="22" color="green">radio_button_checked</a-icon>
                                        {{ (workflow.is_active === 1) ? 'Active' : 'Inactive' }} -->
                                    </a-flex>
                                    <a-flex shrink v-if="$can('applets.update')">
                                        <!-- <a-btn icon text color="grey darken-1" @click="local_webhook_clone_create(workflow.id)">
                                            <a-icon size="16">content_copy</a-icon>
                                        </a-btn> -->
                                        <a-tooltip bottom v-if="$can('applets.update')">
                                                <template v-slot:activator="{ on }">
                                                   <a-btn icon text color="grey darken-1" v-on="on" @click="local_webhook_clone_create(workflow.id)">
                                                        <a-icon size="16">content_copy</a-icon>
                                                    </a-btn>
                                                </template>
                                                <span>Clone Workflow</span>
                                        </a-tooltip>
                                        <a-menu bottom left offset-y max-width="176">
                                            <template v-slot:activator="{ on }">
                                                <a-btn icon text small color="grey darken-1" :disabled="loading" class="mx-0" v-on="on">
                                                    <a-icon size="16">delete</a-icon>
                                                </a-btn>
                                            </template>
                                            <a-card flat>
                                                <a-card-text class="pa-0">
                                                    <div class="pa-3">
                                                        <p class="md-body-2 mb-0 text-center grey--text text--darken-3">Workflow will be deleted. This action cannot be reversed.</p>
                                                    </div>
                                                    <a-btn color="red darken-1" class="elevation-0 ma-0" block dark small @click.prevent="local_workflow_destroy(workflow.id)">Confirm Delete</a-btn>
                                                </a-card-text>
                                            </a-card>
                                        </a-menu>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>

       <a-dialog v-model="dialog_webhook_clone_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="40">
                                    <a-icon dark>content_copy</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1">Clone Workflow</h2>
                                <p class="md-subtitle-2 mb-0">Create a copy of this workflow</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title</label>
                                <a-text-field
                                    v-model="workflow_item.title"
                                    placeholder="Enter the title"
                                    background-color="neutral"
                                    solo flat hide-details>
                                </a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(workflow_response, 'title')">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ $response(workflow_response, 'title') }}
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16">info</a-icon>
                                    This will help you identify the workflow
                                </span>
                            </a-flex>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Destination URL</label>
                                <a-text-field
                                    v-model="workflow_item.action_resource_url"
                                    placeholder="Enter the Destination URL"
                                    background-color="neutral"
                                    solo flat hide-details>
                                </a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(workflow_response, 'action_resource_url')">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ $response(workflow_response, 'action_resource_url') }}
                                </span>
                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                    <a-icon size="16">info</a-icon>
                                    This will help you identify the destination URL
                                </span>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink>
                                <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_workflow_clone()" :loading="loading" :disabled="loading" >Clone Workflow</a-btn>
                                <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_workflow_clone_cancel()">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
       </a-dialog>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import OutgoingWebhooksBreadcrumb from './WebhooksBreadcrumb'
import OutgoingWebhooksHeader from './WebhooksHeader'
import OutgoingWebhooksTabs from './WebhooksTabs'

export default {
    components: {
        OutgoingWebhooksBreadcrumb,
        OutgoingWebhooksHeader,
        OutgoingWebhooksTabs,
    },
    data() {
        return {
            dialog_webhook_clone_form: false,
            workflow_types: [
                {
                    slug: 'pull-projects',
                    direction: 'Incoming',
                    title: 'Import Project from Salesforce Opportunity',
                    subtitle: 'Setup workflow for creating project in Success from Salesforce opportunity.',
                },
                {
                    slug: 'push-status-updates',
                    direction: 'Outgoing',
                    title: 'Create New Entry in Salesforce on Project Status Updates',
                    subtitle: 'Push data to Salesforce when status update is posted in Success.',
                },
                {
                    slug: 'push-stage-changes',
                    direction: 'Outgoing',
                    title: 'Create New Entry in Salesforce on Project Stage Changes',
                    subtitle: 'Push data to Salesforce when project stage is changed in Success.',
                },
                {
                    slug: 'push-stage-changes-update',
                    direction: 'Outgoing',
                    title: 'Update Opportunity in Salesforce on Project Stage Changes',
                    subtitle: 'Update Salesforce opportunity when project stage is changed in Success.',
                }
            ]
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Apps', to: '/apps', exact: true})
            breadcrumbs.push({text: 'Configure Apps', to: '/apps/installed', exact: true})
            return breadcrumbs
        },
        ...mapState('Installation', {
            installation_item: 'item',
        }),
        ...mapState('InstallationAccount', {
            installation_account_list: 'list',
            installation_account_filters: 'filters',
        }),
        ...mapState('Workflow', {
            workflow_item: 'item',
            workflow_list: 'list',
            workflow_filters: 'filters',
            workflow_response:'response'
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            if (this.installation_account_list.length === 0) {
                // this.$router.replace({name: 'appstore-salesforce-accounts'})
            }
            await this.workflow_index({ 'filter[installation_id]': this.$route.params.id })
        },
        async local_installation_account_destroy(id) {
            await this.installation_account_destroy({id: id});
            await this.local_index()
        },
        async local_workflow_store() {
            await this.workflow_clear_item()
            await this.workflow_store({ installation_id: this.installation_item.id, 'order': -1 })
            this.$router.push({name: `appstore-webhook-workflows-create`, params: { id: this.installation_item.id, workflow_id: this.workflow_item.id }})
        },
        async local_workflow_destroy(id) {
            await this.workflow_destroy({id: id})
        },
        local_workflow_type_attribute(slug, property)
        {
            return _.get(_.find(this.workflow_types, {slug: slug}), property)
        },
        async local_webhook_clone_create(id){
            await this.workflow_select({id: id})
            this.workflow_item.title = 'Copy of ' + this.workflow_item.title
            this.dialog_webhook_clone_form = true
        },
        async local_workflow_clone(){
            await this.workflow_clone({title:this.workflow_item.title , action_resource_url:this.workflow_item.action_resource_url, workflow_id:this.workflow_item.id })
            if (!this.$status(this.workflow_response)) return
            this.dialog_webhook_clone_form = false
            this.$notify('success', 'Workflow cloned successfully!')
        },
        async local_workflow_clone_cancel(){
            await this.workflow_clear_item()
            this.dialog_webhook_clone_form = false
        },
        ...mapActions('Installation', {
            installation_show: 'show',
            installation_clear: 'clear',
            installation_clear_item: 'clear_item',
        }),
        ...mapActions('InstallationAccount', {
            installation_account_index: 'index',
            installation_account_destroy: 'destroy',
            installation_account_clear: 'clear',
        }),
        ...mapActions('Workflow', {
            workflow_index: 'index',
            workflow_store: 'store',
            workflow_select:'select',
            workflow_clone: 'clone_store',
            workflow_destroy: 'destroy',
            workflow_clear: 'clear',
            workflow_clear_item: 'clear_item',
        }),
    },
}
</script>

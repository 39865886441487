import { mapState, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState('AutomationWorkflow', {
            automation_workflow_hook_response: 'hook_response',
        }),
    },

    methods: {
        ...mapActions('AutomationWorkflow', {
            automation_workflow_hook_clear: 'hook_clear',
            automation_workflow_hook: 'hook'
        }),
    }
}

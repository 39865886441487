<template>
    <a-avatar v-if="['green', 'on-track'].includes(healthType)" size="16" color="green lighten-4">
        <a-avatar size="13" color="green lighten-3">
            <a-icon size="10" color="green darken-2">fiber_manual_record</a-icon>
        </a-avatar>
    </a-avatar>
    <a-avatar v-else-if="['yellow', 'at-risk'].includes(healthType)" size="16" color="amber lighten-4">
        <a-avatar size="13" color="amber lighten-3">
            <a-icon size="10" color="amber darken-2">fiber_manual_record</a-icon>
        </a-avatar>
    </a-avatar>
    <a-avatar v-else size="16" color="red lighten-4">
        <a-avatar size="13" color="red lighten-3">
            <a-icon size="10" color="red darken-2">fiber_manual_record</a-icon>
        </a-avatar>
    </a-avatar>
</template>

<script>
export default {
    props: {
        healthType: {
            type: String,
        },
    }
}
</script>

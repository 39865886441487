import Vue from 'vue'

let handleOutsideClick;

Vue.directive('outsideClick', {
    bind (el, binding, vnode) {
        handleOutsideClick = (evt) => {
            evt.stopPropagation()

            const { handler, props = {} } = binding.value || {}
            if (!el.contains(evt.target)) {
                if (handler) return vnode.context[handler](evt, props)
                document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape', code: 'Escape' }))
            }
        }

        document.addEventListener('click', handleOutsideClick)
    },

    unbind () {
        document.removeEventListener('click', handleOutsideClick)
    }
})

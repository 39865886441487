<template>
    <div>
        <InternalLinks v-if="local_user_is_external" />
        <ExternalLinks v-else />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import InternalLinks from '@/pages/Projects/ProjectsLinks.vue'
import ExternalLinks from '@/pages/External/ExternalProjects/ExternalProjectsLinks.vue'

export default {
    components: {
        ExternalLinks,
        InternalLinks
    },

    computed: {
        local_user_is_external() {
            return this.user_self && this.user_self.scope === 'internal'
        },
        ...mapState('User', {
            user_self: 'self'
        })
    }

}
</script>

<template>
    <a-sheet class="u-rounded-corners-full orange darken-1 d-inline-flex align-center justify-center">
        <a-icon :size="size" color="white">bolt</a-icon>
    </a-sheet>
</template>

<script>
export default {
    props: {
        size: {
            type: String | Number,
            default: '16'
        }
    }
}
</script>

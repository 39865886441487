const api_endpoint = '/confidence-score-logs'

export default {
    namespaced: true,

    state: {
        item: {},
        response: {}
    },

    mutations: {
        UPDATE_ITEM (state, payload) {
            state.item = payload
        },

        THROW_RESPONSE (state, payload) {
            state.response = payload
        }
    },

    actions: {
        async show (context, payload) {
            try {
                const { data: { data } } = await axios.get(`${api_endpoint}/${payload.id}`, { params: payload.params })
                context.commit('UPDATE_ITEM', data)
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: data });
            }
            catch (error) {
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            }
        }
    }

}

<template>
    <div>
        <a-menu
            :content-class="`u-shadow-md u-rounded-corners white ${contentClass}`"
            v-bind="$attrs"
            :max-width="localIsTypeExternal ? 420 : 440"
            :close-on-content-click="false"
            :disabled="disabled"
            open-on-hover bottom offset-y
        >
            <template v-slot:activator="{ on }">
                <div v-on="on">
                    <slot></slot>
                </div>
            </template>
            <a-sheet class="py-5 px-6" v-if="localIsTypeExternal">
                <div class="u-flex-center-y">
                    <a-icon size="20" color="orange darken-2" class="mr-1">info</a-icon>
                    <h3 class="md-subtitle-1 orange--text text--darken-2 font-weight-medium">Action could not be completed</h3>
                </div>
                <div class="md-body-1 mt-3 grey--text text--darken-2">
                    Please contact your Sales Engineer to update the status.
                </div>
            </a-sheet>
            <a-sheet class="py-5 px-6" v-else>
                <div class="u-flex-center-y">
                    <a-icon size="20" color="orange darken-2" class="mr-1">info</a-icon>
                    <h3 class="md-subtitle-1 orange--text text--darken-2 font-weight-medium">Action could not be completed</h3>
                </div>
                <div class="md-body-1 my-3 grey--text text--darken-2">
                    This project is currently in Inactive stage. Please move the project to an active stage and try again.
                </div>
                <slot name="move-stage" :moveStage="localShowStageUpdateModal" v-if="['queue', 'completed', 'archived'].includes(stage)">
                    <span @click="localShowStageUpdateModal()" class="d-inline-flex align-center u-cursor-pointer">
                        <h3 class="blue--text md-body-2 font-weight-medium">Move to active stage</h3>
                        <a-icon size="16" color="blue" class="ml-1">trending_flat</a-icon>
                    </span>
                </slot>
            </a-sheet>
        </a-menu>

        <!-- Stage Change Dialog -->
        <a-dialog v-model="dialogStageChange" max-width="640">
            <ModalStageChange
                :currentStage="currentStage"
                :selectedStage="stageToChange"
                :isOpen="dialogStageChange"
                @update="localUpdateStage"
                @close="localCloseStageUpdate"
                hideWorkspaceBtn
            >
            </ModalStageChange>
        </a-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ModalStageChange } from '@/pages/Projects/Modals'

export default {
    inheritAttrs: false,

    props: {
        stage: {
            type: String,
            default: 'Queue'
        },
        contentClass: {
            type: String,
            default: ''
        },
        project: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false,
            required: true
        },
        typeExternal: {
            type: Boolean,
            default: false
        }
    },

    components: {
        ModalStageChange
    },

    data () {
        return {
            currentStage: {},
            stageToChange: {},
            dialogStageChange: false,
        }
    },

    computed: {
        localIsTypeExternal () {
            const { scope } = this.user_self || {}
            const { view: currentView } = this.$route.meta || {}
            return (currentView && currentView === 'external') || (scope && scope === 'external')
        },

        ...mapState('Stage', {
            stage_list: 'list'
        }),

        ...mapState('User', {
            user_self: 'self'
        }),
    },

    methods: {
        async localShowStageUpdateModal () {
            this.dialogStageChange = true
            await this.stage_index({
                sort: 'order',
                'filter[workspace_id]': this.project.workspace_id,
                'fields[stages]': 'id,name,status,order'
            })
            const activeStages = this.stage_list.filter(i => i.status === 'active')
            this.currentStage = this.stage_list.find(i => i.id === this.project.stage_id)
            this.stageToChange = activeStages[0]
        },

        async localUpdateStage (prop, resetUpdate) {
            const stage = this.stageToChange
            const project = _.cloneDeep(this.project)
            Object.assign(project, { stage, stage_id: stage.id, status: stage.status })

            const { workspace_id, stage_id, id: project_id } = project
            this.project_update_item(project)

            await this.project_workspace_update({ workspace_id, stage_id, project_id, result_id: null })
            this.$emit('stage-change',  project_id)
            resetUpdate()
            this.localCloseStageUpdate()
        },

        localCloseStageUpdate () {
            this.dialogStageChange = false
            this.currentStage = {}
            this.stageToChange = {}
        },

        ...mapActions('ProjectView', {
            project_update_item: 'update_item',
            project_workspace_update: 'workspace_update',
        }),

        ...mapActions('Stage', {
            stage_index: 'index'
        }),
    }
}
</script>

import { v4 as uuidv4 } from 'uuid'
const endpoint = '/scopes'

export default {
    namespaced: true,
    state: {
        list: [],
        response: {},
        defaults: {
            list: [],
            response: {},
        },
    },

    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        CLEAR_RESPONSE(state) {
            state.response = {};
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },

    actions: {
        async index (context, payload) {
            try {
                const { data: { data: response } } = await axios.get(endpoint, { params: { ...payload } })
                context.commit('UPDATE_LIST', response);
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },

        async update (context, payload) {
            const id = payload.id ?? uuidv4()
            try {
                await axios.put(endpoint + '/' + id, payload)
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            } catch (error) {
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
    },

    getters: {
        list: (state) => state.list,
        response: (state) => state.response,
    }
}

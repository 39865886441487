class Validate {
    constructor (text, { field = '', silent = false }) {
        this.field = field
        this.silent = silent
        this.text = text
        this.errors = []
    }

    run () {
        if (this.errors.length) {
            if (this.silent) return { message: this.errors[0].message }
            else throw new ValidationError(this.errors[0].message)
        }
        return true
    }

    message (text) {
        this.text = text
    }

    length (min = 1, max = 255) {
        const length = (this.text && this.text.trim().length) ?? 0

        if (length < min) this.throwError(`Minimum ${min} character required${this.field ? ' - ' + this.field : ''}`)
        if (length > max) this.throwError(`Maximum of ${max} characters allowed${this.field ? ' - ' + this.field : ''}`)
        return this
    }

    numberRange (min = 0.00, max = 999999999.99, type = 'float', message = null) {
        const value = type === 'float' ? parseFloat(this.text) : parseInt(this.text)

        if (value && isNaN(parseFloat(value))) this.throwError(message ?? `Invalid input, only numbers allowed`)
        if (value > max) this.throwError(message ?? `Should be between ${min} and ${max}`)
        return this
    }

    required (text = this.text) {
        if (this.isEmpty(text)) this.throwError(`Required field${this.field ? ' - ' + this.field : ''}`)
        return this
    }

    isNumber (value = this.text) {
        const isNumber = new RegExp(/^[0-9]*$/g)
        value = value.replace(/,/g, '')
        if (isNaN(value) && !isNumber.test(value)) this.throwError(`Value should be number${this.field ? ' - ' + this.field : ''}`)
        return this
    }

    isEmpty (text = this.text) {
        if (text === undefined || text === null || text === '' || (text && !text.length)) return true
        return false
    }

    isUrl (link) {
        const regex = /(https?:\/\/(www\.)?(([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*)?\.)?(([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*)?\.)?(([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*))?\.(([a-zA-Z]{2,63})?\.)?([a-zA-Z]{2,63})([\/])?([a-zA-Z0-9()!@:%_\+~#?&\/\/=]*[a-z][.a-zA-Z0-9()!@:%_\+\-~#?&\/\/=]*)?)/gi

        let link_match = link.match(regex)

        if (!link_match || link_match[0] !== link) this.throwError('The link format is invalid')
        return this
    }

    urlDomainLength (text) {
        let http_excluded_domain = text.split('http://')

        if (http_excluded_domain[0]) {
            let https_excluded_domain = text.split('https://')
            let domain_list = https_excluded_domain[1].split('.')

            domain_list.forEach((item, index, array) => {
                if (index === array.length-1){
                    let last_ext = item.split('/')
                    if (last_ext[0].length < 2 || last_ext[0].length > 63) {
                        this.throwError('The domain\'s character limit is minimum 2 to 63')
                    } else return
                }
                if (item.length < 2 || item.length > 63) {
                    return this.throwError('The domain\'s character limit is minimum 2 to 63')
                }
            })
        } else {
            let domain_list = http_excluded_domain[1].split('.')
            domain_list.forEach((item, index, array) => {
                if (index === array.length-1) {
                    let last_ext = item.split('/')
                    if (last_ext[0].length < 2 || last_ext[0].length > 63) {
                        this.throwError('The domain\'s character limit is minimum 2 to 63')
                    } else return
                }
                if (item.length < 2 || item.length > 63) {
                    return this.throwError('The domain\'s character limit is minimum 2 to 63')
                }
            })
        }
        return this
    }

    isDomain (text) {
        const expression = /^((www\.)?(([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*)?\.)?(([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*)?\.)?(([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*))?\.(([a-zA-Z]{2,63})?\.)?([a-zA-Z]{2,63}))$/

        let domain_match = text.match(expression)

        if (!domain_match) this.throwError('The domain format is invalid')
        return this
    }

    domainLength (text) {
        let domain_list = text.split('.')
        domain_list.forEach((item) => {
            if ( item.length < 2 || item.length > 63) {
                return this.throwError('The domain\'s character limit is minimum 2 to 63')
            }
        })
        return this
    }

    throwError (msg) {
        this.errors.push(new Error(msg))
    }
}

class ValidationError extends Error {
    constructor (message, name = 'ValidationError') {
        super(message)
        this.name = name
    }
}

export { Validate }

<template>
    <a-container container--fluid grid-list-xl pa-6 mt-4>
        <a-layout wrap align-start v-if="!local_loading">
            <template v-if="local_has_any_permission">
                <a-flex xs12 md6 xl4 v-if="can_external_mixin('usecases.index')">
                    <a-sheet class="u-flex align-center u-rounded-corners pa-6 u-shadow">
                        <div class="u-flex align-center">
                            <h2 class="text-h6 font-weight-medium grey--text text--darken-1 mr-3" style="line-height: 1.1">Scope</h2>
                            <template v-if="can_internal_mixin('usecases.index')">
                                <h3 class="md-body-2 grey--text text--darken-1" v-if="project_item.external_usecases_count > 0">section contains {{ project_item.external_usecases_count }} scope{{ project_item.external_usecases_count > 1 ? 's' : '' }}</h3>
                                <h3 class="md-body-2 grey--text text--darken-1" v-else>No Usecases</h3>
                            </template>
                            <h3 class="md-body-2 grey--text text--darken-1 u-flex align-center" v-else>
                                <a-icon size="18" color="orange darken-4" class="mr-1">warning</a-icon>
                                <span>No permission to view</span>
                            </h3>
                        </div>
                    </a-sheet>
                </a-flex>
                <a-flex xs12 md6 xl4 v-if="can_external_mixin('tasks.index')">
                    <a-sheet class="u-flex align-center u-rounded-corners pa-6 u-shadow">
                        <div class="u-flex align-center">
                            <h2 class="text-h6 font-weight-medium grey--text text--darken-1 mr-3" style="line-height: 1.1">Task</h2>
                            <h3 v-if="can_internal_mixin('tasks.index')" class="md-body-2 grey--text text--darken-1">{{ project_item.external_tasks_count - project_item.external_tasks_pending_count }} completed out of {{ project_item.external_tasks_count }} task{{ project_item.external_tasks_count > 1 ? 's' : '' }}</h3>
                            <h3 class="md-body-2 grey--text text--darken-1 u-flex align-center" v-else>
                                <a-icon size="18" color="orange darken-4" class="mr-1">warning</a-icon>
                                <span>No permission to view</span>
                            </h3>
                        </div>
                    </a-sheet>
                </a-flex>
                <a-flex xs12 md6 xl4 v-if="can_external_mixin('links.index')">
                    <a-sheet class="u-flex align-center u-rounded-corners pa-6 u-shadow">
                        <div class="u-flex align-center">
                            <h2 class="text-h6 font-weight-medium grey--text text--darken-1 mr-3" style="line-height: 1.1">Link</h2>
                            <h3 v-if="can_internal_mixin('links.index')" class="md-body-2 grey--text text--darken-1">section contains {{ project_item.external_links_count }} link{{ project_item.external_links_count > 1 ? 's' : '' }}</h3>
                            <h3 class="md-body-2 grey--text text--darken-1 u-flex align-center" v-else>
                                <a-icon size="18" color="orange darken-4" class="mr-1">warning</a-icon>
                                <span>No permission to view</span>
                            </h3>
                        </div>
                    </a-sheet>
                </a-flex>
                <a-flex xs12 md6 xl4 v-if="can_external_mixin('documents.index')">
                    <a-sheet class="u-flex align-center u-rounded-corners pa-6 u-shadow">
                        <div class="u-flex align-center">
                            <h2 class="text-h6 font-weight-medium grey--text text--darken-1 mr-3" style="line-height: 1.1">Document</h2>
                            <h3 v-if="can_internal_mixin('documents.index')" class="md-body-2 grey--text text--darken-1">section contains {{ project_item.external_documents_count }} document{{ project_item.external_documents_count > 1 ? 's' : '' }}</h3>
                            <h3 class="md-body-2 grey--text text--darken-1 u-flex align-center" v-else>
                                <a-icon size="18" color="orange darken-4" class="mr-1">warning</a-icon>
                                <span>No permission to view</span>
                            </h3>
                        </div>
                    </a-sheet>
                </a-flex>
                <a-flex xs12 md6 xl4 v-if="can_external_mixin('notes.index')">
                    <a-sheet class="u-flex align-center u-rounded-corners pa-6 u-shadow">
                        <div class="u-flex align-center">
                            <h2 class="text-h6 font-weight-medium grey--text text--darken-1 mr-3" style="line-height: 1.1">Note</h2>
                            <h3 v-if="can_internal_mixin('notes.index')" class="md-body-2 grey--text text--darken-1">section contains {{ project_item.external_notes_count }} note{{ project_item.external_notes_count > 1 ? 's' : '' }}</h3>
                            <h3 class="md-body-2 grey--text text--darken-1 u-flex align-center" v-else>
                                <a-icon size="18" color="orange darken-4" class="mr-1">warning</a-icon>
                                <span>No permission to view</span>
                            </h3>
                        </div>
                    </a-sheet>
                </a-flex>
            </template>
            <template v-if="!local_has_any_permission">
                <a-flex xs12 class="u-flex align-center">
                    <a-icon class="u-icon-nudge mr-2" color="yellow darken-4">warning</a-icon>
                    <h2 class="text-subtitle-1 grey--text text--darken-1">No data to show. You don't have sufficient permission to view the project.</h2>
                </a-flex>
            </template>
        </a-layout>
        <a-layout wrap align-start v-if="local_loading">
            <a-flex xs12>
                <h2 class="text-subtitle-1 text-center grey--text text--darken-1">Loading...</h2>
            </a-flex>
        </a-layout>
        <h4 v-if="local_check_empty_fields()" class="grey--text text--darken-1 font-weight-medium md-subtitle-1 px-4 my-4">Tags</h4>
        <template v-if="filteredSection && filteredSection.length">
            <template v-for="section in filteredSection">
                <a-sheet class="white u-shadow u-rounded-corners mb-4" :key="section.id" v-if="section && section.fields && section.fields.length">
                    <h4 class="indigo--text text--darken-1 font-weight-medium px-6 py-5 md-subtitle-1 u-wfull">{{ section.name }}</h4>
                    <a-divider></a-divider>
                    <div v-for="(field, index) in section.fields" :key="field.id">
                        <div v-if="field" class="u-flex align-center">
                            <template>
                                <h2 style="min-width: 184px; max-width: 184px" class="md-subtitle-1 font-weight-medium primary--text text--lighten-2 text-truncate pa-6">
                                    <template v-if="field.parent_type === 'SystemTagSection'">{{ field.parent.value }}</template>
                                    <template v-else>{{ field.parent.name }}</template>
                                </h2>
                                <div class="pa-6">
                                    <template v-for="tag in localProjectFilteredTags(local_get_tag_slug(field))">
                                        <g-tags :can-update="false" :tag="tag" type="Project" :key="tag.id"></g-tags>
                                    </template>
                                    <h3 v-if="localProjectFilteredTags(local_get_tag_slug(field)).length === 0" class="md-body-2 grey--text text--darken-1">No tags</h3>
                                </div>
                            </template>
                        </div>
                        <a-divider v-if="index !== section.fields && section.fields.length"></a-divider>
                    </div>
                </a-sheet>
            </template>
        </template>
    </a-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ExternalPermissionMixin from '@/mixins/mixin-external-view-permission'

export default {
    mixins: [ExternalPermissionMixin],

    data () {
        return {
            project_filters: {},
            local_loading: true,
            field_layout_params: { 'include': 'fields.parent', 'filter[type]': 'project_fields_and_tags', 'filter[is_active]': 1 },
            local_layout_design_list: [],
            filteredSection: []
        }
    },

    mounted() {
        this.local_index()
    },

    computed: {
        local_has_any_permission() {
            const permissions = ['usecases.index', 'tasks.index', 'links.index', 'documents.index', 'notes.index']
            return permissions.some(item => this.can_external_mixin(item))
        },

        ...mapState('Role', {
            role_external_permission: 'external_permission',
        }),

        ...mapState('Project', {
            project_item: 'item',
            project_response: 'response'
        }),

        ...mapState('TagSectionExternal', {
            tag_section_list: 'list',
        }),

        ...mapGetters('VisibilityExternal', {
            visibility_get_visibility_item: 'get_visibility_item',
        }),

        ...mapGetters('PreferenceExternal', {
            preference_get_property_by_key: 'get_property_by_key',
        }),
    },

    methods: {
        async local_index() {
            this.project_filters.include = 'externalUsecasesCount,externalTasksPendingCount,externalTasksCount,externalDocumentsCount,externalNotesCount,externalLinksCount'
            this.project_filters = {...this.project_filters, 'filter[layout_designer]':  1}
            this.project_filters.is_visible_external_tags = 1
            await this.project_show({ id: this.$route.params.id, params: this.project_filters })
            this.local_layout_design_list = _.cloneDeep(this.project_item.layoutDesigner)
            await this.localFetchVisibility()
            this.local_filter_tag_sections()
            this.local_loading = false
        },

        /* Visibility - Start */
        localHasVisibility (field, value) {
            const data = this.visibility_get_visibility_item(field, value)
            if(!data) return null
            return data.project_visibility ? data.project_visibility.visibility : data.visibility
        },
        async localFetchVisibility () {
            await this.visibility_index({
                'include': 'projectVisibility',
                'project_id': this.project_item.id,
                'fields[visibilities]': 'id,key,visibility',
            })
        },
        /* Visibility - End */

        localProjectFilteredTags (type) {
            if (type) return _.filter(this.project_item.external_tags, { type: type.toLowerCase() })
        },

        local_get_tag_slug (tagSection) {
            if (tagSection.parent && tagSection.parent.slug) return tagSection.parent.slug
            return tagSection.parent.status
        },

        local_filter_tag_sections () {
            this.local_layout_design_list.forEach((section, index) => {
                const tagSection = section.fields.filter(field => (field.parent_type !== 'CustomField') && this.localHasVisibility('key', field.parent_type === 'CustomTagSection' ? this.local_get_tag_slug(field) : `project_${this.local_get_tag_slug(field)}`) === 'external-ready-only')
                this.filteredSection.push(section)
                this.filteredSection[index].fields = []
                this.filteredSection[index].fields.push(...tagSection)
            })
            return this.filteredSection
        },

        local_check_empty_fields () {
            return this.filteredSection.filter(section => section.fields && section.fields.length).length
        },

        ...mapActions('Project', {
            project_show: 'show',
            project_clear_item: 'clear_item',
        }),

         ...mapActions('TagSectionExternal', {
            tag_section_index: 'index',
        }),

        ...mapActions('VisibilityExternal', {
            visibility_index: 'index',
            visibility_upsert_store: 'upsert',
            visibility_clear: 'clear',
        }),

    },

}
</script>

const colors = [
    {
        name: 'red-light',
        color_bg: '#FFEBEE',
        color_text: '#D32F2F'
    },
    {
        name: 'pink-light',
        color_bg: '#FCE4EC',
        color_text: '#C2185B'
    },
    {
        name: 'purple-light',
        color_bg: '#F3E5F5',
        color_text: '#7B1FA2'
    },
    {
        name: 'deep-purple-light',
        color_bg: '#EDE7F6',
        color_text: '#512DA8'
    },
    {
        name: 'indigo-light',
        color_bg: '#E8EAF6',
        color_text: '#303F9F'
    },
    {
        name: 'blue-light',
        color_bg: '#E3F2FD',
        color_text: '#1976D2'
    },
    {
        name: 'light-blue-light',
        color_bg: '#E1F5FE',
        color_text: '#0288D1'
    },
    {
        name: 'cyan-light',
        color_bg: '#E0F7FA',
        color_text: '#0097A7'
    },
    {
        name: 'teal-light',
        color_bg: '#E0F2F1',
        color_text: '#00796B'
    },
    {
        name: 'green-light',
        color_bg: '#E8F5E9',
        color_text: '#388E3C'
    },
    {
        name: 'light-green-light',
        color_bg: '#F1F8E9',
        color_text: '#689F38'
    },
    {
        name: 'lime-light',
        color_bg: '#F9FBE7',
        color_text: '#AFB42B'
    },
    {
        name: 'yellow-light',
        color_bg: '#FFFDE7',
        color_text: '#FBC02D'
    },
    {
        name: 'amber-light',
        color_bg: '#FFF8E1',
        color_text: '#FFA000'
    },
    {
        name: 'orange-light',
        color_bg: '#FFF3E0',
        color_text: '#F57C00'
    },
    {
        name: 'deep-orange-light',
        color_bg: '#FBE9E7',
        color_text: '#E64A19'
    },
    {
        name: 'brown-light',
        color_bg: '#EFEBE9',
        color_text: '#5D4037'
    },
    {
        name: 'blue-grey-light',
        color_bg: '#ECEFF1',
        color_text: '#455A64'
    },
    {
        name: 'grey-light',
        color_bg: '#FAFAFA',
        color_text: '#616161'
    },
    {
        name: 'white',
        color_bg: '#FFFFFF',
        color_text: '#424242'
    },
    {
        name: 'red-dark',
        color_bg: '#FF5252',
        color_text: '#FFFFFF'
    },
    {
        name: 'pink-dark',
        color_bg: '#FF4081',
        color_text: '#FFFFFF'
    },
    {
        name: 'purple-dark',
        color_bg: '#E040FB',
        color_text: '#FFFFFF'
    },
    {
        name: 'deep-purple-dark',
        color_bg: '#7C4DFF',
        color_text: '#FFFFFF'
    },
    {
        name: 'indigo-dark',
        color_bg: '#536DFE',
        color_text: '#FFFFFF'
    },
    {
        name: 'blue-dark',
        color_bg: '#448AFF',
        color_text: '#FFFFFF'
    },
    {
        name: 'light-blue-dark',
        color_bg: '#0091EA',
        color_text: '#FFFFFF'
    },
    {
        name: 'cyan-dark',
        color_bg: '#00B8D4',
        color_text: '#FFFFFF'
    },
    {
        name: 'teal-dark',
        color_bg: '#00BFA5',
        color_text: '#FFFFFF'
    },
    {
        name: 'green-dark',
        color_bg: '#00C853',
        color_text: '#FFFFFF'
    },
    {
        name: 'light-green-dark',
        color_bg: '#64DD17',
        color_text: '#FFFFFF'
    },
    {
        name: 'lime-dark',
        color_bg: '#9E9D24',
        color_text: '#FFFFFF'
    },
    {
        name: 'yellow-dark',
        color_bg: '#FBC02D',
        color_text: '#FFFFFF'
    },
    {
        name: 'amber-dark',
        color_bg: '#FF8F00',
        color_text: '#FFFFFF'
    },
    {
        name: 'orange-dark',
        color_bg: '#FF6D00',
        color_text: '#FFFFFF'
    },
    {
        name: 'deep-orange-dark',
        color_bg: '#FF3D00',
        color_text: '#FFFFFF'
    },
    {
        name: 'brown-dark',
        color_bg: '#6D4C41',
        color_text: '#FFFFFF'
    },
    {
        name: 'blue-grey-dark',
        color_bg: '#546E7A',
        color_text: '#FFFFFF'
    },
    {
        name: 'grey-dark',
        color_bg: '#424242',
        color_text: '#FFFFFF'
    },
    {
        name: "black",
        color_bg: '#212121',
        color_text: '#FFFFFF'
    }
]

export default colors

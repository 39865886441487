<template>
    <div>
        <a-container container--fluid grid-list-xl pa-6>
            <p-external-usecase-toolbar
                :list="usecase_list"
                :can-store="permission_usecase('store')"
                :can-delete="permission_usecase('destroy')"
                :expanded-all="accordion_usecases_all"
                :loading="loading"
                :bulk-selected="show_bulk_delete"
                @create="local_usecase_create"
                @bulk-select="(evt) => show_bulk_delete = evt"
                @delete-all="dialog_usecase_delete_all = !dialog_usecase_delete_all"
                v-if="(usecase_list && usecase_list.length) || permission_usecase('store')"
            ></p-external-usecase-toolbar>
            <a-responsive class="my-4" v-if="local_loading">
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Use Cases..</h3>
            </a-responsive>
            <a-responsive class="my-4" v-if="!local_loading && usecase_list.length === 0">
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
            </a-responsive>
            <a-layout wrap align-start>
                <a-flex xs12 pt-0>
                    <!-- Bulk delete -->
                    <div class="u-flex align-center" v-if="show_bulk_delete">
                        <a-sheet class="u-cursor-pointer d-inline-flex align-center pa-1 pl-2 pr-3 mr-2 u-rounded-corners u-border" @click="local_bulk_select_all_toggle">
                            <a-checkbox v-model="bulk_selected_all" @click="local_bulk_select_all_toggle" class="ma-0 pa-0" :disabled="bulk_delete_loading || !local_usecase_list_length" dense hide-details></a-checkbox>
                            <span class="md-subtitle-2 grey--text text--darken-2 ml-n1">Select All</span>
                        </a-sheet>
                        <a-menu bottom right offset-y max-width="360" v-if="local_usecase_list_length" :disabled="bulk_delete_loading || !local_usecase_list_length" content-class="u-rounded-corners">
                            <template v-slot:activator="{ on }">
                                <a-sheet v-on="on" class="u-cursor-pointer d-inline-flex align-center pa-1 pl-2 pr-3 mr-2 u-rounded-corners u-border" min-width="120" max-width="128">
                                    <a-sheet width="24" height="24" class="u-flex-center">
                                        <a-progress-circular width="3" size="20" indeterminate color="orange darken-2" v-if="bulk_delete_loading"></a-progress-circular>
                                        <a-icon color="red darken-2" size="24" v-else>delete_outline</a-icon>
                                    </a-sheet>
                                    <span class="md-subtitle-2 red--text text--darken-2 ml-1">Delete</span>
                                    <a-spacer></a-spacer>
                                    <span class="md-subtitle-2 red--text text--darken-2 ml-1">({{ bulk_delete_ids.length }})</span>
                                </a-sheet>
                            </template>
                            <a-sheet class="pa-5 ">
                                <div class="mb-4">
                                    <h3 class="md-heading-6 body--text text--darken-3 mb-1">Are you sure to continue?</h3>
                                    <p class="md-subtitle-1 body--text text--darken-1 mb-0">This will delete all the selected use cases and cannot be reverted.</p>
                                </div>
                                <a-sheet class="u-cursor-pointer d-inline-flex align-center pa-1 px-2 u-rounded-corners u-border red darken-2" @click="!bulk_delete_loading ? local_bulk_delete() : {}">
                                    <span class="md-subtitle-2 white--text text--darken-2 ml-1">Confirm Delete</span>
                                </a-sheet>
                            </a-sheet>
                        </a-menu>
                        <a-sheet class="u-cursor-pointer grey lighten-2 pa-1 px-2 u-rounded-corners u-border" @click="local_cancel_bulk_delete()">
                            <span class="md-subtitle-2 grey--text text--darken-1">Cancel</span>
                        </a-sheet>
                        <div>
                            <span class="grey--text text--darken-1 md-body-2 ml-1">or press</span>
                            <span class="c-mono-font grey--text text--darken-2 font-weight-bold md-caption ml-1">(Esc)</span>
                        </div>
                    </div>

                    <!-- Use case list -->
                    <template v-if="!local_loading && usecase_list.length">
                        <draggable v-model="usecase_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                            <template v-for="(usecase, index) in usecase_list">
                                <p-external-usecase-card
                                    :usecase="usecase"
                                    :can-update="permission_usecase('update')"
                                    :itemIndex="index"
                                    handle="js-drag-handle"
                                    :disable-navigation="bulk_delete_loading || show_bulk_delete"
                                    @click.stop="show_bulk_delete ? local_add_to_bulk_delete(usecase.id) : ''"
                                    :key="usecase.id"
                                    :item-path="{name: 'projects-usecases-view', params: {usecase_id: usecase.id}}"
                                    :class="['mt-4']"
                                >
                                    <template #selectable>
                                        <v-slide-x-transition leave-absolute hide-on-leave>
                                            <a-checkbox
                                                v-if="show_bulk_delete"
                                                :value="local_is_bulk_selected(usecase.id)"
                                                :disabled="bulk_delete_loading"
                                                @click.stop="local_add_to_bulk_delete(usecase.id)"
                                                class="ma-0 pa-0"
                                                style="margin-top: 2px !important;"
                                                dense hide-details
                                            ></a-checkbox>
                                        </v-slide-x-transition>
                                    </template>
                                    <template #assignees>
                                        <s-assignee-dropdown
                                            :item="usecase"
                                            :users-list="local_member_list(usecase.assignees)"
                                            :project-id="$route.params.id"
                                            :local_loading="local_loading"
                                            :can-update="permission_usecase('update')"
                                            :size="26"
                                            @menuOpen="mixinGetCollabs"
                                            module-type="Usecase"
                                            class="u-flex align-center justify-end mr-3"
                                            hide-empty-text
                                        ></s-assignee-dropdown>
                                    </template>
                                    <template #success-criteria-stats>
                                        <s-stats
                                            module="Success Criteria"
                                            :total="usecase.success_criteria_count"
                                            :open="usecase.success_criteria_open_count"
                                            :closed="usecase.success_criteria_closed_count"
                                            :passed="usecase.success_criteria_passed_count"
                                            :failed="usecase.success_criteria_failed_count"
                                            :invalid="usecase.success_criteria_invalid_count"
                                        ></s-stats>
                                    </template>
                                </p-external-usecase-card>
                            </template>
                        </draggable>
                    </template>
                </a-flex>
            </a-layout>
        </a-container>

        <a-dialog max-width="480" v-model="dialog_usecase_delete_all" persistent>
            <a-card class="pa-6 pb-7 px-8">
                <h2 class="md-heading-5 grey--text text--darken-3">Delete all use case</h2>
                <div class="mt-2 mb-5">
                    <p class="md-subtitle-1 grey--text text--darken-3 mb-1">Are you sure to delete all the Use Cases?</p>
                    <p class="md-subtitle-1 grey--text text--darken-1 mb-0">This will delete all the use cases in this project and cannot be undone.</p>
                </div>
                <div>
                    <a-btn small class="ma-0 px-3 elevation-0 red lighten-4 red--text text--darken-2" @click="local_usecase_delete_all()" :loading="loading">Delete All Use Case</a-btn>
                    <a-btn small color="grey" class="ma-0 ml-4 grey lighten-4" text @click="dialog_usecase_delete_all = !dialog_usecase_delete_all">Cancel</a-btn>
                </div>
            </a-card>
        </a-dialog>

        <a-dialog max-width="800" v-model="dialog_usecase_form" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="40">
                                    <a-icon dark>assignment</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1 mb-1">Use Case</h2>
                                <p class="md-subtitle-2 mb-0">You can add new use case to the project</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Use Case Title</label>
                                <a-text-field
                                    v-model="usecase_item.title"
                                    placeholder="Enter new use case title"
                                    background-color="neutral"
                                    solo flat hide-details
                                >
                                </a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="usecase_response.server && usecase_response.server.errors && usecase_response.server.errors.title">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ usecase_response.server.errors.title[0] }}
                                </span>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-center>
                            <a-flex shrink>
                                <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_usecase_store()" :loading="loading" :disabled="loading">Save</a-btn>
                                <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_usecase_form_close()" :disabled ="loading">Cancel</a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { SUserListPopup, SAssigneeForm, SStats, SAssigneeDropdown } from '@/config/config-shared-components'
import { PExternalUsecaseCard, PExternalUsecaseToolbar } from './../_Partials'
import mixinSearchCollaborators from '@/mixins/mixin-search-collaborators'

export default {
    mixins: [mixinSearchCollaborators],

    components: {
        SUserListPopup, SAssigneeForm, SStats, SAssigneeDropdown,
        PExternalUsecaseCard, PExternalUsecaseToolbar
    },

    data() {
        return {
            breadcrumb_items: [ { text: 'Dashboard', to: '/dashboard', exact: true, } ],
            dialog_usecase_form: false,
            show_bulk_delete: false,
            bulk_delete_loading: false,
            bulk_selected_all: false,
            bulk_delete_ids: [],
            accordion_usecases: [],
            accordion_usecases_all: false,
            search_collaborator: '',
            local_collaborator_list: [],
            dialog_usecase_delete_all: false,
            local_loading: true,
            local_usecase_fields: {
                'include': 'assignees,successCriteriaCount,successCriteriaOpenCount,successCriteriaClosedCount,successCriteriaPassedCount,successCriteriaFailedCount,successCriteriaInvalidCount,attachmentsCount'
            }
        }
    },

    mounted() {
        this.local_index()
        document.addEventListener('keydown', this.local_escap_cancel_bulk_delete)
    },

    beforeDestroy () {
        document.removeEventListener('keydown', this.local_escap_cancel_bulk_delete)
    },

    computed: {
        usecase_list: {
            get() {
                return this.$store.state.UsecaseExternal.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },
        local_usecase_list_length () {
            return this.usecase_list && this.usecase_list.length
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('UsecaseExternal', {
            usecase_item: 'item',
            usecase_filters: 'filters',
            usecase_response: 'response',
            usecase_form_mode: 'form_mode',
        }),
        ...mapState('ProjectExternal', {
            project_item: 'item',
        }),
        ...mapState('CollaboratorExternal', {
            collaborator_list: 'list',
            collaborator_filters: 'filters',
        }),
        ...mapState('AssigneeExternal', {
            assignee_response: 'response',
        }),
        ...mapState('CommentExternal', {
            comment_item: 'item',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('Association', {
            association_response: 'response',
        }),
    },

    methods: {
        async local_index() {
            if (this.$can('usecases.index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.usecase_clear()
            await this.local_usecase_index()

            await this.collaborator_index({
                'filter[project_id]': this.$route.params.id,
                'include': 'user,type',
                count: 200, page: 1
            });
            this.collaborator_filter_list()
            this.local_loading = false
        },
        async local_usecase_index () {
            await this.usecase_index({ params: { ...this.local_usecase_fields, 'filter[project_id]' : this.$route.params.id } })
            if (!this.local_usecase_list_length && this.show_bulk_delete) this.show_bulk_delete = false
        },
        async local_usecase_create() {
            await this.usecase_clear_item()
            this.dialog_usecase_form = true
        },
        async local_usecase_form_close() {
            await this.usecase_clear_item()
            this.dialog_usecase_form = false
        },
        async local_usecase_store() {
            this.usecase_item.project_id = this.$route.params.id
            // this.usecase_item.order = 'prepend'
            await this.usecase_store(this.usecase_item)
            if (this.usecase_response.status === 'success') {
                let usecase_id = this.usecase_item.id
                await this.usecase_clear_item()
                this.$router.push({name: 'projects-usecases-view', params: {usecase_id: usecase_id} })
            }
        },
        async local_reorder(list) {
            await this.usecase_reorder({list: list, project_id: this.$route.params.id})
        },
        local_due_date_passed(date, status) {
            return moment(date).isBefore() && status == 'Closed'
        },
        async local_usecase_delete_all() {
            await this.usecase_delete_all({project_id: this.project_item.id})
            if (this.usecase_response.status == 'success') {
                this.dialog_usecase_delete_all = false
            }
        },
        local_bulk_select_all_toggle () {
            if (this.bulk_delete_loading || !this.local_usecase_list_length) return
            this.bulk_selected_all = !this.bulk_selected_all
            if (this.bulk_selected_all) {
                this.bulk_delete_ids = this.usecase_list.map(({id}) => id)
                return true
            }
            this.bulk_delete_ids = []
        },

        local_add_to_bulk_delete (usecase_id) {
            const hasIdIndex = this.bulk_delete_ids.findIndex(id => id === usecase_id)
            if (hasIdIndex !== -1) this.bulk_delete_ids.splice(hasIdIndex, 1)
            else this.bulk_delete_ids.push(usecase_id)

            if (this.bulk_delete_ids.length === this.local_usecase_list_length) this.bulk_selected_all = true
            else this.bulk_selected_all = false
        },

        local_is_bulk_selected (usecase_id) {
            return this.bulk_delete_ids.includes(usecase_id)
        },

        local_escap_cancel_bulk_delete (evt) {
            if (evt.keyCode === 27 || evt.key === 'Escape') this.local_cancel_bulk_delete()
        },

        local_cancel_bulk_delete () {
            this.show_bulk_delete = false
            this.bulk_delete_loading = false
            this.bulk_selected_all = false
            this.bulk_delete_ids = []
        },

        async local_bulk_delete () {
            if (!this.bulk_delete_ids.length) return this.$notify('warning', 'Minimum 1 use case required to perform this operation.')
            this.bulk_delete_loading = true
            await this.usecase_bulk_delete({ project_id: this.project_item.id, id: this.bulk_delete_ids })
            if (!this.$status(this.usecase_response)) {
                this.bulk_delete_loading = false
                return this.$notify('error', 'Something went wrong, please try again later.')
            }
            await this.local_usecase_index()
            this.bulk_delete_loading = false
            this.$notify('success', `Deleted ${this.bulk_delete_ids.length} use case${this.bulk_delete_ids.length > 1 ? 's' : ''} successfully!`)
            this.bulk_selected_all = false
            this.bulk_delete_ids = []
        },
        local_collaborator_item() {
            let search = this.search_collaborator
            return _.filter(this.local_collaborator_list, function(list) {
                     return list.user.name.toLowerCase().indexOf(search.toLowerCase())>=0;
                });
        },
        collaborator_filter_list() {
            this.local_collaborator_list = this.collaborator_list
        },
        local_member_list(list) {
            return this.collaborator_list.filter(item => !(_.find(list, { id: item.user_id })))
        },
        permission_usecase(type) {
            return this.$can('projects.update-only') && this.$can(`usecases.${type}`)
        },
        ...mapActions('UsecaseExternal', {
            usecase_index: 'index',
            usecase_show: 'show',
            usecase_store: 'store',
            usecase_update: 'update',
            usecase_assign: 'assign',
            usecase_client_visible: 'client_visible',
            usecase_unassign: 'unassign',
            usecase_reorder: 'reorder',
            usecase_template_import: 'template_import',
            usecase_delete_all: 'delete_all',
            usecase_bulk_delete: 'bulk_delete',
            usecase_select: 'select',
            usecase_clear: 'clear',
            usecase_clear_item: 'clear_item',
        }),
        ...mapActions('CommentExternal', {
            comment_store: 'store',
            comment_clear_item: 'clear_item',
        }),
        ...mapActions('CollaboratorExternal', {
            collaborator_index: 'index',
            collaborator_clear: 'clear',
        }),
        ...mapActions('AssigneeExternal', {
            assignee_store: 'store',
            assignee_destroy: 'destroy',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .c-bubble {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        border-radius: 4px 0px 32px 0px !important;
        background: #faa730 !important;
        &--private {
            background: #dcdcdc !important;
        }
    }
</style>

<template>
    <div class="pb-16" :class="{ 'u-no-select u-no-pointer': isBoardExport }">
        <div v-if="local_check_loading('tile_index') || parentLoading" key="temp-loader" style="display: grid; gridTemplateRows: masonry; gridTemplateColumns: repeat(4, 1fr); transition: 0.4s all; gap: 24px; max-width: 1160px;">
            <loader-template width="272" height="272" v-for="i in 7" :key="i"></loader-template>
        </div>
        <template v-else>
            <a-sheet
                v-if="tile_list && !tile_list.length"
                width="272"
                height="272"
                class="u-rounded-corners-lg u-flex-center u-cursor-pointer mb-2"
                style="border: 2px dashed #3949AB !important"
                v-ripple="{ class: 'indigo--text text--darken-1' }"
                @click="local_user_can('store') ? $emit('create-tile', $route.params.view_id) : {}"
            >
                <div class="text-center">
                    <a-icon size="30" color="indigo darken-1" class="d-block mb-2">add_circle</a-icon>
                    <h3 class="md-subtitle-1 font-weight-medium indigo--text text--darken-1">Add New Tile</h3>
                </div>
            </a-sheet>
        </template>
        <div style="max-width: 1160px;" class="c-insights-charts">
            <draggable
                v-model="tile_list"
                v-bind="dragOptions"
                draggable=".js-draggable-file-list-item"
                :style="local_grid_parent"
            >
                <p-tile-wrapper
                    v-for="(item, index) in tile_list"
                    :ref="'tile-' + item.id"
                    :key="index"
                    :item="item"
                    :isRearrange="enableRearrange"
                    :isWiggle="show_wiggle"
                    :isBoardExport="isBoardExport"
                    :is-exported="export_id === item.id"
                    :is-hightlighted="hightlightId === item.id"
                    :class="{ 'u-overflow-hidden': hightlightId === item.id }"
                >
                    <template #toolbar="{ isHover }">
                        <p-tile-toolbar
                            class="grey lighten-5 c-insight-tile-toolbar"
                            style="border-radius: 8px 8px 0px 0px;"
                            :item="item"
                            :isHover="isHover"
                            :isRearrange="enableRearrange"
                            @hard-refresh="local_enable_refresh"
                            @clone="(tile_item, clear_loading) => $emit('clone', tile_item, clear_loading, index)"
                            @edit="$emit('edit', item)"
                            @delete="$emit('delete', item.id)"
                            @export="local_export(item.id, item.title, $event)"
                        ></p-tile-toolbar>
                    </template>
                    <template #content>
                        <component
                            v-if="item.source && item.source.component"
                            :is="`P${item.source.component}`"
                            :isRearrange="enableRearrange"
                            :item="item"
                            @configure="$emit('edit', item)"
                            class="u-flex-center-y"
                        ></component>
                    </template>
                </p-tile-wrapper>
            </draggable>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PTileWrapper from './Partials/PartialTileWrapper.vue'
import PTileToolbar from './Partials/PartialTileToolbar.vue'
import PBarMultipleVerticalChart from './Partials/PartialBarMultipleVerticalChart.vue'
import PBarVerticalChart from './Partials/PartialBarVerticalChart.vue'
import PDonutChart from './Partials/PartialDonutChart.vue'
import PPieChart from './Partials/PartialPieChart.vue'
import PRadialBarChart from './Partials/PartialRadialBarChart.vue'
import PBarStackedVerticalChart from './Partials/PartialBarStackedVerticalChart.vue'
import PLineMultipleVerticalChart from './Partials/PartialLineMultipleVerticalChart.vue'
import PLineChart from './Partials/PartialLineChart.vue'
import PNumberChart from './Partials/PartialNumberChart.vue'
import { saveAsPng } from 'save-html-as-image'

export default {
    name: 'DashboardInsightsType',

    props: {
        currentView: {
            type: Object,
        },
        hightlightId: {
            type: String,
            default: ''
        },
        parentLoading: {
            type: Boolean,
            default: false
        },
        enableRearrange: {
            type: Boolean,
            default: false
        },
        discardRearrange: {
            type: Boolean,
            default: false
        },
        isBoardExport: {
            type: Boolean,
            default: false
        }
    },

    components: {
        PTileWrapper,
        PTileToolbar,
        PDonutChart,
        PPieChart,
        PLineChart,
        PNumberChart,
        PRadialBarChart,
        PBarVerticalChart,
        PBarStackedVerticalChart,
        PBarMultipleVerticalChart,
        PLineMultipleVerticalChart
    },

    data () {
        return {
            list: Array(9).fill(null),
            enable_rearrange: false,
            show_wiggle: false,
            loading_types: {},
            dialog_tile_create: false,
            cloned_grid_list: [],
            export_id: null,
        }
    },

    watch: {
        enableRearrange (val) {
            this.local_wiggle(val)
        },

        discardRearrange (val) {
            if (val) {
                this.tile_list = [...this.cloned_grid_list]
                this.tile_list_set(this.tile_list)
            }
        },

        '$route.view_id': {
            handler (val) {
                this.local_index()
            },
            deep: true
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        tile_list: {
            get () {
                return this.$store.state.Tile.list
            },
            set (list) {
                this.tile_list_set(list)
            }
        },

        local_grid_parent () {
            return {
                display: 'grid',
                gridTemplateRows: 'masonry',
                gridTemplateColumns: 'repeat(4, 1fr)',
                transition: '0.4s all',
                gap: '24px',
            }
        },

        dragOptions () {
            return {
                animation: 200,
                group: 'chart',
                handle: '.js-drag-handle',
                disabled: !this.local_user_can('update'),
                ghostClass: 'ghost',
            }
        },

        local_board_id () {
            return this.$route.params.view_id
        },

        local_board_length () {
            return this.board_list && this.board_list.length
        },

        ...mapState('Board', {
            board_list: 'list',
        }),

        ...mapState('Tile', {
            tile_item: 'item',
            tile_response: 'response',
        }),

        ...mapState('Preference', {
            preference_user_types: 'user_types',
        }),
    },

    methods: {
        async local_index () {
            if (!this.local_user_can('index')) {
                return this.$router.replace({name: 'errors-unauthorized'})
            }

            if (!this.local_board_length && this.$route.name !== 'dashboard-insights') return this.$router.push({ name: 'dashboard-insights' })
            if (this.local_board_length) this.local_back_to_board_view()

            this.local_set_loading('tile_index', true)
            await this.tile_clear()
            await this.tile_index({ 'filter[board_id]': this.local_board_id, include: 'source,tileFilters,tileOptions' })
            this.local_set_loading('tile_index', false)
        },

        local_back_to_board_view () {
            const current_board_pref = this.preference_user_types.find(item => item.key === 'user.insights_dashboard.board_view')
            const preference = current_board_pref ? _.find(this.board_list, { id: current_board_pref.value }) : this.board_list[0]
            if (this.$route.name === 'dashboard-insights') return this.$router.push({ name: 'dashboard-insights-type', params: { view_id: preference?.id ?? this.board_list[0].id } })
        },

        async local_export (id, title, clear_loading) {
            if (id) {
                this.export_id = id;
                const ref = 'tile-' + id
                const el = this.$refs[ref][0].$el

                await saveAsPng(el, { filename: title, printDate: true }, {
                    width: el.clientWidth + 8,
                    height: el.clientHeight + 8,
                    style: {
                        margin: '0',
                        padding: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden'
                    }
                })

                this.export_id = null;

                clear_loading()
            }
        },

        local_wiggle (value) {
            if (value) this.cloned_grid_list = [...this.tile_list]
            this.enable_rearrange = value
            this.show_wiggle = value
        },

        async local_enable_refresh (tile) {
            await this.tile_update_tile_status({ id: tile.id, status: 'force-loading'})
            await this.tile_show_stats({ id: tile.id, params: { force: true } })
            await this.tile_show({ id: tile.id, mode: 'show-modify', params: { include: 'tile_options,tile_filters,source' } })
            if (tile.statistics) this.$set(tile.statistics, 'timestamp', moment())
        },

        local_set_loading (type, value) {
            this.$set(this.loading_types, type, value)
        },

        local_check_loading (type) {
            return !!this.loading_types[type]
        },

        local_user_can (permission) {
            return this.$can(`insights_dashboard.${permission}`)
        },

        ...mapActions('Tile', {
            tile_index: 'index',
            tile_show: 'show',
            tile_show_stats: 'show_stats',
            tile_list_set: 'list_set',
            tile_clear: 'clear',
            tile_update_tile_status: 'update_tile_status'
        }),
    }
}
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>

<template>
    <a-menu bottom offset-y :close-on-content-click="false" max-width="264" min-width="264">
        <template v-slot:activator="{ on }">
            <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="264" v-on="on">
                <div class="md-body-2 grey--text text--darken-2 mx-1 u-flex align-center">
                    <a-icon size="20" :color="!hasFilter ? 'blue' : 'grey darken-1'"  class="mr-1">filter_list</a-icon>
                    <span class="text-capitalize">{{type}}:</span>
                    <span class="ml-2 grey--text text--darken-3 font-weight-medium">
                        <template v-if="selectedList && selectedList.length === 1">
                            <span :title="localGetTitle(selectedList[0])">{{ localGetTitle(selectedList[0]) | truncateText(15) }}</span>
                        </template>
                        <template v-else-if="selectedList && selectedList.length > 1">{{ selectedList.length + ' Selected' }}</template>
                        <template v-else>
                            <span class="text-capitalize">All {{type}}</span>
                        </template>
                    </span>
                </div>
                <a-spacer></a-spacer>
                <a-progress-circular v-if="filterType === 'triggers'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                <a-icon v-else size="20" class="ma-0">arrow_drop_{{ isDropdown ? 'up' : 'down' }}</a-icon>
            </a-sheet>
        </template>
        <a-list class="u-list-condensed u-overflow-y py-2">
            <a-list-item v-for="(item, index) in list" class="pr-5" :key="index" @click="localFilter(item.id)">
                <a-list-item-icon class="ma-0 pa-0 u-flex justify-center">
                    <a-checkbox dense hide-details color="blue darken-2" class="ma-0 pa-0" :value="localGetIsSelected(item.id)"></a-checkbox>
                </a-list-item-icon>
                <a-list-item-title>
                    <span class="md-body-2 grey--text text--darken-3 ml-1" :title="item[triggers]">{{ item[triggers] | truncateText(60) }}</span>
                </a-list-item-title>
            </a-list-item>
            <a-list-item v-if="(list && !list.length)">
                <a-list-item-title>
                    <span class="md-body-2 grey--text text--darken-3 ml-1">No {{type}} Found</span>
                </a-list-item-title>
            </a-list-item>
        </a-list>
        <a-divider></a-divider>
        <a-list class="u-list-condensed u-overflow-y">
            <a-list-item class="pr-5" @click="localFilter(null)">
                <a-list-item-title>
                    <span class="md-body-2 grey--text text--darken-3 ml-1">All {{type}} </span>
                </a-list-item-title>
            </a-list-item>
        </a-list>
    </a-menu>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
        },
        list: {
            type: Array,
            default: () => [],
        },
        selectedList: {
            type: Array,
            default: () => [],
        },
        filterType:{
            type: String,
            default: null,
        },
        isDropdown:{
            type: Boolean,
        },
        hasFilter:{
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            triggers: 'title'   
        }
    },

    methods: {
        localGetTitle (id) {
            const trigger = this.list.find(item => item.id === id)
            return trigger ? trigger[this.triggers] : ''
        },

        localGetIsSelected (id) {
            return this.selectedList.includes(id)
        },

        localFilter (id) {
            this.$emit('filter', id)
        }
    },
}
</script>

const api_endpoint = '/insights';

export default {
    namespaced: true,
    state: {
        insights: {},
        member_item:[],
        meta: {},
        mini_stats: [],
        response: [],
        defaults: {
            insights: {},
            member_item:[],
            meta: {},
            mini_stats: [],
            response: [],
        },
    },
    mutations: {
        UPDATE_INSIGHTS(state, item) {
            state.insights = { ...state.insights, ...item }
        },
        MEMBER_ITEM(state, member_item) {
            state.member_item = member_item;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        UPDATE_MINI_STATS(state, payload) {
            state.mini_stats = payload;
        },
        CLEAR_INSIGHTS(state, item) {
            state.insights = item;
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        lifetime(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/lifetime', { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_INSIGHTS', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        lifetime_stages(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/lifetime/stages', { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_INSIGHTS', { active_stages: response.data.data.stages });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        stages(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/stages', { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_INSIGHTS', { stages: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        project_result(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/projects/result/counts', { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_INSIGHTS', { project_result: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        projects(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/projects', { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_INSIGHTS', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        projects_competitors(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/projects/competitors', { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_INSIGHTS', { competitors: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        account_exe(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/projects/account-executives', { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_INSIGHTS', { account_executives: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        sales_engg(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/projects/sales-engineers', { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_INSIGHTS', { sales_engineers: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        dashboard(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/projects/status-updates/count', { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_MINI_STATS', response.data.data.count);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        stage_poc_count(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/status/projects/counts', { params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_INSIGHTS', { queue_count: response.data.data.count });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        enabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/enabled')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        disabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/disabled')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        role_project_count(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/projects/roles/' + payload.role_id, { params: { ...payload.params } })
                .then((response) => {
                    context.commit('MEMBER_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, context.state.item)
                .then((response) => {
                    context.commit('ADD_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.put(api_endpoint + '/' + context.state.item.id, context.state.item)
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        enable(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/enable', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        disable(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/disable', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('CLEAR_INSIGHTS', _.cloneDeep(context.state.defaults.insights));
                resolve('Cleared');
            });
        },
        member_clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('MEMBER_ITEM', _.cloneDeep(context.state.defaults.member_item));
                resolve('Cleared');
            });
        },
    },
}

<template>
    <div class="white u-rounded-corners-lg">
        <h2 class="md-heading-6">Are you sure to cancel the downgrade?</h2>
        <div class="mt-5 u-flex-center-y">
            <a-btn
                class="indigo darken-2"
                @click="$emit('action')"
                :loading="isLoading"
                dark depressed
            >
                <span class="md-subtitle-1">Yes</span>
            </a-btn>
            <a-btn
                color="grey lighten-5"
                class="ma-0 ml-3"
                @click="$emit('close')"
                :disabled="isLoading"
                depressed
            >
                <span class="md-subtitle-1 grey--text text--darken-2">No</span>
            </a-btn>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    }
}
</script>

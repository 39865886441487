<template>
    <div class="">
        <div class="u-flex align-center">
            <h2 :class="[titleClass]">{{ title }}</h2>
            <a-spacer></a-spacer>
            <div class="u-flex align-center">
                <div v-if="canUpdate" class="mr-1">
                    <p class="u-leading-base md-caption grey--text mb-0 d-flex align-center">
                        <span class="mr-2 font-italic">Select text for formating options</span>
                        <a-icon size="14" color="grey" class="ml-1">format_bold</a-icon>
                        <a-icon size="14" color="grey" class="ml-1">format_italic</a-icon>
                        <!-- <a-icon size="14" color="grey" class="ml-1">format_underlined</a-icon> -->
                    </p>
                </div>
                <a-btn v-if="!hideFoldToggle" small icon text color="grey lighten-1" class="ma-0" @click="$emit('expand', !expanded)">
                    <a-icon size="16" v-if="expanded">unfold_less</a-icon>
                    <a-icon size="16" v-else>unfold_more</a-icon>
                </a-btn>
            </div>
        </div>
        <a-divider></a-divider>
        <slot name="content"></slot>
    </div>
</template>

<script>
export default {
    props: {
        canUpdate: {
            type: Boolean,
        },
        expanded: {
            type: Boolean,
        },
        titleClass: {
            type: String,
            default: 'secondary--text text-h6'
        },
        hideFoldToggle: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Description'
        },
    }
}
</script>

<style>

</style>

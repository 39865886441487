<template>
    <div
        class="c-upgrade-gradient pb-8 pt-12 white u-rounded-corners-lg u-relative"
        style="background-image: linear-gradient(#E8EAF6 20%, #fff 70%)"
    >
        <a-btn small depressed icon class="u-absolute u-cursor-pointer" @click="$emit('close')" style="top: 12px; right: 12px;">
            <a-icon size="18" color="secondary" class="u-opacity-70">cancel</a-icon>
        </a-btn>

        <div
            class="white pa-4 px-8 u-rounded-corners-lg mx-auto"
            style="max-width: 380px; box-shadow: #e8eaf6 0px -92px 8px;"
        >
            <a-sheet width="180" height="180" class="mx-auto pt-4">
                <img
                    src="../../../assets/images/downgrade-image.svg"
                    alt="Downgrade Plan"
                    style="object-fit: contain; width: 100%; height: 100%;"
                >
            </a-sheet>

            <div class="mt-8">
                <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-2 text-center">
                    Are you sure you want to downgrade to <br> <strong class="indigo--text text--darken-2">Starter</strong> plan?
                </h2>
                <h5 class="grey--text md-body-2 text-center mt-3">You can always upgrade later.</h5>
            </div>
            <div class="mt-8">
                <a-btn @click="localDowngrade()" class="grey darken-3 u-wfull" depressed dark>
                    <span class="md-subtitle-2 white--text">Downgrade to Starter</span>
                </a-btn>
                <a-btn @click="$emit('close')" class="u-wfull mt-2 grey lighten-2" depressed>
                    <span class="md-subtitle-2 grey--text text--darken-2">Cancel</span>
                </a-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        localDowngrade () {
            const url = `${process.env.VUE_APP_CORE_API_URL}/${window.location.hostname.split('.')[0]}/internal/subscription-plans/1?redirect_url=${window.location.href}`
            window.location.href = url
        },
    }
}
</script>

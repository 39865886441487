<template>
    <div class="c-no-propagate" v-on="$listeners">
        <a-sheet min-width="118" style="border-radius: 4px;" v-if="item">
            <a-menu v-model="model_status" class="left u-wfull" max-width="280" offset-y dense :disabled="!canUpdate">
                <template v-slot:activator="{ on }">
                    <div v-on.stop="on">
                        <a-btn v-if="outline" outlined small :color="static_status_classes[item.status ? item.status.status : 'Open']" min-width="164" max-width="132" class="c-no-propagate font-weight-medium u-rounded-corners d-inline-flex align-center">
                            <div class="text-truncate" style="maxWidth: 120px;" :title="(item && item.status) ? item.status.value : ''">{{ (item && item.status) ? item.status.value : '' }}</div>
                            <a-spacer></a-spacer>
                            <a-icon v-if="canUpdate" size="18" :color="static_status_classes[item.status ? item.status.status : 'Open']">arrow_drop_{{ model_status ? 'up' : 'down'}}</a-icon>
                        </a-btn>
                        <template v-else-if="onlyAsId">
                            <div :class="['c-no-propagate u-flex align-center u-wfull px-2 py-1 u-cursor-pointer u-no-select', local_get_status_color(status_classes[statusItem && statusItem.status ? statusItem.status : 'Open'], 'bg'), local_get_status_color(status_classes[statusItem && statusItem.status ? statusItem.status : 'Open'], 'text')]" style="min-width: 140px; padding-top: 0px; padding-bottom: 0px; border-radius: 4px; border: 1px solid rgba(0,0,0,0.08)">
                                <div class="u-wfull ma-0 md-body-2 font-weight-medium text-truncate" :title="(statusItem && statusItem.value) ? statusItem.value : ''" :class="inputClass" style="border-radius: 4px; maxWidth: 105px;">
                                    {{ statusItem && statusItem.value ? statusItem.value : '' }}
                                </div>
                                <a-spacer></a-spacer>
                                <a-icon size="15" v-if="canUpdate" :color="local_get_status_color(status_classes[statusItem && statusItem.status ? statusItem.status : 'Open'], 'icon')">keyboard_arrow_{{ model_status ? 'up' : 'down'}}</a-icon>
                            </div>
                        </template>
                        <template v-else>
                            <div :class="['c-no-propagate u-flex align-center u-wfull px-2 u-cursor-pointer u-no-select', local_get_status_color(status_classes[item && item.status ? item.status.status : 'Open'], 'bg'), local_get_status_color(status_classes[item && item.status ? item.status.status : 'Open'], 'text')]" style="min-width: 140px; padding-top: 0px; padding-bottom: 0px; border-radius: 4px; border: 1px solid rgba(0,0,0,0.08)">
                                <div class="u-wfull ma-0 md-body-2 font-weight-medium text-truncate" :title="(item && item.status) ? item.status.value : ''" :class="inputClass" style="border-radius: 4px; maxWidth: 105px;">
                                    {{ item && item.status ? item.status.value : '' }}
                                </div>
                                <a-spacer></a-spacer>
                                <a-icon size="15" v-if="canUpdate" :color="local_get_status_color(status_classes[item && item.status ? item.status.status : 'Open'], 'icon')">keyboard_arrow_{{ model_status ? 'up' : 'down'}}</a-icon>
                            </div>
                        </template>
                    </div>
                </template>
                <a-list class="u-list-condensed">
                    <a-list-item v-for="status in statusList" :key="status.id" :title="status.value" @click="local_set_item_status(item.id, status.id, status)" :disabled="item.status && item.status.id == status.id">
                        <a-list-item-title class="md-body-2">{{ status.value }}</a-list-item-title>
                    </a-list-item>
                </a-list>
            </a-menu>
        </a-sheet>

        <a-dialog v-model="dialog_comment_form" max-width="600" persistent>
            <a-sheet>
                <h3 class="c-title-color text-h6 font-weight-medium secondary--text px-6 py-5">{{ module === 'Testcase' ? 'Test Case' : 'Success Criteria' }} Status Update</h3>
                <a-divider></a-divider>
                <div class="pa-6">
                    <h3 class="md-subtitle-1 font-weight-medium">{{ item.title }}</h3>
                    <div class="u-flex-center-y mt-3 mb-4">
                        <!-- <a-sheet class="md-subtitle-1" :class="[static_status_text_color[item.status ? item.status.status : 'Open']]">{{ item.status ? item.status.value : '' }}</a-sheet> -->
                        <a-sheet class="md-subtitle-1" :class="[static_status_text_color[statusItem && statusItem.status ? statusItem.status : 'Open']]">{{ statusItem && statusItem.status ? statusItem.value : '' }}</a-sheet>
                        <a-icon size="24" color="grey lighten-1" class="mx-2">arrow_right_alt</a-icon>
                        <a-sheet class="md-subtitle-1" :class="[static_status_text_color[local_status ? local_status.status : 'Open']]">{{ local_status ? local_status.value : '' }}</a-sheet>
                    </div>
                    <a-sheet>
                        <a-textarea
                            v-model="local_comment_text"
                            placeholder="Post your comment..."
                            rows="3"
                            background-color="grey lighten-5"
                            class="u-border"
                            solo flat hide-details no-resize autofocus
                        ></a-textarea>
                        <div class="u-flex-center-y justify-space-between">
                            <g-field-info :error="$response(local_comment_response, 'content')" :has-error="$response(local_comment_response, 'content')" show-icon-on-error></g-field-info>
                            <span class="md-body-2 body--text mt-2">(Optional)</span>
                        </div>
                    </a-sheet>
                    <div class="mt-2">
                        <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_comment_store(local_comment_text, local_commentable_type, local_select_id)" :loading="loading" :disabled="loading">Save Comment</a-btn>
                        <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_comment_close()" :disabled="loading">Cancel</a-btn>
                    </div>
                </div>
            </a-sheet>
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        canUpdate: {
            type: Boolean
        },
        updateListItem: {
            type: Boolean,
            default: false
        },
        outline: {
            type: Boolean,
            default: false
        },
        onlyAsId: {
            type: Boolean,
            default: false
        },
        statusItem: {
            type: Object,
        },
        parentList: {
            type: Array
        },
        statusList: {
            type: Array
        },
        item: {
            type: Object
        },
        module: {
            type: String,
            default: 'Testcase'
        },
        inputClass: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            static_status_classes: {
                'Open': 'grey darken-1',
                'Passed': 'green darken-1',
                'Failed': 'deep-orange darken-2',
                'Invalid': 'grey darken-4',
                'Closed': 'green darken-1',
            },
            static_status_text_color: {
                'Open': 'grey--text text--darken-1',
                'Passed': 'green--text text--darken-1',
                'Failed': 'red--text text--darken-1',
                'Invalid': 'grey--text text--darken-4',
                'Closed': 'green--text text--darken-1',
            },
            model_status: null,
            local_select_id: null,
            local_status: null,
            local_status_id: null,
            local_commentable_type: null,
            local_comment_text: null,
            dialog_comment_form: null,
            comment_statuses: ['Failed', 'Invalid', 'Passed'],
        }
    },

    computed: {
        module_status () {
            return this.item && this.item.status && this.item.status.status
        },

        status_classes () {
            return {
                Open: { bg: 'grey lighten-3', text: 'grey--text text--darken-3', icon: 'grey darken-3' },
                Passed: { bg: 'green darken-1', text: 'white--text', icon: 'white' },
                Failed: { bg: 'deep-orange darken-', text: 'white--text', icon: 'white' },
                // Invalid: { bg: 'grey darken-4', text: 'white--text', icon: 'white' },
                Invalid: { bg: 'grey darken-1', text: 'white--text', icon: 'white' },
                Closed: { bg: 'green darken-1', text: 'white--text', icon: 'white' },
            }
        },

        local_is_external () {
            return this.$route.meta.view === 'external' || this.user_self && this.user_self.scope === 'external'
        },

        local_comment_item () {
            return this.local_is_external ? this.comment_external_item : this.comment_item
        },

        local_comment_response () {
            return this.local_is_external ? this.comment_external_response : this.comment_response
        },

        ...mapState('Comment', {
            comment_item: 'item',
            comment_response: 'response',
        }),

        ...mapState('CommentExternal', {
            comment_external_item: 'item',
            comment_external_response: 'response',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async local_set_item_status (id, select_value_id, select_status) {
            if (this.item.status_id === select_value_id) return
            this.local_select_id = id
            this.local_status_id = select_value_id
            this.local_status = select_status
            this.local_commentable_type = this.module

            if (this.comment_statuses.includes(select_status.status)) {
                await this.local_comment_clear()
                return this.dialog_comment_form = true
            }
            await this.local_change_status(select_value_id, select_status)
        },

        async local_change_status (select_value_id, select_status) {
            await this.local_update_status()
            Object.assign(this.item, { status: select_status, status_id: select_value_id })
            this.local_update_list_item()
            if (this.local_comment_text) {
                this.local_comment_text = null
                this.local_comment_clear()
            }
            if (this.dialog_comment_form) this.local_comment_close()
            this.$notify('success', 'Updated status successfully!')
        },

        async local_comment_clear () {
            this.comment_clear_item()
            this.comment_external_clear_item()
        },

        async local_update_status () {
            const comment_id = this.local_comment_text ? this.comment_item.id : null
            const params = { id: this.local_select_id, status_id: this.local_status_id }
            if (this.module === 'Testcase') return this.local_update_testcase_status(comment_id, params)
            this.local_update_success_scriteria_status(comment_id, params)
        },

        async local_update_testcase_status (comment_id, params) {
            if (comment_id) {
                params.comment_id = comment_id
                await this.local_update_tc_status(params)
            } else await this.local_update_tc_status(params)
            this.$emit('update', this.item)
        },

        async local_update_tc_status (params) {
            if (!this.local_is_external) return await this.testcase_status({ ...params })
            await this.testcase_external_status({ ...params })
        },

        async local_update_success_scriteria_status (comment_id, params) {
            if (comment_id) {
                params.comment_id = comment_id
                await this.local_update_sc_status(params)
            } else await this.local_update_sc_status(params)
            this.$emit('update', this.item)
        },

        async local_update_sc_status (params) {
            if (!this.local_is_external) return await this.success_criterion_status({ ...params })
            await this.success_criterion_external_status({ ...params })
        },

        async local_comment_store (comment, type, id) {
            if (comment && comment.trim()) {
                this.local_set_comment_vars(comment, type, id)
                await this.local_add_comment(this.comment_item)
                if (this.local_comment_response.status !== 'success') return
                this.$emit('statusCommentStore', { item_id: id, comment: this.comment_item, module: this.module, usecase: this.item })
            }
            this.local_change_status(this.local_status_id, this.local_status)
        },

        async local_set_comment_vars (comment, type, id) {
            this.comment_item.commentable_type = type
            this.comment_item.visibility = this.local_is_external ? 'external' : 'internal'
            this.comment_item.commentable_id = id
            this.comment_item.content = comment
            this.comment_item.created_by = this.user_self
            this.comment_item.created_by_id = this.user_self.id
            this.comment_item.created_at = new Date()
        },

         async local_add_comment (comment) {
            if (this.local_is_external) {
                await this.comment_external_store(comment)
                return
            }
            await this.comment_store(comment)
        },

        local_update_list_item () {
            const item = this.parentList.find(item => item.id === this.item.id)
            Object.assign(item, { status: this.item.status, status_id: this.item.status_id })
            if (this.module === 'Testcase') this.testcase_tc_upsert(item)
            else this.success_criterion_sc_upsert(item)
        },

        async local_comment_close() {
            this.local_comment_text = null
            this.dialog_comment_form = false
        },

        local_get_status_color (item, field) {
            return item && item[field]
        },

        ...mapActions('Testcase', {
            testcase_status: 'status',
            testcase_tc_upsert: 'tc_upsert',
        }),

        ...mapActions('SuccessCriterion', {
            success_criterion_status: 'status',
            success_criterion_sc_upsert: 'sc_upsert',
        }),

        ...mapActions('Comment', {
            comment_store: 'store',
            comment_clear_item: 'clear_item',
        }),

        ...mapActions('TestcaseExternal', {
            testcase_external_status: 'status',
        }),

        ...mapActions('SuccessCriterionExternal', {
            success_criterion_external_status: 'status',
        }),

        ...mapActions('CommentExternal', {
            comment_external_store: 'store',
            comment_external_clear_item: 'clear_item',
        }),
    }
}
</script>

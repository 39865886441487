<template>
    <div>
        <div v-bind="$attrs" class="my-4 mt-3" v-if="dataItem && dataItem.territories">
            <template v-for="(territory, index) in dataFilteredTerrritories">
                <slot name="item" :item="territory" :destroy="methodAssociationDestroy">
                    <g-tags :tag="territory" :can-update="modelUpdate" :key="territory ? territory.id : index" @destroy="methodAssociationDestroy"></g-tags>
                </slot>
            </template>
        </div>
        <a-autocomplete
            v-model="dataTerritoryInput"
            :items="list"
            item-text="name"
            item-value="id"
            :placeholder="`Select${canUpdate ? ' or create ' : ' '}territory`"
            background-color="white"
            :search-input.sync="dataTerritorySearch"
            class="u-border mt-3"
            :menu-props="{ closeOnContentClick: true, closeOnClick: true }"
            @click:clear="dataTerritoryInput = null"
            :disabled="dataLocalLoading"
            :loading="dataLocalLoading"
            ref="refTerritoryInput"
            v-bind="$attrs"
            @input="methodSelectTag('select')"
            @keyup.enter="$can('territories.store') ? methodTerritoryStore(dataTerritorySearch) : methodSelectTag('select')"
            autofocus solo flat hide-details hide-selected clearable
        >
            <template v-slot:no-data>
                <div v-if="$can('territories.store') && dataTerritorySearch" class="px-4 py-2 u-cursor-pointer" v-ripple @click="$can('territories.store') ? methodTerritoryStore(dataTerritorySearch) : ''">
                    <span class="md-body-2 blue--text text--darken-1" v-if="dataTerritorySearch != null && dataTerritorySearch != ''">Create <strong>"{{ dataTerritorySearch }}"</strong></span>
                </div>
                <div v-else class="md-subtitle-1 px-4 py-2 grey--text text--darken-1">No Territories Found</div>
            </template>
            <template v-slot:selection="{ item }">
                <g-tags :tag="item" hide-clear-icon></g-tags>
            </template>
            <template v-slot:append>
                <a-icon size="16" color="grey darken-1" @click="dataTerritoryInput = null">clear</a-icon>
            </template>
            <template v-slot:item="{ item }">
                <g-tags :tag="item" hide-clear-icon></g-tags>
            </template>
        </a-autocomplete>
        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="territory_response.server && territory_response.server.errors && territory_response.server.errors.name">
            <a-icon size="16" color="red darken-2">info</a-icon>
            {{ territory_response.server.errors.name[0] }}
        </span>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    inheritAttrs: false,
    props: {
        title: {
            type: String,
            default: 'Add new territory'
        },
        item: {
            type: Object | String,
            required: true,
        },
        list: {
            type: Array,
            default: () => [],
            required: true
        },
        module: {
            type: String,
        },
        source: {
            type: String,
        },
        type: {
            type: String,
        },
        canUpdate: {
            type: Boolean
        },
        modelUpdate: {
            type: Boolean,
            default: true
        },
        canCreate: {
            type: Boolean,
            default: true
        },
        filterType: {
            type: Boolean,
            default: false
        },
        formClose: {
            type: Boolean,
        },
    },

    data () {
        return {
            dataTerritoryInput: null,
            dataTerritorySearch: null,
            dataAddedTerritoryId: null,
            dataLocalLoading: false,
            dataItem: this.item
        }
    },

    watch: {
        formClose (val) {
            if (val === false) this.dataItem = {}
            if (val === true) this.dataItem = this.item
        },
    },

    computed: {
        dataFilteredTerrritories () {
            return this.dataItem.territories
            // if (this.filterType && this.type) {
            //     const type = this.type.includes('project_') ? this.type.split('project_')[1] : this.type
            //     return this.dataItem.territories.filter(tag => tag.type === type)
            // }
            // return this.dataItem.territories && this.dataItem.territories.length ? this.dataItem.territories : []
        },

        ...mapState('Association', {
            association_item: 'item',
            association_response: 'response',
        }),

        ...mapState('Territory', {
            territory_item: 'item',
            territory_response: 'response',
        }),
    },

    methods: {
        async methodTerritoryStore (tag_name) {
            if (!tag_name) return
            this.methodLoading(true)
            await this.territory_clear_item()

            this.methodSetTagVars(tag_name)
            await this.territory_store({ ...this.territory_item })

            this.dataAddedTerritoryId = this.territory_item.id
            this.methodAddTag(this.territory_item, 'new')

            this.dataTerritorySearch = null
            this.$refs.refTerritoryInput.ref.blur()
            this.methodLoading(false)

            await this.methodAssociationStore(this.dataAddedTerritoryId)
        },

        methodSetTagVars (name) {
            const type = this.type.includes('project_') ? this.type.split('project_')[1] : this.type
            this.territory_item.name = name
            this.territory_item.type = type
            this.territory_item.color = this.$randomColor()
        },

        methodAddTag (territory, type) {
            if (!type) {
                const tagItem = this.list.find(item => item.id === territory)
                this.dataItem.territories.push(_.cloneDeep(tagItem))
            } else this.dataItem.territories.push(_.cloneDeep(territory))
            this.$emit('after-store', true)
        },

        async methodAssociationStore (target_id) {
            const data = { source_type: this.source, source_id: this.dataItem.id , target_type: 'Territory', target_id, type: this.type  }
            await this.association_store(data)
            if (this.association_response.status !== 'success') return
            this.methodAddAssociationId(this.association_item.id, target_id)
            this.dataTerritoryInput = null
            this.dataAddedTerritoryId = null
            this.territory_clear_item()
        },

        methodAddAssociationId (associateId, territoryId) {
            const index = this.dataItem.territories.findIndex(item => item.id === territoryId)
            if (index !== -1) {
                this.$set(this.dataItem.territories, index, { ...this.dataItem.territories[index], ...{ association: { id: associateId } }})
            }
        },

        async methodSelectTag (arg) {
            if (!this.dataTerritoryInput) return this.dataTerritorySearch = null
            this.methodLoading(true)
            if (arg && arg === 'select') await this.methodAddTag(this.dataTerritoryInput)
            this.methodLoading(false)
            await this.methodAssociationStore(this.dataAddedTerritoryId ?? this.dataTerritoryInput)
        },

        async methodAssociationDestroy (data) {
            this.methodRemoveTag(data)
            await this.association_destroy(data.association)
            if (this.association_response.status !== 'success') return
        },

         async methodAddTag (territory, type) {
            if (!type) {
                const tagItem = this.list.find(item => item.id === territory)
                return this.dataItem.territories.push(_.cloneDeep(tagItem))
            }
            this.dataItem.territories.push(_.cloneDeep(territory))
        },

        async methodRemoveTag (territory) {
            const index = this.dataItem.territories.findIndex(item => item.id === territory.id)
            if (index !== -1) this.dataItem.territories.splice(index, 1)
        },

        methodLoading (val) {
            this.dataLocalLoading = val
        },

        ...mapActions('Association', {
            association_store: 'store',
            association_destroy: 'destroy',
        }),

        ...mapActions('Territory', {
            territory_store: 'store',
            territory_clear_item: 'clear_item',
        }),
    }
}
</script>

<template>
    <div class="u-hfull">
        <!-- Ticket Detail -->
        <a-container
            @dragenter="checkTicketPermission('update') && !localAttachmentsUpdating ? localHandleDragEnter() : ''"
            @drop="localHandleDrop"
            class="u-relative u-hfull"
            container--fluid grid-list-xl pt-0 pb-0 px-6
        >
            <SDragOverlay
                v-if="filesDraggedOver"
                pageType="external"
                @leave="localHandleLeave()"
                @enter="hoveredOnInfo=false"
                @enterInfo="hoveredOnInfo=true"
            >
            </SDragOverlay>
            <a-layout
                align-center
            >
                <a-flex xs12 pb-0 class="u-hfull">
                    <a-container container--fluid grid-list-xl pt-10 px-8 class="u-hfull">
                        <a-layout v-if="pageLoading">
                            <a-flex xs12>
                                <a-responsive class="my-4">
                                    <h3 class="text-h5 font-weight-medium text-center grey--text text--darken-1 mb-1 text-capitalize">Loading..</h3>
                                </a-responsive>
                            </a-flex>
                        </a-layout>
                        <a-layout
                            v-if="!pageLoading"
                        >
                            <a-flex xl9 xs9>
                                <router-link :to="{name: 'external-projects-tickets'}">
                                    <div class="u-overflow-hidden u-cursor-pointer d-inline-flex mb-2">
                                        <a-icon size="16" class="ma-0" color="grey darken-2">arrow_back</a-icon>
                                        <a class="grey--text text--darken-2 pl-1">Back to tickets</a>
                                    </div>
                                </router-link>
                                <div class="u-flex align-center">
                                    <a-text-field
                                        v-model="local_ticket_item.title"
                                        ref="ref_ticket_title"
                                        class="text-h5 font-weight-medium grey--text text--darken-3 u-wfull u-rounded-corners white u-elevation-custom-1"
                                        solo flat hide-details autofocus
                                        v-if="checkTicketPermission('update')"
                                        @input="localTicketValidationCheck()"
                                    ></a-text-field>
                                    <a-sheet v-else class="text-h5 font-weight-medium grey--text py-2 px-3 u-elevation-custom-1 u-rounded-corners text--darken-3 u-wfull">{{ local_ticket_item.title }}</a-sheet>
                                </div>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.title">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ ticket_response.server.errors.title[0] }}
                                </span>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="localGetTicketErrorMessage('title')">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ localGetTicketErrorMessage('title') }}
                                </span>
                                <div class="mt-4">
                                    <a-card class="pa-0 u-elevation-custom-1 u-rounded-corners">
                                        <div class="u-flex align-center px-6 py-3">
                                            <div class="u-flex align-center">
                                                <h3 class="c-letter-space text-uppercase md-caption grey--text text--darken-1 font-weight-medium">Description</h3>
                                            </div>
                                            <a-spacer></a-spacer>
                                            <div class="u-flex align-center" v-if="checkTicketPermission('update')">
                                                <p class="md-caption grey--text mb-0 d-flex align-center">
                                                    <em class="mr-2">Select text for formating options</em>
                                                    <a-icon size="14" color="grey" class="ml-1">format_bold</a-icon>
                                                    <a-icon size="14" color="grey" class="ml-1">format_italic</a-icon>
                                                </p>
                                            </div>
                                        </div>
                                        <a-divider class="u-divider"></a-divider>
                                        <div class="px-6 pt-3 pb-1">
                                            <p class="md-body-2 grey--text text--darken-1" v-if="checkTicketPermission('update') && (!local_ticket_item.description || local_ticket_item.description && local_ticket_item.description.replace(/<[^>]*>?/gm, '') === null || local_ticket_item.description && local_ticket_item.description.replace(/<[^>]*>?/gm, '') == '')" @click="localFocusEditor">Click to edit the description</p>
                                            <editor-content v-else class="c-editor md-body-2 u-hfull" :editor="editor_ticket_description"></editor-content>
                                            <s-editor-menu v-if="checkTicketPermission('update')" :content="editor_ticket_description"></s-editor-menu>
                                        </div>
                                    </a-card>
                                </div>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.description">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ ticket_response.server.errors.description[0] }}
                                </span>
                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="localGetTicketErrorMessage('description')">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ localGetTicketErrorMessage('description') }}
                                </span>
                                <!-- Attachment section -->
                                <div class="mt-8">
                                    <SAttachmentSection
                                        :list="attachment_local_list"
                                        :dropTransferList="localDroppedFilesList"
                                        :sourceId="local_ticket_item.id"
                                        :updateLoading="localAttachmentsUpdating"
                                        :loading="mixinIsLoading('attachment')"
                                        :canUpdate="permission_mixin('poc_tickets.update')"
                                        sourceType="Ticket"
                                        @dropTransfer="localUpdateAttachmentTransfer"
                                        @loadMore="localLoadMoreAttachments()"
                                        @remove="localRemoveAttachmentItem"
                                        @delete="localDeleteAttachmentItem"
                                        isExternal
                                    >
                                    </SAttachmentSection>
                                </div>
                                <template v-if="permission_mixin('projects.update-only') && permission_mixin('poc_tickets.update')">
                                    <div class="c-update-container white u-elevation-custom-1 u-rounded-corners py-3 px-4 mt-6">
                                        <div class="u-flex">
                                            <div class="u-flex" v-if="permission_mixin('poc_tickets.update')">
                                                <a-btn
                                                    @click="localTicketUpdate()"
                                                    :loading="isTicketUpdateLoading"
                                                    :disabled="!localCheckTicketUpdate"
                                                    height="32"
                                                    color="#3949AB"
                                                    class="c-save-btn ma-0 mr-4 px-2 py-3 align-center white--text text--darken-2 u-rounded-corners"
                                                    small
                                                >
                                                    Save
                                                </a-btn>
                                                <a-btn
                                                    :disabled="!localCheckTicketUpdate || localAttachmentsUpdating"
                                                    @click="localTicketUpdateCancel()"
                                                    height="32"
                                                    color="#EEEEEE"
                                                    depressed
                                                    tag="a"
                                                    small
                                                    class="ma-0 px-2 py-3 align-center text--darken-2 u-rounded-corners"
                                                >
                                                    <span style="color: #9E9E9E">Cancel</span>
                                                </a-btn>
                                                <div class="px-4 d-flex align-center ml-4" v-if="mixinIsLoading('attachment-update') || isTicketUpdateLoading">
                                                    <template v-if="mixinIsLoading('attachment-update') && attachment_upload_list && attachment_upload_list.length">
                                                        <span class="text-uppercase blue--text md-body-2">
                                                            Uploading Attachments
                                                        </span>
                                                    </template>
                                                    <template v-else-if="isTicketUpdateLoading && (attachment_upload_list && !attachment_upload_list.length)">
                                                        <span class="text-uppercase blue--text md-body-2">
                                                            Saving changes
                                                        </span>
                                                    </template>
                                                </div>
                                                <a-sheet v-if="localTicketUpdateErrors.length" class="px-4 u-rounded-corners d-flex align-center red lighten-5 ml-4">
                                                    <a-icon size="16px" color="red">info</a-icon>
                                                    <p class="ml-2 font-weight-medium mb-0 red--text text--darken-1">One or more fields are invalid</p>
                                                </a-sheet>
                                            </div>
                                            <a-spacer></a-spacer>
                                            <div v-if="permission_mixin('poc_tickets.destroy')">
                                                <a-menu bottom left offset-y :close-on-content-click="false" max-width="250" :disabled="pageLoading || isTicketUpdateLoading || localAttachmentsUpdating">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <a-btn small text dark class="red lighten-5 u-rounded-corners elevation-0 ma-0 u-wfull red--text text--darken-1 u-font-weight-semibold" height="32">
                                                                Delete Ticket
                                                            </a-btn>
                                                        </div>
                                                    </template>
                                                    <a-card>
                                                        <a-card-text class="pa-4">
                                                            <h2 class="md-heading-6 mb-2">Are you sure to delete this ticket?</h2>
                                                            <p class="body-2 mb-4">This action will delete the ticket and cannot be undone.</p>
                                                            <a-btn class="ma-0 elevation-0" color="red" @click="localProjectTicketDestroy(local_ticket_item)" :loading="loading" :disabled="loading || isTicketUpdateLoading" dark small>
                                                                <a-icon size="16" left class="mr-2">delete</a-icon> Delete
                                                            </a-btn>
                                                        </a-card-text>
                                                    </a-card>
                                                </a-menu>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </a-flex>
                            <a-flex xl3 xs3 ml-4 pr-0>
                                <div :style="[{ maxWidth: local_screen_lg ? 'auto' : '270px' }]" class="u-rounded-corners pa-5 py-4 mt-5">
                                    <div class="c-dropdown mb-5">
                                        <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Ticket Id</span>
                                        <h3 class="md-body-2 font-weight-medium grey--text text--darken-2 grey lighten-2 d-inline-block u-rounded-corners px-1" style="font-family: monospace !important;">{{ local_ticket_item.code }}</h3>
                                    </div>
                                    <div class="c-dropdown mb-5">
                                        <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Due Date</span>
                                        <div class="md-body-2 u-cursor-pointer grey--text text--darken-3 font-weight-medium d-inline-flex align-center">
                                            <a-icon size="16" color="grey darken-1" class="mr-1 u-icon-nudge" style="top: -1px;">date_range</a-icon>
                                            <g-moment v-if="local_ticket_item.due_date" :value="local_ticket_item.due_date" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY" convert-local></g-moment>
                                            <template v-else>No Due Date</template>
                                        </div>
                                    </div>
                                    <div class="c-dropdown mb-6">
                                        <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Type</span>
                                        <s-meta-ticket-dropdown max-width="270" :can-update="checkTicketPermission('update')" text-length="20" :meta-item="local_ticket_item.type" :meta-list="typeList" icon-size="24" inline @updateMeta="localUpdateProjectMeta(...arguments, 'type')">
                                            <template #icon>arrow_drop_down</template>
                                        </s-meta-ticket-dropdown>
                                    </div>
                                    <div class="c-dropdown mb-6">
                                        <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Priority</span>
                                        <s-meta-ticket-dropdown max-width="270" :can-update="checkTicketPermission('update')" text-length="20" :meta-item="local_ticket_item.priority" :meta-list="priorityList" icon-size="24" inline @updateMeta="localUpdateProjectMeta(...arguments, 'priority')">
                                            <template #icon>arrow_drop_down</template>
                                        </s-meta-ticket-dropdown>
                                    </div>
                                    <div class="c-dropdown mb-6">
                                        <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Status</span>
                                        <s-meta-ticket-dropdown max-width="270" :can-update="false" text-length="20" :meta-item="local_ticket_item.status" :meta-list="statusList" icon-size="24" inline @updateMeta="localUpdateProjectMeta(...arguments, 'status')">
                                            <template #icon>arrow_drop_down</template>
                                        </s-meta-ticket-dropdown>
                                    </div>
                                    <div class="c-dropdown mb-6">
                                        <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">Assignee</span>
                                        <s-user-list-popup :item="local_ticket_item" :count="4" :can_update="false" :show-empty-profile="false" no-border @show_add_users_dialog="local_show_add_users_dialog(ticket_item)">
                                            <template #no-users>
                                                <span class="md-body-2 grey--text text--lighten-1">No Assignees</span>
                                            </template>
                                        </s-user-list-popup>
                                    </div>
                                    <div class="c-dropdown mb-5">
                                        <span style="letter-spacing: 0.8px;" class="d-block md-caption text-uppercase grey--text text--darken-1 font-weight-medium mb-1">First Reported by</span>
                                        <h3 class="md-body-2 font-weight-medium u-flex align-center grey--text text--darken-2 u-rounded-corners" style="font-family: monospace !important;">
                                            <a-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on" class="font-weight-medium">
                                                        {{ local_ticket_item.created_by ? local_ticket_item.created_by.name: '' }}
                                                    </span>
                                                </template>
                                                <div class="u-flex align-center">
                                                    <g-avatar :item="local_ticket_item.created_by" no-border :size="28" class="mr-2" :right-space="false"></g-avatar>
                                                    <g-profile-info dark :item="local_ticket_item.created_by"></g-profile-info>
                                                </div>
                                            </a-tooltip>
                                            <a-menu bottom open-on-hover offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on" class="ml-1">
                                                        <g-moment :value="local_ticket_item.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="[on] MMM DD" convert-local></g-moment>
                                                    </div>
                                                </template>
                                                <a-sheet class="md-body-2 u-elevation-custom-1 u-rounded-corners primary darken-1 grey--text text--lighten-1 pa-2 px-3">
                                                    <g-moment :value="local_ticket_item.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mm A" convert-local></g-moment>
                                                </a-sheet>
                                            </a-menu>
                                        </h3>
                                    </div>
                                    <div class="mt-4">
                                        <h3 v-if="timer_update_debounce" class="md-body-2 white orange--text text--darken-1 text-center font-weight-medium pa-2 u-rounded-corners">Autosaving</h3>
                                        <h3 class="md-body-2 white green--text text-center font-weight-medium white pa-2 u-rounded-corners" v-if="!timer_update_debounce && update_autosaved_at">
                                            <g-moment :value="update_autosaved_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="[Autosaved at] hh:mma"></g-moment>
                                        </h3>
                                    </div>
                                </div>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Assignee Form -->
        <a-dialog max-width="800" v-model="dialog_add_users_form" persistent>
            <a-card v-if="local_user_edit_item">
                <a-card-text class="pa-0">
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start>
                            <a-flex shrink class="pr-1">
                                <a-avatar class="primary darken-1" size="40">
                                    <a-icon dark>group</a-icon>
                                </a-avatar>
                            </a-flex>
                            <a-flex>
                                <h2 class="md-heading-6 primary--text text--darken-1"><span class="md-heading-6 text-capitalize">Ticket</span> Assignees</h2>
                                <p class="md-subtitle-2 mb-0">Manage users under <strong class="grey--text text--darken-2">{{ local_truncate_text(local_user_edit_item.title, 55) }}</strong></p>
                            </a-flex>
                            <a-flex shrink v-if="autosave_user_add_status !== 'none'">
                                <a-chip class="right" small color="grey" text-color="white" v-if="autosave_user_add_status === 'loading'">Saving...</a-chip>
                                <a-chip class="right" small color="green" text-color="white" v-if="autosave_user_add_status === 'done'">Saved</a-chip>
                            </a-flex>
                            <a-flex shrink>
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a-btn small text icon color="grey" :disabled="autosave_user_add_status === 'loading'" @click="dialog_add_users_form = false" class="mx-0 mt-0 mr-0 mb-0 pa-0 px-3 right" v-on="on">
                                            <a-icon size="26">cancel</a-icon>
                                        </a-btn>
                                    </template>
                                    <span>Close</span>
                                </a-tooltip>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start style="min-height: 66px;">
                            <a-flex class="u-flex align-center u-wrap">
                                <span class="md-subtitle-1 d-inline-block py-1 px-2 mr-2 my-1 u-rounded-corners u-cursor-pointer" v-if="local_user_edit_item.assignees && local_user_edit_item.assignees.length === 0">
                                    No Users Assigned
                                </span>
                                <template v-else>
                                    <span class="md-subtitle-1 u-flex align-center py-1 px-2 mr-2 my-1 u-cursor-pointer grey lighten-2" style="border-radius: 50px;" v-for="assignee in local_user_edit_item.assignees" :key="assignee.id">
                                        <div class="member-avatar mr-2">
                                            <g-avatar :item="assignee" :size="30" :right-space="false" no-border></g-avatar>
                                        </div>
                                        <div class="member-label u-cursor-pointer">
                                            <template v-if="assignee && assignee.name">
                                                <h2 class="md-subtitle-1 font-weigh-medium grey--text text--darken-3">{{ assignee.name }}</h2>
                                            </template>
                                            <template v-else>
                                                <h2 class="md-subtitle-1 font-weigh-medium grey--text text--darken-3">{{ assignee.assignee.name }}</h2>
                                            </template>
                                        </div>
                                        <a-icon @click="local_assign(local_user_edit_item, assignee.assignee.id, true)" class="ml-2" size="18">clear</a-icon>
                                    </span>
                                </template>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider></a-divider>
                    <a-container container--fluid grid-list-xl class="pa-6">
                        <a-layout wrap align-start>
                            <a-flex xs12>
                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Select Users</label>
                                <a-autocomplete
                                    v-model="local_selected_user_add_item"
                                    :items="local_member_list(local_user_edit_item.assignees)"
                                    item-text="user.name"
                                    item-value="user_id"
                                    label="Select Users"
                                    class="elevation-0"
                                    background-color="neutral"
                                    clear-icon="backspace"
                                    :menu-props="{ closeOnContentClick: autosave_user_add_status === 'loading' }"
                                    :disabled="loading"
                                    @change="local_assign(local_user_edit_item, local_selected_user_add_item[0])"
                                    solo flat hide-details multiple small-chips hide-selected clearable deletable-chips autofocus
                                >
                                    <template v-slot:no-data>
                                        <div class="px-4 py-2">
                                            <span class="md-body-1 grey--text">No Users found</span>
                                        </div>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <a-layout align-center class="py-2">
                                            <g-avatar :item="item"></g-avatar>
                                            <g-profile-info :item="item" set-max-width></g-profile-info>
                                        </a-layout>
                                    </template>
                                </a-autocomplete>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!-- Snackbar -->
        <a-dialog v-model="dialogCancelPageModal" persistent max-width="400">
            <SCancelChanges
                @success="localTicketChangesClear"
                @close="dialogCancelPageModal = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to cancel?</template>
                <template #successButtonLabel>Yes, cancel</template>
                <template #cancelButtonLabel>No</template>
            </SCancelChanges>
        </a-dialog>

        <a-dialog v-model="dialogLeavePageModal" persistent max-width="400">
            <SCancelChanges
                @success="pageToLeave ? pageToLeave() : {}"
                @close="dialogLeavePageModal = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { Editor, EditorContent } from 'tiptap'
import {
    Bold,
    Italic,
    Strike,
    OrderedList,
    BulletList,
    ListItem,
    History,
} from 'tiptap-extensions'
import marked from 'marked'
import { SUserListPopup, SMetaTicketDropdown, SEditorMenu, SCancelChanges, SAttachmentSection } from '@/config/config-shared-components'
import SDragOverlay from '@/components/Attachments/SharedDragOverlay.vue'
import ExternalPermissionMixin from '@/mixins/mixin-external-view-permission'
import mixinLoader from '@/mixins/mixin-module-loading-setup'
import mixinAttachmentsExternal from '@/mixins/mixin-external-attachment'

export default {
    mixins: [ExternalPermissionMixin, mixinLoader, mixinAttachmentsExternal],

    components: {
        EditorContent, SEditorMenu, SMetaTicketDropdown, SUserListPopup, SCancelChanges, SAttachmentSection, SDragOverlay
    },

    data () {
        return {
            autosave_user_add_status: 'none',
            local_user_edit_item: {},
            loading_ticket: {},
            dialog_add_users_form: false,
            dialog_ticket_form: false,
            local_selected_user_add_item: null,
            editor_ticket_description: null,
            timer_update_debounce: null,
            update_autosaved_at: null,
            ticket_title: null,
            datepicker_due_date: null,
            datepicker_due_date_edit: null,
            ticket_tag_input: null,
            local_tag_search: null,
            statusList: [],
            priorityList: [],
            localTicketUpdateErrors: [],
            islocalTicketFieldUpdate: null,
            isTicketUpdateLoading: false,
            dialogCancelPageModal: false,
            dialogLeavePageModal: false,
            typeList: [],
            local_ticket_item: {},
            pageLoading: true,
            pageToLeave: null,
            local_ticket_fields: { 'include': 'status,priority,type,createdBy,assignees.role,tags' },

             //Attachment
            filesDraggedOver: false,
            hoveredOnInfo: false,
            FILTER: {
                parent_id: 'filter[parent_id',
                parent_type: 'filter[parent_type',
                source_id: 'filter[source_id]',
                source_type: 'filter[source_type]',
                fields: 'fields[attachments]',
                page: 'page'
            },
            attachment_fields: 'id,name,size,extension,order,source_id,source_type,parent_id,parent_type',
            localDroppedFilesList: [],
            localAttachmentFilters: {},
            backupAttachmentList: [],
        }
    },

    mounted () {
        this.localIndex()
        if (this.checkTicketPermission('update')) this.localAddListeners()
    },

    beforeDestroy () {
        this.localRemoveListeners()
    },

    beforeRouteLeave (toPage, fromPage, nextRoute) {
        if (!this.localCheckTicketUpdate) return nextRoute()
        nextRoute(false)
        this.dialogLeavePageModal = true
        this.pageToLeave = nextRoute
    },

    computed: {
        localCheckTicketUpdate () {
            return (this.islocalTicketFieldUpdate === false) || this.localCheckAttachmentUpdate
        },

        local_screen_lg () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        local_project_id () {
            return this.$route.params.id
        },

        localCheckAttachmentUpdate () {
            return !!_.size(this.attachment_upload_list) || !!_.size(this.attachment_delete_list)
        },

        localAttachmentsUpdating () {
            return this.mixinIsLoading('attachment-update') || this.mixinIsLoading('attachment-delete')
        },

        ...mapState('CollaboratorExternal', {
            collaborator_list: 'list',
            collaborator_filters: 'filters',
        }),

        ...mapState('AssigneeExternal', {
            assignee_response: 'response',
        }),

        ...mapState('TicketExternal', {
            ticket_search_list: 'search_list',
            ticket_item: 'item',
            ticket_meta: 'meta',
            ticket_form_mode: 'form_mode',
            ticket_response: 'response',
        }),

        ...mapState('MetaExternal', {
            meta_list: 'list',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('AttachmentExternal', {
            attachment_list: 'list',
            attachment_local_list: 'local_list',
            attachment_delete_list: 'delete_list',
            attachment_upload_list: 'upload_list',
            attachment_meta: 'meta',
            attachment_response: 'response',
            attachment_delete_response: 'delete_response',
        }),
    },

    methods: {
        async localIndex () {
            if (!this.can_internal_mixin('poc_tickets.index')) return this.pageLoading = false
            if (!this.can_external_mixin('poc_tickets.index') || !this.can_internal_mixin('poc_tickets.index')) {
                return this.$router.replace({ name: 'errors-unauthorized' })
            }

            await this.localProjectTicketShow()
            this.localSetTicketdescription()

            if (this.ticket_response && this.ticket_response.status !== 'success') {
                if (this.ticket_response.code === 404) return this.$router.replace({ name: '404' })
            }

            this.localLoadTicketAttachments()

            await this.localLoadStatus()
            await this.localLoadPriority()
            await this.localLoadType()
            this.pageLoading = false
        },

        async localProjectTicketShow () {
            await this.ticket_show({
                id: this.$route.params.ticket_id,
                params: this.local_ticket_fields
            })
            this.local_ticket_item = _.cloneDeep(this.ticket_item)
        },

        async localLoadStatus () {
            await this.meta_index({
                'filter[type]': 'ticket_status',
                'sort': 'order'
            })
            this.statusList = this.meta_list
        },

        async localLoadPriority () {
            await this.meta_index({
                'filter[type]': 'ticket_priority',
                'sort': 'order'
            })
            this.priorityList = this.meta_list
        },

        async localLoadType () {
            await this.meta_index({
                'filter[type]': 'ticket_type',
                'sort': 'order'
            })
            this.typeList = this.meta_list
        },

        async localLoadTicketAttachments () {
            this.mixinSetLoading('attachment')
            this.attachment_clear_response()
            this.attachment_clear_upload_list()
            this.localSetAttachmentFilters()
            await this.attachment_index({ params: this.localAttachmentFilters })
            this.mixinResetLoading('attachment')
            this.mixinExternalUpdateLocalAttachmentList({ list: this.attachment_list })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localSetAttachmentFilters () {
            this.localAttachmentFilters[this.FILTER.parent_id] = this.local_ticket_item.parent_id
            this.localAttachmentFilters[this.FILTER.parent_type] = 'Project'
            this.localAttachmentFilters[this.FILTER.source_id] = this.$route.params.ticket_id
            this.localAttachmentFilters[this.FILTER.source_type] = 'Ticket'
            this.localAttachmentFilters[this.FILTER.fields] = this.attachment_fields
            this.localAttachmentFilters.count = 5
        },

        // Add Assignee
        async local_assign (ticket, user_id, unassign) {
            const assignee_param = { resource_type: 'Ticket', resource_id: ticket.id, project_id: this.local_project_id, user_id }
            this.autosave_user_add_status = 'none'
            this.autosave_user_add_status = 'loading'

            if (unassign) await this.assignee_destroy({ id: user_id })
            else await this.assignee_store(assignee_param)
            if (this.assignee_response.status === 'success') {
                await this.ticket_show({ id: ticket.id, params: this.local_ticket_fields })
                this.local_reset_assignee_variables(ticket.id)
            }
        },

        async local_show_add_users_dialog (item) {
            this.autosave_user_add_status = 'none'
            this.local_user_edit_item = item
            this.dialog_add_users_form = true
        },

        local_reset_assignee_variables (ticket_id) {
            this.local_selected_user_add_item = null
            this.autosave_user_add_status = 'done'
            Object.assign(this.local_user_edit_item, this.ticket_item)
        },

        localFocusEditor () {
            this.local_ticket_item.description = ' '
            this.localSetTicketdescription()
            this.editor_ticket_description.focus()
            this.localGetFieldUpdate()
        },

        localSetTicketdescription () {
            if (this.editor_ticket_description) this.editor_ticket_description.destroy()
            const content = this.local_ticket_item.description ? this.local_ticket_item.description : ''
            this.editor_ticket_description = new Editor({
                extensions: [
                    new Bold(),
                    new Italic(),
                    new Strike(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new History(),
                ],
                content: marked(content, { sanitize: true }),
                onUpdate: this.localUpdateDebounced,
                editable: this.checkTicketPermission('update')
            })
        },

        async localUpdateDebounced (state = null) {
            if (state) this.local_ticket_item.description = turndownService.turndown(state.getHTML())
            this.localTicketValidationCheck()
        },

        async localUpdateSystemFieldUpdate () {
            await this.ticket_update({
                item: this.local_ticket_item,
                id: this.local_ticket_item.id,
            })
        },

        localTicketValidationCheck () {
            this.localGetFieldUpdate()
            this.localTicketUpdateErrors = []
            this.localCheckTicketUpdateErros()
        },

        async localTicketUpdate () {
            this.localTicketUpdateErrors = []
            this.isTicketUpdateLoading = true
            const data = await this.localCheckTicketUpdateErros()
            if (data) {
                this.localNotifyResponse('One or more fields are invalid. Ticket could not be saved. Please update and try again.', 'error')
                this.isTicketUpdateLoading = false
                return
            }
            await this.localUpdateSystemFieldUpdate()
            await this.localUpdateTicketAttachment()
            this.localSetTicketdescription()
            this.islocalTicketFieldUpdate = null
            this.isTicketUpdateLoading = false
            this.localNotifyResponse('Ticket Updated Successfully', 'success')
        },

        localCheckTicketUpdateErros () {
            if (this.local_ticket_item) {
                if (!this.local_ticket_item.title.trim()) {
                    const erroritem = {}
                    erroritem.name = 'title'
                    erroritem.message = 'Required field.'
                    this.localTicketErrorMessageUpdate(erroritem)
                }
                if (this.local_ticket_item.title && this.local_ticket_item.title.trim().length > 1000) {
                    const erroritem = {}
                    erroritem.name = 'title'
                    erroritem.message = 'Only maximum of 1000 characters allowed.'
                    this.localTicketErrorMessageUpdate(erroritem)
                }
                if (this.local_ticket_item.description && this.local_ticket_item.description.trim().length > 50000) {
                    const erroritem = {}
                    erroritem.name = 'description'
                    erroritem.message = 'Only maximum of 50000 characters allowed.'
                    this.localTicketErrorMessageUpdate(erroritem)
                }
            }
            if (this.localTicketUpdateErrors.length) return true
            return false
        },

        localGetTicketErrorMessage (name) {
            const item = this.localTicketUpdateErrors.find(list => list.name === name)
            if (item) return item.message
            return null
        },

        localTicketErrorMessageUpdate (item) {
            const index = this.localTicketUpdateErrors.findIndex(list => list.id === item.name)
            if (index === -1) this.localTicketUpdateErrors.push(_.cloneDeep(item))
            else this.localTicketUpdateErrors[index].message = item.message
        },

        // Metas Update
        async localUpdateProjectMeta ({ meta_id }, field, clear = false) {
            if (clear) return this.local_ticket_item[field + '_id'] = null
            if (this.local_ticket_item[field + '_id'] === meta_id) return
            const list = this.localGetMetaList(field)
            const item = list.find(item => item.id === meta_id)
            this.local_ticket_item[field + '_id'] = meta_id
            this.local_ticket_item[field] = item
            this.localGetFieldUpdate()
        },

        async localGetFieldUpdate () {
            this.islocalTicketFieldUpdate = _.isEqual(this.local_ticket_item, this.ticket_item)
        },

        async localBeforeUnloadEvent (e) {
            if (this.localCheckTicketUpdate) {
                return e.returnValue = 'You have unsaved changes. Are you sure you want to leave?'
            }
        },

        localGetMetaList (field) {
            if (field === 'type') return this.typeList
            if (field === 'priority') return this.priorityList
            if (field === 'status') return this.statusList
        },

        async localProjectTicketDestroy (ticket) {
            await this.ticket_destroy(ticket)
            if (this.ticket_response && this.ticket_response.status !== 'success') return
            this.$router.replace({ name: 'external-projects-tickets' })
        },

        localTicketUpdateCancel () {
            this.dialogCancelPageModal = true
        },

        async localTicketChangesClear () {
            this.local_ticket_item = _.cloneDeep(this.ticket_item)
            this.localClearAttachmentUpdate()
            this.localSetTicketdescription()
            this.localGetFieldUpdate()
            this.localTicketUpdateErrors = []
            this.dialogCancelPageModal = false
        },

        local_member_list (list) {
            return this.collaborator_list.filter(item => !(_.find(list, { id: item.user_id })))
        },

        // Extras
        local_set_ticket_loading (field) {
            this.loading_ticket[field + '_id'] = this.ticket_item[field + '_id']
        },

        loading_ticket_item (field, meta_id) {
            return this.loading_ticket[field] === meta_id
        },

        localNotifyResponse (content, type) {
            this.$notify(type, content)
        },

        local_format_date (date) {
            return date !== null ? moment(date).format('MMM D, YYYY') : null
        },

        local_truncate_text (text, range, separator = '...') {
            if (!text) return
            return text.length > range ? text.slice(0, range) + separator : text
        },

        checkTicketPermission (type) {
            return this.permission_mixin('poc_tickets.' + type) && !this.isTicketUpdateLoading
        },

        // Attachment CRUD

        localAddListeners () {
            window.addEventListener('beforeunload', this.localBeforeUnloadEvent)
            this.mixinExternalAttachmentListeners('add')
        },

        localRemoveListeners () {
            window.removeEventListener('beforeunload', this.localBeforeUnloadEvent)
            this.mixinExternalAttachmentListeners('remove')
        },

        localHandleDragEnter (e) {
            this.filesDraggedOver = true
        },

        localHandleLeave (e) {
            if (this.hoveredOnInfo) return
            this.filesDraggedOver = false
        },

        localHandleDrop (e) {
            if (this.localAttachmentsUpdating || this.pageLoading) return
            const droppedFiles = Array.from(e.dataTransfer.files)
            if (_.size(droppedFiles) > 5) return this.localValidateAttachmentCount()
            this.localDroppedFilesList = _.cloneDeep(droppedFiles)
            this.filesDraggedOver = false
        },

        localValidateAttachmentCount () {
            this.$notify('warning', 'Sorry, you can only upload up to 5 files at a time')
            this.filesDraggedOver = false
        },

        localRemoveAttachmentItem (id) {
            this.mixinExternalRemoveAttachmentItem(id)
        },

        localDeleteAttachmentItem (id) {
            this.mixinExternalDeleteAttachmentItem(id)
        },

        async localUpdateTicketAttachment () {
            this.mixinSetLoading('attachment-update')
            this.attachment_clear_response()
            this.mixinExternalRemoveInvalidFiles()
            await this.localBulkDeleteAttachment()
            await this.localUploadAttachment()
            this.mixinResetLoading('attachment-update')
        },

        async localUploadAttachment () {
            if (!_.size(this.attachment_upload_list)) return
            const formData = new FormData();
            formData.append('parent_id', this.local_ticket_item.parent_id)
            formData.append('parent_type', 'Project')
            formData.append('source_id', this.$route.params.ticket_id)
            formData.append('source_type', 'Ticket')
            formData.append('order', -1)
            this.attachment_upload_list.forEach(file => {
                formData.append('files[]', file)
                formData.append('ids[]', file.id)
            })
            await this.attachment_upload(formData)

            if (!this.$status(this.attachment_response)) return this.mixinExternalHandleAttachmentErrors()
            this.localShowTicketAttachments()
            this.attachment_clear_upload_list()
        },

        async localBulkDeleteAttachment () {
            if (!_.size(this.attachment_delete_list)) return
            this.mixinSetLoading('attachment-delete')
            await this.attachment_bulk_destroy({ids: this.attachment_delete_list})
            if (this.$status(this.attachment_delete_response)) {
                if (!_.size(this.attachment_upload_list)) this.localShowTicketAttachments()
                if (!_.size(this.attachment_local_list) && !_.size(this.attachment_upload_list)) this.localLoadTicketAttachments()
                this.attachment_clear_delete_list()
            }
            this.mixinResetLoading('attachment-delete')
        },

        async localShowTicketAttachments () {
            this.localAttachmentFilters.count = _.size(this.attachment_local_list)
            this.localAttachmentFilters.page = 1
            await this.attachment_index({ mode: 'show', params: this.localAttachmentFilters})
            this.mixinExternalUpdateLocalAttachmentList({ list: this.attachment_list})
            this.backupAttachmentList = _.cloneDeep(this.attachment_list)
        },

        localUpdateAttachmentTransfer (params) {
            this.attachment_clear_response()
            const { list, transferFiles } = params || {}
            const totalUploadList = !!_.size(this.attachment_upload_list) ? [...transferFiles, ...this.attachment_upload_list] : [...transferFiles]
            this.mixinExternalPreserveListChanges({ list: list, type: 'drop_transfer' })
            this.attachment_update_upload_list(totalUploadList)
        },

        async localLoadMoreAttachments () {
            if (this.attachment_meta.total === _.size(this.attachment_list)) return

            this.mixinSetLoading('attachment')

            const defaultCount = 5
            const isListShortOfItem = _.size(this.attachment_list)%5 === 0
            const loadType = isListShortOfItem ? 'load_more' : 'index'
            this.localAttachmentFilters.count = isListShortOfItem ? defaultCount : this.attachment_meta.current_page*defaultCount
            this.localAttachmentFilters[this.FILTER.page] = isListShortOfItem ? this.attachment_meta.current_page+1 : 1
            await this.attachment_index({ mode: loadType, params: this.localAttachmentFilters })

            this.mixinResetLoading('attachment')
            this.mixinExternalPreserveListChanges({ list: this.attachment_list, type: 'load_more' })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localClearAttachmentUpdate () {
            this.mixinExternalUpdateLocalAttachmentList({ list: this.backupAttachmentList })
            this.mixinExternalClearAttachmentUpdate()
        },

        ...mapActions('CollaboratorExternal', {
            collaborator_index: 'index',
            collaborator_clear: 'clear',
        }),

        ...mapActions('MetaExternal', {
            meta_index: 'index',
        }),

        ...mapActions('AssigneeExternal', {
            assignee_store: 'store',
            assignee_destroy: 'destroy',
        }),

        ...mapActions('TicketExternal', {
            ticket_update: 'update',
            ticket_show: 'show',
            ticket_select: 'select',
            ticket_visibility: 'visibility',
            ticket_destroy: 'destroy',
            ticket_clear_item: 'clear_item',
            ticket_clear_response: 'clear_response',
        }),

        ...mapActions('AttachmentExternal', {
            attachment_index: 'index',
            attachment_upload: 'upload',
            attachment_update_upload_list: 'update_upload_list',
            attachment_remove_upload_item: 'remove_upload_list_item',
            attachment_bulk_destroy: 'bulk_destroy',
            attachment_clear_upload_list: 'clear_upload_list',
            attachment_clear_delete_list: 'clear_delete_list',
            attachment_clear_response: 'clear_response',
        }),
    }
}
</script>

<style lang="scss" scoped>
    .ProseMirror { outline: none !important; }
    .c-meta-title { color: rgba(0,0,0,0.4); }
    .c-letter-space { letter-spacing: 0.8px; }

    .c-update-container {
        position: sticky !important;
        bottom: 0px;
        background: #FFF !important;
        width: 100%;
    }
</style>

<template>
    <div class="u-wfull" style="max-width: 280px;">
        <a-tooltip
            v-if="!localIsWorkspaceRouteView"
            :disabled="!$isConfidentialMode()"
            content-class="c-tooltip-pointer c-tooltip-pointer--right"
            bottom
        >
            <template v-slot:activator="{ on }">
                <div v-on="on" class="u-wfull">
                    <a-card
                        class="u-rounded-corners transparent u-wfull u-flex-center-y"
                        height="72"
                        flat
                    >
                        <a-menu
                            v-model="search_workspace"
                            :close-on-content-click="workspace_mode"
                            :disabled="localIsProjectView || $isConfidentialMode()"
                            :right="collapsed"
                            :nudge-right="collapsed ? 50 : 0"
                            :nudge-bottom="collapsed ? 0 : 10"
                            :bottom="!collapsed"
                            :offset-y="!collapsed"
                            nudge-top="4"
                            content-class="u-z24 u-rounded-corners-lg"
                            transition="slide-x-transition"
                            max-width="300"
                            min-width="300"
                            class="d-block"
                        >
                            <template v-slot:activator="{ on, value }">
                                <div
                                    v-on="on"
                                    :class="[
                                        { 'u-cursor-pointer': !localIsProjectView },
                                        { 'blue-grey lighten-5 u-rounded-corners-lg': value },
                                    ]"
                                    class="text-capitalize mx-3 pa-2 u-wfull navigation__item--normal"
                                >
                                    <div
                                        v-if="current_workspace"
                                        :title="current_workspace && current_workspace.title"
                                        :class="[{ 'u-flex-center-y': !collapsed }]"
                                        class="u-wfull"
                                        style="column-gap: 8px;"
                                    >
                                        <a-sheet
                                            v-if="current_workspace.title"
                                            width="24"
                                            height="24"
                                            color="deep-purple lighten-4"
                                            class="deep-purple--text text--darken-3 font-weight-medium u-flex-center pa-1 u-rounded-corners"
                                        >
                                            {{ current_workspace.title.charAt(0).toUpperCase() }}
                                        </a-sheet>
                                        <template v-if="!collapsed">
                                            <div
                                                :title="current_workspace.title"
                                                class="u-font-16 u-tracking font-weight-medium grey--text text--darken-2 d-block text-truncate"
                                                style="max-width: 170px;"
                                            >
                                                {{ current_workspace.title }}
                                            </div>
                                            <a-spacer></a-spacer>
                                            <a-icon v-if="!localIsProjectView" size="20" color="grey lighten-1">
                                                {{ search_workspace ? 'expand_less' : 'expand_more' }}
                                            </a-icon>
                                        </template>
                                    </div>
                                    <template v-else>
                                        <div v-if="!collapsed" class="md-subtitle-1 u-flex align-center justify-space-between u-wfull">
                                            <span class="u-font-16 font-weight-medium u-tracking grey--text text--darken-2">
                                                Select workspace
                                            </span>
                                            <a-icon size="26" color="grey lighten-1">{{ search_workspace ? 'expand_less' : 'expand_more' }}</a-icon>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <a-card flat class="u-elevation-custom-1 u-rounded-corners u-overflow-hidden" v-if="local_has_workspace">
                                <a-list class="py-0 u-list-condensed" v-if="workspace_board_list && workspace_board_list.length > 8">
                                    <a-list-item class="px-2 my-2 c-input-wrap">
                                        <a-text-field
                                            v-model="local_search_workspace"
                                            placeholder="Search Workspace"
                                            background-color="neutral"
                                            ref="search_workspace_input"
                                            class="body-2 font-weight-normal"
                                            solo flat hide-details
                                            @click="workspace_mode = false"
                                        ></a-text-field>
                                    </a-list-item>
                                </a-list>
                                <a-list style="max-height: 320px;" class="py-0 overflow-y-auto u-list-condensed">
                                    <template v-for="(workspace) in filtered_board_list">
                                        <a-divider :key="'divider-'+workspace.id"></a-divider>
                                        <a-list-item :key="workspace.id" class="py-1 c-hover-state-light grey lighten-5 text-truncate" style="cursor: text !important;" :title="workspace.title && workspace.title.length > 45 ? workspace.title : ''" @click="workspace_mode = false" v-if="workspace.id === current_workspace_id_mixin">
                                            <a-list-item-title style="cursor: text !important;" class="md-body-2 d-block font-weight-normal text-truncate u-flex align-center justify-space-between">
                                                {{ workspace.title}}
                                            </a-list-item-title>
                                            <a-list-item-icon class="justify-end u-flex align-center">
                                                <a-icon size="18" color="green darken-2">check</a-icon>
                                            </a-list-item-icon>
                                        </a-list-item>
                                        <a-list-item :key="workspace.id" class="py-1 c-hover-state-light text-truncate" :title="workspace.title && workspace.title.length > 45 ? workspace.title : ''" @click="methodSwitchWorkspace(workspace.id)" v-else>
                                            <a-list-item-title class="md-body-2 d-block font-weight-normal text-truncate u-flex align-center justify-space-between">
                                                {{ workspace.title}}
                                            </a-list-item-title>
                                        </a-list-item>
                                    </template>
                                    <a-list-item v-if="workspace_board_list && !workspace_board_list.length" disabled>
                                        <a-list-item-content>
                                            <a-list-item-title v-if="interface_loader">
                                                <span class="mb-0 text-center md-subtitle-1">Loading...</span>
                                            </a-list-item-title>
                                            <a-list-item-title v-else>
                                                <span class="mb-0 text-center md-subtitle-1">No workspace found</span>
                                            </a-list-item-title>
                                        </a-list-item-content>
                                    </a-list-item>
                                </a-list>
                                <a-list v-if="user_self.is_admin == 1" class="py-0 u-list-condensed">
                                    <template>
                                        <a-divider class="grey lighten-3"></a-divider>
                                        <a-list-item class="py-1" @click="methodCreateWorkspace()">
                                            <a-list-item-title class="py-2 u-flex align-center">
                                                <span class="md-body-2 blue--text text--darken-2">{{ $can('workspaces.store') ? 'Create Workspace' : 'View Workspaces' }}</span>
                                                <a-spacer></a-spacer>
                                                <a-icon size="20" color="blue darken-2">{{ $can('workspaces.store') ? 'add' : 'keyboard_arrow_right' }}</a-icon>
                                            </a-list-item-title>
                                        </a-list-item>
                                    </template>
                                </a-list>
                            </a-card>
                        </a-menu>
                    </a-card>
                </div>
            </template>
            <span>You are in Presentation Mode</span>
        </a-tooltip>

        <a-sheet
            v-if="localIsWorkspaceRouteView"
            class="u-flex-center-y transparent px-5"
            height="72"
        >
            <a-tooltip :disabled="!collapsed" content-class="elevation-4 u-rounded-corners-lg" bottom offset-y>
                <template v-slot:activator="{ on }">
                    <a-icon v-on="on" size="20" color="grey darken-2" class="mr-2">
                        language
                    </a-icon>
                </template>
                <a-sheet class="primary grey--text text--lighten-2 u-font-14 font-weight-medium">
                    Global-level
                </a-sheet>
            </a-tooltip>
            <h4 v-if="!collapsed" class="u-font-16 font-weight-medium u-leading-20 u-tracking grey--text text--darken-2">
                Global-level
            </h4>
        </a-sheet>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import loadWorkspace from '@/mixins/mixin-workspace'
import EventBus from '@/config/config-eventbus'

export default {
    mixins: [loadWorkspace],

    props: {
        collapsed: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            search_workspace: false,
            local_search_workspace: '',
            workspace_mode: false,
            local_workspace_state: false,
            local_check_ticket_update: false,
        }
    },

    watch: {
        search_workspace(val) {
            if (this.workspace_board_list && this.workspace_board_list.length <= 8) return
            if (val) {
                setTimeout(() => this.$refs.search_workspace_input.ref.focus(), 500)
            }
        },
    },

    created () {
        // To disable/enable when ticketsview edit/update
        EventBus.$on('workspaceUpdate', this.localLoadWorkspace)
        EventBus.$on('updateUnsavedState', (state) => {
            this.local_check_ticket_update = state
            this.local_workspace_state = state
        })
    },

    computed: {
        local_has_workspace () {
            return this.workspace_board_list && this.workspace_board_list.length
        },

        localIsProjectView () {
            const { meta: { parent } } = this.$route
            return parent && parent === 'projects'
        },

        filtered_board_list () {
            return this.workspace_board_list.filter(item => item.title.toLowerCase().includes(this.local_search_workspace.toLowerCase()))
        },

        current_workspace () {
            const current = this.current_workspace_mixin ?? this.default_workspace_mixin
            if (!current && this.workspace_board_list && this.workspace_board_list.length) this.methodSwitchWorkspace(this.workspace_board_list[0].id)
            return current
        },

        localIsWorkspaceRouteView () {
            const { showWorkspace } = this.$route.meta || {}
            this.workspace_route_update(showWorkspace === false)
            return this.workspace_route_state
        },

        localCurrentWorkspaceLength () {
            return this.current_workspace.title.length >= 22
        },

        ...mapGetters('Workspace', {
            workspace_board_list: 'board_list',
            workspace_current: 'current',
        }),

        ...mapState('User', {
            user_self: 'self'
        }),

        ...mapState('Interface', {
            interface_loader: 'loader',
        }),

        ...mapState('Workspace', {
            workspace_route_state:'update_workspace_routes',
        }),
    },

    methods: {
        local_index () {},

        async methodSwitchWorkspace (id) {
            // To disable/enable when ticketsview edit/update
            if (this.local_check_ticket_update && this.local_workspace_state) {
                EventBus.$emit('workspaceState', {
                    workspaceState: this.local_check_ticket_update,
                    workspaceId: id
                })
                return
            }

            // TODO: Fix multiple requests sent when workspace is disabled or enabled
            this.workspace_mode = true
            this.workspace_switch({ id })
            this.preference_workspace_switch({
                resource_type: 'User',
                value: id,
                label: 'Workspace',
                key: 'user.workspace'
            })
            this.search_workspace = false
            this.local_search_workspace = ''
        },

        localLoadWorkspace (payload) {
            const { dialogLeavePageModal, workspace_switch_id } = payload
            this.local_workspace_state = !dialogLeavePageModal
            if (workspace_switch_id) this.methodSwitchWorkspace(workspace_switch_id)
        },

        methodCreateWorkspace () {
            this.workspace_mode = true
            if (this.$route.name === 'settings-workspace') return
            setTimeout(() => this.$router.push({ name: 'settings-workspace' }), 200)
        },

        ...mapActions('Preference', {
            preference_workspace_switch: 'switch_workspace',
        }),

        ...mapActions('Workspace', {
            workspace_route_update: 'update_route',
            workspace_switch: 'switch_workspace',
        })
    }
}
</script>

<template>
    <div
        :style="[
            currentResizeEl ? { userSelect: 'none' } : '',
            { height: `calc(100vh - ${parentHeightControl}px)` }
        ]"
        ref="refParent"
        class="u-no-select pb-16 u-relative"
        style="overflow: auto; scroll-behaviour: smooth"
    >
        <!-- <div class="c-right-hover-strip u-absolute red darken-1" @drop="localDropRightStrip" @dragover="localDragOverRightStrip" style="right: 0px; top: 0px; z-index: 20; height: 100%; width: 200px;"></div> -->
        <template v-for="(milestone, mIndex) in localOrderedItems">
            <!-- Wrap (Group) title -->
            <div
                v-if="!hideAccordian || localViewNoMilestoneHeader(milestone)"
                :key="localIsNoMilestone(milestone) ? (mIndex + '-milestone-title') : (milestone.id + '-milestone-title')"
                @click="localToggleMilestone(milestone.id)"
                :class="[{ 'u-cursor-pointer': !hideAccordian, 'mt-14': !canUpdate && (mIndex !== 0) }]"
                class="white align-center js-draggable-list-item"
                style="display: flex; position: sticky; left: 0px; top: 0px; z-index: 10; border-bottom: 1px solid rgba(0,0,0,0.1) !important;"
            >
                <div class="u-flex-center-y py-2" style="position: sticky; left: 0px; top: 0px; z-index: 2">
                    <slot
                        name="wrapItem"
                        dragHandle="js-drag-handle"
                        :item="milestone"
                        :canUpdate="canUpdate"
                        :hideAccordian="hideAccordian"
                        :isToggledOn="localIsToggledOn(milestone.id)"
                        :isLoading="addedMilestoneIndex === mIndex"
                        :isNoMilestone="localIsNoMilestone(milestone)"
                        :isHovered="milestone.id === wrapHoverElementId"
                        :toggleMilestone="() => localToggleMilestone(milestone.id)"
                    >
                        <a-icon v-if="!hideAccordian && !localIsNoMilestone(milestone)">keyboard_arrow_down</a-icon>
                        <slot name="wrapTitle" :isNoMilestone="localIsNoMilestone(milestone)" :item="milestone" :isHovered="milestone.id === wrapHoverElementId">
                            <h3 class="md-subtitle-1 ml-4 font-weight-medium grey--text text--darken-3">{{ milestone.title }}</h3>
                        </slot>
                    </slot>
                </div>
                <a-spacer></a-spacer>
                <div v-if="!localIsNoMilestone(milestone)" class="u-relative" style="z-index: 1;">
                    <slot name="wrapExtras" :item="milestone" :isHovered="milestone.id === wrapHoverElementId">
                        Extra Content
                    </slot>
                </div>
                <!-- <div class="white" :style="[{ width: `calc(${getFlexBasis})` }]" style="position: absolute; top: 0px; left: 0px; height: 100%;"></div> -->
                <div class="white" :style="[{ width: `100%` }]" style="position: absolute; top: 0px; left: 0px; height: 100%;"></div>
            </div>

            <!-- Magic table -->
            <template v-if="!localIsToggledOn(milestone.id)">
                <div
                    :style="[{ minWidth: getFlexBasis }]"
                    :class="['u-relative', 'c-magic-table']"
                    :key="localIsNoMilestone(milestone) ? (mIndex + '-table') : (milestone.id + '-table')"
                    :ref="'refMilestone-' + mIndex"
                >
                    <v-fade-transition leave-absolute>
                        <div
                            v-if="addedMilestoneIndex === mIndex"
                            :class="['u-absolute', 'loader-animate']"
                            :style="[{ width: '100%', height: '100%', zIndex: 9, opacity: 0.4, background: '#f6f7f9' }]"
                        ></div>
                    </v-fade-transition>

                    <!-- Header -->
                    <div class="c-magic-table__header-row white" :style="[
                        { display: 'flex', position: 'sticky', left: '0px', top: hideAccordian ? '0px' : '41px', zIndex: 9 },
                        { borderBottom: '1px solid rgba(0,0,0,0.1) !important' },
                        hideAccordian ? { borderTop: '1px solid rgba(0,0,0,0.1) !important' } : '',
                    ]">
                        <!-- Header sticky cell -->
                        <div
                            data-col-position="0"
                            class="white py-3 md-body-2 grey--text text--darken-1 c-magic-table__header-cell c-magic-table__header-cell--sticky"
                            :style="[
                                { position: 'sticky', left: '0px' },
                                { flexBasis: localGetFirstCol ? (localGetFirstCol.minWidth === 'full' ? '100%' : localGetFirstCol.minWidth + 'px') : '500px', zIndex: 5, flexShrink: 0 },
                                scrolledLeft ? { boxShadow: '0px 2px 15px 0px rgba(0,0,0,0.2)', clipPath: 'inset(0px -15px 0px -15px)' } : '',
                                localSelectedColumns.length > 1 ? { borderRight: '1px solid rgba(0,0,0,0.1) !important' } : '',
                                currentResizeEl ? { userSelect: 'none' } : ''
                            ]"
                            @dragover.prevent="(evt) => !milestoneDragStarted ? localDragOver(evt) : null"
                            @dragenter="(evt) => !milestoneDragStarted ? localDragEnter(evt) : null"
                        >
                            <div class="u-flex-center-y">
                                <div class="" style="min-width: 40px; height: 100%"></div>
                                {{ localGetFirstCol ? localGetFirstCol.label : '' }}
                            </div>
                        </div>

                        <!-- Header non-sticky cell -->
                        <div class="c-magic-table__header-non-sticky" :style="[{ position: 'relative', flexBasis: getFlexBasis, display: 'flex' }]">
                            <div
                                v-for="(col, index) in localNonStickCols"
                                :data-col-position="index + 1"
                                class="white u-relative c-magic-table--header-drag c-magic-table__header-cell"
                                :key="col.slug + '-header'"
                                @dragstart="col.slug !== 'add-col' && !milestoneDragStarted ? localDragStart($event, col) : ''"
                                @dragover.prevent="col.slug !== 'add-col' && !milestoneDragStarted ? localDragOver($event) : ''"
                                @dragenter.prevent="col.slug !== 'add-col' && !milestoneDragStarted ? localDragEnter($event) : ''"
                                @dragleave="col.slug !== 'add-col' && !milestoneDragStarted ? localDragLeave($event) : ''"
                                @dragend="col.slug !== 'add-col' && !milestoneDragStarted ? localDragEnd($event) : ''"
                                @drop="col.slug !== 'add-col' && !milestoneDragStarted ? localDragDrop($event, col) : ''"
                                :draggable="col.slug !== 'add-col' && !milestoneDragStarted"
                                :style="[
                                    localGetBorder(col, index),
                                    { minWidth: col.minWidth === 'full' ? '100%' : (col.minWidth + 'px' || '200px'), cursor: 'grab' },
                                    currentResizeEl ? { userSelect: 'none' } : '',
                                    col.slug === 'add-col' ? { width: '100%' } : ''
                                ]"
                                :id="'cell-menu-' + col.slug + '-' + milestone.id"
                            >
                                <template v-if="col.slug !== 'add-col'">
                                    <div class="px-3 py-3 md-body-2 grey--text text--darken-1 u-flex-center-y">
                                        <span>{{ col.label }}</span>
                                        <a-spacer></a-spacer>
                                        <div class="c-magic-table__header-cell-menu">
                                            <a-menu :attach="'#cell-menu-' + col.slug + '-' + milestone.id" open-on-hover left nudge-right="20" offset-y min-width="150" max-width="200" content-class="u-rounded-corners-lg u-z16">
                                                <template v-slot:activator="{ on }">
                                                    <a-icon v-on="on" class="u-rounded-corners" size="16" color="grey">more_vert</a-icon>
                                                </template>
                                                <a-list class="u-list-condensed u-list-condensed--round-select pa-1">
                                                    <a-list-item
                                                        v-if="index !== 0"
                                                        @click.prevent="localCellMenuAction('move-left', index)"
                                                        class="px-3 u-rounded-corners-lg"
                                                    >
                                                        <a-list-item-content class="md-body-2 grey--text text--darken-2">
                                                            Move Left
                                                        </a-list-item-content>
                                                    </a-list-item>
                                                    <a-list-item
                                                        v-if="index !== localSelectedColumns.length - 2"
                                                        @click.prevent="localCellMenuAction('move-right', index)"
                                                        class="px-3 u-rounded-corners-lg"
                                                    >
                                                        <a-list-item-content class="md-body-2 grey--text text--darken-2">
                                                            Move Right
                                                        </a-list-item-content>
                                                    </a-list-item>
                                                    <a-divider class="my-1"></a-divider>
                                                    <a-list-item @click.prevent="localHideColumn(col)" class="px-3 u-rounded-corners-lg">
                                                        <a-list-item-content class="md-body-2 grey--text text--darken-2">
                                                            Hide Column
                                                        </a-list-item-content>
                                                    </a-list-item>
                                                </a-list>
                                            </a-menu>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <a-menu bottom offset-y nudge-left="100" min-width="300" max-width="400" content-class="white">
                                        <template v-slot:activator="{ on }">
                                            <a-card v-on="on" flat tile class="u-wfull text-left u-flex-center-y px-3 py-3 u-wfull" height="100%">
                                                <a-icon size="20" color="grey darken-1">add</a-icon>
                                            </a-card>
                                        </template>
                                        <div>
                                            <a-list class="u-list-std pa-0 white">
                                                <a-list-item class="u-flex-center-y px-3 py-1 ma-0">
                                                    <h2 class="text-uppercase md-body-2 font-weight-medium grey--text text--darken-2">Customize Fields</h2>
                                                    <a-spacer></a-spacer>
                                                    <span class="md-body-2 grey--text font-weight-medium">{{ localCheckedColumns.length }} Selected</span>
                                                </a-list-item>
                                            </a-list>
                                            <a-divider></a-divider>
                                            <div class="c-tiny-scroll u-overflow-y" style="max-height: 300px">
                                                <template v-for="(colItem, index) in localColumnsSelectMenu">
                                                    <a-divider :key="'divider-' + index" v-if="index !== 0"></a-divider>
                                                    <div
                                                        v-if="colItem.slug === 'title'"
                                                        class="px-3 py-2 u-flex-center-y u-cursor-pointer js-draggable-list-item white"
                                                        style="column-gap: 8px;"
                                                        :key="colItem.slug"
                                                        @click.stop="colItem.slug !== 'title' ? localToggleColumn(colItem) : {}"
                                                    >
                                                        <a-icon :class="[{ 'u-opacity-40': colItem.slug === 'title' }, 'js-drag-handle']" @click.stop="{}" size="18" color="grey darken-1">drag_indicator</a-icon>
                                                        <div class="u-flex-center-y u-wfull">
                                                            <span class="md-body-1 grey--text text--darken-2">{{ colItem.label }}</span>
                                                            <a-spacer></a-spacer>
                                                            <a-icon
                                                                @click.stop="colItem.slug !== 'title' ? localToggleColumn(colItem) : {}"
                                                                :color="colItem.selected ? 'indigo darken-2' : 'grey'"
                                                                :outlined="!colItem.selected"
                                                                :class="[{ 'u-opacity-40': colItem.slug === 'title' }]"
                                                                size="20"
                                                            >
                                                                {{ colItem.selected ? 'check_circle' : 'radio_button_unchecked' }}
                                                            </a-icon>
                                                        </div>
                                                    </div>

                                                    <div
                                                        v-else
                                                        class="px-3 py-2 u-flex-center-y u-cursor-pointer js-draggable-list-item"
                                                        style="column-gap: 8px;"
                                                        :key="colItem.slug"
                                                        @click.stop="colItem.slug !== 'title' ? localToggleColumn(colItem) : {}"
                                                        @dragstart="localDragStartCols($event, colItem, index)"
                                                        @dragover.prevent="localDragOverCols($event, colItem, index)"
                                                        @dragenter.prevent="localDragEnterCols($event, colItem, index)"
                                                        @dragleave="localDragLeaveCols($event, colItem, index)"
                                                        @dragend="localDragEndCols($event, colItem, index)"
                                                        @drop="localDragDropCols($event, colItem, index)"
                                                        :draggable="true"
                                                    >
                                                        <a-icon :class="[{ 'u-opacity-40': colItem.slug === 'title' }, 'js-drag-handle']" @click.stop="{}" size="18" color="grey darken-1">drag_indicator</a-icon>
                                                        <div class="u-flex-center-y u-wfull">
                                                            <span class="md-body-1 grey--text text--darken-2">{{ colItem.label }}</span>
                                                            <a-spacer></a-spacer>
                                                            <a-icon
                                                                @click.stop="colItem.slug !== 'title' ? localToggleColumn(colItem) : {}"
                                                                :color="colItem.selected ? 'indigo darken-2' : 'grey'"
                                                                :outlined="!colItem.selected"
                                                                :class="[{ 'u-opacity-40': colItem.slug === 'title' }]"
                                                                size="20"
                                                            >
                                                                {{ colItem.selected ? 'check_circle' : 'radio_button_unchecked' }}
                                                            </a-icon>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </a-menu>
                                </template>
                            </div>
                        </div>
                    </div>

                    <!-- Items -->
                    <template v-for="(row, rowIndex) in milestone.tasks">
                        <div
                            v-if="row"
                            :key="row.id"
                            :ref="`refRow-${row._order}`"
                            :data-row-order="row._order"
                            :id="row.id"
                            :style="[
                                { display: 'flex' },
                                { borderBottom: '1px solid rgba(0,0,0,0.1)' },
                                currentResizeEl ? { userSelect: 'none' } : ''
                            ]"
                            :class="[{ 'js-task-draggable-item': !bulkMode }]"
                            @dragstart="localTaskDragStart($event, row, milestone.id)"
                            @dragover.prevent="localTaskDragOver($event, row, milestone.id)"
                            @dragenter.prevent="localTaskDragEnter($event, row, milestone.id)"
                            @dragleave="localTaskDragLeave($event, row, milestone.id)"
                            @dragend="localTaskDragEnd($event, row, milestone.id)"
                            @drop="localTaskDragDrop($event, row, milestone.id, rowIndex)"
                            class="u-wfull c-magic-table__row"
                        >
                            <!-- TODO: v2 -> Trigger to virtual scroll items to improve performance -->
                            <!-- <SComponentIsVisible @visible="localIsVisible(milestone.id)" /> -->

                            <!-- Row item sticky cell -->
                            <div
                                data-cell-slug="title"
                                class="c-magic-table__cell c-magic-table__cell-sticky"
                                :class="[
                                    { 'u-cursor-pointer': canUpdate },
                                    { 'c-cell-row-hovered': localIsRowHovered(row._order) && !(localIsRowSelected(row.id) && selectedRows.length) },
                                    { 'c-cell-keyed': (localIsRowSelected(row.id) && selectedRows.length) },
                                ]"
                                :ref="`refCell-${rowIndex}-0-id-${row.id}`"
                                :style="[
                                    localIsCellHovered(row._order, 0) ?
                                        (localIsRowSelected(row.id) && selectedRows.length) ?
                                            { backgroundColor: 'rgb(209, 234, 253) !important' } :
                                            { backgroundColor: 'rgb(229, 244, 255) !important' }
                                        : '',
                                    localIsCellEdited(row._order, 0) ? { backgroundColor: 'rgb(255, 255, 255) !important' } : '',
                                    scrolledLeft ? { boxShadow: '0px 2px 15px 0px rgba(0,0,0,0.2)', clipPath: 'inset(0px -15px 0px -15px)' } : '',
                                    { position: 'sticky', left: '0px' },
                                    localSelectedColumns.length > 1 ? { borderRight: '1px solid rgba(0,0,0,0.1) !important' } : '',
                                    { flexBasis: localGetFirstCol ? (localGetFirstCol.minWidth === 'full' ? '100%' : localGetFirstCol.minWidth + 'px') : '500px', zIndex: 5, flexShrink: 0 },
                                ]"
                            >
                                <div
                                    class="u-flex-center-y u-no-select"
                                    style="height: 56px;"
                                    @click.stop="localEditCell($event, row, 0, localGetFirstCol)"
                                >
                                    <!-- <slot
                                        v-if="!taskDragStart"
                                        name="sticky-cell-default"
                                        :item="row"
                                        :isHovered="row.id === itemHoverElementId"
                                        :openEditView="(evt) => localOpenViewForEdit(evt, row)"
                                    ></slot> -->
                                    <div
                                        v-if="!taskDragStart"
                                        class="c-detail-view-btn js-detail-view-btn u-absolute u-cursor-pointer u-no-select u-flex-center blue-grey lighten-5 px-2 py-1 u-shadow"
                                        style="top: 0px; right: 0px; border-radius: 0px 0px 0px 8px"
                                        @click.stop="localOpenViewForEdit($event, row)"
                                    >
                                        <a-icon size="16" color="blue-grey" class="mr-1">open_in_full</a-icon>
                                        <span class="blue-grey--text font-weight-medium md-body-2">Open (O)</span>
                                    </div>

                                    <div class="u-flex-center c-magic-table__row-drag-select" :class="[{ 'u-no-select': bulkMode }]" style="min-width: 40px; height: 100%" @click.stop="{}">
                                        <slot name="itemIcon" v-if="!bulkMode" handle="js-task-drag-handle" :canUpdate="canUpdate" :item="row" :isHovered="row.id === itemHoverElementId"></slot>
                                    </div>
                                    <slot
                                        v-if="localIsCellClicked(row, 0)"
                                        name="cellItem"
                                        colSlug="title"
                                        :item="row"
                                        :column="localGetFirstCol"
                                        :closeCell="localRemoveCellSelection"
                                        :isActive="localIsCellClicked(row, 0)"
                                        :isNoMilestone="localIsNoMilestone(milestone)"
                                        @click.stop="localEditCell($event, row, 0, localGetFirstCol)"
                                    >
                                        {{ row.title || ' - ' }}
                                    </slot>
                                    <slot
                                        v-else
                                        name="cellDisplay"
                                        colSlug="title"
                                        :item="row"
                                        :column="localGetFirstCol"
                                        :closeCell="localRemoveCellSelection"
                                        :isNoMilestone="localIsNoMilestone(milestone)"
                                        :switchDisplay="($event) => localEditCell($event, row, 0, localGetFirstCol)"
                                    >
                                        {{ row.title || ' - ' }}
                                    </slot>
                                </div>
                            </div>

                            <!-- Row items non-sticky cell -->
                            <div class="hide-on-drag" :style="[{ position: 'relative', flexBasis: getFlexBasis, display: 'flex' }]">
                                <div
                                    v-for="(col, colIndex) in localNonStickCols"
                                    class="c-magic-table__cell"
                                    :class="[
                                        { 'u-cursor-pointer': canUpdate },
                                        { 'c-cell-row-hovered': localIsRowHovered(row._order) && !(localIsRowSelected(row.id) && selectedRows.length) },
                                        { 'c-cell-keyed': (localIsRowSelected(row.id) && selectedRows.length) },
                                    ]"
                                    :data-cell-slug="col.slug"
                                    :data-col-position="col._order"
                                    :ref="`refCell-${row._order}-${col._order}-id-${row.id}`"
                                    :key="col.slug + '-cell'"
                                    :style="[
                                        localIsCellHovered(row._order, colIndex + 1) ?
                                            (localIsRowSelected(row.id) && selectedRows.length) ?
                                                { backgroundColor: 'rgb(209, 234, 253) !important' } :
                                                { backgroundColor: 'rgb(229, 244, 255) !important' }
                                            : '',
                                        localIsCellEdited(row._order, colIndex + 1) ? { backgroundColor: 'rgb(255, 255, 255) !important' } : '',
                                        localGetBorder(col, colIndex),
                                        { minWidth: col.minWidth + 'px' || '200px' },
                                        col.slug === 'add-col' ? { width: '100%' } : ''
                                    ]"
                                >
                                    <div
                                        v-if="col.slug !== 'add-col'"
                                        class="u-no-select u-wfull u-flex align-stretch"
                                        :style="[{ height: '56px' }]"
                                    >
                                        <slot
                                            v-if="localIsCellClicked(row, colIndex + 1)"
                                            name="cellItem"
                                            :item="row"
                                            :colSlug="col.slug"
                                            :column="col"
                                            :closeCell="localRemoveCellSelection"
                                            :isNoMilestone="localIsNoMilestone(milestone)"
                                            :isActive="localIsCellClicked(row, colIndex + 1)"
                                            @click.stop="col.slug !== 'add-col' && !avoidValueToggle.includes(col.slug) && canUpdate ? localEditCell($event, row, colIndex + 1, col) : ''"
                                        >
                                            {{ row[col.slug] || ' - ' }}
                                        </slot>
                                        <slot
                                            v-else
                                            name="cellDisplay"
                                            :item="row"
                                            :colSlug="col.slug"
                                            :column="col"
                                            :closeCell="localRemoveCellSelection"
                                            :switchDisplay="($event) => col.slug !== 'add-col' && !avoidValueToggle.includes(col.slug) && canUpdate ? localEditCell($event, row, colIndex + 1, col) : ''"
                                        >
                                            {{ row[col.slug] || ' - ' }}
                                        </slot>
                                    </div>
                                    <div v-else>&nbsp;&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>

                <!-- Add task input -->
                <div
                    v-if="canUpdate && (currentTaskFilter && currentTaskFilter.value === 'all')"
                    :key="localIsNoMilestone(milestone) ? (mIndex + '-new-task-input') : (milestone.id + '-new-task-input')"
                    @click="localEmitCreate(milestone, milestone.tasks && (milestone.tasks.length + 1), 'task')"
                    class="c-add-input u-sticky u-flex"
                    :style="[{
                        borderBottom: '1px solid rgba(0,0,0,0.1)',
                        height: '50px',
                        left: '0px',
                        cursor: 'text'
                    }]"
                >
                    <div class="white u-sticky u-hfull u-wfull u-flex-center-y grey--text px-10" :style="[{ left: '0px' }]">
                        Add new task...
                    </div>
                </div>
            </template>

            <!-- Add milestone -->
            <div v-if="!localIsNoMilestone(milestone) && canUpdate" :key="milestone.id + '-add-section'" @click="localAddMilestone(mIndex)" :style="[{ minWidth: '0px', left: '0px' }]" class="my-8 mb-6 u-sticky u-flex u-cursor-pointer c-add-section">
                <div class="c-add-section__bar u-wfull u-relative">
                    <div class="u-absolute" :style="[{ left: '0px', top: '50%', transform: 'translateY(-50%)', height: '1px', background: 'radial-gradient(50% 50% at 50% 50%, #3949AB 0%, rgba(57, 73, 171, 0.1) 99.99%, rgba(57, 73, 171, 0.08) 100%)', width: '100%' }]"></div>
                    <span class="d-inline-flex align-items-center px-4 white u-sticky" :style="[{ columnGap: '4px', left: '50%', transform: 'translateX(-50%)', zIndex: 1, minWidth: 'max-content' }]">
                        <a-icon size="18" color="indigo darken-1">add</a-icon>
                        <span class="indigo--text text--darken-1 md-body-2">Add Milestone</span>
                    </span>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { SComponentIsVisible, SDraggable } from '@/config/config-shared-components'

export default {
    components: { SComponentIsVisible, SDraggable },

    props: {
        wrapItems: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        columns: {
            type: Array,
            required: true
        },
        avoidValueToggle: {
            type: Array,
            default: () => []
        },
        noDirectEditCols: {
            type: Array,
            default: () => []
        },
        canUpdate: {
            type: Boolean,
            default: false
        },
        hideAccordian: {
            type: Boolean,
            default: false
        },
        hideAddColumn: {
            type: Boolean,
            default: false
        },
        expandAll: {
            type: Boolean,
            default: true
        },
        selectAll: {
            type: Boolean,
            default: false
        },
        refreshOrder: {
            type: Boolean,
            default: false
        },
        viewMode: {
            type: String,
            default: ''
        },
        parentHeightControl: {
            type: Number | String,
            default: '236'
        },
        updateRangesFn: {
            type: Function | Object,
            default: null
        },
        itemRanges: {
            type: Object,
        },
        currentTaskFilter: {
            type: Object,
        },
    },

    data () {
        return {
            /* Props */
            // itemRangeIndices: _.cloneDeep(this.itemRanges),
            cellMenu: [
                { text: 'Move Left', value: 'move-left' },
                { text: 'Move Right', value: 'move-right' },
            ],
            orderedListItems: [],
            currentDragHeaderEl: null,
            scrolledLeft: false,
            lastKeyIsEsc: false,
            modelSelectAllFields: false,
            addMilestoneTimeout: null,
            addedMilestoneIndex: null,
            currentCell: null,
            currentEditCell: null,
            currentHoverCell: null,
            selectedRows: [],
            bulkMode: false,
            shiftStartSelect: null,
            shiftEndSelect: null,
            hasDragSelectStarted: false,
            currentScrollCellPos: null,
            addColumnStub: { label: 'add-col', slug: 'add-col', selected: false, minWidth: 200 },
            currentColumns: [],
            wrapItemList: _.cloneDeep(this.wrapItems),
            currentResizeEl: null,
            taskDragStart: false,
            componentWidth: 'auto',
            keyDownEvt: null,
            rootElement: null,
            componentRefs: null,
            wrapHoverElementId: null,
            itemHoverElementId: null,
            currentFocusedRow: null,
            milestoneDragStarted: false,
            toggledMilestones: []
        }
    },

    watch: {
        selectedRows: {
            handler (val) {
                this.$emit('bulkSelect', val, this.localClearAll)
                this.bulkMode = !!(val.length > 0)
            },
            deep: true,
            immediate: true
        },

        wrapItems: {
            handler (value) {
                this.localFetchRefs()
                this.localOrderItems()
                if (this.canUpdate) this.localAttachMouseHandlers()
                const index = this.orderedListItems.findIndex(i => i._mode === 'create')
                if (index === -1) return

                this.localAddRowForEdit(this.orderedListItems[index])
            },
            immediate: true,
            deep: true,
        },

        refreshOrder (val) {
            if (val) this.localOrderItems()
        },

        configSidebar (newVal, oldVal) {
            if (oldVal !== newVal) this.localTrackComponentWidth()
        },

        expandAll: {
            handler (value) {
                value ? this.localExpandAll() : this.localCollapseAll()
                this.localOrderItems()
                if (this.canUpdate) this.localAttachMouseHandlers()
            },
            immediate: true
        },

        selectAll: {
            handler (value) {
                this.localOrderItems()
                this.localSelectAllToggle(value)
                if (this.canUpdate) this.localAttachMouseHandlers()
            },
            immediate: true
        },
    },

    async mounted () {
        this.localSetupListeners()
        this.localIndex()
    },

    beforeDestroy () {
        this.localDestroyListeners()
    },

    computed: {
        localOrderedItems () {
            return this.items
            // const list = _.cloneDeep(this.items)
            // return list.map(milestone => {
            //     const { endIndex = 10 } = this.itemRanges[milestone.id] || {}
            //     milestone['tasks'] = this.orderedListItems.filter(i => {
            //         return (!i.milestone_id && milestone.id === 'no-milestone') ||
            //             i.milestone_id === milestone.id
            //     }).slice(0, endIndex)

            //     return milestone
            // })
        },

        totalColumnsList () {
            return this.columns.map((item, index) => {
                if (!item.hasOwnProperty('minWidth')) item['minWidth'] = index === 0 ? 500 : 200
                if (!item.hasOwnProperty('selected')) item['selected'] = true
                item['_order'] = index
                return item
            })
        },

        getFlexBasis () {
            return (this.localSelectedColumns.reduce((prev, current) => current.minWidth + prev, 0) + 500) + 'px'
        },

        localTotalSelectedCols () {
            return this.currentColumns.filter(i => i.selected)
        },

        localNonStickCols: {
            get () {
                return this.localSelectedColumns
            },
            set (list) {
                const firstCol = this.currentColumns[0]
                const orderedList = list.map((item, index) => {
                    item['_order'] = index + 1
                    return item
                })
                orderedList.unshift(firstCol)
                this.currentColumns = orderedList
                this.localEmitUpdateCols()
            }
        },

        localSelectedColumns () {
            return this.currentColumns.slice(1).filter(item => item.selected || item.slug === 'add-col')
        },

        localGetFirstCol () {
            return this.currentColumns && this.currentColumns[0]
        },

        localColumnsSelectMenu () {
            const list = _.cloneDeep(this.currentColumns)
            list.splice(-1)
            return list
        },

        localCheckedColumns () {
            const selected = this.localColumnsSelectMenu.filter(i => i.selected)
            return {
                length: _.size(selected),
                list: selected
            }
        },

        localCellIsEdited () {
            return this.currentEditCell && _.size(Object.keys(this.currentEditCell))
        },

        localKeyIsCmdCtrlShift () {
            return this.configKeydown && ((this.configKeydown.ctrlKey || this.configKeydown.metaKey) && this.configKeydown.shiftKey)
        },

        ...mapState('Configuration', {
            configSidebar: 'sidebar',
            configKeydown: 'keydown'
        }),
    },

    methods: {
        async localIndex () {
            this.localOrderItems()
            this.localFetchRefs()
            this.currentColumns = _.cloneDeep(this.totalColumnsList)
            if (!this.hideAddColumn) this.currentColumns.push(this.addColumnStub)
            if (this.canUpdate) this.localAttachMouseHandlers()
            this.componentWidth = this.$el.clientWidth + 'px'
        },

        localSetupListeners () {
            document.addEventListener('click', this.localMouseClick)
            document.addEventListener('keydown', this.localKeyDown)
            if (this.$el) {
                this.$el.addEventListener('scroll', this.localCheckScroll)
                this.$el.addEventListener('resize', this.localTrackComponentWidth)
            }
        },

        localDestroyListeners () {
            document.removeEventListener('click', this.localMouseClick)
            document.removeEventListener('keydown', this.localKeyDown)
            if (this && this.$el) {
                this.$el.removeEventListener('resize', this.localTrackComponentWidth)
                this.$el.removeEventListener('scroll', this.localCheckScroll)

                const rowItems = [...this.$el.querySelectorAll('.c-magic-table__row')]
                rowItems.forEach(row => row.removeEventListener('mousedown', this.localIntiateDragSelect))

                // const dragIcons = [...this.$el.querySelectorAll('.c-magic-table__row-drag-select')]
                // dragIcons.forEach(row => row.removeEventListener('mousedown', this.localIntiateDragSelect))
            }
        },

        localFetchRefs () {
            if (this.$refs.refParent) {
                this.rootElement = this.$refs.refParent
            }
            this.componentRefs = this.$refs
        },

        localClickSelectRow (row) {
            const index = this.selectedRows.findIndex(i => i._order === row._order)
            index === -1 ? this.selectedRows.push(row) : this.selectedRows.splice(index, 1)

            if (_.size(this.selectedRows) === 1) {
                this.shiftStartSelect = this.selectedRows[0]
                this.currentHoverCell = this.selectedRows[0]
            }

            this.$emit('currentRow', row)
        },

        localInitiateDnD (evt) {
            if (!this.canUpdate) return

            const dragEl = evt.target.closest('.c-magic-table__row-drag-select').closest('.js-task-draggable-item')
            dragEl.setAttribute('draggable', !!dragEl)
        },

        localAttachMouseHandlers () {
            if (!this.$el) return

            this.$nextTick(() => {
                const rowItems = [...this.$el.querySelectorAll('.c-magic-table__row')]
                rowItems.forEach(row => row.addEventListener('mousedown', this.localIntiateDragSelect))

                // const dragIcons = [...this.$el.querySelectorAll('.c-magic-table__row-drag-select')]
                // dragIcons.forEach(row => row.addEventListener('mousedown', this.localIntiateDragSelect))
            })
        },

        localIntiateDragSelect (evt) {
            if (evt.target.tagName === 'INPUT') return
            if (evt.target.closest('.js-detail-view-btn')) return
            if (evt.button > 0) return
            if (this.currentEditCell && this.currentEditCell.id) return

            const dragHandlerParent = evt.target.closest('.c-magic-table__row-drag-select')
            if (!this.localKeyIsCmdCtrlShift && !this.bulkMode && dragHandlerParent && dragHandlerParent.contains(evt.target) && this.canUpdate) {
                this.localInitiateDnD(evt)
                return
            }
            if (this.taskDragStart) return
            if (!this.localKeyIsCmdCtrlShift) return

            if (!this.hasDragSelectStarted) {
                this.hasDragSelectStarted = true
                if (evt.target.tagName === 'BODY') {
                    this.selectedRows = []
                    this.currentEditCell = {}
                }
            }

            if (this.hasDragSelectStarted) {
                document.addEventListener('mousemove', this.localDragSelectRows)
                document.addEventListener('mouseup', this.localReleaseDragSelect)
            }
        },

        localDragSelectRows (evt) {
            if (this.taskDragStart) return
            const rowElement = evt.target.closest('.c-magic-table__row')
            if (!rowElement) return true

            const rowOrder = parseInt(rowElement.dataset.rowOrder)
            const rowItem = this.orderedListItems.find(i => i._order === rowOrder)
            if (!rowItem) return true

            this.currentEditCell = {}
            if (_.size(this.selectedRows) === 0) {
                this.selectedRows.push(rowItem)
                this.shiftStartSelect = { ...rowItem }
                return true
            }

            const hasIndex = this.selectedRows.findIndex(i => i._order === rowOrder)
            if (hasIndex === -1) {
                this.selectedRows.push(rowItem)
                return true
            }

            if (_.size(this.selectedRows) <= 1) return

            const lastSelectedOrder = this.selectedRows.slice(-1)[0]._order
            const lastBeforeSelectedOrder = this.selectedRows.slice(-2)[0]._order
            if (rowOrder === lastBeforeSelectedOrder) {
                const lastSelectedIndex = this.selectedRows.findIndex(i => i._order === lastSelectedOrder)
                this.selectedRows.splice(lastSelectedIndex, 1)
            }
        },

        localReleaseDragSelect (evt) {
            document.removeEventListener('mousemove', this.localDragSelectRows)
            document.removeEventListener('mouseup', this.localReleaseDragSelect)
            this.hasDragSelectStarted = false
        },

        localMouseClick (evt) {
            const taskAddBtn = document.querySelector('.c-add-task-btn')
            const inputFields = ['INPUT', 'TEXTAREA']
            const isEditor = evt && evt.target && (
                !!(evt.target.closest('.ProseMirror'))
                || evt.target.classList.value.includes('ProseMirror')
                || evt.target.classList.value.includes('editor')
            )

            if (
                isEditor ||
                (evt && inputFields.includes(evt.target.nodeName)) ||
                taskAddBtn && taskAddBtn.contains(evt.target) ||
                evt.target.closest('.c-add-input') ||
                (evt.target.closest('.c-magic-island')) ||
                (this && (this.$refs.refParent.contains(evt.target)))
            ) return

            setTimeout(() => this.localOnEscape(), 10)
        },

        localKeyDown (evt) {
            if (evt.code === 'Escape') return this.localOnEscape(evt)

            if (evt.target.tagName === 'INPUT') return
            if (evt.code === 'KeyA' && (evt.ctrlKey || evt.metaKey)) return

            if (!this.canUpdate) return

            if (evt.code === 'Enter') return this.localOnEnter(evt)
            if (this.currentEditCell && this.currentEditCell.id) return

            if (evt.target && (evt.target.tagName !== 'BODY' && (evt.target.parentElement && evt.target.parentElement.querySelector('.v-menu')))) return
            if (!_.size(this.selectedRows) && (!this.currentHoverCell || (this.currentHoverCell && !this.currentHoverCell.id))) return

            const row = this.orderedListItems.find(i => i.id === this.currentHoverCell.id)
            if (evt.code === 'ArrowRight') this.localArrowRight(row)
            if (evt.code === 'ArrowLeft') this.localArrowLeft(row)
            if (evt.code === 'ArrowUp') {
                if (evt.shiftKey === true) {
                    evt.preventDefault()
                    this.localBulkSelectUp()
                } else this.localArrowUp()
            }
            if (evt.code === 'ArrowDown') {
                if (evt.shiftKey === true) {
                    evt.preventDefault()
                    this.localBulkSelectDown()
                } else this.localArrowDown()
            }
        },

        localOnEscape (evt) {
            const noKey = (!evt || (evt && evt.key !== 'Escape'))
            if (noKey) return this.localResetFocusToBody()

            if (this.bulkMode) {
                if (this.localCellIsEdited) return this.localRemoveCellSelection()

                this.$emit('clearSelectAll')
                this.selectedRows = []
                return this.localResetFocusToBody()
            }

            this.localRemoveCellSelection()
            this.localResetFocusToBody()
        },

        localResetFocusToBody () {
            if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
            window.focus()
        },

        localClearAll () {
            if (this.bulkMode && this.localCellIsEdited) return

            this.localOnEscape({ key: 'Escape', code: 'Escape' })
        },

        localClearAllSelection () {
            this.selectedRows = []
            this.currentEditCell = null
            this.currentHoverCell = null
            this.shiftStartSelect = null
            this.currentFocusedRow = null
        },

        localOnEnter (evt) {
            const isTargetBody = evt.target.tagName === 'BODY'
            if (this.currentCell && this.currentCell.slug === 'add-col') return
            if ((this.$refs.refParent && !this.$refs.refParent.contains(evt.target)) && !isTargetBody) return
            if (this.currentEditCell && this.currentEditCell.id) return
            if (this.currentCell && this.avoidValueToggle.includes(this.currentCell.slug)) return
            if (!this.currentHoverCell || (this.currentHoverCell && !this.currentHoverCell.id)) return

            const column = this.currentHoverCell.colIndex === 0 ? this.currentColumns[0] : this.localSelectedColumns[this.currentHoverCell.colIndex - 1]
            this.localEditCell(evt, this.currentHoverCell, this.currentHoverCell.colIndex, column)
        },

        localArrowRight (row) {
            this.localRemoveCellSelection()
            const lastColIndex = _.size(this.localSelectedColumns) - 1
            const currentColIndex = this.currentHoverCell.colIndex + 1
            if (currentColIndex > lastColIndex) return

            this.currentCell = this.localSelectedColumns[currentColIndex === 0 ? currentColIndex : (currentColIndex - 1)]
            this.localScrollToColumn(row, currentColIndex)
        },

        localArrowLeft (row) {
            this.localRemoveCellSelection()
            const currentColIndex = this.currentHoverCell.colIndex - 1
            if (currentColIndex < 0) return

            this.currentCell = currentColIndex === 0 ? this.currentColumns[0] : this.localSelectedColumns[currentColIndex - 1]
            this.localScrollToColumn(row, currentColIndex)
        },

        localArrowUp () {
            const currentRowIndex = this.currentHoverCell._order - 1
            if (currentRowIndex < 0) return

            this.localScrollToRow(currentRowIndex)
        },

        localArrowDown () {
            if (!this.currentHoverCell) return

            const lastRowIndex = this.orderedListItems.slice(-1)[0]._order
            const currentRowIndex = this.currentHoverCell._order + 1
            if (currentRowIndex > lastRowIndex) return

            this.localScrollToRow(currentRowIndex)
        },

        localScrollToRow (currentRowIndex) {
            const row = this.orderedListItems.find(i => i._order === currentRowIndex)
            const element = this.componentRefs[`refRow-${row._order}`][0]
            element.scrollIntoView({ block: 'center' })
            if (!this.currentHoverCell) return

            const colIndex = isNaN(parseInt(this.currentHoverCell.colIndex)) ? this.shiftStartSelect.colIndex : this.currentHoverCell.colIndex
            this.currentHoverCell = { ...row, colIndex }
            this.shiftStartSelect = { ...this.currentHoverCell }
            // this.selectedRows = this.shiftStartSelect ? [this.shiftStartSelect] : []
            // this.currentCell = this.currentColumns[colIndex]
            this.currentCell = this.localSelectedColumns[colIndex]
            this.$emit('currentRow', row)
        },

        localScrollToColumn (row, currentColIndex) {
            this.currentHoverCell = { ...row, colIndex: currentColIndex }
            setTimeout(() => this.rootElement.scrollLeft = this.currentHoverCell.colIndex <= 1 ? 0 : ((this.currentHoverCell.colIndex - 1) * 180), 0)
            this.localRemoveCellSelection()
        },

        localBulkSelectUp () {
            if (_.size(this.selectedRows) === 0) {
                this.selectedRows = this.shiftStartSelect ? [this.shiftStartSelect] : []
            }

            const lastSelectedOrder = this.selectedRows.slice(-1)[0]._order
            const order = lastSelectedOrder <= this.shiftStartSelect._order ? lastSelectedOrder - 1 : lastSelectedOrder
            if (order < 0) return

            this.localSelectRowWithShiftArrow(order)
        },

        localBulkSelectDown () {
            if (_.size(this.selectedRows) === 0) {
                this.selectedRows = this.shiftStartSelect ? [this.shiftStartSelect] : []
            }

            const lastSelectedOrder = this.selectedRows.slice(-1)[0]._order
            const order = lastSelectedOrder >= this.shiftStartSelect._order ? lastSelectedOrder + 1 : lastSelectedOrder
            if (order >= _.size(this.orderedListItems)) return

            this.localSelectRowWithShiftArrow(order)
        },

        localSelectRowWithShiftArrow (order) {
            const hasIndex = this.selectedRows.findIndex(i => i._order === order)
            const rowItem = this.orderedListItems.find(i => i._order === order)
            const element = this.componentRefs[`refRow-${rowItem._order >= 1 ? rowItem._order - 1 : 0 }`][0]
            element.scrollIntoView({ block: 'center' })

            if (hasIndex === -1) this.selectedRows.push(rowItem)
            if (hasIndex !== -1 && _.size(this.selectedRows) > 1) this.selectedRows.splice(hasIndex, 1)
        },

        localAddRowForEdit (item, colIndex = 0) {
            this.currentEditCell = { ...item, colIndex }
            this.currentHoverCell = { ...item, colIndex }
            this.shiftStartSelect = { ...this.currentEditCell }
            this.currentFocusedRow = { ...item, colIndex }
            setTimeout(() => {
                const input = this.$el.querySelector('.c-autofocus')
                if (input) input.focus()
            }, 50)
        },

        localEditCell (evt, row, colIndex, col) {
            evt.preventDefault()
            evt.stopPropagation()
            if (!this.canUpdate) return

            if (evt.metaKey || evt.ctrlKey) return this.localClickSelectRow(row)
            if (evt.shiftKey === true) return this.localSelectRowHoldingShift(row)

            const bulkSelectHasRow = this.selectedRows.findIndex(i => i.id === row.id)
            if (bulkSelectHasRow === -1) this.localClearSelection()

            if (!this.noDirectEditCols.includes(col.slug)) this.localAddRowForEdit(row, colIndex)
            else this.localTriggerIndirectEdit(row, col)

            this.$emit('currentRow', row)
        },

        localTriggerIndirectEdit (row, col) {
            this.$emit('openDialog', { item: row, column: col.slug })
        },

        async localSelectRowHoldingShift (row) {
            if (!this.shiftStartSelect) {
                this.shiftStartSelect = { ...row }
                this.currentHoverCell = { ...row }
                this.selectedRows = this.shiftStartSelect ? [this.shiftStartSelect] : []
                return true
            }

            this.selectedRows = []
            this.shiftEndSelect = { ...row }

            const startIndex = this.shiftStartSelect._order
            const endIndex = this.shiftEndSelect._order
            if (startIndex <= endIndex) {
                for (let index = startIndex; index <= endIndex; index++) {
                    const rowItem = this.orderedListItems[index]
                    const hasIdIndex = this.selectedRows.findIndex(i => i.id === rowItem.id)
                    if (hasIdIndex === -1) this.selectedRows.push(rowItem)
                }
            }

            if (startIndex >= endIndex) {
                for (let index = startIndex; index >= endIndex; index--) {
                    const rowItem = this.orderedListItems[index]
                    const hasIdIndex = this.selectedRows.findIndex(i => i.id === rowItem.id)
                    if (hasIdIndex === -1) this.selectedRows.push(rowItem)
                }
            }
        },

        async localRemoveIfSelected (row, milestone) {
            const index = this.selectedRows.findIndex(i => i.rowId === row.id && i.milestone.id === milestone.id)
            if (index !== -1) {
                this.selectedRows.splice(index, 1)
                return { duplicate: true }
            }
            return { duplicate: false }
        },

        localClearSelection () {
            this.selectedRows = []
            this.currentEditCell = {}
        },

        localIsCellEdited (rOrder, cIndex) {
            const { colIndex, _order } = this.currentEditCell || {}
            return rOrder === _order && colIndex === cIndex
        },

        localIsRowSelected (id) {
            // return this.currentHoverCell && this.currentHoverCell._order === order
            // const index = this.selectedRows.findIndex(i => i._order === order)
            const index = this.selectedRows.findIndex(i => i.id === id)
            return index !== -1 || (_.size(this.selectedRows) === 0 && this.currentEditCell && this.currentEditCell.id === id)
        },

        localGetTasks (milestone_id, field = 'list') {
            const list = this.orderedListItems.filter(i => i.milestone_id === milestone_id)
            const props = { list, length: list.length }

            return props[field]
        },

        localCheckScroll (evt) {
            // if (!this.rootElement) return

            const left = this.rootElement.scrollLeft
            this.scrolledLeft = !!(left > 0)
            // if (!_.size(this.selectedRows)) {
            //     this.localOnEscape()
            // }
        },

        localToggleColumn (colItem) {
            const index = this.currentColumns.findIndex(i => i.slug === colItem.slug)
            this.$set(this.currentColumns[index], 'selected', !colItem.selected)
            this.localFetchRefs()
            this.$nextTick(() => {
                if (colItem.selected) this.rootElement.scrollLeft = this.rootElement.scrollWidth - (this.rootElement.clientWidth + 200)
                else this.rootElement.scrollLeft = this.rootElement.scrollWidth + 250
            })
            this.localEmitUpdateCols()
        },

        localEmitUpdateCols () {
            this.$emit('updateColumns', this.currentColumns.filter(i => i.slug !== 'add-col'))
        },

        localHideColumn (colItem) {
            const index = this.currentColumns.findIndex(i => i.slug === colItem.slug)
            if (index !== -1) this.$set(this.currentColumns[index], 'selected', false)
        },

        localCellMenuAction (menuDir, colIndex) {
            const lastIndex = _.size(this.currentColumns) - 2
            let newIndex = 0
            if (menuDir === 'move-right' && colIndex < lastIndex) newIndex = colIndex + 1
            if (menuDir === 'move-left' && colIndex > 0) newIndex = colIndex - 1

            const removedEl = this.currentColumns.splice(colIndex + 1, 1)
            this.currentColumns.splice(newIndex + 1, 0, removedEl[0])
        },

        /* TODO: Implement in v2 */
        // localAllColSelected () {
        //     return this.currentColumns.filter(i => i.slug !== 'add-col').every(i => i.selected === true)
        // },

        localToggleAllColumn (value) {
            this.currentColumns.forEach(col => col.selected = !!(value))
        },

        localEmitCreate (item, order, module, callback, type = null) {
            this.$emit('create', { item, order, _module: module, callback, _type: type })
        },

        localAddMilestone (mIndex) {
            clearTimeout(this.addMilestoneTimeout)
            const newMilestone = { id: uuidv4(), title: 'Untitled Milestone' }
            this.itemRanges[newMilestone.id] = { startIndex: 0, endIndex: 4 }
            this.localEmitCreate(newMilestone, mIndex + 1, 'milestone', this.localMilestonAfterCreate, 'exact_order')
        },

        localMilestonAfterCreate (mIndex) {
            this.$nextTick(() => {
                this.addedMilestoneIndex = mIndex
                // const element = this.componentRefs['refMilestone-' + (mIndex)]
                // const elementInput = this.componentRefs['refMilestone-input-' + (mIndex)]
                // element[0].scrollIntoView({ behavior: 'smooth' })
                // setTimeout(() => elementInput[0].focus(), 500)
                this.addMilestoneTimeout = setTimeout(() => {
                    this.addedMilestoneIndex = null
                }, 1000)
            })
        },

        localOpenViewForEdit (evt, item) {
            evt.stopPropagation()
            evt.preventDefault()

            this.$emit('editItem', item)
        },

        localCalcLeft (index, prev, after, totalLength) {
            const unit = 'px'
            if (index === 0) return 0 + unit
            if (index === totalLength - 1) return ((totalLength - 1) * 200) + unit

            return (index * 200) + unit
        },

        localDragOver (evt) {
            // const hoveredElPos = (this.currentScrollCellPos !== null && this.currentScrollCellPos === 0)
            // console.log(this.$el.querySelector('.c-right-hover-strip') === evt.target, evt)
            // this.localDragOverRightStrip()
            // if (hoveredElPos && this.rootElement.scrollLeft > 0) {
            //     this.rootElement.scrollLeft = this.rootElement.scrollLeft - 2
            // }
        },

        localDragOverRightStrip (evt) {
            // return null
            // if (this.currentDragHeaderEl) {
            //     console.log('RIGHT: ', this.$el.querySelector('.c-right-hover-strip').contains(this.currentDragHeaderEl.target), evt)
            // }
        },

        localDropRightStrip (evt) {},

        localDragEnter (evt) {
            this.currentScrollCellPos = parseInt(evt.target.closest('.c-magic-table__header-cell').dataset.colPosition)
            if (this.currentScrollCellPos !== 0) evt.target.style.backgroundColor = 'rgba(164, 236, 239, 1)'
        },

        localDragLeave (evt) {
            evt.target.style.backgroundColor = ''
        },

        localDragEnd (evt) {
            evt.target.style.backgroundColor = ''
        },

        localDragStart (evt, item) {
            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'
            evt.dataTransfer.setData('colId', item.slug)
            this.currentDragHeaderEl = evt
        },

        localDragDrop (evt, newCol) {
            evt.stopPropagation()
            const colId = evt.dataTransfer.getData('colId')
            const colIndex = this.currentColumns.findIndex(i => i.slug === colId)
            const newIndex = this.currentColumns.findIndex(i => i.slug === newCol.slug)
            const removedEl = this.currentColumns.splice(colIndex, 1)
            this.currentColumns.splice(newIndex, 0, removedEl[0])
            evt.target.style.backgroundColor = ''
            this.localNonStickCols = _.cloneDeep(this.localNonStickCols)
            this.currentDragHeaderEl = null
        },

        localGetBorder (col, index) {
            if (_.size(this.localSelectedColumns) === 1) return { borderLeft: '1px solid rgba(0,0,0,0.1) !important' }
            if (col.slug === 'add-col' || index === this.localSelectedColumns.length - 1) return { borderRight: '0px' }
            return { borderRight: '1px solid rgba(0,0,0,0.1) !important' }
        },

        localIsCellClicked (row, colIndex) {
            if (this.currentEditCell && !this.currentEditCell.id) return false
            return this.currentEditCell && this.currentEditCell.id === row.id && this.currentEditCell.colIndex === colIndex
        },

        localTrackComponentWidth () {
            setTimeout(() => {
                this.componentWidth = this.$el.clientWidth + 'px'
            }, 20);
        },

        localRemoveCellSelection () {
            this.currentEditCell = {}
        },

        localIsRowHovered (rOrder) {
            const {  _order } = this.currentHoverCell || {}
            return rOrder === _order
        },

        localIsCellHovered (rOrder, cIndex) {
            const { colIndex, _order } = this.currentHoverCell || {}
            return (rOrder === _order) && (colIndex === cIndex)
        },

        localMouseOverWrap (evt, item) {
            this.wrapHoverElementId = item.id
        },

        localMouseLeaveWrap (evt) {
            this.wrapHoverElementId = null
        },

        localMouseOverItem (evt, item) {
            this.itemHoverElementId = item.id
        },

        localMouseLeaveItem (evt) {
            this.itemHoverElementId = null
        },

        // TODO: v2 -> Virtual loader to increase page performance
        localIsVisible (key) {
            // if (!this.itemRanges[key]) return

            // const { startIndex, endIndex } = this.itemRanges[key] || {}
            // if (!isNaN(parseInt(startIndex))) this.$set(this.itemRanges[key], 'startIndex', startIndex + 1)
            // if (!isNaN(parseInt(endIndex))) this.$set(this.itemRanges[key], 'endIndex', endIndex + 10)
        },

        localMilestoneDragStart (evt) {
            this.milestoneDragStarted = true
            // evt.dataTransfer.setDragImage(new Image(), 0, 0);
        },

        localToggleMilestone (milestoneId) {
            const index = this.toggledMilestones.indexOf(milestoneId)
            index !== -1 ? this.toggledMilestones.splice(index, 1) : this.toggledMilestones.push(milestoneId)

            this.$emit('onAccordianToggle', this.toggledMilestones)
            this.localOrderItems()
            this.localAttachMouseHandlers()
        },

        localIsToggledOn (milestoneId) {
            const index = this.toggledMilestones.indexOf(milestoneId)
            return index !== -1
        },

        localViewNoMilestoneHeader (milestone) {
            return this.localIsNoMilestone(milestone) && (_.size(this.localOrderedItems) > 1)
        },

        localIsNoMilestone (milestone) {
            return milestone.id === 'no-milestone'
        },

        localSelectAllToggle (value) {
            this.selectedRows = value ? this.orderedListItems : []
        },

        localExpandAll () {
            this.toggledMilestones = []
        },

        localCollapseAll () {
            this.toggledMilestones = this.wrapItems.map(i => i.id)
        },

        // Tasks reorder
        localTaskDragOver (evt) {
            this.localAddBgToCellOnDrag(evt, true)
        },

        localTaskDragEnter (evt) {
            this.localAddBgToCellOnDrag(evt, true)
        },

        localTaskDragLeave (evt) {
            this.localAddBgToCellOnDrag(evt)
        },

        localTaskDragEnd (evt) {
            this.taskDragStart = false
            this.localAddBgToCellOnDrag(evt)
        },

        localTaskDragStart (evt, task, milestoneId) {
            this.taskDragStart = true
            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'
            evt.dataTransfer.setData('task', JSON.stringify(task))
            evt.dataTransfer.setData('taskId', task.id)
            evt.dataTransfer.setData('milestoneId', milestoneId)
        },

        localTaskDragDrop (evt, newTask, newMilestoneId, rowIndex) {
            evt.stopPropagation()
            const oldTask = JSON.parse(evt.dataTransfer.getData('task'))
            const oldTaskId = evt.dataTransfer.getData('taskId')
            const oldMilestoneId = evt.dataTransfer.getData('milestoneId')
            if (oldMilestoneId !== newMilestoneId) return

            const list = this.items
            const newMilestoneIndex = this.items.findIndex(i => i.id === newMilestoneId)
            const oldMilestoneIndex = this.items.findIndex(i => i.id === oldMilestoneId)
            const oldTaskIndex = list[oldMilestoneIndex].tasks.findIndex(i => i.id === oldTaskId)
            const removedEl = list[oldMilestoneIndex].tasks.splice(oldTaskIndex, 1)
            list[newMilestoneIndex].tasks.splice(rowIndex, 0, removedEl[0])

            this.$emit('reorder', {
                type: 'task',
                newMilestoneId,
                oldMilestoneId,
                oldTask,
                newTask,
                newIndex: rowIndex,
                list: list[newMilestoneIndex].tasks,
                milestoneId: newMilestoneId !== 'no-milestone' ? newMilestoneId : null,
            })
            this.taskDragStart = false
            this.localAddBgToCellOnDrag(evt)
        },

        localAddBgToCellOnDrag (evt, color = false) {
            Array.from(evt.target.closest('.c-magic-table__row').querySelectorAll('.c-magic-table__cell'))
                .forEach(i => {
                    i.style.backgroundColor = !color ? '' : 'rgba(164, 236, 239, 1)'
                })
        },

        localDragStartCols (evt, item, index) {
            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'
            evt.dataTransfer.setData('column', JSON.stringify(item))
            evt.dataTransfer.setData('index', JSON.stringify(index))
            evt.target.closest('.js-draggable-list-item').classList.add('c-dragging')
        },

        localDragDropCols (evt, newItem, newIndex) {
            evt.preventDefault()
            const oldIndex = JSON.parse(evt.dataTransfer.getData('index'))
            const removedItem = this.currentColumns.splice(oldIndex, 1)
            this.currentColumns.splice(newIndex, 0, removedItem[0])
            evt.target.closest('.js-draggable-list-item').classList.remove('c-dragging')
            // evt.dataTransfer.clearData()
            this.localEmitUpdateCols()
        },

        localDragEnterCols (evt) {
            evt.preventDefault()
        },

        localDragOverCols (evt) {
            evt.preventDefault()
            evt.target.closest('.js-draggable-list-item').classList.add('c-dragging')
        },

        localDragLeaveCols (evt) {
            evt.preventDefault()
            evt.target.closest('.js-draggable-list-item').classList.remove('c-dragging')
        },

        localDragEndCols (evt) {
            evt.preventDefault()
            evt.target.closest('.js-draggable-list-item').classList.remove('c-dragging')
        },

        localOrderItems () {
            let count = 0
            this.orderedListItems = this.items
                .map(milestone => {
                    return milestone.tasks.map(task => {
                        task._order = count
                        count = count + 1
                        return task
                    })
                })
                .reduce((prev, current) => {
                    prev = [...prev, ...current];
                    return prev
                }, [])
        },
    }
}
</script>

<style lang="scss" scoped>
.c-add-section {
    &__bar {
        visibility: hidden;
    }

    &:hover {
        .c-add-section__bar {
            visibility: visible !important;
        }
    }
}

.c-input-element {
    transition: 0.25s background-color ease-in-out;

    &:focus {
        background-color: rgba($color: #f6f7f9, $alpha: 1.0);
        transition: 0.25s background-color ease-in-out;
    }
}

.c-magic-table {
    &__row {
        &:hover {
            .c-magic-table__cell {
                background-color: #effaff;
            }

            .c-magic-table__row-add-col {
                &::after {
                    content: '';
                    position: absolute;
                    right: calc(-100% + 200px);
                    top: 0px;
                    width: 100%;
                    height: 100%;
                    background: rgb(241, 250, 254);
                }
            }
        }
    }

    &__cell {
        background-color: #fff;
    }

    &__header-cell-menu {
        display: none !important;
    }

    &__header-cell {
        &:hover {
            .c-magic-table__header-cell-menu {
                display: block !important;
            }
        }
    }

    .c-detail-view-btn {
        display: none !important;
    }

    .c-task-drag-handle {
        display: none !important;
    }

    &__cell-sticky {
        &:hover {
            .c-detail-view-btn {
                display: flex !important;
            }

            .c-task-drag-handle {
                display: inline-flex !important;
            }
        }
    }
}

.c-cell-clicked {
    background-color: #d6f8ff !important;
}

.c-cell-keyed {
    background-color: #e1f5fe !important;
}

.c-cell-row-hovered {
    background-color: #f8f8f8 !important;
}

.js-sortable-chosen {
    border-color: transparent !important;
    .hide-on-drag {
        visibility: hidden !important;
    }
}
</style>

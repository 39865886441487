<template>
    <div>
        <div v-if="isLoading">
            <div class="u-flex" style="column-gap: 1.5rem; margin-top: 20px">
                <loader-template width="500" height="40" class="u-rounded-corners"></loader-template>
                <loader-template width="500" height="40" class="u-rounded-corners-xl"></loader-template>
                <loader-template width="500" height="40" class="u-rounded-corners-xl"></loader-template>
            </div>
            <div class="u-flex" style="column-gap: 1.5rem; margin-top: 24px">
                <loader-template width="500" height="40" class="u-rounded-corners"></loader-template>
                <loader-template width="500" height="40" class="u-rounded-corners-xl"></loader-template>
                <loader-template width="500" height="40" class="u-rounded-corners-xl"></loader-template>
            </div>
        </div>
        <!-- @click="localClearDeletedFields() -->
        <div v-else>
            <a-sheet class="transparent">
                <span class="md-body-1 body--text text--lighten-1 mb-3 d-inline-block font-weight-medium">Filters</span>
                <p v-if="(moduleItem.original && !moduleItem.original.length) || isLoading" class="mb-0 md-body-2 body--text text--lighten-2">No filter conditions are applied to this tile. You can start configuring the tile by clicking on the Add Condition button.</p>
            </a-sheet>
            <div v-if="deleted_items && deleted_items.length" class="amber lighten-5 px-6 pt-4 pb-1 u-rounded-corners-lg my-4" :class="[moduleItem.original && !moduleItem.original.length || isLoading ? 'mt-4': 'mt-0']">
                <div class="d-flex justify-space-between">
                    <div class="yellow--text text--darken-4 font-weight-medium">
                        <a-icon color="#FB8C00" size="19" class="mr-1 pl-1">warning</a-icon> Invalid conditions
                    </div>
                    <div style="cursor:pointer" class="u-rounded-corners-lg font-weight-medium grey--text text--darken-2" @click="localClearDeletedFields()">
                        <a-icon color="#616161" size="20" class="pl-1">cancel</a-icon> Clear
                    </div>  
                </div>   
                <p class="md-subtitle-1 brown--text text--darken-1 font-weight-medium pt-2">The specified conditions are unavailable since the associated custom fields no longer exist. This reference is temporary and it will be removed after updating the tile configuration.</p>  
            </div>
            <div v-if="disabled_fields" class="u-flex-center-y amber lighten-5 px-6 py-4 u-rounded-corners-lg mb-4">
                <a-icon color="yellow darken-3" size="20">error</a-icon>
                <h3 class="md-subtitle-1 font-weight-medium brown--text text--darken-2 ml-2">
                    The selected field is disabled. Please reconfigure the conditions!
                </h3>
            </div>
            <div class="mt-2 mb-12">
                <!-- Group loop -->
                <table class="u-wfull" style="border-collapse: collapse;" v-for="(config_filter, group_index) in moduleItem.original" :key="group_index">
                    <!-- OR divider -->
                    <tr v-if="group_index !== 0">
                        <td colspan="100">
                            <a-sheet color="grey lighten-2" height="1" class="u-wfull u-relative my-7">
                                <span class="u-absolute md-body-2 font-weight-medium body--text text--lighten-2 d-inline-block pa-1 px-2 grey lighten-5" style="left: 50%; top: 50%; transform: translate(-50%, -50%); z-index: 1">OR</span>
                            </a-sheet>
                        </td>
                    </tr>

                    <!-- Single filter row -->
                    <tr  class="mb-3" v-for="(filter, index) in config_filter.filters" :key="filter.id">
                        <!-- IF / AND -->
                        <td class="text-left pb-4" style="min-width: 36px;">
                            <a-sheet :class="['transparent text-center font-weight-medium md-caption-2 indigo--text text--darken-1']">{{ index === 0 ? 'IF' : 'AND' }}</a-sheet>
                        </td>
                        <!-- Property -->
                        <td class="text-left pb-4 px-2" style="min-width: 256px">
                            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('filters_entity_id_' + filter.id)">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">
                                        <v-autocomplete
                                            v-model="filter.entity_property_id"
                                            :items="local_entity_list"
                                            item-text="label"
                                            item-value="id"
                                            placeholder="Select Field"
                                            class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                            :class="[{ 'c-wiggle-short u-border-error': local_get_error('filters_entity_id_' + filter.id)}, { 'c-wiggle-short u-border-disable': local_check_disable_item(filter.entity_property_id)}]"
                                            item-color="indigo darken-2"
                                            background-color="white"
                                            :menu-props="{ rounded: 'lg', nudgeBottom: '4px', closeOnClick: true }"
                                            @input="local_tile_value_list(filter.entity_property_id, filter, group_index)"
                                            key="property-field"                                            
                                            solo flat hide-details
                                        >
                                            <template #item="{ item }">                                               
                                                <div style="width: 378px; display: flex; justify-content: space-between">
                                                    <div class="u-flex-center-y" >
                                                        <a-icon size="18" color="grey darken-2" class="mr-3" :outlined="local_get_type_icon(item.key).outlined">{{ local_get_type_icon(item.key).text }}</a-icon>
                                                        <span>{{ item.label }}</span>
                                                    </div>
                                                    <span 
                                                        style="padding: 2px 8px; fontSize: 13px; fontWeight: 500" 
                                                        class="d-inline-flex align-center mr-2 u-rounded-corners-full u-cursor-pointer blue-grey--text blue-grey lighten-5" 
                                                        v-if="local_check_disable_item(item.id)"
                                                    >
                                                        Disabled
                                                    </span>
                                                </div>                                             
                                            </template>
                                            <template #prepend-inner>
                                                <a-icon size="18" color="grey darken-2" class="mr-1" :outlined="local_get_icon(filter.id, 'outlined')">{{ local_get_icon(filter.id, 'text') }}</a-icon>
                                            </template>
                                            <template #append>
                                                <a-icon size="20" color="blue-grey" class="mr-1" v-show="local_check_disable_item(filter.entity_property_id)">block</a-icon>
                                                <a-icon size="20" color="grey darken-2" class="mr-1" v-if="!filter.entity_property_id">arrow_drop_down</a-icon>
                                                <a-icon size="18" color="grey darken-2" class="mr-1" v-else @click="local_clear_property(filter, group_index)">clear</a-icon>
                                            </template>
                                        </v-autocomplete>
                                    </div>
                                </template>
                                <span>{{ local_get_error('filters_entity_id_' + filter.id, 'message') }}</span>
                            </a-tooltip>
                        </td>

                        <!-- Operator -->
                        <td class="text-left pb-4 px-2" style="min-width: 256px;">
                            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('filters_operator_' + filter.id)">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">
                                        <v-autocomplete
                                            v-model="filter.operator"
                                            :items="local_tile_entity_operators(filter.entity_property_id)"
                                            item-text="label"
                                            item-value="key"
                                            placeholder="Select Operator"
                                            class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                            :class="[{ 'c-wiggle-short u-border-error': local_get_error('filters_operator_' + filter.id) }]"
                                            item-color="indigo darken-2"
                                            background-color="white"
                                            :menu-props="{ rounded: 'lg', nudgeBottom: '4px', closeOnClick: true }"
                                            @input="local_change_operator(filter.operator, filter, group_index)"
                                            key="operator-field"
                                            solo flat hide-details
                                        >
                                            <template #append>
                                                <a-icon size="20" color="grey darken-2" class="mr-1" v-if="!filter.operator">arrow_drop_down</a-icon>
                                                <a-icon size="18" color="grey darken-2" class="mr-1" v-else @click="local_change_operator(filter.operator, filter, group_index, true)">clear</a-icon>
                                            </template>
                                        </v-autocomplete>
                                    </div>
                                </template>
                                <span>{{ local_get_error('filters_operator_' + filter.id, 'message') }}</span>
                            </a-tooltip>
                        </td>

                        <!-- Value -->
                        <td v-if="['empty', 'not-empty'].includes(filter.operator)" class="text-left pb-4 px-2" style="min-width: 374px; max-width: 374px">
                            <a-sheet class="grey lighten-4 body--text text--lighten-3 font-italic px-3 u-flex-center-y u-rounded-corners-lg" height="48">
                                No value required
                            </a-sheet>
                        </td>

                        <template v-else>
                            <template v-if="local_get_data_type(filter.entity_property_id) === 'date'">
                                <td v-if="local_operator_is_between(filter.operator, filter.entity_property_id)" class="text-left pb-4 px-2" style="min-width: 374px; max-width: 374px">
                                    <div class="u-flex-center-y u-relative" style="">
                                        <a-sheet width="187" class="u-wfull u-rounded-corners-lg u-border-transparent u-flex-center-y u-relative pr-2 mr-2 u-shadow" :class="[{ 'c-wiggle-short u-border-error': local_get_error('filters_value_' + filter.id) }]">
                                            <vc-date-picker v-model="filter.value" @input="local_change_filter_value_fields(filter, 'value')" class="d-block u-wfull u-relative" :max-date="filter.value_alt" :model-config="modelConfig" :is-required="!!(filter.value)">
                                                <template v-slot="{ togglePopover }">
                                                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('filters_value_' + filter.id)">
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on" class="u-wfull u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="['empty', 'not-empty'].includes(filter.operator) ? {} : togglePopover({ placement: 'bottom-start' })">
                                                                <div class="md-body-2 grey--text text--darken-3 pa-3 pl-3 u-flex-center-y">
                                                                    <a-icon size="18" color="grey darken-1" class="mr-2">date_range</a-icon>
                                                                    <span>{{ filter.value ? local_format_date(filter.value) : 'Select Date' }}</span>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <span>{{ local_get_error('filters_value_' + filter.id, 'message') }}</span>
                                                    </a-tooltip>
                                                </template>
                                            </vc-date-picker>
                                            <a-spacer></a-spacer>
                                            <a-icon v-if="filter.value" @click="local_change_filter_value_fields(filter, 'value', true)" class="c-date-picker--clear" size="18">clear</a-icon>
                                        </a-sheet>
                                        <a-sheet width="187" class="u-wfull u-rounded-corners-lg u-border-transparent u-flex-center-y u-relative pr-2 ml-2 u-shadow" :class="[{ 'c-wiggle-short u-border-error': local_get_error('filters_value_alt_' + filter.id) }]">
                                            <vc-date-picker v-model="filter.value_alt" @input="local_change_filter_value_fields(filter, 'value_alt')" class="d-block u-wfull u-relative" style="z-index: 2" :min-date="filter.value" :model-config="modelConfig" :is-required="!!(filter.value_alt)">
                                                <template v-slot="{ togglePopover }">
                                                    <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" style="z-index: 10 !important" :disabled="!local_get_error('filters_value_alt_' + filter.id)">
                                                        <template v-slot:activator="{ on }">
                                                            <div v-on="on" class="u-wfull u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="['empty', 'not-empty'].includes(filter.operator) ? {} : togglePopover({ placement: 'bottom-start' })">
                                                                <div class="md-body-2 grey--text text--darken-3 pa-3 pl-3 u-flex-center-y">
                                                                    <a-icon size="18" color="grey darken-1" class="mr-2">date_range</a-icon>
                                                                    <span>{{ filter.value_alt ? local_format_date(filter.value_alt) : 'Select Date' }}</span>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <span>{{ local_get_error('filters_value_alt_' + filter.id, 'message') }}</span>
                                                    </a-tooltip>
                                                </template>
                                            </vc-date-picker>
                                            <a-spacer></a-spacer>
                                            <a-icon v-if="filter.value_alt" @click="local_change_filter_value_fields(filter, 'value_alt', true)" class="c-date-picker--clear" size="18">clear</a-icon>
                                        </a-sheet>
                                    </div>
                                </td>
                                <td v-else class="text-left pb-4 px-2" style="min-width: 374px; max-width: 374px">
                                    <a-sheet class="u-wfull u-rounded-corners-lg u-border-transparent u-flex-center-y u-relative pr-2 mr-2 u-shadow" :class="[{ 'c-wiggle-short u-border-error': local_get_error('filters_value_' + filter.id) }]" style="">
                                        <vc-date-picker v-model="filter.value" @input="local_change_filter_value_fields(filter, 'value')" class="d-block u-wfull" :model-config="modelConfig" :is-required="!!(filter.value)">
                                            <template v-slot="{ togglePopover }">
                                                <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('filters_value_' + filter.id)">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on" class="u-wfull u-flex align-stretch u-cursor-pointer u-rounded-corners-lg grey--text text--darken-2" @click="['empty', 'not-empty'].includes(filter.operator) ? {} : togglePopover({ placement: 'bottom-start' })">
                                                            <div class="md-body-2 grey--text text--darken-3 pa-3 pl-3 u-flex-center-y">
                                                                <a-icon size="18" color="grey darken-1" class="mr-2">date_range</a-icon>
                                                                <span>{{ filter.value ? local_format_date(filter.value) : 'Date' }}</span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <span>{{ local_get_error('filters_value_' + filter.id, 'message') }}</span>
                                                </a-tooltip>
                                            </template>
                                        </vc-date-picker>
                                        <a-spacer></a-spacer>
                                        <a-icon v-if="filter.value" @click="local_change_filter_value_fields(filter, 'value', true)" class="c-date-picker--clear" size="18">clear</a-icon>
                                    </a-sheet>
                                </td>
                            </template>

                            <template v-else>
                                <td class="text-left pb-4 px-2" style="min-width: 374px; max-width: 374px">
                                    <div v-if="local_operator_is_between(filter.operator, filter.entity_property_id)" class="u-flex-center-y">
                                        <a-sheet width="187" class="transparent mr-2">
                                            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('filters_value_' + filter.id)">
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <v-text-field
                                                            v-model="filter.value"
                                                            placeholder="Enter Value"
                                                            class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg u-shadow"
                                                            :class="[{ 'c-wiggle-short u-border-error': local_get_error('filters_value_' + filter.id) }]"
                                                            item-color="indigo darken-2"
                                                            background-color="white"
                                                            key="value-btw-text-field"
                                                            @input="local_change_filter_value_fields(filter, 'value')"
                                                            solo flat hide-details
                                                        >
                                                            <template #append>
                                                                <a-icon size="18" color="grey darken-2" class="mr-1" v-if="filter.value" @click="local_change_filter_value_fields(filter, 'value', true)">clear</a-icon>
                                                            </template>
                                                            <template #prepend-inner>
                                                                <span class="grey--text text--darken-1 md-subtitle-1 font-weight-medium" style="margin-left: 2px; margin-right: 6px;" v-if="local_get_entity_key(filter.entity_property_id) === 'deal_amount'">
                                                                    {{ local_currency_symbol }}
                                                                </span>
                                                                <a-icon size="18" color="grey darken-2" class="mr-1" v-else :outlined="local_get_icon(filter.id, 'outlined')">{{ local_get_icon(filter.id, 'text') }}</a-icon>
                                                            </template>
                                                        </v-text-field>
                                                    </div>
                                                </template>
                                                <span>{{ local_get_error('filters_value_' + filter.id, 'message') }}</span>
                                            </a-tooltip>
                                        </a-sheet>
                                        <a-sheet width="187" class="transparent ml-2">
                                            <a-tooltip bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('filters_value_alt_' + filter.id)">
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <v-text-field
                                                            v-model="filter.value_alt"
                                                            placeholder="Enter Value"
                                                            class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                                            :class="[{ 'c-wiggle-short u-border-error': local_get_error('filters_value_alt_' + filter.id) }]"
                                                            item-color="indigo darken-2"
                                                            background-color="white"
                                                            key="value-alt-btw-text-field"
                                                            @input="local_change_filter_value_fields(filter, 'value_alt')"
                                                            solo flat hide-details
                                                        >
                                                            <template #append>
                                                                <a-icon size="18" color="grey darken-2" class="mr-1" v-if="filter.value_alt" @click="local_change_filter_value_fields(filter, 'value_alt', true)">clear</a-icon>
                                                            </template>
                                                            <template #prepend-inner>
                                                                <span class="grey--text text--darken-1 md-subtitle-1 font-weight-medium" style="margin-left: 2px; margin-right: 6px;" v-if="local_get_entity_key(filter.entity_property_id) === 'deal_amount'">
                                                                    {{ local_currency_symbol }}
                                                                </span>
                                                                <a-icon size="18" color="grey darken-2" class="mr-1" v-else :outlined="local_get_icon(filter.id, 'outlined')">{{ local_get_icon(filter.id, 'text') }}</a-icon>
                                                            </template>
                                                        </v-text-field>
                                                    </div>
                                                </template>
                                                <span>{{ local_get_error('filters_value_alt_' + filter.id, 'message') }}</span>
                                            </a-tooltip>
                                        </a-sheet>
                                    </div>
                                    <template v-else>
                                        <a-tooltip v-if="local_filter_input_type(filter.entity_property_id) === 'dropdown'" bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('filters_value_' + filter.id)">
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on">
                                                    <v-autocomplete
                                                        v-model="filter.value"
                                                        :items="local_filter_value_list(filter.entity_property_id)"
                                                        item-text="name"
                                                        item-value="id"
                                                        :placeholder="localGetPlaceholder(filter)"
                                                        class="pa-0 u-border-transparent u-rounded-corners-lg u-shadow"
                                                        :class="[{ 'c-wiggle-short u-border-error': local_get_error('filters_value_' + filter.id) }]"
                                                        item-color="indigo darken-2"
                                                        background-color="white"
                                                        :menu-props="{ rounded: 'lg', nudgeBottom: '4px', closeOnClick: true }"
                                                        key="value-dropdown-field"
                                                        @input="local_change_filter_value_fields(filter, 'value')"
                                                        solo flat hide-details
                                                    >
                                                        <template #item="{ item }">
                                                            <template v-if="local_field_is_tag_type(filter.entity_property_id)">
                                                                <g-tags :text-range="20" :tag="item" :key="item.id" hide-clear-icon></g-tags>
                                                            </template>
                                                            <template v-else-if="item.type === 'competitor'">
                                                                <span class="md-caption d-inline-block py-1 px-2 mr-2 mt-1 mb-1 u-rounded-corners u-cursor-pointer" :style="{backgroundColor: '#2196f3', color: '#fff'}">
                                                                    {{ item.name | truncateText(14) }}
                                                                </span>
                                                            </template>
                                                            <span v-else :title="item.name">{{ item.name | truncateText(20) }}</span>
                                                        </template>
                                                        <template #selection="{ item }">
                                                            <template v-if="local_field_is_tag_type(filter.entity_property_id)">
                                                                <g-tags :text-range="20" :tag="item" :key="item.id" hide-clear-icon></g-tags>
                                                            </template>
                                                            <template v-else-if="item.type === 'competitor'">
                                                                <span class="md-caption d-inline-block py-1 px-2 mr-2 mt-1 mb-1 u-rounded-corners u-cursor-pointer" :style="{backgroundColor: '#2196f3', color: '#fff'}">
                                                                    {{ item.name | truncateText(14) }}
                                                                </span>
                                                            </template>
                                                            <span v-else :title="item.name">{{ item.name | truncateText(20) }}</span>
                                                        </template>
                                                        <template #prepend-inner>
                                                            <a-icon size="18" color="grey darken-2" class="mr-1" :outlined="local_get_icon(filter.id, 'outlined')">{{ local_get_icon(filter.id, 'text') }}</a-icon>
                                                        </template>
                                                        <template #append>
                                                            <a-icon size="20" color="grey darken-2" class="mr-1" v-if="!filter.value">arrow_drop_down</a-icon>
                                                            <a-icon size="18" color="grey darken-2" class="mr-1" v-else @click="local_change_filter_value_fields(filter, 'value', true)">clear</a-icon>
                                                        </template>
                                                    </v-autocomplete>
                                                </div>
                                            </template>
                                            <span>{{ local_get_error('filters_value_' + filter.id, 'message') }}</span>
                                        </a-tooltip>
                                        <a-tooltip v-else-if="local_check_custom_field_dropdown(filter.entity_property_id)" bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('filters_value_' + filter.id)">
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on">
                                                    <a-autocomplete
                                                            v-model="filter.value"
                                                            :items="local_get_custom_field_dropdown(filter.entity_property_id)" 
                                                            item-text="label"
                                                            item-value="value"                                                                                                                     
                                                            :placeholder="localGetPlaceholder(filter)"
                                                            class="pa-0 u-border-transparent u-shadow u-rounded-corners-lg"
                                                            :class="[{ 'c-wiggle-short u-border-error': local_get_error('filters_value_' + filter.id)}]"
                                                            item-color="indigo darken-2"
                                                            background-color="white"
                                                            :menu-props="{ rounded: 'lg', nudgeBottom: '4px', closeOnClick: true }"                                                            
                                                            @input="local_change_filter_value_fields(filter, 'value')"
                                                            solo flat hide-details
                                                        >
                                                        <template #prepend-inner>
                                                            <a-icon size="18" color="grey darken-2" class="mr-1" :outlined="true" >arrow_drop_down_circle</a-icon>
                                                        </template>
                                                        <template #append>
                                                            <a-icon size="20" color="grey darken-2" class="mr-1" v-if="!filter.value">arrow_drop_down</a-icon>
                                                            <a-icon size="18" color="grey darken-2" class="mr-1" v-else @click="local_change_filter_value_fields(filter, 'value', true)">clear</a-icon>
                                                        </template>
                                                    </a-autocomplete>
                                                </div>
                                            </template>
                                            <span>{{ local_get_error('filters_value_' + filter.id, 'message') }}</span>
                                        </a-tooltip>
                                        <a-tooltip v-else bottom content-class="c-tooltip-pointer c-tooltip-pointer--right" :disabled="!local_get_error('filters_value_' + filter.id)">
                                            <template v-slot:activator="{ on }">
                                                <div v-on="on">
                                                    <v-text-field
                                                        v-model="filter.value"
                                                        placeholder="Enter Value"
                                                        class="pa-0 u-border-transparent u-rounded-corners-lg u-shadow"
                                                        :class="[{ 'c-wiggle-short u-border-error': local_get_error('filters_value_' + filter.id) }]"
                                                        item-color="indigo darken-2"
                                                        background-color="white'"
                                                        key="value-text-field"
                                                        @input="local_change_filter_value_fields(filter, 'value')"
                                                        solo flat hide-details
                                                    >
                                                        <template #prepend-inner>
                                                            <span class="grey--text text--darken-1 md-subtitle-1 font-weight-medium" style="margin-left: 2px; margin-right: 6px;" v-if="local_get_entity_key(filter.entity_property_id) === 'deal_amount'">
                                                                {{ local_currency_symbol }}
                                                            </span>
                                                            <a-icon size="18" color="grey darken-2" class="mr-1" v-else :outlined="local_get_icon(filter.id, 'outlined')">{{ local_get_icon(filter.id, 'text') }}</a-icon>
                                                        </template>
                                                        <template #append>
                                                            <a-icon size="18" color="grey darken-2" class="mr-1" v-if="filter.value" @click="local_change_filter_value_fields(filter, 'value', true)">clear</a-icon>
                                                        </template>
                                                    </v-text-field>
                                                </div>
                                            </template>
                                            <span>{{ local_get_error('filters_value_' + filter.id, 'message') }}</span>
                                        </a-tooltip>
                                    </template>
                                </td>
                            </template>
                        </template>

                        <!-- Btn to remove row -->
                        <td class="text-left pb-4" style=" width: 16px;">
                            <a-tooltip bottom v-if="filter_delete_id === filter.id">
                                <template v-slot:activator="{ on }">
                                    <a-icon size="16" color="red darken-1" class="ml-2" v-on="on" @click="local_remove_filter_row(filter)">cancel</a-icon>
                                </template>
                                <span>Confirm Delete?</span>
                            </a-tooltip>
                            <a-icon size="16" v-else color="grey darken-1" class="ml-2" @click="local_confirm_filter_remove(filter.id, filter.entity_property_id)">cancel</a-icon>
                        </td>
                    </tr>

                    <!-- Deleted conditons in this group -->
                    <template v-if="deleted_items.length">
                        <tr v-for="(item, i) in deleted_items" :key="i">
                            <template v-if="item.group_id === config_filter.group_id">
                                <td class="text-left pb-4" style="min-width: 36px;">
                                    <a-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <a-icon size="24" color="yellow darken-3" class="ml-1">error</a-icon>
                                            </div>
                                        </template>
                                        <span>Invalid Condition</span>
                                    </a-tooltip>
                                </td>
                                <td class="text-left pb-4 px-2" style="min-width: 256px">
                                    <v-text-field
                                        :placeholder="item.field_name"
                                        disabled
                                        class="u-rounded-corners-lg u-border-light"
                                        flat solo hide-details
                                    >
                                        <template #prepend-inner>
                                            <a-icon size="18" color="grey ligthten-3" class="mr-1" :outline="local_get_deleted_fields_icons(item.field_type).outline">{{local_get_deleted_fields_icons(item.field_type).text}}</a-icon>
                                        </template>
                                    </v-text-field>
                                </td>
                                <td class="text-left pb-4 px-2" style="min-width: 256px">
                                    <v-text-field
                                        :placeholder="item.operator"
                                        disabled
                                        class="u-rounded-corners-lg u-border-light"
                                        flat solo hide-details
                                    >
                                    </v-text-field>
                                </td>
                                <td class="text-left pb-4 px-2" style="min-width: 256px">
                                    <v-text-field
                                        :placeholder="item.field_type === 'dropdown' ? item.field_label : item.field_value"
                                        disabled
                                        class="u-rounded-corners-lg u-border-light"
                                        flat solo hide-details
                                    >
                                        <template #prepend-inner>
                                            <a-icon size="18" color="grey ligthten-3" class="mr-1" :outline="local_get_deleted_fields_icons(item.field_type).outline">{{local_get_deleted_fields_icons(item.field_type).text}}</a-icon>
                                        </template>
                                    </v-text-field>
                                </td>
                                <td class="text-left pb-4" style=" width: 16px;">                       
                                </td>
                            </template>
                        </tr>
                    </template>

                    <!-- Add condition / group -->
                    <tr>
                        <td colspan="100" class="text-left" style="">
                            <div class="u-flex-center-y mt-2">
                                <a-btn depressed small rounded class="u-flex-center deep-purple darken-1 white--text" @click="local_add_filter(config_filter.group_id)">
                                    <a-icon size="16" color="white" class="u-icon-nudge-xs mr-1">add</a-icon>
                                    <span>Add Condition</span>
                                </a-btn>
                                <a-spacer></a-spacer>
                                <a-btn depressed small rounded class="u-flex-center primary lighten-1 white--text" @click="local_add_filter('new', group_index)">
                                    <a-icon size="16" color="white" class="u-icon-nudge-xs mr-1">add</a-icon>
                                    <span>Add Group</span>
                                </a-btn>
                                <a-tooltip bottom v-if="filter_delete_id === config_filter.group_id">
                                    <template v-slot:activator="{ on }">
                                        <a-icon size="16" color="red darken-1" class="ml-3" v-on="on" @click="local_remove_filter_row(config_filter, 'group')">delete</a-icon>
                                    </template>
                                    <span>Confirm Delete?</span>
                                </a-tooltip>
                                <a-icon size="16" color="grey darken-1" v-else class="ml-3" @click="local_confirm_filter_remove(config_filter.group_id, 'group')">delete</a-icon>
                            </div>
                        </td>
                    </tr>
                </table>

                <!-- Deleted groups -->
                <template v-if="modified_deleted_items && modified_deleted_items.length">
                    <table class="u-wfull mt-2" style="border-collapse: collapse;" v-for="(deleted_array, index) in modified_deleted_items" :key="index + 'filter'">
                        <tr v-if="index !== 0 || moduleItem.original.length !== 0">
                            <td colspan="100">
                                <a-sheet color="grey lighten-2" height="1" class="u-wfull u-relative my-7 mt-5">
                                    <span class="u-absolute md-body-2 font-weight-medium body--text text--lighten-2 d-inline-block pa-1 px-2 grey lighten-5" style="left: 50%; top: 50%; transform: translate(-50%, -50%); z-index: 1">OR</span>
                                </a-sheet>
                            </td>
                        </tr>
                        <tr v-for="(item, i) in deleted_array" :key="i+'item'">
                            <td class="text-left pb-4" style="min-width: 36px;">
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on">
                                            <a-icon size="24" color="yellow darken-3" class="ml-1">error</a-icon>
                                        </div>
                                    </template>
                                    <span>Invalid Condition</span>
                                </a-tooltip>
                            </td>
                            <td class="text-left pb-4 px-2" style="min-width: 256px">
                                <v-text-field
                                        :placeholder="item.field_name"
                                        disabled
                                        class="u-rounded-corners-lg u-border-light"
                                        flat solo hide-details
                                >
                                    <template #prepend-inner>
                                        <a-icon size="18" color="grey ligthten-2" class="mr-1" :outline="local_get_deleted_fields_icons(item.field_type).outline">{{local_get_deleted_fields_icons(item.field_type).text}}</a-icon>
                                    </template>
                                </v-text-field>
                            </td>
                            <td class="text-left pb-4 px-2" style="min-width: 256px">
                                <v-text-field
                                    :placeholder="item.operator"
                                    disabled
                                    class="u-rounded-corners-lg u-border-light"
                                    flat solo hide-details
                                ></v-text-field>
                            </td>
                            <td class="text-left pb-4 px-2" style="min-width: 374px; max-width: 374px">
                                <v-text-field
                                    :placeholder="item.field_type === 'dropdown' ? item.field_label : item.field_value"
                                    disabled
                                    class="u-rounded-corners-lg u-border-light"
                                    flat solo hide-details
                                >
                                    <template #prepend-inner>
                                        <a-icon size="18" color="grey ligthten-2" class="mr-1" :outline="local_get_deleted_fields_icons(item.field_type).outline">{{local_get_deleted_fields_icons(item.field_type).text}}</a-icon>
                                    </template>
                                </v-text-field>
                            </td>
                            <td class="text-left pb-4" style=" width: 16px;">                   
                            </td>
                        </tr>
                    </table>
                </template>

                <!-- Add condition -->
                <a-btn depressed small rounded class="u-flex-center deep-purple darken-1 white--text mt-5" v-if="moduleItem.original && !moduleItem.original.length" @click="local_add_filter('new')">
                    <a-icon size="16" color="white" class="u-icon-nudge-xs mr-1">add</a-icon>
                    <span>Add Condition</span>
                </a-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import EntityOperators from '@/helpers/helper-insights-entity-operators'

export default {
    props: {
        moduleItem: {
            type: Object,
            required: true
        },
        configErrors: {
            type: Array,
        },
        workspaceId: {
            type: String,
        },
        canUpdate: {
            type: Boolean | Number,
            default: true
        },
        isLoading: {
            type: Boolean | Number,
            default: false
        },
        selectedTile: {
            type: Object
        },
        deleted_items: {
            type: Array
        },
        disabled_fields: {
            type: Boolean
        }
    },

    data () {
        return {
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
            filter_delete_timeout: null,
            filter_delete_id: null,
            filter_type_icon: {},
            label_list: [],
            product_list: [],
            platform_list: [],
            filter_value_items: [
                'customer_id',
                'partner_id',
                'stage_id',
                'status',
                'health_color',
                'result_id',
                'workspace_id',
                'label_tag_id',
                'product_tag_id',
                'platform_tag_id',
                'competitor_id',
                'territory_id',
            ],
            status_list: [
                { name: 'Queue', id: 'queue' },
                { name: 'Active', id: 'active' },
                { name: 'Completed', id: 'completed' },
                { name: 'Archived', id: 'archived' },
            ],
            health_color_list: [
                { name: 'Green', id: 'green' },
                { name: 'Yellow', id: 'yellow' },
                { name: 'Red', id: 'red' },
            ],
            localCustomTagList: [],
            deleted_values: [],
            modified_deleted_items: [],
            TAG_FILTERS: {
                FIEDS: 'fields[tags]',
                LABEL: 'filter[label]',
                TYPE: 'filter[type]',
                SORT: 'sort',
                COUNT: 'count',
            },
            tag_filters: {},
        }
    },

    mounted () {
        this.local_load_tag_types('all')
        this.local_set_filter_icons()
        this.local_fill_deleted_values()
        this.local_modify_deleted_items()
    },

    computed: {
        local_entity_list () {
            const entity_properties = this.board_entity_list.reduce((prev, current) =>prev = [...prev, ...current.entity_properties], [])
            const disabled_list = this.local_get_disabled_list()    
            entity_properties.push(...this.local_get_custom_fields_and_tags, ...disabled_list)
            return entity_properties.length ? entity_properties : []
        },

        local_get_custom_fields_and_tags () {
            let entity_properties = []
            this.custom_field_list.forEach((field) => {
                if (field.type !== 'mask' && field.is_active === 1) {
                    const entity_option = {
                        data_type: field.type === 'multiline' ? 'string' : field.type,
                        id: field.id,
                        key: `custom_field.${field.name}`,
                        label: field.label,
                        options: null
                    }
                    entity_properties.push(entity_option)
                }
            })
            this.tag_section_list.forEach((tag) => {
                if (tag.is_active === 1) {
                    const entity_option = {
                        data_type: 'dropdown',
                        id: tag.id,
                        key: tag.slug,
                        label: tag.name,
                        options: null
                    }
                    entity_properties.push(entity_option)
                }
            })
            return entity_properties
        },

        local_currency_symbol () {
            return this.user_self?.organization?.currency?.symbol
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Customer', {
            customer_list: 'list',
        }),

        ...mapState('Partner', {
            partner_list: 'list',
        }),

        ...mapState('Stage', {
            stage_list: 'list',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
        }),

        ...mapState('Competitor', {
            competitor_list: 'list',
        }),

        ...mapState('Territory', {
            territory_list: 'list',
        }),

        ...mapState('Workspace', {
            workspace_search_list: 'search_list',
        }),

        ...mapState('BoardEntity', {
            board_entity_list: 'list',
        }),

        ...mapState('CustomField', {
            custom_field_list: 'list',
        }),

        ...mapState('TagSection', {
            tag_section_list: 'list',
        }),

        ...mapState('Tile', {
            tile_item: 'item',
            tile_meta: 'meta',
            tile_detailed_list: 'detailed_list'
        }),
    },

    methods: {
        // Fields - Start
        async local_tile_value_list (prop_id, filter, group_index) {
            this.local_clear_property(filter, group_index, true)
            const prop_key = this.local_get_entity_key(prop_id)
            this.local_set_value_icon(prop_key, filter)
            this.local_update_filter_fields(filter)
            this.local_check_error_in_filters(filter, 'filters_entity_id_' + filter.id, !!prop_id)
            this.local_validate_tile_config() 
            switch (prop_key) {
                case 'workspace_id': {
                    this.workspace_board_index({
                        mode: 'only-available',
                        params: { 'sort': 'order', 'filters[is_active]': 1, 'fields[workspaces]': 'id,title' }
                    })
                    break;
                }
                case 'customer_id': return this.customer_index({ 'sort': 'name', 'fields[customers]': 'id,name' })
                case 'partner_id': return this.partner_index({ 'sort': 'name', 'fields[partners]': 'id,name' })
                case 'result_id': return this.meta_index({ 'sort': 'order', 'filter[type]': 'project_result', 'fields[metas]': 'id,value' })
                case 'stage_id': return this.stage_index({ 'sort': 'order', 'fields[stages]': 'id,name', 'filter[workspace_id]': this.workspaceId })
                case 'label_tag_id': return this.local_load_tag_types(prop_key)
                case 'product_tag_id': return this.local_load_tag_types(prop_key)
                case 'platform_tag_id': return this.local_load_tag_types(prop_key)
                case 'competitor_id': return this.competitor_index({ 'sort': 'order', 'fields[competitors]': 'id,name' })
                case 'territory_id': return this.territory_index({ 'sort': 'order', 'fields[territories]': 'id,name,color', 'count': 999 })
                default: return []
            }
        },

        async local_load_tag_types (type) {
            if (type !== 'all') {
                await this.tag_index({ 'sort': 'order', 'filter[type]': 'label,product,platform', 'fields[tags]': 'id,type,label,color' })
            }
            this.label_list = _.cloneDeep(this.tag_list.filter(({ type }) => type === 'label'))
            this.product_list = _.cloneDeep(this.tag_list.filter(({ type }) => type === 'product'))
            this.platform_list = _.cloneDeep(this.tag_list.filter(({ type }) => type === 'platform'))
            await this.custom_field_index({
                'filter[resource_type]': 'Project',
            })
            await this.tag_section_index({'sort': 'order'})
            this.localLoadCustomTagList()
        },

        async localLoadCustomTagList () {
            this.tag_filters[this.TAG_FILTERS.TYPE] =  this.localPluckTagSectionSlugs(this.tag_section_list, 'slug')
            this.tag_filters[this.TAG_FILTERS.FIEDS] = 'id,type,label,color'
            this.tag_filters[this.TAG_FILTERS.SORT] = 'label'
            this.tag_filters[this.TAG_FILTERS.COUNT] = 999
            await this.tag_index(this.tag_filters)
            this.localCustomTagList = _.cloneDeep(this.tag_list)
        },

        localPluckTagSectionSlugs(objs, name) {
            let data = [];
            for(let i in objs){
                if(objs[i].hasOwnProperty(name)){
                    data.push(objs[i][name]);
                }
            }
            return data;
        },

        localGetPlaceholder(filter){
            const custom_field = 'custom_field'
            const custom_tag = 'custom_tag_section'
            const isDeleted = this.deleted_values.find(e => e === filter.entity_property_id)
            if (isDeleted && filter.is_value_deleted === 1 && (filter.key.includes(custom_field) || filter.key.includes(custom_tag))) {
                return `${filter.recently_deleted} (Deleted)`
            }
            return 'Select Value'
        },

        local_get_disabled_list() {
            const disabled_list = []
            this.moduleItem.original.forEach(config_filter => {
                config_filter.filters.forEach(filter => {
                    const custom_field = this.custom_field_list.find(field => field.id === filter.entity_property_id && field.is_active === 0)
                    const custom_tag = this.tag_section_list.find(tag => tag.id === filter.entity_property_id && tag.is_active === 0)
                    if (custom_field) {
                        const entity_option = {
                            data_type: custom_field.type === 'date' ? 'date' : custom_field.type === 'dropdown' ? 'dropdown' : 'string',
                            id: custom_field.id,
                            key: `custom_field.${custom_field.name}`,
                            label: custom_field.label,
                            options: null
                        }
                        disabled_list.push(entity_option)
                    }
                    if (custom_tag) {
                        const entity_option = {
                            data_type: 'dropdown',
                            id: custom_tag.id,
                            key: custom_tag.slug,
                            label: custom_tag.name,
                            options: null
                        }
                        disabled_list.push(entity_option)
                    }
                })
            })
            return disabled_list
        },

        localClearDeletedFields() {
            this.$emit('clear_deleted_fields')
            this.modified_deleted_items = []
        },

        local_get_custom_field_dropdown(prop_id) {
            const custom_dropdown = this.custom_field_list.find(field => field.id === prop_id)
            return custom_dropdown.options
        },

        local_check_disable_item(prop_id) {
            const custom_field_item = this.custom_field_list.find(field => prop_id === field.id)
            if (custom_field_item?.is_active === 0) return true
            const custom_tag_item = this.tag_section_list.find(tag => prop_id === tag.id)
            if (custom_tag_item?.is_active === 0) return true
        },

        local_modify_deleted_items() {
            const deletedObjects = {}
            const filter_arr = []
            this.moduleItem.original.forEach(e => {
                filter_arr.push(e.group_id)
            })
            this.deleted_items.forEach(e => {
                const id = e.group_id
                const ind = filter_arr.findIndex(d => d === id )
                if (!deletedObjects.hasOwnProperty(id) && ind === -1) deletedObjects[id] = []
                deletedObjects[id]?.push(e)
            })
            for (const key in deletedObjects) {
                const array = deletedObjects[key]
                this.modified_deleted_items.push(array)
            }    
        },

        local_fill_deleted_values() {
            this.configErrors.forEach(err => {
                if (!err.hasOwnProperty('disabled')) this.deleted_values.push(err.message.entity_property_id)
            })
        },

        async local_clear_property (filter, group_index, property_change = false) {
            const { filters } = this.moduleItem.original[group_index]
            const singleFilter = filters.find(item => item.id === filter.id)
            if (!property_change) singleFilter.entity_property_id = ''
            singleFilter.operator = ''
            singleFilter.value = ''
            singleFilter.value_alt = ''
            if (!property_change) {
                this.local_check_error_in_filters(filter, 'filters_entity_id_' + filter.id, !!singleFilter.entity_property_id)
                this.local_check_error_in_filters(filter, 'filters_operator_' + filter.id, !!singleFilter.operator)
                this.local_check_error_in_filters(filter, 'filters_value_' + filter.id, !!singleFilter.value)
                this.local_check_error_in_filters(filter, 'filters_value_alt_' + filter.id, !!singleFilter.value_alt)
                this.local_validate_tile_config()
                this.local_update_filter_fields(filter, 'clear')
            }
        },
        
        local_set_filter_icons () {
            this.moduleItem.original.forEach(config_filter => {
                config_filter.filters.forEach(filter => {
                    const prop_key = this.local_get_entity_key(filter.entity_property_id)
                    this.local_set_value_icon(prop_key, filter) 
                })
            })
        },

        local_set_value_icon (key, filter) {
            if (!key) return this.local_change_prop_icon(filter.id, 'text_fields', false)
            const { data_type } = this.local_entity_list.find(item => item.key === key)
            if (key === 'deal_amount') return this.local_change_prop_icon(filter.id, 'monetization_on', true)
            switch (data_type) {
                case 'url': return this.local_change_prop_icon(filter.id, 'link', true)
                case 'number': return this.local_change_prop_icon(filter.id, 'pin', false)
                case 'date': return this.local_change_prop_icon(filter.id, 'date_range', false)
                case 'dropdown': return this.local_change_prop_icon(filter.id, 'arrow_drop_down_circle', true)
                case 'string': return this.local_change_prop_icon(filter.id, 'text_fields', false)
                default: this.local_change_prop_icon(filter.id, 'text_fields',false)
            }
        },

        local_get_type_icon (key) {
            if (!key) return { text: 'text_fields', outlined: false }
            const { data_type } = this.local_entity_list.find(item => item.key === key)
            if (key === 'deal_amount') return { text: 'monetization_on', outlined: false }
            switch (data_type) {
                case 'string': return { text: 'text_fields', outlined: false }
                case 'url': return { text: 'link', outlined: true }
                case 'date': return { text: 'date_range', outlined: false }
                case 'dropdown': return { text: 'arrow_drop_down_circle', outlined: true }
                case 'number': return { text: 'pin', outlined: false }
                default: return { text: 'text_fields', outlined: false }
            }
        },

        local_get_icon (filter_id, field) {
            const sample = { text: 'text_fields', outlined: false }
            return this.filter_type_icon[filter_id] ? this.filter_type_icon[filter_id][field] : sample[field]
        },

        local_get_deleted_fields_icons (type) {
            if (!type) return { text: 'text_fields', outlined: false }
            switch (type) {
                case 'url': return { text: 'link', outlined: true }
                case 'number': return { text: 'pin', outlined: false }
                case 'string': return { text: 'text_fields', outlined: false }
                case 'date': return { text: 'date_range', outlined: false }
                case 'dropdown': return { text: 'arrow_drop_down_circle', outlined: true }
                default: return { text: 'text_fields', outlined: false }
            }
        },

        local_change_prop_icon (filter_id, icon, outlined) {
            this.$set(this.filter_type_icon, filter_id, {})
            this.$set(this.filter_type_icon[filter_id], 'text', icon)
            this.$set(this.filter_type_icon[filter_id], 'outlined', outlined)
        },
        // Fields - End

        // Operators - Start
        async local_change_operator (operator, filter, group_index, clear = false) {
            const { filters } = this.moduleItem.original[group_index]
            if (!clear) {
                const singleFilter = filters.find(item => item.id === filter.id)
                singleFilter.value = ''
                singleFilter.value_alt = ''
            }
            if (clear) filter.operator = ''

            this.local_update_filter_fields(filter)
            this.local_check_error_in_filters(filter, 'filters_operator_' + filter.id, !!filter.operator)
            this.local_validate_tile_config()
        },

        local_tile_entity_operators (id) {
            if (!id) return []
            const data_type = this.local_get_entity_key(id, 'data_type')
            return EntityOperators[data_type]
        },

        local_get_entity_key (id, field = 'key') {
            if (!id) return ''
            const item = this.local_entity_list.find(item => item.id === id)
            return item[field]
        },

        local_get_data_type (prop_id) {
            if (!prop_id) return []
            return this.local_get_entity_key(prop_id, 'data_type')
        },

        local_operator_is_between (operator, entity_id) {
            if (!operator || !entity_id) return false
            const operatorTypeArr = this.local_tile_entity_operators(entity_id)
            const { is_value_alt } = operatorTypeArr.find(item => item.key === operator)
            return is_value_alt
        },
        // Operators - End

        // Values - Start
        async local_change_filter_value_fields (filter, type, clear = false) {
            this.local_update_filter_fields(filter)
            if (clear) type === 'value' ? filter.value = '' : filter.value_alt = ''
            this.local_check_error_in_filters(filter, `filters_value_${ filter.id }`, !!filter.value)

            if ((type === 'value' || type === 'value_alt') && this.local_get_entity_key(filter.entity_property_id) === 'deal_amount') {
                this.local_currency_formatter(filter, type)
            }

            if (filter.operator === 'between') this.local_check_error_in_filters(filter, `filters_value_alt_${ filter.id }`, !!filter.value_alt)
            if (filter.operator === 'empty') this.local_check_error_in_filters(filter, `filters_value_${ filter.id }`, true)
            if (filter.operator === 'not-empty') this.local_check_error_in_filters(filter, `filters_value_${ filter.id }`, true)
            else this.local_check_error_in_filters(filter, `filters_value_alt_${ filter.id }`, true)
            this.local_validate_tile_config()
        },

        local_currency_formatter (filter, type, returnType = null) {
            const value = filter[type].replace(/,/g, '')
            const formatted = Intl.NumberFormat(this.local_currency_format).format(value)
            if (type === 'value') filter.value = isNaN(value) ? value : ((!formatted || formatted === '0') ? '' : formatted)
            if (type === 'value_alt') filter.value_alt = isNaN(value) ? value : ((!formatted || formatted === '0') ? '' : formatted)
            if (returnType) return filter[type]
        },

        local_format_date (date, range = false, simple = false) {
            if (range) return moment(date).format('YYYY-MM-DD')
            if (simple) return moment(date).format('MMM D')

            return date ? moment(date).format('MMM D, YYYY') : null
        },

        local_filter_input_type (prop_id) {
            const prop_key = this.local_get_entity_key(prop_id) 
            const isDropdownField = this.filter_value_items.includes(prop_key)
            const custom_tag_item = this.tag_section_list.find(tag => tag.slug === prop_key)
            if (custom_tag_item) return 'dropdown'
            return isDropdownField ? 'dropdown' : 'text'
        },

        local_check_custom_field_dropdown (prop_id) {
            const prop_key = this.local_get_entity_key(prop_id) 
            const custom_field_dropdown = this.custom_field_list.find(field => field.id === prop_id && field.type === 'dropdown' && prop_key.split('.')[0] === 'custom_field')
            if (custom_field_dropdown) return true
            return false
        },

        local_field_is_tag_type (prop_id) {
            const prop_key = this.local_get_entity_key(prop_id)
            const custom_tag_item = this.tag_section_list.find(tag => tag.slug === prop_key)
            if (custom_tag_item) return true
            return ['label_tag_id', 'product_tag_id', 'platform_tag_id', 'territory_id'].includes(prop_key)
        },

        local_filter_value_list (prop_id) {
            const prop_key = this.local_get_entity_key(prop_id)
            const tag_item = this.tag_section_list.find(tag => tag.slug === prop_key)
            const custom_tag = this.localCustomTagList.filter(tag => tag.type === tag_item?.slug)
            if (tag_item) {
                return custom_tag.map(item => ({ id: item.id, name: item.label, label: item.label, type: item.type , color: item.color }))
            }
            switch (prop_key) {
                case 'customer_id': return this.customer_list
                case 'partner_id': return this.partner_list
                case 'stage_id': return this.stage_list
                case 'status': return this.status_list
                case 'health_color': return this.health_color_list
                case 'result_id': return this.meta_list.map(item => ({ id: item.id, name: item.value }))
                case 'workspace_id': return this.workspace_available_list.map(item => ({ id: item.id, name: item.title }))
                case 'label_tag_id': return this.label_list.map(item => ({ id: item.id, name: item.label, type: item.type, color: item.color }))
                case 'product_tag_id': return this.product_list.map(item => ({ id: item.id, name: item.label, type: item.type, color: item.color }))
                case 'platform_tag_id': return this.platform_list.map(item => ({ id: item.id, name: item.label, type: item.type, color: item.color }))
                case 'competitor_id': return this.competitor_list.map(item => ({ id: item.id, name: item.name, type: 'competitor' }))
                case 'territory_id': return this.territory_list.map(item => ({ id: item.id, name: item.name, type: 'territory', color: item.color }))
                default: return []
            }
        },
        // Values - End

        // Custom events - Start
        local_add_filter (id, index) {
            this.$emit('add-filter', id, index)
        },

        local_remove_filter_row (filter, type = 'row') {
            this.$emit('remove-filter', filter, type)
        },

        local_validate_tile_config () {
            this.$emit('validate-config')
        },

        local_update_filter_fields (filter, cleared = 'clear') {
            this.$emit('update-filter-fields', filter, cleared)
        },

        local_set_error (slug, message = null) {
            this.$emit('set-error', slug, message)
        },
        // Custom events - End

        local_confirm_filter_remove (filter_id, entity_id = null) {      
            clearTimeout(this.filter_delete_timeout)          
            this.filter_delete_id = filter_id
            this.filter_delete_timeout = setTimeout(() => this.filter_delete_id = null, 5000)
        },

        local_check_error_in_filters (filter, slug, remove = false) {
            const index = this.configErrors.findIndex(item => item.slug === slug)
            if (!remove) {
                if (index === -1) this.local_set_error(slug, { slug: slug, id: filter.id, group_id: filter.group_id, message: 'Required Field' })
                return true
            }
            if (index !== -1) this.configErrors.splice(index, 1)
        },

        local_get_error (slug, field = 'message') {
            if (!slug) return null
            const item = this.configErrors.find(item => item.slug === slug)
            return (item && item[field] && item[field][field]) ?? ''
        },

        ...mapActions('Customer', {
            customer_index: 'index',
            customer_clear: 'clear',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),

        ...mapActions('Partner', {
            partner_index: 'index',
            partner_clear: 'clear',
        }),

        ...mapActions('Stage', {
            stage_index: 'index',
            stage_clear: 'clear',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
        }),

        ...mapActions('Competitor', {
            competitor_index: 'index',
        }),

        ...mapActions('Territory', {
            territory_index: 'index',
        }),

        ...mapActions('Workspace', {
            workspace_board_index: 'board',
            workspace_clear_search_list: 'clear_search_list',
        }),

        ...mapActions('CustomField', {
            custom_field_index: 'index',
            custom_field_clear: 'clear',
        }),

        ...mapActions('TagSection', {
            tag_section_index: 'index',
            tag_section_clear: 'clear',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
        }),

        ...mapActions('Tile', {
            tile_show: 'show',
            tile_show_detailed: 'show_detailed',
            tile_clear_item: 'clear_item',
        }),
    }
}
</script>

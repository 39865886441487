<template>
	<a-main>
        <a-container
            @dragenter="$can('templates.update') && !localAttachmentsUpdating ? localHandleDragEnter() : ''"
            @drop="localHandleDrop"
            container--fluid grid-list-xl
            class="pb-16 px-2 pt-2 u-hfull u-relative"
        >
            <SDragOverlay
                v-if="filesDraggedOver"
                pageType="templates"
                @leave="localHandleLeave()"
                @enter="hoveredOnInfo=false"
                @enterInfo="hoveredOnInfo=true"
            >
            </SDragOverlay>
            <div>
                <a-layout px-0>
                    <a-flex shrink>
                        <router-link v-if="local_master_template_id" :to="{ name: 'master-templates-notes', params:  { id : this.local_master_template_id } }">
                            <div class="u-overflow-hidden u-cursor-pointer d-inline-flex">
                                <a-icon size="16" class="ma-0" color="grey darken-2">arrow_back</a-icon>
                                <a class="grey--text text--darken-2 pl-1">Back to Notes</a>
                            </div>
                        </router-link>
                        <router-link v-else :to="{name: 'master-templates-system-list-notes'}">
                            <div class="u-overflow-hidden u-cursor-pointer d-inline-flex">
                                <a-icon size="16" class="ma-0" color="grey darken-2">arrow_back</a-icon>
                                <a class="grey--text text--darken-2 pl-1">Back to Notes</a>
                            </div>
                        </router-link>
                    </a-flex>
                </a-layout>
                <a-layout px-0 wrap align-start>
                    <a-flex xs12 class="pb-0 pt-0">
                        <a-text-field
                            v-model="template_note_item.title"
                            v-if="template_note_item"
                            :loading="loading"
                            :disabled="!$can('templates.update') || localAttachmentsUpdating"
                            :readonly="!$can('templates.update')"
                            @input="local_check_notes_modified()"
                            class="md-heading-5 u-elevation-custom-1 u-rounded-corners my-2"
                            solo flat hide-details
                        >
                        </a-text-field>
                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_note_response.server && template_note_response.server.errors && template_note_response.server.errors.title">
                            <a-icon size="20" color="red darken-2">warning</a-icon>
                            {{ template_note_response.server.errors.title[0] }}
                        </span>
                    </a-flex>
                </a-layout>
                <a-layout align-center  px-0 class="mt-0">
                    <a-flex shrink v-if="user_self && user_self.role && user_self.role.scope === 'internal'">
                        <a-menu bottom right offset-y :close-on-content-click="true" max-width="320" :disabled="!$can('templates.update') || localAttachmentsUpdating">
                            <template v-slot:activator="{ on }">
                                <a-card class="u-rounded-corners u-overflow-hidden u-elevation-custom-1" v-on="on">
                                    <a-card-text class="pl-3 py-1" :class="[$can('templates.update') ? 'pr-2' : 'pr-3']">
                                        <span class="md-body-2"> {{ (template_note_item.type) ? template_note_item.type.value : 'No Category' }}</span>
                                        <a-icon size="20" class="ma-0" v-if="$can('templates.update')">arrow_drop_down</a-icon>
                                    </a-card-text>
                                </a-card>
                            </template>
                            <a-list class="u-list-condensed">
                                <a-list-item v-for="type in type_list" :key="'status_key_' + type.value" @click="local_type_update(type.id)">
                                    <a-list-item-title>
                                        <span class="md-body-2">{{ type.value }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                                <a-list-item :key="'status_key_none'" @click="local_type_update(null)">
                                    <a-list-item-title>
                                        <span class="md-body-2">No Category</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink class="pl-0" v-if="user_self && user_self.role && user_self.role.scope === 'internal'">
                        <a-card class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 u-cursor-pointer">
                            <a-card class="px-3 py-1 grey darken-4" min-height="32" :disabled="!$can('templates.update') || localAttachmentsUpdating" v-if="template_note_item.visibility === 'internal'" @click="($can('templates.update') && !localAttachmentsUpdating) ? local_visibility_toggle(template_note_item.id, template_note_item.visibility) : ''">
                                <a-icon size="16" class="ma-0 mr-1 u-icon-nudge" color="white">lock</a-icon>
                                <span class="md-body-2 white--text">Private</span>
                            </a-card>
                            <a-card class="px-3 py-1 orange darken-2" min-height="32" v-else @click="($can('templates.update') && !localAttachmentsUpdating) ? local_visibility_toggle(template_note_item.id, template_note_item.visibility) : ''">
                                <a-icon size="16" class="ma-0 mr-1 u-icon-nudge" color="white">lock_open</a-icon>
                                <span class="md-body-2 white--text">Public</span>
                            </a-card>
                        </a-card>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink v-if="local_master_template_id">
                        <a-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <span v-on="on" class="md-body-2 ma-0 pa-1 blue--text">
                                    <a-icon size="20" class="u-icon-nudge blue--text pr-1" style="top: 0px;">link</a-icon>
                                    Linked with master template
                                </span>
                            </template>
                            <span>Any changes done here will reflect in master template</span>
                        </a-tooltip>
                    </a-flex>
                </a-layout>
                <a-layout wrap px-0 align-center>
                    <a-flex xs12 pt-2>
                        <a-card class="u-elevation-custom-1 u-rounded-corners">
                            <a-card-title class="px-6">
                                <a-layout align-center>
                                    <a-flex shrink>
                                        <h3 class="md-caption grey--text text--darken-1 text-uppercase font-weight-bold">Description</h3>
                                    </a-flex>
                                    <a-spacer></a-spacer>
                                    <a-flex shrink v-if="$can('templates.update')">
                                        <p class="md-caption grey--text mb-0 d-flex align-center">
                                            <em class="mr-2">Select text for formating options</em>
                                            <a-icon size="14" color="grey" class="ml-1">format_bold</a-icon>
                                            <a-icon size="14" color="grey" class="ml-1">format_italic</a-icon>
                                            <!-- <a-icon size="14" color="grey" class="ml-1">format_underlined</a-icon> -->
                                        </p>
                                    </a-flex>
                                    <a-flex shrink class="pl-0">
                                        <a-btn small icon text color="grey lighten-1" class="ma-0" @click="accordion_template_note_description_expanded = !accordion_template_note_description_expanded">
                                            <a-icon size="16" v-if="accordion_template_note_description_expanded">unfold_less</a-icon>
                                            <a-icon size="16" v-else>unfold_more</a-icon>
                                        </a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-card-title>
                            <a-divider></a-divider>
                            <a-card-text class="px-4 pt-4 pb-2" v-if="accordion_template_note_description_expanded">
                                <p class="grey--text text--darken-1 px-2" v-if="(!template_note_item.description || template_note_item.description && template_note_item.description.replace(/<[^>]*>?/gm, '') === null || template_note_item.description && template_note_item.description.replace(/<[^>]*>?/gm, '') == '') && $can('templates.store')" @click="() => { template_note_item.description = ' '; editor_description.focus() }">Click to edit the description</p>
                                <editor-content class="px-2" v-else :editor="editor_description"></editor-content>
                                <editor-menu-bubble v-if="$can('templates.update')" :editor="editor_description" :keep-in-bounds="false" v-slot="{ commands, isActive, menu }">
                                    <div
                                        class="menububble"
                                        :class="{ 'is-active': menu.isActive }"
                                        :style="`left: ${menu.left - 16}px; bottom: ${menu.bottom + 8}px;`"
                                    >
                                        <a-btn icon text small class="mx-0" :color="(isActive.bold()) ? 'blue' : 'grey'" @click="commands.bold">
                                            <a-icon size="16">format_bold</a-icon>
                                        </a-btn>
                                        <a-btn icon text small class="mx-0" :color="(isActive.italic()) ? 'blue' : 'grey'" @click="commands.italic">
                                            <a-icon size="16">format_italic</a-icon>
                                        </a-btn>
                                        <a-divider vertical dark></a-divider>
                                        <a-btn icon text small class="mx-0" :color="(isActive.bullet_list()) ? 'blue' : 'grey'" @click="commands.bullet_list">
                                            <a-icon size="16">format_list_bulleted</a-icon>
                                        </a-btn>
                                        <a-btn icon text small class="mx-0" :color="(isActive.ordered_list()) ? 'blue' : 'grey'" @click="commands.ordered_list">
                                            <a-icon size="16">format_list_numbered</a-icon>
                                        </a-btn>
                                    </div>
                                </editor-menu-bubble>
                            </a-card-text>
                        </a-card>
                    </a-flex>
                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_note_response.server && template_note_response.server.errors && template_note_response.server.errors.description">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ template_note_response.server.errors.description[0] }}
                    </span>
                </a-layout>
                <div class="mt-4">
                    <SAttachmentSection
                        :list="attachment_local_list"
                        :dropTransferList="localDroppedFilesList"
                        :sourceId="$route.params.system_note_id"
                        :updateLoading="localAttachmentsUpdating"
                        :loading="mixinIsLoading('attachment')"
                        :canUpdate="$can('templates.update')"
                        sourceType="Note"
                        @dropTransfer="localUpdateAttachmentTransfer"
                        @loadMore="localLoadMoreAttachments()"
                        @remove="localRemoveAttachmentItem"
                        @delete="localDeleteAttachmentItem"
                    >
                    </SAttachmentSection>
                </div>
                <div class="c-update-container u-rounded-corners py-3 px-5 mt-6" v-if="$can('templates.update')">
                    <div class="u-flex justify-space-between">
                        <div class="u-flex">
                            <a-btn
                                height="32"
                                color="#3949AB"
                                tag="a"
                                :loading="loading || mixinIsLoading('attachment-update') || mixinIsLoading('notes-update')"
                                :disabled="!notes_modified"
                                @click="local_update()"
                                class="c-save-btn mr-4 ma-0 px-2 py-3 u-flex align-center white--text text--darken-2 u-rounded-corners"
                                small depressed
                            >
                                Save
                            </a-btn>
                            <a-btn
                                height="32"
                                color="#EEEEEE"
                                tag="a"
                                :disabled="!notes_modified || loading || mixinIsLoading('attachment-update') || mixinIsLoading('notes-update')"
                                @click="dialog_cancel_changes = true"
                                class="ma-0 px-2 py-3 u-flex align-center text--darken-2 u-rounded-corners"
                                small depressed
                            >
                                <span style="color: #9E9E9E">Cancel</span>
                            </a-btn>
                            <div class="px-4 d-flex align-center ml-4" v-if="mixinIsLoading('attachment-update') || mixinIsLoading('notes-update')">
                                <template v-if="mixinIsLoading('attachment-update') && (attachment_upload_list && attachment_upload_list.length)">
                                    <span class="text-uppercase blue--text md-body-2">
                                        Uploading Attachments
                                    </span>
                                </template>
                                <template v-else-if="mixinIsLoading('notes-update') && attachment_upload_list && !attachment_upload_list.length">
                                    <span class="text-uppercase blue--text md-body-2">
                                        Saving changes
                                    </span>
                                </template>
                            </div>
                        </div>
                        <div v-if="$can('templates.destroy')">
                            <a-btn
                                class="ma-0 red--text text--darken-1 u-font-weight-semibold"
                                height="32"
                                color="red lighten-5"
                                @click="!mixinIsLoading('notes-update') ? dialog_confirm_delete = true : ''"
                                :loading="loading"
                                dark small depressed
                            >
                                <span>Delete Note</span>
                            </a-btn>
                        </div>
                    </div>
                </div>
            </div>
        </a-container>

        <a-snackbar v-model="snackbar_status" :timeout="4000" bottom center color="green">
            <div class="text-center">
                <a-icon class="white--text pr-4">task_alt</a-icon>
                <span>{{ snackbar_text }}</span>
            </div>
        </a-snackbar>

        <!--Cancel change alert-->
        <a-dialog v-model="dialog_cancel_changes" persistent max-width="400">
            <SCancelChanges
                @success="local_cancel_changes()"
                @close="dialog_cancel_changes = false"
                class="pa-6"
            />
        </a-dialog>

        <!--Leaving page without save alert -->
        <a-dialog v-model="dialog_leave_page_alert" persistent max-width="400">
            <SCancelChanges
                @success="local_leave_page()"
                @close="dialog_leave_page_alert = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>

        <!--Delete dialog-->
        <a-dialog v-model="dialog_confirm_delete" persistent max-width="400">
            <a-card class="pa-6">
                <div class="mb-6">
                    <span class="md-heading-6 font-weight-medium grey--text text--darken-4">
                        Are you sure you want to delete this note?
                    </span>
                    <p class="mb-0 mt-3 md-subtitle-1 grey--text text--darken-2">
                        This action cannot be undone.
                    </p>
                </div>
                <div class="u-flex-center-y">
                    <a-btn color="red darken-1" height="28" depressed class="u-rounded-corners text-uppercase" @click="local_destroy(template_note_item.id)">
                        <span class="md-caption text-uppercase white--text">
                            Yes, Delete
                        </span>
                    </a-btn>
                    <a-btn height="28" class="ml-4 u-rounded-corners text-uppercase" depressed @click="dialog_confirm_delete = false">
                        <span class="md-caption text-uppercase">
                            No, Cancel
                        </span>
                    </a-btn>
                </div>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
    Bold,
    Italic,
    Underline,
    Strike,
    OrderedList,
    BulletList,
    ListItem,
    History,
} from 'tiptap-extensions'
import marked from 'marked'
import { SCancelChanges, SAttachmentSection } from '@/config/config-shared-components'
import SDragOverlay from '../../components/Attachments/SharedDragOverlay.vue'
import mixinAttachmentsInternal from '@/mixins/mixin-internal-attachment'
import mixinLoader from '@/mixins/mixin-module-loading-setup'

export default {
    mixins: [mixinLoader, mixinAttachmentsInternal],

    components: {
        EditorContent,
        EditorMenuBubble,
        SCancelChanges,
        SAttachmentSection,
        SDragOverlay
    },
    data() {
        return {
            breadcrumb_items: [
                {text: 'Dashboard', to: '/dashboard', exact: true}
            ],
            edit_mode: false,
            editor_description: null,
            timer_update_debounce: null,
            update_autosaved_at: null,
            type_list: [],
            accordion_template_note_description_expanded: false,
            local_autosave_status: false,
            notes_modified: false,
            local_visibility_note_item: {},
            stored_note: {},
            dialog_cancel_changes: false,
            dialog_leave_page_alert: false,
            to_page_route: null,
            dialog_confirm_delete: false,
            snackbar_status: false,
            snackbar_text: 'Notes updated successfully!',

            //Attachments
            filesDraggedOver: false,
            hoveredOnInfo: false,
            FILTER: {
                parent_id: 'filter[parent_id]',
                parent_type: 'filter[parent_type]',
                source_id: 'filter[source_id]',
                source_type: 'filter[source_type]',
                fields: 'fields[attachments]',
                page: 'page'
            },
            attachment_fields: 'id,name,size,extension,order,source_id,source_type,parent_id,parent_type',
            localDroppedFilesList: [],
            localAttachmentFilters: {},
            backupAttachmentList: [],
        }
    },
    mounted() {
        this.local_index()
        if (this.$can('templates.update')) this.localAddListeners()
    },

    beforeDestroy() {
        this.editor_description.destroy()
        this.localRemoveListeners()
    },

    beforeRouteLeave (toPage, fromPage, next) {
        if (!this.notes_modified) return next()
        next(false)
        this.dialog_leave_page_alert = true
        this.to_page_route = next
    },

    computed: {
        local_master_template_id() {
            return this.$route.params.master_template_id
        },

        localAttachmentsUpdating () {
            return this.mixinIsLoading('attachment-update') || this.mixinIsLoading('attachment-delete')
        },

        localCheckAttachmentUpdate () {
            return !!_.size(this.attachment_upload_list) || !!_.size(this.attachment_delete_list)
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('TemplateNote', {
            template_note_item: 'item',
            template_note_response: 'response',
            template_note_form_mode: 'form_mode',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
            meta_filters: 'filters',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('Attachment', {
            attachment_list: 'list',
            attachment_local_list: 'local_list',
            attachment_delete_list: 'delete_list',
            attachment_upload_list: 'upload_list',
            attachment_meta: 'meta',
            attachment_response: 'response',
            attachment_delete_response: 'delete_response',
        }),
    },

    methods: {
        async local_index() {
            if (this.$can('templates.index') == false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.template_note_clear_item()
            await this.meta_clear()

            await this.local_get_note_template()
            this.localLoadTemplateNoteAttachments()

            await this.meta_index({ 'filter[type]': 'note_type' })
            this.type_list = _.cloneDeep(this.meta_list);

            this.local_note_set_type()
            this.local_note_set_description()

            this.edit_mode = false
            this.accordion_template_note_description_expanded = true
        },

        local_note_set_description() {
            const desc = this.template_note_item.description ? this.template_note_item.description : ' '
            this.editor_description = new Editor({
                extensions: [
                    new Bold(),
                    new Italic(),
                    // new Underline(),
                    new Strike(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new History(),
                ],
                content: marked(desc, { sanitize: true }),
                onUpdate: this.local_update_debounced,
                editable: this.$can('templates.update')
            })
        },

        async local_get_note_template() {
            await this.template_note_show({
                id: this.$route.params.system_note_id,
                params: { 'include': 'type' }
            })
            this.stored_note = _.cloneDeep(this.template_note_item)
        },

        async localLoadTemplateNoteAttachments () {
            this.mixinSetLoading('attachment')
            this.attachment_clear_response()
            this.attachment_clear_upload_list()
            this.localSetAttachmentFilters()
            await this.attachment_index({ params: this.localAttachmentFilters })
            this.mixinResetLoading('attachment')
            this.mixinUpdateLocalAttachmentList({ list: this.attachment_list })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localSetAttachmentFilters () {
            this.localAttachmentFilters[this.FILTER.parent_id] = this.template_note_item.template_group_id
            this.localAttachmentFilters[this.FILTER.parent_type] = 'TemplateGroup'
            this.localAttachmentFilters[this.FILTER.source_id] = this.template_note_item.id
            this.localAttachmentFilters[this.FILTER.source_type] = 'TemplateNote'
            this.localAttachmentFilters[this.FILTER.fields] = this.attachment_fields
            this.localAttachmentFilters.count = 5
        },

        async local_update() {
            this.mixinSetLoading('notes-update')
            if (this.local_visibility_note_item.id) await this.template_note_visibility(this.local_visibility_note_item)
            await this.localUpdateTemplateNoteAttachment()
            await this.template_note_update({
                ...this.template_note_item
            })
            this.mixinResetLoading('notes-update')

            if (this.template_note_response.status !== 'success') return
            this.snackbar_status = true
            this.local_get_note_template()
            this.edit_mode = false
            this.notes_modified = false
        },

        local_check_notes_modified () {
            this.notes_modified = !_.isEqual(this.stored_note, this.template_note_item) || this.localCheckAttachmentUpdate
        },

        local_handler_close (e) {
            if (this.notes_modified) {
                e.returnValue = 'Are you sure to close the tab?';
            }
        },

        async local_cancel_changes () {
            await this.template_note_clear_item()
            await this.meta_clear()
            this.template_note_item.visibility = this.stored_note.visibility
            await this.local_get_note_template()
            this.editor_description.destroy()
            this.local_note_set_description()
            this.localClearAttachmentUpdate()
            this.notes_modified = false
            this.dialog_cancel_changes = false
        },

        local_leave_page () {
            this.notes_modified = false
            this.to_page_route()
        },

        async local_template_note_clear() {
            await this.template_note_show({id: this.$route.params.system_note_id})
            this.edit_mode = false
        },

        async local_type_update(id) {
            this.template_note_item.type_id = id
            this.local_check_notes_modified()
            this.local_note_set_type()
        },

        local_note_set_type() {
            if (this.template_note_item && this.template_note_item.type_id) {
                this.template_note_item.type = this.type_list.find(item => item.id === this.template_note_item.type_id)
            } else this.template_note_item.type = null
        },

        async local_update_debounced(state) {
            this.template_note_item.description = turndownService.turndown(state.getHTML())
            this.local_check_notes_modified()
        },

        async local_clear_debounce_timer(saved = false) {
            if (this.timer_update_debounce) {
                clearTimeout(this.timer_update_debounce)
                this.timer_update_debounce = null
                if (saved) {
                    this.update_autosaved_at = moment().format('YYYY-MM-DD HH:mm:ss')
                }
            }
        },

        async local_visibility_toggle(id, state) {
            this.local_visibility_note_item.id = id
            this.local_visibility_note_item.state = state === 'external' ? 'internal' : 'external'
            this.template_note_item.visibility = this.local_visibility_note_item.state
            this.local_check_notes_modified()
        },

        async local_destroy(id) {
            this.notes_modified = false
            await this.template_note_destroy({id: id})
            if(this.template_note_response.status === 'success') {
                this.$router.push({name: 'master-templates-system-list-notes'})
            }
        },

        local_switch_router() {
            if(this.local_master_template_id) {
                this.$router.push({name: 'master-templates-notes', params:  { id : this.local_master_template_id }})
            }else{
                this.$router.push({name: 'master-templates-system-list-notes'})
            }
        },

        local_update_user_updated_time() {
            this.note_item.updated_at = new Date()
            this.note_item.updated_by.name = this.user_self.name
        },

        // Attachment CRUD

        localAddListeners () {
            window.addEventListener('beforeunload', this.local_handler_close)
            this.mixinAttachmentListeners('add')
        },

        localRemoveListeners () {
            window.removeEventListener('beforeunload', this.local_handler_close)
            this.mixinAttachmentListeners('remove')
        },

        localHandleDragEnter (e) {
            this.filesDraggedOver = true
        },

        localHandleLeave (e) {
            if (this.hoveredOnInfo) return
            this.filesDraggedOver = false
        },

        localHandleDrop (e) {
            if (this.localAttachmentsUpdating || this.pageLoading) return
            const droppedFiles = Array.from(e.dataTransfer.files)
            if (_.size(droppedFiles) > 5) return this.localValidateAttachmentCount()
            this.localDroppedFilesList = _.cloneDeep(droppedFiles)
            this.filesDraggedOver = false
        },

        localValidateAttachmentCount () {
            this.$notify('warning', 'Sorry, you can only upload up to 5 files at a time')
            this.filesDraggedOver = false
        },

        localRemoveAttachmentItem (id) {
            this.mixinRemoveAttachmentItem(id)
            this.local_check_notes_modified()
        },

        localDeleteAttachmentItem (id) {
            this.mixinDeleteAttachmentItem(id)
            this.local_check_notes_modified()
        },

        async localUpdateTemplateNoteAttachment () {
            this.mixinSetLoading('attachment-update')
            this.attachment_clear_response()
            this.mixinRemoveInvalidFiles()
            await this.localBulkDeleteAttachment()
            await this.localUploadAttachment()
            this.mixinResetLoading('attachment-update')
        },

        async localUploadAttachment () {
            if (!_.size(this.attachment_upload_list)) return
            const parentId = this.template_note_item.template_group_id
            const formData = new FormData();
            formData.append('parent_id', parentId)
            formData.append('parent_type', 'TemplateGroup')
            formData.append('source_id', this.template_note_item.id)
            formData.append('source_type', 'TemplateNote')
            formData.append('order', -1)
            this.attachment_upload_list.forEach(file => {
                formData.append('files[]', file)
                formData.append('ids[]', file.id)
            })
            await this.attachment_upload(formData)

            if (!this.$status(this.attachment_response)) return this.mixinHandleAttachmentErrors()
            this.localShowNoteAttachments()
            this.attachment_clear_upload_list()
        },

        async localBulkDeleteAttachment () {
            if (!_.size(this.attachment_delete_list)) return
            this.mixinSetLoading('attachment-delete')
            await this.attachment_bulk_destroy({ids: this.attachment_delete_list})
            if (this.$status(this.attachment_delete_response)) {
                if (!_.size(this.attachment_upload_list)) this.localShowNoteAttachments()
                if (!_.size(this.attachment_local_list) && !_.size(this.attachment_upload_list)) this.localLoadTemplateNoteAttachments()
                this.attachment_clear_delete_list()
            }
            this.mixinResetLoading('attachment-delete')
        },

        async localShowNoteAttachments () {
            this.localAttachmentFilters.count = _.size(this.attachment_local_list)
            this.localAttachmentFilters.page = 1
            await this.attachment_index({ mode: 'show', params: this.localAttachmentFilters})
            this.mixinUpdateLocalAttachmentList({ list: this.attachment_list})
            this.backupAttachmentList = _.cloneDeep(this.attachment_list)
        },

        localUpdateAttachmentTransfer (params) {
            this.attachment_clear_response()
            const { list, transferFiles } = params || {}
            const totalUploadList = !!_.size(this.attachment_upload_list) ? [...transferFiles, ...this.attachment_upload_list] : [...transferFiles]
            this.mixinPreserveListChanges({ list: list, type: 'drop_transfer' })
            this.attachment_update_upload_list(totalUploadList)
            this.local_check_notes_modified()
        },

        async localLoadMoreAttachments () {
            if (this.attachment_meta.total === _.size(this.attachment_list)) return

            this.mixinSetLoading('attachment')

            const defaultCount = 5
            const isListShortOfItem = _.size(this.attachment_list)%5 === 0
            const loadType = isListShortOfItem ? 'load_more' : 'index'
            this.localAttachmentFilters.count = isListShortOfItem ? defaultCount : this.attachment_meta.current_page*defaultCount
            this.localAttachmentFilters[this.FILTER.page] = isListShortOfItem ? this.attachment_meta.current_page+1 : 1
            await this.attachment_index({ mode: loadType, params: this.localAttachmentFilters })

            this.mixinResetLoading('attachment')
            this.mixinPreserveListChanges({ list: this.attachment_list, type: 'load_more' })
            this.backupAttachmentList =  _.cloneDeep(this.attachment_list)
        },

        localClearAttachmentUpdate () {
            this.mixinUpdateLocalAttachmentList({ list: this.backupAttachmentList })
            this.mixinClearAttachmentUpdate()
        },

        ...mapActions('TemplateNote', {
            template_note_show: 'show',
            template_note_visibility: 'visibility',
            template_note_update: 'update',
            template_note_select: 'select',
            template_note_destroy: 'destroy',
            template_note_clear_item: 'clear_item',
        }),

         ...mapActions('Attachment', {
            attachment_index: 'index',
            attachment_upload: 'upload',
            attachment_update_upload_list: 'update_upload_list',
            attachment_remove_upload_item: 'remove_upload_list_item',
            attachment_bulk_destroy: 'bulk_destroy',
            attachment_clear_upload_list: 'clear_upload_list',
            attachment_clear_delete_list: 'clear_delete_list',
            attachment_clear_response: 'clear_response',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),
    },
}
</script>


<style scoped>
.note-description-event{
    pointer-events: none;
}

.c-update-container {
    position: sticky !important;
    bottom: 0px;
    border: 1px solid #EEE;
    background: #FFF;
    width: 100%;
}

</style>

<template>
    <a-main>
        <a-container container--fluid grid-list-xl class="pr-6 mx-0 pa-0">
            <g-page-header
                title="Meetings"
                subtitle="Manage all meeting under your organization"
                icon="video_call"
            />
        </a-container>

        <template v-if="localIsGongCallsSyncing">
            <div class="u-flex-center" style="height: calc(100vh - 334px)">
                <div class="u-flex flex-column align-center" style="row-gap: 8px;">
                    <a-icon size="80" color="purple">$ripple_loading</a-icon>
                    <div class="text-center">
                        <h3 class="md-heading-5 grey--text text--darken-2">Syncing</h3>
                        <h3 class="md-body-2 mt-1 grey--text text--darken-2">Fetching calls from Gong, please visit after sometime.</h3>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <div v-if="!isGongIntegrated" class="u-flex-center" style="min-height: calc(100vh - 167px)">
                <div>
                    <img :src="require('../../assets/images/list-empty.svg')" alt="No Meetings Found" class="mx-auto mb-4 u-wfull d-block" style="width: 180px; height: 120px;">
                    <h2 class="mt-4 mb-0 text-center md-heading-6 font-weight-medium grey--text text--darken-4 text-uppercase">No Meetings Found!</h2>
                    <template v-if="$can('applets.update')">
                        <h3 class="mt-1 text-center md-body-2 font-weight-medium grey--text text--darken-2">To get started, please connect with any application below.</h3>
                        <div class="mt-6 u-flex-center" style="column-gap: 16px">
                            <a-btn tag="a" depressed class="px-3 py-2 u-shadow white" :to="{ name: 'appstore' }">
                                <img src="../../assets/images/apps/gong/logo.svg" alt="Gong Integration" style="width: 20px; height: 20px;">
                                <span class="ml-1 d-block md-body-2 text-uppercase font-weight-medium">Connect With Gong</span>
                            </a-btn>
                            <div class="px-3 py-2 white u-flex-center-y u-no-select u-rounded-corners u-opacity-60" style="height: 36px;" :to="{ name: 'appstore' }">
                                <img src="../../assets/images/apps/zoom/logo.svg" alt="Gong Integration" style="width: 60px; height: 60px;">
                                <h3 class="ml-1 md-body-2 text-uppercase font-weight-medium">(Coming Soon)</h3>
                            </div>
                        </div>
                    </template>
                    <h3 v-else class="mt-1 text-center md-body-2 font-weight-medium grey--text text--darken-2">
                        Please contact your administrator for more information.
                    </h3>
                </div>
            </div>

            <template v-if="isGongIntegrated">
                <template v-if="pageLoading">
                    <PartialMeetingsLoader v-for="(i, index) in 5" :class="[{ 'u-border-t': index === 0 }]" :key="i" />
                </template>

                <div
                    v-if="!pageLoading && (meeting_info_list && !meeting_info_list.gong_config_status)"
                    class="u-flex-center"
                    style="min-height: calc(100vh - 167px)"
                >
                    <div>
                        <img :src="require('../../assets/images/list-empty.svg')" alt="No Meetings Found" class="mx-auto mb-4 u-wfull d-block" style="width: 180px; height: 120px;">
                        <h2 class="mt-4 mb-0 text-center md-heading-6 font-weight-medium grey--text text--darken-4 text-uppercase">No Meetings Found!</h2>
                        <template v-if="$can('applets.update')">
                            <h3 class="mt-1 text-center md-body-2 font-weight-medium grey--text text--darken-2">Looks like you haven’t configured your meeting settings yet.</h3>
                            <div class="mt-6 u-flex-center">
                                <a-btn tag="a" class="px-3 py-2 primary" :to="{ name: 'appstore' }" depressed dark>
                                    <a-icon size="20">login</a-icon>
                                    <span class="ml-2 d-block md-body-2 text-uppercase font-weight-medium">Go to App Store</span>
                                </a-btn>
                            </div>
                        </template>
                        <h3 v-else class="mt-1 text-center md-body-2 font-weight-medium grey--text text--darken-2">
                            Please contact your administrator for more information.
                        </h3>
                    </div>
                </div>

                <template v-else>
                    <template v-if="!pageLoading">
                        <div class="px-3 mb-6 c-search-inventory d-flex align-center u-border u-rounded-corners">
                            <a-progress-circular
                                v-if="mixinIsLoading('meeting-search')"
                                color="orange darken-2"
                                size="24"
                                width="2"
                                indeterminate
                            />
                            <a-icon v-else size="24" color="grey">search</a-icon>
                            <a-text-field
                                v-model="searchMeetingsModel"
                                placeholder="Search meetings..."
                                solo flat hide-details dense
                            />
                        </div>

                        <div class="mb-6 u-flex-center-y white" style="column-gap: 16px">
                            <!-- Filter open button -->
                            <div class="u-border u-rounded-corners">
                                <a-btn @click="filterModal = true" height="36" depressed class="px-3 py-2 white u-rounded-corners">
                                    <div class="u-flex-center-y" style="column-gap: 4px;">
                                        <a-icon size="20" color="grey darken-2">filter_alt</a-icon>
                                        <span class="md-body-2 font-weight-medium grey--text text--darken-2">Filters</span>
                                    </div>
                                </a-btn>
                            </div>

                            <a-spacer></a-spacer>

                            <!-- Last synced info -->
                            <a-sheet class="transparent u-flex-center-y u-rounded-corners" height="36" style="column-gap: 8px; width: max-content">
                                <template v-if="meeting_info_list && meeting_info_list.gong_last_synced_at">
                                    <div class="u-flex-center-y" style="column-gap: 4px;">
                                        <a-icon size="20" color="grey darken-2">history</a-icon>
                                        <h4 class="md-body-2 font-weight-medium grey--text text--darken-2">Last synced {{ meeting_info_list.gong_last_synced_at }}</h4>
                                    </div>
                                    <a-icon size="6" color="grey lighten-1">fiber_manual_record</a-icon>
                                </template>
                                <h4 class="md-body-2 font-weight-medium grey--text text--darken-2" v-if="meeting_meta">
                                    {{ meeting_meta.total_meetings }} Meetings
                                    <!-- <span class="grey--text text--darken-1" v-if="meeting_meta.total_meetings - meeting_meta.total > 0">
                                        ({{ meeting_meta.total_meetings - meeting_meta.total }} hidden)
                                    </span> -->
                                </h4>
                            </a-sheet>
                        </div>
                    </template>

                    <!-- No Data Found Message -->
                    <template v-if="!pageLoading && (meeting_list && !meeting_list.length)">
                        <a-responsive class="my-4">
                            <h3 class="mb-1 text-center md-subtitle-1 font-weight-medium grey--text text--darken-1 text-uppercase">No Data Found</h3>
                            <p class="mb-0 text-center md-body-2 grey--text">There is no data to fetch right now.<br>Try narrowing your search.</p>
                        </a-responsive>
                    </template>

                    <template v-if="!pageLoading">
                        <!-- Meetings list -->
                        <div v-if="meeting_list && meeting_list.length" class="u-flex flex-column" style="row-gap: 0px; margin-bottom: 80px;">
                            <SMeetingCard
                                v-for="(meeting) in meeting_list"
                                :key="meeting.id"
                                :meeting="meeting"
                                :gong-base-url="gongBaseUrl"
                                @openKeywords="localOpenMeetingKeywords(meeting)"
                                class="px-6 py-4 pl-4 u-border-t u-flex-center-y justify-space-between"
                                style="column-gap: 200px;"
                            />

                            <!-- <div v-if="meeting_meta && meeting_meta.total > 20" class="pl-6 pr-3 u-flex-center-y c-sticky-paginate-parent u-shadow u-rounded-corners"> -->
                            <div class="pl-6 pr-3 u-flex-center-y c-sticky-paginate-parent u-border-t">
                                <div class="mr-2 md-body-2 grey--text text--darken-2 font-weight-medium d-inline-block">
                                    Showing {{ meeting_meta.from }} - {{ meeting_meta.to }} out of {{ meeting_meta.total }} meeting{{ meeting_meta.total > 1 ? 's' : '' }}
                                </div>
                                <a-spacer></a-spacer>
                                <div class="u-flex-center-y" style="column-gap: 16px;">
                                    <a-progress-circular
                                        v-if="mixinIsLoading('pagination')"
                                        width="2"
                                        size="16"
                                        color="orange darken-2"
                                        indeterminate
                                    />
                                    <a-pagination
                                        v-model="meeting_meta.current_page"
                                        :length="meeting_meta.last_page"
                                        :total-visible="10"
                                        class="py-1 c-inventory-pagination"
                                        @input="localPaginate()"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>

                    <PartialMeetingFilterPane
                        :isOpen="filterModal"
                        :isLoading="mixinIsLoading('filter-data')"
                        @filter="localApplyFilter"
                        @close="filterModal = false"
                        @clear="localClearMeetingFilters()"
                    />

                    <a-dialog v-model="dialogKeywordsList" max-width="920">
                        <ModalKeywordsDisplay
                            :meeting="selectedMeeting"
                            :isOpen="dialogKeywordsList"
                            @close="localCloseKeywords"
                        />
                    </a-dialog>
                </template>
            </template>
        </template>
    </a-main>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { SMeetingCard } from '@/config/config-shared-components'
import PartialMeetingFilterPane from './Partials/PartialMeetingFilterPane.vue'
import PartialMeetingsLoader from './Partials/PartialMeetingsLoader.vue'
import loadWorkspace from '@/mixins/mixin-workspace'
import mixinLoading from '@/mixins/mixin-module-loading-setup'
import ModalKeywordsDisplay from './Modals/ModalKeywordsDisplay.vue';
import { diffSimple } from '@/helpers/helper-date-filter';

export default {
    components: { SMeetingCard, PartialMeetingFilterPane, PartialMeetingsLoader, ModalKeywordsDisplay },

    mixins: [loadWorkspace, mixinLoading],

    data () {
        return {
            isGongIntegrated: true,
            pageLoading: true,
            filterModal: false,
            dialogKeywordsList: false,
            currentPageNumber: null,
            searchMeetingsTimeout: null,
            searchMeetingsModel: '',
            gongBaseUrl: '',

            selectedMeeting: {},
            meetingFilters: {},
            meetingLocalFilters: {},
            meetingPerPageCount: 20,
            MEETING_FILTERS: {
                PAGE: 'page',
                COUNT: 'count',
                TITLE: 'filter[title]',
                WORKSPACE_ID: 'filter[workspace_id]',
                PROJECT_ID: 'filter[project_id]',
                ENDED_AT: 'filter[ended_at]',
                TRACKERS_KEYWORDS: 'filter[trackers_keywords]',
                COLLABORATORS: 'filter[collaborators]',
            },
            projectFilters: {},
            PROJECT_FILTERS: {
                FIEDS: 'fields[type]',
                STATUS: 'filter[status]',
                WORKSPACE: 'filter[workspace_id]',
            },
        }
    },

    watch: {
        searchMeetingsModel (newVal, oldVal) {
            newVal = _.trim(newVal)
            oldVal = _.trim(oldVal)
            if ((oldVal && !newVal) || newVal) this.localSearchMeetings()
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localIsGongCallsSyncing () {
            return this.meeting_info_list && this.meeting_info_list.is_gong_call_manual_syncing
        },

        localHasMeetingFlag () {
            return this.user_self.organization && this.user_self.organization.flag_meetings && this.user_self.organization.flag_meetings === 1
        },

        ...mapState('Project', {
            project_list: 'list',
        }),

        ...mapState('Meeting', {
            meeting_list: 'list',
            meeting_meta: 'meta',
            meeting_info_list: 'info',
        }),

        ...mapState('Integration', {
            integration_statuses: 'list',
        }),

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async localIndex () {
            if (!this.localHasMeetingFlag) {
                this.$router.replace({ name: '404' })
            }

            if (!this.$can('meetings.index') || !this.$plan('meetings:full-access')) {
                this.$router.replace({ name: 'errors-unauthorized' })
            }

            await this.integration_status_index()
            if (!this.localIntegrationHasAccess('gong')) {
                this.isGongIntegrated = false
                this.pageLoading = false
                return true
            }

            await this.meeting_info_index()
            this.gongBaseUrl = this.meeting_info_list.gong_base_url
            if (this.meeting_info_list && !this.meeting_info_list.gong_config_status) {
                return this.pageLoading = false
            }

            await this.localFetchMeetings({ filters: this.meetingLocalFilters })
            this.localSetProjectFilters()
            await this.project_index({ 'fields[projects]': 'id,title', ...this.projectFilters })
            this.localFetchFilterData()

            this.pageLoading = false
        },

        localIntegrationHasAccess (slug) {
            return !!this.integration_statuses[slug]
        },

        async localFetchFilterData () {
            this.mixinSetLoading('filter-data')
            await this.gong_users_index()
            await this.gong_trackers_index()
            await this.gong_keywords_index()
            this.mixinResetLoading('filter-data')
        },

        async localFetchMeetings (props) {
            const {
                page = 1,
                count = this.meetingPerPageCount,
                searchQuery = this.searchMeetingsModel,
                filters = null
            } = props || {}

            this.meetingFilters = { include: 'meetingTrackers.meetingKeywords,meetingBridgeProjects.project,meetingParticipants' }
            if (_.trim(searchQuery)) this.meetingFilters[this.MEETING_FILTERS.TITLE] = searchQuery
            if (filters && _.size(Object.keys(filters))) this.localSetupMeetingFilters(filters)

            this.meetingFilters[this.MEETING_FILTERS.PAGE] = page
            this.meetingFilters[this.MEETING_FILTERS.COUNT] = count
            this.meetingFilters[this.MEETING_FILTERS.WORKSPACE_ID] = this.current_workspace_id_mixin
            await this.meeting_index(this.meetingFilters)

            this.currentPageNumber = this.meeting_meta.current_page
        },

        localSetupMeetingFilters (filters) {
            const { projects, trackers, keywords, date, collaborators, withProjects: projectsType } = filters || {}

            if (projectsType === 'with') {
                if (_.size(projects)) this.meetingFilters[this.MEETING_FILTERS.PROJECT_ID] = projects.map(i => i.id).join(',')
                else this.meetingFilters[this.MEETING_FILTERS.PROJECT_ID] = 'all'
            }

            if (projectsType === 'all') {
                if (_.size(projects)) this.meetingFilters[this.MEETING_FILTERS.PROJECT_ID] = projects.map(i => i.id).join(',')
            }

            if (projectsType === 'without') {
                this.meetingFilters[this.MEETING_FILTERS.PROJECT_ID] = 'none'
            }

            if (_.size(collaborators)) {
                this.meetingFilters[this.MEETING_FILTERS.COLLABORATORS] = collaborators.map(i => i.email).join(',')
            }

            if (date.operator) {
                const dateConstruct = []
                dateConstruct.push(date.operator.value)
                dateConstruct.push(date.from)
                dateConstruct.push(date.to)
                this.meetingFilters[this.MEETING_FILTERS.ENDED_AT] = dateConstruct.filter(Boolean).join(',')
            }

            const list = []
            if (_.size(trackers)) list.push(trackers.map(i => i.name).join(','))
            if (_.size(keywords)) list.push(keywords.map(i => i.name).join(','))
            if (_.size(list)) {
                this.meetingFilters[this.MEETING_FILTERS.TRACKERS_KEYWORDS] = list.join(',')
            }
        },

        localSearchMeetings () {
            this.mixinSetLoading('meeting-search')
            clearTimeout(this.searchMeetingsTimeout)

            this.searchMeetingsTimeout = setTimeout(async () => {
                await this.localFetchMeetings({ filters: this.meetingLocalFilters })
                this.mixinResetLoading('meeting-search')
            }, 500);
        },

        localSetProjectFilters () {
            // this.projectFilters[this.PROJECT_FILTERS.STATUS] = 'active'
            // this.projectFilters[this.PROJECT_FILTERS.WORKSPACE] = this.current_workspace_id_mixin
        },

        async localPaginate () {
            if (this.currentPageNumber === this.meeting_meta.current_page) return
            this.mixinSetLoading('pagination')

            await this.$vuetify.goTo(0)
            await this.localFetchMeetings({ page: this.meeting_meta.current_page, filters: this.meetingLocalFilters })
            this.currentPageNumber = this.meeting_meta.current_page

            this.mixinResetLoading('pagination')
        },

        localOpenMeetingKeywords (meeting) {
            this.selectedMeeting = _.cloneDeep(meeting)
            this.dialogKeywordsList = true
        },

        localCloseKeywords () {
            this.dialogKeywordsList = false
            this.selectedMeeting = {}
        },

        localApplyFilter (filters) {
            this.meetingLocalFilters = filters
            this.localFetchMeetings({ filters })
        },

        localClearMeetingFilters () {
            this.meetingLocalFilters = {}
            this.localFetchMeetings()
        },

        localFormatDate (date) {
            return date ? diffSimple(date, { format: 'MMM D, YYYY [at] hh:mm A' }) : null
        },

        ...mapActions('Project', {
            project_index: 'index'
        }),

        ...mapActions('Meeting', {
            meeting_index: 'index',
            meeting_info_index: 'info_index',
        }),

        ...mapActions('Integration', {
            integration_status_index: 'statuses',
        }),

        ...mapActions('Gong', {
            gong_users_index: 'users_index',
            gong_trackers_index: 'trackers_index',
            gong_keywords_index: 'keywords_index',
        }),
    }
}
</script>

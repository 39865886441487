<template>
    <a-sheet class="u-relative px-1">
        <div class="u-relative u-wfull">
            <a-btn
                @click="$emit('close')"
                class="u-absolute u-cursor-pointer"
                style="top: 18px; right: 18px;"
                small depressed icon
            >
                <a-icon size="18" color="grey" class="u-opacity-70">cancel</a-icon>
            </a-btn>

            <a-sheet class="pa-5 transparent" ref="refTerritory">
                <partial-project-summary @expandTerritory="localExpand('territory')" />
            </a-sheet>
        </div>

        <div :class="[viewTags ? 'pa-5 pt-0 pb-6' : 'pa-5 py-0']">
            <div
                v-if="localIsQueueStage"
                :style="[
                    { display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px' },
                    { gridTemplateRows: '209px' }
                ]"
            >
                <a-sheet style="grid-column-end: span 2" class="u-rounded-corners-xl u-shadow u-relative">
                    <partial-project-queue-activity-summary />
                </a-sheet>
                <a-sheet
                    class="u-rounded-corners-xl u-shadow"
                    ref="refStatusUpdate"
                >
                    <partial-latest-status-summary @expand="localExpand('statusUpdate')" />
                </a-sheet>
            </div>

            <div
                v-if="localIsActiveStage"
                :style="[
                    { display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px' },
                    { gridTemplateRows: '169px 180px 180px' }
                ]"
            >
                <a-sheet style="grid-column-end: span 2" class="u-rounded-corners-xl u-shadow u-relative">
                    <partial-project-activity-summary />
                </a-sheet>
                <a-sheet
                    v-if="localIsCompletedStage"
                    class="u-rounded-corners-xl u-shadow"
                    ref="refProjectReasons"
                >
                    <partial-completed-reason-summary @expand="localExpand('projectReasons')" />
                </a-sheet>
                <a-sheet
                    v-if="localIsActiveStage"
                    class="u-rounded-corners-xl u-shadow"
                    ref="refStatusUpdate"
                >
                    <partial-latest-status-summary @expand="localExpand('statusUpdate')" />
                </a-sheet>
                <a-sheet
                    v-if="localConfidenceScoreFlag"
                    ref="refConfidenceScore"
                    style="grid-column: 1 / 2; grid-row-end: span 2"
                    class="u-rounded-corners-xl u-shadow"
                >
                    <partial-confidence-score-summary :configMeta="confScoreMeta" @expand="localExpand('confidenceScore')" />
                </a-sheet>
                <a-sheet
                    ref="refScStats"
                    :style="[{ 'grid-column': localConfidenceScoreFlag ? '2 / 3' : '1 / 2', 'grid-row': '2 / 3' }]"
                    class="u-rounded-corners-xl u-shadow"
                >
                    <partial-sc-summary />
                </a-sheet>
                <a-sheet
                    :style="[{ 'grid-column': localConfidenceScoreFlag ? '2 / 3' : '1 / 2', 'grid-row': '3 / 4'}]"
                    class="u-rounded-corners-xl u-shadow"
                >
                    <partial-tasks-summary />
                </a-sheet>
                <a-sheet
                    :style="[{ 'grid-column': localConfidenceScoreFlag ? '3 / 4' : '2 / 3', 'grid-row': localConfidenceScoreFlag ? '2 / 3' : '2 / 4'}]"
                    class="u-rounded-corners-xl u-shadow u-relative" ref="refCompetitors"
                >
                    <partial-competitors-summary @expand="localExpand('competitors')" />
                </a-sheet>
                <a-sheet
                    class="u-rounded-corners-xl u-shadow u-relative"
                    ref="refNotes"
                >
                    <partial-latest-note-summary @expand="localExpand('notes')" />
                </a-sheet>
            </div>

            <div
                v-if="localIsCompletedStage"
                :style="[
                    { display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px' },
                    { gridTemplateRows: localConfidenceScoreFlag ? '201px 180px 180px' : '201px 180px' }
                ]"
            >
                <a-sheet
                    style="grid-column-end: span 2"
                    class="u-rounded-corners-xl u-shadow u-relative"
                    ref="refCompletedActivity"
                >
                    <partial-project-completed-activity-summary @expand="localExpand('completedActivity')" />
                </a-sheet>
                <a-sheet
                    :style="[{ 'grid-column': '3 / 4', 'grid-row': localConfidenceScoreFlag ? '1 / 3' : '1 / 2'}]"
                    class="u-rounded-corners-xl u-shadow u-relative"
                    ref="refCompetitors"
                >
                    <partial-competitors-summary @expand="localExpand('competitors')" />
                </a-sheet>
                <a-sheet
                    v-if="localConfidenceScoreFlag"
                    ref="refConfidenceScore"
                    style="grid-column: 1 / 2; grid-row-end: span 2"
                    class="u-rounded-corners-xl u-shadow"
                >
                    <partial-confidence-score-summary :configMeta="confScoreMeta" @expand="localExpand('confidenceScore')" />
                </a-sheet>
                <a-sheet ref="refScStats" class="u-rounded-corners-xl u-shadow">
                    <partial-completed-project-sc-summary />
                </a-sheet>
                <a-sheet class="u-rounded-corners-xl u-shadow">
                    <partial-completed-project-task-summary />
                </a-sheet>
                <a-sheet class="u-rounded-corners-xl u-shadow u-relative" ref="refNotes">
                    <partial-latest-note-summary @expand="localExpand('notes')" />
                </a-sheet>
            </div>

            <!-- Tags -->
            <div class="mt-5">
                <div
                    @click="localToggleTagsView()"
                    v-ripple="{ class: 'grey--text text--lighten-2' }"
                    class="u-relative u-cursor-pointer u-rounded-corners-full py-2 mb-4 u-no-select"
                >
                    <a-divider class="u-absolute u-wfull grey lighten-2" style="transform: translate(-50%, -50%); left: 50%; top: 50%;"></a-divider>
                    <div class="u-flex-center u-relative">
                        <div class="d-inline-flex align-center grey lighten-5 px-3" style="column-gap: 12px;">
                            <span class="md-body-1 grey--text text--darken-3 u-font-weight-semibold text-uppercase">Project Tags</span>
                            <a-sheet class="grey darken-2 u-rounded-corners-full u-flex-center" width="20" height="20">
                                <a-icon size="20" color="white">{{ viewTags ? 'expand_less' : 'expand_more' }}</a-icon>
                            </a-sheet>
                        </div>
                    </div>
                </div>

                <div
                    v-if="viewTags"
                    :style="[
                        { display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px' },
                        { gridTemplateRows: '188px' }
                    ]"
                >
                    <a-sheet class="u-rounded-corners-xl u-shadow u-relative" ref="refProductTags">
                        <partial-project-tags-summary @expand="localExpand('productTags')" tagType="product" />
                    </a-sheet>
                    <a-sheet class="u-rounded-corners-xl u-shadow u-relative" ref="refPlatformTags">
                        <partial-project-tags-summary @expand="localExpand('platformTags')" tagType="platform" />
                    </a-sheet>
                    <a-sheet class="u-rounded-corners-xl u-shadow u-relative" ref="refLabelTags">
                        <partial-project-tags-summary @expand="localExpand('labelTags')" tagType="label" />
                    </a-sheet>
                </div>
            </div>
        </div>

        <v-fade-transition leave-absolute origin="center center" group>
            <div
                v-if="currentExpandSlug !== ''"
                key="transition-overlay"
                @click="localCloseExpand()"
                class="u-absolute u-wfull u-hfull"
                style="
                    background-color: rgba(0,0,0,0.4);
                    top: 0px;
                    left: 0px;
                    z-index: 8;
                    backdrop-filter: blur(4px);
                "
            />

            <a-sheet
                v-if="currentExpandSlug"
                ref="refExpandContainer"
                key="transition-tile"
                class="u-rounded-corners-xl u-shadow u-absolute u-z10"
                :height="localGetDetailArea.height"
                :width="localDetailOverlayPosition.width"
                :style="[{ left: localDetailOverlayPosition.left + 'px', top: localDetailOverlayPosition.top + 'px' }]"
            >
                <partial-expand-completed-activity-summary
                    v-if="currentExpandSlug === 'completedActivity'"
                    no-fetch
                    class="pb-4 u-rounded-corners-xl"
                />
                <partial-competitors-summary
                    v-if="currentExpandSlug === 'competitors'"
                    no-fetch
                    class="pb-4 u-rounded-corners-xl"
                />
                <partial-latest-status-summary
                    v-if="currentExpandSlug === 'statusUpdate'"
                    no-fetch
                />
                <partial-latest-note-summary
                    v-if="currentExpandSlug === 'notes'"
                    noFetch
                />
                <partial-confidence-score-summary
                    v-if="localConfidenceScoreFlag && currentExpandSlug === 'confidenceScore'"
                    noFetch
                />
                <partial-project-tags-summary
                    v-if="['labelTags', 'productTags', 'platformTags'].includes(currentExpandSlug)"
                    class="pb-4 u-rounded-corners-xl"
                    :tagType="currentExpandSlug.split('Tags')[0]"
                    no-fetch
                />
                <partial-completed-reason-summary
                    v-if="currentExpandSlug === 'projectReasons'"
                    no-fetch
                />
                <div v-if="currentExpandSlug === 'territory'" class="pa-3 py-2 u-flex-center-y flex-wrap" style="min-height: 32px">
                    <template v-if="project.aggr_territories">
                        <template v-for="territory in localGetTerritories">
                            <g-tags :tag="territory" :key="territory.id" hide-clear-icon></g-tags>
                        </template>
                    </template>
                </div>
            </a-sheet>
        </v-fade-transition>
    </a-sheet>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import PartialCompetitorsSummary from '../Partials/PartialCompetitorsSummary.vue'
import PartialConfidenceScoreSummary from '../Partials/PartialConfidenceScoreSummary.vue'
import PartialLatestNoteSummary from '../Partials/PartialLatestNoteSummary.vue'
import PartialLatestStatusSummary from '../Partials/PartialLatestStatusSummary.vue'
import PartialProjectActivitySummary from '../Partials/PartialProjectActivitySummary.vue'
import PartialProjectSummaryActions from '../Partials/PartialProjectSummaryActions.vue'
import PartialProjectTagsSummary from '../Partials/PartialProjectTagsSummary.vue'
import PartialScSummary from '../Partials/PartialScSummary.vue'
import PartialTasksSummary from '../Partials/PartialTasksSummary.vue'
import PartialProjectSummary from '../Partials/PartialProjectSummary.vue'
import PartialCompletedReasonSummary from '../Partials/PartialCompletedReasonSummary.vue'
import PartialProjectQueueActivitySummary from '../Partials/PartialProjectQueueActivitySummary.vue'
import PartialCompletedProjectScSummary from '../Partials/PartialCompletedProjectScSummary.vue'
import PartialCompletedProjectTaskSummary from '../Partials/PartialCompletedProjectTaskSummary.vue'
import PartialExpandCompletedActivitySummary from '../Partials/PartialExpandCompletedActivitySummary.vue'
import PartialProjectCompletedActivitySummary from '../Partials/PartialProjectCompletedActivitySummary.vue'

export default {
	components: {
        PartialLatestStatusSummary,
        PartialCompetitorsSummary,
        PartialLatestNoteSummary,
        PartialProjectSummaryActions,
        PartialProjectTagsSummary,
        PartialProjectActivitySummary,
        PartialConfidenceScoreSummary,
        PartialScSummary,
        PartialTasksSummary,
        PartialProjectSummary,
        PartialCompletedReasonSummary,
        PartialProjectQueueActivitySummary,
        PartialCompletedProjectScSummary,
        PartialCompletedProjectTaskSummary,
        PartialExpandCompletedActivitySummary,
        PartialProjectCompletedActivitySummary,
    },

    provide () {
        const modalState = {}

        // Observed object to keep the provider reactive
        // Refer: https://v2.vuejs.org/v2/api/#provide-inject
        Object.defineProperty(modalState, 'isOpen', {
            enumerable: true,
            get: () => this.isOpen
        })

        Object.defineProperty(modalState, 'isConfScoreActive', {
            enumerable: true,
            get: () => this.localConfidenceScoreFlag
        })

        Object.defineProperty(modalState, 'projectId', {
            enumerable: true,
            get: () => this.project && this.project.id
        })

        Object.defineProperty(modalState, 'project', {
            enumerable: true,
            get: () => this.project || {}
        })

        return { modalState }
    },

    data () {
        return {
            currentExpandSlug: '',
            viewTags: false
        }
    },

    props: {
        project: {
            type: Object,
            required: true
        },
        confScoreMeta: {
            type: Array,
        },
        viewSummaryTags: {
            type: Boolean,
            default: false
        },
        isOpen: {
            type: Boolean,
            default: false
        },
    },

    watch: {
        isOpen: {
            handler (val) {
                if (val) this.localIndex()
                else {
                    this.currentExpandSlug = ''
                    this.localDestroyKeyDown()
                }
            },
            immediate: true
        }
    },

    computed: {
        localDetailOverlayPosition () {
            const currentSlug = this.currentExpandSlug.charAt(0).toUpperCase() + this.currentExpandSlug.slice(1)
            if (currentSlug === 'Territory') {
                const territoryContainer = this.$refs[`ref${currentSlug}`].$children[0].$children[0].$refs.refTerritoryParent
                const rect = territoryContainer.getBoundingClientRect()

                return { left: territoryContainer.offsetLeft, top: territoryContainer.offsetTop, width: rect.width }
            }

            if (currentSlug === 'CompletedActivity') {
                const activityContainer = this.$refs[`ref${currentSlug}`].$el
                const reasonsContainer = this.$refs[`ref${currentSlug}`].$children[0].$children[0].$refs.refCompletedReasons
                const reasonsRect = reasonsContainer.getBoundingClientRect()
                const activityRect = activityContainer.getBoundingClientRect()

                return { left: activityContainer.offsetLeft, top: (activityRect.top + activityRect.height) - 118, width: reasonsRect.width }
            }

            const el = this.$refs[`ref${currentSlug}`].$el
            const rect = this.$refs[`ref${currentSlug}`].$el.getBoundingClientRect()
            const topValue = el.offsetTop - this.localGetDetailArea.nudgeTop

            return { left: el.offsetLeft, top: topValue, width: rect.width }
        },

        localGetDetailArea () {
            switch (this.currentExpandSlug) {
                case 'labelTags': return { height: 500, nudgeTop: 320 }
                case 'productTags': return { height: 500, nudgeTop: 320 }
                case 'platformTags': return { height: 500, nudgeTop: 320 }
                case 'competitors': return { height: 500, nudgeTop: this.localConfidenceScoreFlag && this.localIsActiveStage ? 140 : 0 }
                case 'statusUpdate': return { height: 300, nudgeTop: this.localIsQueueStage ? 60 : 0 }
                case 'projectReasons': return { height: 300, nudgeTop: 0 }
                case 'notes': return { height: 300, nudgeTop: this.localConfidenceScoreFlag ? 120 : 0 }
                case 'confidenceScore': return { height: 500, nudgeTop: 120 }
                case 'territory': return { height: 140, nudgeTop: 0 }
                case 'completedActivity': return { height: 200, nudgeTop: 0 }
            }
        },

        localGetTerritories () {
            if (!_.size(this.project.aggr_territories)) return []

            const territoryIds = this.project.aggr_territories.map(i => i.id)
            return this.territory_list.filter(i => territoryIds.includes(i.id))
        },

        localConfidenceScoreFlag () {
            const orgFlag = this.user_self.organization && this.user_self.organization.flag_confidence_score && this.user_self.organization.flag_confidence_score === 1
            const orgPref = this.preference_get_property_by_key('organization.confidence_score', 'value') === 'Yes'

            return orgFlag && orgPref
        },

        localIsQueueStage () {
            return this.project?.status === 'queue'
        },

        localIsCompletedStage () {
            return this.project?.status === 'completed'
        },

        localIsActiveStage () {
            return this.project?.status === 'active'
        },

        ...mapState('ProjectView', {
            project_item: 'item'
        }),

        ...mapState('User', {
            user_self: 'self'
        }),

        ...mapState('Territory', {
            territory_list: 'list'
        }),

        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key'
        }),
    },

    methods: {
        async localIndex () {
            this.viewTags = this.viewSummaryTags
            this.localCreateKeyDown()
        },

        localExpand (slug) {
            this.currentExpandSlug = slug
            this.$nextTick(() => {
                this.$refs.refExpandContainer.$el.scrollIntoView({ block: 'center', behaviour: 'smooth' })
            })
        },

        localCloseExpand () {
            this.currentExpandSlug = ''
            this.$emit('expand', false)
        },

        localOpenInNewWindow (pageName, projectId = (this.project && this.project.id) || '') {
            if (!projectId) return

            const route = this.$router.resolve({ name: pageName, params: { id: projectId } })
            window.open(route.href)
        },

        localCreateKeyDown () {
            document.addEventListener('keydown', this.localKeyDown)
        },

        localDestroyKeyDown () {
            document.removeEventListener('keydown', this.localKeyDown)
        },

        localKeyDown (evt) {
            const { key } = evt || {}
            if (key === 'Escape' && this.currentExpandSlug) {
                this.currentExpandSlug = ''
                this.$nextTick(() => {
                    const dialog = document.querySelector('.v-dialog__content--active') || null
                    if (dialog) dialog.focus()
                })
            }
        },

        localToggleTagsView () {
            this.viewTags = !this.viewTags
            this.$emit('toggleTagsView', this.viewTags)
        },

        ...mapActions('Territory', {
            territory_index: 'index',
        }),

        ...mapActions('ProjectView', {
            project_show: 'show',
            project_clear_item: 'clear_item'
        }),
    },
}
</script>

<template>
	<a-main class="px-6">
        <a-container grid-list-xl container--fluid class="pa-0 ma-0 mt-5">
            <a-layout align-center>
                <a-flex>
                    <h2 class="md-heading-5 font-weight-semibold">Logs</h2>
                    <p class="md-body-2 mb-0 grey--text text--darken-2">Success and error logs from workflow runs.</p>
                </a-flex>
            </a-layout>
        </a-container>

        <a-container v-if="!localLoading && workflow_log_list && workflow_log_list.length === 0" grid-list-xl container--fluid class="pa-0 my-4">

            <a-layout align-center justify-center>
                <a-flex xs4>
                    <div class="text-center"><a-icon size="160" color="grey lighten-1">menu_open</a-icon></div>
                    <h2 class="md-heading-5 mb-1 grey--text text--darken-3 text-center">No Logs</h2>
                    <p class="md-body-2 grey--text text--darken-1 text-center">Setup the workflow and activate to generate logs.</p>
                </a-flex>
            </a-layout>
        </a-container>

        <a-container v-if="!localLoading || localLoading || (workflow_log_list && workflow_log_list.length)" grid-list-xl container--fluid class="pa-0 my-2 mb-10">
            <template v-if="localLoading">
                <a-layout align-center class="mt-6 white u-rounded-corners">
                    <a-flex class="u-flex flex-column pa-6" style="gap: 24px;">
                        <template v-for="i in 7">
                            <div :key="i" class="u-flex-center-y" style="column-gap: 16px;">
                                <loader-template height="32" width="40" class="u-rounded-corners-lg"></loader-template>
                                <loader-template height="32" width="40" class="u-rounded-corners-lg"></loader-template>
                                <loader-template height="32" width="100%" class="u-rounded-corners-lg"></loader-template>
                                <loader-template height="32" width="120" class="u-rounded-corners-lg"></loader-template>
                            </div>
                        </template>
                    </a-flex>
                </a-layout>
            </template>

            <template v-if="!localLoading && (workflow_log_list && workflow_log_list.length)">
                <!--<a-layout align-center>
                    <a-flex>
                        <h2 class="md-heading-5 font-weight-semibold">Logs</h2>
                        <p class="md-body-2 mb-0 grey--text text--darken-2">Success and error logs from workflow runs.</p>
                    </a-flex>
                </a-layout>-->

                <a-layout align-center>
                    <a-flex>
                        <a-card flat class="transparent u-rounded-corners u-overflow-hidden mb-3">
                            <a-card-text class="pa-0">
                                <table class="c-table u-wfull">
                                    <tr class="c-table__row white u-elevation-custom-1 u-rounded-corners" v-for="item in workflow_log_list" :key="item.id">
                                        <td style="width: 120px" class="c-table__cell py-4 px-4 pl-5">
                                            <div class="d-flex align-center">
                                                <img src="../../assets/images/logo-salesforce.svg" alt="Salesforce" style="display: inline-block; max-width: 20px; max-height: 20px;" v-if="item.workflow && item.workflow.trigger && item.workflow.trigger.service && item.workflow.trigger.service.slug === 'salesforce'">
                                                <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" style="display: inline-block; max-width: 20px; max-height: 20px;" v-if="item.workflow && item.workflow.trigger && item.workflow.trigger.service && item.workflow.trigger.service.slug === 'success'">
                                                <a-icon class="mx-2">arrow_right_alt</a-icon>
                                                <img src="../../assets/images/logo-salesforce.svg" alt="Salesforce" style="display: inline-block; max-width: 20px; max-height: 20px;" v-if="item.workflow && item.workflow.action && item.workflow.action.service && item.workflow.action.service.slug === 'salesforce'">
                                                <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" style="display: inline-block; max-width: 20px; max-height: 20px;" v-if="item.workflow && item.workflow.action && item.workflow.action.service && item.workflow.action.service.slug === 'success'">
                                            </div>
                                        </td>
                                        <td style="width: 250px" class="c-table__cell py-4 text-truncate">
                                            <router-link tag="p" :title="item.workflow.title" style="max-width: 200px" class="md-body-2 px-4 text-truncate mb-0 grey--text text--darken-1 u-cursor-pointer"  :to="{name: `appstore-salesforce-workflows-edit`, params: { id: $route.params.id, workflow_id: item.workflow.id }}">
                                                {{ item.workflow.title }}
                                            </router-link>
                                        </td>
                                        <td style="width: 100px" class="c-table__cell py-4">
                                            <div class="mr-2">
                                                <p style="min-width: 100px" class="md-body-2 mb-0 text-left light-green--text text--darken-2" v-if="item.status === 'success'">
                                                    <a-icon color="light-green darken-2" size="16" class="mr-1 u-icon-nudge-xs">check_circle</a-icon>
                                                    Success
                                                </p>
                                                <p style="min-width: 100px" class="md-body-2 mb-0 text-left red--text text--darken-2" v-if="item.status === 'error'">
                                                    <a-icon color="red darken-2" size="16" class="mr-1 u-icon-nudge-xs">error</a-icon>
                                                    Error
                                                </p>
                                                <p style="min-width: 100px" class="md-body-2 mb-0 text-left grey--text text--darken-2" v-if="item.status === 'incomplete'">
                                                    <a-icon color="orange darken-2" size="16" class="mr-1 u-icon-nudge-xs">info</a-icon>
                                                    Incomplete
                                                </p>
                                                <p style="min-width: 100px" class="md-body-2 mb-0 text-left grey--text text--darken-2" v-if="item.status === 'invalid'">
                                                    <a-icon color="grey darken-2" size="16" class="mr-1 u-icon-nudge-xs">check_circle</a-icon>
                                                    Invalid
                                                </p>
                                            </div>
                                        </td>
                                        <td class="c-table__cell py-4">
                                            <div>
                                                <div class="u-flex align-center u-cursor-pointer" @click="local_show_detail(item)">
                                                    <a-icon size="14" color="grey darken-1" class="mr-2">launch</a-icon>
                                                    <h2 class="md-body-2 grey--text text--darken-1">{{ local_get_response_message(item) }}</h2>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width:200px" class="c-table__cell py-4 text-truncate">
                                            <p style="width:200px" class="md-body-2 pr-6 mb-0 text-truncate grey--text text-right">
                                                <g-moment :value="item.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </a-card-text>
                        </a-card>
                    </a-flex>
                </a-layout>
            </template>
        </a-container>

        <a-dialog v-model="show_detail_view" max-width="900">
            <a-card class="u-elevation-custom-1 u-rounded-corners pa-0">
                <a-card-title class="px-8 pt-5 pb-4 grey lighten-4" style="line-height: normal !important">
                    <h2 class="md-subtitle-1 grey--text text--darken-2 mb-2 text-truncate" style="max-width: 700px;">{{ detail_item.title }}</h2>
                    <div class="u-flex align-center u-wfull mt-n1">
                        <div class="d-flex align-center">
                            <img src="../../assets/images/logo-salesforce.svg" alt="Salesforce" style="display: inline-block; max-width: 20px; max-height: 20px;" v-if="detail_item.workflow && detail_item.workflow.trigger && detail_item.workflow.trigger.service && detail_item.workflow.trigger.service.slug === 'salesforce'">
                            <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" style="display: inline-block; max-width: 16px; max-height: 16px;" v-if="detail_item.workflow && detail_item.workflow.trigger && detail_item.workflow.trigger.service && detail_item.workflow.trigger.service.slug === 'success'">
                            <a-icon class="mx-2">arrow_right_alt</a-icon>
                            <img src="../../assets/images/logo-salesforce.svg" alt="Salesforce" style="display: inline-block; max-width: 20px; max-height: 20px;" v-if="detail_item.workflow && detail_item.workflow.action && detail_item.workflow.action.service && detail_item.workflow.action.service.slug === 'salesforce'">
                            <img src="../../assets/images/success-imgs/icon-success.svg" alt="Success" style="display: inline-block; max-width: 16px; max-height: 16px;" v-if="detail_item.workflow && detail_item.workflow.action && detail_item.workflow.action.service && detail_item.workflow.action.service.slug === 'success'">
                        </div>
                        <a-icon class="mx-2 ml-4" size="4" color="grey darken-1">fiber_manual_record</a-icon>
                        <div>
                            <div class="md-caption mb-0 light-green--text text--darken-2" v-if="detail_item.status === 'success'">
                                <a-icon color="light-green darken-2" size="16">check_circle</a-icon>
                                Success
                            </div>
                            <div class="md-caption mb-0 red--text text--darken-2" v-if="detail_item.status === 'error'">
                                <a-icon color="red darken-2" size="16">error</a-icon>
                                Error
                            </div>
                            <div class="md-caption mb-0 grey--text text--darken-2" v-if="detail_item.status === 'incomplete'">
                                <a-icon color="orange darken-2" size="16">info</a-icon>
                                Incomplete
                            </div>
                            <div class="md-caption mb-0 grey--text text--darken-2" v-if="detail_item.status === 'invalid'">
                                <a-icon color="grey darken-2" size="16">check_circle</a-icon>
                                Invalid
                            </div>
                        </div>
                        <a-icon class="mx-3" size="4" color="grey darken-1">fiber_manual_record</a-icon>
                        <div class="md-caption mb-0 text-truncate grey--text">
                            <g-moment :value="detail_item.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                        </div>
                    </div>
                </a-card-title>
                <a-divider class="grey lighten-3"></a-divider>
                <a-card-text class="px-8 pt-5 pb-4" style="max-height: 500px; overflow-y: auto">
                    <div>
                        <h2 class="md-caption grey--text text--darken-2 text-uppercase font-weight-medium mb-2" style="letter-spacing: 0.8px">Request</h2>
                        <table class="c-table-desc u-wfull" style="border-collapse: collapse; border: 1px solid #000">
                            <tr class="">
                                <td class="c-table-desc__cell" style="width: 160px;">
                                    <span class="md-body-2 font-weight-medium grey--text text--darken-3 mr-2">Salesforce Link</span>
                                </td>
                                <td class="c-table-desc__cell">
                                    <div class="md-caption blue--text text--darken-1" style="font-family: monospace !important" v-if="check_salesforce_instance_url && detail_item.status === 'success'">
                                        <a :href="user_self.organization.salesforce_instance_url + '/' + detail_item.trigger_resource_id" target="_blank" rel="noopener" class="md-body-2 mb-0 blue--text text--darken-2 u-no-underline d-block text-no-wrap text-truncate" style="max-width: 360px;">{{ user_self.organization.salesforce_instance_url + '/' + detail_item.trigger_resource_id }}</a>
                                    </div>
                                    <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important" v-else>-</div>
                                </td>
                            </tr>
                            <tr class="">
                                <td class="c-table-desc__cell" style="width: 160px;">
                                    <span class="md-body-2 font-weight-medium grey--text text--darken-3 mr-2">Salesforce ID</span>
                                </td>
                                <td class="c-table-desc__cell">
                                    <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important" v-if="detail_item.status === 'success'">{{ detail_item.trigger_resource_id }}</div>
                                    <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important" v-else>-</div>
                                </td>
                            </tr>
                            <tr class="">
                                <td class="c-table-desc__cell" style="width: 160px;">
                                    <span class="md-body-2 font-weight-medium grey--text text--darken-3 mr-2">Impacted Resource</span>
                                </td>
                                <td class="c-table-desc__cell">
                                    <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important">{{ detail_item.action_resource_type }}</div>
                                </td>
                            </tr>
                            <tr class="">
                                <td class="c-table-desc__cell" style="width: 160px;">
                                    <span class="md-body-2 font-weight-medium grey--text text--darken-3 mr-2">Status Code</span>
                                </td>
                                <td class="c-table-desc__cell">
                                    <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important" v-if="detail_item.code">{{ detail_item.code }}</div>
                                    <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important" v-else>-</div>
                                </td>
                            </tr>
                            <tr class="">
                                <td class="c-table-desc__cell" style="width: 160px;">
                                    <span class="md-body-2 font-weight-medium grey--text text--darken-3 mr-2">Message</span>
                                </td>
                                <td class="c-table-desc__cell">
                                    <h2 class="md-body-2 grey--text text--darken-1">{{ local_get_response_message(detail_item) }}</h2>
                                </td>
                            </tr>
                            <tr class="">
                                <td class="c-table-desc__cell" style="width: 160px;">
                                    <span class="md-body-2 font-weight-medium grey--text text--darken-3 mr-2">Response</span>
                                </td>
                                <td class="c-table-desc__cell" v-if="detail_item.status === 'invalid' || detail_item.status === 'incomplete'">
                                    <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important">-</div>
                                </td>
                                <td class="c-table-desc__cell" v-else>
                                    <pre>{{ detail_item.response }}</pre>
                                </td>
                            </tr>
                        </table>
                    </div>
                </a-card-text>
                <template v-if="detail_item.mappings && detail_item.workflow && detail_item.workflow.mappings && detail_item.workflow.mappings.length">
                    <a-card-text class="px-8 pb-4" style="max-height: 500px; overflow-y: auto">
                        <div>
                            <h2 class="md-caption grey--text text--darken-2 text-uppercase font-weight-medium mb-2 mt-2" style="letter-spacing: 0.8px">Mappings</h2>
                            <table class="c-table-desc u-wfull" style="border-collapse: collapse; border: 1px solid #000">
                                <tr class="" style="text-align: left;">
                                    <th class="c-table-desc__cell">Field</th>
                                    <th class="c-table-desc__cell">Value</th>
                                </tr>
                                <template v-for="item in detail_item.workflow.mappings">
                                    <tr class="" :key="item.id">
                                        <td class="c-table-desc__cell" style="width: 160px;">
                                            <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important">{{ item.field_name }}</div>
                                        </td>
                                        <td class="c-table-desc__cell">
                                            <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important">{{ detail_item.mappings[item.field_name] }}</div>
                                        </td>
                                    </tr>
                                </template>
                            </table>
                        </div>
                    </a-card-text>
                </template>
                <template v-if="detail_item.conditions && detail_item.conditions.fields && detail_item.conditions.fields.length">
                    <a-card-text class="px-8 pb-4" style="max-height: 500px; overflow-y: auto">
                        <div>
                            <h2 class="md-caption grey--text text--darken-2 text-uppercase font-weight-medium mb-2 mt-2" style="letter-spacing: 0.8px">Condition</h2>
                            <table class="c-table-desc u-wfull" style="border-collapse: collapse; border: 1px solid #000">
                                <tr class="" style="text-align: left;">
                                    <th class="c-table-desc__cell">Field</th>
                                    <th class="c-table-desc__cell">Condition Value</th>
                                    <th class="c-table-desc__cell">Operator</th>
                                    <th class="c-table-desc__cell">Source value</th>
                                    <th class="c-table-desc__cell" style="text-align: center;">Valid</th>
                                </tr>
                                <template v-for="item in detail_item.conditions.fields">
                                    <tr class="" :key="item.id">
                                        <td class="c-table-desc__cell">
                                            <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important">{{ item.name }}</div>
                                        </td>
                                        <td class="c-table-desc__cell">
                                            <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important">{{ item.condition_value }}</div>
                                        </td>
                                        <td class="c-table-desc__cell">
                                            <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important">{{ item.operator }}</div>
                                        </td>
                                        <td class="c-table-desc__cell">
                                            <div class="md-caption grey--text text--darken-1" style="font-family: monospace !important">{{ item.source_value }}</div>
                                        </td>
                                        <td class="c-table-desc__cell">
                                            <div class="md-caption" style="text-align: center;" v-if="item.is_valid === true">
                                                <a-icon color="light-green darken-2" size="16">check_circle</a-icon>
                                            </div>
                                            <div class="md-caption" style="text-align: center;" v-if="item.is_valid === false">
                                                <a-icon color="red darken-2" size="16">error</a-icon>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </table>
                        </div>
                    </a-card-text>
                </template>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data () {
        return {
            show_detail_view: false,
            localLoading: true,
            detail_item: {},
            local_workflow_log_filter:  {
                'filter[workflow_type]': 'salesforce',
                'fields[workflow_logs]': 'id,status,code,conditions,mappings,action_resource_type,trigger_resource_id,response,workflow_id,created_at',
                'fields[workflow]': 'id,title,applet_trigger_id,applet_action_id',
                'fields[workflow.trigger]': 'applet_triggers.id,applet_triggers.applet_service_id',
                'fields[workflow.action]': 'applet_actions.id,applet_actions.slug,applet_actions.applet_service_id',
                'fields[workflow.trigger.service]': 'applet_services.id,applet_services.slug',
                'fields[workflow.action.service]': 'applet_services.id,applet_services.slug',
                'fields[workflow.mappings]': 'workflow_mappings.id,workflow_mappings.field_name,workflow_mappings.value,workflow_mappings.is_valid',
                'include': 'workflow.trigger.service,workflow.action.service,workflow.mappings',
                'sort': '-created_at'
            },
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        check_salesforce_instance_url() {
            return this.user_self && this.user_self.organization && this.user_self.organization.salesforce_instance_url
        },

        ...mapState('WorkflowLog', {
            workflow_log_list: 'list',
            workflow_log_filters: 'filters',
        }),

        ...mapState('Installation', {
            installation_item: 'item',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index () {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.workflow_log_clear()
            await this.workflow_log_index(this.local_workflow_log_filter)
            this.localLoading = false
        },

        local_show_detail (item) {
            this.detail_item = { ...item }
            this.show_detail_view = true
        },

        local_get_response_message (item) {
            if (item.status === 'invalid') {
                if (!item.condition && item.response) return JSON.parse(item.response).message
                if (item.conditions && !item.conditions.result) return 'Conditions not satisfied'
            }

            if (item.status === 'success') return 'Successfully connected'
            if (item.status === 'incomplete') return 'Workflow failed, please contact support'
            if (item.status === 'error') return `HTTP request returned status code ${ item.code }`

            return null
        },

        ...mapActions('Installation', {
            installation_show: 'show',
            installation_clear_item: 'clear_item',
        }),

        ...mapActions('WorkflowLog', {
            workflow_log_index: 'index',
            workflow_log_clear: 'clear',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .c-table {
        display: table !important;
        border-collapse: separate;
        border-spacing: 0 15px;
        &__cell {
            display: table-cell !important;
            white-space: nowrap !important;
            background: #fff !important;
            &:first-child {
                border-radius: 4px 0px 0px 4px;
            }
            &:last-child {
                border-radius: 0px 4px 4px 0px;
            }
        }
    }
    .c-table-desc {
        display: table;
        border-collapse: collapse !important;
        &__cell {
            display: table-cell !important;
            padding: 8px;
            vertical-align: top !important;
            white-space: normal !important;
            border: 1px solid #e4e4e4;
            &:first-child {
                border-radius: 4px 0px 0px 4px;
            }
            &:last-child {
                border-radius: 0px 4px 4px 0px;
            }
        }
    }
</style>

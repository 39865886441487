export default {
    methods: {
        check_meta_permission(type, client_allowed = false) {
            if (this.user_self.role.scope === 'external') {
                return client_allowed
            }
            return this.$can(`metas.${type}`) && this.user_self.role.scope === 'internal'
        }
    }
}

<template>
    <div class="u-flex-center-y">
        <div class="px-4">
            <SAutomationPreHookLoader/>
        </div>

        <!-- TODO: Implement once websockets is available in backend -->
        <!-- <shared-user-notifications>
            <template #default="{ hasUnread, hasUnreadCount }">
                <div v-if="hasUnread" class="navigation__item pa-0 u-relative">
                    <a-sheet
                        class="u-absolute u-flex-center md-caption u-font-weight-semibold u-rounded-corners red darken-1 white--text"
                        width="18"
                        height="18"
                        style="top: 0px; right: 0px;"
                    >
                        {{ hasUnreadCount }}
                    </a-sheet>
                    <a-icon size="20" color="red darken-1">notifications_active</a-icon>
                </div>
                <div v-else class="navigation__item">
                    <a-icon size="20" color="grey darken-2" outlined>notifications</a-icon>
                </div>
            </template>
        </shared-user-notifications> -->

        <a-tooltip bottom offset-y content-class="c-tooltip-pointer c-tooltip-pointer--right">
            <template v-slot:activator="{ on }">
                <div v-on="on">
                    <a href="https://www.notion.so/What-s-new-42338792f7594ebba97b9da58639a8b1" target="_blank" class="navigation__item">
                        <a-icon outlined>redeem</a-icon>
                    </a>
                </div>
            </template>
            <span>What's new?</span>
        </a-tooltip>
        <a-tooltip bottom offset-y content-class="c-tooltip-pointer c-tooltip-pointer--right">
            <template v-slot:activator="{ on }">
                <div v-on="on">
                    <router-link :to="{name: 'support'}" target="_blank" class="navigation__item">
                        <a-icon outlined>help_outline</a-icon>
                    </router-link>
                </div>
            </template>
            <span>Support</span>
        </a-tooltip>
        <a-tooltip bottom offset-y content-class="c-tooltip-pointer c-tooltip-pointer--right">
            <template v-slot:activator="{ on }">
                <div v-on="on">
                    <router-link :to="{name: 'teams-enabled'}" v-if="user_self && user_self.scope === 'internal' && user_self.is_admin === 1" class="navigation__item">
                        <a-icon outlined>people_alt</a-icon>
                    </router-link>
                </div>
            </template>
            <span>Teams</span>
        </a-tooltip>
        <a-tooltip bottom offset-y content-class="c-tooltip-pointer c-tooltip-pointer--right">
            <template v-slot:activator="{ on }">
                <div v-on="on">
                    <router-link :to="{name: 'settings'}" class="navigation__item" v-test-link.settings>
                        <a-icon outlined>settings</a-icon>
                    </router-link>
                </div>
            </template>
            <span>Settings</span>
        </a-tooltip>
        <s-profile-header/>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SProfileHeader from '/src/components/Shared/SharedProfileHeader.vue'
import { SAutomationPreHookLoader } from '@/config/config-shared-components'
import { storage } from '@/helpers/helper-local-storage'

/* TODO: Implement once websockets is available in backend */
// import SharedUserNotifications from '../../../components/Shared/SharedUserNotifications.vue'

export default {
    components: {
        SProfileHeader,
        SAutomationPreHookLoader,
        // SharedUserNotifications /* TODO: Implement once websockets is available in backend */
    },

    computed: {
        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Auth', {
            auth_response: 'response',
        }),
    },

    methods: {
        async local_logout () {
            await this.auth_logout()
            if (this.auth_response.status === 'success') {
                storage.removeOrgKeys()
                window.location.replace('/login')
            }
        },

        ...mapActions('Auth',{
            auth_logout: 'logout',
        }),
    }
}
</script>

<style lang="scss" scoped>
    .navigation__item {
        font-size: 0.8rem;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 500;
        padding: 0 1rem;
        display: block;
        color: rgba(0, 0, 0, 0.6);
        text-decoration: none;
        &--normal {
            font-size: 0.95rem !important;
            text-transform: capitalize !important;
            letter-spacing: auto !important;
        }
    }
    .navigation__item:hover {
        color: #222;
    }
</style>

<template>
	<a-main :class="[{ 'c-project-header-bg': !localIsExternalView }]">
        <div class="u-flex" :class="{ 'white': !localIsExternalView }">
            <a-sheet
                v-if="!localIsExternalView"
                min-height="100vh"
                :min-width="configSidebar ? 280 : 24"
                :max-width="configSidebar ? 280 : 24"
                @click="!configSidebar ? localToggleSidebar(true) : {}"
                class="c-project-sidebar"
                :class="[{
                  'c-project-sidebar--open': configSidebar,
                  'c-project-sidebar--close u-cursor-pointer': !configSidebar,
                }]"
            >
                <div @click.stop="localToggleSidebar(!configSidebar)" :class="['c-sidebar-toggle-border u-cursor-pointer', showSidebar ? 'c-sidebar-toggle-border--bg-none' : 'c-sidebar-toggle-border--no-color']">
                    <div :class="[showSidebar ? 'c-sidebar-toggle--open' : 'c-sidebar-toggle--closed']" class="c-sidebar-toggle u-rounded-corners-full u-shadow align-center justify-center">
                        <a-icon size="16" color="grey darken-2">chevron_{{ configSidebar ? 'left' : 'right' }}</a-icon>
                    </div>
                </div>
                <PProjectSidebar class="c-sidebar-component" :class="[{ 'c-hide-sidebar-elements': !showSidebar }]"></PProjectSidebar>
            </a-sheet>
            <a-sheet class="pt-0 pb-0 mx-auto transparent u-wfull u-hfull" :class="{ 'px-0': localIsExternalView }" :min-width="localIsExternalView ? '100%' : 854" :max-width="localIsExternalView ? '100%' : '100%'">
                <div :class="[{ 'pt-6 pa-6': !localIsExternalView }, 'u-hfull']">
                    <router-view class="u-hfull"></router-view>
                </div>
            </a-sheet>
        </div>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import loadWorkspace from '@/mixins/mixin-workspace'
import mixinLoading from '@/mixins/mixin-module-loading-setup'

import PProjectSidebar from './Partials/PartialProjectSidebar.vue'
import PGlobalSettings from './Partials/PartialGlobalSettings.vue'
import { SWorkspaceSwitch } from '@/config/config-shared-components'
import { storage } from '@/helpers/helper-local-storage';
import { v4 as uuidv4 } from 'uuid';

export default {
    mixins: [
        loadWorkspace,
        mixinLoading
    ],

    components: {
        SWorkspaceSwitch,
        PGlobalSettings,
        PProjectSidebar,
    },

    data () {
        return {
            result_list: [],
            project_move_parameters: {project_id: null, project_title: null, stage_to_id: null, result_id: null},
            dialog_stage_completed: false,
            subRoute: null,
            showSidebar: true,
            currentUserConfig: null
        }
    },

    mounted () {
        document.addEventListener('keydown', this.localKeydown)
        this.local_index()
    },

    beforeDestroy () {
        document.removeEventListener('keydown', this.localKeydown)
    },

    watch: {
        configSidebar: {
            handler (val) {
                this.showSidebar = val
                // if (!val) setTimeout(() => this.showSidebar = false, 60)
                // if (val) setTimeout(() => this.showSidebar = true, 300)
            },
            immediate: true
        },

        '$route': {
            handler (to, from) {
                this.subRoute = null
                const subRoutes = ['projects-tickets-view', 'projects-notes-view', 'projects-usecases-template-import']
                const routeName = to.name
                if (!subRoutes.includes(routeName)) return
                this.subRoute = { parentSlug: from.name, parentName: from.meta.label, title: to.meta.label }
            },
        },
    },

    computed: {
        localCurrentRoute () {
            return this.$route.meta.label
        },

        localUserExternal () {
            return this.user_self && this.user_self.scope === 'external'
        },

        localIsExternalView () {
            return this.$route.meta.view && this.$route.meta.view === 'external'
        },

        localCustomerName () {
            return this.project_item && this.project_item.customer && this.project_item.customer.name
        },

        ...mapState('ProjectView', {
            project_item: 'item',
            project_related_item: 'related_item',
            project_response: 'response',
        }),

        ...mapState('Stage', {
            stage_list: 'list',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
            meta_filters: 'filters',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Preference', {
            preference_list: 'list',
        }),

        ...mapState('Configuration', {
            configSidebar: 'sidebar',
        }),
    },

    methods: {
        async local_index() {
            this.mixinSetLoading('root')
            await this.project_show({
                id: this.$route.params.id ,
                params: { 'fields[projects]': 'id,workspace_id,title,stage_id' }
            })
            this.mixinResetLoading('root')
            this.local_fetch_proj_collabs()
            this.local_fetch_user_config()
            if (this.project_item.workspace_id !== this.current_workspace_id_mixin) {
                const has_workspace = this.active_workspaces_mixin.find(workspace => workspace.id === this.project_item.workspace_id)
                return this.$router.replace({ name: has_workspace ? 'dashboard-columns' : '404' })
            }
        },

        async local_fetch_proj_collabs () {
            const params = { 'filter[project_id]': this.$route.params.id, count: 1000, page: 1 }
            this.collaborator_index({ ...params, include: 'user.role,type,contact' })
        },

        async local_fetch_user_config () {
            await this.preference_index()
            const preferences = _.cloneDeep(this.preference_list)
            const config = preferences.find(i => i.key === 'user.config')
            if (!config) return this.localUpsertUserPref({ type: 'new' })

            const { projectSidebar } = JSON.parse(config.value) || {}
            this.showSidebar = projectSidebar
            this.currentUserConfig = config
            this.configToggleSidebar(projectSidebar)
        },

        async local_project_stage_update_init(project_id, project_title, stage_to_id, result_id = null) {
            let parameters = {
                project_id: project_id,
                project_title: project_title,
                stage_to_id: stage_to_id,
                result_id: result_id,
            }

            if (parameters.stage_to_id === 'completed') {
                await this.local_project_stage_update_result(parameters)
            } else {
                await this.local_project_stage_update_confirm(parameters)
            }
        },

        async local_project_stage_update_result(parameters) {
            this.project_move_parameters = parameters
            this.dialog_stage_completed = true;
        },

        async local_project_stage_update_cancel(parameters) {
            this.project_move_parameters = {project_id: null, project_title: null, stage_to_id: null, result_id: null}
            this.dialog_stage_completed = false;
        },

        async local_project_stage_update_confirm(parameters) {
            await this.project_stage_update({project_id: parameters.project_id, stage_id: parameters.stage_to_id, result_id: parameters.result_id})
            if (this.project_response.status == 'success') {
                this.project_move_parameters = {project_id: null, project_title: null, stage_to_id: null, result_id: null}
                this.dialog_stage_completed = false;
                await this.project_show({ id: this.$route.params.id })
            }
        },

        localToggleSidebar (value) {
            // this.showSidebar = value
            this.configToggleSidebar(value)
            this.localUpsertUserPref()
        },

        localUpsertUserPref (props) {
            const { type } = props || {}
            const params = {
                key: 'user.config',
                resource_id: this.user_self.id,
                resource_type: 'User',
                label: 'User Preference',
                value: JSON.stringify({ projectSidebar: this.configSidebar })
            }
            if (type === 'new') this.currentUserConfig = { ...params, id: uuidv4(), }
            if (type !== 'new') {
                this.currentUserConfig = {
                    ...this.currentUserConfig,
                    value: JSON.stringify({ ...JSON.parse(this.currentUserConfig.value), projectSidebar: this.configSidebar })
                }
            }

            this.preference_upsert(this.currentUserConfig)
        },

        localKeydown (evt) {
            this.configSetKeydown(evt)
        },

        ...mapActions('ProjectView', {
            project_show: 'show',
            project_clear_item: 'clear_item',
        }),

        ...mapActions('Collaborator', {
            collaborator_index: 'index',
        }),

        ...mapActions('Preference', {
            preference_index: 'index',
            preference_upsert: 'upsert_store',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),

        ...mapActions('Configuration', {
            configToggleSidebar: 'toggleSidebar',
            configSetKeydown: 'setKeydown'
        }),
    }
}
</script>

<style lang="scss" scoped>
    .c-normal {
        position: relative !important;
        right: 0px !important;
        display: block !important;
    }
    .c-move-right {
        position: absolute !important;
        right: -1000px !important;
        display: none !important;
    }
    .layout-container {
        display: flex;
        // min-height: calc(100vh - 60px);
        height: calc(100vh - 105px);
        // height: 100%;
        overflow-y: auto !important;
    }
    .layout-container__item {
        height: calc(100vh - 105px);
        overflow-y: auto !important;
        flex: 1 1 auto;
    }
    .layout-container__item--overview {
        width: 348px;
        flex: 0 0 auto;
    }
    .layout-container__item--activities {
        width: 320px;
        flex: 0 0 auto;
    }
    .c-nav-style {
        position: sticky;
        top: 0;
        z-index: 10;
        backdrop-filter: blur(6px);
        -webkit-backdrop-filter: blur(6px);
        --webkit-backdrop-filter: blur(6px);
        transition: 0.4s all;
    }
    .c-sidebar-toggle-border {
        position: absolute;
        width: 1px;
        top: 0;
        bottom: 0;
        right: 0px;
        z-index: 1;

        &--colored {
            background-color: #0a74ac;
        }

        &--no-color {
            background-color: #eeeeee !important;
        }

        &--bg-none {
            background-color: transparent;
        }
    }
    .c-project-header-bg {
        background: #FAFAFA;
    }
</style>

<template>
    <a-menu
        v-model="menuModal"
        :close-on-content-click="false"
        :disabled="disabled"
        nudge-bottom="6"
        min-width="300"
        max-width="300"
        content-class="u-rounded-corners-xl"
        offset-y bottom
    >
        <template v-slot:activator="{ on, value }">
            <slot name="trigger-wrap" :on="on">
                <a-btn v-on="on" icon depressed class="u-rounded-corners" :class="[{ 'grey darken-3': value }]" height="32">
                    <slot name="trigger">
                        <a-icon :color="disabled ? 'grey darken-2' : 'white'" size="20">{{ triggerIcon }}</a-icon>
                    </slot>
                </a-btn>
            </slot>
        </template>
        <a-list class="u-list-condensed u-list-condensed--round-select u-list-condensed--dark u-overflow-y grey darken-4 py-0">
            <!-- <a-list-item class="text-left px-5 py-1">
                <div class="u-flex-center-y">
                    <a-icon color="white" size="20" class="mr-1">import_export</a-icon>
                    <span class="md-subtitle-2 font-weight-medium white--text">Move Tasks between Milestones</span>
                </div>
            </a-list-item> -->
            <a-list-item class="text-left px-5 py-1 white--text grey darken-4">
                <a-list-item-title class="md-subtitle-2 font-weight-medium">
                    <slot name="title">Move Tasks between Milestones</slot>
                </a-list-item-title>
            </a-list-item>
            <!-- <a-divider class="grey darken-3"></a-divider> -->
            <input
                v-if="localListLength > 6"
                v-model="searchText"
                type="text"
                class="grey darken-3 white--text px-5 py-2 u-wfull md-body-2"
                :placeholder="placeholder"
                ref="refSearchText"
            >
        </a-list>
        <a-list
            class="u-list-condensed u-list-condensed--round-select u-list-condensed--dark u-overflow-y grey darken-4 c-tiny-scroll"
            :class="[{ 'pt-0': localListLength <= 6 }]"
            :style="[{ maxHeight: maxHeight + 'px' }]"
        >
            <a-list-item v-if="localFilteredList && !localFilteredList.length" class="md-body-2 white--text text-left px-4 mx-1 py-1">
                <a-list-item-title class="text-left md-body-2">
                    <slot name="noData">No Milestone Found</slot>
                </a-list-item-title>
            </a-list-item>
            <template v-else>
                <a-list-item
                    v-for="item in localFilteredList"
                    :key="item.id"
                    :title="item[itemText]"
                    @click="localEmit({ item })"
                    class="u-rounded-corners-lg px-4 py-1 mx-1 grey--text text--lighten-1"
                >
                    <a-list-item-title class="md-body-2">{{ item[itemText] }}</a-list-item-title>
                </a-list-item>
            </template>
        </a-list>
        <a-list v-if="allowAction" class="u-list-condensed u-list-condensed--round-select u-list-condensed--dark grey darken-4 py-0">
            <a-divider class="grey darken-3"></a-divider>
            <slot name="action">
                <a-list-item class="text-left px-4 py-1 mx-1 white--text" @click="localEmit({ mode: 'create' })">
                    <a-list-item-title class="md-body-2 font-weight-medium">
                        <slot name="actionTitle">Move to New Milestone</slot>
                    </a-list-item-title>
                    <a-list-item-icon v-if="showActionIcon">
                        <a-icon size="18" color="white">{{ actionIcon }}</a-icon>
                    </a-list-item-icon>
                </a-list-item>
            </slot>
        </a-list>
    </a-menu>
</template>

<script>
export default {
    props: {
        itemText: {
            type: String,
            default: 'title'
        },
        triggerIcon: {
            type: String,
            default: 'import_export'
        },
        placeholder: {
            type: String,
            default: 'Search milestones...'
        },
        actionIcon: {
            type: String,
            default: 'content_copy'
        },
        minHeight: {
            type: String | Number,
            default: 224
        },
        maxHeight: {
            type: String | Number,
            default: 224
        },
        list: {
            type: Array,
            default: () => []
        },
        allowAction: {
            type: Boolean,
            default: false
        },
        showActionIcon: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            searchText: '',
            menuModal: false,
        }
    },

    watch: {
        menuModal (val) {
            if (val && this.$refs.refSearchText) {
                setTimeout(() => this.$refs.refSearchText.focus(), 100)
            }
        }
    },

    computed: {
        localFilteredList () {
            const query = this.searchText && this.searchText.trim() ? this.searchText.toLowerCase() : null
            if (!query) return this.list

            return this.list.filter(milestone => {
                return milestone[this.itemText].toLowerCase().includes(query)
            })
        },

        localListLength () {
            return this.list && this.list.length
        }
    },

    methods: {
        localEmit ({ item, mode = 'select' } = {}) {
            this.$emit('change', { item, mode })
            this.menuModal = false
        }
    }
}
</script>

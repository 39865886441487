<template>
	<a-main>
        <a-container grid-list-xl class="pa-6">
            <g-page-header
                title="Apps"
                subtitle="Install third-party applications and connect with existing software in your workflow to automate."
                icon="apps"
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
            ></g-page-header>
            <tab-app></tab-app>
            <a-layout wrap align-center>
                <a-flex xs12 class="my-2" v-for="installation in installation_list" :key="installation.uuid">
                    <a-card class="grey lighten-4 u-elevation-custom-1 u-rounded-corners u-overflow-hidden">
                        <a-card-text class="pa-4">
                            <a-layout align-start>
                                <a-flex shrink>
                                    <div class="u-elevation-custom-1 white" style="display: flex; align-items: center; justify-content: center; width: 64px; height: 64px; border-radius: 0.75rem;">
                                        <img :src="installation.applet.icon" :alt="installation.applet.title" style="display: block; max-width: 32px; max-height: 32px;">
                                    </div>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 mt-1">{{ installation.applet.title }}</h2>
                                    <p class="md-subtitle-1 grey--text text--darken-1 mb-0">{{ installation.applet.subtitle }}</p>
                                    <p class="md-caption grey--text text--darken-1 mt-1 mb-0" v-if="installation.installer">Installed by {{ installation.installer.name }} on <g-moment :value="installation.created_at" input-format="YYYY-MM-DD" output-format="MMMM DD, YYYY"></g-moment></p>
                                </a-flex>
                                <a-flex shrink>
                                    <a-btn color="grey darken-3" class="elevation-0 pa-3 mx-0 mt-3" dark small :to="{name: 'appstore-installation-configure', params: {uuid: installation.uuid}}">
                                        <a-icon class="mr-2" size="18" left>settings_input_antenna</a-icon> Configure
                                    </a-btn>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { TabApp } from '@/config/config-tab-components'

export default {
    components: {
        TabApp
    },
    data() {
        return {
            expansion_panel: [true],
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Apps', to: '/appstore', exact: true})
            breadcrumbs.push({text: 'Installed Apps', to: '/appstore/installed', exact: true})
            return breadcrumbs
        },
        ...mapState('Installation', {
            installation_list: 'list',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            if (this.user_self.role.scope === 'external') {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            this.installation_clear();
            this.installation_index();
        },
        ...mapActions('Installation', {
            installation_index: 'index',
            installation_clear: 'clear',
        }),
    },
}
</script>

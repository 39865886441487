import { kebabToCamelCase } from './helper-case-convertor'

const appRoutes = {}

export const setupAppRouteFns = (router, childRoutes = []) => {
    const routesArr = childRoutes.length ? childRoutes : router.options?.routes
    if (!routesArr || (routesArr && !routesArr.length)) return

    for (let index = 0; index < routesArr.length; index++) {
        const route = routesArr[index]
        if (route?.children && route?.children.length) {
            // if (!route?.name) continue
            setupAppRouteFns(router, route.children)
        }

        if (route.name) {
            Object.assign(appRoutes, {
                [`is` + kebabToCamelCase(route.name)]: () => {
                    return router.currentRoute.name === route.name;
                }
            })
        }
    }

    Object.assign(router, { route: appRoutes })
    Object.assign(router, {
        isActive: () => {
            const matchedRoute = router.matcher.match(router.currentRoute.name, router.currentRoute)
            return router.currentRoute.name === matchedRoute.name
        }
    })

    return router
}

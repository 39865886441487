<template>
    <a-menu bottom left offset-y content-class="u-rounded-corners-lg" nudge-bottom="3">
        <template v-slot:activator="{ on }">
            <div v-on="on" style="height: 40px;" class="ma-0 py-2 pl-2 pr-4 u-flex-center-y u-cursor-pointer u-rounded-corners-lg u-border" v-ripple="true">
                <a-icon size="20" color="grey darken-1" class="mr-1">more_vert</a-icon>
                <span class="md-subtitle-2 grey--text text--darken-3">More Options</span>
            </div>
        </template>
        <a-list class="u-list-std">
            <a-list-item v-if="permission_link('store') && $can('templates.index') && $can('projects.update-only')" @click="$emit('import')">
                <a-list-item-content class="md-body-2 grey--text text--darken-3">Import from template</a-list-item-content>
            </a-list-item>
            <a-list-item @click="!$can('templates.store') || !linksCount ? '' : $emit('export')">
                <a-list-item-content class="md-body-2" :class="[!$can('templates.store') || !linksCount ? 'grey--text': 'grey--text text--darken-3']">Export as Template</a-list-item-content>
            </a-list-item>
        </a-list>
    </a-menu>
</template>

<script>
export default {
    props: {
        linksCount: {
            type: Number
        }
    },

    methods: {
        permission_link (type) {
            return this.$can(`links.${type}`) && this.$can('projects.update-only')
        },
    }
}
</script>

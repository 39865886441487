<template>
    <a-container v-on="$listeners" container--fluid grid-list-xl class="pa-6">
        <a-layout wrap align-center>
            <a-flex xs12>
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                <a-text-field
                    v-model="user_item.name"
                    placeholder="Enter Name"
                    background-color="neutral"
                    solo flat hide-details
                >
                </a-text-field>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="user_response.server && user_response.server.errors && user_response.server.errors.name">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ user_response.server.errors.name[0] }}
                </span>
                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                    <a-icon size="16">info</a-icon>
                    The name will be helpful to identify the user.
                </span>
            </a-flex>
            <a-flex xs12>
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Email</label>
                <a-text-field
                    v-model="user_item.email"
                    placeholder="Enter Email Address"
                    background-color="neutral"
                    solo flat hide-details
                >
                </a-text-field>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="user_response.server && user_response.server.errors && user_response.server.errors.email">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ user_response.server.errors.email[0] }}
                </span>
                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                    <a-icon size="16">info</a-icon>
                    Email address will be used as username for login.
                </span>
            </a-flex>
            <a-flex xs12>
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Job Title (Optional)</label>
                <a-text-field
                    v-model="user_item.job_title"
                    placeholder="Enter Job Title"
                    background-color="neutral"
                    solo flat hide-details
                >
                </a-text-field>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="user_response.server && user_response.server.errors && user_response.server.errors.job_title">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ user_response.server.errors.job_title[0] }}
                </span>
                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                    <a-icon size="16">info</a-icon>
                    The job title will be helpful to identify the user.
                </span>
            </a-flex>
        </a-layout>
    </a-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('User', {
            user_item: 'item',
            user_response: 'response'
        })
    },
}
</script>

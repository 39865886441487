import { render, staticRenderFns } from "./ProjectsUsecasesImport.vue?vue&type=template&id=4454433e&scoped=true"
import script from "./ProjectsUsecasesImport.vue?vue&type=script&lang=js"
export * from "./ProjectsUsecasesImport.vue?vue&type=script&lang=js"
import style0 from "./ProjectsUsecasesImport.vue?vue&type=style&index=0&id=4454433e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4454433e",
  null
  
)

export default component.exports
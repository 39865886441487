const api_endpoint = '/milestones';

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        query: {
            filter: {},
            sort: 'order',
            include: [],
            fields: {},
            append: [],
        },
        query_filters: {
            page: 1,
            count: 10,
        },
        response: {},
        defaults: {
            list: [],
            item: {},
            meta: {},
            query: {
                filter: {},
                sort: 'order',
                include: [],
                fields: {},
                append: [],
            },
            query_filters: {
                page: 1,
                count: 10,
            },
            response: {},
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_MILESTONE(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            Object.assign(state.list[index], payload)
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = Object.assign(state.list[index], {...payload.item});
        },
        REMOVE_TASK_LIST_ITEM(state, payload) {
            const milestone_index = _.findIndex(state.list, { 'id': payload.milestone_id })
            const milestone_tasks = state.list[milestone_index].tasks
            const task_index = _.findIndex(milestone_tasks, { 'id': payload.id })
            if (task_index === -1) return
            state.list[milestone_index].tasks.splice(task_index, 1)
            state.list = [ ...state.list ]
        },
        UPDATE_QUERIES(state, query) {
            state.query = query;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        PREPEND_ITEM(state, item) {
            state.list.unshift(item);
        },
        APPEND_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        CLEAR_RESPONSE(state) {
            state.response = {};
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
        ADD_TASK_ITEM(state, payload){
            let milestone_index = _.findIndex(state.list, { 'id': payload.milestone_id })
            state.list[milestone_index].tasks.push(payload.data);
            state.list[milestone_index].tasks_count = state.list[milestone_index].tasks_count +1
        },
        UPDATE_TASK_LIST_ITEM(state, payload) {
            let milestone_index = _.findIndex(state.list, { 'id': payload.milestone_id })
            let milestone_tasks = state.list[milestone_index].tasks
            let task_index = _.findIndex(milestone_tasks, { 'id': payload.data.id })
            if (milestone_index === -1 || task_index === -1) return
            state.list[milestone_index].tasks[task_index] = Object.assign(state.list[milestone_index].tasks[task_index], { ...payload.data })
            // state.list = [ ...state.list ]
        },
        UPDATE_TASK_UPDATE_VISIBILITY(state, payload) {
            let milestone_index = _.findIndex(state.list, { 'id': payload.milestone_id })
            let milestone_tasks = state.list[milestone_index].tasks
            let task_index = _.findIndex(milestone_tasks, { 'id': payload.id })
            state.list[milestone_index].tasks[task_index].visibility =  payload.state
        },
        REMOVE_TASK_ITEM(state, payload) {
            let milestone_index = _.findIndex(state.list, { 'id': payload.milestone_id })
            let milestone_tasks = state.list[milestone_index].tasks

            let task_index = _.findIndex(milestone_tasks, { 'id': payload.task_id })

            if(state.list[milestone_index].tasks[task_index].completed_at === 1) {
                state.list[milestone_index].tasks_completed_count = state.list[milestone_index].tasks_completed_count -1
            }

            state.list[milestone_index].tasks.splice(task_index, 1)
            state.list[milestone_index].tasks_count = state.list[milestone_index].tasks_count -1

            if(!state.list[milestone_index].tasks.length) {
                state.list[milestone_index].tasks_count = 0
                state.list[milestone_index].tasks_completed_count = 0
            }
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint,  { params: payload })
                .then((response) => {
                    if (context.state.query_filters.page == 1) {
                        context.commit('UPDATE_LIST', response.data.data);
                    }
                    else {
                        context.commit('APPEND_LIST', response.data.data);
                    }
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint + '/' + payload.id, { params: payload.params })
                .then((response) => {
                    if (payload && payload.mode !== 'add') {
                        context.commit('UPDATE_ITEM', response.data.data);
                        context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    } else {
                        context.commit('APPEND_ITEM', response.data.data);
                    }
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.post(api_endpoint, payload)
                .then((response) => {
                    payload.params =  { 'include': 'tasks,tasksCount,tasksCompletedCount,assignees' },
                    context.dispatch('show', { ...payload, mode: 'add' })
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success'});
                    this.dispatch('Interface/loader_stop')
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        task_store(context, payload) {
            context.commit('ADD_TASK_ITEM', payload)
        },
        task_update(context, payload) {
            context.commit('UPDATE_TASK_LIST_ITEM', { ...payload });
        },
        task_visibility(context, payload) {
           context.commit('UPDATE_TASK_UPDATE_VISIBILITY', payload);
        },
        task_destroy(context, payload) {
            context.commit('REMOVE_TASK_ITEM', payload);
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id, payload)
                .then((response) => {
                    // context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: payload });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        expand(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + context.state.item.id + '/expand', context.state.item)
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        collapse(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + context.state.item.id + '/collapse', context.state.item)
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { id: context.state.item.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        expand_all(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/expand_all', payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        collapse_all(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/collapse_all', payload.data)
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        milestone_update(context, payload) {
            context.commit('UPDATE_MILESTONE', payload)
        },
        client_visible(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id + '/client_visible', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        client_invisible(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/' + payload.id + '/client_invisible', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return ext_axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        tasks_reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.patch(api_endpoint + '/tasks/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        template_import(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.post(api_endpoint + '/import/' + payload.id, { project_id: payload.project_id })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        task_remove(context, payload) {
            context.commit('REMOVE_TASK_LIST_ITEM', { ...payload });
        },
        clear_response(context) {
            context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_QUERIES', _.cloneDeep(context.state.defaults.query));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },
}

export const dateVariables = [
    {
        variable: '{{ workflow-date.sameDay }}',
        description: 'Add current date',
        subtitle: null,
    },
    {
        variable: '{{ workflow-date.addDay }}',
        description: 'Adds one day to current date',
        subtitle: null,
    },
    {
        variable: '{{ workflow-date.addDays() }}',
        description: 'Adds multiple days to current date',
        subtitle: 'Ex: {{ workflow-date.addDays(6) }} will add 6 days to current date.',
    },
    {
        variable: '{{ workflow-date.addWeek }}',
        description: 'Adds one week to current date',
        subtitle: null,

    },
    {
        variable: '{{ workflow-date.addWeeks() }}',
        description: 'Adds multiple weeks to current date',
        subtitle: 'Ex: {{ workflow-date.addWeeks(5) }} will add 5 weeks to current date.',
    },
    {
        variable: '{{ workflow-date.addBusinessDay }}',
        description: 'Adds one business day to current date.',
        subtitle: 'Business days are considered from Monday through Friday.',
    },
    {
        variable: '{{ workflow-date.addBusinessDays() }}',
        description: 'Adds multiple business days to current date',
        subtitle: 'Ex: {{ workflow-date.addBusinessDays(4) }} will add 4 business days to current date.',
    },
    {
        variable: '{{ workflow-date.addMonth }}',
        description: 'Adds one business day to current date',
        subtitle: null,
    },
    {
        variable: '{{ workflow-date.addMonths() }}',
        description: 'Adds multiple business days to current date',
        subtitle: 'Ex: {{ workflow-date.addMonths(3) }} will add 3 months to current date.',
    },
    {
        variable: '{{ workflow-date.addYear }}',
        description: 'Adds one year to current date',
        subtitle: null,
    },
    {
        variable: '{{ workflow-date.addYears() }}',
        description: 'Adds multiple years to current date',
        subtitle: 'Ex: {{ workflow-date.addYears(2) }} will add 2 years to current date.',
    },
]
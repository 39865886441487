<template>
    <div class="c-summary-tile pt-4 u-relative u-hfull">
        <loader-project-tags-summary class="py-5 pt-2 px-4" v-if="mixLoading" />

        <template v-else>
            <template v-if="localIsEmpty || !mixHasPermission('tags.index')">
                <partial-empty-state-summary
                    :canView="mixHasPermission('tags.index')"
                    :title="localTagTypeTitle + ' Tags'"
                    icon="sell"
                    modelSlug="tags"
                    class="pb-4"
                    iconSize="26"
                    iconOutlined
                />
            </template>

            <template v-else>
                <div class="u-overflow-hidden u-hfull" ref="refContainer">
                    <h2 class="px-5 md-body-2 font-weight-medium mb-2 grey--text text--darken-2 text-capitalize">{{ tagType }}</h2>
                    <div class="px-5 u-relative" ref="refTagsContainer">
                        <template v-for="(tag, index) in currentTagsList">
                            <g-tags
                                :tag="tag"
                                :key="tag.id"
                                :textRange="20"
                                :data-tag-index="index"
                                style="height: 32px;"
                                class="px-2 mr-2 mb-2"
                                ref="ref"
                                tile hideClearIcon noMargin noPadding
                            />
                        </template>
                        <!-- <a-sheet width="32" height="32" class="grey lighten-3 u-rounded-corners-lg u-flex-center md-body-1">...</a-sheet> -->
                    </div>
                </div>

                <partial-bottom-gradient
                    v-if="showExpand"
                    flat
                />
            </template>
        </template>

        <partial-project-summary-actions
            v-if="mixHasPermission('tags.index') && !mixLoading"
            :showExpand="noFetch ? false : showExpand"
            @expand="$emit('expand')"
            @open="mixOpenInNewWindow('projects-fields-tags')"
            :showActions="noFetch"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MixinModalStateInjection from '../Mixins/MixinModalStateInjection'
import PartialProjectSummaryActions from './PartialProjectSummaryActions'
import PartialBottomGradient from './PartialBottomGradient'
import PartialEmptyStateSummary from './PartialEmptyStateSummary.vue'
import LoaderProjectTagsSummary from '../Loaders/LoaderProjectTagsSummary.vue'

export default {
    components: {
        PartialProjectSummaryActions,
        PartialBottomGradient,
        PartialEmptyStateSummary,
        LoaderProjectTagsSummary
    },

    mixins: [MixinModalStateInjection],

    props: {
        tagType: {
            type: String,
            required: true
        },
        noFetch: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            showExpand: false,
            currentTagsList: [],
            totalTagsList: [],
        }
    },

    computed: {
        localIsEmpty () {
            return !_.size(this.currentTagsList)
        },

        localTagTypeTitle () {
            return this.tagType.charAt(0).toUpperCase() + this.tagType.slice(1)
        },

        ...mapState('Tag', {
            tag_list: 'list'
        }),

         ...mapState('ProjectView', {
            project_item: 'item',
        }),
    },

    methods: {
        async localIndex () {
            if (!this.mixHasPermission('tags.index')) return
            if ((this.noFetch || this.mixLatestSyncTimeDiff() >= 5) && _.keys(this.mixGetSavedSummary).includes(this.tagType + 'Tags')) {
                return this.localNoFetchSetup()
            }

            this.mixSetLoading()
            await this.localGetTags(this.tagType)
            await this.localSetupProjectTags()

            this.mixClearLoading()
            this.localSetupAction()
        },

        localNoFetchSetup () {
            this.currentTagsList = this.mixGetSavedSummary[this.tagType + 'Tags']
            this.localSetupAction()
        },

        async localSetupProjectTags () {
            const tagTypeKey = `aggr_${this.tagType}_tags`
            const { data: { [tagTypeKey]: projectTags }, status } = await this.project_show({
                id: this.mixParentState.projectId,
                stateless: true,
                params: { 'fields[projects]': 'id,' + tagTypeKey }
            })

            if (status === 'error') return
            if (!_.size(projectTags)) {
                this.mixSaveProject({ [this.tagType + 'Tags']: [] })
                return this.currentTagsList = []
            }

            this.currentTagsList = this.totalTagsList.filter(tag => {
                const index = projectTags.map(i => i.id).indexOf(tag.id)
                return index !== -1 ? tag : null
            })
            this.mixSaveProject({ [this.tagType + 'Tags']: this.currentTagsList })
        },

        localSetupAction () {
            if (this.localIsEmpty) return this.showExpand = false
            if (this.localIsEmpty || this.mixLoading) return

            this.$nextTick(() => {
                const parentHeight = this.$refs.refContainer.parentElement.clientHeight - 32
                const tagContainerHeight = this.$refs.refTagsContainer.clientHeight
                this.showExpand = !!(tagContainerHeight > parentHeight)
            })
        },

        async localGetTags () {
            const params = { 'filter[type]': this.tagType, 'fields[tags]': 'id,label,type,color', count: 1000 }
            await this.tag_clear()
            await this.tag_index(params)
            this.totalTagsList = _.cloneDeep(this.tag_list)
        },

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_clear: 'clear'
        }),

        ...mapActions('ProjectView', {
            project_show: 'show',
        }),
    }
}
</script>

<style lang="scss" scoped>

</style>

<template>
    <div :class="[{ 'u-cursor-pointer': !disabled && !readOnly }]" @click.stop="toggle">
        <slot name="prepend-title"></slot>
        <div
            v-on="$listeners"
            class="c-switch"
            :style="dataSwitchStyle"
            :class="[
                value ? 'c-switch--active' : inactiveColor ? inactiveColor : 'c-switch--bg',
                (!dataIsColorCode && value) ? color : ((customBg && value) ? color : ''),
                { 'u-cursor-pointer': !disabled && !readOnly }
            ]">
            <div class="c-switch__toggle u-flex-center" :style="dataToggleStyle" :class="[{ 'c-switch__toggle--active': value }]">
                <a-progress-circular v-if="!denseXl && loading" width="2" size="12" :color="loadingColor" indeterminate></a-progress-circular>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean | Number
        },
        disabled: {
            type: Boolean,
            default: false
        },
        disabledColor: {
            type: String,
            default: '#a0a0a0'
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        color: {
            type: String
        },
        customBg: {
            type: Boolean,
            default: false
        },
        inactiveColor: {
            type: String,
            default: ''
        },
        dense: {
            type: Boolean,
            default: false
        },
        denseXl: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        loadingColor: {
            type: String,
            default: 'orange darken-2'
        },
        tHeight: {
            type: String | Number,
        },
        tWidth: {
            type: String | Number,
        },
    },

    data () {
        return {
            isActive: false
        }
    },

    computed: {
        dataSwitchStyle () {
            const hasDense = this.dense || this.denseXl
            /*
                dense -> w - 40 | default : 50, h - 20
                dense-xl -> w - 32 | default : 24, h - 16
            */
            const styles = {
                width: (hasDense ? (hasDense ? (this.dense ? 40 : 32) : 30) : 50) + 'px',
                height: (hasDense ? (this.dense ? 20 : 16) : 24) + 'px',
                opacity: this.disabled || this.loading ? 0.5 : 1,
            }
            if (this.disabled || this.loading) return Object.assign(styles, { backgroundColor: `${ this.disabledColor } !important` })
            return styles
        },

        dataToggleStyle () {
            const hasDense = this.dense || this.denseXl
            /*
                dense -> w - 16, h - 16, left - 22
                dense-xl -> w- 10, h - 10, left - 16
                normal -> w - 20, h - 20, left - if value is true 28 else 2
            */
            let result = {
                width: (hasDense ? (this.dense ? 16 : 10) : 20) + 'px',
                height: (hasDense ? (this.dense ? 16 : 10) : 20) + 'px',
                left: (this.value ? (hasDense ? (this.dense ? 22 : 18) : 28) : (hasDense ? (this.dense ? 2 : 4) : 2)) + 'px',
                top: (hasDense ? (this.dense ? 2 : 3) : 2) + 'px'
            }
            if (this.dataIsColorCode) return { ...result, backgroundColor: this.color }
            return result
        },

        dataIsColorCode () {
            return this.color && (this.color.charAt(0) === '#' || this.color.slice(0, 3) === 'rgb') ? this.color : null
        }
    },

    methods: {
        toggle () {
            if (this.disabled || this.readOnly || this.loading) return
            this.isActive = !this.value
            this.$emit('input', this.isActive)
            this.$emit('change', this.isActive)
        }
    }
}
</script>

<style scoped>
.c-switch {
    position: relative;
    border-radius: 30px;
    display: flex;
    align-items: center;
}

.c-switch--bg {
    background-color: #cfcfcf;
}

.c-switch--active {
    background-color: #1976d2;
}

.c-switch__toggle {
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 20px;
    background-color: #fff;
    transition: 0.2s all;
}
</style>

<template>
    <v-container v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </v-container>
</template>

<script>
import { VContainer } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VContainer }
}
</script>

<template>
    <a-card>
        <div class="u-flex-center-y px-6 py-5">
            <div class="u-flex-center-y">
                <a-avatar size="40" color="primary">
                    <a-icon size="24" color="white">work</a-icon>
                </a-avatar>
                <h2 class="text-h6 primary--text text--darken-1 font-weight-medium ml-3">Competitor</h2>
            </div>
            <a-spacer></a-spacer>
            <a-icon v-if="$can('projects.update-only')" size="20" color="grey darken-1" class="mr-5 d-inline-block c-hover-state u-rounded-corners-full" @click="$emit('delete', item, 'detail-view')">delete</a-icon>
            <a-icon size="20" color="grey darken-1" @click="$emit('close')">clear</a-icon>
        </div>

        <a-divider></a-divider>

        <a-sheet max-height="500" class="c-main-content u-overflow-y pa-6 pt-5">
            <!-- Name -->
            <div>
                <label class="md-body-2 d-block mb-2 mt-0 grey--text text--darken-1 font-weight-medium">Name</label>
                <a-sheet class="c-textarea-editor c-hover-state" :class="[{ 'grey lighten-5': currentInput === 'name' }]" @click="currentInput = 'name'">
                    <a-tooltip bottom offset-y :disabled="!$response(competitor_response, 'name')">
                        <template v-slot:activator="{ on }">
                            <a-text-field
                                v-on="on"
                                v-model="item.name"
                                :placeholder="($can('projects.update-only') && $can('competitors.update')) ? 'Enter the name' : ''"
                                background-color="transparent"
                                class="u-rounded-corners-lg grey--text text--darken-2"
                                :class="[{ 'c-wiggle-short u-border-error': $response(competitor_response, 'name') }]"
                                :disabled="!($can('projects.update-only') && $can('competitors.update'))"
                                @input="localUpdateComp()"
                                solo flat hide-details
                            >
                            </a-text-field>
                        </template>
                        <span>{{ $response(competitor_response, 'name') }}</span>
                    </a-tooltip>
                </a-sheet>
            </div>

            <!-- Stength -->
            <div class="mt-4">
                <label class="md-body-2 d-block mb-2 mt-0 grey--text text--darken-1 font-weight-medium">
                    Strengths
                    <a-progress-circular v-if="isLoading" size="16" width="2" color="orange darken-2" class="ml-1" indeterminate></a-progress-circular>
                </label>
                <a-sheet class="c-textarea-editor c-hover-state" :class="[{ 'grey lighten-5': currentInput === 'strength' }]" @click="currentInput = 'strength'">
                    <a-tooltip bottom offset-y :disabled="!$response(competitor_response, 'strengths')">
                        <template v-slot:activator="{ on }">
                            <a-textarea
                                v-on="on"
                                v-model="item.strengths"
                                :placeholder="($can('projects.update-only') && $can('competitors.update')) ? 'Enter strengths' : 'No strength added'"
                                background-color="transparent"
                                class="u-rounded-corners-lg grey--text text--darken-2"
                                :class="[{ 'c-wiggle-short u-border-error': $response(competitor_response, 'strengths') }]"
                                :disabled="!($can('projects.update-only') && $can('competitors.update')) || isLoading"
                                @input="localUpdateComp()"
                                rows="1"
                                row-height="16"
                                solo flat hide-details no-resize auto-grow
                            >
                            </a-textarea>
                        </template>
                        <span>{{ $response(competitor_response, 'strengths') }}</span>
                    </a-tooltip>
                </a-sheet>
            </div>

            <!-- Weakness -->
            <div class="mt-4">
                <label class="md-body-2 d-block mb-2 mt-0 grey--text text--darken-1 font-weight-medium">
                    Weaknesses
                    <a-progress-circular v-if="isLoading" size="16" width="2" color="orange darken-2" class="ml-1" indeterminate></a-progress-circular>
                </label>
                <a-sheet class="c-textarea-editor c-hover-state" :class="[{ 'grey lighten-5': currentInput === 'weakness' }]" @click="currentInput = 'weakness'">
                    <a-tooltip bottom offset-y :disabled="!$response(competitor_response, 'weaknesses')">
                        <template v-slot:activator="{ on }">
                            <a-textarea
                                v-on="on"
                                v-model="item.weaknesses"
                                :placeholder="($can('projects.update-only') && $can('competitors.update')) ? 'Enter weaknesses' : 'No weakness added'"
                                background-color="transparent"
                                class="u-rounded-corners-lg grey--text text--darken-2"
                                :class="[{ 'c-wiggle-short u-border-error': $response(competitor_response, 'weaknesses') }]"
                                :disabled="!($can('projects.update-only') && $can('competitors.update')) || isLoading"
                                rows="1"
                                row-height="16"
                                @input="localUpdateComp()"
                                solo flat hide-details no-resize auto-grow
                            >
                            </a-textarea>
                        </template>
                        <span>{{ $response(competitor_response, 'weaknesses') }}</span>
                    </a-tooltip>
                </a-sheet>
            </div>
        </a-sheet>
    </a-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: 'ModalEditCompetitor',

    props: {
        item: {
            type: Object,
        },
        isOpen: {
            type: Boolean
        },
        isLoading: {
            type: Boolean
        }
    },

    data () {
        return {
            loading: false,
            currentInput: '',
            updateTimeout: null
        }
    },

    watch: {
        isOpen (val) {
            if (val) this.localIndex()
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        ...mapState('Competitor', {
            competitor_response: 'response'
        })
    },

     methods: {
         async localIndex () {
            this.loading = false
            this.localResetScroll()
            this.competitor_clear()
         },

         localResetScroll () {
             const el = document.querySelector('.c-main-content')
             el.scrollTop = 0
         },

         async localUpdateComp () {
             this.loading = true
             clearTimeout(this.updateTimeout)
             this.updateTimeout = setTimeout(async () => {
                await this.competitor_update(this.item)
                if (!this.$status(this.competitor_response)) {
                    clearTimeout(this.updateTimeout)
                    return this.loading = false
                }

                this.$emit('update', this.item)
                clearTimeout(this.updateTimeout)
                this.loading = false
             }, 500)
         },

         ...mapActions('Competitor', {
             competitor_update: 'update',
             competitor_clear: 'clear',
         })
    }
}
</script>

<style lang="scss" scoped></style>

<template>
    <div class="u-relative">
        <div class="pa-5 u-flex white justify-space-between align-center u-rounded-corners u-sticky" style="top: 0; z-index: 10">
            <div class="u-flex align-center">
                <div class="u-rounded-corners-full u-flex justify-center" style="width: 40px; height: 40px;" :style="{ backgroundColor: localIconFormat.bgColor }">
                    <a-icon v-if="localIconFormat" :color="localIconFormat.color">{{ localIconFormat.icon }}</a-icon>
                </div>
                <div class="ml-4 grey--text text--darken-4">
                    <div class="md-heading-6 mb-1" :title="item.name">{{ item.name | truncateText(50) }}</div>
                    <div class="md-body-2 grey--text text--darken-1">
                        <span class="text-uppercase">{{ item.extension }}</span>
                        <a-icon size="4" color="grey--text text--lighten-1" class="px-3">fiber_manual_record</a-icon>
                        <span>{{ item.size | sizer }}</span>
                    </div>
                </div>
            </div>
            <div class="u-flex align-center">
                <a-btn
                    :href="link.download"
                    height="36"
                    class="px-3 elevation-0 white--text mr-7"
                    color="indigo darken-1"
                    :disabled="loading"
                    small download
                >
                    <a-icon size="14" class="mr-2">download</a-icon>
                    Download
                </a-btn>
                <a-btn icon plain @click="$emit('close')">
                    <a-icon size="24" color="grey darken-1">close</a-icon>
                </a-btn>
            </div>
        </div>
        <a-divider></a-divider>
        <div class="white pa-10" style="height: 576px">
            <template v-if="loading">
                <loader-template width="100%" height="496"></loader-template>
            </template>
            <template v-else-if="localIconFormat.type === 'image'">
                <div class="u-relative">
                    <v-img
                        :src="link.preview"
                        lazy-src=""
                        height="496"
                        alt="image"
                        @load="localOnImageLoad()"
                        contain
                    >
                    </v-img>
                    <loader-template v-if="loadingImage" width="100%" height="496" class="c-custom-loader"></loader-template>
                </div>
            </template>
            <template v-else>
                <div class="grey lighten-3 u-rounded-corners u-flex flex-column justify-center align-center" style="height: 100%">
                    <div class="grey lighten-2 u-flex align-center justify-center" style="height: 200px; width: 200px; border-radius: 100%">
                        <a-icon color="grey" size="80">insert_drive_file</a-icon>
                    </div>
                    <div class="mt-10 md-heading-5 font-weight-medium grey--text text--darken-2">Preview not available</div>
                    <div class="md-subtitle-2 grey--text text--darken-1">The preview is limited to specific/JPG file formats.</div>
                    <a-btn
                        :href="link.download"
                        height="36"
                        class="px-3 elevation-0 white--text mt-8"
                        color="indigo darken-1"
                        :disabled="loading"
                        small download
                    >
                        <a-icon size="14" class="mr-2">download</a-icon>
                        Download
                    </a-btn>
                </div>
            </template>
            <div class="u-flex justify-center align-center md-subtitle-2" style="margin: 10px 0px">
                <div style="width: 33px; height: 20px" class="text-center grey lighten-3 u-rounded-corners">
                    <span>{{ localItemIndex + 1 }}</span>
                </div>
                <div class="grey--text text--darken-1">
                    <span class="px-1">of</span>
                    <span>{{ localAttachmentCount }}</span>
                </div>
            </div>
        </div>
        <div v-if="localItemIndex > 0" class="u-absolute" style="bottom: 246px; left: 20px">
            <a-btn
                @click="localNavigate({ direction: 'left', item })"
                color="grey lighten-1"
                class="pa-0"
                height="64"
                x-small
            >
                <a-icon size="32" color="grey darken-2">chevron_left</a-icon>
            </a-btn>
        </div>
        <div v-if="localItemIndex < localAttachmentCount - 1" class="u-absolute" style="bottom: 246px; right: 20px">
            <a-btn
                @click="localNavigate({ direction: 'right', item })"
                color="grey lighten-1"
                class="pa-0"
                height="64"
                x-small
            >
                <a-icon size="32" color="grey darken-2">chevron_right</a-icon>
            </a-btn>
        </div>
    </div>
</template>

<script>
import LoaderTemplate from '../Loader/LoaderTemplate.vue'

export default {
    components: { LoaderTemplate },

    props: {
        list: {
            type: Array,
            required: true
        },

        meta: {
            type: Object,
            required: true
        },

        isOpen: {
            type: Boolean,
            default: false,
            required: true
        },

        item: {
            type: Object,
            required: true
        },

        link: {
            type: Object,
            required: true
        },

        loading: {
            type: Boolean,
            required: true
        }
    },

    data () {
        return {
            fileExtIconFormats: [
                { extensions: ['jpg', 'jpeg', 'png', 'gif'], type: 'image', icon: 'image', color: '#FF7043', bgColor: '#FBE9E7' },
                { extensions: ['pdf', 'docx', 'doc', 'zip', 'txt'], type: 'document', icon: 'description', color: '#7E57C2', bgColor: '#EDE7F6' },
                { extensions: ['ppt', 'pptx'], type: 'presentation', icon: 'article', color: '#42A5F5', bgColor: '#E3F2FD' },
                { extensions: ['xlsx', 'xls', 'csv'], type: 'spreadsheet', icon: 'article', color: '#43A047', bgColor: '#E8F5E9' },
            ],
            loadingImage: true,
        }
    },

    watch : {
        isOpen: {
            handler (val) {
                if (val) {
                    this.loadingImage = true
                    document.addEventListener('keydown', this.localKeyDown)
                    return
                }
                document.removeEventListener('keydown', this.localKeyDown)
            },
            immediate: true
        }
    },

    computed: {
        localIconFormat () {
            return this.fileExtIconFormats.find(item => {
                return item.extensions.includes(this.item.extension)
            })
        },

        localItemIndex () {
            return _.findIndex(this.list, {id: this.item.id})
        },

        localAttachmentCount () {
            return this.meta.total
        }
    },

    methods: {
        localOnImageLoad () {
            this.loadingImage = false
        },

        localNavigate (params) {
            const { direction, item } = params || {}
            if (direction === 'right' && this.localItemIndex === this.localAttachmentCount-1) return
            this.loadingImage = true
            this.$emit('navigate', { direction: direction, item })
        },

        localKeyDown (evt) {
            if (evt.code === 'ArrowLeft') this.localNavigate({ direction: 'left', item: this.item })
            if (evt.code === 'ArrowRight') this.localNavigate({ direction: 'right', item: this.item })
        }
    }
}
</script>

<style scoped>
.c-custom-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>

<template>
    <a-main class="px-6 u-hfull">
        <template v-if="!local_is_avoided_route">
            <g-page-header
                title="System Templates"
                :subtitle="`Manage ${local_current_section}`"
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                icon="library_books"
                class="mb-1"
            >
            </g-page-header>
        </template>
        <router-view @unsaved="unsaved = true" @saved="unsaved = false"></router-view>
        <a-dialog v-model="dialog_leave_page_alert" persistent max-width="400">
            <SCancelChanges
                @success="local_leave_page()"
                @close="dialog_leave_page_alert = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState } from 'vuex'
import SCancelChanges from '/src/components/Shared/SharedCancelChanges'

export default {
    components: { SCancelChanges },
    data() {
        return {
            system_template_types: ['usecases', 'tasks', 'links', 'documents', 'notes'],
            unsaved: false,
            dialog_leave_page_alert: false,
            to_page_route: null
        }
    },

    beforeRouteLeave (toPage, fromPage, next) {
        if (!this.unsaved) return next()
        this.dialog_leave_page_alert = true
        next(false)
        this.to_page_route = next
    },

    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Types & Statuses', to: '/settings/master-templates', exact: true})
            return breadcrumbs
        },

        local_current_section () {
            const { name } = this.$route
            const split_name = name.split('-').slice(-1)[0].slice(0, -1)
            return name ? split_name === 'usecase' ? 'use case templates': split_name + ' templates' : 'system templates'
        },

        local_is_avoided_route () {
            const { name } = this.$route
            const avoidRoutes = ['master-templates-system-list-usecases-view', 'master-templates-system-list-notes-view', 'master-templates-system-list-task-view']
            return avoidRoutes.includes(name)
        },

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
    methods: {
        local_leave_page () {
            this.unsaved = false
            this.to_page_route()
        }
    }
}
</script>

<style lang="scss" scoped>
.c-error-strip {
    visibility: hidden;
    margin-top: -4px !important;
    transition: visibility 0.4s all;
    border-radius: 0px 0px 25px 25px !important;
    &__show {
        visibility: visible;
        margin-top: 0px !important;
        transition: visibility 0.4s all;
    }
}
</style>

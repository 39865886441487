import registerGlobal from '@/config/config-register-comp'

const GlobalComponents = [
    'GRichTextEditor',
    'GSettingsBreadcrumb',
    'GDivider',
    'GMoment',
    'GCurrency',
    'GAvatar',
    'GProfileInfo',
    'GPageHeader',
    'GConfirmation',
    'GDialogBox',
    'GDialogBoxSimple',
    'GTags',
    'GAddBtn',
    'GTaskMeter',
    'GCommentBox',
    'GDeleteConfirmSimple',
    'GToggle',
    'GProjectPage',
    // 'GEditor',
    'GEditorBox',
    'GFieldInfo',
    'GFieldText',
    'GUsersAutocomplete',
    'GUsersSelectDropdown',
    'GNonActiveInfo',
    'GUpgradeIcon',
    'GBetaFlag',
    'GSmartTime',
    'GAttachmentCountInfo'
]

GlobalComponents.forEach(comp => registerGlobal(comp, 'G'))

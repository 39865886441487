import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('Preference', {
            preference_get_property_by_key: 'get_property_by_key',
        }),
    },

    methods: {
        mixinGetPreference (slug, field = 'value') {
            return this.preference_get_property_by_key(`${slug}`, field)
        },
    }
}

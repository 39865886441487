<template>
	<a-main>
        <a-container container--fluid grid-list-xl>
            <a-layout align-center class="mt-2">
                <a-flex>
                    <h2 class="md-heading-6 indigo--text text--darken-1 font-weight-semibold"><a-icon size="24" color="indigo" class="mr-1 u-icon-nudge">menu_open</a-icon>Logs</h2>
                    <p class="md-body-2 mb-0 grey--text text--darken-2">Success and error logs from workflow runs.</p>
                </a-flex>
            </a-layout>
           <template v-if="pageLoading">
                <PSalesforceMicroServicesLogListLoader/>
           </template>
            <template v-if="!pageLoading">
                <a-layout wrap align-center class="mt-2">
                    <a-flex shrink pr-2>
                        <a-menu bottom offset-y v-model="logSourceDropdown" max-width="224">
                            <template v-slot:activator="{ on }">
                                <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="200" v-on="on">
                                    <span class="md-body-2 grey--text text--darken-2 mx-1">
                                        <a-icon size="20" :color="localIsSourcesFilter ? 'blue' : 'grey darken-1'" class="mr-1 u-icon-nudge">filter_list</a-icon>
                                        <span class="grey--text text--darken-1 font-weight-normal">{{ selectedSource.label }}</span>
                                    </span>
                                    <a-spacer></a-spacer>
                                    <a-progress-circular v-if="filterLoading === 'source'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                    <a-icon v-else size="20" class="ma-0">arrow_drop_{{ logSourceDropdown ? 'up' : 'down' }}</a-icon>
                                </a-sheet>
                            </template>
                            <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                                <a-list-item v-for="user_source in logSources" @click="localLogSourcesfilter(user_source)" class="pr-5" :class="{ 'grey lighten-4': selectedSource.value === user_source.value }" :key="user_source.value">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">{{ user_source.label }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                            <a-divider></a-divider>
                            <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                                <a-list-item class="pr-5" @click="localLogDefaultSourcesFilter()">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">All Sources</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink pr-2>
                        <a-menu bottom offset-y v-model="workflowDropdown" :close-on-content-click="false" max-width="500" min-width="500">
                            <template v-slot:activator="{ on }">
                                <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="200" v-on="on">
                                    <div class="md-body-2 grey--text text--darken-2 mx-1 u-flex align-center">
                                        <a-icon size="20" :color="localIsWorkflowFilter ? 'blue' : 'grey darken-1'"  class="mr-1 u-icon-nudge">filter_list</a-icon>
                                        <span class="grey--text text--darken-1 font-weight-normal">
                                            <template v-if="selectedWorkflows && selectedWorkflows.length === 1 && selectedWorkflows[0] !== 'All workflows' && selectedWorkflows[0] !== 'No workflow'">
                                                <span :title="localGetWorlflowLabel(selectedWorkflows[0])">{{ localGetWorlflowLabel(selectedWorkflows[0]) | truncateText(15) }}</span>
                                            </template>
                                            <template v-else-if="selectedWorkflows && selectedWorkflows.length > 1">{{ selectedWorkflows.length + ' Selected' }}</template>
                                            <template v-else-if="selectedWorkflows[0] === 'No workflow'">No workflow</template>
                                            <template v-else>All Workflows</template>
                                        </span>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <a-progress-circular v-if="filterLoading === 'workflow'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                    <a-icon v-else size="20" class="ma-0">arrow_drop_{{ workflowDropdown ? 'up' : 'down' }}</a-icon>
                                </a-sheet>
                            </template>
                            <a-list class="u-list-condensed">
                                <a-sheet class="c-input-wrap px-2">
                                    <a-text-field
                                        v-model="searchWorkflow"
                                        placeholder="Search Workflow"
                                        background-color="neutral"
                                        class="md-body-2 font-weight-normal"
                                        solo flat hide-details autofocus
                                    >
                                    </a-text-field>
                                </a-sheet>
                            </a-list>
                            <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                                <a-list-item v-for="workflow in filteredWorkflowList" class="pr-5" :key="workflow.key" @click="localLogworkflowFilter(workflow.workflow_id)">
                                    <a-list-item-icon class="ma-0 pa-0 u-flex justify-center">
                                        <a-checkbox dense hide-details color="blue darken-2" class="ma-0 pa-0" :value="localLogWorkflowIsSelected(workflow.workflow_id)"></a-checkbox>
                                    </a-list-item-icon>
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1" :title="workflow.label">{{ workflow.label | truncateText(60) }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                                <a-list-item v-if="searchWorkflow && (filteredWorkflowList && !filteredWorkflowList.length)">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">No Workflows Found</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                            <a-divider></a-divider>
                            <a-list class="u-list-condensed u-overflow-y">
                                <a-list-item class="pr-5" @click="localLogworkflowFilter()">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">All Workflows</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink pr-2>
                        <a-menu bottom offset-y v-model="projectDropdown" :close-on-content-click="false" max-width="400" min-width="400">
                            <template v-slot:activator="{ on }">
                                <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="200" v-on="on">
                                    <div class="md-body-2 grey--text text--darken-2 mx-1 u-flex align-center">
                                        <a-icon size="20" :color="localIsProjectFilter ? 'blue' : 'grey darken-1'" class="mr-1">filter_list</a-icon>
                                        <span class="grey--text text--darken-1 font-weight-normal">
                                            <template v-if="selectedProjects && selectedProjects.length === 1 && selectedProjects[0] !== 'All workflows' && selectedProjects[0] !== 'No workflow'">
                                               <span :title="localGetProjectTitle(selectedProjects[0])">{{ localGetProjectTitle(selectedProjects[0]) | truncateText(15) }}</span> 
                                            </template>
                                            <template v-else-if="selectedProjects && selectedProjects.length > 1">{{ selectedProjects.length + ' Selected' }}</template>
                                            <template v-else-if="selectedProjects[0] === 'No project'">No Projects Found</template>
                                            <template v-else>All Projects</template>
                                        </span>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <a-progress-circular v-if="filterLoading === 'project'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                    <a-icon v-else size="20" class="ma-0">arrow_drop_{{ projectDropdown ? 'up' : 'down' }}</a-icon>
                                </a-sheet>
                            </template>
                            <a-list class="u-list-condensed">
                                <a-sheet class="c-input-wrap px-2">
                                    <a-text-field
                                        v-model="searchProject"
                                        placeholder="Search Project"
                                        background-color="neutral"
                                        class="md-body-2 font-weight-normal"
                                        solo flat hide-details autofocus 
                                        @input="localSearchProjects()"
                                    >
                                    </a-text-field>
                                </a-sheet>
                            </a-list>
                            <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                                <template v-if="projectLoading">
                                    <a-list-item  v-for="count in 5" class="pr-5" :key="count">
                                        <a-list-item-icon class="ma-0 pa-0">
                                            <loader-template height="20" width="80%"></loader-template>
                                        </a-list-item-icon>
                                        <a-list-item-title>
                                            <loader-template height="20" width="100%"></loader-template>
                                        </a-list-item-title>
                                    </a-list-item>
                                </template>
                                <template v-else>
                                    <a-list-item v-for="project in project_list" class="pr-5" :key="project.id" @click="localProjectFilter(project.id)">
                                        <a-list-item-icon class="ma-0 pa-0 u-flex justify-center">
                                            <a-checkbox dense hide-details color="blue darken-2" :value="localProjectIsSelected(project.id)" class="ma-0 pa-0"></a-checkbox>
                                        </a-list-item-icon>
                                        <a-list-item-title>
                                            <span class="md-body-2 grey--text text--darken-3 ml-1" :title="project.title">{{ project.title | truncateText(60) }}</span>
                                        </a-list-item-title>
                                    </a-list-item>
                                    <a-list-item v-if="searchProject && (project_list && !project_list.length)">
                                        <a-list-item-title>
                                            <span class="md-body-2 grey--text text--darken-3 ml-1">No Projects Found</span>
                                        </a-list-item-title>
                                    </a-list-item>
                                </template>
                            </a-list>
                            <a-divider></a-divider>
                            <a-list class="u-list-condensed u-overflow-y">
                                <a-list-item class="pr-5" @click="localProjectFilter()">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">All Projects</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink pr-2>
                        <a-menu bottom offset-y v-model="statusDropdown" :close-on-content-click="false" max-width="224">
                            <template v-slot:activator="{ on }">
                                <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="200" v-on="on">
                                    <span class="md-body-2 grey--text text--darken-2 u-flex mx-1">
                                        <a-icon size="20" :color="localIsStatusFilter ? 'blue' : 'grey darken-1'" class="mr-1 u-icon-nudge">filter_list</a-icon>
                                        <span class="grey--text text--darken-1 font-weight-normal">
                                            <template v-if="selectedStatus && selectedStatus.length === 1">
                                                <span class="md-body-2 grey--text text--darken-3 ml-1 text-capitalize">{{ localLogGetStatusValue(selectedStatus[0]) }}</span>
                                            </template>
                                            <template v-else-if="selectedStatus && selectedStatus.length > 1">{{ selectedStatus.length + ' Selected' }}</template>
                                            <template v-else>All Status</template>
                                        </span>
                                    </span>
                                    <a-spacer></a-spacer>
                                    <a-progress-circular v-if="filterLoading === 'status'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                    <a-icon v-else size="20" class="ma-0">arrow_drop_{{ statusDropdown ? 'up' : 'down' }}</a-icon>
                                </a-sheet>
                            </template>
                            <a-list class="u-list-condensed u-overflow-y" style="max-height: 300px;">
                                <a-list-item v-for="(status, index) in logStatus" class="pr-5" :key="'status'+index" @click="localLogStatusFilter(status.value)">
                                    <a-list-item-icon class="ma-0 pa-0 u-flex justify-center">
                                        <a-checkbox dense hide-details color="blue darken-2" :value="localLogStatusIsSelected(status.value)" class="ma-0 pa-0"></a-checkbox>
                                    </a-list-item-icon>
                                    <a-list-item-title>
                                        <div class="u-flex">
                                            <span class="md-body-2 grey--text text--darken-3 ml-1">{{ status.label }}</span>
                                            <a-spacer></a-spacer>
                                            <div class="c-filter-status" :class="['c-filter-'+status.value]"></div>
                                        </div>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                            <a-divider></a-divider>
                            <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                                <a-list-item class="pr-5" @click="localDefaultStatusFilter()">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">All Status</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink pr-2>
                        <a-menu bottom offset-y v-model="timeDropdown" max-width="224">
                            <template v-slot:activator="{ on }">
                                <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="200" v-on="on">
                                    <span class="md-body-2 grey--text text--darken-2 mx-1">
                                        <a-icon size="20" :color="selectedTime && selectedTime.value !== 1 && localIsTimeFilter ? 'blue' : 'grey darken-1'" class="mr-1 u-icon-nudge">filter_list</a-icon>
                                        <span class="grey--text text--darken-1 font-weight-normal">{{ selectedTime.label }}</span>
                                    </span>
                                    <a-spacer></a-spacer>
                                    <a-progress-circular v-if="filterLoading === 'time'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                    <a-icon v-else size="20" class="ma-0">arrow_drop_{{ timeDropdown ? 'up' : 'down' }}</a-icon>
                                </a-sheet>
                            </template>
                            <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                                <a-list-item v-for="local_time in logTimes" class="pr-5" @click="localLogTimefilter(local_time)" :class="{ 'grey lighten-4': selectedTime.value === local_time.value }" :key="local_time.value">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">{{ local_time.label }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex shrink>
                        <a-sheet v-if="!localCheckLogsFilter" :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" @click="localClearLogFilters()" height="40" width="132">
                            <a-icon size="20" color="grey darken-1" class="mr-1">highlight_off</a-icon>
                            <span class="grey--text text--darken-1 font-weight-normal">Clear filters</span>
                        </a-sheet>
                    </a-flex>
                    <a-flex shrink>
                        <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" @click="localRefreshMicroserviceLogsList()" height="40" width="100">
                            <a-icon v-if="!localRefreshLoading" size="20" color="grey darken-1" class="mr-1">refresh</a-icon>
                            <a-progress-circular v-else color="orange darken-2" class="mr-1" size="16" width="2" indeterminate></a-progress-circular>
                            <span class="grey--text text--darken-1 font-weight-normal">Refresh</span>
                        </a-sheet>
                    </a-flex>
                </a-layout>
                <template v-if="!pageLoading && filteredMicroserviceWorkflowLogList && filteredMicroserviceWorkflowLogList.length === 0">
                    <a-container grid-list-xl class="my-4">
                        <a-layout align-center justify-center>
                            <a-flex xs4>
                                <div class="text-center"><a-icon size="160" color="grey lighten-1">menu_open</a-icon></div>
                                <h2 class="md-heading-5 mb-1 grey--text text--darken-3 text-center">No Logs</h2>
                                <p class="md-body-2 mb-2 grey--text text--darken-1 text-center">Setup the workflow to automate data sharing between Salesforce and Success.</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </template>
                <template v-if="filteredMicroserviceWorkflowLogList && filteredMicroserviceWorkflowLogList.length">
                    <SalesforceMicroServicesLogList
                        :logList="filteredMicroserviceWorkflowLogList"
                        @action="(id) => localShowLogEntriesInformation(id)"
                    />
                </template>
                <a-layout class="mx-0 c-sticky-paginate-parent u-elevation-custom-1 u-rounded-corners" v-if="filteredMicroserviceWorkflowLogList && filteredMicroserviceWorkflowLogList.length" :class="{ 'pa-4': !showPaginateSection }">
                    <a-flex class="u-flex align-center py-0">
                        <div class="md-body-2 grey--text text--darken-2 font-weight-medium mr-2 d-inline-block">Showing {{ microservice_workflow_log_meta.from }} - {{ microservice_workflow_log_meta.to }} out of {{ microservice_workflow_log_meta.total }} log{{ microservice_workflow_log_meta.total > 1 ? 's' : '' }}</div>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex class="mr-16 pr-16 py-0 u-flex align-center justify-end" v-if="showPaginateSection">
                        <a-progress-circular v-if="pageLoading" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                        <div class="text-right">
                            <a-pagination
                                v-model="microservice_workflow_log_meta.current_page"
                                :length="microservice_workflow_log_meta.last_page"
                                :total-visible="10"
                                class="c-inventory-pagination py-1"
                                @input="localFetchPaginate()"
                            ></a-pagination>
                        </div>
                    </a-flex>
              </a-layout>
            </template>
        </a-container>
        <a-dialog v-model="dialogLogEntriesInformation" scrollable max-width="840">
            <a-card>
                <a-sheet class="py-5 px-6">
                    <p class="text-h6 indigo--text text--darken-1 font-weight-medium ma-0 pa-0">
                        <a-avatar class="indigo darken-1 mr-1" size="40">
                            <a-icon size="20" dark>timeline</a-icon>
                        </a-avatar>
                        Log Entries Information
                    </p>
                </a-sheet>
                <a-divider class="grey lighten-3"></a-divider>
                <template v-if="selectLogitem">
                    <a-sheet class="py-5 px-6 grey lighten-4">
                        <p class="md-subtitle-1 grey--text text--darken-4 mb-0 mr-3">{{ localGetWorkflowTitle(selectLogitem.workflow_id) }}</p>
                        <div class="d-flex align-center mt-5">
                            <img src="./../../assets/images/success-imgs/icon-success.svg" v-if="!localCheckSourece(selectLogitem.source_service, selectLogitem.target_service)" alt="Success" style="display: inline-block; max-width: 25px; max-height: 25px;">
                            <img src="./../../assets/images/logo-salesforce.svg" v-else alt="Salesforce" style="display: inline-block; max-width: 25px; max-height: 25px;">
                            <a-icon class="mx-2" size="16">arrow_forward</a-icon>
                            <img src="./../../assets/images/logo-salesforce.svg" v-if="!localCheckSourece(selectLogitem.source_service, selectLogitem.target_service)" alt="Salesforce" style="display: inline-block; max-width: 25px; max-height: 25px;">
                            <img src="./../../assets/images/success-imgs/icon-success.svg" v-else alt="Success" style="display: inline-block; max-width: 25px; max-height: 25px;">
                            <span class="px-2 grey--text text--darken-1">&bull;</span>
                            <s-log-status :label="selectLogitem.status" :status="selectLogitem.status"></s-log-status>
                        </div>
                     </a-sheet>
                    <a-divider class="grey lighten-3"></a-divider>
                </template>
                <a-card-text class="pa-0"  style="max-height: 700px;">
                    <template v-if="localLogEntriesLoading">
                        <template v-for="(i, index) in 10">
                            <a-sheet class="py-5 px-6" :key="index">
                                <div class="d-flex align-center">
                                    <loader-template height="24" width="100%" class="u-rounded-corners-lg"></loader-template>
                                </div>
                            </a-sheet>
                            <a-divider class="grey lighten-3" :key="'divider'+index"></a-divider>
                        </template>
                    </template>
                    <template v-if="!localLogEntriesLoading && microservice_workflow_log_entry_list && microservice_workflow_log_entry_list.length === 0">
                        <a-sheet class="py-5 px-6">
                            <div>
                                <div class="text-center"><a-icon size="140" color="grey lighten-1">timeline</a-icon></div>
                                <h2 class="md-heading-5 mb-1 grey--text text--darken-3 text-center">No Log Entries</h2>
                            </div>
                        </a-sheet>
                    </template>
                    <template v-if="!localLogEntriesLoading && microservice_workflow_log_entry_list && microservice_workflow_log_entry_list.length">
                        <template v-for="(log_entry, index) in microservice_workflow_log_entry_list">
                            <a-sheet class="py-5 px-6" :key="'1'+index">
                                <div class="u-flex align-center">
                                    <div class="mr-3">
                                        <a-icon color="indigo" size="20" v-if="log_entry.type === 'info'">info</a-icon>
                                        <a-icon color="orange darken" size="20" v-if="log_entry.type === 'warning' || log_entry.type === 'warn'">warning</a-icon>
                                        <a-icon color="red" size="20" v-if="log_entry.type === 'error' || log_entry.type === 'failed'">error</a-icon>
                                    </div>
                                    <div style="width: 70%;">
                                        <p class="md-body-1 mb-0 grey--text text--darken-2">{{ log_entry.message }}</p>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <div class="mx-3">
                                        <g-moment :value="log_entry.updated_at" class="md-body-2 mb-0 grey--text text--darken-2" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mma" convert-local></g-moment>
                                    </div>
                                </div>
                            </a-sheet>
                            <a-divider class="grey lighten-3" :key="'2'+index"></a-divider>
                        </template>
                    </template>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SalesforceMicroServicesLogList from './Partials/SalesforceMicroServicesLogList'
import PSalesforceMicroServicesLogListLoader from './Partials/SalesforceMicroServicesLogListLoader'
import { SLogStatus } from '@/config/config-shared-components'


export default {
    components: {
        SalesforceMicroServicesLogList,
        PSalesforceMicroServicesLogListLoader,
        SLogStatus,
    },
    data() {
        return {
            accordionLog: [],
            selectedWorkflows: [],
            selectedProjects: [],
            selectedStatus: [],

            selectLogitem: null,
            filterLoading: null,
            searchTimeout: null,
            pageCount: null,
            searchWorkflow: '',
            searchProject: '',
            salesforceBaseUrl : 'https://snyksec--pudding.my.salesforce.com',

            dialogPayloadInformation: false,
            logSourceDropdown: false,
            statusDropdown: false,
            timeDropdown: false,
            workflowDropdown: false,
            projectDropdown: false,
            pageLoading: false,
            paginationLoading: false,
            projectLoading: false,
            dialogLogEntriesInformation: false,
            localLogEntriesLoading: false,
            localRefreshLoading: false,

            logSources: [
                { label: 'From Success', value: 'success' },
                { label: 'From Salesforce', value: 'salesforce' },
            ],
            selectedSource: { label: 'All Sources', value: 'all' },
            logStatus: [
                // { label: 'Queued', value: 'queued' }, // grey
                { label: 'Progress', value: 'progress' }, // yellow
                { label: 'Completed', value: 'completed' }, // dark green
                { label: 'Ignored', value: 'ignored' }, // lite green
                { label: 'Failed', value: 'failed' }, // red
            ],
            logTimes: [
                { label: 'Last 1 hour', value: 1 },
                { label: 'Last 8 hour', value: 8 },
                { label: 'Last 24 hour', value: 24 },
                { label: 'Last 7 days', value: 7 },
            ],
            selectedTime: { label: 'All time', value: 0 },
            LOG_FILTERS: {
                PAGE: 'page',
                PROJECT: 'filter[project_ids]',
                STATUS: 'filter[status]',
                WORKFLOW: 'filter[workflow_id]',
                TIME: 'filter[created_at]',
                SOURCE: 'filter[source]',
                COUNT: 'limit',
            },
            logFilters: {},
            PROJECT_FILTERS: {
                FIEDS: 'fields[projects]',
                TITLE: 'filter[title]',
                SORT: 'sort',
                COUNT: 'count',
            },
            project_filters: {},
        }
    },
    mounted() {
        this.localIndex()
    },
    computed: {
        filteredWorkflowList() {
            return this.microservice_workflow_list.filter(list => list.workflow_id !== 'RUVX' && list.label.toLowerCase().includes(this.searchWorkflow.toLowerCase()))
        },

        filteredMicroserviceWorkflowLogList() {
            return this.microservice_workflow_log_list.filter(list => list.status && list.status !== 'queued')
        },
        
        showPaginateSection () {
            return this.microservice_workflow_log_meta.current_page >= 1 && this.microservice_workflow_log_meta.last_page !== 1 && this.microservice_workflow_log_meta.total > 100
        },

        localIsSourcesFilter() {
            return this.selectedSource && this.selectedSource.label !== 'All Sources'
        },

        localIsTimeFilter() {
            return this.selectedTime && this.selectedTime.label !== 'All time'
        },

        localIsWorkflowFilter() {
            return this.selectedWorkflows && this.selectedWorkflows.length
        },

        localIsStatusFilter() {
            return this.selectedStatus && this.selectedStatus.length
        },

        localIsProjectFilter() {
            return this.selectedProjects && this.selectedProjects.length
        },

        localSalesforceMicroserviceReportUrl() {
            return this.user_self && this.user_self.organization && this.user_self.organization.salesforce_microservice_report_url
        },

        localSalesforceMicroserviceReportToken() {
            return this.user_self && this.user_self.organization && this.user_self.organization.salesforce_microservice_report_token
        },

        localCheckLogsFilter () {
            return !((this.localIsTimeFilter && this.selectedTime && this.selectedTime.value !== 1) 
            || this.localIsSourcesFilter || this.localIsWorkflowFilter || this.localIsStatusFilter || this.localIsProjectFilter)
        },

        ...mapState('Project', {
            project_list: 'list',
        }),

        ...mapState('MicroServiceWorkflowLog', {
            microservice_workflow_log_list: 'list',
            microservice_workflow_log_meta: 'meta',
        }),

        ...mapState('MicroServiceLogEntry', {
            microservice_workflow_log_entry_list: 'list',
        }),

        ...mapState('MicroServiceWorkflow', {
            microservice_workflow_list: 'list',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
      
    },
    methods: {
        async localIndex() {
            if (this.user_self && this.user_self.organization && this.user_self.organization.flag_salesforce_microservice === 0 || this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.pageLoading = false
                return this.$router.replace({name: 'errors-unauthorized'})
            }
            
            this.pageLoading = true
            this.loadFilterData()
            await this.localDefaultTimeFilter()
            await this.localLoadMicroserviceWorkflow()
            this.localSetEndPoint()
            this.logFilters[this.LOG_FILTERS.PAGE] = 1
            this.logFilters[this.LOG_FILTERS.COUNT] = 100
            this.logFilters[this.LOG_FILTERS.STATUS] = ['progress', 'completed', 'failed']
            await this.microservice_workflow_log_clear()
            await this.localFetchMicroserviceWorkflowLog(this.logFilters)
            this.pageCount = this.microservice_workflow_log_meta.current_page
            this.pageLoading = false
        },

        async localRefreshMicroserviceLogsList () {
            this.localRefreshLoading = true
            this.localSetEndPoint()
            await this.localFetchMicroserviceWorkflowLog(this.logFilters)
            this.localRefreshLoading = false
        },

        async localDefaultTimeFilter() {
            this.selectedTime.value = 1
            this.logFilters[this.LOG_FILTERS.TIME] = this.selectedTime.value
            this.selectedTime.label = 'Last 1 hour'
        },

        loadFilterData () {
            this.localLoadProjects()
        },

        localCheckSourece (source_service, target_service = null) {
            return (source_service === 'salesforce' &&  target_service === 'success')
        },
        
        async localFetchPaginate () {
            if(this.pageCount == this.microservice_workflow_log_meta.current_page) return
            this.pageLoading = true
            await this.$vuetify.goTo(0)
            this.localSetEndPoint()
            this.logFilters[this.LOG_FILTERS.PAGE] = this.microservice_workflow_log_meta.current_page
            this.logFilters[this.LOG_FILTERS.COUNT] = this.microservice_workflow_log_meta.per_page
            await this.localFetchMicroserviceWorkflowLog(this.logFilters)
            this.pageCount = this.microservice_workflow_log_meta.current_page
            this.pageLoading = false
        },

        async localFetchMicroserviceWorkflowLog(logFilters) {
            await this.microservice_workflow_log_index(logFilters)
        },

        localSelectLogItem (item, type) {
            this.selectLogitem = item
            this.selectLogitem.payload_type = type
            this.dialogPayloadInformation = true
        },

        localGetWorkflowTitle(workflow_id) {
            const workflow = this.microservice_workflow_list.find(item => item.workflow_id === workflow_id)
            return workflow ? workflow.label : ''
        },

        async localProjectFilter(project_id = null) {
            this.filterLoading = 'project'
            if (!project_id) return this.localDefaultProjectFilter()

            if (this.selectedProjects && !this.selectedProjects.length) this.selectedProjects.push(project_id)
            else {
                const index = this.selectedProjects.findIndex(id => id === project_id)
                if (index !== -1) this.selectedProjects.splice(index, 1)
                else this.selectedProjects.push(project_id)
            }

            this.logFilters[this.LOG_FILTERS.PROJECT] = this.selectedProjects
            this.localSetEndPoint()
            await this.localFetchMicroserviceWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        async localDefaultProjectFilter() {
            this.localDefaultFilter()
            this.selectedProjects = []
            this.projectDropdown = false
            this.logFilters[this.LOG_FILTERS.PROJECT] = null
            this.localSetEndPoint()
            await this.localFetchMicroserviceWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        localGetProjectTitle (project_id) {
            const project = this.project_list.find(item => item.id === project_id)
            return project ? project.title : ''
        },

        localProjectIsSelected (project_id) {
            return this.selectedProjects.includes(project_id)
        },

        async localSearchProjects () {            
            clearTimeout(this.searchTimeout)
            this.searchTimeout = window.setTimeout(async () => {
                this.projectLoading = true
                this.project_filters[this.PROJECT_FILTERS.TITLE] = this.searchProject
                this.project_filters[this.PROJECT_FILTERS.COUNT] = this.searchProject ? 999 : 20
                await this.localFetchProjects()
                this.projectLoading = false
            }, 1000)
        },

        async localLoadProjects () {
            this.projectLoading = true
            this.project_filters[this.PROJECT_FILTERS.SORT] = 'title'
            this.project_filters[this.PROJECT_FILTERS.COUNT] = 20
            await this.localFetchProjects()
            this.projectLoading = false
        },

        async localFetchProjects () {
            this.project_filters[this.PROJECT_FILTERS.FIEDS] = 'id,title'
            await this.project_index(this.project_filters)
        },

        async localLoadMicroserviceWorkflow() {
            await this.microservice_workflow_clear()
            await this.microservice_workflow_index({ url: this.localSalesforceMicroserviceReportUrl, token: this.localSalesforceMicroserviceReportToken })
        },

        async localLogSourcesfilter(sources = null) {
            this.filterLoading = 'source'
            this.selectedSource.label = sources.label
            this.selectedSource.value = sources.value
            this.localDefaultFilter()
            this.localSetEndPoint()
            this.logFilters[this.LOG_FILTERS.SOURCE] = sources.value
            await this.localFetchMicroserviceWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        async localLogDefaultSourcesFilter() {
            this.filterLoading = 'source'
            this.selectedSource.label = 'All Sources'
            this.selectedSource.value = 'all'
            this.localSetEndPoint()
            this.logFilters[this.LOG_FILTERS.SOURCE] = this.selectedSource.value
            await this.localFetchMicroserviceWorkflowLog(this.logFilters)
            this.filterLoading = null
        },
        
        async localLogTimefilter(time) {
            this.filterLoading = 'time'
            this.selectedTime.label = time.label
            this.selectedTime.value = time.value
            this.localDefaultFilter()
            this.localSetEndPoint()
            this.logFilters[this.LOG_FILTERS.TIME] = time.value
            await this.localFetchMicroserviceWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        async localLogStatusFilter(value = null) {
            this.filterLoading = 'status'
            if (!value) return this.localDefaultStatusFilter()

            if (this.selectedStatus && !this.selectedStatus.length) this.selectedStatus.push(value)
            else {
                const index = this.selectedStatus.findIndex(id => id === value)
                if (index !== -1) this.selectedStatus.splice(index, 1)
                else this.selectedStatus.push(value)
            }

            this.localDefaultFilter()
            this.logFilters[this.LOG_FILTERS.STATUS] = this.selectedStatus
            this.logFilters.url = this.localSalesforceMicroserviceReportUrl
            this.logFilters.token = this.localSalesforceMicroserviceReportToken
            await this.localFetchMicroserviceWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        async localDefaultStatusFilter() {
            this.selectedStatus = []
            this.logFilters[this.LOG_FILTERS.STATUS] = null
            this.localSetEndPoint()
            await this.localFetchMicroserviceWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        localSetEndPoint() {
            this.logFilters.url = this.localSalesforceMicroserviceReportUrl
            this.logFilters.token = this.localSalesforceMicroserviceReportToken
        },

        localLogStatusIsSelected (value) {
            return this.selectedStatus.includes(value)
        },

        localLogGetStatusValue (value) {
            const status = this.logStatus.find(item => item.value === value)
            return status ? status.value : ''
        },

        async localLogworkflowFilter(workflow_key = null) {
            this.filterLoading = 'workflow'
            if (!workflow_key) return this.localDefaultWorkflowFilter()

            if (this.selectedWorkflows && !this.selectedWorkflows.length) this.selectedWorkflows.push(workflow_key)
            else {
                const index = this.selectedWorkflows.findIndex(id => id === workflow_key)
                if (index !== -1) this.selectedWorkflows.splice(index, 1)
                else this.selectedWorkflows.push(workflow_key)
            }
            this.localDefaultFilter()
            this.logFilters[this.LOG_FILTERS.WORKFLOW] = this.selectedWorkflows
            this.localSetEndPoint()
            await this.localFetchMicroserviceWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        localGetWorlflowLabel(workflow_id) {
            const workflow = this.microservice_workflow_list.find(item => item.workflow_id === workflow_id)
            return workflow ? workflow.label : ''
        },

        localLogWorkflowIsSelected (key) {
            return this.selectedWorkflows.includes(key)
        },

        async localDefaultWorkflowFilter() {
            this.localDefaultFilter()
            this.localSetEndPoint()
            this.selectedWorkflows = []
            this.workflowDropdown = false
            this.logFilters[this.LOG_FILTERS.WORKFLOW] = null
            this.logFilters.url = this.localSalesforceMicroserviceReportUrl
            await this.localFetchMicroserviceWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        async localClearLogFilters() {
            this.filterLoading = 'clear'
            this.localSelectedClear()
            this.localDefaultFilter()
            this.pageLoading = true
            this.logFilters[this.LOG_FILTERS.STATUS] = ['progress', 'completed', 'failed']
            await this.localFetchMicroserviceWorkflowLog(this.logFilters)
            this.pageLoading = false
            this.filterLoading = null
        },

        async localShowLogEntriesInformation(item) {
            this.localLogEntriesLoading = true
            this.dialogLogEntriesInformation = true
            this.selectLogitem = item
            await this.localLoadMicroserviceWorkflowLogEntryList(item.id)
            this.localLogEntriesLoading = false
        },

        async localLoadMicroserviceWorkflowLogEntryList(id) {
            await this.microservice_workflow_log_entry_clear()
            await this.microservice_workflow_log_entry_index(
                { 
                    id : id, 
                    url : this.localSalesforceMicroserviceReportUrl,
                    token: this.localSalesforceMicroserviceReportToken
                }
            )
        },

        localSelectedClear() {
            this.selectedWorkflows = []
            this.selectedProjects = []
            this.selectedStatus = []
            this.selectedSource.label = 'All Sources'
            this.selectedSource.value =  'all'
            this.localSetEndPoint()
            this.localDefaultTimeFilter()
            this.searchWorkflow = '';
            this.logFilters[this.LOG_FILTERS.WORKFLOW] = null
            this.logFilters[this.LOG_FILTERS.STATUS] = null
            this.logFilters[this.LOG_FILTERS.SOURCE] = null
            this.logFilters[this.LOG_FILTERS.PROJECT] = null
        },

        localDefaultFilter() {
            this.logFilters[this.LOG_FILTERS.PAGE] = 1
            this.logFilters[this.LOG_FILTERS.COUNT] = 100
        },

        ...mapActions('MicroServiceWorkflowLog', {
            microservice_workflow_log_index: 'index',
            microservice_workflow_log_clear: 'clear',
        }),

        ...mapActions('MicroServiceLogEntry', {
            microservice_workflow_log_entry_index: 'index',
            microservice_workflow_log_entry_clear: 'clear',
        }),

         ...mapActions('MicroServiceWorkflow', {
            microservice_workflow_index: 'index',
            microservice_workflow_clear: 'clear',
        }),

        ...mapActions('Project', {
            project_index: 'index',
            project_clear: 'clear',
        }),

        ...mapActions('Workflow', {
            workflow_index: 'index',
        }),
    },
}
</script>

<style scoped>
.c-filter-status{
    width: 10px;
    height: 11px;
    border-radius: 50%;
}
.c-filter-completed{
    background: #7aee80 !important;
}
.c-filter-failed{
    background: #fa96a0 !important;
}
.c-filter-queued {
    background: #E0E0E0 !important;
}
.c-filter-progress {
    background: #fce49e !important;
}
.c-filter-ignored {
    background: #cef3cf !important;
}
</style>

import generateBaseUrl from '@/config/config-api'
const api_endpoint = '/template-groups';

const call = {};
const axios = (config = {}, requestType) => {
    // const Axios = generateBaseUrl(process.env.VUE_APP_CORE_API_URL)
    const Axios = generateBaseUrl(process.env.VUE_APP_CORE_API_URL)
    if (requestType) {
        if (call[requestType]) {
            call[requestType].cancel('Only one request allowed at a time. Cancelling the first.');
        }

        call[requestType] = Axios.CancelToken.source();
        config.cancelToken = call[requestType].token
        return Axios(config);
    }
    return Axios(config)
}

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        filters: {
            type: 'Usecase',
            sort_field: 'name',
            sort_direction: 'asc'
        },
        response: [],
        defaults: {
            list: [],
            item: {},
            meta: {},
            filters: {
                type: 'Usecase',
                sort_field: 'name',
                sort_direction: 'asc'
            },
            response: [],
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_LIST_ITEM(state, payload) {
            const index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload.item;
        },
        UPDATE_CHANGE_GROUP_LIST_ITEM(state, payload) {
            const index = _.findIndex(state.list, { 'id': payload.id });
            Object.assign(state.list[index], payload.item)
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        PREPEND_ITEM(state, item) {
            state.list.unshift(item);
        },
        APPEND_ITEM(state, item) {
            state.list.push(item);
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        ADD_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            const index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            const list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
        UPDATE_SECTION_FIELD(state, { id, is_active, field }) {
            const index = _.findIndex(state.list, { id });
            if (index !== -1) state.list[index][field] = is_active
            if (state.item && state.item.id === id) state.item[field] = is_active
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            const config = { method: 'get', url: api_endpoint, params: { ...payload } }
            return axios(config, 'template_index')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        enabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios({ method: 'get', url: api_endpoint + '/enabled' })
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        disabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios({ method: 'get', url: api_endpoint + '/disabled' })
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios({ method: 'get', url: api_endpoint + '/' + payload.id,  params: { ...payload } })
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios({ method: 'post', url: api_endpoint, data: payload })
                .then((response) => {
                    // (response.data.data.order > 0)
                    //     ? context.commit('APPEND_ITEM', response.data.data)
                    //     : context.commit('PREPEND_ITEM', response.data.data)
                    if (payload.model && payload.model === 'template') {
                        context.commit('PREPEND_ITEM', payload)
                        context.commit('UPDATE_ITEM', payload);
                    }
                    else context.commit('UPDATE_ITEM', payload);
                    if (payload.type && payload.type === 'master_template') context.commit('APPEND_ITEM', payload)

                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success', data: response.data.data });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        toggle_active(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios({ method: 'patch', url: api_endpoint + '/' + payload.id + '/states/is-active', data: payload })
                .then((response) => {
                    context.commit('UPDATE_SECTION_FIELD', { id: payload.id, is_active: payload.state, field: 'is_active' })
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        add_group_item(context, payload) {
            context.commit('APPEND_ITEM', payload)
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios({ method: 'patch', url: api_endpoint + '/' + payload.id, data: payload })
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: payload });
                    context.commit('UPDATE_ITEM', payload);
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('REMOVE_ITEM', payload.id);
            return axios({ method: 'delete', url: api_endpoint + '/' + payload.id })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        enable(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios({ method: 'patch', url: api_endpoint + '/' + payload.id + '/enable', data: {} })
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        disable(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios({ method: 'patch', url: api_endpoint + '/' + payload.id + '/disable', data: {} })
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        open(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios({ method: 'patch', url: api_endpoint + '/' + payload.id + '/open', data: {} })
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        close(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios({ method: 'patch', url: api_endpoint + '/' + payload.id + '/close', data: {} })
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        client_visible(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios({ method: 'patch', url: api_endpoint + '/' + payload.id + '/client_visible', data: {} })
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        client_invisible(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios({ method: 'patch', url: api_endpoint + '/' + payload.id + '/client_invisible', data: {} })
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        change_group_update(context, payload) {
            context.commit('UPDATE_CHANGE_GROUP_LIST_ITEM', { id: payload.group_id, item: payload });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return axios({ method: 'patch', url: api_endpoint + '/reorder', data: { data: payload.list } })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        async clear_list(context) {
            context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },
}

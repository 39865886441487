import { storage } from '../helpers/helper-local-storage'
import { echo } from '../config/config-echo'

const hostName = window.location.hostname.split('.')[0]

const Echo = {
    install (Vue, { store }) {
        // TODO: Implement once websockets is available in backend
        // Vue.prototype.$echo = fnSetup(window.Echo, store)
    }
}

const fnSetup = (instance, store) => {
    const { slug: orgSlug } = getOrgDetails(store)

    const setup = {
        instance,
        initialize: () => {
            const { system_organization } = getOrgDetails(store)
            const appId = system_organization?.websocket_app_id
            if (!appId) throw Error('Missing websocket App Id')

            setup.instance = echo.configure(appId)
            window.Echo = setup.instance
        },
        public: (channelId) => {
            if (!setup.instance) setup.initialize()
            return setup.instance.channel(`${hostName || orgSlug}.${channelId}`)
        },
        private: (channelId) => {
            if (!setup.instance) setup.initialize()
            return (instance || setup.instance).private(`${hostName || orgSlug}.${channelId}`)
        },
        isConnected: () => !!(setup.instance && setup.instance.connector?.pusher?.connection?.connection),
    }

    return setup
}

const getOrgDetails = (store) => {
    return storage.get('orgPlan') || store.state.Organization.plan || {}
}

export default Echo

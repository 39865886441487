<template>
    <div>
        <InternalTickets v-if="local_user_is_external" />
        <ExternalTickets v-else />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import InternalTickets from '@/pages/Projects/ProjectsTickets.vue'
import ExternalTickets from '@/pages/External/ExternalProjects/ExternalProjectsTickets.vue'

export default {
    components: {
        ExternalTickets,
        InternalTickets
    },
    computed: {
        local_user_is_external() {
            return this.user_self && this.user_self.scope === 'internal'
        },
        ...mapState('User', {
            user_self: 'self'
        })
    }
}
</script>

<template>
    <div functional>
        <div :class="{ 'mb-3': i !== 0 }" v-for="i in range" :key="i">
            <a-sheet class="u-flex" :class="[wrapperClass]">
                <a-sheet min-width="40" min-height="40" :class="['u-rounded-corners-full', color]"></a-sheet>
                <div class="mt-2 ml-3 u-wfull">
                    <a-sheet width="100%" height="8" :class="['u-rounded-corners-full mb-2', color]"></a-sheet>
                    <div class="u-flex align-center">
                        <a-sheet width="100%" height="8" :class="['u-rounded-corners-full', color]"></a-sheet>
                        <a-sheet width="50" height="8" :class="['ml-3 u-rounded-corners-full', color]"></a-sheet>
                    </div>
                </div>
            </a-sheet>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        range: {
            type: Number,
            default: 5
        },
        wrapperClass: {
            type: String,
        },
        color: {
            type: String,
            default: 'grey lighten-1'
        }
    }
}
</script>

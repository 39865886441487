<template>
    <a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>

        <!-- Header -->
        <a-container grid-list-xl container--fluid class="mt-6 pa-0 ma-0">
            <g-page-header
                title="Billing"
                subtitle="Setup and manage your billing account."
                icon="receipt"
            >
                <template v-slot:action v-if="mixinGetOrgPlan({ field: 'subscription_plan.level' }) === 2">
                    <a-btn color="primary" class="mx-0 elevation-0" dark @click="localGotoManageBilling()">
                        <a-icon class="mr-2" color="white" size="18">credit_card</a-icon>
                        Manage Billing
                    </a-btn>
                </template>
            </g-page-header>
        </a-container>

        <a-container grid-list-xl container--fluid class="pb-16 mx-0 mt-2 mb-16 pa-0">
            <!-- Tabs -->
            <div class="u-flex-center-y">
                <template v-for="tab in localTabRoutes">
                    <div
                        :key="tab.name"
                        class="pb-2 mr-8 d-inline-block u-no-select font-weight-medium md-body-1"
                        :class="[
                            $route.name === tab.name ? 'indigo--text text--darken-2' : 'grey--text text--darken-2',
                            { 'u-cursor-pointer': !pageLoading }
                        ]"
                        :ref="`ref-${tab.name}`"
                        @click="!pageLoading ? localGotoSubRoute(tab.name) : ''"
                    >
                        {{ tab.label }}
                    </div>
                </template>
            </div>

            <!-- Tab Slider -->
            <div class="u-wfull grey lighten-2 u-relative" style="height: 2px;">
                <div
                    class="u-absolute u-hfull indigo darken-1"
                    :style="{ left: tabSliderPos + 'px', width: tabSliderWidth + 'px' }"
                    style="transition: 0.2s left ease-in-out,0.1s width ease-in"
                ></div>
            </div>

            <div v-if="pageLoading" class="py-6">
                Fetching billing details...
            </div>
            <div v-else>
                <router-view></router-view>
            </div>
        </a-container>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ApiUrl } from '@/helpers/helper-api-url-constructor'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'

export default {
    mixins: [mixinGetOrganizationPlan],

    data () {
        return {
            tabSliderWidth: 71,
            tabSliderPos: 0,
            pageLoading: true,
        }
    },

    watch: {
        '$route.name': {
            handler (val) {
                this.localGotoSubRoute(val)
            }
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localTabRoutes () {
            const routes = [{ name: 'settings-billing-overview', label: 'Overview' }]
            if (this.mixinGetOrgPlan({ field: 'subscription_plan.level' }) !== 3) {
                routes.push(...[{ name: 'settings-billing-usage', label: 'Usage' }, { name: 'settings-billing-plans', label: 'Plans' }])
            }

            return routes
        },

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async localIndex() {
            if (this.user_self && this.user_self.is_billing !== 1) {
                return this.$router.replace({name: 'errors-unauthorized'})
            }
            this.$vuetify.goTo(0)
            this.localGotoSubRoute(this.$route.name)
            await this.user_me()
            await this.actionBillingStats()
            this.pageLoading = false
        },

        localGotoSubRoute (routeName) {
            const currentRouteName = this.$route.name
            const { clientWidth, offsetLeft } = this.$refs['ref-' + routeName][0]
            this.tabSliderWidth = clientWidth
            this.tabSliderPos = offsetLeft

            if (currentRouteName === routeName) return
            this.$router.push({ name: routeName })
        },

        localGotoManageBilling () {
            window.location.href = ApiUrl('/internal/stripe/configure')
        },

        ...mapActions('User', {
            user_me: 'me',
        }),

        ...mapActions('SubscriptionPlan', [
            'actionBillingStats'
        ])
    },
}
</script>

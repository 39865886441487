<template>
    <v-textarea v-model="showModel" v-on="$listeners" v-bind="$attrs">
        <template v-for="(_, scopedSlotName) in scopedSlots" v-slot:[scopedSlotName]="scope">
            <slot :name="scopedSlotName" v-bind="scope" />
        </template>
        <template v-for="(_, slotName) in slots" v-slot:[slotName]>
            <slot :name="slotName" />
        </template>
    </v-textarea>
</template>

<script>
import { VTextarea } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VTextarea },
    props: ['value'],

    data () {
        return {
            ref: null,
            slots: {},
            scopedSlots: {}
        }
    },

    mounted () {
        const scopedSlots = ['counter', 'message']
        for (const iterator of Object.entries(this.$scopedSlots)) {
            if (scopedSlots.includes(iterator[0])) this.scopedSlots[iterator[0]] = iterator[1]
            else this.slots[iterator[0]] = iterator[1]
        }

        this.ref = this.$parent.$children && this.$parent.$children[0] &&
                        this.$parent.$children[0].$children && this.$parent.$children[0].$children[0] ?
                        this.$parent.$children[0].$children[0] : null
    },

    computed: {
        showModel: {
            get () {
                return this.value
            },
            set (val) {
                this.$emit('update:value', val)
            }
        }
    }
}
</script>

<template>
    <a-main>
        <PartialBillingOverviewLoader v-if="pageLoading" />

        <template v-if="!pageLoading">
            <!-- Payment failed info -->
            <PartialPaymentStatus v-if="mixinGetOrgPlan({ field: 'subscription_payment_status' }) === 'failed'">
                Oops! We are unable to process your payment. Please check your credentials to avoid downgrade.
            </PartialPaymentStatus>

            <!-- Payment pending info -->
            <PartialPaymentStatus v-if="mixinGetOrgPlan({ field: 'subscription_pending' }) === 1" class="orange darken-4">
                Payment pending! Please pay your overdues to continue using Success.
            </PartialPaymentStatus>

            <div class="mt-8 u-flex align-stretch" style="column-gap: 24px; row-gap: 24px;" v-if="localCurrentPlan && localCurrentPlan.level === 1">
                <!-- Current Plan -->
                <PartialPlanInfoCard class="u-wfull" :planLevel="localCurrentPlan.level" @action="localGoTo('settings-billing-plans')">
                    <template #plan-name>{{ localCurrentPlan.name }}</template>
                    <template #progress-bar>
                        <PartialProjectsCountBar
                            :totalCount="localTotalProjectsLimit"
                            :activeCount="localSubscriptionStats.active_projects"
                        />
                    </template>
                </PartialPlanInfoCard>

                <!-- Professional Plan -->
                <PartialProffessionalCard min-width="532" max-width="612" class="u-wfull" v-if="localCurrentPlan && localCurrentPlan.level === 1" />
            </div>

            <div class="flex-wrap mt-8 u-flex align-stretch" style="column-gap: 24px; row-gap: 24px;" v-if="localCurrentPlan && localCurrentPlan.level === 2">
                <!-- Current Plan -->
                <PartialPlanInfoCard :planLevel="localCurrentPlan.level" style="flex: 2 1 auto" @action="localGoTo('settings-billing-plans')">
                    <template #plan-name>{{ localCurrentPlan.name }}</template>
                    <h2 class="md-heading-4 grey--text text--darken-3 font-weight-bold">${{ localSubscriptionStats.base_amount }}</h2>
                    <p class="mb-0 md-body-2 font-weight-medium grey--text text--darken-1">
                        {{ localTotalProjectsLimit }} Free Active POCs + ${{ localSubscriptionStats.unit_amount }} / POC / month thereafter
                    </p>
                </PartialPlanInfoCard>

                <!-- Project count for paid plans -->
                <PartialPlanInfoCard :planLevel="localCurrentPlan.level" @action="localGoTo('settings-billing-usage')" style="flex: 0 0 25%">
                    <template #title>
                        <h4 class="mb-1 md-body-2 grey--text text--darken-2">Peak usage for current cycle</h4>
                        <p class="mb-0 md-body-2 font-weight-medium grey--text text--darken-3">
                            {{ localFormatDate({ date: localSubscriptionStats.start_date }) }} - {{ localGetCurrentMonth() }}
                        </p>
                    </template>
                    <template #action-label>View details</template>
                    <h2 class="md-heading-4 grey--text text--darken-3 font-weight-bold">{{ localSubscriptionStats.active_projects }}</h2>
                    <p class="mb-0 md-body-2 font-weight-medium grey--text text--darken-1">
                        Active POCs
                    </p>
                </PartialPlanInfoCard>

                <!-- Billing estimation for paid plans -->
                <PartialPlanInfoCard
                    :planLevel="localCurrentPlan.level" actionIcon="launch" style="flex: 0 0 25%"
                    @action="localManagePayment()"
                >
                    <template #title>
                        <h4 class="mb-1 md-body-2 grey--text text--darken-2">Estimated billing for</h4>
                        <p class="mb-0 md-body-2 font-weight-medium grey--text text--darken-3">
                            {{ localFormatDate({ date: localSubscriptionStats.start_date }) }} - {{ localFormatDate({ date: localSubscriptionStats.due_date }) }}
                        </p>
                    </template>
                    <template #action-label>Manage payment method</template>
                    <h2 class="md-heading-4 grey--text text--darken-3 font-weight-bold">${{ localSubscriptionStats.estimated_billing }}</h2>
                    <p class="mb-0 md-body-2 font-weight-medium grey--text text--darken-1">
                        Base Plan + Usage
                    </p>
                </PartialPlanInfoCard>
            </div>

            <div class="flex-wrap mt-8 u-flex align-stretch" style="column-gap: 24px; row-gap: 24px;" v-if="localCurrentPlan && localCurrentPlan.level === 3">
                <!-- Current Plan -->
                <PartialPlanInfoCard hideAction :planLevel="localCurrentPlan.level" style="flex: 0 0 50%">
                    <template #plan-name>{{ localCurrentPlan.name }}</template>
                    <div>
                        <h2 class="md-heading-4 grey--text text--darken-3 font-weight-bold">Customized</h2>
                        <span class="md-body-2 grey--text text--darken-2">for your specific needs with all integrations</span>
                    </div>
                </PartialPlanInfoCard>
            </div>
        </template>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'
import PartialPlanInfoCard from './Partials/PartialPlanInfoCard.vue'
import PartialProjectsCountBar from './Partials/PartialProjectsCountBar.vue'
import PartialProffessionalCard from './Partials/PartialProffessionalCard.vue'
import PartialStarterPlusCard from './Partials/PartialStarterPlusCard.vue'
import PartialBillingOverviewLoader from './Partials/PartialBillingOverviewLoader.vue'
import PartialPaymentStatus from './Partials/PartialPaymentStatus.vue'

export default {
    mixins: [mixinGetOrganizationPlan],

	components: {
        PartialPlanInfoCard,
        PartialPaymentStatus,
        PartialStarterPlusCard,
        PartialProjectsCountBar,
        PartialProffessionalCard,
        PartialBillingOverviewLoader,
    },

    data () {
        return {
            pageLoading: true,
            stripe_url: process.env.VUE_APP_CORE_API_URL + '/' + window.location.hostname.split('.')[0] + '/internal/stripe/configure'
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localTotalProjectsLimit () {
            const limits = { 1: 10, 2: 10, 3: -1 }
            return limits[this.localCurrentPlan.level]
        },

        localCurrentPlan () {
            return this.subscription_plan_item && this.subscription_plan_item.subscription_plan
        },

        localSubscriptionStats () {
            return this.subscription_plan_item
        },

        ...mapState('Organization', {
            organization_plan: 'plan'
        }),

        ...mapState('SubscriptionPlan', {
            subscription_plan_item: 'item'
        })
    },

    methods: {
        async localIndex() {
            if (this.user_self && this.user_self.is_billing !== 1) {
                return this.$router.replace({ name: 'errors-unauthorized' })
            }

            this.$vuetify.goTo(0)
            // await this.actionBillingStats()
            this.pageLoading = false
        },

        localManagePayment () {
            window.location.href = process.env.VUE_APP_CORE_API_URL + '/' + window.location.hostname.split('.')[0] + '/internal/stripe/configure'
        },

        localGetCurrentMonth (params) {
            const { format = 'MMM DD, YYYY' } = params || {}
            return moment().format(format)
        },

        localCurrentStartDate (params) {
            const { format = 'MMM DD, YYYY' } = params || {}
            return moment().startOf('month').format(format)
        },

        localFormatDate (params) {
            const { date = moment(), format = 'MMM DD, YYYY' } = params || {}
            return moment(date).format(format)
        },

        localGoTo (routeName) {
            this.$router.push({ name: routeName })
        },

        // ...mapActions('SubscriptionPlan', [
        //     'actionBillingStats'
        // ])
    },
}
</script>

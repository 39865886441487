<template>
    <div class="pt-5">
        <template v-if="localShowHeader">
            <jira-breadcrumb></jira-breadcrumb>
            <jira-header :installation="installation_item"></jira-header>
            <jira-tabs :installation="installation_item"></jira-tabs>
        </template>
        <div class="">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import JiraBreadcrumb from './Partials/JiraBreadcrumb'
import JiraHeader from './Partials/JiraHeader'
import JiraTabs from './Partials/JiraTabs'

export default {
    components: {
        JiraBreadcrumb,
        JiraHeader,
        JiraTabs,
    },

    mounted() {
        this.local_index()
    },

    computed: {
        localShowHeader () {
            const whiteList = ['appstore-jira-workflows-create', 'appstore-jira-workflows-edit']
            return !whiteList.includes(this.$route.name)
        },

        ...mapState('WorkflowLog', {
            workflow_log_list: 'list',
            workflow_log_filters: 'filters',
        }),

        ...mapState('Installation', {
            installation_item: 'item',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index() {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.installation_clear_item()
            // await this.workflow_log_clear()
            // await this.workflow_clear()

            await this.installation_show({id: this.$route.params.id})

            if (this.installation_item && !this.installation_item.is_active) {
                this.$router.replace({ name: 'appstore-jira-accounts' })
            }

            // this.workflow_log_filters.installation_id = this.$route.params.id
            // await this.workflow_log_index()
        },

        ...mapActions('Installation', {
            installation_show: 'show',
            installation_clear_item: 'clear_item',
        }),

        ...mapActions('Workflow', {
            workflow_clear: 'clear',
            workflow_clear_item: 'clear_item',
        }),

        ...mapActions('WorkflowLog', {
            workflow_log_index: 'index',
            workflow_log_clear: 'clear',
        }),
    },
}
</script>

<style lang="scss" scoped>

</style>

<template>
	<a-main class="px-6">
        <a-container grid-list-xl container--fluid class="pa-0 my-4" v-if="!localLoading && installation_item && installation_item.applet && installation_account_list && installation_account_list.length === 0">
            <a-layout align-center justify-center>
                <a-flex xs4>
                    <div class="text-center"><a-icon size="160" color="grey lighten-1">cloud_off</a-icon></div>
                    <h2 class="md-heading-5 mb-1 grey--text text--darken-3 text-center">No Acccount Connected</h2>
                    <template v-if="$can('applets.update')">
                        <p class="md-body-2 mb-2 grey--text text--darken-1 text-center">Connect your Salesforce organization as Admin with Success to create workflows and setup automation.</p>
                        <div class="text-center">
                            <div class="text-center d-inline-block">
                                <a-menu offset-y>
                                    <template v-slot:activator="{ on }">
                                        <div class="text-center" v-on="on">
                                            <a-btn dark color="blue darken-1" class="d-inline-flex align-center justify-space-between">
                                                <span>Connect Salesforce</span>
                                                <a-icon dark size="16" class="ml-3">keyboard_arrow_down</a-icon>
                                            </a-btn>
                                        </div>
                                    </template>
                                    <a-list>
                                        <a-list-item v-for="item in account_list_types" :key="item.value" :href="local_salesforc_connect_url + '&app_env=' + item.value">
                                            <a-list-item-title>{{ item.label }}</a-list-item-title>
                                        </a-list-item>
                                    </a-list>
                                </a-menu>
                            </div>
                        </div>
                    </template>
                </a-flex>
            </a-layout>
        </a-container>

        <a-container grid-list-xl container--fluid class="pa-0 my-2 mb-10" v-if="installation_account_list && installation_account_list.length > 0">
            <a-layout align-center>
                <a-flex>
                    <h2 class="md-heading-5 font-weight-semibold">Accounts</h2>
                    <p class="md-body-2 mb-0 grey--text text--darken-2">Salesforce accounts connected with Success to run workflows.</p>
                </a-flex>
            </a-layout>

            <a-layout align-center>
                <a-flex>
                    <a-card v-if="localLoading" height="64" class="u-shadow u-rounded-corners u-overflow-hidden px-4 mb-3 u-flex-center-y" style="column-gap: 12px;">
                        <loader-template width="40" class="u-rounded-corners" height="32"></loader-template>
                        <div class="u-wfull">
                            <loader-template width="500" class="u-rounded-corners" height="16"></loader-template>
                            <loader-template width="300" class="u-rounded-corners mt-2" height="12"></loader-template>
                        </div>
                        <loader-template width="140" class="u-rounded-corners" height="32"></loader-template>
                        <loader-template width="40" class="u-rounded-corners" height="32"></loader-template>
                    </a-card>

                    <template v-if="!localLoading && installation_account_list && installation_account_list.length > 0">
                        <a-card class="u-shadow u-rounded-corners u-overflow-hidden mb-3" v-for="account in installation_account_list" :key="account.id">
                            <a-card-text class="pa-0">
                                <a-container grid-list-xl container--fluid class="py-3">
                                    <a-layout align-center>
                                        <a-flex shrink>
                                            <div class="d-flex">
                                                <img src="../../assets/images/logo-salesforce.svg" alt="Salesforce" style="display: inline-block; max-width: 32px; max-height: 32px;">
                                            </div>
                                        </a-flex>
                                        <a-flex>
                                            <p class="body-2 mb-0 font-weight-bold">{{ account.label }}</p>
                                            <p class="md-caption mb-0">Connected on <g-moment :value="account.created_at" input-format="YYYY-MM-DD" output-format="MMMM D, YYYY"></g-moment></p>
                                        </a-flex>
                                        <a-spacer></a-spacer>
                                        <a-flex shrink py-0 class="u-flex align-center justify-end" v-if="account.salesforce_user_id">
                                            <span style="min-width: 130px;" class="md-body-2 d-block text-right grey--text text--darken-2 font-weight-medium mr-2">Service Account</span>
                                            <div v-ripple class="u-rounded-corners-full u-cursor-pointer pa-1" width="24" height="24" v-if="$can('applets.update')" @click="local_installation_account_service_update(account, account.is_service_account)">
                                                <a-icon v-if="account.is_service_account" size="32" color="green darken-1">toggle_on</a-icon>
                                                <a-icon v-else size="32" color="grey lighten-1">toggle_off</a-icon>
                                            </div>
                                        </a-flex>
                                        <a-flex shrink v-if="$can('applets.destroy')">
                                            <a-menu bottom left offset-y max-width="176" nudge-top="80">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn icon text small color="grey darken-1" class="mx-0" v-on="on">
                                                        <a-icon size="18">delete</a-icon>
                                                    </a-btn>
                                                </template>
                                                <a-card flat>
                                                    <a-card-text class="pa-0">
                                                        <div class="pa-3">
                                                            <p class="md-body-2 mb-0 text-center grey--text text--darken-3">Account will be disconnected and all workflows will be disabled. However, the workflows will not be removed.</p>
                                                        </div>
                                                        <a-btn color="red darken-1" class="elevation-0 ma-0" block dark small @click="local_installation_account_destroy(account.id)">Confirm Delete</a-btn>
                                                    </a-card-text>
                                                </a-card>
                                            </a-menu>
                                        </a-flex>
                                    </a-layout>
                                </a-container>
                            </a-card-text>
                        </a-card>
                    </template>
                </a-flex>
            </a-layout>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data () {
        return {
            localLoading: true,
            account_list_types: [
                { label: 'Production Account', value: 'production' },
                { label: 'Sandbox Account', value: 'sandbox' },
                { label: 'Developer Account', value: 'developer' },
            ],
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        local_salesforc_connect_url () {
            return process.env.VUE_APP_CORE_API_URL + '/' + window.location.hostname.split('.')[0] + '/internal/installation-accounts/auth?applet_slug=' + this.local_applet_slug
        },

        local_applet_slug () {
            return (this.installation_item && this.installation_item.applet && this.installation_item.applet.slug) ?? ''
        },

        ...mapState('Installation', {
            installation_item: 'item',
        }),

        ...mapState('InstallationAccount', {
            installation_account_list: 'list',
            installation_account_filters: 'filters',
            installation_account_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index () {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.installation_account_index({
                'filter[installation_id]': this.$route.params.id,
                applet_slug: 'salesforce'
            })
            this.localLoading = false
        },

        local_installation_account_service_update (account, state) {
            if (!this.$can('applets.update')) return
            this.$set(account, 'is_service_account', state === 1 ? 0 : 1)
            this.installation_account_update({ id: account.id, is_service_account: account.is_service_account })
            if (this.$status(this.installation_account_response)) {
                this.$notify('success', 'Account Updated Successfully!')
            }
        },

        async local_installation_account_destroy (id) {
            await this.installation_account_destroy({ id, params: { installation_id: this.$route.params.id } })
            await this.installation_show({ id: this.$route.params.id })
        },

        ...mapActions('Installation', {
            installation_show: 'show',
            installation_clear_item: 'clear_item',
        }),

        ...mapActions('InstallationAccount', {
            installation_account_index: 'index',
            installation_account_update: 'update',
            installation_account_destroy: 'destroy',
            installation_account_clear: 'clear',
        }),
    },
}
</script>

<template>
	<div class="c-bar-vertical-chart u-relative u-elevation-custom-1 u-rounded-corners-lg white">
        <div class="c-bar-vertical-chart__column u-relative">
            <VueApexCharts
                ref="barVerticalChart"
                :options="chart_options"
                :series="series"
                width="536"
                height="195"
            ></VueApexCharts>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
	name: 'BarVerticalChart',

    components: { VueApexCharts },

    data: function() {
        return {
            series: [
                {
                    name: "Desktops",
                    data: [75, 100, 125, 150, 175, 200]
                }
            ],
            chart_options: {
                chart: {
                    type: "bar",
                    toolbar: {
                        show: false,
                    },
                    animations: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 8,
                        dataLabels: {
                            position: "top",
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    padding: {
                        top: -16,
                        bottom: -8,
                        left: -6,
                        right: 0
                    }
                },
                legend: {
                    show: false,
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    labels: {
                        style: {
                            fontFamily: "Inter, sans-serif",
                            fontWeight: 700,
                        },
                    },
                    position: "bottom",
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        fill: {
                            type: "gradient",
                            gradient: {
                                colorFrom: "#D8E3F0",
                                colorTo: "#BED1E6",
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                },
                yaxis: {
                    show: true,
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: true,
                        style: {
                            fontFamily: "Inter, sans-serif",
                        },
                        offsetX: -14,
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
                fill: {
                    opacity: 1,
                    colors: ['#9C27B0']
                },
                tooltip: {
                    y: {
                        title: {}
                    },
                    z: {}
                }
            },
        }
    }
}
</script>

<style scoped>
.c-bar-vertical-chart {
    height: 227px;
    width: 568px;
}

.c-bar-vertical-chart__column {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.c-bar-vertical-chart__column > div {
    min-height: auto!important;
}
</style>



// TODO: Replace the autosuggesting fields in create and edit form with this component

<template>
    <a-combobox
        v-model="dataValue"
        :items="list"
        :item-text="field"
        :item-value="field"
        background-color="white"
        :append-icon="null"
        :search-input.sync="autosuggest_search[field]"
        @input="local_select_autosuggest(item, field)"
        @click="current_search_module = field"
        @clear="local_autosuggest_search_clear(autosuggest_search, field)"
        v-on="$listeners"
        v-bind="$attrs"
        solo flat hide-details clearable hide-no-data
    ></a-combobox>
        <!-- :loading="autosuggest_loading === 'model_no'" -->
</template>

<script>
export default {
    props: {
        value: {
            type: String | Object
        },
        item: {
            type: Object
        },
        list: {
            type: Array
        },
        field: {
            type: String
        },
        clear: {
            type: Function
        }
    },

    data () {
        return {
            dataValue: this.value,
            autosuggest_search: {},
            current_search_module: null
        }
    },

    watch: {
        autosuggest_search: {
            handler (val) {
                if (this.current_search_module) {
                    const value = val[this.current_search_module]
                    this.item[this.current_search_module] = value
                    this.$emit('hit-search', value, this.current_search_module)
                    // this.local_autosuggest(value, this.current_search_module)
                }
            },
            deep: true
        },

        current_search_module (newVal, oldVal) {
            if (newVal && (newVal !== oldVal)) this.clear()
            // if (oldVal) this.autosuggest_search = {}
        },
    },

    methods: {
        local_autosuggest_search_clear (suggestion_text, field) {
            suggestion_text[field] = null
            this.item[field] = null
            this.local_update()
        },

        local_select_autosuggest (item, field) {
            this.local_update()
            if (item[field] && (typeof item[field] === 'object')) {
                this.item[field] = item[field][field]
            }
            this.current_search_module = null
        },

        local_update () {
            this.$emit('update:value', this.dataValue)
        }
    }
}
</script>

<style>

</style>

<template>
    <a-main>
        <a-container container--fluid grid-list-xl class="pa-0 mx-0 pb-16 mb-16">
            <g-page-header
                title="Tickets"
                subtitle="Manage all the tickets under organization"
                :breadcrumb-items="breadcrumb_items"
                icon="account_tree"
            >
                <template v-slot:action>
                    <a-btn color="primary" v-if="$can('tickets.update')" class="elevation-0 mx-0" :disabled="page_loading" @click="local_ticket_create">
                        <a-icon class="mr-2" color="white" size="18">add</a-icon>
                        Create New Ticket
                    </a-btn>
                </template>
            </g-page-header>

            <!-- Search bar -->
            <a-layout wrap align-start>
                <a-flex xs12 pb-2>
                    <a-card flat class="u-rounded-corners px-4 u-border">
                        <a-text-field
                            v-model="search_tickets"
                            placeholder="Search tickets"
                            background-color="white"
                            prepend-icon="search"
                            ref="refSearchTicket"
                            solo flat hide-details autofocus
                            @input="local_search_tickets()"
                        ></a-text-field>
                    </a-card>
                </a-flex>
            </a-layout>

            <!-- Filters Section -->
            <a-layout align-center mt-2 mb-3>
                <a-flex shrink>
                    <a-menu bottom offset-y>
                        <template v-slot:activator="{ on }">
                            <a-card flat class="u-rounded-corners u-overflow-hidden u-border" v-on="on">
                                <a-card-text class="px-2 py-1 u-flex align-center">
                                    <div class="u-flex align-center">
                                        <a-icon size="20" :color="ticket_filter[FILTERS.STATUS] && ticket_filter[FILTERS.STATUS] !== 'all' ? 'blue darken-1' : 'grey darken-1'" class="ma-0">filter_list</a-icon>
                                        <span class="md-body-2 mx-1 text-truncate d-inline-block" style="max-width: 150px;">Status: <strong>{{ ticket_filter[FILTERS.STATUS] && ticket_filter[FILTERS.STATUS] !== 'all' ? local_get_filter_value(status_list, ticket_filter[FILTERS.STATUS], 'value') : 'All' }}</strong></span>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <a-list class="u-list-condensed">
                            <a-list-item @click="local_filter_tickets(FILTERS.STATUS, 'all')">
                                <a-list-item-title>
                                    <span class="md-body-2">All</span>
                                </a-list-item-title>
                            </a-list-item>
                            <a-list-item v-for="item in status_list" :key="item.id" @click="local_filter_tickets(FILTERS.STATUS, item.id)">
                                <a-list-item-title>
                                    <span class="md-body-2">{{ item.value }}</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-flex shrink px-0>
                    <a-menu bottom offset-y>
                        <template v-slot:activator="{ on }">
                            <a-card flat class="u-rounded-corners u-overflow-hidden u-border" v-on="on">
                                <a-card-text class="px-2 py-1 u-flex align-center">
                                    <div class="u-flex align-center">
                                        <a-icon size="20" :color="ticket_filter[FILTERS.PRIORITY] && ticket_filter[FILTERS.PRIORITY] !== 'all' ? 'blue darken-1' : 'grey darken-1'" class="ma-0">filter_list</a-icon>
                                        <span class="md-body-2 mx-1 text-truncate d-inline-block" style="max-width: 150px;">Priority: <strong>{{ ticket_filter[FILTERS.PRIORITY] && ticket_filter[FILTERS.PRIORITY] !== 'all' ? local_get_filter_value(priority_list, ticket_filter[FILTERS.PRIORITY], 'value') : 'All' }}</strong></span>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <a-list class="u-list-condensed">
                            <a-list-item @click="local_filter_tickets(FILTERS.PRIORITY, 'all')">
                                <a-list-item-title>
                                    <span class="md-body-2">All</span>
                                </a-list-item-title>
                            </a-list-item>
                            <a-list-item v-for="item in priority_list" :key="item.id" @click="local_filter_tickets(FILTERS.PRIORITY, item.id)">
                                <a-list-item-title>
                                    <span class="md-body-2">{{ item.value }}</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-flex shrink>
                    <a-menu bottom offset-y>
                        <template v-slot:activator="{ on }">
                            <a-card flat class="u-rounded-corners u-overflow-hidden u-border" v-on="on">
                                <a-card-text class="px-2 py-1 u-flex align-center">
                                    <div class="u-flex align-center">
                                        <a-icon size="20" :color="ticket_filter[FILTERS.TYPE] && ticket_filter[FILTERS.TYPE] !== 'all' ? 'blue darken-1' : 'grey darken-1'" class="ma-0">filter_list</a-icon>
                                        <span class="md-body-2 mx-1 text-truncate d-inline-block" style="max-width: 150px;">Type: <strong>{{ ticket_filter[FILTERS.TYPE] && ticket_filter[FILTERS.TYPE] !== 'all' ? local_get_filter_value(type_list, ticket_filter[FILTERS.TYPE], 'value') : 'All' }}</strong></span>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <a-list class="u-list-condensed">
                            <a-list-item @click="local_filter_tickets(FILTERS.TYPE, 'all')">
                                <a-list-item-title>
                                    <span class="md-body-2">All</span>
                                </a-list-item-title>
                            </a-list-item>
                            <a-list-item v-for="item in type_list" :key="item.id" @click="local_filter_tickets(FILTERS.TYPE, item.id)">
                                <a-list-item-title>
                                    <span class="md-body-2">{{ item.value }}</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink class="pl-1">
                    <a-menu bottom offset-y>
                        <template v-slot:activator="{ on }">
                            <a-card flat class="u-rounded-corners u-overflow-hidden u-border" v-on="on">
                                <a-card-text class="px-2 py-1">
                                    <a-icon size="20" class="ma-0" :color="ticket_filter[FILTERS.SORT] && ticket_filter[FILTERS.SORT] !== 'created_at' ? 'blue darken-1' : 'grey darken-1'">sort</a-icon>
                                    <span class="md-body-2 mx-1">Sort by: <strong>{{ ticket_filter[FILTERS.SORT] ? local_get_filter_value(sort_field_list, ticket_filter[FILTERS.SORT], 'label') : 'Created Date' }}</strong></span>
                                    <a-icon size="20" class="ma-0">arrow_drop_down</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <a-list class="u-list-condensed">
                            <a-list-item v-for="item in sort_field_list" :key="'status_key_' + item.value" @click="local_filter_tickets(FILTERS.SORT, item.value)">
                                <a-list-item-title>
                                    <span class="md-body-2">Sort by: {{ item.label }}</span>
                                </a-list-item-title>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
                <a-flex shrink class="pl-1">
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-card flat class="u-rounded-corners u-overflow-hidden u-border u-cursor-pointer" @click="local_filter_tickets('sort_direction', (sort_direction === '-') ? '' : '-' )" v-on="on">
                                <a-card-text class="px-2 py-1">
                                    <a-icon size="20" class="ma-0">{{ (sort_direction === '') ? 'arrow_downward' : 'arrow_upward' }}</a-icon>
                                </a-card-text>
                            </a-card>
                        </template>
                        <span v-if="sort_direction === ''">Sorted Ascending</span>
                        <span v-if="sort_direction === '-'">Sorted Descending</span>
                    </a-tooltip>
                </a-flex>
                <a-flex shrink class="pl-1">
                    <a-progress-circular color="orange darken-2" size="24" width="2" :indeterminate="loading"></a-progress-circular>
                </a-flex>
            </a-layout>

            <!-- Page Loading -->
            <a-layout v-if="page_loading">
                <a-flex>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-medium text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Tickets..</h3>
                    </a-responsive>
                </a-flex>
            </a-layout>

            <!-- No Data Found Message -->
            <a-layout v-if="!page_loading && (ticket_list && !ticket_list.length)">
                <a-flex>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-medium text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                        <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.<br>Try narrowing your search or add new ticket.</p>
                    </a-responsive>
                </a-flex>
            </a-layout>

            <!-- Tickets List -->
            <a-layout wrap align-start v-if="!page_loading">
                <a-flex xs12 py-0>
                    <draggable key="list" v-model="ticket_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                        <div class="u-wfull relative">
                            <div
                                v-for="(ticket) in ticket_list"
                                :key="ticket.id"
                                @click="local_show_ticket(ticket.id)"
                                class="js-draggable-file-list-item u-flex-center-y u-shadow white u-cursor-pointer u-rounded-corners mb-4 pa-5 pt-4"
                            >
                                <div class="md-caption font-weight-bold grey--text text--darken-2 u-wfull mr-5 text-truncate">
                                    <h2 class="text-subtitle-1 grey--text text--darken-4 text-truncate mb-2" :title="ticket.title">
                                        {{ ticket.title }}
                                    </h2>
                                    <div class="u-flex align-center u-wfull">
                                        <s-meta-ticket-dropdown
                                            :can-update="$can('tickets.update')"
                                            :disabled="!$can('tickets.update')"
                                            :meta-item="ticket.type"
                                            :meta-list="type_list"
                                            :hide-icon="!$can('tickets.update')"
                                            :loading="loading_ticket_item(ticket.id, 'type_id', ticket.type_id)"
                                            @updateMeta="local_update_meta(ticket.id, 'type', ...arguments)"
                                            max-width="150"
                                            inline
                                        ></s-meta-ticket-dropdown>
                                        <a-icon size="5" color="grey lighten-2" class="ml-3">fiber_manual_record</a-icon>
                                        <div class="md-body-2 u-cursor-pointer grey--text text--darken-1 u-flex align-center mb-0 ml-3">
                                            <span class="mr-1">
                                                First Reported by
                                                <span v-if="!ticket.created_by && ticket.bridges && ticket.bridges.length" class="font-weight-medium">Jira</span>
                                                <a-tooltip bottom v-else>
                                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on" class="font-weight-medium">
                                                            {{ ticket.created_by ? ticket.created_by.name: '' | truncateText(13) }}
                                                        </span>
                                                    </template>
                                                    <div class="u-flex align-center">
                                                        <g-avatar :item="ticket.created_by" no-border :size="28" class="mr-2" :right-space="false"></g-avatar>
                                                        <g-profile-info dark :item="ticket.created_by"></g-profile-info>
                                                    </div>
                                                </a-tooltip>
                                                on
                                            </span>
                                             <a-menu bottom open-on-hover offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <g-moment :value="ticket.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD" convert-local></g-moment>
                                                    </div>
                                                </template>
                                                <a-sheet class="md-body-2 u-elevation-custom-1 u-rounded-corners primary darken-1 grey--text text--lighten-1 pa-2 px-3">
                                                    <g-moment :value="ticket.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mm A" convert-local></g-moment>
                                                </a-sheet>
                                            </a-menu>

                                            <div class="u-flex align-center u-cursor-pointer" v-if="ticket.bridges && ticket.bridges.length && ticket.bridges[0]">
                                                <span class="grey--text text--darken-1 mx-3">&bull;</span>
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <a v-on="on" target="_blank" :href="ticket.bridges[0].meta ? ticket.bridges[0].meta.link : '#/'" class="d-block">
                                                            <a-avatar size="18" class="d-block u-wfull">
                                                                <img src="../../assets/images/jira-logo.svg" contain alt="Synced with Jira">
                                                            </a-avatar>
                                                        </a>
                                                    </template>
                                                    <span>Synced with Jira</span>
                                                </a-tooltip>
                                            </div>
                                        </div>
                                        <a-icon size="5" color="grey lighten-2" class="ml-3">fiber_manual_record</a-icon>
                                        <span v-if="ticket.code" class="md-body-2 grey--text text--lighten-1 ml-3">
                                            {{ ticket.code }}
                                        </span>
                                        <a-icon size="5" color="grey lighten-2" class="ml-3">fiber_manual_record</a-icon>
                                        <g-attachment-count-info
                                            :count="ticket.attachments_count"
                                            class="ml-3"
                                            fontSize="14"
                                        />
                                    </div>
                                </div>
                                <a-spacer></a-spacer>
                                <div class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left mr-5">
                                    <g-currency tag="div" class="text-subtitle-1 green--text text--darken-1" :value="ticket.project_linked_count ? ticket.project_deal_amount_sum : 0"></g-currency>
                                    <div v-if="ticket.project_ticket_linked_count" class="md-caption font-weight-bold grey--text text--darken-1 u-flex-center-y">
                                        <span>{{ ticket.project_ticket_linked_count }} </span>
                                        <span class="ml-1">Project{{ ticket.project_ticket_linked_count > 1 ? 's' : '' }}</span>
                                    </div>
                                    <div v-else class="md-caption font-weight-bold grey--text text--darken-1">No Project</div>
                                </div>
                                <div class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left mr-5 u-cursor-pointer">
                                    <s-meta-ticket-dropdown
                                        :can-update="$can('tickets.update')"
                                        :disabled="!$can('tickets.update')"
                                        :hide-icon="!$can('tickets.update')"
                                        :meta-item="ticket.priority"
                                        :meta-list="priority_list"
                                        :loading="loading_ticket_item(ticket.id, 'priority_id', ticket.priority_id)"
                                        @updateMeta="local_update_meta(ticket.id, 'priority', ...arguments)"
                                        text-length="6"
                                    ></s-meta-ticket-dropdown>
                                </div>
                                <div class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left mr-5 u-cursor-pointer">
                                    <s-meta-ticket-dropdown
                                        :can-update="$can('tickets.update')"
                                        :disabled="!$can('tickets.update')"
                                        :hide-icon="!$can('tickets.update')"
                                        :meta-item="ticket.status"
                                        :meta-list="status_list"
                                        :loading="loading_ticket_item(ticket.id, 'status_id', ticket.status_id)"
                                        @updateMeta="local_update_meta(ticket.id, 'status', ...arguments)"
                                    ></s-meta-ticket-dropdown>
                                </div>
                                <div class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-right u-cursor-pointer">
                                    <SAssigneeDropdown
                                        :item="ticket"
                                        :users-list="ticket.assignees"
                                        :can-update="$can('tickets.update')"
                                        :size="36"
                                        @click.stop="{}"
                                        @assignee-store="localTicketShow(ticket.id)"
                                        @unassigned="localTicketShow(ticket.id)"
                                        @menuOpen="localFetchUsers(ticket)"
                                        module-type="Ticket"
                                        class="u-flex align-center"
                                        hideExternalConfirmation typeOrgUser enableSearch
                                    >
                                        <template #subtitle>You can assign to organization members.</template>
                                    </SAssigneeDropdown>
                                </div>
                            </div>
                        </div>
                    </draggable>
                </a-flex>
            </a-layout>

            <!-- Load More Button -->
            <a-layout wrap align-start mt-3 v-if="!page_loading">
                <a-flex xs12 class="u-flex align-center">
                    <a-btn v-if="ticket_meta && (ticket_meta.current_page < ticket_meta.last_page)" :loading="loading" :disabled="loading" @click="local_filter_tickets(FILTERS.PAGE, ticket_meta.current_page + 1, 'pagination')" small text class="primary" dark>
                        Load More
                    </a-btn>
                    <a-spacer></a-spacer>
                    <span class="md-body-2 grey--text text--darken-1" v-if="ticket_meta.total">Showing <strong>{{ ticket_list.length }}</strong> out of <strong>{{ ticket_meta.total }}</strong> tickets</span>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Ticket Add/Edit form -->
        <a-dialog v-model="dialog_ticket_form" :max-width="local_screen_lg ? 1200 : 1280" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="45">
                                        <a-icon dark>account_tree</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ ticket_form_mode === 'add' ? 'Create New' : 'Update' }} Ticket</h2>
                                    <p class="md-subtitle-2 mb-0 grey--text text--darken-1" v-if="ticket_form_mode === 'add'">You can create new ticket</p>
                                    <p class="md-subtitle-2 mb-0 grey--text text--darken-1" v-else :title="ticket_item.title">Update ticket <strong class="grey--text text--darken-2">{{ local_truncate_text(ticket_item.title, 70) }}</strong></p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout>
                                <a-flex xs6 pa-0>
                                    <a-container container--fluid grid-list-xl>
                                        <a-layout wrap>
                                            <a-flex xs12>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title</label>
                                                <a-text-field
                                                    v-model="ticket_item.title"
                                                    background-color="neutral"
                                                    placeholder="Enter Title"
                                                    class="u-border"
                                                    clear-icon="backspace"
                                                    solo flat hide-details clearable autofocus
                                                ></a-text-field>
                                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.title">
                                                    <a-icon size="16" class="u-icon-nudge-xs" color="red darken-2">warning</a-icon>
                                                    {{ ticket_response.server.errors.title[0] }}
                                                </span>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                    <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                                    Enter title of the ticket.
                                                </span>
                                            </a-flex>
                                            <a-flex xs6>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Type</label>
                                                <a-select
                                                    v-model="ticket_item.type_id"
                                                    :items="type_list"
                                                    item-text="value"
                                                    item-value="id"
                                                    placeholder="Select Type"
                                                    background-color="neutral"
                                                    solo flat hide-details
                                                >
                                                    <template v-slot:selection="{ item }">
                                                        <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ item.value }}</span>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ item.value }}</span>
                                                    </template>
                                                </a-select>
                                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.type_id">
                                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                                    {{ ticket_response.server.errors.type_id[0] }}
                                                </span>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                    <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                                    Select type of the ticket
                                                </span>
                                            </a-flex>
                                            <a-flex xs6>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Priority</label>
                                                <a-select
                                                    v-model="ticket_item.priority_id"
                                                    :items="priority_list"
                                                    item-text="value"
                                                    item-value="id"
                                                    placeholder="Select Priority"
                                                    background-color="neutral"
                                                    solo flat hide-details
                                                >
                                                    <template v-slot:selection="{ item }">
                                                        <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ item.value }}</span>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ item.value }}</span>
                                                    </template>
                                                </a-select>
                                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.priority_id">
                                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                                    {{ ticket_response.server.errors.priority_id[0] }}
                                                </span>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                    <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                                    Select priority of the ticket
                                                </span>
                                            </a-flex>
                                            <a-flex xs6>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Status</label>
                                                <a-select
                                                    v-model="ticket_item.status_id"
                                                    :items="status_list"
                                                    item-text="value"
                                                    item-value="id"
                                                    placeholder="Select Status"
                                                    background-color="neutral"
                                                    solo flat hide-details
                                                >
                                                    <template v-slot:selection="{ item }">
                                                        <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ item.value }}</span>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ item.value }}</span>
                                                    </template>
                                                </a-select>
                                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.status_id">
                                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                                    {{ ticket_response.server.errors.status_id[0] }}
                                                </span>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                    <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                                    Select status of the ticket
                                                </span>
                                            </a-flex>
                                            <a-flex xs6>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Due Date (Optional)</label>
                                                <!-- <a-menu
                                                    v-model="datepicker_due_date"
                                                    :close-on-content-click="false"
                                                    max-width="250"
                                                    full-width offset-y lazy
                                                >   <template v-slot:activator="{ on }">
                                                        <a-text-field
                                                            v-model="ticket_item.due_date"
                                                            :value="local_format_date(ticket_item.due_date)"
                                                            append-icon="date_range"
                                                            placeholder="Select Due Date"
                                                            background-color="neutral"
                                                            v-on="on"
                                                            clear-icon="backspace"
                                                            solo flat hide-details clearable readonly
                                                        ></a-text-field>
                                                    </template>
                                                    <vc-date-picker
                                                        v-model="ticket_item.due_date"
                                                        class="d-block u-wfull"
                                                        :model-config="modelConfig"
                                                        @input="datepicker_due_date = false">
                                                    </vc-date-picker>
                                                    <a-date-picker v-model="ticket_item.due_date" @input="datepicker_due_date = false" no-title scrollable></a-date-picker>
                                                </a-menu> -->
                                                <vc-date-picker
                                                    v-model="local_due_date"
                                                    :model-config="modelConfig"
                                                    class="d-block u-wfull"
                                                    @input="local_set_date()"
                                                >
                                                    <template v-slot="{ togglePopover }">
                                                        <div @click.stop="() => togglePopover({ placement: 'bottom-start' })">
                                                            <a-text-field
                                                                v-model="local_due_date"
                                                                :value="local_format_date(ticket_item.due_date)"
                                                                append-icon="date_range"
                                                                placeholder="Select Due Date"
                                                                background-color="neutral"
                                                                clear-icon="backspace"
                                                                solo flat hide-details clearable readonly
                                                            ></a-text-field>
                                                        </div>
                                                    </template>
                                                </vc-date-picker>
                                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.due_date">
                                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                                    {{ ticket_response.server.errors.due_date[0] }}
                                                </span>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                    <a-icon size="16">info</a-icon>
                                                    Select due date of the ticket.
                                                </span>
                                            </a-flex>
                                            <a-flex xs12>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Assignee (Optional)</label>
                                                <GUsersSelectDropdown
                                                    v-model="selected_assignees"
                                                    :list="user_list"
                                                    :loading="mixinSearchLoading"
                                                    :inputStyle="{ minHeight: '24px' }"
                                                    inputClass="md-subtitle-1"
                                                    itemValue="id"
                                                    @search="mixinSearchUsers"
                                                    placeholder="Select Assignees"
                                                    noDataText="No User Found"
                                                    class="pr-5 neutral"
                                                    :class="{ 'py-2': selected_assignees && selected_assignees.length }"
                                                    dropdownClass="c-tiny-scroll py-2"
                                                    returnObject multiple removable
                                                >
                                                    <template #item="{ props: { item } }">
                                                        <div class="u-flex-center-y">
                                                            <g-avatar :item="item"></g-avatar>
                                                            <g-profile-info :item="item" type-user set-max-width></g-profile-info>
                                                        </div>
                                                    </template>
                                                </GUsersSelectDropdown>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                                    <a-icon size="16">info</a-icon>
                                                    Select the assignees for the ticket.
                                                </span>
                                            </a-flex>
                                            <a-flex xs12>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Tags (Optional)</label>
                                                <component
                                                    :is="$can('tags.update') ? 'a-combobox' : 'a-autocomplete'"
                                                    v-model="selected_tags"
                                                    :items="tag_list"
                                                    item-text="label"
                                                    item-value="id"
                                                    :placeholder="`Select${ $can('tags.store') ? ' or create ' : ' ' }tag`"
                                                    background-color="neutral"
                                                    :search-input.sync="ticket_tag_search"
                                                    clear-icon="backspace"
                                                    @input="local_ticket_tag_store"
                                                    solo flat hide-details clearable multiple chips small-chips hide-selected deletable-chips
                                                >
                                                    <template v-slot:no-data>
                                                        <div class="px-4 py-2">
                                                            <template v-if="$can('tags.store')">
                                                                <span class="md-body-2" v-if="ticket_tag_search != null && ticket_tag_search != '' && $can('tags.store')">
                                                                    Create <strong>{{ ticket_tag_search }}</strong> as tag. Hit<span style="font-family: monospace;" class="mx-1 font-weight-bold">Enter</span>to create
                                                                </span>
                                                            </template>
                                                            <span v-else class="md-body-2 grey--text">
                                                                No tags found. <template v-if="$can('tags.store')">Enter label and hit enter to create new one.</template>
                                                            </span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{ item }">
                                                        <g-tags :tag="item" @destroy="local_remove_tag(selected_tags, 'tag', item, $event)"></g-tags>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <g-tags :tag="item" dense hide-clear-icon></g-tags>
                                                    </template>
                                                </component>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                                    <a-icon size="16">info</a-icon>
                                                    Select the tags for the ticket.
                                                </span>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-flex>
                                <a-flex xs6>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Description (Optional)</label>
                                    <div class="neutral pa-3 u-rounded-corners c-scrollbar" style="min-height: 578px; max-height: 578px; overflow-y: auto" @click="() => { ticket_item.description = '<p> </p>'; editor_ticket_description.focus() }">
                                        <p class="grey--text text--darken-1 u-hfull" v-if="editor_ticket_description  === null" @click="() => { ticket_item.description = '<p> </p>'; editor_ticket_description.focus() }">Click to edit the description</p>
                                        <editor-content v-else class="u-hfull" :editor="editor_ticket_description"></editor-content>
                                        <editor-menu-bubble :editor="editor_ticket_description" :keep-in-bounds="false" v-slot="{ commands, isActive, menu }">
                                            <div
                                                class="menububble"
                                                :class="{ 'is-active': menu.isActive }"
                                                :style="`left: ${menu.left - 16}px; bottom: ${menu.bottom + 8}px;`"
                                            >
                                                <a-btn icon text small class="mx-0" :color="(isActive.bold()) ? 'blue' : 'grey'" @click="commands.bold">
                                                    <a-icon size="16">format_bold</a-icon>
                                                </a-btn>
                                                <a-btn icon text small class="mx-0" :color="(isActive.italic()) ? 'blue' : 'grey'" @click="commands.italic">
                                                    <a-icon size="16">format_italic</a-icon>
                                                </a-btn>
                                                <a-divider vertical dark></a-divider>
                                                <a-btn icon text small class="mx-0" :color="(isActive.bullet_list()) ? 'blue' : 'grey'" @click="commands.bullet_list">
                                                    <a-icon size="16">format_list_bulleted</a-icon>
                                                </a-btn>
                                                <a-btn icon text small class="mx-0" :color="(isActive.ordered_list()) ? 'blue' : 'grey'" @click="commands.ordered_list">
                                                    <a-icon size="16">format_list_numbered</a-icon>
                                                </a-btn>
                                            </div>
                                        </editor-menu-bubble>
                                    </div>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.description">
                                        <a-icon size="16" class="u-icon-nudge-xs" color="red darken-2">warning</a-icon>
                                        {{ ticket_response.server.errors.description[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                        You can summarize and describe the ticket.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <a-btn color="primary" :loading="ticket_loader_type === 'add'" :disabled="ticket_loader_type === 'add'" class="ma-0 elevation-0 px-3" @click="local_ticket_store">Create Ticket</a-btn>
                                    <a-btn color="grey" class="ml-2" text :disabled="ticket_loader_type === 'add'" @click="local_close_create_form">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!-- Snackbar -->
        <a-snackbar v-model="snackbar_status" auto-heigh :color="snackbar_text.type" :timeout="3000" bottom center>
            <div class="text-center">
                {{snackbar_text.content}}
            </div>
        </a-snackbar>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
    Bold,
    Italic,
    Strike,
    OrderedList,
    BulletList,
    ListItem,
    History,
} from 'tiptap-extensions'
import marked from 'marked'
import { VCombobox, VAutocomplete } from 'vuetify/lib'

import mixinSearchUsers from '@/mixins/mixin-search-users'
import loadWorkspace from '@/mixins/mixin-workspace'
import { SUserListPopup, SMetaTicketDropdown, SAssigneeDropdown } from '@/config/config-shared-components'

export default {
    mixins: [loadWorkspace, mixinSearchUsers],

    components: {
        EditorContent,
        EditorMenuBubble,
        SMetaTicketDropdown,
        SUserListPopup,
        SAssigneeDropdown,
        VCombobox,
        VAutocomplete,
    },

    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            editor_ticket_description: null,
            ticket_description: '',
            dialog_ticket_form: false,
            datepicker_due_date: null,
            status_list: [],
            priority_list: [],
            type_list: [],
            search_tickets: '',
            local_user_edit_item: {},
            local_selected_user_add_item: null,
            autosave_user_add_status: 'none',
            local_search_loading: false,
            ticket_loader_type: null,
            search_ticket_timeout: null,
            ticket_tag_search: null,
            selected_assignees: [],
            selected_tags: [],
            page_loading: true,
            loading_ticket: {},
            local_ticket_fields: {
                'include': 'status,priority,type,bridges,createdBy,assignees.role,links.parent,linksCount,attachmentsCount',
                'filter[is_global]': 1,
                'sort': 'order'
            },
            snackbar_status: false,
            snackbar_text: { type: '', content: '' },
            ticket_filter: {},
            ticket_count: 30,
            sort_direction: '',
            sort_field_list: [
                { id: 'title', 'label': 'Title', 'value': 'title' },
                { id: 'created_at', 'label': 'Created Date', 'value': 'created_at' },
                { id: 'project_deal_amount_sum', 'label': 'Deal Value', 'value': 'project_deal_amount_sum' },
                { id: 'project_linked_count', 'label': 'Project Count', 'value': 'project_linked_count' },
                { id: 'priority_id', 'label': 'Priority', 'value': 'priority_id' },
                { id: 'status_id', 'label': 'Status', 'value': 'status_id' }
            ],
            FILTERS: {
                STATUS: 'filter[status_id]',
                PRIORITY: 'filter[priority_id]',
                TYPE: 'filter[type_id]',
                SORT: 'sort',
                PAGE: 'page',
                COUNT: 'count'
            },
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
            local_due_date: null
        }
    },

    // created() {
    //     window.addEventListener('keypress', this.local_search_command);
    // },

    // destroyed() {
    //     window.removeEventListener('keypress', this.local_search_command);
    // },

    mounted() {
        this.local_index()
    },

    computed: {
        local_screen_lg () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        table_style () {
            return {
                title: {},
                drag: { width: (this.$vuetify.breakpoint.lgAndDown ?  30 : 30) + 'px' },
                // assignee: { minWidth: (this.$vuetify.breakpoint.lgAndDown ?  90 : 50) + 'px', maxWidth: (this.$vuetify.breakpoint.lgAndDown ?  90 : 155) + 'px' },
                assignee: { width: (this.$vuetify.breakpoint.lgAndDown ?  50 : 50) + 'px' },
                deal_value: { width: (this.$vuetify.breakpoint.lgAndDown ?  110 : 130) + 'px' },
                type: { width: (this.$vuetify.breakpoint.lgAndDown ?  120 : 120) + 'px' },
                priority: { width: (this.$vuetify.breakpoint.lgAndDown ?  110 : 120) + 'px' },
                status: { width: (this.$vuetify.breakpoint.lgAndDown ?  140 : 140) + 'px' },
                action: { width: (this.$vuetify.breakpoint.lgAndDown ?  80 : 80) + 'px' },
            }
        },

        ticket_list: {
            get() {
                return this.$store.state.Ticket.list
            },
            set(list) {
                this.ticket_reorder({ data: list })
            }
        },

        ...mapState('User', {
            user_list: 'list',
            user_self: 'self',
        }),

        ...mapState('Assignee', {
            assignee_response: 'response',
        }),

        ...mapState('Ticket', {
            ticket_item: 'item',
            ticket_meta: 'meta',
            ticket_form_mode: 'form_mode',
            ticket_response: 'response',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
            tag_item: 'item',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),
    },

    methods: {
        async local_index() {
            if (this.$can('tickets.index') === false) {
                return this.$router.replace({ name: 'errors-unauthorized' })
            }
            await this.local_set_initial_filters()
            await this.local_ticket_index()
            this.tag_index({ 'filter[type]': 'ticket_tag', 'sort': 'order' })
            this.local_load_status()
            this.local_load_priority()
            this.local_load_type()
            this.page_loading = false
        },

        async local_set_initial_filters () {
            this.sort_direction = '-'
            this.ticket_filter[this.FILTERS.SORT] = '-created_at'
            this.ticket_filter[this.FILTERS.COUNT] = this.ticket_count
        },

        async local_load_status () {
            await this.meta_index({
                'filter[type]': 'ticket_status',
                'sort': 'order'
            })
            this.status_list = [...this.status_list, ...this.meta_list]
        },

        async local_load_priority () {
            await this.meta_index({
                'filter[type]': 'ticket_priority',
                'sort': 'order'
            })
            this.priority_list = [...this.priority_list, ...this.meta_list]
        },

        async local_load_type () {
            await this.meta_index({
                'filter[type]': 'ticket_type',
                'sort': 'order'
            })
            this.type_list = [...this.type_list, ...this.meta_list]
        },

        async local_search_tickets () {
            // this.page_loading = true
            if (this.search_ticket_timeout) clearTimeout(this.search_ticket_timeout)
            if (!this.search_tickets) {
                this.ticket_filter[this.FILTERS.COUNT] = this.ticket_count
                await this.local_ticket_index()
                // this.page_loading = false
                return
            }
            this.search_ticket_timeout = setTimeout(async () => {
                this.page_loading = true
                this.ticket_filter[this.FILTERS.COUNT] = 99999
                await this.local_ticket_index({
                    'filter[title]': this.search_tickets,
                    'fields[status]': 'id,color,value,status,type',
                })
                this.page_loading = false
            }, 700);
        },

        // local_search_command (evt) {
        //     if (evt.keyCode === 47 && evt.key === '/') {
        //         evt.preventDefault()
        //         evt.stopPropagation()
        //         this.$refs.refSearchTicket.focus()
        //     }
        // },

        // Create ticket
        async local_ticket_create () {
            await this.local_create_reset_form()
            this.local_set_description()
            this.local_set_picklist()
            this.dialog_ticket_form = true
            this.snackbar_status = false
        },

        async local_create_reset_form () {
            await this.ticket_clear_item()
            this.selected_assignees = []
            this.selected_tags = []
            this.ticket_description = ''
        },

        local_set_picklist () {
            this.ticket_item.status_id = this.local_get_starred_meta_id(this.status_list)
            this.ticket_item.priority_id = this.local_get_starred_meta_id(this.priority_list)
            this.ticket_item.type_id = this.local_get_starred_meta_id(this.type_list)
        },

        local_get_starred_meta_id (list) {
            if (list.length === 0) return null
            const list_item = list.find(item => item.is_starred)
            return list_item ? list_item.id : list[0].id
        },

        async local_ticket_store () {
            if (!this.ticket_item.due_date) delete this.ticket_item.due_date
            this.ticket_loader_type = 'add'
            this.ticket_item.is_global = 1
            this.ticket_item.description = this.ticket_description

            await this.ticket_store({ ...this.ticket_item, mode: 'ticket_create' })
            if (this.ticket_response.status !== 'success') {
                return this.ticket_loader_type = null
            }

            await this.local_add_users(this.ticket_item)
            await this.local_add_tags(this.ticket_item)
            setTimeout(async () => {
                await this.$router.push({ name: 'tickets-view', params: { id: this.ticket_item.id } })
                this.ticket_loader_type = null
                this.dialog_ticket_form = false
            }, 1000);
        },

        async local_add_users (model) {
            if (!this.selected_assignees.length) return
            _.map(this.selected_assignees, 'id').forEach(async user_id => {
                await this.assignee_store({ resource_type: 'Ticket', resource_id: model.id, user_id })
            })
        },

        async local_add_tags (model) {
            if (!this.selected_tags.length) return
            let tags = this.selected_tags.map(item => item.id ?? item)
            tags.forEach(async tag_id => {
                await this.attachment_store({ source_type: 'Ticket', source_id: model.id, target_type: 'Tag', target_id : tag_id, type: 'ticket_tag' })
            })
        },

        async local_close_create_form() {
            await this.ticket_clear_item()
            this.dialog_ticket_form = false
            if (this.editor_ticket_description) this.editor_ticket_description.destroy()
        },

        local_set_description () {
            if (this.editor_ticket_description) this.editor_ticket_description.destroy()
            const content = this.ticket_item.description ? this.ticket_item.description : ' '
            this.editor_ticket_description = new Editor({
                extensions: [
                    new Bold(),
                    new Italic(),
                    new Strike(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new History(),
                ],
                content: marked(content, { sanitize: true }),
                onUpdate: state => this.ticket_description = turndownService.turndown(state.getHTML()),
            })
        },

        local_show_ticket (id) {
            this.$router.push({ name: 'tickets-view', params: { id } })
        },

        async local_filter_tickets (filter, value, type = null) {
            if (this.ticket_filter[filter] === value) return
            if (filter === 'sort_direction') {
                this.ticket_filter[this.FILTERS.COUNT] = 100
                this.sort_direction = value
                this.ticket_filter[this.FILTERS.SORT] = this.ticket_filter[this.FILTERS.SORT].charAt(0) === '-' ? this.ticket_filter[this.FILTERS.SORT].substring(1) : this.ticket_filter[this.FILTERS.SORT]
                this.ticket_filter[this.FILTERS.SORT] = `${value}${this.ticket_filter[this.FILTERS.SORT]}`
            } else if (filter === 'sort') {
                this.ticket_filter[this.FILTERS.COUNT] = 100
                this.ticket_filter[filter] = value
                this.ticket_filter[filter] = this.ticket_filter[filter].charAt(0) === '-' ? this.ticket_filter[filter].substring(1) : this.ticket_filter[filter]
                this.ticket_filter[filter] = `${this.sort_direction}${this.ticket_filter[filter]}`
            } else {
                if (!type) this.ticket_filter[this.FILTERS.COUNT] = 100
                this.ticket_filter[filter] = value
            }

            await this.local_ticket_index({ type })
        },

        async local_ticket_index (params = {}) {
            await this.ticket_index({
                ...this.local_ticket_fields,
                ...this.ticket_filter,
                ...params
            })
        },

        async localTicketShow (id, params = this.local_ticket_fields) {
            await this.ticket_show({ id, ...params })
        },

        async localFetchUsers (ticket) {
            const userIds = _.size(ticket.assignees) ? _.map(ticket.assignees, 'id') : null
            this.user_index({
                'filter[is_active]': 1,
                'filter[negate_user_ids]': userIds,
                include: 'role',
                'fields[users]': 'id,name,email,initial,role_id,avatar,scope,color',
                'fields[role]': 'id,name,scope',
                count: 100,
                page: 1
            })
        },

        // Add Assignee
        async local_assign (ticket, { id: user_id }, unassign = false) {
            const assignee_param = { resource_type: 'Ticket', resource_id: ticket.id, user_id }
            this.autosave_user_add_status = 'none'
            this.autosave_user_add_status = 'loading'

            if (unassign) await this.assignee_destroy({ id: user_id })
            else await this.assignee_store(assignee_param)
            if (this.assignee_response.status === 'success') {
                await this.ticket_show({ id: ticket.id, ...this.local_ticket_fields })
                this.local_reset_assignee_variables(ticket.id)
            }
        },

        local_reset_assignee_variables (ticket_id) {
            this.local_selected_user_add_item = null
            this.autosave_user_add_status = 'done'
            Object.assign(this.local_user_edit_item, this.ticket_list.find(item => item.id === ticket_id))
        },

        local_check_user_external (user_id) {
            const { user } = _.find(this.local_collaborator_list, { user_id })
            return user && user.role && user.role.scope === 'external' ? user : false
        },

        // Tag store
        async local_ticket_tag_store () {
            if (this.ticket_tag_search && this.$can('tags.store')) {
                this.tag_item.label = this.ticket_tag_search
                this.tag_item.type = 'ticket_tag'
                this.tag_store({ ...this.tag_item, mode: 'add_update' }).then(async res => {
                    setTimeout(async () => {
                        this.selected_tags.push(this.tag_item)
                        this.selected_tags = this.selected_tags.filter(item => typeof item === 'object')
                        this.ticket_tag_search = null
                        await this.tag_clear_item()
                    }, 200);
                })
            }
        },

        local_remove_tag (list, type, item) {
            const id = item.id ?? item
            const index = list.findIndex(chip => (chip === id) || (chip.id === id))
            if(index !== -1) list.splice(index, 1)
        },

        // Metas Update
        async local_update_meta (id, field, { meta_id }) {
            this.snackbar_status = false
            await this.ticket_select({ id })
            if (this.ticket_item[field + '_id'] === meta_id) return

            this.local_set_ticket_loading(id, field)
            this.ticket_item[field + '_id'] = meta_id
            await this.ticket_update({
                id: id,
                item: this.ticket_item,
                mode: 'update',
                params: {
                    ...this.local_ticket_fields,
                    workspace_id: this.current_workspace_id_mixin
                }
            })
            if (this.ticket_response.status === 'success') {
                this.local_notify_response('Ticket updated successfully', 'success')
                this.loading_ticket = {}
            }
        },

        // Extras
        local_set_ticket_loading (id, field) {
            this.loading_ticket.id = id
            this.loading_ticket[field + '_id'] = this.ticket_item[field + '_id']
        },

        local_get_filter_value (list, value, key) {
            if (value && typeof value === 'string' && value.charAt(0) === '-') return _.get(_.find(list, {value: value.substring(1)}), key)
            return _.get(_.find(list, { id: value }), key)
        },

        local_set_date() {
            this.ticket_item.due_date = this.local_due_date
            this.local_due_date = this.local_format_date(this.ticket_item.due_date)
            this.datepicker_due_date = false
        },

        local_format_date(date) {
            return date ? moment(date).format('MMM DD, YYYY') : null
        },

        local_truncate_text(text, range) {
            if (!text) return
            return text.length > range ? text.slice(0, range) + '...' : text
        },

        loading_ticket_item (id, field = null, meta_id = null) {
            if (!meta_id) return this.loading_ticket.id === id
            return this.loading_ticket.id === id && this.loading_ticket[field] === meta_id
        },

        local_notify_response (content, type) {
            this.snackbar_text.content = content
            this.snackbar_text.type = type ?? 'success'
            this.snackbar_status = true
        },

        ...mapActions('Meta', {
            meta_index: 'index',
        }),

        ...mapActions('User', {
            user_index: 'index',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_clear_item: 'clear_item',
        }),

        ...mapActions('Assignee', {
            assignee_store: 'store',
            assignee_destroy: 'destroy',
        }),

        ...mapActions('Association', {
            attachment_store: 'store',
            attachment_destroy: 'destroy',
        }),

        ...mapActions('Ticket', {
            ticket_index: 'index',
            ticket_store: 'store',
            ticket_update: 'update',
            ticket_select: 'select',
            ticket_show: 'show',
            ticket_reorder: 'reorder',
            ticket_clear_item: 'clear_item',
        }),
    },
}
</script>

<style lang="scss" scoped>
    .c-table {
        display: table !important;
        &__view-action { color: rgb(175, 175, 175) !important; }
        &__content-row {
            border-color: 1px solid red;
            &:hover {
                .c-table__view-action { color: #333 !important; }
            }
        }
        // .c-table__view-action-button--edit, .c-table__view-action-button--delete {
        //     transition: 0.1s ease-in;
        // }
        .c-table__view-action-button--edit, .c-table__view-action-button--delete {
            &:hover {
                // margin-bottom: 2px;
                // box-shadow: 0px 1px 4px rgba($color: #000000, $alpha: 0.2);
                transition: 0.1s ease-in;
                // .a-icon { color: rgb(235, 137, 10) !important }
            }
        }
        .c-table__view-action-button--delete {
            &:hover {
                .a-icon { color: rgb(245, 43, 43) !important }
            }
        }
        &__cell {
            display: table-cell !important;
            vertical-align: middle !important;
            // white-space: nowrap !important;
            &:first-child {
                border-radius: 4px 0px 0px 4px;
            }
            &:last-child {
                border-radius: 0px 4px 4px 0px;
            }
        }
    }
</style>

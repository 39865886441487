const AppSuccess = {
    field_types_all: [
        'string',
        'email',
        'phone',
        'textarea',
        'url',
        'int',
        'double',
        'currency',
        'percent',
        'date',
        'datetime',
        'boolean',
        'picklist'
    ],
    type_filters: [
        {
            type: 'string',
            filters: [
                { name: 'equals', label: 'Equals', allow_input: true, allow_alt_input: false },
                { name: 'not_equals', label: 'Not Equals', allow_input: true, allow_alt_input: false },
                { name: 'contains', label: 'Contains', allow_input: true, allow_alt_input: false },
                { name: 'not_contains', label: 'Does Not Contain', allow_input: true, allow_alt_input: false },
                { name: 'starts_with', label: 'Starts With', allow_input: true, allow_alt_input: false },
                { name: 'ends_with', label: 'Ends With', allow_input: true, allow_alt_input: false },
                { name: 'null', label: 'Empty', allow_input: false, allow_alt_input: false },
                { name: 'not_null', label: 'Not Empty', allow_input: false, allow_alt_input: false },
            ]
        },
        {
            type: 'email',
            filters: [
                { name: 'equals', label: 'Equals', allow_input: true, allow_alt_input: false },
                { name: 'not_equals', label: 'Not Equals', allow_input: true, allow_alt_input: false },
                { name: 'contains', label: 'Contains', allow_input: true, allow_alt_input: false },
                { name: 'not_contains', label: 'Does Not Contain', allow_input: true, allow_alt_input: false },
                { name: 'starts_with', label: 'Starts With', allow_input: true, allow_alt_input: false },
                { name: 'ends_with', label: 'Ends With', allow_input: true, allow_alt_input: false },
                { name: 'null', label: 'Empty', allow_input: false, allow_alt_input: false },
                { name: 'not_null', label: 'Not Empty', allow_input: false, allow_alt_input: false },
            ]
        },
        {
            type: 'phone',
            filters: [
                { name: 'equals', label: 'Equals', allow_input: true, allow_alt_input: false },
                { name: 'not_equals', label: 'Not Equals', allow_input: true, allow_alt_input: false },
                { name: 'contains', label: 'Contains', allow_input: true, allow_alt_input: false },
                { name: 'not_contains', label: 'Does Not Contain', allow_input: true, allow_alt_input: false },
                { name: 'starts_with', label: 'Starts With', allow_input: true, allow_alt_input: false },
                { name: 'ends_with', label: 'Ends With', allow_input: true, allow_alt_input: false },
                { name: 'null', label: 'Empty', allow_input: false, allow_alt_input: false },
                { name: 'not_null', label: 'Not Empty', allow_input: false, allow_alt_input: false },
            ]
        },
        {
            type: 'textarea',
            filters: [
                { name: 'equals', label: 'Equals', allow_input: true, allow_alt_input: false },
                { name: 'not_equals', label: 'Not Equals', allow_input: true, allow_alt_input: false },
                { name: 'contains', label: 'Contains', allow_input: true, allow_alt_input: false },
                { name: 'not_contains', label: 'Does Not Contain', allow_input: true, allow_alt_input: false },
                { name: 'starts_with', label: 'Starts With', allow_input: true, allow_alt_input: false },
                { name: 'ends_with', label: 'Ends With', allow_input: true, allow_alt_input: false },
                { name: 'null', label: 'Empty', allow_input: false, allow_alt_input: false },
                { name: 'not_null', label: 'Not Empty', allow_input: false, allow_alt_input: false },
            ]
        },
        {
            type: 'url',
            filters: [
                { name: 'equals', label: 'Equals', allow_input: true, allow_alt_input: false },
                { name: 'not_equals', label: 'Not Equals', allow_input: true, allow_alt_input: false },
                { name: 'contains', label: 'Contains', allow_input: true, allow_alt_input: false },
                { name: 'not_contains', label: 'Does Not Contain', allow_input: true, allow_alt_input: false },
                { name: 'starts_with', label: 'Starts With', allow_input: true, allow_alt_input: false },
                { name: 'ends_with', label: 'Ends With', allow_input: true, allow_alt_input: false },
                { name: 'null', label: 'Empty', allow_input: false, allow_alt_input: false },
                { name: 'not_null', label: 'Not Empty', allow_input: false, allow_alt_input: false },
            ]
        },
        {
            type: 'int',
            filters: [
                { name: 'equals', label: 'Equals', allow_input: true, allow_alt_input: false },
                { name: 'not_equals', label: 'Not Equals', allow_input: true, allow_alt_input: false },
                { name: 'greater_than', label: 'Greater Than', allow_input: true, allow_alt_input: false },
                { name: 'greater_than_equals', label: 'Equal or Greater Than', allow_input: true, allow_alt_input: false },
                { name: 'lesser_than', label: 'Lesser Than', allow_input: true, allow_alt_input: false },
                { name: 'lesser_than_equals', label: 'Equal or Lesser Than', allow_input: true, allow_alt_input: false },
                { name: 'between', label: 'Between', allow_input: true, allow_alt_input: true },
            ],
        },
        {
            type: 'double',
            filters: [
                { name: 'equals', label: 'Equals', allow_input: true, allow_alt_input: false },
                { name: 'not_equals', label: 'Not Equals', allow_input: true, allow_alt_input: false },
                { name: 'greater_than', label: 'Greater Than', allow_input: true, allow_alt_input: false },
                { name: 'greater_than_equals', label: 'Equal or Greater Than', allow_input: true, allow_alt_input: false },
                { name: 'lesser_than', label: 'Lesser Than', allow_input: true, allow_alt_input: false },
                { name: 'lesser_than_equals', label: 'Equal or Lesser Than', allow_input: true, allow_alt_input: false },
                { name: 'between', label: 'Between', allow_input: true, allow_alt_input: true },
            ],
        },
        {
            type: 'currency',
            filters: [
                { name: 'equals', label: 'Equals', allow_input: true, allow_alt_input: false },
                { name: 'not_equals', label: 'Not Equals', allow_input: true, allow_alt_input: false },
                { name: 'greater_than', label: 'Greater Than', allow_input: true, allow_alt_input: false },
                { name: 'greater_than_equals', label: 'Equal or Greater Than', allow_input: true, allow_alt_input: false },
                { name: 'lesser_than', label: 'Lesser Than', allow_input: true, allow_alt_input: false },
                { name: 'lesser_than_equals', label: 'Equal or Lesser Than', allow_input: true, allow_alt_input: false },
                { name: 'between', label: 'Between', allow_input: true, allow_alt_input: true },
            ],
        },
        {
            type: 'percent',
            filters: [
                { name: 'equals', label: 'Equals', allow_input: true, allow_alt_input: false },
                { name: 'not_equals', label: 'Not Equals', allow_input: true, allow_alt_input: false },
                { name: 'greater_than', label: 'Greater Than', allow_input: true, allow_alt_input: false },
                { name: 'greater_than_equals', label: 'Equal or Greater Than', allow_input: true, allow_alt_input: false },
                { name: 'lesser_than', label: 'Lesser Than', allow_input: true, allow_alt_input: false },
                { name: 'lesser_than_equals', label: 'Equal or Lesser Than', allow_input: true, allow_alt_input: false },
                { name: 'between', label: 'Between', allow_input: true, allow_alt_input: true },
            ],
        },
        {
            type: 'date',
            filters: [
                { name: 'equals', label: 'On', allow_input: true, allow_alt_input: false },
                { name: 'not_equals', label: 'Not On', allow_input: true, allow_alt_input: false },
                { name: 'greater_than', label: 'Later Than', allow_input: true, allow_alt_input: false },
                { name: 'greater_than_equals', label: 'On or Later Than', allow_input: true, allow_alt_input: false },
                { name: 'lesser_than', label: 'Sooner Than', allow_input: true, allow_alt_input: false },
                { name: 'lesser_than_equals', label: 'On or Sooner Than', allow_input: true, allow_alt_input: false },
                { name: 'between', label: 'Between', allow_input: true, allow_alt_input: true },
            ],
        },
        {
            type: 'datetime',
            filters: [
                { name: 'equals', label: 'On', allow_input: true, allow_alt_input: false },
                { name: 'not_equals', label: 'Not On', allow_input: true, allow_alt_input: false },
                { name: 'greater_than', label: 'Later Than', allow_input: true, allow_alt_input: false },
                { name: 'greater_than_equals', label: 'On or Later Than', allow_input: true, allow_alt_input: false },
                { name: 'lesser_than', label: 'Sooner Than', allow_input: true, allow_alt_input: false },
                { name: 'lesser_than_equals', label: 'On or Sooner Than', allow_input: true, allow_alt_input: false },
                { name: 'between', label: 'Between', allow_input: true, allow_alt_input: true },
            ],
        },
        {
            type: 'boolean',
            filters: [
                { name: 'equals', label: 'Is', allow_input: true, allow_alt_input: false },
            ],
        },
        {
            type: 'picklist',
            filters: [
                { name: 'equals', label: 'Is', allow_input: true, allow_alt_input: false },
                { name: 'not_equals', label: 'Is Not', allow_input: true, allow_alt_input: false },
            ],
        },
    ],
    get_field_types_all: function () {
        return this.field_types_all;
    },
    get_filters_by_type: function (type) {
        let filter_object = _.find(this.type_filters, { type: type })
        return (filter_object === undefined) ? [] : filter_object.filters
    },
    get_filters_item_by_field: function (type, filter_name, field) {
        let response_object = {}
        let filter_object = _.find(this.type_filters, { type: type })
        if (filter_object !== undefined && filter_object.filters && filter_object.filters.length > 0) {
            let filter_item = _.find(filter_object.filters, { name: filter_name })
            response_object = filter_item
        }
        return _.get(response_object, field)
    },
}

export default AppSuccess

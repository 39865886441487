<template>
    <div>
        <!-- Show this section if there is update permission -->
        <template v-if="canUpdate">
            <template v-if="list && list.length > 2">
                <a-menu v-model="menuState" offset-y right transition="slide-y-transition" :nudge-top="showMenu ? 0 : 6" open-on-hover min-width="200" :disabled="!canUpdate || !isProjectActive">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" class="u-flex align-center">
                            <template v-if="status">
                                <g-task-meter :value="status.percentage" :color="status.color" class="u-relative" style="z-index: 1;">
                                    <a-icon v-if="status.status === 'Completed' && status.percentage === 100" size="14" :color="$color(status.color, 'color_text')">done</a-icon>
                                </g-task-meter>

                                <!-- Show status value if "showMenu" is set to true else just show the progress circular -->
                                <a-sheet v-if="showMenu" height="26" min-width="180" max-width="180" class="u-flex px-2 py-1 pl-9 ml-n7 u-relative align-center u-rounded-corners-full" :class="[{'u-border': !dataIsComplete}]" :color="dataIsComplete ? $color(status.color, 'color_bg') : 'grey lighten-4'">
                                    <a-sheet width="26" height="26" class="u-rounded-corners-full black u-absolute grey lighten-3" style="left: 2px;"></a-sheet>
                                    <span class="text-truncate md-body-2" :class="{ 'grey--text text--darken-2': !dataIsComplete }" :style="{ color: dataIsComplete ? $color(status.color, 'color_text') : '' }">{{ status.value | truncateText(15) }}</span>
                                    <a-spacer></a-spacer>
                                    <a-icon v-if="canUpdate && isProjectActive" size="14" :color="dataIsComplete ? $color(status.color, 'color_text') : 'grey darken-1'">keyboard_arrow_{{ menuState ? 'up' : 'down' }}</a-icon>
                                </a-sheet>
                            </template>
                        </div>
                    </template>
                    <a-sheet>
                        <a-list class="u-list-condensed u-overflow-y" style="max-height: 360px;">
                            <template v-for="(metaType, index) in dataFilteredMeta">
                                <a-divider class="my-2" v-if="index !== 0" :key="'divider-'+metaType.type"></a-divider>
                                <span :key="'title-'+metaType.type" class="md-caption font-weight-medium mx-5 grey--text text--darken-1 text-uppercase" style="letter-spacing: 0.2px;">{{ metaType.type }}</span>
                                <a-list-item v-for="meta in metaType.list" :key="meta.id" @click="methodUpdate(meta)" :class="{ 'grey lighten-3': (status && status.id === meta.id) }">
                                    <a-list-item-content>
                                        <div class="u-flex align-center">
                                            <div class="u-flex align-center mr-4">
                                                <a-icon size="18" class="u-icon-nudge mr-1" style="top: 1px;" :color="$color(meta.color, 'color_bg')">fiber_manual_record</a-icon>
                                                <span class="md-body-2 grey--text text--darken-1">{{ meta.value }}</span>
                                            </div>
                                            <a-spacer></a-spacer>
                                            <span class="md-body-2 grey--text text--darken-1">{{ meta.percentage }}%</span>
                                        </div>
                                    </a-list-item-content>
                                </a-list-item>
                            </template>
                        </a-list>
                    </a-sheet>
                </a-menu>
            </template>
            <template v-else>
                <!-- Toggle values if the list contains only 2 meta -->
                <div v-if="status" class="u-flex align-center">
                    <g-task-meter :value="status.percentage" :color="status.color" @click="methodDirectUpdate(status.id)">
                        <a-icon v-if="status.status === 'Completed' && status.percentage === 100" size="14" :color="$color(status.color, 'color_text')">done</a-icon>
                    </g-task-meter>
                    <a-sheet v-if="showMenu" height="26" min-width="160" max-width="180" class="u-flex px-2 py-1 pl-9 ml-n7 align-center u-rounded-corners-full" :class="[{'u-border': !dataIsComplete}]" :color="dataIsComplete ? $color(status.color, 'color_bg') : 'grey lighten-4'">
                        <span class="text-truncate md-body-2" :class="{ 'grey--text text--darken-2': !dataIsComplete }" :style="{ color: dataIsComplete ? $color(status.color, 'color_text') : '' }">{{ status.value | truncateText(15) }}</span>
                        <a-spacer></a-spacer>
                        <a-icon size="14" :color="dataIsComplete ? $color(status.color, 'color_text') : 'grey darken-1'">keyboard_arrow_{{ menuState ? 'up' : 'down' }}</a-icon>
                    </a-sheet>
                </div>
            </template>
        </template>

        <!-- Show status in tooltip if there is no permission -->
        <template v-if="!canUpdate">
            <a-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <div v-on="on" class="u-flex align-center">
                        <template v-if="status">
                            <g-task-meter :value="status.percentage" :color="status.color" class="u-relative" style="z-index: 1;">
                                <a-icon v-if="status.status === 'Completed' && status.percentage === 100" size="14" :color="$color(status.color, 'color_text')">done</a-icon>
                            </g-task-meter>
                            <a-sheet v-if="showMenu" height="26" min-width="180" max-width="180" class="u-flex px-2 py-1 pl-9 ml-n7 u-relative align-center u-rounded-corners-full" :class="[{'u-border': !dataIsComplete}]" :color="dataIsComplete ? $color(status.color, 'color_bg') : 'grey lighten-4'">
                                <a-sheet width="26" height="26" class="u-rounded-corners-full black u-absolute grey lighten-3" style="left: 2px;"></a-sheet>
                                <span class="text-truncate md-body-2" :class="{ 'grey--text text--darken-2': !dataIsComplete }" :style="{ color: dataIsComplete ? $color(status.color, 'color_text') : '' }">{{ status.value | truncateText(15) }}</span>
                            </a-sheet>
                        </template>
                    </div>
                </template>
                <div class="u-flex align-center" v-if="status">
                    <a-icon size="18" class="u-icon-nudge mr-1" style="top: 1px;" :color="methodGetColor(status.color)">fiber_manual_record</a-icon>
                    <span class="md-body-2 grey--text text--lighten-4">{{ status.percentage }}%</span>
                </div>
            </a-tooltip>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            required: true
        },
        status: {
            type: Object
        },
        item: {
            type: Object
        },
        showMenu: {
            type: Boolean,
            default: false
        },
        isProjectActive: {
            type: Boolean,
            default: false
        },
        canUpdate: {
            type: Boolean
        },
    },

    data () {
        return {
            menuState: false
        }
    },

    computed: {
        dataIsComplete () {
            return this.status && this.status.status === 'Completed'
        },

        dataFilteredMeta () {
            const types = ['Unstarted', 'Started', 'Completed']
            const result = []
            types.forEach(item => {
                result.push({ type: item, list: this.list.filter(({ status }) => status === item) })
            })
            return result
        },
    },

    methods: {
        methodUpdate (meta) {
            if (this.status.id === meta.id) return
            this.$emit('update-meta', this.item, meta)
            this.methodItemUpdate(meta)
        },

        methodDirectUpdate (id) {
            const meta = this.list.find(item => item.id !== id)
            this.$emit('update-meta', this.item, meta)
            this.methodItemUpdate(meta)
        },

        methodItemUpdate (meta) {
            this.item.status_id = meta.id
            this.item.status = meta
            this.$emit('update-item', this.item, meta)
        },

        methodGetColor (color) {
            const { name, color_bg } = this.$color(color)
            return name === 'black' ? 'grey darken-1' : color_bg
        }
    }
}
</script>

<style>

</style>

import Vue from 'vue'
import store from '../models'
import { get } from 'lodash'

let can = null
let plan = null
let user = null
let organization = null
let organizationPlan = null
let validRoutes = []
let preference = () => {}

export const ValidRoutes = () => {
   setupValidationFns()

    return {
        get: () => validRoutes,
        configure: () => fetchRoutes()
    }
}

const fetchRoutes = () => {
    validRoutes = [
        ...dashboardRoutes(),
        ...projectListRoute(),
        ...tasksRoutes(),
        ...notesRoutes(),
        ...ticketsRoutes(),
        ...meetingsRoutes(),
        ...inventoryRoutes(),
        ...customerRoutes(),
        ...partnerRoutes(),
        ...appstoreRoutes(),
        ...projectReportRoutes(),
        ...usecaseReportRoutes(),
        ...resourcePlanningRoutes(),
        ...insightsRoutes(),
        ...profileRoutes(),
        ...teamsRoutes(),
        ...settingsRoutes(),
        ...supportRoutes(),
        ...ssoSettingsRoute(),
        ...rolesSettingsRoutes(),
        ...workspaceSettingsRoutes(),
        ...organizationSetitngsRoute(),
        ...confidenceScoreSettingsRoute(),
        ...validationSettingsRoute(),
        ...automationSettingsRoute(),
        ...preferenceSettingsRoute(),
        ...notificationPreferenceSettingsRoute(),
        ...documentGeneratorSettingsRoute(),
        ...templateSettingsRoute(),
        ...tagSettingsRoute(),
        ...pickListSettingsRoute(),
        ...metaInventorySettingsRoute(),
        ...competitorSettingsRoute(),
        ...territorySettingsRoute(),
        ...customFieldSettingsRoute(),
        ...layoutDesignerSettingsRoute(),
        ...billingSettingsRoute(),
        ...tokenSettingsRoute(),
        ...authenticationLogSettingsRoute()
    ]
}

const setupValidationFns = (args) => {
    preference = (key) => store.getters['Preference/get_property_by_key'](key, 'value')
    organization = get(store.state.User.self, 'organization')
    organizationPlan = get(store.state.Organization, 'plan')
    user = store.state.User.self
    can = Vue.prototype.$can
    plan = Vue.prototype.$plan
}

const dashboardRoutes = () => {
    if (!can('projects.index')) return []

    const icon = 'home'
    return [
        objConstructor('dashboard-columns', { icon: 'home' }),
        objConstructor('playground', { icon })
    ]
}

const projectListRoute = () => {
    if (!can('projects.index')) return []

    return [objConstructor('projects', { icon: 'folder_open' })]
}

const tasksRoutes = () => {
    if (!can('tasks.index')) return []

    return [objConstructor('tasks', { icon: 'check_circle' })]
}

const notesRoutes = () => {
    if (!can('notes.index')) return []

    return [objConstructor('notes', { icon: 'notes' })]
}

const ticketsRoutes = () => {
    if (!can('tickets.index')) return []

    return [objConstructor('tickets', { icon: 'account_tree' })]
}

const meetingsRoutes = () => {
    if (!can('meetings.index') || !plan('meetings:full-access') || !hasMeetingFlag()) return []

    return [objConstructor('meetings', { icon: 'video_call' })]
}

const inventoryRoutes = () => {
    if (
        !can('inventories.index') ||
        !plan('inventory:full-access') ||
        !hasInventoryFlag() ||
        !hasInventoryPlanAccess() ||
        preference('organization.inventory_view') !== 'Yes'
    ) return []

    return [objConstructor('inventory', { icon: 'memory' })]
}

const customerRoutes = () => {
    if (!can('customers.index')) return []

    return [objConstructor('customers', { icon: 'account_box' })]
}

const partnerRoutes = () => {
    if (!can('partners.index')) return []

    return [objConstructor('partners', { icon: 'contacts' })]
}

const appstoreRoutes = () => {
    if (!can('applets.index')) return []

    return [objConstructor('appstore', { icon: 'apps' })]
}

const projectReportRoutes = () => {
    if (!can('reports.index')) return []

    return [objConstructor('reports', { icon: 'assignment' })]
}

const usecaseReportRoutes = () => {
    if (!can('reports.index') || !can('usecases.index')) return []

    return [objConstructor('reports-usecases', { icon: 'view_list' })]
}

const resourcePlanningRoutes = () => {
    if (!can('reports.index')) return []

    return [objConstructor('resource-planning', { icon: 'perm_contact_calendar' })]
}

const insightsRoutes = () => {
    if (!can('reports.index')) return []

    return [objConstructor('insights', { icon: 'assessment' })]
}

const profileRoutes = () => {
    return [objConstructor('settings-profile', { icon: 'person' })]
}

const teamsRoutes = () => {
    if (!get(user, 'is_admin')) return []

    return [objConstructor('teams-enabled', { icon: 'people_alt' })]
}

const settingsRoutes = () => {
    return [objConstructor('settings', { icon: 'settings' })]
}

const supportRoutes = () => {
    return [objConstructor('support', { icon: 'help_outline' })]
}

// Settings routes
const ssoSettingsRoute = () => {
    if (!get(user, 'is_admin')) return []

    return [objConstructor('settings-sso', {
        icon: 'vpn_key',
        iconOutlined: false
    })]
}

const rolesSettingsRoutes = () => {
    if (!get(user, 'is_admin')) return []

    return [objConstructor('settings-roles', {
        icon: 'verified_user',
        iconOutlined: false
    })]
}

const workspaceSettingsRoutes = () => {
    if (!can('workspaces.index')) return []

    return [objConstructor('settings-workspace', {
        icon: 'apartment',
        iconOutlined: false
    })]
}

const organizationSetitngsRoute = () => {
    if (!get(user, 'is_admin')) return []

    return [objConstructor('settings-organization', {
        icon: 'supervised_user_circle',
        iconOutlined: false
    })]
}

const confidenceScoreSettingsRoute = () => {
    if (
        !can('confidence_score.index') ||
        !hasConfidenceScoreFlag() ||
        preference('organization.confidence_score') !== 'Yes'
    ) return []

    return [objConstructor('settings-confidence-score', {
        icon: 'scoreboard',
        iconOutlined: false
    })]
}

const validationSettingsRoute = () => {
    if (!hasAutomationFlag() || !get(user, 'is_admin')) return []

    return [objConstructor('settings-validations', {
        icon: 'fact_check',
        iconOutlined: false
    })]
}

const automationSettingsRoute = () => {
    if (!hasAutomationFlag() || !get(user, 'is_admin')) return []

    return [objConstructor('settings-automations', { icon: '$automation' })]
}

const preferenceSettingsRoute = () => {
    if (!get(user, 'is_admin')) return []

    return [objConstructor('settings-preferences', {
        icon: 'touch_app',
        iconOutlined: false
    })]
}

const notificationPreferenceSettingsRoute = () => {
    if (!get(user, 'is_admin')) return []

    return [objConstructor('settings-notification-preferences', { icon: 'notifications_active' })]
}

const documentGeneratorSettingsRoute = () => {
    if (!can('document_generator.index')) return []

    return [objConstructor('settings-document-generator', {
        icon: 'source',
        iconOutlined: false
    })]
}

const templateSettingsRoute = () => {
    if (!can('templates.show')) return []

    return [objConstructor('master-templates-system-list-usecases', {
        icon: 'library_books',
        iconOutlined: false
    })]
}

const tagSettingsRoute = () => {
    if (get(user, 'scope') !== 'internal') return []

    return [objConstructor('settings-tags-list', {
        params: { id: 'label' },
        icon: 'label',
        iconOutlined: false,
    })]
}

const pickListSettingsRoute = () => {
    if (!can('metas.index')) return []

    return [objConstructor('settings-metas', { icon: 'list_alt' })]
}

const metaInventorySettingsRoute = () => {
    if (get(organizationPlan, 'subscription_plan.level') === 1) return []

    if (
        get(organizationPlan, 'subscription_plan.level') === 2 &&
        (!hasInventoryFlag() || preference('organization.inventory_view') !== 'Yes')
    ) return []

    if (
        get(organizationPlan, 'subscription_plan.level') === 3 &&
        !hasInventoryFlag()
    ) return []

    return [objConstructor('settings-metas-inventory-categories', { icon: 'memory' })]
}

const competitorSettingsRoute = () => {
    if (get(user, 'scope') !== 'internal') return []

    return [objConstructor('settings-competitors', {
        icon: 'domain',
        iconOutlined: false
    })]
}

const territorySettingsRoute = () => {
    if (get(user, 'scope') !== 'internal') return []

    return [objConstructor('territories', { icon: 'terrain' })]
}

const customFieldSettingsRoute = () => {
    if (get(user, 'scope') !== 'internal') return []

    return [objConstructor('settings-custom-fields-list',
        { params: { id: 'project' },
        icon: 'ballot',
        iconOutlined: false
    })]
}

const layoutDesignerSettingsRoute = () => {
    if (get(user, 'scope') !== 'internal' || !can('layout_designer.index')) return []

    return [objConstructor('layout-designer', {
        icon: 'space_dashboard',
        iconOutlined: false
    })]
}

const billingSettingsRoute = () => {
    if (get(user, 'is_billing') !== 1) return []

    return [objConstructor('settings-billing', {
        icon: 'receipt',
        iconOutlined: false
    })]
}

const tokenSettingsRoute = () => {
    return [objConstructor('settings-tokens', {
        icon: 'code',
        iconOutlined: false
    })]
}

const authenticationLogSettingsRoute = () => {
    if (get(user, 'is_admin') !== 1) return []

    return [objConstructor('settings-authentication-log', {
        icon: 'summarize',
        iconOutlined: false
    })]
}

// Meta
const objConstructor = (name, args) => {
    const { params = {}, icon = null, iconOutlined = true } = args || {}
    return { name, params, icon, iconOutlined, valid: true }
}

export const hasMeetingFlag = () => {
    if (!organization || (organization && !organization.id)) {
        setupValidationFns()
    }

    return get(organization, 'flag_meetings') === 1
}

export const hasInventoryFlag = () => {
    if (!organization || (organization && !organization.id)) {
        setupValidationFns()
    }

    return get(organization, 'flag_inventory') === 1
}

export const hasConfidenceScoreFlag = () => {
    if (!organization || (organization && !organization.id)) {
        setupValidationFns()
    }

    return get(organization, 'flag_confidence_score') === 1
}

export const hasAutomationFlag = () => {
    if (!organization || (organization && !organization.id)) {
        setupValidationFns()
    }

    return get(organization, 'flag_automation') === 1
}

export const hasInventoryPlanAccess = () => {
    if (!organizationPlan || (organizationPlan && !organizationPlan.id)) {
        setupValidationFns()
    }

    return get(organizationPlan, 'subscription_plan.level') === 3
}

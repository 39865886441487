const CheckPresentationView = {
    install(Vue, { store }) {
        Vue.prototype.$isConfidentialMode = () => store.state.ProjectView.is_friendly_view || false

        Vue.prototype.$presentationCheck = (params) => {
            const { slug, key = 'organization.project.confidential_mode' } = params || {}
            const isPresentationView = store.state.ProjectView.is_friendly_view
            if (!isPresentationView) return true

            const prefList = store.state.Preference.list
            if (!_.size(prefList)) return

            const { values } = prefList.find(i => i.key === key) || {}
            if (!values) return

            const { value } = values.find(i => i.slug === slug) || {}
            if (!value) return

            return isPresentationView && value === 'Yes'
        }
    }
}

export default CheckPresentationView

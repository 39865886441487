<template>
    <div>
        <div v-for="(task, taskIndex) in localGetTasks" :key="task.id">
            <slot name="item" :item="task" :itemIndex="taskIndex"></slot>
            <a-divider v-if="tasks && tasks.length && taskIndex !== tasks.length - 1"></a-divider>
        </div>
        <SComponentIsVisible @visible="localIsVisible(header)" />
    </div>
</template>

<script>
import { SComponentIsVisible } from '@/config/config-shared-components'

export default {
    components: { SComponentIsVisible },

    props: {
        tasks: {
            type: Array
        },
        header: {
            type: String
        },
        headerIndex: {
            type: Number
        },
        groupBy: {
            type: String
        },
        tasksRange: {
            type: Object
        },
    },

    data () {
        return {
            tasksRangeIndices: _.cloneDeep(this.tasksRange)
        }
    },

    computed: {
        localGetTasks () {
            if (!this.tasksRangeIndices[this.header] || (this.headerIndex === 0) && this.groupBy !== 'ungrouped') return this.tasks

            const { endIndex } = this.tasksRangeIndices[this.header]
            const totalTasks = _.cloneDeep(this.tasks)
            const result = [...totalTasks.slice(0, endIndex)]
            return result
        },
    },

    methods: {
        localIsVisible (key) {
            if (!this.tasksRangeIndices[key]) return

            const { startIndex, endIndex } = this.tasksRangeIndices[key]
            this.tasksRangeIndices[key].startIndex = startIndex + (this.groupBy === 'ungrouped' ? 5 : 5)
            this.tasksRangeIndices[key].endIndex = endIndex + (this.groupBy === 'ungrouped' ? 5 : 5)
        },
    }
}
</script>

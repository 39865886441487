<template>
    <a-main v-bind="$attrs">
        <a-container container--fluid grid-list-xl :class="parentClass">
            <slot></slot>
        </a-container>
    </a-main>
</template>

<script>
export default {
    props: {
        parentClass: {
            type: String,
            default: 'pa-10 mb-16'
        }
    }
}
</script>

<template>
    <a-layout align-start>
        <a-flex>
            <a-tabs class="mb-0" background-color="transparent" slider-color="primary">
                <a-tab ripple class="mx-2" :to="{name: 'settings-metas-testcases'}">
                    <span class="md-body-2">Test Case Statuses</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-metas-goals'}">
                    <span class="md-body-2">Success Criteria Statuses</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-metas-tasks'}">
                    <span class="md-body-2">Task Statuses</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-metas-notes'}">
                    <span class="md-body-2">Note Types</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-metas-comments'}">
                    <span class="md-body-2">Comment Types</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-metas-results'}">
                    <span class="md-body-2">Project Results</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-metas-collaborators'}">
                    <span class="md-body-2">Collaborator Types</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-metas-ticket-type'}">
                    <span class="md-body-2">Ticket Types</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-metas-ticket-status'}">
                    <span class="md-body-2">Ticket Statuses</span>
                </a-tab>
                <a-tab ripple class="mx-2" :to="{name: 'settings-metas-ticket-priority'}">
                    <span class="md-body-2">Ticket Priority</span>
                </a-tab>
            </a-tabs>
            <a-divider class="mx-2 grey lighten-2"></a-divider>
        </a-flex>
    </a-layout>
</template>

<script>
export default {

}
</script>

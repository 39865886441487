<template>
    <a-main class="mt-4 mb-16">
        <a-btn small text color="white" class="pa-4 mx-0 blue text--darken-2 mb-6" v-if="$can('templates.store')" @click="local_open_import_dialog()">
            <span class="md-body-2">
                <a-icon size="18" left class="u-icon-nudge mr-1" style="top: -2px;">cloud_download</a-icon> Import Document Template
            </span>
        </a-btn>
         <a-responsive class="py-4 my-4" v-if="local_template_master_item && local_template_master_item.master_template_documents && !local_template_master_item.master_template_documents.length && !local_loading">
            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
            <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
        </a-responsive>
        <template v-if="local_loading">
            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1">Loading..</h3>
        </template>
        <template v-if="local_template_master_item && local_template_master_item.master_template_documents && local_template_master_item.master_template_documents.length && !local_loading">
            <draggable v-model="local_template_master_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                <div class="my-2 mt-0" :class="{ 'js-draggable-file-list-item': $can('templates.update') }" v-for="(master_template, index) in local_template_master_item.master_template_documents" :key="index">
                    <a-container container--fluid grid-list-xl  class="pa-4 px-6 u-elevation-custom-1 white u-cursor-pointer" :class="[template_group_expanded === index ? '' : 'u-rounded-corners']">
                        <a-layout wrap align-center>
                            <a-flex shrink class="u-cursor-pointer pr-0">
                                <a-icon class="grey--text text--darken-1 js-drag-handle u-icon-nudge" :class="{'u-opacity-30': !$can('templates.update')}">drag_indicator</a-icon>
                            </a-flex>
                            <a-flex xl9 xs7 @click="local_group_toggle(index)">
                                <h2 class="subtitle-1 u-word-wrap">{{ master_template.name }}</h2>
                                <p class="md-body-2 mb-0 grey--text text--darken-2 mt-2" v-if="template_group_expanded !== index">
                                    <span>{{ master_template.template_documents_count ? master_template.template_documents_count : 0 }} Documents<span class="mx-2"></span></span>
                                </p>
                            </a-flex>
                            <a-spacer></a-spacer>
                            <a-flex shrink pr-2>
                                <a-tooltip bottom offset-overflow>
                                    <template v-slot:activator="{ on }">
                                        <a-btn v-on="on" icon>
                                            <a-icon size="16" class="grey--text text--darken-1">info</a-icon>
                                        </a-btn>
                                    </template>

                                    <div class="py-1">
                                        <p v-if="master_template.created_by" class="text-body-2 grey--text text--lighten-2 mb-0">Created by
                                            <span class="text-subtitle-2 white--text">{{ master_template.created_by.name }}</span>
                                            <span class="ml-2 text-caption">{{ local_date_output_format(master_template.created_at) }}</span>
                                        </p>

                                        <p v-if="master_template.updated_by && master_template.created_at !== master_template.updated_at" class="text-body-2 grey--text text--lighten-2 mb-0">Last updated by
                                            <span class="text-subtitle-2 white--text">{{ master_template.updated_by.name }}</span>
                                            <span class="text-caption ml-2">{{ local_date_output_format(master_template.updated_at) }}</span>
                                        </p>
                                    </div>
                                </a-tooltip>
                                <a-menu bottom left offset-y max-width="320" v-if="$can('templates.destroy')">
                                    <template v-slot:activator="{ on }">
                                        <v-chip v-on.stop="on" class="ma-2 red lighten-5 red--text text-uppercase" small>
                                            <a-icon size="18" class="red--text" left>link</a-icon>
                                            Unlink
                                        </v-chip>
                                    </template>
                                    <a-sheet class="pa-4">
                                        <h2 class="md-heading-6 mb-2">Are you sure you want to unlink this system template?</h2>
                                        <p class="md-body-2 mb-4">This action will remove all the documents linked from system template.</p>
                                        <a-btn class="ma-0 elevation-0 mr-2 text-uppercase" color="red" dark small :loading="local_unlink_loading" @click="local_associate_destroy(master_template)">
                                            <a-icon size="18" class="mr-1">link_off</a-icon> Unlink
                                        </a-btn>
                                    </a-sheet>
                                </a-menu>
                                <a-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a-btn color="grey darken-2 u-cursor-pointer" v-on="on" text icon small exact target="_blank"  :to="{ name: 'master-templates-system-list-documents', params: { group_id : master_template.id } }">
                                            <a-icon small>open_in_new</a-icon>
                                        </a-btn>
                                    </template>
                                    <span>View System Template</span>
                                </a-tooltip>
                                <a-btn icon text color="grey darken-1" @click="local_group_toggle(index)">
                                    <a-icon v-if="template_group_expanded === index">arrow_drop_up</a-icon>
                                    <a-icon v-else>arrow_drop_down</a-icon>
                                </a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <a-divider class="grey lighten-3" v-if="template_group_expanded === index"></a-divider>
                    <div v-if="template_group_expanded === index" class="grey lighten-5 u-elevation-custom-1">
                        <div class="u-wfull u-shadow grey lighten-5 d-inline-block" v-if="local_document_loading" style="height: 64px;">
                            <div class="text-center grey--text text--darken-1 pa-0 pb-1 mt-5">
                                <a-progress-circular color="orange darken-2" size="17" class="mr-2" width="2" center :indeterminate="true"></a-progress-circular>Fetching documents...
                            </div>
                        </div>
                        <a-responsive class="u-shadow grey lighten-5 py-4" v-if="!local_document_loading && template_document_list.length === 0">
                            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                            <p class="md-body-2 text-center mb-0 grey--text text--darken-1">There is no data to fetch right now.</p>
                        </a-responsive>
                        <template v-for="(template_document, index) in template_document_list">
                            <a-card class="u-relative transparent" :key="template_document.id" flat>
                                <div v-if="template_document.visibility === 'external'" class="c-visibility-bar"></div>
                                <a-divider v-if="index !== 0" class="grey lighten-3" :key="template_document.id + '-divider'"></a-divider>
                                <a-card-text class="pa-0">
                                    <a-container container--fluid grid-list-xl class="py-4 px-6">
                                        <a-layout align-center>
                                            <a-flex shrink class="px-0">
                                                <img src="../../assets/images/filetype-icons/unknown.svg" width="48" alt="Unkown Filetype" style="display: block;" v-if="template_document.extension === null">
                                                <a-img :src="require('../../assets/images/filetype-icons/'+template_document.extension+'.svg')" width="48" :alt="template_document.extension" style="display: block;" v-else></a-img>
                                            </a-flex>
                                            <a-flex xs6 xl7>
                                                <h2 class="md-subtitle-1 primary--text d-block text-no-wrap text-truncate mb-1" style="max-width: 360px; line-height: 1.5 !important;" v-if="template_document.title">{{ template_document.title }} <span class="md-body-2 grey--text text--darken-2">({{ template_document.name }})</span></h2>
                                                <h2 class="md-subtitle-1 primary--text d-block text-no-wrap text-truncate mb-1" style="max-width: 360px; line-height: 1.5 !important;" v-else>{{ template_document.name }}</h2>
                                                <p class="md-body-2 grey--text text--darken-2 mb-0">Size: {{ template_document.size | sizer }}</p>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-card-text>
                            </a-card>
                        </template>
                    </div>
                </div>
            </draggable>
        </template>
        <!-- Import Popup -->
        <a-dialog v-model="dialog_import_template" max-width="1000" persistent>
            <ModalImportSystemTemplates
                :templateList="document_group_import_list"
                :loading="template_group_loading"
                :importLoading="import_loading"
                :importPopup="dialog_import_template"
                :selectedIds="selected_group_ids"
                @import="local_import_template"
                @search="local_search_template"
                @showSelected="local_toggle_selected"
                @close="dialog_import_template = false"
            >
                <template #title>Import Document to Master Template</template>
                <template #subtitle>Search and import document groups from system templates.</template>
                <template #item="{ item, index }">
                    <a-sheet class="u-flex mx-8 pa-4 u-rounded-corners u-shadow u-cursor-pointer" @click="local_select_group_to_import(item.id, index)" :class="{ 'mt-4': index !== 0 }">
                        <a-checkbox :value="local_is_group_selected(item.id)" color="blue darken-2" @click="local_select_group_to_import(item.id, index)" class="mr-2 mt-0 pa-0" v-ripple="false" @change="local_select_group_to_import(item.id, index)" hide-details dense small></a-checkbox>
                        <div>
                            <h2 class="md-subtitle-1 grey--text text--darken-3 mb-2">{{ item.name }}</h2>
                            <p class="md-body-2 mb-0 grey--text text--darken-2">
                               {{ item.template_documents_count  }} {{ item.template_documents_count  === 1 ? 'Document' : 'Documents' }}
                            </p>
                        </div>
                    </a-sheet>
                </template>
            </ModalImportSystemTemplates>
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import ModalImportSystemTemplates from './Modals/ModalImportSystemTemplates.vue'
import { diffHuman } from '../../helpers/helper-date-filter'


export default {
    components: { ModalImportSystemTemplates },
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            snackbar_status: false,
            snackbar_text: '',
            template_group_name: null,
            template_document_url: '',
            template_group_expanded: null,
            dialog_template_document_form: false,
            dialog_template_group_form: false,
            dialog_download_link: false,
            local_file_types: '.jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx,.csv,.ppt,.pptx,.txt,.zip',
            dropzoneOptions: {
                url: '/api/template-documents',
                params: {template_group_id: null},
                withCredentials: true,
                createImageThumbnails: false,
                uploadMultiple: false,
                acceptedFiles: '.jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx,.csv,.ppt,.pptx,.txt,.zip',
                maxFilesize: 100,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-Timezone': moment.tz.guess(true),
                    'X-CSRF-TOKEN': window.axios.defaults.headers.common['X-CSRF-TOKEN'],
                }
            },
            local_template_group_clone_item: {},
            dialog_template_group_clone_form: false,
            local_loading: false,
            loading_file: false,
            uploadDocsList: [],
            current_template_group_id: null,
            loading_item: null,
            loading_action: null,
            local_template_master_item: null,
            document_group_import_list: [],
            dialog_import_template: false,
            selected_group_ids: [],
            template_group_loading: false,
            local_unlink_loading: false,
            import_loading: false,
            local_document_loading: false
        }
    },
    mounted() {
        this.local_index()
    },
    watch: {
        template_group_expanded(newValue, oldValue) {
            if (newValue !== null && this.local_template_master_item && this.local_template_master_item.master_template_documents.length) {
                this.local_list(this.local_template_master_item.master_template_documents[newValue].id)
                // this.dropzoneOptions.params.template_group_id = this.template_group_list[newValue].id
            }
        },
        dialog_template_group_form(newValue, oldValue) {
            if (newValue === false) {
                this.template_group_clear_item();
            }
        },
    },
    computed: {
        local_master_template_id() {
            return this.$route.params.id
        },

        local_template_master_list: {
            get() {
                return this.local_template_master_item.master_template_documents
            },
            set(list) {
                this.local_association_reorder(list)
            }
        },

        template_document_list: {
            get() {
                return this.$store.state.TemplateDocument.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },
        template_document_item: {
            get () {
                return this.$store.state.TemplateDocument.item
            },
            set (item) {
                this.$store.state.TemplateDocument.item = item
            }
        },
        template_group_list: {
            get() {
                return this.$store.state.TemplateGroup.list
            },
            set(list) {
                this.local_group_reorder(list)
                this.template_group_expanded = null
            }
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('TemplateGroup', {
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),
        ...mapState('TemplateDocument', {
            template_document_item: 'item',
            template_document_filters: 'filters',
            template_document_response: 'response',
            template_document_links: 'links',
            template_document_form_mode: 'form_mode',
        }),

        ...mapState('TemplateMaster', {
            template_master_item: 'item',
            template_master_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('templates.show') == false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.local_loading = true
            this.local_template_master_item = null
            await this.local_template_master_show()
            this.local_loading = false
        },

        async local_template_master_show() {
            await this.template_master_show({
                'fields[template_groups]': 'id,name,is_active',
                'fields[master_template_documents]' : 'template_groups.id,template_groups.name,template_groups.updated_at,template_groups.created_at,template_groups.updated_by_id,template_groups.created_by_id',
                'filter[type]': 'master_template',
                'include': 'masterTemplateDocuments,masterTemplateDocuments.templateDocuments,masterTemplateDocuments.created_by,masterTemplateDocuments.updated_by',
                'id': this.local_master_template_id,
            })
            this.local_template_master_item = {...this.template_master_item }
        },

        async local_load_document_template_group() {
            this.template_group_loading = true
            this.document_group_import_list = []
            await this.template_group_index({
                'filter[type]': 'documents',
                'fields[template_groups]': 'id,name,order,type',
                'filter[exclude_linked]' : this.local_master_template_id,
                'include': 'templateDocumentsCount',
                'sort': 'order'
            })
            this.document_group_import_list = this.template_group_list
            this.template_group_loading = false
        },
        async local_document_template_group_show(id) {
            await this.template_group_show({
                'fields[template_groups]': 'id,name,order,type',
                'include': 'templateDocumentsCount',
                'sort': 'order',
                'id': id,
            })
        },
        async local_list(id) {
            this.local_document_loading = true
            await this.template_document_clear()
            await this.template_document_index({
                'filter[template_group_id]': id,
                'fields[template_documents]': 'id,name,title,extension,size,order,template_group_id,visibility',
                'sort': 'order',
            })
            this.local_document_loading = false
        },
        async local_store(id) {
            await this.template_document_clear_item()
            this.template_document_item.url = this.template_document_url
            await this.template_document_store({
                ...this.template_document_item,
                   template_group_id: id,
                   order: -1
            })

            if (this.template_document_response.status === 'success') {
                await this.template_document_clear_item()
                this.template_document_url = ''
            }
        },
        local_initiate(template_group_id, index) {
            this.uploadDocsList = []
            this.current_template_group_id = template_group_id
            this.$refs.doc_upload[0].value = null
            this.$refs.doc_upload[0].click()
            this.template_group_expanded = index
        },
        async local_doc_upload() {
            if (this.$refs.doc_upload[0].files && this.$refs.doc_upload[0].files.length > 0) {
                this.loading_file = true
                this.uploadDocsList = [...this.$refs.doc_upload[0].files]
                for (let index = 0; index < this.$refs.doc_upload[0].files.length; index++) {
                    const item = this.$refs.doc_upload[0].files[index];
                    await this.template_document_store(this.local_upload_action(item, this.current_template_group_id))
                    if (this.template_document_response.status === 'success') {
                        this.uploadDocsList.splice(this.uploadDocsList.findIndex(file => file.name === item.name), 1)
                        await this.local_document_template_group_show(this.current_template_group_id)
                    }
                    this.template_document_clear_item()
                }
                if (!this.uploadDocsList.length) this.loading_file = false
            }
        },
        local_upload_action(item, template_group_id) {
            const form_data = new FormData()
            form_data.append('file', item)
            form_data.append('id', uuidv4())
            form_data.append('template_group_id', template_group_id)
            form_data.append('order', -1)
            return form_data
        },
        async local_edit(id) {
            this.dialog_template_document_form = true
            await this.template_document_select({id: id})
        },
        async local_update() {
            await this.template_document_update(this.template_document_item)
            if (this.template_document_response.status === 'success') {
                this.dialog_template_document_form = false
                await this.template_document_clear_item()
            }
        },
        async local_template_clone_create(group_id) {
            this.local_template_group_clone_item.name = ''
            this.local_template_group_clone_item.template_group_id = group_id
            this.dialog_template_group_clone_form = true
        },
        local_group_clone_cancel() {
            this.dialog_template_group_clone_form = false
            this.local_group_clear_item()
        },
        local_group_clear_item() {
            this.template_document_clear_item()
            this.local_template_group_clone_item.name = null
            this.local_template_group_clone_item.template_group_id = null
        },
        async local_group_clone() {
            await this.template_document_group_clone(this.local_template_group_clone_item)
            if (this.template_document_response.status == 'success') {
                this.local_load_document_template_group()
                this.template_group_expanded = null
                this.dialog_template_group_clone_form = false
                this.local_group_clear_item()
                this.local_show_group_message('Document group duplicated successfully')
            }
        },
        async local_destroy(id) {
            await this.template_document_destroy({id: id})
            this.template_group_list[this.template_group_expanded].template_documents_count = this.template_document_list.length
        },
        async local_visibility_toggle(id, status) {
            if (status == 0) {
                await this.template_document_client_visible({id: id})
                await this.template_document_show({id: id})
            } else {
                await this.template_document_client_invisible({id: id})
                await this.template_document_show({id: id})
            }
        },
        async local_reorder(list) {
            this.template_document_reorder({list: list})
        },
        async local_group_reorder(list) {
            await this.template_group_reorder({list: list})
        },
        async local_download(id) {
            this.dialog_download_link = true
            await this.template_document_download({id: id})
        },
        local_download_save() {
            this.dialog_download_link = false
        },
        async local_group_store() {
            this.template_group_item.name = this.template_group_name;
            this.template_group_item.name = this.template_group_name;
            await this.template_group_store({
                name: this.template_group_item.name,
                type: 'documents',
                model: 'template',
                'order': -1
            })

            if (this.template_group_response.status == 'success') {
                this.template_group_expanded = null
                await this.template_group_clear_item()
                this.dialog_template_group_form = false
                this.template_group_name = ''
            }
        },
         async local_group_update() {
            await this.template_group_update({
                ...this.template_group_item
            })
            if (this.template_group_response.status === 'success') {
                await this.template_group_clear_item()
                this.dialog_template_group_form = false
            }
        },
        async local_change_template_document_group(new_group_id, old_group_id, template_document) {
            this.snackbar_status = false
            template_document.template_group_id = new_group_id

            this.template_document_select_item({ data: template_document })
            await this.template_document_group_move({ ...template_document })
            if (this.template_document_response.status === 'success') {
                await this.local_update_group_meta_counts({ group_id: new_group_id, increase: true })
                await this.local_update_group_meta_counts({ group_id: old_group_id, increase: false })
                this.local_show_group_message('Document moved successfully')
            }
        },
        async local_copy_template_document_group(new_group_id, old_group_id, template_document) {
            this.snackbar_status = false
            template_document.template_group_id = new_group_id
            template_document.old_template_group_id = old_group_id

            await this.template_document_group_copy({
                template_document_id: template_document.id,
                template_group_id: new_group_id,
                old_template_group_id: old_group_id
            })
            if (this.template_document_response.status === 'success') {
                await this.local_update_group_meta_counts({ group_id: new_group_id, increase: true })
                this.local_show_group_message('Document copied successfully')
            }
        },
        local_show_group_message(text) {
            this.snackbar_text = `${ text }`
            this.snackbar_status = !this.snackbar_status
        },
        async local_group_form_cancel() {
            this.dialog_template_group_form = false
            await this.template_group_clear_item()
        },
        async local_template_document_form_cancel() {
            this.dialog_template_document_form = false
            await this.template_document_clear_item()
        },
        local_group_edit(id) {
            this.template_group_select({id: id})
            this.dialog_template_group_form = true
        },
        async local_group_destroy(id) {
            this.template_group_destroy({id: id})
            this.template_group_expanded = null
        },
        local_group_toggle(index) {
            if (this.template_group_expanded === index) {
                this.template_group_expanded = null
            }
            else {
                this.template_group_expanded = index
            }
        },

        async local_visibility_toggle(id, status) {
            await this.local_loading_set(id, 'visibility')
            await this.template_document_select({id: id})
            this.template_document_item.visibility = status === 'external' ? 'internal' : 'external'
            this.template_document_item.state = status === 'external' ? 'internal' : 'external'
            await this.template_document_visibility(this.template_document_item)
            await this.local_loading_clear()
            if (this.template_document_response.status === 'success') {
                await this.template_document_clear_item()
            }
        },
        async local_loading_set(id, action) {
            this.loading_item = id
            this.loading_action = action
        },
        async local_loading_clear() {
            this.loading_item = null
            this.loading_action = null
        },
        local_loading_get(id, action) {
            return this.loading && this.loading_item == id && this.loading_action == action
        },

        async local_update_group_meta_counts ({ group_id, increase = false }) {
            const index = this.template_group_list.findIndex(item => item.id === group_id)
            if (index === -1) return

            const temp_group = this.template_group_list[index]
            temp_group.group_id = group_id
            if(!temp_group.template_documents_count) temp_group.template_documents_count = 0

            increase ? temp_group.template_documents_count++ : temp_group.template_documents_count--
            this.template_change_group_update(temp_group)
        },

        local_open_import_dialog () {
            this.dialog_import_template = true
            this.selected_group_ids = []
            this.local_load_document_template_group()
        },

        local_search_template ({ value, clearCb }) {
            if (!value && (value && !value.trim())) {
                this.document_group_import_list = this.template_group_list
                return clearCb()
            }

            const searctText = value ? value.trim().toLowerCase() : ''
            this.document_group_import_list = this.template_group_list.filter(item => {
                const hasIndex = this.selected_group_ids.indexOf(item.id)
                const hasName = item.name && item.name.toLowerCase().includes(searctText)
                if (!hasName) return null
                if (hasName && (!this.showSelected || (this.showSelected && hasIndex !== -1))) return item
                return null
            })
            clearCb()
        },

        local_toggle_selected ({ value: isSelected, searchText, clearCb }) {
            this.showSelected = isSelected
            if (!isSelected) return this.local_search_template({ value: searchText, clearCb })

            this.document_group_import_list = this.document_group_import_list.filter(item => {
                const index = this.selected_group_ids.indexOf(item.id)
                if (index !== -1) return item
                return null
            })
        },

        local_select_group_to_import (id, index) {
            const idIndex = this.selected_group_ids.findIndex(item => item === id)
            if (idIndex !== -1) this.selected_group_ids.splice(idIndex, 1)
            else this.selected_group_ids.push(id)
        },

        local_is_group_selected (id) {
            return this.selected_group_ids.indexOf(id) !== -1
        },

        async local_import_template () {
            this.import_loading = true
            await this.local_group_import()
            this.close_dialog_import_form()
            this.local_template_master_item = null
            this.local_template_master_show()
            this.import_loading = false
            this.dialog_import_template = false
        },

        close_dialog_import_form() {
            this.dialog_import_template = false
            this.selected_group_ids = []
        },

        async local_group_import() {
            for (const group_id of this.selected_group_ids) {
                await this.association_store({
                    source_type: 'TemplateGroup',
                    source_id: this.local_master_template_id,
                    type: 'master_template',
                    target_type : 'TemplateGroup',
                    target_id : group_id
                })
            }
        },

        async local_associate_destroy (data) {
            this.local_unlink_loading = true
            await this.association_destroy(data.association)
            this.template_group_expanded = null
            this.local_template_master_show()
            this.local_unlink_loading = false
        },

        local_date_output_format (date) {
            return diffHuman(date)
        },

        async local_association_reorder(list) {
            const reorderList = list.map(({ association }) => ({ id: association.id }))
            this.association_reorder({list: reorderList})
            this.local_template_master_item.master_template_documents = [...list ]
        },

        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_show: 'show',
            template_change_group_update: 'change_group_update',
            template_group_store: 'store',
            template_group_update: 'update',
            template_group_reorder: 'reorder',
            template_group_select: 'select',
            template_group_destroy: 'destroy',
            template_group_clear: 'clear',
            template_group_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateDocument', {
            template_document_index: 'index',
            template_document_show: 'show',
            template_document_visibility: 'visibility',
            template_document_store: 'store',
            template_document_group_move: 'group_document_move',
            template_document_group_update: 'group_document_update',
            template_document_group_copy: 'group_copy_store',
            template_document_group_clone: 'group_clone_store',
            template_document_select_item: 'select_item',
            template_document_client_visible: 'client_visible',
            template_document_client_invisible: 'client_invisible',
            template_document_update: 'update',
            template_document_select: 'select',
            template_document_download: 'download',
            template_document_destroy: 'destroy',
            template_document_reorder: 'reorder',
            template_document_clear: 'clear',
            template_document_clear_item: 'clear_item',
        }),

        ...mapActions('TemplateMaster', {
            template_master_show: 'show',
            template_master_clear_item: 'clear_item',
        }),

        ...mapActions('Association', {
            association_store: 'store',
            association_destroy: 'destroy',
            association_reorder: 'reorder',
        }),
    }
}
</script>

<style>
.dropzone-custom-content {
    padding: 2rem;
    border: 2px dashed #eee;
    border-radius: .25rem;
    cursor: pointer;
}
.dz-preview {
    background-color: #fff;
    border-radius: .25rem;

    margin-top: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.06), 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.06) !important;
}
.dz-preview .dz-details {
    padding: 1rem;
}
.dz-preview .dz-details .dz-size,
.dz-preview .dz-details .dz-filename {
    display: inline-block;
    font-size: .825rem;
}
.dz-preview .dz-image,
.dz-preview .dz-success-mark,
.dz-preview .dz-error-mark {
    display: none;
}
.dz-preview .dz-progress .dz-upload {
    display: block;
    background-color: #43a047;
    height: 2px;
}
.dz-preview.dz-success {
    display: none;
}
.dz-preview.dz-error .dz-progress {
    display: none;
}
.dz-preview.dz-error .dz-error-message {
    padding: 1rem;
    padding-top: 0;
    font-size: .825rem;
    color: red;
}
.dz-preview.dz-error .dz-details {
    padding-bottom: 0;
}
</style>

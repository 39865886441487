<template>
    <!-- Post Status -->
    <div class="u-rounded-corners-lg u-border pa-7">
        <!-- Options -->
        <div class="u-flex-center-y">
            <!-- Health -->
            <div>
                <a-menu bottom offset-y nudge-bottom="4" :disabled="loading || disableAll">
                    <template v-slot:activator="{ on, value }">
                        <div v-on="on" class="c-health-dropdown u-rounded-corners" :class="[mixinHasError('health') ? 'c-wiggle-short u-border-error' : 'u-border']">
                            <a-tooltip bottom :disabled="!mixinHasError('health')">
                                <template v-slot:activator="{ on: tooltip }">
                                    <div v-on="tooltip" style="width: 240px" :class="[localStatusColor + ' lighten-5', 'u-border u-flex-center-y px-3 py-2 u-rounded-corners u-cursor-pointer',]">
                                        <a-avatar size="16" :color="localStatusColor !== 'grey' ? localStatusColor + ' lighten-4' : localStatusColor + ' lighten-2'">
                                            <a-icon size="12" :color="localStatusColor + ' darken-2'">fiber_manual_record</a-icon>
                                        </a-avatar>
                                        <span :class="[localStatusColor + '--text text--darken-2', 'md-body-2 ml-2 font-weight-medium']">Health:</span>
                                        <span :class="[localStatusColor + '--text text--darken-2', 'md-body-2 ml-1 text-capitalize']">{{ statusUpdate.health ? statusUpdate.health : 'Select Status' }}</span>
                                        <a-spacer></a-spacer>
                                        <a-icon size="18" :color="localStatusColor + ' darken-2'">keyboard_arrow_{{ value ? 'up' : 'down' }}</a-icon>
                                    </div>
                                </template>
                                <span>{{ mixinGetError('health') }}</span>
                            </a-tooltip>
                        </div>
                    </template>
                    <a-list class="u-list-condensed u-list-condensed--medium">
                        <template v-for="(health, index) in healthList">
                            <a-divider v-if="index !== 0"></a-divider>
                            <a-list-item @click="statusUpdate.health = health.value" :class="{ 'grey lighten-5': statusUpdate.health === health.value }" :key="health.value">
                                <div class="u-flex-center-y">
                                    <a-avatar size="16" :color="health.color + ' lighten-4'">
                                        <a-icon size="12" :color="health.color + ' darken-2'">fiber_manual_record</a-icon>
                                    </a-avatar>
                                    <span :class="[health.color + '--text text--darken-2', 'md-body-2 ml-1']">{{ health.text }}</span>
                                </div>
                            </a-list-item>
                        </template>
                    </a-list>
                </a-menu>
            </div>

            <!-- Type -->
            <div class="ml-4">
                <a-menu bottom offset-y nudge-bottom="4" :disabled="loading || disableAll">
                    <template v-slot:activator="{ on, value }">
                        <div v-on="on" class="u-rounded-corners" :class="[mixinHasError('type_id') ? 'c-wiggle-short u-border-error' : 'u-border']">
                            <a-tooltip bottom :disabled="!mixinHasError('type_id')">
                                <template v-slot:activator="{ on: tooltip }">
                                    <div v-on="tooltip" style="width: 280px" :class="['grey lighten-5 u-border u-flex-center-y px-3 py-2 u-rounded-corners u-cursor-pointer', { 'c-wiggle-short u-border-error': mixinHasError('type_id') }]">
                                        <a-icon size="20" color="grey darken-1">swap_vert</a-icon>
                                        <span :class="['md-body-2 ml-1 font-weight-medium grey--text text--darken-2']">Type:</span>
                                        <span :class="['md-body-2 ml-1 text-capitalize grey--text text--darken-2 d-block text-truncate']">{{ localGetTypeLabel(statusUpdate.type_id) }}</span>
                                        <a-spacer></a-spacer>
                                        <a-icon size="18" color="grey darken-2">keyboard_arrow_{{ value ? 'up' : 'down' }}</a-icon>
                                    </div>
                                </template>
                                <span>{{ mixinGetError('type_id') }}</span>
                            </a-tooltip>
                        </div>
                    </template>
                    <a-list class="u-list-condensed u-list-condensed--medium">
                        <template v-for="(type, index) in typeList">
                            <a-divider v-if="index !== 0"></a-divider>
                            <a-list-item @click="statusUpdate.type_id = type.id" :class="{ 'grey lighten-5': statusUpdate.type_id === type.id }" :key="type.id">
                                <span class="md-body-2 grey--text text--darken-3">{{ type.value }}</span>
                            </a-list-item>
                        </template>
                    </a-list>
                </a-menu>
            </div>
        </div>

        <a-tooltip bottom :disabled="!mixinHasError('description')">
            <template v-slot:activator="{ on }">
                <div v-on="on">
                    <a-sheet :class="[{ 'c-wiggle-short u-border-error u-rounded-corners-lg': mixinHasError('description') }]" class="c-plain-textarea u-overflow-hidden u-overflow-y c-tiny-scroll my-5" :max-height="editorHeight">
                        <a-textarea
                            v-on="on"
                            v-model="statusUpdate.description"
                            placeholder="Enter a new status update..."
                            rows="2"
                            row-height="24"
                            class="ma-0 px-0"
                            style="line-height: 64"
                            :disabled="loading || disableAll"
                            :autofocus="isAutoFocus"
                            auto-grow hide-details solo flat
                        ></a-textarea>
                    </a-sheet>
                </div>
            </template>
            <span>{{ mixinGetError('description') }}</span>
        </a-tooltip>

        <div class="u-flex-center-y">
            <a-btn depressed color="primary darken-1" class="mr-4 u-rounded-corners-lg" @click="localStoreStatus()" :disabled="loading || disableAll" :loading="createLoading">{{ editMode ? 'Update' : 'Post' }} Status</a-btn>
            <g-toggle custom-bg dense-xl v-model="visibilityFlag" @change="localToggleVisibility" :disabled="loading || disableAll" class="d-inline-flex align-center" :color="visibilityFlag ? 'orange darken-2' : 'grey darken-4'">
                <span :class="[visibilityFlag ? 'orange--text text--darken-2' : 'grey--text text--darken-3', 'font-weight-medium md-body-2 text-uppercase ml-2']">{{ visibilityFlag ? 'Public' : 'Private' }}</span>
            </g-toggle>
            <template v-if="disableAll">
                <a-spacer></a-spacer>
                <span class="blue--text text--darken-3 md-body-2 font-weight-medium u-flex-center-y">
                    <a-icon size="16" color="blue darken-3" class="mr-1 u-icon-nudge-xs" style="top: 1px;">info</a-icon>
                    <span>Clear the filters to post new status update</span>
                </span>
            </template>
        </div>
    </div>
</template>

<script>
import mixinErrors from '@/mixins/mixin-module-error-setup'
import { Validate } from '@/helpers/helper-validations'

export default {
    props: {
        typeList: {
            type: Array
        },
        error: {
            type: String | Object,
        },
        editItem: {
            type: Object
        },
        editorHeight: {
            type: String | Number,
            default: 120
        },
        disableAll: {
            type: Boolean
        },
        editMode: {
            type: Boolean
        },
        isOpen: {
            type: Boolean
        },
        createLoading: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
    },

    mixins: [mixinErrors],

    data () {
        return {
            /* Boolean */
            visibilityFlag: false,
            isAutoFocus: false,

            /* Object */
            healthFilter: { text: 'All Health', value: 'all' },
            typeFilter: { id: 'all', value: 'All Type' },
            visibilityFilter: { text: 'All Visibility', value: 'all' },
            statusUpdate: { health: '', type_id: '', visibility: '', description: '' },

            /* Array */
            healthList: [
                { text: 'Green', value: 'green', color: 'green' },
                { text: 'Yellow', value: 'yellow', color: 'orange' },
                { text: 'Red', value: 'red', color: 'red' },
            ]
        }
    },

    watch: {
        isOpen (val) {
            if (val) {
                this.localResetStatusUpdateVar()
                this.localSetEditItem()
                setTimeout(() => this.isAutoFocus = true, 0)
            }
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localStatusColor () {
            switch (this.statusUpdate.health) {
                case 'green': return 'green'
                case 'red': return 'red'
                case 'yellow': return 'orange'
                default: return 'grey'
            }
        },

        localProjectId () {
            return this.$route.params.id
        }
    },

     methods: {
         async localIndex () {
             this.localResetStatusUpdateVar()
             this.localSetEditItem()
         },

         localSetEditItem () {
             if (this.editMode) {
                this.statusUpdate = _.cloneDeep(this.editItem)
                this.visibilityFlag = this.editItem.visibility === 'external'
             }
         },

         localGetTypeLabel (id) {
             if (!id) return 'Select Type'

            const index = this.typeList.findIndex(item => item.id === id)
            return (this.typeList[index] && this.typeList[index].value) ?? 'Select Type'
         },

         localStoreStatus () {
             this.mixinClearError('description, health, type_id')
             const hasError = this.localValidateStatusFields(this.statusUpdate)
            if (hasError) return true

            this.$emit('store', this.statusUpdate, this.localResetStatusUpdateVar)
        },

        localValidateStatusFields (item) {
            let hasError = false
            const { message } = new Validate(item.description, { silent: true }).required().length(1, 50000).run()
            if (message) {
                this.mixinSetError({ slug: 'description', message })
                hasError = true
            }

            const { message: healthError } = new Validate(item.health, { silent: true }).required().run()
            if (healthError) {
                this.mixinSetError({ slug: 'health', message: healthError })
                hasError = true
            }

            const { message: typeError } = new Validate(item.type_id, { silent: true }).required().run()
            if (typeError) {
                this.mixinSetError({ slug: 'type_id', message: typeError })
                hasError = true
            }

            return hasError
        },

        localResetStatusUpdateVar () {
            this.mixinClearError('description, health, type_id')
            this.statusUpdate = { health: '', type_id: '', visibility: 'internal', description: '' }
            this.visibilityFlag = false
        },

        localToggleVisibility (value) {
             this.statusUpdate.visibility = value ? 'external' : 'internal'
         },
    }
}
</script>

<template>
    <a-card class="grey lighten-3 pa-0">
        <div class="px-8 py-6 u-flex-center-y">
            <div>
                <h2 class="md-heading-6 indigo--text text--darken-1 mb-1">
                    <slot name="title"></slot>
                </h2>
                <p class="md-subtitle-2 grey--text text--darken-1 mb-0">
                    <slot name="subtitle"></slot>
                </p>
            </div>
            <a-spacer></a-spacer>
            <a-icon size="22" color="grey darken-1" class="u-cursor-pointer" @click="$emit('close')">close</a-icon>
        </div>

        <div class="white u-shadow u-rounded-corners py-1 mx-8">
            <a-text-field
                v-model="searchText"
                @input="localTriggerSearch"
                background-color="white"
                placeholder="Search by group name"
                @click:clear="localTriggerSearch(searchText)"
                solo flat hide-details dense clearable
            >
                <template #prepend-inner>
                    <a-sheet min-width="28">
                        <a-progress-circular width="3" size="20" indeterminate color="orange darken-2" v-if="searchLoading"></a-progress-circular>
                        <a-icon size="24" color="grey" v-else>search</a-icon>
                    </a-sheet>
                </template>
            </a-text-field>
        </div>

        <div class="u-flex-center-y px-8 mt-6">
            <div>
                <p class="md-body-1 primary--text text--darken-2 mr-3 mb-0" v-if="selectedIds && selectedIds.length">{{ selectedIds.length }} {{ selectedIds.length === 1 ? 'Template' : 'Templates' }} Selected</p>
                <p class="md-body-1 mr-3 mb-0 grey--text text--darken-2 u-opacity-40" v-else>No Templates Selected</p>
            </div>
            <a-spacer></a-spacer>
            <!-- <div v-if="localTemplatesLength"> -->
            <div class="mr-4">
                <g-toggle custom-bg dense-xl hide-clear-icon color="blue" class="u-flex-center-y" :value="showSelected" @change="localToggleShowSelected(!showSelected)">
                    <span class="ml-2 md-caption font-weight-medium" :class="[(showSelected ? 'blue' : 'grey') + '--text text--darken-1']" style="margin-bottom: 0px; letter-spacing: 0.5px;">SHOW SELECTED</span>
                </g-toggle>
            </div>

            <a-btn
                @click="$emit('import')"
                :loading="importLoading"
                :disabled="!!(importLoading || selectedIds.length === 0)"
                class="pa-4 mx-0 text-capitalize primary darken-1"
                depressed small
            >
                <span class="md-body-2">Import Templates</span>
            </a-btn>
        </div>

        <!-- <div style="min-height: 300px;" :style="[ localTemplatesLength === 0 ? { marginTop: '32px' } : '']"> -->
        <div style="min-height: 300px;">
            <!-- Search loading -->
            <div class="u-wfull u-border lighten-4 u-rounded-corners d-inline-block" v-if="loading">
                <div class="text-center grey--text text--darken-1 pa-0 pb-1 mt-5">
                    <a-progress-circular color="orange darken-2" size="18" class="mr-2" width="2" center :indeterminate="true"></a-progress-circular>Fetching Groups...
                </div>
            </div>

            <!-- No data message -->
            <a-responsive class="py-6 text-center" v-if="!loading && localTemplatesLength === 0">
                <a-icon size="32" color="grey lighten-1" class="mb-1">info</a-icon>
                <h3 class="md-subtitle-1 font-weight-medium text-center grey--text text--darken-1">No Groups Found</h3>
            </a-responsive>

            <div v-if="localTemplatesLength && !loading" class="pt-6 pb-8">
                <template v-for="(template_group, index) in templateList">
                    <slot name="item" :item="template_group" :index="index"></slot>
                </template>
            </div>
        </div>
    </a-card>
</template>

<script>
export default {
    props: {
        templateList: {
            type: Array
        },
        loading: {
            type: Boolean
        },
        importLoading: {
            type: Boolean
        },
        importPopup: {
            type: Boolean
        },
        selectedIds: {
            type: Array
        }
    },

    data () {
        return {
            searchText: '',
            searchLoading: false,
            showSelected: false,
        }
    },

    watch: {
        importPopup(newValue, oldValue) {
            if (newValue === true) {
                this.searchText = ''
                this.showSelected = false
            }
        },
    },

    computed: {
        localTemplatesLength () {
            return this.templateList && this.templateList.length
        },

        localSearchTextLength () {
            return (this.searchText && this.searchText.trim().length) ?? 0
        }
    },

     methods: {
         localTriggerSearch (value) {
             this.searchLoading = true
             this.$emit('search', { value, list: this.templateList, clearCb: this.localClearSearch })
         },

         localToggleShowSelected (value) {
             this.showSelected = value
             this.$emit('showSelected', { value, searchText: this.searchText, clearCb: this.localClearSearch })
         },

         localClearSearch () {
             this.searchLoading = false
         },
    }
}
</script>

<template>
    <div class="c-heading u-relative u-flex align-center" style="min-height: 40px">
        <!-- Block List Menu -->
        <block-list-menu v-if="$can('document_generator.update')" @addBlock="methodStoreReportBlock"></block-list-menu>

        <!-- Formatting Menu -->
        <div class="u-absolute" style="top: 50%; transform: translateY(-50%); left: -40px">
            <a-menu offset-x left :nudge-top="50" v-model="dataSideMenu" transition="slide-x-transition" :close-on-content-click="false" min-width="350" max-width="350" style="max-width: 24px !important;" content-class="u-border c-radius u-elevation-custom-1">
                <template v-slot:activator="{ on }">
                    <a v-on="on" v-ripple="{ class: 'info--text' }" style="padding: 0 4px 0 4px;" class="c-component__menu u-flex align-center u-rounded-corners list-group-item js-drag-handle" :class="[ dataSideMenu ? 'is-active' : '' ]"><a-icon class="c-component__drag" size="26">drag_indicator</a-icon></a>
                </template>
                <div class="c-radius u-elevation-custom-1 py-3 white" style="max-width: 350px;">
                    <div class="px-4">
                        <div class="u-flex align-center">
                            <h2 class="grey--text text--darken-3 title font-weight-bold" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.title }}</h2>
                            <a-spacer></a-spacer>
                            <a-btn small icon text color="red darken-1 ma-0" class="ma-0" v-if="$can('document_generator.destroy')" @click="methodRemoveComponent">
                                <svg width="18px" height="18px" viewBox="0 0 16 16" class="bi bi-trash red--text text--darken-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>
                            </a-btn>
                        </div>
                        <p class="grey--text text--darken-1 mb-0 body-2 font-weight-medium" v-if="dataGetBlockInfo">{{ dataGetBlockInfo.description }}</p>
                        <a-divider class="grey lighten-3 mt-3 mb-2" v-if="$can('document_generator.update')"></a-divider>
                    </div>
                    <div class="c-settings-report-block mt-4" v-if="$can('document_generator.update')">
                        <div class="u-flex align-center">
                            <div class="pl-4 u-wfull mr-4" v-if="dataUsecaseVisibilityDefault">
                                <div class="grey--text text--darken-1 text-uppercase body-2 font-weight-bold mb-2" style="letter-spacing: 1px;">{{ dataUsecaseVisibilityDefault.title }}</div>
                                <a-menu class="d-block" offset-y bottom transition="slide-y-transition" content-class="c-radius u-border u-elevation-custom-1">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="pa-2 u-cursor-pointer c-radius grey lighten-3 u-flex align-center u-wfull">
                                            <span class="u-flex align-center u-rounded-corners">
                                                {{ methodBlockAttrs(clonedUsecaseTable.id, dataUsecaseVisibilityDefault.id, 'visibility').value.label }}
                                            </span>
                                            <a-spacer></a-spacer>
                                            <a-icon color="grey darken-2" size="24">arrow_drop_down</a-icon>
                                        </div>
                                    </template>
                                    <a-list class="pa-0 py-2 u-list-condensed">
                                        <a-list-item v-for="(menu, index) in dataUsecaseVisibilityDefault.options" :key="menu.level" class="u-wfull px-4" :class="[dataUsecaseVisibilityDefault.options && dataUsecaseVisibilityDefault.options.length - 1 !== index ? 'mb-2' : '' ]" @click="methodUpdateVisibility(menu, dataUsecaseVisibilityDefault.id)">
                                            <a-list-item-content>
                                                <a-list-item-title class="grey--text text--darken-2">{{ menu.label }}</a-list-item-title>
                                            </a-list-item-content>
                                        </a-list-item>
                                    </a-list>
                                </a-menu>
                            </div>
                            <!-- <div class="pr-4 u-wfull" v-if="dataUsecaseStatusDefault">
                                <div class="grey--text text--darken-1 text-uppercase body-2 font-weight-bold mb-2" style="letter-spacing: 1px;">{{ dataUsecaseStatusDefault.title }}</div>
                                <a-menu class="d-block" offset-y bottom transition="slide-y-transition" content-class="c-radius u-border u-elevation-custom-1">
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" class="pa-2 u-cursor-pointer c-radius grey lighten-3 u-flex align-center u-wfull">
                                            <span class="u-flex align-center u-rounded-corners">
                                                {{ methodBlockAttrs(clonedUsecaseTable.id, dataUsecaseStatusDefault.id, 'status').value.label }}
                                            </span>
                                            <a-spacer></a-spacer>
                                            <a-icon color="grey darken-2" size="24">arrow_drop_down</a-icon>
                                        </div>
                                    </template>
                                    <a-list class="pa-0 py-2 u-list-condensed">
                                        <a-list-item v-for="(menu, index) in dataUsecaseStatusDefault.options" :key="menu.label" class="u-wfull px-4" :class="[dataUsecaseStatusDefault.options && dataUsecaseStatusDefault.options.length - 1 !== index ? 'mb-2' : '' ]" @click="methodUpdateStatus(menu, dataUsecaseStatusDefault.id)">
                                            <a-list-item-content>
                                                <a-list-item-title class="grey--text text--darken-2">{{ menu.label }}</a-list-item-title>
                                            </a-list-item-content>
                                        </a-list-item>
                                    </a-list>
                                </a-menu>
                            </div> -->
                        </div>
                        <template v-if="dataUsecaseColumnsDefault">
                            <div class="u-flex align-center grey--text text--darken-1 text-uppercase body-2 font-weight-bold mb-2 mt-4 px-4" style="letter-spacing: 1px;">
                                {{ dataUsecaseColumnsDefault.title }}
                            </div>
                            <a-list class="overflow-y-auto u-list-condensed" style="max-height: 320px; padding: 0px 0px !important;">
                                <a-list-item v-for="(menu, index) in dataUsecaseColumnsDefault.options[0].value" :key="menu + index" class="u-wfull px-4 mb-2 c-radius" @click="methodUpdateColumns(menu, dataUsecaseColumnsDefault.id)">
                                    <a-list-item-action class="mr-2" v-if="menu">
                                        <svg v-if="methodBlockHasField(menu.value)" width="16px" height="16px" viewBox="0 0 16 16" class="bi bi-check-circle-fill blue--text text--darken-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                        </svg>
                                        <svg v-if="!methodBlockHasField(menu.value)" width="16px" height="16px" viewBox="0 0 16 16" class="bi bi-circle grey--text text--darken-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        </svg>
                                    </a-list-item-action>
                                    <a-list-item-content>
                                        <a-list-item-title class="grey--text text--darken-2 text-capitalize">{{ menu.label }}</a-list-item-title>
                                    </a-list-item-content>
                                </a-list-item>
                            </a-list>
                        </template>
                    </div>
                </div>
            </a-menu>
        </div>

        <!-- Block -->
        <div class="c-radius my-3 u-wfull c-xspacing" style="border: 2px dashed #e8e8e8 !important">
            <a-sheet class="u-wfull grey lighten-5 pa-4 c-radius">
                <div class="text-center">
                    <div>
                        <a-icon size="32" color="grey darken-2">table_chart</a-icon>
                    </div>
                    <div class="mt-1">
                        <h2 class="title grey--text text--darken-3">{{ clonedUsecaseTable.report_block.title }}</h2>
                        <h3 class="ml-2 mt-3 grey--text text--darken-1 d-inline-block ml-auto" style="width: 550px; font-size: 16px; font-weight: 400 !important">
                            Visibility: <span class="font-weight-medium" style="color: #737373 !important">{{ selectedVisibility.label }}</span> &bull; Columns: <span class="font-weight-medium" style="color: #737373 !important">{{ selectedColumns.value && selectedColumns.value.length > 0 ? dataCommaSeparatedCols : 'None' }}</span>
                        </h3>
                    </div>
                </div>
            </a-sheet>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import EventBus from '@/config/config-eventbus'
import BlockListMenu from '../BlockListMenu.vue'

export default {
    components: { BlockListMenu },

    props: ['item', 'index'],

    data () {
        return {
            clonedUsecaseTable: this.item,
            currentOption: '',
            currentAlignment: '',
            currentMenuValue: '',
            selectedColumns: [],
            selectedVisibility: { label: 'All', value: 'all' },
            selectedStatus: { label: 'All', value: 'all' },
            dataSideMenu: false,
            format: {
                center: false,
                right: false,
                left: false,
            }
        }
    },

    mounted () {
        this.methodIndex()
    },

    computed: {
        dataCommaSeparatedCols () {
            return this.selectedColumns.value.map(item => item.label).join(', ')
        },

        dataGetBlockInfo () {
            return this.getBlockContent(this.clonedUsecaseTable.report_block_id)
        },

        dataUsecaseColumnsDefault () {
            const info = this.methodDefaultAttrByTitle('Columns')
            const { label, value } = this.getBlockAttrs(this.clonedUsecaseTable.id, info.id).value
            this.selectedColumns = { label, value: _.sortBy(value, ['order']) }
            return info
        },

        dataUsecaseVisibilityDefault () {
            const info = this.methodDefaultAttrByTitle('Visibility')
            this.selectedVisibility = this.getBlockAttrs(this.clonedUsecaseTable.id, info.id).value
            return info
        },

        // dataUsecaseStatusDefault () {
        //     const info = this.methodDefaultAttrByTitle('Status')
        //     this.selectedStatus = this.getBlockAttrs(this.clonedUsecaseTable.id, info.id).value
        //     return info
        // },

        ...mapGetters('ReportBlock', [
            'getDefaultBlockAttr',
            'getBlockContent'
        ]),

        ...mapGetters('ReportTemplateBlock', [
            'getBlockAttrs'
        ]),

        ...mapGetters('ReportTemplate', [
            'getBlock',
        ]),
    },

    methods: {
        methodIndex () {
            this.dataUsecaseColumnsDefault
            this.dataUsecaseVisibilityDefault
            // this.dataUsecaseStatusDefault
        },

        methodUpdateVisibility (menu, defaultAttrId) {
            this.methodSetCurrentOption(defaultAttrId)
            this.selectedVisibility = menu
            this.currentMenuValue = menu
            this.methodUpdateBlockAttr()
        },

        methodUpdateStatus (menu, defaultAttrId) {
            this.methodSetCurrentOption(defaultAttrId)
            this.selectedStatus = menu
            this.currentMenuValue = menu
            this.methodUpdateBlockAttr()
        },

        methodUpdateColumns (menu, defaultAttrId) {
            const index = this.selectedColumns.value.findIndex(item => item.value === menu.value)
            this.methodSetCurrentOption(defaultAttrId)

            if (index !== -1) this.selectedColumns.value.splice(index, 1)
            else {
                this.selectedColumns.value.push(menu)
                this.selectedColumns.value = _.sortBy(this.selectedColumns.value, ['order'])
            }

            this.currentMenuValue = this.selectedColumns
            this.methodUpdateBlockAttr()
        },

        methodSetCurrentOption (defaultAttrId) {
            this.currentOption = {
                defaultAttrId: defaultAttrId,
                menuAttrId: this.methodBlockAttrs(this.clonedUsecaseTable.id, defaultAttrId).id
            }
        },

        methodBlockAttrs (blk_id, defaultAttrId, type = null) {
            const attr = this.getBlockAttrs(blk_id, defaultAttrId)
            type === 'visibility' ? this.selectedVisibility = attr.value : this.selectedStatus = attr.value
            return attr
        },

        methodBlockHasField (value) {
            const index = this.selectedColumns.value.findIndex(item => item.value === value)
            return index !== -1
        },

        async methodUpdateBlockAttr () {
            const params = {
                rt_block_id: this.clonedUsecaseTable.id,
                rb_attribute_id: this.currentOption.defaultAttrId,
                value: JSON.stringify(this.currentMenuValue)
            }

            await this.actionTemplateBlockAttrEdit({ id: this.currentOption.menuAttrId ? this.currentOption.menuAttrId : null, ...params })
            EventBus.$emit('editBlock', this.clonedUsecaseTable)
            this.actionTemplateBlockShow({ id: this.clonedUsecaseTable.id, index: this.index })
        },

        methodDefaultAttrByTitle (title) {
            return this.dataGetBlockInfo.attributes.find(item => item.title === title)
        },

        async methodStoreReportBlock (component) {
            await this.actionTemplateBlockStore({
                index: this.index + 1,
                data: { report_template_id: this.$route.params.template_id, report_block_id: component.id },
                params: { include: 'reportBlock' }
            })
            this.actionTemplateBlockReorder({ list: this.list })
            // EventBus.$emit('reorderBlock', { index: this.index + 1 })
            // EventBus.$emit('storeBlock', { index: this.index + 1, component })
        },

        methodRemoveComponent() {
            this.actionFindAndRemoveBlock(this.clonedUsecaseTable)
            this.actionTemplateBlockDelete({ id: this.clonedUsecaseTable.id })
            // EventBus.$emit('removeComponent', this.clonedUsecaseTable)
        },

        ...mapActions('ReportTemplateBlock', [
            'actionTemplateBlockStore',
            'actionTemplateBlockEdit',
            'actionTemplateBlockReorder',
            'actionTemplateBlockAttrsStore',
            'actionTemplateBlockAttrEdit',
            'actionTemplateBlockShow',
            'actionFindAndRemoveBlock',
            'actionTemplateBlockDelete'
        ]),
    }
}
</script>

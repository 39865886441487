<template>
	<a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="pa-6 pt-3 px-0 mb-12">
            <g-page-header
                title="Manage Partners"
                subtitle="You can create partners and contacts to include in project."
                icon="contacts"
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                class="pr-1"
            >
                <template v-slot:action v-if="check_user_partner_permission('store')">
                    <a-btn color="primary" class="elevation-0 mx-0" dark @click="local_partner_create()">
                        <a-icon class="mr-2" color="white" size="18">add</a-icon>
                        Create New Partner
                    </a-btn>
                </template>
            </g-page-header>
            <a-layout align-center class="my-0 mb-2">
                <a-flex class="py-0">
                    <a-card class="c-search-inventory px-3 d-flex align-center u-border u-rounded-corners mb-1" flat>
                        <a-icon size="24" color="grey" style="max-width: 20px;">search</a-icon>
                        <a-text-field solo flat hide-details dense placeholder="Search by partners name" :loading="search_loading" class="" v-model="search_term" @input="local_search_filter(search_term)"></a-text-field>
                    </a-card>
                </a-flex>
            </a-layout>
            <a-layout v-if="local_loading || (!local_loading && !partner_list.length)">
                <a-flex>
                    <a-responsive class="my-4" v-if="local_loading">
                        <!-- <a-divider class="grey lighten-2 mb-4"></a-divider> -->
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Partners..</h3>
                    </a-responsive>
                    <a-responsive class="my-4" v-if="!local_loading && !partner_list.length">
                        <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                        <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <a-layout>
                <a-flex xs12>
                    <a-card
                        v-for="(partner, index) in partner_list"
                        :key="partner.id"
                        :class="[{ 'mt-4': index !== 0 }]"
                        class="pa-1 u-border"
                        flat
                    >
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl>
                                <a-layout wrap align-center>
                                    <a-flex shrink xs8 xl10>
                                        <h2 class="md-heading-6 font-weight-bold mb-1">{{ partner.name }}</h2>
                                        <p class="md-subtitle-1 grey--text text--darken-2 mb-0" v-if="!partner.contacts_count || partner.contacts_count == 0">No Contacts</p>
                                        <p class="md-subtitle-1 grey--text text--darken-2 mb-0" v-if="partner.contacts_count == 1">1 Contact</p>
                                        <p class="md-subtitle-1 grey--text text--darken-2 mb-0" v-if="partner.contacts_count > 1">{{ partner.contacts_count }} Contacts</p>
                                    </a-flex>
                                    <a-spacer></a-spacer>
                                    <a-flex shrink class="u-flex align-center">
                                        <a-btn color="grey darken-1" class="mx-2" text icon :to="{name: 'partners-contacts', params: {id: partner.id}}" exact>
                                            <a-icon>contact_mail</a-icon>
                                        </a-btn>
                                        <a-btn color="grey darken-1" class="mx-2" text icon @click="local_partner_edit(partner.id)" v-if="check_user_partner_permission('update')">
                                            <a-icon>edit</a-icon>
                                        </a-btn>
                                        <template v-if="check_user_partner_permission('destroy')">
                                            <a-tooltip bottom v-if="partner.total_projects_count > 0">
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <a-btn size="16" text icon disabled>
                                                            <a-icon>delete</a-icon>
                                                        </a-btn>
                                                    </div>
                                                </template>
                                                <div class="text-center" style="max-width: 200px;">This partner belongs to {{ partner.total_projects_count === 1 ? partner.total_projects_count + ' project' : partner.total_projects_count + ' projects' }} and cannot be deleted</div>
                                            </a-tooltip>
                                            <a-menu v-else left offset-y transition="slide-y-transition" max-width="340">
                                                <template v-slot:activator="{ on }">
                                                    <a-btn v-on="on" color="grey darken-1" size="16" text icon>
                                                        <a-icon>delete</a-icon>
                                                    </a-btn>
                                                </template>
                                                <a-card class="u-rounded-corners u-elevation-custom-1 pa-3" min-width="320" max-width="340">
                                                    <a-card-text class="pa-1">
                                                        <h2 class="body-2 font-weight-normal mb-1 grey--text text--darken-1"><strong>Are you sure you want to delete the partner?</strong> <br> This partner and related data will be permanently deleted. This action cannot be undone.</h2>
                                                        <div class="u-flex align-center justify-space-between mt-2">
                                                            <p class="caption grey--text text--darken-1 mb-0 font-weight-normal">Click "Delete" to continue</p>
                                                            <a-btn @click="partner_destroy({ id: partner.id })" small text class="red darken-1 u-rounded-corners ma-0 pa-1 px-2 ml-2" dark>Delete</a-btn>
                                                        </div>
                                                    </a-card-text>
                                                </a-card>
                                            </a-menu>
                                        </template>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
            <a-layout wrap align-start v-if="!local_loading">
                <a-flex xs12 class="u-flex align-center">
                    <a-btn v-if="partners_meta && (partners_meta.current_page < partners_meta.last_page)" :loading="loading" :disabled="loading" @click="local_paginate(partners_meta.current_page + 1)" small text class="primary" dark>
                        Load More
                    </a-btn>
                    <a-spacer></a-spacer>
                    <span class="md-body-2 grey--text text--darken-1" v-if="partners_meta.total">Showing <strong>{{ partner_list.length }}</strong> out of <strong>{{ partners_meta.total }}</strong> accounts</span>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog v-model="dialog_partner_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>contacts</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (partner_form_mode == 'add') ? 'Create New Partner' : 'Edit Existing Partner' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (partner_form_mode == 'add') ? 'You can add new partner to manage projects.' : 'You can edit existing partner information.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                    <a-text-field
                                        v-model="partner_item.name"
                                        placeholder="Enter Partner Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="partner_response.server && partner_response.server.errors && partner_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ partner_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the partner.
                                    </span>
                                </a-flex>
                            </a-layout>
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Domain</label>
                                    <a-text-field
                                        v-model="partner_item.domain"
                                        placeholder="Enter the Domain"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="partner_response.server && partner_response.server.errors && partner_response.server.errors.domain">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ partner_response.server.errors.domain[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The domain will be helpful to identify the partner.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_partner_store()" :loading="loading" :disabled="loading">Save Partner</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_partner_form = !dialog_partner_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            search_term: null,
            dialog_partner_form: false,
            local_loading: true,
            search_loading: false,
            partners_count: 100,
            partners_filter: {},
            FILTERS: {
                PAGE: 'page',
                COUNT: 'count',
                NAME: 'filter[name]'
            }
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Partners', to: '/partners', exact: true})
            return breadcrumbs
        },
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Partner', {
            partner_self: 'self',
            partner_list: 'list',
            partners_meta:'meta',
            partner_item: 'item',
            partner_form_mode: 'form_mode',
            partner_response: 'response',
        }),
    },
    watch: {
        search_term(newValue, oldValue) {
            if (newValue == '' && newValue != oldValue || newValue == null && newValue != oldValue) {
                this.local_loading = true
                this.local_index()
            }
        },
    },
    methods: {
        async local_index() {
            await this.partner_clear()
            this.partners_filter[this.FILTERS.PAGE] = 1
            this.partners_filter[this.FILTERS.COUNT] = this.partners_count
            await this.local_partners_index()
            this.local_loading = false
        },
         async local_paginate(value) {
            this.partners_filter[this.FILTERS.PAGE] = value
            await this.local_partners_index()
        },
        async local_partners_index(params = {}) {
            await this.partner_index({
                include: 'contactsCount,totalProjectsCount',
                'sort': 'name',
                ...this.partners_filter,
                ...params,
            })
            this.search_loading = false
        },
        async local_partner_edit(id) {
            await this.partner_select({id: id})
            this.dialog_partner_form = true
        },
        async local_partner_create() {
            await this.partner_clear_item()
            this.dialog_partner_form = true
        },
        async local_partner_store() {
            if (this.partner_form_mode == 'add') {
                await this.partner_store(this.partner_item)
            } else {
                await this.partner_update()
            }
            if (this.partner_response.status == 'success') {
                this.dialog_partner_form = false
            }
        },
        check_user_partner_permission(type, client_allowed = false) {
            if (this.user_self.role.scope === 'external') {
                return client_allowed
            }
            return this.$can(`partners.${type}`) && this.user_self.role.scope === 'internal'
        },
        local_search_filter(value){
            this.search_loading = true
            clearTimeout(this.search_timeout)
            this.partners_filter[this.FILTERS.COUNT] = 999
            this.partners_filter[this.FILTERS.PAGE] = 1
            this.partners_filter[this.FILTERS.NAME] = value
            this.search_timeout = window.setTimeout(async () => {
                if (!this.search_term.trim()) return this.search_loading = false
                if (!this.search_term || this.search_term.trim().length <= 3) {
                    const search_error = 'Try more than 3 characters to search.'
                    this.$notify('error', search_error)
                    return this.search_loading = false
                }
                this.search_loading = false
                await this.local_partners_index()
            }, 500)
        },
        ...mapActions('Partner', {
            partner_index: 'index',
            partner_store: 'store',
            partner_update: 'update',
            partner_select: 'select',
            partner_upgrade: 'upgrade',
            partner_downgrade: 'downgrade',
            partner_destroy: 'destroy',
            partner_reorder: 'reorder',
            partner_clear: 'clear',
            partner_clear_item: 'clear_item',
        }),
    },
}
</script>

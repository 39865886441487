<template>
    <div class="u-absolute" style="top: 50%; transform: translateY(-50%); left: -60px">
        <a-menu offset-y bottom :nudge-bottom="10" v-model="dataBlockListMenu" :disabled="dataMenuDisabled" :close-on-content-click="dataSearchAction" transition="slide-y-transition" min-width="400" max-width="400" content-class="u-border c-radius u-elevation-custom-1">
            <template v-slot:activator="{ on }">
                <a v-on="on" @click.prevent="dataSearch = ''" v-ripple="{ class: 'info--text' }" style="padding: 4px;" class="c-component__menu u-flex align-center u-rounded-corners" :class="[ dataBlockListMenu ? 'is-active' : '' ]"><a-icon size="18">add</a-icon></a>
            </template>
            <a-card flat class="u-overflow-hidden">
                <a-list class="u-list-condensed py-0">
                    <a-list-item class="c-input-wrap c-input-wrap--doc-generate py-4 px-4" :class="{ 'mb-2 pb-1': dataFilteredBlockList && dataFilteredBlockList.length }">
                        <a-text-field
                            v-model="dataSearch"
                            label="Search Blockssss"
                            color="grey darken-1"
                            background-color="neutral"
                            ref="refSearchInput"
                            class="subheading"
                            solo flat hide-details
                            @click="dataSearchAction = false"
                        >
                        </a-text-field>
                    </a-list-item>
                </a-list>
                <a-list ref="refBlockItems" class="c-block-list-menu px-0 pt-0 overflow-y-auto" :class="[dataFilteredBlockList.length ? 'pb-3' : 'pb-0']" v-if="dataFilteredBlockList" style="max-height: 400px;">
                    <template v-if="dataFilteredBlockList.length">
                        <template v-for="(component, index) in dataFilteredBlockList">
                            <a-divider class="grey lighten-4" :key="index + 'list-divider'" v-if="index !== 0"></a-divider>
                            <a-list-item @click="methodStoreReportBlock(component)" class="u-wfull px-4 py-1" :key="component.id">
                                <div class="u-flex align-start">
                                    <a-list-item-action class="ma-0 mr-2">
                                        <a-avatar tile style="border-radius: 10px !important;" class="u-border grey lighten-2 mr-2">
                                            <a-img :src="require('../../assets/images/blocks/' + component.icon)" width="24" height="24" class="grey--text text--darken-1" contain></a-img>
                                        </a-avatar>
                                    </a-list-item-action>
                                    <a-list-item-content>
                                        <a-list-item-title>{{ component.title }}</a-list-item-title>
                                        <a-list-item-subtitle>{{ component.description }}</a-list-item-subtitle>
                                    </a-list-item-content>
                                </div>
                            </a-list-item>
                        </template>
                    </template>
                    <a-list-item v-else class="u-wfull px-0">
                        <a-list-item-content>
                            <a-list-item-title class="grey--text text--darken-3">No blocks found..</a-list-item-title>
                        </a-list-item-content>
                    </a-list-item>
                </a-list>
            </a-card>
        </a-menu>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data () {
        return {
            dataMenuDisabled: false,
            dataBlockListMenu: false,
            dataSearch: '',
            dataSearchAction: true
        }
    },

    watch: {
        dataBlockListMenu (val) {
            if (val) {
                setTimeout(() => this.$refs.refBlockItems.$el.scrollTop = 0, 100)
                setTimeout(() => this.$refs.refSearchInput.ref.focus(), 500)
            }
        },
    },

    computed: {
        dataFilteredBlockList () {
            const searchValue = this.dataSearch.toLowerCase()
            const searchableFields = ['title', 'description']

            return this.getBlocksList.filter(item => {
                return searchableFields.some(field => item[field] && item[field].toLowerCase().includes(searchValue))
            })
        },

        ...mapGetters('ReportBlock', [
            'getBlocksList',
        ]),
    },

    methods: {
        methodStoreReportBlock (component) {
            this.dataMenuDisabled = true
            this.dataSearchAction = true
            this.$emit('addBlock', component)
            setTimeout(() => this.dataMenuDisabled = false, 100)
        }
    }
}
</script>

<template>
    <div v-once>
        <div class="u-flex justify-space-between mb-4">
            <div v-for="loader in 2" :key="loader" class="u-border c-loader-padding u-rounded-corners-lg">
                <loader-template class="u-rounded-corners-lg" :height="loader_height" :width="loader_width"></loader-template>
            </div>
        </div>
        <div class="u-flex justify-space-between mb-8">
            <div class="u-flex">
                <div v-for="loader in 2" :key="loader" class="u-border c-loader-padding u-rounded-corners-lg mr-4">
                    <loader-template class="u-rounded-corners-lg" :height="loader_height" :width="loader_width"></loader-template>
                </div>
            </div>
            <div class="u-border c-loader-padding u-rounded-corners-lg">
                <loader-template class="u-rounded-corners-lg" :height="loader_height" :width="loader_width"></loader-template>
            </div>
        </div>
        <div v-for="(table, tableIndex) in 2" :key="table" class="mb-4 u-overflow-hidden">
            <div class="u-flex justify-space-between pb-4 pl-6" :class="[{ 'mt-14': tableIndex !== 0 }]">
                <loader-template class="u-rounded-corners-lg" height="16" width="440"></loader-template>
                <loader-template class="u-rounded-corners-lg" height="16" width="240"></loader-template>
            </div>
            <table class="c-loader-cell-table">
                <colgroup>
                    <col span="1" style="width: 60%;">
                    <col span="1" style="width: 10%">
                    <col span="1" style="width: 10%">
                    <col span="1" style="width: 10%">
                    <col span="1" style="width: 10%">
                </colgroup>
                <tbody>
                    <tr v-for="(row, rowIndex) in tableIndex === 0 ? 4 : 3" :key="row">
                        <td class="c-loader-padding c-loader-cell-lg" :class="[{ 'c-row-selected': rowIndex === 1 && tableIndex === 0 }]">
                            <loader-template :height="table_loader_height" width="220"></loader-template>
                        </td>
                        <td v-for="col in 4" :key="col" class="c-loader-padding c-loader-cell-sm" :class="[{ 'c-row-selected': rowIndex === 1 && tableIndex === 0 }]">
                            <loader-template :height="table_loader_height" :width="table_loader_width"></loader-template>
                        </td>
                    </tr>
                    <tr>
                        <td class="c-loader-padding c-loader-cell-lg" colspan="5">
                            <loader-template height="10" width="220"></loader-template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                table_loader_height: 10,
                table_loader_width: 120,
                loader_height: 10,
                loader_width: 120,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .c-loader-cell-table {
        width: 110%;
        border-top: 1px solid rgba(238, 238, 238, 1);
        border-left: none;
        border-collapse: collapse;
    }
    .c-no-bottom-border {
        width: 110%;
        border: 1px solid rgba(238, 238, 238, 1);
        border-left: none;
        border-bottom: none;
    }
    .c-loader-cell-sm {
        border-left: 1px solid rgba(238, 238, 238, 1);
        border-bottom: 1px solid rgba(238, 238, 238, 1);
    }
    .c-loader-cell-lg {
        border-bottom: 1px solid rgba(238, 238, 238, 1);
    }
    .c-loader-padding {
        padding: 1rem 1.5rem;
    }
    .c-row-selected {
        background-color: #E1F5FE !important;
    }
</style>

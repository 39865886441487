<template>
    <a-card>
        <a-sheet class="py-5 px-6">
            <h2 class="font-weight-medium amber--text text--darken-3 u-font-semibold" style="font-size: 18px;">
                <a-icon class="mr-1" color="amber darken-3" size="24" style="top: -2px;">error</a-icon>
                Action required...
            </h2>
            <template v-if="oldRelatedFieldId !== item.related_field_id">
                <p class="grey--text text--darken-2 mt-5 mb-0" v-if="item.related_field_id === 'all'" style="font-size: 16px;">
                    It seems like you have switched the issue type from <strong>{{ getJiraProjectType(oldRelatedFieldId, oldRelatedTypeFieldId) }}</strong> under the project type <strong>{{ getJiraProject(oldRelatedFieldId) }}</strong><a-icon size="16" color="grey darken-3" class="u-icon-nudge-xs mx-1">east</a-icon><strong>All Projects</strong> has caused certain mappings to lack necessary fields, hindering progress.
                </p>
                <template v-else>
                    <p class="grey--text text--darken-2 mt-5 mb-0" v-if="oldRelatedFieldId === 'all'" style="font-size: 16px;">
                       It seems like you have switched the issue type from <strong>All Projects</strong><a-icon size="16" color="grey darken-2" class="u-icon-nudge-xs mx-1">east</a-icon><strong>{{ getJiraProjectType(item.related_field_id, item.related_type_field_id) }}</strong> under the project type <strong>{{ getJiraProject(item.related_field_id) }}</strong> has caused certain mappings incompatible without necessary fields, hindering progress. 
                    </p>
                    <p class="grey--text text--darken-2 mt-5 mb-0" style="font-size: 16px;" v-else>
                        It seems like you have switched the project type from <strong>{{ getJiraProjectType(oldRelatedFieldId, oldRelatedTypeFieldId) }}</strong><a-icon size="16" color="grey darken-2" class="u-icon-nudge-xs mx-1">east</a-icon><strong>{{ getJiraProjectType(item.related_field_id, item.related_type_field_id) }}</strong> under <strong>{{ getJiraProject(oldRelatedFieldId) }} </strong> to <strong>{{ getJiraProjectType(oldRelatedFieldId, oldRelatedTypeFieldId) }}</strong><a-icon size="16" color="grey darken-3" class="u-icon-nudge-xs mx-1">east</a-icon><strong>{{ getJiraProjectType(item.related_field_id, item.related_type_field_id) }}</strong> under <strong>{{ getJiraProject(item.related_field_id) }}</strong> has caused certain mappings incompatible without necessary fields, hindering progress.
                    </p>
                </template>
            </template>
            <template v-if="(oldRelatedFieldId === item.related_field_id) && (oldRelatedTypeFieldId !== item.related_type_field_id)">
                <p class="grey--text text--darken-2 md-subtitle-1 mt-5 mb-0" style="font-size: 16px;">
                    It seems that changing the issue type from <strong>{{ getJiraProjectType(oldRelatedFieldId, oldRelatedTypeFieldId) }}</strong><a-icon size="16" color="grey darken-2" class="u-icon-nudge-xs mx-1">east</a-icon><strong>{{ getJiraProjectType(item.related_field_id, item.related_type_field_id) }}</strong> has resulted in certain mappings incompatible due to the absence of necessary fields, hindering progress.
                </p>
            </template>
            <p v-if="item.related_field_id !== 'all'" class="grey--text text--darken-2 mt-2 mb-0" style="font-size: 16px;">To prevent errors, let's assign new fields to the invalid mappings based on the <strong>{{ getJiraProjectType(item.related_field_id, item.related_type_field_id) }}</strong> issue type</p>
            <p v-if="item.related_field_id === 'all'" class="grey--text text--darken-2 mt-2 mb-0" style="font-size: 16px;"><strong>All projects</strong> supports only Jira system fields.</p>
            <p class="indigo--text text--darken-1 font-weight-medium text-uppercase mt-5 mb-0" style="font-weight: 600;font-size: 14px;">Invalid Mappings:</p>
        </a-sheet> 
        <a-divider class="grey lighten-3 mx-6"></a-divider>
        <a-card-text class="pa-0" style="max-height: 260px;">
            <a-sheet class="py-5 px-6">
                <div class="u-flex-center-y" v-for="(mapping, index) in jiraInvalidField" :key="index" :class="[{ 'mt-6': index !== 0 }]">
                    <div class="mr-5" style="width: 40px; height: 40px; flex: 0 0 auto;">
                        <div v-if="isRemoveMigrationMapping(mapping)" class="u-flex-center u-rounded-corners red lighten-5" style="width: 40px; height: 40px;">
                            <a-icon color="red" size="20">delete</a-icon>
                        </div>
                        <div v-else class="u-flex-center u-rounded-corners" :class="[mapping.field_name && mapping.value ? 'green lighten-5' : 'orange lighten-5']" style="width: 40px; height: 40px;">
                            <a-icon :color="mapping.field_name && mapping.value ? 'green' : 'orange darken-1'" size="20">{{ mapping.field_name && mapping.value ? 'check_circle' : 'error'}} </a-icon>
                        </div>
                    </div>
                    <div style="flex: 1 0 200px; zIndex: 2;">
                        <div class="u-flex-center-y u-rounded-corners-lg u-border-light" style="height: 40px; background: #FAFAFA;">
                            <div class="u-flex-center pl-1" style="width: 38px; height: 40px;">
                                <img :src="require('../../../assets/images/jira-logo.svg')" alt="Jira" :class="[{ 'u-opacity-40': isRemoveMigrationMapping(mapping) }]" style="display: block; max-width: 20px; max-height: 20px;">
                            </div>
                            <a-divider class="grey lighten-2 mx-1" vertical></a-divider>
                            <h4 class="md-subtitle-1 grey--text pl-3 text-decoration-line-through" :class="[{ 'u-opacity-40': isRemoveMigrationMapping(mapping) }]" :title="mapping.label">
                                {{ mapping.label | truncateText(33) }}
                            </h4>
                        </div>
                    </div>
                    <div class="mx-2">
                        <a-icon size="20" color="grey" class="u-icon-nudge-xs">east</a-icon>
                    </div>
                    <div class="white" style="flex: 1 0 200px; zIndex: 2;">
                        <div class="u-flex-center-y u-rounded-corners-lg" :class="[!mapping.value && mapping.errors && mapping.errors.value ? 'c-wiggle-short u-border-error' : 'u-border-light']" style="height: 40px;">
                            <div class="u-flex-center c-migration-validate-input" style="width: 70px; height: 40px;">
                                <template v-if="isFieldPicklist(mapping)">
                                    <a-select
                                        v-model="mapping.field_source"
                                        :items="[{ text: 'Success', value: 'success' }, { text: 'Jira', value: 'jira' }]"
                                        item-text="text"
                                        item-value="value"
                                        class="u-rounded-corners-lg"
                                        flat solo hide-details
                                    >
                                        <template #selection="{ item }">
                                            <template v-if="item.value === 'jira'">
                                                <img :src="require('../../../assets/images/jira-logo.svg')" alt="Jira" :class="[{ 'u-opacity-40': isRemoveMigrationMapping(mapping) }]" style="display: block; max-width: 20px; max-height: 20px;">
                                            </template>
                                            <template v-if="item.value === 'success'">
                                                <img :src="require('../../../assets/images/success-imgs/icon-success.svg')" alt="Success" :class="[{ 'u-opacity-40': isRemoveMigrationMapping(mapping) }]" style="display: block; max-width: 20px; max-height: 20px;">
                                            </template>
                                        </template>
                                    </a-select>
                                </template>
                                <template v-if="!isFieldPicklist(mapping)">
                                    <img v-if="item.direction === 'incoming'" :src="require('../../../assets/images/jira-logo.svg')" alt="Jira" :class="[{ 'u-opacity-40': isRemoveMigrationMapping(mapping) }]" style="display: block; max-width: 20px; max-height: 20px;">
                                    <img v-else :src="require('../../../assets/images/success-imgs/icon-success.svg')" alt="Success" :class="[{ 'u-opacity-40': isRemoveMigrationMapping(mapping) }]" style="display: block; max-width: 20px; max-height: 20px;">
                                </template>
                            </div>
                            <a-divider class="grey lighten-2 mx-1" vertical></a-divider>
                            <a-tooltip bottom offset-overflow :disabled="!(!mapping.value && mapping.errors && mapping.errors.value)">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" class="c-migration-validate-input">
                                        <a-autocomplete
                                            v-model="mapping.value"
                                            :items="!isFieldPicklist(mapping) ? getSourceValueMappingsFields : getSourceValueFields(mapping)"
                                            item-text="label"
                                            item-value="name"
                                            placeholder="Select value"
                                            :disabled="isRemoveMigrationMapping(mapping)"
                                            flat solo hide-details
                                        ></a-autocomplete>
                                    </div>
                                </template>
                                <span>Required field.</span>
                            </a-tooltip>
                        </div>
                    </div>
                    <div class="c-action-connector u-relative u-flex-center-y grey lighten-1" style="width: 24px; height: 2px;"></div>
                    <div class="white" style="flex: 1 0 200px; zIndex: 2;">
                        <div class="u-flex-center-y u-rounded-corners-lg" :class="[!mapping.field_name && mapping.errors && mapping.errors.field_name ? 'c-wiggle-short u-border-error' : 'u-border-light']" style="height: 40px;">
                            <div class="u-flex-center pl-1" style="width: 70px; height: 40px;">
                                <img v-if="item.direction !== 'incoming'" :src="require('../../../assets/images/jira-logo.svg')" alt="Jira" :class="[{ 'u-opacity-40': isRemoveMigrationMapping(mapping) }]" style="display: block; max-width: 20px; max-height: 20px;">
                                <img v-else :src="require('../../../assets/images/success-imgs/icon-success.svg')" alt="Success" :class="[{ 'u-opacity-40': isRemoveMigrationMapping(mapping) }]" style="display: block; max-width: 20px; max-height: 20px;">
                            </div>
                            <a-divider class="grey lighten-2 mx-1" vertical></a-divider>
                            <a-tooltip bottom offset-overflow :disabled="!(!mapping.field_name && mapping.errors && mapping.errors.field_name)">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" class="c-migration-validate-input">
                                        <a-autocomplete
                                            v-model="mapping.field_name"
                                            :items="getSourceFieldNamesMappings"
                                            item-text="label"
                                            item-value="name"
                                            placeholder="Select field"
                                            :disabled="isRemoveMigrationMapping(mapping)"
                                            flat solo hide-details
                                        ></a-autocomplete>
                                    </div>
                                </template>
                                    <span>Required field.</span>
                            </a-tooltip>
                        </div>
                    </div>
                    <div class="ml-5" style="width: 40px; height: 40px; flex: 0 0 auto;">
                        <div class="u-flex-center u-rounded-corners u-border-light u-cursor-pointer" v-if="!isRemoveMigrationMapping(mapping)" style="width: 40px; height: 40px;" @click="removeMigrationMapping(mapping)">
                            <a-icon color="grey" size="20">remove_circle_outline</a-icon>
                        </div>
                        <div class="u-flex-center u-rounded-corners u-border-light u-cursor-pointer" v-if="isRemoveMigrationMapping(mapping)" style="width: 40px; height: 40px;" @click="undoRemoveMigrationMapping(mapping)">
                            <a-icon color="grey darken-3" size="20">undo</a-icon>
                        </div>
                    </div>
                </div>
            </a-sheet>
        </a-card-text>
        <a-divider class="grey lighten-3 mx-6"></a-divider>
        <a-sheet class="py-5 px-6">
            <div class="u-flex">
                <a-btn @click="$emit('migration-update')" :disabled="!isMigrationFieldChanges" class="indigo darken-1 white--text u-rounded-corners elevation-0">Update</a-btn>
                <a-btn @click="$emit('migration-close')"  text class="ml-4 grey lighten-3 grey--text text--darken-1 u-rounded-corners">
                    Cancel
                </a-btn>
                <a-sheet v-if="isJiraInvalidFieldUpdateErrors" class="px-3 u-rounded-corners d-flex align-center ml-4 orange lighten-5">
                    <a-icon size="16px" color="orange darken-1">info</a-icon>
                    <p class="ml-2 font-weight-medium mb-0 orange--text text--darken-1">
                        One or more mappings are invalid
                    </p>
                </a-sheet>
            </div>
        </a-sheet>
    </a-card>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: {},
        },
        jiraInvalidField: {
            type: Array,
            default: [],
        },
        oldRelatedTypeFieldId: {
            type: String,
            default: null,
        },
        oldRelatedFieldId: {
            type: String,
            default: null,
        },
        isRemoveMigrationMapping: {
            type: Function,
            default: () => {},
            required: true,
        },
        removeMigrationMapping: {
            type: Function,
            default: () => {},
            required: true,
        },
        undoRemoveMigrationMapping: {
            type: Function,
            default: () => {},
            required: true,
        },
        isMigrationFieldChanges: {
            type: Boolean,
            default: false
        },
        getSourceValueMappingsFields:{
            type: Array,
            default: [],
        },
        getSourceFieldNamesMappings:{
            type: Array,
            default: [],
        },
        getJiraProjectType: {
            type: Function,
            default: () => {},
            required: true,
        },
        getJiraProject: {
            type: Function,
            default: () => {},
            required: true,
        },
        isFieldPicklist: {
            type: Function,
            default: () => {},
            required: true,
        },
        getSourceValueFields: {
            type: Function,
            default: () => {},
            required: true,
        },
        isJiraInvalidFieldUpdateErrors: {
            type: Boolean,
            default: false
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-action-connector {
        &::before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: #bdbdbd;
            border-radius: 100%;
            left: -6px;
        }
        &::after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: #bdbdbd;
            border-radius: 100%;
            right: -6px;
        }
    }
</style>
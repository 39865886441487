<template>
    <div class="c-assignee-dropdown">
        <!-- Assignee add icon and dropdown -->
        <a-menu v-model="dropdown" ref="refDropdown" offset-y :nudge-top="topNudge" min-width="400" max-width="400" :close-on-content-click="false" content-class="c-shared-assignee-dropdown u-rounded-corners white">
            <template v-slot:activator="{ on }">
                <div v-on.stop="on" class="u-flex align-center" :class="[{'u-cursor-pointer': canUpdate}]">
                    <!-- Assignee horizontal list -->
                    <div class="c-profiles" v-if="dataAssigneesLength" :style="{ 'max-width': item.assignees.length * 32 + 'px', 'flex-direction': 'row-reverse' }">
                        <div v-for="assignee in item.assignees.slice(0, (dataAssigneesLength > count ? (count - 1) : count)).reverse()" :key="assignee.assignee.user_id" class="c-profiles__img-wrap c-profiles--ml-n2">
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <div v-on.stop="on">
                                        <g-avatar no-shadow :item="assignee" :size="size" :font-size="initialFontSize" :no-border="noBorder" :right-space="false"></g-avatar>
                                    </div>
                                </template>
                                <span class="text-center">
                                    <template v-if="assignee && assignee.scope !== 'external'">
                                        <h2 class="md-subtitle-1 font-weight-medium white--text">{{ assignee.name }}</h2>
                                        <template v-if="!hideRole">
                                            <template v-if="!typeOrgUser">
                                                <p class="caption white--text mb-0" v-if="assignee.collaborator && assignee.collaborator.type">{{ assignee.collaborator.type.status }}</p>
                                            </template>
                                            <template v-else>
                                                <p class="caption white--text mb-0" v-if="assignee && assignee.role">{{ assignee.role.name }}</p>
                                            </template>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <h2 class="md-subtitle-1 font-weight-medium white--text">{{ assignee.name }} </h2>
                                        <template v-if="!hideRole && !dataIsExternalView">
                                            <p class="caption white--text mb-0" v-if="assignee.resource_type === 'Customer'">Client</p>
                                            <p class="caption white--text mb-0" v-if="assignee.resource_type === 'Partner'">Partner</p>
                                        </template>
                                    </template>
                                </span>
                            </a-tooltip>
                        </div>
                    </div>

                    <!-- Assignee count -->
                    <div v-if="dataAssigneesLength > count" class="c-profiles__img-wrap ml-n2" style="z-index: 1;">
                        <a-avatar :size="size" class="grey lighten-4">
                            <span class="md-caption font-weight-medium grey--text text--darken-1">+{{ dataAssigneesLength - (count - 1) }}</span>
                        </a-avatar>
                    </div>

                    <!-- Assignee add icon -->
                    <div class="u-flex align-center u-no-select" :class="[{'u-cursor-pointer': canUpdate}]">
                        <template v-if="!dataAssigneesLength && canUpdate">
                            <a-avatar :size="size" color="#3949AB">
                                <a-icon color="white" :size="size / 2">{{ canUpdate ? 'group_add' : 'people_alt' }}</a-icon>
                            </a-avatar>
                        </template>

                        <!-- No permission -->
                        <template v-if="!dataAssigneesLength && !canUpdate">
                            <a-avatar :size="size" color="transparent">
                                <a-icon color="grey lighten-1" :size="size">account_circle</a-icon>
                            </a-avatar>
                        </template>
                    </div>
                </div>
            </template>

            <div class="white">
                <!-- Placeholder info -->
                <a-sheet :class="['grey lighten-4 pa-6', canUpdate ? 'pb-8' : 'pb-6']" style="border-bottom: 1px solid #E4E5EB !important">
                    <template v-if="canUpdate">
                        <div class="u-flex align-center mb-2">
                            <a-icon size="24" color="indigo darken-2" class="mr-2">group_add</a-icon>
                            <h3 class="md-subtitle-1 font-weight-medium indigo--text text--darken-2">
                                <slot name="title">
                                    Assign to <span class="text-capitalize">{{ dataUserType }}s</span>
                                </slot>
                            </h3>
                        </div>
                        <div class="md-subtitle-1 grey--text text--darken-1">
                            <slot name="subtitle">
                                You can assign to both internal and external {{ dataUserType }}s.
                            </slot>
                        </div>
                    </template>
                    <template v-else>
                        <div class="u-flex align-center mb-2">
                            <a-icon size="24" color="indigo darken-2" class="mr-2">people_alt</a-icon>
                            <h3 class="md-subtitle-1 font-weight-medium indigo--text text--darken-2">Collaborators</h3>
                        </div>
                        <div class="md-subtitle-1 grey--text text--darken-1">Assigned internal and external {{ dataUserType }}s.</div>
                    </template>
                </a-sheet>

                <!-- Search field -->
                <a-card flat v-if="canUpdate" :loadingBar="assign_loading || search_loading" bottomLoader class="c-assignee-dropdown--search u-relative u-flex align-center mx-6 u-rounded-corners pa-2" style="border: 1px solid #e4e5eb; margin-top: -22px;">
                    <a-icon size="20" color="grey lighten-1" class="ml-1">person_search</a-icon>
                    <a-text-field
                        v-model="search_assignees"
                        :placeholder="`Search ${dataUserType}s and assign`"
                        class="mr-1"
                        solo flat hide-details autofocus
                        @input="methodSearch"
                    ></a-text-field>
                </a-card>

                <a-sheet class="mt-2">
                    <!--<template v-if="search_loading && enableSearch">
                        <a-sheet class="u-flex-center py-3 mb-1">
                            <span class="md-subtitle-1 body--text text--lighten-3">Searching {{ dataUserType }}s...</span>
                        </a-sheet>
                    </template>
                    <template v-else>-->
                        <!-- Searched list -->
                        <div v-if="canUpdate" :class="['c-collabs']" class="u-overflow-y c-tiny-scroll" style="maxHeight: 250px;">
                            <template v-if="dataCollabsList && dataCollabsList.length">
                                <template v-for="user in dataCollabsList">
                                    <a-sheet tabindex="0" @click="dataExternalWarning.value ? methodAddExternalUser(dataExternalWarning.user_id) : methodInitiateAssigning(user)" :class="[dataExternalWarning.value && (dataExternalWarning.user_id === user.user_id) ? 'orange lighten-5 align-start' : 'align-center', { 'u-cursor-pointer': canUpdate }]" class="c-collabs--item u-flex px-6 py-1 mb-2" :key="user.id">
                                        <template v-if="user.id !== 'no-data'">
                                            <div class="u-flex" :class="{ 'align-items-center': dataIsExternalView || hideRole }">
                                                <g-avatar class="mr-2" :item="user" :size="36" no-border :right-space="false"></g-avatar>
                                                <div v-if="!typeOrgUser && user.user" :class="{ 'u-flex-center-y': dataIsExternalView || hideRole }">
                                                    <h5 class="md-subtitle-1 grey--text text--darken-3 font-weight-medium text-truncate" style="max-width: 350px" :title="user.user.name">{{ user.user.name }}</h5>
                                                    <template v-if="!dataIsExternalView && !hideRole">
                                                        <p class="md-body-2 grey--text text--darken-1 mb-0 text-capitalize" v-if="['client', 'partner'].includes(user.group)">{{ user.group }}</p>
                                                        <template v-else>
                                                            <p class="md-body-2 grey--text text--darken-1 mb-0" v-if="user.type">{{ user.type.status }}</p>
                                                        </template>
                                                    </template>
                                                    <p v-if="dataExternalWarning.value && (dataExternalWarning.user_id === user.user_id)" class="md-caption orange--text text--darken-3 font-weight-medium mb-0">External <span class="text-capitalize">{{ dataUserType }}</span>. Click again to confirm.</p>
                                                </div>
                                                <div v-if="typeOrgUser && user" class="sss">
                                                    <h5 class="md-subtitle-1 grey--text text--darken-3 font-weight-medium text-truncate" style="max-width: 350px" :title="user.name">{{ user.name }}</h5>
                                                    <p class="md-body-2 grey--text text--darken-1 mb-0" v-if="user.role">{{ user.role.name }}</p>
                                                </div>
                                            </div>
                                            <a-spacer></a-spacer>
                                            <template v-if="canUpdate">
                                                <template v-if="methodUserIsSelected(user && user.user && user.user.id)">
                                                    <a-btn icon small class="accent" width="18" height="18" @click.stop="methodRemoveFromCollab(user.user_id)">
                                                        <a-icon size="16" color="white">remove</a-icon>
                                                    </a-btn>
                                                </template>
                                                <div v-else>
                                                    <a-tooltip bottom v-if="dataExternalWarning.value && (dataExternalWarning.user_id === user.user_id)">
                                                        <template v-slot:activator="{ on }">
                                                            <a-btn v-on="on" @click.stop="methodCancelConfirmation()" icon small class="orange darken-4 mr-3" width="18" height="18">
                                                                <a-icon size="16" color="white">cancel</a-icon>
                                                            </a-btn>
                                                        </template>
                                                        <span>Cancel adding external {{ dataUserType }}</span>
                                                    </a-tooltip>
                                                    <a-btn icon small class="green darken-2" width="18" height="18">
                                                        <a-icon size="16" color="white">add</a-icon>
                                                    </a-btn>
                                                </div>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <div class="md-subtitle-1 body--text text--lighten-3 px-6 u-flex-center py-2 mb-1 pt-3">
                                                <span>No {{ dataUserType }} found to assign</span>
                                            </div>
                                        </template>

                                    </a-sheet>
                                </template>
                            </template>
                            <div v-else class="md-subtitle-1 body--text text--lighten-3 px-6 u-flex-center py-2 mb-1 pt-3">
                                <span>No {{ dataUserType }} found to assign</span>
                            </div>
                        </div>
                    <!--</template>-->
                    <a-divider class="body--text text--lighten-4 mb-3" v-if="canUpdate"></a-divider>

                    <!-- Assigned list -->
                    <div class="mb-2 u-overflow-y c-tiny-scroll" style="maxHeight: 250px;">
                        <template v-if="dataAssigneesLength">
                            <template v-for="(assignee) in item.assignees">
                                <a-sheet tabindex="0" @click="canUpdate && !methodUserIsSelected(assignee.assignee && assignee.assignee.user_id, 'assignee') ? methodUserAssign(assignee.assignee.user_id) : ''" :class="[{ 'u-cursor-pointer': canUpdate }]" class="c-collabs--item u-flex align-center px-6 py-1 mb-2" :key="assignee.id">
                                    <div class="u-flex align-center">
                                        <g-avatar class="mr-2" :item="assignee" :size="36" no-border :right-space="false"></g-avatar>
                                        <div :class="{ 'u-flex-center-y': dataIsExternalView || hideRole }">
                                            <h5 class="md-subtitle-1 grey--text text--darken-3 font-weight-medium text-truncate" style="max-width: 350px" :title="assignee.name">{{ assignee.name }}</h5>
                                            <template v-if="!dataIsExternalView && !hideRole">
                                                <p class="md-body-2 grey--text text--darken-1 mb-0" v-if="assignee.resource_type === 'Customer'">Client</p>
                                                <p class="md-body-2 grey--text text--darken-1 mb-0" v-else-if="assignee.resource_type === 'Partner'">Partner</p>
                                                <template v-else>
                                                    <template v-if="typeOrgUser">
                                                        <p class="md-body-2 grey--text text--darken-1 mb-0" v-if="assignee.role">{{ assignee.role.name }}</p>
                                                    </template>
                                                    <template v-else>
                                                        <p class="md-body-2 grey--text text--darken-1 mb-0" v-if="assignee.collaborator && assignee.collaborator.type">{{ assignee.collaborator.type.status }}</p>
                                                    </template>
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <div v-if="canUpdate">
                                        <a-btn icon small class="accent" width="18" height="18" @click.stop="methodRemoveFromCollab(assignee.assignee.user_id)">
                                            <a-icon size="16" color="white">remove</a-icon>
                                        </a-btn>
                                    </div>
                                </a-sheet>
                            </template>
                        </template>
                        <div v-else class="md-body-2 body--text text--lighten-3 pt-3 pb-2 px-6">Not assigned to anyone yet. {{ canUpdate ? `You can search and assign to ${dataUserType}s.` : '' }}</div>
                    </div>
                </a-sheet>
            </div>
        </a-menu>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { SUsersDeck } from '@/config/config-shared-components'

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        canUpdate: {
            type: Boolean
        },
        size: {
            type: Number,
            default: 32
        },
        initialFontSize: {
            type: String,
            default: '12'
        },
        count: {
            type: Number,
            default: 2
        },
        hideEmptyText: {
            type: Boolean,
            default: false
        },
        hideRole: {
            type: Boolean,
            default: false
        },
        hideExternalConfirmation: {
            type: Boolean,
            default: false
        },
        hideAddButton: {
            type: Boolean,
            default: false
        },
        typeOrgUser: {
            type: Boolean,
            default: false
        },
        noBorder: {
            type: Boolean,
            default: false
        },
        enableSearch: {
            type: Boolean,
            default: false
        },
        isExternal: {
            type: Boolean,
        },
        projectId: {
            type: String,
            default: ''
        },
        moduleType: {
            type: String,
        },
        usersList: {
            type: Array,
        },
    },

    components: { SUsersDeck },

    data () {
        return {
            topNudge: 0,
            dropdown: false,
            search_timeout: null,
            search_assignees: '',
            dataExternalWarning: { user: '', user_id: '', value: false },
            search_loading: false,
            assign_loading: false,
        }
    },

    watch: {
        dropdown (newVal, oldVal) {
            if (newVal && !oldVal) {
                this.search_assignees = ''
                if (this.enableSearch) this.methodAssignClear()
            }
            if (newVal) {
                if (this.canUpdate) this.$emit('menuOpen', newVal)
                setTimeout(() => this.positionDropdown(), 50)
            }
        }
    },

    computed: {
        dataAssignees () {
            return this.item && this.item.assignees
        },

        dataAssigneesLength () {
            return this.item && this.item.assignees && this.item.assignees.length
        },

        dataIsExternalView () {
            return this.$route.meta.view === 'external' || (this.user_self && this.user_self.scope === 'external')
        },

        dataCollabsList () {
            let list = []
            if (!this.typeOrgUser) list = this.dataIsExternalView ? this.collaborator_external_list : this.collaborator_list
            else list = this.user_list

            if (this.enableSearch) {
                if (this.item && (!this.item.assignees || (this.item.assignees && !this.item.assignees.length))) return list
                return list.filter(item => {
                    if (this.item && this.item.assignees) {
                        const hasAssigneeIndex = this.item.assignees.findIndex(user => {
                            if (!this.typeOrgUser) return user.assignee.user_id === item.user_id
                            return user.id === item.id
                        })
                        if (hasAssigneeIndex === -1) return item
                    }
                })
            }

            if (this.item && (!this.item.assignees || (this.item.assignees && !this.item.assignees.length))) {
                return list.filter(i => {
                    const searchVal = this.search_assignees || ''
                    if (!searchVal) return i

                    const user = !this.typeOrgUser ? i.user : i
                    const emailMatched = user.email.toLowerCase().includes(searchVal.toLowerCase())
                    const nameMatched = user.name.toLowerCase().includes(searchVal.toLowerCase())
                    return !!(emailMatched || nameMatched)
                })
            }

            list = list.filter(item => {
                if (this.item && this.item.assignees) {
                    const hasAssigneeIndex = this.item.assignees.findIndex(user => {
                        if (!this.typeOrgUser) return user.assignee.user_id === item.user_id
                        return user.id === item.id
                    })
                    return hasAssigneeIndex === -1 ? item : null
                }
            })

            return list.filter(i => {
                const searchVal = this.search_assignees || ''
                if (!searchVal) return i

                const user = !this.typeOrgUser ? i.user : i
                const emailMatched = user.email.toLowerCase().includes(searchVal.toLowerCase())
                const nameMatched = user.name.toLowerCase().includes(searchVal.toLowerCase())
                return !!(emailMatched || nameMatched)
            })
        },

        dataUserType () {
            return this.typeOrgUser ? 'user' : 'collaborator'
        },

        ...mapState('AssigneeExternal', {
            assignee_external_item: 'item',
            assignee_external_response: 'response',
        }),

        ...mapState('Assignee', {
            assignee_item: 'item',
            assignee_response: 'response',
        }),

        ...mapState('Collaborator', {
            collaborator_list: 'list',
            collaborator_response: 'response',
        }),

        ...mapState('CollaboratorExternal', {
            collaborator_external_list: 'list',
            collaborator_external_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_list: 'list',
            user_self: 'self',
        }),
    },

    methods: {
        async methodSearch (value) {
            if (!this.canUpdate) return

            clearTimeout(this.search_timeout)
            if (!value || (value && !value.trim())) {
                this.$emit('menuOpen', true)
                return this.search_loading = false
            }
            if (this.enableSearch) await this.methodAssignClear()
            this.search_loading = true
            this.search_timeout = setTimeout(async () => {
                if (!this.typeOrgUser) await this.methodCollabFetch(value)
                else await this.methodOrgUsersFetch(value)
                this.search_loading = false
                this.positionDropdown()
                clearTimeout(this.search_timeout)
            }, 200)
        },

        async methodOrgUsersFetch (value) {
            if (!this.enableSearch) return
            const params = {
                include: 'role',
                sort: 'name',
                count: 1000,
                page: 1,
                'filter[name]': value,
                'fields[users]': 'id,name,email,initial,role_id,avatar,scope',
                'fields[role]': 'id,name,scope',
            }
            await this.user_index({ ...params })
        },

        async methodCollabFetch (value) {
            if (!this.enableSearch) return
            const params = { 'filter[project_id]': this.$route.params.id ?? this.projectId, 'filter[user]': value, count: 1000, page: 1 }

            if (!this.dataIsExternalView) return await this.collaborator_index({ ...params, include: 'user.role,type,contact' })
            await this.collaborator_external_index({ ...params, include: 'user.role,type' })
        },

        methodInitiateAssigning (user) {
            if (this.methodUserIsSelected(user.user_id)) return
            if (user.scope !== 'external') return this.methodAddInternalUser(user)
            if (this.hideExternalConfirmation || this.isExternal) return this.methodAddExternalUser(user.user_id)

            this.methodAskConfirmation(user)
        },

        methodUserIsSelected (id) {
            if (this.item && !this.item.assignees) return false

            const hasUser = this.item.assignees.find(({ assignee }) => id === assignee.user_id)
            return hasUser ?? false
        },

        async methodAddInternalUser (user) {
            const user_id = user.user_id || user.id
            const params = this.methodSetAssigneeParams(user_id)
            this.methodAddUser(user_id)
            this.assign_loading = true
            await this.methodAssign(params)
            this.$emit('assigned', true)
            this.methodAddAssociateId(user_id, params)
            this.assign_loading = false
        },

        async methodAddExternalUser (user_id) {
            this.$emit('change-visibility', 'internal')

            const params = this.methodSetAssigneeParams(user_id)
            this.methodAddUser(user_id)
            this.assign_loading = true
            await this.methodAssign(params)
            this.$emit('assigned', true)
            this.methodAddAssociateId(user_id, params)
            this.dataExternalWarning = { user: '', user_id: '', value: false }
            this.assign_loading = false
        },

        methodAskConfirmation (user) {
            this.dataExternalWarning = { user, user_id: user.user_id ?? user.id, value: true }
        },

        methodSetAssigneeParams (user_id) {
            const assignee_param = { resource_type: this.moduleType, resource_id: this.item.id, user_id }
            if (this.projectId) Object.assign(assignee_param, { project_id: this.projectId })
            return assignee_param
        },

        methodAddUser (user_id) {
            const added_user = this.usersList.find(item => item.user_id === user_id)
            if (!added_user) return true

            if (!this.item.assignees) Object.assign(this.item, { assignees: [] })
            this.item.assignees.push(Object.assign(added_user.user, { assignee: { user_id } }))
        },

        async methodAssign (assign_param) {
            if (!this.dataIsExternalView) await this.assignee_store(assign_param)
            else await this.assignee_external_store(assign_param)
            this.$emit('assignee-store')
        },

        methodAddAssociateId (user_id, { resource_id }) {
            const user_item = this.item && this.item.assignees ? this.item.assignees.find(user => user.assignee.user_id === user_id) : null
            if (user_item) Object.assign(user_item, { assignee: { id: (this.dataIsExternalView ? this.assignee_external_item.id : this.assignee_item.id), resource_id, user_id } })
        },

        async methodRemoveFromCollab (user_id) {
            const { assignee: { id: assignee_id } } = this.item.assignees.find(item => item.assignee.user_id === user_id)
            this.methodRemoveUser(assignee_id)
            await this.methodUnAssign({ id: assignee_id })
            this.$emit('unassigned')
            if (this.enableSearch) this.methodAssignClear()
        },

        methodRemoveUser (assignee_id) {
            const index = this.item.assignees.findIndex(item => item.assignee.id === assignee_id)
            if (index !== -1) this.item.assignees.splice(index, 1)
        },

        async methodUnAssign (assign_param) {
            if (!this.dataIsExternalView) await this.assignee_destroy(assign_param)
            else await this.assignee_external_destroy(assign_param)
            this.$emit('assignee-remove')
        },

        methodCancelConfirmation () {
            this.dataExternalWarning = { user: '', user_id: '', value: false }
        },

        async methodAssignClear () {
            if (!this.dataIsExternalView) return await this.assignee_clear()
            await this.assignee_external_clear()
        },

        positionDropdown () {
            const input = this.$el
            const dropdown = document.getElementsByClassName('menuable__content__active')[0]
            if (!dropdown || !input) return this.topNudge = 0

            const inputRect = input.getBoundingClientRect()
            const dropdownRect = dropdown.getBoundingClientRect()
            const bodyHeight = window.innerHeight

            if ((bodyHeight - inputRect.bottom) < dropdownRect.height) this.topNudge = dropdownRect.height + inputRect.height
            else this.topNudge = 0
        },

        ...mapActions('AssigneeExternal', {
            assignee_external_store: 'store',
            assignee_external_clear: 'clear',
            assignee_external_destroy: 'destroy',
        }),

        ...mapActions('Assignee', {
            assignee_store: 'store',
            assignee_clear: 'clear',
            assignee_destroy: 'destroy',
        }),

        ...mapActions('User', {
            user_index: 'index',
        }),

        ...mapActions('Collaborator', {
            collaborator_index: 'index',
            collaborator_clear: 'clear',
        }),

        ...mapActions('CollaboratorExternal', {
            collaborator_external_index: 'index',
            collaborator_external_clear: 'clear',
        }),
    }
}
</script>

<style lang="scss" scoped>
.c-collabs {
    .c-collabs--item {
        &:hover { background-color: #f5f5f5 !important; }
    }
}
</style>

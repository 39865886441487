<template>
    <div class="">
        <div class="u-flex-center-y" style="column-gap: 24px;" v-for="i in clonedLists" :key="i.id">
            <span>{{ i.id }}</span>
            <a-sheet width="400">
                <a-text-field type="text" v-model="i.title" @input="localNoteChanges($event, 'title', i.id)"></a-text-field>
            </a-sheet>
            <a-sheet width="400">
                <a-text-field type="text" v-model="i.description" @input="localNoteChanges($event, 'description', i.id)"></a-text-field>
            </a-sheet>
            <div>
                <input type="checkbox" v-model="i.isCompleted" @change="localNoteChanges(i.isCompleted, 'isCompleted', i.id)">
            </div>
        </div>

        <a-btn @click="localReset()" depressed>Clear</a-btn>

        <a-sheet
            max-width="300"
            class="pa-6 u-border-d u-shadow u-rounded-corners-lg transparent"
            style="position: fixed; bottom: 24px; left: 24px; backdrop-filter: blur(10px)"
        >
            <h3 class="md-subtitle-1 grey--text text--darken-4 mb-4">{{ toastMessage }}</h3>
            <div class="">
                <a-btn depressed small @click="localUndo()" class="indigo darken-3 white--text">
                    <a-icon size="20" color="white">undo</a-icon>
                    <span class="md-body-2 ml-1">Undo</span>
                </a-btn>
            </div>
        </a-sheet>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => [
                { id: 1, title: 'Hello world one 1', isCompleted: 1, description: 'This is a sample description one 1' },
                { id: 2, title: 'Hello world two 2', isCompleted: 0, description: 'This is a sample description two 2' },
                { id: 3, title: 'Hello world three 3', isCompleted: 1, description: 'This is a sample description three 3' },
                { id: 4, title: 'Hello world four 4', isCompleted: 0, description: 'This is a sample description four 4' },
            ]
        },
    },

    data () {
        return {
            originalArr: [
                { id: 1, title: 'Hello world one 1', isCompleted: 1, description: 'This is a sample description one 1' },
                { id: 2, title: 'Hello world two 2', isCompleted: 0, description: 'This is a sample description two 2' },
                { id: 3, title: 'Hello world three 3', isCompleted: 1, description: 'This is a sample description three 3' },
                { id: 4, title: 'Hello world four 4', isCompleted: 0, description: 'This is a sample description four 4' },
            ],
            clonedLists: [],
            changesArr: [],
            showToastMessage: false,
            saveTimeout: null,
            toastTimeout: null,
            toastMessage: 'Something for the placeholder!',
            updatedField: null
        }
    },

    watch: {
        showToastMessage: {
            handler (newVal, oldVal) {
                if (oldVal === true && newVal === false) this.localUpdateOriginalArr()
            },
            immediate: true,
        },
    },

    mounted () {
        this.localIndex()
    },

    methods: {
        localIndex () {
            this.clonedLists = _.cloneDeep(this.list)
        },

        localNoteChanges (value, field, id) {
            clearTimeout(this.saveTimeout)
            this.saveTimeout = setTimeout(() => {
                this.localTriggerSave(value, field, id)
                this.locaTriggerToast(value, field, id)
            }, 1000);
        },

        localTriggerSave (value, field, id) {
            const item = this.originalArr.find(i => i.id === id)
            const changeExists = this.changesArr.findIndex(i => i.id === id)
            let obj = { id, changes: [] }

            if (changeExists === -1) {
                obj.changes.push({ field, oldValue: item[field], newValue: value })
                this.changesArr.push(obj)
                return true
            }

            obj = { ...this.changesArr[changeExists] }
            const fieldExists = obj.changes.findIndex(i => i.field === field)
            if (fieldExists !== -1) obj.changes[fieldExists] = { ...obj.changes[fieldExists], newValue: value }
            else obj.changes.push({ field, oldValue: item[field], newValue: value })

            this.changesArr[changeExists] = { ...obj }
        },

        locaTriggerToast (value, field, id) {
            this.updatedField = { value, field, id }
            this.toastMessage = `Updated the ${field} for ${this.changesArr.length} tasks`
            this.showToastMessage = true
            clearTimeout(this.toastTimeout)
            this.toastTimeout = setTimeout(() => {
                this.showToastMessage = false
                this.updatedField = null
                this.toastMessage = null
            }, 5000);
        },

        localUpdateOriginalArr () {
            this.originalArr = _.cloneDeep(this.clonedLists)
            this.changesArr = []
        },

        localUndo () {
            const { field, id } = this.updatedField || {}
            const changeIndex = this.changesArr.findIndex(i => i.id === id)
            const changeItem = this.changesArr[changeIndex]
            const changeFieldIndex = changeItem.changes.findIndex(i => i.field === field)
            const listItemIndex = this.clonedLists.findIndex(i => i.id === id)

            this.clonedLists[listItemIndex][field] = changeItem.changes[changeFieldIndex].oldValue
            changeItem.changes.splice(changeFieldIndex, 1)
            this.changesArr[changeIndex] = { ...this.changesArr[changeIndex], changes: changeItem.changes }

            if (changeItem.changes.length === 0) this.changesArr.splice(changeIndex, 1)

            this.showToastMessage = false
            clearTimeout(this.toastTimeout)
            this.updatedField = null
        },

        localReset () {
            this.changesArr = []
            this.clonedLists = _.cloneDeep(this.originalArr)
        }
    }
}
</script>

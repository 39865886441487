import colors from '@/config/config-colors'

const Color = {
    install(Vue, _) {
        Vue.prototype.$color = (colorName, returnHex, defaultHex = null) => {
            const color = colors.find(item => item.name === colorName)
            if (returnHex) {
                if (color) return color[returnHex]
                if (defaultHex) return defaultHex
                return returnHex === 'color_bg' ? '#f1f1f1' : '#222222'
            }

            if (!color && defaultHex) return defaultHex
            return color
        }
        Vue.prototype.$randomColor = () => {
            const blackList = ['white']
            const filteredColors = colors.filter(i => !blackList.includes(i.name))
            const index = Math.floor(Math.random() * filteredColors.length)
            return filteredColors[index].name
        }
    }
}

export default Color

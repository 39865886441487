<template>
    <span v-bind="$attrs" v-on="$listeners" v-ripple :style="[dark ? { border: '2px dashed #b9b6b6' } : { border: '1px dashed #a3a3a3' }]" style="padding: 2px 10px 2px 6px;" class="md-caption u-no-select d-inline-flex align-center mr-2 my-1 u-cursor-pointer" :class="[tile ? 'u-rounded-corners' : 'u-rounded-corners-full']">
        <slot name="icon" v-if="!noIcon"><a-icon :size="iconSize" color="body lighten-1">add</a-icon></slot>
        <slot>Add</slot>
    </span>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        dark: {
            type: Boolean,
            default: false
        },
        tile: {
            type: Boolean,
            default: false
        },
        noIcon: {
            type: Boolean,
            default: false
        },
        iconSize: {
            type: String,
            default: '16'
        },
    }
}
</script>

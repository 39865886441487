<template>
	<a-main>
        <a-responsive class="grey darken-4 mt-5 px-2 u-rounded-corners-lg">
            <a-container grid-list-xl container--fluid class="py-2">
                <a-layout align-start>
                    <a-flex shrink class="px-0">
                        <a-btn color="white" text :to="{name: 'partners'}" exact>
                            <a-icon class="mr-1">arrow_back</a-icon> Back to Partners
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-container>
        </a-responsive>
        <a-container grid-list-xl container--fluid class="pa-6 pt-3 px-0 mb-12">
            <g-page-header
                :title="(resource_name) ? resource_name+'\'s Contacts' : 'Loading...'"
                subtitle="Manage your contacts to add in projects."
                icon="contact_mail"
                :breadcrumb-items="breadcrumb_items"
                :loading="loading"
                class="pr-1"
            >
                <template v-slot:action v-if="check_user_partner_permission('update')">
                    <a-btn color="primary" class="elevation-0 mx-0" dark @click="local_contact_create()">
                        <a-icon class="mr-2" color="white" size="18">add</a-icon>
                        Create New Contact
                    </a-btn>
                </template>
            </g-page-header>
            <a-layout align-center class="my-0 mb-2">
                <a-flex class="py-0">
                    <a-card class="c-search-inventory px-3 d-flex align-center u-border u-rounded-corners mb-1" flat>
                        <a-icon size="24" color="grey" style="max-width: 20px;">search</a-icon>
                        <a-text-field solo flat hide-details dense placeholder="Search by contact name" :loading="search_loading" class="" v-model="search_term" @input="local_search_filter(search_term)"></a-text-field>
                    </a-card>
                </a-flex>
            </a-layout>
            <a-layout>
                <a-flex xs12>
                    <a-card
                        v-for="(contact, index) in contact_list"
                        :key="contact.id"
                        :class="[{ 'mt-3': index !== 0 }]"
                        class="pa-1 u-border u-rounded-corners"
                        flat
                    >
                        <a-card-text class="pa-0">
                            <a-container container--fluid grid-list-xl>
                                <a-layout wrap align-center>
                                    <a-flex>
                                        <h2 class="md-subtitle-1 font-weight-medium grey--text text--darken-3 mb-1">{{ contact.name }}</h2>
                                        <p class="md-subtitle-2 mb-0" v-if="contact.email">{{ contact.email }}</p>
                                    </a-flex>
                                    <a-flex shrink v-if="contact.salesforce_id">
                                        <a-tooltip bottom left v-if="user_self && user_self.organization && user_self.organization.salesforce_instance_url">
                                            <template v-slot:activator="{ on }">
                                                <a :href="user_self.organization.salesforce_instance_url + '/' + contact.salesforce_id" target="_blank" rel="noopener" v-on="on">
                                                    <img src="../../assets/images/icon-salesforce.svg" height="24" alt="Salesforce">
                                                </a>
                                            </template>
                                            <span class="text-center">Imported from Salesforce.<br><span>Click to open Contact.</span></span>
                                        </a-tooltip>
                                        <a-tooltip bottom left v-else>
                                            <template v-slot:activator="{ on }">
                                                <img src="../../assets/images/icon-salesforce.svg" height="24" alt="Salesforce" v-on="on">
                                            </template>
                                            <span class="text-center">Imported from Salesforce.</span>
                                        </a-tooltip>
                                    </a-flex>
                                    <a-flex shrink v-if="check_user_partner_permission('update')">
                                        <a-btn color="grey" text icon @click="local_contact_edit(contact.id)">
                                            <a-icon>edit</a-icon>
                                        </a-btn>
                                    </a-flex>
                                </a-layout>
                            </a-container>
                        </a-card-text>
                    </a-card>
                </a-flex>
            </a-layout>
            <a-layout wrap align-start v-if="!local_loading">
                <a-flex xs12 class="u-flex align-center">
                    <a-btn v-if="contact_meta && (contact_meta.current_page < contact_meta.last_page)" :loading="loading" :disabled="loading" @click="local_paginate(contact_meta.current_page + 1)" small text class="primary" dark>
                        Load More
                    </a-btn>
                    <a-spacer></a-spacer>
                    <span class="md-body-2 grey--text text--darken-1" v-if="contact_meta.total">Showing <strong>{{ contact_list.length }}</strong> out of <strong>{{ contact_meta.total }}</strong> contacts</span>
                </a-flex>
            </a-layout>
        </a-container>
        <a-dialog v-model="dialog_contact_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="local_contact_store()">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>contact_mail</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (contact_form_mode == 'add') ? 'Create New Contact' : 'Edit Existing Contact' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (contact_form_mode == 'add') ? 'You can add new contact to add in projects.' : 'You can edit existing contact information.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                    <a-text-field
                                        v-model="contact_item.name"
                                        placeholder="Enter Contact Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="contact_response.server && contact_response.server.errors && contact_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ contact_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        The name will be helpful to identify the contact.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Email</label>
                                    <a-text-field
                                        v-model="contact_item.email"
                                        placeholder="Enter Contact Email"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="contact_response.server && contact_response.server.errors && contact_response.server.errors.email">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ contact_response.server.errors.email[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Enter the email address. This will be used to invite to the projects later.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_contact_store()" :loading="loading" :disabled="loading">Save Contact</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="dialog_contact_form = !dialog_contact_form" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                                <a-flex shrink>
                                    <span class="md-caption grey--text text--darken-2 d-block">
                                        &nbsp;
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            local_loading: false,
            dialog_contact_form: false,
            resource_name: null,
            back_path_name: null,
            back_button_text: null,
            search_loading: false,
            search_term: null,
            contact_filter: {},
            contact_count: 100,
            FILTERS: {
                PAGE: 'page',
                COUNT: 'count',
                NAME: 'filter[name]'
            }
        }
    },
    mounted() {
        this.local_index()
    },
    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Partners', to: '/partners', exact: true})
            breadcrumbs.push({text: 'Contacts', to: `/partners/${this.$route.params.id}/contacts`, exact: true})
            return breadcrumbs
        },
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('Contact', {
            contact_list: 'list',
            contact_item: 'item',
            contact_filters: 'filters',
            contact_form_mode: 'form_mode',
            contact_response: 'response',
            contact_meta: 'meta'
        }),
        ...mapState('Partner', {
            partner_item: 'item',
            partner_form_mode: 'form_mode',
            partner_response: 'response',
        }),
    },
    watch: {
        search_term(newValue, oldValue) {
            if (newValue == '' && newValue != oldValue || newValue == null && newValue != oldValue) {
                this.contact_filter[this.FILTERS.PAGE] = 1
                this.contact_filter[this.FILTERS.COUNT] = this.contact_count
                this.local_contact_index()
            }
        },
    },
    methods: {
        async local_index() {
            this.local_loading = true
            await this.contact_clear()
            this.back_path_name = 'partners'
            this.back_button_text = 'partners'

            await this.partner_clear_item()
            await this.partner_show({id: this.$route.params.id})

            this.resource_name = this.partner_item.name
            this.contact_filter[this.FILTERS.PAGE] = 1
            this.contact_filter[this.FILTERS.COUNT] = this.contact_count
            await this.local_contact_index()
            this.local_loading = false
        },
        async local_contact_index() {
            await this.contact_index({
                params:{
                    include: 'bridges',
                    'filter[parent_id]': this.$route.params.id,
                    'filter[parent_type]': 'Partner',
                    'sort': 'name',
                    ...this.contact_filter,
                }
            })
        },
        local_search_filter (value) {
            this.search_loading = true
            clearTimeout(this.search_timeout)
            this.contact_filter[this.FILTERS.COUNT] = 999
            this.contact_filter[this.FILTERS.PAGE] = 1
            this.contact_filter[this.FILTERS.NAME] = value
            this.search_timeout = window.setTimeout(async () => {
                if (!this.search_term.trim()) return this.search_loading = false
                if (!this.search_term || this.search_term.trim().length <= 3) {
                    const search_error = 'Try more than 3 characters to search.'
                    this.$notify('error', search_error)
                    return this.search_loading = false
                }
                await this.local_contact_index()
                this.search_loading = false
            }, 500)
        },
        async local_contact_edit(id) {
            await this.contact_select({id: id})
            this.dialog_contact_form = true
        },
        async local_contact_create() {
            await this.contact_clear_item()
            this.dialog_contact_form = true
        },
        async local_contact_store() {
            this.contact_item.parent_id = this.$route.params.id
            this.contact_item.parent_type = 'Partner'

            if (this.contact_form_mode == 'add') {
                await this.contact_store({ ...this.contact_item, ...{ email: this.contact_item.email ? this.contact_item.email.toLowerCase() : '' } })
            } else {
                await this.contact_update()
            }

            if (this.contact_response.status == 'success') {
                this.dialog_contact_form = false
            }
        },
        async local_contact_reorder(list) {
            this.contact_reorder({list: list})
        },
        check_user_partner_permission(type, client_allowed = false) {
            if (this.user_self.role.scope === 'external') {
                return client_allowed
            }
            return this.$can(`partners.${type}`) && this.user_self.role.scope === 'internal'
        },
        async local_paginate(value) {
            this.contact_filter[this.FILTERS.PAGE] = value
            await this.local_contact_index()
        },
        ...mapActions('Contact', {
            contact_index: 'index',
            contact_store: 'store',
            contact_update: 'update',
            contact_select: 'select',
            contact_upgrade: 'upgrade',
            contact_downgrade: 'downgrade',
            contact_destroy: 'destroy',
            contact_reorder: 'reorder',
            contact_clear: 'clear',
            contact_clear_item: 'clear_item',
        }),
        ...mapActions('Customer', {
            customer_show: 'show',
            customer_clear_item: 'clear_item',
        }),
        ...mapActions('Partner', {
            partner_show: 'show',
            partner_clear_item: 'clear_item',
        }),
    },
}
</script>

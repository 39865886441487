<template>
	<a-main>
        <g-settings-breadcrumb class="mt-5" previousRoute="settings" v-if="workspaceNotFoundError"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="py-6 pt-2 px-0 mb-10">
            <a-layout wrap align-center class="mb-3">
                <a-flex shrink>
                    <a-icon size="48" color="neutral darken-2">apartment</a-icon>
                </a-flex>
                <a-flex>
                    <h2 class="md-heading-5 primary--text">Workspace</h2>
                    <p class="md-subtitle-1 grey--text text--darken-2 mb-0">Manage organization workspaces</p>
                </a-flex>
                <a-flex shrink v-if="$can('workspaces.store')">
                    <a-btn class="ma-0 elevation-0 px-3 primary" @click="local_workspace_create()">
                        <a-icon left class="mr-1">add</a-icon>Create New Workspace
                    </a-btn>
                </a-flex>
            </a-layout>
            <div v-if="localLoading" class="text-center u-wfull">Loading...</div>
            <a-layout class="mx-0" v-if="!localLoading">
                <a-flex xs12 class="pa-0 py-4">
                    <a-card class="u-elevation-custom-1 u-rounded-corners">
                        <a-layout wrap align-center>
                            <a-flex xs12 class="py-0">
                                <draggable v-model="workspace_list" :options="{handle: '.js-drag-handle'}">
                                    <a-card flat v-for="workspace in workspace_list" :key="workspace.id">
                                        <a-card-text class="pa-0 py-2">
                                            <a-container container--fluid grid-list-xl class="py-3 px-6">
                                                <a-layout wrap align-center>
                                                    <a-flex shrink class="px-0" v-if="user_self.role && user_self.role.scope === 'internal' && $can('workspaces.update')">
                                                        <a-icon class="grey--text text--lighten-1 u-cursor-pointer js-drag-handle">drag_indicator</a-icon>
                                                    </a-flex>
                                                    <a-flex xs8 xl9>
                                                        <router-link tag="div" class="u-cursor-pointer u-wfull ml-2" :to="{name: 'settings-workspace-stages', params: {id: workspace.id}}">
                                                            <h3 class="md-subtitle-1 primary--text" style="min-height: 25px;">
                                                                {{ workspace.title }}
                                                                <a-chip small class="ml-3 my-0 grey lighten-3 grey--text text--darken-2" v-if="workspace.is_default === 1">Default</a-chip>
                                                            </h3>
                                                        </router-link>
                                                    </a-flex>
                                                    <a-spacer></a-spacer>
                                                    <a-flex shrink class="lg-and-down:pr-2 xl-and-up:pr-4 py-0" v-if="user_self.role && user_self.role.scope === 'internal' && $can('workspaces.update')">
                                                        <a-icon v-if="!workspace.is_active" size="24" color="grey lighten-1">star_border</a-icon>
                                                        <a-tooltip bottom v-else>
                                                            <template v-slot:activator="{ on }">
                                                                <a-icon size="24" v-if="workspace.is_default" color="orange" v-on="on" @click="{}">star</a-icon>
                                                                <a-icon v-else size="24" @click="local_workspace_default(workspace.is_active, workspace.id, 1)" v-on="on">star_border</a-icon>
                                                            </template>
                                                            <span class="text-center" v-if="workspace.is_default">Default</span>
                                                            <span class="text-center" v-else>Make as default</span>
                                                        </a-tooltip>
                                                    </a-flex>
                                                    <a-flex shrink class="lg-and-down:px-2 xl-and-up:px-4 py-0" v-if="user_self.role && user_self.role.scope === 'internal' && $can('workspaces.update')">
                                                        <a-icon v-if="workspace.is_default" size="30" color="green lighten-3">toggle_on</a-icon>
                                                        <a-tooltip bottom v-else>
                                                            <template v-slot:activator="{ on }">
                                                                <a-icon v-if="workspace.is_active" size="30" color="green lighten-1" v-on="on" @click="local_workspace_active(workspace.is_default, workspace.id, 0)">toggle_on</a-icon>
                                                                <a-icon v-else size="30" color="grey darken-1" v-on="on" @click="local_workspace_active(workspace.is_default, workspace.id, 1)">toggle_off</a-icon>
                                                            </template>
                                                            <template>
                                                                <span class="text-center" v-if="!workspace.is_active">Enable this workspace</span>
                                                                <span class="text-center" v-else>Disable this workspace</span>
                                                            </template>
                                                        </a-tooltip>
                                                    </a-flex>
                                                    <a-flex shrink class="lg-and-down:px-2 xl-and-up:px-4 py-0" v-if="user_self.role && user_self.role.scope === 'internal' && $can('workspaces.update')">
                                                        <a-menu offset-y left class="u-rounded-corners" content-class="u-elevation-custom-1 u-border" max-width="250">
                                                            <template v-slot:activator="{ on }">
                                                                <span v-on="on" v-if="workspace.is_global" class="orange--text text--darken-1 u-flex align-center body-2" style="min-width: 75px;">
                                                                    <a-icon size="18" class="" color="orange lighten-1">lock_open</a-icon>
                                                                    <h5 class="md-body-1 ml-1 orange--text u-cursor-pointer">Public</h5>
                                                                </span>
                                                                <span v-on="on" v-else class="u-flex align-center body-2" style="min-width: 75px;">
                                                                    <a-icon size="18" class="" color="grey darken-1">lock</a-icon>
                                                                    <h5 class="md-body-1 ml-1 grey--text text--darken-1 u-cursor-pointer">Private</h5>
                                                                </span>
                                                            </template>
                                                            <a-card class="u-rounded-corners pa-3" v-if="workspace.is_global">
                                                                <h2 class="grey--text text--darken-2 body-2 mb-2">This workspace is currently public, click "Ok" to make it as private.</h2>
                                                                <a-btn dark small text @click="local_change_workspace_visibility(workspace.id, 0)" class="red lighten-1 ml-0 u-rounded-corners">Ok</a-btn>
                                                            </a-card>
                                                            <a-card class="u-rounded-corners pa-3" v-else>
                                                                <h2 class="grey--text text--darken-2 body-2 mb-2">This workspace is currently private, click "Ok" to make it as public.</h2>
                                                                <a-btn dark small text @click="local_change_workspace_visibility(workspace.id, 1)" class="red lighten-1 ml-0 u-rounded-corners">Ok</a-btn>
                                                            </a-card>
                                                        </a-menu>
                                                        <!-- <div class="d-inline-block" v-on="on">
                                                        </div> -->
                                                        <!-- <a-tooltip bottom>
                                                            <template>
                                                                <span class="text-center" v-if="!workspace.is_global">Change to public</span>
                                                                <span class="text-center" v-else>Change to private</span>
                                                            </template>
                                                        </a-tooltip> -->
                                                    </a-flex>
                                                    <a-flex shrink class="lg-and-down:pl-2 xl-and-up:pl-4 pr-3 py-0">
                                                        <a-tooltip bottom v-if="workspace.is_global === 0">
                                                            <template v-slot:activator="{ on }">
                                                                <div v-on="on">
                                                                    <a-badge color="grey darken-2" bordered overlap>
                                                                        <span slot="badge" class="d-block">{{ workspace.workspace_members_count ? workspace.workspace_members_count : 0 }}</span>
                                                                        <a-icon color="grey lighten-1" size="32">
                                                                            account_circle
                                                                        </a-icon>
                                                                    </a-badge>
                                                                </div>
                                                            </template>
                                                            <span>{{ workspace.workspace_members_count ? workspace.workspace_members_count : 0 }} Member{{ workspace.workspace_members_count === 1 ? '' : 's' }}</span>
                                                        </a-tooltip>
                                                        <template v-else>
                                                            <a-badge color="orange darken-2" bordered overlap>
                                                                <span slot="badge" class="d-block"><a-icon>public</a-icon></span>
                                                                <a-icon color="grey lighten-1" size="32">
                                                                    account_circle
                                                                </a-icon>
                                                            </a-badge>
                                                        </template>
                                                    </a-flex>
                                                    <a-flex shrink class="lg-and-down:px-2 xl-and-up:px-4 py-0 mb-1" v-if="user_self.role && user_self.role.scope === 'internal' && $can('workspaces.update')">
                                                        <a-icon size="18" color="grey darken-1" @click="local_workspace_edit(workspace.id)">edit</a-icon>
                                                    </a-flex>
                                                </a-layout>
                                            </a-container>
                                        </a-card-text>
                                        <a-divider class="grey lighten-4"></a-divider>
                                    </a-card>
                                </draggable>
                            </a-flex>
                        </a-layout>
                    </a-card>
                </a-flex>
            </a-layout>
        </a-container>
        <router-view></router-view>
        <a-dialog v-model="dialog_workspace_form" max-width="700" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>apartment</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (workspace_form_mode == 'add') ? 'Create New Workspace' : 'Edit Existing Workspace' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (workspace_form_mode == 'add') ? 'You can add new workspace to the organization.' : 'You can edit existing workspace.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title</label>
                                    <a-text-field
                                        v-model="local_workspace_title"
                                        placeholder="Enter Workspace Title"
                                        background-color="neutral"
                                        solo flat hide-details autofocus
                                    >
                                    </a-text-field>
                                    <div v-if="show_workspace_error">
                                        <span class="md-caption red--text text--darken-2 d-block mt-2">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ show_workspace_error }}
                                        </span>
                                    </div>
                                    <div v-else>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="workspace_response.server && workspace_response.server.errors && workspace_response.server.errors.title">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            {{ workspace_response.server.errors.title[0] }}
                                        </span>
                                        <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                            <a-icon size="16">info</a-icon>
                                            The title will be helpful to identify the workspace.
                                        </span>
                                    </div>
                                </a-flex>
                                <a-flex xs12 v-if="workspace_form_mode === 'add'">
                                    <a-checkbox hide-details v-model="local_workspace_visibility" :true-value="1" :false-value="0" class="ma-0 my-2 pa-0" color="primary" label="Make workspace public"></a-checkbox>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_workspace_store()" :loading="loading" :disabled="loading">Save Workspace</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_workspace_close()" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-snackbar v-model="snackbar_status" :timeout="4000" bottom center :color="snackbar_response_status === 'error' ? 'red' : ''">
            {{ snackbar_text }}
        </a-snackbar>
	</a-main>
</template>

<script>
import { mapState, mapActions , mapGetters } from 'vuex'

export default {
    data() {
        return {
            localLoading: true,
            dialog_workspace_form: false,
            local_workspace_title: '',
            dialog_stage_form: false,
            accordion_workspace: [],
            local_stage_grouped_title: [],
            accordion_workspace_all: true,
            local_stage_item : {},
            snackbar_response_status: '',
            snackbar_status: false,
            snackbar_text: '',
            local_stage_form_mode: 'add',
            show_workspace_error: null,
            local_workspace_visibility: 1,
            default_workspace: null
        }
    },
    props: ['workspaceNotFoundError'],
    mounted() {
        this.local_index()
    },
    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Settings', to: '/settings', exact: true})
            breadcrumbs.push({text: 'Stages', to: '/settings/stages', exact: true})
            return breadcrumbs
        },
        workspace_list: {
            get() {
                return this.$store.state.Workspace.list
            },
            set(list) {
                this.local_workspace_reorder(list)
            }
        },
        ...mapState('Stage', {
            stage_item: 'item',
            stage_form_mode: 'form_mode',
            stage_response: 'response',
        }),
        ...mapState('Workspace', {
            workspace_board_list: 'board_list',
            workspace_item: 'item',
            workspace_form_mode: 'form_mode',
            workspace_current: 'current',
            workspace_filters: 'filters',
            workspace_response: 'response',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('workspaces.index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.workspace_clear()
            await this.workspace_index({
                include: 'workspaceMembersCount'
            })
            this.localLoading = false
        },
        async local_workspace_edit(id) {
            this.local_workspace_title = ''
            await this.workspace_clear_item()
            await this.workspace_select({id: id})
            this.local_workspace_title = this.workspace_item.title
            this.dialog_workspace_form = true
        },
        async local_workspace_create() {
            await this.workspace_clear_item()
            this.local_workspace_visibility = 1
            this.dialog_workspace_form = true
        },
        async local_workspace_store() {
            this.local_workspace_validation()
            if(this.show_workspace_error) return
            this.workspace_item.title = this.local_workspace_title

            if (this.workspace_form_mode == 'add') {
                this.workspace_item.order = -1
                this.workspace_item.is_global = this.local_workspace_visibility
                await this.workspace_store({ ...this.workspace_item })
                this.workspace_board()
            } else {
                await this.workspace_update({ ...this.workspace_item })
                this.workspace_board()
            }

            if (this.workspace_response.status !== 'success') return
            this.local_workspace_close()
        },
        async local_workspace_close() {
            this.dialog_workspace_form = false
            this.local_workspace_title = ''
            this.show_workspace_error = null
        },
        local_workspace_validation() {
           this.show_workspace_error = null
           if(!this.local_workspace_title || !this.local_workspace_title.trim()) {
               this.show_workspace_error = "Workspace title is required"
               return
           }
           if(this.local_workspace_title && this.local_workspace_title.trim().length > 255) {
               this.show_workspace_error = "Workspace title is too long. Only 255 characters allowed."
               return
           }
        },
        async local_change_workspace_visibility(id, visibility) {
            await this.workspace_visibility({ id: id, state: visibility })
            if (this.workspace_response.status === 'success') {
                await this.workspace_board()
                if (this.workspace_current === id) this.local_switch_workspace()
                return
            }
        },
        local_snackbar_status(text, status) {
            this.snackbar_text = text
            this.snackbar_response_status = status
            this.snackbar_status = true
        },
        async local_workspace_active(workspace_default, id, is_active) {
            if(workspace_default) return
            await this.workspace_active({ id: id, state: is_active})
            if (this.workspace_response.status === 'success') {
                await this.workspace_board()
                if (this.workspace_current === id) this.local_switch_workspace()
                // this.auth_user_permission_index()
                return
            }
            const text = this.workspace_response.server.message
            this.local_snackbar_status(text, 'error')
        },
        async local_switch_workspace (id) {
            id = id ?? this.workspace_board_list[0].id
            await this.preference_workspace_switch({
                resource_type: 'User',
                value: id,
                label: 'Workspace',
                key: 'user.workspace'
            })
        },
        async local_workspace_default(workspace_active, id, is_default) {
            if(!workspace_active) return
            await this.workspace_default({ id: id, state: is_default })
            if (this.workspace_response.status === 'success') {
                // await this.auth_user_permission_index()
                return
            }
            const text = this.workspace_response.server.message
            this.local_snackbar_status(text, 'error')
        },
        async local_workspace_reorder(list) {
            await this.workspace_reorder({list: list})
            this.workspace_board()
        },
        ...mapActions('User', {
            user_self_switch_workspace: 'switch_workspace'
        }),
        ...mapActions('Workspace', {
            workspace_switch: 'switch_workspace'
        }),
        ...mapActions('Preference', {
            preference_workspace_switch: 'switch_workspace',
        }),
        ...mapActions('Auth',{
            auth_user_permission_index: 'userPermissions',
        }),
        ...mapActions('Stage', {
            stage_index: 'index',
            stage_store: 'store',
            stage_update: 'update',
            stage_select: 'select',
            stage_destroy: 'destroy',
            stage_reorder: 'reorder',
            stage_clear: 'clear',
            stage_clear_item: 'clear_item',
        }),
        ...mapActions('Workspace', {
            workspace_index: 'index',
            workspace_board: 'board',
            workspace_store: 'store',
            workspace_show: 'show',
            workspace_update: 'update',
            workspace_visibility: 'visibility',
            workspace_select: 'select',
            workspace_destroy: 'destroy',
            workspace_stage_store: 'stage_store',
            workspace_stage_destroy: 'stage_destroy',
            workspace_stage_select: 'stage_select',
            workspace_stage_update: 'stage_update',
            workspace_stage_reorder: 'stage_reorder',
            workspace_reorder: 'reorder',
            workspace_clear: 'clear',
            workspace_active: 'active',
            workspace_default: 'default',
            workspace_clear_item: 'clear_item',
        }),
    },
}
</script>
